module.exports = [
  {"season": 5, "episode": 1, "scene": 1, "line": "All right, everybody. This is your last meal, so eat up. [cheering]", "order": 20678, "speaker": "Michael Scott"},
  {"season": 5, "episode": 1, "scene": 1, "line": "From this point forward, you will not use the bathroom. We need to keep our starting weights high so we can lose more.", "order": 20679, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 1, "scene": 2, "line": "This summer, corporate is sponsoring a little weight loss contest between the branches. Whichever branch loses the most weight gets three extra vacation days.", "order": 20680, "speaker": "Michael Scott"},
  {"season": 5, "episode": 1, "scene": 3, "line": "What should we do with all these leftovers?", "order": 20681, "speaker": "Meredith Palmer"},
  {"season": 5, "episode": 1, "scene": 3, "line": "I'm taking the dumplings for my wife.", "order": 20682, "speaker": "Stanley Hudson"},
  {"season": 5, "episode": 1, "scene": 3, "line": "No, no, no. This is your last meal. There will be no leftovers.", "order": 20683, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 1, "scene": 3, "line": "I can bring these to my shelter.", "order": 20684, "speaker": "Creed Bratton"},
  {"season": 5, "episode": 1, "scene": 3, "line": "I'm taking my dumplings.", "order": 20685, "speaker": "Stanley Hudson"},
  {"season": 5, "episode": 1, "scene": 3, "line": "[sprays food with bug poison] There. Take those home to your wife.", "order": 20686, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 1, "scene": 3, "line": "Dwight, Dwight!", "order": 20687, "speaker": "Michael Scott"},
  {"season": 5, "episode": 1, "scene": 3, "line": "Michael, it's time.", "order": 20688, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 1, "scene": 4, "line": "Hit the scales, everybody. Right on that black platform. This way. Step it up.", "order": 20689, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 1, "scene": 4, "line": "Don't go breaking my scale.", "order": 20690, "speaker": "Darryl Philbin"},
  {"season": 5, "episode": 1, "scene": 5, "line": "I'm excited to lose weight for the wedding, because I really want to have washboard abs the first time Angela sees me naked.", "order": 20691, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 1, "scene": 6, "line": "Ex-squeeze me.", "order": 20692, "speaker": "Holly Flax"},
  {"season": 5, "episode": 1, "scene": 6, "line": "No, I will ex-squeeze you.", "order": 20693, "speaker": "Michael Scott"},
  {"season": 5, "episode": 1, "scene": 6, "line": "OK everybody. Get used to this because we are going to be standing here briefly every Monday for the next seven Mondays.", "order": 20694, "speaker": "Michael Scott"},
  {"season": 5, "episode": 1, "scene": 6, "line": "Wait a minute. One more bite of eclair each. Hold it in your mouth if you can't swallow.", "order": 20695, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 1, "scene": 6, "line": "[smiles, looks to Michael] Really, nothing?", "order": 20696, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 1, "scene": 6, "line": "2,336 pounds. Y'all need to learn some portion control.", "order": 20697, "speaker": "Darryl Philbin"},
  {"season": 5, "episode": 1, "scene": 6, "line": "Wait a minute. Pam is on the scale.", "order": 20698, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 1, "scene": 6, "line": "Oh, Pamcake. No, no, no. We would love your extra poundage, but you are going to be leaving us next week, so vamoose. Vamoose, Pam. Dunder Mifflin family only.", "order": 20699, "speaker": "Michael Scott"},
  {"season": 5, "episode": 1, "scene": 6, "line": "Family only.", "order": 20700, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 1, "scene": 6, "line": "All right, got it. 2,210 pounds.", "order": 20701, "speaker": "Darryl Philbin"},
  {"season": 5, "episode": 1, "scene": 6, "line": "Pam, you weigh 226 pounds?", "order": 20702, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 1, "scene": 6, "line": "Almost, Kevin.", "order": 20703, "speaker": "Holly Flax"},
  {"season": 5, "episode": 1, "scene": 6, "line": "Not almost, though, Holly. I mean, not -- not close to 200...", "order": 20704, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 1, "scene": 6, "line": "Math is hard.", "order": 20705, "speaker": "Holly Flax"},
  {"season": 5, "episode": 1, "scene": 6, "line": "Yeah.", "order": 20706, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 1, "scene": 6, "line": "Just, we'll just keep going.", "order": 20707, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 1, "scene": 7, "line": "I'm totally gonna slaughter at the weigh-in today.", "order": 20708, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 1, "scene": 7, "line": "All I had this weekend to eat was a chicken breast and a case of Diet Coke.", "order": 20709, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 1, "scene": 7, "line": "Really? 'Cause I haven't eaten anything since noon on Friday.", "order": 20710, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 1, "scene": 7, "line": "OK. [holds elevator] Stanley, come on.", "order": 20711, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 1, "scene": 7, "line": "Oh, I forgot something in my car. I'll see you guys up there. [takes the stairs after the elevator doors close]", "order": 20712, "speaker": "Stanley Hudson"},
  {"season": 5, "episode": 1, "scene": 8, "line": "I happen to be losing weight on my own for my own reasons. The truth is, I have lost a little of my speed, a little of my fire. Here's what I used to look like. [holds up old photo] Look at those biceps. We were fighting the power and eating whatever we wanted.", "order": 20713, "speaker": "Stanley Hudson"},
  {"season": 5, "episode": 1, "scene": 9, "line": "And arch your back. And slowly bring up your head. [Michael and Holly face each other in yoga pose]", "order": 20714, "speaker": "Holly Flax"},
  {"season": 5, "episode": 1, "scene": 10, "line": "Jimbo.", "order": 20715, "speaker": "Michael Scott"},
  {"season": 5, "episode": 1, "scene": 10, "line": "Ah, they moved the shower.", "order": 20716, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 1, "scene": 10, "line": "Did you see Holly's butt?", "order": 20717, "speaker": "Michael Scott"},
  {"season": 5, "episode": 1, "scene": 10, "line": "No, I didn't. [Michael laughs] You know why?", "order": 20718, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 1, "scene": 10, "line": "Why?", "order": 20719, "speaker": "Michael Scott"},
  {"season": 5, "episode": 1, "scene": 10, "line": "Because most of the time, friends don't talk about other friends' butts.", "order": 20720, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 1, "scene": 10, "line": "I know.", "order": 20721, "speaker": "Michael Scott"},
  {"season": 5, "episode": 1, "scene": 10, "line": "But what have you learned about her?", "order": 20722, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 1, "scene": 10, "line": "I learned that she broke her left leg twice in one year. I learned that she's allergic to sesame seeds. I learned that she has read 'Lonesome Dove' three times.", "order": 20723, "speaker": "Michael Scott"},
  {"season": 5, "episode": 1, "scene": 10, "line": "Nice.", "order": 20724, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 1, "scene": 10, "line": "And that her butt refuses to quit!", "order": 20725, "speaker": "Michael Scott"},
  {"season": 5, "episode": 1, "scene": 10, "line": "Well, I tried. [Michael laughs]", "order": 20726, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 1, "scene": 10, "line": "[follows Jim into bathroom] You have to agree with me. [follows Jim back out] That's insane.", "order": 20727, "speaker": "Michael Scott"},
  {"season": 5, "episode": 1, "scene": 10, "line": "I'll just go later.", "order": 20728, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 1, "scene": 10, "line": "I thought you had to pee.", "order": 20729, "speaker": "Michael Scott"},
  {"season": 5, "episode": 1, "scene": 11, "line": "[forces candy out of vending machine and forces fruit into racks]", "order": 20730, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 1, "scene": 12, "line": "Hey, if you're into yoga, I take a great bikram class in Dickson City.", "order": 20731, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 1, "scene": 12, "line": "Oh, thanks. I should check that out.", "order": 20732, "speaker": "Holly Flax"},
  {"season": 5, "episode": 1, "scene": 12, "line": "Also -- and no pressure -- the teacher? He's a catch.", "order": 20733, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 1, "scene": 12, "line": "Actually, I'm a lesbian.", "order": 20734, "speaker": "Holly Flax"},
  {"season": 5, "episode": 1, "scene": 12, "line": "I'm gay!", "order": 20735, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 1, "scene": 12, "line": "I'm not a lesbian. I don't know why I said that... It's a joke.", "order": 20736, "speaker": "Holly Flax"},
  {"season": 5, "episode": 1, "scene": 12, "line": "What's the joke?", "order": 20737, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 1, "scene": 12, "line": "There is no joke. I just said it because I haven't had much luck lately and I'm not really looking to date. Maybe I should switch to women.", "order": 20738, "speaker": "Holly Flax"},
  {"season": 5, "episode": 1, "scene": 12, "line": "Oh, you think it's a choice?", "order": 20739, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 1, "scene": 12, "line": "Um, I'm gonna head back to my work area and just--", "order": 20740, "speaker": "Holly Flax"},
  {"season": 5, "episode": 1, "scene": 12, "line": "I'm messing with you, Holly.", "order": 20741, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 1, "scene": 12, "line": "I knew that. OK, bye.", "order": 20742, "speaker": "Holly Flax"},
  {"season": 5, "episode": 1, "scene": 12, "line": "OK.", "order": 20743, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 1, "scene": 13, "line": "So you have the directions.", "order": 20744, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 1, "scene": 13, "line": "Yes.", "order": 20745, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 1, "scene": 13, "line": "You have a toothbrush.", "order": 20746, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 1, "scene": 13, "line": "Yes.", "order": 20747, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 1, "scene": 13, "line": "You have a cell phone charger.", "order": 20748, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 1, "scene": 13, "line": "I have everything.", "order": 20749, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 1, "scene": 14, "line": "Tomorrow I start a three-month design program at the Pratt Institute in New York. I will be a little fish in the big apple. [does hand gesture] What up, 212?", "order": 20750, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 1, "scene": 15, "line": "Fax this for me.", "order": 20751, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 1, "scene": 15, "line": "Come on, man. She goes to New York in like 10 minutes.", "order": 20752, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 1, "scene": 15, "line": "It's not gonna take her 10 minutes to fax it, Jim. [to Pam] If I don't see you again, goodbye. Well, actually, I'll see you when you give me the fax confirmation, so never mind.", "order": 20753, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 1, "scene": 16, "line": "Ang. Ela. [sings] Ella, ella, ella. Under my Angerela. Ella, ella, ella. Ay, ay--", "order": 20754, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 1, "scene": 16, "line": "What?", "order": 20755, "speaker": "Angela Martin"},
  {"season": 5, "episode": 1, "scene": 16, "line": "Hey, check it out. [hands her brochure] This is The Breakers, Newport, Rhode Island. Huge, awesome gorgeous mansion overlooking the Atlantic. And my dad went to Cornell with the current groundskeeper.", "order": 20756, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 1, "scene": 17, "line": "Every little boy fantasizes about his fairy-tale wedding.", "order": 20757, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 1, "scene": 18, "line": "No. No.", "order": 20758, "speaker": "Angela Martin"},
  {"season": 5, "episode": 1, "scene": 18, "line": "This is where my parents decided not to get divorced.", "order": 20759, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 1, "scene": 18, "line": "I don't care.", "order": 20760, "speaker": "Angela Martin"},
  {"season": 5, "episode": 1, "scene": 18, "line": "OK. How do you feel about Maine? [Angela picks up phone and dials] I'm on it. I'll see you later, love. [Dwight's pager vibrates, he and Angela walk out]", "order": 20761, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 1, "scene": 19, "line": "[exiting warehouse storage room] Ahh. We done good in there, half pint.", "order": 20762, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 1, "scene": 19, "line": "Well, that was the last time, Dwight. I mean it.", "order": 20763, "speaker": "Angela Martin"},
  {"season": 5, "episode": 1, "scene": 19, "line": "Monkey. [kisses her]", "order": 20764, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 1, "scene": 19, "line": "No, no. I have a fiance I very much like!", "order": 20765, "speaker": "Angela Martin"},
  {"season": 5, "episode": 1, "scene": 20, "line": "[sees Pam and Jim through office window, runs out the office] Hey, hey! [falls down stairs, gets up, falls down again] Pam, Pam! Hey!", "order": 20766, "speaker": "Michael Scott"},
  {"season": 5, "episode": 1, "scene": 20, "line": "What happened?", "order": 20767, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 1, "scene": 20, "line": "[frazzled and out of breath] I wrote you a goodbye poem. It's really long. I left it up in my office. Could you just please wait till I go get it?", "order": 20768, "speaker": "Michael Scott"},
  {"season": 5, "episode": 1, "scene": 20, "line": "I should really get going.", "order": 20769, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 1, "scene": 20, "line": "No. OK. Um, the last word is 'seagulls.'", "order": 20770, "speaker": "Michael Scott"},
  {"season": 5, "episode": 1, "scene": 20, "line": "I'm sure it was really lovely.", "order": 20771, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 1, "scene": 20, "line": "I took a lot from other poems.", "order": 20772, "speaker": "Michael Scott"},
  {"season": 5, "episode": 1, "scene": 20, "line": "Call me when you get in. [kisses Pam]", "order": 20773, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 1, "scene": 20, "line": "OK, bye.", "order": 20774, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 1, "scene": 20, "line": "Bye.", "order": 20775, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 1, "scene": 20, "line": "All right, call-- [leans in to kiss Pam]", "order": 20776, "speaker": "Michael Scott"},
  {"season": 5, "episode": 1, "scene": 20, "line": "No, Michael.", "order": 20777, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 1, "scene": 21, "line": "Why haven't I proposed yet? Uh, actually Pam and I talked about it. And we just decided that, um -- well, we didn't want to spend first three months of our engagement apart. And Pam's always said she doesn't want a long engagement. Something in her past, I guess. I'm not really sure of the whole story, but something about a guy who used to work here...", "order": 20778, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 1, "scene": 22, "line": "OK, no, no, no, no, no, no. Same places as last week.", "order": 20779, "speaker": "Michael Scott"},
  {"season": 5, "episode": 1, "scene": 22, "line": "It doesn't matter, Michael.", "order": 20780, "speaker": "Angela Martin"},
  {"season": 5, "episode": 1, "scene": 22, "line": "Yes, it does. No, this is about weight displacement. We have to have the same weight distribution. Right here. Here we go. Let's move that up. Here we go.", "order": 20781, "speaker": "Michael Scott"},
  {"season": 5, "episode": 1, "scene": 22, "line": "Oh, fancy meeting you here.", "order": 20782, "speaker": "Holly Flax"},
  {"season": 5, "episode": 1, "scene": 22, "line": "All right.", "order": 20783, "speaker": "Michael Scott"},
  {"season": 5, "episode": 1, "scene": 22, "line": "OK, everybody smile. Ho! You lost 31 pounds.", "order": 20784, "speaker": "Darryl Philbin"},
  {"season": 5, "episode": 1, "scene": 22, "line": "All right!", "order": 20785, "speaker": "Michael Scott"},
  {"season": 5, "episode": 1, "scene": 23, "line": "I can't believe I'm saying this, but Michael is actually killing it with Holly. And I think I know why. It's because Holly is kind of a major dork.", "order": 20786, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 1, "scene": 24, "line": "[rapping] I'm MC Mike Scott, and I am hot. She's DJ Jazzy Flax, and she is the best. All those sucker branches can suck our fat!", "order": 20787, "speaker": "Michael Scott"},
  {"season": 5, "episode": 1, "scene": 24, "line": "Wikka wikka wikka what?", "order": 20788, "speaker": "Holly Flax"},
  {"season": 5, "episode": 1, "scene": 25, "line": "Dunder Mifflin, this is Ronnie.", "order": 20789, "speaker": "Ronnie"},
  {"season": 5, "episode": 1, "scene": 26, "line": "I don't really know Ronnie. But I have a feeling I will get to know her very well over the next few years, and eventually declare my love for her.", "order": 20790, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 1, "scene": 27, "line": "Sorry I'm late. I accidentally switched my alarm clock setting to Zapf Chancery. [laughter] Which is my terrible segue into our course, Expressive Typography in New Media.", "order": 20791, "speaker": "Professor"},
  {"season": 5, "episode": 1, "scene": 27, "line": "I'm in the wrong class...", "order": 20792, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 1, "scene": 27, "line": "Some of you may remember from last semester I am Professor Monaghan. [sees Pam leaving] And looks like I'm boring someone already.", "order": 20793, "speaker": "Professor"},
  {"season": 5, "episode": 1, "scene": 27, "line": "Oh, no. I just--", "order": 20794, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 1, "scene": 27, "line": "Please sit down.", "order": 20795, "speaker": "Professor"},
  {"season": 5, "episode": 1, "scene": 28, "line": "You're shaking. Are you all right?", "order": 20796, "speaker": "Holly Flax"},
  {"season": 5, "episode": 1, "scene": 28, "line": "Just leave me alone!", "order": 20797, "speaker": "Kelly Kapoor"},
  {"season": 5, "episode": 1, "scene": 29, "line": "[sickly] I am on the third day of my cleanse diet. All I have to do is drink maple syrup, lemon juice, cayenne pepper and water for all three meals. Um, I just bought some bikinis online, size 2. So ... gonna look amazing.", "order": 20798, "speaker": "Kelly Kapoor"},
  {"season": 5, "episode": 1, "scene": 30, "line": "Hi, can I help you?", "order": 20799, "speaker": "Ronnie"},
  {"season": 5, "episode": 1, "scene": 30, "line": "I need you to make me 100 copies of this on canary yellow.", "order": 20800, "speaker": "Jan Levinson"},
  {"season": 5, "episode": 1, "scene": 30, "line": "Hi Jan.", "order": 20801, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 1, "scene": 30, "line": "Hi.", "order": 20802, "speaker": "Jan Levinson"},
  {"season": 5, "episode": 1, "scene": 30, "line": "How's the candle game?", "order": 20803, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 1, "scene": 30, "line": "Oh, great. Yeah, Serenity by Jan is kicking ass and taking names. You remember last week when that girl went missing? Guess whose candles they used for the vigil?", "order": 20804, "speaker": "Jan Levinson"},
  {"season": 5, "episode": 1, "scene": 30, "line": "Cool. Thank God they found her, too.", "order": 20805, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 1, "scene": 30, "line": "Oh, they found her?", "order": 20806, "speaker": "Jan Levinson"},
  {"season": 5, "episode": 1, "scene": 31, "line": "Hi, Jan. Hello, baby. How are you? Good to see you. Who's your daddy? You don't know who your daddy is, do you? No, you don't. [to Jan] Come on. Let's go in.", "order": 20807, "speaker": "Michael Scott"},
  {"season": 5, "episode": 1, "scene": 32, "line": "When Michael told us that Jan was pregnant, he led us to believe that he was the father. By telling us that he was the father.", "order": 20808, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 1, "scene": 33, "line": "Hey, Oscar. Who's that woman in Michael's office with the feet?", "order": 20809, "speaker": "Holly Flax"},
  {"season": 5, "episode": 1, "scene": 33, "line": "That's his ex.", "order": 20810, "speaker": "Jan Levinson"},
  {"season": 5, "episode": 1, "scene": 33, "line": "Oh, she's very beautiful.", "order": 20811, "speaker": "Holly Flax"},
  {"season": 5, "episode": 1, "scene": 33, "line": "Yes, she is. And clinically insane.", "order": 20812, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 1, "scene": 33, "line": "So, listen, I don't know if your offer still stands or whatever, but you can give your yoga teacher my number.", "order": 20813, "speaker": "Holly Flax"},
  {"season": 5, "episode": 1, "scene": 33, "line": "OK.", "order": 20814, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 1, "scene": 34, "line": "Listen, dummy! It's not that hard. All you have to do is take the numbers from the sales report and type them into a master spreadsheet. A G.D. monkey could do it. I do not understand why you can't do it.", "order": 20815, "speaker": "Angela Martin"},
  {"season": 5, "episode": 1, "scene": 34, "line": "No! You do not talk to him like that!", "order": 20816, "speaker": "Holly Flax"},
  {"season": 5, "episode": 1, "scene": 34, "line": "But he's an idiot!", "order": 20817, "speaker": "Angela Martin"},
  {"season": 5, "episode": 1, "scene": 34, "line": "Hey!", "order": 20818, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 1, "scene": 34, "line": "He is not an idiot!", "order": 20819, "speaker": "Holly Flax"},
  {"season": 5, "episode": 1, "scene": 34, "line": "Thank you, Holly.", "order": 20820, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 1, "scene": 34, "line": "He is mentally challenged. But he's doing a super job here.", "order": 20821, "speaker": "Holly Flax"},
  {"season": 5, "episode": 1, "scene": 34, "line": "Wait, back up. Do you think that I am retarded?", "order": 20822, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 1, "scene": 34, "line": "Well, no. Dwight...", "order": 20823, "speaker": "Holly Flax"},
  {"season": 5, "episode": 1, "scene": 34, "line": "Oh, Holly, that is very offensive.", "order": 20824, "speaker": "Angela Martin"},
  {"season": 5, "episode": 1, "scene": 34, "line": "I'm sorry.", "order": 20825, "speaker": "Holly Flax"},
  {"season": 5, "episode": 1, "scene": 35, "line": "Hey. So, since my little lady has such particular, impossible to perceive taste, I have made nonrefundable deposits at four totally different wedding locales: Hot-air balloons over Napa Valley, world-famous Walt Disney's Epcot Center, scuba-diving wedding in the Bahamas and the Wilkes-Barre Marriott Ballroom C.", "order": 20826, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 1, "scene": 35, "line": "Dangerous. Tacky. Sharks. Haunted. No.", "order": 20827, "speaker": "Angela Martin"},
  {"season": 5, "episode": 1, "scene": 36, "line": "[walking into storage room] Hurry, we have to make this fast.", "order": 20828, "speaker": "Angela Martin"},
  {"season": 5, "episode": 1, "scene": 36, "line": "Let's do this thing.", "order": 20829, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 1, "scene": 36, "line": "[Dwight slaps her butt] Hey!", "order": 20830, "speaker": "Angela Martin"},
  {"season": 5, "episode": 1, "scene": 37, "line": "I have a nice comforter and several cozy pillows. I usually read a chapter of a book, and it's lights out by 8:30. That's how I sleep at night!", "order": 20831, "speaker": "Angela Martin"},
  {"season": 5, "episode": 1, "scene": 38, "line": "All right, I am connecting and ... you are not there. Did you install it right? How about this: Go to applications--", "order": 20832, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 1, "scene": 38, "line": "[on computer screen] I can see you!", "order": 20833, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 1, "scene": 38, "line": "All right!", "order": 20834, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 1, "scene": 38, "line": "This is so cool.", "order": 20835, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 1, "scene": 38, "line": "So what is going on, girl in the computer?", "order": 20836, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 1, "scene": 38, "line": "Lunch with a girl from my hall.", "order": 20837, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 1, "scene": 38, "line": "Uh-huh.", "order": 20838, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 1, "scene": 38, "line": "Ceramics class. Then dinner by myself. I'm gonna watch HGTV and maybe go to Bungalow 8 with John Mayer.", "order": 20839, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 1, "scene": 38, "line": "OK, can you turn the girl off please? I'm trying to get some work done.", "order": 20840, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 1, "scene": 38, "line": "No way. No way. Pam Beesley. Hello. [picks up computer] Hey, everybody look, Pam. Pam, say hi. Say hi to Pam. Don't we all look skinny? Oh, oh, almost dropped you!", "order": 20841, "speaker": "Michael Scott"},
  {"season": 5, "episode": 1, "scene": 38, "line": "Can you put me down I'm getting a little nauseous?", "order": 20842, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 1, "scene": 38, "line": "Pam, I'd like you to meet Ronnie. We call her Rice-a-Ronnie. She is hilarious. She is wonderful, a beam of light in this dark, dark office. Not really so much. All she does is plop herself down there and answer phones all day.", "order": 20843, "speaker": "Michael Scott"},
  {"season": 5, "episode": 1, "scene": 38, "line": "The nerve.", "order": 20844, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 1, "scene": 38, "line": "Oh, calm down, weirdo. It's just a joke. She's such a weirdo! You know what? My real beef with her, though, Pam? She can't find those little colored paper clips that I like so much. Will you help her find them, please?", "order": 20845, "speaker": "Michael Scott"},
  {"season": 5, "episode": 1, "scene": 38, "line": "They're on the supply shelf.", "order": 20846, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 1, "scene": 38, "line": "Let's go. We are following Pam to the supply shelf. Let us go.", "order": 20847, "speaker": "Michael Scott"},
  {"season": 5, "episode": 1, "scene": 39, "line": "New York is so exciting. I love my classes. The city's awesome. Um, can you give me back to Jim now, please?", "order": 20848, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 1, "scene": 40, "line": "OK, everybody, let's suck in our guts.", "order": 20849, "speaker": "Michael Scott"},
  {"season": 5, "episode": 1, "scene": 40, "line": "2,184 pounds, for a grand total -- oh! You all gained five pounds back.", "order": 20850, "speaker": "Darryl Philbin"},
  {"season": 5, "episode": 1, "scene": 40, "line": "Damn it. Come on.", "order": 20851, "speaker": "Michael Scott"},
  {"season": 5, "episode": 1, "scene": 40, "line": "[Kelly faints] Oh!", "order": 20852, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 1, "scene": 40, "line": "Oh, my God!", "order": 20853, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 1, "scene": 41, "line": "Come on, Bernard. [Andy spits on floor as warehouse worker walks past]", "order": 20854, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 1, "scene": 41, "line": "Here we go. Everybody on.", "order": 20855, "speaker": "Michael Scott"},
  {"season": 5, "episode": 1, "scene": 41, "line": "All right, we're set.", "order": 20856, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 1, "scene": 41, "line": "Still good. You guys lost a pound. You lost one pound.", "order": 20857, "speaker": "Darryl Philbin"},
  {"season": 5, "episode": 1, "scene": 41, "line": "I was on an IV for two days at the hospital.", "order": 20858, "speaker": "Kelly Kapoor"},
  {"season": 5, "episode": 1, "scene": 42, "line": "I lost four pounds. I don't know what those other fools were doing, but I lost four pounds.", "order": 20859, "speaker": "Stanley Hudson"},
  {"season": 5, "episode": 1, "scene": 43, "line": "Angela, you'll go to the supermarket and get the New York cheesecake. Make sure it's the generic one. It's Stanley's favorite.", "order": 20860, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 1, "scene": 44, "line": "Yes, I've replaced Angela as head of the party planning committee. [footage of Dwight and Angela making out] I guess I was just in the right place at the right time.", "order": 20861, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 1, "scene": 45, "line": "How's it going?", "order": 20862, "speaker": "Michael Scott"},
  {"season": 5, "episode": 1, "scene": 45, "line": "It's going well.", "order": 20863, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 1, "scene": 45, "line": "Good.", "order": 20864, "speaker": "Michael Scott"},
  {"season": 5, "episode": 1, "scene": 45, "line": "I have a crazy idea. What if we did, um, fruit instead of cake?", "order": 20865, "speaker": "Holly Flax"},
  {"season": 5, "episode": 1, "scene": 45, "line": "Cake? Who suggested cake? [Angela points to Phyllis]", "order": 20866, "speaker": "Michael Scott"},
  {"season": 5, "episode": 1, "scene": 45, "line": "We lost weight today. I think it's OK to reward ourselves once in a while.", "order": 20867, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 1, "scene": 45, "line": "Are you kidding me? We only lost one pound, Phyllis.", "order": 20868, "speaker": "Michael Scott"},
  {"season": 5, "episode": 1, "scene": 46, "line": "What is wrong with these people? They have no willpower. I went -- I once went 28 years without having sex. And then again for seven years.", "order": 20869, "speaker": "Michael Scott"},
  {"season": 5, "episode": 1, "scene": 47, "line": "Does anyone have any idea what the number one cause of death is in this country?", "order": 20870, "speaker": "Michael Scott"},
  {"season": 5, "episode": 1, "scene": 47, "line": "Shotgun weddings.", "order": 20871, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 1, "scene": 47, "line": "That's not what that is.", "order": 20872, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 1, "scene": 47, "line": "Fright. Being scared to death.", "order": 20873, "speaker": "Creed Bratton"},
  {"season": 5, "episode": 1, "scene": 47, "line": "No.", "order": 20874, "speaker": "Michael Scott"},
  {"season": 5, "episode": 1, "scene": 47, "line": "Obesity-caused illnesses.", "order": 20875, "speaker": "Holly Flax"},
  {"season": 5, "episode": 1, "scene": 47, "line": "Obesity-caused illnesses.", "order": 20876, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 1, "scene": 47, "line": "Thank you. Holly is right. People, this is not just about winning some extra vacation days. This is about a very cool HR initiative that if we don't follow, we are all going to die of obesity. So how much are we gonna lose?", "order": 20877, "speaker": "Michael Scott"},
  {"season": 5, "episode": 1, "scene": 47, "line": "I would like to lose 65 pounds.", "order": 20878, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 1, "scene": 47, "line": "Yes, all right. Who else? [to Angela] Can I put you down for 10 pounds?", "order": 20879, "speaker": "Michael Scott"},
  {"season": 5, "episode": 1, "scene": 47, "line": "No. My doctor wants me to gain weight.", "order": 20880, "speaker": "Angela Martin"},
  {"season": 5, "episode": 1, "scene": 47, "line": "If you gain weight, you will die. I want you to live forever. I want us all to live forever. How? How are we going to do this?", "order": 20881, "speaker": "Michael Scott"},
  {"season": 5, "episode": 1, "scene": 47, "line": "Cryogenics. Beer me five.", "order": 20882, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 1, "scene": 47, "line": "This is how we're gonna do it. Five pounds. I'm asking each of you to lose five pounds. That plus Jim's 65 will give us a very good chance at winning this thing.", "order": 20883, "speaker": "Michael Scott"},
  {"season": 5, "episode": 1, "scene": 48, "line": "All right, I'm taking off.", "order": 20884, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 1, "scene": 48, "line": "Booty call.", "order": 20885, "speaker": "Michael Scott"},
  {"season": 5, "episode": 1, "scene": 48, "line": "Nope, just going to see Pam.", "order": 20886, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 1, "scene": 48, "line": "Here.", "order": 20887, "speaker": "Michael Scott"},
  {"season": 5, "episode": 1, "scene": 48, "line": "Maybe put up some shelves.", "order": 20888, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 1, "scene": 48, "line": "Wait a second. [removes condom from wallet, throws it to Jim] I don't want you to end up with a surprise pregnancy like me.", "order": 20889, "speaker": "Michael Scott"},
  {"season": 5, "episode": 1, "scene": 48, "line": "OK, thank you for ... this.", "order": 20890, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 1, "scene": 48, "line": "You want more?", "order": 20891, "speaker": "Michael Scott"},
  {"season": 5, "episode": 1, "scene": 48, "line": "No.", "order": 20892, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 1, "scene": 49, "line": "I don't know if there's any one place that has all these things.", "order": 20893, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 1, "scene": 49, "line": "It's not my problem. [dials phone]", "order": 20894, "speaker": "Angela Martin"},
  {"season": 5, "episode": 1, "scene": 49, "line": "Thousand-year-old church in the continental United States. There has to be a rainbow. 24-hour veterinarian on call.", "order": 20895, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 1, "scene": 49, "line": "That is very important to me, so -- I have work to do. Just do it. [walks away]", "order": 20896, "speaker": "Angela Martin"},
  {"season": 5, "episode": 1, "scene": 49, "line": "Sweetheart. [Angela turns around] Just so you know, I don't care where we get married. I'll marry you right here in this building. I'll marry you in the parking lot. I'll marry you in the eye of a hurricane in the middle of a snowstorm on top of a monsoon. All I care about is that we're gonna spend the rest of our lives together.", "order": 20897, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 1, "scene": 49, "line": "[Angela kisses him] Hey-oh!", "order": 20898, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 1, "scene": 49, "line": "I know I haven't made this easy on you. [Dwight comes out of storage room alone, looks at watch.]", "order": 20899, "speaker": "Angela Martin"},
  {"season": 5, "episode": 1, "scene": 50, "line": "[knocks on door] Hi.", "order": 20900, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 1, "scene": 50, "line": "Hi. [they kiss] Mmm. 'Cause-- [looks toward camera]", "order": 20901, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 1, "scene": 50, "line": "Oh, that's so rude of me. Have you seen, um, Pam's new art? It's right there. Check it out. [closes door]", "order": 20902, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 1, "scene": 51, "line": "[music playing] It's a pretty lame party.", "order": 20903, "speaker": "Michael Scott"},
  {"season": 5, "episode": 1, "scene": 51, "line": "Does anyone want to dance?", "order": 20904, "speaker": "Ronnie"},
  {"season": 5, "episode": 1, "scene": 52, "line": "[watching baseball game on TV] Some girl came into Pam's room crying about her roommate stealing her soy milk. So I'm in here waiting it out.", "order": 20905, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 1, "scene": 53, "line": "Stanley, Michael's not here. You can eat a slice of your birthday cake.", "order": 20906, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 1, "scene": 53, "line": "I don't like cake anymore", "order": 20907, "speaker": "Stanley Hudson"},
  {"season": 5, "episode": 1, "scene": 53, "line": "Why not, dawg?", "order": 20908, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 1, "scene": 53, "line": "Tastes changed. Now all I like is baklava.", "order": 20909, "speaker": "Stanley Hudson"},
  {"season": 5, "episode": 1, "scene": 54, "line": "Michael, we have a situation.", "order": 20910, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 1, "scene": 55, "line": "They should not be in that room.", "order": 20911, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 1, "scene": 55, "line": "That's so lame they didn't invite us.", "order": 20912, "speaker": "Holly Flax"},
  {"season": 5, "episode": 1, "scene": 55, "line": "You know what we should do? We should have a party and not invite them.", "order": 20913, "speaker": "Michael Scott"},
  {"season": 5, "episode": 1, "scene": 55, "line": "Yeah!", "order": 20914, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 1, "scene": 55, "line": "Let's go mini-golfing.", "order": 20915, "speaker": "Michael Scott"},
  {"season": 5, "episode": 1, "scene": 55, "line": "Hey, could I have a rain check on the mini-golf. I, uh, actually have a date tonight.", "order": 20916, "speaker": "Holly Flax"},
  {"season": 5, "episode": 1, "scene": 55, "line": "Uh...", "order": 20917, "speaker": "Michael Scott"},
  {"season": 5, "episode": 1, "scene": 55, "line": "It'll probably be a total disaster, but...", "order": 20918, "speaker": "Holly Flax"},
  {"season": 5, "episode": 1, "scene": 55, "line": "Um, I think you'll have fun. Men from Scranton are handsome, and they know how to show a woman a good time.", "order": 20919, "speaker": "Michael Scott"},
  {"season": 5, "episode": 1, "scene": 55, "line": "Not that guy who murdered his mother. He was not so handsome. Also Kevin.", "order": 20920, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 1, "scene": 55, "line": "You know what? We're pretty much done here today. Why don't you just take off early?", "order": 20921, "speaker": "Michael Scott"},
  {"season": 5, "episode": 1, "scene": 55, "line": "Really?", "order": 20922, "speaker": "Holly Flax"},
  {"season": 5, "episode": 1, "scene": 55, "line": "Yeah.", "order": 20923, "speaker": "Michael Scott"},
  {"season": 5, "episode": 1, "scene": 55, "line": "Oh, that's sweet. Wish me luck.", "order": 20924, "speaker": "Holly Flax"},
  {"season": 5, "episode": 1, "scene": 55, "line": "Good luck.", "order": 20925, "speaker": "Michael Scott"},
  {"season": 5, "episode": 1, "scene": 56, "line": "Oh, Holly doesn't need luck. Everyone that meets her instantly loves her.", "order": 20926, "speaker": "Michael Scott"},
  {"season": 5, "episode": 1, "scene": 57, "line": "Party's over. You are so busted!", "order": 20927, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 1, "scene": 57, "line": "OK, everybody. Phyllis, did I or did I not say fruit?", "order": 20928, "speaker": "Michael Scott"},
  {"season": 5, "episode": 1, "scene": 57, "line": "You said fruit, Michael.", "order": 20929, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 1, "scene": 57, "line": "And what is that?", "order": 20930, "speaker": "Michael Scott"},
  {"season": 5, "episode": 1, "scene": 57, "line": "Cake.", "order": 20931, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 1, "scene": 57, "line": "You know, we're gonna have to try a little harder if we are going to win this thing.", "order": 20932, "speaker": "Michael Scott"},
  {"season": 5, "episode": 1, "scene": 57, "line": "Happy Birthday, Stanley! [kicks cake onto Michael]", "order": 20933, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 1, "scene": 58, "line": "It's kind of a good news, bad news situation. The bad news is we're not doing very well, so we really had to starve ourselves this whole week. But the good news is that all the other branches are doing just as bad as we are, so corporate upped the prize to five days. So if we stay fat long enough, we may actually get a whole month off.", "order": 20934, "speaker": "Holly Flax"},
  {"season": 5, "episode": 1, "scene": 59, "line": "[spreading peanut butter on a bagel] What?", "order": 20935, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 1, "scene": 59, "line": "Oh nothing.", "order": 20936, "speaker": "Creed Bratton"},
  {"season": 5, "episode": 1, "scene": 59, "line": "It's just some of us are taking this really seriously.", "order": 20937, "speaker": "Kelly Kapoor"},
  {"season": 5, "episode": 1, "scene": 60, "line": "I swallowed a tapeworm last night. It's going to grow up to three feet inside of me and then it eats all my food so that I don't get fat. And then after three months I take some medicine and then I pass it. Creed sold it to me. It's from Mexico.", "order": 20938, "speaker": "Kelly Kapoor"},
  {"season": 5, "episode": 1, "scene": 61, "line": "That wasn't a tapeworm.", "order": 20939, "speaker": "Creed Bratton"},
  {"season": 5, "episode": 1, "scene": 62, "line": "[walks in the office] How's my favorite branch doing? [no one talks, everyone stares at Ryan] All right [sits at reception].", "order": 20940, "speaker": "Ryan Howard"},
  {"season": 5, "episode": 1, "scene": 63, "line": "Ronnie was bleh. Things were at an all time sad here. But then I got an e-mail from Ryan, that he was coming back to town. And I called the temp agency and I told them I will pay you any amount, just give me Ryan Howard. Give him to me. I want him. I need him.", "order": 20941, "speaker": "Michael Scott"},
  {"season": 5, "episode": 1, "scene": 64, "line": "And you got a goatee!", "order": 20942, "speaker": "Ryan Howard"},
  {"season": 5, "episode": 1, "scene": 64, "line": "I did!", "order": 20943, "speaker": "Michael Scott"},
  {"season": 5, "episode": 1, "scene": 64, "line": "Did you get that after you helped me move and you saw mine?", "order": 20944, "speaker": "Ryan Howard"},
  {"season": 5, "episode": 1, "scene": 64, "line": "Yes. Gooooo-tee!", "order": 20945, "speaker": "Michael Scott"},
  {"season": 5, "episode": 1, "scene": 64, "line": "Firrrrre-duh guy! [high fives Ryan]", "order": 20946, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 1, "scene": 64, "line": "Hey Kevin... [Kevin gives Ryan a noogie] That's really funny.", "order": 20947, "speaker": "Ryan Howard"},
  {"season": 5, "episode": 1, "scene": 64, "line": "Yeah.", "order": 20948, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 1, "scene": 64, "line": "It's great to see you Kev.", "order": 20949, "speaker": "Ryan Howard"},
  {"season": 5, "episode": 1, "scene": 64, "line": "You too.", "order": 20950, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 1, "scene": 65, "line": "I'm keeping a list... of everyone who wrongs me. So when I'm back on top, they'll be sorry. Kevin just made the list.", "order": 20951, "speaker": "Ryan Howard"},
  {"season": 5, "episode": 1, "scene": 66, "line": "Jim. I wanted to apologize... for how I treated you last year. I lost sight of myself and now that I've quit the rat race I've realized there's so much more to life than being the youngest VP in the company's history. I've even started volunteering. Giving back to the community.", "order": 20952, "speaker": "Ryan Howard"},
  {"season": 5, "episode": 1, "scene": 66, "line": "Well that's great. [shakes Ryan's hand] You're talking about your court ordered community service?", "order": 20953, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 1, "scene": 66, "line": "I don't need a judge to tell me to keep my community clean.", "order": 20954, "speaker": "Ryan Howard"},
  {"season": 5, "episode": 1, "scene": 66, "line": "But he did, right?", "order": 20955, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 1, "scene": 66, "line": "All right.", "order": 20956, "speaker": "Ryan Howard"},
  {"season": 5, "episode": 1, "scene": 66, "line": "All right.", "order": 20957, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 1, "scene": 67, "line": "[glares into the camera and writes Jim's name on his list]", "order": 20958, "speaker": "Ryan Howard"},
  {"season": 5, "episode": 1, "scene": 68, "line": "Well you lost zero pounds. [everyone grumbles] No change. On the bright side you gained zero pounds.", "order": 20959, "speaker": "Darryl Philbin"},
  {"season": 5, "episode": 1, "scene": 68, "line": "Hey, you know what? I can't do this by myself people! Kelly and I are the only ones who have either passed out or almost passed out. [flails his arms]", "order": 20960, "speaker": "Michael Scott"},
  {"season": 5, "episode": 1, "scene": 68, "line": "Damnit, I need these five days for my honeymoon! Who is slacking?", "order": 20961, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 1, "scene": 68, "line": "Ok, let's just all try and work harder, ok?", "order": 20962, "speaker": "Holly Flax"},
  {"season": 5, "episode": 1, "scene": 68, "line": "NO! I want names!", "order": 20963, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 1, "scene": 69, "line": "This is the hardest thing I've ever had to do in my entire life. I haven't had a very hard life.", "order": 20964, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 1, "scene": 70, "line": "So how was your date?", "order": 20965, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 1, "scene": 70, "line": "Eight point five. I got a red wine stain on my favorite shirt. But he's cute, right?", "order": 20966, "speaker": "Holly Flax"},
  {"season": 5, "episode": 1, "scene": 70, "line": "[groans painfully]", "order": 20967, "speaker": "Michael Scott"},
  {"season": 5, "episode": 1, "scene": 70, "line": "What's wrong?", "order": 20968, "speaker": "Holly Flax"},
  {"season": 5, "episode": 1, "scene": 70, "line": "[struggling to speak] Mmm--- mmm... Um...", "order": 20969, "speaker": "Michael Scott"},
  {"season": 5, "episode": 1, "scene": 71, "line": "Close the door. This is your fault.", "order": 20970, "speaker": "Michael Scott"},
  {"season": 5, "episode": 1, "scene": 71, "line": "Nope.", "order": 20971, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 1, "scene": 71, "line": "Your stupid friend zone.", "order": 20972, "speaker": "Michael Scott"},
  {"season": 5, "episode": 1, "scene": 71, "line": "Mmm.", "order": 20973, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 1, "scene": 71, "line": "I should have been lovers with her first and then friends. That was terrible advice. Terrible advice! You know my seduction method. I like to get in there and get my hands dirty! Wh--- You sabotaged me. You sabotaged me, man!", "order": 20974, "speaker": "Michael Scott"},
  {"season": 5, "episode": 1, "scene": 72, "line": "Attention! Attention! We only have a few weeks left. [looking at Phyllis] And most of you are just as fat as the day we began.", "order": 20975, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 1, "scene": 72, "line": "How much weight have you lost, Dwight?", "order": 20976, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 1, "scene": 72, "line": "I am hardly the problem, Jim.", "order": 20977, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 1, "scene": 72, "line": "No, you're definitely the problem.", "order": 20978, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 1, "scene": 72, "line": "This is what I'm gonna do. I'm gonna randomly select three names and these three people will get liposuction. Uhhhhh Stanley, Phyllis, Kevin. And you will be responsible for your own medical bills.", "order": 20979, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 1, "scene": 73, "line": "Kelly.", "order": 20980, "speaker": "Ryan Howard"},
  {"season": 5, "episode": 1, "scene": 73, "line": "Oh, hello Ryan. You look well.", "order": 20981, "speaker": "Kelly Kapoor"},
  {"season": 5, "episode": 1, "scene": 73, "line": "I wanted to say I'm sorry... for treating you bad the past couple years. I was in my mid twenties and I was goin' through a lot of stuff. I think I never fully processed 9/11. Um, I want you to know I've changed.", "order": 20982, "speaker": "Ryan Howard"},
  {"season": 5, "episode": 1, "scene": 73, "line": "Cool.", "order": 20983, "speaker": "Kelly Kapoor"},
  {"season": 5, "episode": 1, "scene": 73, "line": "We should get a drink later. Catch up. Pick me up around eight?", "order": 20984, "speaker": "Ryan Howard"},
  {"season": 5, "episode": 1, "scene": 73, "line": "Oh that is so sweet. Um, but I'm dating Darryl, still, and we're like crazy in love so...", "order": 20985, "speaker": "Kelly Kapoor"},
  {"season": 5, "episode": 1, "scene": 73, "line": "That--- I, um... cool. Well maybe I'll see you around.", "order": 20986, "speaker": "Ryan Howard"},
  {"season": 5, "episode": 1, "scene": 73, "line": "It's a small office.", "order": 20987, "speaker": "Kelly Kapoor"},
  {"season": 5, "episode": 1, "scene": 73, "line": "Yeah.", "order": 20988, "speaker": "Ryan Howard"},
  {"season": 5, "episode": 1, "scene": 74, "line": "Hello Phyllis. Do you have a minute? Listen. I'm really sorry... about what I said before. Ok? I was way out of line.", "order": 20989, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 1, "scene": 74, "line": "Yes you were.", "order": 20990, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 1, "scene": 74, "line": "But you know what, I want to make it up to you. There is a sure fire sale, but it's a two man job. Interested?", "order": 20991, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 1, "scene": 74, "line": "Split the commission?", "order": 20992, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 1, "scene": 74, "line": "[sighs] Sixty - forty. Hm? [they shake hands] I'll drive.", "order": 20993, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 1, "scene": 75, "line": "[exhales, camera shows Kelly and Darryl making out on the reception couch, Kelly looking at Ryan]", "order": 20994, "speaker": "Ryan Howard"},
  {"season": 5, "episode": 1, "scene": 76, "line": "Dieting's only half of it.", "order": 20995, "speaker": "Holly Flax"},
  {"season": 5, "episode": 1, "scene": 76, "line": "Mmm-hmm. Saunas.", "order": 20996, "speaker": "Michael Scott"},
  {"season": 5, "episode": 1, "scene": 76, "line": "And exercise. Although I know everybody loves going to the gym.", "order": 20997, "speaker": "Holly Flax"},
  {"season": 5, "episode": 1, "scene": 76, "line": "Gah, the dreadmill. [both laugh]", "order": 20998, "speaker": "Michael Scott"},
  {"season": 5, "episode": 1, "scene": 77, "line": "Probably the weirdest thing about Pam being gone would be lunch... actually. But um, it will force me to become acquaintances with people I consider... coworkers.", "order": 20999, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 1, "scene": 78, "line": "Oh, my God. Ok. Everybody just act normal. Don't say anything.", "order": 21000, "speaker": "Friend"},
  {"season": 5, "episode": 1, "scene": 78, "line": "What?", "order": 21001, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 1, "scene": 78, "line": "My ex-girlfriend. She's right behind you. Just... hide me.", "order": 21002, "speaker": "Friend"},
  {"season": 5, "episode": 1, "scene": 78, "line": "Really, where?", "order": 21003, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 1, "scene": 78, "line": "No no! Don't look! [Pam sees an old lady behind him]", "order": 21004, "speaker": "Friend"},
  {"season": 5, "episode": 1, "scene": 78, "line": "Wow, was it the age difference?", "order": 21005, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 1, "scene": 78, "line": "Uh, actually, kind of. Yes. I just didn't like eating dinner that early. [they laugh, Pam's phone rings, she answers]", "order": 21006, "speaker": "Friend"},
  {"season": 5, "episode": 1, "scene": 78, "line": "Hey! Jim! Um, listen, can I call you back in a little bit? I made friends!", "order": 21007, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 1, "scene": 79, "line": "[storms in the office, sweaty and out of breath] Are you insane?!", "order": 21008, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 1, "scene": 79, "line": "Hey, hey!", "order": 21009, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 1, "scene": 79, "line": "Oh my God, what happened?", "order": 21010, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 1, "scene": 79, "line": "There was no client. Dwight drove me to an abandoned warehouse five miles away and pushed me out of the car. I had to walk home with no money and no phone.", "order": 21011, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 1, "scene": 79, "line": "And you burned over a thousand calories walking home. And this branch just got a little bit closer to winning the contest. Phyllis Vance, ladies and gentlemen! [claps]", "order": 21012, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 1, "scene": 79, "line": "You left me in a bad part of town!", "order": 21013, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 1, "scene": 79, "line": "Yeah, I took your purse. What are you worried about? [Phyllis storms to her desk, picks up phone and dials] You look great. I can definitely see the difference.", "order": 21014, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 1, "scene": 79, "line": "[into phone] Phyllis Vance for David Wallace.", "order": 21015, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 1, "scene": 80, "line": "...or that Dunder Mifflin does not discriminate. Nor does it condone unhealthy dieting habits or extreme weight loss strategies. As was clearly stated in the official starting---'", "order": 21016, "speaker": "Holly Flax"},
  {"season": 5, "episode": 1, "scene": 80, "line": "[in a fat suit, speaking in his Michael Clump voice] Ohhhh! Bang! Boom! Say Clump! Why hello everybody!", "order": 21017, "speaker": "Michael Scott"},
  {"season": 5, "episode": 1, "scene": 80, "line": "What are you doing?", "order": 21018, "speaker": "Holly Flax"},
  {"season": 5, "episode": 1, "scene": 80, "line": "Fair question.", "order": 21019, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 1, "scene": 80, "line": "I say, I say, I say, I say down Holly! I'll take it from here. I am beautiful. [hangs pictures of fat people on the wall, including two fat people on a bike, the Ghostbusters Marshmallow Man, Elvis, Martin Lawrence in Big Mamma's House, Jabba the Hutt, and a fat pig] They are beautiful. He is beautiful. This big fat pig is beautiful.", "order": 21020, "speaker": "Michael Scott"},
  {"season": 5, "episode": 1, "scene": 81, "line": "It's my sumo suit. I just didn't inflate it all the way. I am so glad that I bought instead of rented.", "order": 21021, "speaker": "Michael Scott"},
  {"season": 5, "episode": 1, "scene": 82, "line": "Body image. We are here because there is something wrong with society.", "order": 21022, "speaker": "Michael Scott"},
  {"season": 5, "episode": 1, "scene": 82, "line": "See, you're always saying there's something wrong with society, but... maybe there's something wrong with you.", "order": 21023, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 1, "scene": 82, "line": "If it's me, then society made me that way. Now, I know a lot of you are probably asking yourself, 'Why are you dressed in a plus sized suit?'", "order": 21024, "speaker": "Michael Scott"},
  {"season": 5, "episode": 1, "scene": 82, "line": "Because you're kind of doing Michael Clump.", "order": 21025, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 1, "scene": 82, "line": "How do you know Michael Clump?", "order": 21026, "speaker": "Michael Scott"},
  {"season": 5, "episode": 1, "scene": 82, "line": "Because it's your making-fun-of-fat-people character.", "order": 21027, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 1, "scene": 82, "line": "How dare you! Michael Clump is a celebration of fat people.", "order": 21028, "speaker": "Michael Scott"},
  {"season": 5, "episode": 1, "scene": 82, "line": "I think of him as more like... a monster? What about, 'I say, I say, I say, I'll sit on you!'", "order": 21029, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 1, "scene": 82, "line": "No, no, no, it goes, it goes... look, 'I say, I say, I say, I'll sit on you!'", "order": 21030, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 1, "scene": 82, "line": "No, no, no, NO, no! You know what? Fat people are not monsters! Why don't you guys have a little more sensitivity to the subject. Bang! Boom! Case in point! Look at the outside of this building. It is ugly. But you come inside... and it is beautiful. Just like this unappealing fat suit. Inside is a rather handsome, sexy, man.", "order": 21031, "speaker": "Michael Scott"},
  {"season": 5, "episode": 1, "scene": 82, "line": "Vamping.", "order": 21032, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 1, "scene": 82, "line": "Hey! Why don't you just sit down, please?", "order": 21033, "speaker": "Michael Scott"},
  {"season": 5, "episode": 1, "scene": 82, "line": "Eh, I've been sitting all day.", "order": 21034, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 1, "scene": 82, "line": "We're doing this because of you. So why don't you just go stand somewhere else. [to Ryan] Hey. Hey. You shaved?", "order": 21035, "speaker": "Michael Scott"},
  {"season": 5, "episode": 1, "scene": 82, "line": "I did.", "order": 21036, "speaker": "Ryan Howard"},
  {"season": 5, "episode": 1, "scene": 82, "line": "Why?", "order": 21037, "speaker": "Michael Scott"},
  {"season": 5, "episode": 1, "scene": 82, "line": "[at a loss for words]", "order": 21038, "speaker": "Ryan Howard"},
  {"season": 5, "episode": 1, "scene": 83, "line": "So, in the year 2000, fungus and even black mold was discovered growing in the cave. Some blame it on the new air conditioning system they installed. Some, on the high powered lights. [Pam's friend shows Pam a drawing mocking the professor, they laugh] Others feel that fungus is do to an over---", "order": 21039, "speaker": "Professor"},
  {"season": 5, "episode": 1, "scene": 83, "line": "[to friend] Nice.", "order": 21040, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 1, "scene": 83, "line": "Quiet please.", "order": 21041, "speaker": "Professor"},
  {"season": 5, "episode": 1, "scene": 83, "line": "Sorry.", "order": 21042, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 1, "scene": 83, "line": "Sorry.", "order": 21043, "speaker": "Friend"},
  {"season": 5, "episode": 1, "scene": 84, "line": "Dwight, I would like you to apologize to this beautiful, beautiful woman for forcing her to walk five miles, which for her is basically a death march.", "order": 21044, "speaker": "Michael Scott"},
  {"season": 5, "episode": 1, "scene": 84, "line": "She should thank me.", "order": 21045, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 1, "scene": 84, "line": "Ok Phyllis, thank Dwight first.", "order": 21046, "speaker": "Michael Scott"},
  {"season": 5, "episode": 1, "scene": 84, "line": "Why should I have to thank him?", "order": 21047, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 1, "scene": 84, "line": "You're right, you're right. Just, Dwight, do it. Just say it ok.", "order": 21048, "speaker": "Michael Scott"},
  {"season": 5, "episode": 1, "scene": 84, "line": "[exhales] I apologize for creating a ruse which forced you to exercise.", "order": 21049, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 1, "scene": 84, "line": "Finally. Right? Ok, Phyllis you must be exhausted from standing on those gams. Why don't you have a seat? [Phyllis sits] Let's all clap at Phyllis. [everyone claps] Ok! [in Michael Clump voice] When Michael Clump wants to remind you [normal voice] that corporate... their idea, yes, was to urge you to lose weight, but more importantly what this whole thing is about, this contest is really about being healthy. And in order to be healthy, you have to eat, Kelly. Kelly. [kneels down in front of Kelly, Dwight tries to help him down, Michael shouts gibberish at him] Kelly, I want you to stand up on your chair. Come on, stand up on your chair. Stand up, stand up, stand up. Somebody help her. Here we go. [Kelly stands up on her chair] Ok, I want you to look at her. She's a beautiful Indian woman. Why? What makes her beautiful?", "order": 21050, "speaker": "Michael Scott"},
  {"season": 5, "episode": 1, "scene": 84, "line": "[stands up] Everything. She's perfect.", "order": 21051, "speaker": "Ryan Howard"},
  {"season": 5, "episode": 1, "scene": 84, "line": "I like her nails.", "order": 21052, "speaker": "Meredith Palmer"},
  {"season": 5, "episode": 1, "scene": 84, "line": "Ok, be more specific.", "order": 21053, "speaker": "Michael Scott"},
  {"season": 5, "episode": 1, "scene": 84, "line": "I like her fingernails.", "order": 21054, "speaker": "Meredith Palmer"},
  {"season": 5, "episode": 1, "scene": 84, "line": "I like her purple dress.", "order": 21055, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 1, "scene": 84, "line": "What about her looks?", "order": 21056, "speaker": "Michael Scott"},
  {"season": 5, "episode": 1, "scene": 84, "line": "Hell of an ass.", "order": 21057, "speaker": "Creed Bratton"},
  {"season": 5, "episode": 1, "scene": 84, "line": "...what else do people like?", "order": 21058, "speaker": "Kelly Kapoor"},
  {"season": 5, "episode": 1, "scene": 85, "line": "I wonder what people like about me? ... Probably my jugs.", "order": 21059, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 1, "scene": 86, "line": "Kelly, will you just agree to stop this mess and stop trying to kill yourself?", "order": 21060, "speaker": "Michael Scott"},
  {"season": 5, "episode": 1, "scene": 86, "line": "I hate dieting, I hate it so much. I hate this worm inside of me!", "order": 21061, "speaker": "Kelly Kapoor"},
  {"season": 5, "episode": 1, "scene": 86, "line": "I want you to know. Kelly, look at me! I want you to know that you are beautiful. You are truly, truly beautiful. Give me a hug. [Michael hugs Kelly, his head in her chest] Mmmmmm.", "order": 21062, "speaker": "Michael Scott"},
  {"season": 5, "episode": 1, "scene": 87, "line": "Summers going great. Just spent two hours listening to Michael Clump. I have a weird pain in my left side that I'm convinced is an ulcer. My girlfriend lives in New York and I haven't seen her in 10 days. How's your summer?", "order": 21063, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 1, "scene": 88, "line": "Hey Ryan, look. Shaved off my goatee. I am goateeless. We are the goateeless brothers.", "order": 21064, "speaker": "Michael Scott"},
  {"season": 5, "episode": 1, "scene": 88, "line": "Oh...", "order": 21065, "speaker": "Ryan Howard"},
  {"season": 5, "episode": 1, "scene": 88, "line": "Ahaha, Yep! [Dwight looks down depressingly and rubs his new goatee]", "order": 21066, "speaker": "Michael Scott"},
  {"season": 5, "episode": 1, "scene": 88, "line": "How was Friday night?", "order": 21067, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 1, "scene": 88, "line": "Oh, I had a good time, but it's been three days and no call. I even have two tickets to see Counting Crows for tonight, so...", "order": 21068, "speaker": "Holly Flax"},
  {"season": 5, "episode": 1, "scene": 88, "line": "He'll call.", "order": 21069, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 1, "scene": 88, "line": "Oscar, enough with the girl talk please.", "order": 21070, "speaker": "Michael Scott"},
  {"season": 5, "episode": 1, "scene": 88, "line": "You guys lost four pounds.", "order": 21071, "speaker": "Darryl Philbin"},
  {"season": 5, "episode": 1, "scene": 88, "line": "Hey!", "order": 21072, "speaker": "Michael Scott"},
  {"season": 5, "episode": 1, "scene": 88, "line": "I say we have a parade.", "order": 21073, "speaker": "Darryl Philbin"},
  {"season": 5, "episode": 1, "scene": 88, "line": "We better.", "order": 21074, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 1, "scene": 88, "line": "Excellent! Good job.", "order": 21075, "speaker": "Michael Scott"},
  {"season": 5, "episode": 1, "scene": 89, "line": "So as it turns out, tonight is my nephews T-ball game and I kinda missed the first three, so...", "order": 21076, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 1, "scene": 89, "line": "You've already disappointed him so much. He can't be expecting a lot from you.", "order": 21077, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 1, "scene": 89, "line": "I know. I suck.", "order": 21078, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 1, "scene": 89, "line": "Yes, you do.", "order": 21079, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 1, "scene": 89, "line": "Dwight says 'Hi.'", "order": 21080, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 1, "scene": 89, "line": "I do not! I do not say 'Hi' Pam!", "order": 21081, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 1, "scene": 89, "line": "Now he is saying 'Hi' louder.", "order": 21082, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 1, "scene": 89, "line": "I... listen, umm, we're still good for this weekend, right?", "order": 21083, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 1, "scene": 89, "line": "Umm...", "order": 21084, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 1, "scene": 89, "line": "No football games, recitals, karate tournaments.", "order": 21085, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 1, "scene": 89, "line": "Hey, can you go to IM?", "order": 21086, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 1, "scene": 89, "line": "OK.", "order": 21087, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 1, "scene": 89, "line": "OK.", "order": 21088, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 1, "scene": 89, "line": "Let's meet for lunch.", "order": 21089, "speaker": "JIM9334"},
  {"season": 5, "episode": 1, "scene": 89, "line": "What are you writing about me?", "order": 21090, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 1, "scene": 89, "line": "What -- today?", "order": 21091, "speaker": "Receptionitis15"},
  {"season": 5, "episode": 1, "scene": 89, "line": "Yeah. C'mon we'll meet halfway. You'll be back for your 4 o'clock class.", "order": 21092, "speaker": "JIM9334"},
  {"season": 5, "episode": 1, "scene": 89, "line": "I'm gonna write you both up for not working.", "order": 21093, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 1, "scene": 89, "line": "I'm gonna write you up for not working.", "order": 21094, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 1, "scene": 89, "line": "Ok, well played. Neither of us'll write the other up for not working.", "order": 21095, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 1, "scene": 89, "line": "Where?", "order": 21096, "speaker": "Receptionitis15"},
  {"season": 5, "episode": 1, "scene": 89, "line": "The rest stop where that soda exploded on me. Exit 17, I think. 1 o'clock.", "order": 21097, "speaker": "JIM9334"},
  {"season": 5, "episode": 1, "scene": 89, "line": "Alright. See you there. : ) P.S. I finally finished my", "order": 21098, "speaker": "Receptionitis15"},
  {"season": 5, "episode": 1, "scene": 90, "line": "[holds up an ASCII image of Dwight]", "order": 21099, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 1, "scene": 91, "line": "Yeah, oh, hey. Did he call?", "order": 21100, "speaker": "Michael Scott"},
  {"season": 5, "episode": 1, "scene": 91, "line": "I just got off... Kendall? I just got off...", "order": 21101, "speaker": "Holly Flax"},
  {"season": 5, "episode": 1, "scene": 91, "line": "No no, did, I heard you talking to Oscar about that guy. What, what happened? Did he call?", "order": 21102, "speaker": "Michael Scott"},
  {"season": 5, "episode": 1, "scene": 91, "line": "Oh, no.", "order": 21103, "speaker": "Holly Flax"},
  {"season": 5, "episode": 1, "scene": 91, "line": "Really?", "order": 21104, "speaker": "Michael Scott"},
  {"season": 5, "episode": 1, "scene": 91, "line": "No.", "order": 21105, "speaker": "Holly Flax"},
  {"season": 5, "episode": 1, "scene": 91, "line": "Aach.", "order": 21106, "speaker": "Michael Scott"},
  {"season": 5, "episode": 1, "scene": 91, "line": "And you know what? I even got two tickets to see the Counting Crows tonight as a surprise. Why do guys not call when they say they are gonna call?", "order": 21107, "speaker": "Holly Flax"},
  {"season": 5, "episode": 1, "scene": 91, "line": "I dunno. I always call everybody back right away. He'll call back though. Give him until the end of the day, he'll call. If he doesn't, he is loco.", "order": 21108, "speaker": "Michael Scott"},
  {"season": 5, "episode": 1, "scene": 91, "line": "Thanks. That's sweet. Anyways, umm, seems like we lost to Utica by only eight pounds.", "order": 21109, "speaker": "Holly Flax"},
  {"season": 5, "episode": 1, "scene": 91, "line": "Ugh.", "order": 21110, "speaker": "Michael Scott"},
  {"season": 5, "episode": 1, "scene": 91, "line": "Well, at least everyone lost weight. That's all that really matters.", "order": 21111, "speaker": "Holly Flax"},
  {"season": 5, "episode": 1, "scene": 91, "line": "Wait, do we have until the end of the day?", "order": 21112, "speaker": "Michael Scott"},
  {"season": 5, "episode": 1, "scene": 92, "line": "So we're only eight pounds behind Utica. Now, I know it's a stretch but we could weigh ourselves again at the end of the day and maybe win this thing after all.", "order": 21113, "speaker": "Holly Flax"},
  {"season": 5, "episode": 1, "scene": 92, "line": "Leave it to me, boss. I can get this whole office to make weight. [turns up thermostat] It's gonna be a little toasty in here. Good old fashioned sweat lodge.", "order": 21114, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 1, "scene": 93, "line": "Andy Bernard does not lose contests. He wins them... or he quits them because they are unfair.", "order": 21115, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 1, "scene": 94, "line": "[Kelly eating in the break room] Hey, is that healthy food?", "order": 21116, "speaker": "Michael Scott"},
  {"season": 5, "episode": 1, "scene": 94, "line": "Nope.", "order": 21117, "speaker": "Kelly Kapoor"},
  {"season": 5, "episode": 1, "scene": 94, "line": "We're trying to win a contest here, Kelly. Please?", "order": 21118, "speaker": "Michael Scott"},
  {"season": 5, "episode": 1, "scene": 94, "line": "Hey, Michael. I just scheduled lunch with a client so I might be back a little late.", "order": 21119, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 1, "scene": 94, "line": "Ok, that is fine. Just water, and be back by weigh-in, ok?", "order": 21120, "speaker": "Michael Scott"},
  {"season": 5, "episode": 1, "scene": 94, "line": "Perfect.", "order": 21121, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 1, "scene": 95, "line": "[sprays the inside of the vending machine with bug spray]", "order": 21122, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 1, "scene": 96, "line": "I hate to even ask because you have been so accommodating about all the wedding stuff.", "order": 21123, "speaker": "Angela Martin"},
  {"season": 5, "episode": 1, "scene": 96, "line": "Your wish is my command.", "order": 21124, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 1, "scene": 96, "line": "Is there any way our first dance can be to my favorite song, The Little Drummer Boy?", "order": 21125, "speaker": "Angela Martin"},
  {"season": 5, "episode": 1, "scene": 96, "line": "Of course!", "order": 21126, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 1, "scene": 96, "line": "Really?", "order": 21127, "speaker": "Angela Martin"},
  {"season": 5, "episode": 1, "scene": 96, "line": "Yeah! I mean it's a great song. I always thought it was bigger than Christmas anyway. You know?", "order": 21128, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 1, "scene": 96, "line": "Mmhmm.", "order": 21129, "speaker": "Angela Martin"},
  {"season": 5, "episode": 1, "scene": 96, "line": "And you know what? The guys already know it so it's easy breezy.", "order": 21130, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 1, "scene": 96, "line": "The guys?", "order": 21131, "speaker": "Angela Martin"},
  {"season": 5, "episode": 1, "scene": 96, "line": "I didn't tell you.", "order": 21132, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 1, "scene": 96, "line": "Mm-mm", "order": 21133, "speaker": "Angela Martin"},
  {"season": 5, "episode": 1, "scene": 96, "line": "Here Comes Treble, class of '96 was available for our big day, so, I booked 'em.", "order": 21134, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 1, "scene": 96, "line": "I don't think that...", "order": 21135, "speaker": "Angela Martin"},
  {"season": 5, "episode": 1, "scene": 96, "line": "And, they are collectively my best man. And they are crashing with us for three weeks, so... it totally works out.", "order": 21136, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 1, "scene": 96, "line": "I don't know if I want your old college a capella group to be our wedding band.", "order": 21137, "speaker": "Angela Martin"},
  {"season": 5, "episode": 1, "scene": 96, "line": "Ok, I hear you, sweetheart, but this is a deal breaker. But you know what? You're gonna love these guys. Carl 1, Carl 2, Broccoli Rob, Spare Rib, Doobie, Lunch Box, Boner Champ, that's me, Pubie Lewis and the News, Hopscotch, Jingle Jangle, Sandwich. These are the best best-man a guy could ever hope for.", "order": 21138, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 1, "scene": 96, "line": "[Angela dials the phone] Let's talk about this later. [Dwight's pager buzzes]", "order": 21139, "speaker": "Angela Martin"},
  {"season": 5, "episode": 1, "scene": 96, "line": "Mm-wah! [Angela walks away, Dwight follows]", "order": 21140, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 1, "scene": 97, "line": "Hey! This is not half-way! I did the math. I had to drive way longer than you. Montclair would have been closer, so you have to buy lunch.", "order": 21141, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 1, "scene": 97, "line": "[Jim gets down on one knee]", "order": 21142, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 1, "scene": 97, "line": "What are you doing?", "order": 21143, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 1, "scene": 97, "line": "I just... couldn't wait.", "order": 21144, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 1, "scene": 97, "line": "Oh my God!", "order": 21145, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 1, "scene": 97, "line": "Pam, will you marry me?", "order": 21146, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 1, "scene": 97, "line": "Oh my God!", "order": 21147, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 1, "scene": 97, "line": "So?", "order": 21148, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 1, "scene": 97, "line": "Yes! [they embrace and kiss]", "order": 21149, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 1, "scene": 98, "line": "God. Where is Jim?", "order": 21150, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 1, "scene": 98, "line": "I'm calling it. I guess that's curtains for the yoga guy.", "order": 21151, "speaker": "Holly Flax"},
  {"season": 5, "episode": 1, "scene": 98, "line": "Aww, really?", "order": 21152, "speaker": "Michael Scott"},
  {"season": 5, "episode": 1, "scene": 98, "line": "Yeah.", "order": 21153, "speaker": "Holly Flax"},
  {"season": 5, "episode": 1, "scene": 98, "line": "I have to say, I think the problem with that whole thing...", "order": 21154, "speaker": "Michael Scott"},
  {"season": 5, "episode": 1, "scene": 98, "line": "Yeah?", "order": 21155, "speaker": "Holly Flax"},
  {"season": 5, "episode": 1, "scene": 98, "line": "...was that he was a complete stupid idiot.", "order": 21156, "speaker": "Michael Scott"},
  {"season": 5, "episode": 1, "scene": 98, "line": "Aww man, I really wanted to see them.", "order": 21157, "speaker": "Holly Flax"},
  {"season": 5, "episode": 1, "scene": 98, "line": "I know. I love Counting Crows.", "order": 21158, "speaker": "Michael Scott"},
  {"season": 5, "episode": 1, "scene": 98, "line": "Look at where my seats were.", "order": 21159, "speaker": "Holly Flax"},
  {"season": 5, "episode": 1, "scene": 98, "line": "Wow, really?", "order": 21160, "speaker": "Michael Scott"},
  {"season": 5, "episode": 1, "scene": 98, "line": "Yeah.", "order": 21161, "speaker": "Holly Flax"},
  {"season": 5, "episode": 1, "scene": 98, "line": "That's a shame. A shame to waste these. I want to buy them from you.", "order": 21162, "speaker": "Michael Scott"},
  {"season": 5, "episode": 1, "scene": 98, "line": "Michael, you don't have to buy them.", "order": 21163, "speaker": "Holly Flax"},
  {"season": 5, "episode": 1, "scene": 98, "line": "I do. No. I do. I, I want to. Trust me. I really do.", "order": 21164, "speaker": "Michael Scott"},
  {"season": 5, "episode": 1, "scene": 98, "line": "Ok.", "order": 21165, "speaker": "Holly Flax"},
  {"season": 5, "episode": 1, "scene": 98, "line": "Ok?", "order": 21166, "speaker": "Michael Scott"},
  {"season": 5, "episode": 1, "scene": 98, "line": "Yeah.", "order": 21167, "speaker": "Holly Flax"},
  {"season": 5, "episode": 1, "scene": 98, "line": "Alright. Alright! [tears up tickets] Just, I want you to just forget about him, ok? Case closed. Umm, can I pay you tomorrow? I have like a 60 dollar limit on my ATM, so...", "order": 21168, "speaker": "Michael Scott"},
  {"season": 5, "episode": 1, "scene": 98, "line": "Yeah.", "order": 21169, "speaker": "Holly Flax"},
  {"season": 5, "episode": 1, "scene": 98, "line": "Is that good?", "order": 21170, "speaker": "Michael Scott"},
  {"season": 5, "episode": 1, "scene": 98, "line": "Yeah.", "order": 21171, "speaker": "Holly Flax"},
  {"season": 5, "episode": 1, "scene": 98, "line": "Hey, sorry everybody.", "order": 21172, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 1, "scene": 98, "line": "What are you smiling about there smiley pants? You're late.", "order": 21173, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 1, "scene": 98, "line": "Wet Tuna!", "order": 21174, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 1, "scene": 98, "line": "Hey Dwight.", "order": 21175, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 1, "scene": 98, "line": "OK.", "order": 21176, "speaker": "Michael Scott"},
  {"season": 5, "episode": 1, "scene": 98, "line": "What's up, Meredith?", "order": 21177, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 1, "scene": 98, "line": "Nothing.", "order": 21178, "speaker": "Meredith Palmer"},
  {"season": 5, "episode": 1, "scene": 98, "line": "Dripping on me.", "order": 21179, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 1, "scene": 98, "line": "Darryl, would you do the honors?", "order": 21180, "speaker": "Michael Scott"},
  {"season": 5, "episode": 1, "scene": 98, "line": "Two thousand one hundred seventy-five. [everyone moans] Sorry guys.", "order": 21181, "speaker": "Darryl Philbin"},
  {"season": 5, "episode": 1, "scene": 98, "line": "Nonono, no, no, I don't want to hear moaning. This is a good day. You guys accomplished something big. You lost a ton of weight, literally. A lot of weight. And I don't care what any stupid scale says. You guys are all gigantic losers.", "order": 21182, "speaker": "Michael Scott"},
  {"season": 5, "episode": 1, "scene": 99, "line": "I don't know about anyone else. But it was a good summer for me. I lost seven pounds. And you know what? I'm just gonna take five days off anyway.", "order": 21183, "speaker": "Stanley Hudson"},
  {"season": 5, "episode": 1, "scene": 100, "line": "[in a hospital bed, watching Entourage in Spanish] Oh, I went zip lining my third day in Costa Rica. I guess the harness wasn't strapped in exactly right. I broke my neck. And, I've been in the hospital five weeks now. I still haven't seen the beach. It's nice to have visitors.", "order": 21184, "speaker": "Toby Flenderson"},
  {"season": 5, "episode": 1, "scene": 101, "line": "[to Holly] I'm doing the blood type diet.", "order": 21185, "speaker": "Kelly Kapoor"},
  {"season": 5, "episode": 1, "scene": 101, "line": "Who gives a f[bleep]?", "order": 21186, "speaker": "Meredith Palmer"},
  {"season": 5, "episode": 1, "scene": 101, "line": "What'd you say h[bleep]?", "order": 21187, "speaker": "Kelly Kapoor"},
  {"season": 5, "episode": 1, "scene": 101, "line": "Okay, great. I got it.", "order": 21188, "speaker": "Holly Flax"},
  {"season": 5, "episode": 1, "scene": 102, "line": "Look what just arrived from the Nashua branch. [reads from card attached to gift basket] 'Here's something to enjoy on your three non-vacation days. Nashua branch.' Can I send them a dead deer?", "order": 21189, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 1, "scene": 102, "line": "No, you know what we're gonna do? We're gonna take that and we are going to us it as a reward for the end of our competition.", "order": 21190, "speaker": "Michael Scott"},
  {"season": 5, "episode": 1, "scene": 102, "line": "Classy.", "order": 21191, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 1, "scene": 103, "line": "My dream vacation? I sleep in, putter around the farm, go fishing for a few hours, take a long run in the forest, check the traps. Then the sun comes up and I head into work, which is empty, because everyone else is out of town on vacation.", "order": 21192, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 1, "scene": 104, "line": "[seeing Kevin throw out bunch of M&Ms] Nice job, Kevin. [Kevin fills jar back up with another candy]", "order": 21193, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 1, "scene": 105, "line": "I'm pretty fit. But in the gay community, there's a lot of pressure to be ripped. I got straight abs. I want gay abs.", "order": 21194, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 1, "scene": 106, "line": "Can you turn the heat down now - it's oppressive.", "order": 21195, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 1, "scene": 106, "line": "Yeah, totally. I'm sorry, Oscar. Yeah, I didn't realize it was bothering you.", "order": 21196, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 1, "scene": 106, "line": "It's just...", "order": 21197, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 1, "scene": 106, "line": "Cause it's kind of bothering all of us, 'cause it's really hot, you know, it's hot, so we can lose weight, so we don't screw up this whole contest for the entire freaking office, but if you want, I'll turn it down.", "order": 21198, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 1, "scene": 107, "line": "Hello, hello. Hey! Your face is almost back to normal.", "order": 21199, "speaker": "Michael Scott"},
  {"season": 5, "episode": 1, "scene": 107, "line": "One more kid calls me 'Hellboy,' I swear to God...", "order": 21200, "speaker": "Meredith Palmer"},
  {"season": 5, "episode": 1, "scene": 107, "line": "Oh, you wish. Hellboy's a hero.", "order": 21201, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 1, "scene": 108, "line": "These guys took me out on their fishing boat. I don't think I caught anything.", "order": 21202, "speaker": "Meredith Palmer"},
  {"season": 5, "episode": 1, "scene": 109, "line": "Have you been to the beach yet?", "order": 21203, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 1, "scene": 109, "line": "[on the computer] No, but I'm going zip lining tomorrow, through the, through the rainforest, with this cool couple I, I met at my hotel. Wo-woman's amazing...", "order": 21204, "speaker": "Toby Flenderson"},
  {"season": 5, "episode": 1, "scene": 109, "line": "Hey, Oscar! Come on, let's go!", "order": 21205, "speaker": "Michael Scott"},
  {"season": 5, "episode": 1, "scene": 109, "line": "Okay, Toby, I gotta go.", "order": 21206, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 1, "scene": 109, "line": "Hey, is that Michael? Does he miss me? [laughs] Uh, Oscar? Are you there? Hello? [sighs]", "order": 21207, "speaker": "Toby Flenderson"},
  {"season": 5, "episode": 1, "scene": 110, "line": "I am suspending you without pay for the rest of the year.", "order": 21208, "speaker": "Michael Scott"},
  {"season": 5, "episode": 1, "scene": 110, "line": "No, you're not.", "order": 21209, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 1, "scene": 110, "line": "Okay, no, I am not. Dwight! [Dwight runs into Michael's office] I want you to take Jim's chair. No! No! His chair at his desk. He gets it back on Friday.", "order": 21210, "speaker": "Michael Scott"},
  {"season": 5, "episode": 1, "scene": 110, "line": "All right!", "order": 21211, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 1, "scene": 110, "line": "I want you to get your ass out of my face.", "order": 21212, "speaker": "Michael Scott"},
  {"season": 5, "episode": 1, "scene": 111, "line": "[sitting on a stack of paper] Yeah, well, if you're only free till three on Sunday and I can't get there till one, then it's gonna be pretty tight.", "order": 21213, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 1, "scene": 111, "line": "[from his office] That's what she said.", "order": 21214, "speaker": "Michael Scott"},
  {"season": 5, "episode": 1, "scene": 111, "line": "[as Dwight tries to take paper out from under him] Hey, can you not?", "order": 21215, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 1, "scene": 111, "line": "Can you not? No sitting devices, Michael's rules.", "order": 21216, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 1, "scene": 111, "line": "No, no, no, definitely, definitely. Yes. Next weekend, then. All right! I love you, too.", "order": 21217, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 1, "scene": 111, "line": "Gah! [tries to dislodge paper with his feet]", "order": 21218, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 1, "scene": 112, "line": "Hey, um, my daughter said you traded her a headband for her Nintendo DS?", "order": 21219, "speaker": "Darryl Philbin"},
  {"season": 5, "episode": 1, "scene": 112, "line": "Oh yeah, she loves that headband.", "order": 21220, "speaker": "Kelly Kapoor"},
  {"season": 5, "episode": 1, "scene": 112, "line": "She's six.", "order": 21221, "speaker": "Darryl Philbin"},
  {"season": 5, "episode": 1, "scene": 112, "line": "She manipulates you.", "order": 21222, "speaker": "Kelly Kapoor"},
  {"season": 5, "episode": 1, "scene": 112, "line": "Okay, I'm gonna need you to make this right or else we can't be right.", "order": 21223, "speaker": "Darryl Philbin"},
  {"season": 5, "episode": 1, "scene": 112, "line": "I never know when you're kidding.", "order": 21224, "speaker": "Kelly Kapoor"},
  {"season": 5, "episode": 1, "scene": 113, "line": "I can't feel... I can't feel anything that you're doing, Michael.", "order": 21225, "speaker": "Jan Levinson"},
  {"season": 5, "episode": 1, "scene": 113, "line": "Well...", "order": 21226, "speaker": "Michael Scott"},
  {"season": 5, "episode": 1, "scene": 113, "line": "It's like nothing's happening.", "order": 21227, "speaker": "Jan Levinson"},
  {"season": 5, "episode": 1, "scene": 113, "line": "You're...you know what? I read in one of those books that you're not even supposed to give foot massages because it can induce labor.", "order": 21228, "speaker": "Michael Scott"},
  {"season": 5, "episode": 1, "scene": 113, "line": "Oh, just stop making excuses and just dig in there.", "order": 21229, "speaker": "Jan Levinson"},
  {"season": 5, "episode": 1, "scene": 113, "line": "I'm digging.", "order": 21230, "speaker": "Michael Scott"},
  {"season": 5, "episode": 1, "scene": 114, "line": "When Michael told us that Jan was pregnant, he led us all to believe that he was the father... by telling us he was the father. But then we got this e-mail from Jan. [waves paper and reads from it] 'Hi, everyone! Jan here, writing to share some wonderful news and to quash a rumor. First, the great news - I am expecting. The beautiful baby inside me will be ready to meet the world this fall just a few months before the launch of the new Serenity by Jan line (shameless plug, I know, don't hate me, LOL). Anyway, I also wanted to clarify that the father is not anyone that you or I have ever met. Remember, no matter how excited someone is about my baby, it does not make them the father. Best, Jan. P.S. Hope to ship you something soon.'", "order": 21231, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 1, "scene": 115, "line": "I can't wait to be a father. I'm going to smother that baby.", "order": 21232, "speaker": "Michael Scott"},
  {"season": 5, "episode": 1, "scene": 116, "line": "Uh, new wicks from Craft Corner, uh, this dress [hands Michael bag] returned to Suburban Casuals, and uh, panty liners.", "order": 21233, "speaker": "Jan Levinson"},
  {"season": 5, "episode": 1, "scene": 116, "line": "I really, I don't want to get panty liners, please...", "order": 21234, "speaker": "Michael Scott"},
  {"season": 5, "episode": 1, "scene": 116, "line": "Well, I, you said you wanted to help, so I...", "order": 21235, "speaker": "Jan Levinson"},
  {"season": 5, "episode": 1, "scene": 116, "line": "I know, it's...", "order": 21236, "speaker": "Michael Scott"},
  {"season": 5, "episode": 1, "scene": 116, "line": "Will you be able to milk with those implants?", "order": 21237, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 1, "scene": 116, "line": "You mean breastfeed?", "order": 21238, "speaker": "Jan Levinson"},
  {"season": 5, "episode": 1, "scene": 116, "line": "Yeah. [Jan starts to get up] Easy there, old girl. [tries to help her]", "order": 21239, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 1, "scene": 116, "line": "Uh, I'm fine, thank you.", "order": 21240, "speaker": "Jan Levinson"},
  {"season": 5, "episode": 1, "scene": 116, "line": "Okay.", "order": 21241, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 1, "scene": 116, "line": "Would you just call me when you're done with the errands, Michael?", "order": 21242, "speaker": "Jan Levinson"},
  {"season": 5, "episode": 1, "scene": 116, "line": "Yes.", "order": 21243, "speaker": "Michael Scott"},
  {"season": 5, "episode": 1, "scene": 116, "line": "[as Jan leaves room] Careful. Hmm. She was a keeper.", "order": 21244, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 1, "scene": 116, "line": "I want you to get the panty liners.", "order": 21245, "speaker": "Michael Scott"},
  {"season": 5, "episode": 1, "scene": 116, "line": "Done.", "order": 21246, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 2, "scene": 1, "line": "[on the phone] Well, I should go.", "order": 21247, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 2, "scene": 1, "line": "Alright. I'll tell everybody here that you say hi.", "order": 21248, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 2, "scene": 1, "line": "No, don't. I'm mad at them.", "order": 21249, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 2, "scene": 1, "line": "Why, what happened?", "order": 21250, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 2, "scene": 1, "line": "Not one of them called to congratulate me on our engagement.", "order": 21251, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 2, "scene": 1, "line": "Ah. That... they might be off the hook for because I... didn't tell them.", "order": 21252, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 2, "scene": 1, "line": "What? Why not?", "order": 21253, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 2, "scene": 1, "line": "I just didn't, you know, want a deal.", "order": 21254, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 2, "scene": 1, "line": "Come on, it won't be that bad.", "order": 21255, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 2, "scene": 1, "line": "OK. You know what? Here we go. [stands up] Uh, everybody? I just want to make an announcement. Pam and I are engaged.", "order": 21256, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 2, "scene": 1, "line": "[speakerphone] Hi everyone!", "order": 21257, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 2, "scene": 1, "line": "I thought you were already engaged.", "order": 21258, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 2, "scene": 1, "line": "Nope.", "order": 21259, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 2, "scene": 1, "line": "That was Roy. She was engaged to Roy.", "order": 21260, "speaker": "Angela Martin"},
  {"season": 5, "episode": 2, "scene": 1, "line": "Thank you Angela.", "order": 21261, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 2, "scene": 1, "line": "I got a gift for Pam and Roy. Do I have to get another one?", "order": 21262, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 2, "scene": 1, "line": "Yes.", "order": 21263, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 2, "scene": 1, "line": "A little close to my engagement there Tuna, what's your game here?", "order": 21264, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 2, "scene": 1, "line": "To get married.", "order": 21265, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 2, "scene": 1, "line": "[raises hand] She's not a virgin, you know.", "order": 21266, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 2, "scene": 1, "line": "Wow.", "order": 21267, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 2, "scene": 1, "line": "[walking in] What's going on?", "order": 21268, "speaker": "Michael Scott"},
  {"season": 5, "episode": 2, "scene": 1, "line": "No, nothing. Nothing Michael! Just saying hi. [Michael waves 'hi' at the phone]", "order": 21269, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 2, "scene": 1, "line": "The tall guy got engaged.", "order": 21270, "speaker": "Creed Bratton"},
  {"season": 5, "episode": 2, "scene": 1, "line": "[to Jim] To be married?", "order": 21271, "speaker": "Michael Scott"},
  {"season": 5, "episode": 2, "scene": 1, "line": "Yep. [Michael hugs/tackles Jim]", "order": 21272, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 2, "scene": 1, "line": "Sorry.", "order": 21273, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 2, "scene": 2, "line": "Pencils down! [Dwight quickly puts his pencil down] Just kidding, take your time.", "order": 21274, "speaker": "Holly Flax"},
  {"season": 5, "episode": 2, "scene": 3, "line": "Today is ethics day. After they finish their quiz I'm going to run my first ethics meeting here. It's gonna be insaaaaane. No, it's not. I have to read from the binder.", "order": 21275, "speaker": "Holly Flax"},
  {"season": 5, "episode": 2, "scene": 4, "line": "[trying to assist Michael, who is trying to press play on a CD player] I got it, I got it. [Olivia Newton-John's 'Let's Get Physical' starts to play as Holly and Michael jog into the meeting room, dancing]", "order": 21276, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 2, "scene": 4, "line": "Let's get ethical! Ethical! I wanna get ethical! Let's get into Ethics yeah! Let me hear that Dunder Mifflin talk! Your body talk. Let me hear your body talk.", "order": 21277, "speaker": "Michael Scott & Holly Flax"},
  {"season": 5, "episode": 2, "scene": 4, "line": "WOO! Alright!", "order": 21278, "speaker": "Michael Scott"},
  {"season": 5, "episode": 2, "scene": 5, "line": "Why are you helping her? You're not even dating.' She's my friend... and... ultimately my strategy is to merge this into a relationship... without her even knowing. Uh...", "order": 21279, "speaker": "Michael Scott"},
  {"season": 5, "episode": 2, "scene": 6, "line": "Ok, let's give it for Miss... Holly Flaaaax! [everyone claps]", "order": 21280, "speaker": "Michael Scott"},
  {"season": 5, "episode": 2, "scene": 6, "line": "Thanks Michael. [Michael groans exhaustingly] Today we're going to have a business ethics seminar because recently, without mentioning any names, there has been some misconduct at corporate and we have a very strict ethics policy and that employee has been fired.", "order": 21281, "speaker": "Holly Flax"},
  {"season": 5, "episode": 2, "scene": 6, "line": "Oh come on! He's right there. [points at Ryan] He was hired. [to Jim] Oh check it out, 'Hired Guy.'", "order": 21282, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 2, "scene": 6, "line": "Nice. [they bump fists]", "order": 21283, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 2, "scene": 6, "line": "Ok, elephant in the room. Let's talk about it. Do I regret what I did? Of course I do. Even though it was an amazing ride, and I'll give you an example. Anyone see Survivor season six? [Dwight raises his hand] Anyone know Joanna on that show? [Dwight nods] In New York City, I hooked up with a girl who looked exactly like that. Indistinguishable. So...", "order": 21284, "speaker": "Ryan Howard"},
  {"season": 5, "episode": 2, "scene": 6, "line": "[clapping] Ok. Well done. Good speech Ryan, you're a good guy.", "order": 21285, "speaker": "Michael Scott"},
  {"season": 5, "episode": 2, "scene": 6, "line": "Let's start discussing those questionnaires that you filled in this morning. It is wrong to make personal calls during work hours. Now, some of you marked that you very strongly agree. But ideally you would have selected totally agree.", "order": 21286, "speaker": "Holly Flax"},
  {"season": 5, "episode": 2, "scene": 6, "line": "I thought very strongly agree sounded stronger than totally agree.", "order": 21287, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 2, "scene": 6, "line": "Corporate would like to emphasize that ideally you would all totally agree with that statement.", "order": 21288, "speaker": "Holly Flax"},
  {"season": 5, "episode": 2, "scene": 6, "line": "Well, I think we can all totally agree that Holly is totally fantastic. [claps]", "order": 21289, "speaker": "Michael Scott"},
  {"season": 5, "episode": 2, "scene": 6, "line": "Thank you. In fact, spending a half hour at the water cooler during work hours is a form of stealing.", "order": 21290, "speaker": "Holly Flax"},
  {"season": 5, "episode": 2, "scene": 6, "line": "What?", "order": 21291, "speaker": "Kelly Kapoor"},
  {"season": 5, "episode": 2, "scene": 6, "line": "Yes, it's called time theft, and it's the same as taking money from the company. Can anyone think of examples of things that are over the line time wasters?", "order": 21292, "speaker": "Holly Flax"},
  {"season": 5, "episode": 2, "scene": 6, "line": "This meeting.", "order": 21293, "speaker": "Stanley Hudson"},
  {"season": 5, "episode": 2, "scene": 6, "line": "Hey-oh!", "order": 21294, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 2, "scene": 6, "line": "Yes.", "order": 21295, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 2, "scene": 6, "line": "[to Holly] Can't set 'em up like that.", "order": 21296, "speaker": "Michael Scott"},
  {"season": 5, "episode": 2, "scene": 6, "line": "Why is ok for smokers to take breaks all the time? If I want to go outside and hang out once an hour, then I'll just take up smoking. I'll do it. I don't care.", "order": 21297, "speaker": "Kelly Kapoor"},
  {"season": 5, "episode": 2, "scene": 6, "line": "I'll smoke with you. I got a bag of cigars in my purse.", "order": 21298, "speaker": "Meredith Palmer"},
  {"season": 5, "episode": 2, "scene": 6, "line": "Stealing office supplies is another big ethical area that there seems to be some confusion about.", "order": 21299, "speaker": "Holly Flax"},
  {"season": 5, "episode": 2, "scene": 6, "line": "Can we have a moment? [whispering] Can I talk to you for a sec? Lot of good stuff. And you look... you look fantastic.", "order": 21300, "speaker": "Michael Scott"},
  {"season": 5, "episode": 2, "scene": 6, "line": "Michael.", "order": 21301, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 2, "scene": 6, "line": "Yeah.", "order": 21302, "speaker": "Michael Scott"},
  {"season": 5, "episode": 2, "scene": 6, "line": "What should we be working on while you guys are talking?", "order": 21303, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 2, "scene": 6, "line": "Do some... do some work. [whispering to Holly] People expect a lot from these meetings. Laughter, sudden twists. Surprise endings. You need to be Robin Williams and M. Night Shyamalan. You need to be Robin Shyamalan.", "order": 21304, "speaker": "Michael Scott"},
  {"season": 5, "episode": 2, "scene": 6, "line": "Well, I just have to get through the binder.", "order": 21305, "speaker": "Holly Flax"},
  {"season": 5, "episode": 2, "scene": 6, "line": "Do you... just... you're kind of losing them.", "order": 21306, "speaker": "Michael Scott"},
  {"season": 5, "episode": 2, "scene": 6, "line": "I am?", "order": 21307, "speaker": "Holly Flax"},
  {"season": 5, "episode": 2, "scene": 6, "line": "Yeah, don't think about the stakes. It'll freak you out.", "order": 21308, "speaker": "Michael Scott"},
  {"season": 5, "episode": 2, "scene": 6, "line": "Ok. [to everyone] Michael makes a really good point so, uh, let's just open this up a little bit. Say my name is Lauren and here I am shopping in a supermarket and I steal a pencil. That's not right.", "order": 21309, "speaker": "Holly Flax"},
  {"season": 5, "episode": 2, "scene": 6, "line": "[coughs to hide his words] Lauren, [coughs] enough with the pencils.", "order": 21310, "speaker": "Michael Scott"},
  {"season": 5, "episode": 2, "scene": 6, "line": "No, I have to go over pencils and office supplies. It's part of the ethics thing.", "order": 21311, "speaker": "Holly Flax"},
  {"season": 5, "episode": 2, "scene": 6, "line": "That isn't ethics. Ethics is a real discussion of the competing conceptions of the good. This is just the corporate anti-shoplifting rules.", "order": 21312, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 2, "scene": 6, "line": "I'll drop an ethics bomb on you. Would you steal bread to feed your family? ... Boom!", "order": 21313, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 2, "scene": 6, "line": "Exactly, Andy.", "order": 21314, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 2, "scene": 6, "line": "Yeah, I took intro to philosophy,", "order": 21315, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 2, "scene": 6, "line": "It's a trick question. The bread is poisoned. Also, it's not your real family. You've been cuckolded by a stronger, smarter male.", "order": 21316, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 2, "scene": 6, "line": "No that's... not how it works.", "order": 21317, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 2, "scene": 6, "line": "I would not... steal the bread. And I would not let my family go hungry.", "order": 21318, "speaker": "Michael Scott"},
  {"season": 5, "episode": 2, "scene": 6, "line": "Ok, but we should get back to business. Have any of you ever faced any ethical dilemmas in the workplace.", "order": 21319, "speaker": "Holly Flax"},
  {"season": 5, "episode": 2, "scene": 6, "line": "Anybody? This is a chance for you to say something without any repercussions. Stanley? Oscar, come on.", "order": 21320, "speaker": "Michael Scott"},
  {"season": 5, "episode": 2, "scene": 6, "line": "Pass.", "order": 21321, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 2, "scene": 6, "line": "I will go first. When I discovered", "order": 21322, "speaker": "Michael Scott"},
  {"season": 5, "episode": 2, "scene": 6, "line": "What was the dilemma?", "order": 21323, "speaker": "Holly Flax"},
  {"season": 5, "episode": 2, "scene": 6, "line": "To tell you or not. And I'm glad I did. I feel very very good. Cathartic. I promise you that you are not going to get into trouble. You can say anything you want with total and complete immunity.", "order": 21324, "speaker": "Michael Scott"},
  {"season": 5, "episode": 2, "scene": 6, "line": "Oh--- no no no.", "order": 21325, "speaker": "Holly Flax"},
  {"season": 5, "episode": 2, "scene": 6, "line": "Yeah, [interrupting gibberish]. Come on, anybody. Let it fly.", "order": 21326, "speaker": "Michael Scott"},
  {"season": 5, "episode": 2, "scene": 6, "line": "Ok, once in a while I'll take a long lunch.", "order": 21327, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 2, "scene": 6, "line": "A siesta!", "order": 21328, "speaker": "Michael Scott"},
  {"season": 5, "episode": 2, "scene": 6, "line": "Time thief. Time thief! Fire him!", "order": 21329, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 2, "scene": 6, "line": "Dwight, you've really never stolen any company time?", "order": 21330, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 2, "scene": 6, "line": "Never!", "order": 21331, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 2, "scene": 6, "line": "You are a thief of joy. Anybody else. Yeah?", "order": 21332, "speaker": "Michael Scott"},
  {"season": 5, "episode": 2, "scene": 6, "line": "Sometimes I download pirated music onto my work computer.", "order": 21333, "speaker": "Kelly Kapoor"},
  {"season": 5, "episode": 2, "scene": 6, "line": "Who hasn't? Good. Good! What else?", "order": 21334, "speaker": "Michael Scott"},
  {"season": 5, "episode": 2, "scene": 6, "line": "No--- I, I'd like to hear more about that.", "order": 21335, "speaker": "Holly Flax"},
  {"season": 5, "episode": 2, "scene": 6, "line": "Mmm...", "order": 21336, "speaker": "Michael Scott"},
  {"season": 5, "episode": 2, "scene": 6, "line": "I once reported Oscar to the INS. Turns out he's clean, but I'm glad I did it.", "order": 21337, "speaker": "Angela Martin"},
  {"season": 5, "episode": 2, "scene": 6, "line": "Well, let's keep this party movin' on.", "order": 21338, "speaker": "Michael Scott"},
  {"season": 5, "episode": 2, "scene": 6, "line": "I'll go. Have you guys ever met Bruce Meyers, the Scranton rep for Hammermill?", "order": 21339, "speaker": "Meredith Palmer"},
  {"season": 5, "episode": 2, "scene": 6, "line": "Bruuuuuuuuuce.", "order": 21340, "speaker": "Michael Scott"},
  {"season": 5, "episode": 2, "scene": 6, "line": "Well for the past six years I have been sleeping with him in exchange for discounts on our supplies and Outback Steakhouse gift certificates.", "order": 21341, "speaker": "Meredith Palmer"},
  {"season": 5, "episode": 2, "scene": 6, "line": "Jackpot.", "order": 21342, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 2, "scene": 6, "line": "Ach! Wuh---", "order": 21343, "speaker": "Michael Scott"},
  {"season": 5, "episode": 2, "scene": 6, "line": "Meredith, that is serious. I mean not only that a conflict of interests, there's also an exchange of goods.", "order": 21344, "speaker": "Holly Flax"},
  {"season": 5, "episode": 2, "scene": 6, "line": "Exchange of steak. Have you ever had sirloin steak, honey?", "order": 21345, "speaker": "Meredith Palmer"},
  {"season": 5, "episode": 2, "scene": 6, "line": "That's crazy. That's crazy talk! Meredith! The Merenator, sleepin' with suppliers! Hoo-ooh! Wow! What time we got? [checks watch] You know what? That's a good place to end it. Right there. This, I think, was a great ethics seminar. She has given us a lot of wonderful things to think about. Right... what is wrong. Who's to say? Really. In the end. I mean because it is... unknowable. But, let's give her a round of applause. Holly, everybody. Holly! Get back to work. [everyone applauds, then leaves] [to Holly] Great job. I am truly impressed. That, uh, you really pulled that one out. Classic. Classic meeting. We should celebrate.", "order": 21346, "speaker": "Michael Scott"},
  {"season": 5, "episode": 2, "scene": 6, "line": "Michael, there is some serious issues with Meredith.", "order": 21347, "speaker": "Holly Flax"},
  {"season": 5, "episode": 2, "scene": 6, "line": "Oh...", "order": 21348, "speaker": "Michael Scott"},
  {"season": 5, "episode": 2, "scene": 6, "line": "I mean all of you have done things I wouldn't have done myself but Meredith's actions are really over the line.", "order": 21349, "speaker": "Holly Flax"},
  {"season": 5, "episode": 2, "scene": 6, "line": "Nnyeah. What ya gonna do?", "order": 21350, "speaker": "Michael Scott"},
  {"season": 5, "episode": 2, "scene": 7, "line": "So, regarding this supplier, approximately how many liaisons have there been?", "order": 21351, "speaker": "Holly Flax"},
  {"season": 5, "episode": 2, "scene": 7, "line": "Liaisons, you mean meet-ups? I don't know, once a month for six years. Something like that.", "order": 21352, "speaker": "Meredith Palmer"},
  {"season": 5, "episode": 2, "scene": 7, "line": "Meredith, why don't you tell Holly... it's not what she thinks. Nothing unethical happened... and that you just like to sleep around.", "order": 21353, "speaker": "Michael Scott"},
  {"season": 5, "episode": 2, "scene": 7, "line": "Am I in trouble here or something?", "order": 21354, "speaker": "Meredith Palmer"},
  {"season": 5, "episode": 2, "scene": 7, "line": "No... no, this is just a stupid formality.", "order": 21355, "speaker": "Michael Scott"},
  {"season": 5, "episode": 2, "scene": 7, "line": "No, it's not a formality. Now, were these, um, meet-ups just personal? Unrelated to business?", "order": 21356, "speaker": "Holly Flax"},
  {"season": 5, "episode": 2, "scene": 7, "line": "Nah, I wouldn't have done it if it wasn't for the discount paper. There's not a lot of fruit in those looms.", "order": 21357, "speaker": "Meredith Palmer"},
  {"season": 5, "episode": 2, "scene": 7, "line": "Ughh! For the love of God we're trying to help you... stupid bag.", "order": 21358, "speaker": "Michael Scott"},
  {"season": 5, "episode": 2, "scene": 7, "line": "What I don't understand is... why the steak coupons? I mean, if you were already getting the discounted paper?", "order": 21359, "speaker": "Holly Flax"},
  {"season": 5, "episode": 2, "scene": 7, "line": "Well it's funny. Maybe it's a girl thing, but after we did it, and he would give me those coupons, I just felt good about myself. [Holly begins to write in her notebook] Hey what's going on here? I thought I had immunity?", "order": 21360, "speaker": "Meredith Palmer"},
  {"season": 5, "episode": 2, "scene": 7, "line": "Well unfortunately immunity or something being off the record does not really exist in the workplace. I mean I've never heard of anyone who's kept their job after something like this.", "order": 21361, "speaker": "Holly Flax"},
  {"season": 5, "episode": 2, "scene": 7, "line": "Well... I'm not quitting.", "order": 21362, "speaker": "Meredith Palmer"},
  {"season": 5, "episode": 2, "scene": 8, "line": "[starts a stopwatch as Dwight yawns, stops it when Dwight is done] Yawn. Four seconds.", "order": 21363, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 2, "scene": 8, "line": "What are you doing?", "order": 21364, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 2, "scene": 8, "line": "Oh you had said that you don't do anything personal during work time so... I'm just making sure.", "order": 21365, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 2, "scene": 8, "line": "Oh, so wait a minute, you're going to time me every time I yawn? That's absurd. [Jim smiles and starts the stopwatch] Really? [in a mocking voice] Oh hey look, monkey knows how to use a stopwatch everybody! He's ti--- [Dwight conceeds and goes back to work, Jim stops the watch]", "order": 21366, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 2, "scene": 8, "line": "Personal conversation. Seventeen seconds.", "order": 21367, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 2, "scene": 8, "line": "There is no way that that was--- [Jim starts the watch]", "order": 21368, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 2, "scene": 8, "line": "[stops the watch] One second.", "order": 21369, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 2, "scene": 8, "line": "Well, well. Holly... lujah! It's a miracle, you're at your desk.", "order": 21370, "speaker": "Michael Scott"},
  {"season": 5, "episode": 2, "scene": 8, "line": "It's Mike-raculous.", "order": 21371, "speaker": "Holly Flax"},
  {"season": 5, "episode": 2, "scene": 8, "line": "Hoo-ooh! Reaching! You'll get there. Anyway, I was giving it some thought, and there's no reason that two attractive, good looking, intelligent, funny, attractive people can't, you know, just... sit down and work this whole Meredith thing out.", "order": 21372, "speaker": "Michael Scott"},
  {"season": 5, "episode": 2, "scene": 8, "line": "Sounds good.", "order": 21373, "speaker": "Holly Flax"},
  {"season": 5, "episode": 2, "scene": 8, "line": "Good. Would you care to bang it out over lunch?", "order": 21374, "speaker": "Michael Scott"},
  {"season": 5, "episode": 2, "scene": 8, "line": "Oh I already bought this...", "order": 21375, "speaker": "Holly Flax"},
  {"season": 5, "episode": 2, "scene": 8, "line": "Oh no, no, no. Your food is no good here, my lady. [sweeps her food into the trash can, misses] Sorry! You know what? Let's go out! Dunder Mifflin's treat. Um, actually you're not a client... so... we'll just split it? Ethical.", "order": 21376, "speaker": "Michael Scott"},
  {"season": 5, "episode": 2, "scene": 9, "line": "So, I've gone over corporate policy.", "order": 21377, "speaker": "Holly Flax"},
  {"season": 5, "episode": 2, "scene": 9, "line": "What do you think? What do you think of this place?", "order": 21378, "speaker": "Michael Scott"},
  {"season": 5, "episode": 2, "scene": 9, "line": "Oh, it's very nice.", "order": 21379, "speaker": "Holly Flax"},
  {"season": 5, "episode": 2, "scene": 9, "line": "Istn't it? Yeah, it's kind of... business/romantic. So you're from Des Moines?", "order": 21380, "speaker": "Michael Scott"},
  {"season": 5, "episode": 2, "scene": 9, "line": "Mmm.", "order": 21381, "speaker": "Holly Flax"},
  {"season": 5, "episode": 2, "scene": 9, "line": "Wow, that sounds so... warm.", "order": 21382, "speaker": "Michael Scott"},
  {"season": 5, "episode": 2, "scene": 9, "line": "In Summer.", "order": 21383, "speaker": "Holly Flax"},
  {"season": 5, "episode": 2, "scene": 9, "line": "Here too. You know what my favorite season is?", "order": 21384, "speaker": "Michael Scott"},
  {"season": 5, "episode": 2, "scene": 9, "line": "Maybe we should talk about Meredith first.", "order": 21385, "speaker": "Holly Flax"},
  {"season": 5, "episode": 2, "scene": 9, "line": "Yeah, get the boring stuff out of the way. Autumn was what I was going to say. When the leaves change. It's just, I... I think it is the most contemplative of seasons.", "order": 21386, "speaker": "Michael Scott"},
  {"season": 5, "episode": 2, "scene": 9, "line": "Ok, so I have gone over this and I have thought about it and I just don't think there is any way I can write a report that doesn't end with her being terminated.", "order": 21387, "speaker": "Holly Flax"},
  {"season": 5, "episode": 2, "scene": 9, "line": "Wow, terminator, terminator.", "order": 21388, "speaker": "Michael Scott"},
  {"season": 5, "episode": 2, "scene": 9, "line": "I'm from da future.", "order": 21389, "speaker": "Holly Flax"},
  {"season": 5, "episode": 2, "scene": 9, "line": "Yeah.", "order": 21390, "speaker": "Michael Scott"},
  {"season": 5, "episode": 2, "scene": 10, "line": "Hey, Andy.", "order": 21391, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 2, "scene": 10, "line": "Yo.", "order": 21392, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 2, "scene": 10, "line": "By any chance, did you see Battlestar Galactica last night?", "order": 21393, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 2, "scene": 10, "line": "No, I did not. Was that any good?", "order": 21394, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 2, "scene": 10, "line": "Actually not. It was really so-so.", "order": 21395, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 2, "scene": 10, "line": "Ok. [Dwight turns around, but Jim holds up the stopwatch, threatening to start it]", "order": 21396, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 2, "scene": 10, "line": "I mean I like all the crazy monsters and stuff. You know, like klingons and wookies and all that but... [Dwight begins to turn around again, Jim holds up the watch] Sorry, was there something you wanted to add, Dwight?", "order": 21397, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 2, "scene": 10, "line": "Is that anything like the original Battlestar Galactica?", "order": 21398, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 2, "scene": 10, "line": "You know, it's weird. It's practically a shot for shot remake.", "order": 21399, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 2, "scene": 10, "line": "Really? [Dwight closes his eyes, clutches the paper in his hands] Huh, that's cool.", "order": 21400, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 2, "scene": 10, "line": "Story's kinda bland. It's about this guy named Dumbledore Calrissian who needs to return the ring back to Mordor.", "order": 21401, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 2, "scene": 10, "line": "Really? That doesn't sound right.", "order": 21402, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 2, "scene": 11, "line": "I just don't want my employees thinking that their jobs depend on performance. I mean, what sort of place is that to call home? And Meredith needs this job. This is her main source of money.", "order": 21403, "speaker": "Michael Scott"},
  {"season": 5, "episode": 2, "scene": 11, "line": "Well, that's very sweet but we have to follow the protocol. Those are the rules.", "order": 21404, "speaker": "Holly Flax"},
  {"season": 5, "episode": 2, "scene": 11, "line": "Ok, new idea. We don't report it at all. We just punish her.", "order": 21405, "speaker": "Michael Scott"},
  {"season": 5, "episode": 2, "scene": 11, "line": "We punish her?", "order": 21406, "speaker": "Holly Flax"},
  {"season": 5, "episode": 2, "scene": 11, "line": "Mmhmm, tell her she can't have sex for six months.", "order": 21407, "speaker": "Michael Scott"},
  {"season": 5, "episode": 2, "scene": 11, "line": "I don't think we could enforce that.", "order": 21408, "speaker": "Holly Flax"},
  {"season": 5, "episode": 2, "scene": 11, "line": "I don't know. I saw this thing, like a belt with a key.", "order": 21409, "speaker": "Michael Scott"},
  {"season": 5, "episode": 2, "scene": 11, "line": "A chastity belt.", "order": 21410, "speaker": "Holly Flax"},
  {"season": 5, "episode": 2, "scene": 11, "line": "No, it's more of a underwear garment that has little spikes like made of, sometimes they are made of metal. You know what I am talking about. You unlock a little door that... down... where you, where you put, where you put the...", "order": 21411, "speaker": "Michael Scott"},
  {"season": 5, "episode": 2, "scene": 12, "line": "He has not stopped working for a second. At 12:45 he sneezed while keeping his eyes open, which I always thought was impossible. At 1:32 he peed, and I know that because he did it in an open soda bottle under the desk while filling out expense reports. And on the flip side, I have been so busy watching him that I haven't even started work. It's exhausting, being this vigilant. I'll probably have to go home early today.", "order": 21412, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 2, "scene": 13, "line": "I am just saying that I don't think that you understand what I am saying.", "order": 21413, "speaker": "Michael Scott"},
  {"season": 5, "episode": 2, "scene": 13, "line": "No, you are saying that we should sweep it under the carpet, accept the discount on supplies, not fire Meredith and pretend like the whole thing never happened.", "order": 21414, "speaker": "Holly Flax"},
  {"season": 5, "episode": 2, "scene": 13, "line": "Yes.", "order": 21415, "speaker": "Michael Scott"},
  {"season": 5, "episode": 2, "scene": 13, "line": "What am I saying?", "order": 21416, "speaker": "Holly Flax"},
  {"season": 5, "episode": 2, "scene": 13, "line": "Well... bleh busted. I don't...", "order": 21417, "speaker": "Michael Scott"},
  {"season": 5, "episode": 2, "scene": 13, "line": "I'm saying that her behavior is unethical and a little icky and I don't think I want to work in an environment where that sort of conduct is tolerated.", "order": 21418, "speaker": "Holly Flax"},
  {"season": 5, "episode": 2, "scene": 13, "line": "Well, you have to tolerate a lot when you are part of a family.", "order": 21419, "speaker": "Michael Scott"},
  {"season": 5, "episode": 2, "scene": 13, "line": "It's not a family. It's a workplace.", "order": 21420, "speaker": "Holly Flax"},
  {"season": 5, "episode": 2, "scene": 14, "line": "I will be honest with you. That car ride did not go well and that was not my fault. The only reason I am standing out here right now is because I don't want to take the elevator with her. And I am holding on to her leftovers. [throws Holly's leftovers in the trash]", "order": 21421, "speaker": "Michael Scott"},
  {"season": 5, "episode": 2, "scene": 15, "line": "[Dwight walks back to his desk] 19 minutes and 48 seconds. What were we doing for 19 minutes and 48 seconds?", "order": 21422, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 2, "scene": 15, "line": "None of your business.", "order": 21423, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 2, "scene": 15, "line": "So I guess I can assume that was personal.", "order": 21424, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 2, "scene": 15, "line": "[Dwight stares at Angela, buttoning the top button of her blouse] Fine.", "order": 21425, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 2, "scene": 15, "line": "So maybe you're not completely ethical after all.", "order": 21426, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 2, "scene": 15, "line": "Yes, maybe I'm not. [Dwight grins and gives a sly look at the camera]", "order": 21427, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 2, "scene": 16, "line": "It's been a little tense. People are suspicious of me. And my best friend in the office won't even talk to me. Turns out being the morality police does not make you popular. I should know because in middle school I was the hall monitor and the kids used to stuff egg salad in my locker. I was just hoping middle school was over.", "order": 21428, "speaker": "Holly Flax"},
  {"season": 5, "episode": 2, "scene": 17, "line": "Kendall from corporate HR is on line one and Holly is on her way in too.", "order": 21429, "speaker": "Ryan Howard"},
  {"season": 5, "episode": 2, "scene": 17, "line": "What's the only thing worse than one HR rep?", "order": 21430, "speaker": "Michael Scott"},
  {"season": 5, "episode": 2, "scene": 17, "line": "Two HR reps.", "order": 21431, "speaker": "Ryan Howard"},
  {"season": 5, "episode": 2, "scene": 17, "line": "You get me.", "order": 21432, "speaker": "Michael Scott"},
  {"season": 5, "episode": 2, "scene": 17, "line": "Can I sit?", "order": 21433, "speaker": "Holly Flax"},
  {"season": 5, "episode": 2, "scene": 17, "line": "I don't know. Can you? Kendall, my main man.", "order": 21434, "speaker": "Michael Scott"},
  {"season": 5, "episode": 2, "scene": 17, "line": "[on speakerphone] Listen, Holly, Michael, I just got the report that your branch submitted and there's a lot of stuff about a relationship Meredith is having with..", "order": 21435, "speaker": "Kendall"},
  {"season": 5, "episode": 2, "scene": 17, "line": "Yes, that came out during the ethics seminar.", "order": 21436, "speaker": "Holly Flax"},
  {"season": 5, "episode": 2, "scene": 17, "line": "Let the record show that it was during the immunity part of the seminar.", "order": 21437, "speaker": "Michael Scott"},
  {"season": 5, "episode": 2, "scene": 17, "line": "Well, I'm not sure these circumstances warrant any action.", "order": 21438, "speaker": "Kendall"},
  {"season": 5, "episode": 2, "scene": 17, "line": "Oh, I think it is pretty clear that it was unethical.", "order": 21439, "speaker": "Holly Flax"},
  {"season": 5, "episode": 2, "scene": 17, "line": "Well, from what I can gather it seems like a gray area. Look, to be honest the company is getting a discount at a tough time in our balance sheet and I don't know that the right thing to do for the company is to turn our noses up at that.", "order": 21440, "speaker": "Kendall"},
  {"season": 5, "episode": 2, "scene": 17, "line": "Umm, Kendall, I understand that the discount is good for the company but I'm just not happy about the way we are getting it.", "order": 21441, "speaker": "Holly Flax"},
  {"season": 5, "episode": 2, "scene": 17, "line": "I thought it was clear with you, Holly. Your task was to get signatures from the employees showing that they completed the training.", "order": 21442, "speaker": "Kendall"},
  {"season": 5, "episode": 2, "scene": 17, "line": "No, I understand.", "order": 21443, "speaker": "Holly Flax"},
  {"season": 5, "episode": 2, "scene": 17, "line": "Every other branch has managed to get this to us so if it's not something you can handle then that's a different discussion.", "order": 21444, "speaker": "Kendall"},
  {"season": 5, "episode": 2, "scene": 17, "line": "No. I can do it.", "order": 21445, "speaker": "Holly Flax"},
  {"season": 5, "episode": 2, "scene": 17, "line": "Good.", "order": 21446, "speaker": "Kendall"},
  {"season": 5, "episode": 2, "scene": 18, "line": "How do you tell somebody that you care about deeply, 'I told you so.' Gently with a rose? In a funny way, like it's a hilarious joke? Or do you just let it go, because saying it would just make things worse? ... Probably the funny way.", "order": 21447, "speaker": "Michael Scott"},
  {"season": 5, "episode": 2, "scene": 19, "line": "Can I have everyone's attention? Excuse me, may I have everyone's attention, please. We need to finish the ethics seminar.", "order": 21448, "speaker": "Holly Flax"},
  {"season": 5, "episode": 2, "scene": 19, "line": "No way, lady.", "order": 21449, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 2, "scene": 19, "line": "It's a trap.", "order": 21450, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 2, "scene": 19, "line": "Everyone, please, I just need your signatures to show corporate that I gave you the training.", "order": 21451, "speaker": "Holly Flax"},
  {"season": 5, "episode": 2, "scene": 19, "line": "Don't sign anything.", "order": 21452, "speaker": "Meredith Palmer"},
  {"season": 5, "episode": 2, "scene": 19, "line": "Ok, everybody listen up. If you are not in that conference room in two minutes I am going to kill you.", "order": 21453, "speaker": "Michael Scott"},
  {"season": 5, "episode": 2, "scene": 19, "line": "It's a quarter to five and I have started to gather my things.", "order": 21454, "speaker": "Stanley Hudson"},
  {"season": 5, "episode": 2, "scene": 19, "line": "Get in there right now or I'm gonna lose it! [everyone gets up to go in the conference room]", "order": 21455, "speaker": "Michael Scott"},
  {"season": 5, "episode": 2, "scene": 19, "line": "Am I getting fired?", "order": 21456, "speaker": "Meredith Palmer"},
  {"season": 5, "episode": 2, "scene": 19, "line": "Now is really not the time, Meredith.", "order": 21457, "speaker": "Michael Scott"},
  {"season": 5, "episode": 2, "scene": 20, "line": "In this next section we examine the difference between sick days and personal days. Sick days are only to be used when an illness precludes you form doing your job or can spread to your coworkers. Personal days, on the other hand, are much more flexible.", "order": 21458, "speaker": "Holly Flax"},
  {"season": 5, "episode": 2, "scene": 21, "line": "Thank you, Meredith, this was delicious.", "order": 21459, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 2, "scene": 21, "line": "Hey, where is the steak sauce?", "order": 21460, "speaker": "Meredith Palmer"},
  {"season": 5, "episode": 2, "scene": 21, "line": "I think we're out.", "order": 21461, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 2, "scene": 21, "line": "I got some in my minivan somewhere.", "order": 21462, "speaker": "Meredith Palmer"},
  {"season": 5, "episode": 2, "scene": 21, "line": "Wait a second, how does the steak factor in again?", "order": 21463, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 2, "scene": 21, "line": "I think she got it as a tip, but I don't know why she didn't just take cash.", "order": 21464, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 2, "scene": 21, "line": "I don't care what she's doing. I just hope she keeps doing it.", "order": 21465, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 2, "scene": 21, "line": "Amen. Just keep the ribs coming.", "order": 21466, "speaker": "Stanley Hudson"},
  {"season": 5, "episode": 2, "scene": 22, "line": "Stealing office supplies is another big ethical area that there seems to be some confusion about.", "order": 21467, "speaker": "Holly Flax"},
  {"season": 5, "episode": 2, "scene": 22, "line": "That's a big ethical area?", "order": 21468, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 2, "scene": 23, "line": "Listen, I would love to have an honest conversation with management about ethics. Why isn't the company doing better? Mmm... it could be the pad of Post-its I took home last week. Or it could be the twelve million dollars in deferred compensation in stock options they paid the CEO for a year of substandard performance. I'm sure we'll cover both in the seminar.", "order": 21469, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 2, "scene": 24, "line": "I've done some things I'm not proud of. Committed some atrocities in Vietnam, uh, two years ago. Princess Cruise Lines.", "order": 21470, "speaker": "Creed Bratton"},
  {"season": 5, "episode": 2, "scene": 25, "line": "During our honeymoon safari in Africa, Bob and I were driving late at night, and he'd been drinking, and suddenly thump. We hit something with our jeep. It was probably a man. We didn't know what to do. We heard the police were corrupt and they might beat us, so we just kept driving as fast as we could. We bribed the airline, got on a plane that night, and fled home. Maybe it was just an ostrich. In a soccer uniform.", "order": 21471, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 2, "scene": 26, "line": "Have any of you ever faced any ethical dilemmas in the work place?", "order": 21472, "speaker": "Holly Flax"},
  {"season": 5, "episode": 2, "scene": 26, "line": "Let's keep this rollin'... Ryan?", "order": 21473, "speaker": "Michael Scott"},
  {"season": 5, "episode": 2, "scene": 26, "line": "Oh, but you mean other than embezzling?", "order": 21474, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 2, "scene": 26, "line": "Fraud, Jim, Fraud.", "order": 21475, "speaker": "Ryan Howard"},
  {"season": 5, "episode": 2, "scene": 26, "line": "Fraud.", "order": 21476, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 2, "scene": 26, "line": "Sure, last year you guys were riding me really hard for the website and I just peeled out my Z3 and I knocked the mirror off somebody's car. I never said a thing.", "order": 21477, "speaker": "Ryan Howard"},
  {"season": 5, "episode": 2, "scene": 26, "line": "Wait, what?", "order": 21478, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 2, "scene": 27, "line": "You knocked the mirror off of my car.", "order": 21479, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 2, "scene": 27, "line": "Yeah, isn't that messed up?", "order": 21480, "speaker": "Ryan Howard"},
  {"season": 5, "episode": 2, "scene": 27, "line": "Yeah.", "order": 21481, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 2, "scene": 27, "line": "That guy did a lot of things I'm not proud of.", "order": 21482, "speaker": "Ryan Howard"},
  {"season": 5, "episode": 2, "scene": 27, "line": "Wait, when you say 'that guy', you mean you?", "order": 21483, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 2, "scene": 27, "line": "I mean the guy I used to be. I'm Ryan 2.0 and if it makes you feel any better, that guy did a lot of messed up stuff to me too. [tries to walk away]", "order": 21484, "speaker": "Ryan Howard"},
  {"season": 5, "episode": 2, "scene": 27, "line": "No, you mean that you did a lot of messed up stuff to you... too?", "order": 21485, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 2, "scene": 27, "line": "Look, I feel you okay? That guy took no responsibility for his actions.", "order": 21486, "speaker": "Ryan Howard"},
  {"season": 5, "episode": 2, "scene": 27, "line": "But are, are you gonna pay for my mirror?", "order": 21487, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 2, "scene": 27, "line": "If I have to answer for everything that guy did... [shakes head] I'm never gonna move on.", "order": 21488, "speaker": "Ryan Howard"},
  {"season": 5, "episode": 2, "scene": 27, "line": "It was like two hundred dollars.", "order": 21489, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 2, "scene": 27, "line": "We're never going to get what need from that guy. [still shaking head, pats Kevin on the shoulder and walks out of the kitchen]", "order": 21490, "speaker": "Ryan Howard"},
  {"season": 5, "episode": 2, "scene": 28, "line": "Hey, anybody see a pair of sunglasses?", "order": 21491, "speaker": "Ryan Howard"},
  {"season": 5, "episode": 2, "scene": 28, "line": "Umm... I think I saw some in the kitchen. [toaster oven bell rings, Ryan opens it as smoke rolls out and he see's his melted sunglasses on the tray, Ryan takes cooked sunglasses to Kevin] Oh! I didn't do that. That was Kevin 1.0, but he hurt a lot of people Ryan, and I can't accept responsibility for what he did. [Ryan walks away and throws his sunglasses in the trash, Kevin smiles]", "order": 21492, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 2, "scene": 29, "line": "What are we gonna do?", "order": 21493, "speaker": "Meredith Palmer"},
  {"season": 5, "episode": 2, "scene": 29, "line": "About what?", "order": 21494, "speaker": "Stanley Hudson"},
  {"season": 5, "episode": 2, "scene": 29, "line": "Holly. She's on the warpath. She is gunning for all our jobs. Do you guys have any dirt on her?", "order": 21495, "speaker": "Meredith Palmer"},
  {"season": 5, "episode": 2, "scene": 29, "line": "Mmm... sometimes her stockings have a run in them.", "order": 21496, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 2, "scene": 29, "line": "No. Something I can blackmail her with.", "order": 21497, "speaker": "Meredith Palmer"},
  {"season": 5, "episode": 2, "scene": 29, "line": "One time I said good morning to her, and she didn't say it back.", "order": 21498, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 2, "scene": 29, "line": "Stanley, didn't she say a bunch of racist stuff to you?", "order": 21499, "speaker": "Meredith Palmer"},
  {"season": 5, "episode": 2, "scene": 29, "line": "No, that was you.", "order": 21500, "speaker": "Stanley Hudson"},
  {"season": 5, "episode": 2, "scene": 29, "line": "You weren't even in the room! Thanks for nothing.", "order": 21501, "speaker": "Meredith Palmer"},
  {"season": 5, "episode": 2, "scene": 30, "line": "Hey, how did it go?", "order": 21502, "speaker": "Meredith Palmer"},
  {"season": 5, "episode": 2, "scene": 30, "line": "Oh, cr..., oh, God. Meredith, you know what? I just need a minute, okay?", "order": 21503, "speaker": "Michael Scott"},
  {"season": 5, "episode": 2, "scene": 30, "line": "Okay. [remains sitting]", "order": 21504, "speaker": "Meredith Palmer"},
  {"season": 5, "episode": 2, "scene": 30, "line": "What do you need?", "order": 21505, "speaker": "Michael Scott"},
  {"season": 5, "episode": 2, "scene": 30, "line": "I'm getting fired, aren't I? I can't get fired. I got nothing else. Nothing. No skill set. Can't type, bad on the phone, not great with people.", "order": 21506, "speaker": "Meredith Palmer"},
  {"season": 5, "episode": 2, "scene": 30, "line": "I... really want to keep you.", "order": 21507, "speaker": "Michael Scott"},
  {"season": 5, "episode": 2, "scene": 30, "line": "Where else am I gonna get another job that lets me come it at eleven and leave at four?", "order": 21508, "speaker": "Meredith Palmer"},
  {"season": 5, "episode": 2, "scene": 30, "line": "Wait, what?", "order": 21509, "speaker": "Michael Scott"},
  {"season": 5, "episode": 2, "scene": 30, "line": "I wish Toby was still here.", "order": 21510, "speaker": "Meredith Palmer"},
  {"season": 5, "episode": 2, "scene": 30, "line": "Nnnngah!", "order": 21511, "speaker": "Michael Scott"},
  {"season": 5, "episode": 3, "scene": 1, "line": "[looking pregnant] Hey Michael?", "order": 21512, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 3, "scene": 1, "line": "Yeah.", "order": 21513, "speaker": "Michael Scott"},
  {"season": 5, "episode": 3, "scene": 1, "line": "Contractions are coming every ten minutes.", "order": 21514, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 3, "scene": 1, "line": "OK, just remember to keep breathing.", "order": 21515, "speaker": "Michael Scott"},
  {"season": 5, "episode": 3, "scene": 1, "line": "My cervix is ripening.", "order": 21516, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 3, "scene": 1, "line": "OK, good.", "order": 21517, "speaker": "Michael Scott"},
  {"season": 5, "episode": 3, "scene": 2, "line": "[drawing two family trees on a whiteboard] Jan is about to have a baby with a sperm donor. And Michael is preparing for the birth of a watermelon with Dwight. Now, this baby will be related to Michael through [draws a question mark] delusion.", "order": 21518, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 3, "scene": 3, "line": "Michael! My water's breaking!", "order": 21519, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 3, "scene": 3, "line": "Oh, OK! OK!", "order": 21520, "speaker": "Michael Scott"},
  {"season": 5, "episode": 3, "scene": 3, "line": "Aaaaaaaa! What do you do? What do you do?", "order": 21521, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 3, "scene": 3, "line": "I get a call from Jan and I meet her at the hospital.", "order": 21522, "speaker": "Michael Scott"},
  {"season": 5, "episode": 3, "scene": 3, "line": "Right. Highways or surface roads?", "order": 21523, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 3, "scene": 3, "line": "I take Quincy Ave to Gibson.", "order": 21524, "speaker": "Michael Scott"},
  {"season": 5, "episode": 3, "scene": 3, "line": "No, Gibson is covered in potholes. Your car breaks down. Adapt!", "order": 21525, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 3, "scene": 3, "line": "I checked the route - there are no potholes. Come on, get in here and have the baby.", "order": 21526, "speaker": "Michael Scott"},
  {"season": 5, "episode": 3, "scene": 3, "line": "It's about adapting to the circumstances.", "order": 21527, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 3, "scene": 3, "line": "[annoyed] Andy, would you like to have my baby?", "order": 21528, "speaker": "Michael Scott"},
  {"season": 5, "episode": 3, "scene": 3, "line": "Yes! Yeah. [squatting and pushing] Aaaaaaaa! Aaaaaaaa! Aaaaaaaa!", "order": 21529, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 3, "scene": 3, "line": "No. OK, no. I'm crowning! I'm crowning! Aaaaaaaa! [runs into Michael's office]", "order": 21530, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 3, "scene": 3, "line": "All right, OK. [to Andy] Sorry. Sorry. Here we go. Here we go!", "order": 21531, "speaker": "Michael Scott"},
  {"season": 5, "episode": 3, "scene": 3, "line": "The pressure! The pressure!", "order": 21532, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 3, "scene": 3, "line": "Do it! Do it! Scream! Scream it out, scream it out, scream it out.", "order": 21533, "speaker": "Michael Scott"},
  {"season": 5, "episode": 3, "scene": 3, "line": "Aaaaaaaa!", "order": 21534, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 3, "scene": 3, "line": "Aaaaaaaa!", "order": 21535, "speaker": "Michael Scott"},
  {"season": 5, "episode": 3, "scene": 4, "line": "Babies are one of my many areas of expertise. Growing up, I performed my own circumcision.", "order": 21536, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 3, "scene": 5, "line": "Just keep pushing...", "order": 21537, "speaker": "Michael Scott"},
  {"season": 5, "episode": 3, "scene": 5, "line": "[lying on Michael's desk] Hold me!", "order": 21538, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 3, "scene": 5, "line": "I'm right here.", "order": 21539, "speaker": "Michael Scott"},
  {"season": 5, "episode": 3, "scene": 5, "line": "Cradle my head!", "order": 21540, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 3, "scene": 5, "line": "I'm right here, I'm right here.", "order": 21541, "speaker": "Michael Scott"},
  {"season": 5, "episode": 3, "scene": 5, "line": "I'm screaming, I'm screaming, I'm screaming! Aaaaaaaa!", "order": 21542, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 3, "scene": 5, "line": "[annoyed] All right. Dwight. Just push and breathe.", "order": 21543, "speaker": "Michael Scott"},
  {"season": 5, "episode": 3, "scene": 5, "line": "Numb me up. I want anesthesia!", "order": 21544, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 3, "scene": 5, "line": "Shhh. No, you can't have it. It's too late.", "order": 21545, "speaker": "Michael Scott"},
  {"season": 5, "episode": 3, "scene": 5, "line": "No! I don't want natural!", "order": 21546, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 3, "scene": 5, "line": "No. You have to just push it out!", "order": 21547, "speaker": "Michael Scott"},
  {"season": 5, "episode": 3, "scene": 5, "line": "OK!", "order": 21548, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 3, "scene": 5, "line": "Keep simulating.", "order": 21549, "speaker": "Michael Scott"},
  {"season": 5, "episode": 3, "scene": 5, "line": "Aaaaaaaa!", "order": 21550, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 3, "scene": 5, "line": "OK!", "order": 21551, "speaker": "Michael Scott"},
  {"season": 5, "episode": 3, "scene": 5, "line": "Do you have the Sharpie?!", "order": 21552, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 3, "scene": 5, "line": "Keep simulating.", "order": 21553, "speaker": "Michael Scott"},
  {"season": 5, "episode": 3, "scene": 5, "line": "Do you have the Sharpie?!", "order": 21554, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 3, "scene": 5, "line": "Yes, I do!", "order": 21555, "speaker": "Michael Scott"},
  {"season": 5, "episode": 3, "scene": 5, "line": "OK! When the baby emerges, mark it secretly in a kind of a mark that only you could recognize and no baby snatcher can ever copy.", "order": 21556, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 3, "scene": 5, "line": "OK.", "order": 21557, "speaker": "Michael Scott"},
  {"season": 5, "episode": 3, "scene": 5, "line": "Ready?!", "order": 21558, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 3, "scene": 5, "line": "Yes.", "order": 21559, "speaker": "Michael Scott"},
  {"season": 5, "episode": 3, "scene": 5, "line": "Aaaaaaaa!", "order": 21560, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 3, "scene": 5, "line": "Aaaaaaaa! It's coming! Here we go! [drops the watermelon onto the floor] Oh! God! Wow! What was on that?", "order": 21561, "speaker": "Michael Scott"},
  {"season": 5, "episode": 3, "scene": 5, "line": "Butter. Newborns are slippery.", "order": 21562, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 3, "scene": 5, "line": "Nice touch. Good. Let's try that again.", "order": 21563, "speaker": "Michael Scott"},
  {"season": 5, "episode": 3, "scene": 6, "line": "[eating the watermelon] It is going to be the happiest day of my life. [Dwight gets dressed behind Michael and zips his fly]", "order": 21564, "speaker": "Michael Scott"},
  {"season": 5, "episode": 3, "scene": 7, "line": "Hello ladies, how's my shower going? Phyllis, did you get the live storks?", "order": 21565, "speaker": "Michael Scott"},
  {"season": 5, "episode": 3, "scene": 7, "line": "No.", "order": 21566, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 3, "scene": 7, "line": "Damnit, Phyllis! I'm sorry. [more softly] Damnit, Phyllis. It was hard enough to convince Jan to come. So are we set for refreshments?", "order": 21567, "speaker": "Michael Scott"},
  {"season": 5, "episode": 3, "scene": 7, "line": "Per your instructions, we have the personalized M&Ms with the baby names. This is your boy bowl, with the name 'Chevy.' And this is the girl bowl, with M&Ms with the name 'Astird.'", "order": 21568, "speaker": "Angela Martin"},
  {"season": 5, "episode": 3, "scene": 7, "line": "That can't be right.", "order": 21569, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 3, "scene": 7, "line": "Michael wrote down 'Astird.'", "order": 21570, "speaker": "Angela Martin"},
  {"season": 5, "episode": 3, "scene": 7, "line": "She said it is the name of a Viking princess. So...", "order": 21571, "speaker": "Michael Scott"},
  {"season": 5, "episode": 3, "scene": 7, "line": "Ass...turd.", "order": 21572, "speaker": "Meredith Palmer"},
  {"season": 5, "episode": 3, "scene": 7, "line": "I know. I know. It is beautiful. No. Thank you. Is this it? I mean, is this... Two bowls of M&Ms and some balloons? You know what Phyllis, I think you need to step it up. I think you need to get the lead out. Because if I'm not mistaken, we gave you your wedding shower here. We all came into this room and gave you a golden shower. Well you know what, where's my golden shower, Phyllis?", "order": 21573, "speaker": "Michael Scott"},
  {"season": 5, "episode": 3, "scene": 8, "line": "It does not matter to me at all whether this baby is biologically mine. I am going to love it. It's like when the dog nurses the tiger cub. Have you seen that video? It is... it's so bizarre and unnatural, but... it, it happens.", "order": 21574, "speaker": "Michael Scott"},
  {"season": 5, "episode": 3, "scene": 9, "line": "Hey, I'm collecting for the baby shower.", "order": 21575, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 3, "scene": 9, "line": "But Phyllis, it's not his baby.", "order": 21576, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 3, "scene": 9, "line": "I know, Kevin.", "order": 21577, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 3, "scene": 9, "line": "Why do we have to pay money to get a gift for his ex-girlfriend's unborn sperm bank baby?", "order": 21578, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 3, "scene": 9, "line": "Look at it as if you're paying for the cake, not the baby. Sheet cake.", "order": 21579, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 3, "scene": 10, "line": "I do enjoy being the head of the Party Planning Committee. I'm no longer under Angela's heel, and her little grape head is under mine.", "order": 21580, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 3, "scene": 10, "line": "[banging on the glass] Hey, hey. What are you doing? Don't talk to them.", "order": 21581, "speaker": "Michael Scott"},
  {"season": 5, "episode": 3, "scene": 10, "line": "Sorry.", "order": 21582, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 3, "scene": 10, "line": "Make the party. Don't - make the party, please, Phyllis. [Phyllis starts inflating a balloon with a pump] Pump it!", "order": 21583, "speaker": "Michael Scott"},
  {"season": 5, "episode": 3, "scene": 11, "line": "[on phone] So you know Stacy, right?", "order": 21584, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 3, "scene": 11, "line": "Right, the one from England.", "order": 21585, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 3, "scene": 11, "line": "There's no one from England, Jim. Katie studied in Ireland - this is Stacy, the one who does the Murakami-style collages.", "order": 21586, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 3, "scene": 11, "line": "Oh. Right, that style.", "order": 21587, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 3, "scene": 11, "line": "So, Stacy and Eric get to Bogre's DeTech half an hour early so they don't have to sit on the slab.", "order": 21588, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 3, "scene": 11, "line": "OK...", "order": 21589, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 3, "scene": 11, "line": "Anyway, Bogre's TA Sarah Kaya comes in.", "order": 21590, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 3, "scene": 11, "line": "Wait, who's Sarah Kayacombsen?", "order": 21591, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 3, "scene": 11, "line": "No, Sarah Kaya comes in. Don't interrupt, I have like thirty seconds to finish this and get to DigiPres.", "order": 21592, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 3, "scene": 11, "line": "OK.", "order": 21593, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 3, "scene": 11, "line": "So Sarah Kaya says to Eric, 'No way. You can't reserve seats.' And Stacy gets all up in her face and Sarah Kaya picks up Stacy's new PM pad and throws it at the light box.", "order": 21594, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 3, "scene": 11, "line": "[clearly just humoring her] No... way!", "order": 21595, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 3, "scene": 11, "line": "Yes!", "order": 21596, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 3, "scene": 11, "line": "Ha ha. That's hilarious?", "order": 21597, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 3, "scene": 11, "line": "No, it's horrible! Jim, she might get arrested.", "order": 21598, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 3, "scene": 11, "line": "Who, Stacy?", "order": 21599, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 3, "scene": 11, "line": "No, Stacy is a boy. Ugh. Frustrating. Why can't you just be in art class with me?", "order": 21600, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 3, "scene": 11, "line": "Uh...", "order": 21601, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 3, "scene": 11, "line": "Oh, wait, I gotta go, class starting. [hangs up]", "order": 21602, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 3, "scene": 11, "line": "OK, I'll ta...I'll talk to you later. [hangs up] That was a good story.", "order": 21603, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 3, "scene": 11, "line": "Who's Sarah Kayacombsen?", "order": 21604, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 3, "scene": 12, "line": "Yo. I got the Nard-puppy for ya. What's going on here?", "order": 21605, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 3, "scene": 12, "line": "It's a baby game where you guess whose picture is whose.", "order": 21606, "speaker": "Angela Martin"},
  {"season": 5, "episode": 3, "scene": 12, "line": "Awww. Check it out - who would have thought that this little baby would be marrying that little baby?", "order": 21607, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 3, "scene": 12, "line": "That's Phyllis.", "order": 21608, "speaker": "Angela Martin"},
  {"season": 5, "episode": 3, "scene": 12, "line": "Well -", "order": 21609, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 3, "scene": 12, "line": "Yeah, it is. So I hope you two are very happy together. Pervert.", "order": 21610, "speaker": "Angela Martin"},
  {"season": 5, "episode": 3, "scene": 12, "line": "Why would that make me a pervert, I -", "order": 21611, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 3, "scene": 12, "line": "Well, it does. That's me.", "order": 21612, "speaker": "Angela Martin"},
  {"season": 5, "episode": 3, "scene": 12, "line": "That's not you.", "order": 21613, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 3, "scene": 12, "line": "Yes it is.", "order": 21614, "speaker": "Angela Martin"},
  {"season": 5, "episode": 3, "scene": 12, "line": "That's mean, come on, that's - [Angela storms out]", "order": 21615, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 3, "scene": 13, "line": "Ahoy, matey.", "order": 21616, "speaker": "Michael Scott"},
  {"season": 5, "episode": 3, "scene": 13, "line": "Ahoy.", "order": 21617, "speaker": "Holly Flax"},
  {"season": 5, "episode": 3, "scene": 13, "line": "Ahoy. So, how you doin'?", "order": 21618, "speaker": "Michael Scott"},
  {"season": 5, "episode": 3, "scene": 13, "line": "Good.", "order": 21619, "speaker": "Holly Flax"},
  {"season": 5, "episode": 3, "scene": 13, "line": "Um, listen. Jan Levinson is coming in today, and she is in the terminal stages of her pregnancy - the child of which I have a vested interest. It's all kind of weird. Anyway, she is incredibly... fat and enormous right now - extremely unattractive. And you are, on the other hand, one of the more attractive people in the office. So, while she's here I am going to be acting kind of cold to you. And I am doing this to pay respect to her bloated feelings. And I'm treating Ryan the same way.", "order": 21620, "speaker": "Michael Scott"},
  {"season": 5, "episode": 3, "scene": 13, "line": "Of course. Yeah.", "order": 21621, "speaker": "Holly Flax"},
  {"season": 5, "episode": 3, "scene": 14, "line": "I do not like pregnant women in my workspace. They're always complaining. I have varicose veins, too. I have swollen ankles, I'm constantly hungry - do you think my nipples don't get sore too? Do you think I don't need to know the fastest way to the hospital?", "order": 21622, "speaker": "Stanley Hudson"},
  {"season": 5, "episode": 3, "scene": 15, "line": "[Jan enters the office, pushing a stroller] Jan.", "order": 21623, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 3, "scene": 15, "line": "Hi, Jim!", "order": 21624, "speaker": "Jan Levinson"},
  {"season": 5, "episode": 3, "scene": 15, "line": "What do we have here?", "order": 21625, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 3, "scene": 15, "line": "This is my baby.", "order": 21626, "speaker": "Jan Levinson"},
  {"season": 5, "episode": 3, "scene": 15, "line": "Really...", "order": 21627, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 3, "scene": 15, "line": "Oh no... [Michael enters and notices Jan's baby]", "order": 21628, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 3, "scene": 16, "line": "Jan had the baby and Michael wasn't there to mark it. So the baby could be anybody's. Except Michael's.", "order": 21629, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 3, "scene": 17, "line": "So this is Astird.", "order": 21630, "speaker": "Michael Scott"},
  {"season": 5, "episode": 3, "scene": 17, "line": "Astrid.", "order": 21631, "speaker": "Jan Levinson"},
  {"season": 5, "episode": 3, "scene": 17, "line": "Oh, OK. Why didn't you call me?", "order": 21632, "speaker": "Michael Scott"},
  {"season": 5, "episode": 3, "scene": 17, "line": "Well, um, you know, labor just kind of started very suddenly and-", "order": 21633, "speaker": "Jan Levinson"},
  {"season": 5, "episode": 3, "scene": 17, "line": "I could have helped.", "order": 21634, "speaker": "Michael Scott"},
  {"season": 5, "episode": 3, "scene": 17, "line": "And the birth instructor thought it wasn't a good idea for you to be there, so...", "order": 21635, "speaker": "Jan Levinson"},
  {"season": 5, "episode": 3, "scene": 17, "line": "Oh, man. Him? That guy? He had no sense of humor and I proved him wrong in so many ways.", "order": 21636, "speaker": "Michael Scott"},
  {"season": 5, "episode": 3, "scene": 17, "line": "OK, you don't have any idea what I've been through, so, let's...", "order": 21637, "speaker": "Jan Levinson"},
  {"season": 5, "episode": 3, "scene": 17, "line": "No, I don't!", "order": 21638, "speaker": "Michael Scott"},
  {"season": 5, "episode": 3, "scene": 17, "line": "I'm sorry, I thought today at the baby shower would be a good time for the two of you to meet.", "order": 21639, "speaker": "Jan Levinson"},
  {"season": 5, "episode": 3, "scene": 17, "line": "Can I hold her?", "order": 21640, "speaker": "Michael Scott"},
  {"season": 5, "episode": 3, "scene": 17, "line": "Yes, yes you can. You know, I think, just leave her in the car seat. Yeah.", "order": 21641, "speaker": "Jan Levinson"},
  {"season": 5, "episode": 3, "scene": 17, "line": "[cradling the car seat awkwardly] All right, OK.", "order": 21642, "speaker": "Michael Scott"},
  {"season": 5, "episode": 3, "scene": 18, "line": "I love babies. I think they are beautiful in all sorts of different ways. I try to pick up and hold a baby every day, if possible, because it nourishes me. It feeds my soul. Babies are drawn to me, and I think it's because they see me as one of them. But cooler, and with my life put together a little bit. If a baby were president, there would be no taxes, there would be no war. There would be no government, and things could get terrible. It actually, probably - it would be a better screenplay idea than a serious suggestion.", "order": 21643, "speaker": "Michael Scott"},
  {"season": 5, "episode": 3, "scene": 19, "line": "[carrying the car seat] Ladies and gentlemen, it is my privilege and honor to present for the first time in her life and in the office: Assst...", "order": 21644, "speaker": "Michael Scott"},
  {"season": 5, "episode": 3, "scene": 19, "line": "trid.", "order": 21645, "speaker": "Jan Levinson"},
  {"season": 5, "episode": 3, "scene": 19, "line": "...trid Levinson. Hi! Astrid, this is everybody, look. This is your family. You're going to know them for the rest of your life.", "order": 21646, "speaker": "Michael Scott"},
  {"season": 5, "episode": 3, "scene": 19, "line": "Well...", "order": 21647, "speaker": "Jan Levinson"},
  {"season": 5, "episode": 3, "scene": 19, "line": "[looking at Creed] Well, he may not be here. Say hello! OK, here we go. [lifting the car seat high above his head] Lion King!", "order": 21648, "speaker": "Michael Scott"},
  {"season": 5, "episode": 3, "scene": 19, "line": "Michael, Michael, Michael, that's... yeah... that's too high. I'll take that.", "order": 21649, "speaker": "Jan Levinson"},
  {"season": 5, "episode": 3, "scene": 19, "line": "OK, come on, let's get our shower on. Conference room, choppity chip chop.", "order": 21650, "speaker": "Michael Scott"},
  {"season": 5, "episode": 3, "scene": 19, "line": "You OK? You seem kind of...", "order": 21651, "speaker": "Holly Flax"},
  {"season": 5, "episode": 3, "scene": 19, "line": "[sees Jan sizing up Holly] I'm fine, weirdo. Such an HR weirdo. Try not to suck all the air out of there when you walk in.", "order": 21652, "speaker": "Michael Scott"},
  {"season": 5, "episode": 3, "scene": 20, "line": "Ready to play some games? Let's do it!", "order": 21653, "speaker": "Michael Scott"},
  {"season": 5, "episode": 3, "scene": 20, "line": "Michael, the baby's already been born.", "order": 21654, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 3, "scene": 20, "line": "Uh, duh.", "order": 21655, "speaker": "Michael Scott"},
  {"season": 5, "episode": 3, "scene": 20, "line": "So we had games planned but the baby ruins all of them.", "order": 21656, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 3, "scene": 20, "line": "No, the baby doesn't ruin anything, Kevin, OK? The baby multiplies the fun. Let's just do what you were going to do.", "order": 21657, "speaker": "Michael Scott"},
  {"season": 5, "episode": 3, "scene": 20, "line": "OK... Who wants to guess when the baby will be born?", "order": 21658, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 3, "scene": 20, "line": "All right, how about some presents? I want to see some presents. [holding a memo pad] I got this so I can write down for thank-you notes.", "order": 21659, "speaker": "Michael Scott"},
  {"season": 5, "episode": 3, "scene": 20, "line": "Thank you. That's very sweet of you.", "order": 21660, "speaker": "Jan Levinson"},
  {"season": 5, "episode": 3, "scene": 20, "line": "Whatta we got?", "order": 21661, "speaker": "Michael Scott"},
  {"season": 5, "episode": 3, "scene": 20, "line": "Um, we all chipped in and got you this stroller.", "order": 21662, "speaker": "Angela Martin"},
  {"season": 5, "episode": 3, "scene": 20, "line": "Oh!", "order": 21663, "speaker": "Michael Scott"},
  {"season": 5, "episode": 3, "scene": 20, "line": "Thank you.", "order": 21664, "speaker": "Jan Levinson"},
  {"season": 5, "episode": 3, "scene": 20, "line": "She already has a stroller.", "order": 21665, "speaker": "Michael Scott"},
  {"season": 5, "episode": 3, "scene": 20, "line": "And this is way worse than the stroller she came with.", "order": 21666, "speaker": "Kelly Kapoor"},
  {"season": 5, "episode": 3, "scene": 20, "line": "She's got an Orbit; that's a twelve hundred dollar stroller.", "order": 21667, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 3, "scene": 20, "line": "Huh. Twelve hundred dollars for a stroller? Phhhbt.", "order": 21668, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 3, "scene": 20, "line": "OK, what else?", "order": 21669, "speaker": "Michael Scott"},
  {"season": 5, "episode": 3, "scene": 21, "line": "Twelve hundred dollars is what I spent on my whole bomb shelter. For that kind of money, this stroller should be indestructible. [straps the watermelon into the stroller on the elevator]", "order": 21670, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 3, "scene": 22, "line": "[singing 'Son of a Preacher Man' to Astrid] Being good isn't always easy / No matter how hard I try / When he started sweet-talking to me / He'd come and tell me everything is all right / He'd kiss and tell me everything is all right / Can I get away again tonight / The only one...", "order": 21671, "speaker": "Jan Levinson"},
  {"season": 5, "episode": 3, "scene": 23, "line": "Hey you!", "order": 21672, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 3, "scene": 23, "line": "[on phone] Hey! You busy?", "order": 21673, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 3, "scene": 23, "line": "Not even. I'm doing laundry for the first time in like a month.", "order": 21674, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 3, "scene": 23, "line": "OK, OK, so you gotta hear this. So, Jan's shower is going on right now, and she's singing 'Son of a Preacher Man' and everyone's just staring at her.", "order": 21675, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 3, "scene": 23, "line": "I can't hear anything, there's like, there's like machines going-", "order": 21676, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 3, "scene": 23, "line": "The song is about losing your virginity next to a church, and guess what - she's been singing for the last twenty minutes!", "order": 21677, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 3, "scene": 23, "line": "I can't hear anything!", "order": 21678, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 3, "scene": 23, "line": "Oh. OK, well you know what, I um, just call me later.", "order": 21679, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 3, "scene": 23, "line": "OK.", "order": 21680, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 3, "scene": 24, "line": "I'm not frustrated. Even if I were in Scranton, Jim and I would have days like this. We're just... a little out of sync. You know, that's all. Oh, great. I washed my lipstick.", "order": 21681, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 3, "scene": 25, "line": "[now belting out the lyrics, even though Astrid is asleep] How well I remember / The look that was in his eyes / Stealing kisses from me on the sly / Taking time to make time / Telling me that he's all mine / Learning from each other's knowing / Looking to see how much we've grown / And the only one...", "order": 21682, "speaker": "Jan Levinson"},
  {"season": 5, "episode": 3, "scene": 26, "line": "[heaving the stroller into a tire yard] Don't hit the fence. Oh no, my child!", "order": 21683, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 3, "scene": 27, "line": "[throwing the stroller against a high chain link fence] Don't get stuck on the barbed wire!", "order": 21684, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 3, "scene": 28, "line": "[tossing the stroller off a tall heap of tires] Playtime is over!", "order": 21685, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 3, "scene": 29, "line": "So Jan, tell my intended about the miracle of childbirth.", "order": 21686, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 3, "scene": 29, "line": "Well, actually, I, uh, I had a tub birth. And it was really, really quite amazing.", "order": 21687, "speaker": "Jan Levinson"},
  {"season": 5, "episode": 3, "scene": 29, "line": "You gave birth in a tub?", "order": 21688, "speaker": "Angela Martin"},
  {"season": 5, "episode": 3, "scene": 29, "line": "Yeah, it's a really nice transition from womb to world, you know, kind of like a big womb.", "order": 21689, "speaker": "Jan Levinson"},
  {"season": 5, "episode": 3, "scene": 29, "line": "Um, so you're in the tub with everything?", "order": 21690, "speaker": "Kelly Kapoor"},
  {"season": 5, "episode": 3, "scene": 29, "line": "Oh, yeah, the afterbirth floats, yeah.", "order": 21691, "speaker": "Jan Levinson"},
  {"season": 5, "episode": 3, "scene": 29, "line": "Must be like the tide at Omaha Beach.", "order": 21692, "speaker": "Creed Bratton"},
  {"season": 5, "episode": 3, "scene": 29, "line": "Oh no, it's actually really hygienic, Creed.", "order": 21693, "speaker": "Jan Levinson"},
  {"season": 5, "episode": 3, "scene": 29, "line": "Ugh.", "order": 21694, "speaker": "Creed Bratton"},
  {"season": 5, "episode": 3, "scene": 29, "line": "I'm done.", "order": 21695, "speaker": "Stanley Hudson"},
  {"season": 5, "episode": 3, "scene": 29, "line": "Me too.", "order": 21696, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 3, "scene": 29, "line": "And after the birth you get out and deliver the afterbirth. [everyone starts leaving]", "order": 21697, "speaker": "Jan Levinson"},
  {"season": 5, "episode": 3, "scene": 29, "line": "Hey! Hey, no no no no no no no no. No. No. This is the birth story. It was beautiful. It was beautiful. This is a once-in-a-lifetime experience. And I should have been there. I should have been there to witness this.", "order": 21698, "speaker": "Michael Scott"},
  {"season": 5, "episode": 3, "scene": 30, "line": "I could have helped.", "order": 21699, "speaker": "Michael Scott"},
  {"season": 5, "episode": 3, "scene": 30, "line": "You're sad about this. I know that, and I wish that I... but... would it help if you held Astrid for just a, a little bit?", "order": 21700, "speaker": "Jan Levinson"},
  {"season": 5, "episode": 3, "scene": 30, "line": "[smiling] Uh, yeah.", "order": 21701, "speaker": "Michael Scott"},
  {"season": 5, "episode": 3, "scene": 30, "line": "All right. Um, I'm just going to lie down for a couple of minutes. I'm exhausted, so um. Remind me to talk to you about Astrid's 529. Wake me in twenty.", "order": 21702, "speaker": "Jan Levinson"},
  {"season": 5, "episode": 3, "scene": 30, "line": "[voiceover] I usually love babies, but when I held Astrid I just felt... shortchanged.", "order": 21703, "speaker": "Michael Scott"},
  {"season": 5, "episode": 3, "scene": 30, "line": "Phyllis? Could you take-.", "order": 21704, "speaker": "Michael Scott"},
  {"season": 5, "episode": 3, "scene": 31, "line": "Hey guys.", "order": 21705, "speaker": "Michael Scott"},
  {"season": 5, "episode": 3, "scene": 31, "line": "What's up, Mike?", "order": 21706, "speaker": "Darryl Philbin"},
  {"season": 5, "episode": 3, "scene": 31, "line": "Uh, I need some advice from one baby daddy to another.", "order": 21707, "speaker": "Michael Scott"},
  {"season": 5, "episode": 3, "scene": 31, "line": "You a baby daddy?", "order": 21708, "speaker": "Darryl Philbin"},
  {"season": 5, "episode": 3, "scene": 31, "line": "Yeah, I a baby daddy. Um, when you first became a baby daddy, did you have an immediate connection with that baby? Like, the first time that you held it, did you find that with your baby baby?", "order": 21709, "speaker": "Michael Scott"},
  {"season": 5, "episode": 3, "scene": 31, "line": "Hell yeah. You know why? Because that was my baby.", "order": 21710, "speaker": "Darryl Philbin"},
  {"season": 5, "episode": 3, "scene": 31, "line": "I just saw this baby daddy-", "order": 21711, "speaker": "Michael Scott"},
  {"season": 5, "episode": 3, "scene": 31, "line": "You should stop calling yourself 'baby daddy.'", "order": 21712, "speaker": "Darryl Philbin"},
  {"season": 5, "episode": 3, "scene": 31, "line": "Why, Darryl, because I'm quote white quote unquote?", "order": 21713, "speaker": "Michael Scott"},
  {"season": 5, "episode": 3, "scene": 31, "line": "Because you're not a daddy, and it's not your baby.", "order": 21714, "speaker": "Darryl Philbin"},
  {"season": 5, "episode": 3, "scene": 31, "line": "Well-", "order": 21715, "speaker": "Michael Scott"},
  {"season": 5, "episode": 3, "scene": 31, "line": "You feel connected to his baby over there?", "order": 21716, "speaker": "Darryl Philbin"},
  {"season": 5, "episode": 3, "scene": 31, "line": "It's- that's different.", "order": 21717, "speaker": "Michael Scott"},
  {"season": 5, "episode": 3, "scene": 31, "line": "You feel connected to this? [hold up his back brace]", "order": 21718, "speaker": "Darryl Philbin"},
  {"season": 5, "episode": 3, "scene": 31, "line": "That's not a baby.", "order": 21719, "speaker": "Michael Scott"},
  {"season": 5, "episode": 3, "scene": 31, "line": "You want to hold me, to see how you feel?", "order": 21720, "speaker": "Darryl Philbin"},
  {"season": 5, "episode": 3, "scene": 31, "line": "[sighs] Could I?", "order": 21721, "speaker": "Michael Scott"},
  {"season": 5, "episode": 3, "scene": 31, "line": "No.", "order": 21722, "speaker": "Darryl Philbin"},
  {"season": 5, "episode": 3, "scene": 32, "line": "[wakes up on the reception couch] Oh. I was just catching up on my sleep.", "order": 21723, "speaker": "Jan Levinson"},
  {"season": 5, "episode": 3, "scene": 32, "line": "I can imagine.", "order": 21724, "speaker": "Holly Flax"},
  {"season": 5, "episode": 3, "scene": 32, "line": "Where's Astrid?", "order": 21725, "speaker": "Jan Levinson"},
  {"season": 5, "episode": 3, "scene": 32, "line": "Oh, I think she's on a sales call.", "order": 21726, "speaker": "Holly Flax"},
  {"season": 5, "episode": 3, "scene": 32, "line": "On a what?", "order": 21727, "speaker": "Jan Levinson"},
  {"season": 5, "episode": 3, "scene": 32, "line": "Waaa! More paper! Waaa! [she cracks herself up but Jan is unimpressed] No, she's just on a coffee break.", "order": 21728, "speaker": "Holly Flax"},
  {"season": 5, "episode": 3, "scene": 32, "line": "[sarcastically] That's funny.", "order": 21729, "speaker": "Jan Levinson"},
  {"season": 5, "episode": 3, "scene": 32, "line": "She's with Angela.", "order": 21730, "speaker": "Holly Flax"},
  {"season": 5, "episode": 3, "scene": 33, "line": "[with Astrid covered in and surrounded by vegetables on a lunch table] That's good. Now, I need you to - I need it to look up here. Get the baby to look up here, ready?", "order": 21731, "speaker": "Angela Martin"},
  {"season": 5, "episode": 3, "scene": 33, "line": "OK. Yeah. Look at my finger. Look. Looook!", "order": 21732, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 3, "scene": 33, "line": "Now. Come on, up here!", "order": 21733, "speaker": "Angela Martin"},
  {"season": 5, "episode": 3, "scene": 33, "line": "Look up here!", "order": 21734, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 3, "scene": 33, "line": "Your hand's in it.", "order": 21735, "speaker": "Angela Martin"},
  {"season": 5, "episode": 3, "scene": 33, "line": "What? Sorry.", "order": 21736, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 3, "scene": 33, "line": "Yeah, that's a good one.", "order": 21737, "speaker": "Angela Martin"},
  {"season": 5, "episode": 3, "scene": 33, "line": "What are you doing? What's going on? Excuse me.", "order": 21738, "speaker": "Jan Levinson"},
  {"season": 5, "episode": 3, "scene": 33, "line": "We're taking a picture.", "order": 21739, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 3, "scene": 33, "line": "[picking up Astrid] There you are.", "order": 21740, "speaker": "Jan Levinson"},
  {"season": 5, "episode": 3, "scene": 33, "line": "She's nature's bounty.", "order": 21741, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 3, "scene": 33, "line": "You don't flash around a newborn baby. Don't you know that?", "order": 21742, "speaker": "Jan Levinson"},
  {"season": 5, "episode": 3, "scene": 34, "line": "Michael, I need your help.", "order": 21743, "speaker": "Jan Levinson"},
  {"season": 5, "episode": 3, "scene": 34, "line": "[sees Holly behind Jan] I was just going to... I was going to talk to Holly, about her hygiene. She smells like old tomatoes and dirt.", "order": 21744, "speaker": "Michael Scott"},
  {"season": 5, "episode": 3, "scene": 34, "line": "Uh... come on.", "order": 21745, "speaker": "Jan Levinson"},
  {"season": 5, "episode": 3, "scene": 35, "line": "I like to call this... the bumper test. [drags the stroller behind his car and runs into it several times]", "order": 21746, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 3, "scene": 36, "line": "Sure you can't stay a little longer?", "order": 21747, "speaker": "Michael Scott"},
  {"season": 5, "episode": 3, "scene": 36, "line": "Oh, no, Michael, you know what? I really have to go.", "order": 21748, "speaker": "Jan Levinson"},
  {"season": 5, "episode": 3, "scene": 36, "line": "All right. All right, everybody, we're leaving. Jan and Astrid are leaving.", "order": 21749, "speaker": "Michael Scott"},
  {"season": 5, "episode": 3, "scene": 36, "line": "You know what, where is the stroller that I came in with?", "order": 21750, "speaker": "Jan Levinson"},
  {"season": 5, "episode": 3, "scene": 36, "line": "Oh. I took the liberty of putting it in your trunk.", "order": 21751, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 3, "scene": 36, "line": "Ah. Thanks, Dwight.", "order": 21752, "speaker": "Jan Levinson"},
  {"season": 5, "episode": 3, "scene": 37, "line": "OK. You know what? There is, uh, there is one more thing that you can do for me.", "order": 21753, "speaker": "Jan Levinson"},
  {"season": 5, "episode": 3, "scene": 37, "line": "OK?", "order": 21754, "speaker": "Michael Scott"},
  {"season": 5, "episode": 3, "scene": 37, "line": "Don't date Holly.", "order": 21755, "speaker": "Jan Levinson"},
  {"season": 5, "episode": 3, "scene": 37, "line": "Wha - that's, I hate her. Wha - God! Why would you even ask me to - I, I mean, not that it matters, 'cause I don't, but wha - OK, all right, fine.", "order": 21756, "speaker": "Michael Scott"},
  {"season": 5, "episode": 3, "scene": 37, "line": "Thanks for the baby shower. It was great. And I'll see you... soon.", "order": 21757, "speaker": "Jan Levinson"},
  {"season": 5, "episode": 3, "scene": 37, "line": "All right.", "order": 21758, "speaker": "Michael Scott"},
  {"season": 5, "episode": 3, "scene": 37, "line": "OK.", "order": 21759, "speaker": "Jan Levinson"},
  {"season": 5, "episode": 3, "scene": 38, "line": "You still gonna be mean to me?", "order": 21760, "speaker": "Holly Flax"},
  {"season": 5, "episode": 3, "scene": 38, "line": "[just hugs her, and she hugs him back and smiles] You wanna go out?", "order": 21761, "speaker": "Michael Scott"},
  {"season": 5, "episode": 3, "scene": 38, "line": "Yes.", "order": 21762, "speaker": "Holly Flax"},
  {"season": 5, "episode": 3, "scene": 39, "line": "I didn't feel much when I held Astrid, but I got a good feeling from Holly.", "order": 21763, "speaker": "Michael Scott"},
  {"season": 5, "episode": 3, "scene": 40, "line": "[on voicemail] Hey, it's Jim. Leave a message.", "order": 21764, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 3, "scene": 40, "line": "Hi.", "order": 21765, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 3, "scene": 40, "line": "[on voicemail, because she's calling Jim at the same time] Hi, this is Pam. Leave a message.", "order": 21766, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 3, "scene": 40, "line": "[walking to his car] Hey, it's me. It is 5:03.", "order": 21767, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 3, "scene": 40, "line": "I figured I'd catch you walking to your car, but...", "order": 21768, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 3, "scene": 40, "line": "You must be out or something.", "order": 21769, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 3, "scene": 40, "line": "I'll leave a message.", "order": 21770, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 3, "scene": 40, "line": "Is it me, or are we just a little off today?", "order": 21771, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 3, "scene": 40, "line": "I guess this is just one of those days. It'll get better.", "order": 21772, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 3, "scene": 40, "line": "Hope you didn't have any major laundry issues.", "order": 21773, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 3, "scene": 40, "line": "I finished my laundry. Got all my socks. Nothing like that time that crazy guy pushed you.", "order": 21774, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 3, "scene": 40, "line": "Remember that time that I helped you do your laundry and that crazy guy came in and started yelling at you?", "order": 21775, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 3, "scene": 40, "line": "And then, remember, we went shopping the next day to buy me a washer and dryer?", "order": 21776, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 3, "scene": 40, "line": "Yet here you are, back in a Laundromat. Now, I'm just trying to help you, Beesly. Be safe.", "order": 21777, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 3, "scene": 40, "line": "You're probably upset that I'm even at a Laundromat right now. But, don't worry, I'm being safe. And I'm headed home. I'm... headed to my dorm. Not home.", "order": 21778, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 3, "scene": 40, "line": "Wish you were home. Uh, anyway...", "order": 21779, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 3, "scene": 40, "line": "Anyway, um... I miss you.", "order": 21780, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 3, "scene": 40, "line": "I miss you.", "order": 21781, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 3, "scene": 41, "line": "All right, how about we play the game where we guess how big the mother's belly is?", "order": 21782, "speaker": "Angela Martin"},
  {"season": 5, "episode": 3, "scene": 41, "line": "Oh! OK!", "order": 21783, "speaker": "Michael Scott"},
  {"season": 5, "episode": 3, "scene": 41, "line": "Well...", "order": 21784, "speaker": "Jan Levinson"},
  {"season": 5, "episode": 3, "scene": 41, "line": "How many squares of toilet paper go around the waist?", "order": 21785, "speaker": "Angela Martin"},
  {"season": 5, "episode": 3, "scene": 41, "line": "Does that mean there's no toilet paper in the bathroom?", "order": 21786, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 3, "scene": 41, "line": "Kevin, relax.", "order": 21787, "speaker": "Angela Martin"},
  {"season": 5, "episode": 3, "scene": 41, "line": "OK, you know what, I don't think I need to do this one, because I only gained twelve pounds and I'm just going to get rid of all of that, so...", "order": 21788, "speaker": "Jan Levinson"},
  {"season": 5, "episode": 3, "scene": 41, "line": "Seventeen. I say seventeen squares.", "order": 21789, "speaker": "Michael Scott"},
  {"season": 5, "episode": 3, "scene": 41, "line": "OK, give me the roll. I'll do it.", "order": 21790, "speaker": "Jan Levinson"},
  {"season": 5, "episode": 3, "scene": 41, "line": "Do the boobs!", "order": 21791, "speaker": "Michael Scott"},
  {"season": 5, "episode": 3, "scene": 41, "line": "Yeah, we're not doing my boobs, Michael. OK, seven, someone give me the prize. [to Astrid] Oh, it's OK...", "order": 21792, "speaker": "Jan Levinson"},
  {"season": 5, "episode": 3, "scene": 41, "line": "Did she really just have a baby? She's so beautiful.", "order": 21793, "speaker": "Holly Flax"},
  {"season": 5, "episode": 3, "scene": 41, "line": "Oh yeah. Wait 'til you get to know her better though.", "order": 21794, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 3, "scene": 41, "line": "Hey! HR lady, stop whispering in the corner please. Very rude. You're ruining the party.", "order": 21795, "speaker": "Michael Scott"},
  {"season": 5, "episode": 3, "scene": 42, "line": "I'm starting to get a feeling for what life was like around here for Toby.", "order": 21796, "speaker": "Holly Flax"},
  {"season": 5, "episode": 3, "scene": 42, "line": "[on the phone] It was terrible.", "order": 21797, "speaker": "Toby Flenderson"},
  {"season": 5, "episode": 3, "scene": 42, "line": "I know.", "order": 21798, "speaker": "Holly Flax"},
  {"season": 5, "episode": 3, "scene": 42, "line": "I prefer being stuck in a Costa Rican hospital.", "order": 21799, "speaker": "Toby Flenderson"},
  {"season": 5, "episode": 3, "scene": 42, "line": "Really?", "order": 21800, "speaker": "Holly Flax"},
  {"season": 5, "episode": 3, "scene": 42, "line": "Not really.", "order": 21801, "speaker": "Toby Flenderson"},
  {"season": 5, "episode": 3, "scene": 43, "line": "Hey Jan, you went to a sperm bank?", "order": 21802, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 3, "scene": 43, "line": "Uh, yes, I did.", "order": 21803, "speaker": "Jan Levinson"},
  {"season": 5, "episode": 3, "scene": 43, "line": "I donated sperm.", "order": 21804, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 3, "scene": 43, "line": "Oh.", "order": 21805, "speaker": "Jan Levinson"},
  {"season": 5, "episode": 3, "scene": 43, "line": "Maybe I'm the father.", "order": 21806, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 3, "scene": 43, "line": "Well, this is a highly exclusive establishment.", "order": 21807, "speaker": "Jan Levinson"},
  {"season": 5, "episode": 3, "scene": 43, "line": "Next to the IHOP?", "order": 21808, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 3, "scene": 43, "line": "Well, I paid for an ideal specimen.", "order": 21809, "speaker": "Jan Levinson"},
  {"season": 5, "episode": 3, "scene": 43, "line": "Are you saying Kevin is not an ideal specimen?", "order": 21810, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 3, "scene": 43, "line": "This is a wonderful shower, everyone. Thank you.", "order": 21811, "speaker": "Jan Levinson"},
  {"season": 5, "episode": 3, "scene": 43, "line": "[to camera] I might have done it with Jan!", "order": 21812, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 3, "scene": 44, "line": "It's not Kevin's child. Can't possibly be. I mean, I don't know what I would do. Sue... icide?", "order": 21813, "speaker": "Jan Levinson"},
  {"season": 5, "episode": 3, "scene": 45, "line": "[Jan nurses Astrid with her top down, office workers try to work] Okay, 'Stridly, you want to try the other side? Okay, here we go. That's good. Oh, good job.", "order": 21814, "speaker": "Jan Levinson"},
  {"season": 5, "episode": 3, "scene": 45, "line": "Jan? What's new?", "order": 21815, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 3, "scene": 45, "line": "You know what, Kevin? Why don't you just go ahead and stare? Because, you know, it's, it's fine with me. I mean, this is sooo natural, and so beautiful, and...", "order": 21816, "speaker": "Jan Levinson"},
  {"season": 5, "episode": 3, "scene": 45, "line": "Seriously? You're okay with that?", "order": 21817, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 3, "scene": 45, "line": "Of course. I mean, there's nothing erotic about this, you know, it's, it's, it's what these [shakes a breast] were made for, you know, I mean they're full of baby milk, they're... nourishing my baby, so... [tosses head proudly, Creed comes up to stand next to Kevin, staring directly at Jan's chest] Michael!", "order": 21818, "speaker": "Jan Levinson"},
  {"season": 5, "episode": 3, "scene": 46, "line": "Hello, spoiled little baby, [to a watermelon] in your fancy brand name stroller. Mmm, I wonder if it's as safe as they say it is. Oh, look, a curb. Uh oh, let's see what happens... [lets it go, it flies off the curb, stays upright, and stops safely] Inconclusive. [pushes it out into the street, it rolls to a stop] Spartans would leave a weak baby by the side of the road. My parents left me beside the road. I crawled home.", "order": 21819, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 3, "scene": 47, "line": "Not so weak, huh, Mom?", "order": 21820, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 3, "scene": 48, "line": "Gosh, Mommy feels like taking a jog. [runs with stroller] Oh, no! Mommy forgot to wear a bra, and her big fake boobs are really hurting her, and she needs to let go, she can't control the stroller any longer [forcefully pushes stroller away toward uneven ground with junk strewn around, the stroller hits some junk, stays upright, and stops safely]", "order": 21821, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 3, "scene": 49, "line": "Let's see what this baby can do... [drives car fast, holding stroller alongside the car, stroller remains fine] Aaaah! Aa-aaah! [throws it ahead of the car, it goes for a while and then is stopping] It's a hardy stroller. [takes watermelon out of stroller, holds it up to show camera] Maybe it's safe!", "order": 21822, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 4, "scene": 1, "line": "Pam, line three.", "order": 21823, "speaker": "Receptionist"},
  {"season": 5, "episode": 4, "scene": 1, "line": "Okay, thanks.", "order": 21824, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 4, "scene": 2, "line": "New York, as it turns out, is very expensive, and I ran out of money. I thought about selling a kidney, but Michael offered to get me a part-time job at corporate.", "order": 21825, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 4, "scene": 3, "line": "Hey.", "order": 21826, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 4, "scene": 3, "line": "[on phone] Paaam-o-laaaaaa...", "order": 21827, "speaker": "Michael Scott"},
  {"season": 5, "episode": 4, "scene": 4, "line": "Of course, now Michael knows where to reach me 16 to 18 hours a week.", "order": 21828, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 4, "scene": 5, "line": "Hey Michael.", "order": 21829, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 4, "scene": 5, "line": "[on phone] Paaam-o-laaaaaa... Miss ya kiddo. Miss you... so much.", "order": 21830, "speaker": "Michael Scott"},
  {"season": 5, "episode": 4, "scene": 6, "line": "Hey.", "order": 21831, "speaker": "Michael Scott"},
  {"season": 5, "episode": 4, "scene": 6, "line": "Hello.", "order": 21832, "speaker": "Holly Flax"},
  {"season": 5, "episode": 4, "scene": 6, "line": "That was really fun last night.", "order": 21833, "speaker": "Michael Scott"},
  {"season": 5, "episode": 4, "scene": 6, "line": "Yeah, it was nice.", "order": 21834, "speaker": "Holly Flax"},
  {"season": 5, "episode": 4, "scene": 6, "line": "I'm actually thinking about getting my own set of putt-putt golf clubs.", "order": 21835, "speaker": "Michael Scott"},
  {"season": 5, "episode": 4, "scene": 6, "line": "Oh, that would be great. You need that.", "order": 21836, "speaker": "Holly Flax"},
  {"season": 5, "episode": 4, "scene": 6, "line": "Yeah?", "order": 21837, "speaker": "Michael Scott"},
  {"season": 5, "episode": 4, "scene": 6, "line": "Yeah.", "order": 21838, "speaker": "Holly Flax"},
  {"season": 5, "episode": 4, "scene": 6, "line": "Oh really?", "order": 21839, "speaker": "Michael Scott"},
  {"season": 5, "episode": 4, "scene": 6, "line": "Yeah.", "order": 21840, "speaker": "Holly Flax"},
  {"season": 5, "episode": 4, "scene": 6, "line": "Well...", "order": 21841, "speaker": "Michael Scott"},
  {"season": 5, "episode": 4, "scene": 6, "line": "It would help.", "order": 21842, "speaker": "Holly Flax"},
  {"season": 5, "episode": 4, "scene": 6, "line": "I, uh... [to camera] I let her win.", "order": 21843, "speaker": "Michael Scott"},
  {"season": 5, "episode": 4, "scene": 6, "line": "[to camera] No, he didn't.", "order": 21844, "speaker": "Holly Flax"},
  {"season": 5, "episode": 4, "scene": 6, "line": "So, when, um, can I see you again?", "order": 21845, "speaker": "Michael Scott"},
  {"season": 5, "episode": 4, "scene": 6, "line": "Um, tonight, I'm free tonight. Is that too eager? [laughs]", "order": 21846, "speaker": "Holly Flax"},
  {"season": 5, "episode": 4, "scene": 6, "line": "No.", "order": 21847, "speaker": "Michael Scott"},
  {"season": 5, "episode": 4, "scene": 6, "line": "I don't care, free tonight.", "order": 21848, "speaker": "Holly Flax"},
  {"season": 5, "episode": 4, "scene": 6, "line": "Okay. Oh, wait, oh, tonight's no good. [Holly's smile quickly fades] Because I am busy taking you out.", "order": 21849, "speaker": "Michael Scott"},
  {"season": 5, "episode": 4, "scene": 6, "line": "[gasps] Oh, I just remembered, I can't tonight.", "order": 21850, "speaker": "Holly Flax"},
  {"season": 5, "episode": 4, "scene": 6, "line": "[concerned] Why?", "order": 21851, "speaker": "Michael Scott"},
  {"season": 5, "episode": 4, "scene": 6, "line": "I'm going out with you.", "order": 21852, "speaker": "Holly Flax"},
  {"season": 5, "episode": 4, "scene": 6, "line": "Wow... Oh, wait a second, I can't tonight...", "order": 21853, "speaker": "Michael Scott"},
  {"season": 5, "episode": 4, "scene": 6, "line": "[shakes head] No more.", "order": 21854, "speaker": "Holly Flax"},
  {"season": 5, "episode": 4, "scene": 6, "line": "Tonight's so...", "order": 21855, "speaker": "Michael Scott"},
  {"season": 5, "episode": 4, "scene": 6, "line": "No more.", "order": 21856, "speaker": "Holly Flax"},
  {"season": 5, "episode": 4, "scene": 6, "line": "Too many times. It's all good, um, alright, so, good, so, have a nice day.", "order": 21857, "speaker": "Michael Scott"},
  {"season": 5, "episode": 4, "scene": 6, "line": "Thank you, you too.", "order": 21858, "speaker": "Holly Flax"},
  {"season": 5, "episode": 4, "scene": 6, "line": "You're welcome.", "order": 21859, "speaker": "Michael Scott"},
  {"season": 5, "episode": 4, "scene": 7, "line": "In my opinion, the third date is traditionally the one where... you have sex. Does Holly feel that way? I don't know. I will probably find out tonight. If she starts having sex with me I'll know for sure.", "order": 21860, "speaker": "Michael Scott"},
  {"season": 5, "episode": 4, "scene": 8, "line": "That's for you. And, um, oh. I am right in assuming that Dwight is short for D-Money. 'Cause that's what I wrote on your save-the-date.", "order": 21861, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 4, "scene": 8, "line": "[whispering] You set a date?", "order": 21862, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 4, "scene": 8, "line": "J-Money. Or should it be T-Money, for Tuna? Receptionist Money. K-Money.", "order": 21863, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 4, "scene": 9, "line": "[sigh] What are you making?", "order": 21864, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 4, "scene": 9, "line": "A knife.", "order": 21865, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 4, "scene": 9, "line": "You're making a knife with a knife?", "order": 21866, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 4, "scene": 9, "line": "You got a better way?", "order": 21867, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 4, "scene": 9, "line": "You want to talk about it?", "order": 21868, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 4, "scene": 9, "line": "About what?", "order": 21869, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 4, "scene": 9, "line": "You know I know. [looks at the camera guys] You know they know.", "order": 21870, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 4, "scene": 9, "line": "I know none of that. If I did, you'd be the last to know.", "order": 21871, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 4, "scene": 10, "line": "Oh, the mall could be fun.", "order": 21872, "speaker": "Holly Flax"},
  {"season": 5, "episode": 4, "scene": 10, "line": "Yeah.", "order": 21873, "speaker": "Michael Scott"},
  {"season": 5, "episode": 4, "scene": 10, "line": "We could go to the food court and get different foods. You could get Chicken Teriyaki. I could get a hot dog.", "order": 21874, "speaker": "Holly Flax"},
  {"season": 5, "episode": 4, "scene": 10, "line": "Some of what we order depends on whether we're having sex after. [laughs] Oh, my. Wow, elephant in the room. Are we, do you think? Do you think we're going to have sex tonight?", "order": 21875, "speaker": "Michael Scott"},
  {"season": 5, "episode": 4, "scene": 10, "line": "...Hell yeah.", "order": 21876, "speaker": "Holly Flax"},
  {"season": 5, "episode": 4, "scene": 10, "line": "Okay, so, we do the restaurant thing and then... then... then we can do... We'll just do the restaurant thing first.", "order": 21877, "speaker": "Michael Scott"},
  {"season": 5, "episode": 4, "scene": 10, "line": "Yeah, that's good.", "order": 21878, "speaker": "Holly Flax"},
  {"season": 5, "episode": 4, "scene": 11, "line": "Probably get soup or something light.", "order": 21879, "speaker": "Michael Scott"},
  {"season": 5, "episode": 4, "scene": 12, "line": "Hi.", "order": 21880, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 4, "scene": 12, "line": "She introduced me to so many things. Pasteurized milk, sheets, monotheism, presents on your birthday, preventative medicine.", "order": 21881, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 4, "scene": 12, "line": "It's nice to learn new things.", "order": 21882, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 4, "scene": 12, "line": "I was talking to myself.", "order": 21883, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 4, "scene": 12, "line": "Okay.", "order": 21884, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 4, "scene": 12, "line": "I just don't get it.", "order": 21885, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 4, "scene": 12, "line": "What don't you get?", "order": 21886, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 4, "scene": 12, "line": "Why is she marrying Andy?", "order": 21887, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 4, "scene": 12, "line": "Angela's not really a risk taker. And Andy's not really a risk.", "order": 21888, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 4, "scene": 12, "line": "That's really fattening.", "order": 21889, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 4, "scene": 12, "line": "No, it's lettuce.", "order": 21890, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 4, "scene": 13, "line": "[playing cards] It's time to go.", "order": 21891, "speaker": "Holly Flax"},
  {"season": 5, "episode": 4, "scene": 13, "line": "No. No. Reservations are at 8:00, so we've got like an hour and 45 minutes. [sighs] [goofy voice] I'm a crazy eight, I'm crazy.", "order": 21892, "speaker": "Michael Scott"},
  {"season": 5, "episode": 4, "scene": 13, "line": "You're crazy, go crazy.", "order": 21893, "speaker": "Holly Flax"},
  {"season": 5, "episode": 4, "scene": 14, "line": "[walking out of the building] Oh, I forgot my keys.", "order": 21894, "speaker": "Holly Flax"},
  {"season": 5, "episode": 4, "scene": 14, "line": "Do you need 'em?", "order": 21895, "speaker": "Michael Scott"},
  {"season": 5, "episode": 4, "scene": 14, "line": "Yeah, lets go grab them.", "order": 21896, "speaker": "Holly Flax"},
  {"season": 5, "episode": 4, "scene": 14, "line": "All right.", "order": 21897, "speaker": "Michael Scott"},
  {"season": 5, "episode": 4, "scene": 14, "line": "Oh, after vous. [walking into the girls bathroom]", "order": 21898, "speaker": "Holly Flax"},
  {"season": 5, "episode": 4, "scene": 14, "line": "Thank vous. [chuckling] What? Oh! [kissing]", "order": 21899, "speaker": "Michael Scott"},
  {"season": 5, "episode": 4, "scene": 14, "line": "I didn't forget my keys, I just didn't want to make out with you in front of the cameras. Do you think they can hear us?", "order": 21900, "speaker": "Holly Flax"},
  {"season": 5, "episode": 4, "scene": 14, "line": "Not if we turn these dials [getting louder] all the way down. [whispering] Now they can't hear us at all.", "order": 21901, "speaker": "Michael Scott"},
  {"season": 5, "episode": 4, "scene": 14, "line": "Oh, good.", "order": 21902, "speaker": "Holly Flax"},
  {"season": 5, "episode": 4, "scene": 14, "line": "We're totally alone.", "order": 21903, "speaker": "Michael Scott"},
  {"season": 5, "episode": 4, "scene": 15, "line": "Yes, that's correct. I need to cancel checks from 10,001 to 90,000.", "order": 21904, "speaker": "Angela Martin"},
  {"season": 5, "episode": 4, "scene": 15, "line": "Great, they stole my laptop.", "order": 21905, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 4, "scene": 15, "line": "Yeah, well, they stole my surge protector.", "order": 21906, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 4, "scene": 15, "line": "How does that even compare?", "order": 21907, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 4, "scene": 15, "line": "Oscar, I'm now going to be prone to surges.", "order": 21908, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 4, "scene": 16, "line": "Oh, my God, what happened?", "order": 21909, "speaker": "Michael Scott"},
  {"season": 5, "episode": 4, "scene": 16, "line": "We were robbed last night.", "order": 21910, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 4, "scene": 16, "line": "Bravo, Watson. Looks like a classic seven-man job. Okay, security tapes were stolen, Motives-financial, or possible, vintage HP computer collectors. Hank down at security had clocked out. And that's all we have.", "order": 21911, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 4, "scene": 16, "line": "Can I talk to you for a second?", "order": 21912, "speaker": "Holly Flax"},
  {"season": 5, "episode": 4, "scene": 16, "line": "Yeah. Oh... oh!", "order": 21913, "speaker": "Michael Scott"},
  {"season": 5, "episode": 4, "scene": 16, "line": "That wasn't us, right? I mean... you remembered to lock the doors?", "order": 21914, "speaker": "Holly Flax"},
  {"season": 5, "episode": 4, "scene": 16, "line": "No, did you?", "order": 21915, "speaker": "Michael Scott"},
  {"season": 5, "episode": 4, "scene": 16, "line": "Michael I think this is our fault.", "order": 21916, "speaker": "Holly Flax"},
  {"season": 5, "episode": 4, "scene": 16, "line": "Oh, no, my God.", "order": 21917, "speaker": "Michael Scott"},
  {"season": 5, "episode": 4, "scene": 16, "line": "Oh...", "order": 21918, "speaker": "Holly Flax"},
  {"season": 5, "episode": 4, "scene": 16, "line": "Oh my God!", "order": 21919, "speaker": "Michael Scott"},
  {"season": 5, "episode": 4, "scene": 17, "line": "So much for sex without consequences.", "order": 21920, "speaker": "Michael Scott"},
  {"season": 5, "episode": 4, "scene": 18, "line": "[on phone] [Jim holds up phone] You are such a dork! Shots!", "order": 21921, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 4, "scene": 18, "line": "So apparently Pam went out last night, and accidentally called my work phone at 3:00 in the morning.", "order": 21922, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 4, "scene": 18, "line": "[on phone] I'm not drunk.", "order": 21923, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 4, "scene": 18, "line": "I'm on minute six of this message.", "order": 21924, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 4, "scene": 18, "line": "[on phone] Okay, I do not sound like that.", "order": 21925, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 4, "scene": 18, "line": "You can take the girl out of Philly...", "order": 21926, "speaker": "Man on phone"},
  {"season": 5, "episode": 4, "scene": 18, "line": "[on phone] Scranton.", "order": 21927, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 4, "scene": 18, "line": "The future mother of my children.", "order": 21928, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 4, "scene": 19, "line": "I never felt safe here.", "order": 21929, "speaker": "Angela Martin"},
  {"season": 5, "episode": 4, "scene": 19, "line": "You're always safe with me. I'm a very good screamer. And one day, we're going to move to Disney's Celebration Village in Florida and leave all of this behind.", "order": 21930, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 4, "scene": 19, "line": "I would very much like that.", "order": 21931, "speaker": "Angela Martin"},
  {"season": 5, "episode": 4, "scene": 19, "line": "Yeah?", "order": 21932, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 4, "scene": 19, "line": "Yeah.", "order": 21933, "speaker": "Angela Martin"},
  {"season": 5, "episode": 4, "scene": 19, "line": "[cockney accent] Consider yourself... at home!", "order": 21934, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 4, "scene": 19, "line": "[Dwight groans] Ugh.", "order": 21935, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 4, "scene": 20, "line": "Get in.", "order": 21936, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 4, "scene": 20, "line": "Where are we going?", "order": 21937, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 4, "scene": 20, "line": "I know she loves me, Phyllis. I am sure of it. She practically told me so.", "order": 21938, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 4, "scene": 20, "line": "Then you need to give her an ultimatum. Tell her she needs to make a choice. You or Andy. If she pick you, great. If not, you can move on.", "order": 21939, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 4, "scene": 20, "line": "Are you sure that's going to work?", "order": 21940, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 4, "scene": 20, "line": "It did when Bob said I had to stop talking to my sister on the phone so much.", "order": 21941, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 4, "scene": 20, "line": "Wait, this isn't our floor. Dwight...", "order": 21942, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 4, "scene": 21, "line": "How's everyone doing? My door is always open if anyone wants to talk.", "order": 21943, "speaker": "Holly Flax"},
  {"season": 5, "episode": 4, "scene": 21, "line": "I don't want to talk. I want my laptop back.", "order": 21944, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 4, "scene": 22, "line": "Nobody steals from Creed Bratton and gets away with it. The last person to do this disappeared. His name? [whispering] Creed Bratton.", "order": 21945, "speaker": "Creed Bratton"},
  {"season": 5, "episode": 4, "scene": 23, "line": "We're gonna make everything all right. Alright? Conference room, 15 minutes. Half an hour. Alright?", "order": 21946, "speaker": "Michael Scott"},
  {"season": 5, "episode": 4, "scene": 24, "line": "To recoup their losses, I am planning a little charity auction, where people from all over Scranton can come and bid on our goods and services. I'm calling it crime-aid. It's like farm aid, but instead of farms fighting against AIDS, it is us fighting against our own poverty.", "order": 21947, "speaker": "Michael Scott"},
  {"season": 5, "episode": 4, "scene": 25, "line": "I think it's a fun idea.", "order": 21948, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 4, "scene": 25, "line": "Thank you.", "order": 21949, "speaker": "Michael Scott"},
  {"season": 5, "episode": 4, "scene": 25, "line": "We could auction off things we do for each other like cleaning or tennis lessons. My sorority did it all the time.", "order": 21950, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 4, "scene": 25, "line": "Okay, I'm gonna have to stop you right there. Was this a sorority that you didn't get into a real sorority so you had to kind of form your own?", "order": 21951, "speaker": "Michael Scott"},
  {"season": 5, "episode": 4, "scene": 25, "line": "Move on, Michael.", "order": 21952, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 4, "scene": 25, "line": "Okay. Okay! Well, come on, let's have an auction. Let's do this. We'll auction off people like in the olden days.", "order": 21953, "speaker": "Michael Scott"},
  {"season": 5, "episode": 4, "scene": 25, "line": "So, in order to recoup the value of items we liked, we have to spend more money on things we don't want. Who would ever come to this?", "order": 21954, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 4, "scene": 25, "line": "I don't know, it could be any number of people. It could be a pedestrian. It could be an old person. It could be a lookie-loo. Or, it could be... a Bruce Springsteen fan - what? Who said that? I did. Why did I say that? Oh, I think you know why I said that. I think it is very apparent. I think it goes without saying. Bear with me. There's a point there. But what is the point? I don't understand what he's saying. It seems a little shady. It seems a little foggy. Well, it's not a little foggy there's actually something going on here-", "order": 21955, "speaker": "Michael Scott"},
  {"season": 5, "episode": 4, "scene": 25, "line": "Do you need us for any of this?", "order": 21956, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 4, "scene": 25, "line": "Do I?", "order": 21957, "speaker": "Michael Scott"},
  {"season": 5, "episode": 4, "scene": 26, "line": "Michael scored the big ticket item. Springsteen tickets! The boss scored the boss.", "order": 21958, "speaker": "Holly Flax"},
  {"season": 5, "episode": 4, "scene": 26, "line": "Yeah, I think that's pretty boss.", "order": 21959, "speaker": "Michael Scott"},
  {"season": 5, "episode": 4, "scene": 26, "line": "He knows how to get things. He got me.", "order": 21960, "speaker": "Holly Flax"},
  {"season": 5, "episode": 4, "scene": 26, "line": "Whoa!", "order": 21961, "speaker": "Michael Scott"},
  {"season": 5, "episode": 4, "scene": 26, "line": "[giggles] Sorry.", "order": 21962, "speaker": "Holly Flax"},
  {"season": 5, "episode": 4, "scene": 26, "line": "Twice. ... Right?", "order": 21963, "speaker": "Michael Scott"},
  {"season": 5, "episode": 4, "scene": 26, "line": "Mm-hmm.", "order": 21964, "speaker": "Holly Flax"},
  {"season": 5, "episode": 4, "scene": 26, "line": "Mm-hmm.", "order": 21965, "speaker": "Michael Scott"},
  {"season": 5, "episode": 4, "scene": 26, "line": "Mm-hmm.", "order": 21966, "speaker": "Holly Flax"},
  {"season": 5, "episode": 4, "scene": 27, "line": "Listen to me close. 'Cause I'm only gonna say this once. You either break off your engagement with that singing buffoon, apologize, and then date me, or you can say good-bye to this. [points below his belt]", "order": 21967, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 4, "scene": 27, "line": "I think you have me confused with another person.", "order": 21968, "speaker": "Angela Martin"},
  {"season": 5, "episode": 4, "scene": 27, "line": "I said I was only gonna say this once. You have until 6:14 PM. 6:14!", "order": 21969, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 4, "scene": 27, "line": "I heard you. [quickly walks away]", "order": 21970, "speaker": "Angela Martin"},
  {"season": 5, "episode": 4, "scene": 28, "line": "Pump it up! Yeah! Yeah! Yeah! [Huey Lewis' Heart of Rock and Roll plays] Pump up the volume. Thank you very much, everybody. Thank you, Mr. Springsteen. [music stops] And welcome to C.R.I.M.E.-A.I.D. [clears throat] Crime Reduces Innocence Makes Everyone Angry I Declare. It is not known how many office robberies occur every second because there is no Wikipedia entry for office robbery statistics. However, tonight, its victims, are standing together and standing strong in the form of song, cooking lessons, and hugs. Really Phyllis? You're auctioning a hug? Okay, so with no further ado, lights, camera, auction! Take it away Bruce! [Huey Lewis' Heart of Rock and Roll plays]", "order": 21971, "speaker": "Michael Scott"},
  {"season": 5, "episode": 4, "scene": 29, "line": "Mike gave me a list of his top ten Springsteen songs. Three of them were Huey Lewis and the News. One was Tracy Chapman, Fast Car. And my personal favorite, Short People.", "order": 21972, "speaker": "Darryl Philbin"},
  {"season": 5, "episode": 4, "scene": 30, "line": "Okay, this is the moment that you have all been waiting for. Our first item up for bids tonight is something that I consider to be very boss indeed. [cheers and applause] It is a Yoga lesson from Holly Flax! Yoga! And I would like to start the bidding off at 300 dollars. [auctioneer voice] 300 dollars do I hear 300 dollars? 300 dollars. Hey, batter batter batter, hey batter, swing batter, 300 dollars, 300 dollars, 300 dollars. Anybody, 300 dollars. 400 dollars. Who wants for 400-[speaking gibberish] Do I see somebody in the back? Is there somebody in the back? Do I see somebody in the back? 400-450 [speaking gibberish] Sold! For 300 dollars to me! [bangs gavel, which squeaks] What the hell is that?", "order": 21973, "speaker": "Michael Scott"},
  {"season": 5, "episode": 4, "scene": 30, "line": "It's the only gavel I could find.", "order": 21974, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 4, "scene": 30, "line": "It squeaks when you bang it, that's what she said. Let's hear it for me! Right? A bargain at any price!", "order": 21975, "speaker": "Michael Scott"},
  {"season": 5, "episode": 4, "scene": 30, "line": "Hey Mike, do my thing.", "order": 21976, "speaker": "Darryl Philbin"},
  {"season": 5, "episode": 4, "scene": 30, "line": "Oh, yes, great. Very good, Um, so, Darryl, and the boys in the hood in the warehouse, have graciously donated to, uh, go out for a beer with them right now. You know what, I'm actually going to bid on this. I'm going to start the bidding, because this is something that I have dreamt of-", "order": 21977, "speaker": "Michael Scott"},
  {"season": 5, "episode": 4, "scene": 30, "line": "Mike, you can't do that. It's conflict of interest.", "order": 21978, "speaker": "Darryl Philbin"},
  {"season": 5, "episode": 4, "scene": 30, "line": "5 dollars.", "order": 21979, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 4, "scene": 30, "line": "Sold! To Jim.", "order": 21980, "speaker": "Darryl Philbin"},
  {"season": 5, "episode": 4, "scene": 30, "line": "Okay. Okay, sold! Have fun, you guys. Well, this next item is sure to spring steam from your ears if you don't win it. It just says 'Creed.'", "order": 21981, "speaker": "Michael Scott"},
  {"season": 5, "episode": 4, "scene": 30, "line": "Yeah, that's all-inclusive.", "order": 21982, "speaker": "Creed Bratton"},
  {"season": 5, "episode": 4, "scene": 31, "line": "She thought I was McNabb. [laughing]", "order": 21983, "speaker": "Darryl Philbin"},
  {"season": 5, "episode": 4, "scene": 31, "line": "I can see that.", "order": 21984, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 4, "scene": 31, "line": "Yeah, well, watcha gonna do?", "order": 21985, "speaker": "Darryl Philbin"},
  {"season": 5, "episode": 4, "scene": 31, "line": "Another round, boys? Great. [Jim walks to the bar]", "order": 21986, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 4, "scene": 31, "line": "Halpert?", "order": 21987, "speaker": "Roy Anderson"},
  {"season": 5, "episode": 4, "scene": 31, "line": "What's up, Roy?", "order": 21988, "speaker": "Darryl Philbin"},
  {"season": 5, "episode": 4, "scene": 31, "line": "Hey man.", "order": 21989, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 4, "scene": 31, "line": "I'm not gonna hit you or anything.", "order": 21990, "speaker": "Roy Anderson"},
  {"season": 5, "episode": 4, "scene": 31, "line": "Oh, I wouldn't-[clears throat]", "order": 21991, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 4, "scene": 31, "line": "You good?", "order": 21992, "speaker": "Roy Anderson"},
  {"season": 5, "episode": 4, "scene": 31, "line": "I'm good. How you doing?", "order": 21993, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 4, "scene": 31, "line": "I'm good.", "order": 21994, "speaker": "Roy Anderson"},
  {"season": 5, "episode": 4, "scene": 31, "line": "Yeah, okay.", "order": 21995, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 4, "scene": 31, "line": "Hey Darryl, what's happenin'?", "order": 21996, "speaker": "Roy Anderson"},
  {"season": 5, "episode": 4, "scene": 31, "line": "Oh, what's up, Roy?", "order": 21997, "speaker": "Darryl Philbin"},
  {"season": 5, "episode": 4, "scene": 31, "line": "Hey, what's up, guys? How you been?", "order": 21998, "speaker": "Roy Anderson"},
  {"season": 5, "episode": 4, "scene": 32, "line": "Hi, I'm Kevin. And I'll do your taxes.", "order": 21999, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 4, "scene": 32, "line": "Let's hear an opening bid, everybody. Who's first? Kevin do your taxes. He's the tax man. Kevin the tax man.", "order": 22000, "speaker": "Michael Scott"},
  {"season": 5, "episode": 4, "scene": 32, "line": "Federal and State.", "order": 22001, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 4, "scene": 32, "line": "Federal and State. [no one bids]", "order": 22002, "speaker": "Michael Scott"},
  {"season": 5, "episode": 4, "scene": 32, "line": "Fine. [throws down microphone, knocks down microphone stand]", "order": 22003, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 4, "scene": 32, "line": "Okay, alright. Hey, you know what? I would appreciate it if people would stop storming off the stage.", "order": 22004, "speaker": "Michael Scott"},
  {"season": 5, "episode": 4, "scene": 33, "line": "[playing blues on a guitar] Me and the blues. It's me and the blues. [Dwight looks at his watch, then at Angela, who looks away from Dwight towards Andy]", "order": 22005, "speaker": "Hank Tate"},
  {"season": 5, "episode": 4, "scene": 34, "line": "We are getting there folks. Slow and steady. [David Wallace walks in] Well, well, have we been blessed today. Ladies and gentlemen, Mr. David Wallace! Whoo!", "order": 22006, "speaker": "Michael Scott"},
  {"season": 5, "episode": 4, "scene": 34, "line": "Hey.", "order": 22007, "speaker": "David Wallace"},
  {"season": 5, "episode": 4, "scene": 34, "line": "Hey!", "order": 22008, "speaker": "Michael Scott"},
  {"season": 5, "episode": 4, "scene": 34, "line": "Thank you Michael, thanks, thanks everybody. I'd like to auction off a weekend at my place in Martha's Vineyard. Hey, hey!", "order": 22009, "speaker": "David Wallace"},
  {"season": 5, "episode": 4, "scene": 34, "line": "100 dollars.", "order": 22010, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 4, "scene": 34, "line": "100 dollars, great.", "order": 22011, "speaker": "Michael Scott"},
  {"season": 5, "episode": 4, "scene": 34, "line": "125", "order": 22012, "speaker": "Bob"},
  {"season": 5, "episode": 4, "scene": 34, "line": "140", "order": 22013, "speaker": "Guy in audience"},
  {"season": 5, "episode": 4, "scene": 34, "line": "Hey Phyllis, someone let the air out of your tires. Come quick.", "order": 22014, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 4, "scene": 34, "line": "Oh dear.", "order": 22015, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 4, "scene": 34, "line": "Keep it going. Hey batter batter batter.", "order": 22016, "speaker": "Michael Scott"},
  {"season": 5, "episode": 4, "scene": 34, "line": "160", "order": 22017, "speaker": "Guy in audience"},
  {"season": 5, "episode": 4, "scene": 34, "line": "160", "order": 22018, "speaker": "Michael Scott"},
  {"season": 5, "episode": 4, "scene": 35, "line": "I had to get your attention. This is an emergency. [the air is let out of one of Phyllis' tires]", "order": 22019, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 4, "scene": 35, "line": "Why couldn't have you just said it? Why did you actually do it?", "order": 22020, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 4, "scene": 35, "line": "Listen, she ignored my ultimatum. Now what?", "order": 22021, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 4, "scene": 35, "line": "Now you move on.", "order": 22022, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 4, "scene": 35, "line": "Okay, fine. I've moved on. Now how do I get her back?", "order": 22023, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 4, "scene": 35, "line": "Well Dwight, I don't think you do. I think you've got your answer.", "order": 22024, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 4, "scene": 35, "line": "Wait, that's it? That's your advice? I thought you had some kind of big master plan.", "order": 22025, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 4, "scene": 35, "line": "Well, I just think we all deserve to be with someone who wants to be with us.", "order": 22026, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 4, "scene": 35, "line": "Oh. I get it. You're trying to secure your place as head of the party-planning committee. You're just being selfish. [Phyllis slaps Dwight] And you slap like a girl.", "order": 22027, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 4, "scene": 36, "line": "What did Phyllis do wrong? I'll tell you what Phyllis did wrong. She stuck her nose into my business and tried to help me. [Dwight has an epiphany]", "order": 22028, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 4, "scene": 37, "line": "What's going on with Pam?", "order": 22029, "speaker": "Roy Anderson"},
  {"season": 5, "episode": 4, "scene": 37, "line": "Oh, she's good actually.", "order": 22030, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 4, "scene": 37, "line": "Yeah?", "order": 22031, "speaker": "Roy Anderson"},
  {"season": 5, "episode": 4, "scene": 37, "line": "She's, um, she's in New York. She's at an art school.", "order": 22032, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 4, "scene": 37, "line": "Really?", "order": 22033, "speaker": "Roy Anderson"},
  {"season": 5, "episode": 4, "scene": 37, "line": "Yeah, she's doing really well.", "order": 22034, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 4, "scene": 37, "line": "Huh.", "order": 22035, "speaker": "Roy Anderson"},
  {"season": 5, "episode": 4, "scene": 37, "line": "She's engaged, um... to me.", "order": 22036, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 4, "scene": 37, "line": "[laughs] Congrats, man.", "order": 22037, "speaker": "Roy Anderson"},
  {"season": 5, "episode": 4, "scene": 37, "line": "Oh, thanks man. Appreciate that.", "order": 22038, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 4, "scene": 37, "line": "So Pam's happy?", "order": 22039, "speaker": "Roy Anderson"},
  {"season": 5, "episode": 4, "scene": 37, "line": "Yeah, I'd say she's happy. I mean, she loves her classes. Loves the city. I mean, last night, she was out with her friends, till, like, 8:00 AM.", "order": 22040, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 4, "scene": 37, "line": "Wow.", "order": 22041, "speaker": "Roy Anderson"},
  {"season": 5, "episode": 4, "scene": 37, "line": "What?", "order": 22042, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 4, "scene": 37, "line": "I thought you were a friend.", "order": 22043, "speaker": "Roy Anderson"},
  {"season": 5, "episode": 4, "scene": 38, "line": "[driving] I am going to see Pam. Because I feel like I should. [sighs]", "order": 22044, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 4, "scene": 39, "line": "When are you getting to Bruce?", "order": 22045, "speaker": "Bob"},
  {"season": 5, "episode": 4, "scene": 39, "line": "Uh, just a few more items.", "order": 22046, "speaker": "Michael Scott"},
  {"season": 5, "episode": 4, "scene": 39, "line": "[chanting] Bruce! Bruce! Bruce!", "order": 22047, "speaker": "All"},
  {"season": 5, "episode": 4, "scene": 39, "line": "All right, all right, all right, all right, all right! Here we go. All right, big ticket item. [cheering] All right, in my pocket, I have... two tickets... to Mr. Bruce Springsteen, front... Oh, where are those? Has anyone seen an envelope with Bruce Springsteen front row tickets and backstage passes? Anybody seen something like-lying around?", "order": 22048, "speaker": "Michael Scott"},
  {"season": 5, "episode": 4, "scene": 39, "line": "Do you want us to look for 'em?", "order": 22049, "speaker": "Stanley Hudson"},
  {"season": 5, "episode": 4, "scene": 39, "line": "No. I think they were stolen. I think they were stolen and they're gone forever. So... oh, that was the last good item. So, good job, everybody.", "order": 22050, "speaker": "Michael Scott"},
  {"season": 5, "episode": 4, "scene": 39, "line": "I have my hug.", "order": 22051, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 4, "scene": 39, "line": "Yeah, well, Phyllis, nobody... really wants a hug, so.", "order": 22052, "speaker": "Michael Scott"},
  {"season": 5, "episode": 4, "scene": 39, "line": "I'll bid on a hug.", "order": 22053, "speaker": "Bob"},
  {"season": 5, "episode": 4, "scene": 39, "line": "She's your wife, you idiot.", "order": 22054, "speaker": "Michael Scott"},
  {"season": 5, "episode": 4, "scene": 39, "line": "100 dollars.", "order": 22055, "speaker": "Bob"},
  {"season": 5, "episode": 4, "scene": 39, "line": "200", "order": 22056, "speaker": "David Wallace"},
  {"season": 5, "episode": 4, "scene": 39, "line": "Uh, what the hell is happening?", "order": 22057, "speaker": "Michael Scott"},
  {"season": 5, "episode": 4, "scene": 39, "line": "250", "order": 22058, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 4, "scene": 39, "line": "What are you doing?", "order": 22059, "speaker": "Angela Martin"},
  {"season": 5, "episode": 4, "scene": 39, "line": "I need a hug, unless you're gonna give me one.", "order": 22060, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 4, "scene": 39, "line": "Not here.", "order": 22061, "speaker": "Angela Martin"},
  {"season": 5, "episode": 4, "scene": 39, "line": "300", "order": 22062, "speaker": "Bob"},
  {"season": 5, "episode": 4, "scene": 39, "line": "300, we have 300. 300 going once. 300 going twice.", "order": 22063, "speaker": "Michael Scott"},
  {"season": 5, "episode": 4, "scene": 39, "line": "300 and one penny.", "order": 22064, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 4, "scene": 39, "line": "300 and one penny, 300 and one penny.", "order": 22065, "speaker": "Michael Scott"},
  {"season": 5, "episode": 4, "scene": 39, "line": "325", "order": 22066, "speaker": "Bob"},
  {"season": 5, "episode": 4, "scene": 39, "line": "325, 325.", "order": 22067, "speaker": "Michael Scott"},
  {"season": 5, "episode": 4, "scene": 39, "line": "350", "order": 22068, "speaker": "David Wallace"},
  {"season": 5, "episode": 4, "scene": 39, "line": "350", "order": 22069, "speaker": "Michael Scott"},
  {"season": 5, "episode": 4, "scene": 39, "line": "350 and one penny.", "order": 22070, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 4, "scene": 39, "line": "350 and one penny.", "order": 22071, "speaker": "Michael Scott"},
  {"season": 5, "episode": 4, "scene": 39, "line": "400", "order": 22072, "speaker": "Bob"},
  {"season": 5, "episode": 4, "scene": 39, "line": "400, 400 hey batter batter.", "order": 22073, "speaker": "Michael Scott"},
  {"season": 5, "episode": 4, "scene": 39, "line": "400 and one penny.", "order": 22074, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 4, "scene": 39, "line": "400 and one penny.", "order": 22075, "speaker": "Michael Scott"},
  {"season": 5, "episode": 4, "scene": 39, "line": "500", "order": 22076, "speaker": "Bob"},
  {"season": 5, "episode": 4, "scene": 39, "line": "500", "order": 22077, "speaker": "Michael Scott"},
  {"season": 5, "episode": 4, "scene": 39, "line": "500 and one penny.", "order": 22078, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 4, "scene": 39, "line": "500 and one penny. One million dollar!", "order": 22079, "speaker": "Michael Scott"},
  {"season": 5, "episode": 4, "scene": 39, "line": "700", "order": 22080, "speaker": "Bob"},
  {"season": 5, "episode": 4, "scene": 39, "line": "700", "order": 22081, "speaker": "Michael Scott"},
  {"season": 5, "episode": 4, "scene": 39, "line": "700 and one penny.", "order": 22082, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 4, "scene": 39, "line": "Humuna-humuna-humuna-humuna-humuna-", "order": 22083, "speaker": "Michael Scott"},
  {"season": 5, "episode": 4, "scene": 39, "line": "1000", "order": 22084, "speaker": "Bob"},
  {"season": 5, "episode": 4, "scene": 39, "line": "Eh, it's not worth it.", "order": 22085, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 4, "scene": 39, "line": "1,000 going once, 1,000 going twice. Sold for 1,000 dollar. [applause] Nicely done.", "order": 22086, "speaker": "Michael Scott"},
  {"season": 5, "episode": 4, "scene": 40, "line": "[driving, sighs, laughs] No. you know what? No. Because... I'm not that guy. And [laughs] we are not that couple. [turns around]", "order": 22087, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 4, "scene": 41, "line": "[whispering] Hey, that looks good. Listen, about the tickets. It's sort of a gray-kind of a gray area in terms of...whether or not I had them- [Holly covers Michaels mouth]", "order": 22088, "speaker": "Michael Scott"},
  {"season": 5, "episode": 4, "scene": 42, "line": "The Springsteen tickets seemed too good to be true. But, a lot of Michael seems too good to be true. So far it's all true. But yeah, those tickets, really seemed too good to be true.", "order": 22089, "speaker": "Holly Flax"},
  {"season": 5, "episode": 4, "scene": 43, "line": "[after accidentally seeing Michael and Holly kiss] No, I did not know that Michael was dating Holly.", "order": 22090, "speaker": "David Wallace"},
  {"season": 5, "episode": 4, "scene": 44, "line": "What can I do for you, Michael? I'm kinda busy.", "order": 22091, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 4, "scene": 44, "line": "Oh, I guess that job that I got you is working out for you well, then.", "order": 22092, "speaker": "Michael Scott"},
  {"season": 5, "episode": 4, "scene": 44, "line": "It is. Thanks again.", "order": 22093, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 4, "scene": 44, "line": "Hey, if there's anything else I can do, just let me know.", "order": 22094, "speaker": "Michael Scott"},
  {"season": 5, "episode": 4, "scene": 44, "line": "[long pause] And of course, if there's ever anything I can ever do for you...", "order": 22095, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 4, "scene": 44, "line": "You know what? I would love a slice of real New York style pizza.", "order": 22096, "speaker": "Michael Scott"},
  {"season": 5, "episode": 4, "scene": 44, "line": "If I get you a slice of pizza, are we even?", "order": 22097, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 4, "scene": 44, "line": "Even as pie.", "order": 22098, "speaker": "Michael Scott"},
  {"season": 5, "episode": 4, "scene": 45, "line": "[opening a delivery envelope, takes out slice of pizza which appears to have a section off the end] Okay... don't ask me how I got it, let's just say that I have... connections. [folds it in two and takes a bite] Mmm. Mmm. [with a full mouth and an Italian accent] I'm the Godfather. I'm gonna eat this whole pizza. And then I'm gonna kill you. [digs around in envelope, pulls out the rest of the slice] [indistinct] some cheese. You gotta fold it up. That's the way you eat pizza.", "order": 22099, "speaker": "Michael Scott"},
  {"season": 5, "episode": 4, "scene": 46, "line": "I just don't know if he's presidential enough.", "order": 22100, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 4, "scene": 46, "line": "I know. And how long is CJ gonna spin this whole MS thing? How can Josh and Sam keep quiet?", "order": 22101, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 4, "scene": 47, "line": "Every night, Jim and I watch an episode of The West Wing together on DVD. Um, he wanted to watch Battlestar Galactica and I wanted to watch Cranford... so we compromised. Next we're gonna watch Cranford.", "order": 22102, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 4, "scene": 48, "line": "Cranford? No. We will never watch that.", "order": 22103, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 4, "scene": 49, "line": "I am going to run three possible date ideas by you and I want you to tell me which one gets your blood percolating.", "order": 22104, "speaker": "Michael Scott"},
  {"season": 5, "episode": 4, "scene": 49, "line": "Ah, I'm sure they're all great.", "order": 22105, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 4, "scene": 49, "line": "I have two sure things, one wildcard.", "order": 22106, "speaker": "Michael Scott"},
  {"season": 5, "episode": 4, "scene": 49, "line": "Let's start with the wildcard.", "order": 22107, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 4, "scene": 49, "line": "Okay.", "order": 22108, "speaker": "Michael Scott"},
  {"season": 5, "episode": 4, "scene": 49, "line": "Mmm-hmm.", "order": 22109, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 4, "scene": 49, "line": "The wildcard is dinner at a nice restaurant.", "order": 22110, "speaker": "Michael Scott"},
  {"season": 5, "episode": 4, "scene": 49, "line": "Uh-oh.", "order": 22111, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 4, "scene": 49, "line": "Exactly. Here, here are my sure things. I blindfold her. I take her to the Houdini Museum. She chains me up...", "order": 22112, "speaker": "Michael Scott"},
  {"season": 5, "episode": 4, "scene": 49, "line": "I think I got a pretty good sense of that one.", "order": 22113, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 4, "scene": 49, "line": "Okay.", "order": 22114, "speaker": "Michael Scott"},
  {"season": 5, "episode": 4, "scene": 49, "line": "Yep.", "order": 22115, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 4, "scene": 49, "line": "All right.", "order": 22116, "speaker": "Michael Scott"},
  {"season": 5, "episode": 4, "scene": 49, "line": "Let's move on to the next.", "order": 22117, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 4, "scene": 49, "line": "Number two, we go to the mall...", "order": 22118, "speaker": "Michael Scott"},
  {"season": 5, "episode": 4, "scene": 49, "line": "Mmm-hmm.", "order": 22119, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 4, "scene": 49, "line": "We go to the bed store, I let her choose whatever bed she wants...", "order": 22120, "speaker": "Michael Scott"},
  {"season": 5, "episode": 4, "scene": 49, "line": "Oh, that's good.", "order": 22121, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 4, "scene": 49, "line": "And we have sex on it.", "order": 22122, "speaker": "Michael Scott"},
  {"season": 5, "episode": 4, "scene": 49, "line": "I like the wild card. I'm already liking the wild card.", "order": 22123, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 4, "scene": 49, "line": "Really? I don't know. It's a big third date, Jim.", "order": 22124, "speaker": "Michael Scott"},
  {"season": 5, "episode": 4, "scene": 49, "line": "Mmm-hmm.", "order": 22125, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 4, "scene": 49, "line": "What do I do?", "order": 22126, "speaker": "Michael Scott"},
  {"season": 5, "episode": 4, "scene": 49, "line": "Have you asked her what she wants to do?", "order": 22127, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 4, "scene": 49, "line": "Well, I thought as the man that I should make the decision.", "order": 22128, "speaker": "Michael Scott"},
  {"season": 5, "episode": 4, "scene": 49, "line": "No. You got that backwards.", "order": 22129, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 4, "scene": 49, "line": "Times have changed, Jim.", "order": 22130, "speaker": "Michael Scott"},
  {"season": 5, "episode": 4, "scene": 50, "line": "Our first date was at the Anthracite Coal Museum. We had both been before, but we pretended like it was our first time so we could get all the extra information. They knew we were faking... 'cause they recognized us. But we made them tell us all the stories. All the safety information. And we corrected them whenever they got it wrong. [Phyllis chuckles and nods, Dwight sighs and takes the rest of his sandwich out of the container and holds it up to Phyllis] You want half a sandwich?", "order": 22131, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 4, "scene": 50, "line": "Okay.", "order": 22132, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 4, "scene": 50, "line": "I'll bring you one tomorrow. You've earned it. [bites into the sandwich half, chews] Mmm. I hope you like fox meat.", "order": 22133, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 5, "scene": 1, "line": "[dressed as Carrie Bradshaw] Wow you guys look amazing. Stanley, I thought you hated Halloween.", "order": 22134, "speaker": "Kelly Kapoor"},
  {"season": 5, "episode": 5, "scene": 1, "line": "[dressed as Raggedy Ann] Shh. He wears that so he can sleep at his desk. Who are you?", "order": 22135, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 5, "scene": 1, "line": "Oh, I'm Carrie Bradshaw from Sex And The City.", "order": 22136, "speaker": "Kelly Kapoor"},
  {"season": 5, "episode": 5, "scene": 1, "line": "Mm. I like your shoes. [Kelly has 5-inch heels on]", "order": 22137, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 5, "scene": 1, "line": "Thank you. Will you help walk me to the fax machine?", "order": 22138, "speaker": "Kelly Kapoor"},
  {"season": 5, "episode": 5, "scene": 1, "line": "Sure.", "order": 22139, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 5, "scene": 1, "line": "I got her, I got her. I can help you. You look amazing.", "order": 22140, "speaker": "Ryan Howard"},
  {"season": 5, "episode": 5, "scene": 1, "line": "Inappropriate. Thank you. Who are you, Larry King?", "order": 22141, "speaker": "Kelly Kapoor"},
  {"season": 5, "episode": 5, "scene": 1, "line": "Gordon Gekko.", "order": 22142, "speaker": "Ryan Howard"},
  {"season": 5, "episode": 5, "scene": 1, "line": "Oh, from the insurance commercials!", "order": 22143, "speaker": "Kelly Kapoor"},
  {"season": 5, "episode": 5, "scene": 1, "line": "... Yeah.", "order": 22144, "speaker": "Ryan Howard"},
  {"season": 5, "episode": 5, "scene": 2, "line": "[Creed dressed as the Joker enters] Whoa. Awesome.", "order": 22145, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 5, "scene": 2, "line": "Let's put a smile on that face!", "order": 22146, "speaker": "Creed Bratton"},
  {"season": 5, "episode": 5, "scene": 2, "line": "[also dressed as the Joker] Dammit Creed! I've been up since four!", "order": 22147, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 5, "scene": 3, "line": "[dressed as a kitten] Meow. Sweet 'stume, dude. Who are you supposed to be?", "order": 22148, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 5, "scene": 3, "line": "Dave.", "order": 22149, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 5, "scene": 3, "line": "Cool.", "order": 22150, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 5, "scene": 3, "line": "You are? [Andy hisses] A cat?", "order": 22151, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 5, "scene": 3, "line": "[buzzer noise] We were looking for 'kitten.'", "order": 22152, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 5, "scene": 3, "line": "[phone] Oh, hang on one second. Jim Halpert.", "order": 22153, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 5, "scene": 3, "line": "Hey.", "order": 22154, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 5, "scene": 3, "line": "Hey New York, Happy Halloween!", "order": 22155, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 5, "scene": 3, "line": "Thanks. My costume's getting a lot of attention. [Pam is dressed as Charlie Chaplin]", "order": 22156, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 5, "scene": 4, "line": "So apparently no one dresses up for Halloween here. I wish I had known that before I used greasepaint for my moustache. And I can't even take off my hat, because then I'm Hitler.", "order": 22157, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 5, "scene": 5, "line": "[dressed as the Joker] Hm mm mm mm. Want to see a magic trick? Heh heh heh! I'm gonna make a pencil disapp-- oh! [elevator doors close, reopen.] Disappear.", "order": 22158, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 5, "scene": 6, "line": "Okay, I think we are set. We have puzzles, string for Cat's Cradles. Burned this last night. A little road trip CD. Puppets.", "order": 22159, "speaker": "Michael Scott"},
  {"season": 5, "episode": 5, "scene": 6, "line": "Oh, look at all this stuff! It's only seven hours.", "order": 22160, "speaker": "Holly Flax"},
  {"season": 5, "episode": 5, "scene": 7, "line": "When Corporate found out that we were dating, they decided they were going to transfer Holly back to her old branch, in Nashua, New Hampshire.", "order": 22161, "speaker": "Michael Scott"},
  {"season": 5, "episode": 5, "scene": 8, "line": "Michael is taking a personal day to move me up.", "order": 22162, "speaker": "Holly Flax"},
  {"season": 5, "episode": 5, "scene": 8, "line": "Road trip! Right?", "order": 22163, "speaker": "Michael Scott"},
  {"season": 5, "episode": 5, "scene": 8, "line": "Breaker one-nine, copy?", "order": 22164, "speaker": "Holly Flax"},
  {"season": 5, "episode": 5, "scene": 8, "line": "Oh, copy that breaker. Those Duke boys are at it again.", "order": 22165, "speaker": "Michael Scott"},
  {"season": 5, "episode": 5, "scene": 8, "line": "Hey! Do Not Touch My Radio.", "order": 22166, "speaker": "Darryl Philbin"},
  {"season": 5, "episode": 5, "scene": 8, "line": "We're not.", "order": 22167, "speaker": "Michael Scott"},
  {"season": 5, "episode": 5, "scene": 8, "line": "Kidding.", "order": 22168, "speaker": "Holly Flax"},
  {"season": 5, "episode": 5, "scene": 8, "line": "We're not doing anything.", "order": 22169, "speaker": "Michael Scott"},
  {"season": 5, "episode": 5, "scene": 9, "line": "It's been a weird week since we found out I had to transfer. Michael wanted me to quit and get some job here in Scranton, and I said 'Well, why don't you quit and get some job in Nashua?' And he said 'I asked you first.' And I said 'First!' at the same time he did. And then I said 'Jinx.' And then we never talked about it again and haven't been back to the conversation. So...", "order": 22170, "speaker": "Holly Flax"},
  {"season": 5, "episode": 5, "scene": 10, "line": "All right everybody. I'm out of here. Jim, you're in charge.", "order": 22171, "speaker": "Michael Scott"},
  {"season": 5, "episode": 5, "scene": 10, "line": "Oh, I'll walk you out.", "order": 22172, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 5, "scene": 10, "line": "Ah, you are quite the gentleman.", "order": 22173, "speaker": "Michael Scott"},
  {"season": 5, "episode": 5, "scene": 11, "line": "You can let people go a couple of minutes early if you want.", "order": 22174, "speaker": "Michael Scott"},
  {"season": 5, "episode": 5, "scene": 11, "line": "All right. We'll see. [to camera] No.", "order": 22175, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 5, "scene": 12, "line": "I am off to New York. My brother Pete from Boston, and my brother Tom from New Jersey are taking Pam and I out for lunch, to celebrate the engagement. Or maybe to beat me up. I can never tell with those two.", "order": 22176, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 5, "scene": 13, "line": "Good morning temp.", "order": 22177, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 5, "scene": 13, "line": "Morning. [Dwight reveals Cornell sweatshirt] Wow. Good morning Dwight.", "order": 22178, "speaker": "Ryan Howard"},
  {"season": 5, "episode": 5, "scene": 13, "line": "Thought I'd go casual today. Morning everyone. Good morning. Hello. How are you Phyllis?", "order": 22179, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 5, "scene": 13, "line": "Ha ha ha. That's funny. [angrily] Take that sweatshirt off! Hey buddy.", "order": 22180, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 5, "scene": 13, "line": "Andy.", "order": 22181, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 5, "scene": 13, "line": "Remember when I jokingly yelled at you to take your sweatshirt off? Totally joking. But, you should know, those colors are sacred. Not that I care. But if you're not a Cornell man, you probably shouldn't wear them.", "order": 22182, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 5, "scene": 13, "line": "No I get it. I totally understand. And uh, I just want to assure you, that I mean no disrespect. You see, I'm applying!", "order": 22183, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 5, "scene": 13, "line": "Come on, you think you can get into Cornell?", "order": 22184, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 5, "scene": 13, "line": "Well if somebody who barely out-sells Phyllis, can get in, I should be fine.", "order": 22185, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 5, "scene": 13, "line": "I'm sitting right here Dwight.", "order": 22186, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 5, "scene": 13, "line": "I meant that as a compliment to you Phyllis, as well as a slight to Andy.", "order": 22187, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 5, "scene": 14, "line": "Cornell is a good school, and I want to better myself through higher education. If it makes Andy angry, so be it. [He sips from his Cornell mug]", "order": 22188, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 5, "scene": 15, "line": "I have to unpack this weekend but maybe next weekend we go to the outlets!", "order": 22189, "speaker": "Holly Flax"},
  {"season": 5, "episode": 5, "scene": 15, "line": "Cool!", "order": 22190, "speaker": "Michael Scott"},
  {"season": 5, "episode": 5, "scene": 15, "line": "Mike you'll drive this every weekend?", "order": 22191, "speaker": "Darryl Philbin"},
  {"season": 5, "episode": 5, "scene": 15, "line": "We're gonna switch back and forth, the driving. Sometimes we'll just meet in the middle. It'll be fun. Wait a sec. Oh I love this song.", "order": 22192, "speaker": "Michael Scott"},
  {"season": 5, "episode": 5, "scene": 15, "line": "Life is a highway, I want to ride it all night long.", "order": 22193, "speaker": "Michael Scott & Holly Flax"},
  {"season": 5, "episode": 5, "scene": 15, "line": "If you're going my way, I wanna drive you all night long! If you're going my way...", "order": 22194, "speaker": "Michael Scott, Holly Flax, & Darryl Philbin"},
  {"season": 5, "episode": 5, "scene": 16, "line": "Hey there. So uh, how do you think we're gonna do against Penn this year? Nathan Ford's arm looks pretty strong.", "order": 22195, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 5, "scene": 16, "line": "Well he's had a pretty good season so far--- Stop saying 'we.' You did not go to Cornell. Okay, you're just doing this to screw with me.", "order": 22196, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 5, "scene": 16, "line": "Not so. Cornell is an excellent school. Without its agricultural program, we probably wouldn't have cabbage. At least not modern cabbage.", "order": 22197, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 5, "scene": 16, "line": "I know it's an excellent school, Dwight. I went there. My blood runs Big Red.", "order": 22198, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 5, "scene": 16, "line": "Someday, we'll both get together in Comstock Hall and just laugh about all of this.", "order": 22199, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 5, "scene": 17, "line": "Hey!", "order": 22200, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 5, "scene": 17, "line": "Hey, future baby sis!", "order": 22201, "speaker": "Tom"},
  {"season": 5, "episode": 5, "scene": 17, "line": "How are you Tom. Nice to see you.", "order": 22202, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 5, "scene": 17, "line": "I'm good.", "order": 22203, "speaker": "Tom"},
  {"season": 5, "episode": 5, "scene": 18, "line": "I asked Tom and Pete to come early so we could play a prank on Jim at lunch! Pretty awesome, right? I think they're into the idea. They're probably thinking, 'That Pam Beasly, she's the coolest sister-in-law on the planet. She's the best! The absolute best.'", "order": 22204, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 5, "scene": 19, "line": "Okay, so here's what I'm thinking. I'm gonna say that before ceramics class, I took off my ring, and then when I changed back out of my smock, it wasn't in my pocket anymore, and I lost it.", "order": 22205, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 5, "scene": 19, "line": "That's perfect. You know what would be even more hilarious? Remember that thing we did when Jim was in high school with his girlfriend?", "order": 22206, "speaker": "Pete Miller"},
  {"season": 5, "episode": 5, "scene": 19, "line": "Right! That would be hilarious! We should totally dog her, about being an artist, never making any money!", "order": 22207, "speaker": "Tom"},
  {"season": 5, "episode": 5, "scene": 19, "line": "That, is awesome!", "order": 22208, "speaker": "Pete Miller"},
  {"season": 5, "episode": 5, "scene": 19, "line": "Like she basically has a hobby, for a job.", "order": 22209, "speaker": "Tom"},
  {"season": 5, "episode": 5, "scene": 19, "line": "Oh yeah.", "order": 22210, "speaker": "Pete Miller"},
  {"season": 5, "episode": 5, "scene": 19, "line": "So, not the ring then? The- the- Not doing the ring?", "order": 22211, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 5, "scene": 19, "line": "I think this is better.", "order": 22212, "speaker": "Pete Miller"},
  {"season": 5, "episode": 5, "scene": 19, "line": "The other thing would 'get' Jim.", "order": 22213, "speaker": "Tom"},
  {"season": 5, "episode": 5, "scene": 19, "line": "This is nicer, it's fun. It's fun!", "order": 22214, "speaker": "Pete Miller"},
  {"season": 5, "episode": 5, "scene": 19, "line": "Okay, okay.", "order": 22215, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 5, "scene": 19, "line": "Oh, he hates it when we pick on his girlfriends.", "order": 22216, "speaker": "Tom"},
  {"season": 5, "episode": 5, "scene": 19, "line": "Oookay....", "order": 22217, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 5, "scene": 20, "line": "They came up with that idea really fast.", "order": 22218, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 5, "scene": 21, "line": "This trip was longer than I thought.", "order": 22219, "speaker": "Darryl Philbin"},
  {"season": 5, "episode": 5, "scene": 21, "line": "Yeah it did look shorter on the map-", "order": 22220, "speaker": "Holly Flax"},
  {"season": 5, "episode": 5, "scene": 21, "line": "Ah! Ah! Ahhhh! [waking up] Hey. Whew. Ow. I was having a nightmare.", "order": 22221, "speaker": "Michael Scott"},
  {"season": 5, "episode": 5, "scene": 21, "line": "You were sleeping? You were talking before.", "order": 22222, "speaker": "Holly Flax"},
  {"season": 5, "episode": 5, "scene": 21, "line": "Was I? Really? Was I saying anything interesting?", "order": 22223, "speaker": "Michael Scott"},
  {"season": 5, "episode": 5, "scene": 21, "line": "Not really.", "order": 22224, "speaker": "Darryl Philbin"},
  {"season": 5, "episode": 5, "scene": 21, "line": "All right. What's the scoop, how far?", "order": 22225, "speaker": "Michael Scott"},
  {"season": 5, "episode": 5, "scene": 21, "line": "Four hours. Almost halfway there.", "order": 22226, "speaker": "Darryl Philbin"},
  {"season": 5, "episode": 5, "scene": 21, "line": "We're only halfway?", "order": 22227, "speaker": "Holly Flax"},
  {"season": 5, "episode": 5, "scene": 21, "line": "Halfway! Okay, You know what I want to do, I want to pull over and find little bed and breakfast for when we meet in the middle. Emphasis on the bed. And the breakfast.", "order": 22228, "speaker": "Michael Scott"},
  {"season": 5, "episode": 5, "scene": 21, "line": "Next exit isn't for five miles.", "order": 22229, "speaker": "Darryl Philbin"},
  {"season": 5, "episode": 5, "scene": 21, "line": "Let's check there.", "order": 22230, "speaker": "Holly Flax"},
  {"season": 5, "episode": 5, "scene": 22, "line": "Hey guys.", "order": 22231, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 5, "scene": 22, "line": "Hey, how are you?", "order": 22232, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 5, "scene": 22, "line": "Hi! [smooches]", "order": 22233, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 5, "scene": 22, "line": "Good to see you.", "order": 22234, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 5, "scene": 22, "line": "Nice to see you again, Pam.", "order": 22235, "speaker": "Pete Miller"},
  {"season": 5, "episode": 5, "scene": 22, "line": "Pam, I haven't seen you in so, so long!", "order": 22236, "speaker": "Tom"},
  {"season": 5, "episode": 5, "scene": 22, "line": "All right so now we can sit... and get comfortable.", "order": 22237, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 5, "scene": 23, "line": "There's nothing out here man.", "order": 22238, "speaker": "Darryl Philbin"},
  {"season": 5, "episode": 5, "scene": 23, "line": "Yeah. I don't know I just- I imagined a hotel right here. Pool, over here. Really good breakfast place. With really good bacon.", "order": 22239, "speaker": "Michael Scott"},
  {"season": 5, "episode": 5, "scene": 24, "line": "Here we go, fourth time's a charm. 'Life's like a road where you just... one day here, and the next day back...Sometimes you deal with it, today you don't, sometimes you do, what you want... there's a world out there.... [Holly sobs] Hey. Are you crying?", "order": 22240, "speaker": "Michael Scott"},
  {"season": 5, "episode": 5, "scene": 24, "line": "No.", "order": 22241, "speaker": "Holly Flax"},
  {"season": 5, "episode": 5, "scene": 24, "line": "Allergies?", "order": 22242, "speaker": "Michael Scott"},
  {"season": 5, "episode": 5, "scene": 24, "line": "No.", "order": 22243, "speaker": "Holly Flax"},
  {"season": 5, "episode": 5, "scene": 24, "line": "Did Darryl touch you?", "order": 22244, "speaker": "Michael Scott"},
  {"season": 5, "episode": 5, "scene": 24, "line": "WHAT!?", "order": 22245, "speaker": "Darryl Philbin"},
  {"season": 5, "episode": 5, "scene": 24, "line": "No, Darryl did not touch me. Can we just keep going, please? [crying]", "order": 22246, "speaker": "Holly Flax"},
  {"season": 5, "episode": 5, "scene": 25, "line": "What's the matter?", "order": 22247, "speaker": "Michael Scott"},
  {"season": 5, "episode": 5, "scene": 25, "line": "It's not gonna work.", "order": 22248, "speaker": "Holly Flax"},
  {"season": 5, "episode": 5, "scene": 25, "line": "Sure it is.", "order": 22249, "speaker": "Michael Scott"},
  {"season": 5, "episode": 5, "scene": 25, "line": "There's too much distance.", "order": 22250, "speaker": "Holly Flax"},
  {"season": 5, "episode": 5, "scene": 25, "line": "Oh no no no.. It's gonna work, it'll be fine.", "order": 22251, "speaker": "Michael Scott"},
  {"season": 5, "episode": 5, "scene": 25, "line": "Michael we've only been dating each other for a few weeks", "order": 22252, "speaker": "Holly Flax"},
  {"season": 5, "episode": 5, "scene": 25, "line": "Listen to me. I like you so much.", "order": 22253, "speaker": "Michael Scott"},
  {"season": 5, "episode": 5, "scene": 25, "line": "And I like you too.", "order": 22254, "speaker": "Holly Flax"},
  {"season": 5, "episode": 5, "scene": 25, "line": "And I've dated four women in the last--", "order": 22255, "speaker": "Michael Scott"},
  {"season": 5, "episode": 5, "scene": 25, "line": "I've dated four guys last year too.", "order": 22256, "speaker": "Holly Flax"},
  {"season": 5, "episode": 5, "scene": 25, "line": "Not last - no. In like the last ten years.", "order": 22257, "speaker": "Michael Scott"},
  {"season": 5, "episode": 5, "scene": 25, "line": "Oh.", "order": 22258, "speaker": "Holly Flax"},
  {"season": 5, "episode": 5, "scene": 25, "line": "I've dated almost four women, and you are so far above them, it is stupid.", "order": 22259, "speaker": "Michael Scott"},
  {"season": 5, "episode": 5, "scene": 25, "line": "Michael. Don't. Don't. Don't make it harder than it has to be.", "order": 22260, "speaker": "Holly Flax"},
  {"season": 5, "episode": 5, "scene": 25, "line": "That's what she said.", "order": 22261, "speaker": "Michael Scott"},
  {"season": 5, "episode": 5, "scene": 26, "line": "Holly thinks that this relationship is over. Well, you know what? I am not gonna give up that easy. I'm gonna make this way harder than it needs to be.", "order": 22262, "speaker": "Michael Scott"},
  {"season": 5, "episode": 5, "scene": 27, "line": "Take that down. [Dwight has hung a large red Cornell banner from the ceiling]", "order": 22263, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 5, "scene": 27, "line": "Excuse me?", "order": 22264, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 5, "scene": 27, "line": "Take. That down.", "order": 22265, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 5, "scene": 27, "line": "You know I once dated a couple of guys from Cornell. They were really nice. They gave me a ride home.", "order": 22266, "speaker": "Meredith Palmer"},
  {"season": 5, "episode": 5, "scene": 27, "line": "I seriously doubt that anyone from Cornell dated you.", "order": 22267, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 5, "scene": 27, "line": "It's pronounced Ker-nell. It's the highest rank in the military.", "order": 22268, "speaker": "Creed Bratton"},
  {"season": 5, "episode": 5, "scene": 27, "line": "It's pronounced 'Corn-ell!' It's the highest rank in the Ivy League!", "order": 22269, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 5, "scene": 27, "line": "Andy, let's just talk about this man-to-man, after work.", "order": 22270, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 5, "scene": 27, "line": "Fine.", "order": 22271, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 5, "scene": 27, "line": "What do you say?", "order": 22272, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 5, "scene": 27, "line": "Yeah, good. Can we--- [Dwight pulls out Cornell mascot bobble-head] Grr. Heh heh heh. That's Big Red Bear! That's a bobble Big Red Bear! God!!", "order": 22273, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 5, "scene": 28, "line": "If you leave on Friday, by five, you'll be rolling in at... midnight? At best?", "order": 22274, "speaker": "Holly Flax"},
  {"season": 5, "episode": 5, "scene": 28, "line": "Yeah.", "order": 22275, "speaker": "Michael Scott"},
  {"season": 5, "episode": 5, "scene": 28, "line": "And then sleep in Saturday. That only leaves us less than 24 hours before you have to go back again.", "order": 22276, "speaker": "Holly Flax"},
  {"season": 5, "episode": 5, "scene": 28, "line": "Okay, okay. I will talk to you on the Bluetooth the entire trip. So we're talking constantly all the way.", "order": 22277, "speaker": "Michael Scott"},
  {"season": 5, "episode": 5, "scene": 28, "line": "Oh.", "order": 22278, "speaker": "Holly Flax"},
  {"season": 5, "episode": 5, "scene": 28, "line": "I'll tell you everything that I see. Everything that I pass by, things that I witness on the road.", "order": 22279, "speaker": "Michael Scott"},
  {"season": 5, "episode": 5, "scene": 28, "line": "Well-", "order": 22280, "speaker": "Holly Flax"},
  {"season": 5, "episode": 5, "scene": 28, "line": "Maybe I'll see an accident one weekend?", "order": 22281, "speaker": "Michael Scott"},
  {"season": 5, "episode": 5, "scene": 28, "line": "How long could we keep that up?", "order": 22282, "speaker": "Holly Flax"},
  {"season": 5, "episode": 5, "scene": 28, "line": "Years!", "order": 22283, "speaker": "Michael Scott"},
  {"season": 5, "episode": 5, "scene": 28, "line": "Years?", "order": 22284, "speaker": "Holly Flax"},
  {"season": 5, "episode": 5, "scene": 28, "line": "Yeah.", "order": 22285, "speaker": "Michael Scott"},
  {"season": 5, "episode": 5, "scene": 28, "line": "Years? Of just a few hours every weekend?", "order": 22286, "speaker": "Holly Flax"},
  {"season": 5, "episode": 5, "scene": 28, "line": "Here's my wish. I want you to meet a great guy, and I want you to be happy.", "order": 22287, "speaker": "Michael Scott"},
  {"season": 5, "episode": 5, "scene": 28, "line": "[kisses his temple] Thank you.", "order": 22288, "speaker": "Holly Flax"},
  {"season": 5, "episode": 5, "scene": 28, "line": "My wish has come true, incidentally, because, you've met me, and you are happy.", "order": 22289, "speaker": "Michael Scott"},
  {"season": 5, "episode": 5, "scene": 28, "line": "Clever, Mike.", "order": 22290, "speaker": "Darryl Philbin"},
  {"season": 5, "episode": 5, "scene": 29, "line": "So Pam, how much does an artist make after they leave art school?", "order": 22291, "speaker": "Tom"},
  {"season": 5, "episode": 5, "scene": 29, "line": "Yeah, not a lot of money in the arts, right?", "order": 22292, "speaker": "Pete Miller"},
  {"season": 5, "episode": 5, "scene": 29, "line": "That's not really true. There's a lot of things you can do with an art degree actually.", "order": 22293, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 5, "scene": 29, "line": "Maybe Pam should pay the check by drawing a picture on this napkin.", "order": 22294, "speaker": "Tom"},
  {"season": 5, "episode": 5, "scene": 29, "line": "Wow, that's- that's a little rude. What's your deal?", "order": 22295, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 5, "scene": 29, "line": "Hey, just having fun Jimmy.", "order": 22296, "speaker": "Pete Miller"},
  {"season": 5, "episode": 5, "scene": 29, "line": "Yeah. Right Pam?", "order": 22297, "speaker": "Tom"},
  {"season": 5, "episode": 5, "scene": 29, "line": "Yup! [Tom and Pete give Halpertian looks to camera]", "order": 22298, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 5, "scene": 30, "line": "You know what? I think we're a great couple. I think we're a classic couple. I think we're like Romeo and Juliet. I think we... go together so well. We're like peanut butter and jelly, don't you think?", "order": 22299, "speaker": "Michael Scott"},
  {"season": 5, "episode": 5, "scene": 30, "line": "I do, I think so.", "order": 22300, "speaker": "Holly Flax"},
  {"season": 5, "episode": 5, "scene": 30, "line": "Then don't do it. Please don't do this. Please don't do this. [they continue arguing]", "order": 22301, "speaker": "Michael Scott"},
  {"season": 5, "episode": 5, "scene": 30, "line": "[leaving someone a message on his cell phone, looking very uncomfortable] Hey what's up, I just thought I'd try you. I was thinking about that story where you ran into the girl you used to babysit.", "order": 22302, "speaker": "Darryl Philbin"},
  {"season": 5, "episode": 5, "scene": 30, "line": "I don't know what I'm gonna do!", "order": 22303, "speaker": "Michael Scott"},
  {"season": 5, "episode": 5, "scene": 30, "line": "Please call me back.", "order": 22304, "speaker": "Darryl Philbin"},
  {"season": 5, "episode": 5, "scene": 30, "line": "Please?", "order": 22305, "speaker": "Michael Scott"},
  {"season": 5, "episode": 5, "scene": 30, "line": "You'll be okay", "order": 22306, "speaker": "Holly Flax"},
  {"season": 5, "episode": 5, "scene": 30, "line": "I'm not gonna be okay.", "order": 22307, "speaker": "Michael Scott"},
  {"season": 5, "episode": 5, "scene": 30, "line": "Please...", "order": 22308, "speaker": "Darryl Philbin"},
  {"season": 5, "episode": 5, "scene": 30, "line": "You will.", "order": 22309, "speaker": "Holly Flax"},
  {"season": 5, "episode": 5, "scene": 30, "line": "No I won't! I'm not strong! And I'll go back to Jan, and I hate Jan! Oh God!", "order": 22310, "speaker": "Michael Scott"},
  {"season": 5, "episode": 5, "scene": 31, "line": "[outside men's room] You might be interested- [stops when he sees Stanley come out]", "order": 22311, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 5, "scene": 31, "line": "What?", "order": 22312, "speaker": "Stanley Hudson"},
  {"season": 5, "episode": 5, "scene": 31, "line": "Dwight!", "order": 22313, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 5, "scene": 31, "line": "Andy, I've been meaning to ask you, which a cappella group should I join? The Harmoniacs, or the Do-Re-Mi-Go's?", "order": 22314, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 5, "scene": 31, "line": "Hm. Assuming you had the voice to be in any of them, it's irrelevant. Because I called admissions and it looks like I, will be conducting your university interview.", "order": 22315, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 5, "scene": 31, "line": "That's a conflict of interest.", "order": 22316, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 5, "scene": 31, "line": "Yeah. Big one. So, should I not let you in now, or do you want to do the interview, and then I won't let you in?", "order": 22317, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 5, "scene": 31, "line": "[thinks] Interview.", "order": 22318, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 5, "scene": 31, "line": "Excellent. When the hourglass strikes three, then in the room whence employees confer.", "order": 22319, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 5, "scene": 31, "line": "What?", "order": 22320, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 5, "scene": 31, "line": "The conference room!", "order": 22321, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 5, "scene": 31, "line": "Okay.", "order": 22322, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 5, "scene": 32, "line": "Who are your role models?", "order": 22323, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 5, "scene": 33, "line": "If I had to put Dwight's chances into a percentage, I would say he has none percent chance.", "order": 22324, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 5, "scene": 34, "line": "So, Dane Cook, Jack Bauer, and Eli Whitney. You're doing great.", "order": 22325, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 5, "scene": 35, "line": "Oh wow. This is my niece Vanessa. She's a trumpeter. And, look at her.", "order": 22326, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 5, "scene": 35, "line": "Cute.", "order": 22327, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 5, "scene": 35, "line": "That must be really fun for you and Marci huh?", "order": 22328, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 5, "scene": 35, "line": "Yup. She only knows how to play 'When the Saints go Marching In.'", "order": 22329, "speaker": "Tom"},
  {"season": 5, "episode": 5, "scene": 35, "line": "Love that one.", "order": 22330, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 5, "scene": 35, "line": "Yeah but, she doesn't think she's gonna be like, career musician right?", "order": 22331, "speaker": "Pete Miller"},
  {"season": 5, "episode": 5, "scene": 35, "line": "Here you go again! What is your deal today?", "order": 22332, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 5, "scene": 35, "line": "Just saying. How many famous trumpeters can you name besides... Louis Armstrong?", "order": 22333, "speaker": "Pete Miller"},
  {"season": 5, "episode": 5, "scene": 35, "line": "Miles Davis.", "order": 22334, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 5, "scene": 35, "line": "One.", "order": 22335, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 5, "scene": 35, "line": "Chet ...something.", "order": 22336, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 5, "scene": 35, "line": "Half.", "order": 22337, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 5, "scene": 35, "line": "The point is Pam, is there are jobs-", "order": 22338, "speaker": "Pete Miller"},
  {"season": 5, "episode": 5, "scene": 35, "line": "Dizzy Gillespie.", "order": 22339, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 5, "scene": 35, "line": "Also good.", "order": 22340, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 5, "scene": 35, "line": "And there are hobbies. I love baseball more than anything, but you don't see me try to get on the Mets.", "order": 22341, "speaker": "Pete Miller"},
  {"season": 5, "episode": 5, "scene": 35, "line": "You don't!", "order": 22342, "speaker": "Tom"},
  {"season": 5, "episode": 5, "scene": 35, "line": "Pete couldn't make the Mets. She's at Pratt. You played JV baseball. Will you lighten up a little bit?", "order": 22343, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 5, "scene": 35, "line": "I'm just calling it like I see it.", "order": 22344, "speaker": "Pete Miller"},
  {"season": 5, "episode": 5, "scene": 35, "line": "I don't know if I'm gonna make any money with art.", "order": 22345, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 5, "scene": 35, "line": "Pam, don't worry about it.", "order": 22346, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 5, "scene": 35, "line": "I mean it's a very competitive field. But I have a professor who says I have a lot of promise, and if I don't try now, I never will. So...", "order": 22347, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 5, "scene": 35, "line": "Guys, what is going on?! [Pete and Tom burst out in laughter]", "order": 22348, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 5, "scene": 35, "line": "We pranked you!", "order": 22349, "speaker": "Tom"},
  {"season": 5, "episode": 5, "scene": 35, "line": "It was Pam's idea. Pam was the mastermind.", "order": 22350, "speaker": "Pete Miller"},
  {"season": 5, "episode": 5, "scene": 35, "line": "... Got you.", "order": 22351, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 5, "scene": 35, "line": "That was killer. I was so close to blowing it.", "order": 22352, "speaker": "Pete Miller"},
  {"season": 5, "episode": 5, "scene": 36, "line": "Let's see how well you know your Big Red history.", "order": 22353, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 5, "scene": 36, "line": "Bring it.", "order": 22354, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 5, "scene": 36, "line": "Who was Cornell's eighth president?", "order": 22355, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 5, "scene": 36, "line": "Dale Raymond Corson!", "order": 22356, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 5, "scene": 36, "line": "Mm, I'm sorry that's incorrect. Cornell's seventh president was in fact, James A. Perkins. [writes in a notebook] Comprehension skills, sub-par.", "order": 22357, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 5, "scene": 36, "line": "Hmm, interviewing skills, sub-par. [writes in a notebook]", "order": 22358, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 5, "scene": 36, "line": "What are you writing? Can't even give Cornell your full attention?", "order": 22359, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 5, "scene": 36, "line": "On the contrary, I'm helping Cornell. By evaluating their interviewers.", "order": 22360, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 5, "scene": 36, "line": "Nobody wants ...that, to happen.", "order": 22361, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 5, "scene": 36, "line": "Well, when they get my evaluation we'll see if they're interested.", "order": 22362, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 5, "scene": 36, "line": "Applicant is attempting to blackmail interviewer, showing low moral character.'", "order": 22363, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 5, "scene": 36, "line": "Interviewer is threatening applicant with an arbitrary review process.'", "order": 22364, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 5, "scene": 36, "line": "Applicant is wasting everyone's time with stupid and inane accusations.'", "order": 22365, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 5, "scene": 36, "line": "Interviewer has suspect motives.'", "order": 22366, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 5, "scene": 36, "line": "Applicant has a head shaped like a trapezoid.'", "order": 22367, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 5, "scene": 36, "line": "Interviewer has turned off applicant's interest in Cornell, and they are going to go to the vastly superior Dartmouth.' Ever heard of it? I think I have everything I need.", "order": 22368, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 5, "scene": 36, "line": "I have everything I need- [talking over each other]", "order": 22369, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 5, "scene": 36, "line": "And you will be hearing from the -", "order": 22370, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 5, "scene": 36, "line": "And you will be hearing from -", "order": 22371, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 5, "scene": 36, "line": "-Cornell Application Department,", "order": 22372, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 5, "scene": 36, "line": "which I will not be a part of-", "order": 22373, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 5, "scene": 36, "line": "And you will not be pleased with the result. [pulls table away from Andy]", "order": 22374, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 5, "scene": 36, "line": "And YOU will not be pleased with the result!", "order": 22375, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 5, "scene": 36, "line": "And your affiliation with Cornell -", "order": 22376, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 5, "scene": 36, "line": "And your affiliation with Cornell -", "order": 22377, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 5, "scene": 36, "line": "Will end completely!", "order": 22378, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 5, "scene": 36, "line": "Will end completely!", "order": 22379, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 5, "scene": 36, "line": "[has won the table war] That is all sir, you may go.", "order": 22380, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 5, "scene": 37, "line": "[Michael and Holly cautiously pass each other] There's another dolly in the truck, Mike. You could take more than that lamp.", "order": 22381, "speaker": "Darryl Philbin"},
  {"season": 5, "episode": 5, "scene": 38, "line": "For the record, I wanted go another direction. Which was way better.", "order": 22382, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 5, "scene": 38, "line": "Well, I'll be the judge of that. What do you got?", "order": 22383, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 5, "scene": 38, "line": "Okay. I lost my engagement ring in ceramics class. Left it in my smock. I had this whole thing where I go back to class, wrongly accuse another girl. Look I even used makeup to put a ring around my finger, you can hardly see it, it's very subtle.", "order": 22384, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 5, "scene": 38, "line": "That is good.", "order": 22385, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 5, "scene": 38, "line": "Thank you.", "order": 22386, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 5, "scene": 38, "line": "Truthfully anything would have been better than that prank. [laughs] Oh, text message from my brother. 'Pam cool. Welcome to the family.'", "order": 22387, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 5, "scene": 38, "line": "Oh. Hey how about at Thanksgiving we prank Tom about being bald?", "order": 22388, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 5, "scene": 39, "line": "This is the last of it.", "order": 22389, "speaker": "Darryl Philbin"},
  {"season": 5, "episode": 5, "scene": 39, "line": "Oh that's mine actually. Um, maybe put it back in the truck.", "order": 22390, "speaker": "Michael Scott"},
  {"season": 5, "episode": 5, "scene": 39, "line": "You're not staying?", "order": 22391, "speaker": "Darryl Philbin"},
  {"season": 5, "episode": 5, "scene": 39, "line": "You know I have some things I need to do this weekend. I just remembered, so, I'll just ride back with you.", "order": 22392, "speaker": "Michael Scott"},
  {"season": 5, "episode": 5, "scene": 39, "line": "But you want me to put it back in the truck.", "order": 22393, "speaker": "Darryl Philbin"},
  {"season": 5, "episode": 5, "scene": 39, "line": "I'll be down in just a second. [into house] Holly?", "order": 22394, "speaker": "Michael Scott"},
  {"season": 5, "episode": 5, "scene": 40, "line": "So um... I think I'm gonna go back with Darryl [Holly hugs Michael] Okay. [they kiss goodbye.] Goodbye.", "order": 22395, "speaker": "Michael Scott"},
  {"season": 5, "episode": 5, "scene": 40, "line": "Okay. Bye.", "order": 22396, "speaker": "Holly Flax"},
  {"season": 5, "episode": 5, "scene": 41, "line": "I know it's hard Mike. Break-ups hurt.", "order": 22397, "speaker": "Darryl Philbin"},
  {"season": 5, "episode": 5, "scene": 41, "line": "We didn't break up.", "order": 22398, "speaker": "Michael Scott"},
  {"season": 5, "episode": 5, "scene": 41, "line": "Looked like it. Sometimes when I'm down like this, it helps to sing the blues.", "order": 22399, "speaker": "Darryl Philbin"},
  {"season": 5, "episode": 5, "scene": 41, "line": "Okay.", "order": 22400, "speaker": "Michael Scott"},
  {"season": 5, "episode": 5, "scene": 41, "line": "[bluesy] Da na na na na... da na na na na...", "order": 22401, "speaker": "Darryl Philbin"},
  {"season": 5, "episode": 5, "scene": 41, "line": "That's a really pretty song.", "order": 22402, "speaker": "Michael Scott"},
  {"season": 5, "episode": 5, "scene": 41, "line": "Da na na na na. No, no, check it out, look. Da na na na na... want to do that?", "order": 22403, "speaker": "Darryl Philbin"},
  {"season": 5, "episode": 5, "scene": 41, "line": "Okay.", "order": 22404, "speaker": "Michael Scott"},
  {"season": 5, "episode": 5, "scene": 41, "line": "That's when you hit me with what's getting you down, okay?", "order": 22405, "speaker": "Darryl Philbin"},
  {"season": 5, "episode": 5, "scene": 41, "line": "Okay.", "order": 22406, "speaker": "Michael Scott"},
  {"season": 5, "episode": 5, "scene": 41, "line": "Da na na na na.", "order": 22407, "speaker": "Darryl Philbin"},
  {"season": 5, "episode": 5, "scene": 41, "line": "Da na na na na.", "order": 22408, "speaker": "Michael Scott"},
  {"season": 5, "episode": 5, "scene": 41, "line": "Da na na na na.", "order": 22409, "speaker": "Darryl Philbin"},
  {"season": 5, "episode": 5, "scene": 41, "line": "Da na na na na.", "order": 22410, "speaker": "Michael Scott"},
  {"season": 5, "episode": 5, "scene": 41, "line": "No, wait. You're, you're supposed to... Never mind. Da na na na na.", "order": 22411, "speaker": "Darryl Philbin"},
  {"season": 5, "episode": 5, "scene": 41, "line": "Da na na na na.", "order": 22412, "speaker": "Michael Scott"},
  {"season": 5, "episode": 5, "scene": 41, "line": "Da na na na na.", "order": 22413, "speaker": "Darryl Philbin"},
  {"season": 5, "episode": 5, "scene": 41, "line": "Da na na na na.", "order": 22414, "speaker": "Michael Scott"},
  {"season": 5, "episode": 5, "scene": 41, "line": "Da na na na na!!", "order": 22415, "speaker": "Darryl Philbin"},
  {"season": 5, "episode": 5, "scene": 41, "line": "Da na na na na!!", "order": 22416, "speaker": "Michael Scott"},
  {"season": 5, "episode": 5, "scene": 41, "line": "Da na na na na .", "order": 22417, "speaker": "Darryl Philbin"},
  {"season": 5, "episode": 5, "scene": 41, "line": "[deeper] Da na na na na.", "order": 22418, "speaker": "Michael Scott"},
  {"season": 5, "episode": 5, "scene": 41, "line": "Yeah! Da na na na na.", "order": 22419, "speaker": "Darryl Philbin"},
  {"season": 5, "episode": 5, "scene": 41, "line": "[deeper] Da na na na na.", "order": 22420, "speaker": "Michael Scott"},
  {"season": 5, "episode": 5, "scene": 41, "line": "YEAH! Da na na na na.", "order": 22421, "speaker": "Darryl Philbin"},
  {"season": 5, "episode": 5, "scene": 41, "line": "[blues singer] Da na na na na...", "order": 22422, "speaker": "Michael Scott"},
  {"season": 5, "episode": 5, "scene": 42, "line": "[Whistling, enters office in farmer overalls] I thought I'd come in casual today. Man, I'm hungry. Anyone else feel like a beet?", "order": 22423, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 5, "scene": 42, "line": "Where did you get those?", "order": 22424, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 5, "scene": 42, "line": "What, these? Bernard Farms. Best beets in the state.", "order": 22425, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 5, "scene": 42, "line": "I see what you are doing. But I do not know where you are going with this.", "order": 22426, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 5, "scene": 42, "line": "Well you will. Soon as you visit, my new beet farm. [attempts to bite into a raw beet, beet is too hard] You're supposed to cook these, aren't you?", "order": 22427, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 5, "scene": 42, "line": "[scoffs] Cornell. [easily bites into a beet]", "order": 22428, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 5, "scene": 43, "line": "We'll miss you.", "order": 22429, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 5, "scene": 43, "line": "Well, I'll be around.", "order": 22430, "speaker": "Holly Flax"},
  {"season": 5, "episode": 5, "scene": 43, "line": "[chuckling] Isn't it a seven-hour drive?", "order": 22431, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 5, "scene": 43, "line": "It's not that bad. But if I'm really jonesing to see you guys, I can always fly.", "order": 22432, "speaker": "Holly Flax"},
  {"season": 5, "episode": 5, "scene": 43, "line": "I don't know if it'll be shorter to fly. [Holly gasps] You have to drive to Boston, right? That's an hour and a half. Get there an hour before the flight. Now you're up two and a half hours. The flight to Philadelphia is an hour fifteen. It's another two and a half hour drive to Scranton, assuming there's no traffic, because....", "order": 22433, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 5, "scene": 43, "line": "[interrupting Oscar] Well, I just want to say goodbye. [hugs Oscar]", "order": 22434, "speaker": "Holly Flax"},
  {"season": 5, "episode": 5, "scene": 43, "line": "Okay.", "order": 22435, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 5, "scene": 43, "line": "Okay.", "order": 22436, "speaker": "Holly Flax"},
  {"season": 5, "episode": 5, "scene": 43, "line": "Bye.", "order": 22437, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 5, "scene": 43, "line": "Well, Hol, this is it.", "order": 22438, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 5, "scene": 43, "line": "Yeah, okay. [extends hand to Kevin]", "order": 22439, "speaker": "Holly Flax"},
  {"season": 5, "episode": 5, "scene": 43, "line": "[outstretches arms for a hug, Holly reluctantly complies]", "order": 22440, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 5, "scene": 43, "line": "[whispers in Holly's ear, Holly reacts with horrified look]", "order": 22441, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 5, "scene": 43, "line": "[pushing Kevin away] Um, yeah, that's not gonna happen.", "order": 22442, "speaker": "Holly Flax"},
  {"season": 5, "episode": 5, "scene": 43, "line": "Yeah, but you have to admit it would be wild.", "order": 22443, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 5, "scene": 43, "line": "So, you're dumping Michael? Smart.", "order": 22444, "speaker": "Kelly Kapoor"},
  {"season": 5, "episode": 5, "scene": 43, "line": "No, we're gonna do the long-distance thing.", "order": 22445, "speaker": "Holly Flax"},
  {"season": 5, "episode": 5, "scene": 43, "line": "Oh! You guys are gonna keep dating? That's so romantic!", "order": 22446, "speaker": "Kelly Kapoor"},
  {"season": 5, "episode": 5, "scene": 43, "line": "Yeah, thanks.", "order": 22447, "speaker": "Holly Flax"},
  {"season": 5, "episode": 5, "scene": 43, "line": "I almost quit my job so I could be closer to Bob, and we're in the same building.", "order": 22448, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 5, "scene": 43, "line": "Oh, well....look, we could always call, or email, or write. I should get all your email addresses.", "order": 22449, "speaker": "Holly Flax"},
  {"season": 5, "episode": 5, "scene": 43, "line": "Yes, we'll email you, and then you'll have our addresses.", "order": 22450, "speaker": "Angela Martin"},
  {"season": 5, "episode": 5, "scene": 43, "line": "[talking head] It's a little sad. Michael and I just started dating. He wants me to stay and get a job around here as a baker. Or a baker's helper.", "order": 22451, "speaker": "Holly Flax"},
  {"season": 5, "episode": 5, "scene": 43, "line": "[talking head] This is gonna be awesome. This is gonna be awesome. I've decided that on my drives to Nashua, I am going to learn French, the language of love. And on my drives back, I am going to learn Spanish; what the cleaning crew speaks.", "order": 22452, "speaker": "Michael Scott"},
  {"season": 5, "episode": 5, "scene": 43, "line": "[talking head] I'm driving them up for five times what I would've made at work. I thought I made a good deal. [expression changes from smiling to downcast] Then I realized I'm gonna be trapped in a truck with Mike all day.", "order": 22453, "speaker": "Darryl Philbin"},
  {"season": 5, "episode": 5, "scene": 44, "line": "[playing cat's cradle with Holly] Okay, am I grabbing this one?", "order": 22454, "speaker": "Michael Scott"},
  {"season": 5, "episode": 5, "scene": 44, "line": "[chuckling] No, that's not what you're grabbing.", "order": 22455, "speaker": "Holly Flax"},
  {"season": 5, "episode": 5, "scene": 44, "line": "[laughs]", "order": 22456, "speaker": "Michael Scott"},
  {"season": 5, "episode": 5, "scene": 44, "line": "Put....okay....", "order": 22457, "speaker": "Holly Flax"},
  {"season": 5, "episode": 5, "scene": 44, "line": "[driving truck] Hey, y'all are not gonna be playing these little private games all the way.", "order": 22458, "speaker": "Darryl Philbin"},
  {"season": 5, "episode": 5, "scene": 44, "line": "Sorry, Darryl.", "order": 22459, "speaker": "Holly Flax"},
  {"season": 5, "episode": 5, "scene": 44, "line": "It's like Driving Miss Daisy, you know?", "order": 22460, "speaker": "Darryl Philbin"},
  {"season": 5, "episode": 5, "scene": 44, "line": "All right.", "order": 22461, "speaker": "Holly Flax"},
  {"season": 5, "episode": 5, "scene": 44, "line": "You gotta include me in the conversation.", "order": 22462, "speaker": "Darryl Philbin"},
  {"season": 5, "episode": 5, "scene": 44, "line": "Fair enough.", "order": 22463, "speaker": "Michael Scott"},
  {"season": 5, "episode": 5, "scene": 44, "line": "If you don't mind.", "order": 22464, "speaker": "Darryl Philbin"},
  {"season": 5, "episode": 5, "scene": 44, "line": "Okay, okay. What kind of car games did you play in the 'hood?", "order": 22465, "speaker": "Michael Scott"},
  {"season": 5, "episode": 5, "scene": 44, "line": "I got a game. Cow surfing.", "order": 22466, "speaker": "Darryl Philbin"},
  {"season": 5, "episode": 5, "scene": 44, "line": "Cow surfing?", "order": 22467, "speaker": "Michael Scott"},
  {"season": 5, "episode": 5, "scene": 44, "line": "Spot a cow, last person to say 'Jackson Five' has to get on its back and ride it.", "order": 22468, "speaker": "Darryl Philbin"},
  {"season": 5, "episode": 5, "scene": 44, "line": "Really?", "order": 22469, "speaker": "Michael Scott"},
  {"season": 5, "episode": 5, "scene": 44, "line": "Were there even cows where you grew up?", "order": 22470, "speaker": "Holly Flax"},
  {"season": 5, "episode": 5, "scene": 44, "line": "Everybody got a government cow.", "order": 22471, "speaker": "Darryl Philbin"},
  {"season": 5, "episode": 5, "scene": 44, "line": "Cow surfing. [spots a cow out the window] Okay, here we go, there's a cow.", "order": 22472, "speaker": "Michael Scott"},
  {"season": 5, "episode": 5, "scene": 44, "line": "[in unison] Jackson Five!", "order": 22473, "speaker": "Holly Flax & Darryl Philbin"},
  {"season": 5, "episode": 5, "scene": 44, "line": "Oh, shoot! Okay. All right. All right. [throws up hands] Pull it over. Here we go.", "order": 22474, "speaker": "Michael Scott"},
  {"season": 5, "episode": 5, "scene": 44, "line": "Here we go. Go get him, Mike!", "order": 22475, "speaker": "Darryl Philbin"},
  {"season": 5, "episode": 5, "scene": 44, "line": "[reaching to open door] I'm gonna do it.", "order": 22476, "speaker": "Michael Scott"},
  {"season": 5, "episode": 5, "scene": 44, "line": "[grabs Michael's arm] No, Michael. It's not a real game.", "order": 22477, "speaker": "Holly Flax"},
  {"season": 5, "episode": 5, "scene": 44, "line": "Then why did you say 'Jackson Five?'", "order": 22478, "speaker": "Darryl Philbin"},
  {"season": 5, "episode": 5, "scene": 44, "line": "The man has a point.", "order": 22479, "speaker": "Michael Scott"},
  {"season": 5, "episode": 5, "scene": 44, "line": "Darryl....", "order": 22480, "speaker": "Holly Flax"},
  {"season": 5, "episode": 5, "scene": 44, "line": "All right, I made it up.", "order": 22481, "speaker": "Darryl Philbin"},
  {"season": 5, "episode": 5, "scene": 44, "line": "[visibly shocked] What? Why?", "order": 22482, "speaker": "Michael Scott"},
  {"season": 5, "episode": 5, "scene": 44, "line": "Because I wanted you to like me.", "order": 22483, "speaker": "Darryl Philbin"},
  {"season": 5, "episode": 5, "scene": 44, "line": "[gasps] I do! Hey, come on!", "order": 22484, "speaker": "Michael Scott"},
  {"season": 5, "episode": 5, "scene": 44, "line": "[trying not to laugh] Okay.", "order": 22485, "speaker": "Darryl Philbin"},
  {"season": 5, "episode": 5, "scene": 44, "line": "Jackson Five.", "order": 22486, "speaker": "Michael Scott"},
  {"season": 5, "episode": 5, "scene": 45, "line": "[both laugh hysterically at portable DVD player on dashboard while Darryl drives, looking miserable]", "order": 22487, "speaker": "Holly Flax & Michael Scott"},
  {"season": 5, "episode": 5, "scene": 45, "line": "[makes sudden sharp turn to the left, causing DVD player to fall out open window, then grins to camera]", "order": 22488, "speaker": "Darryl Philbin"},
  {"season": 5, "episode": 5, "scene": 46, "line": "[rubbing noses with Holly] You're the one.", "order": 22489, "speaker": "Michael Scott"},
  {"season": 5, "episode": 5, "scene": 46, "line": "No, you.", "order": 22490, "speaker": "Holly Flax"},
  {"season": 5, "episode": 5, "scene": 46, "line": "You are.", "order": 22491, "speaker": "Michael Scott"},
  {"season": 5, "episode": 5, "scene": 46, "line": "You are.", "order": 22492, "speaker": "Holly Flax"},
  {"season": 5, "episode": 5, "scene": 46, "line": "No, you are.", "order": 22493, "speaker": "Michael Scott"},
  {"season": 5, "episode": 5, "scene": 46, "line": "You are more.", "order": 22494, "speaker": "Holly Flax"},
  {"season": 5, "episode": 5, "scene": 46, "line": "[rubs face in exasperation]", "order": 22495, "speaker": "Darryl Philbin"},
  {"season": 5, "episode": 5, "scene": 46, "line": "You're the best.", "order": 22496, "speaker": "Michael Scott"},
  {"season": 5, "episode": 5, "scene": 46, "line": "You're better.", "order": 22497, "speaker": "Holly Flax"},
  {"season": 5, "episode": 5, "scene": 46, "line": "You're better. You're better than my best.", "order": 22498, "speaker": "Michael Scott"},
  {"season": 5, "episode": 5, "scene": 46, "line": "You're better than betterest.", "order": 22499, "speaker": "Holly Flax"},
  {"season": 5, "episode": 5, "scene": 46, "line": "[plants kisses all over Michael's face]", "order": 22500, "speaker": "Holly Flax"},
  {"season": 5, "episode": 5, "scene": 46, "line": "Here we go.", "order": 22501, "speaker": "Michael Scott"},
  {"season": 5, "episode": 5, "scene": 46, "line": "Hold your breath!", "order": 22502, "speaker": "Holly Flax"},
  {"season": 5, "episode": 5, "scene": 46, "line": "Okay, Ready?", "order": 22503, "speaker": "Michael Scott"},
  {"season": 5, "episode": 5, "scene": 46, "line": "[setting stopwatch] Go.", "order": 22504, "speaker": "Darryl Philbin"},
  {"season": 5, "episode": 5, "scene": 46, "line": "[both inhale deeply and lock lips while holding their breath]", "order": 22505, "speaker": "Michael Scott & Holly Flax"},
  {"season": 5, "episode": 5, "scene": 46, "line": "[looks to camera for several seconds] At least it's quiet.", "order": 22506, "speaker": "Darryl Philbin"},
  {"season": 5, "episode": 5, "scene": 46, "line": "Watch this. Darryl, watch this.", "order": 22507, "speaker": "Michael Scott"},
  {"season": 5, "episode": 5, "scene": 46, "line": "Look, Darryl, lip up.", "order": 22508, "speaker": "Holly Flax"},
  {"season": 5, "episode": 5, "scene": 46, "line": "[kiss each other while puffing their cheeks and mumbling]", "order": 22509, "speaker": "Michael Scott & Holly Flax"},
  {"season": 5, "episode": 5, "scene": 46, "line": "Down, and give me 50!", "order": 22510, "speaker": "Holly Flax"},
  {"season": 5, "episode": 5, "scene": 46, "line": "[in unison while kissing with mouths closed] One, two, three, four, five, six, seven, eight, nine, ten!", "order": 22511, "speaker": "Michael Scott & Holly Flax"},
  {"season": 5, "episode": 5, "scene": 46, "line": "Hey, you know what would be fun? Like, sleeping.", "order": 22512, "speaker": "Darryl Philbin"},
  {"season": 5, "episode": 5, "scene": 46, "line": "Yeah", "order": 22513, "speaker": "Michael Scott"},
  {"season": 5, "episode": 5, "scene": 46, "line": "You know.", "order": 22514, "speaker": "Darryl Philbin"},
  {"season": 5, "episode": 5, "scene": 46, "line": "[hugging Michael] Oh, snuggles!", "order": 22515, "speaker": "Holly Flax"},
  {"season": 5, "episode": 5, "scene": 46, "line": "Yeah. Right, snuggle time.", "order": 22516, "speaker": "Michael Scott"},
  {"season": 5, "episode": 5, "scene": 46, "line": "Snuggles.", "order": 22517, "speaker": "Holly Flax"},
  {"season": 5, "episode": 5, "scene": 46, "line": "There you go.", "order": 22518, "speaker": "Darryl Philbin"},
  {"season": 5, "episode": 5, "scene": 46, "line": "[makes squeaking sounds]", "order": 22519, "speaker": "Michael Scott"},
  {"season": 5, "episode": 5, "scene": 46, "line": "Spooning.", "order": 22520, "speaker": "Holly Flax"},
  {"season": 5, "episode": 5, "scene": 47, "line": "Here's what I was thinking. The following weekend, I drive up.", "order": 22521, "speaker": "Michael Scott"},
  {"season": 5, "episode": 5, "scene": 47, "line": "Mmm hmm.", "order": 22522, "speaker": "Holly Flax"},
  {"season": 5, "episode": 5, "scene": 47, "line": "We get right in the car, we pop up to Montreal. It's like another seven hours.", "order": 22523, "speaker": "Michael Scott"},
  {"season": 5, "episode": 5, "scene": 47, "line": "Ooh!", "order": 22524, "speaker": "Holly Flax"},
  {"season": 5, "episode": 5, "scene": 47, "line": "Speak.", "order": 22525, "speaker": "Michael Scott"},
  {"season": 5, "episode": 5, "scene": 47, "line": "We could stay in a cozy B&B with a fireplace?", "order": 22526, "speaker": "Holly Flax"},
  {"season": 5, "episode": 5, "scene": 47, "line": "Absolutely. Oh, and you know what, Darryl? You are invited to go as well.", "order": 22527, "speaker": "Michael Scott"},
  {"season": 5, "episode": 5, "scene": 47, "line": "Oh, thanks. Thanks. No.", "order": 22528, "speaker": "Darryl Philbin"},
  {"season": 5, "episode": 5, "scene": 47, "line": "Come on, it'll be fun.", "order": 22529, "speaker": "Michael Scott"},
  {"season": 5, "episode": 5, "scene": 47, "line": "Thank you. Thank you. No.", "order": 22530, "speaker": "Darryl Philbin"},
  {"season": 5, "episode": 5, "scene": 48, "line": "[emerging from convenience store with two ice cream sundaes] Hey, look at that. Fun, right?", "order": 22531, "speaker": "Michael Scott"},
  {"season": 5, "episode": 5, "scene": 48, "line": "Mmm.", "order": 22532, "speaker": "Holly Flax"},
  {"season": 5, "episode": 5, "scene": 48, "line": "Mmm mmm! Remember the 15-scooper we had last week? Wasn't that good? I was Scooperman, you were Scoopy-Doo. Remember what you said? You were laughing.", "order": 22533, "speaker": "Michael Scott"},
  {"season": 5, "episode": 5, "scene": 48, "line": "Yeah.", "order": 22534, "speaker": "Holly Flax"},
  {"season": 5, "episode": 5, "scene": 48, "line": "Say what you said.", "order": 22535, "speaker": "Michael Scott"},
  {"season": 5, "episode": 5, "scene": 48, "line": "No, Michael. I don't....[mimicking Scooby-Doo] I'm gonna puke!", "order": 22536, "speaker": "Holly Flax"},
  {"season": 5, "episode": 5, "scene": 48, "line": "[laughs] I'll save you! I'm Superman! Scooperman!", "order": 22537, "speaker": "Michael Scott"},
  {"season": 5, "episode": 5, "scene": 48, "line": "Darryl didn't want any?", "order": 22538, "speaker": "Holly Flax"},
  {"season": 5, "episode": 5, "scene": 48, "line": "I didn't ask.", "order": 22539, "speaker": "Michael Scott"},
  {"season": 5, "episode": 5, "scene": 49, "line": "We want the same things.", "order": 22540, "speaker": "Michael Scott"},
  {"season": 5, "episode": 5, "scene": 49, "line": "I know, but from seven hours away.", "order": 22541, "speaker": "Holly Flax"},
  {"season": 5, "episode": 5, "scene": 49, "line": "That is a lucky number. Seven. 7-Up. Seven dwarfs.", "order": 22542, "speaker": "Michael Scott"},
  {"season": 5, "episode": 5, "scene": 49, "line": "Michael....", "order": 22543, "speaker": "Holly Flax"},
  {"season": 5, "episode": 5, "scene": 49, "line": "Seven deadly sins. It's a sign.", "order": 22544, "speaker": "Michael Scott"},
  {"season": 5, "episode": 5, "scene": 49, "line": "Hey, y'all wanna hear some loud music or something?", "order": 22545, "speaker": "Darryl Philbin"},
  {"season": 5, "episode": 5, "scene": 49, "line": "I'm an atheist. Did you know that? I don't know your religion. There are so many conversations that we haven't even had yet.", "order": 22546, "speaker": "Holly Flax"},
  {"season": 5, "episode": 5, "scene": 49, "line": "Hey, look, Pennsylvania license plate! That's crazy! All the way out here! Who can name all the states?", "order": 22547, "speaker": "Darryl Philbin"},
  {"season": 5, "episode": 5, "scene": 50, "line": "[standing with Holly next to 'Welcome to New Hampshire' sign] Oh, it's really cold here.", "order": 22548, "speaker": "Michael Scott"},
  {"season": 5, "episode": 5, "scene": 50, "line": "Oh, it's just later.", "order": 22549, "speaker": "Holly Flax"},
  {"season": 5, "episode": 5, "scene": 50, "line": "Yeah.", "order": 22550, "speaker": "Michael Scott"},
  {"season": 5, "episode": 5, "scene": 50, "line": "[holding camera] Here we go.", "order": 22551, "speaker": "Darryl Philbin"},
  {"season": 5, "episode": 5, "scene": 50, "line": "[starts to cry] Can we have another? I think I blinked.", "order": 22552, "speaker": "Michael Scott"},
  {"season": 5, "episode": 5, "scene": 51, "line": "[unlocking front door] This is me here.", "order": 22553, "speaker": "Holly Flax"},
  {"season": 5, "episode": 5, "scene": 51, "line": "Oh, you have your own entrance. That's nice.", "order": 22554, "speaker": "Michael Scott"},
  {"season": 5, "episode": 5, "scene": 51, "line": "Stairs. Oh, man....", "order": 22555, "speaker": "Darryl Philbin"},
  {"season": 5, "episode": 5, "scene": 52, "line": "He's just trying to push your buttons.", "order": 22556, "speaker": "Angela Martin"},
  {"season": 5, "episode": 5, "scene": 52, "line": "I don't care, so it doesn't matter.", "order": 22557, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 5, "scene": 52, "line": "Great, then it doesn't matter.", "order": 22558, "speaker": "Angela Martin"},
  {"season": 5, "episode": 5, "scene": 52, "line": "You don't think he could get in, though....right? I mean, I don't care, but I just don't see how he could. I doubt he could get in.", "order": 22559, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 5, "scene": 52, "line": "[reverently] He is fiercely intelligent.", "order": 22560, "speaker": "Angela Martin"},
  {"season": 5, "episode": 5, "scene": 52, "line": "I don't care either way, so....shut up.", "order": 22561, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 5, "scene": 52, "line": "He's just....", "order": 22562, "speaker": "Angela Martin"},
  {"season": 5, "episode": 5, "scene": 53, "line": "[in parking lot on cell phone] Don't tell me to calm down, Dad! [grunts] Don't do that! Oh, God, of all people, I thought you would understand. You're an alum. This guy, he's....he's poking the bear! [listens briefly] Yeah, okay, I'll see you at Thanksgiving. Hi to Mom. Bye.", "order": 22563, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 5, "scene": 54, "line": "Why are you doing this to Andy?", "order": 22564, "speaker": "Angela Martin"},
  {"season": 5, "episode": 5, "scene": 54, "line": "You once told me that Andy and I had different strengths. Well, he can't do what I can do. I can get into Cornell, but he doesn't know how to make food and shelter from a golden retriever.", "order": 22565, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 5, "scene": 54, "line": "[looks at Dwight disgustedly and sighs] Even if you do somehow get in, that's not going to make me leave Andy.", "order": 22566, "speaker": "Angela Martin"},
  {"season": 5, "episode": 5, "scene": 54, "line": "No, but it'll make you respect him less.", "order": 22567, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 5, "scene": 54, "line": "[sighs] Yes, that's true.", "order": 22568, "speaker": "Angela Martin"},
  {"season": 5, "episode": 6, "scene": 1, "line": "Hey, sport.", "order": 22569, "speaker": "Michael Scott"},
  {"season": 5, "episode": 6, "scene": 1, "line": "I heard someone got engaged, you dog, huh? [punches Michael's shoulder]", "order": 22570, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 6, "scene": 1, "line": "Ow! God!", "order": 22571, "speaker": "Michael Scott"},
  {"season": 5, "episode": 6, "scene": 1, "line": "Oh, nothing can hurt you now. You're a man in love!", "order": 22572, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 6, "scene": 2, "line": "I was there. That dude is not engaged. I'm not a big believer in therapy, but I'll go into my own pocket to cover his co-pay.", "order": 22573, "speaker": "Darryl Philbin"},
  {"season": 5, "episode": 6, "scene": 3, "line": "Big idea: double wedding. Me, Angela, you, Holly.", "order": 22574, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 6, "scene": 3, "line": "No, we would never do that. And if we did, it would be with Jim and Pam.", "order": 22575, "speaker": "Michael Scott"},
  {"season": 5, "episode": 6, "scene": 3, "line": "Yeah, we'd never do that.", "order": 22576, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 6, "scene": 3, "line": "Yeah, so there you go.", "order": 22577, "speaker": "Michael Scott"},
  {"season": 5, "episode": 6, "scene": 3, "line": "[enters Michael's office] Michael, I got my bridesmaid dress.", "order": 22578, "speaker": "Kelly Kapoor"},
  {"season": 5, "episode": 6, "scene": 3, "line": "Oh, wow, so quickly.", "order": 22579, "speaker": "Michael Scott"},
  {"season": 5, "episode": 6, "scene": 3, "line": "Yeah, and you said I could get it in any color I wanted, so I picked white.", "order": 22580, "speaker": "Kelly Kapoor"},
  {"season": 5, "episode": 6, "scene": 3, "line": "Michael, did you tell your mom yet?", "order": 22581, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 6, "scene": 3, "line": "Nope. Nope. Not yet.", "order": 22582, "speaker": "Michael Scott"},
  {"season": 5, "episode": 6, "scene": 3, "line": "You wouldn't tell your mom?", "order": 22583, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 6, "scene": 3, "line": "You love your mom.", "order": 22584, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 6, "scene": 3, "line": "Call your mom, Michael. [everyone talking]", "order": 22585, "speaker": "Kelly Kapoor"},
  {"season": 5, "episode": 6, "scene": 3, "line": "I'll call her later.", "order": 22586, "speaker": "Michael Scott"},
  {"season": 5, "episode": 6, "scene": 3, "line": "[chanting and clapping] Call her! Call her!", "order": 22587, "speaker": "Group"},
  {"season": 5, "episode": 6, "scene": 3, "line": "I don't want to do that. [chanting continues] All right. [picks up phone and dials] She is going to freak out!", "order": 22588, "speaker": "Michael Scott"},
  {"season": 5, "episode": 6, "scene": 3, "line": "[punches button] Speakerphone!", "order": 22589, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 6, "scene": 3, "line": "That's -- thanks.", "order": 22590, "speaker": "Michael Scott"},
  {"season": 5, "episode": 6, "scene": 3, "line": "[on speakerphone] Hello?", "order": 22591, "speaker": "Mother"},
  {"season": 5, "episode": 6, "scene": 3, "line": "Mom, I'm getting married.", "order": 22592, "speaker": "Michael Scott"},
  {"season": 5, "episode": 6, "scene": 3, "line": "No, you're not.", "order": 22593, "speaker": "Mother"},
  {"season": 5, "episode": 6, "scene": 3, "line": "Why do you always do that? Whenever I'm getting married, you don't believe me.", "order": 22594, "speaker": "Michael Scott"},
  {"season": 5, "episode": 6, "scene": 3, "line": "Well, are you getting married?", "order": 22595, "speaker": "Mother"},
  {"season": 5, "episode": 6, "scene": 3, "line": "No. [laughs]", "order": 22596, "speaker": "Michael Scott"},
  {"season": 5, "episode": 6, "scene": 3, "line": "Are you-- [Michael ends call]", "order": 22597, "speaker": "Mother"},
  {"season": 5, "episode": 6, "scene": 3, "line": "I'm not, I'm not getting married. So... [laughs] Psych.", "order": 22598, "speaker": "Michael Scott"},
  {"season": 5, "episode": 6, "scene": 4, "line": "So I returned my bridesmaid dress, and it was on sale, so I'm out $100.", "order": 22599, "speaker": "Kelly Kapoor"},
  {"season": 5, "episode": 6, "scene": 4, "line": "And I'm out a fiancee. Are those the customer surveys?", "order": 22600, "speaker": "Michael Scott"},
  {"season": 5, "episode": 6, "scene": 4, "line": "Yeah, uh, this is all of them: Jim, Dwight, Phyllis, Stanley, Andy and -- oh. Oh, it's the receipt to my bridesmaid dress. What's that doing there? [hands stack of papers to Michael]", "order": 22601, "speaker": "Kelly Kapoor"},
  {"season": 5, "episode": 6, "scene": 4, "line": "I'll take care of that for you. [crumples receipt and throws it in trash] Thanks.", "order": 22602, "speaker": "Michael Scott"},
  {"season": 5, "episode": 6, "scene": 5, "line": "Kelly Kapoor is our dusky, exotic customer service rep. And once a year, she will contact our clients and find out how happy they are with our salespeople. Sort of a Kapoor's List. 'Schindler's List' parody. ... That's not appropriate, no.", "order": 22603, "speaker": "Michael Scott"},
  {"season": 5, "episode": 6, "scene": 6, "line": "Those reports affect our bonuses, which is kind of great for me, because you wouldn't know it from looking at her, but Pam's a gold-digger.", "order": 22604, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 6, "scene": 6, "line": "[on Bluetooth speaker] Hey, New York ain't free. Get back to work.", "order": 22605, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 6, "scene": 6, "line": "Aww.", "order": 22606, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 6, "scene": 7, "line": "It's the world's tiniest Bluetooth. I found it in a Japanese gadget store in the village. I got one for Jim, too. Jim, say something.", "order": 22607, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 6, "scene": 7, "line": "[on Bluetooth] Testing, testing. Hello, everybody.", "order": 22608, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 6, "scene": 7, "line": "We wanted to stay on the phone all day, but the company has a policy against eight-hour personal calls. So we're not telling anyone.", "order": 22609, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 6, "scene": 7, "line": "Uh, Pam. What do you want on your coffee?", "order": 22610, "speaker": "Alex"},
  {"season": 5, "episode": 6, "scene": 7, "line": "Sprinkle of cinnamon.", "order": 22611, "speaker": "Jim Halpert & Pam Beesly"},
  {"season": 5, "episode": 6, "scene": 7, "line": "I should go.", "order": 22612, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 6, "scene": 8, "line": "Alrighty, Dwighty, let's see how you did.", "order": 22613, "speaker": "Michael Scott"},
  {"season": 5, "episode": 6, "scene": 8, "line": "Bring it on. [starts to put foot up on Michael's desk]", "order": 22614, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 6, "scene": 8, "line": "No.", "order": 22615, "speaker": "Michael Scott"},
  {"season": 5, "episode": 6, "scene": 8, "line": "Sorry.", "order": 22616, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 6, "scene": 8, "line": "Wow.", "order": 22617, "speaker": "Michael Scott"},
  {"season": 5, "episode": 6, "scene": 8, "line": "What does it say?", "order": 22618, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 6, "scene": 8, "line": "Dwight, your feedback is horrible.", "order": 22619, "speaker": "Michael Scott"},
  {"season": 5, "episode": 6, "scene": 8, "line": "That's impossible.", "order": 22620, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 6, "scene": 8, "line": "A number of your clients found your personal style abrasive and distasteful.", "order": 22621, "speaker": "Michael Scott"},
  {"season": 5, "episode": 6, "scene": 8, "line": "I sell more paper than anyone. [stands and reaches for the file]", "order": 22622, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 6, "scene": 8, "line": "No, no, no, no, no.", "order": 22623, "speaker": "Michael Scott"},
  {"season": 5, "episode": 6, "scene": 8, "line": "Wait, is this a joke? I'm getting that queasy feeling that sometimes accompanies jokes.", "order": 22624, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 6, "scene": 8, "line": "Do I look like I am joking?", "order": 22625, "speaker": "Michael Scott"},
  {"season": 5, "episode": 6, "scene": 8, "line": "No, but that's sometimes part of it.", "order": 22626, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 6, "scene": 8, "line": "If I were joking, you would be laughing. Do you look like you are laughing?", "order": 22627, "speaker": "Michael Scott"},
  {"season": 5, "episode": 6, "scene": 8, "line": "Impossible to say. I can't see myself.", "order": 22628, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 6, "scene": 8, "line": "You're not.", "order": 22629, "speaker": "Michael Scott"},
  {"season": 5, "episode": 6, "scene": 9, "line": "Yo, Tommy Tuna, did you get your scores yet?", "order": 22630, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 6, "scene": 9, "line": "Nope.", "order": 22631, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 6, "scene": 9, "line": "I got mine. They were really good.", "order": 22632, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 6, "scene": 9, "line": "[listening in New York] I miss him.", "order": 22633, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 6, "scene": 9, "line": "You must be really proud.", "order": 22634, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 6, "scene": 9, "line": "Yeah, pretty psyched. Whoa! That's my mug.", "order": 22635, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 6, "scene": 9, "line": "Oh, sorry. It was just -- it was right here.", "order": 22636, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 6, "scene": 9, "line": "Right, well, it's mine, so if you could pour it out and get another one.", "order": 22637, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 6, "scene": 9, "line": "OK. Or maybe I could finish the coffee that's in here and you could use [finds a mug] uh, oh -- Snoopy.", "order": 22638, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 6, "scene": 9, "line": "Don't give him Snoopy. That's mine.", "order": 22639, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 6, "scene": 9, "line": "[smiles] It is a great mug. But it's not my mug. That is my mug. So give it back.", "order": 22640, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 6, "scene": 9, "line": "How can you even be sure?", "order": 22641, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 6, "scene": 9, "line": "It has my face on it.", "order": 22642, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 6, "scene": 9, "line": "[holds mug next to Andy's head] Make the face. [Andy smiles] Yeah, I don't see it.", "order": 22643, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 6, "scene": 9, "line": "Dude, that is my face!", "order": 22644, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 6, "scene": 10, "line": "[Dwight slams something on desk] What was that?", "order": 22645, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 6, "scene": 10, "line": "Wow. That was Dwight. He seems upset about something.", "order": 22646, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 6, "scene": 10, "line": "Describe him exactly. What color mustard is his shirt? Yellow or Dijon?", "order": 22647, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 6, "scene": 10, "line": "It is... more of a spicy brown, actually.", "order": 22648, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 6, "scene": 10, "line": "What are you mumbling about?", "order": 22649, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 6, "scene": 10, "line": "How was your meeting with Michael?", "order": 22650, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 6, "scene": 10, "line": "None of your business.", "order": 22651, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 6, "scene": 10, "line": "Was it your scores?", "order": 22652, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 6, "scene": 10, "line": "Those can't be my scores, Jim. For your information --", "order": 22653, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 6, "scene": 10, "line": "Mm-hmm.", "order": 22654, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 6, "scene": 10, "line": "I'm being sabotaged.", "order": 22655, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 6, "scene": 10, "line": "Of course.", "order": 22656, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 6, "scene": 10, "line": "And I'm going to find that person and punish them.", "order": 22657, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 6, "scene": 10, "line": "Absolutely. Or you could just be nice to your customers.", "order": 22658, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 6, "scene": 10, "line": "You're an idiot.", "order": 22659, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 6, "scene": 10, "line": "There's the charm.", "order": 22660, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 6, "scene": 10, "line": "Jimbo, let's do this thang.", "order": 22661, "speaker": "Michael Scott"},
  {"season": 5, "episode": 6, "scene": 10, "line": "That is me. Wish me luck.", "order": 22662, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 6, "scene": 10, "line": "No way.", "order": 22663, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 6, "scene": 10, "line": "[whispers on bluetooth] Good luck.", "order": 22664, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 6, "scene": 10, "line": "Thanks.", "order": 22665, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 6, "scene": 10, "line": "I didn't say anything", "order": 22666, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 6, "scene": 10, "line": "I love you.", "order": 22667, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 6, "scene": 10, "line": "I love you, too.", "order": 22668, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 6, "scene": 10, "line": "What do you think I am saying to you?!", "order": 22669, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 6, "scene": 10, "line": "I'm not talking to you.", "order": 22670, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 6, "scene": 11, "line": "I've caught Jim talking to himself several times today. What a loser. Get a friend, loser.", "order": 22671, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 6, "scene": 12, "line": "[on phone] Yeah, I'm looking at it right now. It's really gorgeous. You guys do great work.", "order": 22672, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 6, "scene": 13, "line": "I found the best tentist on the east coast. He personally tented Giuliani's first and third weddings. And I got him. [shakes fist] I got him!", "order": 22673, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 6, "scene": 14, "line": "[on phone] Would you be able to do the same design, but with walls of gray? And a top that's gray too? Fabulous.", "order": 22674, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 6, "scene": 15, "line": "Oh, come on.", "order": 22675, "speaker": "Michael Scott"},
  {"season": 5, "episode": 6, "scene": 15, "line": "What?", "order": 22676, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 6, "scene": 15, "line": "You too?", "order": 22677, "speaker": "Michael Scott"},
  {"season": 5, "episode": 6, "scene": 15, "line": "Did my scores drop a little?", "order": 22678, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 6, "scene": 15, "line": "Jim, they are a poopy. [Jim coughs, presses button to end call on Bluetooth]", "order": 22679, "speaker": "Michael Scott"},
  {"season": 5, "episode": 6, "scene": 16, "line": "[touches ear] Jim? Jim?", "order": 22680, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 6, "scene": 17, "line": "Are we even sure that's my file?", "order": 22681, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 6, "scene": 17, "line": "No. [glances at it] Yes, I am sure, Jim. It --", "order": 22682, "speaker": "Michael Scott"},
  {"season": 5, "episode": 6, "scene": 17, "line": "Well, there's got to be an explanation.", "order": 22683, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 6, "scene": 17, "line": "I agree.", "order": 22684, "speaker": "Michael Scott"},
  {"season": 5, "episode": 6, "scene": 17, "line": "Yeah.", "order": 22685, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 6, "scene": 17, "line": "So let's see what we can find out from reading. [reads] Jim Halpert is smudge and arrogant.", "order": 22686, "speaker": "Michael Scott"},
  {"season": 5, "episode": 6, "scene": 17, "line": "I think you mean smug", "order": 22687, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 6, "scene": 17, "line": "[points at Jim] Arrogance.", "order": 22688, "speaker": "Michael Scott"},
  {"season": 5, "episode": 6, "scene": 17, "line": "Michael, I'm just trying to --", "order": 22689, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 6, "scene": 17, "line": "And there's our smudgeness.", "order": 22690, "speaker": "Michael Scott"},
  {"season": 5, "episode": 6, "scene": 18, "line": "I need a decent bonus, because I'm actually in the process of buying my parents' house so that they can retire. And if history tells us anything, it's that you can't go wrong buying a house you can't afford. Pam doesn't know about the house, so it's... a fun surprise. [taps Bluetooth earpiece] Pam, sorry about that. I lost you for a second. So, as it turns out, I may not have done so hot on my customer reviews this year.", "order": 22691, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 6, "scene": 18, "line": "Maybe it's because you spent the whole year flirting with the receptionist.", "order": 22692, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 6, "scene": 18, "line": "A little bit. ... Worth it.", "order": 22693, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 6, "scene": 19, "line": "Here's what's going to happen. I am going to have to fix you, manage you two on a more personal scale, a more micro form of management. Jim, what is that called?", "order": 22694, "speaker": "Michael Scott"},
  {"season": 5, "episode": 6, "scene": 19, "line": "Microgement.", "order": 22695, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 6, "scene": 19, "line": "Boom! Yes. Now, Jim is going to be the client. Dwight, you're going to have to sell to him without being aggressive, hostile or difficult. Let's go.", "order": 22696, "speaker": "Michael Scott"},
  {"season": 5, "episode": 6, "scene": 19, "line": "All right, fine. [picks up phone] Brrring.", "order": 22697, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 6, "scene": 19, "line": "[picks up phone] Hello?", "order": 22698, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 6, "scene": 19, "line": "Hello, this is Dwight Schrute from the Dunder Mifflin Paper Company.", "order": 22699, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 6, "scene": 19, "line": "Wow, that's great, because I need paper.", "order": 22700, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 6, "scene": 19, "line": "Excellent, then you are in luck, because we are having a limited-time offer only on everything.", "order": 22701, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 6, "scene": 19, "line": "Wow, this is my lucky day.", "order": 22702, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 6, "scene": 19, "line": "[whispers] Ask him his name.", "order": 22703, "speaker": "Michael Scott"},
  {"season": 5, "episode": 6, "scene": 19, "line": "What is your name, sir?", "order": 22704, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 6, "scene": 19, "line": "I am Bill Buttlicker.", "order": 22705, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 6, "scene": 19, "line": "Really, that's your real name?", "order": 22706, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 6, "scene": 19, "line": "How dare you? My family built this country, by the way.", "order": 22707, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 6, "scene": 19, "line": "Be respectful, Dwight.", "order": 22708, "speaker": "Michael Scott"},
  {"season": 5, "episode": 6, "scene": 19, "line": "Yes, Michael.", "order": 22709, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 6, "scene": 19, "line": "Would you hold on one second? That's my other line.", "order": 22710, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 6, "scene": 19, "line": "What? No, but I --", "order": 22711, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 6, "scene": 19, "line": "Hello? [laughs] No, I'm just on the phone with this stupid salesman. He's so dumb. Probably just gonna keep him on the line forever and not buy anything. Yeah, OK. [punches button on phone]", "order": 22712, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 6, "scene": 19, "line": "It's up to you to change his mind.", "order": 22713, "speaker": "Michael Scott"},
  {"season": 5, "episode": 6, "scene": 19, "line": "Sorry. That was a family emergency.", "order": 22714, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 6, "scene": 19, "line": "Oh, no. What's wrong?", "order": 22715, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 6, "scene": 19, "line": "You know what? That's private.", "order": 22716, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 6, "scene": 19, "line": "Boundaries, Dwight. Come on!", "order": 22717, "speaker": "Michael Scott"},
  {"season": 5, "episode": 6, "scene": 19, "line": "Sorry, Mr. Buttlicker. As I was saying, we're having a limited--", "order": 22718, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 6, "scene": 19, "line": "Sorry, you're going to have to speak up a little bit louder. I'm hard of hearing.", "order": 22719, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 6, "scene": 19, "line": "He's hard of -- he's an old man. Let's go.", "order": 22720, "speaker": "Michael Scott"},
  {"season": 5, "episode": 6, "scene": 19, "line": "OK, as I was saying, right now we are having --", "order": 22721, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 6, "scene": 19, "line": "You're gonna have to talk louder.", "order": 22722, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 6, "scene": 19, "line": "OK, our prices have never been lower.", "order": 22723, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 6, "scene": 19, "line": "Son, you have to talk louder.", "order": 22724, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 6, "scene": 19, "line": "...never been lower!", "order": 22725, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 6, "scene": 19, "line": "Louder, son!", "order": 22726, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 6, "scene": 19, "line": "[shouting] Buttlicker! Our prices have never been lower!", "order": 22727, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 6, "scene": 19, "line": "Stop it! Stop it!", "order": 22728, "speaker": "Michael Scott"},
  {"season": 5, "episode": 6, "scene": 19, "line": "He --", "order": 22729, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 6, "scene": 19, "line": "That is totally inappropriate. You never yell at the client. You never yell at the client.", "order": 22730, "speaker": "Michael Scott"},
  {"season": 5, "episode": 6, "scene": 19, "line": "Now, you listen to me, sir.", "order": 22731, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 6, "scene": 19, "line": "Here we go.", "order": 22732, "speaker": "Michael Scott"},
  {"season": 5, "episode": 6, "scene": 19, "line": "The three words I would describe you as is aggressive, hostile and definitely difficult.", "order": 22733, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 6, "scene": 19, "line": "Give me the phone.", "order": 22734, "speaker": "Michael Scott"},
  {"season": 5, "episode": 6, "scene": 19, "line": "Please, Mr. Buttlicker --", "order": 22735, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 6, "scene": 19, "line": "I'm irate right now.", "order": 22736, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 6, "scene": 19, "line": "Give me the phone.", "order": 22737, "speaker": "Michael Scott"},
  {"season": 5, "episode": 6, "scene": 19, "line": "Please give me another chance. Mr. Buttlicker.", "order": 22738, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 6, "scene": 19, "line": "Give me the phone. Give me the phone.", "order": 22739, "speaker": "Michael Scott"},
  {"season": 5, "episode": 6, "scene": 19, "line": "I have to put you on with my boss.", "order": 22740, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 6, "scene": 19, "line": "Well, I should hope so. [Michael takes phone] Who is this?", "order": 22741, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 6, "scene": 19, "line": "Hello, this is Michael Scott, regional manager.", "order": 22742, "speaker": "Michael Scott"},
  {"season": 5, "episode": 6, "scene": 19, "line": "Well, this is William M. Buttlicker.", "order": 22743, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 6, "scene": 19, "line": "Hello, Mr. Buttlicker. How may we help you?", "order": 22744, "speaker": "Michael Scott"},
  {"season": 5, "episode": 6, "scene": 19, "line": "Michael, I like the sound of your voice. You know what I'm going to do? I'm going to buy one million dollars worth of paper products today.", "order": 22745, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 6, "scene": 19, "line": "[shakes fist, whispers] Yeah!", "order": 22746, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 6, "scene": 19, "line": "[covers phone, whispers to Dwight] See how it's done? [into phone] Thank you very much, sir. I don't think you'll regret it. [to Dwight] See what I did?", "order": 22747, "speaker": "Michael Scott"},
  {"season": 5, "episode": 6, "scene": 19, "line": "You are the master.", "order": 22748, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 6, "scene": 19, "line": "There is one condition, Michael.", "order": 22749, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 6, "scene": 19, "line": "Yes.", "order": 22750, "speaker": "Michael Scott"},
  {"season": 5, "episode": 6, "scene": 19, "line": "You have to fire the salesman that treated me so terribly.", "order": 22751, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 6, "scene": 19, "line": "Don't do it, Michael.", "order": 22752, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 6, "scene": 19, "line": "... [whispers] It's a million-dollar sale.", "order": 22753, "speaker": "Michael Scott"},
  {"season": 5, "episode": 6, "scene": 20, "line": "So it's called the Shangri-La Tent. It's two stories, heated and has a bridal suite for my bridal sweet. It's just really simple, really tasteful.", "order": 22754, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 6, "scene": 20, "line": "I don't want to be married in a tent like a hobo", "order": 22755, "speaker": "Angela Martin"},
  {"season": 5, "episode": 6, "scene": 20, "line": "Hobos live in trains.", "order": 22756, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 6, "scene": 20, "line": "Nana Mimi can't be in canvas that long.", "order": 22757, "speaker": "Angela Martin"},
  {"season": 5, "episode": 6, "scene": 20, "line": "Well, Nana Mim -- Ahh. OK, look. This tent is awesome. And it's in high demand, so I really think we should put a deposit down now.", "order": 22758, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 6, "scene": 20, "line": "OK fine you can have your tent, but only if it's in a field, a hand-plowed field", "order": 22759, "speaker": "Angela Martin"},
  {"season": 5, "episode": 6, "scene": 20, "line": "Done and done-er.", "order": 22760, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 6, "scene": 20, "line": "There has to be a barn that's old enough that you can see the stars through the roof slats when you lay on your back. And antique tools to look at when you roll over.", "order": 22761, "speaker": "Angela Martin"},
  {"season": 5, "episode": 6, "scene": 20, "line": "Do you have a specific place in mind?", "order": 22762, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 6, "scene": 20, "line": "No. But anything within a five- to eight-mile radius is acceptable.", "order": 22763, "speaker": "Angela Martin"},
  {"season": 5, "episode": 6, "scene": 20, "line": "On it!", "order": 22764, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 6, "scene": 21, "line": "Dwight. Dwight. [Dwight's car comes screeching into view]", "order": 22765, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 6, "scene": 21, "line": "Get in!", "order": 22766, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 6, "scene": 21, "line": "Are you serious?", "order": 22767, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 6, "scene": 21, "line": "Get in! [he peels off into parking space]", "order": 22768, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 6, "scene": 21, "line": "OK, what are you --", "order": 22769, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 6, "scene": 21, "line": "Shh. [turns radio up loud, playing 'Centerfold.'] They might be listening to us.", "order": 22770, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 6, "scene": 21, "line": "What's that?", "order": 22771, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 6, "scene": 21, "line": "They might be listening to us", "order": 22772, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 6, "scene": 21, "line": "Who's they?", "order": 22773, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 6, "scene": 21, "line": "Customer service might be monitoring this conversation.", "order": 22774, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 6, "scene": 21, "line": "In this car?", "order": 22775, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 6, "scene": 21, "line": "You never know. Better safe than sorry.", "order": 22776, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 6, "scene": 21, "line": "[turns radio down] What are you thinking?", "order": 22777, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 6, "scene": 21, "line": "Who stands to benefit from our downfall?", "order": 22778, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 6, "scene": 21, "line": "The mob, maybe NASA.", "order": 22779, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 6, "scene": 21, "line": "Could be the mob. But then Dunder-Mifflin would need to be a front for money laundering, and there's little evidence of that.", "order": 22780, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 6, "scene": 21, "line": "Is there some evidence?", "order": 22781, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 6, "scene": 21, "line": "[on Bluetooth] Ooh, cute shoes online.", "order": 22782, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 6, "scene": 21, "line": "How many shoes do you need?", "order": 22783, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 6, "scene": 21, "line": "I don't know. Two, maybe three if one wears out. How many shoes do you need?", "order": 22784, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 6, "scene": 22, "line": "I'm not talking to you.", "order": 22785, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 6, "scene": 22, "line": "Who are you talking to?", "order": 22786, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 6, "scene": 22, "line": "Pam.", "order": 22787, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 6, "scene": 22, "line": "She's not here, Jim.", "order": 22788, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 6, "scene": 22, "line": "No, she's not.", "order": 22789, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 6, "scene": 23, "line": "[in New York, humming to herself to the tune of 'Centerfold'] Na na na na na. Na na na na na na na.", "order": 22790, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 6, "scene": 24, "line": "[at computer, spins chair around and makes triumphant gesture] Yes!", "order": 22791, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 6, "scene": 25, "line": "I found it. I found the perfect place. A local bed-and-breakfast on a 60-acre beet farm. And even better, I have an in with the owner. Oh, yeah. We work together. It's Dwight Schrute. As in Schrute Farms.", "order": 22792, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 6, "scene": 26, "line": "[on phone] So I'd like to redouble my order, if you could put me down for, um --", "order": 22793, "speaker": "Customer"},
  {"season": 5, "episode": 6, "scene": 26, "line": "Wait, shut up.", "order": 22794, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 6, "scene": 26, "line": "I'm sorry?", "order": 22795, "speaker": "Customer"},
  {"season": 5, "episode": 6, "scene": 26, "line": "Shh. Do you hear that?", "order": 22796, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 6, "scene": 26, "line": "Hear what?", "order": 22797, "speaker": "Customer"},
  {"season": 5, "episode": 6, "scene": 26, "line": "Breathing. Is that you?", "order": 22798, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 6, "scene": 26, "line": "Well, I am breathing, yes.", "order": 22799, "speaker": "Customer"},
  {"season": 5, "episode": 6, "scene": 26, "line": "Well, stop. Hold your breath. I still hear it. Who's there? Kelly, is that you? Hold on. [puts down phone and runs away]", "order": 22800, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 6, "scene": 26, "line": "I need paper.", "order": 22801, "speaker": "Customer"},
  {"season": 5, "episode": 6, "scene": 27, "line": "[jumps into Kelly's area] Ha! [Kelly screams in surprise]", "order": 22802, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 6, "scene": 27, "line": "Oh, my God. You scared me.", "order": 22803, "speaker": "Kelly Kapoor"},
  {"season": 5, "episode": 6, "scene": 27, "line": "Hear anything interesting?", "order": 22804, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 6, "scene": 27, "line": "What are you talking about?", "order": 22805, "speaker": "Kelly Kapoor"},
  {"season": 5, "episode": 6, "scene": 27, "line": "[laughs] I think you know.", "order": 22806, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 6, "scene": 27, "line": "You always say that, and I almost never know.", "order": 22807, "speaker": "Kelly Kapoor"},
  {"season": 5, "episode": 6, "scene": 27, "line": "What are you up to, girl? Huh? Phyllis put you up to this? Stanley? Are they paying you?", "order": 22808, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 6, "scene": 27, "line": "Are you accusing me of something?", "order": 22809, "speaker": "Kelly Kapoor"},
  {"season": 5, "episode": 6, "scene": 27, "line": "Of course I am. I know you're the mastermind, but you're too stupid to do it by yourself.", "order": 22810, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 6, "scene": 27, "line": "[behind Dwight]: OK.", "order": 22811, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 6, "scene": 27, "line": "[surprised] Ah!", "order": 22812, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 6, "scene": 27, "line": "Easy.", "order": 22813, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 6, "scene": 27, "line": "OK.", "order": 22814, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 6, "scene": 27, "line": "Let's just -- Let's head back to the desk.", "order": 22815, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 6, "scene": 27, "line": "You just can't come into my nook and call me stupid. And maybe if you were a little bit more nice and polite, then people wouldn't give you such bad customer reviews.", "order": 22816, "speaker": "Kelly Kapoor"},
  {"season": 5, "episode": 6, "scene": 27, "line": "The reason that I got bad customer reviews is because I didn't! There is a massive conspiracy going on here, and I know you're involved.", "order": 22817, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 6, "scene": 27, "line": "Dwight, get out of my nook!", "order": 22818, "speaker": "Kelly Kapoor"},
  {"season": 5, "episode": 6, "scene": 27, "line": "[in New York] That's what she said! That's what she said! That's what she said!", "order": 22819, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 6, "scene": 27, "line": "Good one.", "order": 22820, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 6, "scene": 28, "line": "[enters breakroom] Hey. Sorry about Dwight, by the way.", "order": 22821, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 6, "scene": 28, "line": "Yeah, he's weird.", "order": 22822, "speaker": "Kelly Kapoor"},
  {"season": 5, "episode": 6, "scene": 28, "line": "Well, we all handle it differently. I personally choose to handle it like a normal person, but...", "order": 22823, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 6, "scene": 28, "line": "OK.", "order": 22824, "speaker": "Kelly Kapoor"},
  {"season": 5, "episode": 6, "scene": 28, "line": "Hey, how are you and Darryl?", "order": 22825, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 6, "scene": 28, "line": "Um, we're cool. [gets up to leave] Bye.", "order": 22826, "speaker": "Kelly Kapoor"},
  {"season": 5, "episode": 6, "scene": 28, "line": "Bye.", "order": 22827, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 6, "scene": 28, "line": "[on Bluetooth] That was weird.", "order": 22828, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 6, "scene": 28, "line": "What was?", "order": 22829, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 6, "scene": 28, "line": "Have you ever had a conversation with Kelly where she didn't go on for 15 minutes without taking a breath?", "order": 22830, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 6, "scene": 28, "line": "No, actually.", "order": 22831, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 6, "scene": 28, "line": "Did you do something to her?", "order": 22832, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 6, "scene": 28, "line": "I don't think so.", "order": 22833, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 6, "scene": 28, "line": "Well, something's off.", "order": 22834, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 6, "scene": 29, "line": "Hey, how's things?", "order": 22835, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 6, "scene": 29, "line": "All right.", "order": 22836, "speaker": "Ryan Howard"},
  {"season": 5, "episode": 6, "scene": 29, "line": "Yeah?", "order": 22837, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 6, "scene": 29, "line": "Living in the moment.", "order": 22838, "speaker": "Ryan Howard"},
  {"season": 5, "episode": 6, "scene": 29, "line": "Do you have any reason to believe that Kelly would be mad at me?", "order": 22839, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 6, "scene": 29, "line": "I don't play the politics game anymore, Jim. Can I tell you something? I played it full on in New York. I played it high stakes. For keeps. Made it to the top. But look what it cost. [takes sip of coffee] Can I tell you what else I learned?", "order": 22840, "speaker": "Ryan Howard"},
  {"season": 5, "episode": 6, "scene": 29, "line": "Wait, that's pretty weird.", "order": 22841, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 6, "scene": 29, "line": "What?", "order": 22842, "speaker": "Ryan Howard"},
  {"season": 5, "episode": 6, "scene": 29, "line": "Well, Andy has a mug just like that.", "order": 22843, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 6, "scene": 29, "line": "Oh, yeah, Kelly gave them out as party favors. Remember? You got one.", "order": 22844, "speaker": "Ryan Howard"},
  {"season": 5, "episode": 6, "scene": 29, "line": "No. What party?", "order": 22845, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 6, "scene": 29, "line": "Her America's Got Talent finale party over the summer. [Jim makes face] That's crazy. It was packed. I thought everyone was there. You were there. I remember you being there.", "order": 22846, "speaker": "Ryan Howard"},
  {"season": 5, "episode": 6, "scene": 29, "line": "I wasn't. But thank you. [to Pam] Do you know anything about this party?", "order": 22847, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 6, "scene": 29, "line": "Yes, I said you definitely should go, but you wanted to visit me instead.", "order": 22848, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 6, "scene": 29, "line": "Well, I can't be the only one who didn't -- [sees mugs on Angela's and Meredith's desks]", "order": 22849, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 6, "scene": 30, "line": "[walks quickly to break room, opens cabinet and finds mugs of Oscar, Creed and Phyllis]", "order": 22850, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 6, "scene": 31, "line": "Dwight, let me see your coffee cup.", "order": 22851, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 6, "scene": 31, "line": "No. [holds Sheriff's Department mug protectively]", "order": 22852, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 6, "scene": 31, "line": "Is that it?", "order": 22853, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 6, "scene": 31, "line": "No. Why? No. [puts it in desk drawer and slams it shut]", "order": 22854, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 6, "scene": 31, "line": "OK, I'm gonna assume that was it. Here's the thing: I think you're right. I think it was Kelly. I think she's mad at us for not coming to her party.", "order": 22855, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 6, "scene": 31, "line": "Oh, man, I knew it. Who are her co-conspirators?", "order": 22856, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 6, "scene": 31, "line": "Probably just Kelly.", "order": 22857, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 6, "scene": 31, "line": "Obviously. I knew it.", "order": 22858, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 6, "scene": 31, "line": "That's what I'm saying.", "order": 22859, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 6, "scene": 31, "line": "Yeah. What?", "order": 22860, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 6, "scene": 31, "line": "You were right.", "order": 22861, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 6, "scene": 31, "line": "I was -- I was right.", "order": 22862, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 6, "scene": 31, "line": "You were right.", "order": 22863, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 6, "scene": 31, "line": "I was right.", "order": 22864, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 6, "scene": 31, "line": "You knew it.", "order": 22865, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 6, "scene": 31, "line": "I knew it.", "order": 22866, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 6, "scene": 31, "line": "You knew it the whole time, buddy.", "order": 22867, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 6, "scene": 31, "line": "I knew it the whole time, buddy! [shoves Jim]", "order": 22868, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 6, "scene": 31, "line": "Wow.", "order": 22869, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 6, "scene": 31, "line": "Yeah! Woo-hoo!", "order": 22870, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 6, "scene": 32, "line": "[in New York] Right Dwight is loud.", "order": 22871, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 6, "scene": 33, "line": "I knew it! Yeah! Ha-ha! Woo! [kicks near Phyllis' head; she ducks] Boom! Kelly the whole time. Let's get her.", "order": 22872, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 6, "scene": 33, "line": "No, no, no, no. Dwight, Dwight, Dwight. Let's talk about this.", "order": 22873, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 6, "scene": 34, "line": "What's going on?", "order": 22874, "speaker": "Kelly Kapoor"},
  {"season": 5, "episode": 6, "scene": 34, "line": "Why don't you tell us?", "order": 22875, "speaker": "Michael Scott"},
  {"season": 5, "episode": 6, "scene": 34, "line": "Nothing's going on.", "order": 22876, "speaker": "Kelly Kapoor"},
  {"season": 5, "episode": 6, "scene": 34, "line": "Let me paint you a picture of a little girl from southern India, who despite being welcomed into this country will now spend the rest of her life in prison for a crime she did commit.", "order": 22877, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 6, "scene": 34, "line": "Dwight. [to Kelly] I was informed by these gentlemen that the reports that you filed may not entirely be accurate.", "order": 22878, "speaker": "Michael Scott"},
  {"season": 5, "episode": 6, "scene": 34, "line": "What? I -- I don't know what you're talking about. This is the first I'm hearing about this.", "order": 22879, "speaker": "Kelly Kapoor"},
  {"season": 5, "episode": 6, "scene": 34, "line": "Oh, come on. You juked the stats, cupcake.", "order": 22880, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 6, "scene": 34, "line": "We called about a dozen customers, and they all said that they gave us great marks.", "order": 22881, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 6, "scene": 34, "line": "What's going on?", "order": 22882, "speaker": "Michael Scott"},
  {"season": 5, "episode": 6, "scene": 34, "line": "I love your tie, Michael.", "order": 22883, "speaker": "Kelly Kapoor"},
  {"season": 5, "episode": 6, "scene": 34, "line": "[looks momentarily flattered, but thinks better of it] Kelly.", "order": 22884, "speaker": "Michael Scott"},
  {"season": 5, "episode": 6, "scene": 34, "line": "I was raped.", "order": 22885, "speaker": "Kelly Kapoor"},
  {"season": 5, "episode": 6, "scene": 34, "line": "You cannot say 'I was raped' and expect all of your problems to go away, Kelly. Not again. Don't keep doing that. I'll give you one last chance to come clean. Just tell me what happened.", "order": 22886, "speaker": "Michael Scott"},
  {"season": 5, "episode": 6, "scene": 34, "line": "OK, all right. OK, I did it. All right? I lied, whatever. Just fire me. But you know what? I did it because you guys didn't come to my party, and you said you would try to and then you didn't even show up, and so you're bad friends.", "order": 22887, "speaker": "Kelly Kapoor"},
  {"season": 5, "episode": 6, "scene": 34, "line": "We have our confession. I'm calling security. [reaches for phone]", "order": 22888, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 6, "scene": 34, "line": "Don't. Don't. Get away from that, Dwight, please. You know what? She's got a point about you two. You do have a problem dealing with people.", "order": 22889, "speaker": "Michael Scott"},
  {"season": 5, "episode": 6, "scene": 34, "line": "See? I wasn't lying.", "order": 22890, "speaker": "Kelly Kapoor"},
  {"season": 5, "episode": 6, "scene": 34, "line": "You were lying.", "order": 22891, "speaker": "Michael Scott"},
  {"season": 5, "episode": 6, "scene": 34, "line": "I was lying.", "order": 22892, "speaker": "Kelly Kapoor"},
  {"season": 5, "episode": 6, "scene": 34, "line": "Yes. Jim, Dwight, please excuse us.", "order": 22893, "speaker": "Michael Scott"},
  {"season": 5, "episode": 6, "scene": 34, "line": "I want to be here when you fire her ass.", "order": 22894, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 6, "scene": 34, "line": "I will call you when it is time. [Jim and Dwight leave]", "order": 22895, "speaker": "Michael Scott"},
  {"season": 5, "episode": 6, "scene": 34, "line": "I have an enormous amount of trouble trying to get people to come to my place. And I hate it. I can't tell you how much leftover guacamole I have ended up eating over the years. I don't even know why I make it in such great quantities. Here's what we're gonna do. We are going to sit here for a while, make it look good. And maybe you should cry. Can you make yourself cry?", "order": 22896, "speaker": "Michael Scott"},
  {"season": 5, "episode": 6, "scene": 34, "line": "No problem.", "order": 22897, "speaker": "Kelly Kapoor"},
  {"season": 5, "episode": 6, "scene": 34, "line": "I think you should do that. [Kelly starts fake-crying, then laughing, then she and Michael both laugh]", "order": 22898, "speaker": "Michael Scott"},
  {"season": 5, "episode": 6, "scene": 35, "line": "Pam Beesley?", "order": 22899, "speaker": "Alex"},
  {"season": 5, "episode": 6, "scene": 35, "line": "Hey, what are you doing here?", "order": 22900, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 6, "scene": 35, "line": "Who's that?", "order": 22901, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 6, "scene": 35, "line": "It's Alex.", "order": 22902, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 6, "scene": 35, "line": "It's Pam. Uh, I came to kidnap you. There's free wine and cheese at the Chuck Close retrospective. Let's go.", "order": 22903, "speaker": "Alex"},
  {"season": 5, "episode": 6, "scene": 35, "line": "Oh, that's gonna be great. Who's Chuck Close?", "order": 22904, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 6, "scene": 35, "line": "Oh, I love Chuck Close and his photo-realist paintings. But I have to work.", "order": 22905, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 6, "scene": 35, "line": "Uh, well, actually there's something else I'd love to talk to you about. Can we go somewhere else to talk about it?", "order": 22906, "speaker": "Alex"},
  {"season": 5, "episode": 6, "scene": 35, "line": "OK.", "order": 22907, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 6, "scene": 35, "line": "That's it. I want to talk to this guy. Put me in his ear.", "order": 22908, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 6, "scene": 35, "line": "[in private office] Um.", "order": 22909, "speaker": "Alex"},
  {"season": 5, "episode": 6, "scene": 35, "line": "What's up?", "order": 22910, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 6, "scene": 35, "line": "I'm gonna take a big leap and I want to tell you that I think you should not move back to Scranton.", "order": 22911, "speaker": "Alex"},
  {"season": 5, "episode": 6, "scene": 35, "line": "Wow.", "order": 22912, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 6, "scene": 35, "line": "I'm gonna make a bigger leap here. He is into you.", "order": 22913, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 6, "scene": 35, "line": "Why did you come to New York in the first place?", "order": 22914, "speaker": "Alex"},
  {"season": 5, "episode": 6, "scene": 35, "line": "Because they have a great design program, and I wanted to see if I was any good at it. And I wanted to work on my art, too.", "order": 22915, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 6, "scene": 35, "line": "Right. And that's why I think that you should stay here. Because I mean, you -- really you just got here, you know? You can't do New York in three months. You know, it has everything. It has -- all the opportunity is here. All the -- the whole art scene is in New York. You know, it would be nuts to go back to Scranton without getting to fully experience it.", "order": 22916, "speaker": "Alex"},
  {"season": 5, "episode": 6, "scene": 35, "line": "Jim's in Scranton.", "order": 22917, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 6, "scene": 35, "line": "I know. But all I'm saying is, if there's even a teeny, tiny part of you that really wants to be an artist, then I think you should stay here, because you don't want to wake up in 50 years and look back and wonder what could have been. And that is the end of my speech. I planned it all. Anyway ... I will see you tomorrow.", "order": 22918, "speaker": "Alex"},
  {"season": 5, "episode": 6, "scene": 35, "line": "I'll see you tomorrow.", "order": 22919, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 6, "scene": 35, "line": "OK. [Pam and Jim look worried]", "order": 22920, "speaker": "Alex"},
  {"season": 5, "episode": 6, "scene": 36, "line": "Is that the Matsuhashi B-400? The world's tiniest Bluetooth? [reaches toward Jim's ear] May I?", "order": 22921, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 6, "scene": 36, "line": "Don't.", "order": 22922, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 6, "scene": 37, "line": "Congratulations on choosing Schrute Farms for your wedding.", "order": 22923, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 6, "scene": 37, "line": "We haven't decided on anything yet. We're still reviewing some options, and it's gonna come down to the numbers.", "order": 22924, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 6, "scene": 37, "line": "Well, then. Why don't you look over some of our materials? [opens album]", "order": 22925, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 6, "scene": 37, "line": "Oh. Hmm.", "order": 22926, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 6, "scene": 37, "line": "[looking at Angela] While I describe to you the Excalibur package. In addition to the breathtaking natural beauty and smell of Schrute Farms, I can promise you that our grounds can be catered to fit your exact specifications. [Angela smiles] I will work tirelessly for you over the coming months and be at your constant disposal. [Andy moves his head into Dwight's view] Please feel free to call or stop by, any time of the day or night.", "order": 22927, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 6, "scene": 37, "line": "That's very generous.", "order": 22928, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 6, "scene": 37, "line": "While you, my good friend, have nothing more to worry about. This wedding is officially out of your hands.", "order": 22929, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 6, "scene": 37, "line": "Oh, thank the good Lord. Deal! [shakes Dwight's hand]", "order": 22930, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 6, "scene": 37, "line": "OK.", "order": 22931, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 6, "scene": 37, "line": "Um, what are we talking price wise?", "order": 22932, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 6, "scene": 37, "line": "You already said deal.", "order": 22933, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 6, "scene": 37, "line": "Pay him whatever he wants.", "order": 22934, "speaker": "Angela Martin"},
  {"season": 5, "episode": 6, "scene": 37, "line": "Can't argue with that. Dwight ... [takes Angela's hand] You are going to make us so happy. [Dwight and Angela grin at each other]", "order": 22935, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 6, "scene": 38, "line": "Come on, how did you propose? Spill it. Were you wasted?", "order": 22936, "speaker": "Meredith Palmer"},
  {"season": 5, "episode": 6, "scene": 38, "line": "How did I propose, let me see... well, I drove her up to Nashua, and I had the ring, a big 10-carat diamond; it was beautiful. And I got down on my hands and knees, and a shooting star crossed overhead, and it just lit up the diamond like a shooting star. And we were in a restaurant, and I put the diamond into a cheeseburger.", "order": 22937, "speaker": "Michael Scott"},
  {"season": 5, "episode": 6, "scene": 38, "line": "Ooooh.", "order": 22938, "speaker": "Kevin Malone & Andy Bernard"},
  {"season": 5, "episode": 6, "scene": 38, "line": "And she took a bite, started to choke. So what do I do? I have CPR training. Go around, start doing the Heimlich.", "order": 22939, "speaker": "Michael Scott"},
  {"season": 5, "episode": 6, "scene": 38, "line": "Perfect.", "order": 22940, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 6, "scene": 38, "line": "The ring, 10-carat diamond, pops out of her mouth, hits her shrimp cocktail, right onto her finger. Million-to-one shot. All of the Greek people in the restaurant start screaming 'Opa!' Which means 'congratulations,' so...", "order": 22941, "speaker": "Michael Scott"},
  {"season": 5, "episode": 6, "scene": 38, "line": "Oh, man!", "order": 22942, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 6, "scene": 39, "line": "[in Jim's ear] Ask how he's doing since the breakup.", "order": 22943, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 6, "scene": 39, "line": "So, how you holdin' up?", "order": 22944, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 6, "scene": 39, "line": "I'm pretty much devastated.", "order": 22945, "speaker": "Michael Scott"},
  {"season": 5, "episode": 6, "scene": 39, "line": "Ask if there's anything you can do.", "order": 22946, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 6, "scene": 39, "line": "You know what you should do? Take a vacation.", "order": 22947, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 6, "scene": 39, "line": "Maybe go to Sandals, Jamaica. Or the other Sandals, Jamaica. I just don't want to go by myself.", "order": 22948, "speaker": "Michael Scott"},
  {"season": 5, "episode": 6, "scene": 39, "line": "Tell him you'd like to go with him.", "order": 22949, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 6, "scene": 39, "line": "We should take a look at those surveys.", "order": 22950, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 6, "scene": 40, "line": "Why are surveys important? Okay, hypothetical: A man wants to buy 500 reams of....carrots. And, so he's like 'What do I do? Where do I go? Do I go to the insensitive carrot supplier, the insensitive carrot salesman? Or do I go to the nice carrot salesman, who will maybe charge a few cents more per ream of carrot, but guarantee next-day delivery on your carrots? Most companies need their carrots the next day. Now, substitute paper for carrots, and that is why surveys are important.'", "order": 22951, "speaker": "Michael Scott"},
  {"season": 5, "episode": 6, "scene": 41, "line": "Great marks, Phyllis. [Phyllis walks out of Michael's office smiling]", "order": 22952, "speaker": "Michael Scott"},
  {"season": 5, "episode": 6, "scene": 41, "line": "[to Stanley] Hey, I did good too!", "order": 22953, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 6, "scene": 41, "line": "I knew it. Haha! [they high five]", "order": 22954, "speaker": "Stanley Hudson"},
  {"season": 5, "episode": 6, "scene": 41, "line": "[hand up, seeking a high five] Up! Don't leave me hangin'! [they do]", "order": 22955, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 6, "scene": 42, "line": "What do I do with my bonus check? [scoffs] Simple. Purchase paper from myself. Boosts my sales, which boosts my bonus. Last year I bought even more paper to make this year's bonus even bigger. Eventually, I'll get a bonus so big I can retire on it. And I'll use the paper to write my memoirs.", "order": 22956, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 6, "scene": 43, "line": "I taught Jim and Dwight everything I know about sales. But there are certain things that cannot be taught, and it is these things that I now must teach them.", "order": 22957, "speaker": "Michael Scott"},
  {"season": 5, "episode": 6, "scene": 44, "line": "Attention, all problem sales persons. Jim Halpert... Dwight Schrute...", "order": 22958, "speaker": "Michael Scott"},
  {"season": 5, "episode": 6, "scene": 44, "line": "[raises hand] Here.", "order": 22959, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 6, "scene": 44, "line": "Intensive one-on-one retraining will begin after lunch. That is all.", "order": 22960, "speaker": "Michael Scott"},
  {"season": 5, "episode": 6, "scene": 44, "line": "I am no longer the worst salesman in the office. Yes! [Andy gestures excitedly, knocking over his coffee mug] Ow! Dammit!", "order": 22961, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 6, "scene": 45, "line": "Dwight and I are both writing letters to our clients. I'm just sending a quick note to say how much I appreciate their business, and he is writing notarized letters to inform them them that lying on customer reviews is a prosecutable form of fraud and defamation. It's just... different styles.", "order": 22962, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 6, "scene": 46, "line": "My job is to manipulate the customer into buying paper. In return, they give me money. I am the predator and they are the prey. Would a mouse give a positive review to a hawk? Should the hawk even care? [sighs heavily, nearly starts to cry] Excuse me, I've got work to do.", "order": 22963, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 6, "scene": 47, "line": "[laughs heartily and passes a newspaper to Phyllis]", "order": 22964, "speaker": "Stanley Hudson"},
  {"season": 5, "episode": 6, "scene": 47, "line": "[laughing] It's good!", "order": 22965, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 6, "scene": 47, "line": "Hey, what are you laughing at?", "order": 22966, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 6, "scene": 47, "line": "[continue to laugh]", "order": 22967, "speaker": "Stanley Hudson & Phyllis Vance"},
  {"season": 5, "episode": 6, "scene": 47, "line": "Are you laughing at me?", "order": 22968, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 6, "scene": 47, "line": "We're laughing at this cartoon! [passes newspaper to Dwight]", "order": 22969, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 6, "scene": 47, "line": "Perfect, isn't it?", "order": 22970, "speaker": "Stanley Hudson"},
  {"season": 5, "episode": 6, "scene": 47, "line": "[looks at cartoon] How is that funny? What are you really laughing at? Huh?", "order": 22971, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 6, "scene": 47, "line": "Haven't you been following the news? Ther university is using its non-profit status to fund its private real estate investments.", "order": 22972, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 6, "scene": 47, "line": "[laughing louder] And he really nailed them on it. Somebody's finally holding them accountable!", "order": 22973, "speaker": "Stanley Hudson"},
  {"season": 5, "episode": 6, "scene": 47, "line": "[looking at cartoon] There's no way you're laughing at this.", "order": 22974, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 6, "scene": 48, "line": "[peering nervously through conference room window] Oh, I don't have anything to say. I'm just hiding from Dwight.", "order": 22975, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 6, "scene": 48, "line": "[is seen taking apart his phone receiver to check for listening devices]", "order": 22976, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 6, "scene": 49, "line": "It's good to be paranoid. People need to be more paranoid. Case in point, JFK. If I had been JFK, I would have seen all three gunmen. I would have pulled out my concealed Luger and fired first. Man in book depository, BOOM! Grassy knoll, BOOM! Fake Jackie, BOOM! [pretends to kiss, then shoot imaginary person to his left] Then I shoot myself, so I don't change history and create a paradox. BOOM! But right at the last minute, [contorts head sideways] I twist out of the way of the bullet. Nice try, history. Better luck next year.", "order": 22977, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 6, "scene": 50, "line": "So, as it turns out, Dwight is right. How bizarre.", "order": 22978, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 6, "scene": 50, "line": "[in Jim's ear] I wonder what else Dwight's been right about....", "order": 22979, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 6, "scene": 51, "line": "Major pharmaceuticals do not want you to know that beet juice has medicinal value both as an aphrodisiac and a laxative.", "order": 22980, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 6, "scene": 51, "line": "The Scranton Zoning Board has a strong bias against beet farmers. The mayor is in the pocket of Big Lettuce.", "order": 22981, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 6, "scene": 51, "line": "Battlestar Galactica isn't a documentary exactly.", "order": 22982, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 6, "scene": 51, "line": "The book All The President's Men is about a conspiracy, just not the one people think.", "order": 22983, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 6, "scene": 51, "line": "Jesus had a daughter, and she was the Mona Lisa.", "order": 22984, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 6, "scene": 51, "line": "What we have come to know as fake crab meat is in fact real crab meat, and real crab meat is lobster.", "order": 22985, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 7, "scene": 1, "line": "Did you know that in Morocco it is common to exchange a small gift when meeting somebody for the first time? In Japan you must always commit suicide to avoid embarrassment. In Italy you must always wash your hands after going to the bathroom. This is considered to be polite.", "order": 22986, "speaker": "Michael Scott"},
  {"season": 5, "episode": 7, "scene": 1, "line": "Why are you telling us this?", "order": 22987, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 7, "scene": 1, "line": "I am jetting off on an international business trip.", "order": 22988, "speaker": "Michael Scott"},
  {"season": 5, "episode": 7, "scene": 1, "line": "Where are you going?", "order": 22989, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 7, "scene": 1, "line": "To Can-A-da.", "order": 22990, "speaker": "Michael Scott"},
  {"season": 5, "episode": 7, "scene": 1, "line": "Where is it?", "order": 22991, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 7, "scene": 1, "line": "Canada.", "order": 22992, "speaker": "Michael Scott"},
  {"season": 5, "episode": 7, "scene": 1, "line": "Okay.", "order": 22993, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 7, "scene": 2, "line": "My boss is sending me abroad to do a presentation to an international client and I have always been intrigued by all things international. The women, the pancakes, the man of mystery...", "order": 22994, "speaker": "Michael Scott"},
  {"season": 5, "episode": 7, "scene": 3, "line": "Meredith, I would like you to pretend that you are from Abu Dhabi.", "order": 22995, "speaker": "Michael Scott"},
  {"season": 5, "episode": 7, "scene": 3, "line": "[British accent] Hello.", "order": 22996, "speaker": "Meredith Palmer"},
  {"season": 5, "episode": 7, "scene": 3, "line": "I am ashamed at your naked face. I must cover it with my jacket. [covers Meredith's face with his jacket] You are now sexy in your culture.", "order": 22997, "speaker": "Michael Scott"},
  {"season": 5, "episode": 7, "scene": 4, "line": "T minus...", "order": 22998, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 7, "scene": 4, "line": "Six point five days. [winces as Kevin smacks him on the back]", "order": 22999, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 7, "scene": 4, "line": "[walks up behind Jim and puts his hands on his shoulders] One more week.", "order": 23000, "speaker": "Creed Bratton"},
  {"season": 5, "episode": 7, "scene": 5, "line": "Pam comes back from New York next week and everyone here has just been so excited for me. And involved. And intrusive. And weird.", "order": 23001, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 7, "scene": 6, "line": "On more week. [chuckles]", "order": 23002, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 7, "scene": 6, "line": "Heh heh. [Jim gives camera an odd look]", "order": 23003, "speaker": "Stanley Hudson"},
  {"season": 5, "episode": 7, "scene": 7, "line": "[on speakerphone] Do you have your passport?", "order": 23004, "speaker": "David Wallace"},
  {"season": 5, "episode": 7, "scene": 7, "line": "I have my passport. [pats jacket pocket]", "order": 23005, "speaker": "Michael Scott"},
  {"season": 5, "episode": 7, "scene": 7, "line": "Got your per diem?", "order": 23006, "speaker": "David Wallace"},
  {"season": 5, "episode": 7, "scene": 7, "line": "I have my per diem. [holds up money] I already know what I am going to spend this on. I am going to buy a sweater.", "order": 23007, "speaker": "Michael Scott"},
  {"season": 5, "episode": 7, "scene": 7, "line": "Michael, the... that's for your food.", "order": 23008, "speaker": "David Wallace"},
  {"season": 5, "episode": 7, "scene": 7, "line": "Well I'll just... I'll use different money for that.", "order": 23009, "speaker": "Michael Scott"},
  {"season": 5, "episode": 7, "scene": 8, "line": "I was happy to send Michael on this trip. He's been feeling pretty down since we had to transfer Holly up to New Hampshire. But this little perk really seemed to turn him around. [chuckling] And it's pretty tough to find somebody who wants to go up to Winnipeg mid-November.", "order": 23010, "speaker": "David Wallace"},
  {"season": 5, "episode": 7, "scene": 9, "line": "And business class air, like a five-star hotel in the sky, nothing but the best. Actually better than a five-star hotel 'cause you get a big, cushy seat and you sit in a row of people and to eat, whatever the mind can imagine. I think I am going to have a filet with mushroom sauce.", "order": 23011, "speaker": "Michael Scott"},
  {"season": 5, "episode": 7, "scene": 9, "line": "Well, I'm just glad to know you're happy because you know I felt bad.", "order": 23012, "speaker": "David Wallace"},
  {"season": 5, "episode": 7, "scene": 9, "line": "Well that is all in the past.", "order": 23013, "speaker": "Michael Scott"},
  {"season": 5, "episode": 7, "scene": 9, "line": "And in terms of nightlife, when you get there just ask the concierge.", "order": 23014, "speaker": "David Wallace"},
  {"season": 5, "episode": 7, "scene": 9, "line": "They have one of those?", "order": 23015, "speaker": "Michael Scott"},
  {"season": 5, "episode": 7, "scene": 10, "line": "[in a singsong voice] Lets do this!", "order": 23016, "speaker": "Michael Scott"},
  {"season": 5, "episode": 7, "scene": 10, "line": "Wait, why do you need three suitcases?", "order": 23017, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 7, "scene": 10, "line": "Two are for souvenirs.", "order": 23018, "speaker": "Michael Scott"},
  {"season": 5, "episode": 7, "scene": 10, "line": "Do you have your money belt?", "order": 23019, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 7, "scene": 10, "line": "I do. It's right here. [indicates to waist]", "order": 23020, "speaker": "Michael Scott"},
  {"season": 5, "episode": 7, "scene": 10, "line": "No, no, no, no, no. You want to wear that puppy right up on the breastbone like a bra. [demonstrates]", "order": 23021, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 7, "scene": 10, "line": "No, I don't want to wear a bra.", "order": 23022, "speaker": "Michael Scott"},
  {"season": 5, "episode": 7, "scene": 10, "line": "Here, let me help you. [reaches for Michael's money belt]", "order": 23023, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 7, "scene": 10, "line": "Stop it! Stop it!", "order": 23024, "speaker": "Michael Scott"},
  {"season": 5, "episode": 7, "scene": 10, "line": "Do you want to get robbed in a foreign country? I wash my hands of this.", "order": 23025, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 7, "scene": 10, "line": "Okay. Where is my translator?", "order": 23026, "speaker": "Michael Scott"},
  {"season": 5, "episode": 7, "scene": 10, "line": "Monsieur.", "order": 23027, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 7, "scene": 10, "line": "There he is.", "order": 23028, "speaker": "Michael Scott"},
  {"season": 5, "episode": 7, "scene": 10, "line": "I'm just bidding a bon voyage a La Mon Petit fiancee. Translation: Goodbye my petite fiancee. [chuckles]", "order": 23029, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 7, "scene": 10, "line": "Be good.", "order": 23030, "speaker": "Angela Martin"},
  {"season": 5, "episode": 7, "scene": 10, "line": "I will try.", "order": 23031, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 7, "scene": 10, "line": "Meaning what?", "order": 23032, "speaker": "Angela Martin"},
  {"season": 5, "episode": 7, "scene": 10, "line": "Meaning I will try to get other dudes laid.", "order": 23033, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 7, "scene": 10, "line": "Yeah baby! That's what I'm talking about. That could be you [points at Jim] if you hadn't forgotten French. Where is my numbers man?", "order": 23034, "speaker": "Michael Scott"},
  {"season": 5, "episode": 7, "scene": 10, "line": "Here.", "order": 23035, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 7, "scene": 10, "line": "There we go. Our town car awaits.", "order": 23036, "speaker": "Michael Scott"},
  {"season": 5, "episode": 7, "scene": 10, "line": "It's just a van.", "order": 23037, "speaker": "Meredith Palmer"},
  {"season": 5, "episode": 7, "scene": 10, "line": "Its not just a van.", "order": 23038, "speaker": "Michael Scott"},
  {"season": 5, "episode": 7, "scene": 10, "line": "Look, I know my way around a van. That is just a van.", "order": 23039, "speaker": "Meredith Palmer"},
  {"season": 5, "episode": 7, "scene": 10, "line": "Dwight, can you get those please?", "order": 23040, "speaker": "Michael Scott"},
  {"season": 5, "episode": 7, "scene": 10, "line": "[sighs and picks up Michael's empty suitcases]", "order": 23041, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 7, "scene": 11, "line": "[on airplane] Welcome to Cribs-the business class edition. Check this out. Mimosa.", "order": 23042, "speaker": "Michael Scott"},
  {"season": 5, "episode": 7, "scene": 11, "line": "Ah... sweet!", "order": 23043, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 7, "scene": 11, "line": "Handed to me as I sat down. This was my hot towel. It is still wet.", "order": 23044, "speaker": "Michael Scott"},
  {"season": 5, "episode": 7, "scene": 11, "line": "Michael Gary Scott rolling like a pimp!", "order": 23045, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 7, "scene": 11, "line": "Take a sip of that. [hands Andy his mimosa]", "order": 23046, "speaker": "Michael Scott"},
  {"season": 5, "episode": 7, "scene": 11, "line": "Mmmhmmm...", "order": 23047, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 7, "scene": 11, "line": "That good?", "order": 23048, "speaker": "Michael Scott"},
  {"season": 5, "episode": 7, "scene": 11, "line": "Mmmm...", "order": 23049, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 7, "scene": 11, "line": "Don't drink all of it. Give some to Oscar.", "order": 23050, "speaker": "Michael Scott"},
  {"season": 5, "episode": 7, "scene": 11, "line": "That's really good. [offers mimosa to Oscar]", "order": 23051, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 7, "scene": 11, "line": "I'm good.", "order": 23052, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 7, "scene": 11, "line": "You want one of your own? I can hook you up.", "order": 23053, "speaker": "Michael Scott"},
  {"season": 5, "episode": 7, "scene": 11, "line": "I'm sorry. You'll need to keep moving.", "order": 23054, "speaker": "Stewardess"},
  {"season": 5, "episode": 7, "scene": 11, "line": "Yes, this is Beth. This is my personal valet/flight attendant and she will be helping me this morning.", "order": 23055, "speaker": "Michael Scott"},
  {"season": 5, "episode": 7, "scene": 11, "line": "We need to keep the isle clear.", "order": 23056, "speaker": "Beth"},
  {"season": 5, "episode": 7, "scene": 11, "line": "Yes...", "order": 23057, "speaker": "Michael Scott"},
  {"season": 5, "episode": 7, "scene": 11, "line": "Oh...", "order": 23058, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 7, "scene": 11, "line": "Get back, get back. Come on, get back to the slums.", "order": 23059, "speaker": "Michael Scott"},
  {"season": 5, "episode": 7, "scene": 11, "line": "Oh boy. [follows Oscar back to coach]", "order": 23060, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 7, "scene": 11, "line": "[whisper] Hey... guys, check it out. My own personal DVD player and 20 movies.", "order": 23061, "speaker": "Michael Scott"},
  {"season": 5, "episode": 7, "scene": 11, "line": "[whispers] Andy brought one too.", "order": 23062, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 7, "scene": 11, "line": "[loudly] Harry and the Henderson's", "order": 23063, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 7, "scene": 11, "line": "Shhhh! Keep it down.", "order": 23064, "speaker": "Michael Scott"},
  {"season": 5, "episode": 7, "scene": 11, "line": "I made egg salad sandwiches. Do you want one? [holds up sandwich]", "order": 23065, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 7, "scene": 11, "line": "Could you have picked something stinkier to bring on a plane? [Andy laughs] My God, Oscar. Really? Do you have a bag of baby poop in there too, to share with everybody? No, I will be ordering my own food, thank you very much.", "order": 23066, "speaker": "Michael Scott"},
  {"season": 5, "episode": 7, "scene": 11, "line": "[to stewardess] Hi.", "order": 23067, "speaker": "Michael Scott"},
  {"season": 5, "episode": 7, "scene": 11, "line": "Hi.", "order": 23068, "speaker": "Beth"},
  {"season": 5, "episode": 7, "scene": 11, "line": "Um. I'd like to see a menu please.", "order": 23069, "speaker": "Michael Scott"},
  {"season": 5, "episode": 7, "scene": 11, "line": "Oh, I'm sorry. There are no meals on flights less than two hours.", "order": 23070, "speaker": "Beth"},
  {"season": 5, "episode": 7, "scene": 11, "line": "Oh... okay. [looks back at Andy and Oscar] Doesn't matter, because I am going to take a nap. I think I am going to use my complimentary blindfold. I will don it... and oh! Look at that. I can't see because I am in a-- [cart crashes into Michael's leg] GAH!", "order": 23071, "speaker": "Michael Scott"},
  {"season": 5, "episode": 7, "scene": 11, "line": "What would you like to drink?", "order": 23072, "speaker": "Beth"},
  {"season": 5, "episode": 7, "scene": 12, "line": "[walks into Kelly's cubicle and sets a box down on the desk] Just checking out where I'm going to be pretty soon. When Pam gets back. Gonna be close quarters. Gonna be a lot of tension.", "order": 23073, "speaker": "Ryan Howard"},
  {"season": 5, "episode": 7, "scene": 12, "line": "For you. I'm with Darryl.", "order": 23074, "speaker": "Kelly Kapoor"},
  {"season": 5, "episode": 7, "scene": 12, "line": "This looks like where I'll probably do my pushups every day. [gets on floor and starts doing pushups]", "order": 23075, "speaker": "Ryan Howard"},
  {"season": 5, "episode": 7, "scene": 12, "line": "Is that supposed to impress me? [Ryan starts trying to do one-armed pushups]", "order": 23076, "speaker": "Kelly Kapoor"},
  {"season": 5, "episode": 7, "scene": 13, "line": "[talking head] No. Not going to happen. He has hurt me too much and too often. And I am in a healthy relationship so I'm not gonna flaunt it and I'm not gonna hurt him, but that door is closed.", "order": 23077, "speaker": "Kelly Kapoor"},
  {"season": 5, "episode": 7, "scene": 14, "line": "[making out with Ryan on her desk] What are we doing? This is so wrong.", "order": 23078, "speaker": "Kelly Kapoor"},
  {"season": 5, "episode": 7, "scene": 14, "line": "Yeah... Mmmmm...", "order": 23079, "speaker": "Ryan Howard"},
  {"season": 5, "episode": 7, "scene": 15, "line": "[going up escalator in the hotel] This is nice. This is nice. Move in here. Very sweet... ah.", "order": 23080, "speaker": "Michael Scott"},
  {"season": 5, "episode": 7, "scene": 15, "line": "I'll check us in.", "order": 23081, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 7, "scene": 15, "line": "Alright.", "order": 23082, "speaker": "Michael Scott"},
  {"season": 5, "episode": 7, "scene": 15, "line": "Very cool.", "order": 23083, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 7, "scene": 15, "line": "Very cool.", "order": 23084, "speaker": "Michael Scott"},
  {"season": 5, "episode": 7, "scene": 15, "line": "We are going to find out where the action is, my friend.", "order": 23085, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 7, "scene": 15, "line": "Okay.", "order": 23086, "speaker": "Michael Scott"},
  {"season": 5, "episode": 7, "scene": 15, "line": "Where's the concierge?", "order": 23087, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 7, "scene": 15, "line": "Yes! Wallace said there would be one of those.", "order": 23088, "speaker": "Michael Scott"},
  {"season": 5, "episode": 7, "scene": 15, "line": "Mmm... bingo! [indicates towards concierge desk] Follow moi, bro-sieur.", "order": 23089, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 7, "scene": 15, "line": "Wow!", "order": 23090, "speaker": "Michael Scott"},
  {"season": 5, "episode": 7, "scene": 15, "line": "[to concierge] What about a nice sushi place? Maybe a place with a view?", "order": 23091, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 7, "scene": 15, "line": "Tsk... Oh. Matsuki. That's a good one. Uh, you may walk there if you wish or you man take the number seventeen bus until 9:00. Other than that you can take the taxi and the number is right there.", "order": 23092, "speaker": "Concierge"},
  {"season": 5, "episode": 7, "scene": 15, "line": "Wow! Wow, I am blown away by this. I, um... I--ah! This is great, thank you!", "order": 23093, "speaker": "Michael Scott"},
  {"season": 5, "episode": 7, "scene": 15, "line": "One final question. Where might you find yourself on a Winnipeg night like tonight?", "order": 23094, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 7, "scene": 15, "line": "Oh, the Huntsman is good.", "order": 23095, "speaker": "Concierge"},
  {"season": 5, "episode": 7, "scene": 15, "line": "[nodding towards Michael] The Huntsman.", "order": 23096, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 7, "scene": 15, "line": "Down here... the financial district.", "order": 23097, "speaker": "Concierge"},
  {"season": 5, "episode": 7, "scene": 16, "line": "A concierge is like the Winnipeg equivalent of a geisha. This is a woman who has been trained in the fine art of fanciness and pleasure. And when you meet one, it is intoxicating. Just what the doctor ordered.", "order": 23098, "speaker": "Michael Scott"},
  {"season": 5, "episode": 7, "scene": 17, "line": "[at school in NYC] Can't believe this. [pulls out cell phone... sighs]", "order": 23099, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 7, "scene": 17, "line": "[in office] Are you sure?", "order": 23100, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 7, "scene": 17, "line": "[over the phone] I just talked to my advisor. Failing.", "order": 23101, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 7, "scene": 17, "line": "Wow. I thought you were good at Flash.", "order": 23102, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 7, "scene": 17, "line": "I was, and then they switched to Acrobat just as I was learning Quark. I hate computers.", "order": 23103, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 7, "scene": 17, "line": "Okay, okay, it's no big deal. So you're not a computer geek.", "order": 23104, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 7, "scene": 17, "line": "I have to stay and retake it.", "order": 23105, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 7, "scene": 17, "line": "[looks shocked] W---Wow. Um. Well... okay.", "order": 23106, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 7, "scene": 17, "line": "That means another twelve weeks. [pause] Can you do this for another three months?", "order": 23107, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 7, "scene": 17, "line": "It's not--- It's not about me. I mean, this your dream.", "order": 23108, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 7, "scene": 17, "line": "[over phone] I know.", "order": 23109, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 7, "scene": 17, "line": "And you went to New York to do this. So when you come back you come back the right way. Right?", "order": 23110, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 7, "scene": 17, "line": "[starting to cry] Right.", "order": 23111, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 7, "scene": 17, "line": "[concerned look on face] You okay?", "order": 23112, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 7, "scene": 17, "line": "[over phone] Yeah I'm fine. Um... my cell phone battery's low, so I have to let you go.", "order": 23113, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 7, "scene": 17, "line": "Okay.", "order": 23114, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 7, "scene": 17, "line": "Alright.", "order": 23115, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 7, "scene": 17, "line": "Alright.", "order": 23116, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 7, "scene": 17, "line": "[over phone] Love you. Bye.", "order": 23117, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 7, "scene": 17, "line": "Love you too. [hangs up phone, Pam sits on a bench crying]", "order": 23118, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 7, "scene": 18, "line": "Guys, she's in there.", "order": 23119, "speaker": "Michael Scott"},
  {"season": 5, "episode": 7, "scene": 18, "line": "Engaging wings [imitates mechanical sound]", "order": 23120, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 7, "scene": 18, "line": "I'm probably going to leave after one drink.", "order": 23121, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 7, "scene": 18, "line": "Let's do this.", "order": 23122, "speaker": "Michael Scott"},
  {"season": 5, "episode": 7, "scene": 18, "line": "Yeah with a hot slab of Canadian bacon in your hand.", "order": 23123, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 7, "scene": 18, "line": "[goes up to concierge from the hotel] Excuse me, hello. Concierge Marie. Michael Scott. Good to see you again.", "order": 23124, "speaker": "Michael Scott"},
  {"season": 5, "episode": 7, "scene": 18, "line": "Good to see you.", "order": 23125, "speaker": "Marie"},
  {"season": 5, "episode": 7, "scene": 18, "line": "Um, this is my associate uh, from Dunder Mifflin, uh, Oscar Martinez.", "order": 23126, "speaker": "Michael Scott"},
  {"season": 5, "episode": 7, "scene": 18, "line": "Nice to meet you. [shakes Marie's hand]", "order": 23127, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 7, "scene": 18, "line": "Oscar.", "order": 23128, "speaker": "Marie"},
  {"season": 5, "episode": 7, "scene": 18, "line": "Works in accounting. This is uh, concierge Marie...", "order": 23129, "speaker": "Michael Scott"},
  {"season": 5, "episode": 7, "scene": 18, "line": "Yes.", "order": 23130, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 7, "scene": 18, "line": "...who works at our hotel. You look, how do you say, radiant tonight.", "order": 23131, "speaker": "Michael Scott"},
  {"season": 5, "episode": 7, "scene": 18, "line": "Thank you.", "order": 23132, "speaker": "Marie"},
  {"season": 5, "episode": 7, "scene": 18, "line": "And it is, how do you say, a beautiful night---", "order": 23133, "speaker": "Michael Scott"},
  {"season": 5, "episode": 7, "scene": 18, "line": "Michael, why?", "order": 23134, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 7, "scene": 18, "line": "She's foreign. I am--", "order": 23135, "speaker": "Michael Scott"},
  {"season": 5, "episode": 7, "scene": 18, "line": "For Madame et monsieur. [hands Michael and Marie a glass of wine]", "order": 23136, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 7, "scene": 18, "line": "Oh, thank you.", "order": 23137, "speaker": "Michael Scott"},
  {"season": 5, "episode": 7, "scene": 18, "line": "You're welcome.", "order": 23138, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 7, "scene": 18, "line": "Thank you, Andy.", "order": 23139, "speaker": "Michael Scott"},
  {"season": 5, "episode": 7, "scene": 18, "line": "Thank you?", "order": 23140, "speaker": "Marie"},
  {"season": 5, "episode": 7, "scene": 18, "line": "Let me get a picture of you guys.", "order": 23141, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 7, "scene": 19, "line": "Everyone is going to end up dying someday. And I think it's better to die with people you like... like Oscar, and Andy, and concierge Marie... than to know that there's somebody out there that you love that you're not with.", "order": 23142, "speaker": "Michael Scott"},
  {"season": 5, "episode": 7, "scene": 20, "line": "Alright, I've scoped out the joint. Those two dudes are as good as naked.", "order": 23143, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 7, "scene": 20, "line": "How do you even know they're gay?", "order": 23144, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 7, "scene": 20, "line": "Come on! It's Dandy Dale and Foppy McGee over there. [turns to bartender] Mademoiselle! Beer me dos Long Island iced teas s'ill vous plait. [to Oscar] Bad decision in a glass.", "order": 23145, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 7, "scene": 21, "line": "I don't care if you're gay, straight, engaged... whatever. A guy needs intercourse.", "order": 23146, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 7, "scene": 22, "line": "You'll thank me when they spank thee.", "order": 23147, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 7, "scene": 22, "line": "Don't do this. [watches Andy take drinks to the other table]", "order": 23148, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 7, "scene": 22, "line": "Do you guys like apples?", "order": 23149, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 7, "scene": 22, "line": "What?", "order": 23150, "speaker": "Guy at table"},
  {"season": 5, "episode": 7, "scene": 22, "line": "Do you like apples?", "order": 23151, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 7, "scene": 22, "line": "Uh, sorry... what?", "order": 23152, "speaker": "Guy at table"},
  {"season": 5, "episode": 7, "scene": 22, "line": "Well, how do you like these apples? [sets drinks on table] Alright, on a scale of 1 - 10, how hot is that dude? [indicates towards Oscar who waves slightly]", "order": 23153, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 7, "scene": 22, "line": "Is he your boyfriend or something?", "order": 23154, "speaker": "Guy at table"},
  {"season": 5, "episode": 7, "scene": 22, "line": "No, but he could be yours if you play your cards right.", "order": 23155, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 7, "scene": 22, "line": "Dude, leave us alone alright?", "order": 23156, "speaker": "Guy"},
  {"season": 5, "episode": 7, "scene": 22, "line": "Gentleman. [walks away]", "order": 23157, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 7, "scene": 22, "line": "What are you doing?", "order": 23158, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 7, "scene": 22, "line": "Dude, you struck out. They're totally stuck up. Here... drink up.", "order": 23159, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 7, "scene": 23, "line": "How about this one? It's Christmas eve---", "order": 23160, "speaker": "Michael Scott"},
  {"season": 5, "episode": 7, "scene": 23, "line": "Mmm-hmmm...", "order": 23161, "speaker": "Concierge Marie"},
  {"season": 5, "episode": 7, "scene": 23, "line": "---and everything's closed and you need to get some dry cleaning done. 12:00 midnight. Where do you go? What do you do? What do you do? Come on! What do you do?", "order": 23162, "speaker": "Michael Scott"},
  {"season": 5, "episode": 7, "scene": 23, "line": "Uh...A stro cleaners on St. Johns place is the only place open on that day.", "order": 23163, "speaker": "Marie"},
  {"season": 5, "episode": 7, "scene": 23, "line": "Unbelievable! Unbelievable.", "order": 23164, "speaker": "Michael Scott"},
  {"season": 5, "episode": 7, "scene": 24, "line": "If you don't mind me asking---", "order": 23165, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 7, "scene": 24, "line": "Anything. You can ask me anything.", "order": 23166, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 7, "scene": 24, "line": "Okay.", "order": 23167, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 7, "scene": 24, "line": "I'm your wingman.", "order": 23168, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 7, "scene": 24, "line": "Its just that I've sat next to Angela for a very long, very long time.", "order": 23169, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 7, "scene": 24, "line": "Right-o.", "order": 23170, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 7, "scene": 24, "line": "How could anyone stand that woman?", "order": 23171, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 7, "scene": 24, "line": "What?", "order": 23172, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 7, "scene": 24, "line": "What do you see in her? Wh -- what do you see in Angela?", "order": 23173, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 7, "scene": 24, "line": "What do I see in Angela?", "order": 23174, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 7, "scene": 24, "line": "I want to know.", "order": 23175, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 7, "scene": 24, "line": "I see through a hard exterior to a little jelly in the middle. She is teaching me to be a better person. And she's working really hard on that. And she has the softest skin I've ever seen and I can't wait to have sex with her.", "order": 23176, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 7, "scene": 24, "line": "You haven't had sex?", "order": 23177, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 7, "scene": 24, "line": "No.", "order": 23178, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 7, "scene": 24, "line": "Y--Are you guys waiting to get married, or?", "order": 23179, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 7, "scene": 24, "line": "Honestly, I don't know what we're waiting for.", "order": 23180, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 7, "scene": 24, "line": "Andy, something is wrong with that woman.", "order": 23181, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 7, "scene": 24, "line": "What is wrong with her?", "order": 23182, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 7, "scene": 24, "line": "I'd like to know. You should call her and ask her. I'd like to know what's wrong with her.", "order": 23183, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 7, "scene": 24, "line": "I should call her and ask her. What is wrong with her?", "order": 23184, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 7, "scene": 24, "line": "Do it! It's a--- Call her! [Andy holds up phone] Oh my God, don't call her! Don't call her, Andy. [giggling] Andy, don't call her!", "order": 23185, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 7, "scene": 24, "line": "Too late, too late. It's dialing... now it's ringing. [Oscar laughs] Shh...", "order": 23186, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 7, "scene": 24, "line": "[on phone] Hello? Hello?", "order": 23187, "speaker": "Angela Martin"},
  {"season": 5, "episode": 7, "scene": 24, "line": "What is wrong with you?", "order": 23188, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 7, "scene": 24, "line": "Why won't you do Andy?", "order": 23189, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 7, "scene": 24, "line": "What?", "order": 23190, "speaker": "Angela Martin"},
  {"season": 5, "episode": 7, "scene": 24, "line": "That was Oscar and he wants to know why you won't do me and I think it's a valid question.", "order": 23191, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 7, "scene": 24, "line": "Are you drunk?", "order": 23192, "speaker": "Angela Martin"},
  {"season": 5, "episode": 7, "scene": 24, "line": "This is Andy Bernard!", "order": 23193, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 7, "scene": 24, "line": "I know who this is!", "order": 23194, "speaker": "Angela Martin"},
  {"season": 5, "episode": 7, "scene": 24, "line": "I wanna take you to sex school.", "order": 23195, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 7, "scene": 24, "line": "What?", "order": 23196, "speaker": "Angela Martin"},
  {"season": 5, "episode": 7, "scene": 24, "line": "[over phone] Who is that monkey?", "order": 23197, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 7, "scene": 24, "line": "Is somebody there?", "order": 23198, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 7, "scene": 24, "line": "Are you drunk?", "order": 23199, "speaker": "Angela Martin"},
  {"season": 5, "episode": 7, "scene": 24, "line": "I have needs.", "order": 23200, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 7, "scene": 24, "line": "We will discuss this later.", "order": 23201, "speaker": "Angela Martin"},
  {"season": 5, "episode": 7, "scene": 24, "line": "Naked.", "order": 23202, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 7, "scene": 24, "line": "What?", "order": 23203, "speaker": "Angela Martin"},
  {"season": 5, "episode": 7, "scene": 24, "line": "We'll discuss it later naked. I want to see you naked.", "order": 23204, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 7, "scene": 25, "line": "[standing outside hotel room... whispering] Do you want to get some breakfast or something?", "order": 23205, "speaker": "Michael Scott"},
  {"season": 5, "episode": 7, "scene": 25, "line": "I am so tired.", "order": 23206, "speaker": "Concierge Marie"},
  {"season": 5, "episode": 7, "scene": 25, "line": "Okay.", "order": 23207, "speaker": "Michael Scott"},
  {"season": 5, "episode": 7, "scene": 26, "line": "[noticing that everyone is giving him a sympathetic look] How does everyone know already?", "order": 23208, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 7, "scene": 26, "line": "Know what?", "order": 23209, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 7, "scene": 26, "line": "Pam failed art school.", "order": 23210, "speaker": "Meredith Palmer"},
  {"season": 5, "episode": 7, "scene": 26, "line": "Oh, well, doesn't surprise me.", "order": 23211, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 7, "scene": 26, "line": "Excuse me?", "order": 23212, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 7, "scene": 26, "line": "Have you seen her painting, Jim? The building? There are shadows coming from two different directions.", "order": 23213, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 7, "scene": 26, "line": "Dwight, stop it.", "order": 23214, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 7, "scene": 26, "line": "Dwight.", "order": 23215, "speaker": "Stanley Hudson"},
  {"season": 5, "episode": 7, "scene": 26, "line": "What? Are there two suns?", "order": 23216, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 7, "scene": 26, "line": "Come on.", "order": 23217, "speaker": "Meredith Palmer"},
  {"season": 5, "episode": 7, "scene": 26, "line": "Oh my God.", "order": 23218, "speaker": "Stanley Hudson"},
  {"season": 5, "episode": 7, "scene": 26, "line": "Last I checked, that's not an office building in the Andromeda galaxy.", "order": 23219, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 7, "scene": 26, "line": "I'm gonna grab a cup of coffee.", "order": 23220, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 7, "scene": 26, "line": "It's totally unrealistic. There are no lines in the parking lot.", "order": 23221, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 7, "scene": 27, "line": "Oscar", "order": 23222, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 7, "scene": 27, "line": "Hey.", "order": 23223, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 7, "scene": 27, "line": "That was fun last night.", "order": 23224, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 7, "scene": 27, "line": "Yeah, it was.", "order": 23225, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 7, "scene": 27, "line": "You know, it's true what they say--- Long Island iced teas are way stronger in Canada.", "order": 23226, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 7, "scene": 27, "line": "Hey, um... thanks for trying to hook me up.", "order": 23227, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 7, "scene": 27, "line": "You kidding me? It's what I do. Get the whole nine 'nards. [Oscar laughs]", "order": 23228, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 7, "scene": 27, "line": "I can't believe we called her up. [both laugh]", "order": 23229, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 7, "scene": 27, "line": "Totally. [continues laughing] What--- Who?", "order": 23230, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 7, "scene": 27, "line": "I'm talking about Angela. I can't believe we called her up last night.", "order": 23231, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 7, "scene": 27, "line": "We called Angela?", "order": 23232, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 7, "scene": 27, "line": "You--- you call--- you called her.", "order": 23233, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 7, "scene": 27, "line": "That was real?! I thought I dreamed that. Oh, God!", "order": 23234, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 7, "scene": 27, "line": "Alright.", "order": 23235, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 7, "scene": 27, "line": "Oh, God!", "order": 23236, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 7, "scene": 27, "line": "Okay.", "order": 23237, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 7, "scene": 27, "line": "So bad!", "order": 23238, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 7, "scene": 27, "line": "Good morning, Michael. [Michael approaches table] Are you ready for the meeting?", "order": 23239, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 7, "scene": 27, "line": "Slept like a baby.", "order": 23240, "speaker": "Michael Scott"},
  {"season": 5, "episode": 7, "scene": 28, "line": "Mmmm.... mmm. This can't happen again.", "order": 23241, "speaker": "Kelly Kapoor"},
  {"season": 5, "episode": 7, "scene": 28, "line": "This has to happen again. Darryl can't happen again. Look at me. Do you want me to do more push-ups?", "order": 23242, "speaker": "Ryan Howard"},
  {"season": 5, "episode": 7, "scene": 28, "line": "Yeah.", "order": 23243, "speaker": "Kelly Kapoor"},
  {"season": 5, "episode": 7, "scene": 28, "line": "Okay. You have to break up with Darryl. I already typed out a text message for you. All you have to do is press 'send'.", "order": 23244, "speaker": "Ryan Howard"},
  {"season": 5, "episode": 7, "scene": 28, "line": "I don't know. I mean, it's well-written and all, I just---", "order": 23245, "speaker": "Kelly Kapoor"},
  {"season": 5, "episode": 7, "scene": 28, "line": "Has to be done. We'll press send together. [phone beeps]", "order": 23246, "speaker": "Ryan Howard"},
  {"season": 5, "episode": 7, "scene": 28, "line": "Oh my God. He's going to kill us.", "order": 23247, "speaker": "Kelly Kapoor"},
  {"season": 5, "episode": 7, "scene": 28, "line": "I'd like to see him try. [kisses Kelly... cell phone beeps]", "order": 23248, "speaker": "Ryan Howard"},
  {"season": 5, "episode": 7, "scene": 28, "line": "Oh! He says it's cool. [laughs] He said, 'It's cool'.", "order": 23249, "speaker": "Kelly Kapoor"},
  {"season": 5, "episode": 7, "scene": 28, "line": "That's all he wrote?", "order": 23250, "speaker": "Ryan Howard"},
  {"season": 5, "episode": 7, "scene": 28, "line": "That's all he wrote.", "order": 23251, "speaker": "Kelly Kapoor"},
  {"season": 5, "episode": 7, "scene": 28, "line": "Can I see it?", "order": 23252, "speaker": "Ryan Howard"},
  {"season": 5, "episode": 7, "scene": 28, "line": "Mmm hmm.", "order": 23253, "speaker": "Kelly Kapoor"},
  {"season": 5, "episode": 7, "scene": 28, "line": "Didn't you two date for like a long time?", "order": 23254, "speaker": "Ryan Howard"},
  {"season": 5, "episode": 7, "scene": 28, "line": "Mmm hmm. It's like a fairy tale! [grabs Ryan and kisses him] Mmmm...", "order": 23255, "speaker": "Kelly Kapoor"},
  {"season": 5, "episode": 7, "scene": 29, "line": "I'll be honest with you--- we've been talking with Catalyst Paper. Their prices are better than yours.", "order": 23256, "speaker": "Client"},
  {"season": 5, "episode": 7, "scene": 29, "line": "Look, people continue to come back to us time and time again because they feel cared for here. They feel respected and they feel that their needs matter. They are treated like human beings. [sighs]", "order": 23257, "speaker": "Michael Scott"},
  {"season": 5, "episode": 7, "scene": 29, "line": "Everything okay?", "order": 23258, "speaker": "Client"},
  {"season": 5, "episode": 7, "scene": 29, "line": "Yes.", "order": 23259, "speaker": "Michael Scott"},
  {"season": 5, "episode": 7, "scene": 30, "line": "Oh man, she is so pissed. [sitting down by Oscar]", "order": 23260, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 7, "scene": 30, "line": "Mmm...", "order": 23261, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 7, "scene": 30, "line": "She's taking us back to first base.", "order": 23262, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 7, "scene": 30, "line": "What is first base with Angela?", "order": 23263, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 7, "scene": 30, "line": "I get to kiss her forehead. [looks at Oscar] I had a good time hanging out this weekend.", "order": 23264, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 7, "scene": 30, "line": "I had a good time too.", "order": 23265, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 7, "scene": 30, "line": "Wingman for life. W.M.F.L", "order": 23266, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 7, "scene": 30, "line": "Thank you.", "order": 23267, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 7, "scene": 30, "line": "You up for a chest bump?", "order": 23268, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 7, "scene": 30, "line": "No.", "order": 23269, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 7, "scene": 30, "line": "Bro hug? [Oscar shakes head... then sticks his hand out and Andy shakes it] Back to basics. I like it.", "order": 23270, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 7, "scene": 31, "line": "I had to go all the way to Canada to get to know a guy who sits 20 feet away from me. And he's delightful! [laughs]", "order": 23271, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 7, "scene": 32, "line": "[on phone] Hey! I just heard you guys made the sale.", "order": 23272, "speaker": "David Wallace"},
  {"season": 5, "episode": 7, "scene": 32, "line": "Yeah, we locked him up for two years.", "order": 23273, "speaker": "Michael Scott"},
  {"season": 5, "episode": 7, "scene": 32, "line": "Good, very good. See, told you. Sounds like somebody had a good trip.", "order": 23274, "speaker": "David Wallace"},
  {"season": 5, "episode": 7, "scene": 32, "line": "No. Actually the trip sucked.", "order": 23275, "speaker": "Michael Scott"},
  {"season": 5, "episode": 7, "scene": 32, "line": "Excuse me?", "order": 23276, "speaker": "David Wallace"},
  {"season": 5, "episode": 7, "scene": 32, "line": "The trip sucked, David. It blew chunks. It was terrible. It was a bad trip.", "order": 23277, "speaker": "Michael Scott"},
  {"season": 5, "episode": 7, "scene": 32, "line": "What are you talking about?", "order": 23278, "speaker": "David Wallace"},
  {"season": 5, "episode": 7, "scene": 32, "line": "I did not like the trip. Well, starting with her airport shuttle which was basically just a van.", "order": 23279, "speaker": "Michael Scott"},
  {"season": 5, "episode": 7, "scene": 32, "line": "Okay.", "order": 23280, "speaker": "David Wallace"},
  {"season": 5, "episode": 7, "scene": 32, "line": "And business class which was basically just coach. And the hotel which sucked big time.", "order": 23281, "speaker": "Michael Scott"},
  {"season": 5, "episode": 7, "scene": 32, "line": "The hotel? What, M--- okay.", "order": 23282, "speaker": "David Wallace"},
  {"season": 5, "episode": 7, "scene": 32, "line": "It was not, it sucked.", "order": 23283, "speaker": "Michael Scott"},
  {"season": 5, "episode": 7, "scene": 32, "line": "I'm sorry to hear the hotel was bad.", "order": 23284, "speaker": "David Wallace"},
  {"season": 5, "episode": 7, "scene": 32, "line": "Oh and thanks for the tip on the concerige. That was great. That was great. That was--- that was a---", "order": 23285, "speaker": "Michael Scott"},
  {"season": 5, "episode": 7, "scene": 32, "line": "Okay, okay. Hey, hey, hey.", "order": 23286, "speaker": "David Wallace"},
  {"season": 5, "episode": 7, "scene": 32, "line": "That was a really good choice.", "order": 23287, "speaker": "Michael Scott"},
  {"season": 5, "episode": 7, "scene": 32, "line": "Michael, please lets listen for a second.", "order": 23288, "speaker": "David Wallace"},
  {"season": 5, "episode": 7, "scene": 32, "line": "No, David. You listen to me. Why did you send her away? That--- God. You knew I liked her and you just sent her away. And that--- that was a sucky thing to do man.", "order": 23289, "speaker": "Michael Scott"},
  {"season": 5, "episode": 7, "scene": 32, "line": "Michael, sometimes---", "order": 23290, "speaker": "David Wallace"},
  {"season": 5, "episode": 7, "scene": 32, "line": "That was a really sucky thing to do.", "order": 23291, "speaker": "Michael Scott"},
  {"season": 5, "episode": 7, "scene": 32, "line": "Sometimes, we-- [Michael hangs up phone]", "order": 23292, "speaker": "David Wallace"},
  {"season": 5, "episode": 7, "scene": 33, "line": "Why have I stayed at Dunder Mifflin for so long? Certainly not because of the paycheck. 'Cause I could be making more money as a doctor or a professional athlete. I think it's because they respect me. A boss that will not fire you, even though you just tell him off... right to his face... over the hone. That's respect.", "order": 23293, "speaker": "Michael Scott"},
  {"season": 5, "episode": 7, "scene": 34, "line": "You did it, man. [fist bumps Jim] Day one. Congrats.", "order": 23294, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 7, "scene": 34, "line": "Thanks man.", "order": 23295, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 7, "scene": 35, "line": "I'm coming back the wrong way. [smiles at Jim across the parking lot] It's not because of you. I don't like graphic design. That's it. [laughs] Stop smiling. I really didn't like it. It's just designing logos and stuff.... and I miss Scranton. But it is not because I missed you. I just really wanted to come home... and I know you said to come home the right way, but you can't tell me what to do. Got it?", "order": 23296, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 7, "scene": 35, "line": "I missed you.", "order": 23297, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 7, "scene": 35, "line": "I missed you too. [Jim kisses her]", "order": 23298, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 7, "scene": 35, "line": "You're back.", "order": 23299, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 7, "scene": 35, "line": "Uh, yeah.", "order": 23300, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 7, "scene": 35, "line": "Good. I need you to make five copies of these. [hands Pam papers]", "order": 23301, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 7, "scene": 35, "line": "I'm not going inside.", "order": 23302, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 7, "scene": 35, "line": "Alright. First thing in the morning then.", "order": 23303, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 7, "scene": 35, "line": "Welcome back.", "order": 23304, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 7, "scene": 36, "line": "We're back together again baby.", "order": 23305, "speaker": "Kelly Kapoor"},
  {"season": 5, "episode": 7, "scene": 36, "line": "We're back.", "order": 23306, "speaker": "Ryan Howard"},
  {"season": 5, "episode": 7, "scene": 36, "line": "They tried to keep us apart, but they couldnt. It was like destiny.", "order": 23307, "speaker": "Kelly Kapoor"},
  {"season": 5, "episode": 7, "scene": 36, "line": "I--- I realized that for whatever reason I... just couldn't do better than kelly.", "order": 23308, "speaker": "Ryan Howard"},
  {"season": 5, "episode": 7, "scene": 36, "line": "Oh!", "order": 23309, "speaker": "Kelly Kapoor"},
  {"season": 5, "episode": 7, "scene": 36, "line": "[whispers] Yeah.", "order": 23310, "speaker": "Ryan Howard"},
  {"season": 5, "episode": 8, "scene": 1, "line": "Are you swallowing them whole? You're eating them so fast, are they even touching your tongue?", "order": 23311, "speaker": "Angela Martin"},
  {"season": 5, "episode": 8, "scene": 1, "line": "[with mouth full] Yes.", "order": 23312, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 8, "scene": 1, "line": "Brownies is it? Hm. Pastry cubes made of sugar and fat? No thank you, I'll stick with my jerkie.", "order": 23313, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 8, "scene": 1, "line": "So why did you come in here?", "order": 23314, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 8, "scene": 1, "line": "To socialize. And inform.", "order": 23315, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 8, "scene": 1, "line": "Oh brownies! I'm taking two so I can parcel them up and eat them at my leisure later on, much healthier.", "order": 23316, "speaker": "Michael Scott"},
  {"season": 5, "episode": 8, "scene": 1, "line": "You're taking two?", "order": 23317, "speaker": "Ryan Howard"},
  {"season": 5, "episode": 8, "scene": 1, "line": "Yeah, um, but one of them is for Toby.", "order": 23318, "speaker": "Kelly Kapoor"},
  {"season": 5, "episode": 8, "scene": 1, "line": "Yeah why don't you send that to him in Costa Rica?", "order": 23319, "speaker": "Michael Scott"},
  {"season": 5, "episode": 8, "scene": 1, "line": "Um, I'm just gonna hand it to him right now.", "order": 23320, "speaker": "Kelly Kapoor"},
  {"season": 5, "episode": 8, "scene": 1, "line": "Heh, okay, weirdo.", "order": 23321, "speaker": "Michael Scott"},
  {"season": 5, "episode": 8, "scene": 1, "line": "Why is that, why is that weird?", "order": 23322, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 8, "scene": 1, "line": "She said she was going to give it to him right now. [starts laughing]", "order": 23323, "speaker": "Michael Scott"},
  {"season": 5, "episode": 8, "scene": 1, "line": "[laughing] She's probably going to, cause they sit next to each other.", "order": 23324, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 8, "scene": 1, "line": "Yeah, they used to.", "order": 23325, "speaker": "Michael Scott"},
  {"season": 5, "episode": 8, "scene": 1, "line": "Toby works here again.", "order": 23326, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 8, "scene": 1, "line": "Oh, can you imagine?", "order": 23327, "speaker": "Michael Scott"},
  {"season": 5, "episode": 8, "scene": 1, "line": "Oh no.", "order": 23328, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 8, "scene": 1, "line": "Uhhgh.", "order": 23329, "speaker": "Michael Scott"},
  {"season": 5, "episode": 8, "scene": 1, "line": "You don't know.", "order": 23330, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 8, "scene": 1, "line": "I don't know. What?", "order": 23331, "speaker": "Michael Scott"},
  {"season": 5, "episode": 8, "scene": 1, "line": "You should probably just meander back there; take a look. See if he's, see if he's back.", "order": 23332, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 8, "scene": 1, "line": "Hmmm, dare I? [laughs] You know what? I'm going to, for old time's sake. [walks to the annex, standing in Toby's cubicle] Great practical joke Jim, you got me to go to the annex. [turns around and sees Toby]", "order": 23333, "speaker": "Michael Scott"},
  {"season": 5, "episode": 8, "scene": 1, "line": "Hi, Micha-", "order": 23334, "speaker": "Toby Flenderson"},
  {"season": 5, "episode": 8, "scene": 1, "line": "NOOOO! GOD! No, God, please no! No! No! NOOO!", "order": 23335, "speaker": "Michael Scott"},
  {"season": 5, "episode": 8, "scene": 2, "line": "[watching Toby at Phyllis' desk through his office blinds] Look at him. With his stupid face. Stupid... tan. No.", "order": 23336, "speaker": "Michael Scott"},
  {"season": 5, "episode": 8, "scene": 2, "line": "He looks great.", "order": 23337, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 8, "scene": 2, "line": "No.", "order": 23338, "speaker": "Michael Scott"},
  {"season": 5, "episode": 8, "scene": 2, "line": "Well rested.", "order": 23339, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 8, "scene": 2, "line": "He looks worse.", "order": 23340, "speaker": "Michael Scott"},
  {"season": 5, "episode": 8, "scene": 3, "line": "Michael, is everyone okay?", "order": 23341, "speaker": "David Wallace"},
  {"season": 5, "episode": 8, "scene": 3, "line": "Uh, well I'm afraid not. Toby Flenderson, of H.R., has made a sudden reappearance.", "order": 23342, "speaker": "Michael Scott"},
  {"season": 5, "episode": 8, "scene": 3, "line": "I don't understand, is anyone hurt?", "order": 23343, "speaker": "David Wallace"},
  {"season": 5, "episode": 8, "scene": 3, "line": "Not on the surface, no, but I can tell people are disturbed, David.", "order": 23344, "speaker": "Michael Scott"},
  {"season": 5, "episode": 8, "scene": 3, "line": "Michael, you texted me, 911 CALL ME.", "order": 23345, "speaker": "David Wallace"},
  {"season": 5, "episode": 8, "scene": 3, "line": "Yes.", "order": 23346, "speaker": "Michael Scott"},
  {"season": 5, "episode": 8, "scene": 3, "line": "All in caps. Do you know what 911 means?", "order": 23347, "speaker": "David Wallace"},
  {"season": 5, "episode": 8, "scene": 4, "line": "I learned a while back that if I don't text 911 people will not return my calls. Um, but now people always return my calls because they think that something horrible has happened.", "order": 23348, "speaker": "Michael Scott"},
  {"season": 5, "episode": 8, "scene": 5, "line": "Now what I'm curious about is how you were able to go an entire a week without knowing a member of your staff was there.", "order": 23349, "speaker": "David Wallace"},
  {"season": 5, "episode": 8, "scene": 5, "line": "I did not want to go back to the annex because that is where Holly worked whom I loved.", "order": 23350, "speaker": "Michael Scott"},
  {"season": 5, "episode": 8, "scene": 5, "line": "Also, it's icky back there.", "order": 23351, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 8, "scene": 5, "line": "That's true. People say it's icky.", "order": 23352, "speaker": "Michael Scott"},
  {"season": 5, "episode": 8, "scene": 5, "line": "Okay, I have to go.", "order": 23353, "speaker": "David Wallace"},
  {"season": 5, "episode": 8, "scene": 5, "line": "David, wait.", "order": 23354, "speaker": "Michael Scott"},
  {"season": 5, "episode": 8, "scene": 5, "line": "No.", "order": 23355, "speaker": "David Wallace"},
  {"season": 5, "episode": 8, "scene": 5, "line": "Is there no way we can get rid of him?", "order": 23356, "speaker": "Michael Scott"},
  {"season": 5, "episode": 8, "scene": 5, "line": "Not without cause, Michael.", "order": 23357, "speaker": "David Wallace"},
  {"season": 5, "episode": 8, "scene": 5, "line": "I have cause. It is be-cause I hate him.", "order": 23358, "speaker": "Michael Scott"},
  {"season": 5, "episode": 8, "scene": 5, "line": "You have to get along with Toby.", "order": 23359, "speaker": "David Wallace"},
  {"season": 5, "episode": 8, "scene": 5, "line": "No.", "order": 23360, "speaker": "Michael Scott"},
  {"season": 5, "episode": 8, "scene": 5, "line": "Yep.", "order": 23361, "speaker": "David Wallace"},
  {"season": 5, "episode": 8, "scene": 5, "line": "I don't.", "order": 23362, "speaker": "Michael Scott"},
  {"season": 5, "episode": 8, "scene": 5, "line": "Goodbye, Michael. [hangs up]", "order": 23363, "speaker": "David Wallace"},
  {"season": 5, "episode": 8, "scene": 5, "line": "[Dwight comes over, starts to massage Michael's shoulders] Don't do that.", "order": 23364, "speaker": "Michael Scott"},
  {"season": 5, "episode": 8, "scene": 6, "line": "[pours something from the fridge into a bowl and opens the microwave, it is covered in exploded food] Oh, come on! [to the documentary crew] Do you see this? Disgusting.", "order": 23365, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 8, "scene": 7, "line": "So, Tunes, you still gonna buy your old man's place?", "order": 23366, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 8, "scene": 7, "line": "Yeah, I am. Wait, how do you know that? I didn't tell you that.", "order": 23367, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 8, "scene": 7, "line": "Ehhh no, I was just walking by your desk. I saw some email. I got peepers of an eagle.", "order": 23368, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 8, "scene": 7, "line": "That's really not cool.", "order": 23369, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 8, "scene": 7, "line": "Kaaw!", "order": 23370, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 8, "scene": 7, "line": "So Jim, you're gonna live in the same house that you used to pee the bed in?", "order": 23371, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 8, "scene": 7, "line": "Yeah, I guess technically Kev, you're right.", "order": 23372, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 8, "scene": 8, "line": "Today's a big day. Today's the day that I show Pam the house that I bought for us. Without telling her. But it's my parent's house, the house I grew up in and yeah, I bought it kind of impulsively. I mean, the price was good and I was helping out my mom. It's got shag carpets. I mean you can't blame my parents it was the 70's. And why would you want to buy ugly wood from trees when you can have paneling? And a painting of some creepy clowns that is apparently crucial to the structural integrity of the building. [tugs and pulls in vain at the clown painting] She's gonna love it. Right?", "order": 23373, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 8, "scene": 9, "line": "Could you guys all do me a favor and not talk about this until I tell Pam?", "order": 23374, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 8, "scene": 9, "line": "Whoa, you haven't told the misses about the castle? You're in for a spanking my friend. Myself and my lady? - no secrets.", "order": 23375, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 8, "scene": 9, "line": "Jim, don't listen to Andy. I think it's so romantic.", "order": 23376, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 8, "scene": 9, "line": "Oh thanks, Phyllis.", "order": 23377, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 8, "scene": 9, "line": "Where's your place?", "order": 23378, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 8, "scene": 9, "line": "Oh, it's on uh Linden Ave? By the quarry?", "order": 23379, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 8, "scene": 9, "line": "Ohhh...", "order": 23380, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 8, "scene": 9, "line": "Cool beans, man, I live by the quarry. We should hang out by the quarry and throw things down there.", "order": 23381, "speaker": "Creed Bratton"},
  {"season": 5, "episode": 8, "scene": 9, "line": "Definitely we should.", "order": 23382, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 8, "scene": 10, "line": "[reading from a note on the microwave] 'To whoever made the microwave mess: the microwave is a shared kitchen appliance. By not cleaning it up you are basically telling whoever follows that their time is less valuable, as they will have to scrub out your disgusting splatter. Sincerely, disappointed.'", "order": 23383, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 8, "scene": 10, "line": "That is just obnoxious.", "order": 23384, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 8, "scene": 10, "line": "No kidding.", "order": 23385, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 8, "scene": 10, "line": "Yeah. Wait, what, the mess or the note?", "order": 23386, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 8, "scene": 10, "line": "The note. So 'holier than thou'.", "order": 23387, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 8, "scene": 10, "line": "Hmm, I liked it.", "order": 23388, "speaker": "Angela Martin"},
  {"season": 5, "episode": 8, "scene": 10, "line": "Don't you think the person who left the mess is the obnoxious one?", "order": 23389, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 8, "scene": 10, "line": "No, the note is way more obnoxious than the mess.", "order": 23390, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 8, "scene": 10, "line": "Sincerely, disappointed? Get off your high horse, Richie.", "order": 23391, "speaker": "Meredith Palmer"},
  {"season": 5, "episode": 8, "scene": 10, "line": "Just because someone likes things clean, doesn't mean they're rich.", "order": 23392, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 8, "scene": 10, "line": "Yeh, they're rich.", "order": 23393, "speaker": "Meredith Palmer"},
  {"season": 5, "episode": 8, "scene": 11, "line": "You want to see some really high caliber acting? Well, Mr. Kurt Russell, you are about to be served. [walks to the annex] Hey Toby, great to have you back, man. Seriously, just a, just a real pleasure to see you again.", "order": 23394, "speaker": "Michael Scott"},
  {"season": 5, "episode": 8, "scene": 11, "line": "Well thanks Michael.", "order": 23395, "speaker": "Toby Flenderson"},
  {"season": 5, "episode": 8, "scene": 11, "line": "You're welcome. Missed you. Missed you as part of our family.", "order": 23396, "speaker": "Michael Scott"},
  {"season": 5, "episode": 8, "scene": 11, "line": "Well that's sweet, I missed you guys too.", "order": 23397, "speaker": "Toby Flenderson"},
  {"season": 5, "episode": 8, "scene": 11, "line": "So Costa Rica that was - did you have fun? That must have been fun.", "order": 23398, "speaker": "Michael Scott"},
  {"season": 5, "episode": 8, "scene": 11, "line": "Well, um, it was amazing. It really was, thanks for asking. Um the beaches were pristine...", "order": 23399, "speaker": "Toby Flenderson"},
  {"season": 5, "episode": 8, "scene": 11, "line": "Nice beaches, pristine beaches?", "order": 23400, "speaker": "Michael Scott"},
  {"season": 5, "episode": 8, "scene": 11, "line": "...and, yeah, the whole thing was incredibly cathartic.", "order": 23401, "speaker": "Toby Flenderson"},
  {"season": 5, "episode": 8, "scene": 11, "line": "Why'd you come back? Why didn't you stay?", "order": 23402, "speaker": "Michael Scott"},
  {"season": 5, "episode": 8, "scene": 11, "line": "It was actually kind of hard to meet people I found.", "order": 23403, "speaker": "Toby Flenderson"},
  {"season": 5, "episode": 8, "scene": 11, "line": "I bet, for you.", "order": 23404, "speaker": "Michael Scott"},
  {"season": 5, "episode": 8, "scene": 11, "line": "And uh, yeah, plus it was hot.", "order": 23405, "speaker": "Toby Flenderson"},
  {"season": 5, "episode": 8, "scene": 11, "line": "[shifting uncomfortably and sweating] Shhh hot, why didn't you get an air-condition--- should have gotten an air-conditioner for yourself.", "order": 23406, "speaker": "Michael Scott"},
  {"season": 5, "episode": 8, "scene": 11, "line": "Are you all right, Michael?", "order": 23407, "speaker": "Toby Flenderson"},
  {"season": 5, "episode": 8, "scene": 11, "line": "Yeah, I am. I am.", "order": 23408, "speaker": "Michael Scott"},
  {"season": 5, "episode": 8, "scene": 12, "line": "I tried, I tried. I tried to talk to Toby and be his friend but that is like trying to be friends with an evil... snail. I feel like I'm dying inside. I feel like Neve Campbell in Sream II. She thinks she can go off to college and be happy and then, the murderer comes back and starts killing off all of her friends. I learned a lot of lessons from that movie, this is just one of them.", "order": 23409, "speaker": "Michael Scott"},
  {"season": 5, "episode": 8, "scene": 13, "line": "Okay, just summarize.", "order": 23410, "speaker": "Michael Scott"},
  {"season": 5, "episode": 8, "scene": 13, "line": "Okay, fireable offenses include: workplace violence and sexual harassment.", "order": 23411, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 8, "scene": 13, "line": "That's it, that's it, perfect. We will get him to hit on somebody, and then we will catch him in the act.", "order": 23412, "speaker": "Michael Scott"},
  {"season": 5, "episode": 8, "scene": 13, "line": "I love catching people in the act. That's why I always whip open doors.", "order": 23413, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 8, "scene": 13, "line": "Mm. Me too. Okay, let's get this started.", "order": 23414, "speaker": "Michael Scott"},
  {"season": 5, "episode": 8, "scene": 13, "line": "Okay [stands up removes jacket and starts loosening his tie]", "order": 23415, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 8, "scene": 13, "line": "What are you doing?", "order": 23416, "speaker": "Michael Scott"},
  {"season": 5, "episode": 8, "scene": 13, "line": "[removes glasses] I am the bait.", "order": 23417, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 8, "scene": 13, "line": "For what?", "order": 23418, "speaker": "Michael Scott"},
  {"season": 5, "episode": 8, "scene": 13, "line": "Men find me desirable.", "order": 23419, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 8, "scene": 13, "line": "No, no, no.", "order": 23420, "speaker": "Michael Scott"},
  {"season": 5, "episode": 8, "scene": 13, "line": "Oh, it's a good day too. I'm wearing my mustard shirt.", "order": 23421, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 8, "scene": 13, "line": "You're the bait for Toby?", "order": 23422, "speaker": "Michael Scott"},
  {"season": 5, "episode": 8, "scene": 13, "line": "Mmhmm.", "order": 23423, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 8, "scene": 13, "line": "No, for one thing, he's not gay. And if somebody were to be bait it would be Jim. Or Ryan. Or me.", "order": 23424, "speaker": "Michael Scott"},
  {"season": 5, "episode": 8, "scene": 13, "line": "Men find me desirable.", "order": 23425, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 8, "scene": 13, "line": "Yes, sure they do, Dwight.", "order": 23426, "speaker": "Michael Scott"},
  {"season": 5, "episode": 8, "scene": 14, "line": "When it comes down to it, it's a health issue. I should have written that.", "order": 23427, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 8, "scene": 14, "line": "Mmhmm, yeah.", "order": 23428, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 8, "scene": 14, "line": "[looks up] Why aren't you as mad or interested in this as me?", "order": 23429, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 8, "scene": 14, "line": "Oh totally. Sorry, are we talking about the microwave still?", "order": 23430, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 8, "scene": 14, "line": "Are you inching away from me?", "order": 23431, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 8, "scene": 14, "line": "No.", "order": 23432, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 8, "scene": 14, "line": "Reach your arms out.", "order": 23433, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 8, "scene": 14, "line": "[laughs and swings his arm out which doesn't come close to touching the reception desk] I'm always this close.", "order": 23434, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 8, "scene": 14, "line": "Pamtown lady sing this song, doo-dah doo-dah. Hello, Pam. Jim may I have a moment with Pam please.", "order": 23435, "speaker": "Michael Scott"},
  {"season": 5, "episode": 8, "scene": 14, "line": "Yes, I just have to take this call anyway so...", "order": 23436, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 8, "scene": 14, "line": "Oh, oooh his mistress. No. I'm kidding. No one would ever cheat on you; you are the complete package, Pam.", "order": 23437, "speaker": "Michael Scott"},
  {"season": 5, "episode": 8, "scene": 14, "line": "What do you need, Michael?", "order": 23438, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 8, "scene": 14, "line": "Okay, what I would like you to do is take this folded note, and deliver it to Toby Flenderson? I just want you to just react to whatever this note elicits. Do not read it beforehand. Can you do that for me?", "order": 23439, "speaker": "Michael Scott"},
  {"season": 5, "episode": 8, "scene": 14, "line": "Good. [Pam stands up and starts reading note] N-no no no, don't.", "order": 23440, "speaker": "Michael Scott"},
  {"season": 5, "episode": 8, "scene": 14, "line": "Please hug and kiss me, no matter how hard I struggle. I'm too shy to tell you that I love you.'", "order": 23441, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 8, "scene": 14, "line": "Pam. Pam, you gave me your word.", "order": 23442, "speaker": "Michael Scott"},
  {"season": 5, "episode": 8, "scene": 15, "line": "[kissing Kelly against her desk] You did that for me?", "order": 23443, "speaker": "Ryan Howard"},
  {"season": 5, "episode": 8, "scene": 15, "line": "Mmhmm.", "order": 23444, "speaker": "Kelly Kapoor"},
  {"season": 5, "episode": 8, "scene": 15, "line": "Are you happy you did?", "order": 23445, "speaker": "Ryan Howard"},
  {"season": 5, "episode": 8, "scene": 15, "line": "Hey guys that's really inappropriate.", "order": 23446, "speaker": "Toby Flenderson"},
  {"season": 5, "episode": 8, "scene": 15, "line": "[kisses for a little longer] What's up?", "order": 23447, "speaker": "Ryan Howard"},
  {"season": 5, "episode": 8, "scene": 15, "line": "Um, I got some photos from Costa Rica if you want to see them?", "order": 23448, "speaker": "Michael Scott"},
  {"season": 5, "episode": 8, "scene": 15, "line": "Yeah, yeah I'll see them.", "order": 23449, "speaker": "Ryan Howard"},
  {"season": 5, "episode": 8, "scene": 15, "line": "Toby can I see those? Ooh. [takes photos and throws them on the floor] What's the matter? What's the matter? You scared?", "order": 23450, "speaker": "Michael Scott"},
  {"season": 5, "episode": 8, "scene": 15, "line": "Those are fighting words.", "order": 23451, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 8, "scene": 15, "line": "You mad? You mad at me?", "order": 23452, "speaker": "Michael Scott"},
  {"season": 5, "episode": 8, "scene": 15, "line": "I hope he doesn't haul off and just hit you.", "order": 23453, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 8, "scene": 15, "line": "Do you want to do that? You want to hit me, you want to punch me?", "order": 23454, "speaker": "Michael Scott"},
  {"season": 5, "episode": 8, "scene": 15, "line": "Hmmm?", "order": 23455, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 8, "scene": 15, "line": "Huh? He might do it...", "order": 23456, "speaker": "Michael Scott"},
  {"season": 5, "episode": 8, "scene": 15, "line": "Punch him, Toby!", "order": 23457, "speaker": "Kelly Kapoor"},
  {"season": 5, "episode": 8, "scene": 15, "line": "I dare you to. Come on.", "order": 23458, "speaker": "Michael Scott"},
  {"season": 5, "episode": 8, "scene": 15, "line": "Come on. [making karate moves] Baaah!", "order": 23459, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 8, "scene": 15, "line": "What's the matter... haaaww!", "order": 23460, "speaker": "Michael Scott"},
  {"season": 5, "episode": 8, "scene": 15, "line": "Hit him! Hit him, Chicken.", "order": 23461, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 8, "scene": 15, "line": "Yeah, punch him.", "order": 23462, "speaker": "Ryan Howard"},
  {"season": 5, "episode": 8, "scene": 15, "line": "Hey hey! Come on, Ryan, who's side are you on?", "order": 23463, "speaker": "Michael Scott"},
  {"season": 5, "episode": 8, "scene": 15, "line": "Ryan. Come on, man?", "order": 23464, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 8, "scene": 15, "line": "No do it, do it. I dare you.", "order": 23465, "speaker": "Michael Scott"},
  {"season": 5, "episode": 8, "scene": 15, "line": "Punch him as hard as you possibly can in the face.", "order": 23466, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 8, "scene": 15, "line": "Not, not as hard as you can, just a good, solid punch. Come on! Come oooon...", "order": 23467, "speaker": "Michael Scott"},
  {"season": 5, "episode": 8, "scene": 15, "line": "I'm not going to punch you, Michael.", "order": 23468, "speaker": "Toby Flenderson"},
  {"season": 5, "episode": 8, "scene": 15, "line": "Are you really not going to punch him?", "order": 23469, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 8, "scene": 15, "line": "No, why would I punch you?", "order": 23470, "speaker": "Toby Flenderson"},
  {"season": 5, "episode": 8, "scene": 15, "line": "Son of a bitch.", "order": 23471, "speaker": "Michael Scott"},
  {"season": 5, "episode": 8, "scene": 15, "line": "[as Toby picks up his photos] You should have hit him man, guy was asking for it. Once in a lifetime, man.", "order": 23472, "speaker": "Ryan Howard"},
  {"season": 5, "episode": 8, "scene": 16, "line": "I thought you were going to parcel those out through the day", "order": 23473, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 8, "scene": 16, "line": "[mouth full of brownie] Just stop it. You haven't done anything helpful all day.", "order": 23474, "speaker": "Michael Scott"},
  {"season": 5, "episode": 8, "scene": 16, "line": "There's still one thing we could do to get Toby fired.", "order": 23475, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 8, "scene": 16, "line": "What's that?", "order": 23476, "speaker": "Michael Scott"},
  {"season": 5, "episode": 8, "scene": 16, "line": "[goes over and closes office door] Frame him, for using drugs.", "order": 23477, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 8, "scene": 16, "line": "Frame him?", "order": 23478, "speaker": "Michael Scott"},
  {"season": 5, "episode": 8, "scene": 16, "line": "Yeah, it's illegal, but... everything they do on The Shield is illegal.", "order": 23479, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 8, "scene": 16, "line": "I've never framed a man before, have you?", "order": 23480, "speaker": "Michael Scott"},
  {"season": 5, "episode": 8, "scene": 16, "line": "Oh I've framed animals before. I framed a raccoon for opening a Christmas present. And I framed a bear for eating out of the garbage.", "order": 23481, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 8, "scene": 16, "line": "Just seems awfully mean. But sometimes the ends justify the mean.", "order": 23482, "speaker": "Michael Scott"},
  {"season": 5, "episode": 8, "scene": 17, "line": "Hello. I've seen you guys around. I'm Michael Scott, Dunder Mifflin. How you doing? [they stare at him] Uhghh, so I guess you know why I'm here? I need to purchase something.", "order": 23483, "speaker": "Michael Scott"},
  {"season": 5, "episode": 8, "scene": 17, "line": "A fridge?", "order": 23484, "speaker": "Vance Refrigeration guy"},
  {"season": 5, "episode": 8, "scene": 17, "line": "No. Uh, I wanted--- I wanted to buy some weed? Some...", "order": 23485, "speaker": "Michael Scott"},
  {"season": 5, "episode": 8, "scene": 17, "line": "What?", "order": 23486, "speaker": "Vance Refrigeration guy"},
  {"season": 5, "episode": 8, "scene": 17, "line": "Grass, weed?", "order": 23487, "speaker": "Michael Scott"},
  {"season": 5, "episode": 8, "scene": 17, "line": "What makes you think we'd have weed?", "order": 23488, "speaker": "Leo"},
  {"season": 5, "episode": 8, "scene": 17, "line": "I heard you drug--- I heard you dealt.", "order": 23489, "speaker": "Michael Scott"},
  {"season": 5, "episode": 8, "scene": 17, "line": "[whispers in Leo's ear] Hey, just hold on one second.", "order": 23490, "speaker": "Vance Refrigeration Guy"},
  {"season": 5, "episode": 8, "scene": 17, "line": "I'm not wearing a wire, so...", "order": 23491, "speaker": "Michael Scott"},
  {"season": 5, "episode": 8, "scene": 17, "line": "Why would you even say that?", "order": 23492, "speaker": "Leo"},
  {"season": 5, "episode": 8, "scene": 17, "line": "[comes back up to Michael] Hey, that's gonna be 500 dollars.", "order": 23493, "speaker": "Vance Refrigeration Guy"},
  {"season": 5, "episode": 8, "scene": 17, "line": "How much? How many pounds is it?", "order": 23494, "speaker": "Michael Scott"},
  {"season": 5, "episode": 8, "scene": 17, "line": "It's- it's two pounds. I'm losing money on this man just, give me the money. Alright, walk away.", "order": 23495, "speaker": "Vance Refrigeration Guy"},
  {"season": 5, "episode": 8, "scene": 17, "line": "Walk away.", "order": 23496, "speaker": "Leo"},
  {"season": 5, "episode": 8, "scene": 17, "line": "Walk away.", "order": 23497, "speaker": "Vance Refrigeration Guy"},
  {"season": 5, "episode": 8, "scene": 18, "line": "Hey, Pam? I just wanted to let you know; I'm totally on your side with the whole microwave situation.", "order": 23498, "speaker": "Ryan Howard"},
  {"season": 5, "episode": 8, "scene": 18, "line": "Thank you.", "order": 23499, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 8, "scene": 18, "line": "I was just back there, to make some cup-o-soup; the thing is still a huge mess.", "order": 23500, "speaker": "Ryan Howard"},
  {"season": 5, "episode": 8, "scene": 18, "line": "I know, can you believe it?", "order": 23501, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 8, "scene": 18, "line": "Yeah, it's crazy. But, I guess the thing is at some point, notes or no notes, someone's gonna have to just get there and clean it up.", "order": 23502, "speaker": "Ryan Howard"},
  {"season": 5, "episode": 8, "scene": 18, "line": "I guess that's why we have a temp, huh?", "order": 23503, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 8, "scene": 18, "line": "Ah ha ha, oh no, trust me. I would just make it worse.", "order": 23504, "speaker": "Ryan Howard"},
  {"season": 5, "episode": 8, "scene": 18, "line": "How would wiping it with a paper towel make it worse?", "order": 23505, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 8, "scene": 18, "line": "I--- I would find a way.", "order": 23506, "speaker": "Ryan Howard"},
  {"season": 5, "episode": 8, "scene": 18, "line": "You've seen things cleaned before though, right?", "order": 23507, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 8, "scene": 18, "line": "I--- Pam, I am hopeless at that stuff I... I, uh...", "order": 23508, "speaker": "Ryan Howard"},
  {"season": 5, "episode": 8, "scene": 19, "line": "Hi, Michael.", "order": 23509, "speaker": "Kelly Kapoor"},
  {"season": 5, "episode": 8, "scene": 19, "line": "Hey, hey, hey. Hey. I forgot... I forgot...", "order": 23510, "speaker": "Michael Scott"},
  {"season": 5, "episode": 8, "scene": 20, "line": "[on a cell phone in the hallway] Yes, I repeat a drug dealer is on the premisis of Dunder Mifflin. His name is Toby Flenderson [Michael opens Toby's desk drawer and puts something in it] and he recently returned from a mysterious vacation in Central America. I have risked a great deal to tell you this information. My name is Andy Bernard. Andrew Bernard, that's my name. See you soon.", "order": 23511, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 8, "scene": 21, "line": "Hi, we received a call?", "order": 23512, "speaker": "Police Officer 1"},
  {"season": 5, "episode": 8, "scene": 21, "line": "I don't know anything about that.", "order": 23513, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 8, "scene": 21, "line": "We were tipped off about a possible narcotics situation.", "order": 23514, "speaker": "Police Officer 1"},
  {"season": 5, "episode": 8, "scene": 21, "line": "Here?", "order": 23515, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 8, "scene": 21, "line": "Hey, Pam. Hey I got this, okay? Hello, officers, Dwight Schrute, former volunteer Sheriff's Deputy. Listen, I may have inside information that someone is hiding drugs in this very office. [leads them back towards the annex as Creed stands up and starts to put him hands up and then relaxes them behind his ears]", "order": 23516, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 8, "scene": 22, "line": "Just pretend like we're talking until the cops leave.", "order": 23517, "speaker": "Creed Bratton"},
  {"season": 5, "episode": 8, "scene": 23, "line": "Those are real cops, real guns. I wonder what's going on. [follows them]", "order": 23518, "speaker": "Michael Scott"},
  {"season": 5, "episode": 8, "scene": 23, "line": "Officers, I reveal to you the perpetrator.", "order": 23519, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 8, "scene": 23, "line": "Hey, what is this?", "order": 23520, "speaker": "Toby Flenderson"},
  {"season": 5, "episode": 8, "scene": 23, "line": "Search his things.", "order": 23521, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 8, "scene": 23, "line": "Sir, can you step away from your desk please?", "order": 23522, "speaker": "Police Officer 2"},
  {"season": 5, "episode": 8, "scene": 23, "line": "Why?", "order": 23523, "speaker": "Toby Flenderson"},
  {"season": 5, "episode": 8, "scene": 23, "line": "Sir, please step away from your desk.", "order": 23524, "speaker": "Police Officer 2"},
  {"season": 5, "episode": 8, "scene": 23, "line": "Hey, what's going on? You don't have my permission to do this.", "order": 23525, "speaker": "Toby Flenderson"},
  {"season": 5, "episode": 8, "scene": 23, "line": "They don't need your permission, Flenderson, they've got the company's permission.", "order": 23526, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 8, "scene": 23, "line": "Hey, hey what are- why are you doing this?", "order": 23527, "speaker": "Toby Flenderson"},
  {"season": 5, "episode": 8, "scene": 23, "line": "Uh, you know what? I think that this... uh, this is probably a misunderstanding.", "order": 23528, "speaker": "Michael Scott"},
  {"season": 5, "episode": 8, "scene": 23, "line": "Don't search my stuff.", "order": 23529, "speaker": "Toby Flenderson"},
  {"season": 5, "episode": 8, "scene": 23, "line": "Sir?", "order": 23530, "speaker": "Police Officer 1"},
  {"season": 5, "episode": 8, "scene": 23, "line": "I have a reasonable right to privacy.", "order": 23531, "speaker": "Toby Flenderson"},
  {"season": 5, "episode": 8, "scene": 23, "line": "Save your whining for the jury there, Flenderson.", "order": 23532, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 8, "scene": 23, "line": "Michael!", "order": 23533, "speaker": "Toby Flenderson"},
  {"season": 5, "episode": 8, "scene": 23, "line": "Yeah, let's just cancel this, okay?", "order": 23534, "speaker": "Michael Scott"},
  {"season": 5, "episode": 8, "scene": 23, "line": "Sir, did you recently return from a trip to Central America?", "order": 23535, "speaker": "Police Officer 1"},
  {"season": 5, "episode": 8, "scene": 23, "line": "Oh my God!", "order": 23536, "speaker": "Toby Flenderson"},
  {"season": 5, "episode": 8, "scene": 23, "line": "No, no no no... No, no.", "order": 23537, "speaker": "Michael Scott"},
  {"season": 5, "episode": 8, "scene": 23, "line": "That was... I went to Costa Rica for a few months. What is going on?", "order": 23538, "speaker": "Toby Flenderson"},
  {"season": 5, "episode": 8, "scene": 23, "line": "No, that was... that was legitimate. That was totally legitimate.", "order": 23539, "speaker": "Michael Scott"},
  {"season": 5, "episode": 8, "scene": 23, "line": "Really?", "order": 23540, "speaker": "Police Officer 1"},
  {"season": 5, "episode": 8, "scene": 23, "line": "Check this out.", "order": 23541, "speaker": "Police Officer 2"},
  {"season": 5, "episode": 8, "scene": 23, "line": "Oh God!", "order": 23542, "speaker": "Michael Scott"},
  {"season": 5, "episode": 8, "scene": 23, "line": "That is not mine. I have never seen that before.", "order": 23543, "speaker": "Toby Flenderson"},
  {"season": 5, "episode": 8, "scene": 23, "line": "No, no no no no.", "order": 23544, "speaker": "Michael Scott"},
  {"season": 5, "episode": 8, "scene": 23, "line": "Turn around.", "order": 23545, "speaker": "Police Officer 1"},
  {"season": 5, "episode": 8, "scene": 23, "line": "What is going on here?", "order": 23546, "speaker": "Toby Flenderson"},
  {"season": 5, "episode": 8, "scene": 23, "line": "God! No, that's not... I don't know what that is. And I... I bet he has nothing to do with that!", "order": 23547, "speaker": "Michael Scott"},
  {"season": 5, "episode": 8, "scene": 23, "line": "Do you have any weapons?", "order": 23548, "speaker": "Police Officer 1"},
  {"season": 5, "episode": 8, "scene": 23, "line": "Of course not!", "order": 23549, "speaker": "Toby Flenderson"},
  {"season": 5, "episode": 8, "scene": 23, "line": "Ahh, that's mine.", "order": 23550, "speaker": "Michael Scott"},
  {"season": 5, "episode": 8, "scene": 23, "line": "No! Michael, no!", "order": 23551, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 8, "scene": 23, "line": "Yes, it's mine.", "order": 23552, "speaker": "Michael Scott"},
  {"season": 5, "episode": 8, "scene": 23, "line": "Some basil. Salad dressing, I think.", "order": 23553, "speaker": "Police Officer 2"},
  {"season": 5, "episode": 8, "scene": 23, "line": "Salad dressing?", "order": 23554, "speaker": "Police Officer 1"},
  {"season": 5, "episode": 8, "scene": 23, "line": "Yeah it's, uh, a caprese salad. There's a little bit of, uh, mozerella right there.", "order": 23555, "speaker": "Police Officer 2"},
  {"season": 5, "episode": 8, "scene": 23, "line": "Are you kidding me? That's my salad.", "order": 23556, "speaker": "Michael Scott"},
  {"season": 5, "episode": 8, "scene": 23, "line": "So wait a minute, there's no drugs?", "order": 23557, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 8, "scene": 23, "line": "No.", "order": 23558, "speaker": "Police Officer 2"},
  {"season": 5, "episode": 8, "scene": 23, "line": "Gahh, dammit! [punches file cabinet] Come on!", "order": 23559, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 8, "scene": 23, "line": "We got a fake tip.", "order": 23560, "speaker": "Police Officer 1"},
  {"season": 5, "episode": 8, "scene": 23, "line": "Wait, officers, are you sure you don't want to interrogate him?", "order": 23561, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 8, "scene": 23, "line": "No.", "order": 23562, "speaker": "Police Officer 1"},
  {"season": 5, "episode": 8, "scene": 23, "line": "[following them out] You have laws that protect you in any kind of interrogation. Why don't you use them?", "order": 23563, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 8, "scene": 23, "line": "You must feel pretty good about yourself right now.", "order": 23564, "speaker": "Michael Scott"},
  {"season": 5, "episode": 8, "scene": 23, "line": "I didn't put caprese salad in my drawer, Michael.", "order": 23565, "speaker": "Toby Flenderson"},
  {"season": 5, "episode": 8, "scene": 23, "line": "Hmm.", "order": 23566, "speaker": "Michael Scott"},
  {"season": 5, "episode": 8, "scene": 23, "line": "Did you?", "order": 23567, "speaker": "Toby Flenderson"},
  {"season": 5, "episode": 8, "scene": 23, "line": "Since when is it illegal to put caprese salad... anywhere.", "order": 23568, "speaker": "Michael Scott"},
  {"season": 5, "episode": 8, "scene": 23, "line": "You know but the police could have been out there you know, catching real criminals instead of here searching my stuff.", "order": 23569, "speaker": "Toby Flenderson"},
  {"season": 5, "episode": 8, "scene": 23, "line": "Are you kidding me? Are you kidding me, that's who you're worried about? You're... you're worried about the cop's time? You think I framed you, and you're worried about the taxpayer? Dah, God! Welcome back, jerky jerk-face.", "order": 23570, "speaker": "Michael Scott"},
  {"season": 5, "episode": 8, "scene": 24, "line": "You said you were leaving and you made liars out of all of us. So...", "order": 23571, "speaker": "Michael Scott"},
  {"season": 5, "episode": 8, "scene": 24, "line": "I did leave.", "order": 23572, "speaker": "Toby Flenderson"},
  {"season": 5, "episode": 8, "scene": 24, "line": "Yes, you did. And then you came back, which makes you the biggest liar of... history.", "order": 23573, "speaker": "Michael Scott"},
  {"season": 5, "episode": 8, "scene": 24, "line": "Well, I don't see it that way.", "order": 23574, "speaker": "Toby Flenderson"},
  {"season": 5, "episode": 8, "scene": 24, "line": "Do you want to hear a lie?", "order": 23575, "speaker": "Michael Scott"},
  {"season": 5, "episode": 8, "scene": 24, "line": "What?", "order": 23576, "speaker": "Toby Flenderson"},
  {"season": 5, "episode": 8, "scene": 24, "line": "I think you're great. You're my best friend.", "order": 23577, "speaker": "Michael Scott"},
  {"season": 5, "episode": 8, "scene": 25, "line": "[stops kissing Kelly] I can't do this.", "order": 23578, "speaker": "Ryan Howard"},
  {"season": 5, "episode": 8, "scene": 25, "line": "Can't do what?", "order": 23579, "speaker": "Kelly Kapoor"},
  {"season": 5, "episode": 8, "scene": 25, "line": "It's not fair to you. And it's really not fair to me.", "order": 23580, "speaker": "Ryan Howard"},
  {"season": 5, "episode": 8, "scene": 25, "line": "Wait, what are you saying? I broke up with Darryl so I could be with you.", "order": 23581, "speaker": "Kelly Kapoor"},
  {"season": 5, "episode": 8, "scene": 25, "line": "That was your choice; don't put that on me. I'm just going on a little trip.", "order": 23582, "speaker": "Ryan Howard"},
  {"season": 5, "episode": 8, "scene": 25, "line": "Oh, can I come?", "order": 23583, "speaker": "Kelly Kapoor"},
  {"season": 5, "episode": 8, "scene": 25, "line": "It's not that kind of trip. I'm going to Thailand with some friends from high school, well, a high school. And if I don't do it now I'll never get to go. And I'll always resent you for it... you don't want me to resent you, do you?", "order": 23584, "speaker": "Ryan Howard"},
  {"season": 5, "episode": 8, "scene": 25, "line": "So you're dumping me?", "order": 23585, "speaker": "Kelly Kapoor"},
  {"season": 5, "episode": 8, "scene": 25, "line": "Let's be adults about this. Let's have sex one more time. And if you have any extra cash, that would be amazing.", "order": 23586, "speaker": "Ryan Howard"},
  {"season": 5, "episode": 8, "scene": 25, "line": "Okay?", "order": 23587, "speaker": "Kelly Kapoor"},
  {"season": 5, "episode": 8, "scene": 26, "line": "[walking out of the building] Hey, do you mind if we make a stop on the way home?", "order": 23588, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 8, "scene": 26, "line": "Sure.", "order": 23589, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 8, "scene": 26, "line": "Cool.", "order": 23590, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 8, "scene": 27, "line": "[getting out of the car] What are we doing at your parent's house?", "order": 23591, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 8, "scene": 27, "line": "I have a surprise for you. All right, ready? Close your eyes and now [spins her around once] open your eyes. Tadah!", "order": 23592, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 8, "scene": 27, "line": "I don't get it.", "order": 23593, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 8, "scene": 27, "line": "I bought it. It's ours. Let's go inside, I'll show you inside. [walking inside] So if you can believe it I did it without a realtor. Saving on closing costs is good and, uh, we can put all the money to de-shag the carpet. Which I think will help, the color situation. [Pam walks over to the clown painting on the wall] Yeah, I am really sorry about this. I tried to move it but he is really nailed in there. Worried about art theft, I guess, lot of art theives in this neighborhood. [upstairs] This is the master bedroom but, I'm actually not allowed in here so... [shuts the door]", "order": 23594, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 8, "scene": 28, "line": "[outside] So I'm still in the process of converting the garage. It's got great light in here and I'm thinking, it could be perfect [opens garage door] for an art studio. [Pam looks around and then looks at him] Look, I know, I bought this without asking you and it's doesn't look great, I know that. And if you really hate it, I totally understand it's just---", "order": 23595, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 8, "scene": 28, "line": "I love it.", "order": 23596, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 8, "scene": 28, "line": "You do?", "order": 23597, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 8, "scene": 28, "line": "Yeah, I love it!", "order": 23598, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 8, "scene": 28, "line": "Really?", "order": 23599, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 8, "scene": 28, "line": "I mean, you bought me a house!", "order": 23600, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 8, "scene": 28, "line": "Oh my God...", "order": 23601, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 8, "scene": 28, "line": "You bought me a house!", "order": 23602, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 8, "scene": 28, "line": "Yeah, I did. [they kiss]", "order": 23603, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 8, "scene": 28, "line": "Um, do we have to sleep in your parent's bedroom?", "order": 23604, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 8, "scene": 28, "line": "No, No, we'll just board that up. It'll be that weird spare room that people ask us about.", "order": 23605, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 8, "scene": 28, "line": "And the clown?", "order": 23606, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 8, "scene": 28, "line": "Yeah, I can't... really can't move him.", "order": 23607, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 8, "scene": 29, "line": "What is my perfect crime? I break into Tiffany's at midnight. Do I go for the vault? No, I go for the chandelier. It's priceless. As I'm taking it down, a woman catches me. She tells me to stop. It's her father's business. She's Tiffany. I say no. We make love all night. In the morning the cops come and I escape in one of their uniforms. I tell her to meet me in Mexico but I go to Canada. I don't trust her. Besides, I like the cold. Thirty years later, I get a postcard. I have a son and he's the chief of police. This is where the story gets interesting: I tell Tiffany to meet me in Paris, by the Trocadero. She's been waiting for me all these years; she's never taken another lover. I don't care, I don't show up. I go to Berlin. That's where I stashed the chandelier.", "order": 23608, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 9, "scene": 1, "line": "Here are our final actual costs for this year.", "order": 23609, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 9, "scene": 1, "line": "Mmm... okay.", "order": 23610, "speaker": "Michael Scott"},
  {"season": 5, "episode": 9, "scene": 1, "line": "As you can see, we did pretty well, so...", "order": 23611, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 9, "scene": 1, "line": "Yes. Yes, I can see... that we did indeed. Why don't you explain this to me like I am an eight-year old.", "order": 23612, "speaker": "Michael Scott"},
  {"season": 5, "episode": 9, "scene": 1, "line": "Alright, well this is the overall budget for this fiscal year along the x-axis...", "order": 23613, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 9, "scene": 1, "line": "Yes.", "order": 23614, "speaker": "Michael Scott"},
  {"season": 5, "episode": 9, "scene": 1, "line": "Right there.", "order": 23615, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 9, "scene": 1, "line": "There's the x-ax...icks.", "order": 23616, "speaker": "Michael Scott"},
  {"season": 5, "episode": 9, "scene": 1, "line": "You can see clearly on this page that we have a surplus of $4300.", "order": 23617, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 9, "scene": 1, "line": "Mmhmm, okay.", "order": 23618, "speaker": "Michael Scott"},
  {"season": 5, "episode": 9, "scene": 1, "line": "But we have to spend that by the end of the day or it will be deducted from next year's budget.", "order": 23619, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 9, "scene": 1, "line": "Why don't you explain this to me like I'm five.", "order": 23620, "speaker": "Michael Scott"},
  {"season": 5, "episode": 9, "scene": 1, "line": "Your mommy and daddy give you ten dollars to open up a lemonade stand. So you go out and you buy cups and you buy lemons and you buy sugar. And now you find out that it only costs you nine dollars.", "order": 23621, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 9, "scene": 1, "line": "Ho-oh!", "order": 23622, "speaker": "Michael Scott"},
  {"season": 5, "episode": 9, "scene": 1, "line": "So you have an extra dollar.", "order": 23623, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 9, "scene": 1, "line": "Yeah.", "order": 23624, "speaker": "Michael Scott"},
  {"season": 5, "episode": 9, "scene": 1, "line": "So you can give that dollar back to mommy and daddy, but guess what? Next summer...", "order": 23625, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 9, "scene": 1, "line": "I'll be six.", "order": 23626, "speaker": "Michael Scott"},
  {"season": 5, "episode": 9, "scene": 1, "line": "And you ask them for money, they're gonna give you nine dollars. 'Cause that's what they think it costs to run the stand. So what you want to do is spend that dollar on something now, so that your parents think it costs ten dollars to run the lemonade stand.", "order": 23627, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 9, "scene": 1, "line": "So the dollar's a surplus. This is a surplus.", "order": 23628, "speaker": "Michael Scott"},
  {"season": 5, "episode": 9, "scene": 1, "line": "We have to spend that $4300 by the end of the day or it'll be deducted from next year's budget.", "order": 23629, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 9, "scene": 1, "line": "[whistles poorly] Whoo.", "order": 23630, "speaker": "Michael Scott"},
  {"season": 5, "episode": 9, "scene": 1, "line": "We should spend this money on a new copier, which we desperately need.", "order": 23631, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 9, "scene": 1, "line": "Okay, break it down in terms of, um... okay, I-I think I'm getting you...", "order": 23632, "speaker": "Michael Scott"},
  {"season": 5, "episode": 9, "scene": 2, "line": "Guess what, everybody? Christmas has come early this year. Oscar, very smartly, has discovered an extra $4300 in the budget. Thank you, Oscar. And I have decided with that money I am going to buy a new, drum roll please... [imitates drum roll] Can anybody guess?", "order": 23633, "speaker": "Michael Scott"},
  {"season": 5, "episode": 9, "scene": 2, "line": "New chairs?", "order": 23634, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 9, "scene": 2, "line": "No, a new copier! [only Oscar applauds] Unless everybody can agree on something better?", "order": 23635, "speaker": "Michael Scott"},
  {"season": 5, "episode": 9, "scene": 2, "line": "No, no, please. Please do not do this.", "order": 23636, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 9, "scene": 2, "line": "Yes, Michael, new chairs. These chairs are terrible. We were supposed to get new ones last year.", "order": 23637, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 9, "scene": 2, "line": "So... we all agree to get new chairs then. Good?", "order": 23638, "speaker": "Michael Scott"},
  {"season": 5, "episode": 9, "scene": 2, "line": "[over indistinct conversation] Good? Yeah, he said good, I'm good...", "order": 23639, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 9, "scene": 2, "line": "Now listen, we are a paper company. How can we take pride in our jobs if we have to put our fine paper in this wretched machine?", "order": 23640, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 9, "scene": 2, "line": "Oscar, no. This is not the time for one of your principled stands.", "order": 23641, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 9, "scene": 2, "line": "Pam, you make more copies than anyone.", "order": 23642, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 9, "scene": 2, "line": "Exactly. That should tell you how terrible the chairs are.", "order": 23643, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 9, "scene": 2, "line": "Okay. Okay, good suggestions. All good suggestions. Uh, let's just decide and agree upon one.", "order": 23644, "speaker": "Michael Scott"},
  {"season": 5, "episode": 9, "scene": 2, "line": "I'm with Pam. Chairs.", "order": 23645, "speaker": "Stanley Hudson"},
  {"season": 5, "episode": 9, "scene": 2, "line": "Alright, so, teams forming.", "order": 23646, "speaker": "Michael Scott"},
  {"season": 5, "episode": 9, "scene": 2, "line": "We should really have the office's air quality tested. We have radon coming from below, we have asbestos in the ceilings. These are silent killers.", "order": 23647, "speaker": "Toby Flenderson"},
  {"season": 5, "episode": 9, "scene": 2, "line": "You are the silent killer. Go back to the annex.", "order": 23648, "speaker": "Michael Scott"},
  {"season": 5, "episode": 9, "scene": 2, "line": "You'll see.", "order": 23649, "speaker": "Toby Flenderson"},
  {"season": 5, "episode": 9, "scene": 2, "line": "Michael?", "order": 23650, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 9, "scene": 2, "line": "Yes.", "order": 23651, "speaker": "Michael Scott"},
  {"season": 5, "episode": 9, "scene": 2, "line": "I've talked to Meredith, Stanley and Jim about the chairs. I know they're with me on this.", "order": 23652, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 9, "scene": 2, "line": "Uh, actually, I'm gonna go with copier.", "order": 23653, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 9, "scene": 2, "line": "What? Jim.", "order": 23654, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 9, "scene": 3, "line": "Ever since Pam and I started dating, I just feel weird asking her to make copies for me. So, I make my own copies. And that copier sucks. Let me tell you, I-But you know what? Pam and I don't have to agree on everything.", "order": 23655, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 9, "scene": 4, "line": "Jim, good for you, standing up to Pam like that.", "order": 23656, "speaker": "Meredith Palmer"},
  {"season": 5, "episode": 9, "scene": 4, "line": "The balls on you, man.", "order": 23657, "speaker": "Creed Bratton"},
  {"season": 5, "episode": 9, "scene": 4, "line": "So Michael, what do you think?", "order": 23658, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 9, "scene": 4, "line": "Why me?", "order": 23659, "speaker": "Michael Scott"},
  {"season": 5, "episode": 9, "scene": 4, "line": "You have to make the decision.", "order": 23660, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 9, "scene": 4, "line": "Wow, okay. Well... I swallowed all your ideas, I'm going to digest them and see what comes out the other end.", "order": 23661, "speaker": "Michael Scott"},
  {"season": 5, "episode": 9, "scene": 5, "line": "Dwight, I'm a little concerned about some of these directions to Schrute Farms...", "order": 23662, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 9, "scene": 5, "line": "Yeah, do tell.", "order": 23663, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 9, "scene": 5, "line": "I mean, like, '156 paces from the light red mailbox, make a left.'", "order": 23664, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 9, "scene": 5, "line": "Mmhmm.", "order": 23665, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 9, "scene": 5, "line": "Walk until you hear the beehive.'", "order": 23666, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 9, "scene": 5, "line": "How could it be more clear?", "order": 23667, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 9, "scene": 5, "line": "I think Andy makes an excellent point.", "order": 23668, "speaker": "Angela Martin"},
  {"season": 5, "episode": 9, "scene": 5, "line": "Okay.", "order": 23669, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 9, "scene": 5, "line": "But my biggest concern is that there's only one bathroom.", "order": 23670, "speaker": "Angela Martin"},
  {"season": 5, "episode": 9, "scene": 5, "line": "We'll dig a trench. As long as it's downhill from the well, we should be fine.", "order": 23671, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 9, "scene": 5, "line": "Nana Mimi cannot squat over some trench.", "order": 23672, "speaker": "Angela Martin"},
  {"season": 5, "episode": 9, "scene": 5, "line": "Well we're not gonna put out stumps, come on.", "order": 23673, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 9, "scene": 5, "line": "Let's three-way this little issue, and come to a solution by the time we get to Schrute Farms, how's that for a plan?", "order": 23674, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 9, "scene": 6, "line": "We're getting married at Schrute Farms, no matter what. I have looked at twelve venues, I have lost eight deposits, and I have seen Angela naked zero times. I am not losing another deposit.", "order": 23675, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 9, "scene": 7, "line": "Hey.", "order": 23676, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 9, "scene": 7, "line": "Hey.", "order": 23677, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 9, "scene": 7, "line": "So um, I've been thinking about this whole chair/copier thing...", "order": 23678, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 9, "scene": 7, "line": "Mmhmm.", "order": 23679, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 9, "scene": 7, "line": "I really think you should reconsider.", "order": 23680, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 9, "scene": 7, "line": "Oh, Pam, I really... hate that copier.", "order": 23681, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 9, "scene": 7, "line": "Yeah, I know.", "order": 23682, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 9, "scene": 7, "line": "Yeah.", "order": 23683, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 9, "scene": 7, "line": "But I really think you should reconsider.", "order": 23684, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 9, "scene": 7, "line": "Beesly, are you threatening me?", "order": 23685, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 9, "scene": 7, "line": "Jim. Jim, Jim, Jim. I'm not threatening you. I love you. [whispers] But you should know, you're on very dangerous ground [kisses Jim]. [at normal volume] All right.", "order": 23686, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 9, "scene": 7, "line": "Okay.", "order": 23687, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 9, "scene": 8, "line": "[shudders] Whew.", "order": 23688, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 9, "scene": 9, "line": "This is where you'll have your receiving line. Of course we'll clear out all the livestock and hay and such.", "order": 23689, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 9, "scene": 9, "line": "Hmm... mm, what's that smell?", "order": 23690, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 9, "scene": 9, "line": "You're gonna need to be more specific.", "order": 23691, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 9, "scene": 9, "line": "Manure. Get rid of it.", "order": 23692, "speaker": "Angela Martin"},
  {"season": 5, "episode": 9, "scene": 9, "line": "Manure covers up the small of the slaughterhouse.", "order": 23693, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 9, "scene": 9, "line": "Do you have to slaughter on our wedding day?", "order": 23694, "speaker": "Angela Martin"},
  {"season": 5, "episode": 9, "scene": 9, "line": "You wanna eat, don't you?", "order": 23695, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 9, "scene": 9, "line": "Honey, say something!", "order": 23696, "speaker": "Angela Martin"},
  {"season": 5, "episode": 9, "scene": 9, "line": "Uh, Dwight, if we pay extra, could you slaughter the entrees the day before?", "order": 23697, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 9, "scene": 9, "line": "...I'll consider it.", "order": 23698, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 9, "scene": 9, "line": "See? That's how you do it! Makin' progress here. [steps in manure, trying to laugh it off] ...Darn! Heh.", "order": 23699, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 9, "scene": 9, "line": "There's a hose out back.", "order": 23700, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 9, "scene": 9, "line": "Okay.", "order": 23701, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 9, "scene": 10, "line": "Remember, you were gonna get a new chair, and you were gonna give your old chair to me. Remember that?", "order": 23702, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 9, "scene": 10, "line": "Yes.", "order": 23703, "speaker": "Michael Scott"},
  {"season": 5, "episode": 9, "scene": 10, "line": "Well that never happened.", "order": 23704, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 9, "scene": 10, "line": "Michael?", "order": 23705, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 9, "scene": 10, "line": "I don't know. I haven't decided yet.", "order": 23706, "speaker": "Michael Scott"},
  {"season": 5, "episode": 9, "scene": 10, "line": "I'm just gonna grab some lunch. You wanna come with?", "order": 23707, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 9, "scene": 10, "line": "Really? Absolutely! Yeah, that would be amazing.", "order": 23708, "speaker": "Michael Scott"},
  {"season": 5, "episode": 9, "scene": 10, "line": "Woah, you guys going to lunch?", "order": 23709, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 9, "scene": 10, "line": "Yes.", "order": 23710, "speaker": "Michael Scott"},
  {"season": 5, "episode": 9, "scene": 10, "line": "Mind if I join?", "order": 23711, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 9, "scene": 10, "line": "Ohh, God!", "order": 23712, "speaker": "Michael Scott"},
  {"season": 5, "episode": 9, "scene": 10, "line": "All right.", "order": 23713, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 9, "scene": 10, "line": "Yeah!", "order": 23714, "speaker": "Michael Scott"},
  {"season": 5, "episode": 9, "scene": 10, "line": "Let's go.", "order": 23715, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 9, "scene": 10, "line": "Best lunch ever! Woo-hoo-hoo-hoo!", "order": 23716, "speaker": "Michael Scott"},
  {"season": 5, "episode": 9, "scene": 10, "line": "Let's do it.", "order": 23717, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 9, "scene": 10, "line": "All right! Cool!", "order": 23718, "speaker": "Michael Scott"},
  {"season": 5, "episode": 9, "scene": 10, "line": "Where we going?", "order": 23719, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 9, "scene": 10, "line": "I have no idea.", "order": 23720, "speaker": "Michael Scott"},
  {"season": 5, "episode": 9, "scene": 11, "line": "So, I guess that's how they're gonna play this. It is on. [very serious] It is so on.", "order": 23721, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 9, "scene": 12, "line": "Look, I really need this new chair. I mean, seriously, how is it possible that in five years I've had two engagement rings, and only one chair?", "order": 23722, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 9, "scene": 13, "line": "[laughing uproariously with Michael and Jim] Stop it! Michael.", "order": 23723, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 9, "scene": 13, "line": "Michael, that is hilarious.", "order": 23724, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 9, "scene": 13, "line": "[through the laughter] Oh, is somebody making fun of me?", "order": 23725, "speaker": "Michael Scott"},
  {"season": 5, "episode": 9, "scene": 13, "line": "The best. Great friends. Thank you, Michael. [all three are still laughing]", "order": 23726, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 9, "scene": 13, "line": "Ahh... I got you some tiramisu. No hard feelings.", "order": 23727, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 9, "scene": 13, "line": "Aww.", "order": 23728, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 9, "scene": 13, "line": "Yeah.", "order": 23729, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 9, "scene": 13, "line": "That's cool. [opens the container and smells the tiramisu] Mm, good stuff. [throws it in the garbage]", "order": 23730, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 9, "scene": 13, "line": "You look really pretty.", "order": 23731, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 9, "scene": 13, "line": "Thank you. [goes to Michael's office and knocks on the door]", "order": 23732, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 9, "scene": 13, "line": "Yeah.", "order": 23733, "speaker": "Michael Scott"},
  {"season": 5, "episode": 9, "scene": 13, "line": "Michael?", "order": 23734, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 9, "scene": 13, "line": "Yeah?", "order": 23735, "speaker": "Michael Scott"},
  {"season": 5, "episode": 9, "scene": 13, "line": "Hey!", "order": 23736, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 9, "scene": 13, "line": "Oh, hey.", "order": 23737, "speaker": "Michael Scott"},
  {"season": 5, "episode": 9, "scene": 13, "line": "You got a second?", "order": 23738, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 9, "scene": 13, "line": "I do.", "order": 23739, "speaker": "Michael Scott"},
  {"season": 5, "episode": 9, "scene": 13, "line": "Oh, good.", "order": 23740, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 9, "scene": 14, "line": "Oh, that must've been so fun.", "order": 23741, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 9, "scene": 14, "line": "it was fun. We had a good time.", "order": 23742, "speaker": "Michael Scott"},
  {"season": 5, "episode": 9, "scene": 14, "line": "Hey, have I told you you look really nice today?", "order": 23743, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 9, "scene": 14, "line": "Oh, thank you.", "order": 23744, "speaker": "Michael Scott"},
  {"season": 5, "episode": 9, "scene": 14, "line": "Yeah, is that a new tie?", "order": 23745, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 9, "scene": 14, "line": "Um, no, not... no. No, I got it at TJ Maxx, four dollars.", "order": 23746, "speaker": "Michael Scott"},
  {"season": 5, "episode": 9, "scene": 14, "line": "That is amazing!", "order": 23747, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 9, "scene": 14, "line": "You think that's good? Check out these pants. Nine dollars.", "order": 23748, "speaker": "Michael Scott"},
  {"season": 5, "episode": 9, "scene": 14, "line": "What?", "order": 23749, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 9, "scene": 14, "line": "Nine dollars. The boys' department.", "order": 23750, "speaker": "Michael Scott"},
  {"season": 5, "episode": 9, "scene": 14, "line": "No.", "order": 23751, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 9, "scene": 14, "line": "Look at the ass. Check out the ass.", "order": 23752, "speaker": "Michael Scott"},
  {"season": 5, "episode": 9, "scene": 14, "line": "[applauding] No way!", "order": 23753, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 9, "scene": 14, "line": "Look at that. [begins dancing] Unh. Unh-unh.", "order": 23754, "speaker": "Michael Scott"},
  {"season": 5, "episode": 9, "scene": 14, "line": "Yeah! Oh, so I guess Oscar and Jim were talking your ear off about the new copier.", "order": 23755, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 9, "scene": 14, "line": "Yes they were.", "order": 23756, "speaker": "Michael Scott"},
  {"season": 5, "episode": 9, "scene": 14, "line": "Yeah.", "order": 23757, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 9, "scene": 14, "line": "They were.", "order": 23758, "speaker": "Michael Scott"},
  {"season": 5, "episode": 9, "scene": 14, "line": "Here's what I was thinking: everyone sits on a chair every day...", "order": 23759, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 9, "scene": 14, "line": "Mmhmm.", "order": 23760, "speaker": "Michael Scott"},
  {"season": 5, "episode": 9, "scene": 14, "line": "But not everyone...", "order": 23761, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 9, "scene": 14, "line": "Sits on a copier.", "order": 23762, "speaker": "Michael Scott"},
  {"season": 5, "episode": 9, "scene": 14, "line": "Or even uses the copier every day.", "order": 23763, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 9, "scene": 14, "line": "Yeah.", "order": 23764, "speaker": "Michael Scott"},
  {"season": 5, "episode": 9, "scene": 14, "line": "Yeah, right?", "order": 23765, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 9, "scene": 14, "line": "Very valid.", "order": 23766, "speaker": "Michael Scott"},
  {"season": 5, "episode": 9, "scene": 14, "line": "...That's it. [giggles]", "order": 23767, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 9, "scene": 14, "line": "All right.", "order": 23768, "speaker": "Michael Scott"},
  {"season": 5, "episode": 9, "scene": 14, "line": "All right, see you later...", "order": 23769, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 9, "scene": 14, "line": "See ya.", "order": 23770, "speaker": "Michael Scott"},
  {"season": 5, "episode": 9, "scene": 14, "line": "Hot tie guy.", "order": 23771, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 9, "scene": 14, "line": "[laughs] Hahaha!... well...", "order": 23772, "speaker": "Michael Scott"},
  {"season": 5, "episode": 9, "scene": 15, "line": "Have you made a decision on the butter sculpture?", "order": 23773, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 9, "scene": 15, "line": "No. I haven't thought of it.", "order": 23774, "speaker": "Angela Martin"},
  {"season": 5, "episode": 9, "scene": 15, "line": "Okay. Cow, goat or sheep. It's not that hard.", "order": 23775, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 9, "scene": 15, "line": "I would like cat.", "order": 23776, "speaker": "Angela Martin"},
  {"season": 5, "episode": 9, "scene": 15, "line": "Cats don't make butter.", "order": 23777, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 9, "scene": 15, "line": "I would like cow butter sculpture of a cat.", "order": 23778, "speaker": "Angela Martin"},
  {"season": 5, "episode": 9, "scene": 15, "line": "It doesn't make any sense.", "order": 23779, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 9, "scene": 15, "line": "Yes it does!", "order": 23780, "speaker": "Angela Martin"},
  {"season": 5, "episode": 9, "scene": 15, "line": "Okay, guys!", "order": 23781, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 9, "scene": 15, "line": "I want a butter sculpture of a cat!", "order": 23782, "speaker": "Angela Martin"},
  {"season": 5, "episode": 9, "scene": 15, "line": "Cow, goat or sheep.", "order": 23783, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 9, "scene": 15, "line": "What is this? All right, we're all on the same team. Is it- [steps in manure] Damn! Why is that in the kitchen?!", "order": 23784, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 9, "scene": 16, "line": "[Pam is making copies and is struggling with the machine] You might want to consider changing teams, because we would-we would love to have you.", "order": 23785, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 9, "scene": 16, "line": "No, copier's great.", "order": 23786, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 9, "scene": 16, "line": "Yeah?", "order": 23787, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 9, "scene": 16, "line": "Yeah. I have my copies. [holds up messed up copies]", "order": 23788, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 9, "scene": 16, "line": "There they are.", "order": 23789, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 9, "scene": 16, "line": "And I have my original. [holds up original paper, ripped in half]", "order": 23790, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 9, "scene": 16, "line": "You got it.", "order": 23791, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 9, "scene": 16, "line": "So suck it.", "order": 23792, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 9, "scene": 16, "line": "Okay.", "order": 23793, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 9, "scene": 17, "line": "Okay.", "order": 23794, "speaker": "Michael Scott"},
  {"season": 5, "episode": 9, "scene": 17, "line": "Michael, I got you a hot chocolate. I hope that's okay.", "order": 23795, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 9, "scene": 17, "line": "Oh, thank you my dear.", "order": 23796, "speaker": "Michael Scott"},
  {"season": 5, "episode": 9, "scene": 17, "line": "Wait, Michael! Let me open the door for you.", "order": 23797, "speaker": "Kelly Kapoor"},
  {"season": 5, "episode": 9, "scene": 17, "line": "Oh, well, chivalry is not dead after all. Okay...", "order": 23798, "speaker": "Michael Scott"},
  {"season": 5, "episode": 9, "scene": 17, "line": "There he is!", "order": 23799, "speaker": "Stanley Hudson"},
  {"season": 5, "episode": 9, "scene": 17, "line": "There he is! Hello, hello!", "order": 23800, "speaker": "Michael Scott"},
  {"season": 5, "episode": 9, "scene": 17, "line": "Hello.", "order": 23801, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 9, "scene": 17, "line": "Hello! Good to see you! Good to see you.", "order": 23802, "speaker": "Michael Scott"},
  {"season": 5, "episode": 9, "scene": 17, "line": "Oh!", "order": 23803, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 9, "scene": 17, "line": "Mm! [gives Jim a high five] Yeah!", "order": 23804, "speaker": "Michael Scott"},
  {"season": 5, "episode": 9, "scene": 17, "line": "There's that ass!", "order": 23805, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 9, "scene": 17, "line": "Hey hey! Yeah! Unh!", "order": 23806, "speaker": "Michael Scott"},
  {"season": 5, "episode": 9, "scene": 17, "line": "Woo! Yeah. Aw, don't take it away!", "order": 23807, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 9, "scene": 17, "line": "Oh... ah, I almost choked.", "order": 23808, "speaker": "Michael Scott"},
  {"season": 5, "episode": 9, "scene": 18, "line": "Here's another place.", "order": 23809, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 9, "scene": 18, "line": "It's beautiful.", "order": 23810, "speaker": "Angela Martin"},
  {"season": 5, "episode": 9, "scene": 18, "line": "Hey-o!", "order": 23811, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 9, "scene": 18, "line": "So... why don't we try this out, we'll see what would happen. Give it a little test drive, what do you say? You pretend to be Angela's father, you will play Angela, and uh, I will pretend to be you. That way you can see what it looks like when you're up here.", "order": 23812, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 9, "scene": 18, "line": "[whistling Pachelbel's 'Canon in D;' switches to singing then back to whistling]", "order": 23813, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 9, "scene": 18, "line": "[to minister] Hello, I'm Angela Martin, and-", "order": 23814, "speaker": "Angela Martin"},
  {"season": 5, "episode": 9, "scene": 18, "line": "I'm Andy.", "order": 23815, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 9, "scene": 18, "line": "I work with Dwight.", "order": 23816, "speaker": "Angela Martin"},
  {"season": 5, "episode": 9, "scene": 18, "line": "He doesn't understand a word you're saying.", "order": 23817, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 9, "scene": 18, "line": "What?", "order": 23818, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 9, "scene": 18, "line": "Although born just minutes from here, he speaks only German. Closed society. So, now, after the readings by all of your sisters, we will arrive at the vows. So, Konrad... [speaks German to minister; minister begins speaking in German] And away we go. This is a little taste of the ceremony, if you will. He's explaining why we're here, what we're doing here, making introductions, blah blah blah... Then he's gonna have Andy repeat a bunch of stuff. He's gonna ask Andy to produce a ring. I have uh, now just uh... just some twine for our purposes, and you will put the ring on her finger. Yadda yadda, then he's going to ask Andy, uh, if he would like to marry Angela. And you will reply, 'I do.' [Andy mouths, 'I do' silently] And then he's going to ask Angela if she would like to marry Andy, to which you will reply...", "order": 23819, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 9, "scene": 18, "line": "I do.", "order": 23820, "speaker": "Angela Martin"},
  {"season": 5, "episode": 9, "scene": 18, "line": "And there we go. Okay, and that's just about it. Man and wife.", "order": 23821, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 9, "scene": 19, "line": "Got a call about a problem up here.", "order": 23822, "speaker": "Hank Tate"},
  {"season": 5, "episode": 9, "scene": 19, "line": "Did somebody call Hank?", "order": 23823, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 9, "scene": 19, "line": "Hank, thank God you're here. The office is at a crossroads.", "order": 23824, "speaker": "Michael Scott"},
  {"season": 5, "episode": 9, "scene": 19, "line": "So, there's no security problem? You know, I hustled up the stairs.", "order": 23825, "speaker": "Hank Tate"},
  {"season": 5, "episode": 9, "scene": 19, "line": "Yes. I need your sage advice. And everyone, whatever Hank decides, that is the decision. That will resolve this issue. [Hank takes some jellybeans from Pam's jar] Yeah, take as many as you want.", "order": 23826, "speaker": "Michael Scott"},
  {"season": 5, "episode": 9, "scene": 19, "line": "Thank you. Thank you. You know, it's nice and warm up here.", "order": 23827, "speaker": "Hank Tate"},
  {"season": 5, "episode": 9, "scene": 19, "line": "Yes.", "order": 23828, "speaker": "Michael Scott"},
  {"season": 5, "episode": 9, "scene": 19, "line": "So what are we talking about?", "order": 23829, "speaker": "Hank Tate"},
  {"season": 5, "episode": 9, "scene": 19, "line": "Okay. Well, we have a surplus. Imagine that your parents give you money for a lemonade stand-", "order": 23830, "speaker": "Michael Scott"},
  {"season": 5, "episode": 9, "scene": 19, "line": "I know what a surplus is.", "order": 23831, "speaker": "Hank Tate"},
  {"season": 5, "episode": 9, "scene": 19, "line": "Oh, okay. Good. Good. Well, here's the thing. Some people want to use the surplus to buy a new copier. Other people are complaining about the chairs.", "order": 23832, "speaker": "Michael Scott"},
  {"season": 5, "episode": 9, "scene": 19, "line": "Is that the copier?", "order": 23833, "speaker": "Hank Tate"},
  {"season": 5, "episode": 9, "scene": 19, "line": "It is, yes.", "order": 23834, "speaker": "Michael Scott"},
  {"season": 5, "episode": 9, "scene": 19, "line": "[inspects the copier] Hmm.", "order": 23835, "speaker": "Hank Tate"},
  {"season": 5, "episode": 9, "scene": 19, "line": "What?", "order": 23836, "speaker": "Michael Scott"},
  {"season": 5, "episode": 9, "scene": 19, "line": "I was just thinking. Let me see a chair.", "order": 23837, "speaker": "Hank Tate"},
  {"season": 5, "episode": 9, "scene": 19, "line": "Okay.", "order": 23838, "speaker": "Michael Scott"},
  {"season": 5, "episode": 9, "scene": 19, "line": "You can try mine.", "order": 23839, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 9, "scene": 19, "line": "Oh, there we go.", "order": 23840, "speaker": "Michael Scott"},
  {"season": 5, "episode": 9, "scene": 19, "line": "Hmm, not much lumbar support.", "order": 23841, "speaker": "Hank Tate"},
  {"season": 5, "episode": 9, "scene": 19, "line": "Now everyone, bear in mind once again that whatever Hank says goes. He is an impartial third party.", "order": 23842, "speaker": "Michael Scott"},
  {"season": 5, "episode": 9, "scene": 19, "line": "On the one hand, this copier is very old. You should see some of the new copiers they have. You would not believe what they do.", "order": 23843, "speaker": "Hank Tate"},
  {"season": 5, "episode": 9, "scene": 19, "line": "So, the copier.", "order": 23844, "speaker": "Michael Scott"},
  {"season": 5, "episode": 9, "scene": 19, "line": "Well, let me finish.", "order": 23845, "speaker": "Hank Tate"},
  {"season": 5, "episode": 9, "scene": 19, "line": "All right, yes.", "order": 23846, "speaker": "Michael Scott"},
  {"season": 5, "episode": 9, "scene": 19, "line": "Now the chairs. The chairs are very weak. Very weak chairs. I could not sit all day in this chair.", "order": 23847, "speaker": "Hank Tate"},
  {"season": 5, "episode": 9, "scene": 19, "line": "Well, what should I do?", "order": 23848, "speaker": "Michael Scott"},
  {"season": 5, "episode": 9, "scene": 19, "line": "...Let me see the copier again.", "order": 23849, "speaker": "Hank Tate"},
  {"season": 5, "episode": 9, "scene": 19, "line": "All right, get out. Get out.", "order": 23850, "speaker": "Michael Scott"},
  {"season": 5, "episode": 9, "scene": 20, "line": "Dwight, I thought I knew what I wanted. And then, being here with you and the German Mennonite minister... it just all felt right. I made a mistake picking Andy.", "order": 23851, "speaker": "Angela Martin"},
  {"season": 5, "episode": 9, "scene": 20, "line": "I know you did. And that's why I have taken care of everything.", "order": 23852, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 9, "scene": 20, "line": "What do you mean?", "order": 23853, "speaker": "Angela Martin"},
  {"season": 5, "episode": 9, "scene": 20, "line": "Well Monkey, he's a real minister. And you said, 'I do.' And I said, 'I do.' And Andy wasn't signing a receipt; he was signing our marriage certificate as a witness.", "order": 23854, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 9, "scene": 20, "line": "Dwight! That doesn't count!", "order": 23855, "speaker": "Angela Martin"},
  {"season": 5, "episode": 9, "scene": 20, "line": "Yes, of course it does.", "order": 23856, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 9, "scene": 20, "line": "No, it doesn't!", "order": 23857, "speaker": "Angela Martin"},
  {"season": 5, "episode": 9, "scene": 20, "line": "It does in the state of Pennsylvania.", "order": 23858, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 9, "scene": 20, "line": "I didn't-", "order": 23859, "speaker": "Angela Martin"},
  {"season": 5, "episode": 9, "scene": 20, "line": "Haha, Mrs. Schrute.", "order": 23860, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 9, "scene": 20, "line": "We are not married.", "order": 23861, "speaker": "Angela Martin"},
  {"season": 5, "episode": 9, "scene": 20, "line": "Wha...", "order": 23862, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 9, "scene": 20, "line": "Take this thing. [takes off twine ring]", "order": 23863, "speaker": "Angela Martin"},
  {"season": 5, "episode": 9, "scene": 20, "line": "My... It's not my fault you don't understand German; I've been telling you to take it for years!", "order": 23864, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 9, "scene": 20, "line": "Are we, uh... are we leaving or what? [Mose hits Andy in the head with a deflated ball] Ow!", "order": 23865, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 9, "scene": 21, "line": "[on speakerphone] Michael.", "order": 23866, "speaker": "David Wallace"},
  {"season": 5, "episode": 9, "scene": 21, "line": "Hey David- [begins coughing violently] I'm sorry. I'm sorry. I'm eating tiramisu. Some of the chocolate powder just went down my throat. I'm stopping now.", "order": 23867, "speaker": "Michael Scott"},
  {"season": 5, "episode": 9, "scene": 21, "line": "Is this why you're calling me?", "order": 23868, "speaker": "David Wallace"},
  {"season": 5, "episode": 9, "scene": 21, "line": "No, no, no, no, no. No. I'm calling- [coughs again] I'm sorry.", "order": 23869, "speaker": "Michael Scott"},
  {"season": 5, "episode": 9, "scene": 21, "line": "Okay.", "order": 23870, "speaker": "David Wallace"},
  {"season": 5, "episode": 9, "scene": 21, "line": "Mm. I'm calling because, um, we have a stupid budget surplus, and people-everybody wants something different.", "order": 23871, "speaker": "Michael Scott"},
  {"season": 5, "episode": 9, "scene": 21, "line": "You want me to weigh in on a minor budget issue?", "order": 23872, "speaker": "David Wallace"},
  {"season": 5, "episode": 9, "scene": 21, "line": "No, no, no. I want you to make the decision so I'm not the bad guy.", "order": 23873, "speaker": "Michael Scott"},
  {"season": 5, "episode": 9, "scene": 21, "line": "Well if I were you, I would just return the surplus and take the bonus.", "order": 23874, "speaker": "David Wallace"},
  {"season": 5, "episode": 9, "scene": 21, "line": "The what now?", "order": 23875, "speaker": "Michael Scott"},
  {"season": 5, "episode": 9, "scene": 21, "line": "Branch managers who come in under budget get 15% of the savings.", "order": 23876, "speaker": "David Wallace"},
  {"season": 5, "episode": 9, "scene": 21, "line": "...Like a tip? [calculates amount] $645?!", "order": 23877, "speaker": "Michael Scott"},
  {"season": 5, "episode": 9, "scene": 22, "line": "I hate disappointing just one person. And I really hate disappointing everyone. But I love Burlington Coat Factory. You go in there with $645, you are literally a king.", "order": 23878, "speaker": "Michael Scott"},
  {"season": 5, "episode": 9, "scene": 23, "line": "[on the phone] We have that going out Tuesday... Okay? Sure, no, I can... I'll double-check that for you.", "order": 23879, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 9, "scene": 23, "line": "All right, attention everyone.", "order": 23880, "speaker": "Michael Scott"},
  {"season": 5, "episode": 9, "scene": 23, "line": "Let me call you right back. Okay.", "order": 23881, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 9, "scene": 23, "line": "I have made my decision. We do not need a new copier. We do not need new chairs. This copier... is... [puts paper on glass and makes a copy, then takes paper from glass] working perfectly.", "order": 23882, "speaker": "Michael Scott"},
  {"season": 5, "episode": 9, "scene": 23, "line": "That's the original.", "order": 23883, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 9, "scene": 23, "line": "Pam, would you stand up for a sec? [sits in Pam's chair] Hmm. See how relaxed I am? I like this chair. [slowly sinking into the chair] Offers good support, it is urkelnomically correct... it's a good chair. I think we're spoiled because we don't appreciate the things that we have. You think kids in Africa have chairs? No. They sit in big piles of garbage. You think they have copiers? [the chair has slowly lowered itself almost to where Michael is not visible over the desk] They don't have copiers. They don't even- [struggles to get up from Pam's chair] Gah! They don't even have paper. And we are spoiled because we throw out perfectly good tiramisu because it has a little tiny hair on it. My point I this: I have seen the light in terms of what we need, and it is nothing.", "order": 23884, "speaker": "Michael Scott"},
  {"season": 5, "episode": 9, "scene": 23, "line": "Do you know?", "order": 23885, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 9, "scene": 23, "line": "Do I know what?", "order": 23886, "speaker": "Michael Scott"},
  {"season": 5, "episode": 9, "scene": 23, "line": "I think you know.", "order": 23887, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 9, "scene": 23, "line": "Mm, no...", "order": 23888, "speaker": "Michael Scott"},
  {"season": 5, "episode": 9, "scene": 23, "line": "Know what?", "order": 23889, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 9, "scene": 23, "line": "Yeah, know what?", "order": 23890, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 9, "scene": 23, "line": "Does anyone happen to know what 15% of 4300 is?", "order": 23891, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 9, "scene": 23, "line": "645", "order": 23892, "speaker": "Michael Scott"},
  {"season": 5, "episode": 9, "scene": 23, "line": "Michael's a genius.", "order": 23893, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 9, "scene": 23, "line": "Right.", "order": 23894, "speaker": "Michael Scott"},
  {"season": 5, "episode": 9, "scene": 23, "line": "Why'd you say dollars?", "order": 23895, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 9, "scene": 23, "line": "Because that is how my mind works.", "order": 23896, "speaker": "Michael Scott"},
  {"season": 5, "episode": 9, "scene": 23, "line": "What's 15% of 200? [Michael doesn't answer] Thank you. Everyone, Michael is returning the surplus so he can keep 15% as a bonus.", "order": 23897, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 9, "scene": 23, "line": "Wait, what?", "order": 23898, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 9, "scene": 23, "line": "You can do that?", "order": 23899, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 9, "scene": 23, "line": "Hey Michael, what's 394 times 5,912?", "order": 23900, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 9, "scene": 23, "line": "Let's see...", "order": 23901, "speaker": "Michael Scott"},
  {"season": 5, "episode": 9, "scene": 23, "line": "You're gonna give yourself a bonus of $645 instead of getting the entire office something it really needs?", "order": 23902, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 9, "scene": 23, "line": "I don't need $645; I already have $645, more or less.", "order": 23903, "speaker": "Michael Scott"},
  {"season": 5, "episode": 9, "scene": 23, "line": "You're gonna get us a copier then?", "order": 23904, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 9, "scene": 23, "line": "This is so stupid.", "order": 23905, "speaker": "Michael Scott"},
  {"season": 5, "episode": 9, "scene": 23, "line": "Or chairs?", "order": 23906, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 9, "scene": 23, "line": "This is so, so stupid. And, God... [looks at his watch] that's my phone.", "order": 23907, "speaker": "Michael Scott"},
  {"season": 5, "episode": 9, "scene": 23, "line": "I didn't hear a phone.", "order": 23908, "speaker": "Stanley Hudson"},
  {"season": 5, "episode": 9, "scene": 23, "line": "To be continued!", "order": 23909, "speaker": "Michael Scott"},
  {"season": 5, "episode": 9, "scene": 24, "line": "No, I don't-this doesn't change anything. I have a very important decision to make. We need a new copier. We need new chairs. And I need to figure out a way to keep this money without having everybody hate me.", "order": 23910, "speaker": "Michael Scott"},
  {"season": 5, "episode": 9, "scene": 25, "line": "Hey Tuna, check it out. Tuna sandwich. Just like you. [Angela walks over to Andy] What-", "order": 23911, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 9, "scene": 25, "line": "[kisses Andy passionately in his chair] Now, I have to take care of a legal issue.", "order": 23912, "speaker": "Angela Martin"},
  {"season": 5, "episode": 9, "scene": 26, "line": "Was that hot or what?", "order": 23913, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 9, "scene": 27, "line": "So what's it gonna be?", "order": 23914, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 9, "scene": 27, "line": "Michael, you have to make a decision. The day is almost done.", "order": 23915, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 9, "scene": 27, "line": "Let me just say, you've been promising me this chair since the day you hired me.", "order": 23916, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 9, "scene": 27, "line": "You are a smart guy. I know you'll do the right thing.", "order": 23917, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 9, "scene": 27, "line": "[groans in frustration] You think it's easy?", "order": 23918, "speaker": "Michael Scott"},
  {"season": 5, "episode": 9, "scene": 27, "line": "It's your job.", "order": 23919, "speaker": "Stanley Hudson"},
  {"season": 5, "episode": 9, "scene": 27, "line": "Okay, you know what? Why don't you guys deal with it? I am going to get up, and I am going to be out in the common area. But you need to decide; otherwise I'm taking the bonus. All right?", "order": 23920, "speaker": "Michael Scott"},
  {"season": 5, "episode": 9, "scene": 28, "line": "It is a classic management tactic. You have two sides, a deadline, you know that neither of them are gonna concede. What you do is you put 'em in a room, and you just- [Pam and Oscar come to the door of kitchen] Hey.", "order": 23921, "speaker": "Michael Scott"},
  {"season": 5, "episode": 9, "scene": 28, "line": "Hey.", "order": 23922, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 9, "scene": 28, "line": "Hey, we're going with the chairs.", "order": 23923, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 9, "scene": 28, "line": "What?", "order": 23924, "speaker": "Michael Scott"},
  {"season": 5, "episode": 9, "scene": 28, "line": "I just figured I'd rather have new chairs than nothing at all.", "order": 23925, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 9, "scene": 28, "line": "Thanks Michael.", "order": 23926, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 9, "scene": 28, "line": "Good work. I'm proud of you. [Pam and Oscar leave] Mother-", "order": 23927, "speaker": "Michael Scott"},
  {"season": 5, "episode": 9, "scene": 29, "line": "[wearing a fur coat] What did we learn this week? Well, one, thanks to me, my team is much, much faster at coming to decisions than I thought they would be. Number two, never buy a fur coat with a credit card until you absolutely have the money to pay for it. And three, [camera zooms out to see fake blood is splattered on the fur coat] you should know that some people think it's cool to throw buckets of fake blood on you as you are walking out of Burlington Coat Factory.", "order": 23928, "speaker": "Michael Scott"},
  {"season": 5, "episode": 9, "scene": 30, "line": "Truce?", "order": 23929, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 9, "scene": 30, "line": "Yeah, I guess, since I won.", "order": 23930, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 9, "scene": 30, "line": "Yeah, you did win. You did win. Anyway, I'm going to need three copies of each of these, stapled and collated. [begins to walk away, then turns back] Totally kidding. [leans in, whispers] I'm gonna need four. [kisses Pam]", "order": 23931, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 10, "scene": 1, "line": "What is this?", "order": 23932, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 10, "scene": 1, "line": "Happy Holidays, Dwight. But do not open it till Christmas.", "order": 23933, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 10, "scene": 1, "line": "You're so pathetic. How long did this take you? Three hours?", "order": 23934, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 10, "scene": 1, "line": "Five minutes actually. I am a black belt in gift wrapping.", "order": 23935, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 10, "scene": 1, "line": "Yeah, no such thing. They don't give out black belts for things that are stupid. [scoffs] Well, I hope it was worth it, cause I'm gonna take it apart in about five minutes.", "order": 23936, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 10, "scene": 1, "line": "I think it'll take you a little longer than that.", "order": 23937, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 10, "scene": 1, "line": "Really? If I can skin a mule deer in less than ten minutes, I ought to be able to cut my -- [sets briefcase on desk and sits in chair, desk and chair are made of paper, Dwight crashes to the floor]", "order": 23938, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 10, "scene": 2, "line": "Eh...", "order": 23939, "speaker": "Stanley Hudson"},
  {"season": 5, "episode": 10, "scene": 2, "line": "I'm sorry. It's the largest one I have.", "order": 23940, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 10, "scene": 2, "line": "I will not be the big guy in the tiny hat. [Kevin is wearing the same hat]", "order": 23941, "speaker": "Stanley Hudson"},
  {"season": 5, "episode": 10, "scene": 3, "line": "This is the first Christmas party I am throwing as head of the party planning committee. The theme is 'Nights in Morocco'. This isn't your grandmothers Christmas party. Unless of course she's from Morocco, in which case it's very accurate.", "order": 23942, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 10, "scene": 4, "line": "Hey, Phyllis, do I need this invite to get in to the party?", "order": 23943, "speaker": "Michael Scott"},
  {"season": 5, "episode": 10, "scene": 4, "line": "Yes.", "order": 23944, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 10, "scene": 4, "line": "Awesome. I think this is going to be the best Christmas party ever.", "order": 23945, "speaker": "Michael Scott"},
  {"season": 5, "episode": 10, "scene": 5, "line": "Angela. You're going to move this for the party right? It's not on theme.", "order": 23946, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 10, "scene": 5, "line": "It's the nativity scene.", "order": 23947, "speaker": "Angela Martin"},
  {"season": 5, "episode": 10, "scene": 5, "line": "Alright. You can keep your camel, sheep, elephant and the north African king can stay. Everything else goes in the drawer.", "order": 23948, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 10, "scene": 6, "line": "I am not going to judge Phyllis for desecrating Christmas. There is one person who will though and Phyllis just stuffed him into a drawer.", "order": 23949, "speaker": "Angela Martin"},
  {"season": 5, "episode": 10, "scene": 7, "line": "I need you to get rid of the tree.", "order": 23950, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 10, "scene": 7, "line": "But--", "order": 23951, "speaker": "Angela Martin"},
  {"season": 5, "episode": 10, "scene": 7, "line": "Thank you.", "order": 23952, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 10, "scene": 8, "line": "Oh, I don't think it's blackmail. Angela just does what I ask her to do so I won't tell everyone that she's cheating on Andy with Dwight. I think for it to be black mail, it would have to be a formal letter.", "order": 23953, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 10, "scene": 9, "line": "Ah. You brought in your doll collection.", "order": 23954, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 10, "scene": 9, "line": "These are not dolls, Jim. These are commodities. Same as gold or oil.", "order": 23955, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 10, "scene": 10, "line": "Every year I do research to determine which toy will be the most popular of the Christmas season. This year it's a doll. Half girl, half unicorn. Catchphrase: 'My horn can pierce the sky'. Pathetic. I bought out every store in the area over the last couple of weeks. And as lazy parents become desperate I will sell them for an enormous profit. Isn't that right princess? [makes doll nod]", "order": 23956, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 10, "scene": 11, "line": "That's the Christmas spirit.", "order": 23957, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 10, "scene": 11, "line": "I am simply punishing those parents that would wait till the last minute to get their child a gift. And such a genetically improbable one. Look at that. How does that happen? King has sex with a unicorn? Man with a horn has sex with a royal horse?", "order": 23958, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 10, "scene": 11, "line": "Yes.", "order": 23959, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 10, "scene": 11, "line": "Oh, hey, is that Princess Unicorn? I thought they were all sold out.", "order": 23960, "speaker": "Michael Scott"},
  {"season": 5, "episode": 10, "scene": 11, "line": "They are now.", "order": 23961, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 10, "scene": 11, "line": "Cool. [sings] My horn can pierce the sky.", "order": 23962, "speaker": "Michael Scott"},
  {"season": 5, "episode": 10, "scene": 12, "line": "This is equal parts scotch, absinthe, rum, gin, vermouth, triple sec, and two packs of Splenda. Call it a one of everything.", "order": 23963, "speaker": "Michael Scott"},
  {"season": 5, "episode": 10, "scene": 12, "line": "[drinks] Oh... my... GOD!!! [laughs] Hit me again.", "order": 23964, "speaker": "Meredith Palmer"},
  {"season": 5, "episode": 10, "scene": 12, "line": "Alright... One more time around the block.", "order": 23965, "speaker": "Michael Scott"},
  {"season": 5, "episode": 10, "scene": 13, "line": "I will grant you one wish.", "order": 23966, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 10, "scene": 13, "line": "I wish that you'd stop rubbing that lamp in that creepy way.", "order": 23967, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 10, "scene": 13, "line": "Stupid. Everyone knows to ask for a hundred more wishes. [Pam laughs] Dumb.", "order": 23968, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 10, "scene": 13, "line": "[sitting on floor playing the sitar] Hey, hey... Ange... check it out. [sings] There's a place in France where the naked ladies dance. [laughs]", "order": 23969, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 10, "scene": 13, "line": "Really Andy? It's Christmas and your singing about nudity and France.", "order": 23970, "speaker": "Angela Martin"},
  {"season": 5, "episode": 10, "scene": 13, "line": "[sings] There's a hole in the wall where the men can see it all.", "order": 23971, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 10, "scene": 14, "line": "Jim, Jim....", "order": 23972, "speaker": "Michael Scott"},
  {"season": 5, "episode": 10, "scene": 14, "line": "What is it?", "order": 23973, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 10, "scene": 14, "line": "That is vodka and I mixed it with orange juice. I call it an orange-vod-juice...ka.", "order": 23974, "speaker": "Michael Scott"},
  {"season": 5, "episode": 10, "scene": 14, "line": "Wow, that is delicious.", "order": 23975, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 10, "scene": 14, "line": "Yeah.", "order": 23976, "speaker": "Michael Scott"},
  {"season": 5, "episode": 10, "scene": 14, "line": "Can't believe no ones thought of that.", "order": 23977, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 10, "scene": 14, "line": "I know.", "order": 23978, "speaker": "Michael Scott"},
  {"season": 5, "episode": 10, "scene": 15, "line": "[dancing drunkenly] Ahh yeah!!", "order": 23979, "speaker": "Meredith Palmer"},
  {"season": 5, "episode": 10, "scene": 16, "line": "[continuing to dance, lifting her shirt] Belly dancing!", "order": 23980, "speaker": "Meredith Palmer"},
  {"season": 5, "episode": 10, "scene": 17, "line": "120, 180... 200.", "order": 23981, "speaker": "Guy buying doll"},
  {"season": 5, "episode": 10, "scene": 17, "line": "All right.", "order": 23982, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 10, "scene": 17, "line": "Thank you so much. My daughter is going to love this.", "order": 23983, "speaker": "Guy"},
  {"season": 5, "episode": 10, "scene": 17, "line": "Oh, so glad I could help.", "order": 23984, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 10, "scene": 17, "line": "Thanks.", "order": 23985, "speaker": "Guy"},
  {"season": 5, "episode": 10, "scene": 17, "line": "Merry Christmas.", "order": 23986, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 10, "scene": 17, "line": "You too.", "order": 23987, "speaker": "Guy"},
  {"season": 5, "episode": 10, "scene": 18, "line": "Fa-la-la-la-la-la-la-ka-ching.", "order": 23988, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 10, "scene": 19, "line": "[sighs] Oh, so this is what every day would be like if you hadn't left India.", "order": 23989, "speaker": "Michael Scott"},
  {"season": 5, "episode": 10, "scene": 20, "line": "[Meredith's hair is on fire] Oh, oh, oh, oh!", "order": 23990, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 10, "scene": 20, "line": "We got a live one! On fire!! [Dwight extinguishes Meredith]", "order": 23991, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 10, "scene": 21, "line": "I'm all right. I'm all right.", "order": 23992, "speaker": "Meredith Palmer"},
  {"season": 5, "episode": 10, "scene": 22, "line": "Sorry.", "order": 23993, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 10, "scene": 23, "line": "We're back on the 5th, should we just do it then?", "order": 23994, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 10, "scene": 23, "line": "Can not do it then. Monthly dental appointment, soft teeth", "order": 23995, "speaker": "Michael Scott"},
  {"season": 5, "episode": 10, "scene": 23, "line": "What about February 2nd?", "order": 23996, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 10, "scene": 23, "line": "Ah... would you want to do it on Groundhogs day?", "order": 23997, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 10, "scene": 23, "line": "No, no. I celebrate privately.", "order": 23998, "speaker": "Michael Scott"},
  {"season": 5, "episode": 10, "scene": 23, "line": "That's cool.", "order": 23999, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 10, "scene": 23, "line": "Why don't we just do it now? We'll do it quickly.", "order": 24000, "speaker": "Michael Scott"},
  {"season": 5, "episode": 10, "scene": 23, "line": "Now? It's our Christmas party.", "order": 24001, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 10, "scene": 23, "line": "We'll do it quickly.", "order": 24002, "speaker": "Michael Scott"},
  {"season": 5, "episode": 10, "scene": 23, "line": "Well what if we can't do it quickly.", "order": 24003, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 10, "scene": 23, "line": "[mimicking] What if we can't do it quickly? What if we cant do it--", "order": 24004, "speaker": "Michael Scott"},
  {"season": 5, "episode": 10, "scene": 23, "line": "Do you know how to do an intervention?", "order": 24005, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 10, "scene": 23, "line": "Hey, shut up. Here we go. Everybody gather up.", "order": 24006, "speaker": "Michael Scott"},
  {"season": 5, "episode": 10, "scene": 24, "line": "An intervention. It's sort of hard to describe, but really its-- it's a coming together... it's a surprise party for people who are-- who have addictions. And you get in their face and you scream at them and you make them feel really badly about themselves. And then they stop.", "order": 24007, "speaker": "Michael Scott"},
  {"season": 5, "episode": 10, "scene": 25, "line": "It looks like we're going to be here for awhile, so why don't you make a little plate of hummus for everyone. Little triangles of pita, toasted on both sides, fanned so you can easily can grab them.", "order": 24008, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 10, "scene": 25, "line": "I don't--", "order": 24009, "speaker": "Angela Martin"},
  {"season": 5, "episode": 10, "scene": 25, "line": "And napkins... fanned.", "order": 24010, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 10, "scene": 26, "line": "Okay, how do you feel?", "order": 24011, "speaker": "Michael Scott"},
  {"season": 5, "episode": 10, "scene": 26, "line": "A little better. I threw up.", "order": 24012, "speaker": "Meredith Palmer"},
  {"season": 5, "episode": 10, "scene": 26, "line": "Uck... T.M.I.", "order": 24013, "speaker": "Michael Scott"},
  {"season": 5, "episode": 10, "scene": 26, "line": "Fire girl. [looks around] Too soon?", "order": 24014, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 10, "scene": 26, "line": "Yeah.", "order": 24015, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 10, "scene": 26, "line": "Okay, you know what I thought we should do is have a quick intervention and then get back to the party.", "order": 24016, "speaker": "Michael Scott"},
  {"season": 5, "episode": 10, "scene": 26, "line": "Michael, we are only allowed to talk about Meredith's work performance. We can not ask her to stop drinking.", "order": 24017, "speaker": "Toby Flenderson"},
  {"season": 5, "episode": 10, "scene": 26, "line": "I am not asking her to stop drinking. I am imploring her to quit being an alcoholic.", "order": 24018, "speaker": "Michael Scott"},
  {"season": 5, "episode": 10, "scene": 26, "line": "I'm not an alcoholic.", "order": 24019, "speaker": "Meredith Palmer"},
  {"season": 5, "episode": 10, "scene": 26, "line": "Yeah, obviously you are. Okay, everybody who thinks that Meredith is an alcoholic please raise your hand. [everyone raises their hand]", "order": 24020, "speaker": "Michael Scott"},
  {"season": 5, "episode": 10, "scene": 26, "line": "Aye.", "order": 24021, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 10, "scene": 26, "line": "The aye's have it.", "order": 24022, "speaker": "Michael Scott"},
  {"season": 5, "episode": 10, "scene": 26, "line": "I don't care what everyone thinks. I know I am not an alcoholic.", "order": 24023, "speaker": "Meredith Palmer"},
  {"season": 5, "episode": 10, "scene": 26, "line": "Alright, well, let's look at this a little bit closer then shall we? [pulls a piece of paper out of his pocket]", "order": 24024, "speaker": "Michael Scott"},
  {"season": 5, "episode": 10, "scene": 27, "line": "When I was in college I used to get wicked hammered. My nickname was puke. I would chug a fifth of socos, sneak into a frat party, polish off a few people's empties, some brewskies, some Jell-O shots, do some body shots off myself, pass out, wake up the next morning, puke, rally, more soco, head to class. Probably would have gotten expelled if I had let it affect my grades, but I aced all my courses. They called me Ace. It was totally awesome. Got straight B's. They called me Buzz.", "order": 24025, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 10, "scene": 28, "line": "Meredith, have you ever used alcohol to alter your mood or deliberately change your state of mind?", "order": 24026, "speaker": "Michael Scott"},
  {"season": 5, "episode": 10, "scene": 28, "line": "Sure.", "order": 24027, "speaker": "Meredith Palmer"},
  {"season": 5, "episode": 10, "scene": 28, "line": "Do you sometimes have a drink to celebrate a special occasion or mark a holiday?", "order": 24028, "speaker": "Michael Scott"},
  {"season": 5, "episode": 10, "scene": 28, "line": "Obviously.", "order": 24029, "speaker": "Meredith Palmer"},
  {"season": 5, "episode": 10, "scene": 28, "line": "Have you ever under the influence of alcohol questioned the teachings of the Mormon church?", "order": 24030, "speaker": "Michael Scott"},
  {"season": 5, "episode": 10, "scene": 28, "line": "Where did you get this?", "order": 24031, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 10, "scene": 28, "line": "I got it on a website, that's not important.", "order": 24032, "speaker": "Michael Scott"},
  {"season": 5, "episode": 10, "scene": 28, "line": "Michael, We should contact some experts. You don't know what your doing.", "order": 24033, "speaker": "Toby Flenderson"},
  {"season": 5, "episode": 10, "scene": 28, "line": "Okay, you know what Toby? One of my employees is undergoing a crisis and I wish for just once you would take my side on this. I'm doing your job man. Hey, are you texting?", "order": 24034, "speaker": "Michael Scott"},
  {"season": 5, "episode": 10, "scene": 28, "line": "Yeah, 'cause this is kind of a drag.", "order": 24035, "speaker": "Kelly Kapoor"},
  {"season": 5, "episode": 10, "scene": 28, "line": "Alcohol is a drag. Yes. Here's what I'd like to do. I'd like to go around the room and have us each express to Meredith how her alcoholism has affected us. I'll begin. This Christmas party is perhaps the best Christmas party I have ever been to, but then you got so drunk that we had to stop and do this. That's how your drinking affected me. Anybody else? Another time when Meredith's drinking affected you? Come on people. If we don't say anything she's not going to get any better. [Kevin raises his hand] Yes, Kevin.", "order": 24036, "speaker": "Michael Scott"},
  {"season": 5, "episode": 10, "scene": 28, "line": "Well, Meredith, there was that one time you bought movie tickets and then you got too drunk to go, so you gave them to me. And that was really cool.", "order": 24037, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 10, "scene": 28, "line": "That's-- you didn't-- you weren't hurt by that.", "order": 24038, "speaker": "Michael Scott"},
  {"season": 5, "episode": 10, "scene": 28, "line": "Yeah, you said affected by it. Thanks again, Meredith.", "order": 24039, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 10, "scene": 28, "line": "You're welcome.", "order": 24040, "speaker": "Meredith Palmer"},
  {"season": 5, "episode": 10, "scene": 28, "line": "Okay, no, that's not what we're going for. Who has a problem with Meredith's drinking? It has to bother you. It bothers me. Right? How does it bother you? Dwight, don't you have anything?", "order": 24041, "speaker": "Michael Scott"},
  {"season": 5, "episode": 10, "scene": 28, "line": "No, I like Meredith.", "order": 24042, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 10, "scene": 29, "line": "Actually, I don't care for Meredith, but I don't believe in this kind of thing. In the Schrute family we believe in a five fingered intervention. [holds up his fist] Awareness, education, control, acceptance and punching.", "order": 24043, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 10, "scene": 30, "line": "Who is going to tell us the latest dirty joke? Who is going to tell us what you watched on television last night?", "order": 24044, "speaker": "Michael Scott"},
  {"season": 5, "episode": 10, "scene": 30, "line": "I am fine.", "order": 24045, "speaker": "Meredith Palmer"},
  {"season": 5, "episode": 10, "scene": 30, "line": "Was John Belushi fine? Was Bob Hope fine? Hey-- come here. If anything ever happened to you, I would be very angry at myself for not doing all that I could do.", "order": 24046, "speaker": "Michael Scott"},
  {"season": 5, "episode": 10, "scene": 30, "line": "I know I drink. I like to party", "order": 24047, "speaker": "Meredith Palmer"},
  {"season": 5, "episode": 10, "scene": 30, "line": "I want you to say I'm an alcoholic.", "order": 24048, "speaker": "Michael Scott"},
  {"season": 5, "episode": 10, "scene": 30, "line": "I am not an alcoholic!", "order": 24049, "speaker": "Meredith Palmer"},
  {"season": 5, "episode": 10, "scene": 30, "line": "You can say it as loudly as you want, but we're not going to believe you.", "order": 24050, "speaker": "Michael Scott"},
  {"season": 5, "episode": 10, "scene": 31, "line": "I was waiting until later to hand out this years gifts from corporate. [holds up shot glass] I don't think they're appropriate anymore.", "order": 24051, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 10, "scene": 32, "line": "Please stop making me do these things.", "order": 24052, "speaker": "Angela Martin"},
  {"season": 5, "episode": 10, "scene": 32, "line": "Oh, sorry, it's your job.", "order": 24053, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 10, "scene": 32, "line": "But it's the season of mercy.", "order": 24054, "speaker": "Angela Martin"},
  {"season": 5, "episode": 10, "scene": 32, "line": "You never showed me mercy when you were in charge. Why aren't you wearing the hairnet?", "order": 24055, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 10, "scene": 32, "line": "I lost it. [Phyllis glares at her until Angela pulls out the hairnet and puts it on]", "order": 24056, "speaker": "Angela Martin"},
  {"season": 5, "episode": 10, "scene": 32, "line": "Ok... nice.", "order": 24057, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 10, "scene": 33, "line": "I don't mind telling you that I have an addiction. I do. To porn.", "order": 24058, "speaker": "Meredith Palmer"},
  {"season": 5, "episode": 10, "scene": 33, "line": "[amid groans from the rest of the office] Alright, no, no, no. No. That is the image, I think we can all agree is very disgusting. But you know what, Meredith, you lit your hair on fire today. What about tomorrow.? What is going to happen when you come into work and you're dead?", "order": 24059, "speaker": "Michael Scott"},
  {"season": 5, "episode": 10, "scene": 33, "line": "I stab her in the brain with a wooden stick.", "order": 24060, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 10, "scene": 34, "line": "There are several ways to kill a zombie, but the most satisfying one is to stab it in the brain with a wooden stick.", "order": 24061, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 10, "scene": 35, "line": "Everyone in this room loves you, but mark my words, we are not going to support your alcoholism anymore. The next time you light yourself on fire we are not going to help put you out.", "order": 24062, "speaker": "Michael Scott"},
  {"season": 5, "episode": 10, "scene": 35, "line": "Oh, as fire marshal I would have to.", "order": 24063, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 10, "scene": 35, "line": "Dwight--", "order": 24064, "speaker": "Michael Scott"},
  {"season": 5, "episode": 10, "scene": 35, "line": "She is a hazard to the other people of the office.", "order": 24065, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 10, "scene": 35, "line": "[sighs]...okay.", "order": 24066, "speaker": "Michael Scott"},
  {"season": 5, "episode": 10, "scene": 35, "line": "I suppose I could do it if it was a controlled burn in a well ventilated area.", "order": 24067, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 10, "scene": 35, "line": "Yeah, but you're gonna need a permit for that.", "order": 24068, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 10, "scene": 35, "line": "Oh right, that'll take a couple of weeks.", "order": 24069, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 10, "scene": 35, "line": "I can get you one in an hour.", "order": 24070, "speaker": "Creed Bratton"},
  {"season": 5, "episode": 10, "scene": 35, "line": "Really?", "order": 24071, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 10, "scene": 35, "line": "Okay you know, this-- this is over.", "order": 24072, "speaker": "Toby Flenderson"},
  {"season": 5, "episode": 10, "scene": 35, "line": "I agree.", "order": 24073, "speaker": "Stanley Hudson"},
  {"season": 5, "episode": 10, "scene": 35, "line": "Michael, I think... you did the best you could, but this is bigger than all of us.", "order": 24074, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 10, "scene": 35, "line": "Enabler! Enabler, enabler, enabler, enabler, enabler. It's Christmas and we are turning our back on somebody who is asking for help.", "order": 24075, "speaker": "Michael Scott"},
  {"season": 5, "episode": 10, "scene": 36, "line": "You know what the only thing I want for Christmas? I want Meredith to get better. That's my only wish. But you know what? My wishes never come true, so I'm not going to wish that on her. I-- a watch would be nice.", "order": 24076, "speaker": "Michael Scott"},
  {"season": 5, "episode": 10, "scene": 37, "line": "[watching Meredith and Michael talk in his office] They've been in there for 45 minutes.", "order": 24077, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 10, "scene": 37, "line": "I know. If she wasn't an alcoholic before, she is now. [Jim laughs]", "order": 24078, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 10, "scene": 38, "line": "That's a halwa shabkia cookie. They serve it during Ramadan. [Angela spits her cookie out]", "order": 24079, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 10, "scene": 38, "line": "Mmmm... chewy.", "order": 24080, "speaker": "Stanley Hudson"},
  {"season": 5, "episode": 10, "scene": 39, "line": "[Andy playing the sitar] Hey.", "order": 24081, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 10, "scene": 39, "line": "What's up?", "order": 24082, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 10, "scene": 39, "line": "Do you take requests?", "order": 24083, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 10, "scene": 39, "line": "Sure.", "order": 24084, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 10, "scene": 39, "line": "Please stop. People are having a Christmas party.", "order": 24085, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 10, "scene": 40, "line": "[whispers to Meredith] I'll be down in a minute. [walks over to Toby] Could you write down the number of that rehabilitation center that you mentioned?", "order": 24086, "speaker": "Michael Scott"},
  {"season": 5, "episode": 10, "scene": 40, "line": "Sure. Wow. [Michael throws a pen at his head] Ow...", "order": 24087, "speaker": "Toby Flenderson"},
  {"season": 5, "episode": 10, "scene": 41, "line": "We just missed Poor Richards.", "order": 24088, "speaker": "Meredith Palmer"},
  {"season": 5, "episode": 10, "scene": 41, "line": "We did?", "order": 24089, "speaker": "Michael Scott"},
  {"season": 5, "episode": 10, "scene": 41, "line": "Yeah, I thought we were going out for a drink?", "order": 24090, "speaker": "Meredith Palmer"},
  {"season": 5, "episode": 10, "scene": 41, "line": "Oh, shoot. Oh well, we'll have to go someplace else then I guess.", "order": 24091, "speaker": "Michael Scott"},
  {"season": 5, "episode": 10, "scene": 41, "line": "The Bog? Cooper's, Kelly's...", "order": 24092, "speaker": "Meredith Palmer"},
  {"season": 5, "episode": 10, "scene": 41, "line": "We could go there, sure.", "order": 24093, "speaker": "Michael Scott"},
  {"season": 5, "episode": 10, "scene": 41, "line": "...Brixx's, Carmen's...", "order": 24094, "speaker": "Meredith Palmer"},
  {"season": 5, "episode": 10, "scene": 41, "line": "Yes, yes.", "order": 24095, "speaker": "Michael Scott"},
  {"season": 5, "episode": 10, "scene": 41, "line": "...The Fort, Andy Gavin's.", "order": 24096, "speaker": "Meredith Palmer"},
  {"season": 5, "episode": 10, "scene": 41, "line": "I have a new place.", "order": 24097, "speaker": "Michael Scott"},
  {"season": 5, "episode": 10, "scene": 41, "line": "Well, it must have just opened up.", "order": 24098, "speaker": "Meredith Palmer"},
  {"season": 5, "episode": 10, "scene": 41, "line": "It-- yep, recently.", "order": 24099, "speaker": "Michael Scott"},
  {"season": 5, "episode": 10, "scene": 41, "line": "Yeah... all right.", "order": 24100, "speaker": "Meredith Palmer"},
  {"season": 5, "episode": 10, "scene": 41, "line": "All right.", "order": 24101, "speaker": "Michael Scott"},
  {"season": 5, "episode": 10, "scene": 41, "line": "Enough of this Christmas crap. Let's get some party music. [changes radio station] Yeah!", "order": 24102, "speaker": "Meredith Palmer"},
  {"season": 5, "episode": 10, "scene": 41, "line": "Yeah, oh there you go.", "order": 24103, "speaker": "Michael Scott"},
  {"season": 5, "episode": 10, "scene": 41, "line": "Yeah!", "order": 24104, "speaker": "Meredith Palmer"},
  {"season": 5, "episode": 10, "scene": 41, "line": "That's good.", "order": 24105, "speaker": "Michael Scott"},
  {"season": 5, "episode": 10, "scene": 41, "line": "Yeah that's better.", "order": 24106, "speaker": "Meredith Palmer"},
  {"season": 5, "episode": 10, "scene": 41, "line": "We're party girls.", "order": 24107, "speaker": "Michael Scott"},
  {"season": 5, "episode": 10, "scene": 42, "line": "[on phone] Hey Sasha, it's daddy. Have you ever heard of this doll , Princess Unicorn?", "order": 24108, "speaker": "Toby Flenderson"},
  {"season": 5, "episode": 10, "scene": 42, "line": "[shouting excitedly over the phone] Daddy, daddy, daddy!!", "order": 24109, "speaker": "Sasha"},
  {"season": 5, "episode": 10, "scene": 42, "line": "No, No, No. No, I'm just curious if you've heard of it.", "order": 24110, "speaker": "Toby Flenderson"},
  {"season": 5, "episode": 10, "scene": 43, "line": "This is great. My ex-wife's going to be so pissed. [chuckles] For once daddy's gonna be a hero.", "order": 24111, "speaker": "Toby Flenderson"},
  {"season": 5, "episode": 10, "scene": 44, "line": "200", "order": 24112, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 10, "scene": 44, "line": "Yo.", "order": 24113, "speaker": "Darryl Philbin"},
  {"season": 5, "episode": 10, "scene": 44, "line": "Hey, I'd like to buy one of your dolls.", "order": 24114, "speaker": "Toby Flenderson"},
  {"season": 5, "episode": 10, "scene": 44, "line": "Oh, that's my last one.", "order": 24115, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 10, "scene": 44, "line": "Oh, no, I-- no, no, I was gonna buy that doll.", "order": 24116, "speaker": "Toby Flenderson"},
  {"season": 5, "episode": 10, "scene": 44, "line": "Thanks man.", "order": 24117, "speaker": "Darryl Philbin"},
  {"season": 5, "episode": 10, "scene": 44, "line": "I was-- I was gonna get the doll.", "order": 24118, "speaker": "Toby Flenderson"},
  {"season": 5, "episode": 10, "scene": 44, "line": "Not my problem.", "order": 24119, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 10, "scene": 44, "line": "But I-- I promised my daughter. Darryl, look-- I-- I need the doll, I need the doll. I-- I'm-- I'm begging you. I just-- I need it more than anything in this world. I need this doll. Darryl, man I need this doll.", "order": 24120, "speaker": "Toby Flenderson"},
  {"season": 5, "episode": 10, "scene": 44, "line": "All right man, don't cry. It's cool. I'll let you get it for $400.", "order": 24121, "speaker": "Darryl Philbin"},
  {"season": 5, "episode": 10, "scene": 44, "line": "I only have $200.", "order": 24122, "speaker": "Toby Flenderson"},
  {"season": 5, "episode": 10, "scene": 44, "line": "You can owe me.", "order": 24123, "speaker": "Darryl Philbin"},
  {"season": 5, "episode": 10, "scene": 44, "line": "[laughs] Oh man, thanks, thank you, [tearing up] thank you, thank you.", "order": 24124, "speaker": "Toby Flenderson"},
  {"season": 5, "episode": 10, "scene": 44, "line": "I know, right. Merry Christmas.", "order": 24125, "speaker": "Darryl Philbin"},
  {"season": 5, "episode": 10, "scene": 44, "line": "Oh thanks. She's gonna... [notices the doll is black] oh...", "order": 24126, "speaker": "Toby Flenderson"},
  {"season": 5, "episode": 10, "scene": 44, "line": "Something wrong with the doll?", "order": 24127, "speaker": "Darryl Philbin"},
  {"season": 5, "episode": 10, "scene": 44, "line": "No. It's even-- it's even better than the one I wanted.", "order": 24128, "speaker": "Toby Flenderson"},
  {"season": 5, "episode": 10, "scene": 45, "line": "Sunrise Rehab? No! No! No! I told you no! There is no way!", "order": 24129, "speaker": "Meredith Palmer"},
  {"season": 5, "episode": 10, "scene": 45, "line": "It's okay. It's all right.", "order": 24130, "speaker": "Michael Scott"},
  {"season": 5, "episode": 10, "scene": 45, "line": "No way! There is no way! No way!", "order": 24131, "speaker": "Meredith Palmer"},
  {"season": 5, "episode": 10, "scene": 45, "line": "Meredith, we are doing this for your own good. Okay. Come on--", "order": 24132, "speaker": "Michael Scott"},
  {"season": 5, "episode": 10, "scene": 45, "line": "No way! No way! There is no way!", "order": 24133, "speaker": "Meredith Palmer"},
  {"season": 5, "episode": 10, "scene": 45, "line": "Here we go. This is gonna be good.", "order": 24134, "speaker": "Michael Scott"},
  {"season": 5, "episode": 10, "scene": 45, "line": "No! No way! I told you. We talked about this. There is absolutely no way. No! No! There is no way! No! No! I am not going in there! I am not going in there!", "order": 24135, "speaker": "Meredith Palmer"},
  {"season": 5, "episode": 10, "scene": 45, "line": "Yes you are. [tries to pull Meredith from the car]", "order": 24136, "speaker": "Michael Scott"},
  {"season": 5, "episode": 10, "scene": 45, "line": "I am not going in there! NO!!", "order": 24137, "speaker": "Meredith Palmer"},
  {"season": 5, "episode": 10, "scene": 45, "line": "Wait a minute. Wait a minute. Wait a minute. Wait a minute. Wait a minute. Wait a minute. Wait a minute. Wait a minute. Wait a minute. Wait a minute. Just wait! Just wait!", "order": 24138, "speaker": "Meredith Palmer"},
  {"season": 5, "episode": 10, "scene": 45, "line": "Shhh... just calm down.", "order": 24139, "speaker": "Michael Scott"},
  {"season": 5, "episode": 10, "scene": 45, "line": "[runs away] Oh my God! Oh my God! Oh my God! No! No!", "order": 24140, "speaker": "Meredith Palmer"},
  {"season": 5, "episode": 10, "scene": 45, "line": "[chasing Meredith] Come on. Shh...", "order": 24141, "speaker": "Michael Scott"},
  {"season": 5, "episode": 10, "scene": 45, "line": "No! No! No! There is no way! There is no way!", "order": 24142, "speaker": "Meredith Palmer"},
  {"season": 5, "episode": 10, "scene": 45, "line": "Here's the door. Here's the door.", "order": 24143, "speaker": "Michael Scott"},
  {"season": 5, "episode": 10, "scene": 45, "line": "There is no way! There is no way! No! No!", "order": 24144, "speaker": "Meredith Palmer"},
  {"season": 5, "episode": 10, "scene": 45, "line": "[dragging Meredith on the floor into the rehab center] Let's start meeting-- Hello. How are you?", "order": 24145, "speaker": "Michael Scott"},
  {"season": 5, "episode": 10, "scene": 45, "line": "This man is crazy! This man is crazy!", "order": 24146, "speaker": "Meredith Palmer"},
  {"season": 5, "episode": 10, "scene": 45, "line": "Can I help you?", "order": 24147, "speaker": "Rehab Nurse"},
  {"season": 5, "episode": 10, "scene": 45, "line": "I have a deposit. Alcoholic.", "order": 24148, "speaker": "Michael Scott"},
  {"season": 5, "episode": 10, "scene": 45, "line": "No! No! No! No! No!", "order": 24149, "speaker": "Meredith Palmer"},
  {"season": 5, "episode": 10, "scene": 45, "line": "So do I sign?", "order": 24150, "speaker": "Michael Scott"},
  {"season": 5, "episode": 10, "scene": 45, "line": "NO!!!", "order": 24151, "speaker": "Meredith Palmer"},
  {"season": 5, "episode": 10, "scene": 46, "line": "I need you to put the Christmas tree back up.", "order": 24152, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 10, "scene": 46, "line": "It's outside.", "order": 24153, "speaker": "Angela Martin"},
  {"season": 5, "episode": 10, "scene": 46, "line": "I didn't ask you where it was. I told you where it needs to be.", "order": 24154, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 10, "scene": 46, "line": "Shut up.", "order": 24155, "speaker": "Angela Martin"},
  {"season": 5, "episode": 10, "scene": 46, "line": "Excuse me?", "order": 24156, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 10, "scene": 46, "line": "I'm not moving the tree. Face it. The only power you have over me is this big secret that I know you're not going to tell. And you want to know how I know that? Because then you won't be able to plan your stupid, tacky parties anymore. So you move the tree.", "order": 24157, "speaker": "Angela Martin"},
  {"season": 5, "episode": 10, "scene": 46, "line": "Okay. [starts to walk away, then turns around] Angela's having sex with Dwight. I caught them doing it after Toby's going away party.", "order": 24158, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 10, "scene": 46, "line": "Well don't look so surprised.", "order": 24159, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 10, "scene": 47, "line": "I knew it.", "order": 24160, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 10, "scene": 47, "line": "You did not know it.", "order": 24161, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 10, "scene": 47, "line": "I knew some of it.", "order": 24162, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 10, "scene": 47, "line": "Everyone knew some of it.", "order": 24163, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 10, "scene": 47, "line": "It's Christmas.", "order": 24164, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 10, "scene": 47, "line": "You knew it.", "order": 24165, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 10, "scene": 47, "line": "Thank you. I knew it.", "order": 24166, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 10, "scene": 47, "line": "She knew it.", "order": 24167, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 10, "scene": 48, "line": "As it turns out you can't just check someone into rehab against their will. They have to do it voluntarily. They have to hit rock bottom. So I think I know what I need to do at this point. I need to find ways to push Meredith to the bottom. Um... I think I can do it. I did it with Jan.", "order": 24168, "speaker": "Michael Scott"},
  {"season": 5, "episode": 10, "scene": 49, "line": "All right, everybody's still here. Perfect! Got a little surprise I've been working on. Angela, my bride, I just wanted you to know that for the rest of our lives, no matter the cultural milieu, I will always be there to bring you Christmas cheer. [sings] Deck the halls with boughs of holly. Fa-la-la-la-la-la-la-la-la. Tis the season to be jolly. Fa-la-la-la-la-la-la-la-la. Don we now our gay apparel. Fa-la-la-la-la-LA. Troll the little Yule tide carol. Roo-di-di-di-do. Roododododo. [laughs]", "order": 24169, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 10, "scene": 49, "line": "I think I'd like to go home now.", "order": 24170, "speaker": "Angela Martin"},
  {"season": 5, "episode": 10, "scene": 49, "line": "Sure. Dooo. Tough room. [chuckles] Come on. I just learned it. Just so you know protocol is a little round of applause. But, Merry Christmas to all, and to all a good night.", "order": 24171, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 11, "scene": 1, "line": "[answering the phone] Dunder Mifflin, this is Pam. I'm sorry, he's not in yet. Would you like his voicemail?", "order": 24172, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 11, "scene": 1, "line": "[heard yelling from the street] Yeah! Yeah! Yeah, okay! Twelve miles an hour. Eat that, Carl Lewis!", "order": 24173, "speaker": "Michael Scott"},
  {"season": 5, "episode": 11, "scene": 2, "line": "Angela made several 911 calls about cars going too fast in front of the building, so the police put up a radar gun. It's actually caused a bit of a traffic hazard.", "order": 24174, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 11, "scene": 3, "line": "[yelling as he runs past the radar gun] Aah!", "order": 24175, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 11, "scene": 3, "line": "Wow, thirteen!", "order": 24176, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 11, "scene": 3, "line": "Yes!", "order": 24177, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 11, "scene": 3, "line": "No. No, no. There was wind.", "order": 24178, "speaker": "Michael Scott"},
  {"season": 5, "episode": 11, "scene": 3, "line": "I was just jogging.", "order": 24179, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 11, "scene": 3, "line": "Dwight, there was wind. I want a do-over.", "order": 24180, "speaker": "Michael Scott"},
  {"season": 5, "episode": 11, "scene": 3, "line": "No, no, no, it's not your turn. All right, thirteen is the new number. Oscar, go ahead.", "order": 24181, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 11, "scene": 3, "line": "I want another try. Here we go! [Michael runs past the radar as a car passes] Thirty-one! Thirty-one!", "order": 24182, "speaker": "Michael Scott"},
  {"season": 5, "episode": 11, "scene": 3, "line": "There was a car.", "order": 24183, "speaker": "Stanley Hudson"},
  {"season": 5, "episode": 11, "scene": 3, "line": "I was ahead of the car. Thirty-one is my new number.", "order": 24184, "speaker": "Michael Scott"},
  {"season": 5, "episode": 11, "scene": 3, "line": "Thirty-one is humanly impossible.", "order": 24185, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 11, "scene": 3, "line": "Go, Oscar. Thirty-one's my number.", "order": 24186, "speaker": "Michael Scott"},
  {"season": 5, "episode": 11, "scene": 3, "line": "That's impossible.", "order": 24187, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 11, "scene": 3, "line": "Beat it!", "order": 24188, "speaker": "Michael Scott"},
  {"season": 5, "episode": 11, "scene": 4, "line": "Today's a big day. My presence has been requested by [in an authoritative voice] Chief Financial Officer, David Wallace. [in normal voice] He says that he wants to talk about big picture stuff. And, I'll be honest, I have little or no idea what that means, so... probably bad.", "order": 24189, "speaker": "Michael Scott"},
  {"season": 5, "episode": 11, "scene": 5, "line": "Quick announcement: new year, new candy.", "order": 24190, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 11, "scene": 5, "line": "Whoo-hoo!", "order": 24191, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 11, "scene": 5, "line": "Okay, be careful, Kevin. They're kind of spicy.", "order": 24192, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 11, "scene": 5, "line": "Hot tamales.", "order": 24193, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 11, "scene": 5, "line": "Yeah.", "order": 24194, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 11, "scene": 5, "line": "Uh oh.", "order": 24195, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 11, "scene": 5, "line": "So, maybe just try one at first, and then if it's okay, have a couple more...", "order": 24196, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 11, "scene": 5, "line": "[entering the office] Excuse me, everyone, can I have the floor please? Um, this is insanely awkward. It's kind of the elephant in the room, so I'll just... [sighs] No one has RSVP'ed to our wedding yet, and the deadline was yesterday.", "order": 24197, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 11, "scene": 5, "line": "Wait, you still don't know...", "order": 24198, "speaker": "Michael Scott"},
  {"season": 5, "episode": 11, "scene": 5, "line": "[getting up quickly to silence Michael] No, no. Nope.", "order": 24199, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 11, "scene": 5, "line": "Hmm?", "order": 24200, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 11, "scene": 5, "line": "Nothing.", "order": 24201, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 11, "scene": 5, "line": "You still don't know.", "order": 24202, "speaker": "Michael Scott"},
  {"season": 5, "episode": 11, "scene": 5, "line": "Why don't... Let's...", "order": 24203, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 11, "scene": 5, "line": "[to Jim] What are you doing?", "order": 24204, "speaker": "Michael Scott"},
  {"season": 5, "episode": 11, "scene": 5, "line": "How about we just... Just have to talk to you for a second...", "order": 24205, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 11, "scene": 5, "line": "Andy.", "order": 24206, "speaker": "Michael Scott"},
  {"season": 5, "episode": 11, "scene": 5, "line": "Ahh! [ushers Michael into his office]", "order": 24207, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 11, "scene": 5, "line": "No, no one has RSVP'ed, and I don't understand it, and now, you're shutting me out. You're not even listening. That's really considerate. Thank you.", "order": 24208, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 11, "scene": 5, "line": "[from inside his office] No, what I'm saying is...", "order": 24209, "speaker": "Michael Scott"},
  {"season": 5, "episode": 11, "scene": 5, "line": "No, no.", "order": 24210, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 11, "scene": 5, "line": "No, that's not it.", "order": 24211, "speaker": "Michael Scott"},
  {"season": 5, "episode": 11, "scene": 5, "line": "I know.", "order": 24212, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 11, "scene": 6, "line": "Andy still doesn't know that Angela's having an affair with Dwight. And it's been seventeen days. I mean, eventually he'll figure it out, when their kids have giant heads and beet-stained teeth. But right now it's just... awkward.", "order": 24213, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 11, "scene": 7, "line": "[sighs] How can he still not know?", "order": 24214, "speaker": "Michael Scott"},
  {"season": 5, "episode": 11, "scene": 7, "line": "We can't figure that out.", "order": 24215, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 11, "scene": 7, "line": "I can't take it anymore.", "order": 24216, "speaker": "Michael Scott"},
  {"season": 5, "episode": 11, "scene": 7, "line": "Wait, what? You can't take what?", "order": 24217, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 11, "scene": 7, "line": "I am telling Andy.", "order": 24218, "speaker": "Michael Scott"},
  {"season": 5, "episode": 11, "scene": 7, "line": "No. You can't do that. It shouldn't come from you.", "order": 24219, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 11, "scene": 7, "line": "Who should it come from, then?", "order": 24220, "speaker": "Michael Scott"},
  {"season": 5, "episode": 11, "scene": 7, "line": "[simultaneously] Angela.", "order": 24221, "speaker": "Everyone"},
  {"season": 5, "episode": 11, "scene": 7, "line": "[to Dwight] Are you still having intercourse with her? [Dwight's expression implies 'yes']", "order": 24222, "speaker": "Michael Scott"},
  {"season": 5, "episode": 11, "scene": 7, "line": "What is wrong with you? She is engaged.", "order": 24223, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 11, "scene": 7, "line": "Did you ever have intercourse in this office? [Dwight's expression implies 'yes']", "order": 24224, "speaker": "Michael Scott"},
  {"season": 5, "episode": 11, "scene": 7, "line": "Are you serious? Ugh. Where? [Dwight stares at Oscar; more forcefully] Where? [Dwight's expression implies 'at your desk'; Oscar's voice breaks] Where, Dwight?", "order": 24225, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 11, "scene": 7, "line": "[coolly] Seems like you already know where.", "order": 24226, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 11, "scene": 8, "line": "Kevin, you screwed this form up again. The amount owed goes at the top.", "order": 24227, "speaker": "Angela Martin"},
  {"season": 5, "episode": 11, "scene": 8, "line": "Oh, I'm sorry. I didn't realize I was doing something wrong. If I had, I would've admitted it, and stopped right away.", "order": 24228, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 11, "scene": 8, "line": "That's enough.", "order": 24229, "speaker": "Angela Martin"},
  {"season": 5, "episode": 11, "scene": 8, "line": "Because I wouldn't want an innocent person, who doesn't know anything about the form... [Angela puts headphones on; Kevin looks at Oscar] What?", "order": 24230, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 11, "scene": 8, "line": "That was good... It's just, at the end you weren't saying something that could also apply to the form.", "order": 24231, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 11, "scene": 8, "line": "How about, 'I'm sorry I did such a whorish job filling out this form?'", "order": 24232, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 11, "scene": 8, "line": "There you go.", "order": 24233, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 11, "scene": 9, "line": "[on the phone] That cannot be true! [Dwight jumps] You're going to charge me a fee to cut my own cake? Uh, no. No, no, no. What I'm saying is, I want to cut it myself...", "order": 24234, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 11, "scene": 9, "line": "[whispering to Jim] Trade seats with me.", "order": 24235, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 11, "scene": 9, "line": "No.", "order": 24236, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 11, "scene": 9, "line": "I've got a better angle on Pam. I can see everything.", "order": 24237, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 11, "scene": 9, "line": "Please stop.", "order": 24238, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 11, "scene": 9, "line": "[grabs a spoon from Jim's coffee cup and checks behind him with it] I need a soup spoon.", "order": 24239, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 11, "scene": 10, "line": "Rule 17: don't turn your back on bears, men you have wronged, or the dominant turkey during mating season. There are forty rules all Schrute boys must learn before the age of five. [sings] Learn your rules. You better learn your rules. If you don't, you'll be eaten in your sleep. [makes chomping sound]", "order": 24240, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 11, "scene": 11, "line": "[moves his bobblehead from one side of his nameplate to the other and clears his throat to get Angela's attention]", "order": 24241, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 11, "scene": 12, "line": "What is it?", "order": 24242, "speaker": "Angela Martin"},
  {"season": 5, "episode": 11, "scene": 12, "line": "You've got to tell Andy about us.", "order": 24243, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 11, "scene": 12, "line": "That is a terrible idea. One of your worst.", "order": 24244, "speaker": "Angela Martin"},
  {"season": 5, "episode": 11, "scene": 12, "line": "Get it over with. Then we don't have to hide anymore.", "order": 24245, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 11, "scene": 12, "line": "You're expanding on your worst idea.", "order": 24246, "speaker": "Angela Martin"},
  {"season": 5, "episode": 11, "scene": 12, "line": "Do you love me or not?", "order": 24247, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 11, "scene": 12, "line": "I've already admitted that I do. Why do you keep making me repeat it?", "order": 24248, "speaker": "Angela Martin"},
  {"season": 5, "episode": 11, "scene": 12, "line": "Because you're engaged to Andy.", "order": 24249, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 11, "scene": 12, "line": "[sighs]", "order": 24250, "speaker": "Angela Martin"},
  {"season": 5, "episode": 11, "scene": 13, "line": "Well?", "order": 24251, "speaker": "Michael Scott"},
  {"season": 5, "episode": 11, "scene": 13, "line": "Not yet.", "order": 24252, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 11, "scene": 13, "line": "When?", "order": 24253, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 11, "scene": 13, "line": "When what? [clears throat] When what?", "order": 24254, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 11, "scene": 13, "line": "You know this can't go on.", "order": 24255, "speaker": "Michael Scott"},
  {"season": 5, "episode": 11, "scene": 13, "line": "What can't go on?", "order": 24256, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 11, "scene": 13, "line": "We have to put an end to this.", "order": 24257, "speaker": "Michael Scott"},
  {"season": 5, "episode": 11, "scene": 13, "line": "Seems like...", "order": 24258, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 11, "scene": 13, "line": "Come on. [Dwight and Jim follow into Michael's office]", "order": 24259, "speaker": "Michael Scott"},
  {"season": 5, "episode": 11, "scene": 13, "line": "You guys should... be hearing what I'm saying.", "order": 24260, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 11, "scene": 14, "line": "This is really not how this is supposed to happen.", "order": 24261, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 11, "scene": 14, "line": "Angela said she was going to tell him. She's just not ready.", "order": 24262, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 11, "scene": 14, "line": "When will she be ready?", "order": 24263, "speaker": "Michael Scott"},
  {"season": 5, "episode": 11, "scene": 14, "line": "I don't know.", "order": 24264, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 11, "scene": 14, "line": "Is she crazy in bed?", "order": 24265, "speaker": "Michael Scott"},
  {"season": 5, "episode": 11, "scene": 14, "line": "[boastfully] Yes.", "order": 24266, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 11, "scene": 14, "line": "Stop. What?", "order": 24267, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 11, "scene": 14, "line": "How so, specifically?", "order": 24268, "speaker": "Michael Scott"},
  {"season": 5, "episode": 11, "scene": 14, "line": "Okay, listen.", "order": 24269, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 11, "scene": 14, "line": "Eager.", "order": 24270, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 11, "scene": 14, "line": "This shouldn't happen at work.", "order": 24271, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 11, "scene": 14, "line": "And flexible.", "order": 24272, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 11, "scene": 14, "line": "And!", "order": 24273, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 11, "scene": 14, "line": "Really?", "order": 24274, "speaker": "Michael Scott"},
  {"season": 5, "episode": 11, "scene": 14, "line": "This shouldn't be coming from his boss. And we should also consider the fact that that man has an anger issue.", "order": 24275, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 11, "scene": 14, "line": "It's too late.", "order": 24276, "speaker": "Michael Scott"},
  {"season": 5, "episode": 11, "scene": 14, "line": "Well it's not too late, because you haven't done anything.", "order": 24277, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 11, "scene": 14, "line": "I am already walking.", "order": 24278, "speaker": "Michael Scott"},
  {"season": 5, "episode": 11, "scene": 14, "line": "Michael, once this gets out... I don't know how it's going to go down.", "order": 24279, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 11, "scene": 14, "line": "Okay, what does that mean?", "order": 24280, "speaker": "Michael Scott"},
  {"season": 5, "episode": 11, "scene": 14, "line": "Might get ugly.", "order": 24281, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 11, "scene": 14, "line": "[sighs] Jim, this has to get out, so we can all deal with it.", "order": 24282, "speaker": "Michael Scott"},
  {"season": 5, "episode": 11, "scene": 14, "line": "But you're leaving...", "order": 24283, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 11, "scene": 14, "line": "[to Michael, who opens the door to leave] Have a good trip.", "order": 24284, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 11, "scene": 14, "line": "Thanks. [to Andy] Andy?", "order": 24285, "speaker": "Michael Scott"},
  {"season": 5, "episode": 11, "scene": 14, "line": "Yeah.", "order": 24286, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 11, "scene": 14, "line": "Walk with me.", "order": 24287, "speaker": "Michael Scott"},
  {"season": 5, "episode": 11, "scene": 14, "line": "Will do, boss-man.", "order": 24288, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 11, "scene": 15, "line": "Ohh, I do not have much time... car's all the way over there, to tell you what I have to tell you. And just bear in mind when I say... say these things, that... are bad things... that you hear... in your ears... this is something that I, if I were you, that I wouldn't want to hear...", "order": 24289, "speaker": "Michael Scott"},
  {"season": 5, "episode": 11, "scene": 15, "line": "You're not making any sense.", "order": 24290, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 11, "scene": 15, "line": "Well... no, I'm not. So I... I'm not very articulate today, so I'll just leave it for another time. Another day.", "order": 24291, "speaker": "Michael Scott"},
  {"season": 5, "episode": 11, "scene": 15, "line": "All righty.", "order": 24292, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 11, "scene": 15, "line": "Which will be fine. I am off!", "order": 24293, "speaker": "Michael Scott"},
  {"season": 5, "episode": 11, "scene": 15, "line": "Have a good meeting!", "order": 24294, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 11, "scene": 15, "line": "Thank you! [gets into his car]", "order": 24295, "speaker": "Michael Scott"},
  {"season": 5, "episode": 11, "scene": 15, "line": "[through the car window] Kick Wallace's ass!", "order": 24296, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 11, "scene": 15, "line": "[through the car window] Okay. I will... Dwight and Angela are having an affair, so...", "order": 24297, "speaker": "Michael Scott"},
  {"season": 5, "episode": 11, "scene": 15, "line": "I can't hear you through the glass", "order": 24298, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 11, "scene": 15, "line": "[rolls down car window] Dwight and Angela are having an affair. They've been sleeping together for some time. That was the news. I wanted to let you know.", "order": 24299, "speaker": "Michael Scott"},
  {"season": 5, "episode": 11, "scene": 15, "line": "What?", "order": 24300, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 11, "scene": 15, "line": "All right. See you later. [backs out of the parking space] Ahh.", "order": 24301, "speaker": "Michael Scott"},
  {"season": 5, "episode": 11, "scene": 15, "line": "Are you serious?", "order": 24302, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 11, "scene": 15, "line": "Yep. [drives off]", "order": 24303, "speaker": "Michael Scott"},
  {"season": 5, "episode": 11, "scene": 16, "line": "I knew something bad was gonna happen today.", "order": 24304, "speaker": "Meredith Palmer"},
  {"season": 5, "episode": 11, "scene": 16, "line": "You said that yesterday.", "order": 24305, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 11, "scene": 16, "line": "Yeah, my neighbor got murdered.", "order": 24306, "speaker": "Meredith Palmer"},
  {"season": 5, "episode": 11, "scene": 16, "line": "[to Dwight] What are you standing for?", "order": 24307, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 11, "scene": 16, "line": "If I'm sitting, I can't disable his neck or his groin.", "order": 24308, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 11, "scene": 16, "line": "You're not going to do anything to his neck or his groin.", "order": 24309, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 11, "scene": 16, "line": "If I'm sitting, I don't have the option to.", "order": 24310, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 11, "scene": 16, "line": "Dwight, I'm in charge when Michael's gone, and I need you to sit...", "order": 24311, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 11, "scene": 16, "line": "[enters the office and walks to Angela] I need to talk to you.", "order": 24312, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 11, "scene": 16, "line": "We can talk right here.", "order": 24313, "speaker": "Angela Martin"},
  {"season": 5, "episode": 11, "scene": 16, "line": "I need to talk to you in private.", "order": 24314, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 11, "scene": 16, "line": "We're not listening.", "order": 24315, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 11, "scene": 16, "line": "Let's go to the conference room.", "order": 24316, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 11, "scene": 17, "line": "Is it true?", "order": 24317, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 11, "scene": 17, "line": "What have you heard?", "order": 24318, "speaker": "Angela Martin"},
  {"season": 5, "episode": 11, "scene": 17, "line": "That you're sleeping with Dwight.", "order": 24319, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 11, "scene": 17, "line": "That doesn't sound like me.", "order": 24320, "speaker": "Angela Martin"},
  {"season": 5, "episode": 11, "scene": 17, "line": "Is it true?", "order": 24321, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 11, "scene": 17, "line": "Andy, I'm engaged to you. I mean, we just signed off on our wedding flowers. Would I have said yes to formal chrysanthemums if I didn't want to get married? And, we went through all that stuff with our wedding cake...", "order": 24322, "speaker": "Angela Martin"},
  {"season": 5, "episode": 11, "scene": 17, "line": "Just answer the question. Are you sleeping with Dwight?", "order": 24323, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 11, "scene": 17, "line": "A little bit.", "order": 24324, "speaker": "Angela Martin"},
  {"season": 5, "episode": 11, "scene": 17, "line": "How long has it been going on?", "order": 24325, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 11, "scene": 17, "line": "I don't know. I mean, we were together, and then he killed sprinkles, and then we stopped, and... I don't know exactly when we started up again.", "order": 24326, "speaker": "Angela Martin"},
  {"season": 5, "episode": 11, "scene": 17, "line": "Who else knows about it?", "order": 24327, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 11, "scene": 17, "line": "Michael.", "order": 24328, "speaker": "Angela Martin"},
  {"season": 5, "episode": 11, "scene": 17, "line": "Who else?", "order": 24329, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 11, "scene": 17, "line": "[quietly] Let me think about it... I, um... there... [Andy looks over to see everyone watching them]", "order": 24330, "speaker": "Angela Martin"},
  {"season": 5, "episode": 11, "scene": 17, "line": "Oh God. Come on!", "order": 24331, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 11, "scene": 18, "line": "So listen, Michael, your branch has been doing great lately, and your sales staff is reporting very strong numbers. Out-performing last year, in fact. Um, and I don't know exactly how to put this, but... what are you doing right?", "order": 24332, "speaker": "David Wallace"},
  {"season": 5, "episode": 11, "scene": 18, "line": "Right what?", "order": 24333, "speaker": "Michael Scott"},
  {"season": 5, "episode": 11, "scene": 18, "line": "Utica, Albany, all the other branches are struggling, but your branch is reporting strong numbers. [Michael smiles] Look, you're not our most traditional guy, but clearly, something you are doing... is right. And I just, I need to get a sense of what that is.", "order": 24334, "speaker": "David Wallace"},
  {"season": 5, "episode": 11, "scene": 18, "line": "David, here it is. My philosophy is basically this. And this is something that I live by. And I always have. And I always will. Don't ever, for any reason, do anything to anyone, for any reason, ever, no matter what. No matter... where. Or who, or who you are with, or, or where you are going, or... or where you've been... ever. For any reason, whatsoever.", "order": 24335, "speaker": "Michael Scott"},
  {"season": 5, "episode": 11, "scene": 19, "line": "Sometimes I'll start a sentence, and I don't even know where it's going. I just hope I find it along the way. Like an improv conversation. An improversation.", "order": 24336, "speaker": "Michael Scott"},
  {"season": 5, "episode": 11, "scene": 20, "line": "This is going to sound sort of high-maintenance, but could we have it, like, three degrees cooler in here? I always think better when it's cooler.", "order": 24337, "speaker": "Michael Scott"},
  {"season": 5, "episode": 11, "scene": 21, "line": "Here's the thing. Michael is doing something right. And in this economic climate, no method of success can be ignored. It's not really time for executives to start getting judgmental now. It's Hail Mary time.", "order": 24338, "speaker": "David Wallace"},
  {"season": 5, "episode": 11, "scene": 21, "line": "[pokes his head in] Hey, what say we order up some pasta?", "order": 24339, "speaker": "Michael Scott"},
  {"season": 5, "episode": 11, "scene": 21, "line": "What say we do.", "order": 24340, "speaker": "David Wallace"},
  {"season": 5, "episode": 11, "scene": 22, "line": "Standard, you know? Nothing fancy.", "order": 24341, "speaker": "Angela Martin"},
  {"season": 5, "episode": 11, "scene": 22, "line": "So like, missionary...", "order": 24342, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 11, "scene": 22, "line": "I said nothing fancy.", "order": 24343, "speaker": "Angela Martin"},
  {"season": 5, "episode": 11, "scene": 22, "line": "Do you love him?", "order": 24344, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 11, "scene": 22, "line": "I love you.", "order": 24345, "speaker": "Angela Martin"},
  {"season": 5, "episode": 11, "scene": 22, "line": "Why should I believe that?", "order": 24346, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 11, "scene": 22, "line": "Andy, we are at a crossroads here. And we can either give in to what people are saying that we're not good together.", "order": 24347, "speaker": "Angela Martin"},
  {"season": 5, "episode": 11, "scene": 22, "line": "Who says that?", "order": 24348, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 11, "scene": 22, "line": "Or, we can prove them wrong. Let's prove them wrong.", "order": 24349, "speaker": "Angela Martin"},
  {"season": 5, "episode": 11, "scene": 23, "line": "Where's Dwight?", "order": 24350, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 11, "scene": 23, "line": "You okay, man?", "order": 24351, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 11, "scene": 23, "line": "No. Not at all, actually. But thanks for asking. Appreciate it. You know what? I'd also like to take this opportunity to thank all of you for lying. To my face. And not telling me what's been going on this entire time.", "order": 24352, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 11, "scene": 23, "line": "You are welcome.", "order": 24353, "speaker": "Creed Bratton"},
  {"season": 5, "episode": 11, "scene": 23, "line": "[Dwight enters] Dwight.", "order": 24354, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 11, "scene": 23, "line": "Andy.", "order": 24355, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 11, "scene": 23, "line": "It's over.", "order": 24356, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 11, "scene": 23, "line": "Oh good. She broke up with you.", "order": 24357, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 11, "scene": 23, "line": "No. It's over between you two.", "order": 24358, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 11, "scene": 23, "line": "Uh, no way. I am not giving up.", "order": 24359, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 11, "scene": 23, "line": "You have to.", "order": 24360, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 11, "scene": 23, "line": "No I don't.", "order": 24361, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 11, "scene": 23, "line": "[forcefully] She doesn't love you. She's marrying me.", "order": 24362, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 11, "scene": 23, "line": "[angrier] Well I don't know about that, because she certainly seems to enjoy making lovemaking with me.", "order": 24363, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 11, "scene": 23, "line": "Angela Bernard.", "order": 24364, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 11, "scene": 23, "line": "Will never be her name.", "order": 24365, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 11, "scene": 23, "line": "It will be her name. And you will have to call her that!", "order": 24366, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 11, "scene": 23, "line": "I don't think so.", "order": 24367, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 11, "scene": 23, "line": "Hey guys, why don't we, uh, just cool off a bit?", "order": 24368, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 11, "scene": 23, "line": "I'm telling you to back down.", "order": 24369, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 11, "scene": 23, "line": "And I'm telling you that I will never back down.", "order": 24370, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 11, "scene": 23, "line": "Then I'll make you.", "order": 24371, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 11, "scene": 23, "line": "Oh really? How are you gonna do that?", "order": 24372, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 11, "scene": 23, "line": "Through the use of force.", "order": 24373, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 11, "scene": 23, "line": "That is very general, and does not scare me in the slightest.", "order": 24374, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 11, "scene": 23, "line": "I will fight you.", "order": 24375, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 11, "scene": 23, "line": "Nope.", "order": 24376, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 11, "scene": 23, "line": "Okay, fine! Good! A duel! The winner gets Angela.", "order": 24377, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 11, "scene": 23, "line": "Fine!", "order": 24378, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 11, "scene": 23, "line": "Fine!", "order": 24379, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 11, "scene": 23, "line": "This is nuts.", "order": 24380, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 11, "scene": 23, "line": "What is your weapon?", "order": 24381, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 11, "scene": 23, "line": "Okay, you know what? That's enough. Because...", "order": 24382, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 11, "scene": 23, "line": "Hey, this is none of your business.", "order": 24383, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 11, "scene": 23, "line": "Hey. It is my business when it happens at work.", "order": 24384, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 11, "scene": 23, "line": "Guess what? Not happening at work.", "order": 24385, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 11, "scene": 23, "line": "Yes!", "order": 24386, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 11, "scene": 23, "line": "We're gonna do it outside.", "order": 24387, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 11, "scene": 23, "line": "Outside of work.", "order": 24388, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 11, "scene": 23, "line": "None of your business.", "order": 24389, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 11, "scene": 23, "line": "None of your business then. [Dwight and Andy high five] Good. So what weapon?", "order": 24390, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 11, "scene": 23, "line": "My bare hands.", "order": 24391, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 11, "scene": 23, "line": "That is stupid. I will use a sword and I will cut off your bare hands.", "order": 24392, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 11, "scene": 23, "line": "Then I'll get something too.", "order": 24393, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 11, "scene": 24, "line": "I've had two men fight over me before. Usually it's over which one gets to hold the camcorder.", "order": 24394, "speaker": "Meredith Palmer"},
  {"season": 5, "episode": 11, "scene": 25, "line": "Angela, you have to put a stop to this right now.", "order": 24395, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 11, "scene": 25, "line": "[thinks for a moment] I will respect the results of the duel.", "order": 24396, "speaker": "Angela Martin"},
  {"season": 5, "episode": 11, "scene": 25, "line": "Of course you will.", "order": 24397, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 11, "scene": 25, "line": "I call loser!", "order": 24398, "speaker": "Meredith Palmer"},
  {"season": 5, "episode": 11, "scene": 25, "line": "I will be taking my break at 4:00 in the parking lot.", "order": 24399, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 11, "scene": 25, "line": "I will also be talking my break at the exact same time and in the same location.", "order": 24400, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 11, "scene": 25, "line": "[softly] What a coincidence.", "order": 24401, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 11, "scene": 25, "line": "Mm.", "order": 24402, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 11, "scene": 26, "line": "So, I either get more involved, or I take a sick day... leaving Dwight in charge. Oh God.", "order": 24403, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 11, "scene": 27, "line": "[collects Dwight's weapons from around the office; holds up a hand scythe]", "order": 24404, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 11, "scene": 27, "line": "[innocently] How'd that get there?", "order": 24405, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 11, "scene": 27, "line": "There's a star-shaped thing taped under the kitchen table.", "order": 24406, "speaker": "Meredith Palmer"},
  {"season": 5, "episode": 11, "scene": 27, "line": "Thanks Meredith.", "order": 24407, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 11, "scene": 28, "line": "So I was in the office, and I look over to our Accounting division, and there is Kevin Malone. Kevin is wearing a jacket that I've never seen before. And I call over to Kevin, 'Kevin, is that a tweed jacket?' And he looks at me and he says, 'Michael, yes it is a tweed jacket.' And I look back at him and I say, 'I feel the need!... The need for tweed.'", "order": 24408, "speaker": "Michael Scott"},
  {"season": 5, "episode": 11, "scene": 28, "line": "It's hard to try and evaluate yourself, Michael, but I appreciate you trying. [gets up] And thanks for coming in.", "order": 24409, "speaker": "David Wallace"},
  {"season": 5, "episode": 11, "scene": 28, "line": "[rises] Oh, thank you.", "order": 24410, "speaker": "Michael Scott"},
  {"season": 5, "episode": 11, "scene": 28, "line": "Yes.", "order": 24411, "speaker": "David Wallace"},
  {"season": 5, "episode": 11, "scene": 28, "line": "I have to say, I am so impressed with the potential you see in me.", "order": 24412, "speaker": "Michael Scott"},
  {"season": 5, "episode": 11, "scene": 28, "line": "Yeah.", "order": 24413, "speaker": "David Wallace"},
  {"season": 5, "episode": 11, "scene": 28, "line": "Okay. [sits back down to continue eating]", "order": 24414, "speaker": "Michael Scott"},
  {"season": 5, "episode": 11, "scene": 28, "line": "[opens his office door] Yeah, finish up.", "order": 24415, "speaker": "David Wallace"},
  {"season": 5, "episode": 11, "scene": 29, "line": "[shouting] Come on! Where are you? Let's do this thing! Come on! Come on out!", "order": 24416, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 11, "scene": 29, "line": "[watching from the conference room] I can't believe they're gonna fight over me.", "order": 24417, "speaker": "Angela Martin"},
  {"season": 5, "episode": 11, "scene": 29, "line": "I guess people have fewer choices as they get older.", "order": 24418, "speaker": "Kelly Kapoor"},
  {"season": 5, "episode": 11, "scene": 29, "line": "Come on!", "order": 24419, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 11, "scene": 29, "line": "Hey, has anything happened yet?", "order": 24420, "speaker": "Meredith Palmer"},
  {"season": 5, "episode": 11, "scene": 29, "line": "Where are you?", "order": 24421, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 11, "scene": 29, "line": "Mm-mm. It's 4:10, I don't think he's gonna show.", "order": 24422, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 11, "scene": 29, "line": "Oh come on, man! Believe in something.", "order": 24423, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 11, "scene": 29, "line": "Come on, coward! Where are you? Where... [spots a note attached to the bushes; goes to read it] 'From the desk of Andrew Bernard.' [scoffs] A note. Pathetic. 'Dear Dwight, by now you have received my note. How are you? I am well. You are no doubt wondering why I have left this note. It has come to my attention that in any physical match with you, I would surely be bested.' True. 'The soft underbelly of my refined upbringing is my soft underbelly...' [as Dwight continues to read, Andy drives around the corner very slowly]", "order": 24424, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 11, "scene": 30, "line": "There's Andy, he's in his car. You guys, what is he doing?", "order": 24425, "speaker": "Kelly Kapoor"},
  {"season": 5, "episode": 11, "scene": 30, "line": "Why isn't Dwight turning around?", "order": 24426, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 11, "scene": 30, "line": "The Prius is silent if he keeps it under five miles per hour. He deserves the win.", "order": 24427, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 11, "scene": 30, "line": "Yeah.", "order": 24428, "speaker": "Creed Bratton"},
  {"season": 5, "episode": 11, "scene": 30, "line": "[still reading] Alas, after much consideration and deliberation...", "order": 24429, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 11, "scene": 30, "line": "Oh my God!", "order": 24430, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 11, "scene": 30, "line": "What's happening?", "order": 24431, "speaker": "Stanley Hudson"},
  {"season": 5, "episode": 11, "scene": 30, "line": "Andy's running over Dwight with his car.", "order": 24432, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 11, "scene": 31, "line": "Whoa! What are you! Hey! Hey!", "order": 24433, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 11, "scene": 31, "line": "Yeah.", "order": 24434, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 11, "scene": 31, "line": "Oh!", "order": 24435, "speaker": "Everyone watching"},
  {"season": 5, "episode": 11, "scene": 31, "line": "Come on! Ow! Ah! Aah!", "order": 24436, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 11, "scene": 31, "line": "You give up?", "order": 24437, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 11, "scene": 31, "line": "Never! [hits Andy's car with his bike chain] Get out and face me like a man!", "order": 24438, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 11, "scene": 31, "line": "I am a man! I'm a bigger man than you'll ever be! I would never sleep with another man's fiance!", "order": 24439, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 11, "scene": 31, "line": "You're not a man! You don't know how to take care of her! [hits his car with the bike chain] All you do is dress fancy and sing. [imitating Andy] 'La la la la la la la la la la!' What does that mean? You can't even protect her!", "order": 24440, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 11, "scene": 31, "line": "Protect her from what? Bears, you idiot? When's the last time you saw a damn bear in Scranton?", "order": 24441, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 11, "scene": 31, "line": "Last year, idiot! [hits his car with the bike chain]", "order": 24442, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 11, "scene": 31, "line": "Dwight! Are your legs broken?", "order": 24443, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 11, "scene": 31, "line": "No. My right one's falling asleep a little bit.", "order": 24444, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 11, "scene": 31, "line": "Andy, are you all right?", "order": 24445, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 11, "scene": 31, "line": "Go away, Tuna! I'm winning this!", "order": 24446, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 11, "scene": 31, "line": "Yeah, back off. This isn't your fight. Oh, how much is this gonna cost? [hits his car with the bike chain] Oh! What? Trust fund will take care of that! [hits his car with the bike chain]", "order": 24447, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 11, "scene": 31, "line": "What did you say? I'm sorry, I couldn't hear you.", "order": 24448, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 11, "scene": 31, "line": "I sa- [Andy honks his horn]", "order": 24449, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 11, "scene": 31, "line": "What? You stupid idiot! [Andy honks his horn] You're like, you're like a Sasquatch! You live in the woods...", "order": 24450, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 11, "scene": 31, "line": "Sasquatches are the strongest animal on the planet! So fine, call me a Sasquatch!", "order": 24451, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 11, "scene": 31, "line": "I don't get it! How can she be sleeping with you this whole time and only sleep with me twice?", "order": 24452, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 11, "scene": 31, "line": "What?", "order": 24453, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 11, "scene": 31, "line": "What!", "order": 24454, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 11, "scene": 31, "line": "She's sleeping with you?", "order": 24455, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 11, "scene": 31, "line": "I'm her fiance.", "order": 24456, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 11, "scene": 31, "line": "She said she was only sleeping with me.", "order": 24457, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 11, "scene": 32, "line": "[enters, followed by Dwight, and makes a call] Yes, hi, my last name is Bernard, and I would like to cancel a wedding cake that I had ordered. B-E-R, N-A, R-D. Yeah, the one shaped like a sailboat... Yep, that's the one.", "order": 24458, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 11, "scene": 32, "line": "[picks up his bobblehead and throws it in the trash can]", "order": 24459, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 11, "scene": 32, "line": "Thank you. [hangs up]", "order": 24460, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 11, "scene": 33, "line": "Wow, what a day! Haha! I thought I was gong to get chewed out, but, hold on! Here's an attaboy for ya! What? Rollercoaster ride! Rollercoaster! It just goes to show, you leave Scranton, exciting things can happen. [sighs] Ahh!", "order": 24461, "speaker": "Michael Scott"},
  {"season": 5, "episode": 11, "scene": 34, "line": "[as Jim is collecting weapons, runs to the couch near reception and pulls out a crossbow; Jim catches him and he hands it over]", "order": 24462, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 12, "scene": 1, "line": "What's this?", "order": 24463, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 12, "scene": 1, "line": "Looks like a red wire.", "order": 24464, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 12, "scene": 1, "line": "Hmm. It wasn't here before.", "order": 24465, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 12, "scene": 1, "line": "Well it's a computer Dwight, computers have wires.", "order": 24466, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 12, "scene": 1, "line": "Yours doesn't.", "order": 24467, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 12, "scene": 1, "line": "Doesn't it?", "order": 24468, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 12, "scene": 1, "line": "No, it's going in a different direction then the other wires.", "order": 24469, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 12, "scene": 1, "line": "Dwight, I am really busy, I can't talk about this anymore. [Dwight follows red cord through office past Phyllis' and Stanley's desks]", "order": 24470, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 12, "scene": 1, "line": "Dwight, get out of here!", "order": 24471, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 12, "scene": 1, "line": "What are you doing?", "order": 24472, "speaker": "Stanley Hudson"},
  {"season": 5, "episode": 12, "scene": 2, "line": "I got 500 feet of red wire at a flea market up by Dunmore High School. 20 bucks for the whole spool. Crazy, what a deal. [behind Jim, Dwight climbs telephone pole] Oh he'll be fine. I made it up there.", "order": 24473, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 12, "scene": 3, "line": "What you talking bout, Wallace? [imitating What you talking bout Willis]", "order": 24474, "speaker": "Michael Scott"},
  {"season": 5, "episode": 12, "scene": 3, "line": "Hi Michael how are you?", "order": 24475, "speaker": "David Wallace"},
  {"season": 5, "episode": 12, "scene": 3, "line": "Hi, I am well, how are you?", "order": 24476, "speaker": "Michael Scott"},
  {"season": 5, "episode": 12, "scene": 3, "line": "So listen, as you know, we haven't yet filled the regional supervisor job.", "order": 24477, "speaker": "David Wallace"},
  {"season": 5, "episode": 12, "scene": 3, "line": "Oh, have you not?", "order": 24478, "speaker": "Michael Scott"},
  {"season": 5, "episode": 12, "scene": 3, "line": "Correct. And I was wondering if I could get you to do some of the field work that would normally go to the supervisor [Michael makes exaggerated oh-my-gosh face at camera]. There is an area from Carbondale to Marshbrook, where we have never done any business.", "order": 24479, "speaker": "David Wallace"},
  {"season": 5, "episode": 12, "scene": 3, "line": "Yeah.", "order": 24480, "speaker": "Michael Scott"},
  {"season": 5, "episode": 12, "scene": 3, "line": "There is a small company there, Prince Paper. I can't get a report on it because it's not a public company. But we have been talking about going after their market, so I was hoping you could do some fact finding for me.", "order": 24481, "speaker": "David Wallace"},
  {"season": 5, "episode": 12, "scene": 3, "line": "Okay.", "order": 24482, "speaker": "Michael Scott"},
  {"season": 5, "episode": 12, "scene": 3, "line": "I'll fax over some of the things we're looking for.", "order": 24483, "speaker": "David Wallace"},
  {"season": 5, "episode": 12, "scene": 3, "line": "Fax, why don't you just send it over on a dinosaur?", "order": 24484, "speaker": "Michael Scott"},
  {"season": 5, "episode": 12, "scene": 3, "line": "Look, this is important, Michael.", "order": 24485, "speaker": "David Wallace"},
  {"season": 5, "episode": 12, "scene": 3, "line": "Oh, well, then, email it to me.", "order": 24486, "speaker": "Michael Scott"},
  {"season": 5, "episode": 12, "scene": 4, "line": "You've got no taste Stanley Hudson.", "order": 24487, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 12, "scene": 4, "line": "Oh, I do.", "order": 24488, "speaker": "Stanley Hudson"},
  {"season": 5, "episode": 12, "scene": 4, "line": "What are you guys talking about?", "order": 24489, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 12, "scene": 4, "line": "Some actress, whether shes hot.", "order": 24490, "speaker": "Stanley Hudson"},
  {"season": 5, "episode": 12, "scene": 4, "line": "Who is the gal in question?", "order": 24491, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 12, "scene": 4, "line": "Hillary Swank.", "order": 24492, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 12, "scene": 4, "line": "Ah, Hillary Swank.", "order": 24493, "speaker": "Creed Bratton"},
  {"season": 5, "episode": 12, "scene": 5, "line": "[most of office huddles around print out of Hillary Swank, judging her hotness] Not at all.", "order": 24494, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 12, "scene": 5, "line": "She's got mean eyes.", "order": 24495, "speaker": "Meredith Palmer"},
  {"season": 5, "episode": 12, "scene": 5, "line": "Have you seen her with her bangs?", "order": 24496, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 12, "scene": 5, "line": "She looks like a monster.", "order": 24497, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 12, "scene": 5, "line": "Guys, she is a beautiful movie star, so maybe we should just go to work.", "order": 24498, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 12, "scene": 5, "line": "She is an amazing actress.", "order": 24499, "speaker": "Meredith Palmer"},
  {"season": 5, "episode": 12, "scene": 5, "line": "That is not the question.", "order": 24500, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 12, "scene": 5, "line": "She's not hot.", "order": 24501, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 12, "scene": 5, "line": "Yeah, thank you Phyllis.", "order": 24502, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 12, "scene": 5, "line": "Okay, okay, okay. Why don't we just put this to a vote, and then we'll be done with it.", "order": 24503, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 12, "scene": 5, "line": "I'm not voting.", "order": 24504, "speaker": "Angela Martin"},
  {"season": 5, "episode": 12, "scene": 5, "line": "[snaps back] No one cares. Who thinks that Hillary Swank is hot, raise your hand. [half of office raises hand] Okay, and who thinks Hillary Swank is not hot, raise your hand. [other half of office raises hand]", "order": 24505, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 12, "scene": 5, "line": "[counting votes] Five. Five to five.", "order": 24506, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 12, "scene": 5, "line": "Thank you, accounting department.", "order": 24507, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 12, "scene": 5, "line": "So what do we do now?", "order": 24508, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 12, "scene": 6, "line": "[riding in car with Michael] What if the owner of Prince Family Paper has a beautiful daughter and we have to seduce her in order to get their secrets?", "order": 24509, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 12, "scene": 6, "line": "I will seduce her.", "order": 24510, "speaker": "Michael Scott"},
  {"season": 5, "episode": 12, "scene": 6, "line": "No, I wanna seduce her.", "order": 24511, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 12, "scene": 6, "line": "No, I will seduce her.", "order": 24512, "speaker": "Michael Scott"},
  {"season": 5, "episode": 12, "scene": 6, "line": "Please Michael, please.", "order": 24513, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 12, "scene": 6, "line": "No, I got it.", "order": 24514, "speaker": "Michael Scott"},
  {"season": 5, "episode": 12, "scene": 6, "line": "You'll fall in love with her.", "order": 24515, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 12, "scene": 6, "line": "Yeah, so what if I did? That would take precedence and I would expect your support.", "order": 24516, "speaker": "Michael Scott"},
  {"season": 5, "episode": 12, "scene": 6, "line": "Okay, let's go over the plan again.", "order": 24517, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 12, "scene": 6, "line": "Alright, I am a local business owner. [Dwight nods along] I need to buy paper. I find out everything about their prices and policies", "order": 24518, "speaker": "Michael Scott"},
  {"season": 5, "episode": 12, "scene": 6, "line": "Your fictitious name?", "order": 24519, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 12, "scene": 6, "line": "Michael Scarn.", "order": 24520, "speaker": "Michael Scott"},
  {"season": 5, "episode": 12, "scene": 6, "line": "I am a paper salesman looking for a new job. I find out everything about their expenses and salaries.", "order": 24521, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 12, "scene": 6, "line": "Okay.", "order": 24522, "speaker": "Michael Scott"},
  {"season": 5, "episode": 12, "scene": 6, "line": "Then we meet at the Denny's...", "order": 24523, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 12, "scene": 6, "line": "[interrupting] No.", "order": 24524, "speaker": "Michael Scott"},
  {"season": 5, "episode": 12, "scene": 6, "line": "...and then we compare notes.", "order": 24525, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 12, "scene": 6, "line": "No, no, I never said Denny's. IHOP.", "order": 24526, "speaker": "Michael Scott"},
  {"season": 5, "episode": 12, "scene": 6, "line": "No! [arguing over each other]", "order": 24527, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 12, "scene": 6, "line": "You know how I feel about IHOP.", "order": 24528, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 12, "scene": 6, "line": "Oh, don't start,", "order": 24529, "speaker": "Michael Scott"},
  {"season": 5, "episode": 12, "scene": 6, "line": "Are you socialist?", "order": 24530, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 12, "scene": 6, "line": "You know what? I don't want to get into this debate again. I enjoy IHOP.", "order": 24531, "speaker": "Michael Scott"},
  {"season": 5, "episode": 12, "scene": 6, "line": "I'll have a cup of a coffee.", "order": 24532, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 12, "scene": 6, "line": "You will have pancakes and you'll like it.", "order": 24533, "speaker": "Michael Scott"},
  {"season": 5, "episode": 12, "scene": 7, "line": "Store only has three employees.", "order": 24534, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 12, "scene": 7, "line": "So what?", "order": 24535, "speaker": "Michael Scott"},
  {"season": 5, "episode": 12, "scene": 7, "line": "It means they are not expanding.", "order": 24536, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 12, "scene": 7, "line": "Maybe they are shrinking.", "order": 24537, "speaker": "Michael Scott"},
  {"season": 5, "episode": 12, "scene": 7, "line": "No.", "order": 24538, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 12, "scene": 7, "line": "They could be shrinking.", "order": 24539, "speaker": "Michael Scott"},
  {"season": 5, "episode": 12, "scene": 7, "line": "No because their sign is centered perfectly above their store. No sign of the sign being moved.", "order": 24540, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 12, "scene": 7, "line": "Ah ha [popping something in his mouth] Yup.", "order": 24541, "speaker": "Michael Scott"},
  {"season": 5, "episode": 12, "scene": 7, "line": "[spying through binoculars] Its lunch time, and no sign of anyone coming in or out. Which means they are not taking new customers out to lunch.", "order": 24542, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 12, "scene": 7, "line": "Mm hmm .", "order": 24543, "speaker": "Michael Scott"},
  {"season": 5, "episode": 12, "scene": 7, "line": "Which Means they're not acquiring new business. So once again no growth.", "order": 24544, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 12, "scene": 7, "line": "And there are clouds. There are clouds in the sky, which means gonna rain, bad for business.", "order": 24545, "speaker": "Michael Scott"},
  {"season": 5, "episode": 12, "scene": 7, "line": "Oh, it would if they were all cumulous and not cirrostratus.", "order": 24546, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 12, "scene": 7, "line": "Argh, why are you all...", "order": 24547, "speaker": "Michael Scott"},
  {"season": 5, "episode": 12, "scene": 7, "line": "Get your clouds right.", "order": 24548, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 12, "scene": 7, "line": "Shut up.", "order": 24549, "speaker": "Michael Scott"},
  {"season": 5, "episode": 12, "scene": 7, "line": "So you go in now, I am going to go in exactly ten minutes. We have never met each other. We are complete strangers. Also, we are going to need a signal to bolt out of there if there is danger. Lick ur lips, try it. [Michael licks lips] No, no like this. [Dwight starts and Michael follows passionately licking their lips] Good.", "order": 24550, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 12, "scene": 7, "line": "Ready to do this?", "order": 24551, "speaker": "Michael Scott"},
  {"season": 5, "episode": 12, "scene": 7, "line": "Yeah.", "order": 24552, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 12, "scene": 7, "line": "Good. Here come the sharks. [they imitate jaws theme sounds and Dwight makes shark fin on his forehead]", "order": 24553, "speaker": "Michael Scott"},
  {"season": 5, "episode": 12, "scene": 8, "line": "In nature, there is something called a food chain; it's where the shark eats a little shark. And the little shark eats a littler shark. And so on and so on. Until you get down to the single cell shark. So now replace sharks with paper companies and that is all you need to know about business.", "order": 24554, "speaker": "Michael Scott"},
  {"season": 5, "episode": 12, "scene": 9, "line": "Hello.", "order": 24555, "speaker": "Michael Scott"},
  {"season": 5, "episode": 12, "scene": 9, "line": "Hello, hi.", "order": 24556, "speaker": "Entire Prince family"},
  {"season": 5, "episode": 12, "scene": 9, "line": "My name is Michael Scarn. I am a local business owner and I would like to find out about your company.", "order": 24557, "speaker": "Michael Scott"},
  {"season": 5, "episode": 12, "scene": 9, "line": "Oh, please come in", "order": 24558, "speaker": "Prince Grandfather"},
  {"season": 5, "episode": 12, "scene": 9, "line": "Thank you.", "order": 24559, "speaker": "Michael Scott"},
  {"season": 5, "episode": 12, "scene": 9, "line": "What kind of business are you in.", "order": 24560, "speaker": "Prince Grandfather"},
  {"season": 5, "episode": 12, "scene": 9, "line": "We are a law firm.", "order": 24561, "speaker": "Michael Scott"},
  {"season": 5, "episode": 12, "scene": 9, "line": "I assume your primary paper needs are stationary, general office.", "order": 24562, "speaker": "Prince Grandfather"},
  {"season": 5, "episode": 12, "scene": 9, "line": "You know, I will be honest with you... about something. Where we used to live, our paper supplier had a lot of big clients and I just didn't feel like a priority. So I guess my question [reaches for paper in his pocket with questions on it] for you would be [reading off sheet] how many clients do you have?", "order": 24563, "speaker": "Michael Scott"},
  {"season": 5, "episode": 12, "scene": 9, "line": "About 80.", "order": 24564, "speaker": "Prince Grandfather"},
  {"season": 5, "episode": 12, "scene": 9, "line": "Really? That many? This doesn't seem like a very big operation.", "order": 24565, "speaker": "Michael Scott"},
  {"season": 5, "episode": 12, "scene": 9, "line": "It's just me, my wife and my son.", "order": 24566, "speaker": "Prince Grandfather"},
  {"season": 5, "episode": 12, "scene": 9, "line": "Ah, so when did you set up shop?", "order": 24567, "speaker": "Michael Scott"},
  {"season": 5, "episode": 12, "scene": 9, "line": "I opened this place after I came back from Vietnam.", "order": 24568, "speaker": "Prince Grandfather"},
  {"season": 5, "episode": 12, "scene": 9, "line": "Oh Vietnam, I hear it's lovely .", "order": 24569, "speaker": "Michael Scott"},
  {"season": 5, "episode": 12, "scene": 9, "line": "[muted] Mmhmm. [shrugs]", "order": 24570, "speaker": "Prince Grandfather"},
  {"season": 5, "episode": 12, "scene": 10, "line": "Each side will have three minutes to prepare opening arguments. Topic: Hillary Swank is attractive.", "order": 24571, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 12, "scene": 10, "line": "Hot!", "order": 24572, "speaker": "Entire office"},
  {"season": 5, "episode": 12, "scene": 10, "line": "The debate is whether she is hot.", "order": 24573, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 12, "scene": 10, "line": "What difference does it make? Attractive, beautiful, hot, we're talking about the same thing here.", "order": 24574, "speaker": "Stanley Hudson"},
  {"season": 5, "episode": 12, "scene": 10, "line": "Huge difference. A painting can be beautiful, but I don't want to bang a painting.", "order": 24575, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 12, "scene": 10, "line": "Okay, TMI.", "order": 24576, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 12, "scene": 11, "line": "So, even specialty paper, say 94 brightness double-bonded, you would put that on the same truck as your conventional stock?", "order": 24577, "speaker": "Michael Scott"},
  {"season": 5, "episode": 12, "scene": 11, "line": "You sure know a lot about paper for a lawyer.", "order": 24578, "speaker": "Prince Grandfather"},
  {"season": 5, "episode": 12, "scene": 11, "line": "Well that is... because... I am a genius.", "order": 24579, "speaker": "Michael Scott"},
  {"season": 5, "episode": 12, "scene": 11, "line": "Oh really?", "order": 24580, "speaker": "Prince Grandfather"},
  {"season": 5, "episode": 12, "scene": 11, "line": "Yes, well about some things, and other things I am very stupid. Like, watch this. Is this the cup? [picks up tape dispenser and makes drinking motion] Is this the cup? [picks up a stapler and makes drinking motion] Is this what I drink out of? [glug noise, both laugh] Laughter is my job, tears are my game, law is my profession.", "order": 24581, "speaker": "Michael Scott"},
  {"season": 5, "episode": 12, "scene": 11, "line": "Hello, I would like to apply for a job.", "order": 24582, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 12, "scene": 11, "line": "Hi, I'm afraid we're not hiring right now.", "order": 24583, "speaker": "Prince Grandfather"},
  {"season": 5, "episode": 12, "scene": 11, "line": "Why don't you just get rid of this guy? [points to Prince Son]", "order": 24584, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 12, "scene": 11, "line": "That's my son.", "order": 24585, "speaker": "Prince Grandfather"},
  {"season": 5, "episode": 12, "scene": 11, "line": "I'm your son now; you can visit him on holidays. Dwight Schrute, I'm a paper salesman at a second tier paper company called Dunder Mifflin. I'm the top salesman there and I'm looking for a new challenge. I would say you guys have what, 40 high full volume clients?", "order": 24586, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 12, "scene": 11, "line": "Try 80.", "order": 24587, "speaker": "Michael Scott"},
  {"season": 5, "episode": 12, "scene": 11, "line": "Pshaw, that's nothing. I've 90 clients myself. You had better look out, someone might run you out of business.", "order": 24588, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 12, "scene": 11, "line": "I sure hope not.", "order": 24589, "speaker": "Prince Grandfather"},
  {"season": 5, "episode": 12, "scene": 11, "line": "Me too. [smirking]", "order": 24590, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 12, "scene": 12, "line": "I think all of us have a tendency to view celebrities as sort of mythical figures, you know? We don't really see them as real so therefore we don't judge them as real people.", "order": 24591, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 12, "scene": 12, "line": "Are you serious? Jim, just show us a picture.", "order": 24592, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 12, "scene": 12, "line": "Kevin, c'mon.", "order": 24593, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 12, "scene": 12, "line": "Yeah, shut up Kevin.", "order": 24594, "speaker": "Kelly Kapoor"},
  {"season": 5, "episode": 12, "scene": 12, "line": "He's making all these fancy, uh, it's a gut thing.", "order": 24595, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 12, "scene": 12, "line": "Kevin, you know what, why don't you close your eyes? Imagine that Hilary Swank comes into this office for real and she walks over to you [in a more and more seductive voice] and says Kevin Malone, I just read your online profile and there is nothing I would rather do then make out with you right now. And now you tell me something, is she hot? [Kevin gets up to join the 'hot' team to their great joy] Does that end the debate?", "order": 24596, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 12, "scene": 12, "line": "[Kevin sits down, gets right back up and heads back to 'not hot' team] No, it's is she hot, not would you do her. Respect the game.", "order": 24597, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 12, "scene": 12, "line": "Oh my God.", "order": 24598, "speaker": "Angela Martin"},
  {"season": 5, "episode": 12, "scene": 12, "line": "[fingers opened an inch] This close.", "order": 24599, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 12, "scene": 13, "line": "Grandfather: So why are you considering leaving Dunder Mifflin?", "order": 24600, "speaker": "Prince"},
  {"season": 5, "episode": 12, "scene": 13, "line": "My boss, his insensitivity might border on cruel. This is a man who does not listen to the needs his underlings. [Michael takes offense and charges over]", "order": 24601, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 12, "scene": 13, "line": "Hey, hey, how is your interview going?", "order": 24602, "speaker": "Michael Scott"},
  {"season": 5, "episode": 12, "scene": 13, "line": "It's going very well.", "order": 24603, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 12, "scene": 13, "line": "Don't blow it. Hey Linda, can I get a picture of you?", "order": 24604, "speaker": "Michael Scott"},
  {"season": 5, "episode": 12, "scene": 13, "line": "You want a picture of me?", "order": 24605, "speaker": "Linda"},
  {"season": 5, "episode": 12, "scene": 13, "line": "I would, could you just stand over here? That would be great [positioning her towards sign of Prince Family Paper customers] Just a little something to remember the trip by. A little to your left [positions Linda to get better picture]", "order": 24606, "speaker": "Michael Scott"},
  {"season": 5, "episode": 12, "scene": 13, "line": "Can I get a copy of that?", "order": 24607, "speaker": "Linda"},
  {"season": 5, "episode": 12, "scene": 13, "line": "Grandma, I can't do this.", "order": 24608, "speaker": "Prince Granddaughter"},
  {"season": 5, "episode": 12, "scene": 13, "line": "What's going on? Little homework over here? Lets see if I can help. Huh, math, that's not so hard. Ok. There are 4 of these, ignore the parenthesis, right?", "order": 24609, "speaker": "Michael Scott"},
  {"season": 5, "episode": 12, "scene": 13, "line": "Right.", "order": 24610, "speaker": "Prince Granddaughter"},
  {"season": 5, "episode": 12, "scene": 13, "line": "Why is this little 2 so small? It's, it's weird, you don't, you just go by the x. The x means times. 4 times x 2. What is double 4?", "order": 24611, "speaker": "Michael Scott"},
  {"season": 5, "episode": 12, "scene": 13, "line": "8", "order": 24612, "speaker": "Prince Granddaughter"},
  {"season": 5, "episode": 12, "scene": 13, "line": "Excellent! Way to go! Nice! [Michael and granddaughter Prince exchange high fives]", "order": 24613, "speaker": "Michael Scott"},
  {"season": 5, "episode": 12, "scene": 13, "line": "Don't put that. [granddaughter Prince erases and corrects answer]", "order": 24614, "speaker": "Prince Grandmother"},
  {"season": 5, "episode": 12, "scene": 14, "line": "Ladies and gentlemen, even though the penis was fake, I was expecting a second plot twist where we found out Hilary Swank was a boy.", "order": 24615, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 12, "scene": 14, "line": "[disapprovingly] Kevin!", "order": 24616, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 12, "scene": 14, "line": "Ok, I wasn't going to dignify this discussion by getting involved, but I don't even get the discussion, hot is a temperature people. But Kevin deserves to lose for what he said, so, yes, she's hot, she's hot as heck, she's a female Boris Becker.", "order": 24617, "speaker": "Angela Martin"},
  {"season": 5, "episode": 12, "scene": 14, "line": "OH! [the 'yes she's hot team' claps]", "order": 24618, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 12, "scene": 15, "line": "Oh, well thank you very much for all of your help. It's going to be an incredibly hard decision.", "order": 24619, "speaker": "Michael Scott"},
  {"season": 5, "episode": 12, "scene": 15, "line": "Wait; let me get something for you. Here is some, some references. Here is a list of references, our top clients. You call any of them; I trust you will hear some good things.", "order": 24620, "speaker": "Prince Grandfather"},
  {"season": 5, "episode": 12, "scene": 15, "line": "I... Okay, okay, okay, [in disbelief] alright, thank you. [heads towards Dwight, makes make out lips signal]", "order": 24621, "speaker": "Michael Scott"},
  {"season": 5, "episode": 12, "scene": 15, "line": "[both making make out lips at each other] Excuse me sir, I took the bus here and was wondering if I could catch a ride home with you in your car.", "order": 24622, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 12, "scene": 15, "line": "Of course. Thank you very much. Thanks for your time.", "order": 24623, "speaker": "Michael Scott"},
  {"season": 5, "episode": 12, "scene": 15, "line": "Thank you very much.", "order": 24624, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 12, "scene": 15, "line": "I cannot wait to contact your clients.", "order": 24625, "speaker": "Michael Scott"},
  {"season": 5, "episode": 12, "scene": 15, "line": "Thank you for your time I look forward to getting your calls. [hurrying out door]", "order": 24626, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 12, "scene": 15, "line": "Bye bye now. So long.", "order": 24627, "speaker": "Prince Grandfather"},
  {"season": 5, "episode": 12, "scene": 15, "line": "Oh my God, oh my God, oh my God. [running out door]", "order": 24628, "speaker": "Dwight Schrute & Michael Scott"},
  {"season": 5, "episode": 12, "scene": 15, "line": "Haha, we struck the mother load!", "order": 24629, "speaker": "Michael Scott"},
  {"season": 5, "episode": 12, "scene": 15, "line": "Cmon.", "order": 24630, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 12, "scene": 15, "line": "Wallace is going to freak.", "order": 24631, "speaker": "Michael Scott"},
  {"season": 5, "episode": 12, "scene": 15, "line": "All their top clients I can't believe it.", "order": 24632, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 12, "scene": 15, "line": "He's going to absolutely freak.", "order": 24633, "speaker": "Michael Scott"},
  {"season": 5, "episode": 12, "scene": 15, "line": "Someone's looking out the window. Go go, Peel out, go. [car hits front divider, screeching]", "order": 24634, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 12, "scene": 15, "line": "Oh shoot.", "order": 24635, "speaker": "Michael Scott"},
  {"season": 5, "episode": 12, "scene": 15, "line": "You drove over that. Go back [car hits divider, screeching]", "order": 24636, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 12, "scene": 15, "line": "Oh God, what was that?", "order": 24637, "speaker": "Michael Scott"},
  {"season": 5, "episode": 12, "scene": 15, "line": "That's not good.", "order": 24638, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 12, "scene": 16, "line": "No, no, no no. Shes hot, okay? Because if you are saying Hillary Swank isn't hot, then you are saying that I am not hot. Because obviously I am not as hot as Hillary Swank! [Kelly runs away in tears]", "order": 24639, "speaker": "Kelly Kapoor"},
  {"season": 5, "episode": 12, "scene": 17, "line": "Oh my God.", "order": 24640, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 12, "scene": 17, "line": "Aw, it's alright.", "order": 24641, "speaker": "Michael Scott"},
  {"season": 5, "episode": 12, "scene": 17, "line": "Oh man,", "order": 24642, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 12, "scene": 17, "line": "Aw, my car.", "order": 24643, "speaker": "Michael Scott"},
  {"season": 5, "episode": 12, "scene": 17, "line": "Not good.", "order": 24644, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 12, "scene": 17, "line": "Is everyone okay?", "order": 24645, "speaker": "Prince Grandfather"},
  {"season": 5, "episode": 12, "scene": 17, "line": "It sounded bad.", "order": 24646, "speaker": "Prince Grandmother"},
  {"season": 5, "episode": 12, "scene": 17, "line": "Yeah.", "order": 24647, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 12, "scene": 17, "line": "No no, we are fine. You have done enough already.", "order": 24648, "speaker": "Michael Scott"},
  {"season": 5, "episode": 12, "scene": 17, "line": "You've done more then you can possibly know.", "order": 24649, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 12, "scene": 17, "line": "Just, shut up. You know we are just going to call a tow truck, so...", "order": 24650, "speaker": "Michael Scott"},
  {"season": 5, "episode": 12, "scene": 17, "line": "A tow truck is going to charge you one hundred bucks to take you a mile. Let me take a look.", "order": 24651, "speaker": "Prince Grandfather"},
  {"season": 5, "episode": 12, "scene": 17, "line": "No, no, really not necessary.", "order": 24652, "speaker": "Michael Scott"},
  {"season": 5, "episode": 12, "scene": 17, "line": "He drove over the divider and then backed over it and caused the..", "order": 24653, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 12, "scene": 17, "line": "I'll be right back I'm just going to grab my tool box.", "order": 24654, "speaker": "Prince Grandfather"},
  {"season": 5, "episode": 12, "scene": 18, "line": "Ladies, are we prepared to let the Kevins of the world decide anything for us? Anything at all? We don't even give him full internet access.", "order": 24655, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 12, "scene": 18, "line": "[surprised] Wait, what?", "order": 24656, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 12, "scene": 19, "line": "See that, the obvious symmetry of the face? That's a natural appeal of the scientific standard of coin aphelia, features that are a composite average of many features. Yes, she is attractive, but is not hot.", "order": 24657, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 12, "scene": 20, "line": "How much longer is this going to take?", "order": 24658, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 12, "scene": 20, "line": "You know let me give you some money for this.", "order": 24659, "speaker": "Michael Scott"},
  {"season": 5, "episode": 12, "scene": 20, "line": "No, I work at a desk all day, its nice working with my hands.", "order": 24660, "speaker": "Prince Son"},
  {"season": 5, "episode": 12, "scene": 20, "line": "Oh coffee, that's too much. [Dwight drinks it]", "order": 24661, "speaker": "Michael Scott"},
  {"season": 5, "episode": 12, "scene": 20, "line": "This is disgusting, what is it this, instant?", "order": 24662, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 12, "scene": 20, "line": "Well that outta do it. [bumper is heavily duct taped to the car]", "order": 24663, "speaker": "Prince Grandfather"},
  {"season": 5, "episode": 12, "scene": 20, "line": "Its all better.", "order": 24664, "speaker": "Prince Granddaughter"},
  {"season": 5, "episode": 12, "scene": 20, "line": "Thank you.", "order": 24665, "speaker": "Michael Scott"},
  {"season": 5, "episode": 12, "scene": 20, "line": "Gosh, well so long. [they exchange byes]", "order": 24666, "speaker": "Prince Grandfather"},
  {"season": 5, "episode": 12, "scene": 20, "line": "I look forward to hearing from you about that job application.", "order": 24667, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 12, "scene": 20, "line": "Ok, goodbye Prince Family.", "order": 24668, "speaker": "Michael Scott"},
  {"season": 5, "episode": 12, "scene": 20, "line": "Goodbye prince family, [to Michael] should be called the Sucker family. Here you go shark, let me fix your fin for you and sharpen your teeth while I'm at it. [Dwight laughs evilly, Michael seems remorseful] Bye bye.", "order": 24669, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 12, "scene": 21, "line": "[punches Michael on the arm, beckons to Michael for high five, does not get it, high fives himself, smiles contently]", "order": 24670, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 12, "scene": 22, "line": "[entire office is chatting] I'm saying, all I'm saying is Kevin is not necessarily a feminist, is all I'm saying.", "order": 24671, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 12, "scene": 22, "line": "No, because... [Dwight and Michael walk in and march into Michael's office, silencing the conversation]", "order": 24672, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 12, "scene": 22, "line": "Dwight, what will happen to that family if I call David and give him this information?", "order": 24673, "speaker": "Michael Scott"},
  {"season": 5, "episode": 12, "scene": 22, "line": "Its simple, David would use that information to destroy them.", "order": 24674, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 12, "scene": 22, "line": "Ok. You know, our sales are fine. We're doing fine. They're doing fine.", "order": 24675, "speaker": "Michael Scott"},
  {"season": 5, "episode": 12, "scene": 22, "line": "They could do better.", "order": 24676, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 12, "scene": 22, "line": "Why don't we just, live and let live?", "order": 24677, "speaker": "Michael Scott"},
  {"season": 5, "episode": 12, "scene": 22, "line": "What?", "order": 24678, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 12, "scene": 22, "line": "Live and let live.", "order": 24679, "speaker": "Michael Scott"},
  {"season": 5, "episode": 12, "scene": 22, "line": "I'm not familiar with that expression,", "order": 24680, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 12, "scene": 22, "line": "It's from James Bond.", "order": 24681, "speaker": "Michael Scott"},
  {"season": 5, "episode": 12, "scene": 22, "line": "It doesn't make sense, of course I'm alive.", "order": 24682, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 12, "scene": 22, "line": "I'm not going to make this call.", "order": 24683, "speaker": "Michael Scott"},
  {"season": 5, "episode": 12, "scene": 22, "line": "Michael, you have to. Have you ever seen a lioness devour its cub? Have you ever seen a baboon devour its mate? Have you ever seen a raccoon devour a squirrel?", "order": 24684, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 12, "scene": 22, "line": "My heart says...", "order": 24685, "speaker": "Michael Scott"},
  {"season": 5, "episode": 12, "scene": 22, "line": "Your heart is a wonderful thing, Michael. But it has made some terrible decisions.", "order": 24686, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 12, "scene": 22, "line": "That is true.", "order": 24687, "speaker": "Michael Scott"},
  {"season": 5, "episode": 12, "scene": 22, "line": "Yes.", "order": 24688, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 12, "scene": 22, "line": "That is true. We have gone down the wrong path many many times.", "order": 24689, "speaker": "Michael Scott"},
  {"season": 5, "episode": 12, "scene": 22, "line": "Yeah.", "order": 24690, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 12, "scene": 22, "line": "Jan... Ryan.", "order": 24691, "speaker": "Michael Scott"},
  {"season": 5, "episode": 12, "scene": 22, "line": "Yeah, save your heart for love, and use your brain for business, right? If we don't do this to prince paper, someone else will. Worse yet, someone else will do it to us.", "order": 24692, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 12, "scene": 23, "line": "I am trying to be more optimistic in life. I've got what, 20 or 30 years left. And my family history says I have less. Now, the old Stanley Hudson would have found something wrong this actress. But that is no way to live life. Look at this healthy sexy pretty strong young woman. C'mon people! She is hot.", "order": 24693, "speaker": "Stanley Hudson"},
  {"season": 5, "episode": 12, "scene": 24, "line": "There was a terrible war, ugh, so many died. Far too many died. But if Frodo hadn't destroyed the ring, then goodness itself might have died.", "order": 24694, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 12, "scene": 24, "line": "You're right.", "order": 24695, "speaker": "Michael Scott"},
  {"season": 5, "episode": 12, "scene": 24, "line": "Yeah.", "order": 24696, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 12, "scene": 24, "line": "You are right. I will call David and give him the customer list and I will give him all my info.", "order": 24697, "speaker": "Michael Scott"},
  {"season": 5, "episode": 12, "scene": 24, "line": "Good, so, [Michael takes client list and heads out of office] where are you going?", "order": 24698, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 12, "scene": 24, "line": "Just something I have to do first.", "order": 24699, "speaker": "Michael Scott"},
  {"season": 5, "episode": 12, "scene": 24, "line": "Where are you taking the client list?", "order": 24700, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 12, "scene": 24, "line": "Somewhere safe, where it can't hurt anyone [starts running, Dwight follows]", "order": 24701, "speaker": "Michael Scott"},
  {"season": 5, "episode": 12, "scene": 24, "line": "Michael, Michael, no. Michael, give that. Michael, don't do this. No. [chasing Michael out of the office into parking lot]", "order": 24702, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 12, "scene": 24, "line": "Oh shoot! [gets to his car turns around]", "order": 24703, "speaker": "Michael Scott"},
  {"season": 5, "episode": 12, "scene": 24, "line": "Freeze, give me the list! [still chasing him back into office where Michael grabs his car keys from his desk and heads back to parking lot]", "order": 24704, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 12, "scene": 24, "line": "No no no, I can't let you do this!", "order": 24705, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 12, "scene": 24, "line": "Those people will be ruined. [runs out back door]", "order": 24706, "speaker": "Michael Scott"},
  {"season": 5, "episode": 12, "scene": 24, "line": "Its business, not personal. [they run back outside, where Dwight gets paper from Michael]", "order": 24707, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 12, "scene": 24, "line": "Give it, give it. We can't do this to those people.", "order": 24708, "speaker": "Michael Scott"},
  {"season": 5, "episode": 12, "scene": 24, "line": "Its over, Michael, its over. [holding list of clients up]", "order": 24709, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 12, "scene": 24, "line": "Im not a shark.", "order": 24710, "speaker": "Michael Scott"},
  {"season": 5, "episode": 12, "scene": 25, "line": "Okay, it is time for the final votes. All of those in favor of resolution Hillary Swank is hot? [half of office raises hand] Okay. And all those opposed? [he skips back and joins other half of office in raising hand]", "order": 24711, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 12, "scene": 25, "line": "This is ridiculous.", "order": 24712, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 12, "scene": 25, "line": "That's the thing about debating, you're just going to get people more entrenched in the view they had in the first place. [two sides of the debate start arguing]", "order": 24713, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 12, "scene": 26, "line": "This is good stuff Michael. You are a titan of industry pal.", "order": 24714, "speaker": "David Wallace"},
  {"season": 5, "episode": 12, "scene": 26, "line": "[pained] Mpfh.", "order": 24715, "speaker": "Michael Scott"},
  {"season": 5, "episode": 12, "scene": 26, "line": "Good work.", "order": 24716, "speaker": "David Wallace"},
  {"season": 5, "episode": 12, "scene": 26, "line": "Thank you.", "order": 24717, "speaker": "Michael Scott"},
  {"season": 5, "episode": 12, "scene": 26, "line": "Look I'll be in touch, thanks buddy.", "order": 24718, "speaker": "David Wallace"},
  {"season": 5, "episode": 12, "scene": 26, "line": "Okay, bye bye.", "order": 24719, "speaker": "Michael Scott"},
  {"season": 5, "episode": 12, "scene": 26, "line": "Guess whose stock just went up, golden boy?", "order": 24720, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 12, "scene": 26, "line": "I guess this is what they call a bittersweet moment. It is bitter because I slightly destroyed a wonderful little family. But sweet because David Wallace thought I did a good job. That's what I hate bittersweet chocolate. I don't even... what's the point of that? Why not just sweet? Who are you helping?", "order": 24721, "speaker": "Michael Scott"},
  {"season": 5, "episode": 12, "scene": 27, "line": "Oh, hey what is this?", "order": 24722, "speaker": "Michael Scott"},
  {"season": 5, "episode": 12, "scene": 27, "line": "Hillary swank.", "order": 24723, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 12, "scene": 27, "line": "Oh, she is hot. ['Hillary Swank is hot' supporters cheer]", "order": 24724, "speaker": "Michael Scott"},
  {"season": 5, "episode": 13, "scene": 1, "line": "Last week I gave a fire safety talk. [clears throat] And nobody paid any attention. It's my own fault for using PowerPoint. PowerPoint is boring. People learn in a lot of different ways, but experience is the best teacher. [lights a cigarette] Today, smoking is gonna save lives. [throws cigarette into garbage can filled with paper and lighter fluid]", "order": 24725, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 13, "scene": 2, "line": "[looking around office to see if anyone notices the smoke] Does anyone smell anything smoky?", "order": 24726, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 13, "scene": 2, "line": "Did you bring your jerky in again?", "order": 24727, "speaker": "Angela Martin"},
  {"season": 5, "episode": 13, "scene": 2, "line": "[clears throat]", "order": 24728, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 13, "scene": 2, "line": "[points to smoke] Oh, my God! Uh, Oh my God!", "order": 24729, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 13, "scene": 2, "line": "What--", "order": 24730, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 13, "scene": 2, "line": "Whoa, fire!", "order": 24731, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 13, "scene": 2, "line": "Oh, fire! Oh my goodness! What's the procedure? What do we do, people?", "order": 24732, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 13, "scene": 2, "line": "The phones are dead.", "order": 24733, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 13, "scene": 2, "line": "Oh, how did that happen?", "order": 24734, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 13, "scene": 2, "line": "It's out in the hall.", "order": 24735, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 13, "scene": 2, "line": "No, we don't know that. The smoke could be coming through an air duct.", "order": 24736, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 13, "scene": 2, "line": "Oh my God! Okay, it happening. Everybody stay calm.", "order": 24737, "speaker": "Michael Scott"},
  {"season": 5, "episode": 13, "scene": 2, "line": "What's the procedure, everyone? What's the procedure?", "order": 24738, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 13, "scene": 2, "line": "Stay [bleep] calm!", "order": 24739, "speaker": "Michael Scott"},
  {"season": 5, "episode": 13, "scene": 2, "line": "Wait, wait, wait.", "order": 24740, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 13, "scene": 2, "line": "Everyone, now [bleep] calm down!", "order": 24741, "speaker": "Michael Scott"},
  {"season": 5, "episode": 13, "scene": 2, "line": "No! No, Michael! No! Touch the handle. If it's hot, there could be a fire in the hallway.", "order": 24742, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 13, "scene": 2, "line": "What does warm mean?", "order": 24743, "speaker": "Michael Scott"},
  {"season": 5, "episode": 13, "scene": 2, "line": "[groaning] Oh my God.", "order": 24744, "speaker": "Everyone"},
  {"season": 5, "episode": 13, "scene": 2, "line": "Not a viable option.", "order": 24745, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 13, "scene": 2, "line": "Try a different door.", "order": 24746, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 13, "scene": 2, "line": "Okay, what's next?", "order": 24747, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 13, "scene": 2, "line": "Don't run.", "order": 24748, "speaker": "Michael Scott"},
  {"season": 5, "episode": 13, "scene": 2, "line": "Oh! Here's a door. Check that one out. How's the handle?", "order": 24749, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 13, "scene": 2, "line": "It-- it's warm.", "order": 24750, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 13, "scene": 2, "line": "Well, uh, another option. [everyone chattering at once]", "order": 24751, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 13, "scene": 2, "line": "Back door.", "order": 24752, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 13, "scene": 2, "line": "Back to our options. Jeez! Ok! settle down everyone. No bunching!", "order": 24753, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 13, "scene": 2, "line": "Oh! I forgot my purse.", "order": 24754, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 13, "scene": 2, "line": "Leave it woman!", "order": 24755, "speaker": "Stanley Hudson"},
  {"season": 5, "episode": 13, "scene": 2, "line": "Get out of the way! Go, go, go!", "order": 24756, "speaker": "Michael Scott"},
  {"season": 5, "episode": 13, "scene": 2, "line": "Things can be replaced, Phyllis! People, human lives, however, can...", "order": 24757, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 13, "scene": 2, "line": "Ah! My hand! That's hot!", "order": 24758, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 13, "scene": 2, "line": "Aah! This ones hot too!", "order": 24759, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 13, "scene": 2, "line": "Okay, we're trapped. Everyone for himself.", "order": 24760, "speaker": "Michael Scott"},
  {"season": 5, "episode": 13, "scene": 2, "line": "Okay, let's go.", "order": 24761, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 13, "scene": 2, "line": "[shouting] Out of my way! Let's go. Get out of my way!", "order": 24762, "speaker": "Everyone"},
  {"season": 5, "episode": 13, "scene": 2, "line": "Calm, please", "order": 24763, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 13, "scene": 2, "line": "Get out of the way!", "order": 24764, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 13, "scene": 2, "line": "Have you ever seen a burn victim?", "order": 24765, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 13, "scene": 2, "line": "Move it!", "order": 24766, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 13, "scene": 2, "line": "Okay! Procedure, procedure. Exit options. Where do we go folks? Wha-- Use a what to cover the mouth?", "order": 24767, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 13, "scene": 2, "line": "[pulling cat out of filing drawer] It's okay. Shh shhh.", "order": 24768, "speaker": "Angela Martin"},
  {"season": 5, "episode": 13, "scene": 2, "line": "A what? A rag. A damp rag, perhaps. Let's remember those procedures. What are the options? Okay, that's the wrong way. We've already tried that. Remember your exit points. Exit points people.", "order": 24769, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 13, "scene": 2, "line": "Oscar.", "order": 24770, "speaker": "Angela Martin"},
  {"season": 5, "episode": 13, "scene": 2, "line": "What's next?", "order": 24771, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 13, "scene": 2, "line": "Oscar!", "order": 24772, "speaker": "Angela Martin"},
  {"season": 5, "episode": 13, "scene": 2, "line": "Stay alive! I'm getting help!", "order": 24773, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 13, "scene": 2, "line": "Pull me up!", "order": 24774, "speaker": "Angela Martin"},
  {"season": 5, "episode": 13, "scene": 2, "line": "You're too heavy!", "order": 24775, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 13, "scene": 2, "line": "I only weigh 82 pounds! Uh-- save Bandit! [throws cat into air duct and he falls out through the other side] Oh!", "order": 24776, "speaker": "Angela Martin"},
  {"season": 5, "episode": 13, "scene": 2, "line": "How about 911? Anyone? 911. [Michael throws a chair at the window, Kevin smashes a chair through the vending machine and begins to grab snacks, everyone is shouting.]", "order": 24777, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 13, "scene": 2, "line": "What do we do?", "order": 24778, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 13, "scene": 2, "line": "Use the surge of fear and adrenaline to sharpen your decision-making.", "order": 24779, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 13, "scene": 2, "line": "Okay, I am not dying here. Come on. [everyone is coughing from the smoke, Dwight lights some fire crackers and they start popping]", "order": 24780, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 13, "scene": 2, "line": "What is that? What is that?", "order": 24781, "speaker": "Angela Martin"},
  {"season": 5, "episode": 13, "scene": 2, "line": "The fire's shooting at us!", "order": 24782, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 13, "scene": 2, "line": "What in the name of God is going on?!", "order": 24783, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 13, "scene": 2, "line": "Yes! [Dwight pulls fire alarm] Yes, ba-- Yes, battering ram! Battering ram!", "order": 24784, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 13, "scene": 2, "line": "Ahhhh!!! [Oscar's leg crashes through the ceiling]", "order": 24785, "speaker": "Phyllis Vance & Creed Bratton"},
  {"season": 5, "episode": 13, "scene": 2, "line": "Go, go, go, go, go!! [Andy and Jim ram the door with the copy machine]", "order": 24786, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 13, "scene": 2, "line": "[throws the projector out the window] Help!! Help!!", "order": 24787, "speaker": "Michael Scott"},
  {"season": 5, "episode": 13, "scene": 2, "line": "I'm about to die!", "order": 24788, "speaker": "Stanley Hudson"},
  {"season": 5, "episode": 13, "scene": 2, "line": "[blowing air horn] Attention everyone! Employees of Dunder Mifflin! This has been a test of our emergency preparedness. There is no fire. It was only a simulation.", "order": 24789, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 13, "scene": 2, "line": "What?!", "order": 24790, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 13, "scene": 2, "line": "Fire not real. This was merely a training exercise. [Oscar drops down from the ceiling] So, what have we learned? [Stanley falls to the floor] Oh come on. It's not real Stanley. Don't have a heart attack.", "order": 24791, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 13, "scene": 2, "line": "No, no, no! You will not die! Stanley! Stanley! You will not die! Stanley! Stanley! Barack is president! You are black, Stanley! I'm gonna give him mouth to mouth.", "order": 24792, "speaker": "Michael Scott"},
  {"season": 5, "episode": 13, "scene": 2, "line": "No, no, no! Don't give him mouth to mouth for this!", "order": 24793, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 13, "scene": 2, "line": "He's going to swallow is tongue.", "order": 24794, "speaker": "Michael Scott"},
  {"season": 5, "episode": 13, "scene": 2, "line": "No. Michael. Michael.", "order": 24795, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 13, "scene": 2, "line": "Open your mouth. Come on. Don't swallow it.", "order": 24796, "speaker": "Michael Scott"},
  {"season": 5, "episode": 13, "scene": 2, "line": "[everyone shouting at once] Michael! Michael!", "order": 24797, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 13, "scene": 2, "line": "Leave me al--", "order": 24798, "speaker": "Michael Scott"},
  {"season": 5, "episode": 13, "scene": 2, "line": "You're choking him!", "order": 24799, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 13, "scene": 2, "line": "Saving him!", "order": 24800, "speaker": "Michael Scott"},
  {"season": 5, "episode": 13, "scene": 3, "line": "How could you possibly think this is a good idea?", "order": 24801, "speaker": "David Wallace"},
  {"season": 5, "episode": 13, "scene": 3, "line": "A lot of ideas were not appreciated in their time.", "order": 24802, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 13, "scene": 3, "line": "Electricity.", "order": 24803, "speaker": "Michael Scott"},
  {"season": 5, "episode": 13, "scene": 3, "line": "Shampoo.", "order": 24804, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 13, "scene": 3, "line": "You could have burned down the whole building.", "order": 24805, "speaker": "David Wallace"},
  {"season": 5, "episode": 13, "scene": 3, "line": "I just want to say for the record, I did not kill anyone. Stanley was attacked by his own heart. And he should be released from the hospital and back in the office in a couple days.", "order": 24806, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 13, "scene": 3, "line": "Did you shout, 'Fire!', causing a panic?", "order": 24807, "speaker": "Lawyer"},
  {"season": 5, "episode": 13, "scene": 3, "line": "Yes I shouted 'fire!'. I shouted many things! I also shouted instructions on how to get out of the building, so you can imagine my frustration as safety officer when nobody would heed of what-- heeded--", "order": 24808, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 13, "scene": 3, "line": "Hed. Hedded", "order": 24809, "speaker": "Michael Scott"},
  {"season": 5, "episode": 13, "scene": 3, "line": "When no one hedded--", "order": 24810, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 13, "scene": 3, "line": "Take hedded of.", "order": 24811, "speaker": "Michael Scott"},
  {"season": 5, "episode": 13, "scene": 3, "line": "N-no one would take hedded of my instructions.", "order": 24812, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 13, "scene": 3, "line": "Heed. Heed.", "order": 24813, "speaker": "Michael Scott"},
  {"season": 5, "episode": 13, "scene": 3, "line": "So, you--", "order": 24814, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 13, "scene": 3, "line": "Take heed of.", "order": 24815, "speaker": "Michael Scott"},
  {"season": 5, "episode": 13, "scene": 3, "line": "And, well, I don't see my co-workers--", "order": 24816, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 13, "scene": 3, "line": "Take heed of.", "order": 24817, "speaker": "Michael Scott"},
  {"season": 5, "episode": 13, "scene": 3, "line": "Hee-heeding this right now.", "order": 24818, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 13, "scene": 3, "line": "Wh--what?", "order": 24819, "speaker": "Lawyer"},
  {"season": 5, "episode": 13, "scene": 3, "line": "Okay. [walks to the window, sighs] This city. Dwight. We are not mad, we are just disappointed.", "order": 24820, "speaker": "Michael Scott"},
  {"season": 5, "episode": 13, "scene": 3, "line": "No, we are mad.", "order": 24821, "speaker": "David Wallace"},
  {"season": 5, "episode": 13, "scene": 3, "line": "Yes. We are. We are livid. But we are going to let this one slide.", "order": 24822, "speaker": "Michael Scott"},
  {"season": 5, "episode": 13, "scene": 3, "line": "No, we're not.", "order": 24823, "speaker": "David Wallace"},
  {"season": 5, "episode": 13, "scene": 3, "line": "I am not a mind reader, David.", "order": 24824, "speaker": "Michael Scott"},
  {"season": 5, "episode": 13, "scene": 3, "line": "Look, this is very serious offense. We have cause to fire you.", "order": 24825, "speaker": "David Wallace"},
  {"season": 5, "episode": 13, "scene": 3, "line": "Can you shove down? Instead... shove down, please. Instead, what I think we should do is strip of your title as safety officer.", "order": 24826, "speaker": "Michael Scott"},
  {"season": 5, "episode": 13, "scene": 3, "line": "No.", "order": 24827, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 13, "scene": 3, "line": "And we should take a part of his pay and donate it the charity of your choice. Something that Dwight doesn't like.", "order": 24828, "speaker": "Michael Scott"},
  {"season": 5, "episode": 13, "scene": 3, "line": "PETA.", "order": 24829, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 13, "scene": 3, "line": "Michael, you have to take responsibility here. One of your employees had a heart attack. He could have died, because of the way that you are allowing your office to run. Do you want that on your conscious?", "order": 24830, "speaker": "David Wallace"},
  {"season": 5, "episode": 13, "scene": 3, "line": "Do you?", "order": 24831, "speaker": "Michael Scott"},
  {"season": 5, "episode": 13, "scene": 3, "line": "Michael?", "order": 24832, "speaker": "David Wallace"},
  {"season": 5, "episode": 13, "scene": 3, "line": "You talking to me?", "order": 24833, "speaker": "Michael Scott"},
  {"season": 5, "episode": 13, "scene": 3, "line": "Yeah.", "order": 24834, "speaker": "David Wallace"},
  {"season": 5, "episode": 13, "scene": 3, "line": "What?", "order": 24835, "speaker": "Michael Scott"},
  {"season": 5, "episode": 13, "scene": 4, "line": "Well... I guess we papered over that pretty nicely. [chuckles] It always amuses me when corporate thinks they can make some big change with a twenty minute meeting in some fancy high-rise. What's the matter? You hungry?", "order": 24836, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 13, "scene": 4, "line": "[sighs] No, Dwight. I am worried. A man's life is in my hands.", "order": 24837, "speaker": "Michael Scott"},
  {"season": 5, "episode": 13, "scene": 4, "line": "Don't you worry about that. I got it covered. Okay?", "order": 24838, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 13, "scene": 4, "line": "Yeah.", "order": 24839, "speaker": "Michael Scott"},
  {"season": 5, "episode": 13, "scene": 4, "line": "I am planning a bomb scare that should really get the blood pumping.", "order": 24840, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 13, "scene": 4, "line": "That's not gonna happen. I'm taking over as safety man.", "order": 24841, "speaker": "Michael Scott"},
  {"season": 5, "episode": 13, "scene": 4, "line": "What? You?", "order": 24842, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 13, "scene": 4, "line": "Yes.", "order": 24843, "speaker": "Michael Scott"},
  {"season": 5, "episode": 13, "scene": 4, "line": "Come on.", "order": 24844, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 13, "scene": 4, "line": "I'm a smart guy. I'll figure it out.", "order": 24845, "speaker": "Michael Scott"},
  {"season": 5, "episode": 13, "scene": 4, "line": "That's preposterous.", "order": 24846, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 13, "scene": 4, "line": "No, I will.", "order": 24847, "speaker": "Michael Scott"},
  {"season": 5, "episode": 13, "scene": 5, "line": "Nobody should have to go to work thinking, 'Oh, this is the place that I might die today.' That's what a hospital is for. An office is for not dying. An office is a place to live life to the fullest, to the max, to... An office is a place where dreams come true.", "order": 24848, "speaker": "Michael Scott"},
  {"season": 5, "episode": 13, "scene": 6, "line": "Shhh! Don't excite him. Don't make him excitable. [whispering] Welcome back, Stanley.", "order": 24849, "speaker": "Michael Scott"},
  {"season": 5, "episode": 13, "scene": 6, "line": "Thank you, Michael.", "order": 24850, "speaker": "Stanley Hudson"},
  {"season": 5, "episode": 13, "scene": 7, "line": "It's true. Around this office, in the past, I have been a little abrupt with people...", "order": 24851, "speaker": "Stanley Hudson"},
  {"season": 5, "episode": 13, "scene": 8, "line": "[flashback] Not maybe. Yes or no.", "order": 24852, "speaker": "Stanley Hudson"},
  {"season": 5, "episode": 13, "scene": 8, "line": "[flashback] No way. Uh-uh.", "order": 24853, "speaker": "Stanley Hudson"},
  {"season": 5, "episode": 13, "scene": 8, "line": "[flashback] Are you from another planet?", "order": 24854, "speaker": "Stanley Hudson"},
  {"season": 5, "episode": 13, "scene": 8, "line": "[flashback] Boy, have you lost your mind? 'Cause I'll help you find it.", "order": 24855, "speaker": "Stanley Hudson"},
  {"season": 5, "episode": 13, "scene": 8, "line": "[flashback] Did I stutter?", "order": 24856, "speaker": "Stanley Hudson"},
  {"season": 5, "episode": 13, "scene": 8, "line": "[flashback] I'm done. Goodbye.", "order": 24857, "speaker": "Stanley Hudson"},
  {"season": 5, "episode": 13, "scene": 9, "line": "But the doctor said if I can't find a new way to relate more positively to my surroundings I'm going to die.", "order": 24858, "speaker": "Stanley Hudson"},
  {"season": 5, "episode": 13, "scene": 10, "line": "Andy.", "order": 24859, "speaker": "Michael Scott"},
  {"season": 5, "episode": 13, "scene": 10, "line": "[in a British accent] A throne for your highness.", "order": 24860, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 13, "scene": 10, "line": "I'm not sitting in a wheelchair.", "order": 24861, "speaker": "Stanley Hudson"},
  {"season": 5, "episode": 13, "scene": 10, "line": "No, no, no. No debate. You are going to sit in that wheelchair until you are back on your feet.", "order": 24862, "speaker": "Michael Scott"},
  {"season": 5, "episode": 13, "scene": 11, "line": "I'm going to die.", "order": 24863, "speaker": "Stanley Hudson"},
  {"season": 5, "episode": 13, "scene": 12, "line": "A-B-C. Okay? And hat stands for... airway, breathing and circulation.", "order": 24864, "speaker": "CPR trainer"},
  {"season": 5, "episode": 13, "scene": 12, "line": "Okay, you know what? That could be a little confusing, because in sales A-B-C means 'always be closing.'", "order": 24865, "speaker": "Michael Scott"},
  {"season": 5, "episode": 13, "scene": 12, "line": "This is a farce. I should be teaching this course.", "order": 24866, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 13, "scene": 12, "line": "Shut it. Shut it.", "order": 24867, "speaker": "Michael Scott"},
  {"season": 5, "episode": 13, "scene": 13, "line": "We found ourselves on the less prepared side of things when Stanley had his... when his heart went berserk. And I knew exactly what to do, but in a much more real sense I had no idea what to do. So I thought we should have CPR training class and of course you can't get the practice dummy unless the instructor comes along with it. Red Cross, you know, racket.", "order": 24868, "speaker": "Michael Scott"},
  {"season": 5, "episode": 13, "scene": 14, "line": "[giving chest compressions to the practice dummy] I can't keep doing this forever.", "order": 24869, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 13, "scene": 14, "line": "It's been 20 seconds.", "order": 24870, "speaker": "CPR Trainer"},
  {"season": 5, "episode": 13, "scene": 14, "line": "Call it.", "order": 24871, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 13, "scene": 14, "line": "Would you like to try next?", "order": 24872, "speaker": "CPR Trainer"},
  {"season": 5, "episode": 13, "scene": 14, "line": "Absolutely I would not.", "order": 24873, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 13, "scene": 14, "line": "You know who I really think should go? Stanley.", "order": 24874, "speaker": "Michael Scott"},
  {"season": 5, "episode": 13, "scene": 14, "line": "Oh, I don't know.", "order": 24875, "speaker": "Stanley Hudson"},
  {"season": 5, "episode": 13, "scene": 14, "line": "That's not a good idea, Michael.", "order": 24876, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 13, "scene": 14, "line": "Come on.", "order": 24877, "speaker": "Michael Scott"},
  {"season": 5, "episode": 13, "scene": 14, "line": "He needs to rest.", "order": 24878, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 13, "scene": 14, "line": "No rest for the sick. We are not always going to be there to coddle your heart back when it disappears to be working. What are you gonna do if you're by yourself and your heart stops?", "order": 24879, "speaker": "Michael Scott"},
  {"season": 5, "episode": 13, "scene": 14, "line": "I would die.", "order": 24880, "speaker": "Stanley Hudson"},
  {"season": 5, "episode": 13, "scene": 14, "line": "And you're okay with that?", "order": 24881, "speaker": "Michael Scott"},
  {"season": 5, "episode": 13, "scene": 14, "line": "I'm okay with the logic of it.", "order": 24882, "speaker": "Stanley Hudson"},
  {"season": 5, "episode": 13, "scene": 14, "line": "Uh-uh. No, no, no. Come on. Get up. Let's do this.", "order": 24883, "speaker": "Michael Scott"},
  {"season": 5, "episode": 13, "scene": 15, "line": "Yes. I had a heart attack. I would quit, but I'm too old to find another job and I don't have enough saved to retire. I feel like I'm working in my own casket.", "order": 24884, "speaker": "Stanley Hudson"},
  {"season": 5, "episode": 13, "scene": 16, "line": "Come on Stanley. You're losing you. You're losing you. Do it!", "order": 24885, "speaker": "Michael Scott"},
  {"season": 5, "episode": 13, "scene": 16, "line": "Michael!", "order": 24886, "speaker": "Angela Martin"},
  {"season": 5, "episode": 13, "scene": 16, "line": "This is you we're talking about.", "order": 24887, "speaker": "Michael Scott"},
  {"season": 5, "episode": 13, "scene": 16, "line": "Michael.", "order": 24888, "speaker": "Angela Martin"},
  {"season": 5, "episode": 13, "scene": 16, "line": "Okay, okay. I'll show them. Here we go. [whispers] Stanley. All right.", "order": 24889, "speaker": "Michael Scott"},
  {"season": 5, "episode": 13, "scene": 16, "line": "So, assessing the situation. Are they breathing?", "order": 24890, "speaker": "CPR Trainer"},
  {"season": 5, "episode": 13, "scene": 16, "line": "No, Rose. They are not breathing. And they have no arms or legs.", "order": 24891, "speaker": "Michael Scott"},
  {"season": 5, "episode": 13, "scene": 16, "line": "No, that's not part of it.", "order": 24892, "speaker": "Rose"},
  {"season": 5, "episode": 13, "scene": 16, "line": "Where are they? You know what? If we come across somebody with no arms or legs do we bother resuscitating them? I mean, what kind of quality of life do we have there?", "order": 24893, "speaker": "Michael Scott"},
  {"season": 5, "episode": 13, "scene": 16, "line": "I would want to live with no legs.", "order": 24894, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 13, "scene": 16, "line": "How about no arms? No arms or legs is basically how you exist right now, Kevin. You don't do anything.", "order": 24895, "speaker": "Michael Scott"},
  {"season": 5, "episode": 13, "scene": 16, "line": "All right, well, lets get back to it. 'Cause you're losing him. Okay, too fast. Everyone, we need to pump at a pace of a 100 beats per minute .", "order": 24896, "speaker": "Rose"},
  {"season": 5, "episode": 13, "scene": 16, "line": "okay, that's uh, hard to keep track. How many is that per hour?", "order": 24897, "speaker": "Michael Scott"},
  {"season": 5, "episode": 13, "scene": 16, "line": "How's that gonna help you?", "order": 24898, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 13, "scene": 16, "line": "I will divide and then count to it.", "order": 24899, "speaker": "Michael Scott"},
  {"season": 5, "episode": 13, "scene": 16, "line": "Right.", "order": 24900, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 13, "scene": 16, "line": "Okay. Well, a good trick is to pump to the tune of 'Staying Alive' by the Bee Gees. Do you know that song?", "order": 24901, "speaker": "Rose"},
  {"season": 5, "episode": 13, "scene": 16, "line": "Yes, yes I do. I love that song. [clears throat, begins to sing] First I was afraid, I was petrified.", "order": 24902, "speaker": "Michael Scott"},
  {"season": 5, "episode": 13, "scene": 16, "line": "No, it's--Ah, Ah, Ah, Ah, stayin' alive, stayin' alive.", "order": 24903, "speaker": "Rose"},
  {"season": 5, "episode": 13, "scene": 16, "line": "Okay, I got it.", "order": 24904, "speaker": "Michael Scott"},
  {"season": 5, "episode": 13, "scene": 16, "line": "[to Rose] You were in the parking lot earlier. That's how I know you.", "order": 24905, "speaker": "Creed Bratton"},
  {"season": 5, "episode": 13, "scene": 16, "line": "Ah, Ah, Ah, Ah, stayin' alive, stayin' alive [Andy joins in] Ah, Ah, Ah, Ah, stayin' alive, stayin' alive.", "order": 24906, "speaker": "Michael Scott"},
  {"season": 5, "episode": 13, "scene": 16, "line": "Ah, Ah, Ah, Ah...", "order": 24907, "speaker": "Michael Scott"},
  {"season": 5, "episode": 13, "scene": 16, "line": "Oh you can tell by the way I use my walk I'm a woman's man, no time to talk. Music loud, women warm, been kicked around since I was born. Oh, it's alright, it's okay, [Michael and Kelly join in] you can look the other way. Loo do do!", "order": 24908, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 13, "scene": 16, "line": "Okay!", "order": 24909, "speaker": "Rose"},
  {"season": 5, "episode": 13, "scene": 16, "line": "Da, da, da, da, da, da, da", "order": 24910, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 13, "scene": 16, "line": "Okay!", "order": 24911, "speaker": "Rose"},
  {"season": 5, "episode": 13, "scene": 16, "line": "[muttering] Stayin' alive, stayin' alive.", "order": 24912, "speaker": "Everyone"},
  {"season": 5, "episode": 13, "scene": 16, "line": "Yeah, okay. You didn't maintain 100 beats per minute, and the ambulance didn't arrive because nobody called 911. So you lost him.", "order": 24913, "speaker": "Rose"},
  {"season": 5, "episode": 13, "scene": 16, "line": "Okay, he's dead. Anyone know what we do next? Anybody? Rose?", "order": 24914, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 13, "scene": 16, "line": "I have no idea.", "order": 24915, "speaker": "Rose"},
  {"season": 5, "episode": 13, "scene": 16, "line": "Anyone else?", "order": 24916, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 13, "scene": 16, "line": "We bury him?", "order": 24917, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 13, "scene": 16, "line": "Wrong. Ehh. Check for an organ donor card. If he has one, we only have minutes to harvest.", "order": 24918, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 13, "scene": 16, "line": "He has no wallet, I checked.", "order": 24919, "speaker": "Creed Bratton"},
  {"season": 5, "episode": 13, "scene": 16, "line": "He is an organ donor.", "order": 24920, "speaker": "Michael Scott"},
  {"season": 5, "episode": 13, "scene": 16, "line": "He is.", "order": 24921, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 13, "scene": 16, "line": "Yeah.", "order": 24922, "speaker": "Michael Scott"},
  {"season": 5, "episode": 13, "scene": 16, "line": "Get me some ice and a styrofoam bucket. [pulls out knife from holder that is attached to his leg] Here we go.", "order": 24923, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 13, "scene": 16, "line": "Oh my God! Dwight!", "order": 24924, "speaker": "Angela Martin"},
  {"season": 5, "episode": 13, "scene": 16, "line": "Dwight!", "order": 24925, "speaker": "Kelly Kapoor"},
  {"season": 5, "episode": 13, "scene": 16, "line": "What are you-- [people are yelling] What are you doing?", "order": 24926, "speaker": "Angela Martin"},
  {"season": 5, "episode": 13, "scene": 16, "line": "We search for the organs. Where's the heart? The precious heart.", "order": 24927, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 13, "scene": 16, "line": "I'm not feeling well. I need to sit down.", "order": 24928, "speaker": "Stanley Hudson"},
  {"season": 5, "episode": 13, "scene": 16, "line": "Hey, Stanley.", "order": 24929, "speaker": "Michael Scott"},
  {"season": 5, "episode": 13, "scene": 16, "line": "Stanley.", "order": 24930, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 13, "scene": 16, "line": "Are you okay?", "order": 24931, "speaker": "Michael Scott"},
  {"season": 5, "episode": 13, "scene": 16, "line": "Oh my God! [Dwight has cut the face off the CPR dummy and put it over his own]", "order": 24932, "speaker": "Angela Martin"},
  {"season": 5, "episode": 13, "scene": 16, "line": "Oh my God!", "order": 24933, "speaker": "Stanley Hudson"},
  {"season": 5, "episode": 13, "scene": 16, "line": "Dwight!", "order": 24934, "speaker": "Angela Martin"},
  {"season": 5, "episode": 13, "scene": 16, "line": "Clarice?", "order": 24935, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 13, "scene": 16, "line": "Oh my God!", "order": 24936, "speaker": "Stanley Hudson"},
  {"season": 5, "episode": 13, "scene": 17, "line": "Could you tell me why you had to cut the face off the dummy?", "order": 24937, "speaker": "David Wallace"},
  {"season": 5, "episode": 13, "scene": 17, "line": "I didn't think it was very realistic in the movie and it turns out, it's pretty realistic.", "order": 24938, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 13, "scene": 17, "line": "We had to pay for it. Cost us thirty five hundred dollars.", "order": 24939, "speaker": "David Wallace"},
  {"season": 5, "episode": 13, "scene": 17, "line": "Five thousand three hundred dollars for a dummy?", "order": 24940, "speaker": "Michael Scott"},
  {"season": 5, "episode": 13, "scene": 17, "line": "Wow.", "order": 24941, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 13, "scene": 17, "line": "Okay, look. David, this is why we have training. We start with the dummy, and we learn from our mistakes. And now Dwight knows not to cut the face off of a real person.", "order": 24942, "speaker": "Michael Scott"},
  {"season": 5, "episode": 13, "scene": 18, "line": "Hope you brought your appetitos. My lady. My tuna. A little movie popcorn. [dumps popcorn in bowl, it's almost all seeds] Uh, damn it. This movie, a lot of buzz. Not coming out for another six months. Mrs. Albert Hannaday. So, friendship with the Nard-dog has its privileges.", "order": 24943, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 13, "scene": 19, "line": "We don't normally download films illegally. Because we're honest, hardworking people.", "order": 24944, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 13, "scene": 19, "line": "And we don't know how.", "order": 24945, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 13, "scene": 19, "line": "But Andy does, so we have to watch it with him.", "order": 24946, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 13, "scene": 19, "line": "Punishment fits the crime.", "order": 24947, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 13, "scene": 20, "line": "[movie] I want you to meet my nana. Nana...", "order": 24948, "speaker": "Jessica Alba"},
  {"season": 5, "episode": 13, "scene": 20, "line": "Mmmhmm...", "order": 24949, "speaker": "Lily"},
  {"season": 5, "episode": 13, "scene": 20, "line": "This is Sam.", "order": 24950, "speaker": "Jessica Alba"},
  {"season": 5, "episode": 13, "scene": 20, "line": "Hi. Nice to meet you Mrs. Hannaday.", "order": 24951, "speaker": "Sam"},
  {"season": 5, "episode": 13, "scene": 20, "line": "Please-- Call me Lily. [light chuckle] Let's play Bridge. You can be my partner.", "order": 24952, "speaker": "Lily"},
  {"season": 5, "episode": 13, "scene": 20, "line": "Alright... Lily.", "order": 24953, "speaker": "Sam"},
  {"season": 5, "episode": 13, "scene": 21, "line": "[after Pam checks her cell phone] What's going on?", "order": 24954, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 13, "scene": 22, "line": "Uh, no one really knows, but Pam's parents are going through a little bit of a rough patch in their marriage.", "order": 24955, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 13, "scene": 23, "line": "My dad spent the night at our place last night. My parents have been fighting for weeks and... it kinda sucks. Jim's been great. But I'm gonna need to buy my dad a robe.", "order": 24956, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 13, "scene": 24, "line": "He's not saying what he needs to say.", "order": 24957, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 13, "scene": 24, "line": "Hmm? Who? Sam?", "order": 24958, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 13, "scene": 24, "line": "Yeah.", "order": 24959, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 13, "scene": 24, "line": "I mean, they just need to communicate, you know? If they said to each other what they're saying to everyone else, then--", "order": 24960, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 13, "scene": 24, "line": "Hmm.", "order": 24961, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 13, "scene": 25, "line": "Jim and Pam are, like, movie geniuses. They're catching things that are totally going over my head.", "order": 24962, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 13, "scene": 26, "line": "Hi, everyone. Corporate has given Dwight two strikes. They are very, very upset with him. So, as a disciplinary measure he is going to have to issue a formal apology. Dwight, have you prepared your statement of regret?", "order": 24963, "speaker": "Michael Scott"},
  {"season": 5, "episode": 13, "scene": 26, "line": "I have.", "order": 24964, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 13, "scene": 26, "line": "Let's hear it.", "order": 24965, "speaker": "Michael Scott"},
  {"season": 5, "episode": 13, "scene": 26, "line": "[clears throat] 'I state my regret.'", "order": 24966, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 13, "scene": 26, "line": "You couldn't of memorized that?", "order": 24967, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 13, "scene": 26, "line": "I could not, because I do not feel it. Okay, everyone. I am going to need you to sign this statement of regret as an acknowledgement that you heard it. Okay? Everyone come on up here. It's not a big deal.", "order": 24968, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 13, "scene": 26, "line": "It is a big deal. You almost killed Stanley.", "order": 24969, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 13, "scene": 26, "line": "Yeah, right. I filled him full of butter and sugar for 50 years and forced him not to exercise. Now, take a lesson from Stanley and jog on up here and sign this, okay? Make a line. Just form a line right here. Sign it! Sign it now!", "order": 24970, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 13, "scene": 27, "line": "Hi dad. Yeah Jim has shaving cream, check our bathroom.", "order": 24971, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 13, "scene": 28, "line": "[movie] I'm in here.", "order": 24972, "speaker": "Lily"},
  {"season": 5, "episode": 13, "scene": 28, "line": "I uh, I'm sorry I didn't uh, realize you were in a bath. Do you want me to go?", "order": 24973, "speaker": "Sam"},
  {"season": 5, "episode": 13, "scene": 28, "line": "I want you to stay.", "order": 24974, "speaker": "Lily"},
  {"season": 5, "episode": 13, "scene": 29, "line": "Gimme a break.", "order": 24975, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 13, "scene": 29, "line": "I know it man. You know, Lilly was supposed to be Nicole Kidman, um and it was gonna be Sophie's mom, not grandmother. But then Nicole kidman dropped out so they went with Lily, with a small rewrite.", "order": 24976, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 13, "scene": 30, "line": "[movie] Get that in there. Oh yeah.", "order": 24977, "speaker": "Sam"},
  {"season": 5, "episode": 13, "scene": 30, "line": "Get that done already.", "order": 24978, "speaker": "Lily"},
  {"season": 5, "episode": 13, "scene": 30, "line": "I know but if I get it in deeper...", "order": 24979, "speaker": "Sam"},
  {"season": 5, "episode": 13, "scene": 31, "line": "[to Pam] Can you believe this? [pause] He was pretty talkative at breakfast.", "order": 24980, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 13, "scene": 31, "line": "Yeah... but... eh... breakfast? You mean when they were eating soup? I don't think that was breakfast. Unless soup, does soup symbolize breakfast?", "order": 24981, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 13, "scene": 31, "line": "So he doesn't share it with his daughter but he shares it with his daughter's fiance?", "order": 24982, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 13, "scene": 31, "line": "You guys, they're making out.", "order": 24983, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 13, "scene": 32, "line": "Hey.", "order": 24984, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 13, "scene": 32, "line": "Hey.", "order": 24985, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 13, "scene": 32, "line": "Um, did my dad say anything about my mom?", "order": 24986, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 13, "scene": 32, "line": "Mmm... nope, we mostly just talked about cereal.", "order": 24987, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 13, "scene": 32, "line": "[sighs]", "order": 24988, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 13, "scene": 32, "line": "What?", "order": 24989, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 13, "scene": 32, "line": "I dunno. I mean, maybe he'll talk to you about some of this stuff 'cause he can't really talk to me about it.", "order": 24990, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 13, "scene": 32, "line": "Mmm...", "order": 24991, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 13, "scene": 32, "line": "You're good to talk to.", "order": 24992, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 13, "scene": 32, "line": "[laughs] I'm ok, I'm not, great, and um... [nods]", "order": 24993, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 13, "scene": 33, "line": "[in a monk-impression tone] Ohmmm... Ohmmm... Everybody sit on the floor Indian style like me. [Meredtih sits Indian style in a dress exposing herself right in front of Michael] Ohmmm my God if you're wearing a dress please keep your knees together nobody wants to see that ohmmm... ohmmm...", "order": 24994, "speaker": "Michael Scott"},
  {"season": 5, "episode": 13, "scene": 34, "line": "My goal is to make this office as peaceful a place for Stanley Hudson as I can. I think sounds have a lot to do with that. Here are some downloaded peaceful sounds. [a couple of sounds play on the computer] That one makes me think... of death. It's kinda nice...", "order": 24995, "speaker": "Michael Scott"},
  {"season": 5, "episode": 13, "scene": 35, "line": "[everyone is lying on the floor in the conference room with the light off, Michael is walking around with a candle in his hand] It is a beautiful, sunny day as we walk through the meadow that is very spiritual and relaxing and there are flowers and it is sunny and beautiful. Now, up ahead, a castle, in a distance.", "order": 24996, "speaker": "Michael Scott"},
  {"season": 5, "episode": 13, "scene": 35, "line": "Don't open your eyes.", "order": 24997, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 13, "scene": 35, "line": "What? [opens her eyes, Michael is standing over her] Oh...", "order": 24998, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 13, "scene": 35, "line": "And you walk up toward the castle, and inside the castle are 4 men, and each of them, none of them have shoes, and they give you a funny cigarette, and you feel even more relaxed, and then you want ice cream. You want a big bowl of ice cream. What kind do you want? Shout it out.", "order": 24999, "speaker": "Michael Scott"},
  {"season": 5, "episode": 13, "scene": 35, "line": "Chunky monkey.", "order": 25000, "speaker": "Meredith Palmer"},
  {"season": 5, "episode": 13, "scene": 35, "line": "Too expensive.", "order": 25001, "speaker": "Michael Scott"},
  {"season": 5, "episode": 13, "scene": 35, "line": "Chocolate.", "order": 25002, "speaker": "Stanley Hudson"},
  {"season": 5, "episode": 13, "scene": 35, "line": "Racism is dead Stanley, you can have any kind of ice cream you want. What do you want? [beeping starts] What is that? People! Please I told you to get rid of the cell phones.", "order": 25003, "speaker": "Michael Scott"},
  {"season": 5, "episode": 13, "scene": 35, "line": "It's my bio-feedback machine..", "order": 25004, "speaker": "Stanley Hudson"},
  {"season": 5, "episode": 13, "scene": 35, "line": "Oh, ok. What is that, like, a video game?", "order": 25005, "speaker": "Michael Scott"},
  {"season": 5, "episode": 13, "scene": 35, "line": "It alerts me when my stress level goes up so I can try to calm down.", "order": 25006, "speaker": "Stanley Hudson"},
  {"season": 5, "episode": 13, "scene": 35, "line": "You have stress?", "order": 25007, "speaker": "Michael Scott"},
  {"season": 5, "episode": 13, "scene": 35, "line": "Yes...", "order": 25008, "speaker": "Stanley Hudson"},
  {"season": 5, "episode": 13, "scene": 35, "line": "During our relaxation exercise?", "order": 25009, "speaker": "Michael Scott"},
  {"season": 5, "episode": 13, "scene": 35, "line": "Let me get you some water.", "order": 25010, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 13, "scene": 35, "line": "No no, I'll help you. I'll help you up. Here we go.", "order": 25011, "speaker": "Michael Scott"},
  {"season": 5, "episode": 13, "scene": 35, "line": "No, Michael, No. [beeping speeds up]", "order": 25012, "speaker": "Stanley Hudson"},
  {"season": 5, "episode": 13, "scene": 35, "line": "Let me getcha.", "order": 25013, "speaker": "Michael Scott"},
  {"season": 5, "episode": 13, "scene": 35, "line": "Would ya, would ya step back please", "order": 25014, "speaker": "Stanley Hudson"},
  {"season": 5, "episode": 13, "scene": 35, "line": "Ok, alright.", "order": 25015, "speaker": "Michael Scott"},
  {"season": 5, "episode": 13, "scene": 35, "line": "Please. A little further.", "order": 25016, "speaker": "Stanley Hudson"},
  {"season": 5, "episode": 13, "scene": 35, "line": "Ok. [beeping slows down]", "order": 25017, "speaker": "Michael Scott"},
  {"season": 5, "episode": 13, "scene": 35, "line": "That's better. [Michael walks back towards Stanley and the beeping goes up again]", "order": 25018, "speaker": "Stanley Hudson"},
  {"season": 5, "episode": 13, "scene": 35, "line": "Ok... I think that thing is on the fritz. Ahh... Oscar, would you reach over and touch his thing? That's what HE said! Right guys, 'cause of gay? [Oscar has Stanley's monitor now] Let's give this a shot.", "order": 25019, "speaker": "Michael Scott"},
  {"season": 5, "episode": 13, "scene": 35, "line": "Hellloo... [beeping speeds up as Michael gets closer]", "order": 25020, "speaker": "Michael Scott"},
  {"season": 5, "episode": 13, "scene": 35, "line": "Michael, I think you're what's stressing everybody out.", "order": 25021, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 13, "scene": 36, "line": "So... it wasn't Dwight after all. Looks like I am the killer. You never expect that you're the killer... it's uh... great twist. Great twist.", "order": 25022, "speaker": "Michael Scott"},
  {"season": 5, "episode": 13, "scene": 37, "line": "Dunder Mifflin this is Pam. Oh, hey Mom. No, what did Dad say?", "order": 25023, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 13, "scene": 38, "line": "What did you say to my dad?", "order": 25024, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 13, "scene": 38, "line": "What?", "order": 25025, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 13, "scene": 38, "line": "After you talked, he called my mom and said he was gonna look for an apartment.", "order": 25026, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 13, "scene": 38, "line": "Oh my God. Pam, I don't know, I, nothing, truly, nothing. I mean, I, I just was honest with him and I, I'm so sorry, I don't know. I'll call him again. [Pam rolls her eyes and walks away]", "order": 25027, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 13, "scene": 39, "line": "Fifty percent of marriages end in divorce, so... it was her parents or my parents...", "order": 25028, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 13, "scene": 40, "line": "What could Jim have said to make my dad want to leave my mom? And at what point in our marriage is he gonna say it to me?", "order": 25029, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 13, "scene": 41, "line": "I don't get it. Do I stress you out?", "order": 25030, "speaker": "Michael Scott"},
  {"season": 5, "episode": 13, "scene": 41, "line": "Nothing stresses me out. Except having to seek the approval of my inferiors.", "order": 25031, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 13, "scene": 41, "line": "Oh...", "order": 25032, "speaker": "Michael Scott"},
  {"season": 5, "episode": 13, "scene": 41, "line": "Speaking of which... [hands Michael the paper to sign for Dwight's formal apology]", "order": 25033, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 13, "scene": 41, "line": "Remember when people used to say boss, when they were describing something that was really cool like, those shoulder pads are really boss man... Look at that perm, that perm is so boss. It's what made me want to become a boss. And I looked so good in a perm and shoulder pads. But now, boss is just slang for jerk in charge.", "order": 25034, "speaker": "Michael Scott"},
  {"season": 5, "episode": 13, "scene": 42, "line": "Ok everybody, I've figured it out. The reason that you are all so stressed around me is that you are too intimidated to tell me what you really think. [Jim shakes his head at the camera] You are keeping these feelings inside, and that is what's causing stress. So, what is the solution? Solution is honesty, laughter, and comedy. In short...", "order": 25035, "speaker": "Michael Scott"},
  {"season": 5, "episode": 13, "scene": 42, "line": "A vacation.", "order": 25036, "speaker": "Kelly Kapoor"},
  {"season": 5, "episode": 13, "scene": 42, "line": "What? No. No, I am talking about a roast! Of Michael Scott! Oh c'mon! Who here has the Comedy Central Roast channel? You've seen it right? Everybody gets together, and everyone starts hurling insults at the one guy, and everybody's laughing, and everybody's hugging each other...", "order": 25037, "speaker": "Michael Scott"},
  {"season": 5, "episode": 13, "scene": 42, "line": "Michael are you serious? You really want us to roast you?", "order": 25038, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 13, "scene": 42, "line": "Si senor.", "order": 25039, "speaker": "Michael Scott"},
  {"season": 5, "episode": 13, "scene": 42, "line": "That's offensive.", "order": 25040, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 13, "scene": 42, "line": "It's not! It's not offensive during a roast! Anything goes! I want you guys to really get crackin on this. I want you to take me down. Don't hold back. I want you to really make fun of anything about me. It could be my race, could be the fact that I'm so fit, or I'm a womanizer... fair game. Whatever. I don't want to write your stuff for you, but I just want it to be good.", "order": 25041, "speaker": "Michael Scott"},
  {"season": 5, "episode": 13, "scene": 43, "line": "[giggling] Oh my GOD... Oh man... Ohhhhh my God...", "order": 25042, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 13, "scene": 44, "line": "I consider myself a good person. But I'm gonna try to make him cry.", "order": 25043, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 13, "scene": 45, "line": "I can already feel people's stress starting to melt. I think they're very excited about paying their respects this way. I have got to make sure that YouTube comes down to tape this.", "order": 25044, "speaker": "Michael Scott"},
  {"season": 5, "episode": 13, "scene": 46, "line": "May I have your attention please? Sign in, sign in on the sign in sheet, the clipboard. This meeting is mandatory, if you do not sign in your name will not be counted. Thank you.", "order": 25045, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 13, "scene": 46, "line": "Hey, this is your apology letter.", "order": 25046, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 13, "scene": 46, "line": "That was the last signature I needed.", "order": 25047, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 13, "scene": 46, "line": "Whoo!! Welcome welcome welcome welcome welcome. You are all jerks. Just kidding, not yet anyway. Welcome to the roast of Mr. Michael Scott. If you're here for the Grabowski wedding, it is the second door on the left. [Creed looks around] So, we all know how these work, needs to get crazy, take your best shot. I am going to sit right here on my chair and... ah... whoever wants to come up and roast me, you may. [Angela jumps up] Ok... lower the mic for the midget.", "order": 25048, "speaker": "Michael Scott"},
  {"season": 5, "episode": 13, "scene": 46, "line": "If you ever wondered if you were Michael Scott, here's a quiz to help. If you ever put sunblock on a window, you might be Michael Scott. [laughter]", "order": 25049, "speaker": "Angela Martin"},
  {"season": 5, "episode": 13, "scene": 47, "line": "I normally don't enjoy making people laugh. [grin]", "order": 25050, "speaker": "Angela Martin"},
  {"season": 5, "episode": 13, "scene": 48, "line": "If you ever called the fire department 'cause your head was stuck in your chair you might be...", "order": 25051, "speaker": "Angela Martin"},
  {"season": 5, "episode": 13, "scene": 48, "line": "Michael Scott! [laughter]", "order": 25052, "speaker": "Group"},
  {"season": 5, "episode": 13, "scene": 48, "line": "Hey Hey, I don't go make burgers where you work and then tell you how to make burgers. Ha ha!", "order": 25053, "speaker": "Michael Scott"},
  {"season": 5, "episode": 13, "scene": 49, "line": "I have made a list of people that I would make out with before I would make out with Michael Scott. A turtle, a fridge, anybody from the warehouse, a woodchipper, Kevin, a candle, and Lord Voldemort. Anyway, Happy Birthday Michael.", "order": 25054, "speaker": "Kelly Kapoor"},
  {"season": 5, "episode": 13, "scene": 49, "line": "You're so lucky! Good one... [clapping]", "order": 25055, "speaker": "Michael Scott"},
  {"season": 5, "episode": 13, "scene": 50, "line": "Michael? You ran over me with your car. You posted a picture of my bare boobs on the bulletin board with a caption that said 'Gross'...", "order": 25056, "speaker": "Meredith Palmer"},
  {"season": 5, "episode": 13, "scene": 50, "line": "Well...", "order": 25057, "speaker": "Michael Scott"},
  {"season": 5, "episode": 13, "scene": 50, "line": "Michael, you are the reason I drink. You are the reason I live to forget.", "order": 25058, "speaker": "Meredith Palmer"},
  {"season": 5, "episode": 13, "scene": 50, "line": "Pow pow pow... [hands making gunshot impression, sporadic clapping]", "order": 25059, "speaker": "Michael Scott"},
  {"season": 5, "episode": 13, "scene": 51, "line": "[yelling at Michael in Spanish]", "order": 25060, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 13, "scene": 52, "line": "[Toby tries to come on stage] NO! No, friends only. Friends ONLY. [Toby shrugs and sits back down]", "order": 25061, "speaker": "Michael Scott"},
  {"season": 5, "episode": 13, "scene": 53, "line": "Several times a day, Michael says words that are way beyond my vocabulary.", "order": 25062, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 13, "scene": 53, "line": "I know where this is goin'.", "order": 25063, "speaker": "Michael Scott"},
  {"season": 5, "episode": 13, "scene": 53, "line": "Do ya?", "order": 25064, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 13, "scene": 53, "line": "No...", "order": 25065, "speaker": "Michael Scott"},
  {"season": 5, "episode": 13, "scene": 53, "line": "Ok. Remember Spider face?", "order": 25066, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 13, "scene": 53, "line": "No.", "order": 25067, "speaker": "Michael Scott"},
  {"season": 5, "episode": 13, "scene": 53, "line": "OK. 'Cause the quote was, cut off your nose to spiderface.", "order": 25068, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 13, "scene": 53, "line": "Spite her - ok [laughter]", "order": 25069, "speaker": "Michael Scott"},
  {"season": 5, "episode": 13, "scene": 53, "line": "Yeah... yep.", "order": 25070, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 13, "scene": 54, "line": "How dare you all attack him like this.", "order": 25071, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 13, "scene": 54, "line": "Oh, stop it Dwight.", "order": 25072, "speaker": "Michael Scott"},
  {"season": 5, "episode": 13, "scene": 54, "line": "Michael is your superior.", "order": 25073, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 13, "scene": 54, "line": "No no no no no no!", "order": 25074, "speaker": "Michael Scott"},
  {"season": 5, "episode": 13, "scene": 54, "line": "Okay, you should be bowing down in front of him.", "order": 25075, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 13, "scene": 54, "line": "Dwight your'e supposed to do it this way.", "order": 25076, "speaker": "Michael Scott"},
  {"season": 5, "episode": 13, "scene": 54, "line": "Ok, no, they don't understand who they have...", "order": 25077, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 13, "scene": 54, "line": "That is the way you're supposed to do it, idiot.", "order": 25078, "speaker": "Michael Scott"},
  {"season": 5, "episode": 13, "scene": 54, "line": "You're interrupting me. I'm trying to get your back.", "order": 25079, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 13, "scene": 54, "line": "Idiot. Idiot! Idiot. Idiot.", "order": 25080, "speaker": "Michael Scott"},
  {"season": 5, "episode": 13, "scene": 54, "line": "Are you calling me an idiot?", "order": 25081, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 13, "scene": 54, "line": "Idiot.", "order": 25082, "speaker": "Michael Scott"},
  {"season": 5, "episode": 13, "scene": 54, "line": "Don't you ever talk to me that way. You pathetic, short little man. You don't have any friends, or any family, or any land. [clapping and whooing]", "order": 25083, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 13, "scene": 55, "line": "Well I just want to take a minute to talk to you all about something very serious. Once every hour, someone is involved in an internet scam. That man is Michael Scott. [laughter] He's supporting about 20 Nigerian princesses.", "order": 25084, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 13, "scene": 55, "line": "Hey, ya know what? Forgive me for caring. Right?", "order": 25085, "speaker": "Michael Scott"},
  {"season": 5, "episode": 13, "scene": 55, "line": "Well, ya know, Michael is a great delegator. He never does any work himself. Ever. And one time, I walked in on him naked, and his thing is so small. [quickly walks off stage] If it were an iPod it would be a shuffle! [clapping, laughter]", "order": 25086, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 13, "scene": 55, "line": "Can I make just a little announcement. In a professional roast, usually the roaster will say something nice about the roastee after they're done, something about how much they love them, so, just, keep that in mind.", "order": 25087, "speaker": "Michael Scott"},
  {"season": 5, "episode": 13, "scene": 56, "line": "Mike claims, we're all a family isn't that right?", "order": 25088, "speaker": "Darryl Philbin"},
  {"season": 5, "episode": 13, "scene": 56, "line": "We are, we are a family.", "order": 25089, "speaker": "Michael Scott"},
  {"season": 5, "episode": 13, "scene": 56, "line": "Ok, so um, what's his name? All the way in the back there.", "order": 25090, "speaker": "Darryl Philbin"},
  {"season": 5, "episode": 13, "scene": 56, "line": "Oh very funny.", "order": 25091, "speaker": "Michael Scott"},
  {"season": 5, "episode": 13, "scene": 56, "line": "What's his name?", "order": 25092, "speaker": "Darryl Philbin"},
  {"season": 5, "episode": 13, "scene": 56, "line": "Uhh... hehe hah! I'm thinking Roy?", "order": 25093, "speaker": "Michael Scott"},
  {"season": 5, "episode": 13, "scene": 56, "line": "Roy left years ago. What's his name?", "order": 25094, "speaker": "Darryl Philbin"},
  {"season": 5, "episode": 13, "scene": 56, "line": "I don't believe I have had the pleasure.", "order": 25095, "speaker": "Michael Scott"},
  {"season": 5, "episode": 13, "scene": 56, "line": "Michael I gave you a ride home last week, we spent an hour in traffic...", "order": 25096, "speaker": "Warehouse Michael"},
  {"season": 5, "episode": 13, "scene": 56, "line": "What's his name?", "order": 25097, "speaker": "Darryl Philbin"},
  {"season": 5, "episode": 13, "scene": 56, "line": "Jefferson.", "order": 25098, "speaker": "Michael Scott"},
  {"season": 5, "episode": 13, "scene": 56, "line": "Nope. His name is Michael. [Michael makes the da dum ksch on the drumset again]", "order": 25099, "speaker": "Darryl Philbin"},
  {"season": 5, "episode": 13, "scene": 57, "line": "[singing and playing the guitar] What I hate about you, you really suck as a boss, you're the laziest, jerkiest and you're dumber than applesauce. We're stuck listening to you all day, Stanley tried to die just to get away, heeey, well it's true. That's what I hate about you. That's what I hate about you. Yeah. And now, a man that deserves no introduction, Michael Scott.", "order": 25100, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 13, "scene": 57, "line": "Haha ha. Thank you very much, thank you. That was great. Great job, great laughs. Really, really went after my intelligence there. [clears throat] Dozens of online IQ test might prove you wrong, but, and my thing isn't tiny, its average, so... get your facts straight. [clears throat again] So when I heard that there was going to be a roast in my honor, I thought [choking up, clears throat again] sorry. [laughs nervously] I think I have a frog in my throat. Um... [sigh] I decided to jot down some quick thoughts about you people, um, first up Phyllis and Kevin. Uh um... [Michael walks off stage, knocking over the snare drum]", "order": 25101, "speaker": "Michael Scott"},
  {"season": 5, "episode": 13, "scene": 58, "line": "Dunder Mifflin this is Pam. Uh, I'm sorry, Michael's not here right now can I take a message? Great. I will. Thanks.", "order": 25102, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 13, "scene": 59, "line": "It's very unusual for Michael not to show up to work. My guess? He's either deeply depressed, or an icicle has snapped off his roof and impaled his brain. He has this terrible habit of standing directly underneath them and staring up at them. And I always say Michael, take two steps back, and stare at the icicle from the side. And he's like no, I like the way they look from standing directly underneath them.", "order": 25103, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 13, "scene": 60, "line": "[in the lunch room, with a sock puppet] He is so dumb that he tries to put his M&Ms in alphabetical order. [giggles]", "order": 25104, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 13, "scene": 60, "line": "Alright Kevin. Enough with the Michael jokes. I think he got it bad enough yesterday.", "order": 25105, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 13, "scene": 60, "line": "I'm almost done.", "order": 25106, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 13, "scene": 60, "line": "That reeks, and I'm trying to eat.", "order": 25107, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 13, "scene": 60, "line": "Attention everyone I just got a text from Michael. He says personnel day. Are we hiring?", "order": 25108, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 13, "scene": 60, "line": "Yep. You're being replaced.", "order": 25109, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 13, "scene": 60, "line": "I think he meant personal day.", "order": 25110, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 13, "scene": 60, "line": "Oh, that's quite a leap Pam.", "order": 25111, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 13, "scene": 60, "line": "I hope he's ok, I feel bad.", "order": 25112, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 13, "scene": 60, "line": "Give it up, he's dead.", "order": 25113, "speaker": "Creed Bratton"},
  {"season": 5, "episode": 13, "scene": 60, "line": "He just sent a text...", "order": 25114, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 13, "scene": 60, "line": "What's a text?", "order": 25115, "speaker": "Creed Bratton"},
  {"season": 5, "episode": 13, "scene": 61, "line": "[at a park throwing whole pieces of bread] Caw... caw... caw caw... caw...", "order": 25116, "speaker": "Michael Scott"},
  {"season": 5, "episode": 13, "scene": 62, "line": "You know sometimes, to get perspective, I like to think about a spaceman on a star incredibly far away. And, our problems don't matter to him, because we're just a distant point of light. But he feels sorry for me, because he has an incredibly powerful microscope, and he can see my face. [looks towards the sky] I'm okay. No, I'm not.", "order": 25117, "speaker": "Michael Scott"},
  {"season": 5, "episode": 13, "scene": 63, "line": "Phyllis there's a package for you.", "order": 25118, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 13, "scene": 63, "line": "Oh, ok. [Phyllis signs for the package, opens an empty box]", "order": 25119, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 13, "scene": 63, "line": "[after snatching the clipboard with her signature] Got it.", "order": 25120, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 13, "scene": 64, "line": "[movie] Sam, Sam Sam. It's not that you dumped my granddaughter, and its not that you want children. It, it's that you lied to me. Can't you see that? Can't you see? Oh, I can never trust you. [Andy looks like he's going to cry]", "order": 25121, "speaker": "Lily"},
  {"season": 5, "episode": 13, "scene": 64, "line": "Lilly no. Lilly! Lilly please! Lilly STOP! I don't care how much time we have left. I don't care what my friends say! And I don't care what your mom thinks! Frankly I'm pretty sure she's not makin any sense. Please. Move back to my apartment. [Andy's crying, 'I'm All Out of Love' starts playing] Lilly. I'm not giving up. I'm not giving up. Lilly... Lilly! Push the reverse button! Reverse the button!", "order": 25122, "speaker": "Sam"},
  {"season": 5, "episode": 13, "scene": 64, "line": "[through tears] Sam! Sam!", "order": 25123, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 13, "scene": 65, "line": "Hey Dad. No I know, Mom told me. Ok. Yeah I'll see ya then.", "order": 25124, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 13, "scene": 66, "line": "So what did he say? Was it my fault?", "order": 25125, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 13, "scene": 66, "line": "Yeah. He said that you told him how much you love me. About how you feel when I walk in a room, and about how, you've never doubted for a second that I'm the woman you want to spend the rest of your life with. I guess he's never felt that with my mom, even at their best.", "order": 25126, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 13, "scene": 66, "line": "You ok?", "order": 25127, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 13, "scene": 66, "line": "Yeah. [they hug]", "order": 25128, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 13, "scene": 67, "line": "When you're a kid you assume your parents are soul mates. My kids are gonna be right about that. I guess it also means that sometimes love affairs look different to the people inside them. [Andy is in the background, looks incredulous]", "order": 25129, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 13, "scene": 68, "line": "I am not insightful enough to be a movie critic. Mm... maybe I could be a food critic. These muffins taste bad. Hmm, or an art critic. That painting is bad.", "order": 25130, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 13, "scene": 69, "line": "Michael! It's really good to see you.", "order": 25131, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 13, "scene": 69, "line": "Hey. Why are you wearing a turtleneck?", "order": 25132, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 13, "scene": 69, "line": "Are you alright?", "order": 25133, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 13, "scene": 69, "line": "Michael, I feel like I was a little harsh yesterday. [murmurs of agreement]", "order": 25134, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 13, "scene": 69, "line": "I um, I spent the afternoon in the park trying to feed the pigeons, I guess they all flew west for the winter, and I, I just had some thoughts that I wanted to share with you people.", "order": 25135, "speaker": "Michael Scott"},
  {"season": 5, "episode": 13, "scene": 69, "line": "What?", "order": 25136, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 13, "scene": 69, "line": "Well I wrote them down so I wouldn't forget. Jim, you're 6'11 and you weigh 90 pounds, Gumby has a better body than you. Boom. Roasted. Dwight, you're a kiss ass. Boom. Roasted. Pam, you failed art school. Boom. Roasted. Meredith, you've slept with so many guys you're starting to look like one. Boom. Roasted. Kevin, I can't decide between a fat joke and a dumb joke. Boom. Roasted. Creed, you're teeth called, your breath stinks. Boom. Roasted. Angela where's Angela? [Angela raises her hand] Whoa there you are, I didn't see you there behind that grain of rice. Boom. Roasted. Stanley, you crush your wife during sex and your heart sucks. Boom. Roasted. Oscar, you are... [Stanley is laughing] Oscar, you're gay.", "order": 25137, "speaker": "Michael Scott"},
  {"season": 5, "episode": 13, "scene": 69, "line": "Wow.", "order": 25138, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 13, "scene": 69, "line": "Andy, Cornell called, they think you suck. And you're gayer than Oscar. Boom. Roasted. [Stanley is still laughing] Alright. Alright everybody, you know I kid, you know I kid. You guys are the reason I went into the paper business, so, uh, goodnight, God bless, God bless America, and get home safe. [clapping and murmurs of appreciation]", "order": 25139, "speaker": "Michael Scott"},
  {"season": 5, "episode": 13, "scene": 70, "line": "They say that laughter is the best medicine so Stanley, you can throw away those pills. You are cured. Actually, you should... better hold onto them pills, just in case.", "order": 25140, "speaker": "Michael Scott"},
  {"season": 5, "episode": 14, "scene": 1, "line": "[breathing heavy over intercom, starts laughing]", "order": 25141, "speaker": "Michael Scott"},
  {"season": 5, "episode": 14, "scene": 2, "line": "This morning the phone guy comes in, and he shows Michael that the phones have a PA function. And then he just left.", "order": 25142, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 14, "scene": 2, "line": "[over PA] This is your captain speaking, the office will be flying at an altitude of two stories. Look out your left hand window and you will see Vance Refrigeration. [snickering] I am the ghost of salesman past, do not come near me. [wheezing laughing, starts coughing] Yes Michael Scott for Dr. Jocelyn, please. Hello doctor I was just following up on my mole again. No, I was looking online about sebaceous cysts. I have not been squeezing it.", "order": 25143, "speaker": "Michael Scott"},
  {"season": 5, "episode": 14, "scene": 2, "line": "[disgusted groan]", "order": 25144, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 14, "scene": 2, "line": "Could I stick it with a pin?", "order": 25145, "speaker": "Michael Scott"},
  {"season": 5, "episode": 14, "scene": 2, "line": "Ugh.", "order": 25146, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 14, "scene": 2, "line": "[still talking over PA] Toby Flenderson to the principal's office. Your mother called, and it appears that you wet the bed again. So you have to get home to wash your sheets [Jim stands up, puts scissors in back pocket] because they are yellow and they're wet with your urine.", "order": 25147, "speaker": "Michael Scott"},
  {"season": 5, "episode": 14, "scene": 2, "line": "[sighs and knocks on Michaels door]", "order": 25148, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 14, "scene": 2, "line": "You, and at six foot six, from the University of North Carolina, Jiiiiim Halpeeeeert.", "order": 25149, "speaker": "Michael Scott"},
  {"season": 5, "episode": 14, "scene": 2, "line": "That's pretty funny. Hey you know what? Did I drop my, uh...", "order": 25150, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 14, "scene": 2, "line": "What?", "order": 25151, "speaker": "Michael Scott"},
  {"season": 5, "episode": 14, "scene": 2, "line": "What the heck? Is that, uh... [bends down, cuts phone cords with scissors]", "order": 25152, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 14, "scene": 2, "line": "You find it?", "order": 25153, "speaker": "Michael Scott"},
  {"season": 5, "episode": 14, "scene": 2, "line": "I didn't, I'll look somewhere else.", "order": 25154, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 14, "scene": 2, "line": "All right.", "order": 25155, "speaker": "Michael Scott"},
  {"season": 5, "episode": 14, "scene": 2, "line": "All right.", "order": 25156, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 14, "scene": 2, "line": "Would you like fries with that? Please drive around.", "order": 25157, "speaker": "Michael Scott"},
  {"season": 5, "episode": 14, "scene": 3, "line": "[knocks on Pam's hotel door] Hello, good morning.", "order": 25158, "speaker": "Michael Scott"},
  {"season": 5, "episode": 14, "scene": 3, "line": "Good morning.", "order": 25159, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 14, "scene": 3, "line": "You ready? And we're off, like a herd of turtles.", "order": 25160, "speaker": "Michael Scott"},
  {"season": 5, "episode": 14, "scene": 4, "line": "Well, Pam and I have eloped. Actually we just robbed a bank, and we are on the lam. [laughs] No, uh, seriously, I am on a lecture circuit. Apparently Scranton has the best sales of any regional branch. So David Wallace has asked me to go to all the branches, except Nashua, still a little bit raw there. Um, but I am going to these branches and sharing my secret recipe for success. My 11 business herbs and spices, in a sales batter. [looks at Pam] Oh that, well, that is Pam. Pam is coming along as my assistant and my driver so I can focus. And I like to pack heavy.", "order": 25161, "speaker": "Michael Scott"},
  {"season": 5, "episode": 14, "scene": 4, "line": "He brought a sled.", "order": 25162, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 14, "scene": 4, "line": "No! That is a toboggan, you never know when you're gonna find a snowy hill so... Every magician has a hot assistant, and every rock star has a roadie, and Pam is my hot roadie.", "order": 25163, "speaker": "Michael Scott"},
  {"season": 5, "episode": 14, "scene": 4, "line": "Yeah. I love being on the road, but I especially love the time and a half pay 24 hours a day, for three days. Cause I have a mortgage now, got a bring home the bucks.", "order": 25164, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 14, "scene": 4, "line": "Yeah, oh don't say bucks, it's not lady like. Here we go! [gets in backseat] Okay so what we do is drive all day and we stay in hotels together at night.", "order": 25165, "speaker": "Michael Scott"},
  {"season": 5, "episode": 14, "scene": 4, "line": "Separate rooms.", "order": 25166, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 14, "scene": 4, "line": "Well that goes without saying.", "order": 25167, "speaker": "Michael Scott"},
  {"season": 5, "episode": 14, "scene": 4, "line": "I'm going to say it anyway.", "order": 25168, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 14, "scene": 4, "line": "Hey! Look at what you're wearing again, Pam. Have you ever seen a magician's assistant? That's...", "order": 25169, "speaker": "Michael Scott"},
  {"season": 5, "episode": 14, "scene": 4, "line": "This is a new cardigan.", "order": 25170, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 14, "scene": 4, "line": "...kind of [retches] Maybe you could tie it around your waist or lose the shirt underneath or something.", "order": 25171, "speaker": "Michael Scott"},
  {"season": 5, "episode": 14, "scene": 4, "line": "No.", "order": 25172, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 14, "scene": 4, "line": "No. Oh, all right.", "order": 25173, "speaker": "Michael Scott"},
  {"season": 5, "episode": 14, "scene": 5, "line": "[front door slams, Kelly walks by] Hey, Kelly.", "order": 25174, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 14, "scene": 5, "line": "Screw you.", "order": 25175, "speaker": "Kelly Kapoor"},
  {"season": 5, "episode": 14, "scene": 5, "line": "Excuse me, that is no way to address a superior.", "order": 25176, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 14, "scene": 5, "line": "Oh yeah? Screw you too.", "order": 25177, "speaker": "Kelly Kapoor"},
  {"season": 5, "episode": 14, "scene": 5, "line": "Whoa! What was that all about.", "order": 25178, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 14, "scene": 5, "line": "You forgot her birthday, it was yesterday.", "order": 25179, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 14, "scene": 6, "line": "[Jim and Dwight sitting next to each other in talking head interview] Go ahead.", "order": 25180, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 14, "scene": 6, "line": "Go ahead, you do it.", "order": 25181, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 14, "scene": 6, "line": "Okay.", "order": 25182, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 14, "scene": 6, "line": "I insist.", "order": 25183, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 14, "scene": 6, "line": "Basically after Phyllis blackmailed Angela, Michael asked them to both step down from the party planning committee cause there was too much drama.", "order": 25184, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 14, "scene": 6, "line": "What he said was... [puts finger toward Jim's face]", "order": 25185, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 14, "scene": 6, "line": "[pushes Dwight's finger away] Just, easy.", "order": 25186, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 14, "scene": 6, "line": "...there was a problem with having one head of the party planning committee. She becomes too powerful, so he appointed two heads.", "order": 25187, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 14, "scene": 6, "line": "Party planning is literally the stupidest thing I have ever done in my life.", "order": 25188, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 14, "scene": 6, "line": "[at the same time as Jim] I am a paper salesman, this is humiliating. [shoves Jim's arm away] That's on my side.", "order": 25189, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 14, "scene": 6, "line": "So this is fun.", "order": 25190, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 14, "scene": 7, "line": "My birthday was yesterday, and everybody forgot. I got really dressed up and excited, and no one said a word. There wasn't even a party. I think sometimes people are really mean to the hot, popular girl.", "order": 25191, "speaker": "Kelly Kapoor"},
  {"season": 5, "episode": 14, "scene": 8, "line": "Can I turn on the radio?", "order": 25192, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 14, "scene": 8, "line": "No. I need silence or Sam Kinison to prepare.", "order": 25193, "speaker": "Michael Scott"},
  {"season": 5, "episode": 14, "scene": 8, "line": "But then you fall asleep and there's nothing for me to do.", "order": 25194, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 14, "scene": 8, "line": "Then listen to your iPod, Pam.", "order": 25195, "speaker": "Michael Scott"},
  {"season": 5, "episode": 14, "scene": 8, "line": "That's dangerous.", "order": 25196, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 14, "scene": 8, "line": "Well then... hey you know what then let's just talk.", "order": 25197, "speaker": "Michael Scott"},
  {"season": 5, "episode": 14, "scene": 8, "line": "That's okay I can... I'm fine. I'll just play a song in my head.", "order": 25198, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 14, "scene": 8, "line": "You nervous about seeing Karen again, since she was the other woman? Actually you were the other woman so...", "order": 25199, "speaker": "Michael Scott"},
  {"season": 5, "episode": 14, "scene": 8, "line": "No, that was a long time ago.", "order": 25200, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 14, "scene": 8, "line": "Is that why your wearing makeup today?", "order": 25201, "speaker": "Michael Scott"},
  {"season": 5, "episode": 14, "scene": 8, "line": "No, I'm not even wearing that much.", "order": 25202, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 14, "scene": 9, "line": "I hate the idea that someone out there hates me. I even hate thinking that Al-Qaeda hates me. I think if they got to know me, they wouldn't hate me. But Karen knows me, and she still hates me, so...", "order": 25203, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 14, "scene": 10, "line": "Oh, this is a nice office.", "order": 25204, "speaker": "Julia"},
  {"season": 5, "episode": 14, "scene": 10, "line": "[laughs] You don't have to lie. Through here.", "order": 25205, "speaker": "Stanley Hudson"},
  {"season": 5, "episode": 14, "scene": 10, "line": "Um Phyllis, who's that?", "order": 25206, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 14, "scene": 10, "line": "She's out of your league Andy.", "order": 25207, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 14, "scene": 10, "line": "For your information, I have been with lots of beautiful women.", "order": 25208, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 14, "scene": 10, "line": "Sexually?", "order": 25209, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 14, "scene": 10, "line": "This conversation is over.", "order": 25210, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 14, "scene": 11, "line": "I am single now. What we have here, is the ultimate smackdown between the Nard-Dog and crippling despair, loneliness, and depression. I intend to win.", "order": 25211, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 14, "scene": 12, "line": "We just wanted to say we are very sorry.", "order": 25212, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 14, "scene": 12, "line": "Screw you guys. You're dead to me.", "order": 25213, "speaker": "Kelly Kapoor"},
  {"season": 5, "episode": 14, "scene": 12, "line": "If you say screw you one more time...", "order": 25214, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 14, "scene": 12, "line": "Yeah, screw you, beefer, I don't forget your birthday, I would never do that.", "order": 25215, "speaker": "Kelly Kapoor"},
  {"season": 5, "episode": 14, "scene": 12, "line": "Hey, HEY.", "order": 25216, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 14, "scene": 12, "line": "[while Dwight and Kelly are arguing] Guys guys guys guys guys guys. We just want to make it up to you. What can we do?", "order": 25217, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 14, "scene": 12, "line": "I guess my only wish, would be that nothing so terrible would ever happen to anyone else ever again.", "order": 25218, "speaker": "Kelly Kapoor"},
  {"season": 5, "episode": 14, "scene": 12, "line": "Oh God.", "order": 25219, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 14, "scene": 12, "line": "Okay.", "order": 25220, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 14, "scene": 12, "line": "In a way, it's good that it happened to me, because at least I can bear it.", "order": 25221, "speaker": "Kelly Kapoor"},
  {"season": 5, "episode": 14, "scene": 12, "line": "What kind of cake do you want imbecile.", "order": 25222, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 14, "scene": 12, "line": "Ice Cream.", "order": 25223, "speaker": "Kelly Kapoor"},
  {"season": 5, "episode": 14, "scene": 13, "line": "[taking Michael's picture] Okay, uh, point at the Dunder Mifflin.", "order": 25224, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 14, "scene": 14, "line": "Hello, Rolando, how are you?", "order": 25225, "speaker": "Michael Scott"},
  {"season": 5, "episode": 14, "scene": 14, "line": "Hi.", "order": 25226, "speaker": "Rolando"},
  {"season": 5, "episode": 14, "scene": 14, "line": "Rolando, [clears throat] I'd like you to meet Pam. She is our receptionist. You know what? Maybe you guys could go out on a little friend date sometime.", "order": 25227, "speaker": "Michael Scott"},
  {"season": 5, "episode": 14, "scene": 14, "line": "[sarcastically chuckles] Uh, your late, everyone's already in the conference room. Karen will be right here to take you over.", "order": 25228, "speaker": "Rolando"},
  {"season": 5, "episode": 14, "scene": 14, "line": "Okay, don't be nervous, just picture her naked.", "order": 25229, "speaker": "Michael Scott"},
  {"season": 5, "episode": 14, "scene": 14, "line": "Stop it, please.", "order": 25230, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 14, "scene": 14, "line": "That's what I do, steal my trick.", "order": 25231, "speaker": "Michael Scott"},
  {"season": 5, "episode": 14, "scene": 14, "line": "Please cut it out.", "order": 25232, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 14, "scene": 14, "line": "Hey. Hi, guys.", "order": 25233, "speaker": "Karen Filippelli"},
  {"season": 5, "episode": 14, "scene": 14, "line": "[notices Karen's pregnant] Oh my God. Is that Jim's?", "order": 25234, "speaker": "Michael Scott"},
  {"season": 5, "episode": 14, "scene": 14, "line": "What!", "order": 25235, "speaker": "Karen Filippelli"},
  {"season": 5, "episode": 14, "scene": 14, "line": "Michael!", "order": 25236, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 14, "scene": 14, "line": "Of course not!", "order": 25237, "speaker": "Karen Filippelli"},
  {"season": 5, "episode": 14, "scene": 14, "line": "Okay. Wow. Oh man! My head just exploded. Whoo! Thank God, for everybody right? Whoo kay. Wow, you're huge! That's incredible! I... God sorry, sorry my head is... I'm just, I'm trying to figure out the last time that you and Jim had sex, and...", "order": 25238, "speaker": "Michael Scott"},
  {"season": 5, "episode": 14, "scene": 14, "line": "Let's just get this over with, shall we?", "order": 25239, "speaker": "Karen Filippelli"},
  {"season": 5, "episode": 14, "scene": 14, "line": "Okay, Mm Hmm. Ten, ten months?", "order": 25240, "speaker": "Michael Scott"},
  {"season": 5, "episode": 14, "scene": 15, "line": "[partially blown up balloons on table and hanging from streamers] Are you kidding?", "order": 25241, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 14, "scene": 15, "line": "Well, I'm not done yet.", "order": 25242, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 14, "scene": 15, "line": "Dwight. This, [picks up balloon] fits in the palm of my hand. You haven't blown 'em up enough. Why have you chosen brown and gray balloons?", "order": 25243, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 14, "scene": 15, "line": "They match the carpet", "order": 25244, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 14, "scene": 15, "line": "What is that? [looks at sign that says 'IT IS YOUR BIRTHDAY.'] It is your birthday period.", "order": 25245, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 14, "scene": 15, "line": "It's a statement of fact.", "order": 25246, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 14, "scene": 15, "line": "Not even an exclamation point?", "order": 25247, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 14, "scene": 15, "line": "This is more professional. It's not like she discovered a cure for cancer.", "order": 25248, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 14, "scene": 15, "line": "I can't believe how bad this looks.", "order": 25249, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 14, "scene": 15, "line": "Are you trying to hurt my feeling? Because if so, you are succeeding. Fortunately, my feelings regenerate at twice the speed of a normal man's.", "order": 25250, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 14, "scene": 15, "line": "Okay, good then.", "order": 25251, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 14, "scene": 15, "line": "Have you collected the money from everyone?", "order": 25252, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 14, "scene": 15, "line": "I am working on it.", "order": 25253, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 14, "scene": 15, "line": "How much do you have?", "order": 25254, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 14, "scene": 15, "line": "Six dollars.", "order": 25255, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 14, "scene": 15, "line": "[yelling] That's how much you and I contributed! I... Damn it Jim!", "order": 25256, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 14, "scene": 15, "line": "I said I was working on it.", "order": 25257, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 14, "scene": 15, "line": "[sighs]", "order": 25258, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 14, "scene": 16, "line": "[looking into Julia's car] Trying to see what CD's she got. It's good to know the deets about the girl you're wooing. Eh, Aha! Fiest. Yes! [bangs on car and alarm goes off] Whoa! Aah, Aah! [backs up and runs into another car starting another alarm]", "order": 25259, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 14, "scene": 17, "line": "[on cell phone] NO! Oh, my God. Oh, No. That is so awful. That is the worst news. I have to go. I have to do a presentation. I'll talk to you... [hangs up phone, sighs] This is going to be hard for me to speak today. Because I just learned that my father has died. ... No, he didn't! He is alive. And this isn't even a cell phone. This is a calculator. But you bought it! And now you can't return it. Or can you? No you can't. [Karen raises her hand] Yes. Karen, do you need to go pump?", "order": 25260, "speaker": "Michael Scott"},
  {"season": 5, "episode": 14, "scene": 17, "line": "Not gonna have to do that, till after I have the baby. No, I am wondering. What are you talking about?", "order": 25261, "speaker": "Karen Filippelli"},
  {"season": 5, "episode": 14, "scene": 17, "line": "I am talking about, how you all need to sell an experience. Like I sold you on the idea that my father had died.", "order": 25262, "speaker": "Michael Scott"},
  {"season": 5, "episode": 14, "scene": 17, "line": "But now we think you're a liar.", "order": 25263, "speaker": "Karen Filippelli"},
  {"season": 5, "episode": 14, "scene": 17, "line": "Would a liar bring mini Mounds bars? [Pam tosses a handful of Mounds bars at everyone]", "order": 25264, "speaker": "Michael Scott"},
  {"season": 5, "episode": 14, "scene": 18, "line": "I am a theatrical person. Growing up, I always thought I would become an actor. Because I have, these memorization tricks that I use. Um, for instance, I learned the Pledge of Allegiance by setting it to the tune of Old MacDonald. [starts singing] I pledge allegiance to the flag of the United States of America, and to the republic for which it stands, one nation under God. With a woof-woof here, and a woof-woof there. Here a woof, there a woof. Everywhere a woof, woof. Um, you get it.", "order": 25265, "speaker": "Michael Scott"},
  {"season": 5, "episode": 14, "scene": 19, "line": "I don't know you. But I need to know you in order to sell to you. That is why I have asked you to go around and tell me you names. I have an amazing mnemonic device, by which I have memorized all of your names. Shirty, mole, lazy eye, Mexico, baldy, sugar boobs, black woman. I have taken a unique part of who you are, and I have used that to memorize your name. Baldy, your head is bald. It is hairless. It is shiny, it is reflective like a mirror. 'M' your name is Mark.", "order": 25266, "speaker": "Michael Scott"},
  {"season": 5, "episode": 14, "scene": 19, "line": "Yes.", "order": 25267, "speaker": "Mark"},
  {"season": 5, "episode": 14, "scene": 19, "line": "Got it. It works.", "order": 25268, "speaker": "Michael Scott"},
  {"season": 5, "episode": 14, "scene": 19, "line": "Uh, it's very insulting.", "order": 25269, "speaker": "Karen Filippelli"},
  {"season": 5, "episode": 14, "scene": 19, "line": "But it works. I would like you all to give this a shot. What do you say?", "order": 25270, "speaker": "Michael Scott"},
  {"season": 5, "episode": 14, "scene": 19, "line": "But we already know each other's names.", "order": 25271, "speaker": "Mark"},
  {"season": 5, "episode": 14, "scene": 19, "line": "Well, then it will be easier for you. But I, I still think it's worthwhile, to give a, give it a try.", "order": 25272, "speaker": "Michael Scott"},
  {"season": 5, "episode": 14, "scene": 19, "line": "Okay. All right everyone, meeting's over.", "order": 25273, "speaker": "Karen Filippelli"},
  {"season": 5, "episode": 14, "scene": 19, "line": "Oh, I still have more.", "order": 25274, "speaker": "Michael Scott"},
  {"season": 5, "episode": 14, "scene": 19, "line": "Can I please see you in my office, please?", "order": 25275, "speaker": "Karen Filippelli"},
  {"season": 5, "episode": 14, "scene": 19, "line": "She is pregnant. She is knocked up. 'K' Karen. Pam, let's go.", "order": 25276, "speaker": "Michael Scott"},
  {"season": 5, "episode": 14, "scene": 20, "line": "I can't accept these prices, Stanley. They just cut our budget.", "order": 25277, "speaker": "Julia"},
  {"season": 5, "episode": 14, "scene": 20, "line": "Brought you guys some coffees. Stanley, I know you have adult onset diabetes. So I put Splenda in yours. Let's see. How many did I put in there? [starts singing to the tune of Feist's 1234] One, Two, Three, Four Splenda's in your coffee, Stanley. None in yours, Julia, cause I don't know how you take it. But if you'd rather...", "order": 25278, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 14, "scene": 20, "line": "Four Splenda. Are you crazy?", "order": 25279, "speaker": "Stanley Hudson"},
  {"season": 5, "episode": 14, "scene": 20, "line": "Well, No, I actually only put in two. But that's not how the songs goes.", "order": 25280, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 14, "scene": 21, "line": "Are you out of your damn mind?", "order": 25281, "speaker": "Stanley Hudson"},
  {"season": 5, "episode": 14, "scene": 21, "line": "Are you out of your damn mind? You bring an angel like that into this office, and you don't even set me up with her.", "order": 25282, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 14, "scene": 21, "line": "We're not friends. I didn't think about it.", "order": 25283, "speaker": "Stanley Hudson"},
  {"season": 5, "episode": 14, "scene": 21, "line": "We are friends. Stanley, we're friends. And you let me down.", "order": 25284, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 14, "scene": 21, "line": "You really like her, huh?", "order": 25285, "speaker": "Stanley Hudson"},
  {"season": 5, "episode": 14, "scene": 21, "line": "Yeah. I really like her with all my heart.", "order": 25286, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 14, "scene": 21, "line": "[thinks for a moment] Give me two clients for her.", "order": 25287, "speaker": "Stanley Hudson"},
  {"season": 5, "episode": 14, "scene": 22, "line": "I grab this [grabs a chain saw], and I turn it on and I say, 'Prepare yourself, for the Utica chain store massacre.'", "order": 25288, "speaker": "Michael Scott"},
  {"season": 5, "episode": 14, "scene": 22, "line": "No that's, that is incredibly dangerous.", "order": 25289, "speaker": "Karen Filippelli"},
  {"season": 5, "episode": 14, "scene": 22, "line": "No, don't worry, the chain is off.", "order": 25290, "speaker": "Michael Scott"},
  {"season": 5, "episode": 14, "scene": 22, "line": "No it's not.", "order": 25291, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 14, "scene": 22, "line": "You know, I think I'm just gonna distill all this, and send it in an email to my team.", "order": 25292, "speaker": "Karen Filippelli"},
  {"season": 5, "episode": 14, "scene": 22, "line": "Email's not scary. This is an opportunity. Don't, don't blow it.", "order": 25293, "speaker": "Michael Scott"},
  {"season": 5, "episode": 14, "scene": 22, "line": "So when are you due?", "order": 25294, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 14, "scene": 22, "line": "Uh, in about a month.", "order": 25295, "speaker": "Karen Filippelli"},
  {"season": 5, "episode": 14, "scene": 22, "line": "Wow, that is wonderful, congratulations.", "order": 25296, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 14, "scene": 22, "line": "Thank you. It just all happened so fast.", "order": 25297, "speaker": "Karen Filippelli"},
  {"season": 5, "episode": 14, "scene": 22, "line": "It's really amazing. Congratulations Karen. So is there a guy or, uh, a person. Or, uh, a sperm machine that did this to you, or?", "order": 25298, "speaker": "Michael Scott"},
  {"season": 5, "episode": 14, "scene": 22, "line": "Yes, Michael. My husband impregnated me.", "order": 25299, "speaker": "Karen Filippelli"},
  {"season": 5, "episode": 14, "scene": 22, "line": "Oh, great.", "order": 25300, "speaker": "Michael Scott"},
  {"season": 5, "episode": 14, "scene": 22, "line": "His name is Dan, uh, this is us. So dorky.", "order": 25301, "speaker": "Karen Filippelli"},
  {"season": 5, "episode": 14, "scene": 22, "line": "Oh, he's cute.", "order": 25302, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 14, "scene": 22, "line": "Yeah, he's so cute. He's a dermatologist. We met a bar. Can you believe that?", "order": 25303, "speaker": "Karen Filippelli"},
  {"season": 5, "episode": 14, "scene": 22, "line": "What is happening there? [points at picture]", "order": 25304, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 14, "scene": 22, "line": "Oh, yeah. Don't even. That's really dorky.", "order": 25305, "speaker": "Karen Filippelli"},
  {"season": 5, "episode": 14, "scene": 22, "line": "It's really sweet.", "order": 25306, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 14, "scene": 22, "line": "No, it's really dorky. You were right the first time.", "order": 25307, "speaker": "Michael Scott"},
  {"season": 5, "episode": 14, "scene": 22, "line": "So, how are things in Scranton? How's Jim?", "order": 25308, "speaker": "Karen Filippelli"},
  {"season": 5, "episode": 14, "scene": 22, "line": "Uh, they're good. Uh, Jim's good. We're engaged.", "order": 25309, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 14, "scene": 22, "line": "That's so great. That's great.", "order": 25310, "speaker": "Karen Filippelli"},
  {"season": 5, "episode": 14, "scene": 22, "line": "Thank you.", "order": 25311, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 14, "scene": 22, "line": "Oh, my God. I'm so happy for you. [hugs pam]", "order": 25312, "speaker": "Karen Filippelli"},
  {"season": 5, "episode": 14, "scene": 22, "line": "Old hatreds dissolve into new friendships. It's a really wonderful moment.", "order": 25313, "speaker": "Michael Scott"},
  {"season": 5, "episode": 14, "scene": 23, "line": "I have to take care of a couple things. So Andy will be taking over things here. You're in good hands, and give my best to your mother.", "order": 25314, "speaker": "Stanley Hudson"},
  {"season": 5, "episode": 14, "scene": 23, "line": "So Julia, um, let's see. With regards to, uh, billing. Should we send bills to you, or to your boyfriend's house or?", "order": 25315, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 14, "scene": 23, "line": "No, it could go straight to our business address.", "order": 25316, "speaker": "Julia"},
  {"season": 5, "episode": 14, "scene": 23, "line": "Oh Okay, all right. That makes sense. How does your boyfriend, deal with your phenomenal success? Is he just, like, totally threatened by you, or?", "order": 25317, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 14, "scene": 23, "line": "Actually, I, I don't have a boyfriend.", "order": 25318, "speaker": "Julia"},
  {"season": 5, "episode": 14, "scene": 23, "line": "[stammers] Really? Is that... wow, that's so weird.", "order": 25319, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 14, "scene": 24, "line": "Guys, stop everything. I'm about to ask out this girl, and I'm completely panicking.", "order": 25320, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 14, "scene": 24, "line": "[quietly] Oh, my desk is over... [quickly walks away]", "order": 25321, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 14, "scene": 24, "line": "This gal, she's really into you?", "order": 25322, "speaker": "Creed Bratton"},
  {"season": 5, "episode": 14, "scene": 24, "line": "Yeah. Yeah. I mean, I've seen her, like, three times today. And we love all the same music, and whenever I walk into a room, she totally looks up.", "order": 25323, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 14, "scene": 24, "line": "All right, all right. Say no more. So, this is how I got squeaky fromme. No small talk. Just show her who's the boss. Just go right in and kiss her.", "order": 25324, "speaker": "Creed Bratton"},
  {"season": 5, "episode": 14, "scene": 24, "line": "Okay, it sounds risky.", "order": 25325, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 14, "scene": 24, "line": "Have I ever steered you wrong, Jim?", "order": 25326, "speaker": "Creed Bratton"},
  {"season": 5, "episode": 14, "scene": 24, "line": "Wait, what?", "order": 25327, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 14, "scene": 25, "line": "I would give that lecture a solid B+. Although, for the record, Karen. Wow, kind of mean.", "order": 25328, "speaker": "Michael Scott"},
  {"season": 5, "episode": 14, "scene": 25, "line": "I like her.", "order": 25329, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 14, "scene": 25, "line": "Really? No, honestly. Tell me what you really think.", "order": 25330, "speaker": "Michael Scott"},
  {"season": 5, "episode": 14, "scene": 25, "line": "I'm serious. Um, I'm really glad I came.", "order": 25331, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 14, "scene": 25, "line": "Why?", "order": 25332, "speaker": "Michael Scott"},
  {"season": 5, "episode": 14, "scene": 25, "line": "Because, um, cause I'll never wonder ever again. If I did something wrong and... Now I have closure. She's happy and, I don't know, it feels good.", "order": 25333, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 14, "scene": 26, "line": "Thanks for walking me out.", "order": 25334, "speaker": "Julia"},
  {"season": 5, "episode": 14, "scene": 26, "line": "Oh, my pleasure. Yeah, there's all kinds of weirdo's out here, so...", "order": 25335, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 14, "scene": 26, "line": "You must be freezing.", "order": 25336, "speaker": "Julia"},
  {"season": 5, "episode": 14, "scene": 26, "line": "I am about to die. [laughs] Will you wait here while I go get my jacket?", "order": 25337, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 14, "scene": 26, "line": "Oh, no, it's okay. This is my car.", "order": 25338, "speaker": "Julia"},
  {"season": 5, "episode": 14, "scene": 26, "line": "Okay, Okay. Well listen, you're a new client, and as one of my new clients, you will always be taken care of. And that is the Nard-Dog guarantee.", "order": 25339, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 14, "scene": 26, "line": "What's a 'Nard-Dog'?", "order": 25340, "speaker": "Julia"},
  {"season": 5, "episode": 14, "scene": 26, "line": "[points to self] This is the Nard-Dog.", "order": 25341, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 14, "scene": 26, "line": "[Andy leans in to kiss her] Whoa. What the hell?", "order": 25342, "speaker": "Julia"},
  {"season": 5, "episode": 14, "scene": 26, "line": "Oh, I'm sorry. I'm sorry. Oh I, I thought we had this energy. And I'm a mess. And I just had my heart broken. And you came in today and your so pretty. You're, like, incredibly pretty.", "order": 25343, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 14, "scene": 26, "line": "I just got out of a relationship too. It's really hard.", "order": 25344, "speaker": "Julia"},
  {"season": 5, "episode": 14, "scene": 26, "line": "Yeah, right? Wow. Yeah. Do you want to talk about it? Go to a mall of something? Just walk around?", "order": 25345, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 14, "scene": 26, "line": "No, I, I don't think so.", "order": 25346, "speaker": "Julia"},
  {"season": 5, "episode": 14, "scene": 26, "line": "Sure, yeah that's... your right. It's too soon for... definitely too soon for me. So I'm glad we go that out of the way. I'm gonna be in touch with you. In three months. I'm gonna call you about that order. And maybe about that mall walk.", "order": 25347, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 14, "scene": 26, "line": "[chuckles] Okay.", "order": 25348, "speaker": "Julia"},
  {"season": 5, "episode": 14, "scene": 26, "line": "Take care Julia.", "order": 25349, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 14, "scene": 26, "line": "Thank you.", "order": 25350, "speaker": "Julia"},
  {"season": 5, "episode": 14, "scene": 26, "line": "[as Julia is pulling out] Sorry I tried to kiss y...", "order": 25351, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 14, "scene": 27, "line": "We lost the account.", "order": 25352, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 14, "scene": 28, "line": "[struggling to blow up a balloon as his desk phone rings] Damn it! [answers phone] Dwight Schrute.", "order": 25353, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 14, "scene": 28, "line": "[over the phone] How old's Kelly?", "order": 25354, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 14, "scene": 28, "line": "Who is this?", "order": 25355, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 14, "scene": 28, "line": "It's Mose. Who do you think it is?", "order": 25356, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 14, "scene": 28, "line": "Mose doesn't know how to use a phone. So joke's on you.", "order": 25357, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 14, "scene": 28, "line": "Look, I'm at the supermarket, and they only have numbered candles. How old is she?", "order": 25358, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 14, "scene": 28, "line": "Uh, 24. 37.", "order": 25359, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 14, "scene": 28, "line": "Do you think I'm calling you for your best approximation?", "order": 25360, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 14, "scene": 28, "line": "[sighs] I'll call you back. [goes to a filling cabinet takes out a file and looks around]", "order": 25361, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 14, "scene": 29, "line": "I have here Kelly Kapoor's personal and confidental file. Allow me to share. [opens file] 'Kelly Kapoor spent April 1995 to December 1996 at Berks County Youth Center.' Juvie. 'According to past employers, it in no way affects her job perfor...' Blah, Blah, Blah. [closes file]", "order": 25362, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 14, "scene": 30, "line": "Are you asleep?", "order": 25363, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 14, "scene": 30, "line": "No. I'm just thinking about what you said, about Karen, about closure. You remember Holly? She used to work for H.R.?", "order": 25364, "speaker": "Michael Scott"},
  {"season": 5, "episode": 14, "scene": 30, "line": "No, remind me.", "order": 25365, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 14, "scene": 30, "line": "Blonde hair, nice boobs. Not too big, not too small.", "order": 25366, "speaker": "Michael Scott"},
  {"season": 5, "episode": 14, "scene": 30, "line": "Perfect boobs, [gives a look at the camera] of course I remember Holly.", "order": 25367, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 14, "scene": 30, "line": "She was the love of my life. What you and Jim have times 100. Just she... she just left. And I didn't... I never got closure, you know? I never got closure with her. I haven't talked to her since. I haven't seen her since. And I feel like I need to go to Nashua and get closure. I feel like I need that.", "order": 25368, "speaker": "Michael Scott"},
  {"season": 5, "episode": 14, "scene": 30, "line": "Okay, lets go.", "order": 25369, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 14, "scene": 30, "line": "[scoofs] I'll just blow off the lecture at Rochester.", "order": 25370, "speaker": "Michael Scott"},
  {"season": 5, "episode": 14, "scene": 30, "line": "Yeah, screw 'em. Let's do this.", "order": 25371, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 14, "scene": 30, "line": "[chuckles] Okay.", "order": 25372, "speaker": "Michael Scott"},
  {"season": 5, "episode": 14, "scene": 31, "line": "[leaning against Creed's desk] So I am collecting $3 from everybody. For Kelly's party.", "order": 25373, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 14, "scene": 31, "line": "I'd like to contribute.", "order": 25374, "speaker": "Creed Bratton"},
  {"season": 5, "episode": 14, "scene": 31, "line": "Oh, great.", "order": 25375, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 14, "scene": 31, "line": "[opens wallet and hands Jim a single bill] There you go.", "order": 25376, "speaker": "Creed Bratton"},
  {"season": 5, "episode": 14, "scene": 31, "line": "[looks at a three dollar bill]", "order": 25377, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 15, "scene": 1, "line": "I am on a lecture circuit. I'm goin' around to all the branches, and I'm telling them my secret recipe for success.", "order": 25378, "speaker": "Michael Scott"},
  {"season": 5, "episode": 15, "scene": 2, "line": "I have now memorized all of your names. Shirty, Mole, Lazy Eye, Mexico, Baldy.", "order": 25379, "speaker": "Michael Scott"},
  {"season": 5, "episode": 15, "scene": 3, "line": "[holding a chainsaw] And I turn it on and I say 'Prepare yourself for the Utica Chainsaw Massacre.'", "order": 25380, "speaker": "Michael Scott"},
  {"season": 5, "episode": 15, "scene": 4, "line": "How's Jim?", "order": 25381, "speaker": "Karen Filippelli"},
  {"season": 5, "episode": 15, "scene": 4, "line": "We're engaged.", "order": 25382, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 15, "scene": 4, "line": "Oh my God, I'm so happy for you!", "order": 25383, "speaker": "Karen Filippelli"},
  {"season": 5, "episode": 15, "scene": 4, "line": "Oh, wow, thank you!", "order": 25384, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 15, "scene": 5, "line": "You remember Holly? She used to work for HR? She's the love of my life. She just left... and I never got closure. And I feel like I need to go to Nashua and get closure.", "order": 25385, "speaker": "Michael Scott"},
  {"season": 5, "episode": 15, "scene": 5, "line": "... Okay, let's go.", "order": 25386, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 15, "scene": 6, "line": "[Kevin is holding two ice cream treats] Two?", "order": 25387, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 15, "scene": 6, "line": "I didn't eat lunch. [Oscar gives Kevin a look] I didn't eat all my lunch.", "order": 25388, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 15, "scene": 7, "line": "[enters smiling and waving] Hello, everyone. Oh, ice cream. Nice, Kevin. Looks good.", "order": 25389, "speaker": "Angela Martin"},
  {"season": 5, "episode": 15, "scene": 7, "line": "It... yeah.", "order": 25390, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 15, "scene": 7, "line": "Angela, you're more chipper than usual.", "order": 25391, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 15, "scene": 7, "line": "I am proud to announce that there is a new addition to the Martin family. [takes a breath, excitedly] She's hypoallergenic. She doesn't struggle when you try to dress her. She's a third-generation show cat. Her father was in 'Meet the Parents.' Needless to say, she was very, very expensive.", "order": 25392, "speaker": "Angela Martin"},
  {"season": 5, "episode": 15, "scene": 7, "line": "How much?", "order": 25393, "speaker": "Meredith Palmer"},
  {"season": 5, "episode": 15, "scene": 7, "line": "Seven thousand dollars.", "order": 25394, "speaker": "Angela Martin"},
  {"season": 5, "episode": 15, "scene": 7, "line": "For a cat? I could get you a kid for that.", "order": 25395, "speaker": "Creed Bratton"},
  {"season": 5, "episode": 15, "scene": 7, "line": "Where'd you get that kind of money?", "order": 25396, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 15, "scene": 7, "line": "I sold Andy's engagement ring on eBay.", "order": 25397, "speaker": "Angela Martin"},
  {"season": 5, "episode": 15, "scene": 7, "line": "Wait, you didn't give it back?", "order": 25398, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 15, "scene": 7, "line": "He wouldn't have wanted that. Her name is Princess Lady!", "order": 25399, "speaker": "Angela Martin"},
  {"season": 5, "episode": 15, "scene": 7, "line": "Seven grand?", "order": 25400, "speaker": "Meredith Palmer"},
  {"season": 5, "episode": 15, "scene": 7, "line": "Mm-hmm.", "order": 25401, "speaker": "Angela Martin"},
  {"season": 5, "episode": 15, "scene": 7, "line": "I gotta see that little bitch.", "order": 25402, "speaker": "Meredith Palmer"},
  {"season": 5, "episode": 15, "scene": 7, "line": "I have something better than a picture. Come on.", "order": 25403, "speaker": "Angela Martin"},
  {"season": 5, "episode": 15, "scene": 8, "line": "You have your cats on Nanny-Cam?", "order": 25404, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 15, "scene": 8, "line": "Yeah. [cats meowing] I mean, I usually try to take leave when I get a new cat, but I'm out of vacation days. And this company still doesn't recognize cat maternity. I mean, when somebody has a kid, oh sure, take off a year.", "order": 25405, "speaker": "Angela Martin"},
  {"season": 5, "episode": 15, "scene": 8, "line": "She's right. I had my second kid just for the vacation.", "order": 25406, "speaker": "Meredith Palmer"},
  {"season": 5, "episode": 15, "scene": 8, "line": "Right. Anyways... I just want to make sure Princess Lady is acclimating well. She means more to me than anyone.", "order": 25407, "speaker": "Angela Martin"},
  {"season": 5, "episode": 15, "scene": 8, "line": "Any cat, you mean.", "order": 25408, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 15, "scene": 8, "line": "And person.", "order": 25409, "speaker": "Angela Martin"},
  {"season": 5, "episode": 15, "scene": 9, "line": "So, detour. We're now adding Nashua to the Lecture Circuit so Michael can confront Holly and get some closure. Nashua actually sounded very excited on the phone. I don't think they get a lot of visitors. Because their office is only accessible by cross-country skis. Hey-oh! [laughs] [whispers] I've been driving too long.", "order": 25410, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 15, "scene": 10, "line": "[shouts from another room] What did you do?", "order": 25411, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 15, "scene": 10, "line": "Stop yelling at me!", "order": 25412, "speaker": "Kelly Kapoor"},
  {"season": 5, "episode": 15, "scene": 10, "line": "What did you do?", "order": 25413, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 15, "scene": 10, "line": "I didn't do anything!", "order": 25414, "speaker": "Kelly Kapoor"},
  {"season": 5, "episode": 15, "scene": 10, "line": "What did you learn in there? I bet you learned things, huh? Like how to fashion a shiv, hmm?", "order": 25415, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 15, "scene": 10, "line": "Hey! What the hell's goin' on?", "order": 25416, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 15, "scene": 10, "line": "Why don't you tell Jim where you were from ages 14 to 15.", "order": 25417, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 15, "scene": 10, "line": "I was kickin' it.", "order": 25418, "speaker": "Kelly Kapoor"},
  {"season": 5, "episode": 15, "scene": 10, "line": "In juvie.", "order": 25419, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 15, "scene": 10, "line": "What?", "order": 25420, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 15, "scene": 10, "line": "Juvie... nile... Detention Center. Where they send teenagers!", "order": 25421, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 15, "scene": 10, "line": "Yep.", "order": 25422, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 15, "scene": 10, "line": "For reha-", "order": 25423, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 15, "scene": 10, "line": "Got it.", "order": 25424, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 15, "scene": 10, "line": "What did you do? Huh?", "order": 25425, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 15, "scene": 10, "line": "Hey, Dwight, sounds like she was 14, so maybe we wanna go a little easy.", "order": 25426, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 15, "scene": 10, "line": "Yeah, if she's old enough to get married, she's old enough to follow the law. [to Kelly] What did you do?", "order": 25427, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 15, "scene": 10, "line": "My boyfriend dumped me, so, I stole his boat. I mean, he told me it was his boat. It was actually his father's. And I just thought it'd be really romantic, like 'Thelma and Louise,' but with, like, a boat. And it was the worst year of my life. And I can't believe that you guys are making me talk about this on my birthday!", "order": 25428, "speaker": "Kelly Kapoor"},
  {"season": 5, "episode": 15, "scene": 10, "line": "I thought you said yesterday was your birthday!", "order": 25429, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 15, "scene": 10, "line": "Hey, you know what? I got you a cake.", "order": 25430, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 15, "scene": 10, "line": "You did? I wanna see the cake.", "order": 25431, "speaker": "Kelly Kapoor"},
  {"season": 5, "episode": 15, "scene": 10, "line": "And... ta da.", "order": 25432, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 15, "scene": 10, "line": "I hate it.", "order": 25433, "speaker": "Kelly Kapoor"},
  {"season": 5, "episode": 15, "scene": 10, "line": "How do you hate it? It's a cake.", "order": 25434, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 15, "scene": 10, "line": "Well, there's no flowers... or toys... or--- I mean, there's nothing on it. Where did you even find a cake like this? I mean, it doesn't have my name on it! Do you guys know what my name is? My name is Kelly!", "order": 25435, "speaker": "Kelly Kapoor"},
  {"season": 5, "episode": 15, "scene": 10, "line": "Right.", "order": 25436, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 15, "scene": 11, "line": "I forgot if there was an 'e' between the 'l' and the 'y.' I still don't know.", "order": 25437, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 15, "scene": 12, "line": "I mean, I don't even know what the theme is. What's the theme?!", "order": 25438, "speaker": "Kelly Kapoor"},
  {"season": 5, "episode": 15, "scene": 12, "line": "Birthday.", "order": 25439, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 15, "scene": 12, "line": "Frosting.", "order": 25440, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 15, "scene": 12, "line": "Those aren't... themes. There's always a theme. [walks out]", "order": 25441, "speaker": "Kelly Kapoor"},
  {"season": 5, "episode": 15, "scene": 12, "line": "There's always a theme.", "order": 25442, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 15, "scene": 12, "line": "Nice job on the cake, Bozo!", "order": 25443, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 15, "scene": 12, "line": "Okay, you know what, next time, I'll let you get the cake and I get to scream at the birthday girl.", "order": 25444, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 15, "scene": 13, "line": "[taking Michael's picture] Okay, a little bit more closer to the sign. Yeah.", "order": 25445, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 15, "scene": 14, "line": "Do I look okay?", "order": 25446, "speaker": "Michael Scott"},
  {"season": 5, "episode": 15, "scene": 14, "line": "You look good.", "order": 25447, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 15, "scene": 15, "line": "Hi.", "order": 25448, "speaker": "Michael Scott"},
  {"season": 5, "episode": 15, "scene": 15, "line": "Hi, can I help you?", "order": 25449, "speaker": "Receptionist"},
  {"season": 5, "episode": 15, "scene": 15, "line": "Yes, you can. I am, uh, Michael Scott, Regional Manager of the Scranton branch.", "order": 25450, "speaker": "Michael Scott"},
  {"season": 5, "episode": 15, "scene": 15, "line": "Yes, we were expecting you. Have a seat.", "order": 25451, "speaker": "Receptionist"},
  {"season": 5, "episode": 15, "scene": 15, "line": "Thank you. ... Um... is, uh, Holly Flax anywhere here?", "order": 25452, "speaker": "Michael Scott"},
  {"season": 5, "episode": 15, "scene": 15, "line": "Actually, she's on an HR retreat for the next three days.", "order": 25453, "speaker": "Receptionist"},
  {"season": 5, "episode": 15, "scene": 15, "line": "Oh- She's not here?", "order": 25454, "speaker": "Michael Scott"},
  {"season": 5, "episode": 15, "scene": 15, "line": "But, if you need to get in touch with her immediately, you could talk to A.J. He's a salesman here.", "order": 25455, "speaker": "Receptionist"},
  {"season": 5, "episode": 15, "scene": 15, "line": "A.J.", "order": 25456, "speaker": "Michael Scott"},
  {"season": 5, "episode": 15, "scene": 15, "line": "Yeah, he's her boyfriend. He's just over there.", "order": 25457, "speaker": "Receptionist"},
  {"season": 5, "episode": 15, "scene": 16, "line": "She has a boyfriend.", "order": 25458, "speaker": "Michael Scott"},
  {"season": 5, "episode": 15, "scene": 16, "line": "I'm so sorry, Michael.", "order": 25459, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 15, "scene": 16, "line": "How could she do this to me, Pam?", "order": 25460, "speaker": "Michael Scott"},
  {"season": 5, "episode": 15, "scene": 16, "line": "She's not doing it to hurt you.", "order": 25461, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 15, "scene": 16, "line": "I can't do the presentation, I can't- [voice cracks] ... just... oh... thinking about seeing him... and... thinking about... him getting to hold her and getting to kiss her, it just- oh, God! [starts crying, covers his face]", "order": 25462, "speaker": "Michael Scott"},
  {"season": 5, "episode": 15, "scene": 16, "line": "Listen, when Jim was dating Karen, I didn't want to come to work. It was awful. I hated it. I wanted to quit, but-", "order": 25463, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 15, "scene": 16, "line": "I know, just... uh, please, I'm going through something, okay? [exhales]", "order": 25464, "speaker": "Michael Scott"},
  {"season": 5, "episode": 15, "scene": 16, "line": "You know, when Holly gets back, everyone will tell her what a great job you did. And then she'll realize what she's missing.", "order": 25465, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 15, "scene": 16, "line": "And then she'll move back to Scranton. And her boyfriend will die.", "order": 25466, "speaker": "Michael Scott"},
  {"season": 5, "episode": 15, "scene": 16, "line": "Yeah, maybe.", "order": 25467, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 15, "scene": 16, "line": "[laughs]", "order": 25468, "speaker": "Michael Scott"},
  {"season": 5, "episode": 15, "scene": 16, "line": "Maybe. One step at a time.", "order": 25469, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 15, "scene": 16, "line": "[sighs]", "order": 25470, "speaker": "Michael Scott"},
  {"season": 5, "episode": 15, "scene": 16, "line": "You can do this.", "order": 25471, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 15, "scene": 16, "line": "I can do this.", "order": 25472, "speaker": "Michael Scott"},
  {"season": 5, "episode": 15, "scene": 17, "line": "It could be snacks, or-", "order": 25473, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 15, "scene": 17, "line": "Why would we- [cats meowing from Angela's computer, sounds like crying]", "order": 25474, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 15, "scene": 17, "line": "Wha-", "order": 25475, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 15, "scene": 17, "line": "Is that what I think it is? [Kevin and Oscar get up and go to Angela's computer]", "order": 25476, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 15, "scene": 17, "line": "Good God!", "order": 25477, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 15, "scene": 17, "line": "[gasps]", "order": 25478, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 15, "scene": 17, "line": "[laughs]", "order": 25479, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 15, "scene": 17, "line": "[gasps]", "order": 25480, "speaker": "Angela Martin"},
  {"season": 5, "episode": 15, "scene": 17, "line": "That one ugly cat is humping Princess Lady!", "order": 25481, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 15, "scene": 17, "line": "No!", "order": 25482, "speaker": "Angela Martin"},
  {"season": 5, "episode": 15, "scene": 17, "line": "Awesome!", "order": 25483, "speaker": "Meredith Palmer"},
  {"season": 5, "episode": 15, "scene": 17, "line": "Stop it, Mr. Ash! Bad cat! That is very bad! You stop it right now! I swear, he is fixed.", "order": 25484, "speaker": "Angela Martin"},
  {"season": 5, "episode": 15, "scene": 17, "line": "Yeah, I know fixed; that ain't fixed.", "order": 25485, "speaker": "Meredith Palmer"},
  {"season": 5, "episode": 15, "scene": 17, "line": "No way.", "order": 25486, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 15, "scene": 17, "line": "No, now listen. You can't let what you see here, sully your image of them. They are good, decent cats. [all laugh] I gotta go. I'll be back in an hour. Oh, stop that! Oh!", "order": 25487, "speaker": "Angela Martin"},
  {"season": 5, "episode": 15, "scene": 17, "line": "Oh, the other one's watching.", "order": 25488, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 15, "scene": 18, "line": "Okay, so all we need is a theme... and cups, and ice, and punch, and a cake.", "order": 25489, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 15, "scene": 18, "line": "Busy!", "order": 25490, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 15, "scene": 18, "line": "Hey, what's that show that she's always talking about?", "order": 25491, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 15, "scene": 18, "line": "Oh, my God. Is this how you are with Pam? 'Cause she must want to shoot herself in the face.", "order": 25492, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 15, "scene": 18, "line": "You know what? I could use a little help.", "order": 25493, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 15, "scene": 18, "line": "You know what? I'm a little busy.", "order": 25494, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 15, "scene": 18, "line": "We have a lot to do, and you are... putting up a very effeminate sign. Is that what you've been doing, is making a sign?", "order": 25495, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 15, "scene": 18, "line": "It's not effeminate. It's festive.", "order": 25496, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 15, "scene": 18, "line": "You've been making that sign, for something that we could just announce to the whole office? Hey everybody, the party's now at 3!", "order": 25497, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 15, "scene": 18, "line": "I know, I just read it on the sign.", "order": 25498, "speaker": "Stanley Hudson"},
  {"season": 5, "episode": 15, "scene": 19, "line": "Goooood morning, Viet-Nashua! Sales. Sales is what bwings us together, today. How do we deal with clients who say, 'Frankly, my dear, I don't give a damn... about paper' and get them to 'Show us the money!' Well, we are going to find out today. Show of hands. How many of you are salesmen? Let's see the salesmen. [some raise their hands] Oh, okay. Well I know what the rest of you are thinking. Wow, yuck. Salesmen are the worst! They are awful. They are so arrogant. They think the whole place revolves around them. Handsome, good listeners, funny, Mr. Wonderful. So, okay, you're a salesman. What's, uh, what's your name?", "order": 25499, "speaker": "Michael Scott"},
  {"season": 5, "episode": 15, "scene": 19, "line": "A.J.", "order": 25500, "speaker": "A.J."},
  {"season": 5, "episode": 15, "scene": 19, "line": "A.J. What kind of name is A.J.?", "order": 25501, "speaker": "Michael Scott"},
  {"season": 5, "episode": 15, "scene": 19, "line": "[laughs]", "order": 25502, "speaker": "A.J."},
  {"season": 5, "episode": 15, "scene": 19, "line": "What do you race cars?", "order": 25503, "speaker": "Michael Scott"},
  {"season": 5, "episode": 15, "scene": 19, "line": "[laughs] I'm a salesman. That's why I raised my hand.", "order": 25504, "speaker": "A.J."},
  {"season": 5, "episode": 15, "scene": 19, "line": "Ooh. Ouch. Okay, good. You're funny, very good and funny. Tell me, A.J., are you dating? Is there somebody you date?", "order": 25505, "speaker": "Michael Scott"},
  {"season": 5, "episode": 15, "scene": 19, "line": "Yeah. Why, are you interested? [all laugh]", "order": 25506, "speaker": "A.J."},
  {"season": 5, "episode": 15, "scene": 19, "line": "I have a question about discounts from distributors.", "order": 25507, "speaker": "Woman"},
  {"season": 5, "episode": 15, "scene": 19, "line": "Yep, we will get to you. Okay, so you're dating somebody? Um... is it serious?", "order": 25508, "speaker": "Michael Scott"},
  {"season": 5, "episode": 15, "scene": 19, "line": "It's pretty serious, yes.", "order": 25509, "speaker": "A.J."},
  {"season": 5, "episode": 15, "scene": 19, "line": "Huh- [pauses] Does she ever talk about me? [voice cracking]", "order": 25510, "speaker": "Michael Scott"},
  {"season": 5, "episode": 15, "scene": 19, "line": "Oh, God.", "order": 25511, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 15, "scene": 19, "line": "Excuse me?", "order": 25512, "speaker": "A.J."},
  {"season": 5, "episode": 15, "scene": 19, "line": "What does this have to do with sales?", "order": 25513, "speaker": "Man"},
  {"season": 5, "episode": 15, "scene": 19, "line": "It's all connected. Shut up. [to A.J.] Does she ever mention 'Michael Scott?'", "order": 25514, "speaker": "Michael Scott"},
  {"season": 5, "episode": 15, "scene": 19, "line": "No, what are you talking about?", "order": 25515, "speaker": "A.J."},
  {"season": 5, "episode": 15, "scene": 19, "line": "[sighs loudly] Does it feel good?", "order": 25516, "speaker": "Michael Scott"},
  {"season": 5, "episode": 15, "scene": 19, "line": "Does what feel good?", "order": 25517, "speaker": "A.J."},
  {"season": 5, "episode": 15, "scene": 19, "line": "[grunts] Your life. [sits down, upset] Oh, wow. Whew. Ahh, legs are sorta giving out. Long drive in the car, so let's just- We'll just continue. [sighs, falls to floor, gets more upset] Oh, wow. [takes deep breath] Ooh, okay.", "order": 25518, "speaker": "Michael Scott"},
  {"season": 5, "episode": 15, "scene": 19, "line": "Michael, get off the floor!", "order": 25519, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 15, "scene": 19, "line": "Yeah, yeah... uh, I can't do this Pam. Just read from- just read the cards.", "order": 25520, "speaker": "Michael Scott"},
  {"season": 5, "episode": 15, "scene": 19, "line": "[whispering] No, no, no, you have to do it-", "order": 25521, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 15, "scene": 19, "line": "No, no, I'm okay. I'm all right. [sighs heavily] Okay... okay.", "order": 25522, "speaker": "Michael Scott"},
  {"season": 5, "episode": 15, "scene": 19, "line": "That was weird, huh? It's all part of the presentation. It was confusing, right? Because confusing situations happen to us all the time in our jobs. [pauses] I'm just trying to bridge the gap between what just happened and the fact that I'm going to be doing the rest of the presentation. [reading from the cards, in Forrest Gump voice] Sales is like a box a chocolates, you never know which vendor you're gonna get. Forrest Gump.", "order": 25523, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 15, "scene": 20, "line": "[pointing to staff members] Blazer. Freckles. Penguin. K.D. Lang. Holly's boyfriend. These mnemonic devices help to make a connection, and then, also to help you memorize names. I have a chainsaw! [holds up chainsaw, makes a wimpy chain saw noise] Cutting down the competition.", "order": 25524, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 15, "scene": 21, "line": "[cats meowing in background][on Nanny-Cam] Where is that bad cat? Oh, you know who you are. Excuse me, Petals, I'm looking for Mr. Ash. He's a bad cat. Bad, bad cat. Do you hear me? Bad. Yeah, you were bad. No, you look at me when I talk to you. Do you hear me, Mr. Ash? You look at me. 'Cause I'm talking to you right now.", "order": 25525, "speaker": "Angela Martin"},
  {"season": 5, "episode": 15, "scene": 21, "line": "[chuckles]", "order": 25526, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 15, "scene": 21, "line": "[snickers]", "order": 25527, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 15, "scene": 21, "line": "[meows like a cat, then hisses]", "order": 25528, "speaker": "Angela Martin"},
  {"season": 5, "episode": 15, "scene": 21, "line": "This is getting weird.", "order": 25529, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 15, "scene": 21, "line": "Is she cleaning the cat with her tongue? [Angela licks cat, meows]", "order": 25530, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 15, "scene": 21, "line": "Ohhh...", "order": 25531, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 15, "scene": 22, "line": "[sighs, touches Holly's sweater, cuts off sleeve, chuckles, sees Word document on Holly's computer named 'Dear Michael,' plugs in USB flash drive to copy file from computer]", "order": 25532, "speaker": "Michael Scott"},
  {"season": 5, "episode": 15, "scene": 23, "line": "[shivers] [meowing noises] Has this been on the entire time?", "order": 25533, "speaker": "Angela Martin"},
  {"season": 5, "episode": 15, "scene": 23, "line": "I have no idea.", "order": 25534, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 15, "scene": 23, "line": "I was looking at pictures of food on my computer.", "order": 25535, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 15, "scene": 23, "line": "[sigh of relief] Well, sorry I'm late. [clears throat, coughs, pulls hairball out of her mouth]", "order": 25536, "speaker": "Angela Martin"},
  {"season": 5, "episode": 15, "scene": 24, "line": "I want to get that image out of my head. The psychological issues that go behind licking a cat, are not things I want to go into. Also, I'm pretty sure she coughed up a hairball.", "order": 25537, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 15, "scene": 25, "line": "[loading up car with Michael] Don't look up. Don't look up. [sighs as office workers all looking from window]", "order": 25538, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 15, "scene": 26, "line": "Want some pie?", "order": 25539, "speaker": "Michael Scott"},
  {"season": 5, "episode": 15, "scene": 26, "line": "No.", "order": 25540, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 15, "scene": 26, "line": "I went through Holly's things.", "order": 25541, "speaker": "Michael Scott"},
  {"season": 5, "episode": 15, "scene": 26, "line": "What?", "order": 25542, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 15, "scene": 26, "line": "I stole a sleeve of her sweater.", "order": 25543, "speaker": "Michael Scott"},
  {"season": 5, "episode": 15, "scene": 26, "line": "Oh, Michael...", "order": 25544, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 15, "scene": 26, "line": "I also stole something off of her computer. A document called 'Dear Michael.'", "order": 25545, "speaker": "Michael Scott"},
  {"season": 5, "episode": 15, "scene": 26, "line": "You did what?", "order": 25546, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 15, "scene": 26, "line": "I shouldn't have done it. It just- I couldn't help it.", "order": 25547, "speaker": "Michael Scott"},
  {"season": 5, "episode": 15, "scene": 26, "line": "She never sent it to you?", "order": 25548, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 15, "scene": 26, "line": "No, sh- she didn't. I'm gonna read it.", "order": 25549, "speaker": "Michael Scott"},
  {"season": 5, "episode": 15, "scene": 26, "line": "No.", "order": 25550, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 15, "scene": 26, "line": "Yes.", "order": 25551, "speaker": "Michael Scott"},
  {"season": 5, "episode": 15, "scene": 26, "line": "No, under no circumstances can you read that letter. It's a violation of her trust.", "order": 25552, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 15, "scene": 26, "line": "How?", "order": 25553, "speaker": "Michael Scott"},
  {"season": 5, "episode": 15, "scene": 26, "line": "Because, she didn't send it to you!", "order": 25554, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 15, "scene": 26, "line": "I know, I know. You're right, you're right.", "order": 25555, "speaker": "Michael Scott"},
  {"season": 5, "episode": 15, "scene": 26, "line": "I could read it.", "order": 25556, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 15, "scene": 26, "line": "No, that wouldn't-", "order": 25557, "speaker": "Michael Scott"},
  {"season": 5, "episode": 15, "scene": 26, "line": "Yeah, I could read it.", "order": 25558, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 15, "scene": 26, "line": "No, you don't have to do that.", "order": 25559, "speaker": "Michael Scott"},
  {"season": 5, "episode": 15, "scene": 26, "line": "Go get your laptop.", "order": 25560, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 15, "scene": 26, "line": "Okay. [leaves to get laptop]", "order": 25561, "speaker": "Michael Scott"},
  {"season": 5, "episode": 15, "scene": 26, "line": "[to camera] What? I'm not in love with her.", "order": 25562, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 15, "scene": 27, "line": "[blowing up balloon, breathes loudly] You have to write my suggestions down, too.", "order": 25563, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 15, "scene": 27, "line": "I'm not writing, 'Horse Hunt.' I don't even know what that means.", "order": 25564, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 15, "scene": 27, "line": "It's in the name.", "order": 25565, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 15, "scene": 27, "line": "[grunts] Okay, so far, our ideal party consists of beer, fights to the death, cupcakes, blood pudding, blood, touch football, mating, charades, and yes, horse hunting.", "order": 25566, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 15, "scene": 27, "line": "You're right, forget horse hunting. It's stupid.", "order": 25567, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 15, "scene": 27, "line": "Look, is there a birthday you remember that you loved?", "order": 25568, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 15, "scene": 27, "line": "Here's one. It was dark, warm, wet. A sudden burst of light. An intense pressure like I'd never felt before. Father, dressed in white, pulls me forward. Mother bites the cord. [pretends to bite]", "order": 25569, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 15, "scene": 27, "line": "Okay, stop. Forever stop that story. That's disgusting, and it doesn't count. So give me another one.", "order": 25570, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 15, "scene": 27, "line": "Schrutes don't celebrate birthdays, idiot. It started as a Depression-era practicality, and then moved on to an awesome tradition that I look forward to every year. [rolls eyes] What about you?", "order": 25571, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 15, "scene": 27, "line": "Actually, when I was seven, my Dad took me to the Natural History Museum in New York. And we looked at fossils all day. And at the end of the day, he got me a little plastic triceratops. It was awesome.", "order": 25572, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 15, "scene": 27, "line": "That's cool. Hey, you know what's even cooler than triceratops? Every other dinosaur that ever existed.", "order": 25573, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 15, "scene": 27, "line": "[to camera] Didn't see that one coming.", "order": 25574, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 15, "scene": 28, "line": "[closes laptop] It's deleted.", "order": 25575, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 15, "scene": 28, "line": "Well?", "order": 25576, "speaker": "Michael Scott"},
  {"season": 5, "episode": 15, "scene": 28, "line": "She still has feelings for you.", "order": 25577, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 15, "scene": 28, "line": "She said that? Is that what it said? What did it say?", "order": 25578, "speaker": "Michael Scott"},
  {"season": 5, "episode": 15, "scene": 28, "line": "I can't tell you specifically, but... it's not over.", "order": 25579, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 15, "scene": 28, "line": "You're sure?", "order": 25580, "speaker": "Michael Scott"},
  {"season": 5, "episode": 15, "scene": 28, "line": "[nods and smiles]", "order": 25581, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 15, "scene": 28, "line": "[smiles, sighs, chuckles] Okay.", "order": 25582, "speaker": "Michael Scott"},
  {"season": 5, "episode": 15, "scene": 29, "line": "[enters conference room]", "order": 25583, "speaker": "Kelly Kapoor"},
  {"season": 5, "episode": 15, "scene": 29, "line": "Happy Birthday!", "order": 25584, "speaker": "All"},
  {"season": 5, "episode": 15, "scene": 29, "line": "Surprise!", "order": 25585, "speaker": "Meredith Palmer"},
  {"season": 5, "episode": 15, "scene": 29, "line": "Hey, it's not a surprise.", "order": 25586, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 15, "scene": 29, "line": "Not a surprise.", "order": 25587, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 15, "scene": 29, "line": "This doesn't look good.", "order": 25588, "speaker": "Kelly Kapoor"},
  {"season": 5, "episode": 15, "scene": 29, "line": "What?! You have a cake. You have a delicious cake, with your name spelled correctly?", "order": 25589, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 15, "scene": 29, "line": "[shakes her head no]", "order": 25590, "speaker": "Kelly Kapoor"},
  {"season": 5, "episode": 15, "scene": 29, "line": "Told you.", "order": 25591, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 15, "scene": 29, "line": "You haven't heard our theme, though.", "order": 25592, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 15, "scene": 29, "line": "You don't hear a theme, you see it... why is there a chicklet on my cake?", "order": 25593, "speaker": "Kelly Kapoor"},
  {"season": 5, "episode": 15, "scene": 29, "line": "That's the best part. That represents a pillow, or a television.", "order": 25594, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 15, "scene": 29, "line": "Our theme, if you will.", "order": 25595, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 15, "scene": 29, "line": "Because the fun part is, you get to decide on an hour of television, or an hour of napping.", "order": 25596, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 15, "scene": 29, "line": "That's our theme.", "order": 25597, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 15, "scene": 29, "line": "Cool.", "order": 25598, "speaker": "Kelly Kapoor"},
  {"season": 5, "episode": 15, "scene": 29, "line": "Yeah?", "order": 25599, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 15, "scene": 29, "line": "I love it.", "order": 25600, "speaker": "Kelly Kapoor"},
  {"season": 5, "episode": 15, "scene": 29, "line": "[high-fives Jim] Oh, yes! Okay, good. So what's it gonna be, Kapoor?", "order": 25601, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 15, "scene": 29, "line": "Ooh- can she pick a half hour of each?", "order": 25602, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 15, "scene": 29, "line": "No.", "order": 25603, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 15, "scene": 29, "line": "No.", "order": 25604, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 15, "scene": 29, "line": "Oh, then pick TV.", "order": 25605, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 15, "scene": 29, "line": "Take a nap!", "order": 25606, "speaker": "Meredith Palmer"},
  {"season": 5, "episode": 15, "scene": 29, "line": "TV.", "order": 25607, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 15, "scene": 29, "line": "No, watching TV at work is really cool.", "order": 25608, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 15, "scene": 29, "line": "Take a nap, nothing good is on TV right now.", "order": 25609, "speaker": "Stanley Hudson"},
  {"season": 5, "episode": 15, "scene": 29, "line": "Bonnie Hunt is on.", "order": 25610, "speaker": "Creed Bratton"},
  {"season": 5, "episode": 15, "scene": 29, "line": "You know what, I have been watching TV all week. I choose nap.", "order": 25611, "speaker": "Kelly Kapoor"},
  {"season": 5, "episode": 15, "scene": 29, "line": "All right.", "order": 25612, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 15, "scene": 29, "line": "Okay, nap it is! Everyone out! Get out! We're gonna be eating cake at our desks. Let's go.", "order": 25613, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 15, "scene": 29, "line": "Surprise.", "order": 25614, "speaker": "Meredith Palmer"},
  {"season": 5, "episode": 15, "scene": 29, "line": "Move it! Let's go, go, go, go, go. I got this.", "order": 25615, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 15, "scene": 29, "line": "Ahem. This is for you... and [puts blanket around her shoulders] ...Happy Birthday, Kel.", "order": 25616, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 15, "scene": 29, "line": "[giggles] Thank you.", "order": 25617, "speaker": "Kelly Kapoor"},
  {"season": 5, "episode": 15, "scene": 29, "line": "[turns off lights] Okay, you've got one hour. [shuts door]", "order": 25618, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 15, "scene": 29, "line": "I'm too excited to sleep.", "order": 25619, "speaker": "Kelly Kapoor"},
  {"season": 5, "episode": 15, "scene": 30, "line": "Mm, great cake.", "order": 25620, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 15, "scene": 30, "line": "Thanks. [both eat cake]", "order": 25621, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 15, "scene": 31, "line": "[opens door to wake Kelly, bangs together two trash can covers loudly] Birthday time is over! Now go make up for all the work you missed when you were taking your nap. [slaps Kelly's butt] Many happy returns. [slams door]", "order": 25622, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 15, "scene": 32, "line": "I feel great. So good to have closure. Ah! We should go apologize to Roy or something.", "order": 25623, "speaker": "Michael Scott"},
  {"season": 5, "episode": 15, "scene": 32, "line": "No, we don't need to do that.", "order": 25624, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 15, "scene": 32, "line": "Who have I wronged? Who have I wronged? Oh, oh! What about that fat guy from Stamford that I insulted? We should find him!", "order": 25625, "speaker": "Michael Scott"},
  {"season": 5, "episode": 15, "scene": 32, "line": "You mean Tony?", "order": 25626, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 15, "scene": 32, "line": "Jabba the hut, Pizza the hut, Fat guys like pizza, pepperoni pizza, pepperoni Tony!", "order": 25627, "speaker": "Michael Scott"},
  {"season": 5, "episode": 15, "scene": 32, "line": "Oh, Michael.", "order": 25628, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 15, "scene": 32, "line": "Man, was he fat. So, so... fat. You know what, forget it. I know me. When I saw him, I would never be able to apologize to him. Too fat. Big fat fatty.", "order": 25629, "speaker": "Michael Scott"},
  {"season": 5, "episode": 15, "scene": 33, "line": "As you may know, I am no longer in a relationships. It's been really stressful here. So, I decided to treat myself to one of God's most perfect creations...a beautiful new cat! It's tacky to talk about money. But she cost seven thousand dollars!", "order": 25630, "speaker": "Angela Martin"},
  {"season": 5, "episode": 15, "scene": 34, "line": "[holds up cell phone showing video of Angela talking to her cats] For a rainy day. [laughs]", "order": 25631, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 16, "scene": 1, "line": "Hi.", "order": 25632, "speaker": "Phone Salesman"},
  {"season": 5, "episode": 16, "scene": 1, "line": "Good morning, can I help you?", "order": 25633, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 16, "scene": 1, "line": "Yes, I'm from Techstar about a new phone system for you. I was wondering if I could talk to Michael Scott.", "order": 25634, "speaker": "Phone Salesman"},
  {"season": 5, "episode": 16, "scene": 1, "line": "I'm sorry, he's not in right now.", "order": 25635, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 16, "scene": 1, "line": "Really? He's never around when I come by.", "order": 25636, "speaker": "Phone Salesman"},
  {"season": 5, "episode": 16, "scene": 1, "line": "Shoot.", "order": 25637, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 16, "scene": 2, "line": "They have new phone systems now that can ring directly to a salesman, or someone presses star and they go to accounting, basically 95% of my job. But I'd like to see a machine that puts out candy for everyone. [realizing] Vending machine.", "order": 25638, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 16, "scene": 3, "line": "How about I make an appointment to come back? That way, I know he'll be here.", "order": 25639, "speaker": "Phone Salesman"},
  {"season": 5, "episode": 16, "scene": 3, "line": "That is a great idea.", "order": 25640, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 16, "scene": 3, "line": "Great.", "order": 25641, "speaker": "Phone Salesman"},
  {"season": 5, "episode": 16, "scene": 3, "line": "[while leafing through Michael's appointment book and seeing nothing but free time] Um, oh boy, let's see, he's really...", "order": 25642, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 16, "scene": 3, "line": "[approaching Reception] Michael Scott, manager. Hi, how are ya?", "order": 25643, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 16, "scene": 3, "line": "There he is!", "order": 25644, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 16, "scene": 3, "line": "Oh, hi! Great.", "order": 25645, "speaker": "Phone Salesman"},
  {"season": 5, "episode": 16, "scene": 3, "line": "Nice to meet you, yeah. Whew! I can assure you we don't need a new system though. Happy with ours.", "order": 25646, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 16, "scene": 3, "line": "[coming out from his office] Hello, may I help you?", "order": 25647, "speaker": "Michael Scott"},
  {"season": 5, "episode": 16, "scene": 3, "line": "Jimbo!", "order": 25648, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 16, "scene": 3, "line": "...Jim.", "order": 25649, "speaker": "Michael Scott"},
  {"season": 5, "episode": 16, "scene": 3, "line": "[impersonating Fonzie] Ayyyy!", "order": 25650, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 16, "scene": 3, "line": "Ayyyy!", "order": 25651, "speaker": "Michael Scott"},
  {"season": 5, "episode": 16, "scene": 3, "line": "Ayyyy!", "order": 25652, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 16, "scene": 3, "line": "Ayyyy!", "order": 25653, "speaker": "Michael Scott"},
  {"season": 5, "episode": 16, "scene": 3, "line": "Ayyyy!", "order": 25654, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 16, "scene": 3, "line": "[running from his desk] Ayyyy!", "order": 25655, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 16, "scene": 3, "line": "Ayyyy!", "order": 25656, "speaker": "Jim Halpert, Pam Beesly, Michael Scott & Dwight Schrute"},
  {"season": 5, "episode": 16, "scene": 3, "line": "Ok. I'm, uh, I'll be going.", "order": 25657, "speaker": "Phone Salesman"},
  {"season": 5, "episode": 16, "scene": 3, "line": "Ayyyy!", "order": 25658, "speaker": "Jim Halpert, Pam Beesly, Michael Scott & Dwight Schrute"},
  {"season": 5, "episode": 16, "scene": 3, "line": "[laughing] What was that?", "order": 25659, "speaker": "Michael Scott"},
  {"season": 5, "episode": 16, "scene": 3, "line": "That was funny.", "order": 25660, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 16, "scene": 3, "line": "That was funny. Let's go do it to somebody else. Ayyyy!", "order": 25661, "speaker": "Michael Scott"},
  {"season": 5, "episode": 16, "scene": 4, "line": "Oh, look at that. Cupids and hearts. Really shoving our faces in it this year. You doin OK, bud?", "order": 25662, "speaker": "Michael Scott"},
  {"season": 5, "episode": 16, "scene": 4, "line": "I miss Stacy.", "order": 25663, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 16, "scene": 4, "line": "Yeah, I hear ya. It's been four months since I was with Holly, and she was way hotter then Stacy. So if you think you're hurting...", "order": 25664, "speaker": "Michael Scott"},
  {"season": 5, "episode": 16, "scene": 4, "line": "I can't even imagine.", "order": 25665, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 16, "scene": 5, "line": "This is our first and only Valentine's Day as fiances.", "order": 25666, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 16, "scene": 5, "line": "You're only engaged once. Well present company excluded, but.", "order": 25667, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 16, "scene": 5, "line": "Really, Jim? On Cupid's birthday?", "order": 25668, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 16, "scene": 5, "line": "Yeah. [Jim leans in for a kiss but Pam turns away] She's fine.", "order": 25669, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 16, "scene": 6, "line": "So, I received my first Valentine from a secret admirer. [Kelly opens the envelope] Roses are red, violets are blue, it's time for your dental cleaning, and maybe a check-up too.", "order": 25670, "speaker": "Kelly Kapoor"},
  {"season": 5, "episode": 16, "scene": 7, "line": "Oh, wow look at those. How nice for you. Up there, front and center, beautiful. I think they would look better right here. [Michael takes the flowers from Pam's desk and puts them on the ground off to the side] They're very pretty and I wouldn't want them to fall. [Kevin groans]", "order": 25671, "speaker": "Michael Scott"},
  {"season": 5, "episode": 16, "scene": 8, "line": "Just about everyone in this office is single right now. Including me. And everyone is experiencing an incredible amount of emotional pain. Especially me, because of my great capacity for emotion, and it is my first Valentine's Day since Holly, so I think that I am well qualified to understand that these people need to be protected from having love shoved into their faces.", "order": 25672, "speaker": "Michael Scott"},
  {"season": 5, "episode": 16, "scene": 9, "line": "Pam, really, they're back?", "order": 25673, "speaker": "Michael Scott"},
  {"season": 5, "episode": 16, "scene": 9, "line": "I can't see them when they're on the floor", "order": 25674, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 16, "scene": 9, "line": "They're for her to look at, Michael.", "order": 25675, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 16, "scene": 9, "line": "Can I have a word with you, Jim?", "order": 25676, "speaker": "Michael Scott"},
  {"season": 5, "episode": 16, "scene": 9, "line": "Yes, let's have a word.", "order": 25677, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 16, "scene": 9, "line": "Yes, um, Jim. Today is a very difficult day for a lot of people in this office.", "order": 25678, "speaker": "Michael Scott"},
  {"season": 5, "episode": 16, "scene": 9, "line": "Oh, I'm sorry.", "order": 25679, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 16, "scene": 9, "line": "Yeah. And the sexy looks between you and Pam, the general sexiness, the flowers, it's creating a bit of a hostile work environment.", "order": 25680, "speaker": "Michael Scott"},
  {"season": 5, "episode": 16, "scene": 9, "line": "I understand that.", "order": 25681, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 16, "scene": 9, "line": "So sexy it becomes hostile.", "order": 25682, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 16, "scene": 9, "line": "Mm-hmm.", "order": 25683, "speaker": "Michael Scott"},
  {"season": 5, "episode": 16, "scene": 9, "line": "Uh, I actually thought we were keeping it pretty low-key.", "order": 25684, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 16, "scene": 9, "line": "Well, if you guys insist on having your own private little love fest--", "order": 25685, "speaker": "Michael Scott"},
  {"season": 5, "episode": 16, "scene": 9, "line": "We do.", "order": 25686, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 16, "scene": 9, "line": "--that none of us can be a part of--", "order": 25687, "speaker": "Michael Scott"},
  {"season": 5, "episode": 16, "scene": 9, "line": "You can't be a part of our relationship, Michael.", "order": 25688, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 16, "scene": 9, "line": "[gesturing Pam to be quiet] --then, we, are gonna have our own private Valentine's Day party.", "order": 25689, "speaker": "Michael Scott"},
  {"season": 5, "episode": 16, "scene": 9, "line": "That sounds fun.", "order": 25690, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 16, "scene": 9, "line": "So suck it. Hey everybody, I just invited Jim to suck it, and I am cordially inviting all of you to a special convention, a lonely heart's convention, this afternoon. Singles only.", "order": 25691, "speaker": "Michael Scott"},
  {"season": 5, "episode": 16, "scene": 9, "line": "Yeah, deal with it Pam!", "order": 25692, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 16, "scene": 9, "line": "So we may not have someone in our lives that we love, but we do have each other.", "order": 25693, "speaker": "Michael Scott"},
  {"season": 5, "episode": 16, "scene": 10, "line": "Having trouble finding a vein?", "order": 25694, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 16, "scene": 10, "line": "Yup, a little.", "order": 25695, "speaker": "Blood Drive Worker"},
  {"season": 5, "episode": 16, "scene": 10, "line": "How about now? [Dwight untenses his arm]", "order": 25696, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 16, "scene": 11, "line": "I train my major blood vessels to retract into my body on command. Also, I can retract my penis up into itself. [pauses, then nods signaling that he performed the feat]", "order": 25697, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 16, "scene": 12, "line": "I am about to give blood. The gift of everlasting life, the transfer of my bodily fluids, oh wow, that's a big needle, that --", "order": 25698, "speaker": "Michael Scott"},
  {"season": 5, "episode": 16, "scene": 12, "line": "You're gonna need to lie down right over here.", "order": 25699, "speaker": "Blood Drive Worker"},
  {"season": 5, "episode": 16, "scene": 12, "line": "Okay. Hello.", "order": 25700, "speaker": "Michael Scott"},
  {"season": 5, "episode": 16, "scene": 12, "line": "Hi, I cant talk right now. I'm sorry.", "order": 25701, "speaker": "Blood Girl"},
  {"season": 5, "episode": 16, "scene": 12, "line": "Oh, OK.", "order": 25702, "speaker": "Michael Scott"},
  {"season": 5, "episode": 16, "scene": 12, "line": "Whew, I'm really nervous.", "order": 25703, "speaker": "Blood Girl"},
  {"season": 5, "episode": 16, "scene": 12, "line": "Yeah, me too.", "order": 25704, "speaker": "Michael Scott"},
  {"season": 5, "episode": 16, "scene": 12, "line": "Yeah, when I get nervous I sort of clam up.", "order": 25705, "speaker": "Blood Girl"},
  {"season": 5, "episode": 16, "scene": 12, "line": "Oh, well, that's fine.", "order": 25706, "speaker": "Michael Scott"},
  {"season": 5, "episode": 16, "scene": 12, "line": "Whew, it's better for me just to be quiet, yeah.", "order": 25707, "speaker": "Blood Girl"},
  {"season": 5, "episode": 16, "scene": 12, "line": "Yeah, can I point something out to you?", "order": 25708, "speaker": "Michael Scott"},
  {"season": 5, "episode": 16, "scene": 12, "line": "Sure.", "order": 25709, "speaker": "Blood Girl"},
  {"season": 5, "episode": 16, "scene": 12, "line": "You're actually talking a lot.", "order": 25710, "speaker": "Michael Scott"},
  {"season": 5, "episode": 16, "scene": 12, "line": "Sorry, it's the other thing I do when I get really nervous.", "order": 25711, "speaker": "Blood Girl"},
  {"season": 5, "episode": 16, "scene": 12, "line": "Okay, here we go.", "order": 25712, "speaker": "Michael Scott"},
  {"season": 5, "episode": 16, "scene": 12, "line": "Alright, here we go.", "order": 25713, "speaker": "Blood Drive Worker"},
  {"season": 5, "episode": 16, "scene": 12, "line": "Oh, God. [Michael clears his throat]", "order": 25714, "speaker": "Michael Scott"},
  {"season": 5, "episode": 16, "scene": 12, "line": "Just relax.", "order": 25715, "speaker": "Blood Drive Worker"},
  {"season": 5, "episode": 16, "scene": 12, "line": "Yup, I'm good, whew. Wow, I feel like a human juicebox. [Blood Girl laughs] Hawaiian blood punch.", "order": 25716, "speaker": "Michael Scott"},
  {"season": 5, "episode": 16, "scene": 12, "line": "Oh, that's gross.", "order": 25717, "speaker": "Blood Girl"},
  {"season": 5, "episode": 16, "scene": 12, "line": "Type O-Ocean Spray.", "order": 25718, "speaker": "Michael Scott"},
  {"season": 5, "episode": 16, "scene": 12, "line": "God, stop. Stop it.", "order": 25719, "speaker": "Blood Girl"},
  {"season": 5, "episode": 16, "scene": 13, "line": "Hey, why don't you guys come have lunch with Bob and me? We'll take all afternoon. [whispering] Michael is terrified of bob.", "order": 25720, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 16, "scene": 13, "line": "What do you think?", "order": 25721, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 16, "scene": 13, "line": "I have a lot of work to do this afternoon. Those mines aren't gonna sweep themselves.", "order": 25722, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 16, "scene": 13, "line": "We're in.", "order": 25723, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 16, "scene": 14, "line": "Yick.", "order": 25724, "speaker": "Michael Scott"},
  {"season": 5, "episode": 16, "scene": 14, "line": "What?", "order": 25725, "speaker": "Blood Girl"},
  {"season": 5, "episode": 16, "scene": 14, "line": "I looked at the bag.", "order": 25726, "speaker": "Michael Scott"},
  {"season": 5, "episode": 16, "scene": 14, "line": "Ew.", "order": 25727, "speaker": "Blood Girl"},
  {"season": 5, "episode": 16, "scene": 14, "line": "I looked straight at the bag.", "order": 25728, "speaker": "Michael Scott"},
  {"season": 5, "episode": 16, "scene": 14, "line": "That's not good.", "order": 25729, "speaker": "Blood Girl"},
  {"season": 5, "episode": 16, "scene": 14, "line": "Could you distract me for a second, just talk about things that don't have blood in them?", "order": 25730, "speaker": "Michael Scott"},
  {"season": 5, "episode": 16, "scene": 14, "line": "Well, ok... bags! [Michael is taken aback] Alright, that was bad.", "order": 25731, "speaker": "Blood Girl"},
  {"season": 5, "episode": 16, "scene": 14, "line": "That was mean.", "order": 25732, "speaker": "Michael Scott"},
  {"season": 5, "episode": 16, "scene": 14, "line": "Um, a hat.", "order": 25733, "speaker": "Blood Girl"},
  {"season": 5, "episode": 16, "scene": 14, "line": "A hat.", "order": 25734, "speaker": "Michael Scott"},
  {"season": 5, "episode": 16, "scene": 14, "line": "A hat with no blood in it?", "order": 25735, "speaker": "Blood Girl"},
  {"season": 5, "episode": 16, "scene": 14, "line": "That is full of soup.", "order": 25736, "speaker": "Michael Scott"},
  {"season": 5, "episode": 16, "scene": 14, "line": "You're cute.", "order": 25737, "speaker": "Blood Girl"},
  {"season": 5, "episode": 16, "scene": 14, "line": "What?", "order": 25738, "speaker": "Michael Scott"},
  {"season": 5, "episode": 16, "scene": 14, "line": "You're done.", "order": 25739, "speaker": "Blood Drive Worker"},
  {"season": 5, "episode": 16, "scene": 14, "line": "Oh, already.", "order": 25740, "speaker": "Michael Scott"},
  {"season": 5, "episode": 16, "scene": 14, "line": "Ah, we did it!", "order": 25741, "speaker": "Blood Girl"},
  {"season": 5, "episode": 16, "scene": 14, "line": "Whew, wow I was so nervous about this I don't think I ate for three days. [Michael passes out]", "order": 25742, "speaker": "Michael Scott"},
  {"season": 5, "episode": 16, "scene": 14, "line": "Is he OK?", "order": 25743, "speaker": "Blood Girl"},
  {"season": 5, "episode": 16, "scene": 15, "line": "[ccming to] Oh my God, how long was I out? [Michael looks over where Blood Girl was and sees Hank]", "order": 25744, "speaker": "Michael Scott"},
  {"season": 5, "episode": 16, "scene": 15, "line": "What?", "order": 25745, "speaker": "Hank Tate"},
  {"season": 5, "episode": 16, "scene": 15, "line": "Excuse me, waitress, where did the lady go?", "order": 25746, "speaker": "Michael Scott"},
  {"season": 5, "episode": 16, "scene": 15, "line": "Oh. She left.", "order": 25747, "speaker": "Blood Drive Worker"},
  {"season": 5, "episode": 16, "scene": 15, "line": "OK. Oh, wait a second, wait a second, that's hers. This is hers, she left her glove. I need her name if I'm gonna return her glove.", "order": 25748, "speaker": "Michael Scott"},
  {"season": 5, "episode": 16, "scene": 15, "line": "I'm sorry sir we can't give out that information. [Michael sighs]", "order": 25749, "speaker": "Blood Drive Worker"},
  {"season": 5, "episode": 16, "scene": 16, "line": "[seeing Kelly tear a paper heart in half] What are you doing?", "order": 25750, "speaker": "Meredith Palmer"},
  {"season": 5, "episode": 16, "scene": 16, "line": "Decorating.", "order": 25751, "speaker": "Kelly Kapoor"},
  {"season": 5, "episode": 16, "scene": 16, "line": "I'll help. [Meredith tears the wings off of a Cupid] Now it's just a stupid baby.", "order": 25752, "speaker": "Meredith Palmer"},
  {"season": 5, "episode": 16, "scene": 16, "line": "Yeah, thanks.", "order": 25753, "speaker": "Kelly Kapoor"},
  {"season": 5, "episode": 16, "scene": 17, "line": "It is so nice to go out with another couple.", "order": 25754, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 16, "scene": 17, "line": "Anything to get out of that office.", "order": 25755, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 16, "scene": 17, "line": "I know", "order": 25756, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 16, "scene": 17, "line": "I honestly don't know how you can work with that jackass, that other jackass, and that new jackass.", "order": 25757, "speaker": "Bob"},
  {"season": 5, "episode": 16, "scene": 17, "line": "He's talking about Michael, Dwight, and Andy.", "order": 25758, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 16, "scene": 17, "line": "Oh yeah, I understood.", "order": 25759, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 16, "scene": 18, "line": "Well here we all are. Alone but together. No flowers for us. [in a Mexican accent] Relationships, we don't need no stinking relationships. I think we should all go around and tell our worst relationship story, and then get past it, just blow through it, yeah? Kelly, what about Ryan? He treated you pretty terribly, yeah?", "order": 25760, "speaker": "Michael Scott"},
  {"season": 5, "episode": 16, "scene": 18, "line": "Well, his heart was in the right place.", "order": 25761, "speaker": "Kelly Kapoor"},
  {"season": 5, "episode": 16, "scene": 18, "line": "Yeah, but now his heart is in Thailand along with the rest of his body having random sex. [Kelly looks hurt] Okay, sorry, let's, who else? Oscar.", "order": 25762, "speaker": "Michael Scott"},
  {"season": 5, "episode": 16, "scene": 18, "line": "I don't think so.", "order": 25763, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 16, "scene": 18, "line": "Come on, I'm sure there's something you need to get off your chest.", "order": 25764, "speaker": "Michael Scott"},
  {"season": 5, "episode": 16, "scene": 18, "line": "I can't.", "order": 25765, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 16, "scene": 18, "line": "If you wanna just, anything? Are you sure? I'm sure whatever you did it wasn't your fault. OK well, who else?", "order": 25766, "speaker": "Michael Scott"},
  {"season": 5, "episode": 16, "scene": 19, "line": "Everyone here who's bowled a 280 please raise your hand. [Bob does so]", "order": 25767, "speaker": "Bob"},
  {"season": 5, "episode": 16, "scene": 19, "line": "No way, a 280?", "order": 25768, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 16, "scene": 19, "line": "Wow, that's impressive.", "order": 25769, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 16, "scene": 19, "line": "Okay. Now everyone here who's bowled under 70 raise their hand.", "order": 25770, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 16, "scene": 19, "line": "Yikes.", "order": 25771, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 16, "scene": 19, "line": "Come on, Bob. Raise your hand. [Bob does so reluctantly]", "order": 25772, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 16, "scene": 19, "line": "No. Bob, no. No!", "order": 25773, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 16, "scene": 19, "line": "What?", "order": 25774, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 16, "scene": 19, "line": "One time. You love bringing up that one time, don't you?", "order": 25775, "speaker": "Bob"},
  {"season": 5, "episode": 16, "scene": 19, "line": "Yes I do.", "order": 25776, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 16, "scene": 19, "line": "Jim uses a 6 pound ball.", "order": 25777, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 16, "scene": 19, "line": "That is a lie, that is a lie.", "order": 25778, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 16, "scene": 19, "line": "Yes, he bowled 5 frames with this pink sparkly thing until a little girl had to ask for her ball back.", "order": 25779, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 16, "scene": 19, "line": "But! That girl must've had monstrous hands because the holes fit.", "order": 25780, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 16, "scene": 19, "line": "No, you just have little dainty fingers.", "order": 25781, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 16, "scene": 19, "line": "Oh yeah. You can always model ladies' jewelry.", "order": 25782, "speaker": "Bob"},
  {"season": 5, "episode": 16, "scene": 19, "line": "Nobody asked, Bob!", "order": 25783, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 16, "scene": 20, "line": "Do you risk telling him how you feel? Do you say something that you can barely admit to yourself?", "order": 25784, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 16, "scene": 20, "line": "Oh God, what did you do? I mean, not that I approve of any of it but...", "order": 25785, "speaker": "Angela Martin"},
  {"season": 5, "episode": 16, "scene": 20, "line": "I was stupid, I told him.", "order": 25786, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 16, "scene": 20, "line": "Was he in to you in like a gay way?", "order": 25787, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 16, "scene": 20, "line": "Moron, if he was there wouldn't be a story.", "order": 25788, "speaker": "Michael Scott"},
  {"season": 5, "episode": 16, "scene": 20, "line": "He told me he wasn't gay. [everyone groans]", "order": 25789, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 16, "scene": 20, "line": "Really sad.", "order": 25790, "speaker": "Michael Scott"},
  {"season": 5, "episode": 16, "scene": 20, "line": "I'm not done yet.", "order": 25791, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 16, "scene": 20, "line": "Oh my God.", "order": 25792, "speaker": "Michael Scott"},
  {"season": 5, "episode": 16, "scene": 20, "line": "A week later a friend of mine calls me up, and he says 'I just saw him in a gay bar in Kansas City.' [everybody groans except for Michael who looks pleasantly surprised]", "order": 25793, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 16, "scene": 20, "line": "Well then it's a happy ending, because he was gay. You should call him!", "order": 25794, "speaker": "Michael Scott"},
  {"season": 5, "episode": 16, "scene": 20, "line": "My worst breakup was actually two breakups. Two different men. I was in love with both of them and when things went bad they had a duel over me.", "order": 25795, "speaker": "Angela Martin"},
  {"season": 5, "episode": 16, "scene": 20, "line": "Yeah, Dwight and Andy. We were here.", "order": 25796, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 16, "scene": 20, "line": "No, this was years ago when I was living in Ohio. John Mark and John David.", "order": 25797, "speaker": "Angela Martin"},
  {"season": 5, "episode": 16, "scene": 20, "line": "Angela, you had two sets of different men actually duel over you?", "order": 25798, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 16, "scene": 20, "line": "I guess I have. Huh.", "order": 25799, "speaker": "Angela Martin"},
  {"season": 5, "episode": 16, "scene": 20, "line": "Alright who's next? Where's Andy?", "order": 25800, "speaker": "Michael Scott"},
  {"season": 5, "episode": 16, "scene": 20, "line": "He's on one of his honeymoons.", "order": 25801, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 16, "scene": 20, "line": "What?", "order": 25802, "speaker": "Michael Scott"},
  {"season": 5, "episode": 16, "scene": 20, "line": "He made non-refundable deposits on his honeymoons, so he's just knocking them off one at a time. I think today he's hot air ballooning and later he's got a couple's massage.", "order": 25803, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 16, "scene": 20, "line": "My worst breakup was with Stacy. It was a Sunday morning, we were reading the paper, and I said 'Oh my God, I think the Eagles could clinch the NFC East!' and she said that we're done.", "order": 25804, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 16, "scene": 20, "line": "You know what guys? I don't think we need to do this.", "order": 25805, "speaker": "Michael Scott"},
  {"season": 5, "episode": 16, "scene": 20, "line": "You're right. OK everyone, back to work.", "order": 25806, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 16, "scene": 20, "line": "No, no, no, no. I mean have this kind of party. I look around and I see all these beautiful people who are alone on Valentine's, and I think that there are other single people out there too. We just need to find them. There's a girl out there for all of us maybe even in this office park. There has to be a way to get all these lonely people together.", "order": 25807, "speaker": "Michael Scott"},
  {"season": 5, "episode": 16, "scene": 20, "line": "A net? a giant net?", "order": 25808, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 16, "scene": 20, "line": "No. Not a giant net.", "order": 25809, "speaker": "Michael Scott"},
  {"season": 5, "episode": 16, "scene": 20, "line": "What do you have in mind?", "order": 25810, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 16, "scene": 20, "line": "I was thinking maybe like a mixer.", "order": 25811, "speaker": "Michael Scott"},
  {"season": 5, "episode": 16, "scene": 20, "line": "Oh God, that's a terrible idea.", "order": 25812, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 16, "scene": 20, "line": "Old fashioned meet market --", "order": 25813, "speaker": "Michael Scott"},
  {"season": 5, "episode": 16, "scene": 20, "line": "No.", "order": 25814, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 16, "scene": 20, "line": "-- I don't think it is.", "order": 25815, "speaker": "Michael Scott"},
  {"season": 5, "episode": 16, "scene": 20, "line": "Lonely people mixing with one another? Breeding? Creating an even lonelier generation? Ha, you're not allowing natural selection to do its work. Pssh. You're like the guy who invented the seat belt.", "order": 25816, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 16, "scene": 21, "line": "These people need love and I am going to get it for them Who cares if we sell a little bit less paper today? A great boss cares more about the happiness of his employees then anything else. I am going to be cupid, and I am going to shoot my sparrow at unsuspecting victims, and they are going to get hit and say 'I'm in love I was hit by cupid's sparrow.' Funny little bird, but he gets the job done.", "order": 25817, "speaker": "Michael Scott"},
  {"season": 5, "episode": 16, "scene": 22, "line": "Hey.", "order": 25818, "speaker": "Michael Scott"},
  {"season": 5, "episode": 16, "scene": 22, "line": "Hey. No movement.", "order": 25819, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 16, "scene": 22, "line": "Ah, still early.", "order": 25820, "speaker": "Michael Scott"},
  {"season": 5, "episode": 16, "scene": 22, "line": "Eh, its not that early.", "order": 25821, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 16, "scene": 22, "line": "[sighs] So how you holding up?", "order": 25822, "speaker": "Michael Scott"},
  {"season": 5, "episode": 16, "scene": 22, "line": "I'm ok. Feel a little lopsided because of all the blood they took out of my right side.", "order": 25823, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 16, "scene": 22, "line": "No. No, I meant about being single today.", "order": 25824, "speaker": "Michael Scott"},
  {"season": 5, "episode": 16, "scene": 22, "line": "Oh. Meh.", "order": 25825, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 16, "scene": 22, "line": "Meh, exactly. Eh.", "order": 25826, "speaker": "Michael Scott"},
  {"season": 5, "episode": 16, "scene": 22, "line": "Eh.", "order": 25827, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 16, "scene": 22, "line": "Oh, here we go.", "order": 25828, "speaker": "Michael Scott"},
  {"season": 5, "episode": 16, "scene": 22, "line": "Hello.", "order": 25829, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 16, "scene": 22, "line": "Hello. [two women, a blonde and a redhead, enter the office. Michael and Dwight walk towards them and Dwight extends his hand to the redhead]", "order": 25830, "speaker": "Michael Scott"},
  {"season": 5, "episode": 16, "scene": 22, "line": "Dwight K. Schrute. Why don't you introduce me to your little friend? [Dwight immediately breaks the handshake with the redhead and turns to the blonde] Hi, Dwight.", "order": 25831, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 16, "scene": 22, "line": "Babe alert! [to the redhead] Hello, I'm Michael Scott, welcome to our little shindig.", "order": 25832, "speaker": "Michael Scott"},
  {"season": 5, "episode": 16, "scene": 22, "line": "Oh, hi! I'm Lynn.", "order": 25833, "speaker": "Lynn"},
  {"season": 5, "episode": 16, "scene": 22, "line": "Lynn, follow me, come on in. For you we have one of our top people Kevin Malone. Kevin, come out here show your beautiful self.", "order": 25834, "speaker": "Michael Scott"},
  {"season": 5, "episode": 16, "scene": 22, "line": "Hello.", "order": 25835, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 16, "scene": 22, "line": "Hi.", "order": 25836, "speaker": "Lynn"},
  {"season": 5, "episode": 16, "scene": 22, "line": "[after a short pause] So run with the ball! Run with it, Kev. [after another pause] Where you from?", "order": 25837, "speaker": "Michael Scott"},
  {"season": 5, "episode": 16, "scene": 22, "line": "I'm from here!", "order": 25838, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 16, "scene": 22, "line": "Yeah, OK. Well, he only gets better.", "order": 25839, "speaker": "Michael Scott"},
  {"season": 5, "episode": 16, "scene": 22, "line": "Thank you, Michael.", "order": 25840, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 16, "scene": 23, "line": "I can untie any knot. I'm serious. Name a knot, any knot. Go ahead.", "order": 25841, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 16, "scene": 23, "line": "I- I- believe you.", "order": 25842, "speaker": "Blonde"},
  {"season": 5, "episode": 16, "scene": 23, "line": "You shouldn't believe everything you hear. In fact, there are many knots that I cannot untie. So where does a woman as charming as yourself find herself employed on a day such as today.", "order": 25843, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 16, "scene": 23, "line": "Um, I work at a place that does catalogs for community colleges and small businesses.", "order": 25844, "speaker": "Blonde"},
  {"season": 5, "episode": 16, "scene": 23, "line": "You must use a lot of paper.", "order": 25845, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 16, "scene": 23, "line": "Oh God tons of it .", "order": 25846, "speaker": "Blonde"},
  {"season": 5, "episode": 16, "scene": 24, "line": "What's the rule about eating when people are in the bathroom?", "order": 25847, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 16, "scene": 24, "line": "I think if you ordered hot food you're allowed to eat.", "order": 25848, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 16, "scene": 24, "line": "Oh, damn. [Pam looks down at her salad] They've been in there for like ten minutes.", "order": 25849, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 16, "scene": 24, "line": "Look at that. Bob ordered hot food.", "order": 25850, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 16, "scene": 24, "line": "Yes. And I think they gave him too many fries.", "order": 25851, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 16, "scene": 24, "line": "We should help him out. [Jim and Pam each take a French fry, eat it, and then look toward the bathroom]", "order": 25852, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 16, "scene": 25, "line": "At the Circle Drive-In they show old movies. It's really cool.", "order": 25853, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 16, "scene": 25, "line": "That does sound cool.", "order": 25854, "speaker": "Lynn"},
  {"season": 5, "episode": 16, "scene": 25, "line": "I used to go there with my fiance [Lynn is taken aback]. Before she left me [Lynn shows pity]. No, I mean, before I left her [Lynn looks confused]. She left me. [Kevin walks away dejected]", "order": 25855, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 16, "scene": 26, "line": "I think I blew it. It all happened so fast. So... fast.", "order": 25856, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 16, "scene": 27, "line": "So, Eric. You mentioned before that you are in tool and dye repair.", "order": 25857, "speaker": "Michael Scott"},
  {"season": 5, "episode": 16, "scene": 27, "line": "I am.", "order": 25858, "speaker": "Eric"},
  {"season": 5, "episode": 16, "scene": 27, "line": "Meredith recently had a total hysterectomy, so that's sort of a repair. Alright, I'll let you guys talk.", "order": 25859, "speaker": "Michael Scott"},
  {"season": 5, "episode": 16, "scene": 28, "line": "It's going very well. People are mixing, a lot of hope. Cause that's what you hate to see, when hope gets crushed. [Michael suddenly turns to look at the open entrance door] Sorry, thought I heard somebody coming in. Um, so [looking at his watch] it's not too late.", "order": 25860, "speaker": "Michael Scott"},
  {"season": 5, "episode": 16, "scene": 29, "line": "...and the kind of discounts we're talking about are not... hold on - Michael, Why do you keep looking at the front door?", "order": 25861, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 16, "scene": 29, "line": "No reason.", "order": 25862, "speaker": "Michael Scott"},
  {"season": 5, "episode": 16, "scene": 29, "line": "Is somebody after you?", "order": 25863, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 16, "scene": 29, "line": "Why do you always go to that? Has anyone ever been after anyone in this office?", "order": 25864, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 16, "scene": 29, "line": "Hey, it just takes one!", "order": 25865, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 16, "scene": 29, "line": "Nobody's after me, I just, I met a woman when I was giving blood and I thought she might come by.", "order": 25866, "speaker": "Michael Scott"},
  {"season": 5, "episode": 16, "scene": 29, "line": "You met a woman when you were giving blood? That is so romantic.", "order": 25867, "speaker": "Kelly Kapoor"},
  {"season": 5, "episode": 16, "scene": 29, "line": "It's not a big deal really, I just, you know met somebody, we hardly talked, I picked up her glove so I was hoping I could give it back to her.", "order": 25868, "speaker": "Michael Scott"},
  {"season": 5, "episode": 16, "scene": 29, "line": "Oh my God, that makes it even more romantic. This is like a modern day Enchanted, it's like a fairy tale.", "order": 25869, "speaker": "Kelly Kapoor"},
  {"season": 5, "episode": 16, "scene": 29, "line": "She could be your soul mate.", "order": 25870, "speaker": "Meredith Palmer"},
  {"season": 5, "episode": 16, "scene": 29, "line": "Oh, not likely. 3 billion woman on the planet, most of them live in Asia so the numbers just don't add up.", "order": 25871, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 16, "scene": 29, "line": "It's possible.", "order": 25872, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 16, "scene": 29, "line": "She could be.", "order": 25873, "speaker": "Kelly Kapoor"},
  {"season": 5, "episode": 16, "scene": 29, "line": "Believe me its nothing. I hardly even talked to her we just, we were lying there next to each other, I think our blood bags touched.", "order": 25874, "speaker": "Michael Scott"},
  {"season": 5, "episode": 16, "scene": 29, "line": "Aww. [Michael grins sheepishly]", "order": 25875, "speaker": "Kelly Kapoor"},
  {"season": 5, "episode": 16, "scene": 30, "line": "Here they come.", "order": 25876, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 16, "scene": 30, "line": "[looking up from her pilfering of Bob's food] What?", "order": 25877, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 16, "scene": 30, "line": "No, just kidding. Seriously though, that's enough.", "order": 25878, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 16, "scene": 30, "line": "Well, you should cover it with the broccoli.", "order": 25879, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 16, "scene": 30, "line": "Oh great, I have to cover?", "order": 25880, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 16, "scene": 30, "line": "Do you think they dined and dashed?", "order": 25881, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 16, "scene": 30, "line": "Well they didn't dine so, yeah, maybe they just dashed.", "order": 25882, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 16, "scene": 30, "line": "I thought we were having a nice time.", "order": 25883, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 16, "scene": 30, "line": "We were.", "order": 25884, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 16, "scene": 30, "line": "Yeah.", "order": 25885, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 16, "scene": 31, "line": "[emerging from the Men's room] Empty.", "order": 25886, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 16, "scene": 31, "line": "[exiting the Ladies' room] Mine too. [Pam and Jim notice the Handicapped stall and walk towards it. Putting their ears to the door they hear Mr. and Mrs. Vance being intimate]", "order": 25887, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 16, "scene": 31, "line": "Oh boy.", "order": 25888, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 16, "scene": 31, "line": "My God.", "order": 25889, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 16, "scene": 31, "line": "OK.", "order": 25890, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 16, "scene": 32, "line": "Is this the party? [everyone turns to Michael wanting to know if this is Blood Girl]", "order": 25891, "speaker": "Girl"},
  {"season": 5, "episode": 16, "scene": 32, "line": "Nah. [everyone groans and Girl turns around and walks away.", "order": 25892, "speaker": "Michael Scott"},
  {"season": 5, "episode": 16, "scene": 33, "line": "OK then.", "order": 25893, "speaker": "Bob"},
  {"season": 5, "episode": 16, "scene": 33, "line": "So...", "order": 25894, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 16, "scene": 33, "line": "Where were we?", "order": 25895, "speaker": "Bob"},
  {"season": 5, "episode": 16, "scene": 33, "line": "[out of breath] Bowling.", "order": 25896, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 16, "scene": 33, "line": "Yep, that, yeah.", "order": 25897, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 16, "scene": 33, "line": "You didn't eat much there Jimbo.", "order": 25898, "speaker": "Bob"},
  {"season": 5, "episode": 16, "scene": 33, "line": "Oh initially I did.", "order": 25899, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 16, "scene": 33, "line": "Want some meat?", "order": 25900, "speaker": "Bob"},
  {"season": 5, "episode": 16, "scene": 33, "line": "Oh sure, a little piece. Ooh, no mushroom though.", "order": 25901, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 16, "scene": 33, "line": "Forgot.", "order": 25902, "speaker": "Bob"},
  {"season": 5, "episode": 16, "scene": 33, "line": "Yeah, thank you. [As Phyllis enjoys Bob's meat, Jim and Pam look knowingly into the camera]", "order": 25903, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 16, "scene": 34, "line": "Hey, I'm sorry we did not have a chance to talk more. I get very nervous talking to pretty girls. Seriously, feel how sweaty my hand is.", "order": 25904, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 16, "scene": 34, "line": "That's really sweaty.", "order": 25905, "speaker": "Lynn"},
  {"season": 5, "episode": 16, "scene": 34, "line": "Are you on email?", "order": 25906, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 16, "scene": 34, "line": "Oh, yeah. [gives Kevin her card]", "order": 25907, "speaker": "Lynn"},
  {"season": 5, "episode": 16, "scene": 34, "line": "Cool. Bye.", "order": 25908, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 16, "scene": 34, "line": "Bye, Kevin.", "order": 25909, "speaker": "Lynn"},
  {"season": 5, "episode": 16, "scene": 35, "line": "Good Valentine's.", "order": 25910, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 16, "scene": 36, "line": "So basically, the greater volume you decide to buy in, the greater discount we can give you.", "order": 25911, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 16, "scene": 36, "line": "OK. [Blonde stands up to leave]", "order": 25912, "speaker": "Blonde"},
  {"season": 5, "episode": 16, "scene": 36, "line": "OK, what's up?", "order": 25913, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 16, "scene": 36, "line": "Look, I'm gonna go...", "order": 25914, "speaker": "Blonde"},
  {"season": 5, "episode": 16, "scene": 36, "line": "Oh-oh-oh before you go, I'd just love to get a firm commitment on this. You know maybe sign--", "order": 25915, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 16, "scene": 36, "line": "Look, we already have a paper supplier.", "order": 25916, "speaker": "Blonde"},
  {"season": 5, "episode": 16, "scene": 36, "line": "OK. Well, thanks for wasting my time tonight, idiot. God!", "order": 25917, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 16, "scene": 36, "line": "Hey, you don't deserve her.", "order": 25918, "speaker": "Michael Scott"},
  {"season": 5, "episode": 16, "scene": 36, "line": "Thanks, Michael.", "order": 25919, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 16, "scene": 37, "line": "Hey, if anybody wants to go...", "order": 25920, "speaker": "Michael Scott"},
  {"season": 5, "episode": 16, "scene": 37, "line": "We can stay a little longer.", "order": 25921, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 16, "scene": 37, "line": "Really? [everybody signifies their agreement, and Kelly gives Michael a piece of cake] Oh, thanks.", "order": 25922, "speaker": "Michael Scott"},
  {"season": 5, "episode": 16, "scene": 37, "line": "Michael, it's time.", "order": 25923, "speaker": "Kelly Kapoor"},
  {"season": 5, "episode": 16, "scene": 37, "line": "You know what, you guys, you guys can get out I'm gonna soldier on a little bit.", "order": 25924, "speaker": "Michael Scott"},
  {"season": 5, "episode": 16, "scene": 37, "line": "Come on, we'll all go.", "order": 25925, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 16, "scene": 37, "line": "[after some encouragement] OK.", "order": 25926, "speaker": "Michael Scott"},
  {"season": 5, "episode": 16, "scene": 38, "line": "Four months ago, I dated a woman named Holly and, um, this is actually the first time that I've even considered getting back into that arena again. You know what, sometimes it's not about whether Cinderella gets her slipper back, but it's about the fact that the prince even picked up the slipper at all. There's a lot of princesses out there. You know, they have all different sizes and shapes of feet and hands so I think, I think my odds are pretty good.", "order": 25927, "speaker": "Michael Scott"},
  {"season": 5, "episode": 16, "scene": 39, "line": "Pardon me, may I have a chocolate chip cookie? I gave blood earlier and I'm still feeling woozy.", "order": 25928, "speaker": "Stanley Hudson"},
  {"season": 5, "episode": 16, "scene": 39, "line": "Of course. [noticing Stanley's arm] That's weird. You got a cotton ball and tape and we've been using Band-Aids.", "order": 25929, "speaker": "Blood Drive Worker"},
  {"season": 5, "episode": 16, "scene": 39, "line": "[backing out of the bloodmobile] I, uh-oh I feel so woozy I just [to Phyllis] Band-Aids.", "order": 25930, "speaker": "Stanley Hudson"},
  {"season": 5, "episode": 16, "scene": 39, "line": "Damn.", "order": 25931, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 17, "scene": 1, "line": "[on the phone] Yeah, that's no problem.", "order": 25932, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 17, "scene": 1, "line": "Pam?", "order": 25933, "speaker": "Michael Scott"},
  {"season": 5, "episode": 17, "scene": 1, "line": "[on the phone] Sure. Uh huh.", "order": 25934, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 17, "scene": 1, "line": "Pam? Knock, knock.", "order": 25935, "speaker": "Michael Scott"},
  {"season": 5, "episode": 17, "scene": 1, "line": "I'm on the phone.", "order": 25936, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 17, "scene": 1, "line": "I know you are. Knock, knock.", "order": 25937, "speaker": "Michael Scott"},
  {"season": 5, "episode": 17, "scene": 1, "line": "[on the phone] You can fax it over. Yeah, 5-7-0-5-5-5-0-1--", "order": 25938, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 17, "scene": 1, "line": "[at same time as Pam] 4-9-1-7-4-5-1", "order": 25939, "speaker": "Michael Scott"},
  {"season": 5, "episode": 17, "scene": 1, "line": "0-1-7-5. Thank you. Bye, bye. [to Michael] It really makes us look unprofessional.", "order": 25940, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 17, "scene": 1, "line": "They would never know it was me doing it. Here we go, Knock, knock. [Dwight walks over]", "order": 25941, "speaker": "Michael Scott"},
  {"season": 5, "episode": 17, "scene": 1, "line": "[sighs] Who's there?", "order": 25942, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 17, "scene": 1, "line": "Buddha.", "order": 25943, "speaker": "Michael Scott"},
  {"season": 5, "episode": 17, "scene": 1, "line": "Buddha who?", "order": 25944, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 17, "scene": 1, "line": "[puts a slice of bread and stick of butter on Pam's desk] Buddha this bread for me, won't you? [Michael and Dwight laugh]", "order": 25945, "speaker": "Michael Scott"},
  {"season": 5, "episode": 17, "scene": 1, "line": "Great.", "order": 25946, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 17, "scene": 1, "line": "I, I need something to wipe my hand.", "order": 25947, "speaker": "Michael Scott"},
  {"season": 5, "episode": 17, "scene": 1, "line": "Now there's, there's butter on my desk.", "order": 25948, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 17, "scene": 1, "line": "That was helping. It was classic.", "order": 25949, "speaker": "Michael Scott"},
  {"season": 5, "episode": 17, "scene": 1, "line": "I got a knock-knock joke.", "order": 25950, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 17, "scene": 1, "line": "No... God.", "order": 25951, "speaker": "Michael Scott"},
  {"season": 5, "episode": 17, "scene": 1, "line": "OK, Michael, please, please, please, please, please let me.", "order": 25952, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 17, "scene": 1, "line": "Alright.", "order": 25953, "speaker": "Michael Scott"},
  {"season": 5, "episode": 17, "scene": 1, "line": "[clears throat] Knock, knock.", "order": 25954, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 17, "scene": 1, "line": "Who's there?", "order": 25955, "speaker": "Michael Scott"},
  {"season": 5, "episode": 17, "scene": 1, "line": "KGB.", "order": 25956, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 17, "scene": 1, "line": "KGB - [Dwight slaps Michael on the face]", "order": 25957, "speaker": "Michael Scott"},
  {"season": 5, "episode": 17, "scene": 1, "line": "[in Russian accent] We will ask the questions!", "order": 25958, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 17, "scene": 1, "line": "What the hell was that? [Michael starts to fight with Dwight]", "order": 25959, "speaker": "Michael Scott"},
  {"season": 5, "episode": 17, "scene": 1, "line": "What are you doing?", "order": 25960, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 17, "scene": 1, "line": "What are you doing?", "order": 25961, "speaker": "Michael Scott"},
  {"season": 5, "episode": 17, "scene": 1, "line": "Hey! Hey! Stop it! Stop it!", "order": 25962, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 17, "scene": 1, "line": "You, you like that?", "order": 25963, "speaker": "Michael Scott"},
  {"season": 5, "episode": 17, "scene": 1, "line": "C'mon. What are you doing? Mine was a part of my hilarious joke.", "order": 25964, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 17, "scene": 1, "line": "Mine was retribution. What are you doing? No more knock-knock jokes. That's it.", "order": 25965, "speaker": "Michael Scott"},
  {"season": 5, "episode": 17, "scene": 1, "line": "Ding Dong.", "order": 25966, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 17, "scene": 1, "line": "[to Jim] Who's there?", "order": 25967, "speaker": "Michael Scott"},
  {"season": 5, "episode": 17, "scene": 1, "line": "KGB.", "order": 25968, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 17, "scene": 1, "line": "Dwight, get the door.", "order": 25969, "speaker": "Michael Scott"},
  {"season": 5, "episode": 17, "scene": 1, "line": "I'm not answering it.", "order": 25970, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 17, "scene": 1, "line": "Answer the door.", "order": 25971, "speaker": "Michael Scott"},
  {"season": 5, "episode": 17, "scene": 1, "line": "Ding dong.", "order": 25972, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 17, "scene": 1, "line": "No way, it's the KGB.", "order": 25973, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 17, "scene": 1, "line": "Ding dong.", "order": 25974, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 17, "scene": 1, "line": "I'm not answering that. You answer it.", "order": 25975, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 17, "scene": 1, "line": "I'm not gonna answer it", "order": 25976, "speaker": "Michael Scott"},
  {"season": 5, "episode": 17, "scene": 1, "line": "I'm not gonna answer it, it's the KGB. [Jim slaps Dwight]", "order": 25977, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 17, "scene": 1, "line": "The KGB will wait for no one! [Michael laughs]", "order": 25978, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 17, "scene": 1, "line": "It's true.", "order": 25979, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 17, "scene": 2, "line": "[Michael walks in dressed as Willy Wonka] Good morning, Michael.", "order": 25980, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 17, "scene": 2, "line": "Oh, no, no, no, no. I am not Michael. I am Willy Wonkaaaaa.", "order": 25981, "speaker": "Michael Scott"},
  {"season": 5, "episode": 17, "scene": 2, "line": "Good morning, Mr. Wonka, here are your messages.", "order": 25982, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 17, "scene": 2, "line": "Why thank you very much. [notices jelly beans] Oh, what are those? What are those? Tell me, please!", "order": 25983, "speaker": "Michael Scott"},
  {"season": 5, "episode": 17, "scene": 2, "line": "Jelly beans.", "order": 25984, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 17, "scene": 2, "line": "No, no, no. They are not just ordinary jelly beans little girl. These are extraordinary jelly beans!", "order": 25985, "speaker": "Michael Scott"},
  {"season": 5, "episode": 17, "scene": 3, "line": "The Willy Wonka Golden Ticket promotional idea is probably the best idea I have ever had. It's probably the best idea anybody has ever had. Three days ago, I slipped five pieces of gold paper into random paper shipments. This entitles the customer to ten percent off of their total order. It will be a day for them that is full of whimsy, and full of excitement and full of fantasy.", "order": 25986, "speaker": "Michael Scott"},
  {"season": 5, "episode": 17, "scene": 4, "line": "I've written these things because it is my responsibility, as manager of this branch, to profiligate great ideas and I think I have done my part with the golden ticket promotion. Now it is your turn. I want to hear some great ideas from you that are just as good as mine. [Jim raises hand] Umm hmm.", "order": 25987, "speaker": "Michael Scott"},
  {"season": 5, "episode": 17, "scene": 4, "line": "We own our own delivery trucks. We could lease them out on the weekends --", "order": 25988, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 17, "scene": 4, "line": "Too many words. Good ideas are simple. 'Golden ticket.'", "order": 25989, "speaker": "Michael Scott"},
  {"season": 5, "episode": 17, "scene": 4, "line": "Free paper.'", "order": 25990, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 17, "scene": 4, "line": "No. Jim. We're a business. Post-its. That is a golden ticket idea. NASA took five or six golden ticket ideas to get man on the moon.", "order": 25991, "speaker": "Michael Scott"},
  {"season": 5, "episode": 17, "scene": 4, "line": "Golden Girls. That's a golden ticket idea, right, I mean how great was that show? Golden Grahams. Another, is it -- I don't get this...", "order": 25992, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 17, "scene": 4, "line": "No, you don't. No, it - [sighs] What will be the state of this company if I am the only one coming up with the great ideas? Right?", "order": 25993, "speaker": "Michael Scott"},
  {"season": 5, "episode": 17, "scene": 5, "line": "I think I should call her.", "order": 25994, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 17, "scene": 5, "line": "No! No! No!", "order": 25995, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 17, "scene": 5, "line": "Why is it so bad for me to call and ask her to lunch today?", "order": 25996, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 17, "scene": 5, "line": "[sighs] You're making it too easy for her. You're just conveying, 'Oh I like you just the way you are.'", "order": 25997, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 17, "scene": 5, "line": "But I do like her just the way she is.", "order": 25998, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 17, "scene": 5, "line": "Well that's not what we agreed on.", "order": 25999, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 17, "scene": 5, "line": "What are you doing?", "order": 26000, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 17, "scene": 5, "line": "Huh?", "order": 26001, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 17, "scene": 5, "line": "[to Kevin] Why don't you just go out on a date with her? Try to spark up an on going joke and then in a month or two, if it feels right, you'll know.", "order": 26002, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 17, "scene": 5, "line": "No.", "order": 26003, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 17, "scene": 5, "line": "You don't have to wait that long. I mean, you don't have to wait a month to ask her out. Just ask her out.", "order": 26004, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 17, "scene": 5, "line": "You're asking him to give up all of his power.", "order": 26005, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 17, "scene": 5, "line": "What power?", "order": 26006, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 17, "scene": 6, "line": "Andy, Pam, and Jim are all telling me how to deal with this girl that I like, Lynn. I, I don't like getting advice from more than one person at a time. I'm a textbook over-thinker.", "order": 26007, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 17, "scene": 7, "line": "You can't let a girl feel good about herself. It will backfire on you. Every compliment has to be backhanded. 'Oh I like your dress, but I'd like it more if you had prettier hair.'", "order": 26008, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 17, "scene": 7, "line": "That's psychotic. Do guys actually do that?", "order": 26009, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 17, "scene": 7, "line": "Well guys with girlfriends don't.", "order": 26010, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 17, "scene": 7, "line": "That's low, Tuna.", "order": 26011, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 17, "scene": 8, "line": "[on phone] Hey, Tom. What's that? Oh, you found a golden ticket. [puts hand on receiver and speaks to office] One of my clients found a golden ticket. [Michael screams in excitement] [Jim back on phone] Yup. No, congratulations. [Michael dances in background]", "order": 26012, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 17, "scene": 8, "line": "Woo hoo.", "order": 26013, "speaker": "Michael Scott"},
  {"season": 5, "episode": 17, "scene": 8, "line": "[on phone] I'll take that down right now.", "order": 26014, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 17, "scene": 8, "line": "Tell me; was it a spoiled little girl with big lips...", "order": 26015, "speaker": "Michael Scott"},
  {"season": 5, "episode": 17, "scene": 8, "line": "I'm on the phone.", "order": 26016, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 17, "scene": 8, "line": "...or an odd little boy with a cowboy obsession?", "order": 26017, "speaker": "Michael Scott"},
  {"season": 5, "episode": 17, "scene": 8, "line": "[on phone] Hold on one second.", "order": 26018, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 17, "scene": 8, "line": "Invite them on the tour.", "order": 26019, "speaker": "Michael Scott"},
  {"season": 5, "episode": 17, "scene": 8, "line": "[to Michael] It's actually Blue Cross from Pennsylvania.", "order": 26020, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 17, "scene": 8, "line": "Really? That's kind of a big client. [gets up and heads toward Oscar] Hey, Oscar. Woo hoo hoo. Um, how much of a hit is ten percent of our Blue Cross account?", "order": 26021, "speaker": "Michael Scott"},
  {"season": 5, "episode": 17, "scene": 8, "line": "Ten percent?", "order": 26022, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 17, "scene": 8, "line": "Yeah?", "order": 26023, "speaker": "Michael Scott"},
  {"season": 5, "episode": 17, "scene": 8, "line": "They're our largest client.", "order": 26024, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 17, "scene": 8, "line": "Yeah.", "order": 26025, "speaker": "Michael Scott"},
  {"season": 5, "episode": 17, "scene": 8, "line": "It's gonna hurt.", "order": 26026, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 17, "scene": 8, "line": "[on phone] Hold on one sec. Hold on one sec, Tom, what's that? You found five golden tickets? And does it say limit one per customer? Nope, it doesn't.", "order": 26027, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 17, "scene": 9, "line": "How do you not spread out the tickets into different shipments?", "order": 26028, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 17, "scene": 9, "line": "I thought I did. So...", "order": 26029, "speaker": "Michael Scott"},
  {"season": 5, "episode": 17, "scene": 9, "line": "OK...", "order": 26030, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 17, "scene": 9, "line": "Well no harm no foul.", "order": 26031, "speaker": "Michael Scott"},
  {"season": 5, "episode": 17, "scene": 9, "line": "...well I'm going to call corporate to make sure that they know I didn't lose half my sales.", "order": 26032, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 17, "scene": 9, "line": "You didn't, but... it'll be fine. It'll be good.", "order": 26033, "speaker": "Michael Scott"},
  {"season": 5, "episode": 17, "scene": 10, "line": "Hey. Hey. Hey. You idiot.", "order": 26034, "speaker": "Michael Scott"},
  {"season": 5, "episode": 17, "scene": 10, "line": "Start over.", "order": 26035, "speaker": "Darryl Philbin"},
  {"season": 5, "episode": 17, "scene": 10, "line": "Sir. I placed a bunch of golden tickets into five separate boxes and some how they all ended up with Blue Cross. How does this happen?", "order": 26036, "speaker": "Michael Scott"},
  {"season": 5, "episode": 17, "scene": 10, "line": "Were the boxes near each other?", "order": 26037, "speaker": "Darryl Philbin"},
  {"season": 5, "episode": 17, "scene": 10, "line": "Irrelevant.", "order": 26038, "speaker": "Michael Scott"},
  {"season": 5, "episode": 17, "scene": 10, "line": "I put three pallets on the truck to Blue Cross once a week. They use a lot of paper.", "order": 26039, "speaker": "Darryl Philbin"},
  {"season": 5, "episode": 17, "scene": 10, "line": "OK, I'm going to ask you something and I want you to be honest. What is a pallet?", "order": 26040, "speaker": "Michael Scott"},
  {"season": 5, "episode": 17, "scene": 11, "line": "My golden ticket idea? OK. Why would anyone think that this is my golden ticket idea? There is a one in 13 chance that this could be anybody's golden ticket idea. [sighs] That... [sighs]", "order": 26041, "speaker": "Michael Scott"},
  {"season": 5, "episode": 17, "scene": 12, "line": "Dunder Mifflin, this is Pam. Oh hi ,David. [Michael shakes his head to Pam] No, I'm sorry he's not back from the Civil Rights rally. I'll have him call you the minute he gets back from the Lincoln Memorial.", "order": 26042, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 17, "scene": 13, "line": "When Michael's skirting a phone call, he gave me a list of places to say he is. 'Stopping a fight in the parking lot. ' 'An Obama fashion show. ' Whatever... that is. Or 'trapped in an oil painting.' I'm gonna save that one.", "order": 26043, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 17, "scene": 14, "line": "Nobody panic. The good news is... they can't fire all of us, right?", "order": 26044, "speaker": "Michael Scott"},
  {"season": 5, "episode": 17, "scene": 14, "line": "They can.", "order": 26045, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 17, "scene": 14, "line": "No, they can't.", "order": 26046, "speaker": "Michael Scott"},
  {"season": 5, "episode": 17, "scene": 14, "line": "Yes, they can.", "order": 26047, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 17, "scene": 14, "line": "Oscar, you don't know what the hell you are talking about.", "order": 26048, "speaker": "Michael Scott"},
  {"season": 5, "episode": 17, "scene": 14, "line": "Michael, what do you think shutting down a branch is?", "order": 26049, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 17, "scene": 14, "line": "Alright, then we're screwed. I am just a net that traps all of your crappy subconscious ideas and adds a little bit of my own childhood memories and whimsy, so -", "order": 26050, "speaker": "Michael Scott"},
  {"season": 5, "episode": 17, "scene": 14, "line": "So, well I lost a ton of money today and I have a mortgage, so I'm a little pissed too.", "order": 26051, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 17, "scene": 14, "line": "Thank you, Jim is with me.", "order": 26052, "speaker": "Michael Scott"},
  {"season": 5, "episode": 17, "scene": 14, "line": "Absolutely not, I'm mad at you.", "order": 26053, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 17, "scene": 14, "line": "Well you know what Jim? It is not my fault that you bought a house to impress Pam. That is why carnations exist.", "order": 26054, "speaker": "Michael Scott"},
  {"season": 5, "episode": 17, "scene": 14, "line": "That's not why.", "order": 26055, "speaker": "Creed Bratton"},
  {"season": 5, "episode": 17, "scene": 14, "line": "OK, we need a gold ticket idea to get us out of this mess. [Pam raises hand] Yes.", "order": 26056, "speaker": "Michael Scott"},
  {"season": 5, "episode": 17, "scene": 14, "line": "Does that mean an idea that blows up in our faces later? [Pam's phone rings]", "order": 26057, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 17, "scene": 14, "line": "Good one.", "order": 26058, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 17, "scene": 14, "line": "OK, don't get that. Please? [Pam gets up to answer her phone]", "order": 26059, "speaker": "Michael Scott"},
  {"season": 5, "episode": 17, "scene": 14, "line": "I've got a golden ticket idea. Why don't you skip on up to the roof and jump off?", "order": 26060, "speaker": "Stanley Hudson"},
  {"season": 5, "episode": 17, "scene": 14, "line": "That is not constructive.", "order": 26061, "speaker": "Michael Scott"},
  {"season": 5, "episode": 17, "scene": 14, "line": "[on phone] Dunder Mifflin, this is Pam. Oh hi, David. He's having a colonoscopy. Alright, I'll find out if he's out yet.", "order": 26062, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 17, "scene": 15, "line": "[on phone] Hello David. I just got back from my procedure, so--", "order": 26063, "speaker": "Michael Scott"},
  {"season": 5, "episode": 17, "scene": 15, "line": "[on speaker phone] Michael, what the hell is going on here?", "order": 26064, "speaker": "David Wallace"},
  {"season": 5, "episode": 17, "scene": 15, "line": "How are you doing?", "order": 26065, "speaker": "Michael Scott"},
  {"season": 5, "episode": 17, "scene": 15, "line": "Fine. What is going on?", "order": 26066, "speaker": "David Wallace"},
  {"season": 5, "episode": 17, "scene": 15, "line": "[reading from computer] My colonoscopy was an examination of my large colon and the distal part of my small bowel with a camera.", "order": 26067, "speaker": "Michael Scott"},
  {"season": 5, "episode": 17, "scene": 15, "line": "Why did you OK this golden ticket idea? We're going to lose a fortune, Michael. How could you let this happen?", "order": 26068, "speaker": "David Wallace"},
  {"season": 5, "episode": 17, "scene": 15, "line": "Well, there is an explanation that involves me not letting it happen and I just don't... I don't know.", "order": 26069, "speaker": "Michael Scott"},
  {"season": 5, "episode": 17, "scene": 15, "line": "How could you not know Michael?", "order": 26070, "speaker": "David Wallace"},
  {"season": 5, "episode": 17, "scene": 15, "line": "I think we might have hired an outside marketing consultant.", "order": 26071, "speaker": "Michael Scott"},
  {"season": 5, "episode": 17, "scene": 15, "line": "Might have hi--- OK, what firm?", "order": 26072, "speaker": "David Wallace"},
  {"season": 5, "episode": 17, "scene": 15, "line": "You're breaking up.", "order": 26073, "speaker": "Michael Scott"},
  {"season": 5, "episode": 17, "scene": 15, "line": "Michael?", "order": 26074, "speaker": "David Wallace"},
  {"season": 5, "episode": 17, "scene": 15, "line": "OK, I think, it might have been an inside consultant.", "order": 26075, "speaker": "Michael Scott"},
  {"season": 5, "episode": 17, "scene": 15, "line": "Was it the sales department?", "order": 26076, "speaker": "David Wallace"},
  {"season": 5, "episode": 17, "scene": 15, "line": "Yes.", "order": 26077, "speaker": "Michael Scott"},
  {"season": 5, "episode": 17, "scene": 15, "line": "Was it Jim?", "order": 26078, "speaker": "David Wallace"},
  {"season": 5, "episode": 17, "scene": 15, "line": "No.", "order": 26079, "speaker": "Michael Scott"},
  {"season": 5, "episode": 17, "scene": 15, "line": "OK, was it Dwight?", "order": 26080, "speaker": "David Wallace"},
  {"season": 5, "episode": 17, "scene": 15, "line": "[long pause] Yes.", "order": 26081, "speaker": "Michael Scott"},
  {"season": 5, "episode": 17, "scene": 16, "line": "[Dwight walks in] Come on in. Good to see ya, have a seat. I just wanted to congratulate you on that great golden ticket idea.", "order": 26082, "speaker": "Michael Scott"},
  {"season": 5, "episode": 17, "scene": 16, "line": "That was your idea.", "order": 26083, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 17, "scene": 16, "line": "Woah, wow, who told you that?", "order": 26084, "speaker": "Michael Scott"},
  {"season": 5, "episode": 17, "scene": 16, "line": "You did. Several times. Over and over again.", "order": 26085, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 17, "scene": 16, "line": "[same time as Dwight] No, I don't think I ever did. That was your idea Dwight.", "order": 26086, "speaker": "Michael Scott"},
  {"season": 5, "episode": 17, "scene": 16, "line": "[same time as Michael] You were dressed as Willy Wonka so...", "order": 26087, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 17, "scene": 16, "line": "I'm not taking... I'm not taking...", "order": 26088, "speaker": "Michael Scott"},
  {"season": 5, "episode": 17, "scene": 16, "line": "Wasn't my idea. Loved - but I can't.", "order": 26089, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 17, "scene": 16, "line": "Wait a second, wait a second, I wrote it down in my diary.", "order": 26090, "speaker": "Michael Scott"},
  {"season": 5, "episode": 17, "scene": 16, "line": "You don't keep a diary.", "order": 26091, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 17, "scene": 16, "line": "Yes I do. You've just never seen it. [reading from diary] March 4th. Dwight came up with the best idea today about golden tickets.", "order": 26092, "speaker": "Michael Scott"},
  {"season": 5, "episode": 17, "scene": 16, "line": "Hold that thought. [Dwight gets up and gets own diary from desk and returns] [flipping through diary] March... 4th. Michael Scott came up with golden ticket idea to give five customers ten perfect off for one year.", "order": 26093, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 17, "scene": 16, "line": "Why do you have a diary?", "order": 26094, "speaker": "Michael Scott"},
  {"season": 5, "episode": 17, "scene": 16, "line": "To keep secrets from my computer.", "order": 26095, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 17, "scene": 16, "line": "OK, you know what? You came up with this idea. I remember you told me that you loved the Willy Wonka movie when you were growing up.", "order": 26096, "speaker": "Michael Scott"},
  {"season": 5, "episode": 17, "scene": 16, "line": "Impossible.", "order": 26097, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 17, "scene": 16, "line": "Yes, you did.", "order": 26098, "speaker": "Michael Scott"},
  {"season": 5, "episode": 17, "scene": 16, "line": "I wasn't given candy as a child, so a movie that fetish-ized it that much, would have made no sense to me. Plus, we weren't allowed to see movies. So... do the math.", "order": 26099, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 17, "scene": 16, "line": "What are you writing? [Dwight writes in diary]", "order": 26100, "speaker": "Michael Scott"},
  {"season": 5, "episode": 17, "scene": 16, "line": "Had conversation with Michael about taking credit for an idea that wasn't mine. Discussed movies.", "order": 26101, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 17, "scene": 16, "line": "Would you like to go to lunch with me? Just the two of us?", "order": 26102, "speaker": "Michael Scott"},
  {"season": 5, "episode": 17, "scene": 16, "line": "With all my heart.", "order": 26103, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 17, "scene": 17, "line": "You guys are throwing a lot at me.", "order": 26104, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 17, "scene": 17, "line": "All I'm saying is that it's a first dates, so just keep a respectful distance.", "order": 26105, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 17, "scene": 17, "line": "Right.", "order": 26106, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 17, "scene": 17, "line": "I don't think Jim means to say that you shouldn't touch her.", "order": 26107, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 17, "scene": 17, "line": "No, that is what I mean.", "order": 26108, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 17, "scene": 17, "line": "[to Jim] Shush. [to Kevin] Kevin, a playful touch on the arm, or on the back, it can show your interest and it's really romantic.", "order": 26109, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 17, "scene": 17, "line": "[puts hand on Jim's hand] Like that?", "order": 26110, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 17, "scene": 17, "line": "No, stop it.", "order": 26111, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 17, "scene": 17, "line": "Don't touch her. Don't talk to her. Don't look at her.", "order": 26112, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 17, "scene": 18, "line": "[Jim, Andy and Pam all talking over each other] ...turn into this black and carbon brick-where this barbeque sauce of shame and rage and two hot people with a perfect relationship would not understand that.", "order": 26113, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 17, "scene": 19, "line": "We think a lot alike. Sometimes you will think something and I will say what you're thinking--", "order": 26114, "speaker": "Michael Scott"},
  {"season": 5, "episode": 17, "scene": 19, "line": "OK, what am I thinking right now?", "order": 26115, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 17, "scene": 19, "line": "Umm, nacho chips.", "order": 26116, "speaker": "Michael Scott"},
  {"season": 5, "episode": 17, "scene": 19, "line": "No... How skin is the largest organ of the body.", "order": 26117, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 17, "scene": 19, "line": "Oh, OK. Let me just cut to the chase, Dwight. I want you to fall on your sword for me.", "order": 26118, "speaker": "Michael Scott"},
  {"season": 5, "episode": 17, "scene": 19, "line": "Oh, not gonna happen.", "order": 26119, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 17, "scene": 20, "line": "I did fall on my sword once. I was running with it in my belt. Won't happen again.", "order": 26120, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 17, "scene": 21, "line": "Why do you even want to stay at Dunder Mifflin? I mean what's the point? You're cooped up in there all day. You don't get to do your farming. You're not dating Angela anymore. It doesn't matter if you get fired for taking credit for the gold ticket idea. It doesn't matter. Me on the other hand, I would be lost without this place. You, way too manly.", "order": 26121, "speaker": "Michael Scott"},
  {"season": 5, "episode": 17, "scene": 21, "line": "What about Shoe La La?", "order": 26122, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 17, "scene": 21, "line": "[sighs] It's not ready yet.", "order": 26123, "speaker": "Michael Scott"},
  {"season": 5, "episode": 17, "scene": 22, "line": "I have an idea for a fancy men's shoe store called Shoe La La. And it's just men's shoes for the special occasions in a man's life, like the day that you get married or the day your wife has a baby, or for just lounging around the house.", "order": 26124, "speaker": "Michael Scott"},
  {"season": 5, "episode": 17, "scene": 23, "line": "May I have your attention please. I have an announcement. Mr. Dwight Schrute and I just returned from a wonderful stroll together and although I probably will never do it again, I had fun. I really had fun with my best friend, Dwight.", "order": 26125, "speaker": "Michael Scott"},
  {"season": 5, "episode": 17, "scene": 23, "line": "These aren't announcements.", "order": 26126, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 17, "scene": 23, "line": "Yes they are, you just don't care about the information.", "order": 26127, "speaker": "Michael Scott"},
  {"season": 5, "episode": 17, "scene": 24, "line": "I love working here and I do not want to leave.", "order": 26128, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 17, "scene": 24, "line": "He doesn't love it that much. Wouldn't be such a bad thing for him to be fired.", "order": 26129, "speaker": "Michael Scott"},
  {"season": 5, "episode": 17, "scene": 24, "line": "Well...", "order": 26130, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 17, "scene": 24, "line": "I mean, like we were talking about that is why he has come to the conclusion...", "order": 26131, "speaker": "Michael Scott"},
  {"season": 5, "episode": 17, "scene": 24, "line": "We discussed the fact that I'm cooped up here instead of experiencing the whole wide world.", "order": 26132, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 17, "scene": 24, "line": "Right.", "order": 26133, "speaker": "Michael Scott"},
  {"season": 5, "episode": 17, "scene": 24, "line": "No plowing my own acres. Going around shirtless all day. You know experiencing freedom.", "order": 26134, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 17, "scene": 24, "line": "That's it. That's it. You can't put a price on freedom.", "order": 26135, "speaker": "Michael Scott"},
  {"season": 5, "episode": 17, "scene": 24, "line": "Try me.", "order": 26136, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 17, "scene": 24, "line": "That's why you made the decision. That's why you made...", "order": 26137, "speaker": "Michael Scott"},
  {"season": 5, "episode": 17, "scene": 24, "line": "I haven't made a decision yet.", "order": 26138, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 17, "scene": 24, "line": "You kind of have.", "order": 26139, "speaker": "Michael Scott"},
  {"season": 5, "episode": 17, "scene": 24, "line": "No.", "order": 26140, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 17, "scene": 24, "line": "Yes.", "order": 26141, "speaker": "Michael Scott"},
  {"season": 5, "episode": 17, "scene": 25, "line": "You cannot take the fall for him.", "order": 26142, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 17, "scene": 25, "line": "He said he would do the same for me.", "order": 26143, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 17, "scene": 25, "line": "He can do the same for you. Right now. By getting fired instead of you. So what are you gonna do?", "order": 26144, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 17, "scene": 25, "line": "I'm gonna go back to work. After I write you up for insubordination.", "order": 26145, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 17, "scene": 25, "line": "There it is.", "order": 26146, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 17, "scene": 26, "line": "[seeing David Wallace come into the office] Oh my God. Dwight! Dwight! Get in--- what's it gonna be, what are you gonna do? [Dwight runs into Michael's office] What's it gonna be? Are you gonna do this thing for me? Dwight? [David knocks on Michael's door and opens door] Hey David, how are you? Good to see you. Nice surprise. Dwight and I were just having a very unfortunate conversation.", "order": 26147, "speaker": "Michael Scott"},
  {"season": 5, "episode": 17, "scene": 26, "line": "That's too bad.", "order": 26148, "speaker": "David Wallace"},
  {"season": 5, "episode": 17, "scene": 26, "line": "Yes it is, but it had to be done, didn't it?", "order": 26149, "speaker": "Michael Scott"},
  {"season": 5, "episode": 17, "scene": 26, "line": "Hopefully, nothing that can't be undone because Dwight I owe you an apology. The head of Blue Cross just called. They were so excited about the golden ticket discount that they have decided to make Dunder Mifflin their exclusive provider of all office supplies. Congratulations Dwight. [holds out hand for handshake]", "order": 26150, "speaker": "David Wallace"},
  {"season": 5, "episode": 17, "scene": 26, "line": "David... you're welcome. [Dwight and David shake hands and make exclamations]", "order": 26151, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 17, "scene": 27, "line": "I just want to say that this golden ticket idea is one of the most brilliant signs of initiative I have ever seen at this company and how about a big round of applause for Mr. Dwight Schrute? [everyone starts clapping]", "order": 26152, "speaker": "David Wallace"},
  {"season": 5, "episode": 17, "scene": 27, "line": "Alright Dwight. This is huge.", "order": 26153, "speaker": "David Wallace"},
  {"season": 5, "episode": 17, "scene": 27, "line": "That's what she said! [David laughs]", "order": 26154, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 17, "scene": 27, "line": "Hey, Dwight, great idea. Dwight, Dwight, great idea. How'd you come up with that idea?", "order": 26155, "speaker": "Michael Scott"},
  {"season": 5, "episode": 17, "scene": 27, "line": "Inspiration.", "order": 26156, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 17, "scene": 27, "line": "Really? How did-- how are you inspired? How did it pop into your head Dwight?", "order": 26157, "speaker": "Michael Scott"},
  {"season": 5, "episode": 17, "scene": 27, "line": "[same time as Michael] You never know when it's gonna strike. Just... boom.", "order": 26158, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 17, "scene": 27, "line": "Give me the details of how that happened.", "order": 26159, "speaker": "Michael Scott"},
  {"season": 5, "episode": 17, "scene": 27, "line": "You, you are taking about Dwight Schrute. The biggest Wonka fan I know. I mean, you've been talking about that movie for years.", "order": 26160, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 17, "scene": 27, "line": "What?", "order": 26161, "speaker": "Michael Scott"},
  {"season": 5, "episode": 17, "scene": 27, "line": "And you know what? I even made fun of you when you dressed up as Willy Wonka to pitch this idea and for that I apologize.", "order": 26162, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 17, "scene": 27, "line": "Apology rejected.", "order": 26163, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 17, "scene": 27, "line": "[going up to give Dwight a hug] Thanks for much for helping the company, Dwight.", "order": 26164, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 17, "scene": 27, "line": "Oh Pam.", "order": 26165, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 17, "scene": 27, "line": "Good work, kid.", "order": 26166, "speaker": "Creed Bratton"},
  {"season": 5, "episode": 17, "scene": 27, "line": "Thanks old man.", "order": 26167, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 17, "scene": 27, "line": "This, this is great. OH, OK, look I want to get you on the horn with the marketing people in New York. The should meet you.", "order": 26168, "speaker": "David Wallace"},
  {"season": 5, "episode": 17, "scene": 27, "line": "Yeah.", "order": 26169, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 17, "scene": 27, "line": "Pam, could you set up a call in there in about 15 minutes, please?", "order": 26170, "speaker": "David Wallace"},
  {"season": 5, "episode": 17, "scene": 27, "line": "Sure.", "order": 26171, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 17, "scene": 27, "line": "David? David?", "order": 26172, "speaker": "Michael Scott"},
  {"season": 5, "episode": 17, "scene": 27, "line": "[same time as Michael] 'Cause I've got this idea...", "order": 26173, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 17, "scene": 27, "line": "David?", "order": 26174, "speaker": "Michael Scott"},
  {"season": 5, "episode": 17, "scene": 27, "line": "Yeah?", "order": 26175, "speaker": "David Wallace"},
  {"season": 5, "episode": 17, "scene": 27, "line": "Could I be a part of the meeting also?", "order": 26176, "speaker": "Michael Scott"},
  {"season": 5, "episode": 17, "scene": 27, "line": "You probably have too much to do around here. I don't want to uh, take up your time with another meeting.", "order": 26177, "speaker": "David Wallace"},
  {"season": 5, "episode": 17, "scene": 27, "line": "Yeah, let's face it. This meeting sounds like it's Wonka fans only. He's never seen the movie. [David laughs]", "order": 26178, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 17, "scene": 27, "line": "Alright.", "order": 26179, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 17, "scene": 28, "line": "[on speakerphone] Hey guys, I have with me Dwight Schrute. Dwight, take it away.", "order": 26180, "speaker": "David Wallace"},
  {"season": 5, "episode": 17, "scene": 28, "line": "OK guys, listen up. Here's the deal. I love candy. Sweet sugary candy from the second it touches my tongue to the moment it's metabolized by my stomach acids, so naturally I liked Willy Wonka.", "order": 26181, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 17, "scene": 28, "line": "Wait, do you guys hear that? There's not a dog listening in, is there? [cut to show Michael on the other line] I hear panting.", "order": 26182, "speaker": "Man on phone"},
  {"season": 5, "episode": 17, "scene": 28, "line": "No. No dogs.", "order": 26183, "speaker": "David Wallace"},
  {"season": 5, "episode": 17, "scene": 28, "line": "So I love Willy Wonka. That golden ticket scene is so inspiring to me that that's where I cam up with that idea [Michael comes into conference room].", "order": 26184, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 17, "scene": 28, "line": "There is no movie called Willy Wonka. It's called Willy Wonka and the Chocolate Factory.", "order": 26185, "speaker": "Michael Scott"},
  {"season": 5, "episode": 17, "scene": 28, "line": "It's actually based on a book called Charlie and the Chocolate Factory.", "order": 26186, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 17, "scene": 28, "line": "Pam...", "order": 26187, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 17, "scene": 28, "line": "I can't... vouch for that. But I do know this. He is a liar. He has taken my idea.", "order": 26188, "speaker": "Michael Scott"},
  {"season": 5, "episode": 17, "scene": 28, "line": "That is my idea.", "order": 26189, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 17, "scene": 28, "line": "[on phone] We are gonna call you back guys. [to Michael] What is going on here?", "order": 26190, "speaker": "David Wallace"},
  {"season": 5, "episode": 17, "scene": 28, "line": "OK, here's what happened, David. It was all my idea. You called me and you were really angry at me and I got scared, so I had Dwight take the fall, but now, it turns out that it's a great idea and Dwight will not confess. Can you believe that?", "order": 26191, "speaker": "Michael Scott"},
  {"season": 5, "episode": 17, "scene": 28, "line": "No, no.", "order": 26192, "speaker": "David Wallace"},
  {"season": 5, "episode": 17, "scene": 28, "line": "It is my idea.", "order": 26193, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 17, "scene": 28, "line": "Oh how dare you.", "order": 26194, "speaker": "Michael Scott"},
  {"season": 5, "episode": 17, "scene": 28, "line": "It is my idea. I'm filled with good ideas. Thousands of good ideas.", "order": 26195, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 17, "scene": 28, "line": "You are? Good ideas, huh? Hey, did you come up with toilet buddy? It's a net, circular net, that you put inside the toilet to catch all of your change and your wallet from falling into the toilet? Formally known as toilet guard?", "order": 26196, "speaker": "Michael Scott"},
  {"season": 5, "episode": 17, "scene": 28, "line": "Horse Boat.", "order": 26197, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 17, "scene": 28, "line": "Oh please.", "order": 26198, "speaker": "Michael Scott"},
  {"season": 5, "episode": 17, "scene": 28, "line": "A canoe built around your horse so you can go from riding to water travel without slowing down. Horse Boat!", "order": 26199, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 17, "scene": 28, "line": "Toilet sponge. It's a hollowed out sponge...", "order": 26200, "speaker": "Michael Scott"},
  {"season": 5, "episode": 17, "scene": 28, "line": "Oh give me a break.", "order": 26201, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 17, "scene": 28, "line": "...that is more absorbent, and softer than toilet paper. I have a lot of toilet ideas.", "order": 26202, "speaker": "Michael Scott"},
  {"season": 5, "episode": 17, "scene": 28, "line": "That's because they're easy!", "order": 26203, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 17, "scene": 28, "line": "They're not easy. Everybody has to go to the bathroom.", "order": 26204, "speaker": "Michael Scott"},
  {"season": 5, "episode": 17, "scene": 28, "line": "[same time as Michael] Women's urinals... uh...", "order": 26205, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 17, "scene": 28, "line": "[same time as Michael and Dwight] Guys, guys. OK, enough. Please, enough. What, is this true, Dwight? I mean, is this true?", "order": 26206, "speaker": "David Wallace"},
  {"season": 5, "episode": 17, "scene": 28, "line": "Yes, it's Michael's idea that he forced on me on threat of death.", "order": 26207, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 17, "scene": 28, "line": "Thank You!", "order": 26208, "speaker": "Michael Scott"},
  {"season": 5, "episode": 17, "scene": 28, "line": "What, I don't -- what do you want me to do now, Michael? What am I supposed to do now?", "order": 26209, "speaker": "David Wallace"},
  {"season": 5, "episode": 17, "scene": 28, "line": "Well David, I will be honest with you. I do want the credit without any of the blame.", "order": 26210, "speaker": "Michael Scott"},
  {"season": 5, "episode": 17, "scene": 28, "line": "OK, uhh... I am going back to New York. Pam, do me a favor, don't send me those notes.", "order": 26211, "speaker": "David Wallace"},
  {"season": 5, "episode": 17, "scene": 28, "line": "OK.", "order": 26212, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 17, "scene": 28, "line": "I am gone.", "order": 26213, "speaker": "David Wallace"},
  {"season": 5, "episode": 17, "scene": 29, "line": "When they look back on this day in the history books all that will be remembered is that I had a good idea. And that's what I am going to write down in my diary. That is what I want you to write down in yours.", "order": 26214, "speaker": "Michael Scott"},
  {"season": 5, "episode": 17, "scene": 29, "line": "Oh I am. In my own words.", "order": 26215, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 17, "scene": 29, "line": "I want to see it.", "order": 26216, "speaker": "Michael Scott"},
  {"season": 5, "episode": 17, "scene": 29, "line": "No.", "order": 26217, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 17, "scene": 29, "line": "I want to see it. [Michael gets up to try to see Dwight's diary]", "order": 26218, "speaker": "Michael Scott"},
  {"season": 5, "episode": 17, "scene": 29, "line": "No, Michael.", "order": 26219, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 17, "scene": 29, "line": "Give me... Give me the diary.", "order": 26220, "speaker": "Michael Scott"},
  {"season": 5, "episode": 17, "scene": 29, "line": "No! [Michael struggles to take Dwight's diary from his hand]", "order": 26221, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 17, "scene": 30, "line": "Hi.", "order": 26222, "speaker": "Lynn"},
  {"season": 5, "episode": 17, "scene": 30, "line": "Lynn, I'm just gonna say to you everything that I am thinking.", "order": 26223, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 17, "scene": 30, "line": "OK.", "order": 26224, "speaker": "Lynn"},
  {"season": 5, "episode": 17, "scene": 30, "line": "I think you have the best smile. I'd like to take you out to dinner and a movie.", "order": 26225, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 17, "scene": 30, "line": "OK.", "order": 26226, "speaker": "Lynn"},
  {"season": 5, "episode": 17, "scene": 30, "line": "Nice. [Both laugh] Boobs. [Lynn blushes]", "order": 26227, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 17, "scene": 31, "line": "Well, it's uh, his funeral. So... neh!", "order": 26228, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 17, "scene": 32, "line": "Ding dong.", "order": 26229, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 17, "scene": 32, "line": "Who is it?", "order": 26230, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 17, "scene": 32, "line": "KGB.", "order": 26231, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 17, "scene": 32, "line": "Alright. I just got out of the shower, I'll be one second.", "order": 26232, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 17, "scene": 32, "line": "[in accent] When you are done, open the door. ... Hello in there?", "order": 26233, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 17, "scene": 32, "line": "Yeah, I'm late for work, so I have to brush my teeth, it's a whole routine.", "order": 26234, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 17, "scene": 32, "line": "We have more houses to visit.", "order": 26235, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 17, "scene": 32, "line": "If you want to come back then, that'll be fine.", "order": 26236, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 17, "scene": 32, "line": "We will come back at... how is [looks at watch] 4:45?", "order": 26237, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 17, "scene": 32, "line": "I get back from work around 6.", "order": 26238, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 17, "scene": 32, "line": "How about 5:15?", "order": 26239, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 17, "scene": 32, "line": "You can try. That, that might work.", "order": 26240, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 17, "scene": 32, "line": "Very well, we will come back at 5:15.", "order": 26241, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 17, "scene": 32, "line": "Alright.", "order": 26242, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 18, "scene": 1, "line": "Michael, since it is your fifteenth anniversary at the company, I thought it might be appropriate to begin the festivities with a fifteen-minute round of applause.", "order": 26243, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 18, "scene": 1, "line": "I like it.", "order": 26244, "speaker": "Michael Scott"},
  {"season": 5, "episode": 18, "scene": 1, "line": "Followed by a fifteen minute moment of silence.", "order": 26245, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 18, "scene": 1, "line": "[wearing a tuxedo] I don't know. Is it classy enough?", "order": 26246, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 18, "scene": 1, "line": "Jim! Enough with the classy, ok?", "order": 26247, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 18, "scene": 1, "line": "I just feel like after fifteen years at this company, bravo by the way, that we should celebrate with a very classy event, a night to remember.", "order": 26248, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 18, "scene": 1, "line": "I think you're right. This party has to have all the excitement, drama and intrigue of my time here.", "order": 26249, "speaker": "Michael Scott"},
  {"season": 5, "episode": 18, "scene": 1, "line": "And of course, classy.", "order": 26250, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 18, "scene": 1, "line": "And classy, yeah.", "order": 26251, "speaker": "Michael Scott"},
  {"season": 5, "episode": 18, "scene": 1, "line": "Michael, you're just agreeing with him because he's wearing a tux. Don't you see what he's doing here?", "order": 26252, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 18, "scene": 2, "line": "Last week, Dwight sent out a memo about the dress code. So, this is me showing him that I'm taking it very seriously.", "order": 26253, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 18, "scene": 3, "line": "Oh, here's one: a string quartet, playing classy-cal music. [Jim grimaces]", "order": 26254, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 18, "scene": 3, "line": "[watches Jim grimace, copies him] You know, that's good but it's not classy. I-I need something classy like the opening of a car dealership.", "order": 26255, "speaker": "Michael Scott"},
  {"season": 5, "episode": 18, "scene": 3, "line": "That's it. Or Mr. Peanut.", "order": 26256, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 18, "scene": 3, "line": "Yes.", "order": 26257, "speaker": "Michael Scott"},
  {"season": 5, "episode": 18, "scene": 3, "line": "Mr. Peanut is not classy.", "order": 26258, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 18, "scene": 3, "line": "He is.", "order": 26259, "speaker": "Michael Scott"},
  {"season": 5, "episode": 18, "scene": 3, "line": "He is a regular peanut. He just happens to have a cane, a monocle and a top hat.", "order": 26260, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 18, "scene": 3, "line": "That's what makes him classy.", "order": 26261, "speaker": "Michael Scott"},
  {"season": 5, "episode": 18, "scene": 3, "line": "Ok, how about this? An ice sculpture, shaped like you, covered in chocolate-covered strawberries.", "order": 26262, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 18, "scene": 3, "line": "Oh, Dwight, you're trying too hard, and that's just not classy. You see, the thing about classy is it's a state of mind.", "order": 26263, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 18, "scene": 3, "line": "Well, I'm sorry, I just don't know what classy is then.", "order": 26264, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 18, "scene": 3, "line": "Ok, well let's just try this one on for size. And I apologize because it's right off the top of my head: an ice sculpture. Of you. Completely surrounded by a variety of chocolate-covered fruits.", "order": 26265, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 18, "scene": 3, "line": "Strawberries?", "order": 26266, "speaker": "Michael Scott"},
  {"season": 5, "episode": 18, "scene": 3, "line": "That's inspired.", "order": 26267, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 18, "scene": 3, "line": "I said that! [storms out, slams door behind him]", "order": 26268, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 18, "scene": 3, "line": "Not classy.", "order": 26269, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 18, "scene": 3, "line": "Not classy at all.", "order": 26270, "speaker": "Michael Scott"},
  {"season": 5, "episode": 18, "scene": 3, "line": "De class'.", "order": 26271, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 18, "scene": 3, "line": "French. Classy.", "order": 26272, "speaker": "Michael Scott"},
  {"season": 5, "episode": 18, "scene": 4, "line": "Hi, can I help you?", "order": 26273, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 18, "scene": 4, "line": "Yeah, I'm Charles Miner, here to see Michael Scott.", "order": 26274, "speaker": "Charles Miner"},
  {"season": 5, "episode": 18, "scene": 4, "line": "Sure. Just one second. Please have a seat.", "order": 26275, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 18, "scene": 4, "line": "[on speakerphone] Yes?", "order": 26276, "speaker": "Michael Scott"},
  {"season": 5, "episode": 18, "scene": 4, "line": "Michael, there's a Charles Miner here to see you.", "order": 26277, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 18, "scene": 4, "line": "Miner? I hardly know her!... Hello?", "order": 26278, "speaker": "Michael Scott"},
  {"season": 5, "episode": 18, "scene": 4, "line": "Yeah, are you coming out now?", "order": 26279, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 18, "scene": 4, "line": "Uh-huh. [comes out of office] Well, well, well, who have we here? Ah, it is Prince Charles Miner. [shakes Charles' hand] At your service. Everybody, this is Sir Charles Miner and he is the new VP for the Northeast region. So, just give it up for this big guy, right? Give it up. [leads round of applause] Here he is.", "order": 26280, "speaker": "Michael Scott"},
  {"season": 5, "episode": 18, "scene": 4, "line": "I-I was-", "order": 26281, "speaker": "Charles Miner"},
  {"season": 5, "episode": 18, "scene": 4, "line": "Wow! Hold that thought. I want everybody to go into the break room. I have a little surprise for you. Go ahead. You too. Dwight, would you escort our guest in there? C'mon, here we go, don't be shy, don't be shy, you're one of us . [whispers] Dwight, stall him a little, I have to get the fish.", "order": 26282, "speaker": "Michael Scott"},
  {"season": 5, "episode": 18, "scene": 4, "line": "Hey.", "order": 26283, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 18, "scene": 4, "line": "Yes.", "order": 26284, "speaker": "Michael Scott"},
  {"season": 5, "episode": 18, "scene": 4, "line": "Really wish you would have told me the new boss was coming today.", "order": 26285, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 18, "scene": 4, "line": "What? No, I didn't want to make everybody nervous.", "order": 26286, "speaker": "Michael Scott"},
  {"season": 5, "episode": 18, "scene": 4, "line": "Nope. I mean, I could have brought a change of clothes, or-", "order": 26287, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 18, "scene": 4, "line": "You look dynamite. What are you talking about? Very slick. Ok, get the fish.", "order": 26288, "speaker": "Michael Scott"},
  {"season": 5, "episode": 18, "scene": 5, "line": "Originally founded in 1866, Scranton quickly became one of Pennsylvania's largest anthracite coal-mining communities.", "order": 26289, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 18, "scene": 5, "line": "Surprise, there is nothing in here. The real surprise is in the conference room. Let's go! [swings arm above head for everyone to come, hits doorframe] Oh! God! Ok, come on, come on!", "order": 26290, "speaker": "Michael Scott"},
  {"season": 5, "episode": 18, "scene": 5, "line": "We'll finish it up later, but essentially what we're talking about is...", "order": 26291, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 18, "scene": 6, "line": "Surprise! [unveils bagels sitting on table] As you can see, I turned the bagels from O's into C's, for Charles.", "order": 26292, "speaker": "Michael Scott"},
  {"season": 5, "episode": 18, "scene": 6, "line": "Thank you.", "order": 26293, "speaker": "Charles Miner"},
  {"season": 5, "episode": 18, "scene": 6, "line": "Took me all night.", "order": 26294, "speaker": "Michael Scott"},
  {"season": 5, "episode": 18, "scene": 6, "line": "This is what you did last night?", "order": 26295, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 18, "scene": 7, "line": "I met this guy at corporate last week and we were just digging each other's vibe. I was totally grooving on him and vice-versa. And besides, the last two people to have his job were Jan and Ryan. The former was my lover, and the latter my best friend. So, who knows? I do. [in British cockney accent] It's gonna be mental! It's going to be mental.", "order": 26296, "speaker": "Michael Scott"},
  {"season": 5, "episode": 18, "scene": 8, "line": "My god, he's like a black George Clooney.", "order": 26297, "speaker": "Kelly Kapoor"},
  {"season": 5, "episode": 18, "scene": 8, "line": "Really? I don't see it. I mean, he's ok, he's not unattractive, it's. . .", "order": 26298, "speaker": "Angela Martin"},
  {"season": 5, "episode": 18, "scene": 9, "line": "Hey, how you doing? Jim Halpert, sales. Just wanted to say, if you need anything, let me know.", "order": 26299, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 18, "scene": 9, "line": "Why are you wearing a tuxedo?", "order": 26300, "speaker": "Charles Miner"},
  {"season": 5, "episode": 18, "scene": 9, "line": "I didn't think you'd notice. [laughs nervously] Uh, it's funny actually, there's another salesman out here, Dwight Schrute, and he sent out this memo, as he always does, and, uh, it was about professionalism in the workplace. Of course, he singled me out. So I just had to mess with him.", "order": 26301, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 18, "scene": 9, "line": "Uh.", "order": 26302, "speaker": "Charles Miner"},
  {"season": 5, "episode": 18, "scene": 9, "line": "Oh, you'd understand if you read the memo which I should probably get you, there's probably one in a drawer... uh. It was pretty crazy. But, uh, not more crazy than wearing a tux, at work, I grant you that... so, uh.", "order": 26303, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 18, "scene": 9, "line": "Your name is Jim?", "order": 26304, "speaker": "Charles Miner"},
  {"season": 5, "episode": 18, "scene": 9, "line": "Jim Halpert, yep. So, um, just going to let you get back to it... [being pulled away by Pam]", "order": 26305, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 18, "scene": 10, "line": "He just had to wear his tux today.", "order": 26306, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 18, "scene": 10, "line": "I thought it'd be funny.", "order": 26307, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 18, "scene": 10, "line": "Took him 40 minutes to get ready.", "order": 26308, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 18, "scene": 11, "line": "All right, let me introduce you to some of the troops. Accounting, front and center. Come on up here. I'd like you to meet somebody. This is the accounting department.", "order": 26309, "speaker": "Michael Scott"},
  {"season": 5, "episode": 18, "scene": 11, "line": "Hey, I come from accounting, too.", "order": 26310, "speaker": "Charles Miner"},
  {"season": 5, "episode": 18, "scene": 11, "line": "Oh, nerd alert!", "order": 26311, "speaker": "Michael Scott"},
  {"season": 5, "episode": 18, "scene": 11, "line": "Ni-", "order": 26312, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 18, "scene": 11, "line": "This is Oscar Martinez. He is Latino. And he just got out of a long-term relationship with a man, Gil, who broke his heart. But he didn't bring any of that into work, it did not affect his job performance whatsoever and I am very proud of him for that.", "order": 26313, "speaker": "Michael Scott"},
  {"season": 5, "episode": 18, "scene": 11, "line": "Hey, Oscar, it's good to meet you.", "order": 26314, "speaker": "Charles Miner"},
  {"season": 5, "episode": 18, "scene": 11, "line": "This little hell-raiser is Angela. She has slept with a bunch of different guys in the office. One over there, in the orange [points to Andy]", "order": 26315, "speaker": "Michael Scott"},
  {"season": 5, "episode": 18, "scene": 11, "line": "Hey-o!", "order": 26316, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 18, "scene": 11, "line": "There you go! Where's the other?", "order": 26317, "speaker": "Michael Scott"},
  {"season": 5, "episode": 18, "scene": 11, "line": "You know, Michael, I don't need to know everyone's sexual history.", "order": 26318, "speaker": "Charles Miner"},
  {"season": 5, "episode": 18, "scene": 11, "line": "Well, perfect, because we have now arrived at Kevin and he has no sexual history.", "order": 26319, "speaker": "Michael Scott"},
  {"season": 5, "episode": 18, "scene": 11, "line": "Hey!", "order": 26320, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 18, "scene": 11, "line": "How about I just say hi to everyone at one time?", "order": 26321, "speaker": "Charles Miner"},
  {"season": 5, "episode": 18, "scene": 11, "line": "Good. Good, good, good. Everyone, please give it up for Charles Miner. [claps]", "order": 26322, "speaker": "Michael Scott"},
  {"season": 5, "episode": 18, "scene": 11, "line": "Thank you, Michael. Thank you for the C-shaped bagels.", "order": 26323, "speaker": "Charles Miner"},
  {"season": 5, "episode": 18, "scene": 11, "line": "Oh! Well...", "order": 26324, "speaker": "Michael Scott"},
  {"season": 5, "episode": 18, "scene": 11, "line": "That's great.", "order": 26325, "speaker": "Charles Miner"},
  {"season": 5, "episode": 18, "scene": 11, "line": "Above and beyond.", "order": 26326, "speaker": "Michael Scott"},
  {"season": 5, "episode": 18, "scene": 11, "line": "Hey, you know we're in tough times and we're not immune to this economy.", "order": 26327, "speaker": "Charles Miner"},
  {"season": 5, "episode": 18, "scene": 11, "line": "That's true.", "order": 26328, "speaker": "Michael Scott"},
  {"season": 5, "episode": 18, "scene": 11, "line": "But the goal is to, you know, fight our way through this.", "order": 26329, "speaker": "Charles Miner"},
  {"season": 5, "episode": 18, "scene": 11, "line": "[clears throat, raises his hand] Stanley Hudson. Are there gonna be layoffs?", "order": 26330, "speaker": "Stanley Hudson"},
  {"season": 5, "episode": 18, "scene": 11, "line": "No. Absolutely not.", "order": 26331, "speaker": "Michael Scott"},
  {"season": 5, "episode": 18, "scene": 11, "line": "Uh, hold on, Michael, thank you. Uh, Stanley, you know, we can't make any promises, but we'll try everything in our power to avoid that.", "order": 26332, "speaker": "Charles Miner"},
  {"season": 5, "episode": 18, "scene": 11, "line": "Do you have specifics?", "order": 26333, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 18, "scene": 11, "line": "Sp-Well, um, Michael should have filled you in last week.", "order": 26334, "speaker": "Charles Miner"},
  {"season": 5, "episode": 18, "scene": 11, "line": "Well, due to the economy, there is a lot of worry going around. I didn't want to worry people.", "order": 26335, "speaker": "Michael Scott"},
  {"season": 5, "episode": 18, "scene": 11, "line": "You didn't tell them.", "order": 26336, "speaker": "Charles Miner"},
  {"season": 5, "episode": 18, "scene": 11, "line": "Well, why don't-", "order": 26337, "speaker": "Michael Scott"},
  {"season": 5, "episode": 18, "scene": 11, "line": "Yeah, I will. What I told Michael last week-", "order": 26338, "speaker": "Charles Miner"},
  {"season": 5, "episode": 18, "scene": 11, "line": "Charles is going to tell you.", "order": 26339, "speaker": "Michael Scott"},
  {"season": 5, "episode": 18, "scene": 11, "line": "Is that we are cutting three percent across the board, which means we will no longer be matching 401k contributions. All overtime requests will need to come through the corporate office.", "order": 26340, "speaker": "Charles Miner"},
  {"season": 5, "episode": 18, "scene": 11, "line": "Fantastic.", "order": 26341, "speaker": "Stanley Hudson"},
  {"season": 5, "episode": 18, "scene": 11, "line": "Well, it's not official.", "order": 26342, "speaker": "Michael Scott"},
  {"season": 5, "episode": 18, "scene": 11, "line": "It is official. It is official. And actually, guys, I'm encouraging branches to consider a freeze on discretionary spending.", "order": 26343, "speaker": "Charles Miner"},
  {"season": 5, "episode": 18, "scene": 11, "line": "Such as salary, benefits, etc, etc. Insurance.", "order": 26344, "speaker": "Michael Scott"},
  {"season": 5, "episode": 18, "scene": 11, "line": "No, no, not salaries. Petty cash, supplies, and uh, you know, parties.", "order": 26345, "speaker": "Charles Miner"},
  {"season": 5, "episode": 18, "scene": 11, "line": "Well-", "order": 26346, "speaker": "Michael Scott"},
  {"season": 5, "episode": 18, "scene": 11, "line": "What about your party?", "order": 26347, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 18, "scene": 11, "line": "Ok, ok, you know what? I think this has been great. I think this gives us a lot to think about, doesn't it? Charles Miner, ladies and gentlemen. [starts round of applause] He has a long trip home. Thank you for coming in. We have to get back to work.", "order": 26348, "speaker": "Michael Scott"},
  {"season": 5, "episode": 18, "scene": 11, "line": "Michael, I'm going to stay for the day.", "order": 26349, "speaker": "Charles Miner"},
  {"season": 5, "episode": 18, "scene": 11, "line": "Oh no, no, no. You don't have to do that. I've got this covered. This was just a meet-and-greet.", "order": 26350, "speaker": "Michael Scott"},
  {"season": 5, "episode": 18, "scene": 11, "line": "No, it's a little bit more than that. I'm going to set up in here, ok?", "order": 26351, "speaker": "Charles Miner"},
  {"season": 5, "episode": 18, "scene": 12, "line": "I am thrilled that the new boss has taken such an active interest in all of the responsibilities that I'm supposed to have. Thrilled.", "order": 26352, "speaker": "Michael Scott"},
  {"season": 5, "episode": 18, "scene": 13, "line": "[on speakerphone] David, it was my understanding that I was not going to be managed.", "order": 26353, "speaker": "Michael Scott"},
  {"season": 5, "episode": 18, "scene": 13, "line": "What gave you that idea?", "order": 26354, "speaker": "David Wallace"},
  {"season": 5, "episode": 18, "scene": 13, "line": "It was my understanding.", "order": 26355, "speaker": "Michael Scott"},
  {"season": 5, "episode": 18, "scene": 13, "line": "I see.", "order": 26356, "speaker": "David Wallace"},
  {"season": 5, "episode": 18, "scene": 13, "line": "Listen, why don't we just leave that position vacant? Truth be told, I think I thrive under a lack of accountability.", "order": 26357, "speaker": "Michael Scott"},
  {"season": 5, "episode": 18, "scene": 13, "line": "Look, Michael, Charles is very qualified. Get to know him. I really think the two of you are going to make a great team.", "order": 26358, "speaker": "David Wallace"},
  {"season": 5, "episode": 18, "scene": 13, "line": "But the branch is still mine?", "order": 26359, "speaker": "Michael Scott"},
  {"season": 5, "episode": 18, "scene": 13, "line": "You're still the branch manager, yes, and if you need anything else at all, just let Charles know.", "order": 26360, "speaker": "David Wallace"},
  {"season": 5, "episode": 18, "scene": 13, "line": "Ask him about the party.", "order": 26361, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 18, "scene": 13, "line": "Oh, right. David, are you coming to my fifteenth anniversary party?", "order": 26362, "speaker": "Michael Scott"},
  {"season": 5, "episode": 18, "scene": 13, "line": "I'll give it my best shot, Michael.", "order": 26363, "speaker": "David Wallace"},
  {"season": 5, "episode": 18, "scene": 13, "line": "No the other thing.", "order": 26364, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 18, "scene": 13, "line": "Oh, ok. If we hire Cirque de Soleil as salaried employees, will that help us with year-end tax stuff? [several seconds of silence]", "order": 26365, "speaker": "Michael Scott"},
  {"season": 5, "episode": 18, "scene": 13, "line": "He hung up?", "order": 26366, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 18, "scene": 13, "line": "No.", "order": 26367, "speaker": "David Wallace"},
  {"season": 5, "episode": 18, "scene": 14, "line": "I just got off the horn with David Wallace, and he said that you and I should try to get to know each other better. And I agree, so what I would like you to do is tell me something that you've never told anybody before.", "order": 26368, "speaker": "Michael Scott"},
  {"season": 5, "episode": 18, "scene": 14, "line": "No.", "order": 26369, "speaker": "Charles Miner"},
  {"season": 5, "episode": 18, "scene": 14, "line": "Come on. What's your wife's name? Where did you work before you came here?", "order": 26370, "speaker": "Michael Scott"},
  {"season": 5, "episode": 18, "scene": 14, "line": "Saticoy Steel.", "order": 26371, "speaker": "Charles Miner"},
  {"season": 5, "episode": 18, "scene": 14, "line": "Beautiful. See, African-Americans have such a rich history of unusual names.", "order": 26372, "speaker": "Michael Scott"},
  {"season": 5, "episode": 18, "scene": 14, "line": "No, I worked at a company called Saticoy Steel.", "order": 26373, "speaker": "Charles Miner"},
  {"season": 5, "episode": 18, "scene": 14, "line": "You're not from paper?", "order": 26374, "speaker": "Michael Scott"},
  {"season": 5, "episode": 18, "scene": 14, "line": "No.", "order": 26375, "speaker": "Charles Miner"},
  {"season": 5, "episode": 18, "scene": 14, "line": "Does David know this?", "order": 26376, "speaker": "Michael Scott"},
  {"season": 5, "episode": 18, "scene": 14, "line": "Yeah, he knows. He just wants a good manager.", "order": 26377, "speaker": "Charles Miner"},
  {"season": 5, "episode": 18, "scene": 14, "line": "Well that, actually, is an excellent segue into really what is my only point-", "order": 26378, "speaker": "Michael Scott"},
  {"season": 5, "episode": 18, "scene": 14, "line": "And what is that?", "order": 26379, "speaker": "Charles Miner"},
  {"season": 5, "episode": 18, "scene": 14, "line": "And that- I-I don't need to be managed, Charles. And if you want pick up some tips, observing here, and take them to the other branches, it's all good. But Jan would mostly come by when she was super horny, and Ryan would come by to visit his parents and do laundry, so... Are we clear?", "order": 26380, "speaker": "Michael Scott"},
  {"season": 5, "episode": 18, "scene": 14, "line": "That's not how I plan on doing things.", "order": 26381, "speaker": "Charles Miner"},
  {"season": 5, "episode": 18, "scene": 14, "line": "Ok, alright, well I'll just bounce that off David, see what comes back.", "order": 26382, "speaker": "Michael Scott"},
  {"season": 5, "episode": 18, "scene": 14, "line": "I'll tell David what he needs to know. Sorry. David wants it this way.", "order": 26383, "speaker": "Charles Miner"},
  {"season": 5, "episode": 18, "scene": 14, "line": "Whatever David wants.", "order": 26384, "speaker": "Michael Scott"},
  {"season": 5, "episode": 18, "scene": 14, "line": "Ok.", "order": 26385, "speaker": "Charles Miner"},
  {"season": 5, "episode": 18, "scene": 15, "line": "[on phone] Michael Scott calling for David. Well, just tell him to call me ASAP as possible. Thanks.", "order": 26386, "speaker": "Michael Scott"},
  {"season": 5, "episode": 18, "scene": 15, "line": "Oh, Michael, come on, no. You have to charm 'em. [calls back on speakerphone]", "order": 26387, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 18, "scene": 15, "line": "David Wallace's office.", "order": 26388, "speaker": "Stephanie"},
  {"season": 5, "episode": 18, "scene": 15, "line": "Hey there, gorgeous. How you doing today?", "order": 26389, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 18, "scene": 15, "line": "Good. Thank you.", "order": 26390, "speaker": "Stephanie"},
  {"season": 5, "episode": 18, "scene": 15, "line": "How's it hanging?", "order": 26391, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 18, "scene": 15, "line": "Alright. . .", "order": 26392, "speaker": "Stephanie"},
  {"season": 5, "episode": 18, "scene": 15, "line": "Good. Listen, my name is, uh, Michael... Scotch. And, uh, I just wanted to say that, uh, I've got David Wallace's son in the trunk of my car. If he doesn't get on this phone in 5 seconds-", "order": 26393, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 18, "scene": 15, "line": "No! You idiot! [hangs up] Idiot, idiot, idiot! What are you doing? God! Oh my god. [redials]", "order": 26394, "speaker": "Michael Scott"},
  {"season": 5, "episode": 18, "scene": 15, "line": "David Wallace's office.", "order": 26395, "speaker": "Stephanie"},
  {"season": 5, "episode": 18, "scene": 15, "line": "Hi Stephanie, it's Michael Scott again. I just spoke with Michael Scotch and the son's going to be returned, everything's fine.", "order": 26396, "speaker": "Michael Scott"},
  {"season": 5, "episode": 18, "scene": 15, "line": "Michael...", "order": 26397, "speaker": "Stephanie"},
  {"season": 5, "episode": 18, "scene": 15, "line": "And I really really need to talk to David.", "order": 26398, "speaker": "Michael Scott"},
  {"season": 5, "episode": 18, "scene": 15, "line": "Please hold.", "order": 26399, "speaker": "Stephanie"},
  {"season": 5, "episode": 18, "scene": 15, "line": "[on cell phone] Charles Miner. Hello? Hello, who is this? Who is this?", "order": 26400, "speaker": "Charles Miner"},
  {"season": 5, "episode": 18, "scene": 15, "line": "I was never given a name. [hangs up, he and Dwight sigh in relief]", "order": 26401, "speaker": "Michael Scott"},
  {"season": 5, "episode": 18, "scene": 17, "line": "[to delivery men] Uh, hi, can I help you? I don't think anyone here-", "order": 26402, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 18, "scene": 17, "line": "Oh, uh, Pam? Hey everyone, lunch is on me today.", "order": 26403, "speaker": "Charles Miner"},
  {"season": 5, "episode": 18, "scene": 17, "line": "That is so unnecessary!", "order": 26404, "speaker": "Angela Martin"},
  {"season": 5, "episode": 18, "scene": 17, "line": "Are you kidding me with this? On the day that I bring in breakfast?", "order": 26405, "speaker": "Michael Scott"},
  {"season": 5, "episode": 18, "scene": 17, "line": "It's no big deal.", "order": 26406, "speaker": "Charles Miner"},
  {"season": 5, "episode": 18, "scene": 17, "line": "I wish you had told us sooner, because I was going to go to the vending machine and get an egg salad.", "order": 26407, "speaker": "Michael Scott"},
  {"season": 5, "episode": 18, "scene": 17, "line": "You still have that option. [Michael sighs]", "order": 26408, "speaker": "Charles Miner"},
  {"season": 5, "episode": 18, "scene": 17, "line": "Thanks again, Charles.", "order": 26409, "speaker": "Angela Martin"},
  {"season": 5, "episode": 18, "scene": 17, "line": "Thank you.", "order": 26410, "speaker": "Kelly Kapoor"},
  {"season": 5, "episode": 18, "scene": 17, "line": "Nobody thanked me.", "order": 26411, "speaker": "Michael Scott"},
  {"season": 5, "episode": 18, "scene": 17, "line": "Thanks, Charles.", "order": 26412, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 18, "scene": 17, "line": "For breakfast. Except Charles. You say no more parties, and then you spend all of this money on lunch, I think it's a little hypercritical.", "order": 26413, "speaker": "Michael Scott"},
  {"season": 5, "episode": 18, "scene": 17, "line": "I do this for every branch I go to. If you do not like it, then I think there are some bagels left over from this morning.", "order": 26414, "speaker": "Charles Miner"},
  {"season": 5, "episode": 18, "scene": 18, "line": "[on phone] Damn it, Stephanie, put me through to him. Is that him in the background? David? David! David, pick up the phone!", "order": 26415, "speaker": "Michael Scott"},
  {"season": 5, "episode": 18, "scene": 19, "line": "Should I seduce him?", "order": 26416, "speaker": "Kelly Kapoor"},
  {"season": 5, "episode": 18, "scene": 19, "line": "No. No one wants to see that. [both smile at David]", "order": 26417, "speaker": "Angela Martin"},
  {"season": 5, "episode": 18, "scene": 20, "line": "Michael?", "order": 26418, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 18, "scene": 20, "line": "Yep.", "order": 26419, "speaker": "Michael Scott"},
  {"season": 5, "episode": 18, "scene": 20, "line": "I just realized that I used the 2008 calendar to do the spreadsheets for January and February. I may need to come in this weekend to fix it.", "order": 26420, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 18, "scene": 20, "line": "Do some overtime? You want to do some overtime this weekend? You know what, Kevin, I applaud your initiative. Yes. You may.", "order": 26421, "speaker": "Michael Scott"},
  {"season": 5, "episode": 18, "scene": 20, "line": "Uh, no, sorry, Michael. No, Kevin, right? We're going to need you to do that during office hours.", "order": 26422, "speaker": "Charles Miner"},
  {"season": 5, "episode": 18, "scene": 20, "line": "Ok.", "order": 26423, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 18, "scene": 20, "line": "Ok.", "order": 26424, "speaker": "Charles Miner"},
  {"season": 5, "episode": 18, "scene": 20, "line": "Kevin? This is my branch. You don't ask Charles. You ask me. I say, approved.", "order": 26425, "speaker": "Michael Scott"},
  {"season": 5, "episode": 18, "scene": 20, "line": "That's not the way it's gonna work.", "order": 26426, "speaker": "Charles Miner"},
  {"season": 5, "episode": 18, "scene": 20, "line": "Yes it is!", "order": 26427, "speaker": "Michael Scott"},
  {"season": 5, "episode": 18, "scene": 20, "line": "No, it is not.", "order": 26428, "speaker": "Charles Miner"},
  {"season": 5, "episode": 18, "scene": 20, "line": "No, it is not.", "order": 26429, "speaker": "Michael Scott"},
  {"season": 5, "episode": 18, "scene": 20, "line": "Ok, so we're on the same page, great.", "order": 26430, "speaker": "Charles Miner"},
  {"season": 5, "episode": 18, "scene": 20, "line": "Ok, so we're on the same page, great.", "order": 26431, "speaker": "Michael Scott"},
  {"season": 5, "episode": 18, "scene": 20, "line": "Ok, Michael, what are you doing?", "order": 26432, "speaker": "Charles Miner"},
  {"season": 5, "episode": 18, "scene": 20, "line": "Ok, Michael, what are you doing?", "order": 26433, "speaker": "Michael Scott"},
  {"season": 5, "episode": 18, "scene": 20, "line": "No, seriously.", "order": 26434, "speaker": "Charles Miner"},
  {"season": 5, "episode": 18, "scene": 20, "line": "No, seriously.", "order": 26435, "speaker": "Michael Scott"},
  {"season": 5, "episode": 18, "scene": 20, "line": "How old are you?", "order": 26436, "speaker": "Charles Miner"},
  {"season": 5, "episode": 18, "scene": 20, "line": "How old are you?", "order": 26437, "speaker": "Michael Scott"},
  {"season": 5, "episode": 18, "scene": 20, "line": "Oh no.", "order": 26438, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 18, "scene": 21, "line": "I can tell Michael's mood by which comedy routine he chooses to do, the more infantile, the more upset he is. And he just skipped the Ace Ventura talking butt thing. He never skips it. This is bad.", "order": 26439, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 18, "scene": 22, "line": "Five years old?", "order": 26440, "speaker": "Charles Miner"},
  {"season": 5, "episode": 18, "scene": 22, "line": "Five years old?", "order": 26441, "speaker": "Michael Scott"},
  {"season": 5, "episode": 18, "scene": 22, "line": "Duuudes, stop.", "order": 26442, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 18, "scene": 22, "line": "[as Michael continues imitating Charles] Not a good idea, Michael. Michael, stop. Stop doing that. Stop it. You're going to get all of us in trouble.", "order": 26443, "speaker": "Employees"},
  {"season": 5, "episode": 18, "scene": 22, "line": "I will walk away.", "order": 26444, "speaker": "Charles Miner"},
  {"season": 5, "episode": 18, "scene": 22, "line": "I'm gonna walk away.", "order": 26445, "speaker": "Michael Scott"},
  {"season": 5, "episode": 18, "scene": 22, "line": "Stop doing that!", "order": 26446, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 18, "scene": 22, "line": "I'm kidding around, wow! Relax, everybody, it's just a joke. It's fine. Boy, so touchy. PPC, let's continue our morning meeting in the conference room.", "order": 26447, "speaker": "Michael Scott"},
  {"season": 5, "episode": 18, "scene": 23, "line": "And then, out of that cake, pops another stripper holding a smaller cake. And then an even smaller stripper pops out of that one.", "order": 26448, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 18, "scene": 23, "line": "What is that smaller stripper holding?", "order": 26449, "speaker": "Michael Scott"},
  {"season": 5, "episode": 18, "scene": 23, "line": "Cupcake. It's cupcakes and strippers all the way down.", "order": 26450, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 18, "scene": 23, "line": "Ok.", "order": 26451, "speaker": "Michael Scott"},
  {"season": 5, "episode": 18, "scene": 24, "line": "What exactly is the PPC?", "order": 26452, "speaker": "Charles Miner"},
  {"season": 5, "episode": 18, "scene": 24, "line": "Oh, that's the Party Planning Committee. They spend hours planning parties.", "order": 26453, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 18, "scene": 25, "line": "Excuse me, guys. What's going on in here?", "order": 26454, "speaker": "Charles Miner"},
  {"season": 5, "episode": 18, "scene": 25, "line": "Party Planning Committee. Not your concern, Charles.", "order": 26455, "speaker": "Michael Scott"},
  {"season": 5, "episode": 18, "scene": 25, "line": "This doesn't seem like a good way to spend company time.", "order": 26456, "speaker": "Charles Miner"},
  {"season": 5, "episode": 18, "scene": 25, "line": "Really? Well then how would we come up with great ideas like Jim's? Go ahead.", "order": 26457, "speaker": "Michael Scott"},
  {"season": 5, "episode": 18, "scene": 25, "line": "No, I would like to get back to work, actually, please.", "order": 26458, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 18, "scene": 25, "line": "Whatcha got, Jim?... What is a two-way petting zoo?", "order": 26459, "speaker": "Charles Miner"},
  {"season": 5, "episode": 18, "scene": 25, "line": "You pet the animals and they pet you back.", "order": 26460, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 18, "scene": 25, "line": "It's a great idea. And we have a cake in the shape of a bale of hay.", "order": 26461, "speaker": "Michael Scott"},
  {"season": 5, "episode": 18, "scene": 25, "line": "It's really just a regular shaped cake.", "order": 26462, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 18, "scene": 25, "line": "But it's cool, and it's-", "order": 26463, "speaker": "Michael Scott"},
  {"season": 5, "episode": 18, "scene": 25, "line": "This isn't a good use of company time, Michael.", "order": 26464, "speaker": "Charles Miner"},
  {"season": 5, "episode": 18, "scene": 25, "line": "Whoa. You're talking about my anniversary party, so. . .", "order": 26465, "speaker": "Michael Scott"},
  {"season": 5, "episode": 18, "scene": 25, "line": "Everyone, except for Michael, go back to your desks.", "order": 26466, "speaker": "Charles Miner"},
  {"season": 5, "episode": 18, "scene": 25, "line": "Yeah, why don't you do that?", "order": 26467, "speaker": "Michael Scott"},
  {"season": 5, "episode": 18, "scene": 25, "line": "[Dwight, Jim and Pam leave] Thank you very much.", "order": 26468, "speaker": "Charles Miner"},
  {"season": 5, "episode": 18, "scene": 25, "line": "Yeah, here we go. Ok.", "order": 26469, "speaker": "Michael Scott"},
  {"season": 5, "episode": 18, "scene": 25, "line": "Ok, Michael, I'm dissolving this committee.", "order": 26470, "speaker": "Charles Miner"},
  {"season": 5, "episode": 18, "scene": 25, "line": "What?", "order": 26471, "speaker": "Michael Scott"},
  {"season": 5, "episode": 18, "scene": 25, "line": "Yeah, I mean, come on. This is a work place. It isn't designed for your vanity.", "order": 26472, "speaker": "Charles Miner"},
  {"season": 5, "episode": 18, "scene": 25, "line": "Newsflash: I've been here for fifteen years. Headline: You have been here one day. End of story. You have no right to cancel my fifteenth anniversary party. End of story. End of story. End of story!", "order": 26473, "speaker": "Michael Scott"},
  {"season": 5, "episode": 18, "scene": 25, "line": "I don't know if I have hurt your feelings or you do not disagree with my methods, but it is over.", "order": 26474, "speaker": "Charles Miner"},
  {"season": 5, "episode": 18, "scene": 25, "line": "Ok, no, no, no, no. No. You know what? I'm going to New York. And I am going to talk to David Wallace. [gets jacket] I'm going to tell him everything. And I'm sorry to say, you're screwed. And, you know what? You are gonna be through. And I'm sorry. I'm sorry to do that to you. Do you even know how paper is made? It's not like steel. You don't put it into a furnace. If you put paper into a furnace, you know what would happen? You'd ruin it. [pushes chair as he leaves]", "order": 26475, "speaker": "Michael Scott"},
  {"season": 5, "episode": 18, "scene": 26, "line": "[Jim knocks on door] Come in.", "order": 26476, "speaker": "Charles Miner"},
  {"season": 5, "episode": 18, "scene": 26, "line": "Hey.", "order": 26477, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 18, "scene": 26, "line": "Hey.", "order": 26478, "speaker": "Charles Miner"},
  {"season": 5, "episode": 18, "scene": 26, "line": "Alright, Charles, I've gotta be honest. I think we really got off on the wrong foot here. And, truth be told, I'm a pretty smart guy, and, uh, a hard-worker. And a great number two for the office.", "order": 26479, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 18, "scene": 26, "line": "Number two?", "order": 26480, "speaker": "Charles Miner"},
  {"season": 5, "episode": 18, "scene": 26, "line": "Second-in-command to Michael.", "order": 26481, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 18, "scene": 26, "line": "Oh, I didn't know that position existed.", "order": 26482, "speaker": "Charles Miner"},
  {"season": 5, "episode": 18, "scene": 26, "line": "Oh, that's because at first it was a made up position for Dwight, just to make him feel better, assistant to the regional manager. But then he lost that, and Michael gave it to me.", "order": 26483, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 18, "scene": 26, "line": "So you're the assistant to the regional manager?", "order": 26484, "speaker": "Charles Miner"},
  {"season": 5, "episode": 18, "scene": 26, "line": "Assistant regional manager, yeah.", "order": 26485, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 18, "scene": 26, "line": "Any responsibilities come with it?", "order": 26486, "speaker": "Charles Miner"},
  {"season": 5, "episode": 18, "scene": 26, "line": "No, not exactly.", "order": 26487, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 18, "scene": 26, "line": "So you keep this made up position? It's important to you?", "order": 26488, "speaker": "Charles Miner"},
  {"season": 5, "episode": 18, "scene": 26, "line": "Alright, I will, um, I'm just gonna let you get back to that.", "order": 26489, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 18, "scene": 26, "line": "Yeah, would you, uh?", "order": 26490, "speaker": "Charles Miner"},
  {"season": 5, "episode": 18, "scene": 26, "line": "Yep. [shuts door behind him]", "order": 26491, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 18, "scene": 27, "line": "Hello, Stephanie. Ok, where is he? [looks into empty office]", "order": 26492, "speaker": "Michael Scott"},
  {"season": 5, "episode": 18, "scene": 28, "line": "[coming out from bathroom] Michael.", "order": 26493, "speaker": "David Wallace"},
  {"season": 5, "episode": 18, "scene": 28, "line": "[points to bathroom door] So is this the meeting you've been in all day?", "order": 26494, "speaker": "Michael Scott"},
  {"season": 5, "episode": 18, "scene": 28, "line": "How did you get here, Michael?", "order": 26495, "speaker": "David Wallace"},
  {"season": 5, "episode": 18, "scene": 28, "line": "I drove.", "order": 26496, "speaker": "Michael Scott"},
  {"season": 5, "episode": 18, "scene": 28, "line": "Ok.", "order": 26497, "speaker": "David Wallace"},
  {"season": 5, "episode": 18, "scene": 29, "line": "Cancelled my fifteenth anniversary party. Just pulled the rug out from under me. And he said no figs. I've already bought em. And I don't have a place to store them. So, I feel like I've been sort of boned.", "order": 26498, "speaker": "Michael Scott"},
  {"season": 5, "episode": 18, "scene": 29, "line": "Michael. Listen-", "order": 26499, "speaker": "David Wallace"},
  {"season": 5, "episode": 18, "scene": 29, "line": "Do you talk to him about this? You've talked to him all day, obviously. Did you talk to him about this?", "order": 26500, "speaker": "Michael Scott"},
  {"season": 5, "episode": 18, "scene": 29, "line": "Well, I didn't know about the figs, specifically. . .", "order": 26501, "speaker": "David Wallace"},
  {"season": 5, "episode": 18, "scene": 29, "line": "Ok. Then I don't get it. It doesn't make any sense to me. Because I thought in the new system I was supposed to talk to Charles, and then Charles was supposed to talk to you, and that would dilute any need for me to ever talk to you again.", "order": 26502, "speaker": "Michael Scott"},
  {"season": 5, "episode": 18, "scene": 29, "line": "That's not exactly-", "order": 26503, "speaker": "David Wallace"},
  {"season": 5, "episode": 18, "scene": 29, "line": "Clearly that's what you wanted.", "order": 26504, "speaker": "Michael Scott"},
  {"season": 5, "episode": 18, "scene": 29, "line": "No.", "order": 26505, "speaker": "David Wallace"},
  {"season": 5, "episode": 18, "scene": 29, "line": "Fifteen years I have been here. And I have sacrificed a lot.", "order": 26506, "speaker": "Michael Scott"},
  {"season": 5, "episode": 18, "scene": 29, "line": "Yes.", "order": 26507, "speaker": "David Wallace"},
  {"season": 5, "episode": 18, "scene": 29, "line": "I've put having a family on hold.", "order": 26508, "speaker": "Michael Scott"},
  {"season": 5, "episode": 18, "scene": 29, "line": "We didn't ask you to do that.", "order": 26509, "speaker": "David Wallace"},
  {"season": 5, "episode": 18, "scene": 29, "line": "And I've never gone hang-gliding. And I've never driven my car to the top of Mount Washington.", "order": 26510, "speaker": "Michael Scott"},
  {"season": 5, "episode": 18, "scene": 30, "line": "Ok. Nice meeting everyone. I'll see you in a couple of weeks.", "order": 26511, "speaker": "Charles Miner"},
  {"season": 5, "episode": 18, "scene": 30, "line": "Cool.", "order": 26512, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 18, "scene": 30, "line": "Excellent.", "order": 26513, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 18, "scene": 30, "line": "It was very nice meeting you, Charles Miner.", "order": 26514, "speaker": "Angela Martin"},
  {"season": 5, "episode": 18, "scene": 30, "line": "See ya. See ya. [Charles leaves] Bye!", "order": 26515, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 18, "scene": 31, "line": "And for my next trick, I will make my career disappear.", "order": 26516, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 18, "scene": 32, "line": "I could see our kids facing obstacles being half-Black and half-Indian, but it's so worth it, you guys.", "order": 26517, "speaker": "Kelly Kapoor"},
  {"season": 5, "episode": 18, "scene": 32, "line": "It's just me here.", "order": 26518, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 18, "scene": 32, "line": "I am going to get him to buy me a prime rib tonight.", "order": 26519, "speaker": "Kelly Kapoor"},
  {"season": 5, "episode": 18, "scene": 32, "line": "I think he left.", "order": 26520, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 18, "scene": 32, "line": "What?! Move! [pushes cameraman out of the way]", "order": 26521, "speaker": "Kelly Kapoor"},
  {"season": 5, "episode": 18, "scene": 33, "line": "[Kelly runs to parking lot in the rain] Hello, Charles? Charles!", "order": 26522, "speaker": "Angela Martin"},
  {"season": 5, "episode": 18, "scene": 33, "line": "Hey! Is that his scarf?", "order": 26523, "speaker": "Kelly Kapoor"},
  {"season": 5, "episode": 18, "scene": 33, "line": "No! [Kelly chases Angela through parking lot]", "order": 26524, "speaker": "Angela Martin"},
  {"season": 5, "episode": 18, "scene": 34, "line": "[out of breath and wet] Charles and Kelly? Absolutely not. He is a sophisticated man. He does not need to go dumpster-diving for companionship, ok?", "order": 26525, "speaker": "Angela Martin"},
  {"season": 5, "episode": 18, "scene": 35, "line": "I don't understand that after fifteen years of service here, I have to get in the car and drive to New York in order to talk to you. That doesn't seem right to me. That doesn't seem fair. And I think that I've earned more than that.", "order": 26526, "speaker": "Michael Scott"},
  {"season": 5, "episode": 18, "scene": 35, "line": "Yeah. You're right. Yes. I get it. I get it, Michael. Here's what I'm going to do-", "order": 26527, "speaker": "David Wallace"},
  {"season": 5, "episode": 18, "scene": 35, "line": "Ok.", "order": 26528, "speaker": "Michael Scott"},
  {"season": 5, "episode": 18, "scene": 35, "line": "We will move some money around, and I will personally see to it that you have your party, you have your figs. I will be in attendance. It's gonna be great. How's that?", "order": 26529, "speaker": "David Wallace"},
  {"season": 5, "episode": 18, "scene": 35, "line": "[gets up to shake David's hand] I quit.", "order": 26530, "speaker": "Michael Scott"},
  {"season": 5, "episode": 18, "scene": 35, "line": "What?", "order": 26531, "speaker": "David Wallace"},
  {"season": 5, "episode": 18, "scene": 35, "line": "You have no idea how high I can fly.", "order": 26532, "speaker": "Michael Scott"},
  {"season": 5, "episode": 18, "scene": 36, "line": "I'm telling you, when corporate sends somebody, it is big trouble, or, really good news. And then sometimes it means business as usual.", "order": 26533, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 18, "scene": 36, "line": "Ok, what do you think?", "order": 26534, "speaker": "Meredith Palmer"},
  {"season": 5, "episode": 18, "scene": 36, "line": "Well, it's-", "order": 26535, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 18, "scene": 36, "line": "Why are you asking him? I just told you why he's here.", "order": 26536, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 18, "scene": 36, "line": "The real question here is who's this guy work for?", "order": 26537, "speaker": "Creed Bratton"},
  {"season": 5, "episode": 18, "scene": 36, "line": "Dunder Mifflin.", "order": 26538, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 18, "scene": 36, "line": "Ah, it's all starting to make sense now.", "order": 26539, "speaker": "Creed Bratton"},
  {"season": 5, "episode": 19, "scene": 1, "line": "I had no idea when I got in that car and headed to New York I was going to quit. I got on that ramp and I thought two hours, two hours to go. Feeling good. Listen to some tunes. Should've peed before I left.", "order": 26540, "speaker": "Michael Scott"},
  {"season": 5, "episode": 19, "scene": 1, "line": "Michael get to the good part.", "order": 26541, "speaker": "Kelly Kapoor"},
  {"season": 5, "episode": 19, "scene": 1, "line": "Okay, so. I get up to the building, I get to the revolving door... broken! So I have to take the normal door.", "order": 26542, "speaker": "Michael Scott"},
  {"season": 5, "episode": 19, "scene": 1, "line": "At least he is in the building.", "order": 26543, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 19, "scene": 1, "line": "No, No! I was so nervous it was the wrong building! I walked into the wrong building!", "order": 26544, "speaker": "Michael Scott"},
  {"season": 5, "episode": 19, "scene": 1, "line": "[groans]", "order": 26545, "speaker": "Everyone"},
  {"season": 5, "episode": 19, "scene": 2, "line": "He finally has a story everyone wants to hear... and he knows it.", "order": 26546, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 19, "scene": 3, "line": "Okay focus. Focus. You're in the right building, you're with the right people; what happened?", "order": 26547, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 19, "scene": 3, "line": "I looked at Wallace and I said 'I quit!' and as I turned to leave I looked back and I said 'You have no idea how high I can fly.'", "order": 26548, "speaker": "Michael Scott"},
  {"season": 5, "episode": 19, "scene": 3, "line": "Did you tell him how sick of him you were?", "order": 26549, "speaker": "Stanley Hudson"},
  {"season": 5, "episode": 19, "scene": 3, "line": "Why would I do that?", "order": 26550, "speaker": "Michael Scott"},
  {"season": 5, "episode": 19, "scene": 3, "line": "Well, wouldn't it feel good to tell him that he was incompetent?", "order": 26551, "speaker": "Angela Martin"},
  {"season": 5, "episode": 19, "scene": 3, "line": "That he's wasted 15 years of your life?", "order": 26552, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 19, "scene": 3, "line": "Did you spit in his face?", "order": 26553, "speaker": "Meredith Palmer"},
  {"season": 5, "episode": 19, "scene": 3, "line": "You guys have thought about this a lot more than I have. I just winged it.", "order": 26554, "speaker": "Michael Scott"},
  {"season": 5, "episode": 19, "scene": 4, "line": "I love a good quitting story. It makes me feel like I have control over my own life. Gives me hope. Maybe I will have one of own someday. [laughs] But I dream... so...", "order": 26555, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 19, "scene": 5, "line": "[Michael has a 'sticky-hand' toy and snags a paper off Jim's desk and then laughs] About a week ago, Michael gave his 2-week notice. And, surprisingly there is a very big difference between Michael trying and Michael not trying.", "order": 26556, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 19, "scene": 6, "line": "Michael is that scotch?", "order": 26557, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 19, "scene": 6, "line": "Scotch with Splenda. Tastes like Splenda, gets you drunk like scotch. [in Oscar's ear] Clinky, clinky-clink. Come-on... come on, come on.", "order": 26558, "speaker": "Michael Scott"},
  {"season": 5, "episode": 19, "scene": 7, "line": "[slightly drunk] What am I gonna do? I'll tell ya what I'm gonna do. I gonna do a little bit of this, a little bit of that. I gonna stay up all day. Gonna sleep it up all night. I'm gonna give it a OHH! HEY! HO! And I'm going to stop worrying about calories.", "order": 26559, "speaker": "Michael Scott"},
  {"season": 5, "episode": 19, "scene": 8, "line": "Maybe you should go into your office, close the door, and make some calls about jobs?", "order": 26560, "speaker": "Stanley Hudson"},
  {"season": 5, "episode": 19, "scene": 8, "line": "I have a job.", "order": 26561, "speaker": "Michael Scott"},
  {"season": 5, "episode": 19, "scene": 8, "line": "For four more days.", "order": 26562, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 19, "scene": 8, "line": "Do you have any leads on a job?", "order": 26563, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 19, "scene": 8, "line": "Pam, what you don't understand is that at my level you just don't look in the want-ads for a job. You are head-hunted.", "order": 26564, "speaker": "Michael Scott"},
  {"season": 5, "episode": 19, "scene": 8, "line": "You called any headhunters?", "order": 26565, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 19, "scene": 8, "line": "Any good headhunter knows I am available.", "order": 26566, "speaker": "Michael Scott"},
  {"season": 5, "episode": 19, "scene": 8, "line": "Any really good headhunter would storm your village at sunset with overwhelming force and cut off your head with a ceremonial knife,", "order": 26567, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 19, "scene": 8, "line": "Right, cause that's what we are talking about.", "order": 26568, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 19, "scene": 8, "line": "Uh, I need you to go over this client list and indicate any wrong or false data.", "order": 26569, "speaker": "Charles Miner"},
  {"season": 5, "episode": 19, "scene": 8, "line": "You're 'I need you to' is my command.", "order": 26570, "speaker": "Michael Scott"},
  {"season": 5, "episode": 19, "scene": 8, "line": "Okay.", "order": 26571, "speaker": "Charles Miner"},
  {"season": 5, "episode": 19, "scene": 9, "line": "Hey Michael...", "order": 26572, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 19, "scene": 9, "line": "Hey.", "order": 26573, "speaker": "Michael Scott"},
  {"season": 5, "episode": 19, "scene": 9, "line": "Can I talk to you a minute?", "order": 26574, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 19, "scene": 9, "line": "You can talk to me for as long as you want. I have all the time in the world. [eats some spaghetti] Oh God! Blech! Phyllis!", "order": 26575, "speaker": "Michael Scott"},
  {"season": 5, "episode": 19, "scene": 9, "line": "I just wanted to tell you that... Oh What? [takes a present out from behind his back] What's that?", "order": 26576, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 19, "scene": 9, "line": "Oh! Hey. What is this about?", "order": 26577, "speaker": "Michael Scott"},
  {"season": 5, "episode": 19, "scene": 9, "line": "Um, you know, cause your leaving and so, it's a farewell...", "order": 26578, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 19, "scene": 9, "line": "Oh.", "order": 26579, "speaker": "Michael Scott"},
  {"season": 5, "episode": 19, "scene": 9, "line": "I hope I get to work with you someday again.", "order": 26580, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 19, "scene": 9, "line": "Me too, me too. Is this wine?", "order": 26581, "speaker": "Michael Scott"},
  {"season": 5, "episode": 19, "scene": 9, "line": "Ah... busted. Yes", "order": 26582, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 19, "scene": 9, "line": "I already have wine.", "order": 26583, "speaker": "Michael Scott"},
  {"season": 5, "episode": 19, "scene": 9, "line": "Oh.", "order": 26584, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 19, "scene": 10, "line": "[interviewee enters office] Hello.", "order": 26585, "speaker": "Isaac"},
  {"season": 5, "episode": 19, "scene": 10, "line": "Hi.", "order": 26586, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 19, "scene": 10, "line": "Isaac Silby here for the interview", "order": 26587, "speaker": "Isaac"},
  {"season": 5, "episode": 19, "scene": 10, "line": "Uh, yes. Please have a seat. It'll be a few minutes.", "order": 26588, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 19, "scene": 10, "line": "Thank you. [to Michael] You interviewing too?", "order": 26589, "speaker": "Isaac"},
  {"season": 5, "episode": 19, "scene": 10, "line": "Hmm?", "order": 26590, "speaker": "Michael Scott"},
  {"season": 5, "episode": 19, "scene": 10, "line": "Interviewing?", "order": 26591, "speaker": "Isaac"},
  {"season": 5, "episode": 19, "scene": 10, "line": "For?", "order": 26592, "speaker": "Michael Scott"},
  {"season": 5, "episode": 19, "scene": 10, "line": "Regional manager.", "order": 26593, "speaker": "Isaac"},
  {"season": 5, "episode": 19, "scene": 10, "line": "Yes I am.", "order": 26594, "speaker": "Michael Scott"},
  {"season": 5, "episode": 19, "scene": 11, "line": "For regional manager I've decided to go with an outside hire. For obvious reasons.", "order": 26595, "speaker": "Charles Miner"},
  {"season": 5, "episode": 19, "scene": 12, "line": "Where you from?", "order": 26596, "speaker": "Michael Scott"},
  {"season": 5, "episode": 19, "scene": 12, "line": "Philly.", "order": 26597, "speaker": "Isaac"},
  {"season": 5, "episode": 19, "scene": 12, "line": "That's a drive!", "order": 26598, "speaker": "Michael Scott"},
  {"season": 5, "episode": 19, "scene": 12, "line": "Yeah, well this is one of the few places that's hiring. It's uh, its brutal out there.", "order": 26599, "speaker": "Isaac"},
  {"season": 5, "episode": 19, "scene": 12, "line": "[sighs]", "order": 26600, "speaker": "Michael Scott"},
  {"season": 5, "episode": 19, "scene": 13, "line": "Pam?", "order": 26601, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 19, "scene": 13, "line": "Hmm?", "order": 26602, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 19, "scene": 13, "line": "When will the new copier be ready?", "order": 26603, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 19, "scene": 13, "line": "I'm working on it Kev.", "order": 26604, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 19, "scene": 13, "line": "You said it would be ready by today. And it is today.", "order": 26605, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 19, "scene": 13, "line": "It'll be ready soon.", "order": 26606, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 19, "scene": 13, "line": "Soon could mean anything. Soon could be 3 weeks.", "order": 26607, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 19, "scene": 13, "line": "Is that what 'soon' means to you?", "order": 26608, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 19, "scene": 13, "line": "Sometimes.", "order": 26609, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 19, "scene": 13, "line": "Then come back soon.", "order": 26610, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 19, "scene": 14, "line": "During the course of business, a copier goes though something called 'Normal wear and tear.'", "order": 26611, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 19, "scene": 15, "line": "[feeding coins into a vent] I think it's 75 cents.", "order": 26612, "speaker": "Creed Bratton"},
  {"season": 5, "episode": 19, "scene": 15, "line": "That's a lot.", "order": 26613, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 19, "scene": 16, "line": "[Bandit is chewing on wires] Bandit, No! No no no!", "order": 26614, "speaker": "Angela Martin"},
  {"season": 5, "episode": 19, "scene": 17, "line": "[dumps coffee all over the copier glass] Oh!", "order": 26615, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 19, "scene": 18, "line": "Yesterday, they delivered the new one. But they didn't set it up. So my day just got a little more interesting. [holds up a huge manual]", "order": 26616, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 19, "scene": 19, "line": "[overhears loud monster noises] Its Monster dot com. Singular.", "order": 26617, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 19, "scene": 19, "line": "Thank you. [groaning ceases]", "order": 26618, "speaker": "Michael Scott"},
  {"season": 5, "episode": 19, "scene": 20, "line": "You work in paper long enough, you get to know the players. [dials phone]", "order": 26619, "speaker": "Michael Scott"},
  {"season": 5, "episode": 19, "scene": 20, "line": "Hi, you've reached Prince Paper. We are sad to inform you that after 40 years of serving the community we are no longer in business. Thank you for your support. May God bless you. [girl's voice] Bye!", "order": 26620, "speaker": "Phone"},
  {"season": 5, "episode": 19, "scene": 20, "line": "[sighs] What am I gong to do? Uh... [chuckles].", "order": 26621, "speaker": "Michael Scott"},
  {"season": 5, "episode": 19, "scene": 21, "line": "PSST! PSST!", "order": 26622, "speaker": "Michael Scott"},
  {"season": 5, "episode": 19, "scene": 21, "line": "Yeah... .you want me to come in your office? Oh.", "order": 26623, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 19, "scene": 22, "line": "Okay, oh okay, okay. Close your eyes.", "order": 26624, "speaker": "Michael Scott"},
  {"season": 5, "episode": 19, "scene": 22, "line": "I would prefer not to.", "order": 26625, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 19, "scene": 22, "line": "Just close your eyes. I'm going to start my own paper company.", "order": 26626, "speaker": "Michael Scott"},
  {"season": 5, "episode": 19, "scene": 22, "line": "You're starting your own paper company?", "order": 26627, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 19, "scene": 22, "line": "yeah!", "order": 26628, "speaker": "Michael Scott"},
  {"season": 5, "episode": 19, "scene": 22, "line": "Why?", "order": 26629, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 19, "scene": 22, "line": "Can you believe... cause I know paper. I know everything there is to know about paper.", "order": 26630, "speaker": "Michael Scott"},
  {"season": 5, "episode": 19, "scene": 22, "line": "Do you know the industry is in decline?", "order": 26631, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 19, "scene": 22, "line": "Yeah! Oh God. I practically invented decline.", "order": 26632, "speaker": "Michael Scott"},
  {"season": 5, "episode": 19, "scene": 22, "line": "Right.", "order": 26633, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 19, "scene": 22, "line": "Right, I know paper, I know how to manage. I have a name, close your eyes.", "order": 26634, "speaker": "Michael Scott"},
  {"season": 5, "episode": 19, "scene": 22, "line": "No, I did that before, it added nothing.", "order": 26635, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 19, "scene": 22, "line": "Close them.", "order": 26636, "speaker": "Michael Scott"},
  {"season": 5, "episode": 19, "scene": 22, "line": "Okay. [keeps eyes open]", "order": 26637, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 19, "scene": 22, "line": "Alright, Michael Scott Paper Company. You want in? Do you want to be a part of this?", "order": 26638, "speaker": "Michael Scott"},
  {"season": 5, "episode": 19, "scene": 22, "line": "I am not gonna do this.", "order": 26639, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 19, "scene": 22, "line": "Obviously.", "order": 26640, "speaker": "Michael Scott"},
  {"season": 5, "episode": 19, "scene": 22, "line": "And you are not going to, either.", "order": 26641, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 19, "scene": 22, "line": "Oh, agreed, mmm, except...", "order": 26642, "speaker": "Michael Scott"},
  {"season": 5, "episode": 19, "scene": 22, "line": "Here's the thing. What I wish for you is that you land a job at a company that A, exists, and B, has a salary. Because they're set up to do that kind of thing.", "order": 26643, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 19, "scene": 22, "line": "Hey hey... what's up Chuck?", "order": 26644, "speaker": "Michael Scott"},
  {"season": 5, "episode": 19, "scene": 22, "line": "Jim.", "order": 26645, "speaker": "Charles Miner"},
  {"season": 5, "episode": 19, "scene": 22, "line": "Hey.", "order": 26646, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 19, "scene": 22, "line": "what are you doing?", "order": 26647, "speaker": "Charles Miner"},
  {"season": 5, "episode": 19, "scene": 22, "line": "Nothing, just talking.", "order": 26648, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 19, "scene": 22, "line": "Okay, Michael handed in his 2-week notice, did you also hand in your 2-week?", "order": 26649, "speaker": "Charles Miner"},
  {"season": 5, "episode": 19, "scene": 22, "line": "I didn't... no.", "order": 26650, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 19, "scene": 22, "line": "Okay. After you.", "order": 26651, "speaker": "Charles Miner"},
  {"season": 5, "episode": 19, "scene": 22, "line": "No I'm staying.", "order": 26652, "speaker": "Michael Scott"},
  {"season": 5, "episode": 19, "scene": 22, "line": "Okay.", "order": 26653, "speaker": "Charles Miner"},
  {"season": 5, "episode": 19, "scene": 23, "line": "[examining the copier manual] Do you want me to translate the German instructions for you?", "order": 26654, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 19, "scene": 23, "line": "No, I'm sure they're pretty much the same as the English instructions.", "order": 26655, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 19, "scene": 23, "line": "Typical American ignorance that got us involved in a war we never should have been in. World War II.", "order": 26656, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 19, "scene": 23, "line": "That's a really... well fine, Do the German instructions say what this is supposed to do?", "order": 26657, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 19, "scene": 23, "line": "Deutsch... let me see here. That is either an incense dispenser, or a ceremonial sarcophagus.", "order": 26658, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 19, "scene": 23, "line": "Hmm...", "order": 26659, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 19, "scene": 24, "line": "My German is pre-industrial and mostly religious.", "order": 26660, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 19, "scene": 25, "line": "Pam, listen. This order form. Instead of saying 'Dunder Mifflin' at the top is there any way I could get it to say something else?", "order": 26661, "speaker": "Michael Scott"},
  {"season": 5, "episode": 19, "scene": 25, "line": "Like Michael Scott Paper Company?", "order": 26662, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 19, "scene": 25, "line": "You... oh. Somebody has been talking in bed. Pillow talk.", "order": 26663, "speaker": "Michael Scott"},
  {"season": 5, "episode": 19, "scene": 25, "line": "Hmm... yeah. Listen Michael. Have you really thought this through? 'Cause it's a pretty big risk.", "order": 26664, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 19, "scene": 25, "line": "This is a dream that I have had since lunch, and I am not giving up on it now.", "order": 26665, "speaker": "Michael Scott"},
  {"season": 5, "episode": 19, "scene": 25, "line": "Yeah... you could give it up though right? And almost nobody would know.", "order": 26666, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 19, "scene": 25, "line": "Before you got here, I'm the one who landed all these clients. Half of them, at least. I can do it again. I know the market, I know the price points. I'm on it, don't worry. So, how do I do that?", "order": 26667, "speaker": "Michael Scott"},
  {"season": 5, "episode": 19, "scene": 25, "line": "Well, um, you can scan it, and then you can upload the image, and then you can copy the new image.", "order": 26668, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 19, "scene": 25, "line": "Can't I take some paper and just tape over it with transparent tape?", "order": 26669, "speaker": "Michael Scott"},
  {"season": 5, "episode": 19, "scene": 25, "line": "Yeah. Good.", "order": 26670, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 19, "scene": 25, "line": "Thank you very much.", "order": 26671, "speaker": "Michael Scott"},
  {"season": 5, "episode": 19, "scene": 25, "line": "Mmm-hmm", "order": 26672, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 19, "scene": 26, "line": "So that is why I have to leave at 5: 00 on Tuesday, it's to pick up my little sisters from school. We're really tight. We're like the Kardashians..", "order": 26673, "speaker": "Kelly Kapoor"},
  {"season": 5, "episode": 19, "scene": 26, "line": "You know, you can run this stuff by Toby.", "order": 26674, "speaker": "Charles Miner"},
  {"season": 5, "episode": 19, "scene": 26, "line": "Yeah I don't like talking... [knock on door]", "order": 26675, "speaker": "Kelly Kapoor"},
  {"season": 5, "episode": 19, "scene": 26, "line": "Come in...", "order": 26676, "speaker": "Charles Miner"},
  {"season": 5, "episode": 19, "scene": 26, "line": "I'm sorry, I didn't realize you were with anyone. I just wanted to bring you the brochure on that accounting seminar that I was telling you about. Earlier.", "order": 26677, "speaker": "Angela Martin"},
  {"season": 5, "episode": 19, "scene": 26, "line": "Yep, we were in the middle of something so why don't you just...", "order": 26678, "speaker": "Kelly Kapoor"},
  {"season": 5, "episode": 19, "scene": 26, "line": "Okay, um, actually you know it was so much fun last year. There was a Sunday-bar. I mean I didn't have any, you know, to stay trim.", "order": 26679, "speaker": "Angela Martin"},
  {"season": 5, "episode": 19, "scene": 26, "line": "Did Michael just let anybody in his office?", "order": 26680, "speaker": "Charles Miner"},
  {"season": 5, "episode": 19, "scene": 26, "line": "Yep! Yeah. He just loved having people, communicating all the time. He was like 'Come on in!'", "order": 26681, "speaker": "Angela Martin & Kelly Kapoor"},
  {"season": 5, "episode": 19, "scene": 27, "line": "I am aware of the effect I have on woman.", "order": 26682, "speaker": "Charles Miner"},
  {"season": 5, "episode": 19, "scene": 28, "line": "So you should be heading back to your desk.", "order": 26683, "speaker": "Kelly Kapoor"},
  {"season": 5, "episode": 19, "scene": 28, "line": "Okay. Bye Charles. Well you'll let me know. Good bye.", "order": 26684, "speaker": "Angela Martin"},
  {"season": 5, "episode": 19, "scene": 28, "line": "She's such a special person. And she's turning 50 this year.", "order": 26685, "speaker": "Kelly Kapoor"},
  {"season": 5, "episode": 19, "scene": 29, "line": "I'm starting my own paper company.", "order": 26686, "speaker": "Michael Scott"},
  {"season": 5, "episode": 19, "scene": 29, "line": "No way!?", "order": 26687, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 19, "scene": 29, "line": "Yeah.", "order": 26688, "speaker": "Michael Scott"},
  {"season": 5, "episode": 19, "scene": 29, "line": "In this climate?", "order": 26689, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 19, "scene": 29, "line": "Yeah. In all climates. It's going to be worldwide. And I'm looking for some talented salesmen to join me. That's where you come in.", "order": 26690, "speaker": "Michael Scott"},
  {"season": 5, "episode": 19, "scene": 29, "line": "Ehh... [in accent] well it's a very intriguing concept, isn't it? Um... hmmm..[makes weird noises to stall, Dwight enters] Michael is starting his own paper company. What do you think about that?", "order": 26691, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 19, "scene": 29, "line": "Your own paper company.", "order": 26692, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 19, "scene": 29, "line": "Can you believe it? Well, we'll see, we'll see. It's just a, just a nugget of an idea right now so", "order": 26693, "speaker": "Michael Scott"},
  {"season": 5, "episode": 19, "scene": 29, "line": "Right...", "order": 26694, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 19, "scene": 29, "line": "Potential, lots of potential. yes.", "order": 26695, "speaker": "Michael Scott"},
  {"season": 5, "episode": 19, "scene": 29, "line": "What a courageous venture.", "order": 26696, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 19, "scene": 29, "line": "It's... it's very courageous, very exciting. Um...", "order": 26697, "speaker": "Michael Scott"},
  {"season": 5, "episode": 19, "scene": 29, "line": "Location is hard for me, with the farm and the responsibilities...", "order": 26698, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 19, "scene": 29, "line": "That's what I was thinking, with the farm, so... You getting to wherever I'm gonna put my thing.", "order": 26699, "speaker": "Michael Scott"},
  {"season": 5, "episode": 19, "scene": 29, "line": "Okay. So yeah.", "order": 26700, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 19, "scene": 29, "line": "So think about it. Lets put a pin in it for now.", "order": 26701, "speaker": "Michael Scott"},
  {"season": 5, "episode": 19, "scene": 29, "line": "You know, I would love to put a pin in that.", "order": 26702, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 19, "scene": 30, "line": "Everyone, can I have your attention. This is the moment you've all been waiting for. Right? [drum roll on copier]", "order": 26703, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 19, "scene": 30, "line": "Little Ms. Thing wants attention.", "order": 26704, "speaker": "Meredith Palmer"},
  {"season": 5, "episode": 19, "scene": 30, "line": "Meredith... [copier has an error] Oh... so uh... G-44. It's not ready Kevin!", "order": 26705, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 19, "scene": 31, "line": "I'm at a crucial point where I have sunk 4 hours into that copier, and I am not going to let it beat me like that wireless router did.", "order": 26706, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 19, "scene": 32, "line": "[bites his sandwich and notices there is a note in it]", "order": 26707, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 19, "scene": 33, "line": "You put a note in my food?", "order": 26708, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 19, "scene": 33, "line": "I made it sterile.", "order": 26709, "speaker": "Michael Scott"},
  {"season": 5, "episode": 19, "scene": 33, "line": "Just to say 'sterile' doesn't make it so.", "order": 26710, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 19, "scene": 33, "line": "I am offering you the opportunity of a lifetime, Oscar. To come work for me.", "order": 26711, "speaker": "Michael Scott"},
  {"season": 5, "episode": 19, "scene": 33, "line": "Do you have a business plan? A funding request? Market research, financials?", "order": 26712, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 19, "scene": 33, "line": "No, no no.", "order": 26713, "speaker": "Michael Scott"},
  {"season": 5, "episode": 19, "scene": 33, "line": "You need those things. Most new businesses, they don't make a profit till at least two years. And then your margins will be razor thin. Best case scenario, you don't cut yourself a salary for at least 5 years. Can you go 5 years without a salary, Michael?", "order": 26714, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 19, "scene": 33, "line": "Okay.", "order": 26715, "speaker": "Michael Scott"},
  {"season": 5, "episode": 19, "scene": 33, "line": "Five years?", "order": 26716, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 19, "scene": 33, "line": "Okay, hey, you already have the job. You don't have to convince me.", "order": 26717, "speaker": "Michael Scott"},
  {"season": 5, "episode": 19, "scene": 33, "line": "It's just not prudent Michael.", "order": 26718, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 19, "scene": 34, "line": "[from inside the restroom] Stanley?", "order": 26719, "speaker": "Michael Scott"},
  {"season": 5, "episode": 19, "scene": 34, "line": "Can't you see I'm urinating?", "order": 26720, "speaker": "Stanley Hudson"},
  {"season": 5, "episode": 19, "scene": 34, "line": "Listen, Listen, Stanley. You don't have to answer me now.", "order": 26721, "speaker": "Michael Scott"},
  {"season": 5, "episode": 19, "scene": 34, "line": "No.", "order": 26722, "speaker": "Stanley Hudson"},
  {"season": 5, "episode": 19, "scene": 34, "line": "Just... I want you to think about it, I'm starting my own company.", "order": 26723, "speaker": "Michael Scott"},
  {"season": 5, "episode": 19, "scene": 34, "line": "No.", "order": 26724, "speaker": "Stanley Hudson"},
  {"season": 5, "episode": 19, "scene": 34, "line": "Oh-okay, you're not letting me finish, and you just lost out on a million dollars.", "order": 26725, "speaker": "Michael Scott"},
  {"season": 5, "episode": 19, "scene": 34, "line": "No I didn't.", "order": 26726, "speaker": "Stanley Hudson"},
  {"season": 5, "episode": 19, "scene": 35, "line": "You know what. I had a great time at prom. And no one said 'Yes' to that either.", "order": 26727, "speaker": "Michael Scott"},
  {"season": 5, "episode": 19, "scene": 36, "line": "How's it working?", "order": 26728, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 19, "scene": 36, "line": "Um, let's see... it's fine.", "order": 26729, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 19, "scene": 36, "line": "Good.", "order": 26730, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 19, "scene": 37, "line": "I did it. I learned everything about this machine. I know all the buttons, even the inside ones. I know all the error messages. I could do a bound book, in plastic with offset colors. Which feels...", "order": 26731, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 19, "scene": 38, "line": "Hey Hank, You ready?", "order": 26732, "speaker": "Charles Miner"},
  {"season": 5, "episode": 19, "scene": 38, "line": "Yeah.", "order": 26733, "speaker": "Hank Tate"},
  {"season": 5, "episode": 19, "scene": 38, "line": "Okay, Michael?", "order": 26734, "speaker": "Charles Miner"},
  {"season": 5, "episode": 19, "scene": 38, "line": "Hmm?", "order": 26735, "speaker": "Michael Scott"},
  {"season": 5, "episode": 19, "scene": 38, "line": "I can't pretend I haven't seen that. So I am going e to ask you to stand up, walk out. And you can't take anything.", "order": 26736, "speaker": "Charles Miner"},
  {"season": 5, "episode": 19, "scene": 38, "line": "Okay, I have immunity. It's my two-weeks...", "order": 26737, "speaker": "Michael Scott"},
  {"season": 5, "episode": 19, "scene": 38, "line": "not if you're starting your own paper company, Michael. Hank...", "order": 26738, "speaker": "Charles Miner"},
  {"season": 5, "episode": 19, "scene": 38, "line": "Okay Michael.", "order": 26739, "speaker": "Hank Tate"},
  {"season": 5, "episode": 19, "scene": 38, "line": "Hank? You really think Hank is going to be loyal to you? Hank, please escort Charles from the building.", "order": 26740, "speaker": "Michael Scott"},
  {"season": 5, "episode": 19, "scene": 38, "line": "Come on man, let's, let's go.", "order": 26741, "speaker": "Hank Tate"},
  {"season": 5, "episode": 19, "scene": 39, "line": "I always thought Michael got a bad rap. He's a good guy. And he's super funny. Yeah, maybe I should tell him before he goes. He's all the way over there.", "order": 26742, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 19, "scene": 40, "line": "[to everyone] Well here we are... I would just like to...", "order": 26743, "speaker": "Michael Scott"},
  {"season": 5, "episode": 19, "scene": 40, "line": "No, no no no. You're done, Michael.", "order": 26744, "speaker": "Charles Miner"},
  {"season": 5, "episode": 19, "scene": 41, "line": "[yelling from Parking lot] Alright then everybody, I'm outta here!", "order": 26745, "speaker": "Michael Scott"},
  {"season": 5, "episode": 19, "scene": 41, "line": "[from behind the upstairs window] What is he doing? It looks like he's saying something.", "order": 26746, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 19, "scene": 41, "line": "I think he's singing.", "order": 26747, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 19, "scene": 41, "line": "I can't believe this is really happening.", "order": 26748, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 19, "scene": 41, "line": "... and I feel free!", "order": 26749, "speaker": "Michael Scott"},
  {"season": 5, "episode": 19, "scene": 42, "line": "And just like that. As mysteriously as he arrived, he was gone. [Michael sneaks back in the parking lot, and into a back door of the building]", "order": 26750, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 19, "scene": 43, "line": "The whole office feels darker, you know? It's just a sad dark day.", "order": 26751, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 19, "scene": 43, "line": "Andy He's gone.", "order": 26752, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 19, "scene": 43, "line": "I know.", "order": 26753, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 19, "scene": 43, "line": "You don't have to kiss his ass anymore.", "order": 26754, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 19, "scene": 44, "line": "Michael is like a movie on a plane. You know, it's not great, but it's something to watch. And when it's over you're like... how much time is left on this flight? Now what?", "order": 26755, "speaker": "Toby Flenderson"},
  {"season": 5, "episode": 19, "scene": 45, "line": "[Michael is Army crawling around the office] Michael?", "order": 26756, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 19, "scene": 45, "line": "Shh shh shh... don't look down, look straight up. Come on please!", "order": 26757, "speaker": "Michael Scott"},
  {"season": 5, "episode": 19, "scene": 45, "line": "These are for employees only.", "order": 26758, "speaker": "Angela Martin"},
  {"season": 5, "episode": 19, "scene": 45, "line": "Angela Kevin, you have to help me out I just need a few things then I will be gone.", "order": 26759, "speaker": "Michael Scott"},
  {"season": 5, "episode": 19, "scene": 45, "line": "Michael, why is it that you asked everyone except me, Angela, Creed, and Meredith?", "order": 26760, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 19, "scene": 45, "line": "I was going to, I wanted to. But I had to start somewhere.", "order": 26761, "speaker": "Michael Scott"},
  {"season": 5, "episode": 19, "scene": 45, "line": "But you didn't want to start with us?", "order": 26762, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 19, "scene": 45, "line": "No, of course not. But now I want everybody. Jim! Jim? Buddy, Hey! Buddy, Jimbo? Did you have time to think about my offer?", "order": 26763, "speaker": "Michael Scott"},
  {"season": 5, "episode": 19, "scene": 45, "line": "I did have a chance to think about it, but then I thought about something else.", "order": 26764, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 19, "scene": 45, "line": "So which way are you leaning?", "order": 26765, "speaker": "Michael Scott"},
  {"season": 5, "episode": 19, "scene": 45, "line": "Well it is tempting but I am 100% leaning towards something else.", "order": 26766, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 19, "scene": 45, "line": "Thanks Jim.", "order": 26767, "speaker": "Michael Scott"},
  {"season": 5, "episode": 19, "scene": 45, "line": "Hey, did anyone see the client list that Michael was supposed to be working on.", "order": 26768, "speaker": "Charles Miner"},
  {"season": 5, "episode": 19, "scene": 45, "line": "No.", "order": 26769, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 19, "scene": 45, "line": "Okay, let me know if you find it.", "order": 26770, "speaker": "Charles Miner"},
  {"season": 5, "episode": 19, "scene": 45, "line": "Okay, Okay, we don't have much time. Just act normal. Don't look at me people. Stop. Don't look down. Don't look down. He's going to see me. Don't look all the way up. Keep it at a normal height. Okay. This is not Michael Scott talking right now, this is your future. Hello, I am your future. You're older, and you are very happy now. Because you went with Michael Scott. Right. So everybody come on down. Lets just crawl out of here together. Alright? Come on! Are you, are you doing your best here? Are you being the best that you can be? [Pam is thinking hard] Alright. Everybody who's going to go with me I want you to stomp your foot. Okay, alright. Come on. You're coming with me. Come on Phyllis. Here we go, here we go!", "order": 26771, "speaker": "Michael Scott"},
  {"season": 5, "episode": 19, "scene": 45, "line": "Michael get up!", "order": 26772, "speaker": "Charles Miner"},
  {"season": 5, "episode": 19, "scene": 45, "line": "Oh God... okay alright. It's time to go it's time to go. Jim, let's go. Come on!", "order": 26773, "speaker": "Michael Scott"},
  {"season": 5, "episode": 19, "scene": 45, "line": "Michael, what are you doing?", "order": 26774, "speaker": "Charles Miner"},
  {"season": 5, "episode": 19, "scene": 45, "line": "I think maybe Hank should be here.", "order": 26775, "speaker": "Michael Scott"},
  {"season": 5, "episode": 19, "scene": 45, "line": "Hank? No, I don't need Hank Michael.", "order": 26776, "speaker": "Charles Miner"},
  {"season": 5, "episode": 19, "scene": 45, "line": "You're going to mess with me, is that what you're going to do? I'll tell you something Charles, I don't even care. Cause I've got nothing got lose. [Charles steps towards Michael] Oh my God, OH GOD! No no, all right. Fine, it's not even worth it.", "order": 26777, "speaker": "Michael Scott"},
  {"season": 5, "episode": 19, "scene": 45, "line": "Oh no.", "order": 26778, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 19, "scene": 45, "line": "What?", "order": 26779, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 19, "scene": 45, "line": "I'm going with him.", "order": 26780, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 19, "scene": 45, "line": "What? Pam!", "order": 26781, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 19, "scene": 45, "line": "I'm going.", "order": 26782, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 19, "scene": 45, "line": "Pam! You can't be serious.", "order": 26783, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 19, "scene": 45, "line": "Michael, wait! I'm coming with you.", "order": 26784, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 19, "scene": 45, "line": "You are?", "order": 26785, "speaker": "Michael Scott"},
  {"season": 5, "episode": 19, "scene": 45, "line": "Yeah.", "order": 26786, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 19, "scene": 45, "line": "Okay. It's going to be great.", "order": 26787, "speaker": "Michael Scott"},
  {"season": 5, "episode": 19, "scene": 45, "line": "Great. Uh, except, I don't want to be a receptionist anymore.", "order": 26788, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 19, "scene": 45, "line": "Right... Executive assistant.", "order": 26789, "speaker": "Michael Scott"},
  {"season": 5, "episode": 19, "scene": 45, "line": "Salesman.", "order": 26790, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 19, "scene": 45, "line": "Alright, okay. Deal! Okay, well... Jim?", "order": 26791, "speaker": "Michael Scott"},
  {"season": 5, "episode": 19, "scene": 45, "line": "Still no.", "order": 26792, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 19, "scene": 45, "line": "Well, okay, lets go.", "order": 26793, "speaker": "Michael Scott"},
  {"season": 5, "episode": 19, "scene": 45, "line": "Oh, all my stuff is still upstairs so...", "order": 26794, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 19, "scene": 45, "line": "Are people watching?", "order": 26795, "speaker": "Michael Scott"},
  {"season": 5, "episode": 19, "scene": 45, "line": "Probably.", "order": 26796, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 19, "scene": 45, "line": "Michael, its not how you leave in an office. It how you...", "order": 26797, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 19, "scene": 45, "line": "Jim Jim Jim... we're having a company meeting here.", "order": 26798, "speaker": "Michael Scott"},
  {"season": 5, "episode": 19, "scene": 45, "line": "I'll bring your stuff home. Okay, bye.", "order": 26799, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 19, "scene": 45, "line": "Okay, see you later.", "order": 26800, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 19, "scene": 45, "line": "Bye.", "order": 26801, "speaker": "Michael Scott"},
  {"season": 5, "episode": 19, "scene": 46, "line": "So, we had a personnel change today. Shouldn't effect our day to day. But, until we get a new receptionist I want Kevin on the phones.", "order": 26802, "speaker": "Charles Miner"},
  {"season": 5, "episode": 19, "scene": 46, "line": "Phones?", "order": 26803, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 19, "scene": 46, "line": "Also, there has been too much wasted time. So Stanley.", "order": 26804, "speaker": "Charles Miner"},
  {"season": 5, "episode": 19, "scene": 46, "line": "[holding a crossword puzzle] Yes.", "order": 26805, "speaker": "Stanley Hudson"},
  {"season": 5, "episode": 19, "scene": 46, "line": "Yeah, I want you to be on top of that okay? I want you to be my productivity czar. Okay, good. Uh, okay that it on my list. So uh, you know, no excuses guys. Lets get going okay? [everyone leaves except Kevin and Stanley, who are sitting stunned in their chairs]", "order": 26806, "speaker": "Charles Miner"},
  {"season": 5, "episode": 20, "scene": 1, "line": "[phone ringing] You gonna answer that, Kev?", "order": 26807, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 20, "scene": 1, "line": "Oh, right. Thanks. [reading off index card] Dunder Mifflin, this is Kevin. Please hold while I transfer you. [cupping the mouthpiece, yelling] Oscar, your mom!", "order": 26808, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 20, "scene": 2, "line": "Charles is having Kevin cover the phones for a while. How do I say this diplomatically? [pause] I think Kevin is doing exactly as well as anyone might have expected someone like him to perform in a position like that.", "order": 26809, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 20, "scene": 3, "line": "Please hold. [cupping mouthpiece and holding out the phone] Andy! Phone call.", "order": 26810, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 20, "scene": 3, "line": "No. [Andy gets up from his desk] Stay there. Kev, thought we nailed the whole transfer thing earlier.", "order": 26811, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 20, "scene": 3, "line": "Yeah, I wrote it on my hand... but then I washed it.", "order": 26812, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 20, "scene": 3, "line": "It is transfer, extension, and then transfer again.", "order": 26813, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 20, "scene": 3, "line": "Okay. Andy, get ready. [muttering] Transfer. Extension. Transfer.", "order": 26814, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 20, "scene": 3, "line": "Here we go. [Meredith's phone rings. Jim and Andy groan.]", "order": 26815, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 20, "scene": 3, "line": "Oh, man!", "order": 26816, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 20, "scene": 3, "line": "It is 1-3-4, Kev! [Kevin runs to the phone]", "order": 26817, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 20, "scene": 3, "line": "Kev, c'mon.", "order": 26818, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 20, "scene": 3, "line": "Hustle!", "order": 26819, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 20, "scene": 3, "line": "Hold it.", "order": 26820, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 20, "scene": 3, "line": "You are murdering the Nard-dog!", "order": 26821, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 20, "scene": 3, "line": "[on phone] This is Kevin. Please hold and I will transfer you. [Phyllis's phone rings]", "order": 26822, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 20, "scene": 3, "line": "You're bad at this too!", "order": 26823, "speaker": "Angela Martin"},
  {"season": 5, "episode": 20, "scene": 3, "line": "[running to the phone] Just... don't answer that call!", "order": 26824, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 20, "scene": 3, "line": "Just transfer the damn call.", "order": 26825, "speaker": "Stanley Hudson"},
  {"season": 5, "episode": 20, "scene": 3, "line": "Your call is very important to us. Ple-[Andy's phone rings]", "order": 26826, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 20, "scene": 3, "line": "Hey-o! [applauding and cheering]", "order": 26827, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 20, "scene": 3, "line": "Way to go.", "order": 26828, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 20, "scene": 4, "line": "My maid died.", "order": 26829, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 20, "scene": 5, "line": "Today is my first day at my new job at Michael Scott Paper Company Incorporated. You know, Apple Computers started in a garage. And we're starting in a condo. So we already have a leg up on Apple. [picks up piece of mail] Look, it's official! [door opens, Michael is in a bath robe]", "order": 26830, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 20, "scene": 5, "line": "Oh, good. My hooker's here. [laughs] Hi.", "order": 26831, "speaker": "Michael Scott"},
  {"season": 5, "episode": 20, "scene": 5, "line": "Michael, you were expecting me, right?", "order": 26832, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 20, "scene": 5, "line": "Yes I was. Yes I was.", "order": 26833, "speaker": "Michael Scott"},
  {"season": 5, "episode": 20, "scene": 5, "line": "Are you wearing anything under the robe?", "order": 26834, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 20, "scene": 5, "line": "That is inappropriate, Pam. Come on in.", "order": 26835, "speaker": "Michael Scott"},
  {"season": 5, "episode": 20, "scene": 6, "line": "This looks great, Michael.", "order": 26836, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 20, "scene": 6, "line": "Thank you. Would you like some french toast?", "order": 26837, "speaker": "Michael Scott"},
  {"season": 5, "episode": 20, "scene": 6, "line": "Yes, please.", "order": 26838, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 20, "scene": 6, "line": "What shape?", "order": 26839, "speaker": "Michael Scott"},
  {"season": 5, "episode": 20, "scene": 6, "line": "... Square is fine.", "order": 26840, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 20, "scene": 6, "line": "Alright.", "order": 26841, "speaker": "Michael Scott"},
  {"season": 5, "episode": 20, "scene": 7, "line": "Just want to fill you in on a few details. As you know, I will be running the branch while we search for Michael's replacement. So please feel free to come to me with any questions or concerns. [Kelly raises hand] Yeah.", "order": 26842, "speaker": "Charles Miner"},
  {"season": 5, "episode": 20, "scene": 7, "line": "Where will you be staying while you're in Scranton?", "order": 26843, "speaker": "Kelly Kapoor"},
  {"season": 5, "episode": 20, "scene": 7, "line": "Eh, uh, in a hotel.", "order": 26844, "speaker": "Charles Miner"},
  {"season": 5, "episode": 20, "scene": 7, "line": "Charles, where were you born?", "order": 26845, "speaker": "Angela Martin"},
  {"season": 5, "episode": 20, "scene": 7, "line": "Actually, I meant questions more about the day-to-day operations of the company. Kay. [Andy raises hand] Yeah.", "order": 26846, "speaker": "Charles Miner"},
  {"season": 5, "episode": 20, "scene": 7, "line": "How are operations of the company? Just, day-to day.", "order": 26847, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 20, "scene": 7, "line": "Okay, let's go over non-discretionary cuts. Okay, guys? [Charles sees Stanley with his crossword, Charles stares and he puts it away] Thank you. So, umm...", "order": 26848, "speaker": "Charles Miner"},
  {"season": 5, "episode": 20, "scene": 7, "line": "[whispering] Would you please stop that?", "order": 26849, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 20, "scene": 7, "line": "What?", "order": 26850, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 20, "scene": 7, "line": "You're breathing very heavily.", "order": 26851, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 20, "scene": 7, "line": "This is how I breathe.", "order": 26852, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 20, "scene": 7, "line": "No it's not.", "order": 26853, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 20, "scene": 7, "line": "If you want to tell me -", "order": 26854, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 20, "scene": 7, "line": "Okay, Jim Halpert. I need your eyes up front.", "order": 26855, "speaker": "Charles Miner"},
  {"season": 5, "episode": 20, "scene": 7, "line": "Oh no. I was just -", "order": 26856, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 20, "scene": 7, "line": "No. Hey, hey. I just want to hear 'yes'.", "order": 26857, "speaker": "Charles Miner"},
  {"season": 5, "episode": 20, "scene": 7, "line": "... Yes.", "order": 26858, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 20, "scene": 7, "line": "Good. As I was saying...", "order": 26859, "speaker": "Charles Miner"},
  {"season": 5, "episode": 20, "scene": 8, "line": "Oh no! The new boss does not find Jim adorable! Ohhhh! [smiles]", "order": 26860, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 20, "scene": 9, "line": "So, what do you say we get started?", "order": 26861, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 20, "scene": 9, "line": "After breakfast.", "order": 26862, "speaker": "Michael Scott"},
  {"season": 5, "episode": 20, "scene": 9, "line": "I'm full.", "order": 26863, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 20, "scene": 9, "line": "So how you feeling about the new company?", "order": 26864, "speaker": "Michael Scott"},
  {"season": 5, "episode": 20, "scene": 9, "line": "I feel good. [sees a huge pile of French toast] Wow.", "order": 26865, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 20, "scene": 9, "line": "You excited? About the new company?", "order": 26866, "speaker": "Michael Scott"},
  {"season": 5, "episode": 20, "scene": 9, "line": "Yeah. I'm excited to start the company.", "order": 26867, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 20, "scene": 9, "line": "After breakfast.", "order": 26868, "speaker": "Michael Scott"},
  {"season": 5, "episode": 20, "scene": 9, "line": "We did that. So, what's next? Michael, just stop for a second. [reaches out to take the whisk] Michael. Stop for a second.", "order": 26869, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 20, "scene": 9, "line": "No, I'm whipping them.", "order": 26870, "speaker": "Michael Scott"},
  {"season": 5, "episode": 20, "scene": 9, "line": "No, I know you are.", "order": 26871, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 20, "scene": 9, "line": "Just let go.", "order": 26872, "speaker": "Michael Scott"},
  {"season": 5, "episode": 20, "scene": 9, "line": "Just let me have... Oh.", "order": 26873, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 20, "scene": 9, "line": "Let go, please. Just gimmee -", "order": 26874, "speaker": "Michael Scott"},
  {"season": 5, "episode": 20, "scene": 9, "line": "Fine! [eggs splatter all over his robe] Oh, I'm sorry. I'm sorry.", "order": 26875, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 20, "scene": 9, "line": "I can't do this! This is pathetic, isn't it? I am such an idiot. I gave up the only job I ever loved to do this? I have egg in my Crocs.", "order": 26876, "speaker": "Michael Scott"},
  {"season": 5, "episode": 20, "scene": 10, "line": "I never see him drink. I never see him eat.", "order": 26877, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 20, "scene": 10, "line": "I don't think he even uses the bathroom.", "order": 26878, "speaker": "Stanley Hudson"},
  {"season": 5, "episode": 20, "scene": 10, "line": "Oh, he does. He does.", "order": 26879, "speaker": "Creed Bratton"},
  {"season": 5, "episode": 20, "scene": 11, "line": "[doing situps] I feel weak today. Felt much stronger yesterday. Like Benjamin Button in reverse.", "order": 26880, "speaker": "Michael Scott"},
  {"season": 5, "episode": 20, "scene": 11, "line": "You know, Michael. When I feel overwhelmed -", "order": 26881, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 20, "scene": 11, "line": "I'm not overwhelmed, Pam.", "order": 26882, "speaker": "Michael Scott"},
  {"season": 5, "episode": 20, "scene": 11, "line": "I know! No, I'm saying that when I feel overwhelmed, something I like to do is make a list. Make a list of things to do and then start with the easy stuff.", "order": 26883, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 20, "scene": 11, "line": "Whatever calms you down.", "order": 26884, "speaker": "Michael Scott"},
  {"season": 5, "episode": 20, "scene": 11, "line": "Okay, first, work out. And, hey! Look! You did that. Check. [Michael doing leg lifts] Eat an enormous breakfast. Check.", "order": 26885, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 20, "scene": 11, "line": "Don't patronize me, Pam.", "order": 26886, "speaker": "Michael Scott"},
  {"season": 5, "episode": 20, "scene": 11, "line": "I think you should get dressed.", "order": 26887, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 20, "scene": 11, "line": "I'm not getting dressed. I'm not getting dressed. I have too many things to do before I get dressed. I need to find a hundred clients.", "order": 26888, "speaker": "Michael Scott"},
  {"season": 5, "episode": 20, "scene": 11, "line": "Michael, that seems impossible.", "order": 26889, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 20, "scene": 11, "line": "It's totally impossible!", "order": 26890, "speaker": "Michael Scott"},
  {"season": 5, "episode": 20, "scene": 11, "line": "We need to come up with one realistic thing that we could do today.", "order": 26891, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 20, "scene": 11, "line": "Assemble a sales team. A dream team.", "order": 26892, "speaker": "Michael Scott"},
  {"season": 5, "episode": 20, "scene": 11, "line": "Great.", "order": 26893, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 20, "scene": 11, "line": "Okay, Ryan.", "order": 26894, "speaker": "Michael Scott"},
  {"season": 5, "episode": 20, "scene": 11, "line": "No. What? Why?", "order": 26895, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 20, "scene": 11, "line": "He's everything I'm not and everything I am. He's the whole package.", "order": 26896, "speaker": "Michael Scott"},
  {"season": 5, "episode": 20, "scene": 11, "line": "No. We're not gonna hire Ryan.", "order": 26897, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 20, "scene": 11, "line": "Umm... Oh! Vikram! Best salesman I've ever met.", "order": 26898, "speaker": "Michael Scott"},
  {"season": 5, "episode": 20, "scene": 11, "line": "Okay.", "order": 26899, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 20, "scene": 11, "line": "He worked at that telemarketing place.", "order": 26900, "speaker": "Michael Scott"},
  {"season": 5, "episode": 20, "scene": 11, "line": "We'll find him.", "order": 26901, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 20, "scene": 11, "line": "Okay. Oh also, we have a meeting this afternoon with a potential investor.", "order": 26902, "speaker": "Michael Scott"},
  {"season": 5, "episode": 20, "scene": 11, "line": "Really?", "order": 26903, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 20, "scene": 11, "line": "Yeah.", "order": 26904, "speaker": "Michael Scott"},
  {"season": 5, "episode": 20, "scene": 11, "line": "We have an investor already?", "order": 26905, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 20, "scene": 11, "line": "Maybe. Barbara Keebis. She invests in local businesses. And I am putting together a little presentation for her.", "order": 26906, "speaker": "Michael Scott"},
  {"season": 5, "episode": 20, "scene": 11, "line": "Michael, that's fantastic.", "order": 26907, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 20, "scene": 11, "line": "Yeah, I guess it's not so bad. Got a few things cookin'. [sits down in robe, legs spread wide] Umm...", "order": 26908, "speaker": "Michael Scott"},
  {"season": 5, "episode": 20, "scene": 11, "line": "Hey! We need to get you dressed!", "order": 26909, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 20, "scene": 12, "line": "I have doubts about this too. But when one person freaks out, sometimes it weirdly makes the other one calmer. That's one thing I've learned about relationships. I hate that I just used the word relationship. [Michael comes out in a suit] Hey! You look great! [checks something off her list] Let's go!", "order": 26910, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 20, "scene": 12, "line": "Alright.", "order": 26911, "speaker": "Michael Scott"},
  {"season": 5, "episode": 20, "scene": 13, "line": "[laughing at something on his computer]", "order": 26912, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 20, "scene": 13, "line": "You a soccer fan?", "order": 26913, "speaker": "Charles Miner"},
  {"season": 5, "episode": 20, "scene": 13, "line": "Oh. Oh my God. I'm so embarrassed. You weren't supposed to see this. This is like my secret obsession.", "order": 26914, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 20, "scene": 13, "line": "Well, that makes two of us.", "order": 26915, "speaker": "Charles Miner"},
  {"season": 5, "episode": 20, "scene": 13, "line": "No way!", "order": 26916, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 20, "scene": 13, "line": "Yeah.", "order": 26917, "speaker": "Charles Miner"},
  {"season": 5, "episode": 20, "scene": 14, "line": "I hate soccer. But guess who doesn't hate soccer? Charles Miner.", "order": 26918, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 20, "scene": 15, "line": "I was actually in Germany for the 2006 World Cup Finals.", "order": 26919, "speaker": "Charles Miner"},
  {"season": 5, "episode": 20, "scene": 15, "line": "Ahhh. You bastard! That shoulda been me!", "order": 26920, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 20, "scene": 15, "line": "Yeah, I love the sport. I love the sport. Alright, man.", "order": 26921, "speaker": "Charles Miner"},
  {"season": 5, "episode": 20, "scene": 15, "line": "Cool!", "order": 26922, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 20, "scene": 16, "line": "I've never been a kiss up. I - it's just not how I operate. I mean, I've always subscribed to the idea that if you really want to impress your boss, you go in there and you do mediocre work. Half-heartedly.", "order": 26923, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 20, "scene": 17, "line": "Next on the list - open the mail.", "order": 26924, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 20, "scene": 17, "line": "Oh. Wow. 'Michael Scott Paper Company.' Okay. Very official. 'Dear Mr. Scott. Please be advised that it is in violation of your condominium agreement to conduct a business headquartered in your residence. The penalty, a forfeiture of residence.'", "order": 26925, "speaker": "Michael Scott"},
  {"season": 5, "episode": 20, "scene": 17, "line": "No, okay. It's fine. It's fine. We're just gonna add 'find office'...", "order": 26926, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 20, "scene": 17, "line": "How are we gonna find an office? How can we pay for an office?", "order": 26927, "speaker": "Michael Scott"},
  {"season": 5, "episode": 20, "scene": 17, "line": "Next on the list -- song parodies.", "order": 26928, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 20, "scene": 17, "line": "Okay. Okay. 'Achey Breaky Fart.'", "order": 26929, "speaker": "Michael Scott"},
  {"season": 5, "episode": 20, "scene": 17, "line": "Great. Let's sing it in the car.", "order": 26930, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 20, "scene": 17, "line": "No! No No. No. 'My Stumps.' Like 'My Humps' but a guy with no legs.", "order": 26931, "speaker": "Michael Scott"},
  {"season": 5, "episode": 20, "scene": 17, "line": "Yeah.", "order": 26932, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 20, "scene": 17, "line": "We can do this.", "order": 26933, "speaker": "Michael Scott"},
  {"season": 5, "episode": 20, "scene": 17, "line": "We can do this.", "order": 26934, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 20, "scene": 18, "line": "Whoa! And he just goes, 'Boom'. [kicking motion] Goal!", "order": 26935, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 20, "scene": 18, "line": "Yep. Yep. That's Pele.", "order": 26936, "speaker": "Charles Miner"},
  {"season": 5, "episode": 20, "scene": 18, "line": "You know your soccer, man.", "order": 26937, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 20, "scene": 18, "line": "I know. Yes I do.", "order": 26938, "speaker": "Charles Miner"},
  {"season": 5, "episode": 20, "scene": 18, "line": "I prefer [looks down at palms] Maradona. Uhhh... Diego Maradona.", "order": 26939, "speaker": "Stanley Hudson"},
  {"season": 5, "episode": 20, "scene": 18, "line": "Oh yeah?", "order": 26940, "speaker": "Charles Miner"},
  {"season": 5, "episode": 20, "scene": 18, "line": "From Argentina.", "order": 26941, "speaker": "Stanley Hudson"},
  {"season": 5, "episode": 20, "scene": 18, "line": "I didn't know we had so many, uh, soccer fans in the office.", "order": 26942, "speaker": "Charles Miner"},
  {"season": 5, "episode": 20, "scene": 18, "line": "I mean, to be fair, I was the first one to talk about it, but...", "order": 26943, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 20, "scene": 18, "line": "What about you, Jim? You a fan of the game?", "order": 26944, "speaker": "Charles Miner"},
  {"season": 5, "episode": 20, "scene": 18, "line": "Uh, no. Nope. Not really.", "order": 26945, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 20, "scene": 18, "line": "Well, it's not for everybody I suppose. [Andy laughs]", "order": 26946, "speaker": "Charles Miner"},
  {"season": 5, "episode": 20, "scene": 18, "line": "It's 'cause I'm more of a player.", "order": 26947, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 20, "scene": 18, "line": "Yeah?", "order": 26948, "speaker": "Charles Miner"},
  {"season": 5, "episode": 20, "scene": 18, "line": "You bet.", "order": 26949, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 20, "scene": 18, "line": "Really, Jim? I had no idea you played soccer. 'Cause you never, ever talk about it.", "order": 26950, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 20, "scene": 18, "line": "Well I do.", "order": 26951, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 20, "scene": 18, "line": "Wow.", "order": 26952, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 20, "scene": 18, "line": "I play.", "order": 26953, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 20, "scene": 18, "line": "You can be so modest sometimes.", "order": 26954, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 20, "scene": 18, "line": "Well, maybe you should get back to work.", "order": 26955, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 20, "scene": 18, "line": "Maybe you and Charles should kick the soccer ball around.", "order": 26956, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 20, "scene": 18, "line": "Maybe we will someday.", "order": 26957, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 20, "scene": 18, "line": "Maybe you will tonight after work. What do you say?", "order": 26958, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 20, "scene": 18, "line": "That's a great idea, Dwight.", "order": 26959, "speaker": "Charles Miner"},
  {"season": 5, "episode": 20, "scene": 18, "line": "Great ideas are just part of what I bring to the table.", "order": 26960, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 20, "scene": 18, "line": "Yeah.", "order": 26961, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 20, "scene": 18, "line": "I don't try and be anything that I'm not.", "order": 26962, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 20, "scene": 18, "line": "What do you say, Jim? Huh? Wanna play some soccer?", "order": 26963, "speaker": "Charles Miner"},
  {"season": 5, "episode": 20, "scene": 18, "line": "Jim, what do you say?", "order": 26964, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 20, "scene": 18, "line": "Sounds fun.", "order": 26965, "speaker": "Angela Martin"},
  {"season": 5, "episode": 20, "scene": 18, "line": "I'm in it to win.", "order": 26966, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 20, "scene": 18, "line": "Game on!", "order": 26967, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 20, "scene": 18, "line": "Okay. See you on the field, there, bro.", "order": 26968, "speaker": "Charles Miner"},
  {"season": 5, "episode": 20, "scene": 18, "line": "Let's... eh... see ya.", "order": 26969, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 20, "scene": 18, "line": "See you on the field. Ha, ha. I can't wait!", "order": 26970, "speaker": "Charles Miner"},
  {"season": 5, "episode": 20, "scene": 19, "line": "Yep, I used to play soccer in school. From second to fourth grade. I was on the orange team.", "order": 26971, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 20, "scene": 20, "line": "[Michael comes out with Vikram, arms raised] We got Vikram!", "order": 26972, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 20, "scene": 20, "line": "You got me.", "order": 26973, "speaker": "Vikram"},
  {"season": 5, "episode": 20, "scene": 21, "line": "Where are we going?", "order": 26974, "speaker": "Vikram"},
  {"season": 5, "episode": 20, "scene": 21, "line": "We have a meeting with an investor today.", "order": 26975, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 20, "scene": 21, "line": "Yes we do. So, get excited. But I have to go the bathroom real quick. If you'll excuse me, be right back. [gets out of the car] Ah, okay.", "order": 26976, "speaker": "Michael Scott"},
  {"season": 5, "episode": 20, "scene": 21, "line": "He seems really confident.", "order": 26977, "speaker": "Vikram"},
  {"season": 5, "episode": 20, "scene": 21, "line": "He can be.", "order": 26978, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 20, "scene": 21, "line": "Confidence. It's the food of the wise man but the liquor of the fool.", "order": 26979, "speaker": "Vikram"},
  {"season": 5, "episode": 20, "scene": 21, "line": "Hm. I'm looking forward to getting to know you better, Vikram.", "order": 26980, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 20, "scene": 22, "line": "Hey. I would like a pair of size nine, please. [Ryan is working at the bowling alley] It's Michael.", "order": 26981, "speaker": "Michael Scott"},
  {"season": 5, "episode": 20, "scene": 22, "line": "I'm swamped, Michael. [over the PA] Happy birthday to Sally in lane 27.", "order": 26982, "speaker": "Ryan Howard"},
  {"season": 5, "episode": 20, "scene": 22, "line": "Okay, imagine a company that has no memory of your past misconduct because they have no files.", "order": 26983, "speaker": "Michael Scott"},
  {"season": 5, "episode": 20, "scene": 23, "line": "He's taking a long time. Is it possible he's bowling? I mean, you know him better than I do.", "order": 26984, "speaker": "Vikram"},
  {"season": 5, "episode": 20, "scene": 23, "line": "Yes. Yes, it's possible.", "order": 26985, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 20, "scene": 24, "line": "Well, it has always been a lifelong dream.", "order": 26986, "speaker": "Michael Scott"},
  {"season": 5, "episode": 20, "scene": 24, "line": "Michael! What's going on?", "order": 26987, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 20, "scene": 24, "line": "I'm...", "order": 26988, "speaker": "Michael Scott"},
  {"season": 5, "episode": 20, "scene": 24, "line": "Hey, Ryan.", "order": 26989, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 20, "scene": 24, "line": "Hey, you.", "order": 26990, "speaker": "Ryan Howard"},
  {"season": 5, "episode": 20, "scene": 24, "line": "Excuse me. Yes?", "order": 26991, "speaker": "Michael Scott"},
  {"season": 5, "episode": 20, "scene": 24, "line": "This wasn't on the list.", "order": 26992, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 20, "scene": 24, "line": "Yes, it is.", "order": 26993, "speaker": "Michael Scott"},
  {"season": 5, "episode": 20, "scene": 24, "line": "No.", "order": 26994, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 20, "scene": 24, "line": "Yes.", "order": 26995, "speaker": "Michael Scott"},
  {"season": 5, "episode": 20, "scene": 24, "line": "It's not.", "order": 26996, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 20, "scene": 24, "line": "It is.", "order": 26997, "speaker": "Michael Scott"},
  {"season": 5, "episode": 20, "scene": 24, "line": "[looks at list] When did you add this to the list?", "order": 26998, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 20, "scene": 24, "line": "Pam. Everyone deserves a second second chance. [walks back to Ryan] Ryan, just out of curiosity, how much do you get paid here?", "order": 26999, "speaker": "Michael Scott"},
  {"season": 5, "episode": 20, "scene": 24, "line": "Sixty thousand dollars a year.", "order": 27000, "speaker": "Ryan Howard"},
  {"season": 5, "episode": 20, "scene": 24, "line": "You get paid by the year at the bowling alley?", "order": 27001, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 20, "scene": 24, "line": "What do you make, secretary?", "order": 27002, "speaker": "Ryan Howard"},
  {"season": 5, "episode": 20, "scene": 24, "line": "Back to work, shoe bitch!", "order": 27003, "speaker": "Supervisor"},
  {"season": 5, "episode": 20, "scene": 24, "line": "I told you guys, I'm really busy here, so...", "order": 27004, "speaker": "Ryan Howard"},
  {"season": 5, "episode": 20, "scene": 24, "line": "Would you like to come to work for the Michael Scott Paper Company?", "order": 27005, "speaker": "Michael Scott"},
  {"season": 5, "episode": 20, "scene": 24, "line": "What size shoes are you guys?", "order": 27006, "speaker": "Ryan Howard"},
  {"season": 5, "episode": 20, "scene": 24, "line": "Uh, nine.", "order": 27007, "speaker": "Michael Scott"},
  {"season": 5, "episode": 20, "scene": 24, "line": "What are those, a men's ten?", "order": 27008, "speaker": "Ryan Howard"},
  {"season": 5, "episode": 20, "scene": 24, "line": "No.", "order": 27009, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 20, "scene": 24, "line": "[Ryan grabs some shoes] Look what he's doing.", "order": 27010, "speaker": "Michael Scott"},
  {"season": 5, "episode": 20, "scene": 24, "line": "What is he doing?", "order": 27011, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 20, "scene": 24, "line": "He's stealing them. Okay, okay.", "order": 27012, "speaker": "Michael Scott"},
  {"season": 5, "episode": 20, "scene": 24, "line": "Oh my God.", "order": 27013, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 20, "scene": 24, "line": "He's already paying for himself.", "order": 27014, "speaker": "Michael Scott"},
  {"season": 5, "episode": 20, "scene": 24, "line": "Sorry!", "order": 27015, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 20, "scene": 25, "line": "Where is it?", "order": 27016, "speaker": "Ryan Howard"},
  {"season": 5, "episode": 20, "scene": 25, "line": "Right here.", "order": 27017, "speaker": "Michael Scott"},
  {"season": 5, "episode": 20, "scene": 25, "line": "Hey, c'mon, guys. These are prime selling hours, you know.", "order": 27018, "speaker": "Vikram"},
  {"season": 5, "episode": 20, "scene": 26, "line": "Miner - [kicking a piece of trash]", "order": 27019, "speaker": "Charles Miner"},
  {"season": 5, "episode": 20, "scene": 26, "line": "Oh, there he goes.", "order": 27020, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 20, "scene": 26, "line": "...sees his partner -", "order": 27021, "speaker": "Charles Miner"},
  {"season": 5, "episode": 20, "scene": 26, "line": "Yikes.", "order": 27022, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 20, "scene": 26, "line": "Halpert. He looks up!", "order": 27023, "speaker": "Charles Miner"},
  {"season": 5, "episode": 20, "scene": 26, "line": "Aw, man.", "order": 27024, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 20, "scene": 26, "line": "Defending duo! He sets him up! [Jim moves the piece of trash with his hands] He sets him up. Yeah. Oh-ho!", "order": 27025, "speaker": "Charles Miner"},
  {"season": 5, "episode": 20, "scene": 26, "line": "Oh! Goal!", "order": 27026, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 20, "scene": 26, "line": "Aw, man, I can't wait to play with you.", "order": 27027, "speaker": "Charles Miner"},
  {"season": 5, "episode": 20, "scene": 26, "line": "Aw, it's gonna be the [softly] worst.", "order": 27028, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 20, "scene": 27, "line": "Do you guys want to hear about Thailand?", "order": 27029, "speaker": "Ryan Howard"},
  {"season": 5, "episode": 20, "scene": 27, "line": "Oh yeah.", "order": 27030, "speaker": "Michael Scott"},
  {"season": 5, "episode": 20, "scene": 27, "line": "Sure.", "order": 27031, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 20, "scene": 27, "line": "It was indescribable.", "order": 27032, "speaker": "Ryan Howard"},
  {"season": 5, "episode": 20, "scene": 27, "line": "Sounds awesome.", "order": 27033, "speaker": "Michael Scott"},
  {"season": 5, "episode": 20, "scene": 27, "line": "Beat.", "order": 27034, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 20, "scene": 28, "line": "What sort of investing club is this?", "order": 27035, "speaker": "Vikram"},
  {"season": 5, "episode": 20, "scene": 28, "line": "Vikram, you ask a lot of questions and I like that. Hey, Nana! Hi!", "order": 27036, "speaker": "Michael Scott"},
  {"season": 5, "episode": 20, "scene": 28, "line": "[mouthing to camera] Nana?", "order": 27037, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 20, "scene": 28, "line": "Michael!", "order": 27038, "speaker": "Nana"},
  {"season": 5, "episode": 20, "scene": 29, "line": "People turn to their families all the time when they need help starting out and if my Nana's investment club can help the Michael Scott Paper Company become a reality, then I'm sure that's what she would have wanted. Does want.", "order": 27039, "speaker": "Michael Scott"},
  {"season": 5, "episode": 20, "scene": 30, "line": "Well I'm sure that you must all have very, very busy schedules, so I appreciate you meeting with us here today. What this is is a business that I have worked toward my entire life. Hey! [snaps in front of sleeping old man] I have assembled what I believe to be the most exciting, sought-after talent in the industry today. Ladies and gentlemen, I present to you the dream team. From our very own Scranton, Pennsylvania - Pam Beesly. Pretty Pam is always reaching for the stars and someday, she may just surprise us all and grab one. Meet Vikram. From his humble beginnings as a - stay standing - from his humble beginnings as a prominent surgeon, he risked it all to become the most successful telemarketer in the lipophedrene industry. And do not call it a comeback. The youngest vice president in the history of Dunder Mifflin, and recent bowling alley employee, Ryan Howard is about to make a splash in paper.", "order": 27040, "speaker": "Michael Scott"},
  {"season": 5, "episode": 20, "scene": 30, "line": "So let's hear it.", "order": 27041, "speaker": "Nana"},
  {"season": 5, "episode": 20, "scene": 30, "line": "Okay. I have spent the last 15 years learning the ins and outs of the paper industry. With a lean, mean fighting crew and low overhead, I think I can perform the same business at a much, much higher rate of profit.", "order": 27042, "speaker": "Michael Scott"},
  {"season": 5, "episode": 20, "scene": 30, "line": "How do you expect to turn a profit in this economy?", "order": 27043, "speaker": "Nana"},
  {"season": 5, "episode": 20, "scene": 30, "line": "By wanting it more. By working hard-", "order": 27044, "speaker": "Michael Scott"},
  {"season": 5, "episode": 20, "scene": 30, "line": "What's your mission statement?", "order": 27045, "speaker": "Nana"},
  {"season": 5, "episode": 20, "scene": 30, "line": "Mmmm-My mission is stated as follows: I will not be beat. I will never give up. I am on a mission. That is the Michael Scott guarantee.", "order": 27046, "speaker": "Michael Scott"},
  {"season": 5, "episode": 20, "scene": 31, "line": "C'mon, Oscar. What positions do people play?", "order": 27047, "speaker": "Charles Miner"},
  {"season": 5, "episode": 20, "scene": 31, "line": "Wing.", "order": 27048, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 20, "scene": 31, "line": "Wag.", "order": 27049, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 20, "scene": 31, "line": "Jim. What do you play?", "order": 27050, "speaker": "Charles Miner"},
  {"season": 5, "episode": 20, "scene": 31, "line": "Left.", "order": 27051, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 20, "scene": 31, "line": "Forward or half?", "order": 27052, "speaker": "Charles Miner"},
  {"season": 5, "episode": 20, "scene": 31, "line": "Forward, definitely.", "order": 27053, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 20, "scene": 31, "line": "Okay, striker, huh?", "order": 27054, "speaker": "Charles Miner"},
  {"season": 5, "episode": 20, "scene": 31, "line": "Striker!", "order": 27055, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 20, "scene": 31, "line": "Let's see what you got in those legs!", "order": 27056, "speaker": "Charles Miner"},
  {"season": 5, "episode": 20, "scene": 31, "line": "Alright! Let's do it.", "order": 27057, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 20, "scene": 31, "line": "Alright. [Dwight blows whistle in Jim's face] No, no, no. That's supposed to be the kickoff.", "order": 27058, "speaker": "Charles Miner"},
  {"season": 5, "episode": 20, "scene": 31, "line": "Kickoff. [to camera] My strategy is to touch the ball as little as possible. Chalk it up to teamwork. [to team] Alright! [clapping]", "order": 27059, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 20, "scene": 32, "line": "And I offer same day, free delivery.", "order": 27060, "speaker": "Michael Scott"},
  {"season": 5, "episode": 20, "scene": 32, "line": "Michael, I don't know about this.", "order": 27061, "speaker": "Nana"},
  {"season": 5, "episode": 20, "scene": 32, "line": "Nana. [softly] I really think that Papa would want you to do this.", "order": 27062, "speaker": "Michael Scott"},
  {"season": 5, "episode": 20, "scene": 32, "line": "I'm not so sure.", "order": 27063, "speaker": "Nana"},
  {"season": 5, "episode": 20, "scene": 32, "line": "Well what is it exactly? I mean, what, what specifically? We don't - it doesn't have to be paper. We could sell medicine and other...", "order": 27064, "speaker": "Michael Scott"},
  {"season": 5, "episode": 20, "scene": 32, "line": "See, this is what concerns me.", "order": 27065, "speaker": "Nana"},
  {"season": 5, "episode": 20, "scene": 32, "line": "We could just give him a chance. It's not much money.", "order": 27066, "speaker": "Old Woman"},
  {"season": 5, "episode": 20, "scene": 32, "line": "Well... this might not be the right time, but I need more than I originally asked for.", "order": 27067, "speaker": "Michael Scott"},
  {"season": 5, "episode": 20, "scene": 32, "line": "This isn't a handout club. It's an investment club!", "order": 27068, "speaker": "Nana"},
  {"season": 5, "episode": 20, "scene": 32, "line": "Okay.", "order": 27069, "speaker": "Michael Scott"},
  {"season": 5, "episode": 20, "scene": 32, "line": "I love you, Michael. I do.", "order": 27070, "speaker": "Nana"},
  {"season": 5, "episode": 20, "scene": 32, "line": "Okay.", "order": 27071, "speaker": "Michael Scott"},
  {"season": 5, "episode": 20, "scene": 32, "line": "But no. I mean it. No.", "order": 27072, "speaker": "Nana"},
  {"season": 5, "episode": 20, "scene": 33, "line": "I thought Nana raised some good questions. What kind of a name is Nana?", "order": 27073, "speaker": "Vikram"},
  {"season": 5, "episode": 20, "scene": 33, "line": "It means grandmother.", "order": 27074, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 20, "scene": 33, "line": "Oh, sweet Jesus. Look, I'm sorry to do this, but can you drop me back at the telemarketing building?", "order": 27075, "speaker": "Vikram"},
  {"season": 5, "episode": 20, "scene": 34, "line": "You know, Michael? You want to succeed? You got to apply the same- [Michael slams door]", "order": 27076, "speaker": "Vikram"},
  {"season": 5, "episode": 20, "scene": 35, "line": "Get 'im, Jim! C'mon, tough D, Jim. [Charles kicks, ball hits Phyllis in the nose]", "order": 27077, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 20, "scene": 35, "line": "Oh, my God! Oh wow. Jim, what the?", "order": 27078, "speaker": "Charles Miner"},
  {"season": 5, "episode": 20, "scene": 35, "line": "Phyllis, Phyllis.", "order": 27079, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 20, "scene": 35, "line": "Phyllis, are you okay? Are, you okay?", "order": 27080, "speaker": "Charles Miner"},
  {"season": 5, "episode": 20, "scene": 35, "line": "[muffled] No, I swallowed a crown.", "order": 27081, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 20, "scene": 35, "line": "Why'd you duck, Jim?", "order": 27082, "speaker": "Charles Miner"},
  {"season": 5, "episode": 20, "scene": 35, "line": "What?", "order": 27083, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 20, "scene": 35, "line": "Yeah, Jim. Why would an experienced soccer player like yourself duck at the very last moment?", "order": 27084, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 20, "scene": 35, "line": "Okay, I'm just gonna go get some ice.", "order": 27085, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 20, "scene": 35, "line": "Does that make you feel better? Huh?", "order": 27086, "speaker": "Charles Miner"},
  {"season": 5, "episode": 20, "scene": 35, "line": "I'm sorry, Phyllis.", "order": 27087, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 20, "scene": 35, "line": "Oh, yeah. Jim's sorry.", "order": 27088, "speaker": "Charles Miner"},
  {"season": 5, "episode": 20, "scene": 36, "line": "Do you get TNT? The station? Do you get TNT?", "order": 27089, "speaker": "Ryan Howard"},
  {"season": 5, "episode": 20, "scene": 36, "line": "Yeah. I have cable and satellite as a backup. [to Pam] Yeah, I know. Two not-so-great things in a row. Ehhhh, well. Stuff happens, right? At least we got Ryan. The Rye-guy. We should call him Rye bread. We don't have to call him that. Unless you like it. We could call him that.", "order": 27090, "speaker": "Michael Scott"},
  {"season": 5, "episode": 20, "scene": 36, "line": "I can't do this.", "order": 27091, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 20, "scene": 36, "line": "What's that?", "order": 27092, "speaker": "Michael Scott"},
  {"season": 5, "episode": 20, "scene": 36, "line": "I can't do this. I had a real job. I sat ten feet away from my fiance. I had health benefits. I was just feeling impulsive. I should have gotten a tiny tattoo on my ankle.", "order": 27093, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 20, "scene": 36, "line": "Blech.", "order": 27094, "speaker": "Michael Scott"},
  {"season": 5, "episode": 20, "scene": 36, "line": "I just keep getting bored. And I let things build up and build up and then I - I, I do something too big, like this. Who does this?", "order": 27095, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 20, "scene": 36, "line": "Well you know what? My mom always used to say that average people are the most special people in the world. And that's why God made so many.", "order": 27096, "speaker": "Michael Scott"},
  {"season": 5, "episode": 20, "scene": 36, "line": "We don't have any money. We don't have an office. We don't have anything.", "order": 27097, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 20, "scene": 36, "line": "Well we should make a list. Lists are good. Lists are good. Lists are good. First on the list, let's get you out of the car. Alright. [Pam tosses list out car window] Okay...", "order": 27098, "speaker": "Michael Scott"},
  {"season": 5, "episode": 20, "scene": 36, "line": "How come out of everyone in the office, I'm the only one that went with you? Is it because I'm that stupid? I mean, your own grandmother doesn't even believe in you!", "order": 27099, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 20, "scene": 36, "line": "I want you to listen to me. Because I want to tell you the situation that we are both in right now, kay? You quit your job. I quit my job. We both quit. Those are the facts. That's what happened. Now, what are our choices right now? Because you know, kiddo, you quit.", "order": 27100, "speaker": "Michael Scott"},
  {"season": 5, "episode": 20, "scene": 36, "line": "Yeah.", "order": 27101, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 20, "scene": 36, "line": "So what are our options? Well, we can start this paper company. We can try. Or... that's it. That's our only option. Because we quit. Pam, I do my best work when people don't believe in me. I remember in high school, my math teacher told me I was gonna flunk out. And know what I did? The very next day I went out and I scored more goals than anyone else in the history of the hockey team. See what I mean? I thrive on this. I thrive on it. So I'm gonna go inside. I'm going to make some calls, I'm gonna get us an office space, and I'm going to show you why you joined this company. Okay? [Pam nods]", "order": 27102, "speaker": "Michael Scott"},
  {"season": 5, "episode": 20, "scene": 37, "line": "You gotta have some extra space. Philly, work with me here. There's gotta be some sort of secret office that you have... lurkin' around... some awesome, free, keep it off the books... Oh, no. Not there. That would be humiliating.", "order": 27103, "speaker": "Michael Scott"},
  {"season": 5, "episode": 20, "scene": 38, "line": "I did what I had to do. I stepped in. I took charge. That's what being a man is. And earlier today, I was freaking out. Pam stepped up. She was the man. Don't think a woman can be a man? Well, then that's your stereotype, not mine.", "order": 27104, "speaker": "Michael Scott"},
  {"season": 5, "episode": 20, "scene": 39, "line": "[walks into room] Okay.", "order": 27105, "speaker": "Michael Scott"},
  {"season": 5, "episode": 20, "scene": 39, "line": "I could work here. I could see this.", "order": 27106, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 20, "scene": 39, "line": "It's right in the middle of the paper belt.", "order": 27107, "speaker": "Michael Scott"},
  {"season": 5, "episode": 20, "scene": 39, "line": "Are you good?", "order": 27108, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 20, "scene": 39, "line": "Yeah, I'm good. You good?", "order": 27109, "speaker": "Michael Scott"},
  {"season": 5, "episode": 20, "scene": 39, "line": "I'm good.", "order": 27110, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 20, "scene": 40, "line": "You know what they say, keep your friends close. [pointing to office sign where 'Dunder Mifflin' is listed right above 'Michael Scott Pap']", "order": 27111, "speaker": "Michael Scott"},
  {"season": 5, "episode": 20, "scene": 40, "line": "Michael, you're back.", "order": 27112, "speaker": "Charles Miner"},
  {"season": 5, "episode": 20, "scene": 40, "line": "Yes, I am, Charles.", "order": 27113, "speaker": "Michael Scott"},
  {"season": 5, "episode": 20, "scene": 40, "line": "Mmmhmm.", "order": 27114, "speaker": "Charles Miner"},
  {"season": 5, "episode": 20, "scene": 40, "line": "Except this time, you have no legal right to kick me out, because I have started my very own paper company right here in the building. If I were you, Charles Miner, I would watch your step. Because the Michael Scott Paper Company is about to open a big ol' can of whoopass on Dunder Mifflin. [Pam and Michael stare him down, he walks away] Actually a six pack. We're gonna open a six pack of whoopass. He looks scared.", "order": 27115, "speaker": "Michael Scott"},
  {"season": 5, "episode": 21, "scene": 1, "line": "It's Britney bitch. And I am back, in the form of a new company. The Michael Scott Paper Company. [tries to parallel park, hits car.] Okay. Not gonna make this one. [drives away revealing plenty of space for the Cruiser]", "order": 27116, "speaker": "Michael Scott"},
  {"season": 5, "episode": 21, "scene": 2, "line": "LOVE the hair. [Ryan walks up driveway with blonde dyed hair]", "order": 27117, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 21, "scene": 2, "line": "Morning Michael!", "order": 27118, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 21, "scene": 2, "line": "Hello! They took away my parking space but they can't take away my pride! [awkwardly climbs out of his car]", "order": 27119, "speaker": "Michael Scott"},
  {"season": 5, "episode": 21, "scene": 3, "line": "I would like to invite you all to come away with me, on a journey. [nips mylar ribbon with hedge clippers, it won't cut, he tears it down] Welcome! To the Michael Scott Paper Company!", "order": 27120, "speaker": "Michael Scott"},
  {"season": 5, "episode": 21, "scene": 4, "line": "So. I thought we could take the customer on a... Then. Then we could...", "order": 27121, "speaker": "Kelly Kapoor"},
  {"season": 5, "episode": 21, "scene": 4, "line": "You realize you're not actually talking to me, right?", "order": 27122, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 21, "scene": 4, "line": "And...", "order": 27123, "speaker": "Kelly Kapoor"},
  {"season": 5, "episode": 21, "scene": 4, "line": "Hey Kelly?", "order": 27124, "speaker": "Charles Miner"},
  {"season": 5, "episode": 21, "scene": 4, "line": "Yes? Charles, you wanted me?", "order": 27125, "speaker": "Kelly Kapoor"},
  {"season": 5, "episode": 21, "scene": 4, "line": "Oh, I meant, I meant that Kelly. [New receptionist walks in]", "order": 27126, "speaker": "Charles Miner"},
  {"season": 5, "episode": 21, "scene": 5, "line": "The new receptionist is also named Kelly. So Kelly Kapoor has decided to hover around my desk, so that she can run into his Charles' office every time he calls for Kelly. She thinks if she says 'You wanted me' enough, he will in fact want her. It's not the worse plan she's ever had.", "order": 27127, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 21, "scene": 6, "line": "Oh, and Kelly...", "order": 27128, "speaker": "Charles Miner"},
  {"season": 5, "episode": 21, "scene": 6, "line": "Yes Charles? Yes, you wanted me?", "order": 27129, "speaker": "Kelly Kapoor"},
  {"season": 5, "episode": 21, "scene": 6, "line": "Okay, I'm gonna call you Kapoor, okay? And you, Hannon.", "order": 27130, "speaker": "Charles Miner"},
  {"season": 5, "episode": 21, "scene": 6, "line": "If we're changing names, can I be Erin? It's my middle name.", "order": 27131, "speaker": "Erin Hannon"},
  {"season": 5, "episode": 21, "scene": 6, "line": "Erin. Okay, that's very pretty.", "order": 27132, "speaker": "Charles Miner"},
  {"season": 5, "episode": 21, "scene": 6, "line": "Well you know what my middle name is? Rajani Ghana! And I hate it! I hate it!", "order": 27133, "speaker": "Kelly Kapoor"},
  {"season": 5, "episode": 21, "scene": 6, "line": "I thought Rajini Ghanda was a boy's name?", "order": 27134, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 21, "scene": 7, "line": "We are in the heart of it. [Bathroom sounds rattle the small office.] And the surge of water carries our business out to the sea.", "order": 27135, "speaker": "Michael Scott"},
  {"season": 5, "episode": 21, "scene": 7, "line": "What should we do now?", "order": 27136, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 21, "scene": 7, "line": "We wait, and hope that people show up to the pancake luncheon.", "order": 27137, "speaker": "Michael Scott"},
  {"season": 5, "episode": 21, "scene": 8, "line": "I don't know I think we've done absolutely everything you can do to prepare for the day. I've updated our contacts, I have gotten quotes from suppliers. I have sent out an E-vite for our big grand opening pancake luncheon. Six yeses, one maybe, only eleven noes. Um, and 788 not yet replieds. But of that group, 782 have viewed it.", "order": 27138, "speaker": "Michael Scott"},
  {"season": 5, "episode": 21, "scene": 9, "line": "I am going to need 800 of these, on nice bright paper. It is a coupon for unparalleled customer service. [He sets sheet between Pam and Ryan on desk. Neither takes it.]", "order": 27139, "speaker": "Michael Scott"},
  {"season": 5, "episode": 21, "scene": 10, "line": "I make that one copy, and I become the girl who makes copies, and by the end of the day I'm receptionist again. And the worst part is, I like making copies. The paper comes out all warm and stuff. And it's cold in there. Cause it's technically a closet.", "order": 27140, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 21, "scene": 11, "line": "What do you think?", "order": 27141, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 21, "scene": 11, "line": "Let me check. [Pulls out hunting scope] Oh nope! Clearly a hunter. Who knows how to throw an outfit together.", "order": 27142, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 21, "scene": 11, "line": "Thank you!", "order": 27143, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 21, "scene": 12, "line": "Yes, I am taking Andy hunting after work. Not long ago we were sexual competitors. I used to hate him, hate him, hate him, hate him. I studied him, to figure out why I hated him so much. But that blossomed into a very real friendship, as these things often do.", "order": 27144, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 21, "scene": 13, "line": "You should come into town this weekend man. Yeah, we'll hang out Scranton style.", "order": 27145, "speaker": "Ryan Howard"},
  {"season": 5, "episode": 21, "scene": 13, "line": "Hey you said you were gonna be out of town this weekend.", "order": 27146, "speaker": "Michael Scott"},
  {"season": 5, "episode": 21, "scene": 13, "line": "Please don't listen to my phone calls. Yeah, she's like um, she'd probably be a Six in New York, but she's like a Seven here in Scranton. And then uh, my boss is my old boss from Dunder Mifflin. It's a small space.", "order": 27147, "speaker": "Ryan Howard"},
  {"season": 5, "episode": 21, "scene": 14, "line": "Hey Jim can I get a, um-", "order": 27148, "speaker": "Charles Miner"},
  {"season": 5, "episode": 21, "scene": 14, "line": "Hi! [Awkward]", "order": 27149, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 21, "scene": 14, "line": "Hi. I need a rundown of your clients, can you get that to me.", "order": 27150, "speaker": "Charles Miner"},
  {"season": 5, "episode": 21, "scene": 14, "line": "Sure!", "order": 27151, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 21, "scene": 14, "line": "Yeah.", "order": 27152, "speaker": "Charles Miner"},
  {"season": 5, "episode": 21, "scene": 14, "line": "Okay.", "order": 27153, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 21, "scene": 15, "line": "What the hell's a rundown?", "order": 27154, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 21, "scene": 16, "line": "When did you need that rundown by?", "order": 27155, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 21, "scene": 16, "line": "As soon as possible.", "order": 27156, "speaker": "Charles Miner"},
  {"season": 5, "episode": 21, "scene": 16, "line": "Okay.", "order": 27157, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 21, "scene": 16, "line": "Just get it right.", "order": 27158, "speaker": "Charles Miner"},
  {"season": 5, "episode": 21, "scene": 16, "line": "Yeah. Gotcha. Of course. I'm gonna dive in. To the rundown. I'll be exhausted 'cause it's like a triathlon. [At door.] Do you want to close this? Close, or keep it?", "order": 27159, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 21, "scene": 17, "line": "Oh.", "order": 27160, "speaker": "Erin Hannon"},
  {"season": 5, "episode": 21, "scene": 17, "line": "You know there's a ghost in this office", "order": 27161, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 21, "scene": 17, "line": "Really?", "order": 27162, "speaker": "Erin Hannon"},
  {"season": 5, "episode": 21, "scene": 17, "line": "A woman was murdered on this very floor in 1816. Haddie McGonagle. She was a prostitute.", "order": 27163, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 21, "scene": 17, "line": "Why was she in this building?", "order": 27164, "speaker": "Erin Hannon"},
  {"season": 5, "episode": 21, "scene": 17, "line": "Oh, this whole place used to be a brothel. There was a tavern on the ground floor.", "order": 27165, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 21, "scene": 17, "line": "That's crazy.", "order": 27166, "speaker": "Erin Hannon"},
  {"season": 5, "episode": 21, "scene": 17, "line": "Yeah isn't it? She was bludgeoned to death by the business end of a riding crop.", "order": 27167, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 21, "scene": 17, "line": "Oh my god!", "order": 27168, "speaker": "Erin Hannon"},
  {"season": 5, "episode": 21, "scene": 17, "line": "It was gruesome. They say she walks these hallways. If you ever feel a tapping on your shoulder?", "order": 27169, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 21, "scene": 17, "line": "Hmm.", "order": 27170, "speaker": "Erin Hannon"},
  {"season": 5, "episode": 21, "scene": 17, "line": "That could be Haddie. Begging for her life. And it could mean, that you're next. [Taps her shoulder]", "order": 27171, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 21, "scene": 17, "line": "Oh! [Dwight and Erin laugh.]", "order": 27172, "speaker": "Erin Hannon"},
  {"season": 5, "episode": 21, "scene": 17, "line": "Just kidding. You finding everything okay?", "order": 27173, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 21, "scene": 17, "line": "Yeah.", "order": 27174, "speaker": "Erin Hannon"},
  {"season": 5, "episode": 21, "scene": 17, "line": "Yeah?", "order": 27175, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 21, "scene": 17, "line": "Just got some ice.", "order": 27176, "speaker": "Erin Hannon"},
  {"season": 5, "episode": 21, "scene": 17, "line": "Erin [eerie voice] Erin...", "order": 27177, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 21, "scene": 17, "line": "Oh, this is awkward. [Andy and Erin 'dance' trying to get out of each other's way]", "order": 27178, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 21, "scene": 17, "line": "Oops!", "order": 27179, "speaker": "Erin Hannon"},
  {"season": 5, "episode": 21, "scene": 17, "line": "Uh oh! Oh! Oh! [Andy improvs musical verbage]", "order": 27180, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 21, "scene": 17, "line": "So I'll see you guys around!", "order": 27181, "speaker": "Erin Hannon"},
  {"season": 5, "episode": 21, "scene": 17, "line": "Hey buddy.", "order": 27182, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 21, "scene": 18, "line": "Hey Ryan? [Ryan watches Montgomery Flea Market ad on YouTube] Could you get to that copy from before?", "order": 27183, "speaker": "Michael Scott"},
  {"season": 5, "episode": 21, "scene": 18, "line": "Pam's better at that stuff", "order": 27184, "speaker": "Ryan Howard"},
  {"season": 5, "episode": 21, "scene": 18, "line": "That is so insulting.", "order": 27185, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 21, "scene": 18, "line": "How is it insulting to say that you're good at something?", "order": 27186, "speaker": "Ryan Howard"},
  {"season": 5, "episode": 21, "scene": 18, "line": "Because the thing you're saying I'm good at, is pushing a big green button a bunch of times.", "order": 27187, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 21, "scene": 18, "line": "I'm not judging it, it's like ... I could run GM but I couldn't fix a car. It's not saying one is better than the other.", "order": 27188, "speaker": "Ryan Howard"},
  {"season": 5, "episode": 21, "scene": 18, "line": "Seriously? Because it sounds like one of those is better than the other.", "order": 27189, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 21, "scene": 18, "line": "What the hell? [Michael closed his laptop]", "order": 27190, "speaker": "Ryan Howard"},
  {"season": 5, "episode": 21, "scene": 18, "line": "Listen. Listen listen listen listen listen listen. I need someone to make a copy of this. Because I don't make copies, I'm the boss. Got it? I make originals.", "order": 27191, "speaker": "Michael Scott"},
  {"season": 5, "episode": 21, "scene": 18, "line": "Yeah I make originals too.", "order": 27192, "speaker": "Ryan Howard"},
  {"season": 5, "episode": 21, "scene": 18, "line": "Shut up!", "order": 27193, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 21, "scene": 18, "line": "Stop it stop it! Bickering! Stop it!", "order": 27194, "speaker": "Michael Scott"},
  {"season": 5, "episode": 21, "scene": 18, "line": "Really!", "order": 27195, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 21, "scene": 18, "line": "Yeah!", "order": 27196, "speaker": "Ryan Howard"},
  {"season": 5, "episode": 21, "scene": 18, "line": "Not much? What's up with you? [from bathroom above] Nah it's okay. I'm in the bathroom. Hey you been watching Damages this year? It's so good. No, you gotta tune in, it's as good as anything on HBO. [sound of pissing] Hey does blue go with tan?", "order": 27197, "speaker": "Toby Flenderson"},
  {"season": 5, "episode": 21, "scene": 18, "line": "Would someone just make the copy?", "order": 27198, "speaker": "Michael Scott"},
  {"season": 5, "episode": 21, "scene": 18, "line": "I have like, like a blue shirt? I don't wear a lot of colors, I have a lot of tan. Uh huh.", "order": 27199, "speaker": "Toby Flenderson"},
  {"season": 5, "episode": 21, "scene": 18, "line": "Just make that copy okay?", "order": 27200, "speaker": "Michael Scott"},
  {"season": 5, "episode": 21, "scene": 19, "line": "Seriously? This is what's so important, putting naked pictures on the desktop?", "order": 27201, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 21, "scene": 19, "line": "That's me and my friend Jonathan from Thailand.", "order": 27202, "speaker": "Ryan Howard"},
  {"season": 5, "episode": 21, "scene": 19, "line": "I don't want to look at your friend Jasmine's boobs all day.", "order": 27203, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 21, "scene": 19, "line": "You could be hot too if you made any effort. At all.", "order": 27204, "speaker": "Ryan Howard"},
  {"season": 5, "episode": 21, "scene": 19, "line": "Like how? Dyeing my hair blond?", "order": 27205, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 21, "scene": 19, "line": "This is from the sun.", "order": 27206, "speaker": "Ryan Howard"},
  {"season": 5, "episode": 21, "scene": 19, "line": "Oh yeah I bet.", "order": 27207, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 21, "scene": 19, "line": "They're getting on my nerves Mom. Both of them. R thinks he's too good to be here, and P is not as much fun without Jim.", "order": 27208, "speaker": "Michael Scott"},
  {"season": 5, "episode": 21, "scene": 19, "line": "Michael, we can hear you.", "order": 27209, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 21, "scene": 19, "line": "I'm on the phone, please. Mom, I'm gonna have to call you back. P's being a giant B.", "order": 27210, "speaker": "Michael Scott"},
  {"season": 5, "episode": 21, "scene": 19, "line": "Okay it's my turn.", "order": 27211, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 21, "scene": 19, "line": "No don't take that, give that back!", "order": 27212, "speaker": "Ryan Howard"},
  {"season": 5, "episode": 21, "scene": 19, "line": "Give it back for what? What're you gonna do with it?", "order": 27213, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 21, "scene": 19, "line": "I'm gonna make a spreadsheet.", "order": 27214, "speaker": "Ryan Howard"},
  {"season": 5, "episode": 21, "scene": 19, "line": "You're gonna make another 'spreadsheet.'", "order": 27215, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 21, "scene": 19, "line": "Yeah!", "order": 27216, "speaker": "Ryan Howard"},
  {"season": 5, "episode": 21, "scene": 19, "line": "Yeah exactly. It's my turn.", "order": 27217, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 21, "scene": 19, "line": "Go make a copy, secretary.", "order": 27218, "speaker": "Ryan Howard"},
  {"season": 5, "episode": 21, "scene": 19, "line": "Come on! Ryan!", "order": 27219, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 21, "scene": 20, "line": "They always say that it is a mistake to hire your friends and they are right! So, I hired my best friends. And this is what I get?", "order": 27220, "speaker": "Michael Scott"},
  {"season": 5, "episode": 21, "scene": 21, "line": "Hey buddy, what are you up to?", "order": 27221, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 21, "scene": 21, "line": "Um, nerthing?", "order": 27222, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 21, "scene": 21, "line": "Listen, when I saw you talking to Erin earlier, I noticed that your pupils dilated, and your skin flushed, and I'm assuming, a little bit of blood rushed into your penis.", "order": 27223, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 21, "scene": 21, "line": "Pffft.", "order": 27224, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 21, "scene": 21, "line": "Well, a little bit of blood rushed into mine as well, so where does that leave us?", "order": 27225, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 21, "scene": 21, "line": "The last thing I want to do, is step on your funk man.", "order": 27226, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 21, "scene": 21, "line": "And I, yours. So I will cede her to you.", "order": 27227, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 21, "scene": 21, "line": "No, that's ridiculous.", "order": 27228, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 21, "scene": 21, "line": "No no no.", "order": 27229, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 21, "scene": 21, "line": "Look you've been here longer, and besides, I'm a better wingman than I am a boyfriend so-", "order": 27230, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 21, "scene": 21, "line": "Look I just want you and I to hang out so, you know. Just, [They Hi-5 badly] Boom.", "order": 27231, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 21, "scene": 22, "line": "Listen up. It has come to my attention that some people in this office are not getting along with other people in this office. And I think I have come up with the reason why. This office space is too small.", "order": 27232, "speaker": "Michael Scott"},
  {"season": 5, "episode": 21, "scene": 22, "line": "Definitely.", "order": 27233, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 21, "scene": 22, "line": "Okay. There are ... 4 corners in this room. Each corner is to be a personal space for each one of you. Whichever corner you want. And make it your own.", "order": 27234, "speaker": "Michael Scott"},
  {"season": 5, "episode": 21, "scene": 22, "line": "We could work from home.", "order": 27235, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 21, "scene": 22, "line": "Or you could fire one of us. Whoever has less education.", "order": 27236, "speaker": "Ryan Howard"},
  {"season": 5, "episode": 21, "scene": 22, "line": "Okay guys, thank you for the offers. But I want you to get pumped about this corner idea. All right? 1,2,3- What are we gonna do?", "order": 27237, "speaker": "Michael Scott"},
  {"season": 5, "episode": 21, "scene": 22, "line": ".... Corner idea?", "order": 27238, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 21, "scene": 22, "line": "No, you're supposed to say, 'Rock the house.'", "order": 27239, "speaker": "Michael Scott"},
  {"season": 5, "episode": 21, "scene": 22, "line": "Rock the house!", "order": 27240, "speaker": "Ryan Howard"},
  {"season": 5, "episode": 21, "scene": 22, "line": "How would we know that?", "order": 27241, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 21, "scene": 23, "line": "Hey dude, you know what a 'rundown' is?", "order": 27242, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 21, "scene": 23, "line": "Use it in a sentence.", "order": 27243, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 21, "scene": 23, "line": "Uh, can you get this rundown for me?' [impersonating Charles]", "order": 27244, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 21, "scene": 23, "line": "Try another sentence.", "order": 27245, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 21, "scene": 23, "line": "This rundown better be really good'?", "order": 27246, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 21, "scene": 23, "line": "I don't know but it sounds like the rundown is really important.", "order": 27247, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 21, "scene": 23, "line": "Charles asked me to do this rundown of all my clients.", "order": 27248, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 21, "scene": 23, "line": "Why don't you just ask him--", "order": 27249, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 21, "scene": 23, "line": "No. I can't. It was like, hours ago.", "order": 27250, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 21, "scene": 23, "line": "What have you been doing?", "order": 27251, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 21, "scene": 23, "line": "Try it in another sentence.", "order": 27252, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 21, "scene": 24, "line": "Last night was crazy. Jojo? Yeah. He did a donut in a parking lot in front of a cop. And then he yells, 'Hey cop, you like donuts?' then we drive off. No, he just stayed there. [Pam goes to sit in a chair in the corner.] I want that new phone. No, the other one. No the other one. No, you know which one I'm talking about it's the one, the one with the awesome browser. [More bathroom noises] No, not that one.", "order": 27253, "speaker": "Ryan Howard"},
  {"season": 5, "episode": 21, "scene": 25, "line": "That's my corner.", "order": 27254, "speaker": "Michael Scott"},
  {"season": 5, "episode": 21, "scene": 25, "line": "I thought that was your corner.", "order": 27255, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 21, "scene": 25, "line": "No, this is where I work. I can't relax in the same corner where I work.", "order": 27256, "speaker": "Michael Scott"},
  {"season": 5, "episode": 21, "scene": 25, "line": "So my corner's the one with the copier?", "order": 27257, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 21, "scene": 25, "line": "Pam, I don't make the rules. [Pam walks out.]", "order": 27258, "speaker": "Michael Scott"},
  {"season": 5, "episode": 21, "scene": 25, "line": "Yeah. I wish my iPod could make phone calls. No I don't want an iPhone, I know what an iPhone is.", "order": 27259, "speaker": "Ryan Howard"},
  {"season": 5, "episode": 21, "scene": 26, "line": "You started on that rundown yet? [Looks at Jim's screen.]", "order": 27260, "speaker": "Charles Miner"},
  {"season": 5, "episode": 21, "scene": 26, "line": "Oh, this is just something I'm taking a break with.", "order": 27261, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 21, "scene": 26, "line": "Oh.", "order": 27262, "speaker": "Charles Miner"},
  {"season": 5, "episode": 21, "scene": 26, "line": "I will get back to the rundown, uh, right now.", "order": 27263, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 21, "scene": 26, "line": "Okay, great.", "order": 27264, "speaker": "Charles Miner"},
  {"season": 5, "episode": 21, "scene": 26, "line": "Hey you know what? Do you have a rundown that I could take a look at, just so I know what type of rundown you're looking for ?", "order": 27265, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 21, "scene": 26, "line": "Just keep it simple.", "order": 27266, "speaker": "Charles Miner"},
  {"season": 5, "episode": 21, "scene": 26, "line": "Keeping it simple -that's what I'm doing. But I am working hard on this one. Real hard.", "order": 27267, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 21, "scene": 26, "line": "You're working hard? On this?", "order": 27268, "speaker": "Charles Miner"},
  {"season": 5, "episode": 21, "scene": 26, "line": "No. Not too hard. Not harder than I should.", "order": 27269, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 21, "scene": 26, "line": "Right. I mean why work harder than you should.", "order": 27270, "speaker": "Charles Miner"},
  {"season": 5, "episode": 21, "scene": 26, "line": "No, I....", "order": 27271, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 21, "scene": 27, "line": "Can I help you?", "order": 27272, "speaker": "Erin Hannon"},
  {"season": 5, "episode": 21, "scene": 27, "line": "No thanks.", "order": 27273, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 21, "scene": 27, "line": "Hey!", "order": 27274, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 21, "scene": 27, "line": "Hey I'm here to see Charles.", "order": 27275, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 21, "scene": 27, "line": "Okay.", "order": 27276, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 21, "scene": 27, "line": "Hey Charles.", "order": 27277, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 21, "scene": 27, "line": "Hey Pam.", "order": 27278, "speaker": "Charles Miner"},
  {"season": 5, "episode": 21, "scene": 27, "line": "I know you're a very busy man so I'll cut right to the chase. I'd like my old job back.", "order": 27279, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 21, "scene": 27, "line": "I don't know what to tell you. The job's been taken.", "order": 27280, "speaker": "Charles Miner"},
  {"season": 5, "episode": 21, "scene": 27, "line": "Um, well, I could come back as a salesman. I have experience now.", "order": 27281, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 21, "scene": 27, "line": "Um.", "order": 27282, "speaker": "Charles Miner"},
  {"season": 5, "episode": 21, "scene": 27, "line": "Or I could come back as your personal assistant. You know? Sort your mail, set your appointments. I know all the people.", "order": 27283, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 21, "scene": 27, "line": "Yeah I know.", "order": 27284, "speaker": "Charles Miner"},
  {"season": 5, "episode": 21, "scene": 27, "line": "Personal shopper?", "order": 27285, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 21, "scene": 27, "line": "No.", "order": 27286, "speaker": "Charles Miner"},
  {"season": 5, "episode": 21, "scene": 27, "line": "Well it was great catching up with you. And I'll see you around the building.", "order": 27287, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 21, "scene": 27, "line": "Okay.", "order": 27288, "speaker": "Charles Miner"},
  {"season": 5, "episode": 21, "scene": 28, "line": "I'm just gonna sit here for a little bit longer if that's okay. The air smells so good. I don't remember it smelling so good. The chairs are so comfortable.", "order": 27289, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 21, "scene": 29, "line": "Oh hey! Stanley, Phyllis. Come on in. Welcome, welcome. Let me give you the tour.", "order": 27290, "speaker": "Michael Scott"},
  {"season": 5, "episode": 21, "scene": 29, "line": "I get it. [To Phyllis] You need to see more?", "order": 27291, "speaker": "Stanley Hudson"},
  {"season": 5, "episode": 21, "scene": 29, "line": "It's really cute.", "order": 27292, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 21, "scene": 29, "line": "Thank you. You remember Ryan [Stanley laughs deeply] And Pam is around here somewhere.", "order": 27293, "speaker": "Michael Scott"},
  {"season": 5, "episode": 21, "scene": 29, "line": "She's upstairs talking to Charles.", "order": 27294, "speaker": "Stanley Hudson"},
  {"season": 5, "episode": 21, "scene": 29, "line": "What for?", "order": 27295, "speaker": "Michael Scott"},
  {"season": 5, "episode": 21, "scene": 29, "line": "I don't know. [They shrug and leave.]", "order": 27296, "speaker": "Stanley Hudson"},
  {"season": 5, "episode": 21, "scene": 29, "line": "Are we staying til five?", "order": 27297, "speaker": "Ryan Howard"},
  {"season": 5, "episode": 21, "scene": 29, "line": "Yes.", "order": 27298, "speaker": "Michael Scott"},
  {"season": 5, "episode": 21, "scene": 30, "line": "Maybe the Michael Scott Paper Company was a huge mistake. I should leave. I should go, and start my own paper company. That'll show 'em.", "order": 27299, "speaker": "Michael Scott"},
  {"season": 5, "episode": 21, "scene": 31, "line": "Get your free pancakes. They are delicious. They are nutritious. They are complimentary. [Flips a pancake. It's about 8x11 inches] Young sir, would you like a free pancake?", "order": 27300, "speaker": "Michael Scott"},
  {"season": 5, "episode": 21, "scene": 31, "line": "I'm texting. I don't want to get my fingers sticky.", "order": 27301, "speaker": "Ryan Howard"},
  {"season": 5, "episode": 21, "scene": 31, "line": "Ryan, enough with the texting machine. Come on. And tuck in your shirt, you're the face of this company.", "order": 27302, "speaker": "Michael Scott"},
  {"season": 5, "episode": 21, "scene": 31, "line": "Hey Pam.", "order": 27303, "speaker": "Michael Scott"},
  {"season": 5, "episode": 21, "scene": 31, "line": "Hey.", "order": 27304, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 21, "scene": 31, "line": "You sorta disappeared there for a while. Where ya been?", "order": 27305, "speaker": "Michael Scott"},
  {"season": 5, "episode": 21, "scene": 31, "line": "Yeah, I needed to take care of some stuff.", "order": 27306, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 21, "scene": 31, "line": "Yeah?", "order": 27307, "speaker": "Michael Scott"},
  {"season": 5, "episode": 21, "scene": 31, "line": "That's as specific as I'd like to be.", "order": 27308, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 21, "scene": 31, "line": "Well, at least you're still being honest with me.", "order": 27309, "speaker": "Michael Scott"},
  {"season": 5, "episode": 21, "scene": 32, "line": "There's the rundown you asked for. I may have expanded some areas that you weren't prepared for.", "order": 27310, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 21, "scene": 32, "line": "Great. Fax that to everyone on the distribution list.", "order": 27311, "speaker": "Charles Miner"},
  {"season": 5, "episode": 21, "scene": 32, "line": "Yeah sure. You want to look at it first?", "order": 27312, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 21, "scene": 32, "line": "Do I need to?", "order": 27313, "speaker": "Charles Miner"},
  {"season": 5, "episode": 21, "scene": 32, "line": "No. No, I just wanted to make sure, it was in the same format. So that distribution list is gonna be my...?", "order": 27314, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 21, "scene": 32, "line": "What's that?", "order": 27315, "speaker": "Charles Miner"},
  {"season": 5, "episode": 21, "scene": 32, "line": "The one I have. I'll use the one I have.", "order": 27316, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 21, "scene": 33, "line": "Just faxing. My Dad. A rundown.", "order": 27317, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 21, "scene": 34, "line": "We're small, but we're eager to make a name for ourselves.", "order": 27318, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 21, "scene": 34, "line": "Do you have a card?", "order": 27319, "speaker": "Man"},
  {"season": 5, "episode": 21, "scene": 34, "line": "Nope, I don't have a card, but I'll do you one better. A little scrap of paper.", "order": 27320, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 21, "scene": 35, "line": "These are terrible boss. You gotta make them in a circle so that they cook evenly.", "order": 27321, "speaker": "Creed Bratton"},
  {"season": 5, "episode": 21, "scene": 35, "line": "These are shaped like paper.", "order": 27322, "speaker": "Michael Scott"},
  {"season": 5, "episode": 21, "scene": 35, "line": "Well I don't even want these. [Takes the rectangle pancakes out of his coat.]", "order": 27323, "speaker": "Creed Bratton"},
  {"season": 5, "episode": 21, "scene": 35, "line": "I'll take them for my kid.", "order": 27324, "speaker": "Meredith Palmer"},
  {"season": 5, "episode": 21, "scene": 36, "line": "Country roads, take me home, to the place...[Dwight is strumming the tune on his guitar] Sorry. I like that song. You're good!", "order": 27325, "speaker": "Erin Hannon"},
  {"season": 5, "episode": 21, "scene": 36, "line": "You're good.", "order": 27326, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 21, "scene": 36, "line": "Thank you.", "order": 27327, "speaker": "Erin Hannon"},
  {"season": 5, "episode": 21, "scene": 36, "line": "That was great, but it's just sort of, it's still a little choppy like. But don't worry, it's hard, it took me a while too. It's like [Andy plays Country Roads on his banjo.]", "order": 27328, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 21, "scene": 36, "line": "Wow!", "order": 27329, "speaker": "Erin Hannon"},
  {"season": 5, "episode": 21, "scene": 36, "line": "What? Oh my God you heard that I'm so embarrassed. I'm like so rusty.", "order": 27330, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 21, "scene": 36, "line": "Oh it's good, you're coming along. It's really technically proficient but really there's no heart or soul in it.", "order": 27331, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 21, "scene": 36, "line": "Really?", "order": 27332, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 21, "scene": 36, "line": "Hey you want to sing with me? Almost heaven...", "order": 27333, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 21, "scene": 36, "line": "West Virginia, Blue Ridge mountain, Shenandoah River [Andy vocalizes]", "order": 27334, "speaker": "Dwight Schrute & Erin Hannon"},
  {"season": 5, "episode": 21, "scene": 36, "line": "In German! Leben ist dort alt, alter als die Baume, Junger als die Berge, wie eine Brise blasend", "order": 27335, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 21, "scene": 36, "line": "Take me home, country roads, to the place, I belong. West Virginia, mountain momma, take me home, country roads. [Erin leaves]", "order": 27336, "speaker": "Dwight Schrute & Andy Bernard"},
  {"season": 5, "episode": 21, "scene": 36, "line": "Take it Andy! Take me home, to the place I belong, Ba bah dah duh", "order": 27337, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 21, "scene": 36, "line": "You have to stop. [bangs on glass window]", "order": 27338, "speaker": "Toby Flenderson"},
  {"season": 5, "episode": 21, "scene": 38, "line": "I once had a dream. That I was eating a peanut butter and tuna fish sandwich. And, let me tell you something it was delicious. So the next day, I decided to make that sandwich. And in real life it is disgusting. [The phone rings] It is a disgusting sandwich. [It rings again.] And the reason I tell you this story. [phone] Pick up the phone.", "order": 27339, "speaker": "Michael Scott"},
  {"season": 5, "episode": 21, "scene": 38, "line": "Dunder Miff...Michael Scott Paper Company, this is Pam. Oh, hi Russell from the pancake luncheon, how are you? Well we'd like to do business with you too! How can we make that happen?", "order": 27340, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 21, "scene": 38, "line": "Keep going. [Whispers]", "order": 27341, "speaker": "Ryan Howard"},
  {"season": 5, "episode": 21, "scene": 38, "line": "Don't tell them we have free delivery! [Also whispers]", "order": 27342, "speaker": "Michael Scott"},
  {"season": 5, "episode": 21, "scene": 38, "line": "We already offered free delivery!", "order": 27343, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 21, "scene": 38, "line": "They don't know that!", "order": 27344, "speaker": "Michael Scott"},
  {"season": 5, "episode": 21, "scene": 38, "line": "Um, I can offer you free delivery on any order that you place today. Okay, twenty boxes? I can do... just a second. I can do 20 boxes at 43 dollars a box. Great!", "order": 27345, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 21, "scene": 38, "line": "Write it down.", "order": 27346, "speaker": "Ryan Howard"},
  {"season": 5, "episode": 21, "scene": 38, "line": "Give him a guarantee.", "order": 27347, "speaker": "Michael Scott"},
  {"season": 5, "episode": 21, "scene": 38, "line": "A guarantee of what?", "order": 27348, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 21, "scene": 38, "line": "Just say the word.", "order": 27349, "speaker": "Michael Scott"},
  {"season": 5, "episode": 21, "scene": 38, "line": "And I guarantee, that you will be satisfied! 'Cause your satisfaction is our guarantee! We guarantee it. We look forward to doing business with you too. Thank you Russell.", "order": 27350, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 21, "scene": 38, "line": "Yes!", "order": 27351, "speaker": "Michael Scott"},
  {"season": 5, "episode": 21, "scene": 38, "line": "I made a sale!", "order": 27352, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 21, "scene": 38, "line": "Oh!", "order": 27353, "speaker": "Ryan Howard"},
  {"season": 5, "episode": 21, "scene": 38, "line": "You did!", "order": 27354, "speaker": "Michael Scott"},
  {"season": 5, "episode": 21, "scene": 38, "line": "Oh!", "order": 27355, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 21, "scene": 38, "line": "Oh yeah!", "order": 27356, "speaker": "Michael Scott"},
  {"season": 5, "episode": 21, "scene": 38, "line": "We did it !", "order": 27357, "speaker": "Ryan Howard"},
  {"season": 5, "episode": 21, "scene": 38, "line": "She did it!", "order": 27358, "speaker": "Michael Scott"},
  {"season": 5, "episode": 21, "scene": 38, "line": "Yeah! Yeah! Yeah! Yeah! [Dancing in the office]", "order": 27359, "speaker": "Michael Scott"},
  {"season": 5, "episode": 21, "scene": 39, "line": "Who would have thought, that the thing that would save this company would be work? And pancakes? In the end, this day definitely had its ups and downs. I realize that we don't have the biggest office. Which is a surprise, because 165 square feet sounds like a lot. But, we have people with the biggest hearts. And I think for a small company that is really [flushing] Someone went to the bathroom. That is really what's important.", "order": 27360, "speaker": "Michael Scott"},
  {"season": 5, "episode": 21, "scene": 40, "line": "Literally every song is better a cappella. Name a song.", "order": 27361, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 21, "scene": 40, "line": "Cherry Pie, Warrant.", "order": 27362, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 21, "scene": 40, "line": "Better a cappella!", "order": 27363, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 21, "scene": 40, "line": "No! Really?", "order": 27364, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 21, "scene": 40, "line": "Yeah. Name another.", "order": 27365, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 21, "scene": 40, "line": "Enter Sandman, Metallica.", "order": 27366, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 21, "scene": 40, "line": "Better a cappella.", "order": 27367, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 21, "scene": 40, "line": "Rebel Yell, Billy Idol", "order": 27368, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 21, "scene": 40, "line": "Aw!! Way better a cappella", "order": 27369, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 21, "scene": 40, "line": "Really?", "order": 27370, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 21, "scene": 40, "line": "Yeah.", "order": 27371, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 22, "scene": 1, "line": "Hup! [throws cheese puff to Ryan who catches it in his mouth]", "order": 27372, "speaker": "Michael Scott"},
  {"season": 5, "episode": 22, "scene": 2, "line": "Things are a little slow here. And there's only so much cold-calling you can do in a day. Turns out there's no limit to the number of cheese puffs you can throw at someone's face.", "order": 27373, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 22, "scene": 2, "line": "Hup! [throws cheese puff to Pam who catches it in her mouth]", "order": 27374, "speaker": "Michael Scott"},
  {"season": 5, "episode": 22, "scene": 2, "line": "We're getting pretty good at it.", "order": 27375, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 22, "scene": 5, "line": "Andy put down a bunch of deposits on stuff for his wedding with Angela, but then she was sleeping with Dwight for... several years. Wait, no, that can't be right.", "order": 27376, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 22, "scene": 5, "line": "The timeline's messy.", "order": 27377, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 22, "scene": 5, "line": "Anyway, now we are going bargain hunting in the haunted graveyard of their love.", "order": 27378, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 22, "scene": 6, "line": "This is my solo. [stereo plays Andy imitating bass guitar, a cappella starts singing 'You Can Call Me Al']", "order": 27379, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 22, "scene": 6, "line": "I'm confused. Am I walking down the aisle to 'You Can Call Me Al?'", "order": 27380, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 22, "scene": 6, "line": "Trust me. You will not be walking. You will be boogie-ing.", "order": 27381, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 22, "scene": 6, "line": "I am extremely interested. So, how much will all of this cost?", "order": 27382, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 22, "scene": 6, "line": "Well, 12 guys, airfare, three nights in a hotel, food per diem, table up front for merch... $9,000.", "order": 27383, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 22, "scene": 6, "line": "I don't know. It seems like a lot for an a cappella group from a college we never went to.", "order": 27384, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 22, "scene": 6, "line": "Did you even hear the music I just played for you?", "order": 27385, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 22, "scene": 6, "line": "Mm-hmm.", "order": 27386, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 22, "scene": 7, "line": "What's wrong with you?", "order": 27387, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 22, "scene": 7, "line": "These sleeves are cutting off my circulation. Not enough blood getting to my hands.", "order": 27388, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 22, "scene": 7, "line": "I think you look nice.", "order": 27389, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 22, "scene": 7, "line": "Doesn't Charles know he's compromising my attack readiness? It's not a dress code. It's a death sentence.", "order": 27390, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 22, "scene": 7, "line": "Looking good.", "order": 27391, "speaker": "Charles Miner"},
  {"season": 5, "episode": 22, "scene": 7, "line": "Kay, thank you. It's a straight jacket! [knocks things off shelf with arms] Okay.", "order": 27392, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 22, "scene": 8, "line": "When Michael was in charge, this place was like the Roman Empire. And the Wild West. And war-torn Poland. And Poland. There was just a lot going on, so what you wore to work was the least of anybody's worries. And in that chaos, I soared.", "order": 27393, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 22, "scene": 9, "line": "Hey.", "order": 27394, "speaker": "Michael Scott"},
  {"season": 5, "episode": 22, "scene": 9, "line": "Hey. Ed's Tires is thinking of making a change.", "order": 27395, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 22, "scene": 9, "line": "[gestures to 'Bed & Breakfast' magazine] Is this good?", "order": 27396, "speaker": "Michael Scott"},
  {"season": 5, "episode": 22, "scene": 9, "line": "They have some great kitchen ideas.", "order": 27397, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 22, "scene": 9, "line": "Oh. Okay. Ed's Tires, huh?", "order": 27398, "speaker": "Michael Scott"},
  {"season": 5, "episode": 22, "scene": 9, "line": "It's small, I know.", "order": 27399, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 22, "scene": 9, "line": "I really appreciate it.", "order": 27400, "speaker": "Michael Scott"},
  {"season": 5, "episode": 22, "scene": 9, "line": "Thanks, Michael. [Michael palms Dwight cash in their handshake] Wait, what is this?", "order": 27401, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 22, "scene": 9, "line": "It's for your trouble.", "order": 27402, "speaker": "Michael Scott"},
  {"season": 5, "episode": 22, "scene": 9, "line": "Wh- I don't need $6 to help a friend.", "order": 27403, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 22, "scene": 9, "line": "No, no, listen, as a friend, I want you to have this.", "order": 27404, "speaker": "Michael Scott"},
  {"season": 5, "episode": 22, "scene": 9, "line": "Michael, you know I can't take this.", "order": 27405, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 22, "scene": 9, "line": "Yes, I do.", "order": 27406, "speaker": "Michael Scott"},
  {"season": 5, "episode": 22, "scene": 9, "line": "But don't forget you owe me $10.", "order": 27407, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 22, "scene": 9, "line": "That was four years ago. Why don't you let it go?", "order": 27408, "speaker": "Michael Scott"},
  {"season": 5, "episode": 22, "scene": 9, "line": "Michael.", "order": 27409, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 22, "scene": 10, "line": "What was up with Pam being all pushy and negative in there?", "order": 27410, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 22, "scene": 10, "line": "I think she just didn't want a crucifix cake.", "order": 27411, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 22, "scene": 10, "line": "It scares me to see you going down a road that I went down.", "order": 27412, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 22, "scene": 10, "line": "Am I going down a road?", "order": 27413, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 22, "scene": 10, "line": "When I see her bossing you around like that, it just makes me wonder if this thing really has the legs to go the distance.", "order": 27414, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 22, "scene": 10, "line": "It's so scary how right the things you're saying are. And you're coming at it with almost no knowledge, so of course I trust your opinion on this.", "order": 27415, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 22, "scene": 11, "line": "I know a few things about love. Horrible, terrible, awful, awful things.", "order": 27416, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 22, "scene": 12, "line": "I was going to use today to purge my inbox, but now something much more pressing has come up.", "order": 27417, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 22, "scene": 13, "line": "Ed's Tires. Why don't you tell them that we have fewer clients, so we can spend more time with each of them. Also, try to discuss it over Indian food, and try to mention how you distrust women.", "order": 27418, "speaker": "Michael Scott"},
  {"season": 5, "episode": 22, "scene": 13, "line": "I'm not gonna do that.", "order": 27419, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 22, "scene": 13, "line": "That is smart. That would not seem genuine. Ryan?", "order": 27420, "speaker": "Michael Scott"},
  {"season": 5, "episode": 22, "scene": 13, "line": "I can get there.", "order": 27421, "speaker": "Ryan Howard"},
  {"season": 5, "episode": 22, "scene": 13, "line": "Good, you take the lead on this one. Also, do not forget that he has just gone through a messy divorce.", "order": 27422, "speaker": "Michael Scott"},
  {"season": 5, "episode": 22, "scene": 13, "line": "Oh, awesome.", "order": 27423, "speaker": "Ryan Howard"},
  {"season": 5, "episode": 22, "scene": 13, "line": "Bring it in. Morning cheer. [clears throat]", "order": 27424, "speaker": "Michael Scott"},
  {"season": 5, "episode": 22, "scene": 13, "line": "U-G-L-Y, you ain't got no alibi! You ugly, huh huh, you ugly! You mama says you ugly! Hey! Go Michael Scott Paper Company!", "order": 27425, "speaker": "Michael Scott, Pam Beesly & Ryan Howard"},
  {"season": 5, "episode": 22, "scene": 14, "line": "I'm here. I'm a part of this now.", "order": 27426, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 22, "scene": 15, "line": "You needed to speak to me?", "order": 27427, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 22, "scene": 15, "line": "Dwight, take a seat.", "order": 27428, "speaker": "Charles Miner"},
  {"season": 5, "episode": 22, "scene": 15, "line": "I prefer to stand. Less blood clots.", "order": 27429, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 22, "scene": 15, "line": "Nah, that's weird. You're gonna sit. [Dwight sits] Great. You know, Dwight, it has been quite a transition for all of us. Are you happy with the way things have been runnin' lately?", "order": 27430, "speaker": "Charles Miner"},
  {"season": 5, "episode": 22, "scene": 15, "line": "Do you mean compared to the ways things ran with other bosses? Comparisons are hard.", "order": 27431, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 22, "scene": 15, "line": "I've just been impressed with your performance and I wanted to make sure good work doesn't go unnoticed.", "order": 27432, "speaker": "Charles Miner"},
  {"season": 5, "episode": 22, "scene": 15, "line": "Your concern is noted.", "order": 27433, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 22, "scene": 15, "line": "Yeah, I like your work ethic. You're so... focused.", "order": 27434, "speaker": "Charles Miner"},
  {"season": 5, "episode": 22, "scene": 15, "line": "Like a wolf. Thank you.", "order": 27435, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 22, "scene": 15, "line": "And I wanna start givin' you more responsibility. What do you say you and I go out for a drink this week?", "order": 27436, "speaker": "Charles Miner"},
  {"season": 5, "episode": 22, "scene": 15, "line": "Really?", "order": 27437, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 22, "scene": 15, "line": "Definitely.", "order": 27438, "speaker": "Charles Miner"},
  {"season": 5, "episode": 22, "scene": 15, "line": "[sighs] It's firm.", "order": 27439, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 22, "scene": 16, "line": "[on phone] I need you to get me the prices that you're charging Ed's tires so I can undercut Dunder Mifflin.", "order": 27440, "speaker": "Michael Scott"},
  {"season": 5, "episode": 22, "scene": 16, "line": "I don't know that I can do that now. You know, uh, something's come up.", "order": 27441, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 22, "scene": 16, "line": "Oh n- Is it Mose? Did you put the cover on that well?", "order": 27442, "speaker": "Michael Scott"},
  {"season": 5, "episode": 22, "scene": 16, "line": "No, Mose is fine. I roped it off. It's not about Mose. Listen, things are changing here, Michael, they're changing fast.", "order": 27443, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 22, "scene": 16, "line": "I'm not following you.", "order": 27444, "speaker": "Michael Scott"},
  {"season": 5, "episode": 22, "scene": 16, "line": "Imagine... Someone has a personal hero they really wanna help. But then there's this new guy. Very cool, very Will Smith-esque, who would not like it if he helped his hero.", "order": 27445, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 22, "scene": 16, "line": "Personal hero, cool new guy. Okay, I think I'm getting your drift.", "order": 27446, "speaker": "Michael Scott"},
  {"season": 5, "episode": 22, "scene": 16, "line": "Good, do you see what I'm saying?", "order": 27447, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 22, "scene": 16, "line": "Crystal clear. So is this for a movie that you're writing?", "order": 27448, "speaker": "Michael Scott"},
  {"season": 5, "episode": 22, "scene": 16, "line": "No.", "order": 27449, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 22, "scene": 16, "line": "Can I use it? [Pam holds up note saying 'He's talking about you!']", "order": 27450, "speaker": "Michael Scott"},
  {"season": 5, "episode": 22, "scene": 16, "line": "No.", "order": 27451, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 22, "scene": 16, "line": "[Michael dismisses note, Ryan and Pam point to notepad] Dwight, are you talking about us?", "order": 27452, "speaker": "Michael Scott"},
  {"season": 5, "episode": 22, "scene": 16, "line": "It is possible that I could be talking about us.", "order": 27453, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 22, "scene": 16, "line": "Someone could say that it is like the situation that we are in now?", "order": 27454, "speaker": "Michael Scott"},
  {"season": 5, "episode": 22, "scene": 16, "line": "It is the situation that we are in now.", "order": 27455, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 22, "scene": 16, "line": "So I would say that the old boss has always been good to Dwight, and he was there first, so he has dibs. You respect dibs, don't you?", "order": 27456, "speaker": "Michael Scott"},
  {"season": 5, "episode": 22, "scene": 16, "line": "I'm not a barbarian.", "order": 27457, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 22, "scene": 16, "line": "Good. Will you meet me in 20 minutes at the spot?", "order": 27458, "speaker": "Michael Scott"},
  {"season": 5, "episode": 22, "scene": 16, "line": "I will.", "order": 27459, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 22, "scene": 16, "line": "Dwight?", "order": 27460, "speaker": "Michael Scott"},
  {"season": 5, "episode": 22, "scene": 16, "line": "Yes?", "order": 27461, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 22, "scene": 16, "line": "Is the cool new guy Charles?", "order": 27462, "speaker": "Michael Scott"},
  {"season": 5, "episode": 22, "scene": 16, "line": "I've said too much.", "order": 27463, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 22, "scene": 16, "line": "Is it Stanley?", "order": 27464, "speaker": "Michael Scott"},
  {"season": 5, "episode": 22, "scene": 17, "line": "Hey, Andy. You know I've been thinkin about what you said-", "order": 27465, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 22, "scene": 17, "line": "Noishe.'", "order": 27466, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 22, "scene": 17, "line": "-I just don't know if I can do it.", "order": 27467, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 22, "scene": 17, "line": "That's interesting, because I hear what you're saying is that you want to do it, which means you can do it. Believe me, I broke up with Angela, and I'm like, the happiest guy ever. I mean, I'm so happy. I'm so happy. Like, total freedom, you know?", "order": 27468, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 22, "scene": 17, "line": "It's just that Pam gets me through the day, you know? I really rely on her. I'm pretty emotionally needy.", "order": 27469, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 22, "scene": 17, "line": "And you know what? I am here for you. Let me be your traveling pants. [imitates punching on Jim's fist] Ah, what'd you do that for?", "order": 27470, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 22, "scene": 17, "line": "[both laugh] You know that I was doin' this.", "order": 27471, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 22, "scene": 17, "line": "[fist pound each other] Totally.", "order": 27472, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 22, "scene": 17, "line": "Okay.", "order": 27473, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 22, "scene": 18, "line": "Hello, Dwight. What's with the shirt? Are you alright?", "order": 27474, "speaker": "Michael Scott"},
  {"season": 5, "episode": 22, "scene": 18, "line": "I'm sorry, Michael", "order": 27475, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 22, "scene": 18, "line": "[Charles comes from around corner] Hi, Michael.", "order": 27476, "speaker": "Charles Miner"},
  {"season": 5, "episode": 22, "scene": 18, "line": "Oh my God! Run! Run! It's a setup. Setup!", "order": 27477, "speaker": "Michael Scott"},
  {"season": 5, "episode": 22, "scene": 18, "line": "No, Mich-Michael.", "order": 27478, "speaker": "Charles Miner"},
  {"season": 5, "episode": 22, "scene": 18, "line": "Dwight, run!", "order": 27479, "speaker": "Michael Scott"},
  {"season": 5, "episode": 22, "scene": 18, "line": "Michael, no, let's be cool, ok?", "order": 27480, "speaker": "Charles Miner"},
  {"season": 5, "episode": 22, "scene": 18, "line": "You be cool.", "order": 27481, "speaker": "Michael Scott"},
  {"season": 5, "episode": 22, "scene": 18, "line": "Yes.", "order": 27482, "speaker": "Charles Miner"},
  {"season": 5, "episode": 22, "scene": 18, "line": "Just-what's going on?", "order": 27483, "speaker": "Michael Scott"},
  {"season": 5, "episode": 22, "scene": 18, "line": "We need to talk about our two companies, and how we should behave. Dwight tells me you've been pestering him for company info.", "order": 27484, "speaker": "Charles Miner"},
  {"season": 5, "episode": 22, "scene": 18, "line": "Mm-do... Dwight would not-", "order": 27485, "speaker": "Michael Scott"},
  {"season": 5, "episode": 22, "scene": 18, "line": "He did.", "order": 27486, "speaker": "Charles Miner"},
  {"season": 5, "episode": 22, "scene": 18, "line": "I did, Michael. I was upset about the shirt sleeves at first, but now I'm okay with it.", "order": 27487, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 22, "scene": 18, "line": "Michael, I want you to stop pestering my salesmen, and I want you to leave Dunder Mifflin alone. Do you understand?", "order": 27488, "speaker": "Charles Miner"},
  {"season": 5, "episode": 22, "scene": 18, "line": "I. Understand. Nothing.", "order": 27489, "speaker": "Michael Scott"},
  {"season": 5, "episode": 22, "scene": 19, "line": "[Michael storms into office] Wow.", "order": 27490, "speaker": "Michael Scott"},
  {"season": 5, "episode": 22, "scene": 19, "line": "Michael, are you alright?", "order": 27491, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 22, "scene": 19, "line": "It was a setup. Dwight told Charles. He told him.", "order": 27492, "speaker": "Michael Scott"},
  {"season": 5, "episode": 22, "scene": 19, "line": "Tell us what you're talking about.", "order": 27493, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 22, "scene": 19, "line": "Yeah.", "order": 27494, "speaker": "Ryan Howard"},
  {"season": 5, "episode": 22, "scene": 19, "line": "It's like, a girl says she'll make out with you, but then her boyfriend is waiting around the corner with a pee-filled balloon.", "order": 27495, "speaker": "Michael Scott"},
  {"season": 5, "episode": 22, "scene": 19, "line": "We can't help you if you don't just tell us what happened.", "order": 27496, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 22, "scene": 19, "line": "I got hit in the face with a pee-filled water balloon, Pam, ok? I don't know how they did it. They filled the balloon with pee. A funnel? I don't know. Is that clear enough for you?", "order": 27497, "speaker": "Michael Scott"},
  {"season": 5, "episode": 22, "scene": 20, "line": "Hey, Jim.", "order": 27498, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 22, "scene": 20, "line": "[Jim slams lunchbag on table] I just totally blew a sales call.", "order": 27499, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 22, "scene": 20, "line": "Bro, I do that all the time.", "order": 27500, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 22, "scene": 20, "line": "Yeah, well, with you it's different, okay? Cause I just- I just suck. I just- I suck!", "order": 27501, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 22, "scene": 20, "line": "Tuna, be nice to my friend Jim, ok?", "order": 27502, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 22, "scene": 20, "line": "Why? When I look in the mirror, I don't like the face that looks back.", "order": 27503, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 22, "scene": 20, "line": "Well, so what? Your body's a ten.", "order": 27504, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 22, "scene": 20, "line": "Forget it.", "order": 27505, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 22, "scene": 20, "line": "Jim.", "order": 27506, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 22, "scene": 20, "line": "I said forget it. [drop kicks lunch across room, stomps on it]", "order": 27507, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 22, "scene": 21, "line": "[on phone] Dwight Schrute.", "order": 27508, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 22, "scene": 21, "line": "Hello, traitor.", "order": 27509, "speaker": "Michael Scott"},
  {"season": 5, "episode": 22, "scene": 21, "line": "I think you have the wrong number, Michael.", "order": 27510, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 22, "scene": 21, "line": "I want you to listen to me, friend, and I want you to listen to me good. I am going to come at you, and I am going to come at you hard. I am going to steal all of your clients, and then I am going to kill them in front of you.", "order": 27511, "speaker": "Michael Scott"},
  {"season": 5, "episode": 22, "scene": 21, "line": "Michael!", "order": 27512, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 22, "scene": 21, "line": "I'm just getting hardcore with him.", "order": 27513, "speaker": "Michael Scott"},
  {"season": 5, "episode": 22, "scene": 21, "line": "Finally.", "order": 27514, "speaker": "Ryan Howard"},
  {"season": 5, "episode": 22, "scene": 21, "line": "Yes, and hear me, Dwight, when I say I brought you into this world, and I can take you out. [hangs up] Bill Cosby.", "order": 27515, "speaker": "Michael Scott"},
  {"season": 5, "episode": 22, "scene": 22, "line": "[on phone] Mr. Schofield, please?", "order": 27516, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 22, "scene": 22, "line": "He's in a meeting.", "order": 27517, "speaker": "Secretary"},
  {"season": 5, "episode": 22, "scene": 22, "line": "Dammit! [hangs up]", "order": 27518, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 22, "scene": 23, "line": "[on phone] Is Mr. Schofield there?", "order": 27519, "speaker": "Michael Scott"},
  {"season": 5, "episode": 22, "scene": 23, "line": "If you'd like to reach an outside line, please dial nine first. [Michael sighs, hangs up phone]", "order": 27520, "speaker": "Automated phone voice"},
  {"season": 5, "episode": 22, "scene": 24, "line": "So, you think Michael's going after the whale, huh?", "order": 27521, "speaker": "Stanley Hudson"},
  {"season": 5, "episode": 22, "scene": 24, "line": "I have a long term relationship with Harper Collins publishers and Mr. Schofield. I'm not worried.", "order": 27522, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 22, "scene": 24, "line": "You sound worried.", "order": 27523, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 22, "scene": 24, "line": "And you have bad skin. Oh, look everyone, we're all making observations! [gibberish sounds]", "order": 27524, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 22, "scene": 25, "line": "[on phone] Well, if you could do me a big favor and tell Mr. Schofield that Michael Scott has tickets to the Wilkes-Barre Penguins game this weekend, and if he would like to join me... Hello, Daniel. How are you? Uh-huh. I sure do. Yes. [referencing rolodex card] Wanted to ask, did Kathy ever make JV?", "order": 27525, "speaker": "Michael Scott"},
  {"season": 5, "episode": 22, "scene": 25, "line": "Look at that old dude and his rolodex go.", "order": 27526, "speaker": "Ryan Howard"},
  {"season": 5, "episode": 22, "scene": 25, "line": "I spent a month putting that rolodex on his Blackberry, which he now uses as a nightlight.", "order": 27527, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 22, "scene": 25, "line": "Wow. High score?", "order": 27528, "speaker": "Michael Scott"},
  {"season": 5, "episode": 22, "scene": 26, "line": "And no, I cannot lower my current prices. [phone rings] Hold on. Hello? Mr. Schofield, thank you so much for taking the time to talk. I wanted to discuss your contract with us- oh, you're considering him. I thought Michael Scott left the paper business after his nervous breakdown.", "order": 27529, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 22, "scene": 27, "line": "Michael has been talking to my biggest client. Master and apprentice pitted against one another for the fate of the greater Scranton area paper market. So it's not exactly like 'Highlander,' but still...", "order": 27530, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 22, "scene": 28, "line": "Hello, Dwight, I've been expecting your call. What do you want?", "order": 27531, "speaker": "Michael Scott"},
  {"season": 5, "episode": 22, "scene": 28, "line": "I would like to arrange a truce.", "order": 27532, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 22, "scene": 28, "line": "So you heard Schofield is considering a switch, and you want mercy?", "order": 27533, "speaker": "Michael Scott"},
  {"season": 5, "episode": 22, "scene": 28, "line": "Meet me in our spot in four minutes.", "order": 27534, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 22, "scene": 28, "line": "No. No. You think I am going to fall for that? There's no-", "order": 27535, "speaker": "Michael Scott"},
  {"season": 5, "episode": 22, "scene": 28, "line": "Michael.", "order": 27536, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 22, "scene": 28, "line": "Meet- Ok, go to the spot and then walk 100 feet.", "order": 27537, "speaker": "Michael Scott"},
  {"season": 5, "episode": 22, "scene": 28, "line": "In which direction?", "order": 27538, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 22, "scene": 28, "line": "Toward the sun.", "order": 27539, "speaker": "Michael Scott"},
  {"season": 5, "episode": 22, "scene": 28, "line": "At what time?", "order": 27540, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 22, "scene": 28, "line": "Noon.", "order": 27541, "speaker": "Michael Scott"},
  {"season": 5, "episode": 22, "scene": 28, "line": "That-", "order": 27542, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 22, "scene": 28, "line": "You have two seconds. [Dwight starts running]", "order": 27543, "speaker": "Michael Scott"},
  {"season": 5, "episode": 22, "scene": 29, "line": "[looking at his watch] Michael. [Madge walks by] Sorry.", "order": 27544, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 22, "scene": 29, "line": "Dwight.", "order": 27545, "speaker": "Michael Scott"},
  {"season": 5, "episode": 22, "scene": 29, "line": "Michael.", "order": 27546, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 22, "scene": 29, "line": "I hope you're not recording this conversation. [Dwight drops his pants and lifts up his shirt] Good. I cannot believe that you sided with Charles.", "order": 27547, "speaker": "Michael Scott"},
  {"season": 5, "episode": 22, "scene": 29, "line": "You were making me do things that were not all right.", "order": 27548, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 22, "scene": 29, "line": "So you just rat me out? You could have said no.", "order": 27549, "speaker": "Michael Scott"},
  {"season": 5, "episode": 22, "scene": 29, "line": "And not come through for you?", "order": 27550, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 22, "scene": 29, "line": "If you want a truce, I will give you a truce.", "order": 27551, "speaker": "Michael Scott"},
  {"season": 5, "episode": 22, "scene": 29, "line": "I want a truce.", "order": 27552, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 22, "scene": 29, "line": "I do too.", "order": 27553, "speaker": "Michael Scott"},
  {"season": 5, "episode": 22, "scene": 29, "line": "Let me take you and your whole company out for lunch at Alfredo's.", "order": 27554, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 22, "scene": 29, "line": "Cooper's.", "order": 27555, "speaker": "Michael Scott"},
  {"season": 5, "episode": 22, "scene": 29, "line": "I had fish yesterday.", "order": 27556, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 22, "scene": 29, "line": "Damn it.", "order": 27557, "speaker": "Michael Scott"},
  {"season": 5, "episode": 22, "scene": 30, "line": "[Michael, Ryan and Pam sitting at restaurant, phone rings] Oh. Dwight-elicious. Where you at?", "order": 27558, "speaker": "Michael Scott"},
  {"season": 5, "episode": 22, "scene": 30, "line": "Oh, hi, Michael. I'm so sorry I'm late. I got stuck in traffic.", "order": 27559, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 22, "scene": 30, "line": "Really? That's weird. We didn't see any.", "order": 27560, "speaker": "Michael Scott"},
  {"season": 5, "episode": 22, "scene": 30, "line": "I hit a bear.", "order": 27561, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 22, "scene": 30, "line": "What?", "order": 27562, "speaker": "Michael Scott"},
  {"season": 5, "episode": 22, "scene": 30, "line": "He's technically fine. I imagine the true horror will be when he wakes up in a zoo. [throws a fish into the vent at Michael's office] Hey, listen, will you do me a favor and order the meatball parm for me, with extra cheese?", "order": 27563, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 22, "scene": 30, "line": "Yeah, sure.", "order": 27564, "speaker": "Michael Scott"},
  {"season": 5, "episode": 22, "scene": 30, "line": "Ok, I will see you very soon, alright? [takes all the items off Michael's desk and puts them into briefcase, including rolodex]", "order": 27565, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 22, "scene": 30, "line": "Okay, see you in a bit. Dwight hit a bear. He'll be here in a sec.", "order": 27566, "speaker": "Michael Scott"},
  {"season": 5, "episode": 22, "scene": 31, "line": "[Pam, Ryan and Michael enter office] Sounds neat.", "order": 27567, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 22, "scene": 31, "line": "We can spend a couple days there.", "order": 27568, "speaker": "Ryan Howard"},
  {"season": 5, "episode": 22, "scene": 31, "line": "Oh my God, I think we've been robbed!", "order": 27569, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 22, "scene": 31, "line": "[Michael answers phone] Yeah.", "order": 27570, "speaker": "Michael Scott"},
  {"season": 5, "episode": 22, "scene": 31, "line": "Did you enjoy your lunch?", "order": 27571, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 22, "scene": 31, "line": "Dwight, not now, we've been robbed.", "order": 27572, "speaker": "Michael Scott"},
  {"season": 5, "episode": 22, "scene": 31, "line": "No, Michael, you were sabotaged.", "order": 27573, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 22, "scene": 31, "line": "No, Dwight, we were robbed. How would you even know? You're still stuck in traffic. [Pam holds up sign saying 'Dwight did it!!'] You?", "order": 27574, "speaker": "Michael Scott"},
  {"season": 5, "episode": 22, "scene": 31, "line": "Me.", "order": 27575, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 22, "scene": 31, "line": "What about our truce?", "order": 27576, "speaker": "Michael Scott"},
  {"season": 5, "episode": 22, "scene": 31, "line": "I broke it.", "order": 27577, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 22, "scene": 31, "line": "On purpose?", "order": 27578, "speaker": "Michael Scott"},
  {"season": 5, "episode": 22, "scene": 31, "line": "Yes.", "order": 27579, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 22, "scene": 31, "line": "Why?", "order": 27580, "speaker": "Michael Scott"},
  {"season": 5, "episode": 22, "scene": 31, "line": "You think this is some kind of game? No, this is a war, and I will not stop, I will not rest. You have no idea what kind of enemy you've created. You have unleashed the wolf!", "order": 27581, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 22, "scene": 31, "line": "Be that as it may, I have your meatball parm sandwich here, and I am going to eat it.", "order": 27582, "speaker": "Michael Scott"},
  {"season": 5, "episode": 22, "scene": 31, "line": "And I knew that you would do that. The meatball parm is their worst sandwich!", "order": 27583, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 22, "scene": 31, "line": "Oh. [bites into sandwich, looks disgusted] Bastard!", "order": 27584, "speaker": "Michael Scott"},
  {"season": 5, "episode": 22, "scene": 32, "line": "Hi there. Dwight Schrute here. I was just calling to see if Michael Scott Paper was meeting all of your paper needs, and how is [reading off rolodex card] Brenda, age four, ponytail, and Simon, age 7? Oh, you don't say.", "order": 27585, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 22, "scene": 33, "line": "[holding up rolodex card] Schrute comma Dwight. And on the back he wrote, 'great salesman, better friend.' [turns card over] 'Tall' and 'beets.'", "order": 27586, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 22, "scene": 34, "line": "And say hello to Cheri, who is your black wife. [hangs up and answers cell phone] I see you're begging for mercy, huh? Well, you will find none here.", "order": 27587, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 22, "scene": 34, "line": "Dwight, do you mind if we talk?", "order": 27588, "speaker": "Michael Scott"},
  {"season": 5, "episode": 22, "scene": 34, "line": "Sure. That'd be fine.", "order": 27589, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 22, "scene": 34, "line": "If you keep coming after us, the Michael Scott Paper Company cannot succeed.", "order": 27590, "speaker": "Michael Scott"},
  {"season": 5, "episode": 22, "scene": 34, "line": "This is war and that is what happens.", "order": 27591, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 22, "scene": 34, "line": "Oh, one more thing. I'm going to have you listen while I steal your biggest client.", "order": 27592, "speaker": "Michael Scott"},
  {"season": 5, "episode": 22, "scene": 34, "line": "Oh, no. No. No. No. No.", "order": 27593, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 22, "scene": 34, "line": "Oh, uh oh, I'm turning you down right now.", "order": 27594, "speaker": "Michael Scott"},
  {"season": 5, "episode": 22, "scene": 34, "line": "Michael! Michael!", "order": 27595, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 22, "scene": 34, "line": "You can hear me, but I can't hear you.", "order": 27596, "speaker": "Michael Scott"},
  {"season": 5, "episode": 22, "scene": 34, "line": "Mr. Schofield's ready to see you now, Mr. Scott.", "order": 27597, "speaker": "Secretary"},
  {"season": 5, "episode": 22, "scene": 34, "line": "Oh, great. Mr. Schofield's ready to see me. Thank you so much.", "order": 27598, "speaker": "Michael Scott"},
  {"season": 5, "episode": 22, "scene": 34, "line": "Don't let him in! He's a traitor! Michael!", "order": 27599, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 22, "scene": 34, "line": "Walking in the door...", "order": 27600, "speaker": "Michael Scott"},
  {"season": 5, "episode": 22, "scene": 34, "line": "Michael, good to see you.", "order": 27601, "speaker": "Mr. Schofield"},
  {"season": 5, "episode": 22, "scene": 34, "line": "Mr. Schofield, good to see you. And I'm closing the door. [Dwight runs out of the office]", "order": 27602, "speaker": "Michael Scott"},
  {"season": 5, "episode": 22, "scene": 35, "line": "[on speakerphone in Dwight's car] Now, when Dwight tells you that he will keep prices steady for a year, I think he is speaking out of turn.", "order": 27603, "speaker": "Michael Scott"},
  {"season": 5, "episode": 22, "scene": 35, "line": "Really?", "order": 27604, "speaker": "Mr. Schofield"},
  {"season": 5, "episode": 22, "scene": 35, "line": "Yes. He does not have the authority to say that. I, on the other hand, am the president, the owner, and the founder. It is like you are buying software from Bill Gates.", "order": 27605, "speaker": "Michael Scott"},
  {"season": 5, "episode": 22, "scene": 35, "line": "Are you saying you invented paper?", "order": 27606, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 22, "scene": 36, "line": "[hugging a crying Jim] Okay, okay.", "order": 27607, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 22, "scene": 36, "line": "Oh, God.", "order": 27608, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 22, "scene": 36, "line": "Okay, Tuna.", "order": 27609, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 22, "scene": 36, "line": "Hey, guys.", "order": 27610, "speaker": "Kelly Kapoor"},
  {"season": 5, "episode": 22, "scene": 36, "line": "Hey.", "order": 27611, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 22, "scene": 36, "line": "Hello.", "order": 27612, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 22, "scene": 37, "line": "Excuse me, can I have your attention, everyone? Here's the deal, everybody, Jim Halpert is very upset and disturbed. I don't know if it was something you did, something you said, a look you gave him, maybe it was nothing at all, but here's the deal, ok? It stops now.", "order": 27613, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 22, "scene": 37, "line": "I guess I could be nicer.", "order": 27614, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 22, "scene": 37, "line": "Andy, I think Jim is messing with you.", "order": 27615, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 22, "scene": 37, "line": "Oh, really?", "order": 27616, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 22, "scene": 37, "line": "Mm-hmm. [looks over to Jim smiling through windows to the kitchen]", "order": 27617, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 22, "scene": 38, "line": "Okay.", "order": 27618, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 22, "scene": 38, "line": "Yeah, okay, what the heck is happennin' here?", "order": 27619, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 22, "scene": 38, "line": "Two things I need you to understand. One, Pam and I are very happy together.", "order": 27620, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 22, "scene": 38, "line": "Uh, that's not what was-", "order": 27621, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 22, "scene": 38, "line": "And two, that stuff that happened with you and Angela is a bummer, and I know you don't think you're ever gonna find someone else, but you will. I promise you, you will.", "order": 27622, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 22, "scene": 39, "line": "Oh man, he got me so good. I learned something about myself today. Yeah. I wish this was a sofa, cause I feel like I could sit here and talk for hours.", "order": 27623, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 22, "scene": 40, "line": "What is that thing that Dwight always says? Paper is the soil in which the seeds of business grow?", "order": 27624, "speaker": "Michael Scott"},
  {"season": 5, "episode": 22, "scene": 40, "line": "It's not the soil! It's the manure! Paper is the manure! On-time delivery is the soil! Aah! [runs into office]", "order": 27625, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 22, "scene": 40, "line": "Uh, hello, Dwight.", "order": 27626, "speaker": "Secretary"},
  {"season": 5, "episode": 22, "scene": 40, "line": "Spin move.", "order": 27627, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 22, "scene": 40, "line": "Oh-", "order": 27628, "speaker": "Secretary"},
  {"season": 5, "episode": 22, "scene": 40, "line": "Ha ha! April 13th, 2002.", "order": 27629, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 22, "scene": 40, "line": "Dwight, I'm in a meeting.", "order": 27630, "speaker": "Mr. Schofield"},
  {"season": 5, "episode": 22, "scene": 40, "line": "That's very rude.", "order": 27631, "speaker": "Michael Scott"},
  {"season": 5, "episode": 22, "scene": 40, "line": "I barge because I care. April 13th, 2002, that is the date when you tried to switch paper providers for an obscure sociology textbook, but were hung out to dry when the price of glossy stock increased.", "order": 27632, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 22, "scene": 40, "line": "Maybe we should schedule a meeting on our-", "order": 27633, "speaker": "Mr. Schofield"},
  {"season": 5, "episode": 22, "scene": 40, "line": "La la la! Continuing. Notice my persistence and recall. Continuing! You called Dunder Mifflin, and your order was filled within an hour!", "order": 27634, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 22, "scene": 40, "line": "I'm going to pull a date out of the air right now. April 13th, 2002. That is the last day that you evaluated your paper needs. Is it not? We all know that the economy is bad, and bloated companies like Dunder Mifflin...", "order": 27635, "speaker": "Michael Scott"},
  {"season": 5, "episode": 22, "scene": 40, "line": "Come on.", "order": 27636, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 22, "scene": 40, "line": "Are going to fall by the wayside. Two of their branches have closed within the last year. The Michael Scott Paper Company, however, has opened a new branch this very month.", "order": 27637, "speaker": "Michael Scott"},
  {"season": 5, "episode": 22, "scene": 40, "line": "What he's not telling you is that he will abandon you.", "order": 27638, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 22, "scene": 40, "line": "Why don't you guys just e-mail me your best offers and we can finish it up that way?", "order": 27639, "speaker": "Mr. Schofield"},
  {"season": 5, "episode": 22, "scene": 40, "line": "That sounds like a fantastic idea. I will see you this weekend for the Penguins. Box seats as usual.", "order": 27640, "speaker": "Michael Scott"},
  {"season": 5, "episode": 22, "scene": 40, "line": "Uh, ok, sure.", "order": 27641, "speaker": "Mr. Schofield"},
  {"season": 5, "episode": 22, "scene": 40, "line": "Good, good, good. I will see you.", "order": 27642, "speaker": "Michael Scott"},
  {"season": 5, "episode": 22, "scene": 40, "line": "Thank you, Mr. Schofield, for your time. Much appreciated. Oh, and tell me, um, how's your gay son?", "order": 27643, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 22, "scene": 40, "line": "Excuse me?", "order": 27644, "speaker": "Mr. Schofield"},
  {"season": 5, "episode": 22, "scene": 41, "line": "I color code all my info. I wrote 'gay son' in green. Green means go, so I know to go ahead and shut up about it. Orange means 'Orange you glad you didn't bring it up?' Most colors mean 'Don't say it.'", "order": 27645, "speaker": "Michael Scott"},
  {"season": 5, "episode": 22, "scene": 42, "line": "How is Tom, the homosexual sophomore?", "order": 27646, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 22, "scene": 43, "line": "I wanted to start a company, not a war. Because in a war, you always fight those you are closest to. And the great tragedy of the civil war is that brother fought against brother. For what? What purpose did that serve? Apart from abolishing slavery? In that case, war was the right choice. This doesn't feel as important though. That's just how the world works, I guess.", "order": 27647, "speaker": "Michael Scott"},
  {"season": 5, "episode": 22, "scene": 45, "line": "He gives me leads and I take the leads from him and in exchange it makes him feel good. And if a friend can't make you feel good, then I don't want any friends. Except Dwight, because he is our only source of fertile leads.", "order": 27648, "speaker": "Michael Scott"},
  {"season": 5, "episode": 22, "scene": 46, "line": "Dwight just got Howard Family Dry Cleaners.", "order": 27649, "speaker": "Michael Scott"},
  {"season": 5, "episode": 22, "scene": 46, "line": "Uncle Dave?", "order": 27650, "speaker": "Ryan Howard"},
  {"season": 5, "episode": 22, "scene": 46, "line": "He's undercutting us big time. He's actually taking a loss and paying the difference out of pocket.", "order": 27651, "speaker": "Michael Scott"},
  {"season": 5, "episode": 22, "scene": 46, "line": "That's my family. When you mess with my family, I can't be responsible for my actions.", "order": 27652, "speaker": "Ryan Howard"},
  {"season": 5, "episode": 22, "scene": 46, "line": "When are you ever responsible for your actions?", "order": 27653, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 22, "scene": 46, "line": "Get off my ass, you hag.", "order": 27654, "speaker": "Ryan Howard"},
  {"season": 5, "episode": 22, "scene": 46, "line": "Hey, hey, hey. Something about that seems inappropriate.", "order": 27655, "speaker": "Michael Scott"},
  {"season": 5, "episode": 22, "scene": 47, "line": "Betrayal ain't just a river in Egypt.", "order": 27656, "speaker": "Michael Scott"},
  {"season": 5, "episode": 22, "scene": 48, "line": "Who is that knocking on my taste buds? Why, it's Mr. Lemon. Hmmm, nice to meet you.", "order": 27657, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 22, "scene": 48, "line": "It's good. But won't it feel weird cutting into a cross?", "order": 27658, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 22, "scene": 48, "line": "Don't think of it as a cross in the Judeo-Christian sense, think of it more as a symbol of your lives crossing in marriage.", "order": 27659, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 22, "scene": 48, "line": "When I think of it like that, I do not think of the other connotations.", "order": 27660, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 22, "scene": 48, "line": "Yes!", "order": 27661, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 22, "scene": 48, "line": "I don't think so, Andy.", "order": 27662, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 22, "scene": 48, "line": "Sorry. Her day.", "order": 27663, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 22, "scene": 49, "line": "Hey, bud.", "order": 27664, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 22, "scene": 49, "line": "Are they looking at me?", "order": 27665, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 22, "scene": 49, "line": "Who?", "order": 27666, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 22, "scene": 49, "line": "Everyone. Everyone just keeps looking at me.", "order": 27667, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 22, "scene": 49, "line": "Are you sure?", "order": 27668, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 22, "scene": 49, "line": "Will you just talk to them, please? Pam just knew!", "order": 27669, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 23, "scene": 1, "line": "It's 4:30 in the morning. Do you know where your kids are? If you are Ryan's parents or Pam's parents or my parents, you do. They're gonna be in this van. With me. Who am I? Nothing to fear. I am just a 44 year old guy with a paper route.", "order": 27670, "speaker": "Michael Scott"},
  {"season": 5, "episode": 23, "scene": 2, "line": "[honking horn] Time to make the donuts. [laughs and drives away as Ryan tries to get in the van] Come on. Whoa! No, I promise I won't do it again. Come on. [does it again] De-nied!", "order": 27671, "speaker": "Michael Scott"},
  {"season": 5, "episode": 23, "scene": 3, "line": "We've been making 5:00 AM deliveries for a couple weeks now. Ever since I've gotten clean there's something about fresh morning air that... just really makes me sick.", "order": 27672, "speaker": "Ryan Howard"},
  {"season": 5, "episode": 23, "scene": 4, "line": "Hello! Time to make the donuts! Oh, Halpert! Whoa! Boner patrol. Arrest that man! Your donuts make me go nuts! [laughs] Hey Pam.", "order": 27673, "speaker": "Michael Scott"},
  {"season": 5, "episode": 23, "scene": 5, "line": "We got the van at a used car lot. We think it says 'Alleluia Church of Scranton.' in Korean. It was either this or an old school bus with an owl living in it.", "order": 27674, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 23, "scene": 6, "line": "You didn't happen to bring any coffee, did you, Michael?", "order": 27675, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 23, "scene": 6, "line": "Milk and sugar.", "order": 27676, "speaker": "Michael Scott"},
  {"season": 5, "episode": 23, "scene": 6, "line": "Oh, awesome. You're a life saver. [drinks from coffee cup] Wait, is this just milk and sugar?", "order": 27677, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 23, "scene": 6, "line": "That's what I said.", "order": 27678, "speaker": "Michael Scott"},
  {"season": 5, "episode": 23, "scene": 6, "line": "Do you drink this every day?", "order": 27679, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 23, "scene": 6, "line": "Every morning.", "order": 27680, "speaker": "Michael Scott"},
  {"season": 5, "episode": 23, "scene": 7, "line": "We're, uh, we're doing okay. A couple weeks in and, um, we're having fun. Uh, yep. We have 20-- of those.", "order": 27681, "speaker": "Michael Scott"},
  {"season": 5, "episode": 23, "scene": 7, "line": "[an Asian lady tries to enter the van] Oh, oh, excuse me! Sorry. Sorry, no. It's, uh, it's a paper company now. It's not for the church.", "order": 27682, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 23, "scene": 8, "line": "Okay, who covers Bans Pet Grooming?", "order": 27683, "speaker": "Charles Miner"},
  {"season": 5, "episode": 23, "scene": 8, "line": "Oh, they're my client.", "order": 27684, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 23, "scene": 8, "line": "No, they were your client. They just called and told us they're switching over to Michael Scott Paper Company.", "order": 27685, "speaker": "Charles Miner"},
  {"season": 5, "episode": 23, "scene": 8, "line": "[sighs] Shame, Jim. I expected more.", "order": 27686, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 23, "scene": 8, "line": "In the last month we have lost ten major clients to Michael Scott.", "order": 27687, "speaker": "Charles Miner"},
  {"season": 5, "episode": 23, "scene": 8, "line": "What are we supposed to do? They keep undercutting us on price.", "order": 27688, "speaker": "Stanley Hudson"},
  {"season": 5, "episode": 23, "scene": 8, "line": "I don't want to hear excuses. I want to see improvements. This is unacceptable.", "order": 27689, "speaker": "Charles Miner"},
  {"season": 5, "episode": 23, "scene": 8, "line": "Hey, Boss. Uh, I'd just like to point out that I have been here less time than these guys.", "order": 27690, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 23, "scene": 8, "line": "Why are you telling me this?", "order": 27691, "speaker": "Charles Miner"},
  {"season": 5, "episode": 23, "scene": 8, "line": "I just think the bar should be lower for a newbie.", "order": 27692, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 23, "scene": 8, "line": "Is this something you really want to have said?", "order": 27693, "speaker": "Charles Miner"},
  {"season": 5, "episode": 23, "scene": 8, "line": "I don't want to have said that. But I think it's important that you know it.", "order": 27694, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 23, "scene": 9, "line": "I don't know what to do to inspire these people. Okay, maybe it's my fault-", "order": 27695, "speaker": "Charles Miner"},
  {"season": 5, "episode": 23, "scene": 9, "line": "It's not your fault. Some people just don't want to be inspired.", "order": 27696, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 23, "scene": 9, "line": "I wrote a memo to all departments asking them to find ways to save money but, uh--", "order": 27697, "speaker": "Charles Miner"},
  {"season": 5, "episode": 23, "scene": 9, "line": "Charles, I got your memo. Thank you. I want you to know I'm putting my foot down when it comes to expense reports. Waste not, want not.", "order": 27698, "speaker": "Angela Martin"},
  {"season": 5, "episode": 23, "scene": 9, "line": "Uh, well said, Angela.", "order": 27699, "speaker": "Charles Miner"},
  {"season": 5, "episode": 23, "scene": 9, "line": "Been there, done that.", "order": 27700, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 23, "scene": 10, "line": "You know what we need? We need some couches in here.", "order": 27701, "speaker": "Michael Scott"},
  {"season": 5, "episode": 23, "scene": 10, "line": "Michael, we should really consider getting a delivery guy.", "order": 27702, "speaker": "Ryan Howard"},
  {"season": 5, "episode": 23, "scene": 10, "line": "Oh, you know what you would love? Is if we built a loft.", "order": 27703, "speaker": "Michael Scott"},
  {"season": 5, "episode": 23, "scene": 10, "line": "Why would I love that? Can we afford a delivery guy?", "order": 27704, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 23, "scene": 10, "line": "Like in a dorm room. You put your desk underneath, you have your loft up top. You can sleep up top.", "order": 27705, "speaker": "Michael Scott"},
  {"season": 5, "episode": 23, "scene": 10, "line": "Yeah, I know what a loft is.", "order": 27706, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 23, "scene": 10, "line": "Most dorm rooms don't even have that.", "order": 27707, "speaker": "Ryan Howard"},
  {"season": 5, "episode": 23, "scene": 10, "line": "Most do in the magazines.", "order": 27708, "speaker": "Michael Scott"},
  {"season": 5, "episode": 23, "scene": 10, "line": "Let's see what a delivery guy costs.", "order": 27709, "speaker": "Ryan Howard"},
  {"season": 5, "episode": 23, "scene": 10, "line": "We should look into that. Or we just go for the loft.", "order": 27710, "speaker": "Michael Scott"},
  {"season": 5, "episode": 23, "scene": 11, "line": "Would you let Charles know that David is here, please? Jim, hey.", "order": 27711, "speaker": "David Wallace"},
  {"season": 5, "episode": 23, "scene": 11, "line": "Hey, David.", "order": 27712, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 23, "scene": 11, "line": "David Wallace! Hello, we've been expecting you, David Wallace. Charles and I were waiting for you.", "order": 27713, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 23, "scene": 11, "line": "There he is. There he is. How was the trip up?", "order": 27714, "speaker": "Charles Miner"},
  {"season": 5, "episode": 23, "scene": 11, "line": "A lot better than a month at the Scranton Radisson, I'm sure.", "order": 27715, "speaker": "David Wallace"},
  {"season": 5, "episode": 23, "scene": 11, "line": "Oh, you know it hasn't been that bad. Hasn't been that bad. These people are the salt of the earth down here. You couldn't ask for a better way to learn a company.", "order": 27716, "speaker": "Charles Miner"},
  {"season": 5, "episode": 23, "scene": 11, "line": "Hmmm.", "order": 27717, "speaker": "David Wallace"},
  {"season": 5, "episode": 23, "scene": 11, "line": "I feel like I should be thanking you.", "order": 27718, "speaker": "Charles Miner"},
  {"season": 5, "episode": 23, "scene": 11, "line": "Uh.", "order": 27719, "speaker": "David Wallace"},
  {"season": 5, "episode": 23, "scene": 12, "line": "[makes kissing, puckering sound]", "order": 27720, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 23, "scene": 13, "line": "The conference room is ready if we want to get started.", "order": 27721, "speaker": "Charles Miner"},
  {"season": 5, "episode": 23, "scene": 13, "line": "You know, I just want to address everyone first.", "order": 27722, "speaker": "David Wallace"},
  {"season": 5, "episode": 23, "scene": 13, "line": "Oh yeah, take your time. Stanley, pay attention.", "order": 27723, "speaker": "Charles Miner"},
  {"season": 5, "episode": 23, "scene": 13, "line": "Hi, everyone.", "order": 27724, "speaker": "David Wallace"},
  {"season": 5, "episode": 23, "scene": 13, "line": "Hi.", "order": 27725, "speaker": "Kelly Kapoor"},
  {"season": 5, "episode": 23, "scene": 13, "line": "Hi. Uh, look it's no secret--", "order": 27726, "speaker": "David Wallace"},
  {"season": 5, "episode": 23, "scene": 13, "line": "Hi.", "order": 27727, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 23, "scene": 13, "line": "Hello. Uh, it is no secret that Michael Scott Paper has siphoned off a large chunk of our core business. And I'm here-- I just want to assure everyone that we think this is just a temporary setback.", "order": 27728, "speaker": "David Wallace"},
  {"season": 5, "episode": 23, "scene": 13, "line": "Right.", "order": 27729, "speaker": "Charles Miner"},
  {"season": 5, "episode": 23, "scene": 13, "line": "Okay?", "order": 27730, "speaker": "David Wallace"},
  {"season": 5, "episode": 23, "scene": 13, "line": "Maybe, and I don't know, if you had just returned Michael's call none of us would've lost clients.", "order": 27731, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 23, "scene": 13, "line": "I've been wondering that myself lately. We're just gonna get started, we're gonna figure this out. Rest assured. Jim, can you come in with us please?", "order": 27732, "speaker": "David Wallace"},
  {"season": 5, "episode": 23, "scene": 13, "line": "Uh, Jim hold on. [whispers] You know, David, uh, Dwight's been my guy. Okay?", "order": 27733, "speaker": "Charles Miner"},
  {"season": 5, "episode": 23, "scene": 13, "line": "Hmm.", "order": 27734, "speaker": "David Wallace"},
  {"season": 5, "episode": 23, "scene": 13, "line": "Yeah, Jim--", "order": 27735, "speaker": "Charles Miner"},
  {"season": 5, "episode": 23, "scene": 13, "line": "I find that extraordinarily surprising.", "order": 27736, "speaker": "David Wallace"},
  {"season": 5, "episode": 23, "scene": 13, "line": "He shows promise and Jim, I don't know-- I-- he's been a disappointment.", "order": 27737, "speaker": "Charles Miner"},
  {"season": 5, "episode": 23, "scene": 13, "line": "We'll bring them both in.", "order": 27738, "speaker": "David Wallace"},
  {"season": 5, "episode": 23, "scene": 13, "line": "Okay, great. Uh, Dwight come on in. Also, Jim.", "order": 27739, "speaker": "Charles Miner"},
  {"season": 5, "episode": 23, "scene": 13, "line": "Come along, afterthought.", "order": 27740, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 23, "scene": 14, "line": "How much can we afford to pay a delivery guy?", "order": 27741, "speaker": "Michael Scott"},
  {"season": 5, "episode": 23, "scene": 14, "line": "Well, if these numbers you gave me are correct--", "order": 27742, "speaker": "Financial Guy"},
  {"season": 5, "episode": 23, "scene": 14, "line": "They are correct, sir.", "order": 27743, "speaker": "Michael Scott"},
  {"season": 5, "episode": 23, "scene": 14, "line": "Then you can't afford to pay him anything.", "order": 27744, "speaker": "Financial Guy"},
  {"season": 5, "episode": 23, "scene": 14, "line": "Okay. A lame attempt at humor. Swing and a miss.", "order": 27745, "speaker": "Michael Scott"},
  {"season": 5, "episode": 23, "scene": 14, "line": "Your prices are too low.", "order": 27746, "speaker": "Financial Guy"},
  {"season": 5, "episode": 23, "scene": 14, "line": "Lowest in town.", "order": 27747, "speaker": "Michael Scott"},
  {"season": 5, "episode": 23, "scene": 14, "line": "Why do you think Staples and Dunder Mifflin can't match your prices?", "order": 27748, "speaker": "Financial Guy"},
  {"season": 5, "episode": 23, "scene": 14, "line": "Corporate greed?", "order": 27749, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 23, "scene": 14, "line": "Look, our price model is fine. I reviewed the numbers myself. Over time with enough volume, we become profitable.", "order": 27750, "speaker": "Ryan Howard"},
  {"season": 5, "episode": 23, "scene": 14, "line": "Yeah, with a fixed cost pricing model that's correct.", "order": 27751, "speaker": "Financial Guy"},
  {"season": 5, "episode": 23, "scene": 14, "line": "Yeah.", "order": 27752, "speaker": "Ryan Howard"},
  {"season": 5, "episode": 23, "scene": 14, "line": "But you need to use a variable cost pricing model.", "order": 27753, "speaker": "Financial Guy"},
  {"season": 5, "episode": 23, "scene": 14, "line": "Okay, sure. Right, so-- why don't you explain what that is to-- so that they can under-- just explain what that is.", "order": 27754, "speaker": "Michael Scott"},
  {"season": 5, "episode": 23, "scene": 14, "line": "Explain what you think that is.", "order": 27755, "speaker": "Ryan Howard"},
  {"season": 5, "episode": 23, "scene": 14, "line": "Okay.", "order": 27756, "speaker": "Financial Guy"},
  {"season": 5, "episode": 23, "scene": 14, "line": "Explain that.", "order": 27757, "speaker": "Michael Scott"},
  {"season": 5, "episode": 23, "scene": 14, "line": "As you sell more paper and your company grows, so will your costs. For example, delivery man, health care...", "order": 27758, "speaker": "Financial Guy"},
  {"season": 5, "episode": 23, "scene": 14, "line": "Well, we don't--", "order": 27759, "speaker": "Michael Scott"},
  {"season": 5, "episode": 23, "scene": 14, "line": "...business expansion--", "order": 27760, "speaker": "Financial Guy"},
  {"season": 5, "episode": 23, "scene": 14, "line": "Whatever, yeah.", "order": 27761, "speaker": "Michael Scott"},
  {"season": 5, "episode": 23, "scene": 14, "line": "At these prices, the more paper you sell, the less money you'll make.", "order": 27762, "speaker": "Financial Guy"},
  {"season": 5, "episode": 23, "scene": 14, "line": "Our prices are the only thing keeping us in business.", "order": 27763, "speaker": "Michael Scott"},
  {"season": 5, "episode": 23, "scene": 14, "line": "They're actually putting you out of business.", "order": 27764, "speaker": "Financial Guy"},
  {"season": 5, "episode": 23, "scene": 14, "line": "Okay, okay. Hold on, hold on. Ty, I would like you to crunch those numbers again.", "order": 27765, "speaker": "Michael Scott"},
  {"season": 5, "episode": 23, "scene": 14, "line": "It's a program. There's no such thing--", "order": 27766, "speaker": "Ty"},
  {"season": 5, "episode": 23, "scene": 14, "line": "Just crunch 'em. Just crunch 'em please.", "order": 27767, "speaker": "Michael Scott"},
  {"season": 5, "episode": 23, "scene": 14, "line": "[presses key on computer] Crunch.", "order": 27768, "speaker": "Ty"},
  {"season": 5, "episode": 23, "scene": 14, "line": "Did it help?", "order": 27769, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 23, "scene": 15, "line": "Hi, Jerry. Michael Scott. Well, this is slightly embarrassing. [laughs] Um, I'm going to have to... ask you to pay me a little bit more money for that delivery we dropped off yesterday. Yeah. We did. We got the check, but we're just going to need a much, much bigger check.", "order": 27770, "speaker": "Michael Scott"},
  {"season": 5, "episode": 23, "scene": 16, "line": "When a child gets behind the wheel of a car and runs into a tree, You don't blame the child. He didn't know any better. You blame the 30-year-old woman who got in the passenger seat and said, 'Drive, kid. I trust you.'", "order": 27771, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 23, "scene": 17, "line": "Now, would corporate approve a temporary price reduction for returning customers?", "order": 27772, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 23, "scene": 17, "line": "God, no.", "order": 27773, "speaker": "Charles Miner"},
  {"season": 5, "episode": 23, "scene": 17, "line": "Stupid. Yeah, makes us look weak.", "order": 27774, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 23, "scene": 17, "line": "I agree.", "order": 27775, "speaker": "Charles Miner"},
  {"season": 5, "episode": 23, "scene": 17, "line": "I say we fill Michael's office with bees. My apiarist owes me a favor.", "order": 27776, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 23, "scene": 17, "line": "Really? Does he do good work or--", "order": 27777, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 23, "scene": 17, "line": "[scoffs] No, Jim. I use a bad apiarist. [sighs]", "order": 27778, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 23, "scene": 17, "line": "Fellas, why don't we take a five-minute break and then we will come back, start fresh, sort this out.", "order": 27779, "speaker": "David Wallace"},
  {"season": 5, "episode": 23, "scene": 17, "line": "Five minutes exactly.", "order": 27780, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 23, "scene": 17, "line": "Okay. Hey, Dwight. Can I talk to you?", "order": 27781, "speaker": "Charles Miner"},
  {"season": 5, "episode": 23, "scene": 17, "line": "Sure thing.", "order": 27782, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 23, "scene": 17, "line": "Yeah.", "order": 27783, "speaker": "Charles Miner"},
  {"season": 5, "episode": 23, "scene": 17, "line": "[opens phone] Hey. I saw you called.", "order": 27784, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 23, "scene": 18, "line": "You're just out of business?", "order": 27785, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 23, "scene": 18, "line": "We have maybe a month. I don't know what I'm gonna do.", "order": 27786, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 23, "scene": 18, "line": "Oh, yeah, well don't worry about it. We'll figure it out. We'll be okay.", "order": 27787, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 23, "scene": 18, "line": "That's what Michael said.", "order": 27788, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 23, "scene": 18, "line": "Oh, yeah. Only this time we will be okay. [cell phone rings; Dwight's voice saying 'Idiot, Idiot, Idiot'] Oh, that's my new 'Dwight' ring.", "order": 27789, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 23, "scene": 18, "line": "I like it.", "order": 27790, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 23, "scene": 18, "line": "Good, right? Hello.", "order": 27791, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 23, "scene": 18, "line": "[over phone] Idiot, we're starting back up. This is Dwight, by the way.", "order": 27792, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 23, "scene": 18, "line": "Oh, okay. [kisses Pam] Don't worry about it.", "order": 27793, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 23, "scene": 19, "line": "Did I ever tell you about the day that Steve Martin died?", "order": 27794, "speaker": "Michael Scott"},
  {"season": 5, "episode": 23, "scene": 19, "line": "Steve Martin's not dead, Michael.", "order": 27795, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 23, "scene": 19, "line": "I know. But I always thought that the day that he died would be the worst day of my life. I was wrong. It's this.", "order": 27796, "speaker": "Michael Scott"},
  {"season": 5, "episode": 23, "scene": 19, "line": "You want to hear something sad?", "order": 27797, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 23, "scene": 19, "line": "I would love that.", "order": 27798, "speaker": "Michael Scott"},
  {"season": 5, "episode": 23, "scene": 19, "line": "So Jim and I are getting married and the wedding's really expensive. So I tried to get a job on the weekends to earn extra money. I applied to Old Navy, Target and Wal-mart. None of 'em called me back. Not even for an interview.", "order": 27799, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 23, "scene": 19, "line": "I never went to Thailand.", "order": 27800, "speaker": "Ryan Howard"},
  {"season": 5, "episode": 23, "scene": 19, "line": "Really?", "order": 27801, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 23, "scene": 19, "line": "I went to Fort Lauderdale.", "order": 27802, "speaker": "Ryan Howard"},
  {"season": 5, "episode": 23, "scene": 19, "line": "Was it nice?", "order": 27803, "speaker": "Michael Scott"},
  {"season": 5, "episode": 23, "scene": 19, "line": "Yeah, it was amazing. There was a great Pad Thai place, though.", "order": 27804, "speaker": "Ryan Howard"},
  {"season": 5, "episode": 23, "scene": 19, "line": "I love Pad Thai.", "order": 27805, "speaker": "Michael Scott"},
  {"season": 5, "episode": 23, "scene": 19, "line": "You've never had Pad Thai.", "order": 27806, "speaker": "Ryan Howard"},
  {"season": 5, "episode": 23, "scene": 19, "line": "No. There's a lot I haven't done.", "order": 27807, "speaker": "Michael Scott"},
  {"season": 5, "episode": 23, "scene": 20, "line": "Now, this is the projection over three months?", "order": 27808, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 23, "scene": 20, "line": "We still have the inventory sitting-- [everyone starts chattering at once]", "order": 27809, "speaker": "Charles Miner"},
  {"season": 5, "episode": 23, "scene": 20, "line": "Let me float something out there, okay? Can I just say-- can I say something?", "order": 27810, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 23, "scene": 20, "line": "Yeah, yeah.", "order": 27811, "speaker": "David Wallace"},
  {"season": 5, "episode": 23, "scene": 20, "line": "There is a hive of bees outside the front door. We kidnap the queen, extract her alarm pheromones, place them on a flushable wipe, put that in his bathroom.", "order": 27812, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 23, "scene": 20, "line": "I can't believe I'm about to say this, but the cheapest option... is to make Michael an offer.", "order": 27813, "speaker": "David Wallace"},
  {"season": 5, "episode": 23, "scene": 20, "line": "Yes. Yes, I was gonna say the same thing. We should buy him out.", "order": 27814, "speaker": "Charles Miner"},
  {"season": 5, "episode": 23, "scene": 20, "line": "Oh, but you didn't.", "order": 27815, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 23, "scene": 20, "line": "Oh, Man! If only Michael had children. That's how you really apply the pressure.", "order": 27816, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 23, "scene": 20, "line": "What is wrong with you?", "order": 27817, "speaker": "Charles Miner"},
  {"season": 5, "episode": 23, "scene": 20, "line": "Jim, you're-- you're pretty close with him. You think they'd be up for hearing an offer?", "order": 27818, "speaker": "David Wallace"},
  {"season": 5, "episode": 23, "scene": 20, "line": "Oh, I don't know. You know, they've taken a good deal of clients, so--", "order": 27819, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 23, "scene": 20, "line": "Yeah.", "order": 27820, "speaker": "David Wallace"},
  {"season": 5, "episode": 23, "scene": 20, "line": "I mean I guess-- I guess I could go down there and try to nudge them in the right direction.", "order": 27821, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 23, "scene": 20, "line": "You know what? Why don't I do it, okay? Michael adores me. I'm the man for this job. Charles, you got my back on this?", "order": 27822, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 23, "scene": 20, "line": "No, Jim... I think you should go.", "order": 27823, "speaker": "Charles Miner"},
  {"season": 5, "episode": 23, "scene": 20, "line": "Okay, so I'll be back in, um-- back in a bit.", "order": 27824, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 23, "scene": 21, "line": "[knocking on door] Hmm?", "order": 27825, "speaker": "Michael Scott"},
  {"season": 5, "episode": 23, "scene": 21, "line": "Hey, can I talk to you guys for a second?", "order": 27826, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 23, "scene": 21, "line": "We're not hiring, Jim.", "order": 27827, "speaker": "Michael Scott"},
  {"season": 5, "episode": 23, "scene": 21, "line": "Actually here for something else.", "order": 27828, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 23, "scene": 21, "line": "Listen, I can't make you laugh right now.", "order": 27829, "speaker": "Michael Scott"},
  {"season": 5, "episode": 23, "scene": 21, "line": "You know I love a good guessing game, but why don't I just tell you what I'm here for? Turns out you guys have made a pretty big dent in the Dunder Mifflin sales.", "order": 27830, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 23, "scene": 21, "line": "Oh, that's nice.", "order": 27831, "speaker": "Ryan Howard"},
  {"season": 5, "episode": 23, "scene": 21, "line": "David Wallace has asked me to come down here and see if you would be interested in Dunder Mifflin buying you out.", "order": 27832, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 23, "scene": 21, "line": "Seriously? Are you being serious?", "order": 27833, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 23, "scene": 21, "line": "He's bluffing, Pam.", "order": 27834, "speaker": "Ryan Howard"},
  {"season": 5, "episode": 23, "scene": 21, "line": "Jim, what you don't understand is that this company's worthl--", "order": 27835, "speaker": "Michael Scott"},
  {"season": 5, "episode": 23, "scene": 21, "line": "Oh!", "order": 27836, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 23, "scene": 21, "line": "No-- We don't have--", "order": 27837, "speaker": "Michael Scott"},
  {"season": 5, "episode": 23, "scene": 21, "line": "Oh! See I'm here to learn as little information as possible. All I really need to hear is if your incredibly successful company would be receptive to a buyout.", "order": 27838, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 23, "scene": 21, "line": "[stammers] Uh... Yes.", "order": 27839, "speaker": "Michael Scott"},
  {"season": 5, "episode": 23, "scene": 21, "line": "Yes.", "order": 27840, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 23, "scene": 21, "line": "Maybe.", "order": 27841, "speaker": "Ryan Howard"},
  {"season": 5, "episode": 23, "scene": 21, "line": "Three yeses. I will see you titans of industry upstairs.", "order": 27842, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 23, "scene": 21, "line": "Yes, well, we're not only tight-ends, we are also quarterbacks.", "order": 27843, "speaker": "Michael Scott"},
  {"season": 5, "episode": 23, "scene": 21, "line": "Missed the last part.", "order": 27844, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 23, "scene": 21, "line": "That's a pun.", "order": 27845, "speaker": "Michael Scott"},
  {"season": 5, "episode": 23, "scene": 21, "line": "Got it.", "order": 27846, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 23, "scene": 21, "line": "Yep. Oh... wow.", "order": 27847, "speaker": "Michael Scott"},
  {"season": 5, "episode": 23, "scene": 22, "line": "Okay, so you are not going to reveal in any way that we're broke.", "order": 27848, "speaker": "Ryan Howard"},
  {"season": 5, "episode": 23, "scene": 22, "line": "Of course not.", "order": 27849, "speaker": "Michael Scott"},
  {"season": 5, "episode": 23, "scene": 22, "line": "That we're having any problem at all.", "order": 27850, "speaker": "Ryan Howard"},
  {"season": 5, "episode": 23, "scene": 22, "line": "Nope, nope, nope.", "order": 27851, "speaker": "Michael Scott"},
  {"season": 5, "episode": 23, "scene": 22, "line": "Just to reiterate, none of us is going to say anything that might indicate that we are going broke.", "order": 27852, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 23, "scene": 22, "line": "Right, right. There is no way in hell that I am going to say that we're broke.", "order": 27853, "speaker": "Michael Scott"},
  {"season": 5, "episode": 23, "scene": 23, "line": "Oh, I'm really worried that I'm going to say it.", "order": 27854, "speaker": "Michael Scott"},
  {"season": 5, "episode": 23, "scene": 23, "line": "No, Michael.", "order": 27855, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 23, "scene": 23, "line": "Ah...", "order": 27856, "speaker": "Michael Scott"},
  {"season": 5, "episode": 23, "scene": 23, "line": "No, man. You're-- you're fine.", "order": 27857, "speaker": "Ryan Howard"},
  {"season": 5, "episode": 23, "scene": 23, "line": "We have to come from a position of strength.", "order": 27858, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 23, "scene": 23, "line": "I'm good, I'm good.", "order": 27859, "speaker": "Michael Scott"},
  {"season": 5, "episode": 23, "scene": 23, "line": "Just put it out of your mind.", "order": 27860, "speaker": "Ryan Howard"},
  {"season": 5, "episode": 23, "scene": 23, "line": "It is. I'm good.", "order": 27861, "speaker": "Michael Scott"},
  {"season": 5, "episode": 23, "scene": 24, "line": "Hello.", "order": 27862, "speaker": "Michael Scott"},
  {"season": 5, "episode": 23, "scene": 24, "line": "Hi.", "order": 27863, "speaker": "Erin Hannon"},
  {"season": 5, "episode": 23, "scene": 24, "line": "Michael Scott Paper Company to see Mr. David Wallace. I believe we're expected. [David and Charles walk out of conference room] Well, well, well. How the turntables...", "order": 27864, "speaker": "Michael Scott"},
  {"season": 5, "episode": 23, "scene": 25, "line": "Michael, in order to expedite these negotiations, we are prepared to make you a very generous offer.", "order": 27865, "speaker": "David Wallace"},
  {"season": 5, "episode": 23, "scene": 25, "line": "And we are prepared to reject that offer.", "order": 27866, "speaker": "Michael Scott"},
  {"season": 5, "episode": 23, "scene": 25, "line": "Michael, you haven't even heard--", "order": 27867, "speaker": "Ryan Howard"},
  {"season": 5, "episode": 23, "scene": 25, "line": "Never accept their first offer. What is your second offer?", "order": 27868, "speaker": "Michael Scott"},
  {"season": 5, "episode": 23, "scene": 25, "line": "12000", "order": 27869, "speaker": "David Wallace"},
  {"season": 5, "episode": 23, "scene": 25, "line": "Are you kidding me? That is insultingly low. I don't even want to hear what your first offer was.", "order": 27870, "speaker": "Michael Scott"},
  {"season": 5, "episode": 23, "scene": 26, "line": "What do you hear?", "order": 27871, "speaker": "Angela Martin"},
  {"season": 5, "episode": 23, "scene": 26, "line": "[mumbles]", "order": 27872, "speaker": "Kelly Kapoor"},
  {"season": 5, "episode": 23, "scene": 27, "line": "Here's the situation. Your company is four weeks old. I know this business. I know what suppliers are charging. I know you can't be making very much money. I don't know how your prices are so low, but I know it can't keep up that way. I'm sure you're scared. Probably in debt. This is the best offer you're gonna get.", "order": 27873, "speaker": "David Wallace"},
  {"season": 5, "episode": 23, "scene": 27, "line": "I'll see your situation and I'll raise you a situation. Your company is losing clients left and right. You have a stockholder meeting coming up and you're going to have to explain to them why your most profitable branch is bleeding. So they may be looking for a little change in the CFO. So I don't think I need to wait out Dunder Mifflin. I think I just have to wait out you.", "order": 27874, "speaker": "Michael Scott"},
  {"season": 5, "episode": 23, "scene": 27, "line": "Okay, now I don't know that I can get this. I do have to go to the board for approval. How's about $60,000. Hmm? $60,0000. Michael?", "order": 27875, "speaker": "David Wallace"},
  {"season": 5, "episode": 23, "scene": 27, "line": "[stutters] We'll have to talk--", "order": 27876, "speaker": "Michael Scott"},
  {"season": 5, "episode": 23, "scene": 27, "line": "What?", "order": 27877, "speaker": "Charles Miner"},
  {"season": 5, "episode": 23, "scene": 27, "line": "We'll have to talk about this.", "order": 27878, "speaker": "Ryan Howard"},
  {"season": 5, "episode": 23, "scene": 27, "line": "Mm-hmm.", "order": 27879, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 23, "scene": 27, "line": "Just amongst ourselves.", "order": 27880, "speaker": "Michael Scott"},
  {"season": 5, "episode": 23, "scene": 27, "line": "Okay, yes. Please take the room. Be right outside. Take your time.", "order": 27881, "speaker": "David Wallace"},
  {"season": 5, "episode": 23, "scene": 27, "line": "Oh, my God. Oh, my God.", "order": 27882, "speaker": "Michael Scott"},
  {"season": 5, "episode": 23, "scene": 27, "line": "60000", "order": 27883, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 23, "scene": 27, "line": "We are so rich.", "order": 27884, "speaker": "Michael Scott"},
  {"season": 5, "episode": 23, "scene": 27, "line": "Are you kidding me?", "order": 27885, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 23, "scene": 28, "line": "Well, Jerry, the one who got away. May I ask why you're leaving the Michael Scott Paper Company? Really? Please hold. [gets up and runs to kitchen] Charles.", "order": 27886, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 23, "scene": 28, "line": "Dwight.", "order": 27887, "speaker": "Charles Miner"},
  {"season": 5, "episode": 23, "scene": 28, "line": "May I have your attention? The Michael Scott Paper Company is broke.", "order": 27888, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 23, "scene": 28, "line": "What? How'd you hear that?", "order": 27889, "speaker": "Charles Miner"},
  {"season": 5, "episode": 23, "scene": 28, "line": "They've been calling all of their clients and begging them to pay more money. That can only mean one thing. They're desperate. Which can only mean one thing. They're total failures.", "order": 27890, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 23, "scene": 28, "line": "Great work Dwight.", "order": 27891, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 23, "scene": 28, "line": "Quiet you.", "order": 27892, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 23, "scene": 28, "line": "No, I mean, great detective work. 'Cause this must be the first case you've ever cracked, right?", "order": 27893, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 23, "scene": 28, "line": "You don't crack a case. That has pejorative connotation. That's like calling a policeman a cop. You solve a case, and yes, I've solved plenty.", "order": 27894, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 23, "scene": 28, "line": "So how long can they stay viable?", "order": 27895, "speaker": "Charles Miner"},
  {"season": 5, "episode": 23, "scene": 28, "line": "What are your top five cases?", "order": 27896, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 23, "scene": 28, "line": "I'm gonna answer Charles first.", "order": 27897, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 23, "scene": 28, "line": "Because you've solved zero cases.", "order": 27898, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 23, "scene": 28, "line": "Okay, one. Case of the beet bandit. Missing beets from all over the farm. No footprints. Inside job. Mose in socks. Boom, case closed.", "order": 27899, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 23, "scene": 28, "line": "Okay, do not go anywhere near the conference room.", "order": 27900, "speaker": "Charles Miner"},
  {"season": 5, "episode": 23, "scene": 28, "line": "Done.", "order": 27901, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 23, "scene": 28, "line": "Because you have embarrassed me for the last time today. Got it?", "order": 27902, "speaker": "Charles Miner"},
  {"season": 5, "episode": 23, "scene": 28, "line": "I'm not-- I'm not following you.", "order": 27903, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 23, "scene": 28, "line": "You two are morons.", "order": 27904, "speaker": "Charles Miner"},
  {"season": 5, "episode": 23, "scene": 28, "line": "Got it.", "order": 27905, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 23, "scene": 28, "line": "Wh--", "order": 27906, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 23, "scene": 28, "line": "Get out.", "order": 27907, "speaker": "Charles Miner"},
  {"season": 5, "episode": 23, "scene": 29, "line": "So you've thought it over, yes, and you accept our offer. We can finally put this whole thing behind us? Hmm?", "order": 27908, "speaker": "David Wallace"},
  {"season": 5, "episode": 23, "scene": 29, "line": "No.", "order": 27909, "speaker": "Michael Scott"},
  {"season": 5, "episode": 23, "scene": 29, "line": "Can you give us another minute please?", "order": 27910, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 23, "scene": 29, "line": "Yeah.", "order": 27911, "speaker": "David Wallace"},
  {"season": 5, "episode": 23, "scene": 29, "line": "Oh, okay.", "order": 27912, "speaker": "Charles Miner"},
  {"season": 5, "episode": 23, "scene": 29, "line": "Michael.", "order": 27913, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 23, "scene": 29, "line": "How could you do this to me, Michael? You just cost me $60,000.", "order": 27914, "speaker": "Ryan Howard"},
  {"season": 5, "episode": 23, "scene": 29, "line": "Why are you assuming you'd get the whole thing?", "order": 27915, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 23, "scene": 29, "line": "It's a lot of money, okay. But we need money coming in every week. We need jobs. Wouldn't you rather have a fishing pole than a fish?", "order": 27916, "speaker": "Michael Scott"},
  {"season": 5, "episode": 23, "scene": 29, "line": "I would rather have $60,000, honestly.", "order": 27917, "speaker": "Ryan Howard"},
  {"season": 5, "episode": 23, "scene": 29, "line": "No, Michael's right. Jobs are safer.", "order": 27918, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 23, "scene": 29, "line": "Agreed?", "order": 27919, "speaker": "Michael Scott"},
  {"season": 5, "episode": 23, "scene": 29, "line": "Agreed. But that's all, okay.", "order": 27920, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 23, "scene": 29, "line": "Yeah, discuss these things--", "order": 27921, "speaker": "Ryan Howard"},
  {"season": 5, "episode": 23, "scene": 29, "line": "Shut up, shut up. Hello?", "order": 27922, "speaker": "Michael Scott"},
  {"season": 5, "episode": 23, "scene": 30, "line": "I want my old job back. I want my old parking space back. I want a Sebring.", "order": 27923, "speaker": "Michael Scott"},
  {"season": 5, "episode": 23, "scene": 30, "line": "They don't make them anymore.", "order": 27924, "speaker": "Charles Miner"},
  {"season": 5, "episode": 23, "scene": 30, "line": "And I want Charles gone.", "order": 27925, "speaker": "Michael Scott"},
  {"season": 5, "episode": 23, "scene": 30, "line": "I am not firing Charles. He is very valuable.", "order": 27926, "speaker": "David Wallace"},
  {"season": 5, "episode": 23, "scene": 30, "line": "That's very kind of you to say, David.", "order": 27927, "speaker": "Charles Miner"},
  {"season": 5, "episode": 23, "scene": 30, "line": "I need him gone.", "order": 27928, "speaker": "Michael Scott"},
  {"season": 5, "episode": 23, "scene": 30, "line": "No.", "order": 27929, "speaker": "David Wallace"},
  {"season": 5, "episode": 23, "scene": 30, "line": "Okay, then I want Pam back.", "order": 27930, "speaker": "Michael Scott"},
  {"season": 5, "episode": 23, "scene": 30, "line": "Uh, you already have a new receptionist--", "order": 27931, "speaker": "David Wallace"},
  {"season": 5, "episode": 23, "scene": 30, "line": "Sales.", "order": 27932, "speaker": "Michael Scott"},
  {"season": 5, "episode": 23, "scene": 30, "line": "Thank you.", "order": 27933, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 23, "scene": 30, "line": "Pam's not a salesperson.", "order": 27934, "speaker": "David Wallace"},
  {"season": 5, "episode": 23, "scene": 30, "line": "Yes, she is. At the Michael Scott Paper Company in it's heyday.", "order": 27935, "speaker": "Michael Scott"},
  {"season": 5, "episode": 23, "scene": 30, "line": "That's right.", "order": 27936, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 23, "scene": 30, "line": "Okay. Please continue.", "order": 27937, "speaker": "David Wallace"},
  {"season": 5, "episode": 23, "scene": 30, "line": "And Ryan.", "order": 27938, "speaker": "Michael Scott"},
  {"season": 5, "episode": 23, "scene": 30, "line": "Ryan cost Dunder Mifflin hundreds of thousands of dollars, Michael. Ryan is--", "order": 27939, "speaker": "David Wallace"},
  {"season": 5, "episode": 23, "scene": 30, "line": "You know, David. I don't care if Ryan murdered his entire family he is like a son to me.", "order": 27940, "speaker": "Michael Scott"},
  {"season": 5, "episode": 23, "scene": 30, "line": "Do you realize what you're asking for here? You're talking about salary plus health benefits--", "order": 27941, "speaker": "David Wallace"},
  {"season": 5, "episode": 23, "scene": 30, "line": "And dental this time.", "order": 27942, "speaker": "Michael Scott"},
  {"season": 5, "episode": 23, "scene": 30, "line": "Insurance, taxes, social security for three people. This is a heck of a lot more than 60 grand. You're talking about a multi-million dollar buy-out.", "order": 27943, "speaker": "David Wallace"},
  {"season": 5, "episode": 23, "scene": 30, "line": "These are our demands.", "order": 27944, "speaker": "Michael Scott"},
  {"season": 5, "episode": 23, "scene": 30, "line": "Your company cannot be worth that much.", "order": 27945, "speaker": "David Wallace"},
  {"season": 5, "episode": 23, "scene": 30, "line": "Our company is worth nothing. That's the difference between you and I. Business isn't about money to me, David. If tomorrow my company goes under, I will just start another paper company. And then another and another and another. I have no shortage of company names.", "order": 27946, "speaker": "Michael Scott"},
  {"season": 5, "episode": 23, "scene": 30, "line": "Michael--", "order": 27947, "speaker": "David Wallace"},
  {"season": 5, "episode": 23, "scene": 30, "line": "That's one of 'em! Yes. These are our demands. This is what we want. Our balls are in your court.", "order": 27948, "speaker": "Michael Scott"},
  {"season": 5, "episode": 23, "scene": 30, "line": "Okay. Deal. Okay?", "order": 27949, "speaker": "David Wallace"},
  {"season": 5, "episode": 23, "scene": 30, "line": "Can we have the room please?", "order": 27950, "speaker": "Michael Scott"},
  {"season": 5, "episode": 23, "scene": 30, "line": "Yeah. [David and Charles leave room, Michael shuts the door and closes the blinds]", "order": 27951, "speaker": "David Wallace"},
  {"season": 5, "episode": 23, "scene": 30, "line": "Yes, yes, yes!", "order": 27952, "speaker": "Ryan Howard"},
  {"season": 5, "episode": 23, "scene": 30, "line": "Alright!", "order": 27953, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 23, "scene": 30, "line": "Yes! Can you believe it? That's what I'm talking about!", "order": 27954, "speaker": "Michael Scott"},
  {"season": 5, "episode": 23, "scene": 31, "line": "There are certain defining moments in a person's life. The day he is born. The day he grows hair. The day he starts a business. And the day he sells that business back to Dunder Mifflin. What have I learned from all of this? It is far too early to tell. I just know that I am flying high and I don't even want to think about it. I just want to enjoy it.", "order": 27955, "speaker": "Michael Scott"},
  {"season": 5, "episode": 23, "scene": 32, "line": "Hey, guys--", "order": 27956, "speaker": "Charles Miner"},
  {"season": 5, "episode": 23, "scene": 32, "line": "No, no. You're done.", "order": 27957, "speaker": "Michael Scott"},
  {"season": 5, "episode": 24, "scene": 1, "line": "At least once a year, I like to bring in some of my Kevin's Famous Chili. The trick is to undercook the onions. Everybody is going to get to know each other in the pot. I'm serious about this stuff. I'm up the night before pressing garlic and dicing whole tomatoes. I toast my own Ancho chilies. It's a recipe passed down from Malone's for generations. It's probably the thing I do best.", "order": 27958, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 24, "scene": 2, "line": "Someone is returning! He started his own company, and now he's back. Who could it be? I'll give you a hint. He is a man. A man you have missed with all your heart. A man who has ruined all other men for you. Who is it?", "order": 27959, "speaker": "Michael Scott"},
  {"season": 5, "episode": 24, "scene": 2, "line": "[whispering] Who is it?", "order": 27960, "speaker": "Ryan Howard"},
  {"season": 5, "episode": 24, "scene": 2, "line": "[whispering] Who is it?", "order": 27961, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 24, "scene": 2, "line": "[whispering] It's Michael Scott. [applause; Michael jumps through sign] Hold it up. Conference room, five minutes.", "order": 27962, "speaker": "Michael Scott"},
  {"season": 5, "episode": 24, "scene": 3, "line": "Well, I guess the first order of business is to welcome back me, and Pam and Ryan. [scattered applause] And how about casual Friday again, huh? Reinstituting casual Friday? You all look great. What I want to do right now is try something a little different. I'm going to throw it out there, starting with this meeting. I want you guys to run it. I want you to say whatever you want, take it away. Your meeting.", "order": 27963, "speaker": "Michael Scott"},
  {"season": 5, "episode": 24, "scene": 3, "line": "[after a few moments of silence] Wh--what are you doing?", "order": 27964, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 24, "scene": 3, "line": "If there is something that you would like to say as a group, then by all means, you may say it to me right now.", "order": 27965, "speaker": "Michael Scott"},
  {"season": 5, "episode": 24, "scene": 3, "line": "Okay.", "order": 27966, "speaker": "Angela Martin"},
  {"season": 5, "episode": 24, "scene": 3, "line": "Yes.", "order": 27967, "speaker": "Michael Scott"},
  {"season": 5, "episode": 24, "scene": 3, "line": "People are dressed inappropriately.", "order": 27968, "speaker": "Angela Martin"},
  {"season": 5, "episode": 24, "scene": 3, "line": "Take it up with Toby. What I want you to do, is I want you to look inward. And I want you to reflect. And I want you to say something different.", "order": 27969, "speaker": "Michael Scott"},
  {"season": 5, "episode": 24, "scene": 3, "line": "Can you give us a hint?", "order": 27970, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 24, "scene": 4, "line": "When I needed salespeople for my new paper company, everyone here turned their back on me. Am I going to ask them to beg for forgiveness? No. Am I going to ask for big, crying apology? No. Am I going to ask them to slit their wrists for me? No. I just want a tiny, microscopic version of that.", "order": 27971, "speaker": "Michael Scott"},
  {"season": 5, "episode": 24, "scene": 5, "line": "Oscar, what were you going to say?", "order": 27972, "speaker": "Michael Scott"},
  {"season": 5, "episode": 24, "scene": 5, "line": "Nothing. I didn't say anything.", "order": 27973, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 24, "scene": 5, "line": "All right.", "order": 27974, "speaker": "Michael Scott"},
  {"season": 5, "episode": 24, "scene": 5, "line": "Do I still have a job here?", "order": 27975, "speaker": "Erin Hannon"},
  {"season": 5, "episode": 24, "scene": 5, "line": "Not important. [everyone mutters] Okay, alright. Yes. Yes, you have a job. Frankly, you have a job because Ryan and Pam are starting with us as salesmen.", "order": 27976, "speaker": "Michael Scott"},
  {"season": 5, "episode": 24, "scene": 5, "line": "Wait, what?", "order": 27977, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 24, "scene": 5, "line": "How is that going to work?", "order": 27978, "speaker": "Stanley Hudson"},
  {"season": 5, "episode": 24, "scene": 5, "line": "It's going to work very smoothly, because Pam and Ryan are bringing over a ton of clients from Michael Scott Paper Company and--", "order": 27979, "speaker": "Michael Scott"},
  {"season": 5, "episode": 24, "scene": 5, "line": "You mean the clients you stole from us.", "order": 27980, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 24, "scene": 5, "line": "Yeah , aren't we getting those clients back?", "order": 27981, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 24, "scene": 5, "line": "No, you lost those clients.", "order": 27982, "speaker": "Michael Scott"},
  {"season": 5, "episode": 24, "scene": 5, "line": "I call foul, sir.", "order": 27983, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 24, "scene": 5, "line": "Okay.", "order": 27984, "speaker": "Michael Scott"},
  {"season": 5, "episode": 24, "scene": 5, "line": "You were bought out. So the company then bought out all of the stolen clients.", "order": 27985, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 24, "scene": 5, "line": "Okay, Dwight. Let me explain something to you. I set the rules and you follow them. Blindly. Okay? And if you have a problem with that then you can talk to our complaint department. It's a trash can.", "order": 27986, "speaker": "Michael Scott"},
  {"season": 5, "episode": 24, "scene": 6, "line": "[to Ryan] Don't fall in love with me, kid.", "order": 27987, "speaker": "Meredith Palmer"},
  {"season": 5, "episode": 24, "scene": 7, "line": "[laughs] Boscov's at the Steamtown Mall?", "order": 27988, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 24, "scene": 7, "line": "It sure looked good on the mannequin.", "order": 27989, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 24, "scene": 7, "line": "Well, you have good taste.", "order": 27990, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 24, "scene": 7, "line": "Well, thanks. I sure wish I had more time to talk to my clients, though.", "order": 27991, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 24, "scene": 7, "line": "What?", "order": 27992, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 24, "scene": 7, "line": "Isn't that what you said to a bunch of my clients when you were stealing them? That I didn't have enough time for them?", "order": 27993, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 24, "scene": 7, "line": "Oh, I, um...", "order": 27994, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 24, "scene": 7, "line": "Close your mouth, sweetie. You look like a trout.", "order": 27995, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 24, "scene": 8, "line": "The man is wearing sandals. I don't need to see Oscar's toes at work. Gross! I mean he looks like he just got off the boat.", "order": 27996, "speaker": "Angela Martin"},
  {"season": 5, "episode": 24, "scene": 8, "line": "Can't you just not look at his feet?", "order": 27997, "speaker": "Toby Flenderson"},
  {"season": 5, "episode": 24, "scene": 8, "line": "Excuse me? Oh. You're so educated, aren't you Toby? So trained to deal with a hysterical woman. I don't want to look at his feet. [storms off] Do your job!", "order": 27998, "speaker": "Angela Martin"},
  {"season": 5, "episode": 24, "scene": 9, "line": "Well, I was in the Seminary for a year and dropped out 'cause I wanted to have sex with this girl, Cathy. Followed her to Scranton. Took the first job I could find in H.R. Later she divorced me. So no, I wouldn't say I have a passion for H.R.", "order": 27999, "speaker": "Toby Flenderson"},
  {"season": 5, "episode": 24, "scene": 10, "line": "[hands Ryan a soda] There you are, my friend.", "order": 28000, "speaker": "Michael Scott"},
  {"season": 5, "episode": 24, "scene": 10, "line": "Oh, thanks, man.", "order": 28001, "speaker": "Ryan Howard"},
  {"season": 5, "episode": 24, "scene": 10, "line": "Pamela.", "order": 28002, "speaker": "Michael Scott"},
  {"season": 5, "episode": 24, "scene": 10, "line": "Thank, Michael.", "order": 28003, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 24, "scene": 10, "line": "Yep.", "order": 28004, "speaker": "Michael Scott"},
  {"season": 5, "episode": 24, "scene": 11, "line": "Fresh hot ink.", "order": 28005, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 24, "scene": 11, "line": "New File System'.", "order": 28006, "speaker": "Stanley Hudson"},
  {"season": 5, "episode": 24, "scene": 11, "line": "Trust me, Stanley, you're going to want to read this memo very carefully.", "order": 28007, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 24, "scene": 11, "line": "I am not changing the way I do my files.", "order": 28008, "speaker": "Stanley Hudson"},
  {"season": 5, "episode": 24, "scene": 11, "line": "No, no. This goes for all of you. Believe me, you are going to want to heat my words.", "order": 28009, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 24, "scene": 12, "line": "When held over heat the invisible ink will reveal that everyone should meet at the warehouse immediately. Do not ask me where I got the invisible ink. Urine. It was urine.", "order": 28010, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 24, "scene": 13, "line": "I'm sorry you're offended by my shoes, but I am not going to drive home to change.", "order": 28011, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 24, "scene": 13, "line": "I could loan you a pair of socks.", "order": 28012, "speaker": "Toby Flenderson"},
  {"season": 5, "episode": 24, "scene": 13, "line": "No.", "order": 28013, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 24, "scene": 13, "line": "No, they're clean. I was going to wear them to volleyball practice later.", "order": 28014, "speaker": "Toby Flenderson"},
  {"season": 5, "episode": 24, "scene": 13, "line": "I don't think so.", "order": 28015, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 24, "scene": 14, "line": "Andrew Bernard.", "order": 28016, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 24, "scene": 14, "line": "Okay, what are you still doing at your desk?", "order": 28017, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 24, "scene": 14, "line": "What are you still doing not at your desk?", "order": 28018, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 24, "scene": 14, "line": "Did you even read the memo?", "order": 28019, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 24, "scene": 14, "line": "All my files are now in reverse alphabetical order--", "order": 28020, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 24, "scene": 14, "line": "No, you idiot. There is a code embedded in the memo. Listen, you need to round up all the salesmen, except for Ryan and Pam, and get them down to the warehouse pronto.", "order": 28021, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 24, "scene": 14, "line": "Oh, it is on like a prawn who yawns at dawn.", "order": 28022, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 24, "scene": 14, "line": "Stop doing rhyming poetry. Just tell them, please. God, the simplest thing.", "order": 28023, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 24, "scene": 15, "line": "We're in crisis mode here, do you understand? The two unqualified so-called salespeople are being favored because they all failed together. And frankly, I'm not convinced that Michael and Pam didn't have a thing going while they were gone.", "order": 28024, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 24, "scene": 15, "line": "Can I address that?", "order": 28025, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 24, "scene": 15, "line": "No, you're too close.", "order": 28026, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 24, "scene": 15, "line": "Okay.", "order": 28027, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 24, "scene": 15, "line": "Stanley, how do you feel about those two taking your clients?", "order": 28028, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 24, "scene": 15, "line": "It's not right. I don't like it.", "order": 28029, "speaker": "Stanley Hudson"},
  {"season": 5, "episode": 24, "scene": 15, "line": "He doesn't like it.", "order": 28030, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 24, "scene": 15, "line": "You don't take my clients away and give them to a secretary. [sighs] No offense, Jim. I think she's very pretty.", "order": 28031, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 24, "scene": 15, "line": "Her face is okay, but-- Jesus! What --what are you doing here?", "order": 28032, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 24, "scene": 15, "line": "I don't know. I saw a crowd. I thought there might be a dogfight or something--", "order": 28033, "speaker": "Meredith Palmer"},
  {"season": 5, "episode": 24, "scene": 15, "line": "Get out! We need to do something. I'm thinking maybe a coup or we take Ryan hostage.", "order": 28034, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 24, "scene": 15, "line": "Those sound too harsh.", "order": 28035, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 24, "scene": 15, "line": "No, I'm not saying we do those things. I'm saying something like those things.", "order": 28036, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 24, "scene": 15, "line": "Of course. What is like a hostage?", "order": 28037, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 24, "scene": 15, "line": "Excellent question.", "order": 28038, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 24, "scene": 15, "line": "We could write a strong-worded letter.", "order": 28039, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 24, "scene": 15, "line": "Words will never be enough.", "order": 28040, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 24, "scene": 15, "line": "Strongly-painted picture.", "order": 28041, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 24, "scene": 15, "line": "No, pictures are too interpretive.", "order": 28042, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 24, "scene": 15, "line": "Can I just say something? I know that tempers are high, but I think the best way to handle this might be to just talk to Michael directly.", "order": 28043, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 24, "scene": 15, "line": "Oh. Well, that--that's a great idea, Jim. Oh, I totally see your point. Okay, we'll talk to Michael directly. So uh, meeting is over, I guess. I'll see everyone upstairs.", "order": 28044, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 24, "scene": 15, "line": "Great. See you upstairs.", "order": 28045, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 24, "scene": 15, "line": "C'mon. [whispers] Guys. Psst, listen. Now is the time for action. I'm talking action--", "order": 28046, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 24, "scene": 16, "line": "That one's great.", "order": 28047, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 24, "scene": 16, "line": "I know.", "order": 28048, "speaker": "Michael Scott"},
  {"season": 5, "episode": 24, "scene": 16, "line": "You look so classy in that picture.", "order": 28049, "speaker": "Ryan Howard"},
  {"season": 5, "episode": 24, "scene": 16, "line": "I do? Well--Where is that bowl?", "order": 28050, "speaker": "Michael Scott"},
  {"season": 5, "episode": 24, "scene": 16, "line": "Hey, you have a second?", "order": 28051, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 24, "scene": 16, "line": "Michael Scott Paper Company only.", "order": 28052, "speaker": "Michael Scott"},
  {"season": 5, "episode": 24, "scene": 16, "line": "Yeah, I think you're going to want to cool it with that 'cause it's starting to upset the other salesmen.", "order": 28053, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 24, "scene": 16, "line": "Okay, well---", "order": 28054, "speaker": "Michael Scott"},
  {"season": 5, "episode": 24, "scene": 16, "line": "Tough.", "order": 28055, "speaker": "Ryan Howard"},
  {"season": 5, "episode": 24, "scene": 16, "line": "I will--no, no. I'll talk to them when they all get back from the bathroom.", "order": 28056, "speaker": "Michael Scott"},
  {"season": 5, "episode": 24, "scene": 16, "line": "This is awkward to talk about, but there may or may not be--but definitely is--a mutiny forming in the warehouse right now.", "order": 28057, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 24, "scene": 16, "line": "Against who?", "order": 28058, "speaker": "Michael Scott"},
  {"season": 5, "episode": 24, "scene": 16, "line": "You. Us.", "order": 28059, "speaker": "Ryan Howard"},
  {"season": 5, "episode": 24, "scene": 16, "line": "What?", "order": 28060, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 24, "scene": 16, "line": "It's gotta be because they all want their clients.", "order": 28061, "speaker": "Ryan Howard"},
  {"season": 5, "episode": 24, "scene": 17, "line": "The old Michael Scott might have taken this, but not the new Michael Scott. They are in for a bitter surprise. I am not to be truffled with.", "order": 28062, "speaker": "Michael Scott"},
  {"season": 5, "episode": 24, "scene": 18, "line": "Six weeks ago, none of these people wanted to come with me. You two were the only ones with the stones to follow.", "order": 28063, "speaker": "Michael Scott"},
  {"season": 5, "episode": 24, "scene": 18, "line": "Mm-hmmm.", "order": 28064, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 24, "scene": 18, "line": "That was us, right there.", "order": 28065, "speaker": "Michael Scott"},
  {"season": 5, "episode": 24, "scene": 18, "line": "We were something else.", "order": 28066, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 24, "scene": 18, "line": "What you gotta do is you gotta go down to that warehouse and you gotta crack some skulls.", "order": 28067, "speaker": "Ryan Howard"},
  {"season": 5, "episode": 24, "scene": 18, "line": "Mm-hmm.", "order": 28068, "speaker": "Michael Scott"},
  {"season": 5, "episode": 24, "scene": 18, "line": "Chiklis style.", "order": 28069, "speaker": "Ryan Howard"},
  {"season": 5, "episode": 24, "scene": 18, "line": "Yeah, the Commish.", "order": 28070, "speaker": "Michael Scott"},
  {"season": 5, "episode": 24, "scene": 18, "line": "Yes, but Chiklis Shield style. Not Commish style.", "order": 28071, "speaker": "Ryan Howard"},
  {"season": 5, "episode": 24, "scene": 18, "line": "Yeah, yeah. The Shield.", "order": 28072, "speaker": "Michael Scott"},
  {"season": 5, "episode": 24, "scene": 19, "line": "It's crazy what's going on out there today, huh?", "order": 28073, "speaker": "Creed Bratton"},
  {"season": 5, "episode": 24, "scene": 19, "line": "I know. Yeah, it's...kinda--", "order": 28074, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 24, "scene": 19, "line": "Sometimes it's best just to say out of it.", "order": 28075, "speaker": "Creed Bratton"},
  {"season": 5, "episode": 24, "scene": 19, "line": "That's true. That's right. Yeah.", "order": 28076, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 24, "scene": 19, "line": "Want to play a game?", "order": 28077, "speaker": "Creed Bratton"},
  {"season": 5, "episode": 24, "scene": 20, "line": "Hey gang. Where you been?", "order": 28078, "speaker": "Michael Scott"},
  {"season": 5, "episode": 24, "scene": 20, "line": "Lunch.", "order": 28079, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 24, "scene": 20, "line": "Where'd you eat?", "order": 28080, "speaker": "Michael Scott"},
  {"season": 5, "episode": 24, "scene": 20, "line": "A restaurant.", "order": 28081, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 24, "scene": 20, "line": "What'd you have, Stanley?", "order": 28082, "speaker": "Michael Scott"},
  {"season": 5, "episode": 24, "scene": 20, "line": "I had a salmon salad. With water, no ice. I was going to get a soda, but the waiter talked me out of it. Her name was Flo, she had black hair.", "order": 28083, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 24, "scene": 20, "line": "I had Mexican food.", "order": 28084, "speaker": "Stanley Hudson"},
  {"season": 5, "episode": 24, "scene": 20, "line": "So since you guys already ate, you won't be eating the stuff you brought with you for lunch, will you? [walks into kitchen] Oh, wow, Stanley. Is that egg salad? Andy brought some salmon.", "order": 28085, "speaker": "Michael Scott"},
  {"season": 5, "episode": 24, "scene": 20, "line": "That looks great.", "order": 28086, "speaker": "Ryan Howard"},
  {"season": 5, "episode": 24, "scene": 20, "line": "Dwight, is that a meat sandwich? You guys hungry?", "order": 28087, "speaker": "Michael Scott"},
  {"season": 5, "episode": 24, "scene": 20, "line": "I can eat.", "order": 28088, "speaker": "Ryan Howard"},
  {"season": 5, "episode": 24, "scene": 20, "line": "I'm going to o have some of this meat sandwich.", "order": 28089, "speaker": "Michael Scott"},
  {"season": 5, "episode": 24, "scene": 20, "line": "It's pony.", "order": 28090, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 24, "scene": 20, "line": "Mom. What kind of sauce is on your salmon?", "order": 28091, "speaker": "Michael Scott"},
  {"season": 5, "episode": 24, "scene": 20, "line": "Dijonnaise.", "order": 28092, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 24, "scene": 20, "line": "Mmm. Thanks for going out, guys.", "order": 28093, "speaker": "Michael Scott"},
  {"season": 5, "episode": 24, "scene": 20, "line": "Mmm.", "order": 28094, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 24, "scene": 21, "line": "Hey, Meredith. Can I talk to you privately for a second?", "order": 28095, "speaker": "Toby Flenderson"},
  {"season": 5, "episode": 24, "scene": 21, "line": "About what?", "order": 28096, "speaker": "Meredith Palmer"},
  {"season": 5, "episode": 24, "scene": 21, "line": "Your outfit.", "order": 28097, "speaker": "Toby Flenderson"},
  {"season": 5, "episode": 24, "scene": 21, "line": "What? What's wrong with my outfit?", "order": 28098, "speaker": "Meredith Palmer"},
  {"season": 5, "episode": 24, "scene": 21, "line": "You--you might consider pulling it down a touch. It's ri--it's riding up a little high.", "order": 28099, "speaker": "Toby Flenderson"},
  {"season": 5, "episode": 24, "scene": 21, "line": "A bunch of prudes. You know, Oscar's allowed to wear sandals, but I'm not allowed to wear open-toed shoes? [pulls down dress, office gasps] Is that how it goes?", "order": 28100, "speaker": "Meredith Palmer"},
  {"season": 5, "episode": 24, "scene": 21, "line": "Meredith, your boob is out.", "order": 28101, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 24, "scene": 21, "line": "Fine. [pulls dress up, everyone gasps again]", "order": 28102, "speaker": "Meredith Palmer"},
  {"season": 5, "episode": 24, "scene": 21, "line": "Meredith, too far!", "order": 28103, "speaker": "Angela Martin"},
  {"season": 5, "episode": 24, "scene": 21, "line": "Dammit, Meredith, where are your panties?", "order": 28104, "speaker": "Kelly Kapoor"},
  {"season": 5, "episode": 24, "scene": 21, "line": "It's casual day. Happy? [bends over to get a file, exposing herself to the office]", "order": 28105, "speaker": "Meredith Palmer"},
  {"season": 5, "episode": 24, "scene": 22, "line": "[playing chess with Jim] No. IF you do that, I'm gonna do that. IF you do that, I'm gonna do that. IF you do this, I'm gonna do that.", "order": 28106, "speaker": "Creed Bratton"},
  {"season": 5, "episode": 24, "scene": 22, "line": "Well, what if I just do this?", "order": 28107, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 24, "scene": 22, "line": "You don't want to do that.", "order": 28108, "speaker": "Creed Bratton"},
  {"season": 5, "episode": 24, "scene": 23, "line": "I'm just hiding out until all this stuff blows over. With Creed. Playing chess. At work. He's winning. I feel like I'm describing a dream I had.", "order": 28109, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 24, "scene": 24, "line": "I'm sorry you feel that way, Mr. Bart. Is there anything I can do to make things better?", "order": 28110, "speaker": "Ryan Howard"},
  {"season": 5, "episode": 24, "scene": 24, "line": "Whoa, whoa, whoa, whoa, whoa, whoa. Give me the phone. Give me the phone.", "order": 28111, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 24, "scene": 24, "line": "Exactly.", "order": 28112, "speaker": "Ryan Howard"},
  {"season": 5, "episode": 24, "scene": 24, "line": "That's my client.", "order": 28113, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 24, "scene": 24, "line": "Exactly. This is your first complaint in ten years.", "order": 28114, "speaker": "Ryan Howard"},
  {"season": 5, "episode": 24, "scene": 24, "line": "Give me the phone.", "order": 28115, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 24, "scene": 24, "line": "Things have been generally good.", "order": 28116, "speaker": "Ryan Howard"},
  {"season": 5, "episode": 24, "scene": 24, "line": "Give me the phone. Tell him Dwight Schrute wants to talk to him.", "order": 28117, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 24, "scene": 24, "line": "Look, uh, Mr. Bart...", "order": 28118, "speaker": "Ryan Howard"},
  {"season": 5, "episode": 24, "scene": 24, "line": "Hi, Mr. Bart.", "order": 28119, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 24, "scene": 24, "line": "At these prices with this service...", "order": 28120, "speaker": "Ryan Howard"},
  {"season": 5, "episode": 24, "scene": 24, "line": "Hey, it's Dwight here. Give me the phone.", "order": 28121, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 24, "scene": 24, "line": "You're not gonna find this anywhere else.", "order": 28122, "speaker": "Ryan Howard"},
  {"season": 5, "episode": 24, "scene": 24, "line": "Dwight Schrute here. Hey, don't hang up.", "order": 28123, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 24, "scene": 24, "line": "No, no, no. Sir, don't listen.", "order": 28124, "speaker": "Ryan Howard"},
  {"season": 5, "episode": 24, "scene": 24, "line": "I know times are tough right now, and I laud your thriftiness. [tries to pull phone out of Ryan's hand] Ryan!", "order": 28125, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 24, "scene": 24, "line": "Ryan, hand the phone over.", "order": 28126, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 24, "scene": 24, "line": "Stop flustering me, everybody!", "order": 28127, "speaker": "Ryan Howard"},
  {"season": 5, "episode": 24, "scene": 24, "line": "Who let this boy on the phone?", "order": 28128, "speaker": "Stanley Hudson"},
  {"season": 5, "episode": 24, "scene": 24, "line": "Ah! Mr. Bart! Ugh! [slams phone down[ I cannot believe you lost that account. He was my client for ten years!", "order": 28129, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 24, "scene": 24, "line": "You made him lose the account by screaming at him the entire time. It's okay, Ryan.", "order": 28130, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 24, "scene": 24, "line": "You're a secretary! What do you know?", "order": 28131, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 24, "scene": 24, "line": "Okay.", "order": 28132, "speaker": "Ryan Howard"},
  {"season": 5, "episode": 24, "scene": 24, "line": "And you're a temp and I'm taking your phone. I'm confiscating this.", "order": 28133, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 24, "scene": 24, "line": "Hey, hey guys. You know what I want you to do? Shake hands, get over it. That was not a shake, Dwight.", "order": 28134, "speaker": "Michael Scott"},
  {"season": 5, "episode": 24, "scene": 24, "line": "We want our clients back or we quit, Michael.", "order": 28135, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 24, "scene": 24, "line": "Who quits?", "order": 28136, "speaker": "Michael Scott"},
  {"season": 5, "episode": 24, "scene": 24, "line": "Me.", "order": 28137, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 24, "scene": 24, "line": "And me.", "order": 28138, "speaker": "Stanley Hudson"},
  {"season": 5, "episode": 24, "scene": 24, "line": "Me too.", "order": 28139, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 24, "scene": 24, "line": "You guys gotta be kidding.", "order": 28140, "speaker": "Michael Scott"},
  {"season": 5, "episode": 24, "scene": 24, "line": "No, we gotta be deadly serious. We'll start our own paper company. The Schrute-Bernard-Lapin-Vance...Stanley Paper Company.", "order": 28141, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 24, "scene": 24, "line": "I love it. I love this idea, and I fully support you. As a matter of fact, I'm going to give you some seed money. [throws money at Phyllis] There you go. There's some seed money for you. And you can take it, no hard feelings. But if you stay, I want an apology, and I want a big one.", "order": 28142, "speaker": "Michael Scott"},
  {"season": 5, "episode": 24, "scene": 24, "line": "You want us to apologize to you?", "order": 28143, "speaker": "Stanley Hudson"},
  {"season": 5, "episode": 24, "scene": 24, "line": "Yes I do.", "order": 28144, "speaker": "Michael Scott"},
  {"season": 5, "episode": 24, "scene": 24, "line": "That's completely backwards.", "order": 28145, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 24, "scene": 24, "line": "It's frontward's.", "order": 28146, "speaker": "Michael Scott"},
  {"season": 5, "episode": 24, "scene": 24, "line": "Michael, you thought you were attacking corporate, but we were the ones who got hurt. You should be apologizing to us.", "order": 28147, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 24, "scene": 24, "line": "That's right.", "order": 28148, "speaker": "Stanley Hudson"},
  {"season": 5, "episode": 24, "scene": 24, "line": "Right.", "order": 28149, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 24, "scene": 24, "line": "Michael, you always said we were a family. Then you went after us.", "order": 28150, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 24, "scene": 25, "line": "No matter how I look at this, I am in the wrong. And I have looked at this thing, like a hundred different ways. From my point of view, from their point of view...98 others. And the bottom line, I am in the wrong. I'm the bad guy.", "order": 28151, "speaker": "Michael Scott"},
  {"season": 5, "episode": 24, "scene": 26, "line": "I need you to arrange a meeting between me and the sales staff without Ryan and Pam knowing.", "order": 28152, "speaker": "Michael Scott"},
  {"season": 5, "episode": 24, "scene": 26, "line": "Gasp. Are you talking about a secret meeting?", "order": 28153, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 24, "scene": 26, "line": "Whatever you guys did earlier.", "order": 28154, "speaker": "Michael Scott"},
  {"season": 5, "episode": 24, "scene": 26, "line": "I don't know that first things about secret meetings.", "order": 28155, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 24, "scene": 26, "line": "Just do it, okay?", "order": 28156, "speaker": "Michael Scott"},
  {"season": 5, "episode": 24, "scene": 26, "line": "I'll do--", "order": 28157, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 24, "scene": 26, "line": "Get 'em there. Get 'em there.", "order": 28158, "speaker": "Michael Scott"},
  {"season": 5, "episode": 24, "scene": 26, "line": "I'll do the best I can.", "order": 28159, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 24, "scene": 26, "line": "Are you kidding me?", "order": 28160, "speaker": "Michael Scott"},
  {"season": 5, "episode": 24, "scene": 26, "line": "Deceit does not come easy to me.", "order": 28161, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 24, "scene": 26, "line": "Okay, okay, okay.", "order": 28162, "speaker": "Michael Scott"},
  {"season": 5, "episode": 24, "scene": 27, "line": "I really love your outfit.", "order": 28163, "speaker": "Erin Hannon"},
  {"season": 5, "episode": 24, "scene": 27, "line": "Thank you so much for saying that. I can't believe that Toby thinks this is inappropriate.", "order": 28164, "speaker": "Kelly Kapoor"},
  {"season": 5, "episode": 24, "scene": 27, "line": "You look like J-Lo.", "order": 28165, "speaker": "Erin Hannon"},
  {"season": 5, "episode": 24, "scene": 28, "line": "So from the bottom of my heart to the top of my heart, I am sorry.", "order": 28166, "speaker": "Michael Scott"},
  {"season": 5, "episode": 24, "scene": 28, "line": "Are you giving us our clients back?", "order": 28167, "speaker": "Stanley Hudson"},
  {"season": 5, "episode": 24, "scene": 28, "line": "If we could just focus and go down the line, and everyone would say, 'apology accepted', I think we would all feel better. And then we can break out my gift to you--complimentary white chocolate bark.", "order": 28168, "speaker": "Michael Scott"},
  {"season": 5, "episode": 24, "scene": 28, "line": "Nobody likes that stuff except for you.", "order": 28169, "speaker": "Stanley Hudson"},
  {"season": 5, "episode": 24, "scene": 28, "line": "They wouldn't make it unless people liked it.", "order": 28170, "speaker": "Michael Scott"},
  {"season": 5, "episode": 24, "scene": 28, "line": "Michael, just give us our clients back. That's all we care about.", "order": 28171, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 24, "scene": 28, "line": "Okay. If I give you your clients back there won't be enough clients for Ryan and Pam, and I promised both of them they were gonna be salespeople.", "order": 28172, "speaker": "Michael Scott"},
  {"season": 5, "episode": 24, "scene": 28, "line": "So?", "order": 28173, "speaker": "Stanley Hudson"},
  {"season": 5, "episode": 24, "scene": 28, "line": "Fine, have your clients. I can see that's what you wanted all along. [everyone mutters 'okay'] However, I rescind my apology.", "order": 28174, "speaker": "Michael Scott"},
  {"season": 5, "episode": 24, "scene": 28, "line": "That's fair.", "order": 28175, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 24, "scene": 28, "line": "You are not reacting at all as I had hoped.", "order": 28176, "speaker": "Michael Scott"},
  {"season": 5, "episode": 24, "scene": 28, "line": "What'd I tell you about building forts in my warehouse?", "order": 28177, "speaker": "Darryl Philbin"},
  {"season": 5, "episode": 24, "scene": 29, "line": "Michael gave all of our clients back to their old salespeople, so now there's not enough for both me and Pam to stay on.", "order": 28178, "speaker": "Ryan Howard"},
  {"season": 5, "episode": 24, "scene": 29, "line": "He can only keep one of us as a salesperson now. He'll make his decision by the end of the day.", "order": 28179, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 24, "scene": 29, "line": "I think you should get it. You really grew into it.", "order": 28180, "speaker": "Ryan Howard"},
  {"season": 5, "episode": 24, "scene": 29, "line": "Oh. I think you should get it. You've changed a lot and you'd be good at it.", "order": 28181, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 24, "scene": 29, "line": "If you really think that, will you tell that to Michael? That would go a long way coming from you.", "order": 28182, "speaker": "Ryan Howard"},
  {"season": 5, "episode": 24, "scene": 29, "line": "Yeah.", "order": 28183, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 24, "scene": 29, "line": "Thanks.", "order": 28184, "speaker": "Ryan Howard"},
  {"season": 5, "episode": 24, "scene": 30, "line": "So hey, I'm want to set you up with my daughter.", "order": 28185, "speaker": "Creed Bratton"},
  {"season": 5, "episode": 24, "scene": 30, "line": "Oh, I'm engaged to Pam.", "order": 28186, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 24, "scene": 30, "line": "I thought you were gay.", "order": 28187, "speaker": "Creed Bratton"},
  {"season": 5, "episode": 24, "scene": 30, "line": "Then why would you want to set me up with your daughter?", "order": 28188, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 24, "scene": 30, "line": "I don't know.", "order": 28189, "speaker": "Creed Bratton"},
  {"season": 5, "episode": 24, "scene": 30, "line": "Jim. Can I talk to you for a minute?", "order": 28190, "speaker": "Michael Scott"},
  {"season": 5, "episode": 24, "scene": 30, "line": "Sure. Let me just finish this turn.", "order": 28191, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 24, "scene": 30, "line": "[puts Scrabble pieces on board] And Bingo was his name. Let's go.", "order": 28192, "speaker": "Michael Scott"},
  {"season": 5, "episode": 24, "scene": 30, "line": "Challenge.", "order": 28193, "speaker": "Creed Bratton"},
  {"season": 5, "episode": 24, "scene": 31, "line": "I need your help. This whole Pam/Ryan debate is screwing with my head.", "order": 28194, "speaker": "Michael Scott"},
  {"season": 5, "episode": 24, "scene": 31, "line": "I don't want to be biased, but I am very close to Ryan. You know that.", "order": 28195, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 24, "scene": 31, "line": "You're close with Pam too.", "order": 28196, "speaker": "Michael Scott"},
  {"season": 5, "episode": 24, "scene": 31, "line": "Eh, she's nice, I guess. You know what? Best thing to do, Pros and Cons list.", "order": 28197, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 24, "scene": 31, "line": "Pro for Ryan, he went to business school, he's my number one choice.", "order": 28198, "speaker": "Michael Scott"},
  {"season": 5, "episode": 24, "scene": 31, "line": "Well, that's--", "order": 28199, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 24, "scene": 31, "line": "I like his hair.", "order": 28200, "speaker": "Michael Scott"},
  {"season": 5, "episode": 24, "scene": 31, "line": "Cons, Ryan. He defrauded the company. He has never made a sale.", "order": 28201, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 24, "scene": 31, "line": "Okay.", "order": 28202, "speaker": "Michael Scott"},
  {"season": 5, "episode": 24, "scene": 31, "line": "He definitely stole my iPod.", "order": 28203, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 24, "scene": 31, "line": "That sounds pretty biased.", "order": 28204, "speaker": "Michael Scott"},
  {"season": 5, "episode": 24, "scene": 31, "line": "You didn't hear my pros. Environmentally-conscious 'cause his mom drives him to work everyday.", "order": 28205, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 24, "scene": 31, "line": "Okay. Pam, pros.", "order": 28206, "speaker": "Michael Scott"},
  {"season": 5, "episode": 24, "scene": 31, "line": "Mm-hmm.", "order": 28207, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 24, "scene": 31, "line": "I like her. I think that she is a fast learner. Cons, she doesn't always follow through. Sh--New York and the whole art school thing. And Roy. She has a weird voice.", "order": 28208, "speaker": "Michael Scott"},
  {"season": 5, "episode": 24, "scene": 31, "line": "That's not true.", "order": 28209, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 24, "scene": 31, "line": "I don't need to tell you that.", "order": 28210, "speaker": "Michael Scott"},
  {"season": 5, "episode": 24, "scene": 31, "line": "I think she'd be a really good salesperson.", "order": 28211, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 24, "scene": 31, "line": "I don't think you are being totally impartial though, because you haven't said one bad thing about Pam.", "order": 28212, "speaker": "Michael Scott"},
  {"season": 5, "episode": 24, "scene": 31, "line": "And I won't.", "order": 28213, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 24, "scene": 31, "line": "Okay. So be it, then you've lost credibility. And I'm going to go with my guy, and that's Ryan.", "order": 28214, "speaker": "Michael Scott"},
  {"season": 5, "episode": 24, "scene": 31, "line": "All right. You're right, sometimes when she's tired, she can be a little bit shrill. But that's not a weird voice.", "order": 28215, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 24, "scene": 31, "line": "Oh, wow! Whoa!", "order": 28216, "speaker": "Michael Scott"},
  {"season": 5, "episode": 24, "scene": 31, "line": "Take it easy.", "order": 28217, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 24, "scene": 31, "line": "Ho-ho, man! Wow. [in a high-pitched voice] Honey! I want you to bring the garbage out. 'Cause I'm not going to have sex with you unless you bring out the garba--", "order": 28218, "speaker": "Michael Scott"},
  {"season": 5, "episode": 24, "scene": 32, "line": "I have a very difficult decision to make. It's like last week I was at the video store. Do I rent Devil Wears Prada again? Or do I finally get around to seeing Sophie's Choice? It is what you would call a classic difficult decision.", "order": 28219, "speaker": "Michael Scott"},
  {"season": 5, "episode": 24, "scene": 33, "line": "You don't understand clothing, Toby. You're dressed like this amorphous blob of khaki.", "order": 28220, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 24, "scene": 33, "line": "All right, look, what you gotta understand is that when you come to work, you give up certain rights.", "order": 28221, "speaker": "Toby Flenderson"},
  {"season": 5, "episode": 24, "scene": 33, "line": "Listen up, Flenderson. You're being weak and ineffectual. I'm cowboying this meeting, okay? Here are the new rules. Earth tones only. Also, women are forbidden to wear pants.", "order": 28222, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 24, "scene": 33, "line": "All right, come. Sit down, Dwight.", "order": 28223, "speaker": "Toby Flenderson"},
  {"season": 5, "episode": 24, "scene": 33, "line": "No.", "order": 28224, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 24, "scene": 33, "line": "I'm running this meeting.", "order": 28225, "speaker": "Toby Flenderson"},
  {"season": 5, "episode": 24, "scene": 33, "line": "That's debatable.", "order": 28226, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 24, "scene": 33, "line": "It's not. It's not. Sit down or I am writing you up.", "order": 28227, "speaker": "Toby Flenderson"},
  {"season": 5, "episode": 24, "scene": 33, "line": "Ooh, where has this guy been?", "order": 28228, "speaker": "Meredith Palmer"},
  {"season": 5, "episode": 24, "scene": 33, "line": "Casual Fridays are cancelled. [everyone protests] Let's just not do it anymore.", "order": 28229, "speaker": "Toby Flenderson"},
  {"season": 5, "episode": 24, "scene": 33, "line": "You're running from the problem.", "order": 28230, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 24, "scene": 33, "line": "There's not a single appropriate outfit in this whole--except mine, quite honestly.", "order": 28231, "speaker": "Toby Flenderson"},
  {"season": 5, "episode": 24, "scene": 34, "line": "Pam, can you come in here, please? Have a seat. [clears throat] Well, there's no easy way of saying this. So I think I will just...drag it out. Since the dawn of time there has been--", "order": 28232, "speaker": "Michael Scott"},
  {"season": 5, "episode": 24, "scene": 34, "line": "Just tell me.", "order": 28233, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 24, "scene": 34, "line": "I gave Ryan the sales job. There just weren't enough clients for the two of you. So I had to--", "order": 28234, "speaker": "Michael Scott"},
  {"season": 5, "episode": 24, "scene": 34, "line": "No, okay.", "order": 28235, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 24, "scene": 34, "line": "I'm sorry, sorry.", "order": 28236, "speaker": "Michael Scott"},
  {"season": 5, "episode": 24, "scene": 34, "line": "Okay, I get it. [Michael laughs] Why are you giggling? Is this a joke? [Michael continues to laugh] Michael, is this-- did I get the job?", "order": 28237, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 24, "scene": 34, "line": "[laughing] Yes, you did.", "order": 28238, "speaker": "Michael Scott"},
  {"season": 5, "episode": 24, "scene": 34, "line": "I did?", "order": 28239, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 24, "scene": 34, "line": "Yep.", "order": 28240, "speaker": "Michael Scott"},
  {"season": 5, "episode": 24, "scene": 34, "line": "For real, you're not kidding?", "order": 28241, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 24, "scene": 34, "line": "No, you--no.", "order": 28242, "speaker": "Michael Scott"},
  {"season": 5, "episode": 24, "scene": 34, "line": "I did?", "order": 28243, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 24, "scene": 34, "line": "No, you're the best person for the job. You're going to be great.", "order": 28244, "speaker": "Michael Scott"},
  {"season": 5, "episode": 24, "scene": 34, "line": "Oh, Michael, thank you. Thank you.", "order": 28245, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 24, "scene": 34, "line": "[laughing] Oh, oh!", "order": 28246, "speaker": "Michael Scott"},
  {"season": 5, "episode": 24, "scene": 34, "line": "Thank you. You will not be sorry.", "order": 28247, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 24, "scene": 34, "line": "You should have seen your face. Oh man! Oh, that was classic.", "order": 28248, "speaker": "Michael Scott"},
  {"season": 5, "episode": 24, "scene": 34, "line": "Oh. [laughing] I really thought I didn't get it.", "order": 28249, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 24, "scene": 34, "line": "Oh, really?", "order": 28250, "speaker": "Michael Scott"},
  {"season": 5, "episode": 24, "scene": 34, "line": "Yeah. 'Cause you said I didn't", "order": 28251, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 24, "scene": 34, "line": "Oh, man. That was really good. That was really good.", "order": 28252, "speaker": "Michael Scott"},
  {"season": 5, "episode": 24, "scene": 34, "line": "What about Ryan?", "order": 28253, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 24, "scene": 34, "line": "I don't know, I offered him his temp job back. We'll see. He was not happy at all when I offered him this job and then I told him he didn't get the job, so--", "order": 28254, "speaker": "Michael Scott"},
  {"season": 5, "episode": 24, "scene": 34, "line": "Yeah. Maybe you shouldn't fake fire people anymore.", "order": 28255, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 24, "scene": 34, "line": "I don't appreciate you telling me what to do. So clean out your desk, you are through here.", "order": 28256, "speaker": "Michael Scott"},
  {"season": 5, "episode": 24, "scene": 34, "line": "Thanks, Michael.", "order": 28257, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 24, "scene": 34, "line": "Oh! What's our receptionists name?", "order": 28258, "speaker": "Michael Scott"},
  {"season": 5, "episode": 24, "scene": 34, "line": "Erin.", "order": 28259, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 24, "scene": 34, "line": "Could you send her in?", "order": 28260, "speaker": "Michael Scott"},
  {"season": 5, "episode": 24, "scene": 34, "line": "[opens door] Erin. Michael wants to see you.", "order": 28261, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 24, "scene": 34, "line": "Hello.", "order": 28262, "speaker": "Michael Scott"},
  {"season": 5, "episode": 24, "scene": 34, "line": "Hi.", "order": 28263, "speaker": "Erin Hannon"},
  {"season": 5, "episode": 24, "scene": 34, "line": "Have a seat.", "order": 28264, "speaker": "Michael Scott"},
  {"season": 5, "episode": 24, "scene": 35, "line": "This is very difficult to say, but no one here like you in the office and we are going to have to let you go. [pick up phone] Hank, could you come up here please? Hank is our security guard--", "order": 28265, "speaker": "Michael Scott"},
  {"season": 5, "episode": 24, "scene": 35, "line": "Oh, I can go.", "order": 28266, "speaker": "Erin Hannon"},
  {"season": 5, "episode": 24, "scene": 35, "line": "He will be ushering you out. [starts laughing]", "order": 28267, "speaker": "Michael Scott"},
  {"season": 5, "episode": 24, "scene": 35, "line": "Oh!", "order": 28268, "speaker": "Erin Hannon"},
  {"season": 5, "episode": 24, "scene": 35, "line": "Oh, your face! Oh!", "order": 28269, "speaker": "Michael Scott"},
  {"season": 5, "episode": 24, "scene": 35, "line": "Is--do the people--", "order": 28270, "speaker": "Erin Hannon"},
  {"season": 5, "episode": 24, "scene": 35, "line": "No, I'm kidding. No, you're not--you're not fired. Yet.", "order": 28271, "speaker": "Michael Scott"},
  {"season": 5, "episode": 24, "scene": 35, "line": "Oh!", "order": 28272, "speaker": "Erin Hannon"},
  {"season": 5, "episode": 24, "scene": 35, "line": "Oh...", "order": 28273, "speaker": "Michael Scott"},
  {"season": 5, "episode": 24, "scene": 35, "line": "Do they not like me, though?", "order": 28274, "speaker": "Erin Hannon"},
  {"season": 5, "episode": 24, "scene": 35, "line": "I don't know, actually.", "order": 28275, "speaker": "Michael Scott"},
  {"season": 5, "episode": 24, "scene": 35, "line": "Okay.", "order": 28276, "speaker": "Erin Hannon"},
  {"season": 5, "episode": 25, "scene": 1, "line": "Oh my God! I can't believe it! I jut won an art contest! [Erin screams, Pam and Jim look at each other, Dwight, laughing, gets up and hands her some money]", "order": 28277, "speaker": "Erin Hannon"},
  {"season": 5, "episode": 25, "scene": 1, "line": "[whispering] Thanks. I still don't understand why you wanted me to say that.", "order": 28278, "speaker": "Erin Hannon"},
  {"season": 5, "episode": 25, "scene": 1, "line": "Shut up.", "order": 28279, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 25, "scene": 2, "line": "[laughs hysterically] I got her!", "order": 28280, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 25, "scene": 3, "line": "Not cool, Dwight. [Dwight continues laughing]", "order": 28281, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 25, "scene": 3, "line": "Not cool. [Dwight still laughing]", "order": 28282, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 25, "scene": 4, "line": "[caught by camera crew dancing to 'At the Car Wash', but laughing] Oh, no. I still have the lease on the Michael Scott Paper Company, so occasionally I will sneak down here for a little coffee and dancing. I actually dance all the time. Tip-toeing around corporate - it is a ballet. When I am breaking all the rules, I am break dancing. And expresso.", "order": 28283, "speaker": "Michael Scott"},
  {"season": 5, "episode": 25, "scene": 5, "line": "Guys, I'm scared. I'm really scared. I think I'm growing into a giant. Because look at this normal sized coffee cup. Looks so tiny in my giant hand now. Anybody want to go to lunch with me later on?", "order": 28284, "speaker": "Michael Scott"},
  {"season": 5, "episode": 25, "scene": 5, "line": "I do.", "order": 28285, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 25, "scene": 5, "line": "Okay. How about a woman? Pam?", "order": 28286, "speaker": "Michael Scott"},
  {"season": 5, "episode": 25, "scene": 5, "line": "Oh, I can't do lunch. I was just sending you an email. Jim and I need to leave early today to meet with our contractor.", "order": 28287, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 25, "scene": 5, "line": "Oh, really? Ok.", "order": 28288, "speaker": "Michael Scott"},
  {"season": 5, "episode": 25, "scene": 5, "line": "Just sent it.", "order": 28289, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 25, "scene": 5, "line": "What about the rest of you? [complete silence]", "order": 28290, "speaker": "Michael Scott"},
  {"season": 5, "episode": 25, "scene": 6, "line": "At Dunder-Mifflin there is a very strict no lunch with the boss policy and I don't know who instituted it. I think it started right after my predecessor stepped down, but at the Michael Scott Paper Company I really enjoyed having lunch with Pam and Ryan everyday. So, rules be damned, I wanna have lunch with these people.", "order": 28291, "speaker": "Michael Scott"},
  {"season": 5, "episode": 25, "scene": 7, "line": "Who else? Who else? Ryan?", "order": 28292, "speaker": "Michael Scott"},
  {"season": 5, "episode": 25, "scene": 7, "line": "I don't do lunch. I'm eating five small meals a day now.", "order": 28293, "speaker": "Ryan Howard"},
  {"season": 5, "episode": 25, "scene": 8, "line": "Now that I'm back to doing the job of a temp, again, I find that food is one thing I can control.", "order": 28294, "speaker": "Ryan Howard"},
  {"season": 5, "episode": 25, "scene": 9, "line": "Ok. Anyone? Anyone? At all? Accounting? I am accounting on you to go to lunch with me.", "order": 28295, "speaker": "Michael Scott"},
  {"season": 5, "episode": 25, "scene": 9, "line": "No. I don't want to stay late to have a two hour lunch.", "order": 28296, "speaker": "Angela Martin"},
  {"season": 5, "episode": 25, "scene": 9, "line": "Michael, we have a lot of work to do.", "order": 28297, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 25, "scene": 9, "line": "What?", "order": 28298, "speaker": "Michael Scott"},
  {"season": 5, "episode": 25, "scene": 9, "line": "Work.", "order": 28299, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 25, "scene": 9, "line": "Ugh! God! What happened to you people? [talking like a robot] We are just office drones. We are office drones. All we do is work... is work.", "order": 28300, "speaker": "Michael Scott"},
  {"season": 5, "episode": 25, "scene": 10, "line": "If you don't take out his battery, he just keeps going all day.", "order": 28301, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 25, "scene": 11, "line": "Oh, no. Your battery fell out.", "order": 28302, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 25, "scene": 11, "line": "[still talking like a robot] I... was just learning... to... love.", "order": 28303, "speaker": "Michael Scott"},
  {"season": 5, "episode": 25, "scene": 12, "line": "Hi, guys. How you doing?", "order": 28304, "speaker": "Erin Hannon"},
  {"season": 5, "episode": 25, "scene": 12, "line": "Erin, how many times do I have to tell you? It is not necessary for you to ask us how we are doing every time you interact with us.", "order": 28305, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 25, "scene": 12, "line": "Right. I'm sorry.", "order": 28306, "speaker": "Erin Hannon"},
  {"season": 5, "episode": 25, "scene": 12, "line": "[sighs] Now, how can I help you?", "order": 28307, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 25, "scene": 12, "line": "Did somebody here leave a map in the printer to Youngstown, Ohio?", "order": 28308, "speaker": "Erin Hannon"},
  {"season": 5, "episode": 25, "scene": 12, "line": "Attention, office. Who here is planning a trip to Youngstown, Ohio? I will take your silence to mean that you are all hiding something. This location is the Superior Court -", "order": 28309, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 25, "scene": 12, "line": "So someone is going to a court. Big deal.", "order": 28310, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 25, "scene": 12, "line": "It is a big deal. Cause there's only a handful of reasons why someone would ever go to a courthouse in Ohio and not be charged with a crime. To claim an inheritance from a deceased relative. To obtain a learner's permit at age 14 and a half instead of 15. Erin, let me see your birth certificate.", "order": 28311, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 25, "scene": 12, "line": "Sure.", "order": 28312, "speaker": "Erin Hannon"},
  {"season": 5, "episode": 25, "scene": 13, "line": "There are other reasons to go to Ohio.", "order": 28313, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 25, "scene": 13, "line": "We're getting married today.", "order": 28314, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 25, "scene": 13, "line": "So, it turns out it's the closest place to get a marriage license without a 3 day waiting period.", "order": 28315, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 25, "scene": 13, "line": "Tell 'em how it happened.", "order": 28316, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 25, "scene": 13, "line": "Ok. So, we're going through all the wedding plans and, boy, it is complicated.", "order": 28317, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 25, "scene": 13, "line": "And very expensive.", "order": 28318, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 25, "scene": 13, "line": "Very expensive. Cause you say you want a small wedding and that's great but then you have to invite -", "order": 28319, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 25, "scene": 13, "line": "You can't leave anyone out.", "order": 28320, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 25, "scene": 13, "line": "No one.", "order": 28321, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 25, "scene": 13, "line": "Ok, just get to the good part.", "order": 28322, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 25, "scene": 13, "line": "Ok. Right. Oh, so this morning we are having breakfast together and I just looked up from my cereal and I said, ' You know what I want to do today? I want to marry you.'", "order": 28323, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 25, "scene": 13, "line": "I had just woken up. I didn't look cute. That's how I knew he meant it.", "order": 28324, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 25, "scene": 14, "line": "Michael? Michael sorry to bother you. Are you going to be working down here? Do you want these down here now?", "order": 28325, "speaker": "Erin Hannon"},
  {"season": 5, "episode": 25, "scene": 14, "line": "No work. No work. No work. I come in here to release frustration. Ooh. Ah. Ah. Ah. Ooh.", "order": 28326, "speaker": "Michael Scott"},
  {"season": 5, "episode": 25, "scene": 14, "line": "Well, I like to swim.", "order": 28327, "speaker": "Erin Hannon"},
  {"season": 5, "episode": 25, "scene": 14, "line": "That's good.", "order": 28328, "speaker": "Michael Scott"},
  {"season": 5, "episode": 25, "scene": 14, "line": "You have a cool place to come hang.", "order": 28329, "speaker": "Erin Hannon"},
  {"season": 5, "episode": 25, "scene": 14, "line": "If you ever want to come down here, door's always open, lock's broken, so...", "order": 28330, "speaker": "Michael Scott"},
  {"season": 5, "episode": 25, "scene": 14, "line": "Thanks.", "order": 28331, "speaker": "Erin Hannon"},
  {"season": 5, "episode": 25, "scene": 14, "line": "Come on in. Hey, hey, hey. Here we go. Here we go. Yeah.", "order": 28332, "speaker": "Michael Scott"},
  {"season": 5, "episode": 25, "scene": 14, "line": "Wow.", "order": 28333, "speaker": "Erin Hannon"},
  {"season": 5, "episode": 25, "scene": 14, "line": "Now you got it.", "order": 28334, "speaker": "Michael Scott"},
  {"season": 5, "episode": 25, "scene": 14, "line": "Now I do got it.", "order": 28335, "speaker": "Erin Hannon"},
  {"season": 5, "episode": 25, "scene": 14, "line": "Now you got it. Hey, you want some espresso?", "order": 28336, "speaker": "Michael Scott"},
  {"season": 5, "episode": 25, "scene": 14, "line": "Oh, yeah.", "order": 28337, "speaker": "Erin Hannon"},
  {"season": 5, "episode": 25, "scene": 14, "line": "You gotta keep yourself dehydrated.", "order": 28338, "speaker": "Michael Scott"},
  {"season": 5, "episode": 25, "scene": 14, "line": "That's rule #1.", "order": 28339, "speaker": "Erin Hannon"},
  {"season": 5, "episode": 25, "scene": 14, "line": "Ok. I love it. I love it.", "order": 28340, "speaker": "Michael Scott"},
  {"season": 5, "episode": 25, "scene": 14, "line": "Whew.", "order": 28341, "speaker": "Erin Hannon"},
  {"season": 5, "episode": 25, "scene": 14, "line": "I love it.", "order": 28342, "speaker": "Michael Scott"},
  {"season": 5, "episode": 25, "scene": 15, "line": "Guys, I believe that I have figured out what is up your butts. There's no reason to be scared. The bad man is gone.Charles is gone.", "order": 28343, "speaker": "Michael Scott"},
  {"season": 5, "episode": 25, "scene": 16, "line": "Charles really did a number on these guys. They are way too focused on work. When I was in charge, this place was like Dave and Buster's People just hanging out, having, fun, eating apps. I don't know. It's like [pause] Dave died or something.", "order": 28344, "speaker": "Michael Scott"},
  {"season": 5, "episode": 25, "scene": 17, "line": "Daddy's here and daddy is going to take care of you.", "order": 28345, "speaker": "Michael Scott"},
  {"season": 5, "episode": 25, "scene": 17, "line": "Please don't refer to yourself as our daddy.", "order": 28346, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 25, "scene": 17, "line": "I am your big daddy and I am gonna kiss da boo boo.", "order": 28347, "speaker": "Michael Scott"},
  {"season": 5, "episode": 25, "scene": 17, "line": "[baby voice] Wittle Andy is afwaid.", "order": 28348, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 25, "scene": 17, "line": "Andy's afwaid?", "order": 28349, "speaker": "Michael Scott"},
  {"season": 5, "episode": 25, "scene": 17, "line": "Yes.", "order": 28350, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 25, "scene": 17, "line": "Are you all afwaid?", "order": 28351, "speaker": "Michael Scott"},
  {"season": 5, "episode": 25, "scene": 17, "line": "No.", "order": 28352, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 25, "scene": 17, "line": "Daddy's here for you. My wittle angels. Ok. I think that I have figured a way to get you guys out of your funk.", "order": 28353, "speaker": "Michael Scott"},
  {"season": 5, "episode": 25, "scene": 17, "line": "What?", "order": 28354, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 25, "scene": 17, "line": "Funk is the problem and the solution.", "order": 28355, "speaker": "Michael Scott"},
  {"season": 5, "episode": 25, "scene": 17, "line": "That makes sense.", "order": 28356, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 25, "scene": 17, "line": "I've taken my downstairs office and I have turned it into a place to hang out. A place where unattractive and attractive people can get together. To meet. To greet. To see the ones that you love. To love the ones that you see.", "order": 28357, "speaker": "Michael Scott"},
  {"season": 5, "episode": 25, "scene": 17, "line": "Is this our punishment for not wanting to have lunch with you?", "order": 28358, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 25, "scene": 17, "line": "Why don't you get over lunch, Oscar? Everybody else is past it.", "order": 28359, "speaker": "Michael Scott"},
  {"season": 5, "episode": 25, "scene": 17, "line": "[Jack Nicholson impression] All work and no play makes Michael a dull boy.", "order": 28360, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 25, "scene": 17, "line": "This is like a haunted coffeehouse thing?", "order": 28361, "speaker": "Meredith Palmer"},
  {"season": 5, "episode": 25, "scene": 17, "line": "No. Dwight is confusing you. That - it's, it's more of a disco.", "order": 28362, "speaker": "Michael Scott"},
  {"season": 5, "episode": 25, "scene": 17, "line": "It's like a haunted disco.", "order": 28363, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 25, "scene": 17, "line": "... with coffee but without the haunted.", "order": 28364, "speaker": "Michael Scott"},
  {"season": 5, "episode": 25, "scene": 17, "line": "It's a combo dance house coffee bar.", "order": 28365, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 25, "scene": 17, "line": "It's a daytime disco on the ground floor of and industrial office building.", "order": 28366, "speaker": "Michael Scott"},
  {"season": 5, "episode": 25, "scene": 17, "line": "It's a cafe disco.", "order": 28367, "speaker": "Erin Hannon"},
  {"season": 5, "episode": 25, "scene": 17, "line": "Exactly.", "order": 28368, "speaker": "Michael Scott"},
  {"season": 5, "episode": 25, "scene": 17, "line": "So, like, a disco cafe?", "order": 28369, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 25, "scene": 17, "line": "Wha - No. No. Not even close. I can't force you to go down but I can entice you. I'm gonna be down there. Erin will be down there from time to time... and all-you-can-eat espresso.", "order": 28370, "speaker": "Michael Scott"},
  {"season": 5, "episode": 25, "scene": 18, "line": "Well, Kevin, I guess it is just me and you.", "order": 28371, "speaker": "Michael Scott"},
  {"season": 5, "episode": 25, "scene": 18, "line": "Yeah.", "order": 28372, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 25, "scene": 18, "line": "Holdin' down the fort.", "order": 28373, "speaker": "Michael Scott"},
  {"season": 5, "episode": 25, "scene": 18, "line": "Yeah. This place is great.", "order": 28374, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 25, "scene": 18, "line": "Thank you.", "order": 28375, "speaker": "Michael Scott"},
  {"season": 5, "episode": 25, "scene": 18, "line": "Oh, my God, Kevin. I am still on hold. You were supposed to get the answer from Michael and come back up.", "order": 28376, "speaker": "Angela Martin"},
  {"season": 5, "episode": 25, "scene": 18, "line": "I'm sorry.", "order": 28377, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 25, "scene": 18, "line": "This is a no work zone. Please respect the lei.", "order": 28378, "speaker": "Michael Scott"},
  {"season": 5, "episode": 25, "scene": 18, "line": "Yeah. Respect the lei.", "order": 28379, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 25, "scene": 18, "line": "Come.", "order": 28380, "speaker": "Angela Martin"},
  {"season": 5, "episode": 25, "scene": 18, "line": "Kevin, stay.", "order": 28381, "speaker": "Michael Scott"},
  {"season": 5, "episode": 25, "scene": 18, "line": "Kevin, come.", "order": 28382, "speaker": "Angela Martin"},
  {"season": 5, "episode": 25, "scene": 18, "line": "Kevin, stay.", "order": 28383, "speaker": "Michael Scott"},
  {"season": 5, "episode": 25, "scene": 18, "line": "Kevin, come.", "order": 28384, "speaker": "Angela Martin"},
  {"season": 5, "episode": 25, "scene": 18, "line": "Stay, stay.", "order": 28385, "speaker": "Michael Scott"},
  {"season": 5, "episode": 25, "scene": 18, "line": "Come on, right now.", "order": 28386, "speaker": "Angela Martin"},
  {"season": 5, "episode": 25, "scene": 18, "line": "Cookie. Kevin, cookie.", "order": 28387, "speaker": "Michael Scott"},
  {"season": 5, "episode": 25, "scene": 18, "line": "Ugh. There is no cookie, Kevin.", "order": 28388, "speaker": "Angela Martin"},
  {"season": 5, "episode": 25, "scene": 18, "line": "[to Michael] Is there a cookie?", "order": 28389, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 25, "scene": 18, "line": "Mmm-hmm.", "order": 28390, "speaker": "Michael Scott"},
  {"season": 5, "episode": 25, "scene": 18, "line": "Wha -", "order": 28391, "speaker": "Angela Martin"},
  {"season": 5, "episode": 25, "scene": 18, "line": "Come on.", "order": 28392, "speaker": "Michael Scott"},
  {"season": 5, "episode": 25, "scene": 18, "line": "Oh, for goodness sakes, Kevin. There's no cookie. There's no cookie. Come on. Come.", "order": 28393, "speaker": "Angela Martin"},
  {"season": 5, "episode": 25, "scene": 18, "line": "I wanted a cookie.", "order": 28394, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 25, "scene": 18, "line": "Completely unacceptable.", "order": 28395, "speaker": "Angela Martin"},
  {"season": 5, "episode": 25, "scene": 19, "line": "Now I know what the founders of Phillip-Morris felt like. you just want to give people a smooth, fun way to relax and suddenly you're just some terrible monster.", "order": 28396, "speaker": "Michael Scott"},
  {"season": 5, "episode": 25, "scene": 20, "line": "[Michael is holding the stereo speaker up to a vent - blaring '[Gonna Make You Sweat] Everybody Dance Now', spins in chair] Oww!", "order": 28397, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 25, "scene": 20, "line": "Aw, what the hell? [goes to Vance Refrigeration] Hi, Jessica. Is Bob in?", "order": 28398, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 25, "scene": 20, "line": "Oh, he's on a call.", "order": 28399, "speaker": "Jessica (Vance Refridgeration)"},
  {"season": 5, "episode": 25, "scene": 20, "line": "Oh, I'll just duck my head in.", "order": 28400, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 25, "scene": 20, "line": "It's pretty important. He wouldn't want to be disturbed. Can I give him a message before he gets off?", "order": 28401, "speaker": "Jessica (Vance Refridgeration)"},
  {"season": 5, "episode": 25, "scene": 20, "line": "Tell him I'm going dancing downstairs in the storage closet between the bathrooms that used to be a utility shower and he should join me there.", "order": 28402, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 25, "scene": 20, "line": "I'll let him know.", "order": 28403, "speaker": "Jessica (Vance Refridgeration)"},
  {"season": 5, "episode": 25, "scene": 20, "line": "Mmm-hmm.", "order": 28404, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 25, "scene": 21, "line": "Phyllis?", "order": 28405, "speaker": "Michael Scott"},
  {"season": 5, "episode": 25, "scene": 21, "line": "Hi.", "order": 28406, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 25, "scene": 21, "line": "Hey!", "order": 28407, "speaker": "Michael Scott"},
  {"season": 5, "episode": 25, "scene": 21, "line": "Hey. I like.", "order": 28408, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 25, "scene": 21, "line": "[starts dancing with Phyllis] Alright. Back. There you are. There you go. Good. Good. Ooh. Show me some attitude.", "order": 28409, "speaker": "Michael Scott"},
  {"season": 5, "episode": 25, "scene": 21, "line": "Oh! Mother ******!", "order": 28410, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 25, "scene": 21, "line": "Oh, God! No! No! No! No! No!", "order": 28411, "speaker": "Michael Scott"},
  {"season": 5, "episode": 25, "scene": 21, "line": "Back!", "order": 28412, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 25, "scene": 21, "line": "Oh, wow! What did you do?", "order": 28413, "speaker": "Michael Scott"},
  {"season": 5, "episode": 25, "scene": 21, "line": "[moans in pain]", "order": 28414, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 25, "scene": 21, "line": "You didn't do that. You're ok. You're ok. Let's lie down. You want to go down? Alright.", "order": 28415, "speaker": "Michael Scott"},
  {"season": 5, "episode": 25, "scene": 21, "line": "Wow! Sorry!", "order": 28416, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 25, "scene": 21, "line": "Ok. That's ok. That's not a problem. Ok, sweetie.", "order": 28417, "speaker": "Michael Scott"},
  {"season": 5, "episode": 25, "scene": 21, "line": "Hey! Hey, hey, hey! I got your text. Who's Phillip?", "order": 28418, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 25, "scene": 21, "line": "No. No. No.", "order": 28419, "speaker": "Michael Scott"},
  {"season": 5, "episode": 25, "scene": 21, "line": "Who tipped you over? Was it Phillip?", "order": 28420, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 25, "scene": 21, "line": "It's my back.", "order": 28421, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 25, "scene": 21, "line": "It's her back. We just - We need - Let's - You know what?", "order": 28422, "speaker": "Michael Scott"},
  {"season": 5, "episode": 25, "scene": 21, "line": "Call a doctor.", "order": 28423, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 25, "scene": 21, "line": "We're going to take care of you. We're gonna get you help. Let's - come on.", "order": 28424, "speaker": "Michael Scott"},
  {"season": 5, "episode": 25, "scene": 21, "line": "No. Call Bob.", "order": 28425, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 25, "scene": 22, "line": "Oh, no. No. No. This is no good.", "order": 28426, "speaker": "Michael Scott"},
  {"season": 5, "episode": 25, "scene": 22, "line": "Yeah. Back injuries are common. Not as common as knee injuries but more common than wrist injuries.", "order": 28427, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 25, "scene": 22, "line": "I don't need you to give me a history lesson. Ok?", "order": 28428, "speaker": "Michael Scott"},
  {"season": 5, "episode": 25, "scene": 22, "line": "What do you think history is?", "order": 28429, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 25, "scene": 22, "line": "It's just, we need to get her out of here because no one is going to want to go in there with a woman writhing around on the floor. Wait, wait, wait. But most importantly we need to get her some medical attention. ASAP. Stat.", "order": 28430, "speaker": "Michael Scott"},
  {"season": 5, "episode": 25, "scene": 23, "line": "[barging through office door] Alright. Here we go.", "order": 28431, "speaker": "Michael Scott"},
  {"season": 5, "episode": 25, "scene": 23, "line": "Out of the way! Move it or lose it!", "order": 28432, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 25, "scene": 23, "line": "You having fun?", "order": 28433, "speaker": "Michael Scott"},
  {"season": 5, "episode": 25, "scene": 23, "line": "Oh, my God! What happened?", "order": 28434, "speaker": "Erin Hannon"},
  {"season": 5, "episode": 25, "scene": 23, "line": "What? Oh, just having to much fun. Phyllis, we're going to put you in here. Dwight's going to take care of you.", "order": 28435, "speaker": "Michael Scott"},
  {"season": 5, "episode": 25, "scene": 23, "line": "What? No. I thought we were going to the hospital.", "order": 28436, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 25, "scene": 23, "line": "You want to get sick you go to the hospital.", "order": 28437, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 25, "scene": 23, "line": "Ok, Dwight. Ok. Good. I do not want anyone to worry.", "order": 28438, "speaker": "Michael Scott"},
  {"season": 5, "episode": 25, "scene": 23, "line": "What happened to Phyllis?", "order": 28439, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 25, "scene": 23, "line": "Oh, you know. Nohting. She's - we were hanging out at Cafe Disco and she had a flare up of am existing injury. But she's a tough, old bird. So...", "order": 28440, "speaker": "Michael Scott"},
  {"season": 5, "episode": 25, "scene": 23, "line": "Can you please go back to work instead of masterminding these situations were in we hurt ourselves?", "order": 28441, "speaker": "Angela Martin"},
  {"season": 5, "episode": 25, "scene": 23, "line": "Ok. Phyllis did injure herself. But she injured herself having fun and I don't think she would trade that memory for anything. [Phyllis can be heard screaming in the backgroud]", "order": 28442, "speaker": "Michael Scott"},
  {"season": 5, "episode": 25, "scene": 23, "line": "I would like the memory of a day uninterrupted by this nonsense.", "order": 28443, "speaker": "Stanley Hudson"},
  {"season": 5, "episode": 25, "scene": 23, "line": "You all took a life here today. you did. The life of the party. Erin?", "order": 28444, "speaker": "Michael Scott"},
  {"season": 5, "episode": 25, "scene": 23, "line": "Yes.", "order": 28445, "speaker": "Erin Hannon"},
  {"season": 5, "episode": 25, "scene": 23, "line": "I want you to go downstairs and I want you to shut it down.", "order": 28446, "speaker": "Michael Scott"},
  {"season": 5, "episode": 25, "scene": 23, "line": "Like unplug the coffee machine and bring it up here.", "order": 28447, "speaker": "Erin Hannon"},
  {"season": 5, "episode": 25, "scene": 23, "line": "I don't care. Bury it. I hope you're happy.", "order": 28448, "speaker": "Michael Scott"},
  {"season": 5, "episode": 25, "scene": 23, "line": "[exits the conference room in an undershirt] I'm gonna need two able bodied men.", "order": 28449, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 25, "scene": 24, "line": "Cafe Disco? More like Crappe Disco.", "order": 28450, "speaker": "Kelly Kapoor"},
  {"season": 5, "episode": 25, "scene": 24, "line": "You're bad. [they turn off the lights and turn on music - start dancing]", "order": 28451, "speaker": "Erin Hannon"},
  {"season": 5, "episode": 25, "scene": 24, "line": "There's girl in there.", "order": 28452, "speaker": "Vance Refrigeration Guy 1"},
  {"season": 5, "episode": 25, "scene": 24, "line": "Where?", "order": 28453, "speaker": "Vance Refrigeration Guy 2"},
  {"season": 5, "episode": 25, "scene": 24, "line": "The other room?", "order": 28454, "speaker": "VRG 1"},
  {"season": 5, "episode": 25, "scene": 24, "line": "What other room?", "order": 28455, "speaker": "VRG 2"},
  {"season": 5, "episode": 25, "scene": 24, "line": "Down the hall.", "order": 28456, "speaker": "VRG 1"},
  {"season": 5, "episode": 25, "scene": 24, "line": "There's girls in there?", "order": 28457, "speaker": "VRG 2"},
  {"season": 5, "episode": 25, "scene": 24, "line": "What'd I just say?", "order": 28458, "speaker": "VRG 1"},
  {"season": 5, "episode": 25, "scene": 24, "line": "You have another sandwich?", "order": 28459, "speaker": "VRG 2"},
  {"season": 5, "episode": 25, "scene": 24, "line": "Forget the sandwich. Girls. Girls.", "order": 28460, "speaker": "VRG 1"},
  {"season": 5, "episode": 25, "scene": 25, "line": "I guess they got what they want. I am eating alone. Might as well be dinner.", "order": 28461, "speaker": "Michael Scott"},
  {"season": 5, "episode": 25, "scene": 26, "line": "You comfy? Alright? Alright. Relax. Relax, ok?", "order": 28462, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 25, "scene": 26, "line": "Dwight! Dwight!", "order": 28463, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 25, "scene": 26, "line": "Come on. Relax. The shirt wasn't doing you any favors. If my assessment in correct, you grind your teeth?", "order": 28464, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 25, "scene": 26, "line": "I do.", "order": 28465, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 25, "scene": 27, "line": "No kidding! She sits three feet from me. It's the most annoying thing. [mimics grinding teeth] It's like children singing Christmas carols.", "order": 28466, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 25, "scene": 28, "line": "Yeah! I love the vibe down here.", "order": 28467, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 25, "scene": 28, "line": "Ashley! You made it!", "order": 28468, "speaker": "Erin Hannon"},
  {"season": 5, "episode": 25, "scene": 28, "line": "You invited someone?", "order": 28469, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 25, "scene": 28, "line": "Oh, yeah. Was I not allowed to do that?", "order": 28470, "speaker": "Erin Hannon"},
  {"season": 5, "episode": 25, "scene": 28, "line": "I've been here 8 years and I've never... [Erin walks away] Rude.", "order": 28471, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 25, "scene": 29, "line": "I'm not sure, but I'm pretty sure I'm in a dance off. Alright!", "order": 28472, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 25, "scene": 30, "line": "Cafe Disco is dead but I can still hear the music in my head.", "order": 28473, "speaker": "Michael Scott"},
  {"season": 5, "episode": 25, "scene": 30, "line": "I hear it, too, Boss.", "order": 28474, "speaker": "Creed Bratton"},
  {"season": 5, "episode": 25, "scene": 31, "line": "It's better than I imagined it!", "order": 28475, "speaker": "Michael Scott"},
  {"season": 5, "episode": 25, "scene": 32, "line": "That feels good, Dwight.", "order": 28476, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 25, "scene": 32, "line": "Tell me where it hurts.", "order": 28477, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 25, "scene": 32, "line": "Right... mmmm... right there.", "order": 28478, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 25, "scene": 32, "line": "Oh yeah. you've got a knot in your crest. This remedy has been passed down in my family for generations. and it always works. My grandfather was told that Diamond Dancer would never race again. they were wrong. He came in 9th in the Apple Creek Derby and his jerky came in 3rd the following year. A majestic beast. So fast. So tender.", "order": 28479, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 25, "scene": 33, "line": "Welcome, welcome! Cafe Disco. I am Michael Scott.", "order": 28480, "speaker": "Michael Scott"},
  {"season": 5, "episode": 25, "scene": 33, "line": "Hey, Angela! Welcome to -", "order": 28481, "speaker": "Erin Hannon"},
  {"season": 5, "episode": 25, "scene": 33, "line": "Stop!", "order": 28482, "speaker": "Angela Martin"},
  {"season": 5, "episode": 25, "scene": 33, "line": "Angela. Angela. Angela. May I interest you in a triple espresso or perhaps a dance?", "order": 28483, "speaker": "Michael Scott"},
  {"season": 5, "episode": 25, "scene": 33, "line": "No. I didn't come down here to get wet and wild. I just need you to sign these.", "order": 28484, "speaker": "Angela Martin"},
  {"season": 5, "episode": 25, "scene": 33, "line": "Oh. Alright. There you go.", "order": 28485, "speaker": "Michael Scott"},
  {"season": 5, "episode": 25, "scene": 33, "line": "No. You need to sign them all.", "order": 28486, "speaker": "Angela Martin"},
  {"season": 5, "episode": 25, "scene": 33, "line": "No. No. No. Here is the deal - one signature for every song.", "order": 28487, "speaker": "Michael Scott"},
  {"season": 5, "episode": 25, "scene": 33, "line": "Look, I hate to be 'that' person but I just don't like the general spirit of music.", "order": 28488, "speaker": "Angela Martin"},
  {"season": 5, "episode": 25, "scene": 33, "line": "I know. I know, Angela. A lot of people doubted Cafe Disco at first but it is a magical place. You have to give it a chance. If these walls could tale they would say, ' This is a magical place! You are safe here. We have talking walls. We're not going to eat you.' [Angela shows Michael the papers again] No.", "order": 28489, "speaker": "Michael Scott"},
  {"season": 5, "episode": 25, "scene": 34, "line": "This is oil from the gland of an otter. It keeps their fur water resistant, as well as traps heat. Now I need you to lie still for an hour.", "order": 28490, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 25, "scene": 34, "line": "An hour? I can't stay here an hour.", "order": 28491, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 25, "scene": 34, "line": "Whoa! Whoa, whoa, girl. Whoa. Whoa.", "order": 28492, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 25, "scene": 35, "line": "Boss, this used to hang from my windshield but it belongs in here.", "order": 28493, "speaker": "Creed Bratton"},
  {"season": 5, "episode": 25, "scene": 35, "line": "Hey, thank you, Creed. you're really getting this place.", "order": 28494, "speaker": "Michael Scott"},
  {"season": 5, "episode": 25, "scene": 35, "line": "No problem. I'll just have no idea who's driving behind me now.", "order": 28495, "speaker": "Creed Bratton"},
  {"season": 5, "episode": 25, "scene": 36, "line": "I'm gonna with the python.", "order": 28496, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 25, "scene": 36, "line": "But the rattler's so scary.", "order": 28497, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 25, "scene": 36, "line": "No. Please. I find the rattle soothing. It puts me to sleep.", "order": 28498, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 25, "scene": 36, "line": "I think Bob is gonna cheat on me with his new secretary. [she starts to giggle]", "order": 28499, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 25, "scene": 36, "line": "What's so funny?", "order": 28500, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 25, "scene": 36, "line": "When I say it out loud it's so silly. [they both laugh]", "order": 28501, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 25, "scene": 37, "line": "Hey! Hey! Angela, no! No cleaning up!", "order": 28502, "speaker": "Michael Scott"},
  {"season": 5, "episode": 25, "scene": 37, "line": "You are forcing me to be down here. Am I not allowed to have some fun?", "order": 28503, "speaker": "Angela Martin"},
  {"season": 5, "episode": 25, "scene": 37, "line": "No... cleaning... up.", "order": 28504, "speaker": "Michael Scott"},
  {"season": 5, "episode": 25, "scene": 38, "line": "[waiting outside the bathroom for Pam, he is holding a hand-picked bouquet of flowers and pacing] These are for you.", "order": 28505, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 25, "scene": 38, "line": "They're beautiful.", "order": 28506, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 25, "scene": 38, "line": "You ready?", "order": 28507, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 25, "scene": 38, "line": "Yeah.", "order": 28508, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 25, "scene": 39, "line": "We should probably stop by. It'd mean a lot to him.", "order": 28509, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 25, "scene": 40, "line": "Hey! Hey! Jim and Pam! Can you believe this? It's really happening.", "order": 28510, "speaker": "Michael Scott"},
  {"season": 5, "episode": 25, "scene": 40, "line": "Wanna dance, Dwight?", "order": 28511, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 25, "scene": 40, "line": "Ordinarily I would say no but you need to move to reduce lactic acid build-up. Also, this song is fantastic.", "order": 28512, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 25, "scene": 40, "line": "Mind if I steal my wife?", "order": 28513, "speaker": "Bob Vance"},
  {"season": 5, "episode": 25, "scene": 40, "line": "You can't steal what is legally your property.", "order": 28514, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 25, "scene": 40, "line": "Are those staples?", "order": 28515, "speaker": "Bob Vance"},
  {"season": 5, "episode": 25, "scene": 41, "line": "This dance competition is not over.", "order": 28516, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 25, "scene": 41, "line": "What dance competition? I was just dancin' casual with my friends, y'all.", "order": 28517, "speaker": "Kelly Kapoor"},
  {"season": 5, "episode": 25, "scene": 42, "line": "You guys are the best for coming. You don't have to stay if you don't want to. I understand.", "order": 28518, "speaker": "Michael Scott"},
  {"season": 5, "episode": 25, "scene": 42, "line": "We're gonna stay.", "order": 28519, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 25, "scene": 42, "line": "Really?", "order": 28520, "speaker": "Michael Scott"},
  {"season": 5, "episode": 25, "scene": 42, "line": "Yeah. At least for one more dance.", "order": 28521, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 25, "scene": 42, "line": "Oh, no. Not me. Not me. Get out of here. Cafe Disco! [Dwight hits Michael in ear] Hey! Come on, man. It's not even to 'Y'.", "order": 28522, "speaker": "Michael Scott"},
  {"season": 5, "episode": 25, "scene": 43, "line": "This is so cheesy.", "order": 28523, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 25, "scene": 43, "line": "Yes.", "order": 28524, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 25, "scene": 43, "line": "I like cheesy.", "order": 28525, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 25, "scene": 43, "line": "Me, too.", "order": 28526, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 25, "scene": 43, "line": "Yeah. I think maybe I want a 'wedding' wedding.", "order": 28527, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 25, "scene": 43, "line": "Me, too.", "order": 28528, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 25, "scene": 43, "line": "Really?", "order": 28529, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 25, "scene": 43, "line": "Yeah.", "order": 28530, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 25, "scene": 44, "line": "Would you like to dance?", "order": 28531, "speaker": "Michael Scott"},
  {"season": 5, "episode": 25, "scene": 44, "line": "No. [Camera pans to down to show her swinging her foot to the music]", "order": 28532, "speaker": "Angela Martin"},
  {"season": 5, "episode": 25, "scene": 45, "line": "Yeah. No. Well, ok.", "order": 28533, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 25, "scene": 45, "line": "Stop squirming.", "order": 28534, "speaker": "Kelly Kapoor"},
  {"season": 5, "episode": 25, "scene": 45, "line": "Well, stop trying to poke me with a sharp thing.", "order": 28535, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 25, "scene": 45, "line": "You wanted to do this. Just be brave.", "order": 28536, "speaker": "Kelly Kapoor"},
  {"season": 5, "episode": 25, "scene": 45, "line": "I, I - but you're not a professional and I'm thinking maybe we should have gone to a professional.", "order": 28537, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 25, "scene": 45, "line": "I'm doing it. No. I'm doing it for free. You have to stop squirming otherwise I'm gonna mess up.", "order": 28538, "speaker": "Kelly Kapoor"},
  {"season": 5, "episode": 25, "scene": 45, "line": "Are you sure that's not the 'gay' ear?", "order": 28539, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 25, "scene": 45, "line": "Gay ear? Are you 12 years old?", "order": 28540, "speaker": "Kelly Kapoor"},
  {"season": 5, "episode": 25, "scene": 45, "line": "Well, I...", "order": 28541, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 25, "scene": 45, "line": "Look, I'm gonna count to 3.", "order": 28542, "speaker": "Kelly Kapoor"},
  {"season": 5, "episode": 25, "scene": 45, "line": "Count to twen - count to twenty.", "order": 28543, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 25, "scene": 45, "line": "No. I'm gonna count to 3.", "order": 28544, "speaker": "Kelly Kapoor"},
  {"season": 5, "episode": 25, "scene": 45, "line": "K", "order": 28545, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 25, "scene": 45, "line": "1... 2... 3", "order": 28546, "speaker": "Kelly Kapoor"},
  {"season": 5, "episode": 25, "scene": 45, "line": "Agh! Son of a bitch!", "order": 28547, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 25, "scene": 45, "line": "Andy, that was just the ice.", "order": 28548, "speaker": "Kelly Kapoor"},
  {"season": 5, "episode": 25, "scene": 45, "line": "It was?", "order": 28549, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 25, "scene": 45, "line": "Yeah. It was.", "order": 28550, "speaker": "Kelly Kapoor"},
  {"season": 5, "episode": 25, "scene": 46, "line": "Hey, Kevin.", "order": 28551, "speaker": "Lynn"},
  {"season": 5, "episode": 25, "scene": 46, "line": "Hi. Did you have bacon for breakfast?", "order": 28552, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 25, "scene": 46, "line": "No.", "order": 28553, "speaker": "Lynn"},
  {"season": 5, "episode": 25, "scene": 46, "line": "Oh...cause you smell like bacon.", "order": 28554, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 25, "scene": 46, "line": "I didn't think I did. How's everything going?", "order": 28555, "speaker": "Lynn"},
  {"season": 5, "episode": 25, "scene": 46, "line": "Tight. I'm going down the hall to this office to see Michael - there's free espressos.", "order": 28556, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 25, "scene": 46, "line": "Oh.", "order": 28557, "speaker": "Lynn"},
  {"season": 5, "episode": 25, "scene": 46, "line": "I would invite you but I remember that you just want to be friends.", "order": 28558, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 25, "scene": 46, "line": "We can still...", "order": 28559, "speaker": "Lynn"},
  {"season": 5, "episode": 25, "scene": 46, "line": "Are you sure that you didn't eat bacon?", "order": 28560, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 25, "scene": 46, "line": "Yes.", "order": 28561, "speaker": "Lynn"},
  {"season": 5, "episode": 25, "scene": 46, "line": "Oh. Whatever it is I like it. Bye.", "order": 28562, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 25, "scene": 47, "line": "Yes, I had some bacon this morning. I just didn't think it was anyone's business.", "order": 28563, "speaker": "Lynn"},
  {"season": 5, "episode": 25, "scene": 48, "line": "I love the smell of bacon on a woman.", "order": 28564, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 25, "scene": 49, "line": "Hey, Lynn, it's Kevin. I was thinking about going and getting a coffee at the Cafe Disco, wondering if you want to go with. Bye. Angela, my stomach is really hurting.", "order": 28565, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 25, "scene": 49, "line": "Just go down, Kevin.", "order": 28566, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 25, "scene": 50, "line": "My grandpa was the funnest guy I ever knew. He would sing. He would dance. He would....fall down. But then...he started going to these meetings and everything changed. He...stopped going out at night. He got a job. The fun just dried up. It was really sad. I don't want that to happen to you guys. I'm not gonna let it happen.", "order": 28567, "speaker": "Michael Scott"},
  {"season": 5, "episode": 25, "scene": 51, "line": "How did I end up back in Crazytown?", "order": 28568, "speaker": "Stanley Hudson"},
  {"season": 5, "episode": 25, "scene": 52, "line": "It is hard to imagine you playing softball.", "order": 28569, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 25, "scene": 52, "line": "I was all-county my junior and senior years.", "order": 28570, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 25, "scene": 52, "line": "Oh yeah? Big deal. I once hit three home runs against Little Flower Catholic.", "order": 28571, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 25, "scene": 52, "line": "The girls school?", "order": 28572, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 25, "scene": 53, "line": "I played high school softball. Notice I didn't say 'girls' high school softball. Not after the landmark case of Shrute vs. the Lackawanna County Board of Education.That was one case the Pennsylvania Supreme Court got right. I hit the ball right over the protest banners.", "order": 28573, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 25, "scene": 54, "line": "[sarcastically] Oh, no. I love Ohio. Next time you're there say 'Hi' to my cousin Amel and then kick him in the groin area because he's a cheat and a ball hog. And if he's still married to Clarissa, kick her in the groin area, too. 'Cause she's a heart breaking tart...and a ball hog.", "order": 28574, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 26, "scene": 1, "line": "[phone rings, whispering] Dunder Mifflin, this is Erin... He's not available right now... Uh huh... Yes... Sure, I'll give him the message when he gets up-- gets back.", "order": 28575, "speaker": "Erin Hannon"},
  {"season": 5, "episode": 26, "scene": 2, "line": "Michael had chicken potpie for lunch. Actually, let me rephrase that, Michael had an entire chicken pot pie for lunch, and- let me be more specific. Michael ate an entire family-sized chicken potpie for lunch and then he promptly fell asleep, so we're all trying to be very quiet so as to not wake him up before 5:00 pm. Which, actually, should be in about... ten minutes.", "order": 28576, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 26, "scene": 3, "line": "[whispering] Okay, I'm gonna go in there and change the computer. Are you sure you can change his watch?", "order": 28577, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 26, "scene": 3, "line": "[whispering] I can do it.", "order": 28578, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 26, "scene": 3, "line": "[whispering] What do you need from me?", "order": 28579, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 26, "scene": 4, "line": "Normally I don't condone leaving early, but I have an appointment with the horse doctor. How that horse became a doctor, I don't know. [laughs heartily] No, I'm kidding. He's just a regular doctor who shoots your horse in the head when its leg is broken.", "order": 28580, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 26, "scene": 5, "line": "[mumbles in his sleep as Pam and Jim change the clocks in his office]", "order": 28581, "speaker": "Michael Scott"},
  {"season": 5, "episode": 26, "scene": 6, "line": "[changing the clock in Michael's car] Like clockwork.", "order": 28582, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 26, "scene": 7, "line": "[silently counting] Three, two, one... [Erin turns on the office lights, all laugh loudly]", "order": 28583, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 26, "scene": 7, "line": "[wakes up, joins in with the laughter] Uh oh. What's so funny?", "order": 28584, "speaker": "Michael Scott"},
  {"season": 5, "episode": 26, "scene": 7, "line": "You had to be there.", "order": 28585, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 26, "scene": 7, "line": "Oh yay! Geography joke. [still laughing, notices the time] Oh! Wow! Okay. Alright, let's all go home. Come on. See you all tamale.", "order": 28586, "speaker": "Michael Scott"},
  {"season": 5, "episode": 26, "scene": 8, "line": "See you later!", "order": 28587, "speaker": "Michael Scott"},
  {"season": 5, "episode": 26, "scene": 8, "line": "Bye Michael! [all applaud and cheer, Dwight imitates gunfire] Yeah!", "order": 28588, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 26, "scene": 9, "line": "[at company picnic] All right, you ready for this?", "order": 28589, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 26, "scene": 9, "line": "Yeah, we walk around, everyone sees our faces and we leave, right?", "order": 28590, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 26, "scene": 9, "line": "Yeah.", "order": 28591, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 26, "scene": 9, "line": "Okay.", "order": 28592, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 26, "scene": 9, "line": "Wait, should I have left the car running? [both laugh]", "order": 28593, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 26, "scene": 10, "line": "Oh, don't get us wrong, we like picnics.", "order": 28594, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 26, "scene": 10, "line": "Come on, who doesn't like a picnic?", "order": 28595, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 26, "scene": 10, "line": "Tell them what happened last year.", "order": 28596, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 26, "scene": 10, "line": "I had this huge spider in my baseball mitt.", "order": 28597, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 26, "scene": 10, "line": "No, no, that guy who hit on me.", "order": 28598, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 26, "scene": 10, "line": "Oh, right, some drunk guy hit on Pam last year. Said he was grabbing her for balance.", "order": 28599, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 26, "scene": 10, "line": "Yeah, you don't grab these [gestures to her chest] for balance.", "order": 28600, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 26, "scene": 10, "line": "Well...", "order": 28601, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 26, "scene": 11, "line": "I'm gonna say 30.", "order": 28602, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 26, "scene": 11, "line": "Ah, 40. Insect repellent, which we clearly need, reduces the effectiveness of SPF.", "order": 28603, "speaker": "Rolph"},
  {"season": 5, "episode": 26, "scene": 11, "line": "Good point, but, thought of that already. Combination SPF/repellent.", "order": 28604, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 26, "scene": 11, "line": "Woah. Homemade?", "order": 28605, "speaker": "Rolph"},
  {"season": 5, "episode": 26, "scene": 11, "line": "Of course. You think the EPA would ever allow that much DEET? [both laugh]", "order": 28606, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 26, "scene": 12, "line": "Rolph is my best friend. We met in a shoe store. I heard him asking for a shoe that could increase his speed and not leave any tracks.", "order": 28607, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 26, "scene": 13, "line": "Michael, isn't that Holly?", "order": 28608, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 26, "scene": 13, "line": "We're just friends. [approaches Holly and AJ] Hey, hey, wait a second. Who let you in here, is what I want to know.", "order": 28609, "speaker": "Michael Scott"},
  {"season": 5, "episode": 26, "scene": 13, "line": "Oh no, I see they're letting just anybody in here.", "order": 28610, "speaker": "Holly Flax"},
  {"season": 5, "episode": 26, "scene": 13, "line": "Mmhmm. Yeah, that's right.", "order": 28611, "speaker": "Michael Scott"},
  {"season": 5, "episode": 26, "scene": 13, "line": "All right.", "order": 28612, "speaker": "Holly Flax"},
  {"season": 5, "episode": 26, "scene": 13, "line": "All right. Mmhmm. [both start laughing and hug] Hey.", "order": 28613, "speaker": "Michael Scott"},
  {"season": 5, "episode": 26, "scene": 13, "line": "Hi. You remember AJ, my boyfriend?", "order": 28614, "speaker": "Holly Flax"},
  {"season": 5, "episode": 26, "scene": 13, "line": "A little bit. Uh, I meet a lot of people.", "order": 28615, "speaker": "Michael Scott"},
  {"season": 5, "episode": 26, "scene": 13, "line": "Hey Michael.", "order": 28616, "speaker": "A.J."},
  {"season": 5, "episode": 26, "scene": 13, "line": "Hey. [hugs AJ a little too forcefully] Arrggh. So would you guys like some lemonade? Or one of you? Or both of you? Either or. The combinations are endless.", "order": 28617, "speaker": "Michael Scott"},
  {"season": 5, "episode": 26, "scene": 13, "line": "Lemonade sounds great.", "order": 28618, "speaker": "Holly Flax"},
  {"season": 5, "episode": 26, "scene": 13, "line": "Okay.", "order": 28619, "speaker": "Michael Scott"},
  {"season": 5, "episode": 26, "scene": 13, "line": "I'd love an iced tea, actually.", "order": 28620, "speaker": "A.J."},
  {"season": 5, "episode": 26, "scene": 13, "line": "You can go to hell. [laughs] I'm kidding. Um, sure, I will get you the best iced tea in the world.", "order": 28621, "speaker": "Michael Scott"},
  {"season": 5, "episode": 26, "scene": 14, "line": "I lied to Kevin. Holly and I can never be just friends. I wrote down a list of bullet points why Holly and I should be together, and I'm going to find the perfect moment today and I am going to tell her. Number one: 'Holly, you and I are soup snakes.' The-and the reason is... because... in terms of the soup, we like to- that doesn't make any sense. We're soul mates. Holly and I are soul mates.", "order": 28622, "speaker": "Michael Scott"},
  {"season": 5, "episode": 26, "scene": 15, "line": "Jim. Pam.", "order": 28623, "speaker": "Charles Miner"},
  {"season": 5, "episode": 26, "scene": 15, "line": "Hey, how are you?", "order": 28624, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 26, "scene": 15, "line": "Hey Charles.", "order": 28625, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 26, "scene": 15, "line": "Nice day, huh?", "order": 28626, "speaker": "Charles Miner"},
  {"season": 5, "episode": 26, "scene": 15, "line": "Yeah.", "order": 28627, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 26, "scene": 15, "line": "Must be nice to get a rest from all your rest. [walks away]", "order": 28628, "speaker": "Charles Miner"},
  {"season": 5, "episode": 26, "scene": 15, "line": "I don't get it. He's not even my boss anymore.", "order": 28629, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 26, "scene": 15, "line": "Do you want me to beat him up for you?", "order": 28630, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 26, "scene": 15, "line": "No, I shouldn't have to ask you to do stuff like that. You should just do it.", "order": 28631, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 26, "scene": 16, "line": "Listen up everyone! I've gone over this lineup very carefully. We cannot forget the humiliation we suffered last year at the softball game with Jim's whole spider-in-the-mitt incident. Right?", "order": 28632, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 26, "scene": 16, "line": "Well, I could've died, so... I looked it up online afterwards.", "order": 28633, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 26, "scene": 16, "line": "Erin, back row. Ryan, you move up a row!", "order": 28634, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 26, "scene": 16, "line": "Bro-migo, you think you could put Erin on my row?", "order": 28635, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 26, "scene": 16, "line": "Why? I don't understand.", "order": 28636, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 26, "scene": 16, "line": "If-", "order": 28637, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 26, "scene": 16, "line": "Woah, woah, woah. W-w-wait a minute. I get it. You want her to set you up so you can spike it.", "order": 28638, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 26, "scene": 16, "line": "Uh... [laughs sheepishly]", "order": 28639, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 26, "scene": 16, "line": "I'll tell you what, I'm gonna do you one better. I'm gonna put you next to Phyllis. She is the best setter on the team.", "order": 28640, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 26, "scene": 16, "line": "That's...", "order": 28641, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 26, "scene": 16, "line": "Sly dog.", "order": 28642, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 26, "scene": 16, "line": "... not what I meant.", "order": 28643, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 26, "scene": 16, "line": "Come on, folks!", "order": 28644, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 26, "scene": 17, "line": "What is up with you two, Holly?", "order": 28645, "speaker": "Michael Scott"},
  {"season": 5, "episode": 26, "scene": 17, "line": "Um, not much.", "order": 28646, "speaker": "Holly Flax"},
  {"season": 5, "episode": 26, "scene": 17, "line": "We're designing a house.", "order": 28647, "speaker": "A.J."},
  {"season": 5, "episode": 26, "scene": 17, "line": "Cool. For who?", "order": 28648, "speaker": "Michael Scott"},
  {"season": 5, "episode": 26, "scene": 17, "line": "For us.", "order": 28649, "speaker": "A.J."},
  {"season": 5, "episode": 26, "scene": 17, "line": "Wow... I'm designing a chair. It's part of your pants. You sit down, you're supported.", "order": 28650, "speaker": "Michael Scott"},
  {"season": 5, "episode": 26, "scene": 17, "line": "I remember your chair pants idea. [laughs]", "order": 28651, "speaker": "Holly Flax"},
  {"season": 5, "episode": 26, "scene": 17, "line": "I like that. Put me down for a pair. I'm a size 34 waist.", "order": 28652, "speaker": "A.J."},
  {"season": 5, "episode": 26, "scene": 17, "line": "All right, fatty. I will do it. You know what, we should actually rehearse.", "order": 28653, "speaker": "Michael Scott"},
  {"season": 5, "episode": 26, "scene": 17, "line": "Okay, yeah.", "order": 28654, "speaker": "Holly Flax"},
  {"season": 5, "episode": 26, "scene": 17, "line": "You guys are really gonna do this?", "order": 28655, "speaker": "A.J."},
  {"season": 5, "episode": 26, "scene": 17, "line": "You bet your fat ass we are.", "order": 28656, "speaker": "Michael Scott"},
  {"season": 5, "episode": 26, "scene": 18, "line": "Well, in his infinite wisdom, David Wallace has authorized us to put on a little presentation about the history of Dunder Mifflin.", "order": 28657, "speaker": "Michael Scott"},
  {"season": 5, "episode": 26, "scene": 18, "line": "Yep, the old comedy team is back together again.", "order": 28658, "speaker": "Holly Flax"},
  {"season": 5, "episode": 26, "scene": 18, "line": "That's right.", "order": 28659, "speaker": "Michael Scott"},
  {"season": 5, "episode": 26, "scene": 18, "line": "[in a New York accent] Have ya hoyd the news? Extry! Extry! Read all about it!", "order": 28660, "speaker": "Holly Flax"},
  {"season": 5, "episode": 26, "scene": 18, "line": "Newspapers for sale!", "order": 28661, "speaker": "Michael Scott"},
  {"season": 5, "episode": 26, "scene": 19, "line": "[playing volleyball, yelling at Erin, who hit the ball out of bounds] Are you blind?! Are you blind?! [turns attention to a man on the other team] Sir, with the glasses, are you literally blind? I'm concerned you might be in danger.", "order": 28662, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 26, "scene": 19, "line": "These are expensive Ray-Bans, jackass.", "order": 28663, "speaker": "Man"},
  {"season": 5, "episode": 26, "scene": 19, "line": "Okay, I was just looking out for you. [to Erin] You're doing great, by the way.", "order": 28664, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 26, "scene": 19, "line": "Thanks.", "order": 28665, "speaker": "Erin Hannon"},
  {"season": 5, "episode": 26, "scene": 20, "line": "[playing volleyball] I got it. [Kevin misses]", "order": 28666, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 26, "scene": 20, "line": "Ohh! Oh, Kevin! Come on!", "order": 28667, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 26, "scene": 20, "line": "Are you blind?!", "order": 28668, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 26, "scene": 20, "line": "I could've gotten that, idiot!", "order": 28669, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 26, "scene": 20, "line": "Can you see things with your eyeballs?!", "order": 28670, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 26, "scene": 20, "line": "It's not a sledgehammer! Come on, people! We need to get our heads in the game! Let's focus! Come on, you're better than this! I am better than this! Phyllis, why are you sitting on the ground?!", "order": 28671, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 26, "scene": 20, "line": "We've been out here for a while. I don't need this.", "order": 28672, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 26, "scene": 20, "line": "[grunts in frustration]", "order": 28673, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 26, "scene": 21, "line": "[playing volleyball, Ryan hits the ball away, not paying attention to the game] Oh come- Ryan, come on, man! W-w-wait. Net. Net. Her hand's on the net.", "order": 28674, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 26, "scene": 21, "line": "So what?", "order": 28675, "speaker": "Woman"},
  {"season": 5, "episode": 26, "scene": 21, "line": "Rule violation. Ball is ours. Give it to me. Our point. Okay. Hey, Pam, how ya doin? Hey, do you know if you're right-handed or left-handed? Or do you even know? What hand do you use to answer the phone?", "order": 28676, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 26, "scene": 21, "line": "Back off, Dwight. [serves] Hyuh! [the opposing team misses the ball, the Scranton team cheers] What?", "order": 28677, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 26, "scene": 22, "line": "Maybe I played a little in junior high... and in high school... maybe a little in college... and went to volleyball camp most summers.", "order": 28678, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 26, "scene": 23, "line": "[serves] Hyuh! [other team misses and Scranton cheers] Well, look at that, we win!", "order": 28679, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 26, "scene": 23, "line": "Nice job, Beesly.", "order": 28680, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 26, "scene": 23, "line": "Yes! We advance to the next round!", "order": 28681, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 26, "scene": 23, "line": "Oh, Lord in heaven.", "order": 28682, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 26, "scene": 23, "line": "[to Phyllis] Had to be part of the group. [laughs]", "order": 28683, "speaker": "Stanley Hudson"},
  {"season": 5, "episode": 26, "scene": 24, "line": "I brought some snacky snacks, in case we get hungry.", "order": 28684, "speaker": "Michael Scott"},
  {"season": 5, "episode": 26, "scene": 24, "line": "Ooh, yes please. [takes a bite] Mmm. So what do you have planned for us today? Hmm?", "order": 28685, "speaker": "Holly Flax"},
  {"season": 5, "episode": 26, "scene": 24, "line": "[watching her eat] What?", "order": 28686, "speaker": "Michael Scott"},
  {"season": 5, "episode": 26, "scene": 24, "line": "Do you have a script for the sketch, or...", "order": 28687, "speaker": "Holly Flax"},
  {"season": 5, "episode": 26, "scene": 24, "line": "Um, no, I just thought we'd wing it. That cool?", "order": 28688, "speaker": "Michael Scott"},
  {"season": 5, "episode": 26, "scene": 24, "line": "Crystal cool.", "order": 28689, "speaker": "Holly Flax"},
  {"season": 5, "episode": 26, "scene": 25, "line": "[playing volleyball] Hit it Andy!", "order": 28690, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 26, "scene": 25, "line": "Bump! I bumped it!", "order": 28691, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 26, "scene": 25, "line": "Set!", "order": 28692, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 26, "scene": 25, "line": "Don't set it to yourself!", "order": 28693, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 26, "scene": 25, "line": "[Pam saves the play and Scranton cheers] Yeah!", "order": 28694, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 26, "scene": 25, "line": "Ow, my ankle!", "order": 28695, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 26, "scene": 25, "line": "What happened?", "order": 28696, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 26, "scene": 25, "line": "I... twisted it.", "order": 28697, "speaker": "Phyllis Vance"},
  {"season": 5, "episode": 26, "scene": 25, "line": "You weren't even moving. [Phyllis leaves the court] Okay, sub!", "order": 28698, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 26, "scene": 25, "line": "I can play.", "order": 28699, "speaker": "Angela Martin"},
  {"season": 5, "episode": 26, "scene": 25, "line": "Is there a... Meredith here?", "order": 28700, "speaker": "Rolph"},
  {"season": 5, "episode": 26, "scene": 25, "line": "Yeah! Man in!", "order": 28701, "speaker": "Meredith Palmer"},
  {"season": 5, "episode": 26, "scene": 25, "line": "Rolph, did you not hear me?", "order": 28702, "speaker": "Angela Martin"},
  {"season": 5, "episode": 26, "scene": 25, "line": "I don't hear cheaters, tramps, or women who break my friend's heart. Let's go!", "order": 28703, "speaker": "Rolph"},
  {"season": 5, "episode": 26, "scene": 26, "line": "We could do a movie... sort of thing.", "order": 28704, "speaker": "Michael Scott"},
  {"season": 5, "episode": 26, "scene": 26, "line": "[gasps] We could do Back to the Future.", "order": 28705, "speaker": "Holly Flax"},
  {"season": 5, "episode": 26, "scene": 26, "line": "Oh!", "order": 28706, "speaker": "Michael Scott"},
  {"season": 5, "episode": 26, "scene": 26, "line": "We have to convince Dunder and Mifflin to go back in time... fix their parents. [both laugh]", "order": 28707, "speaker": "Holly Flax"},
  {"season": 5, "episode": 26, "scene": 26, "line": "Could we get a Delorean?", "order": 28708, "speaker": "Michael Scott"},
  {"season": 5, "episode": 26, "scene": 26, "line": "Jaws. They swim in the ocean and they terrorize the whole community.", "order": 28709, "speaker": "Holly Flax"},
  {"season": 5, "episode": 26, "scene": 26, "line": "Oh! [to the theme of 'Jaws'] Dun-der. Dun-der...", "order": 28710, "speaker": "Michael Scott"},
  {"season": 5, "episode": 26, "scene": 26, "line": "Dun-der. Dun-der...", "order": 28711, "speaker": "Holly Flax"},
  {"season": 5, "episode": 26, "scene": 26, "line": "Dun-der. Dun-der. Dun-der. Dun-der. Blooo!! [both laugh]", "order": 28712, "speaker": "Michael Scott"},
  {"season": 5, "episode": 26, "scene": 26, "line": "Oh... We haven't found our great idea yet.", "order": 28713, "speaker": "Holly Flax"},
  {"season": 5, "episode": 26, "scene": 26, "line": "No. No.", "order": 28714, "speaker": "Michael Scott"},
  {"season": 5, "episode": 26, "scene": 26, "line": "Oh.", "order": 28715, "speaker": "Holly Flax"},
  {"season": 5, "episode": 26, "scene": 26, "line": "We're circling it.", "order": 28716, "speaker": "Michael Scott"},
  {"season": 5, "episode": 26, "scene": 26, "line": "Hmm...", "order": 28717, "speaker": "Holly Flax"},
  {"season": 5, "episode": 26, "scene": 27, "line": "[playing volleyball, Scranton wins another game] Nicely done. We're still going to crush you though!", "order": 28718, "speaker": "David Wallace"},
  {"season": 5, "episode": 26, "scene": 27, "line": "Yes we are!", "order": 28719, "speaker": "Charles Miner"},
  {"season": 5, "episode": 26, "scene": 27, "line": "You suckers are goin down! They're gonna wipe their asses with your serves! Piss all over your faces!", "order": 28720, "speaker": "Rolph"},
  {"season": 5, "episode": 26, "scene": 27, "line": "Okay, Rolph! Woah. Wait, wait- [shouts of disapproval]", "order": 28721, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 26, "scene": 27, "line": "It's true!", "order": 28722, "speaker": "Rolph"},
  {"season": 5, "episode": 26, "scene": 27, "line": "Ah, this reminds me of the HR convention last fall.", "order": 28723, "speaker": "Toby Flenderson"},
  {"season": 5, "episode": 26, "scene": 27, "line": "Oh yeah, with Bernie and Efrem. That was hilarious.", "order": 28724, "speaker": "Kendall"},
  {"season": 5, "episode": 26, "scene": 27, "line": "Really, really funny.", "order": 28725, "speaker": "Toby Flenderson"},
  {"season": 5, "episode": 26, "scene": 27, "line": "Really funny.", "order": 28726, "speaker": "Kendall"},
  {"season": 5, "episode": 26, "scene": 28, "line": "Listen guys, one more point and we play corporate. [they get the last point, Jim and Dwight hug]", "order": 28727, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 26, "scene": 28, "line": "Settle down gentlemen.", "order": 28728, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 26, "scene": 28, "line": "Good game!", "order": 28729, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 26, "scene": 29, "line": "Welcome to the 43rd Annual Company Picnic, everybody. Thanks for being here. Now, a couple of employees have volunteered to entertain us with a song.", "order": 28730, "speaker": "David Wallace"},
  {"season": 5, "episode": 26, "scene": 29, "line": "[shouts from behind the trees] Uh, it's a sketch now.", "order": 28731, "speaker": "Michael Scott"},
  {"season": 5, "episode": 26, "scene": 29, "line": "Okay, introducing Scranton's Michael Scott performing with Nashua's Holly Flax! I have not seen this. [applause]", "order": 28732, "speaker": "David Wallace"},
  {"season": 5, "episode": 26, "scene": 29, "line": "And now, presenting...", "order": 28733, "speaker": "Michael Scott"},
  {"season": 5, "episode": 26, "scene": 29, "line": "SlumDunder Mifflinaire! [laughter]", "order": 28734, "speaker": "Both"},
  {"season": 5, "episode": 26, "scene": 29, "line": "[imitates Who Wants To Be a Millionaire theme music as both sit in folding chairs]", "order": 28735, "speaker": "Michael Scott"},
  {"season": 5, "episode": 26, "scene": 29, "line": "Are you ready to play SlumDunder Mifflinaire?", "order": 28736, "speaker": "Holly Flax"},
  {"season": 5, "episode": 26, "scene": 29, "line": "Yes, I am.", "order": 28737, "speaker": "Michael Scott"},
  {"season": 5, "episode": 26, "scene": 29, "line": "For one hundred dollars, where did Dunder meet Mifflin? A.) On easy street, B.) a tour of Dartmouth College, C.) they never met, D.) brushing their teeth?", "order": 28738, "speaker": "Holly Flax"},
  {"season": 5, "episode": 26, "scene": 29, "line": "Ohh, I'm thinking... I'm going to say... B, tour of Dartmouth College.", "order": 28739, "speaker": "Michael Scott"},
  {"season": 5, "episode": 26, "scene": 29, "line": "That is correct! [both run to another side of the stage, acting out a different scene] How did you know that?! [pantomimes electrocuting Michael]", "order": 28740, "speaker": "Holly Flax"},
  {"season": 5, "episode": 26, "scene": 29, "line": "[screams] Ahhhh!! Ohhh!! Ahhh!! I was there! Ahhh!! I was a tour guide at Dartmouth College!! Noooo!!!", "order": 28741, "speaker": "Michael Scott"},
  {"season": 5, "episode": 26, "scene": 29, "line": "[now pretending to be Dunder and Mifflin] Nice campus. Think you'll get in?", "order": 28742, "speaker": "Holly Flax"},
  {"season": 5, "episode": 26, "scene": 29, "line": "Yeah, I'm definitely getting in. I'm a shoo-in.", "order": 28743, "speaker": "Michael Scott"},
  {"season": 5, "episode": 26, "scene": 29, "line": "I'm Robert Dunder.", "order": 28744, "speaker": "Holly Flax"},
  {"season": 5, "episode": 26, "scene": 29, "line": "I'm Robert Mifflin. [pause to see audience's response] Ah, okay. [both run back to chairs]", "order": 28745, "speaker": "Michael Scott"},
  {"season": 5, "episode": 26, "scene": 29, "line": "Robert Mifflin had a great life. But unfortunately, had undiagnosed depression, which over nine million Americans suffer from and is very treatable. For two hundred and fifty dollars, how did he kill himself? A.) A rope, B.) a knife, C.) a gun, D.) brushing his teeth!", "order": 28746, "speaker": "Holly Flax"},
  {"season": 5, "episode": 26, "scene": 29, "line": "Two hundred and fifty dollars is more money than I've ever seen in my life. I will say, C, a gun. He shot himself in the head.", "order": 28747, "speaker": "Michael Scott"},
  {"season": 5, "episode": 26, "scene": 29, "line": "That is correct!", "order": 28748, "speaker": "Holly Flax"},
  {"season": 5, "episode": 26, "scene": 29, "line": "Yes!", "order": 28749, "speaker": "Michael Scott"},
  {"season": 5, "episode": 26, "scene": 30, "line": "I usually don't enjoy the theater, but this is delightful.", "order": 28750, "speaker": "Stanley Hudson"},
  {"season": 5, "episode": 26, "scene": 31, "line": "The economic downturn has been difficult recently, forcing the closures of both Camden and Yonkers, to be followed soon by what other branch? For five-hundred thousand dollars, is it A.) Scranton, B.) Buffalo, C.) Utica, or D.) toothbrush!", "order": 28751, "speaker": "Holly Flax"},
  {"season": 5, "episode": 26, "scene": 31, "line": "I will say B, Buffalo! Final answer!", "order": 28752, "speaker": "Michael Scott"},
  {"season": 5, "episode": 26, "scene": 31, "line": "That is correct!", "order": 28753, "speaker": "Holly Flax"},
  {"season": 5, "episode": 26, "scene": 31, "line": "[over crowd of murmurs] What is he talking about?", "order": 28754, "speaker": "Man from Buffalo"},
  {"season": 5, "episode": 26, "scene": 31, "line": "[pantomimes biting off Michael's fingers, Michael screams] How did you know that?!", "order": 28755, "speaker": "Holly Flax"},
  {"season": 5, "episode": 26, "scene": 31, "line": "David Wallace told me!!", "order": 28756, "speaker": "Michael Scott"},
  {"season": 5, "episode": 26, "scene": 31, "line": "David, is this true?", "order": 28757, "speaker": "Woman from Buffalo"},
  {"season": 5, "episode": 26, "scene": 31, "line": "Uh, okay everyone, we're at a picnic today...", "order": 28758, "speaker": "David Wallace"},
  {"season": 5, "episode": 26, "scene": 31, "line": "Are we losing our jobs or not, David?", "order": 28759, "speaker": "Man from Buffalo"},
  {"season": 5, "episode": 26, "scene": 31, "line": "They didn't know?", "order": 28760, "speaker": "Holly Flax"},
  {"season": 5, "episode": 26, "scene": 31, "line": "[whispers] I guess not.", "order": 28761, "speaker": "Michael Scott"},
  {"season": 5, "episode": 26, "scene": 31, "line": "I'm sorry, this certainly wasn't the time or the place to announce this sort of thing, but there have been talks about closing the Buffalo branch.", "order": 28762, "speaker": "David Wallace"},
  {"season": 5, "episode": 26, "scene": 31, "line": "And?", "order": 28763, "speaker": "Woman from Buffalo"},
  {"season": 5, "episode": 26, "scene": 31, "line": "We're- we're closing the Buffalo branch.", "order": 28764, "speaker": "David Wallace"},
  {"season": 5, "episode": 26, "scene": 31, "line": "[over shouts of protest] You've got to be kidding me! You've got to be kidding me! We're the best branch in the company! I can't believe it. [Michael and Holly bow]", "order": 28765, "speaker": "Man from Buffalo"},
  {"season": 5, "episode": 26, "scene": 32, "line": "How could you possibly think that the right way to announce a branch closing was in a comedy sketch at the company picnic?", "order": 28766, "speaker": "David Wallace"},
  {"season": 5, "episode": 26, "scene": 32, "line": "Well... I didn't know they didn't know.", "order": 28767, "speaker": "Michael Scott"},
  {"season": 5, "episode": 26, "scene": 32, "line": "What about the fact that they're here today? What about that? That didn't throw up any alarms? No, Michael needed a little bit for his comedy sketch, and he thought, 'oh, this would be really funny.'", "order": 28768, "speaker": "David Wallace"},
  {"season": 5, "episode": 26, "scene": 32, "line": "Thank you.", "order": 28769, "speaker": "Michael Scott"},
  {"season": 5, "episode": 26, "scene": 32, "line": "Damn it, Michael, I told you that in confidence. Now I have to go over and deal with these employees and their families. A little boy just walked up to me and said, 'is my daddy gonna have a job by Christmas?'", "order": 28770, "speaker": "David Wallace"},
  {"season": 5, "episode": 26, "scene": 32, "line": "Well, he's just thinking about his own gifts.", "order": 28771, "speaker": "Michael Scott"},
  {"season": 5, "episode": 26, "scene": 33, "line": "Maybe we shouldn't play due to the circumstances.", "order": 28772, "speaker": "Meredith Palmer"},
  {"season": 5, "episode": 26, "scene": 33, "line": "Hey, people need volleyball now more than ever.", "order": 28773, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 26, "scene": 33, "line": "How do you figure?", "order": 28774, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 26, "scene": 33, "line": "Because if we don't play, then the other team wins.", "order": 28775, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 26, "scene": 33, "line": "Dwight's right. Corporate deserves to get its ass kicked.", "order": 28776, "speaker": "Oscar Martinez"},
  {"season": 5, "episode": 26, "scene": 33, "line": "Let's do this.", "order": 28777, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 26, "scene": 34, "line": "[playing volleyball] Jim, come on! [Jim scores a point, Scranton cheers]", "order": 28778, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 26, "scene": 34, "line": "Look who just woke up! [laughs, scores a point on the next play] I've been up for a while.", "order": 28779, "speaker": "Charles Miner"},
  {"season": 5, "episode": 26, "scene": 34, "line": "It's six to six. It's a nail-biter. [ball hits Kevin]", "order": 28780, "speaker": "Kevin Malone"},
  {"season": 5, "episode": 26, "scene": 34, "line": "Kevin! Now it's seven-six, or is that too much accounting for you?", "order": 28781, "speaker": "Angela Martin"},
  {"season": 5, "episode": 26, "scene": 34, "line": "Here's an accounting question for you: what does one fiance plus one lover equal? Answer: one whore.", "order": 28782, "speaker": "Rolph"},
  {"season": 5, "episode": 26, "scene": 34, "line": "Okay, knock it off, Rolph.", "order": 28783, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 26, "scene": 34, "line": "What? She is sitting there, casting aspersions-", "order": 28784, "speaker": "Rolph"},
  {"season": 5, "episode": 26, "scene": 34, "line": "Rolph, please. I am asking nicely-", "order": 28785, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 26, "scene": 34, "line": "No way! You don't mean that!", "order": 28786, "speaker": "Rolph"},
  {"season": 5, "episode": 26, "scene": 34, "line": "Rolph, leave it alone!", "order": 28787, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 26, "scene": 35, "line": "[hits the ball over the net and scores a point] Yeah! Boo-yah!", "order": 28788, "speaker": "Erin Hannon"},
  {"season": 5, "episode": 26, "scene": 36, "line": "[running for the ball] I got it!", "order": 28789, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 26, "scene": 36, "line": "Pam!", "order": 28790, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 26, "scene": 36, "line": "[falls] Oof!", "order": 28791, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 26, "scene": 36, "line": "You all right?", "order": 28792, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 26, "scene": 36, "line": "Yep. No, no, no, I'm fine.", "order": 28793, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 26, "scene": 36, "line": "You sure?", "order": 28794, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 26, "scene": 36, "line": "Yeah.", "order": 28795, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 26, "scene": 36, "line": "Hey, easy, easy...", "order": 28796, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 26, "scene": 36, "line": "Woah, woah, woah, woah, you wanna get that looked at.", "order": 28797, "speaker": "Charles Miner"},
  {"season": 5, "episode": 26, "scene": 36, "line": "No, no, it's fine. Just gimme a second.", "order": 28798, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 26, "scene": 36, "line": "I don't know. You know, this is a company picnic, so technically that is a company injury, you know? Safest thing to do is get that examined, right David?", "order": 28799, "speaker": "Charles Miner"},
  {"season": 5, "episode": 26, "scene": 36, "line": "Yeah.", "order": 28800, "speaker": "David Wallace"},
  {"season": 5, "episode": 26, "scene": 36, "line": "All right, you know what? You're just trying to get rid of our best player.", "order": 28801, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 26, "scene": 36, "line": "Oh, Jim, you're putting a volleyball game in front of your fiance's health.", "order": 28802, "speaker": "Charles Miner"},
  {"season": 5, "episode": 26, "scene": 36, "line": "No, uh-", "order": 28803, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 26, "scene": 36, "line": "Look, seriously, I can move it fine. Come on, let's go, it's our ball. Let's go!", "order": 28804, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 26, "scene": 36, "line": "Yeah, I don't think we can let you play with that foot.", "order": 28805, "speaker": "Charles Miner"},
  {"season": 5, "episode": 26, "scene": 36, "line": "[whispering] Tell you what. I spotted a small hospital a few kilometers south of here. Get her back as soon as possible. I'll stall 'em.", "order": 28806, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 26, "scene": 36, "line": "I guess that's it for you, Jim.", "order": 28807, "speaker": "Charles Miner"},
  {"season": 5, "episode": 26, "scene": 36, "line": "All right, you know what? [picks Pam up and carries her off the court] Let's do this.", "order": 28808, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 26, "scene": 36, "line": "We'll be back!", "order": 28809, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 26, "scene": 36, "line": "Oh man, I am so mad that Pam got hurt! Argh!! Rrrraah!!! [kicks volleyball into the woods, calmly] I'll get it. [walks slowly toward the woods]", "order": 28810, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 26, "scene": 37, "line": "Probably shouldn't have mentioned Buffalo.", "order": 28811, "speaker": "Michael Scott"},
  {"season": 5, "episode": 26, "scene": 37, "line": "Hindsight.", "order": 28812, "speaker": "Holly Flax"},
  {"season": 5, "episode": 26, "scene": 37, "line": "Should've had hindsight.", "order": 28813, "speaker": "Michael Scott"},
  {"season": 5, "episode": 26, "scene": 37, "line": "How do you think it went before the Buffalo thing?", "order": 28814, "speaker": "Holly Flax"},
  {"season": 5, "episode": 26, "scene": 37, "line": "I think it went well. I think it was good.", "order": 28815, "speaker": "Michael Scott"},
  {"season": 5, "episode": 26, "scene": 37, "line": "There weren't any laughs.", "order": 28816, "speaker": "Holly Flax"},
  {"season": 5, "episode": 26, "scene": 37, "line": "No, it was a tough audience.", "order": 28817, "speaker": "Michael Scott"},
  {"season": 5, "episode": 26, "scene": 37, "line": "Yeah, but we wrote it specifically for this audience.", "order": 28818, "speaker": "Holly Flax"},
  {"season": 5, "episode": 26, "scene": 37, "line": "Believe me, I have seen a lot of tough audiences in my time, and that was one of them.", "order": 28819, "speaker": "Michael Scott"},
  {"season": 5, "episode": 26, "scene": 37, "line": "Well, I'm glad we did it.", "order": 28820, "speaker": "Holly Flax"},
  {"season": 5, "episode": 26, "scene": 37, "line": "Me too. [long pause] We have a lot of good material for next year's sketch.", "order": 28821, "speaker": "Michael Scott"},
  {"season": 5, "episode": 26, "scene": 37, "line": "I can't wait.", "order": 28822, "speaker": "Holly Flax"},
  {"season": 5, "episode": 26, "scene": 38, "line": "[on the phone] Yeah, she's with the nurse right now, so you'll have to stall a little longer... No, don't send in the subs yet... Dwight, I don't know. Think of something!", "order": 28823, "speaker": "Jim Halpert"},
  {"season": 5, "episode": 26, "scene": 38, "line": "To be safe, we should do an x-ray.", "order": 28824, "speaker": "Nurse"},
  {"season": 5, "episode": 26, "scene": 38, "line": "How long will that take?", "order": 28825, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 26, "scene": 38, "line": "Oh, shouldn't be too bad, it's a slow day. So, no other radiation this year, no metal plates, no chance you're pregnant...", "order": 28826, "speaker": "Nurse"},
  {"season": 5, "episode": 26, "scene": 38, "line": "I'm sorry, can we just hurry this up? I've got a game to get back to.", "order": 28827, "speaker": "Pam Beesly"},
  {"season": 5, "episode": 26, "scene": 38, "line": "Oh good, because my next question was 'do you have a game to get back to.'", "order": 28828, "speaker": "Nurse"},
  {"season": 5, "episode": 26, "scene": 39, "line": "What'd you think?", "order": 28829, "speaker": "Holly Flax"},
  {"season": 5, "episode": 26, "scene": 39, "line": "I loved it. You know, there was a part near the end that seemed like that went on a little long, but...", "order": 28830, "speaker": "A.J."},
  {"season": 5, "episode": 26, "scene": 39, "line": "Well you guys should hit the road before... I close down another branch.", "order": 28831, "speaker": "Michael Scott"},
  {"season": 5, "episode": 26, "scene": 39, "line": "[laughs] Okay. So good to see you.", "order": 28832, "speaker": "Holly Flax"},
  {"season": 5, "episode": 26, "scene": 39, "line": "Good to see you. [they hug] AJ.", "order": 28833, "speaker": "Michael Scott"},
  {"season": 5, "episode": 26, "scene": 39, "line": "Michael. [they shake hands]", "order": 28834, "speaker": "A.J."},
  {"season": 5, "episode": 26, "scene": 40, "line": "I didn't find a perfect moment, because I think that today was just about just having today. And I think that we are one of those couples with a long story, when people ask how they found each other. I will see her every now and then, and... Maybe one year she'll be with somebody, and the next year, I'll be with somebody, and it's gonna take a long time... And then it's perfect. I'm in no rush.", "order": 28835, "speaker": "Michael Scott"},
  {"season": 5, "episode": 26, "scene": 41, "line": "Dwight, come on now, it's time to put in the subs.", "order": 28836, "speaker": "David Wallace"},
  {"season": 5, "episode": 26, "scene": 41, "line": "Yeah, it looks like Pam won't make it back. Okay?", "order": 28837, "speaker": "Charles Miner"},
  {"season": 5, "episode": 26, "scene": 41, "line": "Okay. Fine.", "order": 28838, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 26, "scene": 41, "line": "All right! Come on.", "order": 28839, "speaker": "Charles Miner"},
  {"season": 5, "episode": 26, "scene": 41, "line": "Except, you know what? It's not fine. How many people need to get hurt before we learn a valuable lesson? One? Two? Three? Four?", "order": 28840, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 26, "scene": 41, "line": "Dwight.", "order": 28841, "speaker": "Andy Bernard"},
  {"season": 5, "episode": 26, "scene": 41, "line": "No, no, hear me out. Five? Six?", "order": 28842, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 26, "scene": 41, "line": "Dwight.", "order": 28843, "speaker": "David Wallace"},
  {"season": 5, "episode": 26, "scene": 41, "line": "Seven? Can I finish please?", "order": 28844, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 26, "scene": 41, "line": "Okay.", "order": 28845, "speaker": "David Wallace"},
  {"season": 5, "episode": 26, "scene": 41, "line": "Eight?", "order": 28846, "speaker": "Dwight Schrute"},
  {"season": 5, "episode": 26, "scene": 42, "line": "[on the phone] Oh, Dwight, we're so close! Just buy us a few more minutes... Well, they just called me in for an update, so I'll call you right back... Okay... Okay, great. [goes into a private room where Pam and the doctor are, the voices are inaudible, but the camera can see through a window, Pam and Jim look in shock, they hug and Jim goes back out into the hall to call Dwight back, trembling] Hey, Dwight, uh... send in the subs! [laughs] Ohh! [goes back into the room to hug Pam]", "order": 28847, "speaker": "Jim Halpert"}
]
