const SpeakerImages = {
    'A.J.': require('./aj.jpg').default,
    'Al Brown': require('./al-brown.jpg').default,
    'Andy Bernard': require('./andy-bernard.jpg').default,
    'Angela Martin': require('./angela-martin.jpg').default,
    'Captain Jack': require('./captain-jack.jpg').default,
    'Carol Stills': require('./carol-stills.jpg').default,
    'Cathy Simms': require('./cathy-simms.jpg').default,
    'Charles Miner': require('./charles-miner.jpg').default,
    'Clark Green': require('./clark-green.jpg').default,
    'Creed Bratton': require('./creed-bratton.jpg').default,
    'Danny Cordray': require('./danny-cordray.jpg').default,
    'Darryl Philbin': require('./darryl-philbin.jpg').default,
    'David Wallace': require('./david-wallace.jpg').default,
    'Deangelo Vickers': require('./deangelo-vickers.jpg').default,
    'Donna Newton': require('./donna-newton.jpg').default,
    'Dwight Schrute': require('./dwight-schrute.jpg').default,
    'Erin Hannon': require('./erin-hannon.jpg').default,
    'Gabe Lewis': require('./gabe-lewis.jpg').default,
    'Hank Tate': require('./hank-tate.jpg').default,
    'Helene Beesly': require('./helene-beesly.jpg').default,
    'Holly Flax': require('./holly-flax.jpg').default,
    'Jan Levinson': require('./jan-levinson.jpg').default,
    'Jessica (Vance Refridgeration)': require('./jessica-vance-refrigeration.jpg').default,
    'Jim Halpert': require('./jim-halpert.jpg').default,
    'Jo Bennett': require('./jo-bennett.jpg').default,
    'Josh Porter': require('./josh-porter.jpg').default,
    'Karen Filippelli': require('./karen-filippelli.jpg').default,
    'Katy Moore': require('./katy-moore.jpg').default,
    'Kelly Kapoor': require('./kelly-kapoor.jpg').default,
    'Kevin Malone': require('./kevin-malone.jpg').default,
    'Meredith Palmer': require('./meredith-palmer.jpg').default,
    'Michael Scarn': require('./michael-scarn.jpg').default,
    'Michael Scott': require('./michael-scott.jpg').default,
    'Mose Schrute': require('./mose-schrute.jpg').default,
    'Nate Nickerson': require('./nate-nickerson.jpg').default,
    'Nellie Bertram': require('./nellie-bertram.jpg').default,
    'Oscar Martinez': require('./oscar-martinez.jpg').default,
    'Pam Beesly': require('./pam-beesly.jpg').default,
    'Pam Halpert': require('./pam-beesly.jpg').default,
    'Pete Miller': require('./pete-miller.jpg').default,
    'Phyllis Lapin': require('./phyllis-lapin.jpg').default,
    'Phyllis Vance': require('./phyllis-lapin.jpg').default,
    'Robert California': require('./robert-california.jpg').default,
    'Roy Anderson': require('./roy-anderson.jpg').default,
    'Ryan Howard': require('./ryan-howard.jpg').default,
    'Stanley Hudson': require('./stanley-hudson.jpg').default,
    'Susan California': require('./susan-california.jpg').default,
    'Toby Flenderson': require('./toby-flenderson.jpg').default,
    'Todd Packer': require('./todd-packer.jpg').default,
    'Val Johnson': require('./val-johnson.jpg').default,
    Brian: require('./brian.jpg').default,
    Eric: require('./eric.jpg').default,
    Jessica: require('./jessica.jpg').default,
    Maurie: require('./maurie.jpg').default,
    Megan: require('./megan.jpg').default,
    Nick: require('./nick.jpg').default,
    Trevor: require('./trevor.jpg').default,
    Unknown: require('./no-image.jpg').default,
}

export default SpeakerImages
