module.exports = [
  {"season": 2, "episode": 1, "scene": 1, "line": "Tonight is the Dundies, the annual employee awards night here at Dunder Mifflin. [holds up a trophy of a business man] And this is everybody's favorite day. Everybody looks forward to it, because, you know, a lot of the people here don't get trophies, very often. Like Meredith or Kevin, I mean, who's gonna give Kevin an award? Dunkin' Donuts? Plus, bonus, it's really, really funny. So I, you know, an employee will go home, and he'll tell his neighbor, 'Hey, did you get an award?' And the neighbor will say, 'No man. I mean, I slave all day and nobody notices me.' Next thing you know, employee smells something terrible coming from neighbor's house. Neighbor's hanged himself due to lack of recognition. So...", "order": 1537, "speaker": "Michael Scott"},
  {"season": 2, "episode": 1, "scene": 2, "line": "So, you ready for the... the Dundies?", "order": 1538, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 1, "scene": 2, "line": "Ugh...", "order": 1539, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 1, "scene": 3, "line": "You know what they say about a car wreck, where it's so awful you can't look away? The Dundies are like a car wreck that you want to look away, but you have to stare at it because your boss is making you.", "order": 1540, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 1, "scene": 4, "line": "[in a Fat Albert voice] Hey hey hey! It's Fat Halpert.", "order": 1541, "speaker": "Michael Scott"},
  {"season": 2, "episode": 1, "scene": 4, "line": "What?", "order": 1542, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 1, "scene": 4, "line": "[in Fat Albert voice] Fat Halpert. [in normal voice] Jim Halpert.", "order": 1543, "speaker": "Michael Scott"},
  {"season": 2, "episode": 1, "scene": 5, "line": "So why don't I take you on a tour of past Dundie winners. We got Fat Jim Halpert here. Jim, why don't you show of your Dundies to the camera?", "order": 1544, "speaker": "Michael Scott"},
  {"season": 2, "episode": 1, "scene": 5, "line": "Oh, I can't because I keep them hidden. I don't want to look at them and get cocky.", "order": 1545, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 1, "scene": 5, "line": "Oh, that's a good idea.", "order": 1546, "speaker": "Michael Scott"},
  {"season": 2, "episode": 1, "scene": 5, "line": "Mine are at home in a display case above my bed.", "order": 1547, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 1, "scene": 5, "line": "Gyaaah. T.M.I. T.M.I my friends.", "order": 1548, "speaker": "Michael Scott"},
  {"season": 2, "episode": 1, "scene": 6, "line": "T.M.I.? Too much information. Ah, it's just easier to say T.M.I. I used to say 'Don't go there' but that's... lame.", "order": 1549, "speaker": "Michael Scott"},
  {"season": 2, "episode": 1, "scene": 7, "line": "And here we have Stanley the Manly. Now Stanley is a Dundie all-star, aren't you Stan? Why don't you, ah, show them some of your bling.", "order": 1550, "speaker": "Michael Scott"},
  {"season": 2, "episode": 1, "scene": 7, "line": "I don't know where they are, I think I threw them out.", "order": 1551, "speaker": "Stanley Hudson"},
  {"season": 2, "episode": 1, "scene": 7, "line": "Oh, no you di-int.", "order": 1552, "speaker": "Michael Scott"},
  {"season": 2, "episode": 1, "scene": 7, "line": "I think I did.", "order": 1553, "speaker": "Stanley Hudson"},
  {"season": 2, "episode": 1, "scene": 7, "line": "W-why did you...", "order": 1554, "speaker": "Michael Scott"},
  {"season": 2, "episode": 1, "scene": 7, "line": "Say, we got to order some more apa-teezers this time. We ran out last year, remember?", "order": 1555, "speaker": "Stanley Hudson"},
  {"season": 2, "episode": 1, "scene": 7, "line": "Yes we should. I... you know what? I wanted one of those skillets of cheese, but when I got off stage, [turns to Kevin] someone had eaten all of them.", "order": 1556, "speaker": "Michael Scott"},
  {"season": 2, "episode": 1, "scene": 8, "line": "[in video] To Oscar Martinez it's the 'Show Me the Money' award! Yeah!", "order": 1557, "speaker": "Michael Scott"},
  {"season": 2, "episode": 1, "scene": 9, "line": "Michael has taped", "order": 1558, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 1, "scene": 10, "line": "[in video] That's supposed to be confidential.", "order": 1559, "speaker": "Oscar Martinez"},
  {"season": 2, "episode": 1, "scene": 10, "line": "[in video] He has the award-ah! ...it's a type of song that we are going to play for the ladies. Hit it, Dwight!", "order": 1560, "speaker": "Michael Scott"},
  {"season": 2, "episode": 1, "scene": 10, "line": "[singing along to tune on video] A little bit of Paaam, all night long, a little bit of Angela on the thing...", "order": 1561, "speaker": "Michael Scott"},
  {"season": 2, "episode": 1, "scene": 10, "line": "[in video]...a little bit of Phyllis everywhere...", "order": 1562, "speaker": "Michael Scott"},
  {"season": 2, "episode": 1, "scene": 10, "line": "Oh, yeah, this is the part where Kevin sat in front of the camcorder all night. It's great.", "order": 1563, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 1, "scene": 10, "line": "[on video] ...a little bit of Roooy eating chicken crispers... ...a little bit of Jim with some ribs, a little bit of...", "order": 1564, "speaker": "Michael Scott"},
  {"season": 2, "episode": 1, "scene": 11, "line": "It was you.", "order": 1565, "speaker": "Kelly Kapoor"},
  {"season": 2, "episode": 1, "scene": 11, "line": "Live and learn.", "order": 1566, "speaker": "Phyllis Lapin"},
  {"season": 2, "episode": 1, "scene": 11, "line": "[quietly laughing] It wasn't. I swear.", "order": 1567, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 1, "scene": 11, "line": "Yeah, it was.", "order": 1568, "speaker": "Kelly Kapoor"},
  {"season": 2, "episode": 1, "scene": 12, "line": "So, what's the joke? You're not perfect either.", "order": 1569, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 1, "scene": 12, "line": "We're not laughing at you, Dwight.", "order": 1570, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 1, "scene": 12, "line": "So who are we laughing at?", "order": 1571, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 1, "scene": 12, "line": "Um, just something somebody wrote.", "order": 1572, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 1, "scene": 12, "line": "Who? Dave Barry?", "order": 1573, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 1, "scene": 12, "line": "[laughing] No. No, just something that was written in the ladies' room wall.", "order": 1574, "speaker": "Kelly Kapoor"},
  {"season": 2, "episode": 1, "scene": 12, "line": "What is it? Who wrote it?", "order": 1575, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 1, "scene": 12, "line": "Um, it's kind of private.", "order": 1576, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 1, "scene": 12, "line": "[whispering] It's about Michael.", "order": 1577, "speaker": "Phyllis Lapin"},
  {"season": 2, "episode": 1, "scene": 12, "line": "That is defacement of company property. So you better tell me. Kelly, if you tell me, you'll be punished less.", "order": 1578, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 1, "scene": 12, "line": "Okay, now I'm laughing at you.", "order": 1579, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 1, "scene": 13, "line": "[talking to the speakerphone] Will her highness, Jan Levinson-Gould, be descending from her corporate throne this evening to visit us lowly serfs here at Dunder Mifflin Scranton?", "order": 1580, "speaker": "Michael Scott"},
  {"season": 2, "episode": 1, "scene": 13, "line": "[on speaker phone] It's a, it's, it's a two and a half hour drive from New York, Michael.", "order": 1581, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 1, "scene": 13, "line": "Well, you could take the bus. You could work on the way here. Sleep on the way home.", "order": 1582, "speaker": "Michael Scott"},
  {"season": 2, "episode": 1, "scene": 13, "line": "No.", "order": 1583, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 1, "scene": 13, "line": "Wuh... Come on, Jan. This is important. I mean, this is, this is, validation to my employees here that you and corporate approve of this. So...", "order": 1584, "speaker": "Michael Scott"},
  {"season": 2, "episode": 1, "scene": 13, "line": "No, we don't approve of this Michael. I mean, y-you only had the budget for one office party a year, so... we're not paying for this.", "order": 1585, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 1, "scene": 13, "line": "Um...", "order": 1586, "speaker": "Michael Scott"},
  {"season": 2, "episode": 1, "scene": 13, "line": "[to camera] Could you...?", "order": 1587, "speaker": "Michael Scott"},
  {"season": 2, "episode": 1, "scene": 13, "line": "Are you there Michael?", "order": 1588, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 1, "scene": 13, "line": "Yeah, I'm here, I just wanted to, uh, talk to you for a second about that.", "order": 1589, "speaker": "Michael Scott"},
  {"season": 2, "episode": 1, "scene": 13, "line": "Um, what, ah, what is, I mean...", "order": 1590, "speaker": "Michael Scott"},
  {"season": 2, "episode": 1, "scene": 13, "line": "...come on, Jan!", "order": 1591, "speaker": "Michael Scott"},
  {"season": 2, "episode": 1, "scene": 13, "line": "You're dropping an A-Bomb on me here.", "order": 1592, "speaker": "Michael Scott"},
  {"season": 2, "episode": 1, "scene": 13, "line": "Really? I'm dropping an atomic bomb on you?", "order": 1593, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 1, "scene": 13, "line": "Well, yeah, I mean, what is...", "order": 1594, "speaker": "Michael Scott"},
  {"season": 2, "episode": 1, "scene": 13, "line": "You already had a party on May 5th for no reason.", "order": 1595, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 1, "scene": 13, "line": "No reason?! It was the 05 05 05 party...", "order": 1596, "speaker": "Michael Scott"},
  {"season": 2, "episode": 1, "scene": 13, "line": "And you had a luau....", "order": 1597, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 1, "scene": 13, "line": "...it happens once every billion years.", "order": 1598, "speaker": "Michael Scott"},
  {"season": 2, "episode": 1, "scene": 13, "line": "And a tsunami relief fundraiser which somehow lost a lot of money.", "order": 1599, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 1, "scene": 13, "line": "Okay, no, that was a FUN raiser. I think I made that very clear in the fliers, fun, F-U-N.", "order": 1600, "speaker": "Michael Scott"},
  {"season": 2, "episode": 1, "scene": 13, "line": "Okay, well, I don't understand why anyone would have a tsunami FUN raiser, Michael. I mean, that doesn't even make sense.", "order": 1601, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 1, "scene": 13, "line": "Well, I think a lot of people were very affected by the footage.", "order": 1602, "speaker": "Michael Scott"},
  {"season": 2, "episode": 1, "scene": 14, "line": "This is a little character I like to do [places a green turban with a yellow feather on his head], it is, uh, loosely based on Karnack, one of Carson's classic characters. [puts an envelope to his head] Here we go. The PLO, the IRA, and the hot dog stand behind the warehouse. [tears open envelope and pulls out card] 'Name three businesses that have a better health care plans than Dunder Mifflin.' Here's the problem. There's no open bar because of Jan and it's the reason why comedy clubs have a two drink minimum. It'll be fine, I just...wish people were going to be drunk.", "order": 1603, "speaker": "Michael Scott"},
  {"season": 2, "episode": 1, "scene": 15, "line": "Dwight, get out of here!!", "order": 1604, "speaker": "Phyllis Lapin"},
  {"season": 2, "episode": 1, "scene": 15, "line": "No, no, no, no...", "order": 1605, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 1, "scene": 15, "line": "What were you doing in the ladies room?!", "order": 1606, "speaker": "Phyllis Lapin"},
  {"season": 2, "episode": 1, "scene": 15, "line": "...no, no, no, no, it's not what you think.", "order": 1607, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 1, "scene": 15, "line": "Why were you peering over the stalls?!", "order": 1608, "speaker": "Phyllis Lapin"},
  {"season": 2, "episode": 1, "scene": 15, "line": "No, why were you in there?!", "order": 1609, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 1, "scene": 15, "line": "You are a pervert!", "order": 1610, "speaker": "Phyllis Lapin"},
  {"season": 2, "episode": 1, "scene": 15, "line": "What were you doing in there?", "order": 1611, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 1, "scene": 15, "line": "You, are, a pervert!", "order": 1612, "speaker": "Phyllis Lapin"},
  {"season": 2, "episode": 1, "scene": 15, "line": "I am not.", "order": 1613, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 1, "scene": 16, "line": "[in video] The Dundie award for 'Longest Engagement' goes to Pam Beesley.", "order": 1614, "speaker": "Michael Scott"},
  {"season": 2, "episode": 1, "scene": 16, "line": "Pam, everybody! [starts clapping]", "order": 1615, "speaker": "Michael Scott"},
  {"season": 2, "episode": 1, "scene": 16, "line": "Whoooo! When is that girl gonna get married? That's what I have to say. Ah, Roy's accepting.", "order": 1616, "speaker": "Michael Scott"},
  {"season": 2, "episode": 1, "scene": 16, "line": "[on video] Yes.", "order": 1617, "speaker": "Roy Anderson"},
  {"season": 2, "episode": 1, "scene": 16, "line": "[on video] Thank you Roy. Are there any words you'd like to say, on Pam's behalf?", "order": 1618, "speaker": "Michael Scott"},
  {"season": 2, "episode": 1, "scene": 16, "line": "[on video] Ah, w-we'll see you next year.", "order": 1619, "speaker": "Roy Anderson"},
  {"season": 2, "episode": 1, "scene": 16, "line": "[on video] Yeah, oh, hope not! Oh God!", "order": 1620, "speaker": "Michael Scott"},
  {"season": 2, "episode": 1, "scene": 17, "line": "I'm not changing that, it's the best one.", "order": 1621, "speaker": "Michael Scott"},
  {"season": 2, "episode": 1, "scene": 17, "line": "No, it's hilarious, you're right. I just think, um, 'world's longest engagement', um, we're all expecting it, you know?", "order": 1622, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 1, "scene": 17, "line": "That's why it's funny. Every year that Roy and Pam don't get married, it gets funnier.", "order": 1623, "speaker": "Michael Scott"},
  {"season": 2, "episode": 1, "scene": 17, "line": "Well I think if you use the same jokes it just comes across as lazy.", "order": 1624, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 1, "scene": 17, "line": "Oh, [taking it to heart] lazy. Uh huh.", "order": 1625, "speaker": "Michael Scott"},
  {"season": 2, "episode": 1, "scene": 18, "line": "Excuse me, everyone, could I have your attention please. I just wanted to say that the women in this office are terrible. Especially the ones who wrote that stuff about Michael on the bathroom wall. Having a bathroom is a privilege. It is called a ladies room for a reason. And if you cannot behave like ladies, well then you are not going to have a bathroom.", "order": 1626, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 1, "scene": 19, "line": "You're taking away our bathroom?", "order": 1627, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 1, "scene": 19, "line": "We are going to have two men's rooms.", "order": 1628, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 1, "scene": 19, "line": "But where would we...go?", "order": 1629, "speaker": "Phyllis Lapin"},
  {"season": 2, "episode": 1, "scene": 19, "line": "Be prepared to hold folks [Michael comes out of his office] From 9 am to...", "order": 1630, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 1, "scene": 19, "line": "Michael...", "order": 1631, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 1, "scene": 19, "line": "Yes.", "order": 1632, "speaker": "Michael Scott"},
  {"season": 2, "episode": 1, "scene": 19, "line": "...Dwight is banning us from our bathroom.", "order": 1633, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 1, "scene": 19, "line": "Okay, well, that's just ridiculous, so just don't, I-I don't have time for this right now.", "order": 1634, "speaker": "Michael Scott"},
  {"season": 2, "episode": 1, "scene": 19, "line": "Nnnnno, there needs to be repercussions...", "order": 1635, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 1, "scene": 19, "line": "Just don't, don't talk-", "order": 1636, "speaker": "Michael Scott"},
  {"season": 2, "episode": 1, "scene": 19, "line": "...for people's behavior.", "order": 1637, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 1, "scene": 19, "line": "Don't talk-", "order": 1638, "speaker": "Michael Scott"},
  {"season": 2, "episode": 1, "scene": 19, "line": "And it's-", "order": 1639, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 1, "scene": 19, "line": "Just STAP IT YAP IT!!!!", "order": 1640, "speaker": "Michael Scott"},
  {"season": 2, "episode": 1, "scene": 20, "line": "Okay, look, I know there have been a lot of rumors flying around about the Dundies this year. How there is no money, and how there is no food, and how the jokes are really bad, but WHAT THE HELL EVERYBODY!? I mean, God. The Dundies are about the best, in every, one of us. Can't you see that? I mean, okay, we can do better. so, tonight, for the first time, we are inviting all of your friends and family to attend the awards with us.", "order": 1641, "speaker": "Michael Scott"},
  {"season": 2, "episode": 1, "scene": 20, "line": "[with a small fist pump] Yes!", "order": 1642, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 1, "scene": 20, "line": "Yeah, not bad, right? So let's make this the best Dundies ever.", "order": 1643, "speaker": "Michael Scott"},
  {"season": 2, "episode": 1, "scene": 20, "line": "[clapping] Best Dundies ever.", "order": 1644, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 1, "scene": 21, "line": "Welcome to the eighth annual Dundies awards.", "order": 1645, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 1, "scene": 21, "line": "Before we get started, a few announcements. Keep your acceptance speeches short, I have wrap it up music, and I'm not afraid to use it. [points] Devon!", "order": 1646, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 1, "scene": 22, "line": "The Dundies, how can I explain it? Awards you like to hate it. I'm psyched you all made it. You never had to work so hard and feel that no one notices you. You're just a name and number and no one even says hello.' [to Ryan] Card!", "order": 1647, "speaker": "Michael Scott"},
  {"season": 2, "episode": 1, "scene": 23, "line": "The Dundies are kind of like a kid's birthday party, and you go, and there's really nothing for you to do there. But the kid's having a really good time, so you're, kind of there. That's-that's kind of what it's like.", "order": 1648, "speaker": "Oscar Martinez"},
  {"season": 2, "episode": 1, "scene": 24, "line": "You down with The Dundies? You down with the Dundie-'", "order": 1649, "speaker": "Michael Scott"},
  {"season": 2, "episode": 1, "scene": 24, "line": "The waitress tripped on the cord.", "order": 1650, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 1, "scene": 24, "line": "Alright, alright, joke landed. So we are here, thank you all for coming to the 2005 Dundie awards. [takes off sweater to reveal tuxedo] I am your host, Michael Scott. And I just want to tell you please, please, do not drink and drive. Because you may hit a bump and spill the drink!", "order": 1651, "speaker": "Michael Scott"},
  {"season": 2, "episode": 1, "scene": 25, "line": "[to waitress] Oh, just put these on the group tab.", "order": 1652, "speaker": "Kevin Malone"},
  {"season": 2, "episode": 1, "scene": 25, "line": "Nope, actually this year, ah, no group tab, we're going to be doing separate checks.", "order": 1653, "speaker": "Michael Scott"},
  {"season": 2, "episode": 1, "scene": 25, "line": "You said, we could bring our families.", "order": 1654, "speaker": "Stanley Hudson"},
  {"season": 2, "episode": 1, "scene": 25, "line": "I did. And why didn't ya Stanley?", "order": 1655, "speaker": "Michael Scott"},
  {"season": 2, "episode": 1, "scene": 25, "line": "I did, my wife's name is Terri.", "order": 1656, "speaker": "Stanley Hudson"},
  {"season": 2, "episode": 1, "scene": 25, "line": "Well, I'm looking forward to meeting Terri.", "order": 1657, "speaker": "Michael Scott"},
  {"season": 2, "episode": 1, "scene": 25, "line": "It's this person who's hand I'm holding Michael.", "order": 1658, "speaker": "Stanley Hudson"},
  {"season": 2, "episode": 1, "scene": 26, "line": "[to Dwight, in a low voice] Shut it. [normal voice] Um, good. Speaking of relationships, of all, all way shapes and forms. Um, I was out on a very, very hot date with a girl from HR, Dwight.", "order": 1659, "speaker": "Michael Scott"},
  {"season": 2, "episode": 1, "scene": 26, "line": "Really? We don't have any girls from HR.", "order": 1660, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 1, "scene": 26, "line": "No, that...for the sake of the story. And things were getting hot and heavy.", "order": 1661, "speaker": "Michael Scott"},
  {"season": 2, "episode": 1, "scene": 26, "line": "Yeah?", "order": 1662, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 1, "scene": 26, "line": "And I was about to take her bra off...", "order": 1663, "speaker": "Michael Scott"},
  {"season": 2, "episode": 1, "scene": 26, "line": "Yeah!", "order": 1664, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 1, "scene": 26, "line": "...when she made me fill out six hours of paperwork-", "order": 1665, "speaker": "Michael Scott"},
  {"season": 2, "episode": 1, "scene": 26, "line": "Like an AIDS test?", "order": 1666, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 1, "scene": 26, "line": "No! [under his breath] God.", "order": 1667, "speaker": "Michael Scott"},
  {"season": 2, "episode": 1, "scene": 27, "line": "[clears throat] Alright, so let's get this party staaaarrrrted.", "order": 1668, "speaker": "Michael Scott"},
  {"season": 2, "episode": 1, "scene": 28, "line": "Hey let's go to Poor Richard's.", "order": 1669, "speaker": "Darryl Philbin"},
  {"season": 2, "episode": 1, "scene": 28, "line": "Yeah, let's get out of here.", "order": 1670, "speaker": "Roy Anderson"},
  {"season": 2, "episode": 1, "scene": 28, "line": "Um...", "order": 1671, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 1, "scene": 29, "line": "Um, guys, where you going? Pam, show's just getting started.", "order": 1672, "speaker": "Michael Scott"},
  {"season": 2, "episode": 1, "scene": 29, "line": "Sorry.", "order": 1673, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 1, "scene": 30, "line": "You staying?", "order": 1674, "speaker": "Ryan Howard"},
  {"season": 2, "episode": 1, "scene": 30, "line": "Yeah, gotta eat somewhere.", "order": 1675, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 1, "scene": 31, "line": "And now... to someone who quietly goes about their job, but always seems to land the biggest accounts...", "order": 1676, "speaker": "Michael Scott"},
  {"season": 2, "episode": 1, "scene": 31, "line": "...the 'Busiest Beaver' award goes to Phyllis Lapin.", "order": 1677, "speaker": "Michael Scott"},
  {"season": 2, "episode": 1, "scene": 31, "line": "Yeah, way to go Phyllis. Nice work, per usual.", "order": 1678, "speaker": "Michael Scott"},
  {"season": 2, "episode": 1, "scene": 31, "line": "This says 'Bushiest Beaver'.", "order": 1679, "speaker": "Phyllis Lapin"},
  {"season": 2, "episode": 1, "scene": 31, "line": "What? I told them busiest...idiots.", "order": 1680, "speaker": "Michael Scott"},
  {"season": 2, "episode": 1, "scene": 31, "line": "It's, it's fine.", "order": 1681, "speaker": "Phyllis Lapin"},
  {"season": 2, "episode": 1, "scene": 31, "line": "Well, we'll fix it up. You don't have to display that.", "order": 1682, "speaker": "Michael Scott"},
  {"season": 2, "episode": 1, "scene": 32, "line": "...because that's what happens every time!", "order": 1683, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 1, "scene": 32, "line": "...talking about? He's a jackass every year.", "order": 1684, "speaker": "Roy Anderson"},
  {"season": 2, "episode": 1, "scene": 32, "line": "No.", "order": 1685, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 1, "scene": 32, "line": "[Put's his hand on Pam's arm] Come on, we're going to Poor Richard's.", "order": 1686, "speaker": "Roy Anderson"},
  {"season": 2, "episode": 1, "scene": 32, "line": "[Breaks Roy's grip] No, I don't want to go, I don't want to.", "order": 1687, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 1, "scene": 32, "line": "Pam. Go.", "order": 1688, "speaker": "Roy Anderson"},
  {"season": 2, "episode": 1, "scene": 32, "line": "If you would have asked me that, then you would know.", "order": 1689, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 1, "scene": 33, "line": "[in a stereotypical oriental accent] Herro everybodeeee. I'm gonna cwall Jan Revinson-Gould.", "order": 1690, "speaker": "Michael Scott"},
  {"season": 2, "episode": 1, "scene": 34, "line": "Hey! How are ya? I thought you left?", "order": 1691, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 1, "scene": 34, "line": "Oh no, I just, I decided to stay.", "order": 1692, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 1, "scene": 34, "line": "Oh!", "order": 1693, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 1, "scene": 34, "line": "I'll just get a ride home from Angela.", "order": 1694, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 1, "scene": 34, "line": "Oh.", "order": 1695, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 1, "scene": 35, "line": "Oh good, I'm just in time for Ping.", "order": 1696, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 1, "scene": 35, "line": "Yeah.", "order": 1697, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 1, "scene": 35, "line": "[doing impression] Oh, dat wir be fwar. Ah, me so horny.", "order": 1698, "speaker": "Michael Scott"},
  {"season": 2, "episode": 1, "scene": 35, "line": "Right? You know wat I'm talking 'bout.", "order": 1699, "speaker": "Michael Scott"},
  {"season": 2, "episode": 1, "scene": 36, "line": "[to an off camera waiter and still clutching Jim's beer] Can I get a drink?", "order": 1700, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 1, "scene": 37, "line": "This next award goes to somebody, who really, lights up the office.", "order": 1701, "speaker": "Michael Scott"},
  {"season": 2, "episode": 1, "scene": 37, "line": "Somebody, who I think a lot of us, cannot keep, from checking out. The 'Hottest in the Office' award goes to... ...Ryan the temp!", "order": 1702, "speaker": "Michael Scott"},
  {"season": 2, "episode": 1, "scene": 37, "line": "Yeah. [singing to music] 'Hidy ho, you sexy thang. You sexy thang you.' Here you go.", "order": 1703, "speaker": "Michael Scott"},
  {"season": 2, "episode": 1, "scene": 38, "line": "What am I going to do with the award? Nothing. I-I don't know what I'm going to do. That's the least of my...concerns right now.", "order": 1704, "speaker": "Ryan Howard"},
  {"season": 2, "episode": 1, "scene": 39, "line": "And the 'Tight Ass' award goes to Angela. Not only because she is everybody's favorite stickler, but because she has, a great caboose. So...come on down.", "order": 1705, "speaker": "Michael Scott"},
  {"season": 2, "episode": 1, "scene": 39, "line": "No.", "order": 1706, "speaker": "Angela Martin"},
  {"season": 2, "episode": 1, "scene": 40, "line": "[Pam starts sipping an empty glass] I think those might be empty.", "order": 1707, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 1, "scene": 40, "line": "No, no. 'Cause the ice melts and then it's like second drink! [laughs]", "order": 1708, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 1, "scene": 40, "line": "Second drink?", "order": 1709, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 1, "scene": 41, "line": "The 'Spicy Curry' award goes to our very own Kelly Kapoor! Get on up here. Here you go.", "order": 1710, "speaker": "Michael Scott"},
  {"season": 2, "episode": 1, "scene": 41, "line": "Spicy Curry', what's that mean?", "order": 1711, "speaker": "Kelly Kapoor"},
  {"season": 2, "episode": 1, "scene": 41, "line": "Um, not everything means something, it's just a joke.", "order": 1712, "speaker": "Michael Scott"},
  {"season": 2, "episode": 1, "scene": 41, "line": "Yeah, but why'd you give it to me?", "order": 1713, "speaker": "Kelly Kapoor"},
  {"season": 2, "episode": 1, "scene": 41, "line": "I don't know, it's just...", "order": 1714, "speaker": "Michael Scott"},
  {"season": 2, "episode": 1, "scene": 41, "line": "This is a bowler-", "order": 1715, "speaker": "Kelly Kapoor"},
  {"season": 2, "episode": 1, "scene": 41, "line": "I know. It's ju- they didn't have any more businessmen. So...", "order": 1716, "speaker": "Michael Scott"},
  {"season": 2, "episode": 1, "scene": 41, "line": "Yeah, but everyone else-", "order": 1717, "speaker": "Kelly Kapoor"},
  {"season": 2, "episode": 1, "scene": 41, "line": "Just sit down Kelly.", "order": 1718, "speaker": "Michael Scott"},
  {"season": 2, "episode": 1, "scene": 42, "line": "[sweaty and chugging water from a bottle] It's so freakin' hot in there. Now I know what Bob Hope was going through when he performed in Saudi Arabia. Man! I got Dwight sucking the funny out of the room, but you do what you can do. [Music starts playing in the background] Here we go, he's early with the cue. Here we go.", "order": 1719, "speaker": "Michael Scott"},
  {"season": 2, "episode": 1, "scene": 43, "line": "[Michael is singing to the tune of 'Tiny Dancer' by Elton John] 'You have won a tiny Dundie.'", "order": 1720, "speaker": "Michael Scott"},
  {"season": 2, "episode": 1, "scene": 43, "line": "Sing it Elton.", "order": 1721, "speaker": "Guy at bar"},
  {"season": 2, "episode": 1, "scene": 43, "line": "Hey, thanks guys. Hey, where you guys from?", "order": 1722, "speaker": "Michael Scott"},
  {"season": 2, "episode": 1, "scene": 43, "line": "We just came from yo' mama's house.", "order": 1723, "speaker": "Other Guy at Bar"},
  {"season": 2, "episode": 1, "scene": 44, "line": "Oh, alright, yeah.", "order": 1724, "speaker": "Michael Scott"},
  {"season": 2, "episode": 1, "scene": 44, "line": "Sing 'em a song dude.", "order": 1725, "speaker": "Guy At Bar"},
  {"season": 2, "episode": 1, "scene": 44, "line": "Uh, you know what guys, we're just having a little office party, so if you want, uh...", "order": 1726, "speaker": "Michael Scott"},
  {"season": 2, "episode": 1, "scene": 44, "line": "[Something flies by Michael] Hey, you know, cool it guys, really-", "order": 1727, "speaker": "Michael Scott"},
  {"season": 2, "episode": 1, "scene": 44, "line": "You suck man!", "order": 1728, "speaker": "Guy At Bar"},
  {"season": 2, "episode": 1, "scene": 44, "line": "Let's cut it. [Dwight turns the music off]", "order": 1729, "speaker": "Michael Scott"},
  {"season": 2, "episode": 1, "scene": 44, "line": "[clears throat] [with a lot less enthusiasm] I had a few more Dundies to, uh, give out tonight, but, I'm just going to cut it short. And wrap it up so everybody can enjoy their food. Um...thanks for listening, those who listened. [clears throat] This last Dundie is for Kevin, this is the 'Don't Go in There After Me' award. It's for the time that I went into the bathroom after him, and it was really, really smelly. So...", "order": 1730, "speaker": "Michael Scott"},
  {"season": 2, "episode": 1, "scene": 44, "line": "[give Kevin his award]There you go.", "order": 1731, "speaker": "Michael Scott"},
  {"season": 2, "episode": 1, "scene": 45, "line": "Yay Kevin. Whoo hoo for Kevin! For stinking up the bathroom.", "order": 1732, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 1, "scene": 45, "line": "[starts clapping] Yeah, alright Kev.", "order": 1733, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 1, "scene": 45, "line": "Woo! Hey, I haven't gotten one yet!", "order": 1734, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 1, "scene": 45, "line": "Yes, I have not gotten one either. So, keep going.", "order": 1735, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 1, "scene": 45, "line": "More Dundies!", "order": 1736, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 1, "scene": 45, "line": "[clapping] Dundies! Dundies! Dundies! Dundies!", "order": 1737, "speaker": "Pam Beesly & Jim Halpert"},
  {"season": 2, "episode": 1, "scene": 45, "line": "Dundies! Dundies!", "order": 1738, "speaker": "Everybody"},
  {"season": 2, "episode": 1, "scene": 45, "line": "[getting his spirit back] Alright, alright, alright, okay. Alright, we'll keep rolling. Okay, this is the fine work award. This goes to Stanley, for all the fine work he did this year.", "order": 1739, "speaker": "Michael Scott"},
  {"season": 2, "episode": 1, "scene": 45, "line": "Fine work! Fine work Stanley!", "order": 1740, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 1, "scene": 45, "line": "You know you did.", "order": 1741, "speaker": "Michael Scott"},
  {"season": 2, "episode": 1, "scene": 46, "line": "Here here! Speech, speech, speech, speech [other people start joining in]", "order": 1742, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 1, "scene": 46, "line": "Well, well, last year, I got great work, so I don't...", "order": 1743, "speaker": "Stanley Hudson"},
  {"season": 2, "episode": 1, "scene": 46, "line": "So, I don't know what to think about this award. But at least I didn't get smelliest bowel movement like Kevin. [starts chuckling]", "order": 1744, "speaker": "Stanley Hudson"},
  {"season": 2, "episode": 1, "scene": 47, "line": "And this next award is going out to our own little Pam Beesley...", "order": 1745, "speaker": "Michael Scott"},
  {"season": 2, "episode": 1, "scene": 47, "line": "...I think we all know what award Pam is going to be getting this year.", "order": 1746, "speaker": "Michael Scott"},
  {"season": 2, "episode": 1, "scene": 47, "line": "It is the 'Whitest Sneakers' award! Because she always has the whitest tennis shoes on!", "order": 1747, "speaker": "Michael Scott"},
  {"season": 2, "episode": 1, "scene": 48, "line": "Get on down here! Pam Beesley ladies and gentlemen! [Pam grabs the microphone from him] Oh, here we go.", "order": 1748, "speaker": "Michael Scott"},
  {"season": 2, "episode": 1, "scene": 48, "line": "I have so many people to thank for this award.", "order": 1749, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 1, "scene": 48, "line": "Okay, first off, my Keds. Because I couldn't have done it without them. [people clap] Thank you. Let's give Michael a round of applause for MC-ing tonight because [people start clapping again] this is a lot harder than it looks. And also because of Dwight too.", "order": 1750, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 1, "scene": 48, "line": "Um, so, finally, I want to thank God. Because God gave me this Dundie.", "order": 1751, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 1, "scene": 48, "line": "And, I feel God in this Chili's tonight. WHOOOOOOOO!!!!", "order": 1752, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 1, "scene": 48, "line": "Pam Beesley ladies and gentleman. [Pam hugs Michael and gives him a quick peck on the cheek] Oh! Thank you.", "order": 1753, "speaker": "Michael Scott"},
  {"season": 2, "episode": 1, "scene": 49, "line": "What a great year for the Dundies.", "order": 1754, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 1, "scene": 50, "line": "We got to see Ping. [Pam nods] And we learned of Michael's true feelings for Ryan. [Pam nods] Which was touching. And, we heard Michael change the lyrics to a number of classic songs. [Pam nods] Which for me, has ruined them for life. [looks at Pam, who is staring at him, nodding]", "order": 1755, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 1, "scene": 50, "line": "What?", "order": 1756, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 1, "scene": 50, "line": "Nothing.", "order": 1757, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 1, "scene": 50, "line": "Okay.", "order": 1758, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 1, "scene": 50, "line": "What?", "order": 1759, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 1, "scene": 50, "line": "I don't know, what?", "order": 1760, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 1, "scene": 50, "line": "Oh my God! You are so drunk!", "order": 1761, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 1, "scene": 51, "line": "Did you get that? Please tell me you got that. This is all going to be on.", "order": 1762, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 1, "scene": 51, "line": "Quick, quick, the woman is having a seizure. Grab her tongue, grab her tongue! It's okay, I'm a sheriff's deputy.", "order": 1763, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 1, "scene": 51, "line": "He's a volunteer.", "order": 1764, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 1, "scene": 51, "line": "Don't get into that now. We need something to cushion her head. Throw pillow? A cush-", "order": 1765, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 1, "scene": 51, "line": "Dwight come on, come-", "order": 1766, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 1, "scene": 51, "line": "It's okay, I'm going to use my shirt.", "order": 1767, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 1, "scene": 51, "line": "Dwight, get off me!", "order": 1768, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 1, "scene": 51, "line": "I'm sorry, you're gonna have to put your clothes back on, people are trying to eat.", "order": 1769, "speaker": "Employee"},
  {"season": 2, "episode": 1, "scene": 51, "line": "[struggling] Ahh! I can't-", "order": 1770, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 1, "scene": 52, "line": "Was this year's Dundies a success? Well, let's see, I made Pam laugh so hard, that she fell out of her chair, and she almost broke her neck. So I killed, almost.", "order": 1771, "speaker": "Michael Scott"},
  {"season": 2, "episode": 1, "scene": 53, "line": "Oh my God!", "order": 1772, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 1, "scene": 53, "line": "Whoa.", "order": 1773, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 1, "scene": 53, "line": "I just want to say, that this was the best, Dundies, ever! WHOOOOOOOOO!!!", "order": 1774, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 1, "scene": 53, "line": "Whoa.", "order": 1775, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 1, "scene": 53, "line": "Whoa, careful, careful.", "order": 1776, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 1, "scene": 54, "line": "We have a strict policy here not to over serve. Apparently, this young woman was sneaking drinks off other people's tables. I Xeroxed her driver's license and she is not welcome to this restaurant chain ever again.", "order": 1777, "speaker": "Chili's Employee"},
  {"season": 2, "episode": 1, "scene": 55, "line": "Great work tonight.", "order": 1778, "speaker": "Michael Scott"},
  {"season": 2, "episode": 1, "scene": 55, "line": "Watch your step.", "order": 1779, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 1, "scene": 55, "line": "Excellent.", "order": 1780, "speaker": "Michael Scott"},
  {"season": 2, "episode": 1, "scene": 55, "line": "Thanks, I had to, uh, check her pupils to make sure there wasn't a concussion.", "order": 1781, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 1, "scene": 55, "line": "Yes, that too, but I mean with the audio. Great work.", "order": 1782, "speaker": "Michael Scott"},
  {"season": 2, "episode": 1, "scene": 56, "line": "I feel bad about what I wrote on the bathroom wall.", "order": 1783, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 1, "scene": 56, "line": "No you don't.", "order": 1784, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 1, "scene": 57, "line": "Oh, here she is. Careful, careful, whoa. Alright, easy. Almost there.", "order": 1785, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 1, "scene": 57, "line": "Hey, um, can I ask you a question?", "order": 1786, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 1, "scene": 57, "line": "Shoot.", "order": 1787, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 1, "scene": 57, "line": "Um, I just wanted to say thanks.", "order": 1788, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 1, "scene": 57, "line": "Not really a question. [starts to laugh] Okay, let's get you home, you're drunk.", "order": 1789, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 1, "scene": 57, "line": "Alright.", "order": 1790, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 1, "scene": 57, "line": "Bye.", "order": 1791, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 1, "scene": 57, "line": "Goodnight, have a good night. Thank you Angela.", "order": 1792, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 1, "scene": 64, "line": "The weird part about this whole deal this that Michael truly believes that these awards are inspiring and motivational. I mean, he puts more effort and time and commitment into the Dundies than anything else he does all year. Which is probably why this branch will be downsized.", "order": 1793, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 1, "scene": 67, "line": "Angela, it's simple. Just tell me what the bathroom wall says.", "order": 1794, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 1, "scene": 67, "line": "I don't talk about what goes on in the restroom.", "order": 1795, "speaker": "Angela Martin"},
  {"season": 2, "episode": 1, "scene": 67, "line": "I'm not asking what you do in there. I just want to know what's written about Michael.", "order": 1796, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 1, "scene": 67, "line": "I don't says those kinds of things out loud. It's inappropriate.", "order": 1797, "speaker": "Angela Martin"},
  {"season": 2, "episode": 1, "scene": 67, "line": "Okay, I'll talk. You nod if I get it right. Does it have to do with his butt or his wiener?", "order": 1798, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 1, "scene": 69, "line": "Well, what should I do, guys? Seriously, do you want me to just cancel the Dundies?", "order": 1799, "speaker": "Michael Scott"},
  {"season": 2, "episode": 1, "scene": 69, "line": "No, we were just hoping you wouldn't do those characters, like last time.", "order": 1800, "speaker": "Kelly Kapoor"},
  {"season": 2, "episode": 1, "scene": 69, "line": "Ping? Everybody loves Ping. He's my most popular character.", "order": 1801, "speaker": "Michael Scott"},
  {"season": 2, "episode": 1, "scene": 69, "line": "I think many people find that character slightly racist.", "order": 1802, "speaker": "Oscar Martinez"},
  {"season": 2, "episode": 1, "scene": 69, "line": "Really? Well, he is based on the Chinese delivery guy that I have, who talks exactly like that, so, I think you should take it up with him instead of me.", "order": 1803, "speaker": "Michael Scott"},
  {"season": 2, "episode": 1, "scene": 69, "line": "Look, I'm just saying that there are many people that are offered by your insensitive skits.", "order": 1804, "speaker": "Oscar Martinez"},
  {"season": 2, "episode": 1, "scene": 69, "line": "[sighs] Well, neither of you are Chinese, so what... Why do you care?", "order": 1805, "speaker": "Michael Scott"},
  {"season": 2, "episode": 1, "scene": 73, "line": "[singing] You down with the Dundies?", "order": 1806, "speaker": "Michael Scott"},
  {"season": 2, "episode": 1, "scene": 73, "line": "Yeah, you know me.", "order": 1807, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 1, "scene": 73, "line": "[singing] You down with the Dundies?", "order": 1808, "speaker": "Michael Scott"},
  {"season": 2, "episode": 1, "scene": 73, "line": "The Dundies!", "order": 1809, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 1, "scene": 73, "line": "[singing] You down with the Dundies? You're all my homies", "order": 1810, "speaker": "Michael Scott"},
  {"season": 2, "episode": 1, "scene": 73, "line": "You know me.", "order": 1811, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 1, "scene": 73, "line": "[singing] You down with the Dundies?", "order": 1812, "speaker": "Michael Scott"},
  {"season": 2, "episode": 1, "scene": 73, "line": "Yeah, you know me.", "order": 1813, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 1, "scene": 73, "line": "[singing] Yeah, all the homies", "order": 1814, "speaker": "Michael Scott"},
  {"season": 2, "episode": 1, "scene": 74, "line": "I have listened to that song so many times and I still don't know what OPP stands for. Other people's... Something. I... [shakes head]", "order": 1815, "speaker": "Michael Scott"},
  {"season": 2, "episode": 2, "scene": 1, "line": "[clears throat] Hey, what's up?", "order": 1816, "speaker": "Michael Scott"},
  {"season": 2, "episode": 2, "scene": 1, "line": "Hey.", "order": 1817, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 2, "scene": 1, "line": "Any emails today?", "order": 1818, "speaker": "Michael Scott"},
  {"season": 2, "episode": 2, "scene": 1, "line": "Um... I don't think so.", "order": 1819, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 2, "scene": 1, "line": "No? Um... Check your spam folder.", "order": 1820, "speaker": "Michael Scott"},
  {"season": 2, "episode": 2, "scene": 1, "line": "Oh! There it is!", "order": 1821, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 2, "scene": 1, "line": "What?", "order": 1822, "speaker": "Michael Scott"},
  {"season": 2, "episode": 2, "scene": 1, "line": "Um... 'Fifty signs your priest might be Michael Jackson.'", "order": 1823, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 2, "scene": 1, "line": "[laughs uncontrollably]", "order": 1824, "speaker": "Michael Scott"},
  {"season": 2, "episode": 2, "scene": 1, "line": "Well done.", "order": 1825, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 2, "scene": 1, "line": "Kay.", "order": 1826, "speaker": "Michael Scott"},
  {"season": 2, "episode": 2, "scene": 1, "line": "Topical.", "order": 1827, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 2, "scene": 2, "line": "I am king of forwards. It's how I like to do business, everybody joking around. We're like 'Friends'. I am Chandler and Joey and, uh, Pam is Rachel. And Dwight is Kramer.", "order": 1828, "speaker": "Michael Scott"},
  {"season": 2, "episode": 2, "scene": 3, "line": "So the monkey does the sex thing right here! [monkey noises in background]", "order": 1829, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 2, "scene": 3, "line": "That's funny! That's funny. Not offensive. Uh... because it's nature. Educational.", "order": 1830, "speaker": "Michael Scott"},
  {"season": 2, "episode": 2, "scene": 3, "line": "Do you want the link because then you could forward it around?", "order": 1831, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 2, "scene": 3, "line": "Um, I...", "order": 1832, "speaker": "Michael Scott"},
  {"season": 2, "episode": 2, "scene": 3, "line": "Consider it?", "order": 1833, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 2, "scene": 3, "line": "Yeah... maybe. Maybe. Well, we'll see. Because I... I don't know if it's... [muffled by jacket over his head] Whup! Come on! Hey!", "order": 1834, "speaker": "Michael Scott"},
  {"season": 2, "episode": 2, "scene": 3, "line": "What has two thumbs and likes to bone your Mom? [points at self] This guy!", "order": 1835, "speaker": "Todd Packer"},
  {"season": 2, "episode": 2, "scene": 3, "line": "Kay! Oh, you are so bad! Yeah!", "order": 1836, "speaker": "Michael Scott"},
  {"season": 2, "episode": 2, "scene": 3, "line": "[makes laser gun noises]", "order": 1837, "speaker": "Todd Packer"},
  {"season": 2, "episode": 2, "scene": 3, "line": "Oh, Boom! Bam! Oh, this guy is out of control! He is a madman! Better get the bleep button ready for him.", "order": 1838, "speaker": "Michael Scott"},
  {"season": 2, "episode": 2, "scene": 3, "line": "bleep, bleep. What's up, Halpert?", "order": 1839, "speaker": "Todd Packer"},
  {"season": 2, "episode": 2, "scene": 3, "line": "Uh oh.", "order": 1840, "speaker": "Michael Scott"},
  {"season": 2, "episode": 2, "scene": 3, "line": "Still queer?", "order": 1841, "speaker": "Todd Packer"},
  {"season": 2, "episode": 2, "scene": 3, "line": "Uh oh! Oh-ho-ho-ho-ho-o!", "order": 1842, "speaker": "Michael Scott"},
  {"season": 2, "episode": 2, "scene": 4, "line": "Todd Packer and I are total BFF. Best Friends Forever. He and I came up together as salesmen. One time, we were out and we met this set of twins. And Packer told them that we were brothers. And so, you know, one thing led to another, and we brought em back to the motel. And then Packer did both of them. It was awesome. So...", "order": 1843, "speaker": "Michael Scott"},
  {"season": 2, "episode": 2, "scene": 5, "line": "Oh-whoa-oh! Oh! Okay. Grade 'A' gossip for you, right now. Randall, CFO, resigned. Nobody knows why.", "order": 1844, "speaker": "Michael Scott"},
  {"season": 2, "episode": 2, "scene": 5, "line": "Are you kidding? Everyone knows why! You don't know? Okay, check this out. Al lright. So here's the story. So Randall is nailing his secretary, right? And she is totally incompetent.", "order": 1845, "speaker": "Todd Packer"},
  {"season": 2, "episode": 2, "scene": 5, "line": "Really? Here we go! Buckle up. It's going to be a bumpy one!", "order": 1846, "speaker": "Michael Scott"},
  {"season": 2, "episode": 2, "scene": 5, "line": "We're talking blonde incompetent.", "order": 1847, "speaker": "Todd Packer"},
  {"season": 2, "episode": 2, "scene": 5, "line": "Oh, yeah.", "order": 1848, "speaker": "Michael Scott"},
  {"season": 2, "episode": 2, "scene": 5, "line": "Like 10 words a minute... talking.", "order": 1849, "speaker": "Todd Packer"},
  {"season": 2, "episode": 2, "scene": 5, "line": "Well, to be fair... blondes, brunettes, you know, there's a lot of dumb people out there.", "order": 1850, "speaker": "Michael Scott"},
  {"season": 2, "episode": 2, "scene": 5, "line": "They are women, right?", "order": 1851, "speaker": "Todd Packer"},
  {"season": 2, "episode": 2, "scene": 5, "line": "Oh! Wow! I didn't say it! I didn't say it!", "order": 1852, "speaker": "Michael Scott"},
  {"season": 2, "episode": 2, "scene": 5, "line": "I said it. And then, suddenly, for no reason, this bimbo blows the whistle on the whole thing just to be a bitch.", "order": 1853, "speaker": "Todd Packer"},
  {"season": 2, "episode": 2, "scene": 5, "line": "Oh, wow! What did I tell you about the bleep button.", "order": 1854, "speaker": "Michael Scott"},
  {"season": 2, "episode": 2, "scene": 6, "line": "Hey, um... what has two thumbs and hates Todd Packer? [points at self] This guy!", "order": 1855, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 2, "scene": 7, "line": "Meant to ask you, can you think you can get someone to drive me around because of the, uh, DUI situation?", "order": 1856, "speaker": "Todd Packer"},
  {"season": 2, "episode": 2, "scene": 7, "line": "Oh. Bad boy. [to Ryan] Um... Ryan? [makes Donald Duck noise]", "order": 1857, "speaker": "Michael Scott"},
  {"season": 2, "episode": 2, "scene": 7, "line": "[to Ryan] Come on, kid. Let's go.", "order": 1858, "speaker": "Todd Packer"},
  {"season": 2, "episode": 2, "scene": 7, "line": "Ah! Man. That Todd Packer can do anything.", "order": 1859, "speaker": "Michael Scott"},
  {"season": 2, "episode": 2, "scene": 7, "line": "Except pass that breathalyzer.", "order": 1860, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 2, "scene": 8, "line": "You a big William Hung fan?", "order": 1861, "speaker": "Ryan Howard"},
  {"season": 2, "episode": 2, "scene": 8, "line": "Why does everyone ask me that? Who the hell is that?", "order": 1862, "speaker": "Todd Packer"},
  {"season": 2, "episode": 2, "scene": 9, "line": "[monkey noises]", "order": 1863, "speaker": "Kevin Malone's computer"},
  {"season": 2, "episode": 2, "scene": 10, "line": "I'm really excited to meet your Mom.", "order": 1864, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 2, "scene": 10, "line": "You are?", "order": 1865, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 2, "scene": 11, "line": "My Mom is coming in to visit. And she lives like two hours away. And she doesn't have a cell phone... which is cool cause it's kind of adding some suspense to my day. And I keep looking over at the door hoping she'll walk in.", "order": 1866, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 2, "scene": 12, "line": "I've decided to show her around. She really wants to meet everybody.", "order": 1867, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 2, "scene": 12, "line": "Oh yeah?", "order": 1868, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 2, "scene": 12, "line": "mmhmm.", "order": 1869, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 2, "scene": 12, "line": "Good. Cause I have a lot of questions.", "order": 1870, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 2, "scene": 12, "line": "Oh really?", "order": 1871, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 2, "scene": 12, "line": "Yeah. As a child, did Pam show any traits that would hint towards her future career as a receptionist?", "order": 1872, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 2, "scene": 13, "line": "Hey, send me that link to the monkey sex video. I'm going to forward it like it's hot.", "order": 1873, "speaker": "Michael Scott"},
  {"season": 2, "episode": 2, "scene": 13, "line": "Yes!", "order": 1874, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 2, "scene": 13, "line": "Forward it like it's hot. Forward it like it's hot. 'Old School'.", "order": 1875, "speaker": "Michael Scott"},
  {"season": 2, "episode": 2, "scene": 13, "line": "Michael?", "order": 1876, "speaker": "Toby Flenderson"},
  {"season": 2, "episode": 2, "scene": 13, "line": "Yes, Toby?", "order": 1877, "speaker": "Michael Scott"},
  {"season": 2, "episode": 2, "scene": 13, "line": "Um... I need to talk to you in your office. It'll just take two seconds.", "order": 1878, "speaker": "Toby Flenderson"},
  {"season": 2, "episode": 2, "scene": 13, "line": "Um... literally two seconds?", "order": 1879, "speaker": "Michael Scott"},
  {"season": 2, "episode": 2, "scene": 14, "line": "Toby is in HR which technically means he works for Corporate. So he's really not a part of our family. Also he's divorced so he's really not a part of his family.", "order": 1880, "speaker": "Michael Scott"},
  {"season": 2, "episode": 2, "scene": 15, "line": "The full story is that Randall resigned because of sexual harassment. So Corporate asked me to do a five minute review of the Company Sexual Harassment policy.", "order": 1881, "speaker": "Toby Flenderson"},
  {"season": 2, "episode": 2, "scene": 15, "line": "No, no, Toby. No.", "order": 1882, "speaker": "Michael Scott"},
  {"season": 2, "episode": 2, "scene": 15, "line": "It's really not a big deal, Michael.", "order": 1883, "speaker": "Toby Flenderson"},
  {"season": 2, "episode": 2, "scene": 15, "line": "It is a big deal. It's a big deal! What are we supposed to do? Scrutinize every little thing we say and do all day? I mean, come on!", "order": 1884, "speaker": "Michael Scott"},
  {"season": 2, "episode": 2, "scene": 15, "line": "And then Corporate is going to send in a lawyer...", "order": 1885, "speaker": "Toby Flenderson"},
  {"season": 2, "episode": 2, "scene": 15, "line": "What?", "order": 1886, "speaker": "Michael Scott"},
  {"season": 2, "episode": 2, "scene": 15, "line": "Just to refresh you... .", "order": 1887, "speaker": "Toby Flenderson"},
  {"season": 2, "episode": 2, "scene": 15, "line": "NO!", "order": 1888, "speaker": "Michael Scott"},
  {"season": 2, "episode": 2, "scene": 15, "line": "on our policy.", "order": 1889, "speaker": "Toby Flenderson"},
  {"season": 2, "episode": 2, "scene": 15, "line": "What? He! No! Okay, what is a lawyer going to come in and tell us? To not send out hilarious emails or not tell jokes?", "order": 1890, "speaker": "Michael Scott"},
  {"season": 2, "episode": 2, "scene": 15, "line": "Maybe not some of them. Maybe not inappropriate ones.", "order": 1891, "speaker": "Toby Flenderson"},
  {"season": 2, "episode": 2, "scene": 15, "line": "There is no such thing as an appropriate joke. That's why it's a joke.", "order": 1892, "speaker": "Michael Scott"},
  {"season": 2, "episode": 2, "scene": 16, "line": "Everyone! Hello! Everyone. Hi! Sorry to interrupt. I know you're all busy and the last thing you want is for a major interruption. But Toby has an announcement that he insists on making right now in the middle of the day. [to Toby] So, take it away.", "order": 1893, "speaker": "Michael Scott"},
  {"season": 2, "episode": 2, "scene": 16, "line": "Yeah, okay. Corporate would like us to do a five minute review of the Company Sexual Harassment policy so I'll go over that later.", "order": 1894, "speaker": "Toby Flenderson"},
  {"season": 2, "episode": 2, "scene": 16, "line": "I wish you luck, Toby. I really do. But you are going to have a mutiny on your hands and I just can't wait to see how you handle it.", "order": 1895, "speaker": "Michael Scott"},
  {"season": 2, "episode": 2, "scene": 17, "line": "A guy goes to a five dollar... lady of the night and he gets crabs. So, the next day he goes back to complain. And the woman says 'Hey. It was only five dollars. What did you expect? Lobster?' This is what's at stake.", "order": 1896, "speaker": "Michael Scott"},
  {"season": 2, "episode": 2, "scene": 18, "line": "Time to bring out the big guns. I'm heading down to the warehouse where jokes are born. Find a killer joke that'll just blow everybody away at the seminar later. And remind them what is great about this place. So... ah! Here they are. [to Warehouse guys] Guys! Wondering if I could, uh, get your help for something. I'm looking for a new joke to tell and it needs to be just killer. And it does not need to be clean. So whatcha got?", "order": 1897, "speaker": "Michael Scott"},
  {"season": 2, "episode": 2, "scene": 18, "line": "Like a joke? A knock-knock joke?", "order": 1898, "speaker": "Darryl Philbin"},
  {"season": 2, "episode": 2, "scene": 18, "line": "Um, yeah, no, well... I mean better. Better than that. The type of stuff you guys tell all day.", "order": 1899, "speaker": "Michael Scott"},
  {"season": 2, "episode": 2, "scene": 18, "line": "Well, [points at Michael] those are some awful tight pants you have on. Where'd you get em? Like Queers R Us?", "order": 1900, "speaker": "Darryl Philbin"},
  {"season": 2, "episode": 2, "scene": 18, "line": "Boys R Us!", "order": 1901, "speaker": "Roy Anderson"},
  {"season": 2, "episode": 2, "scene": 18, "line": "Oh!", "order": 1902, "speaker": "Warehouse Guy"},
  {"season": 2, "episode": 2, "scene": 18, "line": "Alright, alright. Well, yeah, but, you know... a joke but not necessarily at my expense.", "order": 1903, "speaker": "Michael Scott"},
  {"season": 2, "episode": 2, "scene": 18, "line": "Man, we can see all your business coming around the corner, okay? You need to, you know, hide the... good thing you don't have a lot of business to start with.", "order": 1904, "speaker": "Darryl Philbin"},
  {"season": 2, "episode": 2, "scene": 18, "line": "Oooh, okay. That was still about me.", "order": 1905, "speaker": "Michael Scott"},
  {"season": 2, "episode": 2, "scene": 18, "line": "Hey, hey, hey.", "order": 1906, "speaker": "Roy Anderson"},
  {"season": 2, "episode": 2, "scene": 18, "line": "What?", "order": 1907, "speaker": "Michael Scott"},
  {"season": 2, "episode": 2, "scene": 18, "line": "So you don't have the biggest package. Don't feel bad.", "order": 1908, "speaker": "Roy Anderson"},
  {"season": 2, "episode": 2, "scene": 18, "line": "I don't feel bad.", "order": 1909, "speaker": "Michael Scott"},
  {"season": 2, "episode": 2, "scene": 18, "line": "[fake whispers to Roy] I think he feels bad.", "order": 1910, "speaker": "Darryl Philbin"},
  {"season": 2, "episode": 2, "scene": 18, "line": "No, I don't.", "order": 1911, "speaker": "Michael Scott"},
  {"season": 2, "episode": 2, "scene": 18, "line": "You look like you feel bad.", "order": 1912, "speaker": "Roy Anderson"},
  {"season": 2, "episode": 2, "scene": 18, "line": "Okay.", "order": 1913, "speaker": "Michael Scott"},
  {"season": 2, "episode": 2, "scene": 18, "line": "Little package!", "order": 1914, "speaker": "Roy Anderson"},
  {"season": 2, "episode": 2, "scene": 18, "line": "Well, not exactly what I was looking for but thanks guys.", "order": 1915, "speaker": "Michael Scott"},
  {"season": 2, "episode": 2, "scene": 18, "line": "Little package! Little package!", "order": 1916, "speaker": "Warehouse guy"},
  {"season": 2, "episode": 2, "scene": 18, "line": "Thank you.", "order": 1917, "speaker": "Michael Scott"},
  {"season": 2, "episode": 2, "scene": 18, "line": "You look good.", "order": 1918, "speaker": "Roy Anderson"},
  {"season": 2, "episode": 2, "scene": 18, "line": "Hiding from his momma.", "order": 1919, "speaker": "Darryl Philbin"},
  {"season": 2, "episode": 2, "scene": 18, "line": "[kissing noises, sheep baaing sounds]", "order": 1920, "speaker": "Warehouse guys"},
  {"season": 2, "episode": 2, "scene": 19, "line": "So remember, intent is irrelevant. And that's it. Pam?", "order": 1921, "speaker": "Toby Flenderson"},
  {"season": 2, "episode": 2, "scene": 19, "line": "Um... I just wanted to say that... Just, my Mom's coming in today.", "order": 1922, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 2, "scene": 19, "line": "MILF!", "order": 1923, "speaker": "Kevin Malone"},
  {"season": 2, "episode": 2, "scene": 19, "line": "Thanks, Kevin.", "order": 1924, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 2, "scene": 20, "line": "Usually the day we talk about sexual harassment is the day that everyone harasses me as a joke.", "order": 1925, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 2, "scene": 21, "line": "She's coming in today and maybe just don't joke around about that stuff in front of her.", "order": 1926, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 2, "scene": 21, "line": "Great point.", "order": 1927, "speaker": "Toby Flenderson"},
  {"season": 2, "episode": 2, "scene": 21, "line": "Thank you.", "order": 1928, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 2, "scene": 21, "line": "Um... in fact, basic rule of thumb, let's just act everyday like Pam's Mom's coming in. All right. That's it. Um... if anybody has any questions about anything, you know where I sit in the back.", "order": 1929, "speaker": "Toby Flenderson"},
  {"season": 2, "episode": 2, "scene": 21, "line": "Hi, is it over?", "order": 1930, "speaker": "Michael Scott"},
  {"season": 2, "episode": 2, "scene": 21, "line": "Uh, yes!", "order": 1931, "speaker": "Toby Flenderson"},
  {"season": 2, "episode": 2, "scene": 21, "line": "No.", "order": 1932, "speaker": "Michael Scott"},
  {"season": 2, "episode": 2, "scene": 21, "line": "I can go over it with you.", "order": 1933, "speaker": "Toby Flenderson"},
  {"season": 2, "episode": 2, "scene": 21, "line": "I know, I know. It's good. It is not over. It is not over til it's over.", "order": 1934, "speaker": "Michael Scott"},
  {"season": 2, "episode": 2, "scene": 21, "line": "It's over.", "order": 1935, "speaker": "Toby Flenderson"},
  {"season": 2, "episode": 2, "scene": 21, "line": "Did he tell you everything? Obviously, he didn't because you all still look relatively happy. Albeit bored. Do you realize what we're losing? Seriously?", "order": 1936, "speaker": "Michael Scott"},
  {"season": 2, "episode": 2, "scene": 21, "line": "Email forwards.", "order": 1937, "speaker": "Angela Martin"},
  {"season": 2, "episode": 2, "scene": 21, "line": "Exactly! Mmwwah [blows kiss to Angela]! Can we afford to lose email forwards? Do we want that?", "order": 1938, "speaker": "Michael Scott"},
  {"season": 2, "episode": 2, "scene": 21, "line": "I hate them. You send me these filthy emails and you say forward them to ten people or you'll have bad luck.", "order": 1939, "speaker": "Angela Martin"},
  {"season": 2, "episode": 2, "scene": 21, "line": "Give me a break. Umm... Stanley, how about that hot picture you have by your desk? Centerfold in the Catholic schoolgirl's outfit? I mean, it is hot, it is sexy, and it turns him on. And I will admit, best part of my morning is staring at it. But what? Are we just going to take it away?", "order": 1940, "speaker": "Michael Scott"},
  {"season": 2, "episode": 2, "scene": 21, "line": "That is my daughter. She goes to Catholic girls' school. I am taking it down right now.", "order": 1941, "speaker": "Stanley Hudson"},
  {"season": 2, "episode": 2, "scene": 21, "line": "Um... what about office romance?", "order": 1942, "speaker": "Meredith Palmer"},
  {"season": 2, "episode": 2, "scene": 21, "line": "Office relationships are never a good idea. Yeah. So let's just try to avoid them. But, um, if you already have one, you should disclose it to HR.", "order": 1943, "speaker": "Toby Flenderson"},
  {"season": 2, "episode": 2, "scene": 21, "line": "All relationships? Eh, even a one-night stand?", "order": 1944, "speaker": "Phyllis Lapin"},
  {"season": 2, "episode": 2, "scene": 21, "line": "I think the old honor system was just fine. For example, I have never slept with an employee. And, believe me, I could have.", "order": 1945, "speaker": "Michael Scott"},
  {"season": 2, "episode": 2, "scene": 21, "line": "Yeah, Meredith.", "order": 1946, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 2, "scene": 21, "line": "No! No! Catherine. Remember her? Remember how hot she was?", "order": 1947, "speaker": "Michael Scott"},
  {"season": 2, "episode": 2, "scene": 21, "line": "Yes.", "order": 1948, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 2, "scene": 21, "line": "She would have definitely slept with me.", "order": 1949, "speaker": "Michael Scott"},
  {"season": 2, "episode": 2, "scene": 21, "line": "She wasn't that hot.", "order": 1950, "speaker": "Kevin Malone"},
  {"season": 2, "episode": 2, "scene": 21, "line": "Yes, she was. Dammit, Kevin!", "order": 1951, "speaker": "Michael Scott"},
  {"season": 2, "episode": 2, "scene": 21, "line": "Ok, you know, Michael...", "order": 1952, "speaker": "Toby Flenderson"},
  {"season": 2, "episode": 2, "scene": 22, "line": "I'm in an office relationship. It's special. Um... she's nice. She's shy. She's actually here. You want to meet her? Hold on one second. Oh, my God! Put on a shirt! Put on a... . I told you that you'd be on camera. I'm sorry, she's European. No, I told you that you'd be on camera. Stop it.", "order": 1953, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 2, "scene": 23, "line": "What if Pam was a lesbian? What if she brought her 'partner' in to work? [to Toby] Would that be crossing the line?", "order": 1954, "speaker": "Michael Scott"},
  {"season": 2, "episode": 2, "scene": 23, "line": "No.", "order": 1955, "speaker": "Toby Flenderson"},
  {"season": 2, "episode": 2, "scene": 23, "line": "What if they made out? In front of everybody?", "order": 1956, "speaker": "Michael Scott"},
  {"season": 2, "episode": 2, "scene": 23, "line": "Well, that would be...", "order": 1957, "speaker": "Toby Flenderson"},
  {"season": 2, "episode": 2, "scene": 23, "line": "At home? And I told everybody everything about it.", "order": 1958, "speaker": "Michael Scott"},
  {"season": 2, "episode": 2, "scene": 23, "line": "Okay, I'm lost.", "order": 1959, "speaker": "Toby Flenderson"},
  {"season": 2, "episode": 2, "scene": 23, "line": "Okay. Well, then let's act it out. Pam, you will be girl A and girl B will be... Okay! We'll use the doll. Pam. Pam?", "order": 1960, "speaker": "Michael Scott"},
  {"season": 2, "episode": 2, "scene": 24, "line": "[Crossing the Line: Rules for the Modern Workplace]", "order": 1961, "speaker": "Video"},
  {"season": 2, "episode": 2, "scene": 24, "line": "I wish Todd Packer was here because he would love this. I wonder if anybody else would like to do this. Hey! Um... we have to watch, uh, Toby's video that he's showing us in order to brainwash us and I was wondering if anybody would like to join in? Going to be fun. Got my great pizza. Whataya say? Jim?", "order": 1962, "speaker": "Michael Scott"},
  {"season": 2, "episode": 2, "scene": 24, "line": "No, thanks. I'm good.", "order": 1963, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 2, "scene": 24, "line": "That's what she said. Pam?", "order": 1964, "speaker": "Michael Scott"},
  {"season": 2, "episode": 2, "scene": 24, "line": "Uh... my mother's coming.", "order": 1965, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 2, "scene": 24, "line": "That's what she sai [clears throat] Nope, but... Okay. Well, suit yourself.", "order": 1966, "speaker": "Michael Scott"},
  {"season": 2, "episode": 2, "scene": 25, "line": "Hey, Toby.", "order": 1967, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 2, "scene": 25, "line": "Hey Dwight.", "order": 1968, "speaker": "Toby Flenderson"},
  {"season": 2, "episode": 2, "scene": 25, "line": "You said that we could come to you if we had any questions.", "order": 1969, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 2, "scene": 25, "line": "Sure.", "order": 1970, "speaker": "Toby Flenderson"},
  {"season": 2, "episode": 2, "scene": 25, "line": "Where is the clitoris? On a website, it said at the crest of the labia. What does that mean? What does the female vagina look like?", "order": 1971, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 2, "scene": 26, "line": "Technically, I am in Human Resources. And Dwight was asking about human anatomy. Um... I'm just sad the public school system failed him so badly.", "order": 1972, "speaker": "Toby Flenderson"},
  {"season": 2, "episode": 2, "scene": 27, "line": "Yeah, maybe when you get really comfortable with each other, you can ask for that.", "order": 1973, "speaker": "Toby Flenderson"},
  {"season": 2, "episode": 2, "scene": 27, "line": "Good. Good. And...", "order": 1974, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 2, "scene": 27, "line": "I should get back to work.", "order": 1975, "speaker": "Toby Flenderson"},
  {"season": 2, "episode": 2, "scene": 27, "line": "Okay.", "order": 1976, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 2, "scene": 28, "line": "In today's fast-paced business climate, it can sometimes be hard to know when a comment or an action crosses the line. Let's take a look at a couple of scenarios and ask ourselves 'where is the line?'", "order": 1977, "speaker": "Man in Video"},
  {"season": 2, "episode": 2, "scene": 28, "line": "[Scenario 1: The Natural Redhead]", "order": 1978, "speaker": "Video"},
  {"season": 2, "episode": 2, "scene": 28, "line": "Natural redhead.", "order": 1979, "speaker": "Roy Anderson"},
  {"season": 2, "episode": 2, "scene": 28, "line": "Hey, Rach.", "order": 1980, "speaker": "Actor"},
  {"season": 2, "episode": 2, "scene": 28, "line": "Hey, Joe. Mike.", "order": 1981, "speaker": "Redheaded Actress"},
  {"season": 2, "episode": 2, "scene": 28, "line": "Hey, settle a bet. Are you a natural redhead?", "order": 1982, "speaker": "Actor"},
  {"season": 2, "episode": 2, "scene": 28, "line": "Oh, Mi... ! Hey, stop the video! Michael, stop it right there! Stop it right there! That's that girl from that thing. [pointing at Redheaded Actress] I banged this girl right here. This is...", "order": 1983, "speaker": "Darryl Philbin"},
  {"season": 2, "episode": 2, "scene": 28, "line": "That's her?", "order": 1984, "speaker": "Roy Anderson"},
  {"season": 2, "episode": 2, "scene": 28, "line": "Yes, this is the one.", "order": 1985, "speaker": "Darryl Philbin"},
  {"season": 2, "episode": 2, "scene": 28, "line": "No!", "order": 1986, "speaker": "Roy Anderson"},
  {"season": 2, "episode": 2, "scene": 28, "line": "You remember? Yes!", "order": 1987, "speaker": "Darryl Philbin"},
  {"season": 2, "episode": 2, "scene": 28, "line": "At the party?", "order": 1988, "speaker": "Roy Anderson"},
  {"season": 2, "episode": 2, "scene": 28, "line": "You banged her?", "order": 1989, "speaker": "Warehouse guy"},
  {"season": 2, "episode": 2, "scene": 28, "line": "Yes! [to video screen] Right here. You are a naughty girl!", "order": 1990, "speaker": "Darryl Philbin"},
  {"season": 2, "episode": 2, "scene": 28, "line": "Whoa, whoa, whoa, whoa... Okay! Hypocrite! She is a hypocrite. That is such a scam! Okay.", "order": 1991, "speaker": "Michael Scott"},
  {"season": 2, "episode": 2, "scene": 29, "line": "[to cell phone] Yes. Yes, I did. Okay. Well, we can talk about that later then. [to Pam] Hi.", "order": 1992, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 2, "scene": 29, "line": "Okay, you are never going to believe this. The girl in the video we're watching that Corporate gave us... Darryl banged her! Aaand is about 90% sure.", "order": 1993, "speaker": "Michael Scott"},
  {"season": 2, "episode": 2, "scene": 30, "line": "Don't ever let this little bitch drive you around town. We got, uh, lost for half an hour.", "order": 1994, "speaker": "Todd Packer"},
  {"season": 2, "episode": 2, "scene": 30, "line": "I don't have any DUI's so I can drive myself, but thanks.", "order": 1995, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 2, "scene": 30, "line": "Where is Michael Snot? Sniffing some dude's thong? Probably.", "order": 1996, "speaker": "Todd Packer"},
  {"season": 2, "episode": 2, "scene": 31, "line": "So you are the lawyer, Mr. O'Malley? I know a lot of lawyer jokes.", "order": 1997, "speaker": "Michael Scott"},
  {"season": 2, "episode": 2, "scene": 31, "line": "I love lawyer jokes.", "order": 1998, "speaker": "Mr. O'Malley"},
  {"season": 2, "episode": 2, "scene": 31, "line": "Well, it's probably because you don't get 'em.", "order": 1999, "speaker": "Michael Scott"},
  {"season": 2, "episode": 2, "scene": 32, "line": "When I said before that I was king of forwards, you got to understand that I don't come up with this stuff. I just forward it along. You wouldn't arrest a guy who's just delivering drugs from one guy to another.", "order": 2000, "speaker": "Michael Scott"},
  {"season": 2, "episode": 2, "scene": 33, "line": "You seem a little bit agitated, Michael. What's the problem?", "order": 2001, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 2, "scene": 33, "line": "The problem is that I am the boss and apparently I can't say anything.", "order": 2002, "speaker": "Michael Scott"},
  {"season": 2, "episode": 2, "scene": 33, "line": "Well, that... that's true in a way. You can't say anything.", "order": 2003, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 2, "scene": 33, "line": "Where's the line? Where's the line, Jan.", "order": 2004, "speaker": "Michael Scott"},
  {"season": 2, "episode": 2, "scene": 33, "line": "Do you need to see the video again, Michael?", "order": 2005, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 2, "scene": 33, "line": "No, I've seen the video.", "order": 2006, "speaker": "Michael Scott"},
  {"season": 2, "episode": 2, "scene": 33, "line": "[to Jan] He talked the whole time.", "order": 2007, "speaker": "Toby Flenderson"},
  {"season": 2, "episode": 2, "scene": 33, "line": "No, I didn't. [to Jan] Huh, what? [everyone looks up at blow-up doll]", "order": 2008, "speaker": "Michael Scott"},
  {"season": 2, "episode": 2, "scene": 34, "line": "Attention, everyone! Hello! Ah, yes! I just want you to know that, uh, this is not my decision, but from here on out... we can no longer be friends. And when we talk about things here we must only discuss work-associated things. And, uh, you can consider this my retirement from comedy. And in the future, if I want to say something funny or witty or do an impression, I will no longer, ever, do any of those things.", "order": 2009, "speaker": "Michael Scott"},
  {"season": 2, "episode": 2, "scene": 34, "line": "Does that include 'That's What She Said'?", "order": 2010, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 2, "scene": 34, "line": "Mmmhmm. Yes.", "order": 2011, "speaker": "Michael Scott"},
  {"season": 2, "episode": 2, "scene": 34, "line": "Wow! That is really hard. You really think you can go all day long? Well, you always left me satisfied and smiling, so...", "order": 2012, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 2, "scene": 34, "line": "THAT'S WHAT SHE SAID!", "order": 2013, "speaker": "Michael Scott"},
  {"season": 2, "episode": 2, "scene": 34, "line": "Michael. MICHAEL!", "order": 2014, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 2, "scene": 34, "line": "[laughing] Come on.", "order": 2015, "speaker": "Michael Scott"},
  {"season": 2, "episode": 2, "scene": 34, "line": "Michael, please.", "order": 2016, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 2, "scene": 34, "line": "There he is.", "order": 2017, "speaker": "Todd Packer"},
  {"season": 2, "episode": 2, "scene": 34, "line": "Mwah! [kisses hand and salutes office]", "order": 2018, "speaker": "Michael Scott"},
  {"season": 2, "episode": 2, "scene": 34, "line": "There he is. Good one.", "order": 2019, "speaker": "Todd Packer"},
  {"season": 2, "episode": 2, "scene": 35, "line": "You would have done the same. You just didn't think of it first.", "order": 2020, "speaker": "Michael Scott"},
  {"season": 2, "episode": 2, "scene": 35, "line": "Mike... Michael. Please. I... I... really.", "order": 2021, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 2, "scene": 35, "line": "It's... That's...", "order": 2022, "speaker": "Michael Scott"},
  {"season": 2, "episode": 2, "scene": 35, "line": "That's not my sense of humor.", "order": 2023, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 2, "scene": 35, "line": "Okay. [to man entering office] Hello. [introduces] Jan. Mr. O'Malley. This is my lawyer, James P. Albiny.", "order": 2024, "speaker": "Michael Scott"},
  {"season": 2, "episode": 2, "scene": 35, "line": "Wha...", "order": 2025, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 2, "scene": 35, "line": "I believe you may recognize his face from the billboards. He specializes in Free Speech issues.", "order": 2026, "speaker": "Michael Scott"},
  {"season": 2, "episode": 2, "scene": 35, "line": "[to camera] And motorcycle head injuries, worker's comp, and diet pill lawsuits.", "order": 2027, "speaker": "Albiny"},
  {"season": 2, "episode": 2, "scene": 35, "line": "This guy does it all.", "order": 2028, "speaker": "Michael Scott"},
  {"season": 2, "episode": 2, "scene": 35, "line": "[to Albiny] 'Scuse me, I'm sorry. [to Michael] Michael. Mr. O'Malley is your lawyer.", "order": 2029, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 2, "scene": 35, "line": "What?", "order": 2030, "speaker": "Michael Scott"},
  {"season": 2, "episode": 2, "scene": 35, "line": "Mr. O'Malley is our Corporate lawyer. We have him on retainer. To protect the company as well as upper level management, such as yourself.", "order": 2031, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 2, "scene": 35, "line": "So I'm not in trouble?", "order": 2032, "speaker": "Michael Scott"},
  {"season": 2, "episode": 2, "scene": 36, "line": "I am so used to being the bad boy. I am so used to fighting Corporate that I forget that I am Corporate. Upper management. They hooked me up with an attorney. To protect me. You can't be too careful about what you say. Mo' money, mo' problems.", "order": 2033, "speaker": "Michael Scott"},
  {"season": 2, "episode": 2, "scene": 37, "line": "Okay. Well, let's get you out of here, James. Um... I think we're under an hour still, so...", "order": 2034, "speaker": "Michael Scott"},
  {"season": 2, "episode": 2, "scene": 37, "line": "Yeah, but I did a lot of paperwork at home before I got here.", "order": 2035, "speaker": "Albiny"},
  {"season": 2, "episode": 2, "scene": 37, "line": "I know. We'll talk about it later. Thanks for coming in.", "order": 2036, "speaker": "Michael Scott"},
  {"season": 2, "episode": 2, "scene": 38, "line": "Um... hello.", "order": 2037, "speaker": "Helene Beesly"},
  {"season": 2, "episode": 2, "scene": 38, "line": "[ecstatic] Oh my god!", "order": 2038, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 2, "scene": 38, "line": "Finally made it!", "order": 2039, "speaker": "Helene Beesly"},
  {"season": 2, "episode": 2, "scene": 38, "line": "Hello!", "order": 2040, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 2, "scene": 39, "line": "I love my Mom. Okay. That's probably really the most obvious statement ever.", "order": 2041, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 2, "scene": 40, "line": "This is all yours?", "order": 2042, "speaker": "Helene Beesly"},
  {"season": 2, "episode": 2, "scene": 40, "line": "Yeah. I'm in charge of this whole area.", "order": 2043, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 2, "scene": 40, "line": "Oh, my goodness. That's great.", "order": 2044, "speaker": "Helene Beesly"},
  {"season": 2, "episode": 2, "scene": 41, "line": "So a guy goes home, tells his wife, 'Honey. Pack your bags. I just won the lottery.' She goes, 'Oh my god! That's incredible! Where are we going?' He goes, 'I don't know where you're going, just be out of here by five!' [men laugh] Boom!", "order": 2045, "speaker": "Todd Packer"},
  {"season": 2, "episode": 2, "scene": 42, "line": "This is where I used to keep my computer.", "order": 2046, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 2, "scene": 42, "line": "Oh, right! I remember...", "order": 2047, "speaker": "Helene Beesly"},
  {"season": 2, "episode": 2, "scene": 42, "line": "But then I moved it.", "order": 2048, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 2, "scene": 42, "line": "with the picture.", "order": 2049, "speaker": "Helene Beesly"},
  {"season": 2, "episode": 2, "scene": 42, "line": "Yeah, yeah, but I uh... I switched stuff around because I actually needed like more room for organization. So...", "order": 2050, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 2, "scene": 42, "line": "Sure.", "order": 2051, "speaker": "Helene Beesly"},
  {"season": 2, "episode": 2, "scene": 42, "line": "So this is like, um, an organization station...", "order": 2052, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 2, "scene": 42, "line": "[to Roy] Oooooh!", "order": 2053, "speaker": "Helene Beesly"},
  {"season": 2, "episode": 2, "scene": 42, "line": "Hey!", "order": 2054, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 2, "scene": 42, "line": "Well, there he is!", "order": 2055, "speaker": "Helene Beesly"},
  {"season": 2, "episode": 2, "scene": 42, "line": "How are ya?", "order": 2056, "speaker": "Roy Anderson"},
  {"season": 2, "episode": 2, "scene": 42, "line": "Hi, handsome!", "order": 2057, "speaker": "Helene Beesly"},
  {"season": 2, "episode": 2, "scene": 42, "line": "You look great!", "order": 2058, "speaker": "Roy Anderson"},
  {"season": 2, "episode": 2, "scene": 42, "line": "Oh, thank you! So! We ready for dinner?", "order": 2059, "speaker": "Helene Beesly"},
  {"season": 2, "episode": 2, "scene": 42, "line": "Well, you know... actually, I kind of need to stall a bit. But, it's okay, because I am very used to killing time.", "order": 2060, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 2, "scene": 42, "line": "Oh, I don't believe that.", "order": 2061, "speaker": "Helene Beesly"},
  {"season": 2, "episode": 2, "scene": 42, "line": "Okay, I'm going to go wait in the parking lot. And what kind of tunes you want for the ride? Little, uh, classical? Or oldies?", "order": 2062, "speaker": "Roy Anderson"},
  {"season": 2, "episode": 2, "scene": 42, "line": "Oh, anything is fine.", "order": 2063, "speaker": "Helene Beesly"},
  {"season": 2, "episode": 2, "scene": 42, "line": "All right, I'll see ya.", "order": 2064, "speaker": "Roy Anderson"},
  {"season": 2, "episode": 2, "scene": 42, "line": "So which one is Jim?", "order": 2065, "speaker": "Helene Beesly"},
  {"season": 2, "episode": 2, "scene": 42, "line": "Mom!", "order": 2066, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 2, "scene": 42, "line": "I just wanted to know.", "order": 2067, "speaker": "Helene Beesly"},
  {"season": 2, "episode": 2, "scene": 42, "line": "No.", "order": 2068, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 2, "scene": 42, "line": "All right. Okay.", "order": 2069, "speaker": "Helene Beesly"},
  {"season": 2, "episode": 2, "scene": 42, "line": "Ten minutes.", "order": 2070, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 2, "scene": 42, "line": "Okay.", "order": 2071, "speaker": "Helene Beesly"},
  {"season": 2, "episode": 2, "scene": 42, "line": "Then we can go to dinner.", "order": 2072, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 2, "scene": 42, "line": "I'll make myself busy.", "order": 2073, "speaker": "Helene Beesly"},
  {"season": 2, "episode": 2, "scene": 43, "line": "There's this guy. He's at a Nymphomaniac Convention. And he is psyched 'cause all these women are smokin' hot perfect 10's, except for this one chick who looks a lot like, uh... [points at Phyllis]", "order": 2074, "speaker": "Todd Packer"},
  {"season": 2, "episode": 2, "scene": 43, "line": "Phyllis?", "order": 2075, "speaker": "Kevin Malone"},
  {"season": 2, "episode": 2, "scene": 43, "line": "No. No, no, no. That crosses the line.", "order": 2076, "speaker": "Michael Scott"},
  {"season": 2, "episode": 2, "scene": 43, "line": "Ex-squeeze me?", "order": 2077, "speaker": "Todd Packer"},
  {"season": 2, "episode": 2, "scene": 43, "line": "Not you. Kevin. Just unwarranted. Hostile work environment, Kevin.", "order": 2078, "speaker": "Michael Scott"},
  {"season": 2, "episode": 2, "scene": 43, "line": "Packer said it.", "order": 2079, "speaker": "Kevin Malone"},
  {"season": 2, "episode": 2, "scene": 43, "line": "No. You said it. He pointed. A point is not a say. Look. Kevin, we are a family here and Phyllis is a valued member of that family. Like a grandmother.", "order": 2080, "speaker": "Michael Scott"},
  {"season": 2, "episode": 2, "scene": 43, "line": "I'm the same age as you, Michael.", "order": 2081, "speaker": "Phyllis Lapin"},
  {"season": 2, "episode": 2, "scene": 43, "line": "I don't know about that.", "order": 2082, "speaker": "Michael Scott"},
  {"season": 2, "episode": 2, "scene": 43, "line": "We're in the same High School class.", "order": 2083, "speaker": "Phyllis Lapin"},
  {"season": 2, "episode": 2, "scene": 43, "line": "Well, I have a late birthday and usually September's a cut-off point. [to Kevin] You know what? You just crossed the line. Okay? There's a line and you went over it. And you must be punished. So go to your corner.", "order": 2084, "speaker": "Michael Scott"},
  {"season": 2, "episode": 2, "scene": 43, "line": "You mean where my desk is?", "order": 2085, "speaker": "Kevin Malone"},
  {"season": 2, "episode": 2, "scene": 43, "line": "Yes, your corner. Go.", "order": 2086, "speaker": "Michael Scott"},
  {"season": 2, "episode": 2, "scene": 43, "line": "Okay. I have a lot of work to do anyway.", "order": 2087, "speaker": "Kevin Malone"},
  {"season": 2, "episode": 2, "scene": 43, "line": "Mmmhmmm.", "order": 2088, "speaker": "Michael Scott"},
  {"season": 2, "episode": 2, "scene": 43, "line": "Oh my. They really got to you, didn't they?", "order": 2089, "speaker": "Todd Packer"},
  {"season": 2, "episode": 2, "scene": 43, "line": "They didn't get to me. I got to them. I am still the same old Michael Scott. New and improved. You know what? I love Phyllis. You know what else? I think she is gorgeous. I think she is incredibly, incredibly attractive person. [to Phyllis] C'mere, c'mere, c'mon! Come on! Come on.", "order": 2090, "speaker": "Michael Scott"},
  {"season": 2, "episode": 2, "scene": 43, "line": "Michael! Come on!", "order": 2091, "speaker": "Phyllis Lapin"},
  {"season": 2, "episode": 2, "scene": 43, "line": "Oooh!", "order": 2092, "speaker": "Michael Scott"},
  {"season": 2, "episode": 2, "scene": 43, "line": "You don't have to worry. I'm not going to...", "order": 2093, "speaker": "Phyllis Lapin"},
  {"season": 2, "episode": 2, "scene": 43, "line": "I'm not worried.", "order": 2094, "speaker": "Michael Scott"},
  {"season": 2, "episode": 2, "scene": 43, "line": "...report you to HR.", "order": 2095, "speaker": "Phyllis Lapin"},
  {"season": 2, "episode": 2, "scene": 43, "line": "You know what? The only thing I'm worried about... is getting a boner. Good work today, everybody.", "order": 2096, "speaker": "Michael Scott"},
  {"season": 2, "episode": 2, "scene": 44, "line": "Times have changed a little. And even though we're still a family here at Dunder-Mifflin, families grow. And at some point, the daddy can't take a bath with the kids anymore. I am Upper Management. And it would be inappropriate for me to take a bath with Pam. As much as I might want to.", "order": 2097, "speaker": "Michael Scott"},
  {"season": 2, "episode": 2, "scene": 45, "line": "He said what?", "order": 2098, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 3, "scene": 1, "line": "I'm an early bird, and I'm a night owl. So I'm wise, and I have worms. Oh, breakfast.", "order": 2099, "speaker": "Michael Scott"},
  {"season": 2, "episode": 3, "scene": 1, "line": "I got your sausage, egg and cheese biscuit.", "order": 2100, "speaker": "Ryan Howard"},
  {"season": 2, "episode": 3, "scene": 1, "line": "Yummy, yummy. Thank you, Ryan.", "order": 2101, "speaker": "Michael Scott"},
  {"season": 2, "episode": 3, "scene": 1, "line": "What was the thing, ah, you needed me to come in early for?", "order": 2102, "speaker": "Ryan Howard"},
  {"season": 2, "episode": 3, "scene": 1, "line": "Um. The sausage, egg, and cheese biscuit. But thank you. And why don't you take a couple hours. The office is yours. 'Home Alone,' 'Risky Business.' Take your pants off, run around. Whatever you gotta do.", "order": 2103, "speaker": "Michael Scott"},
  {"season": 2, "episode": 3, "scene": 1, "line": "I'm just going to take a nap in my car until work starts.", "order": 2104, "speaker": "Ryan Howard"},
  {"season": 2, "episode": 3, "scene": 1, "line": "Ok. [Removes biscuit, leaving only sausage, egg and cheese.] Healthier. Gotta watch those carbs.", "order": 2105, "speaker": "Michael Scott"},
  {"season": 2, "episode": 3, "scene": 2, "line": "Today, I, Michael Scott, am becoming a homeowner. Investing in real estate.", "order": 2106, "speaker": "Michael Scott"},
  {"season": 2, "episode": 3, "scene": 2, "line": "Diversifying. Smart.", "order": 2107, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 3, "scene": 2, "line": "Yes it is. Yes it is. It is very important to own property. Back in olden days, they would not even let you vote unless you owned property and they'd throw you in the stocks and humiliate you.", "order": 2108, "speaker": "Michael Scott"},
  {"season": 2, "episode": 3, "scene": 2, "line": "And it worked. They should bring the stocks back. People'd obey the law, there'd be less troublemakers.", "order": 2109, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 3, "scene": 2, "line": "Maybe.", "order": 2110, "speaker": "Michael Scott"},
  {"season": 2, "episode": 3, "scene": 3, "line": "[looks bored. Taps finger on desk. Head falls to desk]", "order": 2111, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 3, "scene": 3, "line": "[laughs]", "order": 2112, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 3, "scene": 4, "line": "Every so often, Jim dies of boredom. I think today it was the expense reports that did him in. And our deal is that, it's up to me to revive him.", "order": 2113, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 3, "scene": 5, "line": "You see Dwight's coffee mug?", "order": 2114, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 3, "scene": 5, "line": "Mm-hmm.", "order": 2115, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 3, "scene": 5, "line": "Sometimes when he's not here, I try to throw stuff in it.", "order": 2116, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 3, "scene": 5, "line": "No way. Let's do this [crumples post it and throws into mug. Misses.] Oh.", "order": 2117, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 3, "scene": 5, "line": "Here.", "order": 2118, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 3, "scene": 5, "line": "Wind.", "order": 2119, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 3, "scene": 5, "line": "Try paperclips. Oh wait. This message. For Dwight.", "order": 2120, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 3, "scene": 5, "line": "Perfect. [misses]", "order": 2121, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 3, "scene": 5, "line": "Oh.", "order": 2122, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 3, "scene": 5, "line": "Oh.", "order": 2123, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 3, "scene": 6, "line": "You should go.", "order": 2124, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 3, "scene": 6, "line": "Yes. Yes. Final walkthrough.", "order": 2125, "speaker": "Michael Scott"},
  {"season": 2, "episode": 3, "scene": 6, "line": "Uh huh.", "order": 2126, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 3, "scene": 6, "line": "Sign the papers at the condo.", "order": 2127, "speaker": "Michael Scott"},
  {"season": 2, "episode": 3, "scene": 6, "line": "You have your lawyer there?", "order": 2128, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 3, "scene": 6, "line": "Uh, I don't need one.", "order": 2129, "speaker": "Michael Scott"},
  {"season": 2, "episode": 3, "scene": 6, "line": "Can I be your representative?", "order": 2130, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 3, "scene": 6, "line": "I don't need a representative.", "order": 2131, "speaker": "Michael Scott"},
  {"season": 2, "episode": 3, "scene": 6, "line": "I think I should be there.", "order": 2132, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 3, "scene": 6, "line": "No, No.", "order": 2133, "speaker": "Michael Scott"},
  {"season": 2, "episode": 3, "scene": 6, "line": "I'm good. I can make sure things are up to code.", "order": 2134, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 3, "scene": 6, "line": "No. Dwight. I'm fine.", "order": 2135, "speaker": "Michael Scott"},
  {"season": 2, "episode": 3, "scene": 6, "line": "Please, I'm always the guy you rely on at work.", "order": 2136, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 3, "scene": 6, "line": "Well, this isn't about work. This is closing on a condo, it's completely personal.", "order": 2137, "speaker": "Michael Scott"},
  {"season": 2, "episode": 3, "scene": 6, "line": "So you're taking a personal day?", "order": 2138, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 3, "scene": 6, "line": "Except that, this is about my living arrangement, and as boss, I need to have a living arrangement in order to do work.", "order": 2139, "speaker": "Michael Scott"},
  {"season": 2, "episode": 3, "scene": 6, "line": "Please, I'll make you proud.", "order": 2140, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 3, "scene": 6, "line": "Ok. Fine. Yes, you can come.", "order": 2141, "speaker": "Michael Scott"},
  {"season": 2, "episode": 3, "scene": 6, "line": "Yes! As your representative?", "order": 2142, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 3, "scene": 6, "line": "As my associate.", "order": 2143, "speaker": "Michael Scott"},
  {"season": 2, "episode": 3, "scene": 6, "line": "Same thing.", "order": 2144, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 3, "scene": 6, "line": "No it is not.", "order": 2145, "speaker": "Michael Scott"},
  {"season": 2, "episode": 3, "scene": 7, "line": "I have been Michael's #2 guy for about 5 years. And we make a great team. We're like one of those classic famous teams. He's like Mozart, and I'm like Mozart's friend. No. I'm like Butch Cassidy, and Michael is like Mozart. You try and hurt Mozart; you're going to get a bullet in your head courtesy of Butch Cassidy.", "order": 2146, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 3, "scene": 8, "line": "Oh, most honorable Pamera. Not offensive, because that's the way they talk in movies.", "order": 2147, "speaker": "Michael Scott"},
  {"season": 2, "episode": 3, "scene": 8, "line": "You headed out?", "order": 2148, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 3, "scene": 8, "line": "We are. Dwight and I are going to the big thing. So why don't you have everybody work on their expense reports and I'd like them in by the end of the day.", "order": 2149, "speaker": "Michael Scott"},
  {"season": 2, "episode": 3, "scene": 8, "line": "Ok.", "order": 2150, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 3, "scene": 8, "line": "Very good.", "order": 2151, "speaker": "Michael Scott"},
  {"season": 2, "episode": 3, "scene": 8, "line": "Have a great time.", "order": 2152, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 3, "scene": 8, "line": "We will. Um, did you do the thing I asked you to do about the magazines?", "order": 2153, "speaker": "Michael Scott"},
  {"season": 2, "episode": 3, "scene": 8, "line": "Yeah, I changed them to your new address.", "order": 2154, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 3, "scene": 8, "line": "Good. The Small Business Man?", "order": 2155, "speaker": "Michael Scott"},
  {"season": 2, "episode": 3, "scene": 8, "line": "Yup.", "order": 2156, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 3, "scene": 8, "line": "Maxim? American Way? Cracked?", "order": 2157, "speaker": "Michael Scott"},
  {"season": 2, "episode": 3, "scene": 8, "line": "Yes, I changed your Cracked magazine subscription.", "order": 2158, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 3, "scene": 8, "line": "How about, uh, Fine Arts? Aficionado Monthly?", "order": 2159, "speaker": "Michael Scott"},
  {"season": 2, "episode": 3, "scene": 8, "line": "[shakes head]", "order": 2160, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 3, "scene": 8, "line": "NO, well can you get on that, because I don't just read Cracked. Thank you.", "order": 2161, "speaker": "Michael Scott"},
  {"season": 2, "episode": 3, "scene": 8, "line": "Yeah.", "order": 2162, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 3, "scene": 8, "line": "Ok. See you soon.", "order": 2163, "speaker": "Michael Scott"},
  {"season": 2, "episode": 3, "scene": 9, "line": "What kind of shocks you got on this baby?", "order": 2164, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 3, "scene": 9, "line": "I don't know, regular. Normal ones. Nothing fancy. Not my style. What are you doing?", "order": 2165, "speaker": "Michael Scott"},
  {"season": 2, "episode": 3, "scene": 9, "line": "[tries to open sun roof] I want to put the top down.", "order": 2166, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 3, "scene": 9, "line": "What? No, Dwight. It's fifty degrees outside. Don't... please...", "order": 2167, "speaker": "Michael Scott"},
  {"season": 2, "episode": 3, "scene": 9, "line": "But then no one can see us.", "order": 2168, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 3, "scene": 9, "line": "I... Just... Would you put it up? [roof opens] Ok. Fine. Just leave it down. Whiner.", "order": 2169, "speaker": "Michael Scott"},
  {"season": 2, "episode": 3, "scene": 9, "line": "Check it out. [points at sunglasses] Terminator.", "order": 2170, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 3, "scene": 9, "line": "I do not understand what you spend your money on.", "order": 2171, "speaker": "Michael Scott"},
  {"season": 2, "episode": 3, "scene": 10, "line": "[paper football lands on desk] Ooh.", "order": 2172, "speaker": "Kevin Malone"},
  {"season": 2, "episode": 3, "scene": 10, "line": "Hey, Oscar, on these new expense reports, do we really have to go back to last quarter?", "order": 2173, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 3, "scene": 10, "line": "Yeah. It's a terrible system, I know.", "order": 2174, "speaker": "Oscar Martinez"},
  {"season": 2, "episode": 3, "scene": 10, "line": "[points at paper on desk] What does 2005 season mean?", "order": 2175, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 3, "scene": 10, "line": "Eh.", "order": 2176, "speaker": "Oscar Martinez"},
  {"season": 2, "episode": 3, "scene": 10, "line": "Wait a minute, what is this?", "order": 2177, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 3, "scene": 10, "line": "It's a scoreboard.", "order": 2178, "speaker": "Oscar Martinez"},
  {"season": 2, "episode": 3, "scene": 10, "line": "What?", "order": 2179, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 3, "scene": 10, "line": "Kevin and I play this paper football game when Michael's out.", "order": 2180, "speaker": "Oscar Martinez"},
  {"season": 2, "episode": 3, "scene": 10, "line": "Really?", "order": 2181, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 3, "scene": 10, "line": "Yeah.", "order": 2182, "speaker": "Oscar Martinez"},
  {"season": 2, "episode": 3, "scene": 10, "line": "Or when we're bored.", "order": 2183, "speaker": "Kevin Malone"},
  {"season": 2, "episode": 3, "scene": 10, "line": "Oh my God! Wait, this goes back two years.", "order": 2184, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 3, "scene": 10, "line": "We're bored a lot.", "order": 2185, "speaker": "Kevin Malone"},
  {"season": 2, "episode": 3, "scene": 11, "line": "[flicks football onto Kevin's desk] OH!", "order": 2186, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 3, "scene": 11, "line": "Oh!", "order": 2187, "speaker": "Kevin Malone"},
  {"season": 2, "episode": 3, "scene": 11, "line": "Sweet!", "order": 2188, "speaker": "Oscar Martinez"},
  {"season": 2, "episode": 3, "scene": 11, "line": "Yes! So close. I really love the paper triangle flicking and hitting things game. Yeah.", "order": 2189, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 3, "scene": 11, "line": "We call it Hate Ball.", "order": 2190, "speaker": "Kevin Malone"},
  {"season": 2, "episode": 3, "scene": 11, "line": "Why?", "order": 2191, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 3, "scene": 11, "line": "Because of how much Angela hates it.", "order": 2192, "speaker": "Kevin Malone"},
  {"season": 2, "episode": 3, "scene": 11, "line": "Hey, do you guys have any other games?", "order": 2193, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 3, "scene": 11, "line": "Sometimes we play 'Who can put the most M&M's in their mouth?'", "order": 2194, "speaker": "Kevin Malone"},
  {"season": 2, "episode": 3, "scene": 11, "line": "You", "order": 2195, "speaker": "Angela Martin"},
  {"season": 2, "episode": 3, "scene": 11, "line": "You should ask Toby to teach you Dunderball.", "order": 2196, "speaker": "Oscar Martinez"},
  {"season": 2, "episode": 3, "scene": 12, "line": "Home, sweet home.", "order": 2197, "speaker": "Michael Scott"},
  {"season": 2, "episode": 3, "scene": 12, "line": "Which one's yours?", "order": 2198, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 3, "scene": 12, "line": "Right there. My sanctuary. My party pad. Someday I can just see my grandkids learning how to walk out here. Hang a swing from this tree. Push them back... wait... [turns around] no, it's this one, right here. Home, sweet home.", "order": 2199, "speaker": "Michael Scott"},
  {"season": 2, "episode": 3, "scene": 13, "line": "[bounces ball off wall with Toby] So that's what this sound is all day.", "order": 2200, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 3, "scene": 14, "line": "Michael, this is Bill. He's the head of the condo association.", "order": 2201, "speaker": "Carol Stills"},
  {"season": 2, "episode": 3, "scene": 14, "line": "Oh, how are you? Nice to meet you, Bill. Bill. Mr. Bill. OHHH NOOO. MR. BILL. OHHH! SNL? When they pull him apart? He'd always get rolled over by something.", "order": 2202, "speaker": "Michael Scott"},
  {"season": 2, "episode": 3, "scene": 14, "line": "Nice to meet you.", "order": 2203, "speaker": "Bill"},
  {"season": 2, "episode": 3, "scene": 14, "line": "Nice to meet you too.", "order": 2204, "speaker": "Michael Scott"},
  {"season": 2, "episode": 3, "scene": 14, "line": "This is smaller than your old place.", "order": 2205, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 3, "scene": 14, "line": "Yeah, small. I'm buying it and I'm not renting it. So, it's still an upgrade. He doesn't know anything about property ownership. Kind of an idiot. Um.", "order": 2206, "speaker": "Michael Scott"},
  {"season": 2, "episode": 3, "scene": 15, "line": "Actually, I do own property. My grandfather left me a 60 acre working beet farm. I run it with my cousin, Mose. We sell beets to local stores and restaurants. It's a nice little farm. Sometimes teenagers use it for sex.", "order": 2207, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 3, "scene": 16, "line": "Are we ready to sign some papers?", "order": 2208, "speaker": "Carol Stills"},
  {"season": 2, "episode": 3, "scene": 16, "line": "Actually, no. We have a couple of questions, about the neighborhood.", "order": 2209, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 3, "scene": 16, "line": "It's very safe. It's very clean. Also, it's very accepting of all lifestyles.", "order": 2210, "speaker": "Bill"},
  {"season": 2, "episode": 3, "scene": 16, "line": "It's a very gay-friendly neighborhood.", "order": 2211, "speaker": "Carol Stills"},
  {"season": 2, "episode": 3, "scene": 16, "line": "Oh. Good. That's good. It's good to be accommodating of that.", "order": 2212, "speaker": "Michael Scott"},
  {"season": 2, "episode": 3, "scene": 16, "line": "Let's go check out the master bedroom.", "order": 2213, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 3, "scene": 17, "line": "Stanley. Just played Dunder Ball with Toby. What about you, you got any games?", "order": 2214, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 3, "scene": 17, "line": "Yeah, I got a game. It's called 'work hard so my kids can go to college.'", "order": 2215, "speaker": "Stanley Hudson"},
  {"season": 2, "episode": 3, "scene": 17, "line": "Fair enough.", "order": 2216, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 3, "scene": 18, "line": "This, my friends, is the master bedroom. Check out the cathedral ceilings. Those are like seventeen feet high. We have cable readiness. [points at wall] Right there. I am going to totally pimp this place out. I am going to put a surround sound system. I am going to put a plasma screen right against this wall.", "order": 2217, "speaker": "Michael Scott"},
  {"season": 2, "episode": 3, "scene": 18, "line": "Oh. Terrible idea.", "order": 2218, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 3, "scene": 18, "line": "I'm putting my bed right over here.", "order": 2219, "speaker": "Michael Scott"},
  {"season": 2, "episode": 3, "scene": 18, "line": "No, no, no, no, no. This is a shared wall. Neighbor throws his wife into the wall, plasma screen hits the floor. Totally smashed.", "order": 2220, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 3, "scene": 18, "line": "Well, then I will get a warrantee.", "order": 2221, "speaker": "Michael Scott"},
  {"season": 2, "episode": 3, "scene": 18, "line": "Warrantees don't cover it, plus they're a rip-off.", "order": 2222, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 3, "scene": 18, "line": "Well then I won't get a warrantee.", "order": 2223, "speaker": "Michael Scott"},
  {"season": 2, "episode": 3, "scene": 18, "line": "Shh Shh.", "order": 2224, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 3, "scene": 18, "line": "So that's the problem, is solved. What?", "order": 2225, "speaker": "Michael Scott"},
  {"season": 2, "episode": 3, "scene": 18, "line": "Listen. [puts ear to wall] Can you hear that? Oh man. These babies are thin.", "order": 2226, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 3, "scene": 19, "line": "[sings Olympic theme song] This scented candle ...andle ...andle. Which I found in the men's bathroom ...room ...room. Represents the eternal burning of competition. Or something.", "order": 2227, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 3, "scene": 19, "line": "It smells like cookies.", "order": 2228, "speaker": "Kevin Malone"},
  {"season": 2, "episode": 3, "scene": 19, "line": "Yes it does. Yes it does my friend. Ok, we will be competing for gold, silver and bronze yogurt lids.", "order": 2229, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 3, "scene": 19, "line": "Now the bronze are really blue, and they're also the back side of the gold, so no flipping. K? Honor system.", "order": 2230, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 3, "scene": 20, "line": "I do play games. I sing and I dangle things in front of my cats. I play lots of games. Just not at work.", "order": 2231, "speaker": "Angela Martin"},
  {"season": 2, "episode": 3, "scene": 21, "line": "Let the games begin. [sings Olympic theme]", "order": 2232, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 3, "scene": 22, "line": "And then, I just need you to sign here at this arrow.", "order": 2233, "speaker": "Carol Stills"},
  {"season": 2, "episode": 3, "scene": 22, "line": "What kind of mortgage did you get?", "order": 2234, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 3, "scene": 22, "line": "Uh... Ten year.", "order": 2235, "speaker": "Michael Scott"},
  {"season": 2, "episode": 3, "scene": 22, "line": "Well, ten over thirty, so thirty year total.", "order": 2236, "speaker": "Carol Stills"},
  {"season": 2, "episode": 3, "scene": 22, "line": "What? Wha? You said ten.", "order": 2237, "speaker": "Michael Scott"},
  {"season": 2, "episode": 3, "scene": 22, "line": "Ten year fixed, over thirty. Thirty year total.", "order": 2238, "speaker": "Carol Stills"},
  {"season": 2, "episode": 3, "scene": 22, "line": "Ho, thirty years.", "order": 2239, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 3, "scene": 22, "line": "Ok, ok, ok.", "order": 2240, "speaker": "Michael Scott"},
  {"season": 2, "episode": 3, "scene": 22, "line": "Wow, you'll be paying this off in your mid-seventies.", "order": 2241, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 3, "scene": 22, "line": "Alright.", "order": 2242, "speaker": "Michael Scott"},
  {"season": 2, "episode": 3, "scene": 22, "line": "Forget about retiring when you're 65. Hey, I've got an idea. You know that extra bedroom? If the whole girlfriend thing never happens, that's where the nurse can live.", "order": 2243, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 3, "scene": 22, "line": "Ok. Alright. Oh boy.", "order": 2244, "speaker": "Michael Scott"},
  {"season": 2, "episode": 3, "scene": 22, "line": "Well, this is it.", "order": 2245, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 3, "scene": 22, "line": "Whenever you're ready.", "order": 2246, "speaker": "Carol Stills"},
  {"season": 2, "episode": 3, "scene": 22, "line": "Um. Oh. [moves stove burner] Oh, ok. Is that suppose to come off?", "order": 2247, "speaker": "Michael Scott"},
  {"season": 2, "episode": 3, "scene": 22, "line": "Actually yes.", "order": 2248, "speaker": "Carol Stills"},
  {"season": 2, "episode": 3, "scene": 22, "line": "Hey, look! Cool. Carpenter ants.", "order": 2249, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 3, "scene": 22, "line": "Um. I'm going to take a little breather for a second. Excuse me.", "order": 2250, "speaker": "Michael Scott"},
  {"season": 2, "episode": 3, "scene": 22, "line": "We'll be here waiting for you.", "order": 2251, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 3, "scene": 22, "line": "Oh, man.", "order": 2252, "speaker": "Michael Scott"},
  {"season": 2, "episode": 3, "scene": 23, "line": "A thirty year mortgage at Michael's age essentially means that he's buying a coffin. If I were buying my coffin, I would get one with thicker walls so you couldn't hear the other dead people.", "order": 2253, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 3, "scene": 24, "line": "Whenever you're ready, Michael.", "order": 2254, "speaker": "Carol Stills"},
  {"season": 2, "episode": 3, "scene": 24, "line": "Uh. [breathes deeply, head at knees]", "order": 2255, "speaker": "Michael Scott"},
  {"season": 2, "episode": 3, "scene": 25, "line": "You have what is the national sport of Icelandic paper companies. And I'm blanking on the name, can you help me out Pam?", "order": 2256, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 3, "scene": 25, "line": "Jim, they refer to it as Flonkerton.", "order": 2257, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 3, "scene": 25, "line": "Hum.", "order": 2258, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 3, "scene": 25, "line": "In English, box of paper snowshoe racing.", "order": 2259, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 3, "scene": 25, "line": "Fair enough, but I like Flonkerton.", "order": 2260, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 3, "scene": 26, "line": "The thing about Jim, is when he's excited about something, like the Office Olympics, he gets really into it and he does a really great job. But the problem with Jim is that he works here, so that hardly ever happens.", "order": 2261, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 3, "scene": 27, "line": "So, who will be challenging Kevin in Flonkerton? Anyone?", "order": 2262, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 3, "scene": 27, "line": "I'll do it.", "order": 2263, "speaker": "Phyllis Lapin"},
  {"season": 2, "episode": 3, "scene": 27, "line": "Yes! Phyllis! [claps] Phyllis, just put your foot right through here [lifts strap on box of paper]. Right through the flonk.", "order": 2264, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 3, "scene": 28, "line": "The ceilings are lower than they were last week. That, I don't... I don't...", "order": 2265, "speaker": "Michael Scott"},
  {"season": 2, "episode": 3, "scene": 28, "line": "What?", "order": 2266, "speaker": "Carol Stills"},
  {"season": 2, "episode": 3, "scene": 28, "line": "...know if you showed me this same unit or not.", "order": 2267, "speaker": "Michael Scott"},
  {"season": 2, "episode": 3, "scene": 28, "line": "Michael, this is the unit you saw and...", "order": 2268, "speaker": "Carol Stills"},
  {"season": 2, "episode": 3, "scene": 28, "line": "Where are all the hot people? I was told that there would be all these attractive singles.", "order": 2269, "speaker": "Michael Scott"},
  {"season": 2, "episode": 3, "scene": 28, "line": "Who told you that?", "order": 2270, "speaker": "Carol Stills"},
  {"season": 2, "episode": 3, "scene": 28, "line": "As far as I can tell, I'm the best-looking person here.", "order": 2271, "speaker": "Michael Scott"},
  {"season": 2, "episode": 3, "scene": 29, "line": "There's a basic principle in real estate, that you should never be the best-looking person in the development. It's just sorta common sense, because if you are, then you've no place to go but down.", "order": 2272, "speaker": "Michael Scott"},
  {"season": 2, "episode": 3, "scene": 30, "line": "Is this a financial thing? If it's a financial thing, what some people do is they rent out the third bedroom.", "order": 2273, "speaker": "Carol Stills"},
  {"season": 2, "episode": 3, "scene": 30, "line": "No, no, no.", "order": 2274, "speaker": "Michael Scott"},
  {"season": 2, "episode": 3, "scene": 30, "line": "That's some extra income for you.", "order": 2275, "speaker": "Carol Stills"},
  {"season": 2, "episode": 3, "scene": 30, "line": "I am not going to rent the third bedroom. I want a price reduction or I am a-walkin.", "order": 2276, "speaker": "Michael Scott"},
  {"season": 2, "episode": 3, "scene": 30, "line": "You will lose $7,000 if you walk away right now.", "order": 2277, "speaker": "Carol Stills"},
  {"season": 2, "episode": 3, "scene": 30, "line": "Ehhhh....", "order": 2278, "speaker": "Michael Scott"},
  {"season": 2, "episode": 3, "scene": 31, "line": "I made the right decision. I'm glad I signed. I'm a homeowner. Right? Good to be a homeowner. Diversifying. This is good. This is fun. We're having fun.", "order": 2279, "speaker": "Michael Scott"},
  {"season": 2, "episode": 3, "scene": 31, "line": "Totally having fun. Can you imagine those poor saps stuck at the office today? [laughs]", "order": 2280, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 3, "scene": 32, "line": "Here we go. Here we go.", "order": 2281, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 3, "scene": 32, "line": "Go! Go! Go!", "order": 2282, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 3, "scene": 32, "line": "Pair of shoes!", "order": 2283, "speaker": "Oscar Martinez"},
  {"season": 2, "episode": 3, "scene": 32, "line": "Dig deep, dig deep! OHHHhhh! It's Phyllis!", "order": 2284, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 3, "scene": 32, "line": "It's Phyllis!", "order": 2285, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 3, "scene": 32, "line": "Phyllis by a nose. Gold medal in Flernenton.", "order": 2286, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 3, "scene": 32, "line": "Flonkerton.", "order": 2287, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 3, "scene": 32, "line": "Thank you, delegate from Iceland.", "order": 2288, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 3, "scene": 32, "line": "Wow!", "order": 2289, "speaker": "Meredith Palmer"},
  {"season": 2, "episode": 3, "scene": 33, "line": "[empties bowl of M&M's into his mouth]", "order": 2290, "speaker": "Kevin Malone"},
  {"season": 2, "episode": 3, "scene": 33, "line": "Wow! Ok. No one else should even try! Gold medals! Give him medals. Wow.", "order": 2291, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 3, "scene": 34, "line": "There's something else Dwight wanted to talk to you about. I have a surprise for you, for helping me out today.", "order": 2292, "speaker": "Michael Scott"},
  {"season": 2, "episode": 3, "scene": 34, "line": "You didn't have to...", "order": 2293, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 3, "scene": 34, "line": "No, no. I insist. I insist. Because you've really done some great work. Great work. And that is why, I am going to let you move into my third bedroom and pay me rent.", "order": 2294, "speaker": "Michael Scott"},
  {"season": 2, "episode": 3, "scene": 35, "line": "Why did I do it? Because I believe in rewarding people for their efforts. Ah. I rewarded Dwight with the room, and he is rewarding me back, ah, with $500 plus utilities.", "order": 2295, "speaker": "Michael Scott"},
  {"season": 2, "episode": 3, "scene": 36, "line": "I don't even know what to say.", "order": 2296, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 3, "scene": 36, "line": "I'm thinking, lock into a four year commitment, we'll go month to month after that. Or, until I start dating, have a girlfriend, then you're, you know, you're gone.", "order": 2297, "speaker": "Michael Scott"},
  {"season": 2, "episode": 3, "scene": 36, "line": "Question. Where can I put my terrarium?", "order": 2298, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 3, "scene": 36, "line": "What the hell is a terrarium?", "order": 2299, "speaker": "Michael Scott"},
  {"season": 2, "episode": 3, "scene": 36, "line": "It's a fish tank for snakes and lizards.", "order": 2300, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 3, "scene": 36, "line": "Oh, so an aquarium. Ah, that will not come into this place.", "order": 2301, "speaker": "Michael Scott"},
  {"season": 2, "episode": 3, "scene": 36, "line": "Question. My grandparents left me a large number of armoires.", "order": 2302, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 3, "scene": 37, "line": "Are you sure you don't want to play?", "order": 2303, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 3, "scene": 37, "line": "I'm sure.", "order": 2304, "speaker": "Angela Martin"},
  {"season": 2, "episode": 3, "scene": 37, "line": "Come on Angela, don't you have a game?", "order": 2305, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 3, "scene": 37, "line": "I have one, yes.", "order": 2306, "speaker": "Angela Martin"},
  {"season": 2, "episode": 3, "scene": 37, "line": "Well, let's play, what is it?", "order": 2307, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 3, "scene": 37, "line": "I call it Pam Pong. I count how many times Jim gets up from his desk and goes to reception to talk to you.", "order": 2308, "speaker": "Angela Martin"},
  {"season": 2, "episode": 3, "scene": 37, "line": "We're friends.", "order": 2309, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 3, "scene": 37, "line": "Apparently.", "order": 2310, "speaker": "Angela Martin"},
  {"season": 2, "episode": 3, "scene": 37, "line": "Very nicely done. I think that's H-O-R for Stanley, and H-O for Phyllis.", "order": 2311, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 3, "scene": 37, "line": "Are you calling me a ho?", "order": 2312, "speaker": "Phyllis Lapin"},
  {"season": 2, "episode": 3, "scene": 37, "line": "Oh my god. Phyllis, coming alive. I like it.", "order": 2313, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 3, "scene": 38, "line": "Question. What about carpooling, who pays for the gas?", "order": 2314, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 3, "scene": 38, "line": "We take separate cars.", "order": 2315, "speaker": "Michael Scott"},
  {"season": 2, "episode": 3, "scene": 38, "line": "Question. Can sometimes I drive your car and you drive mine?", "order": 2316, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 3, "scene": 38, "line": "Why would we do that?", "order": 2317, "speaker": "Michael Scott"},
  {"season": 2, "episode": 3, "scene": 38, "line": "Just for fun?", "order": 2318, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 3, "scene": 38, "line": "No.", "order": 2319, "speaker": "Michael Scott"},
  {"season": 2, "episode": 3, "scene": 38, "line": "Question. Who is the primary on the fire insurance?", "order": 2320, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 3, "scene": 38, "line": "EHHHHNT. Game over. Offer revoked. Dwight. I'm sorry, but you reach out and you try to be a nice guy, and help out a friend, and this is what happens. This is what I get. Oh god. I'm ... Ok.", "order": 2321, "speaker": "Michael Scott"},
  {"season": 2, "episode": 3, "scene": 39, "line": "Thank god. It was nice of him to offer, but I live in a nine bedroom farm house. I have my own crossbow range. It's a perfect situation for me. Although two bathrooms would have been nice, we just have the one. And it's under the porch.", "order": 2322, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 3, "scene": 40, "line": "Ah...", "order": 2323, "speaker": "Oscar Martinez"},
  {"season": 2, "episode": 3, "scene": 40, "line": "OH!", "order": 2324, "speaker": "Everyone"},
  {"season": 2, "episode": 3, "scene": 40, "line": "Who had someone from Vance Refrigeration?", "order": 2325, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 3, "scene": 40, "line": "I did.", "order": 2326, "speaker": "Ryan Howard"},
  {"season": 2, "episode": 3, "scene": 40, "line": "Ryan Howard. Ryan! [claps] Gold medal.", "order": 2327, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 3, "scene": 41, "line": "I made something for our closing ceremonies.", "order": 2328, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 3, "scene": 41, "line": "What? [looks in box] Oh my god. Where did you have time to make that?", "order": 2329, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 3, "scene": 41, "line": "Automatic voicemail.", "order": 2330, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 3, "scene": 41, "line": "Alright Pam, alright [gives her hi-five]. Nice work!", "order": 2331, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 3, "scene": 41, "line": "[sees Angela making check mark on tally sheet]", "order": 2332, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 3, "scene": 41, "line": "A little bit more and I would have had it.", "order": 2333, "speaker": "Stanley Hudson"},
  {"season": 2, "episode": 3, "scene": 42, "line": "You know you can always refinance your mortgage. We had a 15 year on our beet farm. We paid it off early.", "order": 2334, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 3, "scene": 42, "line": "Yeah, well, you know what? Nobody cares about your stupid beet farm. Beets are the worst.", "order": 2335, "speaker": "Michael Scott"},
  {"season": 2, "episode": 3, "scene": 42, "line": "People love beets.", "order": 2336, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 3, "scene": 42, "line": "Nobody likes beets.", "order": 2337, "speaker": "Michael Scott"},
  {"season": 2, "episode": 3, "scene": 42, "line": "Everybody loves beets.", "order": 2338, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 3, "scene": 42, "line": "Nobody likes beets, Dwight. Why don't you grow something that everybody does like? You should grow candy. I'd love a piece of candy right now. Not a beet.", "order": 2339, "speaker": "Michael Scott"},
  {"season": 2, "episode": 3, "scene": 42, "line": "Let's get this roof going.", "order": 2340, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 3, "scene": 42, "line": "Stop it! [smacks Dwight's arm]", "order": 2341, "speaker": "Michael Scott"},
  {"season": 2, "episode": 3, "scene": 42, "line": "Ow.", "order": 2342, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 3, "scene": 43, "line": "Final lap. Final lap. Time to beat is 1:15.", "order": 2343, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 3, "scene": 43, "line": "Oscar!", "order": 2344, "speaker": "Stanley Hudson"},
  {"season": 2, "episode": 3, "scene": 43, "line": "Go! Go! Go!", "order": 2345, "speaker": "Crowd"},
  {"season": 2, "episode": 3, "scene": 43, "line": "Time to beat is one minute, 15 seconds. Here they come. [Michael and Dwight enter] Guys?", "order": 2346, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 3, "scene": 43, "line": "What is going on?", "order": 2347, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 3, "scene": 43, "line": "Nothing. Guys? Timer's still going? Er?", "order": 2348, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 3, "scene": 43, "line": "That's my stopwatch.", "order": 2349, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 3, "scene": 44, "line": "[hands expense report to Oscar] Here you go. All done.", "order": 2350, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 3, "scene": 44, "line": "Great.", "order": 2351, "speaker": "Oscar Martinez"},
  {"season": 2, "episode": 3, "scene": 45, "line": "Yeah, I filled out the expense reports. That took about five minutes and then I closed two sales at lunch time. So, about as productive as any other day. If not more so.", "order": 2352, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 3, "scene": 46, "line": "I figured I could throw it away now, or I could keep it for a couple of months and then throw it away. I mean, it was really nice of Pam to make them, but what am I going to do with a gold medal made of paper clips and an old yogurt lid?", "order": 2353, "speaker": "Ryan Howard"},
  {"season": 2, "episode": 3, "scene": 47, "line": "Hey.", "order": 2354, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 3, "scene": 47, "line": "I have 59 voicemails.", "order": 2355, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 3, "scene": 47, "line": "Mmm. Hey, can you ignore those and do something for me instead?", "order": 2356, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 3, "scene": 47, "line": "Sure.", "order": 2357, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 3, "scene": 47, "line": "Okay, today. 5 o'clock. Closing Ceremonies.", "order": 2358, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 3, "scene": 47, "line": "Really?", "order": 2359, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 3, "scene": 47, "line": "Notify the athletes.", "order": 2360, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 3, "scene": 47, "line": "Cool.", "order": 2361, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 3, "scene": 48, "line": "Michael.", "order": 2362, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 3, "scene": 48, "line": "Yeah. Jim. Slim Jim. What's going... What's going on?", "order": 2363, "speaker": "Michael Scott"},
  {"season": 2, "episode": 3, "scene": 48, "line": "Nothing. I just wanted to congratulate you on your condo.", "order": 2364, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 3, "scene": 48, "line": "Oh. Thanks. Thanks. It's very cool. It's a three bedroom, gay-friendly.", "order": 2365, "speaker": "Michael Scott"},
  {"season": 2, "episode": 3, "scene": 48, "line": "Nice.", "order": 2366, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 3, "scene": 48, "line": "You know.", "order": 2367, "speaker": "Michael Scott"},
  {"season": 2, "episode": 3, "scene": 48, "line": "Hey, would you mind coming out here for a second? I just have something for you.", "order": 2368, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 3, "scene": 48, "line": "Really?", "order": 2369, "speaker": "Michael Scott"},
  {"season": 2, "episode": 3, "scene": 49, "line": "What's this?", "order": 2370, "speaker": "Michael Scott"},
  {"season": 2, "episode": 3, "scene": 49, "line": "These are the Closing Ceremonies. Step up. You're on the top one. [Michael stands on podium] Congratulations to Michael, because he closed on his condo. So, gold medal. [everyone claps]", "order": 2371, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 3, "scene": 49, "line": "I don't really know what to say. Um, I'm not one for making speeches, but ah, my heart is very full at this moment.", "order": 2372, "speaker": "Michael Scott"},
  {"season": 2, "episode": 3, "scene": 49, "line": "And for Dwight Schrute, the silver medal.", "order": 2373, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 3, "scene": 49, "line": "Get up here, Dwight.", "order": 2374, "speaker": "Michael Scott"},
  {"season": 2, "episode": 3, "scene": 49, "line": "Silver medal.", "order": 2375, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 3, "scene": 49, "line": "Yep, not as good as gold. [national anthem plays] Why are you playing the national anthem?", "order": 2376, "speaker": "Michael Scott"},
  {"season": 2, "episode": 3, "scene": 49, "line": "Um... 'Cause your condo's in America.", "order": 2377, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 3, "scene": 49, "line": "Oh. [doves move across cord] What the hell is that?", "order": 2378, "speaker": "Michael Scott"},
  {"season": 2, "episode": 3, "scene": 49, "line": "Those are the doves.", "order": 2379, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 3, "scene": 51, "line": "Hey, Dwight. Jim and I found your wallet in the parking lot.", "order": 2380, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 3, "scene": 51, "line": "What did you do to it?", "order": 2381, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 3, "scene": 51, "line": "Nothing.", "order": 2382, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 3, "scene": 51, "line": "I'm serious. What did you do to it?", "order": 2383, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 3, "scene": 51, "line": "Dwight, I swear, we didn't do anything.", "order": 2384, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 3, "scene": 51, "line": "Nice try. [on the phone] Cancel card. Cancel card. Cancel... card.", "order": 2385, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 4, "scene": 1, "line": "Dunder Mifflin, this is Pam. Sure, can I ask who's calling? Just a second.", "order": 2386, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 4, "scene": 1, "line": "Jim Halpert. What? How did you get this number? Stalker.", "order": 2387, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 4, "scene": 2, "line": "Katy and Jim met in the office. And now I guess they're like going out, or dating, or something. And, uh... I don't know! You know? They're just... She calls him, and they... You know, I'm sorry. I feel like I'm talking really loud. Am I talking really loud?", "order": 2388, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 4, "scene": 3, "line": "So we're still on for lunch? You're meeting me here? Okay. Great. Bye.", "order": 2389, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 4, "scene": 3, "line": "[to Jim] Hey! You can just give her your extension.", "order": 2390, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 4, "scene": 3, "line": "Okay.", "order": 2391, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 4, "scene": 4, "line": "Howard, slash Ryan, Ryan Howard is sitting in my office. And he has been a temp here for a couple of months and he's kind of gotten the lay of the land a little bit. Had a few laughs along the way. And now he wants to know what I think.", "order": 2392, "speaker": "Michael Scott"},
  {"season": 2, "episode": 4, "scene": 4, "line": "The temp agency wants to know what you think.", "order": 2393, "speaker": "Ryan Howard"},
  {"season": 2, "episode": 4, "scene": 4, "line": "Shall we? Let us proceed. First up, proficiency in necessary skills. Aaaaeeexcellent! [laughs]", "order": 2394, "speaker": "Michael Scott"},
  {"season": 2, "episode": 4, "scene": 5, "line": "Michael's in there right now evaluating the temp. He hasn't evaluated me in years.", "order": 2395, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 4, "scene": 6, "line": "Five years from now, what do you want to do? Where do you want to be?", "order": 2396, "speaker": "Michael Scott"},
  {"season": 2, "episode": 4, "scene": 6, "line": "Ah, well, I'm interested in business.", "order": 2397, "speaker": "Ryan Howard"},
  {"season": 2, "episode": 4, "scene": 6, "line": "Oh! Good. Ambitious. Excellent. Want to be a manager?", "order": 2398, "speaker": "Michael Scott"},
  {"season": 2, "episode": 4, "scene": 6, "line": "Uh, no, actually, uh, what I want is to own my own company.", "order": 2399, "speaker": "Ryan Howard"},
  {"season": 2, "episode": 4, "scene": 6, "line": "That is ridiculous.", "order": 2400, "speaker": "Michael Scott"},
  {"season": 2, "episode": 4, "scene": 7, "line": "Ryan's about to attend the Michael Scott School of Business. I'm like Mr. Miyagi and Yoda rolled into one.", "order": 2401, "speaker": "Michael Scott"},
  {"season": 2, "episode": 4, "scene": 8, "line": "[speaks in a Yoda voice] Much advice you seek. [regular voice] Do you know who that is?", "order": 2402, "speaker": "Michael Scott"},
  {"season": 2, "episode": 4, "scene": 8, "line": "Fozzie bear?", "order": 2403, "speaker": "Ryan Howard"},
  {"season": 2, "episode": 4, "scene": 8, "line": "Mmm... No. That was Yoda.", "order": 2404, "speaker": "Michael Scott"},
  {"season": 2, "episode": 4, "scene": 9, "line": "There are ten rules of business that you need to learn. Number one: You need to play to win. But... you also have to win to play.", "order": 2405, "speaker": "Michael Scott"},
  {"season": 2, "episode": 4, "scene": 9, "line": "Got it.", "order": 2406, "speaker": "Ryan Howard"},
  {"season": 2, "episode": 4, "scene": 9, "line": "And I will give you the rest of the ten at lunch.", "order": 2407, "speaker": "Michael Scott"},
  {"season": 2, "episode": 4, "scene": 10, "line": "[to Ryan] [makes clicking noises like shooting a gun] Hey!", "order": 2408, "speaker": "Michael Scott"},
  {"season": 2, "episode": 4, "scene": 11, "line": "Michael and I have a very special connection. He's like Batman, I'm like Robin. He's like the Lone Ranger, and I'm like Tonto. And it's not like there was the Lone Ranger, and Tonto, and Bonto.", "order": 2409, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 4, "scene": 12, "line": "[in background, on phone] But it says no late fee... .", "order": 2410, "speaker": "Oscar Martinez"},
  {"season": 2, "episode": 4, "scene": 12, "line": "[alarm sounds] People!", "order": 2411, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 4, "scene": 12, "line": "Okay! Everybody!", "order": 2412, "speaker": "Angela Martin"},
  {"season": 2, "episode": 4, "scene": 12, "line": "This is not a test! Move to the exits!", "order": 2413, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 4, "scene": 12, "line": "Do not panic!", "order": 2414, "speaker": "Angela Martin"},
  {"season": 2, "episode": 4, "scene": 12, "line": "Head towards the exits.", "order": 2415, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 4, "scene": 12, "line": "Safety partners.", "order": 2416, "speaker": "Angela Martin"},
  {"season": 2, "episode": 4, "scene": 12, "line": "Get up off your desks!", "order": 2417, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 4, "scene": 12, "line": "Do not panic.", "order": 2418, "speaker": "Angela Martin"},
  {"season": 2, "episode": 4, "scene": 12, "line": "[in phone] No, I don't hear it? Alright.", "order": 2419, "speaker": "Oscar Martinez"},
  {"season": 2, "episode": 4, "scene": 12, "line": "No, panic is warranted!", "order": 2420, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 4, "scene": 12, "line": "Go in single file lines.", "order": 2421, "speaker": "Angela Martin"},
  {"season": 2, "episode": 4, "scene": 12, "line": "[in phone] No, no. Finish the...", "order": 2422, "speaker": "Oscar Martinez"},
  {"season": 2, "episode": 4, "scene": 12, "line": "This is not at drill!", "order": 2423, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 4, "scene": 12, "line": "Arms at your sides! Arms at your sides!", "order": 2424, "speaker": "Angela Martin"},
  {"season": 2, "episode": 4, "scene": 12, "line": "Please, move quickly! This is a paper company, people! Step lively!", "order": 2425, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 4, "scene": 12, "line": "Go, let's go.", "order": 2426, "speaker": "Angela Martin"},
  {"season": 2, "episode": 4, "scene": 12, "line": "This whole place is a tinder box, it is ready to blow!", "order": 2427, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 4, "scene": 13, "line": "This is not a test! Can you leave?!", "order": 2428, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 4, "scene": 13, "line": "Oh, you say that every time.", "order": 2429, "speaker": "Phyllis Lapin"},
  {"season": 2, "episode": 4, "scene": 13, "line": "DO YOU WANT TO DIE?", "order": 2430, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 4, "scene": 13, "line": "Oh, boy...", "order": 2431, "speaker": "Phyllis Lapin"},
  {"season": 2, "episode": 4, "scene": 13, "line": "Do you want to die? OUT!!", "order": 2432, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 4, "scene": 13, "line": "Alright, let's go, let's go.", "order": 2433, "speaker": "Angela Martin"},
  {"season": 2, "episode": 4, "scene": 13, "line": "STANLEY! Have you ever seen a burn victim?", "order": 2434, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 4, "scene": 13, "line": "Come on, you're safety partners!", "order": 2435, "speaker": "Angela Martin"},
  {"season": 2, "episode": 4, "scene": 13, "line": "Move to the exits!", "order": 2436, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 4, "scene": 13, "line": "You're safety partners!", "order": 2437, "speaker": "Angela Martin"},
  {"season": 2, "episode": 4, "scene": 13, "line": "We've got smoke! We've got smoke! Smoke! Gah! [Spots Kelly] Oh, Kelly! You're okay! I've got you!", "order": 2438, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 4, "scene": 13, "line": "I'm okay!", "order": 2439, "speaker": "Kelly Kapoor"},
  {"season": 2, "episode": 4, "scene": 13, "line": "Cover your nose and mouth. Breathe through your nose.", "order": 2440, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 4, "scene": 13, "line": "Let go of me!", "order": 2441, "speaker": "Kelly Kapoor"},
  {"season": 2, "episode": 4, "scene": 13, "line": "Breathe through your nose. Remove your stockings. Okay? They'll melt right into your flesh! Stay below the smoke line. Let's go! Clear out, stat! STAT MEANS NOW!", "order": 2442, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 4, "scene": 14, "line": "Yes, I was the first one out. And, yes, I've heard women and children first. But, we do not employ children. We are not a sweat shop. Thankfully. And, uh, women are equal in the workplace by law. So, I let them out first, I have a lawsuit on my hands.", "order": 2443, "speaker": "Michael Scott"},
  {"season": 2, "episode": 4, "scene": 15, "line": "Another rule of business is being able to adapt to different situations.", "order": 2444, "speaker": "Michael Scott"},
  {"season": 2, "episode": 4, "scene": 15, "line": "Yeah.", "order": 2445, "speaker": "Ryan Howard"},
  {"season": 2, "episode": 4, "scene": 15, "line": "Adapt. React. Re-adapt. Act. All right? That's rule number two.", "order": 2446, "speaker": "Michael Scott"},
  {"season": 2, "episode": 4, "scene": 16, "line": "Okay, guys, listen up, we need a head count. We need to count off. Michael's number one. Where is he? Where is he?", "order": 2447, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 4, "scene": 17, "line": "So what was rule two?", "order": 2448, "speaker": "Michael Scott"},
  {"season": 2, "episode": 4, "scene": 17, "line": "Ah... adapt, react, re-adapt, act.", "order": 2449, "speaker": "Ryan Howard"},
  {"season": 2, "episode": 4, "scene": 17, "line": "Okay, well, let's... . let's kind of take it a little slower.", "order": 2450, "speaker": "Michael Scott"},
  {"season": 2, "episode": 4, "scene": 17, "line": "Hey, Michael. Um... Ryan needs his number for the count off.", "order": 2451, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 4, "scene": 17, "line": "Okay, uh, well, one is taken.", "order": 2452, "speaker": "Michael Scott"},
  {"season": 2, "episode": 4, "scene": 17, "line": "Uh, okay, two?", "order": 2453, "speaker": "Ryan Howard"},
  {"season": 2, "episode": 4, "scene": 17, "line": "NO!", "order": 2454, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 4, "scene": 17, "line": "Okay... uh, sorry?", "order": 2455, "speaker": "Ryan Howard"},
  {"season": 2, "episode": 4, "scene": 18, "line": "Okay, he can have 14. Marjory's not here today.", "order": 2456, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 4, "scene": 18, "line": "Well, he needs a permanent number, right?", "order": 2457, "speaker": "Michael Scott"},
  {"season": 2, "episode": 4, "scene": 18, "line": "No.", "order": 2458, "speaker": "Ryan Howard"},
  {"season": 2, "episode": 4, "scene": 19, "line": "...I don't.", "order": 2459, "speaker": "Ryan Howard"},
  {"season": 2, "episode": 4, "scene": 19, "line": "Oh, you know what else? I thought of a nickname for the three of us. Three Musketeers.", "order": 2460, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 4, "scene": 19, "line": "Um, yeah. Okay. That... No, no, no. I got one. I got one. The Three Stooges.", "order": 2461, "speaker": "Michael Scott"},
  {"season": 2, "episode": 4, "scene": 19, "line": "That's funny, too. But if we're the Three Musketeers...", "order": 2462, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 4, "scene": 20, "line": "I don't want to be like 'a guy' here. You know? Like, Stanley is the 'crossword puzzle guy'. And Angela has cats. I don't want to have a thing... here. You know, I don't want to be the 'something guy'.", "order": 2463, "speaker": "Ryan Howard"},
  {"season": 2, "episode": 4, "scene": 21, "line": "Okay, you know what? I am going to be, uh, setting the agenda here. Okay? Can everybody gather up, please? Important announcement. Very important announcement. I think this is a perfect opportunity for all of us to participate in some really intense, psychologically revealing conversations. So we're going to be playing Desert Island, umm, Who Would You Do?", "order": 2464, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 4, "scene": 21, "line": "Ooh.", "order": 2465, "speaker": "Stanley Hudson"},
  {"season": 2, "episode": 4, "scene": 21, "line": "And, um...", "order": 2466, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 4, "scene": 21, "line": "...Would You Rather?", "order": 2467, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 4, "scene": 21, "line": "Would You Rather. Would You Rather is our third game.", "order": 2468, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 4, "scene": 22, "line": "[to firemen] Hey guys, great response time. Listen up, I got some theories. Okay, there's a...", "order": 2469, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 4, "scene": 23, "line": "Okay, so... three books on a desert island? Angela.", "order": 2470, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 4, "scene": 23, "line": "The Bible.", "order": 2471, "speaker": "Angela Martin"},
  {"season": 2, "episode": 4, "scene": 23, "line": "That's one book. You've got two others.", "order": 2472, "speaker": "Stanley Hudson"},
  {"season": 2, "episode": 4, "scene": 23, "line": "A Purpose Driven Life.", "order": 2473, "speaker": "Angela Martin"},
  {"season": 2, "episode": 4, "scene": 23, "line": "Nice. Third book?", "order": 2474, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 4, "scene": 23, "line": "No.", "order": 2475, "speaker": "Angela Martin"},
  {"season": 2, "episode": 4, "scene": 23, "line": "Okay. Phyllis.", "order": 2476, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 4, "scene": 23, "line": "Um, The DaVinci Code.", "order": 2477, "speaker": "Phyllis Lapin"},
  {"season": 2, "episode": 4, "scene": 23, "line": "The DaVinci Code!", "order": 2478, "speaker": "Angela Martin"},
  {"season": 2, "episode": 4, "scene": 23, "line": "Nice.", "order": 2479, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 4, "scene": 23, "line": "I would take The DaVinci Code... so I could burn The DaVinci Code.", "order": 2480, "speaker": "Angela Martin"},
  {"season": 2, "episode": 4, "scene": 23, "line": "Okay. Great, that's going to keep you warm for like 7 seconds. Question: is there fire wood on the island?", "order": 2481, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 4, "scene": 23, "line": "I guess.", "order": 2482, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 4, "scene": 23, "line": "Then I would bring an axe, no books.", "order": 2483, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 4, "scene": 23, "line": "Uh, it has to be a book, Dwight.", "order": 2484, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 4, "scene": 23, "line": "Fine. Physician's Desk Reference.", "order": 2485, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 4, "scene": 23, "line": "Nice. Smart.", "order": 2486, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 4, "scene": 23, "line": "...hollowed out, inside: waterproof matches, iodine tablets, beet seeds, protein bars, NASA blanket, and, in case I get bored, Harry Potter and Sorcerer's Stone. No, Harry Potter and the Prisoner of Azkaban. Question: did my shoes come off in the plane crash?", "order": 2487, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 4, "scene": 24, "line": "Rule number four. In business, image is everything - Andre Agassi. This car is an investment. Right? If I have to take out a client or I'm seen around Scranton in it. I love it. I love this car. Do you like it?", "order": 2488, "speaker": "Michael Scott"},
  {"season": 2, "episode": 4, "scene": 24, "line": "Yeah.", "order": 2489, "speaker": "Ryan Howard"},
  {"season": 2, "episode": 4, "scene": 25, "line": "Okay. Thought people read more books.", "order": 2490, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 4, "scene": 26, "line": "DVDs! Five movies. What would you bring to the island? Yes! Meredith?", "order": 2491, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 4, "scene": 26, "line": "Legends of the Fall, My Big Fat Greek Wedding, Legally Blond, Bridges of Madison County...", "order": 2492, "speaker": "Meredith Palmer"},
  {"season": 2, "episode": 4, "scene": 27, "line": "Wow.", "order": 2493, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 4, "scene": 27, "line": "Legends of the Fall?", "order": 2494, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 4, "scene": 27, "line": "Wow. Bridges of Madison County, Legally Blond, these movies are just... .", "order": 2495, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 4, "scene": 27, "line": "Well, I kind of liked Legal...", "order": 2496, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 4, "scene": 27, "line": "Wait, wait, wait. Pam. No. Do you understand? The... the game is Desert Island Movies, not guilty pleasure movies. Desert Island Movies are the movies you're going to watch for the rest of your life! Forever! Unforgivable.", "order": 2497, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 4, "scene": 27, "line": "I take it back.", "order": 2498, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 4, "scene": 27, "line": "Unforgivable.", "order": 2499, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 4, "scene": 27, "line": "I take it back!", "order": 2500, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 4, "scene": 27, "line": "Good.", "order": 2501, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 4, "scene": 28, "line": "...and Ghost. But, ah, just that one scene...", "order": 2502, "speaker": "Meredith Palmer"},
  {"season": 2, "episode": 4, "scene": 29, "line": "Is this your car, Ryan?", "order": 2503, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 4, "scene": 29, "line": "Wow, some pretty big books back there, huh?", "order": 2504, "speaker": "Michael Scott"},
  {"season": 2, "episode": 4, "scene": 29, "line": "[to Dwight] Don't...", "order": 2505, "speaker": "Ryan Howard"},
  {"season": 2, "episode": 4, "scene": 29, "line": "Good shocks.", "order": 2506, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 4, "scene": 29, "line": "Hello, Mr. Egghead! Woop! So... oh, Stanley Kaplan! I know him. 'M' is for Murder, 'P' is for...", "order": 2507, "speaker": "Michael Scott"},
  {"season": 2, "episode": 4, "scene": 29, "line": "That's actually a test prep book.", "order": 2508, "speaker": "Ryan Howard"},
  {"season": 2, "episode": 4, "scene": 29, "line": "...for Phone. What?", "order": 2509, "speaker": "Michael Scott"},
  {"season": 2, "episode": 4, "scene": 29, "line": "That's a test prep for business school.", "order": 2510, "speaker": "Ryan Howard"},
  {"season": 2, "episode": 4, "scene": 29, "line": "Um, oh, thinking about business school?", "order": 2511, "speaker": "Michael Scott"},
  {"season": 2, "episode": 4, "scene": 29, "line": "I just got in. I applied, I go at night.", "order": 2512, "speaker": "Ryan Howard"},
  {"season": 2, "episode": 4, "scene": 29, "line": "Really?", "order": 2513, "speaker": "Michael Scott"},
  {"season": 2, "episode": 4, "scene": 29, "line": "Yeah.", "order": 2514, "speaker": "Ryan Howard"},
  {"season": 2, "episode": 4, "scene": 29, "line": "So you think you know a lot about business?", "order": 2515, "speaker": "Michael Scott"},
  {"season": 2, "episode": 4, "scene": 29, "line": "No, not yet.", "order": 2516, "speaker": "Ryan Howard"},
  {"season": 2, "episode": 4, "scene": 29, "line": "Uh huh.", "order": 2517, "speaker": "Michael Scott"},
  {"season": 2, "episode": 4, "scene": 29, "line": "Just started.", "order": 2518, "speaker": "Ryan Howard"},
  {"season": 2, "episode": 4, "scene": 29, "line": "Yeah. Quiz me.", "order": 2519, "speaker": "Michael Scott"},
  {"season": 2, "episode": 4, "scene": 29, "line": "I... wouldn't even know where to start.", "order": 2520, "speaker": "Ryan Howard"},
  {"season": 2, "episode": 4, "scene": 29, "line": "Come on, egghead. Let's do it.", "order": 2521, "speaker": "Michael Scott"},
  {"season": 2, "episode": 4, "scene": 29, "line": "Do it.", "order": 2522, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 4, "scene": 29, "line": "Quiz me up.", "order": 2523, "speaker": "Michael Scott"},
  {"season": 2, "episode": 4, "scene": 29, "line": "All right, um... Why have people been rethinking the Microsoft model in the past few years?", "order": 2524, "speaker": "Ryan Howard"},
  {"season": 2, "episode": 4, "scene": 29, "line": "Uh...", "order": 2525, "speaker": "Michael Scott"},
  {"season": 2, "episode": 4, "scene": 30, "line": "When I was Ryan's age, I worked in a fast food restaurant, to save up money for school. And then I spe... lost it in a pyramid scheme. But I learned more about business, right then and there, than business school would ever teach me, or Ryan would ever teach me.", "order": 2526, "speaker": "Michael Scott"},
  {"season": 2, "episode": 4, "scene": 31, "line": "Is it cheaper to sign a new customer? Or to keep an existing customer?", "order": 2527, "speaker": "Ryan Howard"},
  {"season": 2, "episode": 4, "scene": 31, "line": "Keep an existing...", "order": 2528, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 4, "scene": 31, "line": "[to Dwight] Shut, it. Can I... can I just do it please? [to Ryan] Uh, it's equal.", "order": 2529, "speaker": "Michael Scott"},
  {"season": 2, "episode": 4, "scene": 31, "line": "It is ten times more expensive to sign a new customer.", "order": 2530, "speaker": "Ryan Howard"},
  {"season": 2, "episode": 4, "scene": 31, "line": "Okay. Yes! It was a trick question.", "order": 2531, "speaker": "Michael Scott"},
  {"season": 2, "episode": 4, "scene": 31, "line": "Yeah, but look, I mean, he didn't need business school. Okay, Michael comes from the school of hard knocks.", "order": 2532, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 4, "scene": 31, "line": "Okay, Dwight.", "order": 2533, "speaker": "Michael Scott"},
  {"season": 2, "episode": 4, "scene": 31, "line": "Self taught. You didn't even go to college.", "order": 2534, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 4, "scene": 31, "line": "You know what, Dwight? You don't need to help me here. Okay? Well, you know... Maybe you should go to business school like Ryan, then... then you'd know what you're talking about.", "order": 2535, "speaker": "Michael Scott"},
  {"season": 2, "episode": 4, "scene": 31, "line": "[scoffs] Come on. I'm studying with the master, huh?", "order": 2536, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 4, "scene": 31, "line": "For instance, why don't you go to business...", "order": 2537, "speaker": "Michael Scott"},
  {"season": 2, "episode": 4, "scene": 31, "line": "[to Ryan] You should learn from him, right?", "order": 2538, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 4, "scene": 31, "line": "I am.", "order": 2539, "speaker": "Ryan Howard"},
  {"season": 2, "episode": 4, "scene": 31, "line": "Right?", "order": 2540, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 4, "scene": 31, "line": "I am.", "order": 2541, "speaker": "Ryan Howard"},
  {"season": 2, "episode": 4, "scene": 31, "line": "Stop. Dwight. You know what? You're acting like a dork. Would you cool it? Please. Okay. Hey! He's not your five year old brother, Dwight. He's a valued member of this company... and you know what? He knows more about business than you ever will.", "order": 2542, "speaker": "Michael Scott"},
  {"season": 2, "episode": 4, "scene": 31, "line": "Stupid!", "order": 2543, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 4, "scene": 32, "line": "I did not go to business school. You know who else didn't go to business school? LeBron James, Tracy McGrady, Kobe Bryant. They went right from high school to the NBA. So... so it's not the same thing. At all.", "order": 2544, "speaker": "Michael Scott"},
  {"season": 2, "episode": 4, "scene": 33, "line": "Look at this stuff. Market fragments. What is that supposed to be?", "order": 2545, "speaker": "Michael Scott"},
  {"season": 2, "episode": 4, "scene": 33, "line": "It's a way of looking at consumers as subsets of a larger client base.", "order": 2546, "speaker": "Ryan Howard"},
  {"season": 2, "episode": 4, "scene": 33, "line": "You are so smart. You are so eff-in' smart. You should be teaching me.", "order": 2547, "speaker": "Michael Scott"},
  {"season": 2, "episode": 4, "scene": 34, "line": "Pam? Get us back into it.", "order": 2548, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 4, "scene": 34, "line": "Okay.", "order": 2549, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 4, "scene": 34, "line": "Five movies. Go ahead.", "order": 2550, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 4, "scene": 34, "line": "Um, Fargo, um, Edward Scissorhands, Dazed and Confused...", "order": 2551, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 4, "scene": 34, "line": "Ooh, definitely in my top five.", "order": 2552, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 4, "scene": 34, "line": "Yes. In my top three, so suck it.", "order": 2553, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 4, "scene": 34, "line": "What?", "order": 2554, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 4, "scene": 34, "line": "Breakfast Club. Um... The Princess Bride and...", "order": 2555, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 4, "scene": 34, "line": "Okay that's five.", "order": 2556, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 4, "scene": 34, "line": "No, my all time favorite!", "order": 2557, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 4, "scene": 34, "line": "Pam, play by the rules.", "order": 2558, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 4, "scene": 34, "line": "All time favorite.", "order": 2559, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 4, "scene": 34, "line": "Play by the rules. Dwight. All time favorite movie.", "order": 2560, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 4, "scene": 34, "line": "The Crow.", "order": 2561, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 4, "scene": 35, "line": "I became a salesman... because of people, I love making friends. But then I was promoted to manager, at a very young age. I still try to be a friend first, but... You know? I'm very successful... your coworkers look at you differently. Huu, what do you think?", "order": 2562, "speaker": "Michael Scott"},
  {"season": 2, "episode": 4, "scene": 35, "line": "Maybe we should get some air.", "order": 2563, "speaker": "Ryan Howard"},
  {"season": 2, "episode": 4, "scene": 35, "line": "Nah, I'm okay.", "order": 2564, "speaker": "Michael Scott"},
  {"season": 2, "episode": 4, "scene": 35, "line": "I'm really uncomfortable.", "order": 2565, "speaker": "Ryan Howard"},
  {"season": 2, "episode": 4, "scene": 36, "line": "All right. Let's move on. Let's move on to the main event. Who Would You Do?", "order": 2566, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 4, "scene": 36, "line": "Present company excluded?", "order": 2567, "speaker": "Kevin Malone"},
  {"season": 2, "episode": 4, "scene": 36, "line": "Um, not neccessari...", "order": 2568, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 4, "scene": 36, "line": "Pam.", "order": 2569, "speaker": "Kevin Malone"},
  {"season": 2, "episode": 4, "scene": 36, "line": "Pam.", "order": 2570, "speaker": "Oscar Martinez"},
  {"season": 2, "episode": 4, "scene": 36, "line": "Um... okay. Ah, you know what? Maybe I'll... I'll finish explaining the rules. Let's... let me explain it first, and then...", "order": 2571, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 4, "scene": 36, "line": "['Everybody Hurts' by R.E.M.] Think you've had too much / in this life.", "order": 2572, "speaker": "song"},
  {"season": 2, "episode": 4, "scene": 36, "line": "Yeah, so we'll get right... You know what? I'll be right back. Stanley, you're taking over for me, buddy. I'll be right back.", "order": 2573, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 4, "scene": 36, "line": "Okay, um...", "order": 2574, "speaker": "Stanley Hudson"},
  {"season": 2, "episode": 4, "scene": 37, "line": "Dwight. Dwight.", "order": 2575, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 4, "scene": 37, "line": "Everybody hurts,", "order": 2576, "speaker": "Song"},
  {"season": 2, "episode": 4, "scene": 37, "line": "Come on Dwight! Use words.", "order": 2577, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 4, "scene": 37, "line": "Sometim... .", "order": 2578, "speaker": "Song"},
  {"season": 2, "episode": 4, "scene": 37, "line": "Why didn't I go to business school?", "order": 2579, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 4, "scene": 37, "line": "Who goes to business school?", "order": 2580, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 4, "scene": 37, "line": "The temp.", "order": 2581, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 4, "scene": 37, "line": "He does?", "order": 2582, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 4, "scene": 37, "line": "Yeah, it's all him and Michael talk about anymore.", "order": 2583, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 4, "scene": 37, "line": "You know, I bet Ryan thinks to himself 'I wish I were a volunteer sheriff on the weekends'.", "order": 2584, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 4, "scene": 37, "line": "He doesn't even know that I do that.", "order": 2585, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 4, "scene": 37, "line": "You should tell him.", "order": 2586, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 4, "scene": 37, "line": "Oh yeah, Pam. Right. That's going to help things, just talk it out. I hope the war goes on forever and Ryan gets drafted.", "order": 2587, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 4, "scene": 37, "line": "Dwight.", "order": 2588, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 4, "scene": 37, "line": "What?", "order": 2589, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 4, "scene": 37, "line": "I'm sorry I said that, I didn't... just part of me meant it. Besides, he'd end up being a hero anyway.", "order": 2590, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 4, "scene": 37, "line": "You know what you should do? You should quit. And then, that would stick it to both of them.", "order": 2591, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 4, "scene": 37, "line": "Oh Jim, I'm not going to quit. Then Ryan wins.", "order": 2592, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 4, "scene": 37, "line": "Yeah. You're right.", "order": 2593, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 4, "scene": 37, "line": "Thanks you guys. I just need some alone time.", "order": 2594, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 4, "scene": 37, "line": "Kay.", "order": 2595, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 4, "scene": 37, "line": "Everybody hurts", "order": 2596, "speaker": "Song"},
  {"season": 2, "episode": 4, "scene": 37, "line": "Alright buddy.", "order": 2597, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 4, "scene": 37, "line": "Everybody cries", "order": 2598, "speaker": "Song"},
  {"season": 2, "episode": 4, "scene": 37, "line": "Hey! Guys, what's going on?", "order": 2599, "speaker": "Roy Anderson"},
  {"season": 2, "episode": 4, "scene": 37, "line": "Nothing.", "order": 2600, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 4, "scene": 37, "line": "Hey!", "order": 2601, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 4, "scene": 37, "line": "Everybody hurts", "order": 2602, "speaker": "Song"},
  {"season": 2, "episode": 4, "scene": 37, "line": "What's up? Can I hang out with you guys for a bit?", "order": 2603, "speaker": "Roy Anderson"},
  {"season": 2, "episode": 4, "scene": 37, "line": "Sometimes", "order": 2604, "speaker": "Song"},
  {"season": 2, "episode": 4, "scene": 37, "line": "The warehouse guys are a bunch of jackasses sometimes.", "order": 2605, "speaker": "Roy Anderson"},
  {"season": 2, "episode": 4, "scene": 38, "line": "Come on people, you know the rules of the game now.", "order": 2606, "speaker": "Stanley Hudson"},
  {"season": 2, "episode": 4, "scene": 38, "line": "Oh, hey. Game, what game are we playing here?", "order": 2607, "speaker": "Michael Scott"},
  {"season": 2, "episode": 4, "scene": 38, "line": "Okay. It's called Who Would You Do?", "order": 2608, "speaker": "Stanley Hudson"},
  {"season": 2, "episode": 4, "scene": 38, "line": "Oh, I play this at home all the time while I'm falling asleep. What, uh... . Where are we? Where are we here? Mmm.. Roy? Roy? Who would you do, Roy?", "order": 2609, "speaker": "Michael Scott"},
  {"season": 2, "episode": 4, "scene": 38, "line": "Uh... Oh, I got it! Uh, what's the name of that, uh, tight ass, uh, Christian, uh, chick. The, uh, the blond?", "order": 2610, "speaker": "Roy Anderson"},
  {"season": 2, "episode": 4, "scene": 38, "line": "My name is Angela.", "order": 2611, "speaker": "Angela Martin"},
  {"season": 2, "episode": 4, "scene": 38, "line": "Hey, Angela! Roy. Nice to meet you.", "order": 2612, "speaker": "Roy Anderson"},
  {"season": 2, "episode": 4, "scene": 38, "line": "Aaaall right. Who's next, who's next, who's next, who's? Jim? You're next. Who would you do?", "order": 2613, "speaker": "Michael Scott"},
  {"season": 2, "episode": 4, "scene": 38, "line": "Um... Kevin, hands down. Yeah. He's really got that teddy bear thing going on, and afterwards, we could just watch bowling.", "order": 2614, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 4, "scene": 38, "line": "Well, I would definitely have sex with Ryan. 'Cause he is going to own his own business.", "order": 2615, "speaker": "Michael Scott"},
  {"season": 2, "episode": 4, "scene": 38, "line": "You're all gay.", "order": 2616, "speaker": "Roy Anderson"},
  {"season": 2, "episode": 4, "scene": 38, "line": "Who's, uh... Who's next? Who we got? Whooo...", "order": 2617, "speaker": "Michael Scott"},
  {"season": 2, "episode": 4, "scene": 38, "line": "[answers phone] Hey, no, I can talk, I can talk, I can talk... this is great timing.", "order": 2618, "speaker": "Ryan Howard"},
  {"season": 2, "episode": 4, "scene": 38, "line": "Wish I had my cell phone, but I left it inside. So...", "order": 2619, "speaker": "Michael Scott"},
  {"season": 2, "episode": 4, "scene": 38, "line": "Would that make you happy?", "order": 2620, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 4, "scene": 38, "line": "What's that?", "order": 2621, "speaker": "Michael Scott"},
  {"season": 2, "episode": 4, "scene": 38, "line": "If you had your cell phone, it would make you happy?", "order": 2622, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 4, "scene": 38, "line": "Yeah.", "order": 2623, "speaker": "Michael Scott"},
  {"season": 2, "episode": 4, "scene": 38, "line": "I'm on it.", "order": 2624, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 4, "scene": 38, "line": "Dwight. Hey!", "order": 2625, "speaker": "Michael Scott"},
  {"season": 2, "episode": 4, "scene": 38, "line": "You can't go in yet!", "order": 2626, "speaker": "Angela Martin"},
  {"season": 2, "episode": 4, "scene": 38, "line": "Dwight, don't! He is an idiot. The man is an idiot, ladies and gentlemen.", "order": 2627, "speaker": "Michael Scott"},
  {"season": 2, "episode": 4, "scene": 38, "line": "What if he dies in the fire? And that's the last thing you ever said to him.", "order": 2628, "speaker": "Kevin Malone"},
  {"season": 2, "episode": 4, "scene": 38, "line": "I didn't say it to him. I said it about him.", "order": 2629, "speaker": "Michael Scott"},
  {"season": 2, "episode": 4, "scene": 39, "line": "...Jim.", "order": 2630, "speaker": "Meredith Palmer"},
  {"season": 2, "episode": 4, "scene": 39, "line": "Definitely Jim.", "order": 2631, "speaker": "Phyllis Lapin"},
  {"season": 2, "episode": 4, "scene": 39, "line": "Definitely, definitely, Jim.", "order": 2632, "speaker": "Kelly Kapoor"},
  {"season": 2, "episode": 4, "scene": 39, "line": "Come on, Pam.", "order": 2633, "speaker": "Phyllis Lapin"},
  {"season": 2, "episode": 4, "scene": 39, "line": "How about you Pam?", "order": 2634, "speaker": "Kelly Kapoor"},
  {"season": 2, "episode": 4, "scene": 39, "line": "Um... Oscar's kind of cute.", "order": 2635, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 4, "scene": 39, "line": "Yeah, I like Oscar.", "order": 2636, "speaker": "Phyllis Lapin"},
  {"season": 2, "episode": 4, "scene": 39, "line": "Ooh, Toby!", "order": 2637, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 4, "scene": 39, "line": "[in the background] How long does it take to find a cell phone? I don't know either.", "order": 2638, "speaker": "Michael Scott"},
  {"season": 2, "episode": 4, "scene": 39, "line": "Is there anybody else.", "order": 2639, "speaker": "Meredith Palmer"},
  {"season": 2, "episode": 4, "scene": 39, "line": "[clears his throat]", "order": 2640, "speaker": "Kevin Malone"},
  {"season": 2, "episode": 4, "scene": 40, "line": "[on the phone] Hey, where are you? Oh good. Yeah. We're just here, we're playing Desert Island. It's when you pick your five favorite DVDs...", "order": 2641, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 4, "scene": 40, "line": "Seriously, where the hell is Dwight? Hey, call my cell phone. It'll make it easier for him to find.", "order": 2642, "speaker": "Michael Scott"},
  {"season": 2, "episode": 4, "scene": 40, "line": "What's your number?", "order": 2643, "speaker": "Ryan Howard"},
  {"season": 2, "episode": 4, "scene": 40, "line": "I gave it to you in the car.", "order": 2644, "speaker": "Michael Scott"},
  {"season": 2, "episode": 4, "scene": 40, "line": "Um...", "order": 2645, "speaker": "Ryan Howard"},
  {"season": 2, "episode": 4, "scene": 40, "line": "I saw you program it in.", "order": 2646, "speaker": "Michael Scott"},
  {"season": 2, "episode": 4, "scene": 40, "line": "You got to... you got to give it to me again.", "order": 2647, "speaker": "Ryan Howard"},
  {"season": 2, "episode": 4, "scene": 40, "line": "Okay. Alright.", "order": 2648, "speaker": "Michael Scott"},
  {"season": 2, "episode": 4, "scene": 40, "line": "Now I have it.", "order": 2649, "speaker": "Ryan Howard"},
  {"season": 2, "episode": 4, "scene": 40, "line": "Uh, I better tell somebody. [to fireman] Excuse me, sir...", "order": 2650, "speaker": "Michael Scott"},
  {"season": 2, "episode": 4, "scene": 40, "line": "[coughing]", "order": 2651, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 4, "scene": 40, "line": "Dwight!? Great goin'. God, Man! Why did you go in there? What... Everybody was scared out of their wits, man? Oooh.", "order": 2652, "speaker": "Michael Scott"},
  {"season": 2, "episode": 4, "scene": 40, "line": "[coughing] Everyone, okay? Uh, I have an announcement. Apparently, in business school, they don't teach you how to operate a toaster oven. Because some smart, sexy temp left his cheese pita on oven instead of timing it for the toaster thing.", "order": 2653, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 4, "scene": 40, "line": "Wow. Okay. Well, I guess they don't teach how to operate a toaster oven in business school.", "order": 2654, "speaker": "Michael Scott"},
  {"season": 2, "episode": 4, "scene": 40, "line": "That's exactly what I said.", "order": 2655, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 4, "scene": 40, "line": "Hey, did you miss that day there, Ryan?", "order": 2656, "speaker": "Michael Scott"},
  {"season": 2, "episode": 4, "scene": 40, "line": "Were you absent?", "order": 2657, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 4, "scene": 40, "line": "Toaster Oven 101?", "order": 2658, "speaker": "Michael Scott"},
  {"season": 2, "episode": 4, "scene": 40, "line": "You failed?", "order": 2659, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 4, "scene": 40, "line": "I am so sorry.", "order": 2660, "speaker": "Ryan Howard"},
  {"season": 2, "episode": 4, "scene": 41, "line": "Hey! I know what'll impress everybody, I'll start a fire. Oh, man. Bad idea. Bad idea jeans.", "order": 2661, "speaker": "Michael Scott"},
  {"season": 2, "episode": 4, "scene": 42, "line": "I have a song. Attention, everyone! That I want to sing. That I wrote especially for this occasion when I was up there among the flames. Ready? [sings to Billy Joel's 'We Didn't Start the Fire'] Ryan started the fire! It was always burning since the world's been turning!", "order": 2662, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 4, "scene": 42, "line": "[singing] Ryan started the fire! It was always burning---", "order": 2663, "speaker": "Dwight Schrute & Michael Scott"},
  {"season": 2, "episode": 4, "scene": 42, "line": "Everybody!", "order": 2664, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 4, "scene": 42, "line": "[singing] ...since the world was turning.", "order": 2665, "speaker": "Michael Scott"},
  {"season": 2, "episode": 4, "scene": 43, "line": "I can't believe I started the fire.", "order": 2666, "speaker": "Ryan Howard"},
  {"season": 2, "episode": 4, "scene": 44, "line": "[sing gibberish to 'We Didn't Start the Fire']", "order": 2667, "speaker": "Dwight Schrute & Michael Scott"},
  {"season": 2, "episode": 4, "scene": 44, "line": "[singing] ... Marilyn Monroe!", "order": 2668, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 4, "scene": 44, "line": "[singing] Ryan started the fire! It was always burning...", "order": 2669, "speaker": "Dwight Schrute & Michael Scott"},
  {"season": 2, "episode": 4, "scene": 44, "line": "Eat it! You gotta eat it. You have to eat it!", "order": 2670, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 4, "scene": 45, "line": "Hi!", "order": 2671, "speaker": "Katy Moore"},
  {"season": 2, "episode": 4, "scene": 45, "line": "Hey.", "order": 2672, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 4, "scene": 45, "line": "How are you?", "order": 2673, "speaker": "Katy Moore"},
  {"season": 2, "episode": 4, "scene": 45, "line": "Good, how are you?", "order": 2674, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 4, "scene": 45, "line": "I'm good. It's good to see you.", "order": 2675, "speaker": "Katy Moore"},
  {"season": 2, "episode": 4, "scene": 45, "line": "Good to see you, too.", "order": 2676, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 4, "scene": 45, "line": "I'm hungry.", "order": 2677, "speaker": "Katy Moore"},
  {"season": 2, "episode": 4, "scene": 45, "line": "Yeah, I am too.", "order": 2678, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 4, "scene": 45, "line": "Oh, I have been thinking the whole way over and I have my answers.", "order": 2679, "speaker": "Katy Moore"},
  {"season": 2, "episode": 4, "scene": 45, "line": "What answers?", "order": 2680, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 4, "scene": 45, "line": "Um, for the... the desert island.", "order": 2681, "speaker": "Katy Moore"},
  {"season": 2, "episode": 4, "scene": 45, "line": "Oh! Right! Right, right, right, come-ah on, on, on. [to everyone] Ladies and gentlemen! Gather around! We have one more participant. Come on, be polite. Be polite. [to Katy] Desert Island. Five movies. Go.", "order": 2682, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 4, "scene": 45, "line": "Okay, um, first, Legally Blond.", "order": 2683, "speaker": "Katy Moore"},
  {"season": 2, "episode": 4, "scene": 46, "line": "I forgot what a super, nice girl Katy is. And just... good for Jim! They are so cute together. And, um, what an adorable car.", "order": 2684, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 4, "scene": 47, "line": "Okay, I think the game's over... People are like leaving. There was a bigger crowd last time. Do you just want to go to lunch?", "order": 2685, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 4, "scene": 47, "line": "Okay.", "order": 2686, "speaker": "Katy Moore"},
  {"season": 2, "episode": 4, "scene": 47, "line": "Yeah?", "order": 2687, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 4, "scene": 47, "line": "Alright! You want to drive?", "order": 2688, "speaker": "Katy Moore"},
  {"season": 2, "episode": 4, "scene": 47, "line": "Sure.", "order": 2689, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 4, "scene": 47, "line": "Alright.", "order": 2690, "speaker": "Katy Moore"},
  {"season": 2, "episode": 4, "scene": 48, "line": "[looking at Roy and Pam] They are soo cute.", "order": 2691, "speaker": "Katy Moore"},
  {"season": 2, "episode": 4, "scene": 49, "line": "I'm really sorry, Dwight.", "order": 2692, "speaker": "Ryan Howard"},
  {"season": 2, "episode": 4, "scene": 49, "line": "Answer me this, though.", "order": 2693, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 4, "scene": 49, "line": "What?", "order": 2694, "speaker": "Ryan Howard"},
  {"season": 2, "episode": 4, "scene": 49, "line": "Was it worth it? Was it worth it temp?", "order": 2695, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 4, "scene": 49, "line": "No.", "order": 2696, "speaker": "Ryan Howard"},
  {"season": 2, "episode": 4, "scene": 49, "line": "Was it worth it?", "order": 2697, "speaker": "Kevin Malone"},
  {"season": 2, "episode": 4, "scene": 49, "line": "Really?", "order": 2698, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 4, "scene": 49, "line": "I'm really sorry, Dwight.", "order": 2699, "speaker": "Ryan Howard"},
  {"season": 2, "episode": 4, "scene": 49, "line": "The fire guy! The fire guy!", "order": 2700, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 4, "scene": 50, "line": "[sings] Joe McCarthy, Richard Nixon, Studebaker, television, North Korea, South Korea, Marilyn Monroe, Ryan started the fire!", "order": 2701, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 4, "scene": 51, "line": "Okay. Rule five - safety first, i.e. don't burn the building down. Okay? That should be a no brainer.", "order": 2702, "speaker": "Michael Scott"},
  {"season": 2, "episode": 4, "scene": 52, "line": "Oh... look! Ryan is book smart. And I am street smart. And book smart.", "order": 2703, "speaker": "Michael Scott"},
  {"season": 2, "episode": 4, "scene": 53, "line": "I'll give you the rest of the ten tomorrow.", "order": 2704, "speaker": "Michael Scott"},
  {"season": 2, "episode": 5, "scene": 1, "line": "Happy Halloween, everyone! [notices Pam, in her cat costume] Oh... that's great!", "order": 2705, "speaker": "Michael Scott"},
  {"season": 2, "episode": 5, "scene": 1, "line": "Hey... Happy Halloween. Jan called.", "order": 2706, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 5, "scene": 1, "line": "Ohh... OK.", "order": 2707, "speaker": "Michael Scott"},
  {"season": 2, "episode": 5, "scene": 2, "line": "I know why she's calling. It's the end of the month, and I was supposed to let somebody go by the end of the month. And somehow I'm supposed to put on a costume and smile. [dials a number on his speaker phone] Okay.", "order": 2708, "speaker": "Michael Scott"},
  {"season": 2, "episode": 5, "scene": 2, "line": "[on phone] Jan Levinson's office.", "order": 2709, "speaker": "Sherri"},
  {"season": 2, "episode": 5, "scene": 2, "line": "Hey, Sherri. Michael Scott returning.", "order": 2710, "speaker": "Michael Scott"},
  {"season": 2, "episode": 5, "scene": 2, "line": "Oh, she's in a meeting. Uh, she just wanted the name of the employee you let go.", "order": 2711, "speaker": "Sherri"},
  {"season": 2, "episode": 5, "scene": 2, "line": "Well, I'm gonna wait till the end of the day. Because the book said it's best to wait till the end of the day.", "order": 2712, "speaker": "Michael Scott"},
  {"season": 2, "episode": 5, "scene": 2, "line": "I just need the name of who you're planning to let go.", "order": 2713, "speaker": "Sherri"},
  {"season": 2, "episode": 5, "scene": 2, "line": "I don't know... yet. I will have to call her back.", "order": 2714, "speaker": "Michael Scott"},
  {"season": 2, "episode": 5, "scene": 2, "line": "I know she wanted the name.", "order": 2715, "speaker": "Sherri"},
  {"season": 2, "episode": 5, "scene": 2, "line": "Okay... Sherri?", "order": 2716, "speaker": "Michael Scott"},
  {"season": 2, "episode": 5, "scene": 2, "line": "Yeah?", "order": 2717, "speaker": "Sherri"},
  {"season": 2, "episode": 5, "scene": 2, "line": "If you were getting fired, how would you wanna be told so you could still be friends with the person firing you?", "order": 2718, "speaker": "Michael Scott"},
  {"season": 2, "episode": 5, "scene": 2, "line": "Jan wants the name as soon as possible, Michael.", "order": 2719, "speaker": "Sherri"},
  {"season": 2, "episode": 5, "scene": 2, "line": "Thanks.", "order": 2720, "speaker": "Michael Scott"},
  {"season": 2, "episode": 5, "scene": 2, "line": "Mm-Hmm.", "order": 2721, "speaker": "Sherri"},
  {"season": 2, "episode": 5, "scene": 2, "line": "I'll call her back. [talks softy, to himself] Wish I could fire Sherri.", "order": 2722, "speaker": "Michael Scott"},
  {"season": 2, "episode": 5, "scene": 2, "line": "Hey, I'm still here.", "order": 2723, "speaker": "Sherri"},
  {"season": 2, "episode": 5, "scene": 2, "line": "Okay! I'm sorry.", "order": 2724, "speaker": "Michael Scott"},
  {"season": 2, "episode": 5, "scene": 2, "line": "Yeah.", "order": 2725, "speaker": "Sherri"},
  {"season": 2, "episode": 5, "scene": 2, "line": "No?", "order": 2726, "speaker": "Michael Scott"},
  {"season": 2, "episode": 5, "scene": 2, "line": "OK.", "order": 2727, "speaker": "Sherri"},
  {"season": 2, "episode": 5, "scene": 2, "line": "Bye.", "order": 2728, "speaker": "Michael Scott"},
  {"season": 2, "episode": 5, "scene": 2, "line": "Hanging up now.", "order": 2729, "speaker": "Sherri"},
  {"season": 2, "episode": 5, "scene": 3, "line": "I mean you hear about layoffs in the news, but when you actually have to do it yourself, it is heavy stuff. It's... these are people's lives you're talking about.", "order": 2730, "speaker": "Michael Scott"},
  {"season": 2, "episode": 5, "scene": 3, "line": "[entering] You wanted me?", "order": 2731, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 5, "scene": 3, "line": "Yes.", "order": 2732, "speaker": "Michael Scott"},
  {"season": 2, "episode": 5, "scene": 3, "line": "[notices Michael's costume] Papier-mache?", "order": 2733, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 5, "scene": 3, "line": "Yes.", "order": 2734, "speaker": "Michael Scott"},
  {"season": 2, "episode": 5, "scene": 3, "line": "Hmm.", "order": 2735, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 5, "scene": 3, "line": "Yeeesh.", "order": 2736, "speaker": "Michael Scott"},
  {"season": 2, "episode": 5, "scene": 3, "line": "Mm-hmm.", "order": 2737, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 5, "scene": 3, "line": "Um, Pam, I have to let somebody go today. This is, uh, the hardest thing I've ever had to do.", "order": 2738, "speaker": "Michael Scott"},
  {"season": 2, "episode": 5, "scene": 3, "line": "Why did you put it off until Halloween?", "order": 2739, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 5, "scene": 3, "line": "Because it's very scary stuff.", "order": 2740, "speaker": "Michael Scott"},
  {"season": 2, "episode": 5, "scene": 3, "line": "I think it's gonna put a damper on the party a little.", "order": 2741, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 5, "scene": 3, "line": "You're worried about the party? There's a man's life at stake here.", "order": 2742, "speaker": "Michael Scott"},
  {"season": 2, "episode": 5, "scene": 3, "line": "So it's a man?", "order": 2743, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 5, "scene": 3, "line": "No. Or a woman. A human life. If you had to guess, who it would be based on their job performance... and who you think deserved to be fired - who would that be?", "order": 2744, "speaker": "Michael Scott"},
  {"season": 2, "episode": 5, "scene": 3, "line": "I just answer the phone.", "order": 2745, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 5, "scene": 3, "line": "And... sometimes you just let it go to voicemail.", "order": 2746, "speaker": "Michael Scott"},
  {"season": 2, "episode": 5, "scene": 3, "line": "You're costume is fantastic! [laughs]", "order": 2747, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 5, "scene": 3, "line": "I know. I sent away for it in July from a catalog. [bobs his head around, causing the costume head to jiggle around]", "order": 2748, "speaker": "Michael Scott"},
  {"season": 2, "episode": 5, "scene": 3, "line": "Oh no, don't, don't, don't, don't. [Michael laughs] Aah! [laughs, then leaves] Okay...", "order": 2749, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 5, "scene": 3, "line": "Oh, man. Okay, I have to fire somebody.", "order": 2750, "speaker": "Michael Scott"},
  {"season": 2, "episode": 5, "scene": 4, "line": "[eyeing Jim's costume] What is that?! What are you supposed to be?", "order": 2751, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 5, "scene": 4, "line": "I'm a three hole punch version of Jim. 'Cause you can have me either way. Plain White Jim, or Three-hole Punch.", "order": 2752, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 5, "scene": 4, "line": "That's great!", "order": 2753, "speaker": "Phyllis Lapin"},
  {"season": 2, "episode": 5, "scene": 4, "line": "Oh, yeah.", "order": 2754, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 5, "scene": 4, "line": "Yeah, well look... [pulls his hood over his head and pops up his light saber] What about me?", "order": 2755, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 5, "scene": 4, "line": "What are you? A monk?", "order": 2756, "speaker": "Phyllis Lapin"},
  {"season": 2, "episode": 5, "scene": 4, "line": "I am a Sith Lord. [looks at Jim] Oh big deal. Three round pieces of paper taped to a shirt. This cost me 129 dollars.", "order": 2757, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 5, "scene": 4, "line": "Ass.", "order": 2758, "speaker": "Phyllis Lapin"},
  {"season": 2, "episode": 5, "scene": 5, "line": "Hey.", "order": 2759, "speaker": "Michael Scott"},
  {"season": 2, "episode": 5, "scene": 5, "line": "Michael.", "order": 2760, "speaker": "Oscar Martinez"},
  {"season": 2, "episode": 5, "scene": 5, "line": "You guys excited about the party?", "order": 2761, "speaker": "Michael Scott"},
  {"season": 2, "episode": 5, "scene": 5, "line": "Yeah.", "order": 2762, "speaker": "Angela Martin"},
  {"season": 2, "episode": 5, "scene": 5, "line": "It's gonna be fun.", "order": 2763, "speaker": "Michael Scott"},
  {"season": 2, "episode": 5, "scene": 5, "line": "Yeah.", "order": 2764, "speaker": "Kevin Malone"},
  {"season": 2, "episode": 5, "scene": 5, "line": "Yes.", "order": 2765, "speaker": "Angela Martin"},
  {"season": 2, "episode": 5, "scene": 5, "line": "[looks to Oscar] Oh, boy... look at you! Haha. Showing your colors. Bet you wish you wore a dress every day.", "order": 2766, "speaker": "Michael Scott"},
  {"season": 2, "episode": 5, "scene": 5, "line": "What are you implying?", "order": 2767, "speaker": "Oscar Martinez"},
  {"season": 2, "episode": 5, "scene": 5, "line": "All good. Happy Halloween. What happened to all those spooky decorations that we had? The cobwebs and such?", "order": 2768, "speaker": "Michael Scott"},
  {"season": 2, "episode": 5, "scene": 5, "line": "You know, I don't know. We put them all up last night.", "order": 2769, "speaker": "Angela Martin"},
  {"season": 2, "episode": 5, "scene": 5, "line": "Well, you know what? Go buy some more. I'll approve the overages. Sound good?", "order": 2770, "speaker": "Michael Scott"},
  {"season": 2, "episode": 5, "scene": 5, "line": "Yeah.", "order": 2771, "speaker": "Angela Martin"},
  {"season": 2, "episode": 5, "scene": 5, "line": "Good. Oh, yeah, also about budget stuff. Um, I'm going to need you to find, like a, a full employee salary, plus benefits, like fifty grand. I'm going to need you to find 50 grand in the numbers.", "order": 2772, "speaker": "Michael Scott"},
  {"season": 2, "episode": 5, "scene": 5, "line": "But we don't keep two sets of books.", "order": 2773, "speaker": "Angela Martin"},
  {"season": 2, "episode": 5, "scene": 5, "line": "Well, that's not what I'm saying. Just, you know, find it. Pretend that your jobs depend on it.", "order": 2774, "speaker": "Michael Scott"},
  {"season": 2, "episode": 5, "scene": 6, "line": "Mmm-hm-hmm. Interesting take on Dorothy. I love it. Hey, you know what would even be better? Soccer ball and cleats.", "order": 2775, "speaker": "Michael Scott"},
  {"season": 2, "episode": 5, "scene": 6, "line": "Why is that?", "order": 2776, "speaker": "Kelly Kapoor"},
  {"season": 2, "episode": 5, "scene": 6, "line": "Bend It Like Beckham.'", "order": 2777, "speaker": "Michael Scott"},
  {"season": 2, "episode": 5, "scene": 6, "line": "Oh, like ... the movie about the Indian girl who plays soccer?", "order": 2778, "speaker": "Kelly Kapoor"},
  {"season": 2, "episode": 5, "scene": 6, "line": "[laughs] Yeah. That would be perfect.", "order": 2779, "speaker": "Michael Scott"},
  {"season": 2, "episode": 5, "scene": 6, "line": "Yeah, I mean, I guess I could do that. I don't really play soccer or anything.", "order": 2780, "speaker": "Kelly Kapoor"},
  {"season": 2, "episode": 5, "scene": 6, "line": "Well, I don't really have two heads. So...", "order": 2781, "speaker": "Michael Scott"},
  {"season": 2, "episode": 5, "scene": 7, "line": "Wait, what are you again? Oh, right... Three-hole PUNCH! [punches Jim in the chest and cracks up laughing]", "order": 2782, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 5, "scene": 8, "line": "Okay, greatest strength.", "order": 2783, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 5, "scene": 8, "line": "Okay, okay...", "order": 2784, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 5, "scene": 8, "line": "A dog-like obedience to authority", "order": 2785, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 5, "scene": 8, "line": "Nice.", "order": 2786, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 5, "scene": 8, "line": "But that doesn't sound good.", "order": 2787, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 5, "scene": 8, "line": "Okay, okay. Um, how 'bout, the ultimate team player? [Pam laughs and types]", "order": 2788, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 5, "scene": 9, "line": "Dwight is... special. But, I don't believe that his talents are being used in this office. So Pam and I have put his resume on Monster.com, Google, Craig's List. We're really interested most in jobs that take Dwight out of state. Um, preferably Alaska... or India.", "order": 2789, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 5, "scene": 10, "line": "He's a gun nut.", "order": 2790, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 5, "scene": 10, "line": "Um. He sticks to his guns.", "order": 2791, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 5, "scene": 11, "line": "Well, I looked through all the budgets. And there is one department...", "order": 2792, "speaker": "Angela Martin"},
  {"season": 2, "episode": 5, "scene": 11, "line": "Yes?", "order": 2793, "speaker": "Oscar Martinez"},
  {"season": 2, "episode": 5, "scene": 11, "line": "... that has three people...", "order": 2794, "speaker": "Angela Martin"},
  {"season": 2, "episode": 5, "scene": 11, "line": "Yeah?", "order": 2795, "speaker": "Oscar Martinez"},
  {"season": 2, "episode": 5, "scene": 11, "line": "... doing the work that could be done by two.", "order": 2796, "speaker": "Angela Martin"},
  {"season": 2, "episode": 5, "scene": 11, "line": "This is great. [Angela shakes her head] Oh.", "order": 2797, "speaker": "Oscar Martinez"},
  {"season": 2, "episode": 5, "scene": 11, "line": "Yeah. Oh.", "order": 2798, "speaker": "Kevin Malone"},
  {"season": 2, "episode": 5, "scene": 12, "line": "Who do you think it should be?", "order": 2799, "speaker": "Michael Scott"},
  {"season": 2, "episode": 5, "scene": 12, "line": "Jim. Definitely.", "order": 2800, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 5, "scene": 12, "line": "No, Jim brings in money.", "order": 2801, "speaker": "Michael Scott"},
  {"season": 2, "episode": 5, "scene": 12, "line": "Phyllis.", "order": 2802, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 5, "scene": 12, "line": "Eh.", "order": 2803, "speaker": "Michael Scott"},
  {"season": 2, "episode": 5, "scene": 12, "line": "Stanley. Pam. Oscar. Meredith. Kevin. Angela.", "order": 2804, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 5, "scene": 13, "line": "It's not a popularity contest. Although it does make sense to fire the least popular because it has the least effect on morale.", "order": 2805, "speaker": "Michael Scott"},
  {"season": 2, "episode": 5, "scene": 14, "line": "One of the warehouse guys.", "order": 2806, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 5, "scene": 14, "line": "[turns to the fake head, listening] What? There was someone left off that list? Who?", "order": 2807, "speaker": "Michael Scott"},
  {"season": 2, "episode": 5, "scene": 14, "line": "Who is he saying?", "order": 2808, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 5, "scene": 14, "line": "You're right, I didn't even think of him.", "order": 2809, "speaker": "Michael Scott"},
  {"season": 2, "episode": 5, "scene": 14, "line": "No, Michael.", "order": 2810, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 5, "scene": 14, "line": "Yeah, that's actually a really good idea.", "order": 2811, "speaker": "Michael Scott"},
  {"season": 2, "episode": 5, "scene": 14, "line": "No, not me.", "order": 2812, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 5, "scene": 14, "line": "Yeah... I could.", "order": 2813, "speaker": "Michael Scott"},
  {"season": 2, "episode": 5, "scene": 14, "line": "Not Dwight.", "order": 2814, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 5, "scene": 14, "line": "I'm not saying that's what he said.", "order": 2815, "speaker": "Michael Scott"},
  {"season": 2, "episode": 5, "scene": 14, "line": "I know that's what he said.", "order": 2816, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 5, "scene": 14, "line": "[listening to his head] What?", "order": 2817, "speaker": "Michael Scott"},
  {"season": 2, "episode": 5, "scene": 14, "line": "Tell him, not Dwight.", "order": 2818, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 5, "scene": 14, "line": "That is not a very nice thing to say about him.", "order": 2819, "speaker": "Michael Scott"},
  {"season": 2, "episode": 5, "scene": 14, "line": "Tell him to stop.", "order": 2820, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 5, "scene": 14, "line": "Are you kidding?", "order": 2821, "speaker": "Michael Scott"},
  {"season": 2, "episode": 5, "scene": 14, "line": "Quiet, you.", "order": 2822, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 5, "scene": 14, "line": "I agree. He'd land on his feet.", "order": 2823, "speaker": "Michael Scott"},
  {"season": 2, "episode": 5, "scene": 14, "line": "Make him be quiet.", "order": 2824, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 5, "scene": 15, "line": "Those aren't chips and dip.", "order": 2825, "speaker": "Angela Martin"},
  {"season": 2, "episode": 5, "scene": 15, "line": "No, I made brownies.", "order": 2826, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 5, "scene": 15, "line": "Uh!", "order": 2827, "speaker": "Angela Martin"},
  {"season": 2, "episode": 5, "scene": 15, "line": "... What?", "order": 2828, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 5, "scene": 15, "line": "I'm just trying to figure out why you're sabotaging things.", "order": 2829, "speaker": "Angela Martin"},
  {"season": 2, "episode": 5, "scene": 15, "line": "I made brownies.", "order": 2830, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 5, "scene": 15, "line": "And I made cookies. Same category.", "order": 2831, "speaker": "Angela Martin"},
  {"season": 2, "episode": 5, "scene": 16, "line": "I'm guessing Angela's the one in the neighborhood who gives the trick-or-treaters some toothbrushes. Pennies. Walnuts.", "order": 2832, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 5, "scene": 17, "line": "[on phone] Dunder-Mifflin. This is Pam. [listens] Uh, yeah. [snaps her fingers in the air, getting Jim's attention] Just one second. I will, uh, transfer you to our manager, Michael Scott.", "order": 2833, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 5, "scene": 18, "line": "Um... Whoa. [picks up ringing phone][in managerial voice] Michael Scott here. Yes, I am regional manager of this orifice. Mmm hmm. Dwight Schrute is amazing. Yeah. No, he is actually the single greatest employee of his generation. Mm hmm. You know what? I'm gonna tell you what. You hire Dwight K. Schrute, and if he does not meet, nay, exceed every one of your wildest expectations, well then, you can hold me, Michael Gary Scott, personally and financially responsible. Okay. Okay. Okay-kay-kay-kay-kay. Okay.", "order": 2834, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 5, "scene": 19, "line": "Stanley, could you come with me, please.", "order": 2835, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 5, "scene": 19, "line": "No.", "order": 2836, "speaker": "Stanley Hudson"},
  {"season": 2, "episode": 5, "scene": 19, "line": "As Assistant Regional Manager...", "order": 2837, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 5, "scene": 19, "line": "To the.", "order": 2838, "speaker": "Stanley Hudson"},
  {"season": 2, "episode": 5, "scene": 19, "line": "Look! I've got some bad news. You're fired. You need to pack up your things and go. [Stanley laughs.] I'm serious, Stanley. It's over. I'm sorry.", "order": 2839, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 5, "scene": 19, "line": "[laughs, and imitates Donald Trump] You're fired. Get your fingers off my phone.", "order": 2840, "speaker": "Stanley Hudson"},
  {"season": 2, "episode": 5, "scene": 20, "line": "So. How did it go with Stanley? How... how'd he take it?", "order": 2841, "speaker": "Michael Scott"},
  {"season": 2, "episode": 5, "scene": 20, "line": "He wouldn't listen to me", "order": 2842, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 5, "scene": 20, "line": "Ahh, come on.", "order": 2843, "speaker": "Michael Scott"},
  {"season": 2, "episode": 5, "scene": 20, "line": "If you want to fire him, you're going to have to tell him yourself.", "order": 2844, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 5, "scene": 20, "line": "I don't wanna fire Stanley. I never said that. I'm certainly not going to do it myself. Get those big, baleful, eyes staring at me. Yikes. Just, okay, just... [waves Dwight away]", "order": 2845, "speaker": "Michael Scott"},
  {"season": 2, "episode": 5, "scene": 21, "line": "[whispering on the phone] Cumberland Mills?! And how did you get my resume? Oh no, no. I'm very flattered. Don't get me wrong. I'm just not sure that it's my official resume or if it's something that maybe a satisfied customer posted online. What does it say under martial arts training? Oh. Okay, I'm gonna have to supplement that. Could I have your fax number?", "order": 2846, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 5, "scene": 22, "line": "Would I ever leave this company? Look, I'm all about loyalty. In fact, I feel like part of what I'm getting paid for here is my loyalty. But, if there were somewhere else that valued that loyalty more highly, I'm going wherever they value loyalty the most.", "order": 2847, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 5, "scene": 23, "line": "Oh... hey.", "order": 2848, "speaker": "Oscar Martinez"},
  {"season": 2, "episode": 5, "scene": 23, "line": "Oh, your dress is stuck in the back. Kind of just...", "order": 2849, "speaker": "Ryan Howard"},
  {"season": 2, "episode": 5, "scene": 23, "line": "Oh. [fixes his dress]", "order": 2850, "speaker": "Oscar Martinez"},
  {"season": 2, "episode": 5, "scene": 24, "line": "[on the phone] So you got the fax? So why didn't you add it to the res... ? What do you mean? Of course martial arts training is relevant. Oh, excuse me! I know about a billion Asians that would beg to differ. Uh, yeah, I get a little frustrated when I'm dealing with incompetence. Well, you know what? You can go to hell, too. And I will see you there... burning. Fine! Okay... oh wait! So you'll let me know when you've made a decis... [stops and hangs up phone.]", "order": 2851, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 5, "scene": 25, "line": "Jim is really talented. And he should be the one who's getting a better job offer. Like, for real.", "order": 2852, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 5, "scene": 26, "line": "Don't take this the wrong way, but... you should go for that job.", "order": 2853, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 5, "scene": 26, "line": "Um... it's in Maryland.", "order": 2854, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 5, "scene": 26, "line": "Yeah, but I mean, look at the salary. And it's definitely a step up. And a challenge.", "order": 2855, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 5, "scene": 26, "line": "Yeah. Yeah. You know what? Maybe... maybe I will. [starts walking away]", "order": 2856, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 5, "scene": 26, "line": "Jim...", "order": 2857, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 5, "scene": 27, "line": "This is called leveraging an offer. [walks into Michael's office] Michael, can I talk to you for a moment?", "order": 2858, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 5, "scene": 27, "line": "Oh, God.", "order": 2859, "speaker": "Michael Scott"},
  {"season": 2, "episode": 5, "scene": 27, "line": "I just thought you should know that I was just offered a job with better pay, better benefits and a better title at Cumberland Mills.", "order": 2860, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 5, "scene": 27, "line": "Fantastic!", "order": 2861, "speaker": "Michael Scott"},
  {"season": 2, "episode": 5, "scene": 27, "line": "And I turned it down.", "order": 2862, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 5, "scene": 27, "line": "What?! That would've solved all my problems.", "order": 2863, "speaker": "Michael Scott"},
  {"season": 2, "episode": 5, "scene": 27, "line": "Out of loyalty to this company...", "order": 2864, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 5, "scene": 27, "line": "Oh, you idiot.", "order": 2865, "speaker": "Michael Scott"},
  {"season": 2, "episode": 5, "scene": 27, "line": "... so I was hoping to be made Assistant Regional Manager officially.", "order": 2866, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 5, "scene": 27, "line": "If you left, I wouldn't have to fire anybody.", "order": 2867, "speaker": "Michael Scott"},
  {"season": 2, "episode": 5, "scene": 27, "line": "But then you wouldn't have me here.", "order": 2868, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 5, "scene": 27, "line": "Big deal. Oh, it would've worked out so well. Can you get it back?", "order": 2869, "speaker": "Michael Scott"},
  {"season": 2, "episode": 5, "scene": 27, "line": "It's in Maryland.", "order": 2870, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 5, "scene": 27, "line": "You can call. Can you call 'em?", "order": 2871, "speaker": "Michael Scott"},
  {"season": 2, "episode": 5, "scene": 27, "line": "I can't. I... I suppose I coul... no. They never really made me an offer anyway.", "order": 2872, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 5, "scene": 27, "line": "Wohahah! Why are you torturing me?! God.", "order": 2873, "speaker": "Michael Scott"},
  {"season": 2, "episode": 5, "scene": 28, "line": "Honestly, I don't think Michael has the slightest clue of who he's gonna fire. I think he keeps hoping that someone's going to volunteer. Uh, or be run over by a bus before the deadline. But in the end, really, what's going to happen is it's gonna be the first person to give him a dirty look in the hall. And therein lies the true essence of his charisma.", "order": 2874, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 5, "scene": 28, "line": "[clearing his throat and interrupting Jim's talking head] Can I speak to you a minute?", "order": 2875, "speaker": "Michael Scott"},
  {"season": 2, "episode": 5, "scene": 28, "line": "Um... yes.", "order": 2876, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 5, "scene": 29, "line": "Michael, I really didn't mean to...", "order": 2877, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 5, "scene": 29, "line": "Help. Me.", "order": 2878, "speaker": "Michael Scott"},
  {"season": 2, "episode": 5, "scene": 29, "line": "I'm sorry?", "order": 2879, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 5, "scene": 29, "line": "I want you to role play firing me. I want you to fire me, and I will take it.", "order": 2880, "speaker": "Michael Scott"},
  {"season": 2, "episode": 5, "scene": 29, "line": "Oh, you want me to be you?", "order": 2881, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 5, "scene": 29, "line": "Yes.", "order": 2882, "speaker": "Michael Scott"},
  {"season": 2, "episode": 5, "scene": 29, "line": "Okay.", "order": 2883, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 5, "scene": 29, "line": "I want you to be me, and I will be Creed.", "order": 2884, "speaker": "Michael Scott"},
  {"season": 2, "episode": 5, "scene": 29, "line": "Oh, are you firing Creed?", "order": 2885, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 5, "scene": 29, "line": "No, no, no. That's just the first thing... came... in head.", "order": 2886, "speaker": "Michael Scott"},
  {"season": 2, "episode": 5, "scene": 29, "line": "We should switch seats in order to...", "order": 2887, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 5, "scene": 29, "line": "Yes, that's a good idea.", "order": 2888, "speaker": "Michael Scott"},
  {"season": 2, "episode": 5, "scene": 29, "line": "Alright. [they stand up] Excuse me.[They sit down] I'm really sorry, but I have to let you go. And it's purely budgetary. It's not personal...", "order": 2889, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 5, "scene": 29, "line": "Aaaahh! I'm gonna kill myself!", "order": 2890, "speaker": "Michael Scott"},
  {"season": 2, "episode": 5, "scene": 29, "line": "Wow.", "order": 2891, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 5, "scene": 29, "line": "I'm going to kill myself, and it's your fault!", "order": 2892, "speaker": "Michael Scott"},
  {"season": 2, "episode": 5, "scene": 29, "line": "That's an overreaction.", "order": 2893, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 5, "scene": 29, "line": "Corporate is really breathing down my neck. And they're saying this has to be done by the end of the month.", "order": 2894, "speaker": "Michael Scott"},
  {"season": 2, "episode": 5, "scene": 29, "line": "Is this you? Are you being you, or is this Creed? Are you...", "order": 2895, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 5, "scene": 29, "line": "I... this is Creed.", "order": 2896, "speaker": "Michael Scott"},
  {"season": 2, "episode": 5, "scene": 29, "line": "Okay.", "order": 2897, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 5, "scene": 29, "line": "I'm improvising, so just try to keep up. [phone rings]", "order": 2898, "speaker": "Michael Scott"},
  {"season": 2, "episode": 5, "scene": 29, "line": "Oh, hold that thought. Hold that thought.", "order": 2899, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 5, "scene": 29, "line": "And I'm very angry, and I want...", "order": 2900, "speaker": "Michael Scott"},
  {"season": 2, "episode": 5, "scene": 29, "line": "[picks up the phone] Michael Scott here.", "order": 2901, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 5, "scene": 29, "line": "I'm gonna kill you. I'm going to kill you for firing me.", "order": 2902, "speaker": "Michael Scott"},
  {"season": 2, "episode": 5, "scene": 29, "line": "Toby? Mm hmm. [looks back to Michael] I really have to take this Creed, so it was really worth...", "order": 2903, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 5, "scene": 29, "line": "Get off, get off. No, no. OK.. just get off.[sits back down in his chair and waves Jim off.] Just, just... yeah.", "order": 2904, "speaker": "Michael Scott"},
  {"season": 2, "episode": 5, "scene": 30, "line": "What happened?", "order": 2905, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 5, "scene": 30, "line": "It wasn't me.", "order": 2906, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 5, "scene": 30, "line": "Oh. That was like crazy. 'Cause I was...", "order": 2907, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 5, "scene": 30, "line": "Yeah, I know.", "order": 2908, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 5, "scene": 31, "line": "Uh, hey... Creed?", "order": 2909, "speaker": "Michael Scott"},
  {"season": 2, "episode": 5, "scene": 31, "line": "Huh?", "order": 2910, "speaker": "Creed Bratton"},
  {"season": 2, "episode": 5, "scene": 31, "line": "Could I talk to you for a second?", "order": 2911, "speaker": "Michael Scott"},
  {"season": 2, "episode": 5, "scene": 32, "line": "You are great. Very ambitious. And I feel like you want more than this little office has to offer. And I understand that you'd wanna just spread your wings, and fly the coop.", "order": 2912, "speaker": "Michael Scott"},
  {"season": 2, "episode": 5, "scene": 32, "line": "What are you telling me?", "order": 2913, "speaker": "Creed Bratton"},
  {"season": 2, "episode": 5, "scene": 32, "line": "I... we're gonna have to... You... you want something better.", "order": 2914, "speaker": "Michael Scott"},
  {"season": 2, "episode": 5, "scene": 32, "line": "No, I don't. I wanna stay right here.", "order": 2915, "speaker": "Creed Bratton"},
  {"season": 2, "episode": 5, "scene": 32, "line": "No, you wanna leave.", "order": 2916, "speaker": "Michael Scott"},
  {"season": 2, "episode": 5, "scene": 32, "line": "No, I wanna stay here.", "order": 2917, "speaker": "Creed Bratton"},
  {"season": 2, "episode": 5, "scene": 32, "line": "Why... why are you making this so hard?", "order": 2918, "speaker": "Michael Scott"},
  {"season": 2, "episode": 5, "scene": 32, "line": "Um, I think there's a misunderstanding, Michael.", "order": 2919, "speaker": "Creed Bratton"},
  {"season": 2, "episode": 5, "scene": 32, "line": "I think you're right.", "order": 2920, "speaker": "Michael Scott"},
  {"season": 2, "episode": 5, "scene": 32, "line": "Can I go?", "order": 2921, "speaker": "Creed Bratton"},
  {"season": 2, "episode": 5, "scene": 32, "line": "No, of course you can't go. We haven't even started this horrible process of... okay, Creed. I need to let somebody go today. They told me I need to let somebody go. And as much as I think you're a great guy, and I like you, you're... you're, goodbye.", "order": 2922, "speaker": "Michael Scott"},
  {"season": 2, "episode": 5, "scene": 32, "line": "Let's fight it.", "order": 2923, "speaker": "Creed Bratton"},
  {"season": 2, "episode": 5, "scene": 32, "line": "Hmm?", "order": 2924, "speaker": "Michael Scott"},
  {"season": 2, "episode": 5, "scene": 32, "line": "Let's call Jan and fight this thing together like the old days.", "order": 2925, "speaker": "Creed Bratton"},
  {"season": 2, "episode": 5, "scene": 32, "line": "What old days? What are you talking about?", "order": 2926, "speaker": "Michael Scott"},
  {"season": 2, "episode": 5, "scene": 32, "line": "Did you start the paperwork yet?", "order": 2927, "speaker": "Creed Bratton"},
  {"season": 2, "episode": 5, "scene": 32, "line": "It's right here on the desk, yeah.", "order": 2928, "speaker": "Michael Scott"},
  {"season": 2, "episode": 5, "scene": 32, "line": "You don't have to do this, Michael.", "order": 2929, "speaker": "Creed Bratton"},
  {"season": 2, "episode": 5, "scene": 32, "line": "I can't, I can't...", "order": 2930, "speaker": "Michael Scott"},
  {"season": 2, "episode": 5, "scene": 32, "line": "Undo it!", "order": 2931, "speaker": "Creed Bratton"},
  {"season": 2, "episode": 5, "scene": 32, "line": "I can't change anything. This is the way...", "order": 2932, "speaker": "Michael Scott"},
  {"season": 2, "episode": 5, "scene": 32, "line": "No, you have the power to undo it.", "order": 2933, "speaker": "Creed Bratton"},
  {"season": 2, "episode": 5, "scene": 32, "line": "I don't... okay, just listen.", "order": 2934, "speaker": "Michael Scott"},
  {"season": 2, "episode": 5, "scene": 32, "line": "Michael, undo it!", "order": 2935, "speaker": "Creed Bratton"},
  {"season": 2, "episode": 5, "scene": 32, "line": "Don't...", "order": 2936, "speaker": "Michael Scott"},
  {"season": 2, "episode": 5, "scene": 33, "line": "Yeah, I went hunting once. Shot the deer in the leg, had to kill it with a shovel. Took about an hour. Why do you ask?", "order": 2937, "speaker": "Michael Scott"},
  {"season": 2, "episode": 5, "scene": 34, "line": "I have to fire someone today, okay?", "order": 2938, "speaker": "Michael Scott"},
  {"season": 2, "episode": 5, "scene": 34, "line": "Fine. Fire someone else. Fire Devon. He's terrible. I am so much better at my job than Devon.", "order": 2939, "speaker": "Creed Bratton"},
  {"season": 2, "episode": 5, "scene": 34, "line": "Okay, well... I already picked you. And you know that. So, unless I just go through with this, you're always gonna look at me as the guy who almost fired you.", "order": 2940, "speaker": "Michael Scott"},
  {"season": 2, "episode": 5, "scene": 34, "line": "No, no, no, no, no, no. I will forget so fast. You will be my savior. You're they guy who gave me my life back. Thank you. I knew you'd see it my way Michael. God Bless you. You're a fine man.", "order": 2941, "speaker": "Creed Bratton"},
  {"season": 2, "episode": 5, "scene": 34, "line": "Don't...", "order": 2942, "speaker": "Michael Scott"},
  {"season": 2, "episode": 5, "scene": 34, "line": "Listen, you will not regret this either. Devon is terrible; No one's gonna miss him. Good, good, good.", "order": 2943, "speaker": "Creed Bratton"},
  {"season": 2, "episode": 5, "scene": 35, "line": "Devon, could I talk to you for a sec?", "order": 2944, "speaker": "Michael Scott"},
  {"season": 2, "episode": 5, "scene": 36, "line": "Creed's an idiot, you know that.", "order": 2945, "speaker": "Devon"},
  {"season": 2, "episode": 5, "scene": 36, "line": "Well, he...", "order": 2946, "speaker": "Michael Scott"},
  {"season": 2, "episode": 5, "scene": 36, "line": "No, no, no, no, no, no! You had it right the first time.", "order": 2947, "speaker": "Devon"},
  {"season": 2, "episode": 5, "scene": 36, "line": "Well, maybe I did.", "order": 2948, "speaker": "Michael Scott"},
  {"season": 2, "episode": 5, "scene": 36, "line": "Exactly. You gotta go with your gut, man.", "order": 2949, "speaker": "Devon"},
  {"season": 2, "episode": 5, "scene": 36, "line": "Huh. No! I can't, no. I can't go back. I would look like an idiot.", "order": 2950, "speaker": "Michael Scott"},
  {"season": 2, "episode": 5, "scene": 36, "line": "That's why I'm being fired?", "order": 2951, "speaker": "Devon"},
  {"season": 2, "episode": 5, "scene": 36, "line": "No.", "order": 2952, "speaker": "Michael Scott"},
  {"season": 2, "episode": 5, "scene": 36, "line": "So you might not look like an idiot?", "order": 2953, "speaker": "Devon"},
  {"season": 2, "episode": 5, "scene": 36, "line": "No. It was all the stuff that I said. It was the business downturn, the cutbacks, and, and...", "order": 2954, "speaker": "Michael Scott"},
  {"season": 2, "episode": 5, "scene": 36, "line": "This is unbelievable!", "order": 2955, "speaker": "Devon"},
  {"season": 2, "episode": 5, "scene": 36, "line": "I just hope that you and I can remain friends.", "order": 2956, "speaker": "Michael Scott"},
  {"season": 2, "episode": 5, "scene": 37, "line": "Devon, wait, please.", "order": 2957, "speaker": "Michael Scott"},
  {"season": 2, "episode": 5, "scene": 37, "line": "What!", "order": 2958, "speaker": "Devon"},
  {"season": 2, "episode": 5, "scene": 37, "line": "Look, look. In addition to severance, and everything, I want to give you this gift certificate to Chili's. From me. Okay? No hard feelings.", "order": 2959, "speaker": "Michael Scott"},
  {"season": 2, "episode": 5, "scene": 37, "line": "[takes the gift certificate and tears it up] Kevin, Jim, Pam, Kelly, Toby, Oscar, Meredith, Phyllis, Stanley, or the temp. If any of you wanna meet me for a drink, I'm going to be at Poor Richard's. And the rest of you can go to hell!", "order": 2960, "speaker": "Devon"},
  {"season": 2, "episode": 5, "scene": 37, "line": "[watching nearly everyone leave] What about the Halloween party?", "order": 2961, "speaker": "Angela Martin"},
  {"season": 2, "episode": 5, "scene": 38, "line": "Oh, hey, Jim. Wait, stop. Um, I'm sorry... for pushing you towards Cumberland. Seriously, if you left here, I would blow my brains out.", "order": 2962, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 5, "scene": 38, "line": "[motions for her to follow him] Come on.", "order": 2963, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 5, "scene": 39, "line": "That's just a figure of speech, you know? Blow your brains out? Come on. All it really means is that we're friends. Who else is she gonna talk to if I'm gone, right? I mean, if she left, I wouldn't blow my brains out. Of course, I would take that job in Maryland. Because it's double the pay, and soft shell crab just happens to be my favorite food.", "order": 2964, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 5, "scene": 40, "line": "I love Halloween. You know, it's just, it's just fun. Every year, it's just fun. Last Halloween I came as Janet Jackson's boob. It was topical. People got a... a big kick out of it. The year before that, I came as Monica Lewinsky, and I wore a stained dress. The year before that, I also came as Monica Lewinsky. And before that, I was O.J. It was pretty funny. Oh, I wish you were here last year.", "order": 2965, "speaker": "Michael Scott"},
  {"season": 2, "episode": 5, "scene": 41, "line": "[ringing the doorbell of Michael's Condo] Trick or treat!", "order": 2966, "speaker": "Children"},
  {"season": 2, "episode": 5, "scene": 41, "line": "He... Hey, hey, hey, hey! How you doing? Wow! You guys looks great.", "order": 2967, "speaker": "Michael Scott"},
  {"season": 2, "episode": 5, "scene": 41, "line": "I'm a bumble bee.", "order": 2968, "speaker": "Kid"},
  {"season": 2, "episode": 5, "scene": 41, "line": "You look great! And you're a princess?", "order": 2969, "speaker": "Michael Scott"},
  {"season": 2, "episode": 5, "scene": 41, "line": "A fairy princess.", "order": 2970, "speaker": "Kid"},
  {"season": 2, "episode": 5, "scene": 41, "line": "A fairy princess. You're very... .", "order": 2971, "speaker": "Michael Scott"},
  {"season": 2, "episode": 5, "scene": 41, "line": "I'm a lion.", "order": 2972, "speaker": "Kid"},
  {"season": 2, "episode": 5, "scene": 41, "line": "You're a lion. [trying to to open a bag of candy] Wow, I want to hear your, your... Oh! [the bag tears open, spilling all the candy] Oh, okay, that's all yours. That's all yours. Grab it, grab it. You know what? You guys are getting all of these.", "order": 2973, "speaker": "Michael Scott"},
  {"season": 2, "episode": 5, "scene": 45, "line": "I will handle it in the best way possible, but in case he or she goes postal, you will be available to subdue. Am I correct?", "order": 2974, "speaker": "Michael Scott"},
  {"season": 2, "episode": 5, "scene": 45, "line": "Who is it?", "order": 2975, "speaker": "Hank Tate"},
  {"season": 2, "episode": 5, "scene": 45, "line": "I don't know. Hey, are you on our payroll or are you employed by the building?", "order": 2976, "speaker": "Michael Scott"},
  {"season": 2, "episode": 5, "scene": 45, "line": "Building.", "order": 2977, "speaker": "Hank Tate"},
  {"season": 2, "episode": 5, "scene": 45, "line": "Oh, shoot. Okay, well, just have your pepper spray ready.", "order": 2978, "speaker": "Michael Scott"},
  {"season": 2, "episode": 5, "scene": 45, "line": "You're on your own.", "order": 2979, "speaker": "Hank Tate"},
  {"season": 2, "episode": 5, "scene": 46, "line": "All right. All right, this is it. By the time I get back to our floor, I will have decided. [elevator bell dings] Wow, that's a fast elevator.", "order": 2980, "speaker": "Michael Scott"},
  {"season": 2, "episode": 6, "scene": 1, "line": "Where is my desk?", "order": 2981, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 6, "scene": 1, "line": "That is weird.", "order": 2982, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 6, "scene": 1, "line": "This is not funny. This is totally unprofessional.", "order": 2983, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 6, "scene": 1, "line": "Ok, well, you're the one who lost the desk.", "order": 2984, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 6, "scene": 1, "line": "I didn't lose my desk.", "order": 2985, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 6, "scene": 1, "line": "Okay, calm down. Where was the last place you saw it?", "order": 2986, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 6, "scene": 1, "line": "Okay, who moved my desk?", "order": 2987, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 6, "scene": 1, "line": "I think you should retrace your steps.", "order": 2988, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 6, "scene": 1, "line": "Ok, I am going to tell Michael and this entire office will be punished!", "order": 2989, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 6, "scene": 1, "line": "Colder... warmer... little warmer... there you go, ooh, warmer... warmer... warmer... warmer... warmer ... cold, cold, cold, back up... ooh, ooh, warmer, hot, red hot, hot, very hot.", "order": 2990, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 6, "scene": 1, "line": "[In bathroom, answers phone] Dwight Schrute.", "order": 2991, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 6, "scene": 1, "line": "[On the phone with Dwight] Hi, Dwight, um, what sort of discounts are we giving on the 20 lb white model.", "order": 2992, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 6, "scene": 1, "line": "Jim, I've given you this information, like, twenty times.", "order": 2993, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 6, "scene": 1, "line": "I know.", "order": 2994, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 6, "scene": 1, "line": "It's by the ream?", "order": 2995, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 6, "scene": 1, "line": "Uh, yeah, ream.", "order": 2996, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 6, "scene": 1, "line": "...now, $9.78, signs and discounts 7%.", "order": 2997, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 6, "scene": 1, "line": "Ok, thank you, gotta get back to work.", "order": 2998, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 6, "scene": 1, "line": "Wash your hands, Kevin.", "order": 2999, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 6, "scene": 2, "line": "[On the phone] Right, oh let me just check the pricing list. Hold on one second...", "order": 3000, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 6, "scene": 2, "line": "[Also on the phone] Sensei, hello it's Sempai...", "order": 3001, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 6, "scene": 2, "line": "Umm...", "order": 3002, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 6, "scene": 2, "line": "Dwight...", "order": 3003, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 6, "scene": 2, "line": "You know what, let me give you a call right back. I'm going to uh, find it and then I'll call you back, thanks.", "order": 3004, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 6, "scene": 2, "line": "Yes, I just had a ques-... Yes Sensei. Arigatou gozaimashita. Hai.", "order": 3005, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 6, "scene": 2, "line": "Was that your mom?", "order": 3006, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 6, "scene": 2, "line": "No, that was my Sensei.", "order": 3007, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 6, "scene": 2, "line": "Oh, I thought it was your mom.", "order": 3008, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 6, "scene": 2, "line": "I am now Sempai, which is Assistant Sensei.", "order": 3009, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 6, "scene": 2, "line": "Assistant to the Sensei, that's pretty cool.", "order": 3010, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 6, "scene": 2, "line": "Assistant Sensei.", "order": 3011, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 6, "scene": 2, "line": "Ok.", "order": 3012, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 6, "scene": 3, "line": "I am a practitioner of Goju Ru Karate, here in Scranton. My Sensei, Ira, recently promoted me to purple belt, and gave me the duties of a Sempai. Not that a lot of people here in America know what a Sempai is, but it is equally as respected as a Sensei.", "order": 3013, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 6, "scene": 4, "line": "I don't want to stay until seven again this year.", "order": 3014, "speaker": "Stanley Hudson"},
  {"season": 2, "episode": 6, "scene": 4, "line": "I don't really have any control over that Stanley.", "order": 3015, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 6, "scene": 5, "line": "Michael tends to procrastinate a bit whenever he has to do work. Umm, time cards, he has to sign these every Friday. Purchase orders have to be approved at the end of every month. And expense reports, all he has to do is initial these at the end of every quarter. But once a year, it all falls on the same Friday and that's today. I call it the Perfect Storm.", "order": 3016, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 6, "scene": 6, "line": "[singing and tapping on his coffee mug] I don't want to work, I just want to bang on this mug all day.", "order": 3017, "speaker": "Michael Scott"},
  {"season": 2, "episode": 6, "scene": 6, "line": "Did you ask me here for any specific reason?", "order": 3018, "speaker": "Ryan Howard"},
  {"season": 2, "episode": 6, "scene": 6, "line": "Uhh, yes, I did, here's the dizzle. I have a very top secret mission for you. I want you to update all the emergency contact information.", "order": 3019, "speaker": "Michael Scott"},
  {"season": 2, "episode": 6, "scene": 6, "line": "Why is that secret? [Pam knocks and walks into Michael's office]", "order": 3020, "speaker": "Ryan Howard"},
  {"season": 2, "episode": 6, "scene": 6, "line": "[to Pam] Hello, oh God, busy work. Ahh, get away, cretin.", "order": 3021, "speaker": "Michael Scott"},
  {"season": 2, "episode": 6, "scene": 6, "line": "Umm, I put stickers so you know where to sign.", "order": 3022, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 6, "scene": 6, "line": "Yes, thank you. I know where to sign.", "order": 3023, "speaker": "Michael Scott"},
  {"season": 2, "episode": 6, "scene": 6, "line": "It's just last year you...", "order": 3024, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 6, "scene": 6, "line": "Last year they were out of order, weren't they Pam?", "order": 3025, "speaker": "Michael Scott"},
  {"season": 2, "episode": 6, "scene": 6, "line": "Well, the last pick-up for overnight deliveries is at seven. So you need to have them signed by then. Or much earlier.", "order": 3026, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 6, "scene": 6, "line": "Chillax, Pam. Stop Pam-M-S-ing. That's pretty good. Um, actually, I'm sending Ryan on a top secret mission. Tell her what it is.", "order": 3027, "speaker": "Michael Scott"},
  {"season": 2, "episode": 6, "scene": 6, "line": "Updating emergency contacts.", "order": 3028, "speaker": "Ryan Howard"},
  {"season": 2, "episode": 6, "scene": 6, "line": "Well, is that really a priority?", "order": 3029, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 6, "scene": 6, "line": "Is it a priority? Oh I don't know, um, what if there is a tornado, Pam? People's legs are crushed under rubble. 'Please, would you be so kind as to call my wife? No, I can't because we don't have any emergency contact information because Pam said it wasn't a priority.' Think. Think with your head, Pam. Ok, well. She walks out. That's the problem with being a boss is that when you are tough they resent you and when you are cool they walk all over you.", "order": 3030, "speaker": "Michael Scott"},
  {"season": 2, "episode": 6, "scene": 6, "line": "Catch-22.", "order": 3031, "speaker": "Ryan Howard"},
  {"season": 2, "episode": 6, "scene": 6, "line": "Catch-22. Yes. Why don't you give me your contact information to start with, ok, what's your cell?", "order": 3032, "speaker": "Michael Scott"},
  {"season": 2, "episode": 6, "scene": 7, "line": "Uh, Larissa Halpert.", "order": 3033, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 6, "scene": 7, "line": "What's her address? [Ryan's cell phone rings]", "order": 3034, "speaker": "Ryan Howard"},
  {"season": 2, "episode": 6, "scene": 7, "line": "117 Mount Bergin St.", "order": 3035, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 6, "scene": 7, "line": "Hello?", "order": 3036, "speaker": "Ryan Howard"},
  {"season": 2, "episode": 6, "scene": 7, "line": "[in his office on his cell phone, talking in a fake high voice] Hey Ryan. This is Michael Jackson calling from Wonderland.", "order": 3037, "speaker": "Michael Scott"},
  {"season": 2, "episode": 6, "scene": 7, "line": "Do you mean Neverland?", "order": 3038, "speaker": "Ryan Howard"},
  {"season": 2, "episode": 6, "scene": 7, "line": "This is Tito.", "order": 3039, "speaker": "Michael Scott"},
  {"season": 2, "episode": 6, "scene": 7, "line": "What?", "order": 3040, "speaker": "Ryan Howard"},
  {"season": 2, "episode": 6, "scene": 7, "line": "Calling from... [Ryan hangs up]", "order": 3041, "speaker": "Michael Scott"},
  {"season": 2, "episode": 6, "scene": 8, "line": "[Reading Jim's palm] You're major and minor lines cross at a ridge - that sucks.", "order": 3042, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 6, "scene": 8, "line": "You making this up as you go along, aren't you?", "order": 3043, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 6, "scene": 8, "line": "I am just following the website.", "order": 3044, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 6, "scene": 8, "line": "Well, at least I don't have cavities.", "order": 3045, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 6, "scene": 8, "line": "Yes, you have very nice teeth.", "order": 3046, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 6, "scene": 8, "line": "Thanks.", "order": 3047, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 6, "scene": 9, "line": "Who is your emergency contact? [Ryan's phone rings]", "order": 3048, "speaker": "Ryan Howard"},
  {"season": 2, "episode": 6, "scene": 9, "line": "Stacy.", "order": 3049, "speaker": "Kevin Malone"},
  {"season": 2, "episode": 6, "scene": 9, "line": "[looks to see who is calling but doesn't pick up]", "order": 3050, "speaker": "Ryan Howard"},
  {"season": 2, "episode": 6, "scene": 9, "line": "[Taps on the glass in his office to get Ryan's attention] Pick up.", "order": 3051, "speaker": "Michael Scott"},
  {"season": 2, "episode": 6, "scene": 9, "line": "Hello?", "order": 3052, "speaker": "Ryan Howard"},
  {"season": 2, "episode": 6, "scene": 9, "line": "[in a high pitched voice] This is Mike Tyson.", "order": 3053, "speaker": "Michael Scott"},
  {"season": 2, "episode": 6, "scene": 10, "line": "Hey, Dwight. As Sempai, do you think there is ever going to be a day where humans and robots can peacefully co-exist?", "order": 3054, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 6, "scene": 10, "line": "Impossible. The way they're programmed... You're mocking me.", "order": 3055, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 6, "scene": 10, "line": "No I'm not.", "order": 3056, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 6, "scene": 10, "line": "Look, I'm going to offer you a little piece of advice. I'm not afraid to make an example out of you.", "order": 3057, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 6, "scene": 10, "line": "Oh, that's not advice. What advice sounds like is this: umm, don't ever bring your purple belt to work because someone might steal it. [reveals Dwight's purple belt]", "order": 3058, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 6, "scene": 10, "line": "Ok, give that back to me.", "order": 3059, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 6, "scene": 10, "line": "Ok, say please.", "order": 3060, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 6, "scene": 10, "line": "No. That is not a toy.", "order": 3061, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 6, "scene": 10, "line": "Please?", "order": 3062, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 6, "scene": 10, "line": "Please?", "order": 3063, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 6, "scene": 10, "line": "Good, and it absolutely is a toy. Arigatou.", "order": 3064, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 6, "scene": 10, "line": "Arigatou. This is not a toy. This is a message to the entire office so they can see that I am capable of physically dominating them.", "order": 3065, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 6, "scene": 11, "line": "And this is more a ying-yang thing. The 'Michael' all cursive, the 'Scott' all caps. Left brain, right brain. Or, duality of man.", "order": 3066, "speaker": "Michael Scott"},
  {"season": 2, "episode": 6, "scene": 11, "line": "Could you practice on the forms?", "order": 3067, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 6, "scene": 12, "line": "No women or children, unless provoked.", "order": 3068, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 6, "scene": 12, "line": "Ok, Roy?", "order": 3069, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 6, "scene": 12, "line": "Warehouse guy. Doesn't count.", "order": 3070, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 6, "scene": 12, "line": "Ok. Michael? Could you beat up Michael?", "order": 3071, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 6, "scene": 12, "line": "Yeah, yeah, I don't think that would happen.", "order": 3072, "speaker": "Michael Scott"},
  {"season": 2, "episode": 6, "scene": 12, "line": "Because we're friends.", "order": 3073, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 6, "scene": 12, "line": "Because I would kick his ass.", "order": 3074, "speaker": "Michael Scott"},
  {"season": 2, "episode": 6, "scene": 12, "line": "Well, Dwight's a purple belt, so...", "order": 3075, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 6, "scene": 12, "line": "So? I've beaten up black belts.", "order": 3076, "speaker": "Michael Scott"},
  {"season": 2, "episode": 6, "scene": 12, "line": "Uh, how did you know they were black belts?", "order": 3077, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 6, "scene": 12, "line": "They told me. After. You see, I used to run with a very tough crowd. Street Fighter types. Real, real bad people, I'm just lucky I got out.", "order": 3078, "speaker": "Michael Scott"},
  {"season": 2, "episode": 6, "scene": 13, "line": "Is your wife still your contact?", "order": 3079, "speaker": "Ryan Howard"},
  {"season": 2, "episode": 6, "scene": 13, "line": "Um, ex-wife. Yeah. Um, her last name is 'Becker' now.", "order": 3080, "speaker": "Toby Flenderson"},
  {"season": 2, "episode": 6, "scene": 13, "line": "Kay.", "order": 3081, "speaker": "Ryan Howard"},
  {"season": 2, "episode": 6, "scene": 13, "line": "You don't need to write 'ex'.", "order": 3082, "speaker": "Toby Flenderson"},
  {"season": 2, "episode": 6, "scene": 14, "line": "And after that, nobody ever messed with the 'Damn Rascals' ever again.", "order": 3083, "speaker": "Michael Scott"},
  {"season": 2, "episode": 6, "scene": 14, "line": "Sounds tough. When you're a Jet, [starts snapping] you're a Jet all the way, right?", "order": 3084, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 6, "scene": 14, "line": "You were a Jet?", "order": 3085, "speaker": "Michael Scott"},
  {"season": 2, "episode": 6, "scene": 14, "line": "Have you signed the expense reports yet?", "order": 3086, "speaker": "Angela Martin"},
  {"season": 2, "episode": 6, "scene": 14, "line": "Yes, in theory, I have. I just need to cross some t's and dot some i's. Alright, I'm going to be in my office if anybody needs me. [Puts Dwight in a headlock] Hoo-hah. Oh, wow, sleeper hold. That's my rebuttal. Shhh. Hoo. You are the weakest link.", "order": 3087, "speaker": "Michael Scott"},
  {"season": 2, "episode": 6, "scene": 14, "line": "Argggg!", "order": 3088, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 6, "scene": 15, "line": "I'm friends with everybody in this office. We're all best friends - I love everybody here. But sometimes your best friends start coming into work late, and start having dentist appointments that aren't dentist appointments. And that's when it is nice to let them know that you can beat them up.", "order": 3089, "speaker": "Michael Scott"},
  {"season": 2, "episode": 6, "scene": 16, "line": "Just hit me. You'll see.", "order": 3090, "speaker": "Michael Scott"},
  {"season": 2, "episode": 6, "scene": 16, "line": "I can't. I just got a manicure.", "order": 3091, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 6, "scene": 16, "line": "Oh, queer... [realizes he is on camera] eye. Queer eye. That's a good show. Important show. Go ahead. Do it.", "order": 3092, "speaker": "Michael Scott"},
  {"season": 2, "episode": 6, "scene": 16, "line": "Just have Dwight punch you.", "order": 3093, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 6, "scene": 16, "line": "Oh yeah, that would be kind of worthless because I know a ton of fourteen year old girls who can kick his ass.", "order": 3094, "speaker": "Michael Scott"},
  {"season": 2, "episode": 6, "scene": 16, "line": "You know a ton of fourteen year old girls?", "order": 3095, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 6, "scene": 16, "line": "What belt are they?", "order": 3096, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 6, "scene": 16, "line": "Look, Dwight is a wuss. When we rented 'Armageddon'...", "order": 3097, "speaker": "Michael Scott"},
  {"season": 2, "episode": 6, "scene": 16, "line": "No!", "order": 3098, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 6, "scene": 16, "line": "... he cried at the end of it. He did.", "order": 3099, "speaker": "Michael Scott"},
  {"season": 2, "episode": 6, "scene": 16, "line": "Michael, I told you, it was because it was New Year's Eve and it began to snow at exactly midnight.", "order": 3100, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 6, "scene": 16, "line": "Oh, Bruce Willis. Are they going to leave him on the asteroid?", "order": 3101, "speaker": "Michael Scott"},
  {"season": 2, "episode": 6, "scene": 16, "line": "Ok, I'll punch you.", "order": 3102, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 6, "scene": 16, "line": "Ok, here we go. Alright, come on.", "order": 3103, "speaker": "Michael Scott"},
  {"season": 2, "episode": 6, "scene": 16, "line": "Kiyah!", "order": 3104, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 6, "scene": 16, "line": "Fuuuaaaahhhhh... oohhhhh!", "order": 3105, "speaker": "Michael Scott"},
  {"season": 2, "episode": 6, "scene": 17, "line": "Did I want to harm Michael? The one man I've been hired to protect? No, I did not.", "order": 3106, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 6, "scene": 18, "line": "Are you ok? Are you sure you are alright?", "order": 3107, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 6, "scene": 18, "line": "Yeah. [Jim opens office door for Michael] Thank you.", "order": 3108, "speaker": "Michael Scott"},
  {"season": 2, "episode": 6, "scene": 19, "line": "I come from a long line of fighters. My maternal grandfather was the toughest guy I ever knew. World War II veteran. Killed 20 men then spent the rest of the war in an Allied Prison Camp. My father battled blood pressure and obesity all his life. Different kind of fight.", "order": 3109, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 6, "scene": 20, "line": "Ok, he has to be stopped. Please, please, please, please, just ask Michael.", "order": 3110, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 6, "scene": 20, "line": "I don't know.", "order": 3111, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 6, "scene": 20, "line": "Ok, I'll buy you a bag of chips.", "order": 3112, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 6, "scene": 20, "line": "French Onion?", "order": 3113, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 6, "scene": 20, "line": "Obviously.", "order": 3114, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 6, "scene": 20, "line": "Ok.", "order": 3115, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 6, "scene": 20, "line": "Yes.", "order": 3116, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 6, "scene": 20, "line": "[to Kevin, who he is teaching to fight] Take this pen and stab me with it.", "order": 3117, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 6, "scene": 20, "line": "[Pam knocks on his door] Go away.", "order": 3118, "speaker": "Michael Scott"},
  {"season": 2, "episode": 6, "scene": 20, "line": "I just have a quick question.", "order": 3119, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 6, "scene": 20, "line": "I haven't signed them, ok?", "order": 3120, "speaker": "Michael Scott"},
  {"season": 2, "episode": 6, "scene": 20, "line": "No, it's not that. Um, I was just wondering, since I'm probably going to have to stay late, could you ask Dwight to stay late too so he can walk me to my car?", "order": 3121, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 6, "scene": 20, "line": "Come in. Um, Pam, I hate to break this to you but Dwight can't stop you from being mugged. He's just not tough enough.", "order": 3122, "speaker": "Michael Scott"},
  {"season": 2, "episode": 6, "scene": 20, "line": "He's a purple belt. That's really high.", "order": 3123, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 6, "scene": 20, "line": "Oh, I could beat up Dwight. That's ridiculous. I could murder him.", "order": 3124, "speaker": "Michael Scott"},
  {"season": 2, "episode": 6, "scene": 20, "line": "It's just out there, you...", "order": 3125, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 6, "scene": 20, "line": "Oh, so that's what they are saying?", "order": 3126, "speaker": "Michael Scott"},
  {"season": 2, "episode": 6, "scene": 20, "line": "Yeah.", "order": 3127, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 6, "scene": 20, "line": "Ok, alright, where is Dwight?", "order": 3128, "speaker": "Michael Scott"},
  {"season": 2, "episode": 6, "scene": 20, "line": "Uh, Kitchen.", "order": 3129, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 6, "scene": 20, "line": "Ok.", "order": 3130, "speaker": "Michael Scott"},
  {"season": 2, "episode": 6, "scene": 20, "line": "Hi-yah!", "order": 3131, "speaker": "Kelly Kapoor"},
  {"season": 2, "episode": 6, "scene": 20, "line": "Good.", "order": 3132, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 6, "scene": 20, "line": "Wow, that's actually pretty cool Dwight.", "order": 3133, "speaker": "Kelly Kapoor"},
  {"season": 2, "episode": 6, "scene": 20, "line": "Now watch, let me take you from behind.", "order": 3134, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 6, "scene": 20, "line": "What?", "order": 3135, "speaker": "Kelly Kapoor"},
  {"season": 2, "episode": 6, "scene": 20, "line": "Watch out Kelly, he might sucker punch you.", "order": 3136, "speaker": "Michael Scott"},
  {"season": 2, "episode": 6, "scene": 20, "line": "I didn't sucker punch you, Michael.", "order": 3137, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 6, "scene": 20, "line": "No, Really?", "order": 3138, "speaker": "Michael Scott"},
  {"season": 2, "episode": 6, "scene": 20, "line": "In case you remember, I was defending my honor... like a samurai.", "order": 3139, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 6, "scene": 20, "line": "Really? Well, the offer, Dwight, was for one punch which I absorbed. I had no idea that there would be a second punch. So, catch-22.", "order": 3140, "speaker": "Michael Scott"},
  {"season": 2, "episode": 6, "scene": 20, "line": "Ok, fine. Tit for tit. Give it your best shot. Two punches. Go!", "order": 3141, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 6, "scene": 20, "line": "Look, if we were in a bar right now, there would be two punches: me punching you and you hitting the floor.", "order": 3142, "speaker": "Michael Scott"},
  {"season": 2, "episode": 6, "scene": 20, "line": "No, I would block your first punch rendering it ineffective.", "order": 3143, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 6, "scene": 20, "line": "Really?", "order": 3144, "speaker": "Michael Scott"},
  {"season": 2, "episode": 6, "scene": 20, "line": "Yeah.", "order": 3145, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 6, "scene": 20, "line": "You know what? You're just lucky that we are at work right now.", "order": 3146, "speaker": "Michael Scott"},
  {"season": 2, "episode": 6, "scene": 20, "line": "Ooh, what about, uh, Dwight's dojo?", "order": 3147, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 6, "scene": 20, "line": "No, they must have class.", "order": 3148, "speaker": "Michael Scott"},
  {"season": 2, "episode": 6, "scene": 20, "line": "No, it's free during the day. It's fine.", "order": 3149, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 6, "scene": 20, "line": "Look...", "order": 3150, "speaker": "Michael Scott"},
  {"season": 2, "episode": 6, "scene": 20, "line": "I've got the key.", "order": 3151, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 6, "scene": 20, "line": "Michael...", "order": 3152, "speaker": "Toby Flenderson"},
  {"season": 2, "episode": 6, "scene": 20, "line": "Hey, Toby.", "order": 3153, "speaker": "Michael Scott"},
  {"season": 2, "episode": 6, "scene": 20, "line": "Any word on those time cards?", "order": 3154, "speaker": "Toby Flenderson"},
  {"season": 2, "episode": 6, "scene": 20, "line": "I've got an idea: why don't you leave right now. Why don't you walk away from the room, 'kay? Fine. We'll go at lunch. Pam, make an announcement. Figure out carpools.", "order": 3155, "speaker": "Michael Scott"},
  {"season": 2, "episode": 6, "scene": 21, "line": "Um, well, we are all getting excited to see this fight. The Albany branch is working right through lunch to prevent downsizing, but Michael, he decided to extend our lunch by an hour so we could all go down to the dojo and watch him fight Dwight. Fight... Fight, fight, fight, fight, fight, fight, I'm coming, fight...", "order": 3156, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 6, "scene": 22, "line": "I recognize that. That is Japanese for California Roll.", "order": 3157, "speaker": "Michael Scott"},
  {"season": 2, "episode": 6, "scene": 22, "line": "Uh, no, it's not.", "order": 3158, "speaker": "Ira"},
  {"season": 2, "episode": 6, "scene": 22, "line": "I think it is. A guy told me about that.", "order": 3159, "speaker": "Michael Scott"},
  {"season": 2, "episode": 6, "scene": 22, "line": "Actually, it's a symbol for eternal discipline.", "order": 3160, "speaker": "Ira"},
  {"season": 2, "episode": 6, "scene": 22, "line": "Oh.", "order": 3161, "speaker": "Michael Scott"},
  {"season": 2, "episode": 6, "scene": 23, "line": "[Reading Pam's palm, while she has on extremely padded gloves] Wow, that is really interesting.", "order": 3162, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 6, "scene": 23, "line": "What?", "order": 3163, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 6, "scene": 23, "line": "Your love line- I'm just kidding. I can't see anything.", "order": 3164, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 6, "scene": 23, "line": "Well, look closer.", "order": 3165, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 6, "scene": 23, "line": "[Jim moves his head closer and Pam taps him gently in the face] Oh, ok.", "order": 3166, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 6, "scene": 23, "line": "Once point for me.", "order": 3167, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 6, "scene": 23, "line": "[Gently taps Pam on the forehead] Tied up.", "order": 3168, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 6, "scene": 23, "line": "Oh, you're dead.", "order": 3169, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 6, "scene": 23, "line": "What, what are you going to do? Bring it, Beesley. Bring it. Oh yeah, good move. Not such an ultimate fighter now.", "order": 3170, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 6, "scene": 23, "line": "Hey, put me down. Put me down. [Meredith turns and looks at Jim and Pam] Oh my god, hey, put me down. Hey...", "order": 3171, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 6, "scene": 24, "line": "Ok, gentlemen, listen up. After a clean strike to the chest, stomach, or kidneys, I will separate you and award a point. The first person to three wins. Alright?", "order": 3172, "speaker": "Ira"},
  {"season": 2, "episode": 6, "scene": 24, "line": "Yes, Sensei!", "order": 3173, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 6, "scene": 24, "line": "Alotta rules. Alotta rules. On the street we didn't have any rules. Maybe one - no kicks to the groin, home for dinner.", "order": 3174, "speaker": "Michael Scott"},
  {"season": 2, "episode": 6, "scene": 24, "line": "Shi mate!", "order": 3175, "speaker": "Ira"},
  {"season": 2, "episode": 6, "scene": 24, "line": "Hiii! [kicks Michael]", "order": 3176, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 6, "scene": 24, "line": "Hey!", "order": 3177, "speaker": "Michael Scott"},
  {"season": 2, "episode": 6, "scene": 24, "line": "Alright, break.", "order": 3178, "speaker": "Ira"},
  {"season": 2, "episode": 6, "scene": 24, "line": "What the hell was that?", "order": 3179, "speaker": "Michael Scott"},
  {"season": 2, "episode": 6, "scene": 24, "line": "Yes!", "order": 3180, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 6, "scene": 24, "line": "Dwight - awarded a point.", "order": 3181, "speaker": "Ira"},
  {"season": 2, "episode": 6, "scene": 24, "line": "No.", "order": 3182, "speaker": "Michael Scott"},
  {"season": 2, "episode": 6, "scene": 24, "line": "Eat it!", "order": 3183, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 6, "scene": 24, "line": "Alright, that's the way you want it.", "order": 3184, "speaker": "Michael Scott"},
  {"season": 2, "episode": 6, "scene": 24, "line": "Two more.", "order": 3185, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 6, "scene": 24, "line": "Play dirty, huh? Ok, game on, man.", "order": 3186, "speaker": "Michael Scott"},
  {"season": 2, "episode": 6, "scene": 24, "line": "Sweep the leg.", "order": 3187, "speaker": "Kevin Malone"},
  {"season": 2, "episode": 6, "scene": 24, "line": "I'm comin' atcha man. Ok, purple belt, ok. I got him.", "order": 3188, "speaker": "Michael Scott"},
  {"season": 2, "episode": 6, "scene": 24, "line": "No.", "order": 3189, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 6, "scene": 24, "line": "I got his pants.", "order": 3190, "speaker": "Michael Scott"},
  {"season": 2, "episode": 6, "scene": 24, "line": "It was my pants.", "order": 3191, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 6, "scene": 24, "line": "No points for pants.", "order": 3192, "speaker": "Ira"},
  {"season": 2, "episode": 6, "scene": 24, "line": "Dwight, you have... No, you have something... God, you look like such an idiot! [Lots of yelling and flailing of arms by Michael and Dwight]", "order": 3193, "speaker": "Michael Scott"},
  {"season": 2, "episode": 6, "scene": 24, "line": "Clean single kick, gentlemen.", "order": 3194, "speaker": "Ira"},
  {"season": 2, "episode": 6, "scene": 24, "line": "Go on, I dare you to kick there again. Kick there again, I dare ya.", "order": 3195, "speaker": "Michael Scott"},
  {"season": 2, "episode": 6, "scene": 24, "line": "Ok, break. Break.", "order": 3196, "speaker": "Ira"},
  {"season": 2, "episode": 6, "scene": 24, "line": "No holding.", "order": 3197, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 6, "scene": 24, "line": "You can't see. You can't see. Good boy. Good boy. Great boy. Two points, three points, four points. I win. I win. [Michael is using his head guard to hit Dwight] Eight points. Nine points. [Begins to hock a loogie]", "order": 3198, "speaker": "Michael Scott"},
  {"season": 2, "episode": 6, "scene": 24, "line": "No, stop it! Come on! Michael.", "order": 3199, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 6, "scene": 24, "line": "Open your mouth.", "order": 3200, "speaker": "Michael Scott"},
  {"season": 2, "episode": 6, "scene": 24, "line": "No, Michael!", "order": 3201, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 6, "scene": 25, "line": "You talkin' to me? You talkin' to me? 'Raging Bull.' Pacino. Oh, I want that footage. I want it. I need it. Ah, I have to get back to work. I have lots of work... Oh, oh check this out. Come here. [Michael opens his blinds and looks at Ryan in the parking lot] There he is. Mr. Temp. Having lunch by the car. Let us play with him. This'll be hilarious. [Calls Ryan on the phone, Ryan doesn't pick up after seeing that Michael is calling] Oh, we're playing phone tag.", "order": 3202, "speaker": "Michael Scott"},
  {"season": 2, "episode": 6, "scene": 26, "line": "Seven new messages. First New Message. [Michael's voice] 'Hi, Ryan. This is Saddam Hussein.' Next new message. 'Hi, Ryan. This is your girlfriend... and I'm mad!'", "order": 3203, "speaker": "Ryan Howard's Voicemail"},
  {"season": 2, "episode": 6, "scene": 27, "line": "My emergency contact is Todd Packer. Todd F. Packer. Do you know what the F. stands for?", "order": 3204, "speaker": "Michael Scott"},
  {"season": 2, "episode": 6, "scene": 27, "line": "Fudge?", "order": 3205, "speaker": "Ryan Howard"},
  {"season": 2, "episode": 6, "scene": 27, "line": "[knock at the door] Yeah... uh, come in. Oh, hey Karate Kid. The Hillary Swank version. Hi. How are ya?", "order": 3206, "speaker": "Michael Scott"},
  {"season": 2, "episode": 6, "scene": 27, "line": "I need to change my emergency contact information from Michael Scott.", "order": 3207, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 6, "scene": 27, "line": "Ok, to what?", "order": 3208, "speaker": "Ryan Howard"},
  {"season": 2, "episode": 6, "scene": 27, "line": "Just put 'The Hospital.' Contact number: just put 9-1-1. [Dwight leaves]", "order": 3209, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 6, "scene": 27, "line": "He is such a sore loser. You heard, obviously, that I mopped the floor with him this afternoon. You know what, um, do yourself a favor and just keep me as his contact and I will call the hospital. Cut out the middle man.", "order": 3210, "speaker": "Michael Scott"},
  {"season": 2, "episode": 6, "scene": 28, "line": "Later Jim.", "order": 3211, "speaker": "Kevin Malone"},
  {"season": 2, "episode": 6, "scene": 28, "line": "Later, Kev. [Puts French Onion Potato Chips on Pam's desk] Have a good weekend.", "order": 3212, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 6, "scene": 28, "line": "Yeah, you too.", "order": 3213, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 6, "scene": 29, "line": "[Knock at the door] Yeah.", "order": 3214, "speaker": "Michael Scott"},
  {"season": 2, "episode": 6, "scene": 29, "line": "I have the emergency contacts.", "order": 3215, "speaker": "Ryan Howard"},
  {"season": 2, "episode": 6, "scene": 29, "line": "Yeah, just throw them on the chair. I'll take it from here. So, whatcha up to this weekend?", "order": 3216, "speaker": "Michael Scott"},
  {"season": 2, "episode": 6, "scene": 29, "line": "Uh, hanging out with some friends, probably.", "order": 3217, "speaker": "Ryan Howard"},
  {"season": 2, "episode": 6, "scene": 29, "line": "If you're doing anything crazy, give me a shout.", "order": 3218, "speaker": "Michael Scott"},
  {"season": 2, "episode": 6, "scene": 29, "line": "Yeah, alright, I'll um, see you Monday.", "order": 3219, "speaker": "Ryan Howard"},
  {"season": 2, "episode": 6, "scene": 29, "line": "Alright, bye.", "order": 3220, "speaker": "Michael Scott"},
  {"season": 2, "episode": 6, "scene": 30, "line": "Dwight?", "order": 3221, "speaker": "Michael Scott"},
  {"season": 2, "episode": 6, "scene": 30, "line": "Michael, did you finish yet?", "order": 3222, "speaker": "Angela Martin"},
  {"season": 2, "episode": 6, "scene": 30, "line": "This close. Dwight, may I speak with you for a minute?", "order": 3223, "speaker": "Michael Scott"},
  {"season": 2, "episode": 6, "scene": 30, "line": "I'm busy.", "order": 3224, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 6, "scene": 30, "line": "Well, [points at himself] busier. Making the time.", "order": 3225, "speaker": "Michael Scott"},
  {"season": 2, "episode": 6, "scene": 30, "line": "Michael, can't your conversation wait till Monday.", "order": 3226, "speaker": "Stanley Hudson"},
  {"season": 2, "episode": 6, "scene": 30, "line": "We want to go home.", "order": 3227, "speaker": "Toby Flenderson"},
  {"season": 2, "episode": 6, "scene": 30, "line": "Well, you don't even have anyone to go home to, Toby.", "order": 3228, "speaker": "Michael Scott"},
  {"season": 2, "episode": 6, "scene": 30, "line": "The shipping place closes in a half hour.", "order": 3229, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 6, "scene": 30, "line": "I know, but I've been carrying the load on my back all day, and if everybody would just chip in a little bit, it'd might help me out. What do you say? Let's gangbang this thing and go home. Good? Dwight.", "order": 3230, "speaker": "Michael Scott"},
  {"season": 2, "episode": 6, "scene": 31, "line": "This is illegal.", "order": 3231, "speaker": "Angela Martin"},
  {"season": 2, "episode": 6, "scene": 31, "line": "I don't care.", "order": 3232, "speaker": "Stanley Hudson"},
  {"season": 2, "episode": 6, "scene": 32, "line": "I have been testing you the entire day. Did you know that?", "order": 3233, "speaker": "Michael Scott"},
  {"season": 2, "episode": 6, "scene": 32, "line": "Of course.", "order": 3234, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 6, "scene": 32, "line": "And I am happy to say that you have passed. So effective immediately I am promoting you from Assistant to the Regional Manager to Assistant Regional Manager.", "order": 3235, "speaker": "Michael Scott"},
  {"season": 2, "episode": 6, "scene": 32, "line": "Michael, I don't know...", "order": 3236, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 6, "scene": 32, "line": "I know, I know, I know, I wouldn't be offering it if I didn't think you could handle it.", "order": 3237, "speaker": "Michael Scott"},
  {"season": 2, "episode": 6, "scene": 32, "line": "I can handle it. I can. Wow. So I guess this will just be my office.", "order": 3238, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 6, "scene": 32, "line": "No, no, title change only.", "order": 3239, "speaker": "Michael Scott"},
  {"season": 2, "episode": 6, "scene": 32, "line": "I'll have Pam send out a memo.", "order": 3240, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 6, "scene": 32, "line": "No, no. Three month probationary period. Let's not tell anybody about this right now.", "order": 3241, "speaker": "Michael Scott"},
  {"season": 2, "episode": 6, "scene": 32, "line": "Just a formality.", "order": 3242, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 6, "scene": 32, "line": "Absolutely but not really.", "order": 3243, "speaker": "Michael Scott"},
  {"season": 2, "episode": 6, "scene": 32, "line": "Michael, I have so much to learn from you.", "order": 3244, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 6, "scene": 32, "line": "Yes you do.", "order": 3245, "speaker": "Michael Scott"},
  {"season": 2, "episode": 6, "scene": 32, "line": "Thank you, Sensei.", "order": 3246, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 6, "scene": 32, "line": "And, ditto.", "order": 3247, "speaker": "Michael Scott"},
  {"season": 2, "episode": 6, "scene": 33, "line": "I told Dwight that there is honor in losing. Which, as we all know, is completely ridiculous, but there is, however, honor in making a loser feel better which is what I just did for Dwight. Would I rather be feared or loved? Um, easy. Both. I want people to be afraid of how much they love me. And I think I proved that today at the dojo.", "order": 3248, "speaker": "Michael Scott"},
  {"season": 2, "episode": 6, "scene": 35, "line": "[Dwight's pager goes off] Whoa, whoa, whoa. Hold on. Hold on. Sorry, just one second.", "order": 3249, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 6, "scene": 35, "line": "Dwight, you can't use your pager in here, I told you.", "order": 3250, "speaker": "Master"},
  {"season": 2, "episode": 6, "scene": 35, "line": "Okay, I just...", "order": 3251, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 6, "scene": 35, "line": "Dwight...", "order": 3252, "speaker": "Master"},
  {"season": 2, "episode": 6, "scene": 35, "line": "It's a sales call.", "order": 3253, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 6, "scene": 35, "line": "Ten push-ups. Ten push-ups!", "order": 3254, "speaker": "Master"},
  {"season": 2, "episode": 6, "scene": 35, "line": "I can... Yes, sensei.", "order": 3255, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 6, "scene": 36, "line": "Do I feel bad that I haven't bonded with the other students? No, I'm not there to make friends. I'm there to attack people.", "order": 3256, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 6, "scene": 42, "line": "Alyssa? I guess she's technically the senpai. But nobody really respects her. The only reason she got into regionals was because her competition was a bunch of 13-year-old girls. Put me in that division. Let's see how she does.", "order": 3257, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 6, "scene": 43, "line": "God, you look like such an idiot. [both yelling] [grunting]", "order": 3258, "speaker": "Michael Scott"},
  {"season": 2, "episode": 7, "scene": 1, "line": "[entering office] Hey, have they left for the big meeting yet? I've got Michael's lucky tie.", "order": 3259, "speaker": "Ryan Howard"},
  {"season": 2, "episode": 7, "scene": 1, "line": "No. They're in the conference room.", "order": 3260, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 7, "scene": 1, "line": "Good.", "order": 3261, "speaker": "Ryan Howard"},
  {"season": 2, "episode": 7, "scene": 1, "line": "Wait, are those Michael's Levis?", "order": 3262, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 7, "scene": 1, "line": "Yeah, who dry-cleans jeans?", "order": 3263, "speaker": "Ryan Howard"},
  {"season": 2, "episode": 7, "scene": 2, "line": "Michael and his jeans. He gets in them, and I'm not exactly sure what happens. But I can tell you, he loves the way he looks in those jeans. I know that's why he started casual Fridays.", "order": 3264, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 7, "scene": 3, "line": "[to Ryan] I'll take those. Thanks. [throws jeans under her desk]", "order": 3265, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 7, "scene": 4, "line": "This is a projection of the county's needs...", "order": 3266, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 7, "scene": 4, "line": "Wow, graphs and charts, somebody's really been doing their homework. Looks like USA Today.", "order": 3267, "speaker": "Michael Scott"},
  {"season": 2, "episode": 7, "scene": 4, "line": "Thirteen schools, uh, two hospitals...", "order": 3268, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 7, "scene": 5, "line": "So this possible client they're talking about, actually a big deal. It's Lackawanna County. Our whole county. And if we get this, they may not have to downsize our branch. And I could work here for years. And years. [groan] Years.", "order": 3269, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 7, "scene": 6, "line": "So when we get to the Radisson, I'd like to, um-", "order": 3270, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 7, "scene": 6, "line": "I changed it. To Chili's.", "order": 3271, "speaker": "Michael Scott"},
  {"season": 2, "episode": 7, "scene": 6, "line": "Excuse me?", "order": 3272, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 7, "scene": 6, "line": "Radisson just gives out this vibe, 'Oh, I'm doing business at the Radisson'. It's kind of snooty. So.", "order": 3273, "speaker": "Michael Scott"},
  {"season": 2, "episode": 7, "scene": 6, "line": "You had no right to do that, Michael.", "order": 3274, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 7, "scene": 6, "line": "Here's the thing. Chili's is the new golf course. It's where business happens. Small Businessman Magazine.", "order": 3275, "speaker": "Michael Scott"},
  {"season": 2, "episode": 7, "scene": 6, "line": "It said that.", "order": 3276, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 7, "scene": 6, "line": "It will. I sent it in. Letter to the editor.", "order": 3277, "speaker": "Michael Scott"},
  {"season": 2, "episode": 7, "scene": 6, "line": "Alright. But you will let me run this meeting.", "order": 3278, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 7, "scene": 6, "line": "Uh huh, uh huh. [under his breath] Power trip.", "order": 3279, "speaker": "Michael Scott"},
  {"season": 2, "episode": 7, "scene": 6, "line": "What?", "order": 3280, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 7, "scene": 7, "line": "She had done a background check on me, she had it printed out.", "order": 3281, "speaker": "Oscar Martinez"},
  {"season": 2, "episode": 7, "scene": 7, "line": "No...", "order": 3282, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 7, "scene": 7, "line": "Yeah. And she was asking me about stuff, line by line, while we were having dinner.", "order": 3283, "speaker": "Oscar Martinez"},
  {"season": 2, "episode": 7, "scene": 7, "line": "That is unbelievable.", "order": 3284, "speaker": "Toby Flenderson"},
  {"season": 2, "episode": 7, "scene": 7, "line": "What is going on?", "order": 3285, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 7, "scene": 7, "line": "We are doing worst first dates.", "order": 3286, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 7, "scene": 7, "line": "Oh my God, I win! Ok, it was a minor league hockey game. He brought his brother, and when I went to the bathroom, the game ended and they forgot about me.", "order": 3287, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 7, "scene": 7, "line": "Ok, that's a joke.", "order": 3288, "speaker": "Oscar Martinez"},
  {"season": 2, "episode": 7, "scene": 7, "line": "No, they had to come back for me.", "order": 3289, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 7, "scene": 7, "line": "Wait, when was this?", "order": 3290, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 7, "scene": 7, "line": "Umm... it was not that long ago.", "order": 3291, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 7, "scene": 7, "line": "Wait, not Roy. Say it's not your fiance. [laughs]", "order": 3292, "speaker": "Kelly Kapoor"},
  {"season": 2, "episode": 7, "scene": 8, "line": "I always knew Pam has refused to go to sports games with Roy, but I never knew why. Interesting.", "order": 3293, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 7, "scene": 9, "line": "Ok, let's do this thing. [to Pam] Wish us luck.", "order": 3294, "speaker": "Michael Scott"},
  {"season": 2, "episode": 7, "scene": 9, "line": "Good luck, Michael. Good luck, Jan.", "order": 3295, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 7, "scene": 9, "line": "Thank you.", "order": 3296, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 7, "scene": 9, "line": "[under his breath] Kiss ass. Ok, probably going to go late tonight. Burning the midnight tequila. So, I think you could all just take off now.", "order": 3297, "speaker": "Michael Scott"},
  {"season": 2, "episode": 7, "scene": 9, "line": "Michael, shouldn't take more than an hour.", "order": 3298, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 7, "scene": 9, "line": "Well...", "order": 3299, "speaker": "Michael Scott"},
  {"season": 2, "episode": 7, "scene": 9, "line": "Do you always shut down the entire office when you leave for an hour?", "order": 3300, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 7, "scene": 9, "line": "No, no. That would not be efficient. Actually, they just don't get very much work done when I'm not here. [Jan stares at Michael] That's not true. I know how to delegate, and they do more work done when I'm not here. Not more. The same amount of work is done, whether I am here or not. [another Jan stare] Hey, everybody, listen up. This is what we're gonna do. You sit tight, until I return. Sound good? Doesn't matter, it's an order. Follow it blindly, mwahahaha, ok? Alright, ciao. [to Oscar] Adios!", "order": 3301, "speaker": "Michael Scott"},
  {"season": 2, "episode": 7, "scene": 10, "line": "So which way is Chili's?", "order": 3302, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 7, "scene": 10, "line": "Uh, I'll drive.", "order": 3303, "speaker": "Michael Scott"},
  {"season": 2, "episode": 7, "scene": 10, "line": "Oh, no, that's alright. I wanna leave straight from there.", "order": 3304, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 7, "scene": 10, "line": "It's just a couple blocks away, so... boy, you really don't know Scranton, do you?", "order": 3305, "speaker": "Michael Scott"},
  {"season": 2, "episode": 7, "scene": 10, "line": "I know Scranton.", "order": 3306, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 7, "scene": 10, "line": "At all!", "order": 3307, "speaker": "Michael Scott"},
  {"season": 2, "episode": 7, "scene": 10, "line": "Alright.", "order": 3308, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 7, "scene": 10, "line": "You ever been to Scranton, Jan? Dar de-", "order": 3309, "speaker": "Michael Scott"},
  {"season": 2, "episode": 7, "scene": 10, "line": "If it's a couple blocks away-", "order": 3310, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 7, "scene": 10, "line": "Dar de dar.", "order": 3311, "speaker": "Michael Scott"},
  {"season": 2, "episode": 7, "scene": 10, "line": "Ok.", "order": 3312, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 7, "scene": 11, "line": "Jan Levinson-Gould. Jan is cold. If she was sitting across from you on a train and she wasn't moving, you might think she was dead.", "order": 3313, "speaker": "Michael Scott"},
  {"season": 2, "episode": 7, "scene": 12, "line": "We should come up with a signal of some sort.", "order": 3314, "speaker": "Michael Scott"},
  {"season": 2, "episode": 7, "scene": 12, "line": "Why would we need a signal?", "order": 3315, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 7, "scene": 12, "line": "Well, in case one of us gets into trouble, the other one can signal-", "order": 3316, "speaker": "Michael Scott"},
  {"season": 2, "episode": 7, "scene": 12, "line": "What kinda trouble are you planning on getting in, Michael?", "order": 3317, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 7, "scene": 12, "line": "Well, I... it could be either of us.", "order": 3318, "speaker": "Michael Scott"},
  {"season": 2, "episode": 7, "scene": 12, "line": "You're gonna let me do the talking, we agreed on that.", "order": 3319, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 7, "scene": 12, "line": "Yeees.", "order": 3320, "speaker": "Michael Scott"},
  {"season": 2, "episode": 7, "scene": 13, "line": "Hello? Christian?", "order": 3321, "speaker": "Michael Scott"},
  {"season": 2, "episode": 7, "scene": 13, "line": "Yes.", "order": 3322, "speaker": "Christian"},
  {"season": 2, "episode": 7, "scene": 13, "line": "Thought that was you. Hi. Michael Scott. This is Jan Levinson-Gould.", "order": 3323, "speaker": "Michael Scott"},
  {"season": 2, "episode": 7, "scene": 13, "line": "Just Jan Levinson.", "order": 3324, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 7, "scene": 13, "line": "No Gould?", "order": 3325, "speaker": "Michael Scott"},
  {"season": 2, "episode": 7, "scene": 13, "line": "No. [To Christian] Thank you very much for meeting with us. Have you been waiting long?", "order": 3326, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 7, "scene": 13, "line": "No, not long.", "order": 3327, "speaker": "Christian"},
  {"season": 2, "episode": 7, "scene": 13, "line": "Uh, Jan, what happened?", "order": 3328, "speaker": "Michael Scott"},
  {"season": 2, "episode": 7, "scene": 13, "line": "Michael.", "order": 3329, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 7, "scene": 13, "line": "Is Gould dead? What uh-", "order": 3330, "speaker": "Michael Scott"},
  {"season": 2, "episode": 7, "scene": 13, "line": "Michael, we got divorced, ok? [to Christian] I'm so sorry. Excuse me.", "order": 3331, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 7, "scene": 13, "line": "Wow, you're kidding me! Do you wanna talk about?", "order": 3332, "speaker": "Michael Scott"},
  {"season": 2, "episode": 7, "scene": 13, "line": "Michael. [to hostess] Uh, could we have a table for three, please?", "order": 3333, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 7, "scene": 13, "line": "When did this happen?", "order": 3334, "speaker": "Michael Scott"},
  {"season": 2, "episode": 7, "scene": 13, "line": "We're in a meeting.", "order": 3335, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 7, "scene": 13, "line": "Ok.", "order": 3336, "speaker": "Michael Scott"},
  {"season": 2, "episode": 7, "scene": 13, "line": "This way, please.", "order": 3337, "speaker": "Hostess"},
  {"season": 2, "episode": 7, "scene": 13, "line": "Christian.", "order": 3338, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 7, "scene": 13, "line": "Alright, after you.", "order": 3339, "speaker": "Michael Scott"},
  {"season": 2, "episode": 7, "scene": 13, "line": "Thank you.", "order": 3340, "speaker": "Christian"},
  {"season": 2, "episode": 7, "scene": 13, "line": "[mouths 'Wow' to the camera]", "order": 3341, "speaker": "Michael Scott"},
  {"season": 2, "episode": 7, "scene": 14, "line": "I thought we could start by going over the needs of the county.", "order": 3342, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 7, "scene": 14, "line": "Right. Well, Lackawanna County has not been immune to the slow economic growth over the past five years. So for us, the name of the game is budget reduction-", "order": 3343, "speaker": "Christian"},
  {"season": 2, "episode": 7, "scene": 14, "line": "Awesome blossom.", "order": 3344, "speaker": "Michael Scott"},
  {"season": 2, "episode": 7, "scene": 14, "line": "What?", "order": 3345, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 7, "scene": 14, "line": "[to Christian] I think we should share an Awesome Blossom, what do you say? They are awesome. Want to, Christian, blossom?", "order": 3346, "speaker": "Michael Scott"},
  {"season": 2, "episode": 7, "scene": 14, "line": "Sure.", "order": 3347, "speaker": "Christian"},
  {"season": 2, "episode": 7, "scene": 14, "line": "Ok, it's done. Actually, [turns around] Megan, may we have an Awesome Blossom, please, extra awesome? Now it's done.", "order": 3348, "speaker": "Michael Scott"},
  {"season": 2, "episode": 7, "scene": 14, "line": "So-", "order": 3349, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 7, "scene": 14, "line": "I heard a-", "order": 3350, "speaker": "Michael Scott"},
  {"season": 2, "episode": 7, "scene": 14, "line": "If you have a-", "order": 3351, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 7, "scene": 14, "line": "Very very funny joke the other day. Wanna hear it?", "order": 3352, "speaker": "Michael Scott"},
  {"season": 2, "episode": 7, "scene": 14, "line": "Christian, you don't have to listen to this.", "order": 3353, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 7, "scene": 14, "line": "It's ok, I like jokes.", "order": 3354, "speaker": "Christian"},
  {"season": 2, "episode": 7, "scene": 14, "line": "Ok.", "order": 3355, "speaker": "Michael Scott"},
  {"season": 2, "episode": 7, "scene": 14, "line": "Just the one.", "order": 3356, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 7, "scene": 14, "line": "Just one joke. Ok. Well, if it's just gonna be one, I will think of a different joke. Umm... let's see... choo choo choo.", "order": 3357, "speaker": "Michael Scott"},
  {"season": 2, "episode": 7, "scene": 15, "line": "Dunder-Mifflin, this is Pam.", "order": 3358, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 7, "scene": 15, "line": "Pam, it's Michael. I need you to go into my office and check some data for me.", "order": 3359, "speaker": "Michael Scott"},
  {"season": 2, "episode": 7, "scene": 16, "line": "[to Michael on speakerphone] Ok, you want me to read 'em?", "order": 3360, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 7, "scene": 16, "line": "Yes.", "order": 3361, "speaker": "Michael Scott"},
  {"season": 2, "episode": 7, "scene": 16, "line": "Ok. Um, a fisherman is walking down Fifth Avenue walking an animal behind him-", "order": 3362, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 7, "scene": 16, "line": "No.", "order": 3363, "speaker": "Michael Scott"},
  {"season": 2, "episode": 7, "scene": 16, "line": "When-", "order": 3364, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 7, "scene": 16, "line": "Nope. Told it. Not as good as you think. Pick another one.", "order": 3365, "speaker": "Michael Scott"},
  {"season": 2, "episode": 7, "scene": 16, "line": "Ok. There's a transcript between a naval ship-", "order": 3366, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 7, "scene": 16, "line": "Oh ho ho, yea! Bingo! And a lighthouse. Yes. That is hysterical. Could you start that one from the beginning?", "order": 3367, "speaker": "Michael Scott"},
  {"season": 2, "episode": 7, "scene": 16, "line": "Sure. There's a transcript between a naval ship and a lighthouse.", "order": 3368, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 7, "scene": 17, "line": "Is this real? [Pam dumps Michael's screenplay on Jim's desk]", "order": 3369, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 7, "scene": 17, "line": "It is a screenplay. Starring himself.", "order": 3370, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 7, "scene": 17, "line": "Agent Michael Scarn.", "order": 3371, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 7, "scene": 17, "line": "Of the FBI.", "order": 3372, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 7, "scene": 17, "line": "How long is this? [flips through pages] Oh, Pam. Good work! Oop, wait, stop. Drawings.", "order": 3373, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 7, "scene": 17, "line": "What is that?", "order": 3374, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 7, "scene": 17, "line": "Oh, those are drawings. In case the writing didn't really put a picture in your head. And there he is, in the flesh, Agent Michael Scarn. Now we know what he looks like.", "order": 3375, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 7, "scene": 18, "line": "First guy says 'Well, I'm an astronaut, so I drive a Saturn'. And the second guy says, 'Well, I am a pimp, so I drive a cheap Escort'. And the third guy says 'I gotcha both beat, I'm a proctologist, so I drive a brown Probe'.", "order": 3376, "speaker": "Michael Scott"},
  {"season": 2, "episode": 7, "scene": 18, "line": "Ohhh no! [laughs] Oh my God, that's funny! I almost had Awesome Blossom coming out of my nose!", "order": 3377, "speaker": "Christian"},
  {"season": 2, "episode": 7, "scene": 18, "line": "[to waitress] Excuse me, could I have a vodka tonic, please?", "order": 3378, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 7, "scene": 19, "line": "Do we all have our copy of 'Threat Level: Midnight', by Michael Scott?", "order": 3379, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 7, "scene": 19, "line": "Yeah, yeah.", "order": 3380, "speaker": "Everyone"},
  {"season": 2, "episode": 7, "scene": 19, "line": "Alright, let's get this started. I'm gonna be reading the action descriptions, and Phyllis, I would like you to play Catherine Zeta Jones.", "order": 3381, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 7, "scene": 19, "line": "That's the character's name?", "order": 3382, "speaker": "Phyllis Lapin"},
  {"season": 2, "episode": 7, "scene": 19, "line": "Oh yeah-", "order": 3383, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 7, "scene": 19, "line": "Ok, you guys should not be doing this.", "order": 3384, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 7, "scene": 19, "line": "Why not, Dwight? This is a movie. I mean, this is for all of America to enjoy.", "order": 3385, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 7, "scene": 19, "line": "You took something that doesn't belong to you.", "order": 3386, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 7, "scene": 19, "line": "Dwight-", "order": 3387, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 7, "scene": 19, "line": "Brought it in here-", "order": 3388, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 7, "scene": 19, "line": "Do you want to play-", "order": 3389, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 7, "scene": 19, "line": "Made copies of it-", "order": 3390, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 7, "scene": 19, "line": "The lead role of Agent Michael Scarn?", "order": 3391, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 7, "scene": 20, "line": "[making the mouth on his tie talk] Yum! Yum yum yum! [Christian laughs] That's delicious! I love it!", "order": 3392, "speaker": "Michael Scott"},
  {"season": 2, "episode": 7, "scene": 20, "line": "We would probably be upset with ourselves if we went this whole night without talking business, so, Dunder-Mifflin can provide a level of personal service to the county that the warehouse chains just can't match.", "order": 3393, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 7, "scene": 20, "line": "Well, we are out to save money.", "order": 3394, "speaker": "Christian"},
  {"season": 2, "episode": 7, "scene": 20, "line": "What's the bottom line?", "order": 3395, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 7, "scene": 20, "line": "Bop! Be blah bop, be boo boo bop.", "order": 3396, "speaker": "Michael Scott"},
  {"season": 2, "episode": 7, "scene": 21, "line": "That's why I wanted a signal, between us, so that I wouldn't have to just shout non-sense words. That's her fault.", "order": 3397, "speaker": "Michael Scott"},
  {"season": 2, "episode": 7, "scene": 22, "line": "Did somebody say 'baby back ribs'? Hmmm? Hmmmmm?", "order": 3398, "speaker": "Michael Scott"},
  {"season": 2, "episode": 7, "scene": 22, "line": "I don't think Christian has time for that.", "order": 3399, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 7, "scene": 22, "line": "I have time.", "order": 3400, "speaker": "Christian"},
  {"season": 2, "episode": 7, "scene": 22, "line": "[singing] I want my baby back, baby back, baby back [Christian laughs]", "order": 3401, "speaker": "Michael Scott"},
  {"season": 2, "episode": 7, "scene": 22, "line": "[singing] I want my baby back, baby back, baby back-", "order": 3402, "speaker": "Michael Scott & Christian"},
  {"season": 2, "episode": 7, "scene": 22, "line": "[singing] Chili's baby back ribs...", "order": 3403, "speaker": "Michael Scott"},
  {"season": 2, "episode": 7, "scene": 23, "line": "[reading the screenplay] Inside the FBI, Agent Michael Scarn sits with his feet up on his desk. Catherine Zeta Jones enters.", "order": 3404, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 7, "scene": 23, "line": "Sir, you have some messages.", "order": 3405, "speaker": "Phyllis Lapin"},
  {"season": 2, "episode": 7, "scene": 23, "line": "Not now!", "order": 3406, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 7, "scene": 23, "line": "They're important.", "order": 3407, "speaker": "Phyllis Lapin"},
  {"season": 2, "episode": 7, "scene": 23, "line": "Ok, what are they?", "order": 3408, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 7, "scene": 23, "line": "First message is: 'I love you'. That's from me.", "order": 3409, "speaker": "Phyllis Lapin"},
  {"season": 2, "episode": 7, "scene": 23, "line": "Not in a thousand years, Catherine. We work together. And get off my desk!", "order": 3410, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 7, "scene": 24, "line": "Yes, I have acted before. I was in a production of 'Oklahoma' in the seventh grade. I played the part of Mutey the Mailman. They had too many kids, so they made up roles like that. I was good.", "order": 3411, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 7, "scene": 25, "line": "If it isn't my old partner, Samuel L. Chang.", "order": 3412, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 7, "scene": 25, "line": "Agent Michael Scarn, you lost some weight.", "order": 3413, "speaker": "Ryan Howard"},
  {"season": 2, "episode": 7, "scene": 25, "line": "Thank you for noticing. Now keep me company for one more mission. [Pam gets up to talk to Roy]", "order": 3414, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 7, "scene": 25, "line": "Hey, uh, I have to work late.", "order": 3415, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 7, "scene": 25, "line": "[looks around conference room] You're joking right?", "order": 3416, "speaker": "Roy Anderson"},
  {"season": 2, "episode": 7, "scene": 25, "line": "Michael Scarn takes out a nine-millimeter gun and shoots the-", "order": 3417, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 7, "scene": 25, "line": "Pow! Pow! Pow!", "order": 3418, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 7, "scene": 25, "line": "Hahaha, Agent Michael Scarn, you so funny. Word.", "order": 3419, "speaker": "Ryan Howard"},
  {"season": 2, "episode": 7, "scene": 26, "line": "Michael's movie? Two thumbs down. [Smiles] Heh.", "order": 3420, "speaker": "Kevin Malone"},
  {"season": 2, "episode": 7, "scene": 27, "line": "A man sitting several seats down, who has a gold face, turns to Michael Scarn. [out of character] Uh... Ooh, Oscar, you wanna play Goldenface?", "order": 3421, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 7, "scene": 27, "line": "Mr. Scarn, perhaps you would be more comfortable in my private jet?", "order": 3422, "speaker": "Oscar Martinez"},
  {"season": 2, "episode": 7, "scene": 27, "line": "Yes, perhaps I would, Goldenface. Sam, get my luggage.", "order": 3423, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 7, "scene": 27, "line": "I forget it, brutha.", "order": 3424, "speaker": "Ryan Howard"},
  {"season": 2, "episode": 7, "scene": 27, "line": "Samuel, you are such an idiot, you are the worst assistant ever. And you're disgusting, Dwigt. [out of character] Wait, who's Dwigt?", "order": 3425, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 7, "scene": 28, "line": "Here's what we think happened. Michael's sidekick, who all through the movie is this complete idiot who's causing the downfall of the United States, was originally named Dwight. But then Michael changed it to Samuel L. Chang using a search and replace, but that doesn't work on misspelled words, leaving behind one Dwigt. And Dwight figured it out. Oops.", "order": 3426, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 7, "scene": 29, "line": "D-W-I-G-H-T.", "order": 3427, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 7, "scene": 30, "line": "Ok, you know what? I am done with this. That's it, the end.", "order": 3428, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 7, "scene": 30, "line": "Well, some of us wanna keep reading, so-", "order": 3429, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 7, "scene": 30, "line": "Uh, you don't speak for everyone, Jim. Ok, announcement. My uncle bought me some fireworks. Anyone who wants to see a real show, come with me outside now.", "order": 3430, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 7, "scene": 30, "line": "That's actually a good idea. We'll all take a brief intermission. [To Pam] Hey, are you hungry?", "order": 3431, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 7, "scene": 30, "line": "Yeah.", "order": 3432, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 7, "scene": 30, "line": "Yeah?", "order": 3433, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 7, "scene": 31, "line": "So after watching my mom go through so much pain, I decided to keep that promise, that I made to her, and take care of her.", "order": 3434, "speaker": "Christian"},
  {"season": 2, "episode": 7, "scene": 31, "line": "Woo, well, this brings us to Jan. Truth or Dare? Tell us about your divorce. Ohh, ohh.", "order": 3435, "speaker": "Michael Scott"},
  {"season": 2, "episode": 7, "scene": 31, "line": "Oh no, Michael, Michael, please. No, really.", "order": 3436, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 7, "scene": 31, "line": "Oh, so you're not gonna play? She's not playing.", "order": 3437, "speaker": "Michael Scott"},
  {"season": 2, "episode": 7, "scene": 31, "line": "It's not fair.", "order": 3438, "speaker": "Christian"},
  {"season": 2, "episode": 7, "scene": 31, "line": "She's not playing the game.", "order": 3439, "speaker": "Michael Scott"},
  {"season": 2, "episode": 7, "scene": 31, "line": "We'd been fighting for a while-", "order": 3440, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 7, "scene": 31, "line": "Check please.", "order": 3441, "speaker": "Michael Scott"},
  {"season": 2, "episode": 7, "scene": 31, "line": "He didn't want kids, but I knew that going into it. But he also knew that I did. I guess I thought that he would change his mind; he thought that I would change mine.", "order": 3442, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 7, "scene": 31, "line": "You didn't.", "order": 3443, "speaker": "Christian"},
  {"season": 2, "episode": 7, "scene": 31, "line": "I was stupid.", "order": 3444, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 7, "scene": 31, "line": "No.", "order": 3445, "speaker": "Michael Scott & Christian"},
  {"season": 2, "episode": 7, "scene": 31, "line": "No, you were not stupid. Gould was stupid. Right?", "order": 3446, "speaker": "Michael Scott"},
  {"season": 2, "episode": 7, "scene": 31, "line": "That's right.", "order": 3447, "speaker": "Christian"},
  {"season": 2, "episode": 7, "scene": 31, "line": "You know?", "order": 3448, "speaker": "Michael Scott"},
  {"season": 2, "episode": 7, "scene": 31, "line": "You were really brave! You, you put your arms out there, you slit your wrists.", "order": 3449, "speaker": "Christian"},
  {"season": 2, "episode": 7, "scene": 31, "line": "It's true.", "order": 3450, "speaker": "Michael Scott"},
  {"season": 2, "episode": 7, "scene": 31, "line": "You said 'World, this is my blood! It's red, just like yours. So love me!'", "order": 3451, "speaker": "Christian"},
  {"season": 2, "episode": 7, "scene": 32, "line": "I had plans to meet a friend tonight. Which I had to cancel. But this is cool, too. I'm not a complainer.", "order": 3452, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 7, "scene": 33, "line": "[Pam lights a candle] Wow.", "order": 3453, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 7, "scene": 33, "line": "For the bugs.", "order": 3454, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 7, "scene": 33, "line": "Nice. That's excellent, because bugs love my famous grilled cheese sandwich.", "order": 3455, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 7, "scene": 33, "line": "Yes... nice! I can't remember the last time someone made me dinner.", "order": 3456, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 7, "scene": 34, "line": "Right down the street?", "order": 3457, "speaker": "Christian"},
  {"season": 2, "episode": 7, "scene": 34, "line": "Uh huh, Kenneth Road, born and raised. Spent my whole life right here in Lackawanna County and I do not intend on movin'. I know this place. I know how many hospitals we have, I know how many schools we have. It's home, you know? I know the challenges this county's up against. Here's the thing about those discount suppliers. They don't care. They come in, they undercut everything, and they run us out of business, and then, once we're all gone, they jack up the prices.", "order": 3458, "speaker": "Michael Scott"},
  {"season": 2, "episode": 7, "scene": 34, "line": "I know.", "order": 3459, "speaker": "Christian"},
  {"season": 2, "episode": 7, "scene": 34, "line": "It's bad.", "order": 3460, "speaker": "Michael Scott"},
  {"season": 2, "episode": 7, "scene": 34, "line": "It's terrible.", "order": 3461, "speaker": "Christian"},
  {"season": 2, "episode": 7, "scene": 34, "line": "It, you know what, it really is.", "order": 3462, "speaker": "Michael Scott"},
  {"season": 2, "episode": 7, "scene": 34, "line": "Uh- [Michael signals for her to shh]", "order": 3463, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 7, "scene": 34, "line": "I don't know. I guess I could give you guys our business, but you have to meet me half way, ok, because they're expecting me to make cuts.", "order": 3464, "speaker": "Christian"},
  {"season": 2, "episode": 7, "scene": 34, "line": "Well, corporate's gonna go ballistic, but, uh, you think we could Jan?", "order": 3465, "speaker": "Michael Scott"},
  {"season": 2, "episode": 7, "scene": 35, "line": "So, I guess I'll see you in [looks at watch] ten hours.", "order": 3466, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 7, "scene": 35, "line": "What are you going to do with your time off?", "order": 3467, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 7, "scene": 35, "line": "Travel. I've been looking forward to it. It's gonna be... really nice. Gonna find myself.", "order": 3468, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 7, "scene": 35, "line": "[points to Jim's iPod] You have new music?", "order": 3469, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 7, "scene": 35, "line": "Yeah. [Pam puts her hand out for an earbud] Definitely.", "order": 3470, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 7, "scene": 36, "line": "[waving to Christian] See ya.", "order": 3471, "speaker": "Michael Scott"},
  {"season": 2, "episode": 7, "scene": 36, "line": "Bye... thanks. [pumps fist] Yes!", "order": 3472, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 7, "scene": 36, "line": "We did it!", "order": 3473, "speaker": "Michael Scott"},
  {"season": 2, "episode": 7, "scene": 36, "line": "We got it!", "order": 3474, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 7, "scene": 36, "line": "Nailed it. Nailed it! Come here.", "order": 3475, "speaker": "Michael Scott"},
  {"season": 2, "episode": 7, "scene": 36, "line": "I am really- [Michael kisses Jan] Thrilled. [Michael and Jan kiss again] Let's go.", "order": 3476, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 7, "scene": 36, "line": "What!?", "order": 3477, "speaker": "Michael Scott"},
  {"season": 2, "episode": 7, "scene": 36, "line": "Let's go.", "order": 3478, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 7, "scene": 36, "line": "Goin'. Ok. Where we goin'? Doesn't matter. Goin' to the go go. [nervous laugh] Oh-ok.", "order": 3479, "speaker": "Michael Scott"},
  {"season": 2, "episode": 7, "scene": 37, "line": "[waking up on office couch] Michael? Michael? [goes into Michael's office] Michael? [looks out Michael's window] His car's not in the parking lot. I should check the accident reports. [taxi pulls into Dunder-Mifflin parking lot] Who's this? Jan?", "order": 3480, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 7, "scene": 38, "line": "Morning, Pam. Hey.", "order": 3481, "speaker": "Michael Scott"},
  {"season": 2, "episode": 7, "scene": 39, "line": "No, nothing happened. I-I swear, nothing happened. What, I'm, totally being serious. A gentleman does not kiss and tell, and neither do I. [laughs] No, seriously, guys, I'm not, I don't want to go into it at all. It's off limits. Fine, I took her back to her hotel and we made out for a little while. It was great. I mean she told me about her divorce, we talked for about five hours, she fell asleep on my arm. So.", "order": 3482, "speaker": "Michael Scott"},
  {"season": 2, "episode": 7, "scene": 40, "line": "Hello, Dwight.", "order": 3483, "speaker": "Michael Scott"},
  {"season": 2, "episode": 7, "scene": 40, "line": "Did you do her?", "order": 3484, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 7, "scene": 40, "line": "Who.", "order": 3485, "speaker": "Michael Scott"},
  {"season": 2, "episode": 7, "scene": 40, "line": "Jan Levinson-Gould.", "order": 3486, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 7, "scene": 40, "line": "Uh, no, no, no Gould.", "order": 3487, "speaker": "Michael Scott"},
  {"season": 2, "episode": 7, "scene": 40, "line": "Did you do her?", "order": 3488, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 7, "scene": 40, "line": "This is none of your affair because she is your boss-", "order": 3489, "speaker": "Michael Scott"},
  {"season": 2, "episode": 7, "scene": 40, "line": "And she is your boss.", "order": 3490, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 7, "scene": 40, "line": "And she is a woman. She is a strong, soft, thoughtful, sexy woman. And you know what? I don't think that I can sit here and let you talk about her that way without me defending her honor. [to camera] Jan, I defend your honor. [to Dwight] Is that all?", "order": 3491, "speaker": "Michael Scott"},
  {"season": 2, "episode": 7, "scene": 41, "line": "Jan didn't come back for her car last night.", "order": 3492, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 7, "scene": 41, "line": "What!?", "order": 3493, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 7, "scene": 41, "line": "Could it be that Agent Michael Scarn has finally found his Catherine Zeta?", "order": 3494, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 7, "scene": 41, "line": "Oh, I don't know... [Jim laughs, phone rings] Oh my God. This is Jan's cell.", "order": 3495, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 7, "scene": 41, "line": "No way.", "order": 3496, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 7, "scene": 41, "line": "Dunder-Mifflin, this is Pam.", "order": 3497, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 7, "scene": 42, "line": "I know we have to register as a consensual sexual relationship with HR. My question: do I do it as the man? Does she do it as my superior? I don't know. That leads to other issues that we may have in our relationship. It's, uh, [phone rings] Excuse me. Hello? Hi! Just talking about you. The camera? No. Uh huh. How's traffic? I miss you. What. Ok. Well, if it was a mistake, it was a wonderful mistake. No. [to camera] Would you excuse me? [to Jan] No, I did not intentionally get you drunk. Um hmm. No, no. [goes under his desk] This is just a fight. This is just a first fight of many fights we're gonna have. Right. No. Wha-so-I don't understand, you wanna see other people. Only other people. Wh-why, ok, I think you're still a little bit drunk [to camera which is now under desk] Excuse me? Excuse me?! [to Jan] I think you're, yes, why don't you just come back here, go to the hotel, have a few drinks and-no, no. I didn't slip you something!", "order": 3498, "speaker": "Michael Scott"},
  {"season": 2, "episode": 7, "scene": 43, "line": "Some might even say that we had our first date last night.", "order": 3499, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 7, "scene": 43, "line": "Oh, really?", "order": 3500, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 7, "scene": 43, "line": "Really.", "order": 3501, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 7, "scene": 43, "line": "Why might some say that?", "order": 3502, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 7, "scene": 43, "line": "Cause there was dinner, by candlelight.", "order": 3503, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 7, "scene": 43, "line": "Uh hmm.", "order": 3504, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 7, "scene": 43, "line": "Dinner and a show, if you include Michael's movie. [Pam nods reluctantly] And there was dancing and fireworks. Pretty good date.", "order": 3505, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 7, "scene": 43, "line": "We didn't dance.", "order": 3506, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 7, "scene": 43, "line": "You're right, we didn't dance. It was more like, swaying. But still romantic.", "order": 3507, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 7, "scene": 43, "line": "Swaying isn't dancing.", "order": 3508, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 7, "scene": 43, "line": "Least I didn't leave you at a high school hockey game.", "order": 3509, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 7, "scene": 43, "line": "I have some faxes to get out.", "order": 3510, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 7, "scene": 43, "line": "Oh, come on, Pam. I-", "order": 3511, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 7, "scene": 44, "line": "Ok, we didn't dance. I was totally joking anyway. I mean, it's not really a date if the girl goes home to her fiance. Right?", "order": 3512, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 8, "scene": 1, "line": "[bouncing on an exercise ball] You should get one of these.", "order": 3513, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 8, "scene": 1, "line": "No. Thank you.", "order": 3514, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 8, "scene": 1, "line": "Do you even know what this is? It is a fitness orb and it has completely changed my life. Forget everything you thought you knew about ab workouts.", "order": 3515, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 8, "scene": 1, "line": "Done.", "order": 3516, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 8, "scene": 1, "line": "This ab workout is specifically designed to strengthen your core. [knocks things around Jim's desk] Sorry.", "order": 3517, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 8, "scene": 1, "line": "S'ok.", "order": 3518, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 8, "scene": 1, "line": "Numerous health benefits, strengthens your back, better performance in sports, more enjoyable sex.", "order": 3519, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 8, "scene": 1, "line": "You're not having sex.", "order": 3520, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 8, "scene": 1, "line": "Plus, improves your reflexes [knocks over more stuff] see, I would have caught that.", "order": 3521, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 8, "scene": 1, "line": "Ok, you know what, uh, how much is that?", "order": 3522, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 8, "scene": 1, "line": "It's only twenty-five bucks.", "order": 3523, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 8, "scene": 1, "line": "Wow. Um, ok. [pops Dwight's orb with scissors]", "order": 3524, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 8, "scene": 2, "line": "Pam, could I see you in my office?", "order": 3525, "speaker": "Michael Scott"},
  {"season": 2, "episode": 8, "scene": 3, "line": "It's performance review day, company-wide. Last year, my performance review started with Michael asking me what my hopes and dreams were, and it ended with him telling me he could bench-press 190 pounds. So, I don't really know what to expect.", "order": 3526, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 8, "scene": 4, "line": "Pam, you're trustworthy-", "order": 3527, "speaker": "Michael Scott"},
  {"season": 2, "episode": 8, "scene": 4, "line": "Thank you.", "order": 3528, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 8, "scene": 4, "line": "And a woman-", "order": 3529, "speaker": "Michael Scott"},
  {"season": 2, "episode": 8, "scene": 4, "line": "Oh, no.", "order": 3530, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 8, "scene": 4, "line": "And I want you to listen to a voicemail from my boss. [Jan on recording] 'Michael, it's Jan. I guess I missed you. I'll, uh, be there this afternoon for performance reviews. I hope it's understood that that will be our only topic of discussion. See you soon.' First impressions?", "order": 3531, "speaker": "Michael Scott"},
  {"season": 2, "episode": 8, "scene": 4, "line": "Uh, just off the top... I think she'll be here this afternoon.", "order": 3532, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 8, "scene": 5, "line": "My boss is coming in today, the lovely Jan Levinson-Gould will, well, no Gould. The Gould has been [makes slashing neck hand motion] swack, divorced. Um, the awkward part is that this will be the first time that we'll be seeing each other since, well, uh, it was really nothing. We just sort of got caught up in the moment. The vulnerable divorcee gives herself to the understanding, with rugged good-looks, office manager. Just, uh, she didn't want it to continue for some reason. It, we both, I didn't want it, we both didn't want it to continue. Was not professional. Um, when people say something's mutual, it never is. But this was mutual.", "order": 3533, "speaker": "Michael Scott"},
  {"season": 2, "episode": 8, "scene": 6, "line": "[playing Jan's message] 'I guess I missed you.' I guess I missed you. So, she misses me?", "order": 3534, "speaker": "Michael Scott"},
  {"season": 2, "episode": 8, "scene": 6, "line": "She missed you.", "order": 3535, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 8, "scene": 6, "line": "But then she goes on to say 'that will be our only topic of discussion'. That doesn't mean anything, those are just words.", "order": 3536, "speaker": "Michael Scott"},
  {"season": 2, "episode": 8, "scene": 6, "line": "I have one idea of what it means.", "order": 3537, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 8, "scene": 6, "line": "Ok. Yeah, what, what?", "order": 3538, "speaker": "Michael Scott"},
  {"season": 2, "episode": 8, "scene": 6, "line": "Well I don't think you're gonna be very happy with this.", "order": 3539, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 8, "scene": 6, "line": "Ohhh, great. Well, now I'm in a terrible mood. Let's do your performance review-", "order": 3540, "speaker": "Michael Scott"},
  {"season": 2, "episode": 8, "scene": 6, "line": "Because she's conflicted. She has to be professional, but she's fighting feelings... for you.", "order": 3541, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 8, "scene": 6, "line": "Ah, why, that's great news? That, that, then why would, why would I not like that?", "order": 3542, "speaker": "Michael Scott"},
  {"season": 2, "episode": 8, "scene": 6, "line": "Um, just cause, that, you work together, and it might be awkward.", "order": 3543, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 8, "scene": 6, "line": "Oh, wow, wow. Alright, let's listen to that again. [plays Jan's message] 'Michael, it's Jan. I guess I missed you'.", "order": 3544, "speaker": "Michael Scott"},
  {"season": 2, "episode": 8, "scene": 7, "line": "Oh, hey, listen, Jim. Here's a little tip for your performance review.", "order": 3545, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 8, "scene": 7, "line": "Ok.", "order": 3546, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 8, "scene": 7, "line": "Tell Michael that we should be stocking more of the double-tabbed manila file folders.", "order": 3547, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 8, "scene": 7, "line": "We don't have double-tabbed manila file folders.", "order": 3548, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 8, "scene": 7, "line": "Oh, yes, we do.", "order": 3549, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 8, "scene": 7, "line": "No, we don't.", "order": 3550, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 8, "scene": 7, "line": "Yeah, it's a new product. So, you should just suggest that to him and he'll be sure to give you a raise.", "order": 3551, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 8, "scene": 7, "line": "Alright... well, I'm not asking for a raise. I'm gonna actually be asking for a pay decrease.", "order": 3552, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 8, "scene": 7, "line": "Uh, that is so stupid. What if he gives it to you?", "order": 3553, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 8, "scene": 7, "line": "I win.", "order": 3554, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 8, "scene": 7, "line": "Ugh, you know what? I am going to zone you out for the rest of today. I need to stay focused, and I don't have to see you tomorrow or Sunday and please don't call me, and we'll see how things go on Monday. Uh, stupid.", "order": 3555, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 8, "scene": 7, "line": "Wait, wait; one thing. Uh, by tomorrow, you mean Saturday, right?", "order": 3556, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 8, "scene": 7, "line": "Uh, duh.", "order": 3557, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 8, "scene": 7, "line": "Duh.", "order": 3558, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 8, "scene": 8, "line": "Today is Thursday. But Dwight thinks that it's Friday. And that's what I'll be working on this afternoon.", "order": 3559, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 8, "scene": 9, "line": "Sometimes women say more in their pauses than they say in their words.", "order": 3560, "speaker": "Stanley Hudson"},
  {"season": 2, "episode": 8, "scene": 9, "line": "Really?", "order": 3561, "speaker": "Michael Scott"},
  {"season": 2, "episode": 8, "scene": 9, "line": "Oh, yes. Let's listen to it again. And this time, really listen to the pauses.", "order": 3562, "speaker": "Stanley Hudson"},
  {"season": 2, "episode": 8, "scene": 9, "line": "God, Stanley, that's frickin' brilliant. How do you know that? Did you learn that on the streets? Sorry.", "order": 3563, "speaker": "Michael Scott"},
  {"season": 2, "episode": 8, "scene": 9, "line": "Oh, no, that's ok. I did learn it on the streets. On the ghetto, in fact.", "order": 3564, "speaker": "Stanley Hudson"},
  {"season": 2, "episode": 8, "scene": 9, "line": "No kidding.", "order": 3565, "speaker": "Michael Scott"},
  {"season": 2, "episode": 8, "scene": 10, "line": "It's all about my bonus.", "order": 3566, "speaker": "Stanley Hudson"},
  {"season": 2, "episode": 8, "scene": 11, "line": "Michael and Jan definitely made out.", "order": 3567, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 8, "scene": 11, "line": "Ohh...", "order": 3568, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 8, "scene": 11, "line": "Maybe more.", "order": 3569, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 8, "scene": 11, "line": "Eck!... Oh! Also, it is Thursday, but Dwight thinks it's Friday. So, keep that goin'.", "order": 3570, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 8, "scene": 11, "line": "Oh, yea!", "order": 3571, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 8, "scene": 12, "line": "Good work, Stanley. Great performance review. Stanley in the house, everybody. Woo! Angela, your turn.", "order": 3572, "speaker": "Michael Scott"},
  {"season": 2, "episode": 8, "scene": 13, "line": "I actually look forward to performance reviews. I did the youth beauty pageant circuit. And I enjoyed that quite a bit. I really enjoy being judged. I believe I hold up very well to even severe scrutiny.", "order": 3573, "speaker": "Angela Martin"},
  {"season": 2, "episode": 8, "scene": 14, "line": "Michael?", "order": 3574, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 8, "scene": 14, "line": "Yeah?", "order": 3575, "speaker": "Michael Scott"},
  {"season": 2, "episode": 8, "scene": 14, "line": "Jan's on the phone for you.", "order": 3576, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 8, "scene": 14, "line": "Oh; Angela, you were totally satisfactory this year.", "order": 3577, "speaker": "Michael Scott"},
  {"season": 2, "episode": 8, "scene": 15, "line": "Interesting. Jan is calling me. Maybe it wasn't so mutual after all. [puts Jan on speakerphone] Yeah?", "order": 3578, "speaker": "Michael Scott"},
  {"season": 2, "episode": 8, "scene": 15, "line": "Michael.", "order": 3579, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 8, "scene": 15, "line": "Jan! To what do I owe this pleasure?", "order": 3580, "speaker": "Michael Scott"},
  {"season": 2, "episode": 8, "scene": 15, "line": "I am returning your many calls.", "order": 3581, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 8, "scene": 15, "line": "Well, hello to you, too. Um, yeah, I was just um, I just wanted to get some closure on uh, what transpired between us at the meeting we had in the parking lot of the Chili's.", "order": 3582, "speaker": "Michael Scott"},
  {"season": 2, "episode": 8, "scene": 15, "line": "No. No, we won't be discussing that, Michael. The only things I wanna talk about during your performance review are your concrete ideas to improve your branch.", "order": 3583, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 8, "scene": 15, "line": "Well, surely this uh, review is a formality because of what happened uh, at our meeting in the parking lot of Chili's.", "order": 3584, "speaker": "Michael Scott"},
  {"season": 2, "episode": 8, "scene": 15, "line": "Uh, your review is anything but a formality, Michael.", "order": 3585, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 8, "scene": 15, "line": "Oh.", "order": 3586, "speaker": "Michael Scott"},
  {"season": 2, "episode": 8, "scene": 15, "line": "I expect you to forget anything that you think may have happened between us and exhibit completely professional behavior.", "order": 3587, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 8, "scene": 15, "line": "Been thinking about you.", "order": 3588, "speaker": "Michael Scott"},
  {"season": 2, "episode": 8, "scene": 15, "line": "Ok, that is an example of completely unprofessional behavior.", "order": 3589, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 8, "scene": 15, "line": "Um, I don't see how that's unprofessional. Just-", "order": 3590, "speaker": "Michael Scott"},
  {"season": 2, "episode": 8, "scene": 15, "line": "Michael.", "order": 3591, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 8, "scene": 15, "line": "Yep.", "order": 3592, "speaker": "Michael Scott"},
  {"season": 2, "episode": 8, "scene": 15, "line": "Are the cameras with you...", "order": 3593, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 8, "scene": 15, "line": "No.", "order": 3594, "speaker": "Michael Scott"},
  {"season": 2, "episode": 8, "scene": 15, "line": "...in your office?", "order": 3595, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 8, "scene": 15, "line": "They are not. Yes, they are. [Jan hangs up] That's my girlfriend.", "order": 3596, "speaker": "Michael Scott"},
  {"season": 2, "episode": 8, "scene": 16, "line": "I heard they made out and had sex.", "order": 3597, "speaker": "Kevin Malone"},
  {"season": 2, "episode": 8, "scene": 16, "line": "No, they just made out. That's it.", "order": 3598, "speaker": "Oscar Martinez"},
  {"season": 2, "episode": 8, "scene": 16, "line": "Well, I heard they made out", "order": 3599, "speaker": "Kevin Malone"},
  {"season": 2, "episode": 8, "scene": 16, "line": "Don't talk about it. Office romances are nobody's business but the people involved.", "order": 3600, "speaker": "Angela Martin"},
  {"season": 2, "episode": 8, "scene": 16, "line": "Romances?", "order": 3601, "speaker": "Kevin Malone"},
  {"season": 2, "episode": 8, "scene": 17, "line": "Pam, I have ideas on a daily basis. I know I do. I have a clear memory of telling people my ideas. Um, is there any chance you wrote any of my ideas down? In a folder? A 'Michael-idea' folder?", "order": 3602, "speaker": "Michael Scott"},
  {"season": 2, "episode": 8, "scene": 17, "line": "Sorry.", "order": 3603, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 8, "scene": 17, "line": "That's unfortunate. How 'bout the suggestion box? There's tons of ideas in there.", "order": 3604, "speaker": "Michael Scott"},
  {"season": 2, "episode": 8, "scene": 17, "line": "What suggestion box?", "order": 3605, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 8, "scene": 17, "line": "The suggestion box that I put out, and people could be put in suggestions anonymously? Maybe there's prizes?", "order": 3606, "speaker": "Michael Scott"},
  {"season": 2, "episode": 8, "scene": 17, "line": "Oh, yeah. Uh, I think I remember that from back from when I first started.", "order": 3607, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 8, "scene": 17, "line": "Why don't you find it and tell people to get theirs... never mind, I'll tell them. Hello, everybody? Yeah, uh, attention, please. Jan Levinson's coming, very soon, and so, we're going to have our weekly suggestion box meeting, so you can all get your constructive compliments in a.s.a.p.", "order": 3608, "speaker": "Michael Scott"},
  {"season": 2, "episode": 8, "scene": 17, "line": "Don't you mean constructive criticism?", "order": 3609, "speaker": "Ryan Howard"},
  {"season": 2, "episode": 8, "scene": 17, "line": "What did I say?", "order": 3610, "speaker": "Michael Scott"},
  {"season": 2, "episode": 8, "scene": 17, "line": "You said 'constructive complements'; that doesn't make any sense.", "order": 3611, "speaker": "Kelly Kapoor"},
  {"season": 2, "episode": 8, "scene": 17, "line": "Well, Kelly, that was neither constructive nor a compliment, so maybe you should stop criticizing my English and start making some suggestions. 'K?", "order": 3612, "speaker": "Michael Scott"},
  {"season": 2, "episode": 8, "scene": 18, "line": "[on phone] Hey, Dan, this is Jim, and it is about 11:15, and I wanted to know what you were up to tomorrow, which is the fifteenth, and that is a...", "order": 3613, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 8, "scene": 18, "line": "Saturday.", "order": 3614, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 8, "scene": 18, "line": "[pumps fist] ...Saturday, so just let me know what you're doing tomorrow, Saturday, for lunch. Ok, talk to you soon.", "order": 3615, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 8, "scene": 19, "line": "[on phone] We'll address this in the meeting then. Ok. Ok, bye-bye. [to Pam] Could you please tell Michael that I'm here?", "order": 3616, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 8, "scene": 19, "line": "Sure.", "order": 3617, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 8, "scene": 19, "line": "Hi, Jan. How are you?", "order": 3618, "speaker": "Michael Scott"},
  {"season": 2, "episode": 8, "scene": 19, "line": "I'm good; how are you?", "order": 3619, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 8, "scene": 19, "line": "Good to see you.", "order": 3620, "speaker": "Michael Scott"},
  {"season": 2, "episode": 8, "scene": 19, "line": "Nice to see you.", "order": 3621, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 8, "scene": 19, "line": "Ok. [tries to kiss Jan's hand] Ok, why don't we just step into my office? We're gonna go in here.", "order": 3622, "speaker": "Michael Scott"},
  {"season": 2, "episode": 8, "scene": 19, "line": "Can we please go in your office?", "order": 3623, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 8, "scene": 19, "line": "Yep, right after you. Apres-vous. [mouths to Pam] No calls.", "order": 3624, "speaker": "Michael Scott"},
  {"season": 2, "episode": 8, "scene": 19, "line": "Oooo.", "order": 3625, "speaker": "Kevin Malone"},
  {"season": 2, "episode": 8, "scene": 20, "line": "Alright [takes Jan's coat].", "order": 3626, "speaker": "Michael Scott"},
  {"season": 2, "episode": 8, "scene": 20, "line": "Thank you.", "order": 3627, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 8, "scene": 20, "line": "It's nice to see you.", "order": 3628, "speaker": "Michael Scott"},
  {"season": 2, "episode": 8, "scene": 20, "line": "Nice to see you too, Michael.", "order": 3629, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 8, "scene": 20, "line": "Really?", "order": 3630, "speaker": "Michael Scott"},
  {"season": 2, "episode": 8, "scene": 20, "line": "Not like that.", "order": 3631, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 8, "scene": 20, "line": "Oh, well.", "order": 3632, "speaker": "Michael Scott"},
  {"season": 2, "episode": 8, "scene": 20, "line": "You know Michael, I think I need to make something clear right off the top. I'm not going to discuss anything with you other than Dunder-Mifflin business.", "order": 3633, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 8, "scene": 20, "line": "Alright.", "order": 3634, "speaker": "Michael Scott"},
  {"season": 2, "episode": 8, "scene": 20, "line": "Period.", "order": 3635, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 8, "scene": 20, "line": "Yep.", "order": 3636, "speaker": "Michael Scott"},
  {"season": 2, "episode": 8, "scene": 20, "line": "Do we understand each other.", "order": 3637, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 8, "scene": 20, "line": "Absolutely.", "order": 3638, "speaker": "Michael Scott"},
  {"season": 2, "episode": 8, "scene": 21, "line": "I'm a little confused. 'Cause first it's all like kissy-kissy. And then it's like all regret. Because 'Oh, I regret that.' But, 'Wait, I'm still gonna call you.' But, but, 'We're just gonna talk business. And I may come down and fire you if you don't do your job.' But what were talking about when we first kissed? Business.", "order": 3639, "speaker": "Michael Scott"},
  {"season": 2, "episode": 8, "scene": 22, "line": "So are you still in the middle of the performance reviews then?", "order": 3640, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 8, "scene": 22, "line": "No, no, no, I finished all of that. I'm very fast. I'm not too fast. Not like wham-bam-thank ya ma'am. But I do say thank ya ma'am. But, I'm, I'm not like wham-bam. Not that there's anything wrong with wham-bam. If it's consensual. [cold Jan stare] We're talking about office stuff. Can I ask you a question?", "order": 3641, "speaker": "Michael Scott"},
  {"season": 2, "episode": 8, "scene": 22, "line": "No.", "order": 3642, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 8, "scene": 22, "line": "This is a business question. It's nothing personal, I promise.", "order": 3643, "speaker": "Michael Scott"},
  {"season": 2, "episode": 8, "scene": 22, "line": "Fine.", "order": 3644, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 8, "scene": 22, "line": "Are you wearing a new perfume today?", "order": 3645, "speaker": "Michael Scott"},
  {"season": 2, "episode": 8, "scene": 22, "line": "How is that a business question?", "order": 3646, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 8, "scene": 22, "line": "Well, you're wearing it at the office. And [smells Jan] it, I'm sorry, but no offense, but it's really sexy.", "order": 3647, "speaker": "Michael Scott"},
  {"season": 2, "episode": 8, "scene": 22, "line": "Please don't smell me, Michael.", "order": 3648, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 8, "scene": 23, "line": "Hey, Jim.", "order": 3649, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 8, "scene": 23, "line": "Hey, how's it goin'?", "order": 3650, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 8, "scene": 23, "line": "Oh my God, did you see 'The Apprentice' last night?", "order": 3651, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 8, "scene": 23, "line": "Course, it's on every Thursday night, so how could I miss it?", "order": 3652, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 8, "scene": 23, "line": "Can you believe who Trump fired?", "order": 3653, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 8, "scene": 23, "line": "No, that was unbelievable.", "order": 3654, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 8, "scene": 23, "line": "Who? Who was it? Who did he fire?", "order": 3655, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 8, "scene": 23, "line": "You didn't see it?", "order": 3656, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 8, "scene": 23, "line": "No, I went out and got drunk with my laser tag team last night. Crap! I never go out on a Thursday night; what the hell was I thinking?", "order": 3657, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 8, "scene": 24, "line": "I don't understand- [phone rings] Hold on. Sorry. [answers] Yes, Pam.", "order": 3658, "speaker": "Michael Scott"},
  {"season": 2, "episode": 8, "scene": 24, "line": "Michael, it's time for the suggestion box meeting.", "order": 3659, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 8, "scene": 24, "line": "I'm kind of in the middle of something. I wish you wouldn't interrupt.", "order": 3660, "speaker": "Michael Scott"},
  {"season": 2, "episode": 8, "scene": 24, "line": "You told me to buzz you about the suggestion box meeting when Jan was here.", "order": 3661, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 8, "scene": 24, "line": "I did not, not, not use those words.", "order": 3662, "speaker": "Michael Scott"},
  {"season": 2, "episode": 8, "scene": 24, "line": "Uh, I'd like to sit in on that meeting [to Pam] is it happening right now?", "order": 3663, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 8, "scene": 24, "line": "No, it's in like ten minutes.", "order": 3664, "speaker": "Michael Scott"},
  {"season": 2, "episode": 8, "scene": 24, "line": "Everyone's waiting in the conference room.", "order": 3665, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 8, "scene": 24, "line": "Great. Very good.", "order": 3666, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 8, "scene": 25, "line": "Why are we here? Because I value your opinions. Now, I know a lot of don't think that I read your suggestions, but I do. I just sift through them every week and I really look and scrutinize to see what you guys are writing. Um, so, let's, uh, just read some of these suckers. Alright. Number one: 'What should we do to prepare for Y2K?'", "order": 3667, "speaker": "Michael Scott"},
  {"season": 2, "episode": 8, "scene": 25, "line": "What should we do to prepare for Y2K?", "order": 3668, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 8, "scene": 25, "line": "I thought you read these every week.", "order": 3669, "speaker": "Kelly Kapoor"},
  {"season": 2, "episode": 8, "scene": 25, "line": "Well, obviously this one got stuck in the box. [to Jan] That happens occasionally.", "order": 3670, "speaker": "Michael Scott"},
  {"season": 2, "episode": 8, "scene": 25, "line": "It happens occasionally.", "order": 3671, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 8, "scene": 25, "line": "And, um, one down. Next suggestion: 'we need better outreach for employees fighting depression'. Ok, alright, enough with the jokes. Nobody in here is suffering from depression.", "order": 3672, "speaker": "Michael Scott"},
  {"season": 2, "episode": 8, "scene": 25, "line": "That sounds serious, Michael.", "order": 3673, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 8, "scene": 25, "line": "Oh, ok, well, yeah, who wrote it?", "order": 3674, "speaker": "Michael Scott"},
  {"season": 2, "episode": 8, "scene": 25, "line": "Tom?", "order": 3675, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 8, "scene": 25, "line": "Tom. Then it is a joke because there is nobody in here named Tom.", "order": 3676, "speaker": "Michael Scott"},
  {"season": 2, "episode": 8, "scene": 25, "line": "Tom? He worked in accounting up until about a year ago. [blank stares] Tom? [acts like she's shooting herself in the head] Pow.", "order": 3677, "speaker": "Phyllis Lapin"},
  {"season": 2, "episode": 8, "scene": 25, "line": "Oh, that guy? That guy was weird. Alright, next suggestion.", "order": 3678, "speaker": "Michael Scott"},
  {"season": 2, "episode": 8, "scene": 25, "line": "Next suggestion.", "order": 3679, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 8, "scene": 25, "line": "Arrr, dooby dooby do. 'You need to do something about your B.O.'", "order": 3680, "speaker": "Michael Scott"},
  {"season": 2, "episode": 8, "scene": 25, "line": "You need to do something about your B.O.", "order": 3681, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 8, "scene": 25, "line": "Ok, I don't know who this suggestion is meant for, but it's more of a personal suggestion and it's not an office suggestion. Far be it for me to use this as a platform to embarrass anybody.", "order": 3682, "speaker": "Michael Scott"},
  {"season": 2, "episode": 8, "scene": 25, "line": "Aren't the suggestions meant for you?", "order": 3683, "speaker": "Toby Flenderson"},
  {"season": 2, "episode": 8, "scene": 25, "line": "Well, Toby, if by me you are inferring that I have B.O., then I would say that is a very poor choice of words.", "order": 3684, "speaker": "Michael Scott"},
  {"season": 2, "episode": 8, "scene": 25, "line": "Uh, Michael, he wasn't inferring, he was implying. You were inferring.", "order": 3685, "speaker": "Creed Bratton"},
  {"season": 2, "episode": 8, "scene": 25, "line": "Was I, Creed?! Ok, well, you know what? I am implying is that when we're on an elevator together, I should maybe take the stairs, because talk about stank. Not that I would ever say something like that in public, and I never have, and I never will. I just think it's something that we should all be aware of. Ok? Now that we've learned this, let's continue. See, this is good, we're learning and we're figuring some stuff out. 'You need to do something about your coffee breath'-", "order": 3686, "speaker": "Michael Scott"},
  {"season": 2, "episode": 8, "scene": 25, "line": "You need-", "order": 3687, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 8, "scene": 25, "line": "Ok.", "order": 3688, "speaker": "Michael Scott"},
  {"season": 2, "episode": 8, "scene": 25, "line": "To do something about-", "order": 3689, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 8, "scene": 25, "line": "Shut up, shut up, shut up, Dwight, OK. I don't think you people are grasping the concept of the suggestion box.", "order": 3690, "speaker": "Michael Scott"},
  {"season": 2, "episode": 8, "scene": 25, "line": "Sometimes you talk to us real close.", "order": 3691, "speaker": "Angela Martin"},
  {"season": 2, "episode": 8, "scene": 25, "line": "Yeah, is that hard for you? Alright, well-", "order": 3692, "speaker": "Michael Scott"},
  {"season": 2, "episode": 8, "scene": 25, "line": "Well, when you have coffee breath-", "order": 3693, "speaker": "Angela Martin"},
  {"season": 2, "episode": 8, "scene": 25, "line": "I'll work on that-", "order": 3694, "speaker": "Michael Scott"},
  {"season": 2, "episode": 8, "scene": 25, "line": "It's hard.", "order": 3695, "speaker": "Angela Martin"},
  {"season": 2, "episode": 8, "scene": 25, "line": "Let's keep going. Keep it going. Yep. What do we have here? We have somebody's piece of gum. Somebody put a piece of gum in there. This is not a, a garbage can, this is the future of our company. This is not a place for gum. I don't wanna have to read these tomorrow.", "order": 3696, "speaker": "Michael Scott"},
  {"season": 2, "episode": 8, "scene": 25, "line": "Yeah, who wants to come in on a Saturday?", "order": 3697, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 8, "scene": 25, "line": "Yeah, what? Uh, alright, next suggestion.", "order": 3698, "speaker": "Michael Scott"},
  {"season": 2, "episode": 8, "scene": 25, "line": "Next suggestion.", "order": 3699, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 8, "scene": 25, "line": "Don't sl-', ok, that's blank [Dwight picks up note] Don't, just put it-", "order": 3700, "speaker": "Michael Scott"},
  {"season": 2, "episode": 8, "scene": 25, "line": "Don't sleep with your boss'? Do you think this is referring to you boning Jan?", "order": 3701, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 8, "scene": 26, "line": "I can't, I can't-", "order": 3702, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 8, "scene": 26, "line": "I don't understand why you're so upset.", "order": 3703, "speaker": "Michael Scott"},
  {"season": 2, "episode": 8, "scene": 26, "line": "Please sit down.", "order": 3704, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 8, "scene": 26, "line": "Let me ask you-", "order": 3705, "speaker": "Michael Scott"},
  {"season": 2, "episode": 8, "scene": 26, "line": "You're gonna sit here and I'm gonna go sit over there.", "order": 3706, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 8, "scene": 26, "line": "Ok, let me ask you this.", "order": 3707, "speaker": "Michael Scott"},
  {"season": 2, "episode": 8, "scene": 26, "line": "Please, sit yourself down.", "order": 3708, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 8, "scene": 26, "line": "Let me ask you something.", "order": 3709, "speaker": "Michael Scott"},
  {"season": 2, "episode": 8, "scene": 26, "line": "What, Michael.", "order": 3710, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 8, "scene": 26, "line": "Where did you get your outfit?", "order": 3711, "speaker": "Michael Scott"},
  {"season": 2, "episode": 8, "scene": 27, "line": "[loud metal music playing in a stairwell; Dwight pacing] You are giving me this raise! I deserve this raise! [plays air guitar] Yes! [kicks] Yes! Yes! Hiya! The least you can do is keep my salary consistent with inflation! Keeya eyah! Yes! Why are you gonna give me this raise? Why? Because... I'm awesome! I am awesome!", "order": 3712, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 8, "scene": 28, "line": "I just don't understand why you have to pretend like nothing happened.", "order": 3713, "speaker": "Michael Scott"},
  {"season": 2, "episode": 8, "scene": 28, "line": "Because nothing did, Michael. It, I'm not going to say anything more about it, and I would advise that you do the same", "order": 3714, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 8, "scene": 28, "line": "Look-", "order": 3715, "speaker": "Michael Scott"},
  {"season": 2, "episode": 8, "scene": 28, "line": "Michael?", "order": 3716, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 8, "scene": 28, "line": "Oh my God...", "order": 3717, "speaker": "Michael Scott"},
  {"season": 2, "episode": 8, "scene": 28, "line": "I'm sorry, am I interrupting? Oh God; were you guys making out?", "order": 3718, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 8, "scene": 28, "line": "No, Dwight; come in.", "order": 3719, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 8, "scene": 28, "line": "Great.", "order": 3720, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 8, "scene": 28, "line": "What do you want Dwight?", "order": 3721, "speaker": "Michael Scott"},
  {"season": 2, "episode": 8, "scene": 28, "line": "I am ready for my performance review.", "order": 3722, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 8, "scene": 28, "line": "Ok, great. Your performance has been adequate. You may leave; goodbye.", "order": 3723, "speaker": "Michael Scott"},
  {"season": 2, "episode": 8, "scene": 28, "line": "Is this how you've been conducting all the reviews, Michael?", "order": 3724, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 8, "scene": 28, "line": "You wanna talk now, good; OK, Dwight, leave.", "order": 3725, "speaker": "Michael Scott"},
  {"season": 2, "episode": 8, "scene": 28, "line": "Uh, wait, I would like to discuss my raise?", "order": 3726, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 8, "scene": 28, "line": "Why on earth would we give you a raise?", "order": 3727, "speaker": "Michael Scott"},
  {"season": 2, "episode": 8, "scene": 28, "line": "That is an excellent question. Thank you for asking. Let me bring up one word: dedication. [points to graphs] I have never been late. Also, I have never missed a day due to illness. [Michael sighs] Even when I had walking pneumonia. I even come in on holidays.", "order": 3728, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 8, "scene": 28, "line": "You do? How do you get in?", "order": 3729, "speaker": "Michael Scott"},
  {"season": 2, "episode": 8, "scene": 28, "line": "I have a copy of your key.", "order": 3730, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 8, "scene": 28, "line": "That's a serious offense!", "order": 3731, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 8, "scene": 28, "line": "That is a serious offense. Very serious. As is toying with a man's heart.", "order": 3732, "speaker": "Michael Scott"},
  {"season": 2, "episode": 8, "scene": 28, "line": "Oh! Michael, for God sakes!", "order": 3733, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 8, "scene": 28, "line": "I'd also further like to talk about my merits in the workplace.", "order": 3734, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 8, "scene": 28, "line": "Ok, third wheel, why don't you do that?", "order": 3735, "speaker": "Michael Scott"},
  {"season": 2, "episode": 8, "scene": 28, "line": "For instance, the time I brought in deer jerky for the whole office.", "order": 3736, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 8, "scene": 28, "line": "That was deer!? Gross, oh!", "order": 3737, "speaker": "Michael Scott"},
  {"season": 2, "episode": 8, "scene": 28, "line": "You liked it!", "order": 3738, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 8, "scene": 28, "line": "Oh, did not!", "order": 3739, "speaker": "Michael Scott"},
  {"season": 2, "episode": 8, "scene": 28, "line": "Jan, have you ever had deer?", "order": 3740, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 8, "scene": 28, "line": "No.", "order": 3741, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 8, "scene": 28, "line": "It's a delicacy. And you know what? It's an aphrodisiac. So when we're done here, you guys could go over to The Antler Lodge, sample some deer and talk about my raise.", "order": 3742, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 8, "scene": 28, "line": "What do you say, Jan?", "order": 3743, "speaker": "Michael Scott"},
  {"season": 2, "episode": 8, "scene": 28, "line": "Ok! Here's what I'm gonna do: I'm gonna step outside, collect my thoughts, and I will return in about ten minutes.", "order": 3744, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 8, "scene": 28, "line": "Ok. You just, uh, clear your head.", "order": 3745, "speaker": "Michael Scott"},
  {"season": 2, "episode": 8, "scene": 28, "line": "[Dwight opens door] Thank you, Dwight.", "order": 3746, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 8, "scene": 29, "line": "Look, I know it's your job, I know you have to ask, but I promise you, I'm not gonna discuss it with him, I'm certainly not gonna discuss it with you. [digs a cigarette out of her purse] Do you have a light?", "order": 3747, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 8, "scene": 30, "line": "And in conclusion, I think that Lex Luthor said it best when he said 'Dad, you have no idea what I'm capable of'.", "order": 3748, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 8, "scene": 30, "line": "That's from Superman?", "order": 3749, "speaker": "Michael Scott"},
  {"season": 2, "episode": 8, "scene": 30, "line": "Smallville. And that is why, I feel, that I deserve this raise.", "order": 3750, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 8, "scene": 31, "line": "Do you think Michael and Jan actually...", "order": 3751, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 8, "scene": 31, "line": "I don't really wanna picture it. But thank you, Pam.", "order": 3752, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 8, "scene": 31, "line": "How do you come back from that?", "order": 3753, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 8, "scene": 31, "line": "Um, you don't, I don't think, come all the way back, you know. Especially working together.", "order": 3754, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 8, "scene": 31, "line": "No, I mean doing that with Michael. How do you come back from that?", "order": 3755, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 8, "scene": 31, "line": "Oh-", "order": 3756, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 8, "scene": 31, "line": "As a human being.", "order": 3757, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 8, "scene": 31, "line": "Yeah, no, I don't think you can.", "order": 3758, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 8, "scene": 32, "line": "I'm heading back to New York; Alan and I will conduct your performance review over the phone tomorrow.", "order": 3759, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 8, "scene": 32, "line": "Wait.", "order": 3760, "speaker": "Michael Scott"},
  {"season": 2, "episode": 8, "scene": 32, "line": "K?", "order": 3761, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 8, "scene": 32, "line": "Wait, wait, wait, come, I just, I just wanna know why?", "order": 3762, "speaker": "Michael Scott"},
  {"season": 2, "episode": 8, "scene": 32, "line": "Michael, now is not the time or the place.", "order": 3763, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 8, "scene": 32, "line": "Ok, so you're saying that there is a different time or place?", "order": 3764, "speaker": "Michael Scott"},
  {"season": 2, "episode": 8, "scene": 32, "line": "No, I am saying we are never having this conversation.", "order": 3765, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 8, "scene": 32, "line": "Well, ok, well never as in 'never ever ever', or never as in there's still a chance?", "order": 3766, "speaker": "Michael Scott"},
  {"season": 2, "episode": 8, "scene": 32, "line": "Never, for me, always means 'never ever ever.'", "order": 3767, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 8, "scene": 32, "line": "I just want to know, from the horse's mouth, what is the dealio?", "order": 3768, "speaker": "Michael Scott"},
  {"season": 2, "episode": 8, "scene": 32, "line": "Michael, it has nothing-", "order": 3769, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 8, "scene": 32, "line": "Am I too short?", "order": 3770, "speaker": "Michael Scott"},
  {"season": 2, "episode": 8, "scene": 32, "line": "With your looks, ok? It's your personality. I mean, you're obnoxious, and rude, and, and, and stupid, and you do have coffee breath, by the way, and, and I don't agree about the b.o., but you are very, very inconsiderate.", "order": 3771, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 8, "scene": 32, "line": "Really?", "order": 3772, "speaker": "Michael Scott"},
  {"season": 2, "episode": 8, "scene": 32, "line": "Really. You're, you're, you're a great guy, ok?", "order": 3773, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 8, "scene": 32, "line": "I appreciate that, thank you.", "order": 3774, "speaker": "Michael Scott"},
  {"season": 2, "episode": 8, "scene": 32, "line": "And you were very sweet, and you stayed up with me and talked with me, cried with me, and I appreciate that-", "order": 3775, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 8, "scene": 32, "line": "No, I wasn't, I didn't cry-", "order": 3776, "speaker": "Michael Scott"},
  {"season": 2, "episode": 8, "scene": 32, "line": "At this time in my life. I just am not in the place right now where I'm looking for a relationship, so we can still work together, we can still be friends but... ok?", "order": 3777, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 8, "scene": 32, "line": "So my looks have nothing to do with it?", "order": 3778, "speaker": "Michael Scott"},
  {"season": 2, "episode": 8, "scene": 32, "line": "Ohhh, God.", "order": 3779, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 8, "scene": 33, "line": "Jan is not in a place where she feels she can have a relationship right now. And it doesn't matter how great a guy I am. And that is all I needed; I'm good. I can go home now.", "order": 3780, "speaker": "Michael Scott"},
  {"season": 2, "episode": 8, "scene": 34, "line": "Hey, it's 12:20; where the hell's Dwight?", "order": 3781, "speaker": "Michael Scott"},
  {"season": 2, "episode": 8, "scene": 34, "line": "Ummm... no idea.", "order": 3782, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 8, "scene": 34, "line": "Never missed a day, my ass.", "order": 3783, "speaker": "Michael Scott"},
  {"season": 2, "episode": 8, "scene": 34, "line": "[Jim bows to Pam; she bows back] Thank you.", "order": 3784, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 8, "scene": 35, "line": "[running through parking lot] I'm here! I'm here! I'm here! It's ok!", "order": 3785, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 8, "scene": 37, "line": "Today is performance-review day, company-wide, and I'm a little concerned about my review. I exceeded my sick days and my personal days because I just couldn't take it. And I don't have a good answer for him, when he asks me.", "order": 3786, "speaker": "Oscar Martinez"},
  {"season": 2, "episode": 8, "scene": 38, "line": "So...", "order": 3787, "speaker": "Michael Scott"},
  {"season": 2, "episode": 8, "scene": 38, "line": "Michael, I don't know what to say.", "order": 3788, "speaker": "Oscar Martinez"},
  {"season": 2, "episode": 8, "scene": 38, "line": "Um, you're in accounting...", "order": 3789, "speaker": "Michael Scott"},
  {"season": 2, "episode": 8, "scene": 38, "line": "Yes, I'm in accounting and I'm sorry and all the extra days. I know I passed my limit.", "order": 3790, "speaker": "Oscar Martinez"},
  {"season": 2, "episode": 8, "scene": 38, "line": "So, good, good, good. Something to work toward, being here more. I'm a big believer in people being here more.", "order": 3791, "speaker": "Michael Scott"},
  {"season": 2, "episode": 8, "scene": 38, "line": "Yes.", "order": 3792, "speaker": "Oscar Martinez"},
  {"season": 2, "episode": 8, "scene": 41, "line": "I am not going to discuss anything outside of Dunder Mifflin business, period.' Okay. Now, why would Jan say she only wants to talk business and then make it clear that she is on her period? God, I don't understand women. How about a clear signal, right? Is that too much to ask?", "order": 3793, "speaker": "Michael Scott"},
  {"season": 2, "episode": 8, "scene": 48, "line": "Hello, is this R. Gould? Hi there. My name is Michael Scott, I work at Dunder Mifflin. I believe I work with your wife, ex-wife, Jan. Yeah, that's right, yes. Um... I was wondering if I could ask a personal question about her. Mmm-hmm. Okay, well, could I ask anyway? Uh-huh. Well, I'm just gonna ask. When you guys were dating was she sort of easy to get and then really hard to get?", "order": 3794, "speaker": "Michael Scott"},
  {"season": 2, "episode": 8, "scene": 49, "line": "Yeah, that Gould is a real interesting guy, a gem. I can see why he and Jan are no longer together. If my conversation with him is any measure of their relationship, he was verbally abusive, he was curt. He was... He had an inability to communicate, shall I say? He was emotionally unavailable. I don't know how she dealt with that as long as she did.", "order": 3795, "speaker": "Michael Scott"},
  {"season": 2, "episode": 8, "scene": 52, "line": "What is an office? Is it a group of people? Maybe. Is it an idea? Of course, yes. Is it a living organism? Exactly, yes. And any single cell organism has to have a spine, and that's me. But the spine is always controlled by a brain, and that is Jan. But the brain needs a heart, and that is me again. So ironic. You know what? The heart is smarter than the brain. But the brain is so effing hot.", "order": 3796, "speaker": "Michael Scott"},
  {"season": 2, "episode": 9, "scene": 1, "line": "It is Friday morning and it is another beautiful day in Scranton, Pennsylvania. [sees man in a turban outside] Oh my God. Ohhh. [dials phone number] Pick up, pick up, pick up, pick up. Oh, we have a serious problem here. [goes out onto office floor] Alright everybody, lock the doors, turn off the lights. Pretend you're not here.", "order": 3797, "speaker": "Michael Scott"},
  {"season": 2, "episode": 9, "scene": 1, "line": "Are we in danger?", "order": 3798, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 9, "scene": 1, "line": "There's no time to think about if this is real. Just, shh, everybody. [knock at the front door]", "order": 3799, "speaker": "Michael Scott"},
  {"season": 2, "episode": 9, "scene": 1, "line": "Michael, should I call the... [Michael waves his hands] What?", "order": 3800, "speaker": "Kevin Malone"},
  {"season": 2, "episode": 9, "scene": 2, "line": "The IT tech guy and me did not get off to a great start.", "order": 3801, "speaker": "Michael Scott"},
  {"season": 2, "episode": 9, "scene": 3, "line": "Yeah, I tried to install it myself, but, uh, you guys have these things so password-protected...", "order": 3802, "speaker": "Michael Scott"},
  {"season": 2, "episode": 9, "scene": 3, "line": "That just means you have to enter your password.", "order": 3803, "speaker": "Sadiq (IT guy)"},
  {"season": 2, "episode": 9, "scene": 3, "line": "Oh...", "order": 3804, "speaker": "Michael Scott"},
  {"season": 2, "episode": 9, "scene": 3, "line": "What's your password, Michael?", "order": 3805, "speaker": "Sadiq (IT guy)"},
  {"season": 2, "episode": 9, "scene": 3, "line": "Oh, umm... [looks at Post-It on computer]", "order": 3806, "speaker": "Michael Scott"},
  {"season": 2, "episode": 9, "scene": 3, "line": "Oh, it's 1-2-3.", "order": 3807, "speaker": "Sadiq (IT guy)"},
  {"season": 2, "episode": 9, "scene": 3, "line": "Yes.", "order": 3808, "speaker": "Michael Scott"},
  {"season": 2, "episode": 9, "scene": 4, "line": "Mi-", "order": 3809, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 9, "scene": 4, "line": "AH! Guh-oood.", "order": 3810, "speaker": "Michael Scott"},
  {"season": 2, "episode": 9, "scene": 4, "line": "Sorry.", "order": 3811, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 9, "scene": 4, "line": "Please don't do that.", "order": 3812, "speaker": "Michael Scott"},
  {"season": 2, "episode": 9, "scene": 4, "line": "Ok, I'm sorry. What is going on in there? Why is he here? What are you doing?", "order": 3813, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 9, "scene": 4, "line": "I can't tell you.", "order": 3814, "speaker": "Michael Scott"},
  {"season": 2, "episode": 9, "scene": 4, "line": "You have to tell me.", "order": 3815, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 9, "scene": 4, "line": "I don't have to tell you anything.", "order": 3816, "speaker": "Michael Scott"},
  {"season": 2, "episode": 9, "scene": 4, "line": "Look, Michael, I know you don't want to have to think about this, but if something were to happen to you, God forbid, then I would need to know in order to take over.", "order": 3817, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 9, "scene": 4, "line": "Dwight, nothing is going to happen to me, ok? I'm in the best shape of my life. Look at this. [flexes his arms] Brrr! That's strong!", "order": 3818, "speaker": "Michael Scott"},
  {"season": 2, "episode": 9, "scene": 4, "line": "Yeah, but that doesn't matter, you could get a brain aneurysm-", "order": 3819, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 9, "scene": 4, "line": "I'm not going get a brain-", "order": 3820, "speaker": "Michael Scott"},
  {"season": 2, "episode": 9, "scene": 4, "line": "Or get hit by a car-", "order": 3821, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 9, "scene": 4, "line": "Stop it.", "order": 3822, "speaker": "Michael Scott"},
  {"season": 2, "episode": 9, "scene": 4, "line": "Or a bus or a train. Get poisoned, fall in a well, step on a mine, choke.", "order": 3823, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 9, "scene": 4, "line": "Uh, oh, ok; if I step on a mine in Scranton, Pennsylvania, and die, you can have my job, ok? Why don't you just go... away?", "order": 3824, "speaker": "Michael Scott"},
  {"season": 2, "episode": 9, "scene": 5, "line": "There are certain things a boss does not share with his employees. His salary, that would depress them. His bed, it--- And I am not going to tell them that I'll be reading their e-mails.", "order": 3825, "speaker": "Michael Scott"},
  {"season": 2, "episode": 9, "scene": 6, "line": "So how do you search?", "order": 3826, "speaker": "Michael Scott"},
  {"season": 2, "episode": 9, "scene": 6, "line": "By keyword phrase.", "order": 3827, "speaker": "Sadiq (IT guy)"},
  {"season": 2, "episode": 9, "scene": 6, "line": "Try 'profits'. No! Try 'Michael Scott'. 'Michael' 'boss' and 'funny'. [Sadiq (IT guy) types; result pops up] Oh my God, wow! [chuckles] E-mail from Stanley. Stanley, Terribly nice guy. [reads e-mail] 'Sorry I didn't write back sooner; I can't go to the game tonight because my boss Michael is an ass and making me stay late.' Well, Stanley's an ass. Not one of our harder workers.", "order": 3828, "speaker": "Michael Scott"},
  {"season": 2, "episode": 9, "scene": 7, "line": "Hey, what's the deal, Michael? Why are you spying on our computers?", "order": 3829, "speaker": "Oscar Martinez"},
  {"season": 2, "episode": 9, "scene": 7, "line": "Oh, no, everybody; Oscar's gone crazy! What other ghost stories do you have for us? That I'm a robot? [robot voice] I will destroy everything in my path-", "order": 3830, "speaker": "Michael Scott"},
  {"season": 2, "episode": 9, "scene": 7, "line": "Actually, it's just-", "order": 3831, "speaker": "Oscar Martinez"},
  {"season": 2, "episode": 9, "scene": 7, "line": "Beep! Bop!", "order": 3832, "speaker": "Michael Scott"},
  {"season": 2, "episode": 9, "scene": 7, "line": "Ok...", "order": 3833, "speaker": "Oscar Martinez"},
  {"season": 2, "episode": 9, "scene": 7, "line": "Bommmm. Bop! Onk onk. [Tin Man voice] Oil can. Oil can.", "order": 3834, "speaker": "Michael Scott"},
  {"season": 2, "episode": 9, "scene": 7, "line": "Tin Man. Actually we just a got a memo from IT saying you're doing e-mail surveillance.", "order": 3835, "speaker": "Oscar Martinez"},
  {"season": 2, "episode": 9, "scene": 7, "line": "Oh, what? No. That defeats the whole purpose.", "order": 3836, "speaker": "Michael Scott"},
  {"season": 2, "episode": 9, "scene": 7, "line": "So it's true? You have access to our e-mails?", "order": 3837, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 9, "scene": 7, "line": "You know what the problem is?", "order": 3838, "speaker": "Michael Scott"},
  {"season": 2, "episode": 9, "scene": 7, "line": "I think I do.", "order": 3839, "speaker": "Stanley Hudson"},
  {"season": 2, "episode": 9, "scene": 7, "line": "The problem is that when people hear the term 'big brother', they immediately think it's scary or bad, but I don't. I think, wow, I love my big brother.", "order": 3840, "speaker": "Michael Scott"},
  {"season": 2, "episode": 9, "scene": 8, "line": "I gotta erase a lotta stuff. A lot of stuff.", "order": 3841, "speaker": "Kevin Malone"},
  {"season": 2, "episode": 9, "scene": 9, "line": "Oh hey, just so ya know, if you have a lot of sensitive e-mails, they need to be deleted immediately.", "order": 3842, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 9, "scene": 9, "line": "I know.", "order": 3843, "speaker": "Angela Martin"},
  {"season": 2, "episode": 9, "scene": 9, "line": "Good. [Pam overhears]", "order": 3844, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 9, "scene": 9, "line": "[whispers to Jim] Hey. Something just happened. Dwight just told Angela that she has to deleted all of her sensitive e-mails immediately.", "order": 3845, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 9, "scene": 9, "line": "What?", "order": 3846, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 9, "scene": 9, "line": "I know!", "order": 3847, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 9, "scene": 9, "line": "Hmm...", "order": 3848, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 9, "scene": 9, "line": "Do you think that they're like-", "order": 3849, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 9, "scene": 9, "line": "No.", "order": 3850, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 9, "scene": 9, "line": "No, right, no, no.", "order": 3851, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 9, "scene": 9, "line": "[humming]", "order": 3852, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 9, "scene": 9, "line": "Uhhh, ew, ew, ew... .Maybe?", "order": 3853, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 9, "scene": 10, "line": "It's like squishing a spider under a book. It's gonna be really gross but I have to look and make sure that it's really dead. Sooo... [to camera guys] If you guys see anything... ?", "order": 3854, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 9, "scene": 11, "line": "Hey, Dwight, um, my friend is kinda into these two girls that he works with.", "order": 3855, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 9, "scene": 11, "line": "Nice.", "order": 3856, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 9, "scene": 11, "line": "One is tall and brunette, and the other one is short, and blonde, and perky, and kinda judgmental. Who do you think he should choose?", "order": 3857, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 9, "scene": 11, "line": "Does he have access to their medical records?", "order": 3858, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 9, "scene": 11, "line": "Ummmm...", "order": 3859, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 9, "scene": 12, "line": "I think one of the greatest things about modern America is the computerization of medical records. As a volunteer sheriff, I can look up anyone's psychiatric records or surgical histories. Yeast infections. There are a huge number of yeast infections in this county. Probably because we are down river from that old bread factory.", "order": 3860, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 9, "scene": 13, "line": "Meredith has an E-vite from Jim. Barbeque at Jim's tonight. Tonight? Wonder where my e-vitation is. Click on guest list. Angela, Stanley, Oscar, Meredith, Phyllis, Kevin, Creed. Must be... .[scrolls down list] No.", "order": 3861, "speaker": "Michael Scott"},
  {"season": 2, "episode": 9, "scene": 14, "line": "Hey, Angela-", "order": 3862, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 9, "scene": 14, "line": "Hi.", "order": 3863, "speaker": "Angela Martin"},
  {"season": 2, "episode": 9, "scene": 14, "line": "How's it going?", "order": 3864, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 9, "scene": 14, "line": "It's ok.", "order": 3865, "speaker": "Angela Martin"},
  {"season": 2, "episode": 9, "scene": 14, "line": "Listen, are you bringing anyone to Jim's party tonight?", "order": 3866, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 9, "scene": 14, "line": "No. Are we supposed to?", "order": 3867, "speaker": "Angela Martin"},
  {"season": 2, "episode": 9, "scene": 14, "line": "No. I mean, I don't know, I don't think so.", "order": 3868, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 9, "scene": 14, "line": "Hmm... [Pam reaches towards vending machine] Excuse me.", "order": 3869, "speaker": "Angela Martin"},
  {"season": 2, "episode": 9, "scene": 14, "line": "Oh.", "order": 3870, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 9, "scene": 15, "line": "There's always a distance between a boss and the employees. It is just nature's rule. It's intimidation mostly, it's the awareness that they are not me. I do think that I am very approachable, as one of the guys. But maybe I need to be even approachabler.", "order": 3871, "speaker": "Michael Scott"},
  {"season": 2, "episode": 9, "scene": 16, "line": "That's pretty young.", "order": 3872, "speaker": "Kevin Malone"},
  {"season": 2, "episode": 9, "scene": 16, "line": "Yeah.", "order": 3873, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 9, "scene": 16, "line": "[to Michael] Are you gonna eat with us?", "order": 3874, "speaker": "Kevin Malone"},
  {"season": 2, "episode": 9, "scene": 16, "line": "Of course. Hangin' with my crew, crew that I am one of. Hangin' with my Cup of Noodles. This is a meal in a cup.", "order": 3875, "speaker": "Michael Scott"},
  {"season": 2, "episode": 9, "scene": 16, "line": "Uh hum.", "order": 3876, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 9, "scene": 16, "line": "Hot, tasty. Reminds me of college. Lived on this stuff. Brain food. Mmmm... You know what I really, really miss about college? The parties. Everybody'd go. The athletes, the, the nerds, professors.", "order": 3877, "speaker": "Michael Scott"},
  {"season": 2, "episode": 9, "scene": 16, "line": "The professors would go to the parties?", "order": 3878, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 9, "scene": 16, "line": "Yeah! They were the most fun. We always invited them.", "order": 3879, "speaker": "Michael Scott"},
  {"season": 2, "episode": 9, "scene": 17, "line": "It's true. I'm having a party. I've got three cases of imported beer, a karaoke machine, and I didn't invite Michael. So three ingredients for a great party. And it's nothing personal, I just think that if he were there, people wouldn't be able to relax, and you know, have fun, and my roommate wants to meet everybody. Because I'm pretty sure he thinks that I'm making Dwight up. [sighs] He is very real.", "order": 3880, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 9, "scene": 18, "line": "[to cameraman] What? [looks at Dwight eating a Baby Ruth] Ohhhh... Yes! Thank you!", "order": 3881, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 9, "scene": 19, "line": "Question: on the Internet there are several different options to get to your house for the party tonight-", "order": 3882, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 9, "scene": 19, "line": "Oh, uh, no. Could-", "order": 3883, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 9, "scene": 19, "line": "I was wondering-", "order": 3884, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 9, "scene": 19, "line": "Could, keep that down.", "order": 3885, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 9, "scene": 19, "line": "Why?", "order": 3886, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 9, "scene": 19, "line": "Because not everybody knows about the party.", "order": 3887, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 9, "scene": 19, "line": "Like who? Who doesn't know?", "order": 3888, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 9, "scene": 19, "line": "Umm, Michael.", "order": 3889, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 9, "scene": 19, "line": "Why just Michael?", "order": 3890, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 9, "scene": 19, "line": "Because it's a surprise.", "order": 3891, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 9, "scene": 19, "line": "Is it?", "order": 3892, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 9, "scene": 19, "line": "Uh hmm.", "order": 3893, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 9, "scene": 19, "line": "Oh, that's perfect!", "order": 3894, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 9, "scene": 19, "line": "So, don't tell.", "order": 3895, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 9, "scene": 19, "line": "I won't.", "order": 3896, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 9, "scene": 19, "line": "Ok.", "order": 3897, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 9, "scene": 20, "line": "So, Dwight thinks that tonight is a surprise party for Michael.", "order": 3898, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 9, "scene": 20, "line": "Really? That's great.", "order": 3899, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 9, "scene": 20, "line": "I know.", "order": 3900, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 9, "scene": 20, "line": "Maybe we can get him to hide and wait somewhere.", "order": 3901, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 9, "scene": 20, "line": "[laughs] Oh man. Oh, you know what, speaking of which, I was just trying to get a handle on, you know, numbers for food and stuff. So do you think that Roy's gonna come, or...", "order": 3902, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 9, "scene": 20, "line": "Oh, no, he can't make it.", "order": 3903, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 9, "scene": 20, "line": "Oh, ok, cool.", "order": 3904, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 9, "scene": 20, "line": "Hey there.", "order": 3905, "speaker": "Michael Scott"},
  {"season": 2, "episode": 9, "scene": 20, "line": "Hey.", "order": 3906, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 9, "scene": 20, "line": "Almost quittin' time.", "order": 3907, "speaker": "Michael Scott"},
  {"season": 2, "episode": 9, "scene": 20, "line": "Yup, it's, uh, four o'clock.", "order": 3908, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 9, "scene": 20, "line": "One more hour. Take care of anything you forgot to do. Hey, you know, I don't know if you have any plans tonight, but if ya don't, we could hang out.", "order": 3909, "speaker": "Michael Scott"},
  {"season": 2, "episode": 9, "scene": 20, "line": "Oh, um... .I can't.", "order": 3910, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 9, "scene": 20, "line": "You have plans.", "order": 3911, "speaker": "Michael Scott"},
  {"season": 2, "episode": 9, "scene": 20, "line": "Uh hmm, definitely.", "order": 3912, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 9, "scene": 20, "line": "I do, too. I do, too.", "order": 3913, "speaker": "Michael Scott"},
  {"season": 2, "episode": 9, "scene": 20, "line": "You do?", "order": 3914, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 9, "scene": 20, "line": "I do, yeah. Big plans.", "order": 3915, "speaker": "Michael Scott"},
  {"season": 2, "episode": 9, "scene": 20, "line": "Because you said 'do you wanna hang out'-", "order": 3916, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 9, "scene": 20, "line": "Tonight, I can't do it tonight, no. Improv class, I have improv class, hanging out with my improv buds-", "order": 3917, "speaker": "Michael Scott"},
  {"season": 2, "episode": 9, "scene": 20, "line": "Really?", "order": 3918, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 9, "scene": 20, "line": "Yeah.", "order": 3919, "speaker": "Michael Scott"},
  {"season": 2, "episode": 9, "scene": 20, "line": "Aw, that sounds like a lot of fun.", "order": 3920, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 9, "scene": 20, "line": "It's the best. It's the best. I would not miss it for the world. But if something else came up, I would definitely not go.", "order": 3921, "speaker": "Michael Scott"},
  {"season": 2, "episode": 9, "scene": 20, "line": "Improv sounds great.", "order": 3922, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 9, "scene": 20, "line": "It is. Ok.", "order": 3923, "speaker": "Michael Scott"},
  {"season": 2, "episode": 9, "scene": 20, "line": "Alright.", "order": 3924, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 9, "scene": 20, "line": "[someone coughs] What?", "order": 3925, "speaker": "Michael Scott"},
  {"season": 2, "episode": 9, "scene": 20, "line": "I think Stanley just coughed.", "order": 3926, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 9, "scene": 21, "line": "Hey, Pam. Do you need me to walk to your vehicular transport?", "order": 3927, "speaker": "Michael Scott"},
  {"season": 2, "episode": 9, "scene": 21, "line": "No thanks.", "order": 3928, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 9, "scene": 21, "line": "Alright. Oscar, got big plans tonight with-", "order": 3929, "speaker": "Michael Scott"},
  {"season": 2, "episode": 9, "scene": 21, "line": "I'm on a call.", "order": 3930, "speaker": "Oscar Martinez"},
  {"season": 2, "episode": 9, "scene": 21, "line": "Kevin, big man, big man, what are you doing tonight? Where are you off to?", "order": 3931, "speaker": "Michael Scott"},
  {"season": 2, "episode": 9, "scene": 21, "line": "My brother is in town and we are going to see the Alaska Film Festival at the Scien-", "order": 3932, "speaker": "Kevin Malone"},
  {"season": 2, "episode": 9, "scene": 21, "line": "Ok, alright.", "order": 3933, "speaker": "Michael Scott"},
  {"season": 2, "episode": 9, "scene": 21, "line": "Oh.", "order": 3934, "speaker": "Kevin Malone"},
  {"season": 2, "episode": 9, "scene": 21, "line": "Hey, Angela, rushy, rushy. Where you rushin' off to?", "order": 3935, "speaker": "Michael Scott"},
  {"season": 2, "episode": 9, "scene": 21, "line": "I'm just leaving for the day.", "order": 3936, "speaker": "Angela Martin"},
  {"season": 2, "episode": 9, "scene": 21, "line": "Yeah, well duh. Where ya headed?", "order": 3937, "speaker": "Michael Scott"},
  {"season": 2, "episode": 9, "scene": 21, "line": "Charity. Bake drive.", "order": 3938, "speaker": "Angela Martin"},
  {"season": 2, "episode": 9, "scene": 21, "line": "Liar!", "order": 3939, "speaker": "Michael Scott"},
  {"season": 2, "episode": 9, "scene": 21, "line": "No!", "order": 3940, "speaker": "Angela Martin"},
  {"season": 2, "episode": 9, "scene": 21, "line": "You are a liar.", "order": 3941, "speaker": "Michael Scott"},
  {"season": 2, "episode": 9, "scene": 21, "line": "No, I'm not.", "order": 3942, "speaker": "Angela Martin"},
  {"season": 2, "episode": 9, "scene": 21, "line": "Dwight, oh ho, Dwight, Dwight, my loyal compadre. You and I are hangin' tonight. The two of us. We are celebrating our freedom and our manhood. You know what? Why don't we watch that show that you've been wanting to watch, that stupid Battleship Galaxy.", "order": 3943, "speaker": "Michael Scott"},
  {"season": 2, "episode": 9, "scene": 21, "line": "Battlestar Galatica.", "order": 3944, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 9, "scene": 21, "line": "That's, whatever stupid show you want-", "order": 3945, "speaker": "Michael Scott"},
  {"season": 2, "episode": 9, "scene": 21, "line": "I can't-", "order": 3946, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 9, "scene": 21, "line": "To watch tonight", "order": 3947, "speaker": "Michael Scott"},
  {"season": 2, "episode": 9, "scene": 21, "line": "Tonight.", "order": 3948, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 9, "scene": 21, "line": "We're watching it.", "order": 3949, "speaker": "Michael Scott"},
  {"season": 2, "episode": 9, "scene": 21, "line": "Unfortunately, I've got plans. I have to go to practice. Soccer practice.", "order": 3950, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 9, "scene": 21, "line": "I didn't know you played soccer, Dwight.", "order": 3951, "speaker": "Michael Scott"},
  {"season": 2, "episode": 9, "scene": 21, "line": "Clarinet.", "order": 3952, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 9, "scene": 21, "line": "You, too, Dwight?", "order": 3953, "speaker": "Michael Scott"},
  {"season": 2, "episode": 9, "scene": 21, "line": "Have fun tonight! Whatever it is that you're doing, and I'll see you Monday. [to the camera] He has no idea!", "order": 3954, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 9, "scene": 22, "line": "Quick announcement everybody, if I could have everybody's attention. We do have wine in the kitchen, and, uh, there is beer available on the porch and despite what you might think, it's not all for Meredith and Kelly, so please enjoy.", "order": 3955, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 9, "scene": 22, "line": "Jim! You really think this is a good idea, huh? A hide-a-key rock?", "order": 3956, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 9, "scene": 22, "line": "Hey, you must be Dwight!", "order": 3957, "speaker": "Mark"},
  {"season": 2, "episode": 9, "scene": 22, "line": "You don't work with us.", "order": 3958, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 9, "scene": 22, "line": "That's because Mark's my roommate.", "order": 3959, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 9, "scene": 22, "line": "Hey, I love the Birkenstocks.", "order": 3960, "speaker": "Mark"},
  {"season": 2, "episode": 9, "scene": 22, "line": "Thanks. Yeah, I always keep an extra set in the car, for special occasions. Jim, come here.", "order": 3961, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 9, "scene": 22, "line": "Uh hmm.", "order": 3962, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 9, "scene": 22, "line": "When is the guest of honor coming?", "order": 3963, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 9, "scene": 22, "line": "Oh, uh, later-ish.", "order": 3964, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 9, "scene": 22, "line": "He's gonna love it!", "order": 3965, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 9, "scene": 22, "line": "Great. [to a group of guests] Just wanna let you guys know that we will be taking the tour like I promised-", "order": 3966, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 9, "scene": 22, "line": "Hey.", "order": 3967, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 9, "scene": 22, "line": "Hey! Just in time! You wanna go on the group tour? We were just about to leave.", "order": 3968, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 9, "scene": 22, "line": "Definitely.", "order": 3969, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 9, "scene": 22, "line": "Well, the group tour is now leaving, then. Ladies and gentlemen, just a few things that we are gonna be pointing out to you today, you will be able to see both bedrooms and, uh, if we're lucky, maybe get a chance to peek into the bathroom, who knows. I have to remind you that flash photography is prohibited and as much as you can, please refrain from touching things. I know you might want to.", "order": 3970, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 9, "scene": 22, "line": "Hey, is Katy coming?", "order": 3971, "speaker": "Ryan Howard"},
  {"season": 2, "episode": 9, "scene": 22, "line": "Uh, actually, I haven't talked to her in a while.", "order": 3972, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 9, "scene": 22, "line": "Huh, is it ok if I call her?", "order": 3973, "speaker": "Ryan Howard"},
  {"season": 2, "episode": 9, "scene": 22, "line": "We can talk about that later.", "order": 3974, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 9, "scene": 23, "line": "Ok, let's get right into it. I need two people for the first scene.", "order": 3975, "speaker": "Improv Teacher"},
  {"season": 2, "episode": 9, "scene": 23, "line": "[In Horshack voice from 'Welcome Back, Kotter'] Ooo Ooo Ooo Ooo, Mr. Cart-air, Mr. Cart-air.", "order": 3976, "speaker": "Michael Scott"},
  {"season": 2, "episode": 9, "scene": 23, "line": "Ok, Michael. And... anybody? How about Mary-Beth? Come on. Ok, so you start us off Mary-Beth.", "order": 3977, "speaker": "Improv Teacher"},
  {"season": 2, "episode": 9, "scene": 23, "line": "Great. [skips] La la la la la laaa...", "order": 3978, "speaker": "Mary-Beth"},
  {"season": 2, "episode": 9, "scene": 23, "line": "[fake kicks in door] Boom! Detective Michael Scarn. I'm with the FBI.", "order": 3979, "speaker": "Michael Scott"},
  {"season": 2, "episode": 9, "scene": 24, "line": "Think about this; what is the most exciting thing that can happen, on TV, or in movies, or in real life? Somebody has a gun. [gasps] That's why I always start with a gun, because you can't top it, you just can't.", "order": 3980, "speaker": "Michael Scott"},
  {"season": 2, "episode": 9, "scene": 25, "line": "I'm supposed to meet my doctor here? Have ya seen him? He's a very angry midget.", "order": 3981, "speaker": "Girl acting Pregnant"},
  {"season": 2, "episode": 9, "scene": 25, "line": "Boom! Freeze! Michael Scoon, FBI. You know what you did. Boom! Boom! Boom! [shoots at Pregnant Girl and another actor] Yeah, you thought that you could get away with your little ruse, didn't you? Didn't ya!? Well, you didn't, because I know where ya hid the diamonds. I been on to you and your little friends for weeks. [another actor steps in] Boom! Boom! Boom!", "order": 3982, "speaker": "Michael Scott"},
  {"season": 2, "episode": 9, "scene": 25, "line": "I'm not even in the scene!", "order": 3983, "speaker": "Actor"},
  {"season": 2, "episode": 9, "scene": 25, "line": "Again!?", "order": 3984, "speaker": "Actress"},
  {"season": 2, "episode": 9, "scene": 25, "line": "Boom! Boom!", "order": 3985, "speaker": "Michael Scott"},
  {"season": 2, "episode": 9, "scene": 25, "line": "Stop, stop, ok, stop.", "order": 3986, "speaker": "Improv Teacher"},
  {"season": 2, "episode": 9, "scene": 25, "line": "Boom! Boom!", "order": 3987, "speaker": "Michael Scott"},
  {"season": 2, "episode": 9, "scene": 25, "line": "You shot me, great. Now stop.", "order": 3988, "speaker": "Improv Teacher"},
  {"season": 2, "episode": 9, "scene": 25, "line": "Why", "order": 3989, "speaker": "Michael Scott"},
  {"season": 2, "episode": 9, "scene": 25, "line": "You can't just shoot everyone in the scene.", "order": 3990, "speaker": "Improv Teacher"},
  {"season": 2, "episode": 9, "scene": 25, "line": "Well, if you hadn't stopped the scene, you would have seen where it was going.", "order": 3991, "speaker": "Michael Scott"},
  {"season": 2, "episode": 9, "scene": 25, "line": "Ok, what about the scene they set up?", "order": 3992, "speaker": "Improv Teacher"},
  {"season": 2, "episode": 9, "scene": 25, "line": "Boring.", "order": 3993, "speaker": "Michael Scott"},
  {"season": 2, "episode": 9, "scene": 25, "line": "No, it wasn't. No more guns.", "order": 3994, "speaker": "Improv Teacher"},
  {"season": 2, "episode": 9, "scene": 25, "line": "I could of-", "order": 3995, "speaker": "Michael Scott"},
  {"season": 2, "episode": 9, "scene": 25, "line": "No. No. Michael, I want you to give me all the guns you have.", "order": 3996, "speaker": "Improv Teacher"},
  {"season": 2, "episode": 9, "scene": 25, "line": "Ok.", "order": 3997, "speaker": "Michael Scott"},
  {"season": 2, "episode": 9, "scene": 25, "line": "Just, I want you to get rid of all your guns and give them to me. Great.", "order": 3998, "speaker": "Improv Teacher"},
  {"season": 2, "episode": 9, "scene": 25, "line": "Yehhhehh.", "order": 3999, "speaker": "Michael Scott"},
  {"season": 2, "episode": 9, "scene": 25, "line": "Yeeehh, ok.", "order": 4000, "speaker": "Improv Teacher"},
  {"season": 2, "episode": 9, "scene": 26, "line": "Jim's bedroom.", "order": 4001, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 9, "scene": 26, "line": "See, I knew we lost somebody on the tour. It's-", "order": 4002, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 9, "scene": 26, "line": "Cool... This is your desk.", "order": 4003, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 9, "scene": 26, "line": "This is my desk.", "order": 4004, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 9, "scene": 26, "line": "Your home office?", "order": 4005, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 9, "scene": 26, "line": "My home office, this is it.", "order": 4006, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 9, "scene": 26, "line": "Down. You have to sit down so I can get the full effect.", "order": 4007, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 9, "scene": 26, "line": "Ok, sure. Will do.", "order": 4008, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 9, "scene": 26, "line": "Ok, wait, so that would make me [walks to side of room] like right here.", "order": 4009, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 9, "scene": 26, "line": "Yeah, that... Yep, that feels about right.", "order": 4010, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 9, "scene": 26, "line": "And Dwight would be like-", "order": 4011, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 9, "scene": 26, "line": "You know what? Let's just leave that image out of it, because this is a happy place. Happy thoughts, Pam. Happy thoughts.", "order": 4012, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 9, "scene": 26, "line": "Umm, [gasps] yearbook!", "order": 4013, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 9, "scene": 26, "line": "Yeah, you don't have to, ummm. Alright, yes, that's not gonna be awkward at all.", "order": 4014, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 9, "scene": 26, "line": "[finds Jim's picture] Ooooohhh no!", "order": 4015, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 9, "scene": 26, "line": "Oh yeah.", "order": 4016, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 9, "scene": 26, "line": "You were so dorky!", "order": 4017, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 9, "scene": 26, "line": "Thank you.", "order": 4018, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 9, "scene": 27, "line": "Freeze!", "order": 4019, "speaker": "Improv Teacher"},
  {"season": 2, "episode": 9, "scene": 27, "line": "I'm in.", "order": 4020, "speaker": "Michael Scott"},
  {"season": 2, "episode": 9, "scene": 27, "line": "You wanna go over the rules one more time?", "order": 4021, "speaker": "Improv Teacher"},
  {"season": 2, "episode": 9, "scene": 27, "line": "No, no, no. I'm looking in my wallet for money so you can tell me my fortune.", "order": 4022, "speaker": "Michael Scott"},
  {"season": 2, "episode": 9, "scene": 27, "line": "I promise it's worthit . Ooo, I can see you walking out of here and you're thrilled with your reading.", "order": 4023, "speaker": "Bill"},
  {"season": 2, "episode": 9, "scene": 27, "line": "What are you... [whispers to Bill]", "order": 4024, "speaker": "Michael Scott"},
  {"season": 2, "episode": 9, "scene": 27, "line": "Michael, what did you tell him?", "order": 4025, "speaker": "Improv Teacher"},
  {"season": 2, "episode": 9, "scene": 27, "line": "Nothing.", "order": 4026, "speaker": "Michael Scott"},
  {"season": 2, "episode": 9, "scene": 27, "line": "Then why are his hands up? Bill?", "order": 4027, "speaker": "Improv Teacher"},
  {"season": 2, "episode": 9, "scene": 27, "line": "He told me he couldn't show it to me, but he has a gun.", "order": 4028, "speaker": "Bill"},
  {"season": 2, "episode": 9, "scene": 27, "line": "Ok, let's call it a day. Nice job, Bill.", "order": 4029, "speaker": "Improv Teacher"},
  {"season": 2, "episode": 9, "scene": 27, "line": "Good, it's good. Good work, everybody.", "order": 4030, "speaker": "Michael Scott"},
  {"season": 2, "episode": 9, "scene": 28, "line": "Angela, burger? Dog? Havin' fun?", "order": 4031, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 9, "scene": 28, "line": "I got sap on me.", "order": 4032, "speaker": "Angela Martin"},
  {"season": 2, "episode": 9, "scene": 28, "line": "Chicken, hot dog, burger.", "order": 4033, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 9, "scene": 28, "line": "I'm a vegetarian.", "order": 4034, "speaker": "Angela Martin"},
  {"season": 2, "episode": 9, "scene": 28, "line": "There is soda inside.", "order": 4035, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 9, "scene": 28, "line": "Guh.", "order": 4036, "speaker": "Angela Martin"},
  {"season": 2, "episode": 9, "scene": 29, "line": "I didn't think the premium laser color copy batch would sell as well as it did.", "order": 4037, "speaker": "Stanley Hudson"},
  {"season": 2, "episode": 9, "scene": 29, "line": "Yeah, it surprised us all. I'll tell you why. Because when they---", "order": 4038, "speaker": "Oscar Martinez"},
  {"season": 2, "episode": 9, "scene": 29, "line": "I'm sorry guys; can we please not talk about paper? There's gotta be something else we can talk about.", "order": 4039, "speaker": "Kelly Kapoor"},
  {"season": 2, "episode": 9, "scene": 30, "line": "I think it's alright. Jesus drank wine. [Pam overhears]", "order": 4040, "speaker": "Angela Martin"},
  {"season": 2, "episode": 9, "scene": 30, "line": "Hey Phyllis, come here for a second.", "order": 4041, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 9, "scene": 30, "line": "Sure.", "order": 4042, "speaker": "Phyllis Lapin"},
  {"season": 2, "episode": 9, "scene": 30, "line": "Have you heard anything about any secret office romances?", "order": 4043, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 9, "scene": 30, "line": "You tell me. Well, you do mean you and Jim right? Oh God. I am so sorry, I thought, you guys hang out all the time and you're talking all the time. I'm sorry!", "order": 4044, "speaker": "Phyllis Lapin"},
  {"season": 2, "episode": 9, "scene": 30, "line": "That's ok. It's ok.", "order": 4045, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 9, "scene": 31, "line": "[smacks Ryan's hand] Not so fast... Fire Guy.", "order": 4046, "speaker": "Kevin Malone"},
  {"season": 2, "episode": 9, "scene": 32, "line": "How do I get to Bernie's Tavern from here?", "order": 4047, "speaker": "Mary-Beth"},
  {"season": 2, "episode": 9, "scene": 32, "line": "Oh, don't worry. We're all gonna carpool.", "order": 4048, "speaker": "Bill"},
  {"season": 2, "episode": 9, "scene": 32, "line": "So Bernie's, huh? We're all going to Bernie's? [to camera] Go to Bernie's?", "order": 4049, "speaker": "Michael Scott"},
  {"season": 2, "episode": 9, "scene": 32, "line": "Oh sorry, we're not going as a group, it's just a private friend who just happens to know all of us from different ways is throwing a private birthday thing.", "order": 4050, "speaker": "Bill"},
  {"season": 2, "episode": 9, "scene": 32, "line": "Right, right, right. Well guys, I'd love to go to Bernie's with you, but, you know, I have an office party. A big office party I need to go to, so... Can't get out of it.", "order": 4051, "speaker": "Michael Scott"},
  {"season": 2, "episode": 9, "scene": 32, "line": "Ok.", "order": 4052, "speaker": "Bill"},
  {"season": 2, "episode": 9, "scene": 32, "line": "Ok, see you later. Nice job, Bill. Not.", "order": 4053, "speaker": "Michael Scott"},
  {"season": 2, "episode": 9, "scene": 33, "line": "[singing] Here I go again on my own. Going down the only road I've ever known...", "order": 4054, "speaker": "Phyllis Lapin"},
  {"season": 2, "episode": 9, "scene": 33, "line": "Oh by the way how's your side project going?", "order": 4055, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 9, "scene": 33, "line": "Oh, yeah I gave that up.", "order": 4056, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 9, "scene": 33, "line": "Really?", "order": 4057, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 9, "scene": 33, "line": "Yeah, it turns out I was, um, just grasping at straws.", "order": 4058, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 9, "scene": 34, "line": "Just because two people are hanging out, it doesn't mean that they're together, you know? Like people can just be friends. And I think that it was really unfair to think that there was anything else going on.", "order": 4059, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 9, "scene": 35, "line": "[singing] Just turn around now, cause you're not welcome anymore.", "order": 4060, "speaker": "Kevin Malone"},
  {"season": 2, "episode": 9, "scene": 35, "line": "Surprise! [laughs] Everybody!", "order": 4061, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 9, "scene": 35, "line": "Dwight...", "order": 4062, "speaker": "Phyllis Lapin"},
  {"season": 2, "episode": 9, "scene": 35, "line": "Wow! Who opened the morgue for this thing? I'm just driving by, thought I'd drop in. [to Jim] There's some wine. I would love a glass, if you're gonna open it. Here ya go Temp, take my jacket! [sees Sadiq (IT guy)] Oh, come on! That guy? He is a good guy, not a terrorist. Karaoke, I love it! I am a karaoke fiend. I call dibs. I got next, I got next up. Come on, let's get this party started, ha! Ok? Where's that wine?", "order": 4063, "speaker": "Michael Scott"},
  {"season": 2, "episode": 9, "scene": 36, "line": "Ok, yeah, this is a duet, so, need somebody else, Pam? You wanna come up and sing this one? Need somebody else. Takers, please. [singing] Baby when I met you there was peace unknown. Kelly? Tried to get you with a fine tooth comb. I was soft inside, there was something goin' on. This part goes to the, uh, girl. You do something to me that I can't explain. Hold me closer and I feel no pain. In every beat of my heart, we got something goin' on. Tender love is blind---", "order": 4064, "speaker": "Michael Scott"},
  {"season": 2, "episode": 9, "scene": 36, "line": "[singing] It requires a dedication, all this love we feel needs no conversation. Divided, together, uh huh. Making love with each other, uh huh.", "order": 4065, "speaker": "Michael Scott & Jim Halpert"},
  {"season": 2, "episode": 9, "scene": 36, "line": "We're making love!", "order": 4066, "speaker": "Michael Scott"},
  {"season": 2, "episode": 9, "scene": 36, "line": "[singing] Islands in the stream, that is what we are, no one in between, how can we be wrong? Sail away with me...", "order": 4067, "speaker": "Michael Scott & Jim Halpert"},
  {"season": 2, "episode": 9, "scene": 37, "line": "Talk! Just talk!", "order": 4068, "speaker": "Michael Scott"},
  {"season": 2, "episode": 9, "scene": 37, "line": "I am-", "order": 4069, "speaker": "Mary-Beth"},
  {"season": 2, "episode": 9, "scene": 37, "line": "Shut up!", "order": 4070, "speaker": "Michael Scott"},
  {"season": 2, "episode": 9, "scene": 38, "line": "Funny story: the way that I got into improv was, I got into improv. The story about me getting into improv was that I was walking down the street, and a race car pulls up, and the guy says 'Hey you're funny, you're the funniest guy I've ever seen, or my name is not Dale Earnhardt.' [giggles] And that was an improv. Um, the real way is that I found a flyer.", "order": 4071, "speaker": "Michael Scott"},
  {"season": 2, "episode": 10, "scene": 1, "line": "Go. Get the door.", "order": 4072, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 10, "scene": 1, "line": "Here we are.", "order": 4073, "speaker": "Michael Scott"},
  {"season": 2, "episode": 10, "scene": 1, "line": "Go. Push!", "order": 4074, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 10, "scene": 1, "line": "Oh god.", "order": 4075, "speaker": "Michael Scott"},
  {"season": 2, "episode": 10, "scene": 1, "line": "Push!", "order": 4076, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 10, "scene": 1, "line": "No, no, turn it around.", "order": 4077, "speaker": "Michael Scott"},
  {"season": 2, "episode": 10, "scene": 1, "line": "Really shove it.", "order": 4078, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 10, "scene": 1, "line": "You'll break it.", "order": 4079, "speaker": "Michael Scott"},
  {"season": 2, "episode": 10, "scene": 1, "line": "Shove it through! Break it!", "order": 4080, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 10, "scene": 1, "line": "You shove it. Shove it back! Here we go. Don't break the branches, Dwight.", "order": 4081, "speaker": "Michael Scott"},
  {"season": 2, "episode": 10, "scene": 1, "line": "All right.", "order": 4082, "speaker": "Michael Scott"},
  {"season": 2, "episode": 10, "scene": 1, "line": "I got a splinter.", "order": 4083, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 10, "scene": 1, "line": "Well, suck it up. We all have problems. Hey, everybody, look what we have! [laughs] Nice, huh?", "order": 4084, "speaker": "Michael Scott"},
  {"season": 2, "episode": 10, "scene": 1, "line": "I've got it leveraged. Push. Straight up.", "order": 4085, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 10, "scene": 1, "line": "On three. Ready? Big, one, two, three.", "order": 4086, "speaker": "Michael Scott"},
  {"season": 2, "episode": 10, "scene": 1, "line": "One, two, three. [they push the tree up and it breaks through a ceiling tile.]", "order": 4087, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 10, "scene": 1, "line": "Merry Christmas!", "order": 4088, "speaker": "Michael Scott"},
  {"season": 2, "episode": 10, "scene": 2, "line": "Did it work?", "order": 4089, "speaker": "Michael Scott"},
  {"season": 2, "episode": 10, "scene": 2, "line": "[holds up the piece of tree he just cut off with a paper cutter] Well, sort of. Why did you get it so big?", "order": 4090, "speaker": "Kevin Malone"},
  {"season": 2, "episode": 10, "scene": 2, "line": "A, that's what she said, and B, I wanted it to be impressive. The biggest day of the year deserves the biggest tree of the year.", "order": 4091, "speaker": "Michael Scott"},
  {"season": 2, "episode": 10, "scene": 2, "line": "But what are we going to do with this hacked off part?", "order": 4092, "speaker": "Kevin Malone"},
  {"season": 2, "episode": 10, "scene": 2, "line": "Well, that is a perfectly good mini-tree, Kevin. And we are going to sell that to charity. That's what Christmas is all about.", "order": 4093, "speaker": "Michael Scott"},
  {"season": 2, "episode": 10, "scene": 3, "line": "So this year, for the first time ever, I got Pam in Secret Santa. And I got her this teapot, which I know she really wants, so she can make tea at her desk. But I'm also going to stuff it with some inside jokes. Like, this is my high school yearbook photo. She saw it at the party, and it really makes her laugh. Not sure why. What else .. ooh. This is a hot sauce packet. She put this on a hot dog a couple years ago because she thought it was ketchup. And it was really funny, so I kept the other two. [holds up a miniature pencil] This would take a little too long to explain, so I won't. And this is the card. Because Christmas is the time to tell people how you feel.", "order": 4094, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 10, "scene": 4, "line": "Is there anything we're missing? Phyllis, you got the lights?", "order": 4095, "speaker": "Angela Martin"},
  {"season": 2, "episode": 10, "scene": 4, "line": "Yes, I got those cute little ones. [Angela looks at her disapprovingly] Do you think I should have gotten the big ones?", "order": 4096, "speaker": "Phyllis Lapin"},
  {"season": 2, "episode": 10, "scene": 4, "line": "We'll see.", "order": 4097, "speaker": "Angela Martin"},
  {"season": 2, "episode": 10, "scene": 5, "line": "Angela drafted me into the party planning committee. Her memo said that we need to prepare for every possible disaster. Which to me seems excessive.", "order": 4098, "speaker": "Ryan Howard"},
  {"season": 2, "episode": 10, "scene": 6, "line": "[comes into the conference room in a Santa hat and beard] Merry Christmas! Ho, ho, ho, [points to Ryan] pimp. I'm kidding. What do we got, what do we got? How many plates are we getting?", "order": 4099, "speaker": "Michael Scott"},
  {"season": 2, "episode": 10, "scene": 6, "line": "Fifty.", "order": 4100, "speaker": "Angela Martin"},
  {"season": 2, "episode": 10, "scene": 6, "line": "Double it. Double everything. Double ice cream. Double napkins. Double it. On me.", "order": 4101, "speaker": "Michael Scott"},
  {"season": 2, "episode": 10, "scene": 7, "line": "It was a tough year. I had to fire somebody this year. This party has to really rock. Check it out. Christmas bonus. 3,000 G's. I got this for helping save the company money. So I guess some good came out of firing Devon after all. Maybe I should call him and tell him that.", "order": 4102, "speaker": "Michael Scott"},
  {"season": 2, "episode": 10, "scene": 8, "line": "I want people to cut loose. I want people making out in closets. I want people hanging from the ceilings, lamp shades on the heads. I want it to be a Playboy Mansion party. And also, I want you to spread the word that I will have my digital camera. And I'll be taking pictures all along the way. And the best and craziest thing that happens will be on the cover of the newsletter. Incentive.", "order": 4103, "speaker": "Michael Scott"},
  {"season": 2, "episode": 10, "scene": 8, "line": "You do realize that we can't serve liquor at the party.", "order": 4104, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 10, "scene": 8, "line": "Yeah, I know. Damn it. Stupid corporate wet blankets. Like booze ever killed anybody.", "order": 4105, "speaker": "Michael Scott"},
  {"season": 2, "episode": 10, "scene": 9, "line": "One, two, three. [they lift and start to move a desk]", "order": 4106, "speaker": "Kevin Malone & Oscar Martinez"},
  {"season": 2, "episode": 10, "scene": 9, "line": "You guys should use a hand truck.", "order": 4107, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 10, "scene": 9, "line": "Do we have one?", "order": 4108, "speaker": "Kevin Malone"},
  {"season": 2, "episode": 10, "scene": 9, "line": "No.", "order": 4109, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 10, "scene": 10, "line": "[having trouble with a plastic tablecloth as Pam stands idly by] Will you help me?", "order": 4110, "speaker": "Angela Martin"},
  {"season": 2, "episode": 10, "scene": 11, "line": "No! No way! It... no.", "order": 4111, "speaker": "Michael Scott"},
  {"season": 2, "episode": 10, "scene": 11, "line": "Come on, Mike, let me borrow the hat for just a couple of hours.", "order": 4112, "speaker": "Darryl Philbin"},
  {"season": 2, "episode": 10, "scene": 11, "line": "You wanna be Santa?", "order": 4113, "speaker": "Michael Scott"},
  {"season": 2, "episode": 10, "scene": 11, "line": "Yeah.", "order": 4114, "speaker": "Darryl Philbin"},
  {"season": 2, "episode": 10, "scene": 11, "line": "Have you ever seen Santa?", "order": 4115, "speaker": "Michael Scott"},
  {"season": 2, "episode": 10, "scene": 11, "line": "Yeah, I've seen Santa.", "order": 4116, "speaker": "Darryl Philbin"},
  {"season": 2, "episode": 10, "scene": 11, "line": "Okay.", "order": 4117, "speaker": "Michael Scott"},
  {"season": 2, "episode": 10, "scene": 11, "line": "Who cares?", "order": 4118, "speaker": "Darryl Philbin"},
  {"season": 2, "episode": 10, "scene": 11, "line": "Well, I'm sorry. It just doesn't work.", "order": 4119, "speaker": "Michael Scott"},
  {"season": 2, "episode": 10, "scene": 11, "line": "Michael, I would like to be the elf.", "order": 4120, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 10, "scene": 11, "line": "That makes sense because he has elfish features.", "order": 4121, "speaker": "Michael Scott"},
  {"season": 2, "episode": 10, "scene": 12, "line": "[now wearing an elf hat and ears] Okay, everybody listen up! It is time to get your presents, wrap them, and place them under the tree like so. If you do not get your present wrapped and under the tree within the next five minutes you will be disqualified from Secret Santa. All right? No exceptions except Michael.", "order": 4122, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 10, "scene": 13, "line": "I got Angela. She is into these posters of babies dressed as adults. I got her one of those. I felt kind of weird buying that.", "order": 4123, "speaker": "Toby Flenderson"},
  {"season": 2, "episode": 10, "scene": 14, "line": "I got Creed. And to tell you the truth, I don't know anything about Creed. I know his name's Creed. I know he works right over there. I think he's Irish and I .. I got him this shamrock keychain.", "order": 4124, "speaker": "Oscar Martinez"},
  {"season": 2, "episode": 10, "scene": 15, "line": "I got myself for Secret Santa. I was supposed to tell somebody, but I didn't [smiles happily].", "order": 4125, "speaker": "Kevin Malone"},
  {"season": 2, "episode": 10, "scene": 16, "line": "You get something good this year?", "order": 4126, "speaker": "Michael Scott"},
  {"season": 2, "episode": 10, "scene": 16, "line": "I think I did a pretty good job.", "order": 4127, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 10, "scene": 16, "line": "Yeah? Who did you have?", "order": 4128, "speaker": "Michael Scott"},
  {"season": 2, "episode": 10, "scene": 16, "line": "Well, I can't tell you cause it's a secret.", "order": 4129, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 10, "scene": 16, "line": "I think I got something pretty nice for my guy.", "order": 4130, "speaker": "Michael Scott"},
  {"season": 2, "episode": 10, "scene": 16, "line": "Yeah?", "order": 4131, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 10, "scene": 16, "line": "I spent a lot of dough. Lot of dough. Wow.", "order": 4132, "speaker": "Michael Scott"},
  {"season": 2, "episode": 10, "scene": 16, "line": "Well, there's a $20 limit, right? So .. ?", "order": 4133, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 10, "scene": 16, "line": "Yeah. I wanted this party to be really special so I sorta went above and beyond.", "order": 4134, "speaker": "Michael Scott"},
  {"season": 2, "episode": 10, "scene": 16, "line": "That's great. Well don't tell me who it is, cause I can ..", "order": 4135, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 10, "scene": 16, "line": "It was Ryan. Yeah. I have Ryan.", "order": 4136, "speaker": "Michael Scott"},
  {"season": 2, "episode": 10, "scene": 17, "line": "Gather round. Secret Santa, let's go. Let's go. Come on. Stanley, no, I'm going to handle the cord. Okay, safety reasons.", "order": 4137, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 10, "scene": 17, "line": "I know how to plug something in.", "order": 4138, "speaker": "Stanley Hudson"},
  {"season": 2, "episode": 10, "scene": 17, "line": "I want to do it.", "order": 4139, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 10, "scene": 17, "line": "All right, let's count it down, like Rockefeller Center. Ready?", "order": 4140, "speaker": "Michael Scott"},
  {"season": 2, "episode": 10, "scene": 17, "line": "Three, two, one. [very dim lights come on the tree]", "order": 4141, "speaker": "All"},
  {"season": 2, "episode": 10, "scene": 17, "line": "Not great.", "order": 4142, "speaker": "Michael Scott"},
  {"season": 2, "episode": 10, "scene": 17, "line": "I'm sorry, everybody.", "order": 4143, "speaker": "Phyllis Lapin"},
  {"season": 2, "episode": 10, "scene": 17, "line": "I think the tree looks nice.", "order": 4144, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 10, "scene": 17, "line": "Hey, I could get some flares from my car.", "order": 4145, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 10, "scene": 17, "line": "No, no. Shake it off, everybody. Just, let's do Secret Santa.", "order": 4146, "speaker": "Michael Scott"},
  {"season": 2, "episode": 10, "scene": 18, "line": "Presents are the best way to show someone how much you care. It's like this tangible thing that you can point to and say, 'Hey, man, I love you this many dollars worth.'", "order": 4147, "speaker": "Michael Scott"},
  {"season": 2, "episode": 10, "scene": 19, "line": "First present, Oscar.", "order": 4148, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 10, "scene": 19, "line": "[rips off the wrapping] Shower radio. Neat.", "order": 4149, "speaker": "Oscar Martinez"},
  {"season": 2, "episode": 10, "scene": 19, "line": "Oh, good, that was from me.", "order": 4150, "speaker": "Kelly Kapoor"},
  {"season": 2, "episode": 10, "scene": 19, "line": "Thanks, Kelly. You know I was gonna get one of ..", "order": 4151, "speaker": "Oscar Martinez"},
  {"season": 2, "episode": 10, "scene": 19, "line": "Okay. Okay. That's enough. Let's keep it moving on. Jim.", "order": 4152, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 10, "scene": 19, "line": "Oh, cool. [opens his plastic bag]", "order": 4153, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 10, "scene": 19, "line": "That's from me.", "order": 4154, "speaker": "Creed Bratton"},
  {"season": 2, "episode": 10, "scene": 19, "line": "Great. Where did you get it?", "order": 4155, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 10, "scene": 19, "line": "I don't know. It was so long ago.", "order": 4156, "speaker": "Creed Bratton"},
  {"season": 2, "episode": 10, "scene": 20, "line": "He obviously forgot to get me something, and then he went in his closet and dug out this little number [holds up way-too-short sleeves] and then threw it in a bag.", "order": 4157, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 10, "scene": 21, "line": "Yep. That's exactly what happened.", "order": 4158, "speaker": "Creed Bratton"},
  {"season": 2, "episode": 10, "scene": 22, "line": "Pam.", "order": 4159, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 10, "scene": 22, "line": "[opens up her present] Oh, my god! Thank you very much, Santa, whoever you are. It's awesome.", "order": 4160, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 10, "scene": 22, "line": "There's a little more to it.", "order": 4161, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 10, "scene": 22, "line": "All right, next. Ryan. [tosses present]", "order": 4162, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 10, "scene": 22, "line": "No, don't!", "order": 4163, "speaker": "Michael Scott"},
  {"season": 2, "episode": 10, "scene": 22, "line": "[unwraps present] Whoa, a video iPod.", "order": 4164, "speaker": "Ryan Howard"},
  {"season": 2, "episode": 10, "scene": 22, "line": "Whoa. Wow. Jeez. Somebody really got carried away with the spirit of Christmas. That was me, I got a little carried away.", "order": 4165, "speaker": "Michael Scott"},
  {"season": 2, "episode": 10, "scene": 22, "line": "Wasn't there a $20 limit on the gift? This is 400 bucks.", "order": 4166, "speaker": "Ryan Howard"},
  {"season": 2, "episode": 10, "scene": 22, "line": "You don't know that.", "order": 4167, "speaker": "Michael Scott"},
  {"season": 2, "episode": 10, "scene": 22, "line": "Yeah, you left the price tag on.", "order": 4168, "speaker": "Ryan Howard"},
  {"season": 2, "episode": 10, "scene": 22, "line": "I did?", "order": 4169, "speaker": "Michael Scott"},
  {"season": 2, "episode": 10, "scene": 22, "line": "Yeah.", "order": 4170, "speaker": "Ryan Howard"},
  {"season": 2, "episode": 10, "scene": 22, "line": "What? Oh, shoot. Wow. Okay, well, who cares? It doesn't matter what I spent. What matters is that Christmas is fun, right?", "order": 4171, "speaker": "Michael Scott"},
  {"season": 2, "episode": 10, "scene": 22, "line": "Michael.", "order": 4172, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 10, "scene": 22, "line": "Oh hey, for me. What is in here? [opens a handmade oven mitt] Oh, come on.", "order": 4173, "speaker": "Michael Scott"},
  {"season": 2, "episode": 10, "scene": 22, "line": "I knitted it for you.", "order": 4174, "speaker": "Phyllis Lapin"},
  {"season": 2, "episode": 10, "scene": 22, "line": "An oven mitt? Okay. [walks out]", "order": 4175, "speaker": "Michael Scott"},
  {"season": 2, "episode": 10, "scene": 23, "line": "So Phyllis is basically saying, 'Hey Michael, I know you did a lot to help the office this year but I only care about you a homemade oven mitt's worth.' I gave Ryan an iPod.", "order": 4176, "speaker": "Michael Scott"},
  {"season": 2, "episode": 10, "scene": 24, "line": "Should we just keep opening up the presents?", "order": 4177, "speaker": "Kevin Malone"},
  {"season": 2, "episode": 10, "scene": 24, "line": "We don't do anything until Michael gives us further instructions.", "order": 4178, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 10, "scene": 24, "line": "I got it! We are going to turn Secret Santa into Yankee Swap.", "order": 4179, "speaker": "Michael Scott"},
  {"season": 2, "episode": 10, "scene": 24, "line": "What is Yankee Swap?", "order": 4180, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 10, "scene": 24, "line": "One person chooses a gift. The next person can either choose a gift or steal that person's gift. If your gift gets stolen, then you can steal somebody else's gift or choose a new gift.", "order": 4181, "speaker": "Michael Scott"},
  {"season": 2, "episode": 10, "scene": 24, "line": "I thought that was called Nasty Christmas.", "order": 4182, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 10, "scene": 24, "line": "Yeah, we call it White Elephant.", "order": 4183, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 10, "scene": 24, "line": "Well, I call it fun!", "order": 4184, "speaker": "Michael Scott"},
  {"season": 2, "episode": 10, "scene": 24, "line": "Why are we doing this?", "order": 4185, "speaker": "Oscar Martinez"},
  {"season": 2, "episode": 10, "scene": 24, "line": "Because it's better. Because it's more special.", "order": 4186, "speaker": "Michael Scott"},
  {"season": 2, "episode": 10, "scene": 24, "line": "It sounds mean.", "order": 4187, "speaker": "Angela Martin"},
  {"season": 2, "episode": 10, "scene": 24, "line": "Shut it. No, it's not. Okay, just give it a shot.", "order": 4188, "speaker": "Michael Scott"},
  {"season": 2, "episode": 10, "scene": 25, "line": "Michael should have asked the party planning committee first. He's not supposed to just spring things on us out of nowhere. [starts to cry]", "order": 4189, "speaker": "Angela Martin"},
  {"season": 2, "episode": 10, "scene": 26, "line": "Okay, Meredith is up first. Here's the deal. You can either pick a new gift or you can steal somebody else's gift that they've already gotten, like the oven mitt.", "order": 4190, "speaker": "Michael Scott"},
  {"season": 2, "episode": 10, "scene": 26, "line": "I'll take the teapot.", "order": 4191, "speaker": "Meredith Palmer"},
  {"season": 2, "episode": 10, "scene": 26, "line": "Oh, shouldn't we ... I bought that specifically for Pam.", "order": 4192, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 10, "scene": 26, "line": "Yankee Swap! That's what makes it fun. Pam, you can steal the oven mitt now.", "order": 4193, "speaker": "Michael Scott"},
  {"season": 2, "episode": 10, "scene": 26, "line": "I'll take the iPod.", "order": 4194, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 10, "scene": 26, "line": "And I have to give it to her? I don't have a choice?", "order": 4195, "speaker": "Ryan Howard"},
  {"season": 2, "episode": 10, "scene": 26, "line": "Yes, now you can steal the oven mitt, the old shirt or the shower radio or pick a new gift.", "order": 4196, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 10, "scene": 26, "line": "[after Ryan opens a new gift - a nameplate saying 'Kelly'] That was meant for Kelly.", "order": 4197, "speaker": "Stanley Hudson"},
  {"season": 2, "episode": 10, "scene": 26, "line": "Yeah, I figured.", "order": 4198, "speaker": "Ryan Howard"},
  {"season": 2, "episode": 10, "scene": 26, "line": "I think this is going great.", "order": 4199, "speaker": "Michael Scott"},
  {"season": 2, "episode": 10, "scene": 27, "line": "[unwrapping the poster] Yikes.", "order": 4200, "speaker": "Kelly Kapoor"},
  {"season": 2, "episode": 10, "scene": 27, "line": "Well, it's for Angela, so ..", "order": 4201, "speaker": "Toby Flenderson"},
  {"season": 2, "episode": 10, "scene": 27, "line": "That's like, the creepiest thing that I've ever seen.", "order": 4202, "speaker": "Kelly Kapoor"},
  {"season": 2, "episode": 10, "scene": 27, "line": "Angela, you're up.", "order": 4203, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 10, "scene": 27, "line": "I'll take the poster. Some people like these.", "order": 4204, "speaker": "Angela Martin"},
  {"season": 2, "episode": 10, "scene": 27, "line": "I will steal the iPod.", "order": 4205, "speaker": "Kelly Kapoor"},
  {"season": 2, "episode": 10, "scene": 28, "line": "Everyone wants the iPod. It's a huge hit. It is almost a Christmas miracle.", "order": 4206, "speaker": "Michael Scott"},
  {"season": 2, "episode": 10, "scene": 29, "line": "Oh, well, Oscar, you little gourmand, you have the next turn.", "order": 4207, "speaker": "Michael Scott"},
  {"season": 2, "episode": 10, "scene": 29, "line": "I'll take the ... teapot.", "order": 4208, "speaker": "Oscar Martinez"},
  {"season": 2, "episode": 10, "scene": 29, "line": "Damn it.", "order": 4209, "speaker": "Meredith Palmer"},
  {"season": 2, "episode": 10, "scene": 29, "line": "Okay, moving along. Meredith, let's go.", "order": 4210, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 10, "scene": 29, "line": "I really want the iPod.", "order": 4211, "speaker": "Meredith Palmer"},
  {"season": 2, "episode": 10, "scene": 29, "line": "It's already been stolen this round. Pick something else.", "order": 4212, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 10, "scene": 29, "line": "[holding oven mitt] I hope nobody takes this baby, cause this is great. Wow, look at that fine craftsmanship. Somebody really put a lot of work into that. It's beautiful.", "order": 4213, "speaker": "Michael Scott"},
  {"season": 2, "episode": 10, "scene": 29, "line": "I'll take the oven mitt.", "order": 4214, "speaker": "Meredith Palmer"},
  {"season": 2, "episode": 10, "scene": 29, "line": "Sucker! See, I wanted somebody to take it. Boom! Reverse psychology.", "order": 4215, "speaker": "Michael Scott"},
  {"season": 2, "episode": 10, "scene": 30, "line": "Reverse psychology is an awesome tool. I don't know if you guys know about it, but basically you make someone think the opposite of what you believe and that tricks them into doing something stupid. Works like a charm.", "order": 4216, "speaker": "Michael Scott"},
  {"season": 2, "episode": 10, "scene": 31, "line": "[opens present] 'In addition to these paintball pellets, your gift includes two paintball lessons with Dwight Schrute.'", "order": 4217, "speaker": "Michael Scott"},
  {"season": 2, "episode": 10, "scene": 31, "line": "You and me, Michael. Yes!", "order": 4218, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 10, "scene": 31, "line": "Who wants to take paintball lessons? How is that better than an iPod?", "order": 4219, "speaker": "Michael Scott"},
  {"season": 2, "episode": 10, "scene": 31, "line": "I never said it was better than an iPod.", "order": 4220, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 10, "scene": 32, "line": "Michael keeps bragging about his iPod, but you know what? Two paintball lessons with someone as experienced as I am is worth easily, like, 2 grand.", "order": 4221, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 10, "scene": 33, "line": "[shooting paintball gun at target] Take that, Saddam!", "order": 4222, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 10, "scene": 34, "line": "Last gift. Kevin.", "order": 4223, "speaker": "Michael Scott"},
  {"season": 2, "episode": 10, "scene": 34, "line": "I want the foot bath.", "order": 4224, "speaker": "Kevin Malone"},
  {"season": 2, "episode": 10, "scene": 35, "line": "That's the thing I bought myself. I'm really psyched to use it. [pauses] Maybe I should have taken the iPod. Oh, shoot.", "order": 4225, "speaker": "Kevin Malone"},
  {"season": 2, "episode": 10, "scene": 36, "line": "Pam, steal something or pick the final gift.", "order": 4226, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 10, "scene": 36, "line": "I want the iPod.", "order": 4227, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 10, "scene": 36, "line": "Damn it.", "order": 4228, "speaker": "Kelly Kapoor"},
  {"season": 2, "episode": 10, "scene": 36, "line": "Sure you don't want the teapot?", "order": 4229, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 10, "scene": 36, "line": "Well, I mean, it's an iPod. But ..", "order": 4230, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 10, "scene": 36, "line": "Right.", "order": 4231, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 10, "scene": 36, "line": "Sorry, I ..", "order": 4232, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 10, "scene": 36, "line": "No. No. Definitely. It's ..", "order": 4233, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 10, "scene": 36, "line": "Okay, well, I guess I will take that book of short stories.", "order": 4234, "speaker": "Kelly Kapoor"},
  {"season": 2, "episode": 10, "scene": 36, "line": "Yes! There you go. I want the teapot. Gracias.", "order": 4235, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 10, "scene": 36, "line": "Got to be kidding me.", "order": 4236, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 10, "scene": 37, "line": "Yankee Swap is like Machiavelli meets ... Christmas.", "order": 4237, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 10, "scene": 38, "line": "[after Phyllis leaves suddenly] What is she so upset about?", "order": 4238, "speaker": "Michael Scott"},
  {"season": 2, "episode": 10, "scene": 38, "line": "Maybe because you hated her present so much.", "order": 4239, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 10, "scene": 38, "line": "Come on! I think that Yankee Swap was a big hit! I think it's a success and I'm the one who ended up with Dwight's stupid paintball pellets.", "order": 4240, "speaker": "Michael Scott"},
  {"season": 2, "episode": 10, "scene": 38, "line": "Yeah, but, Michael, the point is that we all bought gifts for specific people.", "order": 4241, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 10, "scene": 38, "line": "And you should have just bought a $20 gift like everyone else.", "order": 4242, "speaker": "Stanley Hudson"},
  {"season": 2, "episode": 10, "scene": 38, "line": "Well, I didn't. I got a big bonus because I fired Devon, and I used the money to buy something awesome. Sue me!", "order": 4243, "speaker": "Michael Scott"},
  {"season": 2, "episode": 10, "scene": 38, "line": "You got a bonus check?", "order": 4244, "speaker": "Oscar Martinez"},
  {"season": 2, "episode": 10, "scene": 38, "line": "How much?", "order": 4245, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 10, "scene": 38, "line": "It wasn't. It wasn't that much. It was $3,000.", "order": 4246, "speaker": "Michael Scott"},
  {"season": 2, "episode": 10, "scene": 38, "line": "All right, I'm done now.", "order": 4247, "speaker": "Stanley Hudson"},
  {"season": 2, "episode": 10, "scene": 39, "line": "Unbelievable. I do the nicest thing that anyone has ever done for these people and they freak out. Well, happy birthday, Jesus. Sorry your party's so lame.", "order": 4248, "speaker": "Michael Scott"},
  {"season": 2, "episode": 10, "scene": 40, "line": "It comes to $166.41.", "order": 4249, "speaker": "Liquor Store Clerk"},
  {"season": 2, "episode": 10, "scene": 40, "line": "All right, now, you're the expert. Is this enough to get 20 people plastered?", "order": 4250, "speaker": "Michael Scott"},
  {"season": 2, "episode": 10, "scene": 40, "line": "Fifteen bottles of vodka? Yeah, that should do it.", "order": 4251, "speaker": "Liquor Store Clerk"},
  {"season": 2, "episode": 10, "scene": 40, "line": "Cool, cool. Box it up.", "order": 4252, "speaker": "Michael Scott"},
  {"season": 2, "episode": 10, "scene": 41, "line": "I bought this teapot for Pam, and I know she really wants it. So, can I trade you for it?", "order": 4253, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 10, "scene": 41, "line": "No trades.", "order": 4254, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 10, "scene": 41, "line": "Come on, it's a shamrock keychain. Good luck.", "order": 4255, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 10, "scene": 41, "line": "A real man makes his own luck.' Billy Zane.", "order": 4256, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 10, "scene": 41, "line": "Look, it has sentimental value, Dwight. Can I buy it from you?", "order": 4257, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 10, "scene": 41, "line": "No. I want it. I'm going to use it.", "order": 4258, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 10, "scene": 41, "line": "You don't even drink tea.", "order": 4259, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 10, "scene": 41, "line": "True. But I get sinus infections, and sinus infections can be cured by making your tea from green tea leaf stems ..", "order": 4260, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 10, "scene": 41, "line": "Okay ..", "order": 4261, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 10, "scene": 41, "line": ".. and pouring it directly into your nose, like so. [demonstrates]", "order": 4262, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 10, "scene": 42, "line": "To think that my gift for Pam will be used for that, it's a little too much to handle.", "order": 4263, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 10, "scene": 43, "line": "This is awesome.", "order": 4264, "speaker": "Roy Anderson"},
  {"season": 2, "episode": 10, "scene": 43, "line": "I know. It's totally going to change the way I work out.", "order": 4265, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 10, "scene": 43, "line": "Yeah, I was gonna get you one of these for Christmas, and now I don't have to. I'm gonna save a ton of money.", "order": 4266, "speaker": "Roy Anderson"},
  {"season": 2, "episode": 10, "scene": 43, "line": "So what are you going to get me instead?", "order": 4267, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 10, "scene": 43, "line": "I don't know. Probably like, a sweater or something.", "order": 4268, "speaker": "Roy Anderson"},
  {"season": 2, "episode": 10, "scene": 44, "line": "Uh-oh. Looks like Santa was a little naughty.", "order": 4269, "speaker": "Michael Scott"},
  {"season": 2, "episode": 10, "scene": 44, "line": "What is that?", "order": 4270, "speaker": "Angela Martin"},
  {"season": 2, "episode": 10, "scene": 44, "line": "This is Christmas spirit, as in spirits, booze.", "order": 4271, "speaker": "Michael Scott"},
  {"season": 2, "episode": 10, "scene": 44, "line": "We can drink?", "order": 4272, "speaker": "Meredith Palmer"},
  {"season": 2, "episode": 10, "scene": 44, "line": "We're really not supposed to serve alcohol.", "order": 4273, "speaker": "Toby Flenderson"},
  {"season": 2, "episode": 10, "scene": 44, "line": "Zip it, Toby! Just .. I mean, it's a party. Come on. If I can't throw a good party for my employees, then I am a terrible boss. Who wants a drink?", "order": 4274, "speaker": "Michael Scott"},
  {"season": 2, "episode": 10, "scene": 44, "line": "Me. Please.", "order": 4275, "speaker": "Meredith Palmer"},
  {"season": 2, "episode": 10, "scene": 44, "line": "Go, here we go!", "order": 4276, "speaker": "Michael Scott"},
  {"season": 2, "episode": 10, "scene": 45, "line": "The deal is that this is my last hurrah, cause I made a New Year's resolution that I'm not going to drink anymore. During the week.", "order": 4277, "speaker": "Meredith Palmer"},
  {"season": 2, "episode": 10, "scene": 46, "line": "Hi guys.", "order": 4278, "speaker": "Phyllis Lapin"},
  {"season": 2, "episode": 10, "scene": 46, "line": "Hey.", "order": 4279, "speaker": "Ryan Howard"},
  {"season": 2, "episode": 10, "scene": 46, "line": "Does everybody know my boyfriend, Bob Vance?", "order": 4280, "speaker": "Phyllis Lapin"},
  {"season": 2, "episode": 10, "scene": 46, "line": "Kevin Malone.", "order": 4281, "speaker": "Kevin Malone"},
  {"season": 2, "episode": 10, "scene": 46, "line": "Bob Vance, Vance Refrigeration.", "order": 4282, "speaker": "Bob Vance"},
  {"season": 2, "episode": 10, "scene": 46, "line": "Stanley Hudson.", "order": 4283, "speaker": "Stanley Hudson"},
  {"season": 2, "episode": 10, "scene": 46, "line": "Bob Vance, Vance Refrigeration.", "order": 4284, "speaker": "Bob Vance"},
  {"season": 2, "episode": 10, "scene": 46, "line": "Ryan Howard.", "order": 4285, "speaker": "Ryan Howard"},
  {"season": 2, "episode": 10, "scene": 46, "line": "Bob Vance, Vance Refrigeration.", "order": 4286, "speaker": "Bob Vance"},
  {"season": 2, "episode": 10, "scene": 46, "line": "What line of work you in, Bob?", "order": 4287, "speaker": "Ryan Howard"},
  {"season": 2, "episode": 10, "scene": 47, "line": "I think after I lost Culpepper and T.O it was over, man.", "order": 4288, "speaker": "Roy Anderson"},
  {"season": 2, "episode": 10, "scene": 47, "line": "Oh, yeah, I need McMahon, Deion Branch to have big games or else I'm done.", "order": 4289, "speaker": "Darryl Philbin"},
  {"season": 2, "episode": 10, "scene": 47, "line": "It's possible. I can't believe you traded Shaun Alexander, man.", "order": 4290, "speaker": "Roy Anderson"},
  {"season": 2, "episode": 10, "scene": 47, "line": "I had to. I needed defense.", "order": 4291, "speaker": "Darryl Philbin"},
  {"season": 2, "episode": 10, "scene": 47, "line": "Come on! Shaun Alexander? He's the best back in the league.", "order": 4292, "speaker": "Roy Anderson"},
  {"season": 2, "episode": 10, "scene": 47, "line": "It's defense.", "order": 4293, "speaker": "Darryl Philbin"},
  {"season": 2, "episode": 10, "scene": 47, "line": "Oh, no. That is not worth it.", "order": 4294, "speaker": "Roy Anderson"},
  {"season": 2, "episode": 10, "scene": 47, "line": "It is worth it.", "order": 4295, "speaker": "Darryl Philbin"},
  {"season": 2, "episode": 10, "scene": 47, "line": "Never.", "order": 4296, "speaker": "Roy Anderson"},
  {"season": 2, "episode": 10, "scene": 47, "line": "Are you kidding? You wait.", "order": 4297, "speaker": "Darryl Philbin"},
  {"season": 2, "episode": 10, "scene": 48, "line": "Anybody making out in here? [checks hallway] Not yet, give it time. Oh, hey, Ebenezer, boink. [takes picture of Jim] Okay, how's it going in here? [takes picture of Meredith and Kevin]", "order": 4298, "speaker": "Michael Scott"},
  {"season": 2, "episode": 10, "scene": 48, "line": "We're running low on cups. Do you want me to just run out and get some?", "order": 4299, "speaker": "Ryan Howard"},
  {"season": 2, "episode": 10, "scene": 48, "line": "There should be some ..", "order": 4300, "speaker": "Angela Martin"},
  {"season": 2, "episode": 10, "scene": 48, "line": "No, no, no, no. We'll find some, don't leave the party.", "order": 4301, "speaker": "Michael Scott"},
  {"season": 2, "episode": 10, "scene": 49, "line": "One, two, three. [do a shot]", "order": 4302, "speaker": "Phyllis Lapin, Meredith Palmer, Michael Scott, Kevin Malone"},
  {"season": 2, "episode": 10, "scene": 49, "line": "Kudos to Ryan, king of the party committee.", "order": 4303, "speaker": "Michael Scott"},
  {"season": 2, "episode": 10, "scene": 49, "line": "Oh, no.", "order": 4304, "speaker": "Ryan Howard"},
  {"season": 2, "episode": 10, "scene": 49, "line": "Yeah, yeah, yeah.", "order": 4305, "speaker": "Michael Scott"},
  {"season": 2, "episode": 10, "scene": 49, "line": "I really did not do anything.", "order": 4306, "speaker": "Ryan Howard"},
  {"season": 2, "episode": 10, "scene": 49, "line": "Oh, no, no. No false modesty, my friend.", "order": 4307, "speaker": "Michael Scott"},
  {"season": 2, "episode": 10, "scene": 50, "line": "You know, you don't have to answer calls during a party. Just thought you should know.", "order": 4308, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 10, "scene": 50, "line": "[laughs] No, I was just checking out my present. [holds up teapot]", "order": 4309, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 10, "scene": 50, "line": "But ..", "order": 4310, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 10, "scene": 50, "line": "I traded with Dwight. Just, I figured, you know, you went to a lot of trouble and it means a lot. And also, Roy got me an iPod or was going to get me an iPod, so ..", "order": 4311, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 10, "scene": 50, "line": "Well, either way. This is an amazing gift because it comes with bonus gifts. Look inside.", "order": 4312, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 10, "scene": 50, "line": "[opens teapot] Oh my god! The yearbook picture!", "order": 4313, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 10, "scene": 51, "line": "Yeah, I think I made the right choice.", "order": 4314, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 10, "scene": 52, "line": "Oh, my God! It's incredible. [Jim reaches and steals his card back before she can notice it] Is this the Boggle timer?", "order": 4315, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 10, "scene": 52, "line": "I didn't think you were going to get that one. I really didn't.", "order": 4316, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 10, "scene": 53, "line": "This is so awesome.", "order": 4317, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 10, "scene": 53, "line": "Not bad. And if it couldn't go to Ryan, you are the guy I'd want it to go to.", "order": 4318, "speaker": "Michael Scott"},
  {"season": 2, "episode": 10, "scene": 53, "line": "Thank you.", "order": 4319, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 10, "scene": 53, "line": "You're welcome.", "order": 4320, "speaker": "Michael Scott"},
  {"season": 2, "episode": 10, "scene": 53, "line": "[grabbing Michael around the neck] Merry Christmas, asswipe!", "order": 4321, "speaker": "Todd Packer"},
  {"season": 2, "episode": 10, "scene": 53, "line": "No way. Oh, you're kidding me. Packer! Yes! Todd Packer, ladies and gentlemen!", "order": 4322, "speaker": "Michael Scott"},
  {"season": 2, "episode": 10, "scene": 53, "line": "[rapping] What's up my nerds. Check it out. [points at the mistletoe stuck down his pants]", "order": 4323, "speaker": "Todd Packer"},
  {"season": 2, "episode": 10, "scene": 53, "line": "Oh, no, no. Oh look at that. Icing on the cake.", "order": 4324, "speaker": "Michael Scott"},
  {"season": 2, "episode": 10, "scene": 53, "line": "Pacman need a drinky.", "order": 4325, "speaker": "Todd Packer"},
  {"season": 2, "episode": 10, "scene": 53, "line": "Oh, let's fix you up. Who wants to fix up .. Toby. Toby's gonna fix you up.", "order": 4326, "speaker": "Michael Scott"},
  {"season": 2, "episode": 10, "scene": 54, "line": "[listening to music through headphones] Yeahhh.", "order": 4327, "speaker": "Kevin Malone"},
  {"season": 2, "episode": 10, "scene": 55, "line": "Darryl. There you go. [hands him the Santa hat] You earned it.", "order": 4328, "speaker": "Michael Scott"},
  {"season": 2, "episode": 10, "scene": 55, "line": "That's okay, Mike.", "order": 4329, "speaker": "Darryl Philbin"},
  {"season": 2, "episode": 10, "scene": 55, "line": "No, no, no, no. I really, really want you to have it.", "order": 4330, "speaker": "Michael Scott"},
  {"season": 2, "episode": 10, "scene": 55, "line": "All right. Thanks, man.", "order": 4331, "speaker": "Darryl Philbin"},
  {"season": 2, "episode": 10, "scene": 55, "line": "Hey, Merry Christmas.", "order": 4332, "speaker": "Michael Scott"},
  {"season": 2, "episode": 10, "scene": 56, "line": "[looking at Xeroxed butt pictures] Whose butt is that?", "order": 4333, "speaker": "Ryan Howard"},
  {"season": 2, "episode": 10, "scene": 56, "line": "Mine.", "order": 4334, "speaker": "Kevin Malone"},
  {"season": 2, "episode": 10, "scene": 56, "line": "Oh, how did I not guess that?", "order": 4335, "speaker": "Ryan Howard"},
  {"season": 2, "episode": 10, "scene": 57, "line": "[coming out of his office] Lampshade on head! It's happening!", "order": 4336, "speaker": "Michael Scott"},
  {"season": 2, "episode": 10, "scene": 58, "line": "[as Jim decorates a passed out Todd Packer] Oh, no.", "order": 4337, "speaker": "Creed Bratton"},
  {"season": 2, "episode": 10, "scene": 59, "line": "Hey.", "order": 4338, "speaker": "Kelly Kapoor"},
  {"season": 2, "episode": 10, "scene": 59, "line": "Oh, hello there. [Kelly leans up and kisses him] But what are you doing?", "order": 4339, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 10, "scene": 59, "line": "I don't know.", "order": 4340, "speaker": "Kelly Kapoor"},
  {"season": 2, "episode": 10, "scene": 59, "line": "You shouldn't do things like that. The man is supposed to do that.", "order": 4341, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 10, "scene": 60, "line": "Thanks for the party, Michael.", "order": 4342, "speaker": "Kevin Malone"},
  {"season": 2, "episode": 10, "scene": 60, "line": "Yeah.", "order": 4343, "speaker": "Meredith Palmer"},
  {"season": 2, "episode": 10, "scene": 60, "line": "Oh, hey. Listen up. We're going to Poor Richard's. Who's in?", "order": 4344, "speaker": "Bob Vance"},
  {"season": 2, "episode": 10, "scene": 60, "line": "I'm in.", "order": 4345, "speaker": "Oscar Martinez"},
  {"season": 2, "episode": 10, "scene": 60, "line": "Yes.", "order": 4346, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 10, "scene": 60, "line": "Michael? Poor Richard's?", "order": 4347, "speaker": "Oscar Martinez"},
  {"season": 2, "episode": 10, "scene": 60, "line": "Yeah, that sounds good.", "order": 4348, "speaker": "Michael Scott"},
  {"season": 2, "episode": 10, "scene": 61, "line": "Christmas is awesome. First of all, you get to spend time with people you love. Secondly, you can get drunk and no one can say anything. Third, you give presents. What's better than giving presents? And fourth, getting presents. So, four things. Not bad for one day. It's really the greatest day of all time.", "order": 4349, "speaker": "Michael Scott"},
  {"season": 2, "episode": 10, "scene": 62, "line": "Hey, Meredith. Heading over to Poor Richard's?", "order": 4350, "speaker": "Michael Scott"},
  {"season": 2, "episode": 10, "scene": 62, "line": "Yep.", "order": 4351, "speaker": "Meredith Palmer"},
  {"season": 2, "episode": 10, "scene": 62, "line": "Cool, cool, cool. Do you need a ride? [Meredith drops her top. Michael takes a picture] All right, let's head out. Sounds good. Do you have a coat?", "order": 4352, "speaker": "Michael Scott"},
  {"season": 2, "episode": 10, "scene": 62, "line": "Yeah.", "order": 4353, "speaker": "Meredith Palmer"},
  {"season": 2, "episode": 10, "scene": 62, "line": "Okay!", "order": 4354, "speaker": "Michael Scott"},
  {"season": 2, "episode": 10, "scene": 66, "line": "Phyllis and Angela and Meredith are helpers. I guess Angela's kind of... Angela's little. Phyllis and Meredith are not little. Pam is sort of a medium helper.", "order": 4355, "speaker": "Michael Scott"},
  {"season": 2, "episode": 10, "scene": 68, "line": "Dwight is... Dwight looks like Spock to me. He always wears the ears, and I always give him grief about looking like Spock, and he hates it because he has a different pair of ears that he wears to be Spock, for some reason.", "order": 4356, "speaker": "Michael Scott"},
  {"season": 2, "episode": 10, "scene": 70, "line": "I invited someone to the party. Bob Vance. He works at Vance Refrigeration next door. That's how we met. [whispers] He's my boyfriend.", "order": 4357, "speaker": "Phyllis Lapin"},
  {"season": 2, "episode": 10, "scene": 72, "line": "What separates humans from jungle beasts? The fact that we have rules. When humans give each other gifts, they do it properly and orderly. When bears give each other gifts they just rip open a fox carcass and present it to their mate. I prefer the human way.", "order": 4358, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 10, "scene": 74, "line": "I don't know why Oscar didn't like the gift. I know he wanted a shower radio. I mean, I totally remember him telling me that he wanted one.", "order": 4359, "speaker": "Kelly Kapoor"},
  {"season": 2, "episode": 10, "scene": 74, "line": "Kelly had me last year, too, and she gave me the same exact thing. I only have one shower.", "order": 4360, "speaker": "Oscar Martinez"},
  {"season": 2, "episode": 10, "scene": 78, "line": "I'm the DJ because I really know how to heat up a party.", "order": 4361, "speaker": "Kevin Malone"},
  {"season": 2, "episode": 10, "scene": 82, "line": "Turns out that Creed is a pretty interesting guy.", "order": 4362, "speaker": "Oscar Martinez"},
  {"season": 2, "episode": 10, "scene": 84, "line": "See, that's what Christmas is all about to me, is when you see someone open a gift and you think, 'Man, I wish I got that.' I mean, that's just such a great feeling, to watch other people envying a gift that you gave to somebody else. I don't know, it's just... I don't know, just makes you kind of feel good.", "order": 4363, "speaker": "Michael Scott"},
  {"season": 2, "episode": 11, "scene": 1, "line": "Hey.", "order": 4364, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 11, "scene": 1, "line": "Hello. Jim?", "order": 4365, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 11, "scene": 1, "line": "What's up, buddy?", "order": 4366, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 11, "scene": 1, "line": "This is not funny. Why is my stuff in here?", "order": 4367, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 11, "scene": 1, "line": "Wow, that's weird. Oh, dollar for a stapler, that's pretty good.", "order": 4368, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 11, "scene": 1, "line": "Yeah, well, I'm not paying for my own stuff, okay? I know you did this, because you're friends with the vending machine guy.", "order": 4369, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 11, "scene": 1, "line": "Who, Steve?", "order": 4370, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 11, "scene": 1, "line": "Yeah, Steve, whatever his name is.", "order": 4371, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 11, "scene": 1, "line": "Sorry. What do I want? What do I want... Oh, it's a pencil cup.", "order": 4372, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 11, "scene": 1, "line": "No, no, no, no, no. That's my pencil cup.", "order": 4373, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 11, "scene": 1, "line": "Um, I don't think so, I just bought it.", "order": 4374, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 11, "scene": 1, "line": "Uh, I think so, and you're going to hand it over to me.", "order": 4375, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 11, "scene": 1, "line": "I love these.", "order": 4376, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 11, "scene": 1, "line": "Okay, fine. Where's my wallet?", "order": 4377, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 11, "scene": 1, "line": "Oh, there it is. J1.", "order": 4378, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 11, "scene": 1, "line": "But I don't have any...", "order": 4379, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 11, "scene": 1, "line": "Here, you know what? You can have some nickels.", "order": 4380, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 11, "scene": 1, "line": "[putting quarters in] Five, ten, fifteen, twenty, twenty-five...", "order": 4381, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 11, "scene": 2, "line": "Hello, everyone.", "order": 4382, "speaker": "Michael Scott"},
  {"season": 2, "episode": 11, "scene": 2, "line": "Good morning, Michael.", "order": 4383, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 11, "scene": 2, "line": "Where are we going this afternoon?", "order": 4384, "speaker": "Phyllis Lapin"},
  {"season": 2, "episode": 11, "scene": 2, "line": "Ah! Ha ha ha!", "order": 4385, "speaker": "Michael Scott"},
  {"season": 2, "episode": 11, "scene": 3, "line": "Last week, Michael sent out this mysterious memo.", "order": 4386, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 11, "scene": 3, "line": "It's time for our first quarter camaraderie event, so pack a swimsuit, a toothbrush, rubber-soled shoes, and a ski mask.'", "order": 4387, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 11, "scene": 3, "line": "A ski mask and a swimsuit.", "order": 4388, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 11, "scene": 3, "line": "So that he can have us rob a bank, and then escape through the sewers.", "order": 4389, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 11, "scene": 3, "line": "And brush our teeth.", "order": 4390, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 11, "scene": 4, "line": "Yeah?", "order": 4391, "speaker": "Michael Scott"},
  {"season": 2, "episode": 11, "scene": 4, "line": "Michael.", "order": 4392, "speaker": "Stanley Hudson"},
  {"season": 2, "episode": 11, "scene": 4, "line": "Stanley! Bo banley.", "order": 4393, "speaker": "Michael Scott"},
  {"season": 2, "episode": 11, "scene": 4, "line": "I need to know...", "order": 4394, "speaker": "Stanley Hudson"},
  {"season": 2, "episode": 11, "scene": 4, "line": "Banana fana fo fanley.", "order": 4395, "speaker": "Michael Scott"},
  {"season": 2, "episode": 11, "scene": 4, "line": "What we're doing.", "order": 4396, "speaker": "Stanley Hudson"},
  {"season": 2, "episode": 11, "scene": 4, "line": "Be my mo manley.", "order": 4397, "speaker": "Michael Scott"},
  {"season": 2, "episode": 11, "scene": 4, "line": "You said bring a toothbrush.", "order": 4398, "speaker": "Stanley Hudson"},
  {"season": 2, "episode": 11, "scene": 4, "line": "Stanley.", "order": 4399, "speaker": "Michael Scott"},
  {"season": 2, "episode": 11, "scene": 4, "line": "Is this an overnight?", "order": 4400, "speaker": "Stanley Hudson"},
  {"season": 2, "episode": 11, "scene": 4, "line": "Maybe. The suspense is just so exciting, isn't it?", "order": 4401, "speaker": "Michael Scott"},
  {"season": 2, "episode": 11, "scene": 4, "line": "Should my wife tell her boss she's not coming in tomorrow?", "order": 4402, "speaker": "Stanley Hudson"},
  {"season": 2, "episode": 11, "scene": 4, "line": "Maybe, I don't know.", "order": 4403, "speaker": "Michael Scott"},
  {"season": 2, "episode": 11, "scene": 4, "line": "Not maybe. Yes or no.", "order": 4404, "speaker": "Stanley Hudson"},
  {"season": 2, "episode": 11, "scene": 4, "line": "Well, no. But... okay, don't spoil it for everybody, all right? But we are going on a booze cruise on Lake Wallenpaupack.", "order": 4405, "speaker": "Michael Scott"},
  {"season": 2, "episode": 11, "scene": 4, "line": "In January?", "order": 4406, "speaker": "Stanley Hudson"},
  {"season": 2, "episode": 11, "scene": 4, "line": "It's cheaper.", "order": 4407, "speaker": "Michael Scott"},
  {"season": 2, "episode": 11, "scene": 5, "line": "This is not just another party. This is a leadership training exercise. Right? I'm going to combine elements of fun and motivation and education into a single mind-blowing experience.", "order": 4408, "speaker": "Michael Scott"},
  {"season": 2, "episode": 11, "scene": 6, "line": "It is now time to unveil the destination of this year's retreat. We are going on a harbor cruise of Lake Wallenpaupack. It's a booze cruise!", "order": 4409, "speaker": "Michael Scott"},
  {"season": 2, "episode": 11, "scene": 6, "line": "All right!", "order": 4410, "speaker": "Meredith Palmer"},
  {"season": 2, "episode": 11, "scene": 6, "line": "I have a test for business school tomorrow night. Is it okay if I skip the cruise and study for that?", "order": 4411, "speaker": "Ryan Howard"},
  {"season": 2, "episode": 11, "scene": 6, "line": "No. This is mandatory. But don't worry, you know what? You're gonna learn plenty. This is gonna turn your life around, Ryan.", "order": 4412, "speaker": "Michael Scott"},
  {"season": 2, "episode": 11, "scene": 6, "line": "I'm already in business school.", "order": 4413, "speaker": "Ryan Howard"},
  {"season": 2, "episode": 11, "scene": 6, "line": "Well, this...", "order": 4414, "speaker": "Michael Scott"},
  {"season": 2, "episode": 11, "scene": 6, "line": "Wait, Michael?", "order": 4415, "speaker": "Kelly Kapoor"},
  {"season": 2, "episode": 11, "scene": 6, "line": "Yeah?", "order": 4416, "speaker": "Michael Scott"},
  {"season": 2, "episode": 11, "scene": 6, "line": "Why did you tell us to bring a bathing suit?", "order": 4417, "speaker": "Kelly Kapoor"},
  {"season": 2, "episode": 11, "scene": 6, "line": "To throw you off the scent.", "order": 4418, "speaker": "Michael Scott"},
  {"season": 2, "episode": 11, "scene": 6, "line": "Yeah, but I bought a bathing suit.", "order": 4419, "speaker": "Kelly Kapoor"},
  {"season": 2, "episode": 11, "scene": 6, "line": "Well, just keep the tags on and you can return it.", "order": 4420, "speaker": "Michael Scott"},
  {"season": 2, "episode": 11, "scene": 6, "line": "I took the tags off already.", "order": 4421, "speaker": "Kelly Kapoor"},
  {"season": 2, "episode": 11, "scene": 6, "line": "Well, that's not my fault, okay? Just.. we're not going to pay for a bathing suit. Okay, I know what you're all thinking, 'Who is this smart little cookie?' Her name is Brenda... something, and she is from corporate. And she is here, like you, to learn from what I have to say.", "order": 4422, "speaker": "Michael Scott"},
  {"season": 2, "episode": 11, "scene": 7, "line": "I am a great motivational speaker. I attended a Tony Robbins event by the airport last year, and... it wasn't the actual course. You have to pay for the actual course. But it talked about the actual course. And I've incorporated a lot of his ideas into my own course.", "order": 4423, "speaker": "Michael Scott"},
  {"season": 2, "episode": 11, "scene": 8, "line": "Leader... ship. The word 'ship' is hidden inside the word 'leadership,' as its derivation. So if this office is, in fact, a ship, as its leader, I am the captain. But we're all in the same boat. Teamwork!", "order": 4424, "speaker": "Michael Scott"},
  {"season": 2, "episode": 11, "scene": 9, "line": "Last year, Michael's theme was 'Bowl over the Competition!' So guess where we went.", "order": 4425, "speaker": "Oscar Martinez"},
  {"season": 2, "episode": 11, "scene": 10, "line": "Now, on this ship that is the office, what is a sales department? Anyone?", "order": 4426, "speaker": "Michael Scott"},
  {"season": 2, "episode": 11, "scene": 10, "line": "How about the sales department is the sails?", "order": 4427, "speaker": "Darryl Philbin"},
  {"season": 2, "episode": 11, "scene": 10, "line": "Yes, Darryl, the sales department makes sales. Good. Let me just explain. I see the sales department as the furnace.", "order": 4428, "speaker": "Michael Scott"},
  {"season": 2, "episode": 11, "scene": 10, "line": "A furnace?", "order": 4429, "speaker": "Phyllis Lapin"},
  {"season": 2, "episode": 11, "scene": 10, "line": "Yeesh, how old is this ship?", "order": 4430, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 11, "scene": 10, "line": "How about the anchor?", "order": 4431, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 11, "scene": 10, "line": "What does the furnace do?", "order": 4432, "speaker": "Phyllis Lapin"},
  {"season": 2, "episode": 11, "scene": 10, "line": "All right, let's not get hung up on the furnace. This just... it's the sales... I see the sales department down there. They're in the engine room, and they are shoveling coal into the furnace, right? I mean, who saw the movie Titanic? They were very important in the movie Titanic. Who saw it? Show of hands!", "order": 4433, "speaker": "Michael Scott"},
  {"season": 2, "episode": 11, "scene": 10, "line": "I'm not really sure what movie you're talking about. Are you sure you got the title right?", "order": 4434, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 11, "scene": 10, "line": "Titanic?", "order": 4435, "speaker": "Michael Scott"},
  {"season": 2, "episode": 11, "scene": 10, "line": "I think you're thinking of The Hunt for Red October.", "order": 4436, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 11, "scene": 10, "line": "No, I'm Leo DiCaprio! Come on!", "order": 4437, "speaker": "Michael Scott"},
  {"season": 2, "episode": 11, "scene": 11, "line": "Michael stands in the front of the boat and says that he's king of the world within the first hour, or I give you my next paycheck.", "order": 4438, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 11, "scene": 12, "line": "Michael, everyone in the engine room drowned.", "order": 4439, "speaker": "Phyllis Lapin"},
  {"season": 2, "episode": 11, "scene": 12, "line": "No! Thank you, spoiler alert. You saw the movie, those of you who did. They're happy down there in the furnace room. And they're dirty and grimy and sweaty, and they're singing their ethnic songs, and... actually, that might be warehouse.", "order": 4440, "speaker": "Michael Scott"},
  {"season": 2, "episode": 11, "scene": 12, "line": "What?", "order": 4441, "speaker": "Darryl Philbin"},
  {"season": 2, "episode": 11, "scene": 12, "line": "The... no, no. No, I didn't... okay. Well, okay, in a nutshell, what I'm saying is... leadership. We'll talk more about that on the boat. Ship.", "order": 4442, "speaker": "Michael Scott"},
  {"season": 2, "episode": 11, "scene": 12, "line": "Aye aye, Captain.", "order": 4443, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 11, "scene": 13, "line": "[singing] A three-hour tour, a three-hour tour.", "order": 4444, "speaker": "Michael Scott"},
  {"season": 2, "episode": 11, "scene": 14, "line": "Pam, you are Mary Ann! We have the Professor and Ginger, welcome aboard. Angela, you are Mrs. Howell. Lovey. [to Kelly] Uh... the native. Sometimes they come from neighboring... [to Stanley] We have one of the Globetrotters, I am the Skipper, and Dwight, you will be Gilligan.", "order": 4445, "speaker": "Michael Scott"},
  {"season": 2, "episode": 11, "scene": 14, "line": "Cool.", "order": 4446, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 11, "scene": 14, "line": "Actually, I'm the Skipper. But you can be Gilligan.", "order": 4447, "speaker": "Captain Jack"},
  {"season": 2, "episode": 11, "scene": 14, "line": "I'd rather die. Hi, I am Michael Scott, I am the captain of this party.", "order": 4448, "speaker": "Michael Scott"},
  {"season": 2, "episode": 11, "scene": 14, "line": "I am Captain Jack, I am captain of the ship. I'm also captain of anyone who sets foot on the ship. [to boarding passengers] Hi, welcome aboard.", "order": 4449, "speaker": "Captain Jack"},
  {"season": 2, "episode": 11, "scene": 14, "line": "Okay.", "order": 4450, "speaker": "Michael Scott"},
  {"season": 2, "episode": 11, "scene": 15, "line": "In an office, when you are ranking people, manager is higher than captain. On a boat, who knows? It's nebulose.", "order": 4451, "speaker": "Michael Scott"},
  {"season": 2, "episode": 11, "scene": 16, "line": "Hey, look! I'm king of the world!", "order": 4452, "speaker": "Michael Scott"},
  {"season": 2, "episode": 11, "scene": 17, "line": "Okay, all right! Welcome aboard! I am your captain, Captain Jack.", "order": 4453, "speaker": "Captain Jack"},
  {"season": 2, "episode": 11, "scene": 17, "line": "And I am the regional manager of Dunder-Mifflin, Michael Scott. Welcome, welcome!", "order": 4454, "speaker": "Michael Scott"},
  {"season": 2, "episode": 11, "scene": 17, "line": "Okay! So...", "order": 4455, "speaker": "Captain Jack"},
  {"season": 2, "episode": 11, "scene": 17, "line": "Okay! So...", "order": 4456, "speaker": "Michael Scott"},
  {"season": 2, "episode": 11, "scene": 17, "line": "Please. The life preservers.", "order": 4457, "speaker": "Captain Jack"},
  {"season": 2, "episode": 11, "scene": 17, "line": "Right.", "order": 4458, "speaker": "Michael Scott"},
  {"season": 2, "episode": 11, "scene": 17, "line": "They are located underneath the seats, all along the border of the boat.", "order": 4459, "speaker": "Captain Jack"},
  {"season": 2, "episode": 11, "scene": 17, "line": "But don't worry, you are not going to be needing life preservers tonight.", "order": 4460, "speaker": "Michael Scott"},
  {"season": 2, "episode": 11, "scene": 17, "line": "Well, we might, okay? Please let me finish, okay? Thank you. So, the Coast Guard requires that I tell you where the safety exits are. On this ship, it's very easy. Anywhere over the side. [Dwight laughs loudly.] Not only am I your ship captain, I am also your party captain! Whoo! We're gonna get it going in just a few minutes here...", "order": 4461, "speaker": "Captain Jack"},
  {"season": 2, "episode": 11, "scene": 17, "line": "I'm your party captain too! And you are gonna put on your dancing shoes later on! So we are gonna...", "order": 4462, "speaker": "Michael Scott"},
  {"season": 2, "episode": 11, "scene": 17, "line": "Okay, Michael, if you don't mind...", "order": 4463, "speaker": "Captain Jack"},
  {"season": 2, "episode": 11, "scene": 17, "line": "Rock it!", "order": 4464, "speaker": "Michael Scott"},
  {"season": 2, "episode": 11, "scene": 17, "line": "Please, okay?", "order": 4465, "speaker": "Captain Jack"},
  {"season": 2, "episode": 11, "scene": 17, "line": "If the boat's a-rockin', don't come knockin'!", "order": 4466, "speaker": "Michael Scott"},
  {"season": 2, "episode": 11, "scene": 17, "line": "Michael.", "order": 4467, "speaker": "Captain Jack"},
  {"season": 2, "episode": 11, "scene": 17, "line": "Yep.", "order": 4468, "speaker": "Michael Scott"},
  {"season": 2, "episode": 11, "scene": 17, "line": "Your company's employees are not the only people on the boat tonight, okay?", "order": 4469, "speaker": "Captain Jack"},
  {"season": 2, "episode": 11, "scene": 17, "line": "We're all gonna have a good time tonight!", "order": 4470, "speaker": "Michael Scott"},
  {"season": 2, "episode": 11, "scene": 17, "line": "Why don't you let me and my crew do our job. You just sit back and have a good time. All right?", "order": 4471, "speaker": "Captain Jack"},
  {"season": 2, "episode": 11, "scene": 17, "line": "Hm? Okay. Yep.", "order": 4472, "speaker": "Michael Scott"},
  {"season": 2, "episode": 11, "scene": 18, "line": "You guys, it's like we're in high school and we're at the cool table. Right?", "order": 4473, "speaker": "Katy Moore"},
  {"season": 2, "episode": 11, "scene": 18, "line": "Yeah.", "order": 4474, "speaker": "Roy Anderson"},
  {"season": 2, "episode": 11, "scene": 18, "line": "Pam, were you a cheerleader?", "order": 4475, "speaker": "Katy Moore"},
  {"season": 2, "episode": 11, "scene": 18, "line": "No, she was totally Miss Artsy-Fartsy in high school. She wore the turtleneck and everything!", "order": 4476, "speaker": "Roy Anderson"},
  {"season": 2, "episode": 11, "scene": 18, "line": "That's hilarious.", "order": 4477, "speaker": "Katy Moore"},
  {"season": 2, "episode": 11, "scene": 18, "line": "It's not hilarious, but...", "order": 4478, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 11, "scene": 18, "line": "Where did you go to school?", "order": 4479, "speaker": "Roy Anderson"},
  {"season": 2, "episode": 11, "scene": 18, "line": "Bishop O'Hara.", "order": 4480, "speaker": "Katy Moore"},
  {"season": 2, "episode": 11, "scene": 18, "line": "Piss slop who cares-a? We played you! You... you really look familiar. Did you... you cheered for them, didn't you?", "order": 4481, "speaker": "Roy Anderson"},
  {"season": 2, "episode": 11, "scene": 18, "line": "Um, no.", "order": 4482, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 11, "scene": 18, "line": "Yes, I did! [chanting] A-W-E-S-O-M-E! Awesome! Awesome is what we are! We're the football superstars! A-W-E-S-O-M-E!", "order": 4483, "speaker": "Katy Moore"},
  {"season": 2, "episode": 11, "scene": 18, "line": "I remember that! We crushed you like 42-10!", "order": 4484, "speaker": "Roy Anderson"},
  {"season": 2, "episode": 11, "scene": 19, "line": "Having fun?", "order": 4485, "speaker": "Michael Scott"},
  {"season": 2, "episode": 11, "scene": 19, "line": "Yeah. Everybody's really nice.", "order": 4486, "speaker": "Brenda"},
  {"season": 2, "episode": 11, "scene": 19, "line": "Good. Well, that is what Scranton is all about. Not like you New Yawkers.", "order": 4487, "speaker": "Michael Scott"},
  {"season": 2, "episode": 11, "scene": 19, "line": "When are you going to start the presentation?", "order": 4488, "speaker": "Brenda"},
  {"season": 2, "episode": 11, "scene": 19, "line": "Well, we already sort of started it back at the office and on the dock with the Gilligan thing, so... right now, I was thinking. Yes. Okay, listen up all you Dunder-Mifflinites! I would like to talk to you all about life preservers. Now, one important life preserver in business is IT support.", "order": 4489, "speaker": "Michael Scott"},
  {"season": 2, "episode": 11, "scene": 19, "line": "Not now, Mike, we're doing the limbo! That's right, partiers, it's time to limbo, limbo, limbo!", "order": 4490, "speaker": "Captain Jack"},
  {"season": 2, "episode": 11, "scene": 19, "line": "So, okay.", "order": 4491, "speaker": "Michael Scott"},
  {"season": 2, "episode": 11, "scene": 19, "line": "Limbo, whoo!", "order": 4492, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 11, "scene": 19, "line": "All right! I need a volunteer to come up here and hold my stick. Who's it gonna be?", "order": 4493, "speaker": "Captain Jack"},
  {"season": 2, "episode": 11, "scene": 19, "line": "Me.", "order": 4494, "speaker": "Meredith Palmer"},
  {"season": 2, "episode": 11, "scene": 19, "line": "Okay...", "order": 4495, "speaker": "Captain Jack"},
  {"season": 2, "episode": 11, "scene": 19, "line": "Me! Me, me, me.", "order": 4496, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 11, "scene": 19, "line": "Uh... usually it's a woman.", "order": 4497, "speaker": "Captain Jack"},
  {"season": 2, "episode": 11, "scene": 19, "line": "I'm stronger.", "order": 4498, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 11, "scene": 19, "line": "Hey, I got an idea! How would you like to steer the ship, Dwight?", "order": 4499, "speaker": "Captain Jack"},
  {"season": 2, "episode": 11, "scene": 20, "line": "Keep us on a steady course. Keep a sharp eye out. I'm counting on you!", "order": 4500, "speaker": "Captain Jack"},
  {"season": 2, "episode": 11, "scene": 21, "line": "I was the youngest pilot in Pan Am history. When I was four, the pilot let me ride in the cockpit and fly the plane with him. And I was four. And I was great. And I would have landed it, but my dad wanted us to go back to our seats.", "order": 4501, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 11, "scene": 22, "line": "All right, all right, that was great! Now it's time for the dance contest!", "order": 4502, "speaker": "Captain Jack"},
  {"season": 2, "episode": 11, "scene": 22, "line": "But before that, I have to do my presentation.", "order": 4503, "speaker": "Michael Scott"},
  {"season": 2, "episode": 11, "scene": 22, "line": "Nope! Dance contest!", "order": 4504, "speaker": "Captain Jack"},
  {"season": 2, "episode": 11, "scene": 22, "line": "All right, we'll have a motivational dance contest! Hit it! Yeah, okay, dancing! It is a primal art form used in ancient times to express yourself with the body and communicate!", "order": 4505, "speaker": "Michael Scott"},
  {"season": 2, "episode": 11, "scene": 23, "line": "Sometimes you have to take a break from being the kind of boss that's always trying to teach people things. Sometimes you have to just be the boss of dancing.", "order": 4506, "speaker": "Michael Scott"},
  {"season": 2, "episode": 11, "scene": 24, "line": "[singing] What do you do with a drunken sailor? What do you do with a drunken sailor? What do you do with a drunken sailor early in the morning?", "order": 4507, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 11, "scene": 24, "line": "Hey, come inside and talk to me.", "order": 4508, "speaker": "Angela Martin"},
  {"season": 2, "episode": 11, "scene": 24, "line": "I can't. Do you want us to run aground, woman?!", "order": 4509, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 11, "scene": 25, "line": "[chanting] Snorkel shot! Snorkel shot!", "order": 4510, "speaker": "Darryl Philbin & Katy Moore"},
  {"season": 2, "episode": 11, "scene": 25, "line": "Whoo! Who's next? Come on, Pam! Come on! Come on!", "order": 4511, "speaker": "Roy Anderson"},
  {"season": 2, "episode": 11, "scene": 25, "line": "No, I'm not going to do that.", "order": 4512, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 11, "scene": 25, "line": "Come on!", "order": 4513, "speaker": "Roy Anderson"},
  {"season": 2, "episode": 11, "scene": 25, "line": "That's what I'm talking about!", "order": 4514, "speaker": "Darryl Philbin"},
  {"season": 2, "episode": 11, "scene": 25, "line": "Hey, why don't we find like a quieter place to hang out?", "order": 4515, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 11, "scene": 25, "line": "I've just gotta wait for Darryl to do his shot. Just a minute. Come on! [chanting] Darryl! Darryl!", "order": 4516, "speaker": "Roy Anderson"},
  {"season": 2, "episode": 11, "scene": 26, "line": "It's getting kind of rowdy down there.", "order": 4517, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 11, "scene": 26, "line": "Yeah. [chanting] Darryl! Darryl! Darryl!", "order": 4518, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 11, "scene": 26, "line": "Sometimes I just don't get Roy.", "order": 4519, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 11, "scene": 26, "line": "Well...", "order": 4520, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 11, "scene": 26, "line": "I mean, I don't know. So... what's it like dating a cheerleader?", "order": 4521, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 11, "scene": 26, "line": "Oh, um... [A long silence.]", "order": 4522, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 11, "scene": 26, "line": "I'm cold.", "order": 4523, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 11, "scene": 27, "line": "So, what's this presentation all about?", "order": 4524, "speaker": "Captain Jack"},
  {"season": 2, "episode": 11, "scene": 27, "line": "Ah! See, this is of general interest. It is about priorities and making decisions, using the boat as an analogy. What is important to you? If the boat is sinking, what do you save?", "order": 4525, "speaker": "Michael Scott"},
  {"season": 2, "episode": 11, "scene": 27, "line": "Women and children.", "order": 4526, "speaker": "Captain Jack"},
  {"season": 2, "episode": 11, "scene": 27, "line": "No, no. Salesmen and profit centers.", "order": 4527, "speaker": "Michael Scott"},
  {"season": 2, "episode": 11, "scene": 27, "line": "That's a stupid analogy.", "order": 4528, "speaker": "Captain Jack"},
  {"season": 2, "episode": 11, "scene": 27, "line": "Okay, well, obviously you don't know anything about leadership.", "order": 4529, "speaker": "Michael Scott"},
  {"season": 2, "episode": 11, "scene": 27, "line": "Well, I was the captain of a PC-1 Cyclone Coastal Patrol Boat during Desert Storm.", "order": 4530, "speaker": "Captain Jack"},
  {"season": 2, "episode": 11, "scene": 27, "line": "Wow. You should be the motivational speaker.", "order": 4531, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 11, "scene": 27, "line": "Okay.", "order": 4532, "speaker": "Michael Scott"},
  {"season": 2, "episode": 11, "scene": 27, "line": "Yeah. He gives me real responsibility, Michael. Captain Jack delegates. He's let me steer the ship for the last hour.", "order": 4533, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 11, "scene": 28, "line": "I'd like to be engaged. How did you manage to pull that off?", "order": 4534, "speaker": "Katy Moore"},
  {"season": 2, "episode": 11, "scene": 28, "line": "Uh, I've been engaged for three years, and there's no end in sight. So... you don't wanna ask my advice.", "order": 4535, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 11, "scene": 29, "line": "Suppose your office building's on fire. Jim, who would you save?", "order": 4536, "speaker": "Captain Jack"},
  {"season": 2, "episode": 11, "scene": 29, "line": "Um... let's see, uh... The customer. Because the customer is king.", "order": 4537, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 11, "scene": 29, "line": "Not what I was looking for, but a good thought.", "order": 4538, "speaker": "Michael Scott"},
  {"season": 2, "episode": 11, "scene": 29, "line": "He's just sucking up!", "order": 4539, "speaker": "Captain Jack"},
  {"season": 2, "episode": 11, "scene": 29, "line": "When you were in the Navy, did you ever almost die?", "order": 4540, "speaker": "Roy Anderson"},
  {"season": 2, "episode": 11, "scene": 29, "line": "Oh yeah, oh yeah. And I wasn't thinking about some customer. I was thinking about my first wife. The day I got back on shore, I married her.", "order": 4541, "speaker": "Captain Jack"},
  {"season": 2, "episode": 11, "scene": 30, "line": "You know what? I would save the receptionist. I just wanted to clear that up.", "order": 4542, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 11, "scene": 31, "line": "Hello, everybody, could I have your attention for just a second? Could you listen to me for a second? We were up at the front, and we were talking about what's really important, and... Pam, I think enough is enough. I think we should set a date for our wedding. How about June 10th? Come on, let's do it! Come on, Pam!", "order": 4543, "speaker": "Roy Anderson"},
  {"season": 2, "episode": 11, "scene": 32, "line": "I don't want to take credit for this, but Roy and I were just having a conversation about making commitments and making choices. Right? Did I motivate you?", "order": 4544, "speaker": "Michael Scott"},
  {"season": 2, "episode": 11, "scene": 32, "line": "No, it was Captain Jack.", "order": 4545, "speaker": "Roy Anderson"},
  {"season": 2, "episode": 11, "scene": 32, "line": "Well... could have been either one of us, because we were pretty much saying the same thing. Congratulations. That is great!", "order": 4546, "speaker": "Michael Scott"},
  {"season": 2, "episode": 11, "scene": 32, "line": "We gotta celebrate! Hey, I got an idea, I got an idea. I can marry you right now, as captain of the ship!", "order": 4547, "speaker": "Captain Jack"},
  {"season": 2, "episode": 11, "scene": 32, "line": "Yes! I can marry you as regional manager of Dunder-Mifflin!", "order": 4548, "speaker": "Michael Scott"},
  {"season": 2, "episode": 11, "scene": 32, "line": "No, no, I want my mom and dad to be there.", "order": 4549, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 11, "scene": 32, "line": "Then I'll give you away!", "order": 4550, "speaker": "Michael Scott"},
  {"season": 2, "episode": 11, "scene": 32, "line": "No, thank you.", "order": 4551, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 11, "scene": 33, "line": "Do you think that'll ever be us?", "order": 4552, "speaker": "Katy Moore"},
  {"season": 2, "episode": 11, "scene": 33, "line": "No.", "order": 4553, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 11, "scene": 33, "line": "What is wrong with you? Why did you even bring me here tonight?", "order": 4554, "speaker": "Katy Moore"},
  {"season": 2, "episode": 11, "scene": 33, "line": "I don't know. Let's break up.", "order": 4555, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 11, "scene": 33, "line": "Whoa. What?", "order": 4556, "speaker": "Katy Moore"},
  {"season": 2, "episode": 11, "scene": 34, "line": "This is where Captain Jack drives the boat.", "order": 4557, "speaker": "Captain Jack"},
  {"season": 2, "episode": 11, "scene": 34, "line": "Wow!", "order": 4558, "speaker": "Meredith Palmer"},
  {"season": 2, "episode": 11, "scene": 35, "line": "Seasick? Captain Jack says you should look at the Moon.", "order": 4559, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 11, "scene": 35, "line": "Captain Jack is a fart face. I'm on medication.", "order": 4560, "speaker": "Michael Scott"},
  {"season": 2, "episode": 11, "scene": 35, "line": "Really? What?", "order": 4561, "speaker": "Brenda"},
  {"season": 2, "episode": 11, "scene": 35, "line": "Vomicillin. Okay. All right. It's time to be boss. It's time to motivate. Let's blow some minds here. Okay, guys, guys, cool it. Everybody, Dunder-Mifflin Scranton employees, Brenda, I have some very, very urgent news I need to tell everybody right now. Listen up. The ship is sinking! Okay? We're going down, right now. Just wrap your heads around the reality of that. Shh, please! Everybody, it's my turn now, okay? Captain Jack is gone. In five minutes, this ship is going to be at the bottom of the lake! And there aren't enough spaces on the lifeboat! Who are we gonna save? Do we save sales? Do we save customer service? Do we save accounting? This is a business scenario. Right? It's a scary... it's a...", "order": 4562, "speaker": "Michael Scott"},
  {"season": 2, "episode": 11, "scene": 35, "line": "Hey! Hey! What the hell is going on here?", "order": 4563, "speaker": "Captain Jack"},
  {"season": 2, "episode": 11, "scene": 35, "line": "It's a predicament, and it's something that each and every one of us has to think about.", "order": 4564, "speaker": "Michael Scott"},
  {"season": 2, "episode": 11, "scene": 36, "line": "I'm in the brig. See? The boat's not as corporate-friendly as advertised. What was the deal with the guy jumping overboard? What was... if he had just waited and heard what I had to say, he would be motivated right now and not all wet.", "order": 4565, "speaker": "Michael Scott"},
  {"season": 2, "episode": 11, "scene": 37, "line": "Is somebody there?", "order": 4566, "speaker": "Michael Scott"},
  {"season": 2, "episode": 11, "scene": 37, "line": "What happened to you?", "order": 4567, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 11, "scene": 37, "line": "Captain Jack has a problem with authority.", "order": 4568, "speaker": "Michael Scott"},
  {"season": 2, "episode": 11, "scene": 37, "line": "Oh, right, because you announced that his ship was sinking?", "order": 4569, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 11, "scene": 37, "line": "He just totally lost it. If you ask me, he caused the panic.", "order": 4570, "speaker": "Michael Scott"},
  {"season": 2, "episode": 11, "scene": 37, "line": "What a night.", "order": 4571, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 11, "scene": 37, "line": "Well, it's nice for you. Your friend got engaged.", "order": 4572, "speaker": "Michael Scott"},
  {"season": 2, "episode": 11, "scene": 37, "line": "She was always engaged.", "order": 4573, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 11, "scene": 37, "line": "Roy said the first one didn't count.", "order": 4574, "speaker": "Michael Scott"},
  {"season": 2, "episode": 11, "scene": 37, "line": "That's... great. You know, to tell the truth, I used to have a big thing for Pam, so...", "order": 4575, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 11, "scene": 37, "line": "Really? You're kidding me. You and Pam? Wow. I would have never have put you two together. You really hid it well. God! I usually have a radar for stuff like that. You know, I made out with Jan...", "order": 4576, "speaker": "Michael Scott"},
  {"season": 2, "episode": 11, "scene": 37, "line": "Yeah, I know.", "order": 4577, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 11, "scene": 37, "line": "Yeah? Yep. Well, Pam is cute.", "order": 4578, "speaker": "Michael Scott"},
  {"season": 2, "episode": 11, "scene": 37, "line": "Yeah. She's really funny, and she's warm. And she's just... well, anyway.", "order": 4579, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 11, "scene": 37, "line": "Well, if you like her so much, don't give up.", "order": 4580, "speaker": "Michael Scott"},
  {"season": 2, "episode": 11, "scene": 37, "line": "She's engaged.", "order": 4581, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 11, "scene": 37, "line": "BFD. Engaged ain't married.", "order": 4582, "speaker": "Michael Scott"},
  {"season": 2, "episode": 11, "scene": 37, "line": "Huh.", "order": 4583, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 11, "scene": 37, "line": "Never, ever, ever give up.", "order": 4584, "speaker": "Michael Scott"},
  {"season": 2, "episode": 11, "scene": 38, "line": "Don't worry, Michael. I'm taking us to shore.", "order": 4585, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 11, "scene": 38, "line": "It's a fake wheel, dummy.", "order": 4586, "speaker": "Michael Scott"},
  {"season": 2, "episode": 11, "scene": 44, "line": "Hey, Captain Jack, what kind of fish they got in this lake?", "order": 4587, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 11, "scene": 44, "line": "Perch, bass.", "order": 4588, "speaker": "Captain Jack"},
  {"season": 2, "episode": 11, "scene": 44, "line": "You know, my grandfather told me there was a monster here that eats Catholics. Have you heard...", "order": 4589, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 11, "scene": 44, "line": "I haven't heard that, Dwight.", "order": 4590, "speaker": "Captain Jack"},
  {"season": 2, "episode": 11, "scene": 44, "line": "If the hull is breached for any reason or the boat is overtaken, I am a volunteer Sheriff's Deputy, and you can count on me.", "order": 4591, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 11, "scene": 44, "line": "That's good to know, Dwight. Thanks.", "order": 4592, "speaker": "Captain Jack"},
  {"season": 2, "episode": 11, "scene": 44, "line": "Your problem now, Captain Jack.", "order": 4593, "speaker": "Michael Scott"},
  {"season": 2, "episode": 11, "scene": 47, "line": "Back in the '60s, I was with the Grass Roots. We toured with Janis Joplin, The Doors, Cream. We had a lot of fun, And now I do quality assurance for a paper company. As you can imagine, drugs played a part. They still do. My work calls last about 90 seconds, and that's about as long as I can concentrate.", "order": 4594, "speaker": "Creed Bratton"},
  {"season": 2, "episode": 11, "scene": 48, "line": "Those slide moves are just bush league. I hope I die before I'm old.", "order": 4595, "speaker": "Michael Scott"},
  {"season": 2, "episode": 11, "scene": 52, "line": "You should make a toast.", "order": 4596, "speaker": "Katy Moore"},
  {"season": 2, "episode": 11, "scene": 52, "line": "No, I really don't want to.", "order": 4597, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 11, "scene": 52, "line": "Jim, come on, don't be shy.", "order": 4598, "speaker": "Katy Moore"},
  {"season": 2, "episode": 11, "scene": 52, "line": "Everybody, everybody? There is a toast. Come on...", "order": 4599, "speaker": "Katy Moore"},
  {"season": 2, "episode": 11, "scene": 52, "line": "Yeah! Toast!", "order": 4600, "speaker": "Michael Scott"},
  {"season": 2, "episode": 11, "scene": 52, "line": "Yeah, toast! Toast! Toast!", "order": 4601, "speaker": "Katy Moore"},
  {"season": 2, "episode": 11, "scene": 52, "line": "Yeah.", "order": 4602, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 11, "scene": 52, "line": "Come on.", "order": 4603, "speaker": "Roy Anderson"},
  {"season": 2, "episode": 11, "scene": 52, "line": "Thanks, Katy. Um, didn't really prepare anything to say. We're all caught pretty unprepared with this whirlwind courtship. I guess I just wanna say that Pam is the greatest. My best friend. And she's awesome. And...", "order": 4604, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 11, "scene": 52, "line": "Okay, okay. My turn, my turn. Is this thing on? Uh, I want to congratulate the happy couple. You know, they say that marriage is an institution. And I say, who wants to be in an institution?", "order": 4605, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 11, "scene": 52, "line": "That's my line! That's my line, That's... I always say that at weddings. So I'll just think of something else to say. I... [band starts playing]", "order": 4606, "speaker": "Michael Scott"},
  {"season": 2, "episode": 11, "scene": 52, "line": "Cheers.", "order": 4607, "speaker": "Katy Moore"},
  {"season": 2, "episode": 11, "scene": 54, "line": "[to Angela] So this is...", "order": 4608, "speaker": "Phyllis Lapin"},
  {"season": 2, "episode": 11, "scene": 54, "line": "[storms out of Michael's office] Well, we're not going to Florida.", "order": 4609, "speaker": "Stanley Hudson"},
  {"season": 2, "episode": 11, "scene": 54, "line": "Oh. Someplace with a pool, then?", "order": 4610, "speaker": "Phyllis Lapin"},
  {"season": 2, "episode": 11, "scene": 54, "line": "[gives Phyllis a look]", "order": 4611, "speaker": "Stanley Hudson"},
  {"season": 2, "episode": 12, "scene": 1, "line": "...Lord of the Rings trilogy, if you see it back to back, it's really long. But it's good.", "order": 4612, "speaker": "Oscar Martinez"},
  {"season": 2, "episode": 12, "scene": 1, "line": "[off camera] Yeah, that's right.", "order": 4613, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 12, "scene": 2, "line": "Dunder Mifflin, this is Pam.", "order": 4614, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 12, "scene": 2, "line": "Pam! It's Michael. Help me! I need help right now.", "order": 4615, "speaker": "Michael Scott"},
  {"season": 2, "episode": 12, "scene": 2, "line": "Michael, what's wrong?", "order": 4616, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 12, "scene": 2, "line": "I'm hurt, I have hurt myself. Oh my God!", "order": 4617, "speaker": "Michael Scott"},
  {"season": 2, "episode": 12, "scene": 2, "line": "Ok, wait wait wait wait...", "order": 4618, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 12, "scene": 2, "line": "Ungh, this is not looking good Pam!", "order": 4619, "speaker": "Michael Scott"},
  {"season": 2, "episode": 12, "scene": 2, "line": "Michael, do you need me to call an ambulance?!", "order": 4620, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 12, "scene": 2, "line": "No, I want you to pick me up.", "order": 4621, "speaker": "Michael Scott"},
  {"season": 2, "episode": 12, "scene": 2, "line": "What?", "order": 4622, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 12, "scene": 2, "line": "Ok...", "order": 4623, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 12, "scene": 2, "line": "What's going on?", "order": 4624, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 12, "scene": 2, "line": "Wait a second, I thought you said that you were hurt.", "order": 4625, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 12, "scene": 2, "line": "I am hurt. I hurt my foot.", "order": 4626, "speaker": "Michael Scott"},
  {"season": 2, "episode": 12, "scene": 2, "line": "I'm sorry? Pam.", "order": 4627, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 12, "scene": 2, "line": "[exasperated]", "order": 4628, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 12, "scene": 2, "line": "What is going on?", "order": 4629, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 12, "scene": 2, "line": "I want to come to work. But I need you to come and pick me up. [Jim lunges across Pam's desk and puts Michael on speakerphone]", "order": 4630, "speaker": "Michael Scott"},
  {"season": 2, "episode": 12, "scene": 2, "line": "OH GOD!", "order": 4631, "speaker": "Michael Scott"},
  {"season": 2, "episode": 12, "scene": 2, "line": "Hey, whoa, Michael...", "order": 4632, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 12, "scene": 2, "line": "Oh God!", "order": 4633, "speaker": "Michael Scott"},
  {"season": 2, "episode": 12, "scene": 2, "line": "It's, okay, it's Jim. Just say again, uh, really loudly what happened.", "order": 4634, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 12, "scene": 2, "line": "OK, buhhhh, I burned my foot very badly on my Foreman Grill and I now need someone to come and bring me into work.", "order": 4635, "speaker": "Michael Scott"},
  {"season": 2, "episode": 12, "scene": 2, "line": "You burned", "order": 4636, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 12, "scene": 3, "line": "I enjoy having breakfast in bed. I like waking up to the smell of bacon, sue me. And since I don't have a butler, I have to do it myself. So, most nights before I go to bed, I will lay six strips of bacon out on my George Foreman Grill. Then I go to sleep. When I wake up, I plug in the grill, I go back to sleep again. Then I wake up to the smell of crackling bacon. It is delicious, it's good for me. It's the perfect way to start the day. Today I got up, I stepped onto the grill and it clamped down on my foot... that's it. I don't see what's so hard to believe about that.", "order": 4637, "speaker": "Michael Scott"},
  {"season": 2, "episode": 12, "scene": 4, "line": "Pam, could you come get me?!", "order": 4638, "speaker": "Michael Scott"},
  {"season": 2, "episode": 12, "scene": 4, "line": "Uh, I have to stay here and answer the phone.", "order": 4639, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 12, "scene": 4, "line": "Ok, could someone come and get me please, Ryan?", "order": 4640, "speaker": "Michael Scott"},
  {"season": 2, "episode": 12, "scene": 4, "line": "Michael, you should stay home and rest.", "order": 4641, "speaker": "Phyllis Lapin"},
  {"season": 2, "episode": 12, "scene": 4, "line": "There's no toilet paper here. Could Ryan... tell Ryan to bring toilet paper. Could you tell 'em that?", "order": 4642, "speaker": "Michael Scott"},
  {"season": 2, "episode": 12, "scene": 4, "line": "Can you hop?", "order": 4643, "speaker": "Kevin Malone"},
  {"season": 2, "episode": 12, "scene": 4, "line": "I tried hopping, Kevin, and I bumped my elbow against the wall and now my elbow has a protruberance.", "order": 4644, "speaker": "Michael Scott"},
  {"season": 2, "episode": 12, "scene": 4, "line": "[panicked] No one wants to pick me up!?", "order": 4645, "speaker": "Michael Scott"},
  {"season": 2, "episode": 12, "scene": 4, "line": "[silence, Dwight enters the office] What is going on? What is going on?", "order": 4646, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 12, "scene": 4, "line": "Michael, is, um, sick and he wants one of us to rescue him.", "order": 4647, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 12, "scene": 4, "line": "I'm not sick! I'm burned!", "order": 4648, "speaker": "Michael Scott"},
  {"season": 2, "episode": 12, "scene": 4, "line": "I'm coming Michael!", "order": 4649, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 12, "scene": 4, "line": "Oh...", "order": 4650, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 12, "scene": 4, "line": "I'm gonna save you!", "order": 4651, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 12, "scene": 4, "line": "Don't... is that Dwight? I do not want Dwight.", "order": 4652, "speaker": "Michael Scott"},
  {"season": 2, "episode": 12, "scene": 4, "line": "Hold on Michael! I am coming! Wait there!", "order": 4653, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 12, "scene": 4, "line": "I don't want Dwight!", "order": 4654, "speaker": "Michael Scott"},
  {"season": 2, "episode": 12, "scene": 4, "line": "Michael, why don't you call your girlfriend?", "order": 4655, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 12, "scene": 4, "line": "I don't have a girlfriend.", "order": 4656, "speaker": "Michael Scott"},
  {"season": 2, "episode": 12, "scene": 4, "line": "But you said that you went out with her this weekend.", "order": 4657, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 12, "scene": 4, "line": "It was all made up. Just someone come, ok? Anyone. Anyone but Dwight.", "order": 4658, "speaker": "Michael Scott"},
  {"season": 2, "episode": 12, "scene": 4, "line": "[sounds of a car crash] What was that...", "order": 4659, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 12, "scene": 4, "line": "What was that?! [everyone runs to Michael's office window]", "order": 4660, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 12, "scene": 4, "line": "Oh!", "order": 4661, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 12, "scene": 4, "line": "Ohhhhhh!", "order": 4662, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 12, "scene": 4, "line": "He hit the pole!", "order": 4663, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 12, "scene": 4, "line": "It's broken right, he can't...", "order": 4664, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 12, "scene": 4, "line": "Oh my gosh.", "order": 4665, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 12, "scene": 4, "line": "Oh Dwight, Dwight, [Dwight pukes on his back windshield] Ohhhhhh!", "order": 4666, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 12, "scene": 4, "line": "Oh my God!", "order": 4667, "speaker": "Jim Halpert & Pam Beesly"},
  {"season": 2, "episode": 12, "scene": 4, "line": "Is he ok?", "order": 4668, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 12, "scene": 4, "line": "He's still driving... Dwight, you forgot your bumper!", "order": 4669, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 12, "scene": 4, "line": "Hellooo? ... Please don't send Dwight!", "order": 4670, "speaker": "Michael Scott"},
  {"season": 2, "episode": 12, "scene": 5, "line": "Morning everyone. Don't freak out. I forbid anybody to freak out. Clearly, I have had a very serious accident, but I will recover, God willing. I just want to be treated normally today. Normal would actually be good, considering the trauma that I've been through.", "order": 4671, "speaker": "Michael Scott"},
  {"season": 2, "episode": 12, "scene": 5, "line": "You missed two big conference calls today, one with corporate.", "order": 4672, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 12, "scene": 5, "line": "Did you explain why?", "order": 4673, "speaker": "Michael Scott"},
  {"season": 2, "episode": 12, "scene": 5, "line": "No, I didn't mention that you cooked your foot.", "order": 4674, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 12, "scene": 5, "line": "Burned my foot, Pam.", "order": 4675, "speaker": "Michael Scott"},
  {"season": 2, "episode": 12, "scene": 6, "line": "Please stop popping my cast. Thank you.", "order": 4676, "speaker": "Michael Scott"},
  {"season": 2, "episode": 12, "scene": 6, "line": "So, where are you shipping your foot?", "order": 4677, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 12, "scene": 6, "line": "Ha ha ha. So where are you shipping...", "order": 4678, "speaker": "Michael Scott"},
  {"season": 2, "episode": 12, "scene": 6, "line": "Your foot?", "order": 4679, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 12, "scene": 7, "line": "Thank you. Pam, messages please?", "order": 4680, "speaker": "Michael Scott"},
  {"season": 2, "episode": 12, "scene": 7, "line": "You didn't have any.", "order": 4681, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 12, "scene": 7, "line": "Really, well, it, uh, seemed very important to you earlier that you needed to stay and...", "order": 4682, "speaker": "Michael Scott"},
  {"season": 2, "episode": 12, "scene": 7, "line": "And do my job?", "order": 4683, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 12, "scene": 7, "line": "No, your job is being my friend, Pam. OW! God!", "order": 4684, "speaker": "Michael Scott"},
  {"season": 2, "episode": 12, "scene": 7, "line": "[holding mini-fan] It slipped.", "order": 4685, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 12, "scene": 7, "line": "What?", "order": 4686, "speaker": "Michael Scott"},
  {"season": 2, "episode": 12, "scene": 7, "line": "Sorry.", "order": 4687, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 12, "scene": 7, "line": "It's just that before, you said that you didn't want any special treatment.", "order": 4688, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 12, "scene": 7, "line": "I don't want any special treatment, Pam. I just want you to treat me like you would some family member who's undergone some sort of serious physical trauma. I don't think that's too much to ask.", "order": 4689, "speaker": "Michael Scott"},
  {"season": 2, "episode": 12, "scene": 7, "line": "Do you want some aspirin, because you seem a little fussy.", "order": 4690, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 12, "scene": 7, "line": "No, I don't want some aspirin, yeah I'm a little fussy. Aspirin's not gonna do a damn thing. I'm sitting here with a bloody stump of a foot.", "order": 4691, "speaker": "Michael Scott"},
  {"season": 2, "episode": 12, "scene": 7, "line": "Hey, Pam, I'm assistant regional manager, and I can take care of him. Part of my duties are to.", "order": 4692, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 12, "scene": 7, "line": "What? Part of your duties are to what?", "order": 4693, "speaker": "Michael Scott"},
  {"season": 2, "episode": 12, "scene": 7, "line": "What?", "order": 4694, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 12, "scene": 7, "line": "You just said 'part of your duties are to' something.", "order": 4695, "speaker": "Michael Scott"},
  {"season": 2, "episode": 12, "scene": 7, "line": "No, I didn't.", "order": 4696, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 12, "scene": 7, "line": "Yes, you did. What is wrong with you?", "order": 4697, "speaker": "Michael Scott"},
  {"season": 2, "episode": 12, "scene": 7, "line": "What is wrong with", "order": 4698, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 12, "scene": 8, "line": "Where is my cornbread?", "order": 4699, "speaker": "Michael Scott"},
  {"season": 2, "episode": 12, "scene": 8, "line": "Here you go.", "order": 4700, "speaker": "Ryan Howard"},
  {"season": 2, "episode": 12, "scene": 8, "line": "Thank you. Did you get all dark meat like I like?", "order": 4701, "speaker": "Michael Scott"},
  {"season": 2, "episode": 12, "scene": 8, "line": "Yes. I ordered three full rotisserie chickens worth of all dark meat.", "order": 4702, "speaker": "Ryan Howard"},
  {"season": 2, "episode": 12, "scene": 8, "line": "Where are the yams?", "order": 4703, "speaker": "Michael Scott"},
  {"season": 2, "episode": 12, "scene": 8, "line": "They were out of yams. I got you creamed spinach.", "order": 4704, "speaker": "Ryan Howard"},
  {"season": 2, "episode": 12, "scene": 8, "line": "Did you go to the one in Stroudsburg?", "order": 4705, "speaker": "Michael Scott"},
  {"season": 2, "episode": 12, "scene": 8, "line": "Yes.", "order": 4706, "speaker": "Ryan Howard"},
  {"season": 2, "episode": 12, "scene": 8, "line": "And they had no yams?", "order": 4707, "speaker": "Michael Scott"},
  {"season": 2, "episode": 12, "scene": 8, "line": "They had no yams.", "order": 4708, "speaker": "Ryan Howard"},
  {"season": 2, "episode": 12, "scene": 8, "line": "How strange. Because they always have yams.", "order": 4709, "speaker": "Michael Scott"},
  {"season": 2, "episode": 12, "scene": 9, "line": "Aw, man, is that a Prism Duro-Sport?", "order": 4710, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 12, "scene": 9, "line": "You've seen one of these?", "order": 4711, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 12, "scene": 9, "line": "Yeah, they're like an i-Pod only they're better 'cause they're chunkier and more solid.", "order": 4712, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 12, "scene": 9, "line": "Roy gave it to me for Christmas. I'm trying to figure out how to put songs on it.", "order": 4713, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 12, "scene": 9, "line": "Oh, no no no. Don't go there. I know this Russian website where you can download songs for two cents a piece.", "order": 4714, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 12, "scene": 9, "line": "Really?", "order": 4715, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 12, "scene": 9, "line": "Yeah, I'll write down the address for you. Only, the only thing is, is that all the songs are in Russian. ... Kidding!", "order": 4716, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 12, "scene": 9, "line": "Oh! Ha, haha.", "order": 4717, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 12, "scene": 9, "line": "Why would they all be...? Ok, see you later, Pan.", "order": 4718, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 12, "scene": 9, "line": "Pan?", "order": 4719, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 12, "scene": 10, "line": "Pam... PAAAM!?", "order": 4720, "speaker": "Michael Scott"},
  {"season": 2, "episode": 12, "scene": 10, "line": "Oh, God.", "order": 4721, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 12, "scene": 10, "line": "[phone rings] What.", "order": 4722, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 12, "scene": 10, "line": "Come here please.", "order": 4723, "speaker": "Michael Scott"},
  {"season": 2, "episode": 12, "scene": 10, "line": "Tell me before I come there.", "order": 4724, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 12, "scene": 10, "line": "I want you to rub butter on my foot.", "order": 4725, "speaker": "Michael Scott"},
  {"season": 2, "episode": 12, "scene": 10, "line": "No.", "order": 4726, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 12, "scene": 10, "line": "Pam, please? I have Country Crock.", "order": 4727, "speaker": "Michael Scott"},
  {"season": 2, "episode": 12, "scene": 10, "line": "No.", "order": 4728, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 12, "scene": 10, "line": "Uh, ow. Ryan! ... Ryaaaaan ... RYYYYAN!", "order": 4729, "speaker": "Michael Scott"},
  {"season": 2, "episode": 12, "scene": 11, "line": "These covers are totally indestructible.", "order": 4730, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 12, "scene": 11, "line": "Really?", "order": 4731, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 12, "scene": 11, "line": "Yeah. Throw it. I promise it won't break. Chuck it. [Pam throws her mp3 player]", "order": 4732, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 12, "scene": 11, "line": "Oh no, it's broken.", "order": 4733, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 12, "scene": 11, "line": "What?!", "order": 4734, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 12, "scene": 11, "line": "No, it's fine. I told you it wouldn't break. You could throw it all day long.", "order": 4735, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 12, "scene": 11, "line": "That is so cool. Thanks Dwight!", "order": 4736, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 12, "scene": 11, "line": "Yep.", "order": 4737, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 12, "scene": 12, "line": "So, I guess Pam and Dwight are friends now.", "order": 4738, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 12, "scene": 13, "line": "Oh God no, Dwight isn't my friend... Oh my God! Dwight's kind of my friend!", "order": 4739, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 12, "scene": 14, "line": "No, nope, no one is helping me out at all Mom. No, I'm not gonna call Jan. She'd just worry... drive down here and make a big thing... Who told you that? No, it was mutual. What is Pam doing chatting with you?", "order": 4740, "speaker": "Michael Scott"},
  {"season": 2, "episode": 12, "scene": 15, "line": "Huh. Do you like candy?", "order": 4741, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 12, "scene": 15, "line": "It's alright.", "order": 4742, "speaker": "Angela Martin"},
  {"season": 2, "episode": 12, "scene": 15, "line": "Cause you're sweeter than candy.", "order": 4743, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 12, "scene": 15, "line": "What is wrong with you? [Dwight pats Angela on the rear and runs away laughing]", "order": 4744, "speaker": "Angela Martin"},
  {"season": 2, "episode": 12, "scene": 15, "line": "Hey!", "order": 4745, "speaker": "Angela Martin"},
  {"season": 2, "episode": 12, "scene": 16, "line": "Wow, you just dive right into it.", "order": 4746, "speaker": "Toby Flenderson"},
  {"season": 2, "episode": 12, "scene": 16, "line": "You know, around age twelve, I just started goin' for it.", "order": 4747, "speaker": "Ryan Howard"},
  {"season": 2, "episode": 12, "scene": 16, "line": "[loud noise in bathroom] No! Guh! OW! Awww, help, help me!", "order": 4748, "speaker": "Michael Scott"},
  {"season": 2, "episode": 12, "scene": 16, "line": "What, what happened?", "order": 4749, "speaker": "Toby Flenderson"},
  {"season": 2, "episode": 12, "scene": 16, "line": "I fell off the toilet. I'm caught between the toilet and the wall.", "order": 4750, "speaker": "Michael Scott"},
  {"season": 2, "episode": 12, "scene": 16, "line": "What do you need?", "order": 4751, "speaker": "Toby Flenderson"},
  {"season": 2, "episode": 12, "scene": 16, "line": "Ugh, not you. Someone else. Get Pam.", "order": 4752, "speaker": "Michael Scott"},
  {"season": 2, "episode": 12, "scene": 16, "line": "I don't think Pam's gonna want to come into the men's room.", "order": 4753, "speaker": "Toby Flenderson"},
  {"season": 2, "episode": 12, "scene": 16, "line": "Get Ryan. He needs to lift me. [Ryan shakes his head] and he needs to clean me up a little bit. Bring a wet towel.", "order": 4754, "speaker": "Michael Scott"},
  {"season": 2, "episode": 12, "scene": 16, "line": "Ryan, is, uh, dead.", "order": 4755, "speaker": "Toby Flenderson"},
  {"season": 2, "episode": 12, "scene": 16, "line": "No, he's not.", "order": 4756, "speaker": "Michael Scott"},
  {"season": 2, "episode": 12, "scene": 16, "line": "Dead.", "order": 4757, "speaker": "Toby Flenderson"},
  {"season": 2, "episode": 12, "scene": 16, "line": "I just saw him.", "order": 4758, "speaker": "Michael Scott"},
  {"season": 2, "episode": 12, "scene": 16, "line": "No. Can't, can't you just get up yourself? I... You only grilled your foot.", "order": 4759, "speaker": "Toby Flenderson"},
  {"season": 2, "episode": 12, "scene": 16, "line": "Ugh, forget it. I'll just get up myself. No! Uh, aaaahhh! Ah! Oh God!", "order": 4760, "speaker": "Michael Scott"},
  {"season": 2, "episode": 12, "scene": 17, "line": "Do you think Dwight's bein' a little weird today?", "order": 4761, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 12, "scene": 17, "line": "No, he's actually been really nice and helpful.", "order": 4762, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 12, "scene": 17, "line": "And that isn't weird?", "order": 4763, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 12, "scene": 17, "line": "Wow...", "order": 4764, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 12, "scene": 17, "line": "Can I have everyone's attention please? Phyllis, Oscar, Ryan, who's supposed to be dead, can I ask you all a question? Do you all know what it's like to be disabled? Oscar?", "order": 4765, "speaker": "Michael Scott"},
  {"season": 2, "episode": 12, "scene": 17, "line": "Um, I had scoliosis as a girl.", "order": 4766, "speaker": "Phyllis Lapin"},
  {"season": 2, "episode": 12, "scene": 17, "line": "No, never heard of it. No, a", "order": 4767, "speaker": "Michael Scott"},
  {"season": 2, "episode": 12, "scene": 17, "line": "When I was a teenager, I was in an iron lung.", "order": 4768, "speaker": "Creed Bratton"},
  {"season": 2, "episode": 12, "scene": 17, "line": "Wuh, how, how old are you? Look, the point is, I am the only one here who has a legitimate disability, although I'm sure Stanley has had his fair share of obstacles.", "order": 4769, "speaker": "Michael Scott"},
  {"season": 2, "episode": 12, "scene": 17, "line": "I'm not disabled and neither are you.", "order": 4770, "speaker": "Stanley Hudson"},
  {"season": 2, "episode": 12, "scene": 17, "line": "Ok, [lifts up cooked foot] what does this look like to you Stanley?!", "order": 4771, "speaker": "Michael Scott"},
  {"season": 2, "episode": 12, "scene": 17, "line": "Mailboxes, Etc.", "order": 4772, "speaker": "Stanley Hudson"},
  {"season": 2, "episode": 12, "scene": 17, "line": "Shuuut it, ok, well, well you know what, disabilities are not things to be laughed at or laughed about. You people are jerks. Imagine if you had left Stevie Wonder on the floor of that bathroom instead of me.", "order": 4773, "speaker": "Michael Scott"},
  {"season": 2, "episode": 12, "scene": 17, "line": "Oh, we wouldn't. We love Stevie Wonder.", "order": 4774, "speaker": "Phyllis Lapin"},
  {"season": 2, "episode": 12, "scene": 17, "line": "[sigh] I burned my foot!!! Ok, twenty minutes, conference room, everybody's in there!", "order": 4775, "speaker": "Michael Scott"},
  {"season": 2, "episode": 12, "scene": 17, "line": "[looking up at Creed] Dad?", "order": 4776, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 12, "scene": 18, "line": "While we are waiting for our special guest to arrive, I wanted you all to take a look at a few of the many, many disabled icons who have contributed so much to our society.", "order": 4777, "speaker": "Michael Scott"},
  {"season": 2, "episode": 12, "scene": 18, "line": "Quick question: uh, why is Tom Hanks on the wall?", "order": 4778, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 12, "scene": 18, "line": "Twice.", "order": 4779, "speaker": "Ryan Howard"},
  {"season": 2, "episode": 12, "scene": 18, "line": "Good question. Forrest Gump: mentally challenged, Philadelphia [points to a picture from Big]: AIDS.", "order": 4780, "speaker": "Michael Scott"},
  {"season": 2, "episode": 12, "scene": 18, "line": "I think that's from Big.", "order": 4781, "speaker": "Kevin Malone"},
  {"season": 2, "episode": 12, "scene": 18, "line": "I don't think so, no.", "order": 4782, "speaker": "Michael Scott"},
  {"season": 2, "episode": 12, "scene": 18, "line": "Yeah, he's dancing on a piano with Robert Loggia.", "order": 4783, "speaker": "Kelly Kapoor"},
  {"season": 2, "episode": 12, "scene": 18, "line": "He grew into a man overnight. Rare disability, still works. [sigh] A crossword puzzle Stanley, seriously, are you learning nothing here?", "order": 4784, "speaker": "Michael Scott"},
  {"season": 2, "episode": 12, "scene": 18, "line": "Uh hmmmm... .", "order": 4785, "speaker": "Stanley Hudson"},
  {"season": 2, "episode": 12, "scene": 18, "line": "What you mean uh hmmm... ?", "order": 4786, "speaker": "Michael Scott"},
  {"season": 2, "episode": 12, "scene": 18, "line": "I mean I'm learning nothing.", "order": 4787, "speaker": "Stanley Hudson"},
  {"season": 2, "episode": 12, "scene": 18, "line": "Ok.", "order": 4788, "speaker": "Michael Scott"},
  {"season": 2, "episode": 12, "scene": 18, "line": "Michael Scott, I'm looking for Michael Scott.", "order": 4789, "speaker": "Billy Merchant"},
  {"season": 2, "episode": 12, "scene": 18, "line": "Yes, right in here, come on in.", "order": 4790, "speaker": "Michael Scott"},
  {"season": 2, "episode": 12, "scene": 18, "line": "Great.", "order": 4791, "speaker": "Billy Merchant"},
  {"season": 2, "episode": 12, "scene": 18, "line": "This, ladies and gentlemen, is our special guest.", "order": 4792, "speaker": "Michael Scott"},
  {"season": 2, "episode": 12, "scene": 18, "line": "Sorry I'm late. Someone parked in the handicapped parking space.", "order": 4793, "speaker": "Billy Merchant"},
  {"season": 2, "episode": 12, "scene": 18, "line": "Hey everyone, I'm Billy Merchant, you may have seen me around here before, I'm the properties manager of this office park", "order": 4794, "speaker": "Billy Merchant"},
  {"season": 2, "episode": 12, "scene": 18, "line": "You are so brave. You are so brave.", "order": 4795, "speaker": "Michael Scott"},
  {"season": 2, "episode": 12, "scene": 18, "line": "Thank you. Actually, I've been meaning to come by here for a long time...", "order": 4796, "speaker": "Billy Merchant"},
  {"season": 2, "episode": 12, "scene": 18, "line": "But it's hard for you! Right? Because you're in a wheelchair.", "order": 4797, "speaker": "Michael Scott"},
  {"season": 2, "episode": 12, "scene": 18, "line": "No, I just have a lot of properties to manage.", "order": 4798, "speaker": "Billy Merchant"},
  {"season": 2, "episode": 12, "scene": 18, "line": "Let me ask you something, how long does it take for you to do something simple, every day, like brush your teeth in the morning?", "order": 4799, "speaker": "Michael Scott"},
  {"season": 2, "episode": 12, "scene": 18, "line": "I don't know, like 30 seconds?", "order": 4800, "speaker": "Billy Merchant"},
  {"season": 2, "episode": 12, "scene": 18, "line": "Oh my God, that's three times as long as it takes me.", "order": 4801, "speaker": "Michael Scott"},
  {"season": 2, "episode": 12, "scene": 18, "line": "How did you get in your wheelchair?", "order": 4802, "speaker": "Michael Scott"},
  {"season": 2, "episode": 12, "scene": 18, "line": "This morning? Just like every other morning, just climbed on in. [Everyone laughs]", "order": 4803, "speaker": "Billy Merchant"},
  {"season": 2, "episode": 12, "scene": 18, "line": "Hey, hey, hey, not funny! Not funny.", "order": 4804, "speaker": "Michael Scott"},
  {"season": 2, "episode": 12, "scene": 18, "line": "Hey, hey, relax, just jokin around here.", "order": 4805, "speaker": "Billy Merchant"},
  {"season": 2, "episode": 12, "scene": 18, "line": "Well, that's good, he still has a sense of humor.", "order": 4806, "speaker": "Michael Scott"},
  {"season": 2, "episode": 12, "scene": 18, "line": "Listen, I've actually used a chair since I was four years old. I don't really notice it anymore.", "order": 4807, "speaker": "Billy Merchant"},
  {"season": 2, "episode": 12, "scene": 18, "line": "Well they notice it. Don't you? You notice it. It's the first thing you saw when he rolled in here, isn't it?", "order": 4808, "speaker": "Michael Scott"},
  {"season": 2, "episode": 12, "scene": 19, "line": "I want to clamp Michael's face in a George Foreman grill.", "order": 4809, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 12, "scene": 20, "line": "So, there are just a couple things I want to remind everybody of...", "order": 4810, "speaker": "Billy Merchant"},
  {"season": 2, "episode": 12, "scene": 20, "line": "Ok...", "order": 4811, "speaker": "Michael Scott"},
  {"season": 2, "episode": 12, "scene": 20, "line": "First is parking. You can't block the freight entrance with your car, even if your blinkers are on. Does anybody have any questions? [to Dwight, whose arms is raised] Yes. Yeah? yes...", "order": 4812, "speaker": "Billy Merchant"},
  {"season": 2, "episode": 12, "scene": 20, "line": "Dwight, you have your hand up.", "order": 4813, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 12, "scene": 20, "line": "Ignore him. You know what? We're not that different, you and I. When I clamped my foot into a non-stick...", "order": 4814, "speaker": "Michael Scott"},
  {"season": 2, "episode": 12, "scene": 20, "line": "You know what Michael?", "order": 4815, "speaker": "Billy Merchant"},
  {"season": 2, "episode": 12, "scene": 20, "line": "Yeah...", "order": 4816, "speaker": "Michael Scott"},
  {"season": 2, "episode": 12, "scene": 20, "line": "Let me stop you right there.", "order": 4817, "speaker": "Billy Merchant"},
  {"season": 2, "episode": 12, "scene": 20, "line": "Ok.", "order": 4818, "speaker": "Michael Scott"},
  {"season": 2, "episode": 12, "scene": 20, "line": "And leave.", "order": 4819, "speaker": "Billy Merchant"},
  {"season": 2, "episode": 12, "scene": 21, "line": "Did you see Born on the Fourth of July? I was under the impression that Billy would be more like that guy.", "order": 4820, "speaker": "Michael Scott"},
  {"season": 2, "episode": 12, "scene": 22, "line": "What's wrong with that guy?", "order": 4821, "speaker": "Billy Merchant"},
  {"season": 2, "episode": 12, "scene": 22, "line": "You mean today? He stepped on a George Foremen grill and he burned his foot.", "order": 4822, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 12, "scene": 22, "line": "No, not Michael. The moon-faced kid who crashed into the pole. He looks like he has a concussion.", "order": 4823, "speaker": "Billy Merchant"},
  {"season": 2, "episode": 12, "scene": 23, "line": "[popping his bubble wrap cast] Hey!", "order": 4824, "speaker": "Michael Scott"},
  {"season": 2, "episode": 12, "scene": 23, "line": "I found the pudding cups you wanted in a gas station in Carbondale!", "order": 4825, "speaker": "Ryan Howard"},
  {"season": 2, "episode": 12, "scene": 23, "line": "You did it! Look at you, and with the plate and the napkin. Very nice. Thank you, Ryan.", "order": 4826, "speaker": "Michael Scott"},
  {"season": 2, "episode": 12, "scene": 23, "line": "You are very welcome.", "order": 4827, "speaker": "Ryan Howard"},
  {"season": 2, "episode": 12, "scene": 23, "line": "Did you get the yams?", "order": 4828, "speaker": "Michael Scott"},
  {"season": 2, "episode": 12, "scene": 23, "line": "No, the gas station in Carbondale did not have fresh yams!", "order": 4829, "speaker": "Ryan Howard"},
  {"season": 2, "episode": 12, "scene": 23, "line": "[sigh] Ok, I'll just have the pudding.", "order": 4830, "speaker": "Michael Scott"},
  {"season": 2, "episode": 12, "scene": 23, "line": "You sure?", "order": 4831, "speaker": "Ryan Howard"},
  {"season": 2, "episode": 12, "scene": 23, "line": "Yeh.", "order": 4832, "speaker": "Michael Scott"},
  {"season": 2, "episode": 12, "scene": 23, "line": "Ok.", "order": 4833, "speaker": "Ryan Howard"},
  {"season": 2, "episode": 12, "scene": 24, "line": "You know what? I feel better. Ryan brought me some chocolate pudding and his kindness healed my foot.", "order": 4834, "speaker": "Michael Scott"},
  {"season": 2, "episode": 12, "scene": 25, "line": "Yeah, baby, I am feelin' better. My body's literally healing itself. It is amazing what the human body is capable of when you have a powerful brain.", "order": 4835, "speaker": "Michael Scott"},
  {"season": 2, "episode": 12, "scene": 26, "line": "I ground up four extra-strength aspirin and put them in Michael's pudding. I do the same thing with my dog to get him to take his heartworm medicine.", "order": 4836, "speaker": "Ryan Howard"},
  {"season": 2, "episode": 12, "scene": 27, "line": "Uh, finally feel the blood coursing through my foot veins.", "order": 4837, "speaker": "Michael Scott"},
  {"season": 2, "episode": 12, "scene": 27, "line": "[hits his head on his desk] Uh, ugh, ohhhh...", "order": 4838, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 12, "scene": 27, "line": "Uh, ok, I think we need to take him to the hospital because I'm pretty sure he has a concussion.", "order": 4839, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 12, "scene": 27, "line": "Oh, now you feel some compassion for him.", "order": 4840, "speaker": "Michael Scott"},
  {"season": 2, "episode": 12, "scene": 27, "line": "He needs to go right now, and you're his emergency contact. I think that you should go with him.", "order": 4841, "speaker": "Angela Martin"},
  {"season": 2, "episode": 12, "scene": 27, "line": "Why don't you go with him?", "order": 4842, "speaker": "Michael Scott"},
  {"season": 2, "episode": 12, "scene": 27, "line": "I, barely know him...", "order": 4843, "speaker": "Angela Martin"},
  {"season": 2, "episode": 12, "scene": 27, "line": "I want Michael to take me...", "order": 4844, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 12, "scene": 27, "line": "I can't take you, I don't have my car and yours is all vomity.", "order": 4845, "speaker": "Michael Scott"},
  {"season": 2, "episode": 12, "scene": 27, "line": "You can take my van!", "order": 4846, "speaker": "Meredith Palmer"},
  {"season": 2, "episode": 12, "scene": 27, "line": "Oh, ok, that's, great. No, I can't drive. Jim why don't you drive.", "order": 4847, "speaker": "Michael Scott"},
  {"season": 2, "episode": 12, "scene": 27, "line": "Fine.", "order": 4848, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 12, "scene": 27, "line": "We'll go. I'm still recovering. So let's just, Ryan, could you get my coat please.", "order": 4849, "speaker": "Michael Scott"},
  {"season": 2, "episode": 12, "scene": 27, "line": "Slowly, slowly. Let's just get to the elevator.", "order": 4850, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 12, "scene": 27, "line": "Choo choo choo choo choo choo...", "order": 4851, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 12, "scene": 27, "line": "What are you doing? What, stop...", "order": 4852, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 12, "scene": 27, "line": "Vietnam sounds.", "order": 4853, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 12, "scene": 27, "line": "[Dwight falls onto the couch] Stop, stop, stop, stop.", "order": 4854, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 12, "scene": 27, "line": "Tired... [Jim grabs spray bottle from planter]", "order": 4855, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 12, "scene": 27, "line": "You can't lay down.", "order": 4856, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 12, "scene": 27, "line": "Want to take a rake... .", "order": 4857, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 12, "scene": 27, "line": "Wake up. [sprays Dwight]", "order": 4858, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 12, "scene": 27, "line": "Ahh!", "order": 4859, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 12, "scene": 27, "line": "Dwight, here, let me help you Dwight.", "order": 4860, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 12, "scene": 27, "line": "I'm just gonna get...", "order": 4861, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 12, "scene": 27, "line": "Ok, Pam, thanks.", "order": 4862, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 12, "scene": 27, "line": "Get up, get up.", "order": 4863, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 12, "scene": 27, "line": "You're the best.", "order": 4864, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 12, "scene": 27, "line": "Yeah.", "order": 4865, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 12, "scene": 27, "line": "Just keep him awake.", "order": 4866, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 12, "scene": 27, "line": "It smells like chicken soup.", "order": 4867, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 12, "scene": 27, "line": "I know.", "order": 4868, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 12, "scene": 27, "line": "I have to go to the hospital.", "order": 4869, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 12, "scene": 27, "line": "I know.", "order": 4870, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 12, "scene": 27, "line": "Where we going?", "order": 4871, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 12, "scene": 27, "line": "I just want to say goodbye ok?", "order": 4872, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 12, "scene": 27, "line": "I'll be back, I mean...", "order": 4873, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 12, "scene": 27, "line": "Yes, I know, but it's gonna be different.", "order": 4874, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 12, "scene": 27, "line": "Why?", "order": 4875, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 12, "scene": 27, "line": "It's just hard to explain.", "order": 4876, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 12, "scene": 27, "line": "Aw, Pam, you're adorable [taps her nose]", "order": 4877, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 12, "scene": 27, "line": "Oh my goodness!", "order": 4878, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 12, "scene": 27, "line": "[giggles]", "order": 4879, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 12, "scene": 27, "line": "Come here.", "order": 4880, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 12, "scene": 27, "line": "Oh, huggy hugs.", "order": 4881, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 12, "scene": 28, "line": "Shotgun!", "order": 4882, "speaker": "Michael Scott"},
  {"season": 2, "episode": 12, "scene": 28, "line": "You don't think you should sit in the back with Dwight?", "order": 4883, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 12, "scene": 29, "line": "The rules of shotgun are very simple and very clear. The first person to shout 'shotgun' when you're within the sight of the car gets the front seat. That's how the game's played. There are no exceptions for someone with a concussion.", "order": 4884, "speaker": "Michael Scott"},
  {"season": 2, "episode": 12, "scene": 30, "line": "Oh, God, a mini-van. What is Meredith's problem?", "order": 4885, "speaker": "Michael Scott"},
  {"season": 2, "episode": 12, "scene": 30, "line": "Well, I think she has a kid.", "order": 4886, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 12, "scene": 30, "line": "Well, yeah she has one kid, no husband. She's not gonna find one driving this thing around.", "order": 4887, "speaker": "Michael Scott"},
  {"season": 2, "episode": 12, "scene": 30, "line": "Where are we going?", "order": 4888, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 12, "scene": 30, "line": "Come on, get inside.", "order": 4889, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 12, "scene": 30, "line": "Where are we going?", "order": 4890, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 12, "scene": 30, "line": "We're going to Chuck E. Cheese.", "order": 4891, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 12, "scene": 30, "line": "Chuck E. Cheese? Oh, God, I'm so sick of Chuck E. Cheese.", "order": 4892, "speaker": "Michael Scott"},
  {"season": 2, "episode": 12, "scene": 30, "line": "We're going to the hospital, Michael.", "order": 4893, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 12, "scene": 30, "line": "I know, just sayin'.", "order": 4894, "speaker": "Michael Scott"},
  {"season": 2, "episode": 12, "scene": 31, "line": "Dwight, what are you drinking?", "order": 4895, "speaker": "Michael Scott"},
  {"season": 2, "episode": 12, "scene": 31, "line": "I found it under the seat.", "order": 4896, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 12, "scene": 31, "line": "Oh my God, Dwight, put that down.", "order": 4897, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 12, "scene": 31, "line": "I'm thirsty.", "order": 4898, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 12, "scene": 31, "line": "Give the bottle to Michael [sprays Dwight]", "order": 4899, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 12, "scene": 31, "line": "No!", "order": 4900, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 12, "scene": 31, "line": "Give the bottle to Michael!", "order": 4901, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 12, "scene": 31, "line": "I'm thirsty!", "order": 4902, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 12, "scene": 31, "line": "Give it to me.", "order": 4903, "speaker": "Michael Scott"},
  {"season": 2, "episode": 12, "scene": 31, "line": "No.", "order": 4904, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 12, "scene": 31, "line": "Dwight... [to Jim] You just keep your eyes on the road. [to Dwight] Give me the bottle or you're fired.", "order": 4905, "speaker": "Michael Scott"},
  {"season": 2, "episode": 12, "scene": 31, "line": "You can't fire me, I don't work in this van!", "order": 4906, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 12, "scene": 31, "line": "Give it to me Dwight.", "order": 4907, "speaker": "Michael Scott"},
  {"season": 2, "episode": 12, "scene": 31, "line": "No. [takes a drink]", "order": 4908, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 12, "scene": 31, "line": "Give me the bottle!!", "order": 4909, "speaker": "Michael Scott"},
  {"season": 2, "episode": 12, "scene": 31, "line": "[to Michael] Will you stop?", "order": 4910, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 12, "scene": 31, "line": "Gimme the bottle, Dwight!", "order": 4911, "speaker": "Michael Scott"},
  {"season": 2, "episode": 12, "scene": 31, "line": "Michael stop.", "order": 4912, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 12, "scene": 31, "line": "[drinks] Mmmmm...", "order": 4913, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 12, "scene": 31, "line": "Just give it!", "order": 4914, "speaker": "Michael Scott"},
  {"season": 2, "episode": 12, "scene": 31, "line": "Michael stop. [sprays Michael, then Dwight]", "order": 4915, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 12, "scene": 31, "line": "Stop, stop it! Stop spraying! [Dwight whines] Gimme the bottle!", "order": 4916, "speaker": "Michael Scott"},
  {"season": 2, "episode": 12, "scene": 31, "line": "Stop [sprays Michael]", "order": 4917, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 12, "scene": 31, "line": "My eyes!", "order": 4918, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 12, "scene": 31, "line": "Stop spraying me! Gimme the bottle!", "order": 4919, "speaker": "Michael Scott"},
  {"season": 2, "episode": 12, "scene": 31, "line": "My eyes!", "order": 4920, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 12, "scene": 32, "line": "Dwight, what is your middle name.", "order": 4921, "speaker": "Michael Scott"},
  {"season": 2, "episode": 12, "scene": 32, "line": "Danger.", "order": 4922, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 12, "scene": 32, "line": "[sigh] Something with a 'K'.", "order": 4923, "speaker": "Michael Scott"},
  {"season": 2, "episode": 12, "scene": 32, "line": "It's Kurt. Wow, I am so sad that I know that.", "order": 4924, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 12, "scene": 32, "line": "What do I write under 'reason for visit'?", "order": 4925, "speaker": "Michael Scott"},
  {"season": 2, "episode": 12, "scene": 32, "line": "Concussion. [Michael scribbles something out] What did you write?", "order": 4926, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 12, "scene": 32, "line": "Nothing. I wrote 'bringing someone to the hospital'.", "order": 4927, "speaker": "Michael Scott"},
  {"season": 2, "episode": 12, "scene": 32, "line": "So you thought they meant your reason for coming to the hospital.", "order": 4928, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 12, "scene": 32, "line": "No... you know what Jim, this isn't about me anymore. I made a miraculous recovery, which is more than I can say for him. [Dwight falls towards Jim]", "order": 4929, "speaker": "Michael Scott"},
  {"season": 2, "episode": 12, "scene": 32, "line": "Come on Dwight. [sprays Dwight]", "order": 4930, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 12, "scene": 32, "line": "Hi Michael!", "order": 4931, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 12, "scene": 32, "line": "Hi Dwight.", "order": 4932, "speaker": "Michael Scott"},
  {"season": 2, "episode": 12, "scene": 33, "line": "Ahhh. Mweehaa", "order": 4933, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 12, "scene": 33, "line": "Doctor, what is more serious, a head injury or a foot injury?", "order": 4934, "speaker": "Michael Scott"},
  {"season": 2, "episode": 12, "scene": 33, "line": "A head injury.", "order": 4935, "speaker": "Doctor"},
  {"season": 2, "episode": 12, "scene": 33, "line": "Well, you don't have all the information. The foot as been fairly severely burned and healed quickly, very quickly, actually like suspiciously quickly.", "order": 4936, "speaker": "Michael Scott"},
  {"season": 2, "episode": 12, "scene": 33, "line": "[to Dwight] So, I'm ordering a CAT scan.", "order": 4937, "speaker": "Doctor"},
  {"season": 2, "episode": 12, "scene": 33, "line": "What is that?", "order": 4938, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 12, "scene": 33, "line": "Look since you have the machine up and running, can I just stick my foot, we take a look?", "order": 4939, "speaker": "Michael Scott"},
  {"season": 2, "episode": 12, "scene": 33, "line": "Well, for a burn, you really just need to look at the outside of the foot.", "order": 4940, "speaker": "Doctor"},
  {"season": 2, "episode": 12, "scene": 33, "line": "Ok, what kinda machine is that?", "order": 4941, "speaker": "Michael Scott"},
  {"season": 2, "episode": 12, "scene": 33, "line": "Does the skin look red and swollen?", "order": 4942, "speaker": "Doctor"},
  {"season": 2, "episode": 12, "scene": 33, "line": "That's what she said.", "order": 4943, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 12, "scene": 33, "line": "That's my joke, damnit Dwight.", "order": 4944, "speaker": "Michael Scott"},
  {"season": 2, "episode": 12, "scene": 34, "line": "Ok, no electronics past this point. Camera, sound equipment...", "order": 4945, "speaker": "Lab Tech"},
  {"season": 2, "episode": 12, "scene": 34, "line": "It's ok, they're with me.", "order": 4946, "speaker": "Michael Scott"},
  {"season": 2, "episode": 12, "scene": 34, "line": "No metal of any kind.", "order": 4947, "speaker": "Lab Tech"},
  {"season": 2, "episode": 12, "scene": 34, "line": "Alright, well, I guess this is where we leave you off.", "order": 4948, "speaker": "Michael Scott"},
  {"season": 2, "episode": 12, "scene": 34, "line": "I don't want to do this.", "order": 4949, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 12, "scene": 34, "line": "Uh, well you should of thought of before you crashed your head on your way to pick me up. We'll, see you when you get out.", "order": 4950, "speaker": "Michael Scott"},
  {"season": 2, "episode": 12, "scene": 34, "line": "Oh.", "order": 4951, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 12, "scene": 34, "line": "Fine. Fine.", "order": 4952, "speaker": "Michael Scott"},
  {"season": 2, "episode": 12, "scene": 35, "line": "Dunder Mifflin, this is Pam.", "order": 4953, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 12, "scene": 35, "line": "Dunder Mifflin, this is Jim.", "order": 4954, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 12, "scene": 35, "line": "Oh my God, what is going on, is Dwight ok?", "order": 4955, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 12, "scene": 35, "line": "Uh hmm, he should be fine, but, uh, they brought him in for a CAT scan.", "order": 4956, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 12, "scene": 35, "line": "I can't believe he's getting a CAT scan.", "order": 4957, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 12, "scene": 35, "line": "Michael went in there with him too. It's pretty sweet.", "order": 4958, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 12, "scene": 35, "line": "Really? Michael went in with him?", "order": 4959, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 12, "scene": 35, "line": "Uh huh.", "order": 4960, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 12, "scene": 35, "line": "Wow.", "order": 4961, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 12, "scene": 35, "line": "But they shouldn't be much longer now, so we'll be back soon.", "order": 4962, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 12, "scene": 35, "line": "Ok, that's uh, good news [Pam sees Angela eavesdropping] Uh, yeah, no I'll let you go.", "order": 4963, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 12, "scene": 35, "line": "Ok.", "order": 4964, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 12, "scene": 35, "line": "Ok. Bye.", "order": 4965, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 12, "scene": 35, "line": "Bye.", "order": 4966, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 12, "scene": 36, "line": "Hey, Oscar?", "order": 4967, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 12, "scene": 36, "line": "What's up, Pam?", "order": 4968, "speaker": "Oscar Martinez"},
  {"season": 2, "episode": 12, "scene": 36, "line": "I just wanted to let you that Dwight's gonna be ok. The doctor said there's a really simple treatment for a concussion, so he'll probably even be back at work tomorrow.", "order": 4969, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 12, "scene": 36, "line": "Ok...", "order": 4970, "speaker": "Oscar Martinez"},
  {"season": 2, "episode": 12, "scene": 36, "line": "I just, uh, thought you'd want to know that.", "order": 4971, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 12, "scene": 37, "line": "Ok Mr. Schrute, inhale with me on three. One, two, uh Sir? [Michael tries to put his leg in the scanner] Stop that. Stop. Stop that.", "order": 4972, "speaker": "Lab Tech"},
  {"season": 2, "episode": 13, "scene": 1, "line": "Not much what's up with you?", "order": 4973, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 13, "scene": 1, "line": "Oh, I can not believe I fell for that. [laughing] Oh, my God.", "order": 4974, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 13, "scene": 1, "line": "Ah, ah, ah, what? What? Where's the funny? Give it to me.", "order": 4975, "speaker": "Michael Scott"},
  {"season": 2, "episode": 13, "scene": 1, "line": "Umm, is it me or does it smell like up-dog in here?", "order": 4976, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 13, "scene": 1, "line": "What's up-dog?", "order": 4977, "speaker": "Michael Scott"},
  {"season": 2, "episode": 13, "scene": 1, "line": "Nothin' much what's up with you?", "order": 4978, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 13, "scene": 1, "line": "Oh, oh, wow! I walked right into that. Oh, that's brilliant!", "order": 4979, "speaker": "Michael Scott"},
  {"season": 2, "episode": 13, "scene": 2, "line": "Hey, Stanley, is that jacket make of up-dog?", "order": 4980, "speaker": "Michael Scott"},
  {"season": 2, "episode": 13, "scene": 2, "line": "I'm on the phone.", "order": 4981, "speaker": "Stanley Hudson"},
  {"season": 2, "episode": 13, "scene": 3, "line": "Mmm, what flavour coffee is that? Up-dog?", "order": 4982, "speaker": "Michael Scott"},
  {"season": 2, "episode": 13, "scene": 3, "line": "What's that?", "order": 4983, "speaker": "Ryan Howard"},
  {"season": 2, "episode": 13, "scene": 3, "line": "I don't know, nothin', what's up with you?", "order": 4984, "speaker": "Michael Scott"},
  {"season": 2, "episode": 13, "scene": 3, "line": "Huh?", "order": 4985, "speaker": "Ryan Howard"},
  {"season": 2, "episode": 13, "scene": 3, "line": "[low] No, damn it!", "order": 4986, "speaker": "Michael Scott"},
  {"season": 2, "episode": 13, "scene": 4, "line": "What does that mean?", "order": 4987, "speaker": "Kevin Malone"},
  {"season": 2, "episode": 13, "scene": 4, "line": "What does what mean?", "order": 4988, "speaker": "Michael Scott"},
  {"season": 2, "episode": 13, "scene": 4, "line": "The thing you just said?", "order": 4989, "speaker": "Kevin Malone"},
  {"season": 2, "episode": 13, "scene": 4, "line": "Just forget it.", "order": 4990, "speaker": "Michael Scott"},
  {"season": 2, "episode": 13, "scene": 5, "line": "Dwight! Hey is it me or does this place smell like up-dog?", "order": 4991, "speaker": "Michael Scott"},
  {"season": 2, "episode": 13, "scene": 5, "line": "What's up-dog?", "order": 4992, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 13, "scene": 5, "line": "Gotcha! [laughing] Oh, God. [low] Crap! Nothin' how ya doing?", "order": 4993, "speaker": "Michael Scott"},
  {"season": 2, "episode": 13, "scene": 5, "line": "Good. How are you doing?", "order": 4994, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 13, "scene": 5, "line": "[mouthing] So close.", "order": 4995, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 13, "scene": 5, "line": "[low] Damn it.", "order": 4996, "speaker": "Michael Scott"},
  {"season": 2, "episode": 13, "scene": 6, "line": "Today is spring cleaning day here at Dunder Mifflin. And yes I know its January. I am not an idiot. But, if you do your Spring cleaning in January; guess what you don't have to do in the spring? Anything. They say a cluttered desk means a cluttered mind. Well I say that an empty desk means a...", "order": 4997, "speaker": "Michael Scott"},
  {"season": 2, "episode": 13, "scene": 6, "line": "Empty mind.", "order": 4998, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 13, "scene": 6, "line": "No, that's not... no, that's not what I was going to say.", "order": 4999, "speaker": "Michael Scott"},
  {"season": 2, "episode": 13, "scene": 7, "line": "Meredith, men's room. Make sure you replace the urinal cakes. They're worn down. Kevin file drawers. Angela kitchen. Oscar dusting. Where is Oscar?", "order": 5000, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 13, "scene": 7, "line": "He's out sick.", "order": 5001, "speaker": "Angela Martin"},
  {"season": 2, "episode": 13, "scene": 7, "line": "That's unacceptable.", "order": 5002, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 13, "scene": 7, "line": "I agree it's unacceptable. [longing look]", "order": 5003, "speaker": "Angela Martin"},
  {"season": 2, "episode": 13, "scene": 7, "line": "Whhh... What are you guys doing?", "order": 5004, "speaker": "Kevin Malone"},
  {"season": 2, "episode": 13, "scene": 8, "line": "Michael.", "order": 5005, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 13, "scene": 8, "line": "Yes.", "order": 5006, "speaker": "Michael Scott"},
  {"season": 2, "episode": 13, "scene": 8, "line": "Oscar is out sick.", "order": 5007, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 13, "scene": 8, "line": "On a Friday? [Dwight nods]", "order": 5008, "speaker": "Michael Scott"},
  {"season": 2, "episode": 13, "scene": 9, "line": "Can I do some of the talking?", "order": 5009, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 13, "scene": 9, "line": "I will do all the talking.", "order": 5010, "speaker": "Michael Scott"},
  {"season": 2, "episode": 13, "scene": 9, "line": "Ok, let him know that I'm here.", "order": 5011, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 13, "scene": 9, "line": "Hello.", "order": 5012, "speaker": "Oscar Martinez"},
  {"season": 2, "episode": 13, "scene": 9, "line": "What difference does it make whether your here?", "order": 5013, "speaker": "Michael Scott"},
  {"season": 2, "episode": 13, "scene": 9, "line": "Hello?", "order": 5014, "speaker": "Oscar Martinez"},
  {"season": 2, "episode": 13, "scene": 9, "line": "Hi, Oscar its Michael.", "order": 5015, "speaker": "Michael Scott"},
  {"season": 2, "episode": 13, "scene": 9, "line": "And Dwight.", "order": 5016, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 13, "scene": 9, "line": "Yechh, yeah, um, heard you were under the weather?", "order": 5017, "speaker": "Michael Scott"},
  {"season": 2, "episode": 13, "scene": 9, "line": "Yeah I think I came down with the flu.", "order": 5018, "speaker": "Oscar Martinez"},
  {"season": 2, "episode": 13, "scene": 9, "line": "Really? Oh, that is a shame. You know it's cleaning day here today? Could have used some of that famous Hispanic cleaning ethic.", "order": 5019, "speaker": "Michael Scott"},
  {"season": 2, "episode": 13, "scene": 9, "line": "Yeah, I feel terrible about it.", "order": 5020, "speaker": "Oscar Martinez"},
  {"season": 2, "episode": 13, "scene": 9, "line": "Ask him his symptoms. I'm on Web M.D.", "order": 5021, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 13, "scene": 9, "line": "What are your symptoms?", "order": 5022, "speaker": "Michael Scott"},
  {"season": 2, "episode": 13, "scene": 9, "line": "I have the chills.", "order": 5023, "speaker": "Oscar Martinez"},
  {"season": 2, "episode": 13, "scene": 9, "line": "Umm, hmmm.", "order": 5024, "speaker": "Michael Scott"},
  {"season": 2, "episode": 13, "scene": 9, "line": "I feel nauseous and my heads killing.", "order": 5025, "speaker": "Oscar Martinez"},
  {"season": 2, "episode": 13, "scene": 9, "line": "Checks out.", "order": 5026, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 13, "scene": 9, "line": "Michael is there anything you need from me? I'd like to go back to bed.", "order": 5027, "speaker": "Oscar Martinez"},
  {"season": 2, "episode": 13, "scene": 9, "line": "I need you to go back to bed. I need you to get better. See you Monday. Unless you're still sick. So have a great long weekend.", "order": 5028, "speaker": "Michael Scott"},
  {"season": 2, "episode": 13, "scene": 9, "line": "I'll just be sleep--- [Michael hangs up the phone before Oscar can finish]", "order": 5029, "speaker": "Oscar Martinez"},
  {"season": 2, "episode": 13, "scene": 9, "line": "Ok. First impressions?", "order": 5030, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 13, "scene": 9, "line": "He sounded sick.", "order": 5031, "speaker": "Michael Scott"},
  {"season": 2, "episode": 13, "scene": 9, "line": "Which is exactly how you'd wanna sound like if you wanted someone to think you were sick.", "order": 5032, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 13, "scene": 9, "line": "That's exactly what I was thinking.", "order": 5033, "speaker": "Michael Scott"},
  {"season": 2, "episode": 13, "scene": 9, "line": "Question? May I investigate?", "order": 5034, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 13, "scene": 9, "line": "Yeah. Drop what you're doing. Make this a priority. Because an office can't function efficiently unless people are at their desks doing their jobs.", "order": 5035, "speaker": "Michael Scott"},
  {"season": 2, "episode": 13, "scene": 10, "line": "I bought my veil.", "order": 5036, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 13, "scene": 10, "line": "Oh my God! That is so exciting! Can I be a bridesmaid?", "order": 5037, "speaker": "Kelly Kapoor"},
  {"season": 2, "episode": 13, "scene": 10, "line": "Ummm...", "order": 5038, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 13, "scene": 10, "line": "Listen, you don't have to answer now. But how are you going to do your hair?", "order": 5039, "speaker": "Kelly Kapoor"},
  {"season": 2, "episode": 13, "scene": 10, "line": "Ok. I was thinking about wearing it down. Kind of like, I don't know, like loose with big curls and...", "order": 5040, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 13, "scene": 10, "line": "You'd look like an angel. I'm seriously going to cry.", "order": 5041, "speaker": "Kelly Kapoor"},
  {"season": 2, "episode": 13, "scene": 10, "line": "Wowweee. Mikey likey. Why don't you wear your hair like that all the time. It's much sexier. [Pam puts hair back up] [Michael walks by Jim] Man, this must be torture for you.", "order": 5042, "speaker": "Michael Scott"},
  {"season": 2, "episode": 13, "scene": 11, "line": "Yeah. On the booze cruise I told Michael about some feelings I used to have for Pam. I had just broken up with Katy and had a couple drinks. And I confided in the world's worst confidant.", "order": 5043, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 13, "scene": 12, "line": "Hey Michael.", "order": 5044, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 13, "scene": 12, "line": "Hey Jim-bag.", "order": 5045, "speaker": "Michael Scott"},
  {"season": 2, "episode": 13, "scene": 12, "line": "Remember that thing I told you on the booze cruise about Pam? That... was... personal so if we can just keep that between you and me. That would be great.", "order": 5046, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 13, "scene": 12, "line": "Really?", "order": 5047, "speaker": "Michael Scott"},
  {"season": 2, "episode": 13, "scene": 12, "line": "Umm, hmm.", "order": 5048, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 13, "scene": 12, "line": "Who else knows?", "order": 5049, "speaker": "Michael Scott"},
  {"season": 2, "episode": 13, "scene": 12, "line": "Nobody.", "order": 5050, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 13, "scene": 12, "line": "Wow!", "order": 5051, "speaker": "Michael Scott"},
  {"season": 2, "episode": 13, "scene": 13, "line": "Jim and I are great friends. We hang out a ton, mostly at work. But, the fact that he told me his secret and no one else knows says everything about our friendship. And it is why, I intend on keeping that secret for as long as I possibly can.", "order": 5052, "speaker": "Michael Scott"},
  {"season": 2, "episode": 13, "scene": 14, "line": "My lips are sealed. [singing] My lips are sealed... Bangles.", "order": 5053, "speaker": "Michael Scott"},
  {"season": 2, "episode": 13, "scene": 14, "line": "Alright. Great. Thank you.", "order": 5054, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 13, "scene": 14, "line": "[singing] Can you hear me, they talk about us...", "order": 5055, "speaker": "Michael Scott"},
  {"season": 2, "episode": 13, "scene": 15, "line": "Listen Temp. I am conducting a little investigation so I'm no longer going to be able to head up spring cleaning. Do you think you can handle it?", "order": 5056, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 13, "scene": 15, "line": "Yeah, I think I can handle it.", "order": 5057, "speaker": "Ryan Howard"},
  {"season": 2, "episode": 13, "scene": 15, "line": "Do you", "order": 5058, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 13, "scene": 15, "line": "I think.", "order": 5059, "speaker": "Ryan Howard"},
  {"season": 2, "episode": 13, "scene": 15, "line": "[low] Oh God, here.", "order": 5060, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 13, "scene": 16, "line": "Hey, whatcha gettin'?", "order": 5061, "speaker": "Michael Scott"},
  {"season": 2, "episode": 13, "scene": 16, "line": "I'm going with grape.", "order": 5062, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 13, "scene": 16, "line": "Ah, good stuff, good stuff. Did you see the game last night?", "order": 5063, "speaker": "Michael Scott"},
  {"season": 2, "episode": 13, "scene": 16, "line": "Which one?", "order": 5064, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 13, "scene": 16, "line": "Any of em? So, uh, what's the 411? Any news on the 'P' situation?", "order": 5065, "speaker": "Michael Scott"},
  {"season": 2, "episode": 13, "scene": 16, "line": "I don't know what you mean.", "order": 5066, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 13, "scene": 16, "line": "P-A-M. P-A", "order": 5067, "speaker": "Michael Scott"},
  {"season": 2, "episode": 13, "scene": 16, "line": "Uh, uh, ok.", "order": 5068, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 13, "scene": 16, "line": "No it's okay, we're talking code.", "order": 5069, "speaker": "Michael Scott"},
  {"season": 2, "episode": 13, "scene": 16, "line": "What is?", "order": 5070, "speaker": "Stanley Hudson"},
  {"season": 2, "episode": 13, "scene": 16, "line": "Listen Stanley. How long does it take you to pick out a soda?", "order": 5071, "speaker": "Michael Scott"},
  {"season": 2, "episode": 13, "scene": 16, "line": "I'm going to take off actually.", "order": 5072, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 13, "scene": 16, "line": "Alright, well, cool. [Michael walks by Jim] Still deciding?", "order": 5073, "speaker": "Michael Scott"},
  {"season": 2, "episode": 13, "scene": 16, "line": "Hmm?", "order": 5074, "speaker": "Stanley Hudson"},
  {"season": 2, "episode": 13, "scene": 16, "line": "[Michael presses a button for Stanley] Peach iced tea. You're going to hate it.", "order": 5075, "speaker": "Michael Scott"},
  {"season": 2, "episode": 13, "scene": 17, "line": "Hey Oscar how ya doin'? Dwight Schrute calling. Listen a little question for you, buddy. I called six minutes ago and no one answered. So I was wondering if you could explain. Oh, I see, so. Sounds like you're too sick to come into work but your well enough to go to the pharmacy.", "order": 5076, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 13, "scene": 18, "line": "There are several different ways to tell if a perp is lying. The liar will avoid direct eye contact. The liar will cover part of his or her face with his hands, especially the mouth. The liar will perspire. Unfortunately I spoke to Oscar on the phone so none of this is useful.", "order": 5077, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 13, "scene": 19, "line": "It's Grrrrrrape! Soda.", "order": 5078, "speaker": "Michael Scott"},
  {"season": 2, "episode": 13, "scene": 19, "line": "Tony the tiger. You don't hear that much any more.", "order": 5079, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 13, "scene": 19, "line": "Not so much.", "order": 5080, "speaker": "Michael Scott"},
  {"season": 2, "episode": 13, "scene": 19, "line": "Ok, what is going on here?", "order": 5081, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 13, "scene": 19, "line": "Nothing.", "order": 5082, "speaker": "Michael Scott"},
  {"season": 2, "episode": 13, "scene": 19, "line": "Oh, really nothing? Fact: You are drinking grape soda. You never drink grape soda. Fact: You are talking to Jim. You never talk to Jim.", "order": 5083, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 13, "scene": 19, "line": "Fact: I love grape soda. I always have. Fact: Jim and I talk all the time. We tell each other secrets.", "order": 5084, "speaker": "Michael Scott"},
  {"season": 2, "episode": 13, "scene": 19, "line": "Ok. So what is the secret Michael?", "order": 5085, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 13, "scene": 19, "line": "Um, I had asked Michael if I could head up the Oscar investigation and he said that only Dwight was capable of handling such sensitive material.", "order": 5086, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 13, "scene": 19, "line": "Is that true?", "order": 5087, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 13, "scene": 19, "line": "Um, I don't know, yeah, yeah, yeah it is.", "order": 5088, "speaker": "Michael Scott"},
  {"season": 2, "episode": 13, "scene": 19, "line": "Thank you Michael. I know your telling the truth.", "order": 5089, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 13, "scene": 19, "line": "Ok.", "order": 5090, "speaker": "Michael Scott"},
  {"season": 2, "episode": 13, "scene": 19, "line": "I can tell. I won't let you down.", "order": 5091, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 13, "scene": 19, "line": "Good.", "order": 5092, "speaker": "Michael Scott"},
  {"season": 2, "episode": 13, "scene": 19, "line": "Thanks.", "order": 5093, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 13, "scene": 19, "line": "Whooo, nice. That was, that was slick. What are you doin' for lunch?", "order": 5094, "speaker": "Michael Scott"},
  {"season": 2, "episode": 13, "scene": 19, "line": "I don't know probably just gonna eat my ham and cheese sandwich in the break room.", "order": 5095, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 13, "scene": 19, "line": "Oh nonsense [lifts leg and puts it on Jim's desk], no way, no. Why don't, why don't I take you out to lunch? My treat.", "order": 5096, "speaker": "Michael Scott"},
  {"season": 2, "episode": 13, "scene": 19, "line": "No, that's alright, thank you though. It's, I, gotta do some cleaning, should probably stick around here.", "order": 5097, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 13, "scene": 19, "line": "Hey you know what we could do? We could spread out a blanket in the break room. Have a little picnic order some 'za. Talk about you know who.", "order": 5098, "speaker": "Michael Scott"},
  {"season": 2, "episode": 13, "scene": 19, "line": "Oh, ah, no but no. You know what let's go out. That was a good idea. Let's go out.", "order": 5099, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 13, "scene": 19, "line": "I know just he place.", "order": 5100, "speaker": "Michael Scott"},
  {"season": 2, "episode": 13, "scene": 20, "line": "[at Hooters] Oh man, you should order milk. Get it?", "order": 5101, "speaker": "Michael Scott"},
  {"season": 2, "episode": 13, "scene": 21, "line": "Why do I like Hooters? Well I will give you two reasons, the boobs and the hot wings.", "order": 5102, "speaker": "Michael Scott"},
  {"season": 2, "episode": 13, "scene": 22, "line": "Oh, here we go, here we go. Bogy at 3 o'clock. Hi.", "order": 5103, "speaker": "Michael Scott"},
  {"season": 2, "episode": 13, "scene": 22, "line": "Hey I'm Dana. Welcome to Hooters.", "order": 5104, "speaker": "Dana"},
  {"season": 2, "episode": 13, "scene": 22, "line": "We're not worthy. We're not worthy. Hello Dana, I am Michael and this is Jim and we are brothers.", "order": 5105, "speaker": "Michael Scott"},
  {"season": 2, "episode": 13, "scene": 22, "line": "Nope we're not brothers.", "order": 5106, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 13, "scene": 22, "line": "I'm his boss actually. And I treat him well. I'm taking him out to lunch cause I can afford it and he can have whatever he wants.", "order": 5107, "speaker": "Michael Scott"},
  {"season": 2, "episode": 13, "scene": 22, "line": "Can I just have the ham and cheese sandwich, thanks.", "order": 5108, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 13, "scene": 22, "line": "And for you?", "order": 5109, "speaker": "Dana"},
  {"season": 2, "episode": 13, "scene": 22, "line": "Tell me Dana, how is your chicken breast?", "order": 5110, "speaker": "Michael Scott"},
  {"season": 2, "episode": 13, "scene": 22, "line": "Oh, it's great. It's served with our world famous wing sauce.", "order": 5111, "speaker": "Dana"},
  {"season": 2, "episode": 13, "scene": 22, "line": "Mmmm, sounds yummy. I will have a chicken breast hold the chicken. [Giggles]", "order": 5112, "speaker": "Michael Scott"},
  {"season": 2, "episode": 13, "scene": 22, "line": "Is that what you really want?", "order": 5113, "speaker": "Dana"},
  {"season": 2, "episode": 13, "scene": 22, "line": "No, I'm gonna have the gourmet hot dog.", "order": 5114, "speaker": "Michael Scott"},
  {"season": 2, "episode": 13, "scene": 22, "line": "Great.", "order": 5115, "speaker": "Dana"},
  {"season": 2, "episode": 13, "scene": 23, "line": "Who took all the black ones?", "order": 5116, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 13, "scene": 23, "line": "That's a communal bowl.", "order": 5117, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 13, "scene": 23, "line": "So, how did Oscar sound when he called in?", "order": 5118, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 13, "scene": 23, "line": "Sick, like lots of sniffling. I don't know.", "order": 5119, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 13, "scene": 23, "line": "Sniffling how?", "order": 5120, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 13, "scene": 23, "line": "Umm. How many different ways are there to sniffle?", "order": 5121, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 13, "scene": 23, "line": "Three.", "order": 5122, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 13, "scene": 23, "line": "Ok, it was the second one.", "order": 5123, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 13, "scene": 23, "line": "Ok, good, thank you. That wasn't so hard now was it?", "order": 5124, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 13, "scene": 23, "line": "Nuh-uh.", "order": 5125, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 13, "scene": 24, "line": "What do you like best about Pam?", "order": 5126, "speaker": "Michael Scott"},
  {"season": 2, "episode": 13, "scene": 24, "line": "Uh, I really don't want to talk about it.", "order": 5127, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 13, "scene": 24, "line": "Is it her boobs, or...", "order": 5128, "speaker": "Michael Scott"},
  {"season": 2, "episode": 13, "scene": 24, "line": "Um, she's easy to talk to I guess and she's got a really good sense of humor.", "order": 5129, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 13, "scene": 24, "line": "Really?", "order": 5130, "speaker": "Michael Scott"},
  {"season": 2, "episode": 13, "scene": 24, "line": "Uh-huh.", "order": 5131, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 13, "scene": 24, "line": "Never get's any of my jokes.", "order": 5132, "speaker": "Michael Scott"},
  {"season": 2, "episode": 13, "scene": 24, "line": "What about you?", "order": 5133, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 13, "scene": 24, "line": "Her boobs, definitely.", "order": 5134, "speaker": "Michael Scott"},
  {"season": 2, "episode": 13, "scene": 24, "line": "Wow, that's not what I meant.", "order": 5135, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 13, "scene": 24, "line": "Here you go.", "order": 5136, "speaker": "Dana"},
  {"season": 2, "episode": 13, "scene": 24, "line": "Oh, thank you.", "order": 5137, "speaker": "Michael Scott"},
  {"season": 2, "episode": 13, "scene": 24, "line": "And I understand we have a birthday today.", "order": 5138, "speaker": "Dana"},
  {"season": 2, "episode": 13, "scene": 24, "line": "Ohhh happy birthday Jim!", "order": 5139, "speaker": "Michael Scott"},
  {"season": 2, "episode": 13, "scene": 24, "line": "Ready girls? Front side.", "order": 5140, "speaker": "Dana"},
  {"season": 2, "episode": 13, "scene": 24, "line": "You put your front side in; you put your front side out. You put your front side in and shake it all about. You do the hokey pokey and you turn yourself around. That's what it's all about. Whoo, hoo!", "order": 5141, "speaker": "Hooter's Girls"},
  {"season": 2, "episode": 13, "scene": 24, "line": "Thank you.", "order": 5142, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 13, "scene": 24, "line": "Woo! Yeah!", "order": 5143, "speaker": "Michael Scott"},
  {"season": 2, "episode": 13, "scene": 24, "line": "Thanks, thanks Dana.", "order": 5144, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 13, "scene": 24, "line": "Thank you very much.", "order": 5145, "speaker": "Michael Scott"},
  {"season": 2, "episode": 13, "scene": 25, "line": "Hilarious. Hey.", "order": 5146, "speaker": "Michael Scott"},
  {"season": 2, "episode": 13, "scene": 25, "line": "What did you guys talk about?", "order": 5147, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 13, "scene": 25, "line": "[Holds up Hooters t-shirt] Just you know politics, literature.", "order": 5148, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 13, "scene": 25, "line": "I hate you.", "order": 5149, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 13, "scene": 26, "line": "Quick Oscar update. I have conducted interviews with everyone in the office.", "order": 5150, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 13, "scene": 26, "line": "Just go to his house and see if he's sick. I could have done this Investigation in like twenty minutes.", "order": 5151, "speaker": "Michael Scott"},
  {"season": 2, "episode": 13, "scene": 26, "line": "Including prep time?", "order": 5152, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 13, "scene": 26, "line": "Just do it.", "order": 5153, "speaker": "Michael Scott"},
  {"season": 2, "episode": 13, "scene": 27, "line": "If I had to I could clean out my desk in five seconds and nobody would ever know I had ever been here. And I'd forget too.", "order": 5154, "speaker": "Ryan Howard"},
  {"season": 2, "episode": 13, "scene": 28, "line": "[Michael messes up hair to look like Jim's] Expenses.", "order": 5155, "speaker": "Michael Scott"},
  {"season": 2, "episode": 13, "scene": 28, "line": "Michael is that a wig?", "order": 5156, "speaker": "Kevin Malone"},
  {"season": 2, "episode": 13, "scene": 28, "line": "No. It's... I wear it like that sometimes. Is that a wig?", "order": 5157, "speaker": "Michael Scott"},
  {"season": 2, "episode": 13, "scene": 28, "line": "No.", "order": 5158, "speaker": "Kevin Malone"},
  {"season": 2, "episode": 13, "scene": 28, "line": "This is from Hooters.", "order": 5159, "speaker": "Angela Martin"},
  {"season": 2, "episode": 13, "scene": 28, "line": "Yeah, it's a business lunch.", "order": 5160, "speaker": "Michael Scott"},
  {"season": 2, "episode": 13, "scene": 28, "line": "Did Toby approve this?", "order": 5161, "speaker": "Angela Martin"},
  {"season": 2, "episode": 13, "scene": 28, "line": "No he did not. I don't need his permission.", "order": 5162, "speaker": "Michael Scott"},
  {"season": 2, "episode": 13, "scene": 28, "line": "You just got your corporate credit card back. Do you really want me to take it away again?", "order": 5163, "speaker": "Toby Flenderson"},
  {"season": 2, "episode": 13, "scene": 29, "line": "Uhhh it's ridiculous. They took my card away because I spent $80 bucks at a magic shop. What they don't understand is that I bought the stuff to impress potential clients. So business related, right?", "order": 5164, "speaker": "Michael Scott"},
  {"season": 2, "episode": 13, "scene": 30, "line": "I put a cigarette through a freakin' quarter. And you know what Toby? They almost bought from us.", "order": 5165, "speaker": "Michael Scott"},
  {"season": 2, "episode": 13, "scene": 30, "line": "I'm not processing this.", "order": 5166, "speaker": "Toby Flenderson"},
  {"season": 2, "episode": 13, "scene": 30, "line": "Look Jim needed a relaxing lunch, he has been depressed and it has been affecting his productivity. How is that not work related?", "order": 5167, "speaker": "Michael Scott"},
  {"season": 2, "episode": 13, "scene": 30, "line": "He seems fine to me.", "order": 5168, "speaker": "Toby Flenderson"},
  {"season": 2, "episode": 13, "scene": 30, "line": "You're not his friend, you don't know. He is in love with a girl he works with who's engaged. So just cut me some slack. Please?", "order": 5169, "speaker": "Michael Scott"},
  {"season": 2, "episode": 13, "scene": 30, "line": "Pam?", "order": 5170, "speaker": "Kelly Kapoor"},
  {"season": 2, "episode": 13, "scene": 31, "line": "Angela who would you choose Jim or Roy?", "order": 5171, "speaker": "Phyllis Lapin"},
  {"season": 2, "episode": 13, "scene": 31, "line": "It's nobody's business, Phyllis. Roy.", "order": 5172, "speaker": "Angela Martin"},
  {"season": 2, "episode": 13, "scene": 32, "line": "Jim has got it bad for Pam.", "order": 5173, "speaker": "Kevin Malone"},
  {"season": 2, "episode": 13, "scene": 32, "line": "Oh ho! Which one is Pam?", "order": 5174, "speaker": "Creed Bratton"},
  {"season": 2, "episode": 13, "scene": 32, "line": "Well she's the... Hey Michael so do you think Jim will try to break up the wedding?", "order": 5175, "speaker": "Kevin Malone"},
  {"season": 2, "episode": 13, "scene": 32, "line": "You know what Kevin? Jim is a friend of mine, so the only people that this crush really concerns is Jim and Pam... and me.", "order": 5176, "speaker": "Michael Scott"},
  {"season": 2, "episode": 13, "scene": 33, "line": "As a volunteer Sheriff's Deputy I have been doing surveillance for years. One time I suspected an ex-girlfriend of mine of cheating on me. So I tailed her for six straight nights. Turns out she was, with a couple of guys actually so... mystery solved.", "order": 5177, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 13, "scene": 34, "line": "Jim, why didn't you tell me you had a crush on Pam?", "order": 5178, "speaker": "Kelly Kapoor"},
  {"season": 2, "episode": 13, "scene": 35, "line": "Well the cats out of the bag. I used to have a crush on Pam and now I [hesitate] don't. Riveting.", "order": 5179, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 13, "scene": 36, "line": "Nice... she is", "order": 5180, "speaker": "Kevin Malone"},
  {"season": 2, "episode": 13, "scene": 37, "line": "Hey.", "order": 5181, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 13, "scene": 37, "line": "Hey.", "order": 5182, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 13, "scene": 37, "line": "Did you find anything good in your desk?", "order": 5183, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 13, "scene": 37, "line": "Ah, coupon for a free sandwich.", "order": 5184, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 13, "scene": 37, "line": "Score.", "order": 5185, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 13, "scene": 37, "line": "It expired in August, and my cell phone charger from two years ago.", "order": 5186, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 13, "scene": 37, "line": "Big day.", "order": 5187, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 13, "scene": 37, "line": "Big day.", "order": 5188, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 13, "scene": 37, "line": "Hey oh, listen, um, I told Michael on the booze cruise. It's so stupid. Um, I told Michael that I had had a crush on you when you first started here.", "order": 5189, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 13, "scene": 37, "line": "Oh.", "order": 5190, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 13, "scene": 37, "line": "Well I thought that, I figured you should hear it from me rather than, I mean you know Michael.", "order": 5191, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 13, "scene": 37, "line": "Right.", "order": 5192, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 13, "scene": 37, "line": "And seriously, it's totally not a big deal, ok? And when I found out you were engaged, I mean.", "order": 5193, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 13, "scene": 37, "line": "No, I know, like, I kind of like, I thought that maybe you did when I first started.", "order": 5194, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 13, "scene": 37, "line": "Oh you did?", "order": 5195, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 13, "scene": 37, "line": "No, I mean, just 'cause we like got along so well.", "order": 5196, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 13, "scene": 37, "line": "No, no, you saw through me, great.", "order": 5197, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 13, "scene": 37, "line": "So are you going to be like totally awkward around me now?", "order": 5198, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 13, "scene": 37, "line": "Oh yeah, yeah... hope that's okay.", "order": 5199, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 13, "scene": 37, "line": "Mmm, hmm.", "order": 5200, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 13, "scene": 37, "line": "And Pam it was like three years ago so I am totally over it.", "order": 5201, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 13, "scene": 37, "line": "Cool.", "order": 5202, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 13, "scene": 37, "line": "Ok.", "order": 5203, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 13, "scene": 38, "line": "Stay low... This is it... There he is. He's been gone for at least two hours. Who is that? Come to Papa... Oh yes. Let's roll. I knew it! You are so busted. Ice skates, shopping bags? I think I know what's going on here. You weren't sick at all.", "order": 5204, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 13, "scene": 38, "line": "Who's this?", "order": 5205, "speaker": "Gil"},
  {"season": 2, "episode": 13, "scene": 38, "line": "This is Dwight Schrute. Who is this?", "order": 5206, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 13, "scene": 38, "line": "Gil.", "order": 5207, "speaker": "Gil"},
  {"season": 2, "episode": 13, "scene": 38, "line": "Are you going to tell Michael?", "order": 5208, "speaker": "Oscar Martinez"},
  {"season": 2, "episode": 13, "scene": 38, "line": "How bout this. I don't tell Michael and in exchange you owe me one great big giant favor. Redeemable by me at a time and place of my choosing.", "order": 5209, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 13, "scene": 39, "line": "Guess what I found out about Oscar tonight? He was lying about being sick. Should I have reported Oscar's malfeasance. Hmm, probably, but now I know something he doesn't want me to know. So I can use his malfeasance to establish leverage. Otherwise, it's just malfeasance for malfeasanceses-ses sake.", "order": 5210, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 13, "scene": 40, "line": "Hey.", "order": 5211, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 13, "scene": 40, "line": "I know, I know, I know.", "order": 5212, "speaker": "Michael Scott"},
  {"season": 2, "episode": 13, "scene": 40, "line": "Umm, what happened?", "order": 5213, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 13, "scene": 40, "line": "I, oh, just, um, I know I was trying to, expense reports. And then God, Toby, you know he just... I know. I'm just, I just hope that, I just hope that [starts to get choked up] this doesn't affect our friendship! Stupid, this is so stupid.", "order": 5214, "speaker": "Michael Scott"},
  {"season": 2, "episode": 13, "scene": 40, "line": "Hey, hey, wow, wow. Listen man it's, you know what. It's not a big deal.", "order": 5215, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 13, "scene": 40, "line": "Ok, I'm fine, no I know, I'm good, I'm good, it's just.", "order": 5216, "speaker": "Michael Scott"},
  {"season": 2, "episode": 13, "scene": 40, "line": "Look its one day, everything's gonna be alright. No big deal. You good?", "order": 5217, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 13, "scene": 40, "line": "Yeah I'm good.", "order": 5218, "speaker": "Michael Scott"},
  {"season": 2, "episode": 13, "scene": 40, "line": "Good.", "order": 5219, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 13, "scene": 41, "line": "Creed did you organize the menu book?", "order": 5220, "speaker": "Ryan Howard"},
  {"season": 2, "episode": 13, "scene": 41, "line": "Oh, I thought that was more on a volunteer basis.", "order": 5221, "speaker": "Creed Bratton"},
  {"season": 2, "episode": 13, "scene": 41, "line": "No, that was mandatory.", "order": 5222, "speaker": "Ryan Howard"},
  {"season": 2, "episode": 13, "scene": 41, "line": "Oh, I thought it was a volunteer thing.", "order": 5223, "speaker": "Creed Bratton"},
  {"season": 2, "episode": 13, "scene": 42, "line": "Hey, here's your schedule for next week. Are you okay?", "order": 5224, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 13, "scene": 42, "line": "Yeah I'm fine. Look, about you and Jim.", "order": 5225, "speaker": "Michael Scott"},
  {"season": 2, "episode": 13, "scene": 42, "line": "Oh, no, that's, you don't have to.", "order": 5226, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 13, "scene": 42, "line": "No, I feel it's my responsibility as your boss slash friend.", "order": 5227, "speaker": "Michael Scott"},
  {"season": 2, "episode": 13, "scene": 42, "line": "No, really, it's okay. I know that Jim had, like a crush on me when he first started. But that was a long time ago, so.", "order": 5228, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 13, "scene": 42, "line": "It wasn't that long ago. It was on the booze cruise.", "order": 5229, "speaker": "Michael Scott"},
  {"season": 2, "episode": 13, "scene": 42, "line": "Jim had a crush on me on the booze cruise or he told you about it on the booze cruise?", "order": 5230, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 13, "scene": 42, "line": "Yehhh, okay, shuuttt it Michael. I'm done. That's it. I'm out.", "order": 5231, "speaker": "Michael Scott"},
  {"season": 2, "episode": 13, "scene": 43, "line": "Ready?", "order": 5232, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 13, "scene": 43, "line": "Yep.", "order": 5233, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 13, "scene": 44, "line": "People are always coming to me. 'Michael, I have a secret. Your the only one I trust.' No thanks, because keeping a secret can only lead to trouble. Like I was watching Cinemax last weekend. This movie, Portrait of a... Prostitute something. Secrets of a Call... More Secrets of a Call Girl. And the lead character, Shila, is framed for murder. She goes on the run and winds up working at a bordello in Malibu. I don't, I don't want to live like that. I like it here. I don't want to be Shila, I like being Michael Scott.", "order": 5234, "speaker": "Michael Scott"},
  {"season": 2, "episode": 14, "scene": 1, "line": "[catching Jim looking at him at Pam's desk] What?", "order": 5235, "speaker": "Ryan Howard"},
  {"season": 2, "episode": 14, "scene": 1, "line": "Oh, nothing.", "order": 5236, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 14, "scene": 2, "line": "Pam's on vacation and she gets back tomorrow, so it'll be nice to see her. It'll be nice, and, uh, she set a date for the wedding with Roy. Uh... June. Summer. So, that'll be nice. And that's that.", "order": 5237, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 14, "scene": 3, "line": "[again catching Jim looking at him] What?", "order": 5238, "speaker": "Ryan Howard"},
  {"season": 2, "episode": 14, "scene": 3, "line": "Oh, nothing.", "order": 5239, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 14, "scene": 4, "line": "Jim's been looking at me kind of a lot all week. I would be creeped out by it, but it's nothing compared to the way Michael looks at me.", "order": 5240, "speaker": "Ryan Howard"},
  {"season": 2, "episode": 14, "scene": 5, "line": "Spamster!", "order": 5241, "speaker": "Michael Scott"},
  {"season": 2, "episode": 14, "scene": 5, "line": "Um, Pam plus Spam plus...?", "order": 5242, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 14, "scene": 5, "line": "Hamster.", "order": 5243, "speaker": "Michael Scott"},
  {"season": 2, "episode": 14, "scene": 5, "line": "Right.", "order": 5244, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 14, "scene": 5, "line": "Welcome back! How was your vacation?", "order": 5245, "speaker": "Michael Scott"},
  {"season": 2, "episode": 14, "scene": 5, "line": "It was great.", "order": 5246, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 14, "scene": 5, "line": "Yeah?", "order": 5247, "speaker": "Michael Scott"},
  {"season": 2, "episode": 14, "scene": 5, "line": "Mm-hm.", "order": 5248, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 14, "scene": 5, "line": "Did you get lucky? Oh! Boink!", "order": 5249, "speaker": "Michael Scott"},
  {"season": 2, "episode": 14, "scene": 6, "line": "Roy and I just got back from the Poconos. I get ten vacation days a year, and I try to hold off taking them for as long as possible, and this year I got to the third week in January.", "order": 5250, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 14, "scene": 7, "line": "I am Pam. Spicoli guy. Oh, God. Names, numbers. Okay. [walking into office] Whoa! God! Yuck, yuck. Yuck. Yuck!", "order": 5251, "speaker": "Michael Scott"},
  {"season": 2, "episode": 14, "scene": 7, "line": "What?", "order": 5252, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 14, "scene": 7, "line": "Wow! What happened in there?", "order": 5253, "speaker": "Michael Scott"},
  {"season": 2, "episode": 14, "scene": 7, "line": "I don't know.", "order": 5254, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 14, "scene": 7, "line": "There is stink in there, my God! What is... what is that?", "order": 5255, "speaker": "Michael Scott"},
  {"season": 2, "episode": 14, "scene": 7, "line": "[looking at pile on Michel's carpet] Oh... I don't know.", "order": 5256, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 14, "scene": 7, "line": "Is it a bird?", "order": 5257, "speaker": "Michael Scott"},
  {"season": 2, "episode": 14, "scene": 7, "line": "No, I don't think it's a bird.", "order": 5258, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 14, "scene": 7, "line": "Oh, God! How could that happen? How could... right in the middle of the carpet.", "order": 5259, "speaker": "Michael Scott"},
  {"season": 2, "episode": 14, "scene": 7, "line": "What's goin' on?", "order": 5260, "speaker": "Kevin Malone"},
  {"season": 2, "episode": 14, "scene": 7, "line": "Um, somebody vomited right in the middle of the carpet in my office.", "order": 5261, "speaker": "Michael Scott"},
  {"season": 2, "episode": 14, "scene": 7, "line": "[taking a look] I don't think that's vomit.", "order": 5262, "speaker": "Kevin Malone"},
  {"season": 2, "episode": 14, "scene": 7, "line": "Check it out.", "order": 5263, "speaker": "Michael Scott"},
  {"season": 2, "episode": 14, "scene": 7, "line": "Me?", "order": 5264, "speaker": "Kevin Malone"},
  {"season": 2, "episode": 14, "scene": 7, "line": "Check it out. Don't be a wuss, just get... no, I'm not holding your coffee.", "order": 5265, "speaker": "Michael Scott"},
  {"season": 2, "episode": 14, "scene": 7, "line": "Oh, that's ridiculous.", "order": 5266, "speaker": "Kevin Malone"},
  {"season": 2, "episode": 14, "scene": 7, "line": "What is it?", "order": 5267, "speaker": "Michael Scott"},
  {"season": 2, "episode": 14, "scene": 7, "line": "Michael. [tapping on door]", "order": 5268, "speaker": "Kevin Malone"},
  {"season": 2, "episode": 14, "scene": 7, "line": "What is it? No, just tell me what it is.", "order": 5269, "speaker": "Michael Scott"},
  {"season": 2, "episode": 14, "scene": 7, "line": "[pounding on door] Michael, I ... I ... I gotta get outta here. I can't hold my breath that long.", "order": 5270, "speaker": "Kevin Malone"},
  {"season": 2, "episode": 14, "scene": 7, "line": "Open the door up!", "order": 5271, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 14, "scene": 8, "line": "It smelled", "order": 5272, "speaker": "Kevin Malone"},
  {"season": 2, "episode": 14, "scene": 9, "line": "[after going in to check out the smell] Phew. Oh! No, mm-mm. [leaving quickly]", "order": 5273, "speaker": "Pam Beesly & others"},
  {"season": 2, "episode": 14, "scene": 9, "line": "I cannot believe a pipe burst and left that in there.", "order": 5274, "speaker": "Michael Scott"},
  {"season": 2, "episode": 14, "scene": 9, "line": "That's no burst pipe.", "order": 5275, "speaker": "Toby Flenderson"},
  {"season": 2, "episode": 14, "scene": 9, "line": "How do you know that? What is it, then?", "order": 5276, "speaker": "Michael Scott"},
  {"season": 2, "episode": 14, "scene": 9, "line": "Hi guys. Somebody makin' soup?", "order": 5277, "speaker": "Creed Bratton"},
  {"season": 2, "episode": 14, "scene": 10, "line": "[as cleaning lady with mask leaves] Here she comes. All cleaned? Great. [walks into office]", "order": 5278, "speaker": "Michael Scott"},
  {"season": 2, "episode": 14, "scene": 10, "line": "[coughing] It's still stinky.", "order": 5279, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 14, "scene": 10, "line": "That is worse.", "order": 5280, "speaker": "Michael Scott"},
  {"season": 2, "episode": 14, "scene": 10, "line": "She probably scrubbed it", "order": 5281, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 14, "scene": 11, "line": "[while in his reeking office] I am a big Fear Factor fan. I'm a big fan of anything Joe Rogan does, actually, so this is sort of like my audition tape. Um... [clearing throat] I can't stand it [gets up to leave], I can't stay in here another second. No!", "order": 5282, "speaker": "Michael Scott"},
  {"season": 2, "episode": 14, "scene": 12, "line": "Hey! Welcome back!", "order": 5283, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 14, "scene": 12, "line": "Thanks!", "order": 5284, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 14, "scene": 12, "line": "So, how was the resort? Did you ski a lot?", "order": 5285, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 14, "scene": 12, "line": "A little.", "order": 5286, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 14, "scene": 12, "line": "Good! What's goin' on here?", "order": 5287, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 14, "scene": 13, "line": "What? I did not do that. That sounds disgusting.", "order": 5288, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 14, "scene": 14, "line": "[barely stifling laughter] It wasn't me. Um... it wasn't me. [regaining composure] It was not me.", "order": 5289, "speaker": "Ryan Howard"},
  {"season": 2, "episode": 14, "scene": 15, "line": "[smelling the stink] Oh. Wow.", "order": 5290, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 14, "scene": 15, "line": "[giggles at Roy]", "order": 5291, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 14, "scene": 15, "line": "[sitting at Jim's desk] Hey Jim. I thought that we would be desk buddies while they changed my carpet.", "order": 5292, "speaker": "Michael Scott"},
  {"season": 2, "episode": 14, "scene": 15, "line": "That might be a little difficult with the one computer.", "order": 5293, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 14, "scene": 15, "line": "Oh... It's ...", "order": 5294, "speaker": "Michael Scott"},
  {"season": 2, "episode": 14, "scene": 15, "line": "But there's definitely a desk open in the back.", "order": 5295, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 14, "scene": 15, "line": "[reluctantly] Yeah ...", "order": 5296, "speaker": "Michael Scott"},
  {"season": 2, "episode": 14, "scene": 15, "line": "...which I guess I'll be taking.", "order": 5297, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 14, "scene": 15, "line": "No, no, no! Seriously, I don't mind sharing.", "order": 5298, "speaker": "Michael Scott"},
  {"season": 2, "episode": 14, "scene": 15, "line": "No, no, no, seriously, I'll be in the back.", "order": 5299, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 14, "scene": 16, "line": "Hey, Kelly.", "order": 5300, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 14, "scene": 16, "line": "Are you moving back here?", "order": 5301, "speaker": "Kelly Kapoor"},
  {"season": 2, "episode": 14, "scene": 16, "line": "Um, just for the day while Michael's at my desk.", "order": 5302, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 14, "scene": 16, "line": "Because Toby used to sit there, but he had to move over there because of an allergy.", "order": 5303, "speaker": "Kelly Kapoor"},
  {"season": 2, "episode": 14, "scene": 16, "line": "Allergy to... the desk?", "order": 5304, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 14, "scene": 16, "line": "[shaking head] Weird.", "order": 5305, "speaker": "Kelly Kapoor"},
  {"season": 2, "episode": 14, "scene": 17, "line": "[putting his feet on desk] Yeah, yeah, yeah. Old bullpen.", "order": 5306, "speaker": "Michael Scott"},
  {"season": 2, "episode": 14, "scene": 17, "line": "[putting his feet on desk] Ha ha ha... the old bullpen.", "order": 5307, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 14, "scene": 17, "line": "Don't ape me.", "order": 5308, "speaker": "Michael Scott"},
  {"season": 2, "episode": 14, "scene": 17, "line": "Okay.", "order": 5309, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 14, "scene": 17, "line": "This is great.", "order": 5310, "speaker": "Michael Scott"},
  {"season": 2, "episode": 14, "scene": 17, "line": "Yeah!", "order": 5311, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 14, "scene": 17, "line": "The pressures of my office are insane.", "order": 5312, "speaker": "Michael Scott"},
  {"season": 2, "episode": 14, "scene": 17, "line": "[agreeing] Mm.", "order": 5313, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 14, "scene": 17, "line": "I just... you couldn't understand, but man, you guys have it so easy out here, you know? I used to sit right here.", "order": 5314, "speaker": "Michael Scott"},
  {"season": 2, "episode": 14, "scene": 17, "line": "No way!", "order": 5315, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 14, "scene": 17, "line": "Yeah.", "order": 5316, "speaker": "Michael Scott"},
  {"season": 2, "episode": 14, "scene": 17, "line": "And who had your office?", "order": 5317, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 14, "scene": 18, "line": "Ed Truck. [exclaiming is disgust] Ed Truck was the manager before me. Horrible. He hated fun. It was like, 'Oh, Ed Truck is walking toward us. Stop having fun. Start pretending to do work.' What a jerk. He's... You know what? I swore to myself that if I ever got to walk around the room as manager, people would laugh when they saw me coming and would applaud as I walked away.", "order": 5318, "speaker": "Michael Scott"},
  {"season": 2, "episode": 14, "scene": 19, "line": "[to Jim] I'm serious. My closet doors will not shut. I mean, it only takes so long to measure to make sure that clothes will hang up because aren't all hangers like that big? So I don't understand why the closet engineer didn't think of that. So now I'm doing this new thing where I just leave piles of clothes on the floor and then I walk around the piles to get an outfit...", "order": 5319, "speaker": "Kelly Kapoor"},
  {"season": 2, "episode": 14, "scene": 20, "line": "You know who used to sit at that desk?", "order": 5320, "speaker": "Michael Scott"},
  {"season": 2, "episode": 14, "scene": 20, "line": "That guy Miles who quit to form his own company?", "order": 5321, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 14, "scene": 20, "line": "Mm-mm. Todd Packer.", "order": 5322, "speaker": "Michael Scott"},
  {"season": 2, "episode": 14, "scene": 20, "line": "No!", "order": 5323, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 14, "scene": 20, "line": "Yeah.", "order": 5324, "speaker": "Michael Scott"},
  {"season": 2, "episode": 14, "scene": 20, "line": "I thought he was out on the road.", "order": 5325, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 14, "scene": 20, "line": "He was, but, uh... that desk was empty. He'd come in and sit there sometimes.", "order": 5326, "speaker": "Michael Scott"},
  {"season": 2, "episode": 14, "scene": 20, "line": "Ah.", "order": 5327, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 14, "scene": 21, "line": "When I was in training, many years ago... not so long ago... I worked side-by-side with a fellow named Todd Packer, and together we rocked the office [picture behind Michael falls]. Packer and I once spent the whole day with our pants off, and when people noticed, we convinced them that", "order": 5328, "speaker": "Michael Scott"},
  {"season": 2, "episode": 14, "scene": 22, "line": "I will gladly pay you Tuesday for a hamburger today.", "order": 5329, "speaker": "Michael Scott"},
  {"season": 2, "episode": 14, "scene": 22, "line": "[on phone] Excuse me one second, please. [to Michael] What is it that you need right now that you can't wait until I'm off the phone with a customer?", "order": 5330, "speaker": "Stanley Hudson"},
  {"season": 2, "episode": 14, "scene": 22, "line": "Oh, a customer, well, sound the alarm. [laughs] Okay.", "order": 5331, "speaker": "Michael Scott"},
  {"season": 2, "episode": 14, "scene": 23, "line": "Another time, Packer held this guy's head in the toilet for like a minute. Guy had no sense of humor about it. Probably why he wasn't hired.", "order": 5332, "speaker": "Michael Scott"},
  {"season": 2, "episode": 14, "scene": 24, "line": "[after Michael punches him in the arm] What did you hit me for?", "order": 5333, "speaker": "Creed Bratton"},
  {"season": 2, "episode": 14, "scene": 24, "line": "Charley horse!", "order": 5334, "speaker": "Michael Scott"},
  {"season": 2, "episode": 14, "scene": 24, "line": "What?", "order": 5335, "speaker": "Creed Bratton"},
  {"season": 2, "episode": 14, "scene": 24, "line": "Charley horse!", "order": 5336, "speaker": "Michael Scott"},
  {"season": 2, "episode": 14, "scene": 24, "line": "You shouldn't have hit me, Michael.", "order": 5337, "speaker": "Creed Bratton"},
  {"season": 2, "episode": 14, "scene": 24, "line": "Oh, okay. Gah.", "order": 5338, "speaker": "Michael Scott"},
  {"season": 2, "episode": 14, "scene": 25, "line": "Once, as a joke, Packer banged every chick in the office. [giggles] It was hysterical.", "order": 5339, "speaker": "Michael Scott"},
  {"season": 2, "episode": 14, "scene": 26, "line": "[to Jim] Beyonce, pink the color, Pink the person, hot dogs, basically anything that is awesome. Snow cones...", "order": 5340, "speaker": "Kelly Kapoor"},
  {"season": 2, "episode": 14, "scene": 26, "line": "Hey Jim, Michael wanted me to ask you how to raise your desk chair.", "order": 5341, "speaker": "Ryan Howard"},
  {"season": 2, "episode": 14, "scene": 26, "line": "It's the lever on the side.", "order": 5342, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 14, "scene": 26, "line": "That's what I told him. Thanks. [leaves]", "order": 5343, "speaker": "Ryan Howard"},
  {"season": 2, "episode": 14, "scene": 26, "line": "Oh my God, he is so cute! Would you talk to him for me and see if he likes me?", "order": 5344, "speaker": "Kelly Kapoor"},
  {"season": 2, "episode": 14, "scene": 26, "line": "No, I don't think I can...", "order": 5345, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 14, "scene": 26, "line": "Oh, please Jim? Please, please, Jim. Please, please, please? He's so cute. I like him", "order": 5346, "speaker": "Kelly Kapoor"},
  {"season": 2, "episode": 14, "scene": 27, "line": "[whispering] Dwight.", "order": 5347, "speaker": "Michael Scott"},
  {"season": 2, "episode": 14, "scene": 27, "line": "[whispering] Michael.", "order": 5348, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 14, "scene": 27, "line": "Let's send up Accounting.", "order": 5349, "speaker": "Michael Scott"},
  {"season": 2, "episode": 14, "scene": 27, "line": "What?", "order": 5350, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 14, "scene": 27, "line": "Old fashioned raid. Sales on Accounting. Yeah. Follow my lead.", "order": 5351, "speaker": "Michael Scott"},
  {"season": 2, "episode": 14, "scene": 28, "line": "Hey guys.", "order": 5352, "speaker": "Michael Scott"},
  {"season": 2, "episode": 14, "scene": 28, "line": "Hey, Michael.", "order": 5353, "speaker": "Oscar Martinez"},
  {"season": 2, "episode": 14, "scene": 28, "line": "Ahem. What's up?", "order": 5354, "speaker": "Michael Scott"},
  {"season": 2, "episode": 14, "scene": 28, "line": "Hey, Dwight.", "order": 5355, "speaker": "Oscar Martinez"},
  {"season": 2, "episode": 14, "scene": 28, "line": "[as they throw accountants' files and supplies around] Ahhhh! Whoo hoo! Come on, come on, come on, come on! Sales rules!", "order": 5356, "speaker": "Michael Scott & Dwight Schrute"},
  {"season": 2, "episode": 14, "scene": 28, "line": "Yeah! [laughing]", "order": 5357, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 14, "scene": 28, "line": "Yeah! Oh ho ho [laughing]", "order": 5358, "speaker": "Michael Scott"},
  {"season": 2, "episode": 14, "scene": 28, "line": "Should we help 'em pick up their stuff?", "order": 5359, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 14, "scene": 28, "line": "No, no, no, no. We don't do that. We don't do that.", "order": 5360, "speaker": "Michael Scott"},
  {"season": 2, "episode": 14, "scene": 28, "line": "Okay.", "order": 5361, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 14, "scene": 28, "line": "Watch out, Pam. You're next!", "order": 5362, "speaker": "Michael Scott"},
  {"season": 2, "episode": 14, "scene": 28, "line": "You're gonna throw my things on the ground?", "order": 5363, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 14, "scene": 28, "line": "Maybe!", "order": 5364, "speaker": "Michael Scott"},
  {"season": 2, "episode": 14, "scene": 29, "line": "What happened in Michael's office was wrong. I understand it [chuckles], it makes sense [regains composure] But it... it was still wrong.", "order": 5365, "speaker": "Oscar Martinez"},
  {"season": 2, "episode": 14, "scene": 30, "line": "Why would somebody ruin a perfectly good carpet? I don't know. It could be done out of hate. It could be done out of love. It could be completely neutral. Maybe somebody hates the cleaning lady. And, well, she doesn't do a very good job, obviously, because my office still reeks like you would not believe. I hate her.", "order": 5366, "speaker": "Michael Scott"},
  {"season": 2, "episode": 14, "scene": 31, "line": "You know what? I am beginning to think that what happened to my carpet was an act of terrorism against the office. The only thing that makes any sense.", "order": 5367, "speaker": "Michael Scott"},
  {"season": 2, "episode": 14, "scene": 32, "line": "[on phone] Hello, am I the 107th caller? [hangs up, dials again] Hello, Rock 107. Am I the 107th caller? [hangs up and dials again] Hell , Rock 107. Am I the 107th caller? [hangs up and begins to dial again] I'm totally gonna win us that box set.", "order": 5368, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 14, "scene": 32, "line": "Stop.", "order": 5369, "speaker": "Michael Scott"},
  {"season": 2, "episode": 14, "scene": 32, "line": "Jethro Tull...", "order": 5370, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 14, "scene": 32, "line": "Stop it. [Dwight hangs up] Stop. It. [Dwight beings to dial] Don't. Don't.", "order": 5371, "speaker": "Michael Scott"},
  {"season": 2, "episode": 14, "scene": 32, "line": "I need to make a sales call. Please?", "order": 5372, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 14, "scene": 32, "line": "All right.", "order": 5373, "speaker": "Michael Scott"},
  {"season": 2, "episode": 14, "scene": 32, "line": "[on phone, whispering] Am I the 107th caller?", "order": 5374, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 14, "scene": 33, "line": "[to Roy in Jim's earshot] ...back so soon.", "order": 5375, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 14, "scene": 33, "line": "We can go back in, like, a couple of weeks maybe.", "order": 5376, "speaker": "Roy Anderson"},
  {"season": 2, "episode": 14, "scene": 33, "line": "Yeah, right.", "order": 5377, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 14, "scene": 33, "line": "Okay, maybe another month, like, maybe for, like President's Day or something.", "order": 5378, "speaker": "Roy Anderson"},
  {"season": 2, "episode": 14, "scene": 33, "line": "Yeah, that's right. We could do a three-day weekend. I wonder if I could, like, call in sick on the Friday. Then I get a four-day weekend.", "order": 5379, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 14, "scene": 34, "line": "[to unseen co-worker] But it's so weird to fall asleep. And I just hate it. 'Cause I try to go to bed at, like, 9:30.", "order": 5380, "speaker": "Kelly Kapoor"},
  {"season": 2, "episode": 14, "scene": 35, "line": "[to Roy as Jim escapes into bathroom] Are you kidding?", "order": 5381, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 14, "scene": 35, "line": "No.", "order": 5382, "speaker": "Roy Anderson"},
  {"season": 2, "episode": 14, "scene": 36, "line": "Hi, guys.", "order": 5383, "speaker": "Michael Scott"},
  {"season": 2, "episode": 14, "scene": 36, "line": "We haven't finished getting things in order from your last visit.", "order": 5384, "speaker": "Angela Martin"},
  {"season": 2, "episode": 14, "scene": 36, "line": "I'm just walking around.", "order": 5385, "speaker": "Michael Scott"},
  {"season": 2, "episode": 14, "scene": 36, "line": "Were you?", "order": 5386, "speaker": "Angela Martin"},
  {"season": 2, "episode": 14, "scene": 36, "line": "Well, yeah.", "order": 5387, "speaker": "Michael Scott"},
  {"season": 2, "episode": 14, "scene": 36, "line": "It's just that we're really swamped over here, Michael.", "order": 5388, "speaker": "Oscar Martinez"},
  {"season": 2, "episode": 14, "scene": 36, "line": "Oh, and I'm not? Why would you say that? Because I'm having fun? You guys just are workin' for the weekend, aren't you? I'm workin' for the week. Sales team, listen to me. This is what we're gonna do. I'm gonna up the ante a little bit literally. Right here, I'm gonna put a crisp one hundred dollar bill. The person with the most sales at the end of the day gets to keep the cash. Sound good?", "order": 5389, "speaker": "Michael Scott"},
  {"season": 2, "episode": 14, "scene": 36, "line": "Yeah!", "order": 5390, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 14, "scene": 36, "line": "[counting cash] Seventy, eighty, one, two three. Eighty-three dollars. Still a lotta money and I'm going to ... [moves money after seeing workmen walk by] ... I'm gonna leave it right over here where everybody can see it. I will be taking Jim's clients today because he is not here and out of sight, out of the contest. Let's see who winds up with the cash, shall we?", "order": 5391, "speaker": "Michael Scott"},
  {"season": 2, "episode": 14, "scene": 36, "line": "You're gonna compete against us?", "order": 5392, "speaker": "Phyllis Lapin"},
  {"season": 2, "episode": 14, "scene": 36, "line": "Oh, it is on, Phyllis, it is so on!", "order": 5393, "speaker": "Michael Scott"},
  {"season": 2, "episode": 14, "scene": 36, "line": "It is so on!", "order": 5394, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 14, "scene": 36, "line": "God, this is gonna be fun.", "order": 5395, "speaker": "Michael Scott"},
  {"season": 2, "episode": 14, "scene": 36, "line": "Michael is gonna wipe the floor with us!", "order": 5396, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 14, "scene": 37, "line": "[on phone] So you have 40 boxes going out, and I will deliver those personally in a Sebring. Very good, nice doing business with you. Thank you. [hangs up] Yes! [chuckles] Oh, yeah! Read it and weep. Oh! Oh, look at that! [puts post-it on Phyllis' forehead] Look at me, Phyllis! Oh, what is that? That's my sale! [humming then dancing victoriously]", "order": 5397, "speaker": "Michael Scott"},
  {"season": 2, "episode": 14, "scene": 37, "line": "[walking by with new carpet] What... What's that? Whatcha doing?", "order": 5398, "speaker": "Darryl Philbin"},
  {"season": 2, "episode": 14, "scene": 37, "line": "[stops dance] Nothing.", "order": 5399, "speaker": "Michael Scott"},
  {"season": 2, "episode": 14, "scene": 37, "line": "[laughing] I think he's dancing.", "order": 5400, "speaker": "Roy Anderson"},
  {"season": 2, "episode": 14, "scene": 37, "line": "No. Just ...", "order": 5401, "speaker": "Michael Scott"},
  {"season": 2, "episode": 14, "scene": 37, "line": "That was definitely not dancing.", "order": 5402, "speaker": "Darryl Philbin"},
  {"season": 2, "episode": 14, "scene": 37, "line": "You know what, guys? It's none of your concern. It was official business, so just...", "order": 5403, "speaker": "Michael Scott"},
  {"season": 2, "episode": 14, "scene": 37, "line": "Paper business.", "order": 5404, "speaker": "Darryl Philbin"},
  {"season": 2, "episode": 14, "scene": 37, "line": "Yeah, paper business. Is this done?", "order": 5405, "speaker": "Michael Scott"},
  {"season": 2, "episode": 14, "scene": 37, "line": "Nope.", "order": 5406, "speaker": "Roy Anderson"},
  {"season": 2, "episode": 14, "scene": 37, "line": "Extreme Home Makeover puts together a house in an hour. If you were on that crew, you would be fired like that. [snaps]", "order": 5407, "speaker": "Michael Scott"},
  {"season": 2, "episode": 14, "scene": 38, "line": "Somebody did something bad to Michael's carpet. Maybe that's all we need to know.", "order": 5408, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 14, "scene": 39, "line": "[to Oscar] Who do you think did it?", "order": 5409, "speaker": "Creed Bratton"},
  {"season": 2, "episode": 14, "scene": 39, "line": "Are you kidding? I thought it was you.", "order": 5410, "speaker": "Oscar Martinez"},
  {"season": 2, "episode": 14, "scene": 39, "line": "Really? I thought you. [both laugh in Michael's earshot]", "order": 5411, "speaker": "Creed Bratton"},
  {"season": 2, "episode": 14, "scene": 40, "line": "This was no act of God. A person did this. A person who works in this office. Maybe all of them.", "order": 5412, "speaker": "Michael Scott"},
  {"season": 2, "episode": 14, "scene": 41, "line": "You know what? Today is not a good day for a sales contest. We're... we're not... we're not doin' this today.", "order": 5413, "speaker": "Michael Scott"},
  {"season": 2, "episode": 14, "scene": 41, "line": "That doesn't seem fair.", "order": 5414, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 14, "scene": 41, "line": "You wanna talk about fair? Does anyone need to smell my old carpet? You explain to me how that was fair, and I'll explain to you how this is fair. Plus I just... I think that picking today was sort of taking advantage.", "order": 5415, "speaker": "Michael Scott"},
  {"season": 2, "episode": 14, "scene": 41, "line": "But you're the one who picked today.", "order": 5416, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 14, "scene": 41, "line": "I am a victim of a hate crime. Stanley knows what I'm talkin' about.", "order": 5417, "speaker": "Michael Scott"},
  {"season": 2, "episode": 14, "scene": 41, "line": "That's not what a hate crime is.", "order": 5418, "speaker": "Stanley Hudson"},
  {"season": 2, "episode": 14, "scene": 41, "line": "Well I hated it! A lot! Okay, I... you know what? If the guilty person would just come forward and take their punishment, we'd be done . [no one comes forward] Very well. Then you are all punished.", "order": 5419, "speaker": "Michael Scott"},
  {"season": 2, "episode": 14, "scene": 41, "line": "What's our punishment?", "order": 5420, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 14, "scene": 41, "line": "You're all on a time out. Just sit there quietly. [phone rings, Phyllis reaches to answer] No. NO! [phone continues to ring]", "order": 5421, "speaker": "Michael Scott"},
  {"season": 2, "episode": 14, "scene": 42, "line": "Hey!", "order": 5422, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 14, "scene": 42, "line": "What's up?", "order": 5423, "speaker": "Ryan Howard"},
  {"season": 2, "episode": 14, "scene": 42, "line": "Nothing much. Let me ask you something. It's actually little awkward.", "order": 5424, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 14, "scene": 42, "line": "What?", "order": 5425, "speaker": "Ryan Howard"},
  {"season": 2, "episode": 14, "scene": 42, "line": "What do you think of Kelly?", "order": 5426, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 14, "scene": 42, "line": "I don't know. Depends if you like a little junk in ... [notices camera] Umm... She's really cool.", "order": 5427, "speaker": "Ryan Howard"},
  {"season": 2, "episode": 14, "scene": 42, "line": "Are you interested in her?", "order": 5428, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 14, "scene": 42, "line": "Yeah, totally.", "order": 5429, "speaker": "Ryan Howard"},
  {"season": 2, "episode": 14, "scene": 42, "line": "Really?", "order": 5430, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 14, "scene": 42, "line": "Did she say something?", "order": 5431, "speaker": "Ryan Howard"},
  {"season": 2, "episode": 14, "scene": 42, "line": "She said lots of things.", "order": 5432, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 14, "scene": 42, "line": "Do you know if she's looking for a long-term thing or if she'd be cool just hangin' out?", "order": 5433, "speaker": "Ryan Howard"},
  {"season": 2, "episode": 14, "scene": 42, "line": "I have no idea.", "order": 5434, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 14, "scene": 42, "line": "Can you find out?", "order": 5435, "speaker": "Ryan Howard"},
  {"season": 2, "episode": 14, "scene": 42, "line": "Yeah. Sure.", "order": 5436, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 14, "scene": 43, "line": "[to Jim] Oh, long-term, definitely. Fall in love, have babies, spend every second together... but don't tell him that, okay? Just tell him I'm, like, up for anything. I mean, I'm not a slut, but who knows?", "order": 5437, "speaker": "Kelly Kapoor"},
  {"season": 2, "episode": 14, "scene": 44, "line": "Do you remember Ed Truck?", "order": 5438, "speaker": "Michael Scott"},
  {"season": 2, "episode": 14, "scene": 44, "line": "Sure. He hired me. How's he doing?", "order": 5439, "speaker": "Creed Bratton"},
  {"season": 2, "episode": 14, "scene": 44, "line": "How would I know?", "order": 5440, "speaker": "Michael Scott"},
  {"season": 2, "episode": 14, "scene": 44, "line": "I thought you might.", "order": 5441, "speaker": "Creed Bratton"},
  {"season": 2, "episode": 14, "scene": 44, "line": "My biggest fear is turning into him.", "order": 5442, "speaker": "Michael Scott"},
  {"season": 2, "episode": 14, "scene": 44, "line": "Michael, you should have much bigger fears than that.", "order": 5443, "speaker": "Creed Bratton"},
  {"season": 2, "episode": 14, "scene": 44, "line": "[sighs] I wasn't talking literally, Creed. Yeah, being buried alive would be worse. Happy? Why am I talking to you?", "order": 5444, "speaker": "Michael Scott"},
  {"season": 2, "episode": 14, "scene": 45, "line": "[meeting Ed Truck in parking lot] Ed? Hi. Thanks for meeting me. Must be kinda neat comin' back.", "order": 5445, "speaker": "Michael Scott"},
  {"season": 2, "episode": 14, "scene": 45, "line": "Yeah. Should we go upstairs?", "order": 5446, "speaker": "Ed"},
  {"season": 2, "episode": 14, "scene": 45, "line": "Uh, well, honestly Ed, I really don't wanna be up there right now.", "order": 5447, "speaker": "Michael Scott"},
  {"season": 2, "episode": 14, "scene": 45, "line": "So, what's the problem with my pension?", "order": 5448, "speaker": "Ed"},
  {"season": 2, "episode": 14, "scene": 45, "line": "Oh, no, no, no. You're good. It was clerical. You're good. Um, well, somebody did something in my office, and I now think that they did it on purpose and it was directed at me.", "order": 5449, "speaker": "Michael Scott"},
  {"season": 2, "episode": 14, "scene": 45, "line": "Well, what was done?", "order": 5450, "speaker": "Ed"},
  {"season": 2, "episode": 14, "scene": 45, "line": "I didn't get a good look at... it, but it smells horrible.", "order": 5451, "speaker": "Michael Scott"},
  {"season": 2, "episode": 14, "scene": 45, "line": "Yeah, somebody once did that in my office.", "order": 5452, "speaker": "Ed"},
  {"season": 2, "episode": 14, "scene": 45, "line": "Really?", "order": 5453, "speaker": "Michael Scott"},
  {"season": 2, "episode": 14, "scene": 45, "line": "Yeah.", "order": 5454, "speaker": "Ed"},
  {"season": 2, "episode": 14, "scene": 45, "line": "Well, that figures. So how did you deal with people not liking you?", "order": 5455, "speaker": "Michael Scott"},
  {"season": 2, "episode": 14, "scene": 45, "line": "You can't expect to be friends with everybody.", "order": 5456, "speaker": "Ed"},
  {"season": 2, "episode": 14, "scene": 45, "line": "Well... s-sure I can.", "order": 5457, "speaker": "Michael Scott"},
  {"season": 2, "episode": 14, "scene": 45, "line": "No. They'll always think of you as a boss first.", "order": 5458, "speaker": "Ed"},
  {"season": 2, "episode": 14, "scene": 45, "line": "Not necessarily. You can love a boss like you do a father.", "order": 5459, "speaker": "Michael Scott"},
  {"season": 2, "episode": 14, "scene": 45, "line": "I'm not sure that ever happens.", "order": 5460, "speaker": "Ed"},
  {"season": 2, "episode": 14, "scene": 45, "line": "Well, okay. Different management styles.", "order": 5461, "speaker": "Michael Scott"},
  {"season": 2, "episode": 14, "scene": 45, "line": "Why can't your workers be your workers, family be your family, your friends be your friends?", "order": 5462, "speaker": "Ed"},
  {"season": 2, "episode": 14, "scene": 46, "line": "Last week I would have given a kidney to anyone in this office. I would have reached right into my stomach and pulled it out for them. But now, no. I don't have the relationship with these people that I thought I did. I hope they ask, so they can hear me say, 'Uh... no. I only give my organs to my real friends. Go get yourself a monkey kidney.'", "order": 5463, "speaker": "Michael Scott"},
  {"season": 2, "episode": 14, "scene": 47, "line": "[on phone] Hey, Brenda. This is, uh, Jim Halpert from the boat. And I got your number from the corporate directory and, well, I was assuming that you probably gave it to them because you wanted me to ask you out, right? Um, so gimme a call back. You can get my number from said directory, um, or just check your e-mail 'cause I just sent you one. Yikes. Uh... give me a call back, I hope. I'll talk to you later. Bye.", "order": 5464, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 14, "scene": 47, "line": "You just asked a girl out on the phone!", "order": 5465, "speaker": "Kelly Kapoor"},
  {"season": 2, "episode": 14, "scene": 47, "line": "Yep.", "order": 5466, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 14, "scene": 48, "line": "[on phone] Yes.", "order": 5467, "speaker": "Michael Scott"},
  {"season": 2, "episode": 14, "scene": 48, "line": "Hello, yes. I'm looking for a gay nerd named Michael Scott.", "order": 5468, "speaker": "Todd Packer"},
  {"season": 2, "episode": 14, "scene": 48, "line": "Who is this? How did you get this number?", "order": 5469, "speaker": "Michael Scott"},
  {"season": 2, "episode": 14, "scene": 48, "line": "Your mom, you gay nerd!", "order": 5470, "speaker": "Todd Packer"},
  {"season": 2, "episode": 14, "scene": 48, "line": "Oh my God. Packer. Packster. Whacky Pack. How you doin'?", "order": 5471, "speaker": "Michael Scott"},
  {"season": 2, "episode": 14, "scene": 48, "line": "Hey, did you get that package I left for you?", "order": 5472, "speaker": "Todd Packer"},
  {"season": 2, "episode": 14, "scene": 48, "line": "Uh... no. Did anybody see a package here today? No. How big was it?", "order": 5473, "speaker": "Michael Scott"},
  {"season": 2, "episode": 14, "scene": 48, "line": "It was pretty big.", "order": 5474, "speaker": "Todd Packer"},
  {"season": 2, "episode": 14, "scene": 48, "line": "Really?", "order": 5475, "speaker": "Michael Scott"},
  {"season": 2, "episode": 14, "scene": 48, "line": "Yeah.", "order": 5476, "speaker": "Todd Packer"},
  {"season": 2, "episode": 14, "scene": 48, "line": "Did you see a big package? Where did you leave it?", "order": 5477, "speaker": "Michael Scott"},
  {"season": 2, "episode": 14, "scene": 48, "line": "Left it in the middle of your office.", "order": 5478, "speaker": "Todd Packer"},
  {"season": 2, "episode": 14, "scene": 48, "line": "Really? Guys, did you see a big package in my office?", "order": 5479, "speaker": "Michael Scott"},
  {"season": 2, "episode": 14, "scene": 48, "line": "You mean the thing?", "order": 5480, "speaker": "Roy Anderson"},
  {"season": 2, "episode": 14, "scene": 48, "line": "[laughs uproariously]", "order": 5481, "speaker": "Todd Packer"},
  {"season": 2, "episode": 14, "scene": 48, "line": "Are you kidding me? Oh!", "order": 5482, "speaker": "Michael Scott"},
  {"season": 2, "episode": 14, "scene": 48, "line": "Special delivery!", "order": 5483, "speaker": "Todd Packer"},
  {"season": 2, "episode": 14, "scene": 48, "line": "That was Packer! Oh, you're... you are dead. You are dead, my friend! That is hilar... Oh, God! Of course it was you.", "order": 5484, "speaker": "Michael Scott"},
  {"season": 2, "episode": 14, "scene": 48, "line": "Sit on the throne, Michael.", "order": 5485, "speaker": "Todd Packer"},
  {"season": 2, "episode": 14, "scene": 48, "line": "Oh. [laughs and claps] Yeah, yeah! Oh my God. It was Packer!", "order": 5486, "speaker": "Michael Scott"},
  {"season": 2, "episode": 14, "scene": 49, "line": "It takes an advanced sense of humor. I don't expect everybody to understand. It was done out of love, just like I thought. It's ah... God, these people are so... these are good people. We have fun. [giggles] We just have fun! Oh, I'm just so sorry that I threw the thing out.", "order": 5487, "speaker": "Michael Scott"},
  {"season": 2, "episode": 14, "scene": 50, "line": "You have seven unheard messages.", "order": 5488, "speaker": "Jim Halpert's voicemail"},
  {"season": 2, "episode": 14, "scene": 50, "line": "[voicemail message for Jim] Hey, Jim. It's Pam. I keep looking up to say something to you and then Michael's there and it's horrible. Anyway, I'm bored. Come back!", "order": 5489, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 14, "scene": 51, "line": "[voicemail message for Jim] Hey, guess what? I moved my computer so I can't see Michael's head. It's working. I think I can have a career as a very specific type of decorator.", "order": 5490, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 14, "scene": 52, "line": "[voicemail message for Jim] Sudoku. Level moderate. 18 minutes. Suck on that, Halpert.", "order": 5491, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 14, "scene": 53, "line": "[voicemail message for Jim] I'll transfer you. Dunder Mifflin, this is Pam. Hold, please. Dunder Mifflin, this is ... okay, sorry. Michael was standing at my desk, and I needed to be busy or who knows what would've happened, so thank you.", "order": 5492, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 14, "scene": 54, "line": "[voicemail message for Jim] Hey, what's that word we made up when you have a thing stuck in your shoe? Anyway, I have a thing stuck in my shoe.", "order": 5493, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 14, "scene": 55, "line": "[voicemail message for Jim] Hey, I have a chance to sneak out of here early, and I'm not messing this up, so I'll see you tomorrow.", "order": 5494, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 14, "scene": 56, "line": "[voicemail message for Jim] Calling from my cell phone. I don't know if you guys figured out who did that to Michael's carpet yet, but I have a theory that involves an inter-departmental conspiracy. Everybody in the office. We need to talk.", "order": 5495, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 14, "scene": 58, "line": "Obviously, this was Kevin. This is his sense of humor exactly.", "order": 5496, "speaker": "Angela Martin"},
  {"season": 2, "episode": 14, "scene": 59, "line": "There are so many people with motives, even me. Almost everyone is a suspect. Whoever did this is a genius. [laughs]", "order": 5497, "speaker": "Kevin Malone"},
  {"season": 2, "episode": 14, "scene": 61, "line": "Hey.", "order": 5498, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 14, "scene": 61, "line": "Hey.", "order": 5499, "speaker": "Toby Flenderson"},
  {"season": 2, "episode": 14, "scene": 61, "line": "So, you used to share that cubicle with Kelly, right?", "order": 5500, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 14, "scene": 61, "line": "Mmm-hmm.", "order": 5501, "speaker": "Toby Flenderson"},
  {"season": 2, "episode": 14, "scene": 61, "line": "And then you just changed desks, right?", "order": 5502, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 14, "scene": 61, "line": "Yeah.", "order": 5503, "speaker": "Toby Flenderson"},
  {"season": 2, "episode": 14, "scene": 61, "line": "To one without a cubicle.", "order": 5504, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 14, "scene": 61, "line": "Yeah.", "order": 5505, "speaker": "Toby Flenderson"},
  {"season": 2, "episode": 14, "scene": 61, "line": "So, you just couldn't take it anymore, huh?", "order": 5506, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 14, "scene": 61, "line": "What are you talking about?", "order": 5507, "speaker": "Toby Flenderson"},
  {"season": 2, "episode": 14, "scene": 61, "line": "So, that's how it's gonna be.", "order": 5508, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 14, "scene": 61, "line": "Yeah.", "order": 5509, "speaker": "Toby Flenderson"},
  {"season": 2, "episode": 14, "scene": 62, "line": "Honestly, I don't even hear her anymore. It's like waves crashing against the beach.", "order": 5510, "speaker": "Toby Flenderson"},
  {"season": 2, "episode": 15, "scene": 1, "line": "So, I'm happy to be here. It's very nice to see all of you. You're all looking well.", "order": 5511, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 15, "scene": 2, "line": "Today's a 'women in the workplace' thing. Jan's coming in from Corporate to talk to all the women about... um... I don't really know what. But Michael's not allowed in. She said that about five times.", "order": 5512, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 15, "scene": 3, "line": "Women today, though we have the same options as men, we often face a very different set of obstacles in getting there. So...", "order": 5513, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 15, "scene": 3, "line": "[knocks] Hey, what's going on?", "order": 5514, "speaker": "Michael Scott"},
  {"season": 2, "episode": 15, "scene": 3, "line": "Michael... I thought we agreed you wouldn't be here.", "order": 5515, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 15, "scene": 3, "line": "Yeah... I... You know what... I... I... I just thought about it. I just have a few things I want to say.", "order": 5516, "speaker": "Michael Scott"},
  {"season": 2, "episode": 15, "scene": 3, "line": "What are you doing?", "order": 5517, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 15, "scene": 3, "line": "Hold... Just hear me out. What is more important than Quality? E-Quality. Now studies show that today's woman, the Ally McBeal woman, as I call her, is at a crossroads...", "order": 5518, "speaker": "Michael Scott"},
  {"season": 2, "episode": 15, "scene": 3, "line": "Michael.", "order": 5519, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 15, "scene": 3, "line": "No, just uh... you have come a long way, baby. But I just... just want to keep it within reason.", "order": 5520, "speaker": "Michael Scott"},
  {"season": 2, "episode": 15, "scene": 3, "line": "Michael.", "order": 5521, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 15, "scene": 3, "line": "They did this up in Albany...", "order": 5522, "speaker": "Michael Scott"},
  {"season": 2, "episode": 15, "scene": 3, "line": "You are not allowed in this session.", "order": 5523, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 15, "scene": 3, "line": "And they ended up turning the break room into a lactation room which is disgusting so...", "order": 5524, "speaker": "Michael Scott"},
  {"season": 2, "episode": 15, "scene": 3, "line": "Now you're really not allowed in this session.", "order": 5525, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 15, "scene": 3, "line": "Well, I'm their boss, so I feel like...", "order": 5526, "speaker": "Michael Scott"},
  {"season": 2, "episode": 15, "scene": 3, "line": "I'm your boss.", "order": 5527, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 15, "scene": 3, "line": "[stands up] Anybody want any coffee or...", "order": 5528, "speaker": "Michael Scott"},
  {"season": 2, "episode": 15, "scene": 3, "line": "We're fine, Michael. We just need you to leave, please.", "order": 5529, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 15, "scene": 4, "line": "Women in the workplace... yeah, translation 'I have been banned from my own conference room so that Jan can talk in secret to all the girls.' Oh! Sorry. 'Women of the workplace.' About what? I don't know. Clothes. Me. Eeegkh!", "order": 5530, "speaker": "Michael Scott"},
  {"season": 2, "episode": 15, "scene": 5, "line": "Ladies, I am so, so sorry. Can we start again? We were on such a roll. I... I... really apologize.", "order": 5531, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 15, "scene": 5, "line": "Jan.", "order": 5532, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 15, "scene": 5, "line": "Yes, Pam?", "order": 5533, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 15, "scene": 5, "line": "Michael's still at the door.", "order": 5534, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 15, "scene": 5, "line": "Michael!", "order": 5535, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 15, "scene": 6, "line": "[in the background] So one obstacle is how assertiveness is perceived differently in men and women. Men who are assertive will be admired. They're called... anyone?", "order": 5536, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 15, "scene": 7, "line": "It's a terrible idea.", "order": 5537, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 15, "scene": 7, "line": "What is?", "order": 5538, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 15, "scene": 7, "line": "Them in there all together. If they stay in there too long they're going to get on the same cycle. Wreak havoc on our plumbing.", "order": 5539, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 15, "scene": 8, "line": "Everyone. Guys. Circle up, please. Come on over. Bring your chairs. Toby, come on over. You're a guy... too... sort of. Let's do this!", "order": 5540, "speaker": "Michael Scott"},
  {"season": 2, "episode": 15, "scene": 9, "line": "[in the background] Well, first of all, I, uh, just want to warm up a little bit. Let's just clap.", "order": 5541, "speaker": "Michael Scott"},
  {"season": 2, "episode": 15, "scene": 10, "line": "Let's just clap. Ready? [clapping] Yeah! Yeah!", "order": 5542, "speaker": "Michael Scott"},
  {"season": 2, "episode": 15, "scene": 10, "line": "Yeah!", "order": 5543, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 15, "scene": 10, "line": "That's what I'm talking about!", "order": 5544, "speaker": "Michael Scott"},
  {"season": 2, "episode": 15, "scene": 10, "line": "I don't know what you're doing here, Michael,", "order": 5545, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 15, "scene": 10, "line": "Just having a little 'guys in the workplace' thing.", "order": 5546, "speaker": "Michael Scott"},
  {"season": 2, "episode": 15, "scene": 10, "line": "... but it's very destructive.", "order": 5547, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 15, "scene": 10, "line": "Why can't boys play with dolls? Why does society force us to use urinals when sitting down is far more comfortable?", "order": 5548, "speaker": "Michael Scott"},
  {"season": 2, "episode": 15, "scene": 10, "line": "Can you please do this somewhere else, Michael?", "order": 5549, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 15, "scene": 10, "line": "We have nowhere else Jan. This...", "order": 5550, "speaker": "Michael Scott"},
  {"season": 2, "episode": 15, "scene": 10, "line": "We could do it in the warehouse.", "order": 5551, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 15, "scene": 10, "line": "Dwight, excellent idea. Go to the warehouse.", "order": 5552, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 15, "scene": 10, "line": "OK, OK, Fine. Yeah, actually, perfect. Perfect. You know what? There's another side to this place, gentleman. And I know we all love our cushy jobs and our fun, exciting office. But do you realize that underneath us, there's another world. The warehouse world. A world that is teeming with sweat and dirt and life. Life. The bowels of the office. These guys are down there, they are real men doing real man's work. We are going to learn how a warehouse works.", "order": 5553, "speaker": "Michael Scott"},
  {"season": 2, "episode": 15, "scene": 11, "line": "Oh, I think it's going to work out great. Because managing the warehouse is a very important part of my job. And I haven't been there in months.", "order": 5554, "speaker": "Michael Scott"},
  {"season": 2, "episode": 15, "scene": 12, "line": "Remember on Lost when they met the Others?", "order": 5555, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 15, "scene": 13, "line": "I'm so sorry about that... um... so where were we? Pam, are you okay taking notes then?", "order": 5556, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 15, "scene": 13, "line": "Mmmhmm.", "order": 5557, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 15, "scene": 13, "line": "Please? Thank you. very much.", "order": 5558, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 15, "scene": 14, "line": "So let's meet the warehouse! Let's get some shots. Pan around there. This is Darryl, one of our warehouse staff. Darryl, what is your biggest fear?", "order": 5559, "speaker": "Michael Scott"},
  {"season": 2, "episode": 15, "scene": 14, "line": "My biggest fear is that someone will distract us from getting all the shipments out on time.", "order": 5560, "speaker": "Darryl Philbin"},
  {"season": 2, "episode": 15, "scene": 14, "line": "You know, Darryl is actually the Foreman here and not Roy, which is cool. There's Roy riding the big rig. So Roy is actually going to be marrying Pam sometime this summer. And... uh, she's our receptionist. Sort of a Brangelina thing.", "order": 5561, "speaker": "Michael Scott"},
  {"season": 2, "episode": 15, "scene": 14, "line": "Why?", "order": 5562, "speaker": "Roy Anderson"},
  {"season": 2, "episode": 15, "scene": 14, "line": "Brangelina is the Brad Pitt and Angelina... Roy...", "order": 5563, "speaker": "Michael Scott"},
  {"season": 2, "episode": 15, "scene": 14, "line": "I don't understand.", "order": 5564, "speaker": "Roy Anderson"},
  {"season": 2, "episode": 15, "scene": 14, "line": "Roy and Pam. It's a Ram. It's a Ram thing.", "order": 5565, "speaker": "Michael Scott"},
  {"season": 2, "episode": 15, "scene": 14, "line": "[talking to Jim] I bet Roy heard about you liking Pam. I bet he'll try to beat you up.", "order": 5566, "speaker": "Kevin Malone"},
  {"season": 2, "episode": 15, "scene": 14, "line": "Thanks for the head's up, Kev.", "order": 5567, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 15, "scene": 14, "line": "I've got your back if he does. But try to stay out of it.", "order": 5568, "speaker": "Kevin Malone"},
  {"season": 2, "episode": 15, "scene": 15, "line": "[points at math equasion on chalkboard] Uhhuhhuh. Just in case there's someone down here who shouldn't be. A little 'Good Will Hunting' situation. All right. Troops. This is an important day. Big day. Now you may look around and see two groups here. White collar. Blue collar. But I don't see it that way. And you know why not? Because I am collar-blind.", "order": 5569, "speaker": "Michael Scott"},
  {"season": 2, "episode": 15, "scene": 16, "line": "Why don't we go around the table and all say something that we know we're good at. I will start. I am good at public speaking.", "order": 5570, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 15, "scene": 16, "line": "Hi. I'm Meredith and I'm an alch... good at supplier relations.", "order": 5571, "speaker": "Meredith Palmer"},
  {"season": 2, "episode": 15, "scene": 16, "line": "Great. Phyllis?", "order": 5572, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 15, "scene": 16, "line": "I'm good at computer stuff, emails, spreadsheets, all that.", "order": 5573, "speaker": "Phyllis Lapin"},
  {"season": 2, "episode": 15, "scene": 16, "line": "[disbelieving] Really?", "order": 5574, "speaker": "Angela Martin"},
  {"season": 2, "episode": 15, "scene": 16, "line": "I don't know. I thought that I wasn't going to be asked that...", "order": 5575, "speaker": "Phyllis Lapin"},
  {"season": 2, "episode": 15, "scene": 16, "line": "No. Okay. Stop. Go on...", "order": 5576, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 15, "scene": 16, "line": "I've seen some of your spreadsheets.", "order": 5577, "speaker": "Angela Martin"},
  {"season": 2, "episode": 15, "scene": 16, "line": "Really? I thought they were pretty...", "order": 5578, "speaker": "Phyllis Lapin"},
  {"season": 2, "episode": 15, "scene": 17, "line": "I don't know how I fit in with these women. Here. Or with Jan. Um... I mean we get along great. Fine. Um... I guess the person I have the most in common with is...", "order": 5579, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 15, "scene": 18, "line": "Jim... Halpert. Hey uh, I, uh, you know heard there's a rumor going around about you used to have a crush on Pam.", "order": 5580, "speaker": "Roy Anderson"},
  {"season": 2, "episode": 15, "scene": 18, "line": "Oh, no, no. No.", "order": 5581, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 15, "scene": 18, "line": "No, it's cool, because I know you're a good guy. And I know that that crush ended a long time ago, so... you know. We're cool, right?", "order": 5582, "speaker": "Roy Anderson"},
  {"season": 2, "episode": 15, "scene": 18, "line": "Yeah. Nope. Yeah. Definitely.", "order": 5583, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 15, "scene": 18, "line": "You know, it's great with me cause that way, glad she has a friend at work she can get through the day with. She's not all bap bap bap bap when she gets home.", "order": 5584, "speaker": "Roy Anderson"},
  {"season": 2, "episode": 15, "scene": 18, "line": "Yeah. I like talking to her too.", "order": 5585, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 15, "scene": 18, "line": "So, we're cool, right?", "order": 5586, "speaker": "Roy Anderson"},
  {"season": 2, "episode": 15, "scene": 18, "line": "Yes. Yeah.", "order": 5587, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 15, "scene": 18, "line": "All right.", "order": 5588, "speaker": "Roy Anderson"},
  {"season": 2, "episode": 15, "scene": 18, "line": "Yep. Cool, man.", "order": 5589, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 15, "scene": 18, "line": "Sweet.", "order": 5590, "speaker": "Roy Anderson"},
  {"season": 2, "episode": 15, "scene": 18, "line": "[blows a sigh of relief]", "order": 5591, "speaker": "Kevin Malone"},
  {"season": 2, "episode": 15, "scene": 19, "line": "Hey, Mike, look. How bout we go upstairs, too. You know learn how the office works. We can all switch places today.", "order": 5592, "speaker": "Darryl Philbin"},
  {"season": 2, "episode": 15, "scene": 19, "line": "Oh... well... okay... yeah, you know what? I don't think... You.. You're... My job sucks compared to this. I don't think you'd like it up there.", "order": 5593, "speaker": "Michael Scott"},
  {"season": 2, "episode": 15, "scene": 19, "line": "The experience...", "order": 5594, "speaker": "Darryl Philbin"},
  {"season": 2, "episode": 15, "scene": 19, "line": "Guys! Want to start unloading the truck?", "order": 5595, "speaker": "Michael Scott"},
  {"season": 2, "episode": 15, "scene": 20, "line": "Okay. Let's go. Step up.", "order": 5596, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 15, "scene": 21, "line": "Check this out! Look at that! Look at that [squeezes blow-up doll] bwup-bwa! [talks in girly voice] Hello! How are... [regular voice] Oh! Kay. That is great. That is good stuff.", "order": 5597, "speaker": "Michael Scott"},
  {"season": 2, "episode": 15, "scene": 22, "line": "In five years, I'd like to be... five years sober.", "order": 5598, "speaker": "Meredith Palmer"},
  {"season": 2, "episode": 15, "scene": 22, "line": "That is an excellent goal.", "order": 5599, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 15, "scene": 22, "line": "Four and a half.", "order": 5600, "speaker": "Meredith Palmer"},
  {"season": 2, "episode": 15, "scene": 22, "line": "I'll tell you one thing. I am not going to be one of those women schlepping her kids around in a minivan.", "order": 5601, "speaker": "Kelly Kapoor"},
  {"season": 2, "episode": 15, "scene": 22, "line": "Great! Uh-huh?", "order": 5602, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 15, "scene": 22, "line": "I want an SUV... with three rows of seats.", "order": 5603, "speaker": "Kelly Kapoor"},
  {"season": 2, "episode": 15, "scene": 22, "line": "[general murmuring of agreement]", "order": 5604, "speaker": "Women"},
  {"season": 2, "episode": 15, "scene": 23, "line": "Well, I'll be honest. One of the goals of these women seminars is to feel out if there's any standouts. Women who could be a valuable addition to our Corporate life.", "order": 5605, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 15, "scene": 24, "line": "Michael wants us to bond so we need topics for conversation.", "order": 5606, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 15, "scene": 24, "line": "Ponies.", "order": 5607, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 15, "scene": 24, "line": "No.", "order": 5608, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 15, "scene": 24, "line": "How about rainbows?", "order": 5609, "speaker": "Ryan Howard"},
  {"season": 2, "episode": 15, "scene": 24, "line": "No.", "order": 5610, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 15, "scene": 24, "line": "Flowers.", "order": 5611, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 15, "scene": 25, "line": "It's dangerous, Michael. Come on, get off this.", "order": 5612, "speaker": "Darryl Philbin"},
  {"season": 2, "episode": 15, "scene": 25, "line": "Hey, you're going to, going to hurt yourself.", "order": 5613, "speaker": "Michael Scott"},
  {"season": 2, "episode": 15, "scene": 25, "line": "Mike.", "order": 5614, "speaker": "Darryl Philbin"},
  {"season": 2, "episode": 15, "scene": 25, "line": "Stand clear.", "order": 5615, "speaker": "Michael Scott"},
  {"season": 2, "episode": 15, "scene": 25, "line": "Mike. Get off of the lift. Please. Come on now.", "order": 5616, "speaker": "Darryl Philbin"},
  {"season": 2, "episode": 15, "scene": 25, "line": "I'm fine, I'm fine. I'm fine.", "order": 5617, "speaker": "Michael Scott"},
  {"season": 2, "episode": 15, "scene": 25, "line": "Look, would ya... look.", "order": 5618, "speaker": "Darryl Philbin"},
  {"season": 2, "episode": 15, "scene": 25, "line": "Oh, oh, oh! We'll get somebody to clean that up.", "order": 5619, "speaker": "Michael Scott"},
  {"season": 2, "episode": 15, "scene": 25, "line": "We're the ones that got to clean that up!", "order": 5620, "speaker": "Darryl Philbin"},
  {"season": 2, "episode": 15, "scene": 25, "line": "Dammit, Michael!", "order": 5621, "speaker": "Lonny"},
  {"season": 2, "episode": 15, "scene": 25, "line": "We ought to have this thing serviced.", "order": 5622, "speaker": "Michael Scott"},
  {"season": 2, "episode": 15, "scene": 26, "line": "So! Guy's gripe session. Here we are. Now, we definitely live in different worlds but we have a lot in common. We even like the same girls, some of us. That's going to happen, you know. We're guys, so...", "order": 5623, "speaker": "Michael Scott"},
  {"season": 2, "episode": 15, "scene": 26, "line": "Hey, do you want me to go?", "order": 5624, "speaker": "Madge"},
  {"season": 2, "episode": 15, "scene": 26, "line": "No, why? Why would I... ? You could...", "order": 5625, "speaker": "Michael Scott"},
  {"season": 2, "episode": 15, "scene": 26, "line": "I'll go.", "order": 5626, "speaker": "Madge"},
  {"season": 2, "episode": 15, "scene": 26, "line": "Stay or...", "order": 5627, "speaker": "Michael Scott"},
  {"season": 2, "episode": 15, "scene": 27, "line": "...and a big walk-in closet.", "order": 5628, "speaker": "Phyllis Lapin"},
  {"season": 2, "episode": 15, "scene": 27, "line": "Oh, that's part of my dream too.", "order": 5629, "speaker": "Meredith Palmer"},
  {"season": 2, "episode": 15, "scene": 27, "line": "Oh, me too.", "order": 5630, "speaker": "Kelly Kapoor"},
  {"season": 2, "episode": 15, "scene": 27, "line": "Great, great. And Pam, what about you? What is your dream?", "order": 5631, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 15, "scene": 27, "line": "Well... I always dreamed of a house with a terrace upstairs. Plant flowers on it... stuff like that. Since I was a girl. Um... More seriously though, a husband that I love... Roy. And I love to draw. And I... I did a little in college and I'd still love to do something where I could work with art or graphic design in some way.", "order": 5632, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 15, "scene": 27, "line": "She's real good.", "order": 5633, "speaker": "Phyllis Lapin"},
  {"season": 2, "episode": 15, "scene": 27, "line": "Thanks.", "order": 5634, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 15, "scene": 27, "line": "You know the company is offering a design training program in New York.", "order": 5635, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 15, "scene": 27, "line": "Well... I have a job right now, so I can't really take time off...", "order": 5636, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 15, "scene": 27, "line": "Well, it's only on weekends and then a few weeks in New York, but I'm sure that I could ask Corporate to help you out.", "order": 5637, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 15, "scene": 27, "line": "Well... it's just that the weekends aren't good because, um...", "order": 5638, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 15, "scene": 27, "line": "There are always a million reasons not to do something.", "order": 5639, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 15, "scene": 28, "line": "Let's start with the Warehouse. What bothers you as guys, you know?", "order": 5640, "speaker": "Michael Scott"},
  {"season": 2, "episode": 15, "scene": 28, "line": "My priority is safety.", "order": 5641, "speaker": "Darryl Philbin"},
  {"season": 2, "episode": 15, "scene": 28, "line": "OK.", "order": 5642, "speaker": "Michael Scott"},
  {"season": 2, "episode": 15, "scene": 28, "line": "So it really bothers me when somebody comes in here speeding around on a lift, playing with it like a toy. It kind of gets under my skin.", "order": 5643, "speaker": "Darryl Philbin"},
  {"season": 2, "episode": 15, "scene": 28, "line": "OK, Yeah. Yeah. Yeah shhh...", "order": 5644, "speaker": "Michael Scott"},
  {"season": 2, "episode": 15, "scene": 28, "line": "Uh uh uh. Don't shush me.", "order": 5645, "speaker": "Darryl Philbin"},
  {"season": 2, "episode": 15, "scene": 28, "line": "I... That was just...", "order": 5646, "speaker": "Michael Scott"},
  {"season": 2, "episode": 15, "scene": 28, "line": "That bothers me too.", "order": 5647, "speaker": "Darryl Philbin"},
  {"season": 2, "episode": 15, "scene": 28, "line": "I was breathing.", "order": 5648, "speaker": "Michael Scott"},
  {"season": 2, "episode": 15, "scene": 28, "line": "Pam shushes me. It drives me crazy.", "order": 5649, "speaker": "Roy Anderson"},
  {"season": 2, "episode": 15, "scene": 28, "line": "I hate shushing. You know, that's the thing! What the... ok... what is our beef as human men.", "order": 5650, "speaker": "Michael Scott"},
  {"season": 2, "episode": 15, "scene": 28, "line": "You know that's a good question, Hasselhoff. What bugs us?", "order": 5651, "speaker": "Lonny"},
  {"season": 2, "episode": 15, "scene": 28, "line": "OK. Alright. Good. Guys ragging on each other. That's what guys do... and we love it.", "order": 5652, "speaker": "Michael Scott"},
  {"season": 2, "episode": 15, "scene": 29, "line": "All right. Let's talk about clothing.", "order": 5653, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 15, "scene": 30, "line": "I'm excited about today. [whispers] I love girl talk.", "order": 5654, "speaker": "Phyllis Lapin"},
  {"season": 2, "episode": 15, "scene": 31, "line": "Dress for the job you want, not the job you have. All right. You can use your clothing to send a message about your ambitions by wearing clothes that reflect what you aspire to be.", "order": 5655, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 15, "scene": 32, "line": "I'm not gaining anything from this seminar. I'm a professional woman. The head of accounting. I'm in the healthiest relationship of my life. I just think it's insulting that Jan thinks we need this. And, apparently, judging from her outfit, Jan aspires to be a whore.", "order": 5656, "speaker": "Angela Martin"},
  {"season": 2, "episode": 15, "scene": 33, "line": "I hate it when girls insist on taking them out to new restaurants every weekend night and then they're like 'When are we going to go on a date-date?'", "order": 5657, "speaker": "Roy Anderson"},
  {"season": 2, "episode": 15, "scene": 33, "line": "I hate that too! [general clapping and agreement]", "order": 5658, "speaker": "Guy"},
  {"season": 2, "episode": 15, "scene": 33, "line": "I hate that too.", "order": 5659, "speaker": "Darryl Philbin"},
  {"season": 2, "episode": 15, "scene": 33, "line": "That sucks so much.", "order": 5660, "speaker": "Kevin Malone"},
  {"season": 2, "episode": 15, "scene": 33, "line": "It totally sucks.", "order": 5661, "speaker": "Guy"},
  {"season": 2, "episode": 15, "scene": 33, "line": "Yeah and then they make you drive them to Church the next morning. Like 'Gas ain't free!'", "order": 5662, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 15, "scene": 33, "line": "Yeah, on our salaries, man, what do they expect? You know to take us out every weekend? You know what I mean? We're not millionaires.", "order": 5663, "speaker": "Lonny"},
  {"season": 2, "episode": 15, "scene": 33, "line": "I feel you.", "order": 5664, "speaker": "Michael Scott"},
  {"season": 2, "episode": 15, "scene": 33, "line": "No, you don't. You don't feel us. How can you? You know what?", "order": 5665, "speaker": "Darryl Philbin"},
  {"season": 2, "episode": 15, "scene": 33, "line": "Not literally.", "order": 5666, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 15, "scene": 33, "line": "You say we're the same, but we get compensated very differently.", "order": 5667, "speaker": "Darryl Philbin"},
  {"season": 2, "episode": 15, "scene": 33, "line": "Yes.", "order": 5668, "speaker": "Michael Scott"},
  {"season": 2, "episode": 15, "scene": 33, "line": "We work the same hours as you and you just said we work a lot harder", "order": 5669, "speaker": "Darryl Philbin"},
  {"season": 2, "episode": 15, "scene": 33, "line": "Ah, you do. So...", "order": 5670, "speaker": "Michael Scott"},
  {"season": 2, "episode": 15, "scene": 33, "line": "But we get paid a lot less.", "order": 5671, "speaker": "Darryl Philbin"},
  {"season": 2, "episode": 15, "scene": 33, "line": "Word.", "order": 5672, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 15, "scene": 33, "line": "Like next to no benefits.", "order": 5673, "speaker": "Roy Anderson"},
  {"season": 2, "episode": 15, "scene": 33, "line": "I know. God! What is that?", "order": 5674, "speaker": "Michael Scott"},
  {"season": 2, "episode": 15, "scene": 33, "line": "Exactly.", "order": 5675, "speaker": "Roy Anderson"},
  {"season": 2, "episode": 15, "scene": 33, "line": "It blows. It blows, man. Gah...", "order": 5676, "speaker": "Michael Scott"},
  {"season": 2, "episode": 15, "scene": 33, "line": "You know this would not happen if we had a union.", "order": 5677, "speaker": "Darryl Philbin"},
  {"season": 2, "episode": 15, "scene": 33, "line": "That's what I'm talking about.", "order": 5678, "speaker": "Roy Anderson"},
  {"season": 2, "episode": 15, "scene": 33, "line": "No. Whoa, whoa. Yeah.", "order": 5679, "speaker": "Michael Scott"},
  {"season": 2, "episode": 15, "scene": 33, "line": "Absolutely.", "order": 5680, "speaker": "Roy Anderson"},
  {"season": 2, "episode": 15, "scene": 33, "line": "That's what we need.", "order": 5681, "speaker": "Darryl Philbin"},
  {"season": 2, "episode": 15, "scene": 33, "line": "You know you're right.", "order": 5682, "speaker": "Guy"},
  {"season": 2, "episode": 15, "scene": 33, "line": "Man, see... That's what I've been sayin', man. We need to do this finally.", "order": 5683, "speaker": "Darryl Philbin"},
  {"season": 2, "episode": 15, "scene": 33, "line": "You know what? Is that necessary? Because you already sorta have a union... of guys.", "order": 5684, "speaker": "Michael Scott"},
  {"season": 2, "episode": 15, "scene": 33, "line": "It's more than necessary, Mike. We need this. Roy? You still have that card from the Dockworker's Union?", "order": 5685, "speaker": "Darryl Philbin"},
  {"season": 2, "episode": 15, "scene": 33, "line": "In my truck.", "order": 5686, "speaker": "Roy Anderson"},
  {"season": 2, "episode": 15, "scene": 33, "line": "Dockworker's?", "order": 5687, "speaker": "Michael Scott"},
  {"season": 2, "episode": 15, "scene": 33, "line": "Man, hook you up.", "order": 5688, "speaker": "Darryl Philbin"},
  {"season": 2, "episode": 15, "scene": 33, "line": "[generalized clapping]", "order": 5689, "speaker": "Men"},
  {"season": 2, "episode": 15, "scene": 33, "line": "Come on, man.", "order": 5690, "speaker": "Guy"},
  {"season": 2, "episode": 15, "scene": 33, "line": "Yeah. You know what? I think the problem is the chicks.", "order": 5691, "speaker": "Michael Scott"},
  {"season": 2, "episode": 15, "scene": 33, "line": "Union! Union, yeah.", "order": 5692, "speaker": "Darryl Philbin"},
  {"season": 2, "episode": 15, "scene": 33, "line": "The problem is the chicks. And you gotta blame them.", "order": 5693, "speaker": "Michael Scott"},
  {"season": 2, "episode": 15, "scene": 33, "line": "Are you with us Mike?", "order": 5694, "speaker": "Darryl Philbin"},
  {"season": 2, "episode": 15, "scene": 33, "line": "Yeah-es.", "order": 5695, "speaker": "Michael Scott"},
  {"season": 2, "episode": 15, "scene": 33, "line": "Welcome to the warehouse.", "order": 5696, "speaker": "Darryl Philbin"},
  {"season": 2, "episode": 15, "scene": 33, "line": "Michael, Michael, Michael, Mi...", "order": 5697, "speaker": "Group chant"},
  {"season": 2, "episode": 15, "scene": 34, "line": "Another issue is inequality of pay between men and women. I'm sure that all of you have felt that before...", "order": 5698, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 15, "scene": 34, "line": "[knocks] This is important. Ladies, take a breather. Jan, I uh wanna... Can I help you? Um... I wanted to say that the guys downstairs are thinking about forming a union. And they have some good points...", "order": 5699, "speaker": "Michael Scott"},
  {"season": 2, "episode": 15, "scene": 34, "line": "What? A union! What...", "order": 5700, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 15, "scene": 34, "line": "Don't get hysterical.", "order": 5701, "speaker": "Michael Scott"},
  {"season": 2, "episode": 15, "scene": 34, "line": "I'm not...", "order": 5702, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 15, "scene": 35, "line": "Part of my job is knowing how to talk to women.", "order": 5703, "speaker": "Michael Scott"},
  {"season": 2, "episode": 15, "scene": 36, "line": "Let's... be... rational... here. What are the pros? What are the cons?", "order": 5704, "speaker": "Michael Scott"},
  {"season": 2, "episode": 15, "scene": 36, "line": "The cons are that everyone will lose their job. Michael. Everyone. Office, Warehouse. What do you think... the... pros... are... here?", "order": 5705, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 15, "scene": 36, "line": "Don't talk to me that way please. Just... they're going to want to hear this from you.", "order": 5706, "speaker": "Michael Scott"},
  {"season": 2, "episode": 15, "scene": 36, "line": "You got yourself into this Michael, so you get yourself out.", "order": 5707, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 15, "scene": 36, "line": "But we're bonding down there!", "order": 5708, "speaker": "Michael Scott"},
  {"season": 2, "episode": 15, "scene": 36, "line": "That's too bad.", "order": 5709, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 15, "scene": 36, "line": "I mean I just don't want to have to tell them something they're not going to want to hear.", "order": 5710, "speaker": "Michael Scott"},
  {"season": 2, "episode": 15, "scene": 36, "line": "I don't want to...", "order": 5711, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 15, "scene": 36, "line": "Ok. Come on Jan. After all we've been through...", "order": 5712, "speaker": "Michael Scott"},
  {"season": 2, "episode": 15, "scene": 36, "line": "Michael! Michael! Michael!", "order": 5713, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 15, "scene": 36, "line": "We have a history...", "order": 5714, "speaker": "Michael Scott"},
  {"season": 2, "episode": 15, "scene": 36, "line": "Michael.", "order": 5715, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 15, "scene": 36, "line": "...between us.", "order": 5716, "speaker": "Michael Scott"},
  {"season": 2, "episode": 15, "scene": 36, "line": "Don't say another word.", "order": 5717, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 15, "scene": 36, "line": "I won't", "order": 5718, "speaker": "Michael Scott"},
  {"season": 2, "episode": 15, "scene": 36, "line": "Get yourself down stairs.", "order": 5719, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 15, "scene": 36, "line": "I'm just saying we have something... Ok. Whatever.", "order": 5720, "speaker": "Michael Scott"},
  {"season": 2, "episode": 15, "scene": 37, "line": "You know what... we could get this done a lot quicker if we formed a type of assembly line.", "order": 5721, "speaker": "Ryan Howard"},
  {"season": 2, "episode": 15, "scene": 37, "line": "This here is a run-out-the-clock situation. Just like upstairs.", "order": 5722, "speaker": "Stanley Hudson"},
  {"season": 2, "episode": 15, "scene": 38, "line": "Sports metaphors are one of the ways women feel left out of the language of the office. Now, I know this might sound silly but a ... many women ask to go over it. So... Fumble means...", "order": 5723, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 15, "scene": 38, "line": "Mistake.", "order": 5724, "speaker": "Phyllis Lapin"},
  {"season": 2, "episode": 15, "scene": 38, "line": "Slip.", "order": 5725, "speaker": "Meredith Palmer"},
  {"season": 2, "episode": 15, "scene": 38, "line": "Right. Par for the course is a golf term. It means right on track. Below par means worse. Wait... that should mean better, that doesn't make sense.", "order": 5726, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 15, "scene": 38, "line": "What about second base? Like if Michael said that he got to second base with you? Does that mean you like closed a deal?", "order": 5727, "speaker": "Kelly Kapoor"},
  {"season": 2, "episode": 15, "scene": 38, "line": "Excuse me?", "order": 5728, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 15, "scene": 38, "line": "I mean that's a baseball term, right?", "order": 5729, "speaker": "Kelly Kapoor"},
  {"season": 2, "episode": 15, "scene": 38, "line": "I don't know what Michael was talking about. I don't know.", "order": 5730, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 15, "scene": 39, "line": "[in the background] ...and you went to Chili's and he got to second base with you.", "order": 5731, "speaker": "Kelly Kapoor"},
  {"season": 2, "episode": 15, "scene": 39, "line": "[in the background] Kelly, I don't know what Michael's talking about.", "order": 5732, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 15, "scene": 39, "line": "[in the background] He told everybody so I just want to know is that a baseball term...", "order": 5733, "speaker": "Kelly Kapoor"},
  {"season": 2, "episode": 15, "scene": 39, "line": "Hey.", "order": 5734, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 15, "scene": 39, "line": "Hey!", "order": 5735, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 15, "scene": 39, "line": "How's it going down there?", "order": 5736, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 15, "scene": 39, "line": "It's a complete... well, actually it's exactly what you'd expect, so... How are the girls?", "order": 5737, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 15, "scene": 39, "line": "Good. We watched a video about our changing bodies.", "order": 5738, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 15, "scene": 39, "line": "Did you really?", "order": 5739, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 15, "scene": 39, "line": "No. [laughs]", "order": 5740, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 15, "scene": 39, "line": "Oh.", "order": 5741, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 15, "scene": 39, "line": "Almost.", "order": 5742, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 15, "scene": 39, "line": "Good.", "order": 5743, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 15, "scene": 39, "line": "Um... but hey? Something kind of cool. There's this internship in graphic design that Jan was telling us about. She made it sound, like, really great.", "order": 5744, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 15, "scene": 39, "line": "Nice. Well, what's it all about?", "order": 5745, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 15, "scene": 39, "line": "Um...", "order": 5746, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 15, "scene": 39, "line": "I think you should do it. That's great!", "order": 5747, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 15, "scene": 39, "line": "It's really cool.", "order": 5748, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 15, "scene": 40, "line": "Cold front coming into the Warehouse. Uh oh! Better put on your ski boots! Woohoohoowoo. Waaaah! Happy New Year, Darryl! Hey,Darryl. You ever done this?", "order": 5749, "speaker": "Michael Scott"},
  {"season": 2, "episode": 15, "scene": 41, "line": "Are you married?", "order": 5750, "speaker": "Angela Martin"},
  {"season": 2, "episode": 15, "scene": 41, "line": "I'm divorced.", "order": 5751, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 15, "scene": 41, "line": "That must have been hard.", "order": 5752, "speaker": "Phyllis Lapin"},
  {"season": 2, "episode": 15, "scene": 41, "line": "It was. Yes.", "order": 5753, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 15, "scene": 41, "line": "You were probably feeling really depressed and sad and that's why you did that thing with Michael.", "order": 5754, "speaker": "Kelly Kapoor"},
  {"season": 2, "episode": 15, "scene": 41, "line": "I think you should all spend a little more time thinking about your careers and less time on personal stuff.", "order": 5755, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 15, "scene": 41, "line": "Mmmm, I think we're all okay with the balance we've struck.", "order": 5756, "speaker": "Phyllis Lapin"},
  {"season": 2, "episode": 15, "scene": 41, "line": "At least you don't have kids. You have no kids, right? Thank God.", "order": 5757, "speaker": "Angela Martin"},
  {"season": 2, "episode": 15, "scene": 41, "line": "Okay. Let's take five. I think we can all use five.", "order": 5758, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 15, "scene": 41, "line": "How can someone so beautiful be so sad?", "order": 5759, "speaker": "Kelly Kapoor"},
  {"season": 2, "episode": 15, "scene": 42, "line": "Hi.", "order": 5760, "speaker": "Michael Scott"},
  {"season": 2, "episode": 15, "scene": 42, "line": "Did you take care of the situation?", "order": 5761, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 15, "scene": 42, "line": "Yuh, yuh, yes! I... I have essentially...", "order": 5762, "speaker": "Michael Scott"},
  {"season": 2, "episode": 15, "scene": 42, "line": "Excuse me.", "order": 5763, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 15, "scene": 42, "line": "I have essentially. Yes. I've taken some...", "order": 5764, "speaker": "Michael Scott"},
  {"season": 2, "episode": 15, "scene": 42, "line": "Excuse me. I've been told there's been some interest in forming a Union and that Michael supported it. Obviously he's not a friend of yours because he didn't tell you the facts. So let me. If there is even a whiff of unionizing in this branch, I can guarantee you the branch will be shut down like that [snaps her fingers]. They unionized in Pittsfield and we all know what happened in Pittsfield. It will cost each of you a fortune in legal fees and union dues and that will be nothing compared to the cost of losing your jobs. So I would think long and hard before sacrificing your savings and your futures just to send a message. If you have any further questions you can direct them to... to Michael.", "order": 5765, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 15, "scene": 43, "line": "Dreams are just that. They're dreams. They help get you through the day. Like the thing about the terrace. It's nice but... um... I don't know. It was just something I read in this book when I was twelve. The girl in the book has a terrace outside of her bedroom and she planted flowers on it and I just loved that. Just always kind of stuck with me.", "order": 5766, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 15, "scene": 44, "line": "So you're not doing it.", "order": 5767, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 15, "scene": 44, "line": "How did you know?", "order": 5768, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 15, "scene": 44, "line": "Why not?", "order": 5769, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 15, "scene": 44, "line": "Just like no big reason. Just a bunch of little reasons.", "order": 5770, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 15, "scene": 44, "line": "Come on.", "order": 5771, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 15, "scene": 44, "line": "Roy's right. There's no guarantee it's going to lead to anything anyway.", "order": 5772, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 15, "scene": 44, "line": "Roy said that.", "order": 5773, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 15, "scene": 44, "line": "What? You have something you want to say?", "order": 5774, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 15, "scene": 44, "line": "You got to take a chance on something sometime, Pam. I mean, do you want to be a receptionist here, always?", "order": 5775, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 15, "scene": 44, "line": "Oh, excuse me! I'm fine with my choices!", "order": 5776, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 15, "scene": 44, "line": "You are?", "order": 5777, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 15, "scene": 44, "line": "Yeah.", "order": 5778, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 15, "scene": 45, "line": "It's impractical. I'm not going to try to get a house like that. Um... they don't even make houses like that in Scranton. So, I'm never going to... .", "order": 5779, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 15, "scene": 46, "line": "I'm just going to put this over there.", "order": 5780, "speaker": "Michael Scott"},
  {"season": 2, "episode": 15, "scene": 46, "line": "This is not a good idea right here.", "order": 5781, "speaker": "Darryl Philbin"},
  {"season": 2, "episode": 15, "scene": 46, "line": "You did uh... okay.", "order": 5782, "speaker": "Michael Scott"},
  {"season": 2, "episode": 15, "scene": 47, "line": "Pizza. Great equalizer. Rich people love pizza. Poor people love pizza. White people love pizza. Black people love pizza. Do black people like pizza?", "order": 5783, "speaker": "Michael Scott"},
  {"season": 2, "episode": 15, "scene": 48, "line": "Hey. Um... look guys, I'm sorry. Sometimes Jan can be such a bitch.", "order": 5784, "speaker": "Michael Scott"},
  {"season": 2, "episode": 15, "scene": 48, "line": "Generalized mumbling agreement. Yeah.", "order": 5785, "speaker": "All the Men"},
  {"season": 2, "episode": 15, "scene": 48, "line": "Hey, watch it, watch it. We have a relationship.", "order": 5786, "speaker": "Michael Scott"},
  {"season": 2, "episode": 15, "scene": 49, "line": "Thank you to our hosts.", "order": 5787, "speaker": "Michael Scott"},
  {"season": 2, "episode": 15, "scene": 49, "line": "Hey Michael. This ain't over.", "order": 5788, "speaker": "Darryl Philbin"},
  {"season": 2, "episode": 15, "scene": 50, "line": "Ahhh! Excellent.", "order": 5789, "speaker": "Michael Scott"},
  {"season": 2, "episode": 15, "scene": 51, "line": "Is it good to be back. Yeah. I mean I love the guy stuff but to run an office you need men and women. You know why? Because you need to have that crazy sexual tension to keep things interesting.", "order": 5790, "speaker": "Michael Scott"},
  {"season": 2, "episode": 15, "scene": 52, "line": "Dunder Mifflin. This is Pam. Uh... hold, please.", "order": 5791, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 16, "scene": 1, "line": "I really like Valentine's Day in this office. It's kinda like grade school. Everybody gives out little presents and stuff. Like last year, Jim gave me this card, with Dwight's head on it, it was horrifying and funny and...", "order": 5792, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 16, "scene": 2, "line": "[Delivery man enters with a bouquet of red roses. Pam stands up to look at card.] Phyllis.", "order": 5793, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 16, "scene": 2, "line": "Would you sign here? [Phyllis gets up from desk and walks over.]", "order": 5794, "speaker": "Delivery man"},
  {"season": 2, "episode": 16, "scene": 3, "line": "Roy and I are saving for the wedding, so I made him promise not to get me anything too big.", "order": 5795, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 16, "scene": 4, "line": "Happy Valentine's Day darling. Love Bob Vance, Vance Refrigeration.'", "order": 5796, "speaker": "Meredith Palmer"},
  {"season": 2, "episode": 16, "scene": 4, "line": "Isn't he sweet?", "order": 5797, "speaker": "Phyllis Lapin"},
  {"season": 2, "episode": 16, "scene": 4, "line": "Yeah. Wow.", "order": 5798, "speaker": "Meredith Palmer"},
  {"season": 2, "episode": 16, "scene": 5, "line": "Alright Dwight, as you know I am heading to New York today. [Dwight holds up passport.] Doing a presentation on the branch to the new CFO.", "order": 5799, "speaker": "Michael Scott"},
  {"season": 2, "episode": 16, "scene": 5, "line": "And you want me to come with you.", "order": 5800, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 16, "scene": 5, "line": "Nope. The opposite of that.", "order": 5801, "speaker": "Michael Scott"},
  {"season": 2, "episode": 16, "scene": 5, "line": "I will stay here and run things on this end.", "order": 5802, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 16, "scene": 5, "line": "Ok, good.", "order": 5803, "speaker": "Michael Scott"},
  {"season": 2, "episode": 16, "scene": 5, "line": "Question. Will you be seeing Jan when you're in New York?", "order": 5804, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 16, "scene": 5, "line": "I probably will, why do you ask?", "order": 5805, "speaker": "Michael Scott"},
  {"season": 2, "episode": 16, "scene": 5, "line": "Well... It's Valentine's Day, and you guys, you know...", "order": 5806, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 16, "scene": 5, "line": "Yeah.", "order": 5807, "speaker": "Michael Scott"},
  {"season": 2, "episode": 16, "scene": 5, "line": "Screwed.", "order": 5808, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 16, "scene": 5, "line": "What", "order": 5809, "speaker": "Michael Scott"},
  {"season": 2, "episode": 16, "scene": 6, "line": "This is a business trip. I would have to be a raving lunatic to try to talk to Jan about what happened between us. Her words, not mine. She sent me an email this morning. But, it is Valentine's Day. It's New York. City of Love.", "order": 5810, "speaker": "Michael Scott"},
  {"season": 2, "episode": 16, "scene": 7, "line": "Hey, Pam. You heart N.Y., right? You want me to pick you up anything?", "order": 5811, "speaker": "Michael Scott"},
  {"season": 2, "episode": 16, "scene": 7, "line": "That's OK.", "order": 5812, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 16, "scene": 7, "line": "Alright.", "order": 5813, "speaker": "Michael Scott"},
  {"season": 2, "episode": 16, "scene": 7, "line": "The best present would be, you do a good job in front of the new CFO.", "order": 5814, "speaker": "Oscar Martinez"},
  {"season": 2, "episode": 16, "scene": 7, "line": "Dude, I'm gonna nail it. Me in New York? Oh, I own that city. Fuggedaboudit! See ya!", "order": 5815, "speaker": "Michael Scott"},
  {"season": 2, "episode": 16, "scene": 8, "line": "Well here we go. On our way to New York. New York, New York. City so nice they named it twice. Manhattan is the other name.", "order": 5816, "speaker": "Michael Scott"},
  {"season": 2, "episode": 16, "scene": 9, "line": "So I broke up with Katy and haven't been dating anybody else, so this year I don't have to worry about Valentine's Day. It's gonna be good. I invited a couple of friends over. We're gonna play some cards and I'll end up winning a lotta money. Because, they're idiots. It's gonna be great.", "order": 5817, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 16, "scene": 10, "line": "What's this? What is this?", "order": 5818, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 16, "scene": 10, "line": "I dunno, it's on", "order": 5819, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 16, "scene": 10, "line": "Yeah, but who put it here? And for what purpose?", "order": 5820, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 16, "scene": 10, "line": "It was there when I sat down.", "order": 5821, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 16, "scene": 10, "line": "[opens box and reads card] Happy Valentine's Day. [pulls out bobble head] It's me. I'm the bobble head. Yes! [Angela smirks in background] Ahh!", "order": 5822, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 16, "scene": 11, "line": "The meeting isn't 'til three, but I always like to come to New York little bit early and hit some of my favorite hunts, like right here, is my favorite New York pizza joint. And I'm gonna go get me a New York slice. [Michael walks toward Sbarro.]", "order": 5823, "speaker": "Michael Scott"},
  {"season": 2, "episode": 16, "scene": 12, "line": "Hey Kelly. What's up?", "order": 5824, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 16, "scene": 12, "line": "Nothing. Oh except, oh my God Jim. Last night, Ryan and I totally, finally hooked up. It was awesome.", "order": 5825, "speaker": "Kelly Kapoor"},
  {"season": 2, "episode": 16, "scene": 12, "line": "OH, that's great. I'm really happy for [starts to walk away]", "order": 5826, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 16, "scene": 12, "line": "And it was so funny 'cause we were at this bar with his friends and I was sitting next to him the whole night and he wasn't making a move, so in my head I was like 'Ryan, what's taking you so long?' And then he kissed me. And I didn't know what to say.", "order": 5827, "speaker": "Kelly Kapoor"},
  {"season": 2, "episode": 16, "scene": 12, "line": "Wow.", "order": 5828, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 16, "scene": 12, "line": "So I said, 'Ryan, what took you so long?' And I just said that to him, can you believe that?", "order": 5829, "speaker": "Kelly Kapoor"},
  {"season": 2, "episode": 16, "scene": 12, "line": "Wow.", "order": 5830, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 16, "scene": 12, "line": "Oh my God, Jim, is that embarrassing? I'm embarrassed.", "order": 5831, "speaker": "Kelly Kapoor"},
  {"season": 2, "episode": 16, "scene": 12, "line": "No, don't be.", "order": 5832, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 16, "scene": 12, "line": "Oh, thank God, because I was nervous, Jim, you will not believe.", "order": 5833, "speaker": "Kelly Kapoor"},
  {"season": 2, "episode": 16, "scene": 12, "line": "I bet.", "order": 5834, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 16, "scene": 12, "line": "So nervous, but now -- now I have a boyfriend.", "order": 5835, "speaker": "Kelly Kapoor"},
  {"season": 2, "episode": 16, "scene": 12, "line": "Alright. [Kelly squeals]", "order": 5836, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 16, "scene": 13, "line": "[anguished] I hooked up with her on February 13th.", "order": 5837, "speaker": "Ryan Howard"},
  {"season": 2, "episode": 16, "scene": 14, "line": "Here it is, heart of New York City, Times Square. Named for the good times you have when you're in it. Most people when they come to New York, they go straight to the Empire State Building, that's pretty touristy. I come here. Great places to eat. [points] We have Bubba Gump Shrimp, Red Lobster down there. Ya know. This is, this is the heart of civilization, right here.", "order": 5838, "speaker": "Michael Scott"},
  {"season": 2, "episode": 16, "scene": 15, "line": "Woah. [Delivery man with flowers]", "order": 5839, "speaker": "Kevin Malone"},
  {"season": 2, "episode": 16, "scene": 15, "line": "Guess what?", "order": 5840, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 16, "scene": 15, "line": "Really, Oh, they're from Bob again.", "order": 5841, "speaker": "Phyllis Lapin"},
  {"season": 2, "episode": 16, "scene": 15, "line": "That's great. [Meredith scowls]", "order": 5842, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 16, "scene": 16, "line": "Everybody takes the subway in New York. It's fast, it's efficient, gets you there on time. It's a way to [turns and rushes back up stairs] Okay, there's a guy pooping in a cardboard box down there.", "order": 5843, "speaker": "Michael Scott"},
  {"season": 2, "episode": 16, "scene": 17, "line": "This is the world famous Rockefeller Center. Founded, of course by Theodore Rockefeller. This is a skating rink and I think the Rangers practice there sometimes and it's, that's Tina Fey [points]. That's Tina Fey from Saturday Night Live. Hello? Hello, hi? [walks over] OH, I'm sorry, I thought you were [Conan O'Brien walks in front of Michael], OK, I thought that was. She, she looked a lot like Tina Fey. [to camera] Hello, hello, I thought that was Tina Fey, but it wasn't. So... Are you serious? He was here? When, when I was talking to the fake Tina Fey? Come on! And are you, argh.", "order": 5844, "speaker": "Michael Scott"},
  {"season": 2, "episode": 16, "scene": 18, "line": "Hello Angela. Did you hear, somebody rocked the house and got me the best present I've ever gotten.", "order": 5845, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 16, "scene": 18, "line": "Really? I wouldn't know anything about that, but I'm glad you enjoyed it.", "order": 5846, "speaker": "Angela Martin"},
  {"season": 2, "episode": 16, "scene": 18, "line": "Oh I did. I did.", "order": 5847, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 16, "scene": 18, "line": "I didn't get anything for Valentine's Day.", "order": 5848, "speaker": "Angela Martin"},
  {"season": 2, "episode": 16, "scene": 18, "line": "Oh, I bet you will before the day is over.", "order": 5849, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 16, "scene": 18, "line": "Really? Well, I hope I do.", "order": 5850, "speaker": "Angela Martin"},
  {"season": 2, "episode": 16, "scene": 19, "line": "I would love to live in New York someday. It's a big dream of mine. Work for corporate, with Jan. It'd be awesome. Go to Broadway shows, eat hot dogs. Scranton is great, but New York, is like Scranton on acid, no on speed, no on steroids. [Michael sees it's the end of a street.] OK, umm, I think, that's either the Hudson or the East, so we're back, should be back this way. There's a lotta pressure on me right now. It's like Michael Jordan, in the NBA finals. Or, like Stormin' Norman Schwarzkopf, and this presentation is desert storm and as soon as it's over, we will not have to deal with those Iraqis anymore. Let's do it.", "order": 5851, "speaker": "Michael Scott"},
  {"season": 2, "episode": 16, "scene": 20, "line": "[on phone] Nah that's alright. Spend money on her, instead of giving it to us. That's fine. No, I didn't even have a seat for you anyway. Yeah, hahaha, alright man, have a good night. Bye.", "order": 5852, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 16, "scene": 21, "line": "Woah, woah [Delivery man with oversized bear]", "order": 5853, "speaker": "Kevin Malone"},
  {"season": 2, "episode": 16, "scene": 21, "line": "Phyllis Lapin.", "order": 5854, "speaker": "Delivery man"},
  {"season": 2, "episode": 16, "scene": 21, "line": "OH, Holy God!", "order": 5855, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 16, "scene": 21, "line": "It's from Bob.", "order": 5856, "speaker": "Delivery man"},
  {"season": 2, "episode": 16, "scene": 21, "line": "Man, that thing's bigger than I am.", "order": 5857, "speaker": "Kevin Malone"},
  {"season": 2, "episode": 16, "scene": 21, "line": "No, it's not.", "order": 5858, "speaker": "Delivery man"},
  {"season": 2, "episode": 16, "scene": 21, "line": "Oh zip it.", "order": 5859, "speaker": "Kevin Malone"},
  {"season": 2, "episode": 16, "scene": 22, "line": "There they are. What's up? Hey hey.", "order": 5860, "speaker": "Michael Scott"},
  {"season": 2, "episode": 16, "scene": 22, "line": "Hey.", "order": 5861, "speaker": "Craig"},
  {"season": 2, "episode": 16, "scene": 22, "line": "Michael Scott. [sticks out hand for handshake]", "order": 5862, "speaker": "Josh Porter"},
  {"season": 2, "episode": 16, "scene": 22, "line": "Josh Porter, high five. [They high five] Bam.", "order": 5863, "speaker": "Michael Scott"},
  {"season": 2, "episode": 16, "scene": 22, "line": "You know Dan Gore from Buffalo.", "order": 5864, "speaker": "Josh Porter"},
  {"season": 2, "episode": 16, "scene": 22, "line": "Yeah, how ya doing? Nobody needs to introduce this guy. Craiggers. [bump fists]", "order": 5865, "speaker": "Michael Scott"},
  {"season": 2, "episode": 16, "scene": 22, "line": "What's up buddy?", "order": 5866, "speaker": "Craig"},
  {"season": 2, "episode": 16, "scene": 22, "line": "You have been kicked out of every strip club in Albany, is that true?", "order": 5867, "speaker": "Michael Scott"},
  {"season": 2, "episode": 16, "scene": 22, "line": "Guilty, yeah.", "order": 5868, "speaker": "Craig"},
  {"season": 2, "episode": 16, "scene": 22, "line": "So what's going on? What I miss?", "order": 5869, "speaker": "Michael Scott"},
  {"season": 2, "episode": 16, "scene": 22, "line": "Not much, they're uh, I guess running late upstairs, so we're just waiting for the presentations.", "order": 5870, "speaker": "Josh Porter"},
  {"season": 2, "episode": 16, "scene": 22, "line": "Cool. Good, good, good. Give us some time to catch up, and... [awkward silence]", "order": 5871, "speaker": "Michael Scott"},
  {"season": 2, "episode": 16, "scene": 23, "line": "Pam. Hi, How ya doing? Good. Listen, uh may I speak with you... privately?", "order": 5872, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 16, "scene": 23, "line": "You can't fire me, Dwight, just 'cause Michael's not here.", "order": 5873, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 16, "scene": 23, "line": "No, Pam, Just. Just, [tilts head away, towards another room]", "order": 5874, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 16, "scene": 24, "line": "You need to get something for your girlfriend.", "order": 5875, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 16, "scene": 24, "line": "[same time as Pam] Girlfriend. Yes, and the reason I didn't get anything for this particular person - who shall remain nameless - is that she's not really the kind of person you'd think would be into Valentine's Day. She's kind of...", "order": 5876, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 16, "scene": 24, "line": "Tightly wound?", "order": 5877, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 16, "scene": 24, "line": "(smirking) Exactly.", "order": 5878, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 16, "scene": 24, "line": "Ok, well, sometimes the gift is really about the gesture, you know, like what it means, instead of what it is.", "order": 5879, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 16, "scene": 24, "line": "You mean, like a ham?", "order": 5880, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 16, "scene": 24, "line": "No, not like a ham. It's about doing something, so that the person knows that you really care about her.", "order": 5881, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 16, "scene": 24, "line": "Ok, I get it.", "order": 5882, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 16, "scene": 24, "line": "That you remember her.", "order": 5883, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 16, "scene": 24, "line": "Ok, shut up. I know exactly what to do. [gets up and leaves]", "order": 5884, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 16, "scene": 25, "line": "What about you, Craig, you lose anyone?", "order": 5885, "speaker": "Josh Porter"},
  {"season": 2, "episode": 16, "scene": 25, "line": "Oh man, Jan, called me in September and said 'You gotta fire four people,' and I was just like, 'What?' Ya know?", "order": 5886, "speaker": "Craig"},
  {"season": 2, "episode": 16, "scene": 25, "line": "Did you?", "order": 5887, "speaker": "Josh Porter"},
  {"season": 2, "episode": 16, "scene": 25, "line": "No, I just ignored her. She's the worse.", "order": 5888, "speaker": "Craig"},
  {"season": 2, "episode": 16, "scene": 25, "line": "She is our boss.", "order": 5889, "speaker": "Josh Porter"},
  {"season": 2, "episode": 16, "scene": 25, "line": "She ain't my boss dude. I don't work for that bitch.", "order": 5890, "speaker": "Craig"},
  {"season": 2, "episode": 16, "scene": 25, "line": "Ay, Kay. Come on, you know, that's not. Cool it.", "order": 5891, "speaker": "Michael Scott"},
  {"season": 2, "episode": 16, "scene": 25, "line": "What? You like Jan? How can you", "order": 5892, "speaker": "Craig"},
  {"season": 2, "episode": 16, "scene": 25, "line": "Maybe because she's my girlfriend. [starts retracting statement] Was, or not my girlfriend. She's... we hooked up and...", "order": 5893, "speaker": "Michael Scott"},
  {"season": 2, "episode": 16, "scene": 25, "line": "You hooked up with Jan?", "order": 5894, "speaker": "Josh Porter"},
  {"season": 2, "episode": 16, "scene": 25, "line": "You know, months ago, just once, It's, just stupid. Just forget it,", "order": 5895, "speaker": "Michael Scott"},
  {"season": 2, "episode": 16, "scene": 25, "line": "Yeah, let's change the subject.", "order": 5896, "speaker": "Josh Porter"},
  {"season": 2, "episode": 16, "scene": 25, "line": "Yeah, yeah.", "order": 5897, "speaker": "Michael Scott"},
  {"season": 2, "episode": 16, "scene": 26, "line": "I don't know what he's thinking, but I would just be so psyched if we just dated forever.", "order": 5898, "speaker": "Kelly Kapoor"},
  {"season": 2, "episode": 16, "scene": 26, "line": "Take it slow. 'Cause it seems like a lot of the time things like that need... [Ryan walks in]", "order": 5899, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 16, "scene": 26, "line": "Soda.", "order": 5900, "speaker": "Ryan Howard"},
  {"season": 2, "episode": 16, "scene": 26, "line": "[to Ryan] Cool. Hey, so... do you want to... do something tonight? Or...", "order": 5901, "speaker": "Kelly Kapoor"},
  {"season": 2, "episode": 16, "scene": 26, "line": "[under breath] Oh, no, not while I'm here.", "order": 5902, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 16, "scene": 26, "line": "I mean, I know it's Valentine's Day, or whatever, but there's totally no pressure at all, of any kind. What so ever. So...", "order": 5903, "speaker": "Kelly Kapoor"},
  {"season": 2, "episode": 16, "scene": 26, "line": "I can't tonight. I have plans with my friends.", "order": 5904, "speaker": "Ryan Howard"},
  {"season": 2, "episode": 16, "scene": 26, "line": "OK, That's cool. I completely understand.", "order": 5905, "speaker": "Kelly Kapoor"},
  {"season": 2, "episode": 16, "scene": 26, "line": "Cool. Cool. OK.", "order": 5906, "speaker": "Ryan Howard"},
  {"season": 2, "episode": 16, "scene": 27, "line": "Josh Porter, Stamford.", "order": 5907, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 16, "scene": 27, "line": "David [to Josh and shakes hands]", "order": 5908, "speaker": "David Wallace"},
  {"season": 2, "episode": 16, "scene": 27, "line": "Nice to meet you.", "order": 5909, "speaker": "Josh Porter"},
  {"season": 2, "episode": 16, "scene": 27, "line": "And Michael Scott, Scranton.", "order": 5910, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 16, "scene": 27, "line": "Nice to meet you.", "order": 5911, "speaker": "David Wallace"},
  {"season": 2, "episode": 16, "scene": 27, "line": "Ditto. [to Jan] How are you Jan?", "order": 5912, "speaker": "Michael Scott"},
  {"season": 2, "episode": 16, "scene": 27, "line": "Fine Michael. Thank you.", "order": 5913, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 16, "scene": 27, "line": "OK. So we are in the process of doing a complete review of the company's financial strengths. All I'd like to do today is to...", "order": 5914, "speaker": "David Wallace"},
  {"season": 2, "episode": 16, "scene": 28, "line": "Nervous, no I'm not nervous. Well, I guess I'd be lying if I didn't say I was a little nervous. Umm, the new CFO is judging me on this too, and well, it is Michael, so. Yeah, I'm very nervous.", "order": 5915, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 16, "scene": 29, "line": "So with the twelve new local accounts, we had a total of four percent organic growth, which was just above our pre-year targets.", "order": 5916, "speaker": "Josh Porter"},
  {"season": 2, "episode": 16, "scene": 29, "line": "Thanks very much.", "order": 5917, "speaker": "David Wallace"},
  {"season": 2, "episode": 16, "scene": 29, "line": "Thank you.", "order": 5918, "speaker": "Josh Porter"},
  {"season": 2, "episode": 16, "scene": 29, "line": "OK, Michael.", "order": 5919, "speaker": "David Wallace"},
  {"season": 2, "episode": 16, "scene": 29, "line": "What is a business? Is it a collection of numbers and sales reports? Sure. But as you know, David and Jan, it is much more. [plays video on screen. David and Jan have confused looks on their faces.]", "order": 5920, "speaker": "Michael Scott"},
  {"season": 2, "episode": 16, "scene": 29, "line": "[video dialogue for 'The Faces of Scranton'] Life moves a little slower in Scranton, Pennsylvania. And that's the way we like it. Because at Dunder Mifflin Scranton, we're not just in the paper business, we're in the people business. Let's meet some of the folks that make the Scranton branch so special. [video shows Stanley at desk] This is Stanley Hudson, one of our talented salesman. An African-American father of two, Stanley's dedication is no doubt one of the hallmark's of the foundation of the business we're hoping to build our bases on.", "order": 5921, "speaker": "Michael Scott"},
  {"season": 2, "episode": 16, "scene": 30, "line": "Yeah, I shot a bunch of footage around the office, edited it together on my Mac. I was thinking of entering it into some festivals. Probably won't. You know, not what this is about.", "order": 5922, "speaker": "Michael Scott"},
  {"season": 2, "episode": 16, "scene": 31, "line": "[video dialogue] And finally, Pam Beesly. Look at her. Look how cute. Not bad at all. As the receptionist, Pam is truly the gateway to our world. Well, I hope this gave you a little taste of what life is like here at Dunder Mifflin, Scranton. What it's like to walk a mile in Oscar's shoes. Or try on Phyllis' pants. Maybe even one of Angela's famous brownies. And you'll know, that you're home. [video says, 'Great Scott!']", "order": 5923, "speaker": "Michael Scott"},
  {"season": 2, "episode": 16, "scene": 31, "line": "Questions?", "order": 5924, "speaker": "Michael Scott"},
  {"season": 2, "episode": 16, "scene": 32, "line": "Wow. OK, OK, thank you Michael, that was great.", "order": 5925, "speaker": "David Wallace"},
  {"season": 2, "episode": 16, "scene": 32, "line": "Yes, thank you.", "order": 5926, "speaker": "Michael Scott"},
  {"season": 2, "episode": 16, "scene": 32, "line": "But, for right now what, I would really like to know about is the branch's performance, so do you have that information as well?", "order": 5927, "speaker": "David Wallace"},
  {"season": 2, "episode": 16, "scene": 32, "line": "Yes, absolutely David. Get that for you. I umm... [hands over report]", "order": 5928, "speaker": "Michael Scott"},
  {"season": 2, "episode": 16, "scene": 33, "line": "[with flowers] Can you sign?", "order": 5929, "speaker": "Delivery man"},
  {"season": 2, "episode": 16, "scene": 33, "line": "Yeah.", "order": 5930, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 16, "scene": 33, "line": "[delivering plant to Oscar's desk] Oscar.", "order": 5931, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 16, "scene": 33, "line": "Nothing for me?", "order": 5932, "speaker": "Angela Martin"},
  {"season": 2, "episode": 16, "scene": 33, "line": "[walks away] Join the club.", "order": 5933, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 16, "scene": 33, "line": "Whose it from? [to Oscar]", "order": 5934, "speaker": "Kevin Malone"},
  {"season": 2, "episode": 16, "scene": 33, "line": "My mom. [puts card in pocket]", "order": 5935, "speaker": "Oscar Martinez"},
  {"season": 2, "episode": 16, "scene": 34, "line": "It's frustrating, because we'd be so perfect together.", "order": 5936, "speaker": "Kelly Kapoor"},
  {"season": 2, "episode": 16, "scene": 34, "line": "You know what? Here's the deal, Kelly. It would be really nice if he was in to you, right? It'd be great, but he isn't.", "order": 5937, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 16, "scene": 34, "line": "Yeah, it would be so great if he was.", "order": 5938, "speaker": "Kelly Kapoor"},
  {"season": 2, "episode": 16, "scene": 34, "line": "Well, he's not, though. So you just gotta suck it up. You just gotta move on. Try to have some fun. Come to my poker game tonight.", "order": 5939, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 16, "scene": 34, "line": "Okay, cool. Is it okay if I invite Ryan? [Jim leaves]", "order": 5940, "speaker": "Kelly Kapoor"},
  {"season": 2, "episode": 16, "scene": 35, "line": "And that about does it, thank you.", "order": 5941, "speaker": "Dan"},
  {"season": 2, "episode": 16, "scene": 35, "line": "OK. [looks toward Craig] Craig,", "order": 5942, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 16, "scene": 35, "line": "Yeah. Here's the deal. I did not understand this was supposed to be a full on... like report or whatnot.", "order": 5943, "speaker": "Craig"},
  {"season": 2, "episode": 16, "scene": 35, "line": "Um, I'm sorry, what did you think financial presentation meant?", "order": 5944, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 16, "scene": 35, "line": "I was under the impression this was, more of like... a meet and greet type deal.", "order": 5945, "speaker": "Craig"},
  {"season": 2, "episode": 16, "scene": 35, "line": "So, does that mean you don't have the numbers on your branch?", "order": 5946, "speaker": "David Wallace"},
  {"season": 2, "episode": 16, "scene": 35, "line": "That is correct, yes.", "order": 5947, "speaker": "Craig"},
  {"season": 2, "episode": 16, "scene": 35, "line": "Craig, you realize that we're trying to decide whether if drastic steps need to be taken?", "order": 5948, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 16, "scene": 35, "line": "Look, I'm sorry, I didn't know.", "order": 5949, "speaker": "Craig"},
  {"season": 2, "episode": 16, "scene": 35, "line": "Well, the point is, is that doesn't exactly bode well for your branch.", "order": 5950, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 16, "scene": 35, "line": "Oh man, you know what? Michael made that stupid movie, he doesn't get into any trouble? Maybe I should have slept with you, too. [David looks at Jan, who glares at Michael.]", "order": 5951, "speaker": "Craig"},
  {"season": 2, "episode": 16, "scene": 36, "line": "Oh, ok. Alright.", "order": 5952, "speaker": "Michael Scott"},
  {"season": 2, "episode": 16, "scene": 36, "line": "NO, NO I'm not, I'm not, I just... I just don't know what to do anymore, Michael. I mean, we're all gonna get fired.", "order": 5953, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 16, "scene": 36, "line": "No you're not.", "order": 5954, "speaker": "Michael Scott"},
  {"season": 2, "episode": 16, "scene": 36, "line": "Yeah, Michael - the CFO thinks that we slept together. Do you understand, people get fired for much less? And I just [scratches head] can't believe that you told everybody and we didn't even sleep together.", "order": 5955, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 16, "scene": 36, "line": "Technically, we fell asleep in the same bed. So...", "order": 5956, "speaker": "Michael Scott"},
  {"season": 2, "episode": 16, "scene": 36, "line": "Oh, God. Michael. It was months ago. It was once, It's over. Do you understand?", "order": 5957, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 16, "scene": 36, "line": "Yes. I'm sorry. I will fix this. I'll talk to him. I'll talk to David.", "order": 5958, "speaker": "Michael Scott"},
  {"season": 2, "episode": 16, "scene": 36, "line": "Surely, you cannot be serious?", "order": 5959, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 16, "scene": 36, "line": "I am serious. And don't call me Shirley. Airplane.", "order": 5960, "speaker": "Michael Scott"},
  {"season": 2, "episode": 16, "scene": 37, "line": "Women are like wolves. If you want a wolf, you have to trap it. You have to snare it. And then you have to tame it. Keep it happy. Care for it. Feed it. Lovingly, the way an animal deserves to be loved. And my animal deserves a lot of loving.", "order": 5961, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 16, "scene": 38, "line": "Hey babe.", "order": 5962, "speaker": "Roy Anderson"},
  {"season": 2, "episode": 16, "scene": 38, "line": "Hey.", "order": 5963, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 16, "scene": 38, "line": "You almost ready to go?", "order": 5964, "speaker": "Roy Anderson"},
  {"season": 2, "episode": 16, "scene": 38, "line": "I guess, yeah.", "order": 5965, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 16, "scene": 38, "line": "What's wrong?", "order": 5966, "speaker": "Roy Anderson"},
  {"season": 2, "episode": 16, "scene": 38, "line": "Nothing, it's just I had to sit here all day, while Phyllis got like an entire garden delivered to her.", "order": 5967, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 16, "scene": 38, "line": "What, you're mad at me?", "order": 5968, "speaker": "Roy Anderson"},
  {"season": 2, "episode": 16, "scene": 38, "line": "I mean, I know that we said no big gifts, but I was kind of hoping you'd get me", "order": 5969, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 16, "scene": 38, "line": "Well, Valentine's Day isn't over. Let's get you home and you are gonna get the best sex of you life.", "order": 5970, "speaker": "Roy Anderson"},
  {"season": 2, "episode": 16, "scene": 39, "line": "You understand this is a very serious situation.", "order": 5971, "speaker": "David Wallace"},
  {"season": 2, "episode": 16, "scene": 39, "line": "No no no no no, yes I, OK, well, alright, here's the deal. It's my fault. This is, this is totally on me. Before you guys came in, I was talking to the guys. We were all chatting and I made a joke, a really dumb joke and Craig the idiot took it seriously. [Jan looks at Michael]", "order": 5972, "speaker": "Michael Scott"},
  {"season": 2, "episode": 16, "scene": 39, "line": "You made a joke?", "order": 5973, "speaker": "David Wallace"},
  {"season": 2, "episode": 16, "scene": 39, "line": "I did, it was stupid. And Craig, you saw him, he's not the sharpest tool in the shed. Although he is a tool. [David grins]", "order": 5974, "speaker": "Michael Scott"},
  {"season": 2, "episode": 16, "scene": 39, "line": "Well I don't need to explain to you that even a joke about sexual relations with your boss...", "order": 5975, "speaker": "David Wallace"},
  {"season": 2, "episode": 16, "scene": 39, "line": "I know. It was borderline at best and... And Jan is a fantastic executive and has all the integrity in the world and um, I'm really sorry. It will never happen again.", "order": 5976, "speaker": "Michael Scott"},
  {"season": 2, "episode": 16, "scene": 39, "line": "Uh, that's fine. Let's just forget it.", "order": 5977, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 16, "scene": 39, "line": "Good. [Michael leaves office]", "order": 5978, "speaker": "David Wallace"},
  {"season": 2, "episode": 16, "scene": 40, "line": "Heading out?", "order": 5979, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 16, "scene": 40, "line": "Yeah. Alright, Beesly, Hey, Happy Valentine's Day.", "order": 5980, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 16, "scene": 40, "line": "Bye. [Jim leaves]", "order": 5981, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 16, "scene": 40, "line": "Goodnight Pam. [Leaves with oversized bear on back.]", "order": 5982, "speaker": "Phyllis Lapin"},
  {"season": 2, "episode": 16, "scene": 40, "line": "Night Phyllis.", "order": 5983, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 16, "scene": 41, "line": "Oh, Michael. Thank you again for that, really. It was very nice.", "order": 5984, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 16, "scene": 41, "line": "Oh, no big deal. Really. Sorry again.", "order": 5985, "speaker": "Michael Scott"},
  {"season": 2, "episode": 16, "scene": 41, "line": "Oh, no, it's OK. [Puts hand in way of elevator door to stop from closing.] So, uh, Happy valentine's Day.", "order": 5986, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 16, "scene": 41, "line": "Yeah, Happy Valentine's Day. [Jan turns and then kisses Michael. Michael looks and sees camera, Jan turns and sees camera, too. Elevator door closes.]", "order": 5987, "speaker": "Michael Scott"},
  {"season": 2, "episode": 16, "scene": 41, "line": "Oh.", "order": 5988, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 16, "scene": 42, "line": "Oy vey... schmear. [Points at Fiddler on the Roof playing at Minskoff Theatre and does a dance.]", "order": 5989, "speaker": "Michael Scott"},
  {"season": 2, "episode": 16, "scene": 44, "line": "Michael's got a few tricks for Valentine's Day. He found a place where you can get 12 plastic roses for $10 and he's got a great line. 'Me so thorny.'", "order": 5990, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 16, "scene": 45, "line": "I love you, Angela. Yes, even you. I love you, Kelly. I love you, Meredith. This is all platonically, of course.", "order": 5991, "speaker": "Michael Scott"},
  {"season": 2, "episode": 16, "scene": 46, "line": "I love ladies, always have. And you know what I think is the most attractive part of a woman's body? The brains. Because I don't think a woman is beautiful unless she is smart. And also, the brains are where the ladies get their best nasty ideas for bedroom stuff.", "order": 5992, "speaker": "Michael Scott"},
  {"season": 2, "episode": 16, "scene": 50, "line": "I'm not good with names.", "order": 5993, "speaker": "Creed Bratton"},
  {"season": 2, "episode": 16, "scene": 54, "line": "Okay, here's the thing about Jan. She talks such a big game about, 'Oh there's nothing between us and stop talking about it.' And 'Border-line harassment when you call me at home.' But let me ask you this, is it just a coincidence that this meeting is taking place on Valentine's Day? Answer, maybe, but maybe not. We shall see.", "order": 5994, "speaker": "Michael Scott"},
  {"season": 2, "episode": 16, "scene": 56, "line": "Dwight lives on a beet farm and he practices karate at the forth grade level. And he apparently has a girlfriend, so... I guess there really is someone for everybody.", "order": 5995, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 17, "scene": 1, "line": "Let's think this through. If we ask Corporate for that then...", "order": 5996, "speaker": "Michael Scott"},
  {"season": 2, "episode": 17, "scene": 1, "line": "They are either going to say yes... or no.", "order": 5997, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 17, "scene": 1, "line": "Could go either way. We don't know what they are going to say.", "order": 5998, "speaker": "Michael Scott"},
  {"season": 2, "episode": 17, "scene": 1, "line": "Think it through.", "order": 5999, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 17, "scene": 1, "line": "Have to think it through. Because if they say no...", "order": 6000, "speaker": "Michael Scott"},
  {"season": 2, "episode": 17, "scene": 1, "line": "Can we not?", "order": 6001, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 17, "scene": 1, "line": "No! Yes, we have to! You know why? Because I don't like to be cooped up in that office! In that box! All day long. [Michael starts playing with a football in the office] Heisman! Because I need to think. Okay, Jim? Oh, Kevin, oh! [laughs] Nice catch. Mmmm, mmm, mmm,mmm. Os-car! Intercepted.", "order": 6002, "speaker": "Michael Scott"},
  {"season": 2, "episode": 17, "scene": 1, "line": "Still want that.", "order": 6003, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 17, "scene": 1, "line": "Give it to me. Phyllis, give me the ball. Ok, give me the ball. Give me, you guys... Creed give me the ball! Right now give it to me.", "order": 6004, "speaker": "Michael Scott"},
  {"season": 2, "episode": 17, "scene": 1, "line": "Ryan!", "order": 6005, "speaker": "Creed Bratton"},
  {"season": 2, "episode": 17, "scene": 1, "line": "Fumble! Yaaah!", "order": 6006, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 17, "scene": 1, "line": "Hey, Dwight.", "order": 6007, "speaker": "Michael Scott"},
  {"season": 2, "episode": 17, "scene": 1, "line": "Hut! Hut! Hut! Hike!", "order": 6008, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 17, "scene": 1, "line": "You all right Ryan?", "order": 6009, "speaker": "Michael Scott"},
  {"season": 2, "episode": 17, "scene": 1, "line": "Ryan.", "order": 6010, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 17, "scene": 1, "line": "Yeah.", "order": 6011, "speaker": "Ryan Howard"},
  {"season": 2, "episode": 17, "scene": 1, "line": "Pam!", "order": 6012, "speaker": "Michael Scott"},
  {"season": 2, "episode": 17, "scene": 2, "line": "Ooh. They're having a sale on TiVo. Maybe I should get a TiVo. Oh. DVD Burner! Maybe I should get one of those. You are so lucky, Jim. You are so lucky you don't have this problem. What was the 9th place prize again? A loaf of bread?", "order": 6013, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 17, "scene": 2, "line": "Cugino's pizza.", "order": 6014, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 17, "scene": 2, "line": "Oh, great. Tasty, terrific pizza. Hmm. Question: Do their pizzas play DVDs?", "order": 6015, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 17, "scene": 3, "line": "Dwight was the top salesman of the year at our company. He wins a little prize money and gets honored at some convention. It is literally the highest possible honor that a Northeastern Pennsylvania-Based Mid-size Paper Company Regional Salesman can attain, so...", "order": 6016, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 17, "scene": 4, "line": "What did I do to deserve this?", "order": 6017, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 17, "scene": 4, "line": "Are you sad that Dwight beat you?", "order": 6018, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 17, "scene": 4, "line": "No.", "order": 6019, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 17, "scene": 4, "line": "Are you going to cry, Jim? Do you need a tissue?", "order": 6020, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 17, "scene": 4, "line": "Hey, I heard you got a wedding dress. Do you have pictures?", "order": 6021, "speaker": "Phyllis Lapin"},
  {"season": 2, "episode": 17, "scene": 4, "line": "Oh! I... uh... yeah. Um... I'll uh show them to you later.", "order": 6022, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 17, "scene": 4, "line": "Oh.", "order": 6023, "speaker": "Phyllis Lapin"},
  {"season": 2, "episode": 17, "scene": 4, "line": "Oh, I should get back. Talk to you guys later.", "order": 6024, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 17, "scene": 4, "line": "Ok, cool.", "order": 6025, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 17, "scene": 5, "line": "I have a ton of stuff to do for the wedding. And I have to do it in the office. And that can be kind of awkward. Um... just because people can get all weird about wedding stuff. Then... I just... I don't want to offend... Angela... or someone.", "order": 6026, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 17, "scene": 6, "line": "That's what she said!", "order": 6027, "speaker": "Michael Scott"},
  {"season": 2, "episode": 17, "scene": 6, "line": "Ha! I don't get it.", "order": 6028, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 17, "scene": 6, "line": "Grapes. Seductive. So you ready for the big speech this afternoon?", "order": 6029, "speaker": "Michael Scott"},
  {"season": 2, "episode": 17, "scene": 6, "line": "Well, it's not really a big speech. You still coming right?", "order": 6030, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 17, "scene": 6, "line": "Oh! Abso-fruit-ly. Fruit. Grapes. Nailed the joke. Matter of time. Um... And yes, it is a big speech. Biggest of your life.", "order": 6031, "speaker": "Michael Scott"},
  {"season": 2, "episode": 17, "scene": 7, "line": "Speaker at the Sales Convention. Been there, done that. Went there again, did it again. Two years in a row. Consecutive. I just... I miss the feeling of knowing that you did a good job because someone gives you proof of it. Sir, you're awesome! Here's a plaque. What, a whole year has gone by and you need more proof? Here's a certificate. They stopped making plaques that year.", "order": 6032, "speaker": "Michael Scott"},
  {"season": 2, "episode": 17, "scene": 8, "line": "What if I give a really long, extended Thank You. For instance, 'Thank you, Mr. Blank. Thank you very, very, very...'", "order": 6033, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 17, "scene": 8, "line": "That would look terrible. These are mostly salesmen and salesmen expect to be entertained and you are the main act.", "order": 6034, "speaker": "Michael Scott"},
  {"season": 2, "episode": 17, "scene": 9, "line": "When I was in the sixth grade, I was a finalist in our school Spelling Bee. It was me against Raj Patel. And I misspelled, in front of the entire school, the word 'failure'.", "order": 6035, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 17, "scene": 10, "line": "I can't do this.", "order": 6036, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 17, "scene": 10, "line": "That's because you're incapable of doing it because you don't know how. Because you have no skills. Dwight, there's no way I can possibly teach you what you need to know about public speaking by speech time.", "order": 6037, "speaker": "Michael Scott"},
  {"season": 2, "episode": 17, "scene": 10, "line": "Oh, okay.", "order": 6038, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 17, "scene": 10, "line": "But I can teach you enough so that you don't embarrass me or the company.", "order": 6039, "speaker": "Michael Scott"},
  {"season": 2, "episode": 17, "scene": 10, "line": "Okay, deal! I'll do whatever you say. No questions asked.", "order": 6040, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 17, "scene": 10, "line": "Well, if you have a question, you should ask me.", "order": 6041, "speaker": "Michael Scott"},
  {"season": 2, "episode": 17, "scene": 10, "line": "I'll try and think of one. When...", "order": 6042, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 17, "scene": 10, "line": "Don't. Don't try and think of a question to humor me. Just... try not to be such an idiot.", "order": 6043, "speaker": "Michael Scott"},
  {"season": 2, "episode": 17, "scene": 10, "line": "Is that an insult or is that part of the public speaking advice?", "order": 6044, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 17, "scene": 10, "line": "Insult.", "order": 6045, "speaker": "Michael Scott"},
  {"season": 2, "episode": 17, "scene": 11, "line": "Mom, I'm sorry. I know you and Dad are chipping in for the wedding but I do not want orange invitations. Yes! Well, if you really want my...", "order": 6046, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 17, "scene": 11, "line": "Hi, yeah, can I talk to one of your travel agents?", "order": 6047, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 17, "scene": 12, "line": "I'm going to take a trip. I'm going to get out of town for a while... and go someplace... not here.", "order": 6048, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 17, "scene": 13, "line": "Where do I want to go? Um... that is an excellent question. And one I should have probably thought about before I called you. Um...", "order": 6049, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 17, "scene": 14, "line": "I get here early every morning so I can set the thermostat. I like it a little cooler, around 66 degrees. I'm more productive. Maybe some people don't like it as cold as I do, but I don't care.", "order": 6050, "speaker": "Oscar Martinez"},
  {"season": 2, "episode": 17, "scene": 15, "line": "[stand up comic voice] But seriously, what's the difference between a salesman and a saleswoman?", "order": 6051, "speaker": "Michael Scott"},
  {"season": 2, "episode": 17, "scene": 15, "line": "Saleswoman has a vagina.", "order": 6052, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 17, "scene": 15, "line": "It's a joke, Dwight. It's not a Sex Ed class.", "order": 6053, "speaker": "Michael Scott"},
  {"season": 2, "episode": 17, "scene": 15, "line": "But I'm right?", "order": 6054, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 17, "scene": 15, "line": "Yeah, you're right about the difference between a man and a woman, but not about the punch line to the joke, right? [stand up comic voice] The difference between a salesman and a saleswoman... is boobs!", "order": 6055, "speaker": "Michael Scott"},
  {"season": 2, "episode": 17, "scene": 15, "line": "Hey. Do you remember the speeches that you gave?", "order": 6056, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 17, "scene": 15, "line": "I do. Both of them.", "order": 6057, "speaker": "Michael Scott"},
  {"season": 2, "episode": 17, "scene": 15, "line": "Could I have a copy of one of them?", "order": 6058, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 17, "scene": 15, "line": "No, no! They would remember them. Look, it doesn't matter what you say. It just matters that you're saying something that people care about. Yeah? All right? Here we go. Watch this.", "order": 6059, "speaker": "Michael Scott"},
  {"season": 2, "episode": 17, "scene": 16, "line": "Attention everybody! Attention please! I have some very great news from Corporate. We had a wonderful quarter and as a result all of you are getting bonuses for 1000 dollars!", "order": 6060, "speaker": "Michael Scott"},
  {"season": 2, "episode": 17, "scene": 16, "line": "Yeah!", "order": 6061, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 17, "scene": 16, "line": "[generalized clapping and cheering] Congratulations.", "order": 6062, "speaker": "Michael Scott"},
  {"season": 2, "episode": 17, "scene": 16, "line": "Unbelievable.", "order": 6063, "speaker": "Phyllis Lapin"},
  {"season": 2, "episode": 17, "scene": 17, "line": "You see that? You see how they responded to me? In that moment, I had them.", "order": 6064, "speaker": "Michael Scott"},
  {"season": 2, "episode": 17, "scene": 17, "line": "That is so great about the bonus!", "order": 6065, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 17, "scene": 17, "line": "No, no! It's not true. I was just talking so just go out there and say anything. They'll eat it up. They're a great audience.", "order": 6066, "speaker": "Michael Scott"},
  {"season": 2, "episode": 17, "scene": 18, "line": "Go ahead. Get the wallpaper. Wallpaper the ceiling if you want. Call Terri and tell her she...", "order": 6067, "speaker": "Stanley Hudson"},
  {"season": 2, "episode": 17, "scene": 18, "line": "It's unbelievable!", "order": 6068, "speaker": "Phyllis Lapin"},
  {"season": 2, "episode": 17, "scene": 18, "line": "Excuse me! May I have your attention please? There has been an accident on 84 West. Cars have skidded off the road into the safety railing. Several cars have flipped. There is broken glass everywhere. Several people are injured.", "order": 6069, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 17, "scene": 18, "line": "Do we know anyone who was in the accident?", "order": 6070, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 17, "scene": 18, "line": "Brad Pitt. Also there will be no bonuses.", "order": 6071, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 17, "scene": 18, "line": "Why would this affect our bonuses?", "order": 6072, "speaker": "Stanley Hudson"},
  {"season": 2, "episode": 17, "scene": 18, "line": "They are unrelated.", "order": 6073, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 17, "scene": 18, "line": "Is Brad okay?", "order": 6074, "speaker": "Kelly Kapoor"},
  {"season": 2, "episode": 17, "scene": 18, "line": "He will never act again. Also, this branch is closing.", "order": 6075, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 17, "scene": 18, "line": "What the hell is going on here?", "order": 6076, "speaker": "Oscar Martinez"},
  {"season": 2, "episode": 17, "scene": 18, "line": "Are we out of jobs?", "order": 6077, "speaker": "Angela Martin"},
  {"season": 2, "episode": 17, "scene": 18, "line": "Yes.", "order": 6078, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 17, "scene": 18, "line": "This is karma because of what he did to Jennifer Aniston.", "order": 6079, "speaker": "Kelly Kapoor"},
  {"season": 2, "episode": 17, "scene": 18, "line": "He's kidding. Dwight was kidding and I don't know why because it wasn't funny... and it was just horrible.", "order": 6080, "speaker": "Michael Scott"},
  {"season": 2, "episode": 17, "scene": 18, "line": "Michael?", "order": 6081, "speaker": "Stanley Hudson"},
  {"season": 2, "episode": 17, "scene": 18, "line": "Yeah.", "order": 6082, "speaker": "Michael Scott"},
  {"season": 2, "episode": 17, "scene": 18, "line": "You said we were getting bonuses.", "order": 6083, "speaker": "Stanley Hudson"},
  {"season": 2, "episode": 17, "scene": 18, "line": "All right. Everybody in the conference room now. Let's go. Let's do it.", "order": 6084, "speaker": "Michael Scott"},
  {"season": 2, "episode": 17, "scene": 18, "line": "Cancel wallpaper.", "order": 6085, "speaker": "Stanley Hudson"},
  {"season": 2, "episode": 17, "scene": 19, "line": "As your leader and your friend, I sort of demand that you can all speak in public as I can... and did... twice. [speaking to camera] You saw the plaque, right? [to office] All right. We're all going to go around the room and we're going to make toasts. And that way, we will overcome our fear of public speaking.", "order": 6086, "speaker": "Michael Scott"},
  {"season": 2, "episode": 17, "scene": 19, "line": "You mean Toastmasters?", "order": 6087, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 17, "scene": 19, "line": "Pam! I'm public speaking. Stop public interrupting me. Actually, this would be good practice for your wedding toast.", "order": 6088, "speaker": "Michael Scott"},
  {"season": 2, "episode": 17, "scene": 19, "line": "Yeah, the bride doesn't really do... Have you ever been to a wedding?", "order": 6089, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 17, "scene": 19, "line": "Can I go?", "order": 6090, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 17, "scene": 19, "line": "Yes. Good. Jim taking the initiative.", "order": 6091, "speaker": "Michael Scott"},
  {"season": 2, "episode": 17, "scene": 19, "line": "So. Uh... I am going on a trip. But not really sure where I'm going yet. It's kind of open-ended. So I was hoping maybe you guys would have some suggestions?", "order": 6092, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 17, "scene": 19, "line": "You should go to Hedonism.", "order": 6093, "speaker": "Kevin Malone"},
  {"season": 2, "episode": 17, "scene": 19, "line": "What is that?", "order": 6094, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 17, "scene": 19, "line": "It's like Club Med, but everything is naked.", "order": 6095, "speaker": "Kevin Malone"},
  {"season": 2, "episode": 17, "scene": 19, "line": "I was thinking more like Europe. Or something like that. But, good second choice.", "order": 6096, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 17, "scene": 19, "line": "Been to Amsterdam.", "order": 6097, "speaker": "Toby Flenderson"},
  {"season": 2, "episode": 17, "scene": 19, "line": "Oh ho hokay. You know what? That's not a toast. You're not standing up.", "order": 6098, "speaker": "Michael Scott"},
  {"season": 2, "episode": 17, "scene": 19, "line": "[mimes lifting a glass] To Amsterdam.", "order": 6099, "speaker": "Toby Flenderson"},
  {"season": 2, "episode": 17, "scene": 19, "line": "When did you go there?", "order": 6100, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 17, "scene": 19, "line": "Umm... After my divorce. Yeah.", "order": 6101, "speaker": "Toby Flenderson"},
  {"season": 2, "episode": 17, "scene": 19, "line": "Really for like how long?", "order": 6102, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 17, "scene": 19, "line": "Uh, about a week. Er... .um... .maybe a month. I uh can't...", "order": 6103, "speaker": "Toby Flenderson"},
  {"season": 2, "episode": 17, "scene": 19, "line": "Jimmy, listen to me. You do not want to go to Amsterdam. Trust me.", "order": 6104, "speaker": "Creed Bratton"},
  {"season": 2, "episode": 17, "scene": 19, "line": "Where do I want to go?", "order": 6105, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 17, "scene": 19, "line": "I'd send you to Hong Kong.", "order": 6106, "speaker": "Creed Bratton"},
  {"season": 2, "episode": 17, "scene": 20, "line": "Like to say 'Hi' to my friends in China. [speaks in Chinese]", "order": 6107, "speaker": "Creed Bratton"},
  {"season": 2, "episode": 17, "scene": 21, "line": "Okay, Dwight. Show us what you have learned today.", "order": 6108, "speaker": "Michael Scott"},
  {"season": 2, "episode": 17, "scene": 21, "line": "Good morning, Vietnam! [general groaning] Okay. You know what? This isn't working. Because um I'm not nervous in front of them. They're my subordinates.", "order": 6109, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 17, "scene": 21, "line": "No. We're not.", "order": 6110, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 17, "scene": 21, "line": "Uh, yes you are. I'm Assistant Regional Manager.", "order": 6111, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 17, "scene": 21, "line": "Which means absolutely nothing.", "order": 6112, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 17, "scene": 21, "line": "Michael, can you explain?", "order": 6113, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 17, "scene": 21, "line": "Well, it's mostly made up. So...", "order": 6114, "speaker": "Michael Scott"},
  {"season": 2, "episode": 17, "scene": 22, "line": "Dwight is not going to do a job. It's sad. And they're expecting excellence because I did do such a good job. Two years in a row. I killed. It was amazing.", "order": 6115, "speaker": "Michael Scott"},
  {"season": 2, "episode": 17, "scene": 23, "line": "Confidence, Dwight.", "order": 6116, "speaker": "Michael Scott"},
  {"season": 2, "episode": 17, "scene": 24, "line": "Dwight. If you could travel anywhere in the world where would you go?", "order": 6117, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 17, "scene": 24, "line": "I can travel anywhere except Cuba. And I will travel to New Zealand. And walk the 'Lord of the Rings' trail to Mordor. And then I will hike Mount Doom. So... no... just leave me alone.", "order": 6118, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 17, "scene": 24, "line": "Okay. Just trying to get some advice on my trip.", "order": 6119, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 17, "scene": 24, "line": "Oh please! You're not taking any trip.", "order": 6120, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 17, "scene": 24, "line": "You know I majored in Public Speaking in College.", "order": 6121, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 17, "scene": 24, "line": "You did?", "order": 6122, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 17, "scene": 24, "line": "Mmmhmm. And the first thing they teach you is that you've got to be true to your self. And you are all about authority.", "order": 6123, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 17, "scene": 24, "line": "Yes. I am.", "order": 6124, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 17, "scene": 24, "line": "The great speakers throughout history were not joke tellers. They were people of passion. So if you want to do well today, you got to do what they did.", "order": 6125, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 17, "scene": 24, "line": "Which is?", "order": 6126, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 17, "scene": 24, "line": "You've got to wave your arms and you've got to pound your fists. Many times. It's supposed to emphasize your point.", "order": 6127, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 17, "scene": 25, "line": "Ok, I didn't actually major in Public Speaking. But, I did download speeches from some of history's famous dictators. Like this one [holds up paper]. Originally given by Benito Mussolini.", "order": 6128, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 17, "scene": 26, "line": "Ok, look. I know you are giving this speech on your own", "order": 6129, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 17, "scene": 26, "line": "I'll glance at it.", "order": 6130, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 17, "scene": 27, "line": "It's time, Dwight. The grim reaper is here.", "order": 6131, "speaker": "Michael Scott"},
  {"season": 2, "episode": 17, "scene": 27, "line": "The very best of luck to you, Dwight.", "order": 6132, "speaker": "Angela Martin"},
  {"season": 2, "episode": 17, "scene": 27, "line": "Thank you, Angela.", "order": 6133, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 17, "scene": 28, "line": "Why'd you pick the V.A. for the reception?", "order": 6134, "speaker": "Kelly Kapoor"},
  {"season": 2, "episode": 17, "scene": 28, "line": "Roy has a connection. It's nicer than you think.", "order": 6135, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 17, "scene": 28, "line": "You're inviting Jim?", "order": 6136, "speaker": "Ryan Howard"},
  {"season": 2, "episode": 17, "scene": 28, "line": "Of course. He's one of my closest friends.", "order": 6137, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 17, "scene": 29, "line": "All right. You ready? Here we go! Wow. It's a little bit bigger than I remember. Come on. We're down here. Right.", "order": 6138, "speaker": "Michael Scott"},
  {"season": 2, "episode": 17, "scene": 30, "line": "[song] You all ready for this?", "order": 6139, "speaker": "Overhead"},
  {"season": 2, "episode": 17, "scene": 31, "line": "[coughs] [sniffles] I am just feeling under the weather. And... I think that I will go home and rest.", "order": 6140, "speaker": "Angela Martin"},
  {"season": 2, "episode": 17, "scene": 31, "line": "I've never, ever seen you take a sick day.", "order": 6141, "speaker": "Kevin Malone"},
  {"season": 2, "episode": 17, "scene": 31, "line": "Well, I've seen you take enough for the both of us.", "order": 6142, "speaker": "Angela Martin"},
  {"season": 2, "episode": 17, "scene": 32, "line": "Next, I'd like to introduce the Dunder Mifflin Salesman of the Year, Dwight Schrute!", "order": 6143, "speaker": "Speaker"},
  {"season": 2, "episode": 17, "scene": 32, "line": "[polite clapping]", "order": 6144, "speaker": "Crowd"},
  {"season": 2, "episode": 17, "scene": 32, "line": "Dwight, they called your name.", "order": 6145, "speaker": "Michael Scott"},
  {"season": 2, "episode": 17, "scene": 32, "line": "Dwight, how we doing?", "order": 6146, "speaker": "Speaker"},
  {"season": 2, "episode": 17, "scene": 32, "line": "No, I can't... I ca...", "order": 6147, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 17, "scene": 32, "line": "All right. You know what? Okay. No. No problem. You are lucky you have me here. I'm going to cover for you. [shouts] All right!", "order": 6148, "speaker": "Michael Scott"},
  {"season": 2, "episode": 17, "scene": 32, "line": "[claps]", "order": 6149, "speaker": "Crowd"},
  {"season": 2, "episode": 17, "scene": 32, "line": "Gooood morning, Vietnaaaam! I am not Dwight Schrute. Not at all. I am Michael Scott, his mentor and boss. And until Dwight comes up, if he ever does, I wanted to say a few words about excellence. What makes a work environment excellent? Well, there are many things, I believe, that do such a thing of that nature. And one would be humor. What is the difference between a salesman and a saleswoman?", "order": 6150, "speaker": "Michael Scott"},
  {"season": 2, "episode": 17, "scene": 33, "line": "I always set it at 69. [snickers]", "order": 6151, "speaker": "Kevin Malone"},
  {"season": 2, "episode": 17, "scene": 34, "line": "Maybe we'll use a DJ. That's the one thing Roy's in charge of for this wedding but all he's managed to do is set a date.", "order": 6152, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 17, "scene": 34, "line": "But he did a great job. June 10th is perfect. I want a June wedding. I've always wanted one. Ryan, do you know when you would want to get married?", "order": 6153, "speaker": "Kelly Kapoor"},
  {"season": 2, "episode": 17, "scene": 34, "line": "Actually, I don't see myself ever getting married.", "order": 6154, "speaker": "Ryan Howard"},
  {"season": 2, "episode": 17, "scene": 34, "line": "Oh.", "order": 6155, "speaker": "Kelly Kapoor"},
  {"season": 2, "episode": 17, "scene": 34, "line": "Ryan, you should be more sensitive. It's obvious she likes you and comments like that, they just...", "order": 6156, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 17, "scene": 34, "line": "I know what I said.", "order": 6157, "speaker": "Ryan Howard"},
  {"season": 2, "episode": 17, "scene": 35, "line": "I'm very sorry. I did not know you were wearing a hearing aid and I just thought you were speaking abnormally. ...And now the black guy from the 'Police Academy' movies. A robot. [makes robot sounds] Michael Winslow, anyone?", "order": 6158, "speaker": "Michael Scott"},
  {"season": 2, "episode": 17, "scene": 36, "line": "Car starting. [makes car sounds] All right, Dwight Schrute everyone.", "order": 6159, "speaker": "Michael Scott"},
  {"season": 2, "episode": 17, "scene": 36, "line": "[clapping]", "order": 6160, "speaker": "Crowd"},
  {"season": 2, "episode": 17, "scene": 36, "line": "Good luck. That is a tough crowd.", "order": 6161, "speaker": "Michael Scott"},
  {"season": 2, "episode": 17, "scene": 36, "line": "[bangs fists] Blood alone moves the wheels of history! Have you ever asked yourselves in an hour of meditation, which everyone finds during the day. [waves arm] how long we have been striving for greatness? [bangs fist] Not only the years we've been at war, the war of work, but from the moment as a child when we realized that the world could be conquered. It has been a lifetime's struggle [waves arms]. A never-ending fight. I say to you [hits podium] and you'll understand that it is a privilege to fight!", "order": 6162, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 17, "scene": 36, "line": "[clapping]", "order": 6163, "speaker": "Crowd"},
  {"season": 2, "episode": 17, "scene": 36, "line": "WE ARE WARRIORS!", "order": 6164, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 17, "scene": 36, "line": "[clapping and cheering]", "order": 6165, "speaker": "Crowd"},
  {"season": 2, "episode": 17, "scene": 36, "line": "Salesman of Northeastern Pennsylvania, I ask you once more rise and be worthy of this historical hour!", "order": 6166, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 17, "scene": 36, "line": "[clapping and cheering]", "order": 6167, "speaker": "Crowd"},
  {"season": 2, "episode": 17, "scene": 36, "line": "[laughs maniacally] Yeah. Yes!", "order": 6168, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 17, "scene": 37, "line": "I've got a time share in Key West that might be available.", "order": 6169, "speaker": "Oscar Martinez"},
  {"season": 2, "episode": 17, "scene": 37, "line": "Maybe. Thanks.", "order": 6170, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 17, "scene": 37, "line": "You really think you're going to go?", "order": 6171, "speaker": "Ryan Howard"},
  {"season": 2, "episode": 17, "scene": 37, "line": "Yeah. I'm definitely going.", "order": 6172, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 17, "scene": 37, "line": "Nice. Send me a postcard.", "order": 6173, "speaker": "Ryan Howard"},
  {"season": 2, "episode": 17, "scene": 38, "line": "Jim has worked at the same place for five years. Jim eats the same ham and cheese sandwich everyday for lunch. I don't know. If I were a betting man, I'd say he will have a fun weekend in Philadelphia.", "order": 6174, "speaker": "Ryan Howard"},
  {"season": 2, "episode": 17, "scene": 39, "line": "No revolution is worth anything unless it can defend itself. [bangs fists]", "order": 6175, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 17, "scene": 39, "line": "[claps]", "order": 6176, "speaker": "Crowd"},
  {"season": 2, "episode": 17, "scene": 39, "line": "Some people will tell you salesman is a bad word. They'll conjure up images of used car dealers and door to door charlatans. This is our duty - to change their perception. I say salesmen... and women of the world unite! We must never acquiesce for it is together, TOGETHER, THAT WE PREVAIL! We must never cede control of the motherland! For it is...", "order": 6177, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 17, "scene": 39, "line": "[shouts] Together that we prevail! [cheering and clapping]", "order": 6178, "speaker": "Crowd"},
  {"season": 2, "episode": 17, "scene": 40, "line": "Australia? I have always wanted to go there?", "order": 6179, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 17, "scene": 40, "line": "I'm going. I'm a little nervous to run into Dwight on his connecting flight to Mordor. But, other than that... um, yeah, I bought the ticket, non-refundable.", "order": 6180, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 17, "scene": 40, "line": "That's awesome. Where are you staying?", "order": 6181, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 17, "scene": 40, "line": "I don't know. I feel like I have plenty of time to figure out the details but...", "order": 6182, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 17, "scene": 40, "line": "When are you leaving?", "order": 6183, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 17, "scene": 40, "line": "I'm... leaving on June 8th.", "order": 6184, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 17, "scene": 40, "line": "Oh.", "order": 6185, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 17, "scene": 40, "line": "Yeah. And I'm really sorry about that, I just...", "order": 6186, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 17, "scene": 40, "line": "Oh yeah. That's too bad.", "order": 6187, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 17, "scene": 40, "line": "Yeah. Do you want me to take these on my way out?", "order": 6188, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 17, "scene": 40, "line": "It's ok. I got it.", "order": 6189, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 17, "scene": 40, "line": "Alright.", "order": 6190, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 17, "scene": 41, "line": "Ok, thanks. [to Michael] There you are. What happened?", "order": 6191, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 17, "scene": 41, "line": "I got thirsty. How'd it go?", "order": 6192, "speaker": "Michael Scott"},
  {"season": 2, "episode": 17, "scene": 41, "line": "It was amazing. I wish you would have been there.", "order": 6193, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 17, "scene": 41, "line": "You would not believe what happened here.", "order": 6194, "speaker": "Michael Scott"},
  {"season": 2, "episode": 17, "scene": 41, "line": "What? Something happened?", "order": 6195, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 17, "scene": 41, "line": "Oh! This woman came in, sat down, ordered a drink. The bartender asked for her ID which I thought was odd because I pegged her at like 35.", "order": 6196, "speaker": "Michael Scott"},
  {"season": 2, "episode": 17, "scene": 41, "line": "Weird.", "order": 6197, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 17, "scene": 41, "line": "Yeah, it was weird. So, she was like 'I don't have my ID, please give me one.' And he was like 'I can't do that. I can't serve you.'", "order": 6198, "speaker": "Michael Scott"},
  {"season": 2, "episode": 17, "scene": 41, "line": "Con artist.", "order": 6199, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 17, "scene": 41, "line": "She might have been. So she says 'Fine. I will go to my room. I will get my purse. I will come back. I'll show you my ID.' She hasn't come back yet. She's probably in her room drinking from the mini-bar! Right?", "order": 6200, "speaker": "Michael Scott"},
  {"season": 2, "episode": 17, "scene": 42, "line": "Dwight gave a great speech. That's the word on the street anyway. And I entertained Dwight to no end with my bar stories. So, I captivated the guy who captivated a thousand guys. Can you believe that? A thousand guys?", "order": 6201, "speaker": "Michael Scott"},
  {"season": 2, "episode": 17, "scene": 46, "line": "All right, listen up, please. Dwight has a joke.", "order": 6202, "speaker": "Michael Scott"},
  {"season": 2, "episode": 17, "scene": 46, "line": "[whispering to Angela] Stop it. [to the office] Two sailors walk into a bar...", "order": 6203, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 17, "scene": 46, "line": "Please don't tell a sailor joke.", "order": 6204, "speaker": "Meredith Palmer"},
  {"season": 2, "episode": 17, "scene": 46, "line": "Oh, why not?", "order": 6205, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 17, "scene": 46, "line": "Her nephew's in Iraq.", "order": 6206, "speaker": "Phyllis Lapin"},
  {"season": 2, "episode": 17, "scene": 46, "line": "Iraq is sand. Sailors are on water.", "order": 6207, "speaker": "Michael Scott"},
  {"season": 2, "episode": 17, "scene": 46, "line": "Yes, but they are both in danger.", "order": 6208, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 17, "scene": 46, "line": "Yeah, well, you know what? We need to... All right, everybody in the conference room now. Let's go.", "order": 6209, "speaker": "Michael Scott"},
  {"season": 2, "episode": 17, "scene": 48, "line": "Ryan is a temp, and that means that he could go at any time. Am I worried about that? Try scared to death.", "order": 6210, "speaker": "Michael Scott"},
  {"season": 2, "episode": 17, "scene": 51, "line": "Okay. They got sports medicine seminar, nope. Science fiction convention, nope.", "order": 6211, "speaker": "Michael Scott"},
  {"season": 2, "episode": 17, "scene": 51, "line": "Oh, yeah, I read about that. The entire cast of Battlestar Galactica is gonna be here, including Starbuck. I'm so in love with her.", "order": 6212, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 17, "scene": 51, "line": "You're weird.", "order": 6213, "speaker": "Michael Scott"},
  {"season": 2, "episode": 17, "scene": 51, "line": "No, no, no. It's totally normal, 'cause she's not a Cylon or anything. She's just a great human fighter pilot. If I see her, my heart will explode.", "order": 6214, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 17, "scene": 51, "line": "Are you a 12-year-old girl? I don't know. There we go.", "order": 6215, "speaker": "Michael Scott"},
  {"season": 2, "episode": 18, "scene": 1, "line": "I'm looking forward to 'Take Your Daughter to Work' day. I am not great with kids, but I wanna get better. Because I'm getting married. So, I put out a bunch of extra candy out on my desk so the kids will come talk to me. ...Like the witch in Hanzel and Gretel.", "order": 6216, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 18, "scene": 2, "line": "Bribery. Nice.", "order": 6217, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 18, "scene": 2, "line": "Oh, I have more. [Holds up bags of candy]", "order": 6218, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 18, "scene": 2, "line": "Pam. Ms. Beasley if yer nastay! Janet Jackson. Hey! You having a wardrobe malfunction there? Or w---", "order": 6219, "speaker": "Michael Scott"},
  {"season": 2, "episode": 18, "scene": 2, "line": "Oh, Michael. You can't be nasty today. [whispering] 'Cause of the... [points to 'Welcome Daughters!' sign]", "order": 6220, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 18, "scene": 2, "line": "... Oh, God is that today?", "order": 6221, "speaker": "Michael Scott"},
  {"season": 2, "episode": 18, "scene": 2, "line": "I reminded you last night.", "order": 6222, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 18, "scene": 3, "line": "Listen, I like kids. But this is not a kid's environment. This is like HBO, no limits. Who knows what I'm going to say? Crazy stuff. And it is R rated, it is not rated G. I am like Eddie Murphy in 'Raw,' and they are trying to make me into Eddie Murphy in 'Daddy Daycare.' both great movies, but, still.", "order": 6223, "speaker": "Michael Scott"},
  {"season": 2, "episode": 18, "scene": 4, "line": "Well, I'll be in my office.", "order": 6224, "speaker": "Michael Scott"},
  {"season": 2, "episode": 18, "scene": 4, "line": "Don't you think you should say something?", "order": 6225, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 18, "scene": 4, "line": "They're cool.", "order": 6226, "speaker": "Michael Scott"},
  {"season": 2, "episode": 18, "scene": 4, "line": "Michael, I think that as the boss you should really---", "order": 6227, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 18, "scene": 4, "line": "Fine, fine, fine, fine, fine, fine, fine. Hi, children. I'm Michael Scott, and... I... am in charge of this place... ahh, what'll make you... understand... I am... like Superman, and the people who work here are like citizens of Gotham City.", "order": 6228, "speaker": "Michael Scott"},
  {"season": 2, "episode": 18, "scene": 4, "line": "[in unison] That's Batman.", "order": 6229, "speaker": "Jim Halpert & Dwight Schrute"},
  {"season": 2, "episode": 18, "scene": 4, "line": "Okay, I'm Aquaman. Where does he live, guys?", "order": 6230, "speaker": "Michael Scott"},
  {"season": 2, "episode": 18, "scene": 4, "line": "The ocean.", "order": 6231, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 18, "scene": 4, "line": "[under his breath] I work with a bunch of nerds.", "order": 6232, "speaker": "Michael Scott"},
  {"season": 2, "episode": 18, "scene": 5, "line": "[looks at Sasha] Mmm... hello tiny one.", "order": 6233, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 18, "scene": 5, "line": "[to Sasha] Come on.", "order": 6234, "speaker": "Toby Flenderson"},
  {"season": 2, "episode": 18, "scene": 5, "line": "You are the future!", "order": 6235, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 18, "scene": 6, "line": "This... is my file cabinet. Uhm... oh. This... is the partition... between my desk... and Angela's.", "order": 6236, "speaker": "Kevin Malone"},
  {"season": 2, "episode": 18, "scene": 7, "line": "Abby's my fiancee Stacy's daughter, I think she'll have a good time. I just hope she doesn't look on my computer. ...Actually, I'd better go check.", "order": 6237, "speaker": "Kevin Malone"},
  {"season": 2, "episode": 18, "scene": 8, "line": "Michael, you remember my daughter, Melissa.", "order": 6238, "speaker": "Stanley Hudson"},
  {"season": 2, "episode": 18, "scene": 8, "line": "Oh, yes, hello, how are you? Good to see you. Wow, you've really grown up. You know what? Don't mind me saying so, she is turning into a stone cold fox. Better keep the... frat boys away from her.", "order": 6239, "speaker": "Michael Scott"},
  {"season": 2, "episode": 18, "scene": 8, "line": "I'm in eighth grade.", "order": 6240, "speaker": "Melissa"},
  {"season": 2, "episode": 18, "scene": 8, "line": "Oh.", "order": 6241, "speaker": "Michael Scott"},
  {"season": 2, "episode": 18, "scene": 8, "line": "She's in middle school.", "order": 6242, "speaker": "Stanley Hudson"},
  {"season": 2, "episode": 18, "scene": 8, "line": "Yeah, middle school's amazing. It is extraordinary. An extraordinary time.", "order": 6243, "speaker": "Michael Scott"},
  {"season": 2, "episode": 18, "scene": 9, "line": "It's not that children make me uncomfortable, it's just that, why be a dad when you can be a fun uncle? I've never heard of anyone rebelling against their fun uncle.", "order": 6244, "speaker": "Michael Scott"},
  {"season": 2, "episode": 18, "scene": 10, "line": "[while Jake is throwing things at Michael] They want how many spiral pads?", "order": 6245, "speaker": "Michael Scott"},
  {"season": 2, "episode": 18, "scene": 10, "line": "Um, fif--well, fifty... I... over ordered because they had a back order.", "order": 6246, "speaker": "Meredith Palmer"},
  {"season": 2, "episode": 18, "scene": 10, "line": "Okay.", "order": 6247, "speaker": "Michael Scott"},
  {"season": 2, "episode": 18, "scene": 11, "line": "I got permission to bring Jake into work, which is great because he got suspended this week and now I don't have to pay for a sitter.", "order": 6248, "speaker": "Meredith Palmer"},
  {"season": 2, "episode": 18, "scene": 12, "line": "Can you put that down there?", "order": 6249, "speaker": "Angela Martin"},
  {"season": 2, "episode": 18, "scene": 12, "line": "Yep. [spreads tablecloth]", "order": 6250, "speaker": "Kelly Kapoor"},
  {"season": 2, "episode": 18, "scene": 12, "line": "[to Sasha] Okay, tell them what you wanted to say.", "order": 6251, "speaker": "Toby Flenderson"},
  {"season": 2, "episode": 18, "scene": 12, "line": "Do you need any help?", "order": 6252, "speaker": "Sasha"},
  {"season": 2, "episode": 18, "scene": 12, "line": "No. Thanks. We'd... have to explain everything, it's probably just easier if we do it ourselves.", "order": 6253, "speaker": "Angela Martin"},
  {"season": 2, "episode": 18, "scene": 12, "line": "Alright, I wasn't expecting that. Let's uh... let's go draw.", "order": 6254, "speaker": "Toby Flenderson"},
  {"season": 2, "episode": 18, "scene": 12, "line": "Oh my God, she is so cute, I want to die. Don't you just love kids, Angela?", "order": 6255, "speaker": "Kelly Kapoor"},
  {"season": 2, "episode": 18, "scene": 12, "line": "I guess I wouldn't mind a pair of small, well-behaved boys.", "order": 6256, "speaker": "Angela Martin"},
  {"season": 2, "episode": 18, "scene": 12, "line": "God I cannot wait to get pregnant and have babies!", "order": 6257, "speaker": "Kelly Kapoor"},
  {"season": 2, "episode": 18, "scene": 13, "line": "Kelly and I both agreed that we would just have fun, and, I'm learning that fun for Kelly is... getting married and having babies. Immediately. With me.", "order": 6258, "speaker": "Ryan Howard"},
  {"season": 2, "episode": 18, "scene": 14, "line": "[on phone] Just compare last year's order to this year's. Uh-huh. Yeah, I'm looking at it right now. [Sasha walks in the door] ... Yes. We--yeah, they're very--they're different. [Sasha walks out] Yeah, we can stick with last year's, you're just going to have to supplement it, somehow.", "order": 6259, "speaker": "Michael Scott"},
  {"season": 2, "episode": 18, "scene": 15, "line": "Hey, Abby! Do you want to help me shred some old documents? It's actually pretty cool.", "order": 6260, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 18, "scene": 15, "line": "No thanks.", "order": 6261, "speaker": "Abby"},
  {"season": 2, "episode": 18, "scene": 16, "line": "I only have one goal today. To make one kid like me. Just one.", "order": 6262, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 18, "scene": 17, "line": "What are you reading?", "order": 6263, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 18, "scene": 17, "line": "From the Mixed-Up Files of Mrs. Basil E. Frankweiler.", "order": 6264, "speaker": "Abby"},
  {"season": 2, "episode": 18, "scene": 17, "line": "Aww, best book?", "order": 6265, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 18, "scene": 17, "line": "Yeah, but I've read it before.", "order": 6266, "speaker": "Abby"},
  {"season": 2, "episode": 18, "scene": 17, "line": "Pfft. So have I. Hey, question. If you had to spend a night in the Met or the Aquarium, which would it be?", "order": 6267, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 18, "scene": 17, "line": "Definitely the Aquarium.", "order": 6268, "speaker": "Abby"},
  {"season": 2, "episode": 18, "scene": 17, "line": "Definitely. Yes. Glad you said that. ...You don't want to help me with some of my sales, do you? 'Cause, I'm kind of swamped.", "order": 6269, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 18, "scene": 17, "line": "Sure.", "order": 6270, "speaker": "Abby"},
  {"season": 2, "episode": 18, "scene": 17, "line": "Really?", "order": 6271, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 18, "scene": 17, "line": "Mmhmm!", "order": 6272, "speaker": "Abby"},
  {"season": 2, "episode": 18, "scene": 17, "line": "Yesss. And you're Abby, right?", "order": 6273, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 18, "scene": 17, "line": "Yeah.", "order": 6274, "speaker": "Abby"},
  {"season": 2, "episode": 18, "scene": 17, "line": "I'm Jim. [Jim hi-fives Abby] Annnnd... let's sell some paper.", "order": 6275, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 18, "scene": 17, "line": "Alright.", "order": 6276, "speaker": "Abby"},
  {"season": 2, "episode": 18, "scene": 17, "line": "Let's start with... your mom.", "order": 6277, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 18, "scene": 18, "line": "[on phone] Yes. Well... we can... [Sasha walks in, begins playing with Michael's toy train] uhm... hey, uh, you know what? Can I call you back? I'll call you right back. Yes, I promise. ...Hello, can I help you? ... You can pick that up, if you want. That's--- that's alright. [Sasha moves the train to Michael's desk] Want to bring it over... here, make some room. My name's Michael. What's your name?", "order": 6278, "speaker": "Michael Scott"},
  {"season": 2, "episode": 18, "scene": 18, "line": "Sasha.", "order": 6279, "speaker": "Sasha"},
  {"season": 2, "episode": 18, "scene": 18, "line": "Nice to meet you.", "order": 6280, "speaker": "Michael Scott"},
  {"season": 2, "episode": 18, "scene": 18, "line": "Ooh! [picks up train whistle]", "order": 6281, "speaker": "Sasha"},
  {"season": 2, "episode": 18, "scene": 18, "line": "Oh, you know what that is! That is a train whistle, like I'm the conductor. [blows into whistle] But I'm sort of the conductor of the office here, right? [blows into whistle] You want to try?", "order": 6282, "speaker": "Michael Scott"},
  {"season": 2, "episode": 18, "scene": 18, "line": "Sure. [Sasha blows into whistle continously]", "order": 6283, "speaker": "Sasha"},
  {"season": 2, "episode": 18, "scene": 18, "line": "All aboard for sales! Next stop, Cu...camonga! [Sasha and Michael laugh]", "order": 6284, "speaker": "Michael Scott"},
  {"season": 2, "episode": 18, "scene": 19, "line": "[shaking hands with Abby] Ow, ow, ow, ow, you broke my hand.", "order": 6285, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 18, "scene": 19, "line": "There is no way that hurt.", "order": 6286, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 18, "scene": 19, "line": "Really? 'Cause she's pretty strong, Dwight.", "order": 6287, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 18, "scene": 19, "line": "Little girl. Come over here. Shake my hand. Come on, I don't have all day. [Abby shakes his hand] I don't feel anything. Nothing. [to Jim] You're so weak. [Jake walks over and messes with Dwight's bobbleheads] Uh, excuse me, these are expensive collector's items, okay?", "order": 6288, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 18, "scene": 19, "line": "Do you have any computer games?", "order": 6289, "speaker": "Jake"},
  {"season": 2, "episode": 18, "scene": 19, "line": "No, I don't have computer games on my work computer. That would be innappropriate.", "order": 6290, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 18, "scene": 19, "line": "Yeah, Meredith doesn't have any either. It's so lame here.", "order": 6291, "speaker": "Jake"},
  {"season": 2, "episode": 18, "scene": 19, "line": "You call your mom Meredith? That's very disrespectful.", "order": 6292, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 18, "scene": 19, "line": "Whatever, okay?", "order": 6293, "speaker": "Jake"},
  {"season": 2, "episode": 18, "scene": 19, "line": "You can refer to me as Mister Schrute.", "order": 6294, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 18, "scene": 19, "line": "That's your name? Mister Poop?", "order": 6295, "speaker": "Jake"},
  {"season": 2, "episode": 18, "scene": 19, "line": "Schrute. Mister Schrute.", "order": 6296, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 18, "scene": 19, "line": "Sure, Mister Poop. [Jake walks away]", "order": 6297, "speaker": "Jake"},
  {"season": 2, "episode": 18, "scene": 19, "line": "[quietly] ... Schrute. [Jim and Abby snicker, Angela glares at Dwight]", "order": 6298, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 18, "scene": 20, "line": "[to Phyllis] Are you Mother Goose?", "order": 6299, "speaker": "Sasha"},
  {"season": 2, "episode": 18, "scene": 21, "line": "I drink like, a hundred Ice Macchiatos a day, and practically nothing else.", "order": 6300, "speaker": "Melissa"},
  {"season": 2, "episode": 18, "scene": 21, "line": "Wow.", "order": 6301, "speaker": "Ryan Howard"},
  {"season": 2, "episode": 18, "scene": 21, "line": "There's a really cool coffee place, Jitters, at the Steamtown Mall. Ever been there?", "order": 6302, "speaker": "Melissa"},
  {"season": 2, "episode": 18, "scene": 21, "line": "No.", "order": 6303, "speaker": "Ryan Howard"},
  {"season": 2, "episode": 18, "scene": 21, "line": "You've never been to Jitters? Ryan, you are", "order": 6304, "speaker": "Melissa"},
  {"season": 2, "episode": 18, "scene": 21, "line": "Uhm...", "order": 6305, "speaker": "Ryan Howard"},
  {"season": 2, "episode": 18, "scene": 21, "line": "Come on! [Kelly glares through the door] You have an email address?", "order": 6306, "speaker": "Melissa"},
  {"season": 2, "episode": 18, "scene": 22, "line": "...that I thought you should know ...", "order": 6307, "speaker": "Kelly Kapoor"},
  {"season": 2, "episode": 18, "scene": 22, "line": "Mmhmm. What?", "order": 6308, "speaker": "Stanley Hudson"},
  {"season": 2, "episode": 18, "scene": 22, "line": "I think something a little fishy is going on. [points to Ryan and Melissa]", "order": 6309, "speaker": "Kelly Kapoor"},
  {"season": 2, "episode": 18, "scene": 22, "line": "A little fishy?", "order": 6310, "speaker": "Stanley Hudson"},
  {"season": 2, "episode": 18, "scene": 22, "line": "Yeah. I mean, I've been noticing them all day, I was thinking that maybe ... [Stanley gets up]", "order": 6311, "speaker": "Kelly Kapoor"},
  {"season": 2, "episode": 18, "scene": 23, "line": "That little girl is a child! I don't want to see you sniffing around her anymore this afternoon, do you understand?!", "order": 6312, "speaker": "Stanley Hudson"},
  {"season": 2, "episode": 18, "scene": 23, "line": "Yes, I--", "order": 6313, "speaker": "Ryan Howard"},
  {"season": 2, "episode": 18, "scene": 23, "line": "Boy have you lost your mind? 'Cause I'll help you find it! Whatcha lookin' for, ain't nobody gonna help you out there! Jesus could come through that door and he's not gonna help you if you don't stop sniffing after my child!", "order": 6314, "speaker": "Stanley Hudson"},
  {"season": 2, "episode": 18, "scene": 23, "line": "Okay.", "order": 6315, "speaker": "Ryan Howard"},
  {"season": 2, "episode": 18, "scene": 24, "line": "Stanley yelled at me today. That was one of the most frightening experiences of my life.", "order": 6316, "speaker": "Ryan Howard"},
  {"season": 2, "episode": 18, "scene": 25, "line": "[plays the recorder] That was Greensleeves. A traditional English Ballad about the beheaded Anne Boleyn. And now, a very special treat... a book my Grandmutter used to read me when I was a kid. This is a very special story, it's called Struwwelpeter, by Heinrich Hoffman from 1864. [reading from book] The great tall tailor always comes to little girls that suck their thumbs--- are you listening, Sasha? Right? And 'ere they dream when he's about, he takes his great sharp scissors out, and then cuts their thumbs clean off!", "order": 6317, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 18, "scene": 25, "line": "Dwight! Dwight!", "order": 6318, "speaker": "Michael Scott"},
  {"season": 2, "episode": 18, "scene": 25, "line": "There's a photo...", "order": 6319, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 18, "scene": 25, "line": "What the hell are you reading to them?", "order": 6320, "speaker": "Michael Scott"},
  {"season": 2, "episode": 18, "scene": 25, "line": "These are cautionary tales for kids, my Grandmata used to read these---", "order": 6321, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 18, "scene": 25, "line": "Yeah, you know what? No, no no no no. They, no. The kids don't want to hear some wierdo book that your Nazi war criminal grandmother gave you.", "order": 6322, "speaker": "Michael Scott"},
  {"season": 2, "episode": 18, "scene": 25, "line": "What's a Nazi?", "order": 6323, "speaker": "Sasha"},
  {"season": 2, "episode": 18, "scene": 25, "line": "What's a Nazi?", "order": 6324, "speaker": "Michael Scott"},
  {"season": 2, "episode": 18, "scene": 25, "line": "[standing up] Nazi was a fascist movement...", "order": 6325, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 18, "scene": 25, "line": "Don't!", "order": 6326, "speaker": "Michael Scott"},
  {"season": 2, "episode": 18, "scene": 25, "line": "...from the 1930's...", "order": 6327, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 18, "scene": 25, "line": "Don't! Don't! Don't talk about Nazis in front of--- you know what? They're going to have nightmares, so why don't you just shut it?", "order": 6328, "speaker": "Michael Scott"},
  {"season": 2, "episode": 18, "scene": 25, "line": "I was gonna teach the children how to make corn-husk dolls.", "order": 6329, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 18, "scene": 25, "line": "[sighing] Why don't you just leave? Okay?", "order": 6330, "speaker": "Michael Scott"},
  {"season": 2, "episode": 18, "scene": 25, "line": "...Okay.", "order": 6331, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 18, "scene": 25, "line": "Bye, Mister Poop.", "order": 6332, "speaker": "Jake"},
  {"season": 2, "episode": 18, "scene": 25, "line": "Alright. There goes Mister Poop. Now, who likes Dane Cook?", "order": 6333, "speaker": "Michael Scott"},
  {"season": 2, "episode": 18, "scene": 25, "line": "[raising hands] I do, I do!", "order": 6334, "speaker": "The Kids"},
  {"season": 2, "episode": 18, "scene": 26, "line": "Children cannot lie. They are innocent, and they speak the truth, and out of the mouths of babes, Michael Scott is freaking cool. [cracks up]", "order": 6335, "speaker": "Michael Scott"},
  {"season": 2, "episode": 18, "scene": 27, "line": "You know, I never misbehaved in front of my father because he was a very strict disciplinarian. I can only hope my mate has some of those same qualities [makes eye contact with Dwight].", "order": 6336, "speaker": "Angela Martin"},
  {"season": 2, "episode": 18, "scene": 28, "line": "This is where the magic happens! Right over here, let me show you this. See all these? [pets shelf of paper] You know what that is? That's paper. This is where paper comes from. Any questions?", "order": 6337, "speaker": "Michael Scott"},
  {"season": 2, "episode": 18, "scene": 28, "line": "So... you cut the paper and dye it and stuff.", "order": 6338, "speaker": "Melissa"},
  {"season": 2, "episode": 18, "scene": 28, "line": "No, we don't actually cut the paper. That's a good question. The paper is sent to us cut, and dyed, from a paper manufacturer, and then we sell it to a business for more than we paid for it.", "order": 6339, "speaker": "Michael Scott"},
  {"season": 2, "episode": 18, "scene": 28, "line": "That's not fair. [the rest of the kids agree]", "order": 6340, "speaker": "Abby"},
  {"season": 2, "episode": 18, "scene": 28, "line": "Yes it is, well, w-w--you need someone in the middle to facilitate...", "order": 6341, "speaker": "Michael Scott"},
  {"season": 2, "episode": 18, "scene": 28, "line": "You're just a middleman.", "order": 6342, "speaker": "Jake"},
  {"season": 2, "episode": 18, "scene": 28, "line": "I'm not just a middle... man...", "order": 6343, "speaker": "Michael Scott"},
  {"season": 2, "episode": 18, "scene": 28, "line": "Wait, why doesn't the saw mill just sell paper directly to people?", "order": 6344, "speaker": "Melissa"},
  {"season": 2, "episode": 18, "scene": 28, "line": "You are describing Office Depot, and they're kind of running us out of business.", "order": 6345, "speaker": "Michael Scott"},
  {"season": 2, "episode": 18, "scene": 28, "line": "We have better service than they do!", "order": 6346, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 18, "scene": 28, "line": "...There's Creed! Let's take a look at what he's doing, everybody! This is Creed, and he is in charge of... something. Right?", "order": 6347, "speaker": "Michael Scott"},
  {"season": 2, "episode": 18, "scene": 28, "line": "That is correct.", "order": 6348, "speaker": "Creed Bratton"},
  {"season": 2, "episode": 18, "scene": 28, "line": "Say hi to the kids.", "order": 6349, "speaker": "Michael Scott"},
  {"season": 2, "episode": 18, "scene": 28, "line": "Hi kids.", "order": 6350, "speaker": "Creed Bratton"},
  {"season": 2, "episode": 18, "scene": 28, "line": "Yaaaaay.", "order": 6351, "speaker": "Michael Scott"},
  {"season": 2, "episode": 18, "scene": 28, "line": "Have you ever seen a foot with four toes? [begins untying shoe]", "order": 6352, "speaker": "Creed Bratton"},
  {"season": 2, "episode": 18, "scene": 28, "line": "Ewwww!", "order": 6353, "speaker": "Kids"},
  {"season": 2, "episode": 18, "scene": 28, "line": "What are you doing? N--stop it! Stop it! Just--no, no, no, no! No! Would you cut it out?! What is your problem?", "order": 6354, "speaker": "Michael Scott"},
  {"season": 2, "episode": 18, "scene": 28, "line": "Th-the hair covers it, mostly.", "order": 6355, "speaker": "Creed Bratton"},
  {"season": 2, "episode": 18, "scene": 28, "line": "No no no, we're not gonna see--- we're not gonna see the four toed... Creed, okay?", "order": 6356, "speaker": "Michael Scott"},
  {"season": 2, "episode": 18, "scene": 29, "line": "You know, there's something interesting about me you might want to know. I ... used to be ... the star of a kids show.", "order": 6357, "speaker": "Michael Scott"},
  {"season": 2, "episode": 18, "scene": 29, "line": "No way.", "order": 6358, "speaker": "Kids"},
  {"season": 2, "episode": 18, "scene": 29, "line": "It's true. I did.", "order": 6359, "speaker": "Michael Scott"},
  {"season": 2, "episode": 18, "scene": 29, "line": "You serious?", "order": 6360, "speaker": "Melissa"},
  {"season": 2, "episode": 18, "scene": 29, "line": "Really?", "order": 6361, "speaker": "Jake"},
  {"season": 2, "episode": 18, "scene": 29, "line": "I am totally serious. There was a show called 'Fundle Bundle' and I was the star.", "order": 6362, "speaker": "Michael Scott"},
  {"season": 2, "episode": 18, "scene": 29, "line": "That doesn't sound like a show.", "order": 6363, "speaker": "Abby"},
  {"season": 2, "episode": 18, "scene": 29, "line": "What?!", "order": 6364, "speaker": "Melissa"},
  {"season": 2, "episode": 18, "scene": 29, "line": "It's true! I can prove it! I can prove it, watch this. [gets up and runs out] Ryan, can you come here a second? [clears throat] I would like you to go to my mother's house in Dickson city, and if she is in the pool, the back kitchen window should be unlocked, I want you to boost yourself up, I want you to go down to the basement. In the basement is a tape labeled 'Fundle Bundle'. I want you to grab it, I want you to get my guitar.", "order": 6365, "speaker": "Michael Scott"},
  {"season": 2, "episode": 18, "scene": 29, "line": "Right. Okay.", "order": 6366, "speaker": "Ryan Howard"},
  {"season": 2, "episode": 18, "scene": 29, "line": "I want you to get the tambourine. Do you know how to play the tambourine?", "order": 6367, "speaker": "Michael Scott"},
  {"season": 2, "episode": 18, "scene": 29, "line": "Um, I'm already getting the pizzas from Bernetti's, so...", "order": 6368, "speaker": "Ryan Howard"},
  {"season": 2, "episode": 18, "scene": 29, "line": "You know, I can go with him.", "order": 6369, "speaker": "Melissa"},
  {"season": 2, "episode": 18, "scene": 29, "line": "Oka--", "order": 6370, "speaker": "Michael Scott"},
  {"season": 2, "episode": 18, "scene": 29, "line": "No! I will... go.", "order": 6371, "speaker": "Ryan Howard"},
  {"season": 2, "episode": 18, "scene": 29, "line": "Okay! Thank you Ryan. Good attitude, hottest in the office.", "order": 6372, "speaker": "Michael Scott"},
  {"season": 2, "episode": 18, "scene": 30, "line": "[to Abby] Alright, nowwww... what kind... of pizza do you like?", "order": 6373, "speaker": "Michael Scott"},
  {"season": 2, "episode": 18, "scene": 31, "line": "I don't get why parents are always complaining about how tough it is to raise kids. You joke around with them, you give them pizza, you give them candy, you let them live their lives... They're adults, for God's sake.", "order": 6374, "speaker": "Michael Scott"},
  {"season": 2, "episode": 18, "scene": 32, "line": "I am going to give you a little blast from the past of Michael Gary Scott when he was a child star, and a show that you might remember called 'Fundle Bundle.' Okay? Without further ado, Ryan?", "order": 6375, "speaker": "Michael Scott"},
  {"season": 2, "episode": 18, "scene": 32, "line": "[from TV] ...Bundle, are you ready to come on in? [TV children cheer] Let's have some fun!", "order": 6376, "speaker": "Miss Trudy"},
  {"season": 2, "episode": 18, "scene": 32, "line": "That... is Miss Trudy. Can't tell from the costume, but she had an amazing body. Okay, you can... fast forward. And... I want you... to...", "order": 6377, "speaker": "Michael Scott"},
  {"season": 2, "episode": 18, "scene": 32, "line": "Is that a real fun shooting windmill?", "order": 6378, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 18, "scene": 32, "line": "Stop! Stop! Stop! [Ryan resumes the tape] Yes! That is, uh, Edward R. Meow.", "order": 6379, "speaker": "Michael Scott"},
  {"season": 2, "episode": 18, "scene": 32, "line": "That's pretty funny.", "order": 6380, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 18, "scene": 32, "line": "Yeah.", "order": 6381, "speaker": "Michael Scott"},
  {"season": 2, "episode": 18, "scene": 32, "line": "...Recess! Hey, what's your name?", "order": 6382, "speaker": "Edward R. Meow"},
  {"season": 2, "episode": 18, "scene": 32, "line": "My name's Chet.", "order": 6383, "speaker": "Chet"},
  {"season": 2, "episode": 18, "scene": 32, "line": "Well hi Chet.", "order": 6384, "speaker": "Edward R. Meow"},
  {"season": 2, "episode": 18, "scene": 32, "line": "Is that Chet Montgomery?", "order": 6385, "speaker": "Oscar Martinez"},
  {"season": 2, "episode": 18, "scene": 32, "line": "Uhh, I don't know.", "order": 6386, "speaker": "Michael Scott"},
  {"season": 2, "episode": 18, "scene": 32, "line": "That is!", "order": 6387, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 18, "scene": 32, "line": "Checkin' in with Chet. Doppler's up.", "order": 6388, "speaker": "Darryl Philbin"},
  {"season": 2, "episode": 18, "scene": 32, "line": "What do you want to be when you grow up?", "order": 6389, "speaker": "Edward R. Meow"},
  {"season": 2, "episode": 18, "scene": 32, "line": "I want to be on TV!", "order": 6390, "speaker": "Chet"},
  {"season": 2, "episode": 18, "scene": 32, "line": "[employees chuckle] And he", "order": 6391, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 18, "scene": 32, "line": "Can everyone please shut up, please! So you don't miss it.", "order": 6392, "speaker": "Michael Scott"},
  {"season": 2, "episode": 18, "scene": 32, "line": "Okay, next? So, whats your name?", "order": 6393, "speaker": "Edward R. Meow"},
  {"season": 2, "episode": 18, "scene": 32, "line": "Oh! That's me. Shh. Shh.", "order": 6394, "speaker": "Michael Scott"},
  {"season": 2, "episode": 18, "scene": 32, "line": "Well what's your favorite subject at school?", "order": 6395, "speaker": "Edward R. Meow"},
  {"season": 2, "episode": 18, "scene": 32, "line": "Recess.", "order": 6396, "speaker": "Young Michael Scott"},
  {"season": 2, "episode": 18, "scene": 32, "line": "Ree-cesss. So tell me, what do you want to be when you grow up?", "order": 6397, "speaker": "Edward R. Meow"},
  {"season": 2, "episode": 18, "scene": 32, "line": "I want to be married and have a hundred kids so I can have a hundred friends, and no one can say no to being my friend.", "order": 6398, "speaker": "Young Michael Scott"},
  {"season": 2, "episode": 18, "scene": 32, "line": "[jaw drops, awkward pause] Uh, ah... oh, okay! Well uh, nice talking with you, Michael. Uh, back to you Miss Trudy!", "order": 6399, "speaker": "Edward R. Meow"},
  {"season": 2, "episode": 18, "scene": 32, "line": "Hi everyone, it's one of my favorite times of the day.", "order": 6400, "speaker": "Miss Trudy"},
  {"season": 2, "episode": 18, "scene": 32, "line": "Coulda sworn there was...", "order": 6401, "speaker": "Michael Scott"},
  {"season": 2, "episode": 18, "scene": 32, "line": "Did you get married?", "order": 6402, "speaker": "Melissa"},
  {"season": 2, "episode": 18, "scene": 32, "line": "...uh, no.", "order": 6403, "speaker": "Michael Scott"},
  {"season": 2, "episode": 18, "scene": 32, "line": "Why not?", "order": 6404, "speaker": "Abby"},
  {"season": 2, "episode": 18, "scene": 32, "line": "Uh, just never happened.", "order": 6405, "speaker": "Michael Scott"},
  {"season": 2, "episode": 18, "scene": 32, "line": "So, do you have any kids?", "order": 6406, "speaker": "Sasha"},
  {"season": 2, "episode": 18, "scene": 32, "line": "Uh, nope.", "order": 6407, "speaker": "Michael Scott"},
  {"season": 2, "episode": 18, "scene": 32, "line": "Do you have a girlfriend?", "order": 6408, "speaker": "Jake"},
  {"season": 2, "episode": 18, "scene": 32, "line": "I do okay.", "order": 6409, "speaker": "Michael Scott"},
  {"season": 2, "episode": 18, "scene": 32, "line": "Was Chet Montgomery cool back then?", "order": 6410, "speaker": "Melissa"},
  {"season": 2, "episode": 18, "scene": 32, "line": "Yes.", "order": 6411, "speaker": "Michael Scott"},
  {"season": 2, "episode": 18, "scene": 32, "line": "Even I have a girlfriend.", "order": 6412, "speaker": "Jake"},
  {"season": 2, "episode": 18, "scene": 32, "line": "Okay! Alright, okay.", "order": 6413, "speaker": "Michael Scott"},
  {"season": 2, "episode": 18, "scene": 32, "line": "So you didn't get to be what you wanted to be.", "order": 6414, "speaker": "Sasha"},
  {"season": 2, "episode": 18, "scene": 32, "line": "...I guess not... you know, I have a load of work to do so I am going to grab a slice of this delish pizza... and I'm going to go do my work. Bye.", "order": 6415, "speaker": "Michael Scott"},
  {"season": 2, "episode": 18, "scene": 33, "line": "He's not coming out. He won't pick up the phone.", "order": 6416, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 18, "scene": 33, "line": "Can't believe his mom dressed him like that, that's the real tragedy.", "order": 6417, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 18, "scene": 33, "line": "[wrestling with Jake] Pam! Pam! I love this guy! [laughs] Come on!", "order": 6418, "speaker": "Roy Anderson"},
  {"season": 2, "episode": 18, "scene": 34, "line": "So, Melissa... I met your mom a couple times. She's", "order": 6419, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 18, "scene": 34, "line": "Who? Terry?", "order": 6420, "speaker": "Melissa"},
  {"season": 2, "episode": 18, "scene": 34, "line": "Mmhmm.", "order": 6421, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 18, "scene": 34, "line": "That woman is not my mother. That is my step-mother.", "order": 6422, "speaker": "Melissa"},
  {"season": 2, "episode": 18, "scene": 35, "line": "Mister Poop, I have to tell you something.", "order": 6423, "speaker": "Jake"},
  {"season": 2, "episode": 18, "scene": 35, "line": "Uh, okay. But first, that's not my name.", "order": 6424, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 18, "scene": 35, "line": "You're ugly.", "order": 6425, "speaker": "Jake"},
  {"season": 2, "episode": 18, "scene": 35, "line": "Well at least I'm not a horrible little latchkey kid who got suspended from school. So...", "order": 6426, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 18, "scene": 35, "line": "Meredith!", "order": 6427, "speaker": "Jake"},
  {"season": 2, "episode": 18, "scene": 36, "line": "[Toby knocks on door] Yeah?", "order": 6428, "speaker": "Michael Scott"},
  {"season": 2, "episode": 18, "scene": 36, "line": "I think these belong to you. [puts toys down on desk]", "order": 6429, "speaker": "Toby Flenderson"},
  {"season": 2, "episode": 18, "scene": 36, "line": "Oh, that's okay, she can keep those.", "order": 6430, "speaker": "Michael Scott"},
  {"season": 2, "episode": 18, "scene": 36, "line": "Believe me, she has enough toys... she doesn't need your watch.", "order": 6431, "speaker": "Toby Flenderson"},
  {"season": 2, "episode": 18, "scene": 36, "line": "Thank you.", "order": 6432, "speaker": "Michael Scott"},
  {"season": 2, "episode": 18, "scene": 36, "line": "Is everything okay?", "order": 6433, "speaker": "Toby Flenderson"},
  {"season": 2, "episode": 18, "scene": 36, "line": "You have to ask me that because you work for human resources.", "order": 6434, "speaker": "Michael Scott"},
  {"season": 2, "episode": 18, "scene": 36, "line": "Uh... it's true...", "order": 6435, "speaker": "Toby Flenderson"},
  {"season": 2, "episode": 18, "scene": 37, "line": "Well, sure, playing the field is great, don't get me wrong, but there's more to life than notches just on my bedpost.", "order": 6436, "speaker": "Michael Scott"},
  {"season": 2, "episode": 18, "scene": 37, "line": "Mmhmm.", "order": 6437, "speaker": "Toby Flenderson"},
  {"season": 2, "episode": 18, "scene": 37, "line": "Tell me something honestly, do you... think... that it is too late for me to have kids?", "order": 6438, "speaker": "Michael Scott"},
  {"season": 2, "episode": 18, "scene": 37, "line": "Well, you need a wife first, or at least a girlfriend.", "order": 6439, "speaker": "Toby Flenderson"},
  {"season": 2, "episode": 18, "scene": 37, "line": "What about...", "order": 6440, "speaker": "Michael Scott"},
  {"season": 2, "episode": 18, "scene": 37, "line": "Not Jan.", "order": 6441, "speaker": "Toby Flenderson"},
  {"season": 2, "episode": 18, "scene": 37, "line": "...Jan. Kay.", "order": 6442, "speaker": "Michael Scott"},
  {"season": 2, "episode": 18, "scene": 37, "line": "If you really want to have kids, I--- I guess you could somehow... foster parent, or something.", "order": 6443, "speaker": "Toby Flenderson"},
  {"season": 2, "episode": 18, "scene": 37, "line": "...Or biologically.", "order": 6444, "speaker": "Michael Scott"},
  {"season": 2, "episode": 18, "scene": 37, "line": "Somehow.", "order": 6445, "speaker": "Toby Flenderson"},
  {"season": 2, "episode": 18, "scene": 37, "line": "Thanks, that's, no, that... that really means a lot to me. Hey, does Sasha have a godfather, because I...", "order": 6446, "speaker": "Michael Scott"},
  {"season": 2, "episode": 18, "scene": 37, "line": "Yes.", "order": 6447, "speaker": "Toby Flenderson"},
  {"season": 2, "episode": 18, "scene": 37, "line": "Oh... kay.", "order": 6448, "speaker": "Michael Scott"},
  {"season": 2, "episode": 18, "scene": 38, "line": "Is it okay if I take one?", "order": 6449, "speaker": "Jake"},
  {"season": 2, "episode": 18, "scene": 38, "line": "Sure.", "order": 6450, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 18, "scene": 38, "line": "Thank you.", "order": 6451, "speaker": "Jake"},
  {"season": 2, "episode": 18, "scene": 38, "line": "You're welcome.", "order": 6452, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 18, "scene": 38, "line": "Is your job hard?", "order": 6453, "speaker": "Jake"},
  {"season": 2, "episode": 18, "scene": 38, "line": "It's not too bad. I get to shred things sometimes, do you want to see?", "order": 6454, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 18, "scene": 38, "line": "Yeah!", "order": 6455, "speaker": "Jake"},
  {"season": 2, "episode": 18, "scene": 38, "line": "Really?", "order": 6456, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 18, "scene": 38, "line": "Yeah.", "order": 6457, "speaker": "Jake"},
  {"season": 2, "episode": 18, "scene": 38, "line": "Okay. Um... here it is. Don't put your fingers in there. [shreds paper] Cool huh?", "order": 6458, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 18, "scene": 38, "line": "That's so cool, yeah!", "order": 6459, "speaker": "Jake"},
  {"season": 2, "episode": 18, "scene": 38, "line": "Yeah, I get to do this like, every week.", "order": 6460, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 18, "scene": 38, "line": "That's so awesome!", "order": 6461, "speaker": "Jake"},
  {"season": 2, "episode": 18, "scene": 38, "line": "I know.", "order": 6462, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 18, "scene": 39, "line": "Yes, it is true. I, Michael Scott, am signing up with an online dating service. Thousands of people have done it, and I am going to do it. I need a username. And... I have a great one [types]. Little kid lover. That way, people will know exactly where my priorities are at.", "order": 6463, "speaker": "Michael Scott"},
  {"season": 2, "episode": 18, "scene": 40, "line": "Go ahead.", "order": 6464, "speaker": "Kevin Malone"},
  {"season": 2, "episode": 18, "scene": 40, "line": "Do you want to come over for dinner tonight?", "order": 6465, "speaker": "Abby"},
  {"season": 2, "episode": 18, "scene": 40, "line": "Ohh, man, I would love to! I can't tonight, but can I come over some other time? [Abby nods]", "order": 6466, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 18, "scene": 40, "line": "What're you doing? You never have plans.", "order": 6467, "speaker": "Kevin Malone"},
  {"season": 2, "episode": 18, "scene": 40, "line": "Thanks, Kev. Uhm... I'm actually going on a date.", "order": 6468, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 18, "scene": 40, "line": "Niiice.", "order": 6469, "speaker": "Kevin Malone"},
  {"season": 2, "episode": 18, "scene": 40, "line": "Hey, uh, no, please? You can't leave yet. There's still one more thing we need to do.", "order": 6470, "speaker": "Michael Scott"},
  {"season": 2, "episode": 18, "scene": 40, "line": "[singing] You... who are on the road... must have a code... that you can live by... [Dwight joins in] and so... become yourself... because the past... is just a goodbye... and teach... your children well...", "order": 6471, "speaker": "Michael Scott"},
  {"season": 2, "episode": 18, "scene": 40, "line": "Why does he own a guitar if he doesn't know how to play?", "order": 6472, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 18, "scene": 40, "line": "I think he thought his ukulele skills would transfer. [Jim leaves]", "order": 6473, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 18, "scene": 40, "line": "[singing] ...did slowly go by... and feed... them on your dreams...", "order": 6474, "speaker": "Michael Scott & Dwight Schrute"},
  {"season": 2, "episode": 18, "scene": 40, "line": "My theory is that... [Jim signals he's leaving, waves bye to Pam]", "order": 6475, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 18, "scene": 40, "line": "[singing] ...The one they picked... the one you'll know by... don't you ever ask them why... if I told you would cry... you never look at them and sigh... and know they love you...", "order": 6476, "speaker": "Michael Scott & Dwight Schrute"},
  {"season": 2, "episode": 18, "scene": 40, "line": "And they do, your parents, love you very much.", "order": 6477, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 18, "scene": 40, "line": "One more time. [singing] You...", "order": 6478, "speaker": "Michael Scott"},
  {"season": 2, "episode": 18, "scene": 41, "line": "The Schrutes consider children very valuable. In the olden days, the women would bear many children, so we would have enough laborers to work the fields. And if it was an especially cold winter, and there weren't enough grains or vegetables, they would eat the weakest of the brood. [Laughs] They didn't eat the children.", "order": 6479, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 18, "scene": 45, "line": "I brought my daughter Melissa in. That girl is very spoiled. That child has no idea what I have to deal with so she can have her little cell phone and spend all my money at that Steamtown Mall.", "order": 6480, "speaker": "Stanley Hudson"},
  {"season": 2, "episode": 18, "scene": 48, "line": "Didn't do background checks of the warehouse guys. [exclaims] Well, I'm sure it'll be fine.", "order": 6481, "speaker": "Michael Scott"},
  {"season": 2, "episode": 18, "scene": 56, "line": "A7557962.' Totally nonsense numbers, but you do what you have to do.", "order": 6482, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 19, "scene": 1, "line": "So, Phil recruited me to sell these cards, and now I am recruiting you.", "order": 6483, "speaker": "Michael Scott"},
  {"season": 2, "episode": 19, "scene": 1, "line": "Who is this guy again?", "order": 6484, "speaker": "Oscar Martinez"},
  {"season": 2, "episode": 19, "scene": 1, "line": "Don't worry about Phil. He drives a corvette. He is doing just fine. Okay. Calling cards are... the wave of the future. These things sell themselves.", "order": 6485, "speaker": "Michael Scott"},
  {"season": 2, "episode": 19, "scene": 1, "line": "Who uses calling cards anymore?", "order": 6486, "speaker": "Ryan Howard"},
  {"season": 2, "episode": 19, "scene": 1, "line": "You know what? That's a nice attitude, Ryan, I'm just helping you invest in your future, my friend.", "order": 6487, "speaker": "Michael Scott"},
  {"season": 2, "episode": 19, "scene": 1, "line": "This sounds like a get rich quick scheme.", "order": 6488, "speaker": "Oscar Martinez"},
  {"season": 2, "episode": 19, "scene": 1, "line": "Yes! Thank you! You will get rich quick. We all will!", "order": 6489, "speaker": "Michael Scott"},
  {"season": 2, "episode": 19, "scene": 1, "line": "Didn't you lose a lot of money on that other investment, the one from the email?", "order": 6490, "speaker": "Toby Flenderson"},
  {"season": 2, "episode": 19, "scene": 1, "line": "You know what, Toby? When the son of the deposed king of Nigeria emails you directly, asking for help, you help! His father ran the freaking country, okay? ...Alright, so, raise your hand if you wanna get rich. [Jim and Dwight raise their hands] Alright.", "order": 6491, "speaker": "Michael Scott"},
  {"season": 2, "episode": 19, "scene": 1, "line": "No, um. How is this not a pyramid scheme?", "order": 6492, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 19, "scene": 1, "line": "Alright, let me explain. Again. [draws on board] Phil has recruited me and another guy. Now, we are getting three people each. The more people that get involved, the more who are investing, the more money we're all going to make. It's not a pyramid scheme, it is a... it's not even a scheme per se, it's... [Jim draws a triangle around Michael's diagram] ... I have to go make a call.", "order": 6493, "speaker": "Michael Scott"},
  {"season": 2, "episode": 19, "scene": 2, "line": "Happy birthday Michael.", "order": 6494, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 19, "scene": 2, "line": "Oh ho ho! What?", "order": 6495, "speaker": "Michael Scott"},
  {"season": 2, "episode": 19, "scene": 2, "line": "I said happy birthday.", "order": 6496, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 19, "scene": 2, "line": "Thank you! That's really nice.", "order": 6497, "speaker": "Michael Scott"},
  {"season": 2, "episode": 19, "scene": 3, "line": "Today is my B-day, and people around here just go crazy for it. I don't know why. Oh! Fun fact. I share my birthday with Eva Longoria. So, I have a", "order": 6498, "speaker": "Michael Scott"},
  {"season": 2, "episode": 19, "scene": 4, "line": "What's up?", "order": 6499, "speaker": "Michael Scott"},
  {"season": 2, "episode": 19, "scene": 4, "line": "Hey. ...Oh, happy birthday.", "order": 6500, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 19, "scene": 4, "line": "Ah, thank you sir.", "order": 6501, "speaker": "Michael Scott"},
  {"season": 2, "episode": 19, "scene": 5, "line": "Did you hear anything yet?", "order": 6502, "speaker": "Meredith Palmer"},
  {"season": 2, "episode": 19, "scene": 5, "line": "No. I'm still waiting.", "order": 6503, "speaker": "Kevin Malone"},
  {"season": 2, "episode": 19, "scene": 6, "line": "[Dwight knocks on door] Yeah.", "order": 6504, "speaker": "Michael Scott"},
  {"season": 2, "episode": 19, "scene": 6, "line": "Yes. There he is, the birthday boy!", "order": 6505, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 19, "scene": 6, "line": "Ohh, god.", "order": 6506, "speaker": "Michael Scott"},
  {"season": 2, "episode": 19, "scene": 6, "line": "Birthday hug!", "order": 6507, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 19, "scene": 6, "line": "No no no, no, new suit, please.", "order": 6508, "speaker": "Michael Scott"},
  {"season": 2, "episode": 19, "scene": 6, "line": "That suit is amazing.", "order": 6509, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 19, "scene": 6, "line": "Thank you very much. It is from Italy. [checks jacket] Actually--- no, Bulgaria.", "order": 6510, "speaker": "Michael Scott"},
  {"season": 2, "episode": 19, "scene": 6, "line": "Mmm. Maybe I should get one.", "order": 6511, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 19, "scene": 6, "line": "Good luck. One of a kind.", "order": 6512, "speaker": "Michael Scott"},
  {"season": 2, "episode": 19, "scene": 6, "line": "Ebay. Hm. Question! May I be in charge of the party planning festivities?", "order": 6513, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 19, "scene": 6, "line": "Not necessary, the party planning committee is all over it. They've been working twenty-four seven all day yesterday.", "order": 6514, "speaker": "Michael Scott"},
  {"season": 2, "episode": 19, "scene": 6, "line": "Excellent. On my part, I did manage to reserve the...", "order": 6515, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 19, "scene": 6, "line": "Don't! Nope! Please, don't want to spoil it for anybody. Spoil the surprise.", "order": 6516, "speaker": "Michael Scott"},
  {"season": 2, "episode": 19, "scene": 6, "line": "Let's get the party started. [Begins 'raising the roof']", "order": 6517, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 19, "scene": 6, "line": "Let's get the party started. Not the way I taught you! [Michael joins in]", "order": 6518, "speaker": "Michael Scott"},
  {"season": 2, "episode": 19, "scene": 7, "line": "When should we bring out the cake, one or one thirty?", "order": 6519, "speaker": "Phyllis Lapin"},
  {"season": 2, "episode": 19, "scene": 7, "line": "One's good.", "order": 6520, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 19, "scene": 7, "line": "One thirty. [Pam yawns] I'm sorry, are we boring you?", "order": 6521, "speaker": "Angela Martin"},
  {"season": 2, "episode": 19, "scene": 7, "line": "Party planning committee, listen up. Michael would like trick candles for his birthday cake, so make that a priority.", "order": 6522, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 19, "scene": 7, "line": "Where do we get those?", "order": 6523, "speaker": "Phyllis Lapin"},
  {"season": 2, "episode": 19, "scene": 7, "line": "Not my problem. Here is a list of things that Michael would like to be surprised by. [hands list to Pam]", "order": 6524, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 19, "scene": 7, "line": "Michael wants a strippergram?", "order": 6525, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 19, "scene": 7, "line": "Yes, but he doesn't want to know when, or whom.", "order": 6526, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 19, "scene": 7, "line": "No. This is a closed door meeting.", "order": 6527, "speaker": "Angela Martin"},
  {"season": 2, "episode": 19, "scene": 8, "line": "[answering phone] Yeah?", "order": 6528, "speaker": "Michael Scott"},
  {"season": 2, "episode": 19, "scene": 8, "line": "Michael, I have Jan on the line.", "order": 6529, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 19, "scene": 8, "line": "Oh, great, put her through.", "order": 6530, "speaker": "Michael Scott"},
  {"season": 2, "episode": 19, "scene": 8, "line": "Hello, Michael.", "order": 6531, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 19, "scene": 8, "line": "Hey, you.", "order": 6532, "speaker": "Michael Scott"},
  {"season": 2, "episode": 19, "scene": 8, "line": "I'm... returning your call, you said it was urgent.", "order": 6533, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 19, "scene": 8, "line": "It is urgent, I just wanted to call and wish you a happy birthday.", "order": 6534, "speaker": "Michael Scott"},
  {"season": 2, "episode": 19, "scene": 8, "line": "Well, today's not my birthday, so...", "order": 6535, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 19, "scene": 8, "line": "Really? 'Cause, I thought we had the same birthday.", "order": 6536, "speaker": "Michael Scott"},
  {"season": 2, "episode": 19, "scene": 8, "line": "...Happy birthday, Michael.", "order": 6537, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 19, "scene": 8, "line": "Thanks. [grins]", "order": 6538, "speaker": "Michael Scott"},
  {"season": 2, "episode": 19, "scene": 8, "line": "Am I on camera?", "order": 6539, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 19, "scene": 8, "line": "Nope. Totally private. You can say whatever is in your heart. [Jan hangs up]", "order": 6540, "speaker": "Michael Scott"},
  {"season": 2, "episode": 19, "scene": 8, "line": "[to Ryan, sitting across from Michael] ...You can take a five, if you want.", "order": 6541, "speaker": "Michael Scott"},
  {"season": 2, "episode": 19, "scene": 9, "line": "Somebody brought in donuts for my birthday!", "order": 6542, "speaker": "Michael Scott"},
  {"season": 2, "episode": 19, "scene": 9, "line": "Mmhmm, happy birthday.", "order": 6543, "speaker": "Stanley Hudson"},
  {"season": 2, "episode": 19, "scene": 9, "line": "Thanks.", "order": 6544, "speaker": "Michael Scott"},
  {"season": 2, "episode": 19, "scene": 10, "line": "Man, I'm so sorry. When do you find out?", "order": 6545, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 19, "scene": 10, "line": "They said this afternoon. They're waiting on a second opinion.", "order": 6546, "speaker": "Kevin Malone"},
  {"season": 2, "episode": 19, "scene": 10, "line": "Oh, okay.", "order": 6547, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 19, "scene": 10, "line": "Second opinion on what?", "order": 6548, "speaker": "Kelly Kapoor"},
  {"season": 2, "episode": 19, "scene": 10, "line": "Um, I might have skin cancer.", "order": 6549, "speaker": "Kevin Malone"},
  {"season": 2, "episode": 19, "scene": 10, "line": "Oh, no! I was watching Grey's Anatomy, and there was a lifeguard on it, and", "order": 6550, "speaker": "Kelly Kapoor"},
  {"season": 2, "episode": 19, "scene": 10, "line": "Kelly, you know what...", "order": 6551, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 19, "scene": 11, "line": "I never really thought about death until Princess Diana died. That was the saddest funeral ever. That and my sister's.", "order": 6552, "speaker": "Kelly Kapoor"},
  {"season": 2, "episode": 19, "scene": 12, "line": "Who brought in donuts?", "order": 6553, "speaker": "Toby Flenderson"},
  {"season": 2, "episode": 19, "scene": 12, "line": "Somebody got donuts for my birthday!", "order": 6554, "speaker": "Michael Scott"},
  {"season": 2, "episode": 19, "scene": 12, "line": "Happy birthday!", "order": 6555, "speaker": "Toby Flenderson"},
  {"season": 2, "episode": 19, "scene": 12, "line": "You didn't know it was my birthday.", "order": 6556, "speaker": "Michael Scott"},
  {"season": 2, "episode": 19, "scene": 12, "line": "I... guess I forgot.", "order": 6557, "speaker": "Toby Flenderson"},
  {"season": 2, "episode": 19, "scene": 12, "line": "Well, I guess I forgot to give you a donut [closes box].", "order": 6558, "speaker": "Michael Scott"},
  {"season": 2, "episode": 19, "scene": 12, "line": "Are you serious?", "order": 6559, "speaker": "Toby Flenderson"},
  {"season": 2, "episode": 19, "scene": 12, "line": "Mmm.", "order": 6560, "speaker": "Michael Scott"},
  {"season": 2, "episode": 19, "scene": 13, "line": "Skin cancer is treatable.", "order": 6561, "speaker": "Oscar Martinez"},
  {"season": 2, "episode": 19, "scene": 13, "line": "Right.", "order": 6562, "speaker": "Kevin Malone"},
  {"season": 2, "episode": 19, "scene": 13, "line": "It's going to be okay.", "order": 6563, "speaker": "Oscar Martinez"},
  {"season": 2, "episode": 19, "scene": 13, "line": "You don't know it's going to be okay. Don't give him false hope. ...It's probably nothing, though.", "order": 6564, "speaker": "Angela Martin"},
  {"season": 2, "episode": 19, "scene": 14, "line": "Hi, delivery for Michael Scott.", "order": 6565, "speaker": "Delivery Woman"},
  {"season": 2, "episode": 19, "scene": 14, "line": "Here we go. Ohhhkay, this is great! [giggles] Thank you my friends, she is perfect! Ahhh, Dwight, may I have your chair please? And, um, some singles, if you will! Allllright. Nnnnn-dink! [puts single into delivery woman's pocket, giggles] Okay, um, alright. This has arms. Is that gonna be a... is that alright?", "order": 6566, "speaker": "Michael Scott"},
  {"season": 2, "episode": 19, "scene": 14, "line": "Uh... s-sure.", "order": 6567, "speaker": "Delivery Woman"},
  {"season": 2, "episode": 19, "scene": 14, "line": "[laughing] Okay. I'm so nervous.", "order": 6568, "speaker": "Michael Scott"},
  {"season": 2, "episode": 19, "scene": 14, "line": "I can sign for it.", "order": 6569, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 19, "scene": 14, "line": "Oh. Thanks.", "order": 6570, "speaker": "Delivery Woman"},
  {"season": 2, "episode": 19, "scene": 15, "line": "When I was seven, my mother hired a pony and a cart to come to my house for all the kids... and... I got a really bad rash from the pony, and all the kids got to ride the pony and I had to go inside, and my mother was rubbing cream on me... for probably three hours, and I never came outside. And by the time I got out the pony was already in the truck. And around the corner. So that was my worst birthday.", "order": 6571, "speaker": "Michael Scott"},
  {"season": 2, "episode": 19, "scene": 16, "line": "[eating donuts while Dwight plays the recorder] Stop it. Stop! What is that?", "order": 6572, "speaker": "Michael Scott"},
  {"season": 2, "episode": 19, "scene": 16, "line": "It's 'For the Longest Time,' by William Joel. It's you favorite song.", "order": 6573, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 19, "scene": 16, "line": "Yeah, well, it's on the radio. My birthday blows. Nobody even signed my birthday poster. Probably my mother is the only one that cares enough to send me anything.", "order": 6574, "speaker": "Michael Scott"},
  {"season": 2, "episode": 19, "scene": 16, "line": "I probably care more than she does.", "order": 6575, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 19, "scene": 16, "line": "You're making it worse. I bet Luke Perry's friends don't treat him like this [points to James Dean poster].", "order": 6576, "speaker": "Michael Scott"},
  {"season": 2, "episode": 19, "scene": 17, "line": "When does he hear?", "order": 6577, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 19, "scene": 17, "line": "Sometime today.", "order": 6578, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 19, "scene": 17, "line": "Ohh... poor Kevin.", "order": 6579, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 19, "scene": 18, "line": "If I knew I had a week to live, I would... probably go to Europe. And South America. And the Grand Canyon. And... I would want to see the Pacific Ocean. ...It would be a pretty busy week.", "order": 6580, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 19, "scene": 19, "line": "Uh, that's a list price of four dollars and fifty cents. Unfortunately, this item is on [watch beeps] back... order... [hangs up] Michael! Michael! Michael Michael Michael! Come here, come here, come here! Come here!", "order": 6581, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 19, "scene": 19, "line": "What?", "order": 6582, "speaker": "Michael Scott"},
  {"season": 2, "episode": 19, "scene": 19, "line": "Listen up everyone! It is 11:23 exactly, the exact moment when you emerged from your mother's vaginal canal, so... huh?! Right, have a seat. Please.", "order": 6583, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 19, "scene": 19, "line": "[grinning] Ohhh, God.", "order": 6584, "speaker": "Michael Scott"},
  {"season": 2, "episode": 19, "scene": 19, "line": "There is a tradition that the Hebrews have of hoisting the birthday boy up on a chair.", "order": 6585, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 19, "scene": 19, "line": "Ohhh, no.", "order": 6586, "speaker": "Michael Scott"},
  {"season": 2, "episode": 19, "scene": 19, "line": "So come help me celebrate Michael's birth moment. Kevin!", "order": 6587, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 19, "scene": 19, "line": "...I'll do it.", "order": 6588, "speaker": "Oscar Martinez"},
  {"season": 2, "episode": 19, "scene": 19, "line": "Ohh, no, no, no! I can't... Ryan, come on. Let's do this.", "order": 6589, "speaker": "Michael Scott"},
  {"season": 2, "episode": 19, "scene": 19, "line": "Creed! Come on. Stanley!", "order": 6590, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 19, "scene": 19, "line": "...I feel like we should go get Kevin something. Do you think we can sneak out of here?", "order": 6591, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 19, "scene": 19, "line": "Maybe, but... we're gonna need somebody to create a diversion, and...", "order": 6592, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 19, "scene": 19, "line": "On three, we're going to hoist away! Ready?", "order": 6593, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 19, "scene": 19, "line": "Okay.", "order": 6594, "speaker": "Michael Scott"},
  {"season": 2, "episode": 19, "scene": 19, "line": "Happy birth moment, Michael.", "order": 6595, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 19, "scene": 19, "line": "Thank you.", "order": 6596, "speaker": "Michael Scott"},
  {"season": 2, "episode": 19, "scene": 19, "line": "One. Two. Three! [Michael is raised until his head hits the ceiling]", "order": 6597, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 19, "scene": 19, "line": "Whoa whoa! Alright. Alright. Watch it... please.", "order": 6598, "speaker": "Michael Scott"},
  {"season": 2, "episode": 19, "scene": 19, "line": "Oscar...", "order": 6599, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 19, "scene": 19, "line": "It wasn't me.", "order": 6600, "speaker": "Oscar Martinez"},
  {"season": 2, "episode": 19, "scene": 20, "line": "Okay, that is", "order": 6601, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 19, "scene": 20, "line": "Uh, we don't make an eight foot sub. This is eight one foot subs.", "order": 6602, "speaker": "Delivery Boy"},
  {"season": 2, "episode": 19, "scene": 20, "line": "F. Alright, what's the damage?", "order": 6603, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 19, "scene": 20, "line": "Uh, thirty-nine sixty.", "order": 6604, "speaker": "Delivery Boy"},
  {"season": 2, "episode": 19, "scene": 20, "line": "[pulls out wallet] Thirty nine... sixty.", "order": 6605, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 19, "scene": 21, "line": "Why tip someone for a job I'm capable of doing myself? I can deliver food. I can drive a taxi. I can, and do, cut my own hair. I did, however, tip my urologist, because... I am unable to pulverize my own kidney stones.", "order": 6606, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 19, "scene": 22, "line": "Here they come.", "order": 6607, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 19, "scene": 22, "line": "Get in here... everybody.", "order": 6608, "speaker": "Michael Scott"},
  {"season": 2, "episode": 19, "scene": 22, "line": "Come and get it!", "order": 6609, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 19, "scene": 22, "line": "Birthday party subs! My gift to you.", "order": 6610, "speaker": "Michael Scott"},
  {"season": 2, "episode": 19, "scene": 22, "line": "What is this?", "order": 6611, "speaker": "Oscar Martinez"},
  {"season": 2, "episode": 19, "scene": 22, "line": "Uh, bologna, tomato and ketchup.", "order": 6612, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 19, "scene": 22, "line": "The best.", "order": 6613, "speaker": "Michael Scott"},
  {"season": 2, "episode": 19, "scene": 22, "line": "These are all the same?", "order": 6614, "speaker": "Stanley Hudson"},
  {"season": 2, "episode": 19, "scene": 22, "line": "Yes.", "order": 6615, "speaker": "Michael Scott"},
  {"season": 2, "episode": 19, "scene": 22, "line": "Bologna? I don't eat bologna.", "order": 6616, "speaker": "Angela Martin"},
  {"season": 2, "episode": 19, "scene": 22, "line": "Well, then just have the tomato and ketchup. Still good.", "order": 6617, "speaker": "Michael Scott"},
  {"season": 2, "episode": 19, "scene": 22, "line": "No.", "order": 6618, "speaker": "Angela Martin"},
  {"season": 2, "episode": 19, "scene": 22, "line": "Just the bread, it's fresh baked.", "order": 6619, "speaker": "Michael Scott"},
  {"season": 2, "episode": 19, "scene": 22, "line": "No.", "order": 6620, "speaker": "Angela Martin"},
  {"season": 2, "episode": 19, "scene": 22, "line": "Mm-kay. Get whatever you want. [under breath] And choke on it.", "order": 6621, "speaker": "Michael Scott"},
  {"season": 2, "episode": 19, "scene": 23, "line": "When I was sixteen, I was supposed to go out on a date with a girl named Julie. But there was another Michael in the class that she apparently thought the date was with, so she went out with him, on my birthday. And, she got him a cake, at the restaurant. And it wasn't even his birthday, but I heard about it the next day in school. So...", "order": 6622, "speaker": "Michael Scott"},
  {"season": 2, "episode": 19, "scene": 24, "line": "So. We got Kev some stuff. Um... a party pack of M&M's, his favorite candy. A DVD of American Pie 2, which is his favorite movie, and, he lent it to Creed, so, I can guarantee you he won't get that back.", "order": 6623, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 19, "scene": 24, "line": "Sixty-nine cup of noodles.", "order": 6624, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 19, "scene": 24, "line": "Which we realize sounds crass, but, it... is his favorite number.", "order": 6625, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 19, "scene": 24, "line": "And his favorite lunch.", "order": 6626, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 19, "scene": 25, "line": "Hey temp, you know uh, we still got five feet of sandwich left [pulls ice cream cake out of freezer].", "order": 6627, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 19, "scene": 25, "line": "[making peanut butter and jelly sandwich] Someone ate three feet of that thing?", "order": 6628, "speaker": "Ryan Howard"},
  {"season": 2, "episode": 19, "scene": 25, "line": "Hell, yeah. Save room for ice cream cake.", "order": 6629, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 19, "scene": 25, "line": "[grabs cake] Oh. Thank you.", "order": 6630, "speaker": "Angela Martin"},
  {"season": 2, "episode": 19, "scene": 25, "line": "Oh. I got it.", "order": 6631, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 19, "scene": 25, "line": "What are--- it's... the party planning committee.", "order": 6632, "speaker": "Angela Martin"},
  {"season": 2, "episode": 19, "scene": 25, "line": "[whispering] This is the most important day of the year. I can't risk anything.", "order": 6633, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 19, "scene": 25, "line": "Fine.", "order": 6634, "speaker": "Angela Martin"},
  {"season": 2, "episode": 19, "scene": 25, "line": "What about that meeting... later... to discuss finances?", "order": 6635, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 19, "scene": 25, "line": "Yes... [whispering] but don't expect any cookie.", "order": 6636, "speaker": "Angela Martin"},
  {"season": 2, "episode": 19, "scene": 25, "line": "[whispering] But what if i'm hungry?", "order": 6637, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 19, "scene": 25, "line": "[whispering]", "order": 6638, "speaker": "Angela Martin"},
  {"season": 2, "episode": 19, "scene": 26, "line": "[puts fabric softener into cart] ...What?", "order": 6639, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 19, "scene": 26, "line": "You use fabric softener?", "order": 6640, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 19, "scene": 26, "line": "Yeah, you don't?", "order": 6641, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 19, "scene": 26, "line": "No, I do.", "order": 6642, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 19, "scene": 26, "line": "...Okay.", "order": 6643, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 19, "scene": 27, "line": "[singing] Happy birthday dear Michael, [Michael joins in, Kevin's phone rings] Happy birthday... [everyone but Dwight stops] ...tooo youuuu.", "order": 6644, "speaker": "Office Staff"},
  {"season": 2, "episode": 19, "scene": 27, "line": "Hello? Hey.", "order": 6645, "speaker": "Kevin Malone"},
  {"season": 2, "episode": 19, "scene": 27, "line": "Kevin? Respect the birthday please.", "order": 6646, "speaker": "Michael Scott"},
  {"season": 2, "episode": 19, "scene": 27, "line": "No, um, no not yet. I will. Bye. [hangs up] It was just Stacy.", "order": 6647, "speaker": "Kevin Malone"},
  {"season": 2, "episode": 19, "scene": 27, "line": "Are you done? ...Good. Okay.", "order": 6648, "speaker": "Michael Scott"},
  {"season": 2, "episode": 19, "scene": 27, "line": "Here we go. Make a wish.", "order": 6649, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 19, "scene": 27, "line": "Uhhh... blow out the candle. Okay. Mmmm... [blows out the candles]", "order": 6650, "speaker": "Michael Scott"},
  {"season": 2, "episode": 19, "scene": 27, "line": "Yaoo yay! [claps]", "order": 6651, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 19, "scene": 27, "line": "... I asked for trick candles.", "order": 6652, "speaker": "Michael Scott"},
  {"season": 2, "episode": 19, "scene": 27, "line": "Pam was supposed to get 'em.", "order": 6653, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 19, "scene": 27, "line": "Okay. Well, when she comes back we'll do it again. [notices Meredith hugging Kevin] Hello, what about the birthday boy? Haven't had a hug all day.", "order": 6654, "speaker": "Michael Scott"},
  {"season": 2, "episode": 19, "scene": 27, "line": "No one cares about your birthday. Kevin's waiting to hear if he has skin cancer.", "order": 6655, "speaker": "Angela Martin"},
  {"season": 2, "episode": 19, "scene": 27, "line": "... Aww, that... sucks, great. ... Wow, that's good timing. That's... that's, sorry, that's terrible. Terrible news. That's terrible... terrible news for both of us [takes cake into office and slams the door].", "order": 6656, "speaker": "Michael Scott"},
  {"season": 2, "episode": 19, "scene": 28, "line": "[checking watch] We should probably head back.", "order": 6657, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 19, "scene": 28, "line": "Yeah. Okay. Oh. I dare you to make an announcement.", "order": 6658, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 19, "scene": 28, "line": "You dare me? How old are you?", "order": 6659, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 19, "scene": 28, "line": "Just... quit stalling.", "order": 6660, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 19, "scene": 28, "line": "[over loudspeaker, imitating Darth Vader] Luke, this is your father. Come set the table for dinner.", "order": 6661, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 19, "scene": 28, "line": "Such a dork.", "order": 6662, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 19, "scene": 28, "line": "[loudspeaker] Jim Halpert? Price check on fabric softener, the kind that gives you...", "order": 6663, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 19, "scene": 28, "line": "Ma'am? Please don't touch that. That is not a toy.", "order": 6664, "speaker": "Store Employee"},
  {"season": 2, "episode": 19, "scene": 28, "line": "Oh I'm sorry. I'm sorry.", "order": 6665, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 19, "scene": 28, "line": "How old are you?", "order": 6666, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 19, "scene": 28, "line": "I hate you.", "order": 6667, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 19, "scene": 29, "line": "[to Kevin] Honestly, is there any way you can get on your fiancee's plan? Our health plan is s... just... it's terrible.", "order": 6668, "speaker": "Toby Flenderson"},
  {"season": 2, "episode": 19, "scene": 29, "line": "There you are. Good news. Did some research. It turns out that 98% of people with skin cancer fully recover.", "order": 6669, "speaker": "Michael Scott"},
  {"season": 2, "episode": 19, "scene": 29, "line": "Still scary.", "order": 6670, "speaker": "Kevin Malone"},
  {"season": 2, "episode": 19, "scene": 29, "line": "Yeah, but it's not brain cancer. And it shouldn't stop us from having fun. You know what they say the best medicine is.", "order": 6671, "speaker": "Michael Scott"},
  {"season": 2, "episode": 19, "scene": 29, "line": "Well the doctor said a combination of interferon and dicarbazine.", "order": 6672, "speaker": "Kevin Malone"},
  {"season": 2, "episode": 19, "scene": 29, "line": "And laughter... also.", "order": 6673, "speaker": "Michael Scott"},
  {"season": 2, "episode": 19, "scene": 29, "line": "I don't really think people are in the laughing mood.", "order": 6674, "speaker": "Toby Flenderson"},
  {"season": 2, "episode": 19, "scene": 29, "line": "Why are you here? I didn't even invite you to my birthday party.", "order": 6675, "speaker": "Michael Scott"},
  {"season": 2, "episode": 19, "scene": 29, "line": "I work here.", "order": 6676, "speaker": "Toby Flenderson"},
  {"season": 2, "episode": 19, "scene": 29, "line": "[mocking voice] Nyeh, I work here. [to Kevin] Alright, well, you know what, since Toby doesn't speak for everybody and I am your boss, I... think you should just go home. Take the rest of the afternoon off. Take a sick day.", "order": 6677, "speaker": "Michael Scott"},
  {"season": 2, "episode": 19, "scene": 29, "line": "If I go home now, I'll just drive myself crazy.", "order": 6678, "speaker": "Kevin Malone"},
  {"season": 2, "episode": 19, "scene": 29, "line": "Well, you're pretty much driving everyone else here crazy... crazy with worry.", "order": 6679, "speaker": "Michael Scott"},
  {"season": 2, "episode": 19, "scene": 30, "line": "Where have you been? And don't say the bathroom, 'cause I kicked in all the stalls.", "order": 6680, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 19, "scene": 30, "line": "Well that's an invasion of privacy, so, I'm going to tell Michael.", "order": 6681, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 19, "scene": 30, "line": "Please, don't.", "order": 6682, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 19, "scene": 30, "line": "You... owe me.", "order": 6683, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 19, "scene": 30, "line": "Excuse me, everyone. Attention please. Kevin, we're going to take you to a very special place, a place that will make you happy, and a place that is far, far away from the evil sun.", "order": 6684, "speaker": "Michael Scott"},
  {"season": 2, "episode": 19, "scene": 30, "line": "Is this trip related in any way to your... birthday?", "order": 6685, "speaker": "Stanley Hudson"},
  {"season": 2, "episode": 19, "scene": 30, "line": "How dare you sir. You are gross.", "order": 6686, "speaker": "Michael Scott"},
  {"season": 2, "episode": 19, "scene": 31, "line": "[sees 'Happy Birthday Michael Scott!' poster at skating rink] That should not be there.", "order": 6687, "speaker": "Michael Scott"},
  {"season": 2, "episode": 19, "scene": 31, "line": "I'll get someone to take it down.", "order": 6688, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 19, "scene": 31, "line": "No, it's alright. It's already up. Just leave it. Where's Kevin? Come on! Let's get our skate on!", "order": 6689, "speaker": "Michael Scott"},
  {"season": 2, "episode": 19, "scene": 32, "line": "Don't be scared! You're good! You're good!", "order": 6690, "speaker": "Kelly Kapoor"},
  {"season": 2, "episode": 19, "scene": 32, "line": "Whoa whoa whoa whoa whoa whoa whoa.", "order": 6691, "speaker": "Ryan Howard"},
  {"season": 2, "episode": 19, "scene": 33, "line": "Think you can let go?", "order": 6692, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 19, "scene": 33, "line": "No. [laughs]", "order": 6693, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 19, "scene": 33, "line": "Whoa whoa whoa whoa whoa. [Michael skates by]", "order": 6694, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 19, "scene": 33, "line": "YEAH!", "order": 6695, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 19, "scene": 33, "line": "Who is that?", "order": 6696, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 19, "scene": 33, "line": "Is that Michael?", "order": 6697, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 19, "scene": 34, "line": "Yeah, I've been pretty much skating my whole life. I thought about playing in the NHL, but, you're on the road so much. You got no time to spend with your wife and kids. And I really want a wife and kids.", "order": 6698, "speaker": "Michael Scott"},
  {"season": 2, "episode": 19, "scene": 35, "line": "I got it.", "order": 6699, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 19, "scene": 35, "line": "Hey Pam, all this stuff with Kevin... um, it's pretty scary. And I'm thinking that uh, next time you're in the shower, you should check yourself out. You know, give yourself an exam. Those things are like ticking time bags. Alright? Think about it.", "order": 6700, "speaker": "Michael Scott"},
  {"season": 2, "episode": 19, "scene": 35, "line": "...It's something to think about.", "order": 6701, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 19, "scene": 36, "line": "I can't relax about it, you know?", "order": 6702, "speaker": "Kevin Malone"},
  {"season": 2, "episode": 19, "scene": 36, "line": "Kevin. You heard anything yet?", "order": 6703, "speaker": "Michael Scott"},
  {"season": 2, "episode": 19, "scene": 36, "line": "No, not yet.", "order": 6704, "speaker": "Kevin Malone"},
  {"season": 2, "episode": 19, "scene": 36, "line": "Okay. Well. Live strong.", "order": 6705, "speaker": "Michael Scott"},
  {"season": 2, "episode": 19, "scene": 36, "line": "Okay, Michael.", "order": 6706, "speaker": "Kevin Malone"},
  {"season": 2, "episode": 19, "scene": 36, "line": "Alright.", "order": 6707, "speaker": "Michael Scott"},
  {"season": 2, "episode": 19, "scene": 36, "line": "Michael?", "order": 6708, "speaker": "Carol Stills"},
  {"season": 2, "episode": 19, "scene": 36, "line": "Yeah. Carol? She sold me my condo! Hey! What, is this place on the market? Or...", "order": 6709, "speaker": "Michael Scott"},
  {"season": 2, "episode": 19, "scene": 36, "line": "Uhh, no, I... don't just sell real estate. Uh, my daughter has a skating lesson.", "order": 6710, "speaker": "Carol Stills"},
  {"season": 2, "episode": 19, "scene": 36, "line": "Oh, these... all your kids?", "order": 6711, "speaker": "Michael Scott"},
  {"season": 2, "episode": 19, "scene": 36, "line": "No just the front two.", "order": 6712, "speaker": "Carol Stills"},
  {"season": 2, "episode": 19, "scene": 36, "line": "Oh, hey guys. Whats up? You wanna go for a ride? Is that okay?", "order": 6713, "speaker": "Michael Scott"},
  {"season": 2, "episode": 19, "scene": 36, "line": "Sure.", "order": 6714, "speaker": "Carol Stills"},
  {"season": 2, "episode": 19, "scene": 36, "line": "Cool. Alright. Grab on. Here we go. Ready? Hang on tight. Alright. We are moving. We are reaaallly mooovin' now!", "order": 6715, "speaker": "Michael Scott"},
  {"season": 2, "episode": 19, "scene": 37, "line": "Push. Good! That's great. You got it. [Kevin's phone rings] Excuse me.", "order": 6716, "speaker": "Michael Scott"},
  {"season": 2, "episode": 19, "scene": 37, "line": "Hello? Yeah okay. Alright. Okay, I will, thanks. [hangs up] It was negative.", "order": 6717, "speaker": "Kevin Malone"},
  {"season": 2, "episode": 19, "scene": 37, "line": "Oh... God... [stomps] God! [throws hockey stick and yellow paper bracelet down] We're gonna beat this, okay? We're gonna... come here [hugs Kevin].", "order": 6718, "speaker": "Michael Scott"},
  {"season": 2, "episode": 19, "scene": 38, "line": "Well, apparently in the medicine community, negative means good. Which makes absolutely no sense. In the real world community, that would... be... chaos.", "order": 6719, "speaker": "Michael Scott"},
  {"season": 2, "episode": 19, "scene": 39, "line": "This is awesome. Thanks, you guys.", "order": 6720, "speaker": "Kevin Malone"},
  {"season": 2, "episode": 19, "scene": 39, "line": "Okay, who's this from? Wowwwee, look at that! Jersey!", "order": 6721, "speaker": "Michael Scott"},
  {"season": 2, "episode": 19, "scene": 39, "line": "Turn it around. Turn it around.", "order": 6722, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 19, "scene": 39, "line": "Cool. Ohh. Great. From Dwight.", "order": 6723, "speaker": "Michael Scott"},
  {"season": 2, "episode": 19, "scene": 39, "line": "Number one!!", "order": 6724, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 19, "scene": 39, "line": "Thank you... Dwight. That's great. Thanks.", "order": 6725, "speaker": "Michael Scott"},
  {"season": 2, "episode": 19, "scene": 39, "line": "Michael?", "order": 6726, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 19, "scene": 39, "line": "Yeah.", "order": 6727, "speaker": "Michael Scott"},
  {"season": 2, "episode": 19, "scene": 39, "line": "This is from all of us.", "order": 6728, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 19, "scene": 39, "line": "Oh! You didn't need to do that. ...Nightswept. This is... really amazing. Thank you. I love it.", "order": 6729, "speaker": "Michael Scott"},
  {"season": 2, "episode": 19, "scene": 40, "line": "Michael's birthday was actually pretty cool. It was a good day. I don't know... It was a good day.", "order": 6730, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 19, "scene": 43, "line": "Michael's birthday is hard for me because he gets very excited. But he's also under a lot of pressure, which builds up until he's ready to explode. As his right-hand man, it's my job to release that pressure so that he can enjoy himself, if only for a moment.", "order": 6731, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 19, "scene": 48, "line": "To think that a man's skin could turn on him. It's brilliant, 'cause you'd never expect it. Most people think of their skin as an ally. [scoffs] Makes me rethink having skin at all.", "order": 6732, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 20, "scene": 1, "line": "Kevin Malone, you're next. Spit that out. [Kevin shoves the rest of the donut he's eating into his mouth] Spit... Okay, come on, let's go.", "order": 6733, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 20, "scene": 2, "line": "You look cute today, Dwight.", "order": 6734, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 20, "scene": 2, "line": "Thanks, girl.", "order": 6735, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 20, "scene": 3, "line": "So, yesterday Dwight found half a joint in the parking lot. Which is unfortunate because as it turns out, Dwight", "order": 6736, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 20, "scene": 4, "line": "Let's go over some of the symptoms of marijuana use, shall we? You tell me who this sounds like: slow moving, inattentive, dull, constantly snacking, shows a lack of motivation.", "order": 6737, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 20, "scene": 4, "line": "[nods] Hey...", "order": 6738, "speaker": "Kevin Malone"},
  {"season": 2, "episode": 20, "scene": 5, "line": "I like the people I work with generally, with four exceptions. But someone committed a crime and I did not become a Lackawanna County Volunteer Sheriff's deputy to make friends. And by the way, I haven't.", "order": 6739, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 20, "scene": 6, "line": "[mimicking Stanley] I enjoy the tangy zip of Miracle Whip.", "order": 6740, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 20, "scene": 6, "line": "[laughs] Jim does the best impressions. Sometimes he'll look up at me from his desk and he'll just be someone else. Like he'll go um, [makes mournful face, giggles] that's supposed to be Phyllis. I can't do it as good as he can.", "order": 6741, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 20, "scene": 7, "line": "And the guys are saying, chug, chug, chug, but I'm so small and all I'd eaten that day was one of those Auntie Anne pretzels from the food court so I said 'Is it okay if I sip it?' and they said no, but Ryan seemed cool either way.", "order": 6742, "speaker": "Kelly Kapoor"},
  {"season": 2, "episode": 20, "scene": 7, "line": "Stop! This is not Kelly Kapoor story hour. Illegal drugs were consumed on company property, okay? Your ass is on the line, mister! My ass is is on the line! Now I'm going to ask you again. What time did you go home last night?", "order": 6743, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 20, "scene": 7, "line": "Six.", "order": 6744, "speaker": "Kelly Kapoor"},
  {"season": 2, "episode": 20, "scene": 8, "line": "I didn't know that you were at a party on Saturday night.", "order": 6745, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 20, "scene": 8, "line": "I go to a lot of parties.", "order": 6746, "speaker": "Ryan Howard"},
  {"season": 2, "episode": 20, "scene": 8, "line": "Okay, I'm gonna need to search your car. Give me you keys.", "order": 6747, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 20, "scene": 8, "line": "I am not giving you my keys.", "order": 6748, "speaker": "Ryan Howard"},
  {"season": 2, "episode": 20, "scene": 8, "line": "Don't make me do this the hard way.", "order": 6749, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 20, "scene": 8, "line": "What's the hard way?", "order": 6750, "speaker": "Ryan Howard"},
  {"season": 2, "episode": 20, "scene": 8, "line": "I go down to the police station on my lunch break. I tell a police officer, I know several, what I suspect you may have in your car. He requests a hearing from a judge and obtains a search warrant, once he has said warrant, he will drive over here, and make you give him the keys to your car, and you will have to obey him.", "order": 6751, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 20, "scene": 8, "line": "Yeah, let's do it that way.", "order": 6752, "speaker": "Ryan Howard"},
  {"season": 2, "episode": 20, "scene": 8, "line": "Ry, is he bugging you? Dwight, dude, you gotta take a chill pill, man. It was one joint in the parking lot. You know, you're totally harshing the office mellow.", "order": 6753, "speaker": "Michael Scott"},
  {"season": 2, "episode": 20, "scene": 8, "line": "I can't stop this investigation. It is my job.", "order": 6754, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 20, "scene": 8, "line": "Whoa. You are a volunteer.", "order": 6755, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 20, "scene": 8, "line": "I volunteered for this job.", "order": 6756, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 20, "scene": 8, "line": "And that's not the same.", "order": 6757, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 20, "scene": 8, "line": "It is my duty...", "order": 6758, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 20, "scene": 8, "line": "[interrupting] Volunteer duty.", "order": 6759, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 20, "scene": 8, "line": "...to investigate the crime scene. I have six more interviews to go and then I will reveal what I know.", "order": 6760, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 20, "scene": 8, "line": "[fake coughing] Narc!", "order": 6761, "speaker": "Michael Scott"},
  {"season": 2, "episode": 20, "scene": 8, "line": "[giggling]", "order": 6762, "speaker": "Kevin Malone"},
  {"season": 2, "episode": 20, "scene": 8, "line": "If you are attempting to compliment me then you have done a very good job.", "order": 6763, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 20, "scene": 8, "line": "I wasn't attempting to compliment you.", "order": 6764, "speaker": "Michael Scott"},
  {"season": 2, "episode": 20, "scene": 8, "line": "Well, you have...", "order": 6765, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 20, "scene": 8, "line": "Uuf, well...", "order": 6766, "speaker": "Michael Scott"},
  {"season": 2, "episode": 20, "scene": 8, "line": "...because being a narc is one of the hardest jobs that you can have...", "order": 6767, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 20, "scene": 8, "line": "[shakes head] Okay...", "order": 6768, "speaker": "Michael Scott"},
  {"season": 2, "episode": 20, "scene": 8, "line": "...and I am very proud of being a narc.", "order": 6769, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 20, "scene": 8, "line": "Why don't you just cool it, cool it Dwight, please, God! [to Jim] Dude, where's my office? [Jim quietly laughs] I totally lost it, 'cause I was half-baked. Smokin' doobies. Doobie brothers, I was smokin' doobies with my brothers. Peace out, Seacrest!", "order": 6770, "speaker": "Michael Scott"},
  {"season": 2, "episode": 20, "scene": 8, "line": "Well, your office is behind you.", "order": 6771, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 20, "scene": 8, "line": "Thanks. M-m-munchies. Who wants some munchies?", "order": 6772, "speaker": "Michael Scott"},
  {"season": 2, "episode": 20, "scene": 9, "line": "I don't think Michael's ever done drugs. I don't know if anyone has ever offered him any.", "order": 6773, "speaker": "Ryan Howard"},
  {"season": 2, "episode": 20, "scene": 10, "line": "Oscar visited Mexico when he was five to attend his great-grandmother's funeral. What does that mean to a United States law enforcement officer? He's a potential drug mule.", "order": 6774, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 20, "scene": 11, "line": "Have you ever taken any illegal drugs?", "order": 6775, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 20, "scene": 11, "line": "No, I have not.", "order": 6776, "speaker": "Oscar Martinez"},
  {"season": 2, "episode": 20, "scene": 11, "line": "Do you think it's possible that maybe you could have had some drugs in your system without you knowing about it?", "order": 6777, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 20, "scene": 11, "line": "What are you implying?", "order": 6778, "speaker": "Oscar Martinez"},
  {"season": 2, "episode": 20, "scene": 11, "line": "Have you ever... pooped... a balloon?", "order": 6779, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 20, "scene": 11, "line": "Okay. I'm done with this.", "order": 6780, "speaker": "Oscar Martinez"},
  {"season": 2, "episode": 20, "scene": 11, "line": "He sure left in a hurry.", "order": 6781, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 20, "scene": 12, "line": "I don't want to blow this. This is what all good law enforcement officers dream of. The chance to solve an actual crime.", "order": 6782, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 20, "scene": 13, "line": "Do you know what this is? [pushing a photo toward her]", "order": 6783, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 20, "scene": 13, "line": "Yes, it's marijuana.", "order": 6784, "speaker": "Phyllis Lapin"},
  {"season": 2, "episode": 20, "scene": 13, "line": "How do you know that?", "order": 6785, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 20, "scene": 13, "line": "It's labeled.", "order": 6786, "speaker": "Phyllis Lapin"},
  {"season": 2, "episode": 20, "scene": 13, "line": "[grabs pictures back and looks at it] Dammit.", "order": 6787, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 20, "scene": 14, "line": "That is Northern Lights Cannabis Indica.", "order": 6788, "speaker": "Creed Bratton"},
  {"season": 2, "episode": 20, "scene": 14, "line": "No, it's marijuana.", "order": 6789, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 20, "scene": 15, "line": "I'm just saying that you can't be sure that is wasn't you.", "order": 6790, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 20, "scene": 15, "line": "That's ridiculous, of course it wasn't me.", "order": 6791, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 20, "scene": 15, "line": "Marijuana is a memory loss drug, so maybe you just don't remember.", "order": 6792, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 20, "scene": 15, "line": "I would remember.", "order": 6793, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 20, "scene": 15, "line": "Well, how could you, if it just erased your memory?", "order": 6794, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 20, "scene": 15, "line": "That's not how it works.", "order": 6795, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 20, "scene": 15, "line": "Now how do you know how it works?", "order": 6796, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 20, "scene": 15, "line": "Knock it off, okay, I'm interviewing you.", "order": 6797, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 20, "scene": 15, "line": "No! You said that I'd be conducting the interview when I walked in here. Now exactly how much pot did you smoke?", "order": 6798, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 20, "scene": 15, "line": "[opens eyes wide in total surprise]", "order": 6799, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 20, "scene": 16, "line": "So Pam told me that you do a great Stanley impression, I'd love to hear it.", "order": 6800, "speaker": "Oscar Martinez"},
  {"season": 2, "episode": 20, "scene": 16, "line": "Oh, um...[mimicking Stanley] Why do you keep CC'ing me on things that have nothing to do with me? [Pam and Oscar laugh, Stanley walks in, and Oscar leaves quickly]", "order": 6801, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 20, "scene": 16, "line": "Is that supposed to be me?", "order": 6802, "speaker": "Stanley Hudson"},
  {"season": 2, "episode": 20, "scene": 16, "line": "Oh, hey Stanley. Uh, I was just doing an impression.", "order": 6803, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 20, "scene": 16, "line": "I do not think that is funny.", "order": 6804, "speaker": "Stanley Hudson"},
  {"season": 2, "episode": 20, "scene": 16, "line": "He does everyone in the office.", "order": 6805, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 20, "scene": 16, "line": "Hmmmpt.", "order": 6806, "speaker": "Stanley Hudson"},
  {"season": 2, "episode": 20, "scene": 16, "line": "[in unison] I do not think that is funny.", "order": 6807, "speaker": "Pam Beesly & Jim Halpert"},
  {"season": 2, "episode": 20, "scene": 16, "line": "Jinx! Buy me a coke.", "order": 6808, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 20, "scene": 16, "line": "Oh...", "order": 6809, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 20, "scene": 16, "line": "No, no, no, no talking. Jim is not allowed to talk until after he buys me a coke. Those are the rules of jinx, and they are unflinchingly rigid. [Jim puts money in drink machine, selection is sold out]", "order": 6810, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 20, "scene": 16, "line": "Sold out? That has never happened in the history of jinx.", "order": 6811, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 20, "scene": 16, "line": "[mouths] C'mon!", "order": 6812, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 20, "scene": 16, "line": "Sorry, that's not my problem.", "order": 6813, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 20, "scene": 16, "line": "[presses drink button, looks at camera, makes Jim-face]", "order": 6814, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 20, "scene": 17, "line": "I know you're innocent, but I can't look like I'm treating you any differently.", "order": 6815, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 20, "scene": 17, "line": "I understand.", "order": 6816, "speaker": "Angela Martin"},
  {"season": 2, "episode": 20, "scene": 17, "line": "Where were you yesterday after work?", "order": 6817, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 20, "scene": 17, "line": "[smiles knowingly]", "order": 6818, "speaker": "Angela Martin"},
  {"season": 2, "episode": 20, "scene": 18, "line": "Uh-oh. Uh-oh. Who's he calling? Ratting somebody out. Narc! Narc! Kevin?", "order": 6819, "speaker": "Michael Scott"},
  {"season": 2, "episode": 20, "scene": 18, "line": "That is so good, Michael", "order": 6820, "speaker": "Kevin Malone"},
  {"season": 2, "episode": 20, "scene": 18, "line": "Remember the narc bit? [laughs] Uh-oh, who's in trouble?", "order": 6821, "speaker": "Michael Scott"},
  {"season": 2, "episode": 20, "scene": 19, "line": "Attention everyone. Drug testers are coming in a couple of hours to test everyone's urine.", "order": 6822, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 20, "scene": 19, "line": "Waa... what? What are you talking about?", "order": 6823, "speaker": "Michael Scott"},
  {"season": 2, "episode": 20, "scene": 19, "line": "Company policy. If drugs are found on the premises there is automatic drug testing conducted within twenty-four hours.", "order": 6824, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 20, "scene": 19, "line": "Is that true, Toby?", "order": 6825, "speaker": "Oscar Martinez"},
  {"season": 2, "episode": 20, "scene": 19, "line": "Oh, when you sign your job application you agree to comply with random drug testing.", "order": 6826, "speaker": "Toby Flenderson"},
  {"season": 2, "episode": 20, "scene": 20, "line": "Two nights ago, I went to an Alicia Keys concert at the Montage Mountain Performing Arts Center. I scored these great aisle seats. Anyway, after the opening act this beautiful girl sits down next to me and I never get to meet girls with lip rings and she had one. I don't know exactly how this happened but one of her friends started passing around some stuff and they said it was clove cigarettes, and I'm sure that it was clove cigarettes. Everybody in the aisle was doing it.", "order": 6827, "speaker": "Michael Scott"},
  {"season": 2, "episode": 20, "scene": 21, "line": "Okay, attention everyone the drug testing has been cancelled. Instead, I will be going around to each of you and doing a visual inspection.", "order": 6828, "speaker": "Michael Scott"},
  {"season": 2, "episode": 20, "scene": 21, "line": "No you can't do that.", "order": 6829, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 20, "scene": 21, "line": "I can do that, it is my office.", "order": 6830, "speaker": "Michael Scott"},
  {"season": 2, "episode": 20, "scene": 21, "line": "No you cannot. It has to be official, and it", "order": 6831, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 20, "scene": 21, "line": "Hmmm. Ha. [under his breath] Alright. Great.", "order": 6832, "speaker": "Michael Scott"},
  {"season": 2, "episode": 20, "scene": 22, "line": "Kevin, what prescription drugs are you taking, besides Rogaine?", "order": 6833, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 20, "scene": 22, "line": "I'm not taking Rogaine.", "order": 6834, "speaker": "Kevin Malone"},
  {"season": 2, "episode": 20, "scene": 22, "line": "Angela, what about you?", "order": 6835, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 20, "scene": 22, "line": "I don't take any prescription drugs.", "order": 6836, "speaker": "Angela Martin"},
  {"season": 2, "episode": 20, "scene": 22, "line": "You're not on", "order": 6837, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 20, "scene": 22, "line": "[Gives Dwight a knowing look]", "order": 6838, "speaker": "Angela Martin"},
  {"season": 2, "episode": 20, "scene": 22, "line": "Good.", "order": 6839, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 20, "scene": 23, "line": "So the first time we went out to dinner, it was like, whatever, fine, but I was so nervous. So this time I wanted to be special, so I bought a new dress! [Jim hunches his shoulders and grins] One of those kinds that is kinda low cut at top to show something, but not everything. [Jim shakes his head no in agreement] I mean not everything, Jim. [Jim shakes his head in agreement] I promise, I'm not that kind of...", "order": 6840, "speaker": "Kelly Kapoor"},
  {"season": 2, "episode": 20, "scene": 23, "line": "Hey guys, what's going on?", "order": 6841, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 20, "scene": 23, "line": "We're having the best conversation. [Jim, eyes wide, shakes his head, no]", "order": 6842, "speaker": "Kelly Kapoor"},
  {"season": 2, "episode": 20, "scene": 23, "line": "Oh, okay. Well, Michael wants to see everyone in the conference room. [Jim shakes his head. Seems relieved to be getting away from Kelly] But you know what? We have a few minutes so you guys should definitely finish up your conversation. [Jim nods his head as if to say, 'Gee, thanks, Pam.']", "order": 6843, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 20, "scene": 23, "line": "So, I was looking so hot...", "order": 6844, "speaker": "Kelly Kapoor"},
  {"season": 2, "episode": 20, "scene": 24, "line": "It has come to my attention that some people here think that the use of drugs is something to laugh about.", "order": 6845, "speaker": "Michael Scott"},
  {"season": 2, "episode": 20, "scene": 24, "line": "We don't feel that way.", "order": 6846, "speaker": "Phyllis Lapin"},
  {"season": 2, "episode": 20, "scene": 24, "line": "No, not at all.", "order": 6847, "speaker": "Angela Martin"},
  {"season": 2, "episode": 20, "scene": 24, "line": "You were the one joking around calling Dwight a narc.", "order": 6848, "speaker": "Oscar Martinez"},
  {"season": 2, "episode": 20, "scene": 24, "line": "No, uh, no. That was a test. I was testing you, and you all failed. Miserably. When I said that Dwight was a narc, how many of you defended him? How many of you said, 'Hey, you know what, he's right? What he's doing is protecting this office from the evils of drugs.'", "order": 6849, "speaker": "Michael Scott"},
  {"season": 2, "episode": 20, "scene": 24, "line": "Thank you, Michael.", "order": 6850, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 20, "scene": 25, "line": "I am ridiculously anti-drug. So anti-drug that I am above suspicion in any way that involves suspicion, or testing of any kind.", "order": 6851, "speaker": "Michael Scott"},
  {"season": 2, "episode": 20, "scene": 26, "line": "Drugs ruin lives people. Drugs destroy careers. Take Cheech and Chong everybody knows that Cheech and Chong are funny, but just imagine how funny they would be if they didn't smoke pot. I want everybody to take a look to their left. Now I want everybody to take a look to their right. One of those people will be dead from drug use at some point in their lives. This year more people will use cocaine than will read a book to their children.", "order": 6852, "speaker": "Michael Scott"},
  {"season": 2, "episode": 20, "scene": 26, "line": "Where did you get these facts?", "order": 6853, "speaker": "Stanley Hudson"},
  {"season": 2, "episode": 20, "scene": 26, "line": "Are these facts scaring you, or are they not?", "order": 6854, "speaker": "Michael Scott"},
  {"season": 2, "episode": 20, "scene": 26, "line": "They are not.", "order": 6855, "speaker": "Stanley Hudson"},
  {"season": 2, "episode": 20, "scene": 26, "line": "Do you think that smoking drugs is cool? Do you think that doing alcohol is cool?", "order": 6856, "speaker": "Michael Scott"},
  {"season": 2, "episode": 20, "scene": 26, "line": "No, I don't. I have a glass of red wine with dinner about once a week. For the antioxidants.", "order": 6857, "speaker": "Stanley Hudson"},
  {"season": 2, "episode": 20, "scene": 26, "line": "Okay, enough, enough, enough! I have written down a list of illegal drugs. Take a gander. How many of these are you familiar with?", "order": 6858, "speaker": "Michael Scott"},
  {"season": 2, "episode": 20, "scene": 26, "line": "Hookah is not an illegal drug, Michael.", "order": 6859, "speaker": "Toby Flenderson"},
  {"season": 2, "episode": 20, "scene": 26, "line": "Yes it is.", "order": 6860, "speaker": "Michael Scott"},
  {"season": 2, "episode": 20, "scene": 26, "line": "No it's not. It's a type of pipe. You can fill it with tobacco, often mixed with fruit, or other flavors.", "order": 6861, "speaker": "Toby Flenderson"},
  {"season": 2, "episode": 20, "scene": 26, "line": "Okay, you know what, Toby? Pam, can you take this down? [Pam throws her hands up to indicate she has no notepad] In addition to Toby's urine being tested, I would like to test his blood and his hair.", "order": 6862, "speaker": "Michael Scott"},
  {"season": 2, "episode": 20, "scene": 26, "line": "You can't do that.", "order": 6863, "speaker": "Toby Flenderson"},
  {"season": 2, "episode": 20, "scene": 26, "line": "I can test anyone randomly, and I have chosen you randomly.", "order": 6864, "speaker": "Michael Scott"},
  {"season": 2, "episode": 20, "scene": 26, "line": "That's not random.", "order": 6865, "speaker": "Toby Flenderson"},
  {"season": 2, "episode": 20, "scene": 26, "line": "Okay, eeny, meenie, miney, moe, is random. Okay, you know what? I'm going to need a volunteer to select one of these words and tell us of something tragic that happened in either their lives, or the lives of a loved one. Yeah, Pam.", "order": 6866, "speaker": "Michael Scott"},
  {"season": 2, "episode": 20, "scene": 26, "line": "I know that Jim has an amazing story about a relative of his who got caught up in the world of drugs.", "order": 6867, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 20, "scene": 26, "line": "Really? [Jim shakes his head no]", "order": 6868, "speaker": "Michael Scott"},
  {"season": 2, "episode": 20, "scene": 26, "line": "Uh, hmmm.", "order": 6869, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 20, "scene": 26, "line": "Jim it's okay. You can t... [Jim looks at Pam and shakes his head, Pam looks at him and gestures for Jim to go up and tell his story] This would be a good place to let it out, Jim. These are people you can trust. These are people who care about you. [Jim shakes his head no] It's okay, just we will not judge you. We are here to not judge you. [Jim stands up] Oh, he's doing it, okay. [Jim looks at Pam] It's okay. [Jim pretends to try, looking teary eyed, shakes his head no, mouths 'I can't' and sits back down. Pam is amazed.] Oh. Okay, are you sure? [Jim shakes his head. Kevin pats his shoulder] That looked like it was going to be good. Alright. [Pam nods in admiration at Jim] Okay, well.", "order": 6870, "speaker": "Michael Scott"},
  {"season": 2, "episode": 20, "scene": 27, "line": "Wow! He really pulled out the big guns. Fake crying. Did not expect that.", "order": 6871, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 20, "scene": 28, "line": "The point I'm trying to make with all of this people is that I hate drugs. I hate them, and based upon what I have seen you all don't quite hate 'em as much as I do so you are going to have a drug test, and I am not.", "order": 6872, "speaker": "Michael Scott"},
  {"season": 2, "episode": 20, "scene": 28, "line": "No, you will be tested.", "order": 6873, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 20, "scene": 28, "line": "Yes, I will not be.", "order": 6874, "speaker": "Michael Scott"},
  {"season": 2, "episode": 20, "scene": 28, "line": "You will be. That is the law according to the rules.", "order": 6875, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 20, "scene": 28, "line": "Okay, well Dwight just know that I've been very busy today and I got a lot of work to do and I wasn't planning on going to the bathroom and I don't even know if anything is going to come out, okay? So good. Thank you.", "order": 6876, "speaker": "Michael Scott"},
  {"season": 2, "episode": 20, "scene": 29, "line": "Hi, Linda. Dwight Schrute, Assistant Regional Manager. You might remember testing my urine a few years back when I was applying to be a volunteer sheriff's deputy.", "order": 6877, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 20, "scene": 29, "line": "We test a lot of urine.", "order": 6878, "speaker": "Linda"},
  {"season": 2, "episode": 20, "scene": 29, "line": "Mine was green.", "order": 6879, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 20, "scene": 29, "line": "Oh, right. How are you?", "order": 6880, "speaker": "Linda"},
  {"season": 2, "episode": 20, "scene": 29, "line": "I'm all better.", "order": 6881, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 20, "scene": 30, "line": "So I need you to do some work on the St. Andrews account. I need your urine. I need some filing done.", "order": 6882, "speaker": "Michael Scott"},
  {"season": 2, "episode": 20, "scene": 30, "line": "What kind of filing?", "order": 6883, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 20, "scene": 30, "line": "Just forget it. Just the urine.", "order": 6884, "speaker": "Michael Scott"},
  {"season": 2, "episode": 20, "scene": 30, "line": "That goes directly to the tester.", "order": 6885, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 20, "scene": 30, "line": "Just. I need your urine.", "order": 6886, "speaker": "Michael Scott"},
  {"season": 2, "episode": 20, "scene": 30, "line": "Like in a cup?", "order": 6887, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 20, "scene": 30, "line": "Yes in a cup, we're not animals, Dwight.", "order": 6888, "speaker": "Michael Scott"},
  {"season": 2, "episode": 20, "scene": 30, "line": "For what purpose?", "order": 6889, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 20, "scene": 30, "line": "It's none of your business.", "order": 6890, "speaker": "Michael Scott"},
  {"season": 2, "episode": 20, "scene": 30, "line": "Then I refuse.", "order": 6891, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 20, "scene": 30, "line": "Okay. Alright. Just, I went to an Alicia Keys concert, over the weekend, and I think I may have gotten high accidentally by a girl with a lip ring.", "order": 6892, "speaker": "Michael Scott"},
  {"season": 2, "episode": 20, "scene": 30, "line": "Are you serious?", "order": 6893, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 20, "scene": 30, "line": "I need clean urine for the lady.", "order": 6894, "speaker": "Michael Scott"},
  {"season": 2, "episode": 20, "scene": 30, "line": "But that's illegal.", "order": 6895, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 20, "scene": 30, "line": "Don't think of it that way. It's like, urine goes all over the place. You know, there's no controlling it. It just... goes", "order": 6896, "speaker": "Michael Scott"},
  {"season": 2, "episode": 20, "scene": 30, "line": "Not my urine.", "order": 6897, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 20, "scene": 30, "line": "A cup could find its way under the urine. It might be an accident. It happens.", "order": 6898, "speaker": "Michael Scott"},
  {"season": 2, "episode": 20, "scene": 30, "line": "Were you forced to do drugs at this concert?", "order": 6899, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 20, "scene": 30, "line": "No, just look. Look. Just... just fill up the cup.", "order": 6900, "speaker": "Michael Scott"},
  {"season": 2, "episode": 20, "scene": 31, "line": "Do you want to give Michael your urine?", "order": 6901, "speaker": "Angela Martin"},
  {"season": 2, "episode": 20, "scene": 31, "line": "I want him to have all the urine he needs.", "order": 6902, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 20, "scene": 31, "line": "You're not going to get my permission on this.", "order": 6903, "speaker": "Angela Martin"},
  {"season": 2, "episode": 20, "scene": 31, "line": "I know that. Don't you think I know that?", "order": 6904, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 20, "scene": 32, "line": "Yeah, we do testing all over the country.", "order": 6905, "speaker": "Linda"},
  {"season": 2, "episode": 20, "scene": 32, "line": "Cool. Hey, are you guys hiring?", "order": 6906, "speaker": "Ryan Howard"},
  {"season": 2, "episode": 20, "scene": 32, "line": "You want to work at the urinalysis lab?", "order": 6907, "speaker": "Linda"},
  {"season": 2, "episode": 20, "scene": 32, "line": "Yeah. Maybe.", "order": 6908, "speaker": "Ryan Howard"},
  {"season": 2, "episode": 20, "scene": 33, "line": "My father's name was Dwight Schrute. My grandfather's name was Dwight Schrute. His father's name Dwide Schrude. Amish. I loved my father very much. Every morning he'd wake up at dawn and make us biscuits with gravy. When I was little my dad and I played a lot of games together. My dad cheated a lot but I never busted him on it. I would have, except I didn't know about it. He didn't tell me till years later. I was shocked when I found out.", "order": 6909, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 20, "scene": 34, "line": "What? [Jim shakes his head] Did you want to tell me something? You look like you want to tell me something. [Jim shakes his head no] You look like you have something really important to say and you just can't for some reason. [Jim smiles] Come on, you can tell me. Jim, you can tell me anything. [Jim stops smiling and looks down. Pam wonders what that means]", "order": 6910, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 20, "scene": 35, "line": "I'd like a magazine.", "order": 6911, "speaker": "Kevin Malone"},
  {"season": 2, "episode": 20, "scene": 35, "line": "We just need urine, sir.", "order": 6912, "speaker": "Linda"},
  {"season": 2, "episode": 20, "scene": 35, "line": "I'd still like one.", "order": 6913, "speaker": "Kevin Malone"},
  {"season": 2, "episode": 20, "scene": 36, "line": "Dwight. Well, I passed the test thanks to you and your untainted pee. Thank you very much.", "order": 6914, "speaker": "Michael Scott"},
  {"season": 2, "episode": 20, "scene": 36, "line": "That's great.", "order": 6915, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 20, "scene": 36, "line": "What's wrong? Where's your costume?", "order": 6916, "speaker": "Michael Scott"},
  {"season": 2, "episode": 20, "scene": 36, "line": "It's a uniform and I turned it in today when I tendered my resignation.", "order": 6917, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 20, "scene": 36, "line": "Why? Wha...", "order": 6918, "speaker": "Michael Scott"},
  {"season": 2, "episode": 20, "scene": 36, "line": "I took an oath when I was sworn in and I broke that oath today.", "order": 6919, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 20, "scene": 37, "line": "[placing a coke can in front of Jim] Here. [Jim looks confused] Just buy it from me. I haven't talked to you in hours and it's been weird and I really want to know what the hell's going on with Dwight. [Pam scoots the coke can towards Jim. Jim pulls out his wallet and hands Pam a dollar. He gives the coke back to Pam]", "order": 6920, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 20, "scene": 37, "line": "Hi.", "order": 6921, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 20, "scene": 37, "line": "Hey.", "order": 6922, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 20, "scene": 37, "line": "How much time do you have left on your break?", "order": 6923, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 20, "scene": 37, "line": "Ten minutes.", "order": 6924, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 20, "scene": 38, "line": "Since you did such a good job with the investigation, I decided to pull a few strings. Call in a few favors. and I've decided to make you official security supervisor of the branch.", "order": 6925, "speaker": "Michael Scott"},
  {"season": 2, "episode": 20, "scene": 38, "line": "Really?", "order": 6926, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 20, "scene": 38, "line": "Yes, sir.", "order": 6927, "speaker": "Michael Scott"},
  {"season": 2, "episode": 20, "scene": 38, "line": "That's fantastic because I've always felt that the security here sucked .", "order": 6928, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 20, "scene": 38, "line": "So you wanna? Thanks.", "order": 6929, "speaker": "Michael Scott"},
  {"season": 2, "episode": 20, "scene": 38, "line": "Dwight K. Schrute, I hereby declare you an honorary voluntary corporal in charge of assisting all activities security.", "order": 6930, "speaker": "Hank Tate"},
  {"season": 2, "episode": 20, "scene": 38, "line": "Okay.", "order": 6931, "speaker": "Michael Scott"},
  {"season": 2, "episode": 20, "scene": 38, "line": "Here's your badge.", "order": 6932, "speaker": "Hank Tate"},
  {"season": 2, "episode": 20, "scene": 38, "line": "Thank you, Michael. Very nice. Great. [To Hank] Can I have a gun?", "order": 6933, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 20, "scene": 38, "line": "No, I don't have a gun.", "order": 6934, "speaker": "Hank Tate"},
  {"season": 2, "episode": 20, "scene": 38, "line": "Okay, I'll have to bring in my bow staff.", "order": 6935, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 20, "scene": 38, "line": "I don't think so.", "order": 6936, "speaker": "Hank Tate"},
  {"season": 2, "episode": 20, "scene": 38, "line": "Good.", "order": 6937, "speaker": "Michael Scott"},
  {"season": 2, "episode": 20, "scene": 38, "line": "[salutes] Thank you, Michael.", "order": 6938, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 20, "scene": 38, "line": "No. Oh. Uh...[awkwardly salutes]", "order": 6939, "speaker": "Michael Scott"},
  {"season": 2, "episode": 20, "scene": 38, "line": "I need to go over some details with you.", "order": 6940, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 20, "scene": 38, "line": "Alright. [to Hank] Well, Thank you.", "order": 6941, "speaker": "Michael Scott"},
  {"season": 2, "episode": 20, "scene": 38, "line": "First of all, Hank, how many orange traffic cones do you have?", "order": 6942, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 20, "scene": 38, "line": "Two.", "order": 6943, "speaker": "Hank Tate"},
  {"season": 2, "episode": 20, "scene": 38, "line": "Oh, God.", "order": 6944, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 20, "scene": 39, "line": "Wow. What a terrible day to not be able to talk. Dwight was literally carrying around his own urine and dressed like one of the Village People. Why does he do the things that he does for Michael? I just don't get it. What is he getting out of that relationship?", "order": 6945, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 21, "scene": 1, "line": "So, uh... you found a band for your wedding yet?", "order": 6946, "speaker": "Kevin Malone"},
  {"season": 2, "episode": 21, "scene": 1, "line": "No.", "order": 6947, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 21, "scene": 1, "line": "Cause I'm in a band. We really rock.", "order": 6948, "speaker": "Kevin Malone"},
  {"season": 2, "episode": 21, "scene": 2, "line": "Yeah, I mean it's inevitable. I definitely overhear some wedding preparation, but I'm fine with it. She hears me arranging my social life. And we both have to hear Dwight order deer urine over the Internet, so it evens out.", "order": 6949, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 21, "scene": 3, "line": "Oh I got the 'Save The Date'.", "order": 6950, "speaker": "Phyllis Lapin"},
  {"season": 2, "episode": 21, "scene": 3, "line": "Yeah?", "order": 6951, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 21, "scene": 3, "line": "Yeah, pretty stationery.", "order": 6952, "speaker": "Phyllis Lapin"},
  {"season": 2, "episode": 21, "scene": 3, "line": "Oh, thanks!", "order": 6953, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 21, "scene": 3, "line": "I didn't get mine yet.", "order": 6954, "speaker": "Angela Martin"},
  {"season": 2, "episode": 21, "scene": 3, "line": "Uh...", "order": 6955, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 21, "scene": 4, "line": "There are a few people I decided not to invite, and that might make things kind of awkward but ... it's my wedding. And I don't want anyone there who has called me a hussy.", "order": 6956, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 21, "scene": 5, "line": "Yes, thanks, Fantastic Sam's. Adult Cut Plus. Comes with a shampoo and blow dry. We're doing I.D. photos today. Gotta represent.", "order": 6957, "speaker": "Michael Scott"},
  {"season": 2, "episode": 21, "scene": 6, "line": "Uh, on or off?", "order": 6958, "speaker": "Phyllis Lapin"},
  {"season": 2, "episode": 21, "scene": 6, "line": "Off.", "order": 6959, "speaker": "I.D. Photographer"},
  {"season": 2, "episode": 21, "scene": 6, "line": "Okay... [removes glasses]", "order": 6960, "speaker": "Phyllis Lapin"},
  {"season": 2, "episode": 21, "scene": 6, "line": "Oh! What is on your face? Is that a disguise?", "order": 6961, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 21, "scene": 6, "line": "[leaving the room] Excuse me.", "order": 6962, "speaker": "Phyllis Lapin"},
  {"season": 2, "episode": 21, "scene": 6, "line": "Clown paint.", "order": 6963, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 21, "scene": 7, "line": "I.D. badges are long overdue. Security in this office park is a joke. Last year, I came to work with my spud gun in a duffel bag. I sat at my desk all day, with a rifle that shoots potatoes at 60 pounds per square inch. Can you imagine if I was deranged?", "order": 6964, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 21, "scene": 8, "line": "That's a nice tie.", "order": 6965, "speaker": "Michael Scott"},
  {"season": 2, "episode": 21, "scene": 8, "line": "Thank you.", "order": 6966, "speaker": "Ryan Howard"},
  {"season": 2, "episode": 21, "scene": 8, "line": "That is... who makes that?", "order": 6967, "speaker": "Michael Scott"},
  {"season": 2, "episode": 21, "scene": 8, "line": "Um, I don't...", "order": 6968, "speaker": "Ryan Howard"},
  {"season": 2, "episode": 21, "scene": 8, "line": "Do you mind if I wear that for the photo?", "order": 6969, "speaker": "Michael Scott"},
  {"season": 2, "episode": 21, "scene": 8, "line": "Um... let's um, let's keep our clothes.", "order": 6970, "speaker": "Ryan Howard"},
  {"season": 2, "episode": 21, "scene": 9, "line": "It's like child abuse. I say, if Jesus saw that, he'd freak out! He'd freak out, Toby! I mean on some levels... it's... and I'm supposed to work there. I'm supposed...", "order": 6971, "speaker": "Oscar Martinez"},
  {"season": 2, "episode": 21, "scene": 9, "line": "[walking into the Conference room] What's the dealio?", "order": 6972, "speaker": "Michael Scott"},
  {"season": 2, "episode": 21, "scene": 9, "line": "Just letting Oscar vent a little. We would use the break room, but the photographer's back there today.", "order": 6973, "speaker": "Toby Flenderson"},
  {"season": 2, "episode": 21, "scene": 9, "line": "What's the problem?", "order": 6974, "speaker": "Michael Scott"},
  {"season": 2, "episode": 21, "scene": 9, "line": "Angela!", "order": 6975, "speaker": "Oscar Martinez"},
  {"season": 2, "episode": 21, "scene": 9, "line": "It's just a little dispute over a poster in their workspace.", "order": 6976, "speaker": "Toby Flenderson"},
  {"season": 2, "episode": 21, "scene": 9, "line": "Since Christmas.", "order": 6977, "speaker": "Oscar Martinez"},
  {"season": 2, "episode": 21, "scene": 9, "line": "So what, you're having a little spat. I forget, are you guys dating?", "order": 6978, "speaker": "Michael Scott"},
  {"season": 2, "episode": 21, "scene": 9, "line": "No.", "order": 6979, "speaker": "Oscar Martinez"},
  {"season": 2, "episode": 21, "scene": 9, "line": "Michael, can I talk to you for a uh, second please?", "order": 6980, "speaker": "Toby Flenderson"},
  {"season": 2, "episode": 21, "scene": 9, "line": "Yes.", "order": 6981, "speaker": "Michael Scott"},
  {"season": 2, "episode": 21, "scene": 10, "line": "Here's how I usually handle this: all I do is listen.", "order": 6982, "speaker": "Toby Flenderson"},
  {"season": 2, "episode": 21, "scene": 10, "line": "Yeah?", "order": 6983, "speaker": "Michael Scott"},
  {"season": 2, "episode": 21, "scene": 10, "line": "These things just have a way of working themselves out.", "order": 6984, "speaker": "Toby Flenderson"},
  {"season": 2, "episode": 21, "scene": 10, "line": "Okay.", "order": 6985, "speaker": "Michael Scott"},
  {"season": 2, "episode": 21, "scene": 10, "line": "It's like if you write someone a letter, when you're really angry... they say to keep it in a drawer for a couple days. Then you just never end up sending it.", "order": 6986, "speaker": "Toby Flenderson"},
  {"season": 2, "episode": 21, "scene": 10, "line": "What do you know about conflict resolution? Your answer to everything is to get divorced. So...", "order": 6987, "speaker": "Michael Scott"},
  {"season": 2, "episode": 21, "scene": 10, "line": "Okay.", "order": 6988, "speaker": "Toby Flenderson"},
  {"season": 2, "episode": 21, "scene": 10, "line": "Okay... what?", "order": 6989, "speaker": "Michael Scott"},
  {"season": 2, "episode": 21, "scene": 10, "line": "That was the right decision for me and my marriage.", "order": 6990, "speaker": "Toby Flenderson"},
  {"season": 2, "episode": 21, "scene": 10, "line": "Yeah, well... that's not gonna fly here. Because in this office, it is till death do us part... assuming we don't get downsized. [leans over to Pam] Uh, Pam, will you get Angela and meet us in the conference room please?", "order": 6991, "speaker": "Michael Scott"},
  {"season": 2, "episode": 21, "scene": 11, "line": "[holding up a binder] A mediator's tool chest. Okay, well, before we get started, you should know that are 5 different styles of conflict.[in a comedic voice] My Shaolin temple style defeats your monkey style.", "order": 6992, "speaker": "Michael Scott"},
  {"season": 2, "episode": 21, "scene": 11, "line": "Can we go? I have a lot of work to do.", "order": 6993, "speaker": "Angela Martin"},
  {"season": 2, "episode": 21, "scene": 11, "line": "No. Okay, this is important. The first style is lose/lose.", "order": 6994, "speaker": "Michael Scott"},
  {"season": 2, "episode": 21, "scene": 11, "line": "What's the next one?", "order": 6995, "speaker": "Oscar Martinez"},
  {"season": 2, "episode": 21, "scene": 11, "line": "Just hold on, please! Okay, if we do lose/lose, neither of you gets what you want. Do you understand? You... you would both lose. Now I need to ask you, do you want to pursue a lose/lose negotiation?", "order": 6996, "speaker": "Michael Scott"},
  {"season": 2, "episode": 21, "scene": 11, "line": "Can we just skip to whatever number 5 is - win/win or whatever?", "order": 6997, "speaker": "Angela Martin"},
  {"season": 2, "episode": 21, "scene": 11, "line": "Win/Win is number four and number five is win/win/win. The important difference here is with win/win/win, we all win. Me too. I win for having successfully mediated a conflict at work.", "order": 6998, "speaker": "Michael Scott"},
  {"season": 2, "episode": 21, "scene": 12, "line": "[in front of poster] Okay, since this is the disputed poster. Now, one at a time, I want you to express your feelings using 'I' emotion language and no judging or 'you' statements.", "order": 6999, "speaker": "Michael Scott"},
  {"season": 2, "episode": 21, "scene": 12, "line": "I got this poster for Christmas, and I feel I want to see it everyday. It makes me feel like the babies are the true artists, and God has a really cute sense of humor.", "order": 7000, "speaker": "Angela Martin"},
  {"season": 2, "episode": 21, "scene": 12, "line": "Come on, seriously, that?", "order": 7001, "speaker": "Michael Scott"},
  {"season": 2, "episode": 21, "scene": 12, "line": "I don't like looking at it. It's creepy, and in bad taste, and it's just offensive to me. It makes me think of the horrible, frigid stage mothers who force the babies into it. It's kitsch. It's the opposite of art. It destroys art. It destroys souls. This is so much more offensive to me than hardcore porno. I'm talking about the...", "order": 7002, "speaker": "Oscar Martinez"},
  {"season": 2, "episode": 21, "scene": 12, "line": "Okay, okay. Stop, stop, stop! Let's see if we can just brainstorm and find some creative alternatives that are win/win.", "order": 7003, "speaker": "Michael Scott"},
  {"season": 2, "episode": 21, "scene": 12, "line": "Win!", "order": 7004, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 21, "scene": 12, "line": "Yes. Thank you, Pam. How about Angela makes the poster into a t-shirt, which Oscar wears. That way, he can never see it and whenever she looks at Oscar, she can see it. Win/win/win.", "order": 7005, "speaker": "Michael Scott"},
  {"season": 2, "episode": 21, "scene": 12, "line": "No.", "order": 7006, "speaker": "Oscar Martinez"},
  {"season": 2, "episode": 21, "scene": 12, "line": "That's... no...", "order": 7007, "speaker": "Angela Martin"},
  {"season": 2, "episode": 21, "scene": 12, "line": "Okay... well, brainstorm. Own the solution.", "order": 7008, "speaker": "Michael Scott"},
  {"season": 2, "episode": 21, "scene": 12, "line": "How about, I leave it up?", "order": 7009, "speaker": "Angela Martin"},
  {"season": 2, "episode": 21, "scene": 12, "line": "How 'bout, she takes it down?", "order": 7010, "speaker": "Oscar Martinez"},
  {"season": 2, "episode": 21, "scene": 12, "line": "How about, Angela can keep it up on Tuesdays and Thursdays?", "order": 7011, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 21, "scene": 12, "line": "Okay, that is called a compromise. And it is style 3. And it is not ideal. To sum up, win/win - make the poster into a t-shirt, win/lose - take the poster down, compromise - Tuesdays and Thursdays. And the answer is... make the poster into a t-shirt! Win/win.", "order": 7012, "speaker": "Michael Scott"},
  {"season": 2, "episode": 21, "scene": 12, "line": "Win.", "order": 7013, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 21, "scene": 12, "line": "Fine.", "order": 7014, "speaker": "Oscar Martinez"},
  {"season": 2, "episode": 21, "scene": 12, "line": "But, it...", "order": 7015, "speaker": "Angela Martin"},
  {"season": 2, "episode": 21, "scene": 12, "line": "[claps his hands twice] It is done!", "order": 7016, "speaker": "Michael Scott"},
  {"season": 2, "episode": 21, "scene": 12, "line": "Win", "order": 7017, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 21, "scene": 13, "line": "[snaps a photo of Creed, then Creed turns to the side for a profile shot] No, you're all good.", "order": 7018, "speaker": "Photographer"},
  {"season": 2, "episode": 21, "scene": 13, "line": "Great. [gets up and leaves]", "order": 7019, "speaker": "Creed Bratton"},
  {"season": 2, "episode": 21, "scene": 14, "line": "Hey, Angela.[hands her a Save The Date card] I didn't have your zip code.", "order": 7020, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 21, "scene": 14, "line": "Oh. Thanks.", "order": 7021, "speaker": "Angela Martin"},
  {"season": 2, "episode": 21, "scene": 15, "line": "It was hand delivered. But, I did get a Save The Date after all. It's not my taste.", "order": 7022, "speaker": "Angela Martin"},
  {"season": 2, "episode": 21, "scene": 16, "line": "You solved it?", "order": 7023, "speaker": "Toby Flenderson"},
  {"season": 2, "episode": 21, "scene": 16, "line": "Yes.", "order": 7024, "speaker": "Michael Scott"},
  {"season": 2, "episode": 21, "scene": 16, "line": "Well, good. We can, uh, throw that one out. [shuffles through papers]", "order": 7025, "speaker": "Toby Flenderson"},
  {"season": 2, "episode": 21, "scene": 16, "line": "Are those all the other complains?", "order": 7026, "speaker": "Michael Scott"},
  {"season": 2, "episode": 21, "scene": 16, "line": "Mmm-Hm.", "order": 7027, "speaker": "Toby Flenderson"},
  {"season": 2, "episode": 21, "scene": 16, "line": "I would like to see those please.", "order": 7028, "speaker": "Michael Scott"},
  {"season": 2, "episode": 21, "scene": 16, "line": "I... I can't do that.", "order": 7029, "speaker": "Toby Flenderson"},
  {"season": 2, "episode": 21, "scene": 16, "line": "You can't do that, huh? Huh, ok. Now you and I have a conflict. I order you to give me that file.", "order": 7030, "speaker": "Michael Scott"},
  {"season": 2, "episode": 21, "scene": 16, "line": "That... [shakes head and places hand over the file]", "order": 7031, "speaker": "Toby Flenderson"},
  {"season": 2, "episode": 21, "scene": 16, "line": "Okay. [yanks the file away, despite Toby's resistance] There! No more conflict. [looks at the camera] I had to use win/lose on that. It was not pretty. [looks back at Toby] All right... is that it?", "order": 7032, "speaker": "Michael Scott"},
  {"season": 2, "episode": 21, "scene": 16, "line": "[sighs and pulls out a box under his desk] It's all Dwight's.", "order": 7033, "speaker": "Toby Flenderson"},
  {"season": 2, "episode": 21, "scene": 17, "line": "Every Friday at 4, I have a standing appointment with Dwight for him to file a grievance against Jim. I tell him that I'm sending them to a special file in New York. That box is the special file in New York.", "order": 7034, "speaker": "Toby Flenderson"},
  {"season": 2, "episode": 21, "scene": 18, "line": "Ohh... God. Alright. Why do I have to do everything?", "order": 7035, "speaker": "Michael Scott"},
  {"season": 2, "episode": 21, "scene": 19, "line": "Are you sure? [looks at Oscar, who is front of the camera, holding the baby poster in front of his chest]", "order": 7036, "speaker": "Photographer"},
  {"season": 2, "episode": 21, "scene": 19, "line": "Oh, he's sure. Just shoot.", "order": 7037, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 21, "scene": 19, "line": "[shoots twice]", "order": 7038, "speaker": "Photographer"},
  {"season": 2, "episode": 21, "scene": 20, "line": "[looking through papers in the complaint box] This is from Kevin. He says Stanley uses his Miracle Whip without asking. Meredith complains that everyone talks too loud in the morning and the lights are too bright. Creed... huh. Duh, duh. Creed is sick of looking at the redhead all day and wants a seat facing the receptionist.", "order": 7039, "speaker": "Michael Scott"},
  {"season": 2, "episode": 21, "scene": 20, "line": "Nice.", "order": 7040, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 21, "scene": 20, "line": "You will notice that not one of these complaints is against me.", "order": 7041, "speaker": "Michael Scott"},
  {"season": 2, "episode": 21, "scene": 21, "line": "Actually, I have a separate folder for complaints against Michael. This [unlocks a drawer]is January through March of this year. [pulls out a fairly large folder]", "order": 7042, "speaker": "Toby Flenderson"},
  {"season": 2, "episode": 21, "scene": 22, "line": "How many of you have at one time gone to Toby to complain about another employee? [looks at all the employees, most of whom raise their hands] And... did you get what you wanted, or were you merely listened to, you forget about your problem, and you move on? [employees mumble 'merely listen to and forgotten...' ] That is outrageous! I love this place... and it pains me to see all of the negativity festering... [sighs] Okay, today we are going to get everything out of these files and into the open, where it can be resolved. Alright, how about the Phyllis/Angela dispute?", "order": 7043, "speaker": "Michael Scott"},
  {"season": 2, "episode": 21, "scene": 22, "line": "You already did me.", "order": 7044, "speaker": "Angela Martin"},
  {"season": 2, "episode": 21, "scene": 22, "line": "That's what she said. [Jim mouths these words along with Michael] The thing is, Angela... you are in here an awful lot. You have complained about everybody in the office, except Dwight, which is odd because everyone else has had run ins with Dwight. Toby, by the way, what does 'redacted' mean? There is a file full of complaints in here marked 'redacted'... ?", "order": 7045, "speaker": "Michael Scott"},
  {"season": 2, "episode": 21, "scene": 22, "line": "Yeah, it just means whoever complained came to me later and withdrew it, so I took their name off.", "order": 7046, "speaker": "Toby Flenderson"},
  {"season": 2, "episode": 21, "scene": 22, "line": "Oh, ok. There were a bunch of complaints about Dwight that were put in the redacted file about six months ago.", "order": 7047, "speaker": "Michael Scott"},
  {"season": 2, "episode": 21, "scene": 22, "line": "Whoa.. wha... wait. If someone has a problem with me, why would they withdraw it six months ago?", "order": 7048, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 21, "scene": 22, "line": "[notices Angela's intense concern] Um... let's move on. I volunteer. Did anyone have a problem with me?", "order": 7049, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 21, "scene": 22, "line": "All right, Pamela. Come on down! Let's do it! And [looks through the file]... okay. Just one complaint. Actually, it has been withdrawn. So that is no help to us. Next.", "order": 7050, "speaker": "Michael Scott"},
  {"season": 2, "episode": 21, "scene": 22, "line": "Wait, what did it say?", "order": 7051, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 21, "scene": 22, "line": "Uh... [reading]'Does she have to plan her wedding on office time? Couldn't she do that at home?' [Pam looks Angela an angry look] Who else? Why don't we just warm up first? Warm up emotionally, all right?", "order": 7052, "speaker": "Michael Scott"},
  {"season": 2, "episode": 21, "scene": 23, "line": "I have this kind of big secret about Angela. And I've been really nice to her... and I haven't told anyone. And what the hell?!", "order": 7053, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 21, "scene": 24, "line": "Here is a Kelly complaint: 'Ryan never returns my calls.' Ugh, join the club.", "order": 7054, "speaker": "Michael Scott"},
  {"season": 2, "episode": 21, "scene": 24, "line": "My voicemail's really spotty... sometimes...", "order": 7055, "speaker": "Ryan Howard"},
  {"season": 2, "episode": 21, "scene": 24, "line": "I didn't file a complaint. I was just talking.", "order": 7056, "speaker": "Kelly Kapoor"},
  {"season": 2, "episode": 21, "scene": 24, "line": "To your HR representative.", "order": 7057, "speaker": "Toby Flenderson"},
  {"season": 2, "episode": 21, "scene": 24, "line": "To my friend, I thought. I want that withdrawn.", "order": 7058, "speaker": "Kelly Kapoor"},
  {"season": 2, "episode": 21, "scene": 24, "line": "Fine, I'll take your name off. [looks at Michael] So no one will know.", "order": 7059, "speaker": "Toby Flenderson"},
  {"season": 2, "episode": 21, "scene": 24, "line": "[crumbles up the complaint paper] Makin' progress. [Jim raises his hand] Yes?", "order": 7060, "speaker": "Michael Scott"},
  {"season": 2, "episode": 21, "scene": 24, "line": "Dwight tried to kiss me.", "order": 7061, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 21, "scene": 24, "line": "What?!", "order": 7062, "speaker": "Michael Scott"},
  {"season": 2, "episode": 21, "scene": 24, "line": "And I didn't tell anyone because I'm not really sure how I feel about it.", "order": 7063, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 21, "scene": 24, "line": "That is not true. Redact it. Redact it!", "order": 7064, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 21, "scene": 24, "line": "Well, I'm not actually making a formal complaint. I just really think we should talk about it.", "order": 7065, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 21, "scene": 24, "line": "Okay, question. [looks at Toby] When a name is withdrawn, does that also apply to the permanent misbehavior file in New York?", "order": 7066, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 21, "scene": 24, "line": "Sure.", "order": 7067, "speaker": "Toby Flenderson"},
  {"season": 2, "episode": 21, "scene": 25, "line": "Stanley. [off camera]", "order": 7068, "speaker": "Michael Scott"},
  {"season": 2, "episode": 21, "scene": 25, "line": "[gets up and walks over to Angela, whispering to her] Hey. Thanks for ratting me out!", "order": 7069, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 21, "scene": 25, "line": "[still of camera] You got a lot of anger under there buddy. Come on start us out. Unleash it.", "order": 7070, "speaker": "Michael Scott"},
  {"season": 2, "episode": 21, "scene": 25, "line": "I didn't do it! [Michael and Stanley continue to talk off camera, but it's inaudible]", "order": 7071, "speaker": "Angela Martin"},
  {"season": 2, "episode": 21, "scene": 25, "line": "I find that hard to believe... considering you have problems with every single person in this entire office except Bobblehead Joe.", "order": 7072, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 21, "scene": 26, "line": "Someone complained that the men's room is 'whites only'. Stanley, you know that's not true.", "order": 7073, "speaker": "Michael Scott"},
  {"season": 2, "episode": 21, "scene": 26, "line": "I didn't say that.", "order": 7074, "speaker": "Stanley Hudson"},
  {"season": 2, "episode": 21, "scene": 26, "line": "Then why is there a picture of a white man on the door? [Michael, along with the camera, look at the standard stick man on the bathroom door.]", "order": 7075, "speaker": "Creed Bratton"},
  {"season": 2, "episode": 21, "scene": 26, "line": "Okay, Phyllis. You complained that Angela's giving you dirty looks. And you tried to get off the party planning committee.", "order": 7076, "speaker": "Michael Scott"},
  {"season": 2, "episode": 21, "scene": 26, "line": "No, I never said any such thing. Angela and I are close.", "order": 7077, "speaker": "Phyllis Lapin"},
  {"season": 2, "episode": 21, "scene": 26, "line": "And... also, Phyllis, Stanley says that you cry too much, and that bugs him.", "order": 7078, "speaker": "Michael Scott"},
  {"season": 2, "episode": 21, "scene": 26, "line": "Stanley and I are close, too.", "order": 7079, "speaker": "Phyllis Lapin"},
  {"season": 2, "episode": 21, "scene": 26, "line": "We sit close.", "order": 7080, "speaker": "Stanley Hudson"},
  {"season": 2, "episode": 21, "scene": 26, "line": "Oh... ok.", "order": 7081, "speaker": "Michael Scott"},
  {"season": 2, "episode": 21, "scene": 27, "line": "[sits down for his ID picture] Just take it. [flashes goes off, while he is standing back up again]", "order": 7082, "speaker": "Toby Flenderson"},
  {"season": 2, "episode": 21, "scene": 28, "line": "I can't believe Angela. I went against my better judgment, and I gave her a Save The Date. And now it turns out she complained about me to Toby.", "order": 7083, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 21, "scene": 28, "line": "Well, it was redacted. Look, if she wants an invite, maybe she's just trying to be friends.", "order": 7084, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 21, "scene": 28, "line": "Don't take her side.", "order": 7085, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 21, "scene": 28, "line": "[sighs] Well, what does Roy think about everything?", "order": 7086, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 21, "scene": 28, "line": "I don't know. I try not to bother him about this kind of stuff.", "order": 7087, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 21, "scene": 28, "line": "You mean your thoughts and feelings?", "order": 7088, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 21, "scene": 28, "line": "Yeah.", "order": 7089, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 21, "scene": 28, "line": "Yeah...", "order": 7090, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 21, "scene": 29, "line": "I know you keep saying it's your space, even though there's no assigned parking, but I keep forgetting.", "order": 7091, "speaker": "Phyllis Lapin"},
  {"season": 2, "episode": 21, "scene": 29, "line": "Yes, that's the problem.", "order": 7092, "speaker": "Angela Martin"},
  {"season": 2, "episode": 21, "scene": 29, "line": "I guess so...", "order": 7093, "speaker": "Phyllis Lapin"},
  {"season": 2, "episode": 21, "scene": 29, "line": "Okay, well... all settled, then.", "order": 7094, "speaker": "Michael Scott"},
  {"season": 2, "episode": 21, "scene": 29, "line": "[whispering to Angela] I don't like you.", "order": 7095, "speaker": "Phyllis Lapin"},
  {"season": 2, "episode": 21, "scene": 30, "line": "OK, Ryan. You told Toby that Creed has a distinct old man smell?", "order": 7096, "speaker": "Michael Scott"},
  {"season": 2, "episode": 21, "scene": 31, "line": "I know exactly what he's talking about. I sprout mung beans on a damp paper towel in my desk drawer. Very nutritious. But they smell like death.", "order": 7097, "speaker": "Creed Bratton"},
  {"season": 2, "episode": 21, "scene": 32, "line": "All right, Kevin. You are accused of making sexually suggestive remarks to Angela that made her feel uncomfortable. Solution: Angela, you are to make sexually suggestive remarks to Kevin that will make him uncomfortable.", "order": 7098, "speaker": "Michael Scott"},
  {"season": 2, "episode": 21, "scene": 32, "line": "I accept your decision!", "order": 7099, "speaker": "Kevin Malone"},
  {"season": 2, "episode": 21, "scene": 33, "line": "Hey... you know what, Dwight? Maybe we should get our photo I.D. taken together.", "order": 7100, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 21, "scene": 33, "line": "That doesn't make any sense.", "order": 7101, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 21, "scene": 33, "line": "Well, it saves time, you know. 'Cause we could just meet in the parking lot every morning. Walk in together. Perfect.", "order": 7102, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 21, "scene": 33, "line": "[to Dwight, who is sitting in front of the camera] Smile.", "order": 7103, "speaker": "Photographer"},
  {"season": 2, "episode": 21, "scene": 33, "line": "No.", "order": 7104, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 21, "scene": 34, "line": "I never smile if I can help it. Showing one's teeth is a submission signal in primates. When someone smiles at me, all I see is a chimpanzee begging for its life.", "order": 7105, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 21, "scene": 35, "line": "This came out really well. [picks up Dwight's I.D. from the laminating machine and hands it to Dwight] There you go.", "order": 7106, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 21, "scene": 35, "line": "This is humongous. I am not a security threat...", "order": 7107, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 21, "scene": 35, "line": "Oh.", "order": 7108, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 21, "scene": 35, "line": "And my middle name is 'Kurt', not 'Fart'.", "order": 7109, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 21, "scene": 35, "line": "What did I write?", "order": 7110, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 21, "scene": 36, "line": "I have another complaint for Jim's permanent file.", "order": 7111, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 21, "scene": 36, "line": "Talk to Michael. I gave him the box.", "order": 7112, "speaker": "Toby Flenderson"},
  {"season": 2, "episode": 21, "scene": 36, "line": "What box?!", "order": 7113, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 21, "scene": 37, "line": "But I didn't report your snoring-", "order": 7114, "speaker": "Phyllis Lapin"},
  {"season": 2, "episode": 21, "scene": 37, "line": "Wednesdays, tearful. Tuesdays... [Dwight walks in and rummages through his complaint box]", "order": 7115, "speaker": "Stanley Hudson"},
  {"season": 2, "episode": 21, "scene": 37, "line": "Uh. Dwight.", "order": 7116, "speaker": "Michael Scott"},
  {"season": 2, "episode": 21, "scene": 37, "line": "Ah... agh... dgh... Ahh! No, no! Four years of malfeasance unreported. This cannot stand.", "order": 7117, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 21, "scene": 37, "line": "Okay! Calm down.", "order": 7118, "speaker": "Michael Scott"},
  {"season": 2, "episode": 21, "scene": 37, "line": "No! You calm down! Who's side is Toby on? Who's side are you on?", "order": 7119, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 21, "scene": 37, "line": "Hey, hey!", "order": 7120, "speaker": "Michael Scott"},
  {"season": 2, "episode": 21, "scene": 37, "line": "Him or me?", "order": 7121, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 21, "scene": 37, "line": "Stop.", "order": 7122, "speaker": "Michael Scott"},
  {"season": 2, "episode": 21, "scene": 37, "line": "Him or me? I cannot work with Jim anymore.", "order": 7123, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 21, "scene": 37, "line": "Okay...", "order": 7124, "speaker": "Michael Scott"},
  {"season": 2, "episode": 21, "scene": 37, "line": "Either he goes, or I go.", "order": 7125, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 21, "scene": 37, "line": "Dwight...", "order": 7126, "speaker": "Michael Scott"},
  {"season": 2, "episode": 21, "scene": 37, "line": "You choose!", "order": 7127, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 21, "scene": 37, "line": "Stop...", "order": 7128, "speaker": "Michael Scott"},
  {"season": 2, "episode": 21, "scene": 37, "line": "One of us is out of here by the end of today! [runs out]", "order": 7129, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 21, "scene": 37, "line": "Oh... kay...", "order": 7130, "speaker": "Michael Scott"},
  {"season": 2, "episode": 21, "scene": 38, "line": "I am not bluffing!", "order": 7131, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 21, "scene": 38, "line": "Okay.", "order": 7132, "speaker": "Michael Scott"},
  {"season": 2, "episode": 21, "scene": 38, "line": "Okay?", "order": 7133, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 21, "scene": 38, "line": "Yes.", "order": 7134, "speaker": "Michael Scott"},
  {"season": 2, "episode": 21, "scene": 38, "line": "Do the right thing here, Michael. Okay, I have served you loyally for years.", "order": 7135, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 21, "scene": 38, "line": "Mm-hmm.", "order": 7136, "speaker": "Michael Scott"},
  {"season": 2, "episode": 21, "scene": 38, "line": "I deserve this. You know I do!", "order": 7137, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 21, "scene": 38, "line": "[picks up Dwight's I.D. and snickers] You know your I.D. says you're a security threat?", "order": 7138, "speaker": "Michael Scott"},
  {"season": 2, "episode": 21, "scene": 38, "line": "You have till five.", "order": 7139, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 21, "scene": 39, "line": "Oh, look, Jim. There's a sales manager position open in Stamford. Want me to call Jan and tell her you're interested? I could put in a good word for you, 'cause I'll still be working here. Transfer! Transfer! Everybody! Transfer! Transfer! Transfer! Transfer!", "order": 7140, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 21, "scene": 39, "line": "Okay... you two, in the conference room with me. Nobody leaves until we work this out. Cage match!", "order": 7141, "speaker": "Michael Scott"},
  {"season": 2, "episode": 21, "scene": 40, "line": "Cage matches? Yeah, they work. How could they not work? If they didn't work, everybody would still be in the cage.", "order": 7142, "speaker": "Michael Scott"},
  {"season": 2, "episode": 21, "scene": 41, "line": "Okay, so Dwight, in your own words - [reads from complaint paper] 'Someone replaced all my pens and pencils with crayons. I suspect Jim Halpert.' [flips to another paper] 'Everyone has called me 'Dwayne' all day. I think Jim Halpert paid them to.'", "order": 7143, "speaker": "Michael Scott"},
  {"season": 2, "episode": 21, "scene": 42, "line": "[laughs] Yes! Five bucks each. And it was totally worth it.", "order": 7144, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 21, "scene": 43, "line": "[reading] 'This morning, I found a bloody glove in my desk drawer and Jim Halpert tried to convince me I committed murder. I think he may be the real murderer.' [flips to another paper] 'Jim Halpert said there was an abandoned infant in the woman's room. When I went to save the child, I saw Meredith on the can.' Gah. 'This morning, I knocked myself in the head with the phone.'", "order": 7145, "speaker": "Michael Scott"},
  {"season": 2, "episode": 21, "scene": 44, "line": "That actually took a while. I had to put, uh, more and more nickels into his handset, till he got used to the weight, and then I just... took 'em all out.", "order": 7146, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 21, "scene": 45, "line": "[reading] 'Every time I typed my name, it said 'Diapers'.'", "order": 7147, "speaker": "Michael Scott"},
  {"season": 2, "episode": 21, "scene": 46, "line": "Just a simple macro. You know, these actually don't sound that funny one after another. But he does deserve it, though.", "order": 7148, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 21, "scene": 47, "line": "By the end of the day, my desk was about two feet closer to the copier.'", "order": 7149, "speaker": "Michael Scott"},
  {"season": 2, "episode": 21, "scene": 48, "line": "Yeah, I just moved it an inch every time he went to the bathroom. And that's how I spent my entire day that day.", "order": 7150, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 21, "scene": 49, "line": "The Japanese have this thing called shiatsu massage, where they dig into your body, very hard. And it is very painful. And apparently, some people throw up. But the next day they feel great. I've never had one. They sound awful.", "order": 7151, "speaker": "Michael Scott"},
  {"season": 2, "episode": 21, "scene": 50, "line": "Maybe Stanford would be cool.", "order": 7152, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 21, "scene": 50, "line": "It's a good market. Higher volume.", "order": 7153, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 21, "scene": 50, "line": "Yeah. Maybe we should both go.", "order": 7154, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 21, "scene": 50, "line": "I have a girlfriend...", "order": 7155, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 21, "scene": 50, "line": "Sure you do, Dwight. Sure.", "order": 7156, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 21, "scene": 51, "line": "Hey, there's like, 300 more of these. Let's get to them later.", "order": 7157, "speaker": "Michael Scott"},
  {"season": 2, "episode": 21, "scene": 51, "line": "So, you going to transfer Jim or not?", "order": 7158, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 21, "scene": 51, "line": "Maybe, I haven't decided yet. Let's get to work.", "order": 7159, "speaker": "Michael Scott"},
  {"season": 2, "episode": 21, "scene": 51, "line": "I want an answer by tomorrow.", "order": 7160, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 21, "scene": 52, "line": "Okay. Oh... actually, tomorrow's not good. How about later in the week?", "order": 7161, "speaker": "Michael Scott"},
  {"season": 2, "episode": 21, "scene": 52, "line": "Fine.", "order": 7162, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 21, "scene": 52, "line": "Good. Okay.", "order": 7163, "speaker": "Michael Scott"},
  {"season": 2, "episode": 21, "scene": 53, "line": "Hey! Wait. How about a group picture while you're here?", "order": 7164, "speaker": "Michael Scott"},
  {"season": 2, "episode": 21, "scene": 53, "line": "I can't. I only get reimbursed for the I.D. photos.", "order": 7165, "speaker": "Photographer"},
  {"season": 2, "episode": 21, "scene": 53, "line": "Well... that's... what, a computer camera, right?", "order": 7166, "speaker": "Michael Scott"},
  {"season": 2, "episode": 21, "scene": 53, "line": "You mean digital?", "order": 7167, "speaker": "Photographer"},
  {"season": 2, "episode": 21, "scene": 53, "line": "It'll take like two seconds.", "order": 7168, "speaker": "Michael Scott"},
  {"season": 2, "episode": 21, "scene": 53, "line": "20 bucks.", "order": 7169, "speaker": "Photographer"},
  {"season": 2, "episode": 21, "scene": 53, "line": "Ugh... All right. Everybody, [looks around at the employees] come on. Group photo for the newsletter.", "order": 7170, "speaker": "Michael Scott"},
  {"season": 2, "episode": 21, "scene": 53, "line": "You gotta be kiddin' me.", "order": 7171, "speaker": "Stanley Hudson"},
  {"season": 2, "episode": 21, "scene": 53, "line": "Come on, everybody.", "order": 7172, "speaker": "Michael Scott"},
  {"season": 2, "episode": 21, "scene": 53, "line": "Come on, let's go. Creed, Kevin, Oscar... andale! Let's go.", "order": 7173, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 21, "scene": 54, "line": "One, two, three... smile. [camera flashes, but no one smiles] Try to smile.", "order": 7174, "speaker": "Photographer"},
  {"season": 2, "episode": 21, "scene": 54, "line": "We resolved a lot today, everybody. Think happy thoughts.", "order": 7175, "speaker": "Michael Scott"},
  {"season": 2, "episode": 21, "scene": 54, "line": "Alright, I'm just gonna take it on three... whether you smile or not. One, two, three. [camera flashes]", "order": 7176, "speaker": "Photographer"},
  {"season": 2, "episode": 21, "scene": 54, "line": "Good, let's check that out. [looks at preview screen] Ew, okay, all right. One more. We'll take one more.", "order": 7177, "speaker": "Michael Scott"},
  {"season": 2, "episode": 21, "scene": 54, "line": "That'll be another 20.", "order": 7178, "speaker": "Photographer"},
  {"season": 2, "episode": 21, "scene": 54, "line": "What?", "order": 7179, "speaker": "Michael Scott"},
  {"season": 2, "episode": 21, "scene": 54, "line": "Angela, I want to talk to you about something.", "order": 7180, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 21, "scene": 54, "line": "[off camera] You just press the button.", "order": 7181, "speaker": "Michael Scott"},
  {"season": 2, "episode": 21, "scene": 54, "line": "What?", "order": 7182, "speaker": "Angela Martin"},
  {"season": 2, "episode": 21, "scene": 54, "line": "No, Pam.", "order": 7183, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 21, "scene": 54, "line": "[looks at to Jim] I am. [looks at Angela] It's about the Save The Date.", "order": 7184, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 21, "scene": 54, "line": "Pam, it wasn't her.", "order": 7185, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 21, "scene": 54, "line": "What?!", "order": 7186, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 21, "scene": 54, "line": "I'm the one who complained about you.", "order": 7187, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 21, "scene": 54, "line": "I... I didn't know that Toby was gonna write it down. [the camera flashes] I was just venting.", "order": 7188, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 21, "scene": 54, "line": "[off camera] Okay, good. Check that out.", "order": 7189, "speaker": "Michael Scott"},
  {"season": 2, "episode": 21, "scene": 54, "line": "You know, it was one day.", "order": 7190, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 21, "scene": 54, "line": "[off camera] That's terrible.", "order": 7191, "speaker": "Michael Scott"},
  {"season": 2, "episode": 21, "scene": 54, "line": "And I took it right back. It was like...", "order": 7192, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 21, "scene": 54, "line": "Okay.", "order": 7193, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 21, "scene": 54, "line": "Oh, dear.", "order": 7194, "speaker": "Phyllis Lapin"},
  {"season": 2, "episode": 21, "scene": 54, "line": "[off camera] Let's pay Mr. Price Gouger. [rejoins the group, on camera] Okay... we can do this. Come on, everybody. All right. Here we go. [flash goes off before he sits down]", "order": 7195, "speaker": "Michael Scott"},
  {"season": 2, "episode": 21, "scene": 55, "line": "It was really hard getting a good picture of fifteen people. He would not give me a good discount. And eight tries added up.", "order": 7196, "speaker": "Michael Scott"},
  {"season": 2, "episode": 21, "scene": 56, "line": "[flashback the photo being taken] One, two..[flash goes off] Didn't say three, did I?", "order": 7197, "speaker": "Michael Scott"},
  {"season": 2, "episode": 21, "scene": 57, "line": "But, I'm sort of an expert at Photoshop, so it turned out fine in the end. When people work together, there is going to be conflict. You can't outrun your problems.", "order": 7198, "speaker": "Michael Scott"},
  {"season": 2, "episode": 21, "scene": 58, "line": "[on Pam's answering machine] Hey, Pam... it's Jim. Um, I have a doctor's appointment in the city. So I probably won't be in till the late afternoon. Just thought I'd let you know. Okay, bye. [camera shows Jim sitting on a waiting coach in another Dunder Mifflin office]", "order": 7199, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 21, "scene": 58, "line": "Okay, Jan will see you now.", "order": 7200, "speaker": "Female worker"},
  {"season": 2, "episode": 21, "scene": 58, "line": "Oh, thanks.", "order": 7201, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 21, "scene": 59, "line": "And that is why the idea of a cage match is so universally appealing. But here's the thing about cage matches: sometimes you have to open the cage. And that is something Toby will never understand.", "order": 7202, "speaker": "Michael Scott"},
  {"season": 2, "episode": 22, "scene": 1, "line": "Tonight the Scranton Business Park is having Casino Night and we are converting our warehouse into a full-blown gambling hall. And I know it's illegal in Pennsylvania, but it's for charity. And I consider myself a great philanderer. It's just... It's nice to know at the end of the day, I can look in the mirror and say, 'Michael, because of you, some little kid in the Congo has a belly full of rice this evening.' Makes you feel good.", "order": 7203, "speaker": "Michael Scott"},
  {"season": 2, "episode": 22, "scene": 2, "line": "Excuse me. How long is the wait for a table for two?", "order": 7204, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 22, "scene": 2, "line": "I would never, ever serve you. Not in a million, billion years.", "order": 7205, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 22, "scene": 2, "line": "It's a nice tux.", "order": 7206, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 22, "scene": 2, "line": "I know. It belonged to my grandfather. He was buried in it, so family heirloom.", "order": 7207, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 22, "scene": 3, "line": "So, what's the deal? We gotta pay for our own drinks? That's lame.", "order": 7208, "speaker": "Roy Anderson"},
  {"season": 2, "episode": 22, "scene": 3, "line": "Come on, it'll be fun, and besides, I'm a roulette expert.", "order": 7209, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 22, "scene": 3, "line": "Impossible. Roulette is not a game of skill, it is a game of chance.", "order": 7210, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 22, "scene": 3, "line": "I can always kind of win at roulette.", "order": 7211, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 22, "scene": 3, "line": "Oh, really? Mmm-hmm.", "order": 7212, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 22, "scene": 3, "line": "Yeah.", "order": 7213, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 22, "scene": 3, "line": "How would you do that?", "order": 7214, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 22, "scene": 3, "line": "Mind control.", "order": 7215, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 22, "scene": 3, "line": "[laughs] You can't be serious. Are you serious?", "order": 7216, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 22, "scene": 3, "line": "Ever since I was a little kid, like, eight or nine, I could sort of control things with my mind.", "order": 7217, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 22, "scene": 3, "line": "I don't believe you. Continue.", "order": 7218, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 22, "scene": 3, "line": "It was just little thing, you know, like I could make something shake or I could make a marble fall off the counter. You know, just little things.", "order": 7219, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 22, "scene": 3, "line": "[scoffs] That's ridiculous. You know what? Uh... Why don't you move that coat rack? Excuse me, everyone! Attention in the office, please. Jim is about to prove his telekinetic powers and he needs absolute silence. Go ahead.", "order": 7220, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 22, "scene": 3, "line": "Okay, I'll try. [The coat rack wobbles] [Pam holds up an umbrella handle to the camera in another scene]", "order": 7221, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 22, "scene": 3, "line": "Oh, my God.", "order": 7222, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 22, "scene": 4, "line": "I try not to think of it as lagging behind. It's more of a David-and-Goliath thing.", "order": 7223, "speaker": "Michael Scott"},
  {"season": 2, "episode": 22, "scene": 4, "line": "[on phone] Yeah, but... Well, the fact of the matter is that your branch is currently number four of the five branches that I oversee.", "order": 7224, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 22, "scene": 4, "line": "Top 80 percent!", "order": 7225, "speaker": "Michael Scott"},
  {"season": 2, "episode": 22, "scene": 4, "line": "Michael?", "order": 7226, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 22, "scene": 4, "line": "Yeah?", "order": 7227, "speaker": "Michael Scott"},
  {"season": 2, "episode": 22, "scene": 4, "line": "You know that I'm very serious here.", "order": 7228, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 22, "scene": 4, "line": "Jan, listen, I promise that I will kick it up a notch. Bam!", "order": 7229, "speaker": "Michael Scott"},
  {"season": 2, "episode": 22, "scene": 4, "line": "What?", "order": 7230, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 22, "scene": 4, "line": "Emeril. Oh, actually, while I have you, not that I have you or have ever had you, but we're having our Casino Night tonight and I think everyone would love to see their fearless leader here.", "order": 7231, "speaker": "Michael Scott"},
  {"season": 2, "episode": 22, "scene": 4, "line": "I thought that you were their fearless leader.", "order": 7232, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 22, "scene": 4, "line": "I am, but you are the Eva Peron to my Cesar Chavez.", "order": 7233, "speaker": "Michael Scott"},
  {"season": 2, "episode": 22, "scene": 4, "line": "[laughs] I think you can handle it.", "order": 7234, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 22, "scene": 4, "line": "Oh, come on. Come on.", "order": 7235, "speaker": "Michael Scott"},
  {"season": 2, "episode": 22, "scene": 4, "line": "I think so, Michael...", "order": 7236, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 22, "scene": 4, "line": "You know, it'd be fun. I can hear it in your voice. You need a break.", "order": 7237, "speaker": "Michael Scott"},
  {"season": 2, "episode": 22, "scene": 4, "line": "Goodbye, Michael.", "order": 7238, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 22, "scene": 5, "line": "Jan and I understand each other. The romance thing is sort of on hold for the time being, but we've remained good friends. Good friends with privileges. Not now, some day.", "order": 7239, "speaker": "Michael Scott"},
  {"season": 2, "episode": 22, "scene": 6, "line": "Okay, everybody. Tonight's event is to benefit the Boy Scouts of America.", "order": 7240, "speaker": "Michael Scott"},
  {"season": 2, "episode": 22, "scene": 6, "line": "Again? We do that every year.", "order": 7241, "speaker": "Oscar Martinez"},
  {"season": 2, "episode": 22, "scene": 6, "line": "Well, they need our money. They don't have cookies like the Girl Scouts.", "order": 7242, "speaker": "Michael Scott"},
  {"season": 2, "episode": 22, "scene": 6, "line": "It'd be nice to do something for people who are actually suffering.", "order": 7243, "speaker": "Oscar Martinez"},
  {"season": 2, "episode": 22, "scene": 6, "line": "Well, Oscar, if you don't like it, then you should concentrate on winning. Because the person at the end of the evening with the highest chip count will receive $500 to donate to the charity of their choice. And they will get a mini-fridge compliments of Vance Refrigeration.", "order": 7244, "speaker": "Michael Scott"},
  {"season": 2, "episode": 22, "scene": 6, "line": "Yes!", "order": 7245, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 22, "scene": 6, "line": "So get your charities in to Pam. I, for example, am playing for Comic Relief.", "order": 7246, "speaker": "Michael Scott"},
  {"season": 2, "episode": 22, "scene": 6, "line": "That doesn't exist anymore.", "order": 7247, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 22, "scene": 6, "line": "Comedy is very much alive, as are homeless people.", "order": 7248, "speaker": "Michael Scott"},
  {"season": 2, "episode": 22, "scene": 6, "line": "No, they stopped making that show.", "order": 7249, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 22, "scene": 6, "line": "Well, then, they need our money more than ever.", "order": 7250, "speaker": "Michael Scott"},
  {"season": 2, "episode": 22, "scene": 6, "line": "You have to pick an approved, non-profit organization.", "order": 7251, "speaker": "Angela Martin"},
  {"season": 2, "episode": 22, "scene": 7, "line": "There's a great soup kitchen in downtown Scranton. Delicious pea soup on Thursdays. I'll probably give the money to them.", "order": 7252, "speaker": "Creed Bratton"},
  {"season": 2, "episode": 22, "scene": 8, "line": "Something with animals. Or people.", "order": 7253, "speaker": "Kevin Malone"},
  {"season": 2, "episode": 22, "scene": 9, "line": "Kobe Bryant has a foundation, and he is", "order": 7254, "speaker": "Kelly Kapoor"},
  {"season": 2, "episode": 22, "scene": 10, "line": "We are giving money that has been gambled. Why don't we just deal drugs or prostitute ourselves, and donate that money to charity?", "order": 7255, "speaker": "Angela Martin"},
  {"season": 2, "episode": 22, "scene": 11, "line": "Oh, and another fun thing. We, at the end of the night, are going to give the check to an actual group of Boy Scouts. Right, Toby? We're gonna...", "order": 7256, "speaker": "Michael Scott"},
  {"season": 2, "episode": 22, "scene": 11, "line": "Actually, I didn't think it was appropriate to invite children since it's... You know, there's gambling and alcohol, and it's in our dangerous warehouse and it's a school night... And, you know, Hooters is catering. You know, is that enough? Should I keep going?", "order": 7257, "speaker": "Toby Flenderson"},
  {"season": 2, "episode": 22, "scene": 11, "line": "Why are you the way that you are? Honestly, every time I try to do something fun or exciting, you make it not... that way. I hate so much about the things that you choose to be. Okay, you know what? I will not donate my winnings to Comic Relief, since apparently it doesn't exist. I am going to donate to Afghanistanis with AIDS.", "order": 7258, "speaker": "Michael Scott"},
  {"season": 2, "episode": 22, "scene": 11, "line": "I think you mean the aid to Afghanistan.", "order": 7259, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 22, "scene": 11, "line": "No, I mean Afghanistanis with AIDS.", "order": 7260, "speaker": "Michael Scott"},
  {"season": 2, "episode": 22, "scene": 11, "line": "Afghani.", "order": 7261, "speaker": "Phyllis Lapin"},
  {"season": 2, "episode": 22, "scene": 11, "line": "What?", "order": 7262, "speaker": "Michael Scott"},
  {"season": 2, "episode": 22, "scene": 11, "line": "Afghani.", "order": 7263, "speaker": "Phyllis Lapin"},
  {"season": 2, "episode": 22, "scene": 11, "line": "That's a dog.", "order": 7264, "speaker": "Michael Scott"},
  {"season": 2, "episode": 22, "scene": 11, "line": "No, that's Afghan.", "order": 7265, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 22, "scene": 11, "line": "That's a shawl.", "order": 7266, "speaker": "Michael Scott"},
  {"season": 2, "episode": 22, "scene": 11, "line": "Wait, canine AIDS?", "order": 7267, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 22, "scene": 11, "line": "No. Humans with AIDS.", "order": 7268, "speaker": "Michael Scott"},
  {"season": 2, "episode": 22, "scene": 11, "line": "Who has AIDS?", "order": 7269, "speaker": "Creed Bratton"},
  {"season": 2, "episode": 22, "scene": 11, "line": "Guys, the Afghanistananies.", "order": 7270, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 22, "scene": 11, "line": "Okay, you know what? No. No. AIDS is not funny. Believe me, I have tried.", "order": 7271, "speaker": "Michael Scott"},
  {"season": 2, "episode": 22, "scene": 12, "line": "There are certain topics that are off-limits to comedians, JFK, AIDS, the Holocaust. The Lincoln Assassination just recently became funny. 'I need to see this play like I need a hole in the head.' [laughs] And I hope to someday live in a world where a person could tell a hilarious AIDS joke. It's one of my dreams.", "order": 7272, "speaker": "Michael Scott"},
  {"season": 2, "episode": 22, "scene": 13, "line": "What are you doing?", "order": 7273, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 22, "scene": 13, "line": "Oh, nothing.", "order": 7274, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 22, "scene": 13, "line": "Till Death Do Us Rock.'", "order": 7275, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 22, "scene": 13, "line": "They're wedding bands.", "order": 7276, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 22, "scene": 13, "line": "Oh.", "order": 7277, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 22, "scene": 13, "line": "Roy was supposed to pick the band, but he's concentrating more on the bachelor party now.", "order": 7278, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 22, "scene": 13, "line": "Wait, wait, where you going? I mean, even if you don't hire a band, you still have to watch the bands. Pam, these are people who have never given up on their dreams. I have great respect for that. And, yes, they're all probably very bad and that will make me feel better about not having dreams.", "order": 7279, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 22, "scene": 13, "line": "There's a KISS cover band in here.", "order": 7280, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 22, "scene": 13, "line": "Let's do it.", "order": 7281, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 22, "scene": 14, "line": "I'm pretty happy these days. I'm getting married soon and I'm getting along with everybody at work.", "order": 7282, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 22, "scene": 15, "line": "Why did I talk to Jan about transferring? Well, you know... I have no future here.", "order": 7283, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 22, "scene": 16, "line": "I have already put down the deposit. Do you understand how a deposit works?", "order": 7284, "speaker": "Michael Scott"},
  {"season": 2, "episode": 22, "scene": 16, "line": "Mike, I am not having fire-eaters in a paper warehouse.", "order": 7285, "speaker": "Darryl Philbin"},
  {"season": 2, "episode": 22, "scene": 16, "line": "It's Casino Night like Las Vegas. There are fire-eaters all over the place.", "order": 7286, "speaker": "Michael Scott"},
  {"season": 2, "episode": 22, "scene": 16, "line": "Except my warehouse.", "order": 7287, "speaker": "Darryl Philbin"},
  {"season": 2, "episode": 22, "scene": 16, "line": "Well, actually, it's my warehouse.", "order": 7288, "speaker": "Michael Scott"},
  {"season": 2, "episode": 22, "scene": 16, "line": "Actually, it's owned by Beakman Properties, and Dunder Mifflin is four years into a seven-year lease.", "order": 7289, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 22, "scene": 16, "line": "Why are you here?", "order": 7290, "speaker": "Michael Scott"},
  {"season": 2, "episode": 22, "scene": 16, "line": "When Darryl was coming, you said you wanted me here for protection.", "order": 7291, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 22, "scene": 16, "line": "Not. I said, not that.", "order": 7292, "speaker": "Michael Scott"},
  {"season": 2, "episode": 22, "scene": 16, "line": "We just have a lot of stuff down there that could be stolen.", "order": 7293, "speaker": "Darryl Philbin"},
  {"season": 2, "episode": 22, "scene": 16, "line": "That's ironic.", "order": 7294, "speaker": "Michael Scott"},
  {"season": 2, "episode": 22, "scene": 16, "line": "What?", "order": 7295, "speaker": "Darryl Philbin"},
  {"season": 2, "episode": 22, "scene": 16, "line": "That you are afraid.", "order": 7296, "speaker": "Michael Scott"},
  {"season": 2, "episode": 22, "scene": 16, "line": "Why? 'Cause I'm from the hood?", "order": 7297, "speaker": "Darryl Philbin"},
  {"season": 2, "episode": 22, "scene": 16, "line": "Dinkin' flicka.", "order": 7298, "speaker": "Michael Scott"},
  {"season": 2, "episode": 22, "scene": 16, "line": "[sighs] Dinkin' flicka.", "order": 7299, "speaker": "Darryl Philbin"},
  {"season": 2, "episode": 22, "scene": 17, "line": "I taught Mike some, uh, phrases to help with his interracial conversations. You know, stuff like, 'Fleece it out.' 'Going mach five.' 'Dinkin' flicka.' You know, things us Negroes say.", "order": 7300, "speaker": "Darryl Philbin"},
  {"season": 2, "episode": 22, "scene": 18, "line": "Give me some. [Michael and Darryl perform simultaneous hand gesture]", "order": 7301, "speaker": "Michael Scott"},
  {"season": 2, "episode": 22, "scene": 19, "line": "Oh, yeah, I taught him a handshake, too.", "order": 7302, "speaker": "Darryl Philbin"},
  {"season": 2, "episode": 22, "scene": 20, "line": "[Jim ejects a videotape from the VCR and puts in a new one] Wow. I don't know how you're gonna decide. They are all extremely good.", "order": 7303, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 22, "scene": 20, "line": "I think I should hire them all. Do like Lollapalooza.", "order": 7304, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 22, "scene": 20, "line": "Yes.", "order": 7305, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 22, "scene": 20, "line": "Have three stages, yeah.", "order": 7306, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 22, "scene": 20, "line": "Your mom would love that. She would. Oh, this band is called Scrantonicity.", "order": 7307, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 22, "scene": 20, "line": "Oh.", "order": 7308, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 22, "scene": 20, "line": "Let's take a look. Nice.", "order": 7309, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 22, "scene": 20, "line": "Oh, wait. That's Kevin. On the drums.", "order": 7310, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 22, "scene": 20, "line": "What?", "order": 7311, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 22, "scene": 20, "line": "On the drums! On the drums!", "order": 7312, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 22, "scene": 20, "line": "Oh, my God, that's Kevin! Great song, Kev. Oh, my God, he's the drummer and the singer.", "order": 7313, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 22, "scene": 21, "line": "We really don't do a lot of weddings. We actually don't play in public very often. We are all really hoping that Pam's wedding works out. This could be a turning point for the band.", "order": 7314, "speaker": "Kevin Malone"},
  {"season": 2, "episode": 22, "scene": 22, "line": "Wow. Oh!", "order": 7315, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 22, "scene": 22, "line": "Oh, my...", "order": 7316, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 22, "scene": 22, "line": "Yeah, you haven't seen that since 1983. That is amazing. Okay, we have to sign him. I'm gonna call the label, we're gonna...", "order": 7317, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 22, "scene": 22, "line": "No! No!", "order": 7318, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 22, "scene": 22, "line": "No, Pam, you're gonna lose him to another wedding.", "order": 7319, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 22, "scene": 22, "line": "No, come back! No, no, no!", "order": 7320, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 22, "scene": 22, "line": "Kev!", "order": 7321, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 22, "scene": 23, "line": "Jim is great. Being with him just takes away all the stress of planning my wedding.", "order": 7322, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 22, "scene": 24, "line": "[phone rings] Yes", "order": 7323, "speaker": "Michael Scott"},
  {"season": 2, "episode": 22, "scene": 24, "line": "[phone rings] Michael, Carol Stills for you.", "order": 7324, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 22, "scene": 24, "line": "Who?", "order": 7325, "speaker": "Michael Scott"},
  {"season": 2, "episode": 22, "scene": 24, "line": "Carol Stills.", "order": 7326, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 22, "scene": 24, "line": "Do I know a Carol Stills?", "order": 7327, "speaker": "Michael Scott"},
  {"season": 2, "episode": 22, "scene": 24, "line": "Your realtor.", "order": 7328, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 22, "scene": 24, "line": "Oh, yeah, put her through. Hey Carol, how goes the real estate biz? Is it", "order": 7329, "speaker": "Michael Scott"},
  {"season": 2, "episode": 22, "scene": 24, "line": "It's still me.", "order": 7330, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 22, "scene": 25, "line": "Sometimes I don't put Michael through until he's already said something. I look at it as a practice run for him. He usually does better on the second attempt.", "order": 7331, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 22, "scene": 26, "line": "Carol, you're on with Michael.", "order": 7332, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 22, "scene": 26, "line": "[on phone] Hello, Michael?", "order": 7333, "speaker": "Carol Stills"},
  {"season": 2, "episode": 22, "scene": 26, "line": "Hi, Carol. How you doing?", "order": 7334, "speaker": "Michael Scott"},
  {"season": 2, "episode": 22, "scene": 26, "line": "I'm great. I just needed one last signature for your mortgage insurance.", "order": 7335, "speaker": "Carol Stills"},
  {"season": 2, "episode": 22, "scene": 26, "line": "Oh, hey, no problemo. Incidentally, I love the place.", "order": 7336, "speaker": "Michael Scott"},
  {"season": 2, "episode": 22, "scene": 26, "line": "Oh, great.", "order": 7337, "speaker": "Carol Stills"},
  {"season": 2, "episode": 22, "scene": 26, "line": "Great. It has a little bit of a weird smell. It's okay. At Christmas, the tree helped.", "order": 7338, "speaker": "Michael Scott"},
  {"season": 2, "episode": 22, "scene": 26, "line": "Oh, good, I'm glad. Can I drop it over later?", "order": 7339, "speaker": "Carol Stills"},
  {"season": 2, "episode": 22, "scene": 26, "line": "Actually, I'm sort of hosting this charity thing in our warehouse, Casino Night.", "order": 7340, "speaker": "Michael Scott"},
  {"season": 2, "episode": 22, "scene": 26, "line": "Oh, great.", "order": 7341, "speaker": "Carol Stills"},
  {"season": 2, "episode": 22, "scene": 26, "line": "Yeah, it'll be good. You know what? Why don't you come by? Bring the papers, I'll sign them and then you can stay and have a drink.", "order": 7342, "speaker": "Michael Scott"},
  {"season": 2, "episode": 22, "scene": 26, "line": "To the casino thing?", "order": 7343, "speaker": "Carol Stills"},
  {"season": 2, "episode": 22, "scene": 26, "line": "Yeah. It'll be fun. What do you...[phone rings] What do you...", "order": 7344, "speaker": "Michael Scott"},
  {"season": 2, "episode": 22, "scene": 26, "line": "What?", "order": 7345, "speaker": "Carol Stills"},
  {"season": 2, "episode": 22, "scene": 26, "line": "Oh, I'm sorry. Could you hold on? Yes?", "order": 7346, "speaker": "Michael Scott"},
  {"season": 2, "episode": 22, "scene": 26, "line": "Michael, Jan's on line two.", "order": 7347, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 22, "scene": 26, "line": "Okay, put her through. [Deep voice] Jan Levinson, I presume?", "order": 7348, "speaker": "Michael Scott"},
  {"season": 2, "episode": 22, "scene": 26, "line": "It's still me. Uh, Jan, here's Michael.", "order": 7349, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 22, "scene": 26, "line": "Michael?", "order": 7350, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 22, "scene": 26, "line": "Hey, Jan. How you doing?", "order": 7351, "speaker": "Michael Scott"},
  {"season": 2, "episode": 22, "scene": 26, "line": "You know, I... I thought about it and you are right.", "order": 7352, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 22, "scene": 26, "line": "I am?", "order": 7353, "speaker": "Michael Scott"},
  {"season": 2, "episode": 22, "scene": 26, "line": "I could use a little fun. So, I am going to drive up for your Casino Night.", "order": 7354, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 22, "scene": 26, "line": "Oh, okay.", "order": 7355, "speaker": "Michael Scott"},
  {"season": 2, "episode": 22, "scene": 26, "line": "Incidentally, what is the charity?", "order": 7356, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 22, "scene": 26, "line": "AIDS.", "order": 7357, "speaker": "Michael Scott"},
  {"season": 2, "episode": 22, "scene": 26, "line": "Okay, then. I will see you tonight.", "order": 7358, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 22, "scene": 26, "line": "Okay, sounds great.", "order": 7359, "speaker": "Michael Scott"},
  {"season": 2, "episode": 22, "scene": 26, "line": "Bye-bye.", "order": 7360, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 22, "scene": 26, "line": "Bye Hello, Carol? Hi, sorry about that. I just...", "order": 7361, "speaker": "Michael Scott"},
  {"season": 2, "episode": 22, "scene": 26, "line": "No problemo.", "order": 7362, "speaker": "Carol Stills"},
  {"season": 2, "episode": 22, "scene": 26, "line": "Right.", "order": 7363, "speaker": "Michael Scott"},
  {"season": 2, "episode": 22, "scene": 26, "line": "To answer your question...", "order": 7364, "speaker": "Carol Stills"},
  {"season": 2, "episode": 22, "scene": 26, "line": "Yeah?", "order": 7365, "speaker": "Michael Scott"},
  {"season": 2, "episode": 22, "scene": 26, "line": "Yes.", "order": 7366, "speaker": "Carol Stills"},
  {"season": 2, "episode": 22, "scene": 26, "line": "What?", "order": 7367, "speaker": "Michael Scott"},
  {"season": 2, "episode": 22, "scene": 26, "line": "I'd love to go.", "order": 7368, "speaker": "Carol Stills"},
  {"season": 2, "episode": 22, "scene": 26, "line": "Okay.", "order": 7369, "speaker": "Michael Scott"},
  {"season": 2, "episode": 22, "scene": 26, "line": "I have to get a sitter, but that shouldn't be a problem.", "order": 7370, "speaker": "Carol Stills"},
  {"season": 2, "episode": 22, "scene": 26, "line": "Problem. Good.", "order": 7371, "speaker": "Michael Scott"},
  {"season": 2, "episode": 22, "scene": 26, "line": "And I'll bring the papers, too.", "order": 7372, "speaker": "Carol Stills"},
  {"season": 2, "episode": 22, "scene": 26, "line": "Good, All right. Sounds great.", "order": 7373, "speaker": "Michael Scott"},
  {"season": 2, "episode": 22, "scene": 26, "line": "I'll see you tonight.", "order": 7374, "speaker": "Carol Stills"},
  {"season": 2, "episode": 22, "scene": 26, "line": "Bye.", "order": 7375, "speaker": "Michael Scott"},
  {"season": 2, "episode": 22, "scene": 26, "line": "Bye.", "order": 7376, "speaker": "Carol Stills"},
  {"season": 2, "episode": 22, "scene": 26, "line": "Two queens on Casino Night. I am going to drop a deuce on everybody.", "order": 7377, "speaker": "Michael Scott"},
  {"season": 2, "episode": 22, "scene": 27, "line": "[People playing casino games as the actual Casino Night begins] Oh, my God!", "order": 7378, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 22, "scene": 27, "line": "Yeah! That's great.", "order": 7379, "speaker": "Roy Anderson"},
  {"season": 2, "episode": 22, "scene": 27, "line": "Hey, hey.", "order": 7380, "speaker": "Michael Scott"},
  {"season": 2, "episode": 22, "scene": 27, "line": "Hi.", "order": 7381, "speaker": "Carol Stills"},
  {"season": 2, "episode": 22, "scene": 27, "line": "Hey, Carol.", "order": 7382, "speaker": "Michael Scott"},
  {"season": 2, "episode": 22, "scene": 27, "line": "Hi.", "order": 7383, "speaker": "Carol Stills"},
  {"season": 2, "episode": 22, "scene": 27, "line": "You look great.", "order": 7384, "speaker": "Michael Scott"},
  {"season": 2, "episode": 22, "scene": 27, "line": "Thanks. Thank you for inviting me. It looks so great in here.", "order": 7385, "speaker": "Carol Stills"},
  {"season": 2, "episode": 22, "scene": 27, "line": "Oh, well... Kiss. [Michael kisses her on the cheek, pauses and then kisses her on the other cheek] That's how we do it in the paper biz. It's European and... Yes? Ah, Dwight [Kisses cheeks]", "order": 7386, "speaker": "Michael Scott"},
  {"season": 2, "episode": 22, "scene": 27, "line": "Code name Re/Max is here. No sign of Lan Jevinson.", "order": 7387, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 22, "scene": 28, "line": "I'm Michael's wingman. I've got his back. Two dates. He's got two dates tonight. My job is to keep Jan away from Carol and vice versa. Michael said, 'We must deceive them, so as not to hurt them, and in that way, we honor them.'", "order": 7388, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 22, "scene": 29, "line": "Can I get you a drink? The food is from Hooters.", "order": 7389, "speaker": "Michael Scott"},
  {"season": 2, "episode": 22, "scene": 29, "line": "Drink would be good.", "order": 7390, "speaker": "Carol Stills"},
  {"season": 2, "episode": 22, "scene": 29, "line": "Okay.", "order": 7391, "speaker": "Michael Scott"},
  {"season": 2, "episode": 22, "scene": 30, "line": "Oh, I steal things all the time. It's just something I do. I stopped caring a long time ago. You should see how many supplies I've taken from this place. Honestly, I love stealing things.", "order": 7392, "speaker": "Creed Bratton"},
  {"season": 2, "episode": 22, "scene": 31, "line": "I'm gonna get a drink. Do you need anything?", "order": 7393, "speaker": "Billy's Girlfriend"},
  {"season": 2, "episode": 22, "scene": 31, "line": "No, I'm fine. Thank's sweetheart.", "order": 7394, "speaker": "Billy"},
  {"season": 2, "episode": 22, "scene": 31, "line": "Okay.", "order": 7395, "speaker": "Billy's Girlfriend"},
  {"season": 2, "episode": 22, "scene": 31, "line": "Billy, your nurse is hot.", "order": 7396, "speaker": "Michael Scott"},
  {"season": 2, "episode": 22, "scene": 31, "line": "That's my girlfriend.", "order": 7397, "speaker": "Billy"},
  {"season": 2, "episode": 22, "scene": 31, "line": "Your nurse became your girlfriend? Sweet.", "order": 7398, "speaker": "Michael Scott"},
  {"season": 2, "episode": 22, "scene": 31, "line": "She was never my nurse. I met her at Chili's. She was my waitress.", "order": 7399, "speaker": "Billy"},
  {"season": 2, "episode": 22, "scene": 31, "line": "Chili's is great.", "order": 7400, "speaker": "Michael Scott"},
  {"season": 2, "episode": 22, "scene": 32, "line": "Welkommen, Bienvenue, and welcome to Monte Carlo! Dwight. I am no longer your boss. Lady Fortune is your boss.", "order": 7401, "speaker": "Michael Scott"},
  {"season": 2, "episode": 22, "scene": 32, "line": "[Under his breath] Will Lady Fortune give me a raise?", "order": 7402, "speaker": "Stanley Hudson"},
  {"season": 2, "episode": 22, "scene": 32, "line": "Shut it, shut it, shut it. Will Lady Fortune be your mistress? Only time will tell, my friends. Leave all your preconceived notions about casinos at the door. Old friends, new lovers, and the disabled! Welcome all! Great, okay. Shuffle up and deal. Let's get it started! Black-Eyed Crows.", "order": 7403, "speaker": "Michael Scott"},
  {"season": 2, "episode": 22, "scene": 32, "line": "Okay, the game is No-Limit Texas Hold'em. Good luck, everybody. That's at least four red chips to you, sir.", "order": 7404, "speaker": "Dealer"},
  {"season": 2, "episode": 22, "scene": 32, "line": "All-in. [Other players fold their hands]", "order": 7405, "speaker": "Michael Scott"},
  {"season": 2, "episode": 22, "scene": 33, "line": "Bluffing is a key part of poker, which is too bad, because I'm not very good at bluffing. Did you believe me?", "order": 7406, "speaker": "Michael Scott"},
  {"season": 2, "episode": 22, "scene": 34, "line": "I'll call.", "order": 7407, "speaker": "Toby Flenderson"},
  {"season": 2, "episode": 22, "scene": 34, "line": "What are... That's insane.", "order": 7408, "speaker": "Michael Scott"},
  {"season": 2, "episode": 22, "scene": 34, "line": "I have good cards.", "order": 7409, "speaker": "Toby Flenderson"},
  {"season": 2, "episode": 22, "scene": 34, "line": "Well, Toby, I went all-in on the first hand, so doesn't that tell you that I might have good cards, too? So don't be stupid. Just take it back.", "order": 7410, "speaker": "Michael Scott"},
  {"season": 2, "episode": 22, "scene": 34, "line": "No, I'm sorry. He can't, sir. He's gone all-in.", "order": 7411, "speaker": "Dealer"},
  {"season": 2, "episode": 22, "scene": 34, "line": "Okay, all right, whatever.", "order": 7412, "speaker": "Michael Scott"},
  {"season": 2, "episode": 22, "scene": 34, "line": "Flip them.", "order": 7413, "speaker": "Dealer"},
  {"season": 2, "episode": 22, "scene": 34, "line": "You really screwed that up. [Michael leaves]", "order": 7414, "speaker": "Michael Scott"},
  {"season": 2, "episode": 22, "scene": 34, "line": "Wow.", "order": 7415, "speaker": "Meredith Palmer"},
  {"season": 2, "episode": 22, "scene": 35, "line": "I don't really play cards, but I'm not gonna lie to you. It felt really good to take money from Michael. Gonna chase that feeling.", "order": 7416, "speaker": "Toby Flenderson"},
  {"season": 2, "episode": 22, "scene": 36, "line": "I expect to do very well tonight. I have an acute ability to read people. Jim, for instance, has a huge tell. When he gets a good hand, he coughs.", "order": 7417, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 22, "scene": 37, "line": "[coughs] I will raise. [Dwight sighs and folds his cards] Thanks.", "order": 7418, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 22, "scene": 38, "line": "It's the weirdest thing. Every time I cough, he folds.", "order": 7419, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 22, "scene": 39, "line": "Wow, bad luck.", "order": 7420, "speaker": "Carol Stills"},
  {"season": 2, "episode": 22, "scene": 39, "line": "Yeah, whatever. Hey, you know what? If luck weren't involved, I would always be winning. [Sees Jan] Oh, my God. Oh, my God.", "order": 7421, "speaker": "Michael Scott"},
  {"season": 2, "episode": 22, "scene": 39, "line": "Michael?", "order": 7422, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 22, "scene": 39, "line": "Jan.", "order": 7423, "speaker": "Michael Scott"},
  {"season": 2, "episode": 22, "scene": 39, "line": "Hi.", "order": 7424, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 22, "scene": 39, "line": "Look, okay, I think we're all adults here, and it has always been my understanding that we have an open relationship.", "order": 7425, "speaker": "Michael Scott"},
  {"season": 2, "episode": 22, "scene": 39, "line": "What are you... Just... Wait, what're you talking about?", "order": 7426, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 22, "scene": 39, "line": "What does that mean?", "order": 7427, "speaker": "Carol Stills"},
  {"season": 2, "episode": 22, "scene": 39, "line": "After you said you weren't coming, I invited Carol to come and I don't think that I did anything wrong.", "order": 7428, "speaker": "Michael Scott"},
  {"season": 2, "episode": 22, "scene": 39, "line": "No. No, you didn't. Hi, I'm Jan. I'm Michael's boss.", "order": 7429, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 22, "scene": 39, "line": "Hi, hi.", "order": 7430, "speaker": "Carol Stills"},
  {"season": 2, "episode": 22, "scene": 39, "line": "Does anyone want a drink?", "order": 7431, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 22, "scene": 39, "line": "No, I'm good.", "order": 7432, "speaker": "Carol Stills"},
  {"season": 2, "episode": 22, "scene": 39, "line": "Okay. [Carol stares at Michael]", "order": 7433, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 22, "scene": 39, "line": "Um...", "order": 7434, "speaker": "Michael Scott"},
  {"season": 2, "episode": 22, "scene": 39, "line": "Hey, hey.", "order": 7435, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 22, "scene": 39, "line": "Hey. What...", "order": 7436, "speaker": "Michael Scott"},
  {"season": 2, "episode": 22, "scene": 39, "line": "Jan's here.", "order": 7437, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 22, "scene": 40, "line": "Give me the dice.", "order": 7438, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 22, "scene": 40, "line": "Come on, Dwight.", "order": 7439, "speaker": "Kevin Malone"},
  {"season": 2, "episode": 22, "scene": 40, "line": "Let's go.", "order": 7440, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 22, "scene": 40, "line": "It's all on you, baby. Let's go.", "order": 7441, "speaker": "Billy"},
  {"season": 2, "episode": 22, "scene": 40, "line": "Good evening, Dwight. What is this?", "order": 7442, "speaker": "Angela Martin"},
  {"season": 2, "episode": 22, "scene": 40, "line": "Evening, Angela. This is craps. I need to roll an eight. If I do, everyone wins.", "order": 7443, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 22, "scene": 40, "line": "Yes.", "order": 7444, "speaker": "Kevin Malone"},
  {"season": 2, "episode": 22, "scene": 40, "line": "Then roll an eight.", "order": 7445, "speaker": "Angela Martin"},
  {"season": 2, "episode": 22, "scene": 40, "line": "Thank you, Angela.", "order": 7446, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 22, "scene": 40, "line": "Good luck, Dwight.", "order": 7447, "speaker": "Angela Martin"},
  {"season": 2, "episode": 22, "scene": 40, "line": "Yeah! Yeah! [Kisses Angela, she slaps him and walks away smiling]", "order": 7448, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 22, "scene": 40, "line": "Dwight, let's keep it going. Let's keep it going. Let's go.", "order": 7449, "speaker": "Kevin Malone"},
  {"season": 2, "episode": 22, "scene": 40, "line": "Let it ride. Let it all ride.", "order": 7450, "speaker": "Oscar Martinez"},
  {"season": 2, "episode": 22, "scene": 40, "line": "Give me the dice!", "order": 7451, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 22, "scene": 41, "line": "Yeah, right.", "order": 7452, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 22, "scene": 41, "line": "Yeah, right,' what?", "order": 7453, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 22, "scene": 41, "line": "What was this? [Makes face]", "order": 7454, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 22, "scene": 41, "line": "[Laughs] I have good cards.", "order": 7455, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 22, "scene": 41, "line": "Really?", "order": 7456, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 22, "scene": 41, "line": "Mhmm, And I'm gonna take you all-in.", "order": 7457, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 22, "scene": 41, "line": "Wow. I think you're bluffing.", "order": 7458, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 22, "scene": 41, "line": "Yeah, I think she's full of it.", "order": 7459, "speaker": "Kevin Malone"},
  {"season": 2, "episode": 22, "scene": 41, "line": "Straight.", "order": 7460, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 22, "scene": 41, "line": "Oh. Three nines.", "order": 7461, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 22, "scene": 41, "line": "Pam. Jim Halpert, ladies and gentlemen.", "order": 7462, "speaker": "Kevin Malone"},
  {"season": 2, "episode": 22, "scene": 41, "line": "Thank you very much. It was fun.", "order": 7463, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 22, "scene": 42, "line": "Cosmopolitan, please.", "order": 7464, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 22, "scene": 42, "line": "Can I get a red wine? So, two hours? That's a long drive.", "order": 7465, "speaker": "Carol Stills"},
  {"season": 2, "episode": 22, "scene": 42, "line": "Well, it's part of the job, you know? Keep an eye on things. So... Why not? So, how long have you and Michael been...", "order": 7466, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 22, "scene": 42, "line": "Oh, well, actually, I guess this would be our first date. I guess.", "order": 7467, "speaker": "Carol Stills"},
  {"season": 2, "episode": 22, "scene": 42, "line": "Casino Night in the warehouse. Good sport.", "order": 7468, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 22, "scene": 42, "line": "Well, I'm having a nice time.", "order": 7469, "speaker": "Carol Stills"},
  {"season": 2, "episode": 22, "scene": 42, "line": "Oh, me too. Me too.", "order": 7470, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 22, "scene": 43, "line": "One beer and one Seven and Seven with eight maraschino cherries, sugar on the rim, blended if you can.", "order": 7471, "speaker": "Ryan Howard"},
  {"season": 2, "episode": 22, "scene": 43, "line": "So, that's still going on, huh? You and Kelly?", "order": 7472, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 22, "scene": 44, "line": "All right!", "order": 7473, "speaker": "Michael Scott"},
  {"season": 2, "episode": 22, "scene": 44, "line": "The point is four. Shooter, roll it. Four!", "order": 7474, "speaker": "Dealer"},
  {"season": 2, "episode": 22, "scene": 44, "line": "Come on, shooter!", "order": 7475, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 22, "scene": 44, "line": "Four! [Holds dice in front of Carol] Blow. Blow for luck! Yeah! Also, you. Not playing favorites. [Holds dice in front of Jan] All right, here we go!", "order": 7476, "speaker": "Michael Scott"},
  {"season": 2, "episode": 22, "scene": 44, "line": "All right.", "order": 7477, "speaker": "Carol Stills"},
  {"season": 2, "episode": 22, "scene": 44, "line": "Yeah!", "order": 7478, "speaker": "Michael Scott"},
  {"season": 2, "episode": 22, "scene": 44, "line": "Five.", "order": 7479, "speaker": "Dealer"},
  {"season": 2, "episode": 22, "scene": 44, "line": "So close. So close.", "order": 7480, "speaker": "Michael Scott"},
  {"season": 2, "episode": 22, "scene": 44, "line": "Come on. [Turns to Jan] So where you staying? Radisson?", "order": 7481, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 22, "scene": 44, "line": "What?", "order": 7482, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 22, "scene": 44, "line": "Super 8?", "order": 7483, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 22, "scene": 44, "line": "No, I...", "order": 7484, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 22, "scene": 44, "line": "Motel 6? Best Western?", "order": 7485, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 22, "scene": 44, "line": "I didn't... I don't know...", "order": 7486, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 22, "scene": 44, "line": "Holiday Inn? The Hyatt in Wilkes-Barre? You staying with Michael?", "order": 7487, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 22, "scene": 45, "line": "I won the 2002 $2,500 No-Limit Deuce-to-Seven-Draw Tournament at the World Series of Poker in Vegas. So, yeah... I'm pretty good at poker.", "order": 7488, "speaker": "Kevin Malone"},
  {"season": 2, "episode": 22, "scene": 46, "line": "All-in.", "order": 7489, "speaker": "Kevin Malone"},
  {"season": 2, "episode": 22, "scene": 46, "line": "Okay, let's do it.", "order": 7490, "speaker": "Phyllis Lapin"},
  {"season": 2, "episode": 22, "scene": 46, "line": "Good Luck, honey.", "order": 7491, "speaker": "Bob Vance"},
  {"season": 2, "episode": 22, "scene": 46, "line": "Oh, thank you, Bobby. But it doesn't matter, it's just fun to play.", "order": 7492, "speaker": "Phyllis Lapin"},
  {"season": 2, "episode": 22, "scene": 46, "line": "Three queens.", "order": 7493, "speaker": "Kevin Malone"},
  {"season": 2, "episode": 22, "scene": 46, "line": "Nice, very nice.", "order": 7494, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 22, "scene": 46, "line": "I have an ace.", "order": 7495, "speaker": "Phyllis Lapin"},
  {"season": 2, "episode": 22, "scene": 46, "line": "No, that's a flush.", "order": 7496, "speaker": "Oscar Martinez"},
  {"season": 2, "episode": 22, "scene": 46, "line": "Oh, man!", "order": 7497, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 22, "scene": 46, "line": "Oh, I have a flush!", "order": 7498, "speaker": "Phyllis Lapin"},
  {"season": 2, "episode": 22, "scene": 46, "line": "Yes!", "order": 7499, "speaker": "Bob"},
  {"season": 2, "episode": 22, "scene": 46, "line": "Look, I won! Look I have all the clovers! You wanna play again?", "order": 7500, "speaker": "Phyllis Lapin"},
  {"season": 2, "episode": 22, "scene": 47, "line": "I suck.", "order": 7501, "speaker": "Kevin Malone"},
  {"season": 2, "episode": 22, "scene": 48, "line": "She took you down, huh?", "order": 7502, "speaker": "Roy Anderson"},
  {"season": 2, "episode": 22, "scene": 48, "line": "I do not want to talk about it.", "order": 7503, "speaker": "Kevin Malone"},
  {"season": 2, "episode": 22, "scene": 48, "line": "Hey, I saw your tape. Your band, Scrantonicity? You guys rock.", "order": 7504, "speaker": "Roy Anderson"},
  {"season": 2, "episode": 22, "scene": 48, "line": "Yeah?", "order": 7505, "speaker": "Kevin Malone"},
  {"season": 2, "episode": 22, "scene": 48, "line": "Yeah, you guys wanna play our wedding?", "order": 7506, "speaker": "Roy Anderson"},
  {"season": 2, "episode": 22, "scene": 48, "line": "Awesome. Did Pam say it was okay?", "order": 7507, "speaker": "Kevin Malone"},
  {"season": 2, "episode": 22, "scene": 48, "line": "Whatever. I'm in charge of the music.", "order": 7508, "speaker": "Roy Anderson"},
  {"season": 2, "episode": 22, "scene": 48, "line": "Dude, you will not be sorry.", "order": 7509, "speaker": "Kevin Malone"},
  {"season": 2, "episode": 22, "scene": 48, "line": "Sweet. All right.", "order": 7510, "speaker": "Roy Anderson"},
  {"season": 2, "episode": 22, "scene": 48, "line": "All right.", "order": 7511, "speaker": "Kevin Malone"},
  {"season": 2, "episode": 22, "scene": 49, "line": "Smoke?", "order": 7512, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 22, "scene": 49, "line": "No, thanks. You having fun?", "order": 7513, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 22, "scene": 49, "line": "Fabulous time. I drove two and a half hours to get here.", "order": 7514, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 22, "scene": 49, "line": "Yeah, we all really...", "order": 7515, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 22, "scene": 49, "line": "Left work early, drove down here. And I am completely underdressed", "order": 7516, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 22, "scene": 49, "line": "Well, I think you look great.", "order": 7517, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 22, "scene": 49, "line": "Why did I hook up with Michael?", "order": 7518, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 22, "scene": 49, "line": "Yeah, why did you?", "order": 7519, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 22, "scene": 49, "line": "It was very late, Jim. Very... Very late and... Have you given any more thought to the transfer?", "order": 7520, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 22, "scene": 49, "line": "Oh, yeah.", "order": 7521, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 22, "scene": 49, "line": "Good. Have you told anyone?", "order": 7522, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 22, "scene": 49, "line": "No.", "order": 7523, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 22, "scene": 49, "line": "Well, you should.", "order": 7524, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 22, "scene": 50, "line": "Excuse me. Big moment. The evening's chip leader and winner of this beautiful mini-refrigerator courtesy of Vance Refrigeration, Creed Bratton, Dunder Mifflin!", "order": 7525, "speaker": "Bob"},
  {"season": 2, "episode": 22, "scene": 50, "line": "Thanks. I never owned a refrigerator.", "order": 7526, "speaker": "Creed Bratton"},
  {"season": 2, "episode": 22, "scene": 51, "line": "Sorry, babe. I am just beat.", "order": 7527, "speaker": "Roy Anderson"},
  {"season": 2, "episode": 22, "scene": 51, "line": "It's okay. I'll see you at home.", "order": 7528, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 22, "scene": 51, "line": "Okay. Hey, don't try to lose too much money, all right?", "order": 7529, "speaker": "Roy Anderson"},
  {"season": 2, "episode": 22, "scene": 51, "line": "Okay.", "order": 7530, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 22, "scene": 51, "line": "If you still want a honeymoon. Hey, Halpert. Keep an eye on her, all right?", "order": 7531, "speaker": "Roy Anderson"},
  {"season": 2, "episode": 22, "scene": 51, "line": "Okay, will do.", "order": 7532, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 22, "scene": 51, "line": "See you.", "order": 7533, "speaker": "Roy Anderson"},
  {"season": 2, "episode": 22, "scene": 51, "line": "Bye! Hey.", "order": 7534, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 22, "scene": 51, "line": "Hey, how's it going?", "order": 7535, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 22, "scene": 51, "line": "Good, especially after I took all your money in poker.", "order": 7536, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 22, "scene": 51, "line": "Yeah. Hey, can I talk to you about something?", "order": 7537, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 22, "scene": 51, "line": "About when you want to give me more of your money?", "order": 7538, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 22, "scene": 51, "line": "No, I...", "order": 7539, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 22, "scene": 51, "line": "Did you wanna do that now? We can go inside. I'm feeling kind of good tonight.", "order": 7540, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 22, "scene": 51, "line": "I was just... I'm in love with you.", "order": 7541, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 22, "scene": 51, "line": "What?", "order": 7542, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 22, "scene": 51, "line": "I'm really sorry if that's weird for you to hear, but I needed you to hear it. Probably not good timing, I know that. I just...", "order": 7543, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 22, "scene": 51, "line": "What are you doing? What do you expect me to say to that?", "order": 7544, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 22, "scene": 51, "line": "I just needed you to know. Once.", "order": 7545, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 22, "scene": 51, "line": "Well, I um... I... I can't.", "order": 7546, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 22, "scene": 51, "line": "Yeah.", "order": 7547, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 22, "scene": 51, "line": "You have no idea...", "order": 7548, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 22, "scene": 51, "line": "Don't do that.", "order": 7549, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 22, "scene": 51, "line": "...what your friendship means to me.", "order": 7550, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 22, "scene": 51, "line": "Come on. I don't wanna do that. I wanna be", "order": 7551, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 22, "scene": 51, "line": "I can't. I'm really sorry if you misinterpreted things. It's probably my fault.", "order": 7552, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 22, "scene": 51, "line": "Not your fault. I'm sorry I misinterpreted our friendship.", "order": 7553, "speaker": "Jim Halpert"},
  {"season": 2, "episode": 22, "scene": 52, "line": "Hey. I'm leaving.", "order": 7554, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 22, "scene": 52, "line": "Hey, okay.", "order": 7555, "speaker": "Michael Scott"},
  {"season": 2, "episode": 22, "scene": 52, "line": "So, I just wanted to congratulate you on a fantastic evening. You did the company proud.", "order": 7556, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 22, "scene": 52, "line": "Thank you.", "order": 7557, "speaker": "Michael Scott"},
  {"season": 2, "episode": 22, "scene": 52, "line": "And thanks for inviting me. You were right, I needed it. So, thanks.", "order": 7558, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 22, "scene": 52, "line": "Okay. Thanks for coming.", "order": 7559, "speaker": "Michael Scott"},
  {"season": 2, "episode": 22, "scene": 52, "line": "Nice to meet you.", "order": 7560, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 22, "scene": 52, "line": "You, too.", "order": 7561, "speaker": "Carol Stills"},
  {"season": 2, "episode": 22, "scene": 52, "line": "And you guys have a good time together.", "order": 7562, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 22, "scene": 52, "line": "Okay. Talk to you Monday.", "order": 7563, "speaker": "Michael Scott"},
  {"season": 2, "episode": 22, "scene": 52, "line": "Yeah.", "order": 7564, "speaker": "Jan Levinson"},
  {"season": 2, "episode": 22, "scene": 52, "line": "Goodbye.", "order": 7565, "speaker": "Carol Stills"},
  {"season": 2, "episode": 22, "scene": 52, "line": "Good night. She's a good boss.", "order": 7566, "speaker": "Michael Scott"},
  {"season": 2, "episode": 22, "scene": 52, "line": "She seems really nice.", "order": 7567, "speaker": "Carol Stills"},
  {"season": 2, "episode": 22, "scene": 52, "line": "Oh, she's great.", "order": 7568, "speaker": "Michael Scott"},
  {"season": 2, "episode": 22, "scene": 53, "line": "Love triangle. Drama. All worked out in the end, though. The hero got the girl. Who saw that coming? I did. And Jan was really happy for me. So actually the hero got two girls. He got the girl that he works with and he got the girl that he buys real estate from. So, I've got my New York girl and my local flavor. Life is good.", "order": 7569, "speaker": "Michael Scott"},
  {"season": 2, "episode": 22, "scene": 54, "line": "[On phone] About 10 minutes ago. No, I didn't know what to say. Yes, I know. Um, I don't know, mom, he's my best friend. Yeah, he's great. Yeah, I think I am. [Jim enters] I have to go. I will. Listen, Jim... [They kiss]", "order": 7570, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 22, "scene": 56, "line": "Apparently Michael has come up with a Dunder Mifflin Mad Lib, and he's making us play it with him. We are so happy. No. I'd like to choose a different adjective.", "order": 7571, "speaker": "Pam Beesly"},
  {"season": 2, "episode": 22, "scene": 57, "line": "How about a location?", "order": 7572, "speaker": "Michael Scott"},
  {"season": 2, "episode": 22, "scene": 57, "line": "The planet Caprica.", "order": 7573, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 22, "scene": 57, "line": "Where the hell is that?", "order": 7574, "speaker": "Michael Scott"},
  {"season": 2, "episode": 22, "scene": 57, "line": "It's from Battlestar Galactica.", "order": 7575, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 22, "scene": 57, "line": "Okay, all right.", "order": 7576, "speaker": "Michael Scott"},
  {"season": 2, "episode": 22, "scene": 57, "line": "I am going back to my desk...", "order": 7577, "speaker": "Stanley Hudson"},
  {"season": 2, "episode": 22, "scene": 57, "line": "No, no, no. No, no, not until you give me an adjective.", "order": 7578, "speaker": "Michael Scott"},
  {"season": 2, "episode": 22, "scene": 57, "line": "Annoying.", "order": 7579, "speaker": "Stanley Hudson"},
  {"season": 2, "episode": 22, "scene": 57, "line": "We already have that. So...", "order": 7580, "speaker": "Michael Scott"},
  {"season": 2, "episode": 22, "scene": 57, "line": "Frustrating.", "order": 7581, "speaker": "Toby Flenderson"},
  {"season": 2, "episode": 22, "scene": 57, "line": "No, Toby, damn it, come on! We already have that. I need something fun. Something like fat or smelly. Or pukey. Pukey. Pukey's good. Okay. Pukey. Now I need a noun.", "order": 7582, "speaker": "Michael Scott"},
  {"season": 2, "episode": 22, "scene": 57, "line": "Ass.", "order": 7583, "speaker": "Phyllis Lapin"},
  {"season": 2, "episode": 22, "scene": 57, "line": "Good. Thank you, Phyllis. That wasn't so hard. All right, here we go. 'Office Depot is an annoying store. One day Creed went there to buy paper. And he ran into irritating Cylons.'", "order": 7584, "speaker": "Michael Scott"},
  {"season": 2, "episode": 22, "scene": 57, "line": "Yeah, get them, Creed!", "order": 7585, "speaker": "Dwight Schrute"},
  {"season": 2, "episode": 22, "scene": 57, "line": "One of them was named Michael Scott, and he was an...' Oh, damn it. Okay. Okay, okay, we're... Okay, we're starting over.", "order": 7586, "speaker": "Michael Scott"},
  {"season": 2, "episode": 22, "scene": 62, "line": "Our numbers are down, yes, but we have a heck of a crew here in Scranton. Hardworking, motivated, dynamic. Every single last one of them. They follow my lead. I sort of set the tone. And it doesn't hurt that my ex works for corporate. Jan loves this branch, and I think that's really more than half the battle.", "order": 7587, "speaker": "Michael Scott"}
]