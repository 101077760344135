import Fuse from 'fuse.js'
import data from '../data'

const fuzzyOptions = {
    keys: ['line', 'season', 'speaker'],
    includeMatches: true,
    minMatchCharLength: 2,
    threshold: 0.1,
    ignoreLocation: true,
    sortFn: (a: any) => {
        return a.order
    },
}

const singleOptions = {
    keys: ['order'],
    useExtendedSearch: true,
}

export const fuzzyFuse = new Fuse(data, fuzzyOptions)
export const singleFuse = new Fuse(data, singleOptions)
