module.exports = [
  {"season": 7, "episode": 1, "scene": 1, "line": "[to Toby, who's filming] You fallin' behind.", "order": 36478, "speaker": "Stanley Hudson"},
  {"season": 7, "episode": 1, "scene": 1, "line": "[jumps in front of camera] Wuphf.com!", "order": 36479, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 1, "scene": 1, "line": "Ryan, we're doing the dance!", "order": 36480, "speaker": "Kelly Kapoor"},
  {"season": 7, "episode": 1, "scene": 1, "line": "This is how you build a business. This is how you make it in this country.", "order": 36481, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 1, "scene": 1, "line": "[Bluetooth rings] You got Creed.", "order": 36482, "speaker": "Creed Bratton"},
  {"season": 7, "episode": 1, "scene": 1, "line": "-and you jump in front of them.", "order": 36483, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 1, "scene": 1, "line": "You make it so hard to love you sometimes.", "order": 36484, "speaker": "Kelly Kapoor"},
  {"season": 7, "episode": 1, "scene": 1, "line": "Still behind.", "order": 36485, "speaker": "Stanley Hudson"},
  {"season": 7, "episode": 1, "scene": 1, "line": "[closing door] I said I didn't want to be on the internet!", "order": 36486, "speaker": "Angela Martin"},
  {"season": 7, "episode": 1, "scene": 1, "line": "Go, go, go, go!", "order": 36487, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 1, "scene": 1, "line": "[performs magic-tricks while lip-syncing]", "order": 36488, "speaker": "Michael Scott"},
  {"season": 7, "episode": 1, "scene": 1, "line": "Streamers! [everyone throws streamers in the air and starts dancing]", "order": 36489, "speaker": "All"},
  {"season": 7, "episode": 1, "scene": 1, "line": "Hiya! Ha! [climbs on Phyllis' desk and starts kicking things off, bites off the head of a stuffed animal]", "order": 36490, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 1, "scene": 1, "line": "Dwight! [Dwight pulls out a hunting knife and jumps off desk]", "order": 36491, "speaker": "Phyllis Vance"},
  {"season": 7, "episode": 1, "scene": 1, "line": "Are you crazy?", "order": 36492, "speaker": "Stanley Hudson"},
  {"season": 7, "episode": 1, "scene": 1, "line": "[song ends] Toby, how did we do?", "order": 36493, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 1, "scene": 1, "line": "Well, that was better.", "order": 36494, "speaker": "Toby Flenderson"},
  {"season": 7, "episode": 1, "scene": 1, "line": "Whoo! We did it! [everyone starts cheering]", "order": 36495, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 1, "scene": 2, "line": "I suppose summer had to end sometime. It's sad, though, because I had a great summer. I got West Nile virus, lost a ton of weight. Then I went back to the lake. I stepped on a piece of glass in the parking lot, which hurt. That got infected even though I peed on it. Saw 'Inception.' Or at least I dreamt I did.", "order": 36496, "speaker": "Michael Scott"},
  {"season": 7, "episode": 1, "scene": 3, "line": "Brocolli Rob, obviously, will be there.", "order": 36497, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 1, "scene": 3, "line": "Yes, of course.", "order": 36498, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 1, "scene": 3, "line": "And JC. We call him Blorville, because he looks like a black Orville Redenbacher.", "order": 36499, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 1, "scene": 3, "line": "Who's the guy who invented the peanut?", "order": 36500, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 1, "scene": 3, "line": "Hey, guys.", "order": 36501, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 1, "scene": 3, "line": "Hey!", "order": 36502, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 1, "scene": 3, "line": "Sorry, didn't mean to interrupt.", "order": 36503, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 1, "scene": 3, "line": "Not at all.", "order": 36504, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 1, "scene": 3, "line": "I'm gonna head back to Siberia there. Ciao. [he and Erin kiss]", "order": 36505, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 1, "scene": 4, "line": "I started dating Erin this summer. It has been, in a word, exquisite.", "order": 36506, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 1, "scene": 5, "line": "Gabe is awesome. He has accomplished so much career-wise and height-wise. Thank God he's my boss because I would not have said yes to a first date if I didn't have to, but... it's been great.", "order": 36507, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 1, "scene": 6, "line": "Am I angry that Gabe stole my girlfriend over the summer? No. I've been through anger-management, OK? So right now, I am sitting on a nice beach at Cape Cod, gazing out across the whitecaps. Oh, look, it's a humpback whale. How pretty. He's eating Gabe.", "order": 36508, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 1, "scene": 7, "line": "[goes to change thermostat as Dwight puts a locked cover over it] Hey. What are you doing?", "order": 36509, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 1, "scene": 7, "line": "From now on, if you're hot, sit on an ice pack.", "order": 36510, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 1, "scene": 7, "line": "Well, what if you're cold?", "order": 36511, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 1, "scene": 7, "line": "Like you'd ever be cold, Kevin. Stop asking me hypothetical questions, OK? I'm too busy.", "order": 36512, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 1, "scene": 7, "line": "Hey, Dwight, I don't know if you heard, but we're supposed to be drinking out of weird backpacks instead of cups, like regular people. Oh, you did hear.", "order": 36513, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 1, "scene": 7, "line": "[drinking from Camelback] Jim, you have one job to do. And you do it forgettably. Those of us who are busy require hands-free hydration.", "order": 36514, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 1, "scene": 8, "line": "Dwight Schrute, star salesman, beet farmer, bed-and-breakfast proprietor, aspiring freelance bodyguard. Add to that list owner of this building. Then burn the list.", "order": 36515, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 1, "scene": 9, "line": "This summer, I did the minority executive training program at Yale. You guys, I'm, like, really smart now. You don't even know. You could ask me, 'Kelly, what's the biggest company in the world?' And I'd be like, 'blah blah blah, blah blah blah blah blah blah,' giving you the exact right answer.", "order": 36516, "speaker": "Kelly Kapoor"},
  {"season": 7, "episode": 1, "scene": 10, "line": "Coffee Monkey's arrived.", "order": 36517, "speaker": "Luke"},
  {"season": 7, "episode": 1, "scene": 10, "line": "The coffee's supposed to be here at 9.", "order": 36518, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 1, "scene": 10, "line": "Here you go, Big O. Tiny. [hands Kevin his coffee] Darryl Hannah.", "order": 36519, "speaker": "Luke"},
  {"season": 7, "episode": 1, "scene": 10, "line": "It says decaf. You get me decaf?", "order": 36520, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 1, "scene": 10, "line": "Ooh, yeah. Must've been some sort of mix-up there. You could just drink it, or I could drive all the way back?", "order": 36521, "speaker": "Luke"},
  {"season": 7, "episode": 1, "scene": 11, "line": "My summer, I, um, blew out my knee playing softball. Ended up watching CNN most of the time. I don't know how we're going to get out of Afghanistan. I hate the new assistant. He's only been here for a week, and I hate him.", "order": 36522, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 1, "scene": 12, "line": "New guy sucks. Calls me the Nard-Man. I'm the Nard-Dog, okay? Nard-man is my father.", "order": 36523, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 1, "scene": 13, "line": "My name is Luke Cooper. I love cinema. My favorite movies are Citizen Kane and The Boondock Saints.", "order": 36524, "speaker": "Luke"},
  {"season": 7, "episode": 1, "scene": 14, "line": "That kid is the worst. Needs to be fired, Michael.", "order": 36525, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 1, "scene": 14, "line": "He's not the worst, okay? He's not the worst. You know who's the worst? That intern we had a few years ago. That guy-remember? Eeeh! That face, how ugly he was? He was the worst. Good worker, though. [starts drinking coffee] Ah! It's not cappuccino.", "order": 36526, "speaker": "Michael Scott"},
  {"season": 7, "episode": 1, "scene": 14, "line": "He messed yours up to.", "order": 36527, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 1, "scene": 14, "line": "He's got to go, Mike.", "order": 36528, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 1, "scene": 14, "line": "It's just coffee, guys! But, yeah, I'll look into it, okay?", "order": 36529, "speaker": "Michael Scott"},
  {"season": 7, "episode": 1, "scene": 15, "line": "Wow, that's a lot of keys.", "order": 36530, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 1, "scene": 15, "line": "The bigger the key chain, the more powerful the man.", "order": 36531, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 1, "scene": 15, "line": "That's right. The janitor said that. By the way, the raccoons are back.", "order": 36532, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 1, "scene": 15, "line": "Where?", "order": 36533, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 1, "scene": 15, "line": "I think they run through these panels and then down under the map. I wouldn't know for sure cause I don't have a trained ear. That's why I have to use one of these. [pulls out stethoscope]", "order": 36534, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 1, "scene": 15, "line": "Give me that.", "order": 36535, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 1, "scene": 15, "line": "Yep. Wait, and this. [hands Dwight a hammer]", "order": 36536, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 1, "scene": 15, "line": "Yes.", "order": 36537, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 1, "scene": 15, "line": "Go get 'em.", "order": 36538, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 1, "scene": 16, "line": "What happened to me this summer? Dwight bought the building, so actually, this has been the busiest summer of my life.", "order": 36539, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 1, "scene": 17, "line": "[Jim quietly takes Dwight's keychain and starts adding keys to it, Pam giggles] Hey, what's so funny over here? Ha. Very nice. Give me my keys. [removes keys and throws them at Jim] Ha. Ha. Not. Funny. Jim.", "order": 36540, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 1, "scene": 17, "line": "Sorry.", "order": 36541, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 1, "scene": 17, "line": "Oh, no. Don't worry about it. I was just gonna put a couple keys on it every day until Christmas. Then his pants would have fallen down, which was a little gift to me, but...", "order": 36542, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 1, "scene": 17, "line": "It was really funny.", "order": 36543, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 1, "scene": 17, "line": "Well, it would have been. So unprofessional, Mrs. Halpert.", "order": 36544, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 1, "scene": 17, "line": "I love you.", "order": 36545, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 1, "scene": 18, "line": "Luke? Luke!", "order": 36546, "speaker": "Phyllis Vance"},
  {"season": 7, "episode": 1, "scene": 18, "line": "What's up, Venus?", "order": 36547, "speaker": "Luke"},
  {"season": 7, "episode": 1, "scene": 18, "line": "Did you send those samples to Justin at Lehigh Motors yesterday? Because he didn't get 'em.", "order": 36548, "speaker": "Phyllis Vance"},
  {"season": 7, "episode": 1, "scene": 18, "line": "Uh, if you asked me to do it, I'm sure I did it. Yeah, strawberries are ripe.", "order": 36549, "speaker": "Luke"},
  {"season": 7, "episode": 1, "scene": 18, "line": "Hey, turn off the Chumbawamba and scram, twerp.", "order": 36550, "speaker": "Meredith Palmer"},
  {"season": 7, "episode": 1, "scene": 18, "line": "You don't have to yell.", "order": 36551, "speaker": "Luke"},
  {"season": 7, "episode": 1, "scene": 18, "line": "Okay, rude. Luke, I want you to go to the supermarket for me.", "order": 36552, "speaker": "Michael Scott"},
  {"season": 7, "episode": 1, "scene": 18, "line": "What for?", "order": 36553, "speaker": "Luke"},
  {"season": 7, "episode": 1, "scene": 18, "line": "To get some ice cream for everybody. It's national ice cream afternoon. Great.", "order": 36554, "speaker": "Michael Scott"},
  {"season": 7, "episode": 1, "scene": 19, "line": "And another thing I did this summer, I hired my nephew.", "order": 36555, "speaker": "Michael Scott"},
  {"season": 7, "episode": 1, "scene": 20, "line": "Having Luke here is a pretty big deal for me, because his mother, who also happens to be my half-sister, kind of cut me off from that side of the family 15 years a- 14. The last time I saw Luke was the opening day of 'Ace Ventura II' and that was '95, so yes, 15 years on the dot. Anyway, I lost him in a forest.", "order": 36556, "speaker": "Michael Scott"},
  {"season": 7, "episode": 1, "scene": 21, "line": "Don't bother Luke.", "order": 36557, "speaker": "Michael Scott"},
  {"season": 7, "episode": 1, "scene": 21, "line": "Why is there a circle with a line through it?", "order": 36558, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 1, "scene": 21, "line": "That means don't. Haven't you seen Ghostbusters, Pam?", "order": 36559, "speaker": "Michael Scott"},
  {"season": 7, "episode": 1, "scene": 21, "line": "Yeah, but it's like you're saying we should bother Luke.", "order": 36560, "speaker": "Phyllis Vance"},
  {"season": 7, "episode": 1, "scene": 21, "line": "No, it... Yes, okay, right. [erases don't] All right, yep. My bad. I got it. Here we go. [writes 'Don't' in front of symbol]", "order": 36561, "speaker": "Michael Scott"},
  {"season": 7, "episode": 1, "scene": 21, "line": "Don't don't bother Luke. Got it.", "order": 36562, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 1, "scene": 21, "line": "Come on. Okay, um... [draws a circle around 'Don't' with a line through it] That's as clear as I can make it.", "order": 36563, "speaker": "Michael Scott"},
  {"season": 7, "episode": 1, "scene": 21, "line": "Hey, why can't we bother Luke? He deserves it.", "order": 36564, "speaker": "Phyllis Vance"},
  {"season": 7, "episode": 1, "scene": 21, "line": "Because I don't want it getting back to Sabre that we're yelling at assistants. I think it would reflect poorly on us.", "order": 36565, "speaker": "Michael Scott"},
  {"season": 7, "episode": 1, "scene": 21, "line": "Who's gonna tell on us? Gabe?", "order": 36566, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 1, "scene": 21, "line": "That'd be hilarious. Uh, 'Jo, they're creating a hostile work environment. Stop 'em.'", "order": 36567, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 1, "scene": 21, "line": "Nope, it won't be Gabe but actually, there is somebody in the office that is very, very close to Luke.", "order": 36568, "speaker": "Michael Scott"},
  {"season": 7, "episode": 1, "scene": 21, "line": "How close?", "order": 36569, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 1, "scene": 21, "line": "Well, Dwight, he wiped his butt. Is that close enough for you?", "order": 36570, "speaker": "Michael Scott"},
  {"season": 7, "episode": 1, "scene": 21, "line": "Yes. Of course.", "order": 36571, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 1, "scene": 21, "line": "Guys, I think Michael and Luke are related.", "order": 36572, "speaker": "Toby Flenderson"},
  {"season": 7, "episode": 1, "scene": 21, "line": "No. No, Toby. He's not. Yes he is. He's my nephew.", "order": 36573, "speaker": "Michael Scott"},
  {"season": 7, "episode": 1, "scene": 21, "line": "What?", "order": 36574, "speaker": "All"},
  {"season": 7, "episode": 1, "scene": 21, "line": "Luke is my nephew.", "order": 36575, "speaker": "Michael Scott"},
  {"season": 7, "episode": 1, "scene": 21, "line": "Michael, that's nepotism. Luke is getting special treatment because he's your nephew.", "order": 36576, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 1, "scene": 21, "line": "Yes, exactly. That is a very nice way of putting it, Oscar. Mixing family and business is a beautiful thing.", "order": 36577, "speaker": "Michael Scott"},
  {"season": 7, "episode": 1, "scene": 21, "line": "What's wrong with a level playing field?", "order": 36578, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 1, "scene": 21, "line": "Do you think they should have had open auditions for the band Hanson? What if no one named Hanson showed up? That wouldn't even make sense. Or what if they just hired the littlest kid and a 50-year-old guy who was a murderer? Really safe.", "order": 36579, "speaker": "Michael Scott"},
  {"season": 7, "episode": 1, "scene": 21, "line": "Oh, man, so many points being made.", "order": 36580, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 1, "scene": 22, "line": "I couldn't care less about nepotism. But, I'm loving the debate. Great minds battling it out. And I've got a front row seat.", "order": 36581, "speaker": "Creed Bratton"},
  {"season": 7, "episode": 1, "scene": 23, "line": "God, when he needed help on Earth, who did he hire? Jesus Christ, his son.", "order": 36582, "speaker": "Michael Scott"},
  {"season": 7, "episode": 1, "scene": 23, "line": "That's a really tight argument.", "order": 36583, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 1, "scene": 23, "line": "Thank you.", "order": 36584, "speaker": "Michael Scott"},
  {"season": 7, "episode": 1, "scene": 23, "line": "But you're comparing you and Luke to God and Jesus.", "order": 36585, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 1, "scene": 23, "line": "No! No. I'm just saying, why does God get to do something that I don't?", "order": 36586, "speaker": "Michael Scott"},
  {"season": 7, "episode": 1, "scene": 23, "line": "This is hypothetical. We're talking about Luke who happens to be terrible.", "order": 36587, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 1, "scene": 23, "line": "Well, then, why aren't you bugging me to fire everybody who's bad? You just want me to fire Luke. That's reverse nepotism. He should not be punished because he is related to me and bad at what he does!", "order": 36588, "speaker": "Michael Scott"},
  {"season": 7, "episode": 1, "scene": 23, "line": "I would just like to say something off of what Darryl said about the level playing field. That is actually a zoning issue. So, thank you.", "order": 36589, "speaker": "Kelly Kapoor"},
  {"season": 7, "episode": 1, "scene": 23, "line": "If there's nothing wrong with this, Michael, why have you been keeping it a secret?", "order": 36590, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 1, "scene": 23, "line": "Because I wanted you to come to me and say, 'Wow, he is so great.' And I was gonna say, 'Well, it's in the genes.' And I was actually gonna be wearing jeans. And I'd point to them, right? No. But you ruined it. So, thank you very much. Here he comes. Guys, look. I don't want you to treat him like anyone else in the office. I just want you to treat him like my nephew. Hey, there he is.", "order": 36591, "speaker": "Michael Scott"},
  {"season": 7, "episode": 1, "scene": 23, "line": "Hey! Ice cream afternoon!", "order": 36592, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 1, "scene": 23, "line": "Whoo! Whoo! Whoo!", "order": 36593, "speaker": "Michael Scott"},
  {"season": 7, "episode": 1, "scene": 23, "line": "Wait, soy ice cream? Did you get real ice cream?", "order": 36594, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 1, "scene": 23, "line": "Or enough for everyone?", "order": 36595, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 1, "scene": 23, "line": "No, man. There was no list. Uh, but I got bagel chips.", "order": 36596, "speaker": "Luke"},
  {"season": 7, "episode": 1, "scene": 23, "line": "Ah, my favorite part of an ice cream party. The bagel chips.", "order": 36597, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 1, "scene": 23, "line": "Clutch cream run, bro.", "order": 36598, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 1, "scene": 24, "line": "Get whatever you want, on me.", "order": 36599, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 1, "scene": 24, "line": "You really think you can make it up to me with food?", "order": 36600, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 1, "scene": 24, "line": "I don't know. That usually works.", "order": 36601, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 1, "scene": 25, "line": "I feel horrible for blowing Jim's prank. I don't know if you can tell, but he's mildly upset. And Dwight hasn't been messed with in a while, so he's become a monster. I need to make this right.", "order": 36602, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 1, "scene": 26, "line": "I came down here to feed the feral cats like I usually do and I found something unsavory in the back of your nephew's car.", "order": 36603, "speaker": "Angela Martin"},
  {"season": 7, "episode": 1, "scene": 26, "line": "Ok, you know what? Just -just back off! Back off!", "order": 36604, "speaker": "Michael Scott"},
  {"season": 7, "episode": 1, "scene": 26, "line": "Well, take a look. It's all the packages he was supposed to overnight. He never bothered to send them.", "order": 36605, "speaker": "Phyllis Vance"},
  {"season": 7, "episode": 1, "scene": 26, "line": "All right, those might be his. He might own an Ebay store.", "order": 36606, "speaker": "Michael Scott"},
  {"season": 7, "episode": 1, "scene": 26, "line": "Yep. That's exactly what my first thought was. And then I remembered having a conversation with him where he was like, 'I don't own an Ebay store,' so...", "order": 36607, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 1, "scene": 26, "line": "Ok, ok. You know what? This is disgusting. This is like a witch hunt. This is like the Blair Witch Hunt project.", "order": 36608, "speaker": "Michael Scott"},
  {"season": 7, "episode": 1, "scene": 26, "line": "Oh sure, those are the expense reports that corporate was waiting for yesterday.", "order": 36609, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 1, "scene": 26, "line": "Let's just make this kid open this damn car.", "order": 36610, "speaker": "Stanley Hudson"},
  {"season": 7, "episode": 1, "scene": 26, "line": "Oh my gosh, those are the pants he wanted to return to Talbots.", "order": 36611, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 1, "scene": 26, "line": "I'm reconsidering sending them back anyway.", "order": 36612, "speaker": "Michael Scott"},
  {"season": 7, "episode": 1, "scene": 26, "line": "You get that kid to open this car, or I'm gonna do it myself... by calling Tripple A.", "order": 36613, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 1, "scene": 26, "line": "Wait a minute.", "order": 36614, "speaker": "Michael Scott"},
  {"season": 7, "episode": 1, "scene": 26, "line": "[breaks into the car] I'm in.", "order": 36615, "speaker": "Meredith Palmer"},
  {"season": 7, "episode": 1, "scene": 26, "line": "Don't-all right. Well, ok, you've just committed a felony.", "order": 36616, "speaker": "Michael Scott"},
  {"season": 7, "episode": 1, "scene": 26, "line": "Yep, here they are.", "order": 36617, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 1, "scene": 26, "line": "Yep, I just lost a client cause I swore I sent these samples on time.", "order": 36618, "speaker": "Phyllis Vance"},
  {"season": 7, "episode": 1, "scene": 26, "line": "This kid's costing us sales, Michael.", "order": 36619, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 1, "scene": 26, "line": "I'm sorry, do you want to try them on again to see.", "order": 36620, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 1, "scene": 26, "line": "No, I know they fit. It's not about the fit.", "order": 36621, "speaker": "Michael Scott"},
  {"season": 7, "episode": 1, "scene": 27, "line": "Why is this my problem, Michael?", "order": 36622, "speaker": "Jo Bennett"},
  {"season": 7, "episode": 1, "scene": 27, "line": "Because Gabe tattled. I was perfectly happy to just hide it from you.", "order": 36623, "speaker": "Michael Scott"},
  {"season": 7, "episode": 1, "scene": 27, "line": "Those overnight packages needed to go out, and Michael refuses to hold the guilty party accountable.", "order": 36624, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 1, "scene": 27, "line": "Lower yourself, Gabe. I don't wanna be having a conversation with your crotch. Ok, educate me now why you won't fire the boy.", "order": 36625, "speaker": "Jo Bennett"},
  {"season": 7, "episode": 1, "scene": 27, "line": "You don't have all the facts.", "order": 36626, "speaker": "Michael Scott"},
  {"season": 7, "episode": 1, "scene": 27, "line": "Which are?", "order": 36627, "speaker": "Jo Bennett"},
  {"season": 7, "episode": 1, "scene": 27, "line": "I love him.", "order": 36628, "speaker": "Michael Scott"},
  {"season": 7, "episode": 1, "scene": 27, "line": "Oh, God. How far has it gone?", "order": 36629, "speaker": "Jo Bennett"},
  {"season": 7, "episode": 1, "scene": 27, "line": "No. No. He's my nephew.", "order": 36630, "speaker": "Michael Scott"},
  {"season": 7, "episode": 1, "scene": 27, "line": "Well, I got a nephew too. But he don't work for me. You know why? Cause he's a screw-up. He can swim in my pool but he can't come in my house.", "order": 36631, "speaker": "Jo Bennett"},
  {"season": 7, "episode": 1, "scene": 27, "line": "Well, this office is my pool. And my house is my house. And I just want my nephew to work in my pool.", "order": 36632, "speaker": "Michael Scott"},
  {"season": 7, "episode": 1, "scene": 27, "line": "If you keep him, Michael, I'm gonna hold you accountable for him. You're on the hook for this kid. You got that? Cover his ass like tighty-whities.", "order": 36633, "speaker": "Jo Bennett"},
  {"season": 7, "episode": 1, "scene": 27, "line": "I will cover his ass like moss on a Mississippi tree stump.", "order": 36634, "speaker": "Michael Scott"},
  {"season": 7, "episode": 1, "scene": 27, "line": "Was how I put it not clear enough? I mean, you had to go and make up your own saying? Deal with it, boys.", "order": 36635, "speaker": "Jo Bennett"},
  {"season": 7, "episode": 1, "scene": 28, "line": "Luke and I have been working side-by-side as master and commander. Me as master, he as commander. Occasionally he will need a little push, and I will do that, and he'll slow down, and I'll push him again. That's the thing with kids, you have to keep pushing. You have to push them until they push you back. And then you push some more. It's all about give and take, but mostly it's about pushing each other.", "order": 36636, "speaker": "Michael Scott"},
  {"season": 7, "episode": 1, "scene": 29, "line": "Oh, yeah. This'll be easy. So just, like, rearrange the buttons and stuff.", "order": 36637, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 1, "scene": 29, "line": "Yeah, yeah. Like when he presses 'Doors closed' the doors open. Or he presses 'lobby' it goes to third floor, stuff like that. Can you do that?", "order": 36638, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 1, "scene": 29, "line": "Yeah. Let me take a look at the circus board.", "order": 36639, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 1, "scene": 29, "line": "Dwight is about to get so Pammed.", "order": 36640, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 1, "scene": 29, "line": "Hey, did you SMS text message me?", "order": 36641, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 1, "scene": 29, "line": "Yeah, I wanted to show you something in the lobby. Come on.", "order": 36642, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 1, "scene": 29, "line": "[as elevator doors won't shut] Okay, that's weird. Just hitting 'door closed.' What?", "order": 36643, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 1, "scene": 29, "line": "There we go.", "order": 36644, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 1, "scene": 29, "line": "Is this elevator going up? Hey, the elevator is disobeying us! [elevator stops between floors] Okay, okay. We are stuck. We are stuck. Hank, Hank! Can you hear us? Oh, my God. Okay. Emergency protocol.", "order": 36645, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 1, "scene": 29, "line": "Let's just calm down.", "order": 36646, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 1, "scene": 29, "line": "Pam, try and pry open those front doors, immediately.", "order": 36647, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 1, "scene": 29, "line": "I don't-", "order": 36648, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 1, "scene": 29, "line": "Use your talons! Pry 'em open! [starts to urinate in elevator corner]", "order": 36649, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 1, "scene": 29, "line": "Ok, Dwi-Oh, my God!", "order": 36650, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 1, "scene": 29, "line": "Well, don't look, freak!", "order": 36651, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 1, "scene": 29, "line": "Dwight, what are you doing!? We've only been in here for, like, two seconds!", "order": 36652, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 1, "scene": 29, "line": "I've got 56 ounces of fluid in my bladder! And we have to establish a pee corner!", "order": 36653, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 1, "scene": 29, "line": "You've gotta be kidding me.", "order": 36654, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 1, "scene": 30, "line": "Luke is going to use his laser pointer to point to account setup. You click on that first, then a whole new-Not, no, no, no! [Luke points laser at Oscar's forehead] Luke, come on. Come on. Not on Oscar's head. Alright. Luke, come on. Stop it!", "order": 36655, "speaker": "Michael Scott"},
  {"season": 7, "episode": 1, "scene": 30, "line": "What? I'm stopping it. That's what you said.", "order": 36656, "speaker": "Luke"},
  {"season": 7, "episode": 1, "scene": 30, "line": "Give me the pointer.", "order": 36657, "speaker": "Michael Scott"},
  {"season": 7, "episode": 1, "scene": 30, "line": "So you just want me to move it? You're being totally unclear here. I'm just gonna go ahead and move it. [points it at Angela's chest]", "order": 36658, "speaker": "Luke"},
  {"season": 7, "episode": 1, "scene": 30, "line": "No, just power it down. No, no, no, no, no. Not on Angela's boob. Come on. Come on. Luke, seriously.", "order": 36659, "speaker": "Michael Scott"},
  {"season": 7, "episode": 1, "scene": 30, "line": "All right, fine. Fine. I'll just go.", "order": 36660, "speaker": "Luke"},
  {"season": 7, "episode": 1, "scene": 30, "line": "Okay.", "order": 36661, "speaker": "Michael Scott"},
  {"season": 7, "episode": 1, "scene": 30, "line": "I'm gone.", "order": 36662, "speaker": "Luke"},
  {"season": 7, "episode": 1, "scene": 30, "line": "All right. Okay. Good job. Okay, email accounts. So we're starting with account setup-", "order": 36663, "speaker": "Michael Scott"},
  {"season": 7, "episode": 1, "scene": 30, "line": "Oh, my God, is it in my eyeball? I think he's burning my eye.", "order": 36664, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 1, "scene": 30, "line": "Michael, do something.", "order": 36665, "speaker": "Angela Martin"},
  {"season": 7, "episode": 1, "scene": 30, "line": "Sack up, man.", "order": 36666, "speaker": "Meredith Palmer"},
  {"season": 7, "episode": 1, "scene": 30, "line": "Okay, can I have that?", "order": 36667, "speaker": "Michael Scott"},
  {"season": 7, "episode": 1, "scene": 30, "line": "No.", "order": 36668, "speaker": "Luke"},
  {"season": 7, "episode": 1, "scene": 30, "line": "Ok, I am going to count down from five.", "order": 36669, "speaker": "Michael Scott"},
  {"season": 7, "episode": 1, "scene": 30, "line": "Five, four...", "order": 36670, "speaker": "Both"},
  {"season": 7, "episode": 1, "scene": 30, "line": "I'm not kidding.", "order": 36671, "speaker": "Michael Scott"},
  {"season": 7, "episode": 1, "scene": 30, "line": "Three, two, one.", "order": 36672, "speaker": "Both"},
  {"season": 7, "episode": 1, "scene": 30, "line": "Give-okay. Alright, give it up.", "order": 36673, "speaker": "Michael Scott"},
  {"season": 7, "episode": 1, "scene": 30, "line": "No!", "order": 36674, "speaker": "Luke"},
  {"season": 7, "episode": 1, "scene": 30, "line": "[bending Luke over desk, spanking him repeatedly] You are going to learn to be more professional, Luke!", "order": 36675, "speaker": "Michael Scott"},
  {"season": 7, "episode": 1, "scene": 30, "line": "What the?", "order": 36676, "speaker": "Luke"},
  {"season": 7, "episode": 1, "scene": 30, "line": "That's what you're going to do, Luke!", "order": 36677, "speaker": "Michael Scott"},
  {"season": 7, "episode": 1, "scene": 30, "line": "What the hell was that?", "order": 36678, "speaker": "Luke"},
  {"season": 7, "episode": 1, "scene": 30, "line": "I had to do that.", "order": 36679, "speaker": "Michael Scott"},
  {"season": 7, "episode": 1, "scene": 30, "line": "Hey, [bleep] this! Screw it!", "order": 36680, "speaker": "Luke"},
  {"season": 7, "episode": 1, "scene": 30, "line": "All right. Are we good? [Luke runs out crying] You're okay. He's okay. There are many different schools of thought on capital punishment.", "order": 36681, "speaker": "Michael Scott"},
  {"season": 7, "episode": 1, "scene": 30, "line": "That was awesome!", "order": 36682, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 1, "scene": 30, "line": "Texas justice.", "order": 36683, "speaker": "Stanley Hudson"},
  {"season": 7, "episode": 1, "scene": 30, "line": "Yeah, your nephew is so lame.", "order": 36684, "speaker": "Kelly Kapoor"},
  {"season": 7, "episode": 1, "scene": 30, "line": "He's been trashing us relentlessly on Twitter. Yeah, it's funny stuff, but mean.", "order": 36685, "speaker": "Creed Bratton"},
  {"season": 7, "episode": 1, "scene": 30, "line": "You follow him on Twitter?", "order": 36686, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 1, "scene": 31, "line": "Everywhere I look it's Betty White this and Betty White that. Finally a kid that's not talking about Betty White. Of course I follow him.", "order": 36687, "speaker": "Creed Bratton"},
  {"season": 7, "episode": 1, "scene": 32, "line": "Michael, you just physically assaulted an employee. Can we talk in private?", "order": 36688, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 1, "scene": 32, "line": "Yes, of course. What is this in reference to?", "order": 36689, "speaker": "Michael Scott"},
  {"season": 7, "episode": 1, "scene": 33, "line": "Pam?", "order": 36690, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 1, "scene": 33, "line": "Oh, hey Jim. Some prankster switched the elevator buttons on Dwight.", "order": 36691, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 1, "scene": 33, "line": "I did not do this.", "order": 36692, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 1, "scene": 33, "line": "I know. Yeah, and it was going really great at first. I got video.", "order": 36693, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 1, "scene": 33, "line": "This is impressive.", "order": 36694, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 1, "scene": 33, "line": "Well, you know... they don't call me the Bart Simpson of Scranton for nothing.", "order": 36695, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 1, "scene": 33, "line": "Do they call you that?", "order": 36696, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 1, "scene": 33, "line": "They do call me that.", "order": 36697, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 1, "scene": 33, "line": "Come on, give me your hand. I'll help you down.", "order": 36698, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 1, "scene": 33, "line": "Nope. Scared of getting cut in half. Also, there's pee on the floor.", "order": 36699, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 1, "scene": 33, "line": "Oh! Of course there is. Hey, Chinese tonight?", "order": 36700, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 1, "scene": 33, "line": "Yeah.", "order": 36701, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 1, "scene": 33, "line": "Great.", "order": 36702, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 1, "scene": 33, "line": "How ya doin'?", "order": 36703, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 1, "scene": 33, "line": "Pretty good. You must be almost out of water.", "order": 36704, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 1, "scene": 33, "line": "Stop drinking the water! Stop!", "order": 36705, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 1, "scene": 34, "line": "[Phyllis and Andy act out Luke getting spanked] No, no, no, no. It's the other hand. Right hand. Yeah.", "order": 36706, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 1, "scene": 35, "line": "Would have been nice to work with my family. It would have been nice if Luke had been able to stay here. But he couldn't. These things seldom work out. I don't know how Ringling Brothers do it. Night after night, town after town, all across America. You would think they'd be sick of each other by now. But, clearly, they make it work. And my hat's off to them.", "order": 36707, "speaker": "Michael Scott"},
  {"season": 7, "episode": 1, "scene": 36, "line": "[Dwight starts aggressively spanking Andy] Whoa! Whoa! Hey. Whoa. Dwight! Stop!", "order": 36708, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 1, "scene": 37, "line": "Legal says the best way to protect us from a lawsuit is to call this a stress-induced outburst. You will have to undergo six sessions of counseling.", "order": 36709, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 1, "scene": 37, "line": "That's it? Really? That's nothing. All right. That's highway robbery.", "order": 36710, "speaker": "Michael Scott"},
  {"season": 7, "episode": 1, "scene": 37, "line": "Good. Well, that's the spirit.", "order": 36711, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 1, "scene": 37, "line": "I can do that.", "order": 36712, "speaker": "Michael Scott"},
  {"season": 7, "episode": 1, "scene": 37, "line": "And, uh, you will do your counseling right here, because our HR staff are all certified counselors.", "order": 36713, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 1, "scene": 37, "line": "Okay. Wait, what?", "order": 36714, "speaker": "Michael Scott"},
  {"season": 7, "episode": 1, "scene": 37, "line": "Yeah, I'm really looking forward to working with you, Michael.", "order": 36715, "speaker": "Toby Flenderson"},
  {"season": 7, "episode": 1, "scene": 37, "line": "Is there another option?", "order": 36716, "speaker": "Michael Scott"},
  {"season": 7, "episode": 1, "scene": 37, "line": "The alternative is termination. [Michael stares off, considering termination...]", "order": 36717, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 2, "scene": 1, "line": "Wow. Late every day this week.", "order": 36718, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 2, "scene": 1, "line": "We signed Cici up for this daycare. It's on the other side of town. The traffic...", "order": 36719, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 2, "scene": 1, "line": "Why didn't I think of this before? Did you know that there is a daycare center opening right here in this building?", "order": 36720, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 2, "scene": 1, "line": "Is there really?", "order": 36721, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 2, "scene": 2, "line": "Now that I own the building, I'm looking for new sources of revenue. And a daycare center... Ha ha ha [laughing maniacally] Well I guess it's not an evil idea. It's just a regular idea. But there is no good laugh for a regular idea.", "order": 36722, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 2, "scene": 3, "line": "Welcome to the Sesame Avenue Daycare Center for infants and toddlers.", "order": 36723, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 2, "scene": 3, "line": "Aaah!", "order": 36724, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 2, "scene": 3, "line": "Aaah!", "order": 36725, "speaker": "Mose Schrute"},
  {"season": 7, "episode": 2, "scene": 3, "line": "You remember my cousin Mose.", "order": 36726, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 2, "scene": 3, "line": "Welcome children.", "order": 36727, "speaker": "Mose Schrute"},
  {"season": 7, "episode": 2, "scene": 3, "line": "Were you painting in the dark?", "order": 36728, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 2, "scene": 3, "line": "Wait. Is this your place Dwight?", "order": 36729, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 2, "scene": 3, "line": "Oh no. I like to think of it as a kids place. Would you like a tour?", "order": 36730, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 2, "scene": 3, "line": "I don't really think we need...", "order": 36731, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 2, "scene": 3, "line": "Oh... lets take the tour Pam.", "order": 36732, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 2, "scene": 3, "line": "Yeah.", "order": 36733, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 2, "scene": 3, "line": "Come on. Here is the language skills and cognitive development area. These are English... uh... letters. [pause] I see you found out magical toy box Jim.", "order": 36734, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 2, "scene": 3, "line": "These are actually forks and knives from the break room.", "order": 36735, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 2, "scene": 3, "line": "Jim! To you and me, maybe, but, come on! To a child's imagination that's Mr. Fork and Lieutenant Knife and... Ms. Fork.", "order": 36736, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 2, "scene": 3, "line": "And a soy sauce packet.", "order": 36737, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 2, "scene": 3, "line": "Oh! That shouldn't have been in there. I'm embarrassed.", "order": 36738, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 2, "scene": 3, "line": "Don't beat yourself up.", "order": 36739, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 2, "scene": 3, "line": "We come fully equipped with a restroom. Feeding trough, play bucket, and room for a plant, in success.", "order": 36740, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 2, "scene": 3, "line": "Who will be watching the children?", "order": 36741, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 2, "scene": 3, "line": "No one. The door locks from the outside. Escape is impossible.", "order": 36742, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 2, "scene": 3, "line": "Prove it.", "order": 36743, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 2, "scene": 3, "line": "What?", "order": 36744, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 2, "scene": 3, "line": "Were going to head outside, give it a whirl. I just want to see how it works.", "order": 36745, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 2, "scene": 3, "line": "Oh no, no, no.", "order": 36746, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 2, "scene": 3, "line": "But I'll tell you this, if this works out, I think we're looking real good.", "order": 36747, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 2, "scene": 3, "line": "I promise you, that door locks! [Jim closes the door]", "order": 36748, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 2, "scene": 4, "line": "What's something that you...", "order": 36749, "speaker": "Toby Flenderson"},
  {"season": 7, "episode": 2, "scene": 4, "line": "This is the worst! You are the worst! I hate looking at your face! I wanna smash it!", "order": 36750, "speaker": "Michael Scott"},
  {"season": 7, "episode": 2, "scene": 5, "line": "Ok, you know what? I made a mistake. I committed corporate punishment. My bad. It's over. It's done. But my punishment is ... um ... worse than hell.", "order": 36751, "speaker": "Michael Scott"},
  {"season": 7, "episode": 2, "scene": 6, "line": "Michael has been assigned six hours of mandatory counseling with a trained professional. I actually have a degree in social work. I mean, I know a lot of people would ask a few standard questions, and check off a few boxes, but I've got a chance to do some good here.", "order": 36752, "speaker": "Toby Flenderson"},
  {"season": 7, "episode": 2, "scene": 7, "line": "I know what you want to ask me. 'Did your mom ever see you naked?'", "order": 36753, "speaker": "Michael Scott"},
  {"season": 7, "episode": 2, "scene": 7, "line": "We can do this with more privacy.", "order": 36754, "speaker": "Toby Flenderson"},
  {"season": 7, "episode": 2, "scene": 7, "line": "So you can molest me? Oh, ok. I don't think so. We're going to leave the blinds open so every one can see what a big failure you are. That's the key.", "order": 36755, "speaker": "Michael Scott"},
  {"season": 7, "episode": 2, "scene": 8, "line": "Boycott the Steamtown Mall! Everyone, you heard me! Cancel all of your business with the Steamtown Mall!", "order": 36756, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 2, "scene": 8, "line": "The mall itself or just the stores in the mall?", "order": 36757, "speaker": "Phyllis Vance"},
  {"season": 7, "episode": 2, "scene": 8, "line": "All of it! The mall, the stores, the kiosks!", "order": 36758, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 2, "scene": 8, "line": "America is one big mall.", "order": 36759, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 2, "scene": 8, "line": "Did something happen Dwight?", "order": 36760, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 2, "scene": 8, "line": "Yeah, something happened. Oh yeah, something happened, Jim.", "order": 36761, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 2, "scene": 8, "line": "Well, tell us what it is. I mean, it would help us to get some context so we can get on board.", "order": 36762, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 2, "scene": 8, "line": "You don't want to know.", "order": 36763, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 2, "scene": 9, "line": "I'd like you to imagine a place where you feel very peaceful. For me, it's the walk from the yogurt shop to my car after I drop my daughter off on Sunday afternoons.", "order": 36764, "speaker": "Toby Flenderson"},
  {"season": 7, "episode": 2, "scene": 10, "line": "I'm glad Michael is getting help. He as a lot of issues, and he's stupid.", "order": 36765, "speaker": "Phyllis Vance"},
  {"season": 7, "episode": 2, "scene": 11, "line": "[on phone] Look, I'm not going to tell you that we have lower prices. Is price something that is important to you? Ok, well let me know if anything changes.", "order": 36766, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 2, "scene": 11, "line": "Pam?", "order": 36767, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 2, "scene": 11, "line": "I can't do this. I don't have this sales gene, or whatever it is. Tell me everything is going to be ok.", "order": 36768, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 2, "scene": 11, "line": "Everything is going to be ok.", "order": 36769, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 2, "scene": 11, "line": "Tell me I'm good at sales.", "order": 36770, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 2, "scene": 11, "line": "You're good... you're good at sales.", "order": 36771, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 2, "scene": 12, "line": "The unfair thing about working in sales is that your salary is almost all commission. So, you suck at sales, you make almost no money. I guess that's fair.", "order": 36772, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 2, "scene": 13, "line": "Dwight?", "order": 36773, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 2, "scene": 13, "line": "What?", "order": 36774, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 2, "scene": 13, "line": "Did you call all of my clients at the mall and cancel all my business while using the 'F' word?", "order": 36775, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 2, "scene": 13, "line": "Yes I did. And I'm going to do the same with all of your clients.", "order": 36776, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 2, "scene": 13, "line": "No you will not.", "order": 36777, "speaker": "Stanley Hudson"},
  {"season": 7, "episode": 2, "scene": 13, "line": "Oh yes I will! That mall is corrupt, ok! They're 'appearance'ists!", "order": 36778, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 2, "scene": 13, "line": "Ok, Dwight. We can't do that. So why don't you just tell us what happened?", "order": 36779, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 2, "scene": 13, "line": "Yeah, what happened Dwight?", "order": 36780, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 2, "scene": 13, "line": "Ok.", "order": 36781, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 2, "scene": 13, "line": "You guys know me. You know that I'm not one for fancy things, but there was this one thing. It was in a fancy store. And it caught the corner of my eye when I took Mose down to the Mall to get his blood pressure at the Rite-Aid. And I thought, you know, maybe I deserve this. You know, I had a great crop yield this fall...", "order": 36782, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 2, "scene": 13, "line": "So what happened?", "order": 36783, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 2, "scene": 13, "line": "I went to the store and I pressed the buzzer, and they looked right at me, and then they looked away. And then I pressed the buzzer again, and they started taking pictures of me on their mobile phones. I guess I'm not the kind of guy that's good enough for precious heirlooms.", "order": 36784, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 2, "scene": 13, "line": "You know what Dwight? You need to go back there, and you need to 'Pretty Woman' their asses.", "order": 36785, "speaker": "Kelly Kapoor"},
  {"season": 7, "episode": 2, "scene": 13, "line": "We should start our own mall!", "order": 36786, "speaker": "Creed Bratton"},
  {"season": 7, "episode": 2, "scene": 13, "line": "Yeah!", "order": 36787, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 2, "scene": 13, "line": "Wait, wait, wait. That's actually a really good idea Kelly.", "order": 36788, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 2, "scene": 13, "line": "What did I say?", "order": 36789, "speaker": "Kelly Kapoor"},
  {"season": 7, "episode": 2, "scene": 14, "line": "I talk a lot, so I've learned to just tune myself out.", "order": 36790, "speaker": "Kelly Kapoor"},
  {"season": 7, "episode": 2, "scene": 15, "line": "Dwight, how about this? Instead of boycotting, you and I, together, go down to that store and we teach those snobs a lesson.", "order": 36791, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 2, "scene": 15, "line": "No, you and I... and I. I'm going to come with you.", "order": 36792, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 2, "scene": 15, "line": "Ok", "order": 36793, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 2, "scene": 15, "line": "Alright.", "order": 36794, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 2, "scene": 16, "line": "Did you assume that I would automatically side with the rich snobby shop owner? [removes cuff link] How about now? [removes other cuff link] How about now? [removes tie clip] How about now?", "order": 36795, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 2, "scene": 17, "line": "Uh... what was your favorite flavor of...", "order": 36796, "speaker": "Toby Flenderson"},
  {"season": 7, "episode": 2, "scene": 17, "line": "One hour.", "order": 36797, "speaker": "Michael Scott"},
  {"season": 7, "episode": 2, "scene": 17, "line": "What?", "order": 36798, "speaker": "Toby Flenderson"},
  {"season": 7, "episode": 2, "scene": 17, "line": "One hour. We've done one hour. Let's just speed this up, ok? Keep it moving. I want to do all six hours today.", "order": 36799, "speaker": "Michael Scott"},
  {"season": 7, "episode": 2, "scene": 17, "line": "Ok, I can't count the hour if you don't talk.", "order": 36800, "speaker": "Toby Flenderson"},
  {"season": 7, "episode": 2, "scene": 17, "line": "No, no, no! I know my rights! All I had to do was sit here for six hours. Do my time.", "order": 36801, "speaker": "Michael Scott"},
  {"season": 7, "episode": 2, "scene": 17, "line": "Michael, it's up to me to check off the boxes, and I can't do that unless I honestly...", "order": 36802, "speaker": "Toby Flenderson"},
  {"season": 7, "episode": 2, "scene": 17, "line": "You know, do you have any idea how angry this is making me?", "order": 36803, "speaker": "Michael Scott"},
  {"season": 7, "episode": 2, "scene": 17, "line": "Tell me!", "order": 36804, "speaker": "Toby Flenderson"},
  {"season": 7, "episode": 2, "scene": 18, "line": "I think we might be about to maybe really get somewhere.", "order": 36805, "speaker": "Toby Flenderson"},
  {"season": 7, "episode": 2, "scene": 19, "line": "Yes, I will. I will talk alright. Be careful what you wish for Toby.", "order": 36806, "speaker": "Michael Scott"},
  {"season": 7, "episode": 2, "scene": 20, "line": "Apparently there is a famous Hollywood movie from the 1980's, Beautiful Girl...", "order": 36807, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 2, "scene": 20, "line": "Pretty Woman.", "order": 36808, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 2, "scene": 20, "line": "Apparently, it's one of the best revenge stories of all times, in which this sex worker, who is the antagonist... That can't be right. Andy? How does it...?", "order": 36809, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 2, "scene": 20, "line": "No, no. I want to hear you tell it.", "order": 36810, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 2, "scene": 20, "line": "Ok, Um... The sex worker is denied service at a fancy store because she does not look wealthy. She later returns dressed in all the 'trappings' of extravagant wealth, but instead of going...", "order": 36811, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 2, "scene": 20, "line": "Julia Roberts goes into the store, and she's like,' I was in here yesterday and you wouldn't help me.' And the shop girl goes, 'ok'. And Julia Roberts goes,' You girls work on commission, right?' And the girl is like,'Yeah', and Julia Roberts goes...", "order": 36812, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 2, "scene": 20, "line": "Big mistake! Huge!'", "order": 36813, "speaker": "Kelly Kapoor"},
  {"season": 7, "episode": 2, "scene": 20, "line": "I was telling that... I was telling that.", "order": 36814, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 2, "scene": 21, "line": "How many paper supplies and ink cartridges to go with that? Ok, sure.", "order": 36815, "speaker": "Stanley Hudson"},
  {"season": 7, "episode": 2, "scene": 21, "line": "Who can I speak to in this office about saving on window treatments?", "order": 36816, "speaker": "Window treatment guy"},
  {"season": 7, "episode": 2, "scene": 21, "line": "I don't know.", "order": 36817, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 2, "scene": 21, "line": "Is one of you the office administrator?", "order": 36818, "speaker": "Window treatment guy"},
  {"season": 7, "episode": 2, "scene": 21, "line": "[hesitates] I am. I am the office administrator!", "order": 36819, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 2, "scene": 21, "line": "Can I show you a few samples?", "order": 36820, "speaker": "Window treatment guy"},
  {"season": 7, "episode": 2, "scene": 21, "line": "Oh, we're not interested. We're not interested at all.", "order": 36821, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 2, "scene": 22, "line": "There are a few ways to get promoted. One is to wait for an opening and apply for it. That's the main way. But this could work.", "order": 36822, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 2, "scene": 23, "line": "Toby, can I really tell you anything?", "order": 36823, "speaker": "Michael Scott"},
  {"season": 7, "episode": 2, "scene": 23, "line": "Of course.", "order": 36824, "speaker": "Toby Flenderson"},
  {"season": 7, "episode": 2, "scene": 23, "line": "Well, the other night, I was sitting at the table, eating my penis... I mean peas. That was weird. Aaaah... weird. I think that was ... I was probed. By an alien life form. An A.L.F. Alf, you know, I might have actually been probed by Alf. You might think he's a puppet. You never see the lower half. But there is a lower half.", "order": 36825, "speaker": "Michael Scott"},
  {"season": 7, "episode": 2, "scene": 24, "line": "Office Administrator. So when did this happen?", "order": 36826, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 2, "scene": 24, "line": "A few months ago. I was talking to someone at corporate, who isn't there anymore, and I think the paperwork just got lost in the shuffle. Can you believe that?", "order": 36827, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 2, "scene": 24, "line": "Yeah, totally. Well, congrats!", "order": 36828, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 2, "scene": 24, "line": "Thanks! Yeah, so I'm just going to take care of things around the office and get paid a reasonable salary. You believe that, right?", "order": 36829, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 2, "scene": 24, "line": "Totally, that's great.", "order": 36830, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 2, "scene": 24, "line": "I get paid $40,000 per year.", "order": 36831, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 2, "scene": 24, "line": "Great.", "order": 36832, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 2, "scene": 24, "line": "Maybe $50,000.", "order": 36833, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 2, "scene": 24, "line": "$50,000?", "order": 36834, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 2, "scene": 24, "line": "No, not $50,000, $41,000, I think. $41,500.", "order": 36835, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 2, "scene": 24, "line": "That's great.", "order": 36836, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 2, "scene": 25, "line": "Before me stands your coworker, Dwight Kurt Schrute. Dwight, show them all sides. Turn around. Now, today, we need your help turning Dwight into a gentleman.", "order": 36837, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 2, "scene": 25, "line": "A gentleman who is a rich snob who will go into shopping malls and drop huge amounts of cash on clothes.", "order": 36838, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 2, "scene": 25, "line": "Is he still doing his boycott?", "order": 36839, "speaker": "Stanley Hudson"},
  {"season": 7, "episode": 2, "scene": 25, "line": "No, this is instead of the boycott. Yeah?", "order": 36840, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 2, "scene": 25, "line": "You shirt and tie are disgusto-barfo.", "order": 36841, "speaker": "Kelly Kapoor"},
  {"season": 7, "episode": 2, "scene": 25, "line": "Agreed.", "order": 36842, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 2, "scene": 25, "line": "Really?", "order": 36843, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 2, "scene": 25, "line": "Maybe something not so monochromatic. Not so matching.", "order": 36844, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 2, "scene": 25, "line": "Wait. Less matching to appear more rich?", "order": 36845, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 2, "scene": 25, "line": "The glasses are a little... [Dwight drops glasses to the floor, stomps on them]", "order": 36846, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 2, "scene": 25, "line": "I liked them.", "order": 36847, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 2, "scene": 25, "line": "I thought they were kind of cute.", "order": 36848, "speaker": "Kelly Kapoor"},
  {"season": 7, "episode": 2, "scene": 25, "line": "Yeah, I liked them too.", "order": 36849, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 2, "scene": 25, "line": "I can't see.", "order": 36850, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 2, "scene": 25, "line": "Say stuff like, 'Good morning, Good Afternoon'. People appreciate that.", "order": 36851, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 2, "scene": 25, "line": "Wait, I see you every day. Can I say 'Good month?'", "order": 36852, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 2, "scene": 25, "line": "I'm telling you how to do this man.", "order": 36853, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 2, "scene": 25, "line": "If someone offers you a cocktail, accept, but keep your wits about you.", "order": 36854, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 2, "scene": 25, "line": "Please and thank you go a long way.", "order": 36855, "speaker": "Angela Martin"},
  {"season": 7, "episode": 2, "scene": 25, "line": "Copy. Thank you.", "order": 36856, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 2, "scene": 25, "line": "Thank you.", "order": 36857, "speaker": "Angela Martin"},
  {"season": 7, "episode": 2, "scene": 25, "line": "Please.", "order": 36858, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 2, "scene": 25, "line": "[in a cockney accent] I think he's got it!", "order": 36859, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 2, "scene": 26, "line": "And I was raised by wolves. I was 25 years old before I saw my first human being. Wait, is it a full moon tonight? [howls and growls like a werewolf]", "order": 36860, "speaker": "Michael Scott"},
  {"season": 7, "episode": 2, "scene": 26, "line": "Come on Michael. Those are all fake stories.", "order": 36861, "speaker": "Toby Flenderson"},
  {"season": 7, "episode": 2, "scene": 26, "line": "Yes, Yes. They are all fake stories. What sort of twisted mind would come up with weird stories like that? [pause] Three hours. We're half way done.", "order": 36862, "speaker": "Michael Scott"},
  {"season": 7, "episode": 2, "scene": 26, "line": "Oh.", "order": 36863, "speaker": "Toby Flenderson"},
  {"season": 7, "episode": 2, "scene": 27, "line": "Hey, do you have a second?", "order": 36864, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 2, "scene": 27, "line": "Yeah.", "order": 36865, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 2, "scene": 27, "line": "Well, as I am sure you know, for the past few months, I've been the Office Administrator. Since right before you guys took over.", "order": 36866, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 2, "scene": 27, "line": "Right, of course!", "order": 36867, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 2, "scene": 27, "line": "And, I haven't gotten paid yet. I'm not blaming you.", "order": 36868, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 2, "scene": 27, "line": "Thank you so much.", "order": 36869, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 2, "scene": 27, "line": "I just think somebody lost the paperwork.", "order": 36870, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 2, "scene": 27, "line": "Oh boy. Can you get every department head's signature on this so I can back this up to corporate?", "order": 36871, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 2, "scene": 27, "line": "Yes. Absolutely. Right away.", "order": 36872, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 2, "scene": 28, "line": "You know what Michael? You're right. You win. This is pointless. We're not getting anywhere. I got a bunch of fun stuff, in case my daughter ever comes over. Why don't we just run out the clock by playing games, drawing some pictures, talking?", "order": 36873, "speaker": "Toby Flenderson"},
  {"season": 7, "episode": 2, "scene": 29, "line": "[fanfare]", "order": 36874, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 2, "scene": 29, "line": "We present to you for your comments and approval, Dwight K. Shrute.", "order": 36875, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 2, "scene": 29, "line": "Go get 'em Dwight.", "order": 36876, "speaker": "Phyllis Vance"},
  {"season": 7, "episode": 2, "scene": 29, "line": "Wish us luck!", "order": 36877, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 2, "scene": 29, "line": "Thank you.", "order": 36878, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 2, "scene": 29, "line": "Dwight [snaps a picture with a disposable camera, then drops the camera in the trash can]", "order": 36879, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 2, "scene": 30, "line": "Disposable cameras are fun, although it does seem wasteful and you don't ever get to see your pictures. If it's an important even that you want to remember, I recommend using a real camera. But I don't care if I forget today.", "order": 36880, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 2, "scene": 31, "line": "We can play something more complicated if you like.", "order": 36881, "speaker": "Toby Flenderson"},
  {"season": 7, "episode": 2, "scene": 31, "line": "This is plenty complicated.", "order": 36882, "speaker": "Michael Scott"},
  {"season": 7, "episode": 2, "scene": 31, "line": "So you have played it before?", "order": 36883, "speaker": "Toby Flenderson"},
  {"season": 7, "episode": 2, "scene": 31, "line": "I've played it once or twice with Jeff.", "order": 36884, "speaker": "Michael Scott"},
  {"season": 7, "episode": 2, "scene": 31, "line": "Who's Jeff?", "order": 36885, "speaker": "Toby Flenderson"},
  {"season": 7, "episode": 2, "scene": 31, "line": "Jeff was my mother's boyfriend, who she married.", "order": 36886, "speaker": "Michael Scott"},
  {"season": 7, "episode": 2, "scene": 31, "line": "So, her husband, your stepdad?", "order": 36887, "speaker": "Toby Flenderson"},
  {"season": 7, "episode": 2, "scene": 31, "line": "Yeah, Yeah. I guess I never thought about it that way, though.", "order": 36888, "speaker": "Michael Scott"},
  {"season": 7, "episode": 2, "scene": 31, "line": "Did you guys do much stuff together?", "order": 36889, "speaker": "Toby Flenderson"},
  {"season": 7, "episode": 2, "scene": 31, "line": "Yeah. You know what? He took me to a baseball game once, I remember. It was weird though. They took the pitcher out of the game. I felt really bad, because the pitcher wasn't able to play with his friends anymore. But Jeff said that the manager was making a really good move, by taking the pitcher out. He really respected the manager.", "order": 36890, "speaker": "Michael Scott"},
  {"season": 7, "episode": 2, "scene": 32, "line": "It's working. I'm doing it!", "order": 36891, "speaker": "Toby Flenderson"},
  {"season": 7, "episode": 2, "scene": 33, "line": "[coughs for attention]", "order": 36892, "speaker": "Angela Martin"},
  {"season": 7, "episode": 2, "scene": 33, "line": "What?", "order": 36893, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 2, "scene": 33, "line": "My place tonight. Wear this. [motioning to his new outfit]", "order": 36894, "speaker": "Angela Martin"},
  {"season": 7, "episode": 2, "scene": 33, "line": "Do you have your card?", "order": 36895, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 2, "scene": 33, "line": "Yes. Don't forget the pipe.", "order": 36896, "speaker": "Angela Martin"},
  {"season": 7, "episode": 2, "scene": 34, "line": "Its really sad that your dog ran away. Why did it keep you from going to the park?", "order": 36897, "speaker": "Toby Flenderson"},
  {"season": 7, "episode": 2, "scene": 34, "line": "I was afraid I would find him in the park, playing with another kid.", "order": 36898, "speaker": "Michael Scott"},
  {"season": 7, "episode": 2, "scene": 34, "line": "Why?", "order": 36899, "speaker": "Toby Flenderson"},
  {"season": 7, "episode": 2, "scene": 34, "line": "I guess that's why I thought he ran away in the first place. To find a kid he liked better.", "order": 36900, "speaker": "Michael Scott"},
  {"season": 7, "episode": 2, "scene": 34, "line": "Dogs don't do that.", "order": 36901, "speaker": "Toby Flenderson"},
  {"season": 7, "episode": 2, "scene": 34, "line": "Right. Why would a dog do that? That's silly.", "order": 36902, "speaker": "Michael Scott"},
  {"season": 7, "episode": 2, "scene": 34, "line": "I don't know.", "order": 36903, "speaker": "Toby Flenderson"},
  {"season": 7, "episode": 2, "scene": 34, "line": "When I hear myself say it, it sounds ridiculous. But I've never said it out loud, so...", "order": 36904, "speaker": "Michael Scott"},
  {"season": 7, "episode": 2, "scene": 34, "line": "It's very important for you to be liked, isn't it?", "order": 36905, "speaker": "Toby Flenderson"},
  {"season": 7, "episode": 2, "scene": 34, "line": "Well, lets not get too... This isn't a counseling session... Ok. Son of a gun. Son of a bitch. Wow! Ok.", "order": 36906, "speaker": "Michael Scott"},
  {"season": 7, "episode": 2, "scene": 34, "line": "I'm just trying to help you Michael.", "order": 36907, "speaker": "Toby Flenderson"},
  {"season": 7, "episode": 2, "scene": 34, "line": "You. Bitch. God! You're very helpful aren't you? You try to help everybody. Do you want to play another game?", "order": 36908, "speaker": "Michael Scott"},
  {"season": 7, "episode": 2, "scene": 34, "line": "I just want to...", "order": 36909, "speaker": "Toby Flenderson"},
  {"season": 7, "episode": 2, "scene": 34, "line": "You are good. But you know what? You can't help people. You couldn't help your marriage. You lose. I don't need your help. Nobody needs your help. Nobody wants it! You can't help anybody. I don't need your help! Am I going to make you cry?", "order": 36910, "speaker": "Michael Scott"},
  {"season": 7, "episode": 2, "scene": 34, "line": "[tossing forms at Michael] Just fill them out any way you want.", "order": 36911, "speaker": "Toby Flenderson"},
  {"season": 7, "episode": 2, "scene": 34, "line": "Ok.", "order": 36912, "speaker": "Michael Scott"},
  {"season": 7, "episode": 2, "scene": 34, "line": "I'll have Erin fax them back to corporate.", "order": 36913, "speaker": "Toby Flenderson"},
  {"season": 7, "episode": 2, "scene": 34, "line": "You'll do. . . ok. Filled out. Good. Thank you doctor. Take two of these [giving Toby two middle fingers] and call me in the morning.", "order": 36914, "speaker": "Michael Scott"},
  {"season": 7, "episode": 2, "scene": 35, "line": "Hey Michael, um... about three months ago, I was talking to... [Michael grabs form and signs it without reading.]", "order": 36915, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 2, "scene": 36, "line": "There are a lot of one person departments here, so, there's a lot of department heads. But I'm off to a good start. Oh, man! If I could pull this off, it will be scam of all scams. And yet very helpful to everyone.", "order": 36916, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 2, "scene": 37, "line": "So, Corporate got your evaluations, and they want me to check, is that really how you feel about Michaels situation?", "order": 36917, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 2, "scene": 37, "line": "Yes.", "order": 36918, "speaker": "Toby Flenderson"},
  {"season": 7, "episode": 2, "scene": 37, "line": "That is not how it seems to me.", "order": 36919, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 2, "scene": 37, "line": "He seems fine.", "order": 36920, "speaker": "Toby Flenderson"},
  {"season": 7, "episode": 2, "scene": 37, "line": "You marked severe in all the categories, including at risk for homicidal behavior.", "order": 36921, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 2, "scene": 37, "line": "Heh.", "order": 36922, "speaker": "Toby Flenderson"},
  {"season": 7, "episode": 2, "scene": 38, "line": "I saw a TLC show on Kate Walsh's home office. All corkboard.", "order": 36923, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 2, "scene": 38, "line": "We can do that.", "order": 36924, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 2, "scene": 38, "line": "Right in here?", "order": 36925, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 2, "scene": 38, "line": "Easily.", "order": 36926, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 2, "scene": 38, "line": "I'm back! Ha ha. Pam, perfect. I was hoping to talk to the office administrator about a little office administration problem.", "order": 36927, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 2, "scene": 38, "line": "But of course!", "order": 36928, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 2, "scene": 38, "line": "Great.", "order": 36929, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 2, "scene": 39, "line": "The problem, unfortunately, is about the office administrator. I have gone through everything for the past three years. There is nothing that says you are the office administrator.", "order": 36930, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 2, "scene": 39, "line": "So weird that there is no paperwork.", "order": 36931, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 2, "scene": 39, "line": "At all.", "order": 36932, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 2, "scene": 39, "line": "Although, like, unlikely things happen all the time. My best friend, in High School, she went to Australia, Canberra I think, and she met this guy who lived only two streets away in America.", "order": 36933, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 2, "scene": 39, "line": "Pam, I don't want to accuse you of anything. I just want everything to be back the way it's supposed to be. Can you just admit... admit...", "order": 36934, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 2, "scene": 39, "line": "Admit what?", "order": 36935, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 2, "scene": 39, "line": "I don't want to say it.", "order": 36936, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 2, "scene": 39, "line": "Say it.", "order": 36937, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 2, "scene": 39, "line": "Mm-hmm.", "order": 36938, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 2, "scene": 39, "line": "Say that I'm lying or say that I have the job. Make a definitive statement, Gabe.", "order": 36939, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 2, "scene": 39, "line": "Statements of such nature, while they have their place, are overused in a competitive business environment.", "order": 36940, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 2, "scene": 39, "line": "Great. Well, Let me know if you need a new chair, or anything that an Office Administrator can handle.", "order": 36941, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 2, "scene": 39, "line": "Will do... Can I get one of those name plates... that says Gabe Lewis?", "order": 36942, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 2, "scene": 39, "line": "Sure. Anything else?", "order": 36943, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 2, "scene": 39, "line": "Nope.", "order": 36944, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 2, "scene": 39, "line": "I'll get it right away.", "order": 36945, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 2, "scene": 40, "line": "The first lesson of watching World Poker Tour at 2:00 AM, you play the opponent. Not the cards.", "order": 36946, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 2, "scene": 41, "line": "Is that what you were here for? A crystal wizard? I like it.", "order": 36947, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 2, "scene": 41, "line": "It is a pewter wizard holding a crystal.", "order": 36948, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 2, "scene": 41, "line": "Wait a second. You know you can't buy that now, right? And I can come back and get it for you later. But I'm saying right now, we can't do that.", "order": 36949, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 2, "scene": 41, "line": "Because I'm here for one thing. Revenge.", "order": 36950, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 2, "scene": 41, "line": "That's it.", "order": 36951, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 2, "scene": 41, "line": "Let me know if I can help you with anything.", "order": 36952, "speaker": "Salesman"},
  {"season": 7, "episode": 2, "scene": 41, "line": "Excuse me sir.", "order": 36953, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 2, "scene": 41, "line": "Yes?", "order": 36954, "speaker": "Salesman"},
  {"season": 7, "episode": 2, "scene": 41, "line": "I was here yesterday, and you refused to wait on me.", "order": 36955, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 2, "scene": 41, "line": "I remember, yes. I'm terribly sorry about that.", "order": 36956, "speaker": "Salesman"},
  {"season": 7, "episode": 2, "scene": 41, "line": "You work on commission, don't you?", "order": 36957, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 2, "scene": 41, "line": "Stop, stop, stop.", "order": 36958, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 2, "scene": 41, "line": "No, we don't.", "order": 36959, "speaker": "Salesman"},
  {"season": 7, "episode": 2, "scene": 41, "line": "Did you just say you remember him?", "order": 36960, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 2, "scene": 41, "line": "Of course, but he looks much less threatening now.", "order": 36961, "speaker": "Salesman"},
  {"season": 7, "episode": 2, "scene": 41, "line": "What does that mean?", "order": 36962, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 2, "scene": 41, "line": "We had a safety concern. Um... we very politely indicated that he'd be welcome back...", "order": 36963, "speaker": "Salesman"},
  {"season": 7, "episode": 2, "scene": 41, "line": "Good Morning!", "order": 36964, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 2, "scene": 41, "line": "If he were in accordance with our dress policy.", "order": 36965, "speaker": "Salesman"},
  {"season": 7, "episode": 2, "scene": 41, "line": "What?", "order": 36966, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 2, "scene": 41, "line": "But the blood stained hands...", "order": 36967, "speaker": "Salesman"},
  {"season": 7, "episode": 2, "scene": 41, "line": "It was beet juice! I am a beet farmer idi...", "order": 36968, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 2, "scene": 41, "line": "I'm very sorry.", "order": 36969, "speaker": "Salesman"},
  {"season": 7, "episode": 2, "scene": 41, "line": "Good sir. I happen to have been working a very long day. When I came to you fine establishment. You are such a... I'm gonna... Ok. Listen. You can't treat... Thank you! Good morning sir!", "order": 36970, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 2, "scene": 41, "line": "Lets just go.", "order": 36971, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 2, "scene": 41, "line": "You made a big mistake. Huge!", "order": 36972, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 2, "scene": 41, "line": "There it is. Alright.", "order": 36973, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 2, "scene": 41, "line": "Aaah!", "order": 36974, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 2, "scene": 41, "line": "That's pretty good.", "order": 36975, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 2, "scene": 41, "line": "After you.", "order": 36976, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 2, "scene": 41, "line": "[re-entering the store] I'll take the wizard!", "order": 36977, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 2, "scene": 41, "line": "Oh, ok!", "order": 36978, "speaker": "Salesman"},
  {"season": 7, "episode": 2, "scene": 42, "line": "You forged them! You forged the forms!", "order": 36979, "speaker": "Michael Scott"},
  {"season": 7, "episode": 2, "scene": 42, "line": "You filled them in and faxed them yourself!", "order": 36980, "speaker": "Toby Flenderson"},
  {"season": 7, "episode": 2, "scene": 42, "line": "Yes.", "order": 36981, "speaker": "Michael Scott"},
  {"season": 7, "episode": 2, "scene": 42, "line": "You remember that.", "order": 36982, "speaker": "Toby Flenderson"},
  {"season": 7, "episode": 2, "scene": 42, "line": "Yes, but you had me so worked up that I made a mistake.", "order": 36983, "speaker": "Michael Scott"},
  {"season": 7, "episode": 2, "scene": 42, "line": "Unless part of you made that mistake on purpose.", "order": 36984, "speaker": "Toby Flenderson"},
  {"season": 7, "episode": 2, "scene": 42, "line": "You don't make a mistake on purpose, Toby! Then it is no longer a mistake... Gabe. He messed it up. He messed up the forms, I bet... [mimicking Gabe] 'I mess everything up so I can fix it and keep my job! Bah! Good thing I'm here cause I do nothing. I make everybody nervous!' [normal voice] Ah, ok. Got your notebook? Alright. Alright, lets bang this out. Let's do it to it!", "order": 36985, "speaker": "Michael Scott"},
  {"season": 7, "episode": 3, "scene": 1, "line": "Andy? [Andy starts boombox and begins singing 'The Ballad of Sweeney Todd']", "order": 36986, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 3, "scene": 1, "line": "Yes! [as other costumed actors come in and join Andy in song]", "order": 36987, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 3, "scene": 1, "line": "Did you write this?", "order": 36988, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 3, "scene": 1, "line": "No.", "order": 36989, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 3, "scene": 1, "line": "Who did?", "order": 36990, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 3, "scene": 1, "line": "Steven Sondheim.", "order": 36991, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 3, "scene": 1, "line": "Who is he? [rest of Sweeney Todd cast enters and continues singing]", "order": 36992, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 3, "scene": 1, "line": "What the hell is happening?", "order": 36993, "speaker": "Angela Martin"},
  {"season": 7, "episode": 3, "scene": 2, "line": "We're the cast of Sweeney Todd: The Demon Barber of Fleet Street. We open in a couple weeks at the Loose Screw Playhouse here in Scranton and today just doing a wee bit of viral marketing.", "order": 36994, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 3, "scene": 3, "line": "[cheers loudly after cast finishes singing song] That was amazing! That was awesome! I auditioned for this. When did the cast list go up?", "order": 36995, "speaker": "Michael Scott"},
  {"season": 7, "episode": 3, "scene": 3, "line": "Like a month ago.", "order": 36996, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 3, "scene": 3, "line": "Really? They didn't call me. Who am I playing? ...Andy?", "order": 36997, "speaker": "Michael Scott"},
  {"season": 7, "episode": 3, "scene": 4, "line": "Two comps. For my lady and her Gabe. It's closing night. Tomorrow we have to give the theater over to the Scranton's Miss Fitness pageant.", "order": 36998, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 3, "scene": 4, "line": "I am so excited. But I just need one. Gabe can't come. Sorry.", "order": 36999, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 3, "scene": 4, "line": "What? That's awful. Everyone's gonna miss that guy.", "order": 37000, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 3, "scene": 5, "line": "Gabe is not coming, which is huge because my plan is to make Erin fall back in love with me tonight. Women cannot resist a man singing show tunes. It's so powerful, even a lot of men can't resist a man singing show tunes.", "order": 37001, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 3, "scene": 6, "line": "Oh, who else is going?", "order": 37002, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 3, "scene": 6, "line": "Everyone.", "order": 37003, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 3, "scene": 6, "line": "Gabe seemed to think no one was going.", "order": 37004, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 3, "scene": 6, "line": "What kind of terrible rumor monger is Gabe? He is woefully misinformed, all right? Excuse me, clink, clink, clink, clink, everybody. You're all coming to my show tonight, right?", "order": 37005, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 3, "scene": 6, "line": "Andy, what time's the show?", "order": 37006, "speaker": "Michael Scott"},
  {"season": 7, "episode": 3, "scene": 6, "line": "8pm sharp.", "order": 37007, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 3, "scene": 6, "line": "How long is it?", "order": 37008, "speaker": "Michael Scott"},
  {"season": 7, "episode": 3, "scene": 6, "line": "Hour forty-five.", "order": 37009, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 3, "scene": 6, "line": "Nope, can't make it.", "order": 37010, "speaker": "Michael Scott"},
  {"season": 7, "episode": 3, "scene": 7, "line": "They say that no one can take your pride, but the people who cast Andy's play, they took mine.", "order": 37011, "speaker": "Michael Scott"},
  {"season": 7, "episode": 3, "scene": 8, "line": "Jim, Pam, you guys are in, right?", "order": 37012, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 3, "scene": 8, "line": "Oh, we wanted to, but our sitter just fell through. I'm really sorry.", "order": 37013, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 3, "scene": 8, "line": "Dwight?", "order": 37014, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 3, "scene": 8, "line": "Uh, no, thank you. Last time I went to the theater, a man dressed as a cat sat on my lap.", "order": 37015, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 3, "scene": 9, "line": "It would mean so much to me if you came to my show tonight.", "order": 37016, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 3, "scene": 9, "line": "I can't Andy, it's too hard. That's-I put everything I had into that audition. Do you understand that?", "order": 37017, "speaker": "Michael Scott"},
  {"season": 7, "episode": 3, "scene": 9, "line": "If it makes you feel any better, no one who auditioned for the part of Sweeney Todd got that part. They had to bring in a ringer.", "order": 37018, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 3, "scene": 9, "line": "Wow.", "order": 37019, "speaker": "Michael Scott"},
  {"season": 7, "episode": 3, "scene": 9, "line": "This guy's like world-class. You should not feel bad. I'm asking you thespian to thespian. Will you please be the bigger man and come to my show?", "order": 37020, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 3, "scene": 9, "line": "I wish I could, Andy, but I can't. I have plans that night. I'm going to see a friend in a play called Sweeney Todd. You're that friend. I'm going to see your play.", "order": 37021, "speaker": "Michael Scott"},
  {"season": 7, "episode": 3, "scene": 9, "line": "Noishe!", "order": 37022, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 3, "scene": 9, "line": "And scene.", "order": 37023, "speaker": "Michael Scott"},
  {"season": 7, "episode": 3, "scene": 10, "line": "Listen, I would like you to take me to Andy's play tonight.", "order": 37024, "speaker": "Angela Martin"},
  {"season": 7, "episode": 3, "scene": 10, "line": "Please, waste of time. You know what? Let's just knock this out right now. Disrobe.", "order": 37025, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 3, "scene": 11, "line": "Angela? Don't like her anymore. Not attracted to her anymore. Just contractually obligated to make a baby with her.", "order": 37026, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 3, "scene": 12, "line": "Give me the punch card.", "order": 37027, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 3, "scene": 12, "line": "No. If you want to punch the punch card, you have to take me to the show.", "order": 37028, "speaker": "Angela Martin"},
  {"season": 7, "episode": 3, "scene": 12, "line": "That is not in the contract.", "order": 37029, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 3, "scene": 12, "line": "Well, there's a lot of gray area in that clause. Do you want to re-mediate?", "order": 37030, "speaker": "Angela Martin"},
  {"season": 7, "episode": 3, "scene": 12, "line": "Alright, fine. I'll go to your little show, but I'm not wearing a cape.", "order": 37031, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 3, "scene": 12, "line": "Thank you.", "order": 37032, "speaker": "Angela Martin"},
  {"season": 7, "episode": 3, "scene": 13, "line": "Dwight and I have a contractual agreement to procreate five times, plain and simple. And should he develop feelings for me, well, that would be permissible under item 7C, clause 2, so I would not object. [faint smile]", "order": 37033, "speaker": "Angela Martin"},
  {"season": 7, "episode": 3, "scene": 14, "line": "Hey, how are those salads?", "order": 37034, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 3, "scene": 14, "line": "It's my own fault-", "order": 37035, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 3, "scene": 14, "line": "My parents used to scramble to find babysitters so they could take my younger brother to do stuff. I understand how hard it can be. I just-tonight, if you could...", "order": 37036, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 3, "scene": 14, "line": "We'll keep looking.", "order": 37037, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 3, "scene": 14, "line": "Yes!", "order": 37038, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 3, "scene": 14, "line": "Really?", "order": 37039, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 3, "scene": 14, "line": "I mean, who knows? Maybe I have a niece my family never told me about.", "order": 37040, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 3, "scene": 14, "line": "Oh my gosh, that would be amazing.", "order": 37041, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 3, "scene": 14, "line": "Yeah.", "order": 37042, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 3, "scene": 14, "line": "For a lot of reasons.", "order": 37043, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 3, "scene": 14, "line": "Yeah, I know. Neiche!", "order": 37044, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 3, "scene": 15, "line": "Oh, hey guys.", "order": 37045, "speaker": "Michael Scott"},
  {"season": 7, "episode": 3, "scene": 15, "line": "You brought balloons to a play?", "order": 37046, "speaker": "Stanley Hudson"},
  {"season": 7, "episode": 3, "scene": 15, "line": "I did, because I am being the bigger man, and balloons are bigger than flowers.", "order": 37047, "speaker": "Michael Scott"},
  {"season": 7, "episode": 3, "scene": 15, "line": "It's nice, like Up.", "order": 37048, "speaker": "Phyllis Vance"},
  {"season": 7, "episode": 3, "scene": 15, "line": "Yes.", "order": 37049, "speaker": "Michael Scott"},
  {"season": 7, "episode": 3, "scene": 15, "line": "Excuse me, are you the guy who did an entire Law & Order episode for his audition?", "order": 37050, "speaker": "Usher"},
  {"season": 7, "episode": 3, "scene": 15, "line": "Nope.", "order": 37051, "speaker": "Michael Scott"},
  {"season": 7, "episode": 3, "scene": 16, "line": "Guys?", "order": 37052, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 3, "scene": 16, "line": "Hey! Andy!", "order": 37053, "speaker": "All"},
  {"season": 7, "episode": 3, "scene": 16, "line": "You all made it, thank you so much.", "order": 37054, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 3, "scene": 16, "line": "You should actually thank Erin, she's the one who agreed to babysit.", "order": 37055, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 3, "scene": 16, "line": "She's babysitting?", "order": 37056, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 3, "scene": 17, "line": "I really wanted to see Andy's play, because he's so, so talented. But I've been trying to get in the babysitting game forever. The thirteen-year-olds in this town have a complete monopoly. It's almost like a babysitters club.", "order": 37057, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 3, "scene": 18, "line": "I understand. This is, like, huge opportunity for her.", "order": 37058, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 3, "scene": 19, "line": "[trying to stuff balloons below the seats] This is ridiculous. You'd think they'd discourage people from bringing in balloons.", "order": 37059, "speaker": "Michael Scott"},
  {"season": 7, "episode": 3, "scene": 19, "line": "Hey, I think this guy playing Sweeney Todd is my plumber.", "order": 37060, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 3, "scene": 19, "line": "No, Darryl. This guy's a world-class actor. He doesn't daylight as your plumber.", "order": 37061, "speaker": "Michael Scott"},
  {"season": 7, "episode": 3, "scene": 19, "line": "No, it's my plumber. Says so in his bio. Apparently the director discovered him doing karaoke. It's his first play. He didn't even audition.", "order": 37062, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 3, "scene": 19, "line": "Are you kidding me?", "order": 37063, "speaker": "Michael Scott"},
  {"season": 7, "episode": 3, "scene": 19, "line": "Shhh. If we don't listen to the overture, we won't recognize the musical themes when they come back later.", "order": 37064, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 3, "scene": 19, "line": "All right, I'm sorry. God.", "order": 37065, "speaker": "Michael Scott"},
  {"season": 7, "episode": 3, "scene": 20, "line": "Excuse me, I think you may be in the wrong seats. [Jim and Pam move a few rows back, next to Michael]", "order": 37066, "speaker": "Usher"},
  {"season": 7, "episode": 3, "scene": 20, "line": "Ooooh!", "order": 37067, "speaker": "Kelly Kapoor"},
  {"season": 7, "episode": 3, "scene": 21, "line": "[whispering to man sitting beside him] I work with that guy.", "order": 37068, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 3, "scene": 21, "line": "[after taking her gum out] Ugh! There's gum on the seat and now it's on my work skirt. I have to go change.", "order": 37069, "speaker": "Angela Martin"},
  {"season": 7, "episode": 3, "scene": 21, "line": "Too bad I'm not gonna explain anything that you miss.", "order": 37070, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 3, "scene": 21, "line": "Oh, Dwight, just move.", "order": 37071, "speaker": "Angela Martin"},
  {"season": 7, "episode": 3, "scene": 21, "line": "[whispering to man sitting beside him] His name's Andy. He's a terrible salesman.", "order": 37072, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 3, "scene": 22, "line": "What time is it? [Ryan pulls up a large analog clock on his iPad]", "order": 37073, "speaker": "Kelly Kapoor"},
  {"season": 7, "episode": 3, "scene": 23, "line": "Michael! Shelby Thomas Weemes, the director.", "order": 37074, "speaker": "Shelby"},
  {"season": 7, "episode": 3, "scene": 23, "line": "Hello.", "order": 37075, "speaker": "Michael Scott"},
  {"season": 7, "episode": 3, "scene": 23, "line": "I promise you that if you keep auditioning with similar gusto...", "order": 37076, "speaker": "Shelby"},
  {"season": 7, "episode": 3, "scene": 23, "line": "Okay.", "order": 37077, "speaker": "Michael Scott"},
  {"season": 7, "episode": 3, "scene": 23, "line": "We are going to find a production with a role for Michael Scott.", "order": 37078, "speaker": "Shelby"},
  {"season": 7, "episode": 3, "scene": 23, "line": "Okay.", "order": 37079, "speaker": "Michael Scott"},
  {"season": 7, "episode": 3, "scene": 23, "line": "Yes?", "order": 37080, "speaker": "Shelby"},
  {"season": 7, "episode": 3, "scene": 23, "line": "Yes.", "order": 37081, "speaker": "Michael Scott"},
  {"season": 7, "episode": 3, "scene": 23, "line": "Good.", "order": 37082, "speaker": "Shelby"},
  {"season": 7, "episode": 3, "scene": 23, "line": "Good.", "order": 37083, "speaker": "Michael Scott"},
  {"season": 7, "episode": 3, "scene": 23, "line": "Enjoy the second act.", "order": 37084, "speaker": "Shelby"},
  {"season": 7, "episode": 3, "scene": 23, "line": "You enjoy the second act.", "order": 37085, "speaker": "Michael Scott"},
  {"season": 7, "episode": 3, "scene": 23, "line": "Have a refreshment.", "order": 37086, "speaker": "Shelby"},
  {"season": 7, "episode": 3, "scene": 23, "line": "Thank you. You, too. [steals a bottle of wine from the bar]", "order": 37087, "speaker": "Michael Scott"},
  {"season": 7, "episode": 3, "scene": 24, "line": "Come on, Dwight.", "order": 37088, "speaker": "Angela Martin"},
  {"season": 7, "episode": 3, "scene": 24, "line": "Why are you dressed like a seed catalogue model?", "order": 37089, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 3, "scene": 24, "line": "These are just my dirty old gardening clothes. They were all that I had in my car.", "order": 37090, "speaker": "Angela Martin"},
  {"season": 7, "episode": 3, "scene": 24, "line": "Let's go.", "order": 37091, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 3, "scene": 25, "line": "Hey, Erin, it's Pam. How ya doing? No, no, don't put Cece on the phone, because she can't talk yet. Okay. No, I was just calling to see how everything's going. Yeah? Yeah? It's good? The play? The play is kind of great. I mean, it's fun to hear Andy sing in the appropriate setting. Now, he's really sorry you couldn't make it, too. Yeah. So thank you so much, again. We're having a great time. Oh, they're flashing the lights so we should go in. Thanks. Bye. Okay, so we called. And everything's fine!", "order": 37092, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 3, "scene": 25, "line": "Everything's fine.", "order": 37093, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 3, "scene": 25, "line": "We can relax.", "order": 37094, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 3, "scene": 25, "line": "We can relax. Let's get our Sweeney on.", "order": 37095, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 3, "scene": 26, "line": "[seeing Michael drink from wine bottle] Michael! [bottle gets passed around by Darryl, Meredith and Kevin]", "order": 37096, "speaker": "Meredith Palmer"},
  {"season": 7, "episode": 3, "scene": 27, "line": "Just checking my e-mails. See if I got any last-minute 'break a legs' or 'I still love you' type texts. Doesn't look like I got anything. Maybe on my Facebook wall.", "order": 37097, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 3, "scene": 28, "line": "[on cell phone] Unfortunately, in this ham-fisted production of Sweeney Todd, the real terror comes from the vocal performances. New paragraph.", "order": 37098, "speaker": "Creed Bratton"},
  {"season": 7, "episode": 3, "scene": 29, "line": "Mr. Todd! Mr. Todd! I found her!", "order": 37099, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 3, "scene": 29, "line": "You found Johanna.", "order": 37100, "speaker": "Sweeney Todd"},
  {"season": 7, "episode": 3, "scene": 29, "line": "That monster of a judge has locked her-[cell phone chiming]. Locked her away. [phone continues ringing]", "order": 37101, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 3, "scene": 29, "line": "There's a little bird fluttering around. Do hope it ceases chirping. [audience laughs]", "order": 37102, "speaker": "Sweeney Todd"},
  {"season": 7, "episode": 3, "scene": 29, "line": "How is that funny?", "order": 37103, "speaker": "Michael Scott"},
  {"season": 7, "episode": 3, "scene": 29, "line": "The bird continues to call. Someone please turn off your... bird. Oh, for the love-turn your phone off! There are signs!", "order": 37104, "speaker": "Sweeney Todd"},
  {"season": 7, "episode": 3, "scene": 29, "line": "Oh, it appears the bird was in mine own pocket this whole time. He's gone to sleep now, I've closed his beak. [Michael laughs]", "order": 37105, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 3, "scene": 29, "line": "What is the news of my darling daughter Johanna?", "order": 37106, "speaker": "Sweeney Todd"},
  {"season": 7, "episode": 3, "scene": 29, "line": "[text message chime] You know what? Let me just double check, that bird... Okay, good. It's off. I mean, silenced. I silenced it by killing it. I've killed it! I'm a murderer! Just like you, Sweeney Todd. See, it all connects. Not that I know you're a murderer. My character doesn't know that yet. But I'm suspicious, because of all the razors that you have laying around. And you spend time alone. But you're a barber, so that's legit. So there's that. But...", "order": 37107, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 3, "scene": 29, "line": "[yelling] Where is Johanna?", "order": 37108, "speaker": "Sweeney Todd"},
  {"season": 7, "episode": 3, "scene": 29, "line": "A madhouse.", "order": 37109, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 3, "scene": 29, "line": "A madhouse?", "order": 37110, "speaker": "Sweeney Todd"},
  {"season": 7, "episode": 3, "scene": 29, "line": "Yes, a madhouse.", "order": 37111, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 3, "scene": 29, "line": "A madhouse? Johanna is as good as rescued. Where do you suppose all the wig makes of London go to obtain their human hair? [Michael tips over wine bottle, which noisily rolls down the theater, and accidentally lets go of balloons]", "order": 37112, "speaker": "Sweeney Todd"},
  {"season": 7, "episode": 3, "scene": 29, "line": "Do you think... [bottle rolling] so you think... [bottle rolling, clanking]", "order": 37113, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 3, "scene": 29, "line": "Fogg's Asylum , why not? [balloon pops, Kelly screams and baby starts crying]", "order": 37114, "speaker": "Sweeney Todd"},
  {"season": 7, "episode": 3, "scene": 29, "line": "Sounds like Cece.", "order": 37115, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 3, "scene": 29, "line": "Okay, I think everybody just needs to relax.", "order": 37116, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 3, "scene": 29, "line": "[sees Erin holding Cece] Oh, my God! Go, go!", "order": 37117, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 3, "scene": 29, "line": "Oh, my God.", "order": 37118, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 3, "scene": 30, "line": "[excited] That's really irresponsible of Erin. She's a terrible babysitter.", "order": 37119, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 3, "scene": 31, "line": "I just didn't think that you guys were gonna see us. We were just gonna stop by and get some ice cream and then go home.", "order": 37120, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 3, "scene": 31, "line": "Okay, this was pretty simple-", "order": 37121, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 3, "scene": 31, "line": "Why are you here?", "order": 37122, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 3, "scene": 31, "line": "[overlapping with Pam] Really, all you had to do was play with her for, like, 30 minutes and then put her to bed.", "order": 37123, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 3, "scene": 31, "line": "What possible explanation could you have for possibly being here? I don't... you know, babies shouldn't have ice cream, by the way.", "order": 37124, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 3, "scene": 31, "line": "I'm sorry. I just really wanted to see Andy. You guys made it sound so unmissable, and you set me up with a car seat and everything.", "order": 37125, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 3, "scene": 31, "line": "Well, the car seat was to take her to the hospital, or...", "order": 37126, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 3, "scene": 31, "line": "Oh, no, why would I take her to the hospital?", "order": 37127, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 3, "scene": 31, "line": "You know what? It doesn't matter. It doesn't matter.", "order": 37128, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 3, "scene": 31, "line": "Why don't I just take her home and let you guys finish, and then you should stay out as late as you want.", "order": 37129, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 3, "scene": 31, "line": "No, we're good. Thank you, though.", "order": 37130, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 3, "scene": 31, "line": "Okay. Alright, bye Cece.", "order": 37131, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 3, "scene": 31, "line": "Okay.", "order": 37132, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 3, "scene": 31, "line": "Alright, well, we're never leaving the house again.", "order": 37133, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 3, "scene": 31, "line": "Not together!", "order": 37134, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 3, "scene": 32, "line": "[during curtain call for Sweeney Todd] Boo! Boo!", "order": 37135, "speaker": "Michael Scott"},
  {"season": 7, "episode": 3, "scene": 33, "line": "That was more horrifying than Nunsense.", "order": 37136, "speaker": "Angela Martin"},
  {"season": 7, "episode": 3, "scene": 33, "line": "All that singing got in the way of some perfectly good murders.", "order": 37137, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 3, "scene": 33, "line": "Where's my car? Come on.", "order": 37138, "speaker": "Angela Martin"},
  {"season": 7, "episode": 3, "scene": 34, "line": "Mmm! Fruit is so much better when it's dried. I've already eaten, like, 30 apricots.", "order": 37139, "speaker": "Michael Scott"},
  {"season": 7, "episode": 3, "scene": 34, "line": "Darryl?", "order": 37140, "speaker": "Sweeney Todd"},
  {"season": 7, "episode": 3, "scene": 34, "line": "Hey!", "order": 37141, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 3, "scene": 34, "line": "Didn't know you were gonna be here. How's the toilet?", "order": 37142, "speaker": "Sweeney Todd"},
  {"season": 7, "episode": 3, "scene": 34, "line": "Silent. Look, congratulations.", "order": 37143, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 3, "scene": 34, "line": "Thank you.", "order": 37144, "speaker": "Sweeney Todd"},
  {"season": 7, "episode": 3, "scene": 34, "line": "I had no idea. This plumber has pipes!", "order": 37145, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 3, "scene": 34, "line": "Yeah, good job. Well done.", "order": 37146, "speaker": "Michael Scott"},
  {"season": 7, "episode": 3, "scene": 34, "line": "You're the guy who booed me.", "order": 37147, "speaker": "Sweeney Todd"},
  {"season": 7, "episode": 3, "scene": 34, "line": "Hmm? No, there were a lot of people booing you. I wasn't one of em.", "order": 37148, "speaker": "Michael Scott"},
  {"season": 7, "episode": 3, "scene": 34, "line": "No, I saw you, and you were the only one.", "order": 37149, "speaker": "Sweeney Todd"},
  {"season": 7, "episode": 3, "scene": 34, "line": "Get your eyes checked, chucklehead.", "order": 37150, "speaker": "Michael Scott"},
  {"season": 7, "episode": 3, "scene": 34, "line": "Be cool, Michael. I saw this guy kill a bunch of people. Good work.", "order": 37151, "speaker": "Creed Bratton"},
  {"season": 7, "episode": 3, "scene": 34, "line": "Thank you.", "order": 37152, "speaker": "Sweeney Todd"},
  {"season": 7, "episode": 3, "scene": 34, "line": "You didn't have to boo him.", "order": 37153, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 3, "scene": 34, "line": "Well, he was getting a lot of applause, and I just didn't think it was indicative of how people were really feeling.", "order": 37154, "speaker": "Michael Scott"},
  {"season": 7, "episode": 3, "scene": 34, "line": "How would you like it if we booed you?", "order": 37155, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 3, "scene": 34, "line": "That would never happen.", "order": 37156, "speaker": "Michael Scott"},
  {"season": 7, "episode": 3, "scene": 34, "line": "Boo! Boo!", "order": 37157, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 3, "scene": 34, "line": "Okay. I appreciate the feedback.", "order": 37158, "speaker": "Michael Scott"},
  {"season": 7, "episode": 3, "scene": 34, "line": "Boo! Boo!", "order": 37159, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 3, "scene": 35, "line": "I don't like that at all.", "order": 37160, "speaker": "Michael Scott"},
  {"season": 7, "episode": 3, "scene": 36, "line": "Are you ready?", "order": 37161, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 3, "scene": 36, "line": "Yes. Oh, I'm so scared! Okay, kill me! Just kill me! Kill me!", "order": 37162, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 3, "scene": 36, "line": "[pretends to slit her throat] Ah! Blood everywhere.", "order": 37163, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 3, "scene": 36, "line": "Oh, no. I'm so glad we're hanging out again outside of work.", "order": 37164, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 3, "scene": 36, "line": "Yeah, me, too.", "order": 37165, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 3, "scene": 36, "line": "Okay, I kill you now.", "order": 37166, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 3, "scene": 36, "line": "Okay.", "order": 37167, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 3, "scene": 37, "line": "All right, let's do this.", "order": 37168, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 3, "scene": 37, "line": "No, actually, Dwight, I didn't realize how far this walk was, and I-I'm exhausted.", "order": 37169, "speaker": "Angela Martin"},
  {"season": 7, "episode": 3, "scene": 37, "line": "It's okay, I smell.", "order": 37170, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 3, "scene": 37, "line": "No, no, no, no. I think I just want to go home, but it's okay. This can count as one of your times.", "order": 37171, "speaker": "Angela Martin"},
  {"season": 7, "episode": 3, "scene": 37, "line": "No, no, no. Contractually we're obligated to have sex.", "order": 37172, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 3, "scene": 37, "line": "Well, I won't tell if you don't.", "order": 37173, "speaker": "Angela Martin"},
  {"season": 7, "episode": 3, "scene": 37, "line": "I will tell. I will tell the mediator. What-what are you... [Angela reaches into his pocket] Ok. Oh, was that in the way? Get rid of it. [Angela pulls out hole-punch and punches the card]", "order": 37174, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 3, "scene": 37, "line": "Good night, Dwight.", "order": 37175, "speaker": "Angela Martin"},
  {"season": 7, "episode": 3, "scene": 37, "line": "Good night.", "order": 37176, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 3, "scene": 38, "line": "These would have been your seats. Best seats in the house. Lots of people think it would be the front row, but actually, right here, this is where the speakers converge, and the sound just, like, nails you right here.", "order": 37177, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 3, "scene": 38, "line": "This is awesome. [cell phone rings] Sorry. Hi, Gabe. Yeah, I just stopped by Andy's cast party to say hi to everyone. Oh, sure, I can pick you up some soup. What do you want? That's broth, Gabe. Okay, I-I'll see you soup. [hangs up] Okay, I have to go. Thank you so much. This is so much fun, and I'm really sorry that I missed your play, but next time I'll be there. Or here. Right here, I promise.", "order": 37178, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 3, "scene": 38, "line": "Awesome.", "order": 37179, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 3, "scene": 38, "line": "Okay.", "order": 37180, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 3, "scene": 38, "line": "See you later.", "order": 37181, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 3, "scene": 38, "line": "Thank you.", "order": 37182, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 3, "scene": 39, "line": "[attempting to move car seat] It's like The Hurt Locker!", "order": 37183, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 3, "scene": 40, "line": "This night was a disaster.", "order": 37184, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 3, "scene": 40, "line": "No, it was not a disaster. It was weird, but it wasn't a disaster. I think we have, like, a gift bottle of Irish cream.", "order": 37185, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 3, "scene": 40, "line": "Yeah?", "order": 37186, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 3, "scene": 40, "line": "We could put that in orange juice.", "order": 37187, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 3, "scene": 40, "line": "Get it.", "order": 37188, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 3, "scene": 40, "line": "Yeah?", "order": 37189, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 3, "scene": 40, "line": "Let's get our juice on.", "order": 37190, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 3, "scene": 40, "line": "Yeah!", "order": 37191, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 3, "scene": 41, "line": "Found him!", "order": 37192, "speaker": "Phyllis Vance"},
  {"season": 7, "episode": 3, "scene": 41, "line": "Hey, what's going on here? Post-show blues?", "order": 37193, "speaker": "Michael Scott"},
  {"season": 7, "episode": 3, "scene": 41, "line": "Yeah, I guess you could say that.", "order": 37194, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 3, "scene": 41, "line": "Yeah, I get those every day after work.", "order": 37195, "speaker": "Michael Scott"},
  {"season": 7, "episode": 3, "scene": 41, "line": "Your show was really great, Andy.", "order": 37196, "speaker": "Phyllis Vance"},
  {"season": 7, "episode": 3, "scene": 41, "line": "Interesting subject matter. I'm surprised you pulled it off.", "order": 37197, "speaker": "Stanley Hudson"},
  {"season": 7, "episode": 3, "scene": 41, "line": "It was like amateur hour.", "order": 37198, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 3, "scene": 41, "line": "It was an amateur production, technically.", "order": 37199, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 3, "scene": 41, "line": "Oscar. Enough with the sass, please. God! What is wrong with you? Andy, listen to me. Look me in the eye. I thought that you were awesome.", "order": 37200, "speaker": "Michael Scott"},
  {"season": 7, "episode": 3, "scene": 41, "line": "Stop just saying that.", "order": 37201, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 3, "scene": 41, "line": "I am not just saying that. You can trust that I am telling you the truth. I booed someone tonight. I have no filter. And if I thought you were terrible, I would have booed. And if I thought you had done a better job, I would be saying nicer things right now. But I thought that you were exactly awesome. No better, no worse.", "order": 37202, "speaker": "Michael Scott"},
  {"season": 7, "episode": 3, "scene": 41, "line": "Thank you. That feels good.", "order": 37203, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 3, "scene": 41, "line": "I did not say that to make you feel good. I just said it. Total integrity.", "order": 37204, "speaker": "Michael Scott"},
  {"season": 7, "episode": 3, "scene": 41, "line": "Andy, sing us another song.", "order": 37205, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 3, "scene": 41, "line": "Thanks, but I feel like a loner right now.", "order": 37206, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 3, "scene": 41, "line": "Oh, come on, Andy, you were the highlight.", "order": 37207, "speaker": "Phyllis Vance"},
  {"season": 7, "episode": 3, "scene": 41, "line": "Come on, Andy, seriously.", "order": 37208, "speaker": "Michael Scott"},
  {"season": 7, "episode": 3, "scene": 41, "line": "Andy! Andy! Andy! Andy! Andy!", "order": 37209, "speaker": "All"},
  {"season": 7, "episode": 3, "scene": 41, "line": "Okay, all right, all right, all right.", "order": 37210, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 3, "scene": 42, "line": "[sings Macy Gray's 'I Try' and everyone joins in]", "order": 37211, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 3, "scene": 43, "line": "In the criminal justice system, the people are defended by two separate but equally important groups, the police who investigate the crimes and the district attorneys who prosecute the offenders. These are their stories. Kun-kun! [imitates vacuum cleaner] I'm just a cleaning lady. Aah! A dead body. He wrapped his belt around his own neck. It looks like a classic case of autoerotic asphyxiation. Yeah, looks like everyone's tightening their belts in this economy. [humming theme music] Last time you saw the victim, was he happy? Last time I saw this John, he was-he wasn't a victim, if you know what I'm talkin about.", "order": 37212, "speaker": "Michael Scott"},
  {"season": 7, "episode": 3, "scene": 43, "line": "Thanks you.", "order": 37213, "speaker": "Shelby"},
  {"season": 7, "episode": 3, "scene": 43, "line": "No, that was- I'm just getting into the first act.", "order": 37214, "speaker": "Michael Scott"},
  {"season": 7, "episode": 4, "scene": 1, "line": "[driving past a line of Mexican men looking for work] Hola, hola. Necesito une bueno worker. Tu esporte! Come on! [man walks away] Que? QUE?", "order": 37215, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 4, "scene": 2, "line": "[speaking Spanish]", "order": 37216, "speaker": "Mexican Man"},
  {"season": 7, "episode": 4, "scene": 2, "line": "We don't go with that man. I've seen several men go with that man and not come back.", "order": 37217, "speaker": "Son"},
  {"season": 7, "episode": 4, "scene": 2, "line": "[speaking Spanish]", "order": 37218, "speaker": "Mexican Man"},
  {"season": 7, "episode": 4, "scene": 2, "line": "We've lost friends.", "order": 37219, "speaker": "Son"},
  {"season": 7, "episode": 4, "scene": 2, "line": "[speaking Spanish]", "order": 37220, "speaker": "Mexican Man"},
  {"season": 7, "episode": 4, "scene": 2, "line": "We don't know what he does with them.", "order": 37221, "speaker": "Son"},
  {"season": 7, "episode": 4, "scene": 2, "line": "[speaking Spanish]", "order": 37222, "speaker": "Mexican Man"},
  {"season": 7, "episode": 4, "scene": 2, "line": "I don't want to talk about it anymore.", "order": 37223, "speaker": "Son"},
  {"season": 7, "episode": 4, "scene": 3, "line": "I pick up day laborers and tell them they'll get paid at six p.m. At five forty five, a certain INS agent by the name of Mose Schrute throws them in the back of a van, drops them off in the middle of Harrisburg and tells them it's Canada.", "order": 37224, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 4, "scene": 4, "line": "Hola amigo.", "order": 37225, "speaker": "Nate Nickerson"},
  {"season": 7, "episode": 4, "scene": 4, "line": "Hola, tu es une buena worker?", "order": 37226, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 4, "scene": 4, "line": "Si, yo muy bueno worker.", "order": 37227, "speaker": "Nate Nickerson"},
  {"season": 7, "episode": 4, "scene": 4, "line": "Y el accento, donde are you from?", "order": 37228, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 4, "scene": 4, "line": "Scranton, y before that La Philadelphia.", "order": 37229, "speaker": "Nate Nickerson"},
  {"season": 7, "episode": 4, "scene": 4, "line": "You speak English?", "order": 37230, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 4, "scene": 4, "line": "Yes, I'm really good at English.", "order": 37231, "speaker": "Nate Nickerson"},
  {"season": 7, "episode": 4, "scene": 4, "line": "Ok, good. Me too, get in the car.", "order": 37232, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 4, "scene": 4, "line": "[quietly] Okay.", "order": 37233, "speaker": "Nate Nickerson"},
  {"season": 7, "episode": 4, "scene": 4, "line": "[nods to camera]", "order": 37234, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 4, "scene": 5, "line": "[seeing the worker Dwight picked up in the parking lot] Who's this guy by our cars?", "order": 37235, "speaker": "Angela Martin"},
  {"season": 7, "episode": 4, "scene": 5, "line": "That is my new maintenance worker, Nate. And you'll be happy to know that he's taking care of that hornet's nest that you've been griping about.", "order": 37236, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 4, "scene": 5, "line": "Yeah, I got stung up my dress.", "order": 37237, "speaker": "Phyllis Vance"},
  {"season": 7, "episode": 4, "scene": 5, "line": "Poor hornet.", "order": 37238, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 4, "scene": 5, "line": "[looking outside through the window] I left him all the tools he needs. This is do or die. If he chooses correctly he'll conquer the hornets...", "order": 37239, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 4, "scene": 5, "line": "But if he doesn't?", "order": 37240, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 4, "scene": 5, "line": "He'll die.", "order": 37241, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 4, "scene": 5, "line": "What?", "order": 37242, "speaker": "Kelly Kapoor"},
  {"season": 7, "episode": 4, "scene": 5, "line": "Uhh, beg your pardon?", "order": 37243, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 4, "scene": 5, "line": "When did the phrase do or die become so corrupted?", "order": 37244, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 4, "scene": 5, "line": "[picks up blowtorch]", "order": 37245, "speaker": "Nate Nickerson"},
  {"season": 7, "episode": 4, "scene": 5, "line": "Is that a blow torch?!", "order": 37246, "speaker": "Kelly Kapoor"},
  {"season": 7, "episode": 4, "scene": 5, "line": "No. No no no! [nos coming from all employees]", "order": 37247, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 4, "scene": 5, "line": "Interesting choice...", "order": 37248, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 4, "scene": 5, "line": "[Nate starts walking away from hornet's nest, puts down the blowtorch] Yes!", "order": 37249, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 4, "scene": 5, "line": "Very very smart.", "order": 37250, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 4, "scene": 5, "line": "Yeah, go away. [Kelly nodding]", "order": 37251, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 4, "scene": 5, "line": "[picks up baseball bat, heads towards hornet's nest]", "order": 37252, "speaker": "Nate Nickerson"},
  {"season": 7, "episode": 4, "scene": 5, "line": "No! No no!", "order": 37253, "speaker": "Employees except Dwight Schrute"},
  {"season": 7, "episode": 4, "scene": 5, "line": "A bat! Impressive...", "order": 37254, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 4, "scene": 5, "line": "Oh it's stinging him! Ow! Ow! [yelling all around the office]", "order": 37255, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 4, "scene": 6, "line": "[walks into the office with a large, fake mustache on] Good morning Erin, any mustaches? I mean messages?", "order": 37256, "speaker": "Michael Scott"},
  {"season": 7, "episode": 4, "scene": 6, "line": "[giggles] Terrific!", "order": 37257, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 4, "scene": 7, "line": "There are many reasons a man would wear a fake mustache to work. [spinning in chair] He is a fan of the outrageous. He loves to surprise! He loves... other things as well.", "order": 37258, "speaker": "Michael Scott"},
  {"season": 7, "episode": 4, "scene": 8, "line": "[seeing a large red spot on Michael's lip] God! Wow!", "order": 37259, "speaker": "Phyllis Vance"},
  {"season": 7, "episode": 4, "scene": 8, "line": "[shying away] Look, [sighs] It's a pimple Phyllis. Avrile Lavigne gets them all the time and she rocks harder than anyone alive.", "order": 37260, "speaker": "Michael Scott"},
  {"season": 7, "episode": 4, "scene": 8, "line": "That's no pimple Michael.", "order": 37261, "speaker": "Phyllis Vance"},
  {"season": 7, "episode": 4, "scene": 8, "line": "You mean cancer?", "order": 37262, "speaker": "Michael Scott"},
  {"season": 7, "episode": 4, "scene": 8, "line": "What? No! Wait, no. Definitely not cancer.", "order": 37263, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 4, "scene": 9, "line": "It's just good to stop a Michael train of thought early before it derails and destroys the entire town.", "order": 37264, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 4, "scene": 10, "line": "Wait, wait, wait, wait, wait. It's just a cold sore.", "order": 37265, "speaker": "Meredith Palmer"},
  {"season": 7, "episode": 4, "scene": 10, "line": "It is?", "order": 37266, "speaker": "Michael Scott"},
  {"season": 7, "episode": 4, "scene": 10, "line": "Yup, just a cold sore.", "order": 37267, "speaker": "Meredith Palmer"},
  {"season": 7, "episode": 4, "scene": 10, "line": "Just a cold sore, thank you. Phyllis, I don't have acne. I have a cold sore. I don't even have a cold. I don't know how I got it.", "order": 37268, "speaker": "Michael Scott"},
  {"season": 7, "episode": 4, "scene": 10, "line": "I know how you got it. [smug expression on his face]", "order": 37269, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 4, "scene": 10, "line": "How?", "order": 37270, "speaker": "Michael Scott"},
  {"season": 7, "episode": 4, "scene": 10, "line": "Michael come on. A cold sore is herpes.", "order": 37271, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 4, "scene": 10, "line": "What?", "order": 37272, "speaker": "Michael Scott"},
  {"season": 7, "episode": 4, "scene": 10, "line": "Wait! What you should do, Michael, is have a doctor take a look at it. Because we really don't know what that is.", "order": 37273, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 4, "scene": 10, "line": "I know tons and tons of people who have herpes. I have it myself. That's what it is.", "order": 37274, "speaker": "Meredith Palmer"},
  {"season": 7, "episode": 4, "scene": 10, "line": "I never seen herpes on you.", "order": 37275, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 4, "scene": 10, "line": "Because it's on my genitals, genius.", "order": 37276, "speaker": "Meredith Palmer"},
  {"season": 7, "episode": 4, "scene": 10, "line": "You have a penis?", "order": 37277, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 4, "scene": 10, "line": "Michael, have you ever been tested for STDs?", "order": 37278, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 4, "scene": 10, "line": "Yeah! My last physical when I was forty.", "order": 37279, "speaker": "Michael Scott"},
  {"season": 7, "episode": 4, "scene": 10, "line": "That was like, ten years ago.", "order": 37280, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 4, "scene": 10, "line": "No! It was like four years ago!", "order": 37281, "speaker": "Michael Scott"},
  {"season": 7, "episode": 4, "scene": 10, "line": "Michael, you're at least forty six!", "order": 37282, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 4, "scene": 10, "line": "Why at least? If you're guessing forty six just say forty six. [later in his office, places a band-aid over his cold sore]", "order": 37283, "speaker": "Michael Scott"},
  {"season": 7, "episode": 4, "scene": 11, "line": "Can we please talk about how gross Meredith is?", "order": 37284, "speaker": "Kelly Kapoor"},
  {"season": 7, "episode": 4, "scene": 11, "line": "This is what you get when you treat your body like an outhouse.", "order": 37285, "speaker": "Angela Martin"},
  {"season": 7, "episode": 4, "scene": 11, "line": "You know what guys, why don't we just chill out on this herp-chat. Ok? I was an REA in college and I can tell you, number one sexually transmitted disease is ignorance.", "order": 37286, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 4, "scene": 11, "line": "I guess maybe you should go marry a meth dealer with crabs.", "order": 37287, "speaker": "Kelly Kapoor"},
  {"season": 7, "episode": 4, "scene": 11, "line": "I don't even wanna know these things!", "order": 37288, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 4, "scene": 11, "line": "[walking in] Hey guys! [Angela leaves the table, exhales as she exits the room]", "order": 37289, "speaker": "Meredith Palmer"},
  {"season": 7, "episode": 4, "scene": 12, "line": "How did this happen, how did I get this?", "order": 37290, "speaker": "Michael Scott"},
  {"season": 7, "episode": 4, "scene": 12, "line": "Some eggs can rely dormant in a woman for years, they may not even know they have it.", "order": 37291, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 4, "scene": 12, "line": "You know what, I feel like one of those old timey sailors. With the eye patch. [in pirate voice] It's me own damn fault. Woman in every port.", "order": 37292, "speaker": "Michael Scott"},
  {"season": 7, "episode": 4, "scene": 12, "line": "What port? The Jan port. The Holly Por...", "order": 37293, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 4, "scene": 12, "line": "Don't even! Holly was clean! Okay? If anything I gave it to her.", "order": 37294, "speaker": "Michael Scott"},
  {"season": 7, "episode": 4, "scene": 12, "line": "You may have...", "order": 37295, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 4, "scene": 12, "line": "Oh my God! What if I did?", "order": 37296, "speaker": "Michael Scott"},
  {"season": 7, "episode": 4, "scene": 12, "line": "You need to contact Holly! You need to notify her, that she is crawling with herpes.", "order": 37297, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 4, "scene": 12, "line": "Okay! You know what? Might! Might be crawling with herpes. I might have gotten it after her.", "order": 37298, "speaker": "Michael Scott"},
  {"season": 7, "episode": 4, "scene": 12, "line": "You need to contact every woman you've been with, and notify them of your herpes infestation. It's the right thing to do.", "order": 37299, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 4, "scene": 12, "line": "There's no way I'm gonna do that.", "order": 37300, "speaker": "Michael Scott"},
  {"season": 7, "episode": 4, "scene": 12, "line": "Then I will.", "order": 37301, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 4, "scene": 12, "line": "[makes grunt in attempt to stop Dwight. Dwight hangs up]", "order": 37302, "speaker": "Michael Scott"},
  {"season": 7, "episode": 4, "scene": 13, "line": "No, I'm no doctor, but it seems to me that we all have an obligation to the public health to track down anyone who gives us a disease, inform them of it, and take overwhelming revenge on that person. Again, I'm no doctor. I'm just a normal guy who enjoys revenge.", "order": 37303, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 4, "scene": 14, "line": "[on phone] Hello?", "order": 37304, "speaker": "Donna Newton"},
  {"season": 7, "episode": 4, "scene": 14, "line": "Hi Donna. It's Michael.", "order": 37305, "speaker": "Michael Scott"},
  {"season": 7, "episode": 4, "scene": 14, "line": "Michael, I didn't think I'd here from you. How have you been?", "order": 37306, "speaker": "Donna Newton"},
  {"season": 7, "episode": 4, "scene": 14, "line": "I have a disease, for which there is no known cure, that has been sexually transmitted to me.", "order": 37307, "speaker": "Michael Scott"},
  {"season": 7, "episode": 4, "scene": 14, "line": "Oh no.", "order": 37308, "speaker": "Donna Newton"},
  {"season": 7, "episode": 4, "scene": 14, "line": "I can't even say it. H-I...", "order": 37309, "speaker": "Michael Scott"},
  {"season": 7, "episode": 4, "scene": 14, "line": "Oh my God.", "order": 37310, "speaker": "Donna Newton"},
  {"season": 7, "episode": 4, "scene": 14, "line": "...R-P-E-E-S", "order": 37311, "speaker": "Michael Scott"},
  {"season": 7, "episode": 4, "scene": 14, "line": "Wait, you're calling to tell me that you have herpes?", "order": 37312, "speaker": "Donna Newton"},
  {"season": 7, "episode": 4, "scene": 14, "line": "No, I am calling to see if you gave me herpes. Because if you did I would be able to avoid a lot of sucky conversations. So you have it right?", "order": 37313, "speaker": "Michael Scott"},
  {"season": 7, "episode": 4, "scene": 14, "line": "Ummm, no.", "order": 37314, "speaker": "Donna Newton"},
  {"season": 7, "episode": 4, "scene": 14, "line": "Does your stupid husband have it?", "order": 37315, "speaker": "Michael Scott"},
  {"season": 7, "episode": 4, "scene": 14, "line": "No! He doesn't. Are you telling me I have to get tested?", "order": 37316, "speaker": "Donna Newton"},
  {"season": 7, "episode": 4, "scene": 14, "line": "Yes I am telling you you have to get tested for herpes. Good-bye!", "order": 37317, "speaker": "Michael Scott"},
  {"season": 7, "episode": 4, "scene": 14, "line": "So long Donna! [Michael hangs up]", "order": 37318, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 4, "scene": 15, "line": "Excuse me everyone, can I have your attention please?", "order": 37319, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 4, "scene": 15, "line": "Not again...", "order": 37320, "speaker": "Stanley Hudson"},
  {"season": 7, "episode": 4, "scene": 15, "line": "What do you mean again?", "order": 37321, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 4, "scene": 15, "line": "You're always asking for our attention.", "order": 37322, "speaker": "Stanley Hudson"},
  {"season": 7, "episode": 4, "scene": 15, "line": "Maybe like a year ago...", "order": 37323, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 4, "scene": 15, "line": "Seems recent.", "order": 37324, "speaker": "Stanley Hudson"},
  {"season": 7, "episode": 4, "scene": 15, "line": "No, that's...", "order": 37325, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 4, "scene": 15, "line": "Andy, the reason it seems more recent is because many of us here have never stood up and asked for everyone's attention, and it seems like you've done it on several occasions.", "order": 37326, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 4, "scene": 15, "line": "Everyone, I've noticed that we have not been entirely kind to one of our own, due to stigmas and prejudices.", "order": 37327, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 4, "scene": 15, "line": "Oh! When you got your new phone, that's when you asked for everyone's attention.", "order": 37328, "speaker": "Phyllis Vance"},
  {"season": 7, "episode": 4, "scene": 15, "line": "[agreement around the office] That's what I was thinking, you kept announcing scores.", "order": 37329, "speaker": "Stanley Hudson"},
  {"season": 7, "episode": 4, "scene": 15, "line": "It's the world's only international sport! [sits down]", "order": 37330, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 4, "scene": 16, "line": "[on phone] This is Holly.", "order": 37331, "speaker": "Holly Flax"},
  {"season": 7, "episode": 4, "scene": 16, "line": "No this is Holly.", "order": 37332, "speaker": "Michael Scott"},
  {"season": 7, "episode": 4, "scene": 16, "line": "No this is Holly.", "order": 37333, "speaker": "Holly Flax"},
  {"season": 7, "episode": 4, "scene": 16, "line": "No this is Holly.", "order": 37334, "speaker": "Michael Scott"},
  {"season": 7, "episode": 4, "scene": 16, "line": "No, this is Michael Scott.", "order": 37335, "speaker": "Holly Flax"},
  {"season": 7, "episode": 4, "scene": 16, "line": "Busted. So what can I do for you Holly.", "order": 37336, "speaker": "Michael Scott"},
  {"season": 7, "episode": 4, "scene": 16, "line": "I am calling because, there's a terrible crash!", "order": 37337, "speaker": "Holly Flax"},
  {"season": 7, "episode": 4, "scene": 16, "line": "Oh really? Was anyone killed?", "order": 37338, "speaker": "Michael Scott"},
  {"season": 7, "episode": 4, "scene": 16, "line": "A lot of people.", "order": 37339, "speaker": "Holly Flax"},
  {"season": 7, "episode": 4, "scene": 16, "line": "Any nuns?", "order": 37340, "speaker": "Michael Scott"},
  {"season": 7, "episode": 4, "scene": 16, "line": "Three nuns, [Michael laughs] from a Missionary in South Africa.", "order": 37341, "speaker": "Holly Flax"},
  {"season": 7, "episode": 4, "scene": 16, "line": "[Dwight looks confused to the camera] Were they in the missionary position? [Holly laughs]", "order": 37342, "speaker": "Michael Scott"},
  {"season": 7, "episode": 4, "scene": 17, "line": "[singing] Ba na na na na. Hot pizza, check it and see. Ba na na na. Got a whole bunch of pepperoni. Ba na na na na. [stops singing] Yeah, now you're looking at me. Anyone who's interested in entering into an honest discussion about the sexual mores and taboos of modern society will be rewarded with a pizza break.", "order": 37343, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 4, "scene": 18, "line": "[still on phone with Holly] Do you ever wonder what life would have been like if you hadn't been transferred?", "order": 37344, "speaker": "Michael Scott"},
  {"season": 7, "episode": 4, "scene": 18, "line": "Yeah.", "order": 37345, "speaker": "Holly Flax"},
  {"season": 7, "episode": 4, "scene": 18, "line": "We would have twins. [Dwight points towards the corner of his lip, indicating Michael's 'herpes']", "order": 37346, "speaker": "Michael Scott"},
  {"season": 7, "episode": 4, "scene": 18, "line": "I don't think we'd have kids.", "order": 37347, "speaker": "Holly Flax"},
  {"season": 7, "episode": 4, "scene": 18, "line": "Mmhmm!", "order": 37348, "speaker": "Michael Scott"},
  {"season": 7, "episode": 4, "scene": 18, "line": "It was just for a few weeks!", "order": 37349, "speaker": "Holly Flax"},
  {"season": 7, "episode": 4, "scene": 18, "line": "Mhhmm! We would be married.", "order": 37350, "speaker": "Michael Scott"},
  {"season": 7, "episode": 4, "scene": 18, "line": "[sternly] Michael, I have been dating A.J. for a year and a half now. You do this you know.", "order": 37351, "speaker": "Holly Flax"},
  {"season": 7, "episode": 4, "scene": 18, "line": "Do what?", "order": 37352, "speaker": "Michael Scott"},
  {"season": 7, "episode": 4, "scene": 18, "line": "You romanticize things.", "order": 37353, "speaker": "Holly Flax"},
  {"season": 7, "episode": 4, "scene": 18, "line": "I don't romanticize th- [Dwight nodding and mouthing 'Yes you do'] No...", "order": 37354, "speaker": "Michael Scott"},
  {"season": 7, "episode": 4, "scene": 18, "line": "Michael, you cried at that tagline for a movie you made up.", "order": 37355, "speaker": "Holly Flax"},
  {"season": 7, "episode": 4, "scene": 18, "line": "He had no arms or legs, he couldn't hear see, or speak... This is how he let a nation.", "order": 37356, "speaker": "Michael Scott"},
  {"season": 7, "episode": 4, "scene": 18, "line": "You made ourselves to be more than we were.", "order": 37357, "speaker": "Holly Flax"},
  {"season": 7, "episode": 4, "scene": 18, "line": "We were more than we were.", "order": 37358, "speaker": "Michael Scott"},
  {"season": 7, "episode": 4, "scene": 18, "line": "I don't know what you're getting so upset about, we had to break up a long time ago, it was a good memory.", "order": 37359, "speaker": "Holly Flax"},
  {"season": 7, "episode": 4, "scene": 18, "line": "Ok, alright I gotta go.", "order": 37360, "speaker": "Michael Scott"},
  {"season": 7, "episode": 4, "scene": 18, "line": "Michael... [Michael hangs up]", "order": 37361, "speaker": "Holly Flax"},
  {"season": 7, "episode": 4, "scene": 18, "line": "[to the camera] He forgot to mention the herpes.", "order": 37362, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 4, "scene": 18, "line": "It didn't come up organically.", "order": 37363, "speaker": "Michael Scott"},
  {"season": 7, "episode": 4, "scene": 19, "line": "[to a client] ...to accommodate the, uh, suppliers. [Seeing Michael and Dwight] Gentlemen! Nice to see you, it'll be just a moment. If you could show them into Conference Room B. [to client again] The pharmaceutical aspects should be able to...", "order": 37364, "speaker": "Jan Levinson"},
  {"season": 7, "episode": 4, "scene": 20, "line": "How do I do it? Raise my daughter, work as director of office purchasing for this hospital and release an album of Dorris Day covers on my own label? If I knew I'd tell you.", "order": 37365, "speaker": "Jan Levinson"},
  {"season": 7, "episode": 4, "scene": 21, "line": "It's nice to see you doing so well.", "order": 37366, "speaker": "Michael Scott"},
  {"season": 7, "episode": 4, "scene": 21, "line": "Yeah I'm really happy. Me and Astrid against the world. [laughs] We're loving it, yeah. I'm kind of a supermom.", "order": 37367, "speaker": "Jan Levinson"},
  {"season": 7, "episode": 4, "scene": 21, "line": "Bringing home the bacon.", "order": 37368, "speaker": "Michael Scott"},
  {"season": 7, "episode": 4, "scene": 21, "line": "Yeah. [singing] Fry it up in a pan. Never never never let you forget... [laughs] I love that commercial.", "order": 37369, "speaker": "Jan Levinson"},
  {"season": 7, "episode": 4, "scene": 21, "line": "I don't understand the reference.", "order": 37370, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 4, "scene": 21, "line": "Well, Michael, it was nice to get your call.", "order": 37371, "speaker": "Jan Levinson"},
  {"season": 7, "episode": 4, "scene": 21, "line": "We had to come over right away, it's urgent. Michael has something to tell you. [Michael shushes him]", "order": 37372, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 4, "scene": 21, "line": "Are you gonna keep me in suspense? [Michael sighs]", "order": 37373, "speaker": "Jan Levinson"},
  {"season": 7, "episode": 4, "scene": 21, "line": "[after long wait, to Dwight] Would you excuse us...", "order": 37374, "speaker": "Michael Scott"},
  {"season": 7, "episode": 4, "scene": 21, "line": "Ok I'm gonna head outta here, I know you have a lot to talk about. Ok, is there a, an operating theater that's open to visitors? Never mind I'll find it.", "order": 37375, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 4, "scene": 21, "line": "He hasn't changed.", "order": 37376, "speaker": "Michael Scott"},
  {"season": 7, "episode": 4, "scene": 21, "line": "No, so what couldn't wait?", "order": 37377, "speaker": "Jan Levinson"},
  {"season": 7, "episode": 4, "scene": 21, "line": "Am I the kind of person to misremember our relationship as more than it ever was?", "order": 37378, "speaker": "Michael Scott"},
  {"season": 7, "episode": 4, "scene": 21, "line": "Michael Scott, you are here for a post mortem.", "order": 37379, "speaker": "Jan Levinson"},
  {"season": 7, "episode": 4, "scene": 21, "line": "What?", "order": 37380, "speaker": "Michael Scott"},
  {"season": 7, "episode": 4, "scene": 21, "line": "You wanna dig into our relationship, go over it, see what killed it. Ok, I'm in.", "order": 37381, "speaker": "Jan Levinson"},
  {"season": 7, "episode": 4, "scene": 22, "line": "So, guys. I've been really bothered by the way certain people are getting treated around here, and I just think as an office we're better than that. Ok, now I'm going to show you a picture of genitalia. [grossed our remarks from around the office as Andy holds p a picture of a nude man]", "order": 37382, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 4, "scene": 22, "line": "Andy...", "order": 37383, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 4, "scene": 22, "line": "What, is it because he's black?", "order": 37384, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 4, "scene": 22, "line": "Nope, it's because it's genitalia.", "order": 37385, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 4, "scene": 22, "line": "Perfectly normal genitalia Tuna. Now I'm going to show you another picture of perfectly normal genitalia. [Kevin is grossed out immediately. The office is grossed out]", "order": 37386, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 4, "scene": 22, "line": "Am I blocking anybody? Can everybody see?", "order": 37387, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 4, "scene": 22, "line": "It's normal! Big deal! Yeah sure it has some herpes on it, but you know what? It's just as normal as anyone else's.", "order": 37388, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 4, "scene": 23, "line": "No! In the beginning we were not good.", "order": 37389, "speaker": "Jan Levinson"},
  {"season": 7, "episode": 4, "scene": 23, "line": "Well, there was a little bit of a learning curve in the conversation department, but between the sheets we were like Jordan and Pippin!", "order": 37390, "speaker": "Michael Scott"},
  {"season": 7, "episode": 4, "scene": 23, "line": "Well if there's anything exciting about it it's because we both knew it was wrong!", "order": 37391, "speaker": "Jan Levinson"},
  {"season": 7, "episode": 4, "scene": 23, "line": "Because we work together.", "order": 37392, "speaker": "Michael Scott"},
  {"season": 7, "episode": 4, "scene": 23, "line": "No, ok. Imagine there's a princess, who falls for a guy beneath her station, and the queen doesn't like this at all. And the princess knows that the queen doesn't like it so it just makes her wanna do it all the more just to get at the queen!", "order": 37393, "speaker": "Jan Levinson"},
  {"season": 7, "episode": 4, "scene": 23, "line": "Am I the princess?", "order": 37394, "speaker": "Michael Scott"},
  {"season": 7, "episode": 4, "scene": 23, "line": "No I'm the princess, and the queen.", "order": 37395, "speaker": "Jan Levinson"},
  {"season": 7, "episode": 4, "scene": 23, "line": "[sighs] Ok, so I'm the guy at the station.", "order": 37396, "speaker": "Michael Scott"},
  {"season": 7, "episode": 4, "scene": 23, "line": "Mommy!", "order": 37397, "speaker": "Astrid"},
  {"season": 7, "episode": 4, "scene": 23, "line": "Assy! Awwhhh how was school?", "order": 37398, "speaker": "Jan Levinson"},
  {"season": 7, "episode": 4, "scene": 23, "line": "It was cool.", "order": 37399, "speaker": "Astrid"},
  {"season": 7, "episode": 4, "scene": 23, "line": "[singing] What did you learn?", "order": 37400, "speaker": "Jan Levinson"},
  {"season": 7, "episode": 4, "scene": 23, "line": "What did I learn?", "order": 37401, "speaker": "Astrid"},
  {"season": 7, "episode": 4, "scene": 23, "line": "You might have learned shapes, or blocks, or clocks, or colors. Or you might have learned that we're all, sisters and brothers.", "order": 37402, "speaker": "Jan Levinson"},
  {"season": 7, "episode": 4, "scene": 23, "line": "I have herpes.", "order": 37403, "speaker": "Michael Scott"},
  {"season": 7, "episode": 4, "scene": 24, "line": "I used to think that she was the one. Or at least A the one. And if I called that one so wrong...", "order": 37404, "speaker": "Michael Scott"},
  {"season": 7, "episode": 4, "scene": 25, "line": "Herpes, like all STDS, is a consequence of sex. Can anyone else name any other consequences?", "order": 37405, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 4, "scene": 25, "line": "It feels unbelievable!", "order": 37406, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 4, "scene": 25, "line": "[writing on board] Ok I guess I can make a pros column. Feels...", "order": 37407, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 4, "scene": 25, "line": "...unbelievable.", "order": 37408, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 4, "scene": 25, "line": "The ability to express love physically. It's a magical thing.", "order": 37409, "speaker": "Phyllis Vance"},
  {"season": 7, "episode": 4, "scene": 25, "line": "Express love, magical.", "order": 37410, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 4, "scene": 25, "line": "It feels amazing!", "order": 37411, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 4, "scene": 25, "line": "Umm, ok is that different from feels unbelievable?", "order": 37412, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 4, "scene": 25, "line": "Yes.", "order": 37413, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 4, "scene": 25, "line": "Then I will write it down.", "order": 37414, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 4, "scene": 25, "line": "The feeling of pure risk.", "order": 37415, "speaker": "Creed Bratton"},
  {"season": 7, "episode": 4, "scene": 25, "line": "I actually had that down in the cons column, but...", "order": 37416, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 4, "scene": 25, "line": "It's thrilling.", "order": 37417, "speaker": "Creed Bratton"},
  {"season": 7, "episode": 4, "scene": 25, "line": "Ok, umm. I'll move that. Thrill of risk.", "order": 37418, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 4, "scene": 25, "line": "Andy, aren't there also negatives to sex?", "order": 37419, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 4, "scene": 25, "line": "Yes! Thank you! Such as?", "order": 37420, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 4, "scene": 25, "line": "Unplanned pregnancy.", "order": 37421, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 4, "scene": 25, "line": "Yes, unplanned pregnancy.", "order": 37422, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 4, "scene": 25, "line": "Like Jim and Pam, say whaaat?", "order": 37423, "speaker": "Kelly Kapoor"},
  {"season": 7, "episode": 4, "scene": 25, "line": "Just admit that your baby was a mistake.", "order": 37424, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 4, "scene": 25, "line": "Hey! Our baby was not a mistake. She was a surprise.", "order": 37425, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 4, "scene": 25, "line": "Good!", "order": 37426, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 4, "scene": 25, "line": "I'm sure they don't regret having their child, let's move it to the pros.", "order": 37427, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 4, "scene": 25, "line": "Thank you!", "order": 37428, "speaker": "Jim Halpert & Pam Halpert"},
  {"season": 7, "episode": 4, "scene": 25, "line": "Ok, unplanned pregnancy, going in the pros column.", "order": 37429, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 4, "scene": 26, "line": "Next stop is Helene. You're gonna wanna make a left on Willow, which is a little ways away, uh. I'll remind you.", "order": 37430, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 4, "scene": 26, "line": "You know, I don't know if I trust Jan's judgment. She...", "order": 37431, "speaker": "Michael Scott"},
  {"season": 7, "episode": 4, "scene": 26, "line": "Jan knows paper.", "order": 37432, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 4, "scene": 26, "line": "No, I'm not talking about paper. Relationships, they have the ability to point out if the man is making a bigger deal out of something that is really there.", "order": 37433, "speaker": "Michael Scott"},
  {"season": 7, "episode": 4, "scene": 26, "line": "Wait, what Holly said?", "order": 37434, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 4, "scene": 26, "line": "Yes, I don't...", "order": 37435, "speaker": "Michael Scott"},
  {"season": 7, "episode": 4, "scene": 26, "line": "Forget it Michael! Today is about herpes.", "order": 37436, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 4, "scene": 26, "line": "I know, I know.", "order": 37437, "speaker": "Michael Scott"},
  {"season": 7, "episode": 4, "scene": 26, "line": "Who gave it to you, who has it, and who is going to pay.", "order": 37438, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 4, "scene": 26, "line": "Got it.", "order": 37439, "speaker": "Michael Scott"},
  {"season": 7, "episode": 4, "scene": 26, "line": "TO answer your question about Jan, no. Jan is insane. Why do you think I got- LEFT, WILLOW NOW!", "order": 37440, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 4, "scene": 26, "line": "I'M TRYING! GOD!", "order": 37441, "speaker": "Michael Scott"},
  {"season": 7, "episode": 4, "scene": 27, "line": "[walks into park with Dwight] Hi stranger! Long time!", "order": 37442, "speaker": "Michael Scott"},
  {"season": 7, "episode": 4, "scene": 27, "line": "Who are you?", "order": 37443, "speaker": "Old Woman"},
  {"season": 7, "episode": 4, "scene": 27, "line": "I'm Michael. We dated for a while.", "order": 37444, "speaker": "Michael Scott"},
  {"season": 7, "episode": 4, "scene": 27, "line": "I don't think so...", "order": 37445, "speaker": "Old Woman"},
  {"season": 7, "episode": 4, "scene": 27, "line": "Michael?", "order": 37446, "speaker": "Helene Beesly"},
  {"season": 7, "episode": 4, "scene": 27, "line": "Oh hey! Dwight, would you take my grandmother for a walk while I talk to Helene?", "order": 37447, "speaker": "Michael Scott"},
  {"season": 7, "episode": 4, "scene": 27, "line": "Come on old lady. Let's go.", "order": 37448, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 4, "scene": 27, "line": "What is happening? [Dwight shushes her]", "order": 37449, "speaker": "Old Woman"},
  {"season": 7, "episode": 4, "scene": 27, "line": "Come on.", "order": 37450, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 4, "scene": 28, "line": "Who can tell me what the safest form of sex is?", "order": 37451, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 4, "scene": 28, "line": "Condoms.", "order": 37452, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 4, "scene": 28, "line": "Incorrect, the only true form of safe sex, ok? Abstinence.", "order": 37453, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 4, "scene": 28, "line": "Ohh. Ok. I didn't realize we were doing trick questions. What's the safest way to go skiing? Don't ski! [office laughs]", "order": 37454, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 4, "scene": 28, "line": "I just thought I'd bring it up in case someone in here was practicing abstinence. That's all. Anybody?", "order": 37455, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 4, "scene": 28, "line": "Andy that's way too personal of a question.", "order": 37456, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 4, "scene": 28, "line": "Well someone could answer if they want to. [looks at Erin hopefully] Ok, in that case I will now show you how to put this condom on... using this pencil. [Stanley laughs]", "order": 37457, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 4, "scene": 28, "line": "What?", "order": 37458, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 4, "scene": 28, "line": "Why would you choose a pencil Andy?", "order": 37459, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 4, "scene": 28, "line": "Well I'm not gonna use my penis, Oscar! It's not exactly hard right now anyway.", "order": 37460, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 4, "scene": 28, "line": "Come on, give it a rest pencil dick.", "order": 37461, "speaker": "Meredith Palmer"},
  {"season": 7, "episode": 4, "scene": 28, "line": "I'm doing this for you Meredith!", "order": 37462, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 4, "scene": 28, "line": "I didn't want you it!", "order": 37463, "speaker": "Meredith Palmer"},
  {"season": 7, "episode": 4, "scene": 28, "line": "Well did you h- di- GAH! Does no one appreciate what I'm doing right now?! [throws pizza box at painting and leaves]", "order": 37464, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 4, "scene": 29, "line": "It'll go away in time just don't touch it.", "order": 37465, "speaker": "Helene Beesly"},
  {"season": 7, "episode": 4, "scene": 29, "line": "Did I make more of what we had then what was really there?", "order": 37466, "speaker": "Michael Scott"},
  {"season": 7, "episode": 4, "scene": 29, "line": "What did you think we were?", "order": 37467, "speaker": "Helene Beesly"},
  {"season": 7, "episode": 4, "scene": 29, "line": "Just a quirky indie movie weird sort of thing, breaking all the rules. But had to end, because the summer was over. For you...", "order": 37468, "speaker": "Michael Scott"},
  {"season": 7, "episode": 4, "scene": 29, "line": "I think for you to have come here even expecting that we can have a conversation like this show's ho-how self deluded you are! Michael, your memory has failed you greatly.", "order": 37469, "speaker": "Helene Beesly"},
  {"season": 7, "episode": 4, "scene": 29, "line": "Jerk. [walks off]", "order": 37470, "speaker": "Michael Scott"},
  {"season": 7, "episode": 4, "scene": 30, "line": "Michael! [attempting to get out of the car on the side in which Michael parked too close to a bush. Michael ignores him and walks into a building.]", "order": 37471, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 4, "scene": 30, "line": "So this is the chef's kitchen, which makes it perfect for real entertainers.", "order": 37472, "speaker": "Carroll"},
  {"season": 7, "episode": 4, "scene": 30, "line": "Real entertainers, like Billy Joel.", "order": 37473, "speaker": "Michael Scott"},
  {"season": 7, "episode": 4, "scene": 30, "line": "Michael.", "order": 37474, "speaker": "Carroll"},
  {"season": 7, "episode": 4, "scene": 30, "line": "Carroll, how are you?", "order": 37475, "speaker": "Michael Scott"},
  {"season": 7, "episode": 4, "scene": 30, "line": "I'm great! How are you?", "order": 37476, "speaker": "Carroll"},
  {"season": 7, "episode": 4, "scene": 30, "line": "I'm great! I saw your-your sign outside. And I decided, I'm going to pull in, and maybe buy a house from her.", "order": 37477, "speaker": "Michael Scott"},
  {"season": 7, "episode": 4, "scene": 30, "line": "You didn't call my office and ask where I was?", "order": 37478, "speaker": "Carroll"},
  {"season": 7, "episode": 4, "scene": 30, "line": "No.", "order": 37479, "speaker": "Michael Scott"},
  {"season": 7, "episode": 4, "scene": 30, "line": "Because the receptionist told me a man called but he only said he was my ex-lover.", "order": 37480, "speaker": "Carroll"},
  {"season": 7, "episode": 4, "scene": 30, "line": "Weird...", "order": 37481, "speaker": "Michael Scott"},
  {"season": 7, "episode": 4, "scene": 30, "line": "Yeah.", "order": 37482, "speaker": "Carroll"},
  {"season": 7, "episode": 4, "scene": 30, "line": "He sounds like a nice guy.", "order": 37483, "speaker": "Michael Scott"},
  {"season": 7, "episode": 4, "scene": 31, "line": "I don't know, I mean people were being really mean to Meredith.", "order": 37484, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 4, "scene": 31, "line": "This wasn't really about Meredith was it. You and Erin are broken up.", "order": 37485, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 4, "scene": 31, "line": "How is that relevant to anything?", "order": 37486, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 4, "scene": 31, "line": "I asked you if it was ok if I asked her out. You said, and I quote, 'My good sir! Nothing would make me happier than to hand you the hand of the hand once in my hand.' I specifically remember it because you said it in such a weird way.", "order": 37487, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 4, "scene": 31, "line": "The only reason I said that is because you asked me so politely! It was very difficult for me to say no.", "order": 37488, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 4, "scene": 31, "line": "I'll let this slide, but I expect you to put this whole Erin thing behind you.", "order": 37489, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 4, "scene": 32, "line": "Oh wow another living room!", "order": 37490, "speaker": "Michael Scott"},
  {"season": 7, "episode": 4, "scene": 32, "line": "It's a family room.", "order": 37491, "speaker": "Carroll"},
  {"season": 7, "episode": 4, "scene": 32, "line": "You put the TV here, you put the family over here.", "order": 37492, "speaker": "Michael Scott"},
  {"season": 7, "episode": 4, "scene": 32, "line": "Michael why are you here?", "order": 37493, "speaker": "Carroll"},
  {"season": 7, "episode": 4, "scene": 32, "line": "Someone told me that I romanticize relationships", "order": 37494, "speaker": "Michael Scott"},
  {"season": 7, "episode": 4, "scene": 32, "line": "You know, we all do that.", "order": 37495, "speaker": "Carroll"},
  {"season": 7, "episode": 4, "scene": 32, "line": "I have herpes.", "order": 37496, "speaker": "Michael Scott"},
  {"season": 7, "episode": 4, "scene": 32, "line": "What? Did you have that while we were together?", "order": 37497, "speaker": "Carroll"},
  {"season": 7, "episode": 4, "scene": 32, "line": "I just found out today. It's, uh, right there.", "order": 37498, "speaker": "Michael Scott"},
  {"season": 7, "episode": 4, "scene": 32, "line": "Oh! Oh, that's what you're talking about?", "order": 37499, "speaker": "Carroll"},
  {"season": 7, "episode": 4, "scene": 32, "line": "Mmhmm, I'm sorry.", "order": 37500, "speaker": "Michael Scott"},
  {"season": 7, "episode": 4, "scene": 32, "line": "Did the doctor check it out?", "order": 37501, "speaker": "Carroll"},
  {"season": 7, "episode": 4, "scene": 32, "line": "I'm between specialists right now.", "order": 37502, "speaker": "Michael Scott"},
  {"season": 7, "episode": 4, "scene": 32, "line": "Yes, yes Michael. Actually you do make a bigger deal out of things than you need to. You proposed to me on our fourth date.", "order": 37503, "speaker": "Carroll"},
  {"season": 7, "episode": 4, "scene": 32, "line": "Well I believe in love at first sight.", "order": 37504, "speaker": "Michael Scott"},
  {"season": 7, "episode": 4, "scene": 32, "line": "Well so do I, but we didn't love each other at first either. I don't know what you were thinking!", "order": 37505, "speaker": "Carrol"},
  {"season": 7, "episode": 4, "scene": 32, "line": "I knew what I was thinking at the time, but right now it just seems ridiculous.", "order": 37506, "speaker": "Michael Scott"},
  {"season": 7, "episode": 4, "scene": 32, "line": "[walking downstairs] Excuse me, someone died in the upstairs bathroom didn't they?", "order": 37507, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 4, "scene": 32, "line": "No.", "order": 37508, "speaker": "Carroll"},
  {"season": 7, "episode": 4, "scene": 33, "line": "[Andy walks in Darryl's office] We should schedule meetings, because the days can slip away with chit-chat. Are you crying?", "order": 37509, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 4, "scene": 33, "line": "No I'm just sweating.", "order": 37510, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 4, "scene": 33, "line": "I don't know who's got you upset but my advice is stop crying.", "order": 37511, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 4, "scene": 33, "line": "I'm not crying I'm just sweating.", "order": 37512, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 4, "scene": 33, "line": "Look you need to pick yourself up. Man up, alright? You will win this in the end. It's all about heart, and character. Be your best self.", "order": 37513, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 4, "scene": 33, "line": "Ok.", "order": 37514, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 4, "scene": 33, "line": "Yeah.", "order": 37515, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 4, "scene": 34, "line": "I have no idea what his problem is, that's just my standard advice. It's good advice right?", "order": 37516, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 4, "scene": 35, "line": "[on answering machine] This is Holly Flax, I can't come to the phone right now but please leave a message after the sound of the tiny truck backing up. [BEEP]", "order": 37517, "speaker": "Holly Flax"},
  {"season": 7, "episode": 4, "scene": 35, "line": "Hi Holly it's Michael, I just wanted to call and let you know that I was thinking about what you said. It's just. You know? It's weird. Today I ended up seeing a lot of women that I used to date, and in my mind they were all great. And then when I actually saw them, it was mostly a freak show. And you and me, that must have been a real train wreck. You know what, Holly? You're wrong. You are wrong. I remember every second of us. And talking to them today, I don't feel for them anything like what I feel for you. I didn't joke with any of them, I joked with you. You are the only one who was actually happy to hear from me. And I don't know why you downgraded what we had but I did not make us up. Ok. Oh, wait, and you should talk to a doctor because you might have herpes. Bye.", "order": 37518, "speaker": "Michael Scott"},
  {"season": 7, "episode": 4, "scene": 36, "line": "What is this about?", "order": 37519, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 4, "scene": 36, "line": "Oscar, we once sucked face in public. As part of an office presentation to destroy the stigma of gay kissing. Do you recall?", "order": 37520, "speaker": "Michael Scott"},
  {"season": 7, "episode": 4, "scene": 36, "line": "Yes!", "order": 37521, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 4, "scene": 36, "line": "You may have given me a sexually transmitted disease.", "order": 37522, "speaker": "Michael Scott"},
  {"season": 7, "episode": 4, "scene": 36, "line": "What?", "order": 37523, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 4, "scene": 36, "line": "Herpes duplex.", "order": 37524, "speaker": "Michael Scott"},
  {"season": 7, "episode": 4, "scene": 36, "line": "It was probably just an ingrown mustache hair but we have to be exhaustive.", "order": 37525, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 4, "scene": 36, "line": "I have already contacted all of my ex-lovers except for you.", "order": 37526, "speaker": "Michael Scott"},
  {"season": 7, "episode": 4, "scene": 36, "line": "We were never lovers!", "order": 37527, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 4, "scene": 36, "line": "I'm gonna need a list of every man you've ever had sex with. I'm talking train stations, men's rooms...", "order": 37528, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 4, "scene": 36, "line": "Flower shops, fireworks celebrations...", "order": 37529, "speaker": "Michael Scott"},
  {"season": 7, "episode": 4, "scene": 36, "line": "Fence with a hole in it..", "order": 37530, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 4, "scene": 36, "line": "Moolit Gandala, carrage drive through Central Park...", "order": 37531, "speaker": "Michael Scott"},
  {"season": 7, "episode": 4, "scene": 36, "line": "The woods behind the liquor store, the swamp behind the old folk's home.", "order": 37532, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 4, "scene": 36, "line": "An electric car dealership. [Oscar gets up and starts leaving]", "order": 37533, "speaker": "Michael Scott"},
  {"season": 7, "episode": 4, "scene": 36, "line": "The democratic primaries,", "order": 37534, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 4, "scene": 36, "line": "Oscar! Think abou- Think! [door slams]", "order": 37535, "speaker": "Michael Scott"},
  {"season": 7, "episode": 5, "scene": 1, "line": "Look at that.", "order": 37536, "speaker": "Michael Scott"},
  {"season": 7, "episode": 5, "scene": 1, "line": "Huh?", "order": 37537, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 5, "scene": 1, "line": "Nice!", "order": 37538, "speaker": "Michael Scott"},
  {"season": 7, "episode": 5, "scene": 1, "line": "I love cycling. Andy, I feel like a tourist in my own city. I literally can't wait to wake up every morning.", "order": 37539, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 5, "scene": 1, "line": "Okay.", "order": 37540, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 5, "scene": 1, "line": "She is a beaut!", "order": 37541, "speaker": "Michael Scott"},
  {"season": 7, "episode": 5, "scene": 1, "line": "Can't beat a horse. A horse is a bike that peddles itself.", "order": 37542, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 5, "scene": 1, "line": "Yes, it is.", "order": 37543, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 5, "scene": 1, "line": "Oh, look at that. [pushing bike] Smooth roll.", "order": 37544, "speaker": "Michael Scott"},
  {"season": 7, "episode": 5, "scene": 1, "line": "Yeah.", "order": 37545, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 5, "scene": 1, "line": "You got it! Lance Armstrong's Bike!", "order": 37546, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 5, "scene": 1, "line": "Yes.", "order": 37547, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 5, "scene": 1, "line": "His ass was on that seat? All right!", "order": 37548, "speaker": "Meredith Palmer"},
  {"season": 7, "episode": 5, "scene": 1, "line": "Nice.", "order": 37549, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 5, "scene": 1, "line": "No, Meredith, that's not his actual bicycle, but it is the same exact model he uses.", "order": 37550, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 5, "scene": 1, "line": "I'm on Sheryl Crow's side in that whole thing, so I feel really weird right now.", "order": 37551, "speaker": "Kelly Kapoor"},
  {"season": 7, "episode": 5, "scene": 1, "line": "[crashing sound] Michael!", "order": 37552, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 5, "scene": 1, "line": "Oh, god!", "order": 37553, "speaker": "Michael Scott"},
  {"season": 7, "episode": 5, "scene": 1, "line": "Oh...", "order": 37554, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 5, "scene": 2, "line": "Yes, I can ride a bike. I take spinning classes three times a month. I think I know how to ride a bike.", "order": 37555, "speaker": "Michael Scott"},
  {"season": 7, "episode": 5, "scene": 3, "line": "Are you sure you once knew how to do this?", "order": 37556, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 5, "scene": 3, "line": "I did, yes! I had those extra wheels on the back...that support you. [Pam and Jim begin wheeling him] Mi-chael! Mi-chael!", "order": 37557, "speaker": "Michael Scott"},
  {"season": 7, "episode": 5, "scene": 3, "line": "[chanting] Mi-chael! Mi-chael! Mi-chael! Mi-chael!", "order": 37558, "speaker": "All"},
  {"season": 7, "episode": 5, "scene": 3, "line": "Yeah!", "order": 37559, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 5, "scene": 3, "line": "Come on, Michael! Yeah!", "order": 37560, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 5, "scene": 3, "line": "Just steer more now.", "order": 37561, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 5, "scene": 3, "line": "[crashes into Jim and Pam's parked car] Oh, my god!", "order": 37562, "speaker": "Michael Scott"},
  {"season": 7, "episode": 5, "scene": 3, "line": "Not the car!", "order": 37563, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 5, "scene": 3, "line": "No! No!", "order": 37564, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 5, "scene": 3, "line": "Ow! [grunts, stumbles to his feet] You never forget. Whoo!", "order": 37565, "speaker": "Michael Scott"},
  {"season": 7, "episode": 5, "scene": 4, "line": "How do I look?", "order": 37566, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 5, "scene": 4, "line": "Amazing. How do I look?", "order": 37567, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 5, "scene": 4, "line": "Normal. Ugly.", "order": 37568, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 5, "scene": 4, "line": "Well, I do the best with what I've got. Let's go.", "order": 37569, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 5, "scene": 4, "line": "Alright. Wait, wait, wait, wait for me! It's weird if I come in slightly after.", "order": 37570, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 5, "scene": 5, "line": "We have a big meeting with the chief buyer for Frames Select, Steve Nash.", "order": 37571, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 5, "scene": 5, "line": "He's not the Steve Nash. He's big though. He's kind of like...Scranton's Steve Nash.", "order": 37572, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 5, "scene": 5, "line": "Will you stop trying to put it in terms you think they'll understand? It's condescending.", "order": 37573, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 5, "scene": 5, "line": "I'm not doing that. I'm just explaining.", "order": 37574, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 5, "scene": 5, "line": "And who is this 'the' Steve Nash?", "order": 37575, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 5, "scene": 5, "line": "Phoenix Sun's point guard?", "order": 37576, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 5, "scene": 5, "line": "No.", "order": 37577, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 5, "scene": 5, "line": "No? Nothin'?", "order": 37578, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 5, "scene": 5, "line": "No, Mr. Jock Hipster.", "order": 37579, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 5, "scene": 5, "line": "Well, I'm neither of those things, so...", "order": 37580, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 5, "scene": 6, "line": "[reading Cornell magazine] Whoa. Libby Dirketts got married. Big Red mazel tov to the Libster. Ooh, says here Dan Becker fell off the side of Kilimanjaro in a climbing accident. It appears Dan's Sherpa survived to tell the tale-Oh, my God!", "order": 37581, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 5, "scene": 6, "line": "What? Is Dan okay?", "order": 37582, "speaker": "Phyllis Vance"},
  {"season": 7, "episode": 5, "scene": 6, "line": "No, he died. It's Broccoli Rob. You know this guy; I showed you his picture on Facebook.", "order": 37583, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 5, "scene": 6, "line": "Yes.", "order": 37584, "speaker": "Phyllis Vance"},
  {"season": 7, "episode": 5, "scene": 6, "line": "Some Vermont-based alums can hear 'Broccoli' Rob Blatt, '96, in the state milk lobby's new milk awareness song, 'Calci-YUM!', featuring Phish's Trey Anastasio. Says Broccoli, 'Trey and I had a ton of fun in the studio, and I think you can hear it in the song.''", "order": 37585, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 5, "scene": 6, "line": "Oh, that's great news for your friend.", "order": 37586, "speaker": "Phyllis Vance"},
  {"season": 7, "episode": 5, "scene": 6, "line": "Yeah, yeah, it's great...", "order": 37587, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 5, "scene": 6, "line": "You know, I forget about milk. This is a terrific reminder.", "order": 37588, "speaker": "Phyllis Vance"},
  {"season": 7, "episode": 5, "scene": 7, "line": "I was the artsy, musical one. In Here Comes Treble I had four solos, Broccoli rob had three. Right? Uhh.", "order": 37589, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 5, "scene": 8, "line": "Go tell her we're here. You're good with receptionists.", "order": 37590, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 5, "scene": 8, "line": "Oh, ha ha ha. Dwight...[motions to lobby]", "order": 37591, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 5, "scene": 8, "line": "Crap.", "order": 37592, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 5, "scene": 9, "line": "Danny Cordray is the worst.", "order": 37593, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 5, "scene": 9, "line": "Well, by worst, you mean the best.", "order": 37594, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 5, "scene": 9, "line": "The best salesman ever. He works for Osprey Paper over in Throop, steals more clients from Dunder-Mifflin than anyone.", "order": 37595, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 5, "scene": 9, "line": "So, the situation is the worst.", "order": 37596, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 5, "scene": 9, "line": "Also, he slept with Pam.", "order": 37597, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 5, "scene": 9, "line": "No, he didn't. [Dwight mouthing 'yes, he did']", "order": 37598, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 5, "scene": 10, "line": "Tell 'em.", "order": 37599, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 5, "scene": 10, "line": "Nothing happened. We went on a couple of dates. He never called me again.", "order": 37600, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 5, "scene": 10, "line": "What? He never called you? I thought you said it just fizzled.", "order": 37601, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 5, "scene": 10, "line": "That's fizzling. I mean, someone has to start the fizzle.", "order": 37602, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 5, "scene": 10, "line": "Yeah, I thought you started it.", "order": 37603, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 5, "scene": 10, "line": "No, I liked him. For a couple of days. Four years ago. [Jim nodding repeatedly] You know I have a kid with you, right?", "order": 37604, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 5, "scene": 10, "line": "Ahhh.", "order": 37605, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 5, "scene": 11, "line": "I'm gonna intimidate him. Okay?", "order": 37606, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 5, "scene": 11, "line": "Okay, great-", "order": 37607, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 5, "scene": 11, "line": "Watch this.", "order": 37608, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 5, "scene": 11, "line": "-I'm just gonna watch.", "order": 37609, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 5, "scene": 11, "line": "[speaking loudly] So anyway, she says, 'that is the biggest penis I have ever seen.' And I said, 'I know. That's why I brought you to the Penis Museum, where tickets are a thousand dollars.' Well, hello Danny!", "order": 37610, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 5, "scene": 11, "line": "Hey, Dwight. Good to see you. Jim, hey.", "order": 37611, "speaker": "Danny Cordray"},
  {"season": 7, "episode": 5, "scene": 11, "line": "[shaking hands] How are ya?", "order": 37612, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 5, "scene": 11, "line": "Good to see you too.", "order": 37613, "speaker": "Danny Cordray"},
  {"season": 7, "episode": 5, "scene": 11, "line": "What are you doing?", "order": 37614, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 5, "scene": 11, "line": "Oh, I'm just here for the coffee.", "order": 37615, "speaker": "Danny Cordray"},
  {"season": 7, "episode": 5, "scene": 11, "line": "Like hell you are.", "order": 37616, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 5, "scene": 11, "line": "Dwight!", "order": 37617, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 5, "scene": 11, "line": "He's not just here for the coffee, Jim. Wake up!", "order": 37618, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 5, "scene": 12, "line": "Brainstorming session is now open. Anyone has an idea-[cell phone rings] Hold on. Sorry, sorry, sorry. Hello?", "order": 37619, "speaker": "Michael Scott"},
  {"season": 7, "episode": 5, "scene": 12, "line": "Hey, it's Jim. Listen, this is important. Are you busy?", "order": 37620, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 5, "scene": 12, "line": "Oh, hey. No, I'm not busy. What's up?", "order": 37621, "speaker": "Michael Scott"},
  {"season": 7, "episode": 5, "scene": 12, "line": "What?", "order": 37622, "speaker": "Angela Martin"},
  {"season": 7, "episode": 5, "scene": 12, "line": "You are busy! We're in a meeting!", "order": 37623, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 5, "scene": 12, "line": "It's Jim, Okay? Yes.", "order": 37624, "speaker": "Michael Scott"},
  {"season": 7, "episode": 5, "scene": 12, "line": "Listen, you gotta get over here, 'cause we're pitching Steve Nash and Danny Cordray is here.", "order": 37625, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 5, "scene": 12, "line": "You need the big guns, yes?", "order": 37626, "speaker": "Michael Scott"},
  {"season": 7, "episode": 5, "scene": 12, "line": "Yes.", "order": 37627, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 5, "scene": 12, "line": "What'd he say? What did he say?", "order": 37628, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 5, "scene": 12, "line": "The big gun thing. [Dwight grabs at phone] Stop!", "order": 37629, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 5, "scene": 12, "line": "I will see you in ten. Bye! Hold tight. [hangs up] Well, I know a lot of you thought that my sales days were behind me, and to be honest, so did I...and the only reason I got out of the sales game was to be reluctantly called back in.", "order": 37630, "speaker": "Michael Scott"},
  {"season": 7, "episode": 5, "scene": 12, "line": "You don't look reluctant, Michael. You look really eager.", "order": 37631, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 5, "scene": 12, "line": "[laughing] No, I don't have time for this. Are you kidding me?", "order": 37632, "speaker": "Michael Scott"},
  {"season": 7, "episode": 5, "scene": 12, "line": "You don't?", "order": 37633, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 5, "scene": 12, "line": "No! Okay. [runs out the door] All right.", "order": 37634, "speaker": "Michael Scott"},
  {"season": 7, "episode": 5, "scene": 13, "line": "[knocking] Knockity-knock, don't knock back. Just kidding you can knock; it's your office. Do you have a minute?", "order": 37635, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 5, "scene": 13, "line": "I'm very busy with time-sensitive work.", "order": 37636, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 5, "scene": 13, "line": "Not to go all Sherlock Holmes on you, but I can tell by the reflection in your glasses that you're entering points into Weight Watchers dot com.", "order": 37637, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 5, "scene": 13, "line": "If you don't enter them immediately, you forget. What?", "order": 37638, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 5, "scene": 13, "line": "I'm starting a band and I need you on keyboards.", "order": 37639, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 5, "scene": 13, "line": "Nah. I play for pleasure.", "order": 37640, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 5, "scene": 13, "line": "This is for pleasure.", "order": 37641, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 5, "scene": 13, "line": "I wouldn't enjoy that.", "order": 37642, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 5, "scene": 13, "line": "I'm willing to pay you.", "order": 37643, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 5, "scene": 13, "line": "Oh, yeah?", "order": 37644, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 5, "scene": 13, "line": "Yeah.", "order": 37645, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 5, "scene": 13, "line": "How much?", "order": 37646, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 5, "scene": 13, "line": "Sixty bucks a session.", "order": 37647, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 5, "scene": 13, "line": "That's crazy money. I'll take forty.", "order": 37648, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 5, "scene": 13, "line": "Yes!", "order": 37649, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 5, "scene": 14, "line": "There he is.", "order": 37650, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 5, "scene": 14, "line": "Oh, no, that's a male model.", "order": 37651, "speaker": "Michael Scott"},
  {"season": 7, "episode": 5, "scene": 14, "line": "No, that's him.", "order": 37652, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 5, "scene": 14, "line": "That...hello. Michael Scott, Dunder-Mifflin Paper Company.", "order": 37653, "speaker": "Michael Scott"},
  {"season": 7, "episode": 5, "scene": 14, "line": "Danny Cordray, Osprey Paper.", "order": 37654, "speaker": "Danny Cordray"},
  {"season": 7, "episode": 5, "scene": 14, "line": "Nice to meet you.", "order": 37655, "speaker": "Michael Scott"},
  {"season": 7, "episode": 5, "scene": 14, "line": "Three of you guys for one sale.", "order": 37656, "speaker": "Danny Cordray"},
  {"season": 7, "episode": 5, "scene": 14, "line": "Yeah, well...we call it overkill. Why am I telling you my strategy?", "order": 37657, "speaker": "Michael Scott"},
  {"season": 7, "episode": 5, "scene": 14, "line": "Well, whatever works for ya. Anyway. All right.", "order": 37658, "speaker": "Danny Cordray"},
  {"season": 7, "episode": 5, "scene": 14, "line": "All right.", "order": 37659, "speaker": "Michael Scott"},
  {"season": 7, "episode": 5, "scene": 14, "line": "It's good to see you guys. Nice to meet you.", "order": 37660, "speaker": "Danny Cordray"},
  {"season": 7, "episode": 5, "scene": 14, "line": "Buh-bye. I could swear that guy was a male model.", "order": 37661, "speaker": "Michael Scott"},
  {"season": 7, "episode": 5, "scene": 14, "line": "He's ready for you.", "order": 37662, "speaker": "Receptionist"},
  {"season": 7, "episode": 5, "scene": 14, "line": "Oh, thank you. All right. On the count of three, it's showtime. Ready, one, two--", "order": 37663, "speaker": "Michael Scott"},
  {"season": 7, "episode": 5, "scene": 14, "line": "Nope, not doing that.", "order": 37664, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 5, "scene": 14, "line": "I've been in showtime mode since breakfast.", "order": 37665, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 5, "scene": 14, "line": "Okay, you know-all right, just forget it then.", "order": 37666, "speaker": "Michael Scott"},
  {"season": 7, "episode": 5, "scene": 14, "line": "Showtime!", "order": 37667, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 5, "scene": 14, "line": "It's showtime! Oh...never mind. Let's go.", "order": 37668, "speaker": "Michael Scott"},
  {"season": 7, "episode": 5, "scene": 15, "line": "When I knock on your door, you know who it is; it's Michael Scott. We've been together forever and we-", "order": 37669, "speaker": "Michael Scott"},
  {"season": 7, "episode": 5, "scene": 15, "line": "Michael, I appreciate everything that Dunder-Mifflin has done for this company.", "order": 37670, "speaker": "Steve"},
  {"season": 7, "episode": 5, "scene": 15, "line": "You know what? You are having some problems with your loading dock, are you not? All right. We're going to deliver to you on weekends.", "order": 37671, "speaker": "Michael Scott"},
  {"season": 7, "episode": 5, "scene": 15, "line": "That's very generous, but-", "order": 37672, "speaker": "Steve"},
  {"season": 7, "episode": 5, "scene": 15, "line": "And you know what else we're gonna do? I can't believe I'm gonna say this...we are going to offer you our paper at cost. I know. I could get in a lotta trouble for this, so you'd better shake my hand right now.", "order": 37673, "speaker": "Michael Scott"},
  {"season": 7, "episode": 5, "scene": 15, "line": "He's not kidding.", "order": 37674, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 5, "scene": 15, "line": "Shake it, shake it!", "order": 37675, "speaker": "Michael Scott"},
  {"season": 7, "episode": 5, "scene": 15, "line": "Michael, I'm going with Danny.", "order": 37676, "speaker": "Steve"},
  {"season": 7, "episode": 5, "scene": 15, "line": "Oh.", "order": 37677, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 5, "scene": 15, "line": "Thanks for coming in.", "order": 37678, "speaker": "Steve"},
  {"season": 7, "episode": 5, "scene": 15, "line": "Okay. Thank you.", "order": 37679, "speaker": "Michael Scott"},
  {"season": 7, "episode": 5, "scene": 15, "line": "Thank you.", "order": 37680, "speaker": "Steve"},
  {"season": 7, "episode": 5, "scene": 15, "line": "Thanks, Steve.", "order": 37681, "speaker": "Michael Scott"},
  {"season": 7, "episode": 5, "scene": 16, "line": "Jim talked too much.", "order": 37682, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 5, "scene": 16, "line": "No, I didn't.", "order": 37683, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 5, "scene": 16, "line": "Yes, you did.", "order": 37684, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 5, "scene": 16, "line": "Stop it. Just stop it. We did what we should have done, we just got bested. [pounds the elevator button] Why is there a door close button if it doesn't even close the door?", "order": 37685, "speaker": "Michael Scott"},
  {"season": 7, "episode": 5, "scene": 17, "line": "How do I feel about losing the sale? It's like if Michael Phelps came out of retirement, jumped in the pool, belly-flopped, and drowned.", "order": 37686, "speaker": "Michael Scott"},
  {"season": 7, "episode": 5, "scene": 18, "line": "You know who we always lose out to? Staples, the big guys. Osprey? They're a small company. They're smaller than we are. What's our excuse? How do we combat this guy? Stanley, how do we combat him?", "order": 37687, "speaker": "Michael Scott"},
  {"season": 7, "episode": 5, "scene": 18, "line": "We sell better?", "order": 37688, "speaker": "Stanley Hudson"},
  {"season": 7, "episode": 5, "scene": 18, "line": "Okay. You know what? You clearly don't care, so why don't you just leave?", "order": 37689, "speaker": "Michael Scott"},
  {"season": 7, "episode": 5, "scene": 18, "line": "I would like to stay. This pertains to me.", "order": 37690, "speaker": "Stanley Hudson"},
  {"season": 7, "episode": 5, "scene": 18, "line": "Why don't you go outside and...take a shot of insulin and have a nap, okay?", "order": 37691, "speaker": "Michael Scott"},
  {"season": 7, "episode": 5, "scene": 18, "line": "Why do you always assume I have diabetes?", "order": 37692, "speaker": "Stanley Hudson"},
  {"season": 7, "episode": 5, "scene": 18, "line": "I don't know, your frame, your build-why don't you have a glass of apple juice and tell me you're not a diabetic. [Stanley stands to leave] See? I could tell by the sound you made when you stood up that you have-okay. Yes, Phyllis?", "order": 37693, "speaker": "Michael Scott"},
  {"season": 7, "episode": 5, "scene": 18, "line": "I could try to seduce him.", "order": 37694, "speaker": "Phyllis Vance"},
  {"season": 7, "episode": 5, "scene": 18, "line": "Oh, my god!", "order": 37695, "speaker": "Michael Scott"},
  {"season": 7, "episode": 5, "scene": 18, "line": "I know how we can learn his tricks.", "order": 37696, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 5, "scene": 19, "line": "What I am about to show you is of the utmost secrecy.", "order": 37697, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 5, "scene": 19, "line": "Is that your office?", "order": 37698, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 5, "scene": 19, "line": "Yes, Jim. And with a little tweaking, it becomes a different office. We lure Danny to it and watch him sell.", "order": 37699, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 5, "scene": 19, "line": "Uh...", "order": 37700, "speaker": "Phyllis Vance"},
  {"season": 7, "episode": 5, "scene": 19, "line": "I'm outta here.", "order": 37701, "speaker": "Stanley Hudson"},
  {"season": 7, "episode": 5, "scene": 19, "line": "This is weird.", "order": 37702, "speaker": "Phyllis Vance"},
  {"season": 7, "episode": 5, "scene": 19, "line": "You know what this is? This is a stinger.", "order": 37703, "speaker": "Michael Scott"},
  {"season": 7, "episode": 5, "scene": 19, "line": "A what?", "order": 37704, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 5, "scene": 19, "line": "Like the movie.", "order": 37705, "speaker": "Michael Scott"},
  {"season": 7, "episode": 5, "scene": 19, "line": "I think you mean The Sting.", "order": 37706, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 5, "scene": 19, "line": "Paul Newman, Robert Redford. They're bank robbers.", "order": 37707, "speaker": "Michael Scott"},
  {"season": 7, "episode": 5, "scene": 19, "line": "Nope. Different movie.", "order": 37708, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 5, "scene": 19, "line": "The Sting. The Sting.", "order": 37709, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 5, "scene": 20, "line": "Your two o'clock. [Danny enters]", "order": 37710, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 5, "scene": 20, "line": "Thanks, hun.", "order": 37711, "speaker": "Meredith Palmer"},
  {"season": 7, "episode": 5, "scene": 21, "line": "Meredith was the perfect choice to play the head of the company. Her lunch break lined up with our appointment with Danny and...that's it. That's really all we were looking for.", "order": 37712, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 5, "scene": 22, "line": "Danny Cordray. It's great to meet you.", "order": 37713, "speaker": "Danny Cordray"},
  {"season": 7, "episode": 5, "scene": 22, "line": "Meredith Van Helsing. Pleased to meet you.", "order": 37714, "speaker": "Meredith Palmer"},
  {"season": 7, "episode": 5, "scene": 22, "line": "Meredith Van Helsing?", "order": 37715, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 5, "scene": 22, "line": "Van Helsing was a respected professor before he was a vampire killer.", "order": 37716, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 5, "scene": 22, "line": "Okay. But what is he more famous for?", "order": 37717, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 5, "scene": 22, "line": "Shhh! Hey! That's my mug.", "order": 37718, "speaker": "Michael Scott"},
  {"season": 7, "episode": 5, "scene": 22, "line": "You know this isn't real TV, right?", "order": 37719, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 5, "scene": 22, "line": "Yes.", "order": 37720, "speaker": "Michael Scott"},
  {"season": 7, "episode": 5, "scene": 23, "line": "[singing] Please Mr. President, if you wanna give hope a whirl, bring our troops home safe and sound, says this little girl.", "order": 37721, "speaker": "Darryl Philbin & Andy Bernard"},
  {"season": 7, "episode": 5, "scene": 23, "line": "[in falsetto] Please Mr. President-", "order": 37722, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 5, "scene": 23, "line": "One second, one second, one second. [stops playing] So, this song is from the point of view of a little girl?", "order": 37723, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 5, "scene": 23, "line": "Yeah.", "order": 37724, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 5, "scene": 23, "line": "But you're singing it.", "order": 37725, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 5, "scene": 23, "line": "Yeah, but I'm using my falsetto.", "order": 37726, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 5, "scene": 23, "line": "No, that's not a good idea. I don't see that as a very good song.", "order": 37727, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 5, "scene": 23, "line": "Yeah, well, it'd sound a lot better if you actually sang with some soul.", "order": 37728, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 5, "scene": 23, "line": "Oh, I never sing with soul.", "order": 37729, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 5, "scene": 23, "line": "That's a lie.", "order": 37730, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 5, "scene": 23, "line": "I could sing it.", "order": 37731, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 5, "scene": 23, "line": "I just was hoping to maybe save your voice in case we did a novelty song about frogs.", "order": 37732, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 5, "scene": 23, "line": "But my voice is unique. Like Bob Dylan.", "order": 37733, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 5, "scene": 23, "line": "Okay, three-way argument between a drummer, a keyboardist, and the front man lead singer. Wonder who's gonna win this one. Two, three, four-", "order": 37734, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 5, "scene": 24, "line": "I'm gonna take off my coat, if you don't mind. It's a bit warm in here.", "order": 37735, "speaker": "Danny Cordray"},
  {"season": 7, "episode": 5, "scene": 24, "line": "Hel-lo!", "order": 37736, "speaker": "Meredith Palmer"},
  {"season": 7, "episode": 5, "scene": 24, "line": "Oh, no, no. No. Don't-", "order": 37737, "speaker": "Michael Scott"},
  {"season": 7, "episode": 5, "scene": 24, "line": "People can't keep their true natures hidden for long, and this guy is smoldering like a tire fire.", "order": 37738, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 5, "scene": 24, "line": "Testify.", "order": 37739, "speaker": "Michael Scott"},
  {"season": 7, "episode": 5, "scene": 24, "line": "Okay, he's not that good-looking. I don't understand why everybody's obsessed with this-", "order": 37740, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 5, "scene": 24, "line": "Yeah, he is that good-looking.", "order": 37741, "speaker": "Michael Scott"},
  {"season": 7, "episode": 5, "scene": 24, "line": "He's very, very handsome.", "order": 37742, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 5, "scene": 24, "line": "Okay.", "order": 37743, "speaker": "Michael Scott"},
  {"season": 7, "episode": 5, "scene": 24, "line": "That's a great set of shoulder's you got there. What is that...Genetics? Creatine? [phone rings] Sorry. Yeah?", "order": 37744, "speaker": "Meredith Palmer"},
  {"season": 7, "episode": 5, "scene": 24, "line": "Pull it together, all right? Stop lookin' at him.", "order": 37745, "speaker": "Michael Scott"},
  {"season": 7, "episode": 5, "scene": 24, "line": "I'm sorry. [hangs up] You're here to sell me some paper.", "order": 37746, "speaker": "Meredith Palmer"},
  {"season": 7, "episode": 5, "scene": 24, "line": "Well, actually, uh... no, Miss Van Helsing, that's not why I'm here. I'm here to meet you, see if we'd be a, you know, good fit.", "order": 37747, "speaker": "Danny Cordray"},
  {"season": 7, "episode": 5, "scene": 24, "line": "What do you mean?", "order": 37748, "speaker": "Meredith Palmer"},
  {"season": 7, "episode": 5, "scene": 24, "line": "Oh...my god! He's making her sell to him.", "order": 37749, "speaker": "Michael Scott"},
  {"season": 7, "episode": 5, "scene": 25, "line": "[everyone finishes listening to song] Okay. Honest feedback time. Oscar?", "order": 37750, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 5, "scene": 25, "line": "It's pandering. And it makes me think you think I'm stupid.", "order": 37751, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 5, "scene": 25, "line": "But do you think it could be famous? Like in a car commercial or something?", "order": 37752, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 5, "scene": 25, "line": "Not really. It's kinda weird that a grown man is singing from the point of view of a little girl.", "order": 37753, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 5, "scene": 25, "line": "I feel like I can see someone ice skating to it. You know, like in the Olympics.", "order": 37754, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 5, "scene": 25, "line": "I-I don't think they usually...skate to such...bad songs.", "order": 37755, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 5, "scene": 25, "line": "Rude. And not helpful.", "order": 37756, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 5, "scene": 25, "line": "Well, I really, really, really liked it.", "order": 37757, "speaker": "Creed Bratton"},
  {"season": 7, "episode": 5, "scene": 25, "line": "Well, that...really bums me out.", "order": 37758, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 5, "scene": 25, "line": "You're welcome.", "order": 37759, "speaker": "Creed Bratton"},
  {"season": 7, "episode": 5, "scene": 26, "line": "We should think of this as a first date. And I think it's going very well, how 'bout you?", "order": 37760, "speaker": "Danny Cordray"},
  {"season": 7, "episode": 5, "scene": 26, "line": "Real well. Uh...feels more like a third date to me.", "order": 37761, "speaker": "Meredith Palmer"},
  {"season": 7, "episode": 5, "scene": 26, "line": "Ha ha, okay, there you go! [phone rings, Meredith answers]", "order": 37762, "speaker": "Danny Cordray"},
  {"season": 7, "episode": 5, "scene": 26, "line": "Stay-", "order": 37763, "speaker": "Michael Scott"},
  {"season": 7, "episode": 5, "scene": 26, "line": "Shut up and let me do this! [hangs up] You know, what is with this desk keeping us so far apart?", "order": 37764, "speaker": "Meredith Palmer"},
  {"season": 7, "episode": 5, "scene": 26, "line": "I've never been a desk man. Always traveling on the road. Come on...why not, huh?", "order": 37765, "speaker": "Danny Cordray"},
  {"season": 7, "episode": 5, "scene": 26, "line": "Oh...", "order": 37766, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 5, "scene": 26, "line": "Well, we'll get this...[Meredith unbuttons]", "order": 37767, "speaker": "Danny Cordray"},
  {"season": 7, "episode": 5, "scene": 26, "line": "Oh!", "order": 37768, "speaker": "Michael Scott"},
  {"season": 7, "episode": 5, "scene": 26, "line": "Oh, man!", "order": 37769, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 5, "scene": 26, "line": "So, what's your drink? You a vodka man? Me too.", "order": 37770, "speaker": "Meredith Palmer"},
  {"season": 7, "episode": 5, "scene": 26, "line": "We gotta get someone else in there right now.", "order": 37771, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 5, "scene": 27, "line": "You're an exec at Pennsylvania Solartech and-", "order": 37772, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 5, "scene": 27, "line": "That sounds fake.", "order": 37773, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 5, "scene": 27, "line": "What do you mean?", "order": 37774, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 5, "scene": 27, "line": "I told you! You're an exec at Stark Industries, a corporation you inherited from your father-", "order": 37775, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 5, "scene": 27, "line": "Will you stop? Stop it, stop it. Here's the story-they need Meredith somewhere else asap, okay?", "order": 37776, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 5, "scene": 27, "line": "Okay.", "order": 37777, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 5, "scene": 27, "line": "So you're taking over. You just gotta get her out of there as soon as you can.", "order": 37778, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 5, "scene": 27, "line": "All right. I can do that. Then what?", "order": 37779, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 5, "scene": 27, "line": "Then make him pitch to you.", "order": 37780, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 5, "scene": 27, "line": "Yes.", "order": 37781, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 5, "scene": 27, "line": "We gotta see what he's got.", "order": 37782, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 5, "scene": 27, "line": "Exactly. You can do this.", "order": 37783, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 5, "scene": 27, "line": "Okay, and remember-you're not gay.", "order": 37784, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 5, "scene": 27, "line": "Stop it! It's gonna be great.", "order": 37785, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 5, "scene": 27, "line": "Okay.", "order": 37786, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 5, "scene": 27, "line": "And listen if anything else happens, just...roll with it.", "order": 37787, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 5, "scene": 28, "line": "Meredith, I-", "order": 37788, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 5, "scene": 28, "line": "Oh, Manuel! This is Manuel, my cleaning man. He doesn't speak any English.", "order": 37789, "speaker": "Meredith Palmer"},
  {"season": 7, "episode": 5, "scene": 28, "line": "Hola. Que tal?", "order": 37790, "speaker": "Danny Cordray"},
  {"season": 7, "episode": 5, "scene": 28, "line": "Como estas, senor?", "order": 37791, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 5, "scene": 28, "line": "Uh, Manuel, cleano el window.", "order": 37792, "speaker": "Meredith Palmer"},
  {"season": 7, "episode": 5, "scene": 28, "line": "Who else we got?", "order": 37793, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 5, "scene": 29, "line": "Okay. You're a young hotshot from Stark Industries. You've just bought this company. Meredith is fired. It's a whole new regime. He's gotta pitch to you now.", "order": 37794, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 5, "scene": 29, "line": "Okay. Stark Industries isn't real. I run Google. Larry and Sergey brought me in-", "order": 37795, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 5, "scene": 29, "line": "Great. Sounds awesome. Just have him pitch to you.", "order": 37796, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 5, "scene": 29, "line": "Don't let us down.", "order": 37797, "speaker": "Michael Scott"},
  {"season": 7, "episode": 5, "scene": 29, "line": "[points to Jim] Will do. [points to Michael] Won't do.", "order": 37798, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 5, "scene": 30, "line": "Yeah, sure.", "order": 37799, "speaker": "Danny Cordray"},
  {"season": 7, "episode": 5, "scene": 30, "line": "You smell like a Scorpio. [Ryan enters] This is...Esteban...another cleaning man. He doesn't speak English either. Esteban, el flooro.", "order": 37800, "speaker": "Meredith Palmer"},
  {"season": 7, "episode": 5, "scene": 30, "line": "You know what? I may have parked my car in a compact space, and I hate when I see others do that, so-", "order": 37801, "speaker": "Danny Cordray"},
  {"season": 7, "episode": 5, "scene": 30, "line": "Danny, I feel a real connection to you, and I gotta get real, I'm-", "order": 37802, "speaker": "Meredith Palmer"},
  {"season": 7, "episode": 5, "scene": 30, "line": "No, no, no....", "order": 37803, "speaker": "Michael Scott"},
  {"season": 7, "episode": 5, "scene": 30, "line": "No, no, no, no...", "order": 37804, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 5, "scene": 30, "line": "I'm goin 'in!", "order": 37805, "speaker": "Michael Scott"},
  {"season": 7, "episode": 5, "scene": 30, "line": "Michael! No, Michael, stop it! No!", "order": 37806, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 5, "scene": 30, "line": "Stop, stop! Oh, my god! [enters office] Okay. Stop it, stop it, stop it. Gross! Stop it, please. Everybody, stop.", "order": 37807, "speaker": "Michael Scott"},
  {"season": 7, "episode": 5, "scene": 30, "line": "Michael Scott.", "order": 37808, "speaker": "Danny Cordray"},
  {"season": 7, "episode": 5, "scene": 30, "line": "I said stop. Okay, Danny...this is not Pennsylvania Solartech. This is Dunder-Mifflin Paper Company. This is Meredith Palmer-", "order": 37809, "speaker": "Michael Scott"},
  {"season": 7, "episode": 5, "scene": 30, "line": "Please to meet you-", "order": 37810, "speaker": "Meredith Palmer"},
  {"season": 7, "episode": 5, "scene": 30, "line": "Don't! Get away from him. Please, just leave. Get out of here. Go. Forever.", "order": 37811, "speaker": "Michael Scott"},
  {"season": 7, "episode": 5, "scene": 30, "line": "Hmm.", "order": 37812, "speaker": "Danny Cordray"},
  {"season": 7, "episode": 5, "scene": 30, "line": "I owe you...a most sincere and humble apology. We were trying to watch you to see your sales technique, so we could stop losing so many clients to you.", "order": 37813, "speaker": "Michael Scott"},
  {"season": 7, "episode": 5, "scene": 30, "line": "But from where?", "order": 37814, "speaker": "Danny Cordray"},
  {"season": 7, "episode": 5, "scene": 30, "line": "A surveillance room next to this one.", "order": 37815, "speaker": "Michael Scott"},
  {"season": 7, "episode": 5, "scene": 30, "line": "Okay, so you...set up this fake company, then you hired this homeless woman to impersonate an executive to spy on me so that you could copy my sales technique?", "order": 37816, "speaker": "Danny Cordray"},
  {"season": 7, "episode": 5, "scene": 30, "line": "Yes. And it's the sincerest form of flattery.", "order": 37817, "speaker": "Michael Scott"},
  {"season": 7, "episode": 5, "scene": 30, "line": "Or...crazy.", "order": 37818, "speaker": "Danny Cordray"},
  {"season": 7, "episode": 5, "scene": 30, "line": "Well-", "order": 37819, "speaker": "Michael Scott"},
  {"season": 7, "episode": 5, "scene": 30, "line": "I'm gonna go.", "order": 37820, "speaker": "Danny Cordray"},
  {"season": 7, "episode": 5, "scene": 30, "line": "Okay. You know what, it wasn't just me. Jim and Dwight are behind that wall in the surveillance room and it was their plan as well.", "order": 37821, "speaker": "Michael Scott"},
  {"season": 7, "episode": 5, "scene": 30, "line": "No!", "order": 37822, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 5, "scene": 30, "line": "No, no, no, no.", "order": 37823, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 5, "scene": 30, "line": "Oh, well then, yeah. All right. Hey! [knocking on wall] Good luck, guys. Seems like you got a great operation here.", "order": 37824, "speaker": "Danny Cordray"},
  {"season": 7, "episode": 5, "scene": 30, "line": "No, we don't. Here's-here's my point. Danny, listen, you have to understand that we are not normally like this. We just-we wanted to know your tricks.", "order": 37825, "speaker": "Michael Scott"},
  {"season": 7, "episode": 5, "scene": 30, "line": "What do you mean, my tricks? There's no tricks, man. I'm just a good salesman. You wanna copy that? You can't copy that! [opens door to leave]", "order": 37826, "speaker": "Danny Cordray"},
  {"season": 7, "episode": 5, "scene": 30, "line": "You are, you are. You are! Stop it, stop it. Stop. [closes door] You are a good salesman. And because of that...I want you to work for me.", "order": 37827, "speaker": "Michael Scott"},
  {"season": 7, "episode": 5, "scene": 30, "line": "Sure. You seem like a fun, professional guy.", "order": 37828, "speaker": "Danny Cordray"},
  {"season": 7, "episode": 5, "scene": 30, "line": "So, you will?", "order": 37829, "speaker": "Michael Scott"},
  {"season": 7, "episode": 5, "scene": 30, "line": "[opening door again to leave] No!", "order": 37830, "speaker": "Danny Cordray"},
  {"season": 7, "episode": 5, "scene": 30, "line": "Hold it, hold it. [forcing door closed] Hold on, hold on. Wait, wait, wait, wait. Wait. Wait. Danny, I want you to look at me. Do you want your life to be better...or to be worse or to stay the same?", "order": 37831, "speaker": "Michael Scott"},
  {"season": 7, "episode": 5, "scene": 30, "line": "Get out of my way.", "order": 37832, "speaker": "Danny Cordray"},
  {"season": 7, "episode": 5, "scene": 30, "line": "Answer the question. Do you want a better life, Danny?", "order": 37833, "speaker": "Michael Scott"},
  {"season": 7, "episode": 5, "scene": 30, "line": "I swear to God, I'm gonna hit you. I didn't mean that. I'm sorry. I'm not-it's just I'm very upset right now.", "order": 37834, "speaker": "Danny Cordray"},
  {"season": 7, "episode": 5, "scene": 30, "line": "I know, I know.", "order": 37835, "speaker": "Michael Scott"},
  {"season": 7, "episode": 5, "scene": 30, "line": "I'm very upset!", "order": 37836, "speaker": "Danny Cordray"},
  {"season": 7, "episode": 5, "scene": 30, "line": "Do you want more freedom, less freedom, or to stay the same?", "order": 37837, "speaker": "Michael Scott"},
  {"season": 7, "episode": 5, "scene": 30, "line": "More freedom.", "order": 37838, "speaker": "Danny Cordray"},
  {"season": 7, "episode": 5, "scene": 30, "line": "I can give you more money-there's your better life. I can let you come and go as you please-there's your freedom. And you already know Dunder-Mifflin has the best service and the best prices, but you beat us anyway. Can you imagine how well you would do selling our stuff?", "order": 37839, "speaker": "Michael Scott"},
  {"season": 7, "episode": 5, "scene": 31, "line": "Everyone, may I have your attention? I would like to introduce you to Mr. Danny Cordray. He is going to be joining us as our new traveling salesman. Say hello to Danny!", "order": 37840, "speaker": "Michael Scott"},
  {"season": 7, "episode": 5, "scene": 31, "line": "[bleep] me!", "order": 37841, "speaker": "Kelly Kapoor"},
  {"season": 7, "episode": 5, "scene": 31, "line": "O-kay. You know what? No. No. This is not some sort of construction site...or all of Italy, where you can just go around treating people like meat. We are to respect him. I respect him because he reminds me of somebody. Can anybody guess who that is?", "order": 37842, "speaker": "Michael Scott"},
  {"season": 7, "episode": 5, "scene": 31, "line": "Josh Duhamel.", "order": 37843, "speaker": "Kelly Kapoor"},
  {"season": 7, "episode": 5, "scene": 31, "line": "Yeah, I can see that.", "order": 37844, "speaker": "Angela Martin"},
  {"season": 7, "episode": 5, "scene": 31, "line": "No. No. No. Somebody in this office.", "order": 37845, "speaker": "Michael Scott"},
  {"season": 7, "episode": 5, "scene": 31, "line": "He's like a better-looking Andy.", "order": 37846, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 5, "scene": 31, "line": "Thanks, Kevin.", "order": 37847, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 5, "scene": 31, "line": "No, me. Right? Sorta like...a little younger version of me.", "order": 37848, "speaker": "Michael Scott"},
  {"season": 7, "episode": 5, "scene": 31, "line": "It's hard to judge ourselves accurately, isn't it.", "order": 37849, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 5, "scene": 31, "line": "Michael?", "order": 37850, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 5, "scene": 31, "line": "Yes.", "order": 37851, "speaker": "Michael Scott"},
  {"season": 7, "episode": 5, "scene": 31, "line": "Can I talk to you about something?", "order": 37852, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 5, "scene": 31, "line": "No, you may not.", "order": 37853, "speaker": "Michael Scott"},
  {"season": 7, "episode": 5, "scene": 31, "line": "It's about this very announcement you just made.", "order": 37854, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 5, "scene": 31, "line": "I said no.", "order": 37855, "speaker": "Michael Scott"},
  {"season": 7, "episode": 5, "scene": 31, "line": "Michael-", "order": 37856, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 5, "scene": 31, "line": "We're not-", "order": 37857, "speaker": "Michael Scott"},
  {"season": 7, "episode": 5, "scene": 31, "line": "Michael, I think you really want to talk to him.", "order": 37858, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 5, "scene": 31, "line": "Okay. Let's-all right, all right. Let's talk. Please don't let him leave. [pointing to Danny] Don't leave. Don't let him.", "order": 37859, "speaker": "Michael Scott"},
  {"season": 7, "episode": 5, "scene": 31, "line": "Gotcha. [they go into Michael's office]", "order": 37860, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 5, "scene": 31, "line": "You hired him?", "order": 37861, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 5, "scene": 31, "line": "Guys, let me ask you something. Do you want your life to be better or worse or stay the same?", "order": 37862, "speaker": "Michael Scott"},
  {"season": 7, "episode": 5, "scene": 31, "line": "Stay the same.", "order": 37863, "speaker": "All"},
  {"season": 7, "episode": 5, "scene": 31, "line": "Okay. Well...get ready, 'cause it's gonna get better.", "order": 37864, "speaker": "Michael Scott"},
  {"season": 7, "episode": 5, "scene": 31, "line": "It's not gonna get better; he's gonna steal all of our clients.", "order": 37865, "speaker": "Phyllis Vance"},
  {"season": 7, "episode": 5, "scene": 31, "line": "No, no. Wrong. He would have stolen your clients, but you know what? He can't now. This guy used to steal sales from us; now, he's going to steal sales for us.", "order": 37866, "speaker": "Michael Scott"},
  {"season": 7, "episode": 5, "scene": 31, "line": "Where's he gonna sit? There's no more seats.", "order": 37867, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 5, "scene": 31, "line": "He doesn't need to sit, he's a traveling salesman. Look, I am not going to exclude good people from our staff simply because they are threatening to you. And unless you have a better argument than that, I suggest you leave.", "order": 37868, "speaker": "Michael Scott"},
  {"season": 7, "episode": 5, "scene": 31, "line": "Hmpf!", "order": 37869, "speaker": "Stanley Hudson"},
  {"season": 7, "episode": 5, "scene": 31, "line": "Where's he gonna park? There's no more reserved parking spots.", "order": 37870, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 5, "scene": 31, "line": "Good-bye!", "order": 37871, "speaker": "Michael Scott"},
  {"season": 7, "episode": 5, "scene": 32, "line": "Song's about truth.", "order": 37872, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 5, "scene": 32, "line": "Yeah.", "order": 37873, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 5, "scene": 32, "line": "What's something you really care about?", "order": 37874, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 5, "scene": 32, "line": "Reverse snobbery.", "order": 37875, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 5, "scene": 32, "line": "More universal.", "order": 37876, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 5, "scene": 32, "line": "Sometimes I feel like life has passed me by.", "order": 37877, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 5, "scene": 32, "line": "[plays soft chords and sings] Couldn't get outta bed today. Wish the alarm clock would go away.", "order": 37878, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 5, "scene": 32, "line": "Oh, nice!", "order": 37879, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 5, "scene": 32, "line": "Holy crap. Are you kidding me? You just made that-that's amazing!", "order": 37880, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 5, "scene": 32, "line": "Go ahead.", "order": 37881, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 5, "scene": 32, "line": "[singing] Which me am I gonna be today?", "order": 37882, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 5, "scene": 32, "line": "Which me am I gonna be today?", "order": 37883, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 5, "scene": 32, "line": "I gotta closet full of mes. Am I gonna be the happy me?", "order": 37884, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 5, "scene": 32, "line": "Or the me that stinks.", "order": 37885, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 5, "scene": 32, "line": "Oh, my gosh! We're almost out of time. How much for another half hour?", "order": 37886, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 5, "scene": 32, "line": "Oh, don't worry about it.", "order": 37887, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 5, "scene": 32, "line": "Wait. Seriously?", "order": 37888, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 5, "scene": 32, "line": "Yeah.", "order": 37889, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 5, "scene": 32, "line": "So we're just, like, jamming as friends?", "order": 37890, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 5, "scene": 32, "line": "One, two, three, hit it.", "order": 37891, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 5, "scene": 32, "line": "Closet full of mes-", "order": 37892, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 5, "scene": 33, "line": "Oh, you know...funny. Your wife and I went on a few dates.", "order": 37893, "speaker": "Danny Cordray"},
  {"season": 7, "episode": 5, "scene": 33, "line": "Did ya?", "order": 37894, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 5, "scene": 33, "line": "Yeah. Way ,way, way back.", "order": 37895, "speaker": "Danny Cordray"},
  {"season": 7, "episode": 5, "scene": 33, "line": "I'm just kidding. She told me about it.", "order": 37896, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 5, "scene": 33, "line": "Oh. She was not into me.", "order": 37897, "speaker": "Danny Cordray"},
  {"season": 7, "episode": 5, "scene": 33, "line": "Oh.", "order": 37898, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 5, "scene": 33, "line": "Obviously. I don't even think she called me back.", "order": 37899, "speaker": "Danny Cordray"},
  {"season": 7, "episode": 5, "scene": 33, "line": "You snubbed her.", "order": 37900, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 5, "scene": 33, "line": "Dwight, please.", "order": 37901, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 5, "scene": 33, "line": "Let me handle this, Jim. Drop the act, Cordray, okay? We all know that you probably thought that Pam was too 'meh' or thin without being toned. But I wanna tell you something-she is one of the plain hearty women of Scranton that make this city great. And so what if she doesn't wear makeup? [Pam mouthing 'I wear makeup'] We like her better that way! And you steal clients, don't you? Don't you!", "order": 37902, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 5, "scene": 33, "line": "Okay...that's different.", "order": 37903, "speaker": "Danny Cordray"},
  {"season": 7, "episode": 5, "scene": 33, "line": "Oh, that's different, is it? Okay...thief. You better check your things, people. In fact, where are my keys? Oh, there in my pocket. False alarm. Okay. [awkward pause] So...you're gonna be workin' here?", "order": 37904, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 5, "scene": 33, "line": "Uh...I mean-yeah.", "order": 37905, "speaker": "Danny Cordray"},
  {"season": 7, "episode": 5, "scene": 33, "line": "Welcome aboard.", "order": 37906, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 5, "scene": 33, "line": "Thank you.", "order": 37907, "speaker": "Danny Cordray"},
  {"season": 7, "episode": 5, "scene": 33, "line": "Hey, crazy, um...so...that's it? You're just-you're fine?", "order": 37908, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 5, "scene": 33, "line": "It's after 5:00, Jim. I'm not gonna take this home. [shakes Danny's hand]", "order": 37909, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 5, "scene": 33, "line": "Oh.", "order": 37910, "speaker": "Danny Cordray"},
  {"season": 7, "episode": 5, "scene": 33, "line": "Pleasure.", "order": 37911, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 5, "scene": 33, "line": "Thanks.", "order": 37912, "speaker": "Danny Cordray"},
  {"season": 7, "episode": 5, "scene": 34, "line": "This morning, Danny Cordray stole a sale from me. So what do I do? I go out and I steal Danny Cordray. The sale that mattered, I made. Boom. Funny thing about it, we don't even need him. We already have Packer on the road. Chhguuh! Crap. I forgot about Packer.", "order": 37913, "speaker": "Michael Scott"},
  {"season": 7, "episode": 5, "scene": 35, "line": "[singing and playing drums] Sun's in her eyes, tongue full of flies. Would you like to share my paaaaaaad?", "order": 37914, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 5, "scene": 35, "line": "Croak, croak, croak. Bullfrog in love. Croak, croak, croak. Bullfrog in love and I gave her the croak that meant I love you.", "order": 37915, "speaker": "All"},
  {"season": 7, "episode": 5, "scene": 35, "line": "I'll be your croak monsieur.", "order": 37916, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 5, "scene": 35, "line": "[falsetto] I'll be your croak madame.", "order": 37917, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 5, "scene": 35, "line": "Your mama mighta said that bullfrogs are dogs, but I'm here to tell you that I am a frog! Come and sit on my log, you little pollywog.", "order": 37918, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 5, "scene": 35, "line": "I find you absolutely ribbiting!", "order": 37919, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 5, "scene": 35, "line": "Croak, croak, croak. Bullfrog in love.", "order": 37920, "speaker": "All"},
  {"season": 7, "episode": 5, "scene": 35, "line": "Croak, croak, croak. Bullfrog in love.", "order": 37921, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 5, "scene": 35, "line": "Croak!", "order": 37922, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 5, "scene": 35, "line": "Ribbit!", "order": 37923, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 5, "scene": 35, "line": "Scoopity-splash!", "order": 37924, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 5, "scene": 35, "line": "Nice.", "order": 37925, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 6, "scene": 1, "line": "What about this one? It's kinda badass, right? Just seems kinda crazy in a way I might need right now.", "order": 37926, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 6, "scene": 1, "line": "I don't know. [looks at Stanley, who took his mug and is drinking out of it] Oh! That's... not... yours.", "order": 37927, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 6, "scene": 2, "line": "Stanley just drank OJ out of my mug, and didn't seem to realize that it wasn't his hot coffee. So the question has to be asked, is there no limit to what he won't notice?", "order": 37928, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 6, "scene": 3, "line": "What's a seven letter word for purse?", "order": 37929, "speaker": "Stanley Hudson"},
  {"season": 7, "episode": 6, "scene": 3, "line": "[sitting at Phyllis' desk, dressed like Phyllis; high pitched voice] Satchel!", "order": 37930, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 6, "scene": 3, "line": "Nope. Starts with an H.", "order": 37931, "speaker": "Stanley Hudson"},
  {"season": 7, "episode": 6, "scene": 3, "line": "[shirtless, wearing only a tie] Handbag.", "order": 37932, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 6, "scene": 3, "line": "Hmmm. [glances at Andy] Thank you.", "order": 37933, "speaker": "Stanley Hudson"},
  {"season": 7, "episode": 6, "scene": 4, "line": "Shh! Shhh! [watches Jim put a cardboard box over Stanley's monitor]", "order": 37934, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 6, "scene": 5, "line": "[standing outside the conference room, wearing fake teeth, watching Stanley walk in] All right, everybody, take a seat. As you may have heard, our branch on the planet Jupiter is up eight thousand percent in sales!", "order": 37935, "speaker": "Michael Scott"},
  {"season": 7, "episode": 6, "scene": 5, "line": "Yay! [applause]", "order": 37936, "speaker": "All"},
  {"season": 7, "episode": 6, "scene": 6, "line": "[looks at the clock, then at his watch] Hold up! That clock is slow. It is five o'clock, I will see you all tomorrow:", "order": 37937, "speaker": "Stanley Hudson"},
  {"season": 7, "episode": 6, "scene": 6, "line": "[turns around, is wearing a mustache] Bye, Stanley! Love you! [waves, Stanley leaves]", "order": 37938, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 6, "scene": 6, "line": "[standing next to a pony] So long, Stanley!", "order": 37939, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 6, "scene": 6, "line": "Night, everybody.", "order": 37940, "speaker": "Stanley Hudson"},
  {"season": 7, "episode": 6, "scene": 7, "line": "So what kind of statement are you making with that costume, Kevin?", "order": 37941, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 6, "scene": 7, "line": "The statement that I am making, Oscar, is that I kind of look like Michael Moore.", "order": 37942, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 6, "scene": 8, "line": "Dunder Mifflin, this is Erin! Happy Halloween! How can I haunt you today?", "order": 37943, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 6, "scene": 9, "line": "A lot of people are really getting into Halloween this year! [puts head down over fake bomb made from cardboard, high pitched voice] Six seconds, MacGruber! [lifts head] Pam's got a lot of fun stuff planned! [puts head down, high pitched voice] Uh, two seconds MacGruber! [lifts head] Including a... costume contest, and bobbing for apples, and a Ouija board... OHHH! BOOM! OHH EXPLOSION! [throws fake bomb, takes off sunglasses, points to camera] MACGRUBERRRR!", "order": 37944, "speaker": "Michael Scott"},
  {"season": 7, "episode": 6, "scene": 10, "line": "People are really into the costume contest this year. Might have something to do with the prize, maybe you've heard of it. The 2011 Scranton Wilkes-Barre coupon book worth over fifteen thousand dollars in savings!", "order": 37945, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 6, "scene": 11, "line": "Stop.", "order": 37946, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 6, "scene": 11, "line": "[has his hands around Jim's neck] Too late! If I was the real Scranton Strangler, you'd be so strangled right now. If you're out there, strangler, you will get caught! By me.", "order": 37947, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 6, "scene": 11, "line": "Sounds like someone's really trying to convince us that he's not the Scranton Strangler.", "order": 37948, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 6, "scene": 11, "line": "To my chickens I'm the Scranton Strangler. [looks at Pam] Oh-ho! That's very funny. Looks like someone decided to dress up as old Dwight Schrute's mom.", "order": 37949, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 6, "scene": 11, "line": "What?", "order": 37950, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 6, "scene": 11, "line": "You're only one third as beautiful and only half her height.", "order": 37951, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 6, "scene": 11, "line": "I'm supposed to be Olive Oyl. And it makes more sense when I'm standing next to Popeye, but... Jim doesn't want to put his costume on.", "order": 37952, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 6, "scene": 11, "line": "[places pipe in mouth] I am Popeye!", "order": 37953, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 6, "scene": 12, "line": "I've never really been a costume guy. Even when I was a kid, it just felt like something I was too old for. And then this morning, when Pam hands me this little number [holds up Popeye costume, shakes head] ...no.", "order": 37954, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 6, "scene": 13, "line": "[dressed as Lady Gaga] In case you can't read m-m-my Poker Face... [laughs] we will be reviewing our sales policies. [accepts note from Michael, reads it out loud] I have ten seconds to read them or this whole place blows up.", "order": 37955, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 6, "scene": 13, "line": "MACGRUBER!", "order": 37956, "speaker": "Michael Scott"},
  {"season": 7, "episode": 6, "scene": 13, "line": "Lame. Why don't witches wear panties?", "order": 37957, "speaker": "Todd Packer"},
  {"season": 7, "episode": 6, "scene": 13, "line": "Oh, here we go!", "order": 37958, "speaker": "Michael Scott"},
  {"season": 7, "episode": 6, "scene": 13, "line": "Because they need to grip the broom!", "order": 37959, "speaker": "Todd Packer"},
  {"season": 7, "episode": 6, "scene": 13, "line": "[laughs] OH! Who likes to water ski on Lake Erie? No wai-where does Dracula like to water ski?", "order": 37960, "speaker": "Michael Scott"},
  {"season": 7, "episode": 6, "scene": 13, "line": "Lake Erie.", "order": 37961, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 6, "scene": 14, "line": "When our warehouse workers make deliveries, they're going to be encouraged to offer clients extra products. And then they will split those commissions with sales.", "order": 37962, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 6, "scene": 14, "line": "So, wait, drivers are going to be able to sell paper on the road?", "order": 37963, "speaker": "Michael Scott"},
  {"season": 7, "episode": 6, "scene": 14, "line": "That is correct.", "order": 37964, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 6, "scene": 14, "line": "Has anyone started calling you 'Gabe-wad' yet?", "order": 37965, "speaker": "Todd Packer"},
  {"season": 7, "episode": 6, "scene": 14, "line": "Not here.", "order": 37966, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 6, "scene": 14, "line": "Gabe-wad.", "order": 37967, "speaker": "Danny Cordray"},
  {"season": 7, "episode": 6, "scene": 14, "line": "Okay, guys, fun is fun, but-", "order": 37968, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 6, "scene": 15, "line": "Blackula!", "order": 37969, "speaker": "Michael Scott"},
  {"season": 7, "episode": 6, "scene": 15, "line": "Dracula.", "order": 37970, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 6, "scene": 15, "line": "Oh, so... I almost forgot. You'll find this hilarious. Apparently corporate is going to have drivers sell paper on their routes now. That's like, exactly sort of the idea that you had.", "order": 37971, "speaker": "Michael Scott"},
  {"season": 7, "episode": 6, "scene": 15, "line": "Yes. I do remember saying something like that to you.", "order": 37972, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 6, "scene": 15, "line": "Yeah. I'm sorry. I blew this. You should have gotten credit for that, man.", "order": 37973, "speaker": "Michael Scott"},
  {"season": 7, "episode": 6, "scene": 15, "line": "I'm just glad we're gonna try it out.", "order": 37974, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 6, "scene": 15, "line": "Really? We're good?", "order": 37975, "speaker": "Michael Scott"},
  {"season": 7, "episode": 6, "scene": 15, "line": "Yeah. We're cool.", "order": 37976, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 6, "scene": 15, "line": "Okay. [reaches out and shakes Darryl's hand] Okay.", "order": 37977, "speaker": "Michael Scott"},
  {"season": 7, "episode": 6, "scene": 15, "line": "What's under your shirt?", "order": 37978, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 6, "scene": 15, "line": "Oh. It's a ream of paper. Thought you might hit me.", "order": 37979, "speaker": "Michael Scott"},
  {"season": 7, "episode": 6, "scene": 16, "line": "Darryl Philbin is the greatest guy in the world. And you know what I'd like? I would like to have all the racists brought together and take Darryl Philbin out to lunch. Just to see what they're missing.", "order": 37980, "speaker": "Michael Scott"},
  {"season": 7, "episode": 6, "scene": 17, "line": "[leans hobo sack against the refrigerator] It's a great stick, right?", "order": 37981, "speaker": "Toby Flenderson"},
  {"season": 7, "episode": 6, "scene": 17, "line": "It's really good. It's a classic. I think you might win the whole thing with that.", "order": 37982, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 6, "scene": 17, "line": "Thanks!", "order": 37983, "speaker": "Toby Flenderson"},
  {"season": 7, "episode": 6, "scene": 17, "line": "Everyone realizes that this coupon book isn't actually worth fifteen thousand dollars, right? You'd have to spend two hundred thousand dollars on crap you don't actually need to get fifteen thousand dollars worth of benefits. I'm not the only one who sees this, right?", "order": 37984, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 6, "scene": 17, "line": "No.", "order": 37985, "speaker": "Toby Flenderson"},
  {"season": 7, "episode": 6, "scene": 17, "line": "No.", "order": 37986, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 6, "scene": 17, "line": "I get that. I get that.", "order": 37987, "speaker": "Kelly Kapoor"},
  {"season": 7, "episode": 6, "scene": 18, "line": "Excuse me, everybody. I want to invite you all to the Halloween party I'm having at my bar.", "order": 37988, "speaker": "Danny Cordray"},
  {"season": 7, "episode": 6, "scene": 18, "line": "You own a bar?", "order": 37989, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 6, "scene": 18, "line": "Public School, at exit 11.", "order": 37990, "speaker": "Danny Cordray"},
  {"season": 7, "episode": 6, "scene": 18, "line": "That's a great name. You're hilarious. A plus!", "order": 37991, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 6, "scene": 18, "line": "So. You're all on the list!", "order": 37992, "speaker": "Danny Cordray"},
  {"season": 7, "episode": 6, "scene": 18, "line": "Hey, man, can I get a plus five? It's all guys.", "order": 37993, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 6, "scene": 18, "line": "Hey, what's the crowd like, Danny? Our age?", "order": 37994, "speaker": "Creed Bratton"},
  {"season": 7, "episode": 6, "scene": 18, "line": "Okay.", "order": 37995, "speaker": "Danny Cordray"},
  {"season": 7, "episode": 6, "scene": 19, "line": "I don't think that she would leave Jim for Danny.", "order": 37996, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 6, "scene": 19, "line": "I don't know, they're both handsome.", "order": 37997, "speaker": "Phyllis Vance"},
  {"season": 7, "episode": 6, "scene": 19, "line": "Pam is gonna choose whoever has a scent most like that of her father. Does anyone remember what her dad smelled like?", "order": 37998, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 6, "scene": 19, "line": "I think he-", "order": 37999, "speaker": "Phyllis Vance"},
  {"season": 7, "episode": 6, "scene": 19, "line": "Hey hey hey, quiet, here she comes! Did you hear about that Danny guy? Heard he used to date Pam.", "order": 38000, "speaker": "Creed Bratton"},
  {"season": 7, "episode": 6, "scene": 20, "line": "So four years ago, when I was in Stamford, Connecticut, and dating someone else, Pam went on two dates with Danny. Which was obviously the greatest love story ever told, given how much people are walking on eggshells around us.", "order": 38001, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 6, "scene": 20, "line": "We were basically Romeo and Juliet.", "order": 38002, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 6, "scene": 20, "line": "That's right.", "order": 38003, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 6, "scene": 20, "line": "Except where Juliet doesn't have that great a time and Romeo doesn't call back after two dates.", "order": 38004, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 6, "scene": 20, "line": "Yikes.", "order": 38005, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 6, "scene": 20, "line": "But I've learned to love again. [puts her arm on Jim's shoulder] He's a cartoon sailor.", "order": 38006, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 6, "scene": 20, "line": "Oh, no.", "order": 38007, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 6, "scene": 20, "line": "And looks so handsome in his UNIFORM! Please?", "order": 38008, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 6, "scene": 20, "line": "No. No, I'm not gonna-no.", "order": 38009, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 6, "scene": 21, "line": "Tuna! Tuna! Do you want us to skip this party?", "order": 38010, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 6, "scene": 21, "line": "I don't care.", "order": 38011, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 6, "scene": 22, "line": "I am never gonna forget what Danny did to Pam. I forget nothing. I'm like an elephant in that way.", "order": 38012, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 6, "scene": 22, "line": "You know what else? [pulling fake teeth out of his mouth] The-this... this sucks for Jim. Right? But it also sucks for us. Because we don't get invited to a ton of Halloween parties. [puts teeth back in]", "order": 38013, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 6, "scene": 22, "line": "Yeah, and everyone else is gonna be there. Stanley, Phyllis, Angela, Darryl... Creed's a maybe.", "order": 38014, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 6, "scene": 22, "line": "[removes teeth] Creed's going?!", "order": 38015, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 6, "scene": 23, "line": "Madge from the warehouse just made her first sale. Madge. We should have been doing this a long time ago.", "order": 38016, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 6, "scene": 24, "line": "Erin! Would you please do me a solid and get Darryl Philbin on the speakerphone?", "order": 38017, "speaker": "Michael Scott"},
  {"season": 7, "episode": 6, "scene": 24, "line": "[answers phone] Hello?", "order": 38018, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 6, "scene": 24, "line": "Hi, Darryl, come out here, please.", "order": 38019, "speaker": "Michael Scott"},
  {"season": 7, "episode": 6, "scene": 24, "line": "I'm good.", "order": 38020, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 6, "scene": 24, "line": "You need to stop being so shy, come out here, and embrace who you really are, superstar!", "order": 38021, "speaker": "Michael Scott"},
  {"season": 7, "episode": 6, "scene": 24, "line": "This is embarrassing, Michael.", "order": 38022, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 6, "scene": 24, "line": "Yeah, I bet it is! If you're gonna do your job well here, you gotta get used to being embarrassed.", "order": 38023, "speaker": "Michael Scott"},
  {"season": 7, "episode": 6, "scene": 24, "line": "Let me put my shoes on. [hangs up phone, dial tone]", "order": 38024, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 6, "scene": 24, "line": "[leans over toward Erin] Um, hit the speakerphone button. The speakerphone button? The same button as you hit before... or sit on it.", "order": 38025, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 6, "scene": 24, "line": "This whole delivery slash sales idea? You know whose idea that was? That was Darryl Philbin's. He thought of that way before the corporate fat suits.", "order": 38026, "speaker": "Michael Scott"},
  {"season": 7, "episode": 6, "scene": 24, "line": "So, what happened?", "order": 38027, "speaker": "Angela Martin"},
  {"season": 7, "episode": 6, "scene": 24, "line": "I got in the way. I said no. And it just stopped. But then corporate comes up with this idea, but you know what? They need to know that it was yours. And I don't care if I take a bullet. We're gonna call them, we're gonna put them on speakerphone right now...", "order": 38028, "speaker": "Michael Scott"},
  {"season": 7, "episode": 6, "scene": 24, "line": "Oh! [fumbles for phone]", "order": 38029, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 6, "scene": 24, "line": "And we are going to straighten this out.", "order": 38030, "speaker": "Michael Scott"},
  {"season": 7, "episode": 6, "scene": 24, "line": "Screw corporate! They probably stole the idea anyway.", "order": 38031, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 6, "scene": 24, "line": "Mmhm!", "order": 38032, "speaker": "Michael Scott"},
  {"season": 7, "episode": 6, "scene": 24, "line": "Uh uh. Corporate stole nothing, okay? Darryl told me, and then I told them, giving Darryl full credit, so... no need to [mimicking Andy] screw corporate! Or anything like that, so. Let's give Darryl a round of applause, as planned. [claps]", "order": 38033, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 6, "scene": 25, "line": "I'm a little peeved at Darryl right now.", "order": 38034, "speaker": "Michael Scott"},
  {"season": 7, "episode": 6, "scene": 25, "line": "He went to Gabe behind your back.", "order": 38035, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 6, "scene": 25, "line": "No, he didn't go behind my back. He went over my head.", "order": 38036, "speaker": "Michael Scott"},
  {"season": 7, "episode": 6, "scene": 25, "line": "He went over your head to go behind your back.", "order": 38037, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 6, "scene": 25, "line": "What is taking someone from behind?", "order": 38038, "speaker": "Michael Scott"},
  {"season": 7, "episode": 6, "scene": 25, "line": "No. Shh. Michael, listen. This cannot stand. We can't have workers going straight to corporate. Makes your job superfluous.", "order": 38039, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 6, "scene": 25, "line": "It was a good idea, though.", "order": 38040, "speaker": "Michael Scott"},
  {"season": 7, "episode": 6, "scene": 25, "line": "Yeah. Here's another good idea. Corporate chain of command. We need to strangle Darryl's idea.", "order": 38041, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 6, "scene": 26, "line": "Hey, Danny.", "order": 38042, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 6, "scene": 26, "line": "Hey.", "order": 38043, "speaker": "Danny Cordray"},
  {"season": 7, "episode": 6, "scene": 26, "line": "I'm really sorry, but we can't come to your party tonight.", "order": 38044, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 6, "scene": 26, "line": "I was looking forward to throwing some darts with you guys.", "order": 38045, "speaker": "Danny Cordray"},
  {"season": 7, "episode": 6, "scene": 26, "line": "You were?", "order": 38046, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 6, "scene": 26, "line": "Okay, well then, uh, I mean, maybe we could do it like... next Halloween.", "order": 38047, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 6, "scene": 26, "line": "For sure.", "order": 38048, "speaker": "Danny Cordray"},
  {"season": 7, "episode": 6, "scene": 26, "line": "We're just like, totally caught in the middle here.", "order": 38049, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 6, "scene": 26, "line": "What are you talking about?", "order": 38050, "speaker": "Danny Cordray"},
  {"season": 7, "episode": 6, "scene": 26, "line": "Well Jim and Pam basically begged us not to go...", "order": 38051, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 6, "scene": 26, "line": "Jim and Pam really don't want you to go?", "order": 38052, "speaker": "Danny Cordray"},
  {"season": 7, "episode": 6, "scene": 26, "line": "They're really upset about the whole Danny situation.", "order": 38053, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 6, "scene": 26, "line": "I'll talk to them.", "order": 38054, "speaker": "Danny Cordray"},
  {"season": 7, "episode": 6, "scene": 26, "line": "Yeah, but wait, don't tell them that we said anything to you.", "order": 38055, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 6, "scene": 26, "line": "Okay.", "order": 38056, "speaker": "Danny Cordray"},
  {"season": 7, "episode": 6, "scene": 26, "line": "Or you're dead.", "order": 38057, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 6, "scene": 26, "line": "Okay.", "order": 38058, "speaker": "Danny Cordray"},
  {"season": 7, "episode": 6, "scene": 26, "line": "Okay.", "order": 38059, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 6, "scene": 27, "line": "Kelly! Great costume!", "order": 38060, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 6, "scene": 27, "line": "Oh.", "order": 38061, "speaker": "Toby Flenderson"},
  {"season": 7, "episode": 6, "scene": 27, "line": "KELLY! You cannot change costumes in the middle of the day! Pam, she's out.", "order": 38062, "speaker": "Angela Martin"},
  {"season": 7, "episode": 6, "scene": 27, "line": "Um, if I'm out, I'm going to sue this ENTIRE COMPANY for discrimination.", "order": 38063, "speaker": "Kelly Kapoor"},
  {"season": 7, "episode": 6, "scene": 27, "line": "Guys? You're arguing over a one in sixteen chance. Over a prize worth... forty bucks.", "order": 38064, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 6, "scene": 27, "line": "Um, fifteen thousand bucks, Oscar.", "order": 38065, "speaker": "Kelly Kapoor"},
  {"season": 7, "episode": 6, "scene": 27, "line": "Yeah, shut it, Oscar. Pam?", "order": 38066, "speaker": "Angela Martin"},
  {"season": 7, "episode": 6, "scene": 28, "line": "This is an amazing prize. I mean, I don't even want to give Pam a compliment, because she's so blegh, but she did a good job. I really want that coupon book.", "order": 38067, "speaker": "Angela Martin"},
  {"season": 7, "episode": 6, "scene": 29, "line": "Garbage magnet. [throws magnet] Garbage magnet, God! Magnets are interesting enough, you don't need to tart them up with some design. [to Michael] I can't believe this doesn't make you mad!", "order": 38068, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 6, "scene": 29, "line": "[staring at Gabe and Kevin] What the hell are they talking about?", "order": 38069, "speaker": "Michael Scott"},
  {"season": 7, "episode": 6, "scene": 29, "line": "Hmm, Kevin and Gabe. Probably about the extremes of the human physique.", "order": 38070, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 6, "scene": 29, "line": "Do you think that Kevin is going over my head? I don't-[sees Kevin and Gabe fist-bump] Oh my GOD. Okay. All right. All right. You know what? That's inappropriate, Kevin. I am your boss, and if you have something to say, it goes through me, and then I take it to Gabe. Chain of command. Do you understand?", "order": 38071, "speaker": "Michael Scott"},
  {"season": 7, "episode": 6, "scene": 29, "line": "I am so sorry.", "order": 38072, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 6, "scene": 29, "line": "Oh, you're sorry?", "order": 38073, "speaker": "Michael Scott"},
  {"season": 7, "episode": 6, "scene": 29, "line": "I just thought-", "order": 38074, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 6, "scene": 29, "line": "What did you just thought?", "order": 38075, "speaker": "Michael Scott"},
  {"season": 7, "episode": 6, "scene": 29, "line": "Well, Gabe asked me if there were any really cool Lady Gaga moves that he could do for the catwalk. And so then I tol-[tries not to cry]", "order": 38076, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 6, "scene": 29, "line": "All right.", "order": 38077, "speaker": "Michael Scott"},
  {"season": 7, "episode": 6, "scene": 29, "line": "I told him that there was this one cool move where she powers down like a robot.", "order": 38078, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 6, "scene": 29, "line": "Okay.", "order": 38079, "speaker": "Michael Scott"},
  {"season": 7, "episode": 6, "scene": 29, "line": "Okay? But I- I am so sorry that I didn't tell you first.", "order": 38080, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 6, "scene": 29, "line": "Well, don't let it happen again.", "order": 38081, "speaker": "Michael Scott"},
  {"season": 7, "episode": 6, "scene": 29, "line": "[hysterical] You think that I would let this happen again?! NO WAY JOSE.", "order": 38082, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 6, "scene": 29, "line": "I-", "order": 38083, "speaker": "Michael Scott"},
  {"season": 7, "episode": 6, "scene": 29, "line": "[bleep] you, Gabe!", "order": 38084, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 6, "scene": 29, "line": "Okay...", "order": 38085, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 6, "scene": 30, "line": "Can you imagine? It's just crazy.", "order": 38086, "speaker": "Danny Cordray"},
  {"season": 7, "episode": 6, "scene": 30, "line": "That is crazy. We would never care if Andy or Kevin went to your party.", "order": 38087, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 6, "scene": 30, "line": "Look, I'm just glad we can laugh about it. Because I was a little nervous about coming to work here, with, you know, our history.", "order": 38088, "speaker": "Danny Cordray"},
  {"season": 7, "episode": 6, "scene": 30, "line": "Oh, my gosh! People keep blowing it out of proportion! It's not even a history.", "order": 38089, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 6, "scene": 30, "line": "Exactly.", "order": 38090, "speaker": "Danny Cordray"},
  {"season": 7, "episode": 6, "scene": 30, "line": "It's not like you guys had some long relationship right? Big painful breakup I don't know about?", "order": 38091, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 6, "scene": 30, "line": "No! Two or three dates.", "order": 38092, "speaker": "Danny Cordray"},
  {"season": 7, "episode": 6, "scene": 30, "line": "It was two.", "order": 38093, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 6, "scene": 30, "line": "Was it two? I thought it was three.", "order": 38094, "speaker": "Danny Cordray"},
  {"season": 7, "episode": 6, "scene": 30, "line": "No, we um, we had plans for a third, but then I don't know, you never called me back, so...", "order": 38095, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 6, "scene": 30, "line": "Oooh! You can't handle the truth! [laughs]", "order": 38096, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 6, "scene": 30, "line": "[laughing] Well, that does not sound like me.", "order": 38097, "speaker": "Danny Cordray"},
  {"season": 7, "episode": 6, "scene": 30, "line": "Yeah? [stops laughing] It was though, that's what happened.", "order": 38098, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 6, "scene": 30, "line": "[stops laughing] Well, great, I just wanted to make sure that things weren't weird.", "order": 38099, "speaker": "Danny Cordray"},
  {"season": 7, "episode": 6, "scene": 30, "line": "Hmm-mm.", "order": 38100, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 6, "scene": 31, "line": "Okay, this whole going over my head-gate? Is making people act weird. The chain of command is crumbling. Do you know what just happened? I just made Kevin cry. And Gabe looks like Lady Gaga. That's not Halloween. Halloween should be a day in which we honor monsters and not be mad at each other.", "order": 38101, "speaker": "Michael Scott"},
  {"season": 7, "episode": 6, "scene": 31, "line": "I'm not mad. Are you mad?", "order": 38102, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 6, "scene": 31, "line": "You went over my head. And then you lied to my face. So my head and my face have taken a beating.", "order": 38103, "speaker": "Michael Scott"},
  {"season": 7, "episode": 6, "scene": 31, "line": "Well, I'm sorry if it seems that way to you.", "order": 38104, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 6, "scene": 31, "line": "Okay, that might help. If you said 'I'm sorry' in front of everybody.", "order": 38105, "speaker": "Michael Scott"},
  {"season": 7, "episode": 6, "scene": 31, "line": "Mike.", "order": 38106, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 6, "scene": 31, "line": "In front of me.", "order": 38107, "speaker": "Michael Scott"},
  {"season": 7, "episode": 6, "scene": 31, "line": "You made a bad call. And I fixed it. So I'm not apologizing.", "order": 38108, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 6, "scene": 31, "line": "So that's it.", "order": 38109, "speaker": "Michael Scott"},
  {"season": 7, "episode": 6, "scene": 31, "line": "That's it.", "order": 38110, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 6, "scene": 31, "line": "Is it?", "order": 38111, "speaker": "Michael Scott"},
  {"season": 7, "episode": 6, "scene": 31, "line": "Yes.", "order": 38112, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 6, "scene": 32, "line": "Sookehhh. [removes teeth] Bill Compton, from True Blood.", "order": 38113, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 6, "scene": 32, "line": "How many freakin' vampires am I supposed to care about these days?", "order": 38114, "speaker": "Stanley Hudson"},
  {"season": 7, "episode": 6, "scene": 33, "line": "I guess you could say I'm still in costume. I'm a rational consumer.", "order": 38115, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 6, "scene": 33, "line": "Oh.", "order": 38116, "speaker": "Phyllis Vance"},
  {"season": 7, "episode": 6, "scene": 33, "line": "Stupid coupon booklet. Have you seen my costume? I'm a rational consumer.", "order": 38117, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 6, "scene": 33, "line": "Yeah, I uh, heard you say it to Phyllis. That's a good line.", "order": 38118, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 6, "scene": 33, "line": "Okay, everybody! After you walk the runway everyone has to vote for who gets the coupon book. And you can't vote for yourself.", "order": 38119, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 6, "scene": 33, "line": "Pam. Can you vote for other people?", "order": 38120, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 6, "scene": 33, "line": "Yeah, I gotta get in on this. [mocking Darryl] Hey, it's cool, man, I work in the warehouse! I'm cool! I'm hip and I'm jive! And I don't care about nobody! Do you know who I am? Happy Halloween, jerk!", "order": 38121, "speaker": "Michael Scott"},
  {"season": 7, "episode": 6, "scene": 34, "line": "Still don't know who I am? I'll give you a hint. I go over other people's heads.", "order": 38122, "speaker": "Michael Scott"},
  {"season": 7, "episode": 6, "scene": 34, "line": "Michael, this is a bad idea.", "order": 38123, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 6, "scene": 34, "line": "What's a bad idea?", "order": 38124, "speaker": "Michael Scott"},
  {"season": 7, "episode": 6, "scene": 34, "line": "Dressing up as somebody-I mean, when has that ever worked for you?", "order": 38125, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 6, "scene": 34, "line": "NEVER! [tosses wig] Okay, you know what? Fine. I'm not Darryl. And thank God I'm not Darryl.", "order": 38126, "speaker": "Michael Scott"},
  {"season": 7, "episode": 6, "scene": 34, "line": "Could you for once just let us enjoy a party instead of making it about all your issues?", "order": 38127, "speaker": "Kelly Kapoor"},
  {"season": 7, "episode": 6, "scene": 35, "line": "[walking down the runway] Raaah! [waves sword]", "order": 38128, "speaker": "Stanley Hudson"},
  {"season": 7, "episode": 6, "scene": 36, "line": "I present to you the [finger quotes] rational consumer, as it were.", "order": 38129, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 6, "scene": 37, "line": "Angela as the nurse!", "order": 38130, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 6, "scene": 38, "line": "I don't like your tone! Look, they were sold out of all the other costumes, okay? I think we all live in the real world, here. Let's not pretend to be unaware of what sells in this office.", "order": 38131, "speaker": "Angela Martin"},
  {"season": 7, "episode": 6, "scene": 39, "line": "What should we ask?", "order": 38132, "speaker": "Phyllis Vance"},
  {"season": 7, "episode": 6, "scene": 39, "line": "Hey, can I play? Why don't you ask if Darryl is a bad friend who backstabs people in the back?", "order": 38133, "speaker": "Michael Scott"},
  {"season": 7, "episode": 6, "scene": 39, "line": "We have one question to ask the spirit world and you want us to ask that?", "order": 38134, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 6, "scene": 39, "line": "He says no.", "order": 38135, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 6, "scene": 39, "line": "Aaah! Darryl moved it. You moved it.", "order": 38136, "speaker": "Michael Scott"},
  {"season": 7, "episode": 6, "scene": 39, "line": "No.", "order": 38137, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 6, "scene": 39, "line": "Dwight, you saw Darryl move it, right?", "order": 38138, "speaker": "Michael Scott"},
  {"season": 7, "episode": 6, "scene": 39, "line": "Let's just say I saw exactly what the two of you wanted me to see.", "order": 38139, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 6, "scene": 40, "line": "I know how to sit on a fence. Hell, I can even sleep on a fence. The trick is to do it face down with the post in your mouth.", "order": 38140, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 6, "scene": 41, "line": "If you had one word you would use to describe Darryl what would that word be?", "order": 38141, "speaker": "Michael Scott"},
  {"season": 7, "episode": 6, "scene": 41, "line": "[hands are being forced to the letters] A... S... S... H... E... T?", "order": 38142, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 6, "scene": 41, "line": "An asset!", "order": 38143, "speaker": "Phyllis Vance"},
  {"season": 7, "episode": 6, "scene": 41, "line": "Ah! [lets go, candy corn flies everywhere] That's enough.", "order": 38144, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 6, "scene": 42, "line": "I don't think it's gonna work out the way you think.", "order": 38145, "speaker": "Danny Cordray"},
  {"season": 7, "episode": 6, "scene": 42, "line": "I don't think you get it.", "order": 38146, "speaker": "Todd Packer"},
  {"season": 7, "episode": 6, "scene": 42, "line": "Hey.", "order": 38147, "speaker": "Danny Cordray"},
  {"season": 7, "episode": 6, "scene": 42, "line": "Hey.", "order": 38148, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 6, "scene": 42, "line": "This is some party, huh?", "order": 38149, "speaker": "Danny Cordray"},
  {"season": 7, "episode": 6, "scene": 42, "line": "When you work hard, you play hard around here. Even if you don't work hard. Oh, here's something. Uh, why didn't you ever call Pam back?", "order": 38150, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 6, "scene": 42, "line": "Are you serious?", "order": 38151, "speaker": "Danny Cordray"},
  {"season": 7, "episode": 6, "scene": 42, "line": "Yeah. I mean, I'm not saying that everyone has to fall in love, or anything, I'm just saying... but you know, to not even call her back is...", "order": 38152, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 6, "scene": 42, "line": "You know what it was? I think she gave me her number but then her fours look like eights, and...", "order": 38153, "speaker": "Danny Cordray"},
  {"season": 7, "episode": 6, "scene": 42, "line": "Could be. But you also called her the second time so you had the number right.", "order": 38154, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 6, "scene": 42, "line": "Halpert, you lookin' for someone to bang your wife?", "order": 38155, "speaker": "Todd Packer"},
  {"season": 7, "episode": 6, "scene": 42, "line": "Nope.", "order": 38156, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 6, "scene": 42, "line": "Okay. You wanna know? I didn't call her back because she spent the whole date talking about you. She was obviously in love with you.", "order": 38157, "speaker": "Danny Cordray"},
  {"season": 7, "episode": 6, "scene": 43, "line": "Fifty seven. Fifty eight.", "order": 38158, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 6, "scene": 43, "line": "Wait, wait. She hasn't moved in awhile.", "order": 38159, "speaker": "Meredith Palmer"},
  {"season": 7, "episode": 6, "scene": 43, "line": "[reaches over to pull Erin out of apple bobbing water] ERIN!", "order": 38160, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 6, "scene": 43, "line": "Two! I got two. I ate two whole apples.", "order": 38161, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 6, "scene": 44, "line": "I'd remember talking about Jim. That wasn't it.", "order": 38162, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 6, "scene": 44, "line": "Just tell her the real reason.", "order": 38163, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 6, "scene": 44, "line": "Do you honestly want to know why I didn't call her back on a date over four years ago?", "order": 38164, "speaker": "Danny Cordray"},
  {"season": 7, "episode": 6, "scene": 44, "line": "Hey. She had a nice time. It seems rude.", "order": 38165, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 6, "scene": 44, "line": "I did. And it's just one of those things that's going to keep gnawing at me, like 'gnaw, gnaw, why? Why didn't he? I have no idea why.'", "order": 38166, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 6, "scene": 44, "line": "Okay. Honestly. I didn't call you back because I-thought you seemed a little... dorky.", "order": 38167, "speaker": "Danny Cordray"},
  {"season": 7, "episode": 6, "scene": 44, "line": "Hey, man.", "order": 38168, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 6, "scene": 44, "line": "Thank you! Thank you. I got it. Now I know. You thought I was a little dorky. You know? [gibberish sounds] Okay. Well, excuuuuse me.", "order": 38169, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 6, "scene": 45, "line": "In the future, you need to get Michael's approval on anything before coming to me.", "order": 38170, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 6, "scene": 45, "line": "Thank you.", "order": 38171, "speaker": "Michael Scott"},
  {"season": 7, "episode": 6, "scene": 45, "line": "You're gonna be missing out on some good ideas.", "order": 38172, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 6, "scene": 45, "line": "Okay, well, obviously if it's a really, really good idea, my door is open.", "order": 38173, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 6, "scene": 45, "line": "If they're good ideas, I'm not gonna say no!", "order": 38174, "speaker": "Michael Scott"},
  {"season": 7, "episode": 6, "scene": 45, "line": "You said no to this one!", "order": 38175, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 6, "scene": 45, "line": "That was-okay, you make one mistake in fifteen years and you drag me over the coals, after everything I did for you?", "order": 38176, "speaker": "Michael Scott"},
  {"season": 7, "episode": 6, "scene": 45, "line": "What have you done for me?", "order": 38177, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 6, "scene": 45, "line": "Oh...", "order": 38178, "speaker": "Michael Scott"},
  {"season": 7, "episode": 6, "scene": 45, "line": "What have you done for me?", "order": 38179, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 6, "scene": 45, "line": "Well...", "order": 38180, "speaker": "Michael Scott"},
  {"season": 7, "episode": 6, "scene": 45, "line": "Ed Truck hired me. Jo promoted me. Gabe listened to me. All you've ever done is say no to me. I have ambition. And you kept me at the same level for years.", "order": 38181, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 6, "scene": 45, "line": "Ohh. Dropping bombs, right? [explosion noise] This really make you think, Michael?", "order": 38182, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 6, "scene": 45, "line": "Stop it! Stop, stop. We're thinking. We're thinking about it.", "order": 38183, "speaker": "Michael Scott"},
  {"season": 7, "episode": 6, "scene": 45, "line": "Yeah.", "order": 38184, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 6, "scene": 45, "line": "You don't have to point to the fact that we're thinking about it. Stop it! Just let us think. Okay, next time you have a really great idea, we will put it in a hat, and then we will have Erin pick it out of the hat and let her decide.", "order": 38185, "speaker": "Michael Scott"},
  {"season": 7, "episode": 6, "scene": 45, "line": "I don't understand the point of a hat.", "order": 38186, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 6, "scene": 45, "line": "You're right, we don't need a hat.", "order": 38187, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 6, "scene": 45, "line": "I am not budging on the hat issue.", "order": 38188, "speaker": "Michael Scott"},
  {"season": 7, "episode": 6, "scene": 45, "line": "Okay. We're going to table the hat question. The best ideas are going to come to me, I make the final decision, period.", "order": 38189, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 6, "scene": 45, "line": "Okay. We both reserve the right to go to Jo if we disagree with Gabe.", "order": 38190, "speaker": "Michael Scott"},
  {"season": 7, "episode": 6, "scene": 45, "line": "Okay, why don't we simplify this? Darryl brings it to Michael, Michael brings it to me, no one calls Jo.", "order": 38191, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 6, "scene": 45, "line": "Unless you and I decide we want to talk to Jo, then we'll give her a call.", "order": 38192, "speaker": "Michael Scott"},
  {"season": 7, "episode": 6, "scene": 45, "line": "Cool. Okay. Sorry I lied.", "order": 38193, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 6, "scene": 45, "line": "Sorry I was a jerk.", "order": 38194, "speaker": "Michael Scott"},
  {"season": 7, "episode": 6, "scene": 46, "line": "Friends fight. Friends fight.", "order": 38195, "speaker": "Michael Scott"},
  {"season": 7, "episode": 6, "scene": 47, "line": "What's up, man?", "order": 38196, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 6, "scene": 47, "line": "Sometimes it seems like it was better down in the warehouse, you know?", "order": 38197, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 6, "scene": 47, "line": "When I was a freshman in college, I worked at the student newspaper, the Cornell Daily Sun? This was at Cornell. I had to write an op-ed column every day. Bernard's Regards.", "order": 38198, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 6, "scene": 47, "line": "This was your freshman year.", "order": 38199, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 6, "scene": 47, "line": "I started to ask myself, 'Do I have big plans here?' I didn't want to become editor of that paper, so I got up and walked right out of Walter Bernard hall, and that's actually when I heard eight male voices, singing, unencumbered by instruments. I was hooked. So is becoming CEO of this company your a capella group? Come on, we're going to Danny's bar. Public School.", "order": 38200, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 6, "scene": 47, "line": "No. I got some work to do. I do got big plans with this company.", "order": 38201, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 6, "scene": 48, "line": "To be honest, I still can't believe he didn't call her back. Who doesn't call a dork like that back?", "order": 38202, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 6, "scene": 49, "line": "Oh, wow!", "order": 38203, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 6, "scene": 49, "line": "[holding Cece] Spinach in a can. Power eat spinach. [Popeye sound]", "order": 38204, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 6, "scene": 49, "line": "Aww, my hero!", "order": 38205, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 6, "scene": 50, "line": "Okay, everyone, I've tallied the votes, and the winner... of the costume celebration spectacular... and the Scranton Wilkes-Barre coupon book... Oscar Martinez.", "order": 38206, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 6, "scene": 51, "line": "If I have to vote for someone, I don't want it to be someone who can beat me.", "order": 38207, "speaker": "Kelly Kapoor"},
  {"season": 7, "episode": 6, "scene": 52, "line": "Shake things up. I'm a Nader guy.", "order": 38208, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 6, "scene": 53, "line": "Best Edward James Olmos costume I've ever seen. Like, freaky good.", "order": 38209, "speaker": "Creed Bratton"},
  {"season": 7, "episode": 7, "scene": 1, "line": "I know a lot of us have been feeling under the weather lately. It's that time of year. And according to a study done by the University of Arizona, they've discovered that your keyboards have hundreds of times more bacteria, per square inch, than a toilet seat.", "order": 38210, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 7, "scene": 1, "line": "I heard your momma had more bacteria per square inch than a toilet seat.", "order": 38211, "speaker": "Kelly Kapoor"},
  {"season": 7, "episode": 7, "scene": 1, "line": "That's true, that's true. I dated her momma, and you know what-", "order": 38212, "speaker": "Michael Scott"},
  {"season": 7, "episode": 7, "scene": 1, "line": "Stop.", "order": 38213, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 7, "scene": 1, "line": "[under his breath] Um... kay. Uh, alright.", "order": 38214, "speaker": "Michael Scott"},
  {"season": 7, "episode": 7, "scene": 1, "line": "One of the simplest ways to cut down on the spread of germs is to use something called the vampire cough.", "order": 38215, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 7, "scene": 1, "line": "Di-Did you say vampire?", "order": 38216, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 7, "scene": 1, "line": "Oh, no, it's just that if a vampire had to cough, he would do it like this. [coughs into elbow]", "order": 38217, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 7, "scene": 1, "line": "Uh-huh. Right. And ruin their cloaks? Do you have any idea how expensive wool is in Transylvania? [looks at camera] 'Cause of the euro.", "order": 38218, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 7, "scene": 1, "line": "Well, other things you can do to help cut down on the spread of disease are to wash your hands regularly. Don't come in if you're sick.", "order": 38219, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 7, "scene": 1, "line": "[rolls his eyes and shakes his head] Uh...", "order": 38220, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 7, "scene": 1, "line": "And get your flu shot. Also, I'm going to set up hand sanitizing stations around the office.", "order": 38221, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 7, "scene": 1, "line": "NO, no, no. They will cost you your life.", "order": 38222, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 7, "scene": 1, "line": "Elaborate.", "order": 38223, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 7, "scene": 1, "line": "The worst thing you can do for your immune system is to coddle it. They need to fight their own battles. If Sabre really cared about our well-being, they would set up hand de-sanitizing stations. A simple bowl at every juncture filled with dirt, vomit, fecal matter...", "order": 38224, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 7, "scene": 1, "line": "I'm not - I'm not going to do that. I'm not going to do that.", "order": 38225, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 7, "scene": 1, "line": "Exposing yourself to germs is the best way to make yourself stronger.", "order": 38226, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 7, "scene": 1, "line": "So by that rationale, if I had to sneeze, I should just sneeze on you.", "order": 38227, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 7, "scene": 1, "line": "Yes. I would welcome it.", "order": 38228, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 7, "scene": 1, "line": "[sneezes on Dwight, looks at camera] You're welcome.", "order": 38229, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 7, "scene": 2, "line": "The principle is sound. To avoid illness, expose yourself to germs, enabling your immune system to develop antibodies. I don't know why everyone doesn't do this. Maybe they have something against living forever.", "order": 38230, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 7, "scene": 3, "line": "Jim. Pam. [gasps, speaks in baby voice] And the precious bundle of God's gift to everything. [back to her normal voice] I wish you both a pleasant day. [baby voice] And you too. Yes. Praise God. Ok.", "order": 38231, "speaker": "Angela Martin"},
  {"season": 7, "episode": 7, "scene": 4, "line": "Cece's getting christened today.", "order": 38232, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 7, "scene": 4, "line": "Big day.", "order": 38233, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 7, "scene": 4, "line": "Everyone from work is here. We weren't planning on that.", "order": 38234, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 7, "scene": 4, "line": "Nope.", "order": 38235, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 7, "scene": 5, "line": "Top of the Sunday morning to you.", "order": 38236, "speaker": "Michael Scott"},
  {"season": 7, "episode": 7, "scene": 5, "line": "And a top of the day to you too, sir.", "order": 38237, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 7, "scene": 5, "line": "Hope you brought your pipes. We're about to smoke the opium of the masses.", "order": 38238, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 7, "scene": 5, "line": "Oh, yes. [mimicking smoking] Doobie-doobie doo.", "order": 38239, "speaker": "Michael Scott"},
  {"season": 7, "episode": 7, "scene": 6, "line": "I invited everyone in the office because it's not a day for just Jim or Pam or the baby or me. It is about us all. And I thought we should all celebrate... my joy. And our, all of ours joys.", "order": 38240, "speaker": "Michael Scott"},
  {"season": 7, "episode": 7, "scene": 7, "line": "[looking at Cece, under his breath] Ah, man. [looks at Erin talking to Gabe]", "order": 38241, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 7, "scene": 8, "line": "Does the Nard-dog want Nard-pups? Yeah. I want a big ol' litter of Nard-pups. All jumping around, sucking on the teet. Put 'em in a box, give 'em to my neighbors. Yeah, I want kids.", "order": 38242, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 7, "scene": 9, "line": "[handing out cards] For all your paper and printer needs. For all your paper and printer needs. For all your paper and printer needs.", "order": 38243, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 7, "scene": 10, "line": "Sunday church service... it's been a few years. The Big Guy and I, we got some catching up to do.", "order": 38244, "speaker": "Toby Flenderson"},
  {"season": 7, "episode": 7, "scene": 11, "line": "[in an old man mobster voice] Hey. Hey. I got an offer that you can't refuse. Scooch over.", "order": 38245, "speaker": "Michael Scott"},
  {"season": 7, "episode": 7, "scene": 11, "line": "Oh, Michael, this is just for family.", "order": 38246, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 7, "scene": 11, "line": "But I'm the Godfather.", "order": 38247, "speaker": "Michael Scott"},
  {"season": 7, "episode": 7, "scene": 11, "line": "It is really funny, but you know that can be confusing at a christening because you are not the godfather.", "order": 38248, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 7, "scene": 11, "line": "Are you talking to me?", "order": 38249, "speaker": "Michael Scott"},
  {"season": 7, "episode": 7, "scene": 11, "line": "[whispers to Pam] Ok, your turn.", "order": 38250, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 7, "scene": 11, "line": "Scooch. Before I shoot you in the head.", "order": 38251, "speaker": "Michael Scott"},
  {"season": 7, "episode": 7, "scene": 11, "line": "Okay, Michael. Um, you know I love when you do that voice, but today, I'm so sorry. I need you to tell me that you know you're not Cece's godfather.", "order": 38252, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 7, "scene": 11, "line": "Yeah.", "order": 38253, "speaker": "Michael Scott"},
  {"season": 7, "episode": 7, "scene": 11, "line": "I'm sorry, Michael. I need you to say it. I'm so sorry.", "order": 38254, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 7, "scene": 11, "line": "[normal voice] I'm not the godfather.", "order": 38255, "speaker": "Michael Scott"},
  {"season": 7, "episode": 7, "scene": 11, "line": "Okay, thank you.", "order": 38256, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 7, "scene": 11, "line": "[clears his throat] So who is the godfather?", "order": 38257, "speaker": "Michael Scott"},
  {"season": 7, "episode": 7, "scene": 11, "line": "Our friend, Seth, and his wife there.", "order": 38258, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 7, "scene": 11, "line": "Okay. Old friend, like, you've known him since kindergarten?", "order": 38259, "speaker": "Michael Scott"},
  {"season": 7, "episode": 7, "scene": 11, "line": "No, I met his wife in Mommy and Me. They're a great couple. You should meet them later.", "order": 38260, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 7, "scene": 11, "line": "No, I have plenty of friends, so... all right, well, you have great seats. Enjoy the show.", "order": 38261, "speaker": "Michael Scott"},
  {"season": 7, "episode": 7, "scene": 11, "line": "Okay. Thank you for coming, Michael.", "order": 38262, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 7, "scene": 12, "line": "I don't even know how to say this, but Cece is turning out to be a little b-i-t-c-h. And that's not true, but her parents are kind of boxing me out.", "order": 38263, "speaker": "Michael Scott"},
  {"season": 7, "episode": 7, "scene": 13, "line": "[singing] We ask you, Lord, come to our aid.", "order": 38264, "speaker": "Church congregation"},
  {"season": 7, "episode": 7, "scene": 13, "line": "Good morning.", "order": 38265, "speaker": "Pastor"},
  {"season": 7, "episode": 7, "scene": 13, "line": "Good morning.", "order": 38266, "speaker": "All"},
  {"season": 7, "episode": 7, "scene": 13, "line": "Good morning.", "order": 38267, "speaker": "Michael Scott"},
  {"season": 7, "episode": 7, "scene": 13, "line": "What a terrific day this is, and not just because the Eagles are playing. [laughter, Kevin giggles]", "order": 38268, "speaker": "Pastor"},
  {"season": 7, "episode": 7, "scene": 13, "line": "You may have seen the bus out front. Today we also say good-bye to our young adult ministry. They're leaving for three months to build a school for the village of Quimixto, in Cabo Corrientes, Mexico.", "order": 38269, "speaker": "Pastor"},
  {"season": 7, "episode": 7, "scene": 13, "line": "I've heard of that place. If you go to Cabo San Lucas, all the prostitutes are from Cabo Corrientes.", "order": 38270, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 7, "scene": 14, "line": "[stands outside church entrance] Okay, this is, all right, this is silly. [goes inside] [walks back out] Nope, nope, nope, nope, nope, nope, nope, nope, nope, nope, nope...", "order": 38271, "speaker": "Toby Flenderson"},
  {"season": 7, "episode": 7, "scene": 15, "line": "And thank you for your prayers for Justin. We hope for a speedy recovery.", "order": 38272, "speaker": "Female church member"},
  {"season": 7, "episode": 7, "scene": 15, "line": "Thank you. Anyone else with a prayer or a remembrance? [Dwight and an eldery man stand up at the same time, Dwight stares at him, man sits back down]", "order": 38273, "speaker": "Pastor"},
  {"season": 7, "episode": 7, "scene": 15, "line": "Good morning. Dwight Schrute. Thank you all for your prayers and your remembrances. Like Justin and his disfigurement, I too have had a horrible year. They say that the middle class is disappearing, and with it, the soul of America. So, as a gift to this beautiful congregation, I would like to offer a 4% discount on all Dunder Mifflin Sabre products if you buy a printer at full price.", "order": 38274, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 7, "scene": 16, "line": "Christening calls to repentance, to faithfulness, and to discipleship. We've come to celebrate these babies.", "order": 38275, "speaker": "Pastor"},
  {"season": 7, "episode": 7, "scene": 16, "line": "[looking at Cece's diaper] Somebody needs a change.", "order": 38276, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 7, "scene": 16, "line": "Right now?", "order": 38277, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 7, "scene": 16, "line": "Well, she can't bring this up with her.", "order": 38278, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 7, "scene": 16, "line": "Okay. All right, come on. [picks up Cece from Pam, whispers to her] Where are we going? Where are we going? We're gonna take a little field trip.", "order": 38279, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 7, "scene": 17, "line": "[whispering, going into the bathroom with Cece] Okay. Okay. There's my girl. All right. Hold on one second. There we go. What's that face for? Oh, my-oh, my God. Cece, no. No, no, no, not on the dress! Cece, stop!", "order": 38280, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 7, "scene": 18, "line": "[seeing Jim come in with Cece wearing an old t-shirt] What?", "order": 38281, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 7, "scene": 18, "line": "What?", "order": 38282, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 7, "scene": 18, "line": "Honey, no.", "order": 38283, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 7, "scene": 18, "line": "Yes.", "order": 38284, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 7, "scene": 18, "line": "No, no.", "order": 38285, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 7, "scene": 18, "line": "This is happening.", "order": 38286, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 7, "scene": 18, "line": "We have an extra outfit in the bag.", "order": 38287, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 7, "scene": 18, "line": "No. There's no extra outfit in the bag.", "order": 38288, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 7, "scene": 18, "line": "You said you checked it.", "order": 38289, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 7, "scene": 18, "line": "I did... say that.", "order": 38290, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 7, "scene": 18, "line": "At this time, will the families please come join me?", "order": 38291, "speaker": "Pastor"},
  {"season": 7, "episode": 7, "scene": 19, "line": "Jim and Pam and Cece really seem to be clicking. They are totally gelling. It's as if they leave my office and they go to another office that sells happiness. And good for them. That's great, because, you know, the paper industry's not gonna last forever.", "order": 38292, "speaker": "Michael Scott"},
  {"season": 7, "episode": 7, "scene": 20, "line": "Before we go, I'd like to remind everyone that the 'Halberts' have been kind enough to host a reception next door immediately following the service.", "order": 38293, "speaker": "Pastor"},
  {"season": 7, "episode": 7, "scene": 20, "line": "Mm. No. Wrong on both counts.", "order": 38294, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 7, "scene": 21, "line": "Okay, 'A,' Halbert. And, 'B,' I think a more appropriate statement would be, 'The 'Halberts' are hosting an intimate reception for their invited guests.'", "order": 38295, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 7, "scene": 22, "line": "For all their generosity of spirit, they password-protect their wireless?", "order": 38296, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 7, "scene": 22, "line": "Try 'Jesus.'", "order": 38297, "speaker": "Kelly Kapoor"},
  {"season": 7, "episode": 7, "scene": 22, "line": "Opus dei.", "order": 38298, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 7, "scene": 22, "line": "[to Michael] Hi. Good morning.", "order": 38299, "speaker": "Male church member"},
  {"season": 7, "episode": 7, "scene": 22, "line": "Good morning.", "order": 38300, "speaker": "Michael Scott"},
  {"season": 7, "episode": 7, "scene": 22, "line": "Good morning.", "order": 38301, "speaker": "Female church member"},
  {"season": 7, "episode": 7, "scene": 22, "line": "Good morning.", "order": 38302, "speaker": "Michael Scott"},
  {"season": 7, "episode": 7, "scene": 22, "line": "Hello.", "order": 38303, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 7, "scene": 22, "line": "Shh, shh, shh.", "order": 38304, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 7, "scene": 23, "line": "[walking around and greeting people] How are you? Good morning. Good morning.", "order": 38305, "speaker": "Michael Scott"},
  {"season": 7, "episode": 7, "scene": 23, "line": "Good morning.", "order": 38306, "speaker": "Lady"},
  {"season": 7, "episode": 7, "scene": 24, "line": "I am feeling this. Call it the Holy Spirit or the passion of the Christ. I am loving these people.", "order": 38307, "speaker": "Michael Scott"},
  {"season": 7, "episode": 7, "scene": 25, "line": "Hey.", "order": 38308, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 7, "scene": 25, "line": "I'm Doug Mcpherson, Davey's uncle.", "order": 38309, "speaker": "Doug [church member]"},
  {"season": 7, "episode": 7, "scene": 25, "line": "Oh, well, Dave is an adorable baby.", "order": 38310, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 7, "scene": 25, "line": "Davey.", "order": 38311, "speaker": "Doug"},
  {"season": 7, "episode": 7, "scene": 25, "line": "Yeah. [Doug walks away]", "order": 38312, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 7, "scene": 25, "line": "Wow.", "order": 38313, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 7, "scene": 25, "line": "Well, this is intimate.", "order": 38314, "speaker": "Angela Martin"},
  {"season": 7, "episode": 7, "scene": 25, "line": "We just had to add a few more tables. We weren't expecting this many people.", "order": 38315, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 7, "scene": 25, "line": "You don't know them all?", "order": 38316, "speaker": "Angela Martin"},
  {"season": 7, "episode": 7, "scene": 25, "line": "Nope, and we're gonna need a loaves and fishes kind of miracle to feed 'em all.", "order": 38317, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 7, "scene": 25, "line": "Jesus is not your caterer. [baby voice to Cece] But he should be your caterer 'cause you're a little angel. Why didn't your parents get you a caterer? [normal voice] They don't think.", "order": 38318, "speaker": "Angela Martin"},
  {"season": 7, "episode": 7, "scene": 25, "line": "[to Cece] Hm. Don't listen to her. She's just jealous 'cause she doesn't get to come home to someone as cute as you.", "order": 38319, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 7, "scene": 25, "line": "Huh, you think she is jealous about having a baby?", "order": 38320, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 7, "scene": 25, "line": "I don't know. I'm just hungry.", "order": 38321, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 7, "scene": 25, "line": "Okay, well, you know what? Everybody's hungry. [Pam looks at Jim] Sorry. I think I'm just hungry.", "order": 38322, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 7, "scene": 26, "line": "Oh, after you.", "order": 38323, "speaker": "Michael Scott"},
  {"season": 7, "episode": 7, "scene": 26, "line": "Oh, no, after you.", "order": 38324, "speaker": "Male church member"},
  {"season": 7, "episode": 7, "scene": 26, "line": "No, I insist. After me.", "order": 38325, "speaker": "Michael Scott"},
  {"season": 7, "episode": 7, "scene": 26, "line": "[laughs] I'm gonna use that one. Have a good lunch.", "order": 38326, "speaker": "Male church member"},
  {"season": 7, "episode": 7, "scene": 26, "line": "Thank you. You too.", "order": 38327, "speaker": "Michael Scott"},
  {"season": 7, "episode": 7, "scene": 27, "line": "This is not the meal I was promised. I'm going to have no energy for the rest of the day.", "order": 38328, "speaker": "Stanley Hudson"},
  {"season": 7, "episode": 7, "scene": 27, "line": "I cancelled my plans to come to this thing, and they repay me with this?", "order": 38329, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 7, "scene": 27, "line": "You know what, guys, let's just enjoy lunch.", "order": 38330, "speaker": "Michael Scott"},
  {"season": 7, "episode": 7, "scene": 27, "line": "With what? How? Sometimes, Michael. Sometimes.", "order": 38331, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 7, "scene": 28, "line": "Excuse me, everyone, on behalf of the youth ministry that's leaving for Mexico, I just wanted to thank our hosts and tell you about those good-looking guys and gals over there. [group of young adults cheer and applaud]", "order": 38332, "speaker": "Girl"},
  {"season": 7, "episode": 7, "scene": 28, "line": "[whispering] Teach for America girls are way hotter, but they're nuts.", "order": 38333, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 7, "scene": 29, "line": "This is Jessica Ortega. I met her when both she and I were both seven years old when my parents took me to Quimixto on a service trip.", "order": 38334, "speaker": "Girl"},
  {"season": 7, "episode": 7, "scene": 29, "line": "[chuckling] Who takes a kid to Mexico?", "order": 38335, "speaker": "Phyllis Vance"},
  {"season": 7, "episode": 7, "scene": 29, "line": "I would run to Mexico if that's where the sandwiches are.", "order": 38336, "speaker": "Stanley Hudson"},
  {"season": 7, "episode": 7, "scene": 29, "line": "Shh.", "order": 38337, "speaker": "Michael Scott"},
  {"season": 7, "episode": 7, "scene": 30, "line": "Right now, Jessica's children have to walk twelve miles to a school with dirt floors.", "order": 38338, "speaker": "Girl"},
  {"season": 7, "episode": 7, "scene": 30, "line": "No.", "order": 38339, "speaker": "Michael Scott"},
  {"season": 7, "episode": 7, "scene": 30, "line": "It's gonna be three months of hard work and when we're done, we'll practically be Quimixtanos.", "order": 38340, "speaker": "Girl"},
  {"season": 7, "episode": 7, "scene": 31, "line": "Greg, hello, it's Dwight from the vestibule. You want to know my eleventh commandment? I will not be undersold.", "order": 38341, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 7, "scene": 31, "line": "[overhearing Dwight's conversation] Hey, Dwight. Dwight, hey.", "order": 38342, "speaker": "Michael Scott"},
  {"season": 7, "episode": 7, "scene": 31, "line": "I am ready to love thy neighbor with these kind of discounts.", "order": 38343, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 7, "scene": 31, "line": "Are you kidding me? Stop it.", "order": 38344, "speaker": "Michael Scott"},
  {"season": 7, "episode": 7, "scene": 31, "line": "I'll call you back.", "order": 38345, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 7, "scene": 32, "line": "My parents explained it to me this way. You wouldn't hesitate to save a family member from a burning building, but what if the earth was your building and all the people on it were your family?", "order": 38346, "speaker": "Girl"},
  {"season": 7, "episode": 7, "scene": 32, "line": "What if the moon was your car and Jupiter was your hair brush?", "order": 38347, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 7, "scene": 32, "line": "Shh.", "order": 38348, "speaker": "Michael Scott"},
  {"season": 7, "episode": 7, "scene": 32, "line": "Thank you. Or should I say gracias?", "order": 38349, "speaker": "Girl"},
  {"season": 7, "episode": 7, "scene": 33, "line": "[sighs] Cece went down.", "order": 38350, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 7, "scene": 33, "line": "[holding up an empty serving dish] What was this? You're out of it.", "order": 38351, "speaker": "Woman"},
  {"season": 7, "episode": 7, "scene": 33, "line": "Scones.", "order": 38352, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 7, "scene": 33, "line": "I didn't get one.", "order": 38353, "speaker": "Woman"},
  {"season": 7, "episode": 7, "scene": 33, "line": "Well, if it makes you feel better, I didn't get one either.", "order": 38354, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 7, "scene": 33, "line": "Is it just the one jug of apple cider? [Pam shrugs, woman walks away]", "order": 38355, "speaker": "Woman"},
  {"season": 7, "episode": 7, "scene": 33, "line": "Who the heck was that?", "order": 38356, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 7, "scene": 33, "line": "I think that was sconesy cider, noted baptism reception critic.", "order": 38357, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 7, "scene": 33, "line": "We need more food. I'll go get some subs and sodas.", "order": 38358, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 7, "scene": 33, "line": "All right. And cider.", "order": 38359, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 7, "scene": 34, "line": "[pointing to group of young adults laughing] Look at that. Look at that. That's fun. We need to do stuff together outside of work. Let's go help Africa. Let's go build an airport. We'll start small. We'll have a car wash. We'll send some cheerleaders to regionals.", "order": 38360, "speaker": "Michael Scott"},
  {"season": 7, "episode": 7, "scene": 34, "line": "You could feed the hungry. Us.", "order": 38361, "speaker": "Stanley Hudson"},
  {"season": 7, "episode": 7, "scene": 34, "line": "Why do we have to do something together? I volunteer at a clinic on my own time.", "order": 38362, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 7, "scene": 34, "line": "Yeah, well, that's just a pick-up scene. Okay, we don't have to volunteer. But I think we should hang out more together.", "order": 38363, "speaker": "Michael Scott"},
  {"season": 7, "episode": 7, "scene": 34, "line": "We are hanging out-right now. You want some more of this?", "order": 38364, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 7, "scene": 34, "line": "Look at these people. These are church-going people. And they know how to party.", "order": 38365, "speaker": "Michael Scott"},
  {"season": 7, "episode": 7, "scene": 34, "line": "Church isn't a party, Michael.", "order": 38366, "speaker": "Phyllis Vance"},
  {"season": 7, "episode": 7, "scene": 34, "line": "Well, it's, it's-", "order": 38367, "speaker": "Michael Scott"},
  {"season": 7, "episode": 7, "scene": 34, "line": "Welcome to the party. Everybody have their kool-aid?", "order": 38368, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 7, "scene": 34, "line": "No. Okay, you know what? This is inappropriate. What is wrong with you guys? What is wrong with you? What is so horrible about wanting to get together and do something nice? Why did you guys even come today? What's so great about your lives that you think you're better than everybody else? That you can make fun of everybody else? You're mean. You're mean girls. Like the movie, Mean Girls. And Kevin and Stanley, if you don't stop worrying so much about what you're gonna put in your gullet, you're gonna die in about a month.", "order": 38369, "speaker": "Michael Scott"},
  {"season": 7, "episode": 7, "scene": 35, "line": "Oh, hey, Mee-Maw, would you watch Cece for a second? Don't worry. She's asleep. I'm just gonna track down some more chairs.", "order": 38370, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 7, "scene": 35, "line": "Fine, fine. I guess I'll watch Suzanne's purse and your baby.", "order": 38371, "speaker": "Mee-Maw"},
  {"season": 7, "episode": 7, "scene": 36, "line": "[standing in a line saying goodbye to the kids going to Mexico] Bye. Good luck. Good luck to you. Bye. Do good. Do good. Bye. Have fun. We're proud of you. [starts to walk down through the line and into the bus] Bye. Okay.", "order": 38372, "speaker": "Michael Scott"},
  {"season": 7, "episode": 7, "scene": 37, "line": "[on the bus] Hey. Hey. Is this the bus to Mexico?", "order": 38373, "speaker": "Michael Scott"},
  {"season": 7, "episode": 7, "scene": 37, "line": "Yeah! [cheers, applause]", "order": 38374, "speaker": "Students"},
  {"season": 7, "episode": 7, "scene": 37, "line": "[in reference to the team shirts] I'll take a shirt.", "order": 38375, "speaker": "Michael Scott"},
  {"season": 7, "episode": 7, "scene": 37, "line": "Are you coming?", "order": 38376, "speaker": "Guy"},
  {"season": 7, "episode": 7, "scene": 37, "line": "If you'll have me, yes.", "order": 38377, "speaker": "Michael Scott"},
  {"season": 7, "episode": 7, "scene": 37, "line": "Heck, yes.", "order": 38378, "speaker": "Guy"},
  {"season": 7, "episode": 7, "scene": 37, "line": "Really? [cheers, applause] Thank you. All right.", "order": 38379, "speaker": "Michael Scott"},
  {"season": 7, "episode": 7, "scene": 38, "line": "[seeing Michael on the bus] Michael. Get off the bus.", "order": 38380, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 7, "scene": 38, "line": "No, I'm not. I'm staying on the bus. I'm already on the bus. I'm going.", "order": 38381, "speaker": "Michael Scott"},
  {"season": 7, "episode": 7, "scene": 38, "line": "Michael, this is irresponsible.", "order": 38382, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 7, "scene": 38, "line": "It is not irresponsible! I have never been more confident about a decision in my life.", "order": 38383, "speaker": "Michael Scott"},
  {"season": 7, "episode": 7, "scene": 38, "line": "I agree. I think it's superb.", "order": 38384, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 7, "scene": 38, "line": "Well, you have a job to do. Okay, there is no off-season when it comes to printer sales.", "order": 38385, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 7, "scene": 38, "line": "You know what, my job will be here when I get back.", "order": 38386, "speaker": "Michael Scott"},
  {"season": 7, "episode": 7, "scene": 38, "line": "Michael, you can't go to Mexico. You don't have your passport.", "order": 38387, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 7, "scene": 38, "line": "[sticking his head out Michael's window on the bus] You don't need one to get in. Plus, we can just have it sent to him down in Mexico.", "order": 38388, "speaker": "Guy"},
  {"season": 7, "episode": 7, "scene": 38, "line": "Hey, right?", "order": 38389, "speaker": "Michael Scott"},
  {"season": 7, "episode": 7, "scene": 38, "line": "Michael, what am I supposed to tell my clients if they ask about you?", "order": 38390, "speaker": "Phyllis Vance"},
  {"season": 7, "episode": 7, "scene": 38, "line": "Tell them that I died, and I turned into an angel, and when they feel a breeze in a room with all the windows closed or that-that chill on the back of their neck, that is just me watching over them.", "order": 38391, "speaker": "Michael Scott"},
  {"season": 7, "episode": 7, "scene": 38, "line": "Okay, but what about the bigger corporate accounts?", "order": 38392, "speaker": "Phyllis Vance"},
  {"season": 7, "episode": 7, "scene": 38, "line": "Tell them that I'm in a meeting.", "order": 38393, "speaker": "Michael Scott"},
  {"season": 7, "episode": 7, "scene": 38, "line": "Michael, I'm just about to close a sale. Can I authorize a 15% discount?", "order": 38394, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 7, "scene": 38, "line": "No, you may not.", "order": 38395, "speaker": "Michael Scott"},
  {"season": 7, "episode": 7, "scene": 38, "line": "Gah!", "order": 38396, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 7, "scene": 38, "line": "I think it's really cool, Michael. I wish I had a job that I could just leave.", "order": 38397, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 7, "scene": 38, "line": "Thank you.", "order": 38398, "speaker": "Michael Scott"},
  {"season": 7, "episode": 7, "scene": 38, "line": "[looking at Erin] Save me an aisle seat, Michael! I'm coming!", "order": 38399, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 7, "scene": 39, "line": "I will not stand idly by while these Mexican villagers are sick.", "order": 38400, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 7, "scene": 39, "line": "We're actually building them a school.", "order": 38401, "speaker": "Guy"},
  {"season": 7, "episode": 7, "scene": 39, "line": "Whatever. I won't-I won't stand for it.", "order": 38402, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 7, "scene": 40, "line": "[waving goodbye as the bus drives away] See you in a few months.", "order": 38403, "speaker": "Michael Scott"},
  {"season": 7, "episode": 7, "scene": 41, "line": "If the whole world were like you guys, we wouldn't have so many problems.", "order": 38404, "speaker": "Girl"},
  {"season": 7, "episode": 7, "scene": 41, "line": "Mm. That's not gonna happen.", "order": 38405, "speaker": "Michael Scott"},
  {"season": 7, "episode": 7, "scene": 41, "line": "We're one in a million.", "order": 38406, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 7, "scene": 41, "line": "I know. Nobody I know would leave their jobs and friends and families to do manual labor for three months.", "order": 38407, "speaker": "Girl"},
  {"season": 7, "episode": 7, "scene": 41, "line": "Well, you know what, everybody thinks that I am crazy, and that tells me that I am the sanest person I know. So...", "order": 38408, "speaker": "Michael Scott"},
  {"season": 7, "episode": 7, "scene": 41, "line": "Just a heads up. Probably gonna be borrowing a few things. Uh, gonna need a contact lens case, some sunscreen, some sandals...", "order": 38409, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 7, "scene": 42, "line": "[standing in the church sanctuary, talking to the stained glass] Why you always got to be so mean to me?", "order": 38410, "speaker": "Toby Flenderson"},
  {"season": 7, "episode": 7, "scene": 43, "line": "[seeing Cece gone from the couch he left her] Uh, MeeMaw, where's Cece?", "order": 38411, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 7, "scene": 43, "line": "I don't know. I lost the purse too.", "order": 38412, "speaker": "MeeMaw"},
  {"season": 7, "episode": 7, "scene": 43, "line": "Okay.", "order": 38413, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 7, "scene": 44, "line": "Good-bye, Lackawana County.", "order": 38414, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 7, "scene": 44, "line": "How long till we get to Mexico?", "order": 38415, "speaker": "Michael Scott"},
  {"season": 7, "episode": 7, "scene": 44, "line": "Well, two days minus how long we've been on the road. 45 minutes? So, like, two days basically. Maybe more.", "order": 38416, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 7, "scene": 44, "line": "Ooh. What are we building down there again? Like, a hospital? A school for Mexicans? What?", "order": 38417, "speaker": "Michael Scott"},
  {"season": 7, "episode": 7, "scene": 44, "line": "I don't know, I thought it was like a gymnasium or...", "order": 38418, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 7, "scene": 44, "line": "Why aren't they building it themselves?", "order": 38419, "speaker": "Michael Scott"},
  {"season": 7, "episode": 7, "scene": 44, "line": "They don't know how.", "order": 38420, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 7, "scene": 44, "line": "Do we know how? I don't know how. You know how?", "order": 38421, "speaker": "Michael Scott"},
  {"season": 7, "episode": 7, "scene": 44, "line": "Well, Carla knows.", "order": 38422, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 7, "scene": 44, "line": "Carla knows. Oh, I feel like there were so many more people on this bus. There were, like, 200, 300 people before. Hey, Carla? Carla? Hi. What are you doing?", "order": 38423, "speaker": "Michael Scott"},
  {"season": 7, "episode": 7, "scene": 44, "line": "Oh, trying to sleep.", "order": 38424, "speaker": "Carla"},
  {"season": 7, "episode": 7, "scene": 44, "line": "What is happening? Oh, God, what is happening?", "order": 38425, "speaker": "Michael Scott"},
  {"season": 7, "episode": 7, "scene": 44, "line": "Do you smell like a weird, warm cheese?", "order": 38426, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 7, "scene": 44, "line": "Oh, God, you know what? I'm gonna be in Mexico for my half-birthday.", "order": 38427, "speaker": "Michael Scott"},
  {"season": 7, "episode": 7, "scene": 44, "line": "And the Christmas party.", "order": 38428, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 7, "scene": 44, "line": "And Cinco de Mayo.", "order": 38429, "speaker": "Michael Scott"},
  {"season": 7, "episode": 7, "scene": 44, "line": "Nah, no. We'll be back before that.", "order": 38430, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 7, "scene": 44, "line": "No, we-No. You know how these things go. Construction projects, they say three months, and then, after two years, the lazy bums haven't even started. Now we're those lazy bums. I gotta get off this bus.", "order": 38431, "speaker": "Michael Scott"},
  {"season": 7, "episode": 7, "scene": 45, "line": "Hey, guys, has anybody seen the baby?", "order": 38432, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 7, "scene": 45, "line": "Here's her carrier, but no Cece.", "order": 38433, "speaker": "Phyllis Vance"},
  {"season": 7, "episode": 7, "scene": 45, "line": "Jim, Jim, I want to give a toast. Where's the little girl of honor?", "order": 38434, "speaker": "Jim Halpert's Dad"},
  {"season": 7, "episode": 7, "scene": 45, "line": "I don't know, dad. I don't know.", "order": 38435, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 7, "scene": 45, "line": "I don't mean to bug, but do you know when those subs are due into port? I got a hungry bunch of Mcphersons over there.", "order": 38436, "speaker": "Doug"},
  {"season": 7, "episode": 7, "scene": 45, "line": "I don't know. Hey, have you seen my baby?", "order": 38437, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 7, "scene": 45, "line": "I think maybe some blonde lady had her.", "order": 38438, "speaker": "Doug"},
  {"season": 7, "episode": 7, "scene": 45, "line": "A small blonde woman?", "order": 38439, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 7, "scene": 45, "line": "Smaller than me.", "order": 38440, "speaker": "Doug"},
  {"season": 7, "episode": 7, "scene": 45, "line": "Uh, everyone? Everyone, can I just have your attention? Has anyone seen a small-[pointing to Angela] Stop that tiny blonde woman! She stole my baby!", "order": 38441, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 7, "scene": 45, "line": "[as Kevin runs to grab her] What are you doing?", "order": 38442, "speaker": "Angela Martin"},
  {"season": 7, "episode": 7, "scene": 45, "line": "[reaching into her purse] Give me the baby!", "order": 38443, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 7, "scene": 45, "line": "What? Kevin!", "order": 38444, "speaker": "Angela Martin"},
  {"season": 7, "episode": 7, "scene": 45, "line": "[holding the baby, walks up to Jim] Hi, honey. I was just changing the baby.", "order": 38445, "speaker": "Helene Beesly"},
  {"season": 7, "episode": 7, "scene": 45, "line": "[baby voice] Hi.", "order": 38446, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 7, "scene": 45, "line": "[walks up] Honey.", "order": 38447, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 7, "scene": 45, "line": "[to Cece] Hi. [to everyone] All right. Travel safe, Angela.", "order": 38448, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 7, "scene": 45, "line": "Did you think I stole your baby?", "order": 38449, "speaker": "Angela Martin"},
  {"season": 7, "episode": 7, "scene": 45, "line": "What's that? Oh, yeah, back then I did. Just now. So, don't, because I've got my eye on you.", "order": 38450, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 7, "scene": 45, "line": "[gasping as he looks into Angela's purse] Look at these scones, Jim. Thousands of them. What kind of a person steals scones from a baby?", "order": 38451, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 7, "scene": 45, "line": "Someone put them in my bag.", "order": 38452, "speaker": "Angela Martin"},
  {"season": 7, "episode": 7, "scene": 45, "line": "[to Pam] So that was lunch, eh? Mcpherson troop, let's haul out. Rendezvous at Friendly's.", "order": 38453, "speaker": "Doug"},
  {"season": 7, "episode": 7, "scene": 45, "line": "Did you lose Cece?", "order": 38454, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 7, "scene": 45, "line": "Yeah. Yeah, a little bit. I did.", "order": 38455, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 7, "scene": 46, "line": "Can you fake a seizure or a heart attack or something?", "order": 38456, "speaker": "Michael Scott"},
  {"season": 7, "episode": 7, "scene": 46, "line": "I can make myself cry.", "order": 38457, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 7, "scene": 46, "line": "Do that. Do it.", "order": 38458, "speaker": "Michael Scott"},
  {"season": 7, "episode": 7, "scene": 46, "line": "Should I-I got it. I got it. [walks up to the bus driver] Um, hey, I saw a sign for a scenic overlook coming up. Really love to check out the view.", "order": 38459, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 7, "scene": 46, "line": "Can't stop. We're on a schedule.", "order": 38460, "speaker": "Bus driver"},
  {"season": 7, "episode": 7, "scene": 46, "line": "Well, uh, there's a nice bistro coming up. We could pick up some Paninis for the road.", "order": 38461, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 7, "scene": 46, "line": "Maybe we'll stop in Tennesseee. It's not safe to talk to a driver.", "order": 38462, "speaker": "Bus driver"},
  {"season": 7, "episode": 7, "scene": 46, "line": "[having walked up to the front] Okay, driver, driver, if you're not gonna stop this bus, then I'm going to stop it for you. Okay? Hang on. [pulls the string to request a stop, makes a dinging sound]", "order": 38463, "speaker": "Michael Scott"},
  {"season": 7, "episode": 7, "scene": 46, "line": "Stopping in Tennessee.", "order": 38464, "speaker": "Bus driver"},
  {"season": 7, "episode": 7, "scene": 46, "line": "Hey, is there a problem, you guys?", "order": 38465, "speaker": "Guy"},
  {"season": 7, "episode": 7, "scene": 46, "line": "Yeah, just get him to stop the bus, please.", "order": 38466, "speaker": "Michael Scott"},
  {"season": 7, "episode": 7, "scene": 46, "line": "Why?", "order": 38467, "speaker": "Guy"},
  {"season": 7, "episode": 7, "scene": 46, "line": "Just make him stop the freaking bus!", "order": 38468, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 7, "scene": 46, "line": "Stop the bus, okay?", "order": 38469, "speaker": "Michael Scott"},
  {"season": 7, "episode": 7, "scene": 46, "line": "Are you okay, sir?", "order": 38470, "speaker": "Carla"},
  {"season": 7, "episode": 7, "scene": 46, "line": "I didn't sign up for this. You guys are young, that's great. You want to give back to society. I've done that. I need to take.", "order": 38471, "speaker": "Michael Scott"},
  {"season": 7, "episode": 7, "scene": 46, "line": "You have plenty left to give. You're doing-", "order": 38472, "speaker": "Girl"},
  {"season": 7, "episode": 7, "scene": 46, "line": "Blah-di-blah!", "order": 38473, "speaker": "Michael Scott"},
  {"season": 7, "episode": 7, "scene": 46, "line": "It doesn't matter.", "order": 38474, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 7, "scene": 46, "line": "We could go back and forth all day. It's not gonna solve anything.", "order": 38475, "speaker": "Michael Scott"},
  {"season": 7, "episode": 7, "scene": 46, "line": "If we went to Mexico, we'd probably screw everything up anyway.", "order": 38476, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 7, "scene": 46, "line": "Do you want that for little Jessica? No, you don't. And the answer is clear. Stop the bus. Okay?", "order": 38477, "speaker": "Michael Scott"},
  {"season": 7, "episode": 7, "scene": 46, "line": "Stop the bus!", "order": 38478, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 7, "scene": 46, "line": "[shouting and clapping] Stop the bus! Stop the bus!", "order": 38479, "speaker": "Michael Scott & Andy Bernard"},
  {"season": 7, "episode": 7, "scene": 46, "line": "Okay, stop the dang bus.", "order": 38480, "speaker": "Guy"},
  {"season": 7, "episode": 7, "scene": 46, "line": "[bus stopping] Okay, well, a pleasure. Go save the world. We'll keep an eye on America for you.", "order": 38481, "speaker": "Michael Scott"},
  {"season": 7, "episode": 7, "scene": 46, "line": "[getting off the bus] Wait for me! [bus drives away] Don't say anything to my parents.", "order": 38482, "speaker": "Another guy"},
  {"season": 7, "episode": 7, "scene": 47, "line": "[driving up to pick up Michael, Andy, and kid off the road] Get in quick.", "order": 38483, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 7, "scene": 47, "line": "Why quick?", "order": 38484, "speaker": "Michael Scott"},
  {"season": 7, "episode": 7, "scene": 47, "line": "So it's faster.", "order": 38485, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 7, "scene": 48, "line": "Do you guys want me to take you home or go back to the church?", "order": 38486, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 7, "scene": 48, "line": "Is anyone still at the church?", "order": 38487, "speaker": "Michael Scott"},
  {"season": 7, "episode": 7, "scene": 48, "line": "No, actually most of them went to go see a movie.", "order": 38488, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 7, "scene": 48, "line": "What? Shut up.", "order": 38489, "speaker": "Michael Scott"},
  {"season": 7, "episode": 7, "scene": 48, "line": "Yeah. Kevin suggested it, and then they all went in Meredith's minivan.", "order": 38490, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 7, "scene": 48, "line": "It is so nice to be back in a country that has movies.", "order": 38491, "speaker": "Michael Scott"},
  {"season": 7, "episode": 7, "scene": 48, "line": "I bet. You guys.", "order": 38492, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 7, "scene": 48, "line": "Can I come? [silence]", "order": 38493, "speaker": "Guy"},
  {"season": 7, "episode": 7, "scene": 48, "line": "Oh, Lake Wobblegone's on. Do you guys-are you cool with that?", "order": 38494, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 7, "scene": 48, "line": "... banana bread, but Fred Nordquist had no appetite. He was thinking about his pair of new boots. It'd been ten years after all. As he told Mrs. Nordquist, it would take two years to get comfortable with the new boots.'", "order": 38495, "speaker": "Radio"},
  {"season": 7, "episode": 8, "scene": 1, "line": "[to Gabe] They caught the Scranton Stranger, they trapped him in his house. [run to conference room, everyone is watching the news on a monitor]", "order": 38496, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 8, "scene": 1, "line": "Police have now surrounded the apartment complex.", "order": 38497, "speaker": "Reporter"},
  {"season": 7, "episode": 8, "scene": 1, "line": "[whispering to Pam] They have him surrounded. It's the SWAT. The SWAT's arriving.", "order": 38498, "speaker": "Michael Scott"},
  {"season": 7, "episode": 8, "scene": 1, "line": "It is unknown if he is armed with anything.", "order": 38499, "speaker": "Reporter"},
  {"season": 7, "episode": 8, "scene": 1, "line": "They should do that thing where they play the really good music to get him to come out.", "order": 38500, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 8, "scene": 1, "line": "[sighs] They shouldn't televise any of this, it just encourages copy-cats.", "order": 38501, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 8, "scene": 1, "line": "Just say copies, why do you have to drag cats into this.", "order": 38502, "speaker": "Angela Martin"},
  {"season": 7, "episode": 8, "scene": 1, "line": "Okay, while this is interesting we should get back to work. These stand-offs can last a long time. What if it's another Waco.", "order": 38503, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 8, "scene": 1, "line": "It's pronounced wacko.", "order": 38504, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 8, "scene": 2, "line": "Some events are so news worthy, so historic, that you have to stop everything to watch. Balloon Boy, Michael Jackson's funeral. Things that if you didn't see them live, you wouldn't really care that you didn't see them at all.", "order": 38505, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 8, "scene": 3, "line": "Columbia Street in Washington Ave! When is he gonna come out? [phone rings, Kelly answers and hangs up]", "order": 38506, "speaker": "Kelly Kapoor"},
  {"season": 7, "episode": 8, "scene": 3, "line": "I bet he's wishing he had a hybrid, right? Sixty miles to the gallon in the city.", "order": 38507, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 8, "scene": 3, "line": "No! I bet he's wishing he was strangling someone!", "order": 38508, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 8, "scene": 3, "line": "Ok, that's our street! That's our street! He's going down our street! [runs out of conference room.] Come on everybody! [all quickly follow]", "order": 38509, "speaker": "Michael Scott"},
  {"season": 7, "episode": 8, "scene": 4, "line": "[on sidewalk] There it is! I saw it! I saw it! He went right by! You missed it! [all groan]", "order": 38510, "speaker": "Michael Scott"},
  {"season": 7, "episode": 8, "scene": 5, "line": "[collecting pebbles off the street into a jar] Grandpa, where were you the day the the Scranton Strangler was caught? [old man voice] Well kiddo, I was there. I was there... And I'll tell you what. [shakes jar of pebbles] You go sell these and buy yourself a nice spaceship.", "order": 38511, "speaker": "Michael Scott"},
  {"season": 7, "episode": 8, "scene": 6, "line": "Michael!", "order": 38512, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 8, "scene": 6, "line": "Walk with me.", "order": 38513, "speaker": "Michael Scott"},
  {"season": 7, "episode": 8, "scene": 6, "line": "Gabe and I are having a party, and everyone's invited, and it's at his house apartment and we're gonna watch Glee.", "order": 38514, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 8, "scene": 6, "line": "[obviously faking being serious the whole time] Glee? What is Glee, some television program? Jim I need that thing stat!", "order": 38515, "speaker": "Michael Scott"},
  {"season": 7, "episode": 8, "scene": 6, "line": "[confused] Okay?", "order": 38516, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 8, "scene": 6, "line": "Yeah it's a TV show...", "order": 38517, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 8, "scene": 6, "line": "Dwight! Sign please. I don't have time for parties I don't have time for TV shows. Stanley! What time is that thing I have to do?", "order": 38518, "speaker": "Michael Scott"},
  {"season": 7, "episode": 8, "scene": 6, "line": "I...", "order": 38519, "speaker": "Stanley Hudson"},
  {"season": 7, "episode": 8, "scene": 6, "line": "Cancel it! Are you still here? [to Erin]", "order": 38520, "speaker": "Michael Scott"},
  {"season": 7, "episode": 8, "scene": 6, "line": "Uhh. It's just that it's our first party together so...", "order": 38521, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 8, "scene": 6, "line": "MMM MMM MM MM MM! Did you hear what I said? I'm a very busy man. I don't have time for your TV show.", "order": 38522, "speaker": "Michael Scott"},
  {"season": 7, "episode": 8, "scene": 6, "line": "[sad] Okay, sorry.", "order": 38523, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 8, "scene": 6, "line": "I'm joking.", "order": 38524, "speaker": "Michael Scott"},
  {"season": 7, "episode": 8, "scene": 6, "line": "Wait which one?", "order": 38525, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 8, "scene": 6, "line": "I'm joking. I'm kidding around! I'm not actually angry. [Erin laughs] I'm not busy at all, I'm not doing anything. And I know what Glee is, I'm a total Gleek.", "order": 38526, "speaker": "Michael Scott"},
  {"season": 7, "episode": 8, "scene": 6, "line": "Good me too!", "order": 38527, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 8, "scene": 6, "line": "You know who my favorite character is? The invalid. [Jim makes a face] I am coming to your party.", "order": 38528, "speaker": "Michael Scott"},
  {"season": 7, "episode": 8, "scene": 7, "line": "I don't know if Michael likes Gabe that much, but there's this thing on Glee called mash-up, where two things that don't go together, make one great song. Take Gabe, take Michael, you make Gay Mike. Best friends.", "order": 38529, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 8, "scene": 8, "line": "[reacting to Erin's invitation] No really? Is there time to change this?! That show! Now first they say that Mr. Schu doesn't know anything about choreography, then like three episodes later he's this fantastic choreographer?! Pick a lane people!", "order": 38530, "speaker": "Kelly Kapoor"},
  {"season": 7, "episode": 8, "scene": 9, "line": "And what was with Jesse's sudden turn on Rachel, between Dream-On and Funk. Where the heck did that come from?! Honestly that show, it's just. It's irresponsible.", "order": 38531, "speaker": "Kelly Kapoor"},
  {"season": 7, "episode": 8, "scene": 10, "line": "Are you going? [to Dwight]", "order": 38532, "speaker": "Angela Martin"},
  {"season": 7, "episode": 8, "scene": 10, "line": "Not because I want to. With all the feeling to base a show around. Glee? Thirst. Now that's a show I'd watch.", "order": 38533, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 8, "scene": 10, "line": "I would watch that.", "order": 38534, "speaker": "Angela Martin"},
  {"season": 7, "episode": 8, "scene": 10, "line": "Tonight might be a convenient night for us to have some intercourse.", "order": 38535, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 8, "scene": 11, "line": "[to Erin] You're having a party at Gabe's apartment?", "order": 38536, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 8, "scene": 11, "line": "Mhmm! I'd love it if you were there.", "order": 38537, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 8, "scene": 11, "line": "You would?", "order": 38538, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 8, "scene": 11, "line": "You and Michael are always the life of the party!", "order": 38539, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 8, "scene": 11, "line": "Try and keep me from coming!", "order": 38540, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 8, "scene": 11, "line": "Why would I keep you from coming?", "order": 38541, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 8, "scene": 11, "line": "Try and hide it, I will track this party down!", "order": 38542, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 8, "scene": 11, "line": "Why would I hide it! [giggling]", "order": 38543, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 8, "scene": 12, "line": "Kevin!", "order": 38544, "speaker": "Michael Scott"},
  {"season": 7, "episode": 8, "scene": 12, "line": "Hey, you going tonight?", "order": 38545, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 8, "scene": 12, "line": "Yes, I am. Are you?", "order": 38546, "speaker": "Michael Scott"},
  {"season": 7, "episode": 8, "scene": 12, "line": "Yeah! You gotta go to the boss's party!", "order": 38547, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 8, "scene": 12, "line": "What? No Gabe is... Gabe is not the boss.", "order": 38548, "speaker": "Michael Scott"},
  {"season": 7, "episode": 8, "scene": 12, "line": "No he's not the boss.", "order": 38549, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 8, "scene": 12, "line": "Why did you just say he was the boss?", "order": 38550, "speaker": "Michael Scott"},
  {"season": 7, "episode": 8, "scene": 12, "line": "Cause, you're the boss!", "order": 38551, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 8, "scene": 12, "line": "Yeeee... Guys! Do you consider Gabe to be the boss?", "order": 38552, "speaker": "Michael Scott"},
  {"season": 7, "episode": 8, "scene": 13, "line": "[to Michael at Gabe's apartment] It's make your own pizza night. Isn't Gabe's place so nice?", "order": 38553, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 8, "scene": 13, "line": "Uh huh...", "order": 38554, "speaker": "Michael Scott"},
  {"season": 7, "episode": 8, "scene": 13, "line": "Look at the size of those wine glasses Michael!", "order": 38555, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 8, "scene": 13, "line": "Big...", "order": 38556, "speaker": "Michael Scott"},
  {"season": 7, "episode": 8, "scene": 13, "line": "Those posters used to be real French ads.", "order": 38557, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 8, "scene": 13, "line": "All right!", "order": 38558, "speaker": "Michael Scott"},
  {"season": 7, "episode": 8, "scene": 14, "line": "So these are your cheeses, your ham, your sausages, your herbs, and your vegetables. Here's what's been done, so start creating. Some times it helps to think of a part of the world, and...", "order": 38559, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 8, "scene": 14, "line": "[interrupting] Okay okay okay.", "order": 38560, "speaker": "Michael Scott"},
  {"season": 7, "episode": 8, "scene": 15, "line": "Gabe likes to entertain a lot, ad he cooks in an oven, and all that jazz. I just have a different life style. They have these bags of vegetables that steam right inside their own bag. So I'll get a few ingredients, sit down in front of the TV, a bag of vegetables, before you know it I'm ready for bed!", "order": 38561, "speaker": "Michael Scott"},
  {"season": 7, "episode": 8, "scene": 16, "line": "[tossing pizza dough in the air]", "order": 38562, "speaker": "Michael Scott"},
  {"season": 7, "episode": 8, "scene": 16, "line": "You don't really toss the dough.", "order": 38563, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 8, "scene": 16, "line": "Try and destroy the old ways, Gabe, well I will not let you. [throws pizza dough, hits the ceiling]", "order": 38564, "speaker": "Michael Scott"},
  {"season": 7, "episode": 8, "scene": 17, "line": "[walking with Jim and Pam] Welcome to my Man Cave! I did my senior year in prog in Japan. Best year of my life.", "order": 38565, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 8, "scene": 17, "line": "You play? [referring to keyboard]", "order": 38566, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 8, "scene": 17, "line": "Oh! I like to play soundscapes. I imagine one instant of a song, expanded to be the size of the universe.", "order": 38567, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 8, "scene": 17, "line": "You can't even do that.", "order": 38568, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 8, "scene": 17, "line": "[crying]", "order": 38569, "speaker": "Cece"},
  {"season": 7, "episode": 8, "scene": 17, "line": "She's up! Great!", "order": 38570, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 8, "scene": 18, "line": "Cece, is reverse cycling, which means she sleeps all day and is up all night. Which basically means I'm up all day and I'm up all night. And if it doesn't stop soon, I am going to be up all night.", "order": 38571, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 8, "scene": 19, "line": "[peeking into various rooms] The show's starting. The show's starting! The show's starting! Show's starting! [Glee begins as all get nearby to watch]", "order": 38572, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 8, "scene": 19, "line": "[to Kelly] Who's that?", "order": 38573, "speaker": "Phyllis Vance"},
  {"season": 7, "episode": 8, "scene": 19, "line": "Finn.", "order": 38574, "speaker": "Kelly Kapoor"},
  {"season": 7, "episode": 8, "scene": 19, "line": "Who's that?", "order": 38575, "speaker": "Phyllis Vance"},
  {"season": 7, "episode": 8, "scene": 19, "line": "Rachel.", "order": 38576, "speaker": "Kelly Kapoor"},
  {"season": 7, "episode": 8, "scene": 19, "line": "Which one's Glee?", "order": 38577, "speaker": "Phyllis Vance"},
  {"season": 7, "episode": 8, "scene": 19, "line": "You have to stop.", "order": 38578, "speaker": "Kelly Kapoor"},
  {"season": 7, "episode": 8, "scene": 19, "line": "Where's Michael? Where's Michael? We can't start without Michael! Oh here he is! Ohhhh. Yayyy, and let the show begin! [turning up volume]", "order": 38579, "speaker": "Michael Scott"},
  {"season": 7, "episode": 8, "scene": 19, "line": "[takes remote] It's a little loud...", "order": 38580, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 8, "scene": 19, "line": "Actually I think it's not loud enough. May I? Thank you sah! [takes remote, raises volume] There we go. Glee right? Rock and roll! Turn it up to elevaan! Spinal Cord!", "order": 38581, "speaker": "Michael Scott"},
  {"season": 7, "episode": 8, "scene": 19, "line": "[lowers volum] Some of us are trying to have a conversation.", "order": 38582, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 8, "scene": 19, "line": "Well some of us are trying to have a...", "order": 38583, "speaker": "Michael Scott"},
  {"season": 7, "episode": 8, "scene": 19, "line": "I'll just turn the captions on.", "order": 38584, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 8, "scene": 19, "line": "[grabs different remote from the table] Well I will turn up the volume.", "order": 38585, "speaker": "Michael Scott"},
  {"season": 7, "episode": 8, "scene": 19, "line": "That's for the other box.", "order": 38586, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 8, "scene": 19, "line": "Okay... Heeeere's what we're gonna do! Right down the hall is a bed room. All the real Glee fans are gonna go down the hall to the real Glee party in the bedroom, follow me! Where we can crank it!", "order": 38587, "speaker": "Michael Scott"},
  {"season": 7, "episode": 8, "scene": 20, "line": "[noise on TV, Oscar pauses show] That one! She's been in a couple episodes of Friday Night...", "order": 38588, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 8, "scene": 20, "line": "You know what? I'm so confused. Is this a Glee watching party? Or a Glee pausing party? 'Cause we keep stopping it, to get a history lesson from...", "order": 38589, "speaker": "Kelly Kapoor"},
  {"season": 7, "episode": 8, "scene": 20, "line": "You know what? I didn't read the rules, I didn't know the rules. There, it's on. I didn't read the rule book.", "order": 38590, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 8, "scene": 21, "line": "[enters on Michael alone sitting on a bed watching Glee] Michael? Maybe you should try gong in the other room again. You can have my seat. It's closer to the TV. It'll seem louder.", "order": 38591, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 8, "scene": 21, "line": "Pam I have a loaded gun in my desk at work. If I ever start acting like that weenie Gabe I want you to take that gun, and I want you to shoot me like a hundred times in the groin until I'm dead. Okay?", "order": 38592, "speaker": "Michael Scott"},
  {"season": 7, "episode": 8, "scene": 21, "line": "You have a gun in your desk?", "order": 38593, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 8, "scene": 22, "line": "[in Gabe's bedroom with Andy] Hey! Marantz Tubes Sterio. From the 1970's. I respect that. I respect that a lot. Oh hoho. The five Chinese Berilidy Herbs. No kidding. This is powdered seahorse. They say that fifteen Chinese soldiers fought off the entire army of Gangus Kong just using this stuff. You know what they say...", "order": 38594, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 8, "scene": 22, "line": "No what?", "order": 38595, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 8, "scene": 22, "line": "What is this, Samurai wood cuts? Yep. Pretty erotic.", "order": 38596, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 8, "scene": 22, "line": "Yeah.", "order": 38597, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 8, "scene": 23, "line": "[Pam in the background struggling with Cece] Better men than Gabe have tried to be my boss. David got fired. Charles got fired. Jan went crazy. Ed Truck, who I liked, got decapitated.", "order": 38598, "speaker": "Michael Scott"},
  {"season": 7, "episode": 8, "scene": 23, "line": "Michael, I find it absolutely disgraceful that no one followed you in here for your walk-out. I took the liberty of making a list of everyone who didn't follow you in here. Jim. Oscar. Creed. Me, at first.", "order": 38599, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 8, "scene": 23, "line": "Maybe I should go.", "order": 38600, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 8, "scene": 23, "line": "[continuing] Kelly. Kev- [to Pam] Are you gonna quiet that baby? Or do I have to? [Dwight grabs Cece and makes noises, Cece stops crying] In the Schrute family, the youngest child always raises the others. I've been raising children since I was a baby.", "order": 38601, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 8, "scene": 24, "line": "[to Andy] Take a shot.", "order": 38602, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 8, "scene": 24, "line": "Oh thanks.", "order": 38603, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 8, "scene": 24, "line": "Wow, so much Asia stuff. I wonder if there's a guy in China right now, looking at a bunch of our stuff.", "order": 38604, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 8, "scene": 24, "line": "Why does Erin like Gabe?", "order": 38605, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 8, "scene": 24, "line": "Andy look, all I know is that if I was a girl, and I had to choose between the tall dude who loved Asia, and the you looking dude who loves sweaters and wearing sweaters... I'd choose you.", "order": 38606, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 8, "scene": 24, "line": "That's really nice, thank you.", "order": 38607, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 8, "scene": 24, "line": "And I'd blow your mind.", "order": 38608, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 8, "scene": 24, "line": "[pours powdered seahorse into his wine glass]", "order": 38609, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 8, "scene": 25, "line": "[changes channel during commercial, sports scores appear on screen.]", "order": 38610, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 8, "scene": 25, "line": "Jim what are you doing?", "order": 38611, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 8, "scene": 25, "line": "Oh, it was commercial break so I just wanted to check the scores.", "order": 38612, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 8, "scene": 25, "line": "Flip it back please.", "order": 38613, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 8, "scene": 25, "line": "Okay... [changes back to Glee]", "order": 38614, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 8, "scene": 25, "line": "The show's back on, what happened?", "order": 38615, "speaker": "Kelly Kapoor"},
  {"season": 7, "episode": 8, "scene": 25, "line": "We're behind.", "order": 38616, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 8, "scene": 25, "line": "Go to the recorded version.", "order": 38617, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 8, "scene": 25, "line": "Oh my God what song was that?", "order": 38618, "speaker": "Kelly Kapoor"},
  {"season": 7, "episode": 8, "scene": 25, "line": "I wasn't recording it.", "order": 38619, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 8, "scene": 25, "line": "What?!", "order": 38620, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 8, "scene": 25, "line": "[texting] What song was it?", "order": 38621, "speaker": "Kelly Kapoor"},
  {"season": 7, "episode": 8, "scene": 25, "line": "Wait, why do you have to record it?", "order": 38622, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 8, "scene": 25, "line": "This is why Erin! We're living it! Start recording now!", "order": 38623, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 8, "scene": 25, "line": "Jim. [Jim gives her the remote]", "order": 38624, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 8, "scene": 25, "line": "Okay, they did Blinded by the Light and they did it with an actual blind guy. Was it worth it Jim? [Jim leaves] 'Cause we missed it!", "order": 38625, "speaker": "Kelly Kapoor"},
  {"season": 7, "episode": 8, "scene": 26, "line": "If I could get her sleeping normally I could get my life back.", "order": 38626, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 8, "scene": 26, "line": "That would be nice wouldn't it?", "order": 38627, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 8, "scene": 26, "line": "I can't even talk about it.", "order": 38628, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 8, "scene": 26, "line": "You know it's not really necessary for me to sit here holding her all night. Just go into Gabe's refrigerator, get a lump of suit, or any kind of congealed animal fat will do rally, tie a piece of string to it and the other end to her toe, put the suit in her mouth, she'll be happy for hours.", "order": 38629, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 8, "scene": 26, "line": "I kind of doubt Gabe has suit.", "order": 38630, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 8, "scene": 26, "line": "Really? Oh... Then here we are.", "order": 38631, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 8, "scene": 26, "line": "Michael. Gabe is making the pigs in a blanket, and he kind of needs some [Dwight shushes her] help so, [Erin whispering] he was wondering if you could help him. [Michael goes with Erin]", "order": 38632, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 8, "scene": 27, "line": "[enters room where Phyllis is pouring wine, using stadium announcer voice] Ladies and gentlemen please welcome, Phyllis Vance! [sniffs her] Isn't that White Diamonds Maya Smith Taylor?", "order": 38633, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 8, "scene": 27, "line": "You know your perfumes!", "order": 38634, "speaker": "Phyllis Vance"},
  {"season": 7, "episode": 8, "scene": 27, "line": "My nanny used to wear that.", "order": 38635, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 8, "scene": 28, "line": "I just ate powdered seahorse! I have to admit I did not think it was gonna work, but it is totally working! [clip of him doing pull ups in Gabe's doorway] I feel exactly like a seahorse! [Seahorse impression] Blub blub blub blub blub blub blub blub.", "order": 38636, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 8, "scene": 29, "line": "Look who I found! [points to Michael]", "order": 38637, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 8, "scene": 29, "line": "Yeah, well I wasn't very hard to find.", "order": 38638, "speaker": "Michael Scott"},
  {"season": 7, "episode": 8, "scene": 29, "line": "Let's go ahead and wash our hands.", "order": 38639, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 8, "scene": 29, "line": "Is this the same dough you used for the pizza crust?", "order": 38640, "speaker": "Michael Scott"},
  {"season": 7, "episode": 8, "scene": 29, "line": "Yep! Waste not right?", "order": 38641, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 8, "scene": 29, "line": "So these are pizza dogs, they aren't pigs in a blanket per se.", "order": 38642, "speaker": "Michael Scott"},
  {"season": 7, "episode": 8, "scene": 29, "line": "Michael knows everything there is to know about snacks,", "order": 38643, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 8, "scene": 29, "line": "Well...", "order": 38644, "speaker": "Michael Scott"},
  {"season": 7, "episode": 8, "scene": 29, "line": "Gabe was born in 1982. He was the longest baby in the hospital.", "order": 38645, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 8, "scene": 29, "line": "He's the longest baby in this room.", "order": 38646, "speaker": "Michael Scott"},
  {"season": 7, "episode": 8, "scene": 29, "line": "What's the longest thing you've ever seen? [Michael chuckles] For me it was the tale from Jets.", "order": 38647, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 8, "scene": 29, "line": "Erin you don't have to...", "order": 38648, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 8, "scene": 29, "line": "You know what Erin you do have to.", "order": 38649, "speaker": "Michael Scott"},
  {"season": 7, "episode": 8, "scene": 29, "line": "Michael! You are making this harder than it has to be.", "order": 38650, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 8, "scene": 29, "line": "[grimacing] That's what she said. [leaves]", "order": 38651, "speaker": "Michael Scott"},
  {"season": 7, "episode": 8, "scene": 30, "line": "[whispering] Hey.", "order": 38652, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 8, "scene": 30, "line": "It's a miracle. She loves him.", "order": 38653, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 8, "scene": 30, "line": "I don't know about, love...", "order": 38654, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 8, "scene": 30, "line": "She loves me.", "order": 38655, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 8, "scene": 30, "line": "[whispers in Dwight's ear] Outside my car in two minutes.", "order": 38656, "speaker": "Angela Martin"},
  {"season": 7, "episode": 8, "scene": 30, "line": "Well something's come up, I have to go.", "order": 38657, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 8, "scene": 30, "line": "No no no no no! She'll wake up!", "order": 38658, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 8, "scene": 30, "line": "I have something to do.", "order": 38659, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 8, "scene": 30, "line": "Look, I know what you have to do, please stay with Cece. Dwight? I've always considered for us to be very good friends. Great friends! Remember your concussion?", "order": 38660, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 8, "scene": 30, "line": "I do. But you married my worst enemy.", "order": 38661, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 8, "scene": 30, "line": "Well I think enemy's a strong word. 'Cause I think we have a really charming back and forth. And-", "order": 38662, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 8, "scene": 30, "line": "Enough. I will require beer and pizza to think this over.", "order": 38663, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 8, "scene": 30, "line": "Absolutely! [starts out]", "order": 38664, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 8, "scene": 30, "line": "From Jim.", "order": 38665, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 8, "scene": 30, "line": "I don't think that's gonna...", "order": 38666, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 8, "scene": 30, "line": "Do it!", "order": 38667, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 8, "scene": 30, "line": "What kind of pizza would you like?", "order": 38668, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 8, "scene": 30, "line": "Surprise me. No! Pepperoni.", "order": 38669, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 8, "scene": 31, "line": "[outside opening Gabe's cable hookup box] Okay... Alright.", "order": 38670, "speaker": "Michael Scott"},
  {"season": 7, "episode": 8, "scene": 32, "line": "And the winner is, by two votes- [TV goes blank]", "order": 38671, "speaker": "TV"},
  {"season": 7, "episode": 8, "scene": 32, "line": "Ahhh! What's going on?!?!", "order": 38672, "speaker": "Kelly Kapoor"},
  {"season": 7, "episode": 8, "scene": 33, "line": "[muttering going on all around, Andy looks sick] Hey Creed, would you read Chinese?", "order": 38673, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 8, "scene": 33, "line": "Beleniege!", "order": 38674, "speaker": "Creed Bratton"},
  {"season": 7, "episode": 8, "scene": 33, "line": "What does this say?", "order": 38675, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 8, "scene": 33, "line": "HI mahhhh. Boo yowww. Gunjo! Ooh uncow!", "order": 38676, "speaker": "Creed Bratton"},
  {"season": 7, "episode": 8, "scene": 33, "line": "[confused, rushes out of the room to the room Erin's in, picks up phone, talking fast] Hello UPS! Still waiting on that five minutes with Erin order. Oh never mind it's here. Oh if it isn't the bell of the ball! [queasily] You throw a lovely party ma lady...", "order": 38677, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 8, "scene": 33, "line": "[concerned] Are you having a good time?", "order": 38678, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 8, "scene": 33, "line": "Did you wear my favorite outfit on purpose?", "order": 38679, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 8, "scene": 33, "line": "Andy you look awful!", "order": 38680, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 8, "scene": 33, "line": "You're four seasons in a day. [heaves] You... got the Autumn thing going on. But if you put on ba- [heaves again] Put if you put on a blue... [stops, is struggling] It's Spring Time in the Rockies!", "order": 38681, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 8, "scene": 33, "line": "Are you alright?", "order": 38682, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 8, "scene": 33, "line": "No! [runs off]", "order": 38683, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 8, "scene": 34, "line": "[referring to the pizza Jim is offering him] Insert it in my mouth.", "order": 38684, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 8, "scene": 34, "line": "That's not gonna make your pizza eating experience any better. Trust me.", "order": 38685, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 8, "scene": 34, "line": "No, try me.", "order": 38686, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 8, "scene": 34, "line": "Jim, just don't think of it as degrading. Think of it as, you happen to be moving the six inches his way and he happens to be biting it.", "order": 38687, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 8, "scene": 34, "line": "I'd prefer for him to think of it as degrading.", "order": 38688, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 8, "scene": 34, "line": "Okay... [puts pizza into Dwight's mouth]", "order": 38689, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 8, "scene": 34, "line": "No. Crust first. [Jim turns the pizza around] Okay, now the beer. [Jim shakes his head no] Beer me Jim. [Jim quickly puts the beer into his face] Ssss. Gentle. [drinks the beer] Now I've gotta go meet Angela.", "order": 38690, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 8, "scene": 34, "line": "What no, wait! You said you were gonna help us!", "order": 38691, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 8, "scene": 34, "line": "I have a legal obligation to Angela. Okay, she needs to be serviced.", "order": 38692, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 8, "scene": 34, "line": "You need to stop talking.", "order": 38693, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 8, "scene": 34, "line": "Oh come on, stop the fake prissy act. We're in the real world. Sex contracts exist!", "order": 38694, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 8, "scene": 34, "line": "Okay fine! There must be some way to get out of it. Even just for tonight!", "order": 38695, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 8, "scene": 34, "line": "Yeah sure, Jim could go see her.", "order": 38696, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 8, "scene": 34, "line": "[unbelievingly] Okay...", "order": 38697, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 8, "scene": 34, "line": "You ever been with the blondes before? It's the big leagues.", "order": 38698, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 8, "scene": 34, "line": "I'm going home, and I'm taking my baby with me.", "order": 38699, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 8, "scene": 34, "line": "No no no, don't. I will go talk to Angela.", "order": 38700, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 8, "scene": 34, "line": "She's in heat. She will eat your face off!", "order": 38701, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 8, "scene": 34, "line": "The reverse cycling ends tonight! [Pam leaves]", "order": 38702, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 8, "scene": 34, "line": "[to Jim] A single piece of pepperoni please.", "order": 38703, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 8, "scene": 34, "line": "I'm not gonna- [cuts himself off, Kevin walks in]", "order": 38704, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 8, "scene": 34, "line": "What are you doing?", "order": 38705, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 8, "scene": 34, "line": "[getting under the bed covers] I wanted to eat a pig in a blanket, in a blanket!", "order": 38706, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 8, "scene": 35, "line": "[throws up on bed] Puts pillow in front of the puke.", "order": 38707, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 8, "scene": 35, "line": "Are you alright?", "order": 38708, "speaker": "Phyllis Vance"},
  {"season": 7, "episode": 8, "scene": 35, "line": "Erin likes Gabe.", "order": 38709, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 8, "scene": 35, "line": "I know. I thought you two were nice together.", "order": 38710, "speaker": "Phyllis Vance"},
  {"season": 7, "episode": 8, "scene": 35, "line": "Do you think that they've ever...", "order": 38711, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 8, "scene": 35, "line": "Made love? I don't know.", "order": 38712, "speaker": "Phyllis Vance"},
  {"season": 7, "episode": 8, "scene": 35, "line": "Well girls tell each other things right?", "order": 38713, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 8, "scene": 35, "line": "I'll see what I can find out.", "order": 38714, "speaker": "Phyllis Vance"},
  {"season": 7, "episode": 8, "scene": 36, "line": "[Michael walks into disarray of people's reactions to the TV going out] Where you been?", "order": 38715, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 8, "scene": 36, "line": "I just went out for a walk.", "order": 38716, "speaker": "Michael Scott"},
  {"season": 7, "episode": 8, "scene": 36, "line": "Cable's out.", "order": 38717, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 8, "scene": 36, "line": "What? Really? That stinks!", "order": 38718, "speaker": "Michael Scott"},
  {"season": 7, "episode": 8, "scene": 36, "line": "It's still on upstairs.", "order": 38719, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 8, "scene": 36, "line": "What does that mean? Party's over? Everybody leaves? Are you sure?", "order": 38720, "speaker": "Michael Scott"},
  {"season": 7, "episode": 8, "scene": 36, "line": "[receiving message on his phone] Hey! Puck and Finn worked it out, and the solo is now a duet.", "order": 38721, "speaker": "Creed Bratton"},
  {"season": 7, "episode": 8, "scene": 36, "line": "Are you kidding me?", "order": 38722, "speaker": "Michael Scott"},
  {"season": 7, "episode": 8, "scene": 36, "line": "No.", "order": 38723, "speaker": "Creed Bratton"},
  {"season": 7, "episode": 8, "scene": 36, "line": "Now that's going on?", "order": 38724, "speaker": "Michael Scott"},
  {"season": 7, "episode": 8, "scene": 36, "line": "I can't believe we're missing that!", "order": 38725, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 8, "scene": 36, "line": "Boy I wish I could see that. You know what? I have an idea. I don't know if it's gonna work, but let me give it this. Okay, I'm gonna give it shot. [hurries out]", "order": 38726, "speaker": "Michael Scott"},
  {"season": 7, "episode": 8, "scene": 36, "line": "Please Michael, just make the Glee happen!", "order": 38727, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 8, "scene": 36, "line": "Good luck Michael!", "order": 38728, "speaker": "Kelly Kapoor"},
  {"season": 7, "episode": 8, "scene": 37, "line": "Erin! Uhm, so how long have you two been going out now?", "order": 38729, "speaker": "Phyllis Vance"},
  {"season": 7, "episode": 8, "scene": 37, "line": "Oh, uhm, like three months.", "order": 38730, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 8, "scene": 37, "line": "And I imagine things are starting to heat up?", "order": 38731, "speaker": "Phyllis Vance"},
  {"season": 7, "episode": 8, "scene": 37, "line": "I don't know.", "order": 38732, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 8, "scene": 37, "line": "Of course you don't wanna rush things! I mean the anticipation can be so exquisite. Bob and I took our time. The first time we saw each other naked we didn't even make love, we just stared at each other until we fell asleep. It was magical.", "order": 38733, "speaker": "Phyllis Vance"},
  {"season": 7, "episode": 8, "scene": 37, "line": "[awkwardly] I have to go Phyllis.", "order": 38734, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 8, "scene": 37, "line": "Magical...", "order": 38735, "speaker": "Phyllis Vance"},
  {"season": 7, "episode": 8, "scene": 38, "line": "[knocking on Angela's car window, Angela is naked inside] Hey in there. It's Pam. It's not Dwight! Don't think it's Dwight!", "order": 38736, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 8, "scene": 38, "line": "What are you doing here?", "order": 38737, "speaker": "Angela Martin"},
  {"season": 7, "episode": 8, "scene": 38, "line": "I know you and Dwight have this weird deal, uhm, he sent me out here to see if you could postpone.", "order": 38738, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 8, "scene": 38, "line": "Are you authorized to do this?", "order": 38739, "speaker": "Angela Martin"},
  {"season": 7, "episode": 8, "scene": 38, "line": "Yes! I have been so authorized.", "order": 38740, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 8, "scene": 38, "line": "Ok, uhm, then tell him that we'll do this tomorrow night instead.", "order": 38741, "speaker": "Angela Martin"},
  {"season": 7, "episode": 8, "scene": 38, "line": "Okay.", "order": 38742, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 8, "scene": 38, "line": "Wait Pam! How did he seem? Like, you know, did he seem disappointed?", "order": 38743, "speaker": "Angela Martin"},
  {"season": 7, "episode": 8, "scene": 38, "line": "You know, there are a lot of guys out there who would just love and appreciate a-", "order": 38744, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 8, "scene": 38, "line": "You know what Pam just save it.", "order": 38745, "speaker": "Angela Martin"},
  {"season": 7, "episode": 8, "scene": 39, "line": "[Michael is outside fixing the cable box] You did this?", "order": 38746, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 8, "scene": 39, "line": "No I was just check- Yeah. Yes I did, yeah yeah.", "order": 38747, "speaker": "Michael Scott"},
  {"season": 7, "episode": 8, "scene": 39, "line": "Why don't you like him?", "order": 38748, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 8, "scene": 39, "line": "What is there to like? He's just, he's a weird little skeevy guy with no waist, why do you care whether we like him or not?", "order": 38749, "speaker": "Michael Scott"},
  {"season": 7, "episode": 8, "scene": 39, "line": "I care if", "order": 38750, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 8, "scene": 39, "line": "Why? I'm not your father. [Erin looks sad] All right.", "order": 38751, "speaker": "Michael Scott"},
  {"season": 7, "episode": 8, "scene": 39, "line": "Okay...", "order": 38752, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 8, "scene": 39, "line": "Go to your room.", "order": 38753, "speaker": "Michael Scott"},
  {"season": 7, "episode": 8, "scene": 39, "line": "What? [confused]", "order": 38754, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 8, "scene": 39, "line": "Go to your room young lady!", "order": 38755, "speaker": "Michael Scott"},
  {"season": 7, "episode": 8, "scene": 39, "line": "[slowly getting it] Uhm, I'm not going to my room.", "order": 38756, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 8, "scene": 39, "line": "You listen to me. You listen good. You are are", "order": 38757, "speaker": "Michael Scott"},
  {"season": 7, "episode": 8, "scene": 39, "line": "You listen to me. You are", "order": 38758, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 8, "scene": 39, "line": "As long as you are living under this roof you are going to do what I say.", "order": 38759, "speaker": "Michael Scott"},
  {"season": 7, "episode": 8, "scene": 39, "line": "I hate your roof!", "order": 38760, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 8, "scene": 39, "line": "Oh do not raise your voice to me!", "order": 38761, "speaker": "Michael Scott"},
  {"season": 7, "episode": 8, "scene": 39, "line": "I'll raise it how I want! I'll raise the roof!", "order": 38762, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 8, "scene": 39, "line": "Gahh, I will pull this car over!", "order": 38763, "speaker": "Michael Scott"},
  {"season": 7, "episode": 8, "scene": 39, "line": "I hate it! I hate your car!", "order": 38764, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 8, "scene": 40, "line": "See ya Oscar! [Oscar leaves]", "order": 38765, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 8, "scene": 40, "line": "[enters, looks to Gabe] If you break that girl's heart, I will kill you. It's just a figure of speech. But seriously, if you break that girl's heart I will literally kill you and your entire family.", "order": 38766, "speaker": "Michael Scott"},
  {"season": 7, "episode": 8, "scene": 41, "line": "Gabe, just go and have fun with Erin. But not too much fun! That girl's gonna turn my hair gray.", "order": 38767, "speaker": "Michael Scott"},
  {"season": 7, "episode": 8, "scene": 42, "line": "[Andy is leaning over toilet] What happened?", "order": 38768, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 8, "scene": 42, "line": "I accidentally ate some seahorses.", "order": 38769, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 8, "scene": 42, "line": "How much?", "order": 38770, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 8, "scene": 42, "line": "I didn't know it's powdered, so like four or five, I don't know.", "order": 38771, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 8, "scene": 42, "line": "I've got just the thing! [leaves, comes back with a synthesizer] This one's called Earth Rise, on the Moon. [music plays]", "order": 38772, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 8, "scene": 42, "line": "That's so beautiful.", "order": 38773, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 9, "scene": 1, "line": "[lights and power go off] Uh-Oh. Okay, okay, nobody panic. Listen Up, listen up. [Turns On Flashlight and holds it to his face] Everyone, follow me to the shelter. We've got enough food for 14 days. After that, we have a difficult conversation.", "order": 38774, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 9, "scene": 1, "line": "[as the power and lights come back on] My Bad. Space Heater and Fan were both on high, plugged into the same outlet.", "order": 38775, "speaker": "Michael Scott"},
  {"season": 7, "episode": 9, "scene": 1, "line": "Uh, it's saying the server went down? Does Anybody know that password? 'Cause otherwise we can't do any work.", "order": 38776, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 9, "scene": 1, "line": "Uh... try 'password'.", "order": 38777, "speaker": "Michael Scott"},
  {"season": 7, "episode": 9, "scene": 1, "line": "Nope.", "order": 38778, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 9, "scene": 1, "line": "Try zero, Zero, Zero, Zero, zero, zero.", "order": 38779, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 9, "scene": 1, "line": "No.", "order": 38780, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 9, "scene": 1, "line": "Okay, now try Zero, Zero, Zero, Zero, zero, one.", "order": 38781, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 9, "scene": 1, "line": "Okay, I'm not doing every number.", "order": 38782, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 9, "scene": 1, "line": "Wait, um, does anyone remember when it was set up?", "order": 38783, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 9, "scene": 1, "line": "Uh... it was like eight years ago?", "order": 38784, "speaker": "Michael Scott"},
  {"season": 7, "episode": 9, "scene": 1, "line": "Lord of the Ring stuff? I don't know, I'm just trying to think of things that were happening at the time.", "order": 38785, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 9, "scene": 1, "line": "Um, everyone was getting their driver's license.", "order": 38786, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 9, "scene": 1, "line": "Why don't we just call the I.T. Guy who set it up? What's the name of the guy in Glasses, again?", "order": 38787, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 9, "scene": 1, "line": "Okay, moving backwards, our I.T. Guys have been... Glasses, Turban, Ear Hair, Fatty 3, Shorts, Fatty 2, Lozenge, and Fatso. I think Lozenge was the one who installed it.", "order": 38788, "speaker": "Michael Scott"},
  {"season": 7, "episode": 9, "scene": 1, "line": "I got it, try, um--[Coughs]", "order": 38789, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 9, "scene": 1, "line": "You know what? It made me laugh when I heard it, but Pam got really offended.", "order": 38790, "speaker": "Michael Scott"},
  {"season": 7, "episode": 9, "scene": 1, "line": "Big Boobs.", "order": 38791, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 9, "scene": 1, "line": "Drama Queen?", "order": 38792, "speaker": "Meridith"},
  {"season": 7, "episode": 9, "scene": 1, "line": "Nosy?", "order": 38793, "speaker": "Angela Martin"},
  {"season": 7, "episode": 9, "scene": 1, "line": "You're typing 'Big Boobs'?", "order": 38794, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 9, "scene": 1, "line": "I'm trying everything.", "order": 38795, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 9, "scene": 1, "line": "Try 'Big Boobs' with a 'Z'.", "order": 38796, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 9, "scene": 1, "line": "That's-- [ding] the password. We're in.", "order": 38797, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 9, "scene": 1, "line": "All Right", "order": 38798, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 9, "scene": 1, "line": "Wow.", "order": 38799, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 9, "scene": 1, "line": "Yes.", "order": 38800, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 9, "scene": 1, "line": "The important thing is... this kept us secure people.", "order": 38801, "speaker": "Michael Scott"},
  {"season": 7, "episode": 9, "scene": 2, "line": "Morning.", "order": 38802, "speaker": "Michael Scott"},
  {"season": 7, "episode": 9, "scene": 2, "line": "How's It Going?", "order": 38803, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 9, "scene": 3, "line": "Hey, I'm WUPHF.", "order": 38804, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 9, "scene": 3, "line": "[wearing Ryan's glasses] I'm Facebook.", "order": 38805, "speaker": "Michael Scott"},
  {"season": 7, "episode": 9, "scene": 3, "line": "What's up Facebook?", "order": 38806, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 9, "scene": 3, "line": "I sent you a Facebook message yesterday, I still haven't heard anything back.", "order": 38807, "speaker": "Michael Scott"},
  {"season": 7, "episode": 9, "scene": 3, "line": "You should've sent me a WUPHF.", "order": 38808, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 9, "scene": 3, "line": "A what?", "order": 38809, "speaker": "Michael Scott"},
  {"season": 7, "episode": 9, "scene": 3, "line": "[pulls out Blackberry] When you send a WUPHF, it goes to your home phone, Email, Facebook, Twitter and homescreen. All at the same time. [phone rings, computer chimes, printer starts up]", "order": 38810, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 9, "scene": 3, "line": "WUPHF.", "order": 38811, "speaker": "Robotic Voice"},
  {"season": 7, "episode": 9, "scene": 3, "line": "WUPHF!", "order": 38812, "speaker": "Ryan Howard & Michael Scott"},
  {"season": 7, "episode": 9, "scene": 3, "line": "Dot com!", "order": 38813, "speaker": "Michael Scott"},
  {"season": 7, "episode": 9, "scene": 4, "line": "[getting copies from the color copier behind the receptionist desk] Thanks Erin.", "order": 38814, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 9, "scene": 4, "line": "Sure.", "order": 38815, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 9, "scene": 4, "line": "Pam.", "order": 38816, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 9, "scene": 4, "line": "Mm-Hmm?", "order": 38817, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 9, "scene": 4, "line": "I don't wanna be a lousy snitch.", "order": 38818, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 9, "scene": 4, "line": "Okay.", "order": 38819, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 9, "scene": 4, "line": "Ryan has been using the color printer for his business a lot, and as Office Administrator--", "order": 38820, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 9, "scene": 4, "line": "Oh, it's okay.", "order": 38821, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 9, "scene": 4, "line": "But it's not for--", "order": 38822, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 9, "scene": 4, "line": "Let it go.", "order": 38823, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 9, "scene": 5, "line": "Yes, I invested in WUPHF. It's actually a great idea and I can't believe it didn't exist before. And I know Ryan's kind of a sketchy guy, but hey, I married Jim. I've done my part for the nice guy. Now I want a bedroom set.", "order": 38824, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 9, "scene": 6, "line": "I think an investor's ski trip is the best idea. It will be a bonding experience.", "order": 38825, "speaker": "Michael Scott"},
  {"season": 7, "episode": 9, "scene": 6, "line": "I love it.", "order": 38826, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 9, "scene": 6, "line": "I was looking at a trail map, and I was thinking that we should start on a bunny hill, because I've never actually been skiing. And then work our way up to death drop.", "order": 38827, "speaker": "Michael Scott"},
  {"season": 7, "episode": 9, "scene": 6, "line": "Great, so we got our first offer to buy the company.", "order": 38828, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 9, "scene": 6, "line": "Your kidding?!", "order": 38829, "speaker": "Michael Scott"},
  {"season": 7, "episode": 9, "scene": 6, "line": "We could sell, but why think so small? We can just get a couple more people involved, really do this thing our way.", "order": 38830, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 9, "scene": 6, "line": "You and me.", "order": 38831, "speaker": "Michael Scott"},
  {"season": 7, "episode": 9, "scene": 6, "line": "New Investors are key, though. That's actually why I came in today.", "order": 38832, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 9, "scene": 6, "line": "Don't you work here full-time?", "order": 38833, "speaker": "Michael Scott"},
  {"season": 7, "episode": 9, "scene": 6, "line": "[chuckles] Today, I was thinking I would sit down with a few of the people who haven't invested yet.", "order": 38834, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 9, "scene": 6, "line": "Mm-Hmm.", "order": 38835, "speaker": "Michael Scott"},
  {"season": 7, "episode": 9, "scene": 6, "line": "Or we could do that together, if that sounds fun to you.", "order": 38836, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 9, "scene": 6, "line": "That does sound fun to me.", "order": 38837, "speaker": "Michael Scott"},
  {"season": 7, "episode": 9, "scene": 6, "line": "Awesome.", "order": 38838, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 9, "scene": 7, "line": "[out in the parking lot with a guy unloading hay bales while Dwight sifts through the hay] Ah, this is good hay. Yeah. This is the good stuff, mattress quality.", "order": 38839, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 9, "scene": 7, "line": "Every fall, my Uncle Eldred used to build us a maze out of hay bales for us kids to play in. We called it 'Hay Place'. Eldred Called it 'Hay World'. Eventually lawyers were brought in. But that's all behind us. Hay Place lives on! [guy comes and sticks a Bale of Hay right behing Dwight and knocks it into Dwight] Watch it, watch it.", "order": 38840, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 9, "scene": 8, "line": "Hello! Welcome to Hay Place! A place for hay.", "order": 38841, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 9, "scene": 8, "line": "Don't forget to make a broom.", "order": 38842, "speaker": "Guy"},
  {"season": 7, "episode": 9, "scene": 8, "line": "Oh, and the Petting Zoo closes at 2:00, and the Goat Roast is at 3:00. Come on in, enjoy!", "order": 38843, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 9, "scene": 8, "line": "Hey, I'm kind of in the mood for a roll in the hay.", "order": 38844, "speaker": "Angela Martin"},
  {"season": 7, "episode": 9, "scene": 8, "line": "Roll in the hay, five dollars.", "order": 38845, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 9, "scene": 8, "line": "No, I meant... [motions you and me]", "order": 38846, "speaker": "Angela Martin"},
  {"season": 7, "episode": 9, "scene": 8, "line": "Oh, our contract.", "order": 38847, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 9, "scene": 8, "line": "Mm-Hmm.", "order": 38848, "speaker": "Angela Martin"},
  {"season": 7, "episode": 9, "scene": 8, "line": "Yes, why don't we meet at the usual spot. [looks at his watch] Uh... I've got a half an hour during lunch in-between the historical reenactment of the dunmore farm slaughters and the onion boil.", "order": 38849, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 9, "scene": 8, "line": "Perfect!", "order": 38850, "speaker": "Angela Martin"},
  {"season": 7, "episode": 9, "scene": 8, "line": "So, uh... [notices a kid walking on a bale of hay] Excuse me, get off of there! Those are show bales, not play bales.", "order": 38851, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 9, "scene": 9, "line": "[on phone] All right, Thank you, I will--I will send the contracts over right away. All Right. [hangs up the phone and rubs his eyes] Whoo! [Erin jumps in the background]", "order": 38852, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 9, "scene": 10, "line": "I am on the first hot sale streak of my life. I think it all comes from feeding Cece, because no matter how much she resists, I sell her those carrots. Let's be honest--if I can make mushed carrots seem better than a boob... I can pretty much sell anything. [laughs]", "order": 38853, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 9, "scene": 11, "line": "Commission comes out to... dunh-duh-na-dah! Zero.", "order": 38854, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 9, "scene": 11, "line": "Mm-Kay, Well, it can't be zero, Kev.", "order": 38855, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 9, "scene": 11, "line": "I entered the sale, and I hit enter, and I said, 'dunh-duh-na-dah!' I'm pretty sure I timesed it right.", "order": 38856, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 9, "scene": 11, "line": "You met your commission cap last week. So from here until the end of the fiscal year, your commission will be zero.", "order": 38857, "speaker": "Angela Martin"},
  {"season": 7, "episode": 9, "scene": 11, "line": "What are you talking about? We--we have a commission cap?", "order": 38858, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 9, "scene": 11, "line": "It's a new corporate policy.", "order": 38859, "speaker": "Angela Martin"},
  {"season": 7, "episode": 9, "scene": 11, "line": "Dunh-duh-na-dah!", "order": 38860, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 9, "scene": 12, "line": "Is this job really about the money for you, Jim? I mean, isn't this where you fell in love?", "order": 38861, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 9, "scene": 12, "line": "A commission cap takes away my incentive to sell. So you realize I now have no reason to work, right?", "order": 38862, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 9, "scene": 12, "line": "When you're dealing with a large organization, sometimes you have to put up with policies you don't like. I wish my gym didn't allow full nudity in the locker room. Okay, seeing these old guys walking around naked feels almost passive-aggressive. But I deal with it. 'Cause it's policy. See what I mean?", "order": 38863, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 9, "scene": 12, "line": "Nope.", "order": 38864, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 9, "scene": 12, "line": "Nudity makes me uncomfortable.", "order": 38865, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 9, "scene": 12, "line": "Okay.", "order": 38866, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 9, "scene": 12, "line": "My gym allows it. I wish they didn't, but it's policy. So I respect it, and I just--[put's his hands cupped by his face and turns away] keep a low--you know, I look away. Think about your commission cap as a naked old man in a gym locker room.", "order": 38867, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 9, "scene": 13, "line": "Excuse me, everyone... SEX! Now that I have your attention...", "order": 38868, "speaker": "Michael Scott"},
  {"season": 7, "episode": 9, "scene": 13, "line": "You don't have our attention.", "order": 38869, "speaker": "Stanley Hudson"},
  {"season": 7, "episode": 9, "scene": 13, "line": "MONEY!", "order": 38870, "speaker": "Michael Scott"},
  {"season": 7, "episode": 9, "scene": 13, "line": "I'm listening.", "order": 38871, "speaker": "Stanley Hudson"},
  {"season": 7, "episode": 9, "scene": 13, "line": "You had me at 'Sex.'", "order": 38872, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 9, "scene": 13, "line": "Pervert.", "order": 38873, "speaker": "Michael Scott"},
  {"season": 7, "episode": 9, "scene": 13, "line": "You have all of our attention just by screaming anything.", "order": 38874, "speaker": "Phyllis Vance"},
  {"season": 7, "episode": 9, "scene": 13, "line": "That's good to know. [screams]", "order": 38875, "speaker": "Michael Scott"},
  {"season": 7, "episode": 9, "scene": 13, "line": "What do you want to say, Michael?", "order": 38876, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 9, "scene": 13, "line": "Wuphf! All right, I've warmed them up for you. You have the floor, Ryan.", "order": 38877, "speaker": "Michael Scott"},
  {"season": 7, "episode": 9, "scene": 13, "line": "How's everybody doing?!", "order": 38878, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 9, "scene": 13, "line": "Whoo!", "order": 38879, "speaker": "Michael Scott"},
  {"season": 7, "episode": 9, "scene": 13, "line": "Good! Now, a lot of you have already staked out your corner of the WUPHF empire. Raise your hand if I'm describing you. [Michael, Pam, Daryl, and Stanley raise their hands]", "order": 38880, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 9, "scene": 14, "line": "Yes, I have a dream. And It's not some M.L.K. Dream for Equality. I want to own a decommissioned Lighthouse. And I want to live at the top. And nobody knows I live there. And there's a button that I can press, and it'll launch that lighthouse into space.", "order": 38881, "speaker": "Stanley Hudson"},
  {"season": 7, "episode": 9, "scene": 15, "line": "For my current investors, things are going great.", "order": 38882, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 9, "scene": 15, "line": "We already have a buyer.", "order": 38883, "speaker": "Michael Scott"},
  {"season": 7, "episode": 9, "scene": 15, "line": "Really?! Who?!", "order": 38884, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 9, "scene": 15, "line": "Washington University. I don't even want to think about selling until our numbers get into the billions.", "order": 38885, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 9, "scene": 15, "line": "At least.", "order": 38886, "speaker": "Michael Scott"},
  {"season": 7, "episode": 9, "scene": 15, "line": "So I've decided to open up a second round of investment to friends and family.", "order": 38887, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 9, "scene": 15, "line": "Oh, God. Hold on to your wallets, ladies.", "order": 38888, "speaker": "Phyllis Vance"},
  {"season": 7, "episode": 9, "scene": 16, "line": "Did you ever think of WUPHF as an emergency notification system, like, for college campuses with shooters or gas leaks?", "order": 38889, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 9, "scene": 16, "line": "No, no--Oscar, it's not--it's not a digital rape whistle. WUPHF is about fun.", "order": 38890, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 9, "scene": 16, "line": "That's right!", "order": 38891, "speaker": "Michael Scott"},
  {"season": 7, "episode": 9, "scene": 16, "line": "Fun, communication, connection.", "order": 38892, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 9, "scene": 16, "line": "What's your money situation?", "order": 38893, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 9, "scene": 16, "line": "Well, it's tight, as with any start-up.", "order": 38894, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 9, "scene": 16, "line": "Sure, how long can you sustain this without a cash infusion?", "order": 38895, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 9, "scene": 16, "line": "We have nine solid days. I love these questions. Keep 'em coming.", "order": 38896, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 9, "scene": 16, "line": "I'm sorry, nine days until what?", "order": 38897, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 9, "scene": 16, "line": "Bankruptcy?", "order": 38898, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 9, "scene": 16, "line": "Wait, Ryan, so this could be over by the weekend?", "order": 38899, "speaker": "Michael Scott"},
  {"season": 7, "episode": 9, "scene": 16, "line": "How long do you think a week is?", "order": 38900, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 9, "scene": 16, "line": "No, not this weekend; next weekend. We were gonna go on the investors ski trip. I bought poles.", "order": 38901, "speaker": "Michael Scott"},
  {"season": 7, "episode": 9, "scene": 17, "line": "Well, since I have no incentive to sell, I've cleaned my car, organized my desk, and taken a multivitamin. So... what now?", "order": 38902, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 9, "scene": 18, "line": "Pam. Okay, now I've tried everything.", "order": 38903, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 9, "scene": 18, "line": "Did you prank Dwight?", "order": 38904, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 9, "scene": 18, "line": "No.", "order": 38905, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 9, "scene": 18, "line": "Well, you like that.", "order": 38906, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 9, "scene": 18, "line": "Yeah, it's not as fun if I'm not blowing off work.", "order": 38907, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 9, "scene": 18, "line": "I love you, but I'm kinda busy. So I need you to figure this one out on your own.", "order": 38908, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 9, "scene": 18, "line": "[blows a raspberry, Erin looks shocked, Kevin chuckles]", "order": 38909, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 9, "scene": 19, "line": "Oscar said WUPHF only has enough money for nine days.", "order": 38910, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 9, "scene": 19, "line": "[colorful poster with numbers] Check this out.", "order": 38911, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 9, "scene": 19, "line": "All that color.", "order": 38912, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 9, "scene": 19, "line": "Look at that. Triple your investment by January.", "order": 38913, "speaker": "Michael Scott"},
  {"season": 7, "episode": 9, "scene": 19, "line": "Anyone can just throw numbes up on a chart. What are you doing to make it happen?", "order": 38914, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 9, "scene": 19, "line": "Well, earlier today, I was emailing someone by the name of John Legend.", "order": 38915, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 9, "scene": 19, "line": "There you go.", "order": 38916, "speaker": "Michael Scott"},
  {"season": 7, "episode": 9, "scene": 19, "line": "You have his email?", "order": 38917, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 9, "scene": 19, "line": "A lot of these guys are just Mac.com, @ their website, whatever. We get that guy WUPHFing, it's all over.", "order": 38918, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 9, "scene": 19, "line": "I want my money back.", "order": 38919, "speaker": "Stanley Hudson"},
  {"season": 7, "episode": 9, "scene": 19, "line": "Do not talk like that, Stanley.", "order": 38920, "speaker": "Michael Scott"},
  {"season": 7, "episode": 9, "scene": 19, "line": "Ryan, just tell us your plan.", "order": 38921, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 9, "scene": 19, "line": "Okay, all right. I know what you wanna see. I'll be right back.", "order": 38922, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 9, "scene": 20, "line": "[hits the back of a Dunder-Mifflin Delivery Truck with his hand] Hey kids, was that fun?", "order": 38923, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 9, "scene": 20, "line": "Yeah!", "order": 38924, "speaker": "Kids"},
  {"season": 7, "episode": 9, "scene": 20, "line": "All Right!", "order": 38925, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 9, "scene": 20, "line": "I wanna go on it again.", "order": 38926, "speaker": "Kid"},
  {"season": 7, "episode": 9, "scene": 20, "line": "Oh, I bet you do. You know what? When I was a little kid, they couldn't get me off the hayride! But it's gonna cost you three more bucks. [kid gives him three more bucks]", "order": 38927, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 9, "scene": 21, "line": "Wow! This brings back memories. Hay stacking, hay throwing... and at the end of it all, one lucky boy would be crowned Hay King. I always wanted to be Hay King. The world shines on Mose.", "order": 38928, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 9, "scene": 22, "line": "[waiting for Dwight at their meeting place, slams the door shut]", "order": 38929, "speaker": "Angela Martin"},
  {"season": 7, "episode": 9, "scene": 23, "line": "Where have you been?! I've been waiting.", "order": 38930, "speaker": "Angela Martin"},
  {"season": 7, "episode": 9, "scene": 23, "line": "Uh, can we move this to the end of the day?", "order": 38931, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 9, "scene": 23, "line": "No, I--I think you should make time for it.", "order": 38932, "speaker": "Angela Martin"},
  {"season": 7, "episode": 9, "scene": 23, "line": "I found the needle in the haystack!", "order": 38933, "speaker": "Little Girl"},
  {"season": 7, "episode": 9, "scene": 23, "line": "Hey, congratulations! Do you know what your prize is?", "order": 38934, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 9, "scene": 23, "line": "I don't know!", "order": 38935, "speaker": "Little Girl"},
  {"season": 7, "episode": 9, "scene": 23, "line": "Nothing. Life Lesson? Some tasks are not worth doing.", "order": 38936, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 9, "scene": 23, "line": "Dwight?", "order": 38937, "speaker": "Angela Martin"},
  {"season": 7, "episode": 9, "scene": 24, "line": "WUPHF T-Shirts. Who wants one? Just imagine that you're at spring break, Daytona Beach.", "order": 38938, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 9, "scene": 24, "line": "Here we go.", "order": 38939, "speaker": "Michael Scott"},
  {"season": 7, "episode": 9, "scene": 24, "line": "Everyone's like, 'Hey Dude, whats up with all the hotties in the WUPHF shirts?' Or, Uh, 'Hey, what's up with that helicopter? It's Ry from WUPHF!'", "order": 38940, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 9, "scene": 24, "line": "It's Ryan the WUPHF guy!'", "order": 38941, "speaker": "Michael Scott"},
  {"season": 7, "episode": 9, "scene": 24, "line": "Yo, Ry from WUPHF!'", "order": 38942, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 9, "scene": 24, "line": "He's up there.'", "order": 38943, "speaker": "Michael Scott"},
  {"season": 7, "episode": 9, "scene": 24, "line": "What's he dropping?! Wow, what's falling on us, man?!' WUPHF condoms! 50,000 condoms out of the sky!", "order": 38944, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 9, "scene": 24, "line": "Look at that! He threw 'em on the ground!", "order": 38945, "speaker": "Michael Scott"},
  {"season": 7, "episode": 9, "scene": 24, "line": "Look, this is a marketing campgain. You got nine days. Let's say you do get the money. What are you going to do with it?", "order": 38946, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 9, "scene": 24, "line": "The first lesson of Silicon Valley, actually, is that you only think about the user, the experience. You actually don't think about the money. Ever.", "order": 38947, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 9, "scene": 24, "line": "That sounds weird.", "order": 38948, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 9, "scene": 24, "line": "No, it's not weird, Andy, and you know what else? We have an offer from Washington University. So monetize that.", "order": 38949, "speaker": "Michael Scott"},
  {"season": 7, "episode": 9, "scene": 24, "line": "[walks in] Are you guys meeting about WUPHF? You know that that was my idea, right?", "order": 38950, "speaker": "Kelly Kapoor"},
  {"season": 7, "episode": 9, "scene": 25, "line": "I said to Ryan, 'I try to call you, and you don't have your phone. I try to I.M. You, and you're not online. I wish there was a way that I could do everything all at once, and I could just be like this little dog going, 'Ruff! Ruff! Ruff! Ruff! Ruff!''", "order": 38951, "speaker": "Kelly Kapoor"},
  {"season": 7, "episode": 9, "scene": 25, "line": "Baby, Baby, Baby--", "order": 38952, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 9, "scene": 25, "line": "I think I'm gonna stay here.", "order": 38953, "speaker": "Kelly Kapoor"},
  {"season": 7, "episode": 9, "scene": 25, "line": "No, no, no, no, no.", "order": 38954, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 9, "scene": 25, "line": "I do, I do. This isn't right.", "order": 38955, "speaker": "Kelly Kapoor"},
  {"season": 7, "episode": 9, "scene": 25, "line": "I help you with your things and you help me with my things.", "order": 38956, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 9, "scene": 25, "line": "But I--it's not right.", "order": 38957, "speaker": "Kelly Kapoor"},
  {"season": 7, "episode": 9, "scene": 25, "line": "[whispering] I got this, it's okay. Don't worry about this.", "order": 38958, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 9, "scene": 25, "line": "Is it any particular branch of Washington University?", "order": 38959, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 9, "scene": 25, "line": "The Washington University Public Health Fund.", "order": 38960, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 9, "scene": 25, "line": "W.U.P.H.F.", "order": 38961, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 9, "scene": 25, "line": "Oh, God!", "order": 38962, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 9, "scene": 25, "line": "They only want it for the initials.", "order": 38963, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 9, "scene": 25, "line": "The domain name. Yeah, they do.", "order": 38964, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 9, "scene": 25, "line": "I move we sell!", "order": 38965, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 9, "scene": 25, "line": "What?", "order": 38966, "speaker": "Michael Scott"},
  {"season": 7, "episode": 9, "scene": 25, "line": "Yeah, sell!", "order": 38967, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 9, "scene": 25, "line": "Yeah, sell.", "order": 38968, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 9, "scene": 25, "line": "Sell.", "order": 38969, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 9, "scene": 25, "line": "Get us out of this!", "order": 38970, "speaker": "Stanley Hudson"},
  {"season": 7, "episode": 9, "scene": 25, "line": "Have some faith in this idea!", "order": 38971, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 9, "scene": 25, "line": "If everybody wants to sell, we sell, right?", "order": 38972, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 9, "scene": 25, "line": "I won't sell. And you know what? Ryan and I have the majority of shares. Isn't that right?", "order": 38973, "speaker": "Michael Scott"},
  {"season": 7, "episode": 9, "scene": 25, "line": "Yes. You alone do, actually.", "order": 38974, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 9, "scene": 25, "line": "I--yes. We're not selling. I will not sell.", "order": 38975, "speaker": "Michael Scott"},
  {"season": 7, "episode": 9, "scene": 26, "line": "[runs to and inside the Hay Place maze] Awesome!", "order": 38976, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 9, "scene": 27, "line": "Do you mind if we share this bale?", "order": 38977, "speaker": "Man"},
  {"season": 7, "episode": 9, "scene": 27, "line": "Sure.", "order": 38978, "speaker": "Angela Martin"},
  {"season": 7, "episode": 9, "scene": 27, "line": "There's no charge to sit on the hay, is there?", "order": 38979, "speaker": "Man"},
  {"season": 7, "episode": 9, "scene": 27, "line": "Probably.", "order": 38980, "speaker": "Angela Martin"},
  {"season": 7, "episode": 9, "scene": 27, "line": "I mean, wouldn't you think the ten dollars to build your own broom would include the hay and not just the instructions?", "order": 38981, "speaker": "Man"},
  {"season": 7, "episode": 9, "scene": 27, "line": "Mm-Hmm.", "order": 38982, "speaker": "Angela Martin"},
  {"season": 7, "episode": 9, "scene": 27, "line": "I mean, I'm starting to think this guy is just trying to make money off the holiday.", "order": 38983, "speaker": "Man"},
  {"season": 7, "episode": 9, "scene": 27, "line": "Yeah, instead of Hay Place, it should be pay place. [man laughs] Don't laugh at me.", "order": 38984, "speaker": "Angela Martin"},
  {"season": 7, "episode": 9, "scene": 27, "line": "No, no, no, I wasn't--I was just laughing at your joke.", "order": 38985, "speaker": "Man"},
  {"season": 7, "episode": 9, "scene": 27, "line": "Oh.", "order": 38986, "speaker": "Angela Martin"},
  {"season": 7, "episode": 9, "scene": 27, "line": "Pay Place.", "order": 38987, "speaker": "Man"},
  {"season": 7, "episode": 9, "scene": 27, "line": "Mm. Yeah, so we can celebrate Thanksgiving-Me-Your-Money Day. [both laugh]", "order": 38988, "speaker": "Angela Martin"},
  {"season": 7, "episode": 9, "scene": 27, "line": "That's humorous.", "order": 38989, "speaker": "Man"},
  {"season": 7, "episode": 9, "scene": 27, "line": "Thank You.", "order": 38990, "speaker": "Angela Martin"},
  {"season": 7, "episode": 9, "scene": 27, "line": "[stuck in the maze, says to some kids running around in the maze] You guys know how to get out? [kids run away screaming]", "order": 38991, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 9, "scene": 28, "line": "Hi, Michael.", "order": 38992, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 9, "scene": 28, "line": "Can't talk, saving the planet.", "order": 38993, "speaker": "Michael Scott"},
  {"season": 7, "episode": 9, "scene": 28, "line": "Oh, we don't recycle.", "order": 38994, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 9, "scene": 28, "line": "We Don't? Well, why have I been separating the trash into whites and colors?", "order": 38995, "speaker": "Michael Scott"},
  {"season": 7, "episode": 9, "scene": 28, "line": "I'm sure no one asked you to do that.", "order": 38996, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 9, "scene": 28, "line": "[throws the sack of garbage onto the floor] Eight Years.", "order": 38997, "speaker": "Michael Scott"},
  {"season": 7, "episode": 9, "scene": 28, "line": "Listen, I know you really like Ryan--", "order": 38998, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 9, "scene": 28, "line": "No, I won't even consider it.", "order": 38999, "speaker": "Michael Scott"},
  {"season": 7, "episode": 9, "scene": 28, "line": "Michael, I... [sits down] I hate to say this, but... you know this special bond that you've always felt with Ryan, where, like, you're best friends or you're his mentor or something?", "order": 39000, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 9, "scene": 28, "line": "Right, Yes, yeah. Best friend/mentor.", "order": 39001, "speaker": "Michael Scott"},
  {"season": 7, "episode": 9, "scene": 28, "line": "Michael, I think that feeling only goes one way. And I think that Ryan knows that, and he's taking advantage of you.", "order": 39002, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 9, "scene": 28, "line": "I think you are wrong.", "order": 39003, "speaker": "Michael Scott"},
  {"season": 7, "episode": 9, "scene": 28, "line": "And there are a lot of other people in this office who have money at stake. Other people you care about.", "order": 39004, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 9, "scene": 28, "line": "You may be right. I may be crazy.", "order": 39005, "speaker": "Michael Scott"},
  {"season": 7, "episode": 9, "scene": 28, "line": "Don't.", "order": 39006, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 9, "scene": 28, "line": "I'm just saying that I think I agree with your point.", "order": 39007, "speaker": "Michael Scott"},
  {"season": 7, "episode": 9, "scene": 28, "line": "Yeah, but in a jokey way where you're gonna start singing.", "order": 39008, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 9, "scene": 28, "line": "I thought it might make me feel better.", "order": 39009, "speaker": "Michael Scott"},
  {"season": 7, "episode": 9, "scene": 28, "line": "Okay. Go Ahead.", "order": 39010, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 9, "scene": 28, "line": "[singing] But it just may be a lunatic... [stops singing] No.", "order": 39011, "speaker": "Michael Scott"},
  {"season": 7, "episode": 9, "scene": 29, "line": "[hovering over Meredith's desk] Oh, nice, wasting time, here we go.", "order": 39012, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 9, "scene": 29, "line": "Hey, back off. It's Solitare.", "order": 39013, "speaker": "Meridith"},
  {"season": 7, "episode": 9, "scene": 29, "line": "Hey, kid--hear you're looking for work.", "order": 39014, "speaker": "Creed Bratton"},
  {"season": 7, "episode": 9, "scene": 29, "line": "Talk to me.", "order": 39015, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 9, "scene": 29, "line": "How far can you reach those Lovely long arms of yours? [Jim reaching his arms out as far as he can go] Eh, put 'em down. How long can you hold that pretty little breath of yours? [Jim inhales] Good.", "order": 39016, "speaker": "Creed Bratton"},
  {"season": 7, "episode": 9, "scene": 29, "line": "Hey, Jim? Are you distracting these people?", "order": 39017, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 9, "scene": 29, "line": "We're working. [Jim still holding his breath]", "order": 39018, "speaker": "Creed Bratton"},
  {"season": 7, "episode": 9, "scene": 29, "line": "Can you at least", "order": 39019, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 9, "scene": 30, "line": "[listening to and editing Jo's book on tape]", "order": 39020, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 9, "scene": 30, "line": "The moment Darla put the cupcake in her mouth, her daddy pulled her aside and said, 'You're Too Fat. No one's gonna like you if you're too fat.' The next time I saw David Geffen was at the buffalo club. 'I love you, you Gay Bastard,' I said. 'You Gay Bastard,' I s--. 'Gay Ba--' 'Gay B--''", "order": 39021, "speaker": "Jo Bennett"},
  {"season": 7, "episode": 9, "scene": 31, "line": "Michael, how's it going, bro?", "order": 39022, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 9, "scene": 31, "line": "Good, good, bro.", "order": 39023, "speaker": "Michael Scott"},
  {"season": 7, "episode": 9, "scene": 31, "line": "Hey, thanks so much for having my back out there.", "order": 39024, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 9, "scene": 31, "line": "Always have your back. So, any ideas on how we can get over this nine-day hump here?", "order": 39025, "speaker": "Michael Scott"},
  {"season": 7, "episode": 9, "scene": 31, "line": "I have nothing but ideas. The thing is, they all require money.", "order": 39026, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 9, "scene": 31, "line": "Nine days, though. That's, like...", "order": 39027, "speaker": "Michael Scott"},
  {"season": 7, "episode": 9, "scene": 31, "line": "Look, I know I've tapped you so hard.", "order": 39028, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 9, "scene": 31, "line": "Yes, you have tapped this. Hard.", "order": 39029, "speaker": "Michael Scott"},
  {"season": 7, "episode": 9, "scene": 31, "line": "[laughs] Oh, you are funny, Michael. You--people, people don't give you enough credit.", "order": 39030, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 9, "scene": 31, "line": "Maybe I could take a second mortgage on my condo.", "order": 39031, "speaker": "Michael Scott"},
  {"season": 7, "episode": 9, "scene": 31, "line": "Well, a lot of people are doing that. And there don't seem to be any consequences. And they say the rates have never been lower.", "order": 39032, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 9, "scene": 31, "line": "So you would think that's a good idea for me to do?", "order": 39033, "speaker": "Michael Scott"},
  {"season": 7, "episode": 9, "scene": 31, "line": "Think about this. You and me on a private plane flown by our private pilot--", "order": 39034, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 9, "scene": 31, "line": "Mm-Hmm.", "order": 39035, "speaker": "Michael Scott"},
  {"season": 7, "episode": 9, "scene": 31, "line": "Eating our private meal cooked by our private chef.", "order": 39036, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 9, "scene": 31, "line": "Yeah, well, we never even have dinner now, so...", "order": 39037, "speaker": "Michael Scott"},
  {"season": 7, "episode": 9, "scene": 31, "line": "We totally should.", "order": 39038, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 9, "scene": 31, "line": "Tonight?", "order": 39039, "speaker": "Michael Scott"},
  {"season": 7, "episode": 9, "scene": 31, "line": "I gotta work on this.", "order": 39040, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 9, "scene": 31, "line": "Oh right. What am I saying? Rain Check.", "order": 39041, "speaker": "Michael Scott"},
  {"season": 7, "episode": 9, "scene": 31, "line": "Yeah. [Michael closes the door]", "order": 39042, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 9, "scene": 32, "line": "And now, by show of applause, We will crown this year's hay king. All in favor of Purebred. [crowd applauds] Put your hands together for... Mixed Bread. [crowd applauds] And let's hear it for... Purebred. [crowd applauds] Mm. It appears we have a three-way tie. I have no choice but to pick the Hay King myself. I pick... [all three contestants begging for Dwight to pick them] Me! I am your Hay King! All Hail your Hay King! [drowd leaves disgusted, as Dwight's worker claps]", "order": 39043, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 9, "scene": 33, "line": "Did I truck 300 bales of hay to a parking lot to rectify some childhood disappointment? Yes.", "order": 39044, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 9, "scene": 34, "line": "So, if I were to call Dunder Mifflin and ask for Miss Angela Martin, would I get through to you?", "order": 39045, "speaker": "Man"},
  {"season": 7, "episode": 9, "scene": 34, "line": "You would. And if I were to call your house and ask for your wife, would I get through to you?", "order": 39046, "speaker": "Angela Martin"},
  {"season": 7, "episode": 9, "scene": 34, "line": "My wife passed away a few years ago.", "order": 39047, "speaker": "Man"},
  {"season": 7, "episode": 9, "scene": 34, "line": "How tragic. Very sorry to hear that.", "order": 39048, "speaker": "Angela Martin"},
  {"season": 7, "episode": 9, "scene": 34, "line": "Thank You.", "order": 39049, "speaker": "Man"},
  {"season": 7, "episode": 9, "scene": 34, "line": "[still stuck in maze] Help! Hello?! Oh! Help![runs around the maze]", "order": 39050, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 9, "scene": 35, "line": "[opens the door the Ryan's office, grabs him by the shirt, pulls him out of his chair] Consider it a WUPHF in person. [shoves Ryan down to a chair]", "order": 39051, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 9, "scene": 35, "line": "Your doing the wrong thing. You're gonna lose Michael's investment. You're gonna lose ours.", "order": 39052, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 9, "scene": 35, "line": "You got this crazy opportunity to get our money back. Tell Mike To Sell.", "order": 39053, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 9, "scene": 35, "line": "If you tell Michael to sell, he'll sell.", "order": 39054, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 9, "scene": 35, "line": "I'm betting on myself.", "order": 39055, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 9, "scene": 35, "line": "It's a bad bet.", "order": 39056, "speaker": "Stanley Hudson"},
  {"season": 7, "episode": 9, "scene": 35, "line": "Hello.", "order": 39057, "speaker": "Michael Scott"},
  {"season": 7, "episode": 9, "scene": 35, "line": "Hey. It's an ambush here. Nobody here believes in this company. Will you tell them they're wrong?", "order": 39058, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 9, "scene": 35, "line": "It's not that we don't believe in the company, We don't believe in you.", "order": 39059, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 9, "scene": 35, "line": "All those in favor of selling, say aye. [all say 'aye']", "order": 39060, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 9, "scene": 35, "line": "I. Do not agree to sell, which is to say nay.", "order": 39061, "speaker": "Michael Scott"},
  {"season": 7, "episode": 9, "scene": 35, "line": "What is wrong with you? What happened to you in High School?", "order": 39062, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 9, "scene": 35, "line": "Michael, are you that blind?", "order": 39063, "speaker": "Stanley Hudson"},
  {"season": 7, "episode": 9, "scene": 35, "line": "I'm not blind! I know exactly who he is. He is selfish and lazy and image-obsessed, and he is a bad friend. [all seem to be in agreenment] And he's also clever. And he shoots incredibly high. And he may just make it. But you know what? Even if he doesn't, I would rather go broke betting on my people, than get rich all by myself, on some island like a castaway. And there is no middle ground.", "order": 39064, "speaker": "Michael Scott"},
  {"season": 7, "episode": 9, "scene": 35, "line": "You're gonna lose all of our money.", "order": 39065, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 9, "scene": 35, "line": "Only if he fails. And you know what? Ryan, I believe in you. Just like I believe in all of you. You have nine days to save everybody's money.", "order": 39066, "speaker": "Michael Scott"},
  {"season": 7, "episode": 9, "scene": 35, "line": "Oh... Uh, That's a lot of pressure. I--I'm gonna need some more time.", "order": 39067, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 9, "scene": 35, "line": "You can't have it.", "order": 39068, "speaker": "Michael Scott"},
  {"season": 7, "episode": 9, "scene": 35, "line": "Okay. I won't let you down. [Ryan goes back into his office and shuts the door]", "order": 39069, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 9, "scene": 36, "line": "Angela? [slides open the door] Angela! [see's a note on the door, reads it, and then crumples it up]", "order": 39070, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 9, "scene": 37, "line": "The world sends people your way... Ryan came to me through a temp agency. Andy was transferred here. No idea where Creed came from. The point is you just have to play with the cards that you're dealt. [camera pans to different people in the office] Jim, that guy is an ace. Dwight is my King up my sleeve. Phyllis is my old maid. Oscar is my queen. That's easy. Gimme a hard one. That's what Oscar said. Toby is the instruction card you throw away. Pam's a solid seven. And yeah, you know what? Ryan is probably, like, a two. But sometimes twos can be wild. So watch out. And I am obviously the joker. So... [phone rings, computer chimes, etc.] That's Uh...", "order": 39071, "speaker": "Michael Scott"},
  {"season": 7, "episode": 9, "scene": 37, "line": "WUPHF, from Ryan Howard. Decided to sell company. Thanks, bro. Hell of a ride.", "order": 39072, "speaker": "Robotic Voice"},
  {"season": 7, "episode": 9, "scene": 37, "line": "Thank God.", "order": 39073, "speaker": "Michael Scott"},
  {"season": 7, "episode": 9, "scene": 38, "line": "[puts his phone in his coffee mug and calls Gabe]", "order": 39074, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 9, "scene": 38, "line": "Gabe Lewis.", "order": 39075, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 9, "scene": 38, "line": "[Jim's edited version of her book on tape]: Now listen here, Gabe, you're too fat. No one's gonna like you if you're too fat. I made some changes to my book. See if you like them.", "order": 39076, "speaker": "Jo Bennett"},
  {"season": 7, "episode": 9, "scene": 38, "line": "Well, Jo--", "order": 39077, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 9, "scene": 38, "line": "Now, I love reading, and I hate being interrupted. Shut up and listen, you gay bastard. Chapter One. I was born, not into luxary, nor poverty. [Gabe looks through Jo's book] But into adversity, and for that, I thank the Lord. My Father was a man. That's all we can know. After I learned to ride a bike, there was no stopping me. I would ride up Magnolia Stree and down Azalea Lane. Which would later become my--", "order": 39078, "speaker": "Jo Bennett"},
  {"season": 7, "episode": 9, "scene": 38, "line": "[opens door to Gabe's office] All right, good night, Gabe. Just wanted to say thanks again. [Gabe motions that he's on the phone] 'Cause I really think I made good use of my day, oh! Sorry.", "order": 39079, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 9, "scene": 38, "line": "I've always been a fighter. And Fate has obliged me with plenty of battles, the first being a hard--", "order": 39080, "speaker": "Jo Bennett"},
  {"season": 7, "episode": 10, "scene": 1, "line": "[picks up pencil between his toes]", "order": 39081, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 10, "scene": 1, "line": "Why?", "order": 39082, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 10, "scene": 1, "line": "Twenty minutes a day Jim, that's all it takes. Twenty minutes a day, all feet, no hands and I'll have the pedi-dexterity of a chimp, and you'll be sitting there like an idiot. [attempts to place pencil in pencil sharpener] Okay. Here we go. Ah, yes. [accidentally kicks items from his desk onto Pam's]", "order": 39083, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 10, "scene": 1, "line": "Do you mind?", "order": 39084, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 10, "scene": 1, "line": "I'm sorry Pam, allow me to write you an apology letter.", "order": 39085, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 10, "scene": 1, "line": "You don't have to do that.", "order": 39086, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 10, "scene": 1, "line": "[typing with his toes] D-E-A. Oop, backspace. A. Dear.", "order": 39087, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 10, "scene": 1, "line": "[whistling, places cup of coffee onto Dwight's desk] Ahh, Thank you hands. Nothing else in the universe can do what you do.", "order": 39088, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 10, "scene": 1, "line": "[lifts cup up with toes]", "order": 39089, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 10, "scene": 1, "line": "Oh, don't worry about it. Dwight, its okay. You were wrong.", "order": 39090, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 10, "scene": 1, "line": "[spills hot coffee on himself] Uhh, aah!", "order": 39091, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 10, "scene": 1, "line": "Well, A for effort right?", "order": 39092, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 10, "scene": 1, "line": "[taps Jim's hand with his foot]", "order": 39093, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 10, "scene": 2, "line": "Welcome, start your morning right with a burst of blueberry. Or try plain.", "order": 39094, "speaker": "Hank Tate"},
  {"season": 7, "episode": 10, "scene": 2, "line": "What's the occasion?", "order": 39095, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 10, "scene": 2, "line": "Dwight said this entry was a waste of space.", "order": 39096, "speaker": "Hank Tate"},
  {"season": 7, "episode": 10, "scene": 2, "line": "I would like a muffa du blueberry por favor.", "order": 39097, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 10, "scene": 2, "line": "Eight dollars.", "order": 39098, "speaker": "Hank Tate"},
  {"season": 7, "episode": 10, "scene": 2, "line": "Eight dollars?", "order": 39099, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 10, "scene": 3, "line": "Owning a building is a war between the landlord and the tenant. Not a literal war, unfortunately, but I am using the same tactics. I've surrounded the enemy, and I'm slowly starving them. To save on electricity I've installed a timer and motion sensors on the lights. It's part of my green initiative. And by green, I mean money.", "order": 39100, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 10, "scene": 4, "line": "[examines items on the reception desk] Mmhmm, China. China.", "order": 39101, "speaker": "Michael Scott"},
  {"season": 7, "episode": 10, "scene": 4, "line": "How was the dentist?", "order": 39102, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 10, "scene": 4, "line": "It was great. China.", "order": 39103, "speaker": "Michael Scott"},
  {"season": 7, "episode": 10, "scene": 4, "line": "Are you okay, what's wrong?", "order": 39104, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 10, "scene": 4, "line": "Everything here was made in China, Pam.", "order": 39105, "speaker": "Michael Scott"},
  {"season": 7, "episode": 10, "scene": 4, "line": "Yeah, its where they make stuff.", "order": 39106, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 10, "scene": 4, "line": "They used to make stuff in America, Andy. But we're falling behind, did you know that? China is a sleeping dragon that is just beginning to stir.", "order": 39107, "speaker": "Michael Scott"},
  {"season": 7, "episode": 10, "scene": 4, "line": "Oh, no.", "order": 39108, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 10, "scene": 4, "line": "Yeah, right here. It is right there. [holds up magazine] Anybody read the news any more?", "order": 39109, "speaker": "Michael Scott"},
  {"season": 7, "episode": 10, "scene": 4, "line": "China is on the move?", "order": 39110, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 10, "scene": 4, "line": "I found that in the waiting room at the dentist's office. This kid had the magazine I wanted to read, that's the only one I could reach and I read it and then I read it again.", "order": 39111, "speaker": "Michael Scott"},
  {"season": 7, "episode": 10, "scene": 5, "line": "My whole life I believed that America was number one, that was the saying. Not America is number two. England is number two. China should be like eight.", "order": 39112, "speaker": "Michael Scott"},
  {"season": 7, "episode": 10, "scene": 6, "line": "Hey Andy.", "order": 39113, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 10, "scene": 6, "line": "Hey Darryl.", "order": 39114, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 10, "scene": 6, "line": "You gotta stop texting me so much.", "order": 39115, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 10, "scene": 6, "line": "But I wanted you to know that Michael and I are wearing the same tie today. It's insane.", "order": 39116, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 10, "scene": 6, "line": "You need to change your standard for what's worthy of a text. Ask yourself is this something Darryl needs to know. The answers almost always no.", "order": 39117, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 10, "scene": 6, "line": "Got it. Then I will call you.", "order": 39118, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 10, "scene": 6, "line": "No.", "order": 39119, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 10, "scene": 7, "line": "There was a time when the only people who texted you were people you wanted to text you. Girls. And they'd all say the same thing. 'I'm coming over baby.' And I would text back 'BTB', bring that booty.", "order": 39120, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 10, "scene": 8, "line": "Dwight, why is the toilet paper only half a ply?", "order": 39121, "speaker": "Stanley Hudson"},
  {"season": 7, "episode": 10, "scene": 8, "line": "I'm sorry, isn't that good enough for your anus? Don't get me started on how coddled the modern anus is.", "order": 39122, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 10, "scene": 8, "line": "Umm, Stanley. Maybe you could just unspool a little more each time you need to...", "order": 39123, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 10, "scene": 8, "line": "Don't tell me how to do my business.", "order": 39124, "speaker": "Stanley Hudson"},
  {"season": 7, "episode": 10, "scene": 8, "line": "Stanley, as a fellow Dunder Mifflin employee I feel for you. But, like you, I am completely powerless to the whims of the new building owner.", "order": 39125, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 10, "scene": 8, "line": "Which is you.", "order": 39126, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 10, "scene": 8, "line": "Which is you' is not a sentence.", "order": 39127, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 10, "scene": 8, "line": "I disagree with.", "order": 39128, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 10, "scene": 8, "line": "Are you just gonna sit there, Office Administrator, or are you going to do something?", "order": 39129, "speaker": "Stanley Hudson"},
  {"season": 7, "episode": 10, "scene": 8, "line": "Dwight, can we talk about these cutbacks.", "order": 39130, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 10, "scene": 8, "line": "Pam, when I'm sitting at this desk, I'm a salesman. If you wanna talk to the new building owner, you should call Nate and schedule and appointment.", "order": 39131, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 10, "scene": 8, "line": "I'm not going to do that.", "order": 39132, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 10, "scene": 8, "line": "Well then you're not going to talk to the new building owner. Which is a shame, because I hear he's a very reasonable guy.", "order": 39133, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 10, "scene": 8, "line": "[on the phone] Y'ello.", "order": 39134, "speaker": "Nate Nickerson"},
  {"season": 7, "episode": 10, "scene": 8, "line": "Hi Nate, Its Pam Halpert.", "order": 39135, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 10, "scene": 8, "line": "Oh, hey Pam.", "order": 39136, "speaker": "Nate Nickerson"},
  {"season": 7, "episode": 10, "scene": 8, "line": "Hey, I would just love to schedule a meeting today with Dwight.", "order": 39137, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 10, "scene": 8, "line": "Let me put you on hold for just one 'eensy sec.", "order": 39138, "speaker": "Nate Nickerson"},
  {"season": 7, "episode": 10, "scene": 8, "line": "Sure.", "order": 39139, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 10, "scene": 8, "line": "[answers phone] Dwight Schrute. Mhm. Tell her I'm busy. I don't know, make something up.", "order": 39140, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 10, "scene": 8, "line": "Hey Pam, Dwight's being questioned by the police in connection with a string of dog-nappings that ha...", "order": 39141, "speaker": "Nate Nickerson"},
  {"season": 7, "episode": 10, "scene": 8, "line": "[hangs up phone] Dwight. Dwight. Can you please tell new building owner that he is screwing over all the people he works with, people he's worked with for years. His friends.", "order": 39142, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 10, "scene": 8, "line": "You know what Pam? You're right. This isn't just a business. This is a home, and I would much rather see a smile from Kevin than save hundreds on plumbing and electricity. [Kevin smiles]", "order": 39143, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 10, "scene": 9, "line": "Oh my God.", "order": 39144, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 10, "scene": 9, "line": "What?", "order": 39145, "speaker": "Michael Scott"},
  {"season": 7, "episode": 10, "scene": 9, "line": "According to the internet, the tallest man in the world is Chinese.", "order": 39146, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 10, "scene": 9, "line": "So much for keeping our secrets up high.", "order": 39147, "speaker": "Michael Scott"},
  {"season": 7, "episode": 10, "scene": 9, "line": "What's America gonna do?", "order": 39148, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 10, "scene": 9, "line": "I know what we're going to do. We're going to put our best minds on it.", "order": 39149, "speaker": "Michael Scott"},
  {"season": 7, "episode": 10, "scene": 10, "line": "Everybody stop working. I want you all to imagine a world in which America is not the number one superpower, where forks are irrelevant, and where every man, woman and child is expected to learn how to play the cello. Now open your eyes.", "order": 39150, "speaker": "Michael Scott"},
  {"season": 7, "episode": 10, "scene": 10, "line": "You never told us to close them.", "order": 39151, "speaker": "Angela Martin"},
  {"season": 7, "episode": 10, "scene": 10, "line": "Welcome to your future.", "order": 39152, "speaker": "Michael Scott"},
  {"season": 7, "episode": 10, "scene": 10, "line": "What do we do? How do we stop this?", "order": 39153, "speaker": "Phyllis Vance"},
  {"season": 7, "episode": 10, "scene": 10, "line": "How do we stop it? With a big idea. That's what America is built on, big ideas. Blue jeans, the Grand Canyon. Whose got one, whose got a big idea?", "order": 39154, "speaker": "Michael Scott"},
  {"season": 7, "episode": 10, "scene": 10, "line": "An idea bigger than the Grand Canyon?", "order": 39155, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 10, "scene": 10, "line": "Yes, indeed. Yes Kevin.", "order": 39156, "speaker": "Michael Scott"},
  {"season": 7, "episode": 10, "scene": 10, "line": "An antacid that you only take once a week.", "order": 39157, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 10, "scene": 10, "line": "Once a week antacid is the idea to beat. Anyone else? Nobody? Okay then we are... yes.", "order": 39158, "speaker": "Michael Scott"},
  {"season": 7, "episode": 10, "scene": 10, "line": "Michael! An antacid pill that you take once every six weeks.", "order": 39159, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 10, "scene": 10, "line": "Why not just go one for the year?", "order": 39160, "speaker": "Stanley Hudson"},
  {"season": 7, "episode": 10, "scene": 10, "line": "I don't know.", "order": 39161, "speaker": "Michael Scott"},
  {"season": 7, "episode": 10, "scene": 10, "line": "It's too big of a pill to swallow.", "order": 39162, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 10, "scene": 10, "line": "Alright.", "order": 39163, "speaker": "Michael Scott"},
  {"season": 7, "episode": 10, "scene": 10, "line": "What if we all get together and help each other and hire a new guy, and then we all kill him, but first we take out like a hundred thousand dollar life insurance policy. I bet you guys like that idea don't you?", "order": 39164, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 10, "scene": 11, "line": "I think that's what they're doing to me. I can't prove it, but I wanted to see their faces when I said it. I learned nothing.", "order": 39165, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 10, "scene": 12, "line": "I don't know what the [expletive] that was.", "order": 39166, "speaker": "Michael Scott"},
  {"season": 7, "episode": 10, "scene": 12, "line": "I say we bomb 'em. By 2020, they're gonna be the world's largest economy and they're getting a taste for protein. We'll all starve.", "order": 39167, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 10, "scene": 12, "line": "Yeah, Dwight's right we should drop a bomb.", "order": 39168, "speaker": "Phyllis Vance"},
  {"season": 7, "episode": 10, "scene": 12, "line": "No he's not right. We're not. Where would we even drop it, Phyllis? Did you know that in China there are fifty six cities with over a million people? You know how many we have here? Nine.", "order": 39169, "speaker": "Michael Scott"},
  {"season": 7, "episode": 10, "scene": 12, "line": "[gasps]", "order": 39170, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 10, "scene": 12, "line": "Actually, that's not true. I know the figure you're referring to, and it's a projection of fifteen years from now.", "order": 39171, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 10, "scene": 12, "line": "Thank God.", "order": 39172, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 10, "scene": 12, "line": "No, no. That is right now.", "order": 39173, "speaker": "Michael Scott"},
  {"season": 7, "episode": 10, "scene": 12, "line": "Michael, China's agrarian. Urbanizing fast? You bet 'cha, but still agrarian.", "order": 39174, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 10, "scene": 12, "line": "In terms of land, not population.", "order": 39175, "speaker": "Michael Scott"},
  {"season": 7, "episode": 10, "scene": 12, "line": "Come on Michael. You can...", "order": 39176, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 10, "scene": 12, "line": "No, no you're wrong about this.", "order": 39177, "speaker": "Michael Scott"},
  {"season": 7, "episode": 10, "scene": 12, "line": "Where are you getting this information?", "order": 39178, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 10, "scene": 12, "line": "I got it from NewYorkTimes.com", "order": 39179, "speaker": "Michael Scott"},
  {"season": 7, "episode": 10, "scene": 12, "line": "Uh oh, getting nervous Oscar?", "order": 39180, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 10, "scene": 12, "line": "Okay, someone look it up.", "order": 39181, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 10, "scene": 12, "line": "I'm on it Jim. I'm on it.", "order": 39182, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 10, "scene": 12, "line": "Guys its not worth it really. Guys this is not worth our time.", "order": 39183, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 10, "scene": 12, "line": "[reads text] 'Are you watching this?' Seriously?", "order": 39184, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 10, "scene": 12, "line": "Well are you?", "order": 39185, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 10, "scene": 12, "line": "I'm sitting right here.", "order": 39186, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 10, "scene": 12, "line": "Got it. China has fifty six cities with a population of over one million. The US has... nine.", "order": 39187, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 10, "scene": 12, "line": "Suck it Oscar.", "order": 39188, "speaker": "Meredith Palmer"},
  {"season": 7, "episode": 10, "scene": 12, "line": "Well on the plus side all this worrying about China has made you smarter than Oscar, Michael.", "order": 39189, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 10, "scene": 12, "line": "Great. I was wrong. I'm wrong. Is everyone happy?", "order": 39190, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 10, "scene": 13, "line": "So I happen to know more than the smartest guy in the office. So what? [laughs] I don't care.", "order": 39191, "speaker": "Michael Scott"},
  {"season": 7, "episode": 10, "scene": 14, "line": "Okay, now where were we before I bested Oscar?", "order": 39192, "speaker": "Michael Scott"},
  {"season": 7, "episode": 10, "scene": 15, "line": "Wow, so this building can get uglier.", "order": 39193, "speaker": "Meredith Palmer"},
  {"season": 7, "episode": 10, "scene": 15, "line": "I will not work in a roach billboard.", "order": 39194, "speaker": "Stanley Hudson"},
  {"season": 7, "episode": 10, "scene": 15, "line": "Oh my God, I can't look at roaches.", "order": 39195, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 10, "scene": 15, "line": "Michael do something about this.", "order": 39196, "speaker": "Angela Martin"},
  {"season": 7, "episode": 10, "scene": 15, "line": "Absolutely, Pam this is exactly why I hired you as Office Administrator, handle it. Taking care of business.", "order": 39197, "speaker": "Michael Scott"},
  {"season": 7, "episode": 10, "scene": 15, "line": "Dwight take it down.", "order": 39198, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 10, "scene": 15, "line": "[chuckles]", "order": 39199, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 10, "scene": 15, "line": "I'm serious, take it down or else.", "order": 39200, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 10, "scene": 15, "line": "Or else? Or else what? There's nothing you can do.", "order": 39201, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 10, "scene": 15, "line": "We can move out.", "order": 39202, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 10, "scene": 16, "line": "[lights turn off] Really? [jumps to try and activate motion sensor, Kevin walks through the door setting it off, and the lights come back on]", "order": 39203, "speaker": "Angela Martin"},
  {"season": 7, "episode": 10, "scene": 17, "line": "So, does anyone wanna know where I've been for the last 2 hours?", "order": 39204, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 10, "scene": 17, "line": "Oh God, I've been playing Zombie Soccer for two hours?", "order": 39205, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 10, "scene": 17, "line": "I went out to look for a better office space.", "order": 39206, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 10, "scene": 17, "line": "Waste of time.", "order": 39207, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 10, "scene": 17, "line": "Not really, because I found one.", "order": 39208, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 10, "scene": 17, "line": "Oh wow, these are nice.", "order": 39209, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 10, "scene": 17, "line": "Yup.", "order": 39210, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 10, "scene": 17, "line": "Let me see.", "order": 39211, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 10, "scene": 17, "line": "Look at the huge offices Jim, check out that conference room.", "order": 39212, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 10, "scene": 17, "line": "Yeah, yeah. Totally.", "order": 39213, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 10, "scene": 17, "line": "You can't just move out.", "order": 39214, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 10, "scene": 17, "line": "Oh in three months we can. Check the lease, and if you don't undo all the changes you've made, we're moving. Hey guys, can I show you some pictures.", "order": 39215, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 10, "scene": 17, "line": "[without looking at the pictures] Oh my gosh she is so cute. She looks like both of you.", "order": 39216, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 10, "scene": 17, "line": "They're not of Cece.", "order": 39217, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 10, "scene": 17, "line": "Oh, cool.", "order": 39218, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 10, "scene": 17, "line": "This is possibly our new Dunder Mifflin office.", "order": 39219, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 10, "scene": 17, "line": "Well I like being in the same building as Bob. Keeps me honest.", "order": 39220, "speaker": "Phyllis Vance"},
  {"season": 7, "episode": 10, "scene": 17, "line": "Well, this building isn't far, and its much newer. There's a dry cleaner, nail place and a gym. Oh, and it's next-door to and Outback so it always smells like steak.", "order": 39221, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 10, "scene": 17, "line": "Are you trying to kill me?", "order": 39222, "speaker": "Stanley Hudson"},
  {"season": 7, "episode": 10, "scene": 17, "line": "Is the nail place Koreans or whites?", "order": 39223, "speaker": "Kelly Kapoor"},
  {"season": 7, "episode": 10, "scene": 17, "line": "Koreans.", "order": 39224, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 10, "scene": 17, "line": "Good. And the dry cleaners?", "order": 39225, "speaker": "Kelly Kapoor"},
  {"season": 7, "episode": 10, "scene": 17, "line": "White.", "order": 39226, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 10, "scene": 17, "line": "Good.", "order": 39227, "speaker": "Kelly Kapoor"},
  {"season": 7, "episode": 10, "scene": 18, "line": "Woah, there is a lot of brainpower in this room. We've got Michael and Oscar, the two smartest guys in the office, also in that order.", "order": 39228, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 10, "scene": 18, "line": "Funny Jim. That is funny.", "order": 39229, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 10, "scene": 18, "line": "Very comedically humorous Jim.", "order": 39230, "speaker": "Michael Scott"},
  {"season": 7, "episode": 10, "scene": 18, "line": "I have a computer question. Hey Oscar?", "order": 39231, "speaker": "Kelly Kapoor"},
  {"season": 7, "episode": 10, "scene": 18, "line": "What is it?", "order": 39232, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 10, "scene": 18, "line": "Can you move aside so that I can ask Michael?", "order": 39233, "speaker": "Kelly Kapoor"},
  {"season": 7, "episode": 10, "scene": 18, "line": "Alright. Alright I get it.", "order": 39234, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 10, "scene": 18, "line": "Michael, how do I create a new tab?", "order": 39235, "speaker": "Kelly Kapoor"},
  {"season": 7, "episode": 10, "scene": 18, "line": "Try 'Control P'.", "order": 39236, "speaker": "Michael Scott"},
  {"season": 7, "episode": 10, "scene": 18, "line": "That's print.", "order": 39237, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 10, "scene": 18, "line": "Not if the printer isn't hooked up. You are making some very dangerous assumptions Oscar.", "order": 39238, "speaker": "Michael Scott"},
  {"season": 7, "episode": 10, "scene": 18, "line": "Oscar it must be killing you to know that Michael is smarter than you.", "order": 39239, "speaker": "Kelly Kapoor"},
  {"season": 7, "episode": 10, "scene": 18, "line": "He's... he's not smarter than me, he was just right about one thing.", "order": 39240, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 10, "scene": 18, "line": "Yeah, but it was a really smart thing to be right about, actually.", "order": 39241, "speaker": "Kelly Kapoor"},
  {"season": 7, "episode": 10, "scene": 18, "line": "Actually, it was.", "order": 39242, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 10, "scene": 19, "line": "Around here, Oscar is known as 'actually' because he will insert himself into just about any conversation to add facts, or correct grammar. He really does fit that old stereotype of the smug gay Mexican.", "order": 39243, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 10, "scene": 20, "line": "[reading text] Megan Fox. Question mark. What's that mean?", "order": 39244, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 10, "scene": 20, "line": "Megan Fox! Come on!", "order": 39245, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 10, "scene": 20, "line": "You know what, you're one bad text away from getting blocked.", "order": 39246, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 10, "scene": 20, "line": "Yes, but one good text away from a high five.", "order": 39247, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 10, "scene": 20, "line": "You accept these terms?", "order": 39248, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 10, "scene": 20, "line": "Oh, its on.", "order": 39249, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 10, "scene": 21, "line": "In your perfect would, what would make this building awesome? I'm putting together kind of a wish list.", "order": 39250, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 10, "scene": 21, "line": "Well, I wish for a million wishes.", "order": 39251, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 10, "scene": 21, "line": "Yeah, no. I'm not a genie. I'm just talking about a...", "order": 39252, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 10, "scene": 21, "line": "Then see you later building.", "order": 39253, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 10, "scene": 21, "line": "You can't possibly be serious.", "order": 39254, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 10, "scene": 21, "line": "I said see you later building.", "order": 39255, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 10, "scene": 21, "line": "Hey Michael.", "order": 39256, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 10, "scene": 21, "line": "Hey.", "order": 39257, "speaker": "Michael Scott"},
  {"season": 7, "episode": 10, "scene": 21, "line": "I was thinking about some of the stuff you said earlier about China.", "order": 39258, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 10, "scene": 21, "line": "Mmm.", "order": 39259, "speaker": "Michael Scott"},
  {"season": 7, "episode": 10, "scene": 21, "line": "I'd love to talk more about it.", "order": 39260, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 10, "scene": 21, "line": "Yeah.", "order": 39261, "speaker": "Michael Scott"},
  {"season": 7, "episode": 10, "scene": 21, "line": "Maybe over some coffee later?", "order": 39262, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 10, "scene": 21, "line": "Sure.", "order": 39263, "speaker": "Michael Scott"},
  {"season": 7, "episode": 10, "scene": 21, "line": "Woah, woah, woah. Do you know what you just agreed to?", "order": 39264, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 10, "scene": 21, "line": "Coffee Jim.", "order": 39265, "speaker": "Michael Scott"},
  {"season": 7, "episode": 10, "scene": 21, "line": "No.", "order": 39266, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 10, "scene": 21, "line": "It is not just coffee.", "order": 39267, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 10, "scene": 21, "line": "He's trying to set you up Michael. What's going to happen is he's going to try and bring up what ever you're talking about in a very casual way, but secretly he'll be trying to trip you up, and when he does, boom, its awful. Haven't you noticed that I don't bring up the Tour de France around him?", "order": 39268, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 10, "scene": 21, "line": "Yes.", "order": 39269, "speaker": "Michael Scott"},
  {"season": 7, "episode": 10, "scene": 21, "line": "And then he will smugly pay the cheque and make you feel so small.", "order": 39270, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 10, "scene": 21, "line": "Alright, well I just need to learn everything about China. To be safe I should learn everything about everything but I don't have time. Okay, okay, I'll just learn about China and science and geography and math and literary.", "order": 39271, "speaker": "Michael Scott"},
  {"season": 7, "episode": 10, "scene": 21, "line": "No politics?", "order": 39272, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 10, "scene": 21, "line": "I'm pretty good on politics. 'California is bankrupt, and California, California.' [Jim and Andy follow Michael into his office] What?", "order": 39273, "speaker": "Michael Scott"},
  {"season": 7, "episode": 10, "scene": 22, "line": "Parley, my office, five minutes.", "order": 39274, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 10, "scene": 22, "line": "Parley?", "order": 39275, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 10, "scene": 22, "line": "Pirate code, he wants to meet.", "order": 39276, "speaker": "Creed Bratton"},
  {"season": 7, "episode": 10, "scene": 22, "line": "So everyone here knows pirate code?", "order": 39277, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 10, "scene": 22, "line": "I understand it, I can't speak it.", "order": 39278, "speaker": "Creed Bratton"},
  {"season": 7, "episode": 10, "scene": 23, "line": "Pam, I am not an unreasonable man. If you guys stay, I will stop watering down the soap.", "order": 39279, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 10, "scene": 23, "line": "You've been watering down the soap?", "order": 39280, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 10, "scene": 23, "line": "Why do you even need soap? Are you that bad at going to the bathroom?", "order": 39281, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 10, "scene": 23, "line": "We need everything back the way it was.", "order": 39282, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 10, "scene": 23, "line": "You don't wanna move. Moving is one of the most stressful things you can do in life. You'll probably just take it out on your kid. Jim will turn to the drink. The family will fall apart, and twenty five years from now, Cece will become world famous... for stripping.", "order": 39283, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 10, "scene": 23, "line": "That's a sad story. I have another one. We move, the other tenants follow, the bank takes the building, takes your farm, takes your car and beats the crap out of you. Penniless, you die, and my daughter Cece dances on your grave... fully clothed. [lights turn off, Pam and Dwight begin to wave their arms to alert the sensor]", "order": 39284, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 10, "scene": 24, "line": "Where is Tibet?", "order": 39285, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 10, "scene": 24, "line": "Pass.", "order": 39286, "speaker": "Michael Scott"},
  {"season": 7, "episode": 10, "scene": 24, "line": "When was China founded?", "order": 39287, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 10, "scene": 24, "line": "Pass.", "order": 39288, "speaker": "Michael Scott"},
  {"season": 7, "episode": 10, "scene": 24, "line": "Two for two, keep it up.", "order": 39289, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 10, "scene": 24, "line": "Who is Mao?", "order": 39290, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 10, "scene": 24, "line": "Lifeline.", "order": 39291, "speaker": "Michael Scott"},
  {"season": 7, "episode": 10, "scene": 24, "line": "Damn it. Michael, you are moments away from the smack down of your life. If you don't know something, steer the conversation back to something you do know.", "order": 39292, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 10, "scene": 24, "line": "I could talk about boobs. I bet he knows nothing about boobs.", "order": 39293, "speaker": "Michael Scott"},
  {"season": 7, "episode": 10, "scene": 24, "line": "What do you know about boobs?", "order": 39294, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 10, "scene": 24, "line": "Michael, I have to tell you something it's from Rocky II.", "order": 39295, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 10, "scene": 24, "line": "Thank you.", "order": 39296, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 10, "scene": 24, "line": "This guy doesn't just wanna win. Y'know, he want's to bury you. He wants to humiliate you!", "order": 39297, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 10, "scene": 24, "line": "Wait, wait, wait. How long is this going to take?", "order": 39298, "speaker": "Michael Scott"},
  {"season": 7, "episode": 10, "scene": 24, "line": "I'm like a quarter of the way through.", "order": 39299, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 10, "scene": 24, "line": "Is it going to be worth it?", "order": 39300, "speaker": "Michael Scott"},
  {"season": 7, "episode": 10, "scene": 24, "line": "No.", "order": 39301, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 10, "scene": 24, "line": "Just in case Michael, I made you a cheat sheet.", "order": 39302, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 10, "scene": 24, "line": "I don't need to cheat.", "order": 39303, "speaker": "Michael Scott"},
  {"season": 7, "episode": 10, "scene": 24, "line": "Show him how to use it.", "order": 39304, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 10, "scene": 25, "line": "[riding in car, looking for new office] Ten thousand seven hundred and six.", "order": 39305, "speaker": "Nate Nickerson"},
  {"season": 7, "episode": 10, "scene": 25, "line": "Here it is, right here, pull over. Oh my God. No way. Man, look at that.", "order": 39306, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 10, "scene": 25, "line": "Yeah.", "order": 39307, "speaker": "Nate Nickerson"},
  {"season": 7, "episode": 10, "scene": 25, "line": "There's no building. This could only mean one thing.", "order": 39308, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 10, "scene": 25, "line": "The building's underground?", "order": 39309, "speaker": "Nate Nickerson"},
  {"season": 7, "episode": 10, "scene": 25, "line": "She was lying. Oh, Pam, Pam...", "order": 39310, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 10, "scene": 25, "line": "Pam, Pam, Pam, Pam.", "order": 39311, "speaker": "Dwight Schrute & Nate Nickerson"},
  {"season": 7, "episode": 10, "scene": 25, "line": "Yeah?", "order": 39312, "speaker": "Passer-by"},
  {"season": 7, "episode": 10, "scene": 25, "line": "Pam. Pam?", "order": 39313, "speaker": "Nate Nickerson"},
  {"season": 7, "episode": 10, "scene": 25, "line": "What?", "order": 39314, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 10, "scene": 25, "line": "I'm Pam.", "order": 39315, "speaker": "Passer-by"},
  {"season": 7, "episode": 10, "scene": 25, "line": "Oh.", "order": 39316, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 10, "scene": 25, "line": "No you're not.", "order": 39317, "speaker": "Nate Nickerson"},
  {"season": 7, "episode": 10, "scene": 25, "line": "I'm sorry. We have a colleague with the same name.", "order": 39318, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 10, "scene": 25, "line": "Oh, that's fine.", "order": 39319, "speaker": "Passer-by"},
  {"season": 7, "episode": 10, "scene": 25, "line": "So, okay. You're not a liar too are you?", "order": 39320, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 10, "scene": 25, "line": "I've been known to bend the truth.", "order": 39321, "speaker": "Passer-by"},
  {"season": 7, "episode": 10, "scene": 25, "line": "Damn it, Pam. Get out. Right now. Leave it, I mean it. Get the hell out of here. Go.", "order": 39322, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 10, "scene": 25, "line": "Okay.", "order": 39323, "speaker": "Passer-by"},
  {"season": 7, "episode": 10, "scene": 25, "line": "I can't wait to do to Pam, what I just did... to Pam.", "order": 39324, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 10, "scene": 26, "line": "Hey Pam.", "order": 39325, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 10, "scene": 26, "line": "Yeah?", "order": 39326, "speaker": "Pam Halpert "},
  {"season": 7, "episode": 10, "scene": 26, "line": "Y'know what, I'm the only one here who you haven't asked about the new office.", "order": 39327, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 10, "scene": 26, "line": "I know, because you're the reason we're moving.", "order": 39328, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 10, "scene": 26, "line": "Yeah, but I'm still an employee here, and that part of me is really excited about the change of scenery. What's the square footage in the new place?", "order": 39329, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 10, "scene": 26, "line": "Uhh, I think it's something like umm...", "order": 39330, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 10, "scene": 26, "line": "What's the exact square footage.", "order": 39331, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 10, "scene": 26, "line": "Umm, let me see.", "order": 39332, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 10, "scene": 26, "line": "How many offices are there? Oh I'll just look at the one sheet myself.", "order": 39333, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 10, "scene": 26, "line": "Oh, actually I don't know what I did with the one sheet. Y'know, that's the problem. You only have one sheet.", "order": 39334, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 10, "scene": 26, "line": "[chuckles] You're a funny guy Pam. What's the first thing you guys are gonna do when you move in to the new office? I'm going to walk down the hall and say 'Wow, I can't believe this is real, but it is.' I can't wait.", "order": 39335, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 10, "scene": 26, "line": "Mmhmm.", "order": 39336, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 10, "scene": 27, "line": "I lied about some aspects of the building.", "order": 39337, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 10, "scene": 27, "line": "It's still on a bike path though right?", "order": 39338, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 10, "scene": 27, "line": "There's no building... it doesn't exist.", "order": 39339, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 10, "scene": 27, "line": "What does that mean?", "order": 39340, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 10, "scene": 27, "line": "I needed leverage so I pulled those pictures off the internet. It's just this Office Administrator thing, I don't wanna...", "order": 39341, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 10, "scene": 27, "line": "What?", "order": 39342, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 10, "scene": 27, "line": "Fail. I don't want to fail... again.", "order": 39343, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 10, "scene": 27, "line": "But you didn't fail.", "order": 39344, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 10, "scene": 27, "line": "And that's what you said about Art School, and that's what you said about sales.", "order": 39345, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 10, "scene": 27, "line": "And you didn't fail those things either.", "order": 39346, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 10, "scene": 27, "line": "Well, I'm not an artist, and I'm not a salesman. So what would you call it?", "order": 39347, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 10, "scene": 27, "line": "Hey, hey, hey, hey, hey.", "order": 39348, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 10, "scene": 28, "line": "Michael, the reason I asked you down here for this chat is that I've been thinking that your fears about China are a bit exaggerated.", "order": 39349, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 10, "scene": 28, "line": "Did you know, that China has a new missile, that can sink a US Naval Carrier nine hundred miles off the coast?", "order": 39350, "speaker": "Michael Scott"},
  {"season": 7, "episode": 10, "scene": 28, "line": "We have missiles too.", "order": 39351, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 10, "scene": 28, "line": "Did you also know that China has secretly been expanding its nuclear arsenal. But what do I know, I mean, that's just according to the Pentagon. Our Pentagon.", "order": 39352, "speaker": "Michael Scott"},
  {"season": 7, "episode": 10, "scene": 29, "line": "I wasn't here. [places a book in front of Pam]", "order": 39353, "speaker": "Nate Nickerson"},
  {"season": 7, "episode": 10, "scene": 29, "line": "What?", "order": 39354, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 10, "scene": 29, "line": "I wasn't here. It's a pretty common saying. You may want to log it away for future use.", "order": 39355, "speaker": "Nate Nickerson"},
  {"season": 7, "episode": 10, "scene": 29, "line": "Well thanks.", "order": 39356, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 10, "scene": 29, "line": "Yeah.", "order": 39357, "speaker": "Nate Nickerson"},
  {"season": 7, "episode": 10, "scene": 30, "line": "China has been loaning us billions upon billions of dollars. We are going to be owing them for the rest of our lives. And they will control us.", "order": 39358, "speaker": "Michael Scott"},
  {"season": 7, "episode": 10, "scene": 30, "line": "Actually, we're in a mild recession right now. I'll give you that, but people use China as the boogie man for all their problems. In the nineteen eighties, it was Japan.", "order": 39359, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 10, "scene": 30, "line": "How then do you explain that in the past year manufacturing in China has risen by 17% and in the US it has only risen by 8%?", "order": 39360, "speaker": "Michael Scott"},
  {"season": 7, "episode": 10, "scene": 30, "line": "Do you really think that manufacturing is a relevant indicator of where the world economy's heading in 2011? Do you know the comparative expansion of say the information sector? I'd say that's far more relevant. Wouldn't you?", "order": 39361, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 10, "scene": 30, "line": "Don't... I...", "order": 39362, "speaker": "Michael Scott"},
  {"season": 7, "episode": 10, "scene": 31, "line": "Hello, hello. You're breaking the law.", "order": 39363, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 10, "scene": 31, "line": "Impossible, I love the law.", "order": 39364, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 10, "scene": 31, "line": "Read article nineteen. There are suitable standards that you have to maintain the building at that includes comfortable temperatures and adequate lighting. It also means no more cutting the tampons in two, and no more tampering with the toilet paper.", "order": 39365, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 10, "scene": 31, "line": "I see I've underestimated you, and I didn't think that was possible. Nate, reply the paper.", "order": 39366, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 10, "scene": 31, "line": "I don't think it goes that way.", "order": 39367, "speaker": "Nate Nickerson"},
  {"season": 7, "episode": 10, "scene": 31, "line": "Reply it!", "order": 39368, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 10, "scene": 32, "line": "Don't worry about the coffee, its on me.", "order": 39369, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 10, "scene": 32, "line": "Yeah, I figured that.", "order": 39370, "speaker": "Michael Scott"},
  {"season": 7, "episode": 10, "scene": 32, "line": "Michael, I am so happy that we were able to have this little chat.", "order": 39371, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 10, "scene": 32, "line": "Wait. You forgetting something?", "order": 39372, "speaker": "Michael Scott"},
  {"season": 7, "episode": 10, "scene": 32, "line": "What?", "order": 39373, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 10, "scene": 32, "line": "This chat. Two men, one white, one latina. A boss and a money cruncher. I could fire you.", "order": 39374, "speaker": "Michael Scott"},
  {"season": 7, "episode": 10, "scene": 32, "line": "Fire him. No, show mercy.", "order": 39375, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 10, "scene": 32, "line": "Here we are.", "order": 39376, "speaker": "Michael Scott"},
  {"season": 7, "episode": 10, "scene": 32, "line": "What's your point.", "order": 39377, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 10, "scene": 32, "line": "My point is... that as long as people like you and me don't stop talking, nobody can stop the USA.", "order": 39378, "speaker": "Michael Scott"},
  {"season": 7, "episode": 10, "scene": 32, "line": "Yeah. [clapping]", "order": 39379, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 10, "scene": 32, "line": "Right, yes.", "order": 39380, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 10, "scene": 32, "line": "I am talking about freedom, about choice. America, I don't think you need to worry. Because if you want to beat China you will. If you don't, that's fine. That my friend, is your victory. Y'know, a lot of people say if you dig long enough and hard enough you will get to China, and that may be the true, but what they don't tell you is that if you dig long enough and hard enough in a conversation, you get to a friend. So here is to conversation.", "order": 39381, "speaker": "Michael Scott"},
  {"season": 7, "episode": 10, "scene": 32, "line": "That's not.", "order": 39382, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 10, "scene": 32, "line": "Raise your cups on high. Case closed.", "order": 39383, "speaker": "Michael Scott"},
  {"season": 7, "episode": 10, "scene": 32, "line": "That wasn't what we were discu... That wasn't the whole...", "order": 39384, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 10, "scene": 33, "line": "They say the best vampires don't bleed their victims dry, but give them the strength so that they can bounce back only to be fed on again. I spared Pam, and I may feast off of her profits for years to come. I let Pam win. Haha, oh. I was not motivated by compassion. I have no compassion. Make sure you got that. Not motivated by compassion.", "order": 39385, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 10, "scene": 34, "line": "[reads text] Come to parking lot. Crazy pigeon action?", "order": 39386, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 10, "scene": 35, "line": "Shhh. You're gonna scare 'em away. [two pigeons are eating out of a soft serve cone]", "order": 39387, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 10, "scene": 35, "line": "[laughs] That's a text. [high fives Andy]", "order": 39388, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 10, "scene": 35, "line": "Yeah. Right.", "order": 39389, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 10, "scene": 35, "line": "That's your new standard.", "order": 39390, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 11, "scene": 1, "line": "Got it. [taking a group photo outside]", "order": 39391, "speaker": "Nate Nickerson"},
  {"season": 7, "episode": 11, "scene": 1, "line": "Okay let's go in. I'm freezing.", "order": 39392, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 11, "scene": 1, "line": "People, wait, wait, wait. Come back, come back. One fun one. We're gonna do a fun one.", "order": 39393, "speaker": "Michael Scott"},
  {"season": 7, "episode": 11, "scene": 1, "line": "One Charlie's Angels. One. Let's go.", "order": 39394, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 11, "scene": 1, "line": "Okay, this is just a Christmas card from your paper supplier. No one's putting this on their fridge.", "order": 39395, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 11, "scene": 1, "line": "Oh, I got it, you guys, how 'bout this. Michael, what if all the boys are on one side, all the girls are on the other. The boys are like, 'why I oughta', and the girls are like, 'let's go shopping!'.", "order": 39396, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 11, "scene": 1, "line": "Let's just jump in the air!", "order": 39397, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 11, "scene": 1, "line": "That's it! That's a picture! Yes! Jump in the air. We're gonna jump in the air. Here we go.", "order": 39398, "speaker": "Michael Scott"},
  {"season": 7, "episode": 11, "scene": 1, "line": "Okay.", "order": 39399, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 11, "scene": 1, "line": "One, two, three. [everyone jumps at different times] Not everyone jumped.", "order": 39400, "speaker": "Nate Nickerson"},
  {"season": 7, "episode": 11, "scene": 1, "line": "Okay, everybody jump in the air this time, please. Here we go.", "order": 39401, "speaker": "Michael Scott"},
  {"season": 7, "episode": 11, "scene": 1, "line": "One, two, three. [everyone jumps] Still some people not jumping.", "order": 39402, "speaker": "Nate Nickerson"},
  {"season": 7, "episode": 11, "scene": 1, "line": "You gotta be kidding me. Who isn't jumping?", "order": 39403, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 11, "scene": 1, "line": "I'll tell you who. Darryl, Phyllis, Stanley, Angela, and Oscar.", "order": 39404, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 11, "scene": 1, "line": "I am jumping.", "order": 39405, "speaker": "Phyllis Vance"},
  {"season": 7, "episode": 11, "scene": 1, "line": "You are?", "order": 39406, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 11, "scene": 1, "line": "Yes, I'm jumping.", "order": 39407, "speaker": "Phyllis Vance"},
  {"season": 7, "episode": 11, "scene": 1, "line": "Let me see you jump. [Phyllis barely jumps] Oh, my God. This is a store bought-camera. This isn't one of those special military-grade cameras that would be able to capture that.", "order": 39408, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 11, "scene": 1, "line": "I'm freezing.", "order": 39409, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 11, "scene": 1, "line": "Um, also, Erin is jumping way too early. She's on the ground by 'three'.", "order": 39410, "speaker": "Nate Nickerson"},
  {"season": 7, "episode": 11, "scene": 1, "line": "I didn't want to miss it.", "order": 39411, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 11, "scene": 1, "line": "Well, if we all jump really high, we'll be in the air longer. Do that.", "order": 39412, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 11, "scene": 1, "line": "Here's a question nobody's asking: Is this worth it?", "order": 39413, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 11, "scene": 1, "line": "Don't answer that. People, listen up and listen good. We need to just get one picture where we're all in the air at the same time. Yes?", "order": 39414, "speaker": "Michael Scott"},
  {"season": 7, "episode": 11, "scene": 1, "line": "Why?", "order": 39415, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 11, "scene": 1, "line": "I believe in us. We can do this. Here we go.", "order": 39416, "speaker": "Michael Scott"},
  {"season": 7, "episode": 11, "scene": 1, "line": "Okay, on three. Uh, everyone in the air. Three, [some people jump] two, [others jump] one, [others jump] zero. [everyone begins to yell]", "order": 39417, "speaker": "Nate Nickerson"},
  {"season": 7, "episode": 11, "scene": 1, "line": "We didn't get it.", "order": 39418, "speaker": "Michael Scott"},
  {"season": 7, "episode": 11, "scene": 2, "line": "I'm the office administrator now, which means I'm basically being paid to be head of the party planning committee. The first thing I did as head... I shut it down. At its best it was a toxic political club used to make others feel miserable and left out. At its best it planned parties.", "order": 39419, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 11, "scene": 3, "line": "It hardly looks fake. It's so lush.", "order": 39420, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 11, "scene": 3, "line": "Why's it smell real? [Pam shows him a hidden car-freshener] Ah... good one.", "order": 39421, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 11, "scene": 4, "line": "Oh, ho, ho, ho! Oh, ho, ho, ho, ho, ho! Merry Christmas, little children! [dressed as Santa]", "order": 39422, "speaker": "Michael Scott"},
  {"season": 7, "episode": 11, "scene": 4, "line": "Merry Christmas.", "order": 39423, "speaker": "All"},
  {"season": 7, "episode": 11, "scene": 4, "line": "How's everybody doing today?", "order": 39424, "speaker": "Michael Scott"},
  {"season": 7, "episode": 11, "scene": 4, "line": "Good.", "order": 39425, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 11, "scene": 4, "line": "How's the party coming along?", "order": 39426, "speaker": "Michael Scott"},
  {"season": 7, "episode": 11, "scene": 4, "line": "Great.", "order": 39427, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 11, "scene": 4, "line": "Are we over budget?", "order": 39428, "speaker": "Michael Scott"},
  {"season": 7, "episode": 11, "scene": 4, "line": "Nope.", "order": 39429, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 11, "scene": 4, "line": "No? Good. Did anyone get drunk already?", "order": 39430, "speaker": "Michael Scott"},
  {"season": 7, "episode": 11, "scene": 4, "line": "Not yet!", "order": 39431, "speaker": "Meredith Palmer"},
  {"season": 7, "episode": 11, "scene": 4, "line": "Good for you. Angela, lay it on me. What's the problem?", "order": 39432, "speaker": "Michael Scott"},
  {"season": 7, "episode": 11, "scene": 4, "line": "Nothing. Should be fun.", "order": 39433, "speaker": "Angela Martin"},
  {"season": 7, "episode": 11, "scene": 4, "line": "So Stanley, how big is the bug up your butt today?", "order": 39434, "speaker": "Michael Scott"},
  {"season": 7, "episode": 11, "scene": 4, "line": "[cheerily] Pam got those sugar-free cookies I like. I'm doing fine.", "order": 39435, "speaker": "Stanley Hudson"},
  {"season": 7, "episode": 11, "scene": 4, "line": "Alright. Well, I will be in my office making toys for the good children. Andy?", "order": 39436, "speaker": "Michael Scott"},
  {"season": 7, "episode": 11, "scene": 4, "line": "Yea?", "order": 39437, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 11, "scene": 4, "line": "Whatcha got?", "order": 39438, "speaker": "Michael Scott"},
  {"season": 7, "episode": 11, "scene": 4, "line": "All good, Santa.", "order": 39439, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 11, "scene": 4, "line": "Well, that's a relief. Santa's gonna take some much-needed free time. Alright. Good!", "order": 39440, "speaker": "Michael Scott"},
  {"season": 7, "episode": 11, "scene": 5, "line": "My kids are growin up. As a boss, I look at that and say great. It is exactly what a boss would hope would happen because that's what I want. That's what every boss wants is a, is a wonderful Christmas with no drama.", "order": 39441, "speaker": "Michael Scott"},
  {"season": 7, "episode": 11, "scene": 6, "line": "It's present time, you guys. [all react] Happy Holidays from your friends at Sabre.", "order": 39442, "speaker": "Kelly Kapoor"},
  {"season": 7, "episode": 11, "scene": 6, "line": "We just want to say how grateful we are.", "order": 39443, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 11, "scene": 7, "line": "Sabre is actively looking for ways to involve me as minority executive trainee. So I suggested choosing the annual Christmas gift to the employees. And they said, 'Oh, yes. Perfect. Thank you, Kelly. Finally, something for you to do.'", "order": 39444, "speaker": "Kelly Kapoor"},
  {"season": 7, "episode": 11, "scene": 8, "line": "It's a Hello Kitty laptop sleeve.", "order": 39445, "speaker": "Kelly Kapoor"},
  {"season": 7, "episode": 11, "scene": 8, "line": "Hello Kitty's for girls.", "order": 39446, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 11, "scene": 8, "line": "Nashua got mp3 players.", "order": 39447, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 11, "scene": 8, "line": "Yeah, I don't even have a laptop.", "order": 39448, "speaker": "Phyllis Vance"},
  {"season": 7, "episode": 11, "scene": 8, "line": "I wonder if these presents would be under as much scrutiny if I were white.", "order": 39449, "speaker": "Kelly Kapoor"},
  {"season": 7, "episode": 11, "scene": 8, "line": "Wow.", "order": 39450, "speaker": "Meredith Palmer"},
  {"season": 7, "episode": 11, "scene": 8, "line": "Oh God.", "order": 39451, "speaker": "Phyllis Vance"},
  {"season": 7, "episode": 11, "scene": 8, "line": "Oh come on.", "order": 39452, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 11, "scene": 8, "line": "I said, 'I wonder.' I didn't say, 'I think.'", "order": 39453, "speaker": "Kelly Kapoor"},
  {"season": 7, "episode": 11, "scene": 8, "line": "Kelly, I thought we agreed on fleece blankets.", "order": 39454, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 11, "scene": 8, "line": "Blankets, what am I, five?", "order": 39455, "speaker": "Kelly Kapoor"},
  {"season": 7, "episode": 11, "scene": 8, "line": "Erin and I make great use of ours.", "order": 39456, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 11, "scene": 9, "line": "Yes, Erin and I are still dating. Why do you ask me so often if we're still dating?", "order": 39457, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 11, "scene": 10, "line": "I'll take one of those pink pouches.", "order": 39458, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 11, "scene": 11, "line": "I feel good today. My little girl Jada? It's my turn to have her for Christmas this year. Two years ago I had her, and we had the best time. I tivoed her favorite shows, some things I've never heard of. iCarly... You know who's funny on that show? The friend with the video camera. He's got a nice way of talking.", "order": 39459, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 11, "scene": 12, "line": "Hey, it's snowing.", "order": 39460, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 11, "scene": 12, "line": "[mocking] Oh, my God! It's the first snowfall of Christmas. Is that just so magical for you, little girl? Can you not wait to have a hot chocolate, and cuddle up with Papa and tell him about all your Christmas dreams, hmm? It's not even a real snow. Look, it's a dusting. Pitiful.", "order": 39461, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 11, "scene": 12, "line": "[Jim goes outside, makes a snowball, and brings it back inside] Hey, Dwight. [Jim throws the snowball at Dwight and everyone laughs]", "order": 39462, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 11, "scene": 12, "line": "Damn it, Jim, you cannot throw snowballs in here!", "order": 39463, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 11, "scene": 12, "line": "Well, it's not a snowball, 'cause it's only a dusting. Right?", "order": 39464, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 11, "scene": 12, "line": "Look at that. There's a pebble in there. You coulda killed me.", "order": 39465, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 11, "scene": 12, "line": "Oh, don't be such a baby.", "order": 39466, "speaker": "Phyllis Vance"},
  {"season": 7, "episode": 11, "scene": 12, "line": "Yeah, who's a little girl now? [everyone laughs]", "order": 39467, "speaker": "Stanley Hudson"},
  {"season": 7, "episode": 11, "scene": 12, "line": "You apologize to me right now.", "order": 39468, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 11, "scene": 12, "line": "You've got something on your nose.", "order": 39469, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 11, "scene": 12, "line": "You apologize right now.", "order": 39470, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 11, "scene": 12, "line": "No.", "order": 39471, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 11, "scene": 12, "line": "Very well, then I challenge you to a snowball fight on the first real snow of winter.", "order": 39472, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 11, "scene": 12, "line": "You got it.", "order": 39473, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 11, "scene": 12, "line": "That sounds awesome. Can we all do it?", "order": 39474, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 11, "scene": 12, "line": "No, Andy, it's a snowball fight. It's not fun. Go get your own thing. Beat it. [Jim continues to shake Dwight's hand] Jim, let go. Let go.", "order": 39475, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 11, "scene": 13, "line": "Angela, are you bringing you new boyfriend to the party.", "order": 39476, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 11, "scene": 13, "line": "I wouldn't subject Robert to that. He's a very busy senator.", "order": 39477, "speaker": "Angela Martin"},
  {"season": 7, "episode": 11, "scene": 13, "line": "State senator.", "order": 39478, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 11, "scene": 13, "line": "I would not expect you to know what it's like to date someone in the public arena.", "order": 39479, "speaker": "Angela Martin"},
  {"season": 7, "episode": 11, "scene": 13, "line": "Who are you dating in the public arena?", "order": 39480, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 11, "scene": 13, "line": "The senator.", "order": 39481, "speaker": "Angela Martin"},
  {"season": 7, "episode": 11, "scene": 13, "line": "Oh, right. The state senator.", "order": 39482, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 11, "scene": 13, "line": "We went to a picnic thrown by the Comptroller's wife.", "order": 39483, "speaker": "Angela Martin"},
  {"season": 7, "episode": 11, "scene": 13, "line": "Oh, that would be impressive... if", "order": 39484, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 11, "scene": 13, "line": "Well...", "order": 39485, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 11, "scene": 14, "line": "Do you have any idea how many photographers there are at a ribbon-cutting ceremony. I do. Two. 'Angela...' [imitates camera shooting] 'over here, Angela...' [imitates camera shooting] 'here. Look here!'", "order": 39486, "speaker": "Angela Martin"},
  {"season": 7, "episode": 11, "scene": 15, "line": "Before we kick off the party, I just want to remind everyone that an office party is just that...", "order": 39487, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 11, "scene": 15, "line": "Yeah!", "order": 39488, "speaker": "Michael Scott"},
  {"season": 7, "episode": 11, "scene": 15, "line": "A party. It's not an excuse to get really drunk or confront someone or have a cathartic experience of any kind.", "order": 39489, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 11, "scene": 15, "line": "Pam? Pam?", "order": 39490, "speaker": "Michael Scott"},
  {"season": 7, "episode": 11, "scene": 15, "line": "Pam?", "order": 39491, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 11, "scene": 15, "line": "Wow, there are, there are questions to that. Okay. Um, you know what, no questions. Last item on the agenda, Toby would like to make an announcement.", "order": 39492, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 11, "scene": 15, "line": "Hi guys.", "order": 39493, "speaker": "Toby Flenderson"},
  {"season": 7, "episode": 11, "scene": 15, "line": "Hi.", "order": 39494, "speaker": "Michael Scott"},
  {"season": 7, "episode": 11, "scene": 15, "line": "Uh, I just wanted to let you know that, uh, I'll be taking a leave of absence starting next week.", "order": 39495, "speaker": "Toby Flenderson"},
  {"season": 7, "episode": 11, "scene": 15, "line": "Uh, because you've been on the Lam? Because the 'boring police' have been after you, and they finally caught up with you?", "order": 39496, "speaker": "Michael Scott"},
  {"season": 7, "episode": 11, "scene": 15, "line": "Uh, no, the opposite. I was actually selected to be a juror on a very high-profile case.", "order": 39497, "speaker": "Toby Flenderson"},
  {"season": 7, "episode": 11, "scene": 15, "line": "Yes, the case of the horrible red-headed sad sack. And the verdict, it was Toby. And the sentence, death. Death to Toby!", "order": 39498, "speaker": "Michael Scott"},
  {"season": 7, "episode": 11, "scene": 15, "line": "Death to Toby!", "order": 39499, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 11, "scene": 15, "line": "Okay, that's hurtful talk. We've talked about that. You know, I don't interrupt your announcements.", "order": 39500, "speaker": "Toby Flenderson"},
  {"season": 7, "episode": 11, "scene": 15, "line": "You know what you, you, you leave these huge pauses in your sentences. What do you expect me to do?", "order": 39501, "speaker": "Michael Scott"},
  {"season": 7, "episode": 11, "scene": 15, "line": "What's the case, Toby?", "order": 39502, "speaker": "Phyllis Vance"},
  {"season": 7, "episode": 11, "scene": 15, "line": "Well, I really can't talk about it, but it's a very high profile case.", "order": 39503, "speaker": "Toby Flenderson"},
  {"season": 7, "episode": 11, "scene": 15, "line": "Is it criminal?", "order": 39504, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 11, "scene": 15, "line": "Yes.", "order": 39505, "speaker": "Toby Flenderson"},
  {"season": 7, "episode": 11, "scene": 15, "line": "Have we heard of it?", "order": 39506, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 11, "scene": 15, "line": "I don't know.", "order": 39507, "speaker": "Toby Flenderson"},
  {"season": 7, "episode": 11, "scene": 15, "line": "Is it the middle school teacher who tried to turn her foreign exchange student into a sex slave?", "order": 39508, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 11, "scene": 15, "line": "Come on.", "order": 39509, "speaker": "Toby Flenderson"},
  {"season": 7, "episode": 11, "scene": 15, "line": "Was it the post office guy who rubbed his penis all over the mail?", "order": 39510, "speaker": "Meredith Palmer"},
  {"season": 7, "episode": 11, "scene": 15, "line": "Guys, it's a really big deal. [rubs his neck]", "order": 39511, "speaker": "Toby Flenderson"},
  {"season": 7, "episode": 11, "scene": 15, "line": "He's rubbing his neck. He's rubbing his neck!", "order": 39512, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 11, "scene": 15, "line": "He's rubbing his neck.", "order": 39513, "speaker": "Kelly Kapoor"},
  {"season": 7, "episode": 11, "scene": 15, "line": "He's rubbing his neck.", "order": 39514, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 11, "scene": 15, "line": "Oh, Scranton Strangler! [all react]", "order": 39515, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 11, "scene": 15, "line": "I can neither confirm nor deny this. Let's just say I'll be up to my neck in jury duty. [excited outbursts]", "order": 39516, "speaker": "Toby Flenderson"},
  {"season": 7, "episode": 11, "scene": 15, "line": "That was the worst joke ever.", "order": 39517, "speaker": "Michael Scott"},
  {"season": 7, "episode": 11, "scene": 15, "line": "Corporate will be sending someone else to take my place for a while.", "order": 39518, "speaker": "Toby Flenderson"},
  {"season": 7, "episode": 11, "scene": 15, "line": "Okay. Okay goodbye, goodbye.", "order": 39519, "speaker": "Michael Scott"},
  {"season": 7, "episode": 11, "scene": 15, "line": "Uh, Holly Flax, she comes from the Nashua branch.", "order": 39520, "speaker": "Toby Flenderson"},
  {"season": 7, "episode": 11, "scene": 15, "line": "What? What?", "order": 39521, "speaker": "Michael Scott"},
  {"season": 7, "episode": 11, "scene": 15, "line": "Yeah, uh, she'll be starting next week. If you have any questions about the transition, just let me know.", "order": 39522, "speaker": "Toby Flenderson"},
  {"season": 7, "episode": 11, "scene": 15, "line": "Hold on, Holly's coming back here?", "order": 39523, "speaker": "Michael Scott"},
  {"season": 7, "episode": 11, "scene": 15, "line": "Yeah.", "order": 39524, "speaker": "Toby Flenderson"},
  {"season": 7, "episode": 11, "scene": 15, "line": "Guys, who's Holly?", "order": 39525, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 11, "scene": 15, "line": "That is a great question, Erin. How do you describe somebody who is at the same time an old friend, and was a lover, and was a complicated part of my past, and maybe, just maybe a part of my future?", "order": 39526, "speaker": "Michael Scott"},
  {"season": 7, "episode": 11, "scene": 15, "line": "Wow.", "order": 39527, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 11, "scene": 15, "line": "She's one sassy black lady.", "order": 39528, "speaker": "Creed Bratton"},
  {"season": 7, "episode": 11, "scene": 15, "line": "Holly's coming back, everybody, and we have to have a party.", "order": 39529, "speaker": "Michael Scott"},
  {"season": 7, "episode": 11, "scene": 15, "line": "I'm not sure the temporary replacement of an H.R. rep really warrants a party.", "order": 39530, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 11, "scene": 15, "line": "You know what, we'll postpone this party until then. This is too important. Cancel this one. Dwight get rid of the tree.", "order": 39531, "speaker": "Michael Scott"},
  {"season": 7, "episode": 11, "scene": 15, "line": "Okay.", "order": 39532, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 11, "scene": 15, "line": "Cancel. We're canceling it. [Michael starts to throw away all of the food]", "order": 39533, "speaker": "Michael Scott"},
  {"season": 7, "episode": 11, "scene": 15, "line": "No, don't throw those out!", "order": 39534, "speaker": "Angela Martin"},
  {"season": 7, "episode": 11, "scene": 15, "line": "No, we have to cancel the party.", "order": 39535, "speaker": "Michael Scott"},
  {"season": 7, "episode": 11, "scene": 15, "line": "We can save that.", "order": 39536, "speaker": "Angela Martin"},
  {"season": 7, "episode": 11, "scene": 15, "line": "No, no, no, we're gonna get fresh for Holly. Fresh and new.", "order": 39537, "speaker": "Michael Scott"},
  {"season": 7, "episode": 11, "scene": 15, "line": "I bought these.", "order": 39538, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 11, "scene": 15, "line": "I know.", "order": 39539, "speaker": "Michael Scott"},
  {"season": 7, "episode": 11, "scene": 15, "line": "These cookies are fine.", "order": 39540, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 11, "scene": 15, "line": "It's not. They're not. Fresh and new. Please let go. Please let go.", "order": 39541, "speaker": "Michael Scott"},
  {"season": 7, "episode": 11, "scene": 15, "line": "Michael, Michael, wait we don't have, we don't have the budget for another party.", "order": 39542, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 11, "scene": 15, "line": "Well then everybody'll chip in, it'll be fine.", "order": 39543, "speaker": "Michael Scott"},
  {"season": 7, "episode": 11, "scene": 15, "line": "I honestly think you're idealizing people here again, Michael. I don't think that's gonna happen.", "order": 39544, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 11, "scene": 15, "line": "You know what, I'll pay for it. I'll pay for the party. It doesn't matter. This is way too important. People, Holly's coming back, and this is the most important Christmas party of my life. So back to work.", "order": 39545, "speaker": "Michael Scott"},
  {"season": 7, "episode": 11, "scene": 16, "line": "Man, I worked hard. I worked so hard for this! I was after corporate constantly. I emailed Joe. I wrote letters. And, know who I end up owing this to is the Scranton strangler. Thank you. Thank you, Scranton strangler. I love you! You just took one more person's breath away.", "order": 39546, "speaker": "Michael Scott"},
  {"season": 7, "episode": 11, "scene": 17, "line": "We have always had good Christmas parties here, as you know, but they've never been cool. [imitating Sean Connery] The name is Bond... Santa Bond. I'll have an eggnog, shaken, not stirred. Classic Brosnan. Santa... wonderful tradition. Everybody loves Santa. Everybody can't get enough of the jolly old man. But that is a myth, because you know what, he is not necessarily a big fat guy with a beard. He's not necessarily an old guy. No one knows what the real Santa Claus... God! Ow! [camera pans down to Angela pinning Michael's pants]", "order": 39547, "speaker": "Michael Scott"},
  {"season": 7, "episode": 11, "scene": 17, "line": "Just stop moving your calves so much while you're talking.", "order": 39548, "speaker": "Angela Martin"},
  {"season": 7, "episode": 11, "scene": 17, "line": "Well, this year's gonna be different. We're gonna have fun. It's not gonna be tacky. It's going to be, you know what, the food is going to be austere. It is not going to be tacky, deli platter food. It's not gonna have a big, fat, gross Santa Claus. It's gonna be cool, sleek Santa.", "order": 39549, "speaker": "Michael Scott"},
  {"season": 7, "episode": 11, "scene": 17, "line": "Maybe I'll bring my boyfriend. I'll invite him.", "order": 39550, "speaker": "Angela Martin"},
  {"season": 7, "episode": 11, "scene": 17, "line": "Okay. Sure.", "order": 39551, "speaker": "Michael Scott"},
  {"season": 7, "episode": 11, "scene": 17, "line": "I mean, unless there's any chance there could be press at this party.", "order": 39552, "speaker": "Angela Martin"},
  {"season": 7, "episode": 11, "scene": 17, "line": "You never know about the press.", "order": 39553, "speaker": "Michael Scott"},
  {"season": 7, "episode": 11, "scene": 17, "line": "Well, I only ask because he's a senator.", "order": 39554, "speaker": "Angela Martin"},
  {"season": 7, "episode": 11, "scene": 17, "line": "Could he help us with some parking tickets?", "order": 39555, "speaker": "Michael Scott"},
  {"season": 7, "episode": 11, "scene": 17, "line": "I don't think that's appropriate.", "order": 39556, "speaker": "Angela Martin"},
  {"season": 7, "episode": 11, "scene": 17, "line": "Well, then he's not a senator.", "order": 39557, "speaker": "Michael Scott"},
  {"season": 7, "episode": 11, "scene": 17, "line": "Yes, he is.", "order": 39558, "speaker": "Angela Martin"},
  {"season": 7, "episode": 11, "scene": 17, "line": "Okay.", "order": 39559, "speaker": "Michael Scott"},
  {"season": 7, "episode": 11, "scene": 18, "line": "Hey.", "order": 39560, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 11, "scene": 18, "line": "Hey.", "order": 39561, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 11, "scene": 18, "line": "We still doing the gifts today? [Pam nods] I mean, it is the Christmas party. Well, the classy Christmas party.", "order": 39562, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 11, "scene": 18, "line": "Yes. But don't get too excited, 'cause I didn't have a lot of time this year.", "order": 39563, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 11, "scene": 18, "line": "Me neither. Whew.", "order": 39564, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 11, "scene": 18, "line": "Okay.", "order": 39565, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 11, "scene": 19, "line": "I've been working forever on Jim's present. He always gives me the best Christmas gifts. He'll take a memory or a private joke, and he'll create something totally unique. I love them. So this year I made him something. A comic book. It stars Jimmy Halpert, a mild-mannered paper Salesman who, while riding his bike through the forest, is bitten by a radioactive bear, becomes 'bear man'. Wreaks havoc on the office. It's really good.", "order": 39566, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 11, "scene": 20, "line": "[A note is on Jim's computer that says, 'It is time. Parking lot at noon.' Jim nods and hands it to Dwight who burns it.]", "order": 39567, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 11, "scene": 21, "line": "Okay... [sighs] Oh, no, no, no, no! Fake tree. No, no!", "order": 39568, "speaker": "Michael Scott"},
  {"season": 7, "episode": 11, "scene": 21, "line": "No, it has the little...", "order": 39569, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 11, "scene": 21, "line": "Pam, no, no. Holly's coming from New Hampshire. Somebody from New Hampshire looks at that and thinks it's a burning cross. No, no. I want you to go and get a real tree. Take some money. Thank you.", "order": 39570, "speaker": "Michael Scott"},
  {"season": 7, "episode": 11, "scene": 21, "line": "Hi, I'm looking for Michael Scott.", "order": 39571, "speaker": "Bass Player"},
  {"season": 7, "episode": 11, "scene": 21, "line": "Yes, that's me. Come on it.", "order": 39572, "speaker": "Michael Scott"},
  {"season": 7, "episode": 11, "scene": 22, "line": "[scats awkwardly] There's nothing classier than boring Jazz music. I am here to tell ya. And I made a bit of a judgment call. I hired one musician, because I thought, what's better, to hire an entire quartet for half an hour, or one bassist for the entire day?", "order": 39573, "speaker": "Michael Scott"},
  {"season": 7, "episode": 11, "scene": 23, "line": "Hey, uh, any volunteers to come with me to go buy a Christmas tree?", "order": 39574, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 11, "scene": 23, "line": "I would, but I don't want to get dirty. There might be girls at the party.", "order": 39575, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 11, "scene": 23, "line": "Why do you always think that girls are gonna be at the party? No one invited girls. It's just us.", "order": 39576, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 11, "scene": 23, "line": "I will help. Although my 'brid', my hybrid, my Prius hybrid, won't fit a tree. Which is ironic, considering how many trees it saves on a daily basis.", "order": 39577, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 11, "scene": 23, "line": "Yeah.", "order": 39578, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 11, "scene": 23, "line": "I do, however, have a hookup with a dude who has a pickup.", "order": 39579, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 11, "scene": 23, "line": "Oh, do you mean Darryl? That's a great idea. I'll ask him.", "order": 39580, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 11, "scene": 23, "line": "You know Darryl?", "order": 39581, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 11, "scene": 23, "line": "Yeah. He works here. We all know him.", "order": 39582, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 11, "scene": 23, "line": "I should come along, just 'cause he's my, you know, he's my hookup.", "order": 39583, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 11, "scene": 23, "line": "Cool.", "order": 39584, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 11, "scene": 23, "line": "Come on, now, Justine, look... Listen, look, I've been planning this, okay? You cannot do this, Justine.", "order": 39585, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 11, "scene": 23, "line": "[on the phone] I'm not doing it! She told me she wants to have Christmas here.", "order": 39586, "speaker": "Justine"},
  {"season": 7, "episode": 11, "scene": 23, "line": "She did?", "order": 39587, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 11, "scene": 23, "line": "Yes. She wants to be around family for Christmas.", "order": 39588, "speaker": "Justine"},
  {"season": 7, "episode": 11, "scene": 24, "line": "I don't know... I thought I was enough family for my daughter.", "order": 39589, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 11, "scene": 25, "line": "[Pam knocks on Darryl's door] Don't come in, I'm busy. [Pam opens the door]", "order": 39590, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 11, "scene": 25, "line": "It's cool, Darryl, I'm here too.", "order": 39591, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 11, "scene": 25, "line": "Hey, sorry, I really didn't want to come in. It's just that we have to go buy a new Christmas tree, and we're hoping we could borrow your truck.", "order": 39592, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 11, "scene": 25, "line": "Uh, no.", "order": 39593, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 11, "scene": 25, "line": "Come on, it'll be fun. We could do doughnuts in the snow on the way back.", "order": 39594, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 11, "scene": 25, "line": "No. Thank you for your interest in my truck.", "order": 39595, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 11, "scene": 25, "line": "Okay. Sorry. We'll uh, we'll leave you alone.", "order": 39596, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 11, "scene": 25, "line": "Hey... [sighs] You know what, I could use a breath of fresh air. Let's do it.", "order": 39597, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 11, "scene": 26, "line": "When Holly gets here, I want you to be very helpful to her.", "order": 39598, "speaker": "Michael Scott"},
  {"season": 7, "episode": 11, "scene": 26, "line": "I've looked her up online... there's nothing about her. She's made no impression on the internet.", "order": 39599, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 11, "scene": 26, "line": "She doesn't need an internet presence, you just know.", "order": 39600, "speaker": "Michael Scott"},
  {"season": 7, "episode": 11, "scene": 26, "line": "Oh...", "order": 39601, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 11, "scene": 26, "line": "Hey.", "order": 39602, "speaker": "Toby Flenderson"},
  {"season": 7, "episode": 11, "scene": 26, "line": "What the hell are you doing here? You're supposed to be in the courthouse.", "order": 39603, "speaker": "Michael Scott"},
  {"season": 7, "episode": 11, "scene": 26, "line": "Uh, we're on recess. I came for the party. Hey, everyone.", "order": 39604, "speaker": "Toby Flenderson"},
  {"season": 7, "episode": 11, "scene": 26, "line": "Hi, Toby.", "order": 39605, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 11, "scene": 27, "line": "What does the strangler look like? Is he gorgeous? He looks gorgeous in the drawings.", "order": 39606, "speaker": "Meredith Palmer"},
  {"season": 7, "episode": 11, "scene": 27, "line": "Yeah.", "order": 39607, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 11, "scene": 27, "line": "That scowl.", "order": 39608, "speaker": "Meredith Palmer"},
  {"season": 7, "episode": 11, "scene": 27, "line": "I can't talk about it or I'll get removed from the jury.", "order": 39609, "speaker": "Toby Flenderson"},
  {"season": 7, "episode": 11, "scene": 27, "line": "And then he will come back here and replace Holly, so stop asking him questions.", "order": 39610, "speaker": "Michael Scott"},
  {"season": 7, "episode": 11, "scene": 28, "line": "I know people are only this excited to talk to me because of the trial. But, they talk to me for a while, and maybe people realize I have something to say. And then one day, we're just talking.", "order": 39611, "speaker": "Toby Flenderson"},
  {"season": 7, "episode": 11, "scene": 29, "line": "[standing outside, dials Dwight's cell phone]", "order": 39612, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 11, "scene": 29, "line": "[voicemail recording] You've reached the voice mail of Dwight Kurt Schrute. Please leave... [Jim hangs up and turns for the door. It is pad locked. Jim turns as Dwight springs from a nearby snowman and pelts him with snowballs.]", "order": 39613, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 11, "scene": 29, "line": "Ahhhhhhh!!!! Ahh! Ahh! Ahh!", "order": 39614, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 11, "scene": 29, "line": "Stop! Stop!", "order": 39615, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 11, "scene": 29, "line": "Ahh!", "order": 39616, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 11, "scene": 29, "line": "Oh... oh!", "order": 39617, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 11, "scene": 29, "line": "[grunting, shouting]", "order": 39618, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 11, "scene": 30, "line": "I have no feeling in my fingers or penis. But I think it was worth it.", "order": 39619, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 11, "scene": 31, "line": "[dumps the remainder of the snow in his bag on Jim, then rests his foot on Jim and raises his arms in the air] Haahh!! [runs off]", "order": 39620, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 11, "scene": 32, "line": "Um, I was laying on the ground, defenseless, and uh, he just kept throwing 'em until he exhausted himself. And, uh, [cell phone chimes] then... [reading text message] 'How 'bout icing it? lol. Dwight.'", "order": 39621, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 11, "scene": 33, "line": "Hi.", "order": 39622, "speaker": "Holly Flax"},
  {"season": 7, "episode": 11, "scene": 33, "line": "Hello.", "order": 39623, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 11, "scene": 33, "line": "Is there any way I can get a hand with these, please?", "order": 39624, "speaker": "Holly Flax"},
  {"season": 7, "episode": 11, "scene": 33, "line": "I'm really sorry, I can't help you. I'm waiting for my boss' pretty friend to arrive.", "order": 39625, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 11, "scene": 33, "line": "There she is. Hey. Erin, would you help her, for God's sake?", "order": 39626, "speaker": "Michael Scott"},
  {"season": 7, "episode": 11, "scene": 33, "line": "Oh, you're Holly! Of course. Sorry.", "order": 39627, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 11, "scene": 33, "line": "Hello.", "order": 39628, "speaker": "Michael Scott"},
  {"season": 7, "episode": 11, "scene": 33, "line": "[weak Clint Eastwood accent] Well, well, well, if it isn't Michael Scott. You old bastard.", "order": 39629, "speaker": "Holly Flax"},
  {"season": 7, "episode": 11, "scene": 33, "line": "[imitating her accent] Well, I never thought I'd see your face around these parts, you old bastard.", "order": 39630, "speaker": "Michael Scott"},
  {"season": 7, "episode": 11, "scene": 33, "line": "Well I did show my face around these parts, you old bastard.", "order": 39631, "speaker": "Holly Flax"},
  {"season": 7, "episode": 11, "scene": 33, "line": "[as Curly] Why, you're some sorta wise guy, huh?", "order": 39632, "speaker": "Michael Scott"},
  {"season": 7, "episode": 11, "scene": 33, "line": "[as Curly] I most certainly am.", "order": 39633, "speaker": "Holly Flax"},
  {"season": 7, "episode": 11, "scene": 33, "line": "Nyuk, nyuk, nyuk, nyuk!", "order": 39634, "speaker": "Michael Scott"},
  {"season": 7, "episode": 11, "scene": 33, "line": "Unnnnnnngh!", "order": 39635, "speaker": "Holly Flax"},
  {"season": 7, "episode": 11, "scene": 33, "line": "[as Homer] D'oh!", "order": 39636, "speaker": "Michael Scott"},
  {"season": 7, "episode": 11, "scene": 33, "line": "[as Marge] Oh, Homey. [they both laugh]", "order": 39637, "speaker": "Holly Flax"},
  {"season": 7, "episode": 11, "scene": 33, "line": "Okay... Holly's back.", "order": 39638, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 11, "scene": 33, "line": "Hi. Hi. Oh... [they hug]", "order": 39639, "speaker": "Michael Scott"},
  {"season": 7, "episode": 11, "scene": 33, "line": "[in monster voice] Oh, huggy monster!", "order": 39640, "speaker": "Holly Flax"},
  {"season": 7, "episode": 11, "scene": 33, "line": "Oh no, not the huggy monster! I don't wanna die! I don't wanna die! I don't wanna die! [both sigh] Wow. Well, everybody, you remember Holly.", "order": 39641, "speaker": "Michael Scott"},
  {"season": 7, "episode": 11, "scene": 33, "line": "Hi.", "order": 39642, "speaker": "Holly Flax"},
  {"season": 7, "episode": 11, "scene": 33, "line": "Did you bring us anything from Nashua?", "order": 39643, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 11, "scene": 33, "line": "Oh, yeah. I brought um, some maple candy. But I have to admit, I got a little hungry on the drive, and I ate some of them.", "order": 39644, "speaker": "Holly Flax"},
  {"season": 7, "episode": 11, "scene": 33, "line": "That's adorable.", "order": 39645, "speaker": "Michael Scott"},
  {"season": 7, "episode": 11, "scene": 33, "line": "Would you put those out?", "order": 39646, "speaker": "Holly Flax"},
  {"season": 7, "episode": 11, "scene": 33, "line": "To throw out? Or put out, like, six pieces for everyone?", "order": 39647, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 11, "scene": 33, "line": "Oh, it's so nice to be back. It looks beautiful in here. Super classy. It's like a party for limousine drivers.", "order": 39648, "speaker": "Holly Flax"},
  {"season": 7, "episode": 11, "scene": 33, "line": "Well, you came on the day of our Christmas party.", "order": 39649, "speaker": "Michael Scott"},
  {"season": 7, "episode": 11, "scene": 33, "line": "Oh, it's fabulous. I love what you did.", "order": 39650, "speaker": "Holly Flax"},
  {"season": 7, "episode": 11, "scene": 33, "line": "Isn't it wonderful? We love it here. Don't you love it? All right, let me show you to your desk. [in a stereotyped Mexican accent] I show you to your desk.", "order": 39651, "speaker": "Michael Scott"},
  {"season": 7, "episode": 11, "scene": 33, "line": "[in the same accent] Watch out for my guns they're both loaded. [makes gun noises]", "order": 39652, "speaker": "Holly Flax"},
  {"season": 7, "episode": 11, "scene": 33, "line": "What kind of guns do you have? Six-shooter?", "order": 39653, "speaker": "Michael Scott"},
  {"season": 7, "episode": 11, "scene": 33, "line": "Aww...", "order": 39654, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 11, "scene": 33, "line": "It's a pea shoot...", "order": 39655, "speaker": "Holly Flax"},
  {"season": 7, "episode": 11, "scene": 34, "line": "This is weird. I can't believe you're here. This feels like you never left. Doesn't it?", "order": 39656, "speaker": "Michael Scott"},
  {"season": 7, "episode": 11, "scene": 34, "line": "Yeah, kind of.", "order": 39657, "speaker": "Holly Flax"},
  {"season": 7, "episode": 11, "scene": 34, "line": "Oh...", "order": 39658, "speaker": "Michael Scott"},
  {"season": 7, "episode": 11, "scene": 34, "line": "Oh...", "order": 39659, "speaker": "Holly Flax"},
  {"season": 7, "episode": 11, "scene": 34, "line": "Oh, and you have a Woody. Bah! [the both chuckle] Oh, I love toy...", "order": 39660, "speaker": "Michael Scott"},
  {"season": 7, "episode": 11, "scene": 34, "line": "AJ gave me that.", "order": 39661, "speaker": "Holly Flax"},
  {"season": 7, "episode": 11, "scene": 34, "line": "Well, that's understandable. Still raw. Woody your favorite character?", "order": 39662, "speaker": "Michael Scott"},
  {"season": 7, "episode": 11, "scene": 34, "line": "Mmhmm.", "order": 39663, "speaker": "Holly Flax"},
  {"season": 7, "episode": 11, "scene": 34, "line": "You know who my favorite character in Toy Story is? Andy's mom.", "order": 39664, "speaker": "Michael Scott"},
  {"season": 7, "episode": 11, "scene": 34, "line": "Why?", "order": 39665, "speaker": "Holly Flax"},
  {"season": 7, "episode": 11, "scene": 34, "line": "Because without Andy's mom, there's no plot. And without any plot, there is no movie.", "order": 39666, "speaker": "Michael Scott"},
  {"season": 7, "episode": 11, "scene": 34, "line": "That is a really good point. AJ said he hadn't seen any of the Toy Story movies.", "order": 39667, "speaker": "Holly Flax"},
  {"season": 7, "episode": 11, "scene": 34, "line": "You're kidding me. Ahh!", "order": 39668, "speaker": "Michael Scott"},
  {"season": 7, "episode": 11, "scene": 34, "line": "No. I know. I was like, 'What? Are you serious?'", "order": 39669, "speaker": "Holly Flax"},
  {"season": 7, "episode": 11, "scene": 34, "line": "What a douche bag!", "order": 39670, "speaker": "Michael Scott"},
  {"season": 7, "episode": 11, "scene": 34, "line": "Get a life!", "order": 39671, "speaker": "Holly Flax"},
  {"season": 7, "episode": 11, "scene": 34, "line": "Get a... yeah! Good riddance.", "order": 39672, "speaker": "Michael Scott"},
  {"season": 7, "episode": 11, "scene": 34, "line": "We sat down and we watched them all in one day.", "order": 39673, "speaker": "Holly Flax"},
  {"season": 7, "episode": 11, "scene": 34, "line": "Mmhmm.", "order": 39674, "speaker": "Michael Scott"},
  {"season": 7, "episode": 11, "scene": 34, "line": "Now he's the biggest Toy Story fanatic ever.", "order": 39675, "speaker": "Holly Flax"},
  {"season": 7, "episode": 11, "scene": 34, "line": "Good for him.", "order": 39676, "speaker": "Michael Scott"},
  {"season": 7, "episode": 11, "scene": 34, "line": "Next day I found him in my bed.", "order": 39677, "speaker": "Holly Flax"},
  {"season": 7, "episode": 11, "scene": 34, "line": "Really? That's creepy. How did AJ get in your house?", "order": 39678, "speaker": "Michael Scott"},
  {"season": 7, "episode": 11, "scene": 34, "line": "We live together.", "order": 39679, "speaker": "Holly Flax"},
  {"season": 7, "episode": 11, "scene": 34, "line": "Oh, you do?", "order": 39680, "speaker": "Michael Scott"},
  {"season": 7, "episode": 11, "scene": 34, "line": "He had a little note pinned to him that said, 'You've got a friend in me.'", "order": 39681, "speaker": "Holly Flax"},
  {"season": 7, "episode": 11, "scene": 34, "line": "[fighting back tears] Yeah, Randy Newman's the best.", "order": 39682, "speaker": "Michael Scott"},
  {"season": 7, "episode": 11, "scene": 34, "line": "Yeah. I love him.", "order": 39683, "speaker": "Holly Flax"},
  {"season": 7, "episode": 11, "scene": 34, "line": "Me too.", "order": 39684, "speaker": "Michael Scott"},
  {"season": 7, "episode": 11, "scene": 35, "line": "[singing] Christmas tree, Christmas tree. Won't you be my Christmas tree.", "order": 39685, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 11, "scene": 35, "line": "Hey, how about this one?", "order": 39686, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 11, "scene": 35, "line": "Ehh, seems a little full of itself, right? Kind of a pretty boy? What if we got a really beat up one, like on Charlie Brown. And we just loved it for what it is?", "order": 39687, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 11, "scene": 35, "line": "Maybe. I mean...", "order": 39688, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 11, "scene": 35, "line": "[on the phone] I just, no, she be talking to your mom or something. This is my daughter too, Justine! You seem to think, [Justine hangs up] oh... pick a damn tree already.", "order": 39689, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 11, "scene": 35, "line": "Um, hey, Darryl, listen, it's none of my business, but if I couldn't have Cece for Christmas, I'd be really upset too.", "order": 39690, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 11, "scene": 35, "line": "I don't have kids or anything, but if my grandmother ever dies, I'm going to kill myself.", "order": 39691, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 11, "scene": 35, "line": "Jada don't want to spend Christmas with me. She told her mom it wasn't as much fun. And how could she say that? You know, I took her to the toy store to buy her own presents.", "order": 39692, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 11, "scene": 35, "line": "Well Darryl, no kid wants to buy their own Christmas presents.", "order": 39693, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 11, "scene": 35, "line": "Her mom wants me to take her to mass. I guess that's something to do.", "order": 39694, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 11, "scene": 35, "line": "No, you should have her mom do the boring Christmas stuff on her time. You should be associated with the fun parts of Christmas.", "order": 39695, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 11, "scene": 35, "line": "How do I do that?", "order": 39696, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 11, "scene": 35, "line": "Bring her to the party. Yeah, we'll have Santa, and we'll play games with her. It'll be a lot of fun.", "order": 39697, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 11, "scene": 35, "line": "Yes.", "order": 39698, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 11, "scene": 35, "line": "You'll be Mr. Christmas by the end of the night.", "order": 39699, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 11, "scene": 36, "line": "Hey guys, the tree's here.", "order": 39700, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 11, "scene": 36, "line": "Hey.", "order": 39701, "speaker": "Michael Scott"},
  {"season": 7, "episode": 11, "scene": 36, "line": "Nobody hug me, I'm covered in tree sap, so...", "order": 39702, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 11, "scene": 36, "line": "Why would someone hug you?", "order": 39703, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 11, "scene": 36, "line": "Hey there, Jada. Nice to see you again. I'm Santa Claus. Welcome.", "order": 39704, "speaker": "Michael Scott"},
  {"season": 7, "episode": 11, "scene": 36, "line": "Are you serious?", "order": 39705, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 11, "scene": 36, "line": "It's a sophisticated take.", "order": 39706, "speaker": "Michael Scott"},
  {"season": 7, "episode": 11, "scene": 36, "line": "He doesn't look like Santa Claus.", "order": 39707, "speaker": "Jada"},
  {"season": 7, "episode": 11, "scene": 36, "line": "No, he doesn't. [to Michael] I told her Santa would be here.", "order": 39708, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 11, "scene": 36, "line": "Yeah, well, I was told that Holly would be here, single and ready to date. And we all got misled.", "order": 39709, "speaker": "Michael Scott"},
  {"season": 7, "episode": 11, "scene": 36, "line": "Who told you that?", "order": 39710, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 11, "scene": 36, "line": "Nora Ephron, in every romantic comedy ever made.", "order": 39711, "speaker": "Michael Scott"},
  {"season": 7, "episode": 11, "scene": 37, "line": "So is it an open relationship?", "order": 39712, "speaker": "Phyllis Vance"},
  {"season": 7, "episode": 11, "scene": 37, "line": "Oh, God, no.", "order": 39713, "speaker": "Holly Flax"},
  {"season": 7, "episode": 11, "scene": 37, "line": "Well, you're almost 40. Oh, do you not want kids?", "order": 39714, "speaker": "Kelly Kapoor"},
  {"season": 7, "episode": 11, "scene": 37, "line": "Oh, I want kids. I really want kids. But AJ and I are practically engaged. We talk about spending our lives together.", "order": 39715, "speaker": "Holly Flax"},
  {"season": 7, "episode": 11, "scene": 37, "line": "So where's the ring?", "order": 39716, "speaker": "Kelly Kapoor"},
  {"season": 7, "episode": 11, "scene": 37, "line": "Kelly! Um, how are you adjusting to the move?", "order": 39717, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 11, "scene": 37, "line": "Nobody cares about that. Look, you have to make him commit, or kick his butt to the curb.", "order": 39718, "speaker": "Phyllis Vance"},
  {"season": 7, "episode": 11, "scene": 37, "line": "You guys, I don't think any of us are really qualified to be giving Holly personal advice about her love life.", "order": 39719, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 11, "scene": 37, "line": "Yeah, I mean, maybe Holly's not in any position to be shooing guys away.", "order": 39720, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 11, "scene": 38, "line": "I don't get it! I'm sorry. I just, I don't get it!", "order": 39721, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 11, "scene": 39, "line": "Okay, listen, I'm gonna tell him that if he doesn't propose by the end of this year, we're over.", "order": 39722, "speaker": "Holly Flax"},
  {"season": 7, "episode": 11, "scene": 39, "line": "Wow, an ultimatum.", "order": 39723, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 11, "scene": 39, "line": "Yeah.", "order": 39724, "speaker": "Holly Flax"},
  {"season": 7, "episode": 11, "scene": 39, "line": "It doesn't really seem like you.", "order": 39725, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 11, "scene": 39, "line": "That is a great idea. Ultimatums are key. Basically, nobody does anything for me anymore unless I threaten to kill myself.", "order": 39726, "speaker": "Kelly Kapoor"},
  {"season": 7, "episode": 11, "scene": 40, "line": "[turns in Pam's chair with a Pam wig on and laughs maniacally]", "order": 39727, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 11, "scene": 40, "line": "Don't. Stop, Dwight! [Dwight pelts Jim with snowballs] Dwight, stop! Dwight, stop!", "order": 39728, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 11, "scene": 40, "line": "Oh, no. Oh, no!", "order": 39729, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 11, "scene": 40, "line": "Stop!", "order": 39730, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 11, "scene": 40, "line": "Oh, no. Yes, taste my wrath!", "order": 39731, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 11, "scene": 40, "line": "Okay. Okay. Okay.", "order": 39732, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 11, "scene": 40, "line": "Huh? You like that?", "order": 39733, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 11, "scene": 40, "line": "Seriously!", "order": 39734, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 11, "scene": 40, "line": "Huh?", "order": 39735, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 11, "scene": 40, "line": "Okay. Okay. [Dwight throws another snowball and laughs maniacally]", "order": 39736, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 11, "scene": 41, "line": "Yes, I have a wig for every single person in the office. You never know when your gonna need to bear a passing resemblance to someone.", "order": 39737, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 11, "scene": 42, "line": "I just want it to stop.", "order": 39738, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 11, "scene": 43, "line": "So, cool right?", "order": 39739, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 11, "scene": 43, "line": "There's no connection between the origin story and the quest.", "order": 39740, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 11, "scene": 43, "line": "Okay.", "order": 39741, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 11, "scene": 43, "line": "We need to know who Jimmy Halpert was before he was bitten by the bear. Otherwise, it's the bear's quest.", "order": 39742, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 11, "scene": 43, "line": "Okay, well, I just sorta meant, like, cute, right?", "order": 39743, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 11, "scene": 43, "line": "Well, did you come here for help, or did you come here for me to tell you how great it is?", "order": 39744, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 11, "scene": 43, "line": "I mean, help, if it's something simple, like add page numbers or laminate it or...", "order": 39745, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 11, "scene": 43, "line": "Well, it sounds like you know what you want. [Pam walks away] It's also a little derivative of a serias called 'Bear Man'. Did you look that up?", "order": 39746, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 11, "scene": 43, "line": "No.", "order": 39747, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 11, "scene": 43, "line": "Okay.", "order": 39748, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 11, "scene": 44, "line": "Oh, Jim. Hey.", "order": 39749, "speaker": "Toby Flenderson"},
  {"season": 7, "episode": 11, "scene": 44, "line": "Hey, Toby.", "order": 39750, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 11, "scene": 44, "line": "There's this female uh, uh, stenographer at the courthouse...", "order": 39751, "speaker": "Toby Flenderson"},
  {"season": 7, "episode": 11, "scene": 44, "line": "No way.", "order": 39752, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 11, "scene": 44, "line": "Who looks exactly like you.", "order": 39753, "speaker": "Toby Flenderson"},
  {"season": 7, "episode": 11, "scene": 44, "line": "That's increadible.", "order": 39754, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 11, "scene": 44, "line": "Yeah. No, it's uncanny.", "order": 39755, "speaker": "Toby Flenderson"},
  {"season": 7, "episode": 11, "scene": 44, "line": "You know what's crazy?", "order": 39756, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 11, "scene": 44, "line": "What?", "order": 39757, "speaker": "Toby Flenderson"},
  {"season": 7, "episode": 11, "scene": 44, "line": "I can't reconnect with you right now. Hold on one second. [he hurls a snowball at Dwight with a lacrosse stick, misses, and shatters a window, everyone gasps] Excuse me. [clears throat]", "order": 39758, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 11, "scene": 45, "line": "I guess there are just some people who you stay together with when you transfer, and some people you don't. And that's just the way it is. And I can be mature about that. [clip of Michael throwing Holly's Woody in the trash and pouring coffee on it]", "order": 39759, "speaker": "Michael Scott"},
  {"season": 7, "episode": 11, "scene": 46, "line": "It's so cold. Even with my coat on.", "order": 39760, "speaker": "Angela Martin"},
  {"season": 7, "episode": 11, "scene": 46, "line": "Maybe your senator boyfriend has a blanket in his car... for screwing Americans.", "order": 39761, "speaker": "Meredith Palmer"},
  {"season": 7, "episode": 11, "scene": 46, "line": "Yet another opportunity where a blanket would have come in handy.", "order": 39762, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 11, "scene": 47, "line": "You guys, this has to stop. Someone could have really gotten hurt.", "order": 39763, "speaker": "Holly Flax"},
  {"season": 7, "episode": 11, "scene": 47, "line": "What if Meredith was taking her smoking break below that window? You know what would have happened? The shards of glass would have shaved her face right off. And, yes, it might have been funny. But it also would have been incredibly tragic.", "order": 39764, "speaker": "Michael Scott"},
  {"season": 7, "episode": 11, "scene": 47, "line": "I could not agree more. And just want to state for the record that I am intending to sue Jim for acute psychological distress.", "order": 39765, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 11, "scene": 47, "line": "What are you talking about? You're the one terrorizing me.", "order": 39766, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 11, "scene": 47, "line": "With snowballs, Jim? With fluffy little snowballs? No. I thought we were just playing.", "order": 39767, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 11, "scene": 47, "line": "Dwight's right. What you did was dangerous and inappropriate. I'm really surprised at you guys. Last time I was here, you were both best friends.", "order": 39768, "speaker": "Holly Flax"},
  {"season": 7, "episode": 11, "scene": 47, "line": "All this arguing reminds me of a very funny story. You see this on my desk? [he holds up a toy taxi cab] Know who gave me this? My girlfriend Tara, who lives in New York City.", "order": 39769, "speaker": "Michael Scott"},
  {"season": 7, "episode": 11, "scene": 47, "line": "Wait, what girlfriend?", "order": 39770, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 11, "scene": 47, "line": "I haven't told you about her.", "order": 39771, "speaker": "Michael Scott"},
  {"season": 7, "episode": 11, "scene": 47, "line": "I find that unlikely. You email me when you get a new zit.", "order": 39772, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 11, "scene": 47, "line": "I'm a man in my mid-40s and I still get zits. I think that's pretty interesting. But you know what? I am not on trial here. The Scranton strangler is. So, anyway, we have this great weekend, and she drives me to the airport, and we get to JFK, but I am flying out of LaGuardia. So we laugh and laugh and laugh, and then we spend the rest of the day walking around Slo-mo, drinking latte. And at the end of the day, she gives me this, and she says, 'Michael, maybe next time, you should take a cab.'", "order": 39773, "speaker": "Michael Scott"},
  {"season": 7, "episode": 11, "scene": 47, "line": "I didn't know you had a girlfriend.", "order": 39774, "speaker": "Holly Flax"},
  {"season": 7, "episode": 11, "scene": 47, "line": "I do. She is.", "order": 39775, "speaker": "Michael Scott"},
  {"season": 7, "episode": 11, "scene": 48, "line": "Did you see her face? Well it seems to me that there is a person sitting out there in the annex that still has feelings for Michael G. Scott. And it ain't Tara.", "order": 39776, "speaker": "Michael Scott"},
  {"season": 7, "episode": 11, "scene": 49, "line": "So you went homemade this year.", "order": 39777, "speaker": "Phyllis Vance"},
  {"season": 7, "episode": 11, "scene": 49, "line": "Yup.", "order": 39778, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 11, "scene": 49, "line": "Yeah. Money problems, is that what this is about? I mean, oh, dear, I don't think we can help you out.", "order": 39779, "speaker": "Phyllis Vance"},
  {"season": 7, "episode": 11, "scene": 49, "line": "No, no. Jim had a great year, actually. I just wanted to get your opinion.", "order": 39780, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 11, "scene": 49, "line": "Are you good at homemade?", "order": 39781, "speaker": "Phyllis Vance"},
  {"season": 7, "episode": 11, "scene": 49, "line": "Look at this.", "order": 39782, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 11, "scene": 49, "line": "Yeah...", "order": 39783, "speaker": "Phyllis Vance"},
  {"season": 7, "episode": 11, "scene": 50, "line": "[walks in with a dirty Woody] Who did this? It's obvious to me I'm not welcome here, but somebody better tell me who did this, or else I'm leaving.", "order": 39784, "speaker": "Holly Flax"},
  {"season": 7, "episode": 11, "scene": 50, "line": "Well, I don't think Erin seems to like you.", "order": 39785, "speaker": "Phyllis Vance"},
  {"season": 7, "episode": 11, "scene": 50, "line": "That's not true. I don't know her enough to make a decision even.", "order": 39786, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 11, "scene": 50, "line": "What happened? What happened? Toby, what did you do? I think Toby's very jealous of all the attention you've been getting.", "order": 39787, "speaker": "Michael Scott"},
  {"season": 7, "episode": 11, "scene": 50, "line": "No, I would never ever do anything like that. But it does seem like something you would do.", "order": 39788, "speaker": "Toby Flenderson"},
  {"season": 7, "episode": 11, "scene": 50, "line": "Oh, really? Turn it on me. Well, isn't that nice? Thank you very much.", "order": 39789, "speaker": "Michael Scott"},
  {"season": 7, "episode": 11, "scene": 50, "line": "Wait, wait, guys, listen. Toy Story is all about toys that come to life when people aren't looking. You don't think... it's not possible... that Woody did this to himself.", "order": 39790, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 11, "scene": 50, "line": "It is Christmas.", "order": 39791, "speaker": "Michael Scott"},
  {"season": 7, "episode": 11, "scene": 50, "line": "No, it really seems like something Michael would do.", "order": 39792, "speaker": "Angela Martin"},
  {"season": 7, "episode": 11, "scene": 50, "line": "Mmhmm. Mmhmm. Well, the fact that you would think that points to the possibility that it is probably not me. And that I have been framed.", "order": 39793, "speaker": "Michael Scott"},
  {"season": 7, "episode": 11, "scene": 50, "line": "Why would anyone frame you for that?", "order": 39794, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 11, "scene": 50, "line": "Okay, I'm sorry, why are we discounting this whole 'Woody came to life' thing so quickly?", "order": 39795, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 11, "scene": 50, "line": "Ahhhhhh! [laughs] Surprise! It was part of the party. Pretty funny, huh?", "order": 39796, "speaker": "Michael Scott"},
  {"season": 7, "episode": 11, "scene": 50, "line": "You think this is funny?", "order": 39797, "speaker": "Holly Flax"},
  {"season": 7, "episode": 11, "scene": 50, "line": "[laughs] I don't. But someday I think we will laugh about this... when we tell our kids...", "order": 39798, "speaker": "Michael Scott"},
  {"season": 7, "episode": 11, "scene": 50, "line": "Yikes. [everyone gasps]", "order": 39799, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 11, "scene": 50, "line": "Oh no, that's, that's not happening.", "order": 39800, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 11, "scene": 50, "line": "Dear God in heaven.", "order": 39801, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 11, "scene": 50, "line": "All right. Shh. Okay, you know what? Holly, I didn't mean to do it. It was an accident. An accident borne of jealous feelings.", "order": 39802, "speaker": "Michael Scott"},
  {"season": 7, "episode": 11, "scene": 50, "line": "Michael, you have to let this go. I'm with someone else. I don't feel that way anymore.", "order": 39803, "speaker": "Holly Flax"},
  {"season": 7, "episode": 11, "scene": 50, "line": "Oh, really? You certainly seemed jealous when I told you about my fake girlfriend. That's what Jim and Dwight thought.", "order": 39804, "speaker": "Michael Scott"},
  {"season": 7, "episode": 11, "scene": 50, "line": "Okay, hey, hey, hey...", "order": 39805, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 11, "scene": 50, "line": "No, fake girlfriends are always wrong.", "order": 39806, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 11, "scene": 50, "line": "You made up a fake girlfriend to see if I'd get jealous? And you destroyed a gift my boyfriend gave me? What is the matter with you?", "order": 39807, "speaker": "Holly Flax"},
  {"season": 7, "episode": 11, "scene": 50, "line": "When you got transferred, and I drove you up to Nashua, you said, 'Michael, I love you, but I can't do this.' But then, with this other guy, you don't have any problem with a long-distance relationship, do you? And you know what, that is what is the matter with me.", "order": 39808, "speaker": "Michael Scott"},
  {"season": 7, "episode": 11, "scene": 50, "line": "Michael, I'm sorry.", "order": 39809, "speaker": "Holly Flax"},
  {"season": 7, "episode": 11, "scene": 50, "line": "And we did this whole stupid party for you.", "order": 39810, "speaker": "Michael Scott"},
  {"season": 7, "episode": 11, "scene": 50, "line": "[blocks Holly's path] No.", "order": 39811, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 11, "scene": 50, "line": "You guys, it wasn't my fault.", "order": 39812, "speaker": "Holly Flax"},
  {"season": 7, "episode": 11, "scene": 50, "line": "Oh, nothing is ever your fault! Just like when you ate those maple candies that you brought for us!", "order": 39813, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 11, "scene": 51, "line": "I really think you're better off.", "order": 39814, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 11, "scene": 51, "line": "Mmm. Erin, would you do me a favor and find my street clothes for me, please?", "order": 39815, "speaker": "Michael Scott"},
  {"season": 7, "episode": 11, "scene": 51, "line": "Yeah. Is she an amazing cook or something?", "order": 39816, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 11, "scene": 52, "line": "[walks over to his desk and sees a present sitting on it, reading the note] 'Hey, Pickles, Merry Christmas. Open immediately. Love, Swiss Cheese.' [opens the present and a snowball shoots out at him, everyone laughs] Damn it, Dwight!", "order": 39817, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 11, "scene": 52, "line": "Didn't think your affectionate nicknames would be your undoing, did you, Jim? Let that be a lesson to you all.", "order": 39818, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 11, "scene": 53, "line": "So do they bring in food, or do you get to go out?", "order": 39819, "speaker": "Stanley Hudson"},
  {"season": 7, "episode": 11, "scene": 53, "line": "No, they bring it in.", "order": 39820, "speaker": "Toby Flenderson"},
  {"season": 7, "episode": 11, "scene": 53, "line": "You lucky son of a bitch.", "order": 39821, "speaker": "Stanley Hudson"},
  {"season": 7, "episode": 11, "scene": 54, "line": "I have been trying to get on jury duty every single year since I was 18 years old. To get to go sit in an air-conditioned room downtown, judging people while my lunch is paid for... that is the life.", "order": 39822, "speaker": "Stanley Hudson"},
  {"season": 7, "episode": 11, "scene": 55, "line": "Daddy, I'm bored. Can I read my book in your office?", "order": 39823, "speaker": "Jada"},
  {"season": 7, "episode": 11, "scene": 55, "line": "Sure, sweetie.", "order": 39824, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 11, "scene": 56, "line": "Jada, Darryl, I'm so glad I found you guys. A grinch stole the star from on top of the Christmas tree and is hiding it in the warehouse somewhere. You want to go help me find him?", "order": 39825, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 11, "scene": 57, "line": "Oh, my goodness.", "order": 39826, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 11, "scene": 57, "line": "I am the mean old grinch. The little girl who wishes to win back the Christmas star must first succeed at these challenges.", "order": 39827, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 11, "scene": 57, "line": "Ohh, that sounds fun and Christmasy, you mean old grinch!", "order": 39828, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 11, "scene": 57, "line": "What kind of challenges.", "order": 39829, "speaker": "Jada"},
  {"season": 7, "episode": 11, "scene": 57, "line": "Wahhh...", "order": 39830, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 11, "scene": 57, "line": "Something like an obstacle course, Mr. Grinch?", "order": 39831, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 11, "scene": 57, "line": "No. You must answer topical political questions. How many congressmen is the state of Pennsylvania guaranteed? And what other state has the equal number?", "order": 39832, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 11, "scene": 57, "line": "I don't know.", "order": 39833, "speaker": "Jada"},
  {"season": 7, "episode": 11, "scene": 57, "line": "Do you know the other state?", "order": 39834, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 11, "scene": 57, "line": "Maybe the grinch hid the Christmas star, and we could ask for clues, and he'll tell us if we're hot or cold.", "order": 39835, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 11, "scene": 57, "line": "The star has been hidden. Is the little girl hot or cold? Well, it turns out she's burning up because the star is right behind her ear! [pulls star from coat and pretends to find it behind Jada's ear]", "order": 39836, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 11, "scene": 57, "line": "And the game's over seconds later.", "order": 39837, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 11, "scene": 58, "line": "I'm so glad you could come.", "order": 39838, "speaker": "Angela Martin"},
  {"season": 7, "episode": 11, "scene": 58, "line": "It's nice. I know.", "order": 39839, "speaker": "Robert California"},
  {"season": 7, "episode": 11, "scene": 58, "line": "Hey everybody, this is my boyfriend, Senator Robert Lipton.", "order": 39840, "speaker": "Angela Martin"},
  {"season": 7, "episode": 11, "scene": 58, "line": "Hi.", "order": 39841, "speaker": "Robert California"},
  {"season": 7, "episode": 11, "scene": 58, "line": "Senator, it's an honor. I'm Angela's friend Oscar.", "order": 39842, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 11, "scene": 58, "line": "Oscar. A pleasure.", "order": 39843, "speaker": "Robert California"},
  {"season": 7, "episode": 11, "scene": 59, "line": "Robert seems great. He's very handsome, firm handshake, he's gay, good sense of humor.", "order": 39844, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 11, "scene": 60, "line": "Hi.", "order": 39845, "speaker": "A.J."},
  {"season": 7, "episode": 11, "scene": 60, "line": "Hello.", "order": 39846, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 11, "scene": 60, "line": "I'm, uh, I'm AJ. I'm here to see Holly. It's kind of a surprise.", "order": 39847, "speaker": "A.J."},
  {"season": 7, "episode": 11, "scene": 60, "line": "I know who you are, and I think you should go.", "order": 39848, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 11, "scene": 60, "line": "I bet you didn't even bring us anything, did you?", "order": 39849, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 11, "scene": 60, "line": "Was I supposed to bring you guys something?", "order": 39850, "speaker": "A.J."},
  {"season": 7, "episode": 11, "scene": 60, "line": "What? AJ!", "order": 39851, "speaker": "Holly Flax"},
  {"season": 7, "episode": 11, "scene": 60, "line": "Surprise.", "order": 39852, "speaker": "A.J."},
  {"season": 7, "episode": 11, "scene": 60, "line": "What are you doing here? Oh, my God.", "order": 39853, "speaker": "Holly Flax"},
  {"season": 7, "episode": 11, "scene": 60, "line": "I wanted to see you. How are you?", "order": 39854, "speaker": "A.J."},
  {"season": 7, "episode": 11, "scene": 60, "line": "Wow! Nice.", "order": 39855, "speaker": "Holly Flax"},
  {"season": 7, "episode": 11, "scene": 60, "line": "Oh, God, you look great.", "order": 39856, "speaker": "A.J."},
  {"season": 7, "episode": 11, "scene": 60, "line": "Oh... when did you get here?", "order": 39857, "speaker": "Holly Flax"},
  {"season": 7, "episode": 11, "scene": 60, "line": "Just now.", "order": 39858, "speaker": "A.J."},
  {"season": 7, "episode": 11, "scene": 60, "line": "Hey", "order": 39859, "speaker": "Michael Scott"},
  {"season": 7, "episode": 11, "scene": 60, "line": "Just now. Hey, Michael.", "order": 39860, "speaker": "A.J."},
  {"season": 7, "episode": 11, "scene": 60, "line": "Nice to see you again, AJ. Welcome.", "order": 39861, "speaker": "Michael Scott"},
  {"season": 7, "episode": 11, "scene": 60, "line": "Pleased to see you. Thank you.", "order": 39862, "speaker": "A.J."},
  {"season": 7, "episode": 11, "scene": 60, "line": "Good trip down?", "order": 39863, "speaker": "Michael Scott"},
  {"season": 7, "episode": 11, "scene": 60, "line": "Yeah, it was great.", "order": 39864, "speaker": "A.J."},
  {"season": 7, "episode": 11, "scene": 60, "line": "Good, good. Good to see you. Have fun. Enjoy the partay.", "order": 39865, "speaker": "Michael Scott"},
  {"season": 7, "episode": 11, "scene": 61, "line": "I am dead inside.", "order": 39866, "speaker": "Michael Scott"},
  {"season": 7, "episode": 11, "scene": 62, "line": "What do you want, baby? We got some granola, got some cupcakes, chips, apples, pies...", "order": 39867, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 11, "scene": 62, "line": "You have a whole room of vending machines?", "order": 39868, "speaker": "Jada"},
  {"season": 7, "episode": 11, "scene": 62, "line": "[chuckles] I know. Isn't it something?", "order": 39869, "speaker": "Creed Bratton"},
  {"season": 7, "episode": 11, "scene": 62, "line": "I can't decide what I want.", "order": 39870, "speaker": "Jada"},
  {"season": 7, "episode": 11, "scene": 63, "line": "[handing out vending machine items to everyone] Merry Christmas.", "order": 39871, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 11, "scene": 63, "line": "Merry Christmas.", "order": 39872, "speaker": "Jada"},
  {"season": 7, "episode": 11, "scene": 63, "line": "Thank you.", "order": 39873, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 11, "scene": 63, "line": "And Merry Christmas.", "order": 39874, "speaker": "Jada"},
  {"season": 7, "episode": 11, "scene": 63, "line": "Thank you.", "order": 39875, "speaker": "Meredith Palmer"},
  {"season": 7, "episode": 11, "scene": 63, "line": "And Merry Christ... mas.", "order": 39876, "speaker": "Jada"},
  {"season": 7, "episode": 11, "scene": 64, "line": "Thank you so much.", "order": 39877, "speaker": "Robert California"},
  {"season": 7, "episode": 11, "scene": 64, "line": "Merry Christmas. And Merry Christmas.", "order": 39878, "speaker": "Jada"},
  {"season": 7, "episode": 11, "scene": 64, "line": "Merry Christmas to you.", "order": 39879, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 11, "scene": 65, "line": "Merry Christmas.", "order": 39880, "speaker": "Jada"},
  {"season": 7, "episode": 11, "scene": 65, "line": "Oh, thank you.", "order": 39881, "speaker": "Bass Player"},
  {"season": 7, "episode": 11, "scene": 66, "line": "[Oscar unwraps a pair of Uggs] For your feet!", "order": 39882, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 11, "scene": 67, "line": "[holding a knitted iPad case] It's amazing. It's so great. Thank you.", "order": 39883, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 11, "scene": 68, "line": "[Angela hands Creed a pack of deodorant] For me?", "order": 39884, "speaker": "Creed Bratton"},
  {"season": 7, "episode": 11, "scene": 68, "line": "Yes.", "order": 39885, "speaker": "Angela Martin"},
  {"season": 7, "episode": 11, "scene": 68, "line": "Thank you very much.", "order": 39886, "speaker": "Creed Bratton"},
  {"season": 7, "episode": 11, "scene": 68, "line": "Mmhmm.", "order": 39887, "speaker": "Angela Martin"},
  {"season": 7, "episode": 11, "scene": 69, "line": "I don't know if you guys have had a chance to use the new parking meters that accept credit cards, but Robert was instrumental in that legislation.", "order": 39888, "speaker": "Angela Martin"},
  {"season": 7, "episode": 11, "scene": 69, "line": "Wow, that's awesome!", "order": 39889, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 11, "scene": 69, "line": "A real David and Goliath story.", "order": 39890, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 11, "scene": 69, "line": "I'm just so touched that she's so interested in my work.", "order": 39891, "speaker": "Robert California"},
  {"season": 7, "episode": 11, "scene": 69, "line": "I am.", "order": 39892, "speaker": "Angela Martin"},
  {"season": 7, "episode": 11, "scene": 69, "line": "The real problem is the teachers' union.", "order": 39893, "speaker": "Meredith Palmer"},
  {"season": 7, "episode": 11, "scene": 69, "line": "Zip it, Meredith.", "order": 39894, "speaker": "Angela Martin"},
  {"season": 7, "episode": 11, "scene": 69, "line": "No. You tell me why my kid is 17 and still can't read.", "order": 39895, "speaker": "Meredith Palmer"},
  {"season": 7, "episode": 11, "scene": 69, "line": "Hey, who's in charge of making drinks around here? Is there a bartender?", "order": 39896, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 11, "scene": 69, "line": "You are. You make your own drink. [Ryan groans]", "order": 39897, "speaker": "Angela Martin"},
  {"season": 7, "episode": 11, "scene": 69, "line": "I'll make it. What are you drinking.", "order": 39898, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 11, "scene": 69, "line": "An 'F' train to Brooklyn. Extra bitters.", "order": 39899, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 11, "scene": 69, "line": "I don't know how to make that.", "order": 39900, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 11, "scene": 69, "line": "Okay, well, I'll just stick with my mojito.", "order": 39901, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 11, "scene": 69, "line": "And you know those annoying geese at the park? Well, he's helping eradicate them as well. I mean, I think they've really become a pest.", "order": 39902, "speaker": "Angela Martin"},
  {"season": 7, "episode": 11, "scene": 69, "line": "Annoying.", "order": 39903, "speaker": "Meredith Palmer"},
  {"season": 7, "episode": 11, "scene": 69, "line": "Yes.", "order": 39904, "speaker": "Angela Martin"},
  {"season": 7, "episode": 11, "scene": 70, "line": "[Jim hits a ceiling panel] I don't think he's in the ceiling, babe.", "order": 39905, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 11, "scene": 70, "line": "Well, uh... I don't think any of us really know. Alright. [Jim hands Pam a present]", "order": 39906, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 11, "scene": 70, "line": "Is it... is it pebbles from that beach in Jamaica? [she shakes it]", "order": 39907, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 11, "scene": 70, "line": "Ooh, go easy with the shaking.", "order": 39908, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 11, "scene": 70, "line": "[lifting up a diamond bracelet] Oh, my God.", "order": 39909, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 11, "scene": 70, "line": "You like it?", "order": 39910, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 11, "scene": 70, "line": "I love it.", "order": 39911, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 11, "scene": 71, "line": "Yup, I do make great Christmas gifts. But I couldn't make that.", "order": 39912, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 11, "scene": 72, "line": "Alright, my turn.", "order": 39913, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 11, "scene": 72, "line": "Oh, um, it's just, I didn't, I didn't have a lot of time, so that's just a place holder.", "order": 39914, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 11, "scene": 72, "line": "Right. Of course. [reading the comic book] 'The Adventures of Jimmy Halpert.' Oh, my God. This is awesome! That's my bike. That's my desk. And that is my daughter.", "order": 39915, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 11, "scene": 73, "line": "I mean... [speechless]", "order": 39916, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 11, "scene": 74, "line": "Michael, wait!", "order": 39917, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 11, "scene": 74, "line": "Oh, hey, hey, no, don't run. You're gonna slip.", "order": 39918, "speaker": "Michael Scott"},
  {"season": 7, "episode": 11, "scene": 74, "line": "That's good advice.", "order": 39919, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 11, "scene": 74, "line": "Yup. I read this story about a woman who slipped on some black ice, and she hit her head and went into a coma forever. And then every day, her husband went and visited her in the hospital until she died.", "order": 39920, "speaker": "Michael Scott"},
  {"season": 7, "episode": 11, "scene": 74, "line": "That's a sad story.", "order": 39921, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 11, "scene": 74, "line": "[voice breaking] Yeah, well, at least he was married.", "order": 39922, "speaker": "Michael Scott"},
  {"season": 7, "episode": 11, "scene": 74, "line": "Oh, okay, Michael, slow down. Everything's gonna be okay.", "order": 39923, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 11, "scene": 74, "line": "No, it's not. It's not. Oh, man, I can tell you confidently that it is not gonna be okay.", "order": 39924, "speaker": "Michael Scott"},
  {"season": 7, "episode": 11, "scene": 74, "line": "I shouldn't tell you this, but... AJ won't commit to Holly. And she's gonna tell him that if he doesn't propose to her by the end of the year, it's over.", "order": 39925, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 11, "scene": 74, "line": "Really?", "order": 39926, "speaker": "Michael Scott"},
  {"season": 7, "episode": 11, "scene": 74, "line": "Really. And I don't know about you, but I don't know of a lot of happy marriages that start off with an ultimatum, do you?", "order": 39927, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 11, "scene": 74, "line": "No.", "order": 39928, "speaker": "Michael Scott"},
  {"season": 7, "episode": 11, "scene": 74, "line": "So just be patient.", "order": 39929, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 11, "scene": 74, "line": "Yeah. I mean, I can wait till then. Come here. I don't want you to fall.", "order": 39930, "speaker": "Michael Scott"},
  {"season": 7, "episode": 11, "scene": 75, "line": "I surrender.", "order": 39931, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 11, "scene": 75, "line": "I do not accept your surrender. There's only one way that I would ever relent.", "order": 39932, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 11, "scene": 75, "line": "Anything. You got it.", "order": 39933, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 11, "scene": 75, "line": "You hit Pam in the face with a snowball while I watch.", "order": 39934, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 11, "scene": 75, "line": "You're a psychopath.", "order": 39935, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 11, "scene": 75, "line": "I'll take that as a no.", "order": 39936, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 11, "scene": 76, "line": "I was in the mall, and I saw that, and I thought it had your name written all over it.", "order": 39937, "speaker": "Michael Scott"},
  {"season": 7, "episode": 11, "scene": 76, "line": "Michael, this is the gift that corporate gave us that I picked out. You're re-gifting this to me.", "order": 39938, "speaker": "Kelly Kapoor"},
  {"season": 7, "episode": 11, "scene": 76, "line": "No, that's not... no, I went to the mall and I picked that out especially for you.", "order": 39939, "speaker": "Michael Scott"},
  {"season": 7, "episode": 11, "scene": 76, "line": "Oh, yeah? Well, show me the receipt.", "order": 39940, "speaker": "Kelly Kapoor"},
  {"season": 7, "episode": 11, "scene": 76, "line": "[hands Kelly a receipt from his wallet] From the mall. That...", "order": 39941, "speaker": "Michael Scott"},
  {"season": 7, "episode": 11, "scene": 76, "line": "This is a fast food receipt from April.", "order": 39942, "speaker": "Kelly Kapoor"},
  {"season": 7, "episode": 11, "scene": 76, "line": "Well, that...", "order": 39943, "speaker": "Michael Scott"},
  {"season": 7, "episode": 11, "scene": 76, "line": "God, how many number nines did you order?", "order": 39944, "speaker": "Kelly Kapoor"},
  {"season": 7, "episode": 11, "scene": 76, "line": "Is everyone here kind of mean?", "order": 39945, "speaker": "A.J."},
  {"season": 7, "episode": 11, "scene": 76, "line": "Oh, I think everyone's on edge because of the strangler trial.", "order": 39946, "speaker": "Holly Flax"},
  {"season": 7, "episode": 11, "scene": 76, "line": "Oh.", "order": 39947, "speaker": "A.J."},
  {"season": 7, "episode": 11, "scene": 76, "line": "Hey, I'm so glad you came down. It's been so much harder than I expected.", "order": 39948, "speaker": "Holly Flax"},
  {"season": 7, "episode": 11, "scene": 76, "line": "Hey, what happened to Woody?", "order": 39949, "speaker": "A.J."},
  {"season": 7, "episode": 11, "scene": 76, "line": "Oh, okay, get ready for this. You're not gonna believe it. I was making salad, and he fell right into the dressing. I mean, there was salad dressing all over him.", "order": 39950, "speaker": "Holly Flax"},
  {"season": 7, "episode": 11, "scene": 76, "line": "He smells awful.", "order": 39951, "speaker": "A.J."},
  {"season": 7, "episode": 11, "scene": 76, "line": "It was blue cheese dressing.", "order": 39952, "speaker": "Holly Flax"},
  {"season": 7, "episode": 11, "scene": 76, "line": "Great.", "order": 39953, "speaker": "A.J."},
  {"season": 7, "episode": 11, "scene": 77, "line": "Hey, Mike.", "order": 39954, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 11, "scene": 77, "line": "Hey.", "order": 39955, "speaker": "Michael Scott"},
  {"season": 7, "episode": 11, "scene": 77, "line": "We wanted to give you something.", "order": 39956, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 11, "scene": 77, "line": "Oh.", "order": 39957, "speaker": "Michael Scott"},
  {"season": 7, "episode": 11, "scene": 77, "line": "Merry Christmas.", "order": 39958, "speaker": "Jada"},
  {"season": 7, "episode": 11, "scene": 77, "line": "Thank you. A Hostess apple pie! This is my favorite breakfast. How did you know that? Thank you very much.", "order": 39959, "speaker": "Michael Scott"},
  {"season": 7, "episode": 11, "scene": 77, "line": "What do you say?", "order": 39960, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 11, "scene": 77, "line": "You're welcome.", "order": 39961, "speaker": "Jada"},
  {"season": 7, "episode": 11, "scene": 77, "line": "Oh, you know, I seem to remember that Santa promised that he would listen to all the gifts you wanted for Christmas. Didn't he say that?", "order": 39962, "speaker": "Michael Scott"},
  {"season": 7, "episode": 11, "scene": 77, "line": "Yeah.", "order": 39963, "speaker": "Jada"},
  {"season": 7, "episode": 11, "scene": 77, "line": "I think I know where he is.", "order": 39964, "speaker": "Michael Scott"},
  {"season": 7, "episode": 11, "scene": 78, "line": "A trampoline...", "order": 39965, "speaker": "Jada"},
  {"season": 7, "episode": 11, "scene": 78, "line": "Mmhmm.", "order": 39966, "speaker": "Michael Scott"},
  {"season": 7, "episode": 11, "scene": 78, "line": "Video games.", "order": 39967, "speaker": "Jada"},
  {"season": 7, "episode": 11, "scene": 78, "line": "Video games.", "order": 39968, "speaker": "Michael Scott"},
  {"season": 7, "episode": 11, "scene": 78, "line": "A DSi.", "order": 39969, "speaker": "Jada"},
  {"season": 7, "episode": 11, "scene": 78, "line": "A DSi?", "order": 39970, "speaker": "Michael Scott"},
  {"season": 7, "episode": 11, "scene": 78, "line": "A horse.", "order": 39971, "speaker": "Jada"},
  {"season": 7, "episode": 11, "scene": 78, "line": "A horse.", "order": 39972, "speaker": "Michael Scott"},
  {"season": 7, "episode": 11, "scene": 78, "line": "A pool.", "order": 39973, "speaker": "Jada"},
  {"season": 7, "episode": 11, "scene": 78, "line": "You, are you sure you don't want a pony? You want a real horse?", "order": 39974, "speaker": "Michael Scott"},
  {"season": 7, "episode": 11, "scene": 78, "line": "Yes.", "order": 39975, "speaker": "Jada"},
  {"season": 7, "episode": 11, "scene": 78, "line": "All right. You have to pick up after them.", "order": 39976, "speaker": "Michael Scott"},
  {"season": 7, "episode": 11, "scene": 79, "line": "Hey, sorry. I'm ready.", "order": 39977, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 11, "scene": 79, "line": "I don't want to go.", "order": 39978, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 11, "scene": 79, "line": "Oh... come on, bear man. Come on. [the lights flicker]", "order": 39979, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 11, "scene": 79, "line": "Have you ever seen 'em do that? [walk outside and the parking lot is covered with snowmen]", "order": 39980, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 11, "scene": 79, "line": "I'm sorry. I had no idea.", "order": 39981, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 11, "scene": 79, "line": "No, it's, it's okay. Okay.", "order": 39982, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 11, "scene": 79, "line": "Oh, my God. Oh, my God. No, no, no, no.", "order": 39983, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 11, "scene": 79, "line": "Okay. This is it.", "order": 39984, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 11, "scene": 79, "line": "What?", "order": 39985, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 11, "scene": 79, "line": "Go! Go! Go!", "order": 39986, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 11, "scene": 79, "line": "What? What is it? What? What is, oh, my God! [Jim starts attacking snowmen] Honey? Jim? Jim!", "order": 39987, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 11, "scene": 80, "line": "In the end, the greatest snowball isn't a snowball at all. It's fear. Merry Christmas.", "order": 39988, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 12, "scene": 1, "line": "Next up, secretary Schrute will read the minutes from Sunday's meeting.", "order": 39989, "speaker": "Man"},
  {"season": 7, "episode": 12, "scene": 1, "line": "Minutes from previous meeting of Knights of the Night. No, Jim, hey!", "order": 39990, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 12, "scene": 1, "line": "Oh.", "order": 39991, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 12, "scene": 1, "line": "This meeting is for Knights of the Night only.", "order": 39992, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 12, "scene": 2, "line": "Knights of the Night are volunteer crime patrollers. We're often compared to the Guardian Angels, but we could not be more different from them. Seriously, we are nothing like the Guardian Angels. I mean, broad strokes purposes, sure. Think Guardian Angels. One of our many recent good deeds? We set out to capture the Scranton Strangler. Mission accomplished. Not by us.", "order": 39993, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 12, "scene": 3, "line": "I'm actually just looking for my Blackberry, so, carry on.", "order": 39994, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 12, "scene": 3, "line": "No, no, no, no, no. I see how this movie ends. You make fun of me, everyone laughs, ha, ha, ha... screw you!", "order": 39995, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 12, "scene": 3, "line": "Dwight, don't be bothered by your friend's presence.", "order": 39996, "speaker": "Man"},
  {"season": 7, "episode": 12, "scene": 3, "line": "What are you going to do now? Are you going to make fun of our leader's weird voice? Huh? [mimicking the leader's voice] Dwight, don't be bothered by the [mumbles]. Over the line, Jim.", "order": 39997, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 12, "scene": 3, "line": "I'm just looking for my phone, so... pretend I'm not here.", "order": 39998, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 12, "scene": 3, "line": "Fine. Minutes from previous meeting of Knights of the Night. That's our official name, Jim.", "order": 39999, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 12, "scene": 3, "line": "Sounds good.", "order": 40000, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 12, "scene": 3, "line": "Reflective strips were glued to patrol jackets. Knights went outside with flashlights to test them.", "order": 40001, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 12, "scene": 3, "line": "Smart.", "order": 40002, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 12, "scene": 3, "line": "And the rest was just [mumbles]stuff.", "order": 40003, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 12, "scene": 3, "line": "Go ahead and read it, some people weren't here.", "order": 40004, "speaker": "Man"},
  {"season": 7, "episode": 12, "scene": 3, "line": "Okay. Knights stayed outside to play flashlight tag.", "order": 40005, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 12, "scene": 3, "line": "Oh, come on, that sounds awesome.", "order": 40006, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 12, "scene": 3, "line": "It was pretty cool.", "order": 40007, "speaker": "Member"},
  {"season": 7, "episode": 12, "scene": 3, "line": "Was it?", "order": 40008, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 12, "scene": 3, "line": "It was fun.", "order": 40009, "speaker": "Member"},
  {"season": 7, "episode": 12, "scene": 3, "line": "It was pretty awesome.", "order": 40010, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 12, "scene": 3, "line": "[answers phone] Hey! Yeah. No, I got it. Just leaving now.", "order": 40011, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 12, "scene": 4, "line": "January three, 2011. A day which will live in-famously. Holly Partridge Flax, otherwise known as Holly, otherwise otherwise known as a living, breathing angel, is engaged to another man. This is freaking me out. I- okay, let's switch to the happy video, okay? No, no, no, no, let's push through. We'll do this.", "order": 40012, "speaker": "Michael Scott"},
  {"season": 7, "episode": 12, "scene": 5, "line": "Today will either be the best or the worst day of my life. Holly gave AJ an ultimatum. He either proposes by New Year's or they break up. Now, if she's engaged, I'm gonna go crazy, and I'm gonna start attacking people. If she's not engaged, in all honesty, I may just burn this whole place to the ground out of happiness. Either way, I am going to need some talking down. And nobody talks me down like myself in a video talking me down.", "order": 40013, "speaker": "Michael Scott"},
  {"season": 7, "episode": 12, "scene": 6, "line": "[filming video] Michael, I know what you're thinking. Holly's engaged to another man, and you want to kill yourself. It may seem like a good idea, but it's not.", "order": 40014, "speaker": "Michael Scott"},
  {"season": 7, "episode": 12, "scene": 6, "line": "[giggles] Snot. Sorry, it sounded like you said it's snot. I am so sorry.", "order": 40015, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 12, "scene": 6, "line": "Okay, so killing yourself. [laughs] I was just thinking about snot.", "order": 40016, "speaker": "Michael Scott"},
  {"season": 7, "episode": 12, "scene": 7, "line": "Good morning.", "order": 40017, "speaker": "Angela Martin"},
  {"season": 7, "episode": 12, "scene": 7, "line": "Hey, guys! Fun thing. The index cards on your desk are resolution cards. You write your New Year's resolution on them, I'll collect them, and then display them on... da-da-da-da-da! This! Resolution board.", "order": 40018, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 12, "scene": 7, "line": "Wow. Did your baby draw that?", "order": 40019, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 12, "scene": 7, "line": "The glitter is blinding.", "order": 40020, "speaker": "Angela Martin"},
  {"season": 7, "episode": 12, "scene": 7, "line": "I think it's good.", "order": 40021, "speaker": "Phyllis Vance"},
  {"season": 7, "episode": 12, "scene": 7, "line": "It's gonna be fun.", "order": 40022, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 12, "scene": 8, "line": "I recently met Sue, the office administrator at Vance Refrigeration. She has this awesome wall full of fun initiatives and morale boosters and stuff. Sue just goes for it, she's awesome.", "order": 40023, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 12, "scene": 9, "line": "I know Sue. She's not that great. And you know her husband's in a wheelchair, right?", "order": 40024, "speaker": "Phyllis Vance"},
  {"season": 7, "episode": 12, "scene": 10, "line": "Okay, champagne.", "order": 40025, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 12, "scene": 10, "line": "Happy.", "order": 40026, "speaker": "Michael Scott"},
  {"season": 7, "episode": 12, "scene": 10, "line": "Sponge.", "order": 40027, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 12, "scene": 10, "line": "Sad. To soak up my tears.", "order": 40028, "speaker": "Michael Scott"},
  {"season": 7, "episode": 12, "scene": 10, "line": "Gummi bears and gummi worms.", "order": 40029, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 12, "scene": 10, "line": "Bears sad, worms happy. Come on, Erin.", "order": 40030, "speaker": "Michael Scott"},
  {"season": 7, "episode": 12, "scene": 10, "line": "Ukulele's happy?", "order": 40031, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 12, "scene": 10, "line": "Sad, something to break.", "order": 40032, "speaker": "Michael Scott"},
  {"season": 7, "episode": 12, "scene": 10, "line": "Chocolates.", "order": 40033, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 12, "scene": 10, "line": "For you, job well done.", "order": 40034, "speaker": "Michael Scott"},
  {"season": 7, "episode": 12, "scene": 10, "line": "Thank you! And two bottles of scotch.", "order": 40035, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 12, "scene": 10, "line": "Both sad.", "order": 40036, "speaker": "Michael Scott"},
  {"season": 7, "episode": 12, "scene": 10, "line": "And did you get the tickets?", "order": 40037, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 12, "scene": 10, "line": "I did! I did, indeed. Two front row center, to Paula Poundstone, Live in Poughkeepsie. Holly's favorite. I hope she doesn't call us up on stage. That would suck. 'Hey, you think you can do my job better?' I don't know. 'What's your name?' Michael.", "order": 40038, "speaker": "Michael Scott"},
  {"season": 7, "episode": 12, "scene": 11, "line": "I'm really excited for Michael either way. Because if Holly chooses to be with him, he will be so, so happy. And if not, he'll be avoiding the biggest mistake of his life!", "order": 40039, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 12, "scene": 12, "line": "Oh, hey, guys! I'd like to introduce you to a new little friend of mine. His name is New Year's resolution board.", "order": 40040, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 12, "scene": 12, "line": "Shaaa-shut up. Hello.", "order": 40041, "speaker": "Michael Scott"},
  {"season": 7, "episode": 12, "scene": 12, "line": "What happened? Is there a ring?", "order": 40042, "speaker": "Kelly Kapoor"},
  {"season": 7, "episode": 12, "scene": 12, "line": "So... high five me. We're back. To a happy... [grabs Holly's hand] look at those! Mittens. Are those a present?", "order": 40043, "speaker": "Michael Scott"},
  {"season": 7, "episode": 12, "scene": 12, "line": "[whispering] Come on!", "order": 40044, "speaker": "Kelly Kapoor"},
  {"season": 7, "episode": 12, "scene": 12, "line": "They're warm.", "order": 40045, "speaker": "Holly Flax"},
  {"season": 7, "episode": 12, "scene": 12, "line": "They're so fuzzy and warm! Let me feel those. Let me feel that. Put 'er there. Give me a good firm... ooh! That's a good firm handshake. You're hired! Yes, let's get right into the Anderson account, shall we? Yes, yes, right away.", "order": 40046, "speaker": "Michael Scott"},
  {"season": 7, "episode": 12, "scene": 12, "line": "Hey, hey, hey. Want me to crank the thermostat up to 90, smoke her out? She can't keep those mittens on forever.", "order": 40047, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 12, "scene": 12, "line": "Yes, no, why? Stop! Yes.", "order": 40048, "speaker": "Michael Scott"},
  {"season": 7, "episode": 12, "scene": 12, "line": "[Holly takes off mittens revealing no ring on her ring finger] Congratu-ohhh.", "order": 40049, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 12, "scene": 12, "line": "Wow.", "order": 40050, "speaker": "Kelly Kapoor"},
  {"season": 7, "episode": 12, "scene": 12, "line": "Oh my God! That's it! That's it!", "order": 40051, "speaker": "Michael Scott"},
  {"season": 7, "episode": 12, "scene": 12, "line": "Oh, I get it. Everybody knows about the ultimatum.", "order": 40052, "speaker": "Holly Flax"},
  {"season": 7, "episode": 12, "scene": 12, "line": "Yes, I told everyone.", "order": 40053, "speaker": "Kelly Kapoor"},
  {"season": 7, "episode": 12, "scene": 12, "line": "[sticking both ring fingers up] Ha ha, ha ha!", "order": 40054, "speaker": "Holly Flax"},
  {"season": 7, "episode": 12, "scene": 12, "line": "Hey! [sticking up middle finger] Right back atcha, bitch!", "order": 40055, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 12, "scene": 13, "line": "Happy! Happy, happy, happy, happy!", "order": 40056, "speaker": "Michael Scott"},
  {"season": 7, "episode": 12, "scene": 13, "line": "Whoa, whoa, whoa, okay, calm down, breathe.", "order": 40057, "speaker": "Video Michael Scott"},
  {"season": 7, "episode": 12, "scene": 13, "line": "I'm trying!", "order": 40058, "speaker": "Michael Scott"},
  {"season": 7, "episode": 12, "scene": 13, "line": "I said breathe!", "order": 40059, "speaker": "Video Michael Scott"},
  {"season": 7, "episode": 12, "scene": 13, "line": "I'm trying!", "order": 40060, "speaker": "Michael Scott"},
  {"season": 7, "episode": 12, "scene": 13, "line": "Wait a second, are you listening to music?", "order": 40061, "speaker": "Video Michael Scott"},
  {"season": 7, "episode": 12, "scene": 13, "line": "What?", "order": 40062, "speaker": "Michael Scott"},
  {"season": 7, "episode": 12, "scene": 13, "line": "Shut that off!", "order": 40063, "speaker": "Video Michael Scott"},
  {"season": 7, "episode": 12, "scene": 13, "line": "I'm not listening to music!", "order": 40064, "speaker": "Michael Scott"},
  {"season": 7, "episode": 12, "scene": 13, "line": "Fine, just go crazy for a little while! Lookin' good! You're lookin' good. [roping Michael in] Oh, I gotcha! I gotcha! I'm pulling you back! Michael dance off!", "order": 40065, "speaker": "Video Michael Scott"},
  {"season": 7, "episode": 12, "scene": 13, "line": "Go! Oh, that's nice.", "order": 40066, "speaker": "Michael Scott"},
  {"season": 7, "episode": 12, "scene": 13, "line": "Go!", "order": 40067, "speaker": "Video Michael Scott"},
  {"season": 7, "episode": 12, "scene": 13, "line": "Me? Okay.", "order": 40068, "speaker": "Michael Scott"},
  {"season": 7, "episode": 12, "scene": 13, "line": "Go!", "order": 40069, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 12, "scene": 13, "line": "Nice! We're gonna calm down later.", "order": 40070, "speaker": "Video Michael Scott"},
  {"season": 7, "episode": 12, "scene": 13, "line": "I'm good.", "order": 40071, "speaker": "Michael Scott"},
  {"season": 7, "episode": 12, "scene": 13, "line": "Oh, yeah!", "order": 40072, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 12, "scene": 13, "line": "Oh my God! [sprays champagne on Erin]", "order": 40073, "speaker": "Michael Scott"},
  {"season": 7, "episode": 12, "scene": 14, "line": "Hey, Dwight, I'm collecting resolution cards.", "order": 40074, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 12, "scene": 14, "line": "Not doing that.", "order": 40075, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 12, "scene": 14, "line": "Why not?", "order": 40076, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 12, "scene": 14, "line": "I've achieved plenty and there's no better than the best.", "order": 40077, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 12, "scene": 14, "line": "Drink less caffeine. That's a good one, Pam. Here's mine.", "order": 40078, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 12, "scene": 14, "line": "Learn to cook for one!", "order": 40079, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 12, "scene": 14, "line": "Yeah. I love cooking, but I always find myself throwing out half the food that I prepare, so... two years is time to just start preparing the right amount of food for the right number of people, which is one.", "order": 40080, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 12, "scene": 14, "line": "Well, maybe you'll meet someone.", "order": 40081, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 12, "scene": 14, "line": "No, some people don't just meet someone. I'm fine with it. Really. This is not a pity party. It's not a party at all. It's just sad.", "order": 40082, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 12, "scene": 14, "line": "Angela.", "order": 40083, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 12, "scene": 14, "line": "Yes.", "order": 40084, "speaker": "Angela Martin"},
  {"season": 7, "episode": 12, "scene": 14, "line": "Make time for romance!", "order": 40085, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 12, "scene": 14, "line": "Who's the senator?", "order": 40086, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 12, "scene": 14, "line": "My boyfriend.", "order": 40087, "speaker": "Angela Martin"},
  {"season": 7, "episode": 12, "scene": 14, "line": "Oh, you mean the state senator. I'm sorry, I was confused, because you accidentally wrote 'the senator'.", "order": 40088, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 12, "scene": 14, "line": "Ugh.", "order": 40089, "speaker": "Angela Martin"},
  {"season": 7, "episode": 12, "scene": 14, "line": "Wait, it's that easy? That's not a resolution, that's just... something I want to do. Okay, fine. I can do that... [sticks note card on board, makes trumpet sound] My resolution is... meet a loose woman.", "order": 40090, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 12, "scene": 14, "line": "Oh, God.", "order": 40091, "speaker": "Angela Martin"},
  {"season": 7, "episode": 12, "scene": 14, "line": "That's a good one.", "order": 40092, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 12, "scene": 14, "line": "Yeah.", "order": 40093, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 12, "scene": 14, "line": "You know what, that's my new one. I'm taking that one, too.", "order": 40094, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 12, "scene": 14, "line": "Awesome.", "order": 40095, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 12, "scene": 14, "line": "You know what you guys should do? Go to the bookstore at lunch. There's tons of cuties and it's easy to talk to them. 'Hey, what book is that? Cool, let's hang out tonight. Sex already? Whoa...'", "order": 40096, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 12, "scene": 14, "line": "The bookstore?", "order": 40097, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 12, "scene": 14, "line": "It's that easy?", "order": 40098, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 12, "scene": 14, "line": "I'll come with you, show you how it's done.", "order": 40099, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 12, "scene": 14, "line": "Okay, I'll drive.", "order": 40100, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 12, "scene": 15, "line": "My resolution is to read more. And if someone else is driving me to the bookstore, I can eat my PB&J in the car. 2011 is coming up all Darryl.", "order": 40101, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 12, "scene": 16, "line": "Hello! Oh, somebody's got a new phone!", "order": 40102, "speaker": "Michael Scott"},
  {"season": 7, "episode": 12, "scene": 16, "line": "Yeah.", "order": 40103, "speaker": "Holly Flax"},
  {"season": 7, "episode": 12, "scene": 16, "line": "That is neat.", "order": 40104, "speaker": "Michael Scott"},
  {"season": 7, "episode": 12, "scene": 16, "line": "I got it for Christmas. I'm so out of my league here.", "order": 40105, "speaker": "Holly Flax"},
  {"season": 7, "episode": 12, "scene": 16, "line": "[imitating E.T.] E.T. phone Holly. Holly like phone?", "order": 40106, "speaker": "Michael Scott"},
  {"season": 7, "episode": 12, "scene": 16, "line": "[imitating E.T.] Holly misses old phone.", "order": 40107, "speaker": "Holly Flax"},
  {"season": 7, "episode": 12, "scene": 16, "line": "[imitating E.T.] Why?", "order": 40108, "speaker": "Michael Scott"},
  {"season": 7, "episode": 12, "scene": 16, "line": "[imitating E.T.] New phone is confusing. Gets bad reception.", "order": 40109, "speaker": "Holly Flax"},
  {"season": 7, "episode": 12, "scene": 16, "line": "[imitating E.T.] Bummer.", "order": 40110, "speaker": "Michael Scott"},
  {"season": 7, "episode": 12, "scene": 16, "line": "[imitating E.T.] Bummer.", "order": 40111, "speaker": "Holly Flax"},
  {"season": 7, "episode": 12, "scene": 16, "line": "[imitating E.T.] Reese's Pieces.", "order": 40112, "speaker": "Michael Scott"},
  {"season": 7, "episode": 12, "scene": 16, "line": "[imitating E.T.] Reese's Pieces?", "order": 40113, "speaker": "Holly Flax"},
  {"season": 7, "episode": 12, "scene": 16, "line": "Oh, God, please stop!", "order": 40114, "speaker": "Kelly Kapoor"},
  {"season": 7, "episode": 12, "scene": 16, "line": "I still have my old phone. This thing... indestructible. [slams phone on desk, phone breaks]", "order": 40115, "speaker": "Michael Scott"},
  {"season": 7, "episode": 12, "scene": 16, "line": "[reaches for her cell phone] Oh, I should get this. Hey! Oh, no, don't go see that one, I want to see that together. Yeah, go see that action one. Okay. I love you, too. All right.", "order": 40116, "speaker": "Holly Flax"},
  {"season": 7, "episode": 12, "scene": 17, "line": "Holly doesn't seem to be engaged, but she's talking to AJ like she is. I can't figure it out. Do you think she's already dating a different AJ?", "order": 40117, "speaker": "Michael Scott"},
  {"season": 7, "episode": 12, "scene": 17, "line": "I don't think so.", "order": 40118, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 12, "scene": 17, "line": "When you broke it off with Roy, did you still tell him you loved him?", "order": 40119, "speaker": "Michael Scott"},
  {"season": 7, "episode": 12, "scene": 17, "line": "No.", "order": 40120, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 12, "scene": 17, "line": "But you did still love him.", "order": 40121, "speaker": "Michael Scott"},
  {"season": 7, "episode": 12, "scene": 17, "line": "I'm not... I'm not going to have...", "order": 40122, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 12, "scene": 17, "line": "Do you love him now?", "order": 40123, "speaker": "Michael Scott"},
  {"season": 7, "episode": 12, "scene": 17, "line": "No! Just talk to her.", "order": 40124, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 12, "scene": 17, "line": "No. She'd just resent me. Or worse! She would think of me as her friend. [disgusted voice] Oh, hi friend! Blegh.", "order": 40125, "speaker": "Michael Scott"},
  {"season": 7, "episode": 12, "scene": 17, "line": "I'll talk to her, Michael.", "order": 40126, "speaker": "Phyllis Vance"},
  {"season": 7, "episode": 12, "scene": 17, "line": "You think she'd talk to you?", "order": 40127, "speaker": "Michael Scott"},
  {"season": 7, "episode": 12, "scene": 17, "line": "Why wouldn't she?", "order": 40128, "speaker": "Phyllis Vance"},
  {"season": 7, "episode": 12, "scene": 17, "line": "That's true. She's really nice. She'd talk to anybody. Why don't you bring Erin to balance you out?", "order": 40129, "speaker": "Michael Scott"},
  {"season": 7, "episode": 12, "scene": 17, "line": "There's a plan.", "order": 40130, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 12, "scene": 18, "line": "All right! We should divide up by section. I will take romance and travel.", "order": 40131, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 12, "scene": 18, "line": "I'll take the entirety of the second floor.", "order": 40132, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 12, "scene": 18, "line": "I got that cutie behind the counter.", "order": 40133, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 12, "scene": 19, "line": "Well, if you read a lot, you should check out our e-readers. They're really neat.", "order": 40134, "speaker": "Bookstore employee"},
  {"season": 7, "episode": 12, "scene": 19, "line": "Whoa, I work at a paper company, those things terrify me. They could put us out of business, you know? I heard those machines hold like, ten books at once.", "order": 40135, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 12, "scene": 19, "line": "Actually, it's ten thousand.", "order": 40136, "speaker": "Bookstore employee"},
  {"season": 7, "episode": 12, "scene": 19, "line": "Holy [bleep], what? Let me see that. It's so light. Like a croissant.", "order": 40137, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 12, "scene": 20, "line": "Hi.", "order": 40138, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 12, "scene": 20, "line": "Hi.", "order": 40139, "speaker": "Customer"},
  {"season": 7, "episode": 12, "scene": 20, "line": "Welcome.", "order": 40140, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 12, "scene": 20, "line": "Let us know if you need anything.", "order": 40141, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 12, "scene": 20, "line": "Thanks.", "order": 40142, "speaker": "Customer"},
  {"season": 7, "episode": 12, "scene": 20, "line": "Why did we pretend like we work here?", "order": 40143, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 12, "scene": 20, "line": "Is that what we were doing?", "order": 40144, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 12, "scene": 20, "line": "Hey, how'd you do?", "order": 40145, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 12, "scene": 20, "line": "Good!", "order": 40146, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 12, "scene": 20, "line": "Good. Real good.", "order": 40147, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 12, "scene": 20, "line": "Really?", "order": 40148, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 12, "scene": 20, "line": "Yeah, yeah!", "order": 40149, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 12, "scene": 20, "line": "We kinda nailed it.", "order": 40150, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 12, "scene": 20, "line": "Yeah, pretty much. But, you know, this place is kinda tapped out, so. Let's roll.", "order": 40151, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 12, "scene": 20, "line": "Cool.", "order": 40152, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 12, "scene": 20, "line": "Okay. What'd you get?", "order": 40153, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 12, "scene": 20, "line": "A book about oceans.", "order": 40154, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 12, "scene": 20, "line": "Oh, really? What else? Let me see.", "order": 40155, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 12, "scene": 20, "line": "No, that's uh. Porn. Pornography. Old lady... nasty... porn.", "order": 40156, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 12, "scene": 21, "line": "[whispering] Follow my lead. Hey, want some company?", "order": 40157, "speaker": "Phyllis Vance"},
  {"season": 7, "episode": 12, "scene": 21, "line": "Want some company?", "order": 40158, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 12, "scene": 21, "line": "Oh, sure. Have a seat. How were your breaks?", "order": 40159, "speaker": "Holly Flax"},
  {"season": 7, "episode": 12, "scene": 21, "line": "Oh, really good, I went to Portugal.", "order": 40160, "speaker": "Phyllis Vance"},
  {"season": 7, "episode": 12, "scene": 21, "line": "I went to Portugal.", "order": 40161, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 12, "scene": 21, "line": "Oh, wow... you went together?", "order": 40162, "speaker": "Holly Flax"},
  {"season": 7, "episode": 12, "scene": 21, "line": "No.", "order": 40163, "speaker": "Phyllis Vance"},
  {"season": 7, "episode": 12, "scene": 21, "line": "No.", "order": 40164, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 12, "scene": 21, "line": "So, I've just gotta know. What's going on with you and AJ?", "order": 40165, "speaker": "Phyllis Vance"},
  {"season": 7, "episode": 12, "scene": 21, "line": "Nothing really happened. I mean, I know I made a big deal about this whole New Year's deadline, but um, it came and went... and, we're still together.", "order": 40166, "speaker": "Holly Flax"},
  {"season": 7, "episode": 12, "scene": 21, "line": "You didn't break up with AJ at all? I mean, not even for a week to make him buy you a present?", "order": 40167, "speaker": "Phyllis Vance"},
  {"season": 7, "episode": 12, "scene": 21, "line": "It just wasn't fair of me to give him an ultimatum in the first place. The whole thing is totally my fault. But we're going to be just fine. Just fine.", "order": 40168, "speaker": "Holly Flax"},
  {"season": 7, "episode": 12, "scene": 22, "line": "Whoa! Hold on.", "order": 40169, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 12, "scene": 22, "line": "I am not going back until I fulfill my resolution.", "order": 40170, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 12, "scene": 22, "line": "[Dwight heads toward a strip club] Oh, no, no, no. Uh-uh.", "order": 40171, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 12, "scene": 22, "line": "Come on, why not? I've never been in one before. I'm tired of being Mr. Perfect Role Model all the time.", "order": 40172, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 12, "scene": 22, "line": "I'm telling you, don't do it. I've got nothing against strip clubs, but I do have something against them at noon on a Monday. The day shift at a strip club? You can't unsee that.", "order": 40173, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 12, "scene": 22, "line": "Well, we can't just go back, I mean, we came out to meet women.", "order": 40174, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 12, "scene": 22, "line": "Hey, there's a roller rink across the street! There's always chicks at the rink.", "order": 40175, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 12, "scene": 22, "line": "What kind of chicks are you going to meet there?", "order": 40176, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 12, "scene": 22, "line": "I don't know. Single moms at a skating party. Sweet sixteen, ten year reunion parties.", "order": 40177, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 12, "scene": 22, "line": "Chicks fall down, need help getting back up.", "order": 40178, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 12, "scene": 22, "line": "Roller derby practice!", "order": 40179, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 12, "scene": 22, "line": "We're going skating.", "order": 40180, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 12, "scene": 23, "line": "The goal of this meeting is to get everybody excited about their resolutions! Who's been good about their resolutions so far? Erin. Tell us about it.", "order": 40181, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 12, "scene": 23, "line": "Well, my goal was to learn a new word every single day. And I must say that it is going immensely.", "order": 40182, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 12, "scene": 23, "line": "Cool! Here's a little 'way to go' gift from Sabre. [tosses Erin a Frisbee]", "order": 40183, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 12, "scene": 23, "line": "Intercepted!", "order": 40184, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 12, "scene": 23, "line": "Hey! Gabe.", "order": 40185, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 12, "scene": 23, "line": "Whoops.", "order": 40186, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 12, "scene": 23, "line": "Creed. I noticed you don't have a resolution on the board. What's yours?", "order": 40187, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 12, "scene": 23, "line": "I want to do a cartwheel. But real casual-like. Not make a big deal about it. But I know everybody saw it. Just one stunning, gorgeous cartwheel.", "order": 40188, "speaker": "Creed Bratton"},
  {"season": 7, "episode": 12, "scene": 23, "line": "How is it going?", "order": 40189, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 12, "scene": 23, "line": "I'm having a little trouble motivating.", "order": 40190, "speaker": "Creed Bratton"},
  {"season": 7, "episode": 12, "scene": 23, "line": "One of the hardest parts about making resolutions is keeping them. In fact, most resolutions are abandoned by February.", "order": 40191, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 12, "scene": 23, "line": "Or sooner.", "order": 40192, "speaker": "Michael Scott"},
  {"season": 7, "episode": 12, "scene": 23, "line": "Yeah.", "order": 40193, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 12, "scene": 23, "line": "That's not a joke.", "order": 40194, "speaker": "Michael Scott"},
  {"season": 7, "episode": 12, "scene": 23, "line": "But that's okay. Because it's not about being perfect, it's about trying. In fact, why don't we go around and confess some of the ways that we've fudged on some of our resolutions?", "order": 40195, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 12, "scene": 23, "line": "Well, I said that I would eat more vegetables, and I haven't yet. But it's okay. I still have time, since last year, I ate none.", "order": 40196, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 12, "scene": 23, "line": "Okay, well, my confession is that today I had a sip of coffee. But that's fine.", "order": 40197, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 12, "scene": 23, "line": "Is it?", "order": 40198, "speaker": "Michael Scott"},
  {"season": 7, "episode": 12, "scene": 23, "line": "Yes! Because with all of your support, by this time next year maybe I'll be down to one glass of iced tea a week.", "order": 40199, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 12, "scene": 23, "line": "Next year? Come-I mean, what is the point? What is the point of that? I made a resolution to floss, and I did it. 12:01, January first, BAM! Blood everywhere.", "order": 40200, "speaker": "Michael Scott"},
  {"season": 7, "episode": 12, "scene": 23, "line": "Well not all of us are Michael Freaking Scott.", "order": 40201, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 12, "scene": 23, "line": "What is wrong with you people? Can't you stick to anything? Erin, I want you to go to the kitchen and get me some vegetables. Creed, you say you want to do a cartwheel? Nothing's stopping you. Come on, old man, do a cartwheel!", "order": 40202, "speaker": "Michael Scott"},
  {"season": 7, "episode": 12, "scene": 23, "line": "Well, I can't, I don't know how.", "order": 40203, "speaker": "Creed Bratton"},
  {"season": 7, "episode": 12, "scene": 23, "line": "You're just gonna somersault around for the rest of your life and you know what's going to be on your tombstone? Loser.", "order": 40204, "speaker": "Michael Scott"},
  {"season": 7, "episode": 12, "scene": 23, "line": "My tombstone's already made, thank you.", "order": 40205, "speaker": "Creed Bratton"},
  {"season": 7, "episode": 12, "scene": 23, "line": "Just do a cartwheel!", "order": 40206, "speaker": "Michael Scott"},
  {"season": 7, "episode": 12, "scene": 23, "line": "This is all I could find.", "order": 40207, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 12, "scene": 23, "line": "[holding broccoli in front of Kevin] Eat it.", "order": 40208, "speaker": "Michael Scott"},
  {"season": 7, "episode": 12, "scene": 23, "line": "You don't have to do that, Kevin.", "order": 40209, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 12, "scene": 23, "line": "I don't know. I'm glad this is happening. Thank you, Michael.", "order": 40210, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 12, "scene": 23, "line": "Why are you eating stem first?", "order": 40211, "speaker": "Kelly Kapoor"},
  {"season": 7, "episode": 12, "scene": 23, "line": "This is a new food for me, how else should I eat it?", "order": 40212, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 12, "scene": 23, "line": "The other way.", "order": 40213, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 12, "scene": 23, "line": "Can I get some cheese whiz? Or hollandaise?", "order": 40214, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 12, "scene": 23, "line": "No. No cheese whiz, no hollandaise, no chocolate sauce, just eat it. Come on. Here comes the airplane, there you go. Open, open, into the hanger, there you go.", "order": 40215, "speaker": "Michael Scott"},
  {"season": 7, "episode": 12, "scene": 23, "line": "Oh, Michael.", "order": 40216, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 12, "scene": 23, "line": "Eat it. Put it in your mouth, and just eat it.", "order": 40217, "speaker": "Michael Scott"},
  {"season": 7, "episode": 12, "scene": 23, "line": "God, I hate it.", "order": 40218, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 12, "scene": 23, "line": "I don't care whether you hate it! You said you'd do it!", "order": 40219, "speaker": "Michael Scott"},
  {"season": 7, "episode": 12, "scene": 23, "line": "All right!", "order": 40220, "speaker": "Angela Martin"},
  {"season": 7, "episode": 12, "scene": 23, "line": "Eat it!", "order": 40221, "speaker": "Michael Scott"},
  {"season": 7, "episode": 12, "scene": 23, "line": "You're killing him, Michael!", "order": 40222, "speaker": "Kelly Kapoor"},
  {"season": 7, "episode": 12, "scene": 23, "line": "All right, all right, shh!", "order": 40223, "speaker": "Michael Scott"},
  {"season": 7, "episode": 12, "scene": 23, "line": "All right, I ate the fluffy part, can I be done?", "order": 40224, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 12, "scene": 23, "line": "Let me see if you swallowed it, open your mouth. Under your tongue. [reveals hidden broccoli, spits it out] Oh, God! You guys are pathetic.", "order": 40225, "speaker": "Michael Scott"},
  {"season": 7, "episode": 12, "scene": 23, "line": "Can I get some candy, or something?", "order": 40226, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 12, "scene": 23, "line": "No! You can't have any candy!", "order": 40227, "speaker": "Michael Scott"},
  {"season": 7, "episode": 12, "scene": 23, "line": "I'll get him water.", "order": 40228, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 12, "scene": 23, "line": "Okay, Michael, just settle down.", "order": 40229, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 12, "scene": 24, "line": "[imitating E.T.] Holly Flax.", "order": 40230, "speaker": "Michael Scott"},
  {"season": 7, "episode": 12, "scene": 24, "line": "Yes, Michael?", "order": 40231, "speaker": "Holly Flax"},
  {"season": 7, "episode": 12, "scene": 24, "line": "[imitating E.T.] Not Michael, E.T.", "order": 40232, "speaker": "Michael Scott"},
  {"season": 7, "episode": 12, "scene": 24, "line": "What's up?", "order": 40233, "speaker": "Holly Flax"},
  {"season": 7, "episode": 12, "scene": 24, "line": "Well, I just want to talk about what happened in there.", "order": 40234, "speaker": "Michael Scott"},
  {"season": 7, "episode": 12, "scene": 24, "line": "Oh, no, Michael, I don't want to talk about it with you. I mean, I'm fine, I just... I don't want to talk about it.", "order": 40235, "speaker": "Holly Flax"},
  {"season": 7, "episode": 12, "scene": 25, "line": "Holly, come on in. Thanks for coming. I thought there should be an HR rep here for this. So, Kevin and Creed, things got a little bit intense in the conference room.", "order": 40236, "speaker": "Michael Scott"},
  {"season": 7, "episode": 12, "scene": 25, "line": "You think?", "order": 40237, "speaker": "Creed Bratton"},
  {"season": 7, "episode": 12, "scene": 25, "line": "And I wanted to apologize. These resolutions should be about you, and I made it about me, and it was none of my beeswax.", "order": 40238, "speaker": "Michael Scott"},
  {"season": 7, "episode": 12, "scene": 25, "line": "You were super mean.", "order": 40239, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 12, "scene": 25, "line": "It was insensitive and I am sorry.", "order": 40240, "speaker": "Michael Scott"},
  {"season": 7, "episode": 12, "scene": 25, "line": "It was traumatizing, Michael. I wouldn't be surprised if I never ate a vegetable again.", "order": 40241, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 12, "scene": 25, "line": "I really wanted you to follow through on your resolutions. The cartwheel, the veggies... I... care about you. Very much. And you loom so large in my heart that I crossed the line.", "order": 40242, "speaker": "Michael Scott"},
  {"season": 7, "episode": 12, "scene": 25, "line": "Okay, Michael, no offense, but you need to get your own life.", "order": 40243, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 12, "scene": 25, "line": "You're right. And I hope that we can still be friends.", "order": 40244, "speaker": "Michael Scott"},
  {"season": 7, "episode": 12, "scene": 25, "line": "I don't think I'm there yet, Boss.", "order": 40245, "speaker": "Creed Bratton"},
  {"season": 7, "episode": 12, "scene": 25, "line": "Well, I am. [hugs Michael]", "order": 40246, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 12, "scene": 25, "line": "Okay, oh! All right.", "order": 40247, "speaker": "Michael Scott"},
  {"season": 7, "episode": 12, "scene": 25, "line": "I'm going to help you.", "order": 40248, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 12, "scene": 25, "line": "Okay.", "order": 40249, "speaker": "Michael Scott"},
  {"season": 7, "episode": 12, "scene": 26, "line": "[at the skating rink]Hey, uh. It's just you. Anything you want to hear?", "order": 40250, "speaker": "DJ"},
  {"season": 7, "episode": 12, "scene": 26, "line": "Um... Dave Matthews Band. No hits! Deep tracks only.", "order": 40251, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 12, "scene": 26, "line": "Okay. [Ants Go Marching starts playing]", "order": 40252, "speaker": "DJ"},
  {"season": 7, "episode": 12, "scene": 26, "line": "I said no hits!", "order": 40253, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 12, "scene": 27, "line": "I just feel blocked. Like I'm living, but I'm not...", "order": 40254, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 12, "scene": 27, "line": "Hey, Ryan.", "order": 40255, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 12, "scene": 27, "line": "Yeah.", "order": 40256, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 12, "scene": 27, "line": "How's your fart project coming?", "order": 40257, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 12, "scene": 27, "line": "That's real, real classy, Kevin. Hey, was it me or you that just shoved the butt end of a pound of broccoli into my mouth because Michael told me to?", "order": 40258, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 12, "scene": 27, "line": "Me.", "order": 40259, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 12, "scene": 27, "line": "Kevin, don't! Come on!", "order": 40260, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 12, "scene": 27, "line": "Oh, Pam, soda. Caffeine!", "order": 40261, "speaker": "Kelly Kapoor"},
  {"season": 7, "episode": 12, "scene": 27, "line": "Yeah, just a little, I have a lot to deal with today.", "order": 40262, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 12, "scene": 27, "line": "[holding a pack of cigarettes] Well, if you don't have to do 'em...", "order": 40263, "speaker": "Meredith Palmer"},
  {"season": 7, "episode": 12, "scene": 27, "line": "Meredith, what are you doing? I could be pregnant!", "order": 40264, "speaker": "Kelly Kapoor"},
  {"season": 7, "episode": 12, "scene": 27, "line": "Okay, no.", "order": 40265, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 12, "scene": 28, "line": "My resolution was to get more attention.", "order": 40266, "speaker": "Kelly Kapoor"},
  {"season": 7, "episode": 12, "scene": 29, "line": "No, she's lying.", "order": 40267, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 12, "scene": 29, "line": "Hey, Ryan. [taps board, makes farting noise]", "order": 40268, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 12, "scene": 29, "line": "One, two, three. [does cartwheel] I did it! I did a cartwheel!", "order": 40269, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 12, "scene": 29, "line": "[bleep] you! [bleep] you! God!", "order": 40270, "speaker": "Creed Bratton"},
  {"season": 7, "episode": 12, "scene": 29, "line": "Okay, that's it.", "order": 40271, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 12, "scene": 30, "line": "[throws resolution board away in dumpster] Lesson learned.", "order": 40272, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 12, "scene": 31, "line": "Sup?", "order": 40273, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 12, "scene": 31, "line": "Hey! Where did you go?", "order": 40274, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 12, "scene": 31, "line": "To the arcade.", "order": 40275, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 12, "scene": 31, "line": "Oh, cool.", "order": 40276, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 12, "scene": 31, "line": "Why, did you meet someone?", "order": 40277, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 12, "scene": 31, "line": "Yeah, I did actually. His name is Andy, and he roller skates like a Greek god and you know what? I kinda like hanging out with him.", "order": 40278, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 12, "scene": 31, "line": "Right on, son.", "order": 40279, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 12, "scene": 31, "line": "Gentlemen.", "order": 40280, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 12, "scene": 31, "line": "And where did you go?", "order": 40281, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 12, "scene": 31, "line": "Strip club. I was looking at the strippers at the strip club.", "order": 40282, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 12, "scene": 31, "line": "Right.", "order": 40283, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 12, "scene": 31, "line": "So, that's it, guys? If you want, I can put on the strobe.", "order": 40284, "speaker": "DJ"},
  {"season": 7, "episode": 12, "scene": 31, "line": "Yeah!", "order": 40285, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 12, "scene": 32, "line": "You want to keep this?", "order": 40286, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 12, "scene": 32, "line": "No.", "order": 40287, "speaker": "Michael Scott"},
  {"season": 7, "episode": 12, "scene": 32, "line": "Do you want to keep this?", "order": 40288, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 12, "scene": 32, "line": "Yup.", "order": 40289, "speaker": "Michael Scott"},
  {"season": 7, "episode": 12, "scene": 32, "line": "Do you want to keep this?", "order": 40290, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 12, "scene": 32, "line": "You can toss them.", "order": 40291, "speaker": "Michael Scott"},
  {"season": 7, "episode": 12, "scene": 33, "line": "My resolution? I never want to make Holly cry again. Unless it's from laughing too hard. Or making love too beautifully.", "order": 40292, "speaker": "Michael Scott"},
  {"season": 7, "episode": 12, "scene": 34, "line": "We're gonna be just fine. You know how you say something over and over and the words start to sound weird? Going to be just fine... just fine... just fine... just fine...", "order": 40293, "speaker": "Holly Flax"},
  {"season": 7, "episode": 12, "scene": 35, "line": "I just think we need to cool things down for awhile. Yeah, a break. At least until I get back to Nashua. Yeah, I'm sure.", "order": 40294, "speaker": "Holly Flax"},
  {"season": 7, "episode": 12, "scene": 36, "line": "Look at where you're going to be doing the cartwheel. So look at where you're going to be placing your hands. So pick a spot.", "order": 40295, "speaker": "Michael Scott"},
  {"season": 7, "episode": 12, "scene": 36, "line": "Mmmhm.", "order": 40296, "speaker": "Creed Bratton"},
  {"season": 7, "episode": 12, "scene": 36, "line": "You ready to do this?", "order": 40297, "speaker": "Michael Scott"},
  {"season": 7, "episode": 12, "scene": 36, "line": "Yes, sir.", "order": 40298, "speaker": "Creed Bratton"},
  {"season": 7, "episode": 12, "scene": 36, "line": "You know what? I'm going to stay here as long as it takes.", "order": 40299, "speaker": "Michael Scott"},
  {"season": 7, "episode": 12, "scene": 36, "line": "I really appreciate it, Boss.", "order": 40300, "speaker": "Creed Bratton"},
  {"season": 7, "episode": 12, "scene": 36, "line": "It's about you. It's about you. Go!", "order": 40301, "speaker": "Michael Scott"},
  {"season": 7, "episode": 12, "scene": 36, "line": "I did it!", "order": 40302, "speaker": "Creed Bratton"},
  {"season": 7, "episode": 12, "scene": 36, "line": "You did?", "order": 40303, "speaker": "Michael Scott"},
  {"season": 7, "episode": 12, "scene": 36, "line": "The perfect cartwheel.", "order": 40304, "speaker": "Creed Bratton"},
  {"season": 7, "episode": 12, "scene": 36, "line": "Okay.", "order": 40305, "speaker": "Michael Scott"},
  {"season": 7, "episode": 12, "scene": 36, "line": "What a rush! That's all I had to do all year.", "order": 40306, "speaker": "Creed Bratton"},
  {"season": 7, "episode": 12, "scene": 36, "line": "Congratulations. All right. Well, all right. See you tomorrow.", "order": 40307, "speaker": "Michael Scott"},
  {"season": 7, "episode": 13, "scene": 1, "line": "Oh, sorry, mate.", "order": 40308, "speaker": "David Brent"},
  {"season": 7, "episode": 13, "scene": 1, "line": "[English accent] Oh, sorry, mate. Excuse me. Mate.", "order": 40309, "speaker": "Michael Scott"},
  {"season": 7, "episode": 13, "scene": 1, "line": "[laughing] What you doing?", "order": 40310, "speaker": "David Wallace"},
  {"season": 7, "episode": 13, "scene": 1, "line": "English?", "order": 40311, "speaker": "Michael Scott"},
  {"season": 7, "episode": 13, "scene": 1, "line": "You picked on the wrong person, I can tell you that.", "order": 40312, "speaker": "David Wallace"},
  {"season": 7, "episode": 13, "scene": 1, "line": "Oh no no, I'm not picking on you at all. You're English, correct?", "order": 40313, "speaker": "Michael Scott"},
  {"season": 7, "episode": 13, "scene": 1, "line": "Yeah big time, yeah.", "order": 40314, "speaker": "David Wallace"},
  {"season": 7, "episode": 13, "scene": 1, "line": "I'm working on an English character. Would you mind gi... It's called Reginald Pooftah.", "order": 40315, "speaker": "Michael Scott"},
  {"season": 7, "episode": 13, "scene": 1, "line": "Ooh! David Brent, my liege. How are you?", "order": 40316, "speaker": "David Wallace"},
  {"season": 7, "episode": 13, "scene": 1, "line": "Michael Scott.", "order": 40317, "speaker": "Michael Scott"},
  {"season": 7, "episode": 13, "scene": 1, "line": "Oh, there you go. I do characters as well. I got a Chinese fella. He's called Ho Li [bleep]. That's what it sounds like.", "order": 40318, "speaker": "David Wallace"},
  {"season": 7, "episode": 13, "scene": 1, "line": "[laughing]", "order": 40319, "speaker": "Michael Scott"},
  {"season": 7, "episode": 13, "scene": 1, "line": "Herrow! Herrow!", "order": 40320, "speaker": "David Wallace"},
  {"season": 7, "episode": 13, "scene": 1, "line": "I do Ping. Herrow. I Ping!", "order": 40321, "speaker": "Michael Scott"},
  {"season": 7, "episode": 13, "scene": 1, "line": "You can't do that these days. You can't.", "order": 40322, "speaker": "David Wallace"},
  {"season": 7, "episode": 13, "scene": 1, "line": "No, no, no. And people don't understand that is has nothing to do with making fun of a different nationality.", "order": 40323, "speaker": "Michael Scott"},
  {"season": 7, "episode": 13, "scene": 1, "line": "No, no. No, comedy is a place where the mind goes to tickle itself. That's what she said. [laughs]. [hugs Michaels] Ohh.", "order": 40324, "speaker": "David Wallace"},
  {"season": 7, "episode": 13, "scene": 1, "line": "That's good. Pleasure to meet you.", "order": 40325, "speaker": "Michael Scott"},
  {"season": 7, "episode": 13, "scene": 1, "line": "Where are you working?", "order": 40326, "speaker": "David Wallace"},
  {"season": 7, "episode": 13, "scene": 1, "line": "Dunder Mifflin.", "order": 40327, "speaker": "Michael Scott"},
  {"season": 7, "episode": 13, "scene": 1, "line": "Any jobs now?", "order": 40328, "speaker": "David Wallace"},
  {"season": 7, "episode": 13, "scene": 1, "line": "No, not right now.", "order": 40329, "speaker": "Michael Scott"},
  {"season": 7, "episode": 13, "scene": 1, "line": "Just let me know.", "order": 40330, "speaker": "David Wallace"},
  {"season": 7, "episode": 13, "scene": 1, "line": "All right. See you around.", "order": 40331, "speaker": "Michael Scott"},
  {"season": 7, "episode": 13, "scene": 1, "line": "All right.", "order": 40332, "speaker": "David Wallace"},
  {"season": 7, "episode": 13, "scene": 1, "line": "Bye-bye. What a nice guy.", "order": 40333, "speaker": "Michael Scott"},
  {"season": 7, "episode": 13, "scene": 2, "line": "Your first student is here, Mister Bernard.", "order": 40334, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 13, "scene": 2, "line": "That's actually 'Master of Ceremonies' Bernard.", "order": 40335, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 13, "scene": 3, "line": "Last year, I went to a seminar called 'The Ten Secrets of Real Estate'. Turns out it was just a ploy to sell me time-shares in Idaho. Cut to, you know, spending a weekend in Boise, terrible time, super lonely. But I get to thinking maybe I should put on my own seminar to lure clients.", "order": 40336, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 13, "scene": 4, "line": "Oh it's... [points to conference room]", "order": 40337, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 13, "scene": 4, "line": "[enters] [in a gruff voice] Hello, I am here for the small business seminar.", "order": 40338, "speaker": "Michael Scott"},
  {"season": 7, "episode": 13, "scene": 4, "line": "Michael?", "order": 40339, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 13, "scene": 4, "line": "Nein! I'm greek! My name is Mikanos.", "order": 40340, "speaker": "Michael Scott"},
  {"season": 7, "episode": 13, "scene": 4, "line": "Ooh. Wait, Michael?", "order": 40341, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 13, "scene": 4, "line": "Okay.", "order": 40342, "speaker": "Michael Scott"},
  {"season": 7, "episode": 13, "scene": 5, "line": "I am the plant. Every great seminar has one. My job is to make the speaker look good, and I do this through the techniques of leading questions and laughing at all jokes. And the character 'Mikanos', is just a little added flava. 'Mikanos' is loosely based on another character I do, 'Spiros', who is more about the ladies.", "order": 40343, "speaker": "Michael Scott"},
  {"season": 7, "episode": 13, "scene": 6, "line": "Thank you so much Tuna Turner. You are simply the best.", "order": 40344, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 13, "scene": 6, "line": "Listen, this isn't a favor, all right? This is a good idea. We all win.", "order": 40345, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 13, "scene": 6, "line": "Go higher. I get super flexible when I'm nervous.", "order": 40346, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 13, "scene": 6, "line": "Wow. [sees guy at Erin's desk] Stop, stop, stop, stop, stop, stop. Hey, Andy, I'm really, really sorry to do this to you, but I have a meeting today that I totally forgot, so I can't be apart of this seminar.", "order": 40347, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 13, "scene": 6, "line": "No. What? You can't do that. You are a critical part of this seminar. You're the charming warm-up guy,", "order": 40348, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 13, "scene": 6, "line": "I know.", "order": 40349, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 13, "scene": 6, "line": "If the seminar was a meal, you'd be the amuse-bouche. You like, signal the flavors of the whole night.", "order": 40350, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 13, "scene": 6, "line": "I'm really sorry. I can't do it.", "order": 40351, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 13, "scene": 6, "line": "You can't. You can't.", "order": 40352, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 13, "scene": 7, "line": "I can't have this go badly. I'm a terrible salesman, and I haven't been making very many sales lately, or ever. This is my only idea on how to turn things around. If it goes badly, I might lose my job, which would suck because this is the only job I've ever been good at.", "order": 40353, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 13, "scene": 8, "line": "Will you let me know when this whole seminar thing's over?", "order": 40354, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 13, "scene": 8, "line": "What's going on? Do you know that guy?", "order": 40355, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 13, "scene": 8, "line": "I just have a meeting, uh, outside the office.", "order": 40356, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 13, "scene": 8, "line": "Hey.", "order": 40357, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 13, "scene": 8, "line": "Hey.", "order": 40358, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 13, "scene": 8, "line": "[to Erin] How are the marks?", "order": 40359, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 13, "scene": 9, "line": "What are some of your small business ideas?", "order": 40360, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 13, "scene": 9, "line": "I was thinking we could buy up of abandoned mine shafts.", "order": 40361, "speaker": "Older guy"},
  {"season": 7, "episode": 13, "scene": 9, "line": "Oooh!", "order": 40362, "speaker": "Phyllis Vance, Stanley Hudson, Dwight Schrute"},
  {"season": 7, "episode": 13, "scene": 9, "line": "That's great. There's a big, big future in that.", "order": 40363, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 13, "scene": 9, "line": "A lot of mines in Scranton.", "order": 40364, "speaker": "Phyllis Vance"},
  {"season": 7, "episode": 13, "scene": 9, "line": "Um, what about you?", "order": 40365, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 13, "scene": 9, "line": "Uh, credit cards.", "order": 40366, "speaker": "Younger Guy"},
  {"season": 7, "episode": 13, "scene": 9, "line": "Uh-huh.", "order": 40367, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 13, "scene": 9, "line": "My company would act as an intermedium, for like, point two cents off every transaction.", "order": 40368, "speaker": "Younger Guy"},
  {"season": 7, "episode": 13, "scene": 9, "line": "Wow!", "order": 40369, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 13, "scene": 9, "line": "Something with cell phones. It's like, every time you make a phone call I'd make point two cents. Or anything like any on-line shopping I would get point two cents.", "order": 40370, "speaker": "Younger Guy"},
  {"season": 7, "episode": 13, "scene": 9, "line": "Right. Okay? Great.", "order": 40371, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 13, "scene": 9, "line": "Great.", "order": 40372, "speaker": "Stanley Hudson"},
  {"season": 7, "episode": 13, "scene": 10, "line": "You promised us whales. These are worms.", "order": 40373, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 13, "scene": 10, "line": "They're not worms, Dwight, okay? They're just people with tiny businesses. They're baby whales which is even cuter.", "order": 40374, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 13, "scene": 10, "line": "I'm out.", "order": 40375, "speaker": "Stanley Hudson"},
  {"season": 7, "episode": 13, "scene": 10, "line": "Stanley, you're suppose to close.", "order": 40376, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 13, "scene": 10, "line": "I'm out too.", "order": 40377, "speaker": "Phyllis Vance"},
  {"season": 7, "episode": 13, "scene": 10, "line": "What? I already lost Jim. Salesmen are suppose to help each other out. We're suppose to be a team.", "order": 40378, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 13, "scene": 10, "line": "We're no more a team than the people staying in the same hotel are a team.", "order": 40379, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 13, "scene": 11, "line": "You know what might be kind of fun? I was thinking , you know Andy is having a seminar today? What if we went in as a greek couple? Mikanos and...", "order": 40380, "speaker": "Michael Scott"},
  {"season": 7, "episode": 13, "scene": 11, "line": "Oh, I don't know, Michael. I'm not feeling up to that.", "order": 40381, "speaker": "Holly Flax"},
  {"season": 7, "episode": 13, "scene": 11, "line": "Okay.", "order": 40382, "speaker": "Michael Scott"},
  {"season": 7, "episode": 13, "scene": 12, "line": "Holly broke up with A.J last week, which is the greatest thing that has ever happened... to me. To Holly, it's been the worst week of her life, and I know for a fact that there was a week for her in high school when she got mono and her first period ever. Too much information? That's what I thought. But, you know what? Here I am using it.", "order": 40383, "speaker": "Michael Scott"},
  {"season": 7, "episode": 13, "scene": 13, "line": "[as Mikanos] If-a you change-a you mind, why don't you talk to Mikanos?", "order": 40384, "speaker": "Michael Scott"},
  {"season": 7, "episode": 13, "scene": 13, "line": "Is Mikanos greek? He sounds Italian.", "order": 40385, "speaker": "Holly Flax"},
  {"season": 7, "episode": 13, "scene": 13, "line": "Ugh. You're the fifth person to tell me that today.", "order": 40386, "speaker": "Michael Scott"},
  {"season": 7, "episode": 13, "scene": 13, "line": "Maybe Mikanos ran away to join the Italian circus.", "order": 40387, "speaker": "Holly Flax"},
  {"season": 7, "episode": 13, "scene": 13, "line": "Okay, yeah, like a character history. Good. Tom Hanks does that.", "order": 40388, "speaker": "Michael Scott"},
  {"season": 7, "episode": 13, "scene": 14, "line": "[muttering] Down... there. [to Pam] Is 'jlp' a word?", "order": 40389, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 13, "scene": 14, "line": "Jelp?' J-e-l-p?", "order": 40390, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 13, "scene": 14, "line": "No, j-l-p. Like, 'I jlp... I jlp you!'", "order": 40391, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 13, "scene": 14, "line": "I don't think that's a word.", "order": 40392, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 13, "scene": 14, "line": "I'm playing Scrabble with Gabe, and I've never won a game.", "order": 40393, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 13, "scene": 15, "line": "The winner gets to pick the movie we watch. I have won no games. So far I've seen 'The Shining', 'Rosemary's Baby', 'The Ring'. Not really my thing. Although, I... I do like the early parts of the movies where they have a perfect family and everything.", "order": 40394, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 13, "scene": 16, "line": "You want some help?", "order": 40395, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 13, "scene": 16, "line": "Really?", "order": 40396, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 13, "scene": 16, "line": "Mm-hmm.", "order": 40397, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 13, "scene": 16, "line": "Yes.", "order": 40398, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 13, "scene": 16, "line": "K-a. 'Ka'? What does 'ka' mean?", "order": 40399, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 13, "scene": 16, "line": "It means you're playing someone who's going to destroy you. Why did you play 'moo'?", "order": 40400, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 13, "scene": 16, "line": "Because I'm playing to win. I'm playing 'moo', I'm playing 'milk'. Whatever it takes.", "order": 40401, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 13, "scene": 16, "line": "Okay, but look, you could have hit 'mood'. Would have played a...", "order": 40402, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 13, "scene": 16, "line": "Uh...", "order": 40403, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 13, "scene": 16, "line": "A triple word.", "order": 40404, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 13, "scene": 16, "line": "Like the cow mood yesterday. God.", "order": 40405, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 13, "scene": 16, "line": "Or moon.", "order": 40406, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 13, "scene": 16, "line": "The cow jumped over the moon.", "order": 40407, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 13, "scene": 16, "line": "She's stuck on that one thing.", "order": 40408, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 13, "scene": 16, "line": "No, it doesn't have to just be cow stuff, right?", "order": 40409, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 13, "scene": 17, "line": "Kevin, Jim dropped out of my seminar, and I'm just... I was wondering if you could replace him as my charming warm up guy.", "order": 40410, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 13, "scene": 17, "line": "Andy, I'm no Jim. The only way that I'm Jim is in the movie version when Jim sees what his future would be like if he never met Pam.", "order": 40411, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 13, "scene": 17, "line": "Hey, that's crazy talk. I think you're great.", "order": 40412, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 13, "scene": 17, "line": "Then I won't let you down.", "order": 40413, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 13, "scene": 18, "line": "Cake.", "order": 40414, "speaker": "Kelly Kapoor"},
  {"season": 7, "episode": 13, "scene": 18, "line": "In you go.", "order": 40415, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 13, "scene": 18, "line": "Hi, Ryan, you went to business school, right?", "order": 40416, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 13, "scene": 18, "line": "Yup.", "order": 40417, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 13, "scene": 18, "line": "I need somebody to talk to this seminar about business.", "order": 40418, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 13, "scene": 18, "line": "And?", "order": 40419, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 13, "scene": 18, "line": "Can you do it?", "order": 40420, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 13, "scene": 18, "line": "Okay. I don't... I don't like committing to things just like that.", "order": 40421, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 13, "scene": 18, "line": "So no?", "order": 40422, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 13, "scene": 18, "line": "No, I don't like committing to not doing things, either. That's just as big a commitment.", "order": 40423, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 13, "scene": 18, "line": "Oh, baby.", "order": 40424, "speaker": "Kelly Kapoor"},
  {"season": 7, "episode": 13, "scene": 18, "line": "What do I put you down for, bro hombre?", "order": 40425, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 13, "scene": 18, "line": "Yes.", "order": 40426, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 13, "scene": 18, "line": "All right!", "order": 40427, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 13, "scene": 18, "line": "Yes, I'll do it.", "order": 40428, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 13, "scene": 18, "line": "Okay, than you so much. It's going to be so awesome.", "order": 40429, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 13, "scene": 18, "line": "And if I flake, I flake.", "order": 40430, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 13, "scene": 18, "line": "What?", "order": 40431, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 13, "scene": 19, "line": "Kevin, you open it up.", "order": 40432, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 13, "scene": 19, "line": "Yep.", "order": 40433, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 13, "scene": 19, "line": "Ryan, you come in with your small biz expertise, right? And then Creed: guest speaker extraordinaire. And then I come in and just close all the sales and stuff. Um, okay, here we go. One, two three!", "order": 40434, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 13, "scene": 19, "line": "Go.", "order": 40435, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 13, "scene": 19, "line": "Creed! Ha!", "order": 40436, "speaker": "Creed Bratton"},
  {"season": 7, "episode": 13, "scene": 20, "line": "Welcome everybody. Awesome to see you guys. My name is Andy Bernard but you can call me The Nard Dog.", "order": 40437, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 13, "scene": 20, "line": "Hi Nard Dog. I'm Lu Peachem.", "order": 40438, "speaker": "Older Woman"},
  {"season": 7, "episode": 13, "scene": 20, "line": "Let's get things started, shall we? You guys ready to hear from the Dunder Mifflin business experts? Good. Well, as you can see on your program... first up is a speech called, 'Don't Just Dream it, do it.' Yes! Please give a big hand to Mr. Kevin Malone. [plays Ozzy Osboure's Crazy Train]", "order": 40439, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 13, "scene": 20, "line": "[running laps around the conference room] Yes!", "order": 40440, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 13, "scene": 21, "line": "There are some people who have charm and some people who don't. Guess which type I am. Charm type.", "order": 40441, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 13, "scene": 22, "line": "[out of breath] Dream... big. Right? So what I want you to do is dream the biggest that you c... an. [coughing] And then double it! [coughing].", "order": 40442, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 13, "scene": 22, "line": "Are you okay?", "order": 40443, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 13, "scene": 22, "line": "No. Yes!", "order": 40444, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 13, "scene": 22, "line": "Here...", "order": 40445, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 13, "scene": 22, "line": "No. I'm fine. Okay, from here now. The first lesson that I'm gonna teach you, right, is about finding success. And the key to finding success is to picture a winner. [coughing] [vomiting]. Okay, so then, what are you picturing right now, right this second? 'Cause the universe is yours, people. Get out of the way! [runs out of room]", "order": 40446, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 13, "scene": 23, "line": "I'm really excited to introduce you guys to Ryan Howard. He has achieved a great deal in the last...", "order": 40447, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 13, "scene": 23, "line": "But perhaps no achievement is greater than his on-again, off-again girlfriend.", "order": 40448, "speaker": "Kelly Kapoor"},
  {"season": 7, "episode": 13, "scene": 23, "line": "What are you...", "order": 40449, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 13, "scene": 23, "line": "Who am I? I'm Kelly Kapoor, the business bitch.", "order": 40450, "speaker": "Kelly Kapoor"},
  {"season": 7, "episode": 13, "scene": 24, "line": "It is important to brand yourself, so I have a couple of things in works. 'The Business Bitch', 'The Diet Bitch', 'The Shopping Bitch', 'The Etiquette Bitch.'", "order": 40451, "speaker": "Kelly Kapoor"},
  {"season": 7, "episode": 13, "scene": 25, "line": "I could sit here, and I could tell you the ten secrets of business, and you would have a great time, and you would learn a lot. But who better to tell you than the Yale University adjunct professor in management, Professor Scott Powell, who I have on my Blackberry. It's ringing.", "order": 40452, "speaker": "Kelly Kapoor"},
  {"season": 7, "episode": 13, "scene": 25, "line": "Hey, Kelly Kapoor. What a delicious surprise.", "order": 40453, "speaker": "Professor Powell"},
  {"season": 7, "episode": 13, "scene": 25, "line": "Profess Powell, you are on speakerphone.", "order": 40454, "speaker": "Kelly Kapoor"},
  {"season": 7, "episode": 13, "scene": 25, "line": "Uh, why?", "order": 40455, "speaker": "Professor Powell"},
  {"season": 7, "episode": 13, "scene": 25, "line": "Do you think you could tell us the ten secrets of business?", "order": 40456, "speaker": "Kelly Kapoor"},
  {"season": 7, "episode": 13, "scene": 25, "line": "Um, there aren't really ten secrets.", "order": 40457, "speaker": "Professor Powell"},
  {"season": 7, "episode": 13, "scene": 25, "line": "Come on Scott, please? It's me.", "order": 40458, "speaker": "Kelly Kapoor"},
  {"season": 7, "episode": 13, "scene": 25, "line": "Um, all right. Well, um, I guess know your market would be key. Practice fiscal discipline.", "order": 40459, "speaker": "Professor Powell"},
  {"season": 7, "episode": 13, "scene": 25, "line": "Mm-hmm.", "order": 40460, "speaker": "Kelly Kapoor"},
  {"season": 7, "episode": 13, "scene": 26, "line": "That brilliant little bitch.", "order": 40461, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 13, "scene": 27, "line": "A little treat for our old friend Gabe. Put that 'q' right there.", "order": 40462, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 13, "scene": 27, "line": "Wait. Why?", "order": 40463, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 13, "scene": 27, "line": "Put the 'q' there, sweetie.", "order": 40464, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 13, "scene": 27, "line": "I think there's better...", "order": 40465, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 13, "scene": 27, "line": "Put the 'q' there! [phone rings] Sorry I yelled.", "order": 40466, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 13, "scene": 27, "line": "You could have just told us what you were thinking.", "order": 40467, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 13, "scene": 27, "line": "There's no theater in that.", "order": 40468, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 13, "scene": 27, "line": "There's no yelling in that, either.", "order": 40469, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 13, "scene": 27, "line": "Well...", "order": 40470, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 13, "scene": 28, "line": "What do you do in your free time?", "order": 40471, "speaker": "Holly Flax"},
  {"season": 7, "episode": 13, "scene": 28, "line": "[as Mikanos] Practice Olympics.", "order": 40472, "speaker": "Michael Scott"},
  {"season": 7, "episode": 13, "scene": 28, "line": "Mmm. Do you like movies?", "order": 40473, "speaker": "Holly Flax"},
  {"season": 7, "episode": 13, "scene": 28, "line": "[as Mikanos] I like the musical 'Grease', or as we call it, 'home'.", "order": 40474, "speaker": "Michael Scott"},
  {"season": 7, "episode": 13, "scene": 28, "line": "We have to try this out on somebody.", "order": 40475, "speaker": "Holly Flax"},
  {"season": 7, "episode": 13, "scene": 29, "line": "[as Mikanos] Hello.", "order": 40476, "speaker": "Michael Scott"},
  {"season": 7, "episode": 13, "scene": 29, "line": "Look, you want to order something?", "order": 40477, "speaker": "Hank Tate"},
  {"season": 7, "episode": 13, "scene": 29, "line": "She will have a greekaccino.", "order": 40478, "speaker": "Michael Scott"},
  {"season": 7, "episode": 13, "scene": 29, "line": "I don't know what that is.", "order": 40479, "speaker": "Hank Tate"},
  {"season": 7, "episode": 13, "scene": 29, "line": "It...", "order": 40480, "speaker": "Michael Scott"},
  {"season": 7, "episode": 13, "scene": 29, "line": "[with accent] It's a very strong coffee with milk from a goat.", "order": 40481, "speaker": "Holly Flax"},
  {"season": 7, "episode": 13, "scene": 29, "line": "[laughs] I can't believe-ee. It's a miracle. She can talk!", "order": 40482, "speaker": "Michael Scott"},
  {"season": 7, "episode": 13, "scene": 29, "line": "No more brain damage.", "order": 40483, "speaker": "Holly Flax"},
  {"season": 7, "episode": 13, "scene": 29, "line": "No more brain damage!", "order": 40484, "speaker": "Michael Scott"},
  {"season": 7, "episode": 13, "scene": 29, "line": "Ahh!", "order": 40485, "speaker": "Holly Flax"},
  {"season": 7, "episode": 13, "scene": 30, "line": "I don't know.", "order": 40486, "speaker": "Holly Flax"},
  {"season": 7, "episode": 13, "scene": 31, "line": "Ladies and gentlemen, our special guest speaker will provoke you. He will inspire you. He is... Creed Bratton. [applause]", "order": 40487, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 13, "scene": 31, "line": "Two eyes, two ears, a chin, a mouth, ten fingers, two nipples. A butt, two kneecaps, a penis. I have just described to you the Loch Ness Monster. And the reward for its capture? All the riches in Scotland. So I have one question: Why are you here?", "order": 40488, "speaker": "Creed Bratton"},
  {"season": 7, "episode": 13, "scene": 31, "line": "[clapping] Okay. All right, ladies and gentlemen, it is now time for the rarest of things, an actual free lunch. There are sandwiches in the back. Certainly help yourselves. We'll meet back up in an hour.", "order": 40489, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 13, "scene": 32, "line": "I picked out our movie. It's called 'Suspiria'. It pushes all the boundaries. All your preconceived notions about what horror can be come crashing down.", "order": 40490, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 13, "scene": 32, "line": "When I win...", "order": 40491, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 13, "scene": 32, "line": "Ooh.", "order": 40492, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 13, "scene": 32, "line": "We're gonna watch 'Wall-e', where all the boundaries of color are pushed.", "order": 40493, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 13, "scene": 33, "line": "It is hard to explain why Erin is doing so well today. The only thing I can think is Erin is living out some 'Slumdog Millionaire' scenario, where every word she's playing has a connection to her orphan past. It's possible.", "order": 40494, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 13, "scene": 34, "line": "So what's your crazy business pipe dream?", "order": 40495, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 13, "scene": 34, "line": "Well, I started my own golf supply company.", "order": 40496, "speaker": "Guy"},
  {"season": 7, "episode": 13, "scene": 34, "line": "[unimpressed] Really?", "order": 40497, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 13, "scene": 34, "line": "It's taken off faster than I expected, so I came here to learn about creating manageable growth.", "order": 40498, "speaker": "Guy"},
  {"season": 7, "episode": 13, "scene": 34, "line": "[quietly] Phyllis?", "order": 40499, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 13, "scene": 34, "line": "Hmm?", "order": 40500, "speaker": "Phyllis Vance"},
  {"season": 7, "episode": 13, "scene": 34, "line": "Some of these people are for real.", "order": 40501, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 13, "scene": 35, "line": "Hey, friend. How's it going? Oh, you know what? Let me steep that for you.", "order": 40502, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 13, "scene": 35, "line": "Thank you.", "order": 40503, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 13, "scene": 35, "line": "Hey, you know what? Good news. We're back in. Let's go sell some paper, Buddy. Huh?", "order": 40504, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 13, "scene": 35, "line": "Really?", "order": 40505, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 13, "scene": 35, "line": "Ahem!", "order": 40506, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 13, "scene": 35, "line": "Excuse me.", "order": 40507, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 13, "scene": 35, "line": "I got it. I got it.", "order": 40508, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 13, "scene": 35, "line": "[turning towards Darryl] Hey, do you need a lozenge?", "order": 40509, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 13, "scene": 35, "line": "[whispering] They're a bunch of jackals.", "order": 40510, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 13, "scene": 35, "line": "What?", "order": 40511, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 13, "scene": 35, "line": "They left you in the lurch, and if they want to jump in, it means you're almost there. You did this. Bring it home.", "order": 40512, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 13, "scene": 35, "line": "[to Dwight, Stanley, and Phyllis]: Let me tell you what you can do with your offer to help. You can table it and offer it up another time. Just know that I really appreciate the gesture.", "order": 40513, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 13, "scene": 36, "line": "Hi. Hope you enjoyed your lunch. Welcome back. [to Michael and Holly] Ooh. Well, hello. Welcome to the seminar. Hey, man. What's goin' on?", "order": 40514, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 13, "scene": 36, "line": "[whispering] You're gonna blow it.", "order": 40515, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 13, "scene": 36, "line": "Maybe. Only maybe.", "order": 40516, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 13, "scene": 37, "line": "We got it! Wow! We got it!", "order": 40517, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 13, "scene": 37, "line": "Oscar, wait. I think the victory would be more meaningful if Erin puts the last word in herself.", "order": 40518, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 13, "scene": 37, "line": "Yes, Pam. Yes, most definitely. Yes.", "order": 40519, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 13, "scene": 37, "line": "Yes.", "order": 40520, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 13, "scene": 37, "line": "[pulls phone back] Ah. No! [laughing] [hands phone back]. Although I must say, I will have 'apoplexy' if you lose. Do you understand? 'Apoplexy' is what I will have.", "order": 40521, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 13, "scene": 37, "line": "Apoplexy.", "order": 40522, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 13, "scene": 37, "line": "Yes.", "order": 40523, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 13, "scene": 37, "line": "Got it. [plays word] Oh, Oscar. Oscar?", "order": 40524, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 13, "scene": 38, "line": "I played 'ape.'", "order": 40525, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 13, "scene": 39, "line": "[on phone] I just want to make a point to that last caller. I disagree. I don't think it is the running game at all. I think we do have to make a few moves in this off-season.", "order": 40526, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 13, "scene": 40, "line": "Wow! What a day, right? Guess you guys are probably ready to go. And you got my business card, so...", "order": 40527, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 13, "scene": 40, "line": "[as Mikanos] Dah, dah, dah, dah, dah. You know, I like you. I'm going to give you my secret gyro recipe. Come out here since it's a secret, and I'll tell you. [exits conference room] [normal voice] All, you have to close right now.", "order": 40528, "speaker": "Michael Scott"},
  {"season": 7, "episode": 13, "scene": 40, "line": "Yeah, I mean, I'm getting to it.", "order": 40529, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 13, "scene": 40, "line": "No, you're not. You're getting past it. You have to close. You can do it.", "order": 40530, "speaker": "Michael Scott"},
  {"season": 7, "episode": 13, "scene": 40, "line": "Yeah, yeah.", "order": 40531, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 13, "scene": 40, "line": "Andy, what's the problem?", "order": 40532, "speaker": "Michael Scott"},
  {"season": 7, "episode": 13, "scene": 40, "line": "This is hard for me. I'm a nice guy.", "order": 40533, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 13, "scene": 40, "line": "You, Kelly and Creed, Kevin, they believe in you. Don't let them down. Don't let yourself down, Andy. I'm gonna go back in. I'm going to stall them for a little bit. I want you to get your head together, and then come back in. I want you to close. [entering conference room] [as Mikanos] What is taking that guy so long?", "order": 40534, "speaker": "Michael Scott"},
  {"season": 7, "episode": 13, "scene": 41, "line": "Psst. You think this thing's gonna go much longer?", "order": 40535, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 13, "scene": 41, "line": "I don't know. They're still in there.", "order": 40536, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 13, "scene": 41, "line": "Ohh! Good-bye.", "order": 40537, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 13, "scene": 41, "line": "Stop. Out with it.", "order": 40538, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 13, "scene": 42, "line": "Here's the story. That guy in there is Jim's childhood friend, Tom.", "order": 40539, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 13, "scene": 42, "line": "Tom Witochkin. One of my best buddies, actually.", "order": 40540, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 13, "scene": 42, "line": "And when they were both in the third grade, Jim was placed in the top reading group.", "order": 40541, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 13, "scene": 42, "line": "I was blue group, so it was second from the top.", "order": 40542, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 13, "scene": 42, "line": "And Tom...", "order": 40543, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 13, "scene": 42, "line": "Was in the green group.", "order": 40544, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 13, "scene": 42, "line": "And Jim's mom suggested that Jim spend time hanging with the kids in his reading group, because she though that would be a good influence.", "order": 40545, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 13, "scene": 42, "line": "And that's what I told him.", "order": 40546, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 13, "scene": 42, "line": "Right. But how'd you say it?", "order": 40547, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 13, "scene": 42, "line": "My mom thinks you're too dumb to hang out with.'", "order": 40548, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 13, "scene": 43, "line": "Okay, who would like to purchase this small business package from me right now? Yes, we got one. Okay, the snowball is rolling. Who else? You can put it off for a couple of days, but I guarantee you, eventually you're gonna realize you need this. So the only thing that's gonna be different is you'll be a couple of days behind where you would be if you bought this from me right now. So who's gonna buy one right now?", "order": 40549, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 13, "scene": 43, "line": "I'll take one.", "order": 40550, "speaker": "Older Woman"},
  {"season": 7, "episode": 13, "scene": 43, "line": "Yes! Awesome! All right, anyone else? [younger guy raises hand] Sold! Anybody else? [older guy raises hand] Yes! All right!", "order": 40551, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 13, "scene": 43, "line": "Yeah bitch.", "order": 40552, "speaker": "Kelly Kapoor"},
  {"season": 7, "episode": 13, "scene": 43, "line": "Good choice. The rest of you are dead to me. You made the stupidest decision of your life.", "order": 40553, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 13, "scene": 43, "line": "[quietly] No, no, no.", "order": 40554, "speaker": "Michael Scott"},
  {"season": 7, "episode": 13, "scene": 43, "line": "But it was a pleasure meeting you, and you've got my information, so feel free to call anytime.", "order": 40555, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 13, "scene": 43, "line": "Whoo!", "order": 40556, "speaker": "Kelly Kapoor"},
  {"season": 7, "episode": 13, "scene": 44, "line": "Hey, you think it's cool if I grab a soda?", "order": 40557, "speaker": "Tom"},
  {"season": 7, "episode": 13, "scene": 44, "line": "Yeah, woah. [deeper voice] Yep, absolutely, go ahead.", "order": 40558, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 13, "scene": 44, "line": "Hey.", "order": 40559, "speaker": "Tom"},
  {"season": 7, "episode": 13, "scene": 44, "line": "Hey.", "order": 40560, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 13, "scene": 44, "line": "How's it goin'?", "order": 40561, "speaker": "Tom"},
  {"season": 7, "episode": 13, "scene": 44, "line": "Pretty good.", "order": 40562, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 13, "scene": 44, "line": "It's been a while.", "order": 40563, "speaker": "Tom"},
  {"season": 7, "episode": 13, "scene": 44, "line": "It has been, yeah.", "order": 40564, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 13, "scene": 44, "line": "So you work here, huh?", "order": 40565, "speaker": "Tom"},
  {"season": 7, "episode": 13, "scene": 44, "line": "Sales.", "order": 40566, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 13, "scene": 44, "line": "Must be a front for some kind of famous laboratory. [laughs]", "order": 40567, "speaker": "Tom"},
  {"season": 7, "episode": 13, "scene": 44, "line": "[laughs]", "order": 40568, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 13, "scene": 44, "line": "Cause you're so smart.", "order": 40569, "speaker": "Tom"},
  {"season": 7, "episode": 13, "scene": 44, "line": "Oh, man. You remember that, huh?", "order": 40570, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 13, "scene": 44, "line": "Oh, barely. I'm so dumb, you know, stuff goes in, stuff goes out. Not like you probably remember every paper sale you ever made. Paper salesman genius.", "order": 40571, "speaker": "Tom"},
  {"season": 7, "episode": 13, "scene": 44, "line": "All right, good catch-up.", "order": 40572, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 13, "scene": 44, "line": "Yeah.", "order": 40573, "speaker": "Tom"},
  {"season": 7, "episode": 13, "scene": 44, "line": "See ya.", "order": 40574, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 13, "scene": 44, "line": "Where's your jetpack, Zuckerberg?", "order": 40575, "speaker": "Tom"},
  {"season": 7, "episode": 13, "scene": 45, "line": "Andy. I didn't think you had it in ya.", "order": 40576, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 13, "scene": 45, "line": "Well, I guess when you looked in me, you forgot about my balls. They're on the outside. Don't how you missed 'em.", "order": 40577, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 13, "scene": 46, "line": "[with accent] Wonderful seminar!", "order": 40578, "speaker": "Holly Flax"},
  {"season": 7, "episode": 13, "scene": 46, "line": "[as Mikanos] Almost as good as the first day when we first met. You are the love of my life. Come to me, Necropolis. Put your lips on my lips. Come on.", "order": 40579, "speaker": "Michael Scott"},
  {"season": 7, "episode": 13, "scene": 46, "line": "Michael, I should get back to work.", "order": 40580, "speaker": "Holly Flax"},
  {"season": 7, "episode": 13, "scene": 46, "line": "What? Come on. It's time for grapes. [regular voice] Real fun day.", "order": 40581, "speaker": "Michael Scott"},
  {"season": 7, "episode": 13, "scene": 47, "line": "So, I won.", "order": 40582, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 13, "scene": 47, "line": "I know. You get to pick.", "order": 40583, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 13, "scene": 47, "line": "Well, that's actually what I came to talk to you about. I know how much you want to watch 'Wall-e'.", "order": 40584, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 13, "scene": 47, "line": "Yes?", "order": 40585, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 13, "scene": 47, "line": "So I got us a compromise. This movie's called 'Hardware'. It tells the story of a killer combat robot, just like Wall-e, that the government invented to destroy humans. It's some of what you like and some of what I like, and... married...", "order": 40586, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 13, "scene": 47, "line": "Hey, I heard you talking about movies before, and, anyway, I just watched this over the weekend. I thought you'd really like it.", "order": 40587, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 13, "scene": 47, "line": "There's a 'Shrek' two?!", "order": 40588, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 13, "scene": 47, "line": "Oh, yeah. See you tomorrow.", "order": 40589, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 13, "scene": 47, "line": "Nice guy.", "order": 40590, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 13, "scene": 47, "line": "Ohh. [laughs]", "order": 40591, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 14, "scene": 1, "line": "Everybody, can I please have your attention? Ryan and I have a huge announcement.", "order": 40592, "speaker": "Kelly Kapoor"},
  {"season": 7, "episode": 14, "scene": 1, "line": "Oh my gosh!", "order": 40593, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 14, "scene": 1, "line": "Wow.", "order": 40594, "speaker": "Phyllis Vance"},
  {"season": 7, "episode": 14, "scene": 1, "line": "Over the weekend, Ryan Bailey Howard and I got divorced. [throws ring on the ground]", "order": 40595, "speaker": "Kelly Kapoor"},
  {"season": 7, "episode": 14, "scene": 1, "line": "Sweet! Free Ring!", "order": 40596, "speaker": "Meredith Palmer"},
  {"season": 7, "episode": 14, "scene": 1, "line": "Divorced?", "order": 40597, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 14, "scene": 1, "line": "Just so you know, it's totally amicable. We're fine. We don't need people here to take sides.", "order": 40598, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 14, "scene": 1, "line": "The last thing that we want is any kind of drama.", "order": 40599, "speaker": "Kelly Kapoor"},
  {"season": 7, "episode": 14, "scene": 1, "line": "Wait. Can you back up? What's the story?", "order": 40600, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 14, "scene": 1, "line": "We were having a beautiful weekend in the Poconos. We were making love, constantly. We saw the sunrise. Ryan was crying a lot.", "order": 40601, "speaker": "Kelly Kapoor"},
  {"season": 7, "episode": 14, "scene": 1, "line": "It's not irrelevant. Details", "order": 40602, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 14, "scene": 1, "line": "And in the morning we walked by a chapel and we stopped, suddenly, and Ryan said...", "order": 40603, "speaker": "Kelly Kapoor"},
  {"season": 7, "episode": 14, "scene": 1, "line": "I don't think I should be married to you anymore.", "order": 40604, "speaker": "Ryan Howard & Kelly Kapoor"},
  {"season": 7, "episode": 14, "scene": 1, "line": "What?", "order": 40605, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 14, "scene": 1, "line": "Sorry, when did you get married?", "order": 40606, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 14, "scene": 1, "line": "Ummm, like a week ago, we got really wasted and it just felt right.", "order": 40607, "speaker": "Kelly Kapoor"},
  {"season": 7, "episode": 14, "scene": 1, "line": "And you didn't invite any of us?", "order": 40608, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 14, "scene": 1, "line": "We are getting divorced, Andy! This is such a raw time.", "order": 40609, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 14, "scene": 1, "line": "God baby, you know, people's reactions to this... maybe we made a mistake.", "order": 40610, "speaker": "Kelly Kapoor"},
  {"season": 7, "episode": 14, "scene": 1, "line": "No, with the messed up laws in this country, I don't want to be married until everyone can be married.", "order": 40611, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 14, "scene": 1, "line": "You know what Ryan, I talked to the other gay guys, and we're ok with it. We agree it's fine if you got married.", "order": 40612, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 14, "scene": 1, "line": "No Oscar, Not, not until everyone can!", "order": 40613, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 14, "scene": 1, "line": "Ryan, I changed my mind.", "order": 40614, "speaker": "Kelly Kapoor"},
  {"season": 7, "episode": 14, "scene": 1, "line": "Ok fine, you know what, this actually isn't amicable at all and we actually do need people to take sides. Who is on my side?", "order": 40615, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 14, "scene": 1, "line": "And who is on my side? [no one raises their hand]", "order": 40616, "speaker": "Kelly Kapoor"},
  {"season": 7, "episode": 14, "scene": 2, "line": "All right. Bye.", "order": 40617, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 14, "scene": 2, "line": "Bye.", "order": 40618, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 14, "scene": 2, "line": "Let's Go!", "order": 40619, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 14, "scene": 2, "line": "Just a minute. How long do we have to wait?", "order": 40620, "speaker": "Michael Scott"},
  {"season": 7, "episode": 14, "scene": 2, "line": "For what?", "order": 40621, "speaker": "Holly Flax"},
  {"season": 7, "episode": 14, "scene": 2, "line": "You broke up with AJ weeks ago.", "order": 40622, "speaker": "Michael Scott"},
  {"season": 7, "episode": 14, "scene": 2, "line": "Don't you have a sales call to go on?", "order": 40623, "speaker": "Holly Flax"},
  {"season": 7, "episode": 14, "scene": 2, "line": "I don't understand. I really don't. I mean, we know we are going to start dating. Why not now?", "order": 40624, "speaker": "Michael Scott"},
  {"season": 7, "episode": 14, "scene": 2, "line": "We don't know that.", "order": 40625, "speaker": "Holly Flax"},
  {"season": 7, "episode": 14, "scene": 2, "line": "Sure we do.", "order": 40626, "speaker": "Michael Scott"},
  {"season": 7, "episode": 14, "scene": 2, "line": "Why is it such a certainty that we are supposed to be together?", "order": 40627, "speaker": "Holly Flax"},
  {"season": 7, "episode": 14, "scene": 2, "line": "Why does the sun rise in the morning? Why do magnets stick together? Because everybody says so. Everybody.", "order": 40628, "speaker": "Michael Scott"},
  {"season": 7, "episode": 14, "scene": 2, "line": "Michael, I can't keep getting into a situation where I date whoever I'm working with. Well, you can understand that.", "order": 40629, "speaker": "Holly Flax"},
  {"season": 7, "episode": 14, "scene": 2, "line": "Yeah, I understand. I just don't agree.", "order": 40630, "speaker": "Michael Scott"},
  {"season": 7, "episode": 14, "scene": 2, "line": "Well you don't have to agree.", "order": 40631, "speaker": "Holly Flax"},
  {"season": 7, "episode": 14, "scene": 2, "line": "Yes I do.", "order": 40632, "speaker": "Michael Scott"},
  {"season": 7, "episode": 14, "scene": 2, "line": "No you don't.", "order": 40633, "speaker": "Holly Flax"},
  {"season": 7, "episode": 14, "scene": 2, "line": "Yes I do.", "order": 40634, "speaker": "Michael Scott"},
  {"season": 7, "episode": 14, "scene": 2, "line": "No, you can have your own opinion.", "order": 40635, "speaker": "Holly Flax"},
  {"season": 7, "episode": 14, "scene": 2, "line": "I have my own opinion and my opinion is to disagree with you.", "order": 40636, "speaker": "Michael Scott"},
  {"season": 7, "episode": 14, "scene": 2, "line": "He's going to be a lot of fun to drive around in a car with.", "order": 40637, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 14, "scene": 2, "line": "Aw you'll get through it hon. Just make a game out of it. A funny 'Jim' game.", "order": 40638, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 14, "scene": 2, "line": "That's it? That's all I get? Even after all the hard work I put into celebrating your talent today?", "order": 40639, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 14, "scene": 2, "line": "All right, what'd you do?", "order": 40640, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 14, "scene": 2, "line": "Well, those things that you consider doodles, I consider art.", "order": 40641, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 14, "scene": 2, "line": "Where'd you put it?", "order": 40642, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 14, "scene": 2, "line": "Where'd I put what?", "order": 40643, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 14, "scene": 2, "line": "Let's go.", "order": 40644, "speaker": "Michael Scott"},
  {"season": 7, "episode": 14, "scene": 2, "line": "Oh, sorry gotta go.", "order": 40645, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 14, "scene": 2, "line": "Say it. Where?", "order": 40646, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 14, "scene": 2, "line": "Ok fine, three hints. One! When you are getting colder, you're really getting warmer.", "order": 40647, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 14, "scene": 2, "line": "The fridge.", "order": 40648, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 14, "scene": 2, "line": "Two! You have a better chance, if you think Bob Vance.", "order": 40649, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 14, "scene": 2, "line": "The fridge, got it.", "order": 40650, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 14, "scene": 2, "line": "And the final clue...", "order": 40651, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 14, "scene": 2, "line": "Let's go!", "order": 40652, "speaker": "Michael Scott"},
  {"season": 7, "episode": 14, "scene": 2, "line": "You know what, just think about it, you'll be fine.", "order": 40653, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 14, "scene": 2, "line": "Bye.", "order": 40654, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 14, "scene": 3, "line": "Holly is ruining Michael's life. He thinks she is so special. And she's so not. Her personality is like a 3. Her sense of humor is a 2. Her ears are like a 7and a 4. Add it all up and what do you get? 16. And he treats her like she's a perfect 40. It's nuts.", "order": 40655, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 14, "scene": 4, "line": "Cheer up. We made a sale.", "order": 40656, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 14, "scene": 4, "line": "Just drive faster. I want to get back.", "order": 40657, "speaker": "Michael Scott"},
  {"season": 7, "episode": 14, "scene": 4, "line": "Well, I'm going the speed limit. So...", "order": 40658, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 14, "scene": 4, "line": "Okay, fine. My feelings don't matter to you. What matters to you is your precious speed limit.", "order": 40659, "speaker": "Michael Scott"},
  {"season": 7, "episode": 14, "scene": 4, "line": "Someone's in a bad mood.", "order": 40660, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 14, "scene": 4, "line": "No I'm not. I'm not in a bad mood. I'm not, Jim. Hello? Ok fine, ignore me. Have it your way. Let's just talk about you, as always. Is sex different after the baby, Jim?", "order": 40661, "speaker": "Michael Scott"},
  {"season": 7, "episode": 14, "scene": 4, "line": "Alright, let me turn on some music.", "order": 40662, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 14, "scene": 4, "line": "I need to pee.", "order": 40663, "speaker": "Michael Scott"},
  {"season": 7, "episode": 14, "scene": 4, "line": "No you don't.", "order": 40664, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 14, "scene": 4, "line": "Yeah, I do. My word against yours.", "order": 40665, "speaker": "Michael Scott"},
  {"season": 7, "episode": 14, "scene": 4, "line": "Alright. Well we'll be there in ten minutes.", "order": 40666, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 14, "scene": 4, "line": "What part of 'I need to pee' do you not understand? I'm upset. My bladder is full. There is no telling what I might do right now all over the inside of your precious little car.", "order": 40667, "speaker": "Michael Scott"},
  {"season": 7, "episode": 14, "scene": 4, "line": "Alright, Well if I see a gas station, I'll pull over.", "order": 40668, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 14, "scene": 4, "line": "Well I hope I make it.", "order": 40669, "speaker": "Michael Scott"},
  {"season": 7, "episode": 14, "scene": 5, "line": "Hello?", "order": 40670, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 14, "scene": 5, "line": "Hi Jim, it's Helen.", "order": 40671, "speaker": "Helene Beesly"},
  {"season": 7, "episode": 14, "scene": 5, "line": "Hey Helen. Is everything ok?", "order": 40672, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 14, "scene": 5, "line": "Everything is fine. Baby's fine. She has a tiny fever. I'm taking her for a check up. Nothing to worry about.", "order": 40673, "speaker": "Helene Beesly"},
  {"season": 7, "episode": 14, "scene": 5, "line": "Ok...", "order": 40674, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 14, "scene": 5, "line": "A tiny thing. I locked her in the car.", "order": 40675, "speaker": "Helene Beesly"},
  {"season": 7, "episode": 14, "scene": 5, "line": "What?", "order": 40676, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 14, "scene": 5, "line": "She's smiling. She's happy.", "order": 40677, "speaker": "Helene Beesly"},
  {"season": 7, "episode": 14, "scene": 5, "line": "Oh my God.", "order": 40678, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 14, "scene": 5, "line": "Jim, I don't have a spare key.", "order": 40679, "speaker": "Helene Beesly"},
  {"season": 7, "episode": 14, "scene": 5, "line": "Just stay there. I'll be there in one second. Michael! Michael! Excuse me, sir there is a guy in the bathroom. He's coming out but I have to go because it's an emergency. Will you just tell him call the office. Just call the office! Thank you. Please?", "order": 40680, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 14, "scene": 5, "line": "[towards bathroom] Hello? [gives up and drives off]", "order": 40681, "speaker": "Guy"},
  {"season": 7, "episode": 14, "scene": 6, "line": "This is Pam.", "order": 40682, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 14, "scene": 6, "line": "Hey it's me. So uh, don't worry. Everything's ok.", "order": 40683, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 14, "scene": 6, "line": "What's wrong?", "order": 40684, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 14, "scene": 6, "line": "Nothing. Absolutely nothing. You're not holding a cup of coffee or anything are you?", "order": 40685, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 14, "scene": 6, "line": "Jim, what?", "order": 40686, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 14, "scene": 6, "line": "Uh, so, Cece had a little bit of a fever. And your mom also locked her in the car.", "order": 40687, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 14, "scene": 6, "line": "Oh God! What?!", "order": 40688, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 14, "scene": 6, "line": "No no no it's ok. So, Cece is with daddy now. She's laughing and she's happy. And we are on our way to see Dr. Barbra.", "order": 40689, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 14, "scene": 6, "line": "Ok. She's ok?", "order": 40690, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 14, "scene": 6, "line": "Yes, and your mom got a very well deserved day off. So here's the thing though. I left Michael at the gas station on Benet.", "order": 40691, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 14, "scene": 6, "line": "Understood.", "order": 40692, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 14, "scene": 6, "line": "And his wallet and phone are on the seat next to me.", "order": 40693, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 14, "scene": 6, "line": "Got it. I will put out a A.P.B. Otherwise known as a 'Ask Pam Beasley'. ... Did the phone cut off?", "order": 40694, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 14, "scene": 6, "line": "Nope.", "order": 40695, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 14, "scene": 6, "line": "Alright, just call me after the doctor.", "order": 40696, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 14, "scene": 6, "line": "Ok. Bye.", "order": 40697, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 14, "scene": 6, "line": "Bye.", "order": 40698, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 14, "scene": 7, "line": "I know, I know you have a phone policy. I understand. But this is an emergency because my friend isn't here and I am worried that he has been abducted.", "order": 40699, "speaker": "Michael Scott"},
  {"season": 7, "episode": 14, "scene": 7, "line": "No, he ditched you man. I saw him drive away.", "order": 40700, "speaker": "Attendant"},
  {"season": 7, "episode": 14, "scene": 7, "line": "There is no way he ditched me. There is no way that happened. He's my... Ok. May I please just use your phone? Please!", "order": 40701, "speaker": "Michael Scott"},
  {"season": 7, "episode": 14, "scene": 7, "line": "Just make it quick.", "order": 40702, "speaker": "Attendant"},
  {"season": 7, "episode": 14, "scene": 7, "line": "Ok. I will make it quick. I will make it very quick. [opens phone] And you don't have my numbers on speed dial. Ah, Ok. What is it?", "order": 40703, "speaker": "Michael Scott"},
  {"season": 7, "episode": 14, "scene": 7, "line": "You don't know it.", "order": 40704, "speaker": "Attendant"},
  {"season": 7, "episode": 14, "scene": 7, "line": "You know what, I can dial 411, get Jim's number.", "order": 40705, "speaker": "Michael Scott"},
  {"season": 7, "episode": 14, "scene": 7, "line": "He left you here on purpose. All right? I saw it. He just drove away.", "order": 40706, "speaker": "Attendant"},
  {"season": 7, "episode": 14, "scene": 8, "line": "Actually this is good. I am going to take this opportunity to go walk-about. Good. Good. [to cameras] Nope, get away. No, that's enough. Ok.", "order": 40707, "speaker": "Michael Scott"},
  {"season": 7, "episode": 14, "scene": 9, "line": "It's the gas station on Benet Street?", "order": 40708, "speaker": "Holly Flax"},
  {"season": 7, "episode": 14, "scene": 9, "line": "Yeah.", "order": 40709, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 14, "scene": 9, "line": "Ok.", "order": 40710, "speaker": "Holly Flax"},
  {"season": 7, "episode": 14, "scene": 9, "line": "You know what? Why don't you stay and I'll go?", "order": 40711, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 14, "scene": 9, "line": "Oh no no no. I'll be fine.", "order": 40712, "speaker": "Holly Flax"},
  {"season": 7, "episode": 14, "scene": 9, "line": "It's kind of a sketchy neighborhood though. I better come along.", "order": 40713, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 14, "scene": 9, "line": "Problem solved everyone. Space Orphan and Princess Nincompoop are off to rescue Michael. Unbelievable. I'm going. You drive, I got a car full of fox meat.", "order": 40714, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 14, "scene": 10, "line": "Cute. [enters office area] Very funny, everyone. Who wrote captions under my doodle? I'm not even kidding. They're pretty good.", "order": 40715, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 14, "scene": 10, "line": "Which on in particular?", "order": 40716, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 14, "scene": 10, "line": "Yeah, which one?", "order": 40717, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 14, "scene": 10, "line": "Well the first one has a surprise factor. 'I'm a suck suck-suckidy Sabre!'", "order": 40718, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 14, "scene": 10, "line": "Boo-yea! [office laughs]", "order": 40719, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 14, "scene": 10, "line": "No no. No no no.", "order": 40720, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 14, "scene": 10, "line": "But, ah, I suppose the second one is the better written line.", "order": 40721, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 14, "scene": 10, "line": "You suppose?", "order": 40722, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 14, "scene": 10, "line": "What's it say?", "order": 40723, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 14, "scene": 10, "line": "I'm suppose to be wearing red gloves but my color cartridge portal, got jammed again.'", "order": 40724, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 14, "scene": 10, "line": "Ok. [office laughs] No no no.", "order": 40725, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 14, "scene": 10, "line": "Red gloves.", "order": 40726, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 14, "scene": 10, "line": "Keep it real.", "order": 40727, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 14, "scene": 10, "line": "You guys, if I knew you wanted to do a caption contest, I would have drawn something more challenging.", "order": 40728, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 14, "scene": 10, "line": "And I will take you all down.", "order": 40729, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 14, "scene": 10, "line": "You?", "order": 40730, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 14, "scene": 10, "line": "I've been reading the comics to my daughter since she was three years old. Not once I have used the real captions to Family Circus. That crazy family is hilarious to her for one reason: me.", "order": 40731, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 14, "scene": 10, "line": "Oh it is on like Genghis Khan wearing Sean Jean in Buton.", "order": 40732, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 14, "scene": 10, "line": "Yes. You guys, I have the perfect idea. Ok, it will just take me ten minutes.", "order": 40733, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 14, "scene": 10, "line": "Woo-hoo.", "order": 40734, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 14, "scene": 10, "line": "I can't wait.", "order": 40735, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 14, "scene": 11, "line": "Male. Caucasian. Forties. Black hair. Facial type: marsupial.", "order": 40736, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 14, "scene": 11, "line": "He answers to Michal. Michal G. Scott. Michael J. Fox. Mr. Fox. The Incredible Mr. Fox.", "order": 40737, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 14, "scene": 11, "line": "Yeah, he just left.", "order": 40738, "speaker": "Attendant"},
  {"season": 7, "episode": 14, "scene": 11, "line": "Which way did he go?", "order": 40739, "speaker": "Holly Flax"},
  {"season": 7, "episode": 14, "scene": 11, "line": "Hey hey hey. Let me answer this. Stupid question. He went back to the office, obviously, which is that way.", "order": 40740, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 14, "scene": 11, "line": "Oh really? You don't think he walked by the bakery just for the smell of it?", "order": 40741, "speaker": "Holly Flax"},
  {"season": 7, "episode": 14, "scene": 11, "line": "She's right. He went that way.", "order": 40742, "speaker": "Attendant"},
  {"season": 7, "episode": 14, "scene": 11, "line": "Alright. Don't get a swelled head. You're no tracker. [Dwight and Erin low-five] Let's ride.", "order": 40743, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 14, "scene": 12, "line": "[at puppies] Hey you guys. Listen to me. Don't get hung up on just one girl because there are a whole lot of other girls out there. Look over there. See? They look cute. [at parrots] Hello! You guys are so beautiful. You're so colorful. I wish I could understand you. That's a metaphor I guess. [at snake] You are disgusting. You'll never find love. Yekkk. [holding puppy] Do you think she needs more time or is it never going to happen? [licks his nose] I'm being serious. Seriously.", "order": 40744, "speaker": "Michael Scott"},
  {"season": 7, "episode": 14, "scene": 13, "line": "[on phone] Dunder Mifflin, this is Pam.", "order": 40745, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 14, "scene": 13, "line": "Has Michael checked in?", "order": 40746, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 14, "scene": 13, "line": "Oh, hey Dwight.", "order": 40747, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 14, "scene": 13, "line": "I asked you a question.", "order": 40748, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 14, "scene": 13, "line": "No, he hasn't.", "order": 40749, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 14, "scene": 13, "line": "Goodbye.", "order": 40750, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 14, "scene": 13, "line": "No, wait. Hey, while you are out, could pick up some paper towels and chocolate syrup? We have ice cream so I thought...", "order": 40751, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 14, "scene": 13, "line": "Pam, this isn't a shopping trip.", "order": 40752, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 14, "scene": 13, "line": "No.", "order": 40753, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 14, "scene": 13, "line": "This is a man-hunt slash rescue mission.", "order": 40754, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 14, "scene": 13, "line": "Ok. I, just, when you are done or any time it's convent, I just thought since you are out...", "order": 40755, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 14, "scene": 13, "line": "Pam, I'm obviously going to get that stuff for you so just shut up.", "order": 40756, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 14, "scene": 13, "line": "Ok, well it wasn't obvious so... [phone hangs up]", "order": 40757, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 14, "scene": 13, "line": "No word from Michael.", "order": 40758, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 14, "scene": 13, "line": "Oh.", "order": 40759, "speaker": "Holly Flax"},
  {"season": 7, "episode": 14, "scene": 14, "line": "Ta-da!", "order": 40760, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 14, "scene": 14, "line": "Ok, it's two giant dogs with two giant palm trees on a regular size island.", "order": 40761, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 14, "scene": 14, "line": "Ok. I got one.", "order": 40762, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 14, "scene": 14, "line": "Yeah?", "order": 40763, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 14, "scene": 14, "line": "Yes!", "order": 40764, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 14, "scene": 14, "line": "Ok, I'm sorry but I am going to have to shut this down.", "order": 40765, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 14, "scene": 14, "line": "Boo!", "order": 40766, "speaker": "Office"},
  {"season": 7, "episode": 14, "scene": 14, "line": "Why?", "order": 40767, "speaker": "Meredith Palmer"},
  {"season": 7, "episode": 14, "scene": 14, "line": "Unless we can all agree to some ground rules. It's either that or I can fax this to Joe and let her decide how to proceed.", "order": 40768, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 14, "scene": 14, "line": "Gabe, tell us your stupid rules so we can start the game.", "order": 40769, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 14, "scene": 14, "line": "Number one, and this should be obvious, no captions that insult the company we work for.", "order": 40770, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 14, "scene": 14, "line": "Irony is such a critical...", "order": 40771, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 14, "scene": 14, "line": "Number two: no pop culture references.", "order": 40772, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 14, "scene": 14, "line": "Seriously?", "order": 40773, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 14, "scene": 14, "line": "Wow.", "order": 40774, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 14, "scene": 14, "line": "I think we can all agree that they tend to alienate those who don't get the reference, making them feel like the other.", "order": 40775, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 14, "scene": 14, "line": "Wrap it up, Gabe.", "order": 40776, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 14, "scene": 14, "line": "Ok final thing, and this is a fun one: Instead of writing the caption directly under the picture, let's all try using Sticky Quips. All right? New, from Dunder Mifflin Sabre.", "order": 40777, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 14, "scene": 15, "line": "Sticky Quips are fun. They are safe. They are handy. I like to use Sticky Quips as regular Post-It notes when I am in a fun mood. [laughs] Not every day.", "order": 40778, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 14, "scene": 16, "line": "Go get 'em. Start quipin'.", "order": 40779, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 14, "scene": 16, "line": "Pam, I think I'm going to send you an IM.", "order": 40780, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 14, "scene": 16, "line": "Oh! Ok.", "order": 40781, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 14, "scene": 16, "line": "Send me one too.", "order": 40782, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 14, "scene": 16, "line": "Yeah, yeah. Put me on that.", "order": 40783, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 14, "scene": 16, "line": "C.C", "order": 40784, "speaker": "Phyllis Vance"},
  {"season": 7, "episode": 14, "scene": 16, "line": "Ditto.", "order": 40785, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 14, "scene": 17, "line": "Hello. I would like a hot dog please. Now, I don't have any money so here is what I would like to do. I leave you here with my watch, and I come back later to pay for the hot dog.", "order": 40786, "speaker": "Michael Scott"},
  {"season": 7, "episode": 14, "scene": 17, "line": "I'm not a pawn shop.", "order": 40787, "speaker": "Hot Dog Guy"},
  {"season": 7, "episode": 14, "scene": 17, "line": "Well I understand that but this is a $45 watch.", "order": 40788, "speaker": "Michael Scott"},
  {"season": 7, "episode": 14, "scene": 17, "line": "Wow.", "order": 40789, "speaker": "Hot Dog Guy"},
  {"season": 7, "episode": 14, "scene": 17, "line": "With that I can buy... half the menu.", "order": 40790, "speaker": "Michael Scott"},
  {"season": 7, "episode": 14, "scene": 17, "line": "I can't just go giving away hot dogs.", "order": 40791, "speaker": "Hot Dog Guy"},
  {"season": 7, "episode": 14, "scene": 17, "line": "All right. What do you do with the hog dogs that you don't sell?", "order": 40792, "speaker": "Michael Scott"},
  {"season": 7, "episode": 14, "scene": 17, "line": "Throw 'em away.", "order": 40793, "speaker": "Hot Dog Guy"},
  {"season": 7, "episode": 14, "scene": 17, "line": "Well, okay, instead of throwing them all away later, why don't you just throw one away now into my mouth?", "order": 40794, "speaker": "Michael Scott"},
  {"season": 7, "episode": 14, "scene": 17, "line": "No.", "order": 40795, "speaker": "Hot Dog Guy"},
  {"season": 7, "episode": 14, "scene": 17, "line": "Okay. You've just lost my business.", "order": 40796, "speaker": "Michael Scott"},
  {"season": 7, "episode": 14, "scene": 18, "line": "Hey.", "order": 40797, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 14, "scene": 18, "line": "Hey, you were in there forever.", "order": 40798, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 14, "scene": 18, "line": "There's too many brands. Where's Holly?", "order": 40799, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 14, "scene": 18, "line": "She wandered off like an idiot.", "order": 40800, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 14, "scene": 18, "line": "Hey!", "order": 40801, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 14, "scene": 18, "line": "Hey.", "order": 40802, "speaker": "Holly Flax"},
  {"season": 7, "episode": 14, "scene": 18, "line": "What are you doing?", "order": 40803, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 14, "scene": 18, "line": "Oh, just changing my cell phone plan. Okay. Okay. Here you go. I'll take my free stress ball too now.", "order": 40804, "speaker": "Holly Flax"},
  {"season": 7, "episode": 14, "scene": 18, "line": "Sure thing. Here you go, Miss... Okay, Fanny Smellmore. Real original.", "order": 40805, "speaker": "Cell Phone Sales Person"},
  {"season": 7, "episode": 14, "scene": 18, "line": "What?", "order": 40806, "speaker": "Holly Flax"},
  {"season": 7, "episode": 14, "scene": 18, "line": "You know what? Say hi to Orville Tootenbacher for me.", "order": 40807, "speaker": "Cell Phone Sales Person"},
  {"season": 7, "episode": 14, "scene": 18, "line": "Tootenbacher.", "order": 40808, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 14, "scene": 18, "line": "Orville Tootenbacher. That's Michael's millionaire character that...", "order": 40809, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 14, "scene": 18, "line": "farts popcorn.", "order": 40810, "speaker": "Dwight Schrute & Erin Hannon"},
  {"season": 7, "episode": 14, "scene": 18, "line": "Of course. He was here. She's the key. Amazing. Holly. Hey, where you would you like to go next? Holly?", "order": 40811, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 14, "scene": 18, "line": "Are their egg rolls really that big?", "order": 40812, "speaker": "Holly Flax"},
  {"season": 7, "episode": 14, "scene": 19, "line": "Oh, boy. That was yummy. Thank you so much. You know what? I think I left my wallet in my car. Do you mind if I run out and get it?", "order": 40813, "speaker": "Michael Scott"},
  {"season": 7, "episode": 14, "scene": 19, "line": "No problem.", "order": 40814, "speaker": "Waiter"},
  {"season": 7, "episode": 14, "scene": 19, "line": "Okay. I'll be right back. [goes to leave but walks back]. Okay. You know what? I'm sorry. I'm sorry. I don't have my wallet and I was gonna try to dine and dash and that was stupid and I realize I can't do that to you fine people.", "order": 40815, "speaker": "Michael Scott"},
  {"season": 7, "episode": 14, "scene": 19, "line": "So you can't pay for your food?", "order": 40816, "speaker": "Waiter"},
  {"season": 7, "episode": 14, "scene": 19, "line": "Well I can, but I will have to come back later tonight and pay you. But the point is I did the right thing.", "order": 40817, "speaker": "Michael Scott"},
  {"season": 7, "episode": 14, "scene": 19, "line": "You did not. You have no money. And you dined so much.", "order": 40818, "speaker": "Waiter"},
  {"season": 7, "episode": 14, "scene": 19, "line": "Well the number three is not such a giant feast.", "order": 40819, "speaker": "Michael Scott"},
  {"season": 7, "episode": 14, "scene": 19, "line": "[calling to the back] Mr. Chu!", "order": 40820, "speaker": "Waiter"},
  {"season": 7, "episode": 14, "scene": 19, "line": "Okay, all right. You know what?", "order": 40821, "speaker": "Micahel"},
  {"season": 7, "episode": 14, "scene": 19, "line": "You were trying to steal food from us?", "order": 40822, "speaker": "Waiter"},
  {"season": 7, "episode": 14, "scene": 19, "line": "I am not. I just have had a bad day. And I... okay. I'll be back later with the money. I'm just gonna leave right now.", "order": 40823, "speaker": "Michael Scott"},
  {"season": 7, "episode": 14, "scene": 19, "line": "You can't. We'll stop you.", "order": 40824, "speaker": "Waiter"},
  {"season": 7, "episode": 14, "scene": 19, "line": "Well, I think I can get through the door.", "order": 40825, "speaker": "Michael Scott"},
  {"season": 7, "episode": 14, "scene": 20, "line": "Excuse me Do you speak English? We are looking for a man. Michael, this tall, black hair, Caucasian...", "order": 40826, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 14, "scene": 20, "line": "[point to picture] It's Michael!", "order": 40827, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 14, "scene": 20, "line": "He just left.", "order": 40828, "speaker": "Waiter"},
  {"season": 7, "episode": 14, "scene": 20, "line": "You knew.", "order": 40829, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 14, "scene": 20, "line": "What? No. Dwight asked if I wanted an egg roll. What... what are you implying?", "order": 40830, "speaker": "Holly Flax"},
  {"season": 7, "episode": 14, "scene": 20, "line": "Uncanny. Put a pin in that. [to waiter] Which way did he go? That guy! When he leave here, which way did he go? We looking for him. [pointing] This way, this way, this way? I don't know. Do you know?", "order": 40831, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 14, "scene": 20, "line": "I think he was heading downtown.", "order": 40832, "speaker": "Waiter"},
  {"season": 7, "episode": 14, "scene": 20, "line": "He's heading downtown.", "order": 40833, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 14, "scene": 21, "line": "So what, no one's even gonna try?", "order": 40834, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 14, "scene": 21, "line": "Guess not.", "order": 40835, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 14, "scene": 21, "line": "Oh, come on. My rules could not possibly have been that oppressive.", "order": 40836, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 14, "scene": 21, "line": "You crushed our spirits, Gabe. Congrats. You're a big man, huh? Take a lot to destroy the creativity of a whole group of people. [notification tone] [laughter]", "order": 40837, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 14, "scene": 21, "line": "Hey...", "order": 40838, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 14, "scene": 21, "line": "[whispers] Click the 'x'", "order": 40839, "speaker": "Stanley Hudson"},
  {"season": 7, "episode": 14, "scene": 21, "line": "[whispers] I'm clicking!", "order": 40840, "speaker": "Phyllis Vance"},
  {"season": 7, "episode": 14, "scene": 21, "line": "In the box.", "order": 40841, "speaker": "Stanley Hudson"},
  {"season": 7, "episode": 14, "scene": 21, "line": "I am clicking.", "order": 40842, "speaker": "Phyllis Vance"},
  {"season": 7, "episode": 14, "scene": 21, "line": "Woman, you've had a computer for years!", "order": 40843, "speaker": "Stanley Hudson"},
  {"season": 7, "episode": 14, "scene": 21, "line": "Phyllis!", "order": 40844, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 14, "scene": 21, "line": "Too late! Oh. Ha. An IM chat. Very clever. I'll just print that out. Come on guys. Grow up. I don't want to be your babysitter.", "order": 40845, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 14, "scene": 21, "line": "Oooohhh.", "order": 40846, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 14, "scene": 22, "line": "Darn it Bob. I told you not to buy a Sabre brand lifeboat.' [laughter]", "order": 40847, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 14, "scene": 22, "line": "Nice!", "order": 40848, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 14, "scene": 22, "line": "No, not nice. Terrible. Doesn't even include the fact that they're dogs.", "order": 40849, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 14, "scene": 22, "line": "Do the next one.", "order": 40850, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 14, "scene": 22, "line": "Wake up, Fred. The power cord on your Sabre printer shocked you into a coma, and you're dreaming you're a dog on a desert island.'", "order": 40851, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 14, "scene": 22, "line": "Dreaming he's a dog on a island.", "order": 40852, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 14, "scene": 22, "line": "Uh, excuse me, excuse me. How does the speaker know what the guy in the coma is dreaming?", "order": 40853, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 14, "scene": 22, "line": "Well, if you think it's so easy, Gabe, why don't you try it?", "order": 40854, "speaker": "Phyllis Vance"},
  {"season": 7, "episode": 14, "scene": 22, "line": "Umm... 'You don't have to sniff my rear end anymore, Bob, I'm the only one here.' Ha.", "order": 40855, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 14, "scene": 22, "line": "That's tasteless, Gabe.", "order": 40856, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 14, "scene": 22, "line": "Tasteless?", "order": 40857, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 14, "scene": 22, "line": "Tasteless.", "order": 40858, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 14, "scene": 22, "line": "More tasteless than this...'is that a palm tree or did Gabe get skinnier? Either way, let's pee on it.' [laughter]", "order": 40859, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 14, "scene": 22, "line": "Ladies and gentlemen, I think we have a winner.", "order": 40860, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 14, "scene": 22, "line": "Yes.", "order": 40861, "speaker": "Phyllis Vance"},
  {"season": 7, "episode": 14, "scene": 22, "line": "Yes, well done!", "order": 40862, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 14, "scene": 22, "line": "Who's is it? Who wrote that?", "order": 40863, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 14, "scene": 22, "line": "Yeah, who wrote it?", "order": 40864, "speaker": "Phyllis Vance"},
  {"season": 7, "episode": 14, "scene": 23, "line": "Please. It was easy once I decided I wanted the dog to piss on Gabe.", "order": 40865, "speaker": "Angela Martin"},
  {"season": 7, "episode": 14, "scene": 24, "line": "Where did he go, Holly?", "order": 40866, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 14, "scene": 24, "line": "I have no idea.", "order": 40867, "speaker": "Holly Flax"},
  {"season": 7, "episode": 14, "scene": 24, "line": "Do you see a little clown that you want to follow, huh? Is there a little bird that's chirping to you, 'this way, this way'?", "order": 40868, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 14, "scene": 24, "line": "I don't know.", "order": 40869, "speaker": "Holly Flax"},
  {"season": 7, "episode": 14, "scene": 24, "line": "Okay, close your eyes, we need you to think. What is Michael seeing right now? Can you tell him that we miss him? Michael we're coming for you!", "order": 40870, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 14, "scene": 24, "line": "Will you stop! There has been a few coincidences, that's all.", "order": 40871, "speaker": "Holly Flax"},
  {"season": 7, "episode": 14, "scene": 24, "line": "All right then. Someone propose a plan.", "order": 40872, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 14, "scene": 24, "line": "Okay. We fan out...", "order": 40873, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 14, "scene": 24, "line": "Not you, Erin.", "order": 40874, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 14, "scene": 24, "line": "Stop looking at me like that. Okay, let's just go up somewhere high and see if we could spot him on the street below.", "order": 40875, "speaker": "Holly Flax"},
  {"season": 7, "episode": 14, "scene": 24, "line": "That is the stupidest idea I've ever heard. No. Tap into your common mind and tell us what he would do next.", "order": 40876, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 14, "scene": 24, "line": "Look, I'm not playing. I'm gonna go look for him.", "order": 40877, "speaker": "Holly Flax"},
  {"season": 7, "episode": 14, "scene": 24, "line": "Good. We don't need her.", "order": 40878, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 14, "scene": 24, "line": "Right. I can do this on my own. I can think like Michael. All right... I'm deep below the ocean's surface in a submarine. A torpedo's coming right at me. No. Damn it, that's just my own imagination. Maybe he's bowling.", "order": 40879, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 14, "scene": 25, "line": "[walks to the roof of a building and spots Michael] Michael?", "order": 40880, "speaker": "Holly Flax"},
  {"season": 7, "episode": 14, "scene": 25, "line": "Hi. [laughs] How did you know I was up here?", "order": 40881, "speaker": "Michael Scott"},
  {"season": 7, "episode": 14, "scene": 25, "line": "What are you doing up here?", "order": 40882, "speaker": "Holly Flax"},
  {"season": 7, "episode": 14, "scene": 25, "line": "I got turned around. I thought I could see Dunder Mifflin.", "order": 40883, "speaker": "Michael Scott"},
  {"season": 7, "episode": 14, "scene": 25, "line": "Dunder Mifflin.", "order": 40884, "speaker": "Holly Flax"},
  {"season": 7, "episode": 14, "scene": 25, "line": "Yeah. [laughs] Wow. I just miss you so much.", "order": 40885, "speaker": "Michael Scott"},
  {"season": 7, "episode": 14, "scene": 25, "line": "I missed you too.", "order": 40886, "speaker": "Holly Flax"},
  {"season": 7, "episode": 14, "scene": 25, "line": "Really?", "order": 40887, "speaker": "Michael Scott"},
  {"season": 7, "episode": 14, "scene": 25, "line": "Yeah.", "order": 40888, "speaker": "Holly Flax"},
  {"season": 7, "episode": 14, "scene": 25, "line": "Can I kiss you?", "order": 40889, "speaker": "Michael Scott"},
  {"season": 7, "episode": 14, "scene": 25, "line": "Yeah.", "order": 40890, "speaker": "Holly Flax"},
  {"season": 7, "episode": 14, "scene": 25, "line": "Okay.", "order": 40891, "speaker": "Michael Scott"},
  {"season": 7, "episode": 14, "scene": 26, "line": "Maybe that's not the best one. Keep reading.", "order": 40892, "speaker": "Phyllis Vance"},
  {"season": 7, "episode": 14, "scene": 26, "line": "Uh, it was.", "order": 40893, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 14, "scene": 26, "line": "Maybe it wasn't.", "order": 40894, "speaker": "Meredith Palmer"},
  {"season": 7, "episode": 14, "scene": 26, "line": "Oh, thank God. I had a horrible nightmare that I was stuck in America with Gabe.'", "order": 40895, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 14, "scene": 26, "line": "Oh! [laughter]", "order": 40896, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 14, "scene": 26, "line": "No, that's not the one I was thinking of. Keep going.", "order": 40897, "speaker": "Phyllis Vance"},
  {"season": 7, "episode": 14, "scene": 26, "line": "I know what it smells like but I didn't roll in anything. It's from listening to all of Gabe's bull[bleep]. [laughter] 'Isn't this the perfect romantic getaway, Erin? Sitting on a deserted island wearing dog costumes? I'm Gabe and I'm a weirdo.'", "order": 40898, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 14, "scene": 26, "line": "[laughs]", "order": 40899, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 14, "scene": 26, "line": "Gabe's mom... hmm... Gabe's mom? Wait. Tall woman? Looks like Gabe? Yeah, I banged her.'", "order": 40900, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 14, "scene": 26, "line": "Yeah, there you go. [laughter]", "order": 40901, "speaker": "Phyllis Vance"},
  {"season": 7, "episode": 15, "scene": 1, "line": "Hey. How you doing?", "order": 40902, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 15, "scene": 1, "line": "Thinking about my grandmother a lot.", "order": 40903, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 15, "scene": 1, "line": "Yeah.", "order": 40904, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 15, "scene": 1, "line": "She was about to turn 97.", "order": 40905, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 15, "scene": 1, "line": "At least she lived a very long and I'm sure, a very happy life. Got you this card. When you're ready. We all signed it. We just wanted you to know we're thinking about you.", "order": 40906, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 15, "scene": 1, "line": "Thank you. 'Congratulations, Darryl. Let's get wasted.' 'Have fun today, big guy.' 'Aww, yeah. Party time. Whoo-whoop.'", "order": 40907, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 15, "scene": 1, "line": "It's possible that some people thought it was your birthday.", "order": 40908, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 15, "scene": 1, "line": "Hooray. Live it up big D. ' 'Days like this don't come often enough. Time to celebrate. You deserve this'?", "order": 40909, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 15, "scene": 1, "line": "Birthday punches! [softly punching Darryl in the stomach] One two three four five sice seven eight!", "order": 40910, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 15, "scene": 1, "line": "I'm so sorry.", "order": 40911, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 15, "scene": 1, "line": "[tears welling up in his eyes]", "order": 40912, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 15, "scene": 1, "line": "...thirteen fourteen fifteen sixteen seventeen eighteen nineteen twenty twenty-one twenty-two twenty-three twenty-four twenty-five twenty-six!...", "order": 40913, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 15, "scene": 2, "line": "...So I got an early res at Blue Wasabi and I'm taking Rachel. She's picking me up after work.", "order": 40914, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 15, "scene": 2, "line": "Rachel from the party?", "order": 40915, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 15, "scene": 2, "line": "Oh, yeah!", "order": 40916, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 15, "scene": 2, "line": "Nice. Do the damn thing. - Pow! [they fist bump and explode]", "order": 40917, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 15, "scene": 2, "line": "Blue Wasabi is so good, but get the cheeseburger. They say they won't do it, but they will if you make a scene.", "order": 40918, "speaker": "Phyllis Vance"},
  {"season": 7, "episode": 15, "scene": 2, "line": "Okay, bye. Okay, bye. [kisses Michael] I'll miss you.", "order": 40919, "speaker": "Holly Flax"},
  {"season": 7, "episode": 15, "scene": 2, "line": "Miss you. Okay. I'll be in my office if you need me.", "order": 40920, "speaker": "Michael Scott"},
  {"season": 7, "episode": 15, "scene": 2, "line": "Bye.", "order": 40921, "speaker": "Holly Flax"},
  {"season": 7, "episode": 15, "scene": 2, "line": "Okay, bye. Bye. All right, bye. Bye... bye.", "order": 40922, "speaker": "Michael Scott"},
  {"season": 7, "episode": 15, "scene": 2, "line": "Okay, one more quick one.", "order": 40923, "speaker": "Holly Flax"},
  {"season": 7, "episode": 15, "scene": 2, "line": "Don't go. I don't want you to go. Oh don't go. Don't go.", "order": 40924, "speaker": "Michael Scott"},
  {"season": 7, "episode": 15, "scene": 3, "line": "Holly and I are dating. It's been a week, and I still can't believe it. [sighs] It goes to show that everything you want in life, you get. And you can't work for it. It just comes to you.", "order": 40925, "speaker": "Michael Scott"},
  {"season": 7, "episode": 15, "scene": 4, "line": "And what do we have here?", "order": 40926, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 15, "scene": 4, "line": "From my secret admirer. That's you!", "order": 40927, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 15, "scene": 4, "line": "Come on.", "order": 40928, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 15, "scene": 5, "line": "I dominate Valentine's Day. I practically make romance into a science.", "order": 40929, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 15, "scene": 6, "line": "Roses are red. Violets are blue. Look in the vase to find your first clue.' Oh, my gosh. It's a romantic scavenger hunt!", "order": 40930, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 15, "scene": 6, "line": "Oh, close. It's a romantic treasure hunt. A scavenger hunt is where you find items from a master list. This is where you follow clues that lead to a prize. Common misuse. Good luck.", "order": 40931, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 15, "scene": 6, "line": "But, there's no clue.", "order": 40932, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 15, "scene": 6, "line": "Well, get to work.", "order": 40933, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 15, "scene": 6, "line": "I can't, 'cause there's no clue.", "order": 40934, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 15, "scene": 6, "line": "The puzzle is the clue.", "order": 40935, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 15, "scene": 6, "line": "Oh.", "order": 40936, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 15, "scene": 7, "line": "[clears throat]", "order": 40937, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 15, "scene": 7, "line": "[rubbing hands with Holly] Are these numbers correct?", "order": 40938, "speaker": "Michael Scott"},
  {"season": 7, "episode": 15, "scene": 7, "line": "The numbers are correct, Michael.", "order": 40939, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 15, "scene": 7, "line": "Okay.", "order": 40940, "speaker": "Michael Scott"},
  {"season": 7, "episode": 15, "scene": 7, "line": "[clears throat more loudly]", "order": 40941, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 15, "scene": 7, "line": "Are you... are you sick, Oscar? Do you need to go home? 'Cause you're grossing me out.", "order": 40942, "speaker": "Michael Scott"},
  {"season": 7, "episode": 15, "scene": 7, "line": "I jus... I just need a signature.", "order": 40943, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 15, "scene": 8, "line": "[getting out of a taxi] There you go. Thanks.", "order": 40944, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 15, "scene": 8, "line": "How much did you give him?", "order": 40945, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 15, "scene": 8, "line": "Big tip.", "order": 40946, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 15, "scene": 8, "line": "Wait. I don't have my scarf.", "order": 40947, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 15, "scene": 8, "line": "What?", "order": 40948, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 15, "scene": 8, "line": "I'm missing my scarf. Oh. [both laugh]", "order": 40949, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 15, "scene": 9, "line": "We decided to have a Valentine's day lunch and then that way we can spend the entire night with Cece and avoid the Valentine's day dinner thing. It's a whole thing.", "order": 40950, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 15, "scene": 9, "line": "[loudly] Yeah, lunch was wonderful!", "order": 40951, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 15, "scene": 9, "line": "Shh.", "order": 40952, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 15, "scene": 9, "line": "Um, there was an amazing buffet and there was a chocolate fondue... station. And what else was there?", "order": 40953, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 15, "scene": 9, "line": "Um, bottomless champagne.", "order": 40954, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 15, "scene": 9, "line": "Yes. Never found that bottom, did we?", "order": 40955, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 15, "scene": 9, "line": "No.", "order": 40956, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 15, "scene": 10, "line": "Psst! Psst!", "order": 40957, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 15, "scene": 10, "line": "What's up?", "order": 40958, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 15, "scene": 10, "line": "Are you good at jigsaw puzzles?", "order": 40959, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 15, "scene": 10, "line": "Are you kidding me? It's all I did every summer when my brothers were out sailing.", "order": 40960, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 15, "scene": 10, "line": "Okay.", "order": 40961, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 15, "scene": 10, "line": "But if there is a romantic thing with Gabe, I should probably...", "order": 40962, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 15, "scene": 10, "line": "Oh! No, no, no, no. It's not weird at all. You have your new girlfriend. I have Gabe. This is just as friends.", "order": 40963, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 15, "scene": 10, "line": "Well, okay then.", "order": 40964, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 15, "scene": 10, "line": "Yes!", "order": 40965, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 15, "scene": 10, "line": "At least until Stanley calms down.", "order": 40966, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 15, "scene": 10, "line": "Oh, is he...", "order": 40967, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 15, "scene": 10, "line": "D...d...d...d...don't look. I accidentally did his Sudoku. [Stanley glares at Andy]", "order": 40968, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 15, "scene": 10, "line": "Andy.", "order": 40969, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 15, "scene": 11, "line": "[on phone] All right, I will fax over it to you. Whoop. I will fax it over to you. Okay. Thank you. [chuckles]", "order": 40970, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 15, "scene": 11, "line": "Okay, my name is j...j...j...j...j...j...j...j Jim Halpert and I will fax it in to you... under you. I'll fax it under you. Oh, excuse me. I just p...p...p...p pissed my pants. Not really. I didn't really. [clears throat]", "order": 40971, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 15, "scene": 11, "line": "[goes to hand Dwight a valentines card] Wait.", "order": 40972, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 15, "scene": 11, "line": "You're just filling that out right now. That wasn't meant for me. I will not be your Valentine.", "order": 40973, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 15, "scene": 11, "line": "Aww, nuts. [laughs]", "order": 40974, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 15, "scene": 12, "line": "I don't know if anyone else feels this way and don't get me wrong, I love Michael and Holly, and maybe I'm being overly sensitive, but... the PDA.", "order": 40975, "speaker": "Angela Martin"},
  {"season": 7, "episode": 15, "scene": 12, "line": "Yes! The freaking PDA!", "order": 40976, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 15, "scene": 12, "line": "Uh-huh.", "order": 40977, "speaker": "Angela Martin"},
  {"season": 7, "episode": 15, "scene": 12, "line": "Thank you! I mean, I'm thrilled for them.", "order": 40978, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 15, "scene": 12, "line": "No one is more thrilled for them than I am...", "order": 40979, "speaker": "Angela Martin"},
  {"season": 7, "episode": 15, "scene": 12, "line": "Yes, yes, absolutely.", "order": 40980, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 15, "scene": 12, "line": "...but it's totally inappropriate.", "order": 40981, "speaker": "Angela Martin"},
  {"season": 7, "episode": 15, "scene": 12, "line": "Yeah, it's a little much.", "order": 40982, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 15, "scene": 12, "line": "Mm-hmm.", "order": 40983, "speaker": "Phyllis Vance"},
  {"season": 7, "episode": 15, "scene": 12, "line": "Obviously, so happy for them both.", "order": 40984, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 15, "scene": 12, "line": "So happy. Yeah.", "order": 40985, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 15, "scene": 12, "line": "I don't know, guys. I, for one, enjoy watching them, because...", "order": 40986, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 15, "scene": 12, "line": "No, stop! Just don't.", "order": 40987, "speaker": "Angela Martin"},
  {"season": 7, "episode": 15, "scene": 12, "line": "Kevin!", "order": 40988, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 15, "scene": 12, "line": "Can I finish? Can I finish? Is that okay? I was saying...I enjoy watching them because it makes me horny.", "order": 40989, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 15, "scene": 12, "line": "[groans]", "order": 40990, "speaker": "Everyone"},
  {"season": 7, "episode": 15, "scene": 12, "line": "God! Kevin!", "order": 40991, "speaker": "Angela Martin"},
  {"season": 7, "episode": 15, "scene": 13, "line": "So tell me about your new girlfriend.", "order": 40992, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 15, "scene": 13, "line": "She's neat. Uh, I met her at Darryl's cousin's party. We were the only two white people there.", "order": 40993, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 15, "scene": 13, "line": "Aww, cute.", "order": 40994, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 15, "scene": 13, "line": "Hey, look at that. Done.", "order": 40995, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 15, "scene": 13, "line": "That's Gabe's boom box. Come on. We have to go find it.", "order": 40996, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 15, "scene": 13, "line": "I wish you well on your quest.", "order": 40997, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 15, "scene": 13, "line": "No, come on.", "order": 40998, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 15, "scene": 13, "line": "Aww, I mean...", "order": 40999, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 15, "scene": 13, "line": "[growling] Come on!", "order": 41000, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 15, "scene": 13, "line": "Okay.", "order": 41001, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 15, "scene": 13, "line": "Ha!", "order": 41002, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 15, "scene": 14, "line": "[to Toby] I hear them kissing all the time. It's this horrible plipping sound. You know, it's like Chinese water torture. Just...[makes popping sound] like it's coming from my own head.", "order": 41003, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 15, "scene": 14, "line": "Sit. Okay, just close your eyes. [massaging Holly's back]", "order": 41004, "speaker": "Michael Scott"},
  {"season": 7, "episode": 15, "scene": 14, "line": "Ohh. Can you get lower?", "order": 41005, "speaker": "Holly Flax"},
  {"season": 7, "episode": 15, "scene": 14, "line": "Oh, yeah.", "order": 41006, "speaker": "Michael Scott"},
  {"season": 7, "episode": 15, "scene": 14, "line": "A little lower.", "order": 41007, "speaker": "Holly Flax"},
  {"season": 7, "episode": 15, "scene": 15, "line": "I cannot keep myself from Michael. Everything he does is sexy. He has this undeniable animal magnetism. He's a jungle cat. The man exudes sex. He can put both his legs behind his head.", "order": 41008, "speaker": "Holly Flax"},
  {"season": 7, "episode": 15, "scene": 16, "line": "Thank you all for meeting on such sort notice.", "order": 41009, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 15, "scene": 16, "line": "[clears throat]", "order": 41010, "speaker": "Michael Scott"},
  {"season": 7, "episode": 15, "scene": 16, "line": "Yes?", "order": 41011, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 15, "scene": 16, "line": "[sitting in Holly's lap] Yes. Michael Scott, Dunder Mifflin. What is the purpose of this meeting?", "order": 41012, "speaker": "Michael Scott"},
  {"season": 7, "episode": 15, "scene": 16, "line": "Seeing as it's Valentine's Day, I felt it would be apropos to have a little refresher course on romance in the workplace.", "order": 41013, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 15, "scene": 16, "line": "Perfect. Yes. It is quite apro-propriate. Carry on.", "order": 41014, "speaker": "Michael Scott"},
  {"season": 7, "episode": 15, "scene": 16, "line": "First things first. An office romance is permissible. Not something you'd expect to hear from corporate. Well, guess what. Sabre is 100% tolerant of office romances.", "order": 41015, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 15, "scene": 16, "line": "Speaking of, Gabe, I don't see Erin at this meeting.", "order": 41016, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 15, "scene": 16, "line": "Uh, I also don't see Andy.", "order": 41017, "speaker": "Meredith Palmer"},
  {"season": 7, "episode": 15, "scene": 16, "line": "Oooh.", "order": 41018, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 15, "scene": 16, "line": "OOH! Boom! Face!", "order": 41019, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 15, "scene": 16, "line": "I get it. Andy's slamming my girlfriend. Very funny. What I do not approve of, however, is public displays of affection, or PDAs. PDAs are totally superfluous to a happy, healthy office romance. Perfect example. Look at Jim and Pam.", "order": 41020, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 15, "scene": 16, "line": "Yeah. What? Us?", "order": 41021, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 15, "scene": 16, "line": "They don't touch. They don't kiss. You would hardly even know that they were husband and wife.", "order": 41022, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 15, "scene": 16, "line": "Did it!", "order": 41023, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 15, "scene": 16, "line": "Yeah!", "order": 41024, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 15, "scene": 16, "line": "Love it. Keep it goin.", "order": 41025, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 15, "scene": 16, "line": "Okay. So, PDAs. That's gonna include behaviors such as hugging, kissing, uh...", "order": 41026, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 15, "scene": 16, "line": "Booby honking.", "order": 41027, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 15, "scene": 16, "line": "Yeah, booby honking. Sure.", "order": 41028, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 15, "scene": 16, "line": "Butt honking.", "order": 41029, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 15, "scene": 16, "line": "Butt honking. Yeah, all the honking.", "order": 41030, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 15, "scene": 16, "line": "The one where you start in a crouches position, then you leap...", "order": 41031, "speaker": "Creed Bratton"},
  {"season": 7, "episode": 15, "scene": 16, "line": "Sure. Basically, there's no precise definition, but you know it when you see it. Ahem.", "order": 41032, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 15, "scene": 16, "line": "Are you guys listening to this?", "order": 41033, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 15, "scene": 16, "line": "What, are you talking about me and Holly?", "order": 41034, "speaker": "Michael Scott"},
  {"season": 7, "episode": 15, "scene": 16, "line": "Yeah!", "order": 41035, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 15, "scene": 16, "line": "Maybe you find your own chair.", "order": 41036, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 15, "scene": 17, "line": "So we kiss occasionally.", "order": 41037, "speaker": "Holly Flax"},
  {"season": 7, "episode": 15, "scene": 17, "line": "Not occasionally. All the time.", "order": 41038, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 15, "scene": 17, "line": "Yeah, and it's not just the kissing. It's the flirtatious whispering.", "order": 41039, "speaker": "Phyllis Vance"},
  {"season": 7, "episode": 15, "scene": 17, "line": "The flirtatious tickling.", "order": 41040, "speaker": "Stanley Hudson"},
  {"season": 7, "episode": 15, "scene": 17, "line": "Whispering and tickling have their place in business.", "order": 41041, "speaker": "Michael Scott"},
  {"season": 7, "episode": 15, "scene": 17, "line": "That thing were you softly exhale on her neck.", "order": 41042, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 15, "scene": 17, "line": "I... Okay, yeah, that's pretty hot. I will give you that. Look, you are all hypocrites. You all do it.", "order": 41043, "speaker": "Michael Scott"},
  {"season": 7, "episode": 15, "scene": 17, "line": "Michael. Confession: I have done PDA in the office.", "order": 41044, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 15, "scene": 17, "line": "Thank you.", "order": 41045, "speaker": "Michael Scott"},
  {"season": 7, "episode": 15, "scene": 17, "line": "I've had intercourse in the office.", "order": 41046, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 15, "scene": 17, "line": "All right.", "order": 41047, "speaker": "Michael Scott"},
  {"season": 7, "episode": 15, "scene": 17, "line": "As has Angela!", "order": 41048, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 15, "scene": 17, "line": "Dwight!", "order": 41049, "speaker": "Angela Martin"},
  {"season": 7, "episode": 15, "scene": 17, "line": "As has Ryan. As has Kelly. As has Meredith. As has Phyllis. As has Darryl. As has Creed. [Creed smiles and nods] As has Michael and as has Holly.", "order": 41050, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 15, "scene": 17, "line": "As has Kevin!", "order": 41051, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 15, "scene": 17, "line": "With who?", "order": 41052, "speaker": "Angela Martin"},
  {"season": 7, "episode": 15, "scene": 17, "line": "She goes to another school.", "order": 41053, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 15, "scene": 17, "line": "Okay. Fine. No more PDA.", "order": 41054, "speaker": "Michael Scott"},
  {"season": 7, "episode": 15, "scene": 17, "line": "Good.", "order": 41055, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 15, "scene": 17, "line": "You win. But here's what we are going to do. We are going to designate one of our closets as a hook-up zone.", "order": 41056, "speaker": "Michael Scott"},
  {"season": 7, "episode": 15, "scene": 17, "line": "No.", "order": 41057, "speaker": "Angela Martin"},
  {"season": 7, "episode": 15, "scene": 17, "line": "Anything goes.", "order": 41058, "speaker": "Michael Scott"},
  {"season": 7, "episode": 15, "scene": 17, "line": "Just leave it how you said...", "order": 41059, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 15, "scene": 17, "line": "Michael, that's absurd. What if two couples want to go at the same time?", "order": 41060, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 15, "scene": 17, "line": "Okay, then we have two closets. No, no, we're gonna have three closets, one for each base, no homers. Yes?", "order": 41061, "speaker": "Michael Scott"},
  {"season": 7, "episode": 15, "scene": 17, "line": "And what is the hook-up zone policy on masturbation?", "order": 41062, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 15, "scene": 17, "line": "Pro.", "order": 41063, "speaker": "Michael Scott"},
  {"season": 7, "episode": 15, "scene": 17, "line": "Yes!", "order": 41064, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 15, "scene": 17, "line": "What? Problem solved.", "order": 41065, "speaker": "Michael Scott"},
  {"season": 7, "episode": 15, "scene": 18, "line": "No, no, it's great. I love working at Dunder Mifflin, a division of Taliban enterprises.", "order": 41066, "speaker": "Michael Scott"},
  {"season": 7, "episode": 15, "scene": 19, "line": "That's weird. I mean, that's weird to have sex in the office. That's where you work, right?", "order": 41067, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 15, "scene": 19, "line": "Mm-hmm.", "order": 41068, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 15, "scene": 19, "line": "You know, you're in a meeting, and nobody knows that you've had sex there, except for you and him.", "order": 41069, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 15, "scene": 19, "line": "[snorts] Ew.", "order": 41070, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 15, "scene": 20, "line": "Don't mind us. You keep working.", "order": 41071, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 15, "scene": 20, "line": "Okay. I'll keep working.", "order": 41072, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 15, "scene": 20, "line": "Oh, my God. It's a song.", "order": 41073, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 15, "scene": 20, "line": "Whoa.", "order": 41074, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 15, "scene": 20, "line": "What do you think is on it?", "order": 41075, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 15, "scene": 20, "line": "[softy] We should actually probably talk about it outside or we might make Darryl mad.", "order": 41076, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 15, "scene": 20, "line": "[softly] Not if we're talking like this. I think it's okay. He doesn't seem to hear us.", "order": 41077, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 15, "scene": 20, "line": "I think he can hear us.", "order": 41078, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 15, "scene": 20, "line": "[softly] That makes sense, 'cause I feel like I'd be able to hear us at this level.", "order": 41079, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 15, "scene": 20, "line": "Darryl.", "order": 41080, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 15, "scene": 20, "line": "Darryl is a jerk.", "order": 41081, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 15, "scene": 20, "line": "No, he's not.", "order": 41082, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 15, "scene": 20, "line": "No, I'm just testing if he can hear.", "order": 41083, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 15, "scene": 20, "line": "I can hear you.", "order": 41084, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 15, "scene": 20, "line": "Oh. Okay.", "order": 41085, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 15, "scene": 20, "line": "Okay. Then no more talking. Let's just play the song.", "order": 41086, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 15, "scene": 20, "line": "[Gabe singing] The temp at night. The temp at night. The temp at night. The temp at night.", "order": 41087, "speaker": "Boom Box"},
  {"season": 7, "episode": 15, "scene": 20, "line": "The temp at night?", "order": 41088, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 15, "scene": 20, "line": "What do you...", "order": 41089, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 15, "scene": 20, "line": "You got it. Pick it up. Take it out.", "order": 41090, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 15, "scene": 20, "line": "Pi...", "order": 41091, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 15, "scene": 20, "line": "Pick it up.", "order": 41092, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 15, "scene": 20, "line": "Okay.", "order": 41093, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 15, "scene": 20, "line": "Take it out. Pick it up. Pick it up. Pick it up. Take it out.", "order": 41094, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 15, "scene": 20, "line": "Right. Okay.", "order": 41095, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 15, "scene": 21, "line": "Sorry to interrupt. Forgot to collect my things.", "order": 41096, "speaker": "Holly Flax"},
  {"season": 7, "episode": 15, "scene": 21, "line": "Ah, the old 'leave behind.' Oldest trick in the book. Good thing it's working.", "order": 41097, "speaker": "Michael Scott"},
  {"season": 7, "episode": 15, "scene": 21, "line": "Oh, no. Michael, I don't want to make people uncomfortable.", "order": 41098, "speaker": "Holly Flax"},
  {"season": 7, "episode": 15, "scene": 21, "line": "Since when is comfort the most important thing? I mean, I don't... I don't sit on your lap because it's comfortable. I sit on your lap because I like the way your thighs feel on my butt.", "order": 41099, "speaker": "Michael Scott"},
  {"season": 7, "episode": 15, "scene": 21, "line": "Yeah, but if they're uncomfortable, it makes me uncomfortable.", "order": 41100, "speaker": "Holly Flax"},
  {"season": 7, "episode": 15, "scene": 21, "line": "Oh. Okay. Well, if it makes you uncomfortable, then we'll stop.", "order": 41101, "speaker": "Michael Scott"},
  {"season": 7, "episode": 15, "scene": 21, "line": "Okay.", "order": 41102, "speaker": "Holly Flax"},
  {"season": 7, "episode": 15, "scene": 22, "line": "Holly and I are like Romeo and Juliet. And this office is like the dragon that kept them apart.", "order": 41103, "speaker": "Michael Scott"},
  {"season": 7, "episode": 15, "scene": 23, "line": "Hey, you know why people here are complaining? They are jealous of two people in love on Valentine's Day.", "order": 41104, "speaker": "Michael Scott"},
  {"season": 7, "episode": 15, "scene": 23, "line": "Two people in love?", "order": 41105, "speaker": "Holly Flax"},
  {"season": 7, "episode": 15, "scene": 23, "line": "I love you.", "order": 41106, "speaker": "Michael Scott"},
  {"season": 7, "episode": 15, "scene": 23, "line": "Wait, wait, wait, what do you mean you love me? We've only been dating for a week. Do you mean you love me like, 'oh, hey, there's Holly. I love that girl.' Or you do you mean you love me like you love me-love me?", "order": 41107, "speaker": "Holly Flax"},
  {"season": 7, "episode": 15, "scene": 23, "line": "I love you-love you.", "order": 41108, "speaker": "Michael Scott"},
  {"season": 7, "episode": 15, "scene": 23, "line": "Wow, you love me-love me. I love you-love you. [both laugh]", "order": 41109, "speaker": "Holly Flax"},
  {"season": 7, "episode": 15, "scene": 23, "line": "I am really disappointed in the office's policy on PDA at this moment. I love you. [shakes Holly's hand]", "order": 41110, "speaker": "Michael Scott"},
  {"season": 7, "episode": 15, "scene": 23, "line": "I love you.", "order": 41111, "speaker": "Holly Flax"},
  {"season": 7, "episode": 15, "scene": 24, "line": "Yeah, whatever. No big deal. Just the hottest girl in the world loves me.", "order": 41112, "speaker": "Michael Scott"},
  {"season": 7, "episode": 15, "scene": 25, "line": "Okay, Ryan's the temp. What does he do at night?", "order": 41113, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 15, "scene": 25, "line": "I guess we just have to wait until night and see.", "order": 41114, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 15, "scene": 25, "line": "Oh, or maybe not. [turns out the lights]", "order": 41115, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 15, "scene": 25, "line": "[gasps] Oh. Cool.", "order": 41116, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 15, "scene": 25, "line": "How romantic is this?", "order": 41117, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 15, "scene": 25, "line": "Super romantic.", "order": 41118, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 15, "scene": 25, "line": "Right. Do you see a clue or...", "order": 41119, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 15, "scene": 25, "line": "Yeah. Did you not see it? ['Eat at Hanks' is written in glow-in-the-dark letters above the door]", "order": 41120, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 15, "scene": 25, "line": "Oh, my gosh.", "order": 41121, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 15, "scene": 25, "line": "Hanks.", "order": 41122, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 15, "scene": 26, "line": "Hey!", "order": 41123, "speaker": "Jim Halpert & Pam Halpert"},
  {"season": 7, "episode": 15, "scene": 26, "line": "[bursting out of Ryan's closet] Hey.", "order": 41124, "speaker": "Andy Bernard & Erin Hannon"},
  {"season": 7, "episode": 15, "scene": 26, "line": "No time to chat.", "order": 41125, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 15, "scene": 26, "line": "Sorry.", "order": 41126, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 15, "scene": 26, "line": "Well, we were just looking for Ryan, so he's not here.", "order": 41127, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 15, "scene": 26, "line": "Hey. You're looking for me?", "order": 41128, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 15, "scene": 26, "line": "Oh, yeah, we were. We were just looking to say hi. So hey.", "order": 41129, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 15, "scene": 26, "line": "No you weren't.", "order": 41130, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 15, "scene": 26, "line": "No, we weren't.", "order": 41131, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 15, "scene": 26, "line": "You wanted to have sex in my office.", "order": 41132, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 15, "scene": 26, "line": "No.", "order": 41133, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 15, "scene": 26, "line": "Definitely not. That's disgusting.", "order": 41134, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 15, "scene": 26, "line": "No. It's... it's cool. Just try to put everything back were you found it. Text me when you're finished. I'll be out here.", "order": 41135, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 15, "scene": 26, "line": "Hey, you don't have to...", "order": 41136, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 15, "scene": 26, "line": "That was so embarrassing. I'm gonna die.", "order": 41137, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 15, "scene": 26, "line": "That was really rough.", "order": 41138, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 15, "scene": 27, "line": "Let me make something clear. Jim and I have never and will never have sex in the office.", "order": 41139, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 15, "scene": 27, "line": "No, because the office isn't what I'd consider a romantic place.", "order": 41140, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 15, "scene": 27, "line": "Besides, we have something those other people don't have, which is a home and a bed.", "order": 41141, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 15, "scene": 27, "line": "And a shower.", "order": 41142, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 15, "scene": 28, "line": "Hello, Holly.", "order": 41143, "speaker": "Michael Scott"},
  {"season": 7, "episode": 15, "scene": 28, "line": "Hello.", "order": 41144, "speaker": "Holly Flax"},
  {"season": 7, "episode": 15, "scene": 28, "line": "Here, shake. No!", "order": 41145, "speaker": "Michael Scott"},
  {"season": 7, "episode": 15, "scene": 28, "line": "No, don't touch.", "order": 41146, "speaker": "Holly Flax"},
  {"season": 7, "episode": 15, "scene": 28, "line": "No, we can't.", "order": 41147, "speaker": "Michael Scott"},
  {"season": 7, "episode": 15, "scene": 28, "line": "No touching.", "order": 41148, "speaker": "Holly Flax"},
  {"season": 7, "episode": 15, "scene": 28, "line": "No touching. Ah, I'm trying not to touch you.", "order": 41149, "speaker": "Michael Scott"},
  {"season": 7, "episode": 15, "scene": 28, "line": "Oh.", "order": 41150, "speaker": "Holly Flax"},
  {"season": 7, "episode": 15, "scene": 28, "line": "Oh.", "order": 41151, "speaker": "Michael Scott"},
  {"season": 7, "episode": 15, "scene": 28, "line": "Almost got you.", "order": 41152, "speaker": "Holly Flax"},
  {"season": 7, "episode": 15, "scene": 28, "line": "Oh, no, so close. Oh. No, we can't do that. We can't do this. Ohh.", "order": 41153, "speaker": "Michael Scott"},
  {"season": 7, "episode": 15, "scene": 28, "line": "You're not touching me.", "order": 41154, "speaker": "Holly Flax"},
  {"season": 7, "episode": 15, "scene": 28, "line": "I don't know what your body feels like 'cause I can't touch it.", "order": 41155, "speaker": "Michael Scott"},
  {"season": 7, "episode": 15, "scene": 28, "line": "Okay, this is much worse than before.", "order": 41156, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 15, "scene": 28, "line": "Yeah, I agree. This is nasty.", "order": 41157, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 15, "scene": 28, "line": "Grab you here.", "order": 41158, "speaker": "Michael Scott"},
  {"season": 7, "episode": 15, "scene": 28, "line": "And here.", "order": 41159, "speaker": "Holly Flax"},
  {"season": 7, "episode": 15, "scene": 28, "line": "I will grab you here anyway. [laughs]", "order": 41160, "speaker": "Michael Scott"},
  {"season": 7, "episode": 15, "scene": 29, "line": "You have to cut it out.", "order": 41161, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 15, "scene": 29, "line": "Okay.", "order": 41162, "speaker": "Holly Flax"},
  {"season": 7, "episode": 15, "scene": 29, "line": "Never.", "order": 41163, "speaker": "Michael Scott"},
  {"season": 7, "episode": 15, "scene": 29, "line": "You said you would.", "order": 41164, "speaker": "Holly Flax"},
  {"season": 7, "episode": 15, "scene": 29, "line": "I did say I would, but I was wrong. I can't stop. You don't what it is like to love a woman and have her love you back. It is a feeling that cannot and will not, and frankly, cannot be contained.", "order": 41165, "speaker": "Michael Scott"},
  {"season": 7, "episode": 15, "scene": 29, "line": "No one is a bigger fan of sexual touching than me. But with you two, it's reaching the level of a complex. I can think of three explanations. One: You're taking MDMA. Street name, ecstasy. I've done it, and I know the feeling.", "order": 41166, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 15, "scene": 29, "line": "No, I don't think so.", "order": 41167, "speaker": "Holly Flax"},
  {"season": 7, "episode": 15, "scene": 29, "line": "Number two: You're desperately trying to fit in a whole relationship before Toby comes back from jury duty and Holly has to go back to Nashua. Or three: You're at an age where your sexual climaxes aren't as powerful so you need to overcompensate with foreplay, taboo behavior.", "order": 41168, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 15, "scene": 29, "line": "Yes, it was that. It's that one.", "order": 41169, "speaker": "Michael Scott"},
  {"season": 7, "episode": 15, "scene": 29, "line": "Yes. Number three.", "order": 41170, "speaker": "Holly Flax"},
  {"season": 7, "episode": 15, "scene": 30, "line": "Give me all your money and take off your clothes.", "order": 41171, "speaker": "Holly Flax"},
  {"season": 7, "episode": 15, "scene": 30, "line": "We're not supposed to PDA.", "order": 41172, "speaker": "Michael Scott"},
  {"season": 7, "episode": 15, "scene": 30, "line": "Come one. Nobody's looking. What's wrong?", "order": 41173, "speaker": "Holly Flax"},
  {"season": 7, "episode": 15, "scene": 30, "line": "I haven't thought about you having to go back to Nashua.", "order": 41174, "speaker": "Michael Scott"},
  {"season": 7, "episode": 15, "scene": 30, "line": "Maybe we didn't want to think about it. We can make it work. We'll date long-distance.", "order": 41175, "speaker": "Holly Flax"},
  {"season": 7, "episode": 15, "scene": 30, "line": "That's what we said last time. Remember? We broke up on the drive. How's this gonna be any different?", "order": 41176, "speaker": "Michael Scott"},
  {"season": 7, "episode": 15, "scene": 30, "line": "We weren't in love last time.", "order": 41177, "speaker": "Holly Flax"},
  {"season": 7, "episode": 15, "scene": 30, "line": "I was in love with you.", "order": 41178, "speaker": "Michael Scott"},
  {"season": 7, "episode": 15, "scene": 30, "line": "I'm not saying it won't be hard. But we can make it work. That's what she said.", "order": 41179, "speaker": "Holly Flax"},
  {"season": 7, "episode": 15, "scene": 30, "line": "[chuckles, sighs]", "order": 41180, "speaker": "Michael Scott"},
  {"season": 7, "episode": 15, "scene": 31, "line": "Oh. One Erin Special, please.", "order": 41181, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 15, "scene": 31, "line": "Here's your bottle of sparkling cider.", "order": 41182, "speaker": "Hank Tate"},
  {"season": 7, "episode": 15, "scene": 31, "line": "Hey.", "order": 41183, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 15, "scene": 31, "line": "[laughs] That's my favorite.", "order": 41184, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 15, "scene": 31, "line": "I'm now supposed to tell you to enjoy a mind-hunt break and look for a heart or some [bleep] in the break room when you're through.", "order": 41185, "speaker": "Hank Tate"},
  {"season": 7, "episode": 15, "scene": 32, "line": "[through the window at Michael] Hey, buck up, buddy!", "order": 41186, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 15, "scene": 32, "line": "Make that face he likes.", "order": 41187, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 15, "scene": 32, "line": "Hey. Hey. Come on. You're hurting him.", "order": 41188, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 15, "scene": 32, "line": "It's like a time bomb...", "order": 41189, "speaker": "Michael Scott"},
  {"season": 7, "episode": 15, "scene": 32, "line": "Shh.", "order": 41190, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 15, "scene": 32, "line": "...is ticking down. When it goes off, no more kissing. No more hugging.", "order": 41191, "speaker": "Michael Scott"},
  {"season": 7, "episode": 15, "scene": 32, "line": "Just hush.", "order": 41192, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 15, "scene": 32, "line": "Sexual time bomb.", "order": 41193, "speaker": "Michael Scott"},
  {"season": 7, "episode": 15, "scene": 32, "line": "Just rock. Shh.", "order": 41194, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 15, "scene": 32, "line": "[into recorder] Boner Bomb starring Jason Statham. Or we go against type with an Eisenberg or a Michael Cera.", "order": 41195, "speaker": "Michael Scott"},
  {"season": 7, "episode": 15, "scene": 32, "line": "Movie idea?", "order": 41196, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 15, "scene": 32, "line": "No. [into recorder] Saving the world has never been this hard.", "order": 41197, "speaker": "Michael Scott"},
  {"season": 7, "episode": 15, "scene": 32, "line": "Okay. Shh. Have you talked to her about your feelings? Women really go for that crap.", "order": 41198, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 15, "scene": 32, "line": "No, I can't talk to her. I can't even look at her without thinking about it ending.", "order": 41199, "speaker": "Michael Scott"},
  {"season": 7, "episode": 15, "scene": 32, "line": "Kevin! Do more stupid faces.", "order": 41200, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 15, "scene": 32, "line": "Which one? I have a lot of stupid faces.", "order": 41201, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 15, "scene": 32, "line": "I don't know.", "order": 41202, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 15, "scene": 32, "line": "Do the monkey face.", "order": 41203, "speaker": "Michael Scott"},
  {"season": 7, "episode": 15, "scene": 32, "line": "Do the monkey face!", "order": 41204, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 15, "scene": 32, "line": "I love that. I love that face.", "order": 41205, "speaker": "Michael Scott"},
  {"season": 7, "episode": 15, "scene": 32, "line": "Good. He loves it. Good. Jump. Jump up and down.", "order": 41206, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 15, "scene": 32, "line": "No. The monkey!", "order": 41207, "speaker": "Michael Scott"},
  {"season": 7, "episode": 15, "scene": 32, "line": "Go back to the monkey!", "order": 41208, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 15, "scene": 32, "line": "Don't do the squirrel. Throw your poop.", "order": 41209, "speaker": "Michael Scott"},
  {"season": 7, "episode": 15, "scene": 32, "line": "Hurl your feces.", "order": 41210, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 15, "scene": 32, "line": "That's good.", "order": 41211, "speaker": "Michael Scott"},
  {"season": 7, "episode": 15, "scene": 32, "line": "Good.", "order": 41212, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 15, "scene": 32, "line": "That's good. He's throwing it.", "order": 41213, "speaker": "Michael Scott"},
  {"season": 7, "episode": 15, "scene": 32, "line": "They really do that.", "order": 41214, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 15, "scene": 32, "line": "I know.", "order": 41215, "speaker": "Michael Scott"},
  {"season": 7, "episode": 15, "scene": 33, "line": "[at vending machine] B-9.", "order": 41216, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 15, "scene": 33, "line": "B-9.", "order": 41217, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 15, "scene": 33, "line": "Like a benign tumor.", "order": 41218, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 15, "scene": 33, "line": "Maybe it's just more like, 'be mine.'", "order": 41219, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 15, "scene": 33, "line": "Oh, yes. There's a note. 'A little cookie for you, my miss. But the real treasure is your Valentine's kiss.'", "order": 41220, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 15, "scene": 33, "line": "Mm.", "order": 41221, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 15, "scene": 33, "line": "Mm. Oh. We're suppose to...mmm [leans in to kiss Andy]", "order": 41222, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 15, "scene": 33, "line": "Hmm. [points to Gabe blowing a kiss to Erin]", "order": 41223, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 15, "scene": 34, "line": "Holly. Hey.", "order": 41224, "speaker": "Michael Scott"},
  {"season": 7, "episode": 15, "scene": 34, "line": "Hi.", "order": 41225, "speaker": "Holly Flax"},
  {"season": 7, "episode": 15, "scene": 34, "line": "Holly, I love you so, so, so much. And I think we need to break up.", "order": 41226, "speaker": "Michael Scott"},
  {"season": 7, "episode": 15, "scene": 34, "line": "What? What are you talking about?", "order": 41227, "speaker": "Holly Flax"},
  {"season": 7, "episode": 15, "scene": 34, "line": "Actually, it's Valentine's Day. Shouldn't be doing this today. I'll talk to you tomorrow.", "order": 41228, "speaker": "Michael Scott"},
  {"season": 7, "episode": 15, "scene": 34, "line": "No, we're gonna talk now. You're not breaking up with me.", "order": 41229, "speaker": "Holly Flax"},
  {"season": 7, "episode": 15, "scene": 34, "line": "You're gonna go back to Nashua eventually, and I can't handle it. So let's just...let's...", "order": 41230, "speaker": "Michael Scott"},
  {"season": 7, "episode": 15, "scene": 34, "line": "What if I said it wasn't up to them?", "order": 41231, "speaker": "Holly Flax"},
  {"season": 7, "episode": 15, "scene": 34, "line": "Who?", "order": 41232, "speaker": "Michael Scott"},
  {"season": 7, "episode": 15, "scene": 34, "line": "The company. Nobody knows our future, but it's not gonna be decided by the company. It's not gonna be decided by anybody but us. What we are is up to you and me.", "order": 41233, "speaker": "Holly Flax"},
  {"season": 7, "episode": 15, "scene": 34, "line": "Okay. Okay.", "order": 41234, "speaker": "Michael Scott"},
  {"season": 7, "episode": 15, "scene": 35, "line": "Ladies and gentlemen, I have an announcement to make. As of today, officially, Holly and I are moving into together. Yay! [claps] Thank you. Thank you. Oscar, this must be tough for you, watching this go down. You could not stand in the way of true love, my friend!", "order": 41235, "speaker": "Michael Scott"},
  {"season": 7, "episode": 15, "scene": 35, "line": "Are you kidding? I wasn't trying to break you guys up.", "order": 41236, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 15, "scene": 35, "line": "Better luck next time, pal.", "order": 41237, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 15, "scene": 35, "line": "Well, the point is, there will no longer be any need for PDA here at the office between us because, when we get home, we are going to make love to and with one another, and that, to me, is the best Valentine's Day present that a person could receive. So I bid you all adieu.", "order": 41238, "speaker": "Michael Scott"},
  {"season": 7, "episode": 15, "scene": 35, "line": "You do?", "order": 41239, "speaker": "Holly Flax"},
  {"season": 7, "episode": 15, "scene": 35, "line": "I do.", "order": 41240, "speaker": "Michael Scott"},
  {"season": 7, "episode": 15, "scene": 35, "line": "Go on. Kiss each other already. [they kiss]", "order": 41241, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 15, "scene": 35, "line": "Suck it, Oscar. This must kill you.", "order": 41242, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 15, "scene": 35, "line": "I just told them to do it!", "order": 41243, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 15, "scene": 36, "line": "What?", "order": 41244, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 15, "scene": 36, "line": "No.", "order": 41245, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 15, "scene": 36, "line": "No. We took a walk.", "order": 41246, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 15, "scene": 36, "line": "We took a walk.", "order": 41247, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 15, "scene": 37, "line": "My perfect Valentine's Day? I'm at home, three cell phones in front of me, fielding desperate calls from people who want to buy one of the 50 restaurant reservations I made over six months ago.", "order": 41248, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 15, "scene": 38, "line": "Anybody can be Prince Charming one day a year with the dinner and the flowers and all that. But you know what impresses me? When a guy can do that no days a year.", "order": 41249, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 15, "scene": 39, "line": "Flowers, diamonds, three-course meal, violinist comes to my table to serenade me...", "order": 41250, "speaker": "Kelly Kapoor"},
  {"season": 7, "episode": 15, "scene": 40, "line": "Pizza, soda, the moon, someone to share it with.", "order": 41251, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 15, "scene": 41, "line": "What are our plans for tonight? Umm...", "order": 41252, "speaker": "Michael Scott"},
  {"season": 7, "episode": 15, "scene": 41, "line": "[imitates a squeaky bed] [laughter]", "order": 41253, "speaker": "Holly Flax"},
  {"season": 7, "episode": 16, "scene": 1, "line": "I got a delivery for ya'.", "order": 41254, "speaker": "Delivery Guy"},
  {"season": 7, "episode": 16, "scene": 1, "line": "Leave it at reception.", "order": 41255, "speaker": "Michael Scarn"},
  {"season": 7, "episode": 16, "scene": 1, "line": "I'm supposed to deliver this one in person. [pulls out a gun and starts shooting at Michael, who dodges dramatically]", "order": 41256, "speaker": "Delivery Guy"},
  {"season": 7, "episode": 16, "scene": 1, "line": "[pulls out two handguns and kills the man with an unnecessarily large amount of bullets] Clean up on aisle five. [Threat Level: Midnight titlescreen appears]", "order": 41257, "speaker": "Michael Scarn"},
  {"season": 7, "episode": 16, "scene": 2, "line": "After three years of writing, one year of shooting, four years of re-shooting and two years of editing, I have finally completed my movie, Threat Level: Midnight.", "order": 41258, "speaker": "Michael Scott"},
  {"season": 7, "episode": 16, "scene": 3, "line": "Erin... what are you doing?", "order": 41259, "speaker": "Michael Scott"},
  {"season": 7, "episode": 16, "scene": 3, "line": "Guys! Guys. Did you guys know that our own Michael Scott has made a movie, and that he maybe will let us watch it, but only if everybody's dying to see it.", "order": 41260, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 16, "scene": 3, "line": "That's... That's. Well, don't put words in my mouth.", "order": 41261, "speaker": "Michael Scott"},
  {"season": 7, "episode": 16, "scene": 4, "line": "Threat Level: Midnight is the great lost film of Michael Scott.", "order": 41262, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 16, "scene": 4, "line": "We're all in it, from like years and years ago. It's like a home movie.", "order": 41263, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 16, "scene": 4, "line": "Yeah, if Michael Scott did your home movie!", "order": 41264, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 16, "scene": 4, "line": "Michael screened a work in progress for us, years ago, and it didn't go well. We thought it was a comedy. [flashback clip of Michael angrily leaving the conference room while the whole office is laughing at the tv] Everything pointed to it being a comedy.", "order": 41265, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 16, "scene": 5, "line": "We'd love to see it!", "order": 41266, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 16, "scene": 5, "line": "Sweet, I will go invite Holly.", "order": 41267, "speaker": "Michael Scott"},
  {"season": 7, "episode": 16, "scene": 5, "line": "Okay everyone, I know we're really excited to see this movie that everybody's in, but we have to remember that Michael is sensitive, so let's stay positive! And no laughing, no comments, just positive energy and we'll have a pure fun day! Okay?", "order": 41268, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 16, "scene": 5, "line": "Thanks mom.", "order": 41269, "speaker": "Creed Bratton"},
  {"season": 7, "episode": 16, "scene": 6, "line": "You never told me you made a movie.", "order": 41270, "speaker": "Holly Flax"},
  {"season": 7, "episode": 16, "scene": 6, "line": "Mhmm! It's got action, it's got heart, it's got some bosom.", "order": 41271, "speaker": "Michael Scott"},
  {"season": 7, "episode": 16, "scene": 6, "line": "It's got you.", "order": 41272, "speaker": "Holly Flax"},
  {"season": 7, "episode": 16, "scene": 6, "line": "It's got a lot of me.", "order": 41273, "speaker": "Michael Scott"},
  {"season": 7, "episode": 16, "scene": 6, "line": "[laughs] I can't wait.", "order": 41274, "speaker": "Holly Flax"},
  {"season": 7, "episode": 16, "scene": 7, "line": "Ladies and gentleman, Threat Level: Midnight. [applause]", "order": 41275, "speaker": "Michael Scott"},
  {"season": 7, "episode": 16, "scene": 8, "line": "[Stanley's voice as the screen shows Scarn Manor] Michael Scarn, well that's an interesting story. [headlines of Michael Scarn's success are shown as well as an article reporting the death of Michael's wife, Catherine Zeta Scarn] He was once the best secret agent in the business. That was years ago. Where is he now? Well, that's also an interesting story.", "order": 41276, "speaker": "Narrator"},
  {"season": 7, "episode": 16, "scene": 8, "line": "[Dwight Schrute dressed as a butler. Speaks in slow, deep voice] Master Scarn.", "order": 41277, "speaker": "Samuel"},
  {"season": 7, "episode": 16, "scene": 9, "line": "I play Samuel, Michael Scarn's robot butler. I wanted Samuel's voice- [robot impression] to be like this! [normally] But Michael thought that Samuel should be a very advance android, almost indistinguishable from a real person.", "order": 41278, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 16, "scene": 10, "line": "Dwight does not play a robot.", "order": 41279, "speaker": "Michael Scott"},
  {"season": 7, "episode": 16, "scene": 11, "line": "[crashes cymbals by Michael's ear]", "order": 41280, "speaker": "Samuel"},
  {"season": 7, "episode": 16, "scene": 11, "line": "[lying on bed with a bottle of alcohol] I'm up.", "order": 41281, "speaker": "Michael Scarn"},
  {"season": 7, "episode": 16, "scene": 11, "line": "It's the president. He needs you for a mission.", "order": 41282, "speaker": "Samuel"},
  {"season": 7, "episode": 16, "scene": 11, "line": "Tell him I'm retired.", "order": 41283, "speaker": "Michael Scarn"},
  {"season": 7, "episode": 16, "scene": 11, "line": "It's Goldenface.", "order": 41284, "speaker": "Samuel"},
  {"season": 7, "episode": 16, "scene": 11, "line": "Goldenface, this makes it personal.", "order": 41285, "speaker": "Michael Scarn"},
  {"season": 7, "episode": 16, "scene": 12, "line": "[Darryl, sitting at a desk in the Oval Office] Scarn, you're right on time. [cheering from the office]", "order": 41286, "speaker": "President"},
  {"season": 7, "episode": 16, "scene": 13, "line": "I gave up a lot of weekends because I thought it'd be good for my daughter to see a black man as president. Even in a silly home movie. What a stupid waste of time.", "order": 41287, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 16, "scene": 14, "line": "It's your old enemy, Goldenface. He's after the NHL All Star Game. He's hidden a bomb somewhere in the stadium. Scarn, this one is personal for me. I own the stadium. I can't see it blown up. It's my retirement plan.", "order": 41288, "speaker": "President"},
  {"season": 7, "episode": 16, "scene": 14, "line": "We have to search the stadium.", "order": 41289, "speaker": "Samuel"},
  {"season": 7, "episode": 16, "scene": 14, "line": "Not so fast, Goldenface has taken all the concession stand workers hostage. Scarn, will you find these hostages, and save the game?", "order": 41290, "speaker": "President"},
  {"season": 7, "episode": 16, "scene": 14, "line": "[holding a quarter up] Heads I do it, tails I don't. Best out of seven. [flips the coin] Heads. [flips it again] Tails. [President winces each time he says tails]...Heads...Tails...Heads...Tails. [flips one more time and the quarter spins around on the table. Michael looks at it] Well, it looks like there's going to be a clean-up on aisle five.", "order": 41291, "speaker": "Michael Scarn"},
  {"season": 7, "episode": 16, "scene": 15, "line": "Well, the hostages were scared.", "order": 41292, "speaker": "Narrator"},
  {"season": 7, "episode": 16, "scene": 16, "line": "[as a hostage] Don't you guys get it? Nobody's coming for us.", "order": 41293, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 16, "scene": 16, "line": "[Jim, with his face painted with metallic gold paint] Oh someone's coming alright, the only man who would care. [Goldenface turns in his chair, holding a golden gun] Michael Scarn. See I'm gonna lure him here, then I kill everybody, then... I'm gonna dig up Scarn's dead wife, and I'm gonna hump her real good. [malicious, deep laughter]", "order": 41294, "speaker": "Goldenface"},
  {"season": 7, "episode": 16, "scene": 17, "line": "I did not love the dialogue. Or the character. I took the role to impress a receptionist who will remain nameless.", "order": 41295, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 16, "scene": 18, "line": "[scene shows Michael Scarn driving through the rain] Well, the All Star Game was three days away, so naturally it was all sold out. The only way Scarn was getting in was in a uniform. Just one problem with that, Scarn didn't know a hockey stick from a Slim Jim. So he went to meet with the famed trainer... [Michael stops the car and gets out] Cherokee Jack.", "order": 41296, "speaker": "Narrator"},
  {"season": 7, "episode": 16, "scene": 19, "line": "[Creed wearing a tunic and carrying a mop, standing with Michael in front of an ice rink] Mop the ice.", "order": 41297, "speaker": "Cherokee Jack"},
  {"season": 7, "episode": 16, "scene": 19, "line": "I'm not here to learn how to mop, I'm here to learn how to play hockey.", "order": 41298, "speaker": "Michael Scarn"},
  {"season": 7, "episode": 16, "scene": 19, "line": "Mop it. [A montage of Michael mopping the ice awkwardly and using an ab-cruncher, and slowly getting better. After a little while, Cherokee Jack Slams a hockey stick on the ice to stop Michael, then offers it to him] Now take this.", "order": 41299, "speaker": "Cherokee Jack"},
  {"season": 7, "episode": 16, "scene": 19, "line": "What am I supposed to do with this?", "order": 41300, "speaker": "Michael Scarn"},
  {"season": 7, "episode": 16, "scene": 19, "line": "Mop. [a montage shows Michael sliding around the ice skillfully and hitting the puck into the goal]", "order": 41301, "speaker": "Cherokee Jack"},
  {"season": 7, "episode": 16, "scene": 20, "line": "Well, Michael Scarn was quickly becoming one of the hottest hockey players in the country.", "order": 41302, "speaker": "Narrator"},
  {"season": 7, "episode": 16, "scene": 21, "line": "[Ryan is acting as an official for the competition taking place. A line is formed in front of a line on the ice. Standing behind it are Michael Scarn, Oscar in a blue full-body suit, and a mysterious man in a hockey mask] Each year, the National Hockey League selects one civilian amateur to play in the All Star Game. It's down to the three of you. The final test is speed skating. [holds a gun to the ceiling] On your marks, get set...", "order": 41303, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 16, "scene": 21, "line": "Die! [The masked man pulls off the mask to reveal that it is Goldenface]", "order": 41304, "speaker": "Goldenface"},
  {"season": 7, "episode": 16, "scene": 21, "line": "[Michael and Goldenface skate around the rink, Goldenface shooting with a solid gold gun, Michael with two pistols. Michael is out of breath at the end of the race] Nice try Goldenface, but you forgot one thing, to kill me.", "order": 41305, "speaker": "Michael Scarn"},
  {"season": 7, "episode": 16, "scene": 21, "line": "I wasn't trying to kill you, I was trying to slow you down. [Ryan is seen placing a medal around Oscar's neck]", "order": 41306, "speaker": "Goldenface"},
  {"season": 7, "episode": 16, "scene": 21, "line": "No!", "order": 41307, "speaker": "Michael Scarn"},
  {"season": 7, "episode": 16, "scene": 21, "line": "Oh by the way!", "order": 41308, "speaker": "Goldenface"},
  {"season": 7, "episode": 16, "scene": 21, "line": "Yeah?", "order": 41309, "speaker": "Michael Scarn"},
  {"season": 7, "episode": 16, "scene": 21, "line": "How's your wife doing?", "order": 41310, "speaker": "Goldenface"},
  {"season": 7, "episode": 16, "scene": 21, "line": "[begins to cry as Goldenface laughs]", "order": 41311, "speaker": "Michael Scarn"},
  {"season": 7, "episode": 16, "scene": 22, "line": "Congratulations.", "order": 41312, "speaker": "Michael Scarn"},
  {"season": 7, "episode": 16, "scene": 22, "line": "Hey, you came in second. Not bad either.", "order": 41313, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 16, "scene": 22, "line": "I am sorry that I have to do this... [starts choking Oscar to death with his towel]", "order": 41314, "speaker": "Michael Scarn"},
  {"season": 7, "episode": 16, "scene": 22, "line": "Huh...[muffled screaming as he slowly chokes, and eventually dies]", "order": 41315, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 16, "scene": 22, "line": "I'm so sorry. I'm so sorry.", "order": 41316, "speaker": "Michael Scarn"},
  {"season": 7, "episode": 16, "scene": 23, "line": "I'm intercepting a name. Jasmine Winsong. She works for Goldenface. What I can't figure out is, who is the Funky Cat.", "order": 41317, "speaker": "Samuel"},
  {"season": 7, "episode": 16, "scene": 23, "line": "Not who, what. The Funky Cat is the hippest Jazz Club in town. [stands up with his cup of tea and walks by Samuel, pouring his tea all over him]", "order": 41318, "speaker": "Michael Scarn"},
  {"season": 7, "episode": 16, "scene": 23, "line": "Oh-[starts to freak out, but the scene is crudely cut short]", "order": 41319, "speaker": "Samuel"},
  {"season": 7, "episode": 16, "scene": 24, "line": "He cut the part where my circuit board malfunctioned! What was the point of spilling the drink on me?", "order": 41320, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 16, "scene": 25, "line": "[acting as a jazz singer at the Funky Cat, singing] They call me Jasmine Winsong.", "order": 41321, "speaker": "Jasmine Winsong"},
  {"season": 7, "episode": 16, "scene": 26, "line": "[walking into the Funky Cat] Bingo.", "order": 41322, "speaker": "Michael Scarn"},
  {"season": 7, "episode": 16, "scene": 27, "line": "He finished his movie? [frantically trying to open her car door, as if trying to get away from the camera crew] No kidding. Wow, that's great. Yeah that's good for him.", "order": 41323, "speaker": "Jan Levinson"},
  {"season": 7, "episode": 16, "scene": 28, "line": "Muidats eht rednu era segatsoh eht.", "order": 41324, "speaker": "Jasmine Winsong"},
  {"season": 7, "episode": 16, "scene": 28, "line": "[pulls out a recording device from his pocket and clicks it on]", "order": 41325, "speaker": "Michael Scarn"},
  {"season": 7, "episode": 16, "scene": 28, "line": "[michael pushes the backwards button on the recorder] The hostages are under the stadium. [An assassin with a golden tie shoots her with a tranquilizer and she falls]", "order": 41326, "speaker": "Jasmine Winsong"},
  {"season": 7, "episode": 16, "scene": 28, "line": "Jack Blaise.", "order": 41327, "speaker": "Michael Scarn"},
  {"season": 7, "episode": 16, "scene": 29, "line": "You have to let us go Goldenface! We have families!", "order": 41328, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 16, "scene": 29, "line": "Ha! This is gonna show them [cocks his golden gun] that I mean business. See ya! [points gun to Toby the hostage's head, shoots and Toby's fake head explodes, the shot is shown repeatedly]", "order": 41329, "speaker": "Goldenface"},
  {"season": 7, "episode": 16, "scene": 30, "line": "By far and away, the most expensive shot in the movie. But, it was integral to the story.", "order": 41330, "speaker": "Michael Scott"},
  {"season": 7, "episode": 16, "scene": 31, "line": "Ha! [kicking open the gate in the stadium where the hostages and Goldenface are]", "order": 41331, "speaker": "Samuel"},
  {"season": 7, "episode": 16, "scene": 31, "line": "Michael Scarn!", "order": 41332, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 16, "scene": 31, "line": "Sorry about your friend, Scarn!", "order": 41333, "speaker": "Goldenface"},
  {"season": 7, "episode": 16, "scene": 31, "line": "The joke's on you Goldenface, that man was a wanted animal rapist.", "order": 41334, "speaker": "Michael Scarn"},
  {"season": 7, "episode": 16, "scene": 31, "line": "[gives Michael a disgusted look]", "order": 41335, "speaker": "Goldenface"},
  {"season": 7, "episode": 16, "scene": 31, "line": "We've searched the whole building, Goldenface, where is the bomb?", "order": 41336, "speaker": "Samuel"},
  {"season": 7, "episode": 16, "scene": 31, "line": "Hm?", "order": 41337, "speaker": "Goldenface"},
  {"season": 7, "episode": 16, "scene": 31, "line": "We've searched the whole building, Goldenface, where is the bomb?", "order": 41338, "speaker": "Samuel"},
  {"season": 7, "episode": 16, "scene": 31, "line": "Hm?", "order": 41339, "speaker": "Goldenface"},
  {"season": 7, "episode": 16, "scene": 31, "line": "We've searched the wh- ok...", "order": 41340, "speaker": "Samuel"},
  {"season": 7, "episode": 16, "scene": 31, "line": "He said, where is the bomb?", "order": 41341, "speaker": "Michael Scarn"},
  {"season": 7, "episode": 16, "scene": 31, "line": "In the puck! [tosses the puck to Michael]", "order": 41342, "speaker": "Goldenface"},
  {"season": 7, "episode": 16, "scene": 31, "line": "Why are you telling me this?", "order": 41343, "speaker": "Michael Scarn"},
  {"season": 7, "episode": 16, "scene": 31, "line": "Because I'm going to kill you. [takes out golden gun] Unless! You forgive me for murdering your wife. [distraught, memories of him and his wife are shown on the puck in a dreamy haze]", "order": 41344, "speaker": "Goldenface"},
  {"season": 7, "episode": 16, "scene": 31, "line": "Hey Goldenface.", "order": 41345, "speaker": "Michael Scarn"},
  {"season": 7, "episode": 16, "scene": 31, "line": "Yeah?", "order": 41346, "speaker": "Goldenface"},
  {"season": 7, "episode": 16, "scene": 31, "line": "Go puck yourself!", "order": 41347, "speaker": "Michael Scarn"},
  {"season": 7, "episode": 16, "scene": 31, "line": "[dodges the puck and shoots at Michael]", "order": 41348, "speaker": "Goldenface"},
  {"season": 7, "episode": 16, "scene": 31, "line": "Noooo! [jumps in front of Michael to receive the bullet]", "order": 41349, "speaker": "Samuel"},
  {"season": 7, "episode": 16, "scene": 32, "line": "That was not scripted.", "order": 41350, "speaker": "Michael Scott"},
  {"season": 7, "episode": 16, "scene": 33, "line": "[re-cocks gun and shoots at Michael, who looks shocked and scared]", "order": 41351, "speaker": "Goldenface"},
  {"season": 7, "episode": 16, "scene": 34, "line": "More Tylenol.", "order": 41352, "speaker": "Michael Scarn"},
  {"season": 7, "episode": 16, "scene": 34, "line": "[as a busty nurse] You've already had four.", "order": 41353, "speaker": "Helene Beesly"},
  {"season": 7, "episode": 16, "scene": 34, "line": "Looking at her mother on screen, horrified] Oh God... [looks at Michael] So good.", "order": 41354, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 16, "scene": 34, "line": "You're lucky to be alive.", "order": 41355, "speaker": "Helene Beesly"},
  {"season": 7, "episode": 16, "scene": 34, "line": "It'll take a lot more than a bullet to the brain, lungs, heart, back, and balls to kill Michael Scarn.", "order": 41356, "speaker": "Michael Scarn"},
  {"season": 7, "episode": 16, "scene": 34, "line": "Let's just make sure that everything's... Working properly... [leans in closer to Michael and the heart rate monitor, which was beating steadily, now beats very quickly]", "order": 41357, "speaker": "Helene Beesly"},
  {"season": 7, "episode": 16, "scene": 35, "line": "You just said the bomb... is in the puck?", "order": 41358, "speaker": "President"},
  {"season": 7, "episode": 16, "scene": 35, "line": "Yes.", "order": 41359, "speaker": "Michael Scarn & Samuel"},
  {"season": 7, "episode": 16, "scene": 35, "line": "Is that where you hid the bomb Goldenface?", "order": 41360, "speaker": "President"},
  {"season": 7, "episode": 16, "scene": 35, "line": "[walks into the Oval Office with an accomplice, Troy]", "order": 41361, "speaker": "Goldenface"},
  {"season": 7, "episode": 16, "scene": 35, "line": "But why would you blow up the stadium? You OWN the stadium!", "order": 41362, "speaker": "Samuel"},
  {"season": 7, "episode": 16, "scene": 35, "line": "For the insurance money! I knew it all along! [the President, Goldenface, and his minion pull out automatic machine guns] You will never get away with this! [takes a painting of Abraham Lincoln and awkwardly smashes it on the President's head, runs out of the office as Goldenface and his minion shoot at them]", "order": 41363, "speaker": "Michael Scarn"},
  {"season": 7, "episode": 16, "scene": 36, "line": "Where had I gone wrong? All I wanted was to start a family with my beautiful wife. But somewhere along the way, things got messed up.", "order": 41364, "speaker": "Michael Scarn"},
  {"season": 7, "episode": 16, "scene": 37, "line": "It wasn't easy for Scarn to admit that he had lost his self-confidence. And he hadn't of course, He just wasn't using it right now.", "order": 41365, "speaker": "Narrator"},
  {"season": 7, "episode": 16, "scene": 38, "line": "[in a bar] Beer me Billy.", "order": 41366, "speaker": "Michael Scarn"},
  {"season": 7, "episode": 16, "scene": 38, "line": "[Andy as a bar tender with a Brooklyn accent] You don't looks so good, what's got ya down?", "order": 41367, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 16, "scene": 38, "line": "I got problems Billy. Big problems...", "order": 41368, "speaker": "Michael Scarn"},
  {"season": 7, "episode": 16, "scene": 38, "line": "You got problems?! My TV don't work! I pay thirty bucks a month for the damn satellite what's-a-whosit, I can't even get the damn game! Now you tell me, what's worse than that?", "order": 41369, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 16, "scene": 38, "line": "[laughs to himself] Don't ever change Billy. Goldenface is going to blow up the NHL All Star Game tomorrow.", "order": 41370, "speaker": "Michael Scarn"},
  {"season": 7, "episode": 16, "scene": 38, "line": "I see what you mean about problems. I know what'll cheer you up. That table of bachelorettes over there bought you this drink. [a table with Meredith, Phyllis, Karen, and Angela all say hey to Michael]", "order": 41371, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 16, "scene": 38, "line": "Ever banged an entire bachelorette party, baby? [winks]", "order": 41372, "speaker": "Karen Filippelli"},
  {"season": 7, "episode": 16, "scene": 39, "line": "Why are you singling my line out, like, a million years later?", "order": 41373, "speaker": "Karen Filippelli"},
  {"season": 7, "episode": 16, "scene": 40, "line": "I'm too depressed to save the big game Billy.", "order": 41374, "speaker": "Michael Scarn"},
  {"season": 7, "episode": 16, "scene": 40, "line": "I'm gonna cheer you up, the only ways I know hows. [to a small boy in overalls] Hey kid! Hit G-9 on the Jukebox!", "order": 41375, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 16, "scene": 40, "line": "No Billy, I haven't done that dance since my wife died.", "order": 41376, "speaker": "Michael Scarn"},
  {"season": 7, "episode": 16, "scene": 40, "line": "There is a whole crowd of people out there, who need to learn, how to do The Scarn.", "order": 41377, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 16, "scene": 40, "line": "[A funky beat sounds from the Jukebox and Michael starts dancing poorly] Well my name's Michael Scarn and I'm here to say, I'm about to do The Scarn in a major way. [the bachelorettes and the others in the bar stand up to join him] You jump to the right and you shake a hand and you jump to the left and you shake that hand. You meet new friends, and tie that yarn, and that's how you do The Scarn! [doing the actions he says to] You jump to the right and you shake a hand and you jump to the left and you shake that hand. You meet new friends, and tie that yarn, and that's how you do The Scarn! You jump to the right and you shake a hand and you jump to the left and you shake that hand. You meet new friends, and tie that yarn, and that's how you do The Scarn!", "order": 41378, "speaker": "Michael Scarn"},
  {"season": 7, "episode": 16, "scene": 40, "line": "[as a drunken man in the bar] If doing The Scarn is gay, then I'm the biggest queer on Earth!", "order": 41379, "speaker": "Todd Packer"},
  {"season": 7, "episode": 16, "scene": 41, "line": "[cracking up at the stupidity of it, the rest of the office besides Michael and Holly are concealing their laughter. Pam Is trying to quiet Jim up] Sorry.", "order": 41380, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 16, "scene": 41, "line": "[turns off the movie from the remote, the office complains]", "order": 41381, "speaker": "Michael Scott"},
  {"season": 7, "episode": 16, "scene": 41, "line": "I'm sorry, I'm really, really sorry. I think I was just relieved, to see that Michael Scarn got his confidence back.", "order": 41382, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 16, "scene": 41, "line": "Yeah Michael the movie is amazing!", "order": 41383, "speaker": "Kelly Kapoor"},
  {"season": 7, "episode": 16, "scene": 41, "line": "It's like one of the best movies I've ever seen in my life.", "order": 41384, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 16, "scene": 41, "line": "You should enter it in festivals!", "order": 41385, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 16, "scene": 41, "line": "Or carnivals!", "order": 41386, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 16, "scene": 41, "line": "[to Holly] Well that's a... pretty good reaction. [Holly nods] Pretty cool, right? Did you like it? Did you like that?", "order": 41387, "speaker": "Michael Scott"},
  {"season": 7, "episode": 16, "scene": 41, "line": "Uh... which part?", "order": 41388, "speaker": "Holly Flax"},
  {"season": 7, "episode": 16, "scene": 41, "line": "Okay. [Stands up and leaves the conference room to the dismay of the office] No, it's not good enough. It's not good enough...", "order": 41389, "speaker": "Michael Scott"},
  {"season": 7, "episode": 16, "scene": 41, "line": "Some people are really popping on screen!", "order": 41390, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 16, "scene": 42, "line": "Hey.", "order": 41391, "speaker": "Michael Scott"},
  {"season": 7, "episode": 16, "scene": 42, "line": "Hey! Good movie.", "order": 41392, "speaker": "Holly Flax"},
  {"season": 7, "episode": 16, "scene": 42, "line": "Mmmm, good? Everybody out there says it's great!", "order": 41393, "speaker": "Michael Scott"},
  {"season": 7, "episode": 16, "scene": 42, "line": "I loved it.", "order": 41394, "speaker": "Holly Flax"},
  {"season": 7, "episode": 16, "scene": 42, "line": "Did you? What did you love about it?", "order": 41395, "speaker": "Michael Scott"},
  {"season": 7, "episode": 16, "scene": 42, "line": "Uhm, I loved that you got to work together with all your friends. Isn't that great when you can all work together like that?", "order": 41396, "speaker": "Holly Flax"},
  {"season": 7, "episode": 16, "scene": 43, "line": "No, no. Holly, this isn't Ocean's Eleven, where you get together with all your friends and just have fun and don't care about how it turns out. What'd you really think, honestly.", "order": 41397, "speaker": "Michael Scott"},
  {"season": 7, "episode": 16, "scene": 43, "line": "Uhm...", "order": 41398, "speaker": "Holly Flax"},
  {"season": 7, "episode": 16, "scene": 43, "line": "Is it, is it because you're afraid of where this is gonna take me? See, because I need you... to keep me grounded.", "order": 41399, "speaker": "Michael Scott"},
  {"season": 7, "episode": 16, "scene": 43, "line": "Not worried about that.", "order": 41400, "speaker": "Holly Flax"},
  {"season": 7, "episode": 16, "scene": 43, "line": "[angrily] It was eleven years, okay? This has been my dream for eleven years, and if you don't think it's great than you're basically saying that you don't believe in my dream.", "order": 41401, "speaker": "Michael Scott"},
  {"season": 7, "episode": 16, "scene": 43, "line": "Wha- It's your dream and you never even mentioned it before!", "order": 41402, "speaker": "Holly Flax"},
  {"season": 7, "episode": 16, "scene": 43, "line": "I talk about a lot of things, Holly! I was eventually gonna get around to my dream! Obviously! Eleven years I could've been working on the Scarn Nebulus.", "order": 41403, "speaker": "Michael Scott"},
  {"season": 7, "episode": 16, "scene": 43, "line": "Well why do you have to make a movie at all?", "order": 41404, "speaker": "Holly Flax"},
  {"season": 7, "episode": 16, "scene": 43, "line": "Because, if I don't have this, what do I have? I have nothing.", "order": 41405, "speaker": "Michael Scott"},
  {"season": 7, "episode": 16, "scene": 43, "line": "Really, you can't think of anything else that you might have?", "order": 41406, "speaker": "Holly Flax"},
  {"season": 7, "episode": 16, "scene": 43, "line": "I have my book on business, Somehow I Manage. I have my HBO comedy special, Here I Go Again dot-dot-dot. But you know what? When I think about it, when I really think about it, none of those things are as real to me as my movie.", "order": 41407, "speaker": "Michael Scott"},
  {"season": 7, "episode": 16, "scene": 43, "line": "I'm real.", "order": 41408, "speaker": "Holly Flax"},
  {"season": 7, "episode": 16, "scene": 43, "line": "Yeah, you're a real pain in the ass. And I'm gonna go watch the movie with people who think it's great! And I'm sorry I called you a pain in the ass, I'm angry, and I love you.", "order": 41409, "speaker": "Michael Scott"},
  {"season": 7, "episode": 16, "scene": 43, "line": "I love you too.", "order": 41410, "speaker": "Holly Flax"},
  {"season": 7, "episode": 16, "scene": 44, "line": "I am a huge Woody Allen fan. Although I've only seen Antz, but I'll tell you something. What I respect about that man, is that when he was going through all that stuff that came out in the press, about how Antz was just a rip-off of A Bug's Life, he stayed true to his films. Or at least the film that I saw which again was Antz. The thing is... I thought Bug's Life was better, much better. Than Ants. The point is, don't listen to your critics, listen to your fans.", "order": 41411, "speaker": "Michael Scott"},
  {"season": 7, "episode": 16, "scene": 45, "line": "Who likes Threat Level: Midnight? [applause from the office] Okay then who wants to watch the rest?! [everyone yells approval]", "order": 41412, "speaker": "Michael Scott"},
  {"season": 7, "episode": 16, "scene": 46, "line": "Michael! You have to get to that puck before halftime! Or the whole stadium will explode!", "order": 41413, "speaker": "Samuel"},
  {"season": 7, "episode": 16, "scene": 46, "line": "I know, it's a good thing my trainer and mentor is here to cheer me on!", "order": 41414, "speaker": "Michael Scarn"},
  {"season": 7, "episode": 16, "scene": 46, "line": "Cherokee Jack? Michael he died.", "order": 41415, "speaker": "Samuel"},
  {"season": 7, "episode": 16, "scene": 46, "line": "[crying] This one's for you Cherokee Jack. [Michael skates into the rink of an already in-play match]", "order": 41416, "speaker": "Michael Scarn"},
  {"season": 7, "episode": 16, "scene": 47, "line": "We filmed this during an actual Scranton High School Hockey Game, trying to qualify for states.", "order": 41417, "speaker": "Michael Scott"},
  {"season": 7, "episode": 16, "scene": 48, "line": "[he office shushes Michael to hear the movie better] That's fine, it's great!", "order": 41418, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 16, "scene": 48, "line": "No, no! Actually it's really screwed up because they [chuckles], they were trying to qualify, they were disqualified, they had to forfeit the game. Undefeated season. That's why there were so many people there. [Michael stands up, clearly realizing that his movie is not as great as he thought]", "order": 41419, "speaker": "Michael Scott"},
  {"season": 7, "episode": 16, "scene": 49, "line": "Why is your face gold?", "order": 41420, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 16, "scene": 49, "line": "Why do you care?", "order": 41421, "speaker": "Goldenface"},
  {"season": 7, "episode": 16, "scene": 49, "line": "I'm just making conversation.", "order": 41422, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 16, "scene": 49, "line": "I worked in a gold factory, we had a boss, who only cared about money...", "order": 41423, "speaker": "Goldenface"},
  {"season": 7, "episode": 16, "scene": 50, "line": "Hey...", "order": 41424, "speaker": "Michael Scott"},
  {"season": 7, "episode": 16, "scene": 50, "line": "Hey, I'm sorry. It is good.", "order": 41425, "speaker": "Holly Flax"},
  {"season": 7, "episode": 16, "scene": 50, "line": "No it's not. [kisses Holly while smiling] It's not. But, they really seem to be enjoying it.", "order": 41426, "speaker": "Michael Scott"},
  {"season": 7, "episode": 16, "scene": 51, "line": "Please Goldenface, let us go!", "order": 41427, "speaker": "Hostage"},
  {"season": 7, "episode": 16, "scene": 52, "line": "[sees Cherokee Jack from a confusion of heavenly light and smoke] Cherokee Jack.", "order": 41428, "speaker": "Michael Scarn"},
  {"season": 7, "episode": 16, "scene": 52, "line": "I want you to take all of your frustrations, with women, the system, with everything. Take it out on the puck. All on the puck.", "order": 41429, "speaker": "Cherokee Jack"},
  {"season": 7, "episode": 16, "scene": 53, "line": "[laughs at the stupidity]", "order": 41430, "speaker": "Michael Scott"},
  {"season": 7, "episode": 16, "scene": 54, "line": "[skates forward, hits the puck, and stares in wonder as it flies by]", "order": 41431, "speaker": "Michael Scarn"},
  {"season": 7, "episode": 16, "scene": 55, "line": "Yah! [kicks open the gate to save the hostages, hostages rejoice as Samuel unties them] [the puck is seen flying into space and it hits a satellite, Billy is at his bar and his TV suddenly turns back on]", "order": 41432, "speaker": "Samuel"},
  {"season": 7, "episode": 16, "scene": 56, "line": "Hey! We got sports games again! [people at the bar cheer]", "order": 41433, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 16, "scene": 57, "line": "[seen at his house counting his money] Ha ha ha! Ha ha ha ha ha! [The puck lands on his lap] Oh-[A large explosion blocks out his words] [The office cheers at the ending of the movie, Michael Scarn holds up a trophy]", "order": 41434, "speaker": "Goldenface"},
  {"season": 7, "episode": 16, "scene": 58, "line": "[in Scarn manor] Some breakfast for me [shows a plate of bacon and eggs] and some breakfast for you. [pulls out an oil can and oils Samuel's circuit board on his back]", "order": 41435, "speaker": "Michael Scarn"},
  {"season": 7, "episode": 16, "scene": 59, "line": "Oh yeah, I guess I did let him be a robot.", "order": 41436, "speaker": "Michael Scott"},
  {"season": 7, "episode": 16, "scene": 60, "line": "[phone rings] I'll get it! Man I love being retired! Scarn here!", "order": 41437, "speaker": "Michael Scarn"},
  {"season": 7, "episode": 16, "scene": 60, "line": "[on phone] Michael, it's the president.", "order": 41438, "speaker": "President"},
  {"season": 7, "episode": 16, "scene": 60, "line": "Hello sir.", "order": 41439, "speaker": "Michael Scarn"},
  {"season": 7, "episode": 16, "scene": 60, "line": "I need you for another mission.", "order": 41440, "speaker": "President"},
  {"season": 7, "episode": 16, "scene": 60, "line": "Ugh... [after a little thought] I'm in.", "order": 41441, "speaker": "Michael Scarn"},
  {"season": 7, "episode": 16, "scene": 61, "line": "[the office cheers] Whoa Whoa Whoa! Isn't the president evil?", "order": 41442, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 16, "scene": 61, "line": "Oh yeah! [laughs] Yes he is!", "order": 41443, "speaker": "Michael Scott"},
  {"season": 7, "episode": 16, "scene": 61, "line": "No no, he's doing it to catch the president!", "order": 41444, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 16, "scene": 61, "line": "No, no Dwight. He's just being stupid. [the office laughs]", "order": 41445, "speaker": "Michael Scott"},
  {"season": 7, "episode": 16, "scene": 62, "line": "[in Stanley's voice, a chair is shown with a gray haired head sticking above it] Well, Michael Scarn was back in the game. And I bet you're wondering why do I know so much about Michael Scarn. [the chair revolves to show Michael Scarn, gray haired, talking with Stanley's voice] Well because I AM Michael Scarn. [applause]", "order": 41446, "speaker": "Narrator"},
  {"season": 7, "episode": 16, "scene": 63, "line": "[Andy rapping to a montage of scenes from the movie] Ahhhh, yeah! Threat Level Midnight! Makes all the girlies feel alright! From Madonna to Madelyn Allbrite, Threat Level Midnight! It's a threat, a level, a level level threat. He's the greatest hockey-star I ever seen yet. Threat Level what? Midnight! Threat Level who? Michael Scarn! Threat Level why? Apartheid! Gotta fight it, Free Mandela! Peace I'm out!", "order": 41447, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 17, "scene": 1, "line": "I have the best survival stock shelter in north eastern Pennsylvania. But everything has a shelf-life. So I must eat and replace everything that's about to expire. It's nice not to have to plan my meals.", "order": 41448, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 17, "scene": 2, "line": "You're eating eight year old tomatoes?", "order": 41449, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 17, "scene": 2, "line": "They're still good for another week.", "order": 41450, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 17, "scene": 2, "line": "You know, I think I have some type of cheese in the back of my fridge you might like. [Jim smiles]", "order": 41451, "speaker": "Meredith Palmer"},
  {"season": 7, "episode": 17, "scene": 2, "line": "I've got some cheese you might like too. In between my toes. [all laugh]", "order": 41452, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 17, "scene": 2, "line": "Hardy har har. Okay picture this: Snowy ash drizzles from the sky. A ravenous pack of dogs surrounds you as the flame at the end of your stick dies out. There's only one hope left for you. The door to my shelter. You pound, you beg, Dwight! Please let me in! But I ignore your cries and do not let you in. You wanna know why?", "order": 41453, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 17, "scene": 2, "line": "Because of the sign, that says no pounding no begging.", "order": 41454, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 17, "scene": 2, "line": "No. Because you laughed at me. Kevin will be eaten! Pam will be taken slave! Jim will be made a warlord's gesture. Meredith will do ok. Be assured this day will come. It's just a matter of time. Could be one month, could be two months.", "order": 41455, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 17, "scene": 2, "line": "Three months.", "order": 41456, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 17, "scene": 2, "line": "Could be.", "order": 41457, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 17, "scene": 2, "line": "Four months.", "order": 41458, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 17, "scene": 2, "line": "I can see that happening, yes.", "order": 41459, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 17, "scene": 2, "line": "Eight months?", "order": 41460, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 17, "scene": 2, "line": "That's a realistic time line.", "order": 41461, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 17, "scene": 2, "line": "[Pam kisses Jim on the cheek] Eleven months.", "order": 41462, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 17, "scene": 2, "line": "Perhaps.", "order": 41463, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 17, "scene": 2, "line": "Okay now really think hard about this one: One year.", "order": 41464, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 17, "scene": 2, "line": "I can see that as a very real possibility.", "order": 41465, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 17, "scene": 2, "line": "[time has obviously passed, as everyone is gone except Jim and Dwight] Four hundred and ninety-four months?", "order": 41466, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 17, "scene": 2, "line": "I can see that happening.", "order": 41467, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 17, "scene": 2, "line": "Four hundred and ninety-FIVE months. That's just...", "order": 41468, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 17, "scene": 3, "line": "I really though I was becoming too much of a womanizer. I realized I had shirts in five different women's houses.", "order": 41469, "speaker": "Todd Packer"},
  {"season": 7, "episode": 17, "scene": 3, "line": "Still not seeing the problem here.", "order": 41470, "speaker": "Michael Scott"},
  {"season": 7, "episode": 17, "scene": 3, "line": "All right, truth is I gotta couple love bumps on my ding-dong so, game-over!", "order": 41471, "speaker": "Todd Packer"},
  {"season": 7, "episode": 17, "scene": 4, "line": "It was the best of times, it was the awesomest of times. And now Packer wants to come home. And ohh, look who's here to sign off on it! My boo, Holly.", "order": 41472, "speaker": "Michael Scott"},
  {"season": 7, "episode": 17, "scene": 5, "line": "[walks into conference room with Michael and Todd] Hi!", "order": 41473, "speaker": "Holly Flax"},
  {"season": 7, "episode": 17, "scene": 5, "line": "Hi. [Holly and Michael kiss]", "order": 41474, "speaker": "Michael Scott"},
  {"season": 7, "episode": 17, "scene": 5, "line": "You must be Todd.", "order": 41475, "speaker": "Holly Flax"},
  {"season": 7, "episode": 17, "scene": 5, "line": "[shaking Holly's hand] Whoa! I'm sorry, Michael. I thought we were meeting Holly today, not Jennifer Aniston!", "order": 41476, "speaker": "Todd Packer"},
  {"season": 7, "episode": 17, "scene": 5, "line": "[laughs oddly] Very funny. Okay let's get started.", "order": 41477, "speaker": "Holly Flax"},
  {"season": 7, "episode": 17, "scene": 5, "line": "After you!", "order": 41478, "speaker": "Todd Packer"},
  {"season": 7, "episode": 17, "scene": 5, "line": "Oh, Michael! This'll be just us.", "order": 41479, "speaker": "Michael Scott"},
  {"season": 7, "episode": 17, "scene": 5, "line": "Oh, yes yes yes yes. Just so you know, he's at his funniest when you've given him five shots.", "order": 41480, "speaker": "Michael Scott"},
  {"season": 7, "episode": 17, "scene": 5, "line": "All right.", "order": 41481, "speaker": "Holly Flax"},
  {"season": 7, "episode": 17, "scene": 5, "line": "And it also helps if you've had five shots.", "order": 41482, "speaker": "Michael Scott"},
  {"season": 7, "episode": 17, "scene": 5, "line": "I already have.", "order": 41483, "speaker": "Holly Flax"},
  {"season": 7, "episode": 17, "scene": 5, "line": "Whoa!", "order": 41484, "speaker": "Michael Scott"},
  {"season": 7, "episode": 17, "scene": 5, "line": "[to Michael] Why is Packer back?", "order": 41485, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 17, "scene": 5, "line": "Is Packer here?", "order": 41486, "speaker": "Michael Scott"},
  {"season": 7, "episode": 17, "scene": 5, "line": "Why's he talking to Holly?", "order": 41487, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 17, "scene": 5, "line": "[peering into conference room] Don't know, don't care.", "order": 41488, "speaker": "Michael Scott"},
  {"season": 7, "episode": 17, "scene": 5, "line": "[to Todd] Michael loves you, and your sales speak for themselves.", "order": 41489, "speaker": "Holly Flax"},
  {"season": 7, "episode": 17, "scene": 5, "line": "[excitedly] Oh!", "order": 41490, "speaker": "Todd Packer"},
  {"season": 7, "episode": 17, "scene": 5, "line": "[sees Holly and Todd shaking hands in the conference room] Yes! The Pack is back! Ladies and gentlemen, I would like to introduce you to a man who needs no introduction, probably because most of you have done it with him. Just kidding he wouldn't be interested in any of you. In all seriousness, Todd Packer, is a permanent salesman at this branch. And I would like to invite you to welcome him with open arms.", "order": 41491, "speaker": "Michael Scott"},
  {"season": 7, "episode": 17, "scene": 5, "line": "Yes!", "order": 41492, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 17, "scene": 5, "line": "What!?", "order": 41493, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 17, "scene": 5, "line": "It's great to be among friends, but until then, you suckers will do!", "order": 41494, "speaker": "Todd Packer"},
  {"season": 7, "episode": 17, "scene": 5, "line": "Nice! We got burned!", "order": 41495, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 17, "scene": 5, "line": "You did! You got burned, because Packer's back! Packer is turning in his car for a desk. He is turning in his condoms, for a condominium! Although he's probably going to have to use condoms from here on out.", "order": 41496, "speaker": "Michael Scott"},
  {"season": 7, "episode": 17, "scene": 5, "line": "[seething] Holly, you approved this?", "order": 41497, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 17, "scene": 5, "line": "Yes I did, I think Todd's gonna make a great addition to the staff.", "order": 41498, "speaker": "Holly Flax"},
  {"season": 7, "episode": 17, "scene": 5, "line": "You did approve it?", "order": 41499, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 17, "scene": 5, "line": "Yeah.", "order": 41500, "speaker": "Holly Flax"},
  {"season": 7, "episode": 17, "scene": 5, "line": "What don't you understand about the word approved? It seems some of you don't know what the word approved means.", "order": 41501, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 17, "scene": 6, "line": "I have very little patience for stupidity.", "order": 41502, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 17, "scene": 7, "line": "[walking in with Gabe behind her] Sorry we're late. Gabe fell in the shower.", "order": 41503, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 17, "scene": 7, "line": "I'm such a klutz!", "order": 41504, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 17, "scene": 7, "line": "Yeah, it took the fire department forever to get there. [realizing there's a new computer at her desk. Gets very excited] Oh my God, where did this come from?! Who did this?!", "order": 41505, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 17, "scene": 8, "line": "I got Erin a new computer, because the one at reception sucked. I should know. And I don't wanna say the other one was old, but its I.P. number is one! [laughs sheepishly] Right?", "order": 41506, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 17, "scene": 9, "line": "Thank you Pam.", "order": 41507, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 17, "scene": 9, "line": "Awwh, you're welcome. [they hug] My pleasure.", "order": 41508, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 17, "scene": 9, "line": "[Pam walks over to him and smiles excitedly while bouncing, Jim imitates her] Can I do something for ya?", "order": 41509, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 17, "scene": 9, "line": "I just helped someone out. It feels good.", "order": 41510, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 17, "scene": 9, "line": "Nice. You know, I cleaned our daughter for like, an hour at four a.m. this morning, so...", "order": 41511, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 17, "scene": 9, "line": "So you know the feeling.", "order": 41512, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 17, "scene": 10, "line": "Special delivery for Dwight K. Schrute.", "order": 41513, "speaker": "Michael Scott"},
  {"season": 7, "episode": 17, "scene": 10, "line": "I didn't order anything.", "order": 41514, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 17, "scene": 10, "line": "And I don't have anything for you. But I do wanna talk to you about something. We have been thinking about where Packer should sit... And we can't just sit around and wait for Creed to die.", "order": 41515, "speaker": "Michael Scott"},
  {"season": 7, "episode": 17, "scene": 10, "line": "Well there's a lot of seats in the annex.", "order": 41516, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 17, "scene": 10, "line": "So you wouldn't mind permanently relocating, so that Packer can take this desk here. [Jim looks appalled]", "order": 41517, "speaker": "Michael Scott"},
  {"season": 7, "episode": 17, "scene": 10, "line": "Thanks man, it'd mean a lot.", "order": 41518, "speaker": "Todd Packer"},
  {"season": 7, "episode": 17, "scene": 10, "line": "I have been sitting here for ten years, Michael.", "order": 41519, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 17, "scene": 10, "line": "well I was there for twelve years. Plus my name's carved under the desk.", "order": 41520, "speaker": "Todd Packer"},
  {"season": 7, "episode": 17, "scene": 10, "line": "No it is not.", "order": 41521, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 17, "scene": 10, "line": "Is too!", "order": 41522, "speaker": "Todd Packer"},
  {"season": 7, "episode": 17, "scene": 10, "line": "[Grunting as he crawls under Dwight's desk. Dwight follows] Let's look at this.", "order": 41523, "speaker": "Michael Scott"},
  {"season": 7, "episode": 17, "scene": 10, "line": "Check it out!", "order": 41524, "speaker": "Todd Packer"},
  {"season": 7, "episode": 17, "scene": 10, "line": "I don't wanna move desks!", "order": 41525, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 17, "scene": 10, "line": "Don't be a baby! Okay! There it is. Packer was here and so was your mom! [Todd starts humping Dwight and Michael who are still under the desk.", "order": 41526, "speaker": "Michael Scott"},
  {"season": 7, "episode": 17, "scene": 10, "line": "Do I have any say in this?!", "order": 41527, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 17, "scene": 10, "line": "No!", "order": 41528, "speaker": "Michael Scott"},
  {"season": 7, "episode": 17, "scene": 10, "line": "[grunting while humping Michael and Dwight] Don't even watch, Halpert!", "order": 41529, "speaker": "Todd Packer"},
  {"season": 7, "episode": 17, "scene": 11, "line": "[in the annex, moving into his new desk. Holds up a red tray with rocks on it] Hey, who's dirt box is this?", "order": 41530, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 17, "scene": 11, "line": "Oh, that's our Zen garden.", "order": 41531, "speaker": "Holly Flax"},
  {"season": 7, "episode": 17, "scene": 11, "line": "What do you grow in here, bullcrap? [puts the tray into the garbage]", "order": 41532, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 17, "scene": 11, "line": "[walking into the annex with Jim] Hey, um...", "order": 41533, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 17, "scene": 11, "line": "Hey, what's up guys?", "order": 41534, "speaker": "Holly Flax"},
  {"season": 7, "episode": 17, "scene": 11, "line": "Don't what's up us! You think you're so cute, with your pretty blonde hair!", "order": 41535, "speaker": "Meredith Palmer"},
  {"season": 7, "episode": 17, "scene": 11, "line": "Whoa, pull it back. Uhm, why did you hire Todd Packer?", "order": 41536, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 17, "scene": 11, "line": "Uhm.", "order": 41537, "speaker": "Holly Flax"},
  {"season": 7, "episode": 17, "scene": 11, "line": "He's seriously awful!", "order": 41538, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 17, "scene": 11, "line": "Michael's recommendation was glowing! And, honestly, he's been nothing but nice to me!", "order": 41539, "speaker": "Holly Flax"},
  {"season": 7, "episode": 17, "scene": 11, "line": "That's how he gets you to take off his panties.", "order": 41540, "speaker": "Meredith Palmer"},
  {"season": 7, "episode": 17, "scene": 11, "line": "[Pam starts nodding in agreement] Why are you nodding?", "order": 41541, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 17, "scene": 11, "line": "United front...", "order": 41542, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 17, "scene": 11, "line": "Okay, look. We can't fire someone because we don't like him.", "order": 41543, "speaker": "Holly Flax"},
  {"season": 7, "episode": 17, "scene": 11, "line": "Right this isn't the U.S. Government.", "order": 41544, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 17, "scene": 11, "line": "What are you referencing?", "order": 41545, "speaker": "Kelly Kapoor"},
  {"season": 7, "episode": 17, "scene": 11, "line": "[seems unsure] Everything... Everything.", "order": 41546, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 17, "scene": 11, "line": "Do any of you have any concrete complaints about something he's done?", "order": 41547, "speaker": "Holly Flax"},
  {"season": 7, "episode": 17, "scene": 11, "line": "Well, I mean he humped Michael.", "order": 41548, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 17, "scene": 11, "line": "Well if that's the case, I guess I've gotta be fired too. [all groan in disgust]", "order": 41549, "speaker": "Holly Flax"},
  {"season": 7, "episode": 17, "scene": 12, "line": "[looking at his computer, gasps] Hey! Hey you guys! The Armeth Regado video is up, gather around! [no one moves] Check it out! This guy's on a full beam reach. [watching a sailing video on his computer] They're hiking out like mad! Lock 'em up! Lock 'em up! Nice job! Ughh. [computer is frozen, taps the top of it] This computer's a hunk of jjunk.", "order": 41550, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 17, "scene": 12, "line": "Hey sailor! Come watch it over here! [pats her new computer]", "order": 41551, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 17, "scene": 12, "line": "Where did you get this?!", "order": 41552, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 17, "scene": 12, "line": "Pam gave it to me!", "order": 41553, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 17, "scene": 12, "line": "This is a sick computer! Gwen Stefani has this computer! [to Pam, in a fake British accent] Uhhh, Pamela! What does a guy have to do to get, ahem, one of them?", "order": 41554, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 17, "scene": 12, "line": "You have a computer Andy.", "order": 41555, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 17, "scene": 12, "line": "Yeah, but if you donated my computer to Africa, it would become world famous as the slowest computer in Africa. So...", "order": 41556, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 17, "scene": 12, "line": "Ok, but listen. We just don't have the budget for it. Okay? Reception needed a computer so we got one.", "order": 41557, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 17, "scene": 12, "line": "Well Andy's desk needs a computer. And, I mean, it's just kinda a coincidence that I work there, but...", "order": 41558, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 17, "scene": 12, "line": "Yes, but Reception is a one person department. If I get you a new computer I have to get one for everyone in sales. For Dwight, for Stanley, it would be crazy.", "order": 41559, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 17, "scene": 12, "line": "So something good happening to Stanley is crazy now?", "order": 41560, "speaker": "Stanley Hudson"},
  {"season": 7, "episode": 17, "scene": 12, "line": "I'm not asking for one, I need it.", "order": 41561, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 17, "scene": 12, "line": "If you're just handing them out, I want one too.", "order": 41562, "speaker": "Phyllis Vance"},
  {"season": 7, "episode": 17, "scene": 12, "line": "Phyllis, no body is handing anything out.", "order": 41563, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 17, "scene": 12, "line": "See, this is what I'm talking about.", "order": 41564, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 17, "scene": 12, "line": "What are you gonna play mange on faster?", "order": 41565, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 17, "scene": 12, "line": "Sorry Andy.", "order": 41566, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 17, "scene": 13, "line": "Hey what's going on you guys? [walking into the kitchen with Holly, Dwight, and Kevin, with whom he fist bumps] Yeahh! Three muska-queers!", "order": 41567, "speaker": "Todd Packer"},
  {"season": 7, "episode": 17, "scene": 13, "line": "[giggling] Mean but good!", "order": 41568, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 17, "scene": 13, "line": "So, Todd, this must be nice for you getting off the road. You get to spend some time with your daughter.", "order": 41569, "speaker": "Holly Flax"},
  {"season": 7, "episode": 17, "scene": 13, "line": "I don't know, I love her and all, but she turned into a bitch. Mostly she's great, but some days she acts like her mom. [Holly is un-amused]", "order": 41570, "speaker": "Todd Packer"},
  {"season": 7, "episode": 17, "scene": 13, "line": "Well, some girls go through a phase.", "order": 41571, "speaker": "Holly Flax"},
  {"season": 7, "episode": 17, "scene": 13, "line": "Hey, your life is so insane! You should write a book!", "order": 41572, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 17, "scene": 13, "line": "Since when did you learn how to read?", "order": 41573, "speaker": "Todd Packer"},
  {"season": 7, "episode": 17, "scene": 13, "line": "I do know how to read though!", "order": 41574, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 17, "scene": 13, "line": "Yeah. You know how to read... a menu!", "order": 41575, "speaker": "Todd Packer"},
  {"season": 7, "episode": 17, "scene": 13, "line": "[Todd leaves. Kevin laughs uncertainly] He's right. I mean, I could lose some weight.", "order": 41576, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 17, "scene": 13, "line": "Kevin, in sumo culture, you'd be considered a promising up and comer.", "order": 41577, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 17, "scene": 14, "line": "[walking through the office, notices Andy with Erin's computer set up at his desk] What the heck! Why do you have Erin's computer!", "order": 41578, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 17, "scene": 14, "line": "It's crazy, right? Erin just wanted me to have it, so we switched.", "order": 41579, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 17, "scene": 14, "line": "What? Erin is that true?", "order": 41580, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 17, "scene": 14, "line": "[nods] I just thought, he really needed a new computer, and he knew so much about that one.", "order": 41581, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 17, "scene": 14, "line": "Erin, it's not up to you. This computer was for reception! Okay? It's not yours to give away.", "order": 41582, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 17, "scene": 14, "line": "Pam, when I'm freaking out, I just sorta step back and-", "order": 41583, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 17, "scene": 14, "line": "I'm not freaking out, Andy.", "order": 41584, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 17, "scene": 14, "line": "-take a few breaths, and then I ask myself: Is this worth freaking out about?", "order": 41585, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 17, "scene": 14, "line": "Andy, why should she have your crappy computer?", "order": 41586, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 17, "scene": 14, "line": "That's interesting. So you also think my computer is crappy.", "order": 41587, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 17, "scene": 14, "line": "Switch the computers back Andy.", "order": 41588, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 17, "scene": 14, "line": "Seriously?", "order": 41589, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 17, "scene": 14, "line": "Seriously.", "order": 41590, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 17, "scene": 14, "line": "Pam! Come on!", "order": 41591, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 17, "scene": 14, "line": "Now please.", "order": 41592, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 17, "scene": 14, "line": "Fine! [the office goes back to their work] Please make sure no one is humping me!", "order": 41593, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 17, "scene": 14, "line": "[to Holly who is walking by] Oh! If you're going back to the annex, could you take these to Dwight? I think the ants are waking up. They need to start farming soon. [picks up an ant farm and tube of ants from her desk, Holly takes them] Thanks.", "order": 41594, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 17, "scene": 14, "line": "Best day ever. Best day ever!", "order": 41595, "speaker": "Michael Scott"},
  {"season": 7, "episode": 17, "scene": 14, "line": "So much happening. [not as excited as Michael]", "order": 41596, "speaker": "Holly Flax"},
  {"season": 7, "episode": 17, "scene": 14, "line": "Question, should I get stripes shaved on the side of my head?", "order": 41597, "speaker": "Michael Scott"},
  {"season": 7, "episode": 17, "scene": 14, "line": "No! No.", "order": 41598, "speaker": "Holly Flax"},
  {"season": 7, "episode": 17, "scene": 14, "line": "Please.", "order": 41599, "speaker": "Michael Scott"},
  {"season": 7, "episode": 17, "scene": 14, "line": "Did Todd tell you to do that?", "order": 41600, "speaker": "Holly Flax"},
  {"season": 7, "episode": 17, "scene": 14, "line": "Yeah, You love him right? [Holly seems unsure] You love him. I can tell.", "order": 41601, "speaker": "Michael Scott"},
  {"season": 7, "episode": 17, "scene": 14, "line": "I love you.", "order": 41602, "speaker": "Holly Flax"},
  {"season": 7, "episode": 17, "scene": 14, "line": "No. Not me, him.", "order": 41603, "speaker": "Michael Scott"},
  {"season": 7, "episode": 17, "scene": 14, "line": "That's certainly opinionated.", "order": 41604, "speaker": "Holly Flax"},
  {"season": 7, "episode": 17, "scene": 14, "line": "[looking anxious] If you're not gonna take the ants over I should probably just do it myself.", "order": 41605, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 17, "scene": 14, "line": "Oh I'm going.", "order": 41606, "speaker": "Holly Flax"},
  {"season": 7, "episode": 17, "scene": 14, "line": "Whoa! Look, are you jealous of him? Because you think he's funnier than you? Oh honey...", "order": 41607, "speaker": "Michael Scott"},
  {"season": 7, "episode": 17, "scene": 14, "line": "I don't think he's funnier than me.", "order": 41608, "speaker": "Holly Flax"},
  {"season": 7, "episode": 17, "scene": 14, "line": "He's funnier than me.", "order": 41609, "speaker": "Michael Scott"},
  {"season": 7, "episode": 17, "scene": 14, "line": "No, he's not funny at all.", "order": 41610, "speaker": "Holly Flax"},
  {"season": 7, "episode": 17, "scene": 14, "line": "So I'm less funny than not funny at all?", "order": 41611, "speaker": "Michael Scott"},
  {"season": 7, "episode": 17, "scene": 14, "line": "[very anxious at this point] Gee! Uh oh! I think the ants are starting to eat each other!", "order": 41612, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 17, "scene": 14, "line": "No, what I'm saying is, he's not funny, but you're funnier than he is.", "order": 41613, "speaker": "Holly Flax"},
  {"season": 7, "episode": 17, "scene": 14, "line": "[seems taken aback] Uhm...", "order": 41614, "speaker": "Michael Scott"},
  {"season": 7, "episode": 17, "scene": 14, "line": "Ok: Bill Cosby [puts the ant tube high up. Each time she mentions a name it gets lower], Steve Martin, Charlie Bit My Finger, Michael Scott, then all the way down here [tube takes a jump to the bottom] Todd Packer.", "order": 41615, "speaker": "Holly Flax"},
  {"season": 7, "episode": 17, "scene": 14, "line": "That's insane!", "order": 41616, "speaker": "Michael Scott"},
  {"season": 7, "episode": 17, "scene": 14, "line": "Honey, he's a jerk.", "order": 41617, "speaker": "Holly Flax"},
  {"season": 7, "episode": 17, "scene": 14, "line": "[getting up and walking towards them, worriedly] For Pete's sake it just needs to be done! [takes the ant farm and tube from Holly and heads towards the annex]", "order": 41618, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 17, "scene": 15, "line": "[to Jim] So you two are married to each other now, right?", "order": 41619, "speaker": "Todd Packer"},
  {"season": 7, "episode": 17, "scene": 15, "line": "Yeah.", "order": 41620, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 17, "scene": 15, "line": "That's sweet. How's the sex?", "order": 41621, "speaker": "Todd Packer"},
  {"season": 7, "episode": 17, "scene": 15, "line": "[continues working]", "order": 41622, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 17, "scene": 15, "line": "[walks towards Todd] Hey Packer, I made you some hot chocolate.", "order": 41623, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 17, "scene": 15, "line": "Why?", "order": 41624, "speaker": "Todd Packer"},
  {"season": 7, "episode": 17, "scene": 15, "line": "Cause I wanna let bygones be bygones. Show you I'm cool. You're the new guy. It's cold out. I made too much. I got this awesome hot chocolate recipe from my wife.", "order": 41625, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 17, "scene": 15, "line": "That's a lot of reasons! [suspicious]", "order": 41626, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 17, "scene": 15, "line": "Drink it!", "order": 41627, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 17, "scene": 15, "line": "I think I'll pass. The only hot chocolate I'm into is Viva A Fox. [winks]", "order": 41628, "speaker": "Todd Packer"},
  {"season": 7, "episode": 17, "scene": 15, "line": "[interrupts Dwight trying to force feed Todd] Can I talk to you a second?", "order": 41629, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 17, "scene": 16, "line": "So, this hot chocolate thing.", "order": 41630, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 17, "scene": 16, "line": "None of your business.", "order": 41631, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 17, "scene": 16, "line": "Well, you know you can't actually poison him.", "order": 41632, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 17, "scene": 16, "line": "It wasn't poison Jim, it was a laxative. People take laxatives all the time. This is just, a lot more of a laxative. Let me handle this.", "order": 41633, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 17, "scene": 16, "line": "I really think we should join forces on this one.", "order": 41634, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 17, "scene": 16, "line": "Really.", "order": 41635, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 17, "scene": 16, "line": "What do we think, what would drive him crazy.", "order": 41636, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 17, "scene": 16, "line": "I know.", "order": 41637, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 17, "scene": 16, "line": "Here we go.", "order": 41638, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 17, "scene": 16, "line": "Pepto-Bismol, in his hot chocolate.", "order": 41639, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 17, "scene": 16, "line": "You've gotta stop with the hot chocolate stuff. I was thinking we can jam his drawers, so they only comes out two inches, then he can see everything in them but he can't get to them.", "order": 41640, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 17, "scene": 16, "line": "[mocking] Ooh does Edgar Allan Poe know about that one? So sinister! That wouldn't annoy a person at all! Where do you come up with this stuff?", "order": 41641, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 17, "scene": 16, "line": "Okay! Well this isn't my best, but call Froggy101, say that we're the tour manager for Justin Bieber, and we're giving away free tickets, we give him a number to call for the tickets, and it's his own number.", "order": 41642, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 17, "scene": 16, "line": "Who is Justice Beaver?", "order": 41643, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 17, "scene": 16, "line": "He... It's a crime fighting beaver.", "order": 41644, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 17, "scene": 16, "line": "Why don't you write up your best forty ideas and e-mail them to me. Can you do that?", "order": 41645, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 17, "scene": 16, "line": "Absolutely, I'll e-mail you a hundred.", "order": 41646, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 17, "scene": 16, "line": "Yeah, write up your list of one hundred, edit it down to your top forty, then e-mail it to me, and I'll read it over.", "order": 41647, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 17, "scene": 17, "line": "Pam. Can I talk to you in private?", "order": 41648, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 17, "scene": 17, "line": "I don't know if there's really a private place in this office.", "order": 41649, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 17, "scene": 17, "line": "Well they put a sign-up sheet on the conference room and I signed us up for three mods. A mod is five minutes. And it started two minutes ago.", "order": 41650, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 17, "scene": 17, "line": "[looking confused] You did that?", "order": 41651, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 17, "scene": 17, "line": "Can we talk about this in the meeting? Because we're a little late.", "order": 41652, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 17, "scene": 17, "line": "Okay. [walks to conference room with Andy and stares at the sign up sheet with wonder] When did people sign-", "order": 41653, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 17, "scene": 17, "line": "Mind if I close the door? [after shutting door in the conference room] What you did out there, earlier, was totally un-cool.", "order": 41654, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 17, "scene": 17, "line": "Well what was I supposed to do, let you walk all over me?", "order": 41655, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 17, "scene": 17, "line": "You humiliated me in front of every body!", "order": 41656, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 17, "scene": 17, "line": "Okay, well I didn't think about it like that. It's just, I can't do anything about it. I can't replace that one unless that computer breaks.", "order": 41657, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 17, "scene": 17, "line": "I mean, it's pretty broken already.", "order": 41658, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 17, "scene": 17, "line": "Yeah, well if it breaks all the way I can get you a new one.", "order": 41659, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 17, "scene": 17, "line": "[looks at her knowingly] Pretty sneaky sis. [knocking on conference room door]", "order": 41660, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 17, "scene": 17, "line": "[walking in] Hey cats, we got a jazz session in mod six, nine, and twelve. [Pam and Andy exit as Ryan and others holding saxophones walk in, Pam looks at the sign up sheet in awe]", "order": 41661, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 17, "scene": 18, "line": "[to Jim, the two are alone in the annex] There are over four hundred of these! [indicating a packet of paper]", "order": 41662, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 17, "scene": 18, "line": "Yeah I couldn't cut it down.", "order": 41663, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 17, "scene": 18, "line": "They're all good. So good! Number three: eat a frog. That sounds promising. Number four: eat a dog. I don't know, um, from practical stand point-", "order": 41664, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 17, "scene": 18, "line": "One thirty-five. Did you like one thirty-five?", "order": 41665, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 17, "scene": 18, "line": "Eat a brog. Maybe it's because I didn't understand it. I just had a couple of notes, let me grab a pen. [Dwight tries to open up a drawer on his desk but it only goes out two inches] Damn it. Gah! Just when we were getting going.", "order": 41666, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 17, "scene": 19, "line": "[Andy is seen clicking random things and warning boxes pop up. He pours coffee on his keyboard, puts bologna with mustard in his CD drive, and many other computer harming things] Allow all cookies? Why certainly! Pop-ups? Yes please! Bit torrent streaming from a Somalian music website? Yeah! Why not? [mock baby voice] Oh I hope you don't get sick Mr. Computer. [computer voice] Why are you doing this to me Andy? [normal voice again] Because I hate your programs!", "order": 41667, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 17, "scene": 20, "line": "[at Hank's with Michael, talking to Hank] And a '76 that's good to boot, I like that. [Hank smiles]", "order": 41668, "speaker": "Todd Packer"},
  {"season": 7, "episode": 17, "scene": 20, "line": "And you made Hank smile, that doesn't happen often. You're very charming. That is something you should take upstairs, and use on people that really matter. [Hank looks irritated again]", "order": 41669, "speaker": "Michael Scott"},
  {"season": 7, "episode": 17, "scene": 20, "line": "Why?", "order": 41670, "speaker": "Todd Packer"},
  {"season": 7, "episode": 17, "scene": 20, "line": "I don't know, Holly mentioned that there were some complaints. And that you had said some things about Kevin.", "order": 41671, "speaker": "Michael Scott"},
  {"season": 7, "episode": 17, "scene": 20, "line": "Holly said that?", "order": 41672, "speaker": "Todd Packer"},
  {"season": 7, "episode": 17, "scene": 20, "line": "Yeah.", "order": 41673, "speaker": "Michael Scott"},
  {"season": 7, "episode": 17, "scene": 20, "line": "She was laughing hysterically that whole time!", "order": 41674, "speaker": "Holy"},
  {"season": 7, "episode": 17, "scene": 20, "line": "I guess you said something weird about your daughter?", "order": 41675, "speaker": "Michael Scott"},
  {"season": 7, "episode": 17, "scene": 20, "line": "She asked me, Michael! It would've been rude not to answer.", "order": 41676, "speaker": "Todd Packer"},
  {"season": 7, "episode": 17, "scene": 20, "line": "You've been on the road a long time. And you've been an outdoor cat. And now you have to be an indoor cat, so you can't be peeing all over the walls.", "order": 41677, "speaker": "Michael Scott"},
  {"season": 7, "episode": 17, "scene": 20, "line": "Michael, can I open the kamomo with you. I've been on the road too long. But, I wanna connect with my daughter. And it's not right to call her a bitch in front of strangers.", "order": 41678, "speaker": "Todd Packer"},
  {"season": 7, "episode": 17, "scene": 20, "line": "No it isn't.", "order": 41679, "speaker": "Michael Scott"},
  {"season": 7, "episode": 17, "scene": 20, "line": "You're right. I gotta watch my behavior.", "order": 41680, "speaker": "Todd Packer"},
  {"season": 7, "episode": 17, "scene": 20, "line": "Yeah, a little bit.", "order": 41681, "speaker": "Michael Scott"},
  {"season": 7, "episode": 17, "scene": 20, "line": "Don't give up on me.", "order": 41682, "speaker": "Todd Packer"},
  {"season": 7, "episode": 17, "scene": 20, "line": "I won't.", "order": 41683, "speaker": "Michael Scott"},
  {"season": 7, "episode": 17, "scene": 21, "line": "[Michael and Todd are walking into the office] Okay, every body. I need you to see this. Because maybe there is somebody here that you all underestimated, who will surprise you. Todd Packer, is going to apologize. Kevin! Front and center, come here.", "order": 41684, "speaker": "Michael Scott"},
  {"season": 7, "episode": 17, "scene": 21, "line": "I got a lot of numbers here to put together.", "order": 41685, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 17, "scene": 21, "line": "Kevin, we know that you are just trying to save face, we know that you are hurt and embarrassed.", "order": 41686, "speaker": "Michael Scott"},
  {"season": 7, "episode": 17, "scene": 21, "line": "That's silly. If anything, I should be apologizing to Packer. Because we all know I can dish it, as good as I can take it.", "order": 41687, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 17, "scene": 21, "line": "Okay, sweetie, no. You shouldn't be apologizing to Packer. That doesn't make any sense. You ready for this? You ready for this? Gentlemen, start your engines.", "order": 41688, "speaker": "Michael Scott"},
  {"season": 7, "episode": 17, "scene": 21, "line": "I'm sorry if you were offended by my comments earlier.", "order": 41689, "speaker": "Todd Packer"},
  {"season": 7, "episode": 17, "scene": 21, "line": "Well, like I said, I wasn't offended, but I'll stand here for the big show.", "order": 41690, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 17, "scene": 21, "line": "Kevin, do you accept the apology?", "order": 41691, "speaker": "Michael Scott"},
  {"season": 7, "episode": 17, "scene": 21, "line": "Don't do it Kevin, that's the fake kind of apology.", "order": 41692, "speaker": "Kelly Kapoor"},
  {"season": 7, "episode": 17, "scene": 21, "line": "Okay, go back to the annex.", "order": 41693, "speaker": "Michael Scott"},
  {"season": 7, "episode": 17, "scene": 21, "line": "This is textbook. It's so uncool. Ryan does this to me all the time. Like it's some offense to have feelings. Don't do it Kevin.", "order": 41694, "speaker": "Kelly Kapoor"},
  {"season": 7, "episode": 17, "scene": 21, "line": "[muttering] Sometimes you over react.", "order": 41695, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 17, "scene": 21, "line": "Michael how's this supposed to work? Packer's gonna keep saying terrible things, and then he's just gonna make half-assed apologies. And we're back to square one.", "order": 41696, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 17, "scene": 21, "line": "Okay. You want an apology? Here it goes. Kevin, I am so sorry. You are skinny, and you are a genius.", "order": 41697, "speaker": "Todd Packer"},
  {"season": 7, "episode": 17, "scene": 21, "line": "That-That was maybe too much. Packer is a survivor of divorce Stanley. Packer doesn't speak with his child, Meredith. Packer never lived up to his parent's expectations, Andy. [Andy looks confused and shrugs] Angela loves pussy cats, and Packer loves-", "order": 41698, "speaker": "Michael Scott"},
  {"season": 7, "episode": 17, "scene": 21, "line": "[cutting in] No! Don't!", "order": 41699, "speaker": "Angela Martin"},
  {"season": 7, "episode": 17, "scene": 21, "line": "I was going to say dogs! Okay, you know what, this is over! Apology has been issued! And we're through with it. Packer will be here until the day he dies, just like the rest of us!", "order": 41700, "speaker": "Michael Scott"},
  {"season": 7, "episode": 17, "scene": 22, "line": "[in a car with Andy, by the dumpster you can see a new computer box] So listen, we have to really scuff this up.", "order": 41701, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 17, "scene": 22, "line": "No no no no no! We can say that the previous owner was a neat-freak. Or an elegant old lady and she just kept it around in case her grand-kids came to visit, but they died and they never came, I think I'm gonna make myself cry.", "order": 41702, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 17, "scene": 22, "line": "Andy, this is the deal we made.", "order": 41703, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 17, "scene": 22, "line": "[watching Pam scrap his computer] That's probably good. That's enough.", "order": 41704, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 17, "scene": 22, "line": "We should break this hinge maybe.", "order": 41705, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 17, "scene": 22, "line": "Let's not go crazy.", "order": 41706, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 17, "scene": 23, "line": "Well thanks, will do.", "order": 41707, "speaker": "Todd Packer"},
  {"season": 7, "episode": 17, "scene": 23, "line": "[using fake Southern accent, on the phone with Todd Packer, Dwight is also with Jim] Absolutely, now when you get down there, Jo's a little bit, uh, forgetful. So she may have locked the gate, but what you're gonna do is go ahead, hop it, and just head back to the pool. [Michael walks in on Jim and Dwight]", "order": 41708, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 17, "scene": 23, "line": "That sounds weird.", "order": 41709, "speaker": "Todd Packer"},
  {"season": 7, "episode": 17, "scene": 23, "line": "It is weird! Look at you, perceptive. Now I know why Jo's kept her eye on you!", "order": 41710, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 17, "scene": 23, "line": "[also In southern accent] And you make sure to get down there and check out that Harry Potter World.", "order": 41711, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 17, "scene": 23, "line": "[trying to stop him] Whatever you wanna do in your spare time is up to you!", "order": 41712, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 17, "scene": 23, "line": "Harry Potter World is supposed to be faaantastic!", "order": 41713, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 17, "scene": 24, "line": "Apparently, as soon as corporate found out I wanted to come in off the road, Jo offered me a cushy new job in Tallahassee. And here's the best part. I'm a huge alligator nerd. I can name you every genus, every sub species. Also I'm a huge boob nerd.", "order": 41714, "speaker": "Todd Packer"},
  {"season": 7, "episode": 17, "scene": 25, "line": "Check it out and have a free butterbeer on us. Keep the receipt and we'll get ya' back!", "order": 41715, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 17, "scene": 25, "line": "What are you doing?", "order": 41716, "speaker": "Michael Scott"},
  {"season": 7, "episode": 17, "scene": 25, "line": "[trying to hide from Michael what they're doing, he picks up the phone and talks directly into it] All right, so just pack your bags and, uh, be sure to bring those swimming trunks! Bye now! [hangs up]", "order": 41717, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 17, "scene": 25, "line": "I cannot believe this, you're sending Packer to Florida? Why?", "order": 41718, "speaker": "Michael Scott"},
  {"season": 7, "episode": 17, "scene": 25, "line": "He's a jerk.", "order": 41719, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 17, "scene": 25, "line": "He took my desk.", "order": 41720, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 17, "scene": 25, "line": "Okay, so you're tricking him into flying to Florida?", "order": 41721, "speaker": "Michael Scott"},
  {"season": 7, "episode": 17, "scene": 25, "line": "It wasn't my first choice, Jim had so many better ideas.", "order": 41722, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 17, "scene": 25, "line": "You have to tell him. You have to tell him what you did.", "order": 41723, "speaker": "Michael Scott"},
  {"season": 7, "episode": 17, "scene": 25, "line": "No, no, no. That is not part of the plan. That is actually anti-what we're doing.", "order": 41724, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 17, "scene": 25, "line": "He is my oldest friend, I am going to tell him.", "order": 41725, "speaker": "Michael Scott"},
  {"season": 7, "episode": 17, "scene": 25, "line": "Wait! Why don't we come up with a plan we're all happy about? [Michael leaves]", "order": 41726, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 17, "scene": 26, "line": "[plugging in new computer, acting to make people think he got a bad computer] Pam, how is this thing even any better than my old computer?", "order": 41727, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 17, "scene": 26, "line": "Come on Andy! I mean you said you wanted a new computer and this is the best I could do!", "order": 41728, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 17, "scene": 26, "line": "Where'd you even find this thing, like, in the corner in the warehouse?", "order": 41729, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 17, "scene": 26, "line": "Yes, I found it in a shelf in the corner of the warehouse. [Darryl gives the camera an odd look]", "order": 41730, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 17, "scene": 26, "line": "All right, well thank you for my garbage computer. [Andy turns it on and looks amazed, clearly happy with it]", "order": 41731, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 17, "scene": 27, "line": "[walking into Michael's office] You're looking at the new face of corporate. Gonna put the 'ass', in Tallahassee!", "order": 41732, "speaker": "Todd Packer"},
  {"season": 7, "episode": 17, "scene": 27, "line": "Yes, about that.", "order": 41733, "speaker": "Michael Scott"},
  {"season": 7, "episode": 17, "scene": 27, "line": "Well we gotta go out and celebrate, tonight!", "order": 41734, "speaker": "Todd Packer"},
  {"season": 7, "episode": 17, "scene": 27, "line": "Well... I don't know if that's a good idea.", "order": 41735, "speaker": "Michael Scott"},
  {"season": 7, "episode": 17, "scene": 27, "line": "Do you have a ball and chain?", "order": 41736, "speaker": "Todd Packer"},
  {"season": 7, "episode": 17, "scene": 27, "line": "No, nothing like that at all.", "order": 41737, "speaker": "Michael Scott"},
  {"season": 7, "episode": 17, "scene": 27, "line": "Listen, I'm gonna tell you something that none of these people have the stones to tell you. It's your girlfriend, man. She's uptight.", "order": 41738, "speaker": "Todd Packer"},
  {"season": 7, "episode": 17, "scene": 27, "line": "Sorry?", "order": 41739, "speaker": "Michael Scott"},
  {"season": 7, "episode": 17, "scene": 27, "line": "I know this stuff can hurt, but I wish someone had said something to me about my ex-wife. All I'm saying is about a month or so, meet me down in Florida, I'll introduce you to all the local [spanks the air] talent.", "order": 41740, "speaker": "Todd Packer"},
  {"season": 7, "episode": 17, "scene": 27, "line": "[thinks a moment] Sounds great.", "order": 41741, "speaker": "Michael Scott"},
  {"season": 7, "episode": 17, "scene": 27, "line": "It's gonna be so good.", "order": 41742, "speaker": "Todd Packer"},
  {"season": 7, "episode": 17, "scene": 27, "line": "That is. [Jim sees them and looks happy] It's gonna be awesome. I think you're really going to enjoy it down there.", "order": 41743, "speaker": "Michael Scott"},
  {"season": 7, "episode": 17, "scene": 28, "line": "I'm sorry about your friend.", "order": 41744, "speaker": "Holly Flax"},
  {"season": 7, "episode": 17, "scene": 28, "line": "Nah, he's an ass.", "order": 41745, "speaker": "Michael Scott"},
  {"season": 7, "episode": 17, "scene": 28, "line": "[Brooklyn accent] You ahh.", "order": 41746, "speaker": "Holly Flax"},
  {"season": 7, "episode": 17, "scene": 28, "line": "[imitating] You ahh.", "order": 41747, "speaker": "Michael Scott"},
  {"season": 7, "episode": 17, "scene": 28, "line": "What ah you wicked smaht?", "order": 41748, "speaker": "Holly Flax"},
  {"season": 7, "episode": 17, "scene": 28, "line": "No you ahh.", "order": 41749, "speaker": "Michael Scott"},
  {"season": 7, "episode": 17, "scene": 28, "line": "Who ahh?", "order": 41750, "speaker": "Holly Flax"},
  {"season": 7, "episode": 17, "scene": 28, "line": "[kisses her and speaks normally again] You ahh.", "order": 41751, "speaker": "Michael Scott"},
  {"season": 7, "episode": 17, "scene": 29, "line": "So this new computer you found in the warehouse.", "order": 41752, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 17, "scene": 29, "line": "Yep. Lucked out.", "order": 41753, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 17, "scene": 29, "line": "Yeah, you really did. 'Cause I know every INCH of that warehouse.", "order": 41754, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 17, "scene": 29, "line": "Yep, super lucky.", "order": 41755, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 17, "scene": 29, "line": "Maybe you could go back down there and see if you can find me some extra sick days.", "order": 41756, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 17, "scene": 29, "line": "[hesitating at the bribe] Yeah. You know what , I think I saw ONE sick day down there.", "order": 41757, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 17, "scene": 29, "line": "Really? 'Cause I think maybe I saw five.", "order": 41758, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 17, "scene": 29, "line": "Three. [Daryl nods]", "order": 41759, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 17, "scene": 30, "line": "I'm full on corrupt!", "order": 41760, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 18, "scene": 1, "line": "Hey Oscar, big Will and Grace fan huh?", "order": 41761, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 18, "scene": 1, "line": "No. Everybody assumes I am. I always get them as gifts.", "order": 41762, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 18, "scene": 1, "line": "Oh.", "order": 41763, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 18, "scene": 2, "line": "Dunder Mifflin is having our first own garage sale. Like many Americans, we realized we had a lot of things that we really didn't need. And 10 cents of every dollar is going into the party fund so we can throw parties for ourselves.", "order": 41764, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 18, "scene": 3, "line": "People! Look alive. It's showtime. Doors open in 3...2...1.", "order": 41765, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 18, "scene": 3, "line": "Oh, yikes.", "order": 41766, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 18, "scene": 3, "line": "It's cold Dwight. Just shut the door and let people come in the front.", "order": 41767, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 18, "scene": 3, "line": "It's good that its cold it will drive business inside.", "order": 41768, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 18, "scene": 3, "line": "The signs will drive people inside.", "order": 41769, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 18, "scene": 3, "line": "The warmth will lure people inside.", "order": 41770, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 18, "scene": 3, "line": "Close it.", "order": 41771, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 18, "scene": 3, "line": "This is how business...", "order": 41772, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 18, "scene": 3, "line": "Close it.", "order": 41773, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 18, "scene": 3, "line": "I'm not closing the door.", "order": 41774, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 18, "scene": 3, "line": "Close it. [starts wrestling Dwight]", "order": 41775, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 18, "scene": 3, "line": "Stop it.", "order": 41776, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 18, "scene": 3, "line": "Just close it.", "order": 41777, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 18, "scene": 4, "line": "Uh oh, looks like someone's sign is a little crooked. Uh, looks awful. Need a thumb tack?", "order": 41778, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 18, "scene": 4, "line": "Sure.", "order": 41779, "speaker": "Meredith Palmer"},
  {"season": 7, "episode": 18, "scene": 4, "line": "Not so fast.", "order": 41780, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 18, "scene": 5, "line": "Schrute's are farmers by hobby, and traders by trade. Through the art of the swap, I will walk out of this garage sale with the finest item here.", "order": 41781, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 18, "scene": 6, "line": "I will trade you for the used candle.", "order": 41782, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 18, "scene": 6, "line": "Forget you.", "order": 41783, "speaker": "Meredith Palmer"},
  {"season": 7, "episode": 18, "scene": 6, "line": "Come on, its trash, like your sign, unless you have this.", "order": 41784, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 18, "scene": 6, "line": "Fine, just give it.", "order": 41785, "speaker": "Meredith Palmer"},
  {"season": 7, "episode": 18, "scene": 6, "line": "That's how it's done.", "order": 41786, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 18, "scene": 7, "line": "Woah, I love her. How much for that?", "order": 41787, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 18, "scene": 7, "line": "I uh... I actually don't know why I brought that because It's kind of priceless.", "order": 41788, "speaker": "Michael Scott"},
  {"season": 7, "episode": 18, "scene": 7, "line": "10 dollars.", "order": 41789, "speaker": "Holly Flax"},
  {"season": 7, "episode": 18, "scene": 7, "line": "Mhmm, no I paid 500 dollars for that.", "order": 41790, "speaker": "Michael Scott"},
  {"season": 7, "episode": 18, "scene": 7, "line": "200", "order": 41791, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 18, "scene": 7, "line": "500", "order": 41792, "speaker": "Michael Scott"},
  {"season": 7, "episode": 18, "scene": 7, "line": "20", "order": 41793, "speaker": "Holly Flax"},
  {"season": 7, "episode": 18, "scene": 7, "line": "45", "order": 41794, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 18, "scene": 7, "line": "Get lost.", "order": 41795, "speaker": "Michael Scott"},
  {"season": 7, "episode": 18, "scene": 7, "line": "Damn it.", "order": 41796, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 18, "scene": 7, "line": "That is how you do it.", "order": 41797, "speaker": "Michael Scott"},
  {"season": 7, "episode": 18, "scene": 7, "line": "You know we don't have to sell that if you don't want to. If it's a problem with the neon I can have my neon guy take a look.", "order": 41798, "speaker": "Holly Flax"},
  {"season": 7, "episode": 18, "scene": 7, "line": "You know it's really more for a bachelor pad. I just want to get a fair price.", "order": 41799, "speaker": "Michael Scott"},
  {"season": 7, "episode": 18, "scene": 7, "line": "Well we could put it in storage... in case.", "order": 41800, "speaker": "Holly Flax"},
  {"season": 7, "episode": 18, "scene": 7, "line": "In case... in case of what?", "order": 41801, "speaker": "Michael Scott"},
  {"season": 7, "episode": 18, "scene": 7, "line": "You know... in case if something changes.", "order": 41802, "speaker": "Holly Flax"},
  {"season": 7, "episode": 18, "scene": 7, "line": "I don't have an in case. Do you have an in case?", "order": 41803, "speaker": "Michael Scott"},
  {"season": 7, "episode": 18, "scene": 7, "line": "Nope.", "order": 41804, "speaker": "Holly Flax"},
  {"season": 7, "episode": 18, "scene": 7, "line": "How much for the slip and slide?", "order": 41805, "speaker": "Lady"},
  {"season": 7, "episode": 18, "scene": 7, "line": "Get lost.", "order": 41806, "speaker": "Michael Scott"},
  {"season": 7, "episode": 18, "scene": 8, "line": "[on phone] Yes, hello Mr. Flax this is Michael Scott, your daughter's boss. I am calling because I am going to have to fire your daughter Holly because she is such a terrible employee. I'm just kidding. I'm kidding. I'm actually calling because I'm in love with her. I love your daughter, and I have for some time. And I would like to discuss my intentions with you... which are to ask her to marry me. And I was just hoping you would give me your approval. And this isn't a joke. So call me back, when you get this. And I look forward to speaking. Thank you.", "order": 41807, "speaker": "Michael Scott"},
  {"season": 7, "episode": 18, "scene": 9, "line": "Are you serious? A half used candle? Get out of here Dwight. You're blocking my table with your giant body.", "order": 41808, "speaker": "Kelly Kapoor"},
  {"season": 7, "episode": 18, "scene": 9, "line": "Oh, cause there's a huge line of people waiting in line who want your spinster chotskies.", "order": 41809, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 18, "scene": 9, "line": "Screw you man. People like my stuff.", "order": 41810, "speaker": "Kelly Kapoor"},
  {"season": 7, "episode": 18, "scene": 9, "line": "People like Ryan? Let me tell you about men like him. He comes over and you're like 'Hey baby let me light a candle' then you pull out this one half used and he's like 'Uh! Who else is she seeing? I better lock her down fast.'", "order": 41811, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 18, "scene": 9, "line": "You can take my Helen Fielding collection.", "order": 41812, "speaker": "Kelly Kapoor"},
  {"season": 7, "episode": 18, "scene": 9, "line": "And the Jennifer Weiner collection.", "order": 41813, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 18, "scene": 10, "line": "Oh my gosh, you have the Dallas board game?", "order": 41814, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 18, "scene": 10, "line": "Yea when I was a kid I was on Dallas.", "order": 41815, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 18, "scene": 10, "line": "Really?", "order": 41816, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 18, "scene": 10, "line": "Yea. When I was kid we missed our connecting flight and we spent the entire day on Dallas. Then we spent a week on Hawaii... I was in heaven.", "order": 41817, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 18, "scene": 10, "line": "We should play it.", "order": 41818, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 18, "scene": 10, "line": "Well... there's no instructions.", "order": 41819, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 18, "scene": 10, "line": "It's Dallas the game, we can figure it out.", "order": 41820, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 18, "scene": 10, "line": "Yea.", "order": 41821, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 18, "scene": 10, "line": "I'm pretty good at board games.", "order": 41822, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 18, "scene": 10, "line": "Well, shall we make it a little interesting?", "order": 41823, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 18, "scene": 10, "line": "Sure.", "order": 41824, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 18, "scene": 11, "line": "What is this?", "order": 41825, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 18, "scene": 11, "line": "How did those get out? I'm sorry.", "order": 41826, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 18, "scene": 11, "line": "Professor Copperfield's Miracle Legumes?", "order": 41827, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 18, "scene": 11, "line": "I was in Jamaica, and I got lost, and it was getting dark this one night and then out of nowhere with a cart and he's selling these. Dwight he's telling me things about myself that there's no way he could have known.", "order": 41828, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 18, "scene": 11, "line": "That's a common swindlers trick.", "order": 41829, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 18, "scene": 11, "line": "Probably, probably. So I buy some, I turn the corner, I feel like an idiot, so I go back to get my money, he was gone.", "order": 41830, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 18, "scene": 11, "line": "So you wanna sell me magic beans. [laughs]", "order": 41831, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 18, "scene": 11, "line": "Correction. I do not want to sell you Professor Copperfield's Miracle Legumes.", "order": 41832, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 18, "scene": 11, "line": "Nice try. No. Correction. Terrible try.", "order": 41833, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 18, "scene": 12, "line": "Michael called Dad?", "order": 41834, "speaker": "Holly Flax"},
  {"season": 7, "episode": 18, "scene": 12, "line": "Your friend Michael, yes. What's going on?", "order": 41835, "speaker": "Holly Flax's Mom"},
  {"season": 7, "episode": 18, "scene": 12, "line": "Um, I think I know. But I'll sound stupid if I'm wrong but is Dad there?", "order": 41836, "speaker": "Holly Flax"},
  {"season": 7, "episode": 18, "scene": 12, "line": "Holly?", "order": 41837, "speaker": "Holly Flax's Dad"},
  {"season": 7, "episode": 18, "scene": 12, "line": "Hi Dad.", "order": 41838, "speaker": "Holly Flax"},
  {"season": 7, "episode": 18, "scene": 12, "line": "There was a program on TV about India.", "order": 41839, "speaker": "Holly Flax's Dad"},
  {"season": 7, "episode": 18, "scene": 12, "line": "Um, ok. Did you connect with Michael?", "order": 41840, "speaker": "Holly Flax"},
  {"season": 7, "episode": 18, "scene": 12, "line": "Wanna watch it?", "order": 41841, "speaker": "Holly Flax's Dad"},
  {"season": 7, "episode": 18, "scene": 12, "line": "I... I'm not there Dad, I'm in Pennsylvania.", "order": 41842, "speaker": "Holly Flax"},
  {"season": 7, "episode": 18, "scene": 12, "line": "What are you doing there?", "order": 41843, "speaker": "Holly Flax's Dad"},
  {"season": 7, "episode": 18, "scene": 12, "line": "I live here. Um, can I talk to you about Michael?", "order": 41844, "speaker": "Holly Flax"},
  {"season": 7, "episode": 18, "scene": 12, "line": "I'll, I'll put your mother on.", "order": 41845, "speaker": "Holly Flax's Dad"},
  {"season": 7, "episode": 18, "scene": 12, "line": "No. No... no Dad.", "order": 41846, "speaker": "Holly Flax"},
  {"season": 7, "episode": 18, "scene": 12, "line": "Holly?", "order": 41847, "speaker": "Holly Flax's Mom"},
  {"season": 7, "episode": 18, "scene": 12, "line": "Mom, Dad can't seem to focus on a subject.", "order": 41848, "speaker": "Holly Flax"},
  {"season": 7, "episode": 18, "scene": 12, "line": "Don't you worry about him, he's got me.", "order": 41849, "speaker": "Holly Flax's Mom"},
  {"season": 7, "episode": 18, "scene": 12, "line": "Well who do you have?", "order": 41850, "speaker": "Holly Flax"},
  {"season": 7, "episode": 18, "scene": 12, "line": "I have your father. He's right here I'll put him on.", "order": 41851, "speaker": "Holly Flax's Mom"},
  {"season": 7, "episode": 18, "scene": 12, "line": "No. Mom?", "order": 41852, "speaker": "Holly Flax"},
  {"season": 7, "episode": 18, "scene": 13, "line": "Oh, which one is decaf?", "order": 41853, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 18, "scene": 13, "line": "[looks out window] Michael! Michael!", "order": 41854, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 18, "scene": 13, "line": "Hi.", "order": 41855, "speaker": "Michael Scott"},
  {"season": 7, "episode": 18, "scene": 13, "line": "Hi.. I was just coming out to see what you were doing and to maybe stop you.", "order": 41856, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 18, "scene": 13, "line": "Oh, you know what, you have a siphon? I think I'm gonna run out of gas.", "order": 41857, "speaker": "Michael Scott"},
  {"season": 7, "episode": 18, "scene": 13, "line": "Why do you need more gas?", "order": 41858, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 18, "scene": 13, "line": "Well, I'm writing a message.", "order": 41859, "speaker": "Michael Scott"},
  {"season": 7, "episode": 18, "scene": 13, "line": "Is it a good message or a bad message?", "order": 41860, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 18, "scene": 13, "line": "I'm asking Holly a question in fire.", "order": 41861, "speaker": "Michael Scott"},
  {"season": 7, "episode": 18, "scene": 13, "line": "Are you proposing!?", "order": 41862, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 18, "scene": 13, "line": "Maybe.", "order": 41863, "speaker": "Michael Scott"},
  {"season": 7, "episode": 18, "scene": 13, "line": "Wow.", "order": 41864, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 18, "scene": 13, "line": "Hey you know what? I've got gas all over my hands and my shoes. Would you light it? Would you do the honors please?", "order": 41865, "speaker": "Michael Scott"},
  {"season": 7, "episode": 18, "scene": 13, "line": "Yea. Yea no problem. [runs away]", "order": 41866, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 18, "scene": 13, "line": "Pam.", "order": 41867, "speaker": "Michael Scott"},
  {"season": 7, "episode": 18, "scene": 13, "line": "Yep.", "order": 41868, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 18, "scene": 13, "line": "Could you light this please?", "order": 41869, "speaker": "Micahel"},
  {"season": 7, "episode": 18, "scene": 13, "line": "Michael, you've had two ideas today. And one of them was great. And the other one was terrible.", "order": 41870, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 18, "scene": 13, "line": "I am not in the mood for riddles pam.", "order": 41871, "speaker": "Michael Scott"},
  {"season": 7, "episode": 18, "scene": 13, "line": "This is terrible.", "order": 41872, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 18, "scene": 13, "line": "No, this is romantic.", "order": 41873, "speaker": "Michael Scott"},
  {"season": 7, "episode": 18, "scene": 13, "line": "You know what? I'm gonna get a hose. Then we'll talk about it. Ok we'll figure it out. Be right back. Just stay there.", "order": 41874, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 18, "scene": 14, "line": "Free sample?", "order": 41875, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 18, "scene": 15, "line": "My mom makes the best pesto in the world. And I always tell her 'Mom you should sell this, you'd make a fortune' and she always says 'No it's just for family.' Well finally I was like f*** it, I'll sell it so I'm like 'Mom, I need you to make a ton of pesto for a pesto party for all my friends' and she's like 'uh, ok' ... pesto party? Really? Anyway... she makes like a hundred bottles worth. It's so good. And Phyllis... just had that mom look I wanted.", "order": 41876, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 18, "scene": 16, "line": "You got this kosher certified?", "order": 41877, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 18, "scene": 16, "line": "No I meant like uh, it's cool, its kosher, it's all good.", "order": 41878, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 18, "scene": 16, "line": "Ryan, you have such an interesting mind. So creative. All these new business ideas and artistic projects. [laughs]", "order": 41879, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 18, "scene": 16, "line": "Thank you. Whatcha got there?", "order": 41880, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 18, "scene": 16, "line": "Oh, its just Stanley's old photo album. I'm thinking about throwing it in the garbage. I mean, why would I want some random black mans old photo album on my bookshelf. I'm not James Franco.", "order": 41881, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 18, "scene": 17, "line": "things are going very well. I traded a thumb tack for Meredith's junk for Kelly's crap for Phyllis' garbage for Oscars trash for Stanley's garbage for Ryan's junk for Creed's garbage for a very cute squid that Erin happened to have.", "order": 41882, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 18, "scene": 18, "line": "Jim, put those away.", "order": 41883, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 18, "scene": 18, "line": "[pulls away beans] I'm really sorry. Pam must have put those out.", "order": 41884, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 18, "scene": 18, "line": "just out of curiosity, what were the claims for those beans?", "order": 41885, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 18, "scene": 18, "line": "They're legumes Dwight, and you're just gonna make fun of me, so why would I... You know what, this ends now. [crumples up beans, stomps on them, and throws them in a box]", "order": 41886, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 18, "scene": 19, "line": "Hey Michael everyone's in the conference room.", "order": 41887, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 18, "scene": 19, "line": "Why?", "order": 41888, "speaker": "Michael Scott"},
  {"season": 7, "episode": 18, "scene": 19, "line": "You called the meeting I don't..", "order": 41889, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 18, "scene": 19, "line": "I did?", "order": 41890, "speaker": "Michael Scott"},
  {"season": 7, "episode": 18, "scene": 20, "line": "Ok. Thank you all for coming. I would like to talk to you all today about... recycling.", "order": 41891, "speaker": "Michael Scott"},
  {"season": 7, "episode": 18, "scene": 20, "line": "Michael.", "order": 41892, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 18, "scene": 20, "line": "Yes.", "order": 41893, "speaker": "Michael Scott"},
  {"season": 7, "episode": 18, "scene": 20, "line": "We are a family.", "order": 41894, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 18, "scene": 20, "line": "I could not have agreed with you more.", "order": 41895, "speaker": "Michael Scott"},
  {"season": 7, "episode": 18, "scene": 20, "line": "And I've always believed that we should all be involved in everyone's personal lives, in a very major way.", "order": 41896, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 18, "scene": 20, "line": "Yes. Thank you.", "order": 41897, "speaker": "Michael Scott"},
  {"season": 7, "episode": 18, "scene": 20, "line": "So about this proposal thing...", "order": 41898, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 18, "scene": 20, "line": "No, no, no, no. My minds made up I am not going to change my mind you can't talk me out of it.", "order": 41899, "speaker": "Michael Scott"},
  {"season": 7, "episode": 18, "scene": 20, "line": "Michael, she's perfect for you.", "order": 41900, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 18, "scene": 20, "line": "She's the one.", "order": 41901, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 18, "scene": 20, "line": "She's amazing. This is very exciting.", "order": 41902, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 18, "scene": 20, "line": "So we just... we wanna help you plan the proposal so that it's safe and responsible and realistic and doable.", "order": 41903, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 18, "scene": 20, "line": "I had a great idea until you ruined it.", "order": 41904, "speaker": "Michael Scott"},
  {"season": 7, "episode": 18, "scene": 20, "line": "Wanna know how to do it? Here's how you do it. Take her out to dinner. Go down on one knee. If you are in costume, you did something wrong. If at any point you find yourself tying a ring to a dogs collar, stop, and look at yourself.", "order": 41905, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 18, "scene": 21, "line": "[playing the Dallas board game] Hmm, I'm gonna play me a little ol' black mail card .", "order": 41906, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 18, "scene": 21, "line": "Nice.", "order": 41907, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 18, "scene": 21, "line": "And call a proxy meeting to take control of you and oil once and for all.", "order": 41908, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 18, "scene": 21, "line": "No you cant do that. You cant play a black mail card and call a proxy meeting in the same turn.", "order": 41909, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 18, "scene": 21, "line": "Why not. Seems to me we're just making up rules cause somebody forgot to staple the rules to the inside of the game like a normal human being.", "order": 41910, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 18, "scene": 21, "line": "And I'm gonna play this here share the wealth card.", "order": 41911, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 18, "scene": 21, "line": "Oooh.", "order": 41912, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 18, "scene": 21, "line": "Which entitles me to half of both of y'alls money so if you don't mind.", "order": 41913, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 18, "scene": 21, "line": "no this card is from the wrong game this is from the game of life.", "order": 41914, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 18, "scene": 21, "line": "It was in the box.", "order": 41915, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 18, "scene": 21, "line": "Well played.", "order": 41916, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 18, "scene": 21, "line": "Thank you.", "order": 41917, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 18, "scene": 21, "line": "We must honorably adhere to the rules we are making up on the spot.", "order": 41918, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 18, "scene": 21, "line": "No but that's not fair.", "order": 41919, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 18, "scene": 21, "line": "Well that's Dallas.", "order": 41920, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 18, "scene": 21, "line": "Dallas indeed.", "order": 41921, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 18, "scene": 22, "line": "What... Ryan, where did you get this picture? [a picture of Oscar on Ryans bottles of salsa]", "order": 41922, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 18, "scene": 23, "line": "My mom also makes the best salsa.", "order": 41923, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 18, "scene": 24, "line": "This is your moms old stuff?", "order": 41924, "speaker": "Holly Flax"},
  {"season": 7, "episode": 18, "scene": 24, "line": "Yeah mostly.", "order": 41925, "speaker": "Phyllis Vance"},
  {"season": 7, "episode": 18, "scene": 24, "line": "How's she doing?", "order": 41926, "speaker": "Holly Flax"},
  {"season": 7, "episode": 18, "scene": 24, "line": "It didn't go down so easy but she's made some friends and it's already better than it was.", "order": 41927, "speaker": "Phyllis Vance"},
  {"season": 7, "episode": 18, "scene": 24, "line": "How do you know when its time?", "order": 41928, "speaker": "Holly Flax"},
  {"season": 7, "episode": 18, "scene": 24, "line": "Uh I don't know if you ever know. And if you wait for the day when your parent to comes to you and says 'I cant take care of myself anymore' its never gonna happen. I have a box of bras under the table if you're interested.", "order": 41929, "speaker": "Phyllis Vance"},
  {"season": 7, "episode": 18, "scene": 24, "line": "Let me see.", "order": 41930, "speaker": "Holly Flax"},
  {"season": 7, "episode": 18, "scene": 25, "line": "Ok I think animals and proposals are out. Right Ryan didn't you read in one of your blogs that animals are out?", "order": 41931, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 18, "scene": 25, "line": "Blogs are out but people are texting each other no more animals", "order": 41932, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 18, "scene": 25, "line": "How about this I throw a corpse dressed like me off of the roof, it hits the ground, the head pops off, this leads me to the line ' I lost my head when I fell in love with you.'", "order": 41933, "speaker": "Michael Scott"},
  {"season": 7, "episode": 18, "scene": 25, "line": "That's a guarantee.", "order": 41934, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 18, "scene": 25, "line": "It's easy enough to get a corpse, you just go to a med school. I already have the ring.", "order": 41935, "speaker": "Michael Scott"},
  {"season": 7, "episode": 18, "scene": 25, "line": "Don't think you need the corpse then.", "order": 41936, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 18, "scene": 25, "line": "Heres the ring.", "order": 41937, "speaker": "Michael Scott"},
  {"season": 7, "episode": 18, "scene": 25, "line": "Holy s*** is that real?", "order": 41938, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 18, "scene": 25, "line": "Yeah. I saved 3 years salary.", "order": 41939, "speaker": "Michael Scott"},
  {"season": 7, "episode": 18, "scene": 25, "line": "No. [shakes head back and forth]", "order": 41940, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 18, "scene": 25, "line": "Is she not going to like that?", "order": 41941, "speaker": "Michael Scott"},
  {"season": 7, "episode": 18, "scene": 25, "line": "No she's going to love it so I think you can keep the proposal simple. You know like when Jim proposed. He just he got down on one knee and he told me that he loved me and he asked me to marry him and it was perfect.", "order": 41942, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 18, "scene": 25, "line": "Where was that.", "order": 41943, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 18, "scene": 25, "line": "At a gas station. [oscar laughs]", "order": 41944, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 18, "scene": 25, "line": "At a gas station?", "order": 41945, "speaker": "Michael Scott"},
  {"season": 7, "episode": 18, "scene": 25, "line": "Oh it was when she was working in New York so it was halfway between both of us.", "order": 41946, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 18, "scene": 25, "line": "That mush have been a surprise when... at the gas station you proposed.", "order": 41947, "speaker": "Michael Scott"},
  {"season": 7, "episode": 18, "scene": 25, "line": "No it was really it was really sweet. It was raining and..", "order": 41948, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 18, "scene": 25, "line": "Oh yea you didn't say that the weather was bad that sounds perfect. I want this to be an event that everyone talks about always and forever.", "order": 41949, "speaker": "Michael Scott"},
  {"season": 7, "episode": 18, "scene": 25, "line": "Totally reasonable.", "order": 41950, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 18, "scene": 25, "line": "I just... it's terrifying.", "order": 41951, "speaker": "Michael Scott"},
  {"season": 7, "episode": 18, "scene": 25, "line": "She's not gonna say no.", "order": 41952, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 18, "scene": 25, "line": "I know but I'm still scared I don't know why.", "order": 41953, "speaker": "Michael Scott"},
  {"season": 7, "episode": 18, "scene": 25, "line": "Cause it's a big deal. I mean.. I knew Pam was gonna say yes but I was still scared.", "order": 41954, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 18, "scene": 25, "line": "You were scared?", "order": 41955, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 18, "scene": 25, "line": "Yeah... it.s scary.", "order": 41956, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 18, "scene": 26, "line": "Get this... Kevin thought I was gonna sell my Bowflex for 200 dollars and I told him 'Dude this was a prop in my movie.'", "order": 41957, "speaker": "Michael Scott"},
  {"season": 7, "episode": 18, "scene": 26, "line": "Look I need to talk to you.", "order": 41958, "speaker": "Holly Flax"},
  {"season": 7, "episode": 18, "scene": 26, "line": "Ok it's because we haven't sold anything. You know what? We just wait until the end of the day, people get desperate, and they're gonna pay anything.", "order": 41959, "speaker": "Michael Scott"},
  {"season": 7, "episode": 18, "scene": 26, "line": "I think I need to go home to Colorado. My dad isn't doing so well.", "order": 41960, "speaker": "Holly Flax"},
  {"season": 7, "episode": 18, "scene": 26, "line": "Oh ok. For how long?", "order": 41961, "speaker": "Michael Scott"},
  {"season": 7, "episode": 18, "scene": 26, "line": "I don't know.", "order": 41962, "speaker": "Holly Flax"},
  {"season": 7, "episode": 18, "scene": 26, "line": "Is he alright?", "order": 41963, "speaker": "Michael Scott"},
  {"season": 7, "episode": 18, "scene": 26, "line": "Yeah.", "order": 41964, "speaker": "Holly Flax"},
  {"season": 7, "episode": 18, "scene": 26, "line": "You ok?", "order": 41965, "speaker": "Michael Scott"},
  {"season": 7, "episode": 18, "scene": 26, "line": "Yeah, yeah it's just you know I don't want to go home when he's on his death bed you know, I wanna be there when he's still my dad.", "order": 41966, "speaker": "Holly Flax"},
  {"season": 7, "episode": 18, "scene": 26, "line": "Yeah, yeah well you definitely need to do that.", "order": 41967, "speaker": "Michael Scott"},
  {"season": 7, "episode": 18, "scene": 26, "line": "And I want you to come with me. Now I know that's a lot to ask of a boyfriend..", "order": 41968, "speaker": "Holly Flax"},
  {"season": 7, "episode": 18, "scene": 26, "line": "Ok.", "order": 41969, "speaker": "Michael Scott"},
  {"season": 7, "episode": 18, "scene": 26, "line": "And I was thinking... you know maybe since given our last conversation that we're both ready... Michael Scott will you..", "order": 41970, "speaker": "Holly Flax"},
  {"season": 7, "episode": 18, "scene": 26, "line": "No, no, shhhhut it. Mhmm. Oh God. Nope, nope, nope.", "order": 41971, "speaker": "Michael Scott"},
  {"season": 7, "episode": 18, "scene": 27, "line": "Uh, what?", "order": 41972, "speaker": "Holly Flax"},
  {"season": 7, "episode": 18, "scene": 28, "line": "No. I am not going to be proposed to in the break room. That is not going to be our story. Mhm hm. Should have burned this place down when I had the chance.", "order": 41973, "speaker": "Michael Scott"},
  {"season": 7, "episode": 18, "scene": 29, "line": "Hey guys it's my turn.", "order": 41974, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 18, "scene": 29, "line": "You cant go, you're dead, I shot you 5 moves ago.", "order": 41975, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 18, "scene": 29, "line": "Yeah I told you, you can't shoot people.", "order": 41976, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 18, "scene": 29, "line": "I told you we're way past rules. We have been for some time now. We start having rules now, everything we built collapses.", "order": 41977, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 18, "scene": 29, "line": "This is stupid and I want my money back. Where's the money?", "order": 41978, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 18, "scene": 29, "line": "Yeah where is it?", "order": 41979, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 18, "scene": 29, "line": "Ok you know what? I am never ever playing board games with you two again.", "order": 41980, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 18, "scene": 30, "line": "And that is Dallas. [shows money]", "order": 41981, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 18, "scene": 31, "line": "Nice.", "order": 41982, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 18, "scene": 31, "line": "Not bad right. [swinging golf club]", "order": 41983, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 18, "scene": 31, "line": "Can't really tell the...", "order": 41984, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 18, "scene": 31, "line": "[to Dwight] Nice.", "order": 41985, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 18, "scene": 31, "line": "I know right...[sees Jim's beans] what the?", "order": 41986, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 18, "scene": 31, "line": "Oh my God.", "order": 41987, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 18, "scene": 31, "line": "That... that's impossible.", "order": 41988, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 18, "scene": 31, "line": "It is right. I mean, it's impossible.", "order": 41989, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 18, "scene": 31, "line": "All right, I'll take them.", "order": 41990, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 18, "scene": 31, "line": "They're probably worthless.", "order": 41991, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 18, "scene": 31, "line": "Probably. [walks away with beans]", "order": 41992, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 18, "scene": 31, "line": "Leave the telescope.", "order": 41993, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 18, "scene": 32, "line": "I started with a thumb tack and traded my way to a telescope. But in a way the most valuable thing here wasn't the telescope at all. No, it was this... packet of beans. So I traded the telescope for it. And I can just go buy another telescope.", "order": 41994, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 18, "scene": 33, "line": "Hey, how'd we do?", "order": 41995, "speaker": "Michael Scott"},
  {"season": 7, "episode": 18, "scene": 33, "line": "13 dollars.", "order": 41996, "speaker": "Holly Flax"},
  {"season": 7, "episode": 18, "scene": 33, "line": "That's great and we still have most of our stuff. Good.", "order": 41997, "speaker": "Michael Scott"},
  {"season": 7, "episode": 18, "scene": 33, "line": "Listen about earlier ...", "order": 41998, "speaker": "Holly Flax"},
  {"season": 7, "episode": 18, "scene": 33, "line": "No, no, no, no, lets not talk about that.", "order": 41999, "speaker": "Michael Scott"},
  {"season": 7, "episode": 18, "scene": 33, "line": "It was wrong to put you in that position and... Michael you are my life now. I'm not going to Colorado.", "order": 42000, "speaker": "Holly Flax"},
  {"season": 7, "episode": 18, "scene": 33, "line": "Shhhh. Let's go on a little walk. You know what? I wanna show you some stuff.", "order": 42001, "speaker": "Michael Scott"},
  {"season": 7, "episode": 18, "scene": 34, "line": "So this is one of my favorite places in the world.", "order": 42002, "speaker": "Michael Scott"},
  {"season": 7, "episode": 18, "scene": 34, "line": "Why?", "order": 42003, "speaker": "Holly Flax"},
  {"season": 7, "episode": 18, "scene": 34, "line": "This is where Toby announced that he was going to Costa Rica. Happiest day of my life. Until the day you came to replace him. Let's go in here.", "order": 42004, "speaker": "Michael Scott"},
  {"season": 7, "episode": 18, "scene": 35, "line": "This is where we first kissed.", "order": 42005, "speaker": "Michael Scott"},
  {"season": 7, "episode": 18, "scene": 35, "line": "I remember.", "order": 42006, "speaker": "Holly Flax"},
  {"season": 7, "episode": 18, "scene": 35, "line": "And this is where we first made love. Remember what I tried there?", "order": 42007, "speaker": "Michael Scott"},
  {"season": 7, "episode": 18, "scene": 35, "line": "Michael.. [they both laugh]", "order": 42008, "speaker": "Holly Flax"},
  {"season": 7, "episode": 18, "scene": 36, "line": "Through these blinds is where I first saw you, and you had all these boxes, and I thought you were the prettiest mover I had ever seen. And I was sitting at this desk when I called you to tell you that I had herpes and that I was still in love with you and you said that it was over, and that you didn't love me. Thank goodness none of that was true. Including the herpes.", "order": 42009, "speaker": "Michael Scott"},
  {"season": 7, "episode": 18, "scene": 36, "line": "Yes.", "order": 42010, "speaker": "Holly Flax"},
  {"season": 7, "episode": 18, "scene": 36, "line": "In-grown hair.", "order": 42011, "speaker": "Michael Scott"},
  {"season": 7, "episode": 18, "scene": 37, "line": "Right in here... this is where we first co-ran our meeting. Remember, obesity awareness?", "order": 42012, "speaker": "Michael Scott"},
  {"season": 7, "episode": 18, "scene": 37, "line": "Mh hmm.", "order": 42013, "speaker": "Holly Flax"},
  {"season": 7, "episode": 18, "scene": 37, "line": "We saved a lot of lives that day. That's where you first met Michael Klump.", "order": 42014, "speaker": "Michael Scott"},
  {"season": 7, "episode": 18, "scene": 37, "line": "Oh, I say, I say, I say I sit on you!", "order": 42015, "speaker": "Holly Flax"},
  {"season": 7, "episode": 18, "scene": 37, "line": "And right over there is where you found out that Meredith was prostituting herself for Outback steak. And I will never forget that you had the cutest look on your face because you couldn't believe it, you thought it was so wrong.", "order": 42016, "speaker": "Michael Scott"},
  {"season": 7, "episode": 18, "scene": 38, "line": "And over here..", "order": 42017, "speaker": "Michael Scott"},
  {"season": 7, "episode": 18, "scene": 38, "line": "What happened here?", "order": 42018, "speaker": "Holly Flax"},
  {"season": 7, "episode": 18, "scene": 38, "line": "Well, nothing. Nothing really. I would just find an excuse to come here so I could stare at you through that window. This is what I'd do. [runs water through hand]", "order": 42019, "speaker": "Michael Scott"},
  {"season": 7, "episode": 18, "scene": 38, "line": "Nice", "order": 42020, "speaker": "Holly Flax"},
  {"season": 7, "episode": 18, "scene": 38, "line": "Let's go in here.", "order": 42021, "speaker": "Michael Scott"},
  {"season": 7, "episode": 18, "scene": 39, "line": "[everyone in the office is standing in the kitchen with a lit candle] Hi guys.", "order": 42022, "speaker": "Holly Flax"},
  {"season": 7, "episode": 18, "scene": 39, "line": "This is where our love faces its toughest test. After this, its just smooth sailing for the rest of our lives.", "order": 42023, "speaker": "Michael Scott"},
  {"season": 7, "episode": 18, "scene": 39, "line": "Holly, will you marry me?", "order": 42024, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 18, "scene": 39, "line": "No.", "order": 42025, "speaker": "Holly Flax"},
  {"season": 7, "episode": 18, "scene": 39, "line": "Marry me Holly.", "order": 42026, "speaker": "Stanley Hudson"},
  {"season": 7, "episode": 18, "scene": 39, "line": "No.", "order": 42027, "speaker": "Holly Flax"},
  {"season": 7, "episode": 18, "scene": 39, "line": "That guy's got more than he can handle as it is.", "order": 42028, "speaker": "Michael Scott"},
  {"season": 7, "episode": 18, "scene": 39, "line": "Will you marry me?", "order": 42029, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 18, "scene": 39, "line": "No.", "order": 42030, "speaker": "Holly Flax"},
  {"season": 7, "episode": 18, "scene": 39, "line": "That marriage would be a sham.", "order": 42031, "speaker": "Michael Scott"},
  {"season": 7, "episode": 18, "scene": 39, "line": "Will you marry me?", "order": 42032, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 18, "scene": 39, "line": "No.", "order": 42033, "speaker": "Holly Flax"},
  {"season": 7, "episode": 18, "scene": 39, "line": "Easy no.", "order": 42034, "speaker": "Michael Scott"},
  {"season": 7, "episode": 18, "scene": 39, "line": "Will you marry me?", "order": 42035, "speaker": "Angela Martin"},
  {"season": 7, "episode": 18, "scene": 39, "line": "No.", "order": 42036, "speaker": "Holly Flax"},
  {"season": 7, "episode": 18, "scene": 39, "line": "That would be hot. I would pay to see that.", "order": 42037, "speaker": "Michael Scott"},
  {"season": 7, "episode": 18, "scene": 39, "line": "Will you marry me Holly?", "order": 42038, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 18, "scene": 39, "line": "No.", "order": 42039, "speaker": "Holly Flax"},
  {"season": 7, "episode": 18, "scene": 39, "line": "Only one that I was kind of worried about.", "order": 42040, "speaker": "Michael Scott"},
  {"season": 7, "episode": 18, "scene": 39, "line": "[the area where Holly's desk is is filled with lit candles] This is where I fell in love with you. And this is where I ask you to marry me. It started with.. [fire sprinklers go on] Holly Flax, marrying me will you be?", "order": 42041, "speaker": "Michael Scott"},
  {"season": 7, "episode": 18, "scene": 39, "line": "Your wife becoming me will I. [everyone in the kitchen starts cheering, then enters annex]", "order": 42042, "speaker": "Holly Flax"},
  {"season": 7, "episode": 18, "scene": 39, "line": "That's awesome!", "order": 42043, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 18, "scene": 39, "line": "Congratulations!", "order": 42044, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 18, "scene": 39, "line": "Congratulations!", "order": 42045, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 18, "scene": 39, "line": "Thank you. So guys, guys, guys. We're moving to Colorado.", "order": 42046, "speaker": "Michael Scott"},
  {"season": 7, "episode": 18, "scene": 39, "line": "All of us?", "order": 42047, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 18, "scene": 39, "line": "Yep.", "order": 42048, "speaker": "Michael Scott"},
  {"season": 7, "episode": 18, "scene": 39, "line": "Wait, what?", "order": 42049, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 18, "scene": 39, "line": "Holly has to go back to Colorado. I'm going with her. I'm leaving.", "order": 42050, "speaker": "Michael Scott"},
  {"season": 7, "episode": 19, "scene": 1, "line": "Kahlua Sombrero, please.", "order": 42051, "speaker": "Michael Scott"},
  {"season": 7, "episode": 19, "scene": 1, "line": "All right, so just you tonight?", "order": 42052, "speaker": "Waiter"},
  {"season": 7, "episode": 19, "scene": 1, "line": "Actually, I am meeting somebody, but I'm a little bit early. Leaving my company. After 19 years.", "order": 42053, "speaker": "Michael Scott"},
  {"season": 7, "episode": 19, "scene": 1, "line": "I'll drink to that. I'm starting at a company this week.", "order": 42054, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 19, "scene": 1, "line": "Oh, really?", "order": 42055, "speaker": "Michael Scott"},
  {"season": 7, "episode": 19, "scene": 1, "line": "To begginings and endings.", "order": 42056, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 19, "scene": 1, "line": "And to middles, the unsung heroes. And to moms.", "order": 42057, "speaker": "Michael Scott"},
  {"season": 7, "episode": 19, "scene": 1, "line": "The moms and the troops.", "order": 42058, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 19, "scene": 1, "line": "Do not tell my fiance I'm drinking on a Wednesday.", "order": 42059, "speaker": "Michael Scott"},
  {"season": 7, "episode": 19, "scene": 1, "line": "[laughs] I won't... I don't know her.", "order": 42060, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 19, "scene": 1, "line": "I'm moving out to the burbs... actually, I'm moving further than the burbs, I'm moving to Colorado.", "order": 42061, "speaker": "Michael Scott"},
  {"season": 7, "episode": 19, "scene": 1, "line": "Colorado! The sunshine state.", "order": 42062, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 19, "scene": 1, "line": "Yep. Don't mess with Colorado.", "order": 42063, "speaker": "Michael Scott"},
  {"season": 7, "episode": 19, "scene": 1, "line": "Doing some skiing?", "order": 42064, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 19, "scene": 1, "line": "No, no. I don't want to end up like Sunny Bobo.", "order": 42065, "speaker": "Michael Scott"},
  {"season": 7, "episode": 19, "scene": 1, "line": "Well that's just good sense right there. Everyone I know who skis is dead.", "order": 42066, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 19, "scene": 1, "line": "You know, I would like to try the luge, through.", "order": 42067, "speaker": "Michael Scott"},
  {"season": 7, "episode": 19, "scene": 1, "line": "Try it once, you're hooked. That's my guess.", "order": 42068, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 19, "scene": 1, "line": "That's what I've heard.", "order": 42069, "speaker": "Michael Scott"},
  {"season": 7, "episode": 19, "scene": 1, "line": "I'm an olympics nut.", "order": 42070, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 19, "scene": 1, "line": "Oh yeah? Me too. Summer or winter?", "order": 42071, "speaker": "Michael Scott"},
  {"season": 7, "episode": 19, "scene": 1, "line": "[in unison] Summer!", "order": 42072, "speaker": "Deangelo Vickers & Michael Scott"},
  {"season": 7, "episode": 19, "scene": 1, "line": "[holds out fist] Knuckles! Actually, I gotta come around and give you...", "order": 42073, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 19, "scene": 2, "line": "You know, it's funny, I tried to get an animal olympics going.", "order": 42074, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 19, "scene": 2, "line": "Really? What happened?", "order": 42075, "speaker": "Michael Scott"},
  {"season": 7, "episode": 19, "scene": 2, "line": "You know, life happened. What are you gonna miss most about Scranton?", "order": 42076, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 19, "scene": 2, "line": "Oh... wow. The mountains. Where things are.", "order": 42077, "speaker": "Michael Scott"},
  {"season": 7, "episode": 19, "scene": 2, "line": "That's the way it goes.", "order": 42078, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 19, "scene": 2, "line": "Man, he is late. I'm gonna call him. Do you mind? I'm sorry. [calls Deangelo, Deangelo's phone vibrates]", "order": 42079, "speaker": "Michael Scott"},
  {"season": 7, "episode": 19, "scene": 2, "line": "Excuse me. Hello? You running late?", "order": 42080, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 19, "scene": 2, "line": "No, I'm here. I'm right... I'm at the bar.", "order": 42081, "speaker": "Michael Scott"},
  {"season": 7, "episode": 19, "scene": 2, "line": "I'm at the bar too.", "order": 42082, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 19, "scene": 2, "line": "You are? What bar?", "order": 42083, "speaker": "Michael Scott"},
  {"season": 7, "episode": 19, "scene": 2, "line": "I'm at the bar. The bar that's located in the lobby of the hotel.", "order": 42084, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 19, "scene": 2, "line": "I... do not see you.", "order": 42085, "speaker": "Michael Scott"},
  {"season": 7, "episode": 19, "scene": 2, "line": "How long have you...", "order": 42086, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 19, "scene": 2, "line": "I'm been here about... gosh, over half an hour.", "order": 42087, "speaker": "Michael Scott"},
  {"season": 7, "episode": 19, "scene": 2, "line": "Ok, me too.", "order": 42088, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 19, "scene": 2, "line": "[looking up from phones in unison] Sorry...", "order": 42089, "speaker": "Deangelo Vickers & Michael Scott"},
  {"season": 7, "episode": 19, "scene": 2, "line": "What're you wearing?", "order": 42090, "speaker": "Michael Scott"},
  {"season": 7, "episode": 19, "scene": 2, "line": "I am wearing a grey suit, red tie.", "order": 42091, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 19, "scene": 2, "line": "Are we both at the right place?", "order": 42092, "speaker": "Michael Scott"},
  {"season": 7, "episode": 19, "scene": 2, "line": "Which place?", "order": 42093, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 19, "scene": 2, "line": "I hear your voice.", "order": 42094, "speaker": "Michael Scott"},
  {"season": 7, "episode": 19, "scene": 2, "line": "I hear your voice, I see your lips moving.", "order": 42095, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 19, "scene": 2, "line": "I see your voice in the phone. Oh, man! [holds out hand] Michael Scott.", "order": 42096, "speaker": "Michael Scott"},
  {"season": 7, "episode": 19, "scene": 2, "line": "Deangelo Vickers.", "order": 42097, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 19, "scene": 2, "line": "Wow... that is insane! [laughter]", "order": 42098, "speaker": "Michael Scott"},
  {"season": 7, "episode": 19, "scene": 2, "line": "That is insane, that is the right term. Let's get some Vodka up in this cranberry, and one for my friend.", "order": 42099, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 19, "scene": 3, "line": "[entering Dunder Mifflin offices] Here we go! Are you ready?", "order": 42100, "speaker": "Michael Scott"},
  {"season": 7, "episode": 19, "scene": 3, "line": "I am ready.", "order": 42101, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 19, "scene": 3, "line": "Alright! Here we go!", "order": 42102, "speaker": "Michael Scott"},
  {"season": 7, "episode": 19, "scene": 4, "line": "This is it. What do you think?", "order": 42103, "speaker": "Michael Scott"},
  {"season": 7, "episode": 19, "scene": 4, "line": "Oh, she'll do. She'll do just fine.", "order": 42104, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 19, "scene": 5, "line": "I am very much looking forward to tommorow. It feels like the culmanation of a lot of hard work, a lot of good fortune...", "order": 42105, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 19, "scene": 5, "line": "[pops up from below camera, and runs out of room]", "order": 42106, "speaker": "Michael Scott"},
  {"season": 7, "episode": 19, "scene": 5, "line": "Did that? Did that just happen?! [runs after Michael] We should.. we should write a movie or something! I'm serious!", "order": 42107, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 19, "scene": 6, "line": "Michael is leaving. And apparently they've already hired a new manager. And we're meeting him today. It's a lot to process. Paperwork wise.", "order": 42108, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 19, "scene": 7, "line": "[wearing wig] Nope! It's not Ashton Kutcher. It's Kevin Malone! Equally handsome, equally smart! [winks]", "order": 42109, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 19, "scene": 8, "line": "Ok, everyone, as you know, one of my favorite things is fanfare for it's own sake. So, without further ado, let's start clapping! Presenting Deangelo Vickers!", "order": 42110, "speaker": "Michael Scott"},
  {"season": 7, "episode": 19, "scene": 8, "line": "[blinds roll down, Deangelo waving]", "order": 42111, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 19, "scene": 8, "line": "Come on out!", "order": 42112, "speaker": "Michael Scott"},
  {"season": 7, "episode": 19, "scene": 8, "line": "Hello! [opens door] How are you sir?", "order": 42113, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 19, "scene": 8, "line": "I am well, sir, how are you?", "order": 42114, "speaker": "Michael Scott"},
  {"season": 7, "episode": 19, "scene": 8, "line": "Can I just say, I am so excited to be working here. Little bit about myself, I love the American Southwest, for starters. You may call them Arizona, New Mexico, Nevada, Utah, I call them heaven. I have a peanut allergy, something I live with, it's a part of me. I've learned to cope with it. What else, I'm just as comfortable at the ball game as I am at the opera. I was a-", "order": 42115, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 19, "scene": 9, "line": "Did I want to be made manager? Sure. A great opportunity squandered? Absolutely. A crushing blow? Yes. Will I get over it? No. But life goes on. Not for me.", "order": 42116, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 19, "scene": 10, "line": "[drops papers as Deangelo walks by] I'll get that you guys. Don't... it's just... you know...", "order": 42117, "speaker": "Kelly Kapoor"},
  {"season": 7, "episode": 19, "scene": 10, "line": "[picks up paper] I'll help you.", "order": 42118, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 19, "scene": 10, "line": "Oh, hi! [obviously flirting body language]", "order": 42119, "speaker": "Kelly Kapoor"},
  {"season": 7, "episode": 19, "scene": 10, "line": "Hello!", "order": 42120, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 19, "scene": 10, "line": "Have we met before?", "order": 42121, "speaker": "Kelly Kapoor"},
  {"season": 7, "episode": 19, "scene": 10, "line": "No, not officially, actually, I saw you walking out of the bathroom before.", "order": 42122, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 19, "scene": 10, "line": "Well, I guess I'll go back to my cave. It was really great meeting you.", "order": 42123, "speaker": "Kelly Kapoor"},
  {"season": 7, "episode": 19, "scene": 10, "line": "[offers Kelly the papers as she walks away]", "order": 42124, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 19, "scene": 11, "line": "And that is what you call a meet cute.", "order": 42125, "speaker": "Kelly Kapoor"},
  {"season": 7, "episode": 19, "scene": 12, "line": "And we talk for like 20 minutes, no clue it's Deangelo the whole time!", "order": 42126, "speaker": "Michael Scott"},
  {"season": 7, "episode": 19, "scene": 12, "line": "Kind of embarassing.", "order": 42127, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 19, "scene": 12, "line": "Yeah, that story makes you sound stupid.", "order": 42128, "speaker": "Angela Martin"},
  {"season": 7, "episode": 19, "scene": 12, "line": "I happen to like the hilarious hijinx I get myself into. There he is! Deangelo, I was just telling them about last night, when we met. Remember that? That was crazy!", "order": 42129, "speaker": "Michael Scott"},
  {"season": 7, "episode": 19, "scene": 12, "line": "Oh. [chuckles] That was... let's see, where's my replacement? Where's the guy I'm replacing? Why don't I look to the left? He's sitting right there! [office laughs]", "order": 42130, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 19, "scene": 12, "line": "Oh yeah... I know... that's what I was just saying... that's what...", "order": 42131, "speaker": "Michael Scott"},
  {"season": 7, "episode": 19, "scene": 12, "line": "[silences Michael]", "order": 42132, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 19, "scene": 12, "line": "That exact situation is why I always carry around some of these. [holds up 'Hello, my name is..,'] nametags. Just in case. Because sometimes you just need to I.D. yourself.", "order": 42133, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 19, "scene": 12, "line": "[laughs] Office funny guy! Always glad to have an office funny guy around!", "order": 42134, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 19, "scene": 13, "line": "I wasn't even trying to make a joke. But I guess I've always been sorta quirky, offbeat, a little twisted.", "order": 42135, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 19, "scene": 14, "line": "This is Jim and Pam, aka Jap. What started as an affair has blossomed into a family before our eyes.", "order": 42136, "speaker": "Michael Scott"},
  {"season": 7, "episode": 19, "scene": 14, "line": "Well, it wasn't an affair.", "order": 42137, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 19, "scene": 14, "line": "Yes it was.", "order": 42138, "speaker": "Michael Scott"},
  {"season": 7, "episode": 19, "scene": 14, "line": "But, no, but we are a family.", "order": 42139, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 19, "scene": 14, "line": "[points to picture of Cecelia] We made that.", "order": 42140, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 19, "scene": 14, "line": "Cecelia.", "order": 42141, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 19, "scene": 14, "line": "Well, congratulations. I have four kids of my own.", "order": 42142, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 19, "scene": 14, "line": "Really? Oh, my gosh! We just have the one, but she poops for four. [laughs]", "order": 42143, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 19, "scene": 15, "line": "Uh oh. Someone started off on the right foot with the new boss!", "order": 42144, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 19, "scene": 15, "line": "Yeah, they don't ever talk about careers that were made because of unplanned pregnancies.", "order": 42145, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 19, "scene": 16, "line": "Thanks for meeting me.", "order": 42146, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 19, "scene": 16, "line": "Are you kidding? I'd come anywhere to see a turtle? Where'd you find him.", "order": 42147, "speaker": "Michael Scott"},
  {"season": 7, "episode": 19, "scene": 16, "line": "There's no turtle, Michael. I just wanted to get you here.", "order": 42148, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 19, "scene": 16, "line": "You know me very well, Dwight.", "order": 42149, "speaker": "Michael Scott"},
  {"season": 7, "episode": 19, "scene": 16, "line": "That's because I'm your right-hand man, Michael, but I can't do it again. I can't do it again for a whole new guy.", "order": 42150, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 19, "scene": 16, "line": "Now I'm gonna have to go online and look at turtles or I'll be off all day.", "order": 42151, "speaker": "Michael Scott"},
  {"season": 7, "episode": 19, "scene": 16, "line": "I want to be manager. I just don't understand why I wasn't even interviewed for the job. What's wrong? I was totally qualified, you were pushing for me.", "order": 42152, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 19, "scene": 17, "line": "That apple looks delic!", "order": 42153, "speaker": "Meredith Palmer"},
  {"season": 7, "episode": 19, "scene": 17, "line": "I do a lot of portion control. I try to keep my daily calor intake under 1200. [laughter]", "order": 42154, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 19, "scene": 17, "line": "Deangelo, you're going to starve to death. [laughter]", "order": 42155, "speaker": "Stanley Hudson"},
  {"season": 7, "episode": 19, "scene": 17, "line": "So you decided to have an orgy and not invite me? I call the middle!", "order": 42156, "speaker": "Michael Scott"},
  {"season": 7, "episode": 19, "scene": 17, "line": "It's cool that you like the southwest. It's one of my favorite regions.", "order": 42157, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 19, "scene": 18, "line": "It's one of my favorite regions? Did I just sound totally lame? No, I sounded good.", "order": 42158, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 19, "scene": 19, "line": "I love the desert. It's one of my favorite ecosystems.", "order": 42159, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 19, "scene": 19, "line": "Here's the great thing about the southwest; there's so much more than desert. Along the north rim of the Grand Canyon is a forest as lush as you've ever seen.", "order": 42160, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 19, "scene": 19, "line": "Burnt! It's lush, dummie. Hey, Deangelo, what do you think of bald people? I hate them.", "order": 42161, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 19, "scene": 19, "line": "Deangelo, I forget, did you mention you like politics?", "order": 42162, "speaker": "Angela Martin"},
  {"season": 7, "episode": 19, "scene": 19, "line": "I did not.", "order": 42163, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 19, "scene": 19, "line": "Cause I was thinking that you should meet my boyfriend, he's a state senator. I really think the two of you would hit it off. He's a great person to know.", "order": 42164, "speaker": "Angela Martin"},
  {"season": 7, "episode": 19, "scene": 19, "line": "Sounds very interesting, thank you.", "order": 42165, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 19, "scene": 20, "line": "Dunder Mifflin, this is Erin. Ok, let me transfer you.", "order": 42166, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 19, "scene": 20, "line": "Why do you use your name when you use the phone?", "order": 42167, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 19, "scene": 20, "line": "Oh, that's how Pam does it. I just copy her. She's sort of a living legend.", "order": 42168, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 19, "scene": 20, "line": "Try it without using your name.", "order": 42169, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 19, "scene": 20, "line": "Dunder Mifflin, this is... oh, I like it!", "order": 42170, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 19, "scene": 20, "line": "Dunder Mifflin, how may I assist you?", "order": 42171, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 19, "scene": 20, "line": "Oh, assist.", "order": 42172, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 19, "scene": 20, "line": "I sorta like the old way.", "order": 42173, "speaker": "Michael Scott"},
  {"season": 7, "episode": 19, "scene": 20, "line": "I just prefer it without the name and I thought- I've got to start doing some managing at some point.", "order": 42174, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 19, "scene": 20, "line": "I know, I know, I'm sorry, but if it's not a big deal we should just-", "order": 42175, "speaker": "Michael Scott"},
  {"season": 7, "episode": 19, "scene": 20, "line": "And it really isn't.", "order": 42176, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 19, "scene": 20, "line": "Is that good?", "order": 42177, "speaker": "Michael Scott"},
  {"season": 7, "episode": 19, "scene": 20, "line": "Yeah, ok, good.", "order": 42178, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 19, "scene": 20, "line": "Well, I'd like to change it, actually.", "order": 42179, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 19, "scene": 20, "line": "Well, whatever. Whatever you think will work.", "order": 42180, "speaker": "Michael Scott"},
  {"season": 7, "episode": 19, "scene": 20, "line": "What do you think?", "order": 42181, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 19, "scene": 20, "line": "I think a change would be nice.", "order": 42182, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 19, "scene": 20, "line": "You can do the old way or whatever you think would work. [phone rings]", "order": 42183, "speaker": "Michael Scott"},
  {"season": 7, "episode": 19, "scene": 20, "line": "[whispering] Change it.", "order": 42184, "speaker": "Denagelo"},
  {"season": 7, "episode": 19, "scene": 20, "line": "[picks up phone] ...I'm so sorry. Sorry. [hangs up]", "order": 42185, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 19, "scene": 21, "line": "Hey, funny guy. Wanna have a little mid-day lawl here. Make me laugh, huh? What you got? I can't even look at you.", "order": 42186, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 19, "scene": 21, "line": "Here we go... ok... what do African Americans call... [laughter]", "order": 42187, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 19, "scene": 21, "line": "Deangelo, did you order a barber?", "order": 42188, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 19, "scene": 21, "line": "Oh, yeah. Put a pin on that, kid. Can't wait to hear the punchline.", "order": 42189, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 19, "scene": 21, "line": "Finish the joke, Andy. What do African Americans call?", "order": 42190, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 19, "scene": 21, "line": "I... don't know. Help me.", "order": 42191, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 19, "scene": 22, "line": "[a barber enters the office for Deangelo] That is so badass. So hardcore.", "order": 42192, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 19, "scene": 22, "line": "Yeah, real cool. Real power move.", "order": 42193, "speaker": "Phyllis Vance"},
  {"season": 7, "episode": 19, "scene": 22, "line": "I saw a hawk today. Just sitting right there on the overpass. Looking at me.", "order": 42194, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 19, "scene": 22, "line": "[to Erin] Shave me. [walks into office with Deangelo] This is going to be funny! Looks like we are going to be shaving buddies!", "order": 42195, "speaker": "Michael Scott"},
  {"season": 7, "episode": 19, "scene": 22, "line": "Ok, alright! I love it! I love it! Michael, this is Reggie. He is the #1 yelp reviewed shaver in Scranton. It's not even close.", "order": 42196, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 19, "scene": 22, "line": "And this is Erin and she is going to shave my face. Here we go. [Erin puts too much shaving cream on Michael's face, none on the neck]", "order": 42197, "speaker": "Michael Scott"},
  {"season": 7, "episode": 19, "scene": 22, "line": "Alright. This is how we do it. Feels nice, doesn't it?", "order": 42198, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 19, "scene": 22, "line": "It really does. This is luxery. Here we go. Ah, that is nice.", "order": 42199, "speaker": "Michael Scott"},
  {"season": 7, "episode": 19, "scene": 22, "line": "You wanted to talk to us Deangelo?", "order": 42200, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 19, "scene": 22, "line": "Ah, yes, please! Just cozy up there. Just thought we could have some rap sessions with smaller groups.", "order": 42201, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 19, "scene": 22, "line": "Well, we don't really do rap sessions. We kinda do... [Erin shaves Michael] god... we sorta do more of like powwows or-", "order": 42202, "speaker": "Michael Scott"},
  {"season": 7, "episode": 19, "scene": 22, "line": "Ok, then, powwows then, fine.", "order": 42203, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 19, "scene": 22, "line": "That's-", "order": 42204, "speaker": "Michael Scott"},
  {"season": 7, "episode": 19, "scene": 22, "line": "Hey, Deangelo, my mom just sent me this picture of Cece, it is so adorable.", "order": 42205, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 19, "scene": 22, "line": "Alright, wait, little bit of backstory, she loves dogs. She calls them 'da-das'.", "order": 42206, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 19, "scene": 22, "line": "Da-das.", "order": 42207, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 19, "scene": 22, "line": "And what happens here is-", "order": 42208, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 19, "scene": 22, "line": "You know what, enough about your baby, ok? I'm sorry.", "order": 42209, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 19, "scene": 22, "line": "We were... I think she was just trying to-", "order": 42210, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 19, "scene": 22, "line": "No, no, no, I know what you're doing. Just quit it. I want to hear from the rank and file. How can I be the best manager for you all? What can I do better?", "order": 42211, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 19, "scene": 22, "line": "You know what, everybody? You have to be honest, here. You can't say that everything is perfect. So, you know, come up with something.", "order": 42212, "speaker": "Michael Scott"},
  {"season": 7, "episode": 19, "scene": 22, "line": "Well, Deangelo, I'd say communication could be improved. People stopped reading memos, so everyone marks them urgent.", "order": 42213, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 19, "scene": 22, "line": "Ok, you know what, I mark it urgent A, urgent B, urgent C, urgent D. Urgent A is the most important, urgent D you don't even really have to worry about.", "order": 42214, "speaker": "Michael Scott"},
  {"season": 7, "episode": 19, "scene": 22, "line": "Michael, can you stop talking now? I need to shave your lips.", "order": 42215, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 19, "scene": 22, "line": "Don't shave my lips.", "order": 42216, "speaker": "Michael Scott"},
  {"season": 7, "episode": 19, "scene": 23, "line": "[Andy walks into room] What's up?", "order": 42217, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 19, "scene": 23, "line": "I don't mean to go into a rant here, but...", "order": 42218, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 19, "scene": 24, "line": "I wrote the perfect joke. Topical, edgy, funny.", "order": 42219, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 19, "scene": 25, "line": "...I saw this thing on the news. Democrats want electric cars. Republicans don't. I'm thinking, 'how crazy is it that we have a car debate going on in a city known for gridlock.", "order": 42220, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 19, "scene": 25, "line": "Let's not talk politics in the office, ok? I like you better as a funny guy.", "order": 42221, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 19, "scene": 25, "line": "[leans on table] I wasn't taking a position. It's just one of those things where- [table collapses under Andy]", "order": 42222, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 19, "scene": 25, "line": "[laughter] Now that's funny! That's funny! You walk much?", "order": 42223, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 19, "scene": 25, "line": "Yeah, right, I mean- [walks into door, jokingly]", "order": 42224, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 19, "scene": 26, "line": "Little click...", "order": 42225, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 19, "scene": 26, "line": "[pushing box into office] Deangelo, this box came for you.", "order": 42226, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 19, "scene": 26, "line": "Thank you. That's my stuff. Yeah. Michael, take a look at this.", "order": 42227, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 19, "scene": 26, "line": "Need a hand?", "order": 42228, "speaker": "Stanley Hudson"},
  {"season": 7, "episode": 19, "scene": 26, "line": "Oh, that'd be great, thank you Stanley!", "order": 42229, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 19, "scene": 26, "line": "You know, this office sort of has a perfect Feng Shui to it, so... you know, let's not go overboard with the re-decorating, and I'm still here-", "order": 42230, "speaker": "Michael Scott"},
  {"season": 7, "episode": 19, "scene": 26, "line": "[holds up painting of desert to office, who are impressed]", "order": 42231, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 19, "scene": 26, "line": "I still have to look at this stuff, guys.", "order": 42232, "speaker": "Michael Scott"},
  {"season": 7, "episode": 19, "scene": 26, "line": "So this is a ten-gallon hat, huh?", "order": 42233, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 19, "scene": 26, "line": "Yep, ten-gallon hat. Technically, it only holds about three quartz. Little factoid.", "order": 42234, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 19, "scene": 26, "line": "Interesting.", "order": 42235, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 19, "scene": 27, "line": "Deangelo's great. I love the guy. But I'm not sure he's a great fit for the office. And also, I'm not sure if I love the guy.", "order": 42236, "speaker": "Michael Scott"},
  {"season": 7, "episode": 19, "scene": 28, "line": "[holds out cowboy boots for Darryl]", "order": 42237, "speaker": "Darryl Philbin's sister"},
  {"season": 7, "episode": 19, "scene": 28, "line": "Cool, cool. Alright, see you later.", "order": 42238, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 19, "scene": 28, "line": "Hey, hey, hey! [holds up set of pistols in holsters]", "order": 42239, "speaker": "Darryl Philbin's sister"},
  {"season": 7, "episode": 19, "scene": 28, "line": "No, I'm good. Keep them.", "order": 42240, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 19, "scene": 29, "line": "Hey, funny man! Whatcha got for me? Make me laugh?", "order": 42241, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 19, "scene": 29, "line": "[mimes using a rope to pull himself forward to microwave] Let's see if they have my favorite teas in here... [throws tea packets into air, puts hand in unplugged toaster, pretends to be shocked, takes container of cheese-puffs and throws them into air, pours hot coffee on his pants]", "order": 42242, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 19, "scene": 29, "line": "[laughs] Drink some soap!", "order": 42243, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 19, "scene": 29, "line": "[drinks hand soap]", "order": 42244, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 19, "scene": 29, "line": "[laughter and applause]", "order": 42245, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 19, "scene": 30, "line": "I guess this is my life now.", "order": 42246, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 19, "scene": 31, "line": "How do I become a manger at Sabre?", "order": 42247, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 19, "scene": 31, "line": "First thing's first, thank you for coming here directly. I know you could have called Tallahassee but they would've just looped back to me, so, it's cool you recognized my role here.", "order": 42248, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 19, "scene": 31, "line": "I left a message at corporate.", "order": 42249, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 19, "scene": 31, "line": "Ahh. There you go. Get a recommendation from Michael. That'll put you right on the shortlist for next time there's an opening.", "order": 42250, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 19, "scene": 31, "line": "Can't you just use the recommendation you already have on file?", "order": 42251, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 19, "scene": 31, "line": "What recommendation?", "order": 42252, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 19, "scene": 31, "line": "From when he recommended me to replace him.", "order": 42253, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 19, "scene": 31, "line": "...I'm sorry, I never know how to act in these awkward-type situations.", "order": 42254, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 19, "scene": 32, "line": "Best whale watching: easily the west coast. If you're going whale watching on the East Coast, you might wanna bring a magazine called 'West Coast Whales'. [laughter] Because you're not gonna see them-", "order": 42255, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 19, "scene": 32, "line": "[interrupting Deangelo] Snack time! It's the witching hour! It's the sandwitching hour!", "order": 42256, "speaker": "Michael Scott"},
  {"season": 7, "episode": 19, "scene": 32, "line": "Awesome!", "order": 42257, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 19, "scene": 32, "line": "Mmm, whatcha got?", "order": 42258, "speaker": "Phyllis Vance"},
  {"season": 7, "episode": 19, "scene": 32, "line": "PB and J, my mom's recipe! [throws sandwitch at Phyllis]", "order": 42259, "speaker": "Michael Scott"},
  {"season": 7, "episode": 19, "scene": 32, "line": "Michael! Deangelo has a peanut allergy.", "order": 42260, "speaker": "Angela Martin"},
  {"season": 7, "episode": 19, "scene": 32, "line": "I need a wide berth. I need a wide berth from those nuts.", "order": 42261, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 19, "scene": 32, "line": "What are you doing? Have you lost your mind?", "order": 42262, "speaker": "Stanley Hudson"},
  {"season": 7, "episode": 19, "scene": 32, "line": "I think Kevin wants one. Kevin, here you go! [throws sandwitch into Kevin's open mouth]", "order": 42263, "speaker": "Michael Scott"},
  {"season": 7, "episode": 19, "scene": 32, "line": "Michael, this is serious! Do you know what happens if Deangelo touches a peanut?", "order": 42264, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 19, "scene": 32, "line": "What?", "order": 42265, "speaker": "Michael Scott"},
  {"season": 7, "episode": 19, "scene": 32, "line": "Michael, the last time I was exposed to a peanut, I was itchy for three days, ok? I had to take baths constantly. I missed the O.J. verdict. I had to read about it in the paper like an idiot.", "order": 42266, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 19, "scene": 32, "line": "Wait, so, you don't go into shock or die or anything?", "order": 42267, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 19, "scene": 32, "line": "No, Jim, not everything is life or death. I want to feel comfortable.", "order": 42268, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 19, "scene": 32, "line": "Oscar, here you go. [throws sandwitch]", "order": 42269, "speaker": "Michael Scott"},
  {"season": 7, "episode": 19, "scene": 32, "line": "[uses book to stop the sandwitch's flight] You're getting nut particles all in the air!", "order": 42270, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 19, "scene": 32, "line": "No one had a problem with the air here until you came around.", "order": 42271, "speaker": "Michael Scott"},
  {"season": 7, "episode": 19, "scene": 32, "line": "[steps into conference room] Everyone, mandatory meeting. Multi-purpose room, now.", "order": 42272, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 19, "scene": 32, "line": "No, that's good. You just stay in there, stay in there by yourself, loser. No body go in. [disgusted comments by co-workers] No body go in there. Stay where you are.", "order": 42273, "speaker": "Michael Scott"},
  {"season": 7, "episode": 19, "scene": 32, "line": "Michael, This is insane.", "order": 42274, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 19, "scene": 32, "line": "No, it's not insane, Oscar. I'm been here for 19 years, and just because someone else is called the boss, you're gonna throw it all away? No.", "order": 42275, "speaker": "Michael Scott"},
  {"season": 7, "episode": 19, "scene": 32, "line": "Listen, you're the one who decided to leave. Come on, he's the new boss, you know we have to do this.", "order": 42276, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 19, "scene": 32, "line": "Well, who needs him. Guess it's just you and me, Dwight.", "order": 42277, "speaker": "Michael Scott"},
  {"season": 7, "episode": 19, "scene": 32, "line": "Correction. Just you. Alright, meeting. Multi-function room.", "order": 42278, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 19, "scene": 32, "line": "[walks out] Hold on, Hey Michael, Michael, wait up, hold on. Um, so for these meetings do you just jump into business or do you start with some chit-chat or...?", "order": 42279, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 19, "scene": 32, "line": "I start with some chit-chat and a maybe a couple of jokes. And you might want to develop a couple of characters. You know what, you'll be fine.", "order": 42280, "speaker": "Michael Scott"},
  {"season": 7, "episode": 19, "scene": 32, "line": "Maybe. Maybe not. 50/50. You know what, it is too bad for this place that you're leaving. [extents hands for hug] Bring it in, come on. [Michael doesn't accept hug] Ok. [walks back]", "order": 42281, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 19, "scene": 32, "line": "Ok. [hugs Deangelo from behind] Why did you have to be so damn good?", "order": 42282, "speaker": "Michael Scott"},
  {"season": 7, "episode": 19, "scene": 32, "line": "I... I'm adequete. I'm half as good as you.", "order": 42283, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 19, "scene": 32, "line": "No, no, no. Come on. You are good, they know it.", "order": 42284, "speaker": "Michael Scott"},
  {"season": 7, "episode": 19, "scene": 32, "line": "Will you do me a favour and enjoy this time, ok? You've worked so hard, get your senioritis on. It's Lake Havasu time!", "order": 42285, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 19, "scene": 32, "line": "Guess I've been working so hard I forgot what it's like to be hardly working.", "order": 42286, "speaker": "Micahel"},
  {"season": 7, "episode": 19, "scene": 32, "line": "Ok. What is the Native American girl's name?", "order": 42287, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 19, "scene": 32, "line": "You'll figure it out.", "order": 42288, "speaker": "Michael Scott"},
  {"season": 7, "episode": 19, "scene": 33, "line": "Hey, Deangelo! Wanna meet Cece?", "order": 42289, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 19, "scene": 33, "line": "That's a cute baby. Very cute baby. Adorable. Very cute.", "order": 42290, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 19, "scene": 33, "line": "Thank you very much.", "order": 42291, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 19, "scene": 34, "line": "See, we knew it! If he just met her, he'd understand.", "order": 42292, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 19, "scene": 34, "line": "We're back in! Right?", "order": 42293, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 19, "scene": 35, "line": "I swear, that baby could be the star of a show entitled 'Babys I Don't Care About.'", "order": 42294, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 20, "scene": 1, "line": "It is six a.m. [DeAngelo yawns] and we are about to go house to house to give everyone their Dundie Nomination Certificates. Just like the Oscars!", "order": 42295, "speaker": "Michael Scott"},
  {"season": 7, "episode": 20, "scene": 1, "line": "Yes, and this happens every...?", "order": 42296, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 20, "scene": 1, "line": "Every year! This happens, you have a lot to learn my friend. [DeAngelo laughs]", "order": 42297, "speaker": "Michael Scott"},
  {"season": 7, "episode": 20, "scene": 1, "line": "Well, you know, why don't we try it, and if it goes good it'll be part of my tradition.", "order": 42298, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 20, "scene": 1, "line": "Why do you always say that? You're gonna love it.", "order": 42299, "speaker": "Michael Scott"},
  {"season": 7, "episode": 20, "scene": 2, "line": "[arriving at the first house] Alright let's go! [DeAngelo runs in the wrong direction] This way, this way! [Michael knocks on the door]", "order": 42300, "speaker": "Michael Scott"},
  {"season": 7, "episode": 20, "scene": 2, "line": "Thanks.", "order": 42301, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 20, "scene": 2, "line": "Surprise!", "order": 42302, "speaker": "Michael Scott"},
  {"season": 7, "episode": 20, "scene": 2, "line": "Congratulations!", "order": 42303, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 20, "scene": 2, "line": "Yep, okay...", "order": 42304, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 20, "scene": 2, "line": "You and Pam have both been nominated for Dundie Awards!", "order": 42305, "speaker": "Michael Scott"},
  {"season": 7, "episode": 20, "scene": 2, "line": "Okay.", "order": 42306, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 20, "scene": 2, "line": "[holds up a camera and DeAngelo meows] Here we go! Have Pam come down.", "order": 42307, "speaker": "Michael Scott"},
  {"season": 7, "episode": 20, "scene": 2, "line": "No she doesn't wa- She's not here.", "order": 42308, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 20, "scene": 2, "line": "What's going on?", "order": 42309, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 20, "scene": 2, "line": "Stay in bed!", "order": 42310, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 20, "scene": 3, "line": "[next house] Congratulations!", "order": 42311, "speaker": "Michael Scott"},
  {"season": 7, "episode": 20, "scene": 3, "line": "Have you lost your mind! Get off my property before I call the police!", "order": 42312, "speaker": "Stanley Hudson"},
  {"season": 7, "episode": 20, "scene": 3, "line": "We got it.", "order": 42313, "speaker": "Michael Scott"},
  {"season": 7, "episode": 20, "scene": 3, "line": "Uh, leave it at the door or...?", "order": 42314, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 20, "scene": 3, "line": "Yeah that's fine.", "order": 42315, "speaker": "Michael Scott"},
  {"season": 7, "episode": 20, "scene": 4, "line": "[next house] Hey Toby! You suck! [throwing eggs at his house]", "order": 42316, "speaker": "Michael Scott"},
  {"season": 7, "episode": 20, "scene": 4, "line": "Is this an employee of ours?", "order": 42317, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 20, "scene": 4, "line": "[giggles] Go go go go go go!", "order": 42318, "speaker": "Michael Scott"},
  {"season": 7, "episode": 20, "scene": 5, "line": "[arriving at a dumpy looking, unkempt house] I've never seen this place in the daylight.", "order": 42319, "speaker": "Michael Scott"},
  {"season": 7, "episode": 20, "scene": 5, "line": "It reminds me of Katrina.", "order": 42320, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 20, "scene": 5, "line": "Here we go, alright got it? Set? [the door is ajar, Michael knocks lightly] Hello? [turns around to see Meredith walking towards her house]", "order": 42321, "speaker": "Michael Scott"},
  {"season": 7, "episode": 20, "scene": 5, "line": "I'm so busted! Walk of shame!", "order": 42322, "speaker": "Meredith Palmer"},
  {"season": 7, "episode": 20, "scene": 5, "line": "Do you usually leave your door unlocked? And ajar?", "order": 42323, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 20, "scene": 5, "line": "Ahh, Nice! I got a Dundie nomination!", "order": 42324, "speaker": "Meredith Palmer"},
  {"season": 7, "episode": 20, "scene": 5, "line": "Yes you did. That's right! Congratulations Meredith. We should head out.", "order": 42325, "speaker": "Michael Scott"},
  {"season": 7, "episode": 20, "scene": 5, "line": "No no no! You guys stay! No no no! I have Vienna Sausages and I have napkins. [walks into her house] Let me fix you breakfast!", "order": 42326, "speaker": "Meredith Palmer"},
  {"season": 7, "episode": 20, "scene": 5, "line": "[whispers] I'm not going in there.", "order": 42327, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 20, "scene": 5, "line": "[quietly shuts the door] Go.", "order": 42328, "speaker": "Michael Scott"},
  {"season": 7, "episode": 20, "scene": 6, "line": "[walking into the office] Good morning Erin.", "order": 42329, "speaker": "Michael Scott"},
  {"season": 7, "episode": 20, "scene": 6, "line": "Oh hey, didn't see you.", "order": 42330, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 20, "scene": 6, "line": "You have big plans for tonight?", "order": 42331, "speaker": "Michael Scott"},
  {"season": 7, "episode": 20, "scene": 6, "line": "I don't know, maybe volunteer at the shelter, or go to bed, or... I don't know. Maybe I'm going to the Dundies!! [Flashes her Nomination Certificate]", "order": 42332, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 20, "scene": 6, "line": "You are getting so funny! [Erin giggles] Very good.", "order": 42333, "speaker": "Michael Scott"},
  {"season": 7, "episode": 20, "scene": 7, "line": "The Dundies are my baby, and they need to go on. When Larry King died they didn't just cancel his show. They got Pierce Morgan to come in, and do his show, and, that way, Larry lives on.", "order": 42334, "speaker": "Michael Scott"},
  {"season": 7, "episode": 20, "scene": 8, "line": "Anything can happen at the Dundies! [DeAngelo laughs] They're like the Golden Globes but less mean. And I just want all of us to have a good time!", "order": 42335, "speaker": "Michael Scott"},
  {"season": 7, "episode": 20, "scene": 8, "line": "Just a little announcement folks, remember, the Dundies is a black tie affair.", "order": 42336, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 20, "scene": 8, "line": "Black tie optional.", "order": 42337, "speaker": "Michael Scott"},
  {"season": 7, "episode": 20, "scene": 8, "line": "Every day is black tie optional!", "order": 42338, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 20, "scene": 8, "line": "A la, tonight we will be hosting at Louie Volpies!", "order": 42339, "speaker": "Michael Scott"},
  {"season": 7, "episode": 20, "scene": 8, "line": "Nice! [rest of the office looks excited]", "order": 42340, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 20, "scene": 8, "line": "I love their breadsticks!", "order": 42341, "speaker": "Phyllis Vance"},
  {"season": 7, "episode": 20, "scene": 8, "line": "Oh, their breadsticks are like crack!", "order": 42342, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 20, "scene": 8, "line": "I love when people say like crack when they've obviously never done crack.", "order": 42343, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 20, "scene": 8, "line": "Well the breadsticks are like what then, Ryan, what can I use?", "order": 42344, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 20, "scene": 8, "line": "I don't know, something from your world. The breadsticks are like scrapbooking.", "order": 42345, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 20, "scene": 8, "line": "You're right, you're right. I'm a middle class broad. [Jim does his 'Jim face']", "order": 42346, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 20, "scene": 8, "line": "Shh, shh! Okay, okay. Everybody's right. They're like breadsticks on steroids. Right?", "order": 42347, "speaker": "Michael Scott"},
  {"season": 7, "episode": 20, "scene": 8, "line": "Yeah that's great.", "order": 42348, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 20, "scene": 8, "line": "So as you know, the Dundies are my legacy. So I'm going to need to train a replacement. So this year, I have decided to have a co-host. [Dwight looks suspecting] And that person is [he and DeAngelo roll their tongues as a drum roll] DeAngelo! [all applaud]", "order": 42349, "speaker": "Michael Scott"},
  {"season": 7, "episode": 20, "scene": 9, "line": "Always the padawan, never the Jedi.", "order": 42350, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 20, "scene": 10, "line": "Thank you Michael, uh, but I will have to pass.", "order": 42351, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 20, "scene": 10, "line": "Think of it as part of your training.", "order": 42352, "speaker": "Michael Scott"},
  {"season": 7, "episode": 20, "scene": 10, "line": "Not much of a performer, unless you count singing in the shower. Boy, okay...", "order": 42353, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 20, "scene": 10, "line": "[smiling] Okay, well we'll work on it.", "order": 42354, "speaker": "Michael Scott"},
  {"season": 7, "episode": 20, "scene": 10, "line": "[whispers to Michael] Michael, I'm very, very bad.", "order": 42355, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 20, "scene": 10, "line": "You are doing it.", "order": 42356, "speaker": "Michael Scott"},
  {"season": 7, "episode": 20, "scene": 11, "line": "[all in conference room] Guys! I want you to help us work on some banter.", "order": 42357, "speaker": "Michael Scott"},
  {"season": 7, "episode": 20, "scene": 11, "line": "Yes! I love banter. But I hate witty banter.", "order": 42358, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 20, "scene": 11, "line": "Here we go! So, DeAngelo! You and I have a lot in common. You lost two hundred pounds, and I lose my car keys every morning.", "order": 42359, "speaker": "Michael Scott"},
  {"season": 7, "episode": 20, "scene": 11, "line": "That is true, we do share some similarities. I know how you can fix one of those problems, for me it was portion control. For you, you need a key chain. Maybe one with a-", "order": 42360, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 20, "scene": 11, "line": "[cutting him off] Okay, okay. You know what, I didn't actually lose my keys...", "order": 42361, "speaker": "Michael Scott"},
  {"season": 7, "episode": 20, "scene": 11, "line": "This did not happen.", "order": 42362, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 20, "scene": 11, "line": "Mmmmkay. There needs to be what you call a rat-a-tat. And right now it's all rat and no tat. Ryan come on up here. Tap DeAngelo out. Just watch this. Okay. [Ryan stands next to Michael up front] Ryan how are you today?", "order": 42363, "speaker": "Michael Scott"},
  {"season": 7, "episode": 20, "scene": 11, "line": "Why don't you ask my therapist, my mom certainly pays her enough.", "order": 42364, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 20, "scene": 11, "line": "[very excited] There you go! Lovely! Regis and Kelly! Nice! Use that as an inspiration. Just be funny.", "order": 42365, "speaker": "Michael Scott"},
  {"season": 7, "episode": 20, "scene": 11, "line": "Uhhh, you sir! [points to Jim] Are we having fun tonight?", "order": 42366, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 20, "scene": 11, "line": "Having a great time.", "order": 42367, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 20, "scene": 11, "line": "Oh good!", "order": 42368, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 20, "scene": 11, "line": "Thanks, yeah.", "order": 42369, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 20, "scene": 11, "line": "Where were you on September 11th?", "order": 42370, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 20, "scene": 11, "line": "No! God! [Jim looks at him oddly]", "order": 42371, "speaker": "Michael Scott"},
  {"season": 7, "episode": 20, "scene": 11, "line": "This is so weird! If I'm conducting a meeting, good to go. But if I think of it as a performance... I-I fall apart. Obviously.", "order": 42372, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 20, "scene": 11, "line": "Why don't you think of hosting the Dundies like you're just running a meeting.", "order": 42373, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 20, "scene": 11, "line": "No, no, no! This is the performance of your life!", "order": 42374, "speaker": "Michael Scott"},
  {"season": 7, "episode": 20, "scene": 11, "line": "Why don't you just, privately, in your own head, think of it like a meeting.", "order": 42375, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 20, "scene": 11, "line": "Jim, please no loopholes.", "order": 42376, "speaker": "Michael Scott"},
  {"season": 7, "episode": 20, "scene": 11, "line": "Mike, why do you have to control how he perceives it in his mind?", "order": 42377, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 20, "scene": 11, "line": "This is my last Dundies ever, if I want mind control over him, is that too much to ask?", "order": 42378, "speaker": "Michael Scott"},
  {"season": 7, "episode": 20, "scene": 12, "line": "[laying down, Michael is sitting on DeAngelo's stomach bouncing up and down in Michael's office] Me mo. Me mo.", "order": 42379, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 20, "scene": 12, "line": "Good. Good. Good!", "order": 42380, "speaker": "Michael Scott"},
  {"season": 7, "episode": 20, "scene": 12, "line": "Meee Mo, Mee!", "order": 42381, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 20, "scene": 12, "line": "Now, tell me something terrible that happened in your childhood.", "order": 42382, "speaker": "Michael Scott"},
  {"season": 7, "episode": 20, "scene": 12, "line": "When I was very young, my mother divorced my father and I had to go to court and choose between them.", "order": 42383, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 20, "scene": 12, "line": "[getting off his stomach] Too personal. I don't wanna hear about it. [picking up a portable tape player] Let's try this. Put these on. [DeAngelo puts the headphones in] I am going to turn this on, and turn the volume all the way up. So you can't- [DeAngelo winces] That's right, you're not going to be able to hear yourself. Read this, [gives him a card] and make it sound perfect.", "order": 42384, "speaker": "Michael Scott"},
  {"season": 7, "episode": 20, "scene": 12, "line": "THE DUNDIES ARE-OW-ABOUT COMING TOGETHER! AND RECOGNIZING, THE ADOMINABILITY OF THE HUMAN SPIRIT! [Jim comes and closes the door]", "order": 42385, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 20, "scene": 12, "line": "Say it with an accent!", "order": 42386, "speaker": "Michael Scott"},
  {"season": 7, "episode": 20, "scene": 12, "line": "[bad Australian accent attempt] THE DUNDIES!", "order": 42387, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 20, "scene": 12, "line": "[also in a poor Australian accent] The Dundies!", "order": 42388, "speaker": "Michael Scott"},
  {"season": 7, "episode": 20, "scene": 12, "line": "THE DUNDIES!", "order": 42389, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 20, "scene": 13, "line": "[Jim and Pam are walking outside] I just don't understand the desire to push sweet potato fries on me, and not just regular fries.", "order": 42390, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 20, "scene": 13, "line": "Yeah... [they see Erin eating in her car, and ducking when they see her. Pam knocks on her car window] Hey, Erin, everything okay?", "order": 42391, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 20, "scene": 13, "line": "Get away from the car, he's gonna see you.", "order": 42392, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 20, "scene": 13, "line": "Who's gonna see us?", "order": 42393, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 20, "scene": 13, "line": "[desperately] Just get in! Get in! Just get in!", "order": 42394, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 20, "scene": 13, "line": "Get in the car? [Jim and Pam get in the back seat]", "order": 42395, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 20, "scene": 13, "line": "Please! Sorry. Get in. [Jim and Pam are confused and worried] Sorry sorry sorry.", "order": 42396, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 20, "scene": 13, "line": "What's wrong?", "order": 42397, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 20, "scene": 13, "line": "I eat lunch in the car now. It's my alone time. It's just nice to have some time away from Gabe.", "order": 42398, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 20, "scene": 13, "line": "Why don't you wanna eat lunch with your boyfriend?", "order": 42399, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 20, "scene": 13, "line": "I, really don't like spending time with him.", "order": 42400, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 20, "scene": 13, "line": "Don't you think it's better to tell him the truth now? You know, rather than waste more of his time?", "order": 42401, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 20, "scene": 13, "line": "I think I'm gonna go.", "order": 42402, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 20, "scene": 13, "line": "What?", "order": 42403, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 20, "scene": 13, "line": "I think you got this. Kay? [Erin nods] Alright. Feel better!", "order": 42404, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 20, "scene": 13, "line": "Thank you Jim.", "order": 42405, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 20, "scene": 14, "line": "I'm sorry, that just wasn't interesting to me.", "order": 42406, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 20, "scene": 15, "line": "I can't just dump him Pam, I'm not like you, I can't be mean.", "order": 42407, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 20, "scene": 15, "line": "Wait, when am I...? You just have to be clear and firm. You know, just tell him you don't love him. Just be honest with him. [Erin nods]", "order": 42408, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 20, "scene": 16, "line": "[standing by the Louie Volpies entrance greeting people and giving them menus]", "order": 42409, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 20, "scene": 16, "line": "[walks in] Hi!", "order": 42410, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 20, "scene": 16, "line": "Appalling. [Meredith walks in] Eye sore. [Jim and Pam come in] Surprisingly adequate! Okay everyone! The activities commence in four seconds!", "order": 42411, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 20, "scene": 16, "line": "DeAngelo, Jo's on the phone for you.", "order": 42412, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 20, "scene": 16, "line": "Hello?", "order": 42413, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 20, "scene": 16, "line": "[Michael dressed as Jo Bennett, using a bad Southern accent] DeAngelo! We're in serious horse manure here! The Dundies are tonight! And we ain't got no host!", "order": 42414, "speaker": "Jo Bennett"},
  {"season": 7, "episode": 20, "scene": 16, "line": "Oh no!", "order": 42415, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 20, "scene": 16, "line": "Luckily I have someone for you!", "order": 42416, "speaker": "Jo Bennett"},
  {"season": 7, "episode": 20, "scene": 16, "line": "Billy Crystal?", "order": 42417, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 20, "scene": 16, "line": "Better.", "order": 42418, "speaker": "Jo Bennett"},
  {"season": 7, "episode": 20, "scene": 16, "line": "Neil Patrick Harris?", "order": 42419, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 20, "scene": 16, "line": "He's in Little Shop of Horrors on Broadway. Now we need Michael Scott! The best darn Dundies host on both sides of the Mississippi! [everyone in the audience is laughing]", "order": 42420, "speaker": "Jo Bennett"},
  {"season": 7, "episode": 20, "scene": 16, "line": "Ughhh, I gotta find Michael Scott! And then I gotta, [Russel Brand impression] Get Him to the Dundies!", "order": 42421, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 20, "scene": 16, "line": "[in the office near the accountants] Hey, can you help me find Michael Scott Angela?", "order": 42422, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 20, "scene": 16, "line": "[Michael is wearing a blond wig, talking in a high obnoxious voice] My boyfriend can, he's a state senator.", "order": 42423, "speaker": "Angela Martin"},
  {"season": 7, "episode": 20, "scene": 16, "line": "[laughing]", "order": 42424, "speaker": "Audience"},
  {"season": 7, "episode": 20, "scene": 16, "line": "Mmhmm.", "order": 42425, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 20, "scene": 16, "line": "Oh wait, he can't help because that title has no meaning!", "order": 42426, "speaker": "Angela Martin"},
  {"season": 7, "episode": 20, "scene": 16, "line": "[laughing]", "order": 42427, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 20, "scene": 16, "line": "Try Jim DeAngelo, he'll be able to help.", "order": 42428, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 20, "scene": 16, "line": "Jim! Do you know where I can find Michael Scott?", "order": 42429, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 20, "scene": 16, "line": "[Michael is wearing a wig that is very close to Jim's actual hair, and he is wearing fake earrings, and speaking in a punk-ish deep voice] I totally don't know where Michael is, dude, hey you wanna listen to some records?", "order": 42430, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 20, "scene": 16, "line": "[shaking head at the camera]", "order": 42431, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 20, "scene": 16, "line": "No one is listening to me! And I'm running out of time!", "order": 42432, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 20, "scene": 16, "line": "[Micheal dressed as Phyllis, pretending to knit] Well, what are you talking about? [cracking noise] Ohh! Ohh! [falls onto the ground] I've fallen and I can't get up!", "order": 42433, "speaker": "Phyllis Vance"},
  {"season": 7, "episode": 20, "scene": 16, "line": "Maybe I just need to look into my heart. [in the bathroom in front of the mirror] DeAngelo, where is Michael Scott. [his reflection in the mirror turns around to reveal that it is Michael]", "order": 42434, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 20, "scene": 16, "line": "I'm here. [DeAngelo gasps and turns around] In a good way! I've been here the whole time.", "order": 42435, "speaker": "Michael Scott"},
  {"season": 7, "episode": 20, "scene": 17, "line": "The analytical part of me wants to examine it, but I know it has no content.", "order": 42436, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 20, "scene": 18, "line": "Ladies and gentlemen, please stand up for you co-host this evening, Michael Gary Scott. [Michael comes running up to stage as people applaud] Please remain standing for your other co-host, and new manager, DeAngelo Jeremitrius Vickers! [DeAngelo presents himself, and changes his mind and runs off] Was that part of the...?", "order": 42437, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 20, "scene": 19, "line": "[in the bathroom] DeAngelo, what are you doing? There's a live audience out there!", "order": 42438, "speaker": "Michael Scott"},
  {"season": 7, "episode": 20, "scene": 19, "line": "Go do it by yourself, get Ryan.", "order": 42439, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 20, "scene": 19, "line": "No, Ryan would never do it. It's too on the radar. Look, look. The boss, hosts, the Dundies! It's sort of our perk!", "order": 42440, "speaker": "Michael Scott"},
  {"season": 7, "episode": 20, "scene": 19, "line": "This was not, part of the job description!", "order": 42441, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 20, "scene": 19, "line": "Listen to me, you're not doing this for me, you're not doing this for you, you're not even doing this for them!", "order": 42442, "speaker": "Michael Scott"},
  {"season": 7, "episode": 20, "scene": 19, "line": "Who am I doing this for?", "order": 42443, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 20, "scene": 19, "line": "[slaps him] You're doing this for all those kids out there, eating off the Louie Volpies kid's menu, wondering: Does it get better? What I want you to do, is I want you to say: It's showtime. Get out there.", "order": 42444, "speaker": "Michael Scott"},
  {"season": 7, "episode": 20, "scene": 19, "line": "I can't. [Michael slaps him again]", "order": 42445, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 20, "scene": 19, "line": "Say it.", "order": 42446, "speaker": "Michael Scott"},
  {"season": 7, "episode": 20, "scene": 19, "line": "Stop, hitting me.", "order": 42447, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 20, "scene": 19, "line": "You can do it, just say it.", "order": 42448, "speaker": "Michael Scott"},
  {"season": 7, "episode": 20, "scene": 19, "line": "Hit me again. [Michael slaps him once more]", "order": 42449, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 20, "scene": 19, "line": "Now hit me. [DeAngelo slaps him] One, two, three.", "order": 42450, "speaker": "Michael Scott"},
  {"season": 7, "episode": 20, "scene": 19, "line": "It's showtime.", "order": 42451, "speaker": "Together"},
  {"season": 7, "episode": 20, "scene": 19, "line": "Alright, here we go.", "order": 42452, "speaker": "Michael Scott"},
  {"season": 7, "episode": 20, "scene": 20, "line": "Okay! Okay! Sorry about the delay everybody! But we were at the DMV waiting in line. [everyone laughs]", "order": 42453, "speaker": "Michael Scott"},
  {"season": 7, "episode": 20, "scene": 20, "line": "Fall asleep right after sex. Huh guys?", "order": 42454, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 20, "scene": 20, "line": "Nope, go back to the script.", "order": 42455, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 20, "scene": 20, "line": "There are a lot of great salesmen in this office, but one of our great salesmen is also a great dad. And a close personal friend of mine, Jim Halpert! Best Dad Dundie! [Dwight plays a celebratory honking noise on his keyboard]", "order": 42456, "speaker": "Michael Scott"},
  {"season": 7, "episode": 20, "scene": 20, "line": "Wow! I do not parent for the award but I gotta tell you It feels pretty good. Uh, Cece, if you're watching this at home it's way past your bedtime, by the way how'd this get televised?", "order": 42457, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 20, "scene": 20, "line": "Well done.", "order": 42458, "speaker": "Michael Scott"},
  {"season": 7, "episode": 20, "scene": 20, "line": "I don't know maybe being a good dad is just all in your own compass. I don't know, I don't know. Thank you!", "order": 42459, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 20, "scene": 20, "line": "Alright. [all applaud Jim]", "order": 42460, "speaker": "Michael Scott "},
  {"season": 7, "episode": 20, "scene": 20, "line": "You didn't think to mention me huh?", "order": 42461, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 20, "scene": 20, "line": "Didn't I?", "order": 42462, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 20, "scene": 20, "line": "Which moves us to, Best Mom Dundie. [Pam gets ready and Jim smiles at her] I guess we all kinda consider her a mom around the office. Meredith Palmer!", "order": 42463, "speaker": "Michael Scott"},
  {"season": 7, "episode": 20, "scene": 20, "line": "[jogs up and makes out with Michael, who is unwilling] Tell ya one thing, I'm not gonna be a good mom tonight! Whooo!", "order": 42464, "speaker": "Meredith Palmer"},
  {"season": 7, "episode": 20, "scene": 20, "line": "Excuse me, can I get a photo of the Best Mom and the Best Dad please?", "order": 42465, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 20, "scene": 20, "line": "I gotta go do this.", "order": 42466, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 20, "scene": 20, "line": "[annoyed] Why?", "order": 42467, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 20, "scene": 20, "line": "Big smiles folks! There they are.", "order": 42468, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 20, "scene": 20, "line": "Year after year, I catch a lot of flak on this particular award, because year after year I present this award to a guy instead of a girl. Hottest in the office goes to, [Ryan stands up] Danny Cordray! [Ryan quickly sits] Danny couldn't be here tonight...", "order": 42469, "speaker": "Michael Scott"},
  {"season": 7, "episode": 20, "scene": 21, "line": "How do I feel about not winning Hottest in the Office this year? Uhm, I'm very relieved. How do you, how do you judge something like that? What is the criteria even? It's, it's so subjective.", "order": 42470, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 20, "scene": 22, "line": "Stanley Hudson is a grump, everybody knows that. [Stanley looks angry] But did you know, that Stanley Hudson is also the face of a debilitating disease known as diabetes. The Diabetes Award goes to Stanley Hudson! [Stanley slowly walks over, angry] Come on up here you sick bastard.", "order": 42471, "speaker": "Michael Scott"},
  {"season": 7, "episode": 20, "scene": 23, "line": "I have diabetes too. You don't see me making a big deal about it.", "order": 42472, "speaker": "Phyllis Vance"},
  {"season": 7, "episode": 20, "scene": 24, "line": "They say he's going to be my right hand man, adlib masturbation joke. [realizing he wasn't supposed to read that directly off the cue card, speaks quietly] No, I hate this, I hate it so much. [loud again] Dwight Schrute! Please accept this promising Assistant Manager Dundie. [people clap as Dwight takes the microphone, he is obviously unenthused] Thank you, thank you so much. I would like to thank something that we, uh, take for granted in our daily lives. And that is the humble trashcan. [Creed nods] This is for you trashcan! [walks off and throws the Dundie into the trashcan]", "order": 42473, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 20, "scene": 24, "line": "[the manager of Louie Volpies approaches a giggling Kevin, who is coloring with crayons on the tablecloth] Who gave you those crayons?", "order": 42474, "speaker": "Manager"},
  {"season": 7, "episode": 20, "scene": 24, "line": "I brought them from home! Do you have a red?", "order": 42475, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 20, "scene": 24, "line": "This is a cloth tablecloth! You can't color on it!", "order": 42476, "speaker": "Manager"},
  {"season": 7, "episode": 20, "scene": 24, "line": "Oh really? [camera shows that Kevin has drawn a house on the tablecloth.", "order": 42477, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 20, "scene": 24, "line": "You know, herpes affects one in five sexually active adults? [manager looks up, angrily at Michael] Pippy Longstocking, Ronald McDonald's wife, Ron Howard, Ron Weasley, what do thay all have in common? Red heads. Erin Hannon, come on up here and receive your Cutest Red Head in the Office Award! [Erin looks thrilled and walks up to the stage]", "order": 42478, "speaker": "Michael Scott"},
  {"season": 7, "episode": 20, "scene": 24, "line": "That is bull! [throws her Dundie]", "order": 42479, "speaker": "Meredith Palmer"},
  {"season": 7, "episode": 20, "scene": 24, "line": "Thank you, thank you! This is the first award I've ever won in my entire life. [Gabe and Andy smile at her] People are right about the Dundies, they are magical. But, I don't feel it. And I think that's because I'm not with the right person. Gabe, we should break up.", "order": 42480, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 20, "scene": 24, "line": "What?", "order": 42481, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 20, "scene": 24, "line": "I'm not attracted to you. I just, I cringe when you talk. I have to be honest. Right, right Pam? [Pam looks very awkward] Thank you for hearing me.", "order": 42482, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 20, "scene": 24, "line": "[stands up and walks to stage, takes the microphone] Well, this is embarrassing, um, I'm obviously really angry at Erin. It's that quarter life crisis everyone's been talking about. [Jim is looking wide eyed, jaw dropped] Alright I'm gonna go. [Dwight plays cricket noises]", "order": 42483, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 20, "scene": 24, "line": "[escorts Erin off stage] There you go.", "order": 42484, "speaker": "Michael Scott"},
  {"season": 7, "episode": 20, "scene": 24, "line": "Damn that was cold.", "order": 42485, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 20, "scene": 24, "line": "It is difficult to recognize the person who will be replacing you. But he is a good guy, so the Michael Scott Award for Best Dundies Host goes to DeAngelo Vickers. [DeAngelo jogs up and takes the Dundie and tries to go back to his seat]", "order": 42486, "speaker": "Michael Scott"},
  {"season": 7, "episode": 20, "scene": 24, "line": "Speech! Speech! Speech! Speech!", "order": 42487, "speaker": "Audience"},
  {"season": 7, "episode": 20, "scene": 24, "line": "Hold on, hold on one second. [puts on the headphones and turns the volume up all the way] I WANNA, I WANNA, I FEEL TRULY BLESSED! UHHH, TO BE WORKING WITH ALL OF YOU! BEFORE THIS ALL STARTED, FUNNY STORY, I WAS IN THE BATHROOM, VOMITTING, AND VOMITTING IN THE MEN'S ROOM!", "order": 42488, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 20, "scene": 24, "line": "Okay, okay. [trying to stop him from offending other people in his restaurant]", "order": 42489, "speaker": "Manager"},
  {"season": 7, "episode": 20, "scene": 24, "line": "THAT'S WHY IT'S BEEN SHUT DOWN FOR MOST OF THE EVENING...", "order": 42490, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 20, "scene": 24, "line": "[stopping Manager] DeAngelo is expressing himself. [manager pulls the microphone cable, everyone can still here DeAngelo.", "order": 42491, "speaker": "Michael Scott"},
  {"season": 7, "episode": 20, "scene": 24, "line": "This is truly special, for me! And uh, anyway. It's so much lighter!", "order": 42492, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 20, "scene": 24, "line": "Okay, we're done! That's it.", "order": 42493, "speaker": "Michael Scott"},
  {"season": 7, "episode": 20, "scene": 24, "line": "Michael, are we?!", "order": 42494, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 20, "scene": 24, "line": "We're done. We're done!", "order": 42495, "speaker": "Michael Scott"},
  {"season": 7, "episode": 20, "scene": 25, "line": "[Outside of the restaurant, obviously sad] So that is how it ends. My last Dundies ever. I was hoping it'd be more like Godfather Three. That rapped up the whole franchise in an extremely satisfying way. But instead it is like Godfather One! That was, very confusing, had maybe three big laughs. Oh well...", "order": 42496, "speaker": "Michael Scott"},
  {"season": 7, "episode": 20, "scene": 25, "line": "Michael, we were thinking maybe we could keep this thing going!", "order": 42497, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 20, "scene": 25, "line": "What?", "order": 42498, "speaker": "Michael Scott"},
  {"season": 7, "episode": 20, "scene": 25, "line": "I know I'm the cause of this royal screw up but I would like to see this show go on.", "order": 42499, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 20, "scene": 25, "line": "No, no. You're being too hard on yourself. We had a very truncated rehearsal time.", "order": 42500, "speaker": "Michael Scott"},
  {"season": 7, "episode": 20, "scene": 25, "line": "Let's grab some ice cream, go back to the office, and finish what we started. [others nod in agreement]", "order": 42501, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 20, "scene": 25, "line": "So what you're saying is you kinda like it? [Phyllis impression] I've fallen and I can't get up! [normal again] That Phyllis bit, that was pretty good.", "order": 42502, "speaker": "Michael Scott"},
  {"season": 7, "episode": 20, "scene": 25, "line": "I don't know that we need to dissect it all now, but-", "order": 42503, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 20, "scene": 25, "line": "That got a big laugh.", "order": 42504, "speaker": "Michael Scott"},
  {"season": 7, "episode": 20, "scene": 25, "line": "That did. Pretty huge laugh.", "order": 42505, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 20, "scene": 25, "line": "[to Stanley, who is cracking up again] You were laughing right?", "order": 42506, "speaker": "Michael Scott"},
  {"season": 7, "episode": 20, "scene": 25, "line": "I was.", "order": 42507, "speaker": "Stanley Hudson"},
  {"season": 7, "episode": 20, "scene": 26, "line": "[with Dwight and Michael outside Michael's car] You know what, I have to go to the bathroom.", "order": 42508, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 20, "scene": 26, "line": "We're really close.", "order": 42509, "speaker": "Michael Scott"},
  {"season": 7, "episode": 20, "scene": 26, "line": "I can just run over to the gas station.", "order": 42510, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 20, "scene": 26, "line": "We should just wait here and not get good seats in the conference room?", "order": 42511, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 20, "scene": 26, "line": "That's true, we wouldn't get seats together.", "order": 42512, "speaker": "Michael Scott"},
  {"season": 7, "episode": 20, "scene": 26, "line": "Okay, fine I'll hold it.", "order": 42513, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 20, "scene": 26, "line": "[in the car] You know, despite a couple hiccups, I think that went very well.", "order": 42514, "speaker": "Michael Scott"},
  {"season": 7, "episode": 20, "scene": 26, "line": "I thought it was the worst Dundies I've ever been to.", "order": 42515, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 20, "scene": 26, "line": "Man! Maybe you should have won the Kind of a Bitch Award.", "order": 42516, "speaker": "Michael Scott"},
  {"season": 7, "episode": 20, "scene": 26, "line": "Gladly! I'd accept that award, because a bitch, is a female dog!", "order": 42517, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 20, "scene": 26, "line": "[as Michael pulls over and stops the car] Please don't stop so suddenly, the seatbelt is pressing on my bladder. Why are you even wearing a seatbelt, you're sitting in the backseat, baby.", "order": 42518, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 20, "scene": 26, "line": "What is your problem?!", "order": 42519, "speaker": "Michael Scott"},
  {"season": 7, "episode": 20, "scene": 26, "line": "I just don't see a point in the Dundies! Okay? The jokes are terrible, the venue is bad, the fashion is boring.", "order": 42520, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 20, "scene": 26, "line": "Okay! That, that is unfair! The clothing was safe, but tasteful.", "order": 42521, "speaker": "Michael Scott"},
  {"season": 7, "episode": 20, "scene": 26, "line": "And next time, why don't you pick a co-host, that doesn't have microphone-a-phobia!", "order": 42522, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 20, "scene": 26, "line": "[very uncomfortable] Look, what ever you're going to do, how long is it going to take? Ballpark. 'Cause I just, I gotta make a decision here. [gets out and slams the door]", "order": 42523, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 20, "scene": 26, "line": "He is in an all-out sprint.", "order": 42524, "speaker": "Michael Scott"},
  {"season": 7, "episode": 20, "scene": 27, "line": "[in conference room] Here... [Andy walks in] Okay! By his own omission, this person actually tried marijuana in college, so the Doobie Doobie Pothead Stoner of the Year Award goes to, Andy Bernard! [Andy walks up]", "order": 42525, "speaker": "Michael Scott"},
  {"season": 7, "episode": 20, "scene": 27, "line": "A lot of people I'd like to thank, but I think we all actually wanna thank you Michael.", "order": 42526, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 20, "scene": 27, "line": "Oh, okay. [nods to him]", "order": 42527, "speaker": "Michael Scott"},
  {"season": 7, "episode": 20, "scene": 27, "line": "I mean, we actually all really wanna thank you, for everything.", "order": 42528, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 20, "scene": 27, "line": "[starts playing the tune to Seasons of Love while Andy sits down]", "order": 42529, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 20, "scene": 27, "line": "Oh my God, something's happening.", "order": 42530, "speaker": "Michael Scott"},
  {"season": 7, "episode": 20, "scene": 27, "line": "[singing] Nine million, nine hundred eighty six thousand minutes! We actually sat down, and did the math.", "order": 42531, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 20, "scene": 27, "line": "Nine million, nine hundred eighty six thousand minutes! That's how many minutes, that you've worked here.", "order": 42532, "speaker": "All"},
  {"season": 7, "episode": 20, "scene": 27, "line": "In costumes!", "order": 42533, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 20, "scene": 27, "line": "And impressions!", "order": 42534, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 20, "scene": 27, "line": "In meetings.", "order": 42535, "speaker": "Toby Flenderson"},
  {"season": 7, "episode": 20, "scene": 27, "line": "And cups of coffee.", "order": 42536, "speaker": "Erin Hannon & Kelly Kapoor"},
  {"season": 7, "episode": 20, "scene": 27, "line": "For birthdays!", "order": 42537, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 20, "scene": 27, "line": "More meetings and-", "order": 42538, "speaker": "Stanley Hudson"},
  {"season": 7, "episode": 20, "scene": 27, "line": "E-Mail forms you made us read.", "order": 42539, "speaker": "Women"},
  {"season": 7, "episode": 20, "scene": 27, "line": "Nine million, nine hundred eighty six thousand minutes! That's like watching Die Hard, eighty thousand times!", "order": 42540, "speaker": "All"},
  {"season": 7, "episode": 20, "scene": 27, "line": "You hit me with your car!", "order": 42541, "speaker": "Meredith Palmer"},
  {"season": 7, "episode": 20, "scene": 27, "line": "You helped me get off drugs!", "order": 42542, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 20, "scene": 27, "line": "I watch you when you sleep.", "order": 42543, "speaker": "Creed Bratton"},
  {"season": 7, "episode": 20, "scene": 27, "line": "I forgive you for kissing me!", "order": 42544, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 20, "scene": 27, "line": "Remember to, call. Call. Remember to, call. Call. Remember to, call. Call.", "order": 42545, "speaker": "All"},
  {"season": 7, "episode": 20, "scene": 27, "line": "Call, text or e-mail, or call.", "order": 42546, "speaker": "Kelly Kapoor & Erin Hannon"},
  {"season": 7, "episode": 20, "scene": 27, "line": "[falsetto] Measure your life in love!", "order": 42547, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 20, "scene": 27, "line": "Remember to call, remember to, call. Remember to call.", "order": 42548, "speaker": "All"},
  {"season": 7, "episode": 20, "scene": 28, "line": "[choked up] Yeah, okay. [pauses, then takes a deep breath] Well this is gonna hurt like a mother[bleep].", "order": 42549, "speaker": "Michael Scott"},
  {"season": 7, "episode": 20, "scene": 29, "line": "[recall to Dundies at Louie Volpies] Every day, millions of Americans suffer from extreme repulsiveness. Someone in our midst, is bringing that problem to light. Toby Flenderson, please come up here and accept the Extreme Repulsiveness Award. Oh that's so mean!", "order": 42550, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 20, "scene": 29, "line": "No it's not.", "order": 42551, "speaker": "Michael Scott"},
  {"season": 7, "episode": 20, "scene": 29, "line": "[Toby is shaking his head, no] It's his last Dundies.", "order": 42552, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 20, "scene": 29, "line": "You gotta play along man.", "order": 42553, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 20, "scene": 29, "line": "Come on Toby.", "order": 42554, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 20, "scene": 29, "line": "Here he comes! Alright! You deserve it! [Toby comes up and take the mic]", "order": 42555, "speaker": "Michael Scott"},
  {"season": 7, "episode": 20, "scene": 29, "line": "I really disagree with this. I think it's kind of hateful. Though I am a little happy right now to have a platform to talk about the outcome of a case I was recently a juror on. The Scranton Strangler. That man's being put to death. I was part of the verdict, and I'm not so sure he's guilty any more. [Dwight plays a clip from the song Oh Yeah: Ohhhh yeah! Chica chicaaa]", "order": 42556, "speaker": "Toby Flenderson"},
  {"season": 7, "episode": 21, "scene": 1, "line": "[sitting on the roof of the office building] Well, I'm moving to Colorado to start my new life with Holly. Just up here, getting used to the altitude.", "order": 42557, "speaker": "Michael Scott"},
  {"season": 7, "episode": 21, "scene": 1, "line": "[walking up] Michael?", "order": 42558, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 21, "scene": 1, "line": "Yes?", "order": 42559, "speaker": "Michael Scott"},
  {"season": 7, "episode": 21, "scene": 1, "line": "I've got a treat for you!", "order": 42560, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 21, "scene": 1, "line": "Ahh, thank you. Like a butler.", "order": 42561, "speaker": "Michael Scott"},
  {"season": 7, "episode": 21, "scene": 1, "line": "Colorado specialty, Rocky Mountain Oysters.", "order": 42562, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 21, "scene": 1, "line": "[Michael takes one and bites] Oh, these do not taste like oysters.", "order": 42563, "speaker": "Michael Scott"},
  {"season": 7, "episode": 21, "scene": 1, "line": "[angrily] That's because they're Not oysters, they're bull testicles! I cut them off fresh this morning! [Michael spits it out] Hah!", "order": 42564, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 21, "scene": 1, "line": "Sick freak! What is wrong with you?", "order": 42565, "speaker": "Michael Scott"},
  {"season": 7, "episode": 21, "scene": 1, "line": "What is wrong with you? I'm the sick freak? After what you did? You expect to be buttled? You didn't recommend me?!", "order": 42566, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 21, "scene": 1, "line": "I don't own Dunder Mifflin, okay? [Dwight scoffs] The job was not mine to give. [sighs] Look, I need your advice on something. I am told that there are bears in the Rockies.", "order": 42567, "speaker": "Michael Scott"},
  {"season": 7, "episode": 21, "scene": 1, "line": "Where did you hear that? Obvious XM Radio?", "order": 42568, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 21, "scene": 1, "line": "Well, I was just thinking that maybe I should keep a salami in my pocket...", "order": 42569, "speaker": "Michael Scott"},
  {"season": 7, "episode": 21, "scene": 1, "line": "Great idea.", "order": 42570, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 21, "scene": 1, "line": "...in order to feed the bears.", "order": 42571, "speaker": "Michael Scott"},
  {"season": 7, "episode": 21, "scene": 1, "line": "Especially if you think that life would be better without Legs!", "order": 42572, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 21, "scene": 1, "line": "How do you mean?", "order": 42573, "speaker": "Michael Scott"},
  {"season": 7, "episode": 21, "scene": 1, "line": "Black Bears can smell a salami at five miles Michael, what are you thinking?! And they run faster than a horse, so if you were thinking about outrunning one on a horse I would try a cheetah. You, in tight pants, Michael, are a salami to a Black Bear. Do you understand? [Michael nods in agreement] You're like a giant walking salami!", "order": 42574, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 21, "scene": 1, "line": "Okay, so no salami in the pants. How about a pepperoni?", "order": 42575, "speaker": "Michael Scott"},
  {"season": 7, "episode": 21, "scene": 1, "line": "Any kind of meat that you can possibly name!", "order": 42576, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 21, "scene": 1, "line": "Okay.", "order": 42577, "speaker": "Michael Scott"},
  {"season": 7, "episode": 21, "scene": 2, "line": "[with Michael in his office] You're not gonna take all these toys are you?", "order": 42578, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 21, "scene": 2, "line": "Hm?", "order": 42579, "speaker": "Michael Scott"},
  {"season": 7, "episode": 21, "scene": 2, "line": "I mean you don't have a job lined up, so it's not like you have a desk to put 'em on.", "order": 42580, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 21, "scene": 2, "line": "Well I have interviews.", "order": 42581, "speaker": "Michael Scott"},
  {"season": 7, "episode": 21, "scene": 2, "line": "That's nice. [reaches and a grabs a toy truck] How 'bout that truck? Can I have this little truck? I was thinking I might... glue a stapler on top. Or put a hole here, stick pens in it. [Michael gives an obviously fake smile] You okay?", "order": 42582, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 21, "scene": 2, "line": "Yes. Yes, you know what? Take my favorite truck. Sure.", "order": 42583, "speaker": "Michael Scott"},
  {"season": 7, "episode": 21, "scene": 2, "line": "You know what? Uh, it's your last couple days, I'm gonna get out of your hair.", "order": 42584, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 21, "scene": 2, "line": "Oh you don't, that's-", "order": 42585, "speaker": "Michael Scott"},
  {"season": 7, "episode": 21, "scene": 2, "line": "I will be in the break room.", "order": 42586, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 21, "scene": 2, "line": "That sounds good. Thank you.", "order": 42587, "speaker": "Michael Scott"},
  {"season": 7, "episode": 21, "scene": 3, "line": "Dead man walking.", "order": 42588, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 21, "scene": 4, "line": "Sad? No, no, no. I don't leave 'til tomorrow, so... Tomorrow I will be a wreck.", "order": 42589, "speaker": "Michael Scott"},
  {"season": 7, "episode": 21, "scene": 5, "line": "[sees Andy walk into the Men's Room and follows him in] Stay away from Erin! [cornering him in the bathroom]", "order": 42590, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 21, "scene": 5, "line": "Hey!", "order": 42591, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 21, "scene": 5, "line": "I'm your boss!", "order": 42592, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 21, "scene": 5, "line": "Why don't you, uh, stay away from me?", "order": 42593, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 21, "scene": 5, "line": "No, I'm gonna stand where I want. Okay? You don't wanna get on my bad side! I've seen some horrible things! I own over two hundred horror movies!", "order": 42594, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 21, "scene": 5, "line": "Okay! That's so weird! Just go away!", "order": 42595, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 21, "scene": 5, "line": "No! You go away! [storms out, toilet flushes, Jim exits the bathroom]", "order": 42596, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 21, "scene": 5, "line": "[weak] Hi Tuna.", "order": 42597, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 21, "scene": 6, "line": "So you guys are filming people when they go to the bathroom now?", "order": 42598, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 21, "scene": 7, "line": "[in the conference room with Pam, Angela, and Meredith] How about cupcakes?", "order": 42599, "speaker": "Phyllis Vance"},
  {"season": 7, "episode": 21, "scene": 7, "line": "Please.", "order": 42600, "speaker": "Angela Martin"},
  {"season": 7, "episode": 21, "scene": 7, "line": "What's wrong with cupcakes?", "order": 42601, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 21, "scene": 7, "line": "Everything.", "order": 42602, "speaker": "Angela Martin"},
  {"season": 7, "episode": 21, "scene": 7, "line": "[walking in] There they are! Party Planning Committee together again!", "order": 42603, "speaker": "Michael Scott"},
  {"season": 7, "episode": 21, "scene": 7, "line": "Well we all wanted to plan your goodbye party. We thought this would be easier.", "order": 42604, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 21, "scene": 8, "line": "We thought.", "order": 42605, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 21, "scene": 8, "line": "It's an experiment.", "order": 42606, "speaker": "Angela Martin"},
  {"season": 7, "episode": 21, "scene": 8, "line": "The three of us have all been chairmen before.", "order": 42607, "speaker": "Phyllis Vance"},
  {"season": 7, "episode": 21, "scene": 8, "line": "So this is the dream team.", "order": 42608, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 21, "scene": 8, "line": "[walking up behind the glass] Hey! What are you saying?!", "order": 42609, "speaker": "Meredith Palmer"},
  {"season": 7, "episode": 21, "scene": 8, "line": "The dream team... and Meredith.", "order": 42610, "speaker": "Angela Martin"},
  {"season": 7, "episode": 21, "scene": 9, "line": "[to Michael] We decided on the ice cream. Mint chocolate chip, your favorite.", "order": 42611, "speaker": "Meredith Palmer"},
  {"season": 7, "episode": 21, "scene": 9, "line": "Mmm!", "order": 42612, "speaker": "Michael Scott"},
  {"season": 7, "episode": 21, "scene": 9, "line": "Yeah that was a surprise...", "order": 42613, "speaker": "Phyllis Vance"},
  {"season": 7, "episode": 21, "scene": 9, "line": "You know what, I'm thinking maybe we should get ice cream that everybody'll like. How about vanilla? Let's get vanilla.", "order": 42614, "speaker": "Michael Scott"},
  {"season": 7, "episode": 21, "scene": 9, "line": "[surprised] Okay.", "order": 42615, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 21, "scene": 9, "line": "Tomorrow I want everyone to have a good time. No drama. And as for today, just a typical day. Alright?", "order": 42616, "speaker": "Michael Scott"},
  {"season": 7, "episode": 21, "scene": 9, "line": "Should we get toppings?", "order": 42617, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 21, "scene": 9, "line": "What do you like Pam?", "order": 42618, "speaker": "Michael Scott"},
  {"season": 7, "episode": 21, "scene": 9, "line": "[confused] What?", "order": 42619, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 21, "scene": 9, "line": "What kind of toppings would you like?", "order": 42620, "speaker": "Michael Scott"},
  {"season": 7, "episode": 21, "scene": 9, "line": "Hot fudge?", "order": 42621, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 21, "scene": 9, "line": "Sounds good. Fudge it up! [all seem surprised at Michael's behavior]", "order": 42622, "speaker": "Michael Scott"},
  {"season": 7, "episode": 21, "scene": 10, "line": "[holding up his 'World's Best Boss Mug'] I bought this for myself. And yesterday, they gave me this. [holds up a Dundie that reads, 'World's Best Boss 2011, Michael G. Scott', throws his mug into the garbage can and places his Dundie at the front of his desk] I still need something to drink out of though.", "order": 42623, "speaker": "Michael Scott"},
  {"season": 7, "episode": 21, "scene": 11, "line": "[pulls out a list of everyone in the office, then puts it back in his jacket] Attention everyone, before I leave tomorrow I would like to reveal a secret I have kept for over twenty years. A secret about Phyllis.", "order": 42624, "speaker": "Michael Scott"},
  {"season": 7, "episode": 21, "scene": 11, "line": "Please Michael.", "order": 42625, "speaker": "Phyllis Vance"},
  {"season": 7, "episode": 21, "scene": 11, "line": "When Phyllis was in high school, she was so... cute. [Phyllis looks relieved] And she still is.", "order": 42626, "speaker": "Michael Scott"},
  {"season": 7, "episode": 21, "scene": 12, "line": "I thought he knew about the baby I gave away.", "order": 42627, "speaker": "Phyllis Vance"},
  {"season": 7, "episode": 21, "scene": 13, "line": "[holds up mittens she's knitting] Look Michael, it's a going away present so your hands won't get cold.", "order": 42628, "speaker": "Phyllis Vance"},
  {"season": 7, "episode": 21, "scene": 13, "line": "Ahh.", "order": 42629, "speaker": "Michael Scott"},
  {"season": 7, "episode": 21, "scene": 13, "line": "It's almost done, but you can't get them wet, and they can't be dry-cleaned either. You have to hand wash without water, wring-dry gently, and use a hairdryer on cool.", "order": 42630, "speaker": "Phyllis Vance"},
  {"season": 7, "episode": 21, "scene": 13, "line": "[hesitantly] Sounds great, I just think it's great. Oh, I have gifts as well! [goes into his office and comes out with a bag] And I will start, by giving the first gift to Phyllis! Phyllis, you are shy and sweet, and you don't often speak your mind, but you should. Because you have great ideas. So Phyllis, I am giving you this, so you can always remember to speak your mind. [presents to her a mouth wind-up chattering toy]", "order": 42631, "speaker": "Michael Scott"},
  {"season": 7, "episode": 21, "scene": 13, "line": "It's cute. [plays with it]", "order": 42632, "speaker": "Phyllis Vance"},
  {"season": 7, "episode": 21, "scene": 13, "line": "Stanley, you love your Sudoku and your puzzles, I bestow upon you, my felt. [gives Stanley a small felt table] May you never lose the fun loving quality in life.", "order": 42633, "speaker": "Michael Scott"},
  {"season": 7, "episode": 21, "scene": 13, "line": "Where's the rest of it, it's got no balls.", "order": 42634, "speaker": "Stanley Hudson"},
  {"season": 7, "episode": 21, "scene": 13, "line": "Well, okay... And Andy, Andy who needs confidence that he is a great salesman. I give you, my clients, our ten most important accounts. [other salesmen look outraged]", "order": 42635, "speaker": "Michael Scott"},
  {"season": 7, "episode": 21, "scene": 13, "line": "Wow.", "order": 42636, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 21, "scene": 13, "line": "[angry] Yeah wow!", "order": 42637, "speaker": "Stanley Hudson"},
  {"season": 7, "episode": 21, "scene": 13, "line": "You know I'm the worst salesman here right?", "order": 42638, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 21, "scene": 13, "line": "But you're the best salesman, on the inside.", "order": 42639, "speaker": "Michael Scott"},
  {"season": 7, "episode": 21, "scene": 13, "line": "What does that even mean?", "order": 42640, "speaker": "Phyllis Vance"},
  {"season": 7, "episode": 21, "scene": 13, "line": "You sold us all on Andy, a product that nobody wanted.", "order": 42641, "speaker": "Michael Scott"},
  {"season": 7, "episode": 21, "scene": 13, "line": "I'm gonna lose 'em.", "order": 42642, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 21, "scene": 13, "line": "You're not gonna lose them.", "order": 42643, "speaker": "Michael Scott"},
  {"season": 7, "episode": 21, "scene": 13, "line": "I promise you that I will.", "order": 42644, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 21, "scene": 13, "line": "Just do your best! I have faith in you.", "order": 42645, "speaker": "Michael Scott"},
  {"season": 7, "episode": 21, "scene": 13, "line": "[as Michael walks away, Stanley whispers angrily to Andy] Gimme those clients!", "order": 42646, "speaker": "Stanley Hudson"},
  {"season": 7, "episode": 21, "scene": 13, "line": "No.", "order": 42647, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 21, "scene": 13, "line": "[to Dwight] Do you believe that?!", "order": 42648, "speaker": "Phyllis Vance"},
  {"season": 7, "episode": 21, "scene": 14, "line": "I've given up expecting Michael to do the right thing. Or the decent thing. Or even the comprehensible thing.", "order": 42649, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 21, "scene": 15, "line": "I used to be obese. Once you've conquered obesity, everything else is easy. Life literally moves in slow motion. I'm not saying I'm superman, but, let me just put it this way. If I were shot in the head, I'm pretty sure everything would be fine. I almost welcome it. [winks]", "order": 42650, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 21, "scene": 16, "line": "Kevin, I have something for you.", "order": 42651, "speaker": "Michael Scott"},
  {"season": 7, "episode": 21, "scene": 16, "line": "Oh! [Michael unrolls it to reveal it is a caricature of Kevin as a pig eating pizza]", "order": 42652, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 21, "scene": 16, "line": "You know who that is?", "order": 42653, "speaker": "Michael Scott"},
  {"season": 7, "episode": 21, "scene": 16, "line": "Oh...", "order": 42654, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 21, "scene": 16, "line": "[rips the poster in half] Don't be a caricature Kevin, never be a caricature. How did that feel when I tore that up?", "order": 42655, "speaker": "Michael Scott"},
  {"season": 7, "episode": 21, "scene": 16, "line": "Better...", "order": 42656, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 21, "scene": 16, "line": "Good, stand up. [Kevin does so] You will be thin. You Won't drool over pizza like an animal anymore.", "order": 42657, "speaker": "Michael Scott"},
  {"season": 7, "episode": 21, "scene": 16, "line": "But...", "order": 42658, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 21, "scene": 16, "line": "You will find love.", "order": 42659, "speaker": "Michael Scott"},
  {"season": 7, "episode": 21, "scene": 16, "line": "Michael, I'm pretty much okay with who I am now.", "order": 42660, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 21, "scene": 16, "line": "Don't be. You should never settle for who you are. [moving on] Oscar, Oscar, Oscar! Oscar, you are-", "order": 42661, "speaker": "Michael Scott"},
  {"season": 7, "episode": 21, "scene": 16, "line": "I just lost Porter Hardware! I just, I lost 'em!", "order": 42662, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 21, "scene": 16, "line": "[quietly groans] Okay, you know what? Just do your best buddy! Okay, Oscar, you are very smart, and you have a gigantic education. And, I think of you as my scarecrow, because you gave me a brain. So that's why I made you this. [takes a sack tied in the upper middle making a crude head, with a face drawn on, obviously the product of poor craftsmanship, Oscar pretends to like it]", "order": 42663, "speaker": "Michael Scott"},
  {"season": 7, "episode": 21, "scene": 16, "line": "Thank you Michael. It's beautiful.", "order": 42664, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 21, "scene": 17, "line": "[laughing] It looks like a [more laughing] It looks like it was made by a two year old monkey on a farm! He just accepted, accepted that I put all this work into it! [laughs more] He has the lowest opinion of me, of anybody!", "order": 42665, "speaker": "Michael Scott"},
  {"season": 7, "episode": 21, "scene": 18, "line": "Was it just me, or did you think that we were going to have sex at some point?", "order": 42666, "speaker": "Michael Scott"},
  {"season": 7, "episode": 21, "scene": 18, "line": "It was just you...", "order": 42667, "speaker": "Angela Martin"},
  {"season": 7, "episode": 21, "scene": 18, "line": "How would you have wanted to do it? Okay, okay. You know what? Inappropriate. I am engaged happily, and you have landed yourself a Senator.", "order": 42668, "speaker": "Michael Scott"},
  {"season": 7, "episode": 21, "scene": 18, "line": "State Senator.", "order": 42669, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 21, "scene": 18, "line": "Mmhmm, bravo!", "order": 42670, "speaker": "Michael Scott"},
  {"season": 7, "episode": 21, "scene": 18, "line": "Brava!", "order": 42671, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 21, "scene": 18, "line": "You wanna see some pictures? I just got these.", "order": 42672, "speaker": "Angela Martin"},
  {"season": 7, "episode": 21, "scene": 18, "line": "Sure!", "order": 42673, "speaker": "Michael Scott"},
  {"season": 7, "episode": 21, "scene": 18, "line": "Uhm, these are, okay this is us at the theater. And antiquing! Oh, rollerblading.", "order": 42674, "speaker": "Angela Martin"},
  {"season": 7, "episode": 21, "scene": 18, "line": "Who's that? Who's that guy? [points to second guy in the pictures]", "order": 42675, "speaker": "Michael Scott"},
  {"season": 7, "episode": 21, "scene": 18, "line": "Oh, that's Thomas, Robert's aide.", "order": 42676, "speaker": "Angela Martin"},
  {"season": 7, "episode": 21, "scene": 18, "line": "I guess this could be the one, huh?", "order": 42677, "speaker": "Michael Scott"},
  {"season": 7, "episode": 21, "scene": 18, "line": "Yeah. [Oscar shakes his head no]", "order": 42678, "speaker": "Angela Martin"},
  {"season": 7, "episode": 21, "scene": 19, "line": "[walking into the kitchen, DeAngelo is using the coffee machine to cook chicken covered in melted chocolate] Hey.", "order": 42679, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 21, "scene": 19, "line": "[hurriedly, while hiding the chocolate covered chicken] Hey!", "order": 42680, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 21, "scene": 19, "line": "I'm going to be dropping out on one of our biggest clients this afternoon and I could use some back up.", "order": 42681, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 21, "scene": 19, "line": "Let's rip it up homes. [smacks top of the door frame with his hand, leaving a chocolate hand outline]", "order": 42682, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 21, "scene": 20, "line": "[Andy walks by with DeAngelo, says quietly after he leaves] Walk away bitch.", "order": 42683, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 21, "scene": 21, "line": "[Michael is leaving the accounting area] Oh Michael! Where do you want your last pay check sent?", "order": 42684, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 21, "scene": 21, "line": "My last pay check?", "order": 42685, "speaker": "Michael Scott"},
  {"season": 7, "episode": 21, "scene": 21, "line": "You have an address yet in Colorado?", "order": 42686, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 21, "scene": 21, "line": "No.", "order": 42687, "speaker": "Michael Scott"},
  {"season": 7, "episode": 21, "scene": 21, "line": "What town do Holly's parents live in?", "order": 42688, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 21, "scene": 21, "line": "I'm not sure, um, Mountainton?", "order": 42689, "speaker": "Michael Scott"},
  {"season": 7, "episode": 21, "scene": 21, "line": "Sounds beautiful!", "order": 42690, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 21, "scene": 22, "line": "[Michael sits alone eating at the back of the kitchen, Pam comes in with Jim, Creed and Kevin] You should do more stuff like that.", "order": 42691, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 21, "scene": 22, "line": "I'm going to!", "order": 42692, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 21, "scene": 22, "line": "[to Michael] Hey! It's almost your last day, come sit with us.", "order": 42693, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 21, "scene": 22, "line": "Nah, I'm almost done.", "order": 42694, "speaker": "Michael Scott"},
  {"season": 7, "episode": 21, "scene": 22, "line": "You sure?", "order": 42695, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 21, "scene": 22, "line": "Yeah.", "order": 42696, "speaker": "Michael Scott"},
  {"season": 7, "episode": 21, "scene": 22, "line": "So I'm going to Carbondale this afternoon to get a new bulk shredder.", "order": 42697, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 21, "scene": 22, "line": "Finally! That old shredder sucked.", "order": 42698, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 21, "scene": 22, "line": "It's a good shredder, it just keeps breaking.", "order": 42699, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 21, "scene": 22, "line": "Yeah, it won't shred magazines.", "order": 42700, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 21, "scene": 22, "line": "It's not supposed to shred magazines Kevin.", "order": 42701, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 21, "scene": 22, "line": "I know...", "order": 42702, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 21, "scene": 22, "line": "Did you break the shredder Kevin?", "order": 42703, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 21, "scene": 22, "line": "No, it's just... that old shredder sucks. [Michael looks tearful] Just get one that'll shred magazines.", "order": 42704, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 21, "scene": 22, "line": "I don't think any of them are supposed to shred magazines.", "order": 42705, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 21, "scene": 23, "line": "[crying] I can't do this. All the channels are gonna be different there. I'm not gonna be able to find my shows. I'm not going to start improv at level one, and I don't think my credits will transfer. Ugh, and you know what? I just figured out where I was supposed to go to vote. [picks World's Best Boss mug out of the trashcan and puts it back on his desk] I gotta call her, and I'm going to tell her, that I cannot come. [dials on his phone]", "order": 42706, "speaker": "Michael Scott"},
  {"season": 7, "episode": 21, "scene": 23, "line": "[on phone] Hello there!", "order": 42707, "speaker": "Holly Flax"},
  {"season": 7, "episode": 21, "scene": 23, "line": "Hi. What is the name of our town?", "order": 42708, "speaker": "Michael Scott"},
  {"season": 7, "episode": 21, "scene": 23, "line": "Boulder. Is something wrong? Are you okay?", "order": 42709, "speaker": "Holly Flax"},
  {"season": 7, "episode": 21, "scene": 23, "line": "No, no. I just needed to hear your voice.", "order": 42710, "speaker": "Michael Scott"},
  {"season": 7, "episode": 21, "scene": 23, "line": "[Yoda voice] Oh you mean this?", "order": 42711, "speaker": "Holly Flax"},
  {"season": 7, "episode": 21, "scene": 23, "line": "[laughing] Yes. [Olive Oyl impression] Yes my hero!", "order": 42712, "speaker": "Michael Scott"},
  {"season": 7, "episode": 21, "scene": 23, "line": "[deep man voice] I'll pay the rent! [Michael laughs] Okay, my mom's looking at me and she has no sense of humor. It's a joke mom!", "order": 42713, "speaker": "Holly Flax"},
  {"season": 7, "episode": 21, "scene": 23, "line": "[laughing] Ohh, I miss you.", "order": 42714, "speaker": "Michael Scott"},
  {"season": 7, "episode": 21, "scene": 23, "line": "Well I'll see you tonight. I'll pick you up outside baggage claim.", "order": 42715, "speaker": "Holly Flax"},
  {"season": 7, "episode": 21, "scene": 23, "line": "Okay. I'll see you tonight. I love you.", "order": 42716, "speaker": "Michael Scott"},
  {"season": 7, "episode": 21, "scene": 23, "line": "I love you too.", "order": 42717, "speaker": "Holly Flax"},
  {"season": 7, "episode": 21, "scene": 23, "line": "[hangs up and composes himself] Yes, so I know I told everybody that tomorrow is my last day, but I'm, I'm gonna be leaving tonight. I head to the airport at four. [looks at the list of members of the office, with some names crossed out] And I have said goodbye to half of them.", "order": 42718, "speaker": "Michael Scott"},
  {"season": 7, "episode": 21, "scene": 24, "line": "Well you know Michael, I have a brother in Boulder. Rory Flenderson. You should look him up.", "order": 42719, "speaker": "Toby Flenderson"},
  {"season": 7, "episode": 21, "scene": 24, "line": "[grimaces and nods, Toby nods back at him] Okay.", "order": 42720, "speaker": "Michael Scott"},
  {"season": 7, "episode": 21, "scene": 24, "line": "[smiling] Okay.", "order": 42721, "speaker": "Toby Flenderson"},
  {"season": 7, "episode": 21, "scene": 24, "line": "[walks over to Kelly] Kelly! Kelly? Kelly.", "order": 42722, "speaker": "Michael Scott"},
  {"season": 7, "episode": 21, "scene": 24, "line": "[doing make up and irritated] What?!", "order": 42723, "speaker": "Kelly Kapoor"},
  {"season": 7, "episode": 21, "scene": 24, "line": "If I just went away right now, would that be the best gift that I could give you?", "order": 42724, "speaker": "Michael Scott"},
  {"season": 7, "episode": 21, "scene": 24, "line": "Yes! Please! Please go away! And stop using that weird slow voice.", "order": 42725, "speaker": "Kelly Kapoor"},
  {"season": 7, "episode": 21, "scene": 25, "line": "[in Ryan's office, which is illuminated blue] She was once my girl, and she is your girl now.", "order": 42726, "speaker": "Michael Scott"},
  {"season": 7, "episode": 21, "scene": 25, "line": "Wow. [camera pans over to show that the light is from Michael's St. Pauli Girl Sign, hanging in Ryan's office]", "order": 42727, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 21, "scene": 25, "line": "Yeah.", "order": 42728, "speaker": "Michael Scott"},
  {"season": 7, "episode": 21, "scene": 25, "line": "This is... totally unnecessary.", "order": 42729, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 21, "scene": 25, "line": "You're not prone to seizures?", "order": 42730, "speaker": "Michael Scott"},
  {"season": 7, "episode": 21, "scene": 25, "line": "No.", "order": 42731, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 21, "scene": 26, "line": "[in conference room with the party planning committee] So Michael said we can do whatever we want cake-wise. What do we want?", "order": 42732, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 21, "scene": 26, "line": "[quickly] Erotic.", "order": 42733, "speaker": "Meredith Palmer"},
  {"season": 7, "episode": 21, "scene": 26, "line": "See? This is what happens. You can't let a stray dog into the house.", "order": 42734, "speaker": "Angela Martin"},
  {"season": 7, "episode": 21, "scene": 26, "line": "Hmm, let's hear here out. I would like to hear more about these cakes. [Angela and Pam look uncomfortable]", "order": 42735, "speaker": "Phyllis Vance"},
  {"season": 7, "episode": 21, "scene": 26, "line": "I know these Ethiopians that run a cake shop.", "order": 42736, "speaker": "Meredith Palmer"},
  {"season": 7, "episode": 21, "scene": 26, "line": "Good God...", "order": 42737, "speaker": "Angela Martin"},
  {"season": 7, "episode": 21, "scene": 26, "line": "They make these cakes, they're wild! I mean, they show everything!", "order": 42738, "speaker": "Meredith Palmer"},
  {"season": 7, "episode": 21, "scene": 26, "line": "I don't, I don't think we sh-", "order": 42739, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 21, "scene": 26, "line": "I know what you're thinking, but it's not just black, they do it all. And the women on these cakes, they're not just guy's fantasies. They have real, full women. It is refreshing.", "order": 42740, "speaker": "Meredith Palmer"},
  {"season": 7, "episode": 21, "scene": 26, "line": "[stopping her] Okay, don't turn this into some feminist issue.", "order": 42741, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 21, "scene": 26, "line": "As a person who buys a lot of erotic cakes, it feels good to be represented on one.", "order": 42742, "speaker": "Phyllis Vance"},
  {"season": 7, "episode": 21, "scene": 26, "line": "You know what, I think we should get some other input.", "order": 42743, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 21, "scene": 26, "line": "I think we should do cupcakes. I am one of the few people who looks hot eating a cupcake.", "order": 42744, "speaker": "Kelly Kapoor"},
  {"season": 7, "episode": 21, "scene": 26, "line": "Yeah, cupcakes. That's what I said.", "order": 42745, "speaker": "Phyllis Vance"},
  {"season": 7, "episode": 21, "scene": 26, "line": "No! I'm not cleaning up a bunch of uneaten cupcake bottoms! You know, we don't really care about your opinion. You're just a tie-breaker.", "order": 42746, "speaker": "Angela Martin"},
  {"season": 7, "episode": 21, "scene": 27, "line": "[sees Pam walking towards the door of the office] Um, Pam.", "order": 42747, "speaker": "Michael Scott"},
  {"season": 7, "episode": 21, "scene": 27, "line": "Oh, hey Michael! I'm just going to go to Carbondale to price some shredders. I'll see you later.", "order": 42748, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 21, "scene": 27, "line": "[disappointed] Okay... [Jim is confused]", "order": 42749, "speaker": "Michael Scott"},
  {"season": 7, "episode": 21, "scene": 28, "line": "[walking into the women's bathroom, Gabe storms in after her] Gabe!", "order": 42750, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 21, "scene": 28, "line": "I need to talk to you!", "order": 42751, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 21, "scene": 28, "line": "You can't be in here. This is a lady's room!", "order": 42752, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 21, "scene": 28, "line": "Erin, I respect your privacy, but I will follow you in here everytime you go if that's what it takes. [Creed walks out of one of the stalls]", "order": 42753, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 21, "scene": 28, "line": "Hey Creed.", "order": 42754, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 21, "scene": 28, "line": "Not cool man. [walks out]", "order": 42755, "speaker": "Creed Bratton"},
  {"season": 7, "episode": 21, "scene": 28, "line": "I really think you should leave.", "order": 42756, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 21, "scene": 28, "line": "Someday, you are going to tell our grandchildren about how their grandfather won you back in a women's room.", "order": 42757, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 21, "scene": 28, "line": "Can we talk about this later, I have to go.", "order": 42758, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 21, "scene": 28, "line": "Just read the letter under your windshield wiper, it explains everything. Quick one. [leans in for a kiss]", "order": 42759, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 21, "scene": 28, "line": "Gabe!", "order": 42760, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 21, "scene": 28, "line": "Okay... [walks out embarrassed]", "order": 42761, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 21, "scene": 29, "line": "[on video cam with his brother] Here's the thing Rory, I knew you guys would hit it off in an odd way.", "order": 42762, "speaker": "Toby Flenderson"},
  {"season": 7, "episode": 21, "scene": 29, "line": "I can bring him a welcome basket. I'll surprise him.", "order": 42763, "speaker": "Rory"},
  {"season": 7, "episode": 21, "scene": 29, "line": "Do- Well you should give him a little time to settle in. But-", "order": 42764, "speaker": "Toby Flenderson"},
  {"season": 7, "episode": 21, "scene": 29, "line": "Does he like jams? My shelves are over flowing with preserves.", "order": 42765, "speaker": "Rory"},
  {"season": 7, "episode": 21, "scene": 29, "line": "Well, no. He hates jams.", "order": 42766, "speaker": "Toby Flenderson"},
  {"season": 7, "episode": 21, "scene": 30, "line": "[walking into Darryl's office] Darryl!", "order": 42767, "speaker": "Michael Scott"},
  {"season": 7, "episode": 21, "scene": 30, "line": "Hey.", "order": 42768, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 21, "scene": 30, "line": "I would like to give you, the only copy, of Somehow I Manage. [hands him a thin black folder] Unfinished. If there's anyone here who can finish it, it's you.", "order": 42769, "speaker": "Michael Scott"},
  {"season": 7, "episode": 21, "scene": 30, "line": "That's sweet Mike. Let's see here. There's a chapter, called Gum. With one sentence. Everybody likes the guy who offers him a stick of gum.", "order": 42770, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 21, "scene": 30, "line": "Mmmhmmm. It's true. Darryl, I have one last wish. I would like to use the bailer.", "order": 42771, "speaker": "Michael Scott"},
  {"season": 7, "episode": 21, "scene": 30, "line": "No. Can't let you do that Mike.", "order": 42772, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 21, "scene": 30, "line": "No problem. Worth a try.", "order": 42773, "speaker": "Michael Scott"},
  {"season": 7, "episode": 21, "scene": 31, "line": "[in warehouse] Darryl said I could use the bailer because I'm leaving.", "order": 42774, "speaker": "Michael Scott"},
  {"season": 7, "episode": 21, "scene": 31, "line": "No.", "order": 42775, "speaker": "Warehouse Guy"},
  {"season": 7, "episode": 21, "scene": 31, "line": "[picks up a basketball and dribbles it away from the basket] Alright guys, well... see ya later warehouse. Catch you on the flippity flip. [throws the ball behind him and misses the basket, tries and misses several more times]", "order": 42776, "speaker": "Michael Scott"},
  {"season": 7, "episode": 21, "scene": 32, "line": "[in car with Andy] okay, so what's our approach? You a veteran? Do I have a month to live? You gonna get married tomorrow? What? [Andy stares blankly] Hmm?", "order": 42777, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 21, "scene": 32, "line": "I thought we'd just talk about our customer service and exceptional paper quality.", "order": 42778, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 21, "scene": 32, "line": "That's stupid. [laughing. Nervously, Andy laughs with him]", "order": 42779, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 21, "scene": 32, "line": "[joking] What do I know?", "order": 42780, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 21, "scene": 32, "line": "[still laughing] What do you know? We gotta get psyched up! Okay? Guy? Let's get psyched! Is there an animal shelter on the way?", "order": 42781, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 21, "scene": 32, "line": "[confused] Yeah.", "order": 42782, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 21, "scene": 32, "line": "Awesome! Ani Ani Shelto! Here we come. Do you know how to high-five?", "order": 42783, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 21, "scene": 32, "line": "Yeah!", "order": 42784, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 21, "scene": 32, "line": "Cause if you do now's the time.", "order": 42785, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 21, "scene": 32, "line": "[going for it] Alright.", "order": 42786, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 21, "scene": 32, "line": "Not while I'm driving.", "order": 42787, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 21, "scene": 33, "line": "[still trying the backwards basket] Catch you guys- [forklift drives in front of the camera] Catch you guys on the filippity flip. [misses and picks it up again] Flippity flip! [misses again and grabs it back] Flippity flip! [makes it in, gets excited] Really? [composes himself] Okay, see ya guys! [walks away, containing his self-pleasure]", "order": 42788, "speaker": "Michael Scott"},
  {"season": 7, "episode": 21, "scene": 34, "line": "[at the animal shelter with Andy] You know how I met Jo Bennett? And got started on my ladder of success?", "order": 42789, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 21, "scene": 34, "line": "No. I don't.", "order": 42790, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 21, "scene": 34, "line": "[hands Andy a dog] I'm walking along, out of work. Again. Thinking to myself, 'I only have enough cash to by a sandwich, or get drunk. And I see this guy trying to steal this lady's dog! So I grab the dog. He runs off. She's so grateful, she hires me.", "order": 42791, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 21, "scene": 34, "line": "Awwhh, wow.", "order": 42792, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 21, "scene": 34, "line": "Gimme that dog! That's not your dog! [takes the dog from a confused Andy] Yeah! Again.", "order": 42793, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 21, "scene": 34, "line": "Oh, okay...", "order": 42794, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 21, "scene": 34, "line": "Gimme that damn dog you f***ing thief! Don't ever do It again! [Andy nods modestly] You hear me?! [Andy nods again] You feel that energy?", "order": 42795, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 21, "scene": 34, "line": "Mmhmmm.", "order": 42796, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 21, "scene": 34, "line": "Wooo! Yeah! [parades in circles with the dog, pretends to whack Andy with it] [giving it to Andy] Okay, again.", "order": 42797, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 21, "scene": 35, "line": "I know that Gabe is young, and hot and everything. And he's begging me to reconsider, but I... I just think I'm in love with someone else.", "order": 42798, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 21, "scene": 35, "line": "Kevin?", "order": 42799, "speaker": "Michael Scott"},
  {"season": 7, "episode": 21, "scene": 35, "line": "Andy.", "order": 42800, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 21, "scene": 35, "line": "Ahhhh...", "order": 42801, "speaker": "Michael Scott"},
  {"season": 7, "episode": 21, "scene": 35, "line": "I wish I knew who my birth mother was, so she could just tell me who to choose.", "order": 42802, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 21, "scene": 35, "line": "Maybe neither.", "order": 42803, "speaker": "Michael Scott"},
  {"season": 7, "episode": 21, "scene": 35, "line": "I'm not attracted to Kevin.", "order": 42804, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 21, "scene": 35, "line": "Erin, listen to me. You shouldn't rush into this. At all. And you know why? Because you are beautiful. And you are fun. And you are smart. [Erin is smiling] And when the right guy comes along, you'll know it. You will. [kisses her head] And you know what? You don't need a mom. Because you have my number, and you can call me anytime.", "order": 42805, "speaker": "Michael Scott"},
  {"season": 7, "episode": 21, "scene": 35, "line": "[nods] Extension 147.", "order": 42806, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 21, "scene": 35, "line": "N-", "order": 42807, "speaker": "Michael Scott"},
  {"season": 7, "episode": 21, "scene": 35, "line": "I know.", "order": 42808, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 21, "scene": 35, "line": "Okay. [both chuckling. Michael leaves Erin, looking thoughtful]", "order": 42809, "speaker": "Michael Scott"},
  {"season": 7, "episode": 21, "scene": 36, "line": "[on the phone] You want the 27-26 or the 27-30?", "order": 42810, "speaker": "Phyllis Vance"},
  {"season": 7, "episode": 21, "scene": 36, "line": "Phyllis. Phyllis, are my mittens done?", "order": 42811, "speaker": "Michael Scott"},
  {"season": 7, "episode": 21, "scene": 36, "line": "No. [trying to finish her sale when Michael hangs up the phone] I'm on a sale!", "order": 42812, "speaker": "Phyllis Vance"},
  {"season": 7, "episode": 21, "scene": 36, "line": "Listen to me. It's two p.m. From now until four, your priority is knitting. Knit like the wind.", "order": 42813, "speaker": "Michael Scott"},
  {"season": 7, "episode": 21, "scene": 36, "line": "Okay... [Jim gives a knowing look to the camera]", "order": 42814, "speaker": "Phyllis Vance"},
  {"season": 7, "episode": 21, "scene": 36, "line": "Dwight, I will be leaving tomorrow. [pulls out an envelope from his jacket]", "order": 42815, "speaker": "Michael Scott"},
  {"season": 7, "episode": 21, "scene": 36, "line": "Yes.", "order": 42816, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 21, "scene": 36, "line": "So I wanted to give you that. [hands him the envelope] It's a letter of recommendation. [Dwight looks eager. Michael nods to him and walks away]", "order": 42817, "speaker": "Michael Scott"},
  {"season": 7, "episode": 21, "scene": 37, "line": "This is gonna be good. [eagerly reading the letter] To whom it may concern. [off to the side] Good, real personal. Thanks Michael. [reading again] The dictionary defines superlative as: of the highest kind, quality, or order, surpassing all else, or others. Supreme. [to the side again] That's great, if I wanted the dictionary definition I'd buy a dictionary. [reading again, slowly gets sadder] I define it as Dwight Schrute. As a sales executive, as a leader, as a man, and as a friend, he is of the highest kind, quality, and order. Supreme. [holding back tears] Lot's more like that, really repetitive. What's this? [pulling out a small card from the envelope. Reads it] Two forty five, behind the building. Paintball. [checks his watch quickly] Ohhh, yeah.", "order": 42818, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 21, "scene": 38, "line": "[opening his trunk excitedly and gets out his paintball gear, takes his gun and looks for Michael, gives up, Michael jumps out of the dumpster shooting, they shoot each other, yell, laugh, and taunt, having a great time]", "order": 42819, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 21, "scene": 39, "line": "[in the office, crosses Dwight off his list. He has paint in his messed up hair, and is containing his laughter]", "order": 42820, "speaker": "Michael Scott"},
  {"season": 7, "episode": 21, "scene": 40, "line": "[giving his and Andy's pitch to a client] I would just like to start off saying, I have not worked with Mr. Andy Bernard for very long, I can say, is that he is no Michael Scott. I can't sit here and tell you he's gonna be a success. I can't sit here and tell you that he's even the best man for the job. [Andy looks awkward] But I can say this: He's got potential. Sure. You know, I always say: go big, or go home. You go with This guy, you could be making the biggest mistake of your life, OR, the biggest, Good decision of your life. It's either gonna be the best thing you ever did, or the worst thing you ever did. If you want some boring, white bread clock-watcher who's gonna get you your paper when you ordered it, for the agreed upon price, [Andy looks hopeful] Andy's not your guy. [Andy deflates] You ever play Russian Roulette? [scoffs] Time to spin the chamber horse. By signing up for another year. [hands the client a folder he needs signed for another year's contract]", "order": 42821, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 21, "scene": 41, "line": "[Michael walks into the office, still a mess] Ahh, what happened to you?", "order": 42822, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 21, "scene": 41, "line": "You should see the other guy.", "order": 42823, "speaker": "Michael Scott"},
  {"season": 7, "episode": 21, "scene": 41, "line": "[smiles knowingly]", "order": 42824, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 21, "scene": 41, "line": "[looks at the clock to see it is three o'clock] Jim where is Pam?", "order": 42825, "speaker": "Michael Scott"},
  {"season": 7, "episode": 21, "scene": 41, "line": "Uhh, she's still pricing the whatevers. The shredders.", "order": 42826, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 21, "scene": 41, "line": "But it is already three o'clock! [Michael leaves, Jim gives a knowing look to the camera]", "order": 42827, "speaker": "Michael Scott"},
  {"season": 7, "episode": 21, "scene": 42, "line": "[walks into a movie theater showing The King's Speech]", "order": 42828, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 21, "scene": 43, "line": "[in his office talking to Creed, Gabe, and Meredith in that order] Whether you're scared of dying, or dying Alone, or dying drunk in a ditch. Don't be. It's going to be okay.", "order": 42829, "speaker": "Michael Scott"},
  {"season": 7, "episode": 21, "scene": 44, "line": "Yeah I was tripling up. There's not enough time in the day to have a special moment with everybody.", "order": 42830, "speaker": "Michael Scott"},
  {"season": 7, "episode": 21, "scene": 45, "line": "[Gabe is still standing in front of Michael's desk] And you, why are you still here.", "order": 42831, "speaker": "Michael Scott"},
  {"season": 7, "episode": 21, "scene": 45, "line": "[sounding hurt] I'm either going to quit today, or stay to make sure that Andy's career is destroyed.", "order": 42832, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 21, "scene": 45, "line": "No, you are not going to quit today. For goodness sake, this is not going to be your last day in the office. Everybody gets dumped Gabe. Can I give you a piece of advice? [Gabe nods] A little cover-up on your Adam's apple will make it appear smaller, and make you look less like a transvestite. [Gabe looks awkward and Michael winks]", "order": 42833, "speaker": "Michael Scott"},
  {"season": 7, "episode": 21, "scene": 46, "line": "[walking out the client's room with Andy] That is cold sir! Absolutely cold. You know what? It was a complete waste of my time.", "order": 42834, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 21, "scene": 46, "line": "Uh DeAngelo, I'm, uh. I forgot my bag, so I'll meet you in the car.", "order": 42835, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 21, "scene": 46, "line": "[grumbles] Okay whatever.", "order": 42836, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 21, "scene": 46, "line": "[walks into the client's office again] Sir, I'd just like to apologize, for that. I could tell you that he has a steel plate in his head, or that he's crazy, but the truth is I think he's just a terrible salesman. And I want you to know, that if you re-up with us, anything you need, day or night, I will be the one to take your call.", "order": 42837, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 21, "scene": 46, "line": "Keep talking.", "order": 42838, "speaker": "Client"},
  {"season": 7, "episode": 21, "scene": 47, "line": "[on his phone waiting for Andy, next to their car] Yes. Okay, alright. No, well thank you! Alright, take care. [hangs up] You'll never guess, we did it!", "order": 42839, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 21, "scene": 47, "line": "[laughing] Hey! [they hug] Good job boss!", "order": 42840, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 21, "scene": 47, "line": "We did it!", "order": 42841, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 21, "scene": 48, "line": "[standing in front of his office, sees that it is three forty five] Okay, everybody come on. Conference room five seconds! Let's go! Hurry up, let's do this! [Dwight runs into the conference room. All others follow]", "order": 42842, "speaker": "Michael Scott"},
  {"season": 7, "episode": 21, "scene": 49, "line": "Yes, what is this about?", "order": 42843, "speaker": "Stanley Hudson"},
  {"season": 7, "episode": 21, "scene": 49, "line": "What is this meeting about?", "order": 42844, "speaker": "Michael Scott"},
  {"season": 7, "episode": 21, "scene": 49, "line": "Mmhmmm.", "order": 42845, "speaker": "Stanley Hudson"},
  {"season": 7, "episode": 21, "scene": 49, "line": "Okay, here we are in the conference room. Once again. And I just wanted to call you all here, together, because I have something important. Well there's two things actually. Okay first, I would like a whereabouts on Pam. [Jim looks regretful] And secondly, Phyllis how are those mittens coming? Because I would actually like to bring them home and pack them. I'm leaving for the day at four.", "order": 42846, "speaker": "Michael Scott"},
  {"season": 7, "episode": 21, "scene": 49, "line": "They're almost done but my knuckles are swelling a little and-", "order": 42847, "speaker": "Phyllis Vance"},
  {"season": 7, "episode": 21, "scene": 49, "line": "[cutting her off] Well power through the arthritis Phyllis you can do it!", "order": 42848, "speaker": "Michael Scott"},
  {"season": 7, "episode": 21, "scene": 49, "line": "Is that it?", "order": 42849, "speaker": "Stanley Hudson"},
  {"season": 7, "episode": 21, "scene": 49, "line": "Umm, hmm?", "order": 42850, "speaker": "Michael Scott"},
  {"season": 7, "episode": 21, "scene": 49, "line": "Is that it?", "order": 42851, "speaker": "Stanley Hudson"},
  {"season": 7, "episode": 21, "scene": 49, "line": "[the office looks up expectantly] Ummm... Hm... No. [changing his mind] No. There's a special guest that I would like to invite to say one last goodbye. So here he comes, he's coming right in. [walks out and then back in, now using a Vietnamese accent] Oh hi everybody, it's Ping! [the office groans] And I'm here to say goodbye to all you wonderful people! Thank you everybody! You've been so wonderful! [only Kevin is laughing. Stanley tries to leave. Michael hugs him] I ruv you all! I ruv you very much!", "order": 42852, "speaker": "Michael Scott"},
  {"season": 7, "episode": 21, "scene": 49, "line": "Hey, can I talk to you for a second?", "order": 42853, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 21, "scene": 49, "line": "Sure. [Ping accent] Be right out!", "order": 42854, "speaker": "Michael Scott"},
  {"season": 7, "episode": 21, "scene": 49, "line": "[brings Michael into his office] So I've been meaning to tell ya, I wanna take you out for lunch. For your last day.", "order": 42855, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 21, "scene": 49, "line": "[sad] Ohh...", "order": 42856, "speaker": "Michael Scott"},
  {"season": 7, "episode": 21, "scene": 49, "line": "What do you think? Tomorrow? Lunch, you and me?", "order": 42857, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 21, "scene": 49, "line": "[holding back tears] Okay...", "order": 42858, "speaker": "Michael Scott"},
  {"season": 7, "episode": 21, "scene": 49, "line": "You're not leaving tomorrow. You're leaving today right?", "order": 42859, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 21, "scene": 49, "line": "Maybe.", "order": 42860, "speaker": "Michael Scott"},
  {"season": 7, "episode": 21, "scene": 49, "line": "Wow, so that's it huh? Just, four o'clock and you are gone for good.", "order": 42861, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 21, "scene": 49, "line": "Why am I so sad? Am I doing the wrong thing?", "order": 42862, "speaker": "Michael Scott"},
  {"season": 7, "episode": 21, "scene": 49, "line": "Absolutely not. It's just that sometimes... goodbyes are a bitch.", "order": 42863, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 21, "scene": 49, "line": "[pulls out a recorder and speaks softly into it] T-Shirt idea, goodbyes stink. Okay, alright. So. James Halpert. [starts to cry] You started with this company, as a fine young man...", "order": 42864, "speaker": "Michael Scott"},
  {"season": 7, "episode": 21, "scene": 49, "line": "You know what I think we should do? I think we should just save the goodbyes for tomorrow. At lunch.", "order": 42865, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 21, "scene": 49, "line": "Oh, okay.", "order": 42866, "speaker": "Michael Scott"},
  {"season": 7, "episode": 21, "scene": 49, "line": "And then tomorrow, I can tell you... what a great boss, you turned out to be. The best boss I ever had. [Jim is holding back tears too]", "order": 42867, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 21, "scene": 49, "line": "[hears a taxi honking] Oh shoot! That's my cab.", "order": 42868, "speaker": "Michael Scott"},
  {"season": 7, "episode": 21, "scene": 49, "line": "Alright.", "order": 42869, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 21, "scene": 49, "line": "Alright.", "order": 42870, "speaker": "Michael Scott"},
  {"season": 7, "episode": 21, "scene": 49, "line": "Listen Michael, I really... I did text Pam, but...", "order": 42871, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 21, "scene": 49, "line": "I know, it's okay. Just, give her a hug. Alright?", "order": 42872, "speaker": "Michael Scott"},
  {"season": 7, "episode": 21, "scene": 49, "line": "[shakes Michael's hand] I will see you, tomorrow at lunch.", "order": 42873, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 21, "scene": 49, "line": "I am looking forward to lunch. And hearing about what a great boss I am.", "order": 42874, "speaker": "Michael Scott"},
  {"season": 7, "episode": 21, "scene": 49, "line": "[chuckles] You got it.", "order": 42875, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 21, "scene": 49, "line": "Okay... [crosses Jim off his list] Phyllis.", "order": 42876, "speaker": "Michael Scott"},
  {"season": 7, "episode": 21, "scene": 49, "line": "Oh no, they're still not done.", "order": 42877, "speaker": "Phyllis Vance"},
  {"season": 7, "episode": 21, "scene": 49, "line": "No no no, let me see. [picks up the mostly knitted mittens] Oh Phyllis, nice try. I love 'em. [waves goodbye to her with the mittens] [Michael begins walking out the office, he takes one last look at all his friends to see them working, and Jim looking back, teary eyed]", "order": 42878, "speaker": "Michael Scott"},
  {"season": 7, "episode": 21, "scene": 49, "line": "[drinking from Michael's World's Best Boss mug] See ya tomorrow boss!", "order": 42879, "speaker": "Creed Bratton"},
  {"season": 7, "episode": 21, "scene": 49, "line": "Later guys. [leaves the office]", "order": 42880, "speaker": "Michael Scott"},
  {"season": 7, "episode": 21, "scene": 50, "line": "Got almost everybody. So... Holly's my family now. [you see video of Michael getting into the cab, and driving off. As the cab leaves, Pam drives in] She's my family. The babies that I make with her, will be my children. The people that you work with, are just... when you get down to it... your very best friends. They say, on your deathbed, you never wish you spent more time at the office. But I will. Gotta be a lot better than a deathbed. [You see Michael getting out and entering the airport, and going through security] I actually don't understand deathbeds. I mean, who would buy that?", "order": 42881, "speaker": "Michael Scott"},
  {"season": 7, "episode": 21, "scene": 51, "line": "[putting his shoes back on, talking to the camera crew] Well, I guess this is it. Hey will you guys let me know if this ever airs? Thank you. Alright... Oh! [pulls out his mic from his shirt] This is gonna feel so good, getting this thing off my chest. [he hands them the body mic, when he speaks it is inaudible now] That's what she said! [waves goodbye and walks off to his gate, halfway there Pam comes running up to him and they hug for a while. They say their goodbyes to each other, and Michael walks off for good]", "order": 42882, "speaker": "Michael Scott"},
  {"season": 7, "episode": 21, "scene": 52, "line": "[watching Michael's plane take off. Crying and has smeared make up on] No he wasn't sad. He was full of hope. About Colorado, and he was hoping to get an upgrade as an award's member. And he said he was just real excited to get home and see Holly.", "order": 42883, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 21, "scene": 53, "line": "[all are in the conference room for Michael's party. Michael isn't there. Jim gives the camera a knowing look] Well if he's not gonna make it at least we should go ahead and eat the cake. [Kevin nods] I for one love the corners. [cuts a corner piece, picks it up with his bare hands, and takes a bite] Why'd I just do that? It's not even that good. I don't even want it. I had cake for lunch. [throws the piece in the garbage] No, you know what? I've been good. I deserve this. [grabs a piece from another corner. The office groans] What am I doing? [chucks it into the trashcan] Come on DeAngelo! [DeAngelo tries to lick the cake, everybody yells no]", "order": 42884, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 21, "scene": 53, "line": "[to Jim] Uh oh... [Jim, tearful, nods]", "order": 42885, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 22, "scene": 1, "line": "So, coasting time is officially over. Big changes are comin', and they're comin' fast. If you don't like 'em, this is called a door. You can walk right through it. Alright. I'm not here to be your friend. I like my life outside of this place. I live to leave at 5. Change number one: Darryl. Per your request, the company is sending you to business school at night. Full ride. Deal with it.", "order": 42886, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 22, "scene": 1, "line": "Seriously?", "order": 42887, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 22, "scene": 1, "line": "Stone cold seriously.", "order": 42888, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 22, "scene": 2, "line": "They are trying to figure me out. And I don't like it. Once they figure me out, they start to tell me what I want to hear. And I need to quickly figure out who's a good worker and who is simply a good mind reader. Because as soon as I'm hearing what I want to hear, I'm not gonna care.", "order": 42889, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 22, "scene": 3, "line": "Change two, Toby, you're gettin' a new chair.", "order": 42890, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 22, "scene": 3, "line": "Thanks.", "order": 42891, "speaker": "Toby Flenderson"},
  {"season": 7, "episode": 22, "scene": 3, "line": "Don't thank me! Hey, don't thank me, guy! Okay? And I don't care if you like it.", "order": 42892, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 22, "scene": 3, "line": "These sound like good ideas, why wouldn't we like them?", "order": 42893, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 22, "scene": 3, "line": "I don't care what your favorite flavor is. Here's a bowl of ice cream. You either like it or you don't. [Andy nods] That's my attitude right now in this room, that's my attitude on Ice Cream Thursdays. Alright? Clear? Any questions?", "order": 42894, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 22, "scene": 3, "line": "This all sounds great to me. [hesitates] But I could see how some people might think that they're bad. I don't know what to think.", "order": 42895, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 22, "scene": 3, "line": "That is a s-- astute observation, Kevin.", "order": 42896, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 22, "scene": 4, "line": "Kev's got me pegged. [chuckles]", "order": 42897, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 22, "scene": 5, "line": "It blows away Vermont in the fall.", "order": 42898, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 22, "scene": 5, "line": "Snap, for real?", "order": 42899, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 22, "scene": 5, "line": "Good morning, Deangelo.", "order": 42900, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 22, "scene": 5, "line": "Hold on.", "order": 42901, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 22, "scene": 5, "line": "Okay.", "order": 42902, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 22, "scene": 5, "line": "[continuing to talk to Darryl] And if you're really serious, you should go in the spring.", "order": 42903, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 22, "scene": 5, "line": "Because of the flowers.", "order": 42904, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 22, "scene": 5, "line": "No. Because the entire state smells like Earth.", "order": 42905, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 22, "scene": 5, "line": "Dogwoods, or just the Earth.", "order": 42906, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 22, "scene": 5, "line": "Yes, Pam.", "order": 42907, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 22, "scene": 5, "line": "Hey, well, good morning. I think I have good news for you today. I found your new executive assistant. My friend Carla. [looking at resume] She's got great experience. We even considered making her Cece's godmother, but she had this boyfriend at the time-- but here's her resume.", "order": 42908, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 22, "scene": 5, "line": "Put it with the rest.", "order": 42909, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 22, "scene": 5, "line": "Okay.", "order": 42910, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 22, "scene": 6, "line": "[entering his office] Hey dudes!", "order": 42911, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 22, "scene": 6, "line": "Hey!", "order": 42912, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 22, "scene": 6, "line": "Hey!", "order": 42913, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 22, "scene": 6, "line": "Oh my God, he hates me!", "order": 42914, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 22, "scene": 6, "line": "No he doesn't. You just get so nervous and hyper around him.", "order": 42915, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 22, "scene": 6, "line": "I know.", "order": 42916, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 22, "scene": 6, "line": "Every time there's a silence, your brain's like 'Heh-heh-huh-huh' [imitating crying].", "order": 42917, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 22, "scene": 6, "line": "Is that what he tells you at your little inner circle meetings?", "order": 42918, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 22, "scene": 6, "line": "Careful. There is no inner circle.", "order": 42919, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 22, "scene": 7, "line": "Oh, there's an inner circle. Oh yeah.", "order": 42920, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 22, "scene": 8, "line": "There is no inner circle. Deangelo just prefers to delegate a few things, to a few guys.", "order": 42921, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 22, "scene": 9, "line": "Jim only says that because he's in the inner circle. I also say that because I am also in the inner circle. Did you get that, Ma? Your boy, Kevin Malone, is IN the inner circle! [thinks] Which doesn't exist.", "order": 42922, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 22, "scene": 10, "line": "[holding two cups of coffee] There he is! Got ya coffee. [offers cup to Dwight]", "order": 42923, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 22, "scene": 10, "line": "Oh, wow, thank you. That was so kind of you. [throws cup in the garbage]", "order": 42924, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 22, "scene": 10, "line": "Not a coffee guy, I take it.", "order": 42925, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 22, "scene": 10, "line": "It's just that I own the coffee shop. So, once you've seen sausage being made, all you want to do is make sausage, because it's so much fun.", "order": 42926, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 22, "scene": 10, "line": "Listen. I've got a sixer. 'Automatic for the People' on the jukebox. Let's hit the park after sundown. Come on! Pick up some sausage if you want.", "order": 42927, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 22, "scene": 10, "line": "I think you'll find what you're looking for [points toward Oscar] over there.", "order": 42928, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 22, "scene": 11, "line": "No matter how many times I reach out to Dwight, he doesn't seem to want anything to do with me. It reminds me of my relationship with my son. Except there, I'm the Dwight.", "order": 42929, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 22, "scene": 12, "line": "Who's the biggest client in the state? I say we go get 'em, whatever it takes. Huh? What do you guys say?", "order": 42930, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 22, "scene": 12, "line": "Yes!", "order": 42931, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 22, "scene": 12, "line": "Right! Or my other idea, 50,000 tiny clients.", "order": 42932, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 22, "scene": 12, "line": "Yeah, I say we just go for it!", "order": 42933, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 22, "scene": 12, "line": "[shoots mini basketball at hoop on office door, misses badly] Is that ball lighter than usual? Is that a Chinatown knockoff?", "order": 42934, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 22, "scene": 12, "line": "That's Toys R Us, I think.", "order": 42935, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 22, "scene": 12, "line": "No, that's definitely a knockoff. You can feel the center of gravity's off. Feel that. [throws ball to Gabe] What do you think?", "order": 42936, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 22, "scene": 12, "line": "[holding ball in open palm] Oh, yeah, totally. Jim, you got ripped off big time.", "order": 42937, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 22, "scene": 12, "line": "[calling for the ball] Deangelo! [shoots and makes it] See what I did there? That's what you need to do. [makes shooting gesture] It's that little English. The British are coming.", "order": 42938, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 22, "scene": 12, "line": "Yes.", "order": 42939, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 22, "scene": 12, "line": "Deangelo's open!", "order": 42940, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 22, "scene": 13, "line": "Hey Ry. Your department's killing it, baby. [puts arm around Ryan]", "order": 42941, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 22, "scene": 13, "line": "Hey hey, my pleasure, my treasure.", "order": 42942, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 22, "scene": 13, "line": "Keep it up.", "order": 42943, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 22, "scene": 14, "line": "The problem with having 'It' or 'the X-factor' or whatever it is you want to call it, is that it's impossible to put into words what you're bringing to the table. So to make things simpler for Deangelo, I just, without lying, strongly implied that I'm Kelly's supervisor. It's not even that much of a stretch. She pretty much does whatever I say.", "order": 42944, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 22, "scene": 15, "line": "What?", "order": 42945, "speaker": "Kelly Kapoor"},
  {"season": 7, "episode": 22, "scene": 15, "line": "I'm sorry, I'm sorry, I'm sorry...", "order": 42946, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 22, "scene": 15, "line": "Why should I pretend that you are my boss?", "order": 42947, "speaker": "Kelly Kapoor"},
  {"season": 7, "episode": 22, "scene": 15, "line": "Because what would you have done in that situation, Kelly? I'll tell you what I would have done for you. I'd lie for you.", "order": 42948, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 22, "scene": 15, "line": "Yeah, but you lie all the time. You lie for no reason. Ryan, you just like to lie.", "order": 42949, "speaker": "Kelly Kapoor"},
  {"season": 7, "episode": 22, "scene": 15, "line": "I'd die for you too.", "order": 42950, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 22, "scene": 15, "line": "You really would?", "order": 42951, "speaker": "Kelly Kapoor"},
  {"season": 7, "episode": 22, "scene": 15, "line": "[hears Deangelo entering] Hey, Kelly Kapoor, if I don't have those call logs on my desk, we're just gonna have to evaluate your future at the company!", "order": 42952, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 22, "scene": 15, "line": "Sure thing, Mr. Howard.", "order": 42953, "speaker": "Kelly Kapoor"},
  {"season": 7, "episode": 22, "scene": 15, "line": "Woo. Glad he's not my boss.", "order": 42954, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 22, "scene": 15, "line": "[whispers to Kelly] You're the best. Thank you.", "order": 42955, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 22, "scene": 16, "line": "You know I have a cousin who cracked the secret formula for a certain popular cola that I shall not name. So I've never had to buy it. True story. I just drink my cousin's.", "order": 42956, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 22, "scene": 16, "line": "Congratulations on your one cousin. I have seventy. Each one better than the last.", "order": 42957, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 22, "scene": 16, "line": "You know what... straight up, why don't you like me?", "order": 42958, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 22, "scene": 16, "line": "I'm just not a suck-up like everyone else around here. Okay? I do my job well, so why don't you just leave me alone and let me do it. Okay?", "order": 42959, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 22, "scene": 16, "line": "Ohhhh no.", "order": 42960, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 22, "scene": 16, "line": "What?", "order": 42961, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 22, "scene": 16, "line": "Okay? I'm gonna win you over.", "order": 42962, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 22, "scene": 16, "line": "No you're not.", "order": 42963, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 22, "scene": 16, "line": "Yes I am.", "order": 42964, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 22, "scene": 16, "line": "No.", "order": 42965, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 22, "scene": 16, "line": "Oh yes.", "order": 42966, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 22, "scene": 16, "line": "No.", "order": 42967, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 22, "scene": 16, "line": "Yes.", "order": 42968, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 22, "scene": 16, "line": "No.", "order": 42969, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 22, "scene": 16, "line": "Yes.", "order": 42970, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 22, "scene": 16, "line": "No.", "order": 42971, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 22, "scene": 16, "line": "Yes.", "order": 42972, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 22, "scene": 16, "line": "No.", "order": 42973, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 22, "scene": 16, "line": "Yes.", "order": 42974, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 22, "scene": 16, "line": "No.", "order": 42975, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 22, "scene": 16, "line": "Yes. Yes-yes-yes-yes-yes [starts to run out of the break room] yes-yes-yes-yes-yes-yes-yes-yes-yes-yes-yes-yes!", "order": 42976, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 22, "scene": 17, "line": "[looking at resume] Uh oh, this former administrative assistant misspelled 'administrative' and 'assistant.'", "order": 42977, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 22, "scene": 17, "line": "The winner: under 'Special Skills,' Mr. Don Feiner put 'Juggling.' [laughter]", "order": 42978, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 22, "scene": 17, "line": "What's wrong with juggling, Darryl? I'm a big juggler. I actually perform a motivational juggling routine.", "order": 42979, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 22, "scene": 17, "line": "Seriously?", "order": 42980, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 22, "scene": 17, "line": "Oh yeah. I'd do it for you here, but uh, what would you say this room is, 300 square feet? 320?", "order": 42981, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 22, "scene": 17, "line": "320. Just freeballin' it.", "order": 42982, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 22, "scene": 17, "line": "It's a little cramped. How many square feet out there? 17, 18 hundo?", "order": 42983, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 22, "scene": 17, "line": "Oh, I think it's 18 hundo.", "order": 42984, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 22, "scene": 17, "line": "Give or take. [Deangelo walks out]", "order": 42985, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 22, "scene": 18, "line": "Sorry gang. Thought my juggling stuff was in the trunk of my car. It's not.", "order": 42986, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 22, "scene": 18, "line": "Oh no, do you think it was stolen?", "order": 42987, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 22, "scene": 18, "line": "I gotcha covered, boss. Used to play with the parabolas myself. [opens desk drawer and takes out balls] Got some extra balls! [throws them toward Deangelo, who dodges them] Hey-hey-hey-ho!", "order": 42988, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 22, "scene": 18, "line": "Sorry, I never touch another juggler's instruments. You know, we're all here, I've got the music cued, why don't I just do my routine without the juggling balls? [starts to play 'Wake Me Up Inside' by Evanescence] Prepare... to go into the danger zone. [begins pretend juggling routine]", "order": 42989, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 22, "scene": 18, "line": "Oh wow, you weren't kidding.", "order": 42990, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 22, "scene": 18, "line": "No. Never. Can someone please throw me a fifth ball? If you dare! [Kevin pretends to throw ball] Incoming! And we're on! Remember, nothing's impossible! Phyllis, where's Phyllis?", "order": 42991, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 22, "scene": 18, "line": "[raises hand] Here.", "order": 42992, "speaker": "Phyllis Vance"},
  {"season": 7, "episode": 22, "scene": 18, "line": "Do you believe in me, Phyllis?", "order": 42993, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 22, "scene": 18, "line": "Yeah.", "order": 42994, "speaker": "Phyllis Vance"},
  {"season": 7, "episode": 22, "scene": 18, "line": "Because I believe in you.", "order": 42995, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 22, "scene": 18, "line": "Okay.", "order": 42996, "speaker": "Phyllis Vance"},
  {"season": 7, "episode": 22, "scene": 18, "line": "[pretending to bounce balls off Phyllis at close range] Feel that connection? Don't move your head. Please. Thank you. Oh! Big hand for Phyllis! [clapping] That took a lot of guts! [finishes routine] Ho! I'm Deangelo Vickers, thank you so much, hope you learned something.", "order": 42997, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 22, "scene": 18, "line": "Didn't drop a single ball!", "order": 42998, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 22, "scene": 19, "line": "[imitating Deangelo's pretend juggling routine] Look. I'm juggling eggs and bowling balls. I'm juggling with one hand. No hands.", "order": 42999, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 22, "scene": 19, "line": "What could he possibly stand to gain from a fake juggling routine?", "order": 43000, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 22, "scene": 19, "line": "What could he possibly stand to gain from a real juggling routine? How can you keep defending him?", "order": 43001, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 22, "scene": 19, "line": "He's good at his job. And I like working for him.", "order": 43002, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 22, "scene": 19, "line": "Of course you do, Jim. You're a man. Deangelo is a huge sexist.", "order": 43003, "speaker": "Angela Martin"},
  {"season": 7, "episode": 22, "scene": 19, "line": "Whoa, whoa, whoa. I think if he was sexist, I'd be able to tell. I took a crapload of women's studies courses at Cornell. And I wrote my own companion piece to the 'Vagina Monologues' called the 'Penis Apologies.' So I know a thing or two.", "order": 43004, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 22, "scene": 19, "line": "Okay. Then how about I'm the head of the Accounting Department, but he only ever talks to Kevin? What about Pam and Kelly? Also department heads. But has he ever met with you or even asked you to do anything?", "order": 43005, "speaker": "Angela Martin"},
  {"season": 7, "episode": 22, "scene": 19, "line": "How could I not see it? You're so right.", "order": 43006, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 22, "scene": 19, "line": "[to Jim] Why don't you talk to him about it?", "order": 43007, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 22, "scene": 19, "line": "And say what? 'Hey Deangelo, are you shy or just a sexist?'", "order": 43008, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 22, "scene": 19, "line": "Why don't you just tell him how his actions are being perceived by the women in this office?", "order": 43009, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 22, "scene": 19, "line": "Mmm-hmm.", "order": 43010, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 22, "scene": 19, "line": "And if he doesn't listen, then he can kiss his penis goodbye. Snip snip. Am I right, girls?", "order": 43011, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 22, "scene": 20, "line": "Hey, you got a second?", "order": 43012, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 22, "scene": 20, "line": "Yeah, I got tons of time. This job's a joke.", "order": 43013, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 22, "scene": 21, "line": "So what's up?", "order": 43014, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 22, "scene": 21, "line": "Umm, really, it's nothing, I was just talking to Angela, and she was --", "order": 43015, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 22, "scene": 21, "line": "[opening door to Deangelo's office] Hey, saw Jim come in. We meeting?", "order": 43016, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 22, "scene": 21, "line": "Yeah sure. Let's make it a meeting. [Darryl, Kevin, Gabe enter office]", "order": 43017, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 22, "scene": 21, "line": "If it's alright, can I just have like one minute alone, just to go over --", "order": 43018, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 22, "scene": 21, "line": "What's the big secret? Why are you even whispering? Come on, it's the guys!", "order": 43019, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 22, "scene": 21, "line": "I know. [Gabe and Darryl stand on either side of Jim with paper and pens in hand] Just the guys. Well, maybe that's, uh, part of the problem. I think...so what happened was, I was talking with some of the department heads. Uh, some of the female department heads.", "order": 43020, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 22, "scene": 21, "line": "Uh-oh. Right?", "order": 43021, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 22, "scene": 21, "line": "Hot!", "order": 43022, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 22, "scene": 21, "line": "Maybe there's a vibe out there, with certain members of the office, that you are...a little sexist, or --", "order": 43023, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 22, "scene": 21, "line": "Damn!", "order": 43024, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 22, "scene": 21, "line": "Whoa. Whoa. Wait.", "order": 43025, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 22, "scene": 21, "line": "Are you serious? Who feels this way?", "order": 43026, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 22, "scene": 21, "line": "Oh. Like nobody.", "order": 43027, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 22, "scene": 21, "line": "Umm, Pam?", "order": 43028, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 22, "scene": 21, "line": "It was --", "order": 43029, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 22, "scene": 21, "line": "Was it Pam?", "order": 43030, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 22, "scene": 21, "line": "That sounds like Pam. You know how she gets. Deangelo, she can get really bitchy. [begins bad imitation of Pam] Kevin...", "order": 43031, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 22, "scene": 21, "line": "Guys. Hold on. Doesn't matter who, okay? I'm just happy that Jim brought it to my attention because honestly, I had - I had no idea.", "order": 43032, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 22, "scene": 21, "line": "That's awesome.", "order": 43033, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 22, "scene": 21, "line": "Thank you.", "order": 43034, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 22, "scene": 22, "line": "[on phone] Mom, Ryan's taking us out to dinner tonight. No, no, he's not going to stand us up like he did last time. He won't ever stand us up again.", "order": 43035, "speaker": "Kelly Kapoor"},
  {"season": 7, "episode": 22, "scene": 23, "line": "[joint talking head with Kelly] So I am the new customer service supervisor.", "order": 43036, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 22, "scene": 23, "line": "When Deangelo's around.", "order": 43037, "speaker": "Kelly Kapoor"},
  {"season": 7, "episode": 22, "scene": 23, "line": "And I am also a very dutiful boyfriend when --", "order": 43038, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 22, "scene": 23, "line": "All the time.", "order": 43039, "speaker": "Kelly Kapoor"},
  {"season": 7, "episode": 22, "scene": 23, "line": "All the time.", "order": 43040, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 22, "scene": 24, "line": "Erin, do you mind running down to the lobby and bringing up my brand new executive assistant?", "order": 43041, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 22, "scene": 24, "line": "Absolutely.", "order": 43042, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 22, "scene": 24, "line": "Hey, who'd you end up hiring?", "order": 43043, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 22, "scene": 24, "line": "Oh, I'm glad you asked, Jim. Because apparently there's a rumor running around here that I am a sexist. I can't work here effectively if you guys think I'm something that I am not. I am not a sexist. Raise your hand if you have a vagina. [Deangelo raises hand, Kelly and Pam sheepishly raise hands] Raise your hand if someone you love has a vagina. [Deangelo raises other hand, along with rest of office] Yeah, yeah. Okay. Just about everyone. What about Deangelo's hand? Oh wow. He's got 'em both up. [Ryan raises both hands] Yeah. Uh huh. [others raise both hands] Yeah, so it bothers me when I hear that there's gossip around here that I treat women lesser than men. Okay? Frankly, we all look a little ridiculous when that happens.", "order": 43044, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 22, "scene": 24, "line": "I'm not a feminist, but I think that the men in this office are being given chances that the women aren't.", "order": 43045, "speaker": "Phyllis Vance"},
  {"season": 7, "episode": 22, "scene": 24, "line": "Dwight, what's your take?", "order": 43046, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 22, "scene": 24, "line": "What's the argument here? NBA, WNBA. One is a sport, one is a joke. I love sports, I love jokes. Room for all.", "order": 43047, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 22, "scene": 24, "line": "Man, you're smart. [Erin arrives with new executive assistant] Oh, hey! Hi! Hello. Welcome. Uh, everyone, I'd like you to please welcome Jordan Garfield. This is everyone.", "order": 43048, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 22, "scene": 24, "line": "Hello.", "order": 43049, "speaker": "Jordan"},
  {"season": 7, "episode": 22, "scene": 24, "line": "So, Jordan, uh, where did you work before? Uh, a law office?", "order": 43050, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 22, "scene": 24, "line": "No, Anthropology. We don't have this in that size, pretty lame.", "order": 43051, "speaker": "Jordan"},
  {"season": 7, "episode": 22, "scene": 24, "line": "Lame? You worked at Anthropology?", "order": 43052, "speaker": "Kelly Kapoor"},
  {"season": 7, "episode": 22, "scene": 24, "line": "Yeah.", "order": 43053, "speaker": "Jordan"},
  {"season": 7, "episode": 22, "scene": 24, "line": "That's like my dream job. How did you even get that job?", "order": 43054, "speaker": "Kelly Kapoor"},
  {"season": 7, "episode": 22, "scene": 24, "line": "Well, I umm -", "order": 43055, "speaker": "Jordan"},
  {"season": 7, "episode": 22, "scene": 24, "line": "You chose this job over that job?", "order": 43056, "speaker": "Kelly Kapoor"},
  {"season": 7, "episode": 22, "scene": 24, "line": "Okay, okay, back to work, Kelly, we have a lot to get done today.", "order": 43057, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 22, "scene": 24, "line": "So, umm, is this your first office job then?", "order": 43058, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 22, "scene": 24, "line": "Yeah.", "order": 43059, "speaker": "Jordan"},
  {"season": 7, "episode": 22, "scene": 24, "line": "Yup.", "order": 43060, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 22, "scene": 24, "line": "Wow.", "order": 43061, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 22, "scene": 24, "line": "No corporate experience whatsoever. I didn't want anyone with any bad habits.", "order": 43062, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 22, "scene": 25, "line": "[heading into Deangelo's office] Jim, you coming?", "order": 43063, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 22, "scene": 25, "line": "Oh yeah, did he text us?", "order": 43064, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 22, "scene": 25, "line": "Yeah. [Jim looks at cell phone as Dwight's phone vibrates]", "order": 43065, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 22, "scene": 25, "line": "[holds up phone and looks into Deangelo's office] No!", "order": 43066, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 22, "scene": 25, "line": "Jim, what are you doing? Get in there. This is not the time to take a stand. At least he likes one of us.", "order": 43067, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 22, "scene": 25, "line": "He didn't text me. [Andy's phone chimes]", "order": 43068, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 22, "scene": 25, "line": "Yes! I'm in.", "order": 43069, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 22, "scene": 25, "line": "Andy, what are you doing?", "order": 43070, "speaker": "Angela Martin"},
  {"season": 7, "episode": 22, "scene": 25, "line": "I'm going in, into the belly of the beast. Gonna infiltrate and change from within. [joins the group in Deangelo's office] What's up, mancave! [makes barking noises]", "order": 43071, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 22, "scene": 25, "line": "[to Jim] Just go in. Just go in, he probably forgot to text you.", "order": 43072, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 22, "scene": 26, "line": "Internally, for office use [Jim quietly enters Deangelo's office and sits down] where do we get our paper from? Do we go -", "order": 43073, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 22, "scene": 26, "line": "[to Jordan] Don't worry, the first day's always the hardest.", "order": 43074, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 22, "scene": 26, "line": "[staring at Jim] Hey Jim.", "order": 43075, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 22, "scene": 26, "line": "Yeah.", "order": 43076, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 22, "scene": 26, "line": "Can I help you?", "order": 43077, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 22, "scene": 26, "line": "Nope. Just... [Deangelo continues staring at him] Okay. [gets up and leaves office]", "order": 43078, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 22, "scene": 27, "line": "So, he kicked you out of the inner circle, huh?", "order": 43079, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 22, "scene": 27, "line": "Well, there is no inner...circle.", "order": 43080, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 22, "scene": 28, "line": "Dwight? Deangelo wanted me to ask you if there's anything I can help you with.", "order": 43081, "speaker": "Jordan"},
  {"season": 7, "episode": 22, "scene": 28, "line": "Really? Anything.", "order": 43082, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 22, "scene": 28, "line": "Do you need anything?", "order": 43083, "speaker": "Jordan"},
  {"season": 7, "episode": 22, "scene": 28, "line": "Deangelo, tell your whore to leave me alone.", "order": 43084, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 22, "scene": 29, "line": "Okay, I do not want to waste your time, so I will keep this br-r-r-r-r-r-ief. Now, word on the street is, Mercy Hospital, back on the market. Deangelo would like you to put together a sales pitch for next week. Deangelo has also recently learned about the Barnacle Project. Which is a non-profit organization based in Mystic, Connecticut that assists in the scraping of barnacles --", "order": 43085, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 22, "scene": 30, "line": "So this is my life. Until I win the lottery [laughs]. Or Pam finally writes that series of young adult books.", "order": 43086, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 22, "scene": 31, "line": "So one afternoon, while walking home from school, quirky 10th grader Becky Walters finds a wounded Pegasus in the woods. And she becomes...The Horse Flyer.", "order": 43087, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 22, "scene": 32, "line": "Hey! Kelly, that's the last time I'm gonna talk to you about your paycheck! Okay? We pay you a fair salary here, and if you're only here for the money, maybe you shouldn't be here at all.", "order": 43088, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 22, "scene": 32, "line": "No one likes a money grubber.", "order": 43089, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 22, "scene": 32, "line": "I'm sorry, Mr. Howard, I apologize for grubbing for money. I can't do this. I can't do this. I'm sorry. I just can't do it. Deangelo, Ryan is not my boss. Okay? Frankly, he hasn't had a real job here in years.", "order": 43090, "speaker": "Kelly Kapoor"},
  {"season": 7, "episode": 22, "scene": 32, "line": "Ohh.", "order": 43091, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 22, "scene": 32, "line": "Oh, that's hilarious, Kelly.", "order": 43092, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 22, "scene": 32, "line": "No, he's just a big fraud, Deangelo. He's like Rango. He doesn't work here, basically. Just like the way Rango didn't save those animals. It was just a big misunderstanding.", "order": 43093, "speaker": "Kelly Kapoor"},
  {"season": 7, "episode": 22, "scene": 32, "line": "Is this true, Ryan?", "order": 43094, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 22, "scene": 32, "line": "I did not see Rango.", "order": 43095, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 22, "scene": 32, "line": "Okay, I don't have time for this he said-she said.", "order": 43096, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 22, "scene": 32, "line": "He's not saying anything!", "order": 43097, "speaker": "Kelly Kapoor"},
  {"season": 7, "episode": 22, "scene": 32, "line": "It's too murky. I like Ryan. You seem kind of hysterical to me. Ryan's your supervisor. Let's just leave it that way.", "order": 43098, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 22, "scene": 32, "line": "That's not fair, I mean, I've been working here for such a long ti-- [Deangelo walks away]", "order": 43099, "speaker": "Kelly Kapoor"},
  {"season": 7, "episode": 22, "scene": 32, "line": "[sighs] Oh, close call! Okay, why don't you just finish this up and leave it on my desk and I will see you at your place around 2 am.", "order": 43100, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 22, "scene": 33, "line": "[knocking on door to Deangelo's office as loud yelling comes from inside it] Hey! It sounds like you guys are having a lot of fun, but it's really loud, and some of us are trying to work, so do you think you could do it a little more quietly?", "order": 43101, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 22, "scene": 33, "line": "Well, that's gonna be tough, because we're getting a dunking clinic from Magic Jordan himself.", "order": 43102, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 22, "scene": 33, "line": "[chuckles] Oh, you mean Michael Jordan?", "order": 43103, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 22, "scene": 33, "line": "[laughs] Total brain burp.", "order": 43104, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 22, "scene": 33, "line": "I'm no MJ. I can do his dunk. From the free throw line.", "order": 43105, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 22, "scene": 33, "line": "Whoa.", "order": 43106, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 22, "scene": 33, "line": "Daaamn! Mad respect for my brotha!", "order": 43107, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 22, "scene": 34, "line": "The man is paying me to take Chinese. I will say what I need to say, and soon, I will say it in Chinese.", "order": 43108, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 22, "scene": 35, "line": "Okay, well it's just really loud.", "order": 43109, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 22, "scene": 35, "line": "Okay. We'll keep that in mind. Alright, ladies, back to the game.", "order": 43110, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 22, "scene": 35, "line": "Do it!", "order": 43111, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 22, "scene": 35, "line": "Jim! Come on in.", "order": 43112, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 22, "scene": 35, "line": "You're back in.", "order": 43113, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 22, "scene": 35, "line": "You know what? Instead of a game, why don't we do an exhibition? I'd love to see that dunk of yours.", "order": 43114, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 22, "scene": 35, "line": "Yeah, we'll set that up one day.", "order": 43115, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 22, "scene": 35, "line": "Today. Now, maybe. 'Cause we have a hoop downstairs and a real ball, so you don't have to mime it.", "order": 43116, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 22, "scene": 35, "line": "Yeah, I don't know.", "order": 43117, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 22, "scene": 35, "line": "Why not?", "order": 43118, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 22, "scene": 35, "line": "Only because no one has called NASA to request a liftoff. [laughter] Let's go downstairs! Okay? Let's do it.", "order": 43119, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 22, "scene": 35, "line": "Pass. If I wanted to see a pissing contest, I'd lock Mose in the chicken coop.", "order": 43120, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 22, "scene": 35, "line": "Damn it, Dwight! Enough! Get your ass downstairs or find a new place to sell paper! [Dwight immediately gets up and walks out]", "order": 43121, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 22, "scene": 36, "line": "Okay, a little about me. I respond to strong leadership.", "order": 43122, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 22, "scene": 37, "line": "Alright, there you go.", "order": 43123, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 22, "scene": 37, "line": "Seems a little close, you sure that's the real foul line?", "order": 43124, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 22, "scene": 37, "line": "15 feet from the baseline, so, you need me to move it in?", "order": 43125, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 22, "scene": 37, "line": "Nah, that's 15. Yeah.", "order": 43126, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 22, "scene": 37, "line": "Okay.", "order": 43127, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 22, "scene": 37, "line": "And uh, you know what, to make it interesting, Jordan, why don't you sit underneath the basket?", "order": 43128, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 22, "scene": 37, "line": "Seriously?", "order": 43129, "speaker": "Jordan"},
  {"season": 7, "episode": 22, "scene": 37, "line": "Yeah, come on. I'll dunk over you. Best seat in the house.", "order": 43130, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 22, "scene": 37, "line": "I don't, I don't think I can do that. I'm holding your jewelry.", "order": 43131, "speaker": "Jordan"},
  {"season": 7, "episode": 22, "scene": 37, "line": "Right. Yeah. Okay, fair enough. Kevin, you do it.", "order": 43132, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 22, "scene": 37, "line": "Yes! Okay. [sits down in front of basket]", "order": 43133, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 22, "scene": 37, "line": "Someone want to sit in Kevin's lap? Angela?", "order": 43134, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 22, "scene": 37, "line": "No.", "order": 43135, "speaker": "Angela Martin"},
  {"season": 7, "episode": 22, "scene": 37, "line": "Oscar?", "order": 43136, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 22, "scene": 37, "line": "No thank you.", "order": 43137, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 22, "scene": 37, "line": "Okay, Jimmy, this is for you. Show you that anything is possible.", "order": 43138, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 22, "scene": 37, "line": "Fantastic.", "order": 43139, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 22, "scene": 37, "line": "Alright?", "order": 43140, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 22, "scene": 37, "line": "Yup.", "order": 43141, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 22, "scene": 37, "line": "This is also for the troops. [backs up, bounces ball, starts running, jumps, lands in front of Kevin, jumps again, pulls himself up by the net, dunks ball, hangs on rim] Doctor is in! [hoop starts to fall] Ahhhh! [hoop crashes down on Deangelo]", "order": 43142, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 22, "scene": 38, "line": "[ambulance pulls away in parking lot] Now what?", "order": 43143, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 22, "scene": 39, "line": "[Deangelo enters office in hospital gown with bandage on his head] Deangelo?", "order": 43144, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 22, "scene": 39, "line": "[speaking gibberish] Tablab.", "order": 43145, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 22, "scene": 39, "line": "Oh my God, are you alright? Erin, will you call 911 please?", "order": 43146, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 22, "scene": 39, "line": "Who should I say is calling?", "order": 43147, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 22, "scene": 39, "line": "Erin.", "order": 43148, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 22, "scene": 39, "line": "[lots of gibberish] says to bartender [lots more gibberish] Droswip. Droswip.", "order": 43149, "speaker": "Deangelo Vickers"},
  {"season": 7, "episode": 22, "scene": 39, "line": "Droswip, yeah. I get it. Okay, we're gonna get you to the restroom.", "order": 43150, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 23, "scene": 1, "line": "We could get Deangelo flowers.", "order": 43151, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 23, "scene": 1, "line": "No, you can't get flowers for someone who's in a coma. They'll wilt before he wakes up.", "order": 43152, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 23, "scene": 1, "line": "That's true.", "order": 43153, "speaker": "Phyllis Vance"},
  {"season": 7, "episode": 23, "scene": 1, "line": "All in favor of the baskets full of chocolates, teddy bears, and balloons? [all but Dwight raise their hands]", "order": 43154, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 23, "scene": 1, "line": "Wait, wait. All in favor of the knapsack filled with canned goods, chainsaw, gasoline, and emergency radio in case he wakes up post-apocalypse? [Dwight raises his hand]", "order": 43155, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 23, "scene": 1, "line": "Nope. Baskets have it.", "order": 43156, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 23, "scene": 2, "line": "So as it turns out, unless you're a young child or a prison inmate, you don't need anyone supervising you. People just come in and do their work on their schedule. Imagine that - people like us allowed to sell paper. Unsupervised. [Darryl, Phyllis, Stanley, and Oscar are working while Andy and Ryan play on a foosball table] And yet, somehow it works. It must be because the stakes are so high.", "order": 43157, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 23, "scene": 3, "line": "[on phone] Well, I really appreciate the offer, but I'm just happy the way things are. Okay. Thank you. [to Pam] That was Jo, asking me if I wanted to take over as acting manager while they find a replacement. I told her... I don't want to mess this up, right? There's a consensus, people are happy.", "order": 43158, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 23, "scene": 3, "line": "[on phone] Dwight Schrute. Yes, I would. Thank you. [hangs up] Jordan, gather my things from my desk. [Dwight walks to the manager's office, rubs the door frame, sits at the desk reverently]", "order": 43159, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 23, "scene": 3, "line": "Wait...", "order": 43160, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 23, "scene": 3, "line": "What have you done?", "order": 43161, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 23, "scene": 3, "line": "[on phone] Mose... you'll never guess where I am right now.", "order": 43162, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 23, "scene": 3, "line": "[on phone] Aaaaahhhhh!", "order": 43163, "speaker": "Mose Schrute "},
  {"season": 7, "episode": 23, "scene": 4, "line": "Can't stay there all day.", "order": 43164, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 23, "scene": 4, "line": "I'm coming.", "order": 43165, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 23, "scene": 4, "line": "Do I at least get to go with you in this fantasy?", "order": 43166, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 23, "scene": 4, "line": "You'd slow me down.", "order": 43167, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 23, "scene": 5, "line": "Dwight has been acting manager for three months now. No. A week. Just feels like three months. Let's see, we all have to punch in to a time clock, which is very old, very strong, and has a slot about the size of a finger. [Jim puts a pencil into the time clock and pulls it out broken in half] We were all given new business cards big enough to set us apart from the competition, which is how I learned that our titles are all now Junior Employee. Our lunch breaks are staggered to prevent wasting time. Mine's at 10:30, and I find that the first hour of the day goes by a lot quicker than the second seven hours.", "order": 43168, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 23, "scene": 5, "line": "Stop stalling! Come on.", "order": 43169, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 23, "scene": 6, "line": "I pledge allegiance to the flag of the United States of America, and to the republic for which it stands, one nation...", "order": 43170, "speaker": "All"},
  {"season": 7, "episode": 23, "scene": 6, "line": "...under God...", "order": 43171, "speaker": "All but Oscar Martinez"},
  {"season": 7, "episode": 23, "scene": 6, "line": "...indivisible, with liberty and justice for all. [Kevin finishes behind the rest of the group]", "order": 43172, "speaker": "All"},
  {"season": 7, "episode": 23, "scene": 6, "line": "Amen.", "order": 43173, "speaker": "Angela Martin"},
  {"season": 7, "episode": 23, "scene": 6, "line": "Excellent. Morning announcements! There's been a problem with some people sharing copier codes. Your copier code is a distinct 21-digit number that is unique to you, and you only, okay? Don't share it. [Kevin looks exasperated] Jo Bennett, our CEO, will be here today for a high-level meeting involving Gabe and myself.", "order": 43174, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 23, "scene": 6, "line": "Ooh, about what?", "order": 43175, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 23, "scene": 6, "line": "That's on a need-to-know basis.", "order": 43176, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 23, "scene": 6, "line": "I thought I needed to know for your calendar.", "order": 43177, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 23, "scene": 6, "line": "[quietly] General meet-and-greet. So I expect you to be on your best behavior, which means none of you will be insubordinate, nor will you foment insurrection.", "order": 43178, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 23, "scene": 6, "line": "Question, if we already fomented insurrection, may we be grandfathered in?", "order": 43179, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 23, "scene": 6, "line": "Define foment.", "order": 43180, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 23, "scene": 6, "line": "You define foment.", "order": 43181, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 23, "scene": 6, "line": "[clears throat] Please take note of the new color-coding system.", "order": 43182, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 23, "scene": 7, "line": "[surprising Kelly] Aha!", "order": 43183, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 23, "scene": 7, "line": "Oh my God, what is wrong with you?", "order": 43184, "speaker": "Kelly Kapoor"},
  {"season": 7, "episode": 23, "scene": 7, "line": "Gotcha! Why are you late?", "order": 43185, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 23, "scene": 7, "line": "It's none of your business, actually. It's very medical and personal.", "order": 43186, "speaker": "Kelly Kapoor"},
  {"season": 7, "episode": 23, "scene": 7, "line": "All right. You stop me when I reach the diseased area. [Dwight points to her legs and slowly moves his finger up, stopping at her mid-section]", "order": 43187, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 23, "scene": 8, "line": "In fairness, Darryl did tell me that joke, so...", "order": 43188, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 23, "scene": 8, "line": "Oh! Darryl's funny.", "order": 43189, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 23, "scene": 8, "line": "I know, right?", "order": 43190, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 23, "scene": 8, "line": "Hey, Andy?", "order": 43191, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 23, "scene": 8, "line": "Yeah?", "order": 43192, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 23, "scene": 8, "line": "Can I talk to you a second about these client memos?", "order": 43193, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 23, "scene": 8, "line": "Sure. What's going on? [in room with Gabe]", "order": 43194, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 23, "scene": 8, "line": "Are you still in love with Erin?", "order": 43195, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 23, "scene": 8, "line": "What?", "order": 43196, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 23, "scene": 8, "line": "Because I am. I need to get her back. [crying] I can't be alone anymore. Andy, do you like being alone with me right now?", "order": 43197, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 23, "scene": 8, "line": "No, this is horrifying.", "order": 43198, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 23, "scene": 8, "line": "No. I don't like being alone with me either, okay? I have to get her back. Are you still gonna date her?", "order": 43199, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 23, "scene": 8, "line": "We're just friends, okay?", "order": 43200, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 23, "scene": 8, "line": "Do you promise that?", "order": 43201, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 23, "scene": 8, "line": "Yeah, fine, I promise. We'll never date again. Can we go outside now?", "order": 43202, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 23, "scene": 8, "line": "No, just give me a second. I don't want anyone to know I've been crying.", "order": 43203, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 23, "scene": 9, "line": "[seeing the Join the Fist flyers that Jim is hanging] Hey, hey, hey, hey. What do you think you're doing? What's this? What's the Fist?", "order": 43204, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 23, "scene": 9, "line": "Oh, it's just a social club. You know, like the French Revolution, or the Black Panthers, or communism. It's just a club. Guys talking, you know.", "order": 43205, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 23, "scene": 9, "line": "You expect me to believe that you're starting a rebellion?", "order": 43206, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 23, "scene": 9, "line": "Nope. Social club. God, I hate when everybody calls us a rebellion. [Darryl enters and brandishes his fist, Jim returns the gesture]", "order": 43207, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 23, "scene": 9, "line": "Okay, you know what? I would love to join The Fist.", "order": 43208, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 23, "scene": 9, "line": "And we would love to have you. But not today. Unfortunately, it's a bad day, what with Operation Overthrow and everything, but I have noted it. [Dwight tears the sign down, Jim hangs two signs in its place]", "order": 43209, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 23, "scene": 10, "line": "[inputting numbers into the copier and holding up the line] One...", "order": 43210, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 23, "scene": 11, "line": "[refills his coffee near a sign that reads 'Honor System: $0.50' with a camera pointed at it]", "order": 43211, "speaker": "Stanley Hudson"},
  {"season": 7, "episode": 23, "scene": 12, "line": "Pam, I have to show you this video. You're gonna love it. It's Beyonce falling with a fart mixed in. [buzzer to indicate a blocked web site]", "order": 43212, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 23, "scene": 13, "line": "I will never be happier than I am right now. I will also never be less happy. I will be at my current maximum happiness for the rest of my life. Because I am manager of the Scranton Branch of Dunder Mifflin. Acting Manager.", "order": 43213, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 23, "scene": 14, "line": "[looking at empty vending machine] No food now? Someone has to do something about Dwight!", "order": 43214, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 23, "scene": 14, "line": "He put some snacks in the freezer for us.", "order": 43215, "speaker": "Creed Bratton"},
  {"season": 7, "episode": 23, "scene": 14, "line": "You mean the frozen mice for the piranha?", "order": 43216, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 23, "scene": 14, "line": "No. The blueberry Slurpee pouch.", "order": 43217, "speaker": "Creed Bratton"},
  {"season": 7, "episode": 23, "scene": 14, "line": "He means the ice pack.", "order": 43218, "speaker": "Phyllis Vance"},
  {"season": 7, "episode": 23, "scene": 15, "line": "[on phone] 2:00 should probably be okay. I can either take your number or - [Gabe ends the call]", "order": 43219, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 23, "scene": 15, "line": "I'm sorry. This can't wait.", "order": 43220, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 23, "scene": 15, "line": "He's just gonna call back.", "order": 43221, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 23, "scene": 15, "line": "Erin, I am in love with you. I don't believe in much, okay? I don't believe in horoscopes. I don't believe in Christmas. Uh, uh, I sure as hell don't believe in God. [Erin and Angela both look taken aback]", "order": 43222, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 23, "scene": 15, "line": "[quietly] What?", "order": 43223, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 23, "scene": 15, "line": "Or maybe there's a God. I don't know. I mean, it's just not a guy with a long white beard. [Erin looks shocked] Or it could be. I mean, it's possible that that is exactly what - what God is. But for all of the disbelief [phone rings] I believe in us. I believe in love. You have made me believe that for all of the hokum [phone continues ringing] out... there... uh, do we not have voicemail?", "order": 43224, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 23, "scene": 15, "line": "Dwight doesn't trust robots to give us our messages.", "order": 43225, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 23, "scene": 15, "line": "Well... it's just... [ringing] it messes up my rhythm.", "order": 43226, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 23, "scene": 16, "line": "I'm taking a break from dating. Gabe was a great guy with so many wonderful qualities, but it was a challenge being touched by him.", "order": 43227, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 23, "scene": 17, "line": "Wow, you've really embraced the whole Bond villain aesthetic.", "order": 43228, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 23, "scene": 17, "line": "Ah, Jim, Jim, Jim. The gun is a Beaumont-Adams. Jo collects them. I thought we could have some nice small talk about it. And the piranha's a rescue.", "order": 43229, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 23, "scene": 17, "line": "And the desk.", "order": 43230, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 23, "scene": 17, "line": "The desk is a replica of Uday Hussein's desk. I saw a picture in Newsweek. Listen, I'm a very busy man. Let's get right down to business.", "order": 43231, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 23, "scene": 17, "line": "Okay.", "order": 43232, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 23, "scene": 17, "line": "Jo is coming later today. I cannot have a subordinate trying to make me look stupid. Okay? I need you to promise me that you'll be on your best behavior.", "order": 43233, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 23, "scene": 17, "line": "I promise...d other people that I would be on my worst behavior. And I gave them my word, so...", "order": 43234, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 23, "scene": 17, "line": "Don't make me fire you.", "order": 43235, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 23, "scene": 17, "line": "You can't fire me. You're acting manager. Not office manager. So you have no firing powers.", "order": 43236, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 23, "scene": 17, "line": "Don't make me pre-fire you.", "order": 43237, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 23, "scene": 17, "line": "You wouldn't dare.", "order": 43238, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 23, "scene": 17, "line": "Watch this. You're pre-fired. And when I'm promoted, you'll be full fired.", "order": 43239, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 23, "scene": 17, "line": "[quietly] If you get promoted, and if you haven't fallen in love with me by then.", "order": 43240, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 23, "scene": 17, "line": "What?", "order": 43241, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 23, "scene": 18, "line": "If I had thought that there was a real chance that Dwight would be permanent manager, I would have pre-quit. And you might be saying to yourself, 'Well that's pretty premature to think,' but I always say, it's better to be pre-pre-preprepared.", "order": 43242, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 23, "scene": 19, "line": "How you doing, Jordan?", "order": 43243, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 23, "scene": 19, "line": "I'm good.", "order": 43244, "speaker": "Jordan"},
  {"season": 7, "episode": 23, "scene": 19, "line": "Everyone here thinks that you're a joke. They think that you were hired because of your good looks, which won't last long anyway. As your pale skin and severe bone structure imply, you'll age swiftly and poorly.", "order": 43245, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 23, "scene": 19, "line": "Why are you telling me this?", "order": 43246, "speaker": "Jordan"},
  {"season": 7, "episode": 23, "scene": 19, "line": "Now I want you to find out what people are saying about me, things they don't want me to know abou-[Dwight opens a box and sits down in shock] Ohh... it's a holster.", "order": 43247, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 23, "scene": 20, "line": "[walking around the office with the holster around his waist] Yep. Yep, yep, yep. Lookin' good. [grunts] Ohh... [stretching out his arms to better indicate the gun and holster] Oh, what a day. What a day.", "order": 43248, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 23, "scene": 20, "line": "Dwight, are you carrying a gun?", "order": 43249, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 23, "scene": 20, "line": "The holster was a gift from my great-uncle Honk. Um, I don't know. I guess he's saying that he's proud of me.", "order": 43250, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 23, "scene": 20, "line": "Dwight, guns make me really uncomfortable.", "order": 43251, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 23, "scene": 20, "line": "The gun is just an accessory to the holster, okay? I can't walk around wearing an empty holster.", "order": 43252, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 23, "scene": 20, "line": "Why do you need to wear the holster at all?", "order": 43253, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 23, "scene": 20, "line": "Why do you need to keep wearing those booby shirts all the time?", "order": 43254, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 23, "scene": 20, "line": "Thank you.", "order": 43255, "speaker": "Angela Martin"},
  {"season": 7, "episode": 23, "scene": 20, "line": "You could put your cell phone in it instead.", "order": 43256, "speaker": "Phyllis Vance"},
  {"season": 7, "episode": 23, "scene": 20, "line": "Uh... hello! [indicates his phone clipped onto his belt]", "order": 43257, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 23, "scene": 20, "line": "You could put a banana in it.", "order": 43258, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 23, "scene": 20, "line": "Why would I put a banana in my holster?", "order": 43259, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 23, "scene": 20, "line": "In case you weren't hungry now, but you got hungry later.", "order": 43260, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 23, "scene": 20, "line": "Dwight, no gun, okay? Don't make us call Jo.", "order": 43261, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 23, "scene": 20, "line": "Okay. All right. Fine. Everyone calm down. Someone get me a banana. Sorry I freaked you guys out - aah! [Dwight goes to flip the gun and it fires near Andy's ear, making everyone jump]", "order": 43262, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 23, "scene": 20, "line": "Andy!", "order": 43263, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 23, "scene": 20, "line": "Aah! Aah! It's so loud!", "order": 43264, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 23, "scene": 21, "line": "My God. There is a hardwood floor underneath this carpeting. Why would they do that?", "order": 43265, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 23, "scene": 21, "line": "[trying to find a pitch] Eeeeeeee... There's like this crazy ringing going on. I can't - eeeeeee... I can't find perfect C.", "order": 43266, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 23, "scene": 21, "line": "Okay, everyone make a list of what's lost for the insurance. I lost a necklace, a ring, a painting-", "order": 43267, "speaker": "Meredith Palmer"},
  {"season": 7, "episode": 23, "scene": 21, "line": "Hey, what happened?", "order": 43268, "speaker": "Toby Flenderson"},
  {"season": 7, "episode": 23, "scene": 21, "line": "Dwight went on a shooting spree, and then he shot Andy in the head.", "order": 43269, "speaker": "Kelly Kapoor"},
  {"season": 7, "episode": 23, "scene": 21, "line": "No, no, no. All we know is that a gun fired. That's all we know! No. That's not true. Actually, we heard a loud noise. Later, a hole was seen. No one saw the bullet leave the gun.", "order": 43270, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 23, "scene": 21, "line": "[scatting off-key] Something's definitely wrong.", "order": 43271, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 23, "scene": 21, "line": "I'll look on WebMD. What are your symptoms?", "order": 43272, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 23, "scene": 21, "line": "Oh, everybody. Oscar found a reason to look on WebMD.", "order": 43273, "speaker": "Angela Martin"},
  {"season": 7, "episode": 23, "scene": 21, "line": "[buzzer to indicate a blocked site] Firewall.", "order": 43274, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 23, "scene": 21, "line": "I can't reach Jo. She must already be on the plane.", "order": 43275, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 23, "scene": 21, "line": "Okay, why are you calling Jo?", "order": 43276, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 23, "scene": 21, "line": "I think I should go the hospital.", "order": 43277, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 23, "scene": 21, "line": "I'll go with you.", "order": 43278, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 23, "scene": 21, "line": "No! We really shouldn't be without a receptionist. Now more than ever.", "order": 43279, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 23, "scene": 21, "line": "I'll take him.", "order": 43280, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 23, "scene": 21, "line": "Thank you, Darryl. [Andy limps out of his chair and leans on Darryl for support]", "order": 43281, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 23, "scene": 21, "line": "What's wrong with you?", "order": 43282, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 23, "scene": 21, "line": "It's my ear.", "order": 43283, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 23, "scene": 21, "line": "Yeah.", "order": 43284, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 23, "scene": 21, "line": "Okay, sorry.", "order": 43285, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 23, "scene": 21, "line": "[excitedly] Oh, I should get the accident report binder. I've never used the gun violence forms before.", "order": 43286, "speaker": "Toby Flenderson"},
  {"season": 7, "episode": 23, "scene": 21, "line": "I'm gonna keep trying Jo.", "order": 43287, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 23, "scene": 21, "line": "Okay, everyone! Conference room, right now!", "order": 43288, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 23, "scene": 22, "line": "[enters with a cowboy hat, speaking with a Western accent] Yee-haw! Whoo-hoo! [imitates gunfire] Howdy, partners! It's me, Gun-Safety Dwight! And I'm the rootin'-est-- [removes hat] I can't do this. Um, look. Obviously, a gun went off under my watch, and I'm launching a full investigation.", "order": 43289, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 23, "scene": 22, "line": "We all saw you do it.", "order": 43290, "speaker": "Stanley Hudson"},
  {"season": 7, "episode": 23, "scene": 22, "line": "Really? You did? What hand was I holding the gun in? What did Andy's tie look like?", "order": 43291, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 23, "scene": 22, "line": "Navy blue. Little red anchors.", "order": 43292, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 23, "scene": 22, "line": "I have no way of knowing if that's true.", "order": 43293, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 23, "scene": 22, "line": "I am supposed to collect eyewitness accounts. Who saw Dwight do this? [all raise their hands]", "order": 43294, "speaker": "Toby Flenderson"},
  {"season": 7, "episode": 23, "scene": 22, "line": "Okay, really?", "order": 43295, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 23, "scene": 22, "line": "Would you consider this a terrorist incident?", "order": 43296, "speaker": "Toby Flenderson"},
  {"season": 7, "episode": 23, "scene": 22, "line": "I felt terrorized.", "order": 43297, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 23, "scene": 22, "line": "Come on.", "order": 43298, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 23, "scene": 22, "line": "Oh, there's a whole 'nother terrorism booklet for that.", "order": 43299, "speaker": "Toby Flenderson"},
  {"season": 7, "episode": 23, "scene": 22, "line": "I just really, really think we should handle this internally.", "order": 43300, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 23, "scene": 22, "line": "Dwight, why is it on us to protect you?", "order": 43301, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 23, "scene": 22, "line": "Because you guys are my best friends, and I mean that. Managing you for this last week has been the greatest honor of my life. And if you ruin this, I will burn this office to the ground. And I mean that figuratively. Not literally. Because you guys are so... so important... to me. I love you guys. But don't cross me. But you're the best.", "order": 43302, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 23, "scene": 23, "line": "Hey. [Erin gasps] We're back.", "order": 43303, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 23, "scene": 23, "line": "There he is! Our hero! [Both Dwight and Erin move to hug Andy] You look fantastic. How you doing? Here, tell us your war stories.", "order": 43304, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 23, "scene": 23, "line": "Well, I burst my eardrum. Doctor said it could take weeks to heal.", "order": 43305, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 23, "scene": 23, "line": "Did they say what caused it? Because I know you like putting Q-tips deep into your ear canal.", "order": 43306, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 23, "scene": 23, "line": "Yeah.", "order": 43307, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 23, "scene": 23, "line": "How's your hearing?", "order": 43308, "speaker": "Phyllis Vance"},
  {"season": 7, "episode": 23, "scene": 23, "line": "Temporary deafness in one ear. It was both ears. I couldn't hear a thing Darryl was saying while we were in the waiting room.", "order": 43309, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 23, "scene": 24, "line": "I was talking like this. [mouths words while pointing to his ear] I don't feel good about it, but he just kept calling himself a gunshot victim and it got to me.", "order": 43310, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 23, "scene": 25, "line": "Dwight, I would like tomorrow off so that I can go on my American Idol audition.", "order": 43311, "speaker": "Kelly Kapoor"},
  {"season": 7, "episode": 23, "scene": 25, "line": "American Idol? What? No.", "order": 43312, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 23, "scene": 25, "line": "Okay. I guess I can just not go. Do you guys wanna hear my solo?", "order": 43313, "speaker": "Kelly Kapoor"},
  {"season": 7, "episode": 23, "scene": 25, "line": "No.", "order": 43314, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 23, "scene": 25, "line": "[singing] Why did my temporary boss go on a shooting spree?", "order": 43315, "speaker": "Kelly Kapoor"},
  {"season": 7, "episode": 23, "scene": 25, "line": "Okay, fine. You can go on your singing audition.", "order": 43316, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 23, "scene": 25, "line": "Okay, well, then I want an extra vacation day.", "order": 43317, "speaker": "Phyllis Vance"},
  {"season": 7, "episode": 23, "scene": 25, "line": "Pet Day! I want Pet Day back - no dogs.", "order": 43318, "speaker": "Angela Martin"},
  {"season": 7, "episode": 23, "scene": 25, "line": "Put back everything in the vending machine except the fruit.", "order": 43319, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 23, "scene": 25, "line": "I put everything back in the vending machine.", "order": 43320, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 23, "scene": 25, "line": "Put everything back in the vending machine except... the fruit.", "order": 43321, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 23, "scene": 25, "line": "You have to get rid of all your weapons. All of them. Including killer fish.", "order": 43322, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 23, "scene": 25, "line": "Okay.", "order": 43323, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 23, "scene": 25, "line": "Oh, is it my turn? Ooh, I'm on the spot. I don't know. Um... you know what? I think I'm good.", "order": 43324, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 23, "scene": 25, "line": "Thank God.", "order": 43325, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 23, "scene": 25, "line": "Nope. When Jo's here, can you work in 'Shagadelic, baby,' at least three times in a conversation? Oh, and when I cough can you do jazz hands?", "order": 43326, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 23, "scene": 25, "line": "What's jazz hands?", "order": 43327, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 23, "scene": 25, "line": "[quickly shakes his hands back and forth]", "order": 43328, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 23, "scene": 25, "line": "Fine.", "order": 43329, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 23, "scene": 26, "line": "[leaves the men's room with an empty fish tank after flushing the toilet]", "order": 43330, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 23, "scene": 26, "line": "[after entering the men's room] What the [bleep] is that? [runs out of the bathroom] Oh, my...", "order": 43331, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 23, "scene": 27, "line": "Hey, all! Cornelius, Bobo, have at it. Nobody let my dogs hump each other. They don't seem to know they're brothers.", "order": 43332, "speaker": "Jo Bennett"},
  {"season": 7, "episode": 23, "scene": 27, "line": "Hello, Jo. Welcome. Well, shall we begin?", "order": 43333, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 23, "scene": 27, "line": "Slow yourself down there. Just like a man. Wants to jump right into it while I still got my socks on. Jim Halpert - the only man that ever turned me down.", "order": 43334, "speaker": "Jo Bennett"},
  {"season": 7, "episode": 23, "scene": 27, "line": "Well, I don't know about that. Just wasn't for me. But let me assure you, Dwight is firing on all cylinders. Right?", "order": 43335, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 23, "scene": 27, "line": "Well, some pretty big shoes to fill. I was sorry to hear about Deangelo. He was a good man. Tragic. [Jim coughs]", "order": 43336, "speaker": "Jo Bennett"},
  {"season": 7, "episode": 23, "scene": 27, "line": "[does jazz hands, Jo notices and eyes him oddly] Well, what can you do? Life.", "order": 43337, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 23, "scene": 28, "line": "We have to clean this up now!", "order": 43338, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 23, "scene": 28, "line": "She had so much -", "order": 43339, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 23, "scene": 28, "line": "Hey, guys.", "order": 43340, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 23, "scene": 28, "line": "Hey.", "order": 43341, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 23, "scene": 28, "line": "Hope I'm not interrupting anything.", "order": 43342, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 23, "scene": 28, "line": "No! [Erin and Andy laugh]", "order": 43343, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 23, "scene": 28, "line": "Hey, Andy, did you tell Erin about our conversation earlier when you said you would never want to date her again?", "order": 43344, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 23, "scene": 28, "line": "Uh...", "order": 43345, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 23, "scene": 28, "line": "I just thought that it was so interesting that you promised that.", "order": 43346, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 23, "scene": 28, "line": "Did I say that earlier? Yes, I did. Because you, Gabe, were crying...", "order": 43347, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 23, "scene": 28, "line": "No, I was not.", "order": 43348, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 23, "scene": 28, "line": "And sobbing uncontrollably. Yes. Uh-huh. You were in hysterics. And my maternal instincts kicked in. Is it actually how I feel? Yes. Or no. That is... between me and my diary.", "order": 43349, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 23, "scene": 29, "line": "Oscar, can you print out last year's sale statements?", "order": 43350, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 23, "scene": 29, "line": "Hey, Dwight, gimme a back massage.", "order": 43351, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 23, "scene": 29, "line": "No, Kevin, come on.", "order": 43352, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 23, "scene": 29, "line": "My back is sore, Dwight. Massage it.", "order": 43353, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 23, "scene": 29, "line": "Kevin, not now.", "order": 43354, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 23, "scene": 29, "line": "Hey, Jo!", "order": 43355, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 23, "scene": 29, "line": "Okay, okay. Shh! [Dwight begins rubbing his shoulders]", "order": 43356, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 23, "scene": 29, "line": "No, under the jacket.", "order": 43357, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 23, "scene": 29, "line": "You're kidding me. [reaches under Kevin's jacket] Oh God, what am I touching? It's moist.", "order": 43358, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 23, "scene": 29, "line": "I don't feel anything.", "order": 43359, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 23, "scene": 29, "line": "Oh, it's so wet.", "order": 43360, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 23, "scene": 29, "line": "Push harder, Dwight.", "order": 43361, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 23, "scene": 29, "line": "I can't. I can't push harder!", "order": 43362, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 23, "scene": 29, "line": "Go a little higher. There you go. Nope, higher. Higher. [Dwight has positioned his feet on the filing cabinet behind Kevin and is kneading his back horizontally]", "order": 43363, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 23, "scene": 29, "line": "Okay, how's that?", "order": 43364, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 23, "scene": 29, "line": "Knead it. Knead it like a pizza.", "order": 43365, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 23, "scene": 29, "line": "I'm kneading it!", "order": 43366, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 23, "scene": 29, "line": "Don't eat it.", "order": 43367, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 23, "scene": 29, "line": "Dwight! Walk me out. Let's talk soon. And stay on top of these people. You gotta admit it - it's nice to have a little power, eh? How's it feel?", "order": 43368, "speaker": "Jo Bennett"},
  {"season": 7, "episode": 23, "scene": 29, "line": "Jo... I accidentally fired a gun in the office today.", "order": 43369, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 23, "scene": 29, "line": "What?!", "order": 43370, "speaker": "Jo Bennett"},
  {"season": 7, "episode": 23, "scene": 29, "line": "I am telling you this because I care too much about this job to be blackmailed into doing it poorly. All I've ever wanted was to be manager here. And if you feel that you cannot promote me over this one accident, I understand. But if you think that extortion is worse -", "order": 43371, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 23, "scene": 29, "line": "Shooting is worse! Are you kidding me? It's not even in the same - you shot a gun? What is wrong with you?", "order": 43372, "speaker": "Jo Bennett"},
  {"season": 7, "episode": 23, "scene": 29, "line": "It was a Beaumont-Adams, if that helps.", "order": 43373, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 23, "scene": 29, "line": "Beaumont-Adams is a girl's gun. That just makes it plain stupid.", "order": 43374, "speaker": "Jo Bennett"},
  {"season": 7, "episode": 23, "scene": 29, "line": "I take full responsibility.", "order": 43375, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 23, "scene": 29, "line": "Who else would be responsible?", "order": 43376, "speaker": "Jo Bennett"},
  {"season": 7, "episode": 23, "scene": 29, "line": "Pam made me put a banana in my holster.", "order": 43377, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 23, "scene": 29, "line": "You shot a gun off -", "order": 43378, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 23, "scene": 29, "line": "Okay. Did I make a mistake? Yes. Do I regret the decision that I made? Yes.", "order": 43379, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 23, "scene": 29, "line": "Oh, stop asking yourself easy questions so you can look like a genius.", "order": 43380, "speaker": "Jo Bennett"},
  {"season": 7, "episode": 23, "scene": 29, "line": "Got it.", "order": 43381, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 23, "scene": 29, "line": "I love you, Dwight. But you don't fit this job.", "order": 43382, "speaker": "Jo Bennett"},
  {"season": 7, "episode": 23, "scene": 29, "line": "[on the verge of tears] Jo, please... I will -", "order": 43383, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 23, "scene": 29, "line": "No, child. No. It's over. Now I gotta find a replacement for my replacement.", "order": 43384, "speaker": "Jo Bennett"},
  {"season": 7, "episode": 23, "scene": 30, "line": "You three are my search committee. You're in charge of finding me my next manager. All righty?", "order": 43385, "speaker": "Jo Bennett"},
  {"season": 7, "episode": 23, "scene": 30, "line": "Yep.", "order": 43386, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 23, "scene": 30, "line": "Yep.", "order": 43387, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 23, "scene": 30, "line": "Till then, we need a new acting manager. Now, who's got the most experience in this office?", "order": 43388, "speaker": "Jo Bennett"},
  {"season": 7, "episode": 23, "scene": 30, "line": "Uh, well, we probably don't want to go just on seniority.", "order": 43389, "speaker": "Toby Flenderson"},
  {"season": 7, "episode": 23, "scene": 30, "line": "Who is it?", "order": 43390, "speaker": "Jo Bennett"},
  {"season": 7, "episode": 23, "scene": 31, "line": "Hey, Dwight. What's up?", "order": 43391, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 23, "scene": 31, "line": "Shut up.", "order": 43392, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 23, "scene": 31, "line": "Well, you do know that Jo was right to take the job away from you, right? There's no debating that. But, I will say... in your one week, every single one of the orders went out on time. And I think that is shagadelic, baby. [Dwight sees Creed claiming the desk in the manager's office]", "order": 43393, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 23, "scene": 32, "line": "[giving Kevin numbers for the copier] Four, one, seven, one...", "order": 43394, "speaker": "Angela Martin"},
  {"season": 7, "episode": 23, "scene": 32, "line": "Another one?", "order": 43395, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 23, "scene": 32, "line": "Yes!", "order": 43396, "speaker": "Darryl Philbin & Angela Martin"},
  {"season": 7, "episode": 23, "scene": 32, "line": "Seven, two...", "order": 43397, "speaker": "Angela Martin"},
  {"season": 7, "episode": 23, "scene": 32, "line": "Could you turn the volume down on the beeping?", "order": 43398, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 23, "scene": 32, "line": "Six, nine, shut up, Oscar. Eight...", "order": 43399, "speaker": "Angela Martin"},
  {"season": 7, "episode": 23, "scene": 32, "line": "Nine first.", "order": 43400, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 23, "scene": 32, "line": "Nine, eight, five...", "order": 43401, "speaker": "Angela Martin"},
  {"season": 7, "episode": 23, "scene": 32, "line": "Thank you.", "order": 43402, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 23, "scene": 32, "line": "Three, zero, zero. Again, zero. Four, six...", "order": 43403, "speaker": "Angela Martin"},
  {"season": 7, "episode": 23, "scene": 32, "line": "Nice.", "order": 43404, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 23, "scene": 32, "line": "Two, one...", "order": 43405, "speaker": "Angela Martin"},
  {"season": 7, "episode": 23, "scene": 32, "line": "Oh, I hit three. I hit three.", "order": 43406, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 23, "scene": 32, "line": "God!", "order": 43407, "speaker": "Angela Martin"},
  {"season": 7, "episode": 24, "scene": 1, "line": "[drives up to the entrance of the building, his license plate reads 'NEW MGR'] It's a beautiful morning at Dunder-Mifflin, or as I like to call it, Great Bratton. [gets out of car, tosses his keys toward a nonexistent valet parker] Keep it running.", "order": 43408, "speaker": "Creed Bratton"},
  {"season": 7, "episode": 24, "scene": 2, "line": "Do I love being manager?... I love my kids. I love real estate. [slowly getting more excited] I love ceramics. I love my job. I-I love wrestling.", "order": 43409, "speaker": "Creed Bratton"},
  {"season": 7, "episode": 24, "scene": 3, "line": "[to Jordan] Find out what language this is. [speaking in strange language]", "order": 43410, "speaker": "Creed Bratton"},
  {"season": 7, "episode": 24, "scene": 4, "line": "[running a meeting in the conference room] Okay, team building! On this side of the room, Stanley, Phyllis, Jim, Ted, Elroy, [camera switches to show only Meredith and Kevin have attended the meeting, Jim walks by and sees this] and this side of the room, Pam, Meredith, Phyllis, Creed...", "order": 43411, "speaker": "Creed Bratton"},
  {"season": 7, "episode": 24, "scene": 5, "line": "He never called a meeting.", "order": 43412, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 24, "scene": 6, "line": "[pointing at a whiteboard with BOBODDY written on the left side, vertically] BO-BODDY! BO-BODDY. What does the first 'B' stand for?", "order": 43413, "speaker": "Creed Bratton"},
  {"season": 7, "episode": 24, "scene": 6, "line": "What are we doing?", "order": 43414, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 24, "scene": 6, "line": "We're making acronyms. Okay! What does the first 'B' stand for?", "order": 43415, "speaker": "Creed Bratton"},
  {"season": 7, "episode": 24, "scene": 6, "line": "Um... Business!", "order": 43416, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 24, "scene": 6, "line": "I like it! Business! Good, Kevin! [writes after the first B, I, Z, N, U...] All right! The 'O'...", "order": 43417, "speaker": "Creed Bratton"},
  {"season": 7, "episode": 24, "scene": 7, "line": "We need a new manager.", "order": 43418, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 24, "scene": 8, "line": "[conducting interview] Your paper experience is very interesting. Do you think you could use that experience to inform decisions here?", "order": 43419, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 24, "scene": 8, "line": "Absolutely--I, yes. In fact, I actually have a three-step plan that I believe could effectively double your profits.", "order": 43420, "speaker": "Fred Henry"},
  {"season": 7, "episode": 24, "scene": 8, "line": "Really?", "order": 43421, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 24, "scene": 8, "line": "Yeah.", "order": 43422, "speaker": "Fred"},
  {"season": 7, "episode": 24, "scene": 8, "line": "[waiting for Fred to continue] ...What is it?", "order": 43423, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 24, "scene": 8, "line": "Nice try.", "order": 43424, "speaker": "Fred"},
  {"season": 7, "episode": 24, "scene": 8, "line": "I'm sorry, what is your three-step plan?", "order": 43425, "speaker": "Toby Flenderson"},
  {"season": 7, "episode": 24, "scene": 8, "line": "Well, I mean, I can't just hand you my plan. I mean, if you guys give me the job, then, then you'll get the plan.", "order": 43426, "speaker": "Fred"},
  {"season": 7, "episode": 24, "scene": 8, "line": "Well... it's an interview, and we don't know that you really have the plan.", "order": 43427, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 24, "scene": 8, "line": "[speaking faster] Well, I'm not gonna just make up that I have a plan. I got a plan. Believe me, you guys want it. You're in paper, right?", "order": 43428, "speaker": "Fred"},
  {"season": 7, "episode": 24, "scene": 8, "line": "How would we know that, if you don't...", "order": 43429, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 24, "scene": 8, "line": "You could just be saying it to get the job.", "order": 43430, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 24, "scene": 8, "line": "I guess I could be, if I was... who would do that?", "order": 43431, "speaker": "Fred"},
  {"season": 7, "episode": 24, "scene": 8, "line": "How about this. Why don't you give us a part of the plan, and that way we know you have it.", "order": 43432, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 24, "scene": 8, "line": "Tell you what. I'll give you part three of part two. Not gonna give you a whole part.", "order": 43433, "speaker": "Fred"},
  {"season": 7, "episode": 24, "scene": 8, "line": "Okay.", "order": 43434, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 24, "scene": 8, "line": "Color-code sent documents, TM.", "order": 43435, "speaker": "Fred"},
  {"season": 7, "episode": 24, "scene": 8, "line": "Did you just trademark that...", "order": 43436, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 24, "scene": 8, "line": "[confused] W-What?", "order": 43437, "speaker": "Toby Flenderson"},
  {"season": 7, "episode": 24, "scene": 8, "line": "That's a verbal trademark. That's an agreement.", "order": 43438, "speaker": "Fred"},
  {"season": 7, "episode": 24, "scene": 9, "line": "We are the Search Committee, hand-picked by our CEO, and tasked to find this branch's new manager. Not everyone we meet will be good, but someone's bound to be, right? [Andy, Kelly, and Darryl are each dressed up in suits for their interviews, Andy is also wearing an 'Andy for Manager?' pin on his jacket] And, to be honest, I think a lot of the decent candidates are right here in-house, so, I'm not too worried. But I'm really excited to spend a lot of time with Toby and Gabe. [quietly] Did you know that Gabe's last name was Lewis? I had no idea.", "order": 43439, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 24, "scene": 10, "line": "[in elevator with Phyllis and Darryl, all three are smiling, to Darryl] Good morning.", "order": 43440, "speaker": "Stanley Hudson"},
  {"season": 7, "episode": 24, "scene": 10, "line": "Good morning.", "order": 43441, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 24, "scene": 10, "line": "[also to Darryl] Good morning.", "order": 43442, "speaker": "Phyllis Vance"},
  {"season": 7, "episode": 24, "scene": 10, "line": "Good morning.", "order": 43443, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 24, "scene": 10, "line": "Did you have a nice drive in?", "order": 43444, "speaker": "Phyllis Vance"},
  {"season": 7, "episode": 24, "scene": 10, "line": "I did.", "order": 43445, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 24, "scene": 11, "line": "I have a solid relationship with Jo, the company's owner. I have management experience. I have a good friendship with Jim, the head of the Search Committee, and it doesn't hurt that I'm... [in Oprah-like sing-song voice] bla-aaack!", "order": 43446, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 24, "scene": 12, "line": "[puts up a poster in the break room with 'IF YOU ARE ON THE SEARCH COMMITTEE PLEASE CONSIDER ANDY' on it in bold lettering]", "order": 43447, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 24, "scene": 12, "line": "[commenting on the poster] It's good. I really hope you get it!", "order": 43448, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 24, "scene": 12, "line": "The manager job? Nah, I'm barely interested. I just can't not go for it. You know, it's not the Bernard way. We give it the old college try, and then, in defeat, we show grace.", "order": 43449, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 24, "scene": 12, "line": "Still, I'd really like to see this office with you in the boss's chair.", "order": 43450, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 24, "scene": 12, "line": "Don't even... [getting really happy and excited] EEEHHHH!!!!", "order": 43451, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 24, "scene": 12, "line": "You in the boss's chair!", "order": 43452, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 24, "scene": 12, "line": "EEEHHH!! No. Hehe.", "order": 43453, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 24, "scene": 13, "line": "In my family, you don't really go out and get things. If you want something, you write it on a list, and then the housekeeper goes out and gets it, on Wednesdays and Fridays. So, I don't know, I guess you could say this job is on my list, and... [awkwardly smiling] we'll see what Rosa comes back with.", "order": 43454, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 24, "scene": 14, "line": "[checks to see that she is alone with Phyllis] Did you hear anything?", "order": 43455, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 24, "scene": 14, "line": "Oh, I'm sure they'll let us know when they get the results. [Erin crosses fingers and smiles excitedly]", "order": 43456, "speaker": "Phyllis Vance"},
  {"season": 7, "episode": 24, "scene": 15, "line": "It turns out that Erin was born in the basic time and region that I gave away a child.", "order": 43457, "speaker": "Phyllis Vance"},
  {"season": 7, "episode": 24, "scene": 15, "line": "So, Phyllis might be my mom. I mean, the chances are tiny, but...", "order": 43458, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 24, "scene": 15, "line": "But, probably not, I mean, it was a big year for babies. Porky's had come out.", "order": 43459, "speaker": "Phyllis Vance"},
  {"season": 7, "episode": 24, "scene": 15, "line": "Yeah. I'm sure I was just another Porky's baby.", "order": 43460, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 24, "scene": 15, "line": "Mm.", "order": 43461, "speaker": "Phyllis Vance"},
  {"season": 7, "episode": 24, "scene": 15, "line": "But why not find out.", "order": 43462, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 24, "scene": 15, "line": "Yeah.", "order": 43463, "speaker": "Phyllis Vance"},
  {"season": 7, "episode": 24, "scene": 16, "line": "[to Dwight, who is reading the want ads] Should you really be so blatant about that?", "order": 43464, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 24, "scene": 16, "line": "They won't make me manager, and I won't settle for anything less. I've gone about as far as I can here. That's obvious. Time to take my talents elsewhere.", "order": 43465, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 24, "scene": 16, "line": "Are you really going to apply for work at Scranton Breadworks?", "order": 43466, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 24, "scene": 16, "line": "Bread is the paper of the food industry. You write your sandwich on it.", "order": 43467, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 24, "scene": 17, "line": "Can you do any better on salary?", "order": 43468, "speaker": "Warren Buffett"},
  {"season": 7, "episode": 24, "scene": 17, "line": "Unfortunately, that range is set at corporate.", "order": 43469, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 24, "scene": 17, "line": "What about mileage when I use my car? I mean, gas ain't cheap, you know.", "order": 43470, "speaker": "Warren"},
  {"season": 7, "episode": 24, "scene": 17, "line": "Heh. We think that 25 cents a mile is pretty generous.", "order": 43471, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 24, "scene": 17, "line": "How about 27? And uh, when I make long-distance calls, will they be monitored or is it on the honor system?", "order": 43472, "speaker": "Warren"},
  {"season": 7, "episode": 24, "scene": 18, "line": "[on phone] Okay. [hangs up, smiling] How odd, a very unusual phone call from the Senator's office. The Senator wants me to have lunch with him at The Botanical Gardens.", "order": 43473, "speaker": "Angela Martin"},
  {"season": 7, "episode": 24, "scene": 18, "line": "The Botanical Gardens, Scranton's hidden gem. Don't eat any berries you don't recognize.", "order": 43474, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 24, "scene": 19, "line": "How will your experience selling refinery equipment translate to our smaller scale here?", "order": 43475, "speaker": "Toby Flenderson"},
  {"season": 7, "episode": 24, "scene": 19, "line": "You don't work in sales, do you.", "order": 43476, "speaker": "Robert California"},
  {"season": 7, "episode": 24, "scene": 19, "line": "Uh... Human Resources.", "order": 43477, "speaker": "Toby Flenderson"},
  {"season": 7, "episode": 24, "scene": 19, "line": "You see, I sit across from a man. I see his face. I see his eyes. Now, does it matter if he wants a hundred dollars of paper or a hundred million dollars of deep-sea drilling equipment? Don't be a fool. He wants respect. He wants love. He wants to be younger. He wants to be attractive. There is no such thing as a product. Don't ever think there is. There is only... sex. Everything... is sex. You understand that what I'm telling you is a universal truth,... Toby.", "order": 43478, "speaker": "Robert California"},
  {"season": 7, "episode": 24, "scene": 19, "line": "Yes.", "order": 43479, "speaker": "Toby Flenderson"},
  {"season": 7, "episode": 24, "scene": 19, "line": "Okay, I-I am, I'm almost a little concerned that you might be overqualified for the position. [Robert and Gabe chuckle at this, then Gabe stops chuckling] Do you, um, do you think that you are?", "order": 43480, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 24, "scene": 19, "line": "Do I look like someone who would waste my own time?", "order": 43481, "speaker": "Robert California"},
  {"season": 7, "episode": 24, "scene": 19, "line": "No. [Robert then nods his head at Jim]", "order": 43482, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 24, "scene": 19, "line": "Can you... [seems intimidated, clears throat] You are a man of great confidence. Could you speak a little more to that, and, what the role of confidence... would be... in a dialogue with a subordinate?", "order": 43483, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 24, "scene": 19, "line": "Will you be heard? Will you have a voice? Will I steamroll over you? Do you feel heard right now, Jim? Do you have a voice, right now? You can answer me.", "order": 43484, "speaker": "Robert California"},
  {"season": 7, "episode": 24, "scene": 19, "line": "Yes.", "order": 43485, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 24, "scene": 19, "line": "That was your choice, not mine. The fallacy is that it is up to the steamroller. It is up to the object... whether it will be flattened or not, and I can tell from the small interaction we've had already, you won't be flattened by anybody. Do you agree with me, Jim?", "order": 43486, "speaker": "Robert California"},
  {"season": 7, "episode": 24, "scene": 19, "line": "Yes.", "order": 43487, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 24, "scene": 19, "line": "[more emphatically than Jim] Yes. You do. [nods at the Search Committee, then looks over at the camera with a smirk on his face]", "order": 43488, "speaker": "Robert California"},
  {"season": 7, "episode": 24, "scene": 20, "line": "[Robert leaves the conference room and looks at the others in the office as he grins at the camera and leaves] He creeps me out. [Toby nods] But, I think he might be a genius.", "order": 43489, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 24, "scene": 21, "line": "[Jim points at Darryl to be the next interviewee] Good luck, Darryl.", "order": 43490, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 24, "scene": 21, "line": "[pats Andy on the back] Thank you.", "order": 43491, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 24, "scene": 22, "line": "Think about it! What other mammal, besides humans, drinks the milk of another mammal? I mean, you don't see a bear drinking raccoon milk.", "order": 43492, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 24, "scene": 22, "line": "[Jim and Gabe laugh, Toby smiles and nods] Oh.", "order": 43493, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 24, "scene": 22, "line": "So this is cool. I think we make a good fit. [stands up]", "order": 43494, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 24, "scene": 22, "line": "Oh? Uh... we haven't started the interview yet. Were you--were you joking?", "order": 43495, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 24, "scene": 22, "line": "Uh... [looks at camera] yes?! I was. [sits back down] Little joke!", "order": 43496, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 24, "scene": 22, "line": "So, how would you go about settling interpersonal conflicts within the office?", "order": 43497, "speaker": "Toby Flenderson"},
  {"season": 7, "episode": 24, "scene": 22, "line": "I thought that was your job.", "order": 43498, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 24, "scene": 22, "line": "Well, it's one of my strengths, but it's the manager's job.", "order": 43499, "speaker": "Toby Flenderson"},
  {"season": 7, "episode": 24, "scene": 22, "line": "Well, for instance, how did you deal with it when two warehouse guys got into a fight?", "order": 43500, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 24, "scene": 22, "line": "I'll answer that, Jim. I would use it as an opportunity to teach, uh, about actions... and consequences... of actions. [Jim and Gabe stare at Darryl]", "order": 43501, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 24, "scene": 22, "line": "[noticing Erin in the background leading a black man dressed in a suit through the office] Who's that?", "order": 43502, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 24, "scene": 22, "line": "[ignoring Darryl's question] So I think all we need is a resume, and we'll be good right?", "order": 43503, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 24, "scene": 22, "line": "I... I just thought you knew me.", "order": 43504, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 24, "scene": 22, "line": "Yeah, it's no big deal, just something that looks like that. [shows Darryl a resume] Cool? [goes to put the resume away]", "order": 43505, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 24, "scene": 22, "line": "Let me... [Jim shows him the resume again, Darryl looks at it a little longer this time] Oh. Cool. [Jim gives Darryl a thumbs-up, then looks at the camera as Darryl gets up to leave]", "order": 43506, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 24, "scene": 23, "line": "[at the cafe on the first floor] Did, uh, you just interview?", "order": 43507, "speaker": "Merv Bronte"},
  {"season": 7, "episode": 24, "scene": 23, "line": "Ohh, unfortunately, yes.", "order": 43508, "speaker": "Robert California"},
  {"season": 7, "episode": 24, "scene": 23, "line": "What do you mean?", "order": 43509, "speaker": "Merv"},
  {"season": 7, "episode": 24, "scene": 23, "line": "That business can't attract anyone. It's awful up there. Those people seem like they're in prison, waiting out life sentences in a... dying industry.", "order": 43510, "speaker": "Robert California"},
  {"season": 7, "episode": 24, "scene": 23, "line": "Place must be horrible. I don't know if I want this job. [gets on elevator]", "order": 43511, "speaker": "Merv"},
  {"season": 7, "episode": 24, "scene": 24, "line": "[in elevator] Well, if I get this job offer, and then I know that I'm gonna take it, and if I take it, I know that I'm never gonna quit, and then 25 years are gonna go by and... I'm gonna die here.", "order": 43512, "speaker": "Merv"},
  {"season": 7, "episode": 24, "scene": 25, "line": "What makes you feel qualified to judge a place after a mere interview? [Robert stares at Dwight] What are you doing..? [Dwight sits up abruptly] Stop trying to figure me out.", "order": 43513, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 24, "scene": 25, "line": "I just did.", "order": 43514, "speaker": "Robert California"},
  {"season": 7, "episode": 24, "scene": 25, "line": "You can't.", "order": 43515, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 24, "scene": 25, "line": "It's done.", "order": 43516, "speaker": "Robert California"},
  {"season": 7, "episode": 24, "scene": 25, "line": "No, it's not.", "order": 43517, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 24, "scene": 25, "line": "I know you now, your nature. I'm done. Not worth continuing. [at the same time, Dwight says: You don't know me! Anything about me! Get out of my head!]", "order": 43518, "speaker": "Robert California"},
  {"season": 7, "episode": 24, "scene": 25, "line": "Stop trying to figure me out. [Robert turns to ignore Dwight] Do you even know anything about paper? How it's made?", "order": 43519, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 24, "scene": 25, "line": "I saw an episode of how they make paper on Sesame Street. [nods at Dwight mockingly]", "order": 43520, "speaker": "Robert California"},
  {"season": 7, "episode": 24, "scene": 25, "line": "[standing up] Get out.", "order": 43521, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 24, "scene": 26, "line": "I'm gonna prevent inferior men from sullying my place of work with their weak, passionless leadership. Do you see my hat? No? That's because I just threw it in the ring.", "order": 43522, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 24, "scene": 27, "line": "You guys pay for relocation, though, right?", "order": 43523, "speaker": "Merv"},
  {"season": 7, "episode": 24, "scene": 27, "line": "Well, why would you need relocation, if you already live in Scranton?", "order": 43524, "speaker": "Toby Flenderson"},
  {"season": 7, "episode": 24, "scene": 27, "line": "Well, I'd want to move further away, you know? Just... I don't want any chance to run into my co-workers outside the office.", "order": 43525, "speaker": "Merv"},
  {"season": 7, "episode": 24, "scene": 27, "line": "Can I ask why you're leaving your current job?", "order": 43526, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 24, "scene": 27, "line": "[pulls out a tupperware from his briefcase and takes out a tupperware] I'm leaving my other job because they were all jerks, really. [opens tupperware] You know, all of 'em. You had your jerk-wads, and your jerk-offs, so, just between the wads and the offs, I just... I had to get outta there. [begins eating sandwich]", "order": 43527, "speaker": "Merv"},
  {"season": 7, "episode": 24, "scene": 27, "line": "Is this a bad time to be doing this?", "order": 43528, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 24, "scene": 27, "line": "I'm having a bad time. [chuckles]", "order": 43529, "speaker": "Merv"},
  {"season": 7, "episode": 24, "scene": 27, "line": "I'm sorry, if we did something to upset you, I'm sure it was inadvertent.", "order": 43530, "speaker": "Toby Flenderson"},
  {"season": 7, "episode": 24, "scene": 27, "line": "[continues to chew, slower and slower, appears confused] Wait.", "order": 43531, "speaker": "Merv"},
  {"season": 7, "episode": 24, "scene": 28, "line": "Oh, why did I believe that weirdo in the lobby? These are the nicest people I ever met.", "order": 43532, "speaker": "Merv"},
  {"season": 7, "episode": 24, "scene": 29, "line": "[limo driver lets Angela out of the limo at the office parking lot] Thank you.", "order": 43533, "speaker": "Angela Martin"},
  {"season": 7, "episode": 24, "scene": 30, "line": "[looking at an engagement ring on Angela's finger] It's gorgeous, Angela.", "order": 43534, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 24, "scene": 30, "line": "Yeah, I actually know about nice rings and it is gorgeous.", "order": 43535, "speaker": "Kelly Kapoor"},
  {"season": 7, "episode": 24, "scene": 30, "line": "[looks at ring, whispering] Wow...", "order": 43536, "speaker": "Meredith Palmer"},
  {"season": 7, "episode": 24, "scene": 30, "line": "It's a little flashy. I mean, what am I? Naomi Judd?", "order": 43537, "speaker": "Angela Martin"},
  {"season": 7, "episode": 24, "scene": 30, "line": "Tell us the freaking story!", "order": 43538, "speaker": "Meredith Palmer"},
  {"season": 7, "episode": 24, "scene": 30, "line": "Yeah! Tell us a story.", "order": 43539, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 24, "scene": 30, "line": "Okay. He took me to the replica of Monet's Japanese bridge, and then he put this flower behind my ear, which normally, I would hate, 'cause it's so civil rightsy. Then he got down on one knee, and he said, will you be a Senator's wife?", "order": 43540, "speaker": "Angela Martin"},
  {"season": 7, "episode": 24, "scene": 30, "line": "[laughing] Oh-ho-ho! He talked about himself in the third person?", "order": 43541, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 24, "scene": 30, "line": "Yes, Pam. Not everyone is as informal as you and Jim. [imitating Jim's voice] Oh, hey Pam, dude, whatever, wanna marry me? [Erin, Kelly, and Meredith laugh]", "order": 43542, "speaker": "Angela Martin"},
  {"season": 7, "episode": 24, "scene": 30, "line": "That's not accurate.", "order": 43543, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 24, "scene": 30, "line": "Then I saw flashes. Reporters were there. They always find us. And everyone was crying, even his aide. [camera pans and shows Oscar in disbelief]", "order": 43544, "speaker": "Angela Martin"},
  {"season": 7, "episode": 24, "scene": 31, "line": "Angela's engaged to a gay man. As a gay man, I'm horrified. As a friend of Angela's, horrified. As a lover of elegant weddings, [smiles] I'm a little excited. [expression becomes serious again] But overall, horrified.", "order": 43545, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 24, "scene": 32, "line": "Andy? You all set?", "order": 43546, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 24, "scene": 32, "line": "Okay, guys, it's time. It's pep talk time. Tell me what I need to hear.", "order": 43547, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 24, "scene": 32, "line": "Are you sure this is a good idea? I hate to see you disappointed.", "order": 43548, "speaker": "Phyllis Vance"},
  {"season": 7, "episode": 24, "scene": 32, "line": "[grunts] Augh.", "order": 43549, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 24, "scene": 33, "line": "I think Andy should be the boss. He's just so great. If I'm being objective, then Darryl, of course.", "order": 43550, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 24, "scene": 34, "line": "So, Andy, what improvements would you make as office manager?", "order": 43551, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 24, "scene": 34, "line": "For starters, I really think we can streamline communication around here... [gets cut off by Gabe]", "order": 43552, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 24, "scene": 34, "line": "Whoa! That's a very heavy accusation to level against Toby.", "order": 43553, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 24, "scene": 34, "line": "I wasn't acc-, accusing...", "order": 43554, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 24, "scene": 34, "line": "Well, Toby's in charge of Human Resources, that would include communication. I think that Toby's done a fine job.", "order": 43555, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 24, "scene": 34, "line": "Right! I really wasn't trying to insult any, anyone.", "order": 43556, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 24, "scene": 34, "line": "I didn't think you were.", "order": 43557, "speaker": "Toby Flenderson"},
  {"season": 7, "episode": 24, "scene": 35, "line": "I have two relationships with Andy. I have a personal relationship, and I have a professional relationship. Personally, yeah, I think he's a rat, and I think he's responsible for the demise of my relationship with Erin. Professionally,... he broke up the happiest couple in this office!", "order": 43558, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 24, "scene": 36, "line": "How many windows are there in New York City?", "order": 43559, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 24, "scene": 36, "line": "What?", "order": 43560, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 24, "scene": 36, "line": "Critical thinking. Common, on-the-spot question asked in an interview.", "order": 43561, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 24, "scene": 36, "line": "Okay. Let me think... are you counting car windows?", "order": 43562, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 24, "scene": 36, "line": "No... How far away is the Sun?", "order": 43563, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 24, "scene": 36, "line": "[happily] Uh, 93 million miles.", "order": 43564, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 24, "scene": 36, "line": "[to Gabe] Is it?", "order": 43565, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 24, "scene": 36, "line": "Yeah! And the diameter of the Sun is 870,000 miles, which makes it 109 times wider than the Earth, and... [Gabe looks visibily annoyed] 333,000 times heavier than the Earth,...", "order": 43566, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 24, "scene": 36, "line": "Shut up about the Sun! SHUT UP about the SUN! [slams fist, hurting his hand]", "order": 43567, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 24, "scene": 37, "line": "[alone in his car] I am unhappy with the confusing and, at times, confrontational nature of that meeting. I wanted it to go better. I WANTED IT TO GO BETTER!!", "order": 43568, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 24, "scene": 38, "line": "[on phone] Yes, there used to be a paper clip that would pop up and say 'Looks like you are writing a letter or resume. Would you like help? I believe his name was... [looks embarrassed at camera] Clippy.", "order": 43569, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 24, "scene": 39, "line": "[on phone] First of all, I want to thank you for your years of patronizing our company. I've got some bad news. We're going out of business. [covers phone, whispers to Pam] Saving face. [uncovers phone] Yep, yep. I understand that. Basically everything is falling apart here. Pam looks around the office and realizes only she has overheard this]", "order": 43570, "speaker": "Creed Bratton"},
  {"season": 7, "episode": 24, "scene": 40, "line": "How is this on me?", "order": 43571, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 24, "scene": 41, "line": "Hang up. [Creed hangs up] Corporate needs you to find the differences between this picture and this picture. [shows Creed two similar-looking photos of some building] Intel has told us there were at least seven.", "order": 43572, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 24, "scene": 41, "line": "[takes the pictures] Okay I already see one, gimme. Okay.", "order": 43573, "speaker": "Creed Bratton"},
  {"season": 7, "episode": 24, "scene": 42, "line": "They're the same picture.", "order": 43574, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 24, "scene": 43, "line": "Well, I manage my department, and I've been doing that for several years now. And, god, I've learned a lot of life lessons along the way.", "order": 43575, "speaker": "Kelly Kapoor"},
  {"season": 7, "episode": 24, "scene": 43, "line": "Your department's just you, right?", "order": 43576, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 24, "scene": 43, "line": "Yes, Jim, but I am not easy to manage.", "order": 43577, "speaker": "Kelly Kapoor"},
  {"season": 7, "episode": 24, "scene": 43, "line": "Great! [to Jim and Toby] Um, can we just... [does a 'wrap it up' motion with his pen]", "order": 43578, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 24, "scene": 43, "line": "What was that?", "order": 43579, "speaker": "Kelly Kapoor"},
  {"season": 7, "episode": 24, "scene": 43, "line": "We just have a lot of serious candidates to get through today, so... [nods]", "order": 43580, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 24, "scene": 43, "line": "[getting upset] Am I not a serious candidate?", "order": 43581, "speaker": "Kelly Kapoor"},
  {"season": 7, "episode": 24, "scene": 43, "line": "What do you want me to say? I mean, there's a line of qualified people out there. We have a video CV from England. [to Jim and Toby] Are we all just gonna pretend to-- okay. [to Kelly] Um, what are your weaknesses?", "order": 43582, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 24, "scene": 43, "line": "I don't have any, assh***.", "order": 43583, "speaker": "Kelly Kapoor"},
  {"season": 7, "episode": 24, "scene": 43, "line": "[comes in, slapping some papers on the table] I want an interview.", "order": 43584, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 24, "scene": 44, "line": "[walking with Jim in the parking lot] How's the family?", "order": 43585, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 24, "scene": 44, "line": "Good!", "order": 43586, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 24, "scene": 44, "line": "Good. They good?", "order": 43587, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 24, "scene": 44, "line": "Yeah.", "order": 43588, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 24, "scene": 44, "line": "What's your daughter's name again? Peepee?", "order": 43589, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 24, "scene": 44, "line": "Peepa.", "order": 43590, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 24, "scene": 44, "line": "Peepa, how is she?", "order": 43591, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 24, "scene": 44, "line": "Great.", "order": 43592, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 24, "scene": 44, "line": "Great! Oh, that's great... We never were very good at small talk, were we, Jim?", "order": 43593, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 24, "scene": 44, "line": "No.", "order": 43594, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 24, "scene": 44, "line": "Now, listen,...", "order": 43595, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 24, "scene": 44, "line": "You're not getting an interview, Dwight. The whole point of this Search Committee process is to prevent hiring someone like you ever again.", "order": 43596, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 24, "scene": 44, "line": "Okay, I know why you're saying that, Jim, I really do, but think of it this way. The hand that reaches from the grave to grip your throat [motions to grip Jim's throat] is the strong hand you want on the wheel.", "order": 43597, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 24, "scene": 44, "line": "[pulls Dwight's arm down] Okay. That's vivid.", "order": 43598, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 24, "scene": 44, "line": "I'm going to make you an offer. Okay? You even do so little as grant me an interview, I will guarantee you your dream work life. Okay? You roll in at 10 a.m. to your own private reserved parking spot, pick up your daily free coffee from Dwight's Caffeine Corner, unlimited sex breaks for you and Pam...", "order": 43599, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 24, "scene": 44, "line": "Yikes.", "order": 43600, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 24, "scene": 44, "line": "Erin will eat garbage for your entertainment.", "order": 43601, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 24, "scene": 44, "line": "Well, you know how I like taking bribes. [both smile]", "order": 43602, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 24, "scene": 44, "line": "Ha ha! [pats Jim on the back]", "order": 43603, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 24, "scene": 44, "line": "Unfortunately, Jo also took the job away from you because she doesn't trust your judgment. So how would that look for me, if I recommended you?", "order": 43604, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 24, "scene": 44, "line": "Fine. I'll do it without you, but you'll regret this.", "order": 43605, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 24, "scene": 44, "line": "Okay.", "order": 43606, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 24, "scene": 44, "line": "Let me get that for you. [runs ahead to open the door for Jim]", "order": 43607, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 24, "scene": 44, "line": "Thank you.", "order": 43608, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 24, "scene": 44, "line": "Okay. [looks ashamed]", "order": 43609, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 24, "scene": 45, "line": "Pam... I think Robert is gay.", "order": 43610, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 24, "scene": 45, "line": "The Senator? [Oscar nods] He was married before, and he has a kid.", "order": 43611, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 24, "scene": 45, "line": "[whispers] So!?", "order": 43612, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 24, "scene": 45, "line": "And don't say that thing about how lots of gay guys have kids.", "order": 43613, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 24, "scene": 45, "line": "I have a very strong suspicion.", "order": 43614, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 24, "scene": 45, "line": "Did you see him at a bathhouse?", "order": 43615, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 24, "scene": 45, "line": "What bathhouse?", "order": 43616, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 24, "scene": 45, "line": "The windowless building by the Baskin Robbins.", "order": 43617, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 24, "scene": 45, "line": "[confused, whispers] What?", "order": 43618, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 24, "scene": 45, "line": "Forget it. I'm never gonna know what goes on there.", "order": 43619, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 24, "scene": 46, "line": "My friend at the Scranton Blade is totally plugged into these matters.", "order": 43620, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 24, "scene": 46, "line": "You're in the gay mafia.", "order": 43621, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 24, "scene": 46, "line": "You're thinking of another group. Much wealthier, much older. [whispers, smiles] You sound ignorant.", "order": 43622, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 24, "scene": 46, "line": "You guys, um, talking Senator?", "order": 43623, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 24, "scene": 46, "line": "No.", "order": 43624, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 24, "scene": 46, "line": "No. Why would we be talking about the Senator?", "order": 43625, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 24, "scene": 46, "line": "Cause he's totally gay? [walks away]", "order": 43626, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 24, "scene": 47, "line": "Um, how do I know that Robert is gay? He 'liked' my facebook photos at 3 o'clock in the morning.", "order": 43627, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 24, "scene": 48, "line": "[on video resume on a laptop] Name? David Brent. Occupation? Inspirer. Status? None of your business. Young, free, and single, though. Thanks for asking. Hear you're looking for a new boss. Yeah? Someone to tell a bunch of discontented, underencouraged drones what to do every day. Is that it? Oh. Our out-of-touch powers that be? Want me to fire them, if they don't see things your way? Then I ain't that dude. Bye-bye, baby, bye-bye. Get some other corporate suit to lay down the law. What? You've changed your mind? You're now looking for a leader of men? Ipso facto, women too. [points at self] When do I start? Yeah. [Jim looks uninterested in this candidate]", "order": 43628, "speaker": "David Brent"},
  {"season": 7, "episode": 24, "scene": 49, "line": "Do you know what it took to get Bob to notice me? I waited in his office every morning wearing nothing but kitty-cat ears. I did that every day for two weeks. And on the tenth day he walked in, he was naked too, except a dog nose. Guess what we did then?", "order": 43629, "speaker": "Phyllis Vance"},
  {"season": 7, "episode": 24, "scene": 49, "line": "[trying to cut off Phyllis] I... I don't think I can do that.", "order": 43630, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 24, "scene": 49, "line": "Bestiality. Yeah.", "order": 43631, "speaker": "Phyllis Vance"},
  {"season": 7, "episode": 24, "scene": 49, "line": "Well, that's not my personality.", "order": 43632, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 24, "scene": 49, "line": "If my daughter were asking me... [both giggle]", "order": 43633, "speaker": "Phyllis Vance"},
  {"season": 7, "episode": 24, "scene": 49, "line": "Yes?", "order": 43634, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 24, "scene": 49, "line": "I would say, if you want someone, if you really want them,... go get them.", "order": 43635, "speaker": "Phyllis Vance"},
  {"season": 7, "episode": 24, "scene": 50, "line": "I do really want him.", "order": 43636, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 24, "scene": 51, "line": "So, uh... here you go. [gives resume to Jim]", "order": 43637, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 24, "scene": 51, "line": "Great.", "order": 43638, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 24, "scene": 51, "line": "Hope it's all right.", "order": 43639, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 24, "scene": 51, "line": "I'm sure it's fine.", "order": 43640, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 24, "scene": 51, "line": "The guy who was in here earlier, interviewed after me, how'd he do?", "order": 43641, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 24, "scene": 51, "line": "Howard Cline?", "order": 43642, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 24, "scene": 51, "line": "Ehh, that's not who I'm talking about.", "order": 43643, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 24, "scene": 51, "line": "[checks sheet] Oh... Deshaun Williams.", "order": 43644, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 24, "scene": 51, "line": "Yes. How did he do?", "order": 43645, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 24, "scene": 51, "line": "He's an amazing man. He's a Rhodes Scholar. He invented an app that invents apps. Fantastic kisser...", "order": 43646, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 24, "scene": 51, "line": "Come on man. I'm being serious. Don't joke.", "order": 43647, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 24, "scene": 51, "line": "Darryl! We all know you. Your interview is not nearly as important as the other guys. [Darryl taps a couple times on Jim's desk and walks away]", "order": 43648, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 24, "scene": 52, "line": "First, I'll take down the cubicle walls.", "order": 43649, "speaker": "Nellie Bertram Bertram"},
  {"season": 7, "episode": 24, "scene": 52, "line": "But there aren't...", "order": 43650, "speaker": "Toby Flenderson"},
  {"season": 7, "episode": 24, "scene": 52, "line": "Symbol of transparency. There'd be no titles. Everyone would have the same job. Same goes for me. I'd take your job, but I'd reject the title.", "order": 43651, "speaker": "Nellie Bertram"},
  {"season": 7, "episode": 24, "scene": 52, "line": "A little un-specific.", "order": 43652, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 24, "scene": 52, "line": "Everyone would be known for their accomplishments.", "order": 43653, "speaker": "Nellie Bertram"},
  {"season": 7, "episode": 24, "scene": 52, "line": "That's very interesting. Um, I feel like there might be a conflict there, and if a conflict did arise, how would that be dealt with?", "order": 43654, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 24, "scene": 52, "line": "Ooh? Yeah. Mm. Scratch everything from before. I tell you what I'd do. Go the other way. More cubicles. More division. Everyone is somebody's boss, and that person can fire the person below them. [overlapping comments by the Search Committee] At least once a month, the lowest performing person... [does a cut-throat hand gesture] bye bye!", "order": 43655, "speaker": "Nellie Bertram"},
  {"season": 7, "episode": 24, "scene": 52, "line": "How would you compare, like, an accountant and HR?", "order": 43656, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 24, "scene": 52, "line": "Well, I'll tell you how. Shall I? I'll tell you how.", "order": 43657, "speaker": "Nellie Bertram"},
  {"season": 7, "episode": 24, "scene": 52, "line": "Okay.", "order": 43658, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 24, "scene": 52, "line": "By splitting the difference. Just... just, somewhere in the middle.", "order": 43659, "speaker": "Nellie Bertram"},
  {"season": 7, "episode": 24, "scene": 52, "line": "I think that's probably all we need to hear from...", "order": 43660, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 24, "scene": 52, "line": "Zen office. Hmm? Thought of that? That's what I'd do. Everyone takes their shoes off, before they come in. Okay. There'd be no desks. You just sit on the floor.", "order": 43661, "speaker": "Nellie Bertram"},
  {"season": 7, "episode": 24, "scene": 52, "line": "That's very...", "order": 43662, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 24, "scene": 52, "line": "You've got a Thai woman, out in the back. Sockee! Sockee!", "order": 43663, "speaker": "Nellie Bertram"},
  {"season": 7, "episode": 24, "scene": 52, "line": "Okay, that's not gonna be...", "order": 43664, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 24, "scene": 52, "line": "Sockee!... is her name. Okay? Shes administering massage, all right, if you need it. If you don't, whatever, just talk to her! She's a person! Either way, 50 minutes of that and you...you are cracking to go. [smiles]", "order": 43665, "speaker": "Nellie Bertram"},
  {"season": 7, "episode": 24, "scene": 53, "line": "Is there a front runner?", "order": 43666, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 24, "scene": 53, "line": "You know what? They all just sort of blend together after a while.", "order": 43667, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 24, "scene": 53, "line": "Well, there must be someone who stands out.", "order": 43668, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 24, "scene": 53, "line": "No, not at all. In fact, I'm not even taking it seriously. I think at the end of the day, I'm just gonna pick a name out of the hat.", "order": 43669, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 24, "scene": 53, "line": "Well...", "order": 43670, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 24, "scene": 53, "line": "The hell you will! I worked for the last boss for 15 years. According to my doctor, I don't have another 15 years if I want to keep up the same dietary and sexual lifestyle, which I intend to.", "order": 43671, "speaker": "Stanley Hudson"},
  {"season": 7, "episode": 24, "scene": 53, "line": "[in mock seriousness] Oh no, Stanley... you'll live forever...", "order": 43672, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 24, "scene": 53, "line": "My next boss will be my last boss. He'll be at my funeral. So I would appreciate it if you would take this seriously. [dunks his half-eaten donut in Jim's coffee, takes it back out, and leaves]", "order": 43673, "speaker": "Stanley Hudson"},
  {"season": 7, "episode": 24, "scene": 53, "line": "Uh--", "order": 43674, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 24, "scene": 53, "line": "You pick a crappy boss, you're responsible for my crappy life. [leaves]", "order": 43675, "speaker": "Meredith Palmer"},
  {"season": 7, "episode": 24, "scene": 53, "line": "Okay, everybody, I was just making a joke. I am taking it seriously, I promise.", "order": 43676, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 24, "scene": 53, "line": "Are you? [leaves]", "order": 43677, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 24, "scene": 53, "line": "Little advice? Take a day off from the whole Jim schtick. Try caring about something. You might like how it feels... James. [closes his office door, leaving Jim alone in the break room]", "order": 43678, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 24, "scene": 54, "line": "[walks in with her dogs] Gabe, honey! Oh, lord. Take all this. [gives the dog leashes and multiple bags to Gabe]", "order": 43679, "speaker": "Jo Bennett"},
  {"season": 7, "episode": 24, "scene": 54, "line": "All right.", "order": 43680, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 24, "scene": 54, "line": "Here. Jim! Did you hear Stern this morning?", "order": 43681, "speaker": "Jo Bennett"},
  {"season": 7, "episode": 24, "scene": 54, "line": "No, was it good?", "order": 43682, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 24, "scene": 54, "line": "Ohh, Robin was good. She's always good. Keeps him on his toes. Its Howard's show though. [picks up resume from Jim's desk] Whose is this? Darryl Philbin. Ohh. Very nice to see a familiar face on top. Ah... a little long aren't we? Four pages? Is this the same Darryl Philbin who's had two jobs in ten years at one company?", "order": 43683, "speaker": "Jo Bennett"},
  {"season": 7, "episode": 24, "scene": 54, "line": "Yes, thanks to you, who promoted me, after we, uh, interfaced.", "order": 43684, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 24, "scene": 54, "line": "Coordinated and implemented receipt storage and delivery of over 2.5 billion units of inventory. 2.5 billion, Darryl? 2.5 billion units of what?", "order": 43685, "speaker": "Jo Bennett"},
  {"season": 7, "episode": 24, "scene": 54, "line": "Paper material, ma'am.", "order": 43686, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 24, "scene": 54, "line": "Paper material?", "order": 43687, "speaker": "Jo Bennett"},
  {"season": 7, "episode": 24, "scene": 54, "line": "[softly] Pieces of paper. [Jo rolls her eyes]", "order": 43688, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 24, "scene": 54, "line": "[clears throat] Jo, may I speak to you for a second?", "order": 43689, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 24, "scene": 54, "line": "Or what? You gonna shoot me?", "order": 43690, "speaker": "Jo Bennett"},
  {"season": 7, "episode": 24, "scene": 54, "line": "Ha ha ha! Ha ha ha! Ohh, I enjoy laughing at my mistakes, because I've learned so much from them. I'd like to be interviewed for the position.", "order": 43691, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 24, "scene": 54, "line": "I'll interview you right now.", "order": 43692, "speaker": "Jo Bennett"},
  {"season": 7, "episode": 24, "scene": 54, "line": "Okay.", "order": 43693, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 24, "scene": 54, "line": "Question 1. Ever shot a gun in the office?", "order": 43694, "speaker": "Jo Bennett"},
  {"season": 7, "episode": 24, "scene": 54, "line": "It's complicated.", "order": 43695, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 24, "scene": 54, "line": "Yeah, but see... it's not.", "order": 43696, "speaker": "Jo Bennett"},
  {"season": 7, "episode": 24, "scene": 55, "line": "I don't think we should tell her. Angela went a whole day without telling me I had lettuce in my teeth. Screw her.", "order": 43697, "speaker": "Phyllis Vance"},
  {"season": 7, "episode": 24, "scene": 55, "line": "I'm a woman. I would want to know.", "order": 43698, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 24, "scene": 55, "line": "Yeah, you gotta know.", "order": 43699, "speaker": "Meredith Palmer"},
  {"season": 7, "episode": 24, "scene": 55, "line": "Okay, but if Angela can get a gay man to marry her, maybe I could get a lesbian to marry me? Huh? Huh? That's hot.", "order": 43700, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 24, "scene": 55, "line": "You have met a lesbian in real life, right?", "order": 43701, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 24, "scene": 55, "line": "You know, this is probably is her last chance at a family.", "order": 43702, "speaker": "Phyllis Vance"},
  {"season": 7, "episode": 24, "scene": 55, "line": "She does seem happy.", "order": 43703, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 24, "scene": 55, "line": "You're right. You're right. She seems happy. We don't tell her.", "order": 43704, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 24, "scene": 55, "line": "We don't tell her.", "order": 43705, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 24, "scene": 55, "line": "Okay.", "order": 43706, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 24, "scene": 56, "line": "Excuse me, Ms. Bennett, may I have a word?", "order": 43707, "speaker": "Kelly Kapoor"},
  {"season": 7, "episode": 24, "scene": 56, "line": "Okay.", "order": 43708, "speaker": "Jo Bennett"},
  {"season": 7, "episode": 24, "scene": 56, "line": "Uh,... can it be in private? [to Gabe] Don't worry, it's not about you. [Gabe smiles and leaves] As Minority Executive, I think it's my responsibility to let you know that Gabe is gross. I don't think it's professional that he was sleeping with the receptionist, and then when Erin dumped him, so that she could be with Andy, he became a total crazy stalker psycho.", "order": 43709, "speaker": "Kelly Kapoor"},
  {"season": 7, "episode": 24, "scene": 56, "line": "Welcome to Scranton, Jo. Land of a thousand problems only you can fix. [Jo's phone starts vibrating, she picks it up and sees Dwight has texted her 'Dwight's ability as a manager has lead him to higher levels of proffesional success,'] Oh for god's sake. He's texting me his resume one line at a time. These are costing me ten cents a piece, you jackass! I'm roaming!", "order": 43710, "speaker": "Jo Bennett"},
  {"season": 7, "episode": 24, "scene": 57, "line": "[to Jordan] Get me our biggest client on the phone, right this instant. [closes his office door]", "order": 43711, "speaker": "Creed Bratton"},
  {"season": 7, "episode": 24, "scene": 57, "line": "Who is our biggest client?", "order": 43712, "speaker": "Jordan"},
  {"season": 7, "episode": 24, "scene": 57, "line": "Uh, just put him through to me.", "order": 43713, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 24, "scene": 57, "line": "[laughs] Okay.", "order": 43714, "speaker": "Jordan"},
  {"season": 7, "episode": 24, "scene": 57, "line": "Hello! This is... the client.", "order": 43715, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 24, "scene": 57, "line": "It's Creed. FYI, I'm starting my own paper company, looking to poach some chumps, you in?", "order": 43716, "speaker": "Creed Bratton"},
  {"season": 7, "episode": 24, "scene": 57, "line": "Yes.", "order": 43717, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 24, "scene": 57, "line": "Hehehe, cool. Let's keep this on the QT, okay? I uh, I don't want you to be a dead mamma jamma.", "order": 43718, "speaker": "Creed Bratton"},
  {"season": 7, "episode": 24, "scene": 57, "line": "Great.", "order": 43719, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 24, "scene": 57, "line": "All right, thank you, bye-bye.", "order": 43720, "speaker": "Creed Bratton"},
  {"season": 7, "episode": 24, "scene": 57, "line": "Thank you, bye-bye.", "order": 43721, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 24, "scene": 58, "line": "[knocks and enters] Jo, you have one more candidate. He's a burn victim.", "order": 43722, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 24, "scene": 58, "line": "Huh?", "order": 43723, "speaker": "Jo Bennett"},
  {"season": 7, "episode": 24, "scene": 58, "line": "[motions toward her face] He's all messed up. I can tell him to get lost if you want.", "order": 43724, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 24, "scene": 58, "line": "No, send him in. [to Search Committee] Who is this?", "order": 43725, "speaker": "Jo Bennett"},
  {"season": 7, "episode": 24, "scene": 58, "line": "I have no idea.", "order": 43726, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 24, "scene": 58, "line": "[seeing Dwight wearing sunglasses and whose face and hands are completely covered in gauze] Oh, I know this guy. [Dwight awkwardly hands copies of his resume to the Search Committee] Hello! Mr. 'Soo-ven-yay.' Mr. 'Jacques Soo-ven-yay.' Nice to meet you. It says here you're French. [Dwight nods] So you worked at your last job for 15 years as Assistant to the Regional Manager.", "order": 43727, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 24, "scene": 58, "line": "[mumbling through the gauze] Assistant Regional Manager.", "order": 43728, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 24, "scene": 58, "line": "Assistant to the Regional Manager.", "order": 43729, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 24, "scene": 58, "line": "[again] Assistant Regional Manager.", "order": 43730, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 24, "scene": 58, "line": "What is it?", "order": 43731, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 24, "scene": 58, "line": "[in a cheesy French accent] Assistant Regional Manager.", "order": 43732, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 24, "scene": 58, "line": "Oh, that's my mistake, sorry about that. The last paper company you worked for burned to the ground? And all because they wouldn't hire a manager who lived and breathed paper? That's a travesty.", "order": 43733, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 24, "scene": 58, "line": "I want to talk to Dwight Schrute for a second. I want to ask him a question. [Gabe gets up to leave] If he isn't here in sixty seconds...", "order": 43734, "speaker": "Jo Bennett"},
  {"season": 7, "episode": 24, "scene": 58, "line": "Uhh. Wait. Wait! No. Stop! Stop. Jo... [takes off sunglasses and reveals his face] It's me. I'm Dwight.", "order": 43735, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 24, "scene": 58, "line": "[looks extremely confused] No. No! Wait, but, I mean, you're Dwight, and then... he's the... [trails off]", "order": 43736, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 24, "scene": 58, "line": "[whispers] Yeah.", "order": 43737, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 24, "scene": 58, "line": "[looks astonished] Ohhhh!", "order": 43738, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 24, "scene": 58, "line": "Very unprofessional, 'Jacques.' Or, should I say, Dwight.", "order": 43739, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 24, "scene": 58, "line": "Let's just say I had hired this Jack Souvenir. Then what?", "order": 43740, "speaker": "Jo Bennett"},
  {"season": 7, "episode": 24, "scene": 58, "line": "I would have dressed this way every day, legally changed my name, learned French sign language, shown up, and been the best damn branch manager you'd ever seen!", "order": 43741, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 24, "scene": 58, "line": "All that for this job.", "order": 43742, "speaker": "Jo Bennett"},
  {"season": 7, "episode": 24, "scene": 58, "line": "Yes.", "order": 43743, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 24, "scene": 58, "line": "That's f***ing crazy. [Jo and Dwight both smile] Get outta here. [Dwight leaves] What a nutjob.", "order": 43744, "speaker": "Jo Bennett"},
  {"season": 7, "episode": 24, "scene": 59, "line": "This guy was good. Although, he did keep saying that he needed two weeks off right at the beginning for a trip to the finger lakes. Seriously. Every five minutes, he was like, 'just making sure the finger lakes thing is clear.'", "order": 43745, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 24, "scene": 59, "line": "Okay, it's fun to talk about the rejects, but uh, who's got ya excited?", "order": 43746, "speaker": "Jo Bennett"},
  {"season": 7, "episode": 24, "scene": 59, "line": "Finger Lakes guy is good. Darryl is also very good.", "order": 43747, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 24, "scene": 59, "line": "Who's this fella? Went to Cornell. What's wrong with him?", "order": 43748, "speaker": "Jo Bennett"},
  {"season": 7, "episode": 24, "scene": 59, "line": "How much time do you have? Sales ability? None. Integrity? See sales ability. [makes a 'zero' hand gesture]", "order": 43749, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 24, "scene": 59, "line": "And that's your unbiased opinion.", "order": 43750, "speaker": "Jo Bennett"},
  {"season": 7, "episode": 24, "scene": 59, "line": "Yes, it is.", "order": 43751, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 24, "scene": 59, "line": "So it's not relevant that he took the receptionist away from you?", "order": 43752, "speaker": "Jo Bennett"},
  {"season": 7, "episode": 24, "scene": 59, "line": "Oh, Jo. Jo, I'm disappointed in you. Some people let personal things into the workpl-. Is she with him? Because I thought that she was...", "order": 43753, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 24, "scene": 59, "line": "Gabe...ugh... you got all close to these people. Got involved in their lives. Let's get you back to Florida. We'll figure out something for you.", "order": 43754, "speaker": "Jo Bennett"},
  {"season": 7, "episode": 24, "scene": 59, "line": "That sounds like a promotion!", "order": 43755, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 24, "scene": 59, "line": "It's not. Let's get Kelly in here to take his place.", "order": 43756, "speaker": "Jo Bennett"},
  {"season": 7, "episode": 24, "scene": 59, "line": "Um... why Kelly?", "order": 43757, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 24, "scene": 59, "line": "Cause Gabe's tall and weak. She's short and strong. I'm doing an opposites thing.", "order": 43758, "speaker": "Jo Bennett"},
  {"season": 7, "episode": 24, "scene": 59, "line": "Okay.", "order": 43759, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 24, "scene": 60, "line": "How'd my girl Nellie do?", "order": 43760, "speaker": "Jo Bennett"},
  {"season": 7, "episode": 24, "scene": 60, "line": "Oh, I didn't know you knew her?", "order": 43761, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 24, "scene": 60, "line": "She didn't mention it?", "order": 43762, "speaker": "Jo Bennett"},
  {"season": 7, "episode": 24, "scene": 60, "line": "No.", "order": 43763, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 24, "scene": 60, "line": "Integrity move. I like it.", "order": 43764, "speaker": "Jo Bennett"},
  {"season": 7, "episode": 24, "scene": 60, "line": "You know, she also gave me a reason to think that maybe she wasn't a good fit.", "order": 43765, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 24, "scene": 60, "line": "Well, I'm not saying you must hire her. If you find someone who's clearly a fit, then fine. Just make sure they fit real good. No more manager turnover. Don't mess this up, Jim. And give Dwight an interview. I like a little bit of crazy.", "order": 43766, "speaker": "Jo Bennett"},
  {"season": 7, "episode": 24, "scene": 61, "line": "[hiding under desk, raises a sock puppet up, talking in high-pitched voice] Ahh!", "order": 43767, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 24, "scene": 61, "line": "Erin, what are you doing?", "order": 43768, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 24, "scene": 61, "line": "I've been turned into a puppet!", "order": 43769, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 24, "scene": 61, "line": "Okay. [walks away]", "order": 43770, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 24, "scene": 61, "line": "[giggling, walks to Erin's desk] Look at the puppet! Hi, puppet! Who are you?", "order": 43771, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 24, "scene": 61, "line": "I went to drop off the FedEx forms and an evil witch named Angela turned me into a puppet!", "order": 43772, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 24, "scene": 61, "line": "[giggles] Yeah. Low blow, puppet.", "order": 43773, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 24, "scene": 61, "line": "And there's only one thing that can change me back into a real girl.", "order": 43774, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 24, "scene": 62, "line": "It's good. It's just that I wish the puppets would talk more about the alphabet. Not for me... but, if any kids are watching... A, B, and so forth. You know,... M-N-L-O, P... F...", "order": 43775, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 24, "scene": 63, "line": "[as sock puppet] I need the most special thing in the office.", "order": 43776, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 24, "scene": 63, "line": "Silence?", "order": 43777, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 24, "scene": 63, "line": "A date with the best salesman... Andy Bernard! [Andy walks over to Erin's desk]", "order": 43778, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 24, "scene": 63, "line": "Hey, Erin.", "order": 43779, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 24, "scene": 63, "line": "[gets up] Oh.", "order": 43780, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 24, "scene": 63, "line": "Where'd you learn how to puppet like that?", "order": 43781, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 24, "scene": 63, "line": "[shrugs] I've done it all my life.", "order": 43782, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 24, "scene": 63, "line": "Listen, I'm really flattered, but I don't think we should.", "order": 43783, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 24, "scene": 64, "line": "Erin's my best friend in this office, hands down, but... when she asked me out, I just didn't have that feeling, you know? [clip jumps ahead] Aren't there some things that you really want to like, but you just can't... seem to like it, like, Mad Men... or football... [clips jumps ahead] Let's not forget, Erin chose Gabe over me. That happened. I'm not going to apologize for getting over her, okay? I'm sorry. [clip jumps ahead] I would go for someone who's more... she's great, though.", "order": 43784, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 24, "scene": 65, "line": "[loudly] Stanley, I won't be able to invite everyone to the wedding, because we want to keep it to 350.", "order": 43785, "speaker": "Angela Martin"},
  {"season": 7, "episode": 24, "scene": 65, "line": "I'll get over it.", "order": 43786, "speaker": "Stanley Hudson"},
  {"season": 7, "episode": 24, "scene": 65, "line": "I mean, we just have to see how many Senators and members of Congress want to attend, before we can open it up to regular people. Pam, you know how you and Jim did your ironic wedding? [Pam looks confused] Do you still have the plans for the dream wedding that you couldn't afford?", "order": 43787, "speaker": "Angela Martin"},
  {"season": 7, "episode": 24, "scene": 65, "line": "That was our dream wedding.", "order": 43788, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 24, "scene": 65, "line": "Niagara Falls? Pregnant? That was your dream? Pork medallions?", "order": 43789, "speaker": "Angela Martin"},
  {"season": 7, "episode": 24, "scene": 65, "line": "[looks over at Oscar, who is quietly watching this happen] I hope... you have a very beautiful wedding, Angela.", "order": 43790, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 24, "scene": 65, "line": "Hmm.", "order": 43791, "speaker": "Angela Martin"},
  {"season": 7, "episode": 24, "scene": 66, "line": "All right. Name.", "order": 43792, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 24, "scene": 66, "line": "Dwight Schrute.", "order": 43793, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 24, "scene": 66, "line": "Thank you, Mr. Schnoot. [closes his binder] We will let you know.", "order": 43794, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 24, "scene": 66, "line": "You have to interview me...", "order": 43795, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 24, "scene": 66, "line": "I just did. The answer to that one question told me everything I need to know.", "order": 43796, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 24, "scene": 66, "line": "I demand more questions!", "order": 43797, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 24, "scene": 66, "line": "[ignoring Dwight] All right, guys, good day, a lot of candidates. Let's discuss.", "order": 43798, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 24, "scene": 66, "line": "Okay! If you're not going to interview me, then I'll do it.", "order": 43799, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 24, "scene": 66, "line": "Yes.", "order": 43800, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 24, "scene": 66, "line": "What will be your first priority? I will have seven first priorities. Safety, profits, fostering a community of self-reliance and entrepreneurship, listening, respect for human life, bolstering our public image, and... [chuckles] getting everyone home on time. Dwight, let me be frank. In an accident that no one can blame you for, an antique gun was discharged while you were acting manager. How are we ever to trust you again? [whispering] That's a great question. [Jim nods] I am going to institute a strict no-firearms policy for this office that extends to myself as well. Wow. All of my concerns are disappearing.", "order": 43801, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 24, "scene": 66, "line": "Thank you, Dwight.", "order": 43802, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 24, "scene": 66, "line": "Thank you. [clears throat and gets up] You'll be hearing from us shortly, Mr. Schrute, and I think you're gonna like the call you're going to receive. [smiles] Oh, come on. I'm just happy that I got this meeting. [leaves]", "order": 43803, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 24, "scene": 66, "line": "Well, that was quick.", "order": 43804, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 24, "scene": 66, "line": "[sighs] Very, very interesting. And you know what? I'm impressed.", "order": 43805, "speaker": "Kelly Kapoor"},
  {"season": 7, "episode": 24, "scene": 66, "line": "He's not a real candidate.", "order": 43806, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 24, "scene": 66, "line": "I don't know, Jim, 'cause, makes me think about something my grandfather used to say, which is that, sometimes, the hand that jumps out of the grave and grabs you around the throat; that is the hand that you want on the wheel.", "order": 43807, "speaker": "Kelly Kapoor"},
  {"season": 7, "episode": 24, "scene": 66, "line": "You took the deal.", "order": 43808, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 24, "scene": 66, "line": "Yeah, it was a great deal.", "order": 43809, "speaker": "Kelly Kapoor"},
  {"season": 7, "episode": 24, "scene": 66, "line": "That's not okay.", "order": 43810, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 24, "scene": 66, "line": "I don't know. Dwight seems like a great leader to me, and I look forward to the personal perks that he promised me privately. What do you think, Toby?", "order": 43811, "speaker": "Kelly Kapoor"},
  {"season": 7, "episode": 24, "scene": 66, "line": "Well, we could try him out for a little while... [Jim drops his pen, looks exasperated] and if it doesn't work out, you know, maybe one of us could, you know, step in...", "order": 43812, "speaker": "Toby Flenderson"},
  {"season": 7, "episode": 24, "scene": 66, "line": "What is happening right now?", "order": 43813, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 24, "scene": 67, "line": "Is it true that you're making Dwight the manager?", "order": 43814, "speaker": "Phyllis Vance"},
  {"season": 7, "episode": 24, "scene": 67, "line": "No, why would you think that?", "order": 43815, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 24, "scene": 67, "line": "He and Kelly said, then they pre-fired me.", "order": 43816, "speaker": "Phyllis Vance"},
  {"season": 7, "episode": 24, "scene": 67, "line": "Okay. [walks out of conference room] Okay, guys, just so you all know, no decision has been made, and Dwight is definitely not the boss.", "order": 43817, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 24, "scene": 67, "line": "That is correct. Actually, Dwight is not the manager yet.", "order": 43818, "speaker": "Kelly Kapoor"},
  {"season": 7, "episode": 24, "scene": 67, "line": "No, no, no. Not ever, 'cause that's not gonna happen.", "order": 43819, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 24, "scene": 67, "line": "Well, it's not entirely up to you, is it? Seems to me like someone's getting a little power-mad.", "order": 43820, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 24, "scene": 67, "line": "Am I the only one who remembers what he did when he was in charge? I feel like I'm going a little bit crazy.", "order": 43821, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 24, "scene": 67, "line": "Tuna... You're completely sane.", "order": 43822, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 24, "scene": 67, "line": "Thank you.", "order": 43823, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 24, "scene": 67, "line": "Who do you like for the job? Is there anyone who maybe was a little underwhelming at first, but now seems like a safe, if not slightly unexciting, choice?", "order": 43824, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 24, "scene": 67, "line": "Okay, seems like everybody has an opinion. So, who else? Anybody?", "order": 43825, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 24, "scene": 67, "line": "Do you mean it? Anybody? Are you sure?", "order": 43826, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 24, "scene": 67, "line": "[while sighing] I suppose I am.", "order": 43827, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 24, "scene": 67, "line": "Okay... well what do 'I' want in a manager?... let me see. [walks slowly across the office] what do 'I' want?...", "order": 43828, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 24, "scene": 67, "line": "I don't think he meant, that--", "order": 43829, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 24, "scene": 67, "line": "So now anyone gets to talk at any times?", "order": 43830, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 24, "scene": 67, "line": "Go ahead.", "order": 43831, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 24, "scene": 67, "line": "What do 'I' want?... I'm looking for someone... who... [smiles] Everyone is listening to me. [Dwight looks at the camera, annoyed]", "order": 43832, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 24, "scene": 67, "line": "Can I say...?", "order": 43833, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 24, "scene": 67, "line": "Yes.", "order": 43834, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 24, "scene": 67, "line": "I think it should be Darryl.", "order": 43835, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 24, "scene": 67, "line": "Okay.", "order": 43836, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 24, "scene": 67, "line": "What a surprise! Minorities sticking together.", "order": 43837, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 24, "scene": 67, "line": "Kelly's on your side.", "order": 43838, "speaker": "Oscar Martinez"},
  {"season": 7, "episode": 24, "scene": 67, "line": "I'm sorry, is that all you think of me? A minority? I am so much more than that. I am a dancer, I'm a singer, I'm a fashion designer.", "order": 43839, "speaker": "Kelly Kapoor"},
  {"season": 7, "episode": 24, "scene": 67, "line": "Whoever it is, I think they should be lame. [Stanley shakes his head and rolls his eyes] Kind a a non-threatening, moderate personality.", "order": 43840, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 24, "scene": 67, "line": "I want an outsider.", "order": 43841, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 24, "scene": 67, "line": "Perfect. There are several outside candidates that we think would be really-", "order": 43842, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 24, "scene": 67, "line": "No, I mean an outsider. Like someone on the margins of society, who doesn't see things the way we do, like a homeless person.", "order": 43843, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 24, "scene": 67, "line": "A homeless person. Really? A homeless person.", "order": 43844, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 24, "scene": 67, "line": "No, you're right, Pam, let's just leave him to the welfare system and let that handle it.", "order": 43845, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 24, "scene": 67, "line": "No, I want you to say that you think the 'best' person to be our new manager is a homeless person.", "order": 43846, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 24, "scene": 67, "line": "Let me guess who you want, Pam. Rachael Ray? The ladies of The View?", "order": 43847, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 24, "scene": 68, "line": "I got away with... everything, under the last boss, and it wasn't good for me, at all. So, I want guidance. I want leadership. But don't just, like, boss me around, you know? Like, lead me. Lead me... when I'm in the mood... to be led.", "order": 43848, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 24, "scene": 69, "line": "I just want, for once, a smart, professional, decisive, well-hung man in his forties.", "order": 43849, "speaker": "Meredith Palmer"},
  {"season": 7, "episode": 24, "scene": 69, "line": "Hey! Hey! Hey!", "order": 43850, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 24, "scene": 69, "line": "Okay, fine. Uhh, the guy with a tiny penis. Are you happy? [sarcastically] Let's hire that guy! [Jim looks speechless]", "order": 43851, "speaker": "Meredith Palmer"},
  {"season": 7, "episode": 24, "scene": 69, "line": "She may have a point there... would a small penis work? Small-to-moderate.", "order": 43852, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 24, "scene": 69, "line": "[walks in] Gotta catch a plane.", "order": 43853, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 24, "scene": 69, "line": "Ohh, hey Gabe, I'm sorry, we... we didn't get you a cake or anything. We're gonna miss you.", "order": 43854, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 24, "scene": 69, "line": "Oh, well I'm still the corporate liaison to the branch.", "order": 43855, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 24, "scene": 69, "line": "You are not leaving without giving me a hug. [hugs Gabe] Ughh.", "order": 43856, "speaker": "Kelly Kapoor"},
  {"season": 7, "episode": 24, "scene": 69, "line": "Okay, you know what, you don't need to make that sound.", "order": 43857, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 24, "scene": 69, "line": "I'm sorry! You were just a lot bonier than I thought you were gonna be!", "order": 43858, "speaker": "Kelly Kapoor"},
  {"season": 7, "episode": 24, "scene": 69, "line": "There are plenty of people who love touching me. [camera zooms to Ryan, who looks at the camera, extremely skeptical] I'm a terrific hugger. I've been with a bunch of girls where that's basically all they want to do. I will see you all soon.", "order": 43859, "speaker": "Gabe Lewis"},
  {"season": 7, "episode": 24, "scene": 69, "line": "Later, man.", "order": 43860, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 24, "scene": 69, "line": "[various] Good luck! Goodbye! Good luck at your new job.", "order": 43861, "speaker": "All"},
  {"season": 7, "episode": 24, "scene": 69, "line": "Take care, man.", "order": 43862, "speaker": "Ryan Howard"},
  {"season": 7, "episode": 24, "scene": 69, "line": "We're gonna miss you, Gabe. [Gabe leaves]", "order": 43863, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 24, "scene": 69, "line": "[stands up] Uh, listen up. Listen up, ladies. I want the job. There, I said it. I'm educated. I'm capable. I like all of you... and I won't make any changes.", "order": 43864, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 24, "scene": 69, "line": "I see it. [nods and smiles] I see it like I see a mountain that I'm standing in front of [voice cracks] and facing, and I'm like...", "order": 43865, "speaker": "Erin Hannon"},
  {"season": 7, "episode": 24, "scene": 69, "line": "Yeah. [Oscar looks confused] Yeah, Andy would be wonderful as boss. Erin made a good point. [brushes Erin's hair with her hand]", "order": 43866, "speaker": "Phyllis Vance"},
  {"season": 7, "episode": 24, "scene": 70, "line": "No, we're not related. I got the call. But... I'll tell her some other day. [smiles]", "order": 43867, "speaker": "Phyllis Vance"},
  {"season": 7, "episode": 24, "scene": 71, "line": "What about Darryl? We can all agree that he's a stand-up guy, right?", "order": 43868, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 24, "scene": 71, "line": "Well, let me be clear. I only speak for myself, and not myself and the Senator. I think we have some wonderful candidates, and there's a great, lively debate here, but let's think about-", "order": 43869, "speaker": "Angela Martin"},
  {"season": 7, "episode": 24, "scene": 71, "line": "No, no,... no. Sorry. We cut Kevin off for the same thing. You have to have something to say, if you talk. [Angela rolls her eyes and stops talking]", "order": 43870, "speaker": "Toby Flenderson"},
  {"season": 7, "episode": 24, "scene": 71, "line": "Exactly.", "order": 43871, "speaker": "Kevin Malone"},
  {"season": 7, "episode": 24, "scene": 71, "line": "[enters the office with his daughter, who runs in and hugs Jim] Jada, no, no, no, Jada, what are you doing? [Jim looks knowingly at the camera] Oh. So sorry, guys. I hope my family didn't disrupt your meeting.", "order": 43872, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 24, "scene": 71, "line": "Daddy, are these the people who are making you manager?", "order": 43873, "speaker": "Jada"},
  {"season": 7, "episode": 24, "scene": 71, "line": "[sighs] Maybe, sweetheart. Single dad. Challenges.", "order": 43874, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 24, "scene": 71, "line": "I don't know if he'd be a good manager [Jim shakes his head and looks wide-eyed at Darryl], but he's a really great dad!", "order": 43875, "speaker": "Jada"},
  {"season": 7, "episode": 24, "scene": 71, "line": "Okay, shush it. This was a mistake. Let's go. [Darryl and Jada leave, Ryan slowly shakes his head]", "order": 43876, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 24, "scene": 71, "line": "It seems like we all know enough to vote. Should we just vote now?", "order": 43877, "speaker": "Phyllis Vance"},
  {"season": 7, "episode": 24, "scene": 71, "line": "What? No, no, it's not a vote.", "order": 43878, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 24, "scene": 71, "line": "Then what was this all about?", "order": 43879, "speaker": "Phyllis Vance"},
  {"season": 7, "episode": 24, "scene": 71, "line": "I don't know. This conversation really got away from me.", "order": 43880, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 24, "scene": 71, "line": "I don't care. They can just vote.", "order": 43881, "speaker": "Kelly Kapoor"},
  {"season": 7, "episode": 24, "scene": 71, "line": "No, they can't. That's not how this is gonna work. [exhales] We're going in this room, we're going to have a meeting. We're gonna make a recommendation to Jo, and she's gonna give you her recommendation on Monday. Okay? [to Kelly and Toby, as they re-enter the conference room] What the hell happened out there? [Kevin tries to follow, but the door is closed on him]", "order": 43882, "speaker": "Jim Halpert"},
  {"season": 7, "episode": 24, "scene": 72, "line": "Sometimes you hear about people failing upwards. I think I'm about to do that.", "order": 43883, "speaker": "Andy Bernard"},
  {"season": 7, "episode": 24, "scene": 73, "line": "This job? Oh, yeah, I'll get it. Jo's an old friend. I think... I'm her best friend. She's not my best friend.", "order": 43884, "speaker": "Nellie Bertram"},
  {"season": 7, "episode": 24, "scene": 74, "line": "Every day I have a blueberry muffin. Today, I did not have a blueberry muffin. Should have had the blueberry muffin... especially considering how incredibly superstitious I am.", "order": 43885, "speaker": "Darryl Philbin"},
  {"season": 7, "episode": 24, "scene": 75, "line": "No, I've never been more sure of anything in my life. I will be the new boss of... [looks back to glance at lobby directory] Vance Refrigeration.", "order": 43886, "speaker": "Fred"},
  {"season": 7, "episode": 24, "scene": 76, "line": "Honestly, I think I... I sabotaged myself. It's like I'm afraid of being happy. Case in point... I was supposed to start another job today.", "order": 43887, "speaker": "Merv"},
  {"season": 7, "episode": 24, "scene": 77, "line": "[chuckles] I will get offered the job. That's a... call I've received many times. The slight hopefulness in their voice, the pregnant pause... while they wait to hear my response, and then... my response.", "order": 43888, "speaker": "Robert California"},
  {"season": 7, "episode": 24, "scene": 78, "line": "[geeky voice] I want the job. I really do. It's just, the rest of my family's in the Finger Lakes right now. I'm supposed to be in the Finger Lakes right now. I told them I was on a hike; snuck away to do this interview. I gotta get back pretty soon; they'll worry. People disappear in the Finger Lakes.", "order": 43889, "speaker": "Finger Lakes Guy"},
  {"season": 7, "episode": 24, "scene": 79, "line": "I will run this branch, or I will destroy this branch, or... [shrugs] I don't know. Something always works out. [leaves]", "order": 43890, "speaker": "Dwight Schrute"},
  {"season": 7, "episode": 24, "scene": 80, "line": "You remind me so much of my fourth-biggest client.", "order": 43891, "speaker": "Creed Bratton"},
  {"season": 7, "episode": 24, "scene": 80, "line": "[in high-pitched, damsel-like voice] Is that right?", "order": 43892, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 24, "scene": 80, "line": "I think you two should meet.", "order": 43893, "speaker": "Creed Bratton"},
  {"season": 7, "episode": 24, "scene": 80, "line": "Well, okay!", "order": 43894, "speaker": "Pam Halpert"},
  {"season": 7, "episode": 24, "scene": 80, "line": "Hey Jordana! Patch my ninth- and fourth-biggest clients together. [Pam puts down phone and picks it back up]", "order": 43895, "speaker": "Creed Bratton"},
  {"season": 7, "episode": 24, "scene": 80, "line": "[in a comedic male voice] Hello!", "order": 43896, "speaker": "Pam Halpert as fourth-biggest client"},
  {"season": 7, "episode": 24, "scene": 80, "line": "[in high-pitched, damsel-like voice] Hello!", "order": 43897, "speaker": "Pam Halpert as ninth-biggest client"},
  {"season": 7, "episode": 24, "scene": 80, "line": "Hi, how are ya? [Erin looks amused, Stanley and Phyllis look at Pam, confused]", "order": 43898, "speaker": "Pam Halpert as fourth-biggest client"},
  {"season": 7, "episode": 24, "scene": 80, "line": "Ohho! I'm good! Don't you just love paper, and things about paper!", "order": 43899, "speaker": "Pam Halpert as ninth-biggest client"},
  {"season": 7, "episode": 24, "scene": 80, "line": "Hey, are you single? This seems like a love connection to me!", "order": 43900, "speaker": "Pam Halpert as fourth-biggest client"},
  {"season": 7, "episode": 24, "scene": 80, "line": "It's Kismet!", "order": 43901, "speaker": "Creed Bratton"}
]
