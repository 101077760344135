import Fuse from 'fuse.js'
import Header from './../components/Header'
import PageWrapper from '../components/PageWrapper'
import QuoteCard from './../components/QuoteCard'
import React, { useCallback, useRef } from 'react'
import SearchBar from './../components/SearchBar'
import WGImage from './../assets/images/wayne-gretzky-michael-scott.png'
import useSearch from './../hooks/useSearch'
import { Footer } from '../components/Footer'
import { useTheme } from '../contexts/ThemeContext'

type SearchPageProps = {}

const SearchPage: React.FC<SearchPageProps> = () => {
    const {
        searchPhrase,
        setSearchPhrase,
        results: { quotes, _metadata },
        debouncedSearchPhrase,
        setCurrentPage,
        hasMore,
    } = useSearch()

    const observer: any = useRef()
    const lastQuoteElementRef = useCallback(
        (node) => {
            if (observer.current) {
                observer.current.disconnect()
            }

            observer.current = new IntersectionObserver((entries) => {
                if (entries[0].isIntersecting && hasMore) {
                    setCurrentPage((prev) => prev + 1)
                }
            })

            if (node) {
                observer.current.observe(node)
            }
        },
        [hasMore, setCurrentPage]
    )

    return (
        <>
            <Header searchbar={<SearchBar searchPhrase={searchPhrase} setSearchPhrase={setSearchPhrase} />} />
            <PageWrapper>
                <ResultCount resultCount={_metadata.resultCount} visible={!!debouncedSearchPhrase} />
                <div className="flex flex-col flex-1">
                    {quotes.map((q: Fuse.FuseResult<Quote>, i: number) => {
                        return (
                            <QuoteCard
                                key={i}
                                quote={q}
                                searchPhrase={debouncedSearchPhrase}
                                ref={quotes.length === i + 1 ? lastQuoteElementRef : null}
                                showContextArrow={true}
                            />
                        )
                    })}
                    {_metadata.currentPage === _metadata.pageCount && <WayneGretzky />}
                    {_metadata.resultCount === 0 && (
                        <div className="flex items-center justify-center h-full text-2xl text-center opacity-25 lg:text-4xl">
                            <span
                                className="cursor-pointer dark:text-white"
                                onClick={() => (document.querySelector('#searchbar') as HTMLInputElement).focus()}
                            >
                                {!debouncedSearchPhrase
                                    ? 'Try searching for "Bears. Beets. Battlestar Galactica."'
                                    : 'No results, try searching for something different.'}
                            </span>
                        </div>
                    )}
                </div>
                <Footer text={`Built with ❤️ by amac.`} />
            </PageWrapper>
        </>
    )
}

type ResultCountProps = React.HTMLAttributes<HTMLDivElement> & {
    visible: boolean
    resultCount: number
}

const ResultCount: React.FC<ResultCountProps> = (props) => {
    return props.visible ? (
        <p className="mb-6 text-sm text-gray-600 dark:text-gray-400">{`${props.resultCount} result${
            props.resultCount !== 1 ? 's' : ''
        } found.`}</p>
    ) : null
}

type WayneGretzkyProps = React.HTMLAttributes<HTMLImageElement> & {}

const WayneGretzky: React.FC<WayneGretzkyProps> = () => {
    const { theme } = useTheme()

    return (
        <img
            className="self-center h-24 mb-6 "
            src={WGImage}
            alt="wayne-gretzky-michael-scott"
            style={{ filter: `invert(${theme === 'dark' ? 0 : 1})` }}
        />
    )
}

export default SearchPage
