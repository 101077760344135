module.exports = [
  {
    season: 1,
    episode: 1,
    scene: 1,
    line: 'All right Jim. Your quarterlies look very good. How are things at the library?',
    order: 1,
    speaker: 'Michael Scott'
  },
  {
    season: 1,
    episode: 1,
    scene: 1,
    line: "Oh, I told you. I couldn't close it. So...",
    order: 2,
    speaker: 'Jim Halpert'
  },
  {
    season: 1,
    episode: 1,
    scene: 1,
    line: "So you've come to the master for guidance? Is this what you're saying, grasshopper?",
    order: 3,
    speaker: 'Michael Scott'
  },
  {
    season: 1,
    episode: 1,
    scene: 1,
    line: 'Actually, you called me in here, but yeah.',
    order: 4,
    speaker: 'Jim Halpert'
  },
  {
    season: 1,
    episode: 1,
    scene: 1,
    line: "All right. Well, let me show you how it's done.",
    order: 5,
    speaker: 'Michael Scott'
  },
  {
    season: 1,
    episode: 1,
    scene: 2,
    line:
      "[on the phone] Yes, I'd like to speak to your office manager, please. Yes, hello. This is Michael Scott. I am the Regional Manager of Dunder Mifflin Paper Products. Just wanted to talk to you manager-a-manger. [quick cut scene] All right. Done deal. Thank you very much, sir. You're a gentleman & a scholar. Oh, I'm sorry. OK. I'm sorry. My mistake. [hangs up] That was a woman I was talking to, so... She had a very low voice. Probably a smoker, so... [Clears throat] So that's the way it's done.",
    order: 6,
    speaker: 'Michael Scott'
  },
  {
    season: 1,
    episode: 1,
    scene: 3,
    line:
      "I've, uh, I've been at Dunder Mifflin for 12 years, the last four as Regional Manager. If you want to come through here... See we have the entire floor. So this is my kingdom, as far as the eye can see. This is our receptionist, Pam. Pam! Pam-Pam! Pam Beesly. Pam has been with us for... forever. Right, Pam?",
    order: 7,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 1, scene: 3, line: "Well. I don't know.", order: 8, speaker: 'Pam Beesly' },
  {
    season: 1,
    episode: 1,
    scene: 3,
    line: "If you think she's cute now, you should have seen her a couple of years ago. [growls]",
    order: 9,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 1, scene: 3, line: 'What?', order: 10, speaker: 'Pam Beesly' },
  { season: 1, episode: 1, scene: 3, line: 'Any messages?', order: 11, speaker: 'Michael Scott' },
  { season: 1, episode: 1, scene: 3, line: 'Uh, yeah. Just a fax.', order: 12, speaker: 'Pam Beesly' },
  {
    season: 1,
    episode: 1,
    scene: 3,
    line:
      "Oh! Pam, this is from Corporate. How many times have I told you? There's a special filing cabinet for things from corporate.",
    order: 13,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 1, scene: 3, line: "You haven't told me.", order: 14, speaker: 'Pam Beesly' },
  {
    season: 1,
    episode: 1,
    scene: 3,
    line: "It's called the wastepaper basket! Look at that! Look at that face.",
    order: 15,
    speaker: 'Michael Scott'
  },
  {
    season: 1,
    episode: 1,
    scene: 4,
    line:
      "People say I am the best boss. They go, 'God we've never worked in a place like this before. You're hilarious.' 'And you get the best out of us.' [shows the camera his WORLD'S BEST BOSS mug] I think that pretty much sums it up. I found it at Spencer Gifts.",
    order: 16,
    speaker: 'Michael Scott'
  },
  {
    season: 1,
    episode: 1,
    scene: 5,
    line:
      '[singing] Shall I play for you? Pa rum pump um pum [Imitates heavy drumming] I have no gifts for you. Pa rum pump um pum [Imitates heavy drumming]',
    order: 17,
    speaker: 'Dwight Schrute'
  },
  {
    season: 1,
    episode: 1,
    scene: 6,
    line:
      "My job is to speak to clients on the phone about... uh, quantities and type of copier paper. You know, whether we can supply it to them. Whether they can pay for it. And... I'm boring myself just talking about this.",
    order: 18,
    speaker: 'Jim Halpert'
  },
  { season: 1, episode: 1, scene: 7, line: 'Whassup!', order: 19, speaker: 'Michael Scott' },
  {
    season: 1,
    episode: 1,
    scene: 7,
    line: 'Whassup! I still love that after seven years.',
    order: 20,
    speaker: 'Jim Halpert'
  },
  { season: 1, episode: 1, scene: 7, line: 'Whassup!', order: 21, speaker: 'Michael Scott' },
  { season: 1, episode: 1, scene: 7, line: 'Whassup!', order: 22, speaker: 'Dwight Schrute' },
  { season: 1, episode: 1, scene: 7, line: 'Whass...up!', order: 23, speaker: 'Michael Scott' },
  { season: 1, episode: 1, scene: 7, line: 'Whassup.', order: 24, speaker: 'Dwight Schrute' },
  { season: 1, episode: 1, scene: 7, line: '[Strains, grunts] What?', order: 25, speaker: 'Michael Scott' },
  { season: 1, episode: 1, scene: 7, line: 'Nothing.', order: 26, speaker: 'Jim Halpert' },
  { season: 1, episode: 1, scene: 7, line: 'OK. All right. See you later.', order: 27, speaker: 'Michael Scott' },
  { season: 1, episode: 1, scene: 7, line: 'All right. Take care.', order: 28, speaker: 'Jim Halpert' },
  { season: 1, episode: 1, scene: 7, line: 'Back to work.', order: 29, speaker: 'Michael Scott' },
  {
    season: 1,
    episode: 1,
    scene: 8,
    line: '[on her cell phone] Just before lunch. That would be great.',
    order: 30,
    speaker: 'Jan Levinson'
  },
  {
    season: 1,
    episode: 1,
    scene: 9,
    line:
      "Corporate really doesn't really interfere with me at all. Jan Levinson Gould. [walking out of his office] Jan, hello. I call her Hillary Rodham Clinton. Right? Not to her face, because... well, not because I'm scared of her. Because I'm not. But, um... Yeah.",
    order: 31,
    speaker: 'Michael Scott'
  },
  {
    season: 1,
    episode: 1,
    scene: 10,
    line: 'Alright, was there anything you wanted to add to the agenda?',
    order: 32,
    speaker: 'Jan Levinson'
  },
  { season: 1, episode: 1, scene: 10, line: 'Um... Me no get an agenda.', order: 33, speaker: 'Michael Scott' },
  { season: 1, episode: 1, scene: 10, line: "What? I'm sorry?", order: 34, speaker: 'Jan Levinson' },
  { season: 1, episode: 1, scene: 10, line: "I didn't get any agenda.", order: 35, speaker: 'Michael Scott' },
  {
    season: 1,
    episode: 1,
    scene: 10,
    line: 'Well, I faxed one over to you this morning.',
    order: 36,
    speaker: 'Jan Levinson'
  },
  {
    season: 1,
    episode: 1,
    scene: 10,
    line: "Really? I didn't... [looks at Pam] Did we get a fax this morning?",
    order: 37,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 1, scene: 10, line: 'Uh, yeah, the one...', order: 38, speaker: 'Pam Beesly' },
  {
    season: 1,
    episode: 1,
    scene: 10,
    line:
      "Why isn't it in my hand? A company runs on efficiency of communication, right? So what's the problem, Pam? Why didn't I get it?",
    order: 39,
    speaker: 'Michael Scott'
  },
  {
    season: 1,
    episode: 1,
    scene: 10,
    line: 'You put in the garbage can that was a special filing cabinet.',
    order: 40,
    speaker: 'Pam Beesly'
  },
  {
    season: 1,
    episode: 1,
    scene: 10,
    line:
      "Yeah, that was a joke. That was a joke that was actually my brother's, and... It was supposed to be with bills and it doesn't work great with faxes.",
    order: 41,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 1, scene: 10, line: 'Do you want to look at mine?', order: 42, speaker: 'Jan Levinson' },
  { season: 1, episode: 1, scene: 10, line: 'Yeah, yeah. Lovely. Thank you.', order: 43, speaker: 'Michael Scott' },
  {
    season: 1,
    episode: 1,
    scene: 10,
    line:
      "OK. Since the last meeting, Ellen and the board have decided we can't justify a Scranton branch and a Stamford branch.",
    order: 44,
    speaker: 'Jan Levinson'
  },
  { season: 1, episode: 1, scene: 10, line: 'OK...', order: 45, speaker: 'Michael Scott' },
  { season: 1, episode: 1, scene: 10, line: "Michael, don't panic.", order: 46, speaker: 'Jan Levinson' },
  {
    season: 1,
    episode: 1,
    scene: 10,
    line: 'No, no, no, no, this is good. This is good. This is fine. Excellent.',
    order: 47,
    speaker: 'Michael Scott'
  },
  {
    season: 1,
    episode: 1,
    scene: 10,
    line: "No, no, no, Michael, listen OK. Don't panic. We haven't made... We haven't decided.",
    order: 48,
    speaker: 'Jan Levinson'
  },
  {
    season: 1,
    episode: 1,
    scene: 10,
    line: 'All the alarm bells are kind of going... ringie-dingie-ding!',
    order: 49,
    speaker: 'Michael Scott'
  },
  {
    season: 1,
    episode: 1,
    scene: 10,
    line:
      "I've spoken to Josh in Stamford. I've told him the same as you and it's up to either him or you to convince me that your branch can incorporate the other.",
    order: 50,
    speaker: 'Jan Levinson'
  },
  { season: 1, episode: 1, scene: 10, line: 'OK. No problem.', order: 51, speaker: 'Michael Scott' },
  {
    season: 1,
    episode: 1,
    scene: 10,
    line: 'This does, however, mean that there is going to be downsizing.',
    order: 52,
    speaker: 'Jan Levinson'
  },
  {
    season: 1,
    episode: 1,
    scene: 10,
    line:
      "Me no wanna hear that, Jan. Because downsizing is a bitch. It is a real bitch. And I wouldn't wish that on Josh's men. I certainly wouldn't wish it on my men. Or women, present company excluded. Sorry. Uh, is Josh concerned about downsizing himself? Not downsizing himself but is he concerned about downsizing?",
    order: 53,
    speaker: 'Michael Scott'
  },
  {
    season: 1,
    episode: 1,
    scene: 11,
    line:
      'Question. How long do we have to... [Telephone rings] Oh uh, Todd Packer, terrific rep. Do you mind if I take it?',
    order: 54,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 1, scene: 11, line: 'Go ahead.', order: 55, speaker: 'Jan Levinson' },
  { season: 1, episode: 1, scene: 11, line: 'Packman.', order: 56, speaker: 'Michael Scott' },
  { season: 1, episode: 1, scene: 11, line: 'Hey, you big queen.', order: 57, speaker: 'Todd Packer' },
  { season: 1, episode: 1, scene: 11, line: "Oh, that's not appropriate.", order: 58, speaker: 'Michael Scott' },
  {
    season: 1,
    episode: 1,
    scene: 11,
    line: 'Hey, is old Godzillary coming in today?',
    order: 59,
    speaker: 'Todd Packer'
  },
  { season: 1, episode: 1, scene: 11, line: "Uh, I don't know what you mean.", order: 60, speaker: 'Michael Scott' },
  {
    season: 1,
    episode: 1,
    scene: 11,
    line: "I've been meaning to ask her one question. Does the carpet match the drapes?",
    order: 61,
    speaker: 'Todd Packer'
  },
  {
    season: 1,
    episode: 1,
    scene: 11,
    line: "Oh, my God! Oh! That's... horrifying. Horrible. Horrible person.",
    order: 62,
    speaker: 'Michael Scott'
  },
  {
    season: 1,
    episode: 1,
    scene: 11,
    line: "So do you think we could keep a lid on this for now? I don't want to worry people unnecessarily.",
    order: 63,
    speaker: 'Jan Levinson'
  },
  {
    season: 1,
    episode: 1,
    scene: 11,
    line: 'No, absolutely. Under this regime, it will not leave this office. [zips his lips] Like that.',
    order: 64,
    speaker: 'Michael Scott'
  },
  {
    season: 1,
    episode: 1,
    scene: 12,
    line: 'So what does downsizing actually mean?',
    order: 65,
    speaker: 'Phyllis Lapin'
  },
  { season: 1, episode: 1, scene: 12, line: 'Well...', order: 66, speaker: 'Stanley Hudson' },
  {
    season: 1,
    episode: 1,
    scene: 13,
    line: "You guys better update your resumes just like I'm doing.",
    order: 67,
    speaker: 'Oscar Martinez'
  },
  {
    season: 1,
    episode: 1,
    scene: 14,
    line: "I bet it's gonna be me. Probably gonna be me.",
    order: 68,
    speaker: 'Angela Martin'
  },
  { season: 1, episode: 1, scene: 14, line: "Yeah, it'll be you.", order: 69, speaker: 'Kevin Malone' },
  { season: 1, episode: 1, scene: 15, line: 'I have an important question for you.', order: 70, speaker: 'Pam Beesly' },
  { season: 1, episode: 1, scene: 15, line: 'Yes?', order: 71, speaker: 'Jim Halpert' },
  {
    season: 1,
    episode: 1,
    scene: 15,
    line: "Are you going to Angela's cat party on Sunday?",
    order: 72,
    speaker: 'Pam Beesly'
  },
  { season: 1, episode: 1, scene: 15, line: 'Yeah, stop. That is ridiculous.', order: 73, speaker: 'Jim Halpert' },
  {
    season: 1,
    episode: 1,
    scene: 16,
    line:
      "Am I going to tell them? No, I am not going to tell them. I don't see the point of that. As a doctor, you would not tell a patient if they had cancer.",
    order: 74,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 1, scene: 17, line: 'Hey.', order: 75, speaker: 'Michael Scott' },
  { season: 1, episode: 1, scene: 17, line: 'Hey.', order: 76, speaker: 'Ryan Howard' },
  { season: 1, episode: 1, scene: 17, line: 'This is Mr. Scott.', order: 77, speaker: 'Pam Beesly' },
  { season: 1, episode: 1, scene: 17, line: 'Guilty! Guilty as charged!', order: 78, speaker: 'Michael Scott' },
  {
    season: 1,
    episode: 1,
    scene: 17,
    line: 'Ryan Howard from the temp agency. Daniqua sent me down to start today.',
    order: 79,
    speaker: 'Ryan Howard'
  },
  {
    season: 1,
    episode: 1,
    scene: 17,
    line: 'Howard, like Moe Howard. Three Stooges.',
    order: 80,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 1, scene: 17, line: 'Yup.', order: 81, speaker: 'Ryan Howard' },
  {
    season: 1,
    episode: 1,
    scene: 17,
    line:
      "Watch this. This is Moe. Nyuck-nyuck-nyuck-nyuck-nyuck. Mee! [hold hand up for a high five] Ah, right here. Three Stooges. Oh, Pam. It's a guy thing, Pam. I'm sort of a student of comedy. Watch this. Here we go. [Yelling in cod German] I'm Hitler. Adolf Hitler. [Continues with cod German]",
    order: 82,
    speaker: 'Michael Scott'
  },
  {
    season: 1,
    episode: 1,
    scene: 18,
    line:
      "I don't think it would be the worst thing if they let me go because then I might... I don't think it's many little girls' dream to be a receptionist. I like to do illustrations. Um... Mostly watercolor. A few oil pencil. Um, Jim thinks they're good.",
    order: 83,
    speaker: 'Pam Beesly'
  },
  { season: 1, episode: 1, scene: 18, line: 'Dunder Mifflin. This is Pam.', order: 84, speaker: 'Pam Beesly' },
  {
    season: 1,
    episode: 1,
    scene: 19,
    line:
      'Sure. Mr. Davis, let me call you right back. Yeah, something just came up. Two minutes. Thank you very much. Dwight, what are you doing?',
    order: 85,
    speaker: 'Jim Halpert'
  },
  { season: 1, episode: 1, scene: 19, line: 'What?', order: 86, speaker: 'Dwight Schrute' },
  { season: 1, episode: 1, scene: 19, line: 'What are you doing?', order: 87, speaker: 'Jim Halpert' },
  {
    season: 1,
    episode: 1,
    scene: 19,
    line: "Just clearing my desk. I can't concentrate.",
    order: 88,
    speaker: 'Dwight Schrute'
  },
  { season: 1, episode: 1, scene: 19, line: "It's not on your desk.", order: 89, speaker: 'Jim Halpert' },
  {
    season: 1,
    episode: 1,
    scene: 19,
    line: "It's overlapping. It's all spilling over the edge. One word, two syllables. Demarcation.",
    order: 90,
    speaker: 'Dwight Schrute'
  },
  { season: 1, episode: 1, scene: 19, line: "You can't do that.", order: 91, speaker: 'Dwight Schrute' },
  { season: 1, episode: 1, scene: 19, line: 'Why not?', order: 92, speaker: 'Jim Halpert' },
  {
    season: 1,
    episode: 1,
    scene: 19,
    line: 'Safety violation. I could fall and pierce an organ.',
    order: 93,
    speaker: 'Dwight Schrute'
  },
  {
    season: 1,
    episode: 1,
    scene: 19,
    line:
      "[crosses fingers] We'll see. [Dwight begins smashing pencils with his phone] This is why the whole downsizing thing just doesn't bother me.",
    order: 94,
    speaker: 'Jim Halpert'
  },
  { season: 1, episode: 1, scene: 19, line: 'Downsizing?', order: 95, speaker: 'Dwight Schrute' },
  {
    season: 1,
    episode: 1,
    scene: 20,
    line:
      'Downsizing? I have no problem with that. I have been recommending downsizing since I first got here. I even brought it up in my interview. I say, bring it on.',
    order: 96,
    speaker: 'Dwight Schrute'
  },
  {
    season: 1,
    episode: 1,
    scene: 21,
    line: 'You just still have these messages from yesterday.',
    order: 97,
    speaker: 'Pam Beesly'
  },
  {
    season: 1,
    episode: 1,
    scene: 21,
    line:
      "Relax. Everything's under control. Uh, yeah. Yeah. That's important. Right. Oh this is so important, I should run to answer it. [Imitating Six-Million Dollar Man sound effect]",
    order: 98,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 1, scene: 21, line: 'What?', order: 99, speaker: 'Pam Beesly' },
  {
    season: 1,
    episode: 1,
    scene: 21,
    line:
      "Come on. Six-Million Dollar Man! Steve Austin! Actually, that would be a good salary for me, don't you think? Six million dollars? Memo to Jan. I deserve a raise.",
    order: 100,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 1, scene: 21, line: "Don't we all?", order: 101, speaker: 'Pam Beesly' },
  { season: 1, episode: 1, scene: 21, line: "I'm sorry?", order: 102, speaker: 'Michael Scott' },
  { season: 1, episode: 1, scene: 21, line: 'Nothing.', order: 103, speaker: 'Pam Beesly' },
  {
    season: 1,
    episode: 1,
    scene: 21,
    line:
      "If you're unhappy with your compensation, maybe you should take it up with HR. OK. Not today, OK? Pam, just be professional. [Sighs]",
    order: 104,
    speaker: 'Michael Scott'
  },
  {
    season: 1,
    episode: 1,
    scene: 22,
    line:
      "I think I'm a role model here. I think I garner people's respect. [Imitating a PA] Attention all Dunder Mifflin employees, please. We have a meeting in the conference room, ASAP.",
    order: 105,
    speaker: 'Michael Scott'
  },
  {
    season: 1,
    episode: 1,
    scene: 23,
    line:
      "People I respect, heroes of mine, would be Bob Hope... Abraham Lincoln, definitely. Bono. And probably God would be the fourth one. And I just think all those people really helped the world in so many ways that it's really beyond words. It's really incalculable.",
    order: 106,
    speaker: 'Michael Scott'
  },
  {
    season: 1,
    episode: 1,
    scene: 24,
    line: "Now I know there's some rumors out there and I just kind of want to set the record straight.",
    order: 107,
    speaker: 'Michael Scott'
  },
  {
    season: 1,
    episode: 1,
    scene: 24,
    line: "I'm Assistant Regional Manager. I should know first.",
    order: 108,
    speaker: 'Dwight Schrute'
  },
  {
    season: 1,
    episode: 1,
    scene: 24,
    line: 'Assistant to the Regional Manager.',
    order: 109,
    speaker: 'Michael Scott'
  },
  {
    season: 1,
    episode: 1,
    scene: 24,
    line: 'OK, um, can you just tell me please? Just tell me quietly. Can you whisper it in my ear?',
    order: 110,
    speaker: 'Dwight Schrute'
  },
  {
    season: 1,
    episode: 1,
    scene: 24,
    line: "I'm about to tell everybody. I'm just about to tell everybody.",
    order: 111,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 1, scene: 24, line: "Can't you just tell us.", order: 112, speaker: 'Oscar Martinez' },
  {
    season: 1,
    episode: 1,
    scene: 24,
    line: "Please, OK? Do you want me to tell 'em?",
    order: 113,
    speaker: 'Dwight Schrute'
  },
  {
    season: 1,
    episode: 1,
    scene: 24,
    line: "You don't know what it is. [Laughs]",
    order: 114,
    speaker: 'Michael Scott'
  },
  {
    season: 1,
    episode: 1,
    scene: 24,
    line: "OK. You tell 'em. With my permission. Permission granted.",
    order: 115,
    speaker: 'Dwight Schrute'
  },
  { season: 1, episode: 1, scene: 24, line: "I don't need your permission.", order: 116, speaker: 'Michael Scott' },
  { season: 1, episode: 1, scene: 24, line: 'Go ahead.', order: 117, speaker: 'Dwight Schrute' },
  {
    season: 1,
    episode: 1,
    scene: 24,
    line:
      'Corporate has deemed it appropriate to enforce an ultimatum upon me. And Jan is thinking about downsizing either the Stamford branch or this branch.',
    order: 118,
    speaker: 'Michael Scott'
  },
  {
    season: 1,
    episode: 1,
    scene: 25,
    line:
      "I heard they might be closing this branch down. That's just the rumor going around. This is my first day. I don't really know.",
    order: 119,
    speaker: 'Ryan Howard'
  },
  {
    season: 1,
    episode: 1,
    scene: 26,
    line: 'Yeah but Michael, what if they downsize here?',
    order: 120,
    speaker: 'Oscar Martinez'
  },
  { season: 1, episode: 1, scene: 26, line: 'Not gonna happen.', order: 121, speaker: 'Michael Scott' },
  {
    season: 1,
    episode: 1,
    scene: 26,
    line: 'It could be out of your hands Michael.',
    order: 122,
    speaker: 'Stanley Hudson'
  },
  {
    season: 1,
    episode: 1,
    scene: 26,
    line: "It won't be out of my hands Stanley, OK. I promise you that.",
    order: 123,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 1, scene: 26, line: 'Can you promise that?', order: 124, speaker: 'Stanley Hudson' },
  { season: 1, episode: 1, scene: 26, line: "On his mother's grave.", order: 125, speaker: 'Dwight Schrute' },
  { season: 1, episode: 1, scene: 26, line: 'No.', order: 126, speaker: 'Michael Scott' },
  { season: 1, episode: 1, scene: 26, line: 'What?', order: 127, speaker: 'Phyllis Lapin' },
  {
    season: 1,
    episode: 1,
    scene: 26,
    line: "Well, yeah, it is a promise. And frankly, I'm a little insulted that you have to keep asking about it.",
    order: 128,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 1, scene: 26, line: "It's just that we need to know.", order: 129, speaker: 'Stanley Hudson' },
  {
    season: 1,
    episode: 1,
    scene: 26,
    line:
      'I know. Hold on a second. I think Pam wanted to say something. Pam, you had a look that you wanted to ask a question just then.',
    order: 130,
    speaker: 'Michael Scott'
  },
  {
    season: 1,
    episode: 1,
    scene: 26,
    line: 'I was in the meeting with Jan and she did say that it could be this branch that gets the axe.',
    order: 131,
    speaker: 'Pam Beesly'
  },
  { season: 1, episode: 1, scene: 26, line: 'Are you sure about that?', order: 132, speaker: 'Man' },
  {
    season: 1,
    episode: 1,
    scene: 26,
    line: 'Well, Pam maybe you should stick to the ongoing confidentiality agreement of meetings.',
    order: 133,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 1, scene: 26, line: 'Pam, information is power.', order: 134, speaker: 'Dwight Schrute' },
  {
    season: 1,
    episode: 1,
    scene: 26,
    line: "You can't say for sure whether it'll be us or them, can you?",
    order: 135,
    speaker: 'Stanley Hudson'
  },
  {
    season: 1,
    episode: 1,
    scene: 26,
    line:
      "No, Stanley. No, you did not see me in there with her. I said if Corporate wants to come in here and interfere, then they're gonna have to go through me. Right? You can go mess with Josh's people, but I'm the head of this family, and you ain't gonna be messing with my chillin.",
    order: 136,
    speaker: 'Michael Scott'
  },
  {
    season: 1,
    episode: 1,
    scene: 27,
    line:
      "If I left, what would I do with all this useless information in my head? You know? Tonnage price of manila folders? Um, Pam's favorite flavor of yogurt, which is mixed berry.",
    order: 137,
    speaker: 'Jim Halpert'
  },
  {
    season: 1,
    episode: 1,
    scene: 28,
    line: "Jim said mixed berries? Well, yeah, he's on to me. Um... [Laughs]",
    order: 138,
    speaker: 'Pam Beesly'
  },
  {
    season: 1,
    episode: 1,
    scene: 29,
    line: 'Watch out for this guy. Dwight Schrute in the building. This is Ryan, the new temp.',
    order: 139,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 1, scene: 29, line: "What's up? Nice to meet you.", order: 140, speaker: 'Ryan Howard' },
  { season: 1, episode: 1, scene: 29, line: 'Introduce yourself. Be polite.', order: 141, speaker: 'Michael Scott' },
  {
    season: 1,
    episode: 1,
    scene: 29,
    line: 'Dwight Schrute, Assistant Regional Manager.',
    order: 142,
    speaker: 'Dwight Schrute'
  },
  {
    season: 1,
    episode: 1,
    scene: 29,
    line: 'Assistant to the Regional Manager. So, uh, Dwight tell him about the kung fu and the car and everything.',
    order: 143,
    speaker: 'Michael Scott'
  },
  {
    season: 1,
    episode: 1,
    scene: 29,
    line: "Uh... yeah I got a '78 280Z. Bought it for $1,200. Fixed it up. It's now worth three grand.",
    order: 144,
    speaker: 'Dwight Schrute'
  },
  { season: 1, episode: 1, scene: 29, line: "That's his profit.", order: 145, speaker: 'Michael Scott' },
  {
    season: 1,
    episode: 1,
    scene: 29,
    line: "New engine, new suspension, I got a respray. I've got some photos.",
    order: 146,
    speaker: 'Dwight Schrute'
  },
  { season: 1, episode: 1, scene: 30, line: 'Damn it! Jim!', order: 147, speaker: 'Dwight Schrute' },
  {
    season: 1,
    episode: 1,
    scene: 30,
    line: 'OK. Hold on, hold on. The judge is in session. What is the problem here?',
    order: 148,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 1, scene: 30, line: 'He put my stuff in Jell-O again.', order: 149, speaker: 'Dwight Schrute' },
  { season: 1, episode: 1, scene: 30, line: '[Laughing]', order: 150, speaker: 'Pam Beesly' },
  {
    season: 1,
    episode: 1,
    scene: 30,
    line: "That's real professional thanks. That's the third time and it wasn't funny the first two times either Jim.",
    order: 151,
    speaker: 'Dwight Schrute'
  },
  {
    season: 1,
    episode: 1,
    scene: 31,
    line:
      "It's OK here, but people sometimes take advantage because it's so relaxed. I'm a volunteer Sheriff's Deputy on the weekends. And you cannot screw around there. That's sort of one of the rules.",
    order: 152,
    speaker: 'Dwight Schrute'
  },
  { season: 1, episode: 1, scene: 32, line: 'What is that?', order: 153, speaker: 'Michael Scott' },
  { season: 1, episode: 1, scene: 32, line: 'That is my stapler.', order: 154, speaker: 'Dwight Schrute' },
  {
    season: 1,
    episode: 1,
    scene: 32,
    line:
      'No, no, no. Do not take it out. You have to eat it out of there, because there are starving people in the world [turns to camera] which I hate, and it is a waste of that kind of food.',
    order: 155,
    speaker: 'Michael Scott'
  },
  {
    season: 1,
    episode: 1,
    scene: 32,
    line: 'OK you know what, you can be a witness. [points to Ryan] Can you reprimand him?',
    order: 156,
    speaker: 'Dwight Schrute'
  },
  { season: 1, episode: 1, scene: 32, line: 'How do you know it was me?', order: 157, speaker: 'Jim Halpert' },
  {
    season: 1,
    episode: 1,
    scene: 32,
    line: "It's always you. Are you going to discipline him or not?",
    order: 158,
    speaker: 'Dwight Schrute'
  },
  {
    season: 1,
    episode: 1,
    scene: 32,
    line:
      "Discipline. Kinky! [Laughs] All right, here's the deal you guys. The thing about a practical joke is you have to know when to start and as well as when to stop.",
    order: 159,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 1, scene: 32, line: 'Yeah.', order: 160, speaker: 'Dwight Schrute' },
  {
    season: 1,
    episode: 1,
    scene: 32,
    line: "And yeah, Jim this is the time to stop putting Dwight's personal effects into Jell-O.",
    order: 161,
    speaker: 'Michael Scott'
  },
  {
    season: 1,
    episode: 1,
    scene: 32,
    line: "OK. Dwight, I'm sorry, because I have always been your biggest flan.",
    order: 162,
    speaker: 'Jim Halpert'
  },
  {
    season: 1,
    episode: 1,
    scene: 32,
    line: "[Laughing] Nice. That's the way it is around here. It just kind of goes round and round.",
    order: 163,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 1, scene: 32, line: "You should've put him in custardy.", order: 164, speaker: 'Ryan Howard' },
  { season: 1, episode: 1, scene: 32, line: 'Hey! Yes! New guy! He scores.', order: 165, speaker: 'Michael Scott' },
  {
    season: 1,
    episode: 1,
    scene: 32,
    line: "OK, that's great. I guess what I'm most concerned with is damage to company property. That's all.",
    order: 166,
    speaker: 'Dwight Schrute'
  },
  {
    season: 1,
    episode: 1,
    scene: 32,
    line: "Pudding. Pudding... I'm trying to think of another dessert to do.",
    order: 167,
    speaker: 'Michael Scott'
  },
  {
    season: 1,
    episode: 1,
    scene: 33,
    line: 'Do you like going out at the end of the week for a drink?',
    order: 168,
    speaker: 'Jim Halpert'
  },
  { season: 1, episode: 1, scene: 33, line: 'Yeah.', order: 169, speaker: 'Pam Beesly' },
  {
    season: 1,
    episode: 1,
    scene: 33,
    line: "That's why we're all going out. So we can have an end-of-the-week-drink.",
    order: 170,
    speaker: 'Jim Halpert'
  },
  { season: 1, episode: 1, scene: 33, line: 'So when are we going out?', order: 171, speaker: 'Pam Beesly' },
  { season: 1, episode: 1, scene: 33, line: 'Tonight, hopefully.', order: 172, speaker: 'Jim Halpert' },
  { season: 1, episode: 1, scene: 33, line: 'OK. Yeah.', order: 173, speaker: 'Pam Beesly' },
  { season: 1, episode: 1, scene: 33, line: 'Hey, man.', order: 174, speaker: 'Roy Anderson' },
  { season: 1, episode: 1, scene: 33, line: "What's going on?", order: 175, speaker: 'Jim Halpert' },
  { season: 1, episode: 1, scene: 33, line: 'Hey, baby.', order: 176, speaker: 'Roy Anderson' },
  { season: 1, episode: 1, scene: 33, line: 'Hey.', order: 177, speaker: 'Pam Beesly' },
  {
    season: 1,
    episode: 1,
    scene: 34,
    line:
      "Roy's my fiance. We've been engaged about three years. We were supposed to get married in September but I think we're gonna get married in the spring.",
    order: 178,
    speaker: 'Pam Beesly'
  },
  {
    season: 1,
    episode: 1,
    scene: 35,
    line: 'Do you mind if I go out for a drink with these guys?',
    order: 179,
    speaker: 'Pam Beesly'
  },
  {
    season: 1,
    episode: 1,
    scene: 35,
    line: "No, no. Come on. Let's get out of here and go home.",
    order: 180,
    speaker: 'Roy Anderson'
  },
  {
    season: 1,
    episode: 1,
    scene: 35,
    line: "OK. I'm gonna be a few minutes. So it's only twenty past five. I still have to do my faxes.",
    order: 181,
    speaker: 'Pam Beesly'
  },
  {
    season: 1,
    episode: 1,
    scene: 35,
    line:
      "You know what? You should come with us. Because you know we're all going out and it could be a good chance for you to see what people are like outside the office. I think it could be fun.",
    order: 182,
    speaker: 'Jim Halpert'
  },
  {
    season: 1,
    episode: 1,
    scene: 35,
    line: "It sounds good. Seriously, we've gotta get going.",
    order: 183,
    speaker: 'Roy Anderson'
  },
  { season: 1, episode: 1, scene: 35, line: 'Yeah, yeah.', order: 184, speaker: 'Jim Halpert' },
  { season: 1, episode: 1, scene: 35, line: "Um... What's in the bag?", order: 185, speaker: 'Jim Halpert' },
  {
    season: 1,
    episode: 1,
    scene: 35,
    line: "Just tell her I'll talk to her later.",
    order: 186,
    speaker: 'Roy Anderson'
  },
  {
    season: 1,
    episode: 1,
    scene: 35,
    line: 'No, definitely. All right, dude. Awesome. Will do.',
    order: 187,
    speaker: 'Jim Halpert'
  },
  {
    season: 1,
    episode: 1,
    scene: 36,
    line: "Do I think I'll be invited to the wedding? [scratches head]",
    order: 188,
    speaker: 'Jim Halpert'
  },
  {
    season: 1,
    episode: 1,
    scene: 37,
    line:
      "So have you felt the vibe yet? We work hard, we play hard. Sometimes we play hard when we should be working hard. Right? I guess the atmosphere that I've created here is that I'm a friend first, and a boss second... and probably an entertainer third. [Knock at door] Just a second. Right? Oh, hey do you like The Jamie Kennedy Experiment? Punk'd and all that kind of stuff?",
    order: 189,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 1, scene: 37, line: 'Yeah.', order: 190, speaker: 'Ryan Howard' },
  {
    season: 1,
    episode: 1,
    scene: 37,
    line: 'You are gonna be my accomplice. Just go along with it, OK?',
    order: 191,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 1, scene: 37, line: 'All right.', order: 192, speaker: 'Ryan Howard' },
  {
    season: 1,
    episode: 1,
    scene: 37,
    line: "Just follow my lead. Don't pimp me, all right? Come in. So, uh, Corporate just said that I don't want to...",
    order: 193,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 1, scene: 37, line: 'You got a fax.', order: 194, speaker: 'Pam Beesly' },
  {
    season: 1,
    episode: 1,
    scene: 37,
    line:
      'Oh, thank you. Pam, can you come in here for a sec. Just have a seat. I was gonna call you in anyway. You know Ryan. As you know, there is going to be downsizing. And you have made my life so much easier in that I am going to have to let you go first.',
    order: 195,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 1, scene: 37, line: 'What? Why?', order: 196, speaker: 'Pam Beesly' },
  { season: 1, episode: 1, scene: 37, line: 'Why? Well, theft and stealing.', order: 197, speaker: 'Michael Scott' },
  {
    season: 1,
    episode: 1,
    scene: 37,
    line: 'Stealing? What am I supposed to have stolen?',
    order: 198,
    speaker: 'Pam Beesly'
  },
  { season: 1, episode: 1, scene: 37, line: 'Post-it Notes.', order: 199, speaker: 'Michael Scott' },
  {
    season: 1,
    episode: 1,
    scene: 37,
    line: 'Post-it Notes? What are those worth, 50 cents?',
    order: 200,
    speaker: 'Pam Beesly'
  },
  {
    season: 1,
    episode: 1,
    scene: 37,
    line:
      "50 cents, yeah. If you steal a thousand Post-It Notes at 50 cents apiece, and you know, you've made a profit... margin. You're gonna run us out of business, Pam.",
    order: 201,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 1, scene: 37, line: 'Are you serious?', order: 202, speaker: 'Pam Beesly' },
  { season: 1, episode: 1, scene: 37, line: 'Yeah. I am.', order: 203, speaker: 'Michael Scott' },
  {
    season: 1,
    episode: 1,
    scene: 37,
    line: "I can't believe this. I mean I have never even stolen as much as a paperclip and you're firing me.",
    order: 204,
    speaker: 'Pam Beesly'
  },
  {
    season: 1,
    episode: 1,
    scene: 37,
    line:
      "But the best thing about it is that we're not going to have to give you any severance pay. Because that is gross misconduct and... Just clean out your desk. I'm sorry.",
    order: 205,
    speaker: 'Michael Scott'
  },
  {
    season: 1,
    episode: 1,
    scene: 37,
    line:
      "[Pam starts crying] You been X'd punk! [Laughing] Surprise! It's a joke. We were joking around. See? OK. He was in on it. He was my accomplice. And it was kind of a morale booster thing. And we were showing the new guy around, giving him the feel of the place. So you... God, we totally got you.",
    order: 206,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 1, scene: 37, line: "You're a jerk.", order: 207, speaker: 'Pam Beesly' },
  { season: 1, episode: 1, scene: 37, line: "I don't know about that.", order: 208, speaker: 'Michael Scott' },
  {
    season: 1,
    episode: 1,
    scene: 38,
    line:
      "What is the most important thing for a company? Is it the cash flow? Is it the inventory? Nuh-uh. It's the people. The people. My proudest moment here was not when I increased profits by 17% or when I cut expenses without losing a single employee. No, no, no, no, no. It was a young Guatemalan guy. First job in the country, barely spoke English. He came to me, and said, 'Mr. Scott, would you be the godfather of my child?' Wow. Wow. Didn't work out in the end. We had to let him go. He sucked.",
    order: 209,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 1, scene: 39, line: 'Hey.', order: 210, speaker: 'Pam Beesly' },
  { season: 1, episode: 1, scene: 39, line: 'Hey.', order: 211, speaker: 'Jim Halpert' },
  { season: 1, episode: 1, scene: 39, line: 'How are things?', order: 212, speaker: 'Jim Halpert' },
  {
    season: 1,
    episode: 1,
    scene: 39,
    line: 'Good. I thought you were going out for a drink with...',
    order: 213,
    speaker: 'Pam Beesly'
  },
  {
    season: 1,
    episode: 1,
    scene: 39,
    line: "Oh no, I just decided not to. How's your headache?",
    order: 214,
    speaker: 'Jim Halpert'
  },
  { season: 1, episode: 1, scene: 39, line: "It's better, thanks.", order: 215, speaker: 'Pam Beesly' },
  { season: 1, episode: 1, scene: 39, line: 'Good. Good.', order: 216, speaker: 'Jim Halpert' },
  { season: 1, episode: 1, scene: 39, line: 'Yeah.', order: 217, speaker: 'Pam Beesly' },
  { season: 1, episode: 1, scene: 39, line: "That's great", order: 218, speaker: 'Jim Halpert' },
  { season: 1, episode: 1, scene: 39, line: 'Is...?', order: 219, speaker: 'Pam Beesly' },
  { season: 1, episode: 1, scene: 39, line: 'Yeah?', order: 220, speaker: 'Jim Halpert' },
  { season: 1, episode: 1, scene: 39, line: 'Um... Are you...', order: 221, speaker: 'Pam Beesly' },
  { season: 1, episode: 1, scene: 39, line: 'Am I walking out?', order: 222, speaker: 'Jim Halpert' },
  { season: 1, episode: 1, scene: 39, line: 'Yes.', order: 223, speaker: 'Pam Beesly' },
  { season: 1, episode: 1, scene: 39, line: 'Yes, I... Do you want to...', order: 224, speaker: 'Jim Halpert' },
  { season: 1, episode: 1, scene: 39, line: 'Yeah.', order: 225, speaker: 'Pam Beesly' },
  { season: 1, episode: 1, scene: 39, line: 'Great. Let me just...', order: 226, speaker: 'Jim Halpert' },
  { season: 1, episode: 1, scene: 39, line: '[Car horn honking] Oh, Roy.', order: 227, speaker: 'Jim Halpert' },
  { season: 1, episode: 1, scene: 39, line: 'Yeah. Listen, have a nice weekend.', order: 228, speaker: 'Pam Beesly' },
  {
    season: 1,
    episode: 1,
    scene: 39,
    line: 'Yeah, definitely. You too. Enjoy it. [looks at camera] You know what, just come here.',
    order: 229,
    speaker: 'Jim Halpert'
  },
  {
    season: 1,
    episode: 2,
    scene: 1,
    line: 'Hey, uh, can I help you out in here?',
    order: 230,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 2, scene: 1, line: "Oh, I'm all set, thanks.", order: 231, speaker: 'Al Brown' },
  {
    season: 1,
    episode: 2,
    scene: 1,
    line: "Gotcha. Good. I'd go with the rows. That's a good idea.",
    order: 232,
    speaker: 'Michael Scott'
  },
  {
    season: 1,
    episode: 2,
    scene: 2,
    line:
      "Today is diversity day and someone's going to come in and talk to us about diversity. It's something that I've been pushing, that I've been wanting to push, for a long time and Corporate mandated it. And I never actually talked to Corporate about it. They kind of beat me to the punch, the bastards. But I was going to. And I think it's very important that we have this. I'm very, very excited.",
    order: 233,
    speaker: 'Michael Scott'
  },
  {
    season: 1,
    episode: 2,
    scene: 3,
    line:
      "That's the thing. It's very sturdy paper and on the back it says, '100% post-consumer content.' What? Hello? Uh-huh. Wait. What? I'm sorry, Mr. Decker. I think I'm losing you. [Shedder whirring] Hello? Hello? Yeah. Hold on one second. I don't know. Hold on one second.",
    order: 234,
    speaker: 'Jim Halpert'
  },
  {
    season: 1,
    episode: 2,
    scene: 3,
    line: 'Do you really have to do that right now?',
    order: 235,
    speaker: 'Jim Halpert'
  },
  {
    season: 1,
    episode: 2,
    scene: 3,
    line: 'Yes I do. I should have done it weeks ago actually.',
    order: 236,
    speaker: 'Dwight Schrute'
  },
  {
    season: 1,
    episode: 2,
    scene: 3,
    line:
      "Mr. Decker, I'm sorry about that. What were you... Can you hold on one second? Yeah, just one second. Thanks. [Power off, silence] Hello? That's it. Perfect. So what I was saying... [Dialing tone] Hello? Thanks, Dwight.",
    order: 237,
    speaker: 'Jim Halpert'
  },
  { season: 1, episode: 2, scene: 3, line: 'Retaliation. Tit for tit.', order: 238, speaker: 'Dwight Schrute' },
  { season: 1, episode: 2, scene: 3, line: 'That is not the expression.', order: 239, speaker: 'Jim Halpert' },
  { season: 1, episode: 2, scene: 3, line: 'Well, it should be.', order: 240, speaker: 'Dwight Schrute' },
  {
    season: 1,
    episode: 2,
    scene: 4,
    line:
      "This is my biggest sale of the year. They love me over there for some reason. I'm not really sure why but I make one call over there every year, just to renew their account, and that one call ends up being 25% of my commission for the whole year, so I buy a mini bottle of champagne, celebrate a little. And this year I'm pushing recycled paper on them for one percent more. I know. I'm getting cocky. Right?",
    order: 241,
    speaker: 'Jim Halpert'
  },
  { season: 1, episode: 2, scene: 5, line: 'Solitaire?', order: 242, speaker: 'Jim Halpert' },
  { season: 1, episode: 2, scene: 5, line: 'Yeah, Freecell.', order: 243, speaker: 'Pam Beesly' },
  { season: 1, episode: 2, scene: 5, line: 'Six on seven.', order: 244, speaker: 'Jim Halpert' },
  { season: 1, episode: 2, scene: 5, line: 'I know. I saw that.', order: 245, speaker: 'Pam Beesly' },
  { season: 1, episode: 2, scene: 5, line: "So then, why didn't you do it?", order: 246, speaker: 'Jim Halpert' },
  {
    season: 1,
    episode: 2,
    scene: 5,
    line: "I'm saving that 'cause I like it when the cards go T-ts-ts-tch-tch-tch.",
    order: 247,
    speaker: 'Pam Beesly'
  },
  { season: 1, episode: 2, scene: 5, line: "Who doesn't love that?", order: 248, speaker: 'Jim Halpert' },
  {
    season: 1,
    episode: 2,
    scene: 6,
    line: 'Hey, Oscar! How are you doing, man?',
    order: 249,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 2, scene: 6, line: 'All right.', order: 250, speaker: 'Oscar Martinez' },
  {
    season: 1,
    episode: 2,
    scene: 6,
    line: 'Did you have a good weekend going there?',
    order: 251,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 2, scene: 6, line: 'It was fine.', order: 252, speaker: 'Oscar Martinez' },
  {
    season: 1,
    episode: 2,
    scene: 6,
    line: 'Oh yeah, I bet it was fun. [to Mr. Brown] Oh, hey! This is Oscar---',
    order: 253,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 2, scene: 6, line: 'Martinez.', order: 254, speaker: 'Oscar Martinez' },
  {
    season: 1,
    episode: 2,
    scene: 6,
    line: "Right. See? I don't even know, first-name basis!",
    order: 255,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 2, scene: 6, line: "Great. We're all set.", order: 256, speaker: 'Al Brown' },
  {
    season: 1,
    episode: 2,
    scene: 6,
    line: "Oh hey, well, diversity, everybody, let's do it. Oscar works in... here. Jim, could you wrap it up, please?",
    order: 257,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 2, scene: 6, line: 'Yeah, uh, Mr. Decker, please.', order: 258, speaker: 'Jim Halpert' },
  {
    season: 1,
    episode: 2,
    scene: 6,
    line: "It's diversity day, Jim. I wish every day was diversity day.",
    order: 259,
    speaker: 'Michael Scott'
  },
  {
    season: 1,
    episode: 2,
    scene: 6,
    line: "You know what? I'm actually going to have to call you back. Thank you. Sorry about that.",
    order: 260,
    speaker: 'Jim Halpert'
  },
  {
    season: 1,
    episode: 2,
    scene: 7,
    line: 'Thank you. Thank you. Thank you. Great.',
    order: 261,
    speaker: 'Al Brown'
  },
  {
    season: 1,
    episode: 2,
    scene: 7,
    line: "Come on people, let's get 'em in. Get in the cards! Get in the cards!",
    order: 262,
    speaker: 'Michael Scott'
  },
  {
    season: 1,
    episode: 2,
    scene: 7,
    line:
      "Thank you. Thank you very much. OK. Thanks for filling these out and I promise this'll be quick. At Diversity Today, our philosophy is about honesty and positive expectations. We believe that 99% of the problems in the workplace arise simply out of ignorance.",
    order: 263,
    speaker: 'Al Brown'
  },
  {
    season: 1,
    episode: 2,
    scene: 7,
    line: "You know what? This is a color-free-zone here. Stanley, I don't look at you as another race.",
    order: 264,
    speaker: 'Michael Scott'
  },
  {
    season: 1,
    episode: 2,
    scene: 7,
    line: "Uh, see this is what I'm talking about. We don't have to pretend we're color-blind.",
    order: 265,
    speaker: 'Al Brown'
  },
  { season: 1, episode: 2, scene: 7, line: 'Exactly, were not...', order: 266, speaker: 'Michael Scott' },
  {
    season: 1,
    episode: 2,
    scene: 7,
    line: "That's fighting ignorance with more ignorance.",
    order: 267,
    speaker: 'Al Brown'
  },
  { season: 1, episode: 2, scene: 7, line: 'With tolerance.', order: 268, speaker: 'Michael Scott' },
  { season: 1, episode: 2, scene: 7, line: 'No. With more ignorance.', order: 269, speaker: 'Al Brown' },
  { season: 1, episode: 2, scene: 7, line: 'Ignorance.', order: 270, speaker: 'Michael Scott' },
  {
    season: 1,
    episode: 2,
    scene: 7,
    line: 'Right. Exactly. Uh, instead, we need to celebrate our diversity.',
    order: 271,
    speaker: 'Al Brown'
  },
  { season: 1, episode: 2, scene: 7, line: "Let's celebrate.", order: 272, speaker: 'Michael Scott' },
  { season: 1, episode: 2, scene: 7, line: 'Right. OK.', order: 273, speaker: 'Al Brown' },
  {
    season: 1,
    episode: 2,
    scene: 7,
    line: "Celebrate good times. Come on! Let's celebrate diversity. Right?",
    order: 274,
    speaker: 'Michael Scott'
  },
  {
    season: 1,
    episode: 2,
    scene: 7,
    line: "Yes, exactly. Now here's what we're going to do. I've noticed that...",
    order: 275,
    speaker: 'Al Brown'
  },
  {
    season: 1,
    episode: 2,
    scene: 7,
    line:
      "You know what? Here's what we're going to do. Why don't we go around and everybody... everybody say a race that you are attracted to sexually. I will go last. Go.",
    order: 276,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 2, scene: 7, line: 'I have two. White and Indian.', order: 277, speaker: 'Dwight Schrute' },
  {
    season: 1,
    episode: 2,
    scene: 7,
    line:
      "Actually, I'd prefer not to start that way. Michael, I would love to have your permission to run this session. Can I have your permission?",
    order: 278,
    speaker: 'Al Brown'
  },
  { season: 1, episode: 2, scene: 7, line: 'Yes.', order: 279, speaker: 'Michael Scott' },
  {
    season: 1,
    episode: 2,
    scene: 7,
    line: 'Thank you very much. And it would also help me if you were seated.',
    order: 280,
    speaker: 'Al Brown'
  },
  { season: 1, episode: 2, scene: 7, line: 'OK.', order: 281, speaker: 'Michael Scott' },
  {
    season: 1,
    episode: 2,
    scene: 7,
    line:
      "Thank you. OK. Now, at the start of the session, I had you all write down an incident that you found offensive in the workplace. Now, what I'm going to do is choose one and we're going to act it out.",
    order: 282,
    speaker: 'Al Brown'
  },
  { season: 1, episode: 2, scene: 7, line: 'A few of the ground rules?', order: 283, speaker: 'Dwight Schrute' },
  {
    season: 1,
    episode: 2,
    scene: 7,
    line: "Hey, hey why don't you run it by me and I'll run it by him.",
    order: 284,
    speaker: 'Michael Scott'
  },
  {
    season: 1,
    episode: 2,
    scene: 7,
    line: 'OK, can we steer away from gay people?',
    order: 285,
    speaker: 'Dwight Schrute'
  },
  { season: 1, episode: 2, scene: 7, line: 'Um...', order: 286, speaker: 'Al Brown' },
  {
    season: 1,
    episode: 2,
    scene: 7,
    line:
      "I'm sorry. It's an orientation. It's not a race. Plus a lot of other races are intolerant of gays, so...paradox.",
    order: 287,
    speaker: 'Dwight Schrute'
  },
  { season: 1, episode: 2, scene: 7, line: 'Well, we only have an hour.', order: 288, speaker: 'Al Brown' },
  { season: 1, episode: 2, scene: 7, line: 'I figured it would save time.', order: 289, speaker: 'Dwight Schrute' },
  {
    season: 1,
    episode: 2,
    scene: 7,
    line: "OK. Why don't we just defer to Mr...",
    order: 290,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 2, scene: 7, line: 'Mr. Brown.', order: 291, speaker: 'Al Brown' },
  {
    season: 1,
    episode: 2,
    scene: 7,
    line: 'Ah. Oh, right! OK. First test. I will not call you that.',
    order: 292,
    speaker: 'Michael Scott'
  },
  {
    season: 1,
    episode: 2,
    scene: 7,
    line:
      "Well, it's my name. It's not a test. OK? Um, so looking through the cards, I've noticed that many of you wrote down the same incident, which is ironic, because it's the exact incident I was brought in here to respond to. Now, how many of you are familiar with the Chris Rock routine? Very good. OK.",
    order: 293,
    speaker: 'Al Brown'
  },
  {
    season: 1,
    episode: 2,
    scene: 8,
    line:
      "How come Chris Rock can do a routine and everybody finds it hilarious and ground-breaking and then I go and do the exact same routine, same comedic timing, and people file a complaint to Corporate? Is it because I'm white and Chris is black?",
    order: 294,
    speaker: 'Michael Scott'
  },
  {
    season: 1,
    episode: 2,
    scene: 9,
    line: "So we're going to reenact this with a more positive outcome.",
    order: 295,
    speaker: 'Al Brown'
  },
  {
    season: 1,
    episode: 2,
    scene: 9,
    line: 'I will play the Chris Rock guy. I would like to see someone else pull this off.',
    order: 296,
    speaker: 'Michael Scott'
  },
  {
    season: 1,
    episode: 2,
    scene: 9,
    line: "Well, let's have someone who wasn't involved in the reenactment.",
    order: 297,
    speaker: 'Al Brown'
  },
  { season: 1, episode: 2, scene: 9, line: 'OK, I will play guy listening.', order: 298, speaker: 'Michael Scott' },
  {
    season: 1,
    episode: 2,
    scene: 9,
    line: 'Great. Guy listening. Ok, anyone else remember?',
    order: 299,
    speaker: 'Al Brown'
  },
  { season: 1, episode: 2, scene: 9, line: 'I remember.', order: 300, speaker: 'Kevin Malone' },
  {
    season: 1,
    episode: 2,
    scene: 9,
    line: "Great. You're the Chris Rock guy and you're guy listening.",
    order: 301,
    speaker: 'Al Brown'
  },
  { season: 1, episode: 2, scene: 9, line: 'OK.', order: 302, speaker: 'Michael Scott' },
  {
    season: 1,
    episode: 2,
    scene: 10,
    line: "Kevin is a great guy. He's a great accountant. He is not much of an entertainer.",
    order: 303,
    speaker: 'Michael Scott'
  },
  {
    season: 1,
    episode: 2,
    scene: 11,
    line:
      'Basically, there are two types of black people and black people are actually more racist because they hate the other type of black people. Every time the one type wants to have a good time, then the other type comes in and makes a real mess.',
    order: 304,
    speaker: 'Kevin Malone'
  },
  {
    season: 1,
    episode: 2,
    scene: 11,
    line:
      "OK. I'm sorry. I'm sorry. He's ruin... He's butchering it. Could you just let me... [As Chris Rock] Every time... Every time black people want to have a good time, some ignant ass... [Bleep] I take care of my kid!",
    order: 305,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 2, scene: 11, line: 'Wait a second.', order: 306, speaker: 'Al Brown' },
  {
    season: 1,
    episode: 2,
    scene: 11,
    line: '[Bleep] They always want credit for something they supPOSED to do!',
    order: 307,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 2, scene: 11, line: 'Stop it!', order: 308, speaker: 'Al Brown' },
  {
    season: 1,
    episode: 2,
    scene: 11,
    line: '[As Chris Rock] What you want a cookie?',
    order: 309,
    speaker: 'Michael Scott'
  },
  {
    season: 1,
    episode: 2,
    scene: 12,
    line:
      'Now, this is a simple acronym. HERO. Uh, at Diversity Today, we believe it is very easy to be a HERO. All you need are honesty, empathy, respect and open-mindedness.',
    order: 310,
    speaker: 'Al Brown'
  },
  {
    season: 1,
    episode: 2,
    scene: 12,
    line: "Excuse me, I'm sorry, but that's not all it takes to be a hero.",
    order: 311,
    speaker: 'Dwight Schrute'
  },
  {
    season: 1,
    episode: 2,
    scene: 12,
    line: 'Oh, great. Well, what is a hero to you?',
    order: 312,
    speaker: 'Al Brown'
  },
  {
    season: 1,
    episode: 2,
    scene: 12,
    line: 'A hero kills people, people that wish him harm.',
    order: 313,
    speaker: 'Dwight Schrute'
  },
  { season: 1, episode: 2, scene: 12, line: 'OK.', order: 314, speaker: 'Al Brown' },
  {
    season: 1,
    episode: 2,
    scene: 12,
    line:
      'A hero is part-human and part-supernatural. A hero is born out of a childhood trauma, or out of a disaster that must be avenged.',
    order: 315,
    speaker: 'Dwight Schrute'
  },
  { season: 1, episode: 2, scene: 12, line: "Ok, you're thinking of a superhero.", order: 316, speaker: 'Al Brown' },
  { season: 1, episode: 2, scene: 12, line: 'We all have a hero in our heart.', order: 317, speaker: 'Dwight Schrute' },
  {
    season: 1,
    episode: 2,
    scene: 12,
    line:
      "Now, I need you to take these forms. This kind of expresses the joint experience we had today. And I need you to look 'em over and sign them as kind of a group pledge.",
    order: 318,
    speaker: 'Al Brown'
  },
  {
    season: 1,
    episode: 2,
    scene: 12,
    line: "[Clears throat] I don't think I can sign this.",
    order: 319,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 2, scene: 12, line: "I can't leave until you do.", order: 320, speaker: 'Al Brown' },
  {
    season: 1,
    episode: 2,
    scene: 12,
    line:
      'Well, OK, it says here that I learned something and I knew all this stuff already, so... I know, I could sign something that says that I taught something, or that I helped you teach something, so... Pam! Where is she? Pam, could we change something on this?',
    order: 321,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 2, scene: 12, line: 'Michael, can I talk to you candidly?', order: 322, speaker: 'Al Brown' },
  { season: 1, episode: 2, scene: 12, line: 'Sure.', order: 323, speaker: 'Michael Scott' },
  {
    season: 1,
    episode: 2,
    scene: 13,
    line: "We both know that I'm here because of the comments you made.",
    order: 324,
    speaker: 'Al Brown'
  },
  {
    season: 1,
    episode: 2,
    scene: 13,
    line:
      "Here's the thing. This office, I think this is very advanced in terms of... racial awareness and it's probably more advanced than you're used to. That's probably throwing you off a little bit.",
    order: 325,
    speaker: 'Michael Scott'
  },
  {
    season: 1,
    episode: 2,
    scene: 13,
    line: "Um, it's not throwing me. I need your signature.",
    order: 326,
    speaker: 'Al Brown'
  },
  {
    season: 1,
    episode: 2,
    scene: 13,
    line: 'OK, well I know. You told me that several times.',
    order: 327,
    speaker: 'Michael Scott'
  },
  {
    season: 1,
    episode: 2,
    scene: 13,
    line: "Yes, but you're not listening to me. Yours is the only signature I need.",
    order: 328,
    speaker: 'Al Brown'
  },
  { season: 1, episode: 2, scene: 13, line: 'OK.', order: 329, speaker: 'Michael Scott' },
  {
    season: 1,
    episode: 2,
    scene: 13,
    line:
      "Those are my instructions from the Corporate offices to put you through this seminar for the comments that you made. The reason I made copies for everyone was so you wouldn't be embarrassed.",
    order: 330,
    speaker: 'Al Brown'
  },
  {
    season: 1,
    episode: 2,
    scene: 13,
    line: "Well, here I am thinking that you actually cared about diversity training. And you don't.",
    order: 331,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 2, scene: 13, line: "Don't worry about dating.", order: 332, speaker: 'Al Brown' },
  { season: 1, episode: 2, scene: 13, line: "I won't.", order: 333, speaker: 'Michael Scott' },
  { season: 1, episode: 2, scene: 13, line: 'OK. Thank you.', order: 334, speaker: 'Al Brown' },
  { season: 1, episode: 2, scene: 13, line: 'Yeah, yeah.', order: 335, speaker: 'Michael Scott' },
  {
    season: 1,
    episode: 2,
    scene: 14,
    line:
      "I regret my actions. I regret offending my coworkers. I pledge to bring my best spirit of honesty, empathy, respect and open-mindedness...' Open-mindedness, is that even a word? '...into the workplace. In this way, I can truly be a hero. Signed, Daffy Duck.' [Laughing] He's going to lose it when he reads that.",
    order: 336,
    speaker: 'Michael Scott'
  },
  {
    season: 1,
    episode: 2,
    scene: 15,
    line: 'Yeah, hi. Is Mr. Decker around? Oh, well, could you just have him call me after lunch? Thank you.',
    order: 337,
    speaker: 'Jim Halpert'
  },
  {
    season: 1,
    episode: 2,
    scene: 15,
    line:
      "I pledge to always keep an open mind and an open heart.' I do believe... in that part of the pledge I that just read. But a pledge? Come on. I mean who are we, the Girl Scouts? No. Look... the guy, 'Mr. Brown,' he got us halfway there. He got us talking. Well, no. I got us talking. He got us nothing. He insulted us and he abandoned us. You call that diversity training? I don't. Were there any connections between any of us? Did anyone look each other in the eye? Was there any emotion going on? No. Where was the heart? I didn't see any heart. Where was my Oprah moment? OK, get as much done as you can before lunch because, afterward, I'm going to have you all in tears.",
    order: 338,
    speaker: 'Michael Scott'
  },
  {
    season: 1,
    episode: 2,
    scene: 16,
    line: "All right? Everybody pretty? Come on. Here we go. It's time. Let's do some good.",
    order: 339,
    speaker: 'Michael Scott'
  },
  {
    season: 1,
    episode: 2,
    scene: 16,
    line: "Hey, we're not all going to sit in a circle Indian style are we? [Laughing]",
    order: 340,
    speaker: 'Toby Flenderson'
  },
  { season: 1, episode: 2, scene: 16, line: 'Get out.', order: 341, speaker: 'Michael Scott' },
  { season: 1, episode: 2, scene: 16, line: "I'm sorry.", order: 342, speaker: 'Toby Flenderson' },
  {
    season: 1,
    episode: 2,
    scene: 16,
    line:
      "No, this is not a joke. OK? That was offensive and lame. So double offensive. This is an environment of welcoming and you should just get the hell out of here. OK, let's go. Let's do it. Come on. Let's have some fun, everybody. Here we go. Take a seat. Cop a squat. And um... thanks for coming in. Um... Diversity... is the cornerstone of progress as I've always said. But don't take my word for it. Let's take a look at the tape.",
    order: 343,
    speaker: 'Michael Scott'
  },
  {
    season: 1,
    episode: 2,
    scene: 17,
    line:
      "[on the tape] Hi. I'm Michael Scott. I'm in charge of Dunder Mifflin Paper Products here in Scranton, Pennsylvania but I'm also the founder of Diversity Tomorrow, because today is almost over. Abraham Lincoln once said that, 'If you're a racist, I will attack you with the North.' And those are the principles that I carry with me in the workplace.",
    order: 344,
    speaker: 'Michael Scott'
  },
  {
    season: 1,
    episode: 2,
    scene: 18,
    line: 'OK. Questions? Comments? Anybody? Jim?',
    order: 345,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 2, scene: 18, line: 'Uh, is that it?', order: 346, speaker: 'Jim Halpert' },
  {
    season: 1,
    episode: 2,
    scene: 18,
    line: "Yes. I only had an hour to put it together but I'm going to add on to it later on.",
    order: 347,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 2, scene: 18, line: 'It was kind of hard to hear.', order: 348, speaker: 'Kevin Malone' },
  {
    season: 1,
    episode: 2,
    scene: 18,
    line: 'Uh, yes. That probably had something to do with the camera work. Anybody else? Um...',
    order: 349,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 2, scene: 18, line: 'I have a customer meeting.', order: 350, speaker: 'Kelly Kapoor' },
  {
    season: 1,
    episode: 2,
    scene: 18,
    line:
      "Yeah, well, if you leave we'll only have two left. Yes. Enjoy. Absolutely. Namaste. Ok, well since I am leading this, let's get down to business and why don't I just kind of introduce myself, OK? Um. I am Michael and I am part English, Irish, German and Scottish. Sort of a virtual United Nations. But what some of you might not know is that I am also part Native American Indian.",
    order: 351,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 2, scene: 18, line: 'What part Native American?', order: 352, speaker: 'Oscar Martinez' },
  { season: 1, episode: 2, scene: 18, line: 'Two fifteenths.', order: 353, speaker: 'Michael Scott' },
  {
    season: 1,
    episode: 2,
    scene: 18,
    line: "Two fifteenths, that fraction doesn't make any sense.",
    order: 354,
    speaker: 'Oscar Martinez'
  },
  {
    season: 1,
    episode: 2,
    scene: 18,
    line:
      "Well, you know what, it's kind of hard for me to talk about it. Their suffering. So who else? Let's get this popping. Come on. Who's going? Who's going? Let's go here. Oscar, right here. You're on.",
    order: 355,
    speaker: 'Michael Scott'
  },
  {
    season: 1,
    episode: 2,
    scene: 18,
    line: 'OK, Michael, um... Both my parents were born in Mexico.',
    order: 356,
    speaker: 'Oscar Martinez'
  },
  { season: 1, episode: 2, scene: 18, line: 'Oh, yeah...', order: 357, speaker: 'Michael Scott' },
  {
    season: 1,
    episode: 2,
    scene: 18,
    line: 'And, uh, they moved to the United Sates a year before I was born. So I grew up in the United States.',
    order: 358,
    speaker: 'Oscar Martinez'
  },
  { season: 1, episode: 2, scene: 18, line: 'Wow.', order: 359, speaker: 'Michael Scott' },
  { season: 1, episode: 2, scene: 18, line: 'My parents were Mexican.', order: 360, speaker: 'Oscar Martinez' },
  {
    season: 1,
    episode: 2,
    scene: 18,
    line: "Wow. That is... That is a great story. That's the American Dream right there, right?",
    order: 361,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 2, scene: 18, line: 'Thank... Yeah...', order: 362, speaker: 'Oscar Martinez' },
  {
    season: 1,
    episode: 2,
    scene: 18,
    line: 'Um, let me ask you, is there a term besides Mexican that you prefer? Something less offensive?',
    order: 363,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 2, scene: 18, line: "Mexican isn't offensive.", order: 364, speaker: 'Oscar Martinez' },
  {
    season: 1,
    episode: 2,
    scene: 18,
    line: 'Well, it has certain connotations.',
    order: 365,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 2, scene: 18, line: 'Like what?', order: 366, speaker: 'Oscar Martinez' },
  { season: 1, episode: 2, scene: 18, line: "Like... I don't... I don't know.", order: 367, speaker: 'Michael Scott' },
  {
    season: 1,
    episode: 2,
    scene: 18,
    line: 'What connotations, Michael? You meant something.',
    order: 368,
    speaker: 'Oscar Martinez'
  },
  { season: 1, episode: 2, scene: 18, line: 'No. Now, remember that honesty...', order: 369, speaker: 'Michael Scott' },
  { season: 1, episode: 2, scene: 18, line: "I'm just curious.", order: 370, speaker: 'Oscar Martinez' },
  {
    season: 1,
    episode: 2,
    scene: 18,
    line: '...empathy, respect... [Phone ringing] Jim! Jim!',
    order: 371,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 2, scene: 18, line: 'Hello? Hello?', order: 372, speaker: 'Jim Halpert' },
  {
    season: 1,
    episode: 2,
    scene: 19,
    line:
      "I have something here. I want you to take a card. Put it on your fore... Don't look at the card. I want you to take the card and put it on your forehead and... Take a card, take a card, any card. Um... And I want you to treat other people like the race that is on their forehead. OK? So everybody has a different race. Nobody knows what their race is, so... I want you to really go for it, cause this is real. You know, this isn't just an exercise. This is real life. And... I have a dream that you will really let the sparks fly. Get 'er done.",
    order: 373,
    speaker: 'Michael Scott'
  },
  {
    season: 1,
    episode: 2,
    scene: 20,
    line:
      "Why? Because Martin Luther King is a hero of mine. There's this great Chris Rock bit about how streets named after Martin Luther King tend to be more violent. I'm not going to do it but it's...",
    order: 374,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 2, scene: 21, line: 'Oh this is a good one.', order: 375, speaker: 'Michael Scott' },
  { season: 1, episode: 2, scene: 21, line: 'Um, hi. How are you?', order: 376, speaker: 'Pam Beesly' },
  { season: 1, episode: 2, scene: 21, line: 'Fine. How are you?', order: 377, speaker: 'Stanley Hudson' },
  { season: 1, episode: 2, scene: 21, line: 'Great.', order: 378, speaker: 'Pam Beesly' },
  { season: 1, episode: 2, scene: 21, line: 'Push it.', order: 379, speaker: 'Michael Scott' },
  {
    season: 1,
    episode: 2,
    scene: 21,
    line: "I admire your culture's success in America.",
    order: 380,
    speaker: 'Stanley Hudson'
  },
  { season: 1, episode: 2, scene: 21, line: 'Thank you.', order: 381, speaker: 'Pam Beesly' },
  {
    season: 1,
    episode: 2,
    scene: 21,
    line: 'Good. Bom bom bom-bom bom. Come on Olympics of Suffering right here. Slavery versus the Holocaust. Come on.',
    order: 382,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 2, scene: 21, line: 'Who am I supposed to be?', order: 383, speaker: 'Stanley Hudson' },
  {
    season: 1,
    episode: 2,
    scene: 21,
    line: "No, that was inadvertent. We didn't actually plan that.",
    order: 384,
    speaker: 'Michael Scott'
  },
  {
    season: 1,
    episode: 2,
    scene: 21,
    line: "Lots of cultures eat rice, doesn't help me.",
    order: 385,
    speaker: 'Dwight Schrute'
  },
  {
    season: 1,
    episode: 2,
    scene: 21,
    line: "Um... Shalom. I'd like to apply for a loan.",
    order: 386,
    speaker: 'Dwight Schrute'
  },
  { season: 1, episode: 2, scene: 21, line: "That's nice, Dwight.", order: 387, speaker: 'Pam Beesly' },
  {
    season: 1,
    episode: 2,
    scene: 21,
    line: 'OK, do me. Something stereotypical so I can get it really quick.',
    order: 388,
    speaker: 'Dwight Schrute'
  },
  { season: 1, episode: 2, scene: 21, line: 'OK, I like your food.', order: 389, speaker: 'Pam Beesly' },
  {
    season: 1,
    episode: 2,
    scene: 21,
    line: "Outback steakhouse. [Australian accent] I'm Australian, mate!",
    order: 390,
    speaker: 'Dwight Schrute'
  },
  {
    season: 1,
    episode: 2,
    scene: 21,
    line:
      "Pam, come on. 'I like your food.' Come on stir the pot. Stir the melting pot, Pam! Let's do it. Let's get ugly. Let's get real.",
    order: 391,
    speaker: 'Michael Scott'
  },
  {
    season: 1,
    episode: 2,
    scene: 21,
    line:
      'OK. If I have to do this, based on stereotypes that are totally untrue, that I do not agree with, you would maybe not be a very good driver.',
    order: 392,
    speaker: 'Pam Beesly'
  },
  { season: 1, episode: 2, scene: 21, line: 'Oh, man, am I a woman?', order: 393, speaker: 'Dwight Schrute' },
  {
    season: 1,
    episode: 2,
    scene: 22,
    line:
      "You'll notice I didn't have anybody be an Arab. I thought that would be too explosive. No pun intended. But I just though. 'Too soon for Arabs.' Maybe next year. Um... You know, the ball's in their court.",
    order: 394,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 2, scene: 23, line: 'What are you watching?', order: 395, speaker: 'Jim Halpert' },
  { season: 1, episode: 2, scene: 23, line: "Chappelle's Show.", order: 396, speaker: 'Ryan Howard' },
  { season: 1, episode: 2, scene: 23, line: 'Really?', order: 397, speaker: 'Jim Halpert' },
  {
    season: 1,
    episode: 2,
    scene: 23,
    line: "I downloaded it on her computer. I hope she doesn't mind. She just had a lot of extra space.",
    order: 398,
    speaker: 'Ryan Howard'
  },
  {
    season: 1,
    episode: 2,
    scene: 23,
    line: 'No way. I think she likes this stuff.',
    order: 399,
    speaker: 'Jim Halpert'
  },
  { season: 1, episode: 2, scene: 23, line: "Great. She's cute, huh?", order: 400, speaker: 'Ryan Howard' },
  {
    season: 1,
    episode: 2,
    scene: 23,
    line: "Yeah, you know, she's engaged, but...",
    order: 401,
    speaker: 'Jim Halpert'
  },
  { season: 1, episode: 2, scene: 23, line: 'Oh, no, the girl in the... sketch.', order: 402, speaker: 'Ryan Howard' },
  { season: 1, episode: 2, scene: 23, line: "Oh, yeah. She's hot.", order: 403, speaker: 'Jim Halpert' },
  { season: 1, episode: 2, scene: 24, line: 'Hey.', order: 404, speaker: 'Kevin Malone' },
  { season: 1, episode: 2, scene: 24, line: 'Hey.', order: 405, speaker: 'Angela Martin' },
  { season: 1, episode: 2, scene: 24, line: 'You wanna go to the beach?', order: 406, speaker: 'Kevin Malone' },
  { season: 1, episode: 2, scene: 24, line: 'Sure.', order: 407, speaker: 'Angela Martin' },
  { season: 1, episode: 2, scene: 24, line: 'You wanna get high?', order: 408, speaker: 'Kevin Malone' },
  { season: 1, episode: 2, scene: 24, line: 'No.', order: 409, speaker: 'Angela Martin' },
  { season: 1, episode: 2, scene: 24, line: 'I think you do, mon.', order: 410, speaker: 'Kevin Malone' },
  { season: 1, episode: 2, scene: 24, line: 'Stop...', order: 411, speaker: 'Angela Martin' },
  {
    season: 1,
    episode: 2,
    scene: 24,
    line: "OK. All right. No. It's good. You just need to push it. You need to go a little bit further. All right. OK.",
    order: 412,
    speaker: 'Michael Scott'
  },
  {
    season: 1,
    episode: 2,
    scene: 25,
    line: '[Voice raised, Indian accent] Kelly, how are you?',
    order: 413,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 2, scene: 25, line: 'I just had the longest meeting.', order: 414, speaker: 'Kelly Kapoor' },
  {
    season: 1,
    episode: 2,
    scene: 25,
    line:
      'Oh! Welcome to my convenience store. Would you like some googi googi? I have some very delicious googi, googi, only 99 cents plus tax. Try my googi, googi. [Lowering voice] Try my googi, googi. [High-pitched voice] Try my googi, googi. Try my... [slap!]',
    order: 415,
    speaker: 'Michael Scott'
  },
  {
    season: 1,
    episode: 2,
    scene: 25,
    line:
      "[trying not to cry] All right! All right! Yes! That was great, she gets it! Now she knows what it's like to be a minority.",
    order: 416,
    speaker: 'Michael Scott'
  },
  {
    season: 1,
    episode: 2,
    scene: 26,
    line:
      "[on the phone] Mr. Decker, we didn't lose your sale today, did we? Excellent. OK. Let me just get your... what's that? No, we didn't close last time. I just need your... Oh. W-What code were you given? Oh, OK. That's actually another salesman here. I can redo it if you want to do that. Oh, he gave you a discount? No, I don't blame you.",
    order: 417,
    speaker: 'Jim Halpert'
  },
  {
    season: 1,
    episode: 2,
    scene: 27,
    line:
      'I just hated it when that guy was in here. Mr. Brown, if that was his real name. I mean, he had never met any of us before, and here he was telling us how to do our thing. I just wanted... I just wanted to do it our way. You know? On our own. Man I should have gotten some food.',
    order: 418,
    speaker: 'Michael Scott'
  },
  {
    season: 1,
    episode: 2,
    scene: 27,
    line: '[Itialian accent]Maybe some spagh-etti.',
    order: 419,
    speaker: 'Kevin Malone'
  },
  {
    season: 1,
    episode: 2,
    scene: 27,
    line:
      "Okay, Kevin. You can take that off that thing, OK? That would really, really have shown him up, wouldn't it? If I'd brought in some burritos or some colored greens. Or some pad Thai. I love pad Thai.",
    order: 420,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 2, scene: 27, line: "It's collard greens.", order: 421, speaker: 'Stanley Hudson' },
  { season: 1, episode: 2, scene: 27, line: 'What?', order: 422, speaker: 'Michael Scott' },
  { season: 1, episode: 2, scene: 27, line: "It's collard greens.", order: 423, speaker: 'Stanley Hudson' },
  {
    season: 1,
    episode: 2,
    scene: 27,
    line:
      "That doesn't really make sense. Because you don't call them collared people, that's offensive. Hmmm... OK, well, it's after five. So... Thank you very much. Buena vista Oscar. Thank you. Good job. Oh, my man. Thank you Brazil. Nice.",
    order: 424,
    speaker: 'Michael Scott'
  },
  {
    season: 1,
    episode: 2,
    scene: 28,
    line: "[Pam is asleep, resting her head on Jim's shoulder] Um... Hey.",
    order: 425,
    speaker: 'Jim Halpert'
  },
  { season: 1, episode: 2, scene: 28, line: '[stirs] Mmmm.', order: 426, speaker: 'Pam Beesly' },
  { season: 1, episode: 2, scene: 28, line: 'Hey.', order: 427, speaker: 'Jim Halpert' },
  { season: 1, episode: 2, scene: 28, line: 'Oh.', order: 428, speaker: 'Pam Beesly' },
  { season: 1, episode: 2, scene: 28, line: 'We can go.', order: 429, speaker: 'Jim Halpert' },
  { season: 1, episode: 2, scene: 28, line: 'Sorry.', order: 430, speaker: 'Pam Beesly' },
  { season: 1, episode: 2, scene: 28, line: "That's fine.", order: 431, speaker: 'Jim Halpert' },
  { season: 1, episode: 2, scene: 29, line: 'Uh... Not a bad day.', order: 432, speaker: 'Jim Halpert' },
  {
    season: 1,
    episode: 2,
    scene: 33,
    line: "It would've been just as easy for him to spell insect. Of course, that wouldn't have made any sense either.",
    order: 433,
    speaker: 'Pam Beesly'
  },
  {
    season: 1,
    episode: 2,
    scene: 35,
    line: "Yeah, um, there's good things about Michael. He uh, uh... Yeah, definitely. Um...",
    order: 434,
    speaker: 'Pam Beesly'
  },
  {
    season: 1,
    episode: 2,
    scene: 41,
    line:
      "Talk like you're talking to that race. I have a feeling that this could get kind of volatile. So keep it going. [camera shows Devon sitting outside with West Nile]",
    order: 435,
    speaker: 'Michael Scott'
  },
  {
    season: 1,
    episode: 2,
    scene: 43,
    line: "[Dwight's new race is 'Dwight'] I could be French.",
    order: 436,
    speaker: 'Dwight Schrute'
  },
  {
    season: 1,
    episode: 2,
    scene: 44,
    line: "[takes his 'Dwight' race off his forehead] Damn it, Jim! That's not funny, Jim!",
    order: 437,
    speaker: 'Dwight Schrute'
  },
  {
    season: 1,
    episode: 2,
    scene: 44,
    line: 'Oh, okay. Here we go, breakthrough radar. What happened? What happened here?',
    order: 438,
    speaker: 'Michael Scott'
  },
  {
    season: 1,
    episode: 2,
    scene: 44,
    line: "It didn't have anything to do with race.",
    order: 439,
    speaker: 'Pam Beesly'
  },
  {
    season: 1,
    episode: 2,
    scene: 44,
    line: "Okay, all right. Let's keep on track. Keep on point. Let's do it.",
    order: 440,
    speaker: 'Michael Scott'
  },
  {
    season: 1,
    episode: 2,
    scene: 46,
    line:
      "Try my googi, googi. [Lowering voice] Try my googi, googi. [High-pitched voice] Try my googi, googi. Try my... [Kelly slaps Michael] All right! All right! Yes! That was great, she gets it! Kelly thank you. [claps] She's not here, but she gets it. That's what we have been looking for. The whole time. [trying not to cry] Oh, man. This is what I thrive on. You know? It's like Don Rickles on acid, man. Right?",
    order: 441,
    speaker: 'Michael Scott'
  },
  {
    season: 1,
    episode: 2,
    scene: 46,
    line: 'Um, why did she slap Martin Luther King?',
    order: 442,
    speaker: 'Jim Halpert'
  },
  { season: 1, episode: 2, scene: 46, line: 'What, huh?', order: 443, speaker: 'Michael Scott' },
  { season: 1, episode: 2, scene: 46, line: 'What card was she?', order: 444, speaker: 'Pam Beesly' },
  { season: 1, episode: 2, scene: 46, line: "I think she wasn't wearing a card.", order: 445, speaker: 'Jim Halpert' },
  {
    season: 1,
    episode: 2,
    scene: 46,
    line: "It's good. This is good. We got it happening now. All right? Let's keep it rolling. Let's round it up.",
    order: 446,
    speaker: 'Michael Scott'
  },
  {
    season: 1,
    episode: 3,
    scene: 1,
    line: 'Pam. Pamela. Pam-elama-ding-dong. Making copies.',
    order: 447,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 3, scene: 1, line: "I'm not making any copies.", order: 448, speaker: 'Pam Beesly' },
  {
    season: 1,
    episode: 3,
    scene: 1,
    line: "Let's go. Messages. Stat. Lots to do, lots to do. Information superhighway.",
    order: 449,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 3, scene: 1, line: 'Nothing new.', order: 450, speaker: 'Pam Beesly' },
  { season: 1, episode: 3, scene: 1, line: 'Lay them on me. What?', order: 451, speaker: 'Michael Scott' },
  { season: 1, episode: 3, scene: 1, line: "There's nothing new.", order: 452, speaker: 'Pam Beesly' },
  { season: 1, episode: 3, scene: 1, line: "That's not what you said earlier.", order: 453, speaker: 'Michael Scott' },
  {
    season: 1,
    episode: 3,
    scene: 1,
    line: 'Oh, do you want me to repeat the messages that I gave you before for the... [nods toward camera]',
    order: 454,
    speaker: 'Pam Beesly'
  },
  {
    season: 1,
    episode: 3,
    scene: 2,
    line:
      "The most sacred thing I do is care and provide for my workers, my family. I give them money. I give them food. Not directly, but through the money. I heal them. Today, I am in charge of picking a great new health care plan. Right? That's what this is all about. Does that make me their doctor? Um... Yes, in a way. Yeah, like a specialist.",
    order: 455,
    speaker: 'Michael Scott'
  },
  {
    season: 1,
    episode: 3,
    scene: 3,
    line: 'So, which health plan have you decided on?',
    order: 456,
    speaker: 'Jan Levinson'
  },
  {
    season: 1,
    episode: 3,
    scene: 3,
    line:
      'I am going to go with the best, Jan. I am going to go with the one with the acupuncture, therapeutic massage, you know, the works.',
    order: 457,
    speaker: 'Michael Scott'
  },
  {
    season: 1,
    episode: 3,
    scene: 3,
    line: 'Wait, acupuncture? None of the plans have acupuncture. Have you looked at them closely Michael?',
    order: 458,
    speaker: 'Jan Levinson'
  },
  {
    season: 1,
    episode: 3,
    scene: 3,
    line: "I think it was you who didn't look closely enough at the Gold Plan.",
    order: 459,
    speaker: 'Michael Scott'
  },
  {
    season: 1,
    episode: 3,
    scene: 3,
    line: "The Gold Plan? I'm not even on that plan.",
    order: 460,
    speaker: 'Jan Levinson'
  },
  {
    season: 1,
    episode: 3,
    scene: 3,
    line: "Well, I'd recommend it. It's very good.",
    order: 461,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 3, scene: 3, line: 'Michael.', order: 462, speaker: 'Jan Levinson' },
  { season: 1, episode: 3, scene: 3, line: 'You gotta crack these things open.', order: 463, speaker: 'Michael Scott' },
  {
    season: 1,
    episode: 3,
    scene: 3,
    line:
      "You know the whole reason that we're doing this, is to save money. So you just need to pick a provider and choose the cheapest plan.",
    order: 464,
    speaker: 'Jan Levinson'
  },
  {
    season: 1,
    episode: 3,
    scene: 3,
    line: "Well, that is kind of a tough assignment. Um... It won't be popular decision around the old orifice.",
    order: 465,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 3, scene: 3, line: "It's your job. So...", order: 466, speaker: 'Jan Levinson' },
  {
    season: 1,
    episode: 3,
    scene: 3,
    line: "Well, it's a suicide mission, you know.",
    order: 467,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 3, scene: 3, line: 'Michael... maybe... I mean...', order: 468, speaker: 'Jan Levinson' },
  { season: 1, episode: 3, scene: 3, line: 'There, there...', order: 469, speaker: 'Michael Scott' },
  {
    season: 1,
    episode: 3,
    scene: 3,
    line: 'Sometimes a manager, like yourself, has to deliver the bad news to the employees. I do it all the time.',
    order: 470,
    speaker: 'Jan Levinson'
  },
  {
    season: 1,
    episode: 3,
    scene: 3,
    line: '[scoffs] When have you ever done that?',
    order: 471,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 3, scene: 3, line: "I'm doing it right now. To you.", order: 472, speaker: 'Jan Levinson' },
  {
    season: 1,
    episode: 3,
    scene: 4,
    line: "Last night on Trading Spouses, there's... have you seen it?",
    order: 473,
    speaker: 'Jim Halpert'
  },
  { season: 1, episode: 3, scene: 4, line: 'No. I have a life.', order: 474, speaker: 'Pam Beesly' },
  { season: 1, episode: 3, scene: 4, line: "Interesting, what's that like?", order: 475, speaker: 'Jim Halpert' },
  { season: 1, episode: 3, scene: 4, line: 'You should try it sometime.', order: 476, speaker: 'Pam Beesly' },
  {
    season: 1,
    episode: 3,
    scene: 4,
    line: 'Wow. But then who would watch my TV? That is...',
    order: 477,
    speaker: 'Jim Halpert'
  },
  { season: 1, episode: 3, scene: 4, line: '[laughs]... your problem.', order: 478, speaker: 'Pam Beesly' },
  { season: 1, episode: 3, scene: 4, line: 'Jimbo! Ha haaaaa. Ah.', order: 479, speaker: 'Michael Scott' },
  {
    season: 1,
    episode: 3,
    scene: 5,
    line:
      "There's a decision that needs to be made, and I'm having an unbelievably a busy day. So I'm going to let you pick a health care plan for our office and then explain it to your co-workers.",
    order: 480,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 3, scene: 5, line: 'Gosh.', order: 481, speaker: 'Jim Halpert' },
  { season: 1, episode: 3, scene: 5, line: 'Yeah!', order: 482, speaker: 'Michael Scott' },
  {
    season: 1,
    episode: 3,
    scene: 5,
    line: 'That is a great offer. Thank you. I really think I should be concentrating on sales.',
    order: 483,
    speaker: 'Jim Halpert'
  },
  { season: 1, episode: 3, scene: 5, line: 'Really?', order: 484, speaker: 'Michael Scott' },
  {
    season: 1,
    episode: 3,
    scene: 5,
    line:
      "Yeah. I just don't think this is the kind of task, that I... am going to do. You know who would be great for this?",
    order: 485,
    speaker: 'Jim Halpert'
  },
  {
    season: 1,
    episode: 3,
    scene: 6,
    line: 'Any time Michael asks me to do anything, I just tell him that Dwight should do it.',
    order: 486,
    speaker: 'Jim Halpert'
  },
  { season: 1, episode: 3, scene: 7, line: "Yes. I can do it. I'm your man.", order: 487, speaker: 'Dwight Schrute' },
  {
    season: 1,
    episode: 3,
    scene: 8,
    line:
      "Right now, this is just a job. If I advance any higher in this company then this would be my career. And uh, well, if this were my career, I'd have to throw myself in front of a train.",
    order: 488,
    speaker: 'Jim Halpert'
  },
  {
    season: 1,
    episode: 3,
    scene: 9,
    line: "OK, first, let's go over some parameters. How many people can I fire?",
    order: 489,
    speaker: 'Dwight Schrute'
  },
  {
    season: 1,
    episode: 3,
    scene: 9,
    line: "Ah, none. You're picking a health care plan.",
    order: 490,
    speaker: 'Michael Scott'
  },
  {
    season: 1,
    episode: 3,
    scene: 9,
    line:
      "OK, we'll table that for the time being. Two, I'll need an office. I think the conference room should be fine.",
    order: 491,
    speaker: 'Dwight Schrute'
  },
  {
    season: 1,
    episode: 3,
    scene: 9,
    line: 'You can use the conference room as a temporary workspace.',
    order: 492,
    speaker: 'Michael Scott'
  },
  {
    season: 1,
    episode: 3,
    scene: 9,
    line: '[to self] Yes, I have an office. [to camera] Bigger than his.',
    order: 493,
    speaker: 'Dwight Schrute'
  },
  { season: 1, episode: 3, scene: 9, line: 'Nope, you cannot use it.', order: 494, speaker: 'Michael Scott' },
  {
    season: 1,
    episode: 3,
    scene: 9,
    line: "OK, I take it back, it's a workspace.",
    order: 495,
    speaker: 'Dwight Schrute'
  },
  {
    season: 1,
    episode: 3,
    scene: 9,
    line: 'Temporary workspace. You can use it.',
    order: 496,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 3, scene: 9, line: 'Thank you.', order: 497, speaker: 'Dwight Schrute' },
  {
    season: 1,
    episode: 3,
    scene: 10,
    line:
      "If Dwight fails, then that is strike two, and good for me for, ah, for giving him a second chance. And if he succeeds, then, you know, no one will be prouder than I am. I groomed him. I made him what he is today. Unless he fails, and we've talked about that already.",
    order: 498,
    speaker: 'Michael Scott'
  },
  {
    season: 1,
    episode: 3,
    scene: 11,
    line:
      "What did I do? I did my job. I slashed benefits to the bone. I saved this company money. Was I too harsh? Maybe. I don't believe in coddling people.",
    order: 499,
    speaker: 'Dwight Schrute'
  },
  {
    season: 1,
    episode: 3,
    scene: 11,
    line:
      "In the wild, there is no health care. In the wild, health care is, 'Ow, I hurt my leg. I can't run. A lion eats me and I'm dead.' Well, I'm not dead. I'm the lion. You're dead.",
    order: 500,
    speaker: 'Dwight Schrute'
  },
  {
    season: 1,
    episode: 3,
    scene: 12,
    line: "There's no dental, there's no vision, there's a $1,200 deductible.",
    order: 501,
    speaker: 'Stanley Hudson'
  },
  { season: 1, episode: 3, scene: 13, line: 'Dunder Mifflin, this is Pam.', order: 502, speaker: 'Pam Beesly' },
  {
    season: 1,
    episode: 3,
    scene: 13,
    line: "[on phone] Pam, Michael Scott. How's tricks?",
    order: 503,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 3, scene: 13, line: 'Where are you?', order: 504, speaker: 'Pam Beesly' },
  {
    season: 1,
    episode: 3,
    scene: 13,
    line:
      "Oh, I am in my office. I am swamped. I have work up to my ears I'm busy, busy, busy. Can't step away. I just wanted to check in and see how everybody's doing. Everybody cool out there?",
    order: 505,
    speaker: 'Michael Scott'
  },
  {
    season: 1,
    episode: 3,
    scene: 13,
    line:
      "Actually, people are really unhappy. Um, Dwight sent around this memo and people are freaking out 'cause the...",
    order: 506,
    speaker: 'Pam Beesly'
  },
  {
    season: 1,
    episode: 3,
    scene: 13,
    line: "Pam! Whoa, whoa, I'm sorry, I'm sorry, I, I, I, I gotta go. I'm getting a call.",
    order: 507,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 3, scene: 13, line: "No you're not.", order: 508, speaker: 'Pam Beesly' },
  {
    season: 1,
    episode: 3,
    scene: 13,
    line:
      "I have to make a call after I finish... my work. You know what? Uh, just don't let anybody in my office under any conditions today. I'm just too busy. Too swamped, you know? I am unreachable. I am incommunicado, capisce?",
    order: 509,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 3, scene: 13, line: 'OK.', order: 510, speaker: 'Pam Beesly' },
  {
    season: 1,
    episode: 3,
    scene: 13,
    line: 'Thank you, Oh, gah, here we go again. Gotta go, I have to take this.',
    order: 511,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 3, scene: 13, line: 'Still no one calling.', order: 512, speaker: 'Pam Beesly' },
  { season: 1, episode: 3, scene: 14, line: 'Dwight, what...', order: 513, speaker: 'Pam Beesly' },
  {
    season: 1,
    episode: 3,
    scene: 14,
    line: 'Uh, knock, please. Please knock. This is an office.',
    order: 514,
    speaker: 'Dwight Schrute'
  },
  { season: 1, episode: 3, scene: 14, line: "It says 'workspace'.", order: 515, speaker: 'Jim Halpert' },
  { season: 1, episode: 3, scene: 14, line: 'Same thing.', order: 516, speaker: 'Dwight Schrute' },
  {
    season: 1,
    episode: 3,
    scene: 14,
    line: "If it's the same thing, then why did you write 'workspace'?",
    order: 517,
    speaker: 'Jim Halpert'
  },
  {
    season: 1,
    episode: 3,
    scene: 14,
    line: 'Just knock, Please? As a sign of respect for your superior.',
    order: 518,
    speaker: 'Dwight Schrute'
  },
  { season: 1, episode: 3, scene: 14, line: 'You are not my superior.', order: 519, speaker: 'Jim Halpert' },
  {
    season: 1,
    episode: 3,
    scene: 14,
    line: 'Oh gee, then why do I have an office?',
    order: 520,
    speaker: 'Dwight Schrute'
  },
  { season: 1, episode: 3, scene: 14, line: 'I thought it was a workspace?', order: 521, speaker: 'Jim Halpert' },
  {
    season: 1,
    episode: 3,
    scene: 14,
    line: 'OK. Dwight. Are you really in charge of picking the health care plan?',
    order: 522,
    speaker: 'Pam Beesly'
  },
  { season: 1, episode: 3, scene: 14, line: 'Yes. And my decision in final.', order: 523, speaker: 'Dwight Schrute' },
  {
    season: 1,
    episode: 3,
    scene: 14,
    line: 'This is a ridiculously awful plan. Because you cut everything.',
    order: 524,
    speaker: 'Pam Beesly'
  },
  {
    season: 1,
    episode: 3,
    scene: 14,
    line: 'Aww, times are tough, Pam. Deal with it.',
    order: 525,
    speaker: 'Dwight Schrute'
  },
  {
    season: 1,
    episode: 3,
    scene: 14,
    line: "You cut more than you had to, didn't you?",
    order: 526,
    speaker: 'Jim Halpert'
  },
  { season: 1, episode: 3, scene: 14, line: 'Sure.', order: 527, speaker: 'Dwight Schrute' },
  {
    season: 1,
    episode: 3,
    scene: 14,
    line: "Well, why did you do that? You work here, don't you want good insurance?",
    order: 528,
    speaker: 'Jim Halpert'
  },
  {
    season: 1,
    episode: 3,
    scene: 14,
    line: "Don't need it. Never been sick. Perfect immune system.",
    order: 529,
    speaker: 'Dwight Schrute'
  },
  {
    season: 1,
    episode: 3,
    scene: 14,
    line: "OK, well, if you've never been sick, then you don't have any antibodies.",
    order: 530,
    speaker: 'Jim Halpert'
  },
  {
    season: 1,
    episode: 3,
    scene: 14,
    line:
      "I don't need them. Superior genes. I'm a Schrute. And superior brain power. Through concentration, I can raise and lower my cholesterol at will.",
    order: 531,
    speaker: 'Dwight Schrute'
  },
  {
    season: 1,
    episode: 3,
    scene: 14,
    line: 'Why would you want to raise your cholesterol?',
    order: 532,
    speaker: 'Pam Beesly'
  },
  { season: 1, episode: 3, scene: 14, line: 'So I can lower it.', order: 533, speaker: 'Dwight Schrute' },
  {
    season: 1,
    episode: 3,
    scene: 15,
    line: "He literally won't come out of his office.",
    order: 534,
    speaker: 'Oscar Martinez'
  },
  {
    season: 1,
    episode: 3,
    scene: 15,
    line: "He's got to come out sometime. To go to the bathroom.",
    order: 535,
    speaker: 'Kevin Malone'
  },
  { season: 1, episode: 3, scene: 15, line: "Kevin! That's inappropriate.", order: 536, speaker: 'Angela Martin' },
  { season: 1, episode: 3, scene: 16, line: 'Michael, can I talk to you?', order: 537, speaker: 'Oscar Martinez' },
  {
    season: 1,
    episode: 3,
    scene: 16,
    line: 'Ah, uh, I would love to, but I am really busy. Rain check?',
    order: 538,
    speaker: 'Michael Scott'
  },
  {
    season: 1,
    episode: 3,
    scene: 16,
    line: 'Michael. Michael, please, can we talk to you about this memo?',
    order: 539,
    speaker: 'Meredith Palmer'
  },
  { season: 1, episode: 3, scene: 16, line: 'Ah, what? Which memo?', order: 540, speaker: 'Michael Scott' },
  {
    season: 1,
    episode: 3,
    scene: 16,
    line: "Dwight's health care memo. I told you about it.",
    order: 541,
    speaker: 'Pam Beesly'
  },
  { season: 1, episode: 3, scene: 16, line: 'Is it a good plan?', order: 542, speaker: 'Michael Scott' },
  {
    season: 1,
    episode: 3,
    scene: 16,
    line: "It's a great plan. It saves the company a fortune.",
    order: 543,
    speaker: 'Dwight Schrute'
  },
  { season: 1, episode: 3, scene: 16, line: "It's like a pay decrease.", order: 544, speaker: 'Oscar Martinez' },
  { season: 1, episode: 3, scene: 16, line: 'Michael, he made huge cuts.', order: 545, speaker: 'Pam Beesly' },
  {
    season: 1,
    episode: 3,
    scene: 16,
    line: 'Cuts? What? Wow, Dwight, did you make cuts?',
    order: 546,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 3, scene: 16, line: 'Yeah, you said...', order: 547, speaker: 'Dwight Schrute' },
  {
    season: 1,
    episode: 3,
    scene: 16,
    line:
      "No, no , no, you know what? I said nothing specific because I was so busy. Why don't you go in there and find these people a plan that will work for them? OK?",
    order: 548,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 3, scene: 16, line: 'I can handle that.', order: 549, speaker: 'Dwight Schrute' },
  {
    season: 1,
    episode: 3,
    scene: 16,
    line:
      "OK? All right. Do we feel good? All right. Good. Plus, there's some other good news. Today, at the end of the day, I will have, for all of you, a big surprise. OK? So hang in there, and I will see you at the end of the day. Right?",
    order: 550,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 3, scene: 17, line: 'This is not good.', order: 551, speaker: 'Oscar Martinez' },
  {
    season: 1,
    episode: 3,
    scene: 17,
    line: "It's ridiculous. Did you talk to him?",
    order: 552,
    speaker: 'Angela Martin'
  },
  { season: 1, episode: 3, scene: 17, line: 'What was that?', order: 553, speaker: 'Oscar Martinez' },
  {
    season: 1,
    episode: 3,
    scene: 17,
    line: "You let him walk all over you. It's just pathetic.",
    order: 554,
    speaker: 'Angela Martin'
  },
  { season: 1, episode: 3, scene: 17, line: 'What are you guys talking about?', order: 555, speaker: 'Kevin Malone' },
  { season: 1, episode: 3, scene: 17, line: 'Nothing, Kevin.', order: 556, speaker: 'Angela Martin' },
  {
    season: 1,
    episode: 3,
    scene: 18,
    line:
      "Do I know what the surprise is? Hell no! It doesn't matter. The point is, they're not unhappy anymore. They're out there thinking, 'Wow, my boss really cares about me. He has a surprise. He's cool. I... what a great guy. I love him. I...",
    order: 557,
    speaker: 'Michael Scott'
  },
  {
    season: 1,
    episode: 3,
    scene: 19,
    line:
      "OK, everyone. Gather round. Step forward. It has been brought to my attention that some of you are unhappy with my plan. So what I'd like you to do is to fill this out and write down any diseases you have that you might want covered and I'll see what I can do.",
    order: 558,
    speaker: 'Dwight Schrute'
  },
  {
    season: 1,
    episode: 3,
    scene: 19,
    line: "OK, you know what Dwight? We can't write our diseases down for you because that's confidential.",
    order: 559,
    speaker: 'Jim Halpert'
  },
  {
    season: 1,
    episode: 3,
    scene: 19,
    line:
      "OK, well, I didn't say to write your name down, did I? Fill it out, leave it anonymous. Or, don't write any disease down at all and it won't be covered. Sound fair? Good. I'll be in my office.",
    order: 560,
    speaker: 'Dwight Schrute'
  },
  { season: 1, episode: 3, scene: 19, line: 'Workspace.', order: 561, speaker: 'Jim Halpert' },
  {
    season: 1,
    episode: 3,
    scene: 20,
    line: 'You know what? Come with me. We are going on a little mission. Operation Surprise.',
    order: 562,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 3, scene: 20, line: 'Where are you going?', order: 563, speaker: 'Pam Beesly' },
  {
    season: 1,
    episode: 3,
    scene: 20,
    line: "Um, headed out. Part of my busy day, you know. Meetings. [Giggles] Couldn't find the knob.",
    order: 564,
    speaker: 'Michael Scott'
  },
  {
    season: 1,
    episode: 3,
    scene: 21,
    line:
      'So, basically, I want to do something nice for my employees. Atlantic City, OK? They have this thing where they send a bus, right, for free. Picks everybody up, you head down there, get to the hotel, room is comped, they give you a pile of chips, and your food, everything just kind of all-inclusive, free kind of weekend.',
    order: 565,
    speaker: 'Michael Scott'
  },
  {
    season: 1,
    episode: 3,
    scene: 21,
    line:
      "I don't know of anything like that, but, um, you know what you might want to do, is just call those casinos directly. Um, maybe?",
    order: 566,
    speaker: 'Travel Agent'
  },
  { season: 1, episode: 3, scene: 21, line: 'Yeah, yeah, yeah. I did, so...', order: 567, speaker: 'Michael Scott' },
  {
    season: 1,
    episode: 3,
    scene: 22,
    line: "Wait. What are you writing? Don't write Ebola or mad cow disease. Right? 'Cause I'm suffering from both.",
    order: 568,
    speaker: 'Jim Halpert'
  },
  { season: 1, episode: 3, scene: 22, line: "I'm inventing new diseases.", order: 569, speaker: 'Pam Beesly' },
  { season: 1, episode: 3, scene: 22, line: 'Oh, great.', order: 570, speaker: 'Jim Halpert' },
  {
    season: 1,
    episode: 3,
    scene: 22,
    line:
      "So, let's say my teeth turn to liquid and then, they drip down the back of my throat. What would you call that?",
    order: 571,
    speaker: 'Pam Beesly'
  },
  {
    season: 1,
    episode: 3,
    scene: 22,
    line: "I thought you said you were inventing diseases? That's spontaneous dental hydroplosion.",
    order: 572,
    speaker: 'Jim Halpert'
  },
  { season: 1, episode: 3, scene: 22, line: 'Nice.', order: 573, speaker: 'Pam Beesly' },
  { season: 1, episode: 3, scene: 22, line: 'Thank you.', order: 574, speaker: 'Jim Halpert' },
  {
    season: 1,
    episode: 3,
    scene: 23,
    line:
      '[on his cell phone] Calling you to ask you a little favoroonie my friend. Um, trying to give the troops around here a little bit of a boost. And I was thinking that maybe we could take them down to take a spin on your big ride.',
    order: 575,
    speaker: 'Michael Scott'
  },
  {
    season: 1,
    episode: 3,
    scene: 23,
    line: "You mean the elevator that takes you down into the mineshaft? It's not really a ride.",
    order: 576,
    speaker: 'Man on Phone'
  },
  {
    season: 1,
    episode: 3,
    scene: 23,
    line: "Its says here that it's a 300ft drop.",
    order: 577,
    speaker: 'Michael Scott'
  },
  {
    season: 1,
    episode: 3,
    scene: 23,
    line: 'It goes 300 feet into the earth, but it moves really slowly.',
    order: 578,
    speaker: 'Man on Phone'
  },
  { season: 1, episode: 3, scene: 23, line: "So it's not a free fall?", order: 579, speaker: 'Michael Scott' },
  { season: 1, episode: 3, scene: 23, line: "It's an industrial coal elevator.", order: 580, speaker: 'Man on Phone' },
  {
    season: 1,
    episode: 3,
    scene: 23,
    line: 'Uh, all right. Well, once you get down into the mine, what... you got laser tag or something?',
    order: 581,
    speaker: 'Michael Scott'
  },
  {
    season: 1,
    episode: 3,
    scene: 24,
    line:
      "OK, so I don't know what the surprise is. Am I worried? No. No way. See, I thrive on this. This is my world. This is improv. This is Whose Line is it Anyway?",
    order: 582,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 3, scene: 25, line: 'Damnit! Damnit Jim!', order: 583, speaker: 'Dwight Schrute' },
  {
    season: 1,
    episode: 3,
    scene: 25,
    line: "All right, who did this? I'm not mad. I just want to know who did it so I can punish them.",
    order: 584,
    speaker: 'Dwight Schrute'
  },
  { season: 1, episode: 3, scene: 25, line: 'What are you talking about?', order: 585, speaker: 'Jim Halpert' },
  {
    season: 1,
    episode: 3,
    scene: 25,
    line: 'Uh, someone forged, uh, medical information and that is a felony.',
    order: 586,
    speaker: 'Dwight Schrute'
  },
  {
    season: 1,
    episode: 3,
    scene: 25,
    line: "OK, whoa. 'Cause that is a pretty intense accusation. How do you know that they're fake?",
    order: 587,
    speaker: 'Jim Halpert'
  },
  {
    season: 1,
    episode: 3,
    scene: 25,
    line: 'Uh, leprosy? Flesh eating bacteria. Hot-dog fingers. Government-created killer nanorobot infection.',
    order: 588,
    speaker: 'Dwight Schrute'
  },
  { season: 1, episode: 3, scene: 25, line: "You did this, didn't you?", order: 589, speaker: 'Dwight Schrute' },
  { season: 1, episode: 3, scene: 25, line: 'Absolutely not.', order: 590, speaker: 'Jim Halpert' },
  { season: 1, episode: 3, scene: 25, line: 'Yes you did.', order: 591, speaker: 'Dwight Schrute' },
  { season: 1, episode: 3, scene: 25, line: "No I didn't.", order: 592, speaker: 'Jim Halpert' },
  {
    season: 1,
    episode: 3,
    scene: 25,
    line:
      "I know it was you. Fine. You know what? I'll have to interview each and every one of you until the perpetrator makes him or herself known. And until that time, there will be no health care coverage for any one!",
    order: 593,
    speaker: 'Dwight Schrute'
  },
  { season: 1, episode: 3, scene: 26, line: 'Killer nanorobots?', order: 594, speaker: 'Jim Halpert' },
  { season: 1, episode: 3, scene: 26, line: "It's an epidemic.", order: 595, speaker: 'Pam Beesly' },
  {
    season: 1,
    episode: 3,
    scene: 27,
    line:
      "The problem, Jim, is that people who are really suffering from a medical condition won't receive the care they need, because someone in this office is coming up with all this ridiculous stuff. [reads off of paper] 'Count Choculitis'",
    order: 596,
    speaker: 'Dwight Schrute'
  },
  { season: 1, episode: 3, scene: 27, line: 'Sounds tough.', order: 597, speaker: 'Jim Halpert' },
  {
    season: 1,
    episode: 3,
    scene: 27,
    line: 'Why did you write that down Jim? Is it because you know I love Count Chocula?',
    order: 598,
    speaker: 'Dwight Schrute'
  },
  { season: 1, episode: 3, scene: 27, line: 'Do you?', order: 599, speaker: 'Jim Halpert' },
  { season: 1, episode: 3, scene: 27, line: 'I think you need to confess...', order: 600, speaker: 'Dwight Schrute' },
  { season: 1, episode: 3, scene: 27, line: 'Mmm hmm.', order: 601, speaker: 'Jim Halpert' },
  { season: 1, episode: 3, scene: 27, line: '...the fact...', order: 602, speaker: 'Dwight Schrute' },
  { season: 1, episode: 3, scene: 27, line: 'Yep.', order: 603, speaker: 'Jim Halpert' },
  {
    season: 1,
    episode: 3,
    scene: 27,
    line: 'What are you doing? Those are my keys.',
    order: 604,
    speaker: 'Dwight Schrute'
  },
  {
    season: 1,
    episode: 3,
    scene: 27,
    line: 'Good luck. [closes door and locks it]',
    order: 605,
    speaker: 'Jim Halpert'
  },
  {
    season: 1,
    episode: 3,
    scene: 27,
    line:
      'Jim! Damnit! No! Jim! Let me out! Jim! Let... [Without looking, Jim throws his keys to his left, they land on a shelf next to Stanley]',
    order: 606,
    speaker: 'Dwight Schrute'
  },
  {
    season: 1,
    episode: 3,
    scene: 27,
    line: '[looks at keys, continues talking on phone] ...the light green or green...',
    order: 607,
    speaker: 'Stanley Hudson'
  },
  { season: 1, episode: 3, scene: 28, line: '[answering phone] Jim Halpert.', order: 608, speaker: 'Jim Halpert' },
  { season: 1, episode: 3, scene: 28, line: 'Let me out.', order: 609, speaker: 'Dwight Schrute' },
  { season: 1, episode: 3, scene: 28, line: 'Who is this?', order: 610, speaker: 'Jim Halpert' },
  { season: 1, episode: 3, scene: 28, line: "Let me out or you're fired.", order: 611, speaker: 'Dwight Schrute' },
  { season: 1, episode: 3, scene: 28, line: "No, you can't fire me.", order: 612, speaker: 'Jim Halpert' },
  {
    season: 1,
    episode: 3,
    scene: 28,
    line: "Yes I can. I'm manager for the day. Clean out your desk.",
    order: 613,
    speaker: 'Dwight Schrute'
  },
  {
    season: 1,
    episode: 3,
    scene: 28,
    line: "OK, can you hold on? I'm getting the, ah, beep. [presses button on phone]] Jim Halpert.",
    order: 614,
    speaker: 'Jim Halpert'
  },
  { season: 1, episode: 3, scene: 28, line: "[on phone] Hey, Jim. It's Pam.", order: 615, speaker: 'Pam Beesly' },
  { season: 1, episode: 3, scene: 28, line: 'Hey Pam! How are you?', order: 616, speaker: 'Jim Halpert' },
  { season: 1, episode: 3, scene: 28, line: 'Jim! Open the door!', order: 617, speaker: 'Dwight Schrute' },
  { season: 1, episode: 3, scene: 28, line: 'Good, how are you? Busy?', order: 618, speaker: 'Pam Beesly' },
  {
    season: 1,
    episode: 3,
    scene: 28,
    line: "I'm doing OK. Getting excited for the weekend though. What are you up to?",
    order: 619,
    speaker: 'Jim Halpert'
  },
  { season: 1, episode: 3, scene: 28, line: 'Jim!', order: 620, speaker: 'Dwight Schrute' },
  { season: 1, episode: 3, scene: 28, line: "Um, I'm not bothering you, am I?", order: 621, speaker: 'Pam Beesly' },
  { season: 1, episode: 3, scene: 28, line: 'No, not at all.', order: 622, speaker: 'Jim Halpert' },
  {
    season: 1,
    episode: 3,
    scene: 28,
    line: "You don't have anything you're doing?",
    order: 623,
    speaker: 'Pam Beesly'
  },
  { season: 1, episode: 3, scene: 28, line: 'I have nothing to do.', order: 624, speaker: 'Jim Halpert' },
  { season: 1, episode: 3, scene: 28, line: 'Jim!', order: 625, speaker: 'Dwight Schrute' },
  {
    season: 1,
    episode: 3,
    scene: 28,
    line: "Oh great. Um, no, this weekend? Nothing. I'm not really doing anything.",
    order: 626,
    speaker: 'Pam Beesly'
  },
  { season: 1, episode: 3, scene: 28, line: 'Jim!', order: 627, speaker: 'Dwight Schrute' },
  { season: 1, episode: 3, scene: 28, line: 'Oh yeah?', order: 628, speaker: 'Jim Halpert' },
  { season: 1, episode: 3, scene: 28, line: 'I might go to the mall.', order: 629, speaker: 'Pam Beesly' },
  { season: 1, episode: 3, scene: 28, line: 'The mall?', order: 630, speaker: 'Jim Halpert' },
  { season: 1, episode: 3, scene: 28, line: 'Jim!', order: 631, speaker: 'Dwight Schrute' },
  { season: 1, episode: 3, scene: 28, line: 'I need new shoes.', order: 632, speaker: 'Pam Beesly' },
  {
    season: 1,
    episode: 3,
    scene: 28,
    line: 'Oh, interesting, what kind of shoes?',
    order: 633,
    speaker: 'Jim Halpert'
  },
  { season: 1, episode: 3, scene: 29, line: 'Hello?', order: 634, speaker: 'Jan Levinson' },
  {
    season: 1,
    episode: 3,
    scene: 29,
    line: 'Uh, hello. Uh, this is Dwight Schrute calling for Jan Levenson-Gould.',
    order: 635,
    speaker: 'Dwight Schrute'
  },
  { season: 1, episode: 3, scene: 29, line: 'This is Jan.', order: 636, speaker: 'Jan Levinson' },
  {
    season: 1,
    episode: 3,
    scene: 29,
    line:
      'Hi. Dwight Schrute calling, acting manager, Scranton branch. Listen, I needed your permission to fire Jim Halpert.',
    order: 637,
    speaker: 'Dwight Schrute'
  },
  { season: 1, episode: 3, scene: 29, line: 'Who is this?', order: 638, speaker: 'Jan Levinson' },
  { season: 1, episode: 3, scene: 29, line: 'Dwight Schrute.', order: 639, speaker: 'Dwight Schrute' },
  { season: 1, episode: 3, scene: 29, line: 'From sales?', order: 640, speaker: 'Jan Levinson' },
  { season: 1, episode: 3, scene: 29, line: 'Well...', order: 641, speaker: 'Dwight Schrute' },
  { season: 1, episode: 3, scene: 29, line: "Where's Michael Scott?", order: 642, speaker: 'Jan Levinson' },
  {
    season: 1,
    episode: 3,
    scene: 29,
    line: 'He is not here right now. He put me in charge of the office.',
    order: 643,
    speaker: 'Dwight Schrute'
  },
  {
    season: 1,
    episode: 3,
    scene: 29,
    line: 'Dwight, listen to me very carefully. You are not a manager of anything. Understand?',
    order: 644,
    speaker: 'Jan Levinson'
  },
  {
    season: 1,
    episode: 3,
    scene: 29,
    line: "That's not entirely true, because he put me in charge of picking the health care plan.",
    order: 645,
    speaker: 'Dwight Schrute'
  },
  { season: 1, episode: 3, scene: 29, line: 'Really?', order: 646, speaker: 'Jan Levinson' },
  { season: 1, episode: 3, scene: 29, line: 'Yeah.', order: 647, speaker: 'Dwight Schrute' },
  {
    season: 1,
    episode: 3,
    scene: 29,
    line: 'OK, when Michael gets back, you tell him to call me immediately.',
    order: 648,
    speaker: 'Jan Levinson'
  },
  {
    season: 1,
    episode: 3,
    scene: 29,
    line: 'Call you immediately. Good. Oh, hey, listen, um, since I have you on the phone, um, can I fire Jim?',
    order: 649,
    speaker: 'Dwight Schrute'
  },
  {
    season: 1,
    episode: 3,
    scene: 29,
    line: "No. Please don't use my cell phone ever again.",
    order: 650,
    speaker: 'Jan Levinson'
  },
  {
    season: 1,
    episode: 3,
    scene: 29,
    line: 'Oh, this is your cell, I thought this was your... [dial tone]',
    order: 651,
    speaker: 'Dwight Schrute'
  },
  {
    season: 1,
    episode: 3,
    scene: 30,
    line:
      "Hey, hey, everybody, Ice-cream sandwiches! Aaaahh! [laughs] Here you go. Take one, take one. It's all good. Phyllis, think fast. Ya-bome! Oh, oh, I see Angela. Angela? Right? Waaaaah! Oh, hey temp. Why don't you take two? Because you don't get health care. And uh, faster metabolism.",
    order: 652,
    speaker: 'Michael Scott'
  },
  {
    season: 1,
    episode: 3,
    scene: 30,
    line: 'Did you get the kind with the cookies? Instead of the...',
    order: 653,
    speaker: 'Ryan Howard'
  },
  {
    season: 1,
    episode: 3,
    scene: 30,
    line: "Why don't you just eat it, OK? And here you go, Stanley the manly.",
    order: 654,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 3, scene: 30, line: 'Oh, thanks.', order: 655, speaker: 'Stanley Hudson' },
  { season: 1, episode: 3, scene: 30, line: 'There you go.', order: 656, speaker: 'Michael Scott' },
  {
    season: 1,
    episode: 3,
    scene: 30,
    line: "This isn't the big surprise, is it? Because we've been having a pretty horrible day.",
    order: 657,
    speaker: 'Stanley Hudson'
  },
  {
    season: 1,
    episode: 3,
    scene: 30,
    line:
      "Uh, nope. Nope. This isn't the surprise. It's surprising, um... because you didn't expect it. But you will... you'll know it when you see it.",
    order: 658,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 3, scene: 30, line: 'Michael. Michael?', order: 659, speaker: 'Dwight Schrute' },
  { season: 1, episode: 3, scene: 30, line: '[under his breath] Oh, Christ.', order: 660, speaker: 'Michael Scott' },
  {
    season: 1,
    episode: 3,
    scene: 31,
    line:
      'I tried being rational, OK? And what happened? The employees went crazy, I got no help from corporate. That leaves me with no options.',
    order: 661,
    speaker: 'Dwight Schrute'
  },
  {
    season: 1,
    episode: 3,
    scene: 32,
    line:
      "I'm now going to read out loud your submitted medical conditions. When you hear yours read, please raise your hand to indicate that it is real. If you do not raise your hand, it will not be covered.",
    order: 662,
    speaker: 'Dwight Schrute'
  },
  { season: 1, episode: 3, scene: 32, line: 'What about confidentiality?', order: 663, speaker: 'Stanley Hudson' },
  {
    season: 1,
    episode: 3,
    scene: 32,
    line:
      'You know what? You have forfeited that privilege. I have tried to treat you all as adults, but obviously I am the only adult here. Number one, inverted penis.',
    order: 664,
    speaker: 'Dwight Schrute'
  },
  {
    season: 1,
    episode: 3,
    scene: 32,
    line: 'Could you mean vagina? Because if you do, I want that covered.',
    order: 665,
    speaker: 'Meredith Palmer'
  },
  {
    season: 1,
    episode: 3,
    scene: 32,
    line: 'I thought your vagina was removed during your hysterectomy?',
    order: 666,
    speaker: 'Dwight Schrute'
  },
  {
    season: 1,
    episode: 3,
    scene: 32,
    line: 'A uterus is different from a vagina. I still have a vagina.',
    order: 667,
    speaker: 'Meredith Palmer'
  },
  {
    season: 1,
    episode: 3,
    scene: 33,
    line:
      "OK, great. Dermatitis. Thank you Angela. I'll make sure that's covered. OK, now. Who wrote this, hysterical one? Anal fissures?",
    order: 668,
    speaker: 'Dwight Schrute'
  },
  { season: 1, episode: 3, scene: 33, line: "That's a real thing.", order: 669, speaker: 'Kevin Malone' },
  { season: 1, episode: 3, scene: 33, line: 'Yeah, but no one here has it.', order: 670, speaker: 'Dwight Schrute' },
  { season: 1, episode: 3, scene: 33, line: 'Someone has it.', order: 671, speaker: 'Kevin Malone' },
  { season: 1, episode: 3, scene: 34, line: 'Do you think we should go ?', order: 672, speaker: 'Kevin Malone' },
  {
    season: 1,
    episode: 3,
    scene: 34,
    line: "I don't know, Kevin. This is important. I don't want... [spots Michael through the blinds] There he is.",
    order: 673,
    speaker: 'Oscar Martinez'
  },
  { season: 1, episode: 3, scene: 34, line: 'What is he doing?', order: 674, speaker: 'Kevin Malone' },
  { season: 1, episode: 3, scene: 34, line: "I don't know.", order: 675, speaker: 'Oscar Martinez' },
  { season: 1, episode: 3, scene: 35, line: 'Well?', order: 676, speaker: 'Oscar Martinez' },
  {
    season: 1,
    episode: 3,
    scene: 35,
    line: 'Well, what? You could be referring to anything.',
    order: 677,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 3, scene: 35, line: 'OK, the health care plan.', order: 678, speaker: 'Oscar Martinez' },
  {
    season: 1,
    episode: 3,
    scene: 35,
    line: 'Why did you put Dwight in charge of that? He did a horrible job.',
    order: 679,
    speaker: 'Pam Beesly'
  },
  {
    season: 1,
    episode: 3,
    scene: 35,
    line: 'Uh, Dwight? Did you raise benefits?',
    order: 680,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 3, scene: 35, line: 'I most certainly did not.', order: 681, speaker: 'Dwight Schrute' },
  {
    season: 1,
    episode: 3,
    scene: 35,
    line:
      "Oh come on! That's horrible! Aaah... Thanks, Dwight, for a crappy plan. Ah, Damn! Oh, mmm, I wish I had time to change it, but Jan needs it by five, and... what time is it, what time is it? [looks at watch] Ah, it's after five. Oh, oh it's awful. So, well, OK. See you guys on Monday.",
    order: 682,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 3, scene: 35, line: 'What about the surprise?', order: 683, speaker: 'Angela Martin' },
  {
    season: 1,
    episode: 3,
    scene: 35,
    line:
      'Oh... Yes. Exactly. Thank you Angela, for reminding me. Terrific. Um, before I tell everybody what the big surprise is, would you like to tell me what you think the big surprise is?',
    order: 684,
    speaker: 'Michael Scott'
  },
  {
    season: 1,
    episode: 3,
    scene: 35,
    line: "We all think you don't have a surprise.",
    order: 685,
    speaker: 'Stanley Hudson'
  },
  {
    season: 1,
    episode: 3,
    scene: 35,
    line:
      'All right, I have some news for you. There is a big surprise. And... here it is. Here we go. And the big surprise is... Brrrrrrrr! Drum roll... Brrrrrrrr! Brrrrrrrr!',
    order: 686,
    speaker: 'Michael Scott'
  },
  {
    season: 1,
    episode: 3,
    scene: 36,
    line:
      'When I am backed into a corner, that is when I come alive. See I learned improve from the greats, like, um, Drew Carey and Ryan Stiles.',
    order: 687,
    speaker: 'Michael Scott'
  },
  {
    season: 1,
    episode: 3,
    scene: 37,
    line: '[clapping hands] God, yeah... Ah! This...',
    order: 688,
    speaker: 'Michael Scott'
  },
  {
    season: 1,
    episode: 3,
    scene: 38,
    line:
      "Robin Williams. Oh, man, would I love to go head-to-head with him. Oh! That would be exciting. [as Robin Williams] 'Hi. I'm Mork from Ork.' Well, I'm Bork from Spork. Nanoo, nanoo. Jibelee, baloobaloo.",
    order: 689,
    speaker: 'Michael Scott'
  },
  {
    season: 1,
    episode: 3,
    scene: 39,
    line: 'Oh, um... Jan wants you to call her.',
    order: 690,
    speaker: 'Dwight Schrute'
  },
  { season: 1, episode: 4, scene: 1, line: 'Michael!?', order: 691, speaker: 'Dwight Schrute' },
  { season: 1, episode: 4, scene: 1, line: 'Oh! God. Dwight, come on...', order: 692, speaker: 'Michael Scott' },
  {
    season: 1,
    episode: 4,
    scene: 1,
    line: 'I wanted to talk to you about the downsizing?',
    order: 693,
    speaker: 'Dwight Schrute'
  },
  { season: 1, episode: 4, scene: 1, line: "There's no downsizing.", order: 694, speaker: 'Michael Scott' },
  {
    season: 1,
    episode: 4,
    scene: 1,
    line: "I, but if there were, I'd be protected as assistant regional manager?",
    order: 695,
    speaker: 'Dwight Schrute'
  },
  {
    season: 1,
    episode: 4,
    scene: 1,
    line: 'Assistant to the regional manager Dwight.',
    order: 696,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 4, scene: 1, line: "Yeah, so I don't have to worry?", order: 697, speaker: 'Dwight Schrute' },
  {
    season: 1,
    episode: 4,
    scene: 1,
    line:
      "Look, look, look. I talked to corporate, about protecting the sales staff. And they said they couldn't guarantee it if there's downsizing, okay?",
    order: 698,
    speaker: 'Michael Scott'
  },
  {
    season: 1,
    episode: 4,
    scene: 1,
    line: "But there's no downsizing, so just don't...",
    order: 699,
    speaker: 'Michael Scott'
  },
  {
    season: 1,
    episode: 4,
    scene: 1,
    line: 'Bottom line. Do I need to be worried?',
    order: 700,
    speaker: 'Dwight Schrute'
  },
  { season: 1, episode: 4, scene: 1, line: 'Mmm, mm, mm. Maybe.', order: 701, speaker: 'Michael Scott' },
  {
    season: 1,
    episode: 4,
    scene: 2,
    line:
      "It looks like there's gonna be downsizing. And it's part of my job, but... blah! I hate it. I think the main difference between me and Donald Trump is that, uh, I get no pleasure out of saying the words, 'You're fired.' [as Donald Trump] 'You're foir-ed. Uh, you're foir-ed.' It just makes people sad, and an office can't function that way. No way. [as Donald Trump] 'You're foir-ed.' I think if I had a catchphrase it would be, 'You're hired, and you can work here as long as you want.' But that's unrealistic, so...",
    order: 702,
    speaker: 'Michael Scott'
  },
  {
    season: 1,
    episode: 4,
    scene: 3,
    line:
      "It's a real shame, 'cause studies have shown that more information gets passed through water-cooler gossip than through official memos. Which puts me at a disadvantage, because I bring my own water to work.",
    order: 703,
    speaker: 'Dwight Schrute'
  },
  { season: 1, episode: 4, scene: 4, line: "Why'd you do this?", order: 704, speaker: 'Stanley Hudson' },
  {
    season: 1,
    episode: 4,
    scene: 4,
    line:
      "I didn't do it. What do you mean? Oh, the water cooler was brought over here for... maintenance. So what do you guys hear? What's the scuttlebutt?",
    order: 705,
    speaker: 'Dwight Schrute'
  },
  {
    season: 1,
    episode: 4,
    scene: 5,
    line:
      'Get set for Operation Morale Improvement starring Michael Scott. Now, I think I have had a little stroke of genius in that I have had my assistant Pam... Smile, Pam. I have had her go out and find out whose birthday is coming up, so we can have a little celebration for it. Not bad, not bad at all. All right. And the birthday person is... drum roll please. Here we go, who is the birthday, birthday person?',
    order: 706,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 4, scene: 5, line: "Who is it? Who's the birthday?", order: 707, speaker: 'Michael Scott' },
  {
    season: 1,
    episode: 4,
    scene: 5,
    line: "Um... Actually, we don't have any staff birthdays coming up.",
    order: 708,
    speaker: 'Pam Beesly'
  },
  { season: 1, episode: 4, scene: 5, line: 'Next person on the...', order: 709, speaker: 'Michael Scott' },
  { season: 1, episode: 4, scene: 5, line: 'Oh.', order: 710, speaker: 'Pam Beesly' },
  { season: 1, episode: 4, scene: 5, line: '...calendar.', order: 711, speaker: 'Michael Scott' },
  { season: 1, episode: 4, scene: 5, line: 'Okay, umm... that would be Meredith.', order: 712, speaker: 'Pam Beesly' },
  {
    season: 1,
    episode: 4,
    scene: 5,
    line: 'Yes! All right, come on down Meredith!',
    order: 713,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 4, scene: 5, line: "But it's not until next month.", order: 714, speaker: 'Pam Beesly' },
  {
    season: 1,
    episode: 4,
    scene: 5,
    line: "Um... uh, OK. Well, great, well, you know, it'll be a surprise.",
    order: 715,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 4, scene: 5, line: 'You still want to have a party?', order: 716, speaker: 'Pam Beesly' },
  {
    season: 1,
    episode: 4,
    scene: 5,
    line: 'Yeah, why not? Sure. Go ahead, live a little. Come on, Pam. Come on, shake it up. Shake it up! Shake it up!',
    order: 717,
    speaker: 'Michael Scott'
  },
  {
    season: 1,
    episode: 4,
    scene: 5,
    line:
      '[grabs cell phone off desk] Brrrp! Uh, Spock, are there any signs of life down there? Well, let me check Captain. Eeee. Eeee. Eeee. Eeee. No, Captain. No signs of life down here. Just a wet blanket named Pam. Brr-rrrp. Star Trek.',
    order: 718,
    speaker: 'Michael Scott'
  },
  {
    season: 1,
    episode: 4,
    scene: 6,
    line: "Well, uh, for decorations, maybe we could... it's stupid, forget it.",
    order: 719,
    speaker: 'Phyllis Lapin'
  },
  { season: 1, episode: 4, scene: 6, line: 'What?', order: 720, speaker: 'Angela Martin' },
  {
    season: 1,
    episode: 4,
    scene: 6,
    line:
      "I was just going to say, maybe we could have streamers, but that's dumb, everybody has streamers. Never mind.",
    order: 721,
    speaker: 'Phyllis Lapin'
  },
  {
    season: 1,
    episode: 4,
    scene: 6,
    line: "No, yeah, I think that's a good idea.",
    order: 722,
    speaker: 'Angela Martin'
  },
  { season: 1, episode: 4, scene: 6, line: 'Yeah?', order: 723, speaker: 'Phyllis Lapin' },
  { season: 1, episode: 4, scene: 6, line: 'What color do you guys think?', order: 724, speaker: 'Angela Martin' },
  {
    season: 1,
    episode: 4,
    scene: 6,
    line: "Well, there's green, um, blue... yellow... red...",
    order: 725,
    speaker: 'Phyllis Lapin'
  },
  { season: 1, episode: 4, scene: 6, line: 'How about green?', order: 726, speaker: 'Pam Beesly' },
  { season: 1, episode: 4, scene: 6, line: 'I think green is kind of whoreish.', order: 727, speaker: 'Angela Martin' },
  {
    season: 1,
    episode: 4,
    scene: 7,
    line:
      "This was tough. I suggested we flip a coin. But Angela said she doesn't like to gamble. Of course by saying that she was gambling that I wouldn't smack her.",
    order: 728,
    speaker: 'Pam Beesly'
  },
  {
    season: 1,
    episode: 4,
    scene: 8,
    line: "These are my party-planning beeyatches. Pulled off an amazing '80s party last year. Off the hook!",
    order: 729,
    speaker: 'Michael Scott'
  },
  {
    season: 1,
    episode: 4,
    scene: 8,
    line:
      "So I was thinking, if you haven't already got a cake, um, maybe going for one of those ice-cream cakes from Baskin-Robbins. Those are very good. Very Delicious.",
    order: 730,
    speaker: 'Michael Scott'
  },
  {
    season: 1,
    episode: 4,
    scene: 8,
    line: "Meredith's allergic to dairy, so...",
    order: 731,
    speaker: 'Angela Martin'
  },
  {
    season: 1,
    episode: 4,
    scene: 8,
    line:
      "She's not the only one that's going to be eating it, right? I think everybody likes ice-cream cake. It's not, uh, it's not just about her, so...",
    order: 732,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 4, scene: 8, line: 'It is... her birthday.', order: 733, speaker: 'Pam Beesly' },
  {
    season: 1,
    episode: 4,
    scene: 8,
    line: "Mint chocolate chip! That'd be good, how about some, mint chocolate chip?",
    order: 734,
    speaker: 'Michael Scott'
  },
  {
    season: 1,
    episode: 4,
    scene: 9,
    line:
      "Hey, so listen, I was thinking that it might be a good idea if you and I formed an alliance. 'Cause of the downsizing? I think an alliance might be a good idea, you know. Help each other out.",
    order: 735,
    speaker: 'Dwight Schrute'
  },
  {
    season: 1,
    episode: 4,
    scene: 9,
    line: 'Do you want to form, an alliance, with me?',
    order: 736,
    speaker: 'Dwight Schrute'
  },
  { season: 1, episode: 4, scene: 9, line: 'Absolutely, I do.', order: 737, speaker: 'Jim Halpert' },
  {
    season: 1,
    episode: 4,
    scene: 9,
    line: "Good, good. Excellent, OK. Now we need to figure out who's vulnerable and who's protected...",
    order: 738,
    speaker: 'Dwight Schrute'
  },
  {
    season: 1,
    episode: 4,
    scene: 10,
    line: 'At that moment, I was so happy. I mean, everything Dwight does annoys me.',
    order: 739,
    speaker: 'Jim Halpert'
  },
  { season: 1, episode: 4, scene: 11, line: 'Did you get your tickets?', order: 740, speaker: 'Dwight Schrute' },
  { season: 1, episode: 4, scene: 11, line: 'To what?', order: 741, speaker: 'Jim Halpert' },
  {
    season: 1,
    episode: 4,
    scene: 11,
    line: 'The gun show. [Rolls up his sleeve and kisses his bicep]',
    order: 742,
    speaker: 'Dwight Schrute'
  },
  {
    season: 1,
    episode: 4,
    scene: 12,
    line:
      "And I spend hours thinking of ways to get back at him, but only in ways that could get me arrested. And then here he comes and he says 'No, Jim, here's a way.'",
    order: 743,
    speaker: 'Jim Halpert'
  },
  {
    season: 1,
    episode: 4,
    scene: 13,
    line:
      "There's one other thing and this is important. Let's keep this alliance totally a secret. Don't tell anyone.",
    order: 744,
    speaker: 'Dwight Schrute'
  },
  { season: 1, episode: 4, scene: 14, line: 'An alliance?', order: 745, speaker: 'Pam Beesly' },
  { season: 1, episode: 4, scene: 14, line: 'Oh yeah.', order: 746, speaker: 'Jim Halpert' },
  { season: 1, episode: 4, scene: 14, line: 'What does that even mean?', order: 747, speaker: 'Pam Beesly' },
  {
    season: 1,
    episode: 4,
    scene: 14,
    line: "I think it has something to do with Survivor, but I'm not sure.",
    order: 748,
    speaker: 'Jim Halpert'
  },
  {
    season: 1,
    episode: 4,
    scene: 14,
    line: 'Um, I know that it involves spying on people and we may build a fort, underground.',
    order: 749,
    speaker: 'Jim Halpert'
  },
  {
    season: 1,
    episode: 4,
    scene: 14,
    line: 'Jim! Hey. Hi, Pam. Listen, could I talk to you a second about the... paper products?',
    order: 750,
    speaker: 'Dwight Schrute'
  },
  {
    season: 1,
    episode: 4,
    scene: 15,
    line: 'Did you tell Pam about the alliance?',
    order: 751,
    speaker: 'Dwight Schrute'
  },
  { season: 1, episode: 4, scene: 15, line: 'What? No.', order: 752, speaker: 'Jim Halpert' },
  { season: 1, episode: 4, scene: 15, line: 'Just now.', order: 753, speaker: 'Dwight Schrute' },
  {
    season: 1,
    episode: 4,
    scene: 15,
    line:
      "What? Oh no no no. Dwight, no. I'm using her, for the alliance. Who knows the most information about this office? Pam.",
    order: 754,
    speaker: 'Jim Halpert'
  },
  {
    season: 1,
    episode: 4,
    scene: 15,
    line: "Right, that's good, good, pursue this.",
    order: 755,
    speaker: 'Dwight Schrute'
  },
  {
    season: 1,
    episode: 4,
    scene: 15,
    line: "Well I'm trying to. Do you see what I'm doing?",
    order: 756,
    speaker: 'Jim Halpert'
  },
  { season: 1, episode: 4, scene: 15, line: 'Mmm hmm.', order: 757, speaker: 'Dwight Schrute' },
  {
    season: 1,
    episode: 4,
    scene: 15,
    line:
      "But listen, I'm going to have to talk to her a lot. All right? And there may be chatting, and giggling. And you gotta just pretend to ignore it. Wipe it away.",
    order: 758,
    speaker: 'Jim Halpert'
  },
  { season: 1, episode: 4, scene: 15, line: 'Done.', order: 759, speaker: 'Dwight Schrute' },
  { season: 1, episode: 4, scene: 15, line: 'All right.', order: 760, speaker: 'Jim Halpert' },
  {
    season: 1,
    episode: 4,
    scene: 16,
    line:
      "[to the camera] Can you get her? She's right there. [camera zooms in on Meredith at here desk] That is Meredith, the birthday girl. And this... is Meredith's card. Happy Bird-Day. [laughs] Um, let's see. Jim, Jim wrote, 'Meredith, I heard you're turning 46, but, come on, you're an accountant. Just fudge the numbers.' Not bad, pretty funny, I don't appreciate condoning corporate fraud though. Uh, here's the thing. Whatever I write here has to be really, really funny. Because people out there are expecting it. I've already set the bar really high. And they're all worried about their jobs, you know. It's kinda dark out there. Can you imagine if I wrote something like, uh, 'Oh, Meredith. Happy Birthday. You're great. Love, Michael.' [pretends to vomit and laughs]",
    order: 761,
    speaker: 'Michael Scott'
  },
  {
    season: 1,
    episode: 4,
    scene: 17,
    line: "They seem awfully chummy, don't you think?",
    order: 762,
    speaker: 'Dwight Schrute'
  },
  {
    season: 1,
    episode: 4,
    scene: 17,
    line: "Yeah, what do you think that's about?",
    order: 763,
    speaker: 'Jim Halpert'
  },
  { season: 1, episode: 4, scene: 17, line: 'Only one way to find out.', order: 764, speaker: 'Dwight Schrute' },
  { season: 1, episode: 4, scene: 17, line: "I'm on it.", order: 765, speaker: 'Jim Halpert' },
  { season: 1, episode: 4, scene: 18, line: 'You are not going to believe this.', order: 766, speaker: 'Jim Halpert' },
  { season: 1, episode: 4, scene: 18, line: 'What? I believe it.', order: 767, speaker: 'Dwight Schrute' },
  {
    season: 1,
    episode: 4,
    scene: 18,
    line: 'Well, tensions were high in the kitchen.',
    order: 768,
    speaker: 'Jim Halpert'
  },
  {
    season: 1,
    episode: 4,
    scene: 18,
    line: 'I could tell, from the body language.',
    order: 769,
    speaker: 'Dwight Schrute'
  },
  {
    season: 1,
    episode: 4,
    scene: 19,
    line: 'Hey Kev, that looks good. What is it? Turkey?',
    order: 770,
    speaker: 'Jim Halpert'
  },
  { season: 1, episode: 4, scene: 19, line: 'Italian.', order: 771, speaker: 'Kevin Malone' },
  {
    season: 1,
    episode: 4,
    scene: 19,
    line: 'Oh, Italian. Nice. Wow! You got the works there. Red onion, provolone...',
    order: 772,
    speaker: 'Jim Halpert'
  },
  { season: 1, episode: 4, scene: 19, line: 'Yeah.', order: 773, speaker: 'Kevin Malone' },
  {
    season: 1,
    episode: 4,
    scene: 20,
    line: "Toby and Kevin, they're trying to get Angela kicked off.",
    order: 774,
    speaker: 'Jim Halpert'
  },
  {
    season: 1,
    episode: 4,
    scene: 20,
    line: "Good, let 'em. It helps our cause.",
    order: 775,
    speaker: 'Dwight Schrute'
  },
  {
    season: 1,
    episode: 4,
    scene: 20,
    line: "Well, I don't know, if Kevin's in accounting, and Toby's in Human Resources and they're talking...",
    order: 776,
    speaker: 'Jim Halpert'
  },
  { season: 1, episode: 4, scene: 20, line: "Oh, they're forming an alliance", order: 777, speaker: 'Dwight Schrute' },
  { season: 1, episode: 4, scene: 21, line: 'I love their sandwiches.', order: 778, speaker: 'Toby Flenderson' },
  { season: 1, episode: 4, scene: 21, line: 'I love their sandwiches too.', order: 779, speaker: 'Jim Halpert' },
  { season: 1, episode: 4, scene: 21, line: "Their bread's really good.", order: 780, speaker: 'Kevin Malone' },
  { season: 1, episode: 4, scene: 21, line: 'Their bread is very good.', order: 781, speaker: 'Jim Halpert' },
  { season: 1, episode: 4, scene: 22, line: 'Damn it. God!', order: 782, speaker: 'Dwight Schrute' },
  {
    season: 1,
    episode: 4,
    scene: 22,
    line:
      'OK, listen, we need to assume that everyone in the office is forming an alliance and is therefore trying to get us kicked off.',
    order: 783,
    speaker: 'Jim Halpert'
  },
  { season: 1, episode: 4, scene: 22, line: 'God... Damn it! Why us?', order: 784, speaker: 'Dwight Schrute' },
  {
    season: 1,
    episode: 4,
    scene: 22,
    line: "Because we're strong, Dwight. Because we're strong.",
    order: 785,
    speaker: 'Jim Halpert'
  },
  {
    season: 1,
    episode: 4,
    scene: 23,
    line:
      "[staring at birthday card] Meredith, Meredith... Meri... Mary had a little lamb. Mary... Meredith had a little lamb. Don't bring that lamb to work or it'll poop on the floor.",
    order: 786,
    speaker: 'Michael Scott'
  },
  {
    season: 1,
    episode: 4,
    scene: 23,
    line: "Hey, Oscar! Come on in. What's up?",
    order: 787,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 4, scene: 23, line: "Uh, I'm sorry to bother you.", order: 788, speaker: 'Oscar Martinez' },
  {
    season: 1,
    episode: 4,
    scene: 23,
    line: "Oh, not at all. Come on in. What's going on?",
    order: 789,
    speaker: 'Michael Scott'
  },
  {
    season: 1,
    episode: 4,
    scene: 23,
    line:
      "My nephew is involved with, um, a charity for cerebral palsy, and I was wondering if maybe you'd like to... you know... if...",
    order: 790,
    speaker: 'Oscar Martinez'
  },
  { season: 1, episode: 4, scene: 23, line: 'What?', order: 791, speaker: 'Michael Scott' },
  { season: 1, episode: 4, scene: 23, line: 'Donate to the charity?', order: 792, speaker: 'Oscar Martinez' },
  {
    season: 1,
    episode: 4,
    scene: 23,
    line: 'Oh, God. Of course I would. Get it over here. Get that over here.',
    order: 793,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 4, scene: 23, line: 'Thank you.', order: 794, speaker: 'Oscar Martinez' },
  {
    season: 1,
    episode: 4,
    scene: 23,
    line:
      "No, I'm always good... for some serious buckage. Wow. Two dollars, three dollars? People out here do not care about diseases. I am going to give you... $25.",
    order: 795,
    speaker: 'Michael Scott'
  },
  {
    season: 1,
    episode: 4,
    scene: 23,
    line: "That's... that's... that's very generous.",
    order: 796,
    speaker: 'Oscar Martinez'
  },
  {
    season: 1,
    episode: 4,
    scene: 23,
    line:
      "Oh, my gosh, well... Listen, Oscar, generosity and togetherness and community all convalescences into... morale. That's what I say, so...",
    order: 797,
    speaker: 'Michael Scott'
  },
  {
    season: 1,
    episode: 4,
    scene: 24,
    line: '[whispering] Hey, Jim, can I talk to you for a second?',
    order: 798,
    speaker: 'Pam Beesly'
  },
  { season: 1, episode: 4, scene: 24, line: "Sure, what's up?", order: 799, speaker: 'Jim Halpert' },
  {
    season: 1,
    episode: 4,
    scene: 24,
    line:
      "Um, I don't know, I'm just like, I'm going a little crazy 'cause I keep overhearing all these conversations between Michael and corporate about like, staff issues?",
    order: 800,
    speaker: 'Pam Beesly'
  },
  { season: 1, episode: 4, scene: 24, line: 'Oh no?', order: 801, speaker: 'Jim Halpert' },
  {
    season: 1,
    episode: 4,
    scene: 24,
    line:
      "Yeah, he's making me take notes on these meetings and I'm, like, 'These people are my friends.' But he's all like, 'This is confidential. You can't tell anybody.' But I just feel like I want to... aaah. Just promise me you're not gonna say anything.",
    order: 802,
    speaker: 'Pam Beesly'
  },
  {
    season: 1,
    episode: 4,
    scene: 24,
    line: "No, will not, I'm not going to tell anybody. This is between you and me.",
    order: 803,
    speaker: 'Jim Halpert'
  },
  { season: 1, episode: 4, scene: 24, line: 'OK, yeah.', order: 804, speaker: 'Pam Beesly' },
  { season: 1, episode: 4, scene: 24, line: 'Jackpot.', order: 805, speaker: 'Dwight Schrute' },
  {
    season: 1,
    episode: 4,
    scene: 25,
    line: 'That was beautiful. All her idea too. Awesome. She is so great.',
    order: 806,
    speaker: 'Jim Halpert'
  },
  {
    season: 1,
    episode: 4,
    scene: 26,
    line: '[looking at birthday card] Meredith, bad breath. Meredith has bad breath.',
    order: 807,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 4, scene: 26, line: 'Hey, you wanted to see me?', order: 808, speaker: 'Dwight Schrute' },
  {
    season: 1,
    episode: 4,
    scene: 26,
    line: 'Yeah. What do you know about Meredith?',
    order: 809,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 4, scene: 26, line: "I don't think she'd be missed.", order: 810, speaker: 'Dwight Schrute' },
  {
    season: 1,
    episode: 4,
    scene: 26,
    line: "There's not going to be downsizing Dwight, OK? I just, I need to know a little bit more about my friend.",
    order: 811,
    speaker: 'Michael Scott'
  },
  {
    season: 1,
    episode: 4,
    scene: 26,
    line:
      'Name, Meredith Palmer. Uh, personal information, divorced twice, two kids. Uh, Employer, Dunder Mifflin Paper Incorporated. Awards, multiple Dundies.',
    order: 812,
    speaker: 'Dwight Schrute'
  },
  {
    season: 1,
    episode: 4,
    scene: 26,
    line:
      'I know all that. I know all that. I just, I need something kind of embarrassing, you know. Kind of fun, inside.',
    order: 813,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 4, scene: 26, line: 'She had a hysterectomy.', order: 814, speaker: 'Dwight Schrute' },
  { season: 1, episode: 4, scene: 26, line: '[laughs] Which one is that again?', order: 815, speaker: 'Michael Scott' },
  {
    season: 1,
    episode: 4,
    scene: 26,
    line: "That's where they remove the uterus.",
    order: 816,
    speaker: 'Dwight Schrute'
  },
  {
    season: 1,
    episode: 4,
    scene: 26,
    line: "Oh God! Dwight, no. I'm trying to write something funny. What am I going to do with a removed uterus?",
    order: 817,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 4, scene: 26, line: 'It could be kind of funny.', order: 818, speaker: 'Dwight Schrute' },
  {
    season: 1,
    episode: 4,
    scene: 26,
    line:
      "You know what, I am on a deadline here, and just... OK. Thanks, thanks for your help. I'll work it out. Thank you Dwight. That was a waste of time.",
    order: 819,
    speaker: 'Michael Scott'
  },
  {
    season: 1,
    episode: 4,
    scene: 27,
    line:
      "OK, here's the deal. All right? Pam says that one of the alliances is meeting in the warehouse during Meredith's birthday.",
    order: 820,
    speaker: 'Jim Halpert'
  },
  { season: 1, episode: 4, scene: 27, line: 'Oh my God, we have to be there.', order: 821, speaker: 'Dwight Schrute' },
  {
    season: 1,
    episode: 4,
    scene: 27,
    line: "I know, but it's gonna be a little tough because there's no good place to hide there.",
    order: 822,
    speaker: 'Jim Halpert'
  },
  {
    season: 1,
    episode: 4,
    scene: 27,
    line: 'No no, yes there is. Behind the shelves. Oh my God.',
    order: 823,
    speaker: 'Dwight Schrute'
  },
  { season: 1, episode: 4, scene: 27, line: 'What? What?', order: 824, speaker: 'Jim Halpert' },
  {
    season: 1,
    episode: 4,
    scene: 27,
    line: 'I know. I know exactly what to do.',
    order: 825,
    speaker: 'Dwight Schrute'
  },
  { season: 1, episode: 4, scene: 27, line: '[gives Dwight a high five] Great.', order: 826, speaker: 'Jim Halpert' },
  {
    season: 1,
    episode: 4,
    scene: 28,
    line:
      "I'm a deer hunter. I go all the time with my dad. One thing about deer, they have very good vision. One thing about me, I am better at hiding than they are... at vision.",
    order: 827,
    speaker: 'Dwight Schrute'
  },
  {
    season: 1,
    episode: 4,
    scene: 29,
    line: 'This is going to be perfect, OK? Centrally located. Perfect cover. I can hear and see everything.',
    order: 828,
    speaker: 'Dwight Schrute'
  },
  { season: 1, episode: 4, scene: 29, line: 'Good.', order: 829, speaker: 'Jim Halpert' },
  { season: 1, episode: 4, scene: 30, line: 'Michael? Are you done yet?', order: 830, speaker: 'Ryan Howard' },
  {
    season: 1,
    episode: 4,
    scene: 30,
    line:
      "Almost there. Just a sec. Just a second. It is perfect, thank you. Excellent, here we go. It is time, thank you. OK, come on. Let's go! Get the cake. Here we go. Come on! Shhh. Be quiet.",
    order: 831,
    speaker: 'Michael Scott'
  },
  {
    season: 1,
    episode: 4,
    scene: 31,
    line: "Wait, this isn't gonna work. The lid's open.",
    order: 832,
    speaker: 'Jim Halpert'
  },
  { season: 1, episode: 4, scene: 31, line: 'So tape it down.', order: 833, speaker: 'Dwight Schrute' },
  {
    season: 1,
    episode: 4,
    scene: 31,
    line: "I can't do that. You won't be able to breathe.",
    order: 834,
    speaker: 'Jim Halpert'
  },
  {
    season: 1,
    episode: 4,
    scene: 31,
    line: "Look, I can breathe just fine. OK, but if it makes you feel better, I'll poke holes in the box.",
    order: 835,
    speaker: 'Dwight Schrute'
  },
  { season: 1, episode: 4, scene: 31, line: 'Thank you, thank you. OK.', order: 836, speaker: 'Jim Halpert' },
  { season: 1, episode: 4, scene: 32, line: 'Surprise!', order: 837, speaker: 'Everybody' },
  { season: 1, episode: 4, scene: 32, line: 'Oh! Surprise.', order: 838, speaker: 'Meredith Palmer' },
  { season: 1, episode: 4, scene: 32, line: "No, it's ah...", order: 839, speaker: 'Angela Martin' },
  {
    season: 1,
    episode: 4,
    scene: 32,
    line: "It's surprise Meredith. One, two...",
    order: 840,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 4, scene: 32, line: '[tunelessly] Happy birthday to you.', order: 841, speaker: 'Everybody' },
  { season: 1, episode: 4, scene: 32, line: 'Find a key.', order: 842, speaker: 'Michael Scott' },
  { season: 1, episode: 4, scene: 32, line: 'Happy birthday...', order: 843, speaker: 'Everybody' },
  {
    season: 1,
    episode: 4,
    scene: 33,
    line: 'So do you want me to stay here and, you know, stand next to the box?',
    order: 844,
    speaker: 'Jim Halpert'
  },
  {
    season: 1,
    episode: 4,
    scene: 33,
    line: "No, you need to go upstairs to the party so people don't notice we're both gone.",
    order: 845,
    speaker: 'Dwight Schrute'
  },
  { season: 1, episode: 4, scene: 33, line: "Right... That's good.", order: 846, speaker: 'Jim Halpert' },
  {
    season: 1,
    episode: 4,
    scene: 34,
    line:
      "Can I trust Jim? I don't know. Do I have a choice? No, frankly, I don't. Will I trust Jim? Yes. Should I trust Jim? You tell me.",
    order: 847,
    speaker: 'Dwight Schrute'
  },
  {
    season: 1,
    episode: 4,
    scene: 35,
    line: '[singing] ... birthday, dear Meredith Happy birthday to you...',
    order: 848,
    speaker: 'Everybody'
  },
  { season: 1, episode: 4, scene: 35, line: 'And many more!', order: 849, speaker: 'Michael Scott' },
  { season: 1, episode: 4, scene: 36, line: 'Last year, five years ago...', order: 850, speaker: 'Stanley Hudson' },
  { season: 1, episode: 4, scene: 36, line: "You were surprised, weren't you?", order: 851, speaker: 'Michael Scott' },
  { season: 1, episode: 4, scene: 36, line: 'Yes.', order: 852, speaker: 'Meredith Palmer' },
  {
    season: 1,
    episode: 4,
    scene: 36,
    line:
      "You looked freaked, man. We said 'Surprise.' You were, like, 'What?' 'What the hell's goin' on here?' Good cake. Why don't you have some?",
    order: 853,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 4, scene: 36, line: "Uh, I can't. Um...", order: 854, speaker: 'Meredith Palmer' },
  { season: 1, episode: 4, scene: 36, line: 'Come on. A little bit.', order: 855, speaker: 'Michael Scott' },
  { season: 1, episode: 4, scene: 36, line: "I can't eat dairy.", order: 856, speaker: 'Meredith Palmer' },
  {
    season: 1,
    episode: 4,
    scene: 36,
    line: "Oh, right. God, too bad. It's so good.",
    order: 857,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 4, scene: 36, line: 'Yeah, it makes me sick.', order: 858, speaker: 'Meredith Palmer' },
  {
    season: 1,
    episode: 4,
    scene: 36,
    line: "You know what? If I were allergic to dairy, I think I'd kill myself. 'Cause this is way, way too good.",
    order: 859,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 4, scene: 37, line: "He's in a box?", order: 860, speaker: 'Pam Beesly' },
  {
    season: 1,
    episode: 4,
    scene: 37,
    line:
      "Pam, he's in a box. He's downstairs, in a box, on the floor, near the shelves. I'm serious. Go down there and work your magic.",
    order: 861,
    speaker: 'Jim Halpert'
  },
  {
    season: 1,
    episode: 4,
    scene: 38,
    line:
      "[on her cell phone] Hey where are you? Yeah, we were supposed to meet here. What? Oh my gosh! That ties in perfectly with something that Michael was telling me earlier! I just don't know what some of the people in, like, accounting are going to do? It said specifically that...",
    order: 862,
    speaker: 'Pam Beesly'
  },
  { season: 1, episode: 4, scene: 38, line: '[box falls over] Oh.', order: 863, speaker: 'Dwight Schrute' },
  {
    season: 1,
    episode: 4,
    scene: 39,
    line: 'Jim, good party, huh? Just a little something I whipped up. You know, a little morale boost. No big deal.',
    order: 864,
    speaker: 'Michael Scott'
  },
  {
    season: 1,
    episode: 4,
    scene: 39,
    line:
      "Speaking of which, I meant to tell you. Very impressive, the uh, donation you gave to Oscar's charity. What was it? 25 bucks?",
    order: 865,
    speaker: 'Jim Halpert'
  },
  {
    season: 1,
    episode: 4,
    scene: 39,
    line:
      "Well, you know, money isn't everything Jim. It's not the key to happiness. You know what is? Joy. You should remember that. Maybe you'll give more than three dollars next time.",
    order: 866,
    speaker: 'Michael Scott'
  },
  {
    season: 1,
    episode: 4,
    scene: 39,
    line:
      "Yeah, well, three dollars a mile. It's gonna end up being like 50 bucks. So... God, I can't even calculate what you're gonna have to give.",
    order: 867,
    speaker: 'Jim Halpert'
  },
  { season: 1, episode: 4, scene: 39, line: 'Is Oscar around?', order: 868, speaker: 'Michael Scott' },
  {
    season: 1,
    episode: 4,
    scene: 40,
    line:
      "I just thought it was kind of a flat, you know... 25 dollar, one-time donation. I didn't think it was per mile kinda deal. You know, so...",
    order: 869,
    speaker: 'Michael Scott'
  },
  {
    season: 1,
    episode: 4,
    scene: 40,
    line: "Well, that's what a walk-a-thon is.",
    order: 870,
    speaker: 'Oscar Martinez'
  },
  { season: 1, episode: 4, scene: 40, line: 'I know...', order: 871, speaker: 'Michael Scott' },
  {
    season: 1,
    episode: 4,
    scene: 40,
    line: "It says it right on the sheet. Look, look at the sheet. It says, 'However many dollars per mile.'",
    order: 872,
    speaker: 'Oscar Martinez'
  },
  {
    season: 1,
    episode: 4,
    scene: 40,
    line: 'Right. Got it. Yes. So it does. Um...',
    order: 873,
    speaker: 'Michael Scott'
  },
  {
    season: 1,
    episode: 4,
    scene: 40,
    line: "I just think it's kind of cheap to un-donate money to a charity.",
    order: 874,
    speaker: 'Oscar Martinez'
  },
  {
    season: 1,
    episode: 4,
    scene: 40,
    line:
      "No, no, no, no, no. That wasn't what I wasn't, that wasn't... No. It-it-it's not about the money. It's just... it... it's the ethics of the thing, Oscar. How's your nephew? Is he in good shape?",
    order: 875,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 4, scene: 40, line: 'Yeah.', order: 876, speaker: 'Oscar Martinez' },
  {
    season: 1,
    episode: 4,
    scene: 40,
    line: 'How many miles did he do last year?',
    order: 877,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 4, scene: 40, line: 'Last year, he walked 18 miles.', order: 878, speaker: 'Oscar Martinez' },
  {
    season: 1,
    episode: 4,
    scene: 40,
    line: 'Son of a bitch. That is impressive.',
    order: 879,
    speaker: 'Michael Scott'
  },
  {
    season: 1,
    episode: 4,
    scene: 41,
    line: 'Happy Birthday. [gives Meredith her card]',
    order: 880,
    speaker: 'Pam Beesly'
  },
  {
    season: 1,
    episode: 4,
    scene: 41,
    line: 'Read it out loud. And say who wrote everything so we know whose is the best.',
    order: 881,
    speaker: 'Michael Scott'
  },
  {
    season: 1,
    episode: 4,
    scene: 41,
    line:
      "Happy Bird-day' Um... 'Meredith, good news. You're not actually a year older because you work here, where time stands still.'",
    order: 882,
    speaker: 'Meredith Palmer'
  },
  {
    season: 1,
    episode: 4,
    scene: 41,
    line: "[under his breath] I don't know about that.",
    order: 883,
    speaker: 'Michael Scott'
  },
  {
    season: 1,
    episode: 4,
    scene: 41,
    line: "That was Stanley. 'Meredith, happy birthday, you're the best. Love, Pam.'",
    order: 884,
    speaker: 'Meredith Palmer'
  },
  {
    season: 1,
    episode: 4,
    scene: 41,
    line: '[pretends to vomit] Huh! Thanks, downer.',
    order: 885,
    speaker: 'Michael Scott'
  },
  {
    season: 1,
    episode: 4,
    scene: 41,
    line:
      "This is from Michael. 'Meredith, let's hope the only downsizing that happens to you is that someone downsizes your age.'",
    order: 886,
    speaker: 'Meredith Palmer'
  },
  {
    season: 1,
    episode: 4,
    scene: 41,
    line: "Because of the downsizing. Rumors. And because you're gettin' old.",
    order: 887,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 4, scene: 41, line: "No, I... I get it. It's funny.", order: 888, speaker: 'Meredith Palmer' },
  {
    season: 1,
    episode: 4,
    scene: 41,
    line:
      "[laughs] You didn't get the joke. So, that's cool. That's, you know what? Actually... I have a bunch of these, good ones, that I didn't use. Um... Oh, where's that? Oh, OK, here's a good one. Um... 'Hey Meredith, Liz Taylor called, she wants her age back and her divorces back.' 'Cause Meredith's been divorced like, twice. Is that right?",
    order: 889,
    speaker: 'Michael Scott'
  },
  {
    season: 1,
    episode: 4,
    scene: 41,
    line: "You're right. You're right. Yes.",
    order: 890,
    speaker: 'Meredith Palmer'
  },
  {
    season: 1,
    episode: 4,
    scene: 41,
    line: "Divorce. Um... OK, 'Meredith is so old...'",
    order: 891,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 4, scene: 41, line: 'How old is she?', order: 892, speaker: 'Oscar Martinez' },
  {
    season: 1,
    episode: 4,
    scene: 41,
    line: "Everybody? If... could do it? 'Meredith is so old...'",
    order: 893,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 4, scene: 41, line: 'How old is she?', order: 894, speaker: 'Everybody' },
  {
    season: 1,
    episode: 4,
    scene: 41,
    line: "She's so old, she went into an antique store and they kept her.'",
    order: 895,
    speaker: 'Michael Scott'
  },
  {
    season: 1,
    episode: 4,
    scene: 41,
    line: "That wasn't even mine. I got that off the Internet. Website. Um, don't get mad at me.",
    order: 896,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 4, scene: 41, line: 'Uh, nice party Michael.', order: 897, speaker: 'Oscar Martinez' },
  {
    season: 1,
    episode: 4,
    scene: 41,
    line:
      "This isn't my fault. Ladies, not your best effort. The streamers? I think we could have done better than that.",
    order: 898,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 4, scene: 41, line: "Phyllis wanted red, I didn't.", order: 899, speaker: 'Angela Martin' },
  { season: 1, episode: 4, scene: 41, line: 'Oh, boy... You...', order: 900, speaker: 'Phyllis Lapin' },
  {
    season: 1,
    episode: 4,
    scene: 41,
    line:
      "OK, we... all right. People, hold on, hold on. Just a second. OK, I think we're losing sight of what is really important here. And that is that we are... a group of people... who work together. I was... I really wasn't gonna flaunt this. I have made a very sizable donation to Oscar's nephew's... walkathon. $25.",
    order: 901,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 4, scene: 41, line: 'Per mile.', order: 902, speaker: 'Oscar Martinez' },
  { season: 1, episode: 4, scene: 41, line: 'Per mile, yes.', order: 903, speaker: 'Michael Scott' },
  {
    season: 1,
    episode: 4,
    scene: 42,
    line:
      "When I retire, I... don't want to just disappear to an island somewhere. I wanna be the guy who gives everything back.",
    order: 904,
    speaker: 'Michael Scott'
  },
  {
    season: 1,
    episode: 4,
    scene: 43,
    line: 'A check for the kids, and for the team.',
    order: 905,
    speaker: 'Michael Scott'
  },
  {
    season: 1,
    episode: 4,
    scene: 44,
    line:
      "I want it to be like... 'Hey, who donated that hospital wing that is saving so many lives?' 'Um, well, I don't, I don't know. It was anonymous.' 'Well, guess what, [whispering] that was Michael Scott.' 'But it was anonymous, how do you know?' 'Because I'm him.'",
    order: 906,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 4, scene: 45, line: 'Thank you, Michael.', order: 907, speaker: 'Oscar Martinez' },
  {
    season: 1,
    episode: 4,
    scene: 45,
    line: "Come here. [hugs Oscar and In a low voice] Don't cash that till Friday, OK?",
    order: 908,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 4, scene: 46, line: 'Really? Today?', order: 909, speaker: 'Toby Flenderson' },
  { season: 1, episode: 4, scene: 46, line: 'Yeah.', order: 910, speaker: 'Ryan Howard' },
  { season: 1, episode: 4, scene: 46, line: 'Oh, Happy Birthday.', order: 911, speaker: 'Toby Flenderson' },
  { season: 1, episode: 4, scene: 46, line: 'Thanks.', order: 912, speaker: 'Ryan Howard' },
  { season: 1, episode: 4, scene: 46, line: 'Yeah, I could say something.', order: 913, speaker: 'Toby Flenderson' },
  { season: 1, episode: 4, scene: 46, line: "No, don't. Don't do that.", order: 914, speaker: 'Ryan Howard' },
  {
    season: 1,
    episode: 4,
    scene: 47,
    line: 'OK, OK. I have something that totally tops the box.',
    order: 915,
    speaker: 'Jim Halpert'
  },
  { season: 1, episode: 4, scene: 47, line: 'Oh, tell me, tell me.', order: 916, speaker: 'Pam Beesly' },
  {
    season: 1,
    episode: 4,
    scene: 47,
    line:
      'OK. I have just convinced Dwight that he needs to go to Stamford and... [Pam starts laughing]... spy on our other branch. No no no.',
    order: 917,
    speaker: 'Jim Halpert'
  },
  {
    season: 1,
    episode: 4,
    scene: 47,
    line: 'But before he does so, I told him that he should dye his hair to go undercover.',
    order: 918,
    speaker: 'Jim Halpert'
  },
  { season: 1, episode: 4, scene: 47, line: "[laughing] That's perfect!", order: 919, speaker: 'Pam Beesly' },
  {
    season: 1,
    episode: 4,
    scene: 47,
    line: 'If we can get him to drive to Connecticut... and put peroxide in his hair...',
    order: 920,
    speaker: 'Jim Halpert'
  },
  {
    season: 1,
    episode: 4,
    scene: 47,
    line: '[yelling] What the hell is this? What are you trying to cop a feel or something? Huh Halpert?',
    order: 921,
    speaker: 'Roy Anderson'
  },
  { season: 1, episode: 4, scene: 47, line: 'No, no, dude, no.', order: 922, speaker: 'Jim Halpert' },
  { season: 1, episode: 4, scene: 47, line: 'Hey, Hey!', order: 923, speaker: 'Pam Beesly' },
  {
    season: 1,
    episode: 4,
    scene: 47,
    line: 'No, dude, no, I was just, listen! Whoa.',
    order: 924,
    speaker: 'Jim Halpert'
  },
  { season: 1, episode: 4, scene: 47, line: 'Come on.', order: 925, speaker: 'Pam Beesly' },
  {
    season: 1,
    episode: 4,
    scene: 47,
    line:
      "God, I don't even, I don't even know how to explain this. Uh, um... Dwight, uh, asked me to be in an alliance. And then um... um... we were... we've just been messing with him. Uh, because of the whole alliance thing. Um...",
    order: 926,
    speaker: 'Jim Halpert'
  },
  { season: 1, episode: 4, scene: 47, line: "It's just office pranks.", order: 927, speaker: 'Pam Beesly' },
  {
    season: 1,
    episode: 4,
    scene: 47,
    line: "It's stupid. It's, it's just office pranks.",
    order: 928,
    speaker: 'Jim Halpert'
  },
  {
    season: 1,
    episode: 4,
    scene: 47,
    line: '[looking at Dwight] An alliance? What the hell is he talking about?',
    order: 929,
    speaker: 'Roy Anderson'
  },
  { season: 1, episode: 4, scene: 47, line: 'I have absolutely no idea.', order: 930, speaker: 'Dwight Schrute' },
  { season: 1, episode: 4, scene: 47, line: 'Come on.', order: 931, speaker: 'Roy Anderson' },
  {
    season: 1,
    episode: 4,
    scene: 48,
    line:
      "Do I feel bad about betraying Jim? Not at all. That's the game. Convince him we're in an alliance, get some information, throw him to the wolves.",
    order: 932,
    speaker: 'Dwight Schrute'
  },
  {
    season: 1,
    episode: 4,
    scene: 48,
    line:
      "[With blonde hair] That's politics baby. Get what you can out of someone, then crush them. I think Jim might have learned a very valuable lesson.",
    order: 933,
    speaker: 'Dwight Schrute'
  },
  { season: 1, episode: 4, scene: 56, line: 'Hey.', order: 934, speaker: 'Dwight Schrute' },
  { season: 1, episode: 4, scene: 56, line: 'Hey.', order: 935, speaker: 'Michael Scott' },
  { season: 1, episode: 4, scene: 56, line: 'You wanted to see me?', order: 936, speaker: 'Dwight Schrute' },
  {
    season: 1,
    episode: 4,
    scene: 56,
    line: 'Uh, yeah. What do you know about Meredith?',
    order: 937,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 4, scene: 56, line: "I don't think she'd be missed.", order: 938, speaker: 'Dwight Schrute' },
  {
    season: 1,
    episode: 4,
    scene: 56,
    line: "There's not going to be downsizing Dwight, OK? I just, I need to know a little bit more about my friend.",
    order: 939,
    speaker: 'Michael Scott'
  },
  {
    season: 1,
    episode: 4,
    scene: 56,
    line:
      'Name, Meredith Palmer. Uh, personal information, divorced twice, two kids. Uh, Employer, Dunder Mifflin Paper Incorporated. Awards, multiple Dundies.',
    order: 940,
    speaker: 'Dwight Schrute'
  },
  {
    season: 1,
    episode: 4,
    scene: 56,
    line:
      'I know all that. I know all that. I just, I need something kind of embarrassing, you know. Kind of fun, inside.',
    order: 941,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 4, scene: 56, line: 'She had a hysterectomy.', order: 942, speaker: 'Dwight Schrute' },
  { season: 1, episode: 4, scene: 56, line: 'Which one is that again?', order: 943, speaker: 'Michael Scott' },
  {
    season: 1,
    episode: 4,
    scene: 56,
    line: "That's where they remove the uterus.",
    order: 944,
    speaker: 'Dwight Schrute'
  },
  {
    season: 1,
    episode: 4,
    scene: 56,
    line:
      "Oh God! Dwight, no. I'm trying to write something funny here, okay. What am I going to do with a removed uterus?",
    order: 945,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 4, scene: 56, line: 'It could be kind of funny.', order: 946, speaker: 'Dwight Schrute' },
  {
    season: 1,
    episode: 4,
    scene: 56,
    line: '[sighs] Come up with a joke that I can use, okay?',
    order: 947,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 4, scene: 56, line: 'Mmm-hmm.', order: 948, speaker: 'Dwight Schrute' },
  { season: 1, episode: 4, scene: 56, line: 'Help me out here.', order: 949, speaker: 'Michael Scott' },
  {
    season: 1,
    episode: 4,
    scene: 56,
    line: "Okay. Hey Meredith, where's your uterus?",
    order: 950,
    speaker: 'Dwight Schrute'
  },
  {
    season: 1,
    episode: 4,
    scene: 56,
    line: 'No, not a uterus joke Dwight. Please. Something useable. A joke.',
    order: 951,
    speaker: 'Michael Scott'
  },
  {
    season: 1,
    episode: 4,
    scene: 56,
    line: 'If I find a joke for you, will you grant me immunity? [cut to Jim holding his hands up like a champion]',
    order: 952,
    speaker: 'Dwight Schrute'
  },
  { season: 1, episode: 4, scene: 56, line: 'What?', order: 953, speaker: 'Michael Scott' },
  { season: 1, episode: 4, scene: 56, line: 'From the downsizing.', order: 954, speaker: 'Dwight Schrute' },
  {
    season: 1,
    episode: 4,
    scene: 56,
    line:
      "There's not going to be downsizing, Dwight. You know what, I am on a deadline here and just, okay. Thanks. Thanks for your help. I'm... I'll work it out. Thank you Dwight. [Dwight leaves] Thanks for coming in, that's always... Always helpful when I give him a call. Call him in.",
    order: 955,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 5, scene: 1, line: '[to Jim]: Hey, you ready?', order: 956, speaker: 'Michael Scott' },
  {
    season: 1,
    episode: 5,
    scene: 1,
    line: 'All right, all right, secret sign. Hey, Ryan. [Ryan holds up his bag] Very good. Excellent, excellent.',
    order: 957,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 5, scene: 1, line: 'Michael!', order: 958, speaker: 'Dwight Schrute' },
  {
    season: 1,
    episode: 5,
    scene: 2,
    line:
      "Today at lunchtime we're going to be playing the warehouse staff at a friendly little game of basketball. My idea. Last time I was down there, I noticed they'd put up a couple of hoops, and I play basketball every weekend. So I thought, 'This might be kinda fun.' And so I started messing around and... I'm sinking a few, you know. Swish, swish, swish. Nothing but net. And their jaws just dropped to the floor. African-Americans! So... you know, it's really just a good friendly game, a reason to get together.",
    order: 959,
    speaker: 'Michael Scott'
  },
  {
    season: 1,
    episode: 5,
    scene: 3,
    line: "Pam, Pam, thank you ma'am. Messages, please. Thank you.",
    order: 960,
    speaker: 'Michael Scott'
  },
  {
    season: 1,
    episode: 5,
    scene: 3,
    line: 'Michael, can I talk to you, please? Privately? In your office? I think I should be on the team.',
    order: 961,
    speaker: 'Dwight Schrute'
  },
  {
    season: 1,
    episode: 5,
    scene: 3,
    line: "No. And that's not me being mean, Dwight. That is based on your past behavior.",
    order: 962,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 5, scene: 3, line: 'Oh, please.', order: 963, speaker: 'Dwight Schrute' },
  {
    season: 1,
    episode: 5,
    scene: 3,
    line: '[to camera] When I let him come to my pick-up game...',
    order: 964,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 5, scene: 3, line: 'I apologized for that.', order: 965, speaker: 'Dwight Schrute' },
  { season: 1, episode: 5, scene: 3, line: '[to Dwight] I vouched for you.', order: 966, speaker: 'Michael Scott' },
  { season: 1, episode: 5, scene: 3, line: 'Michael, I...', order: 967, speaker: 'Dwight Schrute' },
  {
    season: 1,
    episode: 5,
    scene: 3,
    line:
      "I vouched for you in front of Todd Packer, Dwight. All right, here's what I'm going to do. The hand strikes and gives a flower. You are not going to play basketball. But I need somebody to come in and take over the holiday and weekend work calendar.",
    order: 968,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 5, scene: 3, line: 'I can handle that.', order: 969, speaker: 'Dwight Schrute' },
  {
    season: 1,
    episode: 5,
    scene: 3,
    line:
      "Good. Excellent, it'll be fun. Because corporate, uh, wants someone to be here on Saturday. And so we're going to have to have some people come in on the weekend, and I know nobody's gonna want to do it and I know everybody's gonna complain and bitch and I don't want to have to deal with that.",
    order: 970,
    speaker: 'Michael Scott'
  },
  {
    season: 1,
    episode: 5,
    scene: 3,
    line: "And that's why you have an assistant regional manager.",
    order: 971,
    speaker: 'Dwight Schrute'
  },
  {
    season: 1,
    episode: 5,
    scene: 3,
    line: 'Yes it is. Assistant to the regional manager.',
    order: 972,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 5, scene: 3, line: '[to camera] Same thing.', order: 973, speaker: 'Dwight Schrute' },
  { season: 1, episode: 5, scene: 3, line: "No, it's not. It's lower, so...", order: 974, speaker: 'Michael Scott' },
  { season: 1, episode: 5, scene: 3, line: "It's close.", order: 975, speaker: 'Dwight Schrute' },
  {
    season: 1,
    episode: 5,
    scene: 4,
    line: 'So we need someone to work this Saturday and I think that, that should be...Jim.',
    order: 976,
    speaker: 'Dwight Schrute'
  },
  {
    season: 1,
    episode: 5,
    scene: 4,
    line:
      "God, this is so sad. This is the smallest amount of power I've ever seen go to someone's head. Phyllis, can you believe this?",
    order: 977,
    speaker: 'Jim Halpert'
  },
  { season: 1, episode: 5, scene: 4, line: 'Keep me out of it.', order: 978, speaker: 'Phyllis Lapin' },
  {
    season: 1,
    episode: 5,
    scene: 5,
    line:
      "My fiance has plans for us this Saturday. So I really hope that Dwight doesn't make me work. Maybe I should sleep with him? I'm kidding, kidding. Totally kidding.",
    order: 979,
    speaker: 'Pam Beesly'
  },
  {
    season: 1,
    episode: 5,
    scene: 6,
    line:
      "All right, managing by walking around. This is our warehouse. Or, as I like to call it, the whorehouse. But don't you call it that, I've earned the right.",
    order: 980,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 5, scene: 6, line: "Fine, don't worry about that.", order: 981, speaker: 'Ryan Howard' },
  {
    season: 1,
    episode: 5,
    scene: 6,
    line:
      "And here we have 'Mister Roger's Neighborhood.' Come on over here. Hey, this is Ryan. He's temping upstairs.",
    order: 982,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 5, scene: 6, line: "What's up?", order: 983, speaker: 'Lonny' },
  {
    season: 1,
    episode: 5,
    scene: 6,
    line: 'And this is the foreman. Mista Ra-jahs.',
    order: 984,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 5, scene: 6, line: "It's not my real name.", order: 985, speaker: 'Darryl Philbin' },
  {
    season: 1,
    episode: 5,
    scene: 6,
    line: "No, it's Darryl. Darryl is Mista Ra-jahs.",
    order: 986,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 5, scene: 6, line: 'Darryl Rogers?', order: 987, speaker: 'Ryan Howard' },
  {
    season: 1,
    episode: 5,
    scene: 6,
    line: 'Darryl Philbin. Then Regis, then Rege, then Roger, then Mister Rogers.',
    order: 988,
    speaker: 'Darryl Philbin'
  },
  {
    season: 1,
    episode: 5,
    scene: 6,
    line:
      '[laughs] And that is Lonny. And this is Roy. Roy dates Pam. You know, the uh, the best looking one upstairs.',
    order: 989,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 5, scene: 6, line: 'Yeah, yeah.', order: 990, speaker: 'Ryan Howard' },
  {
    season: 1,
    episode: 5,
    scene: 6,
    line: "You still getting it regular man? Huh? I mean, I can tell her it's part of the job! Rapport!",
    order: 991,
    speaker: 'Michael Scott'
  },
  {
    season: 1,
    episode: 5,
    scene: 7,
    line:
      "[on the phone] No, no, I know that the warranty's expired, but isn't it supposed to last longer than two years if it isn't defective? OK, fine, three years.",
    order: 992,
    speaker: 'Pam Beesly'
  },
  {
    season: 1,
    episode: 5,
    scene: 8,
    line:
      'Pam gets a little down. Her toaster oven broke. Um, which she got at her engagement shower. Um, for a wedding that still has yet to be set... and that was three years ago.',
    order: 993,
    speaker: 'Jim Halpert'
  },
  {
    season: 1,
    episode: 5,
    scene: 9,
    line: "So, um, one o'clock sharp and we've got a game on.",
    order: 994,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 5, scene: 9, line: "We're loading at one.", order: 995, speaker: 'Darryl Philbin' },
  {
    season: 1,
    episode: 5,
    scene: 9,
    line: "Oh, I see, you're chickening out on me. You're bailing on me.",
    order: 996,
    speaker: 'Michael Scott'
  },
  {
    season: 1,
    episode: 5,
    scene: 9,
    line: "No, we got a truck going out at 1:15. So, that's the busy time.",
    order: 997,
    speaker: 'Darryl Philbin'
  },
  {
    season: 1,
    episode: 5,
    scene: 9,
    line:
      "Oh, well, I'm glad that some time is a busy time because whenever I'm down here it doesn't seem too busy to me. Oh, oh. You can dish it out, but you can't take it. OK, fine, have it your way. [clucking and dancing like a chicken]",
    order: 998,
    speaker: 'Michael Scott'
  },
  {
    season: 1,
    episode: 5,
    scene: 9,
    line: "All right, fine, you know what? One o'clock.",
    order: 999,
    speaker: 'Darryl Philbin'
  },
  { season: 1, episode: 5, scene: 9, line: 'All right, see you at one.', order: 1000, speaker: 'Michael Scott' },
  { season: 1, episode: 5, scene: 10, line: 'Are we ready for the game?', order: 1001, speaker: 'Michael Scott' },
  { season: 1, episode: 5, scene: 10, line: '[half-heartedly] Yeah.', order: 1002, speaker: 'Everybody' },
  {
    season: 1,
    episode: 5,
    scene: 10,
    line:
      'I... yeah, yeah. I know, grumble, grumble. But you would follow me to the ends of the earth, grumbling all the way. Like that, uh, dwarf from Lord of the Rings.',
    order: 1003,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 5, scene: 10, line: 'Gimli.', order: 1004, speaker: 'Dwight Schrute' },
  {
    season: 1,
    episode: 5,
    scene: 10,
    line: "Nerd. That is why you're not on the team.",
    order: 1005,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 5, scene: 10, line: 'Just trying to be helpful.', order: 1006, speaker: 'Dwight Schrute' },
  {
    season: 1,
    episode: 5,
    scene: 10,
    line: "Uh, [in a nerdy voice] 'I'll help, Elwyn Dragonslayer, uh, ten points, power sword.'",
    order: 1007,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 5, scene: 10, line: "That's him.", order: 1008, speaker: 'Jim Halpert' },
  {
    season: 1,
    episode: 5,
    scene: 10,
    line: "OK, so, let's put together a starting line-up, shall we? Stanley of course.",
    order: 1009,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 5, scene: 10, line: "I'm sorry?", order: 1010, speaker: 'Stanley Hudson' },
  { season: 1, episode: 5, scene: 10, line: 'Um, what do you play? Center?', order: 1011, speaker: 'Michael Scott' },
  { season: 1, episode: 5, scene: 10, line: "Why 'of course'?", order: 1012, speaker: 'Stanley Hudson' },
  { season: 1, episode: 5, scene: 10, line: 'Uh...', order: 1013, speaker: 'Michael Scott' },
  { season: 1, episode: 5, scene: 10, line: "What's that supposed to mean?", order: 1014, speaker: 'Stanley Hudson' },
  {
    season: 1,
    episode: 5,
    scene: 10,
    line: "Uh, I don't know. I don't remember saying that.",
    order: 1015,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 5, scene: 10, line: 'Uh, I heard it.', order: 1016, speaker: 'Jim Halpert' },
  {
    season: 1,
    episode: 5,
    scene: 10,
    line: 'Well, people hear a lot of things, man. Um... other starters... Me, of course. I heard it that time.',
    order: 1017,
    speaker: 'Michael Scott'
  },
  {
    season: 1,
    episode: 5,
    scene: 10,
    line: "I'd like to play if it's just for fun. I played basketball in school.",
    order: 1018,
    speaker: 'Phyllis Lapin'
  },
  {
    season: 1,
    episode: 5,
    scene: 10,
    line:
      '[ignores Phyllis)] Um... Yeah. Who else? We have Jim. We have Ryan, the new guy, right? Untested. Willing to prove himself now. A lot of passion, a lot of heart.',
    order: 1019,
    speaker: 'Michael Scott'
  },
  {
    season: 1,
    episode: 5,
    scene: 10,
    line: "But, I'm getting paid to skip lunch?",
    order: 1020,
    speaker: 'Ryan Howard'
  },
  { season: 1, episode: 5, scene: 10, line: 'Yes.', order: 1021, speaker: 'Michael Scott' },
  { season: 1, episode: 5, scene: 10, line: 'OK.', order: 1022, speaker: 'Ryan Howard' },
  {
    season: 1,
    episode: 5,
    scene: 10,
    line: 'Yes, this is business. The, uh, business of team building and morale boosting. Uh, who else?',
    order: 1023,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 5, scene: 10, line: 'I can help out, if you need me.', order: 1024, speaker: 'Oscar Martinez' },
  {
    season: 1,
    episode: 5,
    scene: 10,
    line: 'I will use your talents come baseball season, my friend. Or if we box.',
    order: 1025,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 5, scene: 10, line: 'I have a hoop in my driveway.', order: 1026, speaker: 'Kevin Malone' },
  { season: 1, episode: 5, scene: 10, line: 'No.', order: 1027, speaker: 'Michael Scott' },
  { season: 1, episode: 5, scene: 10, line: 'I have a sports bra.', order: 1028, speaker: 'Phyllis Lapin' },
  { season: 1, episode: 5, scene: 10, line: 'No, no, ridiculous.', order: 1029, speaker: 'Michael Scott' },
  {
    season: 1,
    episode: 5,
    scene: 10,
    line: 'Michael, look. [Dwight throws paper at the garbage can]',
    order: 1030,
    speaker: 'Dwight Schrute'
  },
  {
    season: 1,
    episode: 5,
    scene: 10,
    line: 'Close. All right, uh... Me, Stan the man, Jim, Ryan and Dwight.',
    order: 1031,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 5, scene: 10, line: 'Yes!', order: 1032, speaker: 'Dwight Schrute' },
  { season: 1, episode: 5, scene: 10, line: 'Sorry Phyllis.', order: 1033, speaker: 'Michael Scott' },
  { season: 1, episode: 5, scene: 10, line: 'Can I be team captain?', order: 1034, speaker: 'Dwight Schrute' },
  { season: 1, episode: 5, scene: 10, line: "No, I'm team captain.", order: 1035, speaker: 'Michael Scott' },
  { season: 1, episode: 5, scene: 10, line: 'Can I be team manager?', order: 1036, speaker: 'Dwight Schrute' },
  {
    season: 1,
    episode: 5,
    scene: 10,
    line: 'No, I am the team manager. You can be assistant to the team manager.',
    order: 1037,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 5, scene: 10, line: 'Assistant team manager?', order: 1038, speaker: 'Dwight Schrute' },
  { season: 1, episode: 5, scene: 10, line: 'No.', order: 1039, speaker: 'Michael Scott' },
  {
    season: 1,
    episode: 5,
    scene: 10,
    line: "OK, we'll see who's working this weekend then.",
    order: 1040,
    speaker: 'Dwight Schrute'
  },
  {
    season: 1,
    episode: 5,
    scene: 10,
    line: "Jim, you're in charge of the vacation schedule now.",
    order: 1041,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 5, scene: 10, line: 'Oh my God.', order: 1042, speaker: 'Jim Halpert' },
  { season: 1, episode: 5, scene: 10, line: 'Threat neutralized.', order: 1043, speaker: 'Michael Scott' },
  {
    season: 1,
    episode: 5,
    scene: 11,
    line: '[hits Pam in the head with a piece of paper] Off the backboard!',
    order: 1044,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 5, scene: 11, line: "Please don't throw garbage at me.", order: 1045, speaker: 'Pam Beesly' },
  {
    season: 1,
    episode: 5,
    scene: 11,
    line:
      'Oh, Pam with a zinger. Hey, Pam, how would you, like to be our cheerleader today? You know, some, ah, pigtails? A little, ah, halter top, you could tie that up. And you know, something a little, just, youthful, for a change. Just this once?',
    order: 1046,
    speaker: 'Michael Scott'
  },
  {
    season: 1,
    episode: 5,
    scene: 11,
    line: "I don't think so Michael. Besides, I can't cheer against my fiance.",
    order: 1047,
    speaker: 'Pam Beesly'
  },
  {
    season: 1,
    episode: 5,
    scene: 11,
    line: "I'll do it. Wear a little flouncey skirt if you want, and...",
    order: 1048,
    speaker: 'Jim Halpert'
  },
  {
    season: 1,
    episode: 5,
    scene: 11,
    line:
      'Yeah, I bet you would. Just try not to be too gay on the court. And by gay I mean, um, you know, not in a homosexual way at all. I mean the uh, you know, like the bad-at-sports way. I think that goes without saying.',
    order: 1049,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 5, scene: 11, line: 'Maybe Angela would cheerlead.', order: 1050, speaker: 'Pam Beesly' },
  { season: 1, episode: 5, scene: 11, line: 'Oh, yeah right.', order: 1051, speaker: 'Michael Scott' },
  { season: 1, episode: 5, scene: 11, line: "I'll do it.", order: 1052, speaker: 'Phyllis Lapin' },
  {
    season: 1,
    episode: 5,
    scene: 11,
    line:
      "Oh, yuck, that's worse than you playing. ... 'Cause we need you as an alternate in case somebody gets hurt. That's where we need you. Blessed be those who sit and wait. You made it, suit up, you're on the team! All right, cool! Very good.",
    order: 1053,
    speaker: 'Michael Scott'
  },
  {
    season: 1,
    episode: 5,
    scene: 12,
    line: 'Oh-oh. Oh-oh. A spy from the warehouse. Trying to figure out our plays, huh, man?',
    order: 1054,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 5, scene: 12, line: 'Just getting a tea bag.', order: 1055, speaker: 'Darryl Philbin' },
  {
    season: 1,
    episode: 5,
    scene: 12,
    line:
      "Oh ho, oh, he's running. He's running. He's running, but he can't hide because you know what? One o'clock, you better bring your 'A' game. Because me, and my, posse guys are gonna be in your face. Right in your face!",
    order: 1056,
    speaker: 'Michael Scott'
  },
  {
    season: 1,
    episode: 5,
    scene: 12,
    line: "Why don't we make it more interesting? Loser buys dinner at Farley's.",
    order: 1057,
    speaker: 'Darryl Philbin'
  },
  {
    season: 1,
    episode: 5,
    scene: 12,
    line:
      "Whoa-ho. I like the way you think. You know what, I'm gonna take that one step further. Loser, works, on Saturday.",
    order: 1058,
    speaker: 'Michael Scott'
  },
  {
    season: 1,
    episode: 5,
    scene: 12,
    line: "No, that's not as much fun. You know what?",
    order: 1059,
    speaker: 'Darryl Philbin'
  },
  { season: 1, episode: 5, scene: 12, line: 'What?', order: 1060, speaker: 'Michael Scott' },
  { season: 1, episode: 5, scene: 12, line: "You're on.", order: 1061, speaker: 'Darryl Philbin' },
  {
    season: 1,
    episode: 5,
    scene: 12,
    line: "OK. Cool, you're on. [to Dwight] Don't screw this up.",
    order: 1062,
    speaker: 'Michael Scott'
  },
  {
    season: 1,
    episode: 5,
    scene: 13,
    line: "[to camera] Classic beginner's mistake, eating before a game.",
    order: 1063,
    speaker: 'Michael Scott'
  },
  {
    season: 1,
    episode: 5,
    scene: 14,
    line:
      "Has anyone seen the first-aid kit? [Dwight holds the kit up] How many times have I told you? I'm the safety officer, not you.",
    order: 1064,
    speaker: 'Angela Martin'
  },
  {
    season: 1,
    episode: 5,
    scene: 15,
    line:
      "Basketball? It was kind of my thing in high school. And I'm, yeah, I'm looking forward to playing. You know, I think I'm gonna impress a few people in here.",
    order: 1065,
    speaker: 'Jim Halpert'
  },
  { season: 1, episode: 5, scene: 16, line: 'You coming down?', order: 1066, speaker: 'Jim Halpert' },
  {
    season: 1,
    episode: 5,
    scene: 16,
    line: "Yeah, I'm just forwarding the phones.",
    order: 1067,
    speaker: 'Pam Beesly'
  },
  { season: 1, episode: 5, scene: 16, line: 'You gonna wish me luck?', order: 1068, speaker: 'Jim Halpert' },
  { season: 1, episode: 5, scene: 16, line: "Yeah, you're gonna need it.", order: 1069, speaker: 'Pam Beesly' },
  { season: 1, episode: 5, scene: 16, line: 'Whoa.', order: 1070, speaker: 'Jim Halpert' },
  { season: 1, episode: 5, scene: 16, line: 'Is that trash talk from Pam?', order: 1071, speaker: 'Jim Halpert' },
  {
    season: 1,
    episode: 5,
    scene: 16,
    line: "[laughing] I'm just saying, Roy is very competitive.",
    order: 1072,
    speaker: 'Pam Beesly'
  },
  { season: 1, episode: 5, scene: 16, line: 'Oh.', order: 1073, speaker: 'Jim Halpert' },
  {
    season: 1,
    episode: 5,
    scene: 16,
    line: 'And he wants to take the WaveRunners to the lake this Saturday so...',
    order: 1074,
    speaker: 'Pam Beesly'
  },
  {
    season: 1,
    episode: 5,
    scene: 16,
    line:
      "Well, I'm going to the outlet mall on Saturday, so if you wanna save big on brand names and Roy has to work, which he will, because I'm also competitive, you should feel free to come along.",
    order: 1075,
    speaker: 'Jim Halpert'
  },
  {
    season: 1,
    episode: 5,
    scene: 16,
    line: "Um, I think I'm gonna be up at the lake.",
    order: 1076,
    speaker: 'Pam Beesly'
  },
  {
    season: 1,
    episode: 5,
    scene: 16,
    line: "I think I'll see you at the mall. Yeah.",
    order: 1077,
    speaker: 'Jim Halpert'
  },
  {
    season: 1,
    episode: 5,
    scene: 17,
    line:
      "Hey, there he is! Secret weapon! All right, guys, come on, let's bring it in! Here we go! OK, listen, this is just going to be a friendly game, right? We are all on the same team here, the Dunder Mifflin team. Of course, if you beat us, you're fired. That's a joke. OK, let's do it.",
    order: 1078,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 5, scene: 17, line: 'Have a good game man.', order: 1079, speaker: 'Jim Halpert' },
  { season: 1, episode: 5, scene: 17, line: 'Yeah, you too. Should be fun.', order: 1080, speaker: 'Roy Anderson' },
  {
    season: 1,
    episode: 5,
    scene: 17,
    line: 'All right, everybody stretch out a little bit. Stretch it. Full stretch. Ryan, you wanna stretch?',
    order: 1081,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 5, scene: 17, line: 'I stretched before I came.', order: 1082, speaker: 'Ryan Howard' },
  { season: 1, episode: 5, scene: 17, line: 'OK.', order: 1083, speaker: 'Michael Scott' },
  {
    season: 1,
    episode: 5,
    scene: 18,
    line: 'OK, Ryan, you have Darryl. I have Roy.',
    order: 1084,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 5, scene: 18, line: "Really? I thought I'd take Roy.", order: 1085, speaker: 'Jim Halpert' },
  {
    season: 1,
    episode: 5,
    scene: 18,
    line:
      'Actually, I think Roy is their best player not Lonny. So, Dwight, you uh, have the East German gal. Uh, who else we got... Um...OK, all right, you guys.',
    order: 1086,
    speaker: 'Michael Scott'
  },
  {
    season: 1,
    episode: 5,
    scene: 18,
    line: "[taking off his shirt] OK, we'll be skins!",
    order: 1087,
    speaker: 'Dwight Schrute'
  },
  { season: 1, episode: 5, scene: 18, line: 'Aw, come on Dwight.', order: 1088, speaker: 'Michael Scott' },
  { season: 1, episode: 5, scene: 18, line: 'What? Shirts on or off?', order: 1089, speaker: 'Dwight Schrute' },
  { season: 1, episode: 5, scene: 18, line: 'On. Just put it on.', order: 1090, speaker: 'Michael Scott' },
  { season: 1, episode: 5, scene: 18, line: 'You sure?', order: 1091, speaker: 'Dwight Schrute' },
  {
    season: 1,
    episode: 5,
    scene: 18,
    line: "Yes. Uh, Pam? You kind of have your foot in both camps, why don't you do the uh, jump ball OK?",
    order: 1092,
    speaker: 'Michael Scott'
  },
  {
    season: 1,
    episode: 5,
    scene: 18,
    line: "Don't listen to him Pam. Trust me, tip it my way or you're sleeping in the car.",
    order: 1093,
    speaker: 'Roy Anderson'
  },
  {
    season: 1,
    episode: 5,
    scene: 18,
    line:
      "Stanley! What? You gotta be kidding me! !?! [Roy steals the ball, and goes for a lay up] Oh... Here we go! [Lonny shoots and makes it] Who's on him? Somebody get him!",
    order: 1094,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 5, scene: 18, line: 'Yeah!', order: 1095, speaker: 'Teammates' },
  { season: 1, episode: 5, scene: 18, line: "That's what I'm talking about.", order: 1096, speaker: 'Roy Anderson' },
  {
    season: 1,
    episode: 5,
    scene: 18,
    line:
      "Yeah, yeah, yeah. Over here, over here. [Jim saves the ball from going out of bounds and passes to Michael] Here we go. Three! [Shoots and misses] Let's go to the zone! We're going to zone!",
    order: 1097,
    speaker: 'Michael Scott'
  },
  {
    season: 1,
    episode: 5,
    scene: 18,
    line: 'De-fense! [clap, clap] [Michael joins in] De-fense! [clap clap]',
    order: 1098,
    speaker: 'Dwight Schrute'
  },
  {
    season: 1,
    episode: 5,
    scene: 18,
    line: 'De-fense! De-fense!',
    order: 1099,
    speaker: 'Michael Scott & Dwight Schrute'
  },
  { season: 1, episode: 5, scene: 18, line: '[Roy scores] Well done team.', order: 1100, speaker: 'Warehouse worker' },
  {
    season: 1,
    episode: 5,
    scene: 18,
    line: "Who's got Roy? [Jim does a behind the back move around Roy for the basket]",
    order: 1101,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 5, scene: 18, line: 'Woo!', order: 1102, speaker: 'Pam Beesly' },
  {
    season: 1,
    episode: 5,
    scene: 19,
    line:
      "[misses a half court shot] Aw, come on! What is wrong with me today!? Usually hit those. [Dwight scores] Dwight, I was open. All right, let's go.",
    order: 1103,
    speaker: 'Michael Scott'
  },
  {
    season: 1,
    episode: 5,
    scene: 20,
    line: "[Roy bumps Michael to get around him] OK, foul. Charging. Charging. That's a foul.",
    order: 1104,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 5, scene: 20, line: 'OK.', order: 1105, speaker: 'Roy Anderson' },
  {
    season: 1,
    episode: 5,
    scene: 20,
    line: "OK, I'll take it. [misses free throw] OK.",
    order: 1106,
    speaker: 'Michael Scott'
  },
  {
    season: 1,
    episode: 5,
    scene: 21,
    line:
      "When I am playing hoops all of the stress and responsibility of my job here just melts away. It's gone, I'm in the zone.",
    order: 1107,
    speaker: 'Michael Scott'
  },
  {
    season: 1,
    episode: 5,
    scene: 22,
    line: '[misses another shot] What is wrong with me today?!',
    order: 1108,
    speaker: 'Michael Scott'
  },
  {
    season: 1,
    episode: 5,
    scene: 23,
    line:
      "Who am I? Am I Michael Scott? I don't know... I might just be a basketball machine. What's Dunder Mifflin? I've never heard of it. Filing? Paperwork? Who cares? Possible downsizing? Um... well, that's probably gonna happen, actually.",
    order: 1109,
    speaker: 'Michael Scott'
  },
  {
    season: 1,
    episode: 5,
    scene: 24,
    line:
      "Jim! Jim! Jim, right here, Jim! Give me the ball! Ryan, cut! [Michael looks away and misses Jim's pass] Whoa!",
    order: 1110,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 5, scene: 24, line: 'My bad.', order: 1111, speaker: 'Jim Halpert' },
  {
    season: 1,
    episode: 5,
    scene: 24,
    line: '[scores] Here we go. Here we go. Here we go.',
    order: 1112,
    speaker: 'Darryl Philbin'
  },
  {
    season: 1,
    episode: 5,
    scene: 24,
    line: "[dancing] Where you at? Where you at? You over there? I'm over there.",
    order: 1113,
    speaker: 'Lonny'
  },
  {
    season: 1,
    episode: 5,
    scene: 24,
    line: 'That is cool. Is that like the Robot?',
    order: 1114,
    speaker: 'Michael Scott'
  },
  {
    season: 1,
    episode: 5,
    scene: 25,
    line: '[Ryan scores] Nice! Come here! [gives Ryan a chest bump]',
    order: 1115,
    speaker: 'Michael Scott'
  },
  {
    season: 1,
    episode: 5,
    scene: 25,
    line: "Can we just do one? That's cool, that's fine.",
    order: 1116,
    speaker: 'Ryan Howard'
  },
  {
    season: 1,
    episode: 5,
    scene: 25,
    line: 'You have one more free throw shoot. Come on.',
    order: 1117,
    speaker: 'Darryl Philbin'
  },
  { season: 1, episode: 5, scene: 25, line: "All right, let's go.", order: 1118, speaker: 'Roy Anderson' },
  { season: 1, episode: 5, scene: 26, line: 'Watch your back Madge.', order: 1119, speaker: 'Warehouse worker' },
  { season: 1, episode: 5, scene: 26, line: 'Hey! Come on man!', order: 1120, speaker: 'Madge' },
  { season: 1, episode: 5, scene: 26, line: 'Come on! Hey, Dwight. Dwight!', order: 1121, speaker: 'Michael Scott' },
  {
    season: 1,
    episode: 5,
    scene: 26,
    line: '[scores] Yeah! [points to Madge] In your face!',
    order: 1122,
    speaker: 'Dwight Schrute'
  },
  { season: 1, episode: 5, scene: 26, line: 'Yeah, like that counts.', order: 1123, speaker: 'Madge' },
  { season: 1, episode: 5, scene: 26, line: 'You know what? Dwight, Dwight...', order: 1124, speaker: 'Michael Scott' },
  {
    season: 1,
    episode: 5,
    scene: 27,
    line:
      "Football is like rock and roll, it's just bam-bam-boo... And basketball is like jazz, you know? You're kind of... Dupee-doo, dupee-do. It's all downbeat, it's in the pocket, it's like... [singing] Dupee-do, dupee-do, dapee-dah...",
    order: 1125,
    speaker: 'Michael Scott'
  },
  {
    season: 1,
    episode: 5,
    scene: 28,
    line: '[singing] Du-du-du-du-dupee-do, de-do-do-do. Du-du-du-du-dupee-do, de-do-do-do. Harlem Globetrotter...',
    order: 1126,
    speaker: 'Michael Scott'
  },
  {
    season: 1,
    episode: 5,
    scene: 28,
    line: '[steals the ball, scores, mimics singing] Du-du-du-du-dupee-do. Your ball.',
    order: 1127,
    speaker: 'Roy Anderson'
  },
  {
    season: 1,
    episode: 5,
    scene: 28,
    line: 'All right, time, time out. Come on, sales, over here. Bring it in! Come on!',
    order: 1128,
    speaker: 'Michael Scott'
  },
  {
    season: 1,
    episode: 5,
    scene: 28,
    line: "What's going on? What's going on? You're playing like a bunch of girls.",
    order: 1129,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 5, scene: 28, line: 'You know what? Let me take Roy.', order: 1130, speaker: 'Jim Halpert' },
  {
    season: 1,
    episode: 5,
    scene: 28,
    line: 'All right, switch. Take it up a notch, come on.',
    order: 1131,
    speaker: 'Michael Scott'
  },
  {
    season: 1,
    episode: 5,
    scene: 29,
    line:
      'Shoot, shoot it. [Roy hits Jim in the mouth with his elbow] Whoa, whoa, whoa, whoa! Foul! Naked aggression! Oh, that is... You all right Jim? Suck it up.',
    order: 1132,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 5, scene: 30, line: 'Block, block, block!', order: 1133, speaker: 'Darryl Philbin' },
  { season: 1, episode: 5, scene: 30, line: "He's afraid of you now.", order: 1134, speaker: 'Madge' },
  {
    season: 1,
    episode: 5,
    scene: 30,
    line: '[Jim makes a shot after pushing off Roy] Ouch! Oh, how much does it hurt? How much does it hurt?',
    order: 1135,
    speaker: 'Michael Scott'
  },
  {
    season: 1,
    episode: 5,
    scene: 31,
    line: '[Jim pushes Roy to the ground and makes another shot] Yes!',
    order: 1136,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 5, scene: 31, line: 'What the hell man?', order: 1137, speaker: 'Roy Anderson' },
  { season: 1, episode: 5, scene: 31, line: 'Take it easy.', order: 1138, speaker: 'Jim Halpert' },
  { season: 1, episode: 5, scene: 31, line: 'No, you take it easy.', order: 1139, speaker: 'Roy Anderson' },
  {
    season: 1,
    episode: 5,
    scene: 32,
    line: '[Darryl scores] Watch the long passes, you guys!',
    order: 1140,
    speaker: 'Michael Scott'
  },
  {
    season: 1,
    episode: 5,
    scene: 33,
    line: '[Dwight steals the ball from Ryan] Same team, Dwight.',
    order: 1141,
    speaker: 'Ryan Howard'
  },
  { season: 1, episode: 5, scene: 33, line: 'Dwight!', order: 1142, speaker: 'Michael Scott' },
  { season: 1, episode: 5, scene: 33, line: '[scores] Yes!', order: 1143, speaker: 'Dwight Schrute' },
  {
    season: 1,
    episode: 5,
    scene: 34,
    line: "[Phyllis scores] Yeah! Yeah! Yeah! In, your, face! Angela, what's the score?",
    order: 1144,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 5, scene: 34, line: "You're ahead.", order: 1145, speaker: 'Angela Martin' },
  { season: 1, episode: 5, scene: 34, line: 'Yeah, baby, here we go!', order: 1146, speaker: 'Michael Scott' },
  {
    season: 1,
    episode: 5,
    scene: 34,
    line: '[Jim has the ball] Jim! Jim! Right here! [runs into the elbow of the guy guarding him] Ow! God! Hold it!',
    order: 1147,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 5, scene: 34, line: "I'm sorry.", order: 1148, speaker: 'Worker' },
  { season: 1, episode: 5, scene: 34, line: 'Foul! Foul!', order: 1149, speaker: 'Michael Scott' },
  { season: 1, episode: 5, scene: 34, line: "I'm sorry. You all right?", order: 1150, speaker: 'Worker' },
  { season: 1, episode: 5, scene: 34, line: 'Oh, that hurts.', order: 1151, speaker: 'Michael Scott' },
  { season: 1, episode: 5, scene: 34, line: "Sorry, I didn't mean to do that.", order: 1152, speaker: 'Worker' },
  {
    season: 1,
    episode: 5,
    scene: 34,
    line: "What's your problem man? Gah, just clocking me for no reason?",
    order: 1153,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 5, scene: 34, line: 'Take your shot man!', order: 1154, speaker: 'Darryl Philbin' },
  {
    season: 1,
    episode: 5,
    scene: 34,
    line: 'No, no, no, no. That was a flagrant, personal, intentional foul. Right there.',
    order: 1155,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 5, scene: 34, line: "No it wasn't.", order: 1156, speaker: 'Worker' },
  {
    season: 1,
    episode: 5,
    scene: 34,
    line: "[mocking voice] Yes, it was. You know what, I'm just being fair.",
    order: 1157,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 5, scene: 34, line: 'Oh, really? No, I just put my arm up...', order: 1158, speaker: 'Worker' },
  {
    season: 1,
    episode: 5,
    scene: 34,
    line:
      "Game over. Game over. That is it! I'm sorry, you know? I hate to do it this way but, you know, that's just... we're having a friendly game. It's a shame. This is a damn shame, but we're like a family here and that just, that won't fly.",
    order: 1159,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 5, scene: 34, line: 'This is a cold pack...', order: 1160, speaker: 'Angela Martin' },
  {
    season: 1,
    episode: 5,
    scene: 34,
    line: 'Here, give me that. You have to break the interior bag. [bag explodes]',
    order: 1161,
    speaker: 'Dwight Schrute'
  },
  { season: 1, episode: 5, scene: 34, line: 'Thanks Dwight.', order: 1162, speaker: 'Michael Scott' },
  {
    season: 1,
    episode: 5,
    scene: 34,
    line: "Wait, what does that mean? What is it, a tie? What's going on?",
    order: 1163,
    speaker: 'Lonny'
  },
  {
    season: 1,
    episode: 5,
    scene: 34,
    line: "Well, let's just say whoever was ahead won.",
    order: 1164,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 5, scene: 34, line: 'That was you.', order: 1165, speaker: 'Darryl Philbin' },
  {
    season: 1,
    episode: 5,
    scene: 34,
    line:
      "It was us? Really? I didn't, I didn't know. Great, I mean, I guess you guys are working Saturday. Your face.",
    order: 1166,
    speaker: 'Michael Scott'
  },
  {
    season: 1,
    episode: 5,
    scene: 34,
    line: "No, no, no, I'm not coming in on Saturday.",
    order: 1167,
    speaker: 'Roy Anderson'
  },
  { season: 1, episode: 5, scene: 34, line: "Yeah, this isn't happening.", order: 1168, speaker: 'Darryl Philbin' },
  {
    season: 1,
    episode: 5,
    scene: 34,
    line: "Um... well, you guys, you know, I'm the boss so...",
    order: 1169,
    speaker: 'Michael Scott'
  },
  {
    season: 1,
    episode: 5,
    scene: 34,
    line: "So what's that? We're coming in on Monday, right?",
    order: 1170,
    speaker: 'Lonny'
  },
  { season: 1, episode: 5, scene: 34, line: 'Hey, hey...', order: 1171, speaker: 'Michael Scott' },
  { season: 1, episode: 5, scene: 34, line: 'Monday?', order: 1172, speaker: 'Lonny' },
  {
    season: 1,
    episode: 5,
    scene: 34,
    line:
      "[laughing] You guys believed me? Come on. Dogs, you know, you should know me better than that. No, oh, do you think that would've been good for morale? No. No. No. Exactly, no. I'm embarrassed it was even that close though. So... nah, of course, we're coming in Saturday. Good game. Word.",
    order: 1173,
    speaker: 'Michael Scott'
  },
  {
    season: 1,
    episode: 5,
    scene: 35,
    line: '[to Pam] ...so I talked to the scout, it looks good.',
    order: 1174,
    speaker: 'Jim Halpert'
  },
  { season: 1, episode: 5, scene: 35, line: 'Mmm-hmm.', order: 1175, speaker: 'Pam Beesly' },
  { season: 1, episode: 5, scene: 35, line: "I didn't sign anything.", order: 1176, speaker: 'Jim Halpert' },
  { season: 1, episode: 5, scene: 35, line: 'Hey baby.', order: 1177, speaker: 'Roy Anderson' },
  { season: 1, episode: 5, scene: 35, line: 'Hey.', order: 1178, speaker: 'Pam Beesly' },
  {
    season: 1,
    episode: 5,
    scene: 35,
    line: '[to Jim] Look at Larry Bird. Larry Legend.',
    order: 1179,
    speaker: 'Roy Anderson'
  },
  {
    season: 1,
    episode: 5,
    scene: 35,
    line: "Yeah, he's, uh, pretty good, huh? [to Roy] Let's get you into a tub.",
    order: 1180,
    speaker: 'Pam Beesly'
  },
  { season: 1, episode: 5, scene: 35, line: "Yeah? Let's get you into a tub.", order: 1181, speaker: 'Roy Anderson' },
  {
    season: 1,
    episode: 5,
    scene: 36,
    line: 'Hey, what a game, huh? What a game.',
    order: 1182,
    speaker: 'Michael Scott'
  },
  {
    season: 1,
    episode: 5,
    scene: 36,
    line: 'What time do we have to come in?',
    order: 1183,
    speaker: 'Oscar Martinez'
  },
  {
    season: 1,
    episode: 5,
    scene: 36,
    line:
      "Come on. Let's not be gloomy here man. We're all in this together. We're a team. You know what? Screw corporate, nobody's coming in tomorrow. You have the day off. Like coming in an extra day is gonna prevent us from being downsized. Have a good weekend.",
    order: 1184,
    speaker: 'Michael Scott'
  },
  {
    season: 1,
    episode: 5,
    scene: 37,
    line:
      "The great thing about sports is that it is all about character. And you can learn lessons about life even if you don't win. But we did because we were ahead.",
    order: 1185,
    speaker: 'Michael Scott'
  },
  {
    season: 1,
    episode: 5,
    scene: 40,
    line:
      "Do I have a nickname on the court, um? Well, The Answer would be nice, but it's taken. So, uh, probably The Question. The Answer dishes to The Question. The Question back to The Answer. Answer over to The Question. The Answer, whew, [makes shooting motion] three points. The Question, whew, whew, [makes shooting motion] six points. Nothing but net. Question, who's the best player in the league? Answer, The Question. Or the Drunkmeister.",
    order: 1186,
    speaker: 'Michael Scott'
  },
  {
    season: 1,
    episode: 5,
    scene: 49,
    line: "[back to the basketball game] Let's go Lonny.",
    order: 1187,
    speaker: 'Warehouse Worker'
  },
  {
    season: 1,
    episode: 5,
    scene: 49,
    line: '[Michael steals the ball] Yeah, Michael. Go Michael. [makes shot]',
    order: 1188,
    speaker: 'Jim Halpert'
  },
  { season: 1, episode: 5, scene: 49, line: 'Yes!', order: 1189, speaker: 'Dwight Schrute' },
  {
    season: 1,
    episode: 5,
    scene: 50,
    line:
      "Birdie. He's not the guy with the cheeks. Kenny G. Is... if you knew jazz, you'd know who I mean. Kenny G. God. Glad I remembered that. Jazz people know who he is.",
    order: 1190,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 6, scene: 1, line: 'Are you listening to me Michael?', order: 1191, speaker: 'Jan Levinson' },
  { season: 1, episode: 6, scene: 1, line: 'Affirmative.', order: 1192, speaker: 'Michael Scott' },
  { season: 1, episode: 6, scene: 1, line: 'What did I just say?', order: 1193, speaker: 'Jan Levinson' },
  {
    season: 1,
    episode: 6,
    scene: 1,
    line: 'You just said, let me uh... check my notes. You just said...',
    order: 1194,
    speaker: 'Michael Scott'
  },
  {
    season: 1,
    episode: 6,
    scene: 1,
    line: 'Alan and I have created an incentive program to increase sales.',
    order: 1195,
    speaker: 'Jan Levinson'
  },
  {
    season: 1,
    episode: 6,
    scene: 1,
    line: 'Hey, hey how is Alan? Tell Alan that the Mets suck! Okay? From me, big time. Go Pirates!',
    order: 1196,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 6, scene: 1, line: "I'm not going to do that Michael.", order: 1197, speaker: 'Jan Levinson' },
  { season: 1, episode: 6, scene: 1, line: 'Okay', order: 1198, speaker: 'Michael Scott' },
  {
    season: 1,
    episode: 6,
    scene: 1,
    line: "We've created an incentive program to increase sales.",
    order: 1199,
    speaker: 'Jan Levinson'
  },
  { season: 1, episode: 6, scene: 1, line: 'Uh, huh.', order: 1200, speaker: 'Michael Scott' },
  {
    season: 1,
    episode: 6,
    scene: 1,
    line: 'At the end of the month you can reward your top seller with a prize worth up to a thousand dollars.',
    order: 1201,
    speaker: 'Jan Levinson'
  },
  {
    season: 1,
    episode: 6,
    scene: 1,
    line: "Whoa. Howdy-ho. Wow, a thousand big ones. That's cool. Do I uh, do I get to pick the prize?",
    order: 1202,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 6, scene: 1, line: 'Uh, yes. Yes you can.', order: 1203, speaker: 'Jan Levinson' },
  {
    season: 1,
    episode: 6,
    scene: 1,
    line:
      "Um, question: Does top salesman include uh, people who were at one time such outstanding salesman that've been promoted to...",
    order: 1204,
    speaker: 'Michael Scott'
  },
  {
    season: 1,
    episode: 6,
    scene: 1,
    line: "No, Michael. No. You can't win this prize.",
    order: 1205,
    speaker: 'Jan Levinson'
  },
  { season: 1, episode: 6, scene: 1, line: "I didn't mean me!", order: 1206, speaker: 'Michael Scott' },
  {
    season: 1,
    episode: 6,
    scene: 2,
    line: 'Well, first what we have to do is find out what motivates people more than anything else.',
    order: 1207,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 6, scene: 2, line: 'Sex.', order: 1208, speaker: 'Dwight Schrute' },
  {
    season: 1,
    episode: 6,
    scene: 2,
    line: "It's illegal. Can't do that. Next best thing.",
    order: 1209,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 6, scene: 2, line: 'Torture.', order: 1210, speaker: 'Dwight Schrute' },
  {
    season: 1,
    episode: 6,
    scene: 2,
    line: "Tah, come on Dwight. Just help me out here. That's just stupid.",
    order: 1211,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 6, scene: 2, line: 'Uh, Michael?', order: 1212, speaker: 'Pam Beesly' },
  { season: 1, episode: 6, scene: 2, line: 'Pam!', order: 1213, speaker: 'Michael Scott' },
  { season: 1, episode: 6, scene: 2, line: "Hey, there's a...", order: 1214, speaker: 'Pam Beesly' },
  { season: 1, episode: 6, scene: 2, line: 'Burger with cheese!', order: 1215, speaker: 'Michael Scott' },
  { season: 1, episode: 6, scene: 2, line: "There's a person here...", order: 1216, speaker: 'Pam Beesly' },
  { season: 1, episode: 6, scene: 2, line: 'And fries!', order: 1217, speaker: 'Michael Scott' },
  { season: 1, episode: 6, scene: 2, line: "There's...", order: 1218, speaker: 'Pam Beesly' },
  { season: 1, episode: 6, scene: 2, line: 'And shake! What? Go ahead.', order: 1219, speaker: 'Michael Scott' },
  {
    season: 1,
    episode: 6,
    scene: 2,
    line: "There's a person here who wants to sell handbags.",
    order: 1220,
    speaker: 'Pam Beesly'
  },
  {
    season: 1,
    episode: 6,
    scene: 2,
    line: 'No, no, no. No vendors in the office. That is a distraction.',
    order: 1221,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 6, scene: 2, line: "Okay, I told her you'd talk to her.", order: 1222, speaker: 'Pam Beesly' },
  {
    season: 1,
    episode: 6,
    scene: 2,
    line: "Pam. Pam. Come on, I'm busy. So just tell her to go away.",
    order: 1223,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 6, scene: 2, line: 'Okay.', order: 1224, speaker: 'Pam Beesly' },
  {
    season: 1,
    episode: 6,
    scene: 2,
    line: "[exhales loudly, looks out window and sees Katy] Oooh, alright I'll talk to her.",
    order: 1225,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 6, scene: 3, line: 'This one is hand embroidered.', order: 1226, speaker: 'Katy Moore' },
  {
    season: 1,
    episode: 6,
    scene: 3,
    line: "All right girls break it up, you're being infiltrated. Cock in the henhouse.",
    order: 1227,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 6, scene: 3, line: 'Cocks in the henhouse.', order: 1228, speaker: 'Dwight Schrute' },
  {
    season: 1,
    episode: 6,
    scene: 3,
    line: "Don't say cocks. Oh, what is your name, my fair lass?",
    order: 1229,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 6, scene: 3, line: 'Katy.', order: 1230, speaker: 'Katy Moore' },
  {
    season: 1,
    episode: 6,
    scene: 3,
    line: "Ah, Katy. Wow. Look at you. You are, uh you're like the new and improved Pam. Pam 6.0.",
    order: 1231,
    speaker: 'Michael Scott'
  },
  {
    season: 1,
    episode: 6,
    scene: 3,
    line: "Oh, look. Oh hey, no catfights you two. I'm against violence in the workplace.",
    order: 1232,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 6, scene: 3, line: 'So am I.', order: 1233, speaker: 'Dwight Schrute' },
  { season: 1, episode: 6, scene: 3, line: 'Nobody cares what you think.', order: 1234, speaker: 'Michael Scott' },
  { season: 1, episode: 6, scene: 3, line: "Doesn't matter.", order: 1235, speaker: 'Dwight Schrute' },
  {
    season: 1,
    episode: 6,
    scene: 3,
    line:
      "So uh, you know what? I usually don't allow solicitors in the office but today I am going to break some rules, and you can have the conference room. It's yours. All day.",
    order: 1236,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 6, scene: 3, line: 'Wow, thanks.', order: 1237, speaker: 'Katy Moore' },
  {
    season: 1,
    episode: 6,
    scene: 3,
    line: "There's an HR meeting in there at 11:30.",
    order: 1238,
    speaker: 'Pam Beesly'
  },
  {
    season: 1,
    episode: 6,
    scene: 3,
    line:
      "Well, lets put 'em in the hallway. Give 'em some chairs. Right? Decisiveness. One of the keys to success according to Small Businessman.",
    order: 1239,
    speaker: 'Michael Scott'
  },
  {
    season: 1,
    episode: 6,
    scene: 4,
    line:
      "I do. I read Small Business man. I also uh, subscribe to USA Today and American Way Magazine, that's the in-flight magazine. Some great articles in that. They did this great profile last month of Doris Roberts and where she likes to eat when she's in Phoenix. Illuminating.",
    order: 1240,
    speaker: 'Michael Scott'
  },
  {
    season: 1,
    episode: 6,
    scene: 5,
    line:
      "This is my conference room. So please, uh, make yourself at home. Whatever you need, I'm right on the other side of this wall. [knocks on wall] used to be a window here. There's not anymore. So, that's where I will be.",
    order: 1241,
    speaker: 'Michael Scott'
  },
  {
    season: 1,
    episode: 6,
    scene: 5,
    line:
      "So if you need anything else, something to make you more confortable just don't hesitate to ask. I'm right here.",
    order: 1242,
    speaker: 'Michael Scott'
  },
  {
    season: 1,
    episode: 6,
    scene: 5,
    line: 'I guess a cup of coffee would be great.',
    order: 1243,
    speaker: 'Katy Moore'
  },
  {
    season: 1,
    episode: 6,
    scene: 5,
    line: "Wait a second. I should have spotted another addict. Uh, gotta love the 'bucks.",
    order: 1244,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 6, scene: 5, line: 'What?', order: 1245, speaker: 'Katy Moore' },
  {
    season: 1,
    episode: 6,
    scene: 5,
    line:
      "It's like a slang for Starbucks. They're all over the place. Oh, man, that place is like the promised land to me. What a business model too. Ah, too bad we don't have the good stuff here.",
    order: 1246,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 6, scene: 5, line: 'Regular coffee is fine.', order: 1247, speaker: 'Katy Moore' },
  { season: 1, episode: 6, scene: 5, line: "Nah, it's not. it's spppplllibbb", order: 1248, speaker: 'Michael Scott' },
  { season: 1, episode: 6, scene: 5, line: 'No really it is.', order: 1249, speaker: 'Katy Moore' },
  {
    season: 1,
    episode: 6,
    scene: 5,
    line:
      "No, here's the thing. Y'know I do my best to be my own man and go by the beat of a different drummer and nobody gets me, and they're always putting up walls and I'm always tearing 'em down, just breakin' down barriers, that's what I do all day. So a coffee, regular coffee for you. High test, or unleaded?",
    order: 1250,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 6, scene: 5, line: 'Bring it on.', order: 1251, speaker: 'Katy Moore' },
  {
    season: 1,
    episode: 6,
    scene: 5,
    line: 'Oh. Woo, I will. I will bring it on. Ah, all right.',
    order: 1252,
    speaker: 'Michael Scott'
  },
  {
    season: 1,
    episode: 6,
    scene: 6,
    line: "So are you jealous 'cause there's another girl around?",
    order: 1253,
    speaker: 'Kevin Malone'
  },
  { season: 1, episode: 6, scene: 6, line: 'No.', order: 1254, speaker: 'Pam Beesly' },
  { season: 1, episode: 6, scene: 6, line: "She's prettier than you though.", order: 1255, speaker: 'Kevin Malone' },
  {
    season: 1,
    episode: 6,
    scene: 6,
    line: "That's a very rude thing to say, Kevin.",
    order: 1256,
    speaker: 'Pam Beesly'
  },
  { season: 1, episode: 6, scene: 6, line: '[nods]', order: 1257, speaker: 'Kevin Malone' },
  {
    season: 1,
    episode: 6,
    scene: 7,
    line: 'So do you like the periwinkle and the purples?',
    order: 1258,
    speaker: 'Katy Moore'
  },
  {
    season: 1,
    episode: 6,
    scene: 8,
    line:
      'The purse girl hits everything on my checklist. Creamy skin. Straight teeth. Curly hair. Amazing breasts. Not for me, for my children. The Schrutes produce very thirsty babies.',
    order: 1259,
    speaker: 'Dwight Schrute'
  },
  {
    season: 1,
    episode: 6,
    scene: 9,
    line: "[handing Katy a mug of coffee] There ya go. Nice steaming cup o'joe.",
    order: 1260,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 6, scene: 9, line: 'Thank you.', order: 1261, speaker: 'Katy Moore' },
  {
    season: 1,
    episode: 6,
    scene: 9,
    line:
      "I have an idea. Why don't I introduce you around, you know you can kind of get your foot in the door, meet potential clientele, right?",
    order: 1262,
    speaker: 'Michael Scott'
  },
  {
    season: 1,
    episode: 6,
    scene: 9,
    line: 'Gosh, I would love to but, my purses, I should, um...',
    order: 1263,
    speaker: 'Katy Moore'
  },
  {
    season: 1,
    episode: 6,
    scene: 9,
    line: 'Oh, um, well, we could have Ryan take a look. Ryan, would you look after the purses, please?',
    order: 1264,
    speaker: 'Michael Scott'
  },
  {
    season: 1,
    episode: 6,
    scene: 9,
    line: "I'm installing File Share on all the computers.",
    order: 1265,
    speaker: 'Ryan Howard'
  },
  {
    season: 1,
    episode: 6,
    scene: 9,
    line: 'Yeah, well, bladdy-bluda-blah-blah. Techno-babble. Just do it, okay. We have company. Right?',
    order: 1266,
    speaker: 'Michael Scott'
  },
  {
    season: 1,
    episode: 6,
    scene: 10,
    line:
      "You should sell a lot here because this branch made over a million dollars last year. Not that we're all millionaires. I'm probably closest. So here's Oscar. Oscar, this is Katy.",
    order: 1267,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 6, scene: 10, line: "I'm on the phone.", order: 1268, speaker: 'Oscar Martinez' },
  {
    season: 1,
    episode: 6,
    scene: 10,
    line: 'Oooh-ooh. Oscar the grouch. Right? I thought of that.',
    order: 1269,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 6, scene: 10, line: 'That was on Sesame Street.', order: 1270, speaker: 'Katy Moore' },
  {
    season: 1,
    episode: 6,
    scene: 10,
    line: "I know. I know. I made the connection. Can you believe he'd never heard that before he worked here?",
    order: 1271,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 6, scene: 10, line: "No, I don't believe that.", order: 1272, speaker: 'Katy Moore' },
  { season: 1, episode: 6, scene: 10, line: "I know, it's unbelievable.", order: 1273, speaker: 'Michael Scott' },
  {
    season: 1,
    episode: 6,
    scene: 11,
    line: "It's nice having Katy around. It's another person for Michael to um, interact with.",
    order: 1274,
    speaker: 'Pam Beesly'
  },
  {
    season: 1,
    episode: 6,
    scene: 12,
    line: 'Here is Toby from Human Resources. Katy, Toby.',
    order: 1275,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 6, scene: 12, line: 'Hi', order: 1276, speaker: 'Katy Moore' },
  { season: 1, episode: 6, scene: 12, line: 'Hi, nice to meet you.', order: 1277, speaker: 'Toby Flenderson' },
  { season: 1, episode: 6, scene: 12, line: 'Toby, Katy.', order: 1278, speaker: 'Michael Scott' },
  {
    season: 1,
    episode: 6,
    scene: 12,
    line: "Hey, um did you go to uh, Bishop O'Hara?",
    order: 1279,
    speaker: 'Toby Flenderson'
  },
  { season: 1, episode: 6, scene: 12, line: 'Yeah.', order: 1280, speaker: 'Katy Moore' },
  { season: 1, episode: 6, scene: 12, line: 'Yeah, me too.', order: 1281, speaker: 'Toby Flenderson' },
  { season: 1, episode: 6, scene: 12, line: 'Cool. What year were you there?', order: 1282, speaker: 'Katy Moore' },
  { season: 1, episode: 6, scene: 12, line: 'Eighty-nine.', order: 1283, speaker: 'Toby Flenderson' },
  {
    season: 1,
    episode: 6,
    scene: 12,
    line: "Toby's divorced. He uh, guh recently, right?",
    order: 1284,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 6, scene: 12, line: 'Yeah.', order: 1285, speaker: 'Toby Flenderson' },
  {
    season: 1,
    episode: 6,
    scene: 12,
    line: 'You and your wife, and you have kids.',
    order: 1286,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 6, scene: 12, line: 'A girl.', order: 1287, speaker: 'Toby Flenderson' },
  {
    season: 1,
    episode: 6,
    scene: 12,
    line: 'Oh that so - that was really messy. He slept one night in your car too?',
    order: 1288,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 6, scene: 12, line: '[looks resigned]', order: 1289, speaker: 'Toby Flenderson' },
  {
    season: 1,
    episode: 6,
    scene: 12,
    line: 'I should probably get back to my table.',
    order: 1290,
    speaker: 'Katy Moore'
  },
  {
    season: 1,
    episode: 6,
    scene: 12,
    line:
      "Okay. Alright. Cool. See ya in a bit. [looks at picture on Toby's desk] Oh, she's cute. Cutie-pie. Back to work.",
    order: 1291,
    speaker: 'Michael Scott'
  },
  {
    season: 1,
    episode: 6,
    scene: 13,
    line:
      'I live by one rule. No office romances. No way. Very messy. Inappropriate. No. But, I live by another rule: Just do it. Nike.',
    order: 1292,
    speaker: 'Michael Scott'
  },
  {
    season: 1,
    episode: 6,
    scene: 14,
    line: 'Hey, Jimmy what do you think of that little purse girl, huh?',
    order: 1293,
    speaker: 'Roy Anderson'
  },
  { season: 1, episode: 6, scene: 14, line: 'Cute, sure, yeah.', order: 1294, speaker: 'Jim Halpert' },
  { season: 1, episode: 6, scene: 14, line: "Why don't you get on that?", order: 1295, speaker: 'Roy Anderson' },
  { season: 1, episode: 6, scene: 14, line: "She's not really my type.", order: 1296, speaker: 'Jim Halpert' },
  { season: 1, episode: 6, scene: 14, line: 'What are you gay?', order: 1297, speaker: 'Roy Anderson' },
  { season: 1, episode: 6, scene: 14, line: "Hmmm, I don't think so. Nope.", order: 1298, speaker: 'Jim Halpert' },
  { season: 1, episode: 6, scene: 14, line: 'What is your type?', order: 1299, speaker: 'Kevin Malone' },
  {
    season: 1,
    episode: 6,
    scene: 14,
    line: '[glances at Pam] Moms, primarily. Yep. Soccer moms. Single moms. NASCAR moms. Any type of moms, really.',
    order: 1300,
    speaker: 'Jim Halpert'
  },
  { season: 1, episode: 6, scene: 14, line: "That's disgusting.", order: 1301, speaker: 'Roy Anderson' },
  { season: 1, episode: 6, scene: 14, line: 'Stay away from my mom.', order: 1302, speaker: 'Kevin Malone' },
  { season: 1, episode: 6, scene: 14, line: 'Too late, Kev.', order: 1303, speaker: 'Jim Halpert' },
  {
    season: 1,
    episode: 6,
    scene: 14,
    line: "[Katy walks through breakroom] Man, I would be all over that if I wasn't dating Pam.",
    order: 1304,
    speaker: 'Roy Anderson'
  },
  { season: 1, episode: 6, scene: 14, line: "We're not dating, we're engaged.", order: 1305, speaker: 'Pam Beesly' },
  { season: 1, episode: 6, scene: 14, line: 'Engaged, yeah.', order: 1306, speaker: 'Roy Anderson' },
  {
    season: 1,
    episode: 6,
    scene: 15,
    line:
      "Pam and I are good buddies. I'm sort of Pam's go-to guy for her problems. You know with stuff like work, or uh, her fiance Roy. Or uh... Nope, those are pretty much her only two problems.",
    order: 1307,
    speaker: 'Jim Halpert'
  },
  { season: 1, episode: 6, scene: 16, line: "She'd be perfect for you.", order: 1308, speaker: 'Jim Halpert' },
  {
    season: 1,
    episode: 6,
    scene: 16,
    line: "Hmmm... she's been talking to Michael a lot.",
    order: 1309,
    speaker: 'Dwight Schrute'
  },
  {
    season: 1,
    episode: 6,
    scene: 16,
    line: "So, what? You're Assistant Regional Manager.",
    order: 1310,
    speaker: 'Jim Halpert'
  },
  {
    season: 1,
    episode: 6,
    scene: 16,
    line: 'Assistant to the Regional Manager.',
    order: 1311,
    speaker: 'Dwight Schrute'
  },
  {
    season: 1,
    episode: 6,
    scene: 16,
    line: "Well, you know what Dwight? He's your work boss, okay? He is not your relationship boss.",
    order: 1312,
    speaker: 'Jim Halpert'
  },
  { season: 1, episode: 6, scene: 16, line: "That's true.", order: 1313, speaker: 'Dwight Schrute' },
  {
    season: 1,
    episode: 6,
    scene: 16,
    line:
      "Plus you have so much more to talk to this girl about, You're both um, salesmen. I mean that's something right there.",
    order: 1314,
    speaker: 'Jim Halpert'
  },
  {
    season: 1,
    episode: 6,
    scene: 16,
    line: 'True. Plus I can talk to her about the origins of my last name.',
    order: 1315,
    speaker: 'Dwight Schrute'
  },
  { season: 1, episode: 6, scene: 16, line: "It's all gold.", order: 1316, speaker: 'Jim Halpert' },
  {
    season: 1,
    episode: 6,
    scene: 17,
    line:
      "Guys are usually my best customers, they buy the high end stuff like the beads and the sequins and stuff. For gifts, you know? They don't know what they are looking at. So I make suggestions.",
    order: 1317,
    speaker: 'Katy Moore'
  },
  {
    season: 1,
    episode: 6,
    scene: 18,
    line:
      "Alright. Here's the thing okay, you just keep talking to her. If you hit a stall you have a perfect fall back.",
    order: 1318,
    speaker: 'Jim Halpert'
  },
  { season: 1, episode: 6, scene: 18, line: "What's that?", order: 1319, speaker: 'Dwight Schrute' },
  { season: 1, episode: 6, scene: 18, line: 'You buy a purse.', order: 1320, speaker: 'Jim Halpert' },
  {
    season: 1,
    episode: 6,
    scene: 18,
    line: "I don't want a purse. Purses are for girls.",
    order: 1321,
    speaker: 'Dwight Schrute'
  },
  {
    season: 1,
    episode: 6,
    scene: 18,
    line: "Dwight, that's not necessarily true. Do you read GQ?",
    order: 1322,
    speaker: 'Jim Halpert'
  },
  { season: 1, episode: 6, scene: 18, line: 'No.', order: 1323, speaker: 'Dwight Schrute' },
  {
    season: 1,
    episode: 6,
    scene: 18,
    line: 'Okay, I do. There like mini briefcases, alright? Lots of guys have them.',
    order: 1324,
    speaker: 'Jim Halpert'
  },
  { season: 1, episode: 6, scene: 18, line: 'Like those?', order: 1325, speaker: 'Dwight Schrute' },
  {
    season: 1,
    episode: 6,
    scene: 18,
    line: 'Yes. Listen, you are spending way too much time talking to me, when you could be talking to her.',
    order: 1326,
    speaker: 'Jim Halpert'
  },
  {
    season: 1,
    episode: 6,
    scene: 18,
    line: "Okay, I'm just going to use the bathroom, and then I'm going...",
    order: 1327,
    speaker: 'Dwight Schrute'
  },
  {
    season: 1,
    episode: 6,
    scene: 18,
    line: "No. You don't need the bathroom. You've got it. Go.",
    order: 1328,
    speaker: 'Jim Halpert'
  },
  {
    season: 1,
    episode: 6,
    scene: 19,
    line: "Okay, shhhh stop... stop whatever you're doing because this is going to be good.",
    order: 1329,
    speaker: 'Jim Halpert'
  },
  { season: 1, episode: 6, scene: 19, line: '[smiles]', order: 1330, speaker: 'Pam Beesly' },
  {
    season: 1,
    episode: 6,
    scene: 19,
    line:
      "[mimicing Dwight in high-falsetto voice] Hi my name's Dwight Schrute and I would like to buy a purse from you. Good lord, look at these purses! This is something special. Oh my God is this Salvatore Di-chini-asta?",
    order: 1331,
    speaker: 'Jim Halpert'
  },
  {
    season: 1,
    episode: 6,
    scene: 19,
    line: '[mimicing Katy] Oh definitely, definitely step in and out of it like that.',
    order: 1332,
    speaker: 'Pam Beesly'
  },
  {
    season: 1,
    episode: 6,
    scene: 19,
    line: 'Yes, well I want to stress test it. You know, in case anything happens.',
    order: 1333,
    speaker: 'Jim Halpert'
  },
  { season: 1, episode: 6, scene: 19, line: 'Oh!', order: 1334, speaker: 'Pam Beesly' },
  {
    season: 1,
    episode: 6,
    scene: 19,
    line:
      'Oh! That was really. [Dwight hits purse against table] This is necessary to do to really give it a good workout. This is the ooooh... This is the prettiest one of all.',
    order: 1335,
    speaker: 'Jim Halpert'
  },
  { season: 1, episode: 6, scene: 19, line: 'Oh...', order: 1336, speaker: 'Pam Beesly' },
  {
    season: 1,
    episode: 6,
    scene: 19,
    line: "I'm going to be the prettiest girl in the ball. Oh, how much?",
    order: 1337,
    speaker: 'Jim Halpert'
  },
  { season: 1, episode: 6, scene: 19, line: "Oh, God. It's sad. It's so sad.", order: 1338, speaker: 'Pam Beesly' },
  {
    season: 1,
    episode: 6,
    scene: 19,
    line: '[whispering] Here he comes, shhh...',
    order: 1339,
    speaker: 'Jim Halpert'
  },
  {
    season: 1,
    episode: 6,
    scene: 19,
    line: '[gives Dwight a thumbs-up - mouths the word] Good.',
    order: 1340,
    speaker: 'Jim Halpert'
  },
  { season: 1, episode: 6, scene: 19, line: '[smiles in agreement]', order: 1341, speaker: 'Pam Beesly' },
  { season: 1, episode: 6, scene: 19, line: 'He did pick a good one.', order: 1342, speaker: 'Jim Halpert' },
  { season: 1, episode: 6, scene: 19, line: "You're horrible.", order: 1343, speaker: 'Pam Beesly' },
  {
    season: 1,
    episode: 6,
    scene: 20,
    line: "This one's really good for a hot date.",
    order: 1344,
    speaker: 'Katy Moore'
  },
  { season: 1, episode: 6, scene: 20, line: "Yeah, what's that?", order: 1345, speaker: 'Pam Beesly' },
  { season: 1, episode: 6, scene: 20, line: '[laughs]', order: 1346, speaker: 'Katy Moore' },
  { season: 1, episode: 6, scene: 20, line: "I'm engaged. So...", order: 1347, speaker: 'Pam Beesly' },
  {
    season: 1,
    episode: 6,
    scene: 20,
    line: 'Congratulations. You need a hot date more than anyone.',
    order: 1348,
    speaker: 'Katy Moore'
  },
  { season: 1, episode: 6, scene: 20, line: 'I wished, right?', order: 1349, speaker: 'Pam Beesly' },
  {
    season: 1,
    episode: 6,
    scene: 20,
    line:
      "Giggle-giggle, juji-juji, I get it, I get it. Divine Secrets of the Ya Ya Sisterhood over here right? [to Katy] So how's that uh, coffee from earlier?",
    order: 1350,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 6, scene: 20, line: 'Good.', order: 1351, speaker: 'Katy Moore' },
  {
    season: 1,
    episode: 6,
    scene: 20,
    line:
      'Ah, I knew it. Guzzled it down. You greedy little thing. So, uh, Pam is this your lunch break, or was that earlier when you were eating in the kitchen with those guys?',
    order: 1352,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 6, scene: 20, line: '[whispers] Sorry.', order: 1353, speaker: 'Katy Moore' },
  { season: 1, episode: 6, scene: 20, line: 'Busted.', order: 1354, speaker: 'Michael Scott' },
  { season: 1, episode: 6, scene: 20, line: '[to Pam] Come back...', order: 1355, speaker: 'Katy Moore' },
  {
    season: 1,
    episode: 6,
    scene: 20,
    line:
      'Oh hey, I want to show you something. Come here I want to show you something. I know you are going to like this. Picked it up today. A thousand big ones.',
    order: 1356,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 6, scene: 20, line: 'Is that from Starbucks?', order: 1357, speaker: 'Katy Moore' },
  {
    season: 1,
    episode: 6,
    scene: 20,
    line: 'Yes. This is a Starbucks digital barista. This is the mack daddy of espresso makers.',
    order: 1358,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 6, scene: 20, line: 'Wow. Is that for the office?', order: 1359, speaker: 'Katy Moore' },
  {
    season: 1,
    episode: 6,
    scene: 20,
    line:
      "Oh, I know what you're thinking. You're not prying this out of my hands, but don't tempt me because I'll give it to you!",
    order: 1360,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 6, scene: 20, line: "I wouldn't think of it.", order: 1361, speaker: 'Katy Moore' },
  {
    season: 1,
    episode: 6,
    scene: 21,
    line:
      "Coffee is the great incentivizer in the office. It's a drug. It is quite literally a drug that speeds people up. It's not the only drug that speeds people up. You hear stories about Dunder Mifflin in the eighties before everybody knew how bad cocaine was. Guh. Man, did they move paper!",
    order: 1362,
    speaker: 'Michael Scott'
  },
  {
    season: 1,
    episode: 6,
    scene: 22,
    line:
      "[Katy reading text message on her phone] Oh the rotating um, steam wand. [Katy looks annoyed] What? What's the matter?",
    order: 1363,
    speaker: 'Michael Scott'
  },
  {
    season: 1,
    episode: 6,
    scene: 22,
    line: 'Oh, nothing. My ride just bailed on me.',
    order: 1364,
    speaker: 'Katy Moore'
  },
  {
    season: 1,
    episode: 6,
    scene: 22,
    line: "Oh, oh! God. I'm sorry. Is there...?",
    order: 1365,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 6, scene: 22, line: "Oh no, it's um...", order: 1366, speaker: 'Katy Moore' },
  {
    season: 1,
    episode: 6,
    scene: 22,
    line: 'Where you going? Nearby? Because I can give you a ride.',
    order: 1367,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 6, scene: 22, line: 'No...', order: 1368, speaker: 'Katy Moore' },
  { season: 1, episode: 6, scene: 22, line: 'Seriously. No, really.', order: 1369, speaker: 'Michael Scott' },
  {
    season: 1,
    episode: 6,
    scene: 22,
    line: "No. I really don't want to inconvenience you.",
    order: 1370,
    speaker: 'Katy Moore'
  },
  {
    season: 1,
    episode: 6,
    scene: 22,
    line: "God! No, no, no, no. No inconvenience. I mean I'm out of here at five sharp.",
    order: 1371,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 6, scene: 22, line: 'At five?', order: 1372, speaker: 'Katy Moore' },
  {
    season: 1,
    episode: 6,
    scene: 22,
    line: "I can go earlier. 'Cause I'm the boss. You know, whatever. I'm out of here slaves.",
    order: 1373,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 6, scene: 22, line: 'Okay.', order: 1374, speaker: 'Katy Moore' },
  { season: 1, episode: 6, scene: 22, line: 'What?', order: 1375, speaker: 'Michael Scott' },
  {
    season: 1,
    episode: 6,
    scene: 22,
    line: 'Okay, I guess that would be, I guess that would be okay.',
    order: 1376,
    speaker: 'Katy Moore'
  },
  {
    season: 1,
    episode: 6,
    scene: 22,
    line: "Okay. Sounds good. Sounds good. Five o'clock sharp. I will give you and your purses a ride home.",
    order: 1377,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 6, scene: 22, line: 'Okay. Cool.', order: 1378, speaker: 'Katy Moore' },
  { season: 1, episode: 6, scene: 22, line: 'Excellent.', order: 1379, speaker: 'Michael Scott' },
  { season: 1, episode: 6, scene: 22, line: 'Cool.', order: 1380, speaker: 'Katy Moore' },
  {
    season: 1,
    episode: 6,
    scene: 22,
    line: 'Great. Cool. Cool. [takes deep breath - looks at camera] Yeah, okay.',
    order: 1381,
    speaker: 'Michael Scott'
  },
  {
    season: 1,
    episode: 6,
    scene: 23,
    line:
      'I should have never let the Temp touch this thing. I had all these great icons and now I have four folders. So..',
    order: 1382,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 6, scene: 23, line: "It's actually better this way.", order: 1383, speaker: 'Dwight Schrute' },
  {
    season: 1,
    episode: 6,
    scene: 23,
    line: "No it's not. Because I could just click on the icon and then I'm onto---",
    order: 1384,
    speaker: 'Michael Scott'
  },
  {
    season: 1,
    episode: 6,
    scene: 23,
    line:
      "Michael could I ask you something? I wanted to ask your permission to ask out Katy. I know it's against the rules and everything. Because...",
    order: 1385,
    speaker: 'Dwight Schrute'
  },
  {
    season: 1,
    episode: 6,
    scene: 23,
    line: "No, no, no it's not against the rules. She's not a permanent employee so it's not.",
    order: 1386,
    speaker: 'Michael Scott'
  },
  {
    season: 1,
    episode: 6,
    scene: 23,
    line: 'Thank you, Michael. I appreciate this so much.',
    order: 1387,
    speaker: 'Dwight Schrute'
  },
  {
    season: 1,
    episode: 6,
    scene: 23,
    line: 'But I think you should just know that I am going to be giving her a ride home later.',
    order: 1388,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 6, scene: 23, line: 'What?', order: 1389, speaker: 'Dwight Schrute' },
  {
    season: 1,
    episode: 6,
    scene: 23,
    line: 'She asked me for a ride and so I am going to give her a ride home.',
    order: 1390,
    speaker: 'Michael Scott'
  },
  {
    season: 1,
    episode: 6,
    scene: 23,
    line: 'Is that all it is? Just a ride home? Like a taxicab?',
    order: 1391,
    speaker: 'Dwight Schrute'
  },
  {
    season: 1,
    episode: 6,
    scene: 23,
    line: 'Well, might be a ride home. Might be a ride home and we stop for coffee and dot-dot-dot...',
    order: 1392,
    speaker: 'Michael Scott'
  },
  {
    season: 1,
    episode: 6,
    scene: 23,
    line:
      "Please. Please, I am your inferior and I'm asking you this favor. Can you promise me that it will just be a ride home?",
    order: 1393,
    speaker: 'Dwight Schrute'
  },
  { season: 1, episode: 6, scene: 23, line: 'No. I cannot promise you that.', order: 1394, speaker: 'Michael Scott' },
  {
    season: 1,
    episode: 6,
    scene: 23,
    line: "You cannot promise me, or you won't promise me?",
    order: 1395,
    speaker: 'Dwight Schrute'
  },
  { season: 1, episode: 6, scene: 23, line: 'Listen, Dwight.', order: 1396, speaker: 'Michael Scott' },
  { season: 1, episode: 6, scene: 23, line: 'Do you love her?', order: 1397, speaker: 'Dwight Schrute' },
  {
    season: 1,
    episode: 6,
    scene: 23,
    line:
      "[laughs] Dwight, no. I don't know. It's too early to tell. I don't know how I feel. [Dwight sadly looks away]",
    order: 1398,
    speaker: 'Michael Scott'
  },
  {
    season: 1,
    episode: 6,
    scene: 24,
    line: "I think you've made a really good choice, she's really going to like that.",
    order: 1399,
    speaker: 'Katy Moore'
  },
  { season: 1, episode: 6, scene: 24, line: 'Hmmm...', order: 1400, speaker: 'Stanley Hudson' },
  { season: 1, episode: 6, scene: 24, line: 'Espresso?', order: 1401, speaker: 'Michael Scott' },
  { season: 1, episode: 6, scene: 24, line: 'Oh, thank you.', order: 1402, speaker: 'Katy Moore' },
  {
    season: 1,
    episode: 6,
    scene: 24,
    line: "You're welcome. Thank you. Hmmm-hmm-hmm.",
    order: 1403,
    speaker: 'Michael Scott'
  },
  {
    season: 1,
    episode: 6,
    scene: 24,
    line: 'Is that from the machine that was in your office?',
    order: 1404,
    speaker: 'Stanley Hudson'
  },
  { season: 1, episode: 6, scene: 24, line: 'Ummm-hmmm...', order: 1405, speaker: 'Michael Scott' },
  {
    season: 1,
    episode: 6,
    scene: 24,
    line: 'I thought that was the incentive prize for the top salesperson.',
    order: 1406,
    speaker: 'Stanley Hudson'
  },
  { season: 1, episode: 6, scene: 24, line: 'Very easy to clean.', order: 1407, speaker: 'Michael Scott' },
  {
    season: 1,
    episode: 6,
    scene: 24,
    line: "Okay. Like he's going to win anyway, right? [laughs]",
    order: 1408,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 6, scene: 25, line: 'Did we get any mail?', order: 1409, speaker: 'Michael Scott' },
  { season: 1, episode: 6, scene: 25, line: 'Yeah, I gave it to you.', order: 1410, speaker: 'Pam Beesly' },
  {
    season: 1,
    episode: 6,
    scene: 25,
    line:
      "Yes you did. Yes, you did. Just checkin'. Just checkin', double checkin', checkin' on the check. Thoroughness is very important in an office and...",
    order: 1411,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 6, scene: 25, line: 'So, can I..? [points to the door]', order: 1412, speaker: 'Pam Beesly' },
  {
    season: 1,
    episode: 6,
    scene: 25,
    line: 'Yeah, yeah, of course. Uh, Pam, one more thing. Um, how do girls your age feel about futons?',
    order: 1413,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 6, scene: 26, line: 'A futon?', order: 1414, speaker: 'Jim Halpert' },
  { season: 1, episode: 6, scene: 26, line: '[nods]', order: 1415, speaker: 'Pam Beesly' },
  { season: 1, episode: 6, scene: 26, line: "He's a grown man", order: 1416, speaker: 'Jim Halpert' },
  { season: 1, episode: 6, scene: 26, line: "That's what he said.", order: 1417, speaker: 'Pam Beesly' },
  {
    season: 1,
    episode: 6,
    scene: 26,
    line:
      "That's sad. Or it's innovative. Well, you know the futon is a bed and couch all rolled into one. [Jim sees Roy and trails off]",
    order: 1418,
    speaker: 'Jim Halpert'
  },
  { season: 1, episode: 6, scene: 26, line: "What's up?", order: 1419, speaker: 'Roy Anderson' },
  { season: 1, episode: 6, scene: 26, line: '[not looking at Roy] Hi.', order: 1420, speaker: 'Pam Beesly' },
  { season: 1, episode: 6, scene: 26, line: 'Are you still mad at me?', order: 1421, speaker: 'Roy Anderson' },
  { season: 1, episode: 6, scene: 26, line: 'Roy...', order: 1422, speaker: 'Pam Beesly' },
  { season: 1, episode: 6, scene: 26, line: 'Come on [begins to tickle Pam]', order: 1423, speaker: 'Roy Anderson' },
  { season: 1, episode: 6, scene: 26, line: 'Cut it out.', order: 1424, speaker: 'Pam Beesly' },
  { season: 1, episode: 6, scene: 26, line: 'Come on, you mad at me?', order: 1425, speaker: 'Roy Anderson' },
  { season: 1, episode: 6, scene: 26, line: 'Stop it. [laughing]', order: 1426, speaker: 'Pam Beesly' },
  { season: 1, episode: 6, scene: 26, line: 'Are you still mad at me now?', order: 1427, speaker: 'Roy Anderson' },
  { season: 1, episode: 6, scene: 26, line: '[giggling] Cut it out.', order: 1428, speaker: 'Pam Beesly' },
  { season: 1, episode: 6, scene: 26, line: 'Are you mad at me now?', order: 1429, speaker: 'Roy Anderson' },
  { season: 1, episode: 6, scene: 26, line: 'Stop. [giggling]', order: 1430, speaker: 'Pam Beesly' },
  {
    season: 1,
    episode: 6,
    scene: 26,
    line: 'Huh? huh? Come on... Come on, Pammy I was just kidding.',
    order: 1431,
    speaker: 'Roy Anderson'
  },
  { season: 1, episode: 6, scene: 26, line: "[breathless] Stop, I can't breathe.", order: 1432, speaker: 'Pam Beesly' },
  {
    season: 1,
    episode: 6,
    scene: 26,
    line: "I was just kidding. You know I didn't mean it. I can't...",
    order: 1433,
    speaker: 'Roy Anderson'
  },
  {
    season: 1,
    episode: 6,
    scene: 27,
    line:
      "Jim is a great guy. He's like a brother to me. We're like best friends in the office and I really hope he finds someone.",
    order: 1434,
    speaker: 'Pam Beesly'
  },
  {
    season: 1,
    episode: 6,
    scene: 28,
    line: 'You seem to like to touch things. Did you try the velvet?',
    order: 1435,
    speaker: 'Katy Moore'
  },
  {
    season: 1,
    episode: 6,
    scene: 28,
    line: "I don't like to necessarily touch things. I'm just... I'm shopping.",
    order: 1436,
    speaker: 'Angela Martin'
  },
  {
    season: 1,
    episode: 6,
    scene: 28,
    line:
      "Oh no, it's fine that you, um. Here, what about the raspberry one? It's really uh, kind of festive. It's got a lot of personality.",
    order: 1437,
    speaker: 'Katy Moore'
  },
  { season: 1, episode: 6, scene: 28, line: 'Yeah, uh no.', order: 1438, speaker: 'Angela Martin' },
  {
    season: 1,
    episode: 6,
    scene: 28,
    line: "Hey, how's it going? Good. Can I talk to you for a second? In private?",
    order: 1439,
    speaker: 'Dwight Schrute'
  },
  { season: 1, episode: 6, scene: 28, line: "I don't think so I'm really busy.", order: 1440, speaker: 'Katy Moore' },
  { season: 1, episode: 6, scene: 28, line: 'It will just take a second.', order: 1441, speaker: 'Dwight Schrute' },
  { season: 1, episode: 6, scene: 28, line: "I can't.", order: 1442, speaker: 'Katy Moore' },
  { season: 1, episode: 6, scene: 28, line: 'Just for a minute.', order: 1443, speaker: 'Dwight Schrute' },
  { season: 1, episode: 6, scene: 28, line: "I really can't.", order: 1444, speaker: 'Katy Moore' },
  {
    season: 1,
    episode: 6,
    scene: 28,
    line: 'Please? I wanted to talk to you in private because I wanted to ask you out on a date.',
    order: 1445,
    speaker: 'Dwight Schrute'
  },
  { season: 1, episode: 6, scene: 28, line: 'No.', order: 1446, speaker: 'Katy Moore' },
  {
    season: 1,
    episode: 6,
    scene: 28,
    line: 'Ok was that no to talking to me in private, or was that no to the date?',
    order: 1447,
    speaker: 'Dwight Schrute'
  },
  { season: 1, episode: 6, scene: 28, line: 'Both.', order: 1448, speaker: 'Katy Moore' },
  { season: 1, episode: 6, scene: 28, line: 'What colors do you like?', order: 1449, speaker: 'Katy Moore' },
  { season: 1, episode: 6, scene: 28, line: 'Gray. Dark Gray. Charcoal.', order: 1450, speaker: 'Angela Martin' },
  { season: 1, episode: 6, scene: 29, line: 'Ryan.', order: 1451, speaker: 'Michael Scott' },
  { season: 1, episode: 6, scene: 29, line: 'Yeah.', order: 1452, speaker: 'Ryan Howard' },
  {
    season: 1,
    episode: 6,
    scene: 29,
    line: 'Would you like to help me with a special project?',
    order: 1453,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 6, scene: 29, line: 'I would love to.', order: 1454, speaker: 'Ryan Howard' },
  { season: 1, episode: 6, scene: 29, line: 'Alright.', order: 1455, speaker: 'Michael Scott' },
  {
    season: 1,
    episode: 6,
    scene: 30,
    line: "[in Michael's car] Okay, just throw out all the empties.",
    order: 1456,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 6, scene: 30, line: "You don't want to recycle them?", order: 1457, speaker: 'Ryan Howard' },
  {
    season: 1,
    episode: 6,
    scene: 30,
    line: 'Um, yes. Throw them away in the recycling bin.',
    order: 1458,
    speaker: 'Michael Scott'
  },
  {
    season: 1,
    episode: 6,
    scene: 30,
    line: 'Do you want this? [holding a full bottle of water]',
    order: 1459,
    speaker: 'Ryan Howard'
  },
  { season: 1, episode: 6, scene: 30, line: 'No.', order: 1460, speaker: 'Michael Scott' },
  {
    season: 1,
    episode: 6,
    scene: 30,
    line: 'What about this bottle of power drink?',
    order: 1461,
    speaker: 'Ryan Howard'
  },
  { season: 1, episode: 6, scene: 30, line: 'Uh, what flavor?', order: 1462, speaker: 'Michael Scott' },
  { season: 1, episode: 6, scene: 30, line: 'Blue.', order: 1463, speaker: 'Ryan Howard' },
  { season: 1, episode: 6, scene: 30, line: "Blue's not a flavor.", order: 1464, speaker: 'Michael Scott' },
  { season: 1, episode: 6, scene: 30, line: 'It says flavor: Blue Blast.', order: 1465, speaker: 'Ryan Howard' },
  {
    season: 1,
    episode: 6,
    scene: 30,
    line:
      'Oh, Blue Blast. Yes, put that in the trunk, and there should be an unopened Arctic Chill back there. I want that in the passengers cupholder. Thank you.',
    order: 1466,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 6, scene: 31, line: 'Hi.', order: 1467, speaker: 'Jim Halpert' },
  { season: 1, episode: 6, scene: 31, line: 'Hi.', order: 1468, speaker: 'Katy Moore' },
  { season: 1, episode: 6, scene: 31, line: "I'm Jim, by the way.", order: 1469, speaker: 'Jim Halpert' },
  { season: 1, episode: 6, scene: 31, line: "I'm Katy.", order: 1470, speaker: 'Katy Moore' },
  { season: 1, episode: 6, scene: 31, line: 'Hi Katy, nice to meet you.', order: 1471, speaker: 'Jim Halpert' },
  { season: 1, episode: 6, scene: 31, line: "You sit out there, don't you?", order: 1472, speaker: 'Katy Moore' },
  {
    season: 1,
    episode: 6,
    scene: 31,
    line: "I do. That's what I'm best known for. Sitting out there. Alright, let's talk about purses.",
    order: 1473,
    speaker: 'Jim Halpert'
  },
  { season: 1, episode: 6, scene: 31, line: 'Okay, um...', order: 1474, speaker: 'Katy Moore' },
  {
    season: 1,
    episode: 6,
    scene: 31,
    line: "Katy but you know what, don't try to sell me one. Okay, seriously 'cause I'm just here to learn.",
    order: 1475,
    speaker: 'Jim Halpert'
  },
  { season: 1, episode: 6, scene: 31, line: 'Okay. [laughs]', order: 1476, speaker: 'Katy Moore' },
  {
    season: 1,
    episode: 6,
    scene: 31,
    line: 'Okay, so I know about most of these, but you know you can...',
    order: 1477,
    speaker: 'Jim Halpert'
  },
  { season: 1, episode: 6, scene: 31, line: 'Okay.', order: 1478, speaker: 'Katy Moore' },
  {
    season: 1,
    episode: 6,
    scene: 32,
    line: "What, stop! Whoa! That's my Drakkar Noir.",
    order: 1479,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 6, scene: 32, line: 'No, this is Rite Aid Night Swept.', order: 1480, speaker: 'Ryan Howard' },
  {
    season: 1,
    episode: 6,
    scene: 32,
    line: "No, it is a perfect smell-alike. I'm not paying for the label. Right here. Give it.",
    order: 1481,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 6, scene: 32, line: "Well, it's empty.", order: 1482, speaker: 'Ryan Howard' },
  {
    season: 1,
    episode: 6,
    scene: 32,
    line:
      "Not it's not, there's some in the straw. [Michael opens bottle and wipes straw along his neck] There, now you may throw it out.",
    order: 1483,
    speaker: 'Michael Scott'
  },
  {
    season: 1,
    episode: 6,
    scene: 32,
    line: 'Wow. How many filet-o-fishes did you eat?',
    order: 1484,
    speaker: 'Ryan Howard'
  },
  {
    season: 1,
    episode: 6,
    scene: 32,
    line: "That's over several months, Ryan.",
    order: 1485,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 6, scene: 32, line: '[Under his breath] Still.', order: 1486, speaker: 'Ryan Howard' },
  { season: 1, episode: 6, scene: 33, line: "What's up?", order: 1487, speaker: 'Jim Halpert' },
  { season: 1, episode: 6, scene: 33, line: "I'm bored.", order: 1488, speaker: 'Pam Beesly' },
  { season: 1, episode: 6, scene: 33, line: 'Thank you for choosing me.', order: 1489, speaker: 'Jim Halpert' },
  {
    season: 1,
    episode: 6,
    scene: 33,
    line: "No, I'm kidding. Um, so you got big plans this weekend?",
    order: 1490,
    speaker: 'Pam Beesly'
  },
  {
    season: 1,
    episode: 6,
    scene: 33,
    line: "Ah, well I think I'm gonna see Katy.",
    order: 1491,
    speaker: 'Jim Halpert'
  },
  { season: 1, episode: 6, scene: 33, line: 'Really?', order: 1492, speaker: 'Pam Beesly' },
  { season: 1, episode: 6, scene: 33, line: 'Yeah.', order: 1493, speaker: 'Jim Halpert' },
  { season: 1, episode: 6, scene: 33, line: 'What are you guys going to do?', order: 1494, speaker: 'Pam Beesly' },
  {
    season: 1,
    episode: 6,
    scene: 33,
    line: "Oh, man I don't know. Uh, dinner, drinks, movie, matching tattoos.",
    order: 1495,
    speaker: 'Jim Halpert'
  },
  { season: 1, episode: 6, scene: 33, line: "That's great.", order: 1496, speaker: 'Pam Beesly' },
  { season: 1, episode: 6, scene: 33, line: 'And stuff... yeah.', order: 1497, speaker: 'Jim Halpert' },
  { season: 1, episode: 6, scene: 33, line: "That's cool.", order: 1498, speaker: 'Pam Beesly' },
  { season: 1, episode: 6, scene: 33, line: 'What are you doing?', order: 1499, speaker: 'Jim Halpert' },
  {
    season: 1,
    episode: 6,
    scene: 33,
    line: "I, I was gonna say, I think that um, we're gonna help Roy's cousin move.",
    order: 1500,
    speaker: 'Pam Beesly'
  },
  { season: 1, episode: 6, scene: 33, line: 'Okay.', order: 1501, speaker: 'Jim Halpert' },
  { season: 1, episode: 6, scene: 33, line: "Cause Roy's got a truck.", order: 1502, speaker: 'Pam Beesly' },
  { season: 1, episode: 6, scene: 33, line: "That's cool.", order: 1503, speaker: 'Jim Halpert' },
  { season: 1, episode: 6, scene: 33, line: 'Uh, huh. Yes.', order: 1504, speaker: 'Pam Beesly' },
  {
    season: 1,
    episode: 6,
    scene: 33,
    line: "That is cool. Well, I'll see you Monday though, right?",
    order: 1505,
    speaker: 'Jim Halpert'
  },
  { season: 1, episode: 6, scene: 33, line: 'Great.', order: 1506, speaker: 'Pam Beesly' },
  { season: 1, episode: 6, scene: 33, line: 'Okay.', order: 1507, speaker: 'Jim Halpert' },
  { season: 1, episode: 6, scene: 33, line: "Okay, I'm gonna head back.", order: 1508, speaker: 'Pam Beesly' },
  { season: 1, episode: 6, scene: 33, line: 'Alright.', order: 1509, speaker: 'Jim Halpert' },
  {
    season: 1,
    episode: 6,
    scene: 34,
    line:
      "I think in order to be a ladies man, it's imperative that people don't know you're a ladies man, so I kind of play that close to the chest. I don't know, what can I say? Women are attracted to power. And I think other people have told me that I have a very symmetrical face. [laughs] I don't know. I don't know. Maybe they're right? I don't know.",
    order: 1510,
    speaker: 'Michael Scott'
  },
  {
    season: 1,
    episode: 6,
    scene: 35,
    line: "Sure you don't want me to help you with that? Cause I can grab that no problem.",
    order: 1511,
    speaker: 'Michael Scott'
  },
  {
    season: 1,
    episode: 6,
    scene: 35,
    line: 'Goodnight, it was nice nice to meet some of you.',
    order: 1512,
    speaker: 'Katy Moore'
  },
  {
    season: 1,
    episode: 6,
    scene: 35,
    line: 'See you later. Goodnight. Goodnight, Jim.',
    order: 1513,
    speaker: 'Michael Scott'
  },
  { season: 1, episode: 6, scene: 35, line: 'Goodnight, Michael.', order: 1514, speaker: 'Jim Halpert' },
  { season: 1, episode: 6, scene: 35, line: 'Where you going?', order: 1515, speaker: 'Michael Scott' },
  {
    season: 1,
    episode: 6,
    scene: 35,
    line: "I don't know. Grab a drink, I think?",
    order: 1516,
    speaker: 'Jim Halpert'
  },
  { season: 1, episode: 6, scene: 35, line: 'With us?', order: 1517, speaker: 'Michael Scott' },
  {
    season: 1,
    episode: 6,
    scene: 35,
    line:
      "I uh, I probably should have told you, I don't need a ride now 'cause Jim can take me home after so you're off the hook.",
    order: 1518,
    speaker: 'Katy Moore'
  },
  {
    season: 1,
    episode: 6,
    scene: 35,
    line: 'Okay. Great. Off the hook. Excellent. Okay, cool.',
    order: 1519,
    speaker: 'Michael Scott'
  },
  {
    season: 1,
    episode: 6,
    scene: 35,
    line: "I got this. [taking Katy's bag from Michael]",
    order: 1520,
    speaker: 'Jim Halpert'
  },
  { season: 1, episode: 6, scene: 35, line: 'Alright, have fun.', order: 1521, speaker: 'Michael Scott' },
  { season: 1, episode: 6, scene: 35, line: 'Thanks.', order: 1522, speaker: 'Katy Moore' },
  { season: 1, episode: 6, scene: 35, line: 'I got it.', order: 1523, speaker: 'Jim Halpert' },
  { season: 1, episode: 6, scene: 35, line: "Don't drink and drive.", order: 1524, speaker: 'Michael Scott' },
  { season: 1, episode: 6, scene: 35, line: 'Take it easy.', order: 1525, speaker: 'Michael Scott' },
  { season: 1, episode: 6, scene: 35, line: 'Have a good night.', order: 1526, speaker: 'Jim Halpert' },
  { season: 1, episode: 6, scene: 35, line: 'You too, have a good night.', order: 1527, speaker: 'Michael Scott' },
  { season: 1, episode: 6, scene: 35, line: 'You got that?', order: 1528, speaker: 'Katy Moore' },
  {
    season: 1,
    episode: 6,
    scene: 35,
    line: "Oh, yeah. You sold a lot, so it's lighter.",
    order: 1529,
    speaker: 'Jim Halpert'
  },
  { season: 1, episode: 6, scene: 35, line: 'Good. Here. Squeeze it inside.', order: 1530, speaker: 'Katy Moore' },
  {
    season: 1,
    episode: 6,
    scene: 35,
    line: "Alright now, I'm gonna warn you. Don't freak out, okay?",
    order: 1531,
    speaker: 'Jim Halpert'
  },
  { season: 1, episode: 6, scene: 35, line: 'Why?', order: 1532, speaker: 'Katy Moore' },
  {
    season: 1,
    episode: 6,
    scene: 35,
    line: "This is a really nice car. In case you haven't noticed, this is a Corolla. Okay.",
    order: 1533,
    speaker: 'Jim Halpert'
  },
  { season: 1, episode: 6, scene: 35, line: "It's a... it's a very nice car.", order: 1534, speaker: 'Katy Moore' },
  { season: 1, episode: 6, scene: 35, line: "You're not going to freak out?", order: 1535, speaker: 'Jim Halpert' },
  {
    season: 1,
    episode: 6,
    scene: 36,
    line:
      "Do I have a special someone? Uh well, yeah of course. A bunch of 'em. My employees. If I had to choose between a one-night-stand with some stupid cow I pick-up in a bar, and these people? I'd pick them every time. Because with them, it is an everyday stand and I still know their names in the morning.",
    order: 1536,
    speaker: 'Michael Scott'
  }
]
