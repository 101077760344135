module.exports = [
  {"season": 3, "episode": 1, "scene": 1, "line": "Yeah, I'm not a temp anymore. I got Jim's uh, old job. Which means at my 10 year high school reunion it will", "order": 7588, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 1, "scene": 2, "line": "[after 'the kiss'] You have no idea how long I've wanted to do that.", "order": 7589, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 1, "scene": 2, "line": "Me too. ...I think we're just drunk.", "order": 7590, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 1, "scene": 2, "line": "No I'm not drunk. Are you drunk?", "order": 7591, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 1, "scene": 2, "line": "No... [Jim leans in for another kiss] Jim---", "order": 7592, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 1, "scene": 2, "line": "Are you really gonna marry him? [Pam nods] ...Ok.", "order": 7593, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 1, "scene": 3, "line": "[sobbing] Jim is gone. He's gone. I miss him so much. Ooooh I cry myself to sleep, Jim! [serious]", "order": 7594, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 1, "scene": 4, "line": "No! That is the fun of this place. I call everybody 'faggie'. Why would anybody find that offensive?", "order": 7595, "speaker": "Michael Scott"},
  {"season": 3, "episode": 1, "scene": 4, "line": "OK I think Oscar would just like if you used 'lame' or something like that.", "order": 7596, "speaker": "Toby Flenderson"},
  {"season": 3, "episode": 1, "scene": 4, "line": "That's what faggie means!", "order": 7597, "speaker": "Michael Scott"},
  {"season": 3, "episode": 1, "scene": 4, "line": "No not really...", "order": 7598, "speaker": "Toby Flenderson"},
  {"season": 3, "episode": 1, "scene": 5, "line": "Apparently you called Oscar 'faggie' for liking the movie Shakespeare in Love more than an action movie.", "order": 7599, "speaker": "Toby Flenderson"},
  {"season": 3, "episode": 1, "scene": 5, "line": "It wasn't just an action movie, it was Die Hard!", "order": 7600, "speaker": "Michael Scott"},
  {"season": 3, "episode": 1, "scene": 5, "line": "All right Michael, but Oscar's really gay.", "order": 7601, "speaker": "Toby Flenderson"},
  {"season": 3, "episode": 1, "scene": 5, "line": "Exactly!", "order": 7602, "speaker": "Michael Scott"},
  {"season": 3, "episode": 1, "scene": 5, "line": "I mean for real.", "order": 7603, "speaker": "Toby Flenderson"},
  {"season": 3, "episode": 1, "scene": 5, "line": "Yeah, I know.", "order": 7604, "speaker": "Michael Scott"},
  {"season": 3, "episode": 1, "scene": 5, "line": "No, I mean he's attracted to other men.", "order": 7605, "speaker": "Toby Flenderson"},
  {"season": 3, "episode": 1, "scene": 5, "line": "OK, a little too far, crossed the line.", "order": 7606, "speaker": "Michael Scott"},
  {"season": 3, "episode": 1, "scene": 5, "line": "OK, I am telling you, Oscar is an actual homosexual. Yeah, he told me this morning. And, obviously he hopes he can count on your discretion.", "order": 7607, "speaker": "Toby Flenderson"},
  {"season": 3, "episode": 1, "scene": 6, "line": "I would have never called him that if I knew. You don't... You don't call retarded people 'retards'. It's bad taste. You call your friends 'retards' when they're acting retarded. And I consider Oscar a friend.", "order": 7608, "speaker": "Michael Scott"},
  {"season": 3, "episode": 1, "scene": 7, "line": "Listen man, I am so sorry. I had no idea.", "order": 7609, "speaker": "Michael Scott"},
  {"season": 3, "episode": 1, "scene": 7, "line": "No, it's fine.", "order": 7610, "speaker": "Oscar Martinez"},
  {"season": 3, "episode": 1, "scene": 7, "line": "No. No it's not. I feel terrible about it. I have been calling people 'faggie' since I was in junior high, and I have never made this mistake. If I don't know how to behave, it is because I am just so far the opposite way, you know? I'm just... I, I can't even imagine... the... thing. Maybe we could go out for a beer sometime. And you could tell me... how... you do that to another dude.", "order": 7611, "speaker": "Michael Scott"},
  {"season": 3, "episode": 1, "scene": 7, "line": "That sounds like a great, wonderful idea, let's do that.", "order": 7612, "speaker": "Oscar Martinez"},
  {"season": 3, "episode": 1, "scene": 8, "line": "[in reference to Oscar being gay] It explains so much.", "order": 7613, "speaker": "Angela Martin"},
  {"season": 3, "episode": 1, "scene": 9, "line": "No, I'm not gay. And I don't understand why anyone would think, that I'm gay... if... [sighs] Uh... yeah I'm gay.", "order": 7614, "speaker": "Oscar Martinez"},
  {"season": 3, "episode": 1, "scene": 10, "line": "I can't say whether Dunder Mifflin paper is less flammable, sir, but I can assure that it is certainly not more flammable.", "order": 7615, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 1, "scene": 11, "line": "Why did I transfer to Stamford? I think that's pretty obvious. I got promoted! And you can't beat that view... right?", "order": 7616, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 1, "scene": 12, "line": "Hey, Big Tuna! You're single right?", "order": 7617, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 1, "scene": 12, "line": "Uh-huh, yeah. I am.", "order": 7618, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 1, "scene": 12, "line": "She's pretty hot huh? [Jim nods] She's completely crazy. Steer clear Big Tuna. Head for open waters.", "order": 7619, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 1, "scene": 12, "line": "OooOK.", "order": 7620, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 1, "scene": 12, "line": "OooOK.", "order": 7621, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 1, "scene": 13, "line": "I ate a tuna sandwich, on my first day. So, Andy started calling me Big Tuna. ...I don't think any of them actually know my real name.", "order": 7622, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 1, "scene": 14, "line": "Big Tuna is a super ambitious guy, you know? Cut your throat to get ahead kind of guy, but I mean I'm not threatened by him. I went to Cornell, you ever heard of it? [laughs] I graduated in four years. I never studied once. I was drunk the whole time, and I sang in the acappella group, 'Here Comes Treble.'", "order": 7623, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 1, "scene": 15, "line": "So, end of day we are going to have a little diversity policy refresher, because of some more problems at the Scranton branch. And I have a list of business startups I got from the chamber. Yes, I am going to need someone to cold call them.", "order": 7624, "speaker": "Josh Porter"},
  {"season": 3, "episode": 1, "scene": 15, "line": "Oh, I can do that.", "order": 7625, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 1, "scene": 16, "line": "Jim's nice enough. I dont... I don't know how well he's fitting in here. He's always looking at the camera like this. [makes classic 'Jim'-camera face] What is that?", "order": 7626, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 1, "scene": 17, "line": "Can you tell who's gay and who's not?", "order": 7627, "speaker": "Michael Scott"},
  {"season": 3, "episode": 1, "scene": 17, "line": "Of course.", "order": 7628, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 1, "scene": 17, "line": "What about Oscar?", "order": 7629, "speaker": "Michael Scott"},
  {"season": 3, "episode": 1, "scene": 17, "line": "Absolutely not.", "order": 7630, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 1, "scene": 17, "line": "Well, he", "order": 7631, "speaker": "Michael Scott"},
  {"season": 3, "episode": 1, "scene": 17, "line": "Well, he's not dressed in women's clothes, so...", "order": 7632, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 1, "scene": 17, "line": "[deep sigh] There could be others... I need to know. I don't want to offend anybody else.", "order": 7633, "speaker": "Michael Scott"},
  {"season": 3, "episode": 1, "scene": 17, "line": "You could assume everyone is, and not say anything offensive.", "order": 7634, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 1, "scene": 17, "line": "Yeah, I'm sure everyone would appreciate me treating them like they were gay.", "order": 7635, "speaker": "Michael Scott"},
  {"season": 3, "episode": 1, "scene": 18, "line": "Hey, what about Angela? She's hard and severe. She could be a gay woman.", "order": 7636, "speaker": "Michael Scott"},
  {"season": 3, "episode": 1, "scene": 18, "line": "I really don't think so.", "order": 7637, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 1, "scene": 18, "line": "I don't know, I can imagine her with another woman, can't you?", "order": 7638, "speaker": "Michael Scott"},
  {"season": 3, "episode": 1, "scene": 18, "line": "[creepy smile]", "order": 7639, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 1, "scene": 19, "line": "Do some research. Find out if there's a way to tell by just looking at them.", "order": 7640, "speaker": "Michael Scott"},
  {"season": 3, "episode": 1, "scene": 19, "line": "Jim told me you could buy gay-dar online.", "order": 7641, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 1, "scene": 19, "line": "That's ridiculous.", "order": 7642, "speaker": "Michael Scott"},
  {"season": 3, "episode": 1, "scene": 19, "line": "Yeah probably. He didn't tell the truth a lot.", "order": 7643, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 1, "scene": 19, "line": "[sighs] Let's call him and get the website.", "order": 7644, "speaker": "Michael Scott"},
  {"season": 3, "episode": 1, "scene": 19, "line": "Definitely.", "order": 7645, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 1, "scene": 20, "line": "What's gay-dar?", "order": 7646, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 1, "scene": 21, "line": "Well, they're sold out.", "order": 7647, "speaker": "Michael Scott"},
  {"season": 3, "episode": 1, "scene": 21, "line": "Damn. [thinks] I'll try Brookstone.", "order": 7648, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 1, "scene": 22, "line": "I miss that.", "order": 7649, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 1, "scene": 23, "line": "Chicken or fish?", "order": 7650, "speaker": "Roy Anderson"},
  {"season": 3, "episode": 1, "scene": 23, "line": "[loud sigh] Chicken.", "order": 7651, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 1, "scene": 23, "line": "So you havin' a good day?", "order": 7652, "speaker": "Roy Anderson"},
  {"season": 3, "episode": 1, "scene": 23, "line": "Excellent, thanks.", "order": 7653, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 1, "scene": 23, "line": "Good, glad. OK.", "order": 7654, "speaker": "Roy Anderson"},
  {"season": 3, "episode": 1, "scene": 24, "line": "Yeah, I didn't go through with the wedding. I got cold feet, a few days before. And I can't really explain it. I just had to get out of that relationship. We still had to pay for all the food. So we froze it. But I'm, I'm doing well. I have my own apartment. I'm taking art classes. And I have lunch for the next five weeks.", "order": 7655, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 1, "scene": 25, "line": "After Pam dumped me, I um, I kinda stopped taking care of myself there, and uh, I hit bottom when uh, drunk driving arrest. I've been working out and um, you know, I'm not gonna take her for granted. I gotta win her back.", "order": 7656, "speaker": "Roy Anderson"},
  {"season": 3, "episode": 1, "scene": 26, "line": "I got them a toaster. They called off the wedding and gave the toaster back to me. I tried to return the toaster to the store, and they said they no longer sold that kind of toaster. So now my house has got two toasters.", "order": 7657, "speaker": "Stanley Hudson"},
  {"season": 3, "episode": 1, "scene": 27, "line": "That is so cool that you're gay. I totally underestimated you.", "order": 7658, "speaker": "Kelly Kapoor"},
  {"season": 3, "episode": 1, "scene": 28, "line": "Yes I'm super cool. I am an accountant at a failing paper supply company, Scranton. Much like, um, sir Ian McClellan.", "order": 7659, "speaker": "Oscar Martinez"},
  {"season": 3, "episode": 1, "scene": 29, "line": "Sure, sometimes I watch Will and Grace... and I want to throw up. It's terribly loud. I do like it sometimes when Harry Connick Jr. is on. He's so talented.", "order": 7660, "speaker": "Angela Martin"},
  {"season": 3, "episode": 1, "scene": 30, "line": "OK. Who put my calculator in Jello? Good one. But uh, seriously, guys who did this? Seriously guys, who did this? I need to know who put my calculator in Jello, or I'm gonna lose MY FRICKIN' MIND!", "order": 7661, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 1, "scene": 31, "line": "You know, it's amazing to me that in this day and age, you could be so obtuse about sexual orientation.", "order": 7662, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 1, "scene": 31, "line": "I watch the L Word. I watch, Queer as F***, so...", "order": 7663, "speaker": "Michael Scott"},
  {"season": 3, "episode": 1, "scene": 31, "line": "That's not what it's called.", "order": 7664, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 1, "scene": 31, "line": "OK, Michael, are you aware that you ousted Oscar today?", "order": 7665, "speaker": "Toby Flenderson"},
  {"season": 3, "episode": 1, "scene": 31, "line": "What? What does that even...", "order": 7666, "speaker": "Michael Scott"},
  {"season": 3, "episode": 1, "scene": 31, "line": "Coming out, is a significant moment for a gay person, and they should be allowed to select the timing and manner of announcing it.", "order": 7667, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 1, "scene": 31, "line": "Well, gay pride, right? Gay pride parade? It's not like gay... shame festival.", "order": 7668, "speaker": "Michael Scott"},
  {"season": 3, "episode": 1, "scene": 31, "line": "All right, now Oscar's feeling discriminated against by his co-workers, primarily Angela, and um, that's your fault.", "order": 7669, "speaker": "Toby Flenderson"},
  {"season": 3, "episode": 1, "scene": 31, "line": "I think Angela might be gay. Could Oscar and Angela be having a gay affair? Maybe! Is that what this is about?", "order": 7670, "speaker": "Michael Scott"},
  {"season": 3, "episode": 1, "scene": 31, "line": "NO!", "order": 7671, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 1, "scene": 31, "line": "I don't kn--", "order": 7672, "speaker": "Michael Scott"},
  {"season": 3, "episode": 1, "scene": 31, "line": "No, it's not possible.", "order": 7673, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 1, "scene": 31, "line": "Anything's possible.", "order": 7674, "speaker": "Michael Scott"},
  {"season": 3, "episode": 1, "scene": 31, "line": "You know, imagine... you were gay.", "order": 7675, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 1, "scene": 31, "line": "[laughs] Well, I'm not gay Jan, and you should know that better than anybody!", "order": 7676, "speaker": "Michael Scott"},
  {"season": 3, "episode": 1, "scene": 31, "line": "Michael, your immaturity is extremely disappointing and may even lead to a lawsuit which is the absolute last thing this company needs right now. Do you understand?", "order": 7677, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 1, "scene": 32, "line": "The company has made it my responsibility today to put an end to 100,000 years of being weirded out by gays.", "order": 7678, "speaker": "Michael Scott"},
  {"season": 3, "episode": 1, "scene": 33, "line": "Am I the first gay man you ever knew?", "order": 7679, "speaker": "Oscar Martinez"},
  {"season": 3, "episode": 1, "scene": 33, "line": "Trick question! Cause you can't always tell, so... how would I know. Is that the right answer?", "order": 7680, "speaker": "Michael Scott"},
  {"season": 3, "episode": 1, "scene": 34, "line": "Michael, Dwight's looking at gay pornography on his computer.", "order": 7681, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 1, "scene": 34, "line": "Uh, Michael knows Pam. He asked me to do this just for him. He has his own reasons.", "order": 7682, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 1, "scene": 35, "line": "[looking at gay porn] Nothing wrong with this stuff. At all. This is fine. You know what. Gay porn, straight porn, it's all goooooood! I don't particularly get into this. But uh, you know what. I totally see the merit! And actually... it is quite beautiful.", "order": 7683, "speaker": "Michael Scott"},
  {"season": 3, "episode": 1, "scene": 36, "line": "Ah damn pop-ups.", "order": 7684, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 1, "scene": 36, "line": "What are you doing?!", "order": 7685, "speaker": "Oscar Martinez"},
  {"season": 3, "episode": 1, "scene": 36, "line": "Watching some of your friends.", "order": 7686, "speaker": "Angela Martin"},
  {"season": 3, "episode": 1, "scene": 37, "line": "[yelling] All right, everybody in the conference room! I don't care if you are gay, or straight, or a lesbian, or overweight! JUST GET IN HERE! RIGHT NOW!", "order": 7687, "speaker": "Michael Scott"},
  {"season": 3, "episode": 1, "scene": 38, "line": "Did you know that gay used to mean happy? When I was growing up it meant lame. And now it means a man, who makes love... to other men.", "order": 7688, "speaker": "Michael Scott"},
  {"season": 3, "episode": 1, "scene": 39, "line": "We're all homos! Homosapiens. Gays aren't necessarily who you think they are, people. I mean anybody could be gay. Business-men. Like antique dealers, or hairdressers, or... accountants. Oscar, why don't you take this opportunity to officially come out, to everybody here. However you want to do it. Go ahead. Stand up. I'm doing this for you.", "order": 7689, "speaker": "Michael Scott"},
  {"season": 3, "episode": 1, "scene": 39, "line": "Yes I'm gay. And I didn't plan on sharing that part of my life with you today, so, whatever. Can I sit down now?", "order": 7690, "speaker": "Oscar Martinez"},
  {"season": 3, "episode": 1, "scene": 40, "line": "I'm not offended by homosexuality. In the sixties I made love to many many women, often outdoors, in the mud and the rain. And it was possible a man slipped in, and there would be no way of knowing.", "order": 7691, "speaker": "Creed Bratton"},
  {"season": 3, "episode": 1, "scene": 41, "line": "Who should be the judges and juries of our society?", "order": 7692, "speaker": "Michael Scott"},
  {"season": 3, "episode": 1, "scene": 41, "line": "Judges and juries!", "order": 7693, "speaker": "Angela Martin"},
  {"season": 3, "episode": 1, "scene": 41, "line": "Yes, that's a good point. She has a good point. Because gay marriage currently is not legal, under U.S. law. I bet a lot of straight men wish that applied to them. So they could go out there and have some torrid unabashed monkey sex as much as they could. You know? Sounds pretty good, right?", "order": 7694, "speaker": "Michael Scott"},
  {"season": 3, "episode": 1, "scene": 41, "line": "That sounds great.", "order": 7695, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 1, "scene": 42, "line": "I think all the other office gays should identify themselves, or I will do it for them.", "order": 7696, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 1, "scene": 42, "line": "No one else in this office is gay.", "order": 7697, "speaker": "Oscar Martinez"},
  {"season": 3, "episode": 1, "scene": 42, "line": "What about Phyllis? She makes absolutely no attempt to be feminine!", "order": 7698, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 1, "scene": 42, "line": "I'm getting married to Bob Vance.", "order": 7699, "speaker": "Phyllis Lapin"},
  {"season": 3, "episode": 1, "scene": 42, "line": "That's great. Congratulations Phyllis. That is great, and frankly kiiiiiiiind of amazing! [laughs] See? Everybody has a chance!", "order": 7700, "speaker": "Michael Scott"},
  {"season": 3, "episode": 1, "scene": 43, "line": "But still, Phyllis, in college? Did you ever experiment with other women? A lot of women do!", "order": 7701, "speaker": "Michael Scott"},
  {"season": 3, "episode": 1, "scene": 43, "line": "No, you knew me in high school. Course, we all thought you were gay in high school.", "order": 7702, "speaker": "Phyllis Lapin"},
  {"season": 3, "episode": 1, "scene": 43, "line": "[laughing] Right! [serious] And I take that as a compliment.", "order": 7703, "speaker": "Michael Scott"},
  {"season": 3, "episode": 1, "scene": 43, "line": "Well with your ties and your matching socks and ---", "order": 7704, "speaker": "Phyllis Lapin"},
  {"season": 3, "episode": 1, "scene": 43, "line": "Well, I just like to look good OK, so ---", "order": 7705, "speaker": "Michael Scott"},
  {"season": 3, "episode": 1, "scene": 43, "line": "You sound pretty defensive Michael.", "order": 7706, "speaker": "Oscar Martinez"},
  {"season": 3, "episode": 1, "scene": 43, "line": "No, I am just coming out myself. I am coming out hetero!", "order": 7707, "speaker": "Michael Scott"},
  {"season": 3, "episode": 1, "scene": 43, "line": "I think the problem with this office is that", "order": 7708, "speaker": "Oscar Martinez"},
  {"season": 3, "episode": 1, "scene": 43, "line": "No no no. The only signal that I am sending is:", "order": 7709, "speaker": "Michael Scott"},
  {"season": 3, "episode": 1, "scene": 44, "line": "Look, if I was gay, I would be the most flamboyant gay you have ever seen. I would be leading the parade covered in feathers, and just... I would be waving that rainbow flag.", "order": 7710, "speaker": "Michael Scott"},
  {"season": 3, "episode": 1, "scene": 44, "line": "I don't think I can work here any longer. This has been the worst, most backwards day of my life.", "order": 7711, "speaker": "Oscar Martinez"},
  {"season": 3, "episode": 1, "scene": 44, "line": "You misunderstand-- OK. You know what, OK. I uh, I'm gonna put my money where my mouth is. You ready? I am going to embrace Oscar. You might want to watch this Angela, because you can't catch anything. Here we go. We are going to make a statement. You and I are going to make a statement together. Oscar is my friend ---", "order": 7712, "speaker": "Michael Scott"},
  {"season": 3, "episode": 1, "scene": 44, "line": "I would rather not.", "order": 7713, "speaker": "Oscar Martinez"},
  {"season": 3, "episode": 1, "scene": 44, "line": "...and I just don't care who sees it, doesn't bother me!", "order": 7714, "speaker": "Michael Scott"},
  {"season": 3, "episode": 1, "scene": 45, "line": "No, NO! I don't want to touch you, ever consider that? You're ignorant, and insulting, and small!", "order": 7715, "speaker": "Oscar Martinez"},
  {"season": 3, "episode": 1, "scene": 45, "line": "All right, um... sorry.", "order": 7716, "speaker": "Michael Scott"},
  {"season": 3, "episode": 1, "scene": 45, "line": "Michael... I'm sorry. That was a good idea. Come on, come on. [Oscar and Michael hug]", "order": 7717, "speaker": "Oscar Martinez"},
  {"season": 3, "episode": 1, "scene": 45, "line": "[sobbing] I'm sorry I called you faggie. You're not faggie. You're a a good guy.", "order": 7718, "speaker": "Michael Scott"},
  {"season": 3, "episode": 1, "scene": 46, "line": "Michael appears to be gay too. And yet he is my friend. I guess I do have a gay friend.", "order": 7719, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 1, "scene": 47, "line": "[embracing Oscar]You know what, I'm going to raise the stakes. I want you to watch this. And I want you to burn this into your brains. Because this is an image that I want you people to remember for a long time to come. Whenever you come into the office I want you to think about this. [awkwardly kissing Oscar] I did it. See. I'm still here. We're all still here. [everyone claps]", "order": 7720, "speaker": "Michael Scott"},
  {"season": 3, "episode": 1, "scene": 47, "line": "[Dwight gets up to kiss Oscar] Oh come on! Dwight! Come on, man!", "order": 7721, "speaker": "Michael Scott"},
  {"season": 3, "episode": 1, "scene": 48, "line": "We are not in the playground anymore. There are new rules. We have to be mature. But we can't lose the spirit of child like wonder. What is love... anyway? Maybe it's supposed to break all the rules. Like me and Jan. Or Oscar... and some guy. Life is short. When two people find each other, what should stand in their way?", "order": 7722, "speaker": "Michael Scott"},
  {"season": 3, "episode": 1, "scene": 49, "line": "I am glad that today spurred social change. That's part of my job as regional manager. But you know what? Even if it didn't, at least we put this matter to bed. ...that's what she said. Or he said.", "order": 7723, "speaker": "Michael Scott"},
  {"season": 3, "episode": 1, "scene": 50, "line": "Oh, there's Gill. Oscar's roommate. I wonder if he knows?", "order": 7724, "speaker": "Michael Scott"},
  {"season": 3, "episode": 1, "scene": 51, "line": "I was going to quit. But Jan offered me a three month paid vacation and a company car. All I had to do was sign something saying I won't sue. Gill and I are going to Europe. Kids, sometimes it pays to be gay.", "order": 7725, "speaker": "Oscar Martinez"},
  {"season": 3, "episode": 1, "scene": 52, "line": "[reading the note with the gay-dar] 'Hope this helps. -Jim' Nice!", "order": 7726, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 1, "scene": 53, "line": "[Dwight is running the gay-dar over Oscar's body] What are you doing?!", "order": 7727, "speaker": "Oscar Martinez"},
  {"season": 3, "episode": 1, "scene": 53, "line": "Shhh. Don't be scared. [gay-dar beeps over Oscar's belt buckle, Dwight smiles] It works. [gay-dar goes off next to Dwight's belt buckle] ...oh no.", "order": 7728, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 1, "scene": 54, "line": "[on speakerphone] Okay, what is your goal for after lunch?", "order": 7729, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 1, "scene": 54, "line": "I will do my job to the best of my abilities.", "order": 7730, "speaker": "Michael Scott"},
  {"season": 3, "episode": 1, "scene": 54, "line": "[under breath] Heaven help us. [aloud] Specifically.", "order": 7731, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 1, "scene": 54, "line": "I will do my", "order": 7732, "speaker": "Michael Scott"},
  {"season": 3, "episode": 1, "scene": 54, "line": "Specifically", "order": 7733, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 1, "scene": 54, "line": "Specifically, Jan, I will make at least ten phone calls to clients.", "order": 7734, "speaker": "Michael Scott"},
  {"season": 3, "episode": 1, "scene": 55, "line": "Yes, I have to begin every day by letting Jan know what I intend to accomplish, and then I have to call her at the end of the day to tell her what I", "order": 7735, "speaker": "Michael Scott"},
  {"season": 3, "episode": 1, "scene": 56, "line": "[on speakerphone] Are you on the toilet, Michael?", "order": 7736, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 1, "scene": 56, "line": "[quietly] No.", "order": 7737, "speaker": "Michael Scott"},
  {"season": 3, "episode": 1, "scene": 56, "line": "Well, I know sometimes you are and... that you wouldn't tell me, so I'm just going to assume that you are, and call me back when you're finished.", "order": 7738, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 1, "scene": 56, "line": "Okay. [Jan hangs up. Toby opens the office door.]", "order": 7739, "speaker": "Michael Scott"},
  {"season": 3, "episode": 1, "scene": 56, "line": "Michael?", "order": 7740, "speaker": "Toby Flenderson"},
  {"season": 3, "episode": 1, "scene": 56, "line": "[sigh]", "order": 7741, "speaker": "Michael Scott"},
  {"season": 3, "episode": 1, "scene": 57, "line": "Hi, honey. You holding up?", "order": 7742, "speaker": "Michael Scott"},
  {"season": 3, "episode": 1, "scene": 57, "line": "Yup.", "order": 7743, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 1, "scene": 57, "line": "Yeah.", "order": 7744, "speaker": "Michael Scott"},
  {"season": 3, "episode": 1, "scene": 57, "line": "I'm... painting my new apartment tonight.", "order": 7745, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 1, "scene": 57, "line": "Oh, good. By yourself?", "order": 7746, "speaker": "Michael Scott"},
  {"season": 3, "episode": 1, "scene": 57, "line": "Yes.", "order": 7747, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 1, "scene": 57, "line": "Aw. Well, hang in there, okay? You know what? You should get one of this big body pillows. That way you wouldn't miss you-know-who so much.", "order": 7748, "speaker": "Michael Scott"},
  {"season": 3, "episode": 1, "scene": 57, "line": "Thanks.", "order": 7749, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 1, "scene": 57, "line": "Roy.", "order": 7750, "speaker": "Michael Scott"},
  {"season": 3, "episode": 1, "scene": 57, "line": "Right.", "order": 7751, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 1, "scene": 58, "line": "What do I think? I think everybody should stay out of everybody's personal business!", "order": 7752, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 1, "scene": 59, "line": "Yes, I'm gay.", "order": 7753, "speaker": "Oscar Martinez"},
  {"season": 3, "episode": 1, "scene": 59, "line": "[shocked expression]", "order": 7754, "speaker": "Meredith Palmer"},
  {"season": 3, "episode": 1, "scene": 60, "line": "Why are all the best-looking single men always gay?", "order": 7755, "speaker": "Meredith Palmer"},
  {"season": 3, "episode": 1, "scene": 61, "line": "Hey, Big Tuna. You ever Google Google? What do you think would happen?", "order": 7756, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 1, "scene": 61, "line": "Let's find out. [starts typing]", "order": 7757, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 1, "scene": 61, "line": "[makes frustrated noises]", "order": 7758, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 1, "scene": 61, "line": "Oh! Lots of results.", "order": 7759, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 1, "scene": 61, "line": "I didn't mean you should do it. You were supposed to ponder it. It's... You removed the fun. Thanks a lot, Big Tuna.", "order": 7760, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 2, "scene": 1, "line": "Pam?", "order": 7761, "speaker": "Michael Scott"},
  {"season": 3, "episode": 2, "scene": 1, "line": "Yeah?", "order": 7762, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 2, "scene": 1, "line": "Did you see Oprah yesterday?", "order": 7763, "speaker": "Michael Scott"},
  {"season": 3, "episode": 2, "scene": 1, "line": "No, I didn't.", "order": 7764, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 2, "scene": 1, "line": "I, uh... I am going to be a father.", "order": 7765, "speaker": "Michael Scott"},
  {"season": 3, "episode": 2, "scene": 1, "line": "What was Oprah about?", "order": 7766, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 2, "scene": 1, "line": "Angelina Jolie was on. And she adopted a baby from Asia, and she said that it changed her life. And that really inspired me. So, I want you to look into seeing how much a little Chinese baby would cost.", "order": 7767, "speaker": "Michael Scott"},
  {"season": 3, "episode": 2, "scene": 1, "line": "That's a really big decision.", "order": 7768, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 2, "scene": 1, "line": "I know.", "order": 7769, "speaker": "Michael Scott"},
  {"season": 3, "episode": 2, "scene": 1, "line": "Maybe you should wait before you adopt.", "order": 7770, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 2, "scene": 1, "line": "Well...", "order": 7771, "speaker": "Michael Scott"},
  {"season": 3, "episode": 2, "scene": 1, "line": "Or not adopt.", "order": 7772, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 2, "scene": 1, "line": "Just do it, okay?", "order": 7773, "speaker": "Michael Scott"},
  {"season": 3, "episode": 2, "scene": 1, "line": "Roy's sister looked into it, and the application alone costs a thousand dollars.", "order": 7774, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 2, "scene": 1, "line": "Um... find out if there's a cheaper, less expensive baby out there, okay?", "order": 7775, "speaker": "Michael Scott"},
  {"season": 3, "episode": 2, "scene": 1, "line": "You know, she also said the waiting list is like eight months.", "order": 7776, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 2, "scene": 1, "line": "Eight months?", "order": 7777, "speaker": "Michael Scott"},
  {"season": 3, "episode": 2, "scene": 1, "line": "Yeah.", "order": 7778, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 2, "scene": 1, "line": "I don't even know if I'll want a baby in eight months.", "order": 7779, "speaker": "Michael Scott"},
  {"season": 3, "episode": 2, "scene": 1, "line": "You probably won't.", "order": 7780, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 2, "scene": 1, "line": "You know what, Pam? If in ten years, I haven't had a baby, and you haven't had a baby...", "order": 7781, "speaker": "Michael Scott"},
  {"season": 3, "episode": 2, "scene": 1, "line": "No, Michael.", "order": 7782, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 2, "scene": 1, "line": "Twenty years.", "order": 7783, "speaker": "Michael Scott"},
  {"season": 3, "episode": 2, "scene": 1, "line": "No, Michael.", "order": 7784, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 2, "scene": 1, "line": "Thirty.", "order": 7785, "speaker": "Michael Scott"},
  {"season": 3, "episode": 2, "scene": 1, "line": "Sure.", "order": 7786, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 2, "scene": 1, "line": "It's a deal.", "order": 7787, "speaker": "Michael Scott"},
  {"season": 3, "episode": 2, "scene": 2, "line": "All right. Three pairs of pants. Three pairs of socks. Three packs of condoms.", "order": 7788, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 2, "scene": 2, "line": "Yesh.", "order": 7789, "speaker": "Michael Scott"},
  {"season": 3, "episode": 2, "scene": 2, "line": "Uh, fun jeans.", "order": 7790, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 2, "scene": 2, "line": "Right there. Ah.", "order": 7791, "speaker": "Michael Scott"},
  {"season": 3, "episode": 2, "scene": 2, "line": "Sign.", "order": 7792, "speaker": "Angela Martin"},
  {"season": 3, "episode": 2, "scene": 2, "line": "Per diem.", "order": 7793, "speaker": "Michael Scott"},
  {"season": 3, "episode": 2, "scene": 3, "line": "Guess where I am going. I will give you a hint. It is a booze-fueled sex romp, where anything goes. You are correct, sir! I am headed to Philadelphia for the Annual Northeastern Mid-Market Office Supply Convention. And Jim Halpert is going to be coming, which will be fun. Poor little guy. He's been stuck working under Josh, the poor man's Michael Scott, as he is known around my condo.", "order": 7794, "speaker": "Michael Scott"},
  {"season": 3, "episode": 2, "scene": 4, "line": "Don't be mad, it is a business trip.", "order": 7795, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 2, "scene": 4, "line": "But I don't understand. It's for managers.", "order": 7796, "speaker": "Angela Martin"},
  {"season": 3, "episode": 2, "scene": 4, "line": "Monkey, I am an A. R. M., Assistant Regional Manager.", "order": 7797, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 2, "scene": 4, "line": "I know! It... I was just really hoping we could spend some time together. [A long silence.] Are you still there?", "order": 7798, "speaker": "Angela Martin"},
  {"season": 3, "episode": 2, "scene": 4, "line": "Yes, Monkey.", "order": 7799, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 2, "scene": 4, "line": "Don't 'Monkey' me! You can't wait to get out of here, A. R. M.", "order": 7800, "speaker": "Angela Martin"},
  {"season": 3, "episode": 2, "scene": 5, "line": "In the Martin family, we like to say, 'Looks like someone took the slow train from Philly.' That's code for 'check out the slut.' What is... why are there flies in here?", "order": 7801, "speaker": "Angela Martin"},
  {"season": 3, "episode": 2, "scene": 6, "line": "Oh my God! Are you so excited for tonight? I am so excited. You guys are going to click, I can feel it. So what are you wearing?", "order": 7802, "speaker": "Kelly Kapoor"},
  {"season": 3, "episode": 2, "scene": 6, "line": "This.", "order": 7803, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 2, "scene": 6, "line": "You look so pretty.", "order": 7804, "speaker": "Kelly Kapoor"},
  {"season": 3, "episode": 2, "scene": 6, "line": "Thank you.", "order": 7805, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 2, "scene": 7, "line": "Yes, I have a date. He's a cartoonist for the local paper, which is really neat, because I like to draw too. I'm kind of nervous. I haven't been on a first date in nine years... probably shouldn't broadcast that.", "order": 7806, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 2, "scene": 8, "line": "Well, remember, no matter how much you may want to, do not sleep with him on the first date. It gives him all the power.", "order": 7807, "speaker": "Kelly Kapoor"},
  {"season": 3, "episode": 2, "scene": 8, "line": "Sleep with who? Whom, whom... whom?", "order": 7808, "speaker": "Michael Scott"},
  {"season": 3, "episode": 2, "scene": 8, "line": "My neighbor Alan. They're going on a date tonight.", "order": 7809, "speaker": "Kelly Kapoor"},
  {"season": 3, "episode": 2, "scene": 8, "line": "Oh, wow! Oh my God, I have a great idea. You know what you should do to be hilarious? Wear your wedding dress. It would be a great icebreaker.", "order": 7810, "speaker": "Michael Scott"},
  {"season": 3, "episode": 2, "scene": 8, "line": "And your veil.", "order": 7811, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 2, "scene": 8, "line": "Yeah, do it!", "order": 7812, "speaker": "Michael Scott"},
  {"season": 3, "episode": 2, "scene": 8, "line": "I'll probably just wear this.", "order": 7813, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 2, "scene": 8, "line": "Really? Okay. Well, word of advice: unbutton that top button. Let those things breathe. Any message you want me to relate to Jim?", "order": 7814, "speaker": "Michael Scott"},
  {"season": 3, "episode": 2, "scene": 8, "line": "Um...", "order": 7815, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 2, "scene": 8, "line": "Um. Okay, um.", "order": 7816, "speaker": "Michael Scott"},
  {"season": 3, "episode": 2, "scene": 8, "line": "Um.", "order": 7817, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 2, "scene": 8, "line": "You got that?", "order": 7818, "speaker": "Michael Scott"},
  {"season": 3, "episode": 2, "scene": 8, "line": "I got it.", "order": 7819, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 2, "scene": 8, "line": "Write it down.", "order": 7820, "speaker": "Michael Scott"},
  {"season": 3, "episode": 2, "scene": 8, "line": "I got it.", "order": 7821, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 2, "scene": 8, "line": "Um.", "order": 7822, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 2, "scene": 8, "line": "Um.", "order": 7823, "speaker": "Michael Scott"},
  {"season": 3, "episode": 2, "scene": 8, "line": "[singing] Um, um, um...", "order": 7824, "speaker": "Dwight Schrute & Michael Scott"},
  {"season": 3, "episode": 2, "scene": 9, "line": "There's my girl. I noticed you handing out some shekels. How would one get on that train?", "order": 7825, "speaker": "Creed Bratton"},
  {"season": 3, "episode": 2, "scene": 9, "line": "That was for per diem, for Philadelphia.", "order": 7826, "speaker": "Angela Martin"},
  {"season": 3, "episode": 2, "scene": 9, "line": "That town smells like cheese steaks.", "order": 7827, "speaker": "Meredith Palmer"},
  {"season": 3, "episode": 2, "scene": 9, "line": "That town is full of history!", "order": 7828, "speaker": "Angela Martin"},
  {"season": 3, "episode": 2, "scene": 9, "line": "Andrea's the office bitch. You'll get used to her. [introducing himself] Creed.", "order": 7829, "speaker": "Creed Bratton"},
  {"season": 3, "episode": 2, "scene": 10, "line": "Can I have my neck pillow back? Michael! Can I have my neck pillow back?", "order": 7830, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 2, "scene": 11, "line": "Hey, Michael.", "order": 7831, "speaker": "Josh Porter"},
  {"season": 3, "episode": 2, "scene": 11, "line": "Hey, Josh, how ya doing?", "order": 7832, "speaker": "Michael Scott"},
  {"season": 3, "episode": 2, "scene": 11, "line": "Pretty good.", "order": 7833, "speaker": "Josh Porter"},
  {"season": 3, "episode": 2, "scene": 11, "line": "Good to see you. There he is! There's the traitor! Traitor! Traitor! Come here, you. Come here! Yeah! The product... the progidal... my son returns.", "order": 7834, "speaker": "Michael Scott"},
  {"season": 3, "episode": 2, "scene": 12, "line": "I was shocked when he told me he was transferring to Stamford. It's like the firemen. You don't leave your brothers behind, even if you find out that there is a better fire in Connecticut.", "order": 7835, "speaker": "Michael Scott"},
  {"season": 3, "episode": 2, "scene": 13, "line": "It's really good to see you, man.", "order": 7836, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 2, "scene": 13, "line": "Yeah! Wow, I didn't expect that. It's good to see you too!", "order": 7837, "speaker": "Michael Scott"},
  {"season": 3, "episode": 2, "scene": 13, "line": "Oh, hey, how's it going up there? Have you made any sales yet?", "order": 7838, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 2, "scene": 13, "line": "Yeah, sold about forty thousand.", "order": 7839, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 2, "scene": 13, "line": "Hey!", "order": 7840, "speaker": "Michael Scott"},
  {"season": 3, "episode": 2, "scene": 13, "line": "Shut up. That's impossible.", "order": 7841, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 2, "scene": 13, "line": "No, it's not. I did. Yep.", "order": 7842, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 2, "scene": 13, "line": "Well, I did it too.", "order": 7843, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 2, "scene": 14, "line": "You know, when I saw Dwight, I realized how stupid and petty all those pranks I pulled on him were. And then he spoke. I wonder how hard it would be to get a copy of his room key.", "order": 7844, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 2, "scene": 15, "line": "So did you hear?", "order": 7845, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 2, "scene": 15, "line": "What?", "order": 7846, "speaker": "Toby Flenderson"},
  {"season": 3, "episode": 2, "scene": 15, "line": "Pam's back on the market again.", "order": 7847, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 2, "scene": 15, "line": "Really? She's dating?", "order": 7848, "speaker": "Toby Flenderson"},
  {"season": 3, "episode": 2, "scene": 15, "line": "If I weren't engaged, I would so hit that.", "order": 7849, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 2, "scene": 16, "line": "So what kind of commissions you get up there?", "order": 7850, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 2, "scene": 16, "line": "Oh, Dwight. I've missed you so much.", "order": 7851, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 2, "scene": 16, "line": "You're so immature!", "order": 7852, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 2, "scene": 17, "line": "Listen, Michael, I heard you were a great salesman.", "order": 7853, "speaker": "Josh Porter"},
  {"season": 3, "episode": 2, "scene": 17, "line": "Ah.", "order": 7854, "speaker": "Michael Scott"},
  {"season": 3, "episode": 2, "scene": 17, "line": "And I just want to let you know that if our branch absorbs your branch, I'm going to look for a place for you at Stamford.", "order": 7855, "speaker": "Josh Porter"},
  {"season": 3, "episode": 2, "scene": 17, "line": "Oh, hey!", "order": 7856, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 2, "scene": 17, "line": "Hey, Jan.", "order": 7857, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 2, "scene": 17, "line": "We all checked in?", "order": 7858, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 2, "scene": 17, "line": "Yes.", "order": 7859, "speaker": "Josh Porter"},
  {"season": 3, "episode": 2, "scene": 17, "line": "Great, let's dump our stuff and meet back here in half an hour.", "order": 7860, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 2, "scene": 17, "line": "Okay.", "order": 7861, "speaker": "Josh Porter"},
  {"season": 3, "episode": 2, "scene": 17, "line": "Okay!", "order": 7862, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 2, "scene": 17, "line": "Jan? Look, I think we need to set some ground rules.", "order": 7863, "speaker": "Michael Scott"},
  {"season": 3, "episode": 2, "scene": 17, "line": "What are you talking about?", "order": 7864, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 2, "scene": 17, "line": "The eight-hundred pound gorilla in the room. Carol. I'm still dating her, so nothing can happen between us at the convention.", "order": 7865, "speaker": "Michael Scott"},
  {"season": 3, "episode": 2, "scene": 17, "line": "Step away from me, Michael.", "order": 7866, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 2, "scene": 17, "line": "Thank you for being so brave with all of this. Thank you.", "order": 7867, "speaker": "Michael Scott"},
  {"season": 3, "episode": 2, "scene": 18, "line": "Alan's cartoon is so funny, right?", "order": 7868, "speaker": "Kelly Kapoor"},
  {"season": 3, "episode": 2, "scene": 18, "line": "Mm-hmm.", "order": 7869, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 2, "scene": 18, "line": "And they're, like, so smart. I don't even know what they mean half the time.", "order": 7870, "speaker": "Kelly Kapoor"},
  {"season": 3, "episode": 2, "scene": 19, "line": "This party is going to be awesome.", "order": 7871, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 2, "scene": 19, "line": "I know! That is specifically why I chose a room close to the elevator. More foot traffic.", "order": 7872, "speaker": "Michael Scott"},
  {"season": 3, "episode": 2, "scene": 19, "line": "Check it out.", "order": 7873, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 2, "scene": 19, "line": "That is crooked on that side.", "order": 7874, "speaker": "Michael Scott"},
  {"season": 3, "episode": 2, "scene": 19, "line": "Wow.", "order": 7875, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 2, "scene": 19, "line": "Hey hey!", "order": 7876, "speaker": "Michael Scott"},
  {"season": 3, "episode": 2, "scene": 19, "line": "That is a lot of liquor.", "order": 7877, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 2, "scene": 19, "line": "Yeah.", "order": 7878, "speaker": "Michael Scott"},
  {"season": 3, "episode": 2, "scene": 19, "line": "And a dart board.", "order": 7879, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 2, "scene": 19, "line": "Well, that's how we do it in Scranton. Or did you forget? There ain't no party like a Scranton party, 'cause a Scranton party don't stop.", "order": 7880, "speaker": "Michael Scott"},
  {"season": 3, "episode": 2, "scene": 19, "line": "We should probably head on down and hook up with Jan.", "order": 7881, "speaker": "Josh Porter"},
  {"season": 3, "episode": 2, "scene": 19, "line": "Hey, well, we have time! One for the road, gentlemen?", "order": 7882, "speaker": "Michael Scott"},
  {"season": 3, "episode": 2, "scene": 19, "line": "A shot of MIDORI, perhaps.", "order": 7883, "speaker": "Josh Porter"},
  {"season": 3, "episode": 2, "scene": 19, "line": "Oh, no, sorry, it's an inside joke. There's this bartender at Stamford who, uh... you know what? You'd just have to be there.", "order": 7884, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 2, "scene": 19, "line": "Wish I was. I love inside jokes. I'd love to be a part of one someday.", "order": 7885, "speaker": "Michael Scott"},
  {"season": 3, "episode": 2, "scene": 19, "line": "Um, we should...", "order": 7886, "speaker": "Josh Porter"},
  {"season": 3, "episode": 2, "scene": 19, "line": "Yeah.", "order": 7887, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 2, "scene": 19, "line": "All right, see you guys down there. Change your mind, come back up.", "order": 7888, "speaker": "Michael Scott"},
  {"season": 3, "episode": 2, "scene": 19, "line": "I'll do a shot, Michael.", "order": 7889, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 2, "scene": 19, "line": "Ugh, that would be gross. It's not even lunch yet.", "order": 7890, "speaker": "Michael Scott"},
  {"season": 3, "episode": 2, "scene": 20, "line": "SWAG! Stuff we all get. I basically decorated my condo for free with all of my SWAG.", "order": 7891, "speaker": "Michael Scott"},
  {"season": 3, "episode": 2, "scene": 21, "line": "Check it out. Hi, I'm Michael Scott, Dunder-Mifflin.", "order": 7892, "speaker": "Michael Scott"},
  {"season": 3, "episode": 2, "scene": 21, "line": "Dwight Schrute, Assistant Regional Manager, Dunder-Mifflin.", "order": 7893, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 2, "scene": 21, "line": "All right.", "order": 7894, "speaker": "Jerome Bettis"},
  {"season": 3, "episode": 2, "scene": 21, "line": "I'm a huge fan.", "order": 7895, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 2, "scene": 21, "line": "Thanks. I appreciate it, guys.", "order": 7896, "speaker": "Jerome Bettis"},
  {"season": 3, "episode": 2, "scene": 21, "line": "You know what? I'm having a huge blowout tonight, Room 308. Hope you can come.", "order": 7897, "speaker": "Michael Scott"},
  {"season": 3, "episode": 2, "scene": 21, "line": "All right, maybe. If I can.", "order": 7898, "speaker": "Jerome Bettis"},
  {"season": 3, "episode": 2, "scene": 21, "line": "Well, cool! Okay, so... can I tell people you're gonna be there?", "order": 7899, "speaker": "Michael Scott"},
  {"season": 3, "episode": 2, "scene": 21, "line": "No, you cannot.", "order": 7900, "speaker": "Jerome Bettis"},
  {"season": 3, "episode": 2, "scene": 21, "line": "So maybe. See ya.", "order": 7901, "speaker": "Michael Scott"},
  {"season": 3, "episode": 2, "scene": 21, "line": "Why do they call him The Bus?", "order": 7902, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 2, "scene": 21, "line": "Because he's afraid to fly.", "order": 7903, "speaker": "Michael Scott"},
  {"season": 3, "episode": 2, "scene": 22, "line": "Smile!", "order": 7904, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 2, "scene": 22, "line": "Do you remember me from last year? There's a party in my room, 308, can't miss it, right off the elevator. Tonight. Be there!", "order": 7905, "speaker": "Michael Scott"},
  {"season": 3, "episode": 2, "scene": 23, "line": "All right, Jan just emailed me. She wants us to meet her up front.", "order": 7906, "speaker": "Josh Porter"},
  {"season": 3, "episode": 2, "scene": 23, "line": "Yep. Yeah, she's up front.", "order": 7907, "speaker": "Michael Scott"},
  {"season": 3, "episode": 2, "scene": 23, "line": "You don't have email on your phone.", "order": 7908, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 2, "scene": 23, "line": "I don't have to, I just know. Yes, hello?", "order": 7909, "speaker": "Michael Scott"},
  {"season": 3, "episode": 2, "scene": 23, "line": "No one just called you.", "order": 7910, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 2, "scene": 23, "line": "All right.", "order": 7911, "speaker": "Josh Porter"},
  {"season": 3, "episode": 2, "scene": 23, "line": "All right, nice meeting you, Ted. Take care.", "order": 7912, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 2, "scene": 23, "line": "I'll see you soon.", "order": 7913, "speaker": "Ted"},
  {"season": 3, "episode": 2, "scene": 23, "line": "Yeah, stop by later.", "order": 7914, "speaker": "Josh Porter"},
  {"season": 3, "episode": 2, "scene": 23, "line": "[into phone] See you in a bit. Bye-bye. [to Dwight] May I have a moment of your time please?", "order": 7915, "speaker": "Michael Scott"},
  {"season": 3, "episode": 2, "scene": 24, "line": "I need you to do something for me.", "order": 7916, "speaker": "Michael Scott"},
  {"season": 3, "episode": 2, "scene": 24, "line": "Yes. Anything.", "order": 7917, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 2, "scene": 24, "line": "I want you to dig up some dirt on Josh. Find out if there are any skeletons in his attic.", "order": 7918, "speaker": "Michael Scott"},
  {"season": 3, "episode": 2, "scene": 24, "line": "I'll talk to my buddy down at the station, stat.", "order": 7919, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 2, "scene": 25, "line": "Hey, Pam.", "order": 7920, "speaker": "Toby Flenderson"},
  {"season": 3, "episode": 2, "scene": 25, "line": "Hey! What's up?", "order": 7921, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 2, "scene": 25, "line": "It's, uh... I was... might ask if you wanted...", "order": 7922, "speaker": "Toby Flenderson"},
  {"season": 3, "episode": 2, "scene": 25, "line": "[answering phone] Dunder-Mifflin, this is Pam. He's not in the office. Can I take a message? I will. You too. [hangs up] Sorry. What's up?", "order": 7923, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 2, "scene": 25, "line": "Um, if, uh... um... I just completely forgot what I was going to say. It's so weird.", "order": 7924, "speaker": "Toby Flenderson"},
  {"season": 3, "episode": 2, "scene": 25, "line": "Okay. Well, if you think of it, I'll be here.", "order": 7925, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 2, "scene": 25, "line": "Okay.", "order": 7926, "speaker": "Toby Flenderson"},
  {"season": 3, "episode": 2, "scene": 26, "line": "So I called my buddy down at the station today. Had them run a background check on Josh Porter. See if there's any known aliases, et cetera.", "order": 7927, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 2, "scene": 26, "line": "And?", "order": 7928, "speaker": "Michael Scott"},
  {"season": 3, "episode": 2, "scene": 26, "line": "He wasn't volunteering today.", "order": 7929, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 2, "scene": 27, "line": "Business has been pretty crazy around the office.", "order": 7930, "speaker": "Michael Scott"},
  {"season": 3, "episode": 2, "scene": 27, "line": "Oh yeah?", "order": 7931, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 2, "scene": 27, "line": "Yeah. Moved Ryan over to your desk.", "order": 7932, "speaker": "Michael Scott"},
  {"season": 3, "episode": 2, "scene": 27, "line": "Oh, tell him I say hi.", "order": 7933, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 2, "scene": 27, "line": "I will call him later with that message.", "order": 7934, "speaker": "Michael Scott"},
  {"season": 3, "episode": 2, "scene": 27, "line": "Hey, how is... Toby?", "order": 7935, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 2, "scene": 27, "line": "Toby Flenderson is everything that is wrong with the paper industry. Is he why you left?", "order": 7936, "speaker": "Michael Scott"},
  {"season": 3, "episode": 2, "scene": 27, "line": "Oh, no, it was... you know, a good opportunity for me, a promotion. I got a chance to...", "order": 7937, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 2, "scene": 27, "line": "Opportunity, promotion, blah, blah. You know, Jim, those are just words. Have you taken into account other factors, vis-a-vis bosses? Is Josh funnier than I am? Does he even have a girlfriend? Because I have two, basically.", "order": 7938, "speaker": "Michael Scott"},
  {"season": 3, "episode": 2, "scene": 27, "line": "Michael, it's really not a competition.", "order": 7939, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 2, "scene": 28, "line": "Jim got us a great lead with a new rep from National Envelope. We can grab lunch with them tomorrow.", "order": 7940, "speaker": "Josh Porter"},
  {"season": 3, "episode": 2, "scene": 28, "line": "Great!", "order": 7941, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 2, "scene": 28, "line": "Hey, Jan, Jan? Don't worry, I have got the tip.", "order": 7942, "speaker": "Michael Scott"},
  {"season": 3, "episode": 2, "scene": 28, "line": "Whoa. Michael.", "order": 7943, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 2, "scene": 28, "line": "Wow, oh my God, thank you.", "order": 7944, "speaker": "Waiter"},
  {"season": 3, "episode": 2, "scene": 28, "line": "You're welcome.", "order": 7945, "speaker": "Michael Scott"},
  {"season": 3, "episode": 2, "scene": 28, "line": "Was that your per diem?", "order": 7946, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 2, "scene": 28, "line": "No, that was a different hundred dollar bill.", "order": 7947, "speaker": "Michael Scott"},
  {"season": 3, "episode": 2, "scene": 28, "line": "What have you generated, Michael?", "order": 7948, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 2, "scene": 28, "line": "I have generated a lot of interest in my party this evening.", "order": 7949, "speaker": "Michael Scott"},
  {"season": 3, "episode": 2, "scene": 28, "line": "What party?", "order": 7950, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 2, "scene": 28, "line": "The party I'm having tonight in 308. Obviously, you are invited.", "order": 7951, "speaker": "Michael Scott"},
  {"season": 3, "episode": 2, "scene": 28, "line": "Michael, um... Jim and Josh are in meetings all day. And I am in and out of meetings. I can't stay on top of you 24/7.", "order": 7952, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 2, "scene": 29, "line": "You should order the most expensive thing on the menu, so he knows you're worth it.", "order": 7953, "speaker": "Phyllis Lapin"},
  {"season": 3, "episode": 2, "scene": 29, "line": "If you do that, you're going to have to put out.", "order": 7954, "speaker": "Stanley Hudson"},
  {"season": 3, "episode": 2, "scene": 29, "line": "Oh, yeah. You'll have to put out.", "order": 7955, "speaker": "Phyllis Lapin"},
  {"season": 3, "episode": 2, "scene": 30, "line": "Is there a key for Jane Doe? Thank you.", "order": 7956, "speaker": "Angela Martin"},
  {"season": 3, "episode": 2, "scene": 30, "line": "Any messages for 308? All right.", "order": 7957, "speaker": "Michael Scott"},
  {"season": 3, "episode": 2, "scene": 31, "line": "Hey hey, fellers.", "order": 7958, "speaker": "Michael Scott"},
  {"season": 3, "episode": 2, "scene": 31, "line": "Michael.", "order": 7959, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 2, "scene": 31, "line": "Hey, Michael.", "order": 7960, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 2, "scene": 31, "line": "What's up?", "order": 7961, "speaker": "Michael Scott"},
  {"season": 3, "episode": 2, "scene": 31, "line": "Well, Jim and I have a meeting with Uni-ball in about forty-five minutes, so we should probably go now.", "order": 7962, "speaker": "Josh Porter"},
  {"season": 3, "episode": 2, "scene": 31, "line": "Hey, check this out. How about that? A little friendly competition. Stamford versus Scranton.", "order": 7963, "speaker": "Michael Scott"},
  {"season": 3, "episode": 2, "scene": 31, "line": "I don't think so.", "order": 7964, "speaker": "Josh Porter"},
  {"season": 3, "episode": 2, "scene": 31, "line": "Oh, Jim. Looks like you picked a bad time to defect, my friend.", "order": 7965, "speaker": "Michael Scott"},
  {"season": 3, "episode": 2, "scene": 31, "line": "Fine! All right.", "order": 7966, "speaker": "Josh Porter"},
  {"season": 3, "episode": 2, "scene": 31, "line": "Okay! Excellent. Oh...", "order": 7967, "speaker": "Michael Scott"},
  {"season": 3, "episode": 2, "scene": 31, "line": "Keep the wing flaps.", "order": 7968, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 2, "scene": 31, "line": "Shut it. [answering phone] Hey, Pam, what's up? Yep, yech... no. Tell him I will give him general specifics tomorrow, okay? Yes. [to others] Say hi to Pam!", "order": 7969, "speaker": "Michael Scott"},
  {"season": 3, "episode": 2, "scene": 31, "line": "Hi, Pam.", "order": 7970, "speaker": "Jim Halpert, Josh Porter, & Dwight Schrute"},
  {"season": 3, "episode": 2, "scene": 31, "line": "Yes. That is Josh and Dwight and Jim. [to others] Pam says hi. [into phone] Have fun on your date! Very good, talk to you later. Bye.", "order": 7971, "speaker": "Michael Scott"},
  {"season": 3, "episode": 2, "scene": 32, "line": "You first.", "order": 7972, "speaker": "Michael Scott"},
  {"season": 3, "episode": 2, "scene": 32, "line": "Come on. Nice try, Josh!", "order": 7973, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 2, "scene": 32, "line": "Not. It hit the rim. Okay, and... okay. Double or nothing.", "order": 7974, "speaker": "Michael Scott"},
  {"season": 3, "episode": 2, "scene": 32, "line": "Or what? We didn't bet anything, Michael.", "order": 7975, "speaker": "Josh Porter"},
  {"season": 3, "episode": 2, "scene": 32, "line": "Well, let's...", "order": 7976, "speaker": "Michael Scott"},
  {"season": 3, "episode": 2, "scene": 32, "line": "Yeah, we should go.", "order": 7977, "speaker": "Josh Porter"},
  {"season": 3, "episode": 2, "scene": 32, "line": "Come on!", "order": 7978, "speaker": "Michael Scott"},
  {"season": 3, "episode": 2, "scene": 32, "line": "We gotta go.", "order": 7979, "speaker": "Josh Porter"},
  {"season": 3, "episode": 2, "scene": 32, "line": "Come on!", "order": 7980, "speaker": "Michael Scott"},
  {"season": 3, "episode": 2, "scene": 32, "line": "Uh... we'll do it later.", "order": 7981, "speaker": "Josh Porter"},
  {"season": 3, "episode": 2, "scene": 32, "line": "Say, while I have you here, could I talk to you about some new and exciting advances to our product line?", "order": 7982, "speaker": "Evan"},
  {"season": 3, "episode": 2, "scene": 32, "line": "Fine.", "order": 7983, "speaker": "Michael Scott"},
  {"season": 3, "episode": 2, "scene": 33, "line": "Jim and I have different definitions of friendship. I think it's talking and being friends, and Jim thinks it's moving to Connecticut and being best friends with Josh. Well, phooey on that. I, uh, I'm done. I am not going to be speaking with him anymore. Whatevs. Long-distance relationships never work.", "order": 7984, "speaker": "Michael Scott"},
  {"season": 3, "episode": 2, "scene": 33, "line": "That is so true. Ready?", "order": 7985, "speaker": "Evan"},
  {"season": 3, "episode": 2, "scene": 33, "line": "Okay, let me tell you what we're looking for.", "order": 7986, "speaker": "Michael Scott"},
  {"season": 3, "episode": 2, "scene": 33, "line": "Great.", "order": 7987, "speaker": "Evan"},
  {"season": 3, "episode": 2, "scene": 34, "line": "Sorry, my meeting ran late.", "order": 7988, "speaker": "Michael Scott"},
  {"season": 3, "episode": 2, "scene": 34, "line": "Really?", "order": 7989, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 2, "scene": 34, "line": "Yes, Jan, really. With a rep from HammerMill.", "order": 7990, "speaker": "Michael Scott"},
  {"season": 3, "episode": 2, "scene": 34, "line": "They're exclusive with Staples.", "order": 7991, "speaker": "Josh Porter"},
  {"season": 3, "episode": 2, "scene": 34, "line": "Used to be. Evan will call you in the morning to work out the details. We can now sell HammerMill products.", "order": 7992, "speaker": "Michael Scott"},
  {"season": 3, "episode": 2, "scene": 34, "line": "Yes! Ha!", "order": 7993, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 2, "scene": 34, "line": "Well, Michael, I underestimated you.", "order": 7994, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 2, "scene": 34, "line": "Yeah, well, maybe next time you will estimate me.", "order": 7995, "speaker": "Michael Scott"},
  {"season": 3, "episode": 2, "scene": 35, "line": "Dwight's room key. And... Dwight's room. What can I say? Old habits die hard.", "order": 7996, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 2, "scene": 35, "line": "D?", "order": 7997, "speaker": "Angela Martin"},
  {"season": 3, "episode": 2, "scene": 35, "line": "Oh my God. Dwight got a hooker! Oh my God, I gotta call... well, I gotta call somebody, I don't even know who to call. Dwight got a hooker!", "order": 7998, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 2, "scene": 36, "line": "So, Alan, Pam is obsessed with your cartoons. She reads them every day.", "order": 7999, "speaker": "Kelly Kapoor"},
  {"season": 3, "episode": 2, "scene": 36, "line": "Oh, great.", "order": 8000, "speaker": "Alan"},
  {"season": 3, "episode": 2, "scene": 36, "line": "I don't want ketchup.", "order": 8001, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 2, "scene": 36, "line": "You love ketchup! He loves ketchup.", "order": 8002, "speaker": "Kelly Kapoor"},
  {"season": 3, "episode": 2, "scene": 36, "line": "So how do you come up with your cartoons?", "order": 8003, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 2, "scene": 36, "line": "Well, I just, uh... I kinda think about stuff that I see, or dream them.", "order": 8004, "speaker": "Alan"},
  {"season": 3, "episode": 2, "scene": 36, "line": "You dream in cartoons? How fun!", "order": 8005, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 2, "scene": 37, "line": "Hey, first guest! You are going to have some tequila, my friend.", "order": 8006, "speaker": "Michael Scott"},
  {"season": 3, "episode": 2, "scene": 37, "line": "I thought there was a party in here.", "order": 8007, "speaker": "Guy"},
  {"season": 3, "episode": 2, "scene": 37, "line": "This is the party.", "order": 8008, "speaker": "Michael Scott"},
  {"season": 3, "episode": 2, "scene": 37, "line": "This is room 308?", "order": 8009, "speaker": "Guy"},
  {"season": 3, "episode": 2, "scene": 37, "line": "Party central! So, what can I do you for? [Guy leaves] All right.", "order": 8010, "speaker": "Michael Scott"},
  {"season": 3, "episode": 2, "scene": 38, "line": "See, this one is great, because it can work on a couple of different levels.", "order": 8011, "speaker": "Alan"},
  {"season": 3, "episode": 2, "scene": 38, "line": "Huh.", "order": 8012, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 2, "scene": 38, "line": "[French accent] Freedom fries for the table.", "order": 8013, "speaker": "Alan"},
  {"season": 3, "episode": 2, "scene": 38, "line": "Freedom fries. Yeah.", "order": 8014, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 2, "scene": 38, "line": "Yeah. I mean, people always say, like, 'Don't be edgy.' But I don't know any other way. Yeah, you get it.", "order": 8015, "speaker": "Alan"},
  {"season": 3, "episode": 2, "scene": 39, "line": "Well, it was, uh, it was really nice meeting you, Alan.", "order": 8016, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 2, "scene": 39, "line": "Yeah. Next time bring some of your illustrations, I'll let you pick my brain.", "order": 8017, "speaker": "Alan"},
  {"season": 3, "episode": 2, "scene": 39, "line": "More freedom fries.", "order": 8018, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 2, "scene": 39, "line": "Yeah.", "order": 8019, "speaker": "Alan"},
  {"season": 3, "episode": 2, "scene": 39, "line": "That's great.", "order": 8020, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 2, "scene": 39, "line": "Okay.", "order": 8021, "speaker": "Alan"},
  {"season": 3, "episode": 2, "scene": 40, "line": "I went on a date. It wasn't a love connection, um... I think when I like someone again, I'll just kinda know.", "order": 8022, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 2, "scene": 41, "line": "Michael. Am I the first to arrive?", "order": 8023, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 2, "scene": 41, "line": "People have been filtering in and out.", "order": 8024, "speaker": "Michael Scott"},
  {"season": 3, "episode": 2, "scene": 41, "line": "Can I get a drink?", "order": 8025, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 2, "scene": 41, "line": "What?", "order": 8026, "speaker": "Michael Scott"},
  {"season": 3, "episode": 2, "scene": 41, "line": "Can I get a drink?", "order": 8027, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 2, "scene": 41, "line": "Sure. You like Cosmos?", "order": 8028, "speaker": "Michael Scott"},
  {"season": 3, "episode": 2, "scene": 41, "line": "Yeah.", "order": 8029, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 2, "scene": 41, "line": "Sure, sounds good. So why are you here? Is Josh busy?", "order": 8030, "speaker": "Michael Scott"},
  {"season": 3, "episode": 2, "scene": 41, "line": "Michael...", "order": 8031, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 2, "scene": 41, "line": "I get it! No, no, I totally get it. He made a better paper airplane, Stamford is better in sales... I get it. We had some fun. We had some laughs. And that's just...", "order": 8032, "speaker": "Michael Scott"},
  {"season": 3, "episode": 2, "scene": 41, "line": "Wait, wait. I didn't transfer because of you. You're a good boss. You're a great boss.", "order": 8033, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 2, "scene": 41, "line": "I'm not better than Josh.", "order": 8034, "speaker": "Michael Scott"},
  {"season": 3, "episode": 2, "scene": 41, "line": "Michael, it's not about... I transferred because of Pam.", "order": 8035, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 2, "scene": 41, "line": "Oh my God. You don't even know. She's single now.", "order": 8036, "speaker": "Michael Scott"},
  {"season": 3, "episode": 2, "scene": 41, "line": "No, I just... I heard something about that. It's just, I kind of put it all on the line. Twice, actually. And she said no. Twice.", "order": 8037, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 2, "scene": 41, "line": "I'm sorry, man. I'm sorry. Hey, you know what? I will talk to her.", "order": 8038, "speaker": "Michael Scott"},
  {"season": 3, "episode": 2, "scene": 41, "line": "Nope, that's okay.", "order": 8039, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 2, "scene": 41, "line": "Yeah.", "order": 8040, "speaker": "Michael Scott"},
  {"season": 3, "episode": 2, "scene": 41, "line": "That's all right.", "order": 8041, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 2, "scene": 41, "line": "I will. I'll talk to her. You should at least talk to Roy. I mean, he knows exactly how you're feeling.", "order": 8042, "speaker": "Michael Scott"},
  {"season": 3, "episode": 2, "scene": 41, "line": "Yeah. Okay, maybe.", "order": 8043, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 2, "scene": 41, "line": "Are we early?", "order": 8044, "speaker": "Evan"},
  {"season": 3, "episode": 2, "scene": 41, "line": "Hey! No, you know, people have been filtering in and out.", "order": 8045, "speaker": "Michael Scott"},
  {"season": 3, "episode": 2, "scene": 41, "line": "Hey.", "order": 8046, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 2, "scene": 41, "line": "Evan, this is Jim.", "order": 8047, "speaker": "Michael Scott"},
  {"season": 3, "episode": 2, "scene": 41, "line": "How are you?", "order": 8048, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 2, "scene": 41, "line": "Hey, uh... Arnie Reisman. Michael, Jim.", "order": 8049, "speaker": "Evan"},
  {"season": 3, "episode": 2, "scene": 41, "line": "Hey, Ernie, how ya doing?", "order": 8050, "speaker": "Michael Scott"},
  {"season": 3, "episode": 2, "scene": 41, "line": "Do you guys work together?", "order": 8051, "speaker": "Evan"},
  {"season": 3, "episode": 2, "scene": 41, "line": "No, we used to. Now we're friends.", "order": 8052, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 2, "scene": 41, "line": "Best friends.", "order": 8053, "speaker": "Michael Scott"},
  {"season": 3, "episode": 2, "scene": 42, "line": "Some people need dozens of friends to say, 'Hey, look at me, I'm popular.' But not me. I'm very picky. I need three, maybe two. When you meet that someone special, you'll just know. Because a real relationship, it... it can't be forced. It should just come about effortlessly.", "order": 8054, "speaker": "Michael Scott"},
  {"season": 3, "episode": 2, "scene": 43, "line": "Now, would you do the pleasure of hitting the lights, sir? [lights go out, leaving Michael's black light on] Ha, ha, ha.", "order": 8055, "speaker": "Michael Scott"},
  {"season": 3, "episode": 2, "scene": 43, "line": "Ha, ha, ha, ha.", "order": 8056, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 2, "scene": 43, "line": "Whoa. What are those stains?", "order": 8057, "speaker": "Michael Scott"},
  {"season": 3, "episode": 2, "scene": 43, "line": "Blood, urine, or semen.", "order": 8058, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 2, "scene": 43, "line": "Oh, God, I hope it's urine.", "order": 8059, "speaker": "Michael Scott"},
  {"season": 3, "episode": 2, "scene": 44, "line": "Today I'm headed to Philadelphia for the annual Northeastern Office Supply Convention. [makes horns gesture and heavy metal scream] Wooo-yaaaaah! Oh, these things tend to be kind of crazy.", "order": 8060, "speaker": "Michael Scott"},
  {"season": 3, "episode": 2, "scene": 45, "line": "[Angela enters Michael's office] Ah.", "order": 8061, "speaker": "Michael Scott"},
  {"season": 3, "episode": 2, "scene": 45, "line": "Sign.", "order": 8062, "speaker": "Angela Martin"},
  {"season": 3, "episode": 2, "scene": 45, "line": "Per-diem. [singing as he signs] Perrrr-diiiiieeeemmmm! [shows off $100 bill to Ryan] You know, Ryan, it's all about the Benjamins.", "order": 8063, "speaker": "Michael Scott"},
  {"season": 3, "episode": 2, "scene": 45, "line": "[reading checklist] Fun jeans.", "order": 8064, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 2, "scene": 45, "line": "[points to door] Right there.", "order": 8065, "speaker": "Michael Scott"},
  {"season": 3, "episode": 2, "scene": 45, "line": "Fourth quarter projections for Jan?", "order": 8066, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 2, "scene": 45, "line": "Yes. What? Oh, yeah, have Pam give those to me.", "order": 8067, "speaker": "Michael Scott"},
  {"season": 3, "episode": 2, "scene": 45, "line": "Will do.", "order": 8068, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 2, "scene": 45, "line": "Sure you don't want to come?", "order": 8069, "speaker": "Michael Scott"},
  {"season": 3, "episode": 2, "scene": 45, "line": "Yeah.", "order": 8070, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 2, "scene": 45, "line": "Gonna be fun! Change your mind we can get you a cot.", "order": 8071, "speaker": "Michael Scott"},
  {"season": 3, "episode": 2, "scene": 45, "line": "[shakes his head]", "order": 8072, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 2, "scene": 46, "line": "A couple of years ago, I went to one in Hartford. 'Connecticut.' And Todd Packer and I went to this bar and met a girl. And [laugh] Packer decides to make out with her. Turns out that her boyfriend is the bouncer at the bar. [laugh] I love this. The bouncer chases us out to Packer's Vette, jump in the Vette, he", "order": 8073, "speaker": "Michael Scott"},
  {"season": 3, "episode": 2, "scene": 47, "line": "See that factory?", "order": 8074, "speaker": "Michael Scott"},
  {"season": 3, "episode": 2, "scene": 47, "line": "Mm-hm.", "order": 8075, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 2, "scene": 47, "line": "I almost worked there.", "order": 8076, "speaker": "Michael Scott"},
  {"season": 3, "episode": 2, "scene": 47, "line": "No!", "order": 8077, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 2, "scene": 47, "line": "Yup. Yeah, I applied for a job there.", "order": 8078, "speaker": "Michael Scott"},
  {"season": 3, "episode": 2, "scene": 47, "line": "What would you have done? Been manager?", "order": 8079, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 2, "scene": 47, "line": "I was going to be a bottle capper.", "order": 8080, "speaker": "Michael Scott"},
  {"season": 3, "episode": 2, "scene": 47, "line": "You? A bottle capper?! [laughs in disbelief]", "order": 8081, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 2, "scene": 47, "line": "I know, I know. Crazy world. Crazy, crazy world.", "order": 8082, "speaker": "Michael Scott"},
  {"season": 3, "episode": 2, "scene": 48, "line": "Yes, I'm aware of Jan and Michael's... history. Um, I don't really like to talk about it 'cause it's unprofessional. Kind of gross.", "order": 8083, "speaker": "Josh Porter"},
  {"season": 3, "episode": 2, "scene": 49, "line": "Josh reminds me of a guy I went to high school with. Big football star, girls loved him. Whatever. Guess what he's doing now. He's a TV announcer for the Eagles. Up in the... [laughs] He doesn't even get to play in the game, I mean.", "order": 8084, "speaker": "Michael Scott"},
  {"season": 3, "episode": 2, "scene": 50, "line": "So, you ever been convicted of a felony?", "order": 8085, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 2, "scene": 50, "line": "What? Uh, no. [to Jim] Uh, I want you to make sure you call Karen and get her to fax that vendor list over.", "order": 8086, "speaker": "Josh Porter"},
  {"season": 3, "episode": 2, "scene": 50, "line": "Misdemeanor?", "order": 8087, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 2, "scene": 50, "line": "Um... When I was in the Coast Guard, a couple of us broke into the women's barracks and we got arrested for trespassing.", "order": 8088, "speaker": "Josh Porter"},
  {"season": 3, "episode": 2, "scene": 50, "line": "Coast Guard? So you know how to fight underwater?", "order": 8089, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 2, "scene": 50, "line": "[stunned]", "order": 8090, "speaker": "Josh Porter"},
  {"season": 3, "episode": 2, "scene": 51, "line": "[Jim, Josh, and Jan walk in the hotel lobby] Josh, um... [Jim continues walking ahead] Why don't you come up to my room for a couple of minutes and we can just go over the schedule for tomorrow together.", "order": 8091, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 2, "scene": 51, "line": "Can we do it over breakfast? It'll be...", "order": 8092, "speaker": "Josh Porter"},
  {"season": 3, "episode": 2, "scene": 51, "line": "A quick drink?", "order": 8093, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 2, "scene": 51, "line": "Thanks, but maybe another time.", "order": 8094, "speaker": "Josh Porter"},
  {"season": 3, "episode": 2, "scene": 51, "line": "[putting on a good face] Great! [walks off]", "order": 8095, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 3, "scene": 1, "line": "[waiting on Pam to be done with the microwave] Ahhhhhhhhh. [whispering loudly] I'm hungry.", "order": 8096, "speaker": "Michael Scott"},
  {"season": 3, "episode": 3, "scene": 2, "line": "Movie Monday! The only cure I know for the Monday blues is... Varsity Blues!", "order": 8097, "speaker": "Michael Scott"},
  {"season": 3, "episode": 3, "scene": 3, "line": "Let's gooooo! Let's go, let's go, let's go!", "order": 8098, "speaker": "Michael Scott"},
  {"season": 3, "episode": 3, "scene": 3, "line": "[directing people to their assigned seats] Take a seat down there. Second from the end.", "order": 8099, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 3, "scene": 3, "line": "Alright, everybody here? Yes.", "order": 8100, "speaker": "Michael Scott"},
  {"season": 3, "episode": 3, "scene": 3, "line": "[walking in with a tray of six popcorn bags] Popcorn anyone?", "order": 8101, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 3, "scene": 3, "line": "Yes pleeeeease! Thank yooouuu!", "order": 8102, "speaker": "Michael Scott"},
  {"season": 3, "episode": 3, "scene": 3, "line": "[with five popcorn bags left] Anyone else? Nooo.", "order": 8103, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 3, "scene": 4, "line": "Movie Monday started with training videos, but we went through those pretty fast. Then we watched a medical video. Since then, it's been half hour installments of various movies, with the exception of an episode of Entourage, which Michael made us watch six times.", "order": 8104, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 3, "scene": 5, "line": "Entourage!", "order": 8105, "speaker": "Michael Scott"},
  {"season": 3, "episode": 3, "scene": 6, "line": "Kevin, would you like to do the scenes from last week?", "order": 8106, "speaker": "Michael Scott"},
  {"season": 3, "episode": 3, "scene": 6, "line": "Yes!", "order": 8107, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 3, "scene": 6, "line": "Why him?", "order": 8108, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 3, "scene": 6, "line": "Don't whine. Get the window. [Dwight exhales]", "order": 8109, "speaker": "Michael Scott"},
  {"season": 3, "episode": 3, "scene": 7, "line": "Previously on Varsity Blues...", "order": 8110, "speaker": "Michael Scott"},
  {"season": 3, "episode": 3, "scene": 7, "line": "Ok. Billy-Bob, got a head injury, but coach made him play. So then Lance, he gets sacked, he's out for the year...", "order": 8111, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 3, "scene": 8, "line": "[walking into an empty office because everyone is watching Varsity Blues] Hello?", "order": 8112, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 3, "scene": 9, "line": "Not everyone approves of Movie Monday. I won't say who.", "order": 8113, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 3, "scene": 10, "line": "I don't approve. I don't.", "order": 8114, "speaker": "Angela Martin"},
  {"season": 3, "episode": 3, "scene": 11, "line": "Hello? [Angela coughs and points to the conference room]", "order": 8115, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 3, "scene": 12, "line": "[Jan walks in on Movie Monday] Hi Jan! Hope you brought the Milk Duds!", "order": 8116, "speaker": "Michael Scott"},
  {"season": 3, "episode": 3, "scene": 13, "line": "How would a movie increase productivity Michael? How on earth would it do that?", "order": 8117, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 3, "scene": 13, "line": "People work faster after...", "order": 8118, "speaker": "Michael Scott"},
  {"season": 3, "episode": 3, "scene": 13, "line": "Magically?", "order": 8119, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 3, "scene": 13, "line": "No... they have to... to make up for the time they lost watching the movie.", "order": 8120, "speaker": "Michael Scott"},
  {"season": 3, "episode": 3, "scene": 13, "line": "No.", "order": 8121, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 3, "scene": 14, "line": "[walking past Dwight's desk] Kitchen.", "order": 8122, "speaker": "Angela Martin"},
  {"season": 3, "episode": 3, "scene": 15, "line": "Michael is going to get us all fired. You sat back and let him play that dumb movie, and now Jan is peeved off and we're all going to lose our jobs.", "order": 8123, "speaker": "Angela Martin"},
  {"season": 3, "episode": 3, "scene": 15, "line": "That's not going to happen.", "order": 8124, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 3, "scene": 15, "line": "You know she has it out for him now.", "order": 8125, "speaker": "Angela Martin"},
  {"season": 3, "episode": 3, "scene": 15, "line": "That's not his fault! He had to follow his heart!", "order": 8126, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 3, "scene": 16, "line": "Ever since Michael dumped Jan for Carol, Jan's been bitching out on him. Reject a woman and she will never let it go. One of the many defects of their kind. Also weak arms.", "order": 8127, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 3, "scene": 17, "line": "Dwight, you should be running this office.", "order": 8128, "speaker": "Angela Martin"},
  {"season": 3, "episode": 3, "scene": 17, "line": "Michael would never let me...", "order": 8129, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 3, "scene": 17, "line": "It's not up to Michael, it's Jan's call. Talk to her.", "order": 8130, "speaker": "Angela Martin"},
  {"season": 3, "episode": 3, "scene": 17, "line": "I could never do that!", "order": 8131, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 3, "scene": 17, "line": "Fine! Sit back and do nothing and let us all get fired!", "order": 8132, "speaker": "Angela Martin"},
  {"season": 3, "episode": 3, "scene": 18, "line": "I know that patience and loyalty are good, and virtuous traits. But sometimes I just think you need to grow a pair.", "order": 8133, "speaker": "Angela Martin"},
  {"season": 3, "episode": 3, "scene": 19, "line": "At the Stamford branch, they all play this World War II video game called Call of Duty, and they're all really into it. I'm told it started as a team building exercise. Unfortunately I really suck at it. We didn't play many video games in Scranton. Instead we'd do stuff like, uh, Pam and I would sometimes hum the same high pitched note, and try to get Dwight to make an appointment with an ear doctor. And uh, Pam called it... Pretendenitus.", "order": 8134, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 3, "scene": 20, "line": "[on the phone] Hey Kelly, my stuff just got here. [Kelly screams with excitement into the phone and Pam has to take it away from her ear]", "order": 8135, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 3, "scene": 21, "line": "Kelly really likes to online shop. Soooo, I felt like I wanted some new clothes. I mean, I just, I wanted to, it just, I felt like it was time, to maybe um... just get new clothes.", "order": 8136, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 3, "scene": 22, "line": "[pulling her new shirt out of a box] Ok.", "order": 8137, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 3, "scene": 22, "line": "[gasps] I love it!", "order": 8138, "speaker": "Kelly Kapoor"},
  {"season": 3, "episode": 3, "scene": 22, "line": "Really? I don't know.", "order": 8139, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 3, "scene": 22, "line": "You haven't even tried it on yet, try it on.", "order": 8140, "speaker": "Kelly Kapoor"},
  {"season": 3, "episode": 3, "scene": 22, "line": "Not at work, I'll try it on---", "order": 8141, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 3, "scene": 22, "line": "Fashion show! Fashion show! Fashion show at lunch!", "order": 8142, "speaker": "Kelly Kapoor"},
  {"season": 3, "episode": 3, "scene": 22, "line": "...noooo", "order": 8143, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 3, "scene": 22, "line": "[clapping her hands in unison] Fashion show! Fashion show! Fashion show at lunch! Fashion show! Fashion show! Fashion show at lunch!", "order": 8144, "speaker": "Kelly Kapoor"},
  {"season": 3, "episode": 3, "scene": 23, "line": "Why did you do that?!", "order": 8145, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 3, "scene": 23, "line": "I'm just killing Germans, any way I can.", "order": 8146, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 3, "scene": 23, "line": "We're on the German team. Shoot the Brittish.", "order": 8147, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 3, "scene": 23, "line": "[turning around to Karen] Wait, are we playing teams?", "order": 8148, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 3, "scene": 23, "line": "[laughing] Yeah.", "order": 8149, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 3, "scene": 24, "line": "[outside in the parking lot, pacing back and forth] WAH! GAH! [breathing heavily]", "order": 8150, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 3, "scene": 25, "line": "[answering the phone] Hello?", "order": 8151, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 3, "scene": 25, "line": "Is this Jan?", "order": 8152, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 3, "scene": 25, "line": "Who is this?", "order": 8153, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 3, "scene": 25, "line": "This is Dwight Schrute. I am calling about an extremely sensitive matter.", "order": 8154, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 3, "scene": 25, "line": "You should talk to Michael, and he'll talk to me, and that way we don't have to speak to each other.", "order": 8155, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 3, "scene": 25, "line": "It's about Michael.", "order": 8156, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 3, "scene": 25, "line": "What about him?", "order": 8157, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 3, "scene": 25, "line": "[exhales] I can't talk here. It's too sensitive.", "order": 8158, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 3, "scene": 25, "line": "It's not about a surprise party is it?", "order": 8159, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 3, "scene": 25, "line": "No, but we should discuss that another time.", "order": 8160, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 3, "scene": 25, "line": "Look, I am already an hour outside of Scranton, Dwight, I'm not coming back.", "order": 8161, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 3, "scene": 25, "line": "Pull over at exit 40. There is a Liz Claiborne outlet. I know you like that store. Go inside and shop, until I can meet you.", "order": 8162, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 3, "scene": 25, "line": "How do you know I like that store?", "order": 8163, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 3, "scene": 25, "line": "Many of your blouses are Claiborne's.", "order": 8164, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 3, "scene": 25, "line": "How do you know that?", "order": 8165, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 3, "scene": 25, "line": "Part of my job.", "order": 8166, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 3, "scene": 25, "line": "No it's not. It's officially not.", "order": 8167, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 3, "scene": 25, "line": "...noted.", "order": 8168, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 3, "scene": 26, "line": "[walking into Michael's office] I'm going... to the dentist.", "order": 8169, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 3, "scene": 26, "line": "Ok.", "order": 8170, "speaker": "Michael Scott"},
  {"season": 3, "episode": 3, "scene": 26, "line": "I have to have an emergency crown put in.", "order": 8171, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 3, "scene": 26, "line": "Ouchy.", "order": 8172, "speaker": "Michael Scott"},
  {"season": 3, "episode": 3, "scene": 26, "line": "Yes. It's a new dentist. He's far. I might be gone... three hours.", "order": 8173, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 3, "scene": 26, "line": "Three hours, wow. Have fun. [Dwight walks away staring through the blinds at Michael]", "order": 8174, "speaker": "Michael Scott"},
  {"season": 3, "episode": 3, "scene": 27, "line": "Did you get anything good?", "order": 8175, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 3, "scene": 27, "line": "Yeah.", "order": 8176, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 3, "scene": 27, "line": "New blouse? Halter top? Camisole? Teddy?", "order": 8177, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 3, "scene": 28, "line": "I can save the branch.", "order": 8178, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 3, "scene": 28, "line": "Really?", "order": 8179, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 3, "scene": 28, "line": "If you let me run it.", "order": 8180, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 3, "scene": 28, "line": "Ok.", "order": 8181, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 3, "scene": 28, "line": "[excitedly] 'Ok' I can run it?", "order": 8182, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 3, "scene": 28, "line": "What would you do differently?", "order": 8183, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 3, "scene": 28, "line": "Mostly get rid of waste. Which is half the people there. And clean house. [to waitress in diner] Right here. It's all for me. Thank you.", "order": 8184, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 3, "scene": 28, "line": "Dwight, you must feel strongly, to speak with me this way, behind Michael's back, and turn on so many of your co-workers.", "order": 8185, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 3, "scene": 28, "line": "The decision to turn on Michael was difficult. [pouring way too much syrup on his food] But once I did it, I didn't look back. And mostly I feel that Michael would approve. It's really what's best for the branch. And I could care less about my co-workers. So [cutting his food, giving Jan a 'so there' look], here we are. It's all, on the table. I want... the branch. And I await your decision.", "order": 8186, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 3, "scene": 29, "line": "[shoveling waffles into his mouth] Oh, by the way. There's a new Ann Taylor outlet store near here. I know you like their earrings.", "order": 8187, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 3, "scene": 29, "line": "[thinks for a second] Where is it?", "order": 8188, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 3, "scene": 30, "line": "[on speakerphone with Michael] Michael.", "order": 8189, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 3, "scene": 30, "line": "Hi.", "order": 8190, "speaker": "Michael Scott"},
  {"season": 3, "episode": 3, "scene": 30, "line": "I just had a very interesting conversation with one of your employees.", "order": 8191, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 3, "scene": 30, "line": "Oh. That's nice.", "order": 8192, "speaker": "Michael Scott"},
  {"season": 3, "episode": 3, "scene": 30, "line": "No, it wasn't. Dwight just told me that he thinks he can run the branch better than you.", "order": 8193, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 3, "scene": 30, "line": "...what?", "order": 8194, "speaker": "Michael Scott"},
  {"season": 3, "episode": 3, "scene": 30, "line": "Mm-hmm.", "order": 8195, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 3, "scene": 30, "line": "...you were at the dentist?", "order": 8196, "speaker": "Michael Scott"},
  {"season": 3, "episode": 3, "scene": 30, "line": "You can't have people undermining you. Get control of your branch immediately.", "order": 8197, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 3, "scene": 30, "line": "Ok.", "order": 8198, "speaker": "Michael Scott"},
  {"season": 3, "episode": 3, "scene": 31, "line": "What was Dwight thinking? That he could turn Jan against me? She's my ex-lover! ...ish.", "order": 8199, "speaker": "Michael Scott"},
  {"season": 3, "episode": 3, "scene": 32, "line": "[Pam walks out of the bathroom wearing her new red shirt] Woooooow.", "order": 8200, "speaker": "Phyllis Lapin"},
  {"season": 3, "episode": 3, "scene": 32, "line": "It's", "order": 8201, "speaker": "Kelly Kapoor"},
  {"season": 3, "episode": 3, "scene": 32, "line": "It's really something.", "order": 8202, "speaker": "Phyllis Lapin"},
  {"season": 3, "episode": 3, "scene": 32, "line": "[smiling] It's too much.", "order": 8203, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 3, "scene": 32, "line": "What?", "order": 8204, "speaker": "Kelly Kapoor"},
  {"season": 3, "episode": 3, "scene": 32, "line": "I'm gonna return it.", "order": 8205, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 3, "scene": 32, "line": "No, you have to keep it today, just see how you feel.", "order": 8206, "speaker": "Kelly Kapoor"},
  {"season": 3, "episode": 3, "scene": 33, "line": "[walking into the break room] Hey.", "order": 8207, "speaker": "Roy Anderson"},
  {"season": 3, "episode": 3, "scene": 33, "line": "Hi.", "order": 8208, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 3, "scene": 33, "line": "You look nice.", "order": 8209, "speaker": "Roy Anderson"},
  {"season": 3, "episode": 3, "scene": 33, "line": "Thanks.", "order": 8210, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 3, "scene": 33, "line": "Isn't that like your third soda today?", "order": 8211, "speaker": "Kelly Kapoor"},
  {"season": 3, "episode": 3, "scene": 34, "line": "Yeah I think we can be a big help to your company. [someone dims the lights, signifying that it's game time] Ok, see you next week. Bye. [to Karen] Again?", "order": 8212, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 3, "scene": 34, "line": "Scared?", "order": 8213, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 3, "scene": 35, "line": "[with her hands making the rocker sign] Call of Duty!", "order": 8214, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 3, "scene": 36, "line": "[sounding upset] Andy, Jim, can I see you in the conference room for a minute?", "order": 8215, "speaker": "Josh Porter"},
  {"season": 3, "episode": 3, "scene": 36, "line": "Now?", "order": 8216, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 3, "scene": 36, "line": "Yes now.", "order": 8217, "speaker": "Josh Porter"},
  {"season": 3, "episode": 3, "scene": 36, "line": "Put the game on hold everyone.", "order": 8218, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 3, "scene": 37, "line": "This is not working, ok? We are getting slaughtered out there.", "order": 8219, "speaker": "Josh Porter"},
  {"season": 3, "episode": 3, "scene": 37, "line": "It's the new guy. [looks at Jim]", "order": 8220, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 3, "scene": 37, "line": "Oh, I'm sorry I don't know... what we're talking about...", "order": 8221, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 3, "scene": 37, "line": "See what I mean?", "order": 8222, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 3, "scene": 37, "line": "We just need a strategy, ok? We're going to set up a trap in the gun room. All right Jim, are you using the MP-40 or the 44?", "order": 8223, "speaker": "Josh Porter"},
  {"season": 3, "episode": 3, "scene": 37, "line": "Um, sniper rifle?", "order": 8224, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 3, "scene": 37, "line": "SNIPE--", "order": 8225, "speaker": "Josh Porter"},
  {"season": 3, "episode": 3, "scene": 37, "line": "WHAT?", "order": 8226, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 3, "scene": 37, "line": "JIM!", "order": 8227, "speaker": "Josh Porter"},
  {"season": 3, "episode": 3, "scene": 37, "line": "Are you playing for the other team?!", "order": 8228, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 3, "scene": 37, "line": "You don't snipe in Carrington, ok?", "order": 8229, "speaker": "Josh Porter"},
  {"season": 3, "episode": 3, "scene": 37, "line": "Saboteur!", "order": 8230, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 3, "scene": 37, "line": "Andy, it's not---", "order": 8231, "speaker": "Josh Porter"},
  {"season": 3, "episode": 3, "scene": 37, "line": "Saboteur! I'm going to kill you for real. This game--- the game is over. I'm really going to shoot you.", "order": 8232, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 3, "scene": 38, "line": "Hey Dwight.", "order": 8233, "speaker": "Michael Scott"},
  {"season": 3, "episode": 3, "scene": 38, "line": "Hey.", "order": 8234, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 3, "scene": 38, "line": "Do you want an M&M?", "order": 8235, "speaker": "Michael Scott"},
  {"season": 3, "episode": 3, "scene": 38, "line": "No thanks I'm stuffed.", "order": 8236, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 3, "scene": 38, "line": "No seriously. You should have an M&M, they're really good.", "order": 8237, "speaker": "Michael Scott"},
  {"season": 3, "episode": 3, "scene": 38, "line": "...ok. [takes and eats several M&M's]", "order": 8238, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 3, "scene": 38, "line": "They're good, huh?", "order": 8239, "speaker": "Michael Scott"},
  {"season": 3, "episode": 3, "scene": 38, "line": "...so good.", "order": 8240, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 3, "scene": 38, "line": "Hey. I thought you weren't supposed to eat anything for a couple hours after you've had a crown put in.", "order": 8241, "speaker": "Michael Scott"},
  {"season": 3, "episode": 3, "scene": 38, "line": "They have this new kind of quick drying bonding, so...", "order": 8242, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 3, "scene": 38, "line": "Sounds like a good dentist.", "order": 8243, "speaker": "Michael Scott"},
  {"season": 3, "episode": 3, "scene": 38, "line": "Oh, yeah.", "order": 8244, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 3, "scene": 38, "line": "What's his name?", "order": 8245, "speaker": "Michael Scott"},
  {"season": 3, "episode": 3, "scene": 38, "line": "[long pause] Crentist.", "order": 8246, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 3, "scene": 38, "line": "Your dentist's name is Crentist.", "order": 8247, "speaker": "Michael Scott"},
  {"season": 3, "episode": 3, "scene": 38, "line": "Yep.", "order": 8248, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 3, "scene": 38, "line": "Huh. Sounds a lot like dentist.", "order": 8249, "speaker": "Michael Scott"},
  {"season": 3, "episode": 3, "scene": 38, "line": "Maybe that's why he became a dentist.", "order": 8250, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 3, "scene": 39, "line": "Let me see your teeth. Let me see 'em. Let me see 'em.", "order": 8251, "speaker": "Michael Scott"},
  {"season": 3, "episode": 3, "scene": 39, "line": "[reluctantly opens his mouth and Michael peers inside] Ahh. Aaaah.", "order": 8252, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 3, "scene": 39, "line": "You should... floss.", "order": 8253, "speaker": "Michael Scott"},
  {"season": 3, "episode": 3, "scene": 39, "line": "I know...", "order": 8254, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 3, "scene": 39, "line": "[exhales] Well then... I am glad you're ok.", "order": 8255, "speaker": "Michael Scott"},
  {"season": 3, "episode": 3, "scene": 40, "line": "Business is like a jungle. And I... am like a tiger. And Dwight, is like a monkey, that stabs the tiger in the back with a stick. Does the tiger fire the monkey? Does the tiger transfer the monkey to another branch? [smiling] Pun. There is no way of knowing what goes on inside the tiger's head. We don't have the technology.", "order": 8256, "speaker": "Michael Scott"},
  {"season": 3, "episode": 3, "scene": 41, "line": "Dwight, can I talk to you for a second?", "order": 8257, "speaker": "Michael Scott"},
  {"season": 3, "episode": 3, "scene": 42, "line": "[in Michael's office] Wow, this is tough.", "order": 8258, "speaker": "Michael Scott"},
  {"season": 3, "episode": 3, "scene": 42, "line": "What?", "order": 8259, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 3, "scene": 42, "line": "Ohhhh. Arrrgggh!", "order": 8260, "speaker": "Michael Scott"},
  {"season": 3, "episode": 3, "scene": 42, "line": "What is it?", "order": 8261, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 3, "scene": 42, "line": "Well, I just got off the phone with Jan. And um... she demoted me.", "order": 8262, "speaker": "Michael Scott"},
  {"season": 3, "episode": 3, "scene": 42, "line": "No.", "order": 8263, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 3, "scene": 42, "line": "Yeah. You know what the craziest part of this is? She demoted me to your job!", "order": 8264, "speaker": "Michael Scott"},
  {"season": 3, "episode": 3, "scene": 42, "line": "Gahh!", "order": 8265, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 3, "scene": 42, "line": "And she said that you should be expecting a call later from corporate, and that um, I guess that means that you are going to be acting manager of Dunder Mifflin, Scranton.", "order": 8266, "speaker": "Michael Scott"},
  {"season": 3, "episode": 3, "scene": 42, "line": "I can't believe this news. That--- wow.", "order": 8267, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 3, "scene": 42, "line": "I know. I told her I didn't know whether you'd wanna do it... because you've always been so loyal to me. You've been my most trusted ally.", "order": 8268, "speaker": "Michael Scott"},
  {"season": 3, "episode": 3, "scene": 42, "line": "You said that?", "order": 8269, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 3, "scene": 42, "line": "Yep, I did. I did. But I think... you should do it.", "order": 8270, "speaker": "Michael Scott"},
  {"season": 3, "episode": 3, "scene": 42, "line": "Well... Gosh, if you think I should. Then I will.", "order": 8271, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 3, "scene": 42, "line": "[exhales] Perfect, well, we're settled.", "order": 8272, "speaker": "Michael Scott"},
  {"season": 3, "episode": 3, "scene": 42, "line": "All right.", "order": 8273, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 3, "scene": 42, "line": "All right. Well then, you are now acting manager of Dunder Mifflin Scranton Branch, and I... am assistant regional manager.", "order": 8274, "speaker": "Michael Scott"},
  {"season": 3, "episode": 3, "scene": 42, "line": "Assistant to the regional manager. Thank you Michael, for staying on. I really appreciate it.", "order": 8275, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 3, "scene": 42, "line": "Ohh..", "order": 8276, "speaker": "Michael Scott"},
  {"season": 3, "episode": 3, "scene": 42, "line": "Hey... I can't imagine this place without you.", "order": 8277, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 3, "scene": 42, "line": "[muttering] Can't you? That's so nice.", "order": 8278, "speaker": "Michael Scott"},
  {"season": 3, "episode": 3, "scene": 43, "line": "Well... I guess we should go tell the troops.", "order": 8279, "speaker": "Michael Scott"},
  {"season": 3, "episode": 3, "scene": 43, "line": "Yeah, when I'm ready, Mike. [Dwight exhales, Michael looks at the camera] Ok let's do it.", "order": 8280, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 3, "scene": 44, "line": "Hey, hi, hello. Everybody, I have some good news, and I have some bad news. I... am being replaced, as your leader, by Dwight.", "order": 8281, "speaker": "Michael Scott"},
  {"season": 3, "episode": 3, "scene": 44, "line": "You're kidding...", "order": 8282, "speaker": "Phyllis Lapin"},
  {"season": 3, "episode": 3, "scene": 44, "line": "You might think that I am kidding, and I understand that.", "order": 8283, "speaker": "Michael Scott"},
  {"season": 3, "episode": 3, "scene": 44, "line": "Congratulations Dwight.", "order": 8284, "speaker": "Angela Martin"},
  {"season": 3, "episode": 3, "scene": 44, "line": "Thank you Angela.", "order": 8285, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 3, "scene": 44, "line": "But... why Dwight?", "order": 8286, "speaker": "Stanley Hudson"},
  {"season": 3, "episode": 3, "scene": 44, "line": "Because, Dwight... never lies.", "order": 8287, "speaker": "Michael Scott"},
  {"season": 3, "episode": 3, "scene": 44, "line": "How does that qualify him to run a branch?", "order": 8288, "speaker": "Stanley Hudson"},
  {"season": 3, "episode": 3, "scene": 44, "line": "Because that's all it takes.", "order": 8289, "speaker": "Michael Scott"},
  {"season": 3, "episode": 3, "scene": 45, "line": "Ok, Dwight, would you like to say a few words to everybody... about loyalty?", "order": 8290, "speaker": "Michael Scott"},
  {"season": 3, "episode": 3, "scene": 45, "line": "Thank you Michael. I just want to say, to the few of you who will remain under my employ, that I intend to lead you into the black! With ferocity!", "order": 8291, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 3, "scene": 46, "line": "Michael, what will you do?", "order": 8292, "speaker": "Phyllis Lapin"},
  {"season": 3, "episode": 3, "scene": 46, "line": "Oh, I'll be fine.", "order": 8293, "speaker": "Michael Scott"},
  {"season": 3, "episode": 3, "scene": 46, "line": "Do you have any savings?", "order": 8294, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 3, "scene": 46, "line": "No, no, I don't, but---", "order": 8295, "speaker": "Michael Scott"},
  {"season": 3, "episode": 3, "scene": 46, "line": "Michael, you might lose your condo.", "order": 8296, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 3, "scene": 46, "line": "I... won't. I won't.", "order": 8297, "speaker": "Michael Scott"},
  {"season": 3, "episode": 3, "scene": 47, "line": "I have this little vacuum cleaner that's broken. If Dwight doesn't work out, maybe that could be manager.", "order": 8298, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 3, "scene": 48, "line": "Maybe I'll quit.", "order": 8299, "speaker": "Phyllis Lapin"},
  {"season": 3, "episode": 3, "scene": 49, "line": "It's really happening!", "order": 8300, "speaker": "Angela Martin"},
  {"season": 3, "episode": 3, "scene": 49, "line": "Yes.", "order": 8301, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 3, "scene": 49, "line": "We can make a difference here.", "order": 8302, "speaker": "Angela Martin"},
  {"season": 3, "episode": 3, "scene": 49, "line": "I", "order": 8303, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 3, "scene": 49, "line": "You alone? Because I thought together we could---", "order": 8304, "speaker": "Angela Martin"},
  {"season": 3, "episode": 3, "scene": 49, "line": "Oh please, don't be naive. But you can be in charge of the women.", "order": 8305, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 3, "scene": 50, "line": "[whispering] Look how cute he is! He's trying to shoot with a smoke grenade.", "order": 8306, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 3, "scene": 50, "line": "I'm sorry what are you whispering about?", "order": 8307, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 3, "scene": 50, "line": "I'm sorry nothing, just concentrate on turning around.", "order": 8308, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 3, "scene": 50, "line": "I'm trying---", "order": 8309, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 3, "scene": 50, "line": "Just tap S then D.", "order": 8310, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 3, "scene": 50, "line": "Oh.", "order": 8311, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 3, "scene": 50, "line": "[her players gun is pointed directly at Jim's player's head] Any... last words? No?", "order": 8312, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 3, "scene": 50, "line": "What? [Karen's player shoots Jim's player, 'You killed Jim Halpert' appears on the screen, Karen giggles]", "order": 8313, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 3, "scene": 50, "line": "Wow. Psychopath. [Karen grins widely at Jim]", "order": 8314, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 3, "scene": 51, "line": "What?", "order": 8315, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 3, "scene": 51, "line": "I'm just looking.", "order": 8316, "speaker": "Creed Bratton"},
  {"season": 3, "episode": 3, "scene": 51, "line": "Please go back to your desk.", "order": 8317, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 3, "scene": 51, "line": "In a minute.", "order": 8318, "speaker": "Creed Bratton"},
  {"season": 3, "episode": 3, "scene": 52, "line": "Well, I remember why I dress the way I do at work. But I'm gonna keep the clothes. I mean, it'll be cool to just have some after work clothes that aren't pajamas.", "order": 8319, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 3, "scene": 53, "line": "Well, I guess it's time, that I turn over the keys, to the famous Sebring.", "order": 8320, "speaker": "Michael Scott"},
  {"season": 3, "episode": 3, "scene": 53, "line": "[smiling] No thank you.", "order": 8321, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 3, "scene": 53, "line": "It's a corporate lease Dwight, you've earned it.", "order": 8322, "speaker": "Michael Scott"},
  {"season": 3, "episode": 3, "scene": 53, "line": "No thanks.", "order": 8323, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 3, "scene": 53, "line": "What?", "order": 8324, "speaker": "Michael Scott"},
  {"season": 3, "episode": 3, "scene": 53, "line": "Not my style.", "order": 8325, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 3, "scene": 53, "line": "But you said you liked it. You've always admired it.", "order": 8326, "speaker": "Michael Scott"},
  {"season": 3, "episode": 3, "scene": 53, "line": "Well that was before. I'm thinking about getting something German, something with decent gas mileage. Plus, that convertible, it's a ridiculous choice for this climate.", "order": 8327, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 3, "scene": 53, "line": "[Michael finally breaks] Take it back.", "order": 8328, "speaker": "Michael Scott"},
  {"season": 3, "episode": 3, "scene": 53, "line": "[confused] No.", "order": 8329, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 3, "scene": 53, "line": "That's my car.", "order": 8330, "speaker": "Michael Scott"},
  {"season": 3, "episode": 3, "scene": 53, "line": "What did you---", "order": 8331, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 3, "scene": 53, "line": "THAT'S MY CAR.", "order": 8332, "speaker": "Michael Scott"},
  {"season": 3, "episode": 3, "scene": 53, "line": "Yes.", "order": 8333, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 3, "scene": 54, "line": "I know, Dwight. I know. I know. I know.", "order": 8334, "speaker": "Michael Scott"},
  {"season": 3, "episode": 3, "scene": 54, "line": "You know what?", "order": 8335, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 3, "scene": 54, "line": "Jan called me about your little meeting!", "order": 8336, "speaker": "Michael Scott"},
  {"season": 3, "episode": 3, "scene": 54, "line": "No!", "order": 8337, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 3, "scene": 54, "line": "I know, what you did. I made the whole thing up, Dwight.", "order": 8338, "speaker": "Michael Scott"},
  {"season": 3, "episode": 3, "scene": 54, "line": "I think the Sebring's cool. It's cool. The Seabring's cool. It has a cassette and it has a CD.", "order": 8339, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 3, "scene": 54, "line": "Oh do you? Do you like the Sebring--- HOW DARE YOU?! How dare you, Dwight?", "order": 8340, "speaker": "Michael Scott"},
  {"season": 3, "episode": 3, "scene": 54, "line": "[Dwight gets on his knees and puts his face down on the ground] Don't fire me. Please.", "order": 8341, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 3, "scene": 54, "line": "Give me one good reason why I should fire you RIGHT HERE ON THE SPOT?!", "order": 8342, "speaker": "Michael Scott"},
  {"season": 3, "episode": 3, "scene": 54, "line": "[terrified] I HAVE EXCELLENT SALES NUMBERS!", "order": 8343, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 3, "scene": 54, "line": "Not. Good. Enough!", "order": 8344, "speaker": "Michael Scott"},
  {"season": 3, "episode": 3, "scene": 54, "line": "[sobbing] I'll do anything! Anything! I'll do your laundry for a month! For a year!", "order": 8345, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 3, "scene": 54, "line": "I have a laundry machine!", "order": 8346, "speaker": "Michael Scott"},
  {"season": 3, "episode": 3, "scene": 54, "line": "I'm sorry! I'll do anything, I swear. [squeaking, crying]", "order": 8347, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 3, "scene": 54, "line": "I don't know if I can trust you anymore.", "order": 8348, "speaker": "Michael Scott"},
  {"season": 3, "episode": 3, "scene": 54, "line": "You can't. You can't. But I promise I'll never betray you again. What can I do, Michael. What can I do? [Michael exhales] What can I do? [Dwight has his face to the ground but his butt raised high in the air] What can I do? What can I do?", "order": 8349, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 3, "scene": 54, "line": "You can get up. Get up. [Dwight gets up with spit hanging from his lips] And you can hug it out, bitch. [Dwight and Michael have a firm embrace]", "order": 8350, "speaker": "Michael Scott"},
  {"season": 3, "episode": 3, "scene": 55, "line": "Hug it out, bitch. That is what men say to each other, after a fight. They hug it out, and doing so, the just... let it go. And walk away. And they're done. Not a good idea to say that to a woman however. I have found. It doesn't translate.", "order": 8351, "speaker": "Michael Scott"},
  {"season": 3, "episode": 3, "scene": 56, "line": "[leaving his desk for the day, pretends pull the pen out of a pretend grenade, and tosses it to Karen]", "order": 8352, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 3, "scene": 56, "line": "[throws some desk material in the air to signify debris from the grenade explosion, grins as Jim walks away]", "order": 8353, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 3, "scene": 57, "line": "Yep. Yeah, we hugged it out. But, it turns out, I was still a little angry. So, I felt I needed to punish him, just a little bit more, [cut to Dwight standing on a box with 'LIAR' written on a piece of paper hung around his neck, Dwight's head hung in shame] and I'm making him do my laundry for a year.", "order": 8354, "speaker": "Michael Scott"},
  {"season": 3, "episode": 3, "scene": 58, "line": "Okay. [Jan leaves Michael's office; Dwight enters]", "order": 8355, "speaker": "Michael Scott"},
  {"season": 3, "episode": 3, "scene": 58, "line": "Hey. [shuts door] Is everything okay?", "order": 8356, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 3, "scene": 58, "line": "It is not your business, Dwight.", "order": 8357, "speaker": "Michael Scott"},
  {"season": 3, "episode": 3, "scene": 58, "line": "If the branch is in trouble, there are things you can do.", "order": 8358, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 3, "scene": 58, "line": "What do you know about management, Dwight?", "order": 8359, "speaker": "Michael Scott"},
  {"season": 3, "episode": 3, "scene": 58, "line": "I know that we need to cut costs. We could fire Meredith. That's a big cost.", "order": 8360, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 3, "scene": 58, "line": "I'm not firing anyone.", "order": 8361, "speaker": "Michael Scott"},
  {"season": 3, "episode": 3, "scene": 58, "line": "Creed might be living here, we could charge him rent.", "order": 8362, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 3, "scene": 58, "line": "... What?", "order": 8363, "speaker": "Michael Scott"},
  {"season": 3, "episode": 3, "scene": 59, "line": "Four nights a week, I sleep under my desk, and then three nights I stay at my place in Toronto. They don't know about this job up there. It's a welfare state. Beautiful countries, both of 'em.", "order": 8364, "speaker": "Creed Bratton"},
  {"season": 3, "episode": 3, "scene": 60, "line": "Okay. Here we go. Everybody, you know what? It dawned on me we were all in there watching a movie together before we were so rudely interrupted, Let's get back in there. [no takers] Come on. Movie Monday! Come on, Stanley let's go.", "order": 8365, "speaker": "Michael Scott"},
  {"season": 3, "episode": 3, "scene": 60, "line": "[standing at the supply shelf] I'm on the phone.", "order": 8366, "speaker": "Stanley Hudson"},
  {"season": 3, "episode": 3, "scene": 60, "line": "Ryan?", "order": 8367, "speaker": "Michael Scott"},
  {"season": 3, "episode": 3, "scene": 60, "line": "I'm pretty sure Jan said that we're not supposed to.", "order": 8368, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 3, "scene": 60, "line": "Okay, well, we had scheduled this time to watch a movie together, so I guess I will just go play some online Scrabble.", "order": 8369, "speaker": "Michael Scott"},
  {"season": 3, "episode": 3, "scene": 60, "line": "[looks to Angela]", "order": 8370, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 3, "scene": 60, "line": "[shakes her head disapprovingly]", "order": 8371, "speaker": "Angela Martin"},
  {"season": 3, "episode": 3, "scene": 61, "line": "The Schrutes are a very loyal breed. But I also have Mannheim blood from my German grandmother. And the Mannheims knew when to cut and run. No sense going down with a losing regime. But the Schrute blood... It's amazing that when these two bloods mix, the whole thing didn't explode.", "order": 8372, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 3, "scene": 62, "line": "So, she sleeps with him, but she is still in love with the head surgeon, um, the McDreamy guy, and she starts crying, but the dorky doctor, he's in love with her... [phone rings]", "order": 8373, "speaker": "Michael Scott"},
  {"season": 3, "episode": 3, "scene": 62, "line": "Dunder Mifflin, this is Pam. ... Uh, sure, I'll get him for you. [to Michael] It's Jan for you.", "order": 8374, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 3, "scene": 62, "line": "Really?", "order": 8375, "speaker": "Michael Scott"},
  {"season": 3, "episode": 3, "scene": 62, "line": "Yeah.", "order": 8376, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 3, "scene": 63, "line": "I may have a few weeks ago faked a call from Jan in order to get out of a synopsis of, I think it was, Navy: NCIS. Since then, Michael's been suspicious.", "order": 8377, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 3, "scene": 64, "line": "Nnnnice. Wow!", "order": 8378, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 3, "scene": 65, "line": "[playing Call of Duty] Oh? Someone's left out in the open, taking a little siesta. Not smart. [shoots] Yes! Finally killed you... [Karen's desk is empty]", "order": 8379, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 3, "scene": 65, "line": "Hey, Jim?", "order": 8380, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 3, "scene": 65, "line": "Hey, Jan.", "order": 8381, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 3, "scene": 65, "line": "How's work going?", "order": 8382, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 3, "scene": 65, "line": "[nervously] Pretty good.", "order": 8383, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 3, "scene": 65, "line": "[kills Jim's character, giggles] Oh, you know what, I gotta see Josh right now, but remind me to tell you what Dwight said to me earlier, okay? You'll get a big kick out of it. [leaves]", "order": 8384, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 3, "scene": 65, "line": "[baffled expression]", "order": 8385, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 3, "scene": 66, "line": "Things are a little bit... different here in Stamford. [shrug] It's not bad.", "order": 8386, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 4, "scene": 1, "line": "Hey Ryan, can I get you a pencil from the warehouse?", "order": 8387, "speaker": "Michael Scott"},
  {"season": 3, "episode": 4, "scene": 1, "line": "Uh, no thanks, I'm good.", "order": 8388, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 4, "scene": 1, "line": "Oh, it's okay, I'm going down.", "order": 8389, "speaker": "Michael Scott"},
  {"season": 3, "episode": 4, "scene": 1, "line": "Um... Yeah, absolutely.", "order": 8390, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 4, "scene": 1, "line": "All right, I'll be right back. [goes down pretend stairs]", "order": 8391, "speaker": "Michael Scott"},
  {"season": 3, "episode": 4, "scene": 1, "line": "[laughs uproariously and applauds] Whoo!", "order": 8392, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 4, "scene": 1, "line": "[hands Ryan pencil] There you go.", "order": 8393, "speaker": "Michael Scott"},
  {"season": 3, "episode": 4, "scene": 1, "line": "Awesome!", "order": 8394, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 4, "scene": 1, "line": "Thank you.", "order": 8395, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 4, "scene": 1, "line": "You're welcome.", "order": 8396, "speaker": "Michael Scott"},
  {"season": 3, "episode": 4, "scene": 1, "line": "Michael, can you get me a pen from down in the warehouse?", "order": 8397, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 4, "scene": 1, "line": "Don't mind if I do. See you in a minute. [goes back down pretend stairs and grabs pen from Stanley's desk]", "order": 8398, "speaker": "Michael Scott"},
  {"season": 3, "episode": 4, "scene": 1, "line": "Okay. [continues to laugh] Whoo!", "order": 8399, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 4, "scene": 1, "line": "There you go, fresh from the warehouse.", "order": 8400, "speaker": "Michael Scott"},
  {"season": 3, "episode": 4, "scene": 1, "line": "Hey Michael, would you get me some coffee from the warehouse?", "order": 8401, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 4, "scene": 1, "line": "There's coffee in the kitchen, Pam.", "order": 8402, "speaker": "Michael Scott"},
  {"season": 3, "episode": 4, "scene": 1, "line": "But the warehouse coffee tastes SO much better.", "order": 8403, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 4, "scene": 1, "line": "Yeah. Yes, it's better. It's great.", "order": 8404, "speaker": "Ryan Howard & others"},
  {"season": 3, "episode": 4, "scene": 1, "line": "[breathless] All right. Okay. [goes back down pretend stairs, crawls on belly to the kitchen for the coffee]", "order": 8405, "speaker": "Michael Scott"},
  {"season": 3, "episode": 4, "scene": 2, "line": "I am like Bette Midler in For the Boys. Gotta keep the troops entertained.", "order": 8406, "speaker": "Michael Scott"},
  {"season": 3, "episode": 4, "scene": 3, "line": "[after Michael returns with coffee] With cream and sugar?", "order": 8407, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 4, "scene": 3, "line": "[sighs] All right.", "order": 8408, "speaker": "Michael Scott"},
  {"season": 3, "episode": 4, "scene": 4, "line": "[on speakerphone] So, I wanted to let you know that we lost Ed Truck.", "order": 8409, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 4, "scene": 4, "line": "Oookay. Let me see if I have his cell. Is this the only reason you are calling, Jan? Or does somebody miss me?", "order": 8410, "speaker": "Michael Scott"},
  {"season": 3, "episode": 4, "scene": 4, "line": "Michael, Ed died over the weekend.", "order": 8411, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 4, "scene": 4, "line": "Oh, wow.", "order": 8412, "speaker": "Michael Scott"},
  {"season": 3, "episode": 4, "scene": 5, "line": "Attention, everybody. I just received a call from corporate with some news they felt that I should know first. My old boss Ed Truck has died.", "order": 8413, "speaker": "Michael Scott"},
  {"season": 3, "episode": 4, "scene": 5, "line": "Oh, Michael, that's such terrible news! You must feel so sad.", "order": 8414, "speaker": "Kelly Kapoor"},
  {"season": 3, "episode": 4, "scene": 5, "line": "Yes, I am. It's very sad. Because he was my boss.", "order": 8415, "speaker": "Michael Scott"},
  {"season": 3, "episode": 4, "scene": 5, "line": "That's a shame. Ed was a good guy.", "order": 8416, "speaker": "Phyllis Lapin"},
  {"season": 3, "episode": 4, "scene": 5, "line": "That's right, you worked with him. So did Creed. Well, I'll be in my office in case anybody wants to drop by. Cheer me up.", "order": 8417, "speaker": "Michael Scott"},
  {"season": 3, "episode": 4, "scene": 6, "line": "So did you hear the news?", "order": 8418, "speaker": "Michael Scott"},
  {"season": 3, "episode": 4, "scene": 6, "line": "The news that you just announced? That Ed died?", "order": 8419, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 4, "scene": 6, "line": "Yeah.", "order": 8420, "speaker": "Michael Scott"},
  {"season": 3, "episode": 4, "scene": 6, "line": "Is there anything I can do?", "order": 8421, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 4, "scene": 6, "line": "Oh, gosh, what can anybody do, really? It's... pssssh... . He was almost 70. Circle of life.", "order": 8422, "speaker": "Michael Scott"},
  {"season": 3, "episode": 4, "scene": 6, "line": "Yeah.", "order": 8423, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 4, "scene": 6, "line": "[holds out arms to Pam]", "order": 8424, "speaker": "Michael Scott"},
  {"season": 3, "episode": 4, "scene": 6, "line": "Oh. Okay.", "order": 8425, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 4, "scene": 6, "line": "Yeah. Mmmmmm.", "order": 8426, "speaker": "Michael Scott"},
  {"season": 3, "episode": 4, "scene": 6, "line": "[extricating herself] Okay.", "order": 8427, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 4, "scene": 7, "line": "What's going on with Fairfield County schools? Karen, did you generate that price list?", "order": 8428, "speaker": "Josh Porter"},
  {"season": 3, "episode": 4, "scene": 7, "line": "Um, shoot. Uh, I will. Sorry.", "order": 8429, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 4, "scene": 7, "line": "Okay, just get it done. Jim, will you make sure?", "order": 8430, "speaker": "Josh Porter"},
  {"season": 3, "episode": 4, "scene": 7, "line": "Oh yeah, definitely.", "order": 8431, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 4, "scene": 7, "line": "[coughing out his words] Suck up! Josh, did you hear what I said?", "order": 8432, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 4, "scene": 7, "line": "Thank you, everyone.", "order": 8433, "speaker": "Josh Porter"},
  {"season": 3, "episode": 4, "scene": 8, "line": "[at vending machine] Dammit.", "order": 8434, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 4, "scene": 8, "line": "What's up?", "order": 8435, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 4, "scene": 8, "line": "Uh, nothing. They're just out of Herr's chips.", "order": 8436, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 4, "scene": 8, "line": "Oh.", "order": 8437, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 4, "scene": 8, "line": "But don't worry about it. My snack food doesn't fall under the umbrella of your authority.", "order": 8438, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 4, "scene": 8, "line": "Mmm, that's where you're wrong. I'm your project supervisor today, and I have just decided that we're not doing anything until you get the chips that you require. So, I think we should go get some. Now, please.", "order": 8439, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 4, "scene": 9, "line": "It's a real shame about Ed, huh?", "order": 8440, "speaker": "Creed Bratton"},
  {"season": 3, "episode": 4, "scene": 9, "line": "Yeah. Must really have you thinkin'.", "order": 8441, "speaker": "Michael Scott"},
  {"season": 3, "episode": 4, "scene": 9, "line": "About what?", "order": 8442, "speaker": "Creed Bratton"},
  {"season": 3, "episode": 4, "scene": 9, "line": "The older you get, the bigger the chance is you're gonna die. You knew that.", "order": 8443, "speaker": "Michael Scott"},
  {"season": 3, "episode": 4, "scene": 9, "line": "Ed was decapitated.", "order": 8444, "speaker": "Creed Bratton"},
  {"season": 3, "episode": 4, "scene": 9, "line": "What?", "order": 8445, "speaker": "Michael Scott"},
  {"season": 3, "episode": 4, "scene": 9, "line": "Really?", "order": 8446, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 4, "scene": 9, "line": "He was drunk as a skunk, he was flying down Route 6. He slides under an 18 wheeler. Pop. It snaps right off.", "order": 8447, "speaker": "Creed Bratton"},
  {"season": 3, "episode": 4, "scene": 9, "line": "Oh my God.", "order": 8448, "speaker": "Michael Scott"},
  {"season": 3, "episode": 4, "scene": 9, "line": "That is the way to go. Instant death. Very smart.", "order": 8449, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 4, "scene": 9, "line": "You know a human can go on living for several hours after being decapitated.", "order": 8450, "speaker": "Creed Bratton"},
  {"season": 3, "episode": 4, "scene": 9, "line": "You're thinking of a chicken.", "order": 8451, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 4, "scene": 9, "line": "What did I say?", "order": 8452, "speaker": "Creed Bratton"},
  {"season": 3, "episode": 4, "scene": 10, "line": "That is just not the way a Dunder Mifflin manager should go, I'm sorry. Alone, out of the blue, and not even have his own head to comfort him.", "order": 8453, "speaker": "Michael Scott"},
  {"season": 3, "episode": 4, "scene": 11, "line": "[clears throat] So, I'm not exactly sure how to say this...", "order": 8454, "speaker": "Michael Scott"},
  {"season": 3, "episode": 4, "scene": 11, "line": "Ed was decapitated.", "order": 8455, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 4, "scene": 11, "line": "What are you doing?", "order": 8456, "speaker": "Michael Scott"},
  {"season": 3, "episode": 4, "scene": 11, "line": "You said you didn't know how to say it.", "order": 8457, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 4, "scene": 11, "line": "I didn't... he was driving on the road and he went under a truck. And that's when his head was separated from the rest of him. And I will let you know more as soon as I find out.", "order": 8458, "speaker": "Michael Scott"},
  {"season": 3, "episode": 4, "scene": 12, "line": "Hey.", "order": 8459, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 4, "scene": 12, "line": "Hi.", "order": 8460, "speaker": "Angela Martin"},
  {"season": 3, "episode": 4, "scene": 12, "line": "If my head ever comes off, I would like you to put it on ice.", "order": 8461, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 4, "scene": 12, "line": "I do not wanna talk about this.", "order": 8462, "speaker": "Angela Martin"},
  {"season": 3, "episode": 4, "scene": 13, "line": "When I die, I wanna be frozen. And if they have to freeze me in pieces, so be it. I will wake up stronger than ever because I will have used that time to figure out exactly why I died and what moves I could have used to defend myself better now that I know what hold he had me in.", "order": 8463, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 4, "scene": 14, "line": "I don't understand. We have a day honoring Martin Luther King, but he didn't even work here.", "order": 8464, "speaker": "Michael Scott"},
  {"season": 3, "episode": 4, "scene": 14, "line": "[on speakerphone] I understand how you feel, Michael. I really do. So, would it be helpful to give everyone the day off?", "order": 8465, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 4, "scene": 14, "line": "You really don't get it, do you? You don't understand these people. That is the last thing that they would want is a day off.", "order": 8466, "speaker": "Michael Scott"},
  {"season": 3, "episode": 4, "scene": 14, "line": "Well, what would you suggest?", "order": 8467, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 4, "scene": 14, "line": "[thoughtfully] A statue.", "order": 8468, "speaker": "Michael Scott"},
  {"season": 3, "episode": 4, "scene": 14, "line": "Of Ed?", "order": 8469, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 4, "scene": 14, "line": "Yeah.", "order": 8470, "speaker": "Michael Scott"},
  {"season": 3, "episode": 4, "scene": 14, "line": "[scoffs] I'm not sure that's realistic.", "order": 8471, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 4, "scene": 14, "line": "Well, I think it would be very realistic, it would look just like him.", "order": 8472, "speaker": "Michael Scott"},
  {"season": 3, "episode": 4, "scene": 14, "line": "No, that's not---", "order": 8473, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 4, "scene": 14, "line": "We could have his eyes light up, we could have his arms move...", "order": 8474, "speaker": "Michael Scott"},
  {"season": 3, "episode": 4, "scene": 14, "line": "That is not a statue, that is a robot.", "order": 8475, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 4, "scene": 14, "line": "I think that is a great way to honor Ed.", "order": 8476, "speaker": "Michael Scott"},
  {"season": 3, "episode": 4, "scene": 14, "line": "And how big do you want this robot?", "order": 8477, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 4, "scene": 14, "line": "Life-size.", "order": 8478, "speaker": "Michael Scott"},
  {"season": 3, "episode": 4, "scene": 14, "line": "Mm, no. Better make it two-thirds. Easier to stop if it turns on us.", "order": 8479, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 4, "scene": 14, "line": "What the hell are you two talking about?", "order": 8480, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 4, "scene": 14, "line": "Well, we are talking about how to properly honor a man who gave his life as regional manager of this company, Jan.", "order": 8481, "speaker": "Michael Scott"},
  {"season": 3, "episode": 4, "scene": 14, "line": "You know what, Michael? I've really tried with you today...", "order": 8482, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 4, "scene": 14, "line": "Mm hm.", "order": 8483, "speaker": "Michael Scott"},
  {"season": 3, "episode": 4, "scene": 14, "line": "...and I have to get back to work.", "order": 8484, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 4, "scene": 14, "line": "Oh do you? You know who wished they could get back to work is Ed Truck.", "order": 8485, "speaker": "Michael Scott"},
  {"season": 3, "episode": 4, "scene": 14, "line": "So call me when you feel like having a real conversation.", "order": 8486, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 4, "scene": 14, "line": "But Ed truck can't because he is---", "order": 8487, "speaker": "Michael Scott"},
  {"season": 3, "episode": 4, "scene": 14, "line": "Goodbye. [hangs up]", "order": 8488, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 4, "scene": 14, "line": "DEAD.", "order": 8489, "speaker": "Michael Scott"},
  {"season": 3, "episode": 4, "scene": 14, "line": "Look [holding up sketch] I gave him a six foot extension cord so he can't chase us.", "order": 8490, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 4, "scene": 14, "line": "That's perfect.", "order": 8491, "speaker": "Michael Scott"},
  {"season": 3, "episode": 4, "scene": 15, "line": "Okay, that is a 'no' on the on the West Side Market.", "order": 8492, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 4, "scene": 15, "line": "Okay, great. I think that's enough. Can I get back to work now?", "order": 8493, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 4, "scene": 15, "line": "Wow. Never pegged you for a quitter.", "order": 8494, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 4, "scene": 15, "line": "I am NOT a quitter. I will do this all day if you want.", "order": 8495, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 4, "scene": 15, "line": "Really?", "order": 8496, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 4, "scene": 15, "line": "Yeah, all day.", "order": 8497, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 4, "scene": 15, "line": "Hey. What are we doing? What's the game? I want in.", "order": 8498, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 4, "scene": 15, "line": "Oh, there's no game. We're just trying to get these chips for Karen.", "order": 8499, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 4, "scene": 15, "line": "Did you check the vending machine?", "order": 8500, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 4, "scene": 15, "line": "Oh, the vending machines! How did we miss that?", "order": 8501, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 4, "scene": 15, "line": "I have no idea. We went right for the copier.", "order": 8502, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 4, "scene": 15, "line": "Mm.", "order": 8503, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 4, "scene": 15, "line": "And then we checked the fax machine.", "order": 8504, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 4, "scene": 15, "line": "Yeah, nothing there.", "order": 8505, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 4, "scene": 15, "line": "Did you check your... butt?", "order": 8506, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 4, "scene": 16, "line": "Oohh... can you imagine how much blood there was? If it happened right here, it would reach all the way to reception. Probably get on Pam.", "order": 8507, "speaker": "Michael Scott"},
  {"season": 3, "episode": 4, "scene": 16, "line": "Okay that's enough.", "order": 8508, "speaker": "Phyllis Lapin"},
  {"season": 3, "episode": 4, "scene": 16, "line": "What?", "order": 8509, "speaker": "Michael Scott"},
  {"season": 3, "episode": 4, "scene": 16, "line": "We do not wanna hear about this.", "order": 8510, "speaker": "Stanley Hudson"},
  {"season": 3, "episode": 4, "scene": 16, "line": "Well, you know what? I didn't wanna hear about it either, Stanley, but I did and now I can't stop picturing it. He leaves work, he's on his way home... wham! His cappa is detated from his head!", "order": 8511, "speaker": "Michael Scott"},
  {"season": 3, "episode": 4, "scene": 16, "line": "You have just spit on my face.", "order": 8512, "speaker": "Stanley Hudson"},
  {"season": 3, "episode": 4, "scene": 16, "line": "Well, you know what? There's something wrong with you. There is something wrong with everybody in here. Because we have lost a member of our family and you don't wanna talk about it, you don't wanna think about it, you just wanna get back to work!", "order": 8513, "speaker": "Michael Scott"},
  {"season": 3, "episode": 4, "scene": 17, "line": "There are five stages to grief, which are [glancing at computer screen] denial, anger, bargaining, depression, and acceptance. And right now, out there, they're all denying the fact that they're sad. And that's hard. And it's making them all angry. And it is my job to try to get them all the way through to acceptance. And if not acceptance, then just depression. If I can get them depressed, then I'll have done my job.", "order": 8514, "speaker": "Michael Scott"},
  {"season": 3, "episode": 4, "scene": 18, "line": "I am going to throw you this ball. When you catch the ball, I want you to say the name of a person very important to you, somebody really special who died, and then I want you to say how they died, and you may cry if you like, that is encouraged. Let me just start. Let me show you how this works. I catch the ball. I lost Ed Truck. And... it feels like somebody took my heart and dropped it into a bucket of boiling tears... and at the same time, somebody else is hitting my soul in the crotch with a frozen sledgehammer... and then a third guy walks in and starts punching me in the grief bone... and I'm crying, and nobody can hear me, because I am terribly, terribly... terribly alone.", "order": 8515, "speaker": "Michael Scott"},
  {"season": 3, "episode": 4, "scene": 18, "line": "Excuse me, I'm sorry to interrupt, uh, I need to see Pam. There seems to be, like, something wrong with the radiator in her car.", "order": 8516, "speaker": "Roy Anderson"},
  {"season": 3, "episode": 4, "scene": 18, "line": "Okay, fine. Hurry back.", "order": 8517, "speaker": "Michael Scott"},
  {"season": 3, "episode": 4, "scene": 18, "line": "[as they leave] There's nothing wrong with your car. I just thought you might like a break from the 'grief counseling' session.", "order": 8518, "speaker": "Roy Anderson"},
  {"season": 3, "episode": 4, "scene": 18, "line": "Oh. I would like a break.", "order": 8519, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 4, "scene": 19, "line": "How are you likin' the new car?", "order": 8520, "speaker": "Roy Anderson"},
  {"season": 3, "episode": 4, "scene": 19, "line": "Great.", "order": 8521, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 4, "scene": 19, "line": "Yeah? Sure is small. Got airbags?", "order": 8522, "speaker": "Roy Anderson"},
  {"season": 3, "episode": 4, "scene": 19, "line": "I think so. I don't know, I was mainly focused on the cup holders.", "order": 8523, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 4, "scene": 19, "line": "[laughs] Well, you're not still driving so fast, are you? Yeah.", "order": 8524, "speaker": "Roy Anderson"},
  {"season": 3, "episode": 4, "scene": 20, "line": "[on phone] Hi, yeah. This is Mike from the West Side Market. Well, we get a shipment of Herr's salt and vinegar chips, and we ordered that about three weeks ago and haven't ... . yeah. You have 'em in the warehouse. Great. What is my store number... six. Wait, no. I'll call you back. [quickly hangs up] Shut up [to Karen].", "order": 8525, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 4, "scene": 20, "line": "[laughing] Six?", "order": 8526, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 4, "scene": 21, "line": "[as Pam returns to conference room] Okay, we can start. Um...", "order": 8527, "speaker": "Michael Scott"},
  {"season": 3, "episode": 4, "scene": 21, "line": "You waited for me?", "order": 8528, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 4, "scene": 21, "line": "Yeah. Pam, you're a member of this family. So we will wait for our family members. Phyllis, you wanna give it a shot?", "order": 8529, "speaker": "Michael Scott"},
  {"season": 3, "episode": 4, "scene": 21, "line": "[grabbing ball] I got it. When my mother was pregnant with me, they did an ultrasound and found she was having twins. When they did another ultrasound a few weeks later, they discovered, that I had resorbed the other fetus. Do I regret this? No. I believe his tissue has made me stronger. I now have the strength of a grown man and a little baby.", "order": 8530, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 4, "scene": 21, "line": "Okay, why don't you throw the ball to somebody else.", "order": 8531, "speaker": "Michael Scott"},
  {"season": 3, "episode": 4, "scene": 21, "line": "[throwing ball back] Nope.", "order": 8532, "speaker": "Stanley Hudson"},
  {"season": 3, "episode": 4, "scene": 21, "line": "[returning ball to Stanley] Oh, yes Stanley. Come on. Your turn, you have to go.", "order": 8533, "speaker": "Michael Scott"},
  {"season": 3, "episode": 4, "scene": 21, "line": "I will NOT. [throws ball back]", "order": 8534, "speaker": "Stanley Hudson"},
  {"season": 3, "episode": 4, "scene": 21, "line": "Okay... I'm going to toss the ball to Pam.", "order": 8535, "speaker": "Michael Scott"},
  {"season": 3, "episode": 4, "scene": 21, "line": "Let's see. I had an aunt that I was really close to. She was this amazing female boxer. Um, anyway, she was injured in a fight, and she was paralyzed. So, you can imagine how upset I was when I found out that she asked her manager to remove her breathing tube so she could die.", "order": 8536, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 4, "scene": 21, "line": "Wow. If you wanna cry, that's okay.", "order": 8537, "speaker": "Michael Scott"},
  {"season": 3, "episode": 4, "scene": 21, "line": "[catching ball] Thanks. Um, a few years ago, my family was on a safari in Africa and, um, my cousin, Mufasa was um, he was trampled to death by a pack of wildebeests and, um, we all... took it really hard. All of us kind of in the audience of what happened.", "order": 8538, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 4, "scene": 21, "line": "Do you want to talk about it anymore?", "order": 8539, "speaker": "Michael Scott"},
  {"season": 3, "episode": 4, "scene": 21, "line": "Oh, it would probably take me like an hour and a half to tell that whole story.", "order": 8540, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 4, "scene": 21, "line": "Me, me, me, me, me me. [catches ball] Yes. Okay, um. I was trying to throw this party once. And everyone was over for the weekend. And then my uncle Bernie died, and so me and my best friend, we had to pretend like he was alive, so...", "order": 8541, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 4, "scene": 21, "line": "Wait a second. That's Weekend at Bernie's. [upset] Do you think that this is a game?", "order": 8542, "speaker": "Michael Scott"},
  {"season": 3, "episode": 4, "scene": 21, "line": "Well, there is a ball.", "order": 8543, "speaker": "Phyllis Lapin"},
  {"season": 3, "episode": 4, "scene": 21, "line": "All right, we're starting over.", "order": 8544, "speaker": "Michael Scott"},
  {"season": 3, "episode": 4, "scene": 21, "line": "No, I'm done.", "order": 8545, "speaker": "Stanley Hudson"},
  {"season": 3, "episode": 4, "scene": 21, "line": "You are not leaving. No! We are not done.", "order": 8546, "speaker": "Michael Scott"},
  {"season": 3, "episode": 4, "scene": 21, "line": "We really have a lot of work to do right now.", "order": 8547, "speaker": "Angela Martin"},
  {"season": 3, "episode": 4, "scene": 21, "line": "Yeah. Well, you know what? The guy who had my job has died. And nobody cares! And he sat at my desk. [breaking down]", "order": 8548, "speaker": "Michael Scott"},
  {"season": 3, "episode": 4, "scene": 21, "line": "Michael, look. I know this is hard for you, but death's just a part of life. I mean, just this morning I saw a little bird fly into the glass doors downstairs and die. And I had to keep going.", "order": 8549, "speaker": "Toby Flenderson"},
  {"season": 3, "episode": 4, "scene": 21, "line": "How do you know?", "order": 8550, "speaker": "Michael Scott"},
  {"season": 3, "episode": 4, "scene": 21, "line": "What?", "order": 8551, "speaker": "Toby Flenderson"},
  {"season": 3, "episode": 4, "scene": 21, "line": "That that bird was dead? Did you check its breathing?", "order": 8552, "speaker": "Michael Scott"},
  {"season": 3, "episode": 4, "scene": 21, "line": "It was obvious...", "order": 8553, "speaker": "Toby Flenderson"},
  {"season": 3, "episode": 4, "scene": 21, "line": "Was its heart beating, Toby? Did you check it? No, of course you didn't. You're not a veterinarian. You don't know ANYTHING! [runs out of conference room crying]", "order": 8554, "speaker": "Michael Scott"},
  {"season": 3, "episode": 4, "scene": 21, "line": "Michael! [following Michael downstairs] Michael. Michael ...", "order": 8555, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 4, "scene": 21, "line": "[seeing bird] Oh, God!", "order": 8556, "speaker": "Michael Scott"},
  {"season": 3, "episode": 4, "scene": 21, "line": "[in disgust] Oh...", "order": 8557, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 4, "scene": 21, "line": "Oh my God! Oh my God, come on ...", "order": 8558, "speaker": "Michael Scott"},
  {"season": 3, "episode": 4, "scene": 21, "line": "Poor little fella.", "order": 8559, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 4, "scene": 21, "line": "Oh shhhh--oot!", "order": 8560, "speaker": "Michael Scott"},
  {"season": 3, "episode": 4, "scene": 21, "line": "He is a goner.", "order": 8561, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 4, "scene": 21, "line": "No, he's not.", "order": 8562, "speaker": "Michael Scott"},
  {"season": 3, "episode": 4, "scene": 21, "line": "Yeah.", "order": 8563, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 4, "scene": 21, "line": "No, he's not. [cradling bird next to face]", "order": 8564, "speaker": "Michael Scott"},
  {"season": 3, "episode": 4, "scene": 21, "line": "Michael, get him away from your head! He is covered in germs and bacteria!", "order": 8565, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 4, "scene": 21, "line": "Well, you can't get diseases from a bird!", "order": 8566, "speaker": "Michael Scott"},
  {"season": 3, "episode": 4, "scene": 22, "line": "A dead bird should not be in the kitchen!", "order": 8567, "speaker": "Angela Martin"},
  {"season": 3, "episode": 4, "scene": 22, "line": "We don't know if it's dead.", "order": 8568, "speaker": "Michael Scott"},
  {"season": 3, "episode": 4, "scene": 22, "line": "Ugh, no that thing is", "order": 8569, "speaker": "Kelly Kapoor"},
  {"season": 3, "episode": 4, "scene": 22, "line": "You want me to flush him?", "order": 8570, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 4, "scene": 23, "line": "Attention everybody. This will only take a second of your time. Today at 4 p.m. we will be meeting in the parking lot to have a funeral service for this bird.", "order": 8571, "speaker": "Michael Scott"},
  {"season": 3, "episode": 4, "scene": 23, "line": "I have a lot of work to do.", "order": 8572, "speaker": "Meredith Palmer"},
  {"season": 3, "episode": 4, "scene": 23, "line": "Well, I'm sorry to inconvenience you, Meredith. But that is what you do when things die. You honor them. Toby killed this bird. And now we are going to honor it.", "order": 8573, "speaker": "Michael Scott"},
  {"season": 3, "episode": 4, "scene": 23, "line": "But...", "order": 8574, "speaker": "Angela Martin"},
  {"season": 3, "episode": 4, "scene": 23, "line": "No, no, no! That's enough! You know what? This bird is dead. He died alone. The least you can do is be there for him now. [to Dwight] Find a box for him.", "order": 8575, "speaker": "Michael Scott"},
  {"season": 3, "episode": 4, "scene": 24, "line": "I'm calling a supermarket in Montreal.", "order": 8576, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 4, "scene": 24, "line": "Nice!", "order": 8577, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 4, "scene": 24, "line": "[on phone] Bonjour. Je cherche des tchips de la marque Herr's. Non? Ah... merci quand meme. Au revoir.", "order": 8578, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 4, "scene": 24, "line": "Sounded good.", "order": 8579, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 4, "scene": 24, "line": "Thanks.", "order": 8580, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 4, "scene": 25, "line": "[crying]", "order": 8581, "speaker": "Kelly Kapoor"},
  {"season": 3, "episode": 4, "scene": 25, "line": "It's okay. It's okay. [pats Kelly on shoulder] Shh... yes, I know, I know. It's been a tough day, but it's good to let it show.", "order": 8582, "speaker": "Michael Scott"},
  {"season": 3, "episode": 4, "scene": 25, "line": "I mean, how many times to I have to confirm with Ryan [Michael walks away disgusted] for him to know that we have a date tonight?", "order": 8583, "speaker": "Kelly Kapoor"},
  {"season": 3, "episode": 4, "scene": 26, "line": "[trying to shove bird into a can] Argh... gah!", "order": 8584, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 4, "scene": 26, "line": "What are... What are you doing?", "order": 8585, "speaker": "Michael Scott"},
  {"season": 3, "episode": 4, "scene": 26, "line": "What? No, this is about the right size.", "order": 8586, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 4, "scene": 26, "line": "No, God, no it's not! What is the matter with you? Is that the beak!?", "order": 8587, "speaker": "Michael Scott"},
  {"season": 3, "episode": 4, "scene": 27, "line": "I'm sorry, I grew up on a farm. We slaughtered a pig whenever we wanted bacon. My grandfather was reburied in an old oil drum. [pause] It would have fit if he had given me another minute.", "order": 8588, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 4, "scene": 28, "line": "I need a box. I need a box. A small box... not too confining.", "order": 8589, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 4, "scene": 28, "line": "Is it for the bird?", "order": 8590, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 4, "scene": 28, "line": "Yeah.", "order": 8591, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 4, "scene": 28, "line": "I have it covered. [hands Dwight a decorated box]", "order": 8592, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 4, "scene": 28, "line": "Oh, thank you.", "order": 8593, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 4, "scene": 28, "line": "If you wanna do something for the funeral...", "order": 8594, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 4, "scene": 28, "line": "Yes, please.", "order": 8595, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 4, "scene": 28, "line": "Maybe you could play a song on your recorder.", "order": 8596, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 4, "scene": 28, "line": "Excellent.", "order": 8597, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 4, "scene": 28, "line": "Do you have it with you?", "order": 8598, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 4, "scene": 28, "line": "Always. [runs off]", "order": 8599, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 4, "scene": 29, "line": "Did I wake up this morning thinking I'd be throwing together a bird funeral? You never can tell what your day here is gonna turn into.", "order": 8600, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 4, "scene": 30, "line": "[spotting Herr's chips on her desk] Where'd you find them?", "order": 8601, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 4, "scene": 30, "line": "Where'd I find what?", "order": 8602, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 4, "scene": 31, "line": "I called the manufacturer, who referred me to distributor, who referred me to the vending machine company, who told me that they sell them in the machines in the building next door.", "order": 8603, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 4, "scene": 32, "line": "Glad you could all make it.", "order": 8604, "speaker": "Michael Scott"},
  {"season": 3, "episode": 4, "scene": 32, "line": "You told us we had to.", "order": 8605, "speaker": "Kelly Kapoor"},
  {"season": 3, "episode": 4, "scene": 32, "line": "Dwight, do you have the box?", "order": 8606, "speaker": "Michael Scott"},
  {"season": 3, "episode": 4, "scene": 32, "line": "Actually, I have it.", "order": 8607, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 4, "scene": 32, "line": "You made this? Wow. [impressed] That's... that's very nice.", "order": 8608, "speaker": "Michael Scott"},
  {"season": 3, "episode": 4, "scene": 33, "line": "When I was five my mom told me that my fish went to the hospital in the toilet and it never came back so we had a funeral for it. And I remember thinking 'I'm a little too old for this.' And I was", "order": 8609, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 4, "scene": 34, "line": "I'd also like to say a few words if that's okay.", "order": 8610, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 4, "scene": 34, "line": "Yes.", "order": 8611, "speaker": "Michael Scott"},
  {"season": 3, "episode": 4, "scene": 34, "line": "What do we know about this bird? You might think, 'Not much. It's just a bird.' But we do know some things. We know it was a local bird. Maybe it's that same bird that surprised Oscar that one morning with a special present from above.", "order": 8612, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 4, "scene": 34, "line": "Oh, I remember that. That was SO funny.", "order": 8613, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 4, "scene": 34, "line": "And we know how he died. Flying into the glass doors. But you know what? I don't think he was being stupid. I think he just really, really wanted to come inside our building to spread his cheer and lift our spirits with a song.", "order": 8614, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 4, "scene": 34, "line": "He's not a songbird.", "order": 8615, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 4, "scene": 34, "line": "Shhh.", "order": 8616, "speaker": "Michael Scott"},
  {"season": 3, "episode": 4, "scene": 34, "line": "An impression, then. Lastly, we can't help but notice that he was by himself when he died, but of course, we all know that doesn't mean he was alone. Because I'm sure that there were lots of other birds out there who cared for him very much. He will not be forgotten.", "order": 8617, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 4, "scene": 34, "line": "[quickly] Amen.", "order": 8618, "speaker": "Angela Martin"},
  {"season": 3, "episode": 4, "scene": 34, "line": "[begins playing recorder]", "order": 8619, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 4, "scene": 34, "line": "[singing] Just smile for me and let the day begin. You are the sunshine that lights my heart within... on the wings of love ...", "order": 8620, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 4, "scene": 35, "line": "Society teaches us that having feelings and crying is bad and wrong. Well, that's baloney, because grief isn't wrong. There is such a thing as good grief. Just ask Charlie Brown.", "order": 8621, "speaker": "Michael Scott"},
  {"season": 3, "episode": 4, "scene": 36, "line": "Let's get back to work.", "order": 8622, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 4, "scene": 37, "line": "Hey Jimmy. Want to see some pictures of my baby?", "order": 8623, "speaker": "Hannah"},
  {"season": 3, "episode": 4, "scene": 37, "line": "Umm... sure.", "order": 8624, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 4, "scene": 37, "line": "[shows picture] Here he is.", "order": 8625, "speaker": "Hannah"},
  {"season": 3, "episode": 4, "scene": 37, "line": "[chuckles] He's so cute.", "order": 8626, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 4, "scene": 37, "line": "And here's his first bath. Warning: contains nudity.", "order": 8627, "speaker": "Hannah"},
  {"season": 3, "episode": 4, "scene": 37, "line": "That's okay. [Hannah shows pic of husband naked with baby] Oh, no, it's not. I'm sorry, is that your husband in the tub with him?", "order": 8628, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 4, "scene": 37, "line": "You think we should have left our baby in the tub alone? [Jim shakes head] Talk to me when you have kids.", "order": 8629, "speaker": "Hannah"},
  {"season": 3, "episode": 4, "scene": 37, "line": "Sounds good.", "order": 8630, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 4, "scene": 38, "line": "[on the phone] Ed Truck died over the weekend.", "order": 8631, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 4, "scene": 38, "line": "Oh, wow. He...", "order": 8632, "speaker": "Michael Scott"},
  {"season": 3, "episode": 4, "scene": 38, "line": "Yeah. And I know... I know he was your boss, so I thought you should know.", "order": 8633, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 4, "scene": 38, "line": "Does his family know?", "order": 8634, "speaker": "Michael Scott"},
  {"season": 3, "episode": 4, "scene": 38, "line": "Yes, they know, Michael.", "order": 8635, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 4, "scene": 38, "line": "But I'm the first in the office?", "order": 8636, "speaker": "Michael Scott"},
  {"season": 3, "episode": 4, "scene": 38, "line": "I guess, yes. I think it would be appropriate for you to make an announcement to your team, in case they want to pay their respects.", "order": 8637, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 4, "scene": 38, "line": "Okay, yes, sure, I will do that...", "order": 8638, "speaker": "Michael Scott"},
  {"season": 3, "episode": 4, "scene": 38, "line": "Good.", "order": 8639, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 4, "scene": 38, "line": "...'Cause I'm the first to to find out. Nobody else knows and it is my responsibility to tell them. Well, good. How are you holding up? Want me to come over? [Jan hangs up]", "order": 8640, "speaker": "Michael Scott"},
  {"season": 3, "episode": 4, "scene": 39, "line": "Ed Truck died. And it blows. They say that with grief, time makes it worse, which is bad for me because I found out before anybody so I've had more time to be sad.", "order": 8641, "speaker": "Michael Scott"},
  {"season": 3, "episode": 4, "scene": 40, "line": "Attention, everyone. As you know, Ed Truck is dead. If you have any contact information on Rolodex files or in Microsoft Outlook, remove it now. Also, if you have any photographs, Pam will make her shredder available during lunch. That is all.", "order": 8642, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 4, "scene": 41, "line": "Remembering the dead doesn't help anybody. The way to best honor them is to figure out what killed them and prevent that thing from killing anyone ever again. In this case, some sort of steel exoskeleton to protect the neck, or else a device that allows the head to live separately from the body. I'm working on both.", "order": 8643, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 4, "scene": 42, "line": "I would like you all to clear your schedules this afternoon, because I am telling corporate to send in a grief counselor. We need to find out what is blocking you. And that way, we'll be able to honor Ed the way he deserves to be honored. May he rest in peace.", "order": 8644, "speaker": "Michael Scott"},
  {"season": 3, "episode": 4, "scene": 42, "line": "...ces. [chuckles]", "order": 8645, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 4, "scene": 42, "line": "What was that, Kevin?", "order": 8646, "speaker": "Michael Scott"},
  {"season": 3, "episode": 4, "scene": 42, "line": "Nothing.", "order": 8647, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 4, "scene": 42, "line": "No, you said something, didn't you?", "order": 8648, "speaker": "Michael Scott"},
  {"season": 3, "episode": 4, "scene": 42, "line": "May he rest in pieces. [chuckles] You know, because there's, like, two pieces of him.", "order": 8649, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 4, "scene": 42, "line": "You think that's funny.", "order": 8650, "speaker": "Michael Scott"},
  {"season": 3, "episode": 4, "scene": 42, "line": "No. [chuckles]", "order": 8651, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 4, "scene": 42, "line": "You are disgusting.", "order": 8652, "speaker": "Michael Scott"},
  {"season": 3, "episode": 4, "scene": 42, "line": "Michael, no need to call corporate. We are supposed to use Toby. He's trained as a grief counselor.", "order": 8653, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 4, "scene": 42, "line": "No, that can't be right.", "order": 8654, "speaker": "Michael Scott"},
  {"season": 3, "episode": 4, "scene": 42, "line": "Well, I am trained in grief counseling, but, ahh, I don't think that's what they need right now.", "order": 8655, "speaker": "Toby Flenderson"},
  {"season": 3, "episode": 4, "scene": 42, "line": "Well, then I guess that makes you about the worst grief counselor in the world, doesn't it?", "order": 8656, "speaker": "Michael Scott"},
  {"season": 3, "episode": 4, "scene": 43, "line": "Hey there.", "order": 8657, "speaker": "Roy Anderson"},
  {"season": 3, "episode": 4, "scene": 43, "line": "Hi.", "order": 8658, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 4, "scene": 43, "line": "So how's your day going?", "order": 8659, "speaker": "Roy Anderson"},
  {"season": 3, "episode": 4, "scene": 43, "line": "Well, I will be spending the entire afternoon in grief counseling for someone I've never met.", "order": 8660, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 4, "scene": 43, "line": "That sucks. [laughs] You guys got it much harder up here.", "order": 8661, "speaker": "Roy Anderson"},
  {"season": 3, "episode": 4, "scene": 43, "line": "Closer to the epicenter, I guess.", "order": 8662, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 4, "scene": 43, "line": "[laughs] Cool, all right.", "order": 8663, "speaker": "Roy Anderson"},
  {"season": 3, "episode": 4, "scene": 44, "line": "Well, as you know, Ed Truck passed away. So, I'm here to talk about it if anyone would like to.", "order": 8664, "speaker": "Toby Flenderson"},
  {"season": 3, "episode": 4, "scene": 44, "line": "Not really.", "order": 8665, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 4, "scene": 44, "line": "You're ruining it, already. They should not be given the choice.", "order": 8666, "speaker": "Michael Scott"},
  {"season": 3, "episode": 4, "scene": 45, "line": "I did not know Ed Truck. So I will probably spend the day zoning out and planning my weekend. I think Ed would have wanted it that way.", "order": 8667, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 4, "scene": 46, "line": "Is anyone having any trouble eating?", "order": 8668, "speaker": "Toby Flenderson"},
  {"season": 3, "episode": 4, "scene": 46, "line": "No.", "order": 8669, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 4, "scene": 46, "line": "Concentrating? Is anyone having trouble getting their work done today?", "order": 8670, "speaker": "Toby Flenderson"},
  {"season": 3, "episode": 4, "scene": 46, "line": "I'm having trouble getting my work done today.", "order": 8671, "speaker": "Stanley Hudson"},
  {"season": 3, "episode": 4, "scene": 46, "line": "Well, look, sometimes things crop up later. So, uhh, you should know I'm always available if anyone would like to talk.", "order": 8672, "speaker": "Toby Flenderson"},
  {"season": 3, "episode": 4, "scene": 46, "line": "Wow. Wow. No. No. You are the most heartless, soulless person I've ever met.", "order": 8673, "speaker": "Michael Scott"},
  {"season": 3, "episode": 4, "scene": 46, "line": "Michael, I'm sorry...", "order": 8674, "speaker": "Toby Flenderson"},
  {"season": 3, "episode": 4, "scene": 46, "line": "No. No. Shh. Enough. It is going to take me hours to undo all the damage that your have done here. Dwight, Ryan, I want you to take the table out. We're gonna go on a five minute break, and when you come back, we're going to start with a clean slate, and, in the interim, I would like you to forget everything that Toby has said, okay?", "order": 8675, "speaker": "Michael Scott"},
  {"season": 3, "episode": 4, "scene": 47, "line": "I don't think I'm overstating it when I say that Toby is a plague on this company. And he is worthless. You give people a chance to shine and they blow it, just like you knew they would. Grief counseling is not about, 'Are you hungry? Would you like a sandwich?' These people are so far gone, you have to stick a food tube down their throats.", "order": 8676, "speaker": "Michael Scott"},
  {"season": 3, "episode": 4, "scene": 48, "line": "Okay, part of the problem was that Toby talked at you. Now, I am going to talk with you. First off, I want you to be comfortable, because you really can't grieve if you're all tight and buttoned up. So, guys, loosen your ties, just take your jackets off if you want. Ladies, let your hair down. You know what? Even take your shoes off. I wanna get personal and I want you all to really open up. And I want you to know that anything and everything can be said.", "order": 8677, "speaker": "Michael Scott"},
  {"season": 3, "episode": 4, "scene": 48, "line": "Okay. I don't think Kevin should take his shoes off.", "order": 8678, "speaker": "Angela Martin"},
  {"season": 3, "episode": 4, "scene": 49, "line": "My feet do not smell. Angela knows that hyperhidrosis is a medically diagnosed condition.", "order": 8679, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 4, "scene": 50, "line": "I found an exercise in Toby's binder that he managed to miss, that involves screening the part of you that says, 'I don't wanna do that. I don't wanna talk about that. That is too private.' Here's how it works. I'm going to throw you this ball.", "order": 8680, "speaker": "Michael Scott"},
  {"season": 3, "episode": 4, "scene": 51, "line": "Yeah, the ball-throwing exercise is in the binder. It's in the section marked, 'Fun Ways To Make Brainstorming Sessions Explode With Excitement and Creativity.'", "order": 8681, "speaker": "Toby Flenderson"},
  {"season": 3, "episode": 4, "scene": 52, "line": "Hey, my cousin Billy, they just had the twins.", "order": 8682, "speaker": "Roy Anderson"},
  {"season": 3, "episode": 4, "scene": 52, "line": "Really?", "order": 8683, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 4, "scene": 52, "line": "Yeah.", "order": 8684, "speaker": "Roy Anderson"},
  {"season": 3, "episode": 4, "scene": 52, "line": "Wow, I thought that was supposed to be, like, next month.", "order": 8685, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 4, "scene": 52, "line": "Yeah, I know. I guess they couldn't wait. I'll bring in a picture if you...", "order": 8686, "speaker": "Roy Anderson"},
  {"season": 3, "episode": 4, "scene": 52, "line": "Yeah, I'd love to see them. Oh, and tell them I say, 'Congratulations.'", "order": 8687, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 4, "scene": 52, "line": "Sure.", "order": 8688, "speaker": "Roy Anderson"},
  {"season": 3, "episode": 4, "scene": 52, "line": "I should probably head back in.", "order": 8689, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 4, "scene": 52, "line": "Yeah?", "order": 8690, "speaker": "Roy Anderson"},
  {"season": 3, "episode": 4, "scene": 52, "line": "But, umm, thanks for the break.", "order": 8691, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 4, "scene": 52, "line": "No problem.", "order": 8692, "speaker": "Roy Anderson"},
  {"season": 3, "episode": 4, "scene": 53, "line": "[in his office playing with a Dunder Mifflin Truck when it drops and the 'head' of the Truck comes off]", "order": 8693, "speaker": "Michael Scott"},
  {"season": 3, "episode": 4, "scene": 54, "line": "Michael once told me that Home Alone is the saddest movie ever. When I asked him why, he said, 'Because the whole family forgets the kid at home. There is nothing funny about that.'", "order": 8694, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 4, "scene": 55, "line": "That's... Hold it, hold it, hold it. Start, like, right in there. [Dwight digs hole in ground] Just dig.", "order": 8695, "speaker": "Michael Scott"},
  {"season": 3, "episode": 4, "scene": 55, "line": "Ow! God!", "order": 8696, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 4, "scene": 55, "line": "Okay, give it to me. You don't know what you're doing. All right. [Michael starts digging]", "order": 8697, "speaker": "Michael Scott"},
  {"season": 3, "episode": 4, "scene": 55, "line": "Hey! Hey! What are you guys doing over there?", "order": 8698, "speaker": "Man"},
  {"season": 3, "episode": 4, "scene": 55, "line": "Nothing. Let's just... all right. [talks to warehouse guys] Guys, do you have a box we could use?", "order": 8699, "speaker": "Michael Scott"},
  {"season": 3, "episode": 5, "scene": 1, "line": "Brain teaser. I have two coins totaling 15 cents and one of them is not a nickel. What are they?", "order": 8700, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 5, "scene": 1, "line": "A dime and a nickel.", "order": 8701, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 5, "scene": 1, "line": "No, I said one of them is not a nickel.", "order": 8702, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 5, "scene": 1, "line": "But the other one is. I've heard that before.", "order": 8703, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 5, "scene": 1, "line": "Ok. A man and his son get into a car accident. They are rushed to the hospital. The doctor says, there is no way I can operate on this boy, ...", "order": 8704, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 5, "scene": 1, "line": "Because he's my son. The doctor is the boy's mother.", "order": 8705, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 5, "scene": 1, "line": "A man is found hanging from the ceiling...", "order": 8706, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 5, "scene": 1, "line": "He stepped on a block of ice, hung himself, and the ice melted.", "order": 8707, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 5, "scene": 1, "line": "A hunter.", "order": 8708, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 5, "scene": 1, "line": "It's a polar bear because you're at the North Pole.", "order": 8709, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 5, "scene": 1, "line": "Damn it!", "order": 8710, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 5, "scene": 2, "line": "Tell me what you did yesterday.", "order": 8711, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 5, "scene": 2, "line": "Uhhh... nothing.", "order": 8712, "speaker": "Michael Scott"},
  {"season": 3, "episode": 5, "scene": 2, "line": "Nothing?", "order": 8713, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 5, "scene": 2, "line": "Yeah, nothing. How was your day?", "order": 8714, "speaker": "Michael Scott"},
  {"season": 3, "episode": 5, "scene": 2, "line": "I don't care how your day was Michael.", "order": 8715, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 5, "scene": 2, "line": "Wow. Well. Ok. I don't care how your day was either, Jan. I was just asking you because you asked me. Why do you set me up like that?", "order": 8716, "speaker": "Michael Scott"},
  {"season": 3, "episode": 5, "scene": 2, "line": "Tell me what you did yesterday.", "order": 8717, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 5, "scene": 2, "line": "I worked. And then I went home to my condo. And Carol came over. And then we had sex. Is that what you want to hear?", "order": 8718, "speaker": "Michael Scott"},
  {"season": 3, "episode": 5, "scene": 3, "line": "Never ever, ever sleep with your boss. I am so lucky that Jan and I only got to second base.", "order": 8719, "speaker": "Michael Scott"},
  {"season": 3, "episode": 5, "scene": 4, "line": "Hi, Pam.", "order": 8720, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 5, "scene": 4, "line": "Hi.", "order": 8721, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 5, "scene": 4, "line": "I'm great. So, Pam I would like you to keep a log of everything Michael does hour by hour so we can analyze it at corporate. Ok?", "order": 8722, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 5, "scene": 4, "line": "Oh, I don't know if I'm...", "order": 8723, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 5, "scene": 4, "line": "Thanks Pam.", "order": 8724, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 5, "scene": 5, "line": "It's weird. Jan use to treat Michael like he was a ten year old, but lately it's like he's five.", "order": 8725, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 5, "scene": 6, "line": "So you excited?", "order": 8726, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 5, "scene": 6, "line": "Yeah.", "order": 8727, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 5, "scene": 6, "line": "Very excited?", "order": 8728, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 5, "scene": 6, "line": "Yes. I'm very excited.", "order": 8729, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 5, "scene": 6, "line": "Extremely excited? ... Just very? That's cool.", "order": 8730, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 5, "scene": 7, "line": "I have spent a year here. I have to commit or get out. Dwight's the top salesman in the company and he's taking me on my first sales call today. And, um, I'm excited.", "order": 8731, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 5, "scene": 8, "line": "I am very excited. Ryan hasn't made a sale yet, but more importantly, he hasn't made an ally yet. Is he going to be a slacker-loser-wise-ass like Jim was? Or is he going to join the Dwight Army of Champions?", "order": 8732, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 5, "scene": 9, "line": "So what if they're not talking much, then does it makes sense to kind of lead the conversation? You know, just 'till it gets to a point where they are asking questions? [car stops] So where's the sales office?", "order": 8733, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 5, "scene": 9, "line": "When you are ready to see the sales office, the sales office will present itself to you. Your journey begins now.", "order": 8734, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 5, "scene": 10, "line": "[squeaky chair] Hey.", "order": 8735, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 5, "scene": 10, "line": "What?", "order": 8736, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 5, "scene": 10, "line": "My chair is squeaking.", "order": 8737, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 5, "scene": 10, "line": "Is it?", "order": 8738, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 5, "scene": 10, "line": "You took my chair.", "order": 8739, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 5, "scene": 10, "line": "No, I didn't. I took back my chair that you took from me, but I didn't take your chair.", "order": 8740, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 5, "scene": 10, "line": "When you get up, I'm just going to take it back anyway, so...", "order": 8741, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 5, "scene": 10, "line": "So I guess I can't get up.", "order": 8742, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 5, "scene": 11, "line": "Hey Koselli, the Kos. Cosby. Hey hey hey. I love Jello Pudding Pops. My son, Theo, loves Jello Pudding Pops too.", "order": 8743, "speaker": "Michael Scott"},
  {"season": 3, "episode": 5, "scene": 12, "line": "Do you know where we are, temp?", "order": 8744, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 5, "scene": 12, "line": "I know where we're not.", "order": 8745, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 5, "scene": 12, "line": "I hold in my hand, a beet seed. Take it. [Ryan tries to take it, Dwight closes hand] AH! [Ryan tries again and takes seed] When... Damn it.", "order": 8746, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 5, "scene": 13, "line": "Hey, Big Tuna, can I talk to you for a second.", "order": 8747, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 5, "scene": 13, "line": "Sure.", "order": 8748, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 5, "scene": 13, "line": "Can you stand up? And talk to me over there?", "order": 8749, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 5, "scene": 13, "line": "That's it? That's what you came up with?", "order": 8750, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 5, "scene": 13, "line": "I'm acting my heart out here.", "order": 8751, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 5, "scene": 13, "line": "Really?", "order": 8752, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 5, "scene": 13, "line": "Yeah, really. You asked for my help, so I helped.", "order": 8753, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 5, "scene": 14, "line": "Attention Scranton Business Park, there will be complimentary pretzels in the lobby from now until 4 o'clock as a thank you to our loyal tenants.", "order": 8754, "speaker": "Announcer"},
  {"season": 3, "episode": 5, "scene": 15, "line": "Once a year they bring in a little cart and they give away free pretzels. It's really not a big deal. To some people it is.", "order": 8755, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 5, "scene": 16, "line": "Productivity is important but how can I be productive if I have this one little thing in my brain? That I cannot get out. And that one little thing is a soft pretzel. So I'm just going to have my soft pretzel, then I'll get to work, and I'll be super productive. Look out for me.", "order": 8756, "speaker": "Michael Scott"},
  {"season": 3, "episode": 5, "scene": 17, "line": "[looks at long pretzel line] Oh, shhh... Come on.", "order": 8757, "speaker": "Michael Scott"},
  {"season": 3, "episode": 5, "scene": 18, "line": "And just as you have planted your seed in the ground, I'm going to plant my seed in you.", "order": 8758, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 5, "scene": 18, "line": "I don't think you know what you're saying.", "order": 8759, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 5, "scene": 18, "line": "Smells pretty bad, doesn't it?", "order": 8760, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 5, "scene": 18, "line": "Uh huh.", "order": 8761, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 5, "scene": 18, "line": "It's called bull crap and a client can smell it from a mile away.", "order": 8762, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 5, "scene": 18, "line": "Gotcha.", "order": 8763, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 5, "scene": 18, "line": "Oh hey, I forgot something in my car. I'll be right back.", "order": 8764, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 5, "scene": 18, "line": "Ok. [Dwight drives away] Of course.", "order": 8765, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 5, "scene": 19, "line": "I wake up every morning in a bed that's too small, drive my daughter to a school that's too expensive, and then I go to work to a job for which I get paid too little, but on Pretzel Day? Well, I like pretzel day.", "order": 8766, "speaker": "Stanley Hudson"},
  {"season": 3, "episode": 5, "scene": 20, "line": "Because they acted all tough and everything...", "order": 8767, "speaker": "Kelly Kapoor"},
  {"season": 3, "episode": 5, "scene": 20, "line": "Uh huh.", "order": 8768, "speaker": "Michael Scott"},
  {"season": 3, "episode": 5, "scene": 20, "line": "But what they were rapping about was cupcakes and the Chronicles of Narnia. God. Who invented cupcakes, right? I want to...", "order": 8769, "speaker": "Kelly Kapoor"},
  {"season": 3, "episode": 5, "scene": 20, "line": "[Phyllis embraces Bob ahead in line] Hey, hey, hey! Phyllis, what are you doing?", "order": 8770, "speaker": "Michael Scott"},
  {"season": 3, "episode": 5, "scene": 20, "line": "I'm just saying hi to Bob.", "order": 8771, "speaker": "Phyllis Lapin"},
  {"season": 3, "episode": 5, "scene": 20, "line": "No, I think you're cutting in line.", "order": 8772, "speaker": "Michael Scott"},
  {"season": 3, "episode": 5, "scene": 20, "line": "Well settle down, Scott.", "order": 8773, "speaker": "Bob"},
  {"season": 3, "episode": 5, "scene": 20, "line": "No, I'm not going to settle down.", "order": 8774, "speaker": "Michael Scott"},
  {"season": 3, "episode": 5, "scene": 20, "line": "No way.", "order": 8775, "speaker": "Stanley Hudson"},
  {"season": 3, "episode": 5, "scene": 20, "line": "Get in the back please.", "order": 8776, "speaker": "Michael Scott"},
  {"season": 3, "episode": 5, "scene": 20, "line": "Boooooo. Booooo. Back of the line.", "order": 8777, "speaker": "Stanley Hudson"},
  {"season": 3, "episode": 5, "scene": 20, "line": "Boooooo. Thank you. [hi-five's Stanley]. That's right.", "order": 8778, "speaker": "Michael Scott"},
  {"season": 3, "episode": 5, "scene": 20, "line": "What a pair of Mary's.", "order": 8779, "speaker": "Bob"},
  {"season": 3, "episode": 5, "scene": 20, "line": "This is Pretzel Day.", "order": 8780, "speaker": "Stanley Hudson"},
  {"season": 3, "episode": 5, "scene": 21, "line": "Hey Dwight, you're a great salesman. Can you teach me? Sure, Ryan. I'll make you the ole commodor. I'll abandon you in a beet field. Huh. That sounds great, Dwight, I'll really appreciate that. Thank you so much for your mentorship.", "order": 8781, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 5, "scene": 21, "line": "Congratulations resourceful salesman. You have passed the second challenge. Welcome to Schrute Farm.", "order": 8782, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 5, "scene": 22, "line": "[rocks in squeaky chair]", "order": 8783, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 5, "scene": 22, "line": "[sings] Love me, love me, say that you love me, fool me, fool me,", "order": 8784, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 5, "scene": 22, "line": "stop.", "order": 8785, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 5, "scene": 22, "line": "Go on and fool me. Love me, love me, say that you love me", "order": 8786, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 5, "scene": 22, "line": "This is not fair, this is going to be in my head all day. Please.", "order": 8787, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 5, "scene": 22, "line": "fool me, fool me, go on and fool me. Love me,", "order": 8788, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 5, "scene": 22, "line": "This is not a proportionate response.", "order": 8789, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 5, "scene": 22, "line": "Love me, say that you love me. Fool me, fool me, go on and fool me.", "order": 8790, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 5, "scene": 23, "line": "I don't care 'bout anything but you... what ever happened to those guys?", "order": 8791, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 5, "scene": 24, "line": "Michael.", "order": 8792, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 5, "scene": 24, "line": "No cuts. Oh, Pam. Just the woman I'd like to see. You read my mind.", "order": 8793, "speaker": "Michael Scott"},
  {"season": 3, "episode": 5, "scene": 24, "line": "Great! I thought you could use this time to authorize some checks.", "order": 8794, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 5, "scene": 24, "line": "I thought that maybe you could wait in line for me while I go to the bathroom. You're an angel.", "order": 8795, "speaker": "Michael Scott"},
  {"season": 3, "episode": 5, "scene": 24, "line": "Hey, why don't you just go up to your office, get some work done, and I'll just bring you a pretzel.", "order": 8796, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 5, "scene": 24, "line": "Because I like them a certain way, and if it gets screwed up, then whole thing is blown.", "order": 8797, "speaker": "Michael Scott"},
  {"season": 3, "episode": 5, "scene": 24, "line": "You know, I just think it's really important that you be productive today.", "order": 8798, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 5, "scene": 24, "line": "Pam, productivity starts with patience and determination. I'll be back.", "order": 8799, "speaker": "Michael Scott"},
  {"season": 3, "episode": 5, "scene": 25, "line": "It is time for your next test. You have planted the beet seed. You have walked the long lonely walk of loneliness.", "order": 8800, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 5, "scene": 25, "line": "Look man, I was in a frat in college. So I know what you're doing. I get it.", "order": 8801, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 5, "scene": 25, "line": "You know what your problem is? You know why you haven't made any sales? Cause you think you know everything. You have to trust that maybe there are other people that can teach you things. Are you ready to learn? Are you ready for the final test?", "order": 8802, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 5, "scene": 25, "line": "Yeah.", "order": 8803, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 5, "scene": 25, "line": "Come on!", "order": 8804, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 5, "scene": 26, "line": "Thank you! [takes pretzel]", "order": 8805, "speaker": "Stanley Hudson"},
  {"season": 3, "episode": 5, "scene": 26, "line": "Hi. Please tell me that you have a sweet pretzel left.", "order": 8806, "speaker": "Michael Scott"},
  {"season": 3, "episode": 5, "scene": 26, "line": "We do.", "order": 8807, "speaker": "Pretzel guy"},
  {"season": 3, "episode": 5, "scene": 26, "line": "Thank God.", "order": 8808, "speaker": "Michael Scott"},
  {"season": 3, "episode": 5, "scene": 26, "line": "And we have 18 different toppings. We have sweet glaze, cinnamon sugar, chocolate, white chocolate, fudge, M&M's, caramel dip, mint chip, chocolate chip, marshmallow, nuts, toffee nuts, coconuts, peanut butter drizzle, Oreos, sprinkles, cotton candy bits, and powdered sugar.", "order": 8809, "speaker": "Pretzel guy"},
  {"season": 3, "episode": 5, "scene": 26, "line": "Is there anyway that you could do all, all of them?", "order": 8810, "speaker": "Michael Scott"},
  {"season": 3, "episode": 5, "scene": 26, "line": "The Works. You got it.", "order": 8811, "speaker": "Pretzel guy"},
  {"season": 3, "episode": 5, "scene": 26, "line": "All right! Thank you!", "order": 8812, "speaker": "Michael Scott"},
  {"season": 3, "episode": 5, "scene": 27, "line": "Please be seated. [man runs behind Dwight]", "order": 8813, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 5, "scene": 27, "line": "Who was that?", "order": 8814, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 5, "scene": 27, "line": "Pay no attention to the spirits that haunt this hallowed ground.", "order": 8815, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 5, "scene": 27, "line": "Is that your Cousin Mose?", "order": 8816, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 5, "scene": 27, "line": "Yes.", "order": 8817, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 5, "scene": 28, "line": "Mose is my cousin and he lives here. He will always be my best friend. Unless things go well with Ryan today in which case, I won't hang out with Mose so much anymore.", "order": 8818, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 5, "scene": 29, "line": "What is the greatest danger facing Dunder Mifflin?", "order": 8819, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 5, "scene": 29, "line": "Outsourcing and consolidation of competition.", "order": 8820, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 5, "scene": 29, "line": "Wrong. Flash floods. What is the true cause of Robert Mifflin's suicide?", "order": 8821, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 5, "scene": 29, "line": "Depression?", "order": 8822, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 5, "scene": 29, "line": "Wrong. He hated himself. What is the DHARMA initiative?", "order": 8823, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 5, "scene": 30, "line": "I can't believe that Ryan is not back yet. Where could they be?", "order": 8824, "speaker": "Kelly Kapoor"},
  {"season": 3, "episode": 5, "scene": 30, "line": "Sales take a long time.", "order": 8825, "speaker": "Angela Martin"},
  {"season": 3, "episode": 5, "scene": 30, "line": "Oh my God, I'm so worried.", "order": 8826, "speaker": "Kelly Kapoor"},
  {"season": 3, "episode": 5, "scene": 30, "line": "I'm sure Dwight will protect him.", "order": 8827, "speaker": "Angela Martin"},
  {"season": 3, "episode": 5, "scene": 30, "line": "I don't know. Dwight's so weird.", "order": 8828, "speaker": "Kelly Kapoor"},
  {"season": 3, "episode": 5, "scene": 30, "line": "He's not weird, he's just individualistic.", "order": 8829, "speaker": "Angela Martin"},
  {"season": 3, "episode": 5, "scene": 30, "line": "No, he's a freak.", "order": 8830, "speaker": "Kelly Kapoor"},
  {"season": 3, "episode": 5, "scene": 30, "line": "You're a freak!", "order": 8831, "speaker": "Angela Martin"},
  {"season": 3, "episode": 5, "scene": 31, "line": "Final question, young Ryan Howard. What is Michael Scott's greatest fear?", "order": 8832, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 5, "scene": 31, "line": "Um, loneliness. Maybe women.", "order": 8833, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 5, "scene": 31, "line": "Wrong. He's not afraid of anything. Also, I would have accepted snakes. Fear is what it's all about. You cannot sell while undergoing fear. You need to vanquish fear! One must wrestle fear to the ground. You will now wrestle my cousin Mose.", "order": 8834, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 5, "scene": 31, "line": "No. Ok. All right. All right. It's over. This is over. OK.", "order": 8835, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 5, "scene": 31, "line": "Wrestle him to the ground.", "order": 8836, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 5, "scene": 31, "line": "No, you're a freak. I'm not doing this anymore. This is over. Goodbye.", "order": 8837, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 5, "scene": 31, "line": "Ryan. Don't. Ryan! You don't have to wrestle him. Just get in the coffin. Ryan?", "order": 8838, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 5, "scene": 31, "line": "Bye, Ryan. He seemed nice.", "order": 8839, "speaker": "Mose Schrute"},
  {"season": 3, "episode": 5, "scene": 31, "line": "Where are all the animals?", "order": 8840, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 5, "scene": 32, "line": "Ryan, come on. I am sorry. I am sorry. Mose is sorry too. Look, he sent over a basket with eggs and some fat back bacon. And look, something he whittled.", "order": 8841, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 5, "scene": 33, "line": "You still mad? It's just Jim and I didn't get along, and I didn't want it to be that way again. You know, I wanted us to be a team. An unstoppable team that competed against other teams.", "order": 8842, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 5, "scene": 33, "line": "Look, that, that's not what I wanted, ok. I just wanted to go on a sales call.", "order": 8843, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 5, "scene": 33, "line": "Screwgun. The sales call!", "order": 8844, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 5, "scene": 34, "line": "[Michael is listening to 'Rock And Roll Part 2 by Gary Glitter' and everyone can hear it. Kevin singing in unison with the song] Hey! Hey!", "order": 8845, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 5, "scene": 34, "line": "Mr. Kosseli. Hey hey hey, the Cos. What's going on? Do we have a deal? Deal or no deal? Let's make a deal. So what is the deal?", "order": 8846, "speaker": "Michael Scott"},
  {"season": 3, "episode": 5, "scene": 35, "line": "Establish time frames. Keep the phrase 'real dollars' in their head. And always keep the power in the conversation. That's why you're losing them on the cold calls. Cause you say the word please too much.", "order": 8847, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 5, "scene": 35, "line": "Wait, can you go back?", "order": 8848, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 5, "scene": 35, "line": "Michael always said, K.I.S.S. Keep it simple, stupid. Great advice, hurts my feelings every time.", "order": 8849, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 5, "scene": 36, "line": "Ok, I'm going to establish time frames.", "order": 8850, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 5, "scene": 36, "line": "Good.", "order": 8851, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 5, "scene": 36, "line": "I'm going to put everything in terms of 'real dollars'.", "order": 8852, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 5, "scene": 36, "line": "Right.", "order": 8853, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 5, "scene": 36, "line": "I'm going to ask a lot of questions that all have sort of positive answers.", "order": 8854, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 5, "scene": 36, "line": "Uh huh.", "order": 8855, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 5, "scene": 36, "line": "Saying, that would be better, or we would like that. That sort of thing.", "order": 8856, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 5, "scene": 36, "line": "Exactly. Yes.", "order": 8857, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 5, "scene": 36, "line": "I'm going to try to be confident, but not cocky.", "order": 8858, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 5, "scene": 37, "line": "Oh! Hey! Everyone, I am officially streamlining the efficiency of this corporation. Second...", "order": 8859, "speaker": "Michael Scott"},
  {"season": 3, "episode": 5, "scene": 37, "line": "Second?", "order": 8860, "speaker": "Toby Flenderson"},
  {"season": 3, "episode": 5, "scene": 37, "line": "Yes, second, Toby. Second, I am insisting on increased accountability from every single one of you.", "order": 8861, "speaker": "Michael Scott"},
  {"season": 3, "episode": 5, "scene": 37, "line": "Account...? Michael, what is going on?", "order": 8862, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 5, "scene": 37, "line": "And I will be taking questions.", "order": 8863, "speaker": "Michael Scott"},
  {"season": 3, "episode": 5, "scene": 37, "line": "Did you have a lot of sugar today, Michael?", "order": 8864, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 5, "scene": 37, "line": "What's on your suit?", "order": 8865, "speaker": "Phyllis Lapin"},
  {"season": 3, "episode": 5, "scene": 37, "line": "Carmel Dip. But, one question at a time please. Phyllis, Stanley. I want you to switch desks, I'm going to reorganize and restructure the physical layout of the office to maximize everything! I think we're getting a lot done, don't you? On paper at least. And we are, after all, a paper company. Are we not, are we not, are we not? Are you with me, are you with me? Thank you very much!", "order": 8866, "speaker": "Michael Scott"},
  {"season": 3, "episode": 5, "scene": 38, "line": "They really didn't like me.", "order": 8867, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 5, "scene": 38, "line": "They did not. They didn't have to say it to your face.", "order": 8868, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 5, "scene": 38, "line": "I don't get it. I don't get what I did wrong.", "order": 8869, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 5, "scene": 38, "line": "Not everything's a lesson, Ryan. Sometimes you just fail. It's those online paper jerks. The whole business is changing. You know what? They're going to be screwed once this whole internet fad is over. What are you doing? [Ryan throws egg at building] Oh no no no no! Ryan! Yes! Ryan the temp! Come on! Courtesy of Dunder Mifflin. Come on! Drive.", "order": 8870, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 5, "scene": 38, "line": "You drive.", "order": 8871, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 5, "scene": 39, "line": "Dunder Mifflin, this is Pam. Oh, hi Jan. He's, uh, on a sales call. No message? Bye, Jan.", "order": 8872, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 5, "scene": 40, "line": "[Dwight chokes as Ryan downs a beer in one drink] Temp! Temp! Temp! Temp! Ryan! Ryan! Ryan! Ryan! Yes! Just think, that temp agency could have sent you anywhere.", "order": 8873, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 5, "scene": 40, "line": "I think about that all the time.", "order": 8874, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 5, "scene": 41, "line": "Night, Pam.", "order": 8875, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 5, "scene": 41, "line": "Night.", "order": 8876, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 5, "scene": 41, "line": "Hey, what time is it?", "order": 8877, "speaker": "Michael Scott"},
  {"season": 3, "episode": 5, "scene": 41, "line": "20 past 5.", "order": 8878, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 5, "scene": 41, "line": "AM or PM?", "order": 8879, "speaker": "Michael Scott"},
  {"season": 3, "episode": 5, "scene": 41, "line": "PM.", "order": 8880, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 5, "scene": 41, "line": "Oh, good.", "order": 8881, "speaker": "Michael Scott"},
  {"season": 3, "episode": 5, "scene": 41, "line": "These came for you. Contracts? Brent Koselli?", "order": 8882, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 5, "scene": 41, "line": "Oh. Koselli. With the Jello.", "order": 8883, "speaker": "Michael Scott"},
  {"season": 3, "episode": 5, "scene": 41, "line": "This is a huge sale.", "order": 8884, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 5, "scene": 41, "line": "Yes. Right. Good.", "order": 8885, "speaker": "Michael Scott"},
  {"season": 3, "episode": 5, "scene": 41, "line": "Night, Michael.", "order": 8886, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 5, "scene": 41, "line": "Goodnight!", "order": 8887, "speaker": "Michael Scott"},
  {"season": 3, "episode": 5, "scene": 41, "line": "[phone rings] Dunder Mifflin.", "order": 8888, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 5, "scene": 41, "line": "Ah, hey.", "order": 8889, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 5, "scene": 41, "line": "Oh my God.", "order": 8890, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 5, "scene": 41, "line": "Hi.", "order": 8891, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 5, "scene": 41, "line": "Hi.", "order": 8892, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 5, "scene": 41, "line": "Sorry, I forgot Kevin's extension. It's a fantasy football thing.", "order": 8893, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 5, "scene": 41, "line": "Oh.", "order": 8894, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 5, "scene": 41, "line": "And I was just going to go through the system cause I didn't think you'd be there. Why, why are you still there?", "order": 8895, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 5, "scene": 41, "line": "I had to work late. Jan's making me keep a log of everything Michael does all day.", "order": 8896, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 5, "scene": 41, "line": "Wow. Do you think you could send me a copy of that?", "order": 8897, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 5, "scene": 41, "line": "Yeah, totally. So...", "order": 8898, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 5, "scene": 41, "line": "So...", "order": 8899, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 5, "scene": 41, "line": "Do you...", "order": 8900, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 5, "scene": 41, "line": "Oh, I'm sorry. Go ahead.", "order": 8901, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 5, "scene": 41, "line": "Uh, no, I um. Everything's pretty much the same here.", "order": 8902, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 5, "scene": 41, "line": "Oh, good.", "order": 8903, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 5, "scene": 41, "line": "A little different. What time is it there?", "order": 8904, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 5, "scene": 41, "line": "What time is it here? Um, we're in the same time zone.", "order": 8905, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 5, "scene": 41, "line": "Oh, yeah. Right.", "order": 8906, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 5, "scene": 41, "line": "How far away did you think we were?", "order": 8907, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 5, "scene": 41, "line": "I don't know. It felt far.", "order": 8908, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 5, "scene": 41, "line": "Yeah. I have a question for you.", "order": 8909, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 5, "scene": 41, "line": "What?", "order": 8910, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 5, "scene": 41, "line": "How many words per minute does the average person type?", "order": 8911, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 5, "scene": 41, "line": "I type 90.", "order": 8912, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 5, "scene": 41, "line": "Shut up. Mavis Beacon doesn't even type 90.", "order": 8913, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 5, "scene": 41, "line": "It's true.", "order": 8914, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 5, "scene": 41, "line": "Ok, I said average.", "order": 8915, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 5, "scene": 41, "line": "70? How many do you type?", "order": 8916, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 5, "scene": 41, "line": "Forget it. I was just about to brag but forget it.", "order": 8917, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 5, "scene": 41, "line": "Come on. Tell me.", "order": 8918, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 5, "scene": 41, "line": "No.", "order": 8919, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 5, "scene": 41, "line": "You have to tell me now.", "order": 8920, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 5, "scene": 41, "line": "65. Ok, no need to laugh.", "order": 8921, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 5, "scene": 41, "line": "No, it's, that's respectable.", "order": 8922, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 5, "scene": 41, "line": "Respectable?", "order": 8923, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 5, "scene": 42, "line": "So ok. I'm watching the movie, by myself...", "order": 8924, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 5, "scene": 42, "line": "Right.", "order": 8925, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 5, "scene": 42, "line": "Because I just wanted a relaxing evening at home...", "order": 8926, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 5, "scene": 42, "line": "Ok.", "order": 8927, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 5, "scene": 42, "line": "And, I'm freaking out.", "order": 8928, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 5, "scene": 42, "line": "Yeah.", "order": 8929, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 5, "scene": 42, "line": "That movie is so scary!", "order": 8930, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 5, "scene": 42, "line": "I know!", "order": 8931, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 5, "scene": 42, "line": "But I'm holding on because I keep waiting for Sandra Bullock to show up.", "order": 8932, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 5, "scene": 42, "line": "No way. How do you confuse 28 Days with 28 Days Later?", "order": 8933, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 5, "scene": 42, "line": "Because I got it at Blockbuster and they don't put the pictures on the box.", "order": 8934, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 5, "scene": 42, "line": "No, you're making this up!", "order": 8935, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 5, "scene": 42, "line": "Would I make that up?", "order": 8936, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 5, "scene": 42, "line": "Yes. Fancy New Beesley would make that up. New apartment, new stories.", "order": 8937, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 5, "scene": 42, "line": "Oh, yeah, in my fancy new apartment. I have one bedroom, one bathroom, and a closet.", "order": 8938, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 5, "scene": 42, "line": "And how many kitchens?", "order": 8939, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 5, "scene": 42, "line": "I have one kitchen.", "order": 8940, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 5, "scene": 42, "line": "Wow, you got totally taken for a ride Beesley.", "order": 8941, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 5, "scene": 42, "line": "It's actually...", "order": 8942, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 5, "scene": 42, "line": "Most apartments these days have like three.", "order": 8943, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 5, "scene": 42, "line": "Three kitchens?", "order": 8944, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 5, "scene": 42, "line": "Yes! How are you going to cook every meal of the day in one kitchen?", "order": 8945, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 5, "scene": 42, "line": "[Ryan and Dwight enter] Hey, Ryan, are you ok?", "order": 8946, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 5, "scene": 42, "line": "Pam?", "order": 8947, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 5, "scene": 42, "line": "Yeah. Yeah.", "order": 8948, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 5, "scene": 42, "line": "Pam?", "order": 8949, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 5, "scene": 42, "line": "Um. Ok, bye.", "order": 8950, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 5, "scene": 42, "line": "Oh, yeah, I should, I should, I should probably go too.", "order": 8951, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 5, "scene": 42, "line": "No, I was um...", "order": 8952, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 5, "scene": 42, "line": "Oh, no no.", "order": 8953, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 5, "scene": 42, "line": "You have to go?", "order": 8954, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 5, "scene": 42, "line": "Yeah, uh, well.", "order": 8955, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 5, "scene": 42, "line": "No, I should probably go too.", "order": 8956, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 5, "scene": 42, "line": "Ok.", "order": 8957, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 5, "scene": 42, "line": "I mean, yeah.", "order": 8958, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 5, "scene": 42, "line": "Yeah. Bye Pam.", "order": 8959, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 5, "scene": 42, "line": "Bye Jim.", "order": 8960, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 5, "scene": 43, "line": "Three hundred and sixty four days, 'till the next Pretzel Day.", "order": 8961, "speaker": "Stanley Hudson"},
  {"season": 3, "episode": 5, "scene": 44, "line": "What I want, Michael, is an hour-by-hour log of how you spend your work time.", "order": 8962, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 5, "scene": 44, "line": "Well, that's really not the way I roll, because sometimes I'm in the zone, and sometimes I'm in the zoning out.", "order": 8963, "speaker": "Michael Scott"},
  {"season": 3, "episode": 5, "scene": 44, "line": "Zoning out?", "order": 8964, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 5, "scene": 44, "line": "Do you want me to go on Amazon and order you a slang dictionary? Cause I'll do it.", "order": 8965, "speaker": "Michael Scott"},
  {"season": 3, "episode": 5, "scene": 44, "line": "If you can account for your time...", "order": 8966, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 5, "scene": 44, "line": "Yes, big if.", "order": 8967, "speaker": "Michael Scott"},
  {"season": 3, "episode": 5, "scene": 44, "line": "...then maybe corporate can justify your salary.", "order": 8968, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 5, "scene": 44, "line": "A woman spurned.", "order": 8969, "speaker": "Michael Scott"},
  {"season": 3, "episode": 5, "scene": 45, "line": "It's like mommy and daddy are fighting. Except mommy outranks daddy and mommy is way scarier.", "order": 8970, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 5, "scene": 46, "line": "Take a good look at this boy...", "order": 8971, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 5, "scene": 46, "line": "I always do.", "order": 8972, "speaker": "Michael Scott"},
  {"season": 3, "episode": 5, "scene": 46, "line": "...because you are never going to see him again.", "order": 8973, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 5, "scene": 46, "line": "If you lay a finger...", "order": 8974, "speaker": "Michael Scott"},
  {"season": 3, "episode": 5, "scene": 46, "line": "Today is Ryan's first sales call and I am bringing this boy home a full-grown man.", "order": 8975, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 5, "scene": 46, "line": "Would you look at that? [Hugs Ryan] [Dwight attempts hug] Damn it, Dwight! Come on.", "order": 8976, "speaker": "Michael Scott"},
  {"season": 3, "episode": 5, "scene": 47, "line": "Dwight is taking Ryan on a sales call today. So if we find Ryan's body in a heavily-wooded area tomorrow, I owe Jim $30. It's an old bet, but a deal's a deal.", "order": 8977, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 5, "scene": 48, "line": "I once saw a movie called 'Training Day'. It starred an African-American and Ethan Hawke. In it, the African-American trains Ethan Hawke with various tests and trials. Today, Ryan is Ethan Hawke, and I am the African-American.", "order": 8978, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 5, "scene": 49, "line": "Get in the car.", "order": 8979, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 5, "scene": 49, "line": "I don't have a key.", "order": 8980, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 5, "scene": 49, "line": "Don't you realize the key is inside you? [pretends to pull key out of Ryan's ear]", "order": 8981, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 5, "scene": 50, "line": "He's the top salesman at the company. He's the top salesman at the company. He's the top salesman at the company. Today's gonna be great.", "order": 8982, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 5, "scene": 51, "line": "No, but seriously, your ears are the key. 90% of a good sales call is listening, only 10% is talking.", "order": 8983, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 5, "scene": 51, "line": "Huh.", "order": 8984, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 5, "scene": 51, "line": "Get in the car. [Ryan knocks the car door] Get... get in the car.", "order": 8985, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 5, "scene": 52, "line": "Sales is not all in here. [wails arms] It's also in here.", "order": 8986, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 5, "scene": 52, "line": "What was that first thing you did?", "order": 8987, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 5, "scene": 52, "line": "[wails arms] You know, selling, typing, dialing.", "order": 8988, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 5, "scene": 52, "line": "Oh.", "order": 8989, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 5, "scene": 52, "line": "What is that in your ear? [pretends to pull beet seed out of Ryan's ear] A tiny beet seed. You need to clean your ears better.", "order": 8990, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 5, "scene": 53, "line": "It's going okay. I've already learned more about beet agriculture than I ever thought I'd know.", "order": 8991, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 5, "scene": 54, "line": "9:00 to 10:00, checked in with receptionist. Met with Jan, RE: log. Lost log. Found log.' '10:00 to 11:00, light salad, three points.' That's on the wrong page.", "order": 8992, "speaker": "Michael Scott"},
  {"season": 3, "episode": 5, "scene": 54, "line": "Hey, let's focus today. Make today all about focus. I'll help you.", "order": 8993, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 5, "scene": 54, "line": "Okay. That sounds good. [puts banana in toy mouth that's in his mouth] [laughs] Yes. Actually, today's a good day to do that.", "order": 8994, "speaker": "Michael Scott"},
  {"season": 3, "episode": 5, "scene": 54, "line": "Yeah.", "order": 8995, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 5, "scene": 55, "line": "You know, every time I think I hit rock bottom at my job, the floor opens up, like at a carnival ride. I'm gonna retrace my steps. College, four-year degree, student loans, business school, alone in a beet field. I... there's a step missing. 'Hey, mom.' 'Hey, Ryan. How's that five-year plan coming?' 'Oh, it's great. Today, I knelt down in cow manure and I got abandoned in a beet field.' 'Oh, that's cool.' 'Yeah, that's really cool. I'm learning a lot. I'm really glad I took this full-time job.'", "order": 8996, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 5, "scene": 56, "line": "Sales is about people, Ryan. About understanding them and learning how to get along with them. Case study: me. To understand me, you have to know that I work in the city, but I live here on this small, beautiful farm. It's a family farm. Many Schrutes were born here and many of us end up here. My grandfather was a good man who did some very bad things. What the... Damn it! Those kids! [discovers two teenagers naked, running] You! You, get out of here! This is not a cheap motel! This is a farm and cemetery! Freaks! You're lucky I don't have my crossbow! Let me show you the compost pile.", "order": 8997, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 5, "scene": 57, "line": "[in the kitchen and Stanley comes in and swipes Kevin's pretzel without Kevin seeing] Shoot.", "order": 8998, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 5, "scene": 58, "line": "There's four things that I love. My wife, my daughters, Pam Grier, and a hot, chewy roll of buttered dough.", "order": 8999, "speaker": "Stanley Hudson"},
  {"season": 3, "episode": 5, "scene": 59, "line": "What makes them so good?", "order": 9000, "speaker": "Michael Scott"},
  {"season": 3, "episode": 5, "scene": 59, "line": "I do not know.", "order": 9001, "speaker": "Stanley Hudson"},
  {"season": 3, "episode": 5, "scene": 59, "line": "I mean, they're just dough twisted up with some candy. They taste so good in my mouth.", "order": 9002, "speaker": "Michael Scott"},
  {"season": 3, "episode": 5, "scene": 59, "line": "That's what she said. [Stanley and Michael both laugh]", "order": 9003, "speaker": "Stanley Hudson"},
  {"season": 3, "episode": 5, "scene": 60, "line": "Look, I've never seen a Clint Eastwood movie.", "order": 9004, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 5, "scene": 60, "line": "See, that's weird.", "order": 9005, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 5, "scene": 60, "line": "But if you said 'Go ahead, make my day,' I would know that it's from 'Dirty Harry'. How can you not know that's from 'Ferris Bueller's Day Off'?", "order": 9006, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 5, "scene": 60, "line": "Yo, mama! Ask me how bad I wanted to bone Jennifer Grey.", "order": 9007, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 5, "scene": 60, "line": "I saw the movie. I just don't know every line from the film.", "order": 9008, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 5, "scene": 60, "line": "Seriously, you guys, ask me.", "order": 9009, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 5, "scene": 60, "line": "You didn't know that 'Bueller?' was from Ferris Bueller?", "order": 9010, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 5, "scene": 60, "line": "Let's just say, me and Jennifer Grey probably would've broken a few laws.", "order": 9011, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 6, "scene": 1, "line": "Here you go.", "order": 9012, "speaker": "Kelly Kapoor"},
  {"season": 3, "episode": 6, "scene": 1, "line": "Nice dress, Ryan.", "order": 9013, "speaker": "Michael Scott"},
  {"season": 3, "episode": 6, "scene": 1, "line": "It's not a dress. It's a kurta.", "order": 9014, "speaker": "Kelly Kapoor"},
  {"season": 3, "episode": 6, "scene": 1, "line": "[laughing] OK.", "order": 9015, "speaker": "Michael Scott"},
  {"season": 3, "episode": 6, "scene": 2, "line": "Tonight, one of our most ethnic co-workers, Kelly, has invited us all to a Diwali celebration put on by her community. What is Diwali, you may ask? Well, to have Kelly explain it [girly voice] it's ada blah blah blah, it's so super fun and it's going to be great. [normal voice] Lot of gods with unpronounceable names. Twenty minutes later you find out that is essentially a Hindu Halloween.", "order": 9016, "speaker": "Michael Scott"},
  {"season": 3, "episode": 6, "scene": 3, "line": "You look so handsome.", "order": 9017, "speaker": "Kelly Kapoor"},
  {"season": 3, "episode": 6, "scene": 3, "line": "Really you do. I love the material.", "order": 9018, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 6, "scene": 3, "line": "I know.", "order": 9019, "speaker": "Kelly Kapoor"},
  {"season": 3, "episode": 6, "scene": 3, "line": "How come you didn't get me one?", "order": 9020, "speaker": "Michael Scott"},
  {"season": 3, "episode": 6, "scene": 3, "line": "I...", "order": 9021, "speaker": "Kelly Kapoor"},
  {"season": 3, "episode": 6, "scene": 4, "line": "Ok, so, between Meredith's Mini-van and if I borrow Bob's Yukon that should fit about twelve people.", "order": 9022, "speaker": "Phyllis Lapin"},
  {"season": 3, "episode": 6, "scene": 4, "line": "I actually might not go. Feeling kind of tired.", "order": 9023, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 6, "scene": 4, "line": "Do you want to make Appletini's and watch 'Sex and the City' at my place?", "order": 9024, "speaker": "Meredith Palmer"},
  {"season": 3, "episode": 6, "scene": 4, "line": "Oh, I don't know. I haven't decided yet.", "order": 9025, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 6, "scene": 5, "line": "I don't get why you won't go. Did I do something wrong? I mean, I thought we were really close friends.", "order": 9026, "speaker": "Kelly Kapoor"},
  {"season": 3, "episode": 6, "scene": 5, "line": "I just feel kind of tired, you know.", "order": 9027, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 6, "scene": 5, "line": "Maybe you've got mono.", "order": 9028, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 6, "scene": 5, "line": "Maybe. I just ...I don't really have anyone to go with.", "order": 9029, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 6, "scene": 5, "line": "Well, go with Dwight. He's single, too. Right?", "order": 9030, "speaker": "Kelly Kapoor"},
  {"season": 3, "episode": 6, "scene": 5, "line": "Yeah, totally single. Hundred percent available.", "order": 9031, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 6, "scene": 6, "line": "Are you guys going to this Indian thing tonight?", "order": 9032, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 6, "scene": 6, "line": "I don't know. Who's... uh, who's going?", "order": 9033, "speaker": "Roy Anderson"},
  {"season": 3, "episode": 6, "scene": 6, "line": "Oooh. Do you mean like, is Pam going?", "order": 9034, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 6, "scene": 6, "line": "Don't go. They eat monkey brains.", "order": 9035, "speaker": "Angela Martin"},
  {"season": 3, "episode": 6, "scene": 6, "line": "Hey. Hey. Stop that. That is offensive. Indians do not eat monkey brains. And if they do... sign me up... because I am sure that they are very tasty and nutritional.", "order": 9036, "speaker": "Michael Scott"},
  {"season": 3, "episode": 6, "scene": 7, "line": "It's important that this company celebrates its diversity. And you know what, Stanley? Come Kwanzaa time, I have got you covered, baby.", "order": 9037, "speaker": "Michael Scott"},
  {"season": 3, "episode": 6, "scene": 7, "line": "I don't celebrate Kwanzaa.", "order": 9038, "speaker": "Stanley Hudson"},
  {"season": 3, "episode": 6, "scene": 7, "line": "Wha? Really? You should! It's fun.", "order": 9039, "speaker": "Michael Scott"},
  {"season": 3, "episode": 6, "scene": 8, "line": "I love the people here. And if there was one thing I di... don't really care for is that they can be terribly, terribly ignorant about other cultures. And I don't want them embarrassing me in front of my girlfriend, Carol.", "order": 9040, "speaker": "Michael Scott"},
  {"season": 3, "episode": 6, "scene": 9, "line": "Diwali is a very important holiday for the Hindus. But, frankly, I'm a little appalled that none of you know very much about Indian culture. So, without further ado, Kelly you are on.", "order": 9041, "speaker": "Michael Scott"},
  {"season": 3, "episode": 6, "scene": 9, "line": "Um... Diwali is awesome... and there's food... and there's going to be dancing... and... Oh! I got the raddest outfit. It has, um...", "order": 9042, "speaker": "Kelly Kapoor"},
  {"season": 3, "episode": 6, "scene": 9, "line": "Kelly?", "order": 9043, "speaker": "Michael Scott"},
  {"season": 3, "episode": 6, "scene": 9, "line": "Sparkles...", "order": 9044, "speaker": "Kelly Kapoor"},
  {"season": 3, "episode": 6, "scene": 9, "line": "Um... why don't you tell us a little bit about the origins of the holiday.", "order": 9045, "speaker": "Michael Scott"},
  {"season": 3, "episode": 6, "scene": 9, "line": "Oh, um... I don't know. It's really old, I think.", "order": 9046, "speaker": "Kelly Kapoor"},
  {"season": 3, "episode": 6, "scene": 9, "line": "How many gods do you have?", "order": 9047, "speaker": "Angela Martin"},
  {"season": 3, "episode": 6, "scene": 9, "line": "Like hundreds, I think. Maybe more than that.", "order": 9048, "speaker": "Kelly Kapoor"},
  {"season": 3, "episode": 6, "scene": 9, "line": "[points at picture on the wall] And that blue busty gal? What's her story?", "order": 9049, "speaker": "Angela Martin"},
  {"season": 3, "episode": 6, "scene": 9, "line": "She looks like Pam from the neck down.", "order": 9050, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 6, "scene": 9, "line": "Pam wishes. [generalized laughing] Kelly, I'll take this one. Diwali is a Celebration of the Coronation of the God-King Rama. After his epic battle with Ravana, the Demon King of Lanka. It symbolizes the battle between good and evil...", "order": 9051, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 6, "scene": 9, "line": "All right, all right, all right, all right. This isn't 'Lord of the Rings'.", "order": 9052, "speaker": "Michael Scott"},
  {"season": 3, "episode": 6, "scene": 10, "line": "Sorry.", "order": 9053, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 6, "scene": 11, "line": "I started biking into work. Josh does it and he lives a lot farther away than I do. And also it saves gas money, keeps me in shape... helps the environment. And now I know it makes me really sweaty for work.", "order": 9054, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 6, "scene": 12, "line": "Nice basket.", "order": 9055, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 6, "scene": 12, "line": "Thank you.", "order": 9056, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 6, "scene": 13, "line": "Now, a lot of people say that Kelly is one in a million and that's true, but it's also not true. Because, frankly, there are literally billions of people just like Kelly in the world. Here are some famous Indians. [slide show] Subrahmanyan Chandrasekhar. He is a Nobel prize-winning physicist. Impressive. Apu from the Simpsons. Hilarious. Indian. M. Night Shyamalan. 'The Village', 'Unbreakable', 'Sixth Sense', 'Sig... '", "order": 9057, "speaker": "Michael Scott"},
  {"season": 3, "episode": 6, "scene": 13, "line": "I see dead people.", "order": 9058, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 6, "scene": 13, "line": "Okay. Spoiler... alert.", "order": 9059, "speaker": "Michael Scott"},
  {"season": 3, "episode": 6, "scene": 13, "line": "He was dead the whole time.", "order": 9060, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 6, "scene": 13, "line": "Just stop it. [slide of Michael & Carol kissing] What's the... oh, whoa! [laughs] Where did that come from?", "order": 9061, "speaker": "Michael Scott"},
  {"season": 3, "episode": 6, "scene": 14, "line": "Karen, my chips got stuck in the vending machine again. I need your skinny, little arms.", "order": 9062, "speaker": "Tony"},
  {"season": 3, "episode": 6, "scene": 14, "line": "Oh. Did you shake it?", "order": 9063, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 6, "scene": 14, "line": "Yeah, I shook it, I shook it.", "order": 9064, "speaker": "Tony"},
  {"season": 3, "episode": 6, "scene": 15, "line": "We have such a roller coaster thing, Karen and I.", "order": 9065, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 6, "scene": 15, "line": "Scuse me?", "order": 9066, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 6, "scene": 15, "line": "Roller-coastery friendship. Hot. Cold. On again. Off again. Sexual tension filled type of deal. It's very Sam and Diane.", "order": 9067, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 6, "scene": 15, "line": "Wow.", "order": 9068, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 6, "scene": 15, "line": "From 'Cheers'.", "order": 9069, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 6, "scene": 15, "line": "Yup.", "order": 9070, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 6, "scene": 16, "line": "And another thing about the Indian people... they love sex positions. I present to you the Kama Sutra. I mean look at that. Who's seen that before?", "order": 9071, "speaker": "Michael Scott"},
  {"season": 3, "episode": 6, "scene": 16, "line": "I have. That's the 'Union of the Monkey'.", "order": 9072, "speaker": "Creed Bratton"},
  {"season": 3, "episode": 6, "scene": 16, "line": "Oh, that's what they call it.", "order": 9073, "speaker": "Meredith Palmer"},
  {"season": 3, "episode": 6, "scene": 16, "line": "This is the best meeting we've ever had.", "order": 9074, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 6, "scene": 16, "line": "Thank you, Kevin.", "order": 9075, "speaker": "Michael Scott"},
  {"season": 3, "episode": 6, "scene": 16, "line": "I find this incredibly offensive.", "order": 9076, "speaker": "Angela Martin"},
  {"season": 3, "episode": 6, "scene": 16, "line": "Well, I find it beautiful.", "order": 9077, "speaker": "Michael Scott"},
  {"season": 3, "episode": 6, "scene": 16, "line": "Well, whatever Kelly wants to do in her own house is fine but we shouldn't all be subjected to it.", "order": 9078, "speaker": "Angela Martin"},
  {"season": 3, "episode": 6, "scene": 16, "line": "No...", "order": 9079, "speaker": "Michael Scott"},
  {"season": 3, "episode": 6, "scene": 16, "line": "Actually, she's right. This isn't appropriate. Why don't I take these.", "order": 9080, "speaker": "Toby Flenderson"},
  {"season": 3, "episode": 6, "scene": 16, "line": "No, You're not going to collect them.", "order": 9081, "speaker": "Michael Scott"},
  {"season": 3, "episode": 6, "scene": 16, "line": "Yes.", "order": 9082, "speaker": "Toby Flenderson"},
  {"season": 3, "episode": 6, "scene": 16, "line": "No. This is delightful, charming culture.", "order": 9083, "speaker": "Michael Scott"},
  {"season": 3, "episode": 6, "scene": 17, "line": "My Indian Culture Seminar was going great until Toby decided that he was too immature to deal with culturally explicit images. It's just sex. People... everybody does it. I'm doing it... with Carol! Probably tonight.", "order": 9084, "speaker": "Michael Scott"},
  {"season": 3, "episode": 6, "scene": 18, "line": "All right. Think you guys should be all set. Oh, here's the corporate card for dinner.", "order": 9085, "speaker": "Josh Porter"},
  {"season": 3, "episode": 6, "scene": 18, "line": "Thanks.", "order": 9086, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 6, "scene": 18, "line": "And Karen? Let's keep it to twenty dollars a person this time.", "order": 9087, "speaker": "Josh Porter"},
  {"season": 3, "episode": 6, "scene": 18, "line": "Got it.", "order": 9088, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 6, "scene": 19, "line": "Once a quarter, the sales staff at this branch has to stay late to do order form consolidation... which, amazingly, is even less interesting than it sounds.", "order": 9089, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 6, "scene": 20, "line": "You guys ready to party?", "order": 9090, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 6, "scene": 20, "line": "What's that?", "order": 9091, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 6, "scene": 20, "line": "I said are you ready TO PARTY!", "order": 9092, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 6, "scene": 21, "line": "Isn't this fun? Not wearing shoes?", "order": 9093, "speaker": "Phyllis Lapin"},
  {"season": 3, "episode": 6, "scene": 21, "line": "I wish some of us still had our shoes on.", "order": 9094, "speaker": "Angela Martin"},
  {"season": 3, "episode": 6, "scene": 21, "line": "Stop it. It's a disease! I've told you.", "order": 9095, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 6, "scene": 22, "line": "[wearing cheerleader costume] I thought you said this was a costume party!", "order": 9096, "speaker": "Carol Stills"},
  {"season": 3, "episode": 6, "scene": 22, "line": "[points at woman] What does that look like to you?", "order": 9097, "speaker": "Michael Scott"},
  {"season": 3, "episode": 6, "scene": 22, "line": "An Indian woman in a sari.", "order": 9098, "speaker": "Carol Stills"},
  {"season": 3, "episode": 6, "scene": 22, "line": "No one's even going to notice.", "order": 9099, "speaker": "Michael Scott"},
  {"season": 3, "episode": 6, "scene": 22, "line": "Nice outfit.", "order": 9100, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 6, "scene": 22, "line": "Hey, Kevin. It's a costume. Why don't you just cool it, okay? Carol? Carol.", "order": 9101, "speaker": "Michael Scott"},
  {"season": 3, "episode": 6, "scene": 23, "line": "I'll have one of those as well. Thank you very much. Now these are limes, lemons, onions...", "order": 9102, "speaker": "Michael Scott"},
  {"season": 3, "episode": 6, "scene": 23, "line": "I'm a vegetarian. What can I eat?", "order": 9103, "speaker": "Angela Martin"},
  {"season": 3, "episode": 6, "scene": 23, "line": "It's all vegetarian.", "order": 9104, "speaker": "Server"},
  {"season": 3, "episode": 6, "scene": 23, "line": "I'll just have some bread. You used your hands.", "order": 9105, "speaker": "Angela Martin"},
  {"season": 3, "episode": 6, "scene": 24, "line": "Oh, yuck. [spits out food]", "order": 9106, "speaker": "Michael Scott"},
  {"season": 3, "episode": 6, "scene": 24, "line": "What? Too spicy?", "order": 9107, "speaker": "Carol Stills"},
  {"season": 3, "episode": 6, "scene": 24, "line": "No. These s'mores are disgusting.", "order": 9108, "speaker": "Michael Scott"},
  {"season": 3, "episode": 6, "scene": 24, "line": "They're not s'mores. They're samosas.", "order": 9109, "speaker": "Carol Stills"},
  {"season": 3, "episode": 6, "scene": 24, "line": "Do you think they have any s'mores?", "order": 9110, "speaker": "Michael Scott"},
  {"season": 3, "episode": 6, "scene": 25, "line": "All they are is chocolate, graham cracker, and marshmallow. How difficult would that have been?", "order": 9111, "speaker": "Michael Scott"},
  {"season": 3, "episode": 6, "scene": 26, "line": "So, you're Kelly's sisters, huh?", "order": 9112, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 6, "scene": 26, "line": "[laugh] Kelly Zach Braff [speaks in Hindi]", "order": 9113, "speaker": "Girls"},
  {"season": 3, "episode": 6, "scene": 26, "line": "What?", "order": 9114, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 6, "scene": 26, "line": "Ruka, Nipa, Tiffany. Stop acting like such little losers and just be cool. Come on, Ryan. Come on. Leave him alone. I hate you guys.", "order": 9115, "speaker": "Kelly Kapoor"},
  {"season": 3, "episode": 6, "scene": 26, "line": "They said something about Zach Braff.", "order": 9116, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 6, "scene": 26, "line": "Don't even listen to them. They're so...", "order": 9117, "speaker": "Kelly Kapoor"},
  {"season": 3, "episode": 6, "scene": 26, "line": "No, you don't...", "order": 9118, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 6, "scene": 27, "line": "Very official.", "order": 9119, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 6, "scene": 28, "line": "I decided to come. Uh... I feel a little under-dressed... but at least I'm not dressed like a slutty cheerleader, right? Is that mean?", "order": 9120, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 6, "scene": 29, "line": "Temp! Temp! Pflut! Pflut!", "order": 9121, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 6, "scene": 30, "line": "I don't even want to hear it. Okay. I didn't come this Diwali to get yelled at!", "order": 9122, "speaker": "Kelly Kapoor"},
  {"season": 3, "episode": 6, "scene": 30, "line": "Stop it right now. Ryan is a temporary worker, makes no money. Wally is a whole doctor. So handsome.", "order": 9123, "speaker": "Kelly Kapoor's Mom"},
  {"season": 3, "episode": 6, "scene": 30, "line": "Uh... excuse me. I want to get a...", "order": 9124, "speaker": "Kelly Kapoor"},
  {"season": 3, "episode": 6, "scene": 30, "line": "He's a perfect match.", "order": 9125, "speaker": "Kelly Kapoor's Mom"},
  {"season": 3, "episode": 6, "scene": 31, "line": "Hey, Big Tuna, you ready?", "order": 9126, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 6, "scene": 31, "line": "Yep.", "order": 9127, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 6, "scene": 31, "line": "One. Two. Three. Shot!", "order": 9128, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 6, "scene": 31, "line": "Oh, Holy Mother of God.", "order": 9129, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 6, "scene": 31, "line": "Oh, that burns! Golly. Um...", "order": 9130, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 6, "scene": 31, "line": "Good.", "order": 9131, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 6, "scene": 31, "line": "Ooh.", "order": 9132, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 6, "scene": 32, "line": "[song by Beyonce Knowles] Looking so crazy, my baby. I'm not myself lately. I'm foolish. I don't do this. I've been playing myself. Baby I don't care. Cuz your love got the best of me. And baby you're making a fool of me.", "order": 9133, "speaker": "Overhead"},
  {"season": 3, "episode": 6, "scene": 33, "line": "Wow, thirty years? And you two only met once before the wedding night?", "order": 9134, "speaker": "Michael Scott"},
  {"season": 3, "episode": 6, "scene": 33, "line": "Yes.", "order": 9135, "speaker": "Kelly Kapoor's Father"},
  {"season": 3, "episode": 6, "scene": 33, "line": "Wow.", "order": 9136, "speaker": "Michael Scott"},
  {"season": 3, "episode": 6, "scene": 33, "line": "How long have you been married to the cheerleader?", "order": 9137, "speaker": "Kelly Kapoor's Father"},
  {"season": 3, "episode": 6, "scene": 33, "line": "Oh! She's not a cheerleader. She thought this was a costume party! Um... no, we're not married... yet!", "order": 9138, "speaker": "Michael Scott"},
  {"season": 3, "episode": 6, "scene": 33, "line": "She is very fair.", "order": 9139, "speaker": "Kelly Kapoor's Mom"},
  {"season": 3, "episode": 6, "scene": 33, "line": "She is. Very fair and very kind. So... um... tell me, is your marriage the kind of thing where when you die she has to throw herself on a fire? No? Okay. It's still very cool. Ok. Thanks!", "order": 9140, "speaker": "Michael Scott"},
  {"season": 3, "episode": 6, "scene": 34, "line": "One. Two. Three. Shot!", "order": 9141, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 6, "scene": 34, "line": "Oh.", "order": 9142, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 6, "scene": 35, "line": "Are you okay?", "order": 9143, "speaker": "Carol Stills"},
  {"season": 3, "episode": 6, "scene": 35, "line": "I'm going to be. [to DJ] Hi, I'm just going to get this for a sec... just a sec. [speaks in microphone] Um... everyone? I'm sorry, could I have your attention, please. Thank you. Ah-hah... Hi. Sorry. I just have an announcement to make. Um... okay. I have learned a lot about Indian culture tonight. But I have learned even more about love. And I know you're all thinking 'who is this crazy gringo and what is he talking about?' Well, I'm not crazy. Maybe I'm crazy in love. So without further ado, Carol? Carol Stills. I would like you to do me the honor of making me your husband.", "order": 9144, "speaker": "Michael Scott"},
  {"season": 3, "episode": 6, "scene": 35, "line": "Awww!", "order": 9145, "speaker": "Crowd"},
  {"season": 3, "episode": 6, "scene": 35, "line": "Oh, Michael.", "order": 9146, "speaker": "Carol Stills"},
  {"season": 3, "episode": 6, "scene": 35, "line": "What do you say?", "order": 9147, "speaker": "Michael Scott"},
  {"season": 3, "episode": 6, "scene": 35, "line": "Can we talk about this in private?", "order": 9148, "speaker": "Carol Stills"},
  {"season": 3, "episode": 6, "scene": 35, "line": "I didn't hear you. [laughs]", "order": 9149, "speaker": "Michael Scott"},
  {"season": 3, "episode": 6, "scene": 35, "line": "[louder] Can we talk about this in private?", "order": 9150, "speaker": "Carol Stills"},
  {"season": 3, "episode": 6, "scene": 35, "line": "[lowers microphone] Oh, you've got to be kidding me. Okay.", "order": 9151, "speaker": "Michael Scott"},
  {"season": 3, "episode": 6, "scene": 36, "line": "No. I get it. I get it. You're not ready. We'll wait. This is a classic...", "order": 9152, "speaker": "Michael Scott"},
  {"season": 3, "episode": 6, "scene": 36, "line": "This is the ninth date, Michael.", "order": 9153, "speaker": "Carol Stills"},
  {"season": 3, "episode": 6, "scene": 36, "line": "Yeah, well, but I ... I feel like I've known you many lifetimes. Maybe I'm Hindu after all. Okay, I'm not Hindu, but... Carol. Carol, I just... I feel like... I just like you so much.", "order": 9154, "speaker": "Michael Scott"},
  {"season": 3, "episode": 6, "scene": 36, "line": "I better go. Okay, you can find a way home, all right?", "order": 9155, "speaker": "Carol Stills"},
  {"season": 3, "episode": 6, "scene": 36, "line": "Yes.", "order": 9156, "speaker": "Michael Scott"},
  {"season": 3, "episode": 6, "scene": 36, "line": "Okay.", "order": 9157, "speaker": "Carol Stills"},
  {"season": 3, "episode": 6, "scene": 36, "line": "Ok. Okay. Good night. [louder] Hey, you know what? Why don't I come with you. Cause I've got this book called the Kama Sutra.", "order": 9158, "speaker": "Michael Scott"},
  {"season": 3, "episode": 6, "scene": 36, "line": "Okay, good night, Michael.", "order": 9159, "speaker": "Carol Stills"},
  {"season": 3, "episode": 6, "scene": 36, "line": "All right. Good night.", "order": 9160, "speaker": "Michael Scott"},
  {"season": 3, "episode": 6, "scene": 37, "line": "Well, I was a Temp but I got promoted. So, um... the compensation is a lot more competitive.", "order": 9161, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 6, "scene": 37, "line": "So you're saving money...", "order": 9162, "speaker": "Kelly Kapoor's Mom"},
  {"season": 3, "episode": 6, "scene": 37, "line": "Yes.", "order": 9163, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 6, "scene": 37, "line": "...to start a family and home.", "order": 9164, "speaker": "Kelly Kapoor's Mom"},
  {"season": 3, "episode": 6, "scene": 37, "line": "Oh, um... or travel. And,um... and buy an Xbox.", "order": 9165, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 6, "scene": 37, "line": "Is there anything you wanted to ask us tonight?", "order": 9166, "speaker": "Kelly Kapoor's Father"},
  {"season": 3, "episode": 6, "scene": 38, "line": "Can you believe my boss proposed to his girlfriend in public? That is so Michael.", "order": 9167, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 6, "scene": 38, "line": "Is it? He's really outgoing, huh?", "order": 9168, "speaker": "Young Man"},
  {"season": 3, "episode": 6, "scene": 38, "line": "Yeah. Hey, would you excuse me for a second?", "order": 9169, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 6, "scene": 39, "line": "It's hot in there. How's the naan?", "order": 9170, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 6, "scene": 39, "line": "Dry. You look like you were having fun.", "order": 9171, "speaker": "Angela Martin"},
  {"season": 3, "episode": 6, "scene": 39, "line": "I am. You should come dance with us.", "order": 9172, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 6, "scene": 39, "line": "I have to watch our shoes so they don't get stolen. Who were you texting?", "order": 9173, "speaker": "Angela Martin"},
  {"season": 3, "episode": 6, "scene": 39, "line": "No one.", "order": 9174, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 6, "scene": 40, "line": "[sings Indigo Girls] I went to the doctor. I went to the mountains. I looked to the children...", "order": 9175, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 6, "scene": 40, "line": "Andy, no acappella.", "order": 9176, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 6, "scene": 40, "line": "[sings] I looked to the children, I drank from the fountain.", "order": 9177, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 6, "scene": 40, "line": "[sing] There's more than one answer to these questions pointing me in a crooked line", "order": 9178, "speaker": "Andy Bernard & Jim Halpert"},
  {"season": 3, "episode": 6, "scene": 40, "line": "Wait, wait.", "order": 9179, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 6, "scene": 40, "line": "[sing] the less I seek my source...", "order": 9180, "speaker": "Andy Bernard & Jim Halpert"},
  {"season": 3, "episode": 6, "scene": 40, "line": "Oh, come on, guys. Please.", "order": 9181, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 6, "scene": 40, "line": "[sing] the closer I am to fi-i-i-ne. The closer I am to..", "order": 9182, "speaker": "Andy Bernard & Jim Halpert"},
  {"season": 3, "episode": 6, "scene": 40, "line": "It's not good.", "order": 9183, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 6, "scene": 40, "line": "[sing] fi-i-i-ine!", "order": 9184, "speaker": "Andy Bernard & Jim Halpert"},
  {"season": 3, "episode": 6, "scene": 40, "line": "TUNA! Are you kidding me!!", "order": 9185, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 6, "scene": 41, "line": "Oh, God! [chokes on food] Oh. Wow.", "order": 9186, "speaker": "Michael Scott"},
  {"season": 3, "episode": 6, "scene": 41, "line": "Here. [hands drink]", "order": 9187, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 6, "scene": 41, "line": "That's so spicy.", "order": 9188, "speaker": "Michael Scott"},
  {"season": 3, "episode": 6, "scene": 41, "line": "Yeah.", "order": 9189, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 6, "scene": 41, "line": "Oh. You waiting for a call?", "order": 9190, "speaker": "Michael Scott"},
  {"season": 3, "episode": 6, "scene": 41, "line": "Uh... no.", "order": 9191, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 6, "scene": 41, "line": "Wow. Pam. When Carol said 'No.' tonight, I think I finally realized how you must be feeling. We are both the victims of broken engagements.", "order": 9192, "speaker": "Michael Scott"},
  {"season": 3, "episode": 6, "scene": 41, "line": "Well, you were never really engaged.", "order": 9193, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 6, "scene": 41, "line": "I was in that marriage arena, though.", "order": 9194, "speaker": "Michael Scott"},
  {"season": 3, "episode": 6, "scene": 41, "line": "Yeah.", "order": 9195, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 6, "scene": 41, "line": "Yeah. Uuuuh... well.", "order": 9196, "speaker": "Michael Scott"},
  {"season": 3, "episode": 6, "scene": 41, "line": "I kind of thought something would happen tonight too.", "order": 9197, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 6, "scene": 41, "line": "We're so alike. So alike. [leans in to kiss her]", "order": 9198, "speaker": "Michael Scott"},
  {"season": 3, "episode": 6, "scene": 41, "line": "What are you doing?", "order": 9199, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 6, "scene": 41, "line": "What are you doing?", "order": 9200, "speaker": "Michael Scott"},
  {"season": 3, "episode": 6, "scene": 41, "line": "I'm rejecting your...", "order": 9201, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 6, "scene": 41, "line": "I'm... what? I didn't", "order": 9202, "speaker": "Michael Scott"},
  {"season": 3, "episode": 6, "scene": 41, "line": "...kiss.", "order": 9203, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 6, "scene": 41, "line": "[scoffing noises] Can I have a ride home?", "order": 9204, "speaker": "Michael Scott"},
  {"season": 3, "episode": 6, "scene": 41, "line": "If you sit in the back.", "order": 9205, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 6, "scene": 42, "line": "Goodnight, guys.", "order": 9206, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 6, "scene": 42, "line": "Can I have a ride, man? I... uh... I have my bike.", "order": 9207, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 6, "scene": 42, "line": "No way, dude. I am not driving home. I have brought an inflatable bed for just such occasions. You're welcome to share it though. It's a roomy twin.", "order": 9208, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 6, "scene": 42, "line": "Okay.", "order": 9209, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 6, "scene": 43, "line": "Oh.", "order": 9210, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 6, "scene": 43, "line": "Hey dummy, get in the car!", "order": 9211, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 6, "scene": 43, "line": "I'm a drunk driver.", "order": 9212, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 6, "scene": 43, "line": "Yes, you are. Here. Let me take that. Just... uh... get in the car.", "order": 9213, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 6, "scene": 43, "line": "Man, you can really hold your liquor, Billabelli.", "order": 9214, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 6, "scene": 43, "line": "Yeah, you can't.", "order": 9215, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 6, "scene": 43, "line": "No kidding. And I am just going to lie down in the back, if that's all right.", "order": 9216, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 6, "scene": 43, "line": "Sure. Here's your bag. Just don't puke on anything. You okay?", "order": 9217, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 6, "scene": 43, "line": "So good.", "order": 9218, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 6, "scene": 43, "line": "Good.", "order": 9219, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 6, "scene": 44, "line": "These are not my shoes. This is just like that show 'Taxi Cab Confessions'.", "order": 9220, "speaker": "Michael Scott"},
  {"season": 3, "episode": 6, "scene": 44, "line": "You say one more word; I'm stopping the car.", "order": 9221, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 6, "scene": 44, "line": "Sorry.", "order": 9222, "speaker": "Michael Scott"},
  {"season": 3, "episode": 6, "scene": 45, "line": "This is going out to Indians everywhere. It's a tribute to one of the greats... Mr. Adam Sandler. [sings] Diwali is a festival of lights. Let me tell you something. Tonight has been one crazy night. So put on your saris, it's time to celebrate Diwali. Everybody looks so jolly. But it's not Christmas, it's Diwali. The goddess of destruction Kali stopped by to celebrate Diwali. Don't invite any zombies to a celebration of Diwali. Along came Polly to have some fun at Diwali. If you're Indian and you love to party, have a happy, happy, happy, happy Diwali. Happy Diwali!", "order": 9223, "speaker": "Michael Scott"},
  {"season": 3, "episode": 6, "scene": 45, "line": "[loud clapping, cheering, and whistling]", "order": 9224, "speaker": "Crowd"},
  {"season": 3, "episode": 6, "scene": 46, "line": "How do I explain this to Jan?", "order": 9225, "speaker": "Michael Scott"},
  {"season": 3, "episode": 6, "scene": 46, "line": "Um, well, basically, it's like a really big party, and everybody gets super-dressed up, and there's a bunch of different gods, and each of the gods has a special power, like the Care Bears, you know?", "order": 9226, "speaker": "Kelly Kapoor"},
  {"season": 3, "episode": 6, "scene": 46, "line": "Oh, please, stop talking. Three words or less.", "order": 9227, "speaker": "Michael Scott"},
  {"season": 3, "episode": 6, "scene": 46, "line": "Like, an Indian Halloween?", "order": 9228, "speaker": "Kelly Kapoor"},
  {"season": 3, "episode": 6, "scene": 46, "line": "An Indian Halloween. Okay, great. [to phone] Pam, could you put Jan on, please?", "order": 9229, "speaker": "Michael Scott"},
  {"season": 3, "episode": 6, "scene": 46, "line": "[on phone] Michael, you're on with Jan.", "order": 9230, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 6, "scene": 46, "line": "Hello, Jan.", "order": 9231, "speaker": "Michael Scott"},
  {"season": 3, "episode": 6, "scene": 46, "line": "Michael, what is this about you letting everyone leave work early today?", "order": 9232, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 6, "scene": 46, "line": "Well, I was letting everybody go to the big Diwali party.", "order": 9233, "speaker": "Michael Scott"},
  {"season": 3, "episode": 6, "scene": 46, "line": "What is Diwali?", "order": 9234, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 6, "scene": 46, "line": "What is Diwali?", "order": 9235, "speaker": "Michael Scott"},
  {"season": 3, "episode": 6, "scene": 46, "line": "Yes.", "order": 9236, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 6, "scene": 46, "line": "You don't know what Diwali is? Wow, Jan. I'm surprised. It's the Hindu festival of lights. I just assumed you'd be familiar with it. It's the most sacred and honored Hindu holiday in the world. It's like Christmas, Easter, and Halloween---", "order": 9237, "speaker": "Michael Scott"},
  {"season": 3, "episode": 6, "scene": 46, "line": "Mich---", "order": 9238, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 6, "scene": 46, "line": "---rolled into one.", "order": 9239, "speaker": "Michael Scott"},
  {"season": 3, "episode": 6, "scene": 46, "line": "Oh.", "order": 9240, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 6, "scene": 46, "line": "I bet you're not even aware that our own Kelly Kapoor, who works in...", "order": 9241, "speaker": "Michael Scott"},
  {"season": 3, "episode": 6, "scene": 46, "line": "Um, customer service.", "order": 9242, "speaker": "Kelly Kapoor"},
  {"season": 3, "episode": 6, "scene": 46, "line": "... customer service is a Hindu person.", "order": 9243, "speaker": "Michael Scott"},
  {"season": 3, "episode": 6, "scene": 46, "line": "Oh.", "order": 9244, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 6, "scene": 46, "line": "And.", "order": 9245, "speaker": "Michael Scott"},
  {"season": 3, "episode": 6, "scene": 46, "line": "Yes, of course, I know Kelly.", "order": 9246, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 6, "scene": 46, "line": "Yeah.", "order": 9247, "speaker": "Michael Scott"},
  {"season": 3, "episode": 6, "scene": 46, "line": "I thought she was Muslim.", "order": 9248, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 6, "scene": 46, "line": "What? I'm not Muslim!", "order": 9249, "speaker": "Kelly Kapoor"},
  {"season": 3, "episode": 6, "scene": 46, "line": "Well, I think it sounds lovely. I think it sounds like a good idea.", "order": 9250, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 6, "scene": 46, "line": "You do?", "order": 9251, "speaker": "Michael Scott"},
  {"season": 3, "episode": 6, "scene": 46, "line": "Yes, of course. It's important to celebrate our company's rich diversity. And, and in fact, Michael, if you had planned better, we might have been able to charter a bus to the event---", "order": 9252, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 6, "scene": 46, "line": "Oh, I dunno about that.", "order": 9253, "speaker": "Michael Scott"},
  {"season": 3, "episode": 6, "scene": 46, "line": "---or, or, or been a sponsor for the party itself.", "order": 9254, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 6, "scene": 46, "line": "For Kelly, that seems a bit much.", "order": 9255, "speaker": "Michael Scott"},
  {"season": 3, "episode": 6, "scene": 46, "line": "Hey, one person is an integral part of the fabric---", "order": 9256, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 6, "scene": 46, "line": "[hangs up]", "order": 9257, "speaker": "Michael Scott"},
  {"season": 3, "episode": 6, "scene": 46, "line": "She's so wonderful.", "order": 9258, "speaker": "Kelly Kapoor"},
  {"season": 3, "episode": 6, "scene": 46, "line": "Yeah, you should see her naked.", "order": 9259, "speaker": "Michael Scott"},
  {"season": 3, "episode": 6, "scene": 47, "line": "I had promised Carol a romantic evening on the town. Um, I wanted to take her on this spooky hay ride thing, but it was like seventy dollars a person, and she's allergic to hay. So I said, 'Pop a Claritin, and I will spot you the seventy bucks,' and she's like 'I don't like to borrow money or take unnecessary medication.' And I'm like, 'Well you're really not gonna like what I slipped in your drink last night.' And she's like, 'What the hell are you talking about?' And I'm like, 'I'm kidding. I didn't put a roof...' [cracks himself up] ... We laughed so hard... It was hilarious... oh... [calms down] So yes, I'm very excited about the Diwali party.", "order": 9260, "speaker": "Michael Scott"},
  {"season": 3, "episode": 6, "scene": 48, "line": "For Diwali 07, you must consider Scrantonicity. For a fraction of the cost of this DJ, you could have the rocking sound stylings of a Police cover band.", "order": 9261, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 6, "scene": 48, "line": "No Police cover.", "order": 9262, "speaker": "Mrs. Kapoor"},
  {"season": 3, "episode": 6, "scene": 48, "line": "No, okay, well, let me send you a demo, and... Better yet, I'll give Kelly the demo, and she can give it to you. It'll save us both on postage.", "order": 9263, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 6, "scene": 48, "line": "[nods]", "order": 9264, "speaker": "Mr. Kapoor"},
  {"season": 3, "episode": 6, "scene": 48, "line": "Excellent.", "order": 9265, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 6, "scene": 49, "line": "You're the temp!", "order": 9266, "speaker": "Carol Stills"},
  {"season": 3, "episode": 6, "scene": 49, "line": "Um, yeah. [shakes her hand] Ryan.", "order": 9267, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 6, "scene": 49, "line": "Hi, I'm Carol.", "order": 9268, "speaker": "Carol Stills"},
  {"season": 3, "episode": 6, "scene": 49, "line": "Hi, nice to meet you.", "order": 9269, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 6, "scene": 49, "line": "The wavy brown hair and blue eyes.", "order": 9270, "speaker": "Carol Stills"},
  {"season": 3, "episode": 6, "scene": 49, "line": "Ah.", "order": 9271, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 6, "scene": 49, "line": "Michael talks about you... a lot.", "order": 9272, "speaker": "Carol Stills"},
  {"season": 3, "episode": 6, "scene": 49, "line": "Yeah.", "order": 9273, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 6, "scene": 49, "line": "Top of your class at business school, and you live on Shady Hill Road, right?", "order": 9274, "speaker": "Carol Stills"},
  {"season": 3, "episode": 6, "scene": 49, "line": "Wow, um... Sorry you had to...", "order": 9275, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 6, "scene": 49, "line": "Does Michael talk about", "order": 9276, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 6, "scene": 49, "line": "[pause] Yes.", "order": 9277, "speaker": "Carol Stills"},
  {"season": 3, "episode": 6, "scene": 49, "line": "He does? What does he say.", "order": 9278, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 6, "scene": 49, "line": "He says... 'I love him.' He loves you.", "order": 9279, "speaker": "Carol Stills"},
  {"season": 3, "episode": 6, "scene": 50, "line": "At Cornell, in my fraternity, my house name was 'Hubble' because I was so good at finding a party. I was like a powerful space telescope, so... Freshman year, when my skin was still really bad, they called me El Guapo.", "order": 9280, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 6, "scene": 51, "line": "Yeah. Big Tuna, tuna?", "order": 9281, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 6, "scene": 51, "line": "Nope, I got eel.", "order": 9282, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 6, "scene": 51, "line": "[speechless]", "order": 9283, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 6, "scene": 51, "line": "[looking for Jim's sushi] Eel... eel.", "order": 9284, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 6, "scene": 51, "line": "Didn't see that coming.", "order": 9285, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 6, "scene": 52, "line": "And perhaps the most important person to Indian culture, Sir Ben Kingsley. Because of him, the British left India, and then he became an actor like Ronald Reagan.", "order": 9286, "speaker": "Michael Scott"},
  {"season": 3, "episode": 6, "scene": 52, "line": "Michael?", "order": 9287, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 6, "scene": 52, "line": "Yeah?", "order": 9288, "speaker": "Michael Scott"},
  {"season": 3, "episode": 6, "scene": 52, "line": "Your shirt is buttoned wrong.", "order": 9289, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 6, "scene": 52, "line": "So, any questions?", "order": 9290, "speaker": "Michael Scott"},
  {"season": 3, "episode": 7, "scene": 1, "line": "Hey.", "order": 9291, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 7, "scene": 1, "line": "Hey.", "order": 9292, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 7, "scene": 1, "line": "Who are you faxing so early in the morning?", "order": 9293, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 7, "scene": 1, "line": "Oh, umm... kinda hard to explain.", "order": 9294, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 7, "scene": 2, "line": "I don't have a ton of contact with the Scranton branch, but, before I left, I took a box of Dwight's stationery, so, from time to time, I send Dwight faxes. From himself. From the future. [reading fax] 'Dwight, at 8 A.M. today, someone poisons the coffee. Do not drink the coffee. More instructions will follow. Cordially, Future Dwight.'", "order": 9295, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 7, "scene": 3, "line": "No![knocks coffee out of Stanley's hand.] You'll thank me later.", "order": 9296, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 7, "scene": 4, "line": "There she is - Jan Levinson. First...", "order": 9297, "speaker": "Michael Scott"},
  {"season": 3, "episode": 7, "scene": 4, "line": "Michael.", "order": 9298, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 7, "scene": 4, "line": "...thing in the morning. Love to start my morning with a hearty bowl of Jan.", "order": 9299, "speaker": "Michael Scott"},
  {"season": 3, "episode": 7, "scene": 4, "line": "Michael.", "order": 9300, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 7, "scene": 4, "line": "[singing] Just call me Levinson in the morning, baby.", "order": 9301, "speaker": "Michael Scott"},
  {"season": 3, "episode": 7, "scene": 4, "line": "Michael.", "order": 9302, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 7, "scene": 4, "line": "Yes.", "order": 9303, "speaker": "Michael Scott"},
  {"season": 3, "episode": 7, "scene": 4, "line": "I am here to tell you that we are closing the Scranton branch.", "order": 9304, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 7, "scene": 4, "line": "I don't understand.", "order": 9305, "speaker": "Michael Scott"},
  {"season": 3, "episode": 7, "scene": 4, "line": "The board voted last night to close your branch.", "order": 9306, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 7, "scene": 4, "line": "On whom's authority?", "order": 9307, "speaker": "Michael Scott"},
  {"season": 3, "episode": 7, "scene": 4, "line": "The board's.", "order": 9308, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 7, "scene": 4, "line": "What?", "order": 9309, "speaker": "Michael Scott"},
  {"season": 3, "episode": 7, "scene": 4, "line": "I'm very sorry. I don't relish telling you this. You've been a big part of this company, and the board asked me to thank you for your years of service.", "order": 9310, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 7, "scene": 4, "line": "You're welcome.", "order": 9311, "speaker": "Michael Scott"},
  {"season": 3, "episode": 7, "scene": 4, "line": "A small number of people will be transferred to the Stamford branch, and the rest will be getting severance packages.", "order": 9312, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 7, "scene": 4, "line": "Am I a small number person or a severance package person?", "order": 9313, "speaker": "Michael Scott"},
  {"season": 3, "episode": 7, "scene": 4, "line": "Well, we haven't made final decisions about personnel yet. But you're a severance package person.", "order": 9314, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 7, "scene": 4, "line": "Oh... [burrys head in hands] Oh, my god. Oh, my god! I don't really get it 'cause we're not doing that bad and our numbers are gonna come up.", "order": 9315, "speaker": "Michael Scott"},
  {"season": 3, "episode": 7, "scene": 4, "line": "Well, it's not all about numbers, Michael.", "order": 9316, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 7, "scene": 4, "line": "Well...", "order": 9317, "speaker": "Michael Scott"},
  {"season": 3, "episode": 7, "scene": 4, "line": "It's... it's about talent.", "order": 9318, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 7, "scene": 4, "line": "Oh, you gotta be... Josh?", "order": 9319, "speaker": "Michael Scott"},
  {"season": 3, "episode": 7, "scene": 4, "line": "Our CFO believes that Josh is going to play an important role in our company's future.", "order": 9320, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 7, "scene": 4, "line": "Oh really, what role is that? King of the stupid universe?", "order": 9321, "speaker": "Michael Scott"},
  {"season": 3, "episode": 7, "scene": 5, "line": "It is an outrage, that's all. It's... hey're making a huge, huge mistake. Let's see Josh replace these people. Let's see Josh find another Stanley. You think Stanleys grow on trees? Well they don't. There is no Stanley tree. Do you think the world is crawling with Phyllises? Show me that farm. With Phyllises and Kevins sprouting up all over the place. Ripe for the plucking. Show me that farm.", "order": 9322, "speaker": "Michael Scott"},
  {"season": 3, "episode": 7, "scene": 6, "line": "Pictures. Memories. [Picks up a framed photo of Stanley's daughter from Stanley's desk] Look at that. They grow up so fast.", "order": 9323, "speaker": "Michael Scott"},
  {"season": 3, "episode": 7, "scene": 7, "line": "Hey stranger.", "order": 9324, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 7, "scene": 7, "line": "Don't say that. That just sounds weird. Please.", "order": 9325, "speaker": "Michael Scott"},
  {"season": 3, "episode": 7, "scene": 7, "line": "Sorry. I just feel like we haven't talked in awhile.", "order": 9326, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 7, "scene": 7, "line": "Well... we have nothin to talk about, Dwight. Just do your work ... while you still can.", "order": 9327, "speaker": "Michael Scott"},
  {"season": 3, "episode": 7, "scene": 8, "line": "When you become close with someone, you develop a kind of sixth sense. You can read their moods like a book. And right now, the title of Michael's book is, 'Something Weird is Going On.' Colon, 'What Did Jan Say? The Michael Scott Story. By Michael Scott, with Dwight Schrute.'", "order": 9328, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 7, "scene": 9, "line": "Hey, um... did you hear about your friends in Pennsylvania? Rumor has it that the Scranton Branch is... [clicks her tongue to her mouth motions chopping off a head]", "order": 9329, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 7, "scene": 9, "line": "Really? Wow... that's bad.", "order": 9330, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 7, "scene": 9, "line": "Um, sorry... the Scranton branch is closing? [Karen nods and Andy looks at Jim] In your face!", "order": 9331, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 7, "scene": 9, "line": "Well, I work here now.", "order": 9332, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 7, "scene": 9, "line": "Mmmm.. suck-ah!", "order": 9333, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 7, "scene": 10, "line": "Are you okay?", "order": 9334, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 7, "scene": 10, "line": "Yeah, great! Amazing. Best physical condition of my life.", "order": 9335, "speaker": "Michael Scott"},
  {"season": 3, "episode": 7, "scene": 10, "line": "What did Jan want?", "order": 9336, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 7, "scene": 10, "line": "Nothing. Just checking in. I can't tell you, so...", "order": 9337, "speaker": "Michael Scott"},
  {"season": 3, "episode": 7, "scene": 10, "line": "What can't you tell me?", "order": 9338, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 7, "scene": 10, "line": "Nothing, Pam.[whispers to himself] What difference does it make? We'll be gone in a couple of weeks anyway.", "order": 9339, "speaker": "Michael Scott"},
  {"season": 3, "episode": 7, "scene": 10, "line": "What?!", "order": 9340, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 7, "scene": 10, "line": "What?", "order": 9341, "speaker": "Michael Scott"},
  {"season": 3, "episode": 7, "scene": 10, "line": "You just said that we're gonna be gone...?", "order": 9342, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 7, "scene": 10, "line": "Do I have any messages?", "order": 9343, "speaker": "Michael Scott"},
  {"season": 3, "episode": 7, "scene": 10, "line": "Michael, what's going on?", "order": 9344, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 7, "scene": 10, "line": "Ok, ok...", "order": 9345, "speaker": "Michael Scott"},
  {"season": 3, "episode": 7, "scene": 11, "line": "[facing the whole office] Listen up, everybody... I have some news. We are screwed. Dunder-Mifflin, Scranton is being shut down.", "order": 9346, "speaker": "Michael Scott"},
  {"season": 3, "episode": 7, "scene": 11, "line": "Michael, uh... we shouldn't be talking about this until all the decisions have been made.", "order": 9347, "speaker": "Toby Flenderson"},
  {"season": 3, "episode": 7, "scene": 11, "line": "You knew about this all along, didn't you?", "order": 9348, "speaker": "Michael Scott"},
  {"season": 3, "episode": 7, "scene": 11, "line": "Jan told me just a few minutes before she told you.", "order": 9349, "speaker": "Toby Flenderson"},
  {"season": 3, "episode": 7, "scene": 11, "line": "Traitor. You are a traitor.", "order": 9350, "speaker": "Michael Scott"},
  {"season": 3, "episode": 7, "scene": 11, "line": "What about us, Michael? Do we still have jobs?", "order": 9351, "speaker": "Angela Martin"},
  {"season": 3, "episode": 7, "scene": 11, "line": "I don't know. Probably not. This is the worst.", "order": 9352, "speaker": "Michael Scott"},
  {"season": 3, "episode": 7, "scene": 12, "line": "It makes perfect sense that it would happen today because I just received this in the mail. [holds up cards] A thousand business cards with this address and phone number.", "order": 9353, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 7, "scene": 13, "line": "I don't want to blame anyone in particular. I think everyone's to blame.", "order": 9354, "speaker": "Angela Martin"},
  {"season": 3, "episode": 7, "scene": 14, "line": "If I get to stay and Ryan is laid off, I will kill myself. Like Romeo and Juliet, the Claire Danes one.", "order": 9355, "speaker": "Kelly Kapoor"},
  {"season": 3, "episode": 7, "scene": 15, "line": "[surrounded by clapping coworkers] Stamford, Connecticut! Stamford, Connecticut!", "order": 9356, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 7, "scene": 16, "line": "I worked in Scranton for a really long time. And uh, it's going to be weird that it's all disappearing. I mean, I always knew that the branch would shut down someday. I just figured it would be because Michael sold the building for some magic beans.", "order": 9357, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 7, "scene": 17, "line": "I couldn't be happier. I'm gonna take the severance and retire. My wife and I are gonna travel. [chuckles] I really couldn't be happier.", "order": 9358, "speaker": "Stanley Hudson"},
  {"season": 3, "episode": 7, "scene": 18, "line": "It's a blessing in disguise. Actually, not even in disguise. Sometimes at home, I answer the phone, 'Dunder-Mifflin, this is Pam.' So, maybe that'll stop now.", "order": 9359, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 7, "scene": 19, "line": "What does that mean?", "order": 9360, "speaker": "Roy Anderson"},
  {"season": 3, "episode": 7, "scene": 19, "line": "Well, some of us are fired and a few are going to Connecticut.", "order": 9361, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 7, "scene": 20, "line": "I don't really want to work here without Pam. You know that Cinderella song, 'You Don't Know What You Got (Till It's Gone)'? That pretty much says it better than how I know how to say it... in words.", "order": 9362, "speaker": "Roy Anderson"},
  {"season": 3, "episode": 7, "scene": 21, "line": "Hey Mike.", "order": 9363, "speaker": "Darryl Philbin"},
  {"season": 3, "episode": 7, "scene": 21, "line": "Darryl. Noble Darryl. [sighs]", "order": 9364, "speaker": "Michael Scott"},
  {"season": 3, "episode": 7, "scene": 21, "line": "Look, I heard about the office. Tough break.", "order": 9365, "speaker": "Darryl Philbin"},
  {"season": 3, "episode": 7, "scene": 21, "line": "I know, I know. Well, I'll land on my feet. Don't worry about me.", "order": 9366, "speaker": "Michael Scott"},
  {"season": 3, "episode": 7, "scene": 21, "line": "I wasn't.", "order": 9367, "speaker": "Darryl Philbin"},
  {"season": 3, "episode": 7, "scene": 21, "line": "So, you'll be okay too. You're a warrior. You're smart, capable. You'll find something else and...", "order": 9368, "speaker": "Michael Scott"},
  {"season": 3, "episode": 7, "scene": 21, "line": "Actually, Bob Vance bought out the warehouse. So he's keeping on the whole crew. So, we good.", "order": 9369, "speaker": "Darryl Philbin"},
  {"season": 3, "episode": 7, "scene": 21, "line": "Awesome.", "order": 9370, "speaker": "Michael Scott"},
  {"season": 3, "episode": 7, "scene": 22, "line": "This is my house. The CFO is taking away my house and giving it to Josh. And Josh is giving the garage to Bob Vance.", "order": 9371, "speaker": "Michael Scott"},
  {"season": 3, "episode": 7, "scene": 23, "line": "All right, listen up. Some of you may have heard some rumors about the branch closing. But, I am not going to take this lying down. I have a plan and I am going to save our jobs. So just hang in there. [Looks at Dwight] Let's go.", "order": 9372, "speaker": "Michael Scott"},
  {"season": 3, "episode": 7, "scene": 23, "line": "Yes!", "order": 9373, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 7, "scene": 23, "line": "Oh, good... you're bringing Dwight.", "order": 9374, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 7, "scene": 23, "line": "Yes. This might get ugly. I need backup.", "order": 9375, "speaker": "Michael Scott"},
  {"season": 3, "episode": 7, "scene": 24, "line": "What's the plan?", "order": 9376, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 7, "scene": 24, "line": "Go to New York, confront the CFO, show him he's making a mistake... save the branch.", "order": 9377, "speaker": "Michael Scott"},
  {"season": 3, "episode": 7, "scene": 24, "line": "Can I drive?", "order": 9378, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 7, "scene": 24, "line": "No... way.", "order": 9379, "speaker": "Michael Scott"},
  {"season": 3, "episode": 7, "scene": 24, "line": "Shotgun!", "order": 9380, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 7, "scene": 24, "line": "No. There's no one else.", "order": 9381, "speaker": "Michael Scott"},
  {"season": 3, "episode": 7, "scene": 24, "line": "Still.", "order": 9382, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 7, "scene": 25, "line": "Thank you very much. [hangs up cell phone] Okay, secretary says Wallace is away for the day and won't be coming back into the office.", "order": 9383, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 7, "scene": 25, "line": "Okay, okay. Um...", "order": 9384, "speaker": "Michael Scott"},
  {"season": 3, "episode": 7, "scene": 25, "line": "But, do not worry. I have his home address right [presses cell phone button] here.", "order": 9385, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 7, "scene": 25, "line": "Why?", "order": 9386, "speaker": "Michael Scott"},
  {"season": 3, "episode": 7, "scene": 25, "line": "Christmas card list.", "order": 9387, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 7, "scene": 25, "line": "You sent him cards? You never met him.", "order": 9388, "speaker": "Michael Scott"},
  {"season": 3, "episode": 7, "scene": 25, "line": "But when I do, we'll have something to talk about.", "order": 9389, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 7, "scene": 26, "line": "Hey, do you have a second?", "order": 9390, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 7, "scene": 26, "line": "Sure, what's up?", "order": 9391, "speaker": "Josh Porter"},
  {"season": 3, "episode": 7, "scene": 26, "line": "I know it's not definite or whatever, but uh, do you know who's coming over here from Scranton?", "order": 9392, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 7, "scene": 26, "line": "I honestly don't. I don't know.", "order": 9393, "speaker": "Josh Porter"},
  {"season": 3, "episode": 7, "scene": 26, "line": "Okay, so is it like sales or... accountants?", "order": 9394, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 7, "scene": 26, "line": "You know what, Jim, I wouldn't worry about it.", "order": 9395, "speaker": "Josh Porter"},
  {"season": 3, "episode": 7, "scene": 26, "line": "What does that mean?", "order": 9396, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 7, "scene": 27, "line": "Hi.", "order": 9397, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 7, "scene": 27, "line": "Jan, hey.", "order": 9398, "speaker": "Josh Porter"},
  {"season": 3, "episode": 7, "scene": 27, "line": "Oh, good. You're both here. Ready to talk logistics?", "order": 9399, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 7, "scene": 28, "line": "[to Kelly] I just feel like it could have been something special if we could have kept working together, but I'm gonna go someplace else and you're gonna go someplace else. It just doesn't make sense.", "order": 9400, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 7, "scene": 29, "line": "This kinda worked out perfectly for me. I got some good experience. Uh, Michael's gonna write me a great recommendation. And as far as me and Kelly goes, I think it's for the best.", "order": 9401, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 7, "scene": 30, "line": "Okay, this is it. [runs up the stairs of CFO's house, with Dwight] This is exactly what Michael Moore does, famous documentarian. He goes up to people with a camera and he's like 'Why did you do this? Why did you pollute? You are bad. You're a bad person.' It's very dramatic. Although, I can't say I was a big fan of 'Bowling for Columbine', because I thought it was going to be a bowling movie, like 'Kingpin'. And it wasn't. It was something else.", "order": 9402, "speaker": "Michael Scott"},
  {"season": 3, "episode": 7, "scene": 31, "line": "So, Josh will be running what is now called Dunder-Mifflin ,Northeast, which is all the offices north of Stamford. And Jim, fi you want the job, you'll be his number two.", "order": 9403, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 7, "scene": 31, "line": "Wow. Uh, sure. Absolutely.", "order": 9404, "speaker": "Josh Porter"},
  {"season": 3, "episode": 7, "scene": 31, "line": "Awesome.", "order": 9405, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 7, "scene": 31, "line": "Excuse me, Jan, I'm sorry... I'm gonna have to stop you there. I, um, will not be taking the job.", "order": 9406, "speaker": "Josh Porter"},
  {"season": 3, "episode": 7, "scene": 31, "line": "Wha... excuse... why not?", "order": 9407, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 7, "scene": 31, "line": "As of today, I have accepted a senior managment position at Staples.", "order": 9408, "speaker": "Josh Porter"},
  {"season": 3, "episode": 7, "scene": 31, "line": "Today?", "order": 9409, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 7, "scene": 31, "line": "[nods]", "order": 9410, "speaker": "Josh Porter"},
  {"season": 3, "episode": 7, "scene": 31, "line": "You leveraged your new position with us into another offer?", "order": 9411, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 7, "scene": 31, "line": "[sighs]", "order": 9412, "speaker": "Josh Porter"},
  {"season": 3, "episode": 7, "scene": 31, "line": "Damn it, Josh. This whole restructuring thing was based around keeping you. I...", "order": 9413, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 7, "scene": 31, "line": "I'm sorry, all right? It's done, it's done.", "order": 9414, "speaker": "Josh Porter"},
  {"season": 3, "episode": 7, "scene": 31, "line": "I'm gonna make some calls.", "order": 9415, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 7, "scene": 31, "line": "Say what you will about Michael Scott, but he would never do that.", "order": 9416, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 7, "scene": 32, "line": "So, do you know what you're gonna say when he shows up?", "order": 9417, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 7, "scene": 32, "line": "I will improvise. I will speak from the heart.", "order": 9418, "speaker": "Michael Scott"},
  {"season": 3, "episode": 7, "scene": 32, "line": "No. Bad idea. You need an attack plan. Here, I'll be him, you be you. Let's practice.", "order": 9419, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 7, "scene": 32, "line": "All right.", "order": 9420, "speaker": "Michael Scott"},
  {"season": 3, "episode": 7, "scene": 32, "line": "[as David Wallace] Dum, dum, dum, dum... coming home from work.", "order": 9421, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 7, "scene": 32, "line": "Excuse me, Mr. Wallace? David Wallace?", "order": 9422, "speaker": "M ichael"},
  {"season": 3, "episode": 7, "scene": 32, "line": "Yes? What is the meaning of this?", "order": 9423, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 7, "scene": 32, "line": "Can you tell us why you are shutting down Scranton and putting 15 people out of work?", "order": 9424, "speaker": "Michael Scott "},
  {"season": 3, "episode": 7, "scene": 32, "line": "Well, the branch is no longer finicially viable. It's simple dollars and cents.", "order": 9425, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 7, "scene": 32, "line": "Yes, but these are employees, Sir. These are human beings.", "order": 9426, "speaker": "Michael Scott"},
  {"season": 3, "episode": 7, "scene": 32, "line": "Listen, Scott... we're losing money, okay? It's not a charity; it's a business. And it's a dying business.", "order": 9427, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 7, "scene": 32, "line": "[no longer talking to Dwight as Wallace] Stop... stop it! Just, okay. He's not going to say any of that.", "order": 9428, "speaker": "Michael Scott"},
  {"season": 3, "episode": 7, "scene": 32, "line": "[as himself] Whoa hey, why not?", "order": 9429, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 7, "scene": 32, "line": "Because he'd be intimidated and I, just... let's start again. Just be more scared of me, okay?", "order": 9430, "speaker": "Michael Scott"},
  {"season": 3, "episode": 7, "scene": 32, "line": "Okay.", "order": 9431, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 7, "scene": 32, "line": "Don't touch me this time.", "order": 9432, "speaker": "Michael Scott"},
  {"season": 3, "episode": 7, "scene": 32, "line": "[as David Wallace] Dum, dum, dum, du, doy, du, do... coming home from work...", "order": 9433, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 7, "scene": 32, "line": "Excuse me, Mr. Wallace?", "order": 9434, "speaker": "Michael Scott"},
  {"season": 3, "episode": 7, "scene": 32, "line": "[frightened] Uh!", "order": 9435, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 7, "scene": 33, "line": "So... we are still scrambling here. But uh, it looks like Scranton is going to absorb Stamford.", "order": 9436, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 7, "scene": 33, "line": "Wow.", "order": 9437, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 7, "scene": 33, "line": "And I know that you just left there a couple of months ago, but we would like to offer you the number two position at that branch.", "order": 9438, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 7, "scene": 33, "line": "Thank you.", "order": 9439, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 7, "scene": 33, "line": "Yeah.", "order": 9440, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 7, "scene": 33, "line": "No, it's just I'm not sure if I um... well to be quite honest with you, Jan, I have a few unpleasant memories of Scranton. And um...", "order": 9441, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 7, "scene": 33, "line": "Michael.", "order": 9442, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 7, "scene": 33, "line": "No. No, no. Just um... some personal stuff. And I'm not really ready to revist that, I don't think.", "order": 9443, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 7, "scene": 33, "line": "Ah. Well, please think about it. And call me. We will do whatever we can to get you to stay.", "order": 9444, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 7, "scene": 33, "line": "Okay.", "order": 9445, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 7, "scene": 34, "line": "Hey guys. Uh, I'm trying to organize a little group lunch for everybody since you know, we're never gonna see each other again.", "order": 9446, "speaker": "Phyllis Lapin"},
  {"season": 3, "episode": 7, "scene": 34, "line": "Where are we going?", "order": 9447, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 7, "scene": 34, "line": "I thought maybe DJ's.", "order": 9448, "speaker": "Phyllis Lapin"},
  {"season": 3, "episode": 7, "scene": 34, "line": "How about Cugino's?", "order": 9449, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 7, "scene": 34, "line": "I don't want to go all the way to Dunmore.", "order": 9450, "speaker": "Angela Martin"},
  {"season": 3, "episode": 7, "scene": 34, "line": "How 'bout Cooper's then?", "order": 9451, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 7, "scene": 34, "line": "No seafood.", "order": 9452, "speaker": "Angela Martin"},
  {"season": 3, "episode": 7, "scene": 34, "line": "But, I don't want to go to DJ's.", "order": 9453, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 7, "scene": 34, "line": "Oh, now all of a sudden you get picky?", "order": 9454, "speaker": "Angela Martin"},
  {"season": 3, "episode": 7, "scene": 34, "line": "Okay, forget it. [walks away]", "order": 9455, "speaker": "Phyllis Lapin"},
  {"season": 3, "episode": 7, "scene": 34, "line": "Hooters?", "order": 9456, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 7, "scene": 34, "line": "No.", "order": 9457, "speaker": "Angela Martin"},
  {"season": 3, "episode": 7, "scene": 35, "line": "Ah. [takes a gulp of Gatorade and passes the bottle to Michael] Here, replinish your fluids.", "order": 9458, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 7, "scene": 35, "line": "[takes bottle and cleans off cap with his tie]", "order": 9459, "speaker": "Michael Scott"},
  {"season": 3, "episode": 7, "scene": 36, "line": "[in Scranton] Where's Michael?", "order": 9460, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 7, "scene": 36, "line": "He's not here. I don't know where he is.", "order": 9461, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 7, "scene": 36, "line": "[looks around, noticing that no one is working] Wha... what's going on here?", "order": 9462, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 7, "scene": 36, "line": "We know the branch is closing; Michael told us.", "order": 9463, "speaker": "Phyllis Lapin"},
  {"season": 3, "episode": 7, "scene": 36, "line": "Ah, god. Okay. You know what everybody? I'm sure there is a better way to do this but I've drive something like 400 miles today and I'm completely exhausted so I'm just gonna tell you. Your branch is not closing; Stamford is closing. Um, for the time being, it seems that all your jobs are safe.", "order": 9464, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 7, "scene": 37, "line": "Yes!", "order": 9465, "speaker": "Angela Martin"},
  {"season": 3, "episode": 7, "scene": 37, "line": "Yes! [hugs Angela]", "order": 9466, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 7, "scene": 38, "line": "Stanley! [hugs Stanley]", "order": 9467, "speaker": "Phyllis Lapin"},
  {"season": 3, "episode": 7, "scene": 39, "line": "Is it because of Michael? Did he actually do something?", "order": 9468, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 7, "scene": 39, "line": "Well, reasons are not important. Would you just call him, please? Wherever he is... and tell him.", "order": 9469, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 7, "scene": 39, "line": "Sure, uh, Jan... um, do you know, is anyone coming back to Scranton?", "order": 9470, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 7, "scene": 39, "line": "Back?", "order": 9471, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 7, "scene": 39, "line": "Coming to Scranton. Is anyone coming to Scranton?", "order": 9472, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 7, "scene": 39, "line": "Uh, we don't know. Probably. A few.", "order": 9473, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 7, "scene": 40, "line": "[referring to Michael's ringing phone] who is it?", "order": 9474, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 7, "scene": 40, "line": "The office.", "order": 9475, "speaker": "Michael Scott"},
  {"season": 3, "episode": 7, "scene": 40, "line": "Gonna get it?", "order": 9476, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 7, "scene": 40, "line": "No, not until I have some good news for them. Not until I have some good news.", "order": 9477, "speaker": "Michael Scott"},
  {"season": 3, "episode": 7, "scene": 41, "line": "I guess some new people might be coming from Stamford. Should be fun. New blood.", "order": 9478, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 7, "scene": 41, "line": "Is Jim coming back.", "order": 9479, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 7, "scene": 41, "line": "That's, um... I hadn't thought about it, huh.", "order": 9480, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 7, "scene": 41, "line": "I just don't want it to be weird, you know? I mean, I took his old job and his old desk.", "order": 9481, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 7, "scene": 41, "line": "Yeah, that might be weird. Overall though, we still all have our jobs... so, good news, right?", "order": 9482, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 7, "scene": 41, "line": "Oh, yeah, totally.", "order": 9483, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 7, "scene": 41, "line": "Ahhhhh! [hugs Ryan] I'm so happy we don't have to break up now, Ryan! [kisses him] This is the best day of my whole l ife!", "order": 9484, "speaker": "Kelly Kapoor"},
  {"season": 3, "episode": 7, "scene": 42, "line": "What you gonna do?", "order": 9485, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 7, "scene": 42, "line": "I really don't know. How you doing with all this?", "order": 9486, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 7, "scene": 42, "line": "You know, I'm fine. I'll be better when I know if I have a job.", "order": 9487, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 7, "scene": 42, "line": "You'd actually move to Scranton?", "order": 9488, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 7, "scene": 42, "line": "Yeah, if they let me, I think I... I think I would.", "order": 9489, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 7, "scene": 42, "line": "New York City is 45 minutes down the road from here. And you wanna move to Scranton? I dunno. If I were you I'd move to New York?", "order": 9490, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 7, "scene": 42, "line": "Yeah, you know... I might do that. I, who knows? I... I might do that.", "order": 9491, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 7, "scene": 43, "line": "Hey Andy!", "order": 9492, "speaker": "Josh Porter"},
  {"season": 3, "episode": 7, "scene": 43, "line": "[after screaming and throwing papers around in the kitchen] What's up, Josh?", "order": 9493, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 7, "scene": 43, "line": "I just want to say thanks. Good luck.", "order": 9494, "speaker": "Josh Porter"},
  {"season": 3, "episode": 7, "scene": 43, "line": "Thanks, man. You, too. Totally.", "order": 9495, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 7, "scene": 44, "line": "Anything?", "order": 9496, "speaker": "Michael Scott"},
  {"season": 3, "episode": 7, "scene": 44, "line": "Nothing. [puts down binoculars]", "order": 9497, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 7, "scene": 44, "line": "What if this doesn't work? What is the office actually goes under?", "order": 9498, "speaker": "Michael Scott"},
  {"season": 3, "episode": 7, "scene": 44, "line": "Then it was an honor to have worked with you.", "order": 9499, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 7, "scene": 44, "line": "[pats Dwight and sighs] All right, favorite moments in Dunder-Mifflin history. Go.", "order": 9500, "speaker": "Michael Scott"},
  {"season": 3, "episode": 7, "scene": 44, "line": "My first day when you hazed me by spraying me with a fire extinguisher.", "order": 9501, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 7, "scene": 44, "line": "That was hilarious. The foam ...", "order": 9502, "speaker": "Michael Scott"},
  {"season": 3, "episode": 7, "scene": 44, "line": "Uh,... my first sale, my promotion to assistant regional manager, our basketball game, when you took me to the hospital, and told me that you cared about me.", "order": 9503, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 7, "scene": 44, "line": "Oh, right. Okay, that's enough. That's good.", "order": 9504, "speaker": "Michael Scott"},
  {"season": 3, "episode": 7, "scene": 44, "line": "What were your favorite moments?", "order": 9505, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 7, "scene": 44, "line": "Oh, hmm, all of them. I loved them all. Every single one.", "order": 9506, "speaker": "Michael Scott"},
  {"season": 3, "episode": 7, "scene": 44, "line": "What about when Jan said the branch was closing?", "order": 9507, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 7, "scene": 44, "line": "God, Dwight!", "order": 9508, "speaker": "Michael Scott"},
  {"season": 3, "episode": 7, "scene": 44, "line": "Well, it doesn't...", "order": 9509, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 7, "scene": 45, "line": "Hey Pam, we're going to Poor Richards. Creed's buying shots.", "order": 9510, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 7, "scene": 45, "line": "No thanks, guys.", "order": 9511, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 7, "scene": 45, "line": "All right.", "order": 9512, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 7, "scene": 45, "line": "Have fun though.", "order": 9513, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 7, "scene": 45, "line": "Cool.", "order": 9514, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 7, "scene": 45, "line": "Hey, I hear Jim's coming back.", "order": 9515, "speaker": "Phyllis Lapin"},
  {"season": 3, "episode": 7, "scene": 45, "line": "Really? Where did you hear that?", "order": 9516, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 7, "scene": 45, "line": "I was...", "order": 9517, "speaker": "Phyllis Lapin"},
  {"season": 3, "episode": 7, "scene": 45, "line": "[interupting] Hey Ryan, you coming?", "order": 9518, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 7, "scene": 45, "line": "Uh yeah, we'll meet you there.", "order": 9519, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 7, "scene": 45, "line": "Awesome. Let's go, Phyllis.", "order": 9520, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 7, "scene": 45, "line": "[to Pam] I'll tell you later.", "order": 9521, "speaker": "Phyllis Lapin"},
  {"season": 3, "episode": 7, "scene": 45, "line": "Okay.", "order": 9522, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 7, "scene": 46, "line": "Crazy day, huh?", "order": 9523, "speaker": "Roy Anderson"},
  {"season": 3, "episode": 7, "scene": 46, "line": "Yeah.", "order": 9524, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 7, "scene": 46, "line": "Yeah, man! I'm uh... I'm really glad you're still gonna be working here.", "order": 9525, "speaker": "Roy Anderson"},
  {"season": 3, "episode": 7, "scene": 46, "line": "Yeah, me too.", "order": 9526, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 7, "scene": 47, "line": "Maybe this is good. Finding another job is a pain. There's another annoying boss, another desk, I'd have to learn everything all over again. So, there are reasons to stay.", "order": 9527, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 7, "scene": 48, "line": "Hey, um, I think I am gonna take that job. And Scranton... it's not that bad. So, if they offer you a job there, I think you should take it.", "order": 9528, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 7, "scene": 48, "line": "Okay, yeah. Maybe I will.", "order": 9529, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 7, "scene": 48, "line": "Okay.", "order": 9530, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 7, "scene": 49, "line": "Yeah, I'm happy he said that. I mean, I don't think he's into me or anything, but, I'm kind of into him. So...there you go.", "order": 9531, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 7, "scene": 50, "line": "What are we still doing here? It's over. Let's go home. Get the car.", "order": 9532, "speaker": "Michael Scott"},
  {"season": 3, "episode": 7, "scene": 50, "line": "[picks up his beeping cell phone]", "order": 9533, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 7, "scene": 50, "line": "Oh, this was such a stupid idea! This was so stupid. I am such a stupid idiot. I let everybody down. Everybody hates me. I lost everybody's jobs. Nobody likes me anymore!", "order": 9534, "speaker": "Michael Scott"},
  {"season": 3, "episode": 7, "scene": 50, "line": "[on the phone] Oh my God!", "order": 9535, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 7, "scene": 50, "line": "What?!", "order": 9536, "speaker": "Michael Scott"},
  {"season": 3, "episode": 7, "scene": 50, "line": "Stranford is closed! Michael, we're not closed. Stamford is closed. Stamford is closed.", "order": 9537, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 7, "scene": 50, "line": "We did it? We did it.", "order": 9538, "speaker": "Michael Scott"},
  {"season": 3, "episode": 7, "scene": 50, "line": "We did it!", "order": 9539, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 7, "scene": 50, "line": "We did it! We did it!", "order": 9540, "speaker": "Michael Scott & Dwight Schrute"},
  {"season": 3, "episode": 7, "scene": 50, "line": "Right here! Right here! [pounds his chest against Dwight's]", "order": 9541, "speaker": "Michael Scott"},
  {"season": 3, "episode": 7, "scene": 50, "line": "Ouu! Ou! Ouu! Ouu!", "order": 9542, "speaker": "Michael Scott & Dwight Schrute"},
  {"season": 3, "episode": 7, "scene": 50, "line": "Yeah, baby!", "order": 9543, "speaker": "Michael Scott"},
  {"season": 3, "episode": 7, "scene": 50, "line": "In your face!", "order": 9544, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 7, "scene": 50, "line": "We did it!", "order": 9545, "speaker": "Michael Scott"},
  {"season": 3, "episode": 7, "scene": 50, "line": "Oh, man.", "order": 9546, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 7, "scene": 50, "line": "How did we do it?", "order": 9547, "speaker": "Michael Scott"},
  {"season": 3, "episode": 7, "scene": 50, "line": "I don't ... have no idea.", "order": 9548, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 7, "scene": 50, "line": "I don't understand.", "order": 9549, "speaker": "Michael Scott"},
  {"season": 3, "episode": 7, "scene": 51, "line": "Well for a minute there, I saw myself selling my house, moving to Costa Rica, learning how to surf. But, Costa Rica will still be there. When I'm 65.", "order": 9550, "speaker": "Toby Flenderson"},
  {"season": 3, "episode": 8, "scene": 1, "line": "The Stamford branch is closing and everybody's just packing up their stuff. Andy Bernard made these tasteful hats.", "order": 9551, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 8, "scene": 2, "line": "[to office worker] That's the other thing you got to watch out... [to Jim] Yo! Tuna! I wanna talk to you about this new boss, Michael Scott.", "order": 9552, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 8, "scene": 2, "line": "Yeah.", "order": 9553, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 8, "scene": 2, "line": "So what's he like? Likes? Dislikes? Favorite sports? Favorite movies? Favorite men's magazines?", "order": 9554, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 8, "scene": 2, "line": "You know what? I think you just need to meet him.", "order": 9555, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 8, "scene": 2, "line": "Playing your cards close to the vest. I get it. Good luck over there, Tuna. Cross me and I will destroy you.", "order": 9556, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 8, "scene": 2, "line": "Sounds good Andy.", "order": 9557, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 8, "scene": 2, "line": "This is going to be an adventure.", "order": 9558, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 8, "scene": 2, "line": "Yes. This is going to be very interesting. All right, I'm out of here. See you later?", "order": 9559, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 8, "scene": 2, "line": "Right on. [to Andy] Hey is that Josh's computer?", "order": 9560, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 8, "scene": 2, "line": "What?", "order": 9561, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 8, "scene": 3, "line": "Hey!", "order": 9562, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 8, "scene": 3, "line": "Hey.", "order": 9563, "speaker": "Toby Flenderson"},
  {"season": 3, "episode": 8, "scene": 3, "line": "How'd the run go?", "order": 9564, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 8, "scene": 3, "line": "Ah, pretty good. I finished.", "order": 9565, "speaker": "Toby Flenderson"},
  {"season": 3, "episode": 8, "scene": 3, "line": "That's great!", "order": 9566, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 8, "scene": 3, "line": "Psh, why is that great?", "order": 9567, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 8, "scene": 3, "line": "Because he accomplished something.", "order": 9568, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 8, "scene": 3, "line": "What was your mile time?", "order": 9569, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 8, "scene": 3, "line": "About seven.", "order": 9570, "speaker": "Toby Flenderson"},
  {"season": 3, "episode": 8, "scene": 3, "line": "[scoffs] I could beat that on a skateboard.", "order": 9571, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 8, "scene": 3, "line": "Well, that has wheels.", "order": 9572, "speaker": "Toby Flenderson"},
  {"season": 3, "episode": 8, "scene": 3, "line": "Yeah, well my feet don't. And I can still crush that time.", "order": 9573, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 8, "scene": 3, "line": "Really, Dwight? How fast are you?", "order": 9574, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 8, "scene": 3, "line": "Let's just put it this way. Last weekend I outran a black pepper snake.", "order": 9575, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 8, "scene": 3, "line": "Really?", "order": 9576, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 8, "scene": 4, "line": "I am fast. To give you a reference point. I'm somewhere between a snake and a mongoose. And a panther.", "order": 9577, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 8, "scene": 5, "line": "Man, what is taking Toby so long?", "order": 9578, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 8, "scene": 5, "line": "Oh, I'll just time him later.", "order": 9579, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 8, "scene": 5, "line": "And you'll compare the times?", "order": 9580, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 8, "scene": 5, "line": "Yeah. Are you ready?", "order": 9581, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 8, "scene": 5, "line": "No, my groin...", "order": 9582, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 8, "scene": 5, "line": "Set", "order": 9583, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 8, "scene": 5, "line": "...is really tight.", "order": 9584, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 8, "scene": 5, "line": "Go!", "order": 9585, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 8, "scene": 5, "line": "I can't... [starts running]", "order": 9586, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 8, "scene": 5, "line": "Am I being mean to Dwight? I don't know. I did just make him run around the building and I have no intention of timing him. This isn't even a stopwatch. It's a digital thermometer. He does make my life harder, sometimes. And on purpose. Like he tried to put meters on the bathroom stalls as a way of bringing in more money for the company. [yells to Dwight] Hey, three more laps to go. Gotta pick it up if you're going to beat Toby.", "order": 9587, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 8, "scene": 5, "line": "Aaaaah!", "order": 9588, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 8, "scene": 5, "line": "I should probably get back to work.", "order": 9589, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 8, "scene": 6, "line": "Here... Who's here?", "order": 9590, "speaker": "Michael Scott"},
  {"season": 3, "episode": 8, "scene": 6, "line": "Nametag?", "order": 9591, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 8, "scene": 6, "line": "Yes, please.", "order": 9592, "speaker": "Michael Scott"},
  {"season": 3, "episode": 8, "scene": 6, "line": "Karen Filippelli.", "order": 9593, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 8, "scene": 6, "line": "Karen Filip... [In Italian voice] Ka-ren Fili-pell-li.", "order": 9594, "speaker": "Michael Scott"},
  {"season": 3, "episode": 8, "scene": 6, "line": "Probably Italian. Possibly Filipino.", "order": 9595, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 8, "scene": 6, "line": "Okay.", "order": 9596, "speaker": "Michael Scott"},
  {"season": 3, "episode": 8, "scene": 7, "line": "My branch is absorbing the Stamford branch. Or, as I like to put it, my family is doubling in size. That's all I'm going to say about it because I have a gigantic performance ahead of me. And I have to get into my head and focus. [exhales]", "order": 9597, "speaker": "Michael Scott"},
  {"season": 3, "episode": 8, "scene": 8, "line": "Who's next?", "order": 9598, "speaker": "Michael Scott"},
  {"season": 3, "episode": 8, "scene": 8, "line": "That's Andy Bernard.", "order": 9599, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 8, "scene": 8, "line": "Andy Bernard.", "order": 9600, "speaker": "Michael Scott"},
  {"season": 3, "episode": 8, "scene": 8, "line": "If I were you...", "order": 9601, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 8, "scene": 8, "line": "Saint Bernard.", "order": 9602, "speaker": "Michael Scott"},
  {"season": 3, "episode": 8, "scene": 8, "line": "...I would fire Anthony Gardner...", "order": 9603, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 8, "scene": 8, "line": "What?", "order": 9604, "speaker": "Michael Scott"},
  {"season": 3, "episode": 8, "scene": 8, "line": "...before noon...", "order": 9605, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 8, "scene": 8, "line": "I'm not...", "order": 9606, "speaker": "Michael Scott"},
  {"season": 3, "episode": 8, "scene": 8, "line": "...to consolidate power.", "order": 9607, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 8, "scene": 8, "line": "I'm not firing somebody on the first day.", "order": 9608, "speaker": "Michael Scott"},
  {"season": 3, "episode": 8, "scene": 8, "line": "No, no, no! Not somebody. Gardner.", "order": 9609, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 8, "scene": 9, "line": "The Japanese camp guards of World War II always chose one man to kill whenever a batch of new prisoners arrived. I always wondered how they chose the man who was to die. I think I would have been good at choosing the person.", "order": 9610, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 8, "scene": 10, "line": "Good morning!", "order": 9611, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 8, "scene": 10, "line": "Got the food? Good! Looky-looky-looky. What I want you to do... set it up in the conference room, please. Make it look nice. As if you are trying to impress a much older man who's way out of your league.", "order": 9612, "speaker": "Michael Scott"},
  {"season": 3, "episode": 8, "scene": 10, "line": "Okay.", "order": 9613, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 8, "scene": 11, "line": "Yes! I'm in a good mood today! I'm excited to meet all the new people and to see my old friend again, definitely. That's always a thing that makes people happy... to have an old friend back.", "order": 9614, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 8, "scene": 12, "line": "Hello?", "order": 9615, "speaker": "Hannah"},
  {"season": 3, "episode": 8, "scene": 12, "line": "Ah! Ding-ding-ding-ding-ding. Our first arrival. Welcome to Scranton. This is Hannah Smoterich-Barr.", "order": 9616, "speaker": "Michael Scott"},
  {"season": 3, "episode": 8, "scene": 12, "line": "Hannah Smoterich-Barr.", "order": 9617, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 8, "scene": 12, "line": "Welcome to our humble abode. Follow me to your desk. Your ball and chain is right over here.", "order": 9618, "speaker": "Michael Scott"},
  {"season": 3, "episode": 8, "scene": 13, "line": "You know for a lot of these people this is the only family they have. So... As far as I'm concerned, [holds up WORLD'S BEST BOSS mug] this says 'World's Best Dad'.", "order": 9619, "speaker": "Michael Scott"},
  {"season": 3, "episode": 8, "scene": 14, "line": "Ah! There he is Tony... what's your last name?", "order": 9620, "speaker": "Michael Scott"},
  {"season": 3, "episode": 8, "scene": 14, "line": "Gardner.", "order": 9621, "speaker": "Tony"},
  {"season": 3, "episode": 8, "scene": 14, "line": "Gardner! I knew that. There you go. Gift bag... for you. [laughs] Okay.", "order": 9622, "speaker": "Michael Scott"},
  {"season": 3, "episode": 8, "scene": 14, "line": "Thanks.", "order": 9623, "speaker": "Tony"},
  {"season": 3, "episode": 8, "scene": 14, "line": "Michael, I didn't get a gift bag.", "order": 9624, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 8, "scene": 14, "line": "Well, they're just for guests. If there are any left over, you can buy one later.", "order": 9625, "speaker": "Michael Scott"},
  {"season": 3, "episode": 8, "scene": 14, "line": "My bag's mostly pencils.", "order": 9626, "speaker": "Hannah"},
  {"season": 3, "episode": 8, "scene": 14, "line": "Wh..and coupons... to various hot spots around Scranton. [to Tony] All right! Let me show you to your area, sir. Come on, big guy.", "order": 9627, "speaker": "Michael Scott"},
  {"season": 3, "episode": 8, "scene": 14, "line": "Can I have your pencils?", "order": 9628, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 8, "scene": 14, "line": "No.", "order": 9629, "speaker": "Hannah"},
  {"season": 3, "episode": 8, "scene": 14, "line": "Hi!", "order": 9630, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 8, "scene": 14, "line": "Hi.", "order": 9631, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 8, "scene": 14, "line": "I'm Pam.", "order": 9632, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 8, "scene": 14, "line": "Karen. I love your sweater.", "order": 9633, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 8, "scene": 14, "line": "Oh, thanks. My Mom made it for me.", "order": 9634, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 8, "scene": 14, "line": "Really? That's so cool. I've always wanted to learn...", "order": 9635, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 8, "scene": 14, "line": "Welcome.", "order": 9636, "speaker": "Michael Scott"},
  {"season": 3, "episode": 8, "scene": 14, "line": "...to knit.", "order": 9637, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 8, "scene": 14, "line": "Welcome, welcome, welcome! [in robot voice] Take me to your leader. Oh wait, I am your leader.", "order": 9638, "speaker": "Michael Scott"},
  {"season": 3, "episode": 8, "scene": 14, "line": "Uh wait, are you a robot or a Martian?", "order": 9639, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 8, "scene": 14, "line": "Mmm... dah. I am actually your boss, Michael Scott. Welcome. Wow! You are very exotic looking. Was your dad a G.I. or uh?", "order": 9640, "speaker": "Michael Scott"},
  {"season": 3, "episode": 8, "scene": 15, "line": "I'll be the Number Two guy here in Scranton in six weeks. How? Name repetition, personality mirroring, and never breaking off a handshake. I'm always thinking one step ahead. Like a carpenter that makes stairs.", "order": 9641, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 8, "scene": 16, "line": "Hello.", "order": 9642, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 8, "scene": 16, "line": "Ah! You must be Andy Bernard. Aloha and welcome!", "order": 9643, "speaker": "Michael Scott"},
  {"season": 3, "episode": 8, "scene": 16, "line": "And you must be Michael Scott. Aloha and... hello.", "order": 9644, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 8, "scene": 16, "line": "A-ha-ha-ho. Very good! Welcome to our little kingdom. Ah, we have a bag of nifty gifties for you.", "order": 9645, "speaker": "Michael Scott"},
  {"season": 3, "episode": 8, "scene": 16, "line": "Michael, thank you for welcoming me to your little kingdom, Mike.", "order": 9646, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 8, "scene": 16, "line": "Oh.", "order": 9647, "speaker": "Michael Scott"},
  {"season": 3, "episode": 8, "scene": 16, "line": "Nifty!", "order": 9648, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 8, "scene": 16, "line": "They are nifty! They're nifty gifties.", "order": 9649, "speaker": "Michael Scott"},
  {"season": 3, "episode": 8, "scene": 17, "line": "You know who I really like? Is this guy Andy Bernard. He has got this very likable way about him.", "order": 9650, "speaker": "Michael Scott"},
  {"season": 3, "episode": 8, "scene": 18, "line": "...which is why they need a passing game.", "order": 9651, "speaker": "Martin"},
  {"season": 3, "episode": 8, "scene": 18, "line": "Right.", "order": 9652, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 8, "scene": 18, "line": "[to Jim] No way. Get him out of here. We don't want any of this kind in here. Good to see you, man.", "order": 9653, "speaker": "Michael Scott"},
  {"season": 3, "episode": 8, "scene": 18, "line": "Okay.", "order": 9654, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 8, "scene": 18, "line": "[to Martin] How are you? Martin Nash, I presume? This is a little gift bag for you.", "order": 9655, "speaker": "Michael Scott"},
  {"season": 3, "episode": 8, "scene": 18, "line": "Oh! Thanks.", "order": 9656, "speaker": "Martin"},
  {"season": 3, "episode": 8, "scene": 18, "line": "Free of charge. Follow me, I will show you where all the slaves work. [shakes head] Not... so, your desk is...", "order": 9657, "speaker": "Michael Scott"},
  {"season": 3, "episode": 8, "scene": 18, "line": "Hi, I'm Jim. I'm new here.", "order": 9658, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 8, "scene": 18, "line": "Oh my god! It's really you!", "order": 9659, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 8, "scene": 18, "line": "Yeah, I was just doing a little joke there about how we'd never met...", "order": 9660, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 8, "scene": 18, "line": "I know. I don't care.", "order": 9661, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 8, "scene": 18, "line": "Awesome! Good to be back. The place looks really good.", "order": 9662, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 8, "scene": 18, "line": "It's really good to see you.", "order": 9663, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 8, "scene": 18, "line": "You, too.", "order": 9664, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 8, "scene": 19, "line": "Where do I stand with Pam? Um... no idea. I mean, we're friends. Always have been friends. Um... .that is where we stand.", "order": 9665, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 8, "scene": 20, "line": "Well, well, well. Look what the cat dragged in from Stamford.", "order": 9666, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 8, "scene": 20, "line": "Hey, Dwight.", "order": 9667, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 8, "scene": 20, "line": "Fact - I am older. I am wiser. Do not mess with me.", "order": 9668, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 8, "scene": 20, "line": "Okay. Sounds good.", "order": 9669, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 8, "scene": 20, "line": "What are you doing?", "order": 9670, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 8, "scene": 20, "line": "I don't know what you're talking about.", "order": 9671, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 8, "scene": 20, "line": "I have a smudge on my forehead?", "order": 9672, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 8, "scene": 20, "line": "No. Looks good.", "order": 9673, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 8, "scene": 20, "line": "Why are you looking at my forehead?", "order": 9674, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 8, "scene": 20, "line": "I'm not.", "order": 9675, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 8, "scene": 20, "line": "Meet my eye line, Jim!", "order": 9676, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 8, "scene": 20, "line": "I am.", "order": 9677, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 8, "scene": 20, "line": "Stop acting like an idiot!", "order": 9678, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 8, "scene": 20, "line": "Okay.", "order": 9679, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 8, "scene": 20, "line": "Hey, buddy. Welcome back.", "order": 9680, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 8, "scene": 20, "line": "Hey! How are you man? Good to see... you.", "order": 9681, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 8, "scene": 20, "line": "I'm good! How are you? So...", "order": 9682, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 8, "scene": 20, "line": "Oh, I'm sorry. Are you sitting here now?", "order": 9683, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 8, "scene": 20, "line": "Yeah.", "order": 9684, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 8, "scene": 20, "line": "Oh.", "order": 9685, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 8, "scene": 20, "line": "Um... unless you really, really want it back.", "order": 9686, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 8, "scene": 20, "line": "You know, man, it's really you're call.", "order": 9687, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 8, "scene": 20, "line": "Cool, thank you.", "order": 9688, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 8, "scene": 20, "line": "[whispers] Let me get that for you.", "order": 9689, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 8, "scene": 20, "line": "Yuh.", "order": 9690, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 8, "scene": 20, "line": "This one taken? No. Good.", "order": 9691, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 8, "scene": 21, "line": "Yeah. Jim is a nice guy. That's why I got the desk.", "order": 9692, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 8, "scene": 22, "line": "Hey, this came with the Stamford book. [Sets down box that says: Personnel Files Stamford CT Office Confidential] Can you take care of it?", "order": 9693, "speaker": "Toby Flenderson"},
  {"season": 3, "episode": 8, "scene": 22, "line": "Oh, yes I can.", "order": 9694, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 8, "scene": 23, "line": "In general, they do not give me much responsibility. But they do let me shred the company documents. And that is really all I need.", "order": 9695, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 8, "scene": 24, "line": "Michael Scott. Thank you. I appreciate it, Mike.", "order": 9696, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 8, "scene": 24, "line": "Right.", "order": 9697, "speaker": "Michael Scott"},
  {"season": 3, "episode": 8, "scene": 24, "line": "Hello. I don't believe we've been introduced. Dwight Schrute. Assistant Regional Manager.", "order": 9698, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 8, "scene": 24, "line": "Andy Bernard. Regional Director in Charge of Sales.", "order": 9699, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 8, "scene": 24, "line": "So you'll be reporting to me, then.", "order": 9700, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 8, "scene": 24, "line": "Umm, on the contrary.", "order": 9701, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 8, "scene": 24, "line": "My title has 'Manager' in it.", "order": 9702, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 8, "scene": 24, "line": "And I'm a director.", "order": 9703, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 8, "scene": 24, "line": "Oh.", "order": 9704, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 8, "scene": 24, "line": "Which on a film set is the highest title there is. Do you know anything about film?", "order": 9705, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 8, "scene": 24, "line": "I know everything about film. I've seen over 240 of them.", "order": 9706, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 8, "scene": 24, "line": "Congratulations.", "order": 9707, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 8, "scene": 25, "line": "Hey, Toby!", "order": 9708, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 8, "scene": 25, "line": "Hey, Jim!", "order": 9709, "speaker": "Toby Flenderson"},
  {"season": 3, "episode": 8, "scene": 25, "line": "How are you, man?", "order": 9710, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 8, "scene": 25, "line": "Oh, really good.", "order": 9711, "speaker": "Toby Flenderson"},
  {"season": 3, "episode": 8, "scene": 25, "line": "I just wanted to say hi.", "order": 9712, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 8, "scene": 25, "line": "How are you? Hey. Welcome back. [puts out fist to bump]", "order": 9713, "speaker": "Toby Flenderson"},
  {"season": 3, "episode": 8, "scene": 25, "line": "Oh. Is that like your new thing?", "order": 9714, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 8, "scene": 25, "line": "No, I'm sorry, it's...", "order": 9715, "speaker": "Toby Flenderson"},
  {"season": 3, "episode": 8, "scene": 25, "line": "No. It's cool.", "order": 9716, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 8, "scene": 25, "line": "No, it's nothing. We'll just... [shakes hand]", "order": 9717, "speaker": "Toby Flenderson"},
  {"season": 3, "episode": 8, "scene": 25, "line": "All right. Good to be back.", "order": 9718, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 8, "scene": 25, "line": "So... okay.", "order": 9719, "speaker": "Toby Flenderson"},
  {"season": 3, "episode": 8, "scene": 25, "line": "All right.", "order": 9720, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 8, "scene": 25, "line": "All right... sorry... sorry about that.", "order": 9721, "speaker": "Toby Flenderson"},
  {"season": 3, "episode": 8, "scene": 25, "line": "No problem.", "order": 9722, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 8, "scene": 25, "line": "It was just...", "order": 9723, "speaker": "Toby Flenderson"},
  {"season": 3, "episode": 8, "scene": 25, "line": "[off camera] ...what?", "order": 9724, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 8, "scene": 25, "line": "Nothing.", "order": 9725, "speaker": "Toby Flenderson"},
  {"season": 3, "episode": 8, "scene": 26, "line": "This thing is so awesome. It will shred anything. Ooh! It will shred a CD. It will shred... a credit card. It will shred... oh! [whispers] Shoot.", "order": 9726, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 8, "scene": 27, "line": "[talking to himself] I present the orientation video.", "order": 9727, "speaker": "Michael Scott"},
  {"season": 3, "episode": 8, "scene": 27, "line": "We need to talk!", "order": 9728, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 8, "scene": 27, "line": "Not now.", "order": 9729, "speaker": "Michael Scott"},
  {"season": 3, "episode": 8, "scene": 27, "line": "Which is higher? Assistant Regional Manager or Regional Director in Charge of Sales?", "order": 9730, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 8, "scene": 27, "line": "I told you the titles are irrelevant. They just relate to pay scale.", "order": 9731, "speaker": "Michael Scott"},
  {"season": 3, "episode": 8, "scene": 27, "line": "Okay. So who gets paid more? Me or Andy?", "order": 9732, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 8, "scene": 27, "line": "It is not a matter of more or less. Your pay is just different. Okay? All right! Show time, part one.", "order": 9733, "speaker": "Michael Scott"},
  {"season": 3, "episode": 8, "scene": 27, "line": "Ok. Who reports to who?", "order": 9734, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 8, "scene": 27, "line": "I don't care! Dwight! You all report to me! That's all that matters! The rest of it just work out amongst yourselves, ok?", "order": 9735, "speaker": "Michael Scott"},
  {"season": 3, "episode": 8, "scene": 27, "line": "And then if I want...", "order": 9736, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 8, "scene": 27, "line": "Work it out amongst your selves!", "order": 9737, "speaker": "Michael Scott"},
  {"season": 3, "episode": 8, "scene": 27, "line": "I...", "order": 9738, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 8, "scene": 27, "line": "Please! I have a company to run. Well, you let me run the company?", "order": 9739, "speaker": "Michael Scott"},
  {"season": 3, "episode": 8, "scene": 27, "line": "I...", "order": 9740, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 8, "scene": 27, "line": "Will you?", "order": 9741, "speaker": "Michael Scott"},
  {"season": 3, "episode": 8, "scene": 27, "line": "One...", "order": 9742, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 8, "scene": 27, "line": "Please?", "order": 9743, "speaker": "Michael Scott"},
  {"season": 3, "episode": 8, "scene": 28, "line": "Jim!", "order": 9744, "speaker": "Kelly Kapoor"},
  {"season": 3, "episode": 8, "scene": 28, "line": "Kelly!", "order": 9745, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 8, "scene": 28, "line": "Oh! Oh my god! I have so much to tell you!", "order": 9746, "speaker": "Kelly Kapoor"},
  {"season": 3, "episode": 8, "scene": 28, "line": "Really?", "order": 9747, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 8, "scene": 28, "line": "Yes! Tom Cruise and Katie Holmes? They had a baby and they named it Suri. And then Brad Pitt and Angelina Jolie? They had a baby, too, and they named it Shiloh. And both babies are amazing!", "order": 9748, "speaker": "Kelly Kapoor"},
  {"season": 3, "episode": 8, "scene": 28, "line": "Great. What's new with you?", "order": 9749, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 8, "scene": 28, "line": "I just told you.", "order": 9750, "speaker": "Kelly Kapoor"},
  {"season": 3, "episode": 8, "scene": 29, "line": "Okay. Everybody settled in? Good. Why don't we all proceed in to the conference room? Or, should I say, the banquet hall. For... drum roll, please [makes drum roll noises], the official Merger Day All-Family Welcome Breakfast. Come on in!", "order": 9751, "speaker": "Michael Scott"},
  {"season": 3, "episode": 8, "scene": 30, "line": "Yoko shack.", "order": 9752, "speaker": "Michael Scott"},
  {"season": 3, "episode": 8, "scene": 30, "line": "...thank you James. [laughs]", "order": 9753, "speaker": "Male voice"},
  {"season": 3, "episode": 8, "scene": 30, "line": "Hey, champagne.", "order": 9754, "speaker": "Meredith Palmer"},
  {"season": 3, "episode": 8, "scene": 30, "line": "Nope, no. Guests only.", "order": 9755, "speaker": "Michael Scott"},
  {"season": 3, "episode": 8, "scene": 30, "line": "Looks like salmon.", "order": 9756, "speaker": "Kelly Kapoor"},
  {"season": 3, "episode": 8, "scene": 30, "line": "Nope. Um... for the guests it is. For you, consider it cow-meat. Strictly taboo.", "order": 9757, "speaker": "Michael Scott"},
  {"season": 3, "episode": 8, "scene": 30, "line": "I eat beef.", "order": 9758, "speaker": "Kelly Kapoor"},
  {"season": 3, "episode": 8, "scene": 30, "line": "Well, then... consider it poisoned beef. No touchy.", "order": 9759, "speaker": "Michael Scott"},
  {"season": 3, "episode": 8, "scene": 30, "line": "The beef is poisoned?", "order": 9760, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 8, "scene": 30, "line": "No, it's not beef... just... sit down, please. [talks to group] Welcome. Help yourself.", "order": 9761, "speaker": "Michael Scott"},
  {"season": 3, "episode": 8, "scene": 30, "line": "Um... You might want these orientation materials.", "order": 9762, "speaker": "Toby Flenderson"},
  {"season": 3, "episode": 8, "scene": 30, "line": "Wrong. Toby, this is an orientation not a borientation.[talks to group] Okay. Do not worry. All of your questions are about to be answered. Cell phones and pagers off, please.", "order": 9763, "speaker": "Michael Scott"},
  {"season": 3, "episode": 8, "scene": 30, "line": "Oh, this looks promising.", "order": 9764, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 8, "scene": 30, "line": "You won't be disappointed.", "order": 9765, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 8, "scene": 30, "line": "Let's face it. Moving to a new job can be very stressful. So I have made an orientation video especially for you newcomers. But it's not like any orientation video that any of you have ever seen. It's funny. It's got a little bit of a zing to it and I hope that it gives you a flavor of what we're all about here at Dunder Mifflin. And what we're all about here in Scranton. So let's just all laugh together and watch 'Lazy Scranton'.", "order": 9766, "speaker": "Michael Scott"},
  {"season": 3, "episode": 8, "scene": 31, "line": "[Lazy Scranton Video]", "order": 9767, "speaker": "Video"},
  {"season": 3, "episode": 8, "scene": 31, "line": "Sittin' in my office with a plate of grilled bacon, call my man Dwight just to see what was shakin'.", "order": 9768, "speaker": "Michael Scott"},
  {"season": 3, "episode": 8, "scene": 31, "line": "Yo Mike, our town is dope and pretty.", "order": 9769, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 8, "scene": 31, "line": "So check out how we live", "order": 9770, "speaker": "Michael Scott"},
  {"season": 3, "episode": 8, "scene": 31, "line": "in the Electric City!", "order": 9771, "speaker": "Michael Scott & Dwight Schrute"},
  {"season": 3, "episode": 8, "scene": 31, "line": "They call it Scranton.", "order": 9772, "speaker": "Michael Scott"},
  {"season": 3, "episode": 8, "scene": 31, "line": "What?", "order": 9773, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 8, "scene": 31, "line": "The Electric City. Scranton.", "order": 9774, "speaker": "Michael Scott"},
  {"season": 3, "episode": 8, "scene": 31, "line": "What?", "order": 9775, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 8, "scene": 31, "line": "The Electric City. Call poison control if you're bit by a spider.", "order": 9776, "speaker": "Michael Scott"},
  {"season": 3, "episode": 8, "scene": 31, "line": "But check that it's covered by your health care provider!", "order": 9777, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 8, "scene": 32, "line": "It reminds me of the orientation video showed on my first day. 'The Scranton Witch Project'.", "order": 9778, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 8, "scene": 33, "line": "[in video] I am so scared... when people don't label their personal food.", "order": 9779, "speaker": "Michael Scott"},
  {"season": 3, "episode": 8, "scene": 34, "line": "[Lazy Scranton video continues]", "order": 9780, "speaker": "Video"},
  {"season": 3, "episode": 8, "scene": 34, "line": "You like coal mines and you wanna see 'em, Well, check it out, yo, the Anthracite Museum! Plenty of space in the parking lot,", "order": 9781, "speaker": "Michael Scott"},
  {"season": 3, "episode": 8, "scene": 34, "line": "But the little cars go in the compact spot", "order": 9782, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 8, "scene": 34, "line": "Spot, spot, spot, spot ...", "order": 9783, "speaker": "Michael Scott & Dwight Schrute"},
  {"season": 3, "episode": 8, "scene": 35, "line": "[on video in background] Spot. Scranton. What? The Electric City. Scranton.What? The Electric City. Scranton. What?", "order": 9784, "speaker": "Michael Scott"},
  {"season": 3, "episode": 8, "scene": 35, "line": "Well, so far, I think it is killin'. I thought it would either be an 'A' or an 'A+' but I completely forgot that there's an 'A++'.", "order": 9785, "speaker": "Michael Scott"},
  {"season": 3, "episode": 8, "scene": 36, "line": "[recording phone message] This is Karen Filippelli. Please leave a message.", "order": 9786, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 8, "scene": 36, "line": "Terrible. Totally unconvincing.", "order": 9787, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 8, "scene": 36, "line": "[recording] This is Karen Filippelli. Please leave a message.", "order": 9788, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 8, "scene": 36, "line": "Not bad, but you are Italian so... try it more Italian.", "order": 9789, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 8, "scene": 36, "line": "[recording] [in bad Italian accent] Dis is Kar-en Fill-uh-pel-li. Please leava me da message. A bon danza.", "order": 9790, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 8, "scene": 36, "line": "You feel good?", "order": 9791, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 8, "scene": 36, "line": "Mm-hm.", "order": 9792, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 8, "scene": 36, "line": "All right.", "order": 9793, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 8, "scene": 36, "line": "[recording phone message] Karen Filippelli.", "order": 9794, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 8, "scene": 37, "line": "Hey, buddy. Anything new to report?", "order": 9795, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 8, "scene": 37, "line": "Do you mean to me? From you? Cause that's how it works.", "order": 9796, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 8, "scene": 37, "line": "Sure thing, buddy.", "order": 9797, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 8, "scene": 38, "line": "Am I trying to get under his skin? Yes. Because the angrier he gets, the more marginalized he becomes. Meanwhile, Andy Bernard is out there layin' on the charm.", "order": 9798, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 8, "scene": 39, "line": "Hey, Angela. Check this out. It's my new screen saver.", "order": 9799, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 8, "scene": 39, "line": "Oh.", "order": 9800, "speaker": "Angela Martin"},
  {"season": 3, "episode": 8, "scene": 39, "line": "Do you like it?", "order": 9801, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 8, "scene": 39, "line": "I do like it, actually.", "order": 9802, "speaker": "Angela Martin"},
  {"season": 3, "episode": 8, "scene": 39, "line": "Thank you. You have such a pretty smile by the way.", "order": 9803, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 8, "scene": 39, "line": "Thank you.", "order": 9804, "speaker": "Angela Martin"},
  {"season": 3, "episode": 8, "scene": 39, "line": "You're welcome.", "order": 9805, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 8, "scene": 40, "line": "Hey.", "order": 9806, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 8, "scene": 40, "line": "Hey!", "order": 9807, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 8, "scene": 40, "line": "What happened to grape soda?", "order": 9808, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 8, "scene": 40, "line": "Oh yeah. I'm trying to move away from that. Getting into more of a bottled water phase.", "order": 9809, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 8, "scene": 40, "line": "Oh. You've changed so much.", "order": 9810, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 8, "scene": 40, "line": "Well, I'm evolving, Pam.", "order": 9811, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 8, "scene": 40, "line": "So when do I get to hear everything? Are you still getting unpacked or... you want to grab a coffee or something after work?", "order": 9812, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 8, "scene": 40, "line": "Oh! Um... tonight, actually? No. I'm uh just still getting settled.", "order": 9813, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 8, "scene": 40, "line": "Oh, yeah, no! You know. Whenever.", "order": 9814, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 8, "scene": 40, "line": "Okay.", "order": 9815, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 8, "scene": 40, "line": "Oh-kay. Sorry to interrupt. I...", "order": 9816, "speaker": "Michael Scott"},
  {"season": 3, "episode": 8, "scene": 40, "line": "Nope. You're not interrupting anything. Nope. I'm...", "order": 9817, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 8, "scene": 40, "line": "All right.", "order": 9818, "speaker": "Michael Scott"},
  {"season": 3, "episode": 8, "scene": 40, "line": "Don't...", "order": 9819, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 8, "scene": 40, "line": "Okay.", "order": 9820, "speaker": "Michael Scott"},
  {"season": 3, "episode": 8, "scene": 40, "line": "All right. [to Pam] I should probably get back to work. Get back to work.", "order": 9821, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 8, "scene": 40, "line": "Yeah. I know, me too.", "order": 9822, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 8, "scene": 40, "line": "All right!", "order": 9823, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 8, "scene": 41, "line": "The day's going fine. It's been a little chaotic but it's fine. It's great! A lot of distractions. But, it's good.", "order": 9824, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 8, "scene": 42, "line": "[using breast pump] Take a picture. It'll last longer.", "order": 9825, "speaker": "Hannah"},
  {"season": 3, "episode": 8, "scene": 42, "line": "I'm sorry. It's just, it's a little distracting.", "order": 9826, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 8, "scene": 42, "line": "Ditto that, my brother.", "order": 9827, "speaker": "Creed Bratton"},
  {"season": 3, "episode": 8, "scene": 43, "line": "Look what's on his computer.", "order": 9828, "speaker": "Hannah"},
  {"season": 3, "episode": 8, "scene": 43, "line": "What is that? A squid's eye or...", "order": 9829, "speaker": "Michael Scott"},
  {"season": 3, "episode": 8, "scene": 43, "line": "It's my left breast.", "order": 9830, "speaker": "Hannah"},
  {"season": 3, "episode": 8, "scene": 43, "line": "How did you...", "order": 9831, "speaker": "Michael Scott"},
  {"season": 3, "episode": 8, "scene": 43, "line": "Right place at the right time.", "order": 9832, "speaker": "Creed Bratton"},
  {"season": 3, "episode": 8, "scene": 44, "line": "Uh, what's that smell?", "order": 9833, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 8, "scene": 44, "line": "What smell?", "order": 9834, "speaker": "Phyllis Lapin"},
  {"season": 3, "episode": 8, "scene": 44, "line": "Must be an... air freshener plugged in somewhere. It smells like a funeral home.", "order": 9835, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 8, "scene": 44, "line": "Oh, I'll help you find it.", "order": 9836, "speaker": "Phyllis Lapin"},
  {"season": 3, "episode": 8, "scene": 44, "line": "Oh, you know. Never... .never mind.", "order": 9837, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 8, "scene": 44, "line": "What is it?", "order": 9838, "speaker": "Phyllis Lapin"},
  {"season": 3, "episode": 8, "scene": 44, "line": "I... I... I, uh, think I'm just allergic to your perfume.", "order": 9839, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 8, "scene": 44, "line": "My perfume?", "order": 9840, "speaker": "Phyllis Lapin"},
  {"season": 3, "episode": 8, "scene": 44, "line": "It's just my crazy nose. I'm... uh, used to different smells.", "order": 9841, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 8, "scene": 44, "line": "Bob Vance bought this perfume for me in Metropolitan Orlando. It's made from real pine.", "order": 9842, "speaker": "Phyllis Lapin"},
  {"season": 3, "episode": 8, "scene": 44, "line": "Who's Bob Vance?", "order": 9843, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 8, "scene": 44, "line": "You have a lot to learn about this town, sweetie.", "order": 9844, "speaker": "Phyllis Lapin"},
  {"season": 3, "episode": 8, "scene": 45, "line": "I don't know who these new people think they are. I've sat downwind of Phyllis' stinky perfumes for years. Never said a word.", "order": 9845, "speaker": "Stanley Hudson"},
  {"season": 3, "episode": 8, "scene": 46, "line": "People hate people that are different from them. That's natural. But you know what makes people forget their differences? A great show. That is why I created the 'Integration Celebration'. This is the moment when Scranton and Stamford come together as one. United in applause.", "order": 9846, "speaker": "Michael Scott"},
  {"season": 3, "episode": 8, "scene": 47, "line": "I know what a lot of you must be thinking. 'Wow! What a day! Feels more like a night at a party than a day at work.' Well, in my opinion, business should feel like a night out. A night... at... the Roxbury. Okay. There's supposed to be music and it's... I got it, I got it! I got it. Dwight! Just.", "order": 9847, "speaker": "Michael Scott"},
  {"season": 3, "episode": 8, "scene": 47, "line": "Do you have batteries?", "order": 9848, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 8, "scene": 47, "line": "Ssshh stupid! Um...", "order": 9849, "speaker": "Michael Scott"},
  {"season": 3, "episode": 8, "scene": 47, "line": "[sings drum beat intro to 'What is Love?']", "order": 9850, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 8, "scene": 47, "line": "That's it!", "order": 9851, "speaker": "Michael Scott"},
  {"season": 3, "episode": 8, "scene": 47, "line": "[sings] What is love?", "order": 9852, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 8, "scene": 47, "line": "Yes, yes! Okay!", "order": 9853, "speaker": "Michael Scott"},
  {"season": 3, "episode": 8, "scene": 47, "line": "Baby, don't hurt me.", "order": 9854, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 8, "scene": 47, "line": "Okay, here we go.", "order": 9855, "speaker": "Michael Scott"},
  {"season": 3, "episode": 8, "scene": 47, "line": "Don't hurt me! Baby, don't hurt me!", "order": 9856, "speaker": "Andy Bernard & Michael Scott"},
  {"season": 3, "episode": 8, "scene": 47, "line": "Oh!", "order": 9857, "speaker": "Michael Scott"},
  {"season": 3, "episode": 8, "scene": 47, "line": "Baby, don't hurt me!", "order": 9858, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 8, "scene": 47, "line": "Oh! Oh! Oh!", "order": 9859, "speaker": "Michael Scott"},
  {"season": 3, "episode": 8, "scene": 47, "line": "Whoa-oh-oh-oh", "order": 9860, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 8, "scene": 47, "line": "Scuse me! Scuse me!", "order": 9861, "speaker": "Michael Scott"},
  {"season": 3, "episode": 8, "scene": 47, "line": "Whoa-oh-oh-oh! Whoa-oh-oh...", "order": 9862, "speaker": "Andy Bernard & Michael Scott"},
  {"season": 3, "episode": 8, "scene": 47, "line": "You me? You me? Me you? You! You! You me! Bow Bow Bow!", "order": 9863, "speaker": "Michael Scott"},
  {"season": 3, "episode": 8, "scene": 47, "line": "Whoa-oh-oh-oh.", "order": 9864, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 8, "scene": 47, "line": "You me? You me? Me you me! You! You! You me you! You! Oh my nose so itchy, why's my nose so itchy!", "order": 9865, "speaker": "Michael Scott"},
  {"season": 3, "episode": 8, "scene": 47, "line": "Oh, probably because of all the nose candy.", "order": 9866, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 8, "scene": 47, "line": "[laughs hysterically] Okay, I told you these guys had a sense of humor.", "order": 9867, "speaker": "Michael Scott"},
  {"season": 3, "episode": 8, "scene": 47, "line": "Very funny, Michael!", "order": 9868, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 8, "scene": 47, "line": "Okay! Okay!", "order": 9869, "speaker": "Michael Scott"},
  {"season": 3, "episode": 8, "scene": 47, "line": "Really funny, Michael!", "order": 9870, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 8, "scene": 47, "line": "All right, all right, I'm on a roll.", "order": 9871, "speaker": "Michael Scott"},
  {"season": 3, "episode": 8, "scene": 48, "line": "Why are the new people on the table? To show them that we are not above them.", "order": 9872, "speaker": "Michael Scott"},
  {"season": 3, "episode": 8, "scene": 48, "line": "Shouldn't we be equals?", "order": 9873, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 8, "scene": 48, "line": "Not today. No. Tony! Please join your cohorts on the table if you would.", "order": 9874, "speaker": "Michael Scott"},
  {"season": 3, "episode": 8, "scene": 48, "line": "Uh... this is difficult, for me.", "order": 9875, "speaker": "Tony"},
  {"season": 3, "episode": 8, "scene": 48, "line": "I understand. We're all friends.", "order": 9876, "speaker": "Michael Scott"},
  {"season": 3, "episode": 8, "scene": 48, "line": "No. I mean I can't physically. I can't get on the table.", "order": 9877, "speaker": "Tony"},
  {"season": 3, "episode": 8, "scene": 48, "line": "Oh, well. Just use the momentum of your lower half to hoist yourself up.", "order": 9878, "speaker": "Michael Scott"},
  {"season": 3, "episode": 8, "scene": 48, "line": "[starts to climb table]", "order": 9879, "speaker": "Tony"},
  {"season": 3, "episode": 8, "scene": 48, "line": "You know what? I'll help. I will...", "order": 9880, "speaker": "Michael Scott"},
  {"season": 3, "episode": 8, "scene": 48, "line": "No, please. No.", "order": 9881, "speaker": "Tony"},
  {"season": 3, "episode": 8, "scene": 48, "line": "Don't be shy! Dwight! Let's do this!", "order": 9882, "speaker": "Michael Scott"},
  {"season": 3, "episode": 8, "scene": 48, "line": "Do this. Come on. Ready?", "order": 9883, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 8, "scene": 48, "line": "Come on. We're doing this thing! Let's get up.", "order": 9884, "speaker": "Michael Scott"},
  {"season": 3, "episode": 8, "scene": 48, "line": "On three. One... two... three.", "order": 9885, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 8, "scene": 48, "line": "Bend at the knees. Okay, here we go. Here we go. I'm under this... I'm under this hock here. I don't know what I'm grabbing here.", "order": 9886, "speaker": "Michael Scott"},
  {"season": 3, "episode": 8, "scene": 48, "line": "All right. All right. Stop. Put me down.", "order": 9887, "speaker": "Tony"},
  {"season": 3, "episode": 8, "scene": 48, "line": "We've almost got it. Push it! Push it!", "order": 9888, "speaker": "Michael Scott"},
  {"season": 3, "episode": 8, "scene": 48, "line": "Let me go!", "order": 9889, "speaker": "Tony"},
  {"season": 3, "episode": 8, "scene": 48, "line": "I'm right in your crack!", "order": 9890, "speaker": "Michael Scott"},
  {"season": 3, "episode": 8, "scene": 48, "line": "Put me down right...", "order": 9891, "speaker": "Tony"},
  {"season": 3, "episode": 8, "scene": 48, "line": "Up and over.", "order": 9892, "speaker": "Michael Scott"},
  {"season": 3, "episode": 8, "scene": 48, "line": "Put me down!", "order": 9893, "speaker": "Tony"},
  {"season": 3, "episode": 8, "scene": 48, "line": "Up and over.", "order": 9894, "speaker": "Michael Scott"},
  {"season": 3, "episode": 8, "scene": 48, "line": "Put me down right now!", "order": 9895, "speaker": "Tony"},
  {"season": 3, "episode": 8, "scene": 48, "line": "You've got it.", "order": 9896, "speaker": "Michael Scott"},
  {"season": 3, "episode": 8, "scene": 48, "line": "I'VE HAD ENOUGH!", "order": 9897, "speaker": "Tony"},
  {"season": 3, "episode": 8, "scene": 48, "line": "You've got it, you've got it.", "order": 9898, "speaker": "Michael Scott"},
  {"season": 3, "episode": 8, "scene": 48, "line": "PUT ME DOWN RIGHT NOW or else!", "order": 9899, "speaker": "Tony"},
  {"season": 3, "episode": 8, "scene": 48, "line": "Whoa Whoa Whoa! Okay.", "order": 9900, "speaker": "Michael Scott"},
  {"season": 3, "episode": 8, "scene": 48, "line": "Hey, hey. Easy.", "order": 9901, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 8, "scene": 48, "line": "Easy.", "order": 9902, "speaker": "Michael Scott"},
  {"season": 3, "episode": 8, "scene": 48, "line": "I'm sorry!", "order": 9903, "speaker": "Tony"},
  {"season": 3, "episode": 8, "scene": 48, "line": "Don't...", "order": 9904, "speaker": "Michael Scott"},
  {"season": 3, "episode": 8, "scene": 48, "line": "It's just not going to work for me.", "order": 9905, "speaker": "Tony"},
  {"season": 3, "episode": 8, "scene": 48, "line": "What...", "order": 9906, "speaker": "Michael Scott"},
  {"season": 3, "episode": 8, "scene": 48, "line": "I have to go.", "order": 9907, "speaker": "Tony"},
  {"season": 3, "episode": 8, "scene": 48, "line": "I don't understand.", "order": 9908, "speaker": "Michael Scott"},
  {"season": 3, "episode": 8, "scene": 48, "line": "I was on the fence about this and... it's just not a good fit.", "order": 9909, "speaker": "Tony"},
  {"season": 3, "episode": 8, "scene": 48, "line": "Well, we'll squeeze you in.", "order": 9910, "speaker": "Michael Scott"},
  {"season": 3, "episode": 8, "scene": 48, "line": "I can't work here. I have to quit.", "order": 9911, "speaker": "Tony"},
  {"season": 3, "episode": 8, "scene": 49, "line": "You can't quit! On the first day. That's [deep voice] heresy, my friend! [regular voice] Okay, let's talk about this. What happened? I mean, what... Was it Toby? Did he say something? Cause he's... what?", "order": 9912, "speaker": "Michael Scott"},
  {"season": 3, "episode": 8, "scene": 49, "line": "No. Toby was helpful. He was very kind. It's just your management style.", "order": 9913, "speaker": "Tony"},
  {"season": 3, "episode": 8, "scene": 49, "line": "My management style? So... didn't you think 'Lazy Scranton' was funny?", "order": 9914, "speaker": "Michael Scott"},
  {"season": 3, "episode": 8, "scene": 49, "line": "No. Was it supposed to be funny?", "order": 9915, "speaker": "Tony"},
  {"season": 3, "episode": 8, "scene": 49, "line": "Okay. Well, don't bother quitting because you're fired.", "order": 9916, "speaker": "Michael Scott"},
  {"season": 3, "episode": 8, "scene": 49, "line": "Excuse me?", "order": 9917, "speaker": "Tony"},
  {"season": 3, "episode": 8, "scene": 49, "line": "You are fired! I'm sorry, but we don't have quitters on this team! Just clean out your desk!", "order": 9918, "speaker": "Michael Scott"},
  {"season": 3, "episode": 8, "scene": 49, "line": "But there's nothing in my desk except coupons.", "order": 9919, "speaker": "Tony"},
  {"season": 3, "episode": 8, "scene": 49, "line": "Don't try to apologize to me, man! It's too late. Just get out! Take your bad vibes with you.", "order": 9920, "speaker": "Michael Scott"},
  {"season": 3, "episode": 8, "scene": 49, "line": "That was my advice. Remember? I'm the one who suggested that you fire him.", "order": 9921, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 8, "scene": 49, "line": "Probably the best advice you ever gave me, Dwight.", "order": 9922, "speaker": "Michael Scott"},
  {"season": 3, "episode": 8, "scene": 49, "line": "And what advice has Andy given to you today that you have acted on? Would 'none' be an accurate estimate? None advice? [whispers] Fire Andy. Fire. Andy.", "order": 9923, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 8, "scene": 50, "line": "Dwight may have won the battle. But I will win... the next battle.", "order": 9924, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 8, "scene": 51, "line": "Tony was right. This environment is dysfunctional.", "order": 9925, "speaker": "Hannah"},
  {"season": 3, "episode": 8, "scene": 51, "line": "Maybe that's because some people treat it like their own private Hooters strip club.", "order": 9926, "speaker": "Angela Martin"},
  {"season": 3, "episode": 8, "scene": 51, "line": "Whoa, Angela! Hold on. Hooters is a restaurant. With over 400 locations worldwide.", "order": 9927, "speaker": "Michael Scott"},
  {"season": 3, "episode": 8, "scene": 51, "line": "Back to work. We don't have to get along. We just have to work together.", "order": 9928, "speaker": "Stanley Hudson"},
  {"season": 3, "episode": 8, "scene": 51, "line": "No. We do have to get along. Can't we all just get along? Or have we forgotten the words of the Reverend King.", "order": 9929, "speaker": "Michael Scott"},
  {"season": 3, "episode": 8, "scene": 52, "line": "The word merger comes from the word marriage. And that was what today was supposed to be. The loving union between people. Instead, it has become like when my Mom moved in with Jeff. And once again, it becomes my job to fix it.", "order": 9930, "speaker": "Michael Scott"},
  {"season": 3, "episode": 8, "scene": 53, "line": "Hey! Hey, everybody! Something happened! Those guys from Vance Refrigeration, they let the air out of our tires.", "order": 9931, "speaker": "Michael Scott"},
  {"season": 3, "episode": 8, "scene": 53, "line": "What!", "order": 9932, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 8, "scene": 53, "line": "Yeah! They punk'd us! They punk'd us good! Come on. Come on!", "order": 9933, "speaker": "Michael Scott"},
  {"season": 3, "episode": 8, "scene": 53, "line": "Does Bob Vance work for Vance Refrigeration?", "order": 9934, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 8, "scene": 53, "line": "Does he ever.", "order": 9935, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 8, "scene": 54, "line": "Man, they got us so bad. We cannot let them get away with this. We have got to pull together as one and steal their refrigerators.", "order": 9936, "speaker": "Michael Scott"},
  {"season": 3, "episode": 8, "scene": 54, "line": "YEAH!", "order": 9937, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 8, "scene": 54, "line": "Yeah.", "order": 9938, "speaker": "Michael Scott"},
  {"season": 3, "episode": 8, "scene": 54, "line": "I don't... I don't think we can do that.", "order": 9939, "speaker": "Toby Flenderson"},
  {"season": 3, "episode": 8, "scene": 54, "line": "Go home, Toby. Just...", "order": 9940, "speaker": "Michael Scott"},
  {"season": 3, "episode": 8, "scene": 54, "line": "Hey! Why doesn't your car have a flat tire?", "order": 9941, "speaker": "Martin"},
  {"season": 3, "episode": 8, "scene": 54, "line": "Why? I will tell you why. Because they saved the worst for me. They put a hate note under my windshield wiper. Check this out. It's so hateful. [reads note] 'You guys SUCK! You can never pull together as one and revenge us. That is why you SUCK!'", "order": 9942, "speaker": "Michael Scott"},
  {"season": 3, "episode": 8, "scene": 54, "line": "For crying out loud.", "order": 9943, "speaker": "Hannah"},
  {"season": 3, "episode": 8, "scene": 54, "line": "No. No, no, no. No. You are falling for it. You're playing right into their hands. This is just what they want you to do. Don't, oh...", "order": 9944, "speaker": "Michael Scott"},
  {"season": 3, "episode": 8, "scene": 54, "line": "[to Stanley] What's up with this guy?", "order": 9945, "speaker": "Martin"},
  {"season": 3, "episode": 8, "scene": 54, "line": "Got an hour? I'll try to explain.", "order": 9946, "speaker": "Stanley Hudson"},
  {"season": 3, "episode": 8, "scene": 54, "line": "THIS IS EGREGIOUS! This is egregious!", "order": 9947, "speaker": "Michael Scott"},
  {"season": 3, "episode": 8, "scene": 55, "line": "Trust me. It only gets worse.", "order": 9948, "speaker": "Stanley Hudson"},
  {"season": 3, "episode": 8, "scene": 55, "line": "Is he always like this?", "order": 9949, "speaker": "Martin"},
  {"season": 3, "episode": 8, "scene": 55, "line": "Sometimes he brings more costumes.", "order": 9950, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 8, "scene": 55, "line": "When do people work?", "order": 9951, "speaker": "Hannah"},
  {"season": 3, "episode": 8, "scene": 55, "line": "Oh, we find little times during the day.", "order": 9952, "speaker": "Phyllis Lapin"},
  {"season": 3, "episode": 8, "scene": 55, "line": "How are we going to get home?", "order": 9953, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 8, "scene": 55, "line": "Bob Vance has an air pump. He said he'd fill all our tires up.", "order": 9954, "speaker": "Phyllis Lapin"},
  {"season": 3, "episode": 8, "scene": 55, "line": "Bob Vance of Vance Refrigeration.", "order": 9955, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 8, "scene": 56, "line": "See that? Mission accomplished. Like a bunch of fourth graders. Sometimes, what brings the kids together is hating the lunch lady. Although that'll change. Because, by the end of the fourth grade, the lunch lady was actually the person I hung out with the most. [answers phone] Jell-o!", "order": 9956, "speaker": "Michael Scott"},
  {"season": 3, "episode": 8, "scene": 56, "line": "Michael!", "order": 9957, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 8, "scene": 56, "line": "Hi, Jan!", "order": 9958, "speaker": "Michael Scott"},
  {"season": 3, "episode": 8, "scene": 56, "line": "Did you fire Tony Gardner when he was trying to quit?", "order": 9959, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 8, "scene": 56, "line": "I did. Major personnel crises averted. Compliment accepted.", "order": 9960, "speaker": "Michael Scott"},
  {"season": 3, "episode": 8, "scene": 56, "line": "Do you realize, Michael, that we now have to pay him severance?", "order": 9961, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 8, "scene": 56, "line": "Yes.", "order": 9962, "speaker": "Michael Scott"},
  {"season": 3, "episode": 8, "scene": 56, "line": "You do?", "order": 9963, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 8, "scene": 56, "line": "But do you realize that that was actually Dwight's idea? Bad advice from my Number Two.", "order": 9964, "speaker": "Michael Scott"},
  {"season": 3, "episode": 8, "scene": 56, "line": "What? No. No. No, Jim is your Number Two.", "order": 9965, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 8, "scene": 56, "line": "What?", "order": 9966, "speaker": "Michael Scott"},
  {"season": 3, "episode": 8, "scene": 56, "line": "He's the only one who has worked with both groups. I sent you a memo about this.", "order": 9967, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 8, "scene": 56, "line": "Yes, I know that. For, I do read the memos.", "order": 9968, "speaker": "Michael Scott"},
  {"season": 3, "episode": 8, "scene": 57, "line": "So after a great deal of thought and introspective-shun, I have decided to make Jim my new Number Two.", "order": 9969, "speaker": "Michael Scott"},
  {"season": 3, "episode": 8, "scene": 57, "line": "If he even wants it. It doesn't come with a pay raise.", "order": 9970, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 8, "scene": 57, "line": "Yeah, it does, actually.", "order": 9971, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 8, "scene": 57, "line": "So who will be your new Number Three?", "order": 9972, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 8, "scene": 57, "line": "Uh... that I have not decided yet.", "order": 9973, "speaker": "Michael Scott"},
  {"season": 3, "episode": 8, "scene": 57, "line": "Michael, I would just like to say you have handled this entire situation with great aplomb.", "order": 9974, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 8, "scene": 57, "line": "Thank you, Andy. That's... very kind. Thanks.", "order": 9975, "speaker": "Michael Scott"},
  {"season": 3, "episode": 8, "scene": 57, "line": "And I have to say your leadership...", "order": 9976, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 8, "scene": 57, "line": "Shut it...", "order": 9977, "speaker": "Michael Scott"},
  {"season": 3, "episode": 8, "scene": 57, "line": "has brought...", "order": 9978, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 8, "scene": 57, "line": "Shut it! That's... [whispers to camera] suck up!", "order": 9979, "speaker": "Michael Scott"},
  {"season": 3, "episode": 8, "scene": 58, "line": "[talking on cellphone] Hey! Where you at, Filippelli?", "order": 9980, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 8, "scene": 58, "line": "[off camera] I'm at the grocery store buying a corkscrew to give myself a lobotomy.", "order": 9981, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 8, "scene": 58, "line": "[laughs] What's wrong? You didn't have a good first day?", "order": 9982, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 8, "scene": 58, "line": "Oh my god! Hey! You want to meet at Cooper's in an hour? I need a drink.", "order": 9983, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 8, "scene": 58, "line": "Yeah. Sure. Sounds good. I... . [sees Pam in rearview mirror] Hey! You know what? Can I give you a call right back?", "order": 9984, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 8, "scene": 58, "line": "Yeah.", "order": 9985, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 8, "scene": 58, "line": "Ok, thanks.", "order": 9986, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 8, "scene": 58, "line": "Ok.", "order": 9987, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 8, "scene": 59, "line": "Hey.", "order": 9988, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 8, "scene": 59, "line": "Hey!", "order": 9989, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 8, "scene": 59, "line": "I thought you had already... left.", "order": 9990, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 8, "scene": 59, "line": "Uh... no. I just uh had some other stuff I had to do.", "order": 9991, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 8, "scene": 59, "line": "Oh... Good.", "order": 9992, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 8, "scene": 59, "line": "What's up?", "order": 9993, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 8, "scene": 59, "line": "Oh, nothing. I just feel bad. I feel like things were a little weird today... or something.", "order": 9994, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 8, "scene": 59, "line": "What do you mean?", "order": 9995, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 8, "scene": 59, "line": "I just think I should tell you that... I've sort of started seeing someone. And uh...", "order": 9996, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 8, "scene": 59, "line": "Oh. That's totally cool. You can do whatever you want.", "order": 9997, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 8, "scene": 59, "line": "O..ok. Um..good.", "order": 9998, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 8, "scene": 59, "line": "We're friends. We'll always be friends.", "order": 9999, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 8, "scene": 59, "line": "Right.", "order": 10000, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 8, "scene": 59, "line": "It's good to have you back.", "order": 10001, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 8, "scene": 59, "line": "Yeah. Good to be back.", "order": 10002, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 8, "scene": 60, "line": "Where'd you get that salad?", "order": 10003, "speaker": "Meredith Palmer"},
  {"season": 3, "episode": 8, "scene": 60, "line": "Staples.", "order": 10004, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 8, "scene": 61, "line": "Saw your dorkmobile in the parking lot. What does it get, like four miles to the gallon?", "order": 10005, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 8, "scene": 61, "line": "Uh, try double that. Classic TransAm, vintage American muscle. Please.", "order": 10006, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 8, "scene": 61, "line": "Yeah, my Xterra's pretty sweet. Luxurious, yet rugged. Leave it to the Japanese.", "order": 10007, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 8, "scene": 61, "line": "Xterra's not even a real word.", "order": 10008, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 8, "scene": 61, "line": "Actually, it is. It's Latin for 'earth.'", "order": 10009, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 8, "scene": 61, "line": "Oh, so you drive an X-Earth?", "order": 10010, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 8, "scene": 61, "line": "Yeah.", "order": 10011, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 8, "scene": 61, "line": "That makes sense. I'd rather drive a classic TransAm than an XEarth.", "order": 10012, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 8, "scene": 61, "line": "Yeah, I bet you would. Oh, by the way, 1985 called. It wants its car back.", "order": 10013, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 8, "scene": 61, "line": "Well I hope 1985 has a time machine 'cause I drive an 87.", "order": 10014, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 8, "scene": 61, "line": "Oh, speaking of time machines, I just got back from the future and I went to your funeral and, guess what, nobody came.", "order": 10015, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 8, "scene": 61, "line": "Speaking of funerals, why don't you go ahead and go die.", "order": 10016, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 8, "scene": 61, "line": "Oh, that was a really well constructed sentence. You should be an English professor at Cor-not University.", "order": 10017, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 8, "scene": 61, "line": "Idiot!", "order": 10018, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 8, "scene": 61, "line": "If I were an idiot, I'd be driving a TransAm.", "order": 10019, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 8, "scene": 61, "line": "If you were driving a TransAm, you would be the smartest idiot in the whole world.", "order": 10020, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 8, "scene": 61, "line": "[coughs word] Idiot!", "order": 10021, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 8, "scene": 61, "line": "[coughs sentence] You're the idiot!", "order": 10022, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 8, "scene": 61, "line": "[coughs sentence] Nice comeback!", "order": 10023, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 8, "scene": 61, "line": "[coughs sentence] I was making fun of your comeback! That's why it worked. [talks normally to camera] Totally got the best of that interchange.", "order": 10024, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 8, "scene": 62, "line": "And this is, don't tell me, Martin... Ne... Ne...Nack.", "order": 10025, "speaker": "Michael Scott"},
  {"season": 3, "episode": 8, "scene": 62, "line": "Nash.", "order": 10026, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 8, "scene": 62, "line": "Martin Nash. Okay.", "order": 10027, "speaker": "Michael Scott"},
  {"season": 3, "episode": 8, "scene": 62, "line": "Male, age 37...", "order": 10028, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 8, "scene": 62, "line": "Good.", "order": 10029, "speaker": "Michael Scott"},
  {"season": 3, "episode": 8, "scene": 62, "line": "Mocha complexion. Supplier relations. Either caught a fish or was standing next to a man who caught a fish in Key West, Florida.", "order": 10030, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 8, "scene": 62, "line": "What are you talking about?", "order": 10031, "speaker": "Michael Scott"},
  {"season": 3, "episode": 8, "scene": 62, "line": "[holds up piece of paper] It wasn't clear on the Google Image search.", "order": 10032, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 8, "scene": 62, "line": "All right, just...", "order": 10033, "speaker": "Michael Scott"},
  {"season": 3, "episode": 8, "scene": 63, "line": "Hey, Kev. How you doing?", "order": 10034, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 8, "scene": 63, "line": "Hey, man. Good. Listen, my M&M's are under the desk if you want any.", "order": 10035, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 8, "scene": 63, "line": "Okay.", "order": 10036, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 8, "scene": 63, "line": "Hidden from [whisper] them.", "order": 10037, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 8, "scene": 63, "line": "Smart. Good. [to Angela] Hey, Angela.", "order": 10038, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 8, "scene": 63, "line": "You need a hair cut.", "order": 10039, "speaker": "Angela Martin"},
  {"season": 3, "episode": 8, "scene": 63, "line": "Bye, Angela. All right, Kev.", "order": 10040, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 8, "scene": 63, "line": "See you later, Jim.", "order": 10041, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 8, "scene": 63, "line": "See you later.", "order": 10042, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 8, "scene": 63, "line": "[to Angela] It's his first day back.", "order": 10043, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 8, "scene": 63, "line": "His hair's flipping out on the sides.", "order": 10044, "speaker": "Angela Martin"},
  {"season": 3, "episode": 8, "scene": 63, "line": "[smiles] I like it.", "order": 10045, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 8, "scene": 63, "line": "Oh, my Lord.", "order": 10046, "speaker": "Angela Martin"},
  {"season": 3, "episode": 8, "scene": 64, "line": "This is weird with you facing the other way.", "order": 10047, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 8, "scene": 64, "line": "Yeah, I never even knew that part of the office existed. [Pam chuckles] Hey, who's that guy? [Jim points to Stanley]", "order": 10048, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 8, "scene": 64, "line": "That's Stanley Hudson.", "order": 10049, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 8, "scene": 64, "line": "Huh.", "order": 10050, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 8, "scene": 64, "line": "He's one of our salesmen.", "order": 10051, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 8, "scene": 64, "line": "Seems like a nice enough guy.", "order": 10052, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 8, "scene": 64, "line": "Oh very.", "order": 10053, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 8, "scene": 64, "line": "What about, uh.. [points at Meredith]", "order": 10054, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 8, "scene": 64, "line": "Hmm, that is... Janet Fenstermaker.", "order": 10055, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 8, "scene": 64, "line": "Really?", "order": 10056, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 8, "scene": 64, "line": "[Pam nods head] You should say hi. Use her full name.", "order": 10057, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 8, "scene": 64, "line": "Thank you for all of this. This is so helpful. You know what? One last question. Um, who is that fine older gentleman in the corner? [points to Creed]", "order": 10058, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 8, "scene": 64, "line": "Uh, that is Creed Bratton. He has four toes and he fought in the Civil war.", "order": 10059, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 8, "scene": 64, "line": "For the North.", "order": 10060, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 8, "scene": 64, "line": "For both sides. Whoever paid more.", "order": 10061, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 8, "scene": 64, "line": "Obviously.", "order": 10062, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 8, "scene": 64, "line": "Yeah.", "order": 10063, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 8, "scene": 64, "line": "Hey, uh sorry. Seriously, can you guys... I'm trying to get some work done.", "order": 10064, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 8, "scene": 64, "line": "Sorry. Man. [Pam hesitates and then leaves]", "order": 10065, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 8, "scene": 64, "line": "Thanks.", "order": 10066, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 8, "scene": 65, "line": "Hey, nice thermos.", "order": 10067, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 8, "scene": 65, "line": "[notices Karen has same thermos as herself] Hey. That's funny.", "order": 10068, "speaker": "Meredith Palmer"},
  {"season": 3, "episode": 8, "scene": 65, "line": "Yeah.", "order": 10069, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 8, "scene": 65, "line": "[holds out flask] Do you want a little?", "order": 10070, "speaker": "Meredith Palmer"},
  {"season": 3, "episode": 8, "scene": 65, "line": "Uh no, I'm fine, thank you.", "order": 10071, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 8, "scene": 65, "line": "Okay. If you change your mind, you just let me know, okay?", "order": 10072, "speaker": "Meredith Palmer"},
  {"season": 3, "episode": 8, "scene": 65, "line": "Yeah, I'm okay, thanks.", "order": 10073, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 8, "scene": 65, "line": "That's cool.", "order": 10074, "speaker": "Meredith Palmer"},
  {"season": 3, "episode": 8, "scene": 66, "line": "[approaching Kevin to shake hand] Hi, Andy Bernard.", "order": 10075, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 8, "scene": 66, "line": "Kevin Malone.", "order": 10076, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 8, "scene": 66, "line": "Nice to meet you.", "order": 10077, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 8, "scene": 66, "line": "What's that on your shirt? [points to Andy's shirt]", "order": 10078, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 8, "scene": 66, "line": "That is a penguin. You like it?", "order": 10079, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 8, "scene": 66, "line": "The Penguin.", "order": 10080, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 8, "scene": 66, "line": "Andy, or Andrew.", "order": 10081, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 8, "scene": 66, "line": "Okay, Penguin. [laughs]", "order": 10082, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 8, "scene": 66, "line": "Smiles.", "order": 10083, "speaker": "Michael Scott"},
  {"season": 3, "episode": 8, "scene": 66, "line": "Yeah.", "order": 10084, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 8, "scene": 66, "line": "Good, what's funny?", "order": 10085, "speaker": "Michael Scott"},
  {"season": 3, "episode": 8, "scene": 66, "line": "Michael, have you met the Penguin?", "order": 10086, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 8, "scene": 66, "line": "Oh, nicknames, yay! Penguin power.", "order": 10087, "speaker": "Michael Scott"},
  {"season": 3, "episode": 8, "scene": 66, "line": "Ha, that's why I wore this shirt.", "order": 10088, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 8, "scene": 66, "line": "Good.", "order": 10089, "speaker": "Michael Scott"},
  {"season": 3, "episode": 8, "scene": 67, "line": "Hey, girls. What's happening? Startinng to blend? Well, before long, you guys keep hanging out, you guys you are gonna share the same menstrual cycle.", "order": 10090, "speaker": "Michael Scott"},
  {"season": 3, "episode": 8, "scene": 68, "line": "[sniffs. Pans to Creed eating his mungbeans.]", "order": 10091, "speaker": "Martin"},
  {"season": 3, "episode": 8, "scene": 69, "line": "[pushing back desk item] Here you go.", "order": 10092, "speaker": "Hannah"},
  {"season": 3, "episode": 8, "scene": 69, "line": "That was in your way?", "order": 10093, "speaker": "Meredith Palmer"},
  {"season": 3, "episode": 8, "scene": 69, "line": "Um", "order": 10094, "speaker": "Hannah"},
  {"season": 3, "episode": 8, "scene": 69, "line": "Sorry.", "order": 10095, "speaker": "Meredith Palmer"},
  {"season": 3, "episode": 8, "scene": 69, "line": "Yeah, I mean my stuff's coming over, but umm... is this...is this yours? [holds up travel mug]", "order": 10096, "speaker": "Hannah"},
  {"season": 3, "episode": 8, "scene": 69, "line": "[takes mug] Yes.", "order": 10097, "speaker": "Meredith Palmer"},
  {"season": 3, "episode": 8, "scene": 70, "line": "Hey Kevin.", "order": 10098, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 8, "scene": 70, "line": "Yeah.", "order": 10099, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 8, "scene": 70, "line": "Have you lost weight?", "order": 10100, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 8, "scene": 70, "line": "Well... I... Hey. You've never met me before.", "order": 10101, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 8, "scene": 70, "line": "I know, but you just give off the vide of a guy who's getting thinner.", "order": 10102, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 8, "scene": 70, "line": "Well, thanks. I appreciate that.", "order": 10103, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 8, "scene": 70, "line": "You're welcome.", "order": 10104, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 8, "scene": 71, "line": "You know who I really like? That guy, Andy.", "order": 10105, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 8, "scene": 72, "line": "Can't we all get along? No, we can't. But here's the thing: yes, we can. Well, why not? Because of differeneces. Well, can we overcome these differences? No. Can we find each other and connect with each other in spite of these differences? No, we can't. But, we have to try and that is why I created the Integration Celebration.", "order": 10106, "speaker": "Michael Scott"},
  {"season": 3, "episode": 8, "scene": 73, "line": "I need somebody to pick a card. [Dwight raises hand] One of the new people, please? Tony? [Tony walks away]", "order": 10107, "speaker": "Michael Scott"},
  {"season": 3, "episode": 8, "scene": 73, "line": "[gets up] I'll do it.", "order": 10108, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 8, "scene": 73, "line": "All right. Good. Thank you. [Andy picks a card] Do not look at your card. Now, I've always said that to be a great salesman is to have the ability to connect with people right here [puts hand over heart]. So to be a great salesman is to be a king of hearts. Turn over your card.", "order": 10109, "speaker": "Michael Scott"},
  {"season": 3, "episode": 8, "scene": 73, "line": "[turns over card] King of hearts.", "order": 10110, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 8, "scene": 73, "line": "[starts clapping] Very good Michael. Really good. [Michael shushes Dwight]", "order": 10111, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 8, "scene": 73, "line": "So I think in order for us all to work together well we must come together as a family and then we can all be [starts to fan out the rest of his cards facing the group showing kings of hearts] a king of hearts.", "order": 10112, "speaker": "Michael Scott"},
  {"season": 3, "episode": 8, "scene": 73, "line": "[claps] That was fantastic.", "order": 10113, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 8, "scene": 73, "line": "[clapping] No, no, it's better than fantastic. It's super perfect.", "order": 10114, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 8, "scene": 73, "line": "Dwight, don't, don't be a suck up.", "order": 10115, "speaker": "Michael Scott"},
  {"season": 3, "episode": 8, "scene": 74, "line": "What I want all of you [points at Scranton branch] to do is approach one of the new people and tell them the one thing that you like most about them. Who wants to start? Who wants to give it a shot?", "order": 10116, "speaker": "Michael Scott"},
  {"season": 3, "episode": 8, "scene": 74, "line": "[raises hand] I want to.", "order": 10117, "speaker": "Meredith Palmer"},
  {"season": 3, "episode": 8, "scene": 74, "line": "Okay Meredith, let's give it a shot.", "order": 10118, "speaker": "Michael Scott"},
  {"season": 3, "episode": 8, "scene": 74, "line": "[to Martin] I love your complexion. It's like devil's food cake.", "order": 10119, "speaker": "Meredith Palmer"},
  {"season": 3, "episode": 8, "scene": 74, "line": "Thank you.", "order": 10120, "speaker": "Martin"},
  {"season": 3, "episode": 8, "scene": 74, "line": "[clapping] Beautiful. Very good, nice.", "order": 10121, "speaker": "Michael Scott"},
  {"season": 3, "episode": 8, "scene": 75, "line": "It just goes to show you, you play with fire and you are gonna singe your eyebrows. And they do not grow back the same way. [takes of glasses and points to right eyebrow]", "order": 10122, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 8, "scene": 76, "line": "It is inspiring to me to watch you navigate these murky waters.", "order": 10123, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 8, "scene": 76, "line": "Okay.", "order": 10124, "speaker": "Michael Scott"},
  {"season": 3, "episode": 8, "scene": 76, "line": "Bull... [bleep] [clears throat] Sorry.", "order": 10125, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 8, "scene": 76, "line": "We heard it.", "order": 10126, "speaker": "Michael Scott"},
  {"season": 3, "episode": 8, "scene": 76, "line": "Yeah.", "order": 10127, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 8, "scene": 76, "line": "We heard what you said. You didn't cover it at all.", "order": 10128, "speaker": "Michael Scott"},
  {"season": 3, "episode": 8, "scene": 76, "line": "I had a tickle in my throat.", "order": 10129, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 8, "scene": 76, "line": "[points at camera] They're gonna have... You can't say that, all right?", "order": 10130, "speaker": "Michael Scott"},
  {"season": 3, "episode": 8, "scene": 76, "line": "I can't say [clearinng throat noise]?", "order": 10131, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 8, "scene": 76, "line": "[clearing throat noise] Idiot. Dwight's an idiot! Did you catch that or did... did the cough cover it?", "order": 10132, "speaker": "Michael Scott"},
  {"season": 3, "episode": 8, "scene": 76, "line": "Sometimes it hurts Michael.", "order": 10133, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 8, "scene": 76, "line": "[cleaing throat] You're a jerk. Dwight's a jerk!", "order": 10134, "speaker": "Michael Scott"},
  {"season": 3, "episode": 9, "scene": 1, "line": "Ohhhh! She's absolutely adorable!", "order": 10135, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 9, "scene": 1, "line": "He.", "order": 10136, "speaker": "Hannah"},
  {"season": 3, "episode": 9, "scene": 1, "line": "...Oh, sorry. He's--he's dressed all in pink.", "order": 10137, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 9, "scene": 1, "line": "That's his favorite color.", "order": 10138, "speaker": "Hannah"},
  {"season": 3, "episode": 9, "scene": 1, "line": "...Oh. That's... fun for him.", "order": 10139, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 9, "scene": 1, "line": "Fantastic.", "order": 10140, "speaker": "Stanley Hudson"},
  {"season": 3, "episode": 9, "scene": 1, "line": "Ohhh, wow. Look at that. How cute.", "order": 10141, "speaker": "Michael Scott"},
  {"season": 3, "episode": 9, "scene": 1, "line": "Thank youuu.", "order": 10142, "speaker": "Hannah"},
  {"season": 3, "episode": 9, "scene": 1, "line": "Ohh. May I?", "order": 10143, "speaker": "Michael Scott"},
  {"season": 3, "episode": 9, "scene": 1, "line": "Uh, sure!", "order": 10144, "speaker": "Hannah"},
  {"season": 3, "episode": 9, "scene": 1, "line": "[climbs under desk] Hey, look at me, I'm a baby! I'm one of those babies from 'Look Who's Talking.' What am I thinking? [Laughs] Look at all those staplers! What's a stapler!? I don't even know, I'm a baby! Hey, Mom, I'm thirsty! I'm thirsty, Mama! I want some milk. And you know where milk comes from! Breasts.", "order": 10145, "speaker": "Michael Scott"},
  {"season": 3, "episode": 9, "scene": 2, "line": "Hey.", "order": 10146, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 9, "scene": 2, "line": "Hey.", "order": 10147, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 9, "scene": 2, "line": "...Almost done?", "order": 10148, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 9, "scene": 2, "line": "Just about... yup. Now.", "order": 10149, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 9, "scene": 3, "line": "Yes. I have started to see Karen. It's very new, and... not really ready to talk about it openly yet, just because, I think, once the word gets out there, it might affect the way people behave around us or... I dunno. Just, not yet.", "order": 10150, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 9, "scene": 4, "line": "Yeah, Jan, it um... looks like a check, piece of paper of some sort. Receipt. I don't know.", "order": 10151, "speaker": "Michael Scott"},
  {"season": 3, "episode": 9, "scene": 4, "line": "Jan, this is Angela Martin from accounting.", "order": 10152, "speaker": "Angela Martin"},
  {"season": 3, "episode": 9, "scene": 4, "line": "[over phone] Mmhmm.", "order": 10153, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 9, "scene": 4, "line": "Look, we have a rebate from... the Federal Work Opportunity Program and no one knows what that means.", "order": 10154, "speaker": "Angela Martin"},
  {"season": 3, "episode": 9, "scene": 4, "line": "We get that money for hiring an ex-convict.", "order": 10155, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 9, "scene": 4, "line": "I didn't hire an ex-convict. Unless they mean Toby. Convicted rapist. [Jan sighs] ... I'm just kidding.", "order": 10156, "speaker": "Michael Scott"},
  {"season": 3, "episode": 9, "scene": 4, "line": "When did the check come?", "order": 10157, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 9, "scene": 4, "line": "Last week.", "order": 10158, "speaker": "Angela Martin"},
  {"season": 3, "episode": 9, "scene": 4, "line": "Okay, that's when the branches merged, so Josh must have been taking advantage of this program. Smart move.", "order": 10159, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 9, "scene": 4, "line": "One of the Stamford people is a criminal?", "order": 10160, "speaker": "Angela Martin"},
  {"season": 3, "episode": 9, "scene": 4, "line": "Hey Jan, speaking of Stamford, Hannah brought in her baby.", "order": 10161, "speaker": "Michael Scott"},
  {"season": 3, "episode": 9, "scene": 4, "line": "Jan, which one of the new employees is a criminal?", "order": 10162, "speaker": "Angela Martin"},
  {"season": 3, "episode": 9, "scene": 4, "line": "Uh, reformed convict, and, uh, I'm not sure. Though hang on, let me email our HR, stay on the line.", "order": 10163, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 9, "scene": 4, "line": "[whispering] Who is it?", "order": 10164, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 9, "scene": 4, "line": "Hannah?", "order": 10165, "speaker": "Michael Scott"},
  {"season": 3, "episode": 9, "scene": 4, "line": "Hmm.", "order": 10166, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 9, "scene": 4, "line": "Hmm.", "order": 10167, "speaker": "Angela Martin"},
  {"season": 3, "episode": 9, "scene": 4, "line": "Andy.", "order": 10168, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 9, "scene": 4, "line": "Andy?", "order": 10169, "speaker": "Angela Martin"},
  {"season": 3, "episode": 9, "scene": 4, "line": "Hmm. Martin?", "order": 10170, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 9, "scene": 4, "line": "Kuhhhh... you are such a racist.", "order": 10171, "speaker": "Michael Scott"},
  {"season": 3, "episode": 9, "scene": 4, "line": "Wait, why am I a racist?", "order": 10172, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 9, "scene": 4, "line": "Because you think he's black.", "order": 10173, "speaker": "Michael Scott"},
  {"season": 3, "episode": 9, "scene": 4, "line": "He is black... right? And...", "order": 10174, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 9, "scene": 4, "line": "Stop it. Stop it right-stop it right now.", "order": 10175, "speaker": "Michael Scott"},
  {"season": 3, "episode": 9, "scene": 4, "line": "...Okay it's someone named Martin Nash.", "order": 10176, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 9, "scene": 4, "line": "Yeah!", "order": 10177, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 9, "scene": 4, "line": "Michael?", "order": 10178, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 9, "scene": 5, "line": "Why did the convict have to be a black guy? It is such a stereotype. I just wish that Josh had made a more progressive choice. Like a white guy... who went to prison for... polluting a black guy's lake.", "order": 10179, "speaker": "Michael Scott"},
  {"season": 3, "episode": 9, "scene": 6, "line": "I wonder what he did.", "order": 10180, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 9, "scene": 6, "line": "In our society, a black man can be arrested for almost anything. He was probably at a sporting event and... saw some people pushing each other, and he intervened.", "order": 10181, "speaker": "Michael Scott"},
  {"season": 3, "episode": 9, "scene": 6, "line": "Why would anyone go to jail for that?", "order": 10182, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 9, "scene": 6, "line": "Sssssso, what we need to do... is to forget about this whole Martin in prison thing. People will draw unfair conclusions about Martin and or black people.", "order": 10183, "speaker": "Michael Scott"},
  {"season": 3, "episode": 9, "scene": 6, "line": "Cool.", "order": 10184, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 9, "scene": 6, "line": "Okay. Angela?", "order": 10185, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 9, "scene": 6, "line": "Sure. Let's protect the convicts. At the expense of the general feeling of safety in the workplace. As a 90-pound female that sits in an ill-lit, rarely-visited corner of the office, naturally I agree with that.", "order": 10186, "speaker": "Angela Martin"},
  {"season": 3, "episode": 9, "scene": 6, "line": "Good. All right. [everyone leaves]", "order": 10187, "speaker": "Michael Scott"},
  {"season": 3, "episode": 9, "scene": 7, "line": "[picks up phone] Jim Halpert.", "order": 10188, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 9, "scene": 7, "line": "[over phone] I am so horny.", "order": 10189, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 9, "scene": 7, "line": "...Okay I can't... help you... with that.", "order": 10190, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 9, "scene": 7, "line": "Oh, I think you can, Big Tuna. Tell me about that Indian chick, Kelly. She seems pretty slutty. Good for a romp in the sack.", "order": 10191, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 9, "scene": 7, "line": "She is dating Ryan, I think.", "order": 10192, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 9, "scene": 7, "line": "Oh, and I care why?", "order": 10193, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 9, "scene": 7, "line": "She's... high-maintenance.", "order": 10194, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 9, "scene": 7, "line": "Next. How about... [motions toward Angela]. Blondes are more fun. C'mon, trust me on that.", "order": 10195, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 9, "scene": 7, "line": "Yeah, trust me, that would be fun for no one.", "order": 10196, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 9, "scene": 7, "line": "Okay fine. Um. Pam, the receptionist. Paaam. Should I go for it?", "order": 10197, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 9, "scene": 7, "line": "...Absolutely you should.", "order": 10198, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 9, "scene": 7, "line": "Jackpot.", "order": 10199, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 9, "scene": 8, "line": "Just... try to be cool.", "order": 10200, "speaker": "Michael Scott"},
  {"season": 3, "episode": 9, "scene": 8, "line": "I am cool.", "order": 10201, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 9, "scene": 8, "line": "Okay, are you cool, really?", "order": 10202, "speaker": "Michael Scott"},
  {"season": 3, "episode": 9, "scene": 8, "line": "I'm cool, I'm cool, I'm so cool. Tell me what is going on.", "order": 10203, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 9, "scene": 8, "line": "Um... Martin, from Stamford, was, at one time, in prison. [Dwight starts to run] No. Dwight! Be cool! Be cool!", "order": 10204, "speaker": "Michael Scott"},
  {"season": 3, "episode": 9, "scene": 9, "line": "I am greatly concerned about having a convict in the office. And I do not care if that convict is white, black, Asian, German, or some kind of halfsy. I do not like criminals.", "order": 10205, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 9, "scene": 10, "line": "All... righty, let's get started. What is she into?", "order": 10206, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 9, "scene": 11, "line": "I know Pam pretty well. I know the things that she likes, and, just as important, I know the things that she hates. So, one of the things that she likes is pranks. And, the things that she hates...", "order": 10207, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 9, "scene": 12, "line": "Frisbee-based competitions...", "order": 10208, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 9, "scene": 12, "line": "Are you kidding?", "order": 10209, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 9, "scene": 12, "line": "She...", "order": 10210, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 9, "scene": 12, "line": "I started the main Frisbee golf club at Cornell. Where I went to college. I live to frolf.", "order": 10211, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 9, "scene": 12, "line": "Lead off with that. She loves hunting. She also loves those ads for Six Flags, with the old guy. [Andy hums Six Flag ad] Got it. Also... do you speak pig latin?", "order": 10212, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 9, "scene": 13, "line": "Hey Martin, how's it going?", "order": 10213, "speaker": "Michael Scott"},
  {"season": 3, "episode": 9, "scene": 13, "line": "Good. Getting settled, you know?", "order": 10214, "speaker": "Martin"},
  {"season": 3, "episode": 9, "scene": 13, "line": "Ah. Good good. Good. Just a second. Everybody? May I have your attention please? I realize that a lot of you have already heard that Martin here has had some trouble with the law, but I just want to declare publicly that I... trust... him, completely, and that anybody who doesn't is an ignorant, dumb... person. Okay? As a matter of fact, you show me a white man you trust and I will show you a black man that I trust even more. Pam. Tell me a white person you trust.", "order": 10215, "speaker": "Michael Scott"},
  {"season": 3, "episode": 9, "scene": 13, "line": "My dad.", "order": 10216, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 9, "scene": 13, "line": "...Danny Glover. [Jim raises hand] Yeah.", "order": 10217, "speaker": "Michael Scott"},
  {"season": 3, "episode": 9, "scene": 13, "line": "Jonas Salk.", "order": 10218, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 9, "scene": 13, "line": "Who?", "order": 10219, "speaker": "Michael Scott"},
  {"season": 3, "episode": 9, "scene": 13, "line": "Justin Timberlake?", "order": 10220, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 9, "scene": 13, "line": "Oh. Please. Colin Powell.", "order": 10221, "speaker": "Michael Scott"},
  {"season": 3, "episode": 9, "scene": 13, "line": "Hey I got one.", "order": 10222, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 9, "scene": 13, "line": "Yup.", "order": 10223, "speaker": "Michael Scott"},
  {"season": 3, "episode": 9, "scene": 13, "line": "Jesus.", "order": 10224, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 9, "scene": 13, "line": "Apollo Creed.", "order": 10225, "speaker": "Michael Scott"},
  {"season": 3, "episode": 9, "scene": 14, "line": "Close your eyes. Picture a convict. What's he wearing? Nothing special, baseball cap on backwards, baggy pants... he says something ordinary like... 'yo, thats shizzle.' Okay. Now slowly open your eyes again. Who are you picturing? A black man? Wrong. That was a white woman. Surprised? Well, shame on you.", "order": 10226, "speaker": "Michael Scott"},
  {"season": 3, "episode": 9, "scene": 15, "line": "So... you all wanna know what I was in for?", "order": 10227, "speaker": "Martin"},
  {"season": 3, "episode": 9, "scene": 15, "line": "No. That's not cool. You don't have to tell them.", "order": 10228, "speaker": "Michael Scott"},
  {"season": 3, "episode": 9, "scene": 15, "line": "Um, I really don't mind. It was a stupid mistake. I was working in finance and, I... got involved in some insider trading. So, I spent a little time in the clink.", "order": 10229, "speaker": "Martin"},
  {"season": 3, "episode": 9, "scene": 15, "line": "[laughs] That is awesome.", "order": 10230, "speaker": "Michael Scott"},
  {"season": 3, "episode": 9, "scene": 16, "line": "I had Martin explain to me three times what he got arrested for, because... it sounds an awful lot like what I do here every day.", "order": 10231, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 9, "scene": 17, "line": "What was prison like?", "order": 10232, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 9, "scene": 17, "line": "Not terrible. Boring. We do the same thing every day. But... at least we got outdoors time.", "order": 10233, "speaker": "Martin"},
  {"season": 3, "episode": 9, "scene": 17, "line": "You got outdoors time?", "order": 10234, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 9, "scene": 17, "line": "Two hours, every day. Sometimes we'd play pickup football games...", "order": 10235, "speaker": "Martin"},
  {"season": 3, "episode": 9, "scene": 17, "line": "Michael, why don't we get outdoors time?", "order": 10236, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 9, "scene": 17, "line": "Yeah, some days I never go outside.", "order": 10237, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 9, "scene": 17, "line": "Well, we are running a business, so.", "order": 10238, "speaker": "Michael Scott"},
  {"season": 3, "episode": 9, "scene": 17, "line": "What was your cell like?", "order": 10239, "speaker": "Meredith Palmer"},
  {"season": 3, "episode": 9, "scene": 17, "line": "Not good. Uh... a little bit bigger than Michael's office... but, you know, I really only slept there. You know, during the day, there-our time's our own. They had uh, classes, I took some... watercolor classes.", "order": 10240, "speaker": "Martin"},
  {"season": 3, "episode": 9, "scene": 17, "line": "They have art classes?", "order": 10241, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 9, "scene": 17, "line": "Yeap. Yeah.", "order": 10242, "speaker": "Martin"},
  {"season": 3, "episode": 9, "scene": 17, "line": "They have business classes there?", "order": 10243, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 9, "scene": 17, "line": "They did, taught by some uh, Harvard business school guys--a lot of the guys also, that were in the class, the inmates, a lot of them have gone on to do extraordinary things in business.", "order": 10244, "speaker": "Martin"},
  {"season": 3, "episode": 9, "scene": 17, "line": "Kinda sounds like... prison's... better than Dunder-Mifflin.", "order": 10245, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 9, "scene": 17, "line": "Ah. Well. That's not true.", "order": 10246, "speaker": "Michael Scott"},
  {"season": 3, "episode": 9, "scene": 17, "line": "I would so rather be in prison.", "order": 10247, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 9, "scene": 17, "line": "Prison sounds great.", "order": 10248, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 9, "scene": 17, "line": "No you would not.", "order": 10249, "speaker": "Michael Scott"},
  {"season": 3, "episode": 9, "scene": 18, "line": "This place is not prison. It's... way better than prison.", "order": 10250, "speaker": "Michael Scott"},
  {"season": 3, "episode": 9, "scene": 19, "line": "[singsong voice] Baby. Hello baby. Here you want to play with this?", "order": 10251, "speaker": "Creed Bratton"},
  {"season": 3, "episode": 9, "scene": 19, "line": "You can't give paperclips to a baby. He could swallow.", "order": 10252, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 9, "scene": 19, "line": "Oh, it's okay. I've got tons of them. You like that? Goo-goo-goo-goo?", "order": 10253, "speaker": "Creed Bratton"},
  {"season": 3, "episode": 9, "scene": 20, "line": "Pam-a-lama-ding-dong. Listen, you're cute. There is no gettin' around it. So... I don't know if you like country music, but I was thinking maybe one of these days we could drive out to a field, crank up some tunes, smoke a few Macanudos... maybe even toss a disk around. Utway ooday ooyay inkthay, Ampay?", "order": 10254, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 9, "scene": 20, "line": "Wow... I--", "order": 10255, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 9, "scene": 20, "line": "Shh. Think about it. I'll hit you back.", "order": 10256, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 9, "scene": 21, "line": "Wow. That was... wow.", "order": 10257, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 9, "scene": 22, "line": "Okay! Listen up everybody! Um, you guys said that prison was better than this place, and I heard ya, loud and clear, so, I am instituting some changes to make this more like prison. We are going to start with an hour of outdoor time. So let's go!", "order": 10258, "speaker": "Michael Scott"},
  {"season": 3, "episode": 9, "scene": 23, "line": "Michael, it's freezing out.", "order": 10259, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 9, "scene": 23, "line": "I can't feel my toes.", "order": 10260, "speaker": "Phyllis Lapin"},
  {"season": 3, "episode": 9, "scene": 23, "line": "Why don't we... pump some iron? Anyone wanna... pump up?", "order": 10261, "speaker": "Michael Scott"},
  {"season": 3, "episode": 9, "scene": 23, "line": "What is that, like... five pounds?", "order": 10262, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 9, "scene": 23, "line": "It's uh... two and a half. I'm not going for bulk, I'm going for tone.", "order": 10263, "speaker": "Michael Scott"},
  {"season": 3, "episode": 9, "scene": 23, "line": "I'm going back inside.", "order": 10264, "speaker": "Stanley Hudson"},
  {"season": 3, "episode": 9, "scene": 23, "line": "Yeah, it's... freaking cold out here. Anybody wants to stay out, you've got about... twenty-seven minutes of rec time.", "order": 10265, "speaker": "Michael Scott"},
  {"season": 3, "episode": 9, "scene": 24, "line": "Oh heyyy. Martiiin. You have TV in the joint?", "order": 10266, "speaker": "Michael Scott"},
  {"season": 3, "episode": 9, "scene": 24, "line": "Yeah, in the rec room.", "order": 10267, "speaker": "Martin"},
  {"season": 3, "episode": 9, "scene": 24, "line": "Ah. A ten inch black and white?", "order": 10268, "speaker": "Michael Scott"},
  {"season": 3, "episode": 9, "scene": 24, "line": "Actually, our TV was bigger than that one.", "order": 10269, "speaker": "Martin"},
  {"season": 3, "episode": 9, "scene": 25, "line": "These people don't realize how lucky they are. This office is the American Dream. And they would rather be in the hole.", "order": 10270, "speaker": "Michael Scott"},
  {"season": 3, "episode": 9, "scene": 26, "line": "Oh. Andy. I thought of one last tact you can take with Pam.", "order": 10271, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 9, "scene": 26, "line": "Yeah.", "order": 10272, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 9, "scene": 26, "line": "Quick question - do you play the guitar?", "order": 10273, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 9, "scene": 26, "line": "I play the banjo.", "order": 10274, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 9, "scene": 26, "line": "Hold on, let me think about that, yes, that'll work. But can you sing in a sexy high falsetto voice?", "order": 10275, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 9, "scene": 26, "line": "[singing] You know I can, my man.", "order": 10276, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 9, "scene": 26, "line": "Yup. That's perfect.", "order": 10277, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 9, "scene": 26, "line": "Hey.", "order": 10278, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 9, "scene": 26, "line": "Hey.", "order": 10279, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 9, "scene": 26, "line": "I'm gonna go get my banjo out of my car.", "order": 10280, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 9, "scene": 26, "line": "Perfect.", "order": 10281, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 9, "scene": 26, "line": "What is going on?", "order": 10282, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 9, "scene": 26, "line": "...I'm messing with Andy. I'm sending him to all the women in the office with... just... terrible information on how to get them to go out with him.", "order": 10283, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 9, "scene": 26, "line": "I love it. I want in. Who's the target?", "order": 10284, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 9, "scene": 26, "line": "...Oh, you know what? ... It was... gonna be Pam, but...", "order": 10285, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 9, "scene": 26, "line": "Perfect. What do I do? Just give me an assignment.", "order": 10286, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 9, "scene": 26, "line": "Uh, you know what though? I feel like I already sicked him on Pam... we'll give her a break. Let's think of someone else.", "order": 10287, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 9, "scene": 27, "line": "All right everybody, there has been a lot of name calling against our office today. Corporate maligning, slurring, much of it coming from one of you, who claims that prison is better... than... here. And none of can say 'Boo' because none of us have ever been to prison. Well, there's somebody I'd like you to meet. Somebody else who has been to prison, who can tell you what it is really like [puts on bandanna]. I'm prison Mike! You know why they call me prison Mike?!", "order": 10288, "speaker": "Michael Scott"},
  {"season": 3, "episode": 9, "scene": 27, "line": "Do you really expect us to believe you're somebody else?", "order": 10289, "speaker": "Angela Martin"},
  {"season": 3, "episode": 9, "scene": 27, "line": "Do you really expect me to not push you up against the wall, beeyotch?! [employees protest] All right, hey, hey, hey, hey, that's just the way we talk in the clink. Been a lot of fun talk about prison today, but I am here to scare you straight. I AM HERE TO SCARE YOU STRAIIIGHT!! In prison you are somebody's bitch. Oh, and you. [points to Ryan] You, my friend, would be da belle of da ball. Don't drop the soap! Don't drop the soap!", "order": 10290, "speaker": "Michael Scott"},
  {"season": 3, "episode": 9, "scene": 27, "line": "Michael, please. [Michael makes kissing noises]", "order": 10291, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 9, "scene": 27, "line": "Where... did you learn all of this?", "order": 10292, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 9, "scene": 27, "line": "Internet.", "order": 10293, "speaker": "Michael Scott"},
  {"season": 3, "episode": 9, "scene": 27, "line": "So, not prison.", "order": 10294, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 9, "scene": 27, "line": "And prison. Eh, fifty-fifty, both. Look, prison stinks, is what I'm saying. It's not like you can go home, and, recharge your batteries, and come back in the morning and, be with your friends, having fun in the office.", "order": 10295, "speaker": "Michael Scott"},
  {"season": 3, "episode": 9, "scene": 27, "line": "What'd you do, Prison Mike?", "order": 10296, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 9, "scene": 27, "line": "I stole. ... And I robbed. And I kidnapped... the... president's son. And held him for ransom.", "order": 10297, "speaker": "Michael Scott"},
  {"season": 3, "episode": 9, "scene": 27, "line": "That is... quite the rap sheet, Prison Mike.", "order": 10298, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 9, "scene": 27, "line": "And I nevah got caught, neither.", "order": 10299, "speaker": "Michael Scott"},
  {"season": 3, "episode": 9, "scene": 27, "line": "Well, you're... in... prison, but, mmhmm.", "order": 10300, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 9, "scene": 27, "line": "Prison Mike? What was the food like in prison?", "order": 10301, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 9, "scene": 27, "line": "Gruel. Sandwiches. Gruel omelettes. Nothing but gruel. Plus, you can eat your own hair.", "order": 10302, "speaker": "Michael Scott"},
  {"season": 3, "episode": 9, "scene": 27, "line": "Wow. Prison sounds horrible.", "order": 10303, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 9, "scene": 27, "line": "Yeah. Yeah. Well, thank you, Andy. Tanks.", "order": 10304, "speaker": "Michael Scott"},
  {"season": 3, "episode": 9, "scene": 27, "line": "Prison Mike. What's the very very worst thing about prison?", "order": 10305, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 9, "scene": 27, "line": "Don't encourage him, Dwight.", "order": 10306, "speaker": "Angela Martin"},
  {"season": 3, "episode": 9, "scene": 27, "line": "The worst thing about prison was the... was the Dementors. They... were flying all over the place, and they were scary. And they'd come down, and they'd suck the soul out of your body, and it hurt!", "order": 10307, "speaker": "Michael Scott"},
  {"season": 3, "episode": 9, "scene": 27, "line": "Demen-Dementors like in Harry Potter?", "order": 10308, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 9, "scene": 27, "line": "No, not Harry Potter. ... There are no movies in prison. This is my point! You guys got it soft, and cushy! This place is freaking awesome! The people are awesome! Your boss is nice! Everyone seems to get along! People are tolerant! People who... have jumped to conclusions can redeem themselves! Nobody is nobody's bitch. I hope that this scared you. And from me, Prison Mike, to you, I just wanna thank you for listening to me. Letting me be a part of your life today. 'Cause you got a good life! YOu got a good life. A good life. [turns around, takes bandanna off] So. What do you think? It doesn't sound so great, does it?", "order": 10309, "speaker": "Michael Scott"},
  {"season": 3, "episode": 9, "scene": 27, "line": "Wow. Thank you. Um, that must have been hard for you to relive that. Both of you.", "order": 10310, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 9, "scene": 27, "line": "Yeah, that... wasn't really... at all my experience. There were certain elements of what you performed, I've seen on television. But it didn't remind me of my time in prison.", "order": 10311, "speaker": "Martin"},
  {"season": 3, "episode": 9, "scene": 27, "line": "Okay. Okay, fine. You guys think prison is so great? All right. Well, here you go. [locks employees in conference room]", "order": 10312, "speaker": "Michael Scott"},
  {"season": 3, "episode": 9, "scene": 27, "line": "Okay, Michael. Come on. Let us out.", "order": 10313, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 9, "scene": 27, "line": "No! If you think is prison is so wonderful, then, enjoy prison! They are such babies. I am going to leave them in there until they can appreciate what it's like to have freedom. And if this doesn't bother them, then I am out of ideas.", "order": 10314, "speaker": "Michael Scott"},
  {"season": 3, "episode": 9, "scene": 28, "line": "[knocking on door] Hey! Let us out of here! Hey! I have--", "order": 10315, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 9, "scene": 28, "line": "Shh!", "order": 10316, "speaker": "Hannah"},
  {"season": 3, "episode": 9, "scene": 29, "line": "[answering phone] This is Toby. Hey Pam. Where are you calling from?", "order": 10317, "speaker": "Toby Flenderson"},
  {"season": 3, "episode": 9, "scene": 30, "line": "Michael. Why's everyone locked in the conference room?", "order": 10318, "speaker": "Toby Flenderson"},
  {"season": 3, "episode": 9, "scene": 30, "line": "They were very disrespectful to me, and to the office. And Martin has had a bad influence, to think that I gave him the benefit of the doubt.", "order": 10319, "speaker": "Michael Scott"},
  {"season": 3, "episode": 9, "scene": 30, "line": "Well, you're going to have to let 'em out. Or... or I will.", "order": 10320, "speaker": "Toby Flenderson"},
  {"season": 3, "episode": 9, "scene": 30, "line": "Okay. You know what Toby? I am teaching them a lesson, so.", "order": 10321, "speaker": "Michael Scott"},
  {"season": 3, "episode": 9, "scene": 30, "line": "You know they're teasing you. I mean... obviously, this is... a much nicer place than an actual prison. We get paid to be here. We go home afterwards and have social lives. We have... we have parties here. They're teasing you. To be funny.", "order": 10322, "speaker": "Toby Flenderson"},
  {"season": 3, "episode": 9, "scene": 30, "line": "[unlocks door] Okay nutcases, get out of there! Good work. Long day. Really long. Why don't you guys head home. Early. Time off for good behavior! Heh. Good job. Enjoy your freedoms!", "order": 10323, "speaker": "Michael Scott"},
  {"season": 3, "episode": 9, "scene": 31, "line": "Martin went from being a new guy from Stamford... to a convict, to... my friend. Back to a convict. Then to... a kind of a nuisance, actually, to be completely honest. And finally, to... a quitter. And I will not miss him. And that is not because he is black.", "order": 10324, "speaker": "Michael Scott"},
  {"season": 3, "episode": 9, "scene": 32, "line": "[singing] So we've been told, and some choose to believe it, I know they're wrong, wait and see, 'cause one day we'll find it, the ainbowray onnectionkay, the lovers, the dreamers, and meeee...", "order": 10325, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 10, "scene": 1, "line": "Merry Christmas! [holds up dead goose and puts it on Pam's desk]", "order": 10326, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 10, "scene": 1, "line": "Merry Christ--[looks up] NO! Why... why did you bring that here?", "order": 10327, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 10, "scene": 1, "line": "Don't worry, she's dead. Oh wait. He's dead.", "order": 10328, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 10, "scene": 1, "line": "Dwight, what uh...", "order": 10329, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 10, "scene": 1, "line": "I accidentally ran over it. It's a Christmas miracle!", "order": 10330, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 10, "scene": 1, "line": "Well, get it out of here.", "order": 10331, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 10, "scene": 1, "line": "Relax, OK. And because this is Christmas, I am going to roast this goose and prepare it with a wild rice dressing. Do we have any cayenne pepper in the kitchen?", "order": 10332, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 10, "scene": 1, "line": "Merry Christmas Dwight.", "order": 10333, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 10, "scene": 1, "line": "Jim.", "order": 10334, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 10, "scene": 1, "line": "Wow. What have we got here?", "order": 10335, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 10, "scene": 1, "line": "What does it look like?", "order": 10336, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 10, "scene": 1, "line": "Dead goose.", "order": 10337, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 10, "scene": 1, "line": "And circle gets the square.", "order": 10338, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 10, "scene": 1, "line": "All right.", "order": 10339, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 10, "scene": 1, "line": "[to Pam] So can you watch this? I'm gonna get my carving knife out of the trunk.", "order": 10340, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 10, "scene": 1, "line": "Oh, Dwight, we talked about this.", "order": 10341, "speaker": "Toby Flenderson"},
  {"season": 3, "episode": 10, "scene": 1, "line": "No, Toby, this is different. He's already dead.", "order": 10342, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 10, "scene": 2, "line": "Once I brought in a duck. To prepare for lunch. And people got upset. Apparently, they got attached to the duck and didn't want to see it killed.", "order": 10343, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 10, "scene": 3, "line": "He was already dead. And we Schrutes use every part of the goose. The meat has a delicious, smoky, rich flavor. Plus you can use the molten goose grease and save it in the refrigerator, thus saving you a trip to the store for a can of expensive goose grease.", "order": 10344, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 10, "scene": 3, "line": "Wow. Win-Win.", "order": 10345, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 10, "scene": 3, "line": "Exactly. Thank you, Jim.", "order": 10346, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 10, "scene": 3, "line": "I like goose. If it's already dead is it so crazy we eat it?", "order": 10347, "speaker": "Phyllis Lapin"},
  {"season": 3, "episode": 10, "scene": 3, "line": "That's crazy. It's crazy.", "order": 10348, "speaker": "Creed Bratton"},
  {"season": 3, "episode": 10, "scene": 3, "line": "Dwight, you cannot keep that here.", "order": 10349, "speaker": "Toby Flenderson"},
  {"season": 3, "episode": 10, "scene": 3, "line": "Ok, that is ridiculous. And totally against the spirit of Christmas.", "order": 10350, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 10, "scene": 3, "line": "Come on Dwight. We went over this, like for a half an hour.", "order": 10351, "speaker": "Toby Flenderson"},
  {"season": 3, "episode": 10, "scene": 3, "line": "It's Christmas Toby.", "order": 10352, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 10, "scene": 3, "line": "It's a dead animal in an office. You can't...", "order": 10353, "speaker": "Toby Flenderson"},
  {"season": 3, "episode": 10, "scene": 3, "line": "Toby...", "order": 10354, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 10, "scene": 3, "line": "I'm sorry.", "order": 10355, "speaker": "Toby Flenderson"},
  {"season": 3, "episode": 10, "scene": 3, "line": "[sighs] Please? Please?", "order": 10356, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 10, "scene": 3, "line": "Clean it in your car.", "order": 10357, "speaker": "Toby Flenderson"},
  {"season": 3, "episode": 10, "scene": 3, "line": "I would like it off my desk.", "order": 10358, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 10, "scene": 3, "line": "Oh Pam. Take a chill pill.", "order": 10359, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 10, "scene": 4, "line": "[Riding a bicycle down the hallway and singing] Deck the halls with boughs of holly. Fa la la la la la la la la. [Struggling to stay on] 'Tis the ... ack. [expletive] Hey! [to Pam] I would like a nice slice of Christmas Pam. Side of candied Pams. And perhaps, some Pam-chops, with mint...", "order": 10360, "speaker": "Michael Scott"},
  {"season": 3, "episode": 10, "scene": 4, "line": "Can I help you, Michael?", "order": 10361, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 10, "scene": 4, "line": "I'm looking for the toy drive box.", "order": 10362, "speaker": "Michael Scott"},
  {"season": 3, "episode": 10, "scene": 4, "line": "It's behind you.", "order": 10363, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 10, "scene": 4, "line": "OK... Well, I need to put this bike in there. I hope it'll fit, with all these little knickknacks.", "order": 10364, "speaker": "Michael Scott"},
  {"season": 3, "episode": 10, "scene": 4, "line": "Wow. What kind of bike is that?", "order": 10365, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 10, "scene": 4, "line": "Umm... I don't know? Average kind?", "order": 10366, "speaker": "Michael Scott"},
  {"season": 3, "episode": 10, "scene": 4, "line": "The tires look pretty worn.", "order": 10367, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 10, "scene": 4, "line": "Well, that is probably from the test drive.", "order": 10368, "speaker": "Michael Scott"},
  {"season": 3, "episode": 10, "scene": 4, "line": "But the paint's chipping. Is that your old bike, Michael?", "order": 10369, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 10, "scene": 4, "line": "No.", "order": 10370, "speaker": "Michael Scott"},
  {"season": 3, "episode": 10, "scene": 4, "line": "Yo, Michael, sweet ride Mike.", "order": 10371, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 10, "scene": 4, "line": "Oh, thanks. [Michael and Andy do a fist bump and explosion.]", "order": 10372, "speaker": "Michael Scott"},
  {"season": 3, "episode": 10, "scene": 4, "line": "Michael?", "order": 10373, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 10, "scene": 4, "line": "Yes? [Pam holds up a bag] Oh Pam, that is so sweet. You didn't have to do that.", "order": 10374, "speaker": "Michael Scott"},
  {"season": 3, "episode": 10, "scene": 4, "line": "[hands over bag] I didn't, it's from corporate.", "order": 10375, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 10, "scene": 4, "line": "OK. Did everybody get one of these?", "order": 10376, "speaker": "Michael Scott"},
  {"season": 3, "episode": 10, "scene": 4, "line": "Yup.", "order": 10377, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 10, "scene": 4, "line": "Terrific. Good.", "order": 10378, "speaker": "Michael Scott"},
  {"season": 3, "episode": 10, "scene": 5, "line": "This is going to be the best Christmas ever. My girlfriend Carol is coming to our party tonight, and I have a little surprise for her. [singing] I've got two tickets to paradise! Pack your bags, we're leaving the day-after-tomorrow! Um, taking her to Sandals, Jamaica, all-inclusive. All-inclusive. You know what that means? Right? Yeah.", "order": 10379, "speaker": "Michael Scott"},
  {"season": 3, "episode": 10, "scene": 6, "line": "[Creed takes toy from toy drive box.] Oh, I think you're supposed to put a toy", "order": 10380, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 10, "scene": 6, "line": "And a happy holiday to you.", "order": 10381, "speaker": "Creed Bratton"},
  {"season": 3, "episode": 10, "scene": 7, "line": "[Carol walks through door looking upset] [waves] Carol.", "order": 10382, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 10, "scene": 7, "line": "Hi, is Michael around here?", "order": 10383, "speaker": "Carol Stills"},
  {"season": 3, "episode": 10, "scene": 7, "line": "There she is. A Christmas Carol. Hello [kisses Carol] You're about five hours early to the party. You're such a blonde. [laughs]", "order": 10384, "speaker": "Michael Scott"},
  {"season": 3, "episode": 10, "scene": 7, "line": "Michael.", "order": 10385, "speaker": "Carol Stills"},
  {"season": 3, "episode": 10, "scene": 7, "line": "Hey, everybody. I don't know who you haven't met yet, but I think this is one of them. This is my girlfriend - Carol. This is just the front of her. Show 'em, show 'em the other side.", "order": 10386, "speaker": "Michael Scott"},
  {"season": 3, "episode": 10, "scene": 7, "line": "[whispers] What?", "order": 10387, "speaker": "Carol Stills"},
  {"season": 3, "episode": 10, "scene": 7, "line": "Turn around, turn around. Come on. [making a body turning gesture]", "order": 10388, "speaker": "Michael Scott"},
  {"season": 3, "episode": 10, "scene": 7, "line": "Get outta here.", "order": 10389, "speaker": "Carol Stills"},
  {"season": 3, "episode": 10, "scene": 7, "line": "No, you get outta here.", "order": 10390, "speaker": "Michael Scott"},
  {"season": 3, "episode": 10, "scene": 7, "line": "Michael", "order": 10391, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 10, "scene": 7, "line": "Yes.", "order": 10392, "speaker": "Michael Scott"},
  {"season": 3, "episode": 10, "scene": 7, "line": "If I may say. She's even prettier that you described her.", "order": 10393, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 10, "scene": 7, "line": "[standing up] Oh, ouch. Michael, I don't think there is anything wrong with the way you described her.", "order": 10394, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 10, "scene": 7, "line": "Thank you.", "order": 10395, "speaker": "Michael Scott"},
  {"season": 3, "episode": 10, "scene": 7, "line": "I really need a moment alone with you. [Carol heads towards Michael's office.]", "order": 10396, "speaker": "Carol Stills"},
  {"season": 3, "episode": 10, "scene": 7, "line": "Not as much as I need a moment alone with you. [puts finger on her back and reaches for her butt] Berp.", "order": 10397, "speaker": "Michael Scott"},
  {"season": 3, "episode": 10, "scene": 8, "line": "What is this? [Holds up a Christmas photo]", "order": 10398, "speaker": "Carol Stills"},
  {"season": 3, "episode": 10, "scene": 8, "line": "That is my Christmas card. It's a picture of you and me and your kids on a ski trip, having a blast. Ski-sons Greetings.", "order": 10399, "speaker": "Michael Scott"},
  {"season": 3, "episode": 10, "scene": 8, "line": "No. See, we never went on a ski trip.", "order": 10400, "speaker": "Carol Stills"},
  {"season": 3, "episode": 10, "scene": 8, "line": "I know.", "order": 10401, "speaker": "Michael Scott"},
  {"season": 3, "episode": 10, "scene": 8, "line": "I went on a ski trip.", "order": 10402, "speaker": "Carol Stills"},
  {"season": 3, "episode": 10, "scene": 8, "line": "Right.", "order": 10403, "speaker": "Michael Scott"},
  {"season": 3, "episode": 10, "scene": 8, "line": "Two years ago with my kids and my ex-husband.", "order": 10404, "speaker": "Carol Stills"},
  {"season": 3, "episode": 10, "scene": 8, "line": "Yes, but what you didn't realize at the time was that I was with you in a sense. I was in your heart...", "order": 10405, "speaker": "Michael Scott"},
  {"season": 3, "episode": 10, "scene": 8, "line": "Michael.", "order": 10406, "speaker": "Carol Stills"},
  {"season": 3, "episode": 10, "scene": 8, "line": "And next to your kids. What?", "order": 10407, "speaker": "Michael Scott"},
  {"season": 3, "episode": 10, "scene": 8, "line": "This is so weird.", "order": 10408, "speaker": "Carol Stills"},
  {"season": 3, "episode": 10, "scene": 8, "line": "I don't understand?", "order": 10409, "speaker": "Michael Scott"},
  {"season": 3, "episode": 10, "scene": 9, "line": "It's a bold move, to Photoshop yourself into a picture with your girlfriend and her kids on a ski trip with their real father. But then again, Michael's a bold guy. [Towards unseen cameraman] Is bold the right word?", "order": 10410, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 10, "scene": 10, "line": "I think you're a really sweet guy.", "order": 10411, "speaker": "Carol Stills"},
  {"season": 3, "episode": 10, "scene": 10, "line": "OK", "order": 10412, "speaker": "Michael Scott"},
  {"season": 3, "episode": 10, "scene": 10, "line": "But, um, I don't know how to deal with, with this thing [waves Christmas card]. And, and the proposal. And I don't think things are going to work out with us.", "order": 10413, "speaker": "Carol Stills"},
  {"season": 3, "episode": 10, "scene": 10, "line": "No, no no no Ok, OK, You know what, you're not thinking straight. You know what you need? You need to think this through in [making drum noise] Jamaica's largest fresh water pool.", "order": 10414, "speaker": "Michael Scott"},
  {"season": 3, "episode": 10, "scene": 10, "line": "What are you talking about?", "order": 10415, "speaker": "Carol Stills"},
  {"season": 3, "episode": 10, "scene": 10, "line": "I got us tickets to Sandals, Jamaica, We leave day after tomorrow. [Carol shakes head in disappointment]. You better find the skimpiest bikini there is.", "order": 10416, "speaker": "Michael Scott"},
  {"season": 3, "episode": 10, "scene": 10, "line": "Oh, no. Michael.", "order": 10417, "speaker": "Carol Stills"},
  {"season": 3, "episode": 10, "scene": 10, "line": "And it's all inclusive.", "order": 10418, "speaker": "Michael Scott"},
  {"season": 3, "episode": 10, "scene": 10, "line": "Michael...", "order": 10419, "speaker": "Carol Stills"},
  {"season": 3, "episode": 10, "scene": 10, "line": "Yes.", "order": 10420, "speaker": "Michael Scott"},
  {"season": 3, "episode": 10, "scene": 10, "line": "I'm sorry.", "order": 10421, "speaker": "Carol Stills"},
  {"season": 3, "episode": 10, "scene": 10, "line": "No, Carol. You walk out that door and it is over.", "order": 10422, "speaker": "Michael Scott"},
  {"season": 3, "episode": 10, "scene": 10, "line": "I know. [Closes door.]", "order": 10423, "speaker": "Carol Stills"},
  {"season": 3, "episode": 10, "scene": 11, "line": "Psst. Jim. [Jim turns around. Pam nods head towards her for him to come over] Um, hey. I need to give you your Christmas gift now because, well, I'll just tell you.", "order": 10424, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 10, "scene": 11, "line": "What?", "order": 10425, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 10, "scene": 11, "line": "For the past few months I've been sending Dwight letters from the CIA. [hands a folder to Jim]", "order": 10426, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 10, "scene": 11, "line": "Are you serious?", "order": 10427, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 10, "scene": 11, "line": "They're considering him for a top secret mission. There's his application. Oh, and this is where I made him list every secret he promised he'd never ever tell.", "order": 10428, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 10, "scene": 11, "line": "[Opens folder and reads from Dwight's file] Last year, my boss, Michael Scott, took a day off because he said he had pneumonia, but really, he was leaving early to go to magic camp. Wow.", "order": 10429, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 10, "scene": 11, "line": "So... here's the gift. You get to decide what his top secret mission is. [Jim smiles] Sorry I didn't wrap it.", "order": 10430, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 10, "scene": 11, "line": "[closes folder] You know what? Uh, I really don't think I should be doing this stuff anymore, you know?", "order": 10431, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 10, "scene": 11, "line": "Oh.", "order": 10432, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 10, "scene": 11, "line": "No, because of the promotion.", "order": 10433, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 10, "scene": 11, "line": "Oh yeah.", "order": 10434, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 10, "scene": 11, "line": "It just feels a little bit, like...", "order": 10435, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 10, "scene": 11, "line": "No, I get it, of course. OK. [holds out hand for folder. Jim gives Pam back the folder.]", "order": 10436, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 10, "scene": 12, "line": "I feel like there's a chance for me to start over. And if I fall back into the same kind of things I used to do., then ... what am I doing?", "order": 10437, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 10, "scene": 13, "line": "I'd like everybody's attention. Christmas is canceled.", "order": 10438, "speaker": "Michael Scott"},
  {"season": 3, "episode": 10, "scene": 13, "line": "You can't cancel a holiday.", "order": 10439, "speaker": "Stanley Hudson"},
  {"season": 3, "episode": 10, "scene": 13, "line": "Keep it up Stanley and you will lose New Year's.", "order": 10440, "speaker": "Michael Scott"},
  {"season": 3, "episode": 10, "scene": 13, "line": "What does that mean?", "order": 10441, "speaker": "Stanley Hudson"},
  {"season": 3, "episode": 10, "scene": 13, "line": "Jim, take New Year's away from Stanley.", "order": 10442, "speaker": "Michael Scott"},
  {"season": 3, "episode": 10, "scene": 13, "line": "Michael, what's going on?", "order": 10443, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 10, "scene": 13, "line": "Carol and I split up. Amicably. And I just don't think it would be appropriate to celebrate under the circumstances.", "order": 10444, "speaker": "Michael Scott"},
  {"season": 3, "episode": 10, "scene": 13, "line": "Will they still air 'Rudolph?'", "order": 10445, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 10, "scene": 13, "line": "That's not fair.", "order": 10446, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 10, "scene": 13, "line": "[over everybody else's talking] Are we gonna cancel Hanukah as well?", "order": 10447, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 10, "scene": 13, "line": "Fine! [everybody stops talking] Have your party. Just no guests.", "order": 10448, "speaker": "Michael Scott"},
  {"season": 3, "episode": 10, "scene": 13, "line": "But we invited guests.", "order": 10449, "speaker": "Phyllis Lapin"},
  {"season": 3, "episode": 10, "scene": 13, "line": "Well, you know what Phyllis? All of your guests would have probably canceled at the last minute anyway, leaving your life a stupid rotten mess.", "order": 10450, "speaker": "Michael Scott"},
  {"season": 3, "episode": 10, "scene": 14, "line": "Oh, oh, oh, oh, it hurts.", "order": 10451, "speaker": "Michael Scott"},
  {"season": 3, "episode": 10, "scene": 14, "line": "I know.", "order": 10452, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 10, "scene": 14, "line": "It hurts my heart. It hurts my stomach. It hurts my arms.", "order": 10453, "speaker": "Michael Scott"},
  {"season": 3, "episode": 10, "scene": 14, "line": "OK, well, why are you laying like that?", "order": 10454, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 10, "scene": 14, "line": "[moves a little] Thanks. How did you push away the bad thoughts?", "order": 10455, "speaker": "Michael Scott"},
  {"season": 3, "episode": 10, "scene": 14, "line": "Like what?", "order": 10456, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 10, "scene": 14, "line": "Like maybe, the real reason they left was because there were things they wanted you to do in bed, that were, foreign, and scary?", "order": 10457, "speaker": "Michael Scott"},
  {"season": 3, "episode": 10, "scene": 14, "line": "Well.. um I, I don't...", "order": 10458, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 10, "scene": 14, "line": "And not that you didn't want to try them. Some wine may have helped. Do you know what I'm referring to?", "order": 10459, "speaker": "Michael Scott"},
  {"season": 3, "episode": 10, "scene": 14, "line": "I don't need to know.", "order": 10460, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 10, "scene": 14, "line": "Michael. Sorry to interrupt. Uh, It appears we're one bathrobe short.", "order": 10461, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 10, "scene": 14, "line": "Take it from Toby.", "order": 10462, "speaker": "Michael Scott"},
  {"season": 3, "episode": 10, "scene": 14, "line": "Copy.", "order": 10463, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 10, "scene": 14, "line": "Hey, would you like to go to Sandals, Jamaica with me?", "order": 10464, "speaker": "Michael Scott"},
  {"season": 3, "episode": 10, "scene": 14, "line": "No, thank you.", "order": 10465, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 10, "scene": 14, "line": "It's all... [Pam walks away.] OK.", "order": 10466, "speaker": "Michael Scott"},
  {"season": 3, "episode": 10, "scene": 15, "line": "Hey Dwight. Pretty nice robe- [Dwight grabs bag with robe in it and walks away.] Why?", "order": 10467, "speaker": "Toby Flenderson"},
  {"season": 3, "episode": 10, "scene": 16, "line": "Phyllis, I need you to pick up green streamers at lunch.", "order": 10468, "speaker": "Angela Martin"},
  {"season": 3, "episode": 10, "scene": 16, "line": "I thought you said green was whorish.", "order": 10469, "speaker": "Phyllis Lapin"},
  {"season": 3, "episode": 10, "scene": 16, "line": "No, orange is whorish.", "order": 10470, "speaker": "Angela Martin"},
  {"season": 3, "episode": 10, "scene": 16, "line": "Uh, so I had a couple of ideas to make the Stamford people feel more at home. Each year we have a Christmas raffle ... [Phyllis and Pam nod their heads in agreement].", "order": 10471, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 10, "scene": 16, "line": "It would never work here.", "order": 10472, "speaker": "Angela Martin"},
  {"season": 3, "episode": 10, "scene": 16, "line": "Ok ... um, another idea was karaoke ...", "order": 10473, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 10, "scene": 16, "line": "No.", "order": 10474, "speaker": "Angela Martin"},
  {"season": 3, "episode": 10, "scene": 16, "line": "A Christmas drinking game ...", "order": 10475, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 10, "scene": 16, "line": "Yes.", "order": 10476, "speaker": "Meredith Palmer"},
  {"season": 3, "episode": 10, "scene": 16, "line": "God help you.", "order": 10477, "speaker": "Angela Martin"},
  {"season": 3, "episode": 10, "scene": 16, "line": "What?", "order": 10478, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 10, "scene": 16, "line": "These are all terrible ideas and none of them are on the theme of 'A Nutcracker Christmas.' I think you should leave.", "order": 10479, "speaker": "Angela Martin"},
  {"season": 3, "episode": 10, "scene": 16, "line": "You're kidding.", "order": 10480, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 10, "scene": 16, "line": "You tried this out, and it's clearly not for you. It's time to go. Come on. [Angela gets up from seat] Please. [Karen gets up and leaves] Thank you. OK, thank you very much. [Angela closes door]", "order": 10481, "speaker": "Angela Martin"},
  {"season": 3, "episode": 10, "scene": 17, "line": "What are you doing? ['Goodbye My Lover' by James Blunt plays]", "order": 10482, "speaker": "Michael Scott"},
  {"season": 3, "episode": 10, "scene": 17, "line": "[putting things into a box] We are getting rid of everything that reminds you of Carol.", "order": 10483, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 10, "scene": 17, "line": "Hey, what's the haps? [Dwight shuts door in his face.]", "order": 10484, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 10, "scene": 17, "line": "Carol?", "order": 10485, "speaker": "Michael Scott"},
  {"season": 3, "episode": 10, "scene": 17, "line": "Oh, look at this. [looks through papers] Your old condo closing papers. It's riddled with Carol's name. I wish I could throw this in the box. [Michael hits repeat to 'Goodbye My Lover' on computer]", "order": 10486, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 10, "scene": 17, "line": "Why don't you just buy the whole song?", "order": 10487, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 10, "scene": 17, "line": "I don't have to buy it. I just want to taste it. I just... I just want a little taste of it.", "order": 10488, "speaker": "Michael Scott"},
  {"season": 3, "episode": 10, "scene": 17, "line": "Oh, look at this, she saved you two thousand dollars 'cause they failed to report a mold problem. [Continues to sift through papers] But wouldn't that affect the final... How did she? Oh, oh, I see what she did. That is good. Wow. Carol is one smart cookie.", "order": 10489, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 10, "scene": 17, "line": "[singing] Goodbye my lover. Goodbye my friend. [hits repeat again]", "order": 10490, "speaker": "Michael Scott"},
  {"season": 3, "episode": 10, "scene": 18, "line": "This is an old adage, but they say when you find true love, you know within the first 24 hours. With Carol, I knew within the first 24 minutes of the second day I met her.", "order": 10491, "speaker": "Michael Scott"},
  {"season": 3, "episode": 10, "scene": 19, "line": "I feel like I've been kinda cold to Karen and there's no real reason for it. I mean it's not like she's ever done anything to me. So, I think I probably shouldn't be cold to her.", "order": 10492, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 10, "scene": 20, "line": "Hey, Karen. Sorry about that meeting today, that was really crazy.", "order": 10493, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 10, "scene": 20, "line": "Yeah, right? I'm so glad you said that. Because, I don't know how those meetings usually go.", "order": 10494, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 10, "scene": 20, "line": "Um, usually like that.", "order": 10495, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 10, "scene": 20, "line": "Does anyone ever stand up to Angela or..?", "order": 10496, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 10, "scene": 20, "line": "I think one of her cats did once. She came in with scratches all over her face. [both laugh]", "order": 10497, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 10, "scene": 20, "line": "Right.", "order": 10498, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 10, "scene": 20, "line": "Um, I really liked your karaoke idea.", "order": 10499, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 10, "scene": 20, "line": "Oh cool, yeah.", "order": 10500, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 10, "scene": 20, "line": "That could really be fun.", "order": 10501, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 10, "scene": 20, "line": "Oh, thank you.", "order": 10502, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 10, "scene": 20, "line": "You guys do a raffle?", "order": 10503, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 10, "scene": 20, "line": "Yeah, we do a raffle...", "order": 10504, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 10, "scene": 21, "line": "Hey Kev.", "order": 10505, "speaker": "Toby Flenderson"},
  {"season": 3, "episode": 10, "scene": 21, "line": "Hey. [Toby puts hand on Kevin's back, who is wearing the robe and feels it a little bit.]", "order": 10506, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 10, "scene": 22, "line": "This should've been up yesterday. [refers to party flyer]", "order": 10507, "speaker": "Angela Martin"},
  {"season": 3, "episode": 10, "scene": 22, "line": "It'll be all right.", "order": 10508, "speaker": "Phyllis Lapin"},
  {"season": 3, "episode": 10, "scene": 22, "line": "[Pam puts up another flyer] What is that? [Reads flyer] The 'Committee to Plan Parties invites you to a margarita-karaoke Christmas?' There's no such thing as the 'Committee to Plan Parties.'", "order": 10509, "speaker": "Angela Martin"},
  {"season": 3, "episode": 10, "scene": 22, "line": "There is now. We just started it.", "order": 10510, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 10, "scene": 22, "line": "Well, you just can't start a committee. You have to have funding.", "order": 10511, "speaker": "Angela Martin"},
  {"season": 3, "episode": 10, "scene": 22, "line": "What's your funding?", "order": 10512, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 10, "scene": 22, "line": "Two hundred dollars.", "order": 10513, "speaker": "Angela Martin"},
  {"season": 3, "episode": 10, "scene": 22, "line": "What's ours again?", "order": 10514, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 10, "scene": 22, "line": "Umm, two hundred and one dollars.", "order": 10515, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 10, "scene": 22, "line": "Oh right.", "order": 10516, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 10, "scene": 22, "line": "[pointing] Hey, a margarita-karaoke Christmas party, that sounds like fun.", "order": 10517, "speaker": "Kelly Kapoor"},
  {"season": 3, "episode": 10, "scene": 22, "line": "No, that is not a party. There's only one party and it's hosted by the Party PLANNING Committee and it starts at three o'clock.", "order": 10518, "speaker": "Angela Martin"},
  {"season": 3, "episode": 10, "scene": 22, "line": "Then why are there two flyers?", "order": 10519, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 10, "scene": 22, "line": "Oh, I understand that this is confusing for everyone, let me explain. There's a party that starts at three.", "order": 10520, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 10, "scene": 22, "line": "Right.", "order": 10521, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 10, "scene": 22, "line": "And then there's a way more fun party that starts at two forty-five.", "order": 10522, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 10, "scene": 22, "line": "Right, and if you're interested in the way more fun party, all the info can be found here on our more brightly colored flyer. [Angela rips down brightly colored flyer and walks away. Karen rips down Angela's flyer.]", "order": 10523, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 10, "scene": 22, "line": "I didn't see where it was.", "order": 10524, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 10, "scene": 23, "line": "Yup, looks like the Scranton people and the Stamford people are finally coming together. And that's what you want, right?", "order": 10525, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 10, "scene": 24, "line": "I think that's a really good option...", "order": 10526, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 10, "scene": 24, "line": "Pam and Karen. I am ordering you to cease and desist all party planning. Immediately.", "order": 10527, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 10, "scene": 24, "line": "You can't do that.", "order": 10528, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 10, "scene": 24, "line": "As ranking number three in this office, I am order -", "order": 10529, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 10, "scene": 24, "line": "Umm... I'm number three.", "order": 10530, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 10, "scene": 24, "line": "You're number four.", "order": 10531, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 10, "scene": 24, "line": "Yeah, but I'm number three.", "order": 10532, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 10, "scene": 24, "line": "Ah, no. [to Pam and Karen] You must turn over to me all Christmas decorations and party paraphernalia immediately that will be returned to you on January fourth.", "order": 10533, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 10, "scene": 24, "line": "OK, I think I can help here.", "order": 10534, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 10, "scene": 24, "line": "Ok, good", "order": 10535, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 10, "scene": 24, "line": "As ranking number two, I am starting a committee to determine the validity of the two committees and I am the sole member. The committee will act on this now.", "order": 10536, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 10, "scene": 24, "line": "OK, this is stupid.", "order": 10537, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 10, "scene": 24, "line": "Could you please keep it down? [interrupted Dwight] I'm in session. I've determined that this committee is valid. [Pam and Karen cheer.]", "order": 10538, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 10, "scene": 24, "line": "No no, no, wait, wait, wait [raises hand] Permission to join the Validity Committee.", "order": 10539, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 10, "scene": 24, "line": "Permission denied.", "order": 10540, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 10, "scene": 24, "line": "Damn it!", "order": 10541, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 10, "scene": 25, "line": "Hey. I can't concentrate when I know you're in pain, man. Let me take you to lunch. [Michael sighs.] C'mon, my treat.", "order": 10542, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 10, "scene": 25, "line": "All right. Nothing here to distract myself with anyway.", "order": 10543, "speaker": "Michael Scott"},
  {"season": 3, "episode": 10, "scene": 25, "line": "That's my boy. I know the perfect place, too.", "order": 10544, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 10, "scene": 25, "line": "Hooters?", "order": 10545, "speaker": "Michael Scott"},
  {"season": 3, "episode": 10, "scene": 25, "line": "No. Benihana. Much classier. But don't worry, the babes are totally hot, too. [do a fist bump and explosion]", "order": 10546, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 10, "scene": 25, "line": "Ah, I need my entourage [both exit Michael's office] Jim. Dwight. Ryan. C'mon, we're going to Asian Hooters.", "order": 10547, "speaker": "Michael Scott"},
  {"season": 3, "episode": 10, "scene": 25, "line": "Ah man, I can't.", "order": 10548, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 10, "scene": 25, "line": "Why not?", "order": 10549, "speaker": "Michael Scott"},
  {"season": 3, "episode": 10, "scene": 25, "line": "I'm not feeling so well. I've got a ton of work to do here. MSG allergy, peanut allergy, I just ate there last night. [Michael feels Ryan's forehead]", "order": 10550, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 10, "scene": 25, "line": "Ok, feel better.", "order": 10551, "speaker": "Michael Scott"},
  {"season": 3, "episode": 10, "scene": 25, "line": "Thanks.", "order": 10552, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 10, "scene": 25, "line": "C'mon Jim, let's go.", "order": 10553, "speaker": "Michael Scott"},
  {"season": 3, "episode": 10, "scene": 25, "line": "OK. [to Ryan] Wow, thanks for taking all the excuses, dude.", "order": 10554, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 10, "scene": 25, "line": "Doctor appointment, car trouble, plantar warts, granddad fought in World War II. Use your head, man. I keep mine in here. [Holds up blackberry.] Look alive, Halpert. Welcome back.", "order": 10555, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 10, "scene": 26, "line": "Bros before hos. Why? Because your bros are always there for you. They have got your back after your ho rips your heart out for no good reason. And you were nothing but great to your ho and you told her she was the only ho for you. And that she was better than all the other hos in the world. And then ... and then suddenly she's not yo' ho no mo'.", "order": 10556, "speaker": "Michael Scott"},
  {"season": 3, "episode": 10, "scene": 27, "line": "So she looks really hot, so I said, 'You look hotter than usual today.' [to waitress] Thank you. Michael [gesturing to sit down.] Head of the table.", "order": 10557, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 10, "scene": 27, "line": "Get out Jim. [Dwight tries to sit between Andy and Jim] Actually, um... [to two other people at the table] Sir, I'm going to need take this chair.", "order": 10558, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 10, "scene": 27, "line": "Dwight, just, just leave them alone please. They're on a date. They look very happy.", "order": 10559, "speaker": "Michael Scott"},
  {"season": 3, "episode": 10, "scene": 27, "line": "So she looks at me right, and she goes, 'I'm sorry, don't I even know you?' After a year, a", "order": 10560, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 10, "scene": 27, "line": "Yes.", "order": 10561, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 10, "scene": 27, "line": "I can't... I can't here what you're saying.", "order": 10562, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 10, "scene": 27, "line": "Carol used to drink lattes.", "order": 10563, "speaker": "Michael Scott"},
  {"season": 3, "episode": 10, "scene": 27, "line": "[off screen at other end of table] What're you talking about?", "order": 10564, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 10, "scene": 27, "line": "She would get this little foam mustache...", "order": 10565, "speaker": "Michael Scott"},
  {"season": 3, "episode": 10, "scene": 27, "line": "[still off screen] Carol had a mustache?", "order": 10566, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 10, "scene": 27, "line": "And I used to say 'Hey, got latte?' And she'd say, that's not funny.", "order": 10567, "speaker": "Michael Scott"},
  {"season": 3, "episode": 10, "scene": 27, "line": "[off screen] What are you guys talking about?", "order": 10568, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 10, "scene": 27, "line": "She totally got me. She understood that we didn't have to laugh to enjoy ---", "order": 10569, "speaker": "Michael Scott"},
  {"season": 3, "episode": 10, "scene": 27, "line": "Michael! Repeat what you said louder!", "order": 10570, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 10, "scene": 28, "line": "It is my job to be there for Michael. How can I be there for Michael if I'm here for Michael? [food is thrown at Dwight's face]", "order": 10571, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 10, "scene": 29, "line": "I'm already starting to forget what color eyes she had. I can't, OK, I'm gonna call her. I am gonna call and find out...", "order": 10572, "speaker": "Michael Scott"},
  {"season": 3, "episode": 10, "scene": 29, "line": "No.", "order": 10573, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 10, "scene": 29, "line": "Who are you calling?", "order": 10574, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 10, "scene": 29, "line": "I'm just gonna call her.", "order": 10575, "speaker": "Michael Scott"},
  {"season": 3, "episode": 10, "scene": 29, "line": "Put that away. Put that away. S.O.S. We... I... May day. Haha. Man down over here, [to waitress] we need your help.", "order": 10576, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 10, "scene": 29, "line": "What can I get for 'ya?", "order": 10577, "speaker": "Waitress"},
  {"season": 3, "episode": 10, "scene": 29, "line": "I think we'll start with a round of noga-sakes. [waitress gives confused look]", "order": 10578, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 10, "scene": 30, "line": "One part eggnog, three parts sake. Some places won't make it for you though, because eggnog is seasonal.", "order": 10579, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 10, "scene": 31, "line": "You should, you should put out salt for the rims [referring to margaritas]", "order": 10580, "speaker": "Phyllis Lapin"},
  {"season": 3, "episode": 10, "scene": 31, "line": "That's a great idea.", "order": 10581, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 10, "scene": 31, "line": "Phyllis?", "order": 10582, "speaker": "Angela Martin"},
  {"season": 3, "episode": 10, "scene": 31, "line": "I was just getting a snack.", "order": 10583, "speaker": "Phyllis Lapin"},
  {"season": 3, "episode": 10, "scene": 31, "line": "You can have your snack in here.", "order": 10584, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 10, "scene": 31, "line": "Pam, don't tell her what to do! Phyllis.", "order": 10585, "speaker": "Angela Martin"},
  {"season": 3, "episode": 10, "scene": 31, "line": "OK.", "order": 10586, "speaker": "Phyllis Lapin"},
  {"season": 3, "episode": 10, "scene": 32, "line": "I think I'll go to Angela's party, because that's the party I know.", "order": 10587, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 10, "scene": 33, "line": "I miss the days when there was only one party I didn't want to go to.", "order": 10588, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 10, "scene": 34, "line": "So I only use three?", "order": 10589, "speaker": "Roy Anderson"},
  {"season": 3, "episode": 10, "scene": 34, "line": "If you're using more than three pieces of tape to wrap a present, you're doing it wrong.", "order": 10590, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 10, "scene": 34, "line": "OK. And where to do you get all those cool bows?", "order": 10591, "speaker": "Roy Anderson"},
  {"season": 3, "episode": 10, "scene": 34, "line": "Oh, I just get those at any party supply place.", "order": 10592, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 10, "scene": 34, "line": "OK. Are you sure I can't use like, the cartoons from the newspaper?", "order": 10593, "speaker": "Roy Anderson"},
  {"season": 3, "episode": 10, "scene": 34, "line": "Oh yeah, your mom would love that.", "order": 10594, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 10, "scene": 34, "line": "[Karen walks in] Hey. All right, I'll see you guys later. [leaves room]", "order": 10595, "speaker": "Roy Anderson"},
  {"season": 3, "episode": 10, "scene": 34, "line": "Bye.", "order": 10596, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 10, "scene": 34, "line": "He's cute. You should date him.", "order": 10597, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 10, "scene": 34, "line": "Oh, yeah... Maybe.", "order": 10598, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 10, "scene": 35, "line": "Looks like you've got a little Nakiri knife action going there.", "order": 10599, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 10, "scene": 35, "line": "No, it's Usuba.", "order": 10600, "speaker": "Chef"},
  {"season": 3, "episode": 10, "scene": 35, "line": "Yeah, I bet you wish you had a Nakiri, though.", "order": 10601, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 10, "scene": 35, "line": "Actually, the Usuba's the better knife when you're working with this quantity.", "order": 10602, "speaker": "Chef"},
  {"season": 3, "episode": 10, "scene": 35, "line": "Nah, I don't know... Still think Nakiri's better.", "order": 10603, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 10, "scene": 35, "line": "I think he'd know. [Dwight and her stare at each other]", "order": 10604, "speaker": "Woman at bar"},
  {"season": 3, "episode": 10, "scene": 36, "line": "Nothing he's doing is cheering me up.", "order": 10605, "speaker": "Michael Scott"},
  {"season": 3, "episode": 10, "scene": 36, "line": "Wait for the onion. Trust me. Hey Cindy, he just had his heart broken, you wouldn't do that to him, would you?", "order": 10606, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 10, "scene": 36, "line": "I don't know.", "order": 10607, "speaker": "Cindy"},
  {"season": 3, "episode": 10, "scene": 36, "line": "Oh, sure you would. Look at you, I bet you break up with a guy every hour. [Cindy laughs and walks away]", "order": 10608, "speaker": "Michael Scott"},
  {"season": 3, "episode": 10, "scene": 36, "line": "You made her laugh. Yes! She totally digs us. [referring to what chef is doing] Watch, watch, watch, watch. [Onions smoke up] Heh, heh? What did I tell 'ya?", "order": 10609, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 10, "scene": 36, "line": "You're right. That's good.", "order": 10610, "speaker": "Michael Scott"},
  {"season": 3, "episode": 10, "scene": 37, "line": "Hello everyone. We would just like to announce that our party is starting now in the break room. So... you can come by... .", "order": 10611, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 10, "scene": 37, "line": "I have a very important announcement to make... about... your paychecks. Umm... Your paychecks will be arriving as scheduled on Friday. And they will be in the correct amount that they are normally in... Please stand by for a very important announcement... refer..[starts to leave office] for further regarding your paycheck! [runs outside]", "order": 10612, "speaker": "Angela Martin"},
  {"season": 3, "episode": 10, "scene": 38, "line": "[on cell phone to Dwight] I need to know if I can start the party?", "order": 10613, "speaker": "Angela Martin"},
  {"season": 3, "episode": 10, "scene": 38, "line": "Michael! Hey! Over here, Michael! [waves down Michael with a napkin and whistles to get attention]", "order": 10614, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 10, "scene": 38, "line": "This drink... [in audible conversation with Michael]", "order": 10615, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 10, "scene": 38, "line": "I don't know. No one can hear me. You know what? Start the party.", "order": 10616, "speaker": "Michael Scott"},
  {"season": 3, "episode": 10, "scene": 38, "line": "Did Michael give you permission to do this?", "order": 10617, "speaker": "Angela Martin"},
  {"season": 3, "episode": 10, "scene": 38, "line": "[with conviction] Start the party.", "order": 10618, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 10, "scene": 38, "line": "[Angela slams phone shut and runs back to office.] Also, I would like to inform you that, um, as a special treat, my party will be starting early. In fact, it will be starting right now [opens door to conference room.]", "order": 10619, "speaker": "Angela Martin"},
  {"season": 3, "episode": 10, "scene": 38, "line": "Our party is also starting now [opens door to break room.]", "order": 10620, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 10, "scene": 38, "line": "Yup.", "order": 10621, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 10, "scene": 38, "line": "[Stanley gets up and walks towards the break room.] All right Stanley. Woo.", "order": 10622, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 10, "scene": 38, "line": "Good choice. [Kelly drags Ryan to the break room.]", "order": 10623, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 10, "scene": 38, "line": "Welcome Hannah. You will not be disappointed.", "order": 10624, "speaker": "Angela Martin"},
  {"season": 3, "episode": 10, "scene": 38, "line": "Why would I be disappointed?", "order": 10625, "speaker": "Hannah"},
  {"season": 3, "episode": 10, "scene": 38, "line": "I said you wouldn't be disappointed. [Meredith heads towards break room.]", "order": 10626, "speaker": "Angela Martin"},
  {"season": 3, "episode": 10, "scene": 38, "line": "Meredith, if you don't come to my party, you will be very, very sorry.", "order": 10627, "speaker": "Angela Martin"},
  {"season": 3, "episode": 10, "scene": 38, "line": "Is that a threat?", "order": 10628, "speaker": "Meredith Palmer"},
  {"season": 3, "episode": 10, "scene": 38, "line": "No, it's an invitation.", "order": 10629, "speaker": "Angela Martin"},
  {"season": 3, "episode": 10, "scene": 38, "line": "We have vodka!", "order": 10630, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 10, "scene": 38, "line": "Lots of it! [Meredith leaves to break room party.]", "order": 10631, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 10, "scene": 39, "line": "I hear Angela's party will have double-fudge brownies. It will also have Angela. Double fudge... Angela... double fudge... Angela [puts out hands to weigh his options] Hmmm...", "order": 10632, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 10, "scene": 40, "line": "Brownies. Cupcakes. [Kevin walks toward conference room]", "order": 10633, "speaker": "Angela Martin"},
  {"season": 3, "episode": 10, "scene": 40, "line": "Don't push it.", "order": 10634, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 10, "scene": 41, "line": "Are we taking this too far? You know what, I don't think we're taking this far enough. [Pam and Karen look at each other] What?", "order": 10635, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 10, "scene": 41, "line": "I got goose bumps.", "order": 10636, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 10, "scene": 42, "line": "I don't back down. My sister and I used to be best friends, and we haven't talked in 16 years. Over some disagreement, I don't even remember. So... yeah. I'm pretty good.", "order": 10637, "speaker": "Angela Martin"},
  {"season": 3, "episode": 10, "scene": 43, "line": "Ahh, damn this chicken is good. My compliments to the chef. [points to chef] Which is you! Right? Hahahaha. Awesome! I need some meat. I want some steak. [leans over to neighboring man's plate] I see steak.", "order": 10638, "speaker": "Michael Scott"},
  {"season": 3, "episode": 10, "scene": 43, "line": "Excuse me.", "order": 10639, "speaker": "Man"},
  {"season": 3, "episode": 10, "scene": 43, "line": "Excuse me [tries to take away meat with chopsticks] Ah, un guard. [Fights with chopsticks and laughs] Family style.", "order": 10640, "speaker": "Michael Scott"},
  {"season": 3, "episode": 10, "scene": 43, "line": "No, it's not.", "order": 10641, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 10, "scene": 43, "line": "Cindy, Cindy.", "order": 10642, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 10, "scene": 43, "line": "I don't think... I love it!", "order": 10643, "speaker": "Michael Scott"},
  {"season": 3, "episode": 10, "scene": 43, "line": "I want you to close your eyes and imagine your dream house. [Cindy laughs] C'mon, do it for Michael. He's had a really long day.", "order": 10644, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 10, "scene": 43, "line": "[laughing] I don't know what he's doing.", "order": 10645, "speaker": "Michael Scott"},
  {"season": 3, "episode": 10, "scene": 43, "line": "This is great.", "order": 10646, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 10, "scene": 43, "line": "Jim! Jim! What... What's happening?", "order": 10647, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 10, "scene": 43, "line": "Oh, [points to Cindy] she's asleep.", "order": 10648, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 10, "scene": 43, "line": "Oh... Narcolepsy.", "order": 10649, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 10, "scene": 43, "line": "Probably.", "order": 10650, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 10, "scene": 43, "line": "Now open your eyes and describe it to me.", "order": 10651, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 10, "scene": 43, "line": "I... don't know, it has four bed rooms and a loft...", "order": 10652, "speaker": "Cindy"},
  {"season": 3, "episode": 10, "scene": 43, "line": "Oh my gosh, now she's up.", "order": 10653, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 10, "scene": 43, "line": "And she's trying to correctly butcher a goose, but she's having trouble coming up with it.", "order": 10654, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 10, "scene": 43, "line": "Oh, OK. Cindy! Yo, Cindy! Cindy! Hold its neck back and insert the knife below the jaw. Bring it all the way around, there's going to be a good amount of blood. [Cindy looks confused. Woman next to Dwight is disgusted] Don't let it bother you. Have a bucket there, for the blood... and the innards... and the feathers.", "order": 10655, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 10, "scene": 44, "line": "Oh no, this is different. The CIA thing, that was a prank on Dwight. This is more like a umm... OK, it's pretty much the same thing.", "order": 10656, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 10, "scene": 45, "line": "What d'ya think? [to Stanley about his drink]", "order": 10657, "speaker": "Kelly Kapoor"},
  {"season": 3, "episode": 10, "scene": 45, "line": "Fruity and delicious.", "order": 10658, "speaker": "Stanley Hudson"},
  {"season": 3, "episode": 10, "scene": 45, "line": "See, I told you. You want one Meredith?", "order": 10659, "speaker": "Kelly Kapoor"},
  {"season": 3, "episode": 10, "scene": 45, "line": "No thanks. They're too sweet.", "order": 10660, "speaker": "Meredith Palmer"},
  {"season": 3, "episode": 10, "scene": 45, "line": "Hey, so what's the status.", "order": 10661, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 10, "scene": 45, "line": "Looks like they forgot the power cord.", "order": 10662, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 10, "scene": 45, "line": "What?", "order": 10663, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 10, "scene": 45, "line": "Oh, you're kidding me?", "order": 10664, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 10, "scene": 45, "line": "No.", "order": 10665, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 10, "scene": 45, "line": "Oh, you guys, guys, um... I'm sorry, but there's a problem with the karaoke machine. [everyone sighs]", "order": 10666, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 10, "scene": 45, "line": "Well that blows.", "order": 10667, "speaker": "Kelly Kapoor"},
  {"season": 3, "episode": 10, "scene": 45, "line": "Hang on little ladies. You don't need this thing, I'll go grab my synthesizer. [Everyone cheers]", "order": 10668, "speaker": "Darryl Philbin"},
  {"season": 3, "episode": 10, "scene": 45, "line": "Darryl! Darryl! Darryl! [Darryl exits break room and passes Phyllis]", "order": 10669, "speaker": "Everybody"},
  {"season": 3, "episode": 10, "scene": 45, "line": "Hi.", "order": 10670, "speaker": "Phyllis Lapin"},
  {"season": 3, "episode": 10, "scene": 45, "line": "Hey. Hey, look, when you get done with your... [looks inside conference room] meeting, you should, uh, come to the break room. We're having a party.", "order": 10671, "speaker": "Darryl Philbin"},
  {"season": 3, "episode": 10, "scene": 45, "line": "Oh, ok.", "order": 10672, "speaker": "Phyllis Lapin"},
  {"season": 3, "episode": 10, "scene": 45, "line": "All right. Se you later...", "order": 10673, "speaker": "Darryl Philbin"},
  {"season": 3, "episode": 10, "scene": 45, "line": "[tries to break nut on a Nutcracker doll] These nuts are really hard to crack.", "order": 10674, "speaker": "Hannah"},
  {"season": 3, "episode": 10, "scene": 45, "line": "Try harder then. [Hannah smashes Nutcracker on top of nut. Sees Kevin take another brownie] Uh uh. No one has seconds until everyone's had some.", "order": 10675, "speaker": "Angela Martin"},
  {"season": 3, "episode": 10, "scene": 45, "line": "You've got to be kidding!", "order": 10676, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 10, "scene": 45, "line": "You've got to be kidding. [Kevin takes bite of brownie]", "order": 10677, "speaker": "Angela Martin"},
  {"season": 3, "episode": 10, "scene": 46, "line": "Cold, huh?", "order": 10678, "speaker": "Phyllis Lapin"},
  {"season": 3, "episode": 10, "scene": 46, "line": "Huh? [puts on jacket]", "order": 10679, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 10, "scene": 46, "line": "Maybe that'll help. Is it cold in there?", "order": 10680, "speaker": "Phyllis Lapin"},
  {"season": 3, "episode": 10, "scene": 47, "line": "[referring to waitresses] They have been checking us out, all night! I am not kidding!", "order": 10681, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 10, "scene": 47, "line": "You know what we should do?", "order": 10682, "speaker": "Michael Scott"},
  {"season": 3, "episode": 10, "scene": 47, "line": "What?", "order": 10683, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 10, "scene": 47, "line": "We should invite them to the Christmas party.", "order": 10684, "speaker": "Michael Scott"},
  {"season": 3, "episode": 10, "scene": 47, "line": "Now, you are thinking. Yes. And you know what? Because you have had such a rough day, you get Cindy.", "order": 10685, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 10, "scene": 47, "line": "Oh, you are such mench my friend. [They stand up and do fist bump and explosion]", "order": 10686, "speaker": "Michael Scott"},
  {"season": 3, "episode": 10, "scene": 48, "line": "OK, where is everybody? I would like them to meet my new girlfriend. [Guys walk in with two different waitresses from Benihana's]", "order": 10687, "speaker": "Michael Scott"},
  {"season": 3, "episode": 10, "scene": 48, "line": "I'm sure they'll want to meet her, too.", "order": 10688, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 10, "scene": 49, "line": "The least I could do was give some poor sick kid a bike.", "order": 10689, "speaker": "Michael Scott"},
  {"season": 3, "episode": 10, "scene": 49, "line": "That rocks.", "order": 10690, "speaker": "Second Cindy"},
  {"season": 3, "episode": 10, "scene": 49, "line": "Nah, you rock. Tell you what, if you want it, it's yours.", "order": 10691, "speaker": "Michael Scott"},
  {"season": 3, "episode": 10, "scene": 49, "line": "Thanks! I, I wanna give you something.", "order": 10692, "speaker": "Second Cindy"},
  {"season": 3, "episode": 10, "scene": 49, "line": "Oh. [She whispers in his ear. Michael starts to laugh] That's what she said.", "order": 10693, "speaker": "Michael Scott"},
  {"season": 3, "episode": 10, "scene": 50, "line": "Hello? I would like everybody to meet my new girlfriend.", "order": 10694, "speaker": "Michael Scott"},
  {"season": 3, "episode": 10, "scene": 50, "line": "My new girlfriend.", "order": 10695, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 10, "scene": 50, "line": "Where's Dwight?", "order": 10696, "speaker": "Angela Martin"},
  {"season": 3, "episode": 10, "scene": 50, "line": "Is he the hot one or the giant baby?", "order": 10697, "speaker": "Second Cindy"},
  {"season": 3, "episode": 10, "scene": 50, "line": "[whispers] The giant baby. Wow, I am so sorry, I had no idea this party would be so lame.", "order": 10698, "speaker": "Michael Scott"},
  {"season": 3, "episode": 10, "scene": 50, "line": "[under her breath] There's another party in the break room.", "order": 10699, "speaker": "Phyllis Lapin"},
  {"season": 3, "episode": 10, "scene": 50, "line": "Oh, party in the break room! Let's go, let's go! Thank God! Let's go to the break room.", "order": 10700, "speaker": "Michael Scott"},
  {"season": 3, "episode": 10, "scene": 50, "line": "Hey, excuse me! Waitress lady! Hey, where do you think you're going with that? [refers to toy she just took from table]", "order": 10701, "speaker": "Angela Martin"},
  {"season": 3, "episode": 10, "scene": 50, "line": "I thought I could have it.", "order": 10702, "speaker": "Second Cindy"},
  {"season": 3, "episode": 10, "scene": 50, "line": "You can't have it [takes back toy] I don't walk into your house and steal your Hello Kitty backpack.", "order": 10703, "speaker": "Angela Martin"},
  {"season": 3, "episode": 10, "scene": 50, "line": "Shhh... Shut it! C'mon! Party.", "order": 10704, "speaker": "Michael Scott"},
  {"season": 3, "episode": 10, "scene": 50, "line": "Hey! It's Angela! [Angela slams door shut, but not before Kevin sneaks out] Hey!", "order": 10705, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 10, "scene": 50, "line": "We're going to ...", "order": 10706, "speaker": "Michael Scott"},
  {"season": 3, "episode": 10, "scene": 51, "line": "[singing] I want you to know. That I'm happy for you. I wish nothing but...", "order": 10707, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 10, "scene": 52, "line": "I just think there are two, two specific kinds of people in the world. People who own houses and people who own condos. [turns to reach for pretzel while Second Cindy turns other direction] And... my question to you [points and pulls away other waitress] is do you agree?", "order": 10708, "speaker": "Michael Scott"},
  {"season": 3, "episode": 10, "scene": 52, "line": "Do I agree about what?", "order": 10709, "speaker": "Other waitress"},
  {"season": 3, "episode": 10, "scene": 52, "line": "Do you agree about what? [laughs] Wow. You're lucky you're so darn cute.", "order": 10710, "speaker": "Michael Scott"},
  {"season": 3, "episode": 10, "scene": 52, "line": "What're you talking about?", "order": 10711, "speaker": "Other waitress"},
  {"season": 3, "episode": 10, "scene": 52, "line": "What're you talking about?", "order": 10712, "speaker": "Michael Scott"},
  {"season": 3, "episode": 10, "scene": 53, "line": "[singing] And I'm here to remind you of the mess you left when you went away. It's not fair, to deny me of the cross I bear that you give to me. You, you, you, you, you, you, you oughta know ta know.", "order": 10713, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 10, "scene": 53, "line": "All right [high fives Kevin]", "order": 10714, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 10, "scene": 54, "line": "Did you have fun at Benihana's?", "order": 10715, "speaker": "Angela Martin"},
  {"season": 3, "episode": 10, "scene": 54, "line": "No. It was awful. I couldn't hear anything. [cheers from other party]", "order": 10716, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 10, "scene": 54, "line": "I've had the worse day here [turns up Nutcracker music on the stereo. [Sighs. Dwight reaches for and holds Angela's hand]", "order": 10717, "speaker": "Angela Martin"},
  {"season": 3, "episode": 10, "scene": 54, "line": "[Karen and Pam enter] Dwight, you won the raffle! [holds out gift]", "order": 10718, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 10, "scene": 54, "line": "No... way! Yeah!", "order": 10719, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 10, "scene": 54, "line": "Open it! Open it ! Open it! [Dwight rips open present. Angela is upset and Pam notices]", "order": 10720, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 10, "scene": 54, "line": "Oh.", "order": 10721, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 10, "scene": 54, "line": "Do you like it?", "order": 10722, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 10, "scene": 54, "line": "Walkie-talkies.", "order": 10723, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 10, "scene": 54, "line": "Can I talk to you a second?", "order": 10724, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 10, "scene": 54, "line": "Yes!", "order": 10725, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 10, "scene": 55, "line": "Well the Committee to Plan Parties has served its purpose. We're gonna disband. In the name of Christmas.", "order": 10726, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 10, "scene": 55, "line": "[holds up rice krispie shaped like a star] In the name of Nutcracker Christmas.", "order": 10727, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 10, "scene": 56, "line": "Angela, we've been hearing really great thing about uh... your brownies and we were hoping you'd consider merging into two parties.", "order": 10728, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 10, "scene": 56, "line": "I'm not sure. Does your karaoke machine have Christmas songs?", "order": 10729, "speaker": "Angela Martin"},
  {"season": 3, "episode": 10, "scene": 56, "line": "Yeah, but we don't have a power cord.", "order": 10730, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 10, "scene": 56, "line": "Oh, I may have seen it somewhere. [Goes to plant and picks up power cord] Is it this one?", "order": 10731, "speaker": "Angela Martin"},
  {"season": 3, "episode": 10, "scene": 56, "line": "Mmm. Hmm.", "order": 10732, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 10, "scene": 56, "line": "Yeah, thanks.", "order": 10733, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 10, "scene": 56, "line": "We'll go tell everyone.", "order": 10734, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 10, "scene": 56, "line": "OK.", "order": 10735, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 10, "scene": 57, "line": "[singing] And if you want love, we'll make it. Swim in a big sea of blankets. Take all your big plans and break 'em. This is bound to take awhile. Your body is a wonderland. [Michael notices Cindy ride up to him on the bike and looks confused] Your body is a wonderland.", "order": 10736, "speaker": "Andy Bernard & Michael Scott"},
  {"season": 3, "episode": 10, "scene": 57, "line": "Your hands.", "order": 10737, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 10, "scene": 57, "line": "Your body is a wonderland.", "order": 10738, "speaker": "Both"},
  {"season": 3, "episode": 10, "scene": 57, "line": "I'll use my hands on it.", "order": 10739, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 10, "scene": 57, "line": "Something 'bout the way your hair falls in your face...", "order": 10740, "speaker": "Both"},
  {"season": 3, "episode": 10, "scene": 58, "line": "[singing] Whatever we deny or embrace. For worse or for better. We belong, we belong, we belong together... Ryan...", "order": 10741, "speaker": "Kelly Kapoor"},
  {"season": 3, "episode": 10, "scene": 58, "line": "When you know, you just know.", "order": 10742, "speaker": "Michael Scott"},
  {"season": 3, "episode": 10, "scene": 58, "line": "Right.", "order": 10743, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 10, "scene": 58, "line": "Check her out. My little gal over there. Babe-alectable.", "order": 10744, "speaker": "Michael Scott"},
  {"season": 3, "episode": 10, "scene": 58, "line": "Which one is she? [Michael stares at both waitresses]", "order": 10745, "speaker": "Roy Anderson"},
  {"season": 3, "episode": 10, "scene": 58, "line": "It's... it's one of those two. [points at both]", "order": 10746, "speaker": "Michael Scott"},
  {"season": 3, "episode": 10, "scene": 58, "line": "You don't know?", "order": 10747, "speaker": "Roy Anderson"},
  {"season": 3, "episode": 10, "scene": 58, "line": "Dude, you should know.", "order": 10748, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 10, "scene": 58, "line": "Yeah... well, it's been hard, they're wearing the exact same uniform. And I've been drinking. And you know how all waitresses look alike.", "order": 10749, "speaker": "Michael Scott"},
  {"season": 3, "episode": 10, "scene": 59, "line": "I honestly don't see what the big deal is. Stevie Wonder is married. Are you going to tell me that Stevie Wonder doesn't love his wife just because he's not sure what she looks like?", "order": 10750, "speaker": "Michael Scott"},
  {"season": 3, "episode": 10, "scene": 60, "line": "Hey. Where's my girl? Is she in the fridge? Where is she hiding? I don't know where she is. 'Cause I'm drunk, I can't even find her.", "order": 10751, "speaker": "Michael Scott"},
  {"season": 3, "episode": 10, "scene": 60, "line": "You know where I am.", "order": 10752, "speaker": "Second Cindy"},
  {"season": 3, "episode": 10, "scene": 60, "line": "Haha, I do! I just haven't hugged you in awhile [marks Cindy's arm with a black marker]", "order": 10753, "speaker": "Michael Scott"},
  {"season": 3, "episode": 10, "scene": 60, "line": "Oh.", "order": 10754, "speaker": "Second Cindy"},
  {"season": 3, "episode": 10, "scene": 60, "line": "Oh, good. That felt good. Let's go. Party.", "order": 10755, "speaker": "Michael Scott"},
  {"season": 3, "episode": 10, "scene": 61, "line": "[singing] Spinnin' n reelin with love. Give it the time, I might come back down. But it feels so good. My feet don't touch the ground. Wha..wha..wild. Wha..wha..wild. Well everybody knows, I'm crazy about 'ya...", "order": 10756, "speaker": "Creed Bratton"},
  {"season": 3, "episode": 10, "scene": 62, "line": "[into walkie-talkie] Monkey, this is Possum. Do you copy?", "order": 10757, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 10, "scene": 62, "line": "[into another walkie-talkie] Copy, Possum. What's your twenty?", "order": 10758, "speaker": "Angela Martin"},
  {"season": 3, "episode": 10, "scene": 63, "line": "No way. [both hold up Bridget Jones' Diary movie DVD]", "order": 10759, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 10, "scene": 63, "line": "What a horrible, horrible movie that was.", "order": 10760, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 10, "scene": 63, "line": "And now we get to remember it forever.", "order": 10761, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 10, "scene": 63, "line": "Thank you.", "order": 10762, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 10, "scene": 63, "line": "Thank you. [Both hug]", "order": 10763, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 10, "scene": 64, "line": "Hey.", "order": 10764, "speaker": "Second Cindy"},
  {"season": 3, "episode": 10, "scene": 64, "line": "Hey, hey, you. How are ya?", "order": 10765, "speaker": "Michael Scott"},
  {"season": 3, "episode": 10, "scene": 64, "line": "Hey. This party blows, so we're gonna leave.", "order": 10766, "speaker": "Second Cindy"},
  {"season": 3, "episode": 10, "scene": 64, "line": "No, no, no. Hey, you should stay because we are having fun and...", "order": 10767, "speaker": "Michael Scott"},
  {"season": 3, "episode": 10, "scene": 64, "line": "Cool...", "order": 10768, "speaker": "Second Cindy"},
  {"season": 3, "episode": 10, "scene": 64, "line": "Where do you wanna go?", "order": 10769, "speaker": "Michael Scott"},
  {"season": 3, "episode": 10, "scene": 64, "line": "I... We're just gonna take off.", "order": 10770, "speaker": "Second Cindy"},
  {"season": 3, "episode": 10, "scene": 64, "line": "I... you know what? I, OK, listen. I like you. I really like you. So much in fact, that I would like you to accompany me on a trip to Sandals, Jamaica.", "order": 10771, "speaker": "Michael Scott"},
  {"season": 3, "episode": 10, "scene": 64, "line": "No... I have school. [leaves with other waitress and bicycle]", "order": 10772, "speaker": "Second Cindy"},
  {"season": 3, "episode": 10, "scene": 64, "line": "You want help? OK... Merry Christmas.", "order": 10773, "speaker": "Michael Scott"},
  {"season": 3, "episode": 10, "scene": 64, "line": "Merry Christmas.", "order": 10774, "speaker": "Second Cindy"},
  {"season": 3, "episode": 10, "scene": 65, "line": "[singing] Lady, from the moment I saw you standing all alone. You gave all the love that I needed...", "order": 10775, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 10, "scene": 66, "line": "That waitress was the one.", "order": 10776, "speaker": "Michael Scott"},
  {"season": 3, "episode": 10, "scene": 66, "line": "No. She wasn't.", "order": 10777, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 10, "scene": 66, "line": "How can you be sure?", "order": 10778, "speaker": "Michael Scott"},
  {"season": 3, "episode": 10, "scene": 66, "line": "Well, for starters, I've known you as a couple since the beginning of the relationship, which was approximately [looks a watch] three hours ago.", "order": 10779, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 10, "scene": 66, "line": "Don't make fun... You're making fun of me.", "order": 10780, "speaker": "Michael Scott"},
  {"season": 3, "episode": 10, "scene": 66, "line": "Sorry.", "order": 10781, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 10, "scene": 66, "line": "I guess, I didn't know her very well. I marked her arm.", "order": 10782, "speaker": "Michael Scott"},
  {"season": 3, "episode": 10, "scene": 66, "line": "You what?", "order": 10783, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 10, "scene": 66, "line": "I, I put a mark on her arm. [Both are laughing] So I could tell them apart. I don't... I know, I know. I can't believe I gave her my bike!", "order": 10784, "speaker": "Michael Scott"},
  {"season": 3, "episode": 10, "scene": 66, "line": "Yeah.", "order": 10785, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 10, "scene": 66, "line": "Oh, why do I feel like crap?", "order": 10786, "speaker": "Michael Scott"},
  {"season": 3, "episode": 10, "scene": 66, "line": "You just had a rebound.", "order": 10787, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 10, "scene": 66, "line": "I had rebound? Yeah.", "order": 10788, "speaker": "Michael Scott"},
  {"season": 3, "episode": 10, "scene": 66, "line": "Yeah. Which, don't get me wrong, can be a really fun distraction, but, when it's over, you're left thinking about the girl you really like, the one that broke your heart.", "order": 10789, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 10, "scene": 67, "line": "I totally rebounded. Someone else shoots and I take the ball and I score. Well, I guess I didn't score and I'm not sure who's actually shooting, but, whatever. Doesn't matter. It's all good. Or as my ex might say. Domo arigato, Mr. Scott-o.", "order": 10790, "speaker": "Michael Scott"},
  {"season": 3, "episode": 10, "scene": 68, "line": "[singing, with Dwight holding up microphone] Little baby, parum pum pum pum. I am a poor boy too, parum pum pum pum. I have no gift to bring, parum pum pum pum. That's fit to give our King, parum pum pum pum..", "order": 10791, "speaker": "Angela Martin"},
  {"season": 3, "episode": 10, "scene": 69, "line": "[on phone] Yes, I just wanted to see if, uh you would like to come to Jamaica with me. There's this resort called Sandals. Really? OK. All right. I promise you won't be disappointed. Umm, It's all-inclusive...", "order": 10792, "speaker": "Michael Scott"},
  {"season": 3, "episode": 10, "scene": 70, "line": "[singing] ... Shall I play for you, parum pum pum pum [Oscar and Gil walk through door]", "order": 10793, "speaker": "Angela Martin"},
  {"season": 3, "episode": 10, "scene": 70, "line": "Too soon. [both turn back around and walk out]", "order": 10794, "speaker": "Oscar Martinez"},
  {"season": 3, "episode": 10, "scene": 70, "line": "[singing] I played my drum for Him, parum pum pum pum. I played my best for Him, parum pum pum pum, Rum pum pum pum, rum pum pum pum.", "order": 10795, "speaker": "Angela Martin"},
  {"season": 3, "episode": 10, "scene": 70, "line": "[singing along] pum pum pum pum pum pum pum", "order": 10796, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 10, "scene": 71, "line": "Bye Pam.", "order": 10797, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 10, "scene": 71, "line": "Night.", "order": 10798, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 10, "scene": 71, "line": "[to Pam] Oh, you know what? Sorry, forgot to tell you. I intercepted a transmission earlier and it seems that the CIA is gonna need Dwight down at their headquarters in Langley for training and an ice cream social with the other agents.", "order": 10799, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 10, "scene": 71, "line": "We should get him a bus ticket. [types on computer] To make his trip easier.", "order": 10800, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 10, "scene": 71, "line": "Oh no, that would be great.", "order": 10801, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 10, "scene": 71, "line": "It costs seventy five dollars.", "order": 10802, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 10, "scene": 71, "line": "Hmm... Well, maybe the CIA can send a helicopter?", "order": 10803, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 10, "scene": 71, "line": "Ohh... [laughs]", "order": 10804, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 10, "scene": 72, "line": "[Cell phone beeps] What the? [Reading text message] 'You have been compromised. Abort mission. Destroy phone.' Destroy phone? [Dwight throws phone off of the roof and walks away]", "order": 10805, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 10, "scene": 73, "line": "William Randolph Shakespeare once wrote in one of his plays, and I'm paraphrasing here, 'Love doth be poison.' Brilliant. And a lot of people don't give Shakespeare enough credit. They think it was somebody else. A-holes.", "order": 10806, "speaker": "Michael Scott"},
  {"season": 3, "episode": 10, "scene": 74, "line": "When Michael suggested canceling Christmas, I was outraged, not on my behalf, but on behalf of baby Jesus. I mean, would he cancel Christmas because he got dumped or he was two weeks behind on his party planning, on top of the fact that his cats were ill? I think not. I think baby Jesus would suck it up and plan his party.", "order": 10807, "speaker": "Angela Martin"},
  {"season": 3, "episode": 10, "scene": 75, "line": "We didn't have a party planning committee in Stamford. Somebody would just volunteer to run out to Carvel and pick up an ice cream cake. So the fact that they have a committee here, fascinating.", "order": 10808, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 10, "scene": 76, "line": "This is ridiculous. I... I've spent so much time, like I always do. And for that little trollop to come in here and throw her own party... Are you even listening to me?", "order": 10809, "speaker": "Angela Martin"},
  {"season": 3, "episode": 10, "scene": 76, "line": "Angela, what do you want me to do?", "order": 10810, "speaker": "Toby Flenderson"},
  {"season": 3, "episode": 10, "scene": 76, "line": "I want you to tell her to stop, Toby. Am I being clear?", "order": 10811, "speaker": "Angela Martin"},
  {"season": 3, "episode": 10, "scene": 76, "line": "[sighing] Look, it's a free country. If she wants to throw a party...", "order": 10812, "speaker": "Toby Flenderson"},
  {"season": 3, "episode": 10, "scene": 76, "line": "What are you...?", "order": 10813, "speaker": "Angela Martin"},
  {"season": 3, "episode": 10, "scene": 76, "line": "...using her own money...", "order": 10814, "speaker": "Toby Flenderson"},
  {"season": 3, "episode": 10, "scene": 76, "line": "And what...", "order": 10815, "speaker": "Angela Martin"},
  {"season": 3, "episode": 10, "scene": 76, "line": "...then she should be allowed to.", "order": 10816, "speaker": "Toby Flenderson"},
  {"season": 3, "episode": 10, "scene": 76, "line": "No she shouldn't.", "order": 10817, "speaker": "Angela Martin"},
  {"season": 3, "episode": 10, "scene": 76, "line": "The more the merrier.", "order": 10818, "speaker": "Toby Flenderson"},
  {"season": 3, "episode": 10, "scene": 76, "line": "[Angela slaps Toby on the face.] Stop it.", "order": 10819, "speaker": "Angela Martin"},
  {"season": 3, "episode": 10, "scene": 76, "line": "Oww.", "order": 10820, "speaker": "Toby Flenderson"},
  {"season": 3, "episode": 10, "scene": 76, "line": "I'm sorry. It wasn't my intention to hurt you, but I want to wake you up to the injustice that's going on right in front of your face.", "order": 10821, "speaker": "Angela Martin"},
  {"season": 3, "episode": 10, "scene": 76, "line": "That really hurt. I'm writing you up. [Angela makes an angry face] Hey, you are not allowed to touch other employees. Am I being clear?", "order": 10822, "speaker": "Toby Flenderson"},
  {"season": 3, "episode": 10, "scene": 76, "line": "Michael was so right about you. You are pathetic.", "order": 10823, "speaker": "Angela Martin"},
  {"season": 3, "episode": 10, "scene": 77, "line": "I don't care which party I go to. Once you've danced naked at a hash bonfire with the spirits of the dead, all parties seem pretty much the same.", "order": 10824, "speaker": "Creed Bratton"},
  {"season": 3, "episode": 10, "scene": 78, "line": "I think I'll go to Angela's party, because that's the party I know.", "order": 10825, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 10, "scene": 79, "line": "Where would you like the Rice Krispy Treat stars?", "order": 10826, "speaker": "Phyllis Lapin"},
  {"season": 3, "episode": 10, "scene": 79, "line": "They're Sugarplum fairy wands.", "order": 10827, "speaker": "Angela Martin"},
  {"season": 3, "episode": 10, "scene": 79, "line": "Sugarplum fairy wands.", "order": 10828, "speaker": "Phyllis Lapin"},
  {"season": 3, "episode": 10, "scene": 79, "line": "It's like you weren't even at the meeting.", "order": 10829, "speaker": "Angela Martin"},
  {"season": 3, "episode": 10, "scene": 80, "line": "I'm going to the party in the break room, because they have more chairs in there. If I have to stand around a long time, I get real unpleasant to be around.", "order": 10830, "speaker": "Stanley Hudson"},
  {"season": 3, "episode": 10, "scene": 81, "line": "[Phyllis puts down phone] Hmm hmm. [Phyllis gets up and goes to Angela's party]", "order": 10831, "speaker": "Angela Martin"},
  {"season": 3, "episode": 10, "scene": 82, "line": "No, no, no, no. You know what, I better hold off. I wanna make sure I don't dial Carol before I get drunk.", "order": 10832, "speaker": "Michael Scott"},
  {"season": 3, "episode": 10, "scene": 82, "line": "So, really, you're just calling her?", "order": 10833, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 10, "scene": 82, "line": "Yeah. You know what? I am. I'm gonna call her. That's a good idea.", "order": 10834, "speaker": "Michael Scott"},
  {"season": 3, "episode": 10, "scene": 82, "line": "[from across the table] Where's Michael going? Michael? Is he sick? Is he choking?", "order": 10835, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 10, "scene": 83, "line": "[on [phone] No, I don't think I'm asking too much. I think it's only fair that you tell me exactly what I did wrong. Okay. Uh huh. Well that... yeah. Uh huh. All right. Well, could you tell me something that I did", "order": 10836, "speaker": "Michael Scott"},
  {"season": 3, "episode": 10, "scene": 84, "line": "[knocks on office wall] Hey.", "order": 10837, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 10, "scene": 84, "line": "Hello, Ryan.", "order": 10838, "speaker": "Kelly Kapoor"},
  {"season": 3, "episode": 10, "scene": 84, "line": "Merry Christmas. [hands over a gift]", "order": 10839, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 10, "scene": 84, "line": "Oh, thank you. Do you always get presents for your ex-girlfriends?", "order": 10840, "speaker": "Kelly Kapoor"},
  {"season": 3, "episode": 10, "scene": 84, "line": "Okay, we're never technically... um is there.. Is there a problem?", "order": 10841, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 10, "scene": 84, "line": "Well, I didn't get you anything, because you have treated me inconsiderately, and I'm not gonna stand for that anymore.", "order": 10842, "speaker": "Kelly Kapoor"},
  {"season": 3, "episode": 10, "scene": 84, "line": "Um That's... That's fair. I'm sorry. Merry Christmas. [Ryan starts walking away.]", "order": 10843, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 10, "scene": 84, "line": "[runnning after him] Wait, Ryan, you're not mean. You're adorable. I'm so sorry. I got you a present, too. But then when I got to work, I didn't see anything on my desk from you, so I threw it away. And then Asuncion took the trash out, that's why I think it's in the dumpster. I'm so sorry. I'm the worst. [Kelly hugs Ryan]", "order": 10844, "speaker": "Kelly Kapoor"},
  {"season": 3, "episode": 10, "scene": 85, "line": "There's no fizz in this punch. Phyllis!", "order": 10845, "speaker": "Angela Martin"},
  {"season": 3, "episode": 10, "scene": 85, "line": "I'll just go get a ginger ale.", "order": 10846, "speaker": "Phyllis Lapin"},
  {"season": 3, "episode": 10, "scene": 85, "line": "No, I've got it, Phyllis. Stay. Have fun. Eat your wand.", "order": 10847, "speaker": "Angela Martin"},
  {"season": 3, "episode": 10, "scene": 86, "line": "[talking to Kelly in dumpster] Hey, Kelly, you know what? Throw my gift away, too.", "order": 10848, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 10, "scene": 86, "line": "[from inside dumpster] No, you paid money for that CD.", "order": 10849, "speaker": "Kelly Kapoor"},
  {"season": 3, "episode": 10, "scene": 86, "line": "I'm serious. It'll show that we don't care about material things.", "order": 10850, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 10, "scene": 86, "line": "I don't understand.", "order": 10851, "speaker": "Kelly Kapoor"},
  {"season": 3, "episode": 10, "scene": 86, "line": "Kelly, I'm so cold!", "order": 10852, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 10, "scene": 86, "line": "[starts to cry] You don't have to scream at me.", "order": 10853, "speaker": "Kelly Kapoor"},
  {"season": 3, "episode": 10, "scene": 86, "line": "Are you having fun in this relationship?", "order": 10854, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 10, "scene": 86, "line": "Sometimes.", "order": 10855, "speaker": "Kelly Kapoor"},
  {"season": 3, "episode": 10, "scene": 86, "line": "I don't know Kelly, sometimes I look at us and I think...", "order": 10856, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 10, "scene": 86, "line": "[crying] Don't dump me while I'm in the dumpster. Just go back inside if you're so cold. I'll just stay in the dumpster. [Ryan smirks]", "order": 10857, "speaker": "Kelly Kapoor"},
  {"season": 3, "episode": 10, "scene": 87, "line": "[both inside dumpster] Did you look over here?", "order": 10858, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 10, "scene": 87, "line": "Who's eating all these apples?", "order": 10859, "speaker": "Kelly Kapoor"},
  {"season": 3, "episode": 10, "scene": 87, "line": "Kelly.", "order": 10860, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 10, "scene": 87, "line": "And what are these? Are... Why are there egg yolks in here? Aren't you supposed to eat the yolks?", "order": 10861, "speaker": "Kelly Kapoor"},
  {"season": 3, "episode": 10, "scene": 87, "line": "Michael was on an egg-yolk diet. He's so weird.", "order": 10862, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 10, "scene": 87, "line": "Do you think I should diet?", "order": 10863, "speaker": "Kelly Kapoor"},
  {"season": 3, "episode": 10, "scene": 87, "line": "No, you're perfect. [Kelly gasps] What? Did you find it?", "order": 10864, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 10, "scene": 87, "line": "No, I'm scared.", "order": 10865, "speaker": "Kelly Kapoor"},
  {"season": 3, "episode": 10, "scene": 87, "line": "All of a sudden?", "order": 10866, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 10, "scene": 87, "line": "Uh huh. Hold me.", "order": 10867, "speaker": "Kelly Kapoor"},
  {"season": 3, "episode": 10, "scene": 88, "line": "Oh, honey, here's Stanley.", "order": 10868, "speaker": "Michael Scott"},
  {"season": 3, "episode": 10, "scene": 88, "line": "Hi.", "order": 10869, "speaker": "Second Cindy"},
  {"season": 3, "episode": 10, "scene": 88, "line": "He is also in an interracial relationship, so, you know, if you have any problems, you can always call him.", "order": 10870, "speaker": "Michael Scott"},
  {"season": 3, "episode": 10, "scene": 88, "line": "Oh, you're gonna face far greater problems than I can possibly help you with.", "order": 10871, "speaker": "Stanley Hudson"},
  {"season": 3, "episode": 10, "scene": 88, "line": "Sounds good. Okay, let's go.", "order": 10872, "speaker": "Michael Scott"},
  {"season": 3, "episode": 11, "scene": 1, "line": "All right, let's get started. Umm... Oh, first off, we're supposed to be pushing cardstock this week. So... let's push cardstock this week. Uh, also... [to Dwight] what is this?", "order": 10873, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 11, "scene": 1, "line": "Tape recorder.", "order": 10874, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 11, "scene": 1, "line": "For what?", "order": 10875, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 11, "scene": 1, "line": "For recording. Michael is on vacation and he's asked me to record all meetings and to type up the transcripts.", "order": 10876, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 11, "scene": 1, "line": "OK. Uh, Karen, any news from that law firm?", "order": 10877, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 11, "scene": 1, "line": "Yeah, the deal closed yesterday, it's the six month commitment.", "order": 10878, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 11, "scene": 1, "line": "Oh my God, Dwight, what're you doing?", "order": 10879, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 11, "scene": 1, "line": "What?", "order": 10880, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 11, "scene": 1, "line": "You're not allowed to take off your pants in the middle of the office.", "order": 10881, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 11, "scene": 1, "line": "I'm not.", "order": 10882, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 11, "scene": 1, "line": "Dwight, you know what, just back up, okay, that's making me uncomfortable. This is sexual harassment, by the way. Oh my God! He's got a knife!", "order": 10883, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 11, "scene": 1, "line": "I do not have a knife!", "order": 10884, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 11, "scene": 1, "line": "No, let the record show that Dwight K. Schrute is now completely nude and is holding a plastic knife to Stanley's neck?!", "order": 10885, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 11, "scene": 1, "line": "[leans into tape recorder] Let the record show that Jim Halpert is a liar!", "order": 10886, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 11, "scene": 1, "line": "[picks up tape recorder and speaks into it] Dwight Schrute is now wearing a baby's bonnet.", "order": 10887, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 11, "scene": 1, "line": "Give me it. I am not.", "order": 10888, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 11, "scene": 1, "line": "Oh, Jim Carrey just walked in! Dwight, get his autograph for Michael quickly...", "order": 10889, "speaker": "Phyllis Lapin"},
  {"season": 3, "episode": 11, "scene": 1, "line": "Jim Carrey did not just walk in, OK.", "order": 10890, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 11, "scene": 1, "line": "Dwight, what is that on your stomach? Is that a Muppet Babies tattoo?", "order": 10891, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 11, "scene": 1, "line": "Oh my God, Karen, you're right, that is Animal from the Muppet Babies.", "order": 10892, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 11, "scene": 1, "line": "You can't see... You can't see my stomach.", "order": 10893, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 11, "scene": 1, "line": "I am now chopping off Phyllis' head with a chainsaw! ... Rin-in-in-in-in-in!", "order": 10894, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 11, "scene": 2, "line": "Hey, mon!", "order": 10895, "speaker": "Michael Scott"},
  {"season": 3, "episode": 11, "scene": 2, "line": "Hey. You have a bunch of messages and... [sees Michael shaking head with beads in hair] that's nice. Hannah quit while you were gone. I guess she memo-ed to file some complaints she had about being a working mother? And so you might also have to be deposed.", "order": 10896, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 11, "scene": 2, "line": "Blah, blah, blah, blah, blah, blah, blah, blah. Relax.", "order": 10897, "speaker": "Michael Scott"},
  {"season": 3, "episode": 11, "scene": 2, "line": "OK.", "order": 10898, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 11, "scene": 2, "line": "Just relax, OK? I'll get to all of it later.", "order": 10899, "speaker": "Michael Scott"},
  {"season": 3, "episode": 11, "scene": 2, "line": "It's kind of serious.", "order": 10900, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 11, "scene": 2, "line": "Aren't you going to ask me how Jamaica was? Say it. Ask me.", "order": 10901, "speaker": "Michael Scott"},
  {"season": 3, "episode": 11, "scene": 2, "line": "How was Jamaica?", "order": 10902, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 11, "scene": 2, "line": "It was", "order": 10903, "speaker": "Michael Scott"},
  {"season": 3, "episode": 11, "scene": 2, "line": "Oh, Michael, I'm glad you're here.", "order": 10904, "speaker": "Stanley Hudson"},
  {"season": 3, "episode": 11, "scene": 2, "line": "Stanley. You know what? It is really good to see you, too.", "order": 10905, "speaker": "Michael Scott"},
  {"season": 3, "episode": 11, "scene": 2, "line": "My bonus check was a hundred dollars less than you promised.", "order": 10906, "speaker": "Stanley Hudson"},
  {"season": 3, "episode": 11, "scene": 2, "line": "OK, well payroll is in charge of all that.", "order": 10907, "speaker": "Michael Scott"},
  {"season": 3, "episode": 11, "scene": 2, "line": "They said I should talk to you.", "order": 10908, "speaker": "Stanley Hudson"},
  {"season": 3, "episode": 11, "scene": 2, "line": "Well, I am just getting settled in. So, I'm gonna...", "order": 10909, "speaker": "Michael Scott"},
  {"season": 3, "episode": 11, "scene": 2, "line": "I am not doing a lick more work until I get my full bonus check.", "order": 10910, "speaker": "Stanley Hudson"},
  {"season": 3, "episode": 11, "scene": 2, "line": "You are not as much fun as your Jamaican brothers... mon.", "order": 10911, "speaker": "Michael Scott"},
  {"season": 3, "episode": 11, "scene": 3, "line": "You want to talk about it?", "order": 10912, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 11, "scene": 3, "line": "Nope.", "order": 10913, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 11, "scene": 4, "line": "I still haven't found an apartment yet. I'm living in a hotel. Yesterday, I saw a 'for rent' sign down the street from Jim and he said he didn't think it'd be such a good idea. He said it would be like we were living together. In different houses. Two blocks away.", "order": 10914, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 11, "scene": 5, "line": "Feelin' hot, hot, hot! [playing conch shell] Feelin' hot, hot, hot! Feelin' hot, hot, hot! Feelin' hot, hot, hot! That's all I know so far, but I'm gonna keep practicing.", "order": 10915, "speaker": "Michael Scott"},
  {"season": 3, "episode": 11, "scene": 5, "line": "That's good. [Michael continues to play conch shell]", "order": 10916, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 11, "scene": 5, "line": "You know, I had never been out of the country before now?", "order": 10917, "speaker": "Michael Scott"},
  {"season": 3, "episode": 11, "scene": 5, "line": "Huh.", "order": 10918, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 11, "scene": 5, "line": "Got to see how Jamaicans live. It is great, you know. They just relax, they party all the time.", "order": 10919, "speaker": "Michael Scott"},
  {"season": 3, "episode": 11, "scene": 5, "line": "It's kind of an impoverished country.", "order": 10920, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 11, "scene": 5, "line": "Yeah... Gosh. Great. You know what, Pam? Make a note. I want us all to start having pina coladas every day at three.", "order": 10921, "speaker": "Michael Scott"},
  {"season": 3, "episode": 11, "scene": 5, "line": "But you can't today, we're doing inventory.", "order": 10922, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 11, "scene": 5, "line": "Inventory's at the end of December.", "order": 10923, "speaker": "Michael Scott"},
  {"season": 3, "episode": 11, "scene": 5, "line": "We couldn't do it without you, so we postponed.", "order": 10924, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 11, "scene": 5, "line": "I specifically went on vacation so I would miss it.", "order": 10925, "speaker": "Michael Scott"},
  {"season": 3, "episode": 11, "scene": 6, "line": "Inventory is boring. In the islands, they don't make you do stuff like take inventory. Why do you think so many businesses move to the Caymans?", "order": 10926, "speaker": "Michael Scott"},
  {"season": 3, "episode": 11, "scene": 7, "line": "Tonight, we are going to have an inventory luau. I want to bring back a little slice of paradise to the Dunder Mifflin warehouse inventory. So, Party Planning Committee, get on it.", "order": 10927, "speaker": "Michael Scott"},
  {"season": 3, "episode": 11, "scene": 7, "line": "By the end of the day? That's impossible.", "order": 10928, "speaker": "Angela Martin"},
  {"season": 3, "episode": 11, "scene": 7, "line": "The Jamaicans don't have a word for 'impossible.'", "order": 10929, "speaker": "Michael Scott"},
  {"season": 3, "episode": 11, "scene": 7, "line": "Yep, it's English, it's 'impossible.'", "order": 10930, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 11, "scene": 7, "line": "Michael, there's no way we can do it in time.", "order": 10931, "speaker": "Angela Martin"},
  {"season": 3, "episode": 11, "scene": 8, "line": "How hard is a luau? All you need are some grass skirts, pineapple, poi, tiki torches, suckling pig, some fire dancers. That's all you need.", "order": 10932, "speaker": "Michael Scott"},
  {"season": 3, "episode": 11, "scene": 9, "line": "Come on in. Settle in. Settling. Settling... and settled. Good, there is something I would like to show everybody. See this sign? [points to a TV monitor of a picture from Jamaica and reads] 'No Shirt, No Shoes, No Problem.' This is an attitude I would like all of you to have right here. So what, if we have to stay late to do inventory? No problem.", "order": 10933, "speaker": "Michael Scott"},
  {"season": 3, "episode": 11, "scene": 9, "line": "Oh my God. Is that Jan? [points to same monitor]", "order": 10934, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 11, "scene": 9, "line": "What? Where?", "order": 10935, "speaker": "Everybody"},
  {"season": 3, "episode": 11, "scene": 9, "line": "On the left.", "order": 10936, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 11, "scene": 9, "line": "Oh yeah, oh my God.", "order": 10937, "speaker": "Everybody"},
  {"season": 3, "episode": 11, "scene": 9, "line": "No, no, no. No, that's a German woman named Urkel Grue.", "order": 10938, "speaker": "Michael Scott"},
  {"season": 3, "episode": 11, "scene": 10, "line": "Jan told me to play it cool and not tell anybody because it can get us both in trouble. So officially, I did not see her. But I did see Jan there. In our room. At night. And in the morning. That's all I'm gonna say. Sex. We had sex. I had sex with her. I had sex with Jan.", "order": 10939, "speaker": "Michael Scott"},
  {"season": 3, "episode": 11, "scene": 11, "line": "[on phone] Hey dude. I just got back from Jamaica.", "order": 10940, "speaker": "Michael Scott"},
  {"season": 3, "episode": 11, "scene": 11, "line": "Big whoop! I was in Hot-lanta. That whole town is whacked.", "order": 10941, "speaker": "Todd Packer"},
  {"season": 3, "episode": 11, "scene": 11, "line": "Yeah, that sounds amazing. You know what? The lady Jan Levinson wanted to go to Montego Bay.", "order": 10942, "speaker": "Michael Scott"},
  {"season": 3, "episode": 11, "scene": 11, "line": "You took the ice queen? I don't buy it.", "order": 10943, "speaker": "Todd Packer"},
  {"season": 3, "episode": 11, "scene": 11, "line": "Well I'm looking at a photo, right now. And I'm telling 'ya, could be in Maxim.", "order": 10944, "speaker": "Michael Scott"},
  {"season": 3, "episode": 11, "scene": 11, "line": "They wouldn't give you a subscription to Maxim.", "order": 10945, "speaker": "Todd Packer"},
  {"season": 3, "episode": 11, "scene": 11, "line": "Oh no?", "order": 10946, "speaker": "Michael Scott"},
  {"season": 3, "episode": 11, "scene": 11, "line": "No.", "order": 10947, "speaker": "Todd Packer"},
  {"season": 3, "episode": 11, "scene": 11, "line": "OK. Well, check this out. I am sending you some email. You got it?", "order": 10948, "speaker": "Michael Scott"},
  {"season": 3, "episode": 11, "scene": 11, "line": "Well, no. I got nothing.", "order": 10949, "speaker": "Todd Packer"},
  {"season": 3, "episode": 11, "scene": 11, "line": "Check it again. Hit refresh.", "order": 10950, "speaker": "Michael Scott"},
  {"season": 3, "episode": 11, "scene": 11, "line": "Yeah, Mike, still nothing.", "order": 10951, "speaker": "Todd Packer"},
  {"season": 3, "episode": 11, "scene": 11, "line": "OK, wait a second. I sent it to you at... [reading computer screen] Packer@DunderMifflin.com.", "order": 10952, "speaker": "Michael Scott"},
  {"season": 3, "episode": 11, "scene": 11, "line": "Wait, I just got it from somebody else. Wow. This is hot. Damn! How do I get you out of this picture?", "order": 10953, "speaker": "Todd Packer"},
  {"season": 3, "episode": 11, "scene": 12, "line": "Darryl?! Hey. Hi. Where's Darryl?", "order": 10954, "speaker": "Michael Scott"},
  {"season": 3, "episode": 11, "scene": 12, "line": "He's in the office.", "order": 10955, "speaker": "Roy Anderson"},
  {"season": 3, "episode": 11, "scene": 12, "line": "OK, Hey, man, how's it going?", "order": 10956, "speaker": "Michael Scott"},
  {"season": 3, "episode": 11, "scene": 12, "line": "All right, what's up Mike?", "order": 10957, "speaker": "Darryl Philbin"},
  {"season": 3, "episode": 11, "scene": 12, "line": "That's great, OK. Um, so did you get an email from me?", "order": 10958, "speaker": "Michael Scott"},
  {"season": 3, "episode": 11, "scene": 12, "line": "Yup.", "order": 10959, "speaker": "Darryl Philbin"},
  {"season": 3, "episode": 11, "scene": 12, "line": "OK. Well, that was supposed to go to Packer, not 'packaging.' Did you already, um, forward to a whole bunch of people?", "order": 10960, "speaker": "Michael Scott"},
  {"season": 3, "episode": 11, "scene": 12, "line": "Uh huh.", "order": 10961, "speaker": "Darryl Philbin"},
  {"season": 3, "episode": 11, "scene": 12, "line": "OK. Um, well, did you get the second email that I sent? Explaining that the first email was a mistake and that you should delete it.", "order": 10962, "speaker": "Michael Scott"},
  {"season": 3, "episode": 11, "scene": 12, "line": "Yup.", "order": 10963, "speaker": "Darryl Philbin"},
  {"season": 3, "episode": 11, "scene": 12, "line": "And you sent that out to everyone?", "order": 10964, "speaker": "Michael Scott"},
  {"season": 3, "episode": 11, "scene": 12, "line": "Mike, I'm very busy down here. [eats chicken]", "order": 10965, "speaker": "Darryl Philbin"},
  {"season": 3, "episode": 11, "scene": 13, "line": "Yikes.", "order": 10966, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 11, "scene": 13, "line": "Already sent it to you my friend.", "order": 10967, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 11, "scene": 13, "line": "Fantastic.", "order": 10968, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 11, "scene": 13, "line": "Boring. Call me if she rolls over.", "order": 10969, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 11, "scene": 14, "line": "Hey.", "order": 10970, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 11, "scene": 14, "line": "Hey.", "order": 10971, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 11, "scene": 14, "line": "You OK?", "order": 10972, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 11, "scene": 14, "line": "Yeah.", "order": 10973, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 11, "scene": 14, "line": "You sure?", "order": 10974, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 11, "scene": 14, "line": "Yeah. Yes. Um, I'm just in this, like, stupid fight with Karen.", "order": 10975, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 11, "scene": 14, "line": "Oh. You want to talk about it?", "order": 10976, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 11, "scene": 14, "line": "Really?", "order": 10977, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 11, "scene": 15, "line": "I have a special assignment for you.", "order": 10978, "speaker": "Michael Scott"},
  {"season": 3, "episode": 11, "scene": 15, "line": "Who's the target?", "order": 10979, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 11, "scene": 15, "line": "A sensitive email has been released to the office. It contains a file, a picture. The file name is 'Jamaican Jan Sun Princess.'", "order": 10980, "speaker": "Michael Scott"},
  {"season": 3, "episode": 11, "scene": 15, "line": "What's it of?", "order": 10981, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 11, "scene": 15, "line": "Not important.", "order": 10982, "speaker": "Michael Scott"},
  {"season": 3, "episode": 11, "scene": 15, "line": "Unless you're willing to tell me everything, I cannot accept this assignment.", "order": 10983, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 11, "scene": 15, "line": "OK, forget it.", "order": 10984, "speaker": "Michael Scott"},
  {"season": 3, "episode": 11, "scene": 15, "line": "OK, I accept it.", "order": 10985, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 11, "scene": 16, "line": "So, I dunno, I just feel likes we've been dating a month, right? Same street. I think that might be a little close. A little much.", "order": 10986, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 11, "scene": 16, "line": "Hmm.", "order": 10987, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 11, "scene": 16, "line": "Hmm, what?", "order": 10988, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 11, "scene": 16, "line": "How far away does she live now, like ten minutes?", "order": 10989, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 11, "scene": 16, "line": "Yeah, I guess.", "order": 10990, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 11, "scene": 16, "line": "Honestly, I think you should go easy on her.", "order": 10991, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 11, "scene": 17, "line": "No, I didn't mind helping Jim with his problem. That's what friends do. I help Phyllis all the time. Just yesterday, I untangled a piece of tape from her hair. So, yeah.", "order": 10992, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 11, "scene": 18, "line": "Hey, thanks a lot.", "order": 10993, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 11, "scene": 18, "line": "Oh, don't worry about it. I mean, it's better than listening to Michael play a conch shell... which is what I was doing. Oh, also, Michael went to Jamaica with Jan!", "order": 10994, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 11, "scene": 18, "line": "Oh, yeah, How have we not talked about this already? I mean what happened there? Kidnapping? [both start laughing]", "order": 10995, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 11, "scene": 19, "line": "Hey I need to talk to you right now.", "order": 10996, "speaker": "Toby Flenderson"},
  {"season": 3, "episode": 11, "scene": 19, "line": "Not now, not ever.", "order": 10997, "speaker": "Michael Scott"},
  {"season": 3, "episode": 11, "scene": 19, "line": "About you and Jan.", "order": 10998, "speaker": "Toby Flenderson"},
  {"season": 3, "episode": 11, "scene": 19, "line": "Aww, none of your business.", "order": 10999, "speaker": "Michael Scott"},
  {"season": 3, "episode": 11, "scene": 19, "line": "Wish it were true, but it, it uh, seems from that photo that you took, you've entered into an intimate relationship.", "order": 11000, "speaker": "Toby Flenderson"},
  {"season": 3, "episode": 11, "scene": 19, "line": "That photo is my personal property and if you are telling me you went on my computer and stole that photo, then I am going to call the cops.", "order": 11001, "speaker": "Michael Scott"},
  {"season": 3, "episode": 11, "scene": 19, "line": "Michael, nine different people emailed me that photo, including my ex-wife... we don't talk now.", "order": 11002, "speaker": "Toby Flenderson"},
  {"season": 3, "episode": 11, "scene": 19, "line": "This is probably the icebreaker you need.", "order": 11003, "speaker": "Michael Scott"},
  {"season": 3, "episode": 11, "scene": 19, "line": "You know, for your own protection, you should disclose the relationship to HR.", "order": 11004, "speaker": "Toby Flenderson"},
  {"season": 3, "episode": 11, "scene": 19, "line": "I bet you would love all the details, wouldn't you? Skeevy little perv.", "order": 11005, "speaker": "Michael Scott"},
  {"season": 3, "episode": 11, "scene": 19, "line": "All right, if you're having a relationship with your superior, you must disclose it.", "order": 11006, "speaker": "Toby Flenderson"},
  {"season": 3, "episode": 11, "scene": 19, "line": "No, no, no. I am not dating Jan. She was very clear about that. Just two like souls having a romantic time in the most romantic place on earth. Got enough, weirdo?", "order": 11007, "speaker": "Michael Scott"},
  {"season": 3, "episode": 11, "scene": 19, "line": "All right, thanks Michael.", "order": 11008, "speaker": "Toby Flenderson"},
  {"season": 3, "episode": 11, "scene": 19, "line": "OK.", "order": 11009, "speaker": "Michael Scott"},
  {"season": 3, "episode": 11, "scene": 20, "line": "OK, we only have three hours people to plan a whole luau, and you're not helping.", "order": 11010, "speaker": "Angela Martin"},
  {"season": 3, "episode": 11, "scene": 20, "line": "What are the ingredients of poi?", "order": 11011, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 11, "scene": 20, "line": "I called every grocery store in Scranton, and no one sells whole pigs.", "order": 11012, "speaker": "Phyllis Lapin"},
  {"season": 3, "episode": 11, "scene": 20, "line": "Did you try the petting zoo?", "order": 11013, "speaker": "Angela Martin"},
  {"season": 3, "episode": 11, "scene": 21, "line": "[on phone] Dunder Mifflin, this is Pam. Just a second. Michael, it's Jan on the phone for you.", "order": 11014, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 11, "scene": 21, "line": "Oh, God, no. No, no! Hang up! Hang up! Tell her I'm not here. Tell... tell her, I ran out for cash. I hit a deer. I hit a deer with my car. Tell her I hit a cat. Tell her I hit a cat.", "order": 11015, "speaker": "Michael Scott"},
  {"season": 3, "episode": 11, "scene": 21, "line": "He'll call you back. OK, great.", "order": 11016, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 11, "scene": 21, "line": "She bought it? [Pam nods] OK. OK.", "order": 11017, "speaker": "Michael Scott"},
  {"season": 3, "episode": 11, "scene": 21, "line": "[to Jim and Ryan] Michael hit a deer?", "order": 11018, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 11, "scene": 22, "line": "Michael! Michael! [pokes head through blinds in Michael's office] There's an emergency in the warehouse.", "order": 11019, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 11, "scene": 22, "line": "There an accident? Somebody hurt?", "order": 11020, "speaker": "Michael Scott"},
  {"season": 3, "episode": 11, "scene": 22, "line": "No, it's... involves the photograph.", "order": 11021, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 11, "scene": 22, "line": "Oh. God. No, no, no, no, no.", "order": 11022, "speaker": "Michael Scott"},
  {"season": 3, "episode": 11, "scene": 23, "line": "[starts clapping] Mike, you are a rock star, man. You are the man! Well done.", "order": 11023, "speaker": "Roy Anderson"},
  {"season": 3, "episode": 11, "scene": 23, "line": "All right. [continued clapping and cheering for Michael]", "order": 11024, "speaker": "Michael Scott"},
  {"season": 3, "episode": 11, "scene": 23, "line": "That corporate booty, he likes to hit it!", "order": 11025, "speaker": "Roy Anderson"},
  {"season": 3, "episode": 11, "scene": 24, "line": "Hello. Hi. Attention everyone. Um, apparently, there is an email circulating around that contains a very PG-13 rating picture of me and a woman---", "order": 11026, "speaker": "Michael Scott"},
  {"season": 3, "episode": 11, "scene": 24, "line": "Jan.", "order": 11027, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 11, "scene": 24, "line": "No, Kevin. A woman. Maybe Jan, maybe...", "order": 11028, "speaker": "Michael Scott"},
  {"season": 3, "episode": 11, "scene": 24, "line": "Urkel Grue.", "order": 11029, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 11, "scene": 24, "line": "My point is that, if you get it, I would ask that you just delete it. Sight unseen. Let's be professional, all right?", "order": 11030, "speaker": "Michael Scott"},
  {"season": 3, "episode": 11, "scene": 25, "line": "Feelin'... feelin' hot, hot, hot! [plays conch shell]", "order": 11031, "speaker": "Michael Scott"},
  {"season": 3, "episode": 11, "scene": 25, "line": "Hey.", "order": 11032, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 11, "scene": 25, "line": "Hey.", "order": 11033, "speaker": "Michael Scott"},
  {"season": 3, "episode": 11, "scene": 25, "line": "What are you doing out here?", "order": 11034, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 11, "scene": 25, "line": "Island living. You know?", "order": 11035, "speaker": "Michael Scott"},
  {"season": 3, "episode": 11, "scene": 25, "line": "Jan called. She's coming in later to talk to you.", "order": 11036, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 11, "scene": 25, "line": "Did she say what it's about?", "order": 11037, "speaker": "Michael Scott"},
  {"season": 3, "episode": 11, "scene": 25, "line": "That's all she said.", "order": 11038, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 11, "scene": 25, "line": "Ole ole - ole ole... Ole ole - ole ole. People in the party - hot hot hot [playing conch shell]", "order": 11039, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 11, "scene": 26, "line": "Uh, I think you dropped this [hands over piece of paper]", "order": 11040, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 11, "scene": 26, "line": "You sure?", "order": 11041, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 11, "scene": 26, "line": "Definitely.", "order": 11042, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 11, "scene": 27, "line": "I have disconnected the office T1 line. I have ordered that that [referring to large picture of Michael and Jan in Jamaica on the wall] be taken down and destroyed all print outs from the bathroom.", "order": 11043, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 11, "scene": 27, "line": "There are copies in the bathroom?", "order": 11044, "speaker": "Michael Scott"},
  {"season": 3, "episode": 11, "scene": 27, "line": "There were. A lot of them.", "order": 11045, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 11, "scene": 27, "line": "All right.", "order": 11046, "speaker": "Michael Scott"},
  {"season": 3, "episode": 11, "scene": 28, "line": "I think I owe you one.", "order": 11047, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 11, "scene": 28, "line": "Sorry?", "order": 11048, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 11, "scene": 28, "line": "For talking sense into Halpert. The Day's Inn room 228 was starting to get really depressing.", "order": 11049, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 11, "scene": 28, "line": "Oh, yeah, no. Don't worry about it. I mean, he was being ridiculous.", "order": 11050, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 11, "scene": 28, "line": "Yeah, but... thanks. Seriously.", "order": 11051, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 11, "scene": 28, "line": "Sure.", "order": 11052, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 11, "scene": 29, "line": "Oh, yes! Yes! What'd I tell you? I knew he'd turn up [holds up an iPod inside speakers] You see that? This is the greatest night of my life.", "order": 11053, "speaker": "Darryl Philbin"},
  {"season": 3, "episode": 11, "scene": 30, "line": "Who did this to you? [refers to Pam crying] Where is he?", "order": 11054, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 11, "scene": 30, "line": "What? No, it's not... it's nothing.", "order": 11055, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 11, "scene": 30, "line": "[takes off coat to tie around waist] It's hot in here.", "order": 11056, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 11, "scene": 30, "line": "Yeah.", "order": 11057, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 11, "scene": 30, "line": "Yeah.", "order": 11058, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 11, "scene": 30, "line": "[Dwight hands out handkerchief] Thanks. You don't need to stay here.", "order": 11059, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 11, "scene": 30, "line": "I know. [puts arm around Pam who continues to cry] So you're PMSing pretty bad, huh?", "order": 11060, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 11, "scene": 31, "line": "Hello everyone. Hello Michael.", "order": 11061, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 11, "scene": 31, "line": "Guh.", "order": 11062, "speaker": "Michael Scott"},
  {"season": 3, "episode": 11, "scene": 31, "line": "Hi Jan, you look... tan.", "order": 11063, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 11, "scene": 31, "line": "I was in Scottsdale visiting my sister.", "order": 11064, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 11, "scene": 31, "line": "Yeah. How was it?", "order": 11065, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 11, "scene": 31, "line": "Very sunny. Family's important. Michael, I would like to speak with you, in your office.", "order": 11066, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 11, "scene": 31, "line": "OK, yup.", "order": 11067, "speaker": "Michael Scott"},
  {"season": 3, "episode": 11, "scene": 32, "line": "Why am I here, Michael?", "order": 11068, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 11, "scene": 32, "line": "I...", "order": 11069, "speaker": "Michael Scott"},
  {"season": 3, "episode": 11, "scene": 32, "line": "In the last year, I've gone through a divorce, an identity theft, a husband who would not... communicate. This is nether here or there. My psychiatrist thinks that I have some self-destructive tendencies and that for once, I should indulge them. You following me?", "order": 11070, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 11, "scene": 32, "line": "I... I... Yes.", "order": 11071, "speaker": "Michael Scott"},
  {"season": 3, "episode": 11, "scene": 32, "line": "I think I owe it to myself to find some kind of happiness, you know? I mean, even, even if it means... lowering my expectations or, or redefining the word, itself.", "order": 11072, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 11, "scene": 32, "line": "OK, yeah.", "order": 11073, "speaker": "Michael Scott"},
  {"season": 3, "episode": 11, "scene": 32, "line": "This is the kind of thing, you know? I am... attracted to you. I, I don't know why, I... but, I am. And, I need to follow my instincts. At least that's what Dr. Perry thinks.", "order": 11074, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 11, "scene": 32, "line": "Who is Dr. Perry? I...", "order": 11075, "speaker": "Michael Scott"},
  {"season": 3, "episode": 11, "scene": 32, "line": "This is the point, OK? You're wrong for me. In... In... every way. But I still find myself wanting to... be with you.", "order": 11076, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 11, "scene": 32, "line": "And I, to you, in addition, feel the same feelings that you are as well.", "order": 11077, "speaker": "Michael Scott"},
  {"season": 3, "episode": 11, "scene": 32, "line": "Good, good.", "order": 11078, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 11, "scene": 32, "line": "So, umm... thanks for coming by. So, I, uh...", "order": 11079, "speaker": "Michael Scott"},
  {"season": 3, "episode": 11, "scene": 32, "line": "Well, good, OK. Thank you... for taking the time...", "order": 11080, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 11, "scene": 32, "line": "Well thanks for coming over, I appreciate - [Jan leans over and kisses Michael passionately]", "order": 11081, "speaker": "Michael Scott"},
  {"season": 3, "episode": 11, "scene": 32, "line": "Wait 15 minutes, find an excuse, and meet me at your condo.", "order": 11082, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 11, "scene": 32, "line": "Jan, you... complete... me.", "order": 11083, "speaker": "Michael Scott"},
  {"season": 3, "episode": 11, "scene": 32, "line": "Oh, God.", "order": 11084, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 11, "scene": 33, "line": "Hey, remember when we were planning our honeymoon and you wanted to go to Hawaii and I wanted to go to Mexico?", "order": 11085, "speaker": "Roy Anderson"},
  {"season": 3, "episode": 11, "scene": 33, "line": "Yeah.", "order": 11086, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 11, "scene": 33, "line": "I was definitely right. [both laugh] Oh, brother.", "order": 11087, "speaker": "Roy Anderson"},
  {"season": 3, "episode": 11, "scene": 34, "line": "What am I going to do? [Refers to large pictures of Jan and Michael] I'm gonna hang it up at home. I don't have a lot of art. [smirks]", "order": 11088, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 11, "scene": 35, "line": "I just had the best, most romantic week of my life in Sandals, Jamaica. Went with a lady, and we laid on the beach and drank fruity drinks for free, because it was all-inclusive. And then I flew back to Scranton, and then they lost one of my bags.", "order": 11089, "speaker": "Michael Scott"},
  {"season": 3, "episode": 11, "scene": 36, "line": "Hey, Mister tally man, tally me banana, six hand, seven hand, eight hand, BRUNCH! [snickers] So delicious. Dinner, too. And every morning I would watch the sunrise and I would eat a mango. That is how I want to feel all the time. It's called island living. You know, how they say take a chill pill? In Jamaica, I took one. Guy on the beach sold it to me for $40. And after I finished vomiting, I was more relaxed than I ever have been. Lost six pounds, too. That's how I want every day to be.", "order": 11090, "speaker": "Michael Scott"},
  {"season": 3, "episode": 11, "scene": 37, "line": "Michael sends me a postcard from every vacation he goes on. Atlantic City, he wishes I was here. Busch Gardens, Virginia, all I got was this lousy postcard. And this time, I'm Jamaican-him-crazy. I don't know how far away he thought he was, because he put $10 worth of stamps on this.", "order": 11091, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 11, "scene": 38, "line": "Hello, you're back.", "order": 11092, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 11, "scene": 38, "line": "Yeah, mon. Me just got back to the office.", "order": 11093, "speaker": "Michael Scott"},
  {"season": 3, "episode": 11, "scene": 38, "line": "While you were gone...", "order": 11094, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 11, "scene": 38, "line": "Me had an i-rey time.", "order": 11095, "speaker": "Michael Scott"},
  {"season": 3, "episode": 11, "scene": 38, "line": "While you were gone, Jim glued my desk drawers together. He changed my voicemail so that my voice sounded like a chipmunk. He told me that we had a meeting at 4:00 AM, and I was the only one who showed up.", "order": 11096, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 11, "scene": 38, "line": "Dwight, please, please.", "order": 11097, "speaker": "Michael Scott"},
  {"season": 3, "episode": 11, "scene": 38, "line": "He turned over my...", "order": 11098, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 11, "scene": 38, "line": "I just got back from vacation, mon, all right? So chill. Let's don't... Don't harsh my mellow, mon.", "order": 11099, "speaker": "Michael Scott"},
  {"season": 3, "episode": 11, "scene": 39, "line": "I took a vacation once. Never again. While I was away, Jim had my desk shipped to me in Roswell.", "order": 11100, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 11, "scene": 40, "line": "Presents. I got presents for everybody. Dwight, a genuine Rastafarian wig.", "order": 11101, "speaker": "Michael Scott"},
  {"season": 3, "episode": 11, "scene": 40, "line": "Unbelievable.", "order": 11102, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 11, "scene": 40, "line": "Andy, I got you a genuine Jamaican T-shirt. They love Milli Vanilli down there.", "order": 11103, "speaker": "Michael Scott"},
  {"season": 3, "episode": 11, "scene": 40, "line": "I give thanks, mon.", "order": 11104, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 11, "scene": 40, "line": "You are so welcome, mon. Angela, you just got leied. [puts lei on Angela]", "order": 11105, "speaker": "Michael Scott"},
  {"season": 3, "episode": 11, "scene": 40, "line": "No, I didn't.", "order": 11106, "speaker": "Angela Martin"},
  {"season": 3, "episode": 11, "scene": 40, "line": "Yes, you did. Okay. Oh, Pam, I forgot to get you something.", "order": 11107, "speaker": "Michael Scott"},
  {"season": 3, "episode": 11, "scene": 40, "line": "That's okay.", "order": 11108, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 11, "scene": 40, "line": "I'm just kidding. Coconuts for your coconuts!", "order": 11109, "speaker": "Michael Scott"},
  {"season": 3, "episode": 11, "scene": 40, "line": "No.", "order": 11110, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 11, "scene": 40, "line": "[laughs] Try it on.", "order": 11111, "speaker": "Michael Scott"},
  {"season": 3, "episode": 11, "scene": 40, "line": "No.", "order": 11112, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 11, "scene": 40, "line": "Go ahead. Let me know if I miscalculated the size. I don't think I did.", "order": 11113, "speaker": "Michael Scott"},
  {"season": 3, "episode": 11, "scene": 40, "line": "Thank you.", "order": 11114, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 11, "scene": 40, "line": "Stanley, I got you some ganja. I'm just kidding. It's coffee. I bet you wish it was ganja.", "order": 11115, "speaker": "Michael Scott"},
  {"season": 3, "episode": 11, "scene": 41, "line": "I just got back from vacay. Come on. Give me a break, man. [holds up CD] Waves over Jamaica, $6.99.", "order": 11116, "speaker": "Michael Scott"},
  {"season": 3, "episode": 11, "scene": 42, "line": "Did he actually give you some ganja?", "order": 11117, "speaker": "Creed Bratton"},
  {"season": 3, "episode": 11, "scene": 42, "line": "No.", "order": 11118, "speaker": "Stanley Hudson"},
  {"season": 3, "episode": 11, "scene": 42, "line": "You want some?", "order": 11119, "speaker": "Creed Bratton"},
  {"season": 3, "episode": 11, "scene": 43, "line": "Okay, let me tell you a little story. When I was on vacation, I met a man named Bavon. And Bavon takes people parasailing. And you know what he said to me? He said that he loves going to work every day. How many of you can say that you love going to work every day like Bavon? [Dwight and Andy raises hand] No one.", "order": 11120, "speaker": "Michael Scott"},
  {"season": 3, "episode": 11, "scene": 43, "line": "But, Michael, Buvon gets to go parasailing every day, and that's fun.", "order": 11121, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 11, "scene": 43, "line": "His name is Bavon. And you know what? I'm sure Bavon gets sick of his job, too. I'm sure he would like to take a day off. I'm sure he would like to spend a vacation selling paper, but he has the right attitude. And that is something that you learn when you go to Jamaica, and you really apply yourself.", "order": 11122, "speaker": "Michael Scott"},
  {"season": 3, "episode": 11, "scene": 44, "line": "I would love to take them all to Jamaica, but is that good management? Yes. But is it the best management? Well, I can't afford it, and corporate won't pay for it. So who's the real criminal?", "order": 11123, "speaker": "Michael Scott"},
  {"season": 3, "episode": 11, "scene": 45, "line": "How many of you know the meaning of the Jamaican term, Hakuna Matata?", "order": 11124, "speaker": "Michael Scott"},
  {"season": 3, "episode": 11, "scene": 45, "line": "[raises hand] Are you stoned?", "order": 11125, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 11, "scene": 45, "line": "I...", "order": 11126, "speaker": "Michael Scott"},
  {"season": 3, "episode": 11, "scene": 45, "line": "In the picture, are you high?", "order": 11127, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 11, "scene": 45, "line": "Oh, that's red eye.", "order": 11128, "speaker": "Michael Scott"},
  {"season": 3, "episode": 11, "scene": 45, "line": "Is that not what happens when you're high?", "order": 11129, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 11, "scene": 45, "line": "You look like you have the munchies.", "order": 11130, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 11, "scene": 45, "line": "Well...", "order": 11131, "speaker": "Michael Scott"},
  {"season": 3, "episode": 11, "scene": 45, "line": "I have the munchies. I'm gonna get some of those chocolate wafers.", "order": 11132, "speaker": "Kelly Kapoor"},
  {"season": 3, "episode": 11, "scene": 45, "line": "Hey, can we just focus?", "order": 11133, "speaker": "Michael Scott"},
  {"season": 3, "episode": 11, "scene": 46, "line": "Michael and Jan together in Jamaica. I guess I would just need a little bit more evidence than seeing it with my own eyes.", "order": 11134, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 11, "scene": 47, "line": "I don't know if Michael and Jan really went to Jamaica, because Michael's way into Photoshop. For example, I never went with him to Egypt. [holds up picture with Michael and himself in Egypt]", "order": 11135, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 11, "scene": 48, "line": "Where did you save the list of exchanges?", "order": 11136, "speaker": "Phyllis Lapin"},
  {"season": 3, "episode": 11, "scene": 48, "line": "I will gladly give you that information when my bonus comes through.", "order": 11137, "speaker": "Stanley Hudson"},
  {"season": 3, "episode": 11, "scene": 48, "line": "But I won't be able to do inventory without it.", "order": 11138, "speaker": "Phyllis Lapin"},
  {"season": 3, "episode": 11, "scene": 48, "line": "Then you won't be able to do inventory.", "order": 11139, "speaker": "Stanley Hudson"},
  {"season": 3, "episode": 11, "scene": 49, "line": "Yeah, I really like Stanley, but I'm not always sure if he likes me. I guess that's okay, 'cause I don't really like him.", "order": 11140, "speaker": "Phyllis Lapin"},
  {"season": 3, "episode": 11, "scene": 50, "line": "Come on. Come on.", "order": 11141, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 11, "scene": 50, "line": "Okay. Well, Jan's email password is not Dunder Mifflin, Sandals, Jamaica, Michael, Michael Scott, Jan Scott, or Mrs. Jan Scott.", "order": 11142, "speaker": "Michael Scott"},
  {"season": 3, "episode": 11, "scene": 50, "line": "Try Dwight.", "order": 11143, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 11, "scene": 50, "line": "Her passwords aren't going to be Dwight, Dwight. That's stupid. Okay.", "order": 11144, "speaker": "Michael Scott"},
  {"season": 3, "episode": 11, "scene": 51, "line": "Don't you have some sort of chaser or gobbler thing that you can send out that's able to eat up that other emails? I read about something like that. Well, I am in America and I am online, so I do think it involves you. Uh-huh. And if I were a subscriber, would then you be able to send out the gobbler? I'd... Yes, I will hold.", "order": 11145, "speaker": "Michael Scott"},
  {"season": 3, "episode": 11, "scene": 52, "line": "So your trip to Scottsdale was good?", "order": 11146, "speaker": "Someone"},
  {"season": 3, "episode": 11, "scene": 52, "line": "Oh yeah, Arizona's beautiful. Yeah. It's great to... great to see my sister.", "order": 11147, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 11, "scene": 52, "line": "Burnt.", "order": 11148, "speaker": "Someone"},
  {"season": 3, "episode": 11, "scene": 52, "line": "Oh, yeah, I know. I don't... I don't normally lay out in the sun a lot, but, you know, when it's Scottsdale... I was visiting my sister and...", "order": 11149, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 11, "scene": 53, "line": "When a woman gets older, she'll go just about anywhere with just about anyone. I've done some things I'm not so proud of, but I've seen the world.", "order": 11150, "speaker": "Meredith Palmer"},
  {"season": 3, "episode": 11, "scene": 54, "line": "Your body is a temple. You have to respect it. You can't just whore it out.", "order": 11151, "speaker": "Angela Martin"},
  {"season": 3, "episode": 11, "scene": 55, "line": "I should be turned on. But, last year, remember, I had that skin cancer scare, and so now all I can think is that I hope she was wearing sunscreen. God, I would love to rub some on her.", "order": 11152, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 11, "scene": 56, "line": "The worst part about the Internet, when a secret is out, everyone knows. The best part about the Internet, that video of the dancing Indian midget, crossbow.org, massively multiplayer online gaming, i.e. Everquest. I also enjoy online banking.", "order": 11153, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 11, "scene": 57, "line": "Okay. So I went on the Internet, and she is not gonna find another apartment for that price. I mean this one is twice as much, and it doesn't have parking. Does she like to park?", "order": 11154, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 11, "scene": 57, "line": "Oh, loves it.", "order": 11155, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 11, "scene": 57, "line": "Okay. This one is the same price but it's got...", "order": 11156, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 11, "scene": 57, "line": "Green walls.", "order": 11157, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 11, "scene": 57, "line": "I think that's mold.", "order": 11158, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 11, "scene": 57, "line": "Ooh... fancy.", "order": 11159, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 11, "scene": 58, "line": "Spiderman, Peter Parker, would not reveal his love for Mary Jane for fear that she might be captured and harmed by the Green Goblin. Now, if Michael had studied the Spidey principles that I'd drawn up for him, Jan's boobs might not be all over the Internet. [Dwight pretends to shoot out webs from his palms]", "order": 11160, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 11, "scene": 59, "line": "Attention everyone! May I have your attention, please? Listen up. If we all work really hard and focus, we should be out of here in seven hours. Now, somewhere in these stacks, I have hidden a deck of cards. That should motivate you to keep your eyes open. If you find the deck of cards, you can redeem it with me in exchange for a free soft drink. [Jim shuffles the deck of cards] Get back... Back to work!", "order": 11161, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 11, "scene": 60, "line": "Yes, it is The Island Luau Inventory '07. It's actually a success compared to Disco Audit '05.", "order": 11162, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 11, "scene": 61, "line": "What's the problem?", "order": 11163, "speaker": "Roy Anderson"},
  {"season": 3, "episode": 11, "scene": 61, "line": "We have seven fewer boxes of Canariola copier paper in stock than we're supposed to.", "order": 11164, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 11, "scene": 61, "line": "Let me see this. [scans one box seven times] Looks good to me.", "order": 11165, "speaker": "Roy Anderson"},
  {"season": 3, "episode": 11, "scene": 62, "line": "I wouldn't have come, but I know Jan is showing up soon. I don't wanna miss the show.", "order": 11166, "speaker": "Stanley Hudson"},
  {"season": 3, "episode": 11, "scene": 63, "line": "[Kelly singing] Hey! Hey! Stop your mouth, please.", "order": 11167, "speaker": "Michael Scott"},
  {"season": 3, "episode": 11, "scene": 63, "line": "Eighty-six. Eighty-seven. [singing] Eighty-eight. Eighty-nine.", "order": 11168, "speaker": "Kelly Kapoor"},
  {"season": 3, "episode": 11, "scene": 64, "line": "Hey Dwight, I bet I can stack faster than you can.", "order": 11169, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 11, "scene": 64, "line": "Please, I can double your pace.", "order": 11170, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 11, "scene": 64, "line": "Oh, yeah? Prove it, champ. You ready?", "order": 11171, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 11, "scene": 64, "line": "Let's go.", "order": 11172, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 11, "scene": 64, "line": "One, two, three, go!", "order": 11173, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 11, "scene": 64, "line": "[Dwight and Andy stacking boxes rapidly] Nice.", "order": 11174, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 11, "scene": 64, "line": "Get out of my way, Jim!", "order": 11175, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 11, "scene": 64, "line": "Jim, move!", "order": 11176, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 11, "scene": 64, "line": "Okay.", "order": 11177, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 11, "scene": 64, "line": "Okay.", "order": 11178, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 11, "scene": 64, "line": "Dang it.", "order": 11179, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 11, "scene": 64, "line": "Two at a time!", "order": 11180, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 11, "scene": 64, "line": "Those aren't straight. Totally crooked. Totally crooked. You're disqualified.", "order": 11181, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 11, "scene": 65, "line": "[to herself] Be careful. [yells as boxes fall on her] Hello? I'm stuck!", "order": 11182, "speaker": "Meredith Palmer"},
  {"season": 3, "episode": 11, "scene": 66, "line": "Hey, Stanley, in case I don't see you again after tonight, here is your bonus, $1,000 cash. Keep the change. [hands Stanley a piece of Jamaican currency]", "order": 11183, "speaker": "Michael Scott"},
  {"season": 3, "episode": 11, "scene": 66, "line": "There is a butterfly on this. Why?", "order": 11184, "speaker": "Stanley Hudson"},
  {"season": 3, "episode": 11, "scene": 66, "line": "It is Jamaican. The exchange rate is 65-to-1. I don't remember in which direction.", "order": 11185, "speaker": "Michael Scott"},
  {"season": 3, "episode": 11, "scene": 66, "line": "So it's either worth $15 bucks or $65,000.", "order": 11186, "speaker": "Stanley Hudson"},
  {"season": 3, "episode": 11, "scene": 66, "line": "Yes, good luck with that.", "order": 11187, "speaker": "Michael Scott"},
  {"season": 3, "episode": 11, "scene": 67, "line": "You know, I actually lived in Honolulu for a year.", "order": 11188, "speaker": "Toby Flenderson"},
  {"season": 3, "episode": 11, "scene": 67, "line": "Oh, so you've been to a real luau.", "order": 11189, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 11, "scene": 67, "line": "Yeah.", "order": 11190, "speaker": "Toby Flenderson"},
  {"season": 3, "episode": 11, "scene": 67, "line": "Yeah.", "order": 11191, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 11, "scene": 67, "line": "I tried. We didn't have time.", "order": 11192, "speaker": "Angela Martin"},
  {"season": 3, "episode": 11, "scene": 68, "line": "You're a box short on the black, medium-line ballpoints.", "order": 11193, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 11, "scene": 68, "line": "I don't know what to tell you.", "order": 11194, "speaker": "Creed Bratton"},
  {"season": 3, "episode": 11, "scene": 69, "line": "One year, I had a close acquaintance of mine back a truck in here and clean out this whole place. That was a very good year. I think they blamed it on some kid.", "order": 11195, "speaker": "Creed Bratton"},
  {"season": 3, "episode": 12, "scene": 1, "line": "Jim, could you come in here please?", "order": 11196, "speaker": "Michael Scott"},
  {"season": 3, "episode": 12, "scene": 1, "line": "Hi, Jim.", "order": 11197, "speaker": "Harvey"},
  {"season": 3, "episode": 12, "scene": 1, "line": "Hello.", "order": 11198, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 12, "scene": 1, "line": "I am Harvey, a computer. Jim sucks.", "order": 11199, "speaker": "Harvey"},
  {"season": 3, "episode": 12, "scene": 1, "line": "Sorry, oh wow, that's so rude. I'm sorry, I can't control him.", "order": 11200, "speaker": "Michael Scott"},
  {"season": 3, "episode": 12, "scene": 1, "line": "Yeah, you can.", "order": 11201, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 12, "scene": 1, "line": "You know what? Get Pam.", "order": 11202, "speaker": "Michael Scott"},
  {"season": 3, "episode": 12, "scene": 1, "line": "For this?", "order": 11203, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 12, "scene": 1, "line": "Pam.", "order": 11204, "speaker": "Michael Scott"},
  {"season": 3, "episode": 12, "scene": 1, "line": "Pam, you look very hot today.", "order": 11205, "speaker": "Harvey"},
  {"season": 3, "episode": 12, "scene": 1, "line": "Pam, meet Harvey. This is Michael's new friend.", "order": 11206, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 12, "scene": 1, "line": "Great.", "order": 11207, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 12, "scene": 1, "line": "Me so horny. Me love you long tim.", "order": 11208, "speaker": "Harvey"},
  {"season": 3, "episode": 12, "scene": 1, "line": "Oh, that is gross.", "order": 11209, "speaker": "Michael Scott"},
  {"season": 3, "episode": 12, "scene": 1, "line": "Who is 'Long Tim'?", "order": 11210, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 12, "scene": 1, "line": "Damn it.", "order": 11211, "speaker": "Michael Scott"},
  {"season": 3, "episode": 12, "scene": 1, "line": "Long time. Me lobe yoy long time.", "order": 11212, "speaker": "Harvey"},
  {"season": 3, "episode": 12, "scene": 1, "line": "Oh well, 'Yoy' should bring in 'Long Tim' in one day. Shouldn't he?", "order": 11213, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 12, "scene": 1, "line": "I would love to meet Long Tim.", "order": 11214, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 12, "scene": 1, "line": "Yeah. Right?", "order": 11215, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 12, "scene": 1, "line": "Yeah.", "order": 11216, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 12, "scene": 1, "line": "You ruined a funny joke, you. Get out of my offive.", "order": 11217, "speaker": "Harvey"},
  {"season": 3, "episode": 12, "scene": 1, "line": "Ok.", "order": 11218, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 12, "scene": 1, "line": "Ok. Bye Harvey.", "order": 11219, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 12, "scene": 1, "line": "Boobs.", "order": 11220, "speaker": "Harvey"},
  {"season": 3, "episode": 12, "scene": 2, "line": "Angela.", "order": 11221, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 12, "scene": 2, "line": "What?", "order": 11222, "speaker": "Angela Martin"},
  {"season": 3, "episode": 12, "scene": 2, "line": "That was a voicemail that corporate left last night. They did not get our tax forms. Did you send them?", "order": 11223, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 12, "scene": 2, "line": "They arrived this morning.", "order": 11224, "speaker": "Angela Martin"},
  {"season": 3, "episode": 12, "scene": 2, "line": "Are you sure? It is a big deal.", "order": 11225, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 12, "scene": 2, "line": "Is it a big deal? Is it Kevin?", "order": 11226, "speaker": "Angela Martin"},
  {"season": 3, "episode": 12, "scene": 2, "line": "... Do you really not know? Because it is a big deal.", "order": 11227, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 12, "scene": 3, "line": "Five of us transferred from Stamford. There are two of us left. Me and Karen. It's like we are touring Willy Wonka's Chocolate Factory and dropping off one by one. Well, guess what? I am not falling in a chocolate river.", "order": 11228, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 12, "scene": 4, "line": "Everything ok? [Takes candy from Pam's desk]", "order": 11229, "speaker": "Angela Martin"},
  {"season": 3, "episode": 12, "scene": 4, "line": "Everything is fine. You are in the clear.", "order": 11230, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 12, "scene": 4, "line": "Thank you. [Puts candy back] I... I don't want those.", "order": 11231, "speaker": "Angela Martin"},
  {"season": 3, "episode": 12, "scene": 5, "line": "Dwight, care to join us, finally? Thank you.", "order": 11232, "speaker": "Michael Scott"},
  {"season": 3, "episode": 12, "scene": 5, "line": "Hey Dwight, pass the tardy sauce. Get it Michael?", "order": 11233, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 12, "scene": 5, "line": "Yes. Ok, here is the dream team. My sales dream team. Today we are going to team up for sales calls. Andy, since this was your idea, you get to pick first.", "order": 11234, "speaker": "Michael Scott"},
  {"season": 3, "episode": 12, "scene": 5, "line": "Hmmm, well... let me think about this for a minute. Oh, I don't know. Michael Scott.", "order": 11235, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 12, "scene": 5, "line": "Oh.", "order": 11236, "speaker": "Michael Scott"},
  {"season": 3, "episode": 12, "scene": 5, "line": "Ph. D. Doctor of Sales.", "order": 11237, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 12, "scene": 5, "line": "Well, I appreciate that. That is very gracious of you.", "order": 11238, "speaker": "Michael Scott"},
  {"season": 3, "episode": 12, "scene": 5, "line": "Well, it is very gracious of you to accept.", "order": 11239, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 12, "scene": 5, "line": "Well, thank you sir. Ok, now going by seniority. Phyllis, our resident senior.", "order": 11240, "speaker": "Michael Scott"},
  {"season": 3, "episode": 12, "scene": 5, "line": "We're the same age and I'll pick... Karen.", "order": 11241, "speaker": "Phyllis Lapin"},
  {"season": 3, "episode": 12, "scene": 5, "line": "Oh, uh, thanks.", "order": 11242, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 12, "scene": 5, "line": "Good. Next up, Superfly himself, Stanley.", "order": 11243, "speaker": "Michael Scott"},
  {"season": 3, "episode": 12, "scene": 5, "line": "Pass.", "order": 11244, "speaker": "Stanley Hudson"},
  {"season": 3, "episode": 12, "scene": 5, "line": "You can't pass. You've got to pick somebody.", "order": 11245, "speaker": "Michael Scott"},
  {"season": 3, "episode": 12, "scene": 5, "line": "... I'll take the kid.", "order": 11246, "speaker": "Stanley Hudson"},
  {"season": 3, "episode": 12, "scene": 6, "line": "I am very flattered. I was his second choice after 'Pass.'", "order": 11247, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 12, "scene": 7, "line": "So that just leaves Dwight and Jim.", "order": 11248, "speaker": "Michael Scott"},
  {"season": 3, "episode": 12, "scene": 7, "line": "Ok, wait. Does anyone want to trade?", "order": 11249, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 12, "scene": 7, "line": "Yup. I'll trade.", "order": 11250, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 12, "scene": 8, "line": "Dwight and I used to go on sales calls all the time. In fact, I have a picture to remember that time. Oh young Jim. There is just so much I need to warn you about. And yet, tragically, I cannot.", "order": 11251, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 12, "scene": 9, "line": "Sebring by Chrysler. Heck of a... motor... carriage.", "order": 11252, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 12, "scene": 9, "line": "Dwight?", "order": 11253, "speaker": "Michael Scott"},
  {"season": 3, "episode": 12, "scene": 9, "line": "Yup?", "order": 11254, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 12, "scene": 9, "line": "Here ya go. [throws laundry]", "order": 11255, "speaker": "Michael Scott"},
  {"season": 3, "episode": 12, "scene": 9, "line": "Yeah! You want shirts on hangers?", "order": 11256, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 12, "scene": 9, "line": "Please.", "order": 11257, "speaker": "Michael Scott"},
  {"season": 3, "episode": 12, "scene": 9, "line": "He does your laundry?", "order": 11258, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 12, "scene": 9, "line": "Long story. All right everybody, circle up. Here we go. You know what this is? This is the 'Amazing Race.' [To Ryan and Stanley] And you guys are the retired marines. [To Phyllis and Karen] And you guys are the mother and daughter. [To Dwight and Jim] And you guys are the gay couple. And we are the firefighter heroes. Are we ready to go?", "order": 11259, "speaker": "Michael Scott"},
  {"season": 3, "episode": 12, "scene": 9, "line": "Wait, 'Amazing Race' like, the biggest sale wins?", "order": 11260, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 12, "scene": 9, "line": "No, we're just going to rush out, do the sales thing, and come back.", "order": 11261, "speaker": "Michael Scott"},
  {"season": 3, "episode": 12, "scene": 9, "line": "Is there a prize?", "order": 11262, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 12, "scene": 9, "line": "Just bragging rights.", "order": 11263, "speaker": "Michael Scott"},
  {"season": 3, "episode": 12, "scene": 9, "line": "Then how is this 'Amazing Race'?", "order": 11264, "speaker": "Phyllis Lapin"},
  {"season": 3, "episode": 12, "scene": 9, "line": "It's just... brrrrrr... It's 'Amazing Race,' Phyllis. Okay? We're in teams of two and we are on a mission. All right, so, on your mark, get set, go. Let's do it.", "order": 11265, "speaker": "Michael Scott"},
  {"season": 3, "episode": 12, "scene": 9, "line": "Come on!!", "order": 11266, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 12, "scene": 9, "line": "Michael. [Michael throws Phyllis' keys under the car.]", "order": 11267, "speaker": "Phyllis Lapin"},
  {"season": 3, "episode": 12, "scene": 9, "line": "Whoah, whoah. Oh hahahaha. Vamanos!", "order": 11268, "speaker": "Michael Scott"},
  {"season": 3, "episode": 12, "scene": 9, "line": "Bueno.", "order": 11269, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 12, "scene": 9, "line": "Do you have a pole?", "order": 11270, "speaker": "Phyllis Lapin"},
  {"season": 3, "episode": 12, "scene": 9, "line": "Let's go get a broom.", "order": 11271, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 12, "scene": 10, "line": "Seriously? You're going to sit in the back?", "order": 11272, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 12, "scene": 10, "line": "Uh, yeah. It's the safest part of the car. In the event of a crash, the driver always protects his side of the car first.", "order": 11273, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 12, "scene": 11, "line": "Here we go.", "order": 11274, "speaker": "Michael Scott"},
  {"season": 3, "episode": 12, "scene": 12, "line": "In order to take down Dwight, I have to chip away at his ally, which, in this case, is Michael. Here's the good news. Every success I've ever had at my job or with the lady-folk has come from my ability to slowly and painfully wear someone down.", "order": 11275, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 12, "scene": 13, "line": "What's the deal with Dwight doing your laundry?", "order": 11276, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 12, "scene": 13, "line": "Ugh... that is a long story. Um, couple of months ago, Dwight tried to go behind my back with Jan and get my job and I am now having him do my laundry as punishment.", "order": 11277, "speaker": "Michael Scott"},
  {"season": 3, "episode": 12, "scene": 13, "line": "Wow, that is a long story, but quite well told, Michael. I find it very interesting. Especially about the part Dwight going behind your back and basically, like, being a terrible person. You know if you want your laundry done right? I used to work at Abercrombie. So, pretty good folder.", "order": 11278, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 12, "scene": 14, "line": "Hey Pam. Would you like to go with me to grab a coffee?", "order": 11279, "speaker": "Angela Martin"},
  {"season": 3, "episode": 12, "scene": 14, "line": "Really?", "order": 11280, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 12, "scene": 14, "line": "Yeah, I could use some fresh air. Might be fun.", "order": 11281, "speaker": "Angela Martin"},
  {"season": 3, "episode": 12, "scene": 14, "line": "Ok. Sure.", "order": 11282, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 12, "scene": 14, "line": "Ok.", "order": 11283, "speaker": "Angela Martin"},
  {"season": 3, "episode": 12, "scene": 15, "line": "Why are we turning in here, this is a beauty salon?", "order": 11284, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 12, "scene": 15, "line": "Um-hmmm.", "order": 11285, "speaker": "Phyllis Lapin"},
  {"season": 3, "episode": 12, "scene": 16, "line": "Hey, do you mind if I take the lead on this one and then you can critique me after?", "order": 11286, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 12, "scene": 16, "line": "You want the lead?", "order": 11287, "speaker": "Stanley Hudson"},
  {"season": 3, "episode": 12, "scene": 16, "line": "Yeah, if you don't mind.", "order": 11288, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 12, "scene": 16, "line": "Mind? Nothing would delight me more.", "order": 11289, "speaker": "Stanley Hudson"},
  {"season": 3, "episode": 12, "scene": 17, "line": "Leave the keys.", "order": 11290, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 12, "scene": 17, "line": "You still do that thing?", "order": 11291, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 12, "scene": 17, "line": "Leave the keys!", "order": 11292, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 12, "scene": 18, "line": "I think you have assembled an amazing team at Scranton. It's really a pleasure to be a part of it. It's like, everyone has their own special skill, you know, just like the Superfriends. Except for Dwight, who is more like a SuperDud. I mean, he would be a Superfriend if there was a Superfriend whose super power was always being late. You know?", "order": 11293, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 12, "scene": 18, "line": "Hawkman.", "order": 11294, "speaker": "Michael Scott"},
  {"season": 3, "episode": 12, "scene": 19, "line": "My plan is taking longer than I thought but I don't give up easily. I have walked two marathons.", "order": 11295, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 12, "scene": 19, "line": "[Walks out of the Ladies' Restroom] Let's go. The men's room was disgusting.", "order": 11296, "speaker": "Michael Scott"},
  {"season": 3, "episode": 12, "scene": 20, "line": "After you sir.", "order": 11297, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 12, "scene": 20, "line": "No thank you, I never let anyone walk behind me. Seven out of ten attacks are from the rear.", "order": 11298, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 12, "scene": 20, "line": "Ok, well, that still leaves a 30% chance that I will attack you from the front.", "order": 11299, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 12, "scene": 20, "line": "Uh, yeah, but it will be easier to stop. I can always block the blow. I can counter it with... [Jim slaps Dwight]", "order": 11300, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 12, "scene": 21, "line": "Angela, you seem so happy. I bet you wish you were like this all the time.", "order": 11301, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 12, "scene": 21, "line": "This friend of mine - let's call her Noelle - she missed this deadline turning something in to Corporate in New York. But then this gallant gentleman - we'll call him Kurt - he drove all the way to New York and handed it in for her. That's... I don't know. I guess he really just likes her a lot.", "order": 11302, "speaker": "Angela Martin"},
  {"season": 3, "episode": 12, "scene": 21, "line": "That's great.", "order": 11303, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 12, "scene": 21, "line": "Yes, it is. [Walks up to the counter where there is no employee] Hello?", "order": 11304, "speaker": "Angela Martin"},
  {"season": 3, "episode": 12, "scene": 22, "line": "Hey, did you catch that up at Lake Wallenpaupack?", "order": 11305, "speaker": "Michael Scott"},
  {"season": 3, "episode": 12, "scene": 22, "line": "Yep.", "order": 11306, "speaker": "Buyer"},
  {"season": 3, "episode": 12, "scene": 22, "line": "You know, I used to go up there all the time with my step-dad, and I never caught anything that big.", "order": 11307, "speaker": "Michael Scott"},
  {"season": 3, "episode": 12, "scene": 22, "line": "Caught an eighty-pound shark off of Montauk. It's in the Hamptons. My dad's got a 42-ft Bayliner. Sniped it with a rifle from the crow's nest. Also shot a deer once.", "order": 11308, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 12, "scene": 22, "line": "You know what? Let's get right down to it. Dunder-Mifflin may be just two rooms and a warehouse, but what we lack in flash, we will make up for with hardwork and decency.", "order": 11309, "speaker": "Michael Scott"},
  {"season": 3, "episode": 12, "scene": 22, "line": "Ok, this is the classic undersell because you should know we don't work out of a log cabin. We trade on the New York Stock Exchange. Ever heard of it? It's in New York.", "order": 11310, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 12, "scene": 22, "line": "Uh, I have to say I am a little wary with getting involved with a big company. We've had some problems in the past.", "order": 11311, "speaker": "Buyer"},
  {"season": 3, "episode": 12, "scene": 22, "line": "I think what Andy is trying to express is that while we have the resources of a large company, we will give you the care and attention of a small company.", "order": 11312, "speaker": "Michael Scott"},
  {"season": 3, "episode": 12, "scene": 22, "line": "Oh, man, that is, like, poetry.", "order": 11313, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 12, "scene": 22, "line": "No.", "order": 11314, "speaker": "Michael Scott"},
  {"season": 3, "episode": 12, "scene": 22, "line": "I swear, this guy could sell paper to a tree.", "order": 11315, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 12, "scene": 22, "line": "Stop it. [Puts his hand on Andy's shoulder] Stop it.", "order": 11316, "speaker": "Michael Scott"},
  {"season": 3, "episode": 12, "scene": 22, "line": "Ow.", "order": 11317, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 12, "scene": 24, "line": "Oh man. Talk about your classic 'Lame dash O.' Do we even want that guy buying our paper?", "order": 11318, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 12, "scene": 24, "line": "Yes.", "order": 11319, "speaker": "Michael Scott"},
  {"season": 3, "episode": 12, "scene": 25, "line": "I... I'm so sorry man. I really screwed that up.", "order": 11320, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 12, "scene": 25, "line": "Ah, no. Don't worry about it.", "order": 11321, "speaker": "Michael Scott"},
  {"season": 3, "episode": 12, "scene": 25, "line": "I really 'Schruted' it.", "order": 11322, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 12, "scene": 25, "line": "What?", "order": 11323, "speaker": "Michael Scott"},
  {"season": 3, "episode": 12, "scene": 25, "line": "Schruted' it. It's just this thing that people say around your office all the time. Like, when you screw something up in a really irreversible way, you 'Schruted' it. I don't know where it comes from though. Do you think it comes from Dwight Schrute?", "order": 11324, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 12, "scene": 25, "line": "I don't know. Who knows how words are formed.", "order": 11325, "speaker": "Michael Scott"},
  {"season": 3, "episode": 12, "scene": 26, "line": "It's a big order. Thanks Kenny.", "order": 11326, "speaker": "Phyllis Lapin"},
  {"season": 3, "episode": 12, "scene": 26, "line": "Yeah, thank you.", "order": 11327, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 12, "scene": 26, "line": "Hey, how's Annie?", "order": 11328, "speaker": "Phyllis Lapin"},
  {"season": 3, "episode": 12, "scene": 26, "line": "Oh, she's great. This is us last year in Bermuda. Lovely place. You ever been to Bermuda?", "order": 11329, "speaker": "Kenny"},
  {"season": 3, "episode": 12, "scene": 27, "line": "Stanley Hudson.", "order": 11330, "speaker": "Julius"},
  {"season": 3, "episode": 12, "scene": 27, "line": "Ah, Julius, how's it goin'?", "order": 11331, "speaker": "Stanley Hudson"},
  {"season": 3, "episode": 12, "scene": 27, "line": "Great, great, great.", "order": 11332, "speaker": "Julius"},
  {"season": 3, "episode": 12, "scene": 27, "line": "Stanley.", "order": 11333, "speaker": "Guy"},
  {"season": 3, "episode": 12, "scene": 27, "line": "So good to see you too. I'd like you fellas to meet Ryan Howard.", "order": 11334, "speaker": "Stanley Hudson"},
  {"season": 3, "episode": 12, "scene": 27, "line": "Hi.", "order": 11335, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 12, "scene": 27, "line": "I'm gonna let Ryan do a little pitch for you while I do my crossword. Ryan?", "order": 11336, "speaker": "Stanley Hudson"},
  {"season": 3, "episode": 12, "scene": 27, "line": "Um... [To the Buyers] Hi. Hi. Hi. Hi.", "order": 11337, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 12, "scene": 27, "line": "Hello, Ryan. What do you have for us?", "order": 11338, "speaker": "Julius"},
  {"season": 3, "episode": 12, "scene": 27, "line": "Oh...", "order": 11339, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 12, "scene": 28, "line": "We can offer our biggest discounts on 30% recycled and ultra-premium laser.", "order": 11340, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 12, "scene": 28, "line": "Kay. 'Kay.", "order": 11341, "speaker": "Man"},
  {"season": 3, "episode": 12, "scene": 28, "line": "Can I use your phone?", "order": 11342, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 12, "scene": 28, "line": "Yeah, sure, go ahead.", "order": 11343, "speaker": "Man"},
  {"season": 3, "episode": 12, "scene": 28, "line": "Thanks.", "order": 11344, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 12, "scene": 28, "line": "Let me talk to you about a few of the other things we can offer. Namely, we know the tax season is coming up, so by April 1st we can have you fully stocked.", "order": 11345, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 12, "scene": 28, "line": "[On Phone] One...", "order": 11346, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 12, "scene": 28, "line": "We have discount prices on ink cartridges...", "order": 11347, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 12, "scene": 28, "line": "Three...", "order": 11348, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 12, "scene": 28, "line": "And, also, any forms that you are going to need...", "order": 11349, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 12, "scene": 28, "line": "Seven...", "order": 11350, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 12, "scene": 28, "line": "We can custom make them.", "order": 11351, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 12, "scene": 31, "line": "Ha ha ha! And you just said, 'Hi! Hi! Hi!' Ha heh ha! You sounded like my niece, and she's six months old!", "order": 11352, "speaker": "Stanley Hudson"},
  {"season": 3, "episode": 12, "scene": 32, "line": "We'll I appreciate what you guys are saying but it, uh, makes more fiscal sense to go with one of the big guys.", "order": 11353, "speaker": "Man"},
  {"season": 3, "episode": 12, "scene": 32, "line": "Sure.", "order": 11354, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 12, "scene": 32, "line": "Sure, that's true we can't compete with their prices. But let me ask you something. How important to you is customer service?", "order": 11355, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 12, "scene": 32, "line": "It's very.", "order": 11356, "speaker": "Man"},
  {"season": 3, "episode": 12, "scene": 32, "line": "Please keep holding, your call is very important to us.", "order": 11357, "speaker": "Phone"},
  {"season": 3, "episode": 12, "scene": 32, "line": "Erm, that's one of the 'Big guys.' Been on hold this whole time.", "order": 11358, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 12, "scene": 32, "line": "[Dials cell Phone] And this is Dunder-Mifflin.", "order": 11359, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 12, "scene": 32, "line": "Dunder-Mifflin customer service, this is Kelly.", "order": 11360, "speaker": "Kelly Kapoor"},
  {"season": 3, "episode": 12, "scene": 32, "line": "Hey, Kelly, it's Jim.", "order": 11361, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 12, "scene": 32, "line": "Oh my god, Jim. How are you ? I wanted to tell you ... . [Jim hangs up]", "order": 11362, "speaker": "Kelly Kapoor"},
  {"season": 3, "episode": 12, "scene": 32, "line": "Here is my card. It's got my Cell number, my pager number, my home number, and my other pager number. I never take vacations, I never get sick, and I don't celebrate any major holidays.", "order": 11363, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 12, "scene": 32, "line": "All right, I get it. We got a deal.", "order": 11364, "speaker": "Man"},
  {"season": 3, "episode": 12, "scene": 32, "line": "Thanks.", "order": 11365, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 12, "scene": 34, "line": "Thanks. That was fun.", "order": 11366, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 12, "scene": 34, "line": "Yeah, I really enjoy spending time with you. You are a very nice person.", "order": 11367, "speaker": "Phyllis Lapin"},
  {"season": 3, "episode": 12, "scene": 34, "line": "Thank you.", "order": 11368, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 12, "scene": 34, "line": "I'm so glad you're with Jim. He was hung up on Pam for such a long time. Never thought he would get over her.", "order": 11369, "speaker": "Phyllis Lapin"},
  {"season": 3, "episode": 12, "scene": 34, "line": "That's nice.", "order": 11370, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 12, "scene": 34, "line": "You can pay me back later for the makeover.", "order": 11371, "speaker": "Phyllis Lapin"},
  {"season": 3, "episode": 12, "scene": 35, "line": "Hey boss. Got a minute?", "order": 11372, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 12, "scene": 35, "line": "Yes, Andy.", "order": 11373, "speaker": "Michael Scott"},
  {"season": 3, "episode": 12, "scene": 35, "line": "I forget, why did Dwight say he was late this morning?", "order": 11374, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 12, "scene": 35, "line": "He didn't say.", "order": 11375, "speaker": "Michael Scott"},
  {"season": 3, "episode": 12, "scene": 35, "line": "That's weird. Because I was just walking past his desk and I saw this, which is a tollbooth receipt from New York City, stamped really early this morning. So, why would Dwight go to New York without telling anyone? Do you think he went to see Jan? That's not like him. Is it? Someone told me a story about this, with, like, laundry and betrayal. Did you betray Dwight and try to steal his job or something?", "order": 11376, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 12, "scene": 35, "line": "No, you are remembering it wrong.", "order": 11377, "speaker": "Michael Scott"},
  {"season": 3, "episode": 12, "scene": 36, "line": "Dwight betrayed me once before. So this is his strike two. You know what they say? Fool me once, strike one. But fool me twice, strike three.", "order": 11378, "speaker": "Michael Scott"},
  {"season": 3, "episode": 12, "scene": 37, "line": "I like Karen. She's pretty and appears intelligent.", "order": 11379, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 12, "scene": 37, "line": "Well, I like pretty women who have the appearance of intelligence.", "order": 11380, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 12, "scene": 37, "line": "My girlfriend is also beautiful and smart. She could be a model or a college professor which is intimidating to a lot of guys.", "order": 11381, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 12, "scene": 37, "line": "We should go on a double date.", "order": 11382, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 12, "scene": 37, "line": "No thank you.", "order": 11383, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 12, "scene": 38, "line": "Dwight's name is on the security sign-in sheet, but I don't know who he met with. And where it asks to state your business, he wrote 'Beeswax. Not yours, Inc.'", "order": 11384, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 12, "scene": 38, "line": "I knew it. [Dwight and Jim walk in]", "order": 11385, "speaker": "Michael Scott"},
  {"season": 3, "episode": 12, "scene": 38, "line": "Oh, doggie.", "order": 11386, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 12, "scene": 39, "line": "Hey, do you want to grab a coffee?", "order": 11387, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 12, "scene": 39, "line": "Sure. Look at you!", "order": 11388, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 12, "scene": 39, "line": "Yeah.", "order": 11389, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 12, "scene": 41, "line": "Hey, we nailed the sale!", "order": 11390, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 12, "scene": 41, "line": "Where were you this morning?", "order": 11391, "speaker": "Michael Scott"},
  {"season": 3, "episode": 12, "scene": 41, "line": "I overslept. Damn rooster didn't crow.", "order": 11392, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 12, "scene": 41, "line": "Why do you lie, liar?", "order": 11393, "speaker": "Michael Scott"},
  {"season": 3, "episode": 12, "scene": 41, "line": "I am not a liar.", "order": 11394, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 12, "scene": 41, "line": "You are lying right now.", "order": 11395, "speaker": "Michael Scott"},
  {"season": 3, "episode": 12, "scene": 41, "line": "It sure seems like he is lying.", "order": 11396, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 12, "scene": 41, "line": "Stay out of this, you!", "order": 11397, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 12, "scene": 41, "line": "I know that you went to corporate this morning, and I know that you lied about it. And given our history, I need you tell me this instant exactly what you were doing.", "order": 11398, "speaker": "Michael Scott"},
  {"season": 3, "episode": 12, "scene": 41, "line": "Michael, I cannot tell you what I was doing there. But you have to trust me, I would never do anything to hurt you or this company.", "order": 11399, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 12, "scene": 41, "line": "Ok, you know what? I want you to think about your future in this company. I want you to think about it long and hard.", "order": 11400, "speaker": "Michael Scott"},
  {"season": 3, "episode": 12, "scene": 41, "line": "That's what she said.", "order": 11401, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 12, "scene": 41, "line": "Don't. Don't you dare. I want to know what you were doing this morning by the end of the day.", "order": 11402, "speaker": "Michael Scott"},
  {"season": 3, "episode": 12, "scene": 42, "line": "It's going to be ok.", "order": 11403, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 12, "scene": 42, "line": "How is going to be ok, Dwight? Everyone will know our business.", "order": 11404, "speaker": "Angela Martin"},
  {"season": 3, "episode": 12, "scene": 42, "line": "That's not the worst thing in the world. I'll just stand up in front of the office and reveal our true love. It won't be that bad. Look at Kelly and Ryan.", "order": 11405, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 12, "scene": 42, "line": "I hate those two people more than anything in the entire world.", "order": 11406, "speaker": "Angela Martin"},
  {"season": 3, "episode": 12, "scene": 42, "line": "Well, I don't have a lot of choices.", "order": 11407, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 12, "scene": 43, "line": "So, let me ask you a question.", "order": 11408, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 12, "scene": 43, "line": "Ok.", "order": 11409, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 12, "scene": 43, "line": "Did you ever have a thing for Pam?", "order": 11410, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 12, "scene": 43, "line": "Pam? Did I ever have a 'thing' for her? No, why? Did she say something?", "order": 11411, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 12, "scene": 43, "line": "I moved here from Connecticut...", "order": 11412, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 12, "scene": 43, "line": "Yeah. Ok, here's the ... I had a crush on her before I left. And I told her about it and she didn't feel the same way. So, it didn't amount to anything, and I left. I'm really glad you're here. 'Kay?", "order": 11413, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 12, "scene": 43, "line": "Kay.", "order": 11414, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 12, "scene": 44, "line": "Ahem-hem-hem. May I have your attention please? This will only take a moment of your time. Although I love this company more than almost anything in the world, I have decided to step down from my post and spend more time with my family. I do not fear the unknown and I will meet my new challenges head-on and I will succeed. And I will laugh in the faces of those who doubt me. It has been a pleasure working with some of you and I will not forget those of you soon but remember, while today it is me, we all shall fall. In other words, I am quitting. So... .", "order": 11415, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 12, "scene": 45, "line": "Oompa-Loompa Doompity-Dawesome, Dwight is now gone which is totally awesome. Why was he gone, he was such a nice guy? No, he was not. He was a total douche. Doompity-doomp.", "order": 11416, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 12, "scene": 46, "line": "I would like to give the rest of my belongings to Michael Scott. Just take them. Except this.", "order": 11417, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 12, "scene": 46, "line": "Good luck.", "order": 11418, "speaker": "Michael Scott"},
  {"season": 3, "episode": 12, "scene": 47, "line": "Dwight will be missed. Not by me so much, but, he will be missed.", "order": 11419, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 12, "scene": 48, "line": "Dwight, from sales, was one of the most honorable and efficient employees this company has ever had.", "order": 11420, "speaker": "Angela Martin"},
  {"season": 3, "episode": 12, "scene": 49, "line": "One of my life goals was to die right here in my desk chair. And today, that dream was shattered.", "order": 11421, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 12, "scene": 50, "line": "Hey man. [Dwight hugs Jim and leaves]", "order": 11422, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 12, "scene": 50, "line": "What happened on your sales call?", "order": 11423, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 12, "scene": 51, "line": "Um, am I happy the way things turned out? Oh, well, happy's such an ugly word. But, um, I saw what needed to be done and I did it and now I'm thrilled. So, it's pretty... [camera had focused in on Angela watching] Hello? Pretty good.", "order": 11424, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 13, "scene": 1, "line": "I have left Dunder-Mifflin after many record-breaking years and am officially on the job market. And it's very exciting.", "order": 11425, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 13, "scene": 2, "line": "For your convenience, I've broken it down into three parts: professional resume, athletic and special skills resume, and Dwight Schrute trivia.", "order": 11426, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 13, "scene": 3, "line": "I am ready to face any challenges that might be foolish enough to face me.", "order": 11427, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 13, "scene": 4, "line": "How would I describe myself? Three words. Hard-working, Alpha male, Jackhammer, Merciless, Insatiable.", "order": 11428, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 13, "scene": 5, "line": "There is nothing on my horizon except everything. Everything is on my horizon.", "order": 11429, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 13, "scene": 6, "line": "[in Staples uniform] I got this job to make some money while I continue my employment search. And uh, it's fine for the time being. Oops. Break's over.", "order": 11430, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 13, "scene": 7, "line": "[to Ryan] Big Turkey. [cellphone plays 'Rockin' Robin']", "order": 11431, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 13, "scene": 7, "line": "Is that you singing?", "order": 11432, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 13, "scene": 7, "line": "All four parts. Recorded it on my computer. It took me forever.", "order": 11433, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 13, "scene": 7, "line": "Nice job.", "order": 11434, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 13, "scene": 7, "line": "Thank you muchly. [cellphone continues playing]", "order": 11435, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 13, "scene": 7, "line": "You gonna answer it?", "order": 11436, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 13, "scene": 7, "line": "I called it myself. I just thought you'd get a kick out of the new ring.", "order": 11437, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 13, "scene": 7, "line": "Yikes.", "order": 11438, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 13, "scene": 7, "line": "Side note. I'd just like to say I'm thrilled to be working directly beneath you.", "order": 11439, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 13, "scene": 7, "line": "Thank you.", "order": 11440, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 13, "scene": 7, "line": "I feel I have a lot to learn from you, even though you're younger and have less experience. So here's to the future... Andy and the Tuna. [sings] Andy and the tuna...", "order": 11441, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 13, "scene": 8, "line": "I miss Dwight. Congratulations, universe. You win.", "order": 11442, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 13, "scene": 9, "line": "Yes, Dwight Schrute has left this company. More personnel turnover.", "order": 11443, "speaker": "Michael Scott"},
  {"season": 3, "episode": 13, "scene": 9, "line": "The cost of doing business.", "order": 11444, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 13, "scene": 9, "line": "Yes, well. It is a big loss. Dwight was the top salesman...", "order": 11445, "speaker": "Michael Scott"},
  {"season": 3, "episode": 13, "scene": 9, "line": "Was the top salesman...", "order": 11446, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 13, "scene": 9, "line": "I said 'was'.", "order": 11447, "speaker": "Michael Scott"},
  {"season": 3, "episode": 13, "scene": 9, "line": "[chuckles] Addition by subtraction.", "order": 11448, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 13, "scene": 9, "line": "What does that even mean? That is impossible.", "order": 11449, "speaker": "Michael Scott"},
  {"season": 3, "episode": 13, "scene": 9, "line": "Mmmm. Yeah you're right.", "order": 11450, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 13, "scene": 9, "line": "But, there is some good news. Oscar is back. Addition by addition. So we are going to have a big party today to welcome him back and hopefully that will lift everybody's spirits.", "order": 11451, "speaker": "Michael Scott"},
  {"season": 3, "episode": 13, "scene": 10, "line": "Hey, everyone.", "order": 11452, "speaker": "Oscar Martinez"},
  {"season": 3, "episode": 13, "scene": 10, "line": "Oh, hello, Oscar. How was your gay-cation?", "order": 11453, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 13, "scene": 10, "line": "Oh, that's very funny.", "order": 11454, "speaker": "Oscar Martinez"},
  {"season": 3, "episode": 13, "scene": 10, "line": "Yeah? I thought of it like, two seconds after you left.", "order": 11455, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 13, "scene": 10, "line": "[sits at his desk] Hi, Angela.", "order": 11456, "speaker": "Oscar Martinez"},
  {"season": 3, "episode": 13, "scene": 10, "line": "Oscar.", "order": 11457, "speaker": "Angela Martin"},
  {"season": 3, "episode": 13, "scene": 11, "line": "Hey, boss.", "order": 11458, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 13, "scene": 11, "line": "Hey, what's up.", "order": 11459, "speaker": "Michael Scott"},
  {"season": 3, "episode": 13, "scene": 11, "line": "Noooothin'. Ehrrrrrrrrr. Heh. Man. TGI-Wednesday. Am I right?", "order": 11460, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 13, "scene": 11, "line": "Yep.", "order": 11461, "speaker": "Michael Scott"},
  {"season": 3, "episode": 13, "scene": 11, "line": "Gonna go home. Get my beer on. Get my 'Lost' on. What are you doing later, wanna hang out?", "order": 11462, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 13, "scene": 11, "line": "I don't know. Maybe.", "order": 11463, "speaker": "Michael Scott"},
  {"season": 3, "episode": 13, "scene": 11, "line": "Well, I will take that as a maybe. [Michael gets up] Where are you going?", "order": 11464, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 13, "scene": 11, "line": "Bathroom.", "order": 11465, "speaker": "Michael Scott"},
  {"season": 3, "episode": 13, "scene": 11, "line": "Oh, well, I'm going to the kitchen, I'll walk with you.", "order": 11466, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 13, "scene": 12, "line": "[lurking by the bathroom door] Yeah, things are going pretty good. Gettin' a lot of face time with the boss.", "order": 11467, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 13, "scene": 13, "line": "Oscar? I have a question. Would you like to join the party planning committee?", "order": 11468, "speaker": "Angela Martin"},
  {"season": 3, "episode": 13, "scene": 13, "line": "The one of all women?", "order": 11469, "speaker": "Oscar Martinez"},
  {"season": 3, "episode": 13, "scene": 13, "line": "Yeah.", "order": 11470, "speaker": "Angela Martin"},
  {"season": 3, "episode": 13, "scene": 13, "line": "Because I'm gay?", "order": 11471, "speaker": "Oscar Martinez"},
  {"season": 3, "episode": 13, "scene": 13, "line": "No. No. Certain events have transpired. And I've thought about certain things. And I'm sorry for the way those certain events transpired. [starts to cry] And I would just like to make some changes about certain things and certain situations.", "order": 11472, "speaker": "Angela Martin"},
  {"season": 3, "episode": 13, "scene": 13, "line": "OK, OK, OK, all right, all right. I'll join. I'd love to. That's -- thank you.", "order": 11473, "speaker": "Oscar Martinez"},
  {"season": 3, "episode": 13, "scene": 13, "line": "Thank you. [sniffles]", "order": 11474, "speaker": "Angela Martin"},
  {"season": 3, "episode": 13, "scene": 13, "line": "Can I join too?", "order": 11475, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 13, "scene": 13, "line": "Never.", "order": 11476, "speaker": "Angela Martin"},
  {"season": 3, "episode": 13, "scene": 14, "line": "You sell those two printers this morning? Nice work.", "order": 11477, "speaker": "Staples Guy"},
  {"season": 3, "episode": 13, "scene": 14, "line": "[scoffs] Child's play. Give me something hard to sell.", "order": 11478, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 13, "scene": 15, "line": "Wow. [walks over to the plant] What is wrong with this thing? It looks terrible.", "order": 11479, "speaker": "Michael Scott"},
  {"season": 3, "episode": 13, "scene": 15, "line": "Do you want me to ask the cleaning crew if they stopped watering it?", "order": 11480, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 13, "scene": 15, "line": "Yeah. And you know what? Ask them about the toys on my desk too. They always used to arrange them in a very pleasing way. It used to brighten my morning.", "order": 11481, "speaker": "Michael Scott"},
  {"season": 3, "episode": 13, "scene": 15, "line": "Oh, that wasn't the night crew. That was Dwight.", "order": 11482, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 13, "scene": 15, "line": "Really? That was very nice of him. We need more attitude like that around the office.", "order": 11483, "speaker": "Michael Scott"},
  {"season": 3, "episode": 13, "scene": 15, "line": "Feel ya, dawg.", "order": 11484, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 13, "scene": 15, "line": "Yeah, do you?", "order": 11485, "speaker": "Michael Scott"},
  {"season": 3, "episode": 13, "scene": 15, "line": "Absolutely.", "order": 11486, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 13, "scene": 15, "line": "What did I say?", "order": 11487, "speaker": "Michael Scott"},
  {"season": 3, "episode": 13, "scene": 15, "line": "You said... [makes gibberish noises]", "order": 11488, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 13, "scene": 15, "line": "Huh.", "order": 11489, "speaker": "Michael Scott"},
  {"season": 3, "episode": 13, "scene": 15, "line": "Which is like, 'Right on.' And Pam was like 'blah blah blah' and you were like 'Yeah, psht.' Nailed it.", "order": 11490, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 13, "scene": 15, "line": "Oh, no.", "order": 11491, "speaker": "Michael Scott"},
  {"season": 3, "episode": 13, "scene": 15, "line": "Oh, no.", "order": 11492, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 13, "scene": 16, "line": "Love that Andy, right? Solid fellow. Seems smart enough. Likes me a lot. A lot. Too much. Like a crazy person. A little. Not super crazy... just... there's something about him that creeps me out. I can't really explain it. He's always up in my bidness. Which is ebonics for 'being in my face and annoying the bejesus out of me.' I don't understand how someone could have so little self-awareness.", "order": 11493, "speaker": "Michael Scott"},
  {"season": 3, "episode": 13, "scene": 17, "line": "I really have no preference. We don't even have to have a party.", "order": 11494, "speaker": "Oscar Martinez"},
  {"season": 3, "episode": 13, "scene": 17, "line": "No, hey, hey. Don't be ridiculous. Of course we are going to have a party. A celebration of Oscar. Oscar night. And I want it to be Oscar-specific.", "order": 11495, "speaker": "Michael Scott"},
  {"season": 3, "episode": 13, "scene": 17, "line": "Michael --", "order": 11496, "speaker": "Oscar Martinez"},
  {"season": 3, "episode": 13, "scene": 17, "line": "No, no, no. I mean, not because you're gay. Your gayness does not define you. Your Mexican-ness is what defines you to me. And I think we should celebrate Oscar's Mexicanity. So Phyllis... I want you to go find firecrackers. And a Chihuahua. Pam, in the frozen food section, Swanson makes a delightful chimichanga.", "order": 11497, "speaker": "Michael Scott"},
  {"season": 3, "episode": 13, "scene": 17, "line": "Why don't you have me riding in on a donkey into the office like Pepe.", "order": 11498, "speaker": "Oscar Martinez"},
  {"season": 3, "episode": 13, "scene": 17, "line": "Ah, a burro, of course. If Oscar wants a donkey, let's get him one.", "order": 11499, "speaker": "Michael Scott"},
  {"season": 3, "episode": 13, "scene": 18, "line": "Need any help?", "order": 11500, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 13, "scene": 18, "line": "Oh, no, thank you. I'm just looking.", "order": 11501, "speaker": "Lady"},
  {"season": 3, "episode": 13, "scene": 18, "line": "Great. I will literally be standing right here if you need anything at all.", "order": 11502, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 13, "scene": 18, "line": "Okay. [moves away]", "order": 11503, "speaker": "Lady"},
  {"season": 3, "episode": 13, "scene": 19, "line": "I think I could go for some tuna fish right about now. Oh, oh, got my rod here. [fake casts off] Whizzzzz. [catches Jim] Click, click, click, click, click, click, click. [Jim gets up] Cli -- ah! I got one! I got one! Ahhhh!", "order": 11504, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 13, "scene": 20, "line": "Hey.", "order": 11505, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 13, "scene": 20, "line": "Hey.", "order": 11506, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 13, "scene": 20, "line": "So Andy is in rare form today.", "order": 11507, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 13, "scene": 20, "line": "Yeah, you should not encourage him.", "order": 11508, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 13, "scene": 20, "line": "Encourage him? I'm the victim, okay? He's fishing for me. We've got to do something.", "order": 11509, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 13, "scene": 20, "line": "Look, I've got like fifteen new clients I inherited from Dwight and each file is password protected with a different mythical creature. So, I'm sorry. I can't.", "order": 11510, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 13, "scene": 20, "line": "Fine. Party pooper.", "order": 11511, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 13, "scene": 21, "line": "Who's that sportscaster that bit that lady? Marv something? Andy is like Marv Something. Great sportscaster. Big weirdo creep.", "order": 11512, "speaker": "Michael Scott"},
  {"season": 3, "episode": 13, "scene": 22, "line": "185 pounder. Check it out. Whew! ['displays' Jim who looks thoroughly annoyed]", "order": 11513, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 13, "scene": 23, "line": "Hey, Ryan?", "order": 11514, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 13, "scene": 23, "line": "What?", "order": 11515, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 13, "scene": 23, "line": "You wanna pull a prank on Andy?", "order": 11516, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 13, "scene": 23, "line": "Not right now, but ask me again ten years ago.", "order": 11517, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 13, "scene": 23, "line": "I liked you better when you were the temp.", "order": 11518, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 13, "scene": 23, "line": "Yeah, me too.", "order": 11519, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 13, "scene": 24, "line": "Hey guys. How's the workload on all of Dwight's old accounts? Handling it okay?", "order": 11520, "speaker": "Michael Scott"},
  {"season": 3, "episode": 13, "scene": 24, "line": "Sort of. He had a lot of clients.", "order": 11521, "speaker": "Phyllis Lapin"},
  {"season": 3, "episode": 13, "scene": 24, "line": "Yes, he did. Have any of you talked to Dwight?", "order": 11522, "speaker": "Michael Scott"},
  {"season": 3, "episode": 13, "scene": 24, "line": "Oh, sure, we talk all the time.", "order": 11523, "speaker": "Stanley Hudson"},
  {"season": 3, "episode": 13, "scene": 24, "line": "Really?", "order": 11524, "speaker": "Michael Scott"},
  {"season": 3, "episode": 13, "scene": 24, "line": "No.", "order": 11525, "speaker": "Stanley Hudson"},
  {"season": 3, "episode": 13, "scene": 24, "line": "Don't - don't do that. That's not nice. What about you, Phyllis? You and Dwight were close.", "order": 11526, "speaker": "Michael Scott"},
  {"season": 3, "episode": 13, "scene": 24, "line": "No. Sorry.", "order": 11527, "speaker": "Phyllis Lapin"},
  {"season": 3, "episode": 13, "scene": 25, "line": "Dwight has a big personality and I have a big personality. And a lot of times when two people like that get together it can be explosive.", "order": 11528, "speaker": "Phyllis Lapin"},
  {"season": 3, "episode": 13, "scene": 26, "line": "Really comin' down out there. Commute's gonna be hell.", "order": 11529, "speaker": "Paris"},
  {"season": 3, "episode": 13, "scene": 26, "line": "I have snow tires and chains. Plus exceptional hand-eye coordination.", "order": 11530, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 13, "scene": 26, "line": "So um, where were you workin' before this?", "order": 11531, "speaker": "Paris"},
  {"season": 3, "episode": 13, "scene": 26, "line": "Dunder-Mifflin.", "order": 11532, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 13, "scene": 26, "line": "What kind of company is that?", "order": 11533, "speaker": "Paris"},
  {"season": 3, "episode": 13, "scene": 26, "line": "[scoffs] Paper company. They're only one of Staples' top competitors in the area.", "order": 11534, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 13, "scene": 26, "line": "I never heard of 'em.", "order": 11535, "speaker": "Paris"},
  {"season": 3, "episode": 13, "scene": 26, "line": "Whoa. Really? Have you heard of paper?", "order": 11536, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 13, "scene": 26, "line": "You gonna be like that, huh?", "order": 11537, "speaker": "Paris"},
  {"season": 3, "episode": 13, "scene": 27, "line": "I don't like him, his giant head, or his beady little eyes. That's all I got to say on the matter.", "order": 11538, "speaker": "Paris"},
  {"season": 3, "episode": 13, "scene": 28, "line": "[singing] In your he-ead, in your he-eyd-ed. Zombie. Zombie. Zombie. Ey, ey, ey, ey. In your he-eyd-ed.", "order": 11539, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 13, "scene": 28, "line": "Would you like to pull a prank on Andy?", "order": 11540, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 13, "scene": 28, "line": "Oh, I'm kind of in the middle of -- yes please.", "order": 11541, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 13, "scene": 28, "line": "Okay, good. Stay right here.", "order": 11542, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 13, "scene": 28, "line": "[after Jim knocks over his pencils] Oh. Good move, Tuna. Nice one.", "order": 11543, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 13, "scene": 28, "line": "[hands Pam Andy's phone] Are there any messages?", "order": 11544, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 13, "scene": 28, "line": "Nope.", "order": 11545, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 13, "scene": 28, "line": "So weird.", "order": 11546, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 13, "scene": 28, "line": "[takes the phone] Hmm.", "order": 11547, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 13, "scene": 29, "line": "Nice to have Oscar back.", "order": 11548, "speaker": "Michael Scott"},
  {"season": 3, "episode": 13, "scene": 29, "line": "Yeah.", "order": 11549, "speaker": "Angela Martin"},
  {"season": 3, "episode": 13, "scene": 30, "line": "Large Tuna. Have you seen my cell phone device?", "order": 11550, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 13, "scene": 30, "line": "No.", "order": 11551, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 13, "scene": 30, "line": "Cause someone is calling right now. There is a call.", "order": 11552, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 13, "scene": 31, "line": "Angela?", "order": 11553, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 13, "scene": 31, "line": "Oh. [hands Pam tape]", "order": 11554, "speaker": "Angela Martin"},
  {"season": 3, "episode": 13, "scene": 31, "line": "Is everything okay?", "order": 11555, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 13, "scene": 31, "line": "No.", "order": 11556, "speaker": "Angela Martin"},
  {"season": 3, "episode": 13, "scene": 32, "line": "What's going on?", "order": 11557, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 13, "scene": 32, "line": "What are you talking about?", "order": 11558, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 13, "scene": 32, "line": "Where is my FREAKING phone?!", "order": 11559, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 13, "scene": 32, "line": "You know what? Maybe it's in the ceiling.", "order": 11560, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 13, "scene": 32, "line": "Maybe you're in the ceiling!", "order": 11561, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 13, "scene": 32, "line": "Okay.", "order": 11562, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 13, "scene": 32, "line": "[trying to look in Phyllis's desk, she slams the drawer shut] I don't trust you, Phyllis!", "order": 11563, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 13, "scene": 33, "line": "I wanted to let you know that Dwight was late that morning because he was driving to New York to drop off the correlated documents that I forgot to send. Though to be fair, Kevin never reminded me.", "order": 11564, "speaker": "Angela Martin"},
  {"season": 3, "episode": 13, "scene": 33, "line": "Why would Dwight do that for you? I think I know why. Because Dwight loves this company.", "order": 11565, "speaker": "Michael Scott"},
  {"season": 3, "episode": 13, "scene": 33, "line": "Yes.", "order": 11566, "speaker": "Angela Martin"},
  {"season": 3, "episode": 13, "scene": 33, "line": "Do you think that anyone else out there would have driven to corporate for you?", "order": 11567, "speaker": "Michael Scott"},
  {"season": 3, "episode": 13, "scene": 33, "line": "None of them. Especially not Andy.", "order": 11568, "speaker": "Angela Martin"},
  {"season": 3, "episode": 13, "scene": 34, "line": "Pam, I have a mission to accomplish. Make sure this party gets rolling and I will be back shortly.", "order": 11569, "speaker": "Michael Scott"},
  {"season": 3, "episode": 13, "scene": 34, "line": "Wait up. Where are you going? Do you want me to come with?", "order": 11570, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 13, "scene": 34, "line": "Um.", "order": 11571, "speaker": "Michael Scott"},
  {"season": 3, "episode": 13, "scene": 34, "line": "Just listen, I forgot to tell you the plan for this Saturday. You, me, bars, beers, buzzed. Wings. Shots. Drunk. Waitresses, hot. Football - Cornell/Hofstra. Slaughter. Then a quick nap at my place and we'll hit the tiz-own.", "order": 11572, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 13, "scene": 34, "line": "No. I don't want to do any of that.", "order": 11573, "speaker": "Michael Scott"},
  {"season": 3, "episode": 13, "scene": 34, "line": "Duh. Which is why I was just joking about doing that.", "order": 11574, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 13, "scene": 34, "line": "No, just stop. Stop. Stop doing it. You're going to drive me crazy.", "order": 11575, "speaker": "Michael Scott"},
  {"season": 3, "episode": 13, "scene": 34, "line": "Fine. I'll just sit at my desk and be quiet. Sorry I annoyed you with my friendship. [Andy's phone rings] Excuse me. And I'm also sorry that a lot of people here for some reason think it's funny to steal someone's personal property and hide it from them. Here's a little newsflash! It's not funny! In fact, it's pretty freakin' unfunny! Oh, my GOD. [punches a hole in the wall] That... was an overreaction. Gonna hit the break room. Does anybody want anything? Pam, you good?", "order": 11576, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 13, "scene": 34, "line": "Yeah.", "order": 11577, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 13, "scene": 34, "line": "Sure? Okay.", "order": 11578, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 13, "scene": 35, "line": "[to customer] Well, that question is meaningless. Just go with the copy paper. It's your funeral. See how that works out for you.", "order": 11579, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 13, "scene": 35, "line": "Hey.", "order": 11580, "speaker": "Michael Scott"},
  {"season": 3, "episode": 13, "scene": 35, "line": "Hey.", "order": 11581, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 13, "scene": 35, "line": "What's up?", "order": 11582, "speaker": "Michael Scott"},
  {"season": 3, "episode": 13, "scene": 35, "line": "Same old.", "order": 11583, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 13, "scene": 35, "line": "Um. It takes a big man to admit his mistake. And I am that big man. Angela from accounting told me what you did.", "order": 11584, "speaker": "Michael Scott"},
  {"season": 3, "episode": 13, "scene": 35, "line": "Oh my God, she told you?", "order": 11585, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 13, "scene": 35, "line": "Yes, she did. And Dwight, if you were willing to do something like that for some random co-worker, then clearly I have misjudged you from the beginning, and I apologize.", "order": 11586, "speaker": "Michael Scott"},
  {"season": 3, "episode": 13, "scene": 35, "line": "Accepted.", "order": 11587, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 13, "scene": 35, "line": "How's this place treating you?", "order": 11588, "speaker": "Michael Scott"},
  {"season": 3, "episode": 13, "scene": 35, "line": "[scoffs] The boss isn't funny.", "order": 11589, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 13, "scene": 35, "line": "Oh, well.", "order": 11590, "speaker": "Michael Scott"},
  {"season": 3, "episode": 13, "scene": 35, "line": "I don't get to wear my ties.", "order": 11591, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 13, "scene": 35, "line": "No. I'm sure.", "order": 11592, "speaker": "Michael Scott"},
  {"season": 3, "episode": 13, "scene": 35, "line": "So?", "order": 11593, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 13, "scene": 35, "line": "So, maybe you should come back. You should come back. Please.", "order": 11594, "speaker": "Michael Scott"},
  {"season": 3, "episode": 13, "scene": 35, "line": "I don't want to do your laundry anymore.", "order": 11595, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 13, "scene": 35, "line": "We can talk about that. [Dwight high fives Michael] All right.", "order": 11596, "speaker": "Michael Scott"},
  {"season": 3, "episode": 13, "scene": 36, "line": "[inspecting the hole in the wall] Oh my God, that's half-inch drywall.", "order": 11597, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 13, "scene": 36, "line": "I think we broke his brain. [they both snicker]", "order": 11598, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 13, "scene": 36, "line": "[imitating Andy] 'It's not freakin' funny!'", "order": 11599, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 13, "scene": 37, "line": "Are you enjoying your fiesta?", "order": 11600, "speaker": "Angela Martin"},
  {"season": 3, "episode": 13, "scene": 37, "line": "Actually, yeah. I didn't think I would, but turns out -- [Angela walks away] it's great.", "order": 11601, "speaker": "Oscar Martinez"},
  {"season": 3, "episode": 13, "scene": 37, "line": "Ladies and gentlemen! May I present... Mr. Dwight Schrute!", "order": 11602, "speaker": "Michael Scott"},
  {"season": 3, "episode": 13, "scene": 37, "line": "Yay. [scattered appalause]", "order": 11603, "speaker": "Everybody"},
  {"season": 3, "episode": 13, "scene": 37, "line": "Welcome back.", "order": 11604, "speaker": "Angela Martin"},
  {"season": 3, "episode": 13, "scene": 37, "line": "Thank you.", "order": 11605, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 13, "scene": 37, "line": "Okay, Dwight, you can let go of her hand. You're gonna break it. [looks around the room] Not bad, huh?", "order": 11606, "speaker": "Michael Scott"},
  {"season": 3, "episode": 13, "scene": 37, "line": "You did this for me? [camera pans to 'Welcome Back Oscar' sign]", "order": 11607, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 13, "scene": 37, "line": "Guilty.", "order": 11608, "speaker": "Michael Scott"},
  {"season": 3, "episode": 13, "scene": 38, "line": "Oh... Where did you get this stuff?", "order": 11609, "speaker": "Creed Bratton"},
  {"season": 3, "episode": 13, "scene": 38, "line": "Gerty's.", "order": 11610, "speaker": "Meredith Palmer"},
  {"season": 3, "episode": 13, "scene": 38, "line": "Which aisle?", "order": 11611, "speaker": "Creed Bratton"},
  {"season": 3, "episode": 13, "scene": 38, "line": "I don't remember.", "order": 11612, "speaker": "Meredith Palmer"},
  {"season": 3, "episode": 13, "scene": 38, "line": "Well, draw me a map, mama.", "order": 11613, "speaker": "Creed Bratton"},
  {"season": 3, "episode": 13, "scene": 39, "line": "Pam. I will shake mine and then you will shake yours.", "order": 11614, "speaker": "Michael Scott"},
  {"season": 3, "episode": 13, "scene": 39, "line": "No, I will not.", "order": 11615, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 13, "scene": 40, "line": "So does this remind you of your childhood right now?", "order": 11616, "speaker": "Michael Scott"},
  {"season": 3, "episode": 13, "scene": 40, "line": "It reminds me a lot of the 'Three Amigos' with Steve Martin and Chevy Chase.", "order": 11617, "speaker": "Oscar Martinez"},
  {"season": 3, "episode": 13, "scene": 40, "line": "Wow. Thank you. Wow, that's-- thanks so much.", "order": 11618, "speaker": "Michael Scott"},
  {"season": 3, "episode": 13, "scene": 41, "line": "Hey.", "order": 11619, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 13, "scene": 41, "line": "[sighs] Do you still have feelings for her?", "order": 11620, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 13, "scene": 41, "line": "[long pause and then he sighs and nods] Yes.", "order": 11621, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 13, "scene": 42, "line": "And now, ladies and gentlemen, the big finale! Sir, would you do the honor? [hands broom to Dwight, though Oscar thinks he's handing it to him]", "order": 11622, "speaker": "Michael Scott"},
  {"season": 3, "episode": 13, "scene": 42, "line": "Oh, man. [Kevin starts to put a blindfold on] No, no, no. I don't need it. Get out! [beats up pinata]", "order": 11623, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 13, "scene": 43, "line": "It takes a big man to admit his mistake and that's what I did. The important thing is I learned something. I don't want somebody sucking up to me because they think I'm going to help their career. I want them sucking up to me because they genuinely love me. Hmm.", "order": 11624, "speaker": "Michael Scott"},
  {"season": 3, "episode": 13, "scene": 44, "line": "So Michael had a little chat with corporate and they decided to send me to management training. Anger management, technically, but still. Management material. [gets out of his car] This whole thing supposed to take ten weeks, but I can be done in five. How? Name repetition, personality mirroring, and positive reinforcement through nods and smiles. So, don't worry about old Andy Bernard. I'll be back. Just like Rambo, so.", "order": 11625, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 13, "scene": 44, "line": "Oh, hi. You must be Andy.", "order": 11626, "speaker": "Marcy"},
  {"season": 3, "episode": 13, "scene": 44, "line": "Oh, hi! Yes. I am and you must be... Marcy!", "order": 11627, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 13, "scene": 44, "line": "That's right, it's so good to meet you.", "order": 11628, "speaker": "Marcy"},
  {"season": 3, "episode": 13, "scene": 44, "line": "It's so good to meet you!", "order": 11629, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 13, "scene": 44, "line": "Thanks. Well, you ready to have some fun?", "order": 11630, "speaker": "Marcy"},
  {"season": 3, "episode": 13, "scene": 44, "line": "Yeah.", "order": 11631, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 14, "scene": 1, "line": "Hello son. If you're watching this, that means I'm already dead. Life is a road---", "order": 11632, "speaker": "Michael Scott"},
  {"season": 3, "episode": 14, "scene": 1, "line": "How do you know it's going to be a boy?", "order": 11633, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 14, "scene": 1, "line": "How, would you stop interrupting please?", "order": 11634, "speaker": "Michael Scott"},
  {"season": 3, "episode": 14, "scene": 2, "line": "Yesterday I was scraping some gunk off my wall sockets with a metal fork and I gave myself the nastiest shock. And when I came to, I had an epiphery. Life is precious. And if I die I want my son to know the dealio. The dealio of life.", "order": 11635, "speaker": "Michael Scott"},
  {"season": 3, "episode": 14, "scene": 3, "line": "Here are some things that I want to teach you that your mother won't be able to.", "order": 11636, "speaker": "Michael Scott"},
  {"season": 3, "episode": 14, "scene": 4, "line": "To jump start a car, first pop the hood. Then you take these bad boys and clip them anywhere on the engine. Then you take these and clip them wherever.", "order": 11637, "speaker": "Michael Scott"},
  {"season": 3, "episode": 14, "scene": 4, "line": "[Shakes head]", "order": 11638, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 14, "scene": 5, "line": "Number eight, learn how to take off a woman's bra.", "order": 11639, "speaker": "Michael Scott"},
  {"season": 3, "episode": 14, "scene": 5, "line": "What?", "order": 11640, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 14, "scene": 5, "line": "We will demonstrate on Pam.", "order": 11641, "speaker": "Michael Scott"},
  {"season": 3, "episode": 14, "scene": 5, "line": "No. No. [Leaves]", "order": 11642, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 14, "scene": 5, "line": "Come on.", "order": 11643, "speaker": "Michael Scott"},
  {"season": 3, "episode": 14, "scene": 6, "line": "[Dwight is wearing a bra] You just twist your hand until something breaks.", "order": 11644, "speaker": "Michael Scott"},
  {"season": 3, "episode": 14, "scene": 6, "line": "Ow.", "order": 11645, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 14, "scene": 6, "line": "Well you get the picture. Thanks Pam.", "order": 11646, "speaker": "Michael Scott"},
  {"season": 3, "episode": 14, "scene": 7, "line": "And remember no matter what, I will always love you.", "order": 11647, "speaker": "Michael Scott"},
  {"season": 3, "episode": 14, "scene": 7, "line": "What if he's a murderer?", "order": 11648, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 14, "scene": 7, "line": "He's not going to be a murderer.", "order": 11649, "speaker": "Michael Scott"},
  {"season": 3, "episode": 14, "scene": 7, "line": "Maybe that's how you die.", "order": 11650, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 14, "scene": 7, "line": "You know what, Dwight, do you want to do this, or no?", "order": 11651, "speaker": "Michael Scott"},
  {"season": 3, "episode": 14, "scene": 7, "line": "I want to do this.", "order": 11652, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 14, "scene": 7, "line": "Okay. From the top. Ready? Three-", "order": 11653, "speaker": "Michael Scott"},
  {"season": 3, "episode": 14, "scene": 7, "line": "Action.", "order": 11654, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 14, "scene": 8, "line": "Hello everyone! As you know, we are six days away from Phyllis' wedding. [Applause] So get your suits to the dry cleaner and get your hair did. And Karen, um, you might want to invest in a dress or a skirt of some kind if you don't already have one. This may be Phyllis' only wedding ever. It is my job to ensure that none of you look like ragamuffins. So I am instituting prima nocta.", "order": 11655, "speaker": "Michael Scott"},
  {"season": 3, "episode": 14, "scene": 9, "line": "Prima nocta, I believe from the movie Braveheart, and confirmed on wikipedia, is when the king got to deflower every new bride on her wedding night. So...", "order": 11656, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 14, "scene": 10, "line": "I'm sorry. I had a very different understanding as to what prima nocta meant.", "order": 11657, "speaker": "Michael Scott"},
  {"season": 3, "episode": 14, "scene": 11, "line": "I'm trying to get everyone excited about Phyllis' wedding because I want her to get people excited about my wedding when the time comes. Which won't be hard, because it's going to be awesome. A lot better than hers, that's for sure. It'll probably be on a boat.", "order": 11658, "speaker": "Michael Scott"},
  {"season": 3, "episode": 14, "scene": 12, "line": "What's up spinsters?", "order": 11659, "speaker": "Michael Scott"},
  {"season": 3, "episode": 14, "scene": 12, "line": "Nothing. You know this is a luncheon shower. Girls only.", "order": 11660, "speaker": "Angela Martin"},
  {"season": 3, "episode": 14, "scene": 12, "line": "No problem. The guys are having a little shindig of their own in the warehouse. From 2:30 to 3:15. It is the only time that Bob was available. Sort of a guy's night out. A G-N-O if you will. A Gah-No. Actually, it's more of a guy's afternoon in. A G-A-I. A gay. Not- not- it's uh, not gay, it's just a, it's a bridal shower for guys. A guy shower. An hour long shower with guys.", "order": 11661, "speaker": "Michael Scott"},
  {"season": 3, "episode": 14, "scene": 13, "line": "I guess Jim and I have had a little bit of a rough patch for the past couple weeks but we had some really good talks and actually now I think that we're better than ever.", "order": 11662, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 14, "scene": 14, "line": "Karen and I had a long talk last night and the night before that and uh, every night, for the last five nights.", "order": 11663, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 14, "scene": 15, "line": "Something's up with Jim and Karen. Not that I've been eavesdropping. It's not really any of my business, but I've gotten pretty good at reading the back of Jim's neck.", "order": 11664, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 14, "scene": 16, "line": "Halpert! Tall, queer, handsome as ever. Hey everybody it's me, Jim. Hello, hello. Todd Packer.", "order": 11665, "speaker": "Todd Packer"},
  {"season": 3, "episode": 14, "scene": 16, "line": "Karen Fillipelli. Jim's girlfriend.", "order": 11666, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 14, "scene": 16, "line": "Shut up!", "order": 11667, "speaker": "Todd Packer"},
  {"season": 3, "episode": 14, "scene": 16, "line": "Yep.", "order": 11668, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 14, "scene": 16, "line": "Shut it!", "order": 11669, "speaker": "Todd Packer"},
  {"season": 3, "episode": 14, "scene": 16, "line": "That's rude.", "order": 11670, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 14, "scene": 16, "line": "Either this chick is a dude or Halpert got scared straight!", "order": 11671, "speaker": "Todd Packer"},
  {"season": 3, "episode": 14, "scene": 16, "line": "Yes. Yes! Oh! There-oh! [Clutches chest and falls to the floor]", "order": 11672, "speaker": "Michael Scott"},
  {"season": 3, "episode": 14, "scene": 16, "line": "What happened?", "order": 11673, "speaker": "Todd Packer"},
  {"season": 3, "episode": 14, "scene": 16, "line": "Oh God-", "order": 11674, "speaker": "Michael Scott"},
  {"season": 3, "episode": 14, "scene": 16, "line": "Quick somebody help! Help the man! [Pretends to kick Michael]", "order": 11675, "speaker": "Todd Packer"},
  {"season": 3, "episode": 14, "scene": 16, "line": "No, no, no!", "order": 11676, "speaker": "Michael Scott"},
  {"season": 3, "episode": 14, "scene": 17, "line": "I can't believe you're not going to be there. It's going to be good, it's going to be a great bachelor party man.", "order": 11677, "speaker": "Michael Scott"},
  {"season": 3, "episode": 14, "scene": 17, "line": "I have a full day of sales calls.", "order": 11678, "speaker": "Todd Packer"},
  {"season": 3, "episode": 14, "scene": 17, "line": "You should get out of them.", "order": 11679, "speaker": "Michael Scott"},
  {"season": 3, "episode": 14, "scene": 17, "line": "[Breaks pencil.]", "order": 11680, "speaker": "Todd Packer"},
  {"season": 3, "episode": 14, "scene": 17, "line": "It's the only time he can do it. 2:30 to 3:15. It's going to be great. We're going to be doing some darts, we're going to be grilling up some steaks, got some pie. Going to be very delicious.", "order": 11681, "speaker": "Michael Scott"},
  {"season": 3, "episode": 14, "scene": 17, "line": "And what kind of stripper did you get?", "order": 11682, "speaker": "Todd Packer"},
  {"season": 3, "episode": 14, "scene": 17, "line": "I did not order a stripper.", "order": 11683, "speaker": "Michael Scott"},
  {"season": 3, "episode": 14, "scene": 17, "line": "You didn't order a stripper? Have you ever even been to a bachelor party?", "order": 11684, "speaker": "Todd Packer"},
  {"season": 3, "episode": 14, "scene": 17, "line": "Um, not personally, no.", "order": 11685, "speaker": "Michael Scott"},
  {"season": 3, "episode": 14, "scene": 17, "line": "Mike, okay, a stripper is bachelor party 101. If you don't get a stripper your party is going to suck it hard.", "order": 11686, "speaker": "Todd Packer"},
  {"season": 3, "episode": 14, "scene": 17, "line": "I can't get a stripper here. Sexual Harassment.", "order": 11687, "speaker": "Michael Scott"},
  {"season": 3, "episode": 14, "scene": 17, "line": "Just get one for the girls too. That evens it out. You know, separate but equal.", "order": 11688, "speaker": "Todd Packer"},
  {"season": 3, "episode": 14, "scene": 17, "line": "So that's what that means.", "order": 11689, "speaker": "Michael Scott"},
  {"season": 3, "episode": 14, "scene": 18, "line": "Okay everybody, slight change of plans, we are still going to be having two parties but each is going to get a little extra dose of naught-ay. Uh! [Spanks himself]", "order": 11690, "speaker": "Michael Scott"},
  {"season": 3, "episode": 14, "scene": 18, "line": "Alright! Uh! [Spanks himself]", "order": 11691, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 14, "scene": 18, "line": "Gay.", "order": 11692, "speaker": "Michael Scott"},
  {"season": 3, "episode": 14, "scene": 18, "line": "What?", "order": 11693, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 14, "scene": 18, "line": "Okay. Co-ed naked strippers in this office. For realsies.", "order": 11694, "speaker": "Michael Scott"},
  {"season": 3, "episode": 14, "scene": 18, "line": "Under no circumstance should a man strip off his clothes in this office.", "order": 11695, "speaker": "Angela Martin"},
  {"season": 3, "episode": 14, "scene": 18, "line": "SHUT UP ANGELA!", "order": 11696, "speaker": "Meredith Palmer"},
  {"season": 3, "episode": 14, "scene": 19, "line": "Hey.", "order": 11697, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 14, "scene": 19, "line": "Hey.", "order": 11698, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 14, "scene": 19, "line": "Ugh.", "order": 11699, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 14, "scene": 19, "line": "Ugh.", "order": 11700, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 14, "scene": 19, "line": "Everything okay?", "order": 11701, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 14, "scene": 19, "line": "Oh yeah. Why?", "order": 11702, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 14, "scene": 19, "line": "Well you seem a little tired.", "order": 11703, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 14, "scene": 19, "line": "Oh. Yeah well, I guess there's been a couple late nights. Karen and I have been up talking.", "order": 11704, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 14, "scene": 19, "line": "You should get more sleep.", "order": 11705, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 14, "scene": 19, "line": "Yeah, I know I should.", "order": 11706, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 14, "scene": 19, "line": "Never underestimate the power of a good night's sleep.", "order": 11707, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 14, "scene": 19, "line": "No, I'm sure you're right.", "order": 11708, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 14, "scene": 19, "line": "When I get eight hours, compared to like six hours, like, big difference.", "order": 11709, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 14, "scene": 19, "line": "Really?", "order": 11710, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 14, "scene": 19, "line": "Oh yeah. Gotta get your REM cycle going with the whole sleeping. Better than not.", "order": 11711, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 14, "scene": 19, "line": "Good advice Beesley. Thanks. See you out there.", "order": 11712, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 14, "scene": 19, "line": "Yeah. Don't fall asleep at your desk. [Turning towards the vending machine] Oh my God.", "order": 11713, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 14, "scene": 20, "line": "Okay. We are off. Ryan and I are going to go get some supplies and I need you to handle hiring the strippers.", "order": 11714, "speaker": "Michael Scott"},
  {"season": 3, "episode": 14, "scene": 20, "line": "Absolutely not.", "order": 11715, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 14, "scene": 20, "line": "I'm on it.", "order": 11716, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 14, "scene": 20, "line": "Well get on it. And make it happen.", "order": 11717, "speaker": "Michael Scott"},
  {"season": 3, "episode": 14, "scene": 21, "line": "Ruddy cheeks, thick calves, no tats, no moles, no tats. No, TATS. Of course I want-", "order": 11718, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 14, "scene": 21, "line": "Stop. That's disgusting.", "order": 11719, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 14, "scene": 21, "line": "Leave me alone and get the male stripper.", "order": 11720, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 14, "scene": 21, "line": "Fine.", "order": 11721, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 14, "scene": 21, "line": "I knew you would, Nancy.", "order": 11722, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 14, "scene": 21, "line": "Sally.", "order": 11723, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 14, "scene": 21, "line": "No preference, what do you think redhead or brunette?", "order": 11724, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 14, "scene": 21, "line": "Blonde.", "order": 11725, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 14, "scene": 21, "line": "Nice. Do you have any blonde women?", "order": 11726, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 14, "scene": 22, "line": "[Giggles.]", "order": 11727, "speaker": "Michael Scott"},
  {"season": 3, "episode": 14, "scene": 22, "line": "He hasn't even said a word yet. Just giggling.", "order": 11728, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 14, "scene": 22, "line": "[Pokes Ryan with a sex toy.] Gotcha, oh! [Phone rings.] Yes?", "order": 11729, "speaker": "Michael Scott"},
  {"season": 3, "episode": 14, "scene": 22, "line": "Are you okay?", "order": 11730, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 14, "scene": 22, "line": "I'm in the, I'm in the sex shop.", "order": 11731, "speaker": "Michael Scott"},
  {"season": 3, "episode": 14, "scene": 22, "line": "Ah, gotcha. Okay, so they have Albert Einstein, Ben Franklin, or Sponge Bob Squarepants.", "order": 11732, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 14, "scene": 22, "line": "Squarepants?", "order": 11733, "speaker": "Michael Scott"},
  {"season": 3, "episode": 14, "scene": 22, "line": "Yep, Spongebob Squarepants.", "order": 11734, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 14, "scene": 22, "line": "And you think that'd be sexy?", "order": 11735, "speaker": "Michael Scott"},
  {"season": 3, "episode": 14, "scene": 23, "line": "Michael referred me to a male strip club called Banana Slings. Instead, I called the Scholastic Speakers of Pennsylvania.", "order": 11736, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 14, "scene": 24, "line": "Hold the door please!", "order": 11737, "speaker": "Ben Franklin"},
  {"season": 3, "episode": 14, "scene": 24, "line": "Oh.", "order": 11738, "speaker": "Michael Scott"},
  {"season": 3, "episode": 14, "scene": 24, "line": "Thank you.", "order": 11739, "speaker": "Ben Franklin"},
  {"season": 3, "episode": 14, "scene": 24, "line": "Oh. Hello!", "order": 11740, "speaker": "Michael Scott"},
  {"season": 3, "episode": 14, "scene": 24, "line": "Hello!", "order": 11741, "speaker": "Ben Franklin"},
  {"season": 3, "episode": 14, "scene": 24, "line": "You wearing a thong?", "order": 11742, "speaker": "Michael Scott"},
  {"season": 3, "episode": 14, "scene": 24, "line": "What?", "order": 11743, "speaker": "Ben Franklin"},
  {"season": 3, "episode": 14, "scene": 25, "line": "Sparkling cider is very good.", "order": 11744, "speaker": "Angela Martin"},
  {"season": 3, "episode": 14, "scene": 25, "line": "I think that's champagne.", "order": 11745, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 14, "scene": 25, "line": "[Spits champagne back into her glass]", "order": 11746, "speaker": "Angela Martin"},
  {"season": 3, "episode": 14, "scene": 25, "line": "Hello ladies. Who here is a history buff? Who's a fan of buff naked? Without further ado, the one, the only, the sexy Mr. Benjamin Franklin.", "order": 11747, "speaker": "Michael Scott"},
  {"season": 3, "episode": 14, "scene": 25, "line": "Thank you for that introduction, Mr. Scott, and good afternoon fine gentlewomen of Dunder Mifflin.", "order": 11748, "speaker": "Ben Franklin"},
  {"season": 3, "episode": 14, "scene": 25, "line": "Half pants, right Mr. Franklin?", "order": 11749, "speaker": "Michael Scott"},
  {"season": 3, "episode": 14, "scene": 25, "line": "Knickers in fact, yes!", "order": 11750, "speaker": "Ben Franklin"},
  {"season": 3, "episode": 14, "scene": 25, "line": "He's in his knickers. Mr. Franklin, I would say you are probably one of the sexiest presidents ever.", "order": 11751, "speaker": "Michael Scott"},
  {"season": 3, "episode": 14, "scene": 25, "line": "Well, actually, I never was president.", "order": 11752, "speaker": "Ben Franklin"},
  {"season": 3, "episode": 14, "scene": 25, "line": "Yes, but, Ben Franklin was.", "order": 11753, "speaker": "Michael Scott"},
  {"season": 3, "episode": 14, "scene": 25, "line": "Ah. I'm here to teach you a little bit about my life and the era of the founding fathers.", "order": 11754, "speaker": "Ben Franklin"},
  {"season": 3, "episode": 14, "scene": 25, "line": "And when they came over on the Mayflower. Bow chicka bow.", "order": 11755, "speaker": "Michael Scott"},
  {"season": 3, "episode": 14, "scene": 25, "line": "Wait, this is the entertainment?", "order": 11756, "speaker": "Meredith Palmer"},
  {"season": 3, "episode": 14, "scene": 25, "line": "Yeah, alright, so I want you to give him your undivided attention and Mr. Franklin, if any of these ladies misbehave I give you permission to spank them. Especially that one. [Points to Phyllis]", "order": 11757, "speaker": "Michael Scott"},
  {"season": 3, "episode": 14, "scene": 26, "line": "Have you ever seen a stripper before?", "order": 11758, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 14, "scene": 26, "line": "Yes. Jennifer Garner portrayed one on Alias. It was one of her many aliases.", "order": 11759, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 14, "scene": 26, "line": "Yeah, me neither.", "order": 11760, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 14, "scene": 26, "line": "Hey.", "order": 11761, "speaker": "Elizabeth"},
  {"season": 3, "episode": 14, "scene": 26, "line": "Hi.", "order": 11762, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 14, "scene": 26, "line": "I'm Elizabeth, I'm the dancer that was requested.", "order": 11763, "speaker": "Elizabeth"},
  {"season": 3, "episode": 14, "scene": 26, "line": "Okay, I specifically ordered a stripper.", "order": 11764, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 14, "scene": 26, "line": "I'm the stripper.", "order": 11765, "speaker": "Elizabeth"},
  {"season": 3, "episode": 14, "scene": 26, "line": "Oh, okay good. Well in the future please identify yourself as such.", "order": 11766, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 14, "scene": 26, "line": "[Phone rings] Oh God.", "order": 11767, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 14, "scene": 26, "line": "[Reading the text message] Is she hot? Text back, 'Kind of.'", "order": 11768, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 14, "scene": 27, "line": "It was a warm June evening in 1752, the night of my fateful kite flight.", "order": 11769, "speaker": "Ben Franklin"},
  {"season": 3, "episode": 14, "scene": 27, "line": "Mr. Franklin?", "order": 11770, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 14, "scene": 27, "line": "Yes?", "order": 11771, "speaker": "Ben Franklin"},
  {"season": 3, "episode": 14, "scene": 27, "line": "Do you have a girlfriend?", "order": 11772, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 14, "scene": 27, "line": "I have a lovely wife, Deborah.", "order": 11773, "speaker": "Ben Franklin"},
  {"season": 3, "episode": 14, "scene": 27, "line": "But don't you also have girlfriends in Paris? Like a lot of them?", "order": 11774, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 14, "scene": 27, "line": "Uh, well that is a grey area of my life. Okay? So, it was a warm June evening.", "order": 11775, "speaker": "Ben Franklin"},
  {"season": 3, "episode": 14, "scene": 28, "line": "Look at that. Nothing like grilling in the great outdoors.", "order": 11776, "speaker": "Michael Scott"},
  {"season": 3, "episode": 14, "scene": 28, "line": "Is this the same grill you grilled your foot on?", "order": 11777, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 14, "scene": 28, "line": "No. Yes.", "order": 11778, "speaker": "Michael Scott"},
  {"season": 3, "episode": 14, "scene": 28, "line": "Oh gross.", "order": 11779, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 14, "scene": 28, "line": "But I got all the foot off of it.", "order": 11780, "speaker": "Michael Scott"},
  {"season": 3, "episode": 14, "scene": 29, "line": "Okay Ben Franklin!", "order": 11781, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 14, "scene": 29, "line": "Ben Franklin tied a cherry stem with his mouth!", "order": 11782, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 14, "scene": 29, "line": "Well, that is because I am a renaissance man.", "order": 11783, "speaker": "Ben Franklin"},
  {"season": 3, "episode": 14, "scene": 29, "line": "Ben Franklin, do you wear boxers, briefs, or pantaloons?", "order": 11784, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 14, "scene": 29, "line": "Well, you're very saucy! [Winks at Pam.]", "order": 11785, "speaker": "Ben Franklin"},
  {"season": 3, "episode": 14, "scene": 30, "line": "Guys, beef! It's what's for dinner! Who wants some man meat?", "order": 11786, "speaker": "Michael Scott"},
  {"season": 3, "episode": 14, "scene": 30, "line": "I do! I want some man meat!", "order": 11787, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 14, "scene": 30, "line": "Michael, Dwight would like your man meat.", "order": 11788, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 14, "scene": 30, "line": "Well then my man meat, he shall have. There you go. Deliciousity.", "order": 11789, "speaker": "Michael Scott"},
  {"season": 3, "episode": 14, "scene": 30, "line": "[Stanley's fork breaks.] Of course.", "order": 11790, "speaker": "Stanley Hudson"},
  {"season": 3, "episode": 14, "scene": 31, "line": "So I guess you have the Ben Franklin wig and the costume and you figure, how can I put this to practical use?", "order": 11791, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 14, "scene": 31, "line": "Well I like to think that his dad was a Ben Franklin impersonator and he really pressured him into it.", "order": 11792, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 14, "scene": 31, "line": "Hey um, I wanted to talk to you. I know this is weird or whatever, but Jim told me about you guys.", "order": 11793, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 14, "scene": 31, "line": "What do you mean?", "order": 11794, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 14, "scene": 31, "line": "Well that you kissed. And we talked it through and it's totally fine, it's not a big deal. It's just a kiss. Wait- you're not still interested in him?", "order": 11795, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 14, "scene": 31, "line": "Oh yeah.", "order": 11796, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 14, "scene": 31, "line": "Really?", "order": 11797, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 14, "scene": 31, "line": "Oh no, I was confused by your phrasing. You should definitely go out with Jim. I mean, you're going out with Jim. I'm not going out with Jim. You're dating him, which is awesome, because you guys are great together.", "order": 11798, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 14, "scene": 31, "line": "Okay.", "order": 11799, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 14, "scene": 31, "line": "I'm not into Jim. Yeah.", "order": 11800, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 14, "scene": 31, "line": "So um, well good.", "order": 11801, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 14, "scene": 31, "line": "Yeah. Sorry.", "order": 11802, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 14, "scene": 31, "line": "What are you sorry about?", "order": 11803, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 14, "scene": 31, "line": "Um, what?", "order": 11804, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 14, "scene": 31, "line": "What are you sorry about?", "order": 11805, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 14, "scene": 31, "line": "Nothing. I was just thinking of something else.", "order": 11806, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 14, "scene": 32, "line": "Okay. The game is no limit deuce to seven lowball. Lines twenty five fifty, nickels are worth ten, dimes twenty-five, and quarters fifty. Nothing wild.", "order": 11807, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 14, "scene": 32, "line": "I call shuffle. Here we go. [Spreads cards out all over the table]", "order": 11808, "speaker": "Michael Scott"},
  {"season": 3, "episode": 14, "scene": 32, "line": "Michael.", "order": 11809, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 14, "scene": 32, "line": "Ah, gentlemen, the entertainment is here. Everybody I'd like to introduce you to Elizabeth.", "order": 11810, "speaker": "Michael Scott"},
  {"season": 3, "episode": 14, "scene": 32, "line": "Hi guys!", "order": 11811, "speaker": "Elizabeth"},
  {"season": 3, "episode": 14, "scene": 32, "line": "Nice outfit.", "order": 11812, "speaker": "Michael Scott"},
  {"season": 3, "episode": 14, "scene": 32, "line": "I hear there's an important meeting here.", "order": 11813, "speaker": "Elizabeth"},
  {"season": 3, "episode": 14, "scene": 33, "line": "I'm not really into strippers. You know what I find sexy? Pam's art. She's an artist and I appreciate that. It's very moving and sexy. The art.", "order": 11814, "speaker": "Roy Anderson"},
  {"season": 3, "episode": 14, "scene": 34, "line": "Hit it. [Feel Like Makin' Love plays in background] So where's the groom?", "order": 11815, "speaker": "Elizabeth"},
  {"season": 3, "episode": 14, "scene": 34, "line": "He's right there. There he is.", "order": 11816, "speaker": "Michael Scott"},
  {"season": 3, "episode": 14, "scene": 34, "line": "Absolutely not. That's all you. That's all you.", "order": 11817, "speaker": "Bob Vance"},
  {"season": 3, "episode": 14, "scene": 34, "line": "Okay. Alright, I'll do it. Yeah. Lets do it. Lets do this thing. Yeah. What do I?", "order": 11818, "speaker": "Michael Scott"},
  {"season": 3, "episode": 14, "scene": 34, "line": "Just sit on down.", "order": 11819, "speaker": "Elizabeth"},
  {"season": 3, "episode": 14, "scene": 34, "line": "Alright.", "order": 11820, "speaker": "Michael Scott"},
  {"season": 3, "episode": 14, "scene": 34, "line": "Alright.", "order": 11821, "speaker": "Elizabeth"},
  {"season": 3, "episode": 14, "scene": 34, "line": "Okay.", "order": 11822, "speaker": "Michael Scott"},
  {"season": 3, "episode": 14, "scene": 34, "line": "Here we go.", "order": 11823, "speaker": "Elizabeth"},
  {"season": 3, "episode": 14, "scene": 34, "line": "Here we go. Alright!", "order": 11824, "speaker": "Michael Scott"},
  {"season": 3, "episode": 14, "scene": 34, "line": "Yeah!", "order": 11825, "speaker": "Elizabeth"},
  {"season": 3, "episode": 14, "scene": 34, "line": "Not bad! Not bad! You smell nice. Like Tide.", "order": 11826, "speaker": "Michael Scott"},
  {"season": 3, "episode": 14, "scene": 34, "line": "Mmm, what's that?", "order": 11827, "speaker": "Elizabeth"},
  {"season": 3, "episode": 14, "scene": 34, "line": "You smell like Tide detergent. Do you use Tide detergent? Ha ha ha! I have a girlfriend so...", "order": 11828, "speaker": "Michael Scott"},
  {"season": 3, "episode": 14, "scene": 34, "line": "I bet she'd be jealous. [Rips off shirt]", "order": 11829, "speaker": "Elizabeth"},
  {"season": 3, "episode": 14, "scene": 34, "line": "Yeah she probably, yeah she would be. You know what, okay, excuse me. Please stop it, stop it. Turn off the music. This is wrong, this is wrong! This is wrong! I have a girlfriend. And you are engaged and I'm sure you have a boyfriend in prison or something so lets just clear out okay. Shame on you. Go back to work!", "order": 11830, "speaker": "Michael Scott"},
  {"season": 3, "episode": 14, "scene": 35, "line": "Elizabeth, I want you to sit here. When the phone rings, answer it.", "order": 11831, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 14, "scene": 35, "line": "You want me to answer phones with my clothes on.", "order": 11832, "speaker": "Elizabeth"},
  {"season": 3, "episode": 14, "scene": 35, "line": "We hired you for three hours work and we're going to get it.", "order": 11833, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 14, "scene": 35, "line": "Oh I love your poster.", "order": 11834, "speaker": "Elizabeth"},
  {"season": 3, "episode": 14, "scene": 35, "line": "Thank you.", "order": 11835, "speaker": "Angela Martin"},
  {"season": 3, "episode": 14, "scene": 35, "line": "[Mouths 'hi' at the stripper]", "order": 11836, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 14, "scene": 36, "line": "You know I invented electricity.", "order": 11837, "speaker": "Ben Franklin"},
  {"season": 3, "episode": 14, "scene": 36, "line": "I know.", "order": 11838, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 14, "scene": 36, "line": "Well I'm sensing a little electricity right here.", "order": 11839, "speaker": "Ben Franklin"},
  {"season": 3, "episode": 14, "scene": 36, "line": "Didn't Ben Franklin have syphilis?", "order": 11840, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 14, "scene": 36, "line": "Yes, but I don't. My name is Gordon.", "order": 11841, "speaker": "Ben Franklin"},
  {"season": 3, "episode": 14, "scene": 36, "line": "Ohhh.", "order": 11842, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 14, "scene": 37, "line": "On one hand I want to be honest with Jan and tell her the truth but on the other hand I'm afraid she's going to dump me.", "order": 11843, "speaker": "Michael Scott"},
  {"season": 3, "episode": 14, "scene": 37, "line": "You know Michael, I fathered an illegitimate son.", "order": 11844, "speaker": "Ben Franklin"},
  {"season": 3, "episode": 14, "scene": 37, "line": "Really.", "order": 11845, "speaker": "Michael Scott"},
  {"season": 3, "episode": 14, "scene": 37, "line": "But I kept all this from my wife Deborah. These things only serve to upset the women. They are the gentler sex.", "order": 11846, "speaker": "Ben Franklin"},
  {"season": 3, "episode": 14, "scene": 37, "line": "Well, Ben Franklin, you're really kind of a sleezebag.", "order": 11847, "speaker": "Michael Scott"},
  {"season": 3, "episode": 14, "scene": 38, "line": "Stripper? Could I ask you a question about women? Um, should I tell my girlfriend that you danced up on me?", "order": 11848, "speaker": "Michael Scott"},
  {"season": 3, "episode": 14, "scene": 38, "line": "Secrets, secrets are no fun. Secrets, secrets hurt someone.", "order": 11849, "speaker": "Elizabeth"},
  {"season": 3, "episode": 14, "scene": 38, "line": "Wow. Thank you.", "order": 11850, "speaker": "Michael Scott"},
  {"season": 3, "episode": 14, "scene": 39, "line": "Care for a piece of chocolate?", "order": 11851, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 14, "scene": 39, "line": "Chocolate! Where did you acquire it? That is a delicacy in the Amazon but it has not yet been imported to the United States.", "order": 11852, "speaker": "Ben Franklin"},
  {"season": 3, "episode": 14, "scene": 39, "line": "Who is the king of Austria?", "order": 11853, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 14, "scene": 39, "line": "Joseph the 2nd.", "order": 11854, "speaker": "Ben Franklin"},
  {"season": 3, "episode": 14, "scene": 39, "line": "Who is the king of Prussia?", "order": 11855, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 14, "scene": 39, "line": "Fredrick Wilhelm the 3rd.", "order": 11856, "speaker": "Ben Franklin"},
  {"season": 3, "episode": 14, "scene": 39, "line": "Who is the king of England?", "order": 11857, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 14, "scene": 39, "line": "Why the tyrant King George, of course!", "order": 11858, "speaker": "Ben Franklin"},
  {"season": 3, "episode": 14, "scene": 40, "line": "I don't care what Jim says, that is not the real Ben Franklin. I am 99% sure.", "order": 11859, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 14, "scene": 41, "line": "[On speaker phone] Michael, I left a meeting. What is so urgent? Are you, are you okay?", "order": 11860, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 14, "scene": 41, "line": "Not really, look I don't know how to say this so I just will.", "order": 11861, "speaker": "Michael Scott"},
  {"season": 3, "episode": 14, "scene": 41, "line": "Okay.", "order": 11862, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 14, "scene": 41, "line": "I went to a bachelor party and things got a little out of hand.", "order": 11863, "speaker": "Michael Scott"},
  {"season": 3, "episode": 14, "scene": 41, "line": "Uh when, when did, when last night?", "order": 11864, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 14, "scene": 41, "line": "No, today at work.", "order": 11865, "speaker": "Michael Scott"},
  {"season": 3, "episode": 14, "scene": 41, "line": "You went to a bachelor party at work?", "order": 11866, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 14, "scene": 41, "line": "Yes. I kind of arranged it.", "order": 11867, "speaker": "Michael Scott"},
  {"season": 3, "episode": 14, "scene": 42, "line": "Well I don't even think the stripper was that hot. Do you guys think she was hot?", "order": 11868, "speaker": "Kelly Kapoor"},
  {"season": 3, "episode": 14, "scene": 42, "line": "Kelly don't do this.", "order": 11869, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 14, "scene": 42, "line": "Do what? I just asked you a question.", "order": 11870, "speaker": "Kelly Kapoor"},
  {"season": 3, "episode": 14, "scene": 42, "line": "You know what you're doing.", "order": 11871, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 14, "scene": 42, "line": "Anyway, do you know who was totally flirting with Ben Franklin? Pam.", "order": 11872, "speaker": "Kelly Kapoor"},
  {"season": 3, "episode": 14, "scene": 42, "line": "Really? Looks like I hired the right guy. I'm glad. Any real potential there Beesley?", "order": 11873, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 14, "scene": 42, "line": "Yeah, right. God, I need a boyfriend. You know Ryan I'm totally ready to be set up with one of your business school friends. Whenever.", "order": 11874, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 14, "scene": 42, "line": "Ok.", "order": 11875, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 14, "scene": 43, "line": "Oh my God, I would get so fat if I worked here.", "order": 11876, "speaker": "Elizabeth"},
  {"season": 3, "episode": 14, "scene": 43, "line": "Yeah? I lose my appetite all the time.", "order": 11877, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 14, "scene": 43, "line": "You could strip you know.", "order": 11878, "speaker": "Elizabeth"},
  {"season": 3, "episode": 14, "scene": 43, "line": "Thanks.", "order": 11879, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 14, "scene": 44, "line": "So you don't want to end our relationship?", "order": 11880, "speaker": "Michael Scott"},
  {"season": 3, "episode": 14, "scene": 44, "line": "I'm closer to firing you.", "order": 11881, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 14, "scene": 44, "line": "That is so sweet. You are the best GD girlfriend in the world. Do you know that?", "order": 11882, "speaker": "Michael Scott"},
  {"season": 3, "episode": 14, "scene": 44, "line": "I'll talk to you later.", "order": 11883, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 14, "scene": 44, "line": "You are. You are.", "order": 11884, "speaker": "Michael Scott"},
  {"season": 3, "episode": 14, "scene": 45, "line": "So you know who turned out to be kind of a creep? Ben Franklin. And Elizabeth the stripper gave me great advice. Which rhymed. Really makes you wonder how Ben Franklin can become president, but someone like Elizabeth can't.", "order": 11885, "speaker": "Michael Scott"},
  {"season": 3, "episode": 14, "scene": 46, "line": "Are you near sighted or far sighted?", "order": 11886, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 14, "scene": 46, "line": "Both. That's why I invented the bifocal.", "order": 11887, "speaker": "Ben Franklin"},
  {"season": 3, "episode": 14, "scene": 46, "line": "GAH!!!", "order": 11888, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 15, "scene": 1, "line": "Damn, lost another file. Going to have to reboot. Again. [Windows reboot sound] Hey, Dwight, do you want an Altoid?", "order": 11889, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 15, "scene": 1, "line": "What do you think?", "order": 11890, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 15, "scene": 2, "line": "In school, we learned about this scientist who trained dogs to salivate at the sound of a bell by feeding them whenever a bell rang. For the last couple of weeks I've been conducting a similar experiment.", "order": 11891, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 15, "scene": 3, "line": "[Windows reboot sound] Dwight, want an Altoid?", "order": 11892, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 15, "scene": 3, "line": "Okay.", "order": 11893, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 15, "scene": 4, "line": "[Windows reboot sound] Altoid?", "order": 11894, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 15, "scene": 4, "line": "Sure", "order": 11895, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 15, "scene": 5, "line": "[Windows reboot sound] Mint Dwight?", "order": 11896, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 15, "scene": 5, "line": "Inbwit? Yes.", "order": 11897, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 15, "scene": 6, "line": "[Windows reboot sound] [Dwight holds out his hand, sighs]", "order": 11898, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 15, "scene": 6, "line": "What are you doing?", "order": 11899, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 15, "scene": 6, "line": "I...", "order": 11900, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 15, "scene": 6, "line": "What?", "order": 11901, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 15, "scene": 6, "line": "I don't know. My mouth tastes so bad all of a sudden. [nasty, dry mouth-smaking noise]", "order": 11902, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 15, "scene": 7, "line": "Always the bridesmaids, right ladies?", "order": 11903, "speaker": "Michael Scott"},
  {"season": 3, "episode": 15, "scene": 7, "line": "Okay, for this next one everyone hop out. Just Phyllis and Dad. Actually, let's bring Mom back in. And the sisters. And you, and you, and you. Great.", "order": 11904, "speaker": "Photographer"},
  {"season": 3, "episode": 15, "scene": 8, "line": "Phyllis is getting married. And I am in the wedding party. She has asked me to push her father's wheelchair down the aisle. So, basically, I am co-giving away the bride. Since I pay her salary it is like I'm paying for the wedding. Which I'm happy to do. It's a big day for Phyllis. But it's an even bigger day for me. Employer of the bride.", "order": 11905, "speaker": "Michael Scott"},
  {"season": 3, "episode": 15, "scene": 9, "line": "Yes, I put Michael in my wedding. It was the only way I could think to get six weeks off for my honeymoon. No one else has ever gotten six weeks before.", "order": 11906, "speaker": "Phyllis Lapin"},
  {"season": 3, "episode": 15, "scene": 10, "line": "Phyllis... ended up using the exact same invitations as Roy and me. So it was kind of like being invited to my own wedding. And I was like 'Wait, thought I called that off'.", "order": 11907, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 15, "scene": 11, "line": "So what's in the box?", "order": 11908, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 15, "scene": 11, "line": "A toaster, you?", "order": 11909, "speaker": "Stanley Hudson"},
  {"season": 3, "episode": 15, "scene": 11, "line": "A toaster.", "order": 11910, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 15, "scene": 11, "line": "Unbelievable.", "order": 11911, "speaker": "Stanley Hudson"},
  {"season": 3, "episode": 15, "scene": 12, "line": "Hello, Angela.", "order": 11912, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 15, "scene": 12, "line": "Hi, Dwight.", "order": 11913, "speaker": "Angela Martin"},
  {"season": 3, "episode": 15, "scene": 12, "line": "You look as beautiful as the Queen of England.", "order": 11914, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 15, "scene": 12, "line": "Thank you. Don't linger. Break left. Left!", "order": 11915, "speaker": "Angela Martin"},
  {"season": 3, "episode": 15, "scene": 13, "line": "The Shrutes have their own traditions. We usually marry standing in our own graves. Makes the funerals very romantic, but the weddings are a bleak affair.", "order": 11916, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 15, "scene": 14, "line": "Those flowers are nice.", "order": 11917, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 15, "scene": 14, "line": "Yeah. P and R?", "order": 11918, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 15, "scene": 14, "line": "Phyllis and Robert.", "order": 11919, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 15, "scene": 14, "line": "Ah, of course.", "order": 11920, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 15, "scene": 15, "line": "Also, Pam and Roy.", "order": 11921, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 15, "scene": 16, "line": "There she is. I swear Phyllis you are as beautiful as the first day you started work at Dunder Mifflin.", "order": 11922, "speaker": "Michael Scott"},
  {"season": 3, "episode": 15, "scene": 16, "line": "Thanks, Michael. That's sweet. Same as when you said it outside.", "order": 11923, "speaker": "Phyllis Lapin"},
  {"season": 3, "episode": 15, "scene": 16, "line": "How you doin'? You excited.", "order": 11924, "speaker": "Michael Scott"},
  {"season": 3, "episode": 15, "scene": 16, "line": "Yes, very.", "order": 11925, "speaker": "Phyllis Lapin"},
  {"season": 3, "episode": 15, "scene": 16, "line": "Me, too. If you need to vomit, that is ok. I did. Do you want to talk about tonight?", "order": 11926, "speaker": "Michael Scott"},
  {"season": 3, "episode": 15, "scene": 16, "line": "No.", "order": 11927, "speaker": "Phyllis Lapin"},
  {"season": 3, "episode": 15, "scene": 16, "line": "You're probably worried about pleasing Bob. A lot of pressure. Phyllis, did you break wind? It's okay, if you did. It's a very natural reaction. It's your wedding. And you're nervous...", "order": 11928, "speaker": "Michael Scott"},
  {"season": 3, "episode": 15, "scene": 16, "line": "That wasn't me.", "order": 11929, "speaker": "Phyllis Lapin"},
  {"season": 3, "episode": 15, "scene": 16, "line": "Okay... umm... I'm sure that Bob... Wow. That is... that is pungent. I lost my train of thought. Aaah... Are you set on that hairstyle?", "order": 11930, "speaker": "Michael Scott"},
  {"season": 3, "episode": 15, "scene": 16, "line": "I thought it was...", "order": 11931, "speaker": "Phyllis Lapin"},
  {"season": 3, "episode": 15, "scene": 16, "line": "Here, let me...", "order": 11932, "speaker": "Michael Scott"},
  {"season": 3, "episode": 15, "scene": 16, "line": "Michael... No.", "order": 11933, "speaker": "Phyllis Lapin"},
  {"season": 3, "episode": 15, "scene": 16, "line": "Just cover up that bald patch.", "order": 11934, "speaker": "Michael Scott"},
  {"season": 3, "episode": 15, "scene": 16, "line": "I don't need your... thank you. No, Michael please... I just need some time alone.", "order": 11935, "speaker": "Phyllis Lapin"},
  {"season": 3, "episode": 15, "scene": 16, "line": "Okay.", "order": 11936, "speaker": "Michael Scott"},
  {"season": 3, "episode": 15, "scene": 17, "line": "You might be surprised to learn that I've only been to one other wedding. It's actually a very cute story. My Mom was marrying Jeff. And they asked me to be ring bearer. I was understandably emotional and somehow my pants became wet.", "order": 11937, "speaker": "Michael Scott"},
  {"season": 3, "episode": 15, "scene": 17, "line": "[in video of Michael as a kid] I hate you!", "order": 11938, "speaker": "Michael Scott"},
  {"season": 3, "episode": 15, "scene": 17, "line": "Long story short: Jeff's dog ended up as ring bearer. And the irony is that after the ceremony that dog peed on everything and nobody said 'boo'.", "order": 11939, "speaker": "Michael Scott"},
  {"season": 3, "episode": 15, "scene": 18, "line": "Why are all these people here? There are too many people on this Earth. We need a new plague. Who are all these people?", "order": 11940, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 15, "scene": 18, "line": "You know what? I bet a lot of them are wedding crashers.", "order": 11941, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 15, "scene": 18, "line": "No way.", "order": 11942, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 15, "scene": 18, "line": "Did you ever see that movie?", "order": 11943, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 15, "scene": 18, "line": "Of course I saw it.", "order": 11944, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 15, "scene": 19, "line": "I saw Wedding Crashers accidentally. I bought a ticket for Grizzly Man and went into the wrong theatre. After an hour, I figured I was in the wrong theatre, but I kept waiting. That's the thing about bear attacks, they come when you least expect it.", "order": 11945, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 15, "scene": 20, "line": "You know I just wish, I wish, I had the investigative powers to smoke some of these guys out.", "order": 11946, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 15, "scene": 20, "line": "Once again, Jim, I will take care of this. I will locate the wedding crashers and report them to Phyllis. That way I won't have to get her a gift.", "order": 11947, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 15, "scene": 21, "line": "[to Toby's date] Hi. I'm Kevin. [to Toby] Where did you find her?", "order": 11948, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 15, "scene": 21, "line": "At the gym.", "order": 11949, "speaker": "Toby Flenderson"},
  {"season": 3, "episode": 15, "scene": 21, "line": "Riiight. The gym. [snickers]", "order": 11950, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 15, "scene": 22, "line": "Could you scoot over? You're on my dress.", "order": 11951, "speaker": "Kelly Kapoor"},
  {"season": 3, "episode": 15, "scene": 22, "line": "I thought you're not supposed to wear white to a wedding.", "order": 11952, "speaker": "Meredith Palmer"},
  {"season": 3, "episode": 15, "scene": 22, "line": "I know but there was an emergency.", "order": 11953, "speaker": "Kelly Kapoor"},
  {"season": 3, "episode": 15, "scene": 23, "line": "I look", "order": 11954, "speaker": "Kelly Kapoor"},
  {"season": 3, "episode": 15, "scene": 24, "line": "This strappy young lad sitting here is Phyllis' father, Albert, and he is quite the ladies' man, aren't you Albert, hah? Ah, ringbearer. I could have done better. I will do better. I am going to be better. I can't believe I'm actually doing this! Ooh! Are you ready for this, Albert? I am. Let's do it.", "order": 11955, "speaker": "Michael Scott"},
  {"season": 3, "episode": 15, "scene": 25, "line": "That's my dress.", "order": 11956, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 15, "scene": 25, "line": "[whispers to father] That's ok. [Albert gets out of his wheelchair and starts walking]", "order": 11957, "speaker": "Michael Scott"},
  {"season": 3, "episode": 15, "scene": 25, "line": "It's a miracle.", "order": 11958, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 15, "scene": 25, "line": "[generalized clapping]", "order": 11959, "speaker": "Crowd"},
  {"season": 3, "episode": 15, "scene": 25, "line": "This is bull****!", "order": 11960, "speaker": "Michael Scott"},
  {"season": 3, "episode": 15, "scene": 26, "line": "Me walking Phyllis down the aisle was supposed to be the highlight of the wedding. And now... the wedding has no highlight.", "order": 11961, "speaker": "Michael Scott"},
  {"season": 3, "episode": 15, "scene": 27, "line": "I can't believe I pushed that... that guy's lazy ass around all day... until he was ready to stand up and steal the show. That's... well... I got news for you, Albert. If that's your real name. The show's not over.", "order": 11962, "speaker": "Michael Scott"},
  {"season": 3, "episode": 15, "scene": 28, "line": "And do you, Phyllis, take Bob Vance, Vance Refrigeration, to be your lawfully wedded husband?", "order": 11963, "speaker": "Priest"},
  {"season": 3, "episode": 15, "scene": 28, "line": "I do.", "order": 11964, "speaker": "Phyllis Lapin"},
  {"season": 3, "episode": 15, "scene": 28, "line": "Ladies and Gentlemen, may I present to you for the first time as a couple, Mr. and Mrs. Bob Vance!", "order": 11965, "speaker": "Michael Scott"},
  {"season": 3, "episode": 15, "scene": 28, "line": "And do you, Bob...", "order": 11966, "speaker": "Priest"},
  {"season": 3, "episode": 15, "scene": 28, "line": "Oh, shiii...", "order": 11967, "speaker": "Michael Scott"},
  {"season": 3, "episode": 15, "scene": 28, "line": "... take Phyllis to be your lawfully wedded wife", "order": 11968, "speaker": "Priest"},
  {"season": 3, "episode": 15, "scene": 28, "line": "I do.", "order": 11969, "speaker": "Bob Vance"},
  {"season": 3, "episode": 15, "scene": 28, "line": "You may now kiss the bride.", "order": 11970, "speaker": "Priest"},
  {"season": 3, "episode": 15, "scene": 28, "line": "Ladies and Gentleman, for the first time as a couple, Mr. and Mrs. Bob Vance. [generalized clapping and cheering] Yeah! That's what I'm talking about!", "order": 11971, "speaker": "Michael Scott"},
  {"season": 3, "episode": 15, "scene": 29, "line": "Congratulations, Phyllis. You look lovely. Your dress is very white. So white, my eyes are burning.", "order": 11972, "speaker": "Angela Martin"},
  {"season": 3, "episode": 15, "scene": 29, "line": "Thanks Angela.", "order": 11973, "speaker": "Phyllis Lapin"},
  {"season": 3, "episode": 15, "scene": 30, "line": "Congratulations, Bob. You're a good man. But just know... if you ever lay a finger on Phyllis, I will kill you.", "order": 11974, "speaker": "Michael Scott"},
  {"season": 3, "episode": 15, "scene": 30, "line": "If you ever lay I finger on Phyllis, I'll kill you.", "order": 11975, "speaker": "Bob Vance"},
  {"season": 3, "episode": 15, "scene": 30, "line": "Agreed. No fingers will be laid on Phyllis. [to Albert] Oh, decided to sit down again, huh? Great. Bet you can hear me, too.", "order": 11976, "speaker": "Michael Scott"},
  {"season": 3, "episode": 15, "scene": 31, "line": "Best of luck, Phyllis. Also I'm going to need to see a copy of the guest manifest as well as photographs of the caterers.", "order": 11977, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 15, "scene": 31, "line": "I don't have that, Dwight.", "order": 11978, "speaker": "Phyllis Vance"},
  {"season": 3, "episode": 15, "scene": 31, "line": "Dammit, Phyllis!", "order": 11979, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 15, "scene": 32, "line": "Are you all right? This must be so awful for you.", "order": 11980, "speaker": "Kelly Kapoor"},
  {"season": 3, "episode": 15, "scene": 32, "line": "What do you mean?", "order": 11981, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 15, "scene": 32, "line": "Well... this was supposed to be your wedding.", "order": 11982, "speaker": "Kelly Kapoor"},
  {"season": 3, "episode": 15, "scene": 32, "line": "Oh... um... no. That's... um... That's actually fine", "order": 11983, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 15, "scene": 32, "line": "There's no way it's fine. I'm sorry. If I was you, I would just like freak out and get really drunk and then tell someone I was pregnant.", "order": 11984, "speaker": "Kelly Kapoor"},
  {"season": 3, "episode": 15, "scene": 32, "line": "Okay, that's a lot of good ideas. Thanks", "order": 11985, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 15, "scene": 33, "line": "Scuse me, sir. How do you know the happy couple?", "order": 11986, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 15, "scene": 33, "line": "Who?", "order": 11987, "speaker": "Uncle Al"},
  {"season": 3, "episode": 15, "scene": 33, "line": "The bride and groom? What are their names?", "order": 11988, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 15, "scene": 33, "line": "Oh, I... I don't... I'm not sure.", "order": 11989, "speaker": "Uncle Al"},
  {"season": 3, "episode": 15, "scene": 33, "line": "Oh I get it, I get it, come on, freeloader. Let's move it. Come on. Come on.", "order": 11990, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 15, "scene": 33, "line": "Okay, Okay. Where are we going?", "order": 11991, "speaker": "Uncle Al"},
  {"season": 3, "episode": 15, "scene": 33, "line": "Got to find yourself another wedding to crash, my friend.", "order": 11992, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 15, "scene": 33, "line": "Oh!", "order": 11993, "speaker": "Uncle Al"},
  {"season": 3, "episode": 15, "scene": 34, "line": "Phyllis! Are you happy with everything? What can I do to make it more perfecter?", "order": 11994, "speaker": "Michael Scott"},
  {"season": 3, "episode": 15, "scene": 34, "line": "It's beautiful. Why don't you find your seat. Enjoy the buffet.", "order": 11995, "speaker": "Phyllis Vance"},
  {"season": 3, "episode": 15, "scene": 34, "line": "I'm already on it. The chicken? Totally undercooked. I sent it back.", "order": 11996, "speaker": "Michael Scott"},
  {"season": 3, "episode": 15, "scene": 34, "line": "It's fish.", "order": 11997, "speaker": "Phyllis Vance"},
  {"season": 3, "episode": 15, "scene": 34, "line": "I will take care of that.", "order": 11998, "speaker": "Michael Scott"},
  {"season": 3, "episode": 15, "scene": 35, "line": "I do. I know a fair amount about fine food... and drink. This is a white.", "order": 11999, "speaker": "Michael Scott"},
  {"season": 3, "episode": 15, "scene": 36, "line": "No this is not our first wedding. This is the THIRD wedding that Scrantonicity has played. We also played our bassist's wedding and our guitarist's wedding.", "order": 12000, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 15, "scene": 37, "line": "Attention, everyone. Attention, please. I am supposed to ask if anyone has seen Uncle Al. He is old and has brown eyes and dementia. His family is very concerned. It is a very serious situation. [sings] Roxanne. You don't have to put on your red light.", "order": 12001, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 15, "scene": 38, "line": "Hey.", "order": 12002, "speaker": "Roy Anderson"},
  {"season": 3, "episode": 15, "scene": 38, "line": "Hey.", "order": 12003, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 15, "scene": 38, "line": "I know I normally don't notice these kind of things but uh... This wedding's really nice! I mean, the flowers and stuff? Phyllis has got some great taste.", "order": 12004, "speaker": "Roy Anderson"},
  {"season": 3, "episode": 15, "scene": 38, "line": "You're kidding me, right?", "order": 12005, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 15, "scene": 38, "line": "I know you're probably not going to remember this, right? But um... Those color roses? I got you those color roses for our prom.", "order": 12006, "speaker": "Roy Anderson"},
  {"season": 3, "episode": 15, "scene": 38, "line": "Roy, I picked those flowers. Phyllis just stole all of my ideas for our wedding.", "order": 12007, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 15, "scene": 38, "line": "I uh guess I wasn't really too involved in the planning.", "order": 12008, "speaker": "Roy Anderson"},
  {"season": 3, "episode": 15, "scene": 38, "line": "Yeah.", "order": 12009, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 15, "scene": 38, "line": "Sorry about that.", "order": 12010, "speaker": "Roy Anderson"},
  {"season": 3, "episode": 15, "scene": 38, "line": "It's okay.", "order": 12011, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 15, "scene": 38, "line": "You think this sucks for you? I was the one who actually wanted to get married.", "order": 12012, "speaker": "Roy Anderson"},
  {"season": 3, "episode": 15, "scene": 39, "line": "Phyllis, you're a wonderful woman. And you're a hell of a bowler!", "order": 12013, "speaker": "RAndy Bernard"},
  {"season": 3, "episode": 15, "scene": 39, "line": "[cheering and clapping]", "order": 12014, "speaker": "Crowd"},
  {"season": 3, "episode": 15, "scene": 39, "line": "She is.", "order": 12015, "speaker": "Unknown"},
  {"season": 3, "episode": 15, "scene": 39, "line": "Cheers.", "order": 12016, "speaker": "RAndy Bernard"},
  {"season": 3, "episode": 15, "scene": 39, "line": "Cheers.", "order": 12017, "speaker": "Crowd"},
  {"season": 3, "episode": 15, "scene": 39, "line": "Thank you, Randy. That was great. Thank you. Thank you very much. Hi, I'm Michael Scott and for the next forty minutes, I'm going to be your tour guide through the lives of Phyllis Lapin and Bob Vance. One of the great, seemingly impossible, love stories of our time. My name is Michael Scott. Webster's Dictionary defines 'wedding' as the fusing of two metals with a hot torch. Well, you know something. I think you guys are two metals. Gold medals. For those of you who don't know me, I'm Michael Scott, Phyllis' boss. To quote from The Princess Bride 'Mawige...", "order": 12018, "speaker": "Michael Scott"},
  {"season": 3, "episode": 15, "scene": 40, "line": "The most important part of a speech is the opening line. When time is not a factor, I like to try out three or four different ones.", "order": 12019, "speaker": "Michael Scott"},
  {"season": 3, "episode": 15, "scene": 41, "line": "Phyllis and Bob: their celebrity couple name would be Phlob. You look at her... and she's kind of matronly today, but back in High School, I swear, her nickname was 'Easy Rider'. Now as for Bob... Bob Vance...", "order": 12020, "speaker": "Michael Scott"},
  {"season": 3, "episode": 15, "scene": 41, "line": "Oh okay. That's enough.", "order": 12021, "speaker": "Bob Vance"},
  {"season": 3, "episode": 15, "scene": 41, "line": "is a guy that...", "order": 12022, "speaker": "Michael Scott"},
  {"season": 3, "episode": 15, "scene": 41, "line": "Thanks, Michael. Give me...", "order": 12023, "speaker": "Bob Vance"},
  {"season": 3, "episode": 15, "scene": 41, "line": "he works... Okay hold, hold on, hold on. Look. Look. I didn't say anything when Phyllis' dad upstaged me at the ceremony. And I think you owe me this. Kay.", "order": 12024, "speaker": "Michael Scott"},
  {"season": 3, "episode": 15, "scene": 41, "line": "Give me the microphone.", "order": 12025, "speaker": "Bob Vance"},
  {"season": 3, "episode": 15, "scene": 41, "line": "No. I'm not going to...", "order": 12026, "speaker": "Michael Scott"},
  {"season": 3, "episode": 15, "scene": 41, "line": "Give me... Give me the microphone, Michael.", "order": 12027, "speaker": "Bob Vance"},
  {"season": 3, "episode": 15, "scene": 41, "line": "Ok. All right.", "order": 12028, "speaker": "Michael Scott"},
  {"season": 3, "episode": 15, "scene": 41, "line": "You're out of here!", "order": 12029, "speaker": "Bob Vance"},
  {"season": 3, "episode": 15, "scene": 41, "line": "Oh. Yeah. You're out of here! You're... Yeah. I hate you!", "order": 12030, "speaker": "Michael Scott"},
  {"season": 3, "episode": 15, "scene": 42, "line": "Hey.", "order": 12031, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 15, "scene": 42, "line": "Hey!", "order": 12032, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 15, "scene": 42, "line": "When are we going to get to see some of those famous Beesly dance moves?", "order": 12033, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 15, "scene": 42, "line": "Oh... I'm pacing myself.", "order": 12034, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 15, "scene": 42, "line": "Come on. Get out there. Give the people what they want.", "order": 12035, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 15, "scene": 42, "line": "No. I'm such a dorky dancer.", "order": 12036, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 15, "scene": 42, "line": "I know. It's very cute.", "order": 12037, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 15, "scene": 43, "line": "Hypothetically, if I thought Pam was interested, then... No, it's totally hypothetical.", "order": 12038, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 15, "scene": 44, "line": "Come... Come on!", "order": 12039, "speaker": "Michael Scott"},
  {"season": 3, "episode": 15, "scene": 44, "line": "I can't let you in, Michael.", "order": 12040, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 15, "scene": 44, "line": "Dwight, just...", "order": 12041, "speaker": "Michael Scott"},
  {"season": 3, "episode": 15, "scene": 44, "line": "No, it's Bob and Phyllis' orders.", "order": 12042, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 15, "scene": 44, "line": "Look, I just wanted to go in and quietly sit and have a piece of cake. I'm not even going to dance one song.", "order": 12043, "speaker": "Michael Scott"},
  {"season": 3, "episode": 15, "scene": 44, "line": "You are a real life wedding crasher and I must bounce you. I'm sorry, it gives me no pleasure.", "order": 12044, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 15, "scene": 44, "line": "OK.", "order": 12045, "speaker": "Michael Scott"},
  {"season": 3, "episode": 15, "scene": 45, "line": "Hey, they're playing our song.", "order": 12046, "speaker": "Roy Anderson"},
  {"season": 3, "episode": 15, "scene": 45, "line": "Yeah, that's weird. I thought they only played the Police.", "order": 12047, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 15, "scene": 45, "line": "I know. Uh... I gave them twenty bucks. You want to dance?", "order": 12048, "speaker": "Roy Anderson"},
  {"season": 3, "episode": 15, "scene": 46, "line": "[sings] ee... I was meant for you... buppity du bom bu.", "order": 12049, "speaker": "Michael Scott"},
  {"season": 3, "episode": 15, "scene": 47, "line": "[to Pam] Hey, want to get out of here?", "order": 12050, "speaker": "Roy Anderson"},
  {"season": 3, "episode": 15, "scene": 48, "line": "Here's a 'not hypothetical'. I'm really happy I'm with Karen.", "order": 12051, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 15, "scene": 49, "line": "[sings] Every little thing she does is magic. Every little thing she do just turns me on. Even though my life before was tragic. Now I know my love for her goes on. Every...", "order": 12052, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 15, "scene": 50, "line": "One... Two... Three. Ahhhh! [Phyllis throws the flowers, Ryan knocks them out of Kelly's hands, Toby's date gets them]", "order": 12053, "speaker": "Women"},
  {"season": 3, "episode": 15, "scene": 51, "line": "Toby! Yeah!", "order": 12054, "speaker": "Toby Flenderson"},
  {"season": 3, "episode": 15, "scene": 52, "line": "I just want Phyllis to have a great day.", "order": 12055, "speaker": "Michael Scott"},
  {"season": 3, "episode": 15, "scene": 52, "line": "Phyllis and you will be great together.", "order": 12056, "speaker": "Uncle Al"},
  {"season": 3, "episode": 15, "scene": 52, "line": "We are great together. We are a great team.", "order": 12057, "speaker": "Michael Scott"},
  {"season": 3, "episode": 15, "scene": 52, "line": "The Celtics were a great team.", "order": 12058, "speaker": "Uncle Al"},
  {"season": 3, "episode": 15, "scene": 52, "line": "Yes. Yes. They were. Robert Parrish! I should talk to her. I don't want this to ruin her honeymoon.", "order": 12059, "speaker": "Michael Scott"},
  {"season": 3, "episode": 15, "scene": 52, "line": "Nobody ever helped me. I had to do it myself. Even the doctor didn't know!", "order": 12060, "speaker": "Uncle Al"},
  {"season": 3, "episode": 15, "scene": 52, "line": "Dude, keep it together. I listened to you for half an hour even though most of that stuff went right over my head.", "order": 12061, "speaker": "Michael Scott"},
  {"season": 3, "episode": 15, "scene": 53, "line": "Phyllis. Phyllis! Wait! Please. I'm sorry. I just... I just wanted to make this a day to remember.", "order": 12062, "speaker": "Michael Scott"},
  {"season": 3, "episode": 15, "scene": 53, "line": "You found Uncle Al!", "order": 12063, "speaker": "Phyllis Vance"},
  {"season": 3, "episode": 15, "scene": 53, "line": "Yeah. Yeah. He's kind of a weirdo.", "order": 12064, "speaker": "Michael Scott"},
  {"season": 3, "episode": 15, "scene": 53, "line": "Thank you, Michael.", "order": 12065, "speaker": "Phyllis Vance"},
  {"season": 3, "episode": 15, "scene": 53, "line": "You're... You're welcome.", "order": 12066, "speaker": "Michael Scott"},
  {"season": 3, "episode": 15, "scene": 54, "line": "They say that your wedding day goes by in such a flash that your lucky if you even get a piece of your own cake. I say that's crazy. I say let them eat cake. Margaret Thatcher said that about marriage. Smart broad.", "order": 12067, "speaker": "Michael Scott"},
  {"season": 3, "episode": 15, "scene": 55, "line": "Be careful. Oh no!! [Phyllis and Bob smear cake on each other's face] Oh wow! Phyllis! Phyllis! You look like a clown! Here. Get me! Get me! [Michael smears cake on his own face]", "order": 12068, "speaker": "Michael Scott"},
  {"season": 3, "episode": 16, "scene": 1, "line": "Oh hey, Kevin, nice of you to join us, where were you?", "order": 12069, "speaker": "Michael Scott"},
  {"season": 3, "episode": 16, "scene": 1, "line": "My tire blew out on the way here, Michael.", "order": 12070, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 16, "scene": 1, "line": "Huh?", "order": 12071, "speaker": "Michael Scott"},
  {"season": 3, "episode": 16, "scene": 1, "line": "I almost died. I... I went into this skid---", "order": 12072, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 16, "scene": 1, "line": "Pop quiz.", "order": 12073, "speaker": "Michael Scott"},
  {"season": 3, "episode": 16, "scene": 1, "line": "...What?", "order": 12074, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 16, "scene": 1, "line": "Why is today a special day?", "order": 12075, "speaker": "Michael Scott"},
  {"season": 3, "episode": 16, "scene": 1, "line": "I almost died.", "order": 12076, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 16, "scene": 1, "line": "Today's a special day, because I am being honored as a... visiting... professor, special lecturer, emeritus... how did you, how did you...", "order": 12077, "speaker": "Michael Scott"},
  {"season": 3, "episode": 16, "scene": 1, "line": "You will be a guest speaker... in my Emerging Enterprises class.", "order": 12078, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 16, "scene": 1, "line": "In business school, Kevin. Business school.", "order": 12079, "speaker": "Michael Scott"},
  {"season": 3, "episode": 16, "scene": 1, "line": "Wow.", "order": 12080, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 16, "scene": 2, "line": "If you bring your boss to class, it automatically bumps you up a full letter grade. So... I'd be stupid", "order": 12081, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 16, "scene": 3, "line": "A boss is like a teacher. And I am like the cool teacher. Like Mr. Handell. Mr. Handell would hang out with us, and he would tell us awesome jokes. And he actually hooked up with one of the students. Um... and then like twelve other kids came forward. It was in all the papers. ... Really ruined eighth grade for us.", "order": 12082, "speaker": "Michael Scott"},
  {"season": 3, "episode": 16, "scene": 4, "line": "Here we go. College Roadshow. Gotta bring our... A game. What was the most inspiring thing I've ever said to you?", "order": 12083, "speaker": "Michael Scott"},
  {"season": 3, "episode": 16, "scene": 4, "line": "Don't be an idiot.' Changed my life.", "order": 12084, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 16, "scene": 5, "line": "Whenever I'm about to do something, I think, 'Would an idiot do that?' And if they would, I do not do that thing.", "order": 12085, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 16, "scene": 6, "line": "Will they throw their hats, you think?", "order": 12086, "speaker": "Michael Scott"},
  {"season": 3, "episode": 16, "scene": 6, "line": "What?", "order": 12087, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 16, "scene": 6, "line": "A lot of times, at a... school, or naval academy, after a rousing speech, the crowd would throw its hats high into the air.", "order": 12088, "speaker": "Michael Scott"},
  {"season": 3, "episode": 16, "scene": 6, "line": "Y-You understand nobody's graduating.", "order": 12089, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 16, "scene": 6, "line": "Yeah, I know, I know. I'm just saying if they did throw their hats I've got a great line for that: 'May your hats fly as high as your dreams.' ... That was a pretty good line.", "order": 12090, "speaker": "Michael Scott"},
  {"season": 3, "episode": 16, "scene": 6, "line": "...It doesn't apply.", "order": 12091, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 16, "scene": 6, "line": "I understand! Wow. Relax, spazzy boy. Sometimes you're such a little spaz! [pokes Ryan] Whoa, hey!", "order": 12092, "speaker": "Michael Scott"},
  {"season": 3, "episode": 16, "scene": 6, "line": "Quit it!", "order": 12093, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 16, "scene": 6, "line": "We have fun.", "order": 12094, "speaker": "Michael Scott"},
  {"season": 3, "episode": 16, "scene": 7, "line": "I can't for your art show tonight.", "order": 12095, "speaker": "Roy Anderson"},
  {"season": 3, "episode": 16, "scene": 7, "line": "Okay, just so you know, it's just the students from my class in a little studio.", "order": 12096, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 16, "scene": 7, "line": "I-I wouldn't miss it for the world.", "order": 12097, "speaker": "Roy Anderson"},
  {"season": 3, "episode": 16, "scene": 7, "line": "Thanks.", "order": 12098, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 16, "scene": 8, "line": "I'm really happy to be back with Roy. I think it shows maturity. Maturity and dignity. ... Is that braggy? I don't mean it to be braggy.", "order": 12099, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 16, "scene": 9, "line": "Love ya.", "order": 12100, "speaker": "Roy Anderson"},
  {"season": 3, "episode": 16, "scene": 9, "line": "You too.", "order": 12101, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 16, "scene": 10, "line": "Pam's with Roy. I'm with Karen. And, uh, Brangelina is with Frangelina. Movin' on.", "order": 12102, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 16, "scene": 11, "line": "I can't believe you're back together with Roy!", "order": 12103, "speaker": "Kelly Kapoor"},
  {"season": 3, "episode": 16, "scene": 11, "line": "Oh, yeah! We have such a solid foundation, you know.", "order": 12104, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 16, "scene": 11, "line": "Oh my God. You're so in love now.", "order": 12105, "speaker": "Kelly Kapoor"},
  {"season": 3, "episode": 16, "scene": 11, "line": "Yeah. Oh, you should come to my art show, by the way.", "order": 12106, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 16, "scene": 11, "line": "Oh, art show!", "order": 12107, "speaker": "Kelly Kapoor"},
  {"season": 3, "episode": 16, "scene": 11, "line": "I mean, it's not a big deal, but I think a lot of people from the office will be there.", "order": 12108, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 16, "scene": 11, "line": "...Oh... yeah. Definitely... I'll be there. For sure.", "order": 12109, "speaker": "Kelly Kapoor"},
  {"season": 3, "episode": 16, "scene": 12, "line": "Campus. Brings back so many memories. ... That I would have made. Hey. Frisbee. Check that out. Aww! What do you say we get our Fris on before class? [runs over, throws frisbee] Whoo!", "order": 12110, "speaker": "Michael Scott"},
  {"season": 3, "episode": 16, "scene": 12, "line": "....Dude.", "order": 12111, "speaker": "College Student"},
  {"season": 3, "episode": 16, "scene": 13, "line": "...Oh my God. Animal stool. [jumps on desk]", "order": 12112, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 16, "scene": 13, "line": "Dwight, what are you doing?", "order": 12113, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 16, "scene": 13, "line": "Solving a mystery, if that's quite alright with you. [opens ceiling tile] Come to Papa. Okay, ladies and gentlemen, what we have here is a bird that has been trapped in a vent. Fortunately I have found it befo--BAT! BAAAT! BAT!", "order": 12114, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 16, "scene": 13, "line": "Oh my God!", "order": 12115, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 16, "scene": 13, "line": "BAAAAT! BAT! NO! EVERYONE REMAIN CALM! There it goes!", "order": 12116, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 16, "scene": 13, "line": "Goooood bye.", "order": 12117, "speaker": "Stanley Hudson"},
  {"season": 3, "episode": 16, "scene": 13, "line": "[on ground] ... Please don't let that stupid thing near me...", "order": 12118, "speaker": "Angela Martin"},
  {"season": 3, "episode": 16, "scene": 14, "line": "Okay, this is it. Ryan is doing my intro right now.", "order": 12119, "speaker": "Michael Scott"},
  {"season": 3, "episode": 16, "scene": 14, "line": "...Dunder-Mifflin can't compete with the modern chains, and management is unwilling, or unable, to adapt. Their customers are dying off...", "order": 12120, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 16, "scene": 14, "line": "I can't hear what he's saying, but he looks like he's really into it.", "order": 12121, "speaker": "Michael Scott"},
  {"season": 3, "episode": 16, "scene": 15, "line": "We have... a bat... in the office.", "order": 12122, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 16, "scene": 16, "line": "The simple solution would be to open a window... if we had... windows that could open.", "order": 12123, "speaker": "Toby Flenderson"},
  {"season": 3, "episode": 16, "scene": 17, "line": "Poop is raining from the ceilings. Poop!", "order": 12124, "speaker": "Angela Martin"},
  {"season": 3, "episode": 16, "scene": 18, "line": "Okay. Thanks. [hangs up phone] Animal control will be here at six.", "order": 12125, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 16, "scene": 18, "line": "At Six?! No, that is unacceptable. Okay, Jim, you are the number two in this office. You need to step up and show some leadership.", "order": 12126, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 16, "scene": 18, "line": "I'm sorry what did you say? So wierd...", "order": 12127, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 16, "scene": 18, "line": "What? What's so wierd?", "order": 12128, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 16, "scene": 18, "line": "The bat, I mean, I know I felt it bite me, but look. There's no mark. I feel so... tingly... so strangely powerful... [shrugs] Oh well.", "order": 12129, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 16, "scene": 19, "line": "And now, without further ado, I present the regional manager of Dunder Mifflin, Scranton, Michael Scott.", "order": 12130, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 16, "scene": 19, "line": "Hello everyone, I am Michael Scott. And I would like to start today by inspiring you. May I borrow someone's textbook, please? Thank you. What have we here? Ooh. Economics. Very, very interesting. [rips pages out of book] You cannot learn from books. Replace these pages with life lessons, and then, you will have... a book... that is worth its weight in gold. [gives book back] I know these are expensive, um, but the lesson is priceless. Good. Alright. I think you're inspired. Shall we proceed? There are four kinds of business: Tourism. Food service. Railroads, and sales. And hospitals slash manufacturing. And air travel.", "order": 12131, "speaker": "Michael Scott"},
  {"season": 3, "episode": 16, "scene": 20, "line": "[sighs] This is your job, Halpert.", "order": 12132, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 16, "scene": 20, "line": "Ow!", "order": 12133, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 16, "scene": 20, "line": "Oh, what happened?", "order": 12134, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 16, "scene": 20, "line": "That bread on your desk? I just picked it up. It's white hot.", "order": 12135, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 16, "scene": 20, "line": "But Jim, this garlic bread is cold.", "order": 12136, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 16, "scene": 20, "line": "What? ... No. It burned me. I... bizarre.", "order": 12137, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 16, "scene": 20, "line": "No... no. One crisis at a time.", "order": 12138, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 16, "scene": 21, "line": "If a vampire bat was in the U.S., it would make sense for it to come to a 'sylvania.' Like PENN-sylvania. Now that doesn't mean that Jim is going to become a vampire. Only that he carries the vampiric germ.", "order": 12139, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 16, "scene": 22, "line": "So, you wanna start a business. How do you start? What do you need? Well, first of all, you need a building. And secondly, you need supply. You need something to sell. Now this could be anything. It could be... a... thingamajig. Or a... a whosi-whatsi. Or... [pulls out a candy bar] a Whatchamacallit [throws bar]. Now, you need to sell those in order to have a PayDay [takes out a PayDay, throws it]. And, if you sell enough of them, you will make a 100 Grand [throws 100 Grand bar]. [pulls out a Snickers] Satisfied?", "order": 12140, "speaker": "Michael Scott"},
  {"season": 3, "episode": 16, "scene": 23, "line": "Oh, this looks great. I'd, I'd love to be there, but my daughter's play is tonight. ... Damnit! You know, one of the other parents will probably videotape it.", "order": 12141, "speaker": "Toby Flenderson"},
  {"season": 3, "episode": 16, "scene": 23, "line": "Oh! No, you should go.", "order": 12142, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 16, "scene": 23, "line": "Well, it's important to support local art, you know. And what they do is not art.", "order": 12143, "speaker": "Toby Flenderson"},
  {"season": 3, "episode": 16, "scene": 24, "line": "Okay, I'm seeing some confused... faces out there. Let me slow down a little bit. Break this down. Okay. The more stickers you sell, the more profit, fancy word for money, you have to buy PlayStations and Beanie Babies.", "order": 12144, "speaker": "Michael Scott"},
  {"season": 3, "episode": 16, "scene": 24, "line": "Michael.", "order": 12145, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 16, "scene": 24, "line": "...And products!", "order": 12146, "speaker": "Michael Scott"},
  {"season": 3, "episode": 16, "scene": 24, "line": "What we normally do here is more of a question and answer thing.", "order": 12147, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 16, "scene": 24, "line": "Well... okay, I was just kind of getting it going. Um, alright. Well, okay, we can do questions. Okay. Very good. First hand up.", "order": 12148, "speaker": "Michael Scott"},
  {"season": 3, "episode": 16, "scene": 24, "line": "Sir, as a company that primary distributes paper, how have you adapted your business model to function in an increasingly paperless world?", "order": 12149, "speaker": "Business Student #1"},
  {"season": 3, "episode": 16, "scene": 24, "line": "We can't overestimate the value of computers. Yes, they are great for playing games and forwarding funny emails. But real business is done on paper, okay? Write that down. [everyone types on their laptops]", "order": 12150, "speaker": "Michael Scott"},
  {"season": 3, "episode": 16, "scene": 25, "line": "Hey Jim, here's the aspirin you wanted.", "order": 12151, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 16, "scene": 25, "line": "Oh, thank God. I have such a headache from that glare.", "order": 12152, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 16, "scene": 25, "line": "What glare?", "order": 12153, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 16, "scene": 25, "line": "The glare off Angela's crucifix? It's blinding.", "order": 12154, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 16, "scene": 26, "line": "I don't have a lot of experience with vampires, but I have hunted werewolves. I shot one once. But by the time I got to it, it had turned back into my neighbor's dog.", "order": 12155, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 16, "scene": 27, "line": "Extraordinary events call for extraordinary actions. We form an allegiance--", "order": 12156, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 16, "scene": 27, "line": "Sure.", "order": 12157, "speaker": "Creed Bratton"},
  {"season": 3, "episode": 16, "scene": 27, "line": "--to use sudden violence.", "order": 12158, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 16, "scene": 27, "line": "Okay.", "order": 12159, "speaker": "Creed Bratton"},
  {"season": 3, "episode": 16, "scene": 27, "line": "Do you have the tools to turn a wooden mop handle into a stake?", "order": 12160, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 16, "scene": 27, "line": "What size?", "order": 12161, "speaker": "Creed Bratton"},
  {"season": 3, "episode": 16, "scene": 28, "line": "What do you say to a customer who wants to leave you for the convenience and savings of a nationwide chain?", "order": 12162, "speaker": "Business Student #2"},
  {"season": 3, "episode": 16, "scene": 28, "line": "...I say you will miss our service, and I absolutely guarantee you'll come back.", "order": 12163, "speaker": "Michael Scott"},
  {"season": 3, "episode": 16, "scene": 28, "line": "Has anyone ever come back?", "order": 12164, "speaker": "Business Student #2"},
  {"season": 3, "episode": 16, "scene": 28, "line": "...We don't want them back, 'cause they're... stupid.", "order": 12165, "speaker": "Michael Scott"},
  {"season": 3, "episode": 16, "scene": 28, "line": "How far has your Herfindahl index declined since the merger?", "order": 12166, "speaker": "Business Student #3"},
  {"season": 3, "episode": 16, "scene": 28, "line": "Nice try, how's your Pollack-says-what index?", "order": 12167, "speaker": "Michael Scott"},
  {"season": 3, "episode": 16, "scene": 28, "line": "...What?", "order": 12168, "speaker": "Business Student #3"},
  {"season": 3, "episode": 16, "scene": 28, "line": "Thanks, Kowalsky. Um, can we get on track here?", "order": 12169, "speaker": "Michael Scott"},
  {"season": 3, "episode": 16, "scene": 28, "line": "By your own employee's calculation you'll be obsolete in the next five to ten years.", "order": 12170, "speaker": "Business Student #1"},
  {"season": 3, "episode": 16, "scene": 28, "line": "...Wait, Ryan said that?", "order": 12171, "speaker": "Michael Scott"},
  {"season": 3, "episode": 16, "scene": 29, "line": "What are you doing? You'd better not hurt that little bat.", "order": 12172, "speaker": "Kelly Kapoor"},
  {"season": 3, "episode": 16, "scene": 29, "line": "Animals can't feel pain.", "order": 12173, "speaker": "Creed Bratton"},
  {"season": 3, "episode": 16, "scene": 29, "line": "Don't hurt that bat, Creed! It's a living thing with feelings and a family!", "order": 12174, "speaker": "Kelly Kapoor"},
  {"season": 3, "episode": 16, "scene": 29, "line": "Flush him towards the door. On my go... NOW!", "order": 12175, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 16, "scene": 29, "line": "AHH! KILL IT! KILL IT! KILL IIIT!", "order": 12176, "speaker": "Kelly Kapoor"},
  {"season": 3, "episode": 16, "scene": 29, "line": "[locks bat in break room] I... am a hero!", "order": 12177, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 16, "scene": 30, "line": "Yeah sure, you know business, sitting up here in your ivory tower. And your ebony tower. You know what? Tell you one thing, Dunder-Mifflin is here to stay.", "order": 12178, "speaker": "Michael Scott"},
  {"season": 3, "episode": 16, "scene": 30, "line": "But how can you compete against a company with the resources of a nationwide chain?", "order": 12179, "speaker": "Business Student #2"},
  {"season": 3, "episode": 16, "scene": 30, "line": "David will always beat Goliath.", "order": 12180, "speaker": "Michael Scott"},
  {"season": 3, "episode": 16, "scene": 30, "line": "But there's five Goliaths, there's... Staples, Officemax...", "order": 12181, "speaker": "Business Student #1"},
  {"season": 3, "episode": 16, "scene": 30, "line": "Yeah, yeah. You know what else is facing five Goliaths? America. Al-Qaeda, global warming, sex predators... mercury poisoning. So do we just give up? Is that what we're learning in business school?", "order": 12182, "speaker": "Michael Scott"},
  {"season": 3, "episode": 16, "scene": 30, "line": "But in the big picture...", "order": 12183, "speaker": "Business Student #1"},
  {"season": 3, "episode": 16, "scene": 30, "line": "Dunder-Mifflin is the big picture! Can't you understand that? No, you can't. You're too young. Ryan... has never made a sale. And he started a fire trying to make a cheesy pita. And everybody thinks he's a tease. Well you know what? He doesn't know anything, and neither do you. [walks out] SO SUCK ON THAT!", "order": 12184, "speaker": "Michael Scott"},
  {"season": 3, "episode": 16, "scene": 31, "line": "...It wasn't personal.", "order": 12185, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 16, "scene": 31, "line": "Business is always personal. It's the most personal thing in the world. When we get back to the office, pack your things.", "order": 12186, "speaker": "Michael Scott"},
  {"season": 3, "episode": 16, "scene": 31, "line": "Pack my--?", "order": 12187, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 16, "scene": 31, "line": "You heard me, pack your things.", "order": 12188, "speaker": "Michael Scott"},
  {"season": 3, "episode": 16, "scene": 32, "line": "I really want to come out!", "order": 12189, "speaker": "Meredith Palmer"},
  {"season": 3, "episode": 16, "scene": 32, "line": "Good night, Mary Beth!", "order": 12190, "speaker": "Creed Bratton"},
  {"season": 3, "episode": 16, "scene": 33, "line": "So... you're cool to just wait here for animal control?", "order": 12191, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 16, "scene": 33, "line": "Animal control? I've been controlling animals since I was six.", "order": 12192, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 16, "scene": 33, "line": "Cool. Okay. I'm gonna go home and lie down, draw the shades... there's just so much sun in here... bye Dwight.", "order": 12193, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 16, "scene": 33, "line": "Goodbye Jim. And good luck.", "order": 12194, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 16, "scene": 34, "line": "Jim is on a path now. An eternal journey, and I wish him well. But I have a destiny in this realm. Specifically, in the kitchen.", "order": 12195, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 16, "scene": 35, "line": "...And it's all from the same series.", "order": 12196, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 16, "scene": 35, "line": "Oh.", "order": 12197, "speaker": "Woman"},
  {"season": 3, "episode": 16, "scene": 35, "line": "Called 'Impressions.'", "order": 12198, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 16, "scene": 35, "line": "Oh.", "order": 12199, "speaker": "Woman"},
  {"season": 3, "episode": 16, "scene": 35, "line": "Not that I call myself an impressionist, per se.", "order": 12200, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 16, "scene": 35, "line": "Maybe one day.", "order": 12201, "speaker": "Woman"},
  {"season": 3, "episode": 16, "scene": 35, "line": "I hope so.", "order": 12202, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 16, "scene": 35, "line": "Mmm.", "order": 12203, "speaker": "Woman"},
  {"season": 3, "episode": 16, "scene": 35, "line": "I still need... you know, my breakthrough, or whatever. [Woman leaves, Roy enters] Hey, babe, how are you?", "order": 12204, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 16, "scene": 35, "line": "Good. Alright I brought my brother, huh?", "order": 12205, "speaker": "Roy Anderson"},
  {"season": 3, "episode": 16, "scene": 35, "line": "Hey, Kenny.", "order": 12206, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 16, "scene": 35, "line": "Hey Pam.", "order": 12207, "speaker": "Kenny"},
  {"season": 3, "episode": 16, "scene": 35, "line": "How 'bout this, huh? I show up with my brother, and, no one from work is here? That's... pretty cool, huh?", "order": 12208, "speaker": "Roy Anderson"},
  {"season": 3, "episode": 16, "scene": 36, "line": "Magic time. Gyeeeaahhh! [puts bag over Meredith's head]", "order": 12209, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 16, "scene": 36, "line": "Ahh! Ahh! Ahh! Get off! Get off me! Get off me!", "order": 12210, "speaker": "Meredith Palmer"},
  {"season": 3, "episode": 16, "scene": 36, "line": "Hold still, woman!", "order": 12211, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 16, "scene": 36, "line": "Get off me! Get it off! Ahhhh!", "order": 12212, "speaker": "Meredith Palmer"},
  {"season": 3, "episode": 16, "scene": 36, "line": "...[captures bat in bag] ... You're welcome.", "order": 12213, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 16, "scene": 37, "line": "It's cool if I go, right? I mean, I looked at all of them.", "order": 12214, "speaker": "Roy Anderson"},
  {"season": 3, "episode": 16, "scene": 37, "line": "Yeah, I'll just, I'll drive myself home.", "order": 12215, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 16, "scene": 37, "line": "To my place?", "order": 12216, "speaker": "Roy Anderson"},
  {"season": 3, "episode": 16, "scene": 37, "line": "Maybe, I'm a little tired.", "order": 12217, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 16, "scene": 37, "line": "Your art.. was the prettiest art of... all art.", "order": 12218, "speaker": "Roy Anderson"},
  {"season": 3, "episode": 16, "scene": 37, "line": "Thank you.", "order": 12219, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 16, "scene": 38, "line": "Look, I'm sorry, okay? I was just trying to do my presentation, and... of course, I was wrong to suggest that Dunder-Mifflin might ever go out of business. But you don't have to fire me.", "order": 12220, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 16, "scene": 38, "line": "Fire you? No, no no. You are moving... to the annex.", "order": 12221, "speaker": "Michael Scott"},
  {"season": 3, "episode": 16, "scene": 38, "line": "To the annex? Where... Kelly is?", "order": 12222, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 16, "scene": 38, "line": "A good manager doesn't fire people. He hires people and inspires people. ... People, Ryan. And people will never go out of business.", "order": 12223, "speaker": "Michael Scott"},
  {"season": 3, "episode": 16, "scene": 39, "line": "You're the one who said we needed more culture.", "order": 12224, "speaker": "Oscar Martinez"},
  {"season": 3, "episode": 16, "scene": 39, "line": "This is culture to you?", "order": 12225, "speaker": "Gil"},
  {"season": 3, "episode": 16, "scene": 39, "line": "It's her first try.", "order": 12226, "speaker": "Oscar Martinez"},
  {"season": 3, "episode": 16, "scene": 39, "line": "Yeah, on Van Gogh's first try, he drew the hands of the peasants.", "order": 12227, "speaker": "Gil"},
  {"season": 3, "episode": 16, "scene": 39, "line": "Meaning what?", "order": 12228, "speaker": "Oscar Martinez"},
  {"season": 3, "episode": 16, "scene": 39, "line": "Meaning, real art takes courage, okay? And honesty.", "order": 12229, "speaker": "Gil"},
  {"season": 3, "episode": 16, "scene": 39, "line": "Well, those aren't Pam's strong points.", "order": 12230, "speaker": "Oscar Martinez"},
  {"season": 3, "episode": 16, "scene": 39, "line": "Yeah, exactly. That's why this is... motel art.", "order": 12231, "speaker": "Gil"},
  {"season": 3, "episode": 16, "scene": 40, "line": "Thanks for coming.", "order": 12232, "speaker": "Artist"},
  {"season": 3, "episode": 16, "scene": 40, "line": "Pam-casso! Sorry I'm late, I had to race across town.", "order": 12233, "speaker": "Michael Scott"},
  {"season": 3, "episode": 16, "scene": 40, "line": "Oh, Michael.", "order": 12234, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 16, "scene": 40, "line": "Wow! You did these... freehand?", "order": 12235, "speaker": "Michael Scott"},
  {"season": 3, "episode": 16, "scene": 40, "line": "Yep.", "order": 12236, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 16, "scene": 40, "line": "My God, these could be tracings! Ohh! Look at this one. Wow! You nailed it. [sighs] ... How much?", "order": 12237, "speaker": "Michael Scott"},
  {"season": 3, "episode": 16, "scene": 40, "line": "What do you mean?", "order": 12238, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 16, "scene": 40, "line": "I don't see a... price.", "order": 12239, "speaker": "Michael Scott"},
  {"season": 3, "episode": 16, "scene": 40, "line": "Um... you wanna buy it?", "order": 12240, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 16, "scene": 40, "line": "Well, yeah. Yeah, we have to have it for the office. I mean, there's my... window, and there's my car! That your car?", "order": 12241, "speaker": "Michael Scott"},
  {"season": 3, "episode": 16, "scene": 40, "line": "Uh-huh.", "order": 12242, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 16, "scene": 40, "line": "That is our building... and we sell paper. ... I am really proud of you.", "order": 12243, "speaker": "Michael Scott"},
  {"season": 3, "episode": 16, "scene": 40, "line": "[hugs Michael] ... Thank you.", "order": 12244, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 16, "scene": 40, "line": "What?", "order": 12245, "speaker": "Michael Scott"},
  {"season": 3, "episode": 16, "scene": 40, "line": "Do you have something in your pocket?", "order": 12246, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 16, "scene": 40, "line": "...Chunky. Do you want half?", "order": 12247, "speaker": "Michael Scott"},
  {"season": 3, "episode": 16, "scene": 40, "line": "No thank you.", "order": 12248, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 16, "scene": 40, "line": "Okay.", "order": 12249, "speaker": "Michael Scott"},
  {"season": 3, "episode": 16, "scene": 41, "line": "It is... a message. It is an inspiration, it is... a source of beauty. And without paper, it could not have happened. Unless, you had a camera.", "order": 12250, "speaker": "Michael Scott"},
  {"season": 3, "episode": 16, "scene": 42, "line": "Oh my God, oh my God, oh my God, oh my God, oh my God, oh my God, oh my God, oh my God, oh my God, oh my God, oh my God, oh my God, oh my God...", "order": 12251, "speaker": "Kelly Kapoor"},
  {"season": 3, "episode": 16, "scene": 42, "line": "It's only temporary, okay? Don't get excited.", "order": 12252, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 16, "scene": 42, "line": "I won't, I won't, I won't, I won't, I won't, I won't, I won't, I won't, I won't, I won't, I won't, I won't...", "order": 12253, "speaker": "Kelly Kapoor"},
  {"season": 3, "episode": 16, "scene": 43, "line": "Well ultimately I went into business to inspire people. They say those who can't do, teach. I can do both. I teach doing.", "order": 12254, "speaker": "Michael Scott"},
  {"season": 3, "episode": 16, "scene": 44, "line": "OK, here we go... Digest of business terms, CliffsNotes on business economics, and a sales encyclopedia.", "order": 12255, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 16, "scene": 44, "line": "Wow! That's a lot of books, do you have, umm, one book that sums up all of these.", "order": 12256, "speaker": "Michael Scott"},
  {"season": 3, "episode": 16, "scene": 44, "line": "Those are the books that sum up the other books.", "order": 12257, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 16, "scene": 44, "line": "So no?", "order": 12258, "speaker": "Michael Scott"},
  {"season": 3, "episode": 16, "scene": 44, "line": "What are you reading?", "order": 12259, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 16, "scene": 44, "line": "Oh, umm, ever since I started here, I've kept a diary of all my sales.", "order": 12260, "speaker": "Michael Scott"},
  {"season": 3, "episode": 16, "scene": 44, "line": "Oh, that could be interesting.", "order": 12261, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 16, "scene": 44, "line": "No, it's just mileage mostly, but there's one chapter that I call wisdoms. I'm going to read you a series of statements, and I want you to tell me, on a scale of one to ten, how smart they are.", "order": 12262, "speaker": "Michael Scott"},
  {"season": 3, "episode": 16, "scene": 44, "line": "[clears throat] I am ready.", "order": 12263, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 16, "scene": 44, "line": "Don't do what I say, say what I do.", "order": 12264, "speaker": "Michael Scott"},
  {"season": 3, "episode": 16, "scene": 44, "line": "Five.", "order": 12265, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 16, "scene": 44, "line": "Just getting warmed up. Mistakes are just successes that you mess up.", "order": 12266, "speaker": "Michael Scott"},
  {"season": 3, "episode": 16, "scene": 44, "line": "Hmm... Five.", "order": 12267, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 16, "scene": 44, "line": "This... You know this is a scale of one to ten?", "order": 12268, "speaker": "Michael Scott"},
  {"season": 3, "episode": 16, "scene": 44, "line": "Yeah.", "order": 12269, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 16, "scene": 44, "line": "OK. Do you want me to repeat that?", "order": 12270, "speaker": "Michael Scott"},
  {"season": 3, "episode": 16, "scene": 44, "line": "No, I got it.", "order": 12271, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 16, "scene": 45, "line": "[motions to phyllis's desk]", "order": 12272, "speaker": "Creed Bratton"},
  {"season": 3, "episode": 16, "scene": 45, "line": "She's on her honeymoon. She won't be back for six weeks.", "order": 12273, "speaker": "Stanley Hudson"},
  {"season": 3, "episode": 16, "scene": 45, "line": "I'll wait. [sighs]", "order": 12274, "speaker": "Creed Bratton"},
  {"season": 3, "episode": 16, "scene": 46, "line": "Here's a joke for you. Why did Pam hook up with Roy at Phyllis's wedding?", "order": 12275, "speaker": "Meredith Palmer"},
  {"season": 3, "episode": 16, "scene": 46, "line": "Don't Meredith.", "order": 12276, "speaker": "Angela Martin"},
  {"season": 3, "episode": 16, "scene": 46, "line": "You ready?", "order": 12277, "speaker": "Meredith Palmer"},
  {"season": 3, "episode": 16, "scene": 46, "line": "You know I don't like to participate in the...", "order": 12278, "speaker": "Oscar Martinez"},
  {"season": 3, "episode": 16, "scene": 46, "line": "Why did Pam hook up with Roy at Phyllis's wedding?", "order": 12279, "speaker": "Meredith Palmer"},
  {"season": 3, "episode": 16, "scene": 46, "line": "Really, it's just that I don't like to participate...", "order": 12280, "speaker": "Oscar Martinez"},
  {"season": 3, "episode": 16, "scene": 46, "line": "Grow up.", "order": 12281, "speaker": "Meredith Palmer"},
  {"season": 3, "episode": 16, "scene": 46, "line": "It's just... I'm sorry... It's gossip, and I don't think it's healthy.", "order": 12282, "speaker": "Oscar Martinez"},
  {"season": 3, "episode": 16, "scene": 46, "line": "Get over yourself.", "order": 12283, "speaker": "Meredith Palmer"},
  {"season": 3, "episode": 16, "scene": 46, "line": "Well, I'm not going to get over myself... it's wrong.", "order": 12284, "speaker": "Oscar Martinez"},
  {"season": 3, "episode": 16, "scene": 46, "line": "Because Roy use to... [long censor beep]... with the rice... [long censor beep]... [laughs]", "order": 12285, "speaker": "Meredith Palmer"},
  {"season": 3, "episode": 16, "scene": 46, "line": "[laughs] That's disgusting.", "order": 12286, "speaker": "Oscar Martinez"},
  {"season": 3, "episode": 16, "scene": 46, "line": "[still laughing] I know.", "order": 12287, "speaker": "Meredith Palmer"},
  {"season": 3, "episode": 16, "scene": 47, "line": "Everybody please stand, and get up on your desks. [goes to stand on desk] Just stand right up.", "order": 12288, "speaker": "Michael Scott"},
  {"season": 3, "episode": 16, "scene": 47, "line": "Michael it's not... It's not...", "order": 12289, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 16, "scene": 47, "line": "That's, yep a little flimsy. Umm... OK... Ahh... Alright, no more desks. Oh OK.", "order": 12290, "speaker": "Michael Scott"},
  {"season": 3, "episode": 16, "scene": 48, "line": "I have a lot of big ideas, and the trick is to get it out of my brain and into theirs, in a way that is easy to swallow. Simple... [holds up snickers] Kids love candy bars.", "order": 12291, "speaker": "Michael Scott"},
  {"season": 3, "episode": 16, "scene": 49, "line": "Lets try a little... Little social experiment here. Raise your hands if you like to buy things. OK, I see some people who like to buy things. You're it, you are the reason this world rolls along, give yourselves a round of applause. Say, say you want to start a business, and business is all about buying and selling, is it not? So say you want to sell lemonade, or stickers. Something you have to keep in mind, you must, and you might want to jot this down, you must sell the stickers for more than you bought the stickers for.", "order": 12292, "speaker": "Michael Scott"},
  {"season": 3, "episode": 16, "scene": 50, "line": "How have you adopted your business model to function in an increasingly paperless world?", "order": 12293, "speaker": "Student 1"},
  {"season": 3, "episode": 16, "scene": 50, "line": "Oh, Oh, I take umbrage to that premise...ses. Umm, let me ask you this: How many people exist in the world? Over forty billion. What are they going to be writing on... rocks? Any one else? [points] Yep.", "order": 12294, "speaker": "Michael Scott"},
  {"season": 3, "episode": 16, "scene": 50, "line": "Just following up on that first question...", "order": 12295, "speaker": "Student 2"},
  {"season": 3, "episode": 16, "scene": 50, "line": "No no no no no. Never follow, always lead.", "order": 12296, "speaker": "Michael Scott"},
  {"season": 3, "episode": 16, "scene": 50, "line": "OK.", "order": 12297, "speaker": "Student 2"},
  {"season": 3, "episode": 16, "scene": 50, "line": "OK.", "order": 12298, "speaker": "Michael Scott"},
  {"season": 3, "episode": 16, "scene": 50, "line": "New question.", "order": 12299, "speaker": "Student 2"},
  {"season": 3, "episode": 16, "scene": 50, "line": "Very good.", "order": 12300, "speaker": "Michael Scott"},
  {"season": 3, "episode": 16, "scene": 50, "line": "In the digital age...", "order": 12301, "speaker": "Student 2"},
  {"season": 3, "episode": 16, "scene": 50, "line": "OK.", "order": 12302, "speaker": "Michael Scott"},
  {"season": 3, "episode": 16, "scene": 50, "line": "... email...", "order": 12303, "speaker": "Student 2"},
  {"season": 3, "episode": 16, "scene": 50, "line": "Bop bop de Bope bop bop bop bop bop. You only have me for a limited time... Yes.", "order": 12304, "speaker": "Michael Scott"},
  {"season": 3, "episode": 16, "scene": 50, "line": "Do you believe your high fixed cost are warranted given your diminishing market share.", "order": 12305, "speaker": "Student 3"},
  {"season": 3, "episode": 16, "scene": 50, "line": "How can I put this? That question makes you sound gay.", "order": 12306, "speaker": "Michael Scott"},
  {"season": 3, "episode": 16, "scene": 51, "line": "[Karen sees Pam's art show flyer on the cabinet door, she looks around to make sure no one is looking, then proceeds to rip in down, Oscar comes out of the bathroom, Karen acts like she was just looking at it and then walks out of the kitchen]", "order": 12307, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 16, "scene": 52, "line": "BAT!", "order": 12308, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 16, "scene": 52, "line": "[comes out of the bathroom... screams... runs back into the bathroom]", "order": 12309, "speaker": "Meredith Palmer"},
  {"season": 3, "episode": 16, "scene": 53, "line": "What is a leader? I asked Jim that, and he said he had never heard the word before. In America, in 2007. He said he knew the word ladder... What an ignoramous.", "order": 12310, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 16, "scene": 54, "line": "[Bat hanging from ceiling cleaning itself] I really want to come out.", "order": 12311, "speaker": "Meredith Palmer"},
  {"season": 3, "episode": 16, "scene": 54, "line": "You want rabies? Be my guest.", "order": 12312, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 16, "scene": 55, "line": "[looking with Kevin at the art show flyer] Should be fun.", "order": 12313, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 16, "scene": 55, "line": "Your name looks really nice.", "order": 12314, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 16, "scene": 56, "line": "Goodnight Mary-Beth.", "order": 12315, "speaker": "Creed Bratton"},
  {"season": 3, "episode": 16, "scene": 56, "line": "Hope she's OK.", "order": 12316, "speaker": "Kelly Kapoor"},
  {"season": 3, "episode": 16, "scene": 56, "line": "Just goes to show the power of prayer.", "order": 12317, "speaker": "Angela Martin"},
  {"season": 3, "episode": 16, "scene": 56, "line": "You prayed for this?", "order": 12318, "speaker": "Kelly Kapoor"},
  {"season": 3, "episode": 16, "scene": 56, "line": "In a general sense.", "order": 12319, "speaker": "Angela Martin"},
  {"season": 3, "episode": 16, "scene": 57, "line": "So cool you're back with Roy. Maybe we can rent that cabin on the lake again this summer.", "order": 12320, "speaker": "Kenny"},
  {"season": 3, "episode": 16, "scene": 57, "line": "Yeah, that'd be nice.", "order": 12321, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 16, "scene": 57, "line": "There'll be two of you and only one of me this time, since Denise left me. So I think it's fair I only pay a third.", "order": 12322, "speaker": "Kenny"},
  {"season": 3, "episode": 16, "scene": 57, "line": "Yeah that seems fair.", "order": 12323, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 17, "scene": 1, "line": "Can you confirm that the straps are tight?", "order": 12324, "speaker": "Michael Scott"},
  {"season": 3, "episode": 17, "scene": 1, "line": "Yes. But this [pulls at straight jacket] seems to be...", "order": 12325, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 17, "scene": 1, "line": "And now, the chains.", "order": 12326, "speaker": "Michael Scott"},
  {"season": 3, "episode": 17, "scene": 2, "line": "A lot of people think that magic camp is just for kids. And that's why so many other people in my class were kids. Self fulfilling prophecy. It's um, it's really for anybody with a dream and a belief in magic and a little extra time after school.", "order": 12327, "speaker": "Michael Scott"},
  {"season": 3, "episode": 17, "scene": 3, "line": "MAGIC MAGIC Magic Magic magic magic... And now, Michael the Magic, will attempt to escape from extreme bondage. [Kevin giggles] Can he do it? I don't see how he can.", "order": 12328, "speaker": "Michael Scott"},
  {"season": 3, "episode": 17, "scene": 3, "line": "I know how. Dislocate his shoulder and slip his arm out from underneath.", "order": 12329, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 17, "scene": 3, "line": "No. No. Everyone, now count down with me. THREE!", "order": 12330, "speaker": "Michael Scott"},
  {"season": 3, "episode": 17, "scene": 3, "line": "Sorry, quick thing. Is it true that if you can't get out, you don't want anyone to help you?", "order": 12331, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 17, "scene": 3, "line": "I will get out. Oh yes, I will.", "order": 12332, "speaker": "Michael Scott"},
  {"season": 3, "episode": 17, "scene": 3, "line": "So we shouldn't help you, no matter how much you might beg and plead?", "order": 12333, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 17, "scene": 3, "line": "No. Alright, this is getting hot. So let's just do this. Ok, ready? Three, two, one, go. [Michael spits out key, Jim covers it with his foot.]", "order": 12334, "speaker": "Michael Scott"},
  {"season": 3, "episode": 17, "scene": 3, "line": "AHHHH!", "order": 12335, "speaker": "Michael Scott"},
  {"season": 3, "episode": 17, "scene": 3, "line": "Is everything ok, Michael?", "order": 12336, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 17, "scene": 3, "line": "Yes.", "order": 12337, "speaker": "Michael Scott"},
  {"season": 3, "episode": 17, "scene": 4, "line": "I cannot tell you how I plan to escape. Other than by using magic. That is the magician's code. Separately, on an unrelated note, if you happen to find a small brass key...", "order": 12338, "speaker": "Michael Scott"},
  {"season": 3, "episode": 17, "scene": 5, "line": "Alright.", "order": 12339, "speaker": "Michael Scott"},
  {"season": 3, "episode": 17, "scene": 5, "line": "[trumpet sounds]", "order": 12340, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 17, "scene": 5, "line": "Ready? Come on guys. Early worm gets the worm.", "order": 12341, "speaker": "Michael Scott"},
  {"season": 3, "episode": 17, "scene": 5, "line": "Another worm? Like, are they friends?", "order": 12342, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 17, "scene": 5, "line": "It's early bird gets the worm.", "order": 12343, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 17, "scene": 5, "line": "Pam, would you smell my breath?", "order": 12344, "speaker": "Michael Scott"},
  {"season": 3, "episode": 17, "scene": 5, "line": "No, no.", "order": 12345, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 17, "scene": 5, "line": "Let me smell. [Michael breathes at Dwight] Good, not great.", "order": 12346, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 17, "scene": 6, "line": "Michael, you go to parties all the time, why is tonight so special?' Well, tonight is so special because my boss's boss's boss, the CFO, not his initials, common mistake, is having a little shindig for all the managers in the company. And Jan and I are going as a couple. For the first time. So it's kind of our coming out party. Really. And that is why tonight is so special.", "order": 12347, "speaker": "Michael Scott"},
  {"season": 3, "episode": 17, "scene": 7, "line": "Jimbo, last chance to carpool.", "order": 12348, "speaker": "Michael Scott"},
  {"season": 3, "episode": 17, "scene": 7, "line": "Oh no thanks, I think Karen and I will take my car.", "order": 12349, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 17, "scene": 7, "line": "Sure? Might be a good idea.", "order": 12350, "speaker": "Michael Scott"},
  {"season": 3, "episode": 17, "scene": 7, "line": "Yeah.", "order": 12351, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 17, "scene": 7, "line": "Go in together, could save some gas, have some fun, long trip.", "order": 12352, "speaker": "Michael Scott"},
  {"season": 3, "episode": 17, "scene": 7, "line": "Thanks.", "order": 12353, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 17, "scene": 7, "line": "Play some games?", "order": 12354, "speaker": "Michael Scott"},
  {"season": 3, "episode": 17, "scene": 7, "line": "Oh. Um. I think we're good.", "order": 12355, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 17, "scene": 7, "line": "I spy?", "order": 12356, "speaker": "Michael Scott"},
  {"season": 3, "episode": 17, "scene": 7, "line": "Yeah.", "order": 12357, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 17, "scene": 8, "line": "Why don't I wanna go? Didn't expect to need a reason, so let me think here. Um. I don't know any of these people. It's an obligation. I don't like talking paper in my free time, or in my work time. And, did I use the word pointless?", "order": 12358, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 17, "scene": 9, "line": "Thanks for inviting me along.", "order": 12359, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 17, "scene": 9, "line": "Oh, sure. Really didn't give it any thought. Wait, should you be going? [phone rings] Heh-woh you.", "order": 12360, "speaker": "Michael Scott"},
  {"season": 3, "episode": 17, "scene": 9, "line": "Michael?", "order": 12361, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 17, "scene": 9, "line": "Hey, Buttercup.", "order": 12362, "speaker": "Michael Scott"},
  {"season": 3, "episode": 17, "scene": 9, "line": "Hi.", "order": 12363, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 17, "scene": 9, "line": "I am on my way. I should be there in about 15...", "order": 12364, "speaker": "Michael Scott"},
  {"season": 3, "episode": 17, "scene": 9, "line": "Let's just blow this party off.", "order": 12365, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 17, "scene": 9, "line": "That's what she said.", "order": 12366, "speaker": "Michael Scott"},
  {"season": 3, "episode": 17, "scene": 9, "line": "Am I on speaker phone?", "order": 12367, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 17, "scene": 9, "line": "Uh, yes you are.", "order": 12368, "speaker": "Michael Scott"},
  {"season": 3, "episode": 17, "scene": 9, "line": "Is anybody else in the...", "order": 12369, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 17, "scene": 9, "line": "Hello, Jan.", "order": 12370, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 17, "scene": 9, "line": "Hi, Dwight. Ok, Michael, take me off speaker phone.", "order": 12371, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 17, "scene": 9, "line": "No problem. [doesn't turn off speakerphone]", "order": 12372, "speaker": "Michael Scott"},
  {"season": 3, "episode": 17, "scene": 9, "line": "Ok. Let's just go to a motel...", "order": 12373, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 17, "scene": 9, "line": "Ok.", "order": 12374, "speaker": "Michael Scott"},
  {"season": 3, "episode": 17, "scene": 9, "line": "...and just like rip into each other like we did on that black sand beach in Jamaica.", "order": 12375, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 17, "scene": 9, "line": "Ok. Jan. Jan? This party is actually a really big step for us. So, I...", "order": 12376, "speaker": "Michael Scott"},
  {"season": 3, "episode": 17, "scene": 9, "line": "Still on speaker?", "order": 12377, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 17, "scene": 9, "line": "Ummm... I don't know.", "order": 12378, "speaker": "Michael Scott"},
  {"season": 3, "episode": 17, "scene": 9, "line": "Are camera's there?", "order": 12379, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 17, "scene": 9, "line": "Maybe.", "order": 12380, "speaker": "Michael Scott"},
  {"season": 3, "episode": 17, "scene": 9, "line": "See you soon.", "order": 12381, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 17, "scene": 9, "line": "Ok.", "order": 12382, "speaker": "Michael Scott"},
  {"season": 3, "episode": 17, "scene": 9, "line": "Talk to you later, Jan.", "order": 12383, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 17, "scene": 9, "line": "Alright. Bye.", "order": 12384, "speaker": "Michael Scott"},
  {"season": 3, "episode": 17, "scene": 10, "line": "Hey, Michael left early, so a bunch of us are going to go to Poor Richard's for happy hour. You should come.", "order": 12385, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 17, "scene": 10, "line": "I can't. My brother, he just unloaded the jet ski's and kinda took a bath, so... we're going to go get hammered.", "order": 12386, "speaker": "Roy Anderson"},
  {"season": 3, "episode": 17, "scene": 10, "line": "Ok, well, we're going to a bar. Hey. You have to come to stuff with me. I'm serious! If you're going to be my boyfriend, you have to do boyfriend things.", "order": 12387, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 17, "scene": 10, "line": "Ok.", "order": 12388, "speaker": "Roy Anderson"},
  {"season": 3, "episode": 17, "scene": 11, "line": "I have decided that I'm going to be more honest. I'm going to tell people what I want. Directly. So, look out world, cause ol' Pammy is getting what she wants. And, don't call me Pammy.", "order": 12389, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 17, "scene": 12, "line": "Beauty. Thank you sir!", "order": 12390, "speaker": "Michael Scott"},
  {"season": 3, "episode": 17, "scene": 12, "line": "Salad.", "order": 12391, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 17, "scene": 12, "line": "Thank you.", "order": 12392, "speaker": "Michael Scott"},
  {"season": 3, "episode": 17, "scene": 12, "line": "You dressed exactly like the servants.", "order": 12393, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 17, "scene": 12, "line": "Shut up. Ok, change shirts with me.", "order": 12394, "speaker": "Michael Scott"},
  {"season": 3, "episode": 17, "scene": 12, "line": "Wait. I don't think yours will fit me.", "order": 12395, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 17, "scene": 12, "line": "I don't care. Oh, wow. Here. Don't put my jacket. Don't give me that.", "order": 12396, "speaker": "Michael Scott"},
  {"season": 3, "episode": 17, "scene": 12, "line": "That would have been really embarrassing.", "order": 12397, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 17, "scene": 12, "line": "Yes.", "order": 12398, "speaker": "Michael Scott"},
  {"season": 3, "episode": 17, "scene": 12, "line": "Crisis averted.", "order": 12399, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 17, "scene": 12, "line": "Ok.", "order": 12400, "speaker": "Michael Scott"},
  {"season": 3, "episode": 17, "scene": 13, "line": "Oh, that duck is so cute.", "order": 12401, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 17, "scene": 13, "line": "Hey Pam.", "order": 12402, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 17, "scene": 13, "line": "Hey guys.", "order": 12403, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 17, "scene": 13, "line": "Oscar. Angela.", "order": 12404, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 17, "scene": 14, "line": "Hi.", "order": 12405, "speaker": "Michael Scott"},
  {"season": 3, "episode": 17, "scene": 15, "line": "Actually, it's polite to arrive early. And smart. Only really good friends show up early. Ergo de facto. Go to a party really early. Become a really good friend.", "order": 12406, "speaker": "Michael Scott"},
  {"season": 3, "episode": 17, "scene": 16, "line": "Oh, um, potato salad.", "order": 12407, "speaker": "Michael Scott"},
  {"season": 3, "episode": 17, "scene": 16, "line": "It's from both of us.", "order": 12408, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 17, "scene": 16, "line": "No, it's not.", "order": 12409, "speaker": "Michael Scott"},
  {"season": 3, "episode": 17, "scene": 16, "line": "Wonderful, let's, ah, see where we can put this. Ok.", "order": 12410, "speaker": "Rachel"},
  {"season": 3, "episode": 17, "scene": 16, "line": "Oh, you probably want to leave the cover on until the guests get here.", "order": 12411, "speaker": "Michael Scott"},
  {"season": 3, "episode": 17, "scene": 17, "line": "It's been sitting in my car all day. Sun beating down on the mayonnaise. Just, you never know.", "order": 12412, "speaker": "Michael Scott"},
  {"season": 3, "episode": 17, "scene": 18, "line": "Kevin, you and Stacy set a date yet?", "order": 12413, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 17, "scene": 18, "line": "Yeah.", "order": 12414, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 17, "scene": 18, "line": "Oh my God, when is it?", "order": 12415, "speaker": "Kelly Kapoor"},
  {"season": 3, "episode": 17, "scene": 18, "line": "It's complicated. I would appreciate some space on this.", "order": 12416, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 17, "scene": 18, "line": "Hey.", "order": 12417, "speaker": "Roy Anderson"},
  {"season": 3, "episode": 17, "scene": 18, "line": "Hey. You made it.", "order": 12418, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 17, "scene": 18, "line": "You said it was important, so... How's it going?", "order": 12419, "speaker": "Roy Anderson"},
  {"season": 3, "episode": 17, "scene": 18, "line": "What's up, Pam.", "order": 12420, "speaker": "Kenny"},
  {"season": 3, "episode": 17, "scene": 18, "line": "Hey Kenny, sorry about the jet skis.", "order": 12421, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 17, "scene": 18, "line": "You guys, uh, want a round, on me?", "order": 12422, "speaker": "Roy Anderson"},
  {"season": 3, "episode": 17, "scene": 18, "line": "Yeah.", "order": 12423, "speaker": "Everyone"},
  {"season": 3, "episode": 17, "scene": 18, "line": "Yeah? Get you a drink.", "order": 12424, "speaker": "Roy Anderson"},
  {"season": 3, "episode": 17, "scene": 18, "line": "Thanks man.", "order": 12425, "speaker": "Kenny"},
  {"season": 3, "episode": 17, "scene": 18, "line": "No thank you, Roy.", "order": 12426, "speaker": "Angela Martin"},
  {"season": 3, "episode": 17, "scene": 19, "line": "Oh, you know that line on the top of the shrimp? That's feces.", "order": 12427, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 17, "scene": 20, "line": "What are you looking for? You bring dip?", "order": 12428, "speaker": "Michael Scott"},
  {"season": 3, "episode": 17, "scene": 20, "line": "I'm sure that it's catered. I need you to sign these, Michael. It's a waver of some of your rights. You should read it carefully. It releases the company in the event that our relationship, in your opinion or in reality, interferes with work. You get a copy, I get a copy, and a third copy goes to HR.", "order": 12429, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 17, "scene": 20, "line": "Awesome. I'm going to frame mine. I could frame yours too.", "order": 12430, "speaker": "Michael Scott"},
  {"season": 3, "episode": 17, "scene": 20, "line": "You realize this is a legal document that says you can't sue the company.", "order": 12431, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 17, "scene": 20, "line": "Over our love.", "order": 12432, "speaker": "Michael Scott"},
  {"season": 3, "episode": 17, "scene": 20, "line": "I've never told you that I love you.", "order": 12433, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 17, "scene": 20, "line": "You don't have to, Jan. This contract says it all.", "order": 12434, "speaker": "Michael Scott"},
  {"season": 3, "episode": 17, "scene": 21, "line": "I am taking a calculated risk. What's the upside? I overcome my nausea, fall deeply in love, babies, normalcy, no more self loathing. Downside? I, uh, date Michael Scott publicly and collapse in on myself like a dying star.", "order": 12435, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 17, "scene": 22, "line": "What's this over the 'i'?", "order": 12436, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 17, "scene": 22, "line": "It's a heart.", "order": 12437, "speaker": "Michael Scott"},
  {"season": 3, "episode": 17, "scene": 23, "line": "Why is this so hard? That's what she said. Oh my God. What am I saying?", "order": 12438, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 17, "scene": 24, "line": "I love this woman!", "order": 12439, "speaker": "Michael Scott"},
  {"season": 3, "episode": 17, "scene": 24, "line": "Oh, no. Michael, please. Michael, please.", "order": 12440, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 17, "scene": 25, "line": "Do you ever watch Battlestar Galactica?", "order": 12441, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 17, "scene": 25, "line": "No.", "order": 12442, "speaker": "Dan Gore"},
  {"season": 3, "episode": 17, "scene": 25, "line": "No? Then you are an idiot.", "order": 12443, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 17, "scene": 26, "line": "Hello Michael.", "order": 12444, "speaker": "Rachel"},
  {"season": 3, "episode": 17, "scene": 26, "line": "Rachel. Boy, you clean up good. Place looks great with all the lights on. And everything. Actually looks bigger with people in it. It's weird. So...", "order": 12445, "speaker": "Michael Scott"},
  {"season": 3, "episode": 17, "scene": 26, "line": "Jan, glad you could make it.", "order": 12446, "speaker": "David Wallace"},
  {"season": 3, "episode": 17, "scene": 26, "line": "Of course, of course, David. Do you remember Michael Scott?", "order": 12447, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 17, "scene": 26, "line": "Of course I do.", "order": 12448, "speaker": "David Wallace"},
  {"season": 3, "episode": 17, "scene": 26, "line": "From the Scranton Branch.", "order": 12449, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 17, "scene": 26, "line": "Jan and I are lovers. It feels so good to finally say that out loud.", "order": 12450, "speaker": "Michael Scott"},
  {"season": 3, "episode": 17, "scene": 26, "line": "David, can I, um, speak to you privately for just a moment, please.", "order": 12451, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 17, "scene": 26, "line": "Excuse me.", "order": 12452, "speaker": "Rachel"},
  {"season": 3, "episode": 17, "scene": 26, "line": "Ok.", "order": 12453, "speaker": "Michael Scott"},
  {"season": 3, "episode": 17, "scene": 27, "line": "[looks at beers] Oh. [Starts to walk away, turns around] No. Actually, one of these is supposed to be a lite.", "order": 12454, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 17, "scene": 27, "line": "Oh, sorry.", "order": 12455, "speaker": "Bartender"},
  {"season": 3, "episode": 17, "scene": 28, "line": "So did the merger go smoothly, or?", "order": 12456, "speaker": "Man"},
  {"season": 3, "episode": 17, "scene": 28, "line": "It did. Like butter. Mike Myers, SNL. You should ask Karen, she was one of them.", "order": 12457, "speaker": "Michael Scott"},
  {"season": 3, "episode": 17, "scene": 28, "line": "I'm the only one left. Everyone else was either fired or quit. And there is one in Anger Management.", "order": 12458, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 17, "scene": 28, "line": "Yeah, but you're great now, right? We're all great. Aren't we great?", "order": 12459, "speaker": "Michael Scott"},
  {"season": 3, "episode": 17, "scene": 28, "line": "We're good. [Michael tries to kiss Jan] Michael, stop. Please stop that, ok?", "order": 12460, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 17, "scene": 28, "line": "Can I get anyone anything, or?", "order": 12461, "speaker": "Rachel"},
  {"season": 3, "episode": 17, "scene": 28, "line": "I could go for an appeteaser.", "order": 12462, "speaker": "Michael Scott"},
  {"season": 3, "episode": 17, "scene": 28, "line": "Martini please.", "order": 12463, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 17, "scene": 28, "line": "Bagel bites or something.", "order": 12464, "speaker": "Michael Scott"},
  {"season": 3, "episode": 17, "scene": 28, "line": "Rachel, your house is beautiful.", "order": 12465, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 17, "scene": 28, "line": "Thank you.", "order": 12466, "speaker": "Rachel"},
  {"season": 3, "episode": 17, "scene": 28, "line": "What's the square footage?", "order": 12467, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 17, "scene": 28, "line": "About 5,000.", "order": 12468, "speaker": "David Wallace"},
  {"season": 3, "episode": 17, "scene": 28, "line": "Does that include the garage?", "order": 12469, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 17, "scene": 28, "line": "Dwight, wow. That's not appropriate.", "order": 12470, "speaker": "Michael Scott"},
  {"season": 3, "episode": 17, "scene": 28, "line": "I'm just...", "order": 12471, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 17, "scene": 28, "line": "I don't know", "order": 12472, "speaker": "David Wallace"},
  {"season": 3, "episode": 17, "scene": 28, "line": "It's a common question.", "order": 12473, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 17, "scene": 28, "line": "David, how much did this house cost?", "order": 12474, "speaker": "Michael Scott"},
  {"season": 3, "episode": 17, "scene": 28, "line": "These old colonials are great, when they're sound. I'd love to take a look around.", "order": 12475, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 17, "scene": 28, "line": "I'll show you around.", "order": 12476, "speaker": "Rachel"},
  {"season": 3, "episode": 17, "scene": 28, "line": "Cool, let's start with the banisters.", "order": 12477, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 17, "scene": 29, "line": "Hey, do you see that guy behind you in the blue blazer against the wall?", "order": 12478, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 17, "scene": 29, "line": "Yep.", "order": 12479, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 17, "scene": 29, "line": "That's Drake. And just so you know, I don't want to be weird or anything, but we use to date.", "order": 12480, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 17, "scene": 29, "line": "Oh, ok. Cool. Thanks for telling me.", "order": 12481, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 17, "scene": 29, "line": "And it didn't end well.", "order": 12482, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 17, "scene": 29, "line": "Gotcha. Alright.", "order": 12483, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 17, "scene": 30, "line": "This was a gift from Lee Iacocca. Twenty year old, single malt scotch.", "order": 12484, "speaker": "David Wallace"},
  {"season": 3, "episode": 17, "scene": 30, "line": "Here is to Mr. Iacocca and his failed experiment, the De Lorean. [takes sip, coughs violently]", "order": 12485, "speaker": "Michael Scott"},
  {"season": 3, "episode": 17, "scene": 30, "line": "You ok?", "order": 12486, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 17, "scene": 30, "line": "Yeah. Do you have any ice?", "order": 12487, "speaker": "Michael Scott"},
  {"season": 3, "episode": 17, "scene": 30, "line": "Sure.", "order": 12488, "speaker": "David Wallace"},
  {"season": 3, "episode": 17, "scene": 30, "line": "How about some Splenda?", "order": 12489, "speaker": "Michael Scott"},
  {"season": 3, "episode": 17, "scene": 31, "line": "One, two, three, up Jenkins! Down Jenkins. Oh, I think I heard the quarter over here.", "order": 12490, "speaker": "Roy Anderson"},
  {"season": 3, "episode": 17, "scene": 31, "line": "Where?", "order": 12491, "speaker": "Kenny"},
  {"season": 3, "episode": 17, "scene": 31, "line": "On this side.", "order": 12492, "speaker": "Roy Anderson"},
  {"season": 3, "episode": 17, "scene": 31, "line": "No, it is definitely under one of these hands. [points to Meredith and Kelly's hands]", "order": 12493, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 17, "scene": 31, "line": "I think I heard it on Roy's side.", "order": 12494, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 17, "scene": 31, "line": "No, it is here.", "order": 12495, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 17, "scene": 31, "line": "[tapping Meredith and Kelly's hands] Not here. Not here. Not here. Not here.", "order": 12496, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 17, "scene": 31, "line": "Good thing you didn't listen to me.", "order": 12497, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 17, "scene": 31, "line": "Yeah, close one.", "order": 12498, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 17, "scene": 31, "line": "[points at one of Stanley's hands and one of Pam's] Not here. Not here. It's either here or here. [Stares at Pam] Not here. [Points at Stanley's hand].", "order": 12499, "speaker": "Roy Anderson"},
  {"season": 3, "episode": 17, "scene": 31, "line": "YES!", "order": 12500, "speaker": "Kenny"},
  {"season": 3, "episode": 17, "scene": 31, "line": "Nice job.", "order": 12501, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 17, "scene": 31, "line": "I can read you like a book.", "order": 12502, "speaker": "Roy Anderson"},
  {"season": 3, "episode": 17, "scene": 31, "line": "Oh yeah?", "order": 12503, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 17, "scene": 31, "line": "You can't keep anything from me.", "order": 12504, "speaker": "Roy Anderson"},
  {"season": 3, "episode": 17, "scene": 32, "line": "Hey Creed.", "order": 12505, "speaker": "Student 1"},
  {"season": 3, "episode": 17, "scene": 32, "line": "Hey! What are you guys doing here?", "order": 12506, "speaker": "Creed Bratton"},
  {"season": 3, "episode": 17, "scene": 32, "line": "You're the man buddy.", "order": 12507, "speaker": "Student 2"},
  {"season": 3, "episode": 17, "scene": 33, "line": "I run a small fake ID company from my car with a laminating machine I swiped from the sheriff's station.", "order": 12508, "speaker": "Creed Bratton"},
  {"season": 3, "episode": 17, "scene": 34, "line": "Huh. [Bangs on wall] Yeah, these studs are way too far apart. What's in here? [Opens door].", "order": 12509, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 17, "scene": 34, "line": "Uh, that's a guest room.", "order": 12510, "speaker": "Rachel"},
  {"season": 3, "episode": 17, "scene": 34, "line": "Just the one window?", "order": 12511, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 17, "scene": 34, "line": "[phone rings] Oh. I must get that. You'll have to excuse me.", "order": 12512, "speaker": "Rachel"},
  {"season": 3, "episode": 17, "scene": 34, "line": "Are those real pearls?", "order": 12513, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 17, "scene": 34, "line": "Uh, yes.", "order": 12514, "speaker": "Rachel"},
  {"season": 3, "episode": 17, "scene": 34, "line": "[tests smoke detector] Good.", "order": 12515, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 17, "scene": 35, "line": "Well, it was nice meeting you guys. Take care.", "order": 12516, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 17, "scene": 35, "line": "Well, if you're wondering why his wife was staring daggers at me, it's because I kinda saw him for a little bit while they were separated.", "order": 12517, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 17, "scene": 35, "line": "Oh. Hadn't noticed.", "order": 12518, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 17, "scene": 35, "line": "Really? I thought it was so obvious. I'm glad it didn't make you uncomfortable.", "order": 12519, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 17, "scene": 35, "line": "No, it was before I knew you so, its fine.", "order": 12520, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 17, "scene": 36, "line": "This one really smells like vanilla. Check that out.", "order": 12521, "speaker": "Michael Scott"},
  {"season": 3, "episode": 17, "scene": 36, "line": "It's nice.", "order": 12522, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 17, "scene": 36, "line": "You and the misses should join us at Sandals Jamaica next Christmas.", "order": 12523, "speaker": "Michael Scott"},
  {"season": 3, "episode": 17, "scene": 36, "line": "I, Michael, I think David probably wants to spend Christmas here with his family.", "order": 12524, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 17, "scene": 36, "line": "Oh yeah, they don't allow kids at Sandals. They are persona non gratis... there. But it's fun. It's an awesome place. You would not believe how low this girl can limbo.", "order": 12525, "speaker": "Michael Scott"},
  {"season": 3, "episode": 17, "scene": 36, "line": "Oh.", "order": 12526, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 17, "scene": 36, "line": "Crazy.", "order": 12527, "speaker": "Michael Scott"},
  {"season": 3, "episode": 17, "scene": 36, "line": "I'm sorry. You're just going to have to excuse us for just a couple minutes.", "order": 12528, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 17, "scene": 36, "line": "Ok. Excuse me. What's going on? What is it?", "order": 12529, "speaker": "Michael Scott"},
  {"season": 3, "episode": 17, "scene": 36, "line": "Sorry. Michael, come here. Just, just, just, just. [kisses Michael]", "order": 12530, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 17, "scene": 36, "line": "What are you doing?", "order": 12531, "speaker": "Michael Scott"},
  {"season": 3, "episode": 17, "scene": 36, "line": "Don't you know what I'm doing?", "order": 12532, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 17, "scene": 36, "line": "Yes, but you could tell me. What... What is that? Why are you? Why are we going in the bathroom? I thought this is where you liked your privacy.", "order": 12533, "speaker": "Michael Scott"},
  {"season": 3, "episode": 17, "scene": 36, "line": "Shut up.", "order": 12534, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 17, "scene": 37, "line": "What has gotten into you?", "order": 12535, "speaker": "Michael Scott"},
  {"season": 3, "episode": 17, "scene": 37, "line": "Come on.", "order": 12536, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 17, "scene": 37, "line": "No, no, no.", "order": 12537, "speaker": "Michael Scott"},
  {"season": 3, "episode": 17, "scene": 37, "line": "What?", "order": 12538, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 17, "scene": 37, "line": "Come on, let's go back to the party.", "order": 12539, "speaker": "Michael Scott"},
  {"season": 3, "episode": 17, "scene": 37, "line": "Just let me loosen my dress.", "order": 12540, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 17, "scene": 37, "line": "Don't take that dress. Stop it, Jan.", "order": 12541, "speaker": "Michael Scott"},
  {"season": 3, "episode": 17, "scene": 37, "line": "Michael.", "order": 12542, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 17, "scene": 37, "line": "No, no, no, means please don't. Please.", "order": 12543, "speaker": "Michael Scott"},
  {"season": 3, "episode": 17, "scene": 37, "line": "Slam me up against the wall, right here.", "order": 12544, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 17, "scene": 37, "line": "I'm not going to slam you up against the wall.", "order": 12545, "speaker": "Michael Scott"},
  {"season": 3, "episode": 17, "scene": 37, "line": "Oh, please.", "order": 12546, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 17, "scene": 37, "line": "You're acting inappropriate. Jan.", "order": 12547, "speaker": "Michael Scott"},
  {"season": 3, "episode": 17, "scene": 37, "line": "Oh, I'm acting inappropriate? Get out.", "order": 12548, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 17, "scene": 38, "line": "Here. [hands Pam a duck stuffed animal]", "order": 12549, "speaker": "Toby Flenderson"},
  {"season": 3, "episode": 17, "scene": 38, "line": "Hey, where have you been all night? I was looking forward to hanging out with you.", "order": 12550, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 17, "scene": 38, "line": "I was...", "order": 12551, "speaker": "Toby Flenderson"},
  {"season": 3, "episode": 17, "scene": 38, "line": "Hey, don't you have a daughter?", "order": 12552, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 17, "scene": 38, "line": "[takes duck back] Yes.", "order": 12553, "speaker": "Toby Flenderson"},
  {"season": 3, "episode": 17, "scene": 39, "line": "Oh good, you're up. Hey, who makes this chair?", "order": 12554, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 17, "scene": 39, "line": "I don't know, it was here when I was born.", "order": 12555, "speaker": "Child"},
  {"season": 3, "episode": 17, "scene": 39, "line": "I want one. It's got good solid construction. Comfortable. What is this? Oak?", "order": 12556, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 17, "scene": 39, "line": "I don't know.", "order": 12557, "speaker": "Child"},
  {"season": 3, "episode": 17, "scene": 39, "line": "What do you know?", "order": 12558, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 17, "scene": 40, "line": "God, I hate these parties. Do you want to sneak out back and shoot some hoops? Meet me outside in two minutes.", "order": 12559, "speaker": "David Wallace"},
  {"season": 3, "episode": 17, "scene": 41, "line": "You stay here and have fun, because I'm going to go out back and shoot hoops with David Wallace.", "order": 12560, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 17, "scene": 41, "line": "Ok. Oh, um, don't mention that you and I are dating cause I think he might still have feelings for me.", "order": 12561, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 17, "scene": 41, "line": "Wallace? What the hell, have you dated like every guy here? [Karen smiles and gives herself away] Wow. Ok. You got me.", "order": 12562, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 17, "scene": 41, "line": "I so got you.", "order": 12563, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 17, "scene": 41, "line": "So, none of them?", "order": 12564, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 17, "scene": 41, "line": "Of course not. I mean, you're kind of like, my first.", "order": 12565, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 17, "scene": 41, "line": "Really?", "order": 12566, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 17, "scene": 41, "line": "Oh my God, it's so easy. Fun.", "order": 12567, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 17, "scene": 41, "line": "Ok.", "order": 12568, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 17, "scene": 41, "line": "Hey Jan.", "order": 12569, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 17, "scene": 41, "line": "Not too good.", "order": 12570, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 17, "scene": 42, "line": "Did you get a chance to try Michael's homemade potato salad?", "order": 12571, "speaker": "Rachel"},
  {"season": 3, "episode": 17, "scene": 43, "line": "Rachel thinks that I brought homemade potato salad and I just picked it up at the supermarket. It's funny. I wish I could make potato salad that good. It's just potatoes and mayonnaise. There is something wrong with Jan.", "order": 12572, "speaker": "Michael Scott"},
  {"season": 3, "episode": 17, "scene": 44, "line": "What's ah, what's with Jan and Michael?", "order": 12573, "speaker": "David Wallace"},
  {"season": 3, "episode": 17, "scene": 44, "line": "I don't know. Where to begin? My ball.", "order": 12574, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 17, "scene": 45, "line": "The chimney is in decent shape. Not great. I found some termite damage in a crawl space and some structural flaws in the foundation so all in all, it was a pretty fun cocktail party.", "order": 12575, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 17, "scene": 46, "line": "What?", "order": 12576, "speaker": "Roy Anderson"},
  {"season": 3, "episode": 17, "scene": 46, "line": "I want us to make it. I want a fresh start.", "order": 12577, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 17, "scene": 46, "line": "That's awesome. That's what I want.", "order": 12578, "speaker": "Roy Anderson"},
  {"season": 3, "episode": 17, "scene": 46, "line": "Oh ok, but in order for us to make it, there can't be any secrets between us.", "order": 12579, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 17, "scene": 46, "line": "I didn't do anything. Ask anyone, I totally could have and I didn't at all.", "order": 12580, "speaker": "Roy Anderson"},
  {"season": 3, "episode": 17, "scene": 46, "line": "Just listen. Remember that casino night about a month before we were supposed to get married? I kissed Jim.", "order": 12581, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 17, "scene": 46, "line": "What?", "order": 12582, "speaker": "Roy Anderson"},
  {"season": 3, "episode": 17, "scene": 46, "line": "He told me how he felt and I guess I had feelings too, and we kissed.", "order": 12583, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 17, "scene": 46, "line": "Jim came on to you?", "order": 12584, "speaker": "Roy Anderson"},
  {"season": 3, "episode": 17, "scene": 46, "line": "Just listen.", "order": 12585, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 17, "scene": 46, "line": "No, I am listening! That's the problem I am listening!", "order": 12586, "speaker": "Roy Anderson"},
  {"season": 3, "episode": 17, "scene": 46, "line": "Don't yell!", "order": 12587, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 17, "scene": 46, "line": "Don't yell?!", "order": 12588, "speaker": "Roy Anderson"},
  {"season": 3, "episode": 17, "scene": 46, "line": "This is over.", "order": 12589, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 17, "scene": 46, "line": "Yeah, you're right. This is so over. You kidding me, Pam!? Come on! God!", "order": 12590, "speaker": "Roy Anderson"},
  {"season": 3, "episode": 17, "scene": 46, "line": "Damn jet skis!", "order": 12591, "speaker": "Kenny"},
  {"season": 3, "episode": 17, "scene": 47, "line": "Our first fight. If this is about what happened in the bathroom, there was no place to cuddle...", "order": 12592, "speaker": "Michael Scott"},
  {"season": 3, "episode": 17, "scene": 47, "line": "I feel sick.", "order": 12593, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 17, "scene": 47, "line": "You didn't have any of the potato salad did you?", "order": 12594, "speaker": "Michael Scott"},
  {"season": 3, "episode": 17, "scene": 47, "line": "No, we were good when we were just running around, you know, in secret. It was wrong and it was exciting. Maybe it was a mistake to take it public.", "order": 12595, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 17, "scene": 47, "line": "Well, if that's the way you feel, my lady, then you have hurt me greatly.", "order": 12596, "speaker": "Michael Scott"},
  {"season": 3, "episode": 17, "scene": 47, "line": "Please don't cry.", "order": 12597, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 17, "scene": 47, "line": "I'm not going to cry. I feel like it but I am not going to. Why don't you just take your stupid love contract and tear it up into a million little pieces.", "order": 12598, "speaker": "Michael Scott"},
  {"season": 3, "episode": 17, "scene": 47, "line": "It was never a love contract, Michael and besides, I have already given a copy to David and it would be just as embarrassing to get it back as I was handing it to him.", "order": 12599, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 17, "scene": 47, "line": "I want the house, Jan. I want the picket fence. I want the ketchup fights and the tickling, and the giggling.", "order": 12600, "speaker": "Michael Scott"},
  {"season": 3, "episode": 17, "scene": 47, "line": "I didn't mean it. I was...", "order": 12601, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 17, "scene": 47, "line": "Whatever.", "order": 12602, "speaker": "Michael Scott"},
  {"season": 3, "episode": 17, "scene": 47, "line": "Tired. I'm tired. And I didn't eat enough. And, and, that's all. That was it.", "order": 12603, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 17, "scene": 47, "line": "That's all, you didn't mean it?", "order": 12604, "speaker": "Michael Scott"},
  {"season": 3, "episode": 17, "scene": 47, "line": "That's all. I didn't mean it. That's all. I'm just saying I didn't mean it.", "order": 12605, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 17, "scene": 47, "line": "I love you, Jan.", "order": 12606, "speaker": "Michael Scott"},
  {"season": 3, "episode": 17, "scene": 47, "line": "Ok.", "order": 12607, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 17, "scene": 47, "line": "Don't break up you guys, you're great together.", "order": 12608, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 17, "scene": 48, "line": "Are they going to call the cops?", "order": 12609, "speaker": "Roy Anderson"},
  {"season": 3, "episode": 17, "scene": 48, "line": "No, I paid them off.", "order": 12610, "speaker": "Kenny"},
  {"season": 3, "episode": 17, "scene": 48, "line": "Jet ski money?", "order": 12611, "speaker": "Roy Anderson"},
  {"season": 3, "episode": 17, "scene": 48, "line": "All of it.", "order": 12612, "speaker": "Kenny"},
  {"season": 3, "episode": 17, "scene": 48, "line": "I'm gonna kill Jim Halpert.", "order": 12613, "speaker": "Roy Anderson"},
  {"season": 3, "episode": 17, "scene": 49, "line": "I think it's great that Jim's invited. It's important to have contact with the New York execs, or they'll think everyone here is like Mi...chael.", "order": 12614, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 17, "scene": 50, "line": "[Points to Pam's painting of the building] [laughs] You gotta sign that.", "order": 12615, "speaker": "Roy Anderson"},
  {"season": 3, "episode": 17, "scene": 50, "line": "[laughs]", "order": 12616, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 17, "scene": 50, "line": "No you gotta put your name in the corner. I've seen that artist do that. Its good too, cause like you can know who paints what, you know.", "order": 12617, "speaker": "Roy Anderson"},
  {"season": 3, "episode": 17, "scene": 51, "line": "Hey, are you going to Poor Richard's?", "order": 12618, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 17, "scene": 51, "line": "I don't know, you?", "order": 12619, "speaker": "Toby Flenderson"},
  {"season": 3, "episode": 17, "scene": 51, "line": "Maybe.", "order": 12620, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 17, "scene": 51, "line": "I'm going and so are both of you. We're all going.", "order": 12621, "speaker": "Kelly Kapoor"},
  {"season": 3, "episode": 17, "scene": 51, "line": "Hey, isn't there some rule against inter-office dating?", "order": 12622, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 17, "scene": 51, "line": "[whispering] I am not doing your dirty work for you.", "order": 12623, "speaker": "Toby Flenderson"},
  {"season": 3, "episode": 17, "scene": 51, "line": "I hear whispering.", "order": 12624, "speaker": "Kelly Kapoor"},
  {"season": 3, "episode": 17, "scene": 52, "line": "Ok, so promise that you'll wait till we leave the parking lot before you go to the bar.", "order": 12625, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 17, "scene": 52, "line": "Yes boss.", "order": 12626, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 17, "scene": 52, "line": "See ya Pam.", "order": 12627, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 17, "scene": 53, "line": "Oscar, Angela? I didn't think you guys would come.", "order": 12628, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 17, "scene": 53, "line": "Why wouldn't we come?", "order": 12629, "speaker": "Angela Martin"},
  {"season": 3, "episode": 17, "scene": 53, "line": "Because of reasons...", "order": 12630, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 17, "scene": 54, "line": "Are there carbs in vodka?", "order": 12631, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 17, "scene": 55, "line": "Hey folks, what can I get you? Oh, hey Meredith.", "order": 12632, "speaker": "Waiter"},
  {"season": 3, "episode": 17, "scene": 55, "line": "Hey", "order": 12633, "speaker": "Meredith Palmer"},
  {"season": 3, "episode": 17, "scene": 55, "line": "Another vodka?", "order": 12634, "speaker": "Waiter"},
  {"season": 3, "episode": 17, "scene": 55, "line": "Yeah. Jack, these are my co-workers.", "order": 12635, "speaker": "Meredith Palmer"},
  {"season": 3, "episode": 17, "scene": 55, "line": "Sup?", "order": 12636, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 17, "scene": 55, "line": "[pointing to each correct person] Ryan, Pam, Stanley, Kelly, Kevin, Creed. How'd I do?", "order": 12637, "speaker": "Waiter"},
  {"season": 3, "episode": 17, "scene": 55, "line": "Hmm, come here much?", "order": 12638, "speaker": "Stanley Hudson"},
  {"season": 3, "episode": 17, "scene": 56, "line": "These wings are all fat and bone.", "order": 12639, "speaker": "Oscar Martinez"},
  {"season": 3, "episode": 17, "scene": 56, "line": "This place is disgusting. [They smile at each other]", "order": 12640, "speaker": "Angela Martin"},
  {"season": 3, "episode": 17, "scene": 57, "line": "Why would the architect design stairs this wide? Was there an obese family in here before you?", "order": 12641, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 17, "scene": 57, "line": "I don't know that. I'm sure the architect passed away many years ago.", "order": 12642, "speaker": "Rachel"},
  {"season": 3, "episode": 17, "scene": 57, "line": "How convenient.", "order": 12643, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 17, "scene": 58, "line": "I am attracted to weird, wrong men. I dated a violent karate instructor and then an unemployed fireman. Life has been complicated. I've made some decisions that I'm not proud of; my marriage, being overly litigious with my family. I orgasm fairly easily. Michael was kind of like my dirty secret, you know, having sex with him, oh I was just turned on by my own degradation. Um, why, anyway, I was told by somebody recently that I respect that I'm old enough to have a mature relationship, and that's what this whole evening is about and I have high hopes.", "order": 12644, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 17, "scene": 59, "line": "[at the door] Hi Ryan.", "order": 12645, "speaker": "Melissa"},
  {"season": 3, "episode": 17, "scene": 59, "line": "Hey.", "order": 12646, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 17, "scene": 59, "line": "How are you doing? I've, I've missed you.", "order": 12647, "speaker": "Melissa"},
  {"season": 3, "episode": 17, "scene": 59, "line": "I've missed you too...", "order": 12648, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 17, "scene": 59, "line": "Don't you remember me?", "order": 12649, "speaker": "Melissa"},
  {"season": 3, "episode": 17, "scene": 59, "line": "Um, you ah, you gotta give me a hint. Um, did you meet me at the gym?", "order": 12650, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 17, "scene": 59, "line": "No.", "order": 12651, "speaker": "Melissa"},
  {"season": 3, "episode": 17, "scene": 59, "line": "[still at the table] Who the hell is Ryan talking to?", "order": 12652, "speaker": "Kelly Kapoor"},
  {"season": 3, "episode": 17, "scene": 59, "line": "[at the door] Are you a client of Dunder Mifflin.", "order": 12653, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 17, "scene": 59, "line": "No.", "order": 12654, "speaker": "Melissa"},
  {"season": 3, "episode": 17, "scene": 59, "line": "[at the table] Oh, not again. Melissa!", "order": 12655, "speaker": "Stanley Hudson"},
  {"season": 3, "episode": 17, "scene": 59, "line": "Daddy.", "order": 12656, "speaker": "Melissa"},
  {"season": 3, "episode": 17, "scene": 59, "line": "Wait for my outside. [To Ryan] She is sixteen years old. What is wrong with you?", "order": 12657, "speaker": "Stanley Hudson"},
  {"season": 3, "episode": 17, "scene": 59, "line": "Okay, okay. Stanley, let me explain to you what happened.", "order": 12658, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 17, "scene": 59, "line": "No, no, no, no.", "order": 12659, "speaker": "Stanley Hudson"},
  {"season": 3, "episode": 17, "scene": 59, "line": "She came right up to me just like she did when you brought her into work.", "order": 12660, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 17, "scene": 59, "line": "No, no, no. You planned this whole thing boy. I'll snatch your spine out your back.", "order": 12661, "speaker": "Stanley Hudson"},
  {"season": 3, "episode": 17, "scene": 60, "line": "Space shuttle. Which one? Oh, Atlantis. Good thing it's not the Discovery. You play soccer?", "order": 12662, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 17, "scene": 61, "line": "What a weird day. I don't understand it, but you don't get to understand everyday of your life. Maybe five, ten days a year, I'll get home and I will have no idea what the hell just happened. Guess everybody has days like that. Sometimes they're the nicest ones. Still, I think there might be something wrong with Jan.", "order": 12663, "speaker": "Michael Scott"},
  {"season": 3, "episode": 18, "scene": 1, "line": "So do you want to see it or not?", "order": 12664, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 18, "scene": 1, "line": "I don't know. Feel like... Friday night crowds...", "order": 12665, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 18, "scene": 1, "line": "Oh my God, you're like, agoraphobic.", "order": 12666, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 18, "scene": 1, "line": "Agoraphobic?", "order": 12667, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 18, "scene": 1, "line": "Yeah.", "order": 12668, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 18, "scene": 1, "line": "Really?", "order": 12669, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 18, "scene": 1, "line": "Yeah! You would rather sit on your couch and watch a Phillies game, than go out to a movie with your awesome girlfriend.", "order": 12670, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 18, "scene": 1, "line": "Absolutely correct.", "order": 12671, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 18, "scene": 1, "line": "Later, Jim.", "order": 12672, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 18, "scene": 1, "line": "Kev, have a good weekend.", "order": 12673, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 18, "scene": 1, "line": "Bye. Ok, so this is what's gonna happen. You're gonna suck it up.", "order": 12674, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 18, "scene": 1, "line": "Here we go...", "order": 12675, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 18, "scene": 1, "line": "...and we're gonna go to dinner.", "order": 12676, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 18, "scene": 1, "line": "Ok...", "order": 12677, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 18, "scene": 1, "line": "And then we're gonna go to the movies.", "order": 12678, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 18, "scene": 1, "line": "Sounds good.", "order": 12679, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 18, "scene": 1, "line": "Hey Halpert!", "order": 12680, "speaker": "Roy Anderson"},
  {"season": 3, "episode": 18, "scene": 1, "line": "Hey... [Roy lunges towards Jim]", "order": 12681, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 18, "scene": 1, "line": "ROY!", "order": 12682, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 18, "scene": 1, "line": "[shrieks]", "order": 12683, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 18, "scene": 1, "line": "Roy don't! [Dwight pepper-sprays Roy]", "order": 12684, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 18, "scene": 1, "line": "[screams in pain] Ahh God!", "order": 12685, "speaker": "Roy Anderson"},
  {"season": 3, "episode": 18, "scene": 1, "line": "Pam, please call security!", "order": 12686, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 18, "scene": 2, "line": "Everyday, for eight years, I have brought pepper spray into this office to protect myself and my fellow employees. And everyday, for eight years, people have laughed at me. Well, who's laughing now? [Dwight blinks and winces in pain from the pepper spray]", "order": 12687, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 18, "scene": 3, "line": "No need for consternation, everything is under control.", "order": 12688, "speaker": "Michael Scott"},
  {"season": 3, "episode": 18, "scene": 3, "line": "Michael, last Friday one of your employees attacked another employee in your office!", "order": 12689, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 18, "scene": 3, "line": "It was a crime of passion, Jan, not a disgruntled employee. Everyone here is extremely gruntled.", "order": 12690, "speaker": "Michael Scott"},
  {"season": 3, "episode": 18, "scene": 3, "line": "[sigh] Is Toby there?", "order": 12691, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 18, "scene": 3, "line": "No...", "order": 12692, "speaker": "Michael Scott"},
  {"season": 3, "episode": 18, "scene": 3, "line": "I'm... here, Jan.", "order": 12693, "speaker": "Toby Flenderson"},
  {"season": 3, "episode": 18, "scene": 3, "line": "Ok, what... what is the situation Toby?", "order": 12694, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 18, "scene": 3, "line": "Well, we fired Roy, obviously. And Jim won't press charges against Roy or the company.", "order": 12695, "speaker": "Toby Flenderson"},
  {"season": 3, "episode": 18, "scene": 3, "line": "Thank God.", "order": 12696, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 18, "scene": 3, "line": "Yeah, um, but now apparently Darryl has some issue with his...", "order": 12697, "speaker": "Toby Flenderson"},
  {"season": 3, "episode": 18, "scene": 3, "line": "No, he has been wanting a raise for a couple of months and he's just using this Roy thing as leverage.", "order": 12698, "speaker": "Michael Scott"},
  {"season": 3, "episode": 18, "scene": 3, "line": "All right, well are you gonna take care of this?", "order": 12699, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 18, "scene": 3, "line": "Yeppers.", "order": 12700, "speaker": "Michael Scott"},
  {"season": 3, "episode": 18, "scene": 3, "line": "What did I tell you about 'yeppers?'", "order": 12701, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 18, "scene": 3, "line": "I don't... remember.", "order": 12702, "speaker": "Michael Scott"},
  {"season": 3, "episode": 18, "scene": 3, "line": "I told you not to say it. Do you remember that?", "order": 12703, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 18, "scene": 3, "line": "Yeesh...", "order": 12704, "speaker": "Michael Scott"},
  {"season": 3, "episode": 18, "scene": 4, "line": "I really don't want to talk about it. I don't mean to be rude, but I just... I don't want to comment on what happened. It", "order": 12705, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 18, "scene": 5, "line": "I guess... all things considered, I was lucky Dwight was there. And Roy was lucky that Dwight only used pepper spray. And not the nunchucks or the throwing stars.", "order": 12706, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 18, "scene": 6, "line": "Hey man, I never got a chance to thank you... for stopping Roy. Thank you.", "order": 12707, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 18, "scene": 6, "line": "Thank you not necessary and thus, not accepted. I saw someone breaking the law and I interceded.", "order": 12708, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 18, "scene": 6, "line": "Okay. Um... Got you something.", "order": 12709, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 18, "scene": 6, "line": "Don't want it.", "order": 12710, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 18, "scene": 6, "line": "You don't know what it is.", "order": 12711, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 18, "scene": 6, "line": "Don't want it. Won't open it. Don't need it. Won't take it. Citizens do not accept prizes for being citizens.", "order": 12712, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 18, "scene": 7, "line": "It was a little glass display case for his for his bobblehead. That would have made us even, I think. He saves my life, I get him a box for his desk toy. Even Steven.", "order": 12713, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 18, "scene": 8, "line": "No, don't call me a hero. Do you know who the real heroes are? The guys who wake up every morning, and go into their normal jobs, and get a distress call from the commissioner, and take off their glasses and change into capes and fly around fighting crime. Those are the real heroes.", "order": 12714, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 18, "scene": 9, "line": "Angela, Roy's check. He's coming in later to pick it up.", "order": 12715, "speaker": "Oscar Martinez"},
  {"season": 3, "episode": 18, "scene": 9, "line": "Man, I cannot believe I missed the fight.", "order": 12716, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 18, "scene": 9, "line": "It was crazy.", "order": 12717, "speaker": "Oscar Martinez"},
  {"season": 3, "episode": 18, "scene": 9, "line": "You saw it? Describe it please.", "order": 12718, "speaker": "Angela Martin"},
  {"season": 3, "episode": 18, "scene": 9, "line": "Well, I heard some shouting. And I look over and Roy's by reception and you could just tell he's gonna punch somebody. Jim says something. Roy stomps over there. All of the sudden, BAM. Roy goes down, and Dwight's standing there like an action hero.", "order": 12719, "speaker": "Oscar Martinez"},
  {"season": 3, "episode": 18, "scene": 9, "line": "Oh...", "order": 12720, "speaker": "Angela Martin"},
  {"season": 3, "episode": 18, "scene": 9, "line": "It was insane!", "order": 12721, "speaker": "Oscar Martinez"},
  {"season": 3, "episode": 18, "scene": 9, "line": "[flustered] Well... good for Dwight.", "order": 12722, "speaker": "Angela Martin"},
  {"season": 3, "episode": 18, "scene": 10, "line": "Ok I want you to be Darryl and ask me for a raise, because I want to try out some of these negotiation tactics on you.", "order": 12723, "speaker": "Michael Scott"},
  {"season": 3, "episode": 18, "scene": 10, "line": "Where'd you get that?", "order": 12724, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 18, "scene": 10, "line": "Wikipedia.", "order": 12725, "speaker": "Michael Scott"},
  {"season": 3, "episode": 18, "scene": 11, "line": "Wikipedia... is the best thing ever. Anyone in the world can write anything they want about any subject, so you know you are getting the best possible information.", "order": 12726, "speaker": "Michael Scott"},
  {"season": 3, "episode": 18, "scene": 12, "line": "Ok, Darryl, ask me for a raise.", "order": 12727, "speaker": "Michael Scott"},
  {"season": 3, "episode": 18, "scene": 12, "line": "Hey, Mike. Since Roy left I've been doing a lot more work, and I need a raise.", "order": 12728, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 18, "scene": 12, "line": "Hmm, well that's interesting Darryl. I think... [mumbling softly] that maybe you should... [mumbling jibberish]", "order": 12729, "speaker": "Michael Scott"},
  {"season": 3, "episode": 18, "scene": 12, "line": "I can't hear you.", "order": 12730, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 18, "scene": 12, "line": "What I'm saying is that, [continues to mumble jibberish]", "order": 12731, "speaker": "Michael Scott"},
  {"season": 3, "episode": 18, "scene": 12, "line": "Still nothin'.", "order": 12732, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 18, "scene": 12, "line": "Ok, see what I did?", "order": 12733, "speaker": "Michael Scott"},
  {"season": 3, "episode": 18, "scene": 12, "line": "No.", "order": 12734, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 18, "scene": 12, "line": "By leaning back, and by whispering, I established a dominant physical position.", "order": 12735, "speaker": "Michael Scott"},
  {"season": 3, "episode": 18, "scene": 12, "line": "Nice.", "order": 12736, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 18, "scene": 12, "line": "Ok, let's try another one. Um...", "order": 12737, "speaker": "Michael Scott"},
  {"season": 3, "episode": 18, "scene": 12, "line": "Okay.", "order": 12738, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 18, "scene": 12, "line": "Walking out of the room unexpectedly.", "order": 12739, "speaker": "Michael Scott"},
  {"season": 3, "episode": 18, "scene": 12, "line": "And what happens in this one?", "order": 12740, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 18, "scene": 12, "line": "It's a surprise.", "order": 12741, "speaker": "Michael Scott"},
  {"season": 3, "episode": 18, "scene": 12, "line": "Okay.", "order": 12742, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 18, "scene": 12, "line": "Go ahead, ask me for a raise.", "order": 12743, "speaker": "Michael Scott"},
  {"season": 3, "episode": 18, "scene": 12, "line": "Can I have a raise?", "order": 12744, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 18, "scene": 12, "line": "[gets up and begins to walk out of the room]", "order": 12745, "speaker": "Michael Scott"},
  {"season": 3, "episode": 18, "scene": 12, "line": "[softly] Sex, Steve Martin, Terri Hatcher.", "order": 12746, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 18, "scene": 12, "line": "What?", "order": 12747, "speaker": "Michael Scott"},
  {"season": 3, "episode": 18, "scene": 12, "line": "What?", "order": 12748, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 18, "scene": 12, "line": "No, what did you say?", "order": 12749, "speaker": "Michael Scott"},
  {"season": 3, "episode": 18, "scene": 12, "line": "I didn't say anything. I was waiting to see what happened.", "order": 12750, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 18, "scene": 12, "line": "Oh it... sounded interesting... what you were gonna...", "order": 12751, "speaker": "Michael Scott"},
  {"season": 3, "episode": 18, "scene": 13, "line": "I saw the perpetrator advance toward the victim at a high rate of speed. His head was thrown back, his shoulder and arm cocked indicating an attack position. Perp grabbed the victim. I removed my weapon from its secure hiding place.", "order": 12752, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 18, "scene": 13, "line": "Which is where?", "order": 12753, "speaker": "Toby Flenderson"},
  {"season": 3, "episode": 18, "scene": 13, "line": "Irrelevant. Discharged it at a distance of a little over a meter into the perpetrator's eyes, nose, and face area. Rendering him utterly and completely disabled. Then I contacted the authorities. The end.", "order": 12754, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 18, "scene": 13, "line": "Thanks Dwight.", "order": 12755, "speaker": "Toby Flenderson"},
  {"season": 3, "episode": 18, "scene": 14, "line": "That is the bravest thing I have ever heard.", "order": 12756, "speaker": "Kelly Kapoor"},
  {"season": 3, "episode": 18, "scene": 14, "line": "I can't imagine what I would have done.", "order": 12757, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 18, "scene": 14, "line": "I can. You would have left me to fend for myself. Like that time we were on the Ferris Wheel and that kid dropped a milk shake on me and you just laughed.", "order": 12758, "speaker": "Kelly Kapoor"},
  {"season": 3, "episode": 18, "scene": 14, "line": "Well that was funny, that's why.", "order": 12759, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 18, "scene": 14, "line": "Oh it was?", "order": 12760, "speaker": "Kelly Kapoor"},
  {"season": 3, "episode": 18, "scene": 14, "line": "Mm-hmm.", "order": 12761, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 18, "scene": 14, "line": "Okay, well the next time that you get scared, that you think a murderer's in your apartment in the middle of the night...", "order": 12762, "speaker": "Kelly Kapoor"},
  {"season": 3, "episode": 18, "scene": 14, "line": "Okay.", "order": 12763, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 18, "scene": 14, "line": "...and you call me, to calm you down...", "order": 12764, "speaker": "Kelly Kapoor"},
  {"season": 3, "episode": 18, "scene": 14, "line": "You know what? I didn't---", "order": 12765, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 18, "scene": 14, "line": "Can you stop...", "order": 12766, "speaker": "Toby Flenderson"},
  {"season": 3, "episode": 18, "scene": 14, "line": "...you can just call somebody else 'cause I'm not gonna do it anymore, Ryan. I'm not.", "order": 12767, "speaker": "Kelly Kapoor"},
  {"season": 3, "episode": 18, "scene": 14, "line": "There's a bunch of people back here, maybe...", "order": 12768, "speaker": "Toby Flenderson"},
  {"season": 3, "episode": 18, "scene": 14, "line": "Well, don't talk to me about calling people in the middle of the night...", "order": 12769, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 18, "scene": 14, "line": "Guys...", "order": 12770, "speaker": "Toby Flenderson"},
  {"season": 3, "episode": 18, "scene": 14, "line": "I call you in the middle of the night to tell you that I love you!", "order": 12771, "speaker": "Kelly Kapoor"},
  {"season": 3, "episode": 18, "scene": 15, "line": "I don't think Michael intended to punish me, by putting Ryan back here with Kelly. But, if he did intend that? Wow. Genius.", "order": 12772, "speaker": "Toby Flenderson"},
  {"season": 3, "episode": 18, "scene": 16, "line": "[knock on door] Yeah.", "order": 12773, "speaker": "Michael Scott"},
  {"season": 3, "episode": 18, "scene": 16, "line": "You ready for me?", "order": 12774, "speaker": "Darryl Philbin"},
  {"season": 3, "episode": 18, "scene": 16, "line": "Yes, yeah, absolutely. Have a seat.", "order": 12775, "speaker": "Michael Scott"},
  {"season": 3, "episode": 18, "scene": 16, "line": "Cool.", "order": 12776, "speaker": "Darryl Philbin"},
  {"season": 3, "episode": 18, "scene": 16, "line": "You know what? Actually, let's go into the conference room.", "order": 12777, "speaker": "Michael Scott"},
  {"season": 3, "episode": 18, "scene": 16, "line": "Okay.", "order": 12778, "speaker": "Darryl Philbin"},
  {"season": 3, "episode": 18, "scene": 16, "line": "No, you know what? Let's stay here. No let's go... Yeah let's go to the conference room.", "order": 12779, "speaker": "Michael Scott"},
  {"season": 3, "episode": 18, "scene": 17, "line": "Tactic number six. Change the location of the meeting at the last second. Totally throws 'em off.", "order": 12780, "speaker": "Michael Scott"},
  {"season": 3, "episode": 18, "scene": 18, "line": "Number 14, declining to speak first. Makes them feel uncomfortable, puts you in control.", "order": 12781, "speaker": "Michael Scott"},
  {"season": 3, "episode": 18, "scene": 19, "line": "[long pause] I am declining to speak first.", "order": 12782, "speaker": "Michael Scott"},
  {"season": 3, "episode": 18, "scene": 19, "line": "Okay, I'll start. It's pretty simple really. I uh, I think I deserve a raise. I'm scheduled to get one in six months, but I'd like that to be moved up to now.", "order": 12783, "speaker": "Darryl Philbin"},
  {"season": 3, "episode": 18, "scene": 19, "line": "Hmm. Ohh, Darryl. You are a good worker, and a good man. I just, you know, times are tight. And I just don't think corporate is going to go for this right now.", "order": 12784, "speaker": "Michael Scott"},
  {"season": 3, "episode": 18, "scene": 19, "line": "Are you wearin' lady clothes?", "order": 12785, "speaker": "Darryl Philbin"},
  {"season": 3, "episode": 18, "scene": 19, "line": "What?", "order": 12786, "speaker": "Michael Scott"},
  {"season": 3, "episode": 18, "scene": 19, "line": "Are you wearin' lady clothes? Those look like lady... pants.", "order": 12787, "speaker": "Darryl Philbin"},
  {"season": 3, "episode": 18, "scene": 19, "line": "No, this is a power suit.", "order": 12788, "speaker": "Michael Scott"},
  {"season": 3, "episode": 18, "scene": 19, "line": "That there's a woman's suit.", "order": 12789, "speaker": "Darryl Philbin"},
  {"season": 3, "episode": 18, "scene": 19, "line": "[Darryl laughs] I do not buy woman's clothes. I would not make that mistake again.", "order": 12790, "speaker": "Michael Scott"},
  {"season": 3, "episode": 18, "scene": 19, "line": "I'ma call Roy, man.", "order": 12791, "speaker": "Darryl Philbin"},
  {"season": 3, "episode": 18, "scene": 19, "line": "Ohh... kay.", "order": 12792, "speaker": "Michael Scott"},
  {"season": 3, "episode": 18, "scene": 19, "line": "This is gonna make him feel better.", "order": 12793, "speaker": "Darryl Philbin"},
  {"season": 3, "episode": 18, "scene": 19, "line": "All right.", "order": 12794, "speaker": "Michael Scott"},
  {"season": 3, "episode": 18, "scene": 19, "line": "This is too good.", "order": 12795, "speaker": "Darryl Philbin"},
  {"season": 3, "episode": 18, "scene": 19, "line": "Alright, you know what? Pam, could you please tell Darryl that this is not a woman's suit?", "order": 12796, "speaker": "Michael Scott"},
  {"season": 3, "episode": 18, "scene": 19, "line": "Oh my God, that's a woman's suit!", "order": 12797, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 18, "scene": 19, "line": "You're wearing a woman's suit?", "order": 12798, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 18, "scene": 19, "line": "No, I do, I, I wear men's suits, OK? I got this out of a bin.", "order": 12799, "speaker": "Michael Scott"},
  {"season": 3, "episode": 18, "scene": 20, "line": "There were these huge bins of clothes, and everybody was rifling through them like crazy, and I grabbed one. And it fit! So I don't think that this is totally just a woman's suit. At the very least it's bisexual.", "order": 12800, "speaker": "Michael Scott"},
  {"season": 3, "episode": 18, "scene": 21, "line": "Who makes it?", "order": 12801, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 18, "scene": 21, "line": "Uh, [reading the inside of his jacket] MISSterious. And it is mysterious because the buttons are on the wrong side... that's the mystery.", "order": 12802, "speaker": "Michael Scott"},
  {"season": 3, "episode": 18, "scene": 21, "line": "Look, it's got shoulderpads, and did you see that lining?", "order": 12803, "speaker": "Phyllis Vance"},
  {"season": 3, "episode": 18, "scene": 21, "line": "Okay.", "order": 12804, "speaker": "Michael Scott"},
  {"season": 3, "episode": 18, "scene": 21, "line": "Did you see...", "order": 12805, "speaker": "Phyllis Vance"},
  {"season": 3, "episode": 18, "scene": 21, "line": "Would you stop it, please?", "order": 12806, "speaker": "Michael Scott"},
  {"season": 3, "episode": 18, "scene": 21, "line": "So, none of that tipped you off?", "order": 12807, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 18, "scene": 21, "line": "It's European, OK? It's a European cut.", "order": 12808, "speaker": "Michael Scott"},
  {"season": 3, "episode": 18, "scene": 21, "line": "Michael, the pants don't have any pockets.", "order": 12809, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 18, "scene": 21, "line": "No, they don't. See? [Michael lifts his jacket tail, sticks out his back side and shows Pam]", "order": 12810, "speaker": "Michael Scott"},
  {"season": 3, "episode": 18, "scene": 21, "line": "[Laughing, covering her mouth]", "order": 12811, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 18, "scene": 21, "line": "Italians don't wear pockets.", "order": 12812, "speaker": "Michael Scott"},
  {"season": 3, "episode": 18, "scene": 22, "line": "It's been a really rough couple of days... This helps a little.", "order": 12813, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 18, "scene": 23, "line": "Hey, maybe you want to come over and raid my closet?", "order": 12814, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 18, "scene": 23, "line": "No, I don't want to do that because I'm twice your size anyway.", "order": 12815, "speaker": "Michael Scott"},
  {"season": 3, "episode": 18, "scene": 23, "line": "Yeah, he look like Hillary Clinton.", "order": 12816, "speaker": "Darryl Philbin"},
  {"season": 3, "episode": 18, "scene": 23, "line": "Um, let's just do this in 15 minutes.", "order": 12817, "speaker": "Michael Scott"},
  {"season": 3, "episode": 18, "scene": 23, "line": "Okay, can you just stand right there? [snaps camera phone picture] I gotta send some e-mails.", "order": 12818, "speaker": "Darryl Philbin"},
  {"season": 3, "episode": 18, "scene": 24, "line": "Negotiations are all about controlling things. About being in the driver's seat. And make one tiny mistake, you're dead. I made one tiny mistake. I wore woman's clothes.", "order": 12819, "speaker": "Michael Scott"},
  {"season": 3, "episode": 18, "scene": 25, "line": "Karen, how do you feel that Roy tried to kick your boyfriend's ass over another woman?", "order": 12820, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 18, "scene": 25, "line": "I feel great, Kevin. Thank you.", "order": 12821, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 18, "scene": 25, "line": "You must have been scared out of your mind.", "order": 12822, "speaker": "Stanley Hudson"},
  {"season": 3, "episode": 18, "scene": 25, "line": "Well, you know it happened so fast I didn't really have time to be scared.", "order": 12823, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 18, "scene": 25, "line": "What happened, exactly? I wasn't here, so I haven't really heard the whole story.", "order": 12824, "speaker": "Angela Martin"},
  {"season": 3, "episode": 18, "scene": 25, "line": "Um, well, Jim and I were talking and Roy walked in looking super angry.", "order": 12825, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 18, "scene": 25, "line": "Mm-hmm.", "order": 12826, "speaker": "Angela Martin"},
  {"season": 3, "episode": 18, "scene": 25, "line": "And he's a big dude, you know? And all of a sudden, Jim pushed me out of the way, and Roy cocked his fist, and then bam, Dwight sprays him and knocks him on his butt.", "order": 12827, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 18, "scene": 25, "line": "[flustered] Goodness.", "order": 12828, "speaker": "Angela Martin"},
  {"season": 3, "episode": 18, "scene": 26, "line": "When I heard Jim and Pam had kissed, my reaction was to have lots of long talks with Jim about our feelings. Roy just attacked him. I'm not sure which one Jim hated more.", "order": 12829, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 18, "scene": 27, "line": "Let's get down to business. Why don't you tell me why you think you deserve a raise.", "order": 12830, "speaker": "Michael Scott"},
  {"season": 3, "episode": 18, "scene": 27, "line": "Well, it's simple Mike. I mean we merged these two branches right? So now we're shipping twice as many orders as we used to. With Roy gone we got a smaller crew. And I'm pickin' up all of his slack, so I think I should be compensated fairly, by gettin' a raise.", "order": 12831, "speaker": "Darryl Philbin"},
  {"season": 3, "episode": 18, "scene": 27, "line": "[mumbles jibberish]", "order": 12832, "speaker": "Michael Scott"},
  {"season": 3, "episode": 18, "scene": 27, "line": "What? I can't hear you.", "order": 12833, "speaker": "Darryl Philbin"},
  {"season": 3, "episode": 18, "scene": 27, "line": "[mumbling softly] That was a very good point.", "order": 12834, "speaker": "Michael Scott"},
  {"season": 3, "episode": 18, "scene": 27, "line": "I can't--- what, Mike? Are you---", "order": 12835, "speaker": "Darryl Philbin"},
  {"season": 3, "episode": 18, "scene": 27, "line": "[mumbling softly] You make a very compelling argument.", "order": 12836, "speaker": "Michael Scott"},
  {"season": 3, "episode": 18, "scene": 28, "line": "Sorry I almost got you killed.", "order": 12837, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 18, "scene": 28, "line": "Yeah, that was nuts.", "order": 12838, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 18, "scene": 28, "line": "He could have broken your nose or something. Crazy. It's just so stupid. I mean, getting back with Roy and everything. I mean, what was I thinking, right?", "order": 12839, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 18, "scene": 28, "line": "No, I mean, you guys really seem to have a strong connection.", "order": 12840, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 18, "scene": 28, "line": "Not anymore. It's, um... It's completely over now.", "order": 12841, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 18, "scene": 28, "line": "We'll see. I'm sure you guys will... find you way back to one another someday.", "order": 12842, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 18, "scene": 28, "line": "Jim... I am really... sorry.", "order": 12843, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 18, "scene": 28, "line": "Oh, yeah. Don't worry about it.", "order": 12844, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 18, "scene": 29, "line": "I am going to give you a piece of paper. I want you to write down how much you want, and I want you to slide it back across the desk to me.", "order": 12845, "speaker": "Michael Scott"},
  {"season": 3, "episode": 18, "scene": 29, "line": "Why can't I just... tell you?", "order": 12846, "speaker": "Darryl Philbin"},
  {"season": 3, "episode": 18, "scene": 29, "line": "Because, that is the way these things are done. In... films. [Darryl writes the amount and starts to hand the paper to Michael] No, slide--- slide it, yes.", "order": 12847, "speaker": "Michael Scott"},
  {"season": 3, "episode": 18, "scene": 29, "line": "There you go.", "order": 12848, "speaker": "Darryl Philbin"},
  {"season": 3, "episode": 18, "scene": 29, "line": "Oh. [scoffs] Come on. Be serious.", "order": 12849, "speaker": "Michael Scott"},
  {"season": 3, "episode": 18, "scene": 29, "line": "I am serious, Mike. That's a 10% raise. That's what I want.", "order": 12850, "speaker": "Darryl Philbin"},
  {"season": 3, "episode": 18, "scene": 29, "line": "I... I can't give you that, I--- I don't make this much.", "order": 12851, "speaker": "Michael Scott"},
  {"season": 3, "episode": 18, "scene": 29, "line": "Come on, be for real Mike.", "order": 12852, "speaker": "Darryl Philbin"},
  {"season": 3, "episode": 18, "scene": 29, "line": "I don't. Want me to prove it to you? There is... a pay stub.", "order": 12853, "speaker": "Michael Scott"},
  {"season": 3, "episode": 18, "scene": 29, "line": "[laughs] Are you serious? You're earning this?", "order": 12854, "speaker": "Darryl Philbin"},
  {"season": 3, "episode": 18, "scene": 29, "line": "Plus perks, yes.", "order": 12855, "speaker": "Michael Scott"},
  {"season": 3, "episode": 18, "scene": 29, "line": "Mike, this is barely more than I make. You been here ten years, dog. [laughs]", "order": 12856, "speaker": "Darryl Philbin"},
  {"season": 3, "episode": 18, "scene": 29, "line": "Fourteen years.", "order": 12857, "speaker": "Michael Scott"},
  {"season": 3, "episode": 18, "scene": 29, "line": "Ho-ho!", "order": 12858, "speaker": "Darryl Philbin"},
  {"season": 3, "episode": 18, "scene": 29, "line": "No, please, please...", "order": 12859, "speaker": "Michael Scott"},
  {"season": 3, "episode": 18, "scene": 29, "line": "Oh, I'm sorry Mike, some of my folks got to hear about this one. [texting on cell phone] Ah. [laughs]", "order": 12860, "speaker": "Darryl Philbin"},
  {"season": 3, "episode": 18, "scene": 29, "line": "Ok, let's take 15, again.", "order": 12861, "speaker": "Michael Scott"},
  {"season": 3, "episode": 18, "scene": 30, "line": "A boss's salary isn't just about money, it is about perks. It... for example, every year I get a $100 gas card... Can't put a price tag on that.", "order": 12862, "speaker": "Michael Scott"},
  {"season": 3, "episode": 18, "scene": 31, "line": "Ok, if you don't want a gift, at least let me buy you a beer, or lunch or something.", "order": 12863, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 18, "scene": 31, "line": "When Han Solo returns to the Death Star in the Millennium Falcon, and shoots down the TIE fighters and saves the Rebel cause, do you think he does so for a free beer?", "order": 12864, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 18, "scene": 31, "line": "Boy I---", "order": 12865, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 18, "scene": 31, "line": "No. And why are you so interested in buying me something Jim, what's your angle?", "order": 12866, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 18, "scene": 32, "line": "It's like when he annoys me and I want to screw with him to get him back, he never sees it coming. But now, I want to be nice to him, and actually give him something, and he's like an eel. I just can't grab onto him. It's infuriating.", "order": 12867, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 18, "scene": 32, "line": "Maybe you just feel guilty about all the pranks.", "order": 12868, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 18, "scene": 32, "line": "Well... yes, that's probably what it is. So what do I do?", "order": 12869, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 18, "scene": 32, "line": "Hmm... I don't know. Maybe you should go back out there and sell paper so we can go on a trip.", "order": 12870, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 18, "scene": 33, "line": "Michael, here's the, uh, $15 I owe you.", "order": 12871, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 18, "scene": 33, "line": "Oh, thank you.", "order": 12872, "speaker": "Michael Scott"},
  {"season": 3, "episode": 18, "scene": 33, "line": "Yeah. I heard you might need it. So...", "order": 12873, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 18, "scene": 33, "line": "Here's the $40 you gave me.", "order": 12874, "speaker": "Creed Bratton"},
  {"season": 3, "episode": 18, "scene": 33, "line": "I didn't give you $40.", "order": 12875, "speaker": "Michael Scott"},
  {"season": 3, "episode": 18, "scene": 33, "line": "In a way you did.", "order": 12876, "speaker": "Creed Bratton"},
  {"season": 3, "episode": 18, "scene": 34, "line": "Yeah, I heard how much Michael makes. I still think he's way overpaid.", "order": 12877, "speaker": "Stanley Hudson"},
  {"season": 3, "episode": 18, "scene": 35, "line": "[on cell phone] Fourteen years. Fourteen. I know. [laughing] Ok, alright. I gotta go. Later. [hangs up]", "order": 12878, "speaker": "Darryl Philbin"},
  {"season": 3, "episode": 18, "scene": 35, "line": "Okay. Okay, here's the straight... dope. No tricks. No Wikipedia.", "order": 12879, "speaker": "Michael Scott"},
  {"season": 3, "episode": 18, "scene": 35, "line": "What?", "order": 12880, "speaker": "Darryl Philbin"},
  {"season": 3, "episode": 18, "scene": 35, "line": "I talked to corporate, and they told me that I can only give you a 5% raise.", "order": 12881, "speaker": "Michael Scott"},
  {"season": 3, "episode": 18, "scene": 35, "line": "That's 'cause of you, Mike. They're not gonna give the workin' man more than the boss.", "order": 12882, "speaker": "Darryl Philbin"},
  {"season": 3, "episode": 18, "scene": 35, "line": "Well what am I supposed to do?", "order": 12883, "speaker": "Michael Scott"},
  {"season": 3, "episode": 18, "scene": 35, "line": "Get your own raise. You gotta get out there and earn, son.", "order": 12884, "speaker": "Darryl Philbin"},
  {"season": 3, "episode": 18, "scene": 35, "line": "I'm not gonna go out and ask for a raise right now. That is ridiculous.", "order": 12885, "speaker": "Michael Scott"},
  {"season": 3, "episode": 18, "scene": 35, "line": "Well, when they merged the two branches together, they put", "order": 12886, "speaker": "Darryl Philbin"},
  {"season": 3, "episode": 18, "scene": 35, "line": "[exhales] That's true.", "order": 12887, "speaker": "Michael Scott"},
  {"season": 3, "episode": 18, "scene": 35, "line": "Yeah that's true. You gotta call your girl, and get paid. Show her who wears the pants in the relationship.", "order": 12888, "speaker": "Darryl Philbin"},
  {"season": 3, "episode": 18, "scene": 35, "line": "You know what? I should.", "order": 12889, "speaker": "Michael Scott"},
  {"season": 3, "episode": 18, "scene": 35, "line": "Yeah, you should.", "order": 12890, "speaker": "Darryl Philbin"},
  {"season": 3, "episode": 18, "scene": 35, "line": "I have been a loyal employee for a long time.", "order": 12891, "speaker": "Michael Scott"},
  {"season": 3, "episode": 18, "scene": 35, "line": "Fourteen years long.", "order": 12892, "speaker": "Darryl Philbin"},
  {"season": 3, "episode": 18, "scene": 35, "line": "You know what? I deserve a bump.", "order": 12893, "speaker": "Michael Scott"},
  {"season": 3, "episode": 18, "scene": 35, "line": "Make it happen, cap'in.", "order": 12894, "speaker": "Darryl Philbin"},
  {"season": 3, "episode": 18, "scene": 35, "line": "I am makin' it happen, sergeant.", "order": 12895, "speaker": "Michael Scott"},
  {"season": 3, "episode": 18, "scene": 36, "line": "I remember it was very late at night, like 11, 11:30. Big fella comes in screamin' about God knows what. I think maybe Halpert had stolen his car. [Angela rolls her eyes] Something like that. So the big fella pulls out a sock filled with nickels. Then Schrute grabs a can of hairspray and a lighter---", "order": 12896, "speaker": "Creed Bratton"},
  {"season": 3, "episode": 18, "scene": 36, "line": "You're useless.", "order": 12897, "speaker": "Angela Martin"},
  {"season": 3, "episode": 18, "scene": 37, "line": "Why don't we talk next month, after the quarter ends?", "order": 12898, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 18, "scene": 37, "line": "No, Jan. I've never asked for a raise in 14 years. This is long overdue. I wanna do it today.", "order": 12899, "speaker": "Michael Scott"},
  {"season": 3, "episode": 18, "scene": 37, "line": "Today. All right, well, uh, if you want to do it today, we should meet in person, and uh, can you get here by five?", "order": 12900, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 18, "scene": 37, "line": "Yshhyah. Um, yeah. I'll leave right away.", "order": 12901, "speaker": "Michael Scott"},
  {"season": 3, "episode": 18, "scene": 37, "line": "Great. Uh, and listen. Because of our, uh, our... you know, situation, we're gonna need to have a third party present.", "order": 12902, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 18, "scene": 37, "line": "Yes, I'm bringing Darryl.", "order": 12903, "speaker": "Michael Scott"},
  {"season": 3, "episode": 18, "scene": 37, "line": "Da--- Darryl from the warehouse?", "order": 12904, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 18, "scene": 37, "line": "Mm-hm.", "order": 12905, "speaker": "Michael Scott"},
  {"season": 3, "episode": 18, "scene": 37, "line": "No, Michael. We, we need an HR rep. So, uh, I think you should just bring Toby.", "order": 12906, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 18, "scene": 37, "line": "Hey, I'd rather kill myself.", "order": 12907, "speaker": "Michael Scott"},
  {"season": 3, "episode": 18, "scene": 37, "line": "Michael, he's your branch's HR rep...", "order": 12908, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 18, "scene": 37, "line": "[talking over Jan] No, Toby is terrible. Toby is the worst human being I've ever known.", "order": 12909, "speaker": "Michael Scott"},
  {"season": 3, "episode": 18, "scene": 37, "line": "...and we need someone else, in the room, because of our relationship. You know this. Michael, either Toby comes with you, or we don't do it.", "order": 12910, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 18, "scene": 37, "line": "[sighs] Fine.", "order": 12911, "speaker": "Michael Scott"},
  {"season": 3, "episode": 18, "scene": 38, "line": "You are so mean.", "order": 12912, "speaker": "Kelly Kapoor"},
  {"season": 3, "episode": 18, "scene": 38, "line": "I don't know what you're talking about.", "order": 12913, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 18, "scene": 38, "line": "Yes you do, Ryan Bailey Howard. You called me stupid.", "order": 12914, "speaker": "Kelly Kapoor"},
  {"season": 3, "episode": 18, "scene": 38, "line": "No, I said your idea was stupid.", "order": 12915, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 18, "scene": 38, "line": "Toby, come on. Let's go.", "order": 12916, "speaker": "Michael Scott"},
  {"season": 3, "episode": 18, "scene": 38, "line": "Where?", "order": 12917, "speaker": "Toby Flenderson"},
  {"season": 3, "episode": 18, "scene": 38, "line": "Where? I'm gonna smack you in the head with a hammer. Come on, let's go.", "order": 12918, "speaker": "Michael Scott"},
  {"season": 3, "episode": 18, "scene": 38, "line": "What is so stupid about wanting to name a baby Usher?", "order": 12919, "speaker": "Kelly Kapoor"},
  {"season": 3, "episode": 18, "scene": 38, "line": "Alright.", "order": 12920, "speaker": "Toby Flenderson"},
  {"season": 3, "episode": 18, "scene": 38, "line": "Usher Jennifer Hudson Kapoor.", "order": 12921, "speaker": "Kelly Kapoor"},
  {"season": 3, "episode": 18, "scene": 38, "line": "Don't you see why that's insane?", "order": 12922, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 18, "scene": 38, "line": "Oh, so I'm crazy now?", "order": 12923, "speaker": "Kelly Kapoor"},
  {"season": 3, "episode": 18, "scene": 39, "line": "Comfortable, Mike?", "order": 12924, "speaker": "Darryl Philbin"},
  {"season": 3, "episode": 18, "scene": 39, "line": "Yeah. Fine.", "order": 12925, "speaker": "Michael Scott"},
  {"season": 3, "episode": 18, "scene": 39, "line": "How about you, man. Comfortable?", "order": 12926, "speaker": "Darryl Philbin"},
  {"season": 3, "episode": 18, "scene": 39, "line": "No.", "order": 12927, "speaker": "Toby Flenderson"},
  {"season": 3, "episode": 18, "scene": 39, "line": "[imitating Chris Tucker] Don't ever touch a black man's radio! ...Chris Tucker.", "order": 12928, "speaker": "Michael Scott"},
  {"season": 3, "episode": 18, "scene": 39, "line": "Thank you.", "order": 12929, "speaker": "Darryl Philbin"},
  {"season": 3, "episode": 18, "scene": 39, "line": "Well...", "order": 12930, "speaker": "Michael Scott"},
  {"season": 3, "episode": 18, "scene": 39, "line": "I haven't been to New York in a long time.", "order": 12931, "speaker": "Darryl Philbin"},
  {"season": 3, "episode": 18, "scene": 39, "line": "Mm, the Big Apple.", "order": 12932, "speaker": "Michael Scott"},
  {"season": 3, "episode": 18, "scene": 39, "line": "Maybe I'll stay overnight. Got a cousin lives down there.", "order": 12933, "speaker": "Darryl Philbin"},
  {"season": 3, "episode": 18, "scene": 39, "line": "How would we get home?", "order": 12934, "speaker": "Toby Flenderson"},
  {"season": 3, "episode": 18, "scene": 39, "line": "Oh you could stay too. He's got a big place.", "order": 12935, "speaker": "Darryl Philbin"},
  {"season": 3, "episode": 18, "scene": 39, "line": "Maybe I'll stay.", "order": 12936, "speaker": "Michael Scott"},
  {"season": 3, "episode": 18, "scene": 39, "line": "Mm, it's not that big.", "order": 12937, "speaker": "Darryl Philbin"},
  {"season": 3, "episode": 18, "scene": 39, "line": "Well...", "order": 12938, "speaker": "Michael Scott"},
  {"season": 3, "episode": 18, "scene": 39, "line": "Busses, though. They get you home quick.", "order": 12939, "speaker": "Darryl Philbin"},
  {"season": 3, "episode": 18, "scene": 39, "line": "[mouth full of pretzels] Oh, I...", "order": 12940, "speaker": "Michael Scott"},
  {"season": 3, "episode": 18, "scene": 40, "line": "And all of a sudden, Dwight stood up and was like 'No!'", "order": 12941, "speaker": "Kelly Kapoor"},
  {"season": 3, "episode": 18, "scene": 40, "line": "Then what'd he do? [Kelly's phone rings]", "order": 12942, "speaker": "Angela Martin"},
  {"season": 3, "episode": 18, "scene": 40, "line": "You should just read the report that Toby did. He took everyone's stories. [answering phone] Dunder Mifflin, customer service, this is Kelly. Oh yeah I could totally help you with that. Ok, let me just get the folder out. Okay, it seems here that you ordered 12,000 reams of paper. Oh, 12 reams...", "order": 12943, "speaker": "Kelly Kapoor"},
  {"season": 3, "episode": 18, "scene": 41, "line": "Hey guys, Jan is ready for you.", "order": 12944, "speaker": "Hunter"},
  {"season": 3, "episode": 18, "scene": 41, "line": "Okay, bring it home now. And don't forget the new black man phrase I taught you.", "order": 12945, "speaker": "Darryl Philbin"},
  {"season": 3, "episode": 18, "scene": 41, "line": "Pippity poppity, give me the zoppity.", "order": 12946, "speaker": "Michael Scott"},
  {"season": 3, "episode": 18, "scene": 41, "line": "Yes sir. Remember that. I'll be right outside if you need me", "order": 12947, "speaker": "Darryl Philbin"},
  {"season": 3, "episode": 18, "scene": 41, "line": "All right.", "order": 12948, "speaker": "Michael Scott"},
  {"season": 3, "episode": 18, "scene": 42, "line": "Yeah, I taught Mike some new phrases. I want him to get the raise, I... just can't help myself.", "order": 12949, "speaker": "Darryl Philbin"},
  {"season": 3, "episode": 18, "scene": 43, "line": "[to Jim] Hey man, uh... I'm sorry. [Jim motions 'Don't worry about it'] [Roy receives his check from Angela] Thanks. [to Pam] Can I, like, see you after work for coffee, or... something?", "order": 12950, "speaker": "Roy Anderson"},
  {"season": 3, "episode": 18, "scene": 43, "line": "I don't know.", "order": 12951, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 18, "scene": 43, "line": "Please. I just got some stuff I gotta say to you. [Pam nods, meekly]", "order": 12952, "speaker": "Roy Anderson"},
  {"season": 3, "episode": 18, "scene": 43, "line": "[as Roy is walking out] Jim--- Roy--- Look out!", "order": 12953, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 18, "scene": 43, "line": "Thanks, Kev. I'm good though.", "order": 12954, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 18, "scene": 44, "line": "Thank you, Hunter. [to Michael and Toby] Hello. Come in. [exhales] Ah, Okay.", "order": 12955, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 18, "scene": 44, "line": "Who's the boy toy?", "order": 12956, "speaker": "Michael Scott"},
  {"season": 3, "episode": 18, "scene": 44, "line": "That's my new assistant.", "order": 12957, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 18, "scene": 44, "line": "Were you going to tell me that you hired James Van Der Beek?", "order": 12958, "speaker": "Michael Scott"},
  {"season": 3, "episode": 18, "scene": 44, "line": "I have to call you the second I get a new assistant?", "order": 12959, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 18, "scene": 44, "line": "Be nice to get a memo, we are lovers.", "order": 12960, "speaker": "Michael Scott"},
  {"season": 3, "episode": 18, "scene": 44, "line": "Hi, Jan.", "order": 12961, "speaker": "Toby Flenderson"},
  {"season": 3, "episode": 18, "scene": 44, "line": "Hi, Toby. [clears throat] First--- [Michael clears his throat] First off, Michael, this is a salary negotiation. All matters regarding our personal relationship have to be set aside. Are we clear?", "order": 12962, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 18, "scene": 44, "line": "Pippity poppity.", "order": 12963, "speaker": "Michael Scott"},
  {"season": 3, "episode": 18, "scene": 44, "line": "Right now we can offer you a 6% raise.", "order": 12964, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 18, "scene": 44, "line": "Six percent? After all we've been through?", "order": 12965, "speaker": "Michael Scott"},
  {"season": 3, "episode": 18, "scene": 44, "line": "Oh, God.", "order": 12966, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 18, "scene": 44, "line": "I got you... jade earrings.", "order": 12967, "speaker": "Michael Scott"},
  {"season": 3, "episode": 18, "scene": 44, "line": "Michael---", "order": 12968, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 18, "scene": 44, "line": "No!", "order": 12969, "speaker": "Michael Scott"},
  {"season": 3, "episode": 18, "scene": 44, "line": "Michael---", "order": 12970, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 18, "scene": 44, "line": "No. You gonna play it like this? You give me a good raise, or no more sex. [Toby begins to write] [to Toby] What are you writing, perv-ball?", "order": 12971, "speaker": "Michael Scott"},
  {"season": 3, "episode": 18, "scene": 44, "line": "Just preparing for the deposition.", "order": 12972, "speaker": "Toby Flenderson"},
  {"season": 3, "episode": 18, "scene": 45, "line": "This may be the first time that a male subordinate has attempted to get a modest scheduled raise by threatening to withhold sex from a female superior. It will be a groundbreaking case when it inevitably goes to trial.", "order": 12973, "speaker": "Toby Flenderson"},
  {"season": 3, "episode": 18, "scene": 46, "line": "I'm so sorry, Pammy. I really wasn't gonna do anything. But then I... kept thinkin' about you two together, and... I just thought you guys were really good friends, or... or maybe he was gay or somethin'... Not that that's wrong.", "order": 12974, "speaker": "Roy Anderson"},
  {"season": 3, "episode": 18, "scene": 46, "line": "I'm sorry too. I just, I think that we both made some bad choices.", "order": 12975, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 18, "scene": 46, "line": "So you gonna start datin' Halpert then?", "order": 12976, "speaker": "Roy Anderson"},
  {"season": 3, "episode": 18, "scene": 46, "line": "Um... no. No, he has a girlfriend.", "order": 12977, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 18, "scene": 46, "line": "Oh yeah... Wait a minute, you... broke off our wedding for the guy.", "order": 12978, "speaker": "Roy Anderson"},
  {"season": 3, "episode": 18, "scene": 46, "line": "No, there were a lot of reasons.", "order": 12979, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 18, "scene": 46, "line": "But you're not even gonna try to go out with him? [Pam meekly shakes her head] I don't get you Pam.", "order": 12980, "speaker": "Roy Anderson"},
  {"season": 3, "episode": 18, "scene": 46, "line": "I know.", "order": 12981, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 18, "scene": 47, "line": "What's this?", "order": 12982, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 18, "scene": 47, "line": "What's what?", "order": 12983, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 18, "scene": 47, "line": "Certificate of Bravery, from the Scranton Police Department. 'Recognizing outstanding citizenship from a very brave young man. Dwight K. Schrute'", "order": 12984, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 18, "scene": 47, "line": "Wow. I guess word got around. That's a nice... honor.", "order": 12985, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 18, "scene": 47, "line": "Please. They hand these out to little kids. Look. There's a teddy bear in a policeman's cap.", "order": 12986, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 18, "scene": 47, "line": "[under his breath] Didn't think you'd notice...", "order": 12987, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 18, "scene": 48, "line": "Why don't you just take that pen and stab me in the heart. This is me, Jan. This is me!", "order": 12988, "speaker": "Michael Scott"},
  {"season": 3, "episode": 18, "scene": 48, "line": "Okay, Michael. Please, why don't we just take a break. This is really going nowhere.", "order": 12989, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 18, "scene": 48, "line": "Okay, no, no, no, no. You do not try tactic number eight on me. I invented tactic number eight. I'm not going anywhere.", "order": 12990, "speaker": "Michael Scott"},
  {"season": 3, "episode": 18, "scene": 48, "line": "Ok, Toby, how about if you...", "order": 12991, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 18, "scene": 48, "line": "Sure.", "order": 12992, "speaker": "Toby Flenderson"},
  {"season": 3, "episode": 18, "scene": 48, "line": "Great.", "order": 12993, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 18, "scene": 49, "line": "What's wrong with you?", "order": 12994, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 18, "scene": 49, "line": "Ohh. It was a weird day. I accidentally cross-dressed. And then Darryl made me feel bad for not making any money. And then I had to ride up here with stupid Toby. And then, your assistant, is all young and hot. And I---", "order": 12995, "speaker": "Michael Scott"},
  {"season": 3, "episode": 18, "scene": 49, "line": "Okay, Michael. I can offer you a 12% raise, but you have got to ask for 15.", "order": 12996, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 18, "scene": 49, "line": "Well that's ridiculous I'm not gonna make---", "order": 12997, "speaker": "Michael Scott"},
  {"season": 3, "episode": 18, "scene": 49, "line": "No, just... I just need you to ask for it, so I can record that you asked for it. Okay?", "order": 12998, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 18, "scene": 49, "line": "Ah, so... All right, Levinson. Here's the rub. I would like a 15% raise.", "order": 12999, "speaker": "Michael Scott"},
  {"season": 3, "episode": 18, "scene": 49, "line": "No. But we can offer you 12.", "order": 13000, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 18, "scene": 49, "line": "But you just said 15.", "order": 13001, "speaker": "Michael Scott"},
  {"season": 3, "episode": 18, "scene": 50, "line": "Negotiation is an art. Back and forth. Give and take. And today, both Darryl and I took something. Higher salaries. Win win win. But you know, life is about more... than just salaries. It's about perks. Like having sex with Jan--", "order": 13002, "speaker": "Michael Scott"},
  {"season": 3, "episode": 18, "scene": 50, "line": "Michael!", "order": 13003, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 18, "scene": 51, "line": "So you and Bob are looking at a historical house?", "order": 13004, "speaker": "Stanley Hudson"},
  {"season": 3, "episode": 18, "scene": 51, "line": "Mm-hmm, near the river.", "order": 13005, "speaker": "Phyllis Vance"},
  {"season": 3, "episode": 18, "scene": 51, "line": "Mm, how many bedrooms?", "order": 13006, "speaker": "Stanley Hudson"},
  {"season": 3, "episode": 18, "scene": 51, "line": "Four.", "order": 13007, "speaker": "Phyllis Vance"},
  {"season": 3, "episode": 18, "scene": 52, "line": "Dwight. Dwight. I've been doing some very interesting reading.", "order": 13008, "speaker": "Angela Martin"},
  {"season": 3, "episode": 18, "scene": 52, "line": "Really?", "order": 13009, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 18, "scene": 52, "line": "Mm-hmm. Tales of bravery.", "order": 13010, "speaker": "Angela Martin"},
  {"season": 3, "episode": 18, "scene": 52, "line": "Mm, good stuff.", "order": 13011, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 18, "scene": 52, "line": "Mm-hmm. I was thinking tonight, we could... read it together.", "order": 13012, "speaker": "Angela Martin"},
  {"season": 3, "episode": 18, "scene": 52, "line": "Sounds... fun. [they kiss, Jim walks out of the bathroom and sees]", "order": 13013, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 18, "scene": 53, "line": "[scoffs] I... will never say a word. And now, we are even.", "order": 13014, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 18, "scene": 54, "line": "I graduated from anger management the same way I graduated from Cornell. On time. Now I'm back. Got a second chance, and I'm not gonna blow it. [in a forceful voice] So look out Dunder Mifflin! [laughs] [snaps] I mean, look out... in a fun way! You know, not like, I'm gonna hurt you...", "order": 13015, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 18, "scene": 55, "line": "Hey guys! Guess who's back! [Dwight pepper-spray's Andy] AHHH! [screams in pain] OH, GOD!", "order": 13016, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 18, "scene": 55, "line": "No need to thank me.", "order": 13017, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 18, "scene": 56, "line": "I am not a hero. I am a mere defender of the office. You know who's a real hero? Hiro, from", "order": 13018, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 18, "scene": 57, "line": "Look, I am not a gazillionaire. I can't... I can't dress like Donald Trump dresses. I have to stay within the parameters of my budget. I can't shop at a fancy Norwegian boutique. I buy my suits the old fashioned way. I buy them from discount stores... or on eBay. And when I found out that Ross was having a midnight madness sale, I ran, not walked, as per the instructions on the flier. And I got there, and when the doors opened, all of these women ran to this one bin. And I did too. And I grabbed this suit. And a woman, sort of a, I don't know, Missy Elliot type, grabbed the jacket. And, uh, we had kind of a heated tug of war. And I could tell by the way she was screaming that this must be a get. And I figured she was just buying it for her husband, to answer your question from before. Um, anyway, she s... socked me, in the eye. And she got it, she got the suit. But, I went to the Ross, in Trenton New Jersey, and I found the exact same suit. And I didn't have to deal with Missy Elliot.", "order": 13019, "speaker": "Michael Scott"},
  {"season": 3, "episode": 19, "scene": 1, "line": "Good morning, Pam.", "order": 13020, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 19, "scene": 1, "line": "Oh, welcome back, Andy.", "order": 13021, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 19, "scene": 1, "line": "Drew. I'm Drew now.", "order": 13022, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 19, "scene": 1, "line": "Oh. Drew. Sorry.", "order": 13023, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 19, "scene": 1, "line": "Apology not... accepted. Because it wasn't even necessary in the first place. [laughs]", "order": 13024, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 19, "scene": 2, "line": "Several weeks ago, Andy Bernard had an incident. [cut to shot of Andy punching a hole in the wall] But after five weeks in Anger Management, I'm back. And I've got a new attitude. And a new name. And... a bunch of new techniques for dealing with the grumpies.", "order": 13025, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 19, "scene": 3, "line": "Mornin' Jim.", "order": 13026, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 19, "scene": 3, "line": "Hey, Andy. How are you, man?", "order": 13027, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 19, "scene": 3, "line": "Good. Drew.", "order": 13028, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 19, "scene": 3, "line": "What's that?", "order": 13029, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 19, "scene": 3, "line": "Dr--- You can call me Drew.", "order": 13030, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 19, "scene": 3, "line": "No, I'm not gonna call you that.", "order": 13031, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 19, "scene": 3, "line": "Cool. I can't control what you do. I can only control what", "order": 13032, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 19, "scene": 3, "line": "Andy.", "order": 13033, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 19, "scene": 3, "line": "Drew. [walks to Dwight] Dwight. How's it goin' man?", "order": 13034, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 19, "scene": 4, "line": "Yes, I have decided to shun Andy Bernard for the next three years. Which I'm looking forward to. It's an Amish technique. It's like slapping someone with silence. I was shunned from the age of four until my sixth birthday... for not saving the excess oil from a can of tuna.", "order": 13035, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 19, "scene": 5, "line": "Jim, could you please inform Andy Bernard that he is being shunned.", "order": 13036, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 19, "scene": 5, "line": "Andy, Dwight says welcome back, and he could use a hug.", "order": 13037, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 19, "scene": 5, "line": "Ok, tell him that's not true.", "order": 13038, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 19, "scene": 5, "line": "Dwight says that he actually doesn't know one single fact about bear attacks.", "order": 13039, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 19, "scene": 5, "line": "You guys...", "order": 13040, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 19, "scene": 5, "line": "Ok, no. Jim, tell him that bears can climb faster than they can run. Jim! Tell him!", "order": 13041, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 19, "scene": 5, "line": "[half-heartedly] Andy! Nah, that's too far.", "order": 13042, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 19, "scene": 5, "line": "Damn you.", "order": 13043, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 19, "scene": 6, "line": "Today is Safety Training Day. Toby is leading ours upstairs. Yeauck. But, I am giving everyone a little bit of a treat. We are going to listen in on Darryl's presentation to the warehouse. And if I know Darryl, it gonna be zoppity.", "order": 13044, "speaker": "Michael Scott"},
  {"season": 3, "episode": 19, "scene": 7, "line": "Now, this is the forklift. You need--- [Michael rattles it] You need a license to operate this machine. That means the upstairs office workers", "order": 13045, "speaker": "Darryl Philbin"},
  {"season": 3, "episode": 19, "scene": 7, "line": "Hmm.", "order": 13046, "speaker": "Michael Scott"},
  {"season": 3, "episode": 19, "scene": 7, "line": "Should you drive the forklift?", "order": 13047, "speaker": "Darryl Philbin"},
  {"season": 3, "episode": 19, "scene": 7, "line": "I can, and I have.", "order": 13048, "speaker": "Michael Scott"},
  {"season": 3, "episode": 19, "scene": 7, "line": "No! No no no no no! I said", "order": 13049, "speaker": "Darryl Philbin"},
  {"season": 3, "episode": 19, "scene": 7, "line": "You're not allowed to drive the forklift.", "order": 13050, "speaker": "Lonny"},
  {"season": 3, "episode": 19, "scene": 7, "line": "It's not safe, you don't have a license.", "order": 13051, "speaker": "Darryl Philbin"},
  {"season": 3, "episode": 19, "scene": 7, "line": "Guys, I'm not the only one who's driven the forklift. [points] Pudge has driven the forklift.", "order": 13052, "speaker": "Michael Scott"},
  {"season": 3, "episode": 19, "scene": 7, "line": "Madge.", "order": 13053, "speaker": "Madge"},
  {"season": 3, "episode": 19, "scene": 7, "line": "I thought your name was Pudge?", "order": 13054, "speaker": "Michael Scott"},
  {"season": 3, "episode": 19, "scene": 7, "line": "No, it's always been Madge.", "order": 13055, "speaker": "Madge"},
  {"season": 3, "episode": 19, "scene": 7, "line": "Okay. Um, her.", "order": 13056, "speaker": "Michael Scott"},
  {"season": 3, "episode": 19, "scene": 7, "line": "Her. Yes, 'her' is qualified to work a dangerous machine. You are not. Okay?", "order": 13057, "speaker": "Darryl Philbin"},
  {"season": 3, "episode": 19, "scene": 7, "line": "Ah, fine.", "order": 13058, "speaker": "Michael Scott"},
  {"season": 3, "episode": 19, "scene": 7, "line": "Do you understand that?", "order": 13059, "speaker": "Darryl Philbin"},
  {"season": 3, "episode": 19, "scene": 7, "line": "Yeeesh.", "order": 13060, "speaker": "Michael Scott"},
  {"season": 3, "episode": 19, "scene": 8, "line": "We do safety training every year, or after an accident. ... We've never made it a full year. This particular time, I was reaching for a supply box on the top shelf, when one office worker, who shall remain nameless, kicked the ladder out from under me and yelled...", "order": 13061, "speaker": "Darryl Philbin"},
  {"season": 3, "episode": 19, "scene": 9, "line": "[barely keeping his composure] 'Hey Darryl, how's it hangin'?!' [laughs]", "order": 13062, "speaker": "Michael Scott"},
  {"season": 3, "episode": 19, "scene": 10, "line": "And I fell and busted my ankle. I'm legitimately scared for my workers.", "order": 13063, "speaker": "Darryl Philbin"},
  {"season": 3, "episode": 19, "scene": 11, "line": "The baler can flatten a car engine. It can cut off your arm and crush your entire body without skipping a beat.", "order": 13064, "speaker": "Darryl Philbin"},
  {"season": 3, "episode": 19, "scene": 11, "line": "Yeah!", "order": 13065, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 19, "scene": 11, "line": "It's on!", "order": 13066, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 19, "scene": 11, "line": "How many people a year do you think get their arms cut off in a baler?", "order": 13067, "speaker": "Darryl Philbin"},
  {"season": 3, "episode": 19, "scene": 11, "line": "Bail'er? I hardly know her.", "order": 13068, "speaker": "Michael Scott"},
  {"season": 3, "episode": 19, "scene": 11, "line": "Dammit, Michael. Pay attention, man.", "order": 13069, "speaker": "Lonny"},
  {"season": 3, "episode": 19, "scene": 11, "line": "Anybody wanna take a guess? Anybody?", "order": 13070, "speaker": "Darryl Philbin"},
  {"season": 3, "episode": 19, "scene": 11, "line": "Five bucks says it's over 50.", "order": 13071, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 19, "scene": 11, "line": "You really wanna bet?", "order": 13072, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 19, "scene": 11, "line": "Anybody?", "order": 13073, "speaker": "Darryl Philbin"},
  {"season": 3, "episode": 19, "scene": 11, "line": "Ever since March Madness ended, I am so bored.", "order": 13074, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 19, "scene": 11, "line": "How many?", "order": 13075, "speaker": "Guy"},
  {"season": 3, "episode": 19, "scene": 11, "line": "Ok, you're on.", "order": 13076, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 19, "scene": 11, "line": "Ten people, Michael. Ten people. Would you like to be one of them?", "order": 13077, "speaker": "Darryl Philbin"},
  {"season": 3, "episode": 19, "scene": 11, "line": "[mouths] Damn...", "order": 13078, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 19, "scene": 11, "line": "[in background] You have to be alert, and calm. And always careful...", "order": 13079, "speaker": "Darryl Philbin"},
  {"season": 3, "episode": 19, "scene": 11, "line": "No, don't worry about it. We'll just got double or nothin'.", "order": 13080, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 19, "scene": 11, "line": "On what?", "order": 13081, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 19, "scene": 11, "line": "I don't know, we'll figure somethin' out.", "order": 13082, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 19, "scene": 11, "line": "Nice.", "order": 13083, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 19, "scene": 11, "line": "What are you guys talkin' about?", "order": 13084, "speaker": "Oscar Martinez"},
  {"season": 3, "episode": 19, "scene": 11, "line": "These are very dangerous machines down here, and the upstairs workers,", "order": 13085, "speaker": "Darryl Philbin"},
  {"season": 3, "episode": 19, "scene": 11, "line": "Yes, yes. But it wouldn't be the worst thing in the world... if somebody...", "order": 13086, "speaker": "Michael Scott"},
  {"season": 3, "episode": 19, "scene": 11, "line": "It would be the worst thing in the world! It would! Very much so.", "order": 13087, "speaker": "Darryl Philbin"},
  {"season": 3, "episode": 19, "scene": 11, "line": "What the", "order": 13088, "speaker": "Lonny"},
  {"season": 3, "episode": 19, "scene": 11, "line": "It's a big red trash compactor!", "order": 13089, "speaker": "Michael Scott"},
  {"season": 3, "episode": 19, "scene": 11, "line": "What are you---", "order": 13090, "speaker": "Lonny"},
  {"season": 3, "episode": 19, "scene": 11, "line": "It's not a trash compactor! It's a baler!", "order": 13091, "speaker": "Darryl Philbin"},
  {"season": 3, "episode": 19, "scene": 11, "line": "Don't disrespect the baler!", "order": 13092, "speaker": "Lonny"},
  {"season": 3, "episode": 19, "scene": 11, "line": "Okay, okay! I got it. I got it. ... Only on the rarest of occasions...", "order": 13093, "speaker": "Michael Scott"},
  {"season": 3, "episode": 19, "scene": 11, "line": "No do not touch it!", "order": 13094, "speaker": "Darryl Philbin"},
  {"season": 3, "episode": 19, "scene": 11, "line": "...would I go near---", "order": 13095, "speaker": "Michael Scott"},
  {"season": 3, "episode": 19, "scene": 11, "line": "There is no occasion for you to go near this stuff, okay?", "order": 13096, "speaker": "Darryl Philbin"},
  {"season": 3, "episode": 19, "scene": 12, "line": "Toby now has the floor... and he is going to try not to screw this up, like everything else in his life. Let me rephrase that. I believe that you can do safety training and make it sound just as good as Darryl. Here we go!", "order": 13097, "speaker": "Michael Scott"},
  {"season": 3, "episode": 19, "scene": 12, "line": "Ok, um, one thing that you're gonna want to look out for is carpal tunnel syndrome. It's recommended that you take a ten minute break from typing every hour. For your circulation, you're gonna want to get up out of your chairs and uh, and move around about ten minutes every hour.", "order": 13098, "speaker": "Toby Flenderson"},
  {"season": 3, "episode": 19, "scene": 12, "line": "Yes, good. Fine. Like stretching and...", "order": 13099, "speaker": "Michael Scott"},
  {"season": 3, "episode": 19, "scene": 12, "line": "Um, yeah. You're computer screen can be a big strain on your eyes, so uh, it's also recommended that you step away for about... about ten minutes every hour.", "order": 13100, "speaker": "Toby Flenderson"},
  {"season": 3, "episode": 19, "scene": 12, "line": "Wow, that is... that time really adds up. That's like... a half an hour, every hour?", "order": 13101, "speaker": "Michael Scott"},
  {"season": 3, "episode": 19, "scene": 12, "line": "Take them at the same time.", "order": 13102, "speaker": "Darryl Philbin"},
  {"season": 3, "episode": 19, "scene": 12, "line": "Ok, you know what? You're making it sound kind of lame. So, skip ahead to the really dangerous stuff. Like sometimes computers can explode, can they not?", "order": 13103, "speaker": "Michael Scott"},
  {"season": 3, "episode": 19, "scene": 12, "line": "No, no. Um, you always want to keep a sweater or cardigan of some sort, in case it gets drafty.", "order": 13104, "speaker": "Toby Flenderson"},
  {"season": 3, "episode": 19, "scene": 12, "line": "What about a long sleeve T?", "order": 13105, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 19, "scene": 12, "line": "Well, that'll work.", "order": 13106, "speaker": "Toby Flenderson"},
  {"season": 3, "episode": 19, "scene": 12, "line": "Long johns? A shaw?", "order": 13107, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 19, "scene": 12, "line": "You know, anything that warms you.", "order": 13108, "speaker": "Toby Flenderson"},
  {"season": 3, "episode": 19, "scene": 12, "line": "Ok, you know what? I think that everybody is going to vomit due to boredom. [to warehouse guys] Sorry, he is very lame. [takes book from Toby] Um, let's see. 'Seasonal affective disorder! A depression that includes weight gain, fatigue, irritability, brought on by the low light of winter.'", "order": 13109, "speaker": "Michael Scott"},
  {"season": 3, "episode": 19, "scene": 12, "line": "Thank God we only had a baler to deal with.", "order": 13110, "speaker": "Darryl Philbin"},
  {"season": 3, "episode": 19, "scene": 12, "line": "Yeah, that dim light is a bitch, ain't it?", "order": 13111, "speaker": "Lonny"},
  {"season": 3, "episode": 19, "scene": 12, "line": "Ok guys, you know what? I didn't--- I didn't interrupt when you were having your presentation.", "order": 13112, "speaker": "Michael Scott"},
  {"season": 3, "episode": 19, "scene": 12, "line": "Actually, you did.", "order": 13113, "speaker": "Darryl Philbin"},
  {"season": 3, "episode": 19, "scene": 12, "line": "Yes. Okay, let's do another one. This is a good one. 'A particular concern for office workers is a sedimentary life style, which can contribute---'", "order": 13114, "speaker": "Michael Scott"},
  {"season": 3, "episode": 19, "scene": 12, "line": "Sedentary.", "order": 13115, "speaker": "Toby Flenderson"},
  {"season": 3, "episode": 19, "scene": 12, "line": "Yes. 'Which can contribute to heart disease.' Heart disease kills more people that balers.", "order": 13116, "speaker": "Michael Scott"},
  {"season": 3, "episode": 19, "scene": 12, "line": "That's called having a fat butt, Michael.", "order": 13117, "speaker": "Lonny"},
  {"season": 3, "episode": 19, "scene": 12, "line": "Mmmm, no, no, it's... sedentary...", "order": 13118, "speaker": "Michael Scott"},
  {"season": 3, "episode": 19, "scene": 12, "line": "Yeah, yeah. That's, that's fat butt disease. That's what you suffer from?", "order": 13119, "speaker": "Lonny"},
  {"season": 3, "episode": 19, "scene": 12, "line": "No.", "order": 13120, "speaker": "Michael Scott"},
  {"season": 3, "episode": 19, "scene": 12, "line": "Fat butt disease, Michael?", "order": 13121, "speaker": "Lonny"},
  {"season": 3, "episode": 19, "scene": 12, "line": "Excuse me, sea monster, you weigh like a thousand pounds.", "order": 13122, "speaker": "Kelly Kapoor"},
  {"season": 3, "episode": 19, "scene": 12, "line": "Yeah? I bet you'd like to swim with this sea monster, wouldn't you?", "order": 13123, "speaker": "Lonny"},
  {"season": 3, "episode": 19, "scene": 12, "line": "Ryan?", "order": 13124, "speaker": "Kelly Kapoor"},
  {"season": 3, "episode": 19, "scene": 12, "line": "Dude, tell your girl to shut up.", "order": 13125, "speaker": "Lonny"},
  {"season": 3, "episode": 19, "scene": 12, "line": "What?!", "order": 13126, "speaker": "Kelly Kapoor"},
  {"season": 3, "episode": 19, "scene": 12, "line": "Kelly, you insulted the gentleman. Please apologize.", "order": 13127, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 19, "scene": 12, "line": "Are you kidding me?", "order": 13128, "speaker": "Kelly Kapoor"},
  {"season": 3, "episode": 19, "scene": 12, "line": "Alright, we outta here.", "order": 13129, "speaker": "Darryl Philbin"},
  {"season": 3, "episode": 19, "scene": 12, "line": "Darryl, I did not walk out in the middle of yours. So, I---", "order": 13130, "speaker": "Michael Scott"},
  {"season": 3, "episode": 19, "scene": 12, "line": "Yeah, but ours was real, Michael.", "order": 13131, "speaker": "Lonny"},
  {"season": 3, "episode": 19, "scene": 12, "line": "That's what I've been trying to tell you, Mike. It's serious down there. We do dangerous stuff, man. This is shenanigans, foolishness, Nerf-ball. You live a sweet, little, Nerf-y life. Sittin' on your biscuit. Never havin' to risk it.", "order": 13132, "speaker": "Darryl Philbin"},
  {"season": 3, "episode": 19, "scene": 12, "line": "Okay. ... What, Nerf isn't cool anymore?", "order": 13133, "speaker": "Michael Scott"},
  {"season": 3, "episode": 19, "scene": 13, "line": "Darryl thinks he is such a man because he works in a warehouse. I worked in a warehouse. Men's Warehouse. I was a greeter. I'd like to see Darryl greet people. Probably make 'em feel like wimps. Not me, I... 'Hello, I'm Michael. Welcome to Men's Warehouse. We have a special on khaki pants today.' ... This is one example.", "order": 13134, "speaker": "Michael Scott"},
  {"season": 3, "episode": 19, "scene": 14, "line": "[Pam is holding a jar of jellybeans] Ten.", "order": 13135, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 19, "scene": 14, "line": "Really, ten? That's your guess? You're a professional accountant.", "order": 13136, "speaker": "Oscar Martinez"},
  {"season": 3, "episode": 19, "scene": 14, "line": "There's like ten green ones.", "order": 13137, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 19, "scene": 14, "line": "Forty-two.", "order": 13138, "speaker": "Oscar Martinez"},
  {"season": 3, "episode": 19, "scene": 14, "line": "I'm gonna say fifty.", "order": 13139, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 19, "scene": 14, "line": "Fifty-one.", "order": 13140, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 19, "scene": 14, "line": "Oh, don't be that person.", "order": 13141, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 19, "scene": 14, "line": "That is lame.", "order": 13142, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 19, "scene": 14, "line": "It's a strategy!", "order": 13143, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 19, "scene": 14, "line": "It's called being smart.", "order": 13144, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 19, "scene": 14, "line": "Thank you.", "order": 13145, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 19, "scene": 14, "line": "Oh, geeze.", "order": 13146, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 19, "scene": 15, "line": "I don't know how the whole betting thing started, but it's fun.", "order": 13147, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 19, "scene": 16, "line": "Ten...", "order": 13148, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 19, "scene": 16, "line": "Kev's out.", "order": 13149, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 19, "scene": 16, "line": "Damn it.", "order": 13150, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 19, "scene": 16, "line": "47, 48, 49! Jim wins!", "order": 13151, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 19, "scene": 16, "line": "Oooh! [Jim claps]", "order": 13152, "speaker": "Everyone"},
  {"season": 3, "episode": 19, "scene": 16, "line": "That is not fair. He has spent hours up here at reception with you.", "order": 13153, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 19, "scene": 16, "line": "Okay, okay.", "order": 13154, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 19, "scene": 16, "line": "No, constantly. Like, for", "order": 13155, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 19, "scene": 16, "line": "Okay.", "order": 13156, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 19, "scene": 17, "line": "Pam, depression is as scary as a baler, right?", "order": 13157, "speaker": "Michael Scott"},
  {"season": 3, "episode": 19, "scene": 17, "line": "I don't understand the question.", "order": 13158, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 19, "scene": 17, "line": "Working in an office can lead to depression. Which can lead to suicide. I mean this is really serious stuff.", "order": 13159, "speaker": "Michael Scott"},
  {"season": 3, "episode": 19, "scene": 17, "line": "Yeah...", "order": 13160, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 19, "scene": 17, "line": "I--- I--- Nobody commits suicide because they work with a baler, and yet those guys are makin' fun of me, calling me a Nerf, that...", "order": 13161, "speaker": "Michael Scott"},
  {"season": 3, "episode": 19, "scene": 17, "line": "It's really hard to demonstrate depression. Their safety training had visuals.", "order": 13162, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 19, "scene": 17, "line": "Yeah... you are... ah, so right. They had visual aids. And all we had were the facts. You don't go to the science museum and get handed a pamphlet on electricity. You go to the science museum and you put your hand on a metal ball, your hair sticks up straight... and you know science.", "order": 13163, "speaker": "Michael Scott"},
  {"season": 3, "episode": 19, "scene": 17, "line": "So, you're okay?", "order": 13164, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 19, "scene": 17, "line": "Indubitably.", "order": 13165, "speaker": "Michael Scott"},
  {"season": 3, "episode": 19, "scene": 18, "line": "They use props. They use visual aids, and they just made us look like dopes.", "order": 13166, "speaker": "Michael Scott"},
  {"season": 3, "episode": 19, "scene": 18, "line": "Idiots! God, what are we gonna do!?", "order": 13167, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 19, "scene": 18, "line": "I don't know, I don't know. Because you know what our killer is? Depression---", "order": 13168, "speaker": "Michael Scott"},
  {"season": 3, "episode": 19, "scene": 18, "line": "Wolves.", "order": 13169, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 19, "scene": 18, "line": "Nn--- Depression.", "order": 13170, "speaker": "Michael Scott"},
  {"season": 3, "episode": 19, "scene": 19, "line": "Visual aids.", "order": 13171, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 19, "scene": 19, "line": "Yes.", "order": 13172, "speaker": "Michael Scott"},
  {"season": 3, "episode": 19, "scene": 19, "line": "A quilt. Depression quilt?", "order": 13173, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 19, "scene": 19, "line": "No time to sew a quilt. ... I got it. Give me the number for the Giant Big Box Toy Store.", "order": 13174, "speaker": "Michael Scott"},
  {"season": 3, "episode": 19, "scene": 20, "line": "You may be asking yourself, 'What am I doing on a trampoline?' Well, I thought I'd bounce here for a while, relieve some stress, and then move on with my day. Not! Here's the plan. Dwight, is going to gather all of the office workers and all of the warehouse guys, we're going to have another safety seminar. Only this time, where's Michael? Oh my God! He is on the roof! Now I have got their attention. I... tell them, about the cold hard facts of depression. And then I say, 'Hey! You ever seen a suicide?' And I jump. And they freak out. And they get to see... the dangers of depression with their own eyes. Nice side note: They might think 'Hey, I should have been nicer to Michael.' But that's... not why I'm doing this... Then, I land on the trampoline, take a couple extra bounces for fun. I climb off, walk around the corner... Ta-freakin'-da! [Dwight nods]", "order": 13175, "speaker": "Michael Scott"},
  {"season": 3, "episode": 19, "scene": 21, "line": "So then the next movie moves to the top of the queue. So number five becomes number four, number six becomes number five, [Ryan checks his watch]number three becomes number two, etc, etc. And let's just say that I just sent back", "order": 13176, "speaker": "Kelly Kapoor"},
  {"season": 3, "episode": 19, "scene": 21, "line": "I guess I forgot. [kisses Kelly, gathers up all the money]", "order": 13177, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 19, "scene": 21, "line": "You're such a ditz.", "order": 13178, "speaker": "Kelly Kapoor"},
  {"season": 3, "episode": 19, "scene": 21, "line": "Ryan, well done. Two minutes, forty-two seconds. Additionally, Pam, you win ten because she said 'awesome' 12 times, and Jim, you win five because she mentioned six romantic comedies.", "order": 13179, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 19, "scene": 22, "line": "[on the roof] Okay, let's do this thing! I'll go summon the troops!", "order": 13180, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 19, "scene": 22, "line": "Maybe we should test it first. Letterman-style. Throw a TV over, or...", "order": 13181, "speaker": "Michael Scott"},
  {"season": 3, "episode": 19, "scene": 22, "line": "We measured it once...", "order": 13182, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 19, "scene": 22, "line": "Go buy some watermelons.", "order": 13183, "speaker": "Michael Scott"},
  {"season": 3, "episode": 19, "scene": 22, "line": "Seedless?", "order": 13184, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 19, "scene": 22, "line": "Just...", "order": 13185, "speaker": "Michael Scott"},
  {"season": 3, "episode": 19, "scene": 23, "line": "[Creed takes a bite of an apple] Creed is eating an apple. I found a potato.", "order": 13186, "speaker": "Toby Flenderson"},
  {"season": 3, "episode": 19, "scene": 23, "line": "Hey Creed.", "order": 13187, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 19, "scene": 23, "line": "Hey!", "order": 13188, "speaker": "Creed Bratton"},
  {"season": 3, "episode": 19, "scene": 23, "line": "Hey.", "order": 13189, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 19, "scene": 23, "line": "Hello. [Jim replaces Creed's apple with a potato][Creed takes a bite of the potato]", "order": 13190, "speaker": "Creed Bratton"},
  {"season": 3, "episode": 19, "scene": 23, "line": "Yes!", "order": 13191, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 19, "scene": 23, "line": "Here you go. [hands money]", "order": 13192, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 19, "scene": 23, "line": "Nice.", "order": 13193, "speaker": "Toby Flenderson"},
  {"season": 3, "episode": 19, "scene": 24, "line": "I don't know this place as well as I thought I did. I'm getting cleaned out.", "order": 13194, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 19, "scene": 25, "line": "Ready?", "order": 13195, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 19, "scene": 25, "line": "Let's do it! Drop that sucker.", "order": 13196, "speaker": "Michael Scott"},
  {"season": 3, "episode": 19, "scene": 25, "line": "[drops watermelon, watermelon bounces off trampoline, onto a car, bursts, car alarm sounds]", "order": 13197, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 19, "scene": 25, "line": "BINGO! WHOA WHOA WHOA! Oh... crap. Deactivate the car alarm. Clean up the mess.", "order": 13198, "speaker": "Michael Scott"},
  {"season": 3, "episode": 19, "scene": 25, "line": "Okay.", "order": 13199, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 19, "scene": 25, "line": "Find out whose car that is. If it's Stanley's, call the offices of James P. Albini, see if he handles hate crimes.", "order": 13200, "speaker": "Michael Scott"},
  {"season": 3, "episode": 19, "scene": 25, "line": "Got it.", "order": 13201, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 19, "scene": 25, "line": "Also, take apart the trampoline, stick it in the baler.", "order": 13202, "speaker": "Michael Scott"},
  {"season": 3, "episode": 19, "scene": 25, "line": "We're not allowed to use the baler.", "order": 13203, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 19, "scene": 25, "line": "Have Pa--adge do it, or... the sea monster.", "order": 13204, "speaker": "Michael Scott"},
  {"season": 3, "episode": 19, "scene": 25, "line": "I'm on it!", "order": 13205, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 19, "scene": 26, "line": "I'm temporarily lifting the shun.", "order": 13206, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 19, "scene": 26, "line": "Thank you.", "order": 13207, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 19, "scene": 26, "line": "It means nothing. I need you to do something for me.", "order": 13208, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 19, "scene": 26, "line": "Anything.", "order": 13209, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 19, "scene": 26, "line": "Okay, calm down. I need you to acquire an inflatable house, and/or castle.", "order": 13210, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 19, "scene": 26, "line": "You mean a moon bounce.", "order": 13211, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 19, "scene": 26, "line": "What do you think? You've got an hour.", "order": 13212, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 19, "scene": 26, "line": "I'm gonna need... I'm gonna need petty cash.", "order": 13213, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 19, "scene": 26, "line": "Shunning resumed.", "order": 13214, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 19, "scene": 26, "line": "Do you, do you want a drawbridge?", "order": 13215, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 19, "scene": 26, "line": "Un-shun. Yeah that sounds good. Re-shun.", "order": 13216, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 19, "scene": 27, "line": "Oh yeah, this is much better. Safer. Excellent decision.", "order": 13217, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 19, "scene": 27, "line": "Yes, thank you for seeing that.", "order": 13218, "speaker": "Michael Scott"},
  {"season": 3, "episode": 19, "scene": 27, "line": "When you land, try and land like an eight year-old. These bouncy castles are not designed for adults.", "order": 13219, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 19, "scene": 27, "line": "I don't know if I wanna do this.", "order": 13220, "speaker": "Michael Scott"},
  {"season": 3, "episode": 19, "scene": 27, "line": "Do you wanna do another test? I got plenty of watermelons in my trunk!", "order": 13221, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 19, "scene": 27, "line": "No. No more. The tests are going terrible. If we keep doing it, we're not gonna want to jump. This is about doing, not thinking.", "order": 13222, "speaker": "Michael Scott"},
  {"season": 3, "episode": 19, "scene": 27, "line": "That's right! Doing! Totally doing! It's rock n' roll!", "order": 13223, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 19, "scene": 27, "line": "Rock n' roll!", "order": 13224, "speaker": "Michael Scott"},
  {"season": 3, "episode": 19, "scene": 27, "line": "Yeah!", "order": 13225, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 19, "scene": 27, "line": "That's right! I am not thinking.", "order": 13226, "speaker": "Michael Scott"},
  {"season": 3, "episode": 19, "scene": 27, "line": "[imitating the sound and playing an air guitar] Near near near near near!", "order": 13227, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 19, "scene": 27, "line": "Yes! Yeah!", "order": 13228, "speaker": "Michael Scott"},
  {"season": 3, "episode": 19, "scene": 27, "line": "[singing] Michael is awesome! Jumpin' off the roof!", "order": 13229, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 19, "scene": 27, "line": "Woo!", "order": 13230, "speaker": "Michael Scott"},
  {"season": 3, "episode": 19, "scene": 27, "line": "[singing] Bouncin' on the bouncy bounce! Show 'em who's boss!", "order": 13231, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 19, "scene": 27, "line": "Woo!", "order": 13232, "speaker": "Michael Scott"},
  {"season": 3, "episode": 19, "scene": 27, "line": "[singing] Rip a hole in the suuuuuuun!", "order": 13233, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 19, "scene": 27, "line": "I am ready to do this! I am ready to make a point! [Dwight continues air guitar]", "order": 13234, "speaker": "Michael Scott"},
  {"season": 3, "episode": 19, "scene": 28, "line": "[out of breath] Guys! Listen up! Michael is up on the roof, and acting strange!", "order": 13235, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 19, "scene": 28, "line": "Whoa! What's the situation?", "order": 13236, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 19, "scene": 28, "line": "[hesitates] Un-shun. I think he's suffering from depression. Re-shun.", "order": 13237, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 19, "scene": 28, "line": "Ok, when's the shunning thing gonna end?", "order": 13238, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 19, "scene": 28, "line": "Un-shun. Never. Re-shun. I think he wants you all to come out to the parking lot and watch him die!", "order": 13239, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 19, "scene": 28, "line": "Is it nice outside?", "order": 13240, "speaker": "Stanley Hudson"},
  {"season": 3, "episode": 19, "scene": 28, "line": "It's gorgeous. Let's go!", "order": 13241, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 19, "scene": 28, "line": "Do I need my jacket?", "order": 13242, "speaker": "Stanley Hudson"},
  {"season": 3, "episode": 19, "scene": 28, "line": "No really, it's, it's very nice. Come on!", "order": 13243, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 19, "scene": 28, "line": "Will I be too warm in a long sleeve T?", "order": 13244, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 19, "scene": 28, "line": "Everybody's gonna be fine in exactly what they're wearing, let's go! Let's go!", "order": 13245, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 19, "scene": 29, "line": "[outside] Come on, hurry up you guys!", "order": 13246, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 19, "scene": 29, "line": "[on the roof] My life! Oh, my life...", "order": 13247, "speaker": "Michael Scott"},
  {"season": 3, "episode": 19, "scene": 29, "line": "[on megaphone] Michael, what's wrong?!", "order": 13248, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 19, "scene": 29, "line": "Everything's wrong. The stress of my modern office, has caused me to go into a depression.", "order": 13249, "speaker": "Michael Scott"},
  {"season": 3, "episode": 19, "scene": 29, "line": "Depression? Isn't that just a fancy word for feeling bummed out?", "order": 13250, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 19, "scene": 29, "line": "Dwight, you ignorant slut. Depression is a very serious illness. Over 32 thousand people commit suicide every year! According to a 2004 study!", "order": 13251, "speaker": "Michael Scott"},
  {"season": 3, "episode": 19, "scene": 29, "line": "Is that the last year the data was available?", "order": 13252, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 19, "scene": 29, "line": "Yes! My head is in such pain! And turmoil!", "order": 13253, "speaker": "Michael Scott"},
  {"season": 3, "episode": 19, "scene": 29, "line": "Don't do anything rash!", "order": 13254, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 19, "scene": 29, "line": "Wait, where are the warehouse guys?", "order": 13255, "speaker": "Michael Scott"},
  {"season": 3, "episode": 19, "scene": 29, "line": "I didn't... [Dwight runs up to the side of the building] I didn't think you needed them for this part.", "order": 13256, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 19, "scene": 29, "line": "Okay... that's...", "order": 13257, "speaker": "Michael Scott"},
  {"season": 3, "episode": 19, "scene": 29, "line": "you said to just...", "order": 13258, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 19, "scene": 29, "line": "That's the whole point, dummy.", "order": 13259, "speaker": "Michael Scott"},
  {"season": 3, "episode": 19, "scene": 29, "line": "Okay, I'm on it!", "order": 13260, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 19, "scene": 29, "line": "Okay.", "order": 13261, "speaker": "Michael Scott"},
  {"season": 3, "episode": 19, "scene": 30, "line": "Attention blue collar workers!", "order": 13262, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 19, "scene": 31, "line": "What are the odds that this is in any way real?", "order": 13263, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 19, "scene": 31, "line": "I'd say like... 10,000 to 1?", "order": 13264, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 19, "scene": 31, "line": "Okay, I'd like ten bucks on those odds.", "order": 13265, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 19, "scene": 32, "line": "If someone gives you 10,000 to 1 on anything, you take it. If John Mellencamp ever wins an Oscar, I am going to be a", "order": 13266, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 19, "scene": 33, "line": "Michael's up on the roof and he's acting strange!", "order": 13267, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 19, "scene": 33, "line": "Oooooh, my life!", "order": 13268, "speaker": "Michael Scott"},
  {"season": 3, "episode": 19, "scene": 33, "line": "Michael! What's wrong?", "order": 13269, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 19, "scene": 33, "line": "Everything is wrong, Dwight. The stress of my modern office, has made me depressed.", "order": 13270, "speaker": "Michael Scott"},
  {"season": 3, "episode": 19, "scene": 33, "line": "Depressed? Isn't that just a fancy word for feeling bummed out?", "order": 13271, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 19, "scene": 33, "line": "Dwight, you ignorant slut.", "order": 13272, "speaker": "Michael Scott"},
  {"season": 3, "episode": 19, "scene": 34, "line": "Well, you know, the first performance was a little off. But I really think they hit their stride, in the second show. Um, might even bring my parents tomorrow, to the matinee.", "order": 13273, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 19, "scene": 34, "line": "And that is why, I am going to jump off this roof!", "order": 13274, "speaker": "Michael Scott"},
  {"season": 3, "episode": 19, "scene": 34, "line": "Oh, excuse me. It's my... favorite part.", "order": 13275, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 19, "scene": 35, "line": "This is just offensive.", "order": 13276, "speaker": "Angela Martin"},
  {"season": 3, "episode": 19, "scene": 35, "line": "At least we're outside.", "order": 13277, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 19, "scene": 36, "line": "[zipping up his pants] Hey, check it out, there's a... there's a castle over there.", "order": 13278, "speaker": "Creed Bratton"},
  {"season": 3, "episode": 19, "scene": 36, "line": "Oh my God, there", "order": 13279, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 19, "scene": 36, "line": "No, there's nothing to see over there, people! There's nothing to see. ...They found the castle, Michael.", "order": 13280, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 19, "scene": 36, "line": "Damn it.", "order": 13281, "speaker": "Michael Scott"},
  {"season": 3, "episode": 19, "scene": 37, "line": "Oh... God. Oh my God, he's gonna jump.", "order": 13282, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 19, "scene": 37, "line": "Oh. He's going to kill himself, pretending to kill himself.", "order": 13283, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 19, "scene": 37, "line": "Yeah...", "order": 13284, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 19, "scene": 38, "line": "Hey uh, Michael. Don't jump on the bouncy castle. You can't do that, because you're going to get horribly, horribly injured.", "order": 13285, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 19, "scene": 38, "line": "Hey Michael! I have a present for you, but you have to come down and get it.", "order": 13286, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 19, "scene": 38, "line": "What is it?", "order": 13287, "speaker": "Michael Scott"},
  {"season": 3, "episode": 19, "scene": 38, "line": "Come down and... open it and you'll see.", "order": 13288, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 19, "scene": 38, "line": "Dwight, find out what the present is.", "order": 13289, "speaker": "Michael Scott"},
  {"season": 3, "episode": 19, "scene": 38, "line": "Okay, uh... I don't see anything. She might be bluffing.", "order": 13290, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 19, "scene": 38, "line": "Dwight...", "order": 13291, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 19, "scene": 38, "line": "Dwight, what are you---", "order": 13292, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 19, "scene": 38, "line": "Oh... It's uh, a Repliee Q1 Expo female robot, they're only available in Japan.", "order": 13293, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 19, "scene": 38, "line": "Dwight, you are such a liar. Pam, really, what is it?", "order": 13294, "speaker": "Michael Scott"},
  {"season": 3, "episode": 19, "scene": 38, "line": "Mike, this is the opposite of safety. You jump, you're gonna serious hurt yourself.", "order": 13295, "speaker": "Darryl Philbin"},
  {"season": 3, "episode": 19, "scene": 38, "line": "You told me, that I lead a... cushy, wimpy, Nerf life.", "order": 13296, "speaker": "Michael Scott"},
  {"season": 3, "episode": 19, "scene": 38, "line": "Yeah, but I never said you had nothing to live for.", "order": 13297, "speaker": "Darryl Philbin"},
  {"season": 3, "episode": 19, "scene": 38, "line": "What do I have to live for?", "order": 13298, "speaker": "Michael Scott"},
  {"season": 3, "episode": 19, "scene": 38, "line": "A lot... of things. Uh, you, uh... What about Jan? Lovely, lovely, lovely Jan, man. It's goin' good, right?", "order": 13299, "speaker": "Darryl Philbin"},
  {"season": 3, "episode": 19, "scene": 38, "line": "It's complicated with Jan. And I don't know where I stand, or what I want. The sex isn't nearly as good as it used to be.", "order": 13300, "speaker": "Michael Scott"},
  {"season": 3, "episode": 19, "scene": 38, "line": "Mike, you're a very brave man. I mean, it takes courage just to", "order": 13301, "speaker": "Darryl Philbin"},
  {"season": 3, "episode": 19, "scene": 38, "line": "Do you really mean that?", "order": 13302, "speaker": "Michael Scott"},
  {"season": 3, "episode": 19, "scene": 38, "line": "I couldn't do it. I--- I ain't that strong. And I ain't that brave.", "order": 13303, "speaker": "Darryl Philbin"},
  {"season": 3, "episode": 19, "scene": 38, "line": "I'm braver than you?", "order": 13304, "speaker": "Michael Scott"},
  {"season": 3, "episode": 19, "scene": 38, "line": "Way", "order": 13305, "speaker": "Darryl Philbin"},
  {"season": 3, "episode": 19, "scene": 38, "line": "I Braveheart. I am.", "order": 13306, "speaker": "Michael Scott"},
  {"season": 3, "episode": 19, "scene": 38, "line": "Come down, okay?", "order": 13307, "speaker": "Darryl Philbin"},
  {"season": 3, "episode": 19, "scene": 38, "line": "Okay. Pam, I'm coming down to get my present.", "order": 13308, "speaker": "Michael Scott"},
  {"season": 3, "episode": 19, "scene": 39, "line": "An office... is as safe as the people in it. And sometimes those people can drive you to do crazy things to show the dangers of the office. That's the danger I found myself in today. I saved a life. My own. Am I a hero? I really can't say. But, yes.", "order": 13309, "speaker": "Michael Scott"},
  {"season": 3, "episode": 19, "scene": 40, "line": "When people think of Andy, they think of hate and anger. When people think of Drew, they think of baked goods.", "order": 13310, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 19, "scene": 40, "line": "Oh my God, these cookies have no flavor. How is that possible?", "order": 13311, "speaker": "Kelly Kapoor"},
  {"season": 3, "episode": 19, "scene": 40, "line": "They are the worst. Who's this Drew chick?", "order": 13312, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 19, "scene": 40, "line": "Drew isn't a girl... She's Andy.", "order": 13313, "speaker": "Phyllis Vance"},
  {"season": 3, "episode": 19, "scene": 40, "line": "Oh no, nobody likes my cookies. I'm so angry, argh I want to hit something. [laughs] Totally kidin' guys.", "order": 13314, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 19, "scene": 41, "line": "Little device I learned to diffuse tension, be the first to make fun of yourself, because if they make fun of you before you do... Then you might lose your mind.", "order": 13315, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 19, "scene": 42, "line": "[pours the last of the coffee into his mug]", "order": 13316, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 19, "scene": 42, "line": "Ahhh... Is that the last of it?", "order": 13317, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 19, "scene": 42, "line": "I'm sorry.", "order": 13318, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 19, "scene": 42, "line": "It's OK. I can't always have what I want, but I can always want what I have.", "order": 13319, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 19, "scene": 42, "line": "Well, would you like a cup of no coffee? 'Cause that's what we have.", "order": 13320, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 19, "scene": 42, "line": "Is it fresh? [winks]", "order": 13321, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 19, "scene": 42, "line": "Are you going to beat me up?", "order": 13322, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 19, "scene": 42, "line": "Umm... [draws back arm] No. [laughs]", "order": 13323, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 19, "scene": 43, "line": "People don't need to be afraid of me. I can't achieve anger any more, and I have a new car. Toyota Prius, because Andy didn't care about fuel efficiency, but Drew has seen 'An Inconvenient Truth'. Nearly twice.", "order": 13324, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 19, "scene": 44, "line": "[walks around Andy's new car keying it]", "order": 13325, "speaker": "Angela Martin"},
  {"season": 3, "episode": 19, "scene": 45, "line": "OK, come on everybody, go time. Pam let's go.", "order": 13326, "speaker": "Michael Scott"},
  {"season": 3, "episode": 19, "scene": 45, "line": "I thought I'd stay and answer the phones.", "order": 13327, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 19, "scene": 45, "line": "Don't worry Pam, you will be answering phones for the rest of your life... Your long lovely life. [whispers to camera] saved it.", "order": 13328, "speaker": "Michael Scott"},
  {"season": 3, "episode": 19, "scene": 46, "line": "Here we go.", "order": 13329, "speaker": "Michael Scott"},
  {"season": 3, "episode": 19, "scene": 46, "line": "Come on.", "order": 13330, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 19, "scene": 46, "line": "Field Trip.", "order": 13331, "speaker": "Michael Scott"},
  {"season": 3, "episode": 19, "scene": 46, "line": "Step lively. Single file everybody.", "order": 13332, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 19, "scene": 46, "line": "Like we're going to the science museum.", "order": 13333, "speaker": "Michael Scott"},
  {"season": 3, "episode": 19, "scene": 46, "line": "Let's single...", "order": 13334, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 19, "scene": 46, "line": "Learn by touching.", "order": 13335, "speaker": "Michael Scott"},
  {"season": 3, "episode": 19, "scene": 46, "line": "Single... OK.", "order": 13336, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 19, "scene": 46, "line": "I've been told that I'm a tactile learner.", "order": 13337, "speaker": "Michael Scott"},
  {"season": 3, "episode": 19, "scene": 46, "line": "Buddy system, everyone buddy up, find a buddy.", "order": 13338, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 19, "scene": 47, "line": "You should work out Ryan, you would look so hot.", "order": 13339, "speaker": "Kelly Kapoor"},
  {"season": 3, "episode": 19, "scene": 47, "line": "Totally, we should both work out.", "order": 13340, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 19, "scene": 47, "line": "Screw you.", "order": 13341, "speaker": "Kelly Kapoor"},
  {"season": 3, "episode": 19, "scene": 47, "line": "Kelly, Ryan looks great. You should be thankful that you...", "order": 13342, "speaker": "Michael Scott"},
  {"season": 3, "episode": 19, "scene": 47, "line": "Sir, could you please, please focus on Darryl?", "order": 13343, "speaker": "Larry"},
  {"season": 3, "episode": 19, "scene": 47, "line": "I...", "order": 13344, "speaker": "Michael Scott"},
  {"season": 3, "episode": 19, "scene": 47, "line": "Try.", "order": 13345, "speaker": "Larry"},
  {"season": 3, "episode": 19, "scene": 47, "line": "Summing up.", "order": 13346, "speaker": "Darryl Philbin"},
  {"season": 3, "episode": 19, "scene": 47, "line": "Sum it up Darryl, sum it up.", "order": 13347, "speaker": "Michael Scott"},
  {"season": 3, "episode": 19, "scene": 48, "line": "We got beat today, Dwight.", "order": 13348, "speaker": "Michael Scott"},
  {"season": 3, "episode": 19, "scene": 48, "line": "[sighs]", "order": 13349, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 19, "scene": 48, "line": "But it was not a fair fight.", "order": 13350, "speaker": "Michael Scott"},
  {"season": 3, "episode": 19, "scene": 48, "line": "No it was not. Remind me again, what was the fight exactly, who was it between, and what was the outcome?", "order": 13351, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 19, "scene": 48, "line": "It was the warehouse guys...", "order": 13352, "speaker": "Michael Scott"},
  {"season": 3, "episode": 19, "scene": 48, "line": "Right.", "order": 13353, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 19, "scene": 48, "line": "... Dwight, they embarrassed us...", "order": 13354, "speaker": "Michael Scott"},
  {"season": 3, "episode": 19, "scene": 48, "line": "Argh!", "order": 13355, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 19, "scene": 48, "line": "... because they had a much scarier safety presentation.", "order": 13356, "speaker": "Michael Scott"},
  {"season": 3, "episode": 19, "scene": 48, "line": "Ghaaa!", "order": 13357, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 19, "scene": 49, "line": "You know what's funny? Robin Williams. [sighs] When a cat gets stuck in a washing machine. City Slickers. Talking like Borat. You know what's not funny? Safety, or making fun of the person trying to talk about safety. [in Borat voice] Not nice. I must show people how dangerous office can be. High five... Now that's funny.", "order": 13358, "speaker": "Michael Scott"},
  {"season": 3, "episode": 19, "scene": 50, "line": "How do you make depression sexy? How do you... How do you get people interested in depression? That is the conon... the conun... the conumbery", "order": 13359, "speaker": "Michael Scott"},
  {"season": 3, "episode": 19, "scene": 51, "line": "Those warehouse guys, think that we are all flabby, middle management, nerf balls. Well I'm going to show them that we have nerfs of steel. [winks]", "order": 13360, "speaker": "Michael Scott"},
  {"season": 3, "episode": 19, "scene": 52, "line": "[Michael, Andy, and Dwight are standing around a trampoline] You know guys, I recently learned some pretty cool stuff about the difference between feeling something and... acting on that thing.", "order": 13361, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 19, "scene": 52, "line": "Can you tell Andy to focus on the springs? Please.", "order": 13362, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 19, "scene": 52, "line": "Yeah, Drew.", "order": 13363, "speaker": "Michael Scott"},
  {"season": 3, "episode": 19, "scene": 52, "line": "Yes bosses.", "order": 13364, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 19, "scene": 52, "line": "You know Drew, why don't you go inside. Dwight come here. Where should we put this?", "order": 13365, "speaker": "Michael Scott"},
  {"season": 3, "episode": 19, "scene": 52, "line": "OK, ahh, turn around, and do a broad jump for me on go. Ready... Go! One Mississ... OK, you went that far on one Mississ. Ippi would take you to here. This trajectory times the propulsion from your quadriceps would be about three Mississippi, maybe three and a quarter. So one Mississ... correct... ippi, two Mississippi, three Mississippi... e. Right here.", "order": 13366, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 19, "scene": 52, "line": "OK, good. Thank you. Wanna take our time, do this right.", "order": 13367, "speaker": "Michael Scott"},
  {"season": 3, "episode": 19, "scene": 53, "line": "[phone rings] Hello? Hi Bob Vance. [laughs]", "order": 13368, "speaker": "Phyllis Vance"},
  {"season": 3, "episode": 19, "scene": 53, "line": "That's three times in ten minutes.", "order": 13369, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 19, "scene": 53, "line": "Where's my five dollars?", "order": 13370, "speaker": "Stanley Hudson"},
  {"season": 3, "episode": 19, "scene": 53, "line": "You are my little teddy bear. I wantcha.", "order": 13371, "speaker": "Phyllis Vance"},
  {"season": 3, "episode": 19, "scene": 54, "line": "I always knew my co-workers were annoying. I never realized I could profit from it. Go ahead Bob Vance, call Phyllis, all day.", "order": 13372, "speaker": "Stanley Hudson"},
  {"season": 3, "episode": 19, "scene": 55, "line": "[Michael is up on the roof of the building] Michael, go ahead, we're listening.", "order": 13373, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 19, "scene": 55, "line": "I'm going to wait. I don't want to say this twice, it's too painful and I'm too depressed.", "order": 13374, "speaker": "Michael Scott"},
  {"season": 3, "episode": 19, "scene": 55, "line": "[talking through bullhorn] Michael, you can not fly, you can not, I've seen this too many times.", "order": 13375, "speaker": "Creed Bratton"},
  {"season": 3, "episode": 19, "scene": 55, "line": "Give me that thing, I want to try.", "order": 13376, "speaker": "Kelly Kapoor"},
  {"season": 3, "episode": 19, "scene": 55, "line": "Oh, you know what we should probably preserve the batteries.", "order": 13377, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 19, "scene": 55, "line": "I was just going to say that [yells toward Michael] you have a cute butt. Oh my God I did not just say that, did I just say that? Oh my God I'm so adorable.", "order": 13378, "speaker": "Kelly Kapoor"},
  {"season": 3, "episode": 19, "scene": 55, "line": "I know the timings bad, but I hate to be a nudge, but there's stuff that he has to sign that needs to be mailed by four.", "order": 13379, "speaker": "Angela Martin"},
  {"season": 3, "episode": 19, "scene": 55, "line": "OK, if he's not down by four, I'll get the papers up to him.", "order": 13380, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 19, "scene": 55, "line": "OK, it's three forty eight.", "order": 13381, "speaker": "Angela Martin"},
  {"season": 3, "episode": 19, "scene": 55, "line": "OK, Ryan you know what? You can do this.", "order": 13382, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 19, "scene": 55, "line": "Oh, no, I have no training in this sort of thing.", "order": 13383, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 19, "scene": 55, "line": "Come on.", "order": 13384, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 19, "scene": 55, "line": "Don't play dumb, you know damn well what we talking about.", "order": 13385, "speaker": "Stanley Hudson"},
  {"season": 3, "episode": 19, "scene": 55, "line": "Come on. He'll listen to you.", "order": 13386, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 19, "scene": 55, "line": "Come on Ryan.", "order": 13387, "speaker": "Everyone"},
  {"season": 3, "episode": 19, "scene": 55, "line": "Let's do it.", "order": 13388, "speaker": "Angela Martin"},
  {"season": 3, "episode": 19, "scene": 55, "line": "Come on pretty boy.", "order": 13389, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 19, "scene": 55, "line": "Michael, you have always taken a great interest in my career.", "order": 13390, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 19, "scene": 55, "line": "I will miss you the most Ryan.", "order": 13391, "speaker": "Michael Scott"},
  {"season": 3, "episode": 19, "scene": 55, "line": "Oh, what is your deal man!? Seriously I don't get it.", "order": 13392, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 19, "scene": 55, "line": "Let me borrow that chief. Michael, you don't have to prove anything to anyone but yourself. So don't jump just to prove anything to us. OK? That said, if you need to prove something to yourself, you should jump.", "order": 13393, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 19, "scene": 55, "line": "Oh!", "order": 13394, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 19, "scene": 55, "line": "Up to you.", "order": 13395, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 19, "scene": 55, "line": "No no no!", "order": 13396, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 19, "scene": 55, "line": "If you do jump, I want you to reach for the sky.", "order": 13397, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 19, "scene": 55, "line": "Hey!", "order": 13398, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 19, "scene": 55, "line": "OK. careful, thanks. [takes bullhorn from Andy]", "order": 13399, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 19, "scene": 55, "line": "Grab a comet and kiss the moon! Kiss it!", "order": 13400, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 19, "scene": 56, "line": "See this is why you always take ten thousand to one. You never know.", "order": 13401, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 19, "scene": 57, "line": "You own a convertible. Come on man that's, that's hot. Women love that stuff right?", "order": 13402, "speaker": "Darryl Philbin"},
  {"season": 3, "episode": 19, "scene": 57, "line": "It's only leased, and it's got rust damage, he'll never get his deposit back.", "order": 13403, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 19, "scene": 58, "line": "Coal miners, crab fishermen, Dog the bounty hunter, all dangerous jobs. But also upper middle management. [takes deep breath] Wow! I could have fallen off a roof today... And you don't worry about falling off a roof when you work on a crab boat... And there are no roofs in coal mines.", "order": 13404, "speaker": "Michael Scott"},
  {"season": 3, "episode": 19, "scene": 59, "line": "[in parking lot] Goodnight Stanley. [sees his car is keyed, just nods his head]", "order": 13405, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 20, "scene": 1, "line": "[Dressed as Dwight] It's kind of blurry. [puts on his glasses] That's better. [exhales] Question. What kind of bear is best?", "order": 13406, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 20, "scene": 1, "line": "That's a ridiculous question.", "order": 13407, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 20, "scene": 1, "line": "False. Black bear.", "order": 13408, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 20, "scene": 1, "line": "Well that's debatable. There are basically two schools of thought---", "order": 13409, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 20, "scene": 1, "line": "Fact. Bears eat beets. Bears. Beets. Battlestar Galactica.", "order": 13410, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 20, "scene": 1, "line": "Bears do not--- What is going on--- What are you doing?!", "order": 13411, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 20, "scene": 2, "line": "Last week, I was in a drug store and I saw these glasses. Uh, four dollars. And it only cost me seven dollars to recreate the rest of the ensemble. And that's a grand total of... [Jim calculates the total on his calculator-watch] eleven dollars.", "order": 13412, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 20, "scene": 3, "line": "You know what? Imitation is the most sincere form of flattery, so I thank you. [Jim places a bobble-head on his desk] Identity theft is not a joke, Jim! Millions of families suffer every year!", "order": 13413, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 20, "scene": 3, "line": "... MICHAEL!", "order": 13414, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 20, "scene": 3, "line": "Oh, that's funny. MICHAEL!", "order": 13415, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 20, "scene": 4, "line": "[on phone] Yes, I understand. Can I transfer you to customer relations?", "order": 13416, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 20, "scene": 4, "line": "[on phone] Absolutely. I couldn't be more sorry about this.", "order": 13417, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 20, "scene": 4, "line": "[on phone] I know, I know. We're all trying to get to the bottom of this.", "order": 13418, "speaker": "Phyllis Vance"},
  {"season": 3, "episode": 20, "scene": 4, "line": "[on phone] I am upset. Don't I sound upset?", "order": 13419, "speaker": "Stanley Hudson"},
  {"season": 3, "episode": 20, "scene": 4, "line": "[on phone] It is disgusting. I totally agree. Well, we're going to recalling all of that paper.", "order": 13420, "speaker": "Michael Scott"},
  {"season": 3, "episode": 20, "scene": 5, "line": "We have a crisis. Apparently, a disgruntled employee at the paper mill decided that it would be funny to put an obscene watermark on our 24 pound cream butter stock. Five hundred boxes has gone out, with the image of a beloved cartoon duck, performing... unspeakable acts upon a certain cartoon mouse that a lot of people like. I've never been a fan.", "order": 13421, "speaker": "Michael Scott"},
  {"season": 3, "episode": 20, "scene": 6, "line": "Everybody in here. STAT. No time to lose. Cri-Man-Squa. F and C, doubletime.", "order": 13422, "speaker": "Michael Scott"},
  {"season": 3, "episode": 20, "scene": 6, "line": "Cri-Man-Squa?", "order": 13423, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 20, "scene": 6, "line": "Crisis Management Squad.", "order": 13424, "speaker": "Michael Scott"},
  {"season": 3, "episode": 20, "scene": 6, "line": "F and C, doubletime?", "order": 13425, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 20, "scene": 6, "line": "Front and Center. Twice as fast as you would normally go. Any other questions?", "order": 13426, "speaker": "Michael Scott"},
  {"season": 3, "episode": 20, "scene": 6, "line": "One more. Why are you talking like that?", "order": 13427, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 20, "scene": 6, "line": "To save time, Jim.", "order": 13428, "speaker": "Michael Scott"},
  {"season": 3, "episode": 20, "scene": 6, "line": "Actually I think you could make the argument that it wastes time.", "order": 13429, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 20, "scene": 6, "line": "Yeah, she has a good point. I mean, for example, with the last thing you said, by the time you explained it, it actually took up more time.", "order": 13430, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 20, "scene": 6, "line": "You know what? Bluh bluh bluh bluh bluh! Forget it. Where is Creed?", "order": 13431, "speaker": "Michael Scott"},
  {"season": 3, "episode": 20, "scene": 6, "line": "Here.", "order": 13432, "speaker": "Creed Bratton"},
  {"season": 3, "episode": 20, "scene": 6, "line": "Creed. Quality assurance. Your job. I really think you screwed the pooch on one, Creed. Because of you, the entire company is in jeopardy.", "order": 13433, "speaker": "Michael Scott"},
  {"season": 3, "episode": 20, "scene": 7, "line": "Every week I'm supposed to take four hours and do a quality spot-check at the paper mill. And of course the one year I blow it off, this happens.", "order": 13434, "speaker": "Creed Bratton"},
  {"season": 3, "episode": 20, "scene": 8, "line": "We have a lot of angry customers out there. This puts us at threat level midnight. Accounting, you are on customer service duty today.", "order": 13435, "speaker": "Michael Scott"},
  {"season": 3, "episode": 20, "scene": 8, "line": "That's really not our job.", "order": 13436, "speaker": "Oscar Martinez"},
  {"season": 3, "episode": 20, "scene": 8, "line": "Midnight, Oscar!", "order": 13437, "speaker": "Michael Scott"},
  {"season": 3, "episode": 20, "scene": 8, "line": "Kelly's training us?", "order": 13438, "speaker": "Angela Martin"},
  {"season": 3, "episode": 20, "scene": 9, "line": "This day is bananas. B-A-N-A-N-A-S. This", "order": 13439, "speaker": "Kelly Kapoor"},
  {"season": 3, "episode": 20, "scene": 10, "line": "[getting a pill from the bottle] I don't have a headache. I'm just preparing.", "order": 13440, "speaker": "Angela Martin"},
  {"season": 3, "episode": 20, "scene": 11, "line": "Jim, big fire in your house. Your client, Dunmore High-school, sent out their prom invitations on this paper. Went home to all the kids.", "order": 13441, "speaker": "Michael Scott"},
  {"season": 3, "episode": 20, "scene": 11, "line": "Yeah, I gotta call out on that.", "order": 13442, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 20, "scene": 11, "line": "No, no, no, no. Not good enough. This is a keystone account. I want you in the school. In person.", "order": 13443, "speaker": "Michael Scott"},
  {"season": 3, "episode": 20, "scene": 11, "line": "All right.", "order": 13444, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 20, "scene": 11, "line": "I want you to bring a partner.", "order": 13445, "speaker": "Michael Scott"},
  {"season": 3, "episode": 20, "scene": 11, "line": "I'll go.", "order": 13446, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 20, "scene": 11, "line": "No, sweet cheeks. We need someone who's actually made a sale. Andy, you go.", "order": 13447, "speaker": "Michael Scott"},
  {"season": 3, "episode": 20, "scene": 11, "line": "[English accent] William Dolittle at your service. A.K.A., Will Do.", "order": 13448, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 20, "scene": 11, "line": "Yeah, I'm definitely gonna go alone.", "order": 13449, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 20, "scene": 11, "line": "No, no. I need two men on this. That's what she said. No time! But she did. NO TIME! Guys, get on this. Dwight, I want you to be in charge of the press conference.", "order": 13450, "speaker": "Michael Scott"},
  {"season": 3, "episode": 20, "scene": 11, "line": "Yes! You are entering the 'No Spin Zone!'", "order": 13451, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 20, "scene": 11, "line": "We're having a press conference?", "order": 13452, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 20, "scene": 11, "line": "No, Pam. The press is just gonna find out by themselves.", "order": 13453, "speaker": "Michael Scott"},
  {"season": 3, "episode": 20, "scene": 11, "line": "Not! [scoffs]", "order": 13454, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 20, "scene": 12, "line": "Here's the thing. When a company screws up, best thing to do is call a press conference. Alert the media, and then you control the story. Wait for them to find out, and the story controls you. That's what happened to O.J.", "order": 13455, "speaker": "Michael Scott"},
  {"season": 3, "episode": 20, "scene": 13, "line": "I have invited Barbara Allen, one of our oldest clients, to come in here, and meet with me, for a personal apology. The press wants a story, I will give them a story.", "order": 13456, "speaker": "Michael Scott"},
  {"season": 3, "episode": 20, "scene": 13, "line": "Oh, did the press ask for a story?", "order": 13457, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 20, "scene": 13, "line": "Here", "order": 13458, "speaker": "Michael Scott"},
  {"season": 3, "episode": 20, "scene": 14, "line": "Yes, hello. Creed Bratton, Quality Assurance, Dunder Mifflin Scranton. I was supposed to meet with one of your floor managers last week for a quality inspection, and he or she wasn't there. And I'm trying to remember who it was. Mm-hmm. Who wasn't there last week? Debbie Brown. And which day was that? Wednesday, the eleventh. Perfect.", "order": 13459, "speaker": "Creed Bratton"},
  {"season": 3, "episode": 20, "scene": 15, "line": "The only difference between me and a homeless man is this job. I will do whatever it takes to survive. Like I did... when I was a homeless man.", "order": 13460, "speaker": "Creed Bratton"},
  {"season": 3, "episode": 20, "scene": 16, "line": "Look, I know the reason that you guys became accountants is because you're not good at interacting with people. But guess what? From now on, you guys are no longer losers! So give yourselves a round of applause. [Kelly and Kevin clap]", "order": 13461, "speaker": "Kelly Kapoor"},
  {"season": 3, "episode": 20, "scene": 16, "line": "I wonder how many phone calls you're missing while you're teaching us to answer calls.", "order": 13462, "speaker": "Oscar Martinez"},
  {"season": 3, "episode": 20, "scene": 16, "line": "I know, right? Probably a lot.", "order": 13463, "speaker": "Kelly Kapoor"},
  {"season": 3, "episode": 20, "scene": 16, "line": "Can you just tell us what we need to do so we can go?", "order": 13464, "speaker": "Angela Martin"},
  {"season": 3, "episode": 20, "scene": 16, "line": "OK, Angela. I love your enthusiasm. All you need to know how to do is pick up the phone and say 'Customer Service, this is Kelly!' Except don't say 'Kelly,' say your own name. Or if you're bored, you can just make up a name. Like one time, I said I was Bridget Jones, [in an English accent] and I talked like this for the whole conversation!", "order": 13465, "speaker": "Kelly Kapoor"},
  {"season": 3, "episode": 20, "scene": 16, "line": "Oh! Can I be [horrible Australian accent] Australian, mate?", "order": 13466, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 20, "scene": 16, "line": "[in accent] Absolutely!", "order": 13467, "speaker": "Kelly Kapoor"},
  {"season": 3, "episode": 20, "scene": 16, "line": "[in accent] 'ello, mate!", "order": 13468, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 20, "scene": 16, "line": "[in accent] I like ice cream! I need a boyfriend.", "order": 13469, "speaker": "Kelly Kapoor"},
  {"season": 3, "episode": 20, "scene": 16, "line": "[in accent] I like ice cream too, mate. Alligators and dingo babies.", "order": 13470, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 20, "scene": 17, "line": "Beer me!", "order": 13471, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 20, "scene": 17, "line": "What's that?", "order": 13472, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 20, "scene": 17, "line": "Hand me that water. I always say 'Beer me.' Gets a laugh like a quarter of the time. So how's what's-her-name?", "order": 13473, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 20, "scene": 17, "line": "You know her name.", "order": 13474, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 20, "scene": 17, "line": "Who, Karen? Yeah, she's only one of my oldest friends. [takes swig of water] Mmm. How's the apartment hangin'?", "order": 13475, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 20, "scene": 17, "line": "It's fine.", "order": 13476, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 20, "scene": 17, "line": "Nice. Check out this sunshine, man. Global warming, right? Today was supposed to be really cold, I bet.", "order": 13477, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 20, "scene": 17, "line": "What about music? Do you have any music?", "order": 13478, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 20, "scene": 17, "line": "Uh, yeah. Should have said so. [sings] Rha-dah-dah-dah! Give me the beat, boys, and free my soul. I wanna get lost in your rock and roll and drift away-he-hay. Ruu-da-doo-da-doo, ba-dit-da-doo-da-dun, Give me the beat, boys, and free my little-ole-soul, I wa--", "order": 13479, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 20, "scene": 17, "line": "I was thinkin' about more like a CD, or... a CD.", "order": 13480, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 20, "scene": 17, "line": "Your call, dude. My girlfriend made an awesome mix. Beer me that disc.", "order": 13481, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 20, "scene": 18, "line": "Lord, beer me strength.", "order": 13482, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 20, "scene": 19, "line": "So, Tuna. When we get in there, let's do a really good job, okay?", "order": 13483, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 20, "scene": 19, "line": "Did that", "order": 13484, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 20, "scene": 19, "line": "Well not everything a guy says needs to be said. Sometimes it's just about the music of the conversation.", "order": 13485, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 20, "scene": 20, "line": "Whoa! What the--- Why is my girlfriend here?", "order": 13486, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 20, "scene": 20, "line": "Oh, is she that teacher in the white?", "order": 13487, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 20, "scene": 20, "line": "No, she's a part-time frozen yogurt chef.", "order": 13488, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 20, "scene": 20, "line": "Which one... is she?", "order": 13489, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 20, "scene": 20, "line": "The one in the green hoodie.", "order": 13490, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 20, "scene": 20, "line": "Wow.", "order": 13491, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 20, "scene": 20, "line": "I wonder if she's, like, a... a guidance counselor, or something?", "order": 13492, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 20, "scene": 20, "line": "No, I don't think so.", "order": 13493, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 20, "scene": 20, "line": "She's like, probably a tutor.", "order": 13494, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 20, "scene": 20, "line": "Nope.", "order": 13495, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 20, "scene": 20, "line": "She probably a t--", "order": 13496, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 20, "scene": 20, "line": "No.", "order": 13497, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 20, "scene": 20, "line": "Sh---", "order": 13498, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 20, "scene": 20, "line": "No.", "order": 13499, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 20, "scene": 21, "line": "OK, press conference in 45. Dwight, make sure this place looks nice.", "order": 13500, "speaker": "Michael Scott"},
  {"season": 3, "episode": 20, "scene": 21, "line": "On it. OK, Karen, Ryan, Pam, center stage. Pam, run a comb through your hair.", "order": 13501, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 20, "scene": 22, "line": "First rule in road-side beet sales: Put the most attractive beets on top. The ones that make you pull the car over and go, 'Wow, I need this beet right now.' Those are the money beets.", "order": 13502, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 20, "scene": 23, "line": "[Chad Lite walks in the door] [to Office workers] Hey! [snaps twice] Look sharp! [to Chad Lite] Hey! Uh, Hi. Hello, Dwight Schrute.", "order": 13503, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 20, "scene": 23, "line": "Hi, uh---", "order": 13504, "speaker": "Chad Lite"},
  {"season": 3, "episode": 20, "scene": 23, "line": "And you must be uh, from the Washington Post.", "order": 13505, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 20, "scene": 23, "line": "Scranton Times. Chad Lite, 'Lighter Side of Life.'", "order": 13506, "speaker": "Chad Lite"},
  {"season": 3, "episode": 20, "scene": 23, "line": "And 'Breaking Corporate News.'", "order": 13507, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 20, "scene": 23, "line": "And obits.", "order": 13508, "speaker": "Chad Lite"},
  {"season": 3, "episode": 20, "scene": 23, "line": "Oh, dear God. OK, here are your credentials. You've been granted level three security clearance.", "order": 13509, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 20, "scene": 23, "line": "Oh...", "order": 13510, "speaker": "Chad Lite"},
  {"season": 3, "episode": 20, "scene": 23, "line": "Don't get too excited, that's out of 20. Right this way. Regional Manager Michael Scott will be addressing the client in the press room shortly. Have a seat, can I get you a beverage?", "order": 13511, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 20, "scene": 23, "line": "Uh, yeah, I'd like uh, uh---", "order": 13512, "speaker": "Chad Lite"},
  {"season": 3, "episode": 20, "scene": 23, "line": "[shuts door on him] Great.", "order": 13513, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 20, "scene": 24, "line": "Oh, Andy. You know what? We don't have a", "order": 13514, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 20, "scene": 24, "line": "Jamie!", "order": 13515, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 20, "scene": 24, "line": "Andy--- Ohh...", "order": 13516, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 20, "scene": 24, "line": "What are you doing here?", "order": 13517, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 20, "scene": 24, "line": "Andy?", "order": 13518, "speaker": "Jamie"},
  {"season": 3, "episode": 20, "scene": 24, "line": "Are you a student here?", "order": 13519, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 20, "scene": 24, "line": "Oh... yeah...", "order": 13520, "speaker": "Jamie"},
  {"season": 3, "episode": 20, "scene": 24, "line": "You never told me you were in high-school!", "order": 13521, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 20, "scene": 24, "line": "This is weird. I... gotta go to Spanish.", "order": 13522, "speaker": "Jamie"},
  {"season": 3, "episode": 20, "scene": 24, "line": "OH MY GOD!", "order": 13523, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 20, "scene": 24, "line": "Oh my God.", "order": 13524, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 20, "scene": 24, "line": "I had no idea.", "order": 13525, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 20, "scene": 24, "line": "Well... that's not gonna hold up in court.", "order": 13526, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 20, "scene": 24, "line": "Huh... We didn't do anything illegal. Except knock over a mailbox with her friends.", "order": 13527, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 20, "scene": 25, "line": "[on phone] Sir, yes, you have a valid point. I'm sorry. No, I am", "order": 13528, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 20, "scene": 25, "line": "[on phone] Really, Dixon City? ...Carbondale.", "order": 13529, "speaker": "Oscar Martinez"},
  {"season": 3, "episode": 20, "scene": 25, "line": "[on phone] Excuse me? Well, I don't see how that's our fault. And I've already told you, the official position of Dunder Mifflin is apologetic, so I don't know what you want from me. [hangs up]", "order": 13530, "speaker": "Angela Martin"},
  {"season": 3, "episode": 20, "scene": 25, "line": "OK, first, I just wanna say that you are doing", "order": 13531, "speaker": "Kelly Kapoor"},
  {"season": 3, "episode": 20, "scene": 26, "line": "When I went over Wednesday, for the spot-check, I got a call from Debbie Brown, saying she has an emergency dentist appointment.", "order": 13532, "speaker": "Creed Bratton"},
  {"season": 3, "episode": 20, "scene": 26, "line": "Emergency dentist appointment.", "order": 13533, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 20, "scene": 26, "line": "Now I'm told she told her manager she had the flu. I'm a trusting guy, but uh, I just wish Debbie Brown had been there. We would have caught this.", "order": 13534, "speaker": "Creed Bratton"},
  {"season": 3, "episode": 20, "scene": 27, "line": "Yes, I'll be sure someone returns your call. I'm so sorry. Bye.", "order": 13535, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 20, "scene": 27, "line": "Hello, I'm looking for Michael Scott.", "order": 13536, "speaker": "Barbara"},
  {"season": 3, "episode": 20, "scene": 28, "line": "Mrs. Allen is our most important client... because every client is our most important client. Even though she's a pretty unimportant client, really.", "order": 13537, "speaker": "Michael Scott"},
  {"season": 3, "episode": 20, "scene": 29, "line": "And so, with the eyes of the nation upon us, I would like to say that Dunder Mifflin truly regrets this unfortunate incident. And, as a gesture of gratitude, for your continued loyalty, Mrs. Allen, I would like to present you with this novelty check, for six months of free paper or 25 reams, whichever comes first.", "order": 13538, "speaker": "Michael Scott"},
  {"season": 3, "episode": 20, "scene": 29, "line": "[snaps picture with his camera phone] You look good in this.", "order": 13539, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 20, "scene": 29, "line": "So, let us consider this matter ended.", "order": 13540, "speaker": "Michael Scott"},
  {"season": 3, "episode": 20, "scene": 29, "line": "Well it isn't ended. I'm... I'm very angry. I--- I could have lost business.", "order": 13541, "speaker": "Barbara"},
  {"season": 3, "episode": 20, "scene": 29, "line": "I know, I know you're angry. And we are truly, truly sorry.", "order": 13542, "speaker": "Michael Scott"},
  {"season": 3, "episode": 20, "scene": 29, "line": "I don't accept your apology. The watermark was obscene and horrifying.", "order": 13543, "speaker": "Barbara"},
  {"season": 3, "episode": 20, "scene": 29, "line": "Well, we are extremely sorry.", "order": 13544, "speaker": "Michael Scott"},
  {"season": 3, "episode": 20, "scene": 29, "line": "I don't accept.", "order": 13545, "speaker": "Barbara"},
  {"season": 3, "episode": 20, "scene": 30, "line": "I'll be with you in a moment.", "order": 13546, "speaker": "School Official"},
  {"season": 3, "episode": 20, "scene": 30, "line": "All right.", "order": 13547, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 20, "scene": 31, "line": "Who was that guy she was talking to? At her locker.", "order": 13548, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 20, "scene": 31, "line": "Not important. Because you're not dating her. Because it's a felony.", "order": 13549, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 20, "scene": 31, "line": "But who", "order": 13550, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 20, "scene": 31, "line": "Probably", "order": 13551, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 20, "scene": 32, "line": "The issue with the watermark is very serious.", "order": 13552, "speaker": "School Official"},
  {"season": 3, "episode": 20, "scene": 32, "line": "Absolutely.", "order": 13553, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 20, "scene": 32, "line": "We teach our students that character counts.", "order": 13554, "speaker": "School Official"},
  {"season": 3, "episode": 20, "scene": 32, "line": "And you should.", "order": 13555, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 20, "scene": 32, "line": "But---", "order": 13556, "speaker": "School Official"},
  {"season": 3, "episode": 20, "scene": 32, "line": "[scoffs] Pfft. You don't teach it well enough. One of your students is a", "order": 13557, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 20, "scene": 32, "line": "Andy... is having a", "order": 13558, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 20, "scene": 32, "line": "I want to take out an ad, in your yearbook. A full page, two words---", "order": 13559, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 20, "scene": 32, "line": "Good luck.'", "order": 13560, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 20, "scene": 32, "line": "That's not what I had in mind.", "order": 13561, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 20, "scene": 33, "line": "Ask where he's from.", "order": 13562, "speaker": "Kelly Kapoor"},
  {"season": 3, "episode": 20, "scene": 33, "line": "[on phone] Where are you from? Ohio? That's nice. So what do you want? [to Kelly] He's upset about the watermark.", "order": 13563, "speaker": "Angela Martin"},
  {"season": 3, "episode": 20, "scene": 33, "line": "OK, great. Now tell him that it was an unfortunate error, and we're doing everything we can do to fix it, and that you're sorry.", "order": 13564, "speaker": "Kelly Kapoor"},
  {"season": 3, "episode": 20, "scene": 33, "line": "[on phone] It was an unfortunate error.", "order": 13565, "speaker": "Angela Martin"},
  {"season": 3, "episode": 20, "scene": 33, "line": "And you're sorry.", "order": 13566, "speaker": "Kelly Kapoor"},
  {"season": 3, "episode": 20, "scene": 33, "line": "...and the company has already apologized, so you can take that apology or not. [hangs up] [to Kelly] I think he had Tourette's or something!", "order": 13567, "speaker": "Angela Martin"},
  {"season": 3, "episode": 20, "scene": 34, "line": "We... are going to do everything humanly possible, to ensure that this never happens again.", "order": 13568, "speaker": "Michael Scott"},
  {"season": 3, "episode": 20, "scene": 34, "line": "Well, it--- it doesn't help, because it already happened to me.", "order": 13569, "speaker": "Barbara"},
  {"season": 3, "episode": 20, "scene": 34, "line": "The watermark... it's a one time thing.", "order": 13570, "speaker": "Michael Scott"},
  {"season": 3, "episode": 20, "scene": 34, "line": "I don't care! It was disgusting. Cartoon characters having sex?", "order": 13571, "speaker": "Barbara"},
  {"season": 3, "episode": 20, "scene": 34, "line": "May I point out that the sex appeared to be consensual? Both animals were smiling.", "order": 13572, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 20, "scene": 34, "line": "OK...", "order": 13573, "speaker": "Michael Scott"},
  {"season": 3, "episode": 20, "scene": 35, "line": "I grew up on a farm. I have seen animals having sex in every position imaginable. Goat on chicken. Chicken on goat. Couple of chickens doin' a goat, couple of pigs watching. Whoever drew this got it exactly right.", "order": 13574, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 20, "scene": 36, "line": "What... can I do, for you?", "order": 13575, "speaker": "Michael Scott"},
  {"season": 3, "episode": 20, "scene": 36, "line": "I, for starters, I think that you should resign.", "order": 13576, "speaker": "Barbara"},
  {"season": 3, "episode": 20, "scene": 36, "line": "Well... [exhales] OK, well... Um, wasn't really my fault. The guys at the papermill---", "order": 13577, "speaker": "Michael Scott"},
  {"season": 3, "episode": 20, "scene": 36, "line": "You're the head---", "order": 13578, "speaker": "Barbara"},
  {"season": 3, "episode": 20, "scene": 36, "line": "The guys at the papermill--- No no no!", "order": 13579, "speaker": "Michael Scott"},
  {"season": 3, "episode": 20, "scene": 36, "line": "You're the head of the company!", "order": 13580, "speaker": "Barbara"},
  {"season": 3, "episode": 20, "scene": 36, "line": "I'm", "order": 13581, "speaker": "Michael Scott"},
  {"season": 3, "episode": 20, "scene": 36, "line": "Yes, and that makes it your responsibility---", "order": 13582, "speaker": "Barbara"},
  {"season": 3, "episode": 20, "scene": 36, "line": "No, I'm a regional manager---", "order": 13583, "speaker": "Michael Scott"},
  {"season": 3, "episode": 20, "scene": 36, "line": "And so you should lose your job!", "order": 13584, "speaker": "Barbara"},
  {"season": 3, "episode": 20, "scene": 36, "line": "No--- my--- OK, this is insane. You can get out of here. Get out!", "order": 13585, "speaker": "Michael Scott"},
  {"season": 3, "episode": 20, "scene": 36, "line": "Fine.", "order": 13586, "speaker": "Barbara"},
  {"season": 3, "episode": 20, "scene": 36, "line": "That's insane. We'll give this to somebody who will appreciate it.", "order": 13587, "speaker": "Michael Scott"},
  {"season": 3, "episode": 20, "scene": 36, "line": "Mm-hmm.", "order": 13588, "speaker": "Barbara"},
  {"season": 3, "episode": 20, "scene": 36, "line": "It's non-transferable...", "order": 13589, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 20, "scene": 36, "line": "Doesn't matter. Out please!", "order": 13590, "speaker": "Michael Scott"},
  {"season": 3, "episode": 20, "scene": 36, "line": "I'm calling the Better Business Bureau.", "order": 13591, "speaker": "Barbara"},
  {"season": 3, "episode": 20, "scene": 36, "line": "Yeah, well I'm calling the Ungrateful Bi-atch Hotline! [to Chad Lite] Did you get all that?", "order": 13592, "speaker": "Michael Scott"},
  {"season": 3, "episode": 20, "scene": 36, "line": "Everything.", "order": 13593, "speaker": "Chad Lite"},
  {"season": 3, "episode": 20, "scene": 37, "line": "We gotta do something. [exhales] This is spinning out of control, Pam. This is just, not...", "order": 13594, "speaker": "Michael Scott"},
  {"season": 3, "episode": 20, "scene": 37, "line": "It's just the Scranton Times...", "order": 13595, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 20, "scene": 37, "line": "No, then Newsweek picks it up. And then CNN does a story about it. Then... YouTube gets a hold of it...", "order": 13596, "speaker": "Michael Scott"},
  {"season": 3, "episode": 20, "scene": 37, "line": "You know what? I really think the whole thing is just gonna blow over in like a week or two.", "order": 13597, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 20, "scene": 37, "line": "You're right. It will blow over. But it's not... going to take... a week or two. [pulls out a video camcorder from his desk] Do you know what this is for?", "order": 13598, "speaker": "Michael Scott"},
  {"season": 3, "episode": 20, "scene": 37, "line": "Yes.", "order": 13599, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 20, "scene": 38, "line": "Michael likes me to run the camera when he makes his apology videos. He says he needs a woman's touch.", "order": 13600, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 20, "scene": 39, "line": "[Dwight is spraying far too much hairspray into Michael's hair] OK, I think that's good.", "order": 13601, "speaker": "Michael Scott"},
  {"season": 3, "episode": 20, "scene": 40, "line": "Hello. I am Michael Scott, Regional Manager of Dunder Mifflin Scranton. By now you are probably sick of hearing about Dunder Mifflin and our embarrassing watermark boner. Let me tell you something. Something from the heart. I am not leaving this office. It will take a SWAT team, to remove me from this office, and maybe not even that.'", "order": 13602, "speaker": "Michael Scott"},
  {"season": 3, "episode": 20, "scene": 40, "line": "Uh, you could never withstand a SWAT team.", "order": 13603, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 20, "scene": 40, "line": "That's how devoted I am to this job.", "order": 13604, "speaker": "Michael Scott"},
  {"season": 3, "episode": 20, "scene": 40, "line": "I'm just saying...", "order": 13605, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 20, "scene": 40, "line": "I know.", "order": 13606, "speaker": "Michael Scott"},
  {"season": 3, "episode": 20, "scene": 40, "line": "They would flank you. Throw in a concussion grenade.", "order": 13607, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 20, "scene": 40, "line": "I understand that, Dwight.", "order": 13608, "speaker": "Michael Scott"},
  {"season": 3, "episode": 20, "scene": 40, "line": "You would be on the ground, blind, deaf, dumb.", "order": 13609, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 20, "scene": 40, "line": "Do you think you're taking it a little... literally, Dwight? And now we're wasting tape. I'm gonna have to cut this all out. Can you say cut?", "order": 13610, "speaker": "Michael Scott"},
  {"season": 3, "episode": 20, "scene": 40, "line": "Cut.", "order": 13611, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 20, "scene": 40, "line": "So I'll know where---", "order": 13612, "speaker": "Michael Scott"},
  {"season": 3, "episode": 20, "scene": 40, "line": "Cut.", "order": 13613, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 20, "scene": 40, "line": "I'm asking Pam to do it, please.", "order": 13614, "speaker": "Michael Scott"},
  {"season": 3, "episode": 20, "scene": 40, "line": "Cut.", "order": 13615, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 20, "scene": 40, "line": "OK, ready?", "order": 13616, "speaker": "Michael Scott"},
  {"season": 3, "episode": 20, "scene": 41, "line": "Kevin, what's four plus seven?", "order": 13617, "speaker": "Angela Martin"},
  {"season": 3, "episode": 20, "scene": 41, "line": "[thinks] Eleven.", "order": 13618, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 20, "scene": 41, "line": "Yeah, well you didn't know that when you filled out this payroll form.", "order": 13619, "speaker": "Angela Martin"},
  {"season": 3, "episode": 20, "scene": 41, "line": "Yeah, well at least I didn't suck at customer relations. Oooh, yes. Facial.", "order": 13620, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 20, "scene": 41, "line": "[laughs] Yes. [air high five]", "order": 13621, "speaker": "Oscar Martinez"},
  {"season": 3, "episode": 20, "scene": 41, "line": "You two are apes.", "order": 13622, "speaker": "Angela Martin"},
  {"season": 3, "episode": 20, "scene": 41, "line": "I expect you to apologize for that, Angela.", "order": 13623, "speaker": "Oscar Martinez"},
  {"season": 3, "episode": 20, "scene": 41, "line": "I'm sorry... that you're both morons.", "order": 13624, "speaker": "Angela Martin"},
  {"season": 3, "episode": 20, "scene": 41, "line": "Oh, but you still said 'I'm sorry.'", "order": 13625, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 20, "scene": 41, "line": "I called you morons.", "order": 13626, "speaker": "Angela Martin"},
  {"season": 3, "episode": 20, "scene": 41, "line": "Still said it.", "order": 13627, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 20, "scene": 41, "line": "Still said it, so... [Kevin and Oscar screw up an air high five]", "order": 13628, "speaker": "Oscar Martinez"},
  {"season": 3, "episode": 20, "scene": 42, "line": "Five, four, three. 'There is no way, I will resign. It wouldn't be fair. Not to the good workers I work with, not to my clients, and especially not to me. Let's not forget who this whole resigning business is about, anyway. If I could leave you with one thought, remember... it wasn't me. They're trying to make me an escape goat. If I am fired, I swear to God, that every single piece of copier paper in this town is going to have the F-word on it. The F-word. You have one day.'", "order": 13629, "speaker": "Michael Scott"},
  {"season": 3, "episode": 20, "scene": 42, "line": "One day for what?", "order": 13630, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 20, "scene": 42, "line": "That's... they always give an ultimatum.", "order": 13631, "speaker": "Michael Scott"},
  {"season": 3, "episode": 20, "scene": 42, "line": "OK.", "order": 13632, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 20, "scene": 42, "line": "Good, cut?", "order": 13633, "speaker": "Michael Scott"},
  {"season": 3, "episode": 20, "scene": 42, "line": "Cut. That was your best apology video ever.", "order": 13634, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 20, "scene": 42, "line": "Thought so too.", "order": 13635, "speaker": "Michael Scott"},
  {"season": 3, "episode": 20, "scene": 43, "line": "[to Dwight] Got a farewell card for Debbie Brown. I thought maybe you'd like to sign it. Maybe throw a couple of bucks in there for her. She's got some children.", "order": 13636, "speaker": "Creed Bratton"},
  {"season": 3, "episode": 20, "scene": 44, "line": "I feel terrible about Debbie Brown. She got fired because of Dwight. So I thought I'd pass around a goodbye card, maybe everyone could put in a couple of bucks to help her through these difficult times. Why do bad things always happen to the good people? It's tragic. Just tragic.", "order": 13637, "speaker": "Creed Bratton"},
  {"season": 3, "episode": 20, "scene": 45, "line": "You want music?", "order": 13638, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 20, "scene": 45, "line": "I don't care.", "order": 13639, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 20, "scene": 45, "line": "Come on man, just give it a couple days. I think you'll be all right.", "order": 13640, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 20, "scene": 45, "line": "Yeah.", "order": 13641, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 20, "scene": 45, "line": "[singing the intro to The Lion King's 'The Lion Sleeps Tonight'] A-wimowheh, a-wimowheh, a-wimowheh, a-wimowheh, a-wimowheh, a-wimowheh, a-wimowheh, a-wimowheh...", "order": 13642, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 20, "scene": 45, "line": "You know what--- I don't---", "order": 13643, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 20, "scene": 45, "line": "A-wimowheh, a-wimowheh, a-wimowheh, a-wimowheh, a-wimowheh, a-wimowheh, a-wimowheh, a-wimowheh, a-wimowheh...", "order": 13644, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 20, "scene": 45, "line": "[Joins in with classic Andy falsetto] Oooo-oo-oooo-ooo-oo-oooo-oo-ooo-ooo a-wi-imbube!", "order": 13645, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 20, "scene": 45, "line": "A-wimowheh, a-wimowheh, a-wimowheh, a-wimowheh, a-wimowheh...", "order": 13646, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 20, "scene": 45, "line": "Oooo-oo-oooo-ooo-oo-oooo-oo-ooo-ooo a-wi-imbube!", "order": 13647, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 20, "scene": 45, "line": "A-wimowheh, a-wimowheh, a-wimowheh, a-wimowheh, a-wimowheh...", "order": 13648, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 20, "scene": 45, "line": "Sweet.", "order": 13649, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 20, "scene": 46, "line": "[walks in dressed as Jim] Pam.", "order": 13650, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 20, "scene": 46, "line": "Hey, Dwight. You look really nice today.", "order": 13651, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 20, "scene": 46, "line": "[scoffs] Pssssh. I look like an idiot. Hey Karen.", "order": 13652, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 20, "scene": 46, "line": "Hey, Dwight, lookin' sharp.", "order": 13653, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 20, "scene": 46, "line": "Yeah, that's cause I'm... you're boyfriend, Jim Halpert. Hey, Karen, wanna get t-together later and have sexual intercourse cause you're my girlfriend?", "order": 13654, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 20, "scene": 46, "line": "Do you?", "order": 13655, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 20, "scene": 46, "line": "No.", "order": 13656, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 20, "scene": 46, "line": "OK.", "order": 13657, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 20, "scene": 46, "line": "I'm good. Thanks.", "order": 13658, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 20, "scene": 46, "line": "[Dwight imitates the 'Jim face'] Look at that.", "order": 13659, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 20, "scene": 46, "line": "I'm Jim Halpert. [more horrible 'Jim faces']", "order": 13660, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 20, "scene": 46, "line": "Spot on.", "order": 13661, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 20, "scene": 46, "line": "Ah-luh-luh, a little comment. Muh.", "order": 13662, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 20, "scene": 47, "line": "Oh my God, I am so sorry, that is so messed up. Everyone here is so upset, you have no idea. And rest assured your voice has been heard. Okay, I'll be thinking about you all day. Customer service, this is Kelly. Oh my God, I am so sorry, that is so messed up. Everyone here is so upset, you have no idea. And rest assured your voice has been heard. Okay, I'll be thinking about you all day.", "order": 13663, "speaker": "Kelly Kapoor"},
  {"season": 3, "episode": 20, "scene": 48, "line": "Customer service, this is Kelly. Oh my God, I am so sorry, that is so messed up. Everyone here is so upset, you have no idea. I'll be thinking about you all day. Customer service, this is Kelly. Oh my God, I am so sorry, that is so messed up. Everyone here is so upset, you have no idea. I'll be thinking about you all day. Customer service, this is Kelly. Oh my God, I am so sorry, that is so messed up. Everyone here is so upset, you have no idea. I'll be thinking about you all day.", "order": 13664, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 20, "scene": 49, "line": "The key to a crisis is dealing with it right away. Like that aspirin company that sent out the poisoned aspirin in the regular aspirin bottles. If you don't act fast, you will lose customers.", "order": 13665, "speaker": "Michael Scott"},
  {"season": 3, "episode": 20, "scene": 50, "line": "Hello, CNN? I need to speak to your Scranton-area bureau chief? All right, then the bureau chief for the Greater Wilkes-Barre area? How about, ah, Lackawanna County. Okay, Northeastern Pennsylvania then. This is extremely urgent! No... you put", "order": 13666, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 20, "scene": 51, "line": "Customer service isn't like accounting. It's not just typing numbers into a calculator and then it tells you an answer.", "order": 13667, "speaker": "Kelly Kapoor"},
  {"season": 3, "episode": 20, "scene": 51, "line": "We do a lot more than that.", "order": 13668, "speaker": "Oscar Martinez"},
  {"season": 3, "episode": 20, "scene": 51, "line": "Okay, or hand out checks, or whatever it is you do. Look, I know the reason that you guys became accountants is because you think that people don't like you. But guess what, from now on you guys are not losers! So give yourselves a round of applause.", "order": 13669, "speaker": "Kelly Kapoor"},
  {"season": 3, "episode": 20, "scene": 51, "line": "I wonder how many phone calls you're missing while you're teaching us to answer phone calls.", "order": 13670, "speaker": "Oscar Martinez"},
  {"season": 3, "episode": 20, "scene": 51, "line": "The annex rocks. All you need to do is make sure that you make a connection with the customer. They just wanna know that you're sorry. Do that and you'll be fine. Make sense?", "order": 13671, "speaker": "Kelly Kapoor"},
  {"season": 3, "episode": 20, "scene": 51, "line": "Mm-hm.", "order": 13672, "speaker": "Angela Martin"},
  {"season": 3, "episode": 20, "scene": 51, "line": "Okay, role-playing time!", "order": 13673, "speaker": "Kelly Kapoor"},
  {"season": 3, "episode": 20, "scene": 51, "line": "Alright, I think I'm trained.", "order": 13674, "speaker": "Angela Martin"},
  {"season": 3, "episode": 20, "scene": 51, "line": "Ooh, can I be a pirate?", "order": 13675, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 20, "scene": 52, "line": "[hums]", "order": 13676, "speaker": "Creed Bratton"},
  {"season": 3, "episode": 20, "scene": 52, "line": "Hello.", "order": 13677, "speaker": "Chad Lite"},
  {"season": 3, "episode": 20, "scene": 52, "line": "Hey!", "order": 13678, "speaker": "Creed Bratton"},
  {"season": 3, "episode": 20, "scene": 52, "line": "Excuse me. You're Creed Bratton, guitar player for Grass Roots in the late 1960s.", "order": 13679, "speaker": "Chad Lite"},
  {"season": 3, "episode": 20, "scene": 52, "line": "I am indeed. How'd you know that?", "order": 13680, "speaker": "Creed Bratton"},
  {"season": 3, "episode": 20, "scene": 52, "line": "I wrote your obituary.", "order": 13681, "speaker": "Chad Lite"},
  {"season": 3, "episode": 20, "scene": 52, "line": "Oh, oh that's right! Good work. Thank you very much, sir [shakes hand].", "order": 13682, "speaker": "Creed Bratton"},
  {"season": 3, "episode": 20, "scene": 53, "line": "About 10 years ago, for tax reasons, I faked my own death. I've been collecting benefits as my own widow ever since.", "order": 13683, "speaker": "Creed Bratton"},
  {"season": 3, "episode": 20, "scene": 54, "line": "Oh, my God!", "order": 13684, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 20, "scene": 54, "line": "Oh, my God! Wow.", "order": 13685, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 20, "scene": 54, "line": "I had no idea.", "order": 13686, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 20, "scene": 54, "line": "Then you did nothing wrong. We should go.", "order": 13687, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 20, "scene": 54, "line": "How could I not have realized?", "order": 13688, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 20, "scene": 54, "line": "She looks older.", "order": 13689, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 20, "scene": 54, "line": "Yeah, she does, doesn't she?", "order": 13690, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 20, "scene": 54, "line": "No.", "order": 13691, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 20, "scene": 54, "line": "She acts older, too. She knows everything about European history, and photosynthesis, and she made me this lanyard with, like, a Fimo clay thing. She's a real Renaissance woman.", "order": 13692, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 20, "scene": 54, "line": "Or a high school girl. Let's go [mouths 'wow' to the camera].", "order": 13693, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 20, "scene": 55, "line": "Chicken pot pie.", "order": 13694, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 20, "scene": 55, "line": "What's that?", "order": 13695, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 20, "scene": 55, "line": "That's what I was eating, in the parking lot of a 7-Eleven for dinner. And I got out of the car to shake the crumbs off my shirt, and this smoking hot woman comes up and we strike up a conversation.", "order": 13696, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 20, "scene": 55, "line": "Oh. You don't have to tell me this.", "order": 13697, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 20, "scene": 55, "line": "She lets me buy her and her friends some wine coolers for a dinner party she's going to.", "order": 13698, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 20, "scene": 55, "line": "You shouldn't be talking about that.", "order": 13699, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 20, "scene": 55, "line": "I threw in some scratch tickets, 'cause generous guy, and next thing you know we're making out in the woods.", "order": 13700, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 20, "scene": 55, "line": "Hypothetically. Hypothetically you were making out in the woods.", "order": 13701, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 20, "scene": 55, "line": "No, we were really making out in the woods.", "order": 13702, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 20, "scene": 55, "line": "No, can't help you now.", "order": 13703, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 20, "scene": 55, "line": "I gotta go talk to her.", "order": 13704, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 20, "scene": 55, "line": "No. D-", "order": 13705, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 20, "scene": 56, "line": "With dissatisfied clients, it doesn't matter what you did. All they wanna hear is that you're sorry. They're like women that way. And this client is a woman. So when I say I'm sorry, it will be twice as effective.", "order": 13706, "speaker": "Michael Scott"},
  {"season": 3, "episode": 20, "scene": 57, "line": "So we're good?", "order": 13707, "speaker": "Michael Scott"},
  {"season": 3, "episode": 20, "scene": 57, "line": "No, we're not.", "order": 13708, "speaker": "Barbara Allen"},
  {"season": 3, "episode": 20, "scene": 57, "line": "I'm sorry.", "order": 13709, "speaker": "Michael Scott"},
  {"season": 3, "episode": 20, "scene": 57, "line": "Well, okay. But I don't accept.", "order": 13710, "speaker": "Barbara Allen"},
  {"season": 3, "episode": 20, "scene": 57, "line": "[whispering] Say you're sorry times infinity...", "order": 13711, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 20, "scene": 57, "line": "Okay.", "order": 13712, "speaker": "Michael Scott"},
  {"season": 3, "episode": 20, "scene": 57, "line": "...'cause there's no comeback for that.", "order": 13713, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 20, "scene": 57, "line": "All right. We are infinitely sorry.", "order": 13714, "speaker": "Michael Scott"},
  {"season": 3, "episode": 20, "scene": 57, "line": "I'm still furious.", "order": 13715, "speaker": "Barbara Allen"},
  {"season": 3, "episode": 20, "scene": 57, "line": "You understand? You... you get this check.", "order": 13716, "speaker": "Michael Scott"},
  {"season": 3, "episode": 20, "scene": 57, "line": "Mmm.", "order": 13717, "speaker": "Barbara Allen"},
  {"season": 3, "episode": 20, "scene": 58, "line": "Can I help you?", "order": 13718, "speaker": "Spanish Teacher"},
  {"season": 3, "episode": 20, "scene": 58, "line": "Yeah. I'm Mr. Bernard Johnson, MD. I'd like to talk with Jamie about a certain heart condition. Diagnosis: broken.", "order": 13719, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 20, "scene": 58, "line": "Get out of here, Andy.", "order": 13720, "speaker": "Jamie"},
  {"season": 3, "episode": 20, "scene": 58, "line": "Who's this guy?", "order": 13721, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 20, "scene": 58, "line": "My boyfriend.", "order": 13722, "speaker": "Jamie"},
  {"season": 3, "episode": 20, "scene": 58, "line": "You're such a liar.", "order": 13723, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 20, "scene": 58, "line": "Sir? You need to get out of here, before I call the police, okay?", "order": 13724, "speaker": "Spanish Teacher"},
  {"season": 3, "episode": 20, "scene": 58, "line": "Yeah, maybe you should call the police. See whose side they take. Warning, most cops are dudes.", "order": 13725, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 20, "scene": 58, "line": "Dr. Johnson, we need you in surgery right away.", "order": 13726, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 20, "scene": 58, "line": "What?", "order": 13727, "speaker": "Spanish Teacher"},
  {"season": 3, "episode": 20, "scene": 59, "line": "I don't believe in apologies. When someone in the yakuza makes a mistake, they cut off a portion of their own pinky finger. And in that way, save face. And knowing the Japanese, they probably get an even better prosthetic finger. With a knife you can take out of it, or a screwdriver or a corkscrew.", "order": 13728, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 20, "scene": 60, "line": "Why am I getting all of the bad ones?", "order": 13729, "speaker": "Angela Martin"},
  {"season": 3, "episode": 20, "scene": 60, "line": "Why can't you just say you're sorry and make them feel better?", "order": 13730, "speaker": "Kelly Kapoor"},
  {"season": 3, "episode": 20, "scene": 60, "line": "I'm not gonna lie and say I'm sorry when the company didn't do anything wrong. That's immoral, and this is ridiculous.", "order": 13731, "speaker": "Angela Martin"},
  {"season": 3, "episode": 20, "scene": 60, "line": "Would you, please? Please.", "order": 13732, "speaker": "Oscar Martinez"},
  {"season": 3, "episode": 20, "scene": 60, "line": "Well, you have to do what I say, okay? Because Michael put me in charge, even though you're old enough to be my mother.", "order": 13733, "speaker": "Kelly Kapoor"},
  {"season": 3, "episode": 20, "scene": 60, "line": "What? Well what you say is stupid, and your job is pointless. And you're a slut and everyone thinks so.", "order": 13734, "speaker": "Angela Martin"},
  {"season": 3, "episode": 20, "scene": 60, "line": "Well, you're fired, okay? Go back to accounting, Angela.", "order": 13735, "speaker": "Kelly Kapoor"},
  {"season": 3, "episode": 20, "scene": 60, "line": "Gladly. I quit.", "order": 13736, "speaker": "Angela Martin"},
  {"season": 3, "episode": 20, "scene": 61, "line": "If cartoon characters didn't have sex, then how would we get new cartoon characters? From the sex! [giggles]", "order": 13737, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 20, "scene": 62, "line": "The customer is always right. Mrs. Allen was our customer. She was wrong. Is that a contradiction? No. Because she's not our customer anymore.", "order": 13738, "speaker": "Michael Scott"},
  {"season": 3, "episode": 20, "scene": 63, "line": "Michael likes me to run the camera when he makes his apology videos. My favorite is the one for his mother.", "order": 13739, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 20, "scene": 64, "line": "Hey, Mom. Happy birthday, belatedly. Sorry I forgot. I had put it on my calendar and Pam threw that out.", "order": 13740, "speaker": "Michael Scott"},
  {"season": 3, "episode": 20, "scene": 65, "line": "My personal favorite is the one he made for his condo association.", "order": 13741, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 20, "scene": 66, "line": "And without denying the seriousness of the situation, I would just like to remind you that in much of the Netherlands, swimming naked is the norm. So in Norway, you'd be the weirdos.", "order": 13742, "speaker": "Michael Scott"},
  {"season": 3, "episode": 20, "scene": 67, "line": "Okay, Andy, we need to go. Seriously.", "order": 13743, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 20, "scene": 67, "line": "Dude, there she is. Don't look, don't look. She's sitting with Denise. How am I supposed to do this? Denise hates me.", "order": 13744, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 20, "scene": 67, "line": "Don't do it.", "order": 13745, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 20, "scene": 67, "line": "What is your problem, man? Can't you support a bro? That's why you don't have any friends, Tuna. I'm sorry. I'm sorry. I just, I need...", "order": 13746, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 20, "scene": 67, "line": "That's okay.", "order": 13747, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 20, "scene": 67, "line": "...your help. I love her.", "order": 13748, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 20, "scene": 67, "line": "What?", "order": 13749, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 20, "scene": 67, "line": "Yeah. I lo- I love her. Is this what love is? This, like, horrible feeling in my stomach?", "order": 13750, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 20, "scene": 67, "line": "No, that's your conscience. Listen to it.", "order": 13751, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 20, "scene": 67, "line": "Look, okay, just be my wingman. Beer me five minutes. Talk to Denise. She's really into White Russians and whippets.", "order": 13752, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 20, "scene": 68, "line": "Jamie, can I talk to you for a second?", "order": 13753, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 20, "scene": 68, "line": "Yeah. I only have a minute.", "order": 13754, "speaker": "Jamie"},
  {"season": 3, "episode": 20, "scene": 68, "line": "[sits down next to Denise] So, high school.", "order": 13755, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 20, "scene": 68, "line": "Do you have a cigarette?", "order": 13756, "speaker": "Denise"},
  {"season": 3, "episode": 20, "scene": 68, "line": "Oh, I don't smoke. Sorry.", "order": 13757, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 20, "scene": 68, "line": "There's nothing to do in this town.", "order": 13758, "speaker": "Denise"},
  {"season": 3, "episode": 20, "scene": 68, "line": "You should take up a musical instrument.", "order": 13759, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 20, "scene": 68, "line": "Yeah, I should take up that. Jamie?", "order": 13760, "speaker": "Denise"},
  {"season": 3, "episode": 20, "scene": 68, "line": "Hey, uh, does Mr. Nortman still teach here? He's a jerk, right?", "order": 13761, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 20, "scene": 68, "line": "He's dead. Hey, do you have any weed?", "order": 13762, "speaker": "Denise"},
  {"season": 3, "episode": 20, "scene": 68, "line": "No, nope. What else? What else? You watch The Hills? What is Heidi thinking?", "order": 13763, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 20, "scene": 68, "line": "How old are you?", "order": 13764, "speaker": "Denise"},
  {"season": 3, "episode": 20, "scene": 68, "line": "How old do you think I am?", "order": 13765, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 20, "scene": 68, "line": "Forty?", "order": 13766, "speaker": "Denise"},
  {"season": 3, "episode": 20, "scene": 68, "line": "[shakes head]", "order": 13767, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 20, "scene": 68, "line": "Are you someone's dad here?", "order": 13768, "speaker": "Denise"},
  {"season": 3, "episode": 20, "scene": 68, "line": "Not that I know of.", "order": 13769, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 20, "scene": 68, "line": "Ew. What kind of car do you have?", "order": 13770, "speaker": "Denise"},
  {"season": 3, "episode": 20, "scene": 68, "line": "I drive a Saab station wagon.", "order": 13771, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 20, "scene": 68, "line": "My dad just gave me his old Lexus.", "order": 13772, "speaker": "Denise"},
  {"season": 3, "episode": 20, "scene": 68, "line": "Really? Way to earn it. Hey, Andy?", "order": 13773, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 20, "scene": 68, "line": "What?", "order": 13774, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 20, "scene": 68, "line": "Yup.", "order": 13775, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 20, "scene": 69, "line": "Very well [hangs up phone]. I just got a call from Debbie Brown over at the mill. She claims innocence and is willing to come over here and defend herself.", "order": 13776, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 20, "scene": 69, "line": "No time. Dwight, I love Debbie Brown. She's a close personal friend. But quality is my life. This company has a reputation to uphold. As much as it pains me, someone has to be held accountable.", "order": 13777, "speaker": "Creed Bratton"},
  {"season": 3, "episode": 20, "scene": 69, "line": "You're right. Mercy is for girl-babies. Time to put this dog down.", "order": 13778, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 20, "scene": 70, "line": "Are we ready?", "order": 13779, "speaker": "Michael Scott"},
  {"season": 3, "episode": 20, "scene": 70, "line": "Final touch-ups.", "order": 13780, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 20, "scene": 70, "line": "Okay. Am I shiny?", "order": 13781, "speaker": "Michael Scott"},
  {"season": 3, "episode": 20, "scene": 70, "line": "Yeah.", "order": 13782, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 20, "scene": 70, "line": "Okay. We good? Okay, that's enough Aqua Net.", "order": 13783, "speaker": "Michael Scott"},
  {"season": 3, "episode": 20, "scene": 70, "line": "I don't know...", "order": 13784, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 20, "scene": 70, "line": "[clears throat] Stop it.", "order": 13785, "speaker": "Michael Scott"},
  {"season": 3, "episode": 20, "scene": 70, "line": "[coughs]", "order": 13786, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 20, "scene": 71, "line": "Hey, Angela. I'm sorry that I said you were old enough to be my mother. I don't know why I said that. I think I might be hypoglycemic or something, but still, it's no excuse. So, I'm very sorry.", "order": 13787, "speaker": "Kelly Kapoor"},
  {"season": 3, "episode": 20, "scene": 71, "line": "Oh. Thank you, Kelly [long pause]. And I wish I hadn't called you stupid.", "order": 13788, "speaker": "Angela Martin"},
  {"season": 3, "episode": 20, "scene": 71, "line": "Do you think I'm a slut?", "order": 13789, "speaker": "Kelly Kapoor"},
  {"season": 3, "episode": 20, "scene": 71, "line": "No, but some of your outfits are not appr-", "order": 13790, "speaker": "Angela Martin"},
  {"season": 3, "episode": 20, "scene": 71, "line": "Apology accepted! [hugs Angela]", "order": 13791, "speaker": "Kelly Kapoor"},
  {"season": 3, "episode": 20, "scene": 71, "line": "I didn't apologize.", "order": 13792, "speaker": "Angela Martin"},
  {"season": 3, "episode": 20, "scene": 71, "line": "We're like best friends now!", "order": 13793, "speaker": "Kelly Kapoor"},
  {"season": 3, "episode": 20, "scene": 71, "line": "We're not.", "order": 13794, "speaker": "Angela Martin"},
  {"season": 3, "episode": 20, "scene": 71, "line": "I'll text you!", "order": 13795, "speaker": "Kelly Kapoor"},
  {"season": 3, "episode": 20, "scene": 71, "line": "Don't!", "order": 13796, "speaker": "Angela Martin"},
  {"season": 3, "episode": 20, "scene": 71, "line": "Bye, Angela!", "order": 13797, "speaker": "Kelly Kapoor"},
  {"season": 3, "episode": 20, "scene": 71, "line": "Fine.", "order": 13798, "speaker": "Angela Martin"},
  {"season": 3, "episode": 20, "scene": 72, "line": "That is all I was looking for today. She got something out of my training session. I mean, she will never work in customer relations. Oh, my God, no. Never. But maybe she'll be less of a grump.", "order": 13799, "speaker": "Kelly Kapoor"},
  {"season": 3, "episode": 20, "scene": 73, "line": "Kelly's not so bad. She really taught me something today [rolls eyes].", "order": 13800, "speaker": "Angela Martin"},
  {"season": 3, "episode": 20, "scene": 74, "line": "I have literally apologized an infinite number of times on this, and there are still calls for me to resign. Calls from an annoying woman, and possibly even the media alike. Well let me tell you something, something from the heart. I will not resign. I need this job. My mortgage is hundreds of dollars a month. With this job, I can barely cover that. I have a company car, but I still have to pay for gas, and gas prices are high. And I have no savings whatsoever. And it wasn't even me. It's so not fair that they want me to resign.", "order": 13801, "speaker": "Michael Scott"},
  {"season": 3, "episode": 20, "scene": 75, "line": "...to the fullest extent of the law [hangs up phone]. Listen up, everyone! I just got off the phone with the mill. Final responsibility for Watermark-gate has been assigned. Floor manager Debbie Brown has officially been terminated. Lets give a big hand to Creed Bratton, without whose earnest and diligent sleuthing this scandal might never have been resolved [claps].", "order": 13802, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 20, "scene": 75, "line": "Just doing my job, guys.", "order": 13803, "speaker": "Creed Bratton"},
  {"season": 3, "episode": 21, "scene": 1, "line": "[to Pam] Hey.", "order": 13804, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 21, "scene": 1, "line": "[Dwight hands Jim a piece of paper] Oh, what's this?", "order": 13805, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 21, "scene": 1, "line": "That is a demerit.", "order": 13806, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 21, "scene": 1, "line": "[reads demerit] 'Jim Halpert, tardiness.' Ugh. I love it already.", "order": 13807, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 21, "scene": 1, "line": "You've gotta learn, Jim. You are second in command, but that does not put you above the law.", "order": 13808, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 21, "scene": 1, "line": "Oh, I understand. And I also have lots of questions, like, what does a demerit mean?", "order": 13809, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 21, "scene": 1, "line": "[scoffs] Let's put it this way. You do not want to receive three of those.", "order": 13810, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 21, "scene": 1, "line": "Lay it on me.", "order": 13811, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 21, "scene": 1, "line": "Three demerits and you'll receive a citation.", "order": 13812, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 21, "scene": 1, "line": "Now that sounds serious.", "order": 13813, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 21, "scene": 1, "line": "Oh, it is serious. Five citations and you're looking at a violation. Four of those and you'll receive a verbal warning. Keep it up, and you're looking at a written warning. Two of those, that'll land you in a world of hurt... in the form of a disciplinary review written up by me and placed on the desk of my immediate superior.", "order": 13814, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 21, "scene": 1, "line": "Which would be me.", "order": 13815, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 21, "scene": 1, "line": "That is correct.", "order": 13816, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 21, "scene": 1, "line": "OK, I want a copy on my desk by the end of the day or you will receive a full disadulation.", "order": 13817, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 21, "scene": 1, "line": "What's a dis... what's that?", "order": 13818, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 21, "scene": 1, "line": "Oh, you don't want to know.", "order": 13819, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 21, "scene": 2, "line": "[door opens] Hey, Phyllis. You all right?", "order": 13820, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 21, "scene": 2, "line": "I think I just got flashed.", "order": 13821, "speaker": "Phyllis Vance"},
  {"season": 3, "episode": 21, "scene": 2, "line": "What? Really?", "order": 13822, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 21, "scene": 2, "line": "In the parking lot.", "order": 13823, "speaker": "Phyllis Vance"},
  {"season": 3, "episode": 21, "scene": 2, "line": "Oh, my God.", "order": 13824, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 21, "scene": 2, "line": "[jumps out of his chair and runs for the door] Move!", "order": 13825, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 21, "scene": 2, "line": "OK, I'll call the", "order": 13826, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 21, "scene": 2, "line": "What happened? What can I do to help?", "order": 13827, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 21, "scene": 2, "line": "[on the phone] OK.", "order": 13828, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 21, "scene": 2, "line": "I'll check the web.", "order": 13829, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 21, "scene": 2, "line": "[on the phone] Thank you. [hangs up the phone] The police are on it. They say they've already had three calls.", "order": 13830, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 21, "scene": 2, "line": "[to Phyllis] Can you tell us what happened?", "order": 13831, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 21, "scene": 2, "line": "Um... I was walking to the building and this man asked me for directions. And he was holding a map. And when I walked over, he had it out... on the map.", "order": 13832, "speaker": "Phyllis Vance"},
  {"season": 3, "episode": 21, "scene": 2, "line": "Phyllis. You're a married woman.", "order": 13833, "speaker": "Angela Martin"},
  {"season": 3, "episode": 21, "scene": 2, "line": "The guy was just hanging brain. I mean, what's all the fuss?", "order": 13834, "speaker": "Creed Bratton"},
  {"season": 3, "episode": 21, "scene": 3, "line": "If that's flashing, then lock me up.", "order": 13835, "speaker": "Creed Bratton"},
  {"season": 3, "episode": 21, "scene": 4, "line": "[whispering] It's just, like, so creepy.", "order": 13836, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 21, "scene": 4, "line": "[whispering] Yeah. [Pam and Ryan whispering]", "order": 13837, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 21, "scene": 4, "line": "What's happening?", "order": 13838, "speaker": "Michael Scott"},
  {"season": 3, "episode": 21, "scene": 4, "line": "Oh, some guy exposed himself to Phyllis in the parking lot.", "order": 13839, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 21, "scene": 4, "line": "Really? Is she OK?", "order": 13840, "speaker": "Michael Scott"},
  {"season": 3, "episode": 21, "scene": 4, "line": "Yeah, Bob Vance took her for a walk to calm down.", "order": 13841, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 21, "scene": 4, "line": "OK. [deep breath] Phyllis, you say? [snorts] Hmm. [suppressed laugher]", "order": 13842, "speaker": "Michael Scott"},
  {"season": 3, "episode": 21, "scene": 4, "line": "What is so funny?", "order": 13843, "speaker": "Angela Martin"},
  {"season": 3, "episode": 21, "scene": 4, "line": "Um... I mean did he even see Pam? Or, uh... Karen from behind?", "order": 13844, "speaker": "Michael Scott"},
  {"season": 3, "episode": 21, "scene": 4, "line": "I'm guessing not.", "order": 13845, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 21, "scene": 4, "line": "[laughing softly] I'm sorry. It's pretty funny when you think about it.", "order": 13846, "speaker": "Michael Scott"},
  {"season": 3, "episode": 21, "scene": 4, "line": "Mm... not really, no.", "order": 13847, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 21, "scene": 4, "line": "It's disgusting and demeaning.", "order": 13848, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 21, "scene": 4, "line": "Oh, OK. Masters of comedy. A guy dropped his pants. Have you ever been to the circus? [everyone glares] OK. [Michael puts his finger through his pant's zipper] He's back! [laughs] OK. Hmm. [babbling with his coat pulled closed] Waagh! [flashes everyone]", "order": 13849, "speaker": "Michael Scott"},
  {"season": 3, "episode": 21, "scene": 4, "line": "Hey, what's going on? There's a police car in the...", "order": 13850, "speaker": "Toby Flenderson"},
  {"season": 3, "episode": 21, "scene": 4, "line": "What? Oh. [makes descending scale noise as his finger goes down]", "order": 13851, "speaker": "Michael Scott"},
  {"season": 3, "episode": 21, "scene": 4, "line": "What's going on?", "order": 13852, "speaker": "Toby Flenderson"},
  {"season": 3, "episode": 21, "scene": 4, "line": "Oh, Phyllis got flashed. It's, uh... [laughs]", "order": 13853, "speaker": "Michael Scott"},
  {"season": 3, "episode": 21, "scene": 4, "line": "I don't think laughing about it is an appropriate response.", "order": 13854, "speaker": "Toby Flenderson"},
  {"season": 3, "episode": 21, "scene": 4, "line": "Oh, come on. We are laughing at Phyllis, but she's not even here, so no harm, no foul.", "order": 13855, "speaker": "Michael Scott"},
  {"season": 3, "episode": 21, "scene": 4, "line": "I don't think the women in this office -", "order": 13856, "speaker": "Toby Flenderson"},
  {"season": 3, "episode": 21, "scene": 4, "line": "Incidentally, where were you during all of this? Maybe you're the flasher.", "order": 13857, "speaker": "Michael Scott"},
  {"season": 3, "episode": 21, "scene": 4, "line": "I was at a parent-teacher conference.", "order": 13858, "speaker": "Toby Flenderson"},
  {"season": 3, "episode": 21, "scene": 4, "line": "Uh-huh. Prove it. Let's see your penis. [everyone is shocked] [exhales]I... you know, as that was coming out of my mouth, I knew that it was wrong.", "order": 13859, "speaker": "Michael Scott"},
  {"season": 3, "episode": 21, "scene": 5, "line": "In all the excitement, I forgot that my primary goal is to keep people safe. Women can't have fun if they don't feel safe. For example, Jan and I have a safe word in case things go too far. Foliage. And if one of us says that word, the other one has to stop. Although last time... she pretended she didn't hear me.", "order": 13860, "speaker": "Michael Scott"},
  {"season": 3, "episode": 21, "scene": 6, "line": "[on speakerphone] Michael, ...", "order": 13861, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 21, "scene": 6, "line": "Huh?", "order": 13862, "speaker": "Michael Scott"},
  {"season": 3, "episode": 21, "scene": 6, "line": "...come over after work tonight, OK? I miss your body.", "order": 13863, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 21, "scene": 6, "line": "I don't know. I feel... I drive a lot. I'm spending a fortune on gas and tolls -", "order": 13864, "speaker": "Michael Scott"},
  {"season": 3, "episode": 21, "scene": 6, "line": "I'll give you $200. And if I get up before you, I'll leave it on the dresser.", "order": 13865, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 21, "scene": 6, "line": "Um, that... I don't know. That makes me kind of uncomfortable.", "order": 13866, "speaker": "Michael Scott"},
  {"season": 3, "episode": 21, "scene": 6, "line": "$300?", "order": 13867, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 21, "scene": 6, "line": "I... uh, well, I don't know.", "order": 13868, "speaker": "Michael Scott"},
  {"season": 3, "episode": 21, "scene": 6, "line": "You know whatever. Just let my assistant know if you're coming over so he can get more vodka, OK? Hunter, are you on?", "order": 13869, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 21, "scene": 6, "line": "[on speakerphone] You got it, Jan.", "order": 13870, "speaker": "Hunter"},
  {"season": 3, "episode": 21, "scene": 7, "line": "Employees of this office are very small and delicate. Deserve protection from local pervs. Better 1,000 innocent men are locked up than one guilty man roam free.", "order": 13871, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 21, "scene": 8, "line": "[exhales] I am sick over this thing. Those people out there are clearly afraid. And that can't happen. Not in my house.", "order": 13872, "speaker": "Michael Scott"},
  {"season": 3, "episode": 21, "scene": 8, "line": "Agreed. Let me show you what I've been working on.", "order": 13873, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 21, "scene": 8, "line": "OK.", "order": 13874, "speaker": "Michael Scott"},
  {"season": 3, "episode": 21, "scene": 8, "line": "[lays a folder full of pictures on Michael's desk] There are several penises there I'd love Phyllis to run her eyes over. You know, see if we can catch this pervert.", "order": 13875, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 21, "scene": 8, "line": "This is the last", "order": 13876, "speaker": "Michael Scott"},
  {"season": 3, "episode": 21, "scene": 8, "line": "Look at that one.", "order": 13877, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 21, "scene": 8, "line": "Dwight, are those your pants? That's a Polaroid. [Dwight takes the Polaroid and crumples it in his hand]", "order": 13878, "speaker": "Michael Scott"},
  {"season": 3, "episode": 21, "scene": 9, "line": "Attention, everybody. Dwight has something he would like to say.", "order": 13879, "speaker": "Michael Scott"},
  {"season": 3, "episode": 21, "scene": 9, "line": "Due to a recent incident involving Phyllis, a man, a map, and his penis - I think you know what I'm referring to - Michael has authorized me to form an emergency anti-flashing task force.", "order": 13880, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 21, "scene": 9, "line": "[raises hand] Question. Won't that interfere with your other task forces?", "order": 13881, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 21, "scene": 9, "line": "Answer: No, because this is being given priority one. This is a petition for the business park to upgrade their security cameras as well as install two floodlights in the parking lot. And I know... I know what you're thinking. [Pam nods] Won't that just shed more light on the penises? But that is a risk we have to take. Pam. You can draw, kind of. Why don't you work with Phallus on drawing a picture of the exposer that I can post around the community?", "order": 13882, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 21, "scene": 9, "line": "Phallus?", "order": 13883, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 21, "scene": 9, "line": "Phyllis, sorry. I've got penises on the brain. Back to work, everybody.", "order": 13884, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 21, "scene": 10, "line": "I don't often miss Roy. But I can tell you one thing. I wish someone had flashed me when I was with Roy. Because that would have been the ass-kicking of the year. [small laugh] Especially if it had been Jim. He would not have wanted me to have seen Jim's... Whoo, I am... I am saying a lot of things.", "order": 13885, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 21, "scene": 11, "line": "I didn't really get a good look.", "order": 13886, "speaker": "Phyllis Vance"},
  {"season": 3, "episode": 21, "scene": 11, "line": "That's OK. I don't feel like answering phones.", "order": 13887, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 21, "scene": 11, "line": "Hey, did you guys see this memo that Dwight sent out? [reads memo] 'Women will be sent home if they wear makeup or heels exceeding 1/4 inch. Females are not allowed to speak to strangers unless given written authorization by Dwight Schrute.' This is ridiculous.", "order": 13888, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 21, "scene": 11, "line": "Attention. I am removing all bananas from the kitchen.", "order": 13889, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 21, "scene": 11, "line": "Dwight, this memo that you distributed is insulting.", "order": 13890, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 21, "scene": 11, "line": "Desperate times call for desperate measures.", "order": 13891, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 21, "scene": 11, "line": "[reads memo] 'Sleeves down to the wrists, buttoned-up collars, and muted colors.' Nobody dress like that. [camera pans over to Angela]", "order": 13892, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 21, "scene": 11, "line": "OK, you know something, Dwight? We are not the terrorists. Why don't you just take these women, put 'em in a burlap sack, and hit 'em with a stick? Because that's what you're doing. I celebrate these women. They deserve the right to dress as they please. If Pam wants to show more cleavage, she should be able to. I encourage that.", "order": 13893, "speaker": "Michael Scott"},
  {"season": 3, "episode": 21, "scene": 11, "line": "Look, it's really simple. We just want you guys to treat us with respect.", "order": 13894, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 21, "scene": 11, "line": "See? That's what we're talking about. Did you hear that, Dwight?", "order": 13895, "speaker": "Michael Scott"},
  {"season": 3, "episode": 21, "scene": 11, "line": "Yes. Did you hear that, Michael?", "order": 13896, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 21, "scene": 11, "line": "No, Dwight. Respect. R-E-S-P-svee-T. Find out what it means to me. All right, you know what? That's it. Conference room, five minutes. Women's appreciation.", "order": 13897, "speaker": "Michael Scott"},
  {"season": 3, "episode": 21, "scene": 11, "line": "Wait a second, how are you qualified for that?", "order": 13898, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 21, "scene": 11, "line": "Oh, I don't know, James. Did I come from a woman? Have I slept with a woman? More than one?", "order": 13899, "speaker": "Michael Scott"},
  {"season": 3, "episode": 21, "scene": 11, "line": "[eating banana] Mm, less than three.", "order": 13900, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 21, "scene": 11, "line": "That is not current.", "order": 13901, "speaker": "Michael Scott"},
  {"season": 3, "episode": 21, "scene": 11, "line": "You know what? Why doesn't Oscar run the meeting? He's a homosexual.", "order": 13902, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 21, "scene": 11, "line": "Why don't you run the meeting? You play with dolls.", "order": 13903, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 21, "scene": 11, "line": "Those are collectible action figures and they're worth more than your car.", "order": 13904, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 21, "scene": 11, "line": "You know what? I am the expert. I will conduct it. I know the crap out of women.", "order": 13905, "speaker": "Michael Scott"},
  {"season": 3, "episode": 21, "scene": 12, "line": "I, um... would like to apologize for all of the men who thought this was a laughing matter.", "order": 13906, "speaker": "Michael Scott"},
  {"season": 3, "episode": 21, "scene": 12, "line": "Are we still discussing this? I say again, what is the big deal?", "order": 13907, "speaker": "Creed Bratton"},
  {"season": 3, "episode": 21, "scene": 12, "line": "Nobody likes to be flashed. When Meredith flashed me at that Christmas party, I nearly vomited.", "order": 13908, "speaker": "Michael Scott"},
  {"season": 3, "episode": 21, "scene": 12, "line": "I don't remember doing that.", "order": 13909, "speaker": "Meredith Palmer"},
  {"season": 3, "episode": 21, "scene": 12, "line": "What a surprise.", "order": 13910, "speaker": "Angela Martin"},
  {"season": 3, "episode": 21, "scene": 12, "line": "OK, no catfights. Please. Let's - my point is... my point is... a penis, when seen in the right context, is the most wonderful sight for a woman. But in the wrong context, it is like a monster movie.", "order": 13911, "speaker": "Michael Scott"},
  {"season": 3, "episode": 21, "scene": 12, "line": "Alien", "order": 13912, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 21, "scene": 12, "line": "What are...? Shut it. Shut. Up. OK, so what I want to engage us in today is a hardcore discussion about women's problems and issues and situations. Magazines and TV shows and movies portray women as skinny, tall goddesses. Well, look around. Are women like that? No. No, they are not. [points to Pam] Even the hot ones aren't really that skinny. So what does that say? That says that you women are up against it. And it is criminal. Society doesn't care. Society sucks. I don't even consider myself a part of society. FYI. Because I am so angry over all of this.", "order": 13913, "speaker": "Michael Scott"},
  {"season": 3, "episode": 21, "scene": 12, "line": "If it were up to me, you ladies would be the fashion models.", "order": 13914, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 21, "scene": 12, "line": "Yes, Andy. Then the fashion models could come here and work with me.", "order": 13915, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 21, "scene": 12, "line": "What you're saying is extremely misogynistic.", "order": 13916, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 21, "scene": 12, "line": "Yes. Thank you. That was not necessary, but I appreciated it. And I proves my point. Women can do anything.", "order": 13917, "speaker": "Michael Scott"},
  {"season": 3, "episode": 21, "scene": 12, "line": "I'm saying that you're being sexist.", "order": 13918, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 21, "scene": 12, "line": "No. I'm being misogynistic. That is insane. I am not being sexist.", "order": 13919, "speaker": "Michael Scott"},
  {"season": 3, "episode": 21, "scene": 12, "line": "That - it's the same thing.", "order": 13920, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 21, "scene": 12, "line": "Michael.", "order": 13921, "speaker": "Phyllis Vance"},
  {"season": 3, "episode": 21, "scene": 12, "line": "Yes.", "order": 13922, "speaker": "Michael Scott"},
  {"season": 3, "episode": 21, "scene": 12, "line": "When I got my hair cut short, you asked me if I was a lesbian.", "order": 13923, "speaker": "Phyllis Vance"},
  {"season": 3, "episode": 21, "scene": 12, "line": "Because wha... that was one possible explanation as to why you got that haircut.", "order": 13924, "speaker": "Michael Scott"},
  {"season": 3, "episode": 21, "scene": 12, "line": "And when we get mad, you always ask us if we're on our periods.", "order": 13925, "speaker": "Angela Martin"},
  {"season": 3, "episode": 21, "scene": 12, "line": "I have to know whether you're serious or not.", "order": 13926, "speaker": "Michael Scott"},
  {"season": 3, "episode": 21, "scene": 12, "line": "I wish I could menstruate.", "order": 13927, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 21, "scene": 13, "line": "If I could menstruate, I wouldn't have to deal with idiotic calendars anymore. I'd just be able to count down from my previous cycle. Plus, I'd be more in tune with the moon and the tides.", "order": 13928, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 21, "scene": 14, "line": "Can we just get back to work?", "order": 13929, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 21, "scene": 14, "line": "Ye - OK, yes.", "order": 13930, "speaker": "Michael Scott"},
  {"season": 3, "episode": 21, "scene": 14, "line": "This is not work talk.", "order": 13931, "speaker": "Angela Martin"},
  {"season": 3, "episode": 21, "scene": 14, "line": "You're right. You're right, you're right. And you know why? It's because of where we are. This is a masculine environment. We need to find a place where you feel comfortable. You know where we're gonna go? The Steamtown Mall. [Kelly gasps]", "order": 13932, "speaker": "Michael Scott"},
  {"season": 3, "episode": 21, "scene": 15, "line": "Frankly, it's kind of insulting. But I have a bunch of stuff I need to return in my car. So... I could do that.", "order": 13933, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 21, "scene": 16, "line": "Malls are just awful and humiliating. They're just store after store of these horrible salespeople making a big fuss out of an adult shopping in a junior's section. There are petite adults who are sort of... smaller who need to wear... maybe a kids' size 10.", "order": 13934, "speaker": "Angela Martin"},
  {"season": 3, "episode": 21, "scene": 17, "line": "OK, let's go, ladies of Dunder-Mifflin. Hey, we should have a calendar printed up. Pam, put that in my good idea folder. [Pam nods] Let's go!", "order": 13935, "speaker": "Michael Scott"},
  {"season": 3, "episode": 21, "scene": 17, "line": "Have you finished with the sketch?", "order": 13936, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 21, "scene": 17, "line": "Yeah.", "order": 13937, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 21, "scene": 17, "line": "Hmm, doesn't seem like the type.", "order": 13938, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 21, "scene": 17, "line": "Uh, Phyllis got a good look.", "order": 13939, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 21, "scene": 17, "line": "Hmm.", "order": 13940, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 21, "scene": 18, "line": "[Pam's sketch looks like Dwight with a mustache, but without his glasses] I plan on plastering this pervert's face everywhere. You can run, but you cannot hide.", "order": 13941, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 21, "scene": 19, "line": "[tires screeching] Oh.", "order": 13942, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 21, "scene": 19, "line": "Meredith, slow down! We're not gonna get there any faster if we're dead.", "order": 13943, "speaker": "Angela Martin"},
  {"season": 3, "episode": 21, "scene": 19, "line": "Thanks. I know how to drive. [dumps the crumbs from a bag of chips she was eating into her mouth and throws it out the window]", "order": 13944, "speaker": "Meredith Palmer"},
  {"season": 3, "episode": 21, "scene": 19, "line": "Oh, yeah. You really shouldn't litter.", "order": 13945, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 21, "scene": 19, "line": "My car, my rules.", "order": 13946, "speaker": "Meredith Palmer"},
  {"season": 3, "episode": 21, "scene": 20, "line": "Hey, Jim. You wanna go in the women's bathroom?", "order": 13947, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 21, "scene": 20, "line": "No. Thank you, though.", "order": 13948, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 21, "scene": 20, "line": "You aren't curious?", "order": 13949, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 21, "scene": 20, "line": "Not really. I've seen a bathroom before.", "order": 13950, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 21, "scene": 20, "line": "Yeah, but... it's every guy's fantasy.", "order": 13951, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 21, "scene": 20, "line": "I think you mean a girl's", "order": 13952, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 21, "scene": 20, "line": "[quietly] Yeah. I'm going in.", "order": 13953, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 21, "scene": 20, "line": "Go crazy.", "order": 13954, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 21, "scene": 20, "line": "[in women's bathroom] Oh... my... God.", "order": 13955, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 21, "scene": 21, "line": "I really appreciate your letting me work alongside you so closely today.", "order": 13956, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 21, "scene": 21, "line": "Of course you do, moon face. That's because you're a preppy freak, you're the office pariah, and nobody likes you. So start hanging these all around the building.", "order": 13957, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 21, "scene": 21, "line": "This guy looks like a real deviant.", "order": 13958, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 21, "scene": 21, "line": "No, duh. That's why we gotta catch him. Start hanging those.", "order": 13959, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 21, "scene": 21, "line": "Aye, aye, Cap'n.", "order": 13960, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 21, "scene": 21, "line": "More like, 'Aye, aye, General.'", "order": 13961, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 21, "scene": 22, "line": "[Meredith parking the car] I don't think she's gonna make it. Don't think she's gonna make it - [metal scraping]", "order": 13962, "speaker": "Michael Scott"},
  {"season": 3, "episode": 21, "scene": 22, "line": "It's a little too tight. I'm gonna find another spot.", "order": 13963, "speaker": "Meredith Palmer"},
  {"season": 3, "episode": 21, "scene": 22, "line": "Many women", "order": 13964, "speaker": "Michael Scott"},
  {"season": 3, "episode": 21, "scene": 23, "line": "[marks Scranton with a red pushpin on a map of Pennsylvania and exhales] This is what we", "order": 13965, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 21, "scene": 24, "line": "[in women's bathroom] Well, I stand corrected. This is pretty cool.", "order": 13966, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 21, "scene": 24, "line": "Yes.", "order": 13967, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 21, "scene": 24, "line": "Hey, uh... where'd you decide to take Karen tonight?", "order": 13968, "speaker": "Toby Flenderson"},
  {"season": 3, "episode": 21, "scene": 24, "line": "Anna Maria's.", "order": 13969, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 21, "scene": 24, "line": "What's the occasion?", "order": 13970, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 21, "scene": 24, "line": "Six-month anniversary. What?", "order": 13971, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 21, "scene": 24, "line": "Nothing - I think, uh, we all kinda thought you guys were just, like, hooking up.", "order": 13972, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 21, "scene": 24, "line": "No, we've been dating for six months.", "order": 13973, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 21, "scene": 24, "line": "Uh, she might mention an email that I wrote a while back, um -", "order": 13974, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 21, "scene": 24, "line": "Oh, right. I remember that one. She read it to me. She said she's not really ready to date somebody in the office, but she really likes you as a friend.", "order": 13975, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 21, "scene": 24, "line": "I figured. It's cool. I don't - I wouldn't want to be in an office relationship anyway.", "order": 13976, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 21, "scene": 25, "line": "All right. Hope nobody's on a diet.", "order": 13977, "speaker": "Michael Scott"},
  {"season": 3, "episode": 21, "scene": 25, "line": "Thanks, Michael.", "order": 13978, "speaker": "Kelly Kapoor"},
  {"season": 3, "episode": 21, "scene": 25, "line": "Thank you, Michael.", "order": 13979, "speaker": "Angela Martin"},
  {"season": 3, "episode": 21, "scene": 25, "line": "You're welcome. You're welcome, you're welcome. OK. So, let's dish.", "order": 13980, "speaker": "Michael Scott"},
  {"season": 3, "episode": 21, "scene": 25, "line": "What do you want to dish about?", "order": 13981, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 21, "scene": 25, "line": "Anything you guys want. This is your time. [everyone is silent or continues to eat, Michael exhales] Mm. What is a Pap smear? Or is it 'shmear?' Like the cream cheese.", "order": 13982, "speaker": "Michael Scott"},
  {"season": 3, "episode": 21, "scene": 25, "line": "OK. New topic. Kelly, how are things with Ryan?", "order": 13983, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 21, "scene": 25, "line": "Awesome. Um, awful, I mean. But, uh, sometimes awesome.", "order": 13984, "speaker": "Kelly Kapoor"},
  {"season": 3, "episode": 21, "scene": 25, "line": "What, um... what do you think of role-play?", "order": 13985, "speaker": "Michael Scott"},
  {"season": 3, "episode": 21, "scene": 25, "line": "Oh, it can be fun.", "order": 13986, "speaker": "Phyllis Vance"},
  {"season": 3, "episode": 21, "scene": 25, "line": "Yeah? Well, Jan has this schoolgirl fantasy.", "order": 13987, "speaker": "Michael Scott"},
  {"season": 3, "episode": 21, "scene": 25, "line": "It's a pretty common one.", "order": 13988, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 21, "scene": 25, "line": "I just... I feel uncomfortable wearing the dress.", "order": 13989, "speaker": "Michael Scott"},
  {"season": 3, "episode": 21, "scene": 25, "line": "OK. I'm gonna be at the doll store.", "order": 13990, "speaker": "Angela Martin"},
  {"season": 3, "episode": 21, "scene": 26, "line": "Sometimes, the clothes at GapKids are just too flashy. So I'm forced to go to the American Girl store and order clothes for large colonial dolls.", "order": 13991, "speaker": "Angela Martin"},
  {"season": 3, "episode": 21, "scene": 27, "line": "Michael, you shouldn't do anything that you're uncomfortable with.", "order": 13992, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 21, "scene": 27, "line": "Jan says anything that doesn't scare us is not worth doing. I don't know. Maybe we're different people. I like cuddling and spooning and she likes videotaping us during sex.", "order": 13993, "speaker": "Michael Scott"},
  {"season": 3, "episode": 21, "scene": 27, "line": "Oh, my God.", "order": 13994, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 21, "scene": 27, "line": "[groans] And then watching it back right afterward to improve my form.", "order": 13995, "speaker": "Michael Scott"},
  {"season": 3, "episode": 21, "scene": 27, "line": "That is not healthy behavior.", "order": 13996, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 21, "scene": 27, "line": "No, it's not that bad. The worst part is that she shows it to her therapist and they discuss it.", "order": 13997, "speaker": "Michael Scott"},
  {"season": 3, "episode": 21, "scene": 27, "line": "Michael, you need to get out of this.", "order": 13998, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 21, "scene": 27, "line": "No, she's... she's fooling around. It's a woman thing.", "order": 13999, "speaker": "Michael Scott"},
  {"season": 3, "episode": 21, "scene": 27, "line": "No, normal women don't do stuff like that. This is bad. [Karen nods and Michael looks like he's about to start crying]", "order": 14000, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 21, "scene": 27, "line": "No... No, it's all right. I'm OK. I'm OK. [sniffing] You guys... what are we gonna do about Jan? [sighs]", "order": 14001, "speaker": "Michael Scott"},
  {"season": 3, "episode": 21, "scene": 28, "line": "Read the pros first.", "order": 14002, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 21, "scene": 28, "line": "OK. Jan is smart. Uh, successful. Good clothes. Hot. Perfect skin. Nice butt.", "order": 14003, "speaker": "Michael Scott"},
  {"season": 3, "episode": 21, "scene": 28, "line": "She does have very nice clothes.", "order": 14004, "speaker": "Phyllis Vance"},
  {"season": 3, "episode": 21, "scene": 28, "line": "OK, OK. Um, cons.", "order": 14005, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 21, "scene": 28, "line": "Cons. Wears too much makeup. Breasts: not anything to write home about. Insecure about body. I'm unhappy when I'm with her. Flat-chested.", "order": 14006, "speaker": "Michael Scott"},
  {"season": 3, "episode": 21, "scene": 28, "line": "What was the last one?", "order": 14007, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 21, "scene": 28, "line": "She's totally flat. Shrunken chesticles.", "order": 14008, "speaker": "Michael Scott"},
  {"season": 3, "episode": 21, "scene": 28, "line": "No, the one before that.", "order": 14009, "speaker": "Phyllis Vance"},
  {"season": 3, "episode": 21, "scene": 28, "line": "I'm unhappy when I'm with her.", "order": 14010, "speaker": "Michael Scott"},
  {"season": 3, "episode": 21, "scene": 28, "line": "Michael... you shouldn't be with someone who doesn't make you happy.", "order": 14011, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 21, "scene": 28, "line": "I'm happy sometimes. Um... when we scrapbook or right towards the end of having sex.", "order": 14012, "speaker": "Michael Scott"},
  {"season": 3, "episode": 21, "scene": 28, "line": "Look, most relationships have their rough patches. You just have to push through it sometimes.", "order": 14013, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 21, "scene": 28, "line": "Yeah, that's smart.", "order": 14014, "speaker": "Michael Scott"},
  {"season": 3, "episode": 21, "scene": 28, "line": "Maybe. But it sounds like you're just wrong for each other.", "order": 14015, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 21, "scene": 28, "line": "That sounds good too. I don't know who's right. I just don't - I don't know. I don't know.", "order": 14016, "speaker": "Michael Scott"},
  {"season": 3, "episode": 21, "scene": 28, "line": "I bet you know. Don't think, just answer. What do you want to do about Jan?", "order": 14017, "speaker": "Phyllis Vance"},
  {"season": 3, "episode": 21, "scene": 28, "line": "I wanna break up with Jan. Wow. I wanna break up with Jan.", "order": 14018, "speaker": "Michael Scott"},
  {"season": 3, "episode": 21, "scene": 28, "line": "My mom taught me that.", "order": 14019, "speaker": "Phyllis Vance"},
  {"season": 3, "episode": 21, "scene": 29, "line": "Wow, I cannot believe this yogurt has no calories.", "order": 14020, "speaker": "Michael Scott"},
  {"season": 3, "episode": 21, "scene": 29, "line": "No one said it has no calories.", "order": 14021, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 21, "scene": 29, "line": "Oh, hey, guys. I want to do something nice for you, because you did something so nice for me earlier. I want you to go in there. I want you to buy one item on me as a thank-you. [Kelly sprints inside Victoria's Secret] Come on. Get in here.", "order": 14022, "speaker": "Michael Scott"},
  {"season": 3, "episode": 21, "scene": 30, "line": "Let's face it, most guys are from the Dark Ages. They're caveman. And they like a woman to be showing her cleavage and to be wearing 8-inch heels, and to be wearing, um, see-through underpants. But... for me, a woman looks best when she is just absolutely naked.", "order": 14023, "speaker": "Michael Scott"},
  {"season": 3, "episode": 21, "scene": 31, "line": "[in women's bathroom] This is so great, huh? We should do this much more often.", "order": 14024, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 21, "scene": 31, "line": "I-I think we hang out an appropriate amount of time.", "order": 14025, "speaker": "Toby Flenderson"},
  {"season": 3, "episode": 21, "scene": 31, "line": "What are you doing in here? This is the women's room.", "order": 14026, "speaker": "Creed Bratton"},
  {"season": 3, "episode": 21, "scene": 31, "line": "You're in here.", "order": 14027, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 21, "scene": 31, "line": "I pay for that privilege. [goes into stall]", "order": 14028, "speaker": "Creed Bratton"},
  {"season": 3, "episode": 21, "scene": 31, "line": "[all get up to leave] OK.", "order": 14029, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 21, "scene": 32, "line": "[wearing headphones and speaking loudly] I'm a pretty normal guy. I do one weird thing. I like to go in the women's room for number two. I've been caught several times and I have paid dearly.", "order": 14030, "speaker": "Creed Bratton"},
  {"season": 3, "episode": 21, "scene": 33, "line": "Mm. You don't want anything? My treat. Some panties or... pick a thong or... G-string. T-back. Get a nice bra. Padded bra. See-through, push-up, lace? Thigh-high? Bustier? Any - it just - you know what, I would love to buy you a fresh set of underwear.", "order": 14031, "speaker": "Michael Scott"},
  {"season": 3, "episode": 21, "scene": 33, "line": "Phyllis. What do you think? Too much?", "order": 14032, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 21, "scene": 33, "line": "Jim's gonna love it. [Karen giggles]", "order": 14033, "speaker": "Phyllis Vance"},
  {"season": 3, "episode": 21, "scene": 34, "line": "I'm kind of in-between boyfriends right now. So I don't need anything sexy. But I do need some new hand towels. I figure I can cut up this robe.", "order": 14034, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 21, "scene": 35, "line": "[in the car] Slower. Slower. Meredith. [Michael's cell phone ringing My Humps] Slow it up.", "order": 14035, "speaker": "Angela Martin"},
  {"season": 3, "episode": 21, "scene": 35, "line": "Oh. Oh, no. [inhales sharply] It's Jan. What do I do?", "order": 14036, "speaker": "Michael Scott"},
  {"season": 3, "episode": 21, "scene": 35, "line": "Answer it.", "order": 14037, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 21, "scene": 35, "line": "Don't answer it.", "order": 14038, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 21, "scene": 35, "line": "OK, it stopped.", "order": 14039, "speaker": "Michael Scott"},
  {"season": 3, "episode": 21, "scene": 35, "line": "[bang] Whoa.", "order": 14040, "speaker": "Kelly Kapoor"},
  {"season": 3, "episode": 21, "scene": 35, "line": "[tires screeching] Crap.", "order": 14041, "speaker": "Meredith Palmer"},
  {"season": 3, "episode": 21, "scene": 36, "line": "[snickers at Pam's sketch] That is pretty cool.", "order": 14042, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 21, "scene": 37, "line": "Michael, you know how to, um -change a wheel, right?", "order": 14043, "speaker": "Kelly Kapoor"},
  {"season": 3, "episode": 21, "scene": 37, "line": "Uh, yeah. Yep. Um... Could somebody grab me the lever, and I will...", "order": 14044, "speaker": "Michael Scott"},
  {"season": 3, "episode": 21, "scene": 37, "line": "Here, uh... Meredith? Why don't you put your hazards on.", "order": 14045, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 21, "scene": 37, "line": "Yeah, get your hazards on for safety. Let's see. [takes off his coat and tosses it aside] There we go! [Pam brings over the jack and lug wrench] Good. Yes, we have the... all right. [tries the use the jack to loosen the lug nuts]", "order": 14046, "speaker": "Michael Scott"},
  {"season": 3, "episode": 21, "scene": 37, "line": "I think I've got it.", "order": 14047, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 21, "scene": 37, "line": "Do you have a... a crescent? A crescent Allan?", "order": 14048, "speaker": "Michael Scott"},
  {"season": 3, "episode": 21, "scene": 37, "line": "I don't think we really need that, Michael.", "order": 14049, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 21, "scene": 37, "line": "Uh... you know what? I'm going to... you take care of that. I'm gonna do traffic... detail.", "order": 14050, "speaker": "Michael Scott"},
  {"season": 3, "episode": 21, "scene": 38, "line": "You know, I changed a tire today. All by myself. This bathrobe's already coming in handy. [Meredith honking] Coming!", "order": 14051, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 21, "scene": 39, "line": "Think we'll find him?", "order": 14052, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 21, "scene": 39, "line": "Yeah, I do. 'Cause justice never rests.", "order": 14053, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 21, "scene": 39, "line": "[pulls a candy bar from his coat pocket] Halvsies?", "order": 14054, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 21, "scene": 39, "line": "No. Wholesies. [snatches the candy bar]", "order": 14055, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 21, "scene": 39, "line": "Listen, man, I really appreciate you letting me shadow you today. I feel like I learned a lot.", "order": 14056, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 21, "scene": 39, "line": "Natch. [bites off a lot of candy bar]", "order": 14057, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 21, "scene": 39, "line": "Yep. If you don't mind, I think I'll hang some of these posters around my neighborhood. Schools, post office, et cetera.", "order": 14058, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 21, "scene": 39, "line": "You know, I may have underestimated you. You're not a total ass. [coughs]", "order": 14059, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 21, "scene": 40, "line": "OK, I am really going to do this.", "order": 14060, "speaker": "Michael Scott"},
  {"season": 3, "episode": 21, "scene": 40, "line": "Good luck, Michael.", "order": 14061, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 21, "scene": 40, "line": "You know what? I need my girls with me. Pam, Karen, even Phyllis. Come on. Let's do this. Let's do it. [sighs]", "order": 14062, "speaker": "Michael Scott"},
  {"season": 3, "episode": 21, "scene": 40, "line": "OK, remember, be strong.", "order": 14063, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 21, "scene": 40, "line": "I love you guys. Now I'm getting her voicemail.", "order": 14064, "speaker": "Michael Scott"},
  {"season": 3, "episode": 21, "scene": 40, "line": "Don't leave a -", "order": 14065, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 21, "scene": 40, "line": "[leaving a message] Hey, Jan. It's me, Michael. I'm just calling to say that I think we need a little break, permanently. And, uh, I know everybody says this, but I-I wanna remain friends. Or at least business associates who get along. Oh, just so you know, it's not me, it's you. [Jan walks in] OK, buddy. Somebody just walked in. I have to go. Um, so I'll talk to you later.", "order": 14066, "speaker": "Michael Scott"},
  {"season": 3, "episode": 21, "scene": 40, "line": "Michael... [clears throat] I was, um... I was really unhappy with our conversation earlier. And I... I just - I couldn't stop thinking about it. So I decided that I would drive down here and apologize to you in person. So... [takes Michael's hand] I'm sorry.", "order": 14067, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 21, "scene": 40, "line": "Thank you.", "order": 14068, "speaker": "Michael Scott"},
  {"season": 3, "episode": 21, "scene": 40, "line": "So... we're good?", "order": 14069, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 21, "scene": 40, "line": "Abso-fruit-ly.", "order": 14070, "speaker": "Michael Scott"},
  {"season": 3, "episode": 21, "scene": 40, "line": "[cell phone vibrates] Oh. Hold on, I'm sorry.", "order": 14071, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 21, "scene": 40, "line": "No... No...", "order": 14072, "speaker": "Michael Scott"},
  {"season": 3, "episode": 21, "scene": 40, "line": "One second. Oh! It's from you. Uh, you wanna grab some dinner?", "order": 14073, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 21, "scene": 40, "line": "Yeah.", "order": 14074, "speaker": "Michael Scott"},
  {"season": 3, "episode": 21, "scene": 40, "line": "OK. [voice on phone] 'It's me, Michael. I'm just calling to say that I think we need a little break, permanently. And, uh, I know everybody says this, but I want...'", "order": 14075, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 21, "scene": 40, "line": "Maybe some Italian. [voice on phone] '... to remain friends. Or at least business associates -' [Jan takes the phone away from her ear] Chinese? [voice on phone] 'who get along. Oh, just so you know, it's not me, it's you. OK, buddy.'", "order": 14076, "speaker": "Michael Scott"},
  {"season": 3, "episode": 21, "scene": 40, "line": "Oh. [door closes]", "order": 14077, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 21, "scene": 41, "line": "[sighs] Any man who says he totally understands women is a fool. Because they are un-understandable. There's a wishing fountain at the mall. And I threw a coin in for every woman in the world and made a wish. I wished for Jan to get over me. I wished for Phyllis, a plasma TV. I wished for Pam to gain courage. I wished for Angela, a heart, and for Kelly, a brain. 'Michael, how can you appreciate women so much but also dump one of them?' You mean, how can I be so illogical and flighty and unpredictable and emotional? Well, maybe I learned something from women after all.", "order": 14078, "speaker": "Michael Scott"},
  {"season": 3, "episode": 21, "scene": 42, "line": "[phone rings] Dunder-Mifflin paper/sex predator hotline, this is Dwight Schrute.", "order": 14079, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 21, "scene": 42, "line": "Hey, Dwight. It's Jim.", "order": 14080, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 21, "scene": 42, "line": "Jim, what are you doing? I'm busy.", "order": 14081, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 21, "scene": 42, "line": "No, you're not. I'm looking right at you.", "order": 14082, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 21, "scene": 42, "line": "Ugh. I'm hanging up.", "order": 14083, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 21, "scene": 42, "line": "Don't. [quietly] I have information about the sex predator.", "order": 14084, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 21, "scene": 42, "line": "You have information about the sex predator?", "order": 14085, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 21, "scene": 42, "line": "I saw him two minutes ago.", "order": 14086, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 21, "scene": 42, "line": "Where?", "order": 14087, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 21, "scene": 42, "line": "In the women's bathroom, above the sink.", "order": 14088, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 21, "scene": 42, "line": "[hangs up and runs to the women's bathroom] Anti-flashing task force! Above the sink. Above the sink. Hmm. [sees himself in the mirror with two black marks on the mirror making a mustache, removes his glasses and figures it out] PAM!", "order": 14089, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 21, "scene": 43, "line": "So how are you liking the Wall Street Journal subscription?", "order": 14090, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 21, "scene": 43, "line": "Love it.", "order": 14091, "speaker": "Michael Scott"},
  {"season": 3, "episode": 21, "scene": 43, "line": "Are you reading it everyday, like we decided you would?", "order": 14092, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 21, "scene": 43, "line": "Uh, yes, I am.", "order": 14093, "speaker": "Michael Scott"},
  {"season": 3, "episode": 21, "scene": 43, "line": "Good, good, it's the best business reporting isn't it?", "order": 14094, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 21, "scene": 43, "line": "Uh, yeah, it's okay. Its just, I don't think the cartoons are very funny.", "order": 14095, "speaker": "Michael Scott"},
  {"season": 3, "episode": 21, "scene": 43, "line": "Wait, wait, what cartoons?", "order": 14096, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 21, "scene": 43, "line": "The a cartoon that goes with each article. This one says Mel Karmazin? I don't, I don't know. I guess I get it.", "order": 14097, "speaker": "Michael Scott"},
  {"season": 3, "episode": 21, "scene": 43, "line": "[Sigh]", "order": 14098, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 21, "scene": 43, "line": "We should really start recycling.", "order": 14099, "speaker": "Michael Scott"},
  {"season": 3, "episode": 21, "scene": 43, "line": "Right uh, Michael.", "order": 14100, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 21, "scene": 44, "line": "Hey, what's going on?", "order": 14101, "speaker": "Kelly Kapoor"},
  {"season": 3, "episode": 21, "scene": 44, "line": "Phyllis got flashed in the parking lot.", "order": 14102, "speaker": "Stanley Hudson"},
  {"season": 3, "episode": 21, "scene": 44, "line": "[Screams]", "order": 14103, "speaker": "Kelly Kapoor"},
  {"season": 3, "episode": 21, "scene": 44, "line": "Okay, you need to stop that right now.", "order": 14104, "speaker": "Stanley Hudson"},
  {"season": 3, "episode": 21, "scene": 44, "line": "Phyllis, you okay? You sure?", "order": 14105, "speaker": "Bob"},
  {"season": 3, "episode": 21, "scene": 45, "line": "Hey good lookin', what ya got cookin'?", "order": 14106, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 21, "scene": 45, "line": "I got nothing cooking, it's cooked. It's borscht and its served cold.", "order": 14107, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 21, "scene": 45, "line": "Hey listen, I'm sure everyone's already told you but, your presentation out there, was pretty inspiring stuff. Must be nice being a woman around here, knowing they have a protector in you.", "order": 14108, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 21, "scene": 45, "line": "If you are trying to kiss my ass, I would not suggest it. Believe me, you do not want to kiss this ass.", "order": 14109, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 21, "scene": 46, "line": "Borscht, according to Dwight it's best served cold. You know what else is best served cold? Yeah, gazpacho. You see what I'm doing? I am establishing a mutual love of cold soups. This is the first step in my plan to win Dwight's trust.", "order": 14110, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 21, "scene": 47, "line": "I should make you my vichyssoise.", "order": 14111, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 21, "scene": 47, "line": "I will never be your vichyssoise.", "order": 14112, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 21, "scene": 48, "line": "Orgasms, why can women have them? Yep?", "order": 14113, "speaker": "Michael Scott"},
  {"season": 3, "episode": 21, "scene": 48, "line": "No. I'm not a lawyer, but I don't think this is the place to do this.", "order": 14114, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 21, "scene": 49, "line": "Sometimes I imagine what it would be like to be a woman. I have a full-length mirror in my bathroom, and before I get dressed I will tuck myself between my legs just to see. And, um, it's uncomfortable. So maybe I do understand.", "order": 14115, "speaker": "Michael Scott"},
  {"season": 3, "episode": 21, "scene": 50, "line": "[sings] We're going to the mall. Bye Ryan, bye.", "order": 14116, "speaker": "Kelly Kapoor"},
  {"season": 3, "episode": 21, "scene": 51, "line": "Lets go, fall out, let's go. Move, move, move, move, move.", "order": 14117, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 21, "scene": 52, "line": "I got shotgun. Damn it.", "order": 14118, "speaker": "Michael Scott"},
  {"season": 3, "episode": 21, "scene": 53, "line": "Let me be clear. There's only one thing that's important to me, and its not friendship. Its dominance, and I think I know a thing or two about dominating. Okay, I lived with a dominatrix for three years in Stamford. Mistress Lila. Taught me more than any college professor I ever had, while attending Cornell, which is where I went to school.", "order": 14119, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 21, "scene": 54, "line": "Look at that! Come on! Free underwear!", "order": 14120, "speaker": "Michael Scott"},
  {"season": 3, "episode": 21, "scene": 55, "line": "It is totally inappropriate, but on the other hand...", "order": 14121, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 21, "scene": 56, "line": "Hey, Pam, how bout something like that for you. Could sort of cover up any imperfections in the mid section. Just show off the twins a little bit.", "order": 14122, "speaker": "Michael Scott"},
  {"season": 3, "episode": 21, "scene": 57, "line": "That's pretty cool.", "order": 14123, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 21, "scene": 58, "line": "You can make people believe anything. Today I made Dwight believe I like cold soups. Why? I don't know. I don't have to know. That's what makes me so dangerous.", "order": 14124, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 21, "scene": 59, "line": "Idiot.", "order": 14125, "speaker": "Michael Scott"},
  {"season": 3, "episode": 21, "scene": 60, "line": "I learned a lot about women today. And any man who says he totally understands women is a fool. Because they are un-understandable. Jan and I broke up. Why? Because she didn't like one message I left on her voicemail? But that is her peariagative. I thought I understood women, but maybe, can't believe I'm gonna say this, maybe, women don't understand themselves.", "order": 14126, "speaker": "Michael Scott"},
  {"season": 3, "episode": 22, "scene": 1, "line": "Ughh... Blech.", "order": 14127, "speaker": "Michael Scott"},
  {"season": 3, "episode": 22, "scene": 1, "line": "OK, where does it hurt?", "order": 14128, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 22, "scene": 1, "line": "Just... all over. I don't want to do anything... I'm dying...", "order": 14129, "speaker": "Michael Scott"},
  {"season": 3, "episode": 22, "scene": 1, "line": "No, that's not how it works. You have to point to a specific part of the body.", "order": 14130, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 22, "scene": 1, "line": "Right there. [Michael points to computer screen.]", "order": 14131, "speaker": "Michael Scott"},
  {"season": 3, "episode": 22, "scene": 1, "line": "[reading from screen] 'Abdomen. Menses.'", "order": 14132, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 22, "scene": 1, "line": "Maybe.", "order": 14133, "speaker": "Michael Scott"},
  {"season": 3, "episode": 22, "scene": 1, "line": "The uterus contracts after your egg passes through it.'", "order": 14134, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 22, "scene": 1, "line": "Not it. I don't have eggs.", "order": 14135, "speaker": "Michael Scott"},
  {"season": 3, "episode": 22, "scene": 2, "line": "About 40 times a year, Michael gets really sick, but has no symptoms. Dwight is always gravely concerned.", "order": 14136, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 22, "scene": 3, "line": "Oh, is it possible you ate food that contained animal waste?", "order": 14137, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 22, "scene": 3, "line": "That's possible.", "order": 14138, "speaker": "Michael Scott"},
  {"season": 3, "episode": 22, "scene": 3, "line": "Michael?", "order": 14139, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 22, "scene": 3, "line": "Uh-huh?", "order": 14140, "speaker": "Michael Scott"},
  {"season": 3, "episode": 22, "scene": 3, "line": "David Wallace is on line one.", "order": 14141, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 22, "scene": 3, "line": "The CFO? Ohh...", "order": 14142, "speaker": "Michael Scott"},
  {"season": 3, "episode": 22, "scene": 3, "line": "OK, everybody out. Out. Out. Out. OK.", "order": 14143, "speaker": "Michael Scott"},
  {"season": 3, "episode": 22, "scene": 4, "line": "To what do I owe this great honor, David Wallace?", "order": 14144, "speaker": "Michael Scott"},
  {"season": 3, "episode": 22, "scene": 4, "line": "[on phone] Michael, I am calling---", "order": 14145, "speaker": "David Wallace"},
  {"season": 3, "episode": 22, "scene": 4, "line": "And Gromit. [David sighs] Jan? Is Jan there?", "order": 14146, "speaker": "Michael Scott"},
  {"season": 3, "episode": 22, "scene": 4, "line": "Jan is out of town right now.", "order": 14147, "speaker": "David Wallace"},
  {"season": 3, "episode": 22, "scene": 4, "line": "Oh, you sigh like Jan. I broke Jan's heart, David, and it was awful. It was... It was never my intention to ruin a life. But you know what? Sometimes...", "order": 14148, "speaker": "Michael Scott"},
  {"season": 3, "episode": 22, "scene": 4, "line": "Michael?", "order": 14149, "speaker": "David Wallace"},
  {"season": 3, "episode": 22, "scene": 4, "line": "...you just gots to get your freak on.", "order": 14150, "speaker": "Michael Scott"},
  {"season": 3, "episode": 22, "scene": 4, "line": "Michael?", "order": 14151, "speaker": "David Wallace"},
  {"season": 3, "episode": 22, "scene": 4, "line": "Yeah.", "order": 14152, "speaker": "Michael Scott"},
  {"season": 3, "episode": 22, "scene": 4, "line": "Michael?", "order": 14153, "speaker": "David Wallace"},
  {"season": 3, "episode": 22, "scene": 4, "line": "Hmmm.", "order": 14154, "speaker": "Michael Scott"},
  {"season": 3, "episode": 22, "scene": 4, "line": "I am calling to see if you can come down and interview for a job we have opening in corporate.", "order": 14155, "speaker": "David Wallace"},
  {"season": 3, "episode": 22, "scene": 4, "line": "Really?", "order": 14156, "speaker": "Michael Scott"},
  {"season": 3, "episode": 22, "scene": 4, "line": "Week from today. Bring your first quarter stats and your recommendation for who would take over the Scranton Branch.", "order": 14157, "speaker": "David Wallace"},
  {"season": 3, "episode": 22, "scene": 4, "line": "Wow. I wish I had prepared something to say.", "order": 14158, "speaker": "Michael Scott"},
  {"season": 3, "episode": 22, "scene": 4, "line": "That's not necessary.", "order": 14159, "speaker": "David Wallace"},
  {"season": 3, "episode": 22, "scene": 4, "line": "May God guide you in your quest.", "order": 14160, "speaker": "Michael Scott"},
  {"season": 3, "episode": 22, "scene": 4, "line": "Yes.", "order": 14161, "speaker": "David Wallace"},
  {"season": 3, "episode": 22, "scene": 5, "line": "OK, everybody have their towels and swim suits? We have about an hour and half. I suggest that you all go potty now and then we will be congregating on the partay bus.", "order": 14162, "speaker": "Michael Scott"},
  {"season": 3, "episode": 22, "scene": 6, "line": "Oh I'm excited. Today is Beach Day! And Michael is taking the whole office to the beach. So I'm wearing my bathing suit underneath my shirt [lifts up shirt, definitely", "order": 14163, "speaker": "Meredith Palmer"},
  {"season": 3, "episode": 22, "scene": 7, "line": "Oscar, you brought your Speedo, I assume?", "order": 14164, "speaker": "Michael Scott"},
  {"season": 3, "episode": 22, "scene": 7, "line": "I don't wear a Speedo, Michael.", "order": 14165, "speaker": "Oscar Martinez"},
  {"season": 3, "episode": 22, "scene": 7, "line": "Well, you can't swim in leather pants. [laughs] I'm just yankin' your chain. Not literally.", "order": 14166, "speaker": "Michael Scott"},
  {"season": 3, "episode": 22, "scene": 8, "line": "Anybody need sun block? Got SPF 30.", "order": 14167, "speaker": "Toby Flenderson"},
  {"season": 3, "episode": 22, "scene": 8, "line": "Oh, you know what? Uh, you're not going.", "order": 14168, "speaker": "Michael Scott"},
  {"season": 3, "episode": 22, "scene": 8, "line": "It's Beach Day...", "order": 14169, "speaker": "Toby Flenderson"},
  {"season": 3, "episode": 22, "scene": 8, "line": "Yeah, I know. I'm sorry, Toby. We... um... Somebody has to stay here.", "order": 14170, "speaker": "Michael Scott"},
  {"season": 3, "episode": 22, "scene": 9, "line": "I want today to be a beautiful memory... that the staff and I share after I have passed on to New York. And if Toby is a part of it... then it'll suck.", "order": 14171, "speaker": "Michael Scott"},
  {"season": 3, "episode": 22, "scene": 10, "line": "Hey, want my sun screen?", "order": 14172, "speaker": "Toby Flenderson"},
  {"season": 3, "episode": 22, "scene": 10, "line": "Oh, great. I forgot mine and I'm wearing a two piece.", "order": 14173, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 22, "scene": 10, "line": "Uh-huh.", "order": 14174, "speaker": "Toby Flenderson"},
  {"season": 3, "episode": 22, "scene": 10, "line": "Thanks Toby.", "order": 14175, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 22, "scene": 10, "line": "Hey Pam, I have a very important job for you", "order": 14176, "speaker": "Michael Scott"},
  {"season": 3, "episode": 22, "scene": 10, "line": "I thought we were just having fun at the beach.", "order": 14177, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 22, "scene": 10, "line": "We are. We are. But, I would like you to take notes. And I want you to find out about people's character. Not their hotness, per se, but their humor, and their charisma, and the indefinable quality that makes you all glad to follow me.", "order": 14178, "speaker": "Michael Scott"},
  {"season": 3, "episode": 22, "scene": 11, "line": "What happens to a company if somebody takes their boss away? I will answer your question with a question. It's like what happens to a chicken when you take its head away. It dies. Unless you find a new head. I need to see which one of these people have the skills to be a chicken head.", "order": 14179, "speaker": "Michael Scott"},
  {"season": 3, "episode": 22, "scene": 12, "line": "You want me to write down people's indefinable qualities?", "order": 14180, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 22, "scene": 12, "line": "I want you to write down everything that people are doing all day. And then type it up, in a way that is helpful. Alright?", "order": 14181, "speaker": "Michael Scott"},
  {"season": 3, "episode": 22, "scene": 13, "line": "I have the most boring job in the office, so... why wouldn't I have the most boring job on beach day?", "order": 14182, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 22, "scene": 14, "line": "This way to the partay bus.", "order": 14183, "speaker": "Michael Scott"},
  {"season": 3, "episode": 22, "scene": 15, "line": "I just want to lie on the beach and eat hot dogs. That's all I've ever wanted.", "order": 14184, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 22, "scene": 16, "line": "[singing] [Angela mouthing the words next to him] And the night got deathly quiet, and his face lost all expression. Said, 'If you're gonna play the game, boy, ya gotta learn to play it right.' [rest of office joins in at varying times] You got to know when to hold 'em, know when to fold 'em, Know when to walk away and know when to run. You never count your money when you're sittin' at the table. There'll be time enough for countin' when the dealin's done.", "order": 14185, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 22, "scene": 17, "line": "Everybody, may I have you attention please? Today, we are not just spending a day at the beach.", "order": 14186, "speaker": "Michael Scott"},
  {"season": 3, "episode": 22, "scene": 17, "line": "Oh, sweet mother of God.", "order": 14187, "speaker": "Stanley Hudson"},
  {"season": 3, "episode": 22, "scene": 17, "line": "If you don't like it, Stanley, you can go to the back of the bus.", "order": 14188, "speaker": "Michael Scott"},
  {"season": 3, "episode": 22, "scene": 17, "line": "Excuse me?", "order": 14189, "speaker": "Stanley Hudson"},
  {"season": 3, "episode": 22, "scene": 17, "line": "Or the front of the bus. Or drive the bus. Just, we are all participating in mandatory fun activities. Funtivities! And there is a special secret prize for the winner!", "order": 14190, "speaker": "Michael Scott"},
  {"season": 3, "episode": 22, "scene": 17, "line": "Yes! Funtivities! I knew it wasn't just a trip to the beach.", "order": 14191, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 22, "scene": 17, "line": "Okay, you know what? Your enthusiasm's turning people off.", "order": 14192, "speaker": "Michael Scott"},
  {"season": 3, "episode": 22, "scene": 17, "line": "I hope there will be management parables.", "order": 14193, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 22, "scene": 17, "line": "Well, [whispering] Hey Pam, did you get that down?", "order": 14194, "speaker": "Michael Scott"},
  {"season": 3, "episode": 22, "scene": 17, "line": "Like what?", "order": 14195, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 22, "scene": 17, "line": "Like everything I said and everything they did and... Just don't...", "order": 14196, "speaker": "Michael Scott"},
  {"season": 3, "episode": 22, "scene": 17, "line": "Well... no, I don't... exactly... what?", "order": 14197, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 22, "scene": 17, "line": "Well write it down before you forget it. That's... You've just been drawing pictures. [sighs] Rrrr. I can't stay mad at you.", "order": 14198, "speaker": "Michael Scott"},
  {"season": 3, "episode": 22, "scene": 18, "line": "Here we are ladies and gentlemen. Everybody ready? Last one down is a rotten egg. [Gets off bus] Watch out for snakes!", "order": 14199, "speaker": "Michael Scott"},
  {"season": 3, "episode": 22, "scene": 19, "line": "Everyone put on sunscreen.", "order": 14200, "speaker": "Angela Martin"},
  {"season": 3, "episode": 22, "scene": 19, "line": "Alright, find a cozy spot. Everybody settle in. [everybody sits down on beach] OK, everybody up! Circle 'round. [motions for circle to form] Let us play some games. We are situated on the northeast corner of scenic Lake Scranton. America's eighth largest indigenous body of water. It is here that a group of Americans will undergo the ultimate challenge. One day, 14 strangers who work together, but only one survivor.", "order": 14201, "speaker": "Michael Scott"},
  {"season": 3, "episode": 22, "scene": 19, "line": "What?", "order": 14202, "speaker": "Oscar Martinez"},
  {"season": 3, "episode": 22, "scene": 19, "line": "Just words. Inspiring words. [under breath] Not a contender. [out loud] For the competitions, you will be divided into four tribes. Each tribe will have a leader that I will pick randomly off the top of my head without thinking. Jim, Dwight, Andy and Stanley.", "order": 14203, "speaker": "Michael Scott"},
  {"season": 3, "episode": 22, "scene": 19, "line": "Yes!", "order": 14204, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 22, "scene": 19, "line": "Choose your tribes. Except for Pam. Not Pam.", "order": 14205, "speaker": "Michael Scott"},
  {"season": 3, "episode": 22, "scene": 20, "line": "Jim Halpert. Pros: smart, cool, good-looking. Remind you of anybody you know? Cons: not a hard worker. I can spend all day on a project, and he will finish the same project in a half an hour. So that should tell you something.", "order": 14206, "speaker": "Michael Scott"},
  {"season": 3, "episode": 22, "scene": 21, "line": "I choose Michael!", "order": 14207, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 22, "scene": 21, "line": "I'm not playing.", "order": 14208, "speaker": "Michael Scott"},
  {"season": 3, "episode": 22, "scene": 21, "line": "OK, temp.", "order": 14209, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 22, "scene": 22, "line": "Dwight is an obvious candidate for my job. He has the best sales record in the office. He loves the work. He is, however, an idiot.", "order": 14210, "speaker": "Michael Scott"},
  {"season": 3, "episode": 22, "scene": 23, "line": "We are going to choose team names. Dwight?", "order": 14211, "speaker": "Michael Scott"},
  {"season": 3, "episode": 22, "scene": 23, "line": "We will be called Gryffindor.", "order": 14212, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 22, "scene": 23, "line": "Really? Not Slytherin?", "order": 14213, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 22, "scene": 23, "line": "Slytherin are the bad guys, Jim.", "order": 14214, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 22, "scene": 23, "line": "I know. Okay, we will be Voldemort.", "order": 14215, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 22, "scene": 23, "line": "He-who-must-not-be-named? I wouldn't do that.", "order": 14216, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 22, "scene": 23, "line": "[starts chanting] Vol-de-mort, Vol-de-mort ...[Kevin and Karen follow] Vol-de-mort! Vol-de-mort! Vol-de-mort!", "order": 14217, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 22, "scene": 23, "line": "OK, seriously. You really shouldn't be saying that.", "order": 14218, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 22, "scene": 23, "line": "Hey, hey, hey. Ok, Ok... Stanley, your team name?", "order": 14219, "speaker": "Michael Scott"},
  {"season": 3, "episode": 22, "scene": 23, "line": "I don't care what you call my team.", "order": 14220, "speaker": "Stanley Hudson"},
  {"season": 3, "episode": 22, "scene": 23, "line": "Then I will name your team the Red team.", "order": 14221, "speaker": "Michael Scott"},
  {"season": 3, "episode": 22, "scene": 23, "line": "No [crosses arms], the blue team.", "order": 14222, "speaker": "Stanley Hudson"},
  {"season": 3, "episode": 22, "scene": 24, "line": "I am also considering Stanley because of all the good that black people have done. For America.", "order": 14223, "speaker": "Michael Scott"},
  {"season": 3, "episode": 22, "scene": 25, "line": "We will be team U.S.A.", "order": 14224, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 22, "scene": 25, "line": "Very good. Pam, please take a note that Andy is patriotic.", "order": 14225, "speaker": "Michael Scott"},
  {"season": 3, "episode": 22, "scene": 26, "line": "Andy Bernard. Pros: He's classy. He gets me. He went to Cornell. I trust him. Cons: I don't really trust him.", "order": 14226, "speaker": "Michael Scott"},
  {"season": 3, "episode": 22, "scene": 27, "line": "It is time for the great spoon and egg race. This one is with a little twist.", "order": 14227, "speaker": "Michael Scott"},
  {"season": 3, "episode": 22, "scene": 27, "line": "There's already a twist, you're carrying an egg on a spoon.", "order": 14228, "speaker": "Stanley Hudson"},
  {"season": 3, "episode": 22, "scene": 27, "line": "Shh... The person carrying the egg will be blindfolded. Please put on your blindfolds. That person carrying the egg will go down, circle a torch, come back, deposit their egg in the pail. First team back is the winner. Alright. Ready? Set. Go! [contestants start walking]", "order": 14229, "speaker": "Michael Scott"},
  {"season": 3, "episode": 22, "scene": 27, "line": "Come on Phyllis, you can do it. [Phyllis' egg falls off spoon] Ahh...", "order": 14230, "speaker": "Oscar Martinez"},
  {"season": 3, "episode": 22, "scene": 27, "line": "Thank you so much. [Stanley sits down and pulls out his crossword puzzle]", "order": 14231, "speaker": "Stanley Hudson"},
  {"season": 3, "episode": 22, "scene": 27, "line": "Phyllis is out. Yes! [to Kelly] Follow my voice. Follow my voice. Yeah, keep it moving. Keep it moving. Right this way. Looking good. [Cheers]", "order": 14232, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 22, "scene": 27, "line": "I don't want to hit the big rock!", "order": 14233, "speaker": "Kelly Kapoor"},
  {"season": 3, "episode": 22, "scene": 27, "line": "Don't worry. You're not...", "order": 14234, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 22, "scene": 27, "line": "I know I'm near the big rock. I just know it.", "order": 14235, "speaker": "Kelly Kapoor"},
  {"season": 3, "episode": 22, "scene": 27, "line": "No where near the big rock.", "order": 14236, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 22, "scene": 27, "line": "[takes off blindfold] I just don't want to get hit by it...", "order": 14237, "speaker": "Kelly Kapoor"},
  {"season": 3, "episode": 22, "scene": 27, "line": "What're you doing? No! See, now we're disqualified.", "order": 14238, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 22, "scene": 28, "line": "I am okay if I lose every single contest today. Honestly. Because I see these contests as an opportunity for me to demonstrate what a good sport I am. Mallard! [throws a stone]", "order": 14239, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 22, "scene": 29, "line": "[to Karen] Woah, stop, stop, stop. There's a hole. Step over the hole.", "order": 14240, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 22, "scene": 29, "line": "Hole?", "order": 14241, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 22, "scene": 29, "line": "Yup. [Karen takes a big step] Perfect, oooh, just made it. OK, turn left.", "order": 14242, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 22, "scene": 30, "line": "[to Ryan] Come on! Come on! Andale! Arriba! Arriba!", "order": 14243, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 22, "scene": 30, "line": "Can you just stop this right now, or I'm not gonna do this anymore.", "order": 14244, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 22, "scene": 30, "line": "What are you saying?", "order": 14245, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 22, "scene": 30, "line": "You have to stop yelling at me or I'm not gonna do the egg race.", "order": 14246, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 22, "scene": 30, "line": "OK, I apologize for yelling at you.", "order": 14247, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 22, "scene": 30, "line": "That's what being a good captain is about. It's about listening to the members of your team.", "order": 14248, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 22, "scene": 30, "line": "I am trying to bring team spirit.", "order": 14249, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 22, "scene": 31, "line": "[to Karen] Woah, stop, another hole. Take a big step. [Karen steps into lake] Yes!", "order": 14250, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 22, "scene": 31, "line": "[lifts up blindfold] Oh my God! You ass! [chases Jim and throws egg at him] [both laugh]", "order": 14251, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 22, "scene": 32, "line": "There's nothing better than a beautiful day at the beach, filled with sun, surf, and uh... diligent note-taking. [holds up notes]", "order": 14252, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 22, "scene": 32, "line": "[off screen] Pam...you're missing things. [shakes off hand and starts writing in notebook]", "order": 14253, "speaker": "Michael Scott"},
  {"season": 3, "episode": 22, "scene": 33, "line": "[at Ryan holding egg in spoon] Let's go! Let's go! Come on! Mush! Mush! Come on you bastard! [Ryan takes off blindfold and throws it to ground along with egg and spoon] What the? Damn it temp!", "order": 14254, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 22, "scene": 33, "line": "[begins to clap] Great job everyone, that was fantastic. [Michael sighs]", "order": 14255, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 22, "scene": 34, "line": "Okay Pam, I have another little project for you.", "order": 14256, "speaker": "Michael Scott"},
  {"season": 3, "episode": 22, "scene": 34, "line": "Does it have to do with these shopping bags filled with hot dogs?", "order": 14257, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 22, "scene": 34, "line": "Smart as a whip! Yes! [holds up hot dog packages] These are pre-cooked, so it's not absolutely necessary, but I would appreciate it if you could heat up 800 hot dogs for a little contest I'm going to be having. I would greatly appreciate it.", "order": 14258, "speaker": "Michael Scott"},
  {"season": 3, "episode": 22, "scene": 34, "line": "When's the contest?", "order": 14259, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 22, "scene": 34, "line": "Like umm...[looks at watch] ten minutes?", "order": 14260, "speaker": "Michael Scott"},
  {"season": 3, "episode": 22, "scene": 34, "line": "How am I supposed to... get...", "order": 14261, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 22, "scene": 34, "line": "Thanks a bunch.", "order": 14262, "speaker": "Michael Scott"},
  {"season": 3, "episode": 22, "scene": 35, "line": "A good manager has got to be hungry. Hungry for success.", "order": 14263, "speaker": "Michael Scott"},
  {"season": 3, "episode": 22, "scene": 36, "line": "OK, who's hungry [Kevin starts to put hot dog in mouth] No, no, no. Do not touch the food. Please. Not yet. That is our next event. A hot dog eating contest. [sighs at the table] For those of you who are curious, the world record is 54 and one half hot dogs. Wow! And you know what? I personally have cooked up enough so each and every one of you could break that record. So shoot for the stars, OK? Alright, the team that eats the most hot dogs in ten minutes will be declared the winner. On you mark. Get set.", "order": 14264, "speaker": "Michael Scott"},
  {"season": 3, "episode": 22, "scene": 36, "line": "Can I have a turkey burger?", "order": 14265, "speaker": "Kelly Kapoor"},
  {"season": 3, "episode": 22, "scene": 36, "line": "No, I have the only one. I claimed it. Ready?", "order": 14266, "speaker": "Michael Scott"},
  {"season": 3, "episode": 22, "scene": 36, "line": "Turkey is a healthy meat.", "order": 14267, "speaker": "Oscar Martinez"},
  {"season": 3, "episode": 22, "scene": 36, "line": "It's very good for you. [overlapping talk from all at table]", "order": 14268, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 22, "scene": 36, "line": "Guys, guys, guys. Come on! Pleeeeease... Let's just... OK, it's very important that you all try to eat as many hot dogs as you can. On your mark. Get set. Eat!", "order": 14269, "speaker": "Michael Scott"},
  {"season": 3, "episode": 22, "scene": 36, "line": "Is there any mustard?", "order": 14270, "speaker": "Phyllis Vance"},
  {"season": 3, "episode": 22, "scene": 36, "line": "No mustard, no mustard. Just eat it, eat it, Phyllis. Dip it in the water so it'll slide down your gullet more easily.", "order": 14271, "speaker": "Michael Scott"},
  {"season": 3, "episode": 22, "scene": 36, "line": "Come on, the winner gets a big, big prize.", "order": 14272, "speaker": "Michael Scott"},
  {"season": 3, "episode": 22, "scene": 36, "line": "[eating hot dog] What is it?", "order": 14273, "speaker": "Meredith Palmer"},
  {"season": 3, "episode": 22, "scene": 36, "line": "I can't say.", "order": 14274, "speaker": "Michael Scott"},
  {"season": 3, "episode": 22, "scene": 36, "line": "You can't say, or you can't pronounce it?", "order": 14275, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 22, "scene": 36, "line": "The winner gets a regional manager's salary for a year, and a Sebring, and the feeling that they are making a difference in the world.", "order": 14276, "speaker": "Michael Scott"},
  {"season": 3, "episode": 22, "scene": 36, "line": "Can we just take those first two things?", "order": 14277, "speaker": "Kelly Kapoor"},
  {"season": 3, "episode": 22, "scene": 36, "line": "The winner of today gets my job. Ok? I'm interviewing for a job at corporate. And they're only interviewing a handful of people and I'm the most qualified and I'll probably get it. Alright?", "order": 14278, "speaker": "Michael Scott"},
  {"season": 3, "episode": 22, "scene": 36, "line": "You're leaving?", "order": 14279, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 22, "scene": 36, "line": "I didn't want to tell anyway. I didn't want to cast a pall over our fun beach day. But you know what? I don't know who to recommend because frankly nobody is stepping up.", "order": 14280, "speaker": "Michael Scott"},
  {"season": 3, "episode": 22, "scene": 36, "line": "[slams fist onto table] I am so hungry! [starts eating hot dog real fast]", "order": 14281, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 22, "scene": 36, "line": "Do you expect me to believe that you're truly making your recommendations on this basis?", "order": 14282, "speaker": "Stanley Hudson"},
  {"season": 3, "episode": 22, "scene": 36, "line": "[points at Stanley] Word. [Stanley starts eating hot dog] There we go. Let's see it.", "order": 14283, "speaker": "Michael Scott"},
  {"season": 3, "episode": 22, "scene": 37, "line": "I would rather work for an upturned broom with a bucket for a head than work for somebody else in this office besides myself. Game on!", "order": 14284, "speaker": "Stanley Hudson"},
  {"season": 3, "episode": 22, "scene": 38, "line": "Four. Three. Two. One. Stop your eating! And the winner is Andy Bernard, with 14 hot dogs!", "order": 14285, "speaker": "Michael Scott"},
  {"season": 3, "episode": 22, "scene": 38, "line": "Team U.S.A.!", "order": 14286, "speaker": "Kelly Kapoor"},
  {"season": 3, "episode": 22, "scene": 38, "line": "One came up.", "order": 14287, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 22, "scene": 38, "line": "13 hot dogs, everybody!", "order": 14288, "speaker": "Michael Scott"},
  {"season": 3, "episode": 22, "scene": 38, "line": "Damn it!", "order": 14289, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 22, "scene": 39, "line": "[holding a fish with all the meat stripped off] Nobody told me we were going to have hot dogs!", "order": 14290, "speaker": "Creed Bratton"},
  {"season": 3, "episode": 22, "scene": 40, "line": "Sabotage.", "order": 14291, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 22, "scene": 40, "line": "What? What are you saying? Did you say sandwich?", "order": 14292, "speaker": "Angela Martin"},
  {"season": 3, "episode": 22, "scene": 40, "line": "No. I was saying that before. Not now. Now I am saying sabotage. The ancient Dutch art of screwing up your own team.", "order": 14293, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 22, "scene": 40, "line": "I knew you were saying sabotage. I was giving you an example of it. I will misunderstand everything that Andy says until he goes insane.", "order": 14294, "speaker": "Angela Martin"},
  {"season": 3, "episode": 22, "scene": 40, "line": "If Michael organizes some kind of group hug, stand next to me.", "order": 14295, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 22, "scene": 41, "line": "It's very simple. There are only three rules. You must not touch the ground. You must not step outside of the ring. And you must always wear the safety mittens.", "order": 14296, "speaker": "Michael Scott"},
  {"season": 3, "episode": 22, "scene": 41, "line": "Uh, we don't have any safety mittens", "order": 14297, "speaker": "Stanley Hudson"},
  {"season": 3, "episode": 22, "scene": 41, "line": "Probably left them in the trunk of my car. It's alright. It's alright. Here we go.", "order": 14298, "speaker": "Michael Scott"},
  {"season": 3, "episode": 22, "scene": 41, "line": "Get 'em big boy!", "order": 14299, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 22, "scene": 41, "line": "Aaaaaand, go!", "order": 14300, "speaker": "Michael Scott"},
  {"season": 3, "episode": 22, "scene": 41, "line": "C'mon, Jim!", "order": 14301, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 22, "scene": 41, "line": "[growly noises, Jim looks terrified]", "order": 14302, "speaker": "Stanley Hudson"},
  {"season": 3, "episode": 22, "scene": 41, "line": "What are you doing?", "order": 14303, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 22, "scene": 41, "line": "Nice, Stanley!", "order": 14304, "speaker": "Michael Scott"},
  {"season": 3, "episode": 22, "scene": 41, "line": "[to Jim] Sorry about that. It's all about taking points away from Dwight.", "order": 14305, "speaker": "Stanley Hudson"},
  {"season": 3, "episode": 22, "scene": 41, "line": "Yeah. No. Good.", "order": 14306, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 22, "scene": 42, "line": "Oh, my God. I have never seen that look in a man's eyes... ever. I thought that I might die. On beach day.", "order": 14307, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 22, "scene": 43, "line": "If either of these guys are put in charge of the office I will transfer to Albany. Gil can come if he wants. I'm kinda looking for a way out of that relationship anyway. I think I might try girls for a while. Angela thinks I can cross over. We'll see.", "order": 14308, "speaker": "Oscar Martinez"},
  {"season": 3, "episode": 22, "scene": 44, "line": "[Andy and Dwight sumo wrestle] Gaaaaa....", "order": 14309, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 22, "scene": 44, "line": "One. Two. Three.", "order": 14310, "speaker": "Michael Scott"},
  {"season": 3, "episode": 22, "scene": 44, "line": "Yaaaaa! Yaaaaa!", "order": 14311, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 22, "scene": 44, "line": "Excellent!", "order": 14312, "speaker": "Michael Scott"},
  {"season": 3, "episode": 22, "scene": 44, "line": "Gryffindor! Gryffindor!", "order": 14313, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 22, "scene": 45, "line": "I didn't win. But the only reason I didn't win is because I recently learned that it's better to work thing out with words.", "order": 14314, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 22, "scene": 45, "line": "That's not why you lost.", "order": 14315, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 22, "scene": 45, "line": "Yeah it is. I totally could have kicked your butt so bad.", "order": 14316, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 22, "scene": 45, "line": "Yeah, right.", "order": 14317, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 22, "scene": 45, "line": "Yeah, right, c'mon! C'mon! [Dwight and Andy wrestle]", "order": 14318, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 22, "scene": 45, "line": "[bouncing off of Andy] Aaaagh! Uggggh! How do you like that?", "order": 14319, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 22, "scene": 46, "line": "[Trying to wet bandana, falls in water] Oh, God! Waaa! Guhh! Wuuuh! Help! Angela! Angela, hey! Oh, thank God! Please tell somebody!", "order": 14320, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 22, "scene": 46, "line": "What, Andy? Andy, what should I tell them?", "order": 14321, "speaker": "Angela Martin"},
  {"season": 3, "episode": 22, "scene": 46, "line": "Go tell them I'm floating away, obviously!", "order": 14322, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 22, "scene": 46, "line": "I don't understand what you want from me.", "order": 14323, "speaker": "Angela Martin"},
  {"season": 3, "episode": 22, "scene": 46, "line": "Angela, it's pretty simple! Look at what I'm doing and go tell somebody about it!", "order": 14324, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 22, "scene": 46, "line": "Sorry! Bye, Andy!", "order": 14325, "speaker": "Angela Martin"},
  {"season": 3, "episode": 22, "scene": 46, "line": "Angela!", "order": 14326, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 22, "scene": 47, "line": "I knew that finding a successor would be difficult. I did not know that it would be impossible. Thus far the candidates have been wildly disappointing. Uh, Jim is not taking it seriously. Uh, Stanley is having a stroke. And Andy, where is he? Where the hell is Andy? Where is he, Pam? Do you know? Ah... who's ahead in points?", "order": 14327, "speaker": "Michael Scott"},
  {"season": 3, "episode": 22, "scene": 47, "line": "I think they're even. At various times you gave Jim ten points, Dwight a gold star, and Stanley a thumbs up. And I don't really know how to compare those units.", "order": 14328, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 22, "scene": 47, "line": "Check to see if there is a conversion chart in that notebook.", "order": 14329, "speaker": "Michael Scott"},
  {"season": 3, "episode": 22, "scene": 47, "line": "I really doubt it, Michael.", "order": 14330, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 22, "scene": 47, "line": "Please just check.", "order": 14331, "speaker": "Michael Scott"},
  {"season": 3, "episode": 22, "scene": 48, "line": "[on cell phone] Great. Yeah, I'll see you next week. Thank you, and here is Karen Filipelli. Bye.", "order": 14332, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 22, "scene": 48, "line": "Hello? Yeah, hi David. Yes, I would like to be considered for the corporate position in well.", "order": 14333, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 22, "scene": 48, "line": "In well?", "order": 14334, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 22, "scene": 48, "line": "As well.", "order": 14335, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 22, "scene": 48, "line": "How would that work in well? I just want to know.", "order": 14336, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 22, "scene": 48, "line": "Yes. uh, huh.", "order": 14337, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 22, "scene": 48, "line": "Wait a minute.", "order": 14338, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 22, "scene": 48, "line": "That would be fine.", "order": 14339, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 22, "scene": 48, "line": "If this job is in a well, I don't want it.", "order": 14340, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 22, "scene": 48, "line": "[whispering] Cut it out!", "order": 14341, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 22, "scene": 48, "line": "I don't!", "order": 14342, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 22, "scene": 49, "line": "What does a great manager need most of all? Courage.", "order": 14343, "speaker": "Michael Scott"},
  {"season": 3, "episode": 22, "scene": 49, "line": "How so? I mean... sure thing, that sounds smart... I can't do this anymore! I'm goin' to sit in the bus.", "order": 14344, "speaker": "Stanley Hudson"},
  {"season": 3, "episode": 22, "scene": 49, "line": "Your loss, Stanley. Meanwhile the rest of us will have a super fun time defeating our fear and creating a lasting memory. Walking through FIRE!", "order": 14345, "speaker": "Michael Scott"},
  {"season": 3, "episode": 22, "scene": 50, "line": "Who among you has the guts to replace me? Let him walk across these coals.", "order": 14346, "speaker": "Michael Scott"},
  {"season": 3, "episode": 22, "scene": 50, "line": "Are you going to try it?", "order": 14347, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 22, "scene": 50, "line": "I'm not going to talk through the fire after your disgusting feet have gone through.", "order": 14348, "speaker": "Angela Martin"},
  {"season": 3, "episode": 22, "scene": 50, "line": "Angela, it is a million degrees.", "order": 14349, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 22, "scene": 50, "line": "I'm gonna do it. And I fully expect to burn my feet and go to the hospital. That's the right spirit when doing a coal walk, right?", "order": 14350, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 22, "scene": 50, "line": "No. No, not you, Pam. You have to keep score.", "order": 14351, "speaker": "Michael Scott"},
  {"season": 3, "episode": 22, "scene": 50, "line": "I'd like to try it.", "order": 14352, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 22, "scene": 50, "line": "Pointless.", "order": 14353, "speaker": "Michael Scott"},
  {"season": 3, "episode": 22, "scene": 50, "line": "But I'm not kidding. I really want to do it.", "order": 14354, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 22, "scene": 50, "line": "Blah, blah, blee blup, blup, okay? This is about guts. It takes guts to be a regional manager. Jim, you're up.", "order": 14355, "speaker": "Michael Scott"},
  {"season": 3, "episode": 22, "scene": 50, "line": "Nope.", "order": 14356, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 22, "scene": 50, "line": "Ji.. why not? C'mon.", "order": 14357, "speaker": "Michael Scott"},
  {"season": 3, "episode": 22, "scene": 50, "line": "Oh, 'cause I don't want my feet to get burned.", "order": 14358, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 22, "scene": 50, "line": "You do not have what it takes to be a regional manager.", "order": 14359, "speaker": "Michael Scott"},
  {"season": 3, "episode": 22, "scene": 50, "line": "That's harsh.", "order": 14360, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 22, "scene": 50, "line": "Who's next? Andy? Where's Andy? Andy is never here today.", "order": 14361, "speaker": "Michael Scott"},
  {"season": 3, "episode": 22, "scene": 51, "line": "[lying in water, car lights light up his location] Hello? Who's there? My name is Andrew Bernard, I was with a group called Dunder Mifflin. Hello?", "order": 14362, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 22, "scene": 52, "line": "Why don't you go Michael?", "order": 14363, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 22, "scene": 52, "line": "Because I already did. Remember? I burned my foot on a George Foreman grill.", "order": 14364, "speaker": "Michael Scott"},
  {"season": 3, "episode": 22, "scene": 52, "line": "And that is not the same at all. If you're going to ask other people to do it, you should do it yourself.", "order": 14365, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 22, "scene": 52, "line": "Alright. Okay. Alright. Fine. 'kay. [clears throat, breathes noisily] The mind has to wrap around the foot. [exhales] Okay.", "order": 14366, "speaker": "Michael Scott"},
  {"season": 3, "episode": 22, "scene": 52, "line": "Do you want us to count to three?", "order": 14367, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 22, "scene": 52, "line": "Yes. Count to three, please.", "order": 14368, "speaker": "Michael Scott"},
  {"season": 3, "episode": 22, "scene": 52, "line": "Three. Two. One.", "order": 14369, "speaker": "Group"},
  {"season": 3, "episode": 22, "scene": 52, "line": "Count the other way. Count... no, no, count one, two, three, not three, two, one.", "order": 14370, "speaker": "Michael Scott"},
  {"season": 3, "episode": 22, "scene": 52, "line": "One. Two. Three! Go! Do it! Go, Michael.", "order": 14371, "speaker": "Group"},
  {"season": 3, "episode": 22, "scene": 52, "line": "Wait! Am I going on go? Or am I going on three?", "order": 14372, "speaker": "Michael Scott"},
  {"season": 3, "episode": 22, "scene": 52, "line": "On the go that's after three.", "order": 14373, "speaker": "Creed Bratton"},
  {"season": 3, "episode": 22, "scene": 52, "line": "Okay.", "order": 14374, "speaker": "Michael Scott"},
  {"season": 3, "episode": 22, "scene": 52, "line": "One, two, three, go!", "order": 14375, "speaker": "Group"},
  {"season": 3, "episode": 22, "scene": 52, "line": "No! It's okay. I will do this Michael.", "order": 14376, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 22, "scene": 52, "line": "Don't, don't.", "order": 14377, "speaker": "Michael Scott"},
  {"season": 3, "episode": 22, "scene": 52, "line": "[walking on coals] I will walk and stand on these coals until you award me the position of regional manager!", "order": 14378, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 22, "scene": 52, "line": "Wow. Go, Dwight. Keep moving.", "order": 14379, "speaker": "Group"},
  {"season": 3, "episode": 22, "scene": 52, "line": "[standing still, on the coals] GIVE ME THE JOB! GIVE ME THE JOB!", "order": 14380, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 22, "scene": 52, "line": "I'm not going to give it to you.", "order": 14381, "speaker": "Michael Scott"},
  {"season": 3, "episode": 22, "scene": 52, "line": "[falling on his knees, then on all fours] Aaagh, aaagh!", "order": 14382, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 22, "scene": 52, "line": "[yelling excitedly for Dwight to get off the coals]", "order": 14383, "speaker": "Group"},
  {"season": 3, "episode": 22, "scene": 52, "line": "Michael, do something!", "order": 14384, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 22, "scene": 52, "line": "Aggggh, that stings!", "order": 14385, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 22, "scene": 53, "line": "Being the boss is also about image. I've never looked like that. That was gross. I just, I don't see the connection between a firewalk and management. Worst seventy-five bucks I ever spent. You know what, if I had to pick my replacement based on today, it would be Mr. 'Outside Hire.'", "order": 14386, "speaker": "Michael Scott"},
  {"season": 3, "episode": 22, "scene": 53, "line": "Or Mrs. 'Outside Hire.'", "order": 14387, "speaker": "Angela Martin"},
  {"season": 3, "episode": 22, "scene": 53, "line": "Yeah. Ummm, hmm... True. Look, I don't want to leave this branch that I love to an outside hire therefore we are going to have a one hundred point winner take all sudden death tribal council round. To test the aspect of my job that I think is the most important, something I call the Bob Hope factor.", "order": 14388, "speaker": "Michael Scott"},
  {"season": 3, "episode": 22, "scene": 53, "line": "Who's Bob Hope?", "order": 14389, "speaker": "Kelly Kapoor"},
  {"season": 3, "episode": 22, "scene": 53, "line": "God! He's a comedian.", "order": 14390, "speaker": "Michael Scott"},
  {"season": 3, "episode": 22, "scene": 53, "line": "Oh, like Amanda Bynes.", "order": 14391, "speaker": "Kelly Kapoor"},
  {"season": 3, "episode": 22, "scene": 53, "line": "Who's Amanda Bynes?", "order": 14392, "speaker": "Michael Scott"},
  {"season": 3, "episode": 22, "scene": 53, "line": "She's from 'What a Girl Wants.'", "order": 14393, "speaker": "Kelly Kapoor"},
  {"season": 3, "episode": 22, "scene": 53, "line": "Oh, I love that movie. Yes, Kelly is right, the person to replace me has to have a great sense of humor and they have to possess the leadership qualities of a Bynes or a Hope. So without further ado, Jim and Dwight show us what you got.", "order": 14394, "speaker": "Michael Scott"},
  {"season": 3, "episode": 22, "scene": 53, "line": "Hey, I know what you're looking for, but um, I got to be honest, I really don't think I should be considered as your replacement.", "order": 14395, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 22, "scene": 53, "line": "You are being too modest.", "order": 14396, "speaker": "Michael Scott"},
  {"season": 3, "episode": 22, "scene": 53, "line": "Michael, on Thursday I'm going to drive down and interview with David for the open position in New York.", "order": 14397, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 22, "scene": 53, "line": "Hhhh... okay. That is not funny. I am deducting sixty points from Voldemort for false pretenses. Okay, Dwight your turn. Wow us.", "order": 14398, "speaker": "Michael Scott"},
  {"season": 3, "episode": 22, "scene": 53, "line": "The Aristocrats. A man and his wife and his children go into the offices of a talent agency. And the talent agent says, 'Describe your act.' And the man says something really, really raunchy and the talent representative says, 'What do you call yourselves?' And the man says, 'The Aristocrats!'", "order": 14399, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 22, "scene": 54, "line": "[breathes deeply and runs across coals] Oooh, uh hah, ooh! Wha-hoo! Ahhhh...", "order": 14400, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 22, "scene": 55, "line": "[finishes story] I mean truly repulsive acts.", "order": 14401, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 22, "scene": 55, "line": "That is a very, very funny story.", "order": 14402, "speaker": "Michael Scott"},
  {"season": 3, "episode": 22, "scene": 55, "line": "[runs over after walking over fire coals] Hey! I want to say something. I've been trying to be more honest lately and I just need to say a few things. I did the coal walk! Just, I did it! Michael, you couldn't even do that. Maybe I should be your boss? Wow, I feel really good right now. Why didn't any of you come to my art show? I invited all of you. That really sucked. It's like sometimes some of you act like I don't even exist. [turns and looks directly at Jim] Jim, I called off my wedding because of you. And now we're not even friends. And things are just like weird between us, and that sucks. And I miss you. You were my best friend before you went to Stamford, and I really miss you. I shouldn't have been with Roy. And there were a lot of reasons to call off my wedding. But the truth is, I didn't care about any of those reasons until I met you. And now you're with someone else. And that's... fine. It's... whatever. That's not what... I'm not... Okay, my feet really hurt. The thing that I'm just trying to say to you, Jim... and to everyone else in this circle I guess, is that I miss having fun with you. Just you, not everyone in the circle. Okay, I am going to go walk in the water now. Yeah. It's a good day.", "order": 14403, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 22, "scene": 55, "line": "Pam, that was amazing. But I am still looking for someone with a sales background.", "order": 14404, "speaker": "Michael Scott"},
  {"season": 3, "episode": 22, "scene": 56, "line": "Flintstones, meet the Flintstones. They're a modern stone-age family. Ba-ba-da-da-da. From the town of Bedrock, they're a page right out of history. Ba-da-da-da-da. [overlapping singing of the wrong verses] When you're with the Flintstones, have a yabba-dabba-doo time, a dabba-doo time, we'll have a gay old time!", "order": 14405, "speaker": "Group"},
  {"season": 3, "episode": 22, "scene": 56, "line": "WWWWIIIILLLLMMMMAAAA!", "order": 14406, "speaker": "Michael Scott"},
  {"season": 3, "episode": 22, "scene": 56, "line": "Nice!", "order": 14407, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 22, "scene": 57, "line": "So we have about an hour and a half. I suggest that you all go potty now and then we will be congregating on the party bus. Fun on wheels. Only way to travel. Besides a monorail.", "order": 14408, "speaker": "Michael Scott"},
  {"season": 3, "episode": 22, "scene": 57, "line": "Well, Bob was going to just drive me.", "order": 14409, "speaker": "Phyllis Vance"},
  {"season": 3, "episode": 22, "scene": 57, "line": "No. No, no, no. No guests today. Classic gang only. Just us good friends, and Karen and Andy.", "order": 14410, "speaker": "Michael Scott"},
  {"season": 3, "episode": 22, "scene": 58, "line": "Sometimes Michael gets nostalgic and he talks about the classic gang. That's Michael, me, Jim, Dwight, Angela, Kevin, Oscar, Stanley, Phyllis, Creed, Meredith, Kelly and Ryan. We're a regular Ocean's Eleven.", "order": 14411, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 22, "scene": 59, "line": "Jim, David Wallace is calling for you.", "order": 14412, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 22, "scene": 59, "line": "Oh, okay. Hello. Hey, David. Sure.", "order": 14413, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 22, "scene": 60, "line": "Pam, you broke up with your boyfriend and then kept working together. How did that work out?", "order": 14414, "speaker": "Michael Scott"},
  {"season": 3, "episode": 22, "scene": 60, "line": "You remember you fired Roy for attacking Jim and I don't talk to him anymore really.", "order": 14415, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 22, "scene": 60, "line": "Acrimonious.", "order": 14416, "speaker": "Michael Scott"},
  {"season": 3, "episode": 22, "scene": 61, "line": "This way to the party bus. Good.", "order": 14417, "speaker": "Michael Scott"},
  {"season": 3, "episode": 22, "scene": 61, "line": "[Sign says 'No Alcohol, No Loud Talking'] Um, What the hell?", "order": 14418, "speaker": "Meredith Palmer"},
  {"season": 3, "episode": 22, "scene": 61, "line": "It's a bus that takes you to a party. Here we go.", "order": 14419, "speaker": "Michael Scott"},
  {"season": 3, "episode": 22, "scene": 61, "line": "Um, I forgot my...", "order": 14420, "speaker": "Meredith Palmer"},
  {"season": 3, "episode": 22, "scene": 61, "line": "Here we go. Come on. Okay, everybody here?", "order": 14421, "speaker": "Michael Scott"},
  {"season": 3, "episode": 22, "scene": 61, "line": "Yeah!", "order": 14422, "speaker": "All"},
  {"season": 3, "episode": 22, "scene": 61, "line": "Then, Mr. Driver, we are off like a herd of turtles! Johnny Carson. [Meredith runs to catch up to bus with her large drink cup]", "order": 14423, "speaker": "Michael Scott"},
  {"season": 3, "episode": 22, "scene": 62, "line": "Seat belts fastened, folks. Safety first. Hey what is all this?", "order": 14424, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 22, "scene": 62, "line": "Nothing.", "order": 14425, "speaker": "Michael Scott"},
  {"season": 3, "episode": 22, "scene": 62, "line": "If I guess, will you tell me?", "order": 14426, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 22, "scene": 62, "line": "No.", "order": 14427, "speaker": "Michael Scott"},
  {"season": 3, "episode": 22, "scene": 62, "line": "Please?", "order": 14428, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 22, "scene": 62, "line": "No!", "order": 14429, "speaker": "Michael Scott"},
  {"season": 3, "episode": 22, "scene": 62, "line": "Is that a sumo suit?", "order": 14430, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 22, "scene": 62, "line": "No.", "order": 14431, "speaker": "Michael Scott"},
  {"season": 3, "episode": 22, "scene": 62, "line": "I know, we're putting on a play for Japanese investors.", "order": 14432, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 22, "scene": 62, "line": "No, we are not.", "order": 14433, "speaker": "Michael Scott"},
  {"season": 3, "episode": 22, "scene": 62, "line": "Please tell me. I've been so good...", "order": 14434, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 22, "scene": 62, "line": "You are really annoying me. If you don't knock it off...", "order": 14435, "speaker": "Michael Scott"},
  {"season": 3, "episode": 22, "scene": 62, "line": "Please.", "order": 14436, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 22, "scene": 62, "line": "...I'm never gonna tell you anything ever again. Stop it! Stop it! Stop it!", "order": 14437, "speaker": "Michael Scott"},
  {"season": 3, "episode": 22, "scene": 62, "line": "Please.", "order": 14438, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 22, "scene": 62, "line": "Can we please pull over at the next rest stop?", "order": 14439, "speaker": "Meredith Palmer"},
  {"season": 3, "episode": 22, "scene": 62, "line": "No. We're only 10 minutes from the lake.", "order": 14440, "speaker": "Michael Scott"},
  {"season": 3, "episode": 22, "scene": 62, "line": "Pull over! Pull over! [Meredith uses a bush while all the other passengers look the other way, except Creed]", "order": 14441, "speaker": "Meredith Palmer"},
  {"season": 3, "episode": 22, "scene": 63, "line": "Yes, I am choosing my apprentice. Which is why I have modeled all my games after Survivor.", "order": 14442, "speaker": "Michael Scott"},
  {"season": 3, "episode": 22, "scene": 64, "line": "Any questions?", "order": 14443, "speaker": "Michael Scott"},
  {"season": 3, "episode": 22, "scene": 64, "line": "Just one.", "order": 14444, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 22, "scene": 64, "line": "Yup.", "order": 14445, "speaker": "Michael Scott"},
  {"season": 3, "episode": 22, "scene": 64, "line": "The torches are lit.", "order": 14446, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 22, "scene": 64, "line": "Yes, for dramatic affect.", "order": 14447, "speaker": "Michael Scott"},
  {"season": 3, "episode": 22, "scene": 64, "line": "No, Michael, people are blindfolded. That's a hazard.", "order": 14448, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 22, "scene": 64, "line": "Very good, Jim. Pam, please note that Jim is very astute. All right, are we ready?", "order": 14449, "speaker": "Michael Scott"},
  {"season": 3, "episode": 22, "scene": 64, "line": "Are you not gonna put out the torches?", "order": 14450, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 22, "scene": 64, "line": "Set!", "order": 14451, "speaker": "Michael Scott"},
  {"season": 3, "episode": 22, "scene": 64, "line": "Okay.", "order": 14452, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 22, "scene": 64, "line": "Go!", "order": 14453, "speaker": "Michael Scott"},
  {"season": 3, "episode": 22, "scene": 65, "line": "I think Jim would be a good boss. Plus he's eye candy. It's OK, Bob... Vance knows he's on my list. Um, George Clooney, Leonardo DiCaprio, Jim, and that British guy that got in trouble with a prostitute.", "order": 14454, "speaker": "Phyllis Vance"},
  {"season": 3, "episode": 22, "scene": 66, "line": "It is an honor just to be considered for Michael's job. Honestly. And if I win it, then I will be ordering a pretty sizeable Most Improved Player plaque to put over the hole I punched in the wall.", "order": 14455, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 22, "scene": 67, "line": "[sighs] Okay. Pam, who came out best in the race, in your opinion?", "order": 14456, "speaker": "Michael Scott"},
  {"season": 3, "episode": 22, "scene": 67, "line": "Um, Karen left her blindfold on the longest but she also threw her egg at Jim.", "order": 14457, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 22, "scene": 67, "line": "Because he wasn't following the rules.", "order": 14458, "speaker": "Michael Scott"},
  {"season": 3, "episode": 22, "scene": 67, "line": "I think they were just having fun.", "order": 14459, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 22, "scene": 67, "line": "But they didn't complete their task, Pam! If people can't carry an egg in a spoon, blindfolded, then what does that tell you about how they will be able to manage a sales report, or conduct a business call?", "order": 14460, "speaker": "Michael Scott"},
  {"season": 3, "episode": 22, "scene": 67, "line": "It tells me nothing.", "order": 14461, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 22, "scene": 67, "line": "Exactly. Are you sure you're doing that right? Taking an awfully long time.", "order": 14462, "speaker": "Michael Scott"},
  {"season": 3, "episode": 22, "scene": 67, "line": "There's 800 of them!", "order": 14463, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 22, "scene": 67, "line": "Okay, all right.", "order": 14464, "speaker": "Michael Scott"},
  {"season": 3, "episode": 22, "scene": 68, "line": "It's very important that you all try to eat as many hotdogs as you can...", "order": 14465, "speaker": "Michael Scott"},
  {"season": 3, "episode": 22, "scene": 68, "line": "Hotdogs are really unhealthy.", "order": 14466, "speaker": "Meredith Palmer"},
  {"season": 3, "episode": 22, "scene": 68, "line": "Son of a bitch. God! Okay. All right you know what? Here we go. On your mark! Get set!", "order": 14467, "speaker": "Michael Scott"},
  {"season": 3, "episode": 22, "scene": 68, "line": "Is there any mustard?", "order": 14468, "speaker": "Phyllis Vance"},
  {"season": 3, "episode": 22, "scene": 68, "line": "No mustard! No mustard! Just... eat it. Eat it, Phyllis. Dip it in the water so it will slide down your gullet more easily.", "order": 14469, "speaker": "Michael Scott"},
  {"season": 3, "episode": 22, "scene": 68, "line": "That's what she said!", "order": 14470, "speaker": "Everyone"},
  {"season": 3, "episode": 22, "scene": 68, "line": "No, no, no, no, not that--- just... come on, you guys, let's do it! I'm serious. Who's got the hungry?", "order": 14471, "speaker": "Michael Scott"},
  {"season": 3, "episode": 22, "scene": 68, "line": "I do!", "order": 14472, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 22, "scene": 68, "line": "Who's got the hungry?", "order": 14473, "speaker": "Michael Scott"},
  {"season": 3, "episode": 22, "scene": 68, "line": "I do!", "order": 14474, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 22, "scene": 68, "line": "Come on, Ryan...", "order": 14475, "speaker": "Michael Scott"},
  {"season": 3, "episode": 22, "scene": 68, "line": "Let's go.", "order": 14476, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 22, "scene": 68, "line": "Ryan, I want to see you with a hot dog in your mouth. Right now.", "order": 14477, "speaker": "Michael Scott"},
  {"season": 3, "episode": 22, "scene": 69, "line": "Karen and I are having our own contest to see who can eat the most normal amount of tuna salad in an unspecified but very cofortable amount of time. I don't know what to tell you. Right now? Dead heat.", "order": 14478, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 22, "scene": 69, "line": "Keep eating tuna, Big Tuna. Loser!", "order": 14479, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 22, "scene": 69, "line": "He's gonna throw up.", "order": 14480, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 22, "scene": 70, "line": "[Dwight and Stanley are in sumo suits] Ready! Go!", "order": 14481, "speaker": "Michael Scott"},
  {"season": 3, "episode": 22, "scene": 71, "line": "The guy who sits behind me and the guy who sits across from me are fighting to see who becomes my boss. [laughs]", "order": 14482, "speaker": "Phyllis Vance"},
  {"season": 3, "episode": 22, "scene": 72, "line": "[Dwight knocks Stanley down] Yeah! Yeah! Yeah! Eat sand, you fat, old man!", "order": 14483, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 22, "scene": 73, "line": "Oh, I'm out of it. Let's face it, the hot dog-eating contest was my best shot.", "order": 14484, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 22, "scene": 74, "line": "Michael, since Andy isn't here, maybe I could be the team captain.", "order": 14485, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 22, "scene": 74, "line": "You? You haven't made a sale.", "order": 14486, "speaker": "Michael Scott"},
  {"season": 3, "episode": 22, "scene": 74, "line": "I know, but I'd like to give this a try.", "order": 14487, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 22, "scene": 74, "line": "Don't try to flirt your way into this. Sometimes you really creep me out.", "order": 14488, "speaker": "Michael Scott"},
  {"season": 3, "episode": 22, "scene": 75, "line": "[in sumo suit, waves down a car, which swerves around him, runs after it, waving his arms] Wait! [throws sumo hair-hat at the retreating car]", "order": 14489, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 22, "scene": 76, "line": "Andy Bernard is in first place with four hot dogs! Dwight Schrute is a close second with three and a half! Here comes Stanley! Here comes Stanley, with three hot dogs!", "order": 14490, "speaker": "Michael Scott"},
  {"season": 3, "episode": 22, "scene": 77, "line": "This is an inflatable sumo suit. [blows] Now, in the olden days, when they wanted to find a guy who could be king, they would have him pull a sword out of a stone. Well, times have changed. [blows] And it's not even about who is the best sumo wrestler. It's about who is the best boss. And I don't even care who wins. [blows] It's how they wrestle in a blow-up suit... [breathes in] that will tell me all I need to know [blows] or how sumo... [collapses]", "order": 14491, "speaker": "Michael Scott"},
  {"season": 3, "episode": 23, "scene": 1, "line": "[at Dunder-Mifflin Corporate in NY] David!", "order": 14492, "speaker": "Michael Scott"},
  {"season": 3, "episode": 23, "scene": 1, "line": "[confused] Oh, Michael?", "order": 14493, "speaker": "David Wallace"},
  {"season": 3, "episode": 23, "scene": 1, "line": "Are we all set?", "order": 14494, "speaker": "Michael Scott"},
  {"season": 3, "episode": 23, "scene": 1, "line": "Isn't our interview tomorrow?", "order": 14495, "speaker": "David Wallace"},
  {"season": 3, "episode": 23, "scene": 1, "line": "Yes. I just happened to be in the neighborhood, thought I'd drop in and say hello.", "order": 14496, "speaker": "Michael Scott"},
  {"season": 3, "episode": 23, "scene": 1, "line": "You happened to be in mid-town Mahattan?", "order": 14497, "speaker": "David Wallace"},
  {"season": 3, "episode": 23, "scene": 1, "line": "Thought I'd catch a show.", "order": 14498, "speaker": "Michael Scott"},
  {"season": 3, "episode": 23, "scene": 1, "line": "In the middle of a work day?", "order": 14499, "speaker": "David Wallace"},
  {"season": 3, "episode": 23, "scene": 1, "line": "Naaah. You know what? Since I'm here, let me ask you a few questions about the job.", "order": 14500, "speaker": "Michael Scott"},
  {"season": 3, "episode": 23, "scene": 1, "line": "Okay.", "order": 14501, "speaker": "David Wallace"},
  {"season": 3, "episode": 23, "scene": 1, "line": "Um, how many people are you interviewing?", "order": 14502, "speaker": "Michael Scott"},
  {"season": 3, "episode": 23, "scene": 1, "line": "We're only interviewing the branch managers and some other upper level company people.", "order": 14503, "speaker": "David Wallace"},
  {"season": 3, "episode": 23, "scene": 1, "line": "Ah, well, good. Out of curiosity are you interviewing anyone who has been here longer than I have, or manages more people?", "order": 14504, "speaker": "Michael Scott"},
  {"season": 3, "episode": 23, "scene": 1, "line": "I don't think so.", "order": 14505, "speaker": "David Wallace"},
  {"season": 3, "episode": 23, "scene": 1, "line": "Great. One more... question. When you merged those branches who did you put in charge?", "order": 14506, "speaker": "Michael Scott"},
  {"season": 3, "episode": 23, "scene": 1, "line": "I believe we put you in charge.", "order": 14507, "speaker": "David Wallace"},
  {"season": 3, "episode": 23, "scene": 1, "line": "Ah, great. No further questions.", "order": 14508, "speaker": "Michael Scott"},
  {"season": 3, "episode": 23, "scene": 1, "line": "Okay, Michael.", "order": 14509, "speaker": "David Wallace"},
  {"season": 3, "episode": 23, "scene": 1, "line": "Okay.", "order": 14510, "speaker": "Michael Scott"},
  {"season": 3, "episode": 23, "scene": 1, "line": "I'm really looking forward to our interview.", "order": 14511, "speaker": "David Wallace"},
  {"season": 3, "episode": 23, "scene": 1, "line": "And I'm really looking forward to working with you.", "order": 14512, "speaker": "Michael Scott"},
  {"season": 3, "episode": 23, "scene": 2, "line": "I got it made in the shade. I know this company. The other branch managers are total morons. [on cell phone] Hey, Pam yeah, I forgot what day the interview was and I drove to New York accidentially. I'll be like three hours late.", "order": 14513, "speaker": "Michael Scott"},
  {"season": 3, "episode": 23, "scene": 3, "line": "[Jim walk through the door sporting an obvious new haircut] Hey.", "order": 14514, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 23, "scene": 3, "line": "Hey, Kev.", "order": 14515, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 23, "scene": 3, "line": "What's different about you? You look worse.", "order": 14516, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 23, "scene": 3, "line": "Thank you.", "order": 14517, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 23, "scene": 3, "line": "You got a haircut. It's sexy, hot.", "order": 14518, "speaker": "Meredith Palmer"},
  {"season": 3, "episode": 23, "scene": 3, "line": "Ohhh...", "order": 14519, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 23, "scene": 3, "line": "Turn around.", "order": 14520, "speaker": "Meredith Palmer"},
  {"season": 3, "episode": 23, "scene": 3, "line": "No.", "order": 14521, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 23, "scene": 3, "line": "Yes.", "order": 14522, "speaker": "Meredith Palmer"},
  {"season": 3, "episode": 23, "scene": 3, "line": "No way.", "order": 14523, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 23, "scene": 3, "line": "Do it!", "order": 14524, "speaker": "Meredith Palmer"},
  {"season": 3, "episode": 23, "scene": 3, "line": "Blup-dup-do. What's up, Big Haircut? You are no longer Big Tuna. From henceforth, you shall be known as Big Haircut.", "order": 14525, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 23, "scene": 3, "line": "[exhales loudly] Andy...", "order": 14526, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 23, "scene": 3, "line": "What is it, Big Haircut?", "order": 14527, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 23, "scene": 3, "line": "Nothing.", "order": 14528, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 23, "scene": 3, "line": "Sorry, I can't hear you Big Haircut.", "order": 14529, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 23, "scene": 3, "line": "Yup.", "order": 14530, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 23, "scene": 3, "line": "What?", "order": 14531, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 23, "scene": 4, "line": "Karen suggested that I get a haircut for the interview tomorrow so that I could look presentable and not, as she so lovingly puts it, homeless.", "order": 14532, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 23, "scene": 5, "line": "Hey. I think it looks great.", "order": 14533, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 23, "scene": 5, "line": "Thanks, Pam.", "order": 14534, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 23, "scene": 6, "line": "After I had my little outburst at the beach, Jim was really nice about it. He just basically said that he missed my friendship too and I would always mean a lot to him and I understand where he's coming from. For the record, I am not embarassed at all. It needed to be said, and I said it, and it only took me three years to summons the courage, so [quietly, and mock bowing] thank you.", "order": 14535, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 23, "scene": 7, "line": "[knock on door] Yeah.", "order": 14536, "speaker": "Michael Scott"},
  {"season": 3, "episode": 23, "scene": 7, "line": "You wanted to see me?", "order": 14537, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 23, "scene": 7, "line": "Yes, the time has come to name my own replacement. So please hand this letter of congratulations to Dwight K. Schrute.", "order": 14538, "speaker": "Michael Scott"},
  {"season": 3, "episode": 23, "scene": 7, "line": "But that's my name. [opens letter and reads] Dwight, congratuations a-wipe. Don't screw the pooch. [begins to cry] Thank you.", "order": 14539, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 23, "scene": 7, "line": "Okay. Uh...", "order": 14540, "speaker": "Michael Scott"},
  {"season": 3, "episode": 23, "scene": 7, "line": "[cries harder] Thank you, Michael.", "order": 14541, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 23, "scene": 7, "line": "Uh, okay.", "order": 14542, "speaker": "Michael Scott"},
  {"season": 3, "episode": 23, "scene": 7, "line": "[sobbing, holding letter to chest] Thank you so much.", "order": 14543, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 23, "scene": 7, "line": "Stop crying.", "order": 14544, "speaker": "Michael Scott"},
  {"season": 3, "episode": 23, "scene": 7, "line": "[sobbing] Thank you.", "order": 14545, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 23, "scene": 7, "line": "Ohhhhh...", "order": 14546, "speaker": "Michael Scott"},
  {"season": 3, "episode": 23, "scene": 8, "line": "Hey.", "order": 14547, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 23, "scene": 8, "line": "Hey.", "order": 14548, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 23, "scene": 8, "line": "Um, about the beach...", "order": 14549, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 23, "scene": 8, "line": "It's okay, we all say things without thinking.", "order": 14550, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 23, "scene": 8, "line": "Oh, no it's not that, I've actually been thinking that for a long time, and I'm glad I said it. I just... I'm sorry if it made you feel weird.", "order": 14551, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 23, "scene": 8, "line": "[confused] Oh. Okay.", "order": 14552, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 23, "scene": 9, "line": "Pam is... kind of a bitch.", "order": 14553, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 23, "scene": 10, "line": "Hey, what if we leave tonight? Grab a bite, get a hotel room, enjoy the city a little bit.", "order": 14554, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 23, "scene": 10, "line": "Ahhh... just have so much paperwork to do. [exhales] Wow. Done. Okay, let's do it.", "order": 14555, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 23, "scene": 11, "line": "So I was wondering if Karen and I could get off a few hours early 'cause we want to spend the night in the city.", "order": 14556, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 23, "scene": 11, "line": "Why so you can do it?", "order": 14557, "speaker": "Michael Scott"},
  {"season": 3, "episode": 23, "scene": 11, "line": "[looks annoyed]", "order": 14558, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 23, "scene": 11, "line": "Whoops.", "order": 14559, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 23, "scene": 11, "line": "No, um, well I was thinking that uh, actually we could all leave tomorrow and do a convoy you know? Convoys are really fun. Pull up next to each other, give each other the finger...", "order": 14560, "speaker": "Michael Scott"},
  {"season": 3, "episode": 23, "scene": 11, "line": "Hmmm.", "order": 14561, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 23, "scene": 11, "line": "Heh.", "order": 14562, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 23, "scene": 11, "line": "Moon each other.", "order": 14563, "speaker": "Michael Scott"},
  {"season": 3, "episode": 23, "scene": 11, "line": "Ah, we're gonna go tonight, but we're gonna see you there tomorrow morning, right?", "order": 14564, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 23, "scene": 11, "line": "All right.", "order": 14565, "speaker": "Michael Scott"},
  {"season": 3, "episode": 23, "scene": 11, "line": "All right.", "order": 14566, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 23, "scene": 11, "line": "Your loss.", "order": 14567, "speaker": "Michael Scott"},
  {"season": 3, "episode": 23, "scene": 11, "line": "Wait, how would you moon us if you were driving?", "order": 14568, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 23, "scene": 11, "line": "Cruise control.", "order": 14569, "speaker": "Michael Scott"},
  {"season": 3, "episode": 23, "scene": 11, "line": "Oh.", "order": 14570, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 23, "scene": 12, "line": "So Jim, who do you think is hotter? Pam or Karen?", "order": 14571, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 23, "scene": 12, "line": "Yeah, I'm not going to talk about this now.", "order": 14572, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 23, "scene": 12, "line": "Pam is taller.", "order": 14573, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 23, "scene": 12, "line": "You sure?", "order": 14574, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 23, "scene": 12, "line": "Yes. She has bigger breasts, too.", "order": 14575, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 23, "scene": 12, "line": "Wow.", "order": 14576, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 23, "scene": 12, "line": "I think Karen has a prettier face.", "order": 14577, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 23, "scene": 12, "line": "Uh, hmm. [thinking] Uh, hmm. What else?", "order": 14578, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 23, "scene": 12, "line": "Well I mean Pam's face is really pretty too. It's a very tough call.", "order": 14579, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 23, "scene": 12, "line": "Hm. Really tough call.", "order": 14580, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 23, "scene": 12, "line": "Yeah.", "order": 14581, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 23, "scene": 12, "line": "You know what? Why don't you take the rest of the day, figure it out and then come back and tell me what you got.", "order": 14582, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 23, "scene": 12, "line": "Will do.", "order": 14583, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 23, "scene": 12, "line": "All right. [smiles]", "order": 14584, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 23, "scene": 13, "line": "Hey, Pam I've been meaning to say something to you. I really miss our friendship. [group laughs]", "order": 14585, "speaker": "Oscar Martinez"},
  {"season": 3, "episode": 23, "scene": 13, "line": "Wow, very funny.", "order": 14586, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 23, "scene": 13, "line": "I've never heard you talk that much. I thought it was Kelly.", "order": 14587, "speaker": "Stanley Hudson"},
  {"season": 3, "episode": 23, "scene": 13, "line": "Are you kidding? I would have never done that. It was pathetic-ville. No offense, Pam.", "order": 14588, "speaker": "Kelly Kapoor"},
  {"season": 3, "episode": 23, "scene": 13, "line": "[smiles]", "order": 14589, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 23, "scene": 13, "line": "You know what? Don't even worry about it. Everyone was so drunk, I bet no one even remembers what you said.", "order": 14590, "speaker": "Meredith Palmer"},
  {"season": 3, "episode": 23, "scene": 13, "line": "I remember. I blogged the whole thing.", "order": 14591, "speaker": "Creed Bratton"},
  {"season": 3, "episode": 23, "scene": 14, "line": "www.creedthoughts.gov.www/creedthoughts. Check it out.", "order": 14592, "speaker": "Creed Bratton"},
  {"season": 3, "episode": 23, "scene": 15, "line": "Last year Creed asked me how to set up a blog. Wanting to protect the world from being exposed to Creed's brain I opened up a Word document on his computer and put an address at the top. I've read some of it. Even for the intranet it's... pretty shocking.", "order": 14593, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 23, "scene": 16, "line": "There they are, the Accounting Department. I shall miss your humility and your promptly printed checks.", "order": 14594, "speaker": "Michael Scott"},
  {"season": 3, "episode": 23, "scene": 16, "line": "Do you think it's gonna to be weird working with Jan in New York?", "order": 14595, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 23, "scene": 16, "line": "No. Not at all. I haven't talked to Jan since we broke up, and I think if she had something to say she would have called me.", "order": 14596, "speaker": "Michael Scott"},
  {"season": 3, "episode": 23, "scene": 16, "line": "Maybe you should talk to her? Before...", "order": 14597, "speaker": "Oscar Martinez"},
  {"season": 3, "episode": 23, "scene": 16, "line": "No, no, no. You know what? It's a done deal. I basically have the job already. There's nothing she can do to stop it now. I already sold my condo.", "order": 14598, "speaker": "Michael Scott"},
  {"season": 3, "episode": 23, "scene": 16, "line": "Michael...", "order": 14599, "speaker": "Oscar Martinez"},
  {"season": 3, "episode": 23, "scene": 16, "line": "What?", "order": 14600, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 23, "scene": 16, "line": "Why?", "order": 14601, "speaker": "Angela Martin"},
  {"season": 3, "episode": 23, "scene": 16, "line": "I'm sorry, that just doesn't make sense.", "order": 14602, "speaker": "Oscar Martinez"},
  {"season": 3, "episode": 23, "scene": 16, "line": "Yes, I...", "order": 14603, "speaker": "Michael Scott"},
  {"season": 3, "episode": 23, "scene": 16, "line": "Wha? Who gave you that advice?", "order": 14604, "speaker": "Angela Martin"},
  {"season": 3, "episode": 23, "scene": 16, "line": "Yeah, Michael you should never sell your condo...", "order": 14605, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 23, "scene": 16, "line": "I have to buy another place.", "order": 14606, "speaker": "Michael Scott"},
  {"season": 3, "episode": 23, "scene": 16, "line": "But you said you were in debt. [crosstalk]", "order": 14607, "speaker": "Angela Martin"},
  {"season": 3, "episode": 23, "scene": 16, "line": "You're not sure that you have the job.", "order": 14608, "speaker": "Oscar Martinez"},
  {"season": 3, "episode": 23, "scene": 16, "line": "I sold it on eBay. The buyer was very motivated, as was I. It went for eighty percent of what I paid. Sold in record time.", "order": 14609, "speaker": "Michael Scott"},
  {"season": 3, "episode": 23, "scene": 17, "line": "How would you like to spend the night with the Regional Manager of Dunder-Mifflin, Scranton?", "order": 14610, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 23, "scene": 17, "line": "No Dwight, I don't care if that is how they consolidated power in ancient Rome.", "order": 14611, "speaker": "Angela Martin"},
  {"season": 3, "episode": 23, "scene": 17, "line": "No, no, not Michael. [whispers] Me. I'm taking his job.", "order": 14612, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 23, "scene": 17, "line": "[smiles] Not now. [Dwight leaves] Goodbye, Kelly Kapoor.", "order": 14613, "speaker": "Angela Martin"},
  {"season": 3, "episode": 23, "scene": 18, "line": "[squeezing hand grips] Jim, Jim, Jim. Jim, Jim, Jim, Jim, Jim, Jim, Jim.", "order": 14614, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 23, "scene": 18, "line": "Oh hey, Dwight.", "order": 14615, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 23, "scene": 18, "line": "I am gonna be your new boss. [laughs] It's my greatest dream come true. Welcome to the Hotel Hell. Check in time is now. Check out time is never.", "order": 14616, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 23, "scene": 18, "line": "Does my room have cable?", "order": 14617, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 23, "scene": 18, "line": "No. And the sheets are made of fire.", "order": 14618, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 23, "scene": 18, "line": "Can I change rooms?", "order": 14619, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 23, "scene": 18, "line": "Sorry, we're all booked up. Hell convention in town.", "order": 14620, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 23, "scene": 18, "line": "Can I have a late checkout?", "order": 14621, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 23, "scene": 18, "line": "I'll have to talk to the manager.", "order": 14622, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 23, "scene": 18, "line": "You're not the manager even in your own fantasy?", "order": 14623, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 23, "scene": 18, "line": "I'm the owner. The co-owner. With Satan!", "order": 14624, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 23, "scene": 18, "line": "Okay, just so I understand it, in your wildest fantasy you are in Hell and you are co-running a bed and breakfast with the devil?", "order": 14625, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 23, "scene": 18, "line": "Yeah, but I haven't told you my salary yet.", "order": 14626, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 23, "scene": 18, "line": "Go.", "order": 14627, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 23, "scene": 18, "line": "Eighty thousand dollars a year.", "order": 14628, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 23, "scene": 19, "line": "Once I'm officially Regional Manager my first order of business will be to demote Jim Halpert. So I will need a new number two. My ideal choice? Jack Bauer. But he is unavailable. Fictional. And overqualified.", "order": 14629, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 23, "scene": 20, "line": "[signing interview sheet] I will see you at the inter-view.", "order": 14630, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 23, "scene": 20, "line": "Yes you will.", "order": 14631, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 23, "scene": 21, "line": "Who is D. Abramson?", "order": 14632, "speaker": "Michael Scott"},
  {"season": 3, "episode": 23, "scene": 21, "line": "He's from that company in Pitts...", "order": 14633, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 23, "scene": 21, "line": "[walks through front door] Michael.", "order": 14634, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 23, "scene": 21, "line": "Why... are you here?", "order": 14635, "speaker": "Michael Scott"},
  {"season": 3, "episode": 23, "scene": 21, "line": "Uh, how are you?", "order": 14636, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 23, "scene": 21, "line": "I'm good. How are you, Janet? It's good to see you.", "order": 14637, "speaker": "Michael Scott"},
  {"season": 3, "episode": 23, "scene": 21, "line": "I'm great. Uh, can we, can we talk... privately... for a minute?", "order": 14638, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 23, "scene": 21, "line": "Why privately?", "order": 14639, "speaker": "Michael Scott"},
  {"season": 3, "episode": 23, "scene": 21, "line": "I just, [quietly] I uh... I don't uh... I, I don't, I don't like the way that we left things.", "order": 14640, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 23, "scene": 21, "line": "Okay, sure, fine. Um, why don't you wait in my office. I have some important business matters to take care of.", "order": 14641, "speaker": "Michael Scott"},
  {"season": 3, "episode": 23, "scene": 21, "line": "Okay.", "order": 14642, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 23, "scene": 21, "line": "Okay.", "order": 14643, "speaker": "Michael Scott"},
  {"season": 3, "episode": 23, "scene": 22, "line": "Pam, Defcon ten. Houston, we have a problem.", "order": 14644, "speaker": "Michael Scott"},
  {"season": 3, "episode": 23, "scene": 22, "line": "What do you want me to do?", "order": 14645, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 23, "scene": 22, "line": "Uh, I may need some immediate assistance. If you would slowly and quietly gather the ladies in the conference room. Phyllis, Angela, Karen.", "order": 14646, "speaker": "Michael Scott"},
  {"season": 3, "episode": 23, "scene": 22, "line": "What about Meredith?", "order": 14647, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 23, "scene": 22, "line": "No. She's an alternate.", "order": 14648, "speaker": "Michael Scott"},
  {"season": 3, "episode": 23, "scene": 23, "line": "So, how you been?", "order": 14649, "speaker": "Michael Scott"},
  {"season": 3, "episode": 23, "scene": 23, "line": "Been good.", "order": 14650, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 23, "scene": 23, "line": "Good.", "order": 14651, "speaker": "Michael Scott"},
  {"season": 3, "episode": 23, "scene": 23, "line": "Good.", "order": 14652, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 23, "scene": 23, "line": "Yeah.", "order": 14653, "speaker": "Michael Scott"},
  {"season": 3, "episode": 23, "scene": 23, "line": "Sorry to drop by unexpectedly. I uh, I tried calling, but I kept getting voice mail.", "order": 14654, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 23, "scene": 23, "line": "Weird. Yeah, I didn't get both of your messages.", "order": 14655, "speaker": "Michael Scott"},
  {"season": 3, "episode": 23, "scene": 24, "line": "So, um, Michael needs us in the conference room.", "order": 14656, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 23, "scene": 24, "line": "Cause of Jan again?", "order": 14657, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 23, "scene": 24, "line": "Hey, uh, before I leave tonight, would you mind making a half a dozen copies of Jim's and my sales reports for our interviews tomorrow?", "order": 14658, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 23, "scene": 24, "line": "Uh, sure.", "order": 14659, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 23, "scene": 24, "line": "Thanks.", "order": 14660, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 23, "scene": 24, "line": "I really hope you get the job.", "order": 14661, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 23, "scene": 24, "line": "Thanks. [looks suspicious]", "order": 14662, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 23, "scene": 25, "line": "After you ended... everything with us, I went on a vacation.", "order": 14663, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 23, "scene": 25, "line": "Hmm.", "order": 14664, "speaker": "Michael Scott"},
  {"season": 3, "episode": 23, "scene": 25, "line": "To kinda clear my mind.", "order": 14665, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 23, "scene": 25, "line": "Sound good. Sounds fun.", "order": 14666, "speaker": "Michael Scott"},
  {"season": 3, "episode": 23, "scene": 25, "line": "It was. Yeah, it was good. [laughs] I think I'll just get right to the point, you know? Um, I, I feel good about myself for the first time in a really long time.", "order": 14667, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 23, "scene": 25, "line": "Hmm..", "order": 14668, "speaker": "Michael Scott"},
  {"season": 3, "episode": 23, "scene": 25, "line": "I've made some big changes in my life and... I miss you. I want us to get back together.", "order": 14669, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 23, "scene": 25, "line": "Would you excuse me for a second?", "order": 14670, "speaker": "Michael Scott"},
  {"season": 3, "episode": 23, "scene": 25, "line": "Ohh... [Michael leaves and walks into conference room]", "order": 14671, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 23, "scene": 26, "line": "Defcon twenty. She wants to get back together.", "order": 14672, "speaker": "Michael Scott"},
  {"season": 3, "episode": 23, "scene": 26, "line": "What are you gonna do?", "order": 14673, "speaker": "Phyllis Vance"},
  {"season": 3, "episode": 23, "scene": 26, "line": "I don't know, that's why you're here, help me. Please...", "order": 14674, "speaker": "Michael Scott"},
  {"season": 3, "episode": 23, "scene": 26, "line": "Do you want to get back together with her?", "order": 14675, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 23, "scene": 26, "line": "No, no. What do I do?", "order": 14676, "speaker": "Michael Scott"},
  {"season": 3, "episode": 23, "scene": 26, "line": "Just don't get back together with her.", "order": 14677, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 23, "scene": 26, "line": "What if she makes me?", "order": 14678, "speaker": "Michael Scott"},
  {"season": 3, "episode": 23, "scene": 26, "line": "How can she make you get back together with her?", "order": 14679, "speaker": "Angela Martin"},
  {"season": 3, "episode": 23, "scene": 26, "line": "She made me do a lot of things I didn't wanna do.", "order": 14680, "speaker": "Michael Scott"},
  {"season": 3, "episode": 23, "scene": 26, "line": "This was a terrible relationship. You were not happy when you were with her.", "order": 14681, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 23, "scene": 26, "line": "I wasn't.", "order": 14682, "speaker": "Michael Scott"},
  {"season": 3, "episode": 23, "scene": 26, "line": "You're so much happier now. Just go in there and be strong.", "order": 14683, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 23, "scene": 26, "line": "You're right. You're absolutely right. I'm gonna go in there and tell her that we can't be together.", "order": 14684, "speaker": "Michael Scott"},
  {"season": 3, "episode": 23, "scene": 26, "line": "Right.", "order": 14685, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 23, "scene": 26, "line": "Wow.", "order": 14686, "speaker": "Michael Scott"},
  {"season": 3, "episode": 23, "scene": 26, "line": "Do it.", "order": 14687, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 23, "scene": 26, "line": "I'm in a very good place right now. Thank you.", "order": 14688, "speaker": "Michael Scott"},
  {"season": 3, "episode": 23, "scene": 27, "line": "[walks back into office with Jan] Okay. [clears throat] Jan, we need to talk. [Jan turns revealing that she has had her breasts enlarged]", "order": 14689, "speaker": "Michael Scott"},
  {"season": 3, "episode": 23, "scene": 28, "line": "Jan is in a different place right now, and it is a sign of maturity to give people second chances. So I am going to hear her out.", "order": 14690, "speaker": "Michael Scott"},
  {"season": 3, "episode": 23, "scene": 29, "line": "Oh my God. [mouths to Jim]", "order": 14691, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 23, "scene": 29, "line": "Yeah, bigger actually.", "order": 14692, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 23, "scene": 29, "line": "[whispers] That's crazy!", "order": 14693, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 23, "scene": 29, "line": "Mm-hm.", "order": 14694, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 23, "scene": 29, "line": "[mouths] Wow!", "order": 14695, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 23, "scene": 29, "line": "[moves to block Jim from Pam's eyeline] Oh my God. Can you believe that?", "order": 14696, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 23, "scene": 29, "line": "Unbelievable.", "order": 14697, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 23, "scene": 29, "line": "Wow!", "order": 14698, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 23, "scene": 29, "line": "She could put the cup right there.", "order": 14699, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 23, "scene": 29, "line": "I know!", "order": 14700, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 23, "scene": 30, "line": "No, it's fine. I'm sure it must have been weird for Jim when Roy and I were joking around... that", "order": 14701, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 23, "scene": 31, "line": "I would never do that. Waste of money. In my experience, guys are way more attracted to the back of you than the front.", "order": 14702, "speaker": "Meredith Palmer"},
  {"season": 3, "episode": 23, "scene": 32, "line": "I love fake boobs. Often times, you find them on strippers.", "order": 14703, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 23, "scene": 33, "line": "I find it offensive. Au natural, baby. That's how I like 'em. Swing low, sweet chariots.", "order": 14704, "speaker": "Creed Bratton"},
  {"season": 3, "episode": 23, "scene": 34, "line": "So... [exhales] there are a lot of things that I would like to do differently.", "order": 14705, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 23, "scene": 34, "line": "Yeah.", "order": 14706, "speaker": "Michael Scott"},
  {"season": 3, "episode": 23, "scene": 34, "line": "I feel like, when we first got together, that I had a problem with my priorities.", "order": 14707, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 23, "scene": 34, "line": "First got priorities.", "order": 14708, "speaker": "Michael Scott"},
  {"season": 3, "episode": 23, "scene": 34, "line": "And if we could talk about a way to approach a possible reconciliation...", "order": 14709, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 23, "scene": 34, "line": "Let's get back together. [Jan smiles and laughs]", "order": 14710, "speaker": "Michael Scott"},
  {"season": 3, "episode": 23, "scene": 35, "line": "No. No, no, no, no. I'll tell you this, it is", "order": 14711, "speaker": "Michael Scott"},
  {"season": 3, "episode": 23, "scene": 36, "line": "Okay, everyone, listen up! [claps] Time to begin the interview process! [looks at sign-up sheet, only contains Andy's name] Andrew Bernard.", "order": 14712, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 23, "scene": 36, "line": "Saving the best for first!", "order": 14713, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 23, "scene": 36, "line": "All right, you guys. See you later, wish us luck!", "order": 14714, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 23, "scene": 36, "line": "No! Hey, hey, hey, hey! Do not wish them luck. Do not wish them luck. All luck must be wished unto Michael.", "order": 14715, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 23, "scene": 36, "line": "Good luck, you guys! [everyone wishes them luck]", "order": 14716, "speaker": "Oscar Martinez"},
  {"season": 3, "episode": 23, "scene": 36, "line": "What did I say!?", "order": 14717, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 23, "scene": 36, "line": "[to Jim] Good luck.", "order": 14718, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 23, "scene": 36, "line": "Thanks.", "order": 14719, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 23, "scene": 37, "line": "I'm happy for him. I hope he gets the job. I really just want him to be happy. And I know that sounds cliche. And I know saying it sounds cliche, sounds cliche... Maybe I'm being cliche. I don't care. Cause I am what I am. [thinks] That's Popeye.", "order": 14720, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 23, "scene": 38, "line": "I am going to begin this process with a simple test of intelligence and mental dexterity. What is the best color?", "order": 14721, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 23, "scene": 38, "line": "White, because it contains all other colors.", "order": 14722, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 23, "scene": 38, "line": "Wrong. Black. It is the most dominant. How do you make a table?", "order": 14723, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 23, "scene": 38, "line": "You make a chair, but you don't sit on it.", "order": 14724, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 23, "scene": 38, "line": "What is the capital of Maine?", "order": 14725, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 23, "scene": 38, "line": "The capital of Maine is Montpelier, Vermont, which is near Ithaca, New York, where I went to Cornell.", "order": 14726, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 23, "scene": 38, "line": "Okay, also, moratorium on Cornell talk. Don't wanna hear about it. Forget your personal history, and learn the history of this company.", "order": 14727, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 23, "scene": 38, "line": "Should not be a problem. I minored in history in the Ivy League school which I attended.", "order": 14728, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 23, "scene": 38, "line": "You're not off to a very good start, Bernard.", "order": 14729, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 23, "scene": 38, "line": "I agree. But in another way, I am off to a very good start, wouldn't you say?", "order": 14730, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 23, "scene": 39, "line": "I am a great interviewee. Why? Because I have something no one else has: my brain. Which I use to my advantage, when advantageous.", "order": 14731, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 23, "scene": 40, "line": "[Andy and Dwight arm-wrestle, Andy is about to win] Time! No, you failed.", "order": 14732, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 23, "scene": 40, "line": "Damn it!", "order": 14733, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 23, "scene": 40, "line": "This interview is over. I'll let you know.", "order": 14734, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 23, "scene": 41, "line": "[Driving to New York] Hey, thank you so much for driving me down for my interview.", "order": 14735, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 23, "scene": 41, "line": "Totally, no problem. What are you interviewing for, by the way, my assistant? Or...", "order": 14736, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 23, "scene": 41, "line": "Oh, you know I'm gonna have a congratulations Karen party um, tomorrow night at my friend's house.", "order": 14737, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 23, "scene": 41, "line": "Oh wow, that sounds like fun. Is your friend named Karen too? What did she accomplish?", "order": 14738, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 23, "scene": 41, "line": "No, I'm sorry I should have been clearer. It's for me.", "order": 14739, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 23, "scene": 41, "line": "Oh...", "order": 14740, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 23, "scene": 41, "line": "Cause I'm going to get the job.", "order": 14741, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 23, "scene": 42, "line": "[holding Jan's breasts] Remarkable.", "order": 14742, "speaker": "Michael Scott"},
  {"season": 3, "episode": 23, "scene": 42, "line": "Thanks.", "order": 14743, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 23, "scene": 42, "line": "Wait, wait. Check one more time.", "order": 14744, "speaker": "Michael Scott"},
  {"season": 3, "episode": 23, "scene": 42, "line": "Oh, okay.", "order": 14745, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 23, "scene": 42, "line": "Very good [Jan nods]", "order": 14746, "speaker": "Michael Scott"},
  {"season": 3, "episode": 23, "scene": 42, "line": "Well, I um, I have to get back, but I will see you in New York tomorrow, right?", "order": 14747, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 23, "scene": 42, "line": "Yes, indeed.", "order": 14748, "speaker": "Michael Scott"},
  {"season": 3, "episode": 23, "scene": 42, "line": "Good luck with your interview. [whispers] Bye.", "order": 14749, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 23, "scene": 43, "line": "So, I guess we're getting back together.", "order": 14750, "speaker": "Michael Scott"},
  {"season": 3, "episode": 23, "scene": 43, "line": "What happened?", "order": 14751, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 23, "scene": 43, "line": "Your advice was good, but Jan's was bigger.", "order": 14752, "speaker": "Michael Scott"},
  {"season": 3, "episode": 23, "scene": 44, "line": "Here's the sitch. Two weeks ago, I was in the worst relationship of my life. She treated me poorly. We didn't connect, I was miserable. Now, I'm in the best relationship of my life with the same woman. Love is a mystery.", "order": 14753, "speaker": "Michael Scott"},
  {"season": 3, "episode": 23, "scene": 45, "line": "So we have all night. Where do you want to go first?", "order": 14754, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 23, "scene": 45, "line": "Oh, I dunno, how about the U.N.?", "order": 14755, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 23, "scene": 45, "line": "How often do you come here?", "order": 14756, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 23, "scene": 45, "line": "Um, everytime my sixth grade class has a field trip.", "order": 14757, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 23, "scene": 45, "line": "I think you'll really enjoy this, adult Jim.", "order": 14758, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 23, "scene": 46, "line": "Hey, everybody. The next time you see me, I'll be working for corporate. Starting tomorrow, Dwight Schrute will be running the branch. So without further ado, [clicks on small tape player] I would like to start saying my goodbyes [Song: Thank You, by Natalie Merchant plays in background] Okay. Goodnight, and good luck.", "order": 14759, "speaker": "Michael Scott"},
  {"season": 3, "episode": 23, "scene": 47, "line": "[Dwight clicks off tape player] Who's ready to work?", "order": 14760, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 23, "scene": 48, "line": "[Karen punches buttons on an ATM] Yeah, we went to the Spotted Pig for dinner. It's in the Village. Uh, Karen knew it. And then we second acted Spamalot. That's when you sneak in at intermission with all the smokers. And then we went to a bar that used to be a church. Oh and at this one bar, I swear I saw Lorne Michaels.", "order": 14761, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 23, "scene": 48, "line": "That wasn't him. [Jim nods, mouths, yes, it was]", "order": 14762, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 23, "scene": 49, "line": "So what's going to happen to us when I get this job?", "order": 14763, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 23, "scene": 49, "line": "Oh do you mean when I get the job?", "order": 14764, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 23, "scene": 49, "line": "Well,", "order": 14765, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 23, "scene": 49, "line": "You mean Kevin?", "order": 14766, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 23, "scene": 49, "line": "Exactly. But you get it, right? Can't stay there.", "order": 14767, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 23, "scene": 49, "line": "Yeah, I do. C'mon. [they hold hands and cross street]", "order": 14768, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 23, "scene": 50, "line": "[exhales] How are you guys doing? Need anything?", "order": 14769, "speaker": "Michael Scott"},
  {"season": 3, "episode": 23, "scene": 50, "line": "Uh, no, we're good. Thanks.", "order": 14770, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 23, "scene": 50, "line": "I have been here a bunch of times, so I know where everything is, know everybody's names. If you need to know somebody's name, just ask me.", "order": 14771, "speaker": "Michael Scott"},
  {"season": 3, "episode": 23, "scene": 50, "line": "[points at bearded man] Who's that?", "order": 14772, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 23, "scene": 50, "line": "That", "order": 14773, "speaker": "Michael Scott"},
  {"season": 3, "episode": 23, "scene": 50, "line": "Beardy?", "order": 14774, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 23, "scene": 50, "line": "Mm-hmm.", "order": 14775, "speaker": "Michael Scott"},
  {"season": 3, "episode": 23, "scene": 50, "line": "I'm gonna introduce myself.", "order": 14776, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 23, "scene": 50, "line": "No, no, no, just... that's not his real name. That's just what I call him, so...", "order": 14777, "speaker": "Michael Scott"},
  {"season": 3, "episode": 23, "scene": 51, "line": "I am by far the most qualified person they're interviewing. Jim and Karen are here, which is cute. They're like, kid actors tagging along with daddy, on the big audition, hoping to be discovered. Except daddy... is the best actor around. Daddy is Meryl Streep.", "order": 14778, "speaker": "Michael Scott"},
  {"season": 3, "episode": 23, "scene": 52, "line": "Michael is gone.", "order": 14779, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 23, "scene": 52, "line": "Hail to the chief!", "order": 14780, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 23, "scene": 52, "line": "My first order of business: make Andrew Bernard my number two.", "order": 14781, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 23, "scene": 52, "line": "My first order of business: accept.", "order": 14782, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 23, "scene": 52, "line": "As if you had a choice. [scoffs] Duh. [scoffs] Opportunity of a lifetime. [spits chew into Michael's World's Best Boss mug]", "order": 14783, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 23, "scene": 53, "line": "Three months ago, I was nowhere. I was just a Cornell grad, in anger management. Look where I am now. Not bad. [washing out Michael's mug]", "order": 14784, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 23, "scene": 54, "line": "Hey! Hunter! Wha's up my brutha? This is Hunter, secretary extraordinaire.", "order": 14785, "speaker": "Michael Scott"},
  {"season": 3, "episode": 23, "scene": 54, "line": "Uh, administrative assistant.", "order": 14786, "speaker": "Hunter"},
  {"season": 3, "episode": 23, "scene": 54, "line": "Jan in yet?", "order": 14787, "speaker": "Michael Scott"},
  {"season": 3, "episode": 23, "scene": 54, "line": "I think she's comin' in... later.", "order": 14788, "speaker": "Hunter"},
  {"season": 3, "episode": 23, "scene": 54, "line": "Could you give her a message for me, when she gets in?", "order": 14789, "speaker": "Michael Scott"},
  {"season": 3, "episode": 23, "scene": 54, "line": "Sure.", "order": 14790, "speaker": "Hunter"},
  {"season": 3, "episode": 23, "scene": 54, "line": "Just say, 'I want to squeeze them.' It's code. She'll know what it means.", "order": 14791, "speaker": "Michael Scott"},
  {"season": 3, "episode": 23, "scene": 54, "line": "Okay.", "order": 14792, "speaker": "Hunter"},
  {"season": 3, "episode": 23, "scene": 54, "line": "Oh, and Hunter? Could you tell her, [Michael shakes his head and makes the 'motorboat' noise] 'Brbrbrbrbr!' also?", "order": 14793, "speaker": "Michael Scott"},
  {"season": 3, "episode": 23, "scene": 55, "line": "You showed great leadership potential at the coal walk. Even if you did follow it with that embarrassing personal confession.", "order": 14794, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 23, "scene": 55, "line": "Thank you.", "order": 14795, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 23, "scene": 55, "line": "I had to make Andy my number two. It's political, complicated, you wouldn't understand. I want you... to be Assistant Regional Manager.", "order": 14796, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 23, "scene": 55, "line": "Really?", "order": 14797, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 23, "scene": 55, "line": "Well, in a sense. Although, publicly I am going to retain the Assistant Regional Manager position.", "order": 14798, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 23, "scene": 55, "line": "You will be your own assistant.", "order": 14799, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 23, "scene": 55, "line": "Correct, I need someone I can trust. But I would also like the title... to be secretly applied to you. Just stripped of its pomp and frills.", "order": 14800, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 23, "scene": 55, "line": "Okay. So... you would be the Regional Manager,", "order": 14801, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 23, "scene": 55, "line": "Mmmmmm, let's call it Secret Assistant", "order": 14802, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 23, "scene": 55, "line": "Mm-hmm.", "order": 14803, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 23, "scene": 55, "line": "Do you accept?", "order": 14804, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 23, "scene": 55, "line": "Absolutely, I do.", "order": 14805, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 23, "scene": 56, "line": "I learned from Jim, if Dwight ever asks you to accept something secret... you reply, 'Absolutely, I do.'", "order": 14806, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 23, "scene": 57, "line": "[knocks on door] Hello, hello!", "order": 14807, "speaker": "Michael Scott"},
  {"season": 3, "episode": 23, "scene": 57, "line": "Michael, good to see you!", "order": 14808, "speaker": "David Wallace"},
  {"season": 3, "episode": 23, "scene": 57, "line": "Good to see you. Here are the post-merger performance stats that you asked for.", "order": 14809, "speaker": "Michael Scott"},
  {"season": 3, "episode": 23, "scene": 57, "line": "Oh, great. I've been meaning to thank you by the way. You didn't lose a single customer during the merger, and you still managed to trim the budget? That is nice work. [Michael smiles] So, let me ask you a question right off the bat. What do you think are your greatest strengths as a manager?", "order": 14810, "speaker": "David Wallace"},
  {"season": 3, "episode": 23, "scene": 57, "line": "Why don't I tell you what my greatest weaknesses are? I work too hard. I care too much. And sometimes I can be too invested in my job.", "order": 14811, "speaker": "Michael Scott"},
  {"season": 3, "episode": 23, "scene": 57, "line": "Okay. And your strengths?", "order": 14812, "speaker": "David Wallace"},
  {"season": 3, "episode": 23, "scene": 57, "line": "Well, my weaknesses are actually... strengths.", "order": 14813, "speaker": "Michael Scott"},
  {"season": 3, "episode": 23, "scene": 57, "line": "Oh. Yes. Very good.", "order": 14814, "speaker": "David Wallace"},
  {"season": 3, "episode": 23, "scene": 57, "line": "Thank you.", "order": 14815, "speaker": "Michael Scott"},
  {"season": 3, "episode": 23, "scene": 57, "line": "Very good.", "order": 14816, "speaker": "David Wallace"},
  {"season": 3, "episode": 23, "scene": 58, "line": "Dwight is our new boss.", "order": 14817, "speaker": "Phyllis Vance"},
  {"season": 3, "episode": 23, "scene": 58, "line": "Oh, Michael's not going anywhere.", "order": 14818, "speaker": "Oscar Martinez"},
  {"season": 3, "episode": 23, "scene": 58, "line": "Then who do you think will get the job?", "order": 14819, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 23, "scene": 58, "line": "Karen. She looks corporate. Those little pants suits.", "order": 14820, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 23, "scene": 58, "line": "I think it's gonna be Michael.", "order": 14821, "speaker": "Phyllis Vance"},
  {"season": 3, "episode": 23, "scene": 58, "line": "Do you really think he's qualified for that job?", "order": 14822, "speaker": "Oscar Martinez"},
  {"season": 3, "episode": 23, "scene": 58, "line": "No, but he wasn't qualified for the job he has now, and he got that one.", "order": 14823, "speaker": "Phyllis Vance"},
  {"season": 3, "episode": 23, "scene": 58, "line": "[laughs] Mm. Yeah.", "order": 14824, "speaker": "Oscar Martinez"},
  {"season": 3, "episode": 23, "scene": 59, "line": "Listen up! Come to the center of the room, please. This... [holds up paper that resemles a sheet sized dollar bill with Dwight's face in the middle] is a Schrute Buck. When you have done something good, you will receive one Schrute Buck. One thousand Schrute Bucks... equals an extra five minutes for lunch.", "order": 14825, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 23, "scene": 59, "line": "[raises hand] What is the cash value of a Schrute Buck?", "order": 14826, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 23, "scene": 59, "line": "Excellent question, Pam. 1/100th of a cent.", "order": 14827, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 23, "scene": 59, "line": "So ten", "order": 14828, "speaker": "Oscar Martinez"},
  {"season": 3, "episode": 23, "scene": 59, "line": "Just... zip your lid! Another announcement. Michael wasted an enormous amount of the group's time and patience with non-work related ethnic celebrations and parades of soft-minded dogoodedness. No longer. No more meetings!", "order": 14829, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 23, "scene": 59, "line": "Amen.", "order": 14830, "speaker": "Stanley Hudson"},
  {"season": 3, "episode": 23, "scene": 59, "line": "Instead, today I will begin my first lecture in a long series of lectures designed to increase your knowledge on the world of paper.", "order": 14831, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 23, "scene": 59, "line": "[raises hand] Do we have to?", "order": 14832, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 23, "scene": 59, "line": "Yes! Michael is gone. There's a new sheriff here in these offices, and his name is 'me.' Conference room! Ten seconds! All of you!", "order": 14833, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 23, "scene": 60, "line": "I literally cannot wait to see what Dwight has planned. And I wish Jim were here.", "order": 14834, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 23, "scene": 61, "line": "What do you think we could be doing better?", "order": 14835, "speaker": "David Wallace"},
  {"season": 3, "episode": 23, "scene": 61, "line": "I've never been a big fan of the name Dunder Mifflin. I was thinking we could name the company something like, 'Paper Great. Where great paper is our passion. We're GRRRRRRRRRREAT!' I don't know, could be good. Or, uh, 'Super Duper Paper. It's super duper.' I don't know, something like that.", "order": 14836, "speaker": "Michael Scott"},
  {"season": 3, "episode": 23, "scene": 61, "line": "Okay!", "order": 14837, "speaker": "David Wallace"},
  {"season": 3, "episode": 23, "scene": 61, "line": "Okay.", "order": 14838, "speaker": "Michael Scott"},
  {"season": 3, "episode": 23, "scene": 61, "line": "Thanks for comin' in, Michael.", "order": 14839, "speaker": "David Wallace"},
  {"season": 3, "episode": 23, "scene": 61, "line": "Thank you.", "order": 14840, "speaker": "Michael Scott"},
  {"season": 3, "episode": 23, "scene": 61, "line": "It is always a treat when our paths cross.", "order": 14841, "speaker": "David Wallace"},
  {"season": 3, "episode": 23, "scene": 61, "line": "It is always a treat when", "order": 14842, "speaker": "Michael Scott"},
  {"season": 3, "episode": 23, "scene": 61, "line": "You're back together.", "order": 14843, "speaker": "David Wallace"},
  {"season": 3, "episode": 23, "scene": 61, "line": "Yes. And I am very excited about the prospect of working under her... or on top of her. [laughs] Mm, that's not sexual, just... we're all professionals.", "order": 14844, "speaker": "Michael Scott"},
  {"season": 3, "episode": 23, "scene": 61, "line": "Okay, uh. Well, I thought it was clear in the description, the position... the job you're applying for... is Jan's job.", "order": 14845, "speaker": "David Wallace"},
  {"season": 3, "episode": 23, "scene": 61, "line": "I don't understand. So, we're gonna tag team it?", "order": 14846, "speaker": "Michael Scott"},
  {"season": 3, "episode": 23, "scene": 61, "line": "No, we're letting Jan go.", "order": 14847, "speaker": "David Wallace"},
  {"season": 3, "episode": 23, "scene": 62, "line": "Listen up. Let's start... from the ground", "order": 14848, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 23, "scene": 62, "line": "[at the same time as Dwight] Trees.", "order": 14849, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 23, "scene": 62, "line": "Trees! And where do trees grow?", "order": 14850, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 23, "scene": 62, "line": "[at the same time as Dwight] Forest.", "order": 14851, "speaker": "Kevin Malone"},
  {"season": 3, "episode": 23, "scene": 62, "line": "Soil. Right. We have, in front of you here, seven different types of Pennsylvania top soil. Now, what would you say... is the most important element in the production of above ground leafy growth? Probably phosphorus, right? [Angela smiles, shakes her head 'no'] Wrong! It's nitrogen! Absorb this information. Good! Now, let us discuss precipitation. Stanley! When rainfall occurs, does it usually fall in a liquid, solid, or gaseous... state?", "order": 14852, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 23, "scene": 62, "line": "Liquid.", "order": 14853, "speaker": "Stanley Hudson"},
  {"season": 3, "episode": 23, "scene": 62, "line": "Very good! You have earned one Schrute Buck.", "order": 14854, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 23, "scene": 62, "line": "I don't want it.", "order": 14855, "speaker": "Stanley Hudson"},
  {"season": 3, "episode": 23, "scene": 62, "line": "Then you have been deducted 50 Schrute Bucks!", "order": 14856, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 23, "scene": 62, "line": "Make it 100.", "order": 14857, "speaker": "Stanley Hudson"},
  {"season": 3, "episode": 23, "scene": 62, "line": "We--- Don't you wanna earn Schrute Bucks?", "order": 14858, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 23, "scene": 62, "line": "No. In fact, I'll give you a billion Stanley Nickels if you never talk to me again.", "order": 14859, "speaker": "Stanley Hudson"},
  {"season": 3, "episode": 23, "scene": 62, "line": "What's the ratio of Stanley Nickels to Schrute Bucks?", "order": 14860, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 23, "scene": 62, "line": "The same as the ratio of unicorns to leprechauns.", "order": 14861, "speaker": "Stanley Hudson"},
  {"season": 3, "episode": 23, "scene": 62, "line": "Okay---", "order": 14862, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 23, "scene": 62, "line": "That's it!", "order": 14863, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 23, "scene": 62, "line": "What---", "order": 14864, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 23, "scene": 62, "line": "Class is canceled, everybody out!", "order": 14865, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 23, "scene": 62, "line": "No wait, what are you doing?!", "order": 14866, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 23, "scene": 62, "line": "I'm punishing them.", "order": 14867, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 23, "scene": 62, "line": "No, no, no, wait! Class is not canceled.", "order": 14868, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 23, "scene": 62, "line": "[grumbling, getting loud]", "order": 14869, "speaker": "Everyone"},
  {"season": 3, "episode": 23, "scene": 62, "line": "HEY! COME ON! Let's listen to Dwight's presentation! [Dwight winks at Pam]", "order": 14870, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 23, "scene": 62, "line": "What--- What are you winking for?", "order": 14871, "speaker": "Oscar Martinez"},
  {"season": 3, "episode": 23, "scene": 62, "line": "Zip your lid!", "order": 14872, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 23, "scene": 63, "line": "So, that's... my basic 5 year plan. And after that, who knows?", "order": 14873, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 23, "scene": 63, "line": "Okay. Uh, now, this may seem like an odd question, but...", "order": 14874, "speaker": "David Wallace"},
  {"season": 3, "episode": 23, "scene": 63, "line": "Yeah?", "order": 14875, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 23, "scene": 63, "line": "...what do you think about Michael Scott?", "order": 14876, "speaker": "David Wallace"},
  {"season": 3, "episode": 23, "scene": 63, "line": "He's a very nice man. And he's very well suited for the job he has now.", "order": 14877, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 23, "scene": 63, "line": "This is off the record.", "order": 14878, "speaker": "David Wallace"},
  {"season": 3, "episode": 23, "scene": 63, "line": "He would be disaster.", "order": 14879, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 23, "scene": 64, "line": "Hey you!", "order": 14880, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 23, "scene": 64, "line": "Hey.", "order": 14881, "speaker": "Michael Scott"},
  {"season": 3, "episode": 23, "scene": 64, "line": "How was your interview?", "order": 14882, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 23, "scene": 64, "line": "Pretty good.", "order": 14883, "speaker": "Michael Scott"},
  {"season": 3, "episode": 23, "scene": 64, "line": "Yeah?", "order": 14884, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 23, "scene": 64, "line": "Could have gone better I guess.", "order": 14885, "speaker": "Michael Scott"},
  {"season": 3, "episode": 23, "scene": 64, "line": "Oh. [they kiss] I'll put in a good word for you.", "order": 14886, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 23, "scene": 64, "line": "Cool. Maybe you should do it sooner rather than later.", "order": 14887, "speaker": "Michael Scott"},
  {"season": 3, "episode": 23, "scene": 64, "line": "What?", "order": 14888, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 23, "scene": 64, "line": "Daaah, let's just run away together. Let's just run away to Jamaica and live in a bungalow. You have some savings right? You could pay off my debts. It would... be fine. We'd have fun.", "order": 14889, "speaker": "Michael Scott"},
  {"season": 3, "episode": 23, "scene": 64, "line": "What's... what's the matter? What... what happened in there?", "order": 14890, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 23, "scene": 64, "line": "I can't tell you.", "order": 14891, "speaker": "Michael Scott"},
  {"season": 3, "episode": 23, "scene": 64, "line": "Tell me what?", "order": 14892, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 23, "scene": 65, "line": "You son of a bitch!", "order": 14893, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 23, "scene": 65, "line": "Jan, this isn't the time, we're in an interview---", "order": 14894, "speaker": "David Wallace"},
  {"season": 3, "episode": 23, "scene": 65, "line": "You're", "order": 14895, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 23, "scene": 65, "line": "Frankly, it's overdue. Your behavior in the last two years has been completely erratic.", "order": 14896, "speaker": "David Wallace"},
  {"season": 3, "episode": 23, "scene": 65, "line": "Erratic?", "order": 14897, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 23, "scene": 65, "line": "Recently, you don't even show an interest in your work! You smoke constantly in your office. You spend most of the day online shopping. You disappear for hours at a time, sometimes days. Always saying you're visiting your sister in Scottsdale. You go to Scranton far more often than you used to---", "order": 14898, "speaker": "David Wallace"},
  {"season": 3, "episode": 23, "scene": 65, "line": "[opens up her overcoat] Is it because of these?", "order": 14899, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 23, "scene": 65, "line": "Wuuuoooh, hey, whoa, Jan---", "order": 14900, "speaker": "Michael Scott"},
  {"season": 3, "episode": 23, "scene": 65, "line": "No, I wanna know. I wanna know! Because if it is, then, then, then I will see you in court!", "order": 14901, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 23, "scene": 65, "line": "It's not.", "order": 14902, "speaker": "David Wallace"},
  {"season": 3, "episode": 23, "scene": 65, "line": "No?", "order": 14903, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 23, "scene": 65, "line": "It's not.", "order": 14904, "speaker": "David Wallace"},
  {"season": 3, "episode": 23, "scene": 65, "line": "Cause he likes them. [points to Michael] Okay? He likes them. And, and that is, that is all I care about.", "order": 14905, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 23, "scene": 65, "line": "The time has come for you to end your professional relationship with this company. You are clearly unstable.", "order": 14906, "speaker": "David Wallace"},
  {"season": 3, "episode": 23, "scene": 65, "line": "Hey!", "order": 14907, "speaker": "Michael Scott"},
  {"season": 3, "episode": 23, "scene": 65, "line": "Yeah!", "order": 14908, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 23, "scene": 65, "line": "No--- We're all unstable.", "order": 14909, "speaker": "Michael Scott"},
  {"season": 3, "episode": 23, "scene": 65, "line": "Okay, you know what? I'm just not leaving. I'm not leaving. Not leaving.", "order": 14910, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 23, "scene": 65, "line": "David, I did not tell her.", "order": 14911, "speaker": "Michael Scott"},
  {"season": 3, "episode": 23, "scene": 66, "line": "It's like staring into my soul, when I look at this wall.", "order": 14912, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 23, "scene": 66, "line": "It's like outerspace without the stars, it's so black. [laughs]", "order": 14913, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 23, "scene": 66, "line": "This is gonna look so awesome!", "order": 14914, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 23, "scene": 66, "line": "It's so intimidating! Anyone who comes in here... is gonna have to take me seriously. Abandon all hope, ye who enter here!", "order": 14915, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 23, "scene": 66, "line": "[laughs] Totally!", "order": 14916, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 23, "scene": 67, "line": "[giving a hug] Bye Hunter.", "order": 14917, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 23, "scene": 67, "line": "Bye.", "order": 14918, "speaker": "Hunter"},
  {"season": 3, "episode": 23, "scene": 67, "line": "Good luck with your band.", "order": 14919, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 23, "scene": 67, "line": "Oh, thank---", "order": 14920, "speaker": "Hunter"},
  {"season": 3, "episode": 23, "scene": 67, "line": "Don't let them change you, okay? [Jan drops several items, Michael and Hunter help pick them up, she starts to walk out the door] So long, [censored].", "order": 14921, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 23, "scene": 67, "line": "So, I am gonna... give her a ride home. Let me know about the job.", "order": 14922, "speaker": "Michael Scott"},
  {"season": 3, "episode": 23, "scene": 67, "line": "Actually, Michael, I think we're gonna take it in another direction.", "order": 14923, "speaker": "David Wallace"},
  {"season": 3, "episode": 23, "scene": 67, "line": "Good, I'm glad we're on the same page. I have a lot of ideas for new directions.", "order": 14924, "speaker": "Michael Scott"},
  {"season": 3, "episode": 23, "scene": 67, "line": "No, we're not giving you the job.", "order": 14925, "speaker": "David Wallace"},
  {"season": 3, "episode": 23, "scene": 67, "line": "You know what? That... is actually good... because, um, I don't think I could take... my girlfriend's job. That's not being a good boyfriend. So, I respectfully withdraw my name from consideration. Do you accept my withdrawal?", "order": 14926, "speaker": "Michael Scott"},
  {"season": 3, "episode": 23, "scene": 67, "line": "I do.", "order": 14927, "speaker": "David Wallace"},
  {"season": 3, "episode": 23, "scene": 67, "line": "Good. Very good. I'm glad we are finally on the same page. Still have my job in Scranton, though.", "order": 14928, "speaker": "Michael Scott"},
  {"season": 3, "episode": 23, "scene": 67, "line": "Yes.", "order": 14929, "speaker": "David Wallace"},
  {"season": 3, "episode": 23, "scene": 67, "line": "Good. That's all I ever wanted. These two. [points at Jim and Karen] Either one of them... excellent candidates.", "order": 14930, "speaker": "Michael Scott"},
  {"season": 3, "episode": 23, "scene": 68, "line": "Wow. That was some serious... hardcore... self destruction.", "order": 14931, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 23, "scene": 68, "line": "Yeah. Kinda feel bad for her though.", "order": 14932, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 23, "scene": 68, "line": "Don't! She's nuts! [they laugh]", "order": 14933, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 23, "scene": 68, "line": "Oh, man. You know what? This might take a while. You really don't have to stay, if you don't want.", "order": 14934, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 23, "scene": 68, "line": "Okay, good! Because, um, a bunch of my friends are meeting downtown for lunch. And, I was gonna meet them, so...", "order": 14935, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 23, "scene": 68, "line": "Okay.", "order": 14936, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 23, "scene": 68, "line": "Just call me when you're done.", "order": 14937, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 23, "scene": 68, "line": "Sure.", "order": 14938, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 23, "scene": 68, "line": "Good luck, Halpert.", "order": 14939, "speaker": "Karen Filippelli"},
  {"season": 3, "episode": 23, "scene": 68, "line": "Thank you.", "order": 14940, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 23, "scene": 68, "line": "[phone rings, picks up] Dunder Mifflin, this is Grace. Sure.", "order": 14941, "speaker": "Receptionist"},
  {"season": 3, "episode": 23, "scene": 69, "line": "Oh, God! I mean, I just don't understand! It's just", "order": 14942, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 23, "scene": 69, "line": "I know. I know, I'm sorry about that. That was terrible.", "order": 14943, "speaker": "Michael Scott"},
  {"season": 3, "episode": 23, "scene": 69, "line": "Oh, just... No, actually I think it's good, you know? It's fine, actually, I do. I really think it's great that it happened. Because, you know, my work has always been the thing that has gotten in the way of my happiness, so... [laughs]", "order": 14944, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 23, "scene": 69, "line": "Well, it's... [Jan starts crying, high pitched whining] No, don't cry, it's gonna be OK.", "order": 14945, "speaker": "Michael Scott"},
  {"season": 3, "episode": 23, "scene": 69, "line": "[sobbing] Oh, I know, I know. It's just... I'm sorry. It's just these painkillers that I started taking since the surgery. Ohhh! They make my moods totally unpredictable! Wow! What am I gonna do?", "order": 14946, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 23, "scene": 69, "line": "[whispers] I don't know. [normal volume] Well I guess... you could come and stay at my condo. I think I could back out of the sale. [Jan exhales] Probably get some negative feedback on my eBay profile.", "order": 14947, "speaker": "Michael Scott"},
  {"season": 3, "episode": 23, "scene": 69, "line": "Live together. Actually, wait a minute! This could be great! This could be perfect! You know, my full-time job could be our relationship. I could wear stretch pants and wait for you to come home at 5:15. [laughs] It could work. This could work, really!", "order": 14948, "speaker": "Jan Levinson"},
  {"season": 3, "episode": 23, "scene": 70, "line": "[in the office, imitating the terminator] I'll be baaaaack! And I am baaaack!", "order": 14949, "speaker": "Michael Scott"},
  {"season": 3, "episode": 23, "scene": 70, "line": "How'd it go?", "order": 14950, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 23, "scene": 70, "line": "No. No, Pam. I'm baaaaaack! For gooood! Kevin Nealon.", "order": 14951, "speaker": "Michael Scott"},
  {"season": 3, "episode": 23, "scene": 71, "line": "Everybody, may I have your attention please? It is with great honor and privilege that I announce to you that I have officially withdrawn my name for consideration from the corporate job. I know, I know, I know. 'Michael, what are you thinking? You were a shoe in.' Well, got down there. I nailed the interview. And the strangest thing happened. Why is my office black?", "order": 14952, "speaker": "Michael Scott"},
  {"season": 3, "episode": 23, "scene": 71, "line": "To intimidate my subordinates.", "order": 14953, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 23, "scene": 71, "line": "That's stupid.", "order": 14954, "speaker": "Michael Scott"},
  {"season": 3, "episode": 23, "scene": 71, "line": "It was Andy's idea.", "order": 14955, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 23, "scene": 71, "line": "You shouldn't have taken it. Bad management! Good thing I'm baaaaaack. [laughs] Ryan, coffee.", "order": 14956, "speaker": "Michael Scott"},
  {"season": 3, "episode": 23, "scene": 71, "line": "I don't do that stuff anymore.", "order": 14957, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 23, "scene": 71, "line": "No, it's for me, bimbo. Kids.", "order": 14958, "speaker": "Michael Scott"},
  {"season": 3, "episode": 23, "scene": 72, "line": "So I'm back. And I am never, ever going to leave. I am going nowhere. This place... is like... the hospital where I was born, my house, my old age home, and my... graveyard... for my bones.", "order": 14959, "speaker": "Michael Scott"},
  {"season": 3, "episode": 23, "scene": 72, "line": "Did... Karen get the job?", "order": 14960, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 23, "scene": 72, "line": "[to everyone] Back to work. [to Ryan] Still waitin' on that coffee.", "order": 14961, "speaker": "Michael Scott"},
  {"season": 3, "episode": 23, "scene": 73, "line": "Pam, hello.", "order": 14962, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 23, "scene": 73, "line": "Dwight, hello.", "order": 14963, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 23, "scene": 73, "line": "I wanted to thank you... for helping me, when you held the title, Secret Assistant to the Regional Manager. You served the office with great dignity. [Pam salutes Dwight, Dwight returns the salute]", "order": 14964, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 23, "scene": 74, "line": "No, I don't know what the future holds, but... I'm optimistic. And, uh, I had fun goofing around with Dwight today. Jim and I... are just... too similar. Maybe one day I'll find my own Karen. But--- you--- that is, a, um, you know, not--- A man. A man version. But, uh, until then... I can hold my head up. ... I'm not gay.", "order": 14965, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 23, "scene": 75, "line": "I don't know how I feel about hiring a Sixers fan.", "order": 14966, "speaker": "David Wallace"},
  {"season": 3, "episode": 23, "scene": 75, "line": "I should leave. [pretends to get up] [they laugh]", "order": 14967, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 23, "scene": 75, "line": "Uh, let me ask you a question, Jim. You're clearly a very bright guy.", "order": 14968, "speaker": "David Wallace"},
  {"season": 3, "episode": 23, "scene": 75, "line": "Thanks.", "order": 14969, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 23, "scene": 75, "line": "Always hit your numbers, personable, you make a great impression on everyone you meet---", "order": 14970, "speaker": "David Wallace"},
  {"season": 3, "episode": 23, "scene": 75, "line": "I'm sorry, wait, so is the question 'How'd I get to be so awesome?' [laughs] Because, I don't have an answer for you. [laughs]", "order": 14971, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 23, "scene": 75, "line": "Uh, oh, hey, do you have your quarterly numbers?", "order": 14972, "speaker": "David Wallace"},
  {"season": 3, "episode": 23, "scene": 75, "line": "Yes, absolutely.", "order": 14973, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 23, "scene": 75, "line": "And that, uh, questionnaire. Sorry to make you fill that thing out...", "order": 14974, "speaker": "David Wallace"},
  {"season": 3, "episode": 23, "scene": 75, "line": "Oh, no, absolutely.", "order": 14975, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 23, "scene": 75, "line": "...that's a HR formality. We have this very irritating HR guy here, he's probably the only person you're not gonna like. [Jim notices a small note inside his papers, it reads 'Jim, Don't forget us when you're famous! Pam' It has a gold medal yogurt lid attached.] Kendall. Ugh. So, first up...", "order": 14976, "speaker": "David Wallace"},
  {"season": 3, "episode": 23, "scene": 75, "line": "[hands David his papers] There you go.", "order": 14977, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 23, "scene": 75, "line": "How do you think you function here in New York?", "order": 14978, "speaker": "David Wallace"},
  {"season": 3, "episode": 23, "scene": 75, "line": "[not thinking clearly because of Pam's note] What's that? Oh, uh, great. You know? I just um, I really appreciate the buildings, and uh, the people, and um, there's just a [sic] energy... New York has, uh... Not to mention, they have places that are open past eight. [David laughs] So that's a... bonus.", "order": 14979, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 23, "scene": 75, "line": "You've been in the Scranton branch a long time. [Jim stares at Pam's note] What have you liked most about that place?", "order": 14980, "speaker": "David Wallace"},
  {"season": 3, "episode": 23, "scene": 75, "line": "[thinks] The friendships.", "order": 14981, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 23, "scene": 75, "line": "Okay. Well, we want the person who takes this position to be here for the long haul. So... long haul. Where do you see yourself in ten years?", "order": 14982, "speaker": "David Wallace"},
  {"season": 3, "episode": 23, "scene": 76, "line": "[flashback to Beach Games] How are your feet?", "order": 14983, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 23, "scene": 76, "line": "Medium rare. Thanks. [they laugh]", "order": 14984, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 23, "scene": 76, "line": "The real reason that I went to Stamford... was because... I wanted to be... not here.", "order": 14985, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 23, "scene": 76, "line": "I know.", "order": 14986, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 23, "scene": 76, "line": "And even though... I came back, I just, I feel like I've never really... come back.", "order": 14987, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 23, "scene": 76, "line": "Well, I wish you would.", "order": 14988, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 23, "scene": 77, "line": "[phone rings, Pam answers] Dunder Mifflin, this is Pam. Just one moment, I'll transfer you.", "order": 14989, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 23, "scene": 78, "line": "I haven't heard anything, but I bet Jim got the job. I mean, why wouldn't he? He's totally qualified, and smart, everyone loves him... and, if he never comes back again... that's OK. We're friends. And I'm sure we'll stay friends. We just, we never got the timing right. You know? I shot him down, and then he did the same to me, and... But you know what? It's OK. I'm totally fine. Everything is gonna be totally--- [Jim walks in]", "order": 14990, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 23, "scene": 78, "line": "Pam. [to camera] Sorry. [to Pam] Um, are you free for dinner tonight?", "order": 14991, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 23, "scene": 78, "line": "Yes.", "order": 14992, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 23, "scene": 78, "line": "All right. Then... it's a date.", "order": 14993, "speaker": "Jim Halpert"},
  {"season": 3, "episode": 23, "scene": 78, "line": "[to camera, tearing up, smiling] I'm sorry, what was the question?", "order": 14994, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 23, "scene": 79, "line": "[on phone] So, I know we left the other day on a note of uncertainty. But, after some more thought, I'm very pleased to be able to offer you this job. [laughs] Great! I'm so glad. We're all very excited you're going to be joining us. It'll be nice to have another MBA around here.", "order": 14995, "speaker": "David Wallace"},
  {"season": 3, "episode": 23, "scene": 79, "line": "[on phone, smiling] I'm excited too. Okay. Bye.", "order": 14996, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 23, "scene": 79, "line": "Who was that?", "order": 14997, "speaker": "Kelly Kapoor"},
  {"season": 3, "episode": 23, "scene": 79, "line": "[still smiling] Nobody. You and I are done.", "order": 14998, "speaker": "Ryan Howard"},
  {"season": 3, "episode": 23, "scene": 79, "line": "What?! [Ryan smiles and glances at the camera]", "order": 14999, "speaker": "Kelly Kapoor"},
  {"season": 3, "episode": 23, "scene": 80, "line": "Where do you see yourself in ten years?", "order": 15000, "speaker": "David Wallace"},
  {"season": 3, "episode": 23, "scene": 80, "line": "Well, [clears throat] I've actually done a lot of thinking about that. And in ten years, I am living in a big house, with my kids. And in this house we have screens on the walls that have famous art on them and I have a remote that works everything, that has like a belt-loop attachment, or in my ear, like a gluetooth. And, because it's the future, I can take just a little tiny pill and get all my vitamins for the whole day.", "order": 15001, "speaker": "Michael Scott"},
  {"season": 3, "episode": 23, "scene": 80, "line": "A... multivitamin?", "order": 15002, "speaker": "David Wallace"},
  {"season": 3, "episode": 23, "scene": 80, "line": "Well, I don't know what it's gonna be called, but... my point is, the future of this company is now. And the future... is me.", "order": 15003, "speaker": "Michael Scott"},
  {"season": 3, "episode": 23, "scene": 81, "line": "Michael, what happened?", "order": 15004, "speaker": "Phyllis Vance"},
  {"season": 3, "episode": 23, "scene": 81, "line": "Jan got fired. And I realized that I could not work for a company that would fire my girlfriend. But more than that, I realized that... I couldn't take a job that would take me away from this place. This is where I belong. This is... my home. And home is where the hardest.", "order": 15005, "speaker": "Michael Scott"},
  {"season": 3, "episode": 23, "scene": 81, "line": "Home is where the", "order": 15006, "speaker": "Oscar Martinez"},
  {"season": 3, "episode": 23, "scene": 81, "line": "Heart is. That makes a lot more sense. You think they'd help each other out like that at corporate? [Oscar shrugs] I think not!", "order": 15007, "speaker": "Michael Scott"},
  {"season": 3, "episode": 23, "scene": 82, "line": "Well, if Michael gets the job, I will be so upset, because Dwight used to have a crush on me, so if he's boss, it'll be really awkward.", "order": 15008, "speaker": "Kelly Kapoor"},
  {"season": 3, "episode": 23, "scene": 82, "line": "Mmm-hmm. [nods]", "order": 15009, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 23, "scene": 82, "line": "But if Jim gets it, you should be happy, because you have a crush on him, and he totally doesn't feel the same way, and then he'll be gone.", "order": 15010, "speaker": "Kelly Kapoor"},
  {"season": 3, "episode": 23, "scene": 82, "line": "Yeah, well, you know, whatever happens. [Kelly puts her hand on Pam's shoulder] Oh.", "order": 15011, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 23, "scene": 82, "line": "Pam, he's just not that into you.", "order": 15012, "speaker": "Kelly Kapoor"},
  {"season": 3, "episode": 23, "scene": 82, "line": "Oh, I know. We talked about it and he told me.", "order": 15013, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 23, "scene": 82, "line": "No, seriously Pam, wake up.", "order": 15014, "speaker": "Kelly Kapoor"},
  {"season": 3, "episode": 23, "scene": 82, "line": "Okay.", "order": 15015, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 23, "scene": 83, "line": "Kelly made me realize something. She and I have... nothing in common. And I need more friends.", "order": 15016, "speaker": "Pam Beesly"},
  {"season": 3, "episode": 23, "scene": 84, "line": "Define scorched earth policy.", "order": 15017, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 23, "scene": 84, "line": "When an attacker goes through another country sometimes they wi...", "order": 15018, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 23, "scene": 84, "line": "Nyah ah! Too long! What is the Dharma Initiative?", "order": 15019, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 23, "scene": 84, "line": "It is the source of all energy on this planet.", "order": 15020, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 23, "scene": 84, "line": "Wrong. It is a corporation formed by aliens.", "order": 15021, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 23, "scene": 85, "line": "I'm a very good interviewee. Why? Because I have something that nobody else has. And that is... my brain. Which makes me charming, witty, intelligent... [long pause] and quick on my feet.", "order": 15022, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 23, "scene": 86, "line": "Who was the second man on the moon?", "order": 15023, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 23, "scene": 86, "line": "Trick question. There was no second man on the moon.", "order": 15024, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 23, "scene": 86, "line": "Inventor of the cotton gin.", "order": 15025, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 23, "scene": 86, "line": "Eli Edison.", "order": 15026, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 23, "scene": 86, "line": "Talk me through an appendectomy.", "order": 15027, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 23, "scene": 86, "line": "Step 1, disinfection. Step 2, incision. Step 3, remove the appendix tenderly so it doesn't burst and spread toxic...icity everywhere.", "order": 15028, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 23, "scene": 86, "line": "Step 5, [talking over Andy, indistinct] ...the wound.", "order": 15029, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 23, "scene": 86, "line": "Re-suture. Sutures, yes.", "order": 15030, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 23, "scene": 86, "line": "When is the Paleolithic Age?", "order": 15031, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 23, "scene": 86, "line": "17 B.C.", "order": 15032, "speaker": "Andy Bernard"},
  {"season": 3, "episode": 23, "scene": 86, "line": "What is a scented candle?", "order": 15033, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 23, "scene": 87, "line": "How is the new boss? Tough. Do people respect him? They have to. Do they like him? Irrelevant. They do not. And I hate them back.", "order": 15034, "speaker": "Dwight Schrute"},
  {"season": 3, "episode": 23, "scene": 88, "line": "New York real estate. I started by looking for a brownstone, like the Huxtables had, [shakes head] but instead I found this very, very, nice place in Jamaica, Queens, mon. It is on the last stop of the subway, which is wonderful, because if I fall asleep, I still end up at home. It is a suh-weeeet one bedroom. I have a roommate, uh, Vijay Chokalingham, he's a sophomore at Queens College. He actually works in the Indian restaurant right below the apartment, so... free food, and the place always smells like curry. So, win/win.", "order": 15035, "speaker": "Michael Scott"}
]