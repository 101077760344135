import './styles/index.css'
import * as serviceWorker from './utils/serviceWorker'
import QuotePage from './pages/QuotePage'
import React from 'react'
import ReactDOM from 'react-dom'
import SearchPage from './pages/SearchPage'
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'
import { ThemeProvider, useTheme } from './contexts/ThemeContext'

type AppProps = {}

const App: React.FC<AppProps> = () => {
    const { componentMounted } = useTheme()

    if (!componentMounted) {
        return null
    }

    return (
        <Router>
            <Switch>
                <Route exact path="/" component={SearchPage} />
                <Route path="/quote/:quoteId" component={QuotePage} />
                <Redirect to="/" />
            </Switch>
        </Router>
    )
}

ReactDOM.render(
    <React.StrictMode>
        <ThemeProvider>
            <App />
        </ThemeProvider>
    </React.StrictMode>,
    document.getElementById('root')
)

serviceWorker.unregister()
