import React, { useState, useEffect } from 'react'
import { useCallback } from 'react'
import useLocalStorage from '../hooks/useLocalStorage'

const ThemeContext = React.createContext({ theme: 'light', toggleTheme: () => {}, componentMounted: false })

const ThemeProvider = (props) => {
    const [theme, setTheme] = useLocalStorage('theme', 'light')
    const [componentMounted, setComponentMounted] = useState(false)

    const setMode = useCallback(
        (mode: any) => {
            setTheme(mode)
            if (mode === 'dark') {
                document.querySelector('html')?.classList.add('dark')
            } else {
                document.querySelector('html')?.classList.remove('dark')
            }
        },
        [setTheme]
    )

    const toggleTheme = () => {
        if (theme === 'light') {
            setMode('dark')
        } else {
            setMode('light')
        }
    }

    useEffect(() => {
        const initialTheme = localStorage.getItem('theme')
        setMode(initialTheme)
        setComponentMounted(true)
    }, [setMode, setComponentMounted])

    return <ThemeContext.Provider value={{ theme, toggleTheme, componentMounted }} {...props} />
}

const useTheme = () => {
    return React.useContext(ThemeContext)
}

export { ThemeProvider, useTheme }
