module.exports = [
  {"season": 6, "episode": 1, "scene": 1, "line": "[enters office, somersaults onto couch, kicks over painting and lamp] Parkour!", "order": 28848, "speaker": "Michael Scott"},
  {"season": 6, "episode": 1, "scene": 1, "line": "[rush into office, Andy climbs on reception desk, Dwight holds video camera]", "order": 28849, "speaker": "Dwight Schrute & Andy Bernard"},
  {"season": 6, "episode": 1, "scene": 1, "line": "Parkour!", "order": 28850, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 1, "scene": 1, "line": "[walking on Pam's desk, then Jim's desk] Extreme! Parkour!", "order": 28851, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 1, "scene": 1, "line": "This... is Parkour. [turns laptop to camera, shows Parkour instructional video] Internet sensation of 2004. And it was in one of the Bond films. It's pretty impressive. [Michael is shown climbing/walking on various objects in office] The goal is to get from point A to point B as creatively as possible, so technically they are doing Parkour, as long as point A is delusion and point B is the hospital.", "order": 28852, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 1, "scene": 1, "line": "[stands on his hands kicking open bathroom doors as Andy films, Meredith is seen applying deodorant in women's bathroom]", "order": 28853, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 1, "scene": 1, "line": "[approaching elevator, as Michael films] Parkour! Parkour!", "order": 28854, "speaker": "Dwight Schrute & Andy Bernard"},
  {"season": 6, "episode": 1, "scene": 1, "line": "Andy! Leapfrog! [Andy leapfrogs Dwight]", "order": 28855, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 1, "scene": 1, "line": "Do me! Do me!", "order": 28856, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 1, "scene": 1, "line": "Yeah! [Dwight jumps on Andy's back, riding him like a horse] Parkour! Parkour!", "order": 28857, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 1, "scene": 1, "line": "Ow! Get off! Get off!", "order": 28858, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 1, "scene": 1, "line": "Okay.", "order": 28859, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 1, "scene": 1, "line": "[walking across cars in parking lot, Dwight filming] Parkour! Parkour!", "order": 28860, "speaker": "Andy Bernard & Michael Scott"},
  {"season": 6, "episode": 1, "scene": 1, "line": "[crouching beside car] Jump over the camera! Jump over the camera! [Michael feebly attempts to jump off car, gives double thumbs up to camera]", "order": 28861, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 1, "scene": 1, "line": "[standing on tractor trailer with Dwight and Andy, looking down] Okay, we all go together or we go one at a time.", "order": 28862, "speaker": "Michael Scott"},
  {"season": 6, "episode": 1, "scene": 1, "line": "Here it is, here it is... truck, to refrigerators, to dumpster, [camera pans to refrigerator boxes, then dumpster] 360 onto the pallets, backflip gainer to the trash can.", "order": 28863, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 1, "scene": 1, "line": "[in unison] Yeah! Gainer! Woohoo!", "order": 28864, "speaker": "Michael Scott & Dwight Schrute"},
  {"season": 6, "episode": 1, "scene": 1, "line": "[to Michael's camera] Hardcore Parkour!", "order": 28865, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 1, "scene": 1, "line": "Parkoooooour!", "order": 28866, "speaker": "Michael Scott"},
  {"season": 6, "episode": 1, "scene": 1, "line": "Let's do it!", "order": 28867, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 1, "scene": 1, "line": "[leaps from trailer, disappears into empty refrigerator box]", "order": 28868, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 1, "scene": 1, "line": "[look down, appearing shocked and concerned]", "order": 28869, "speaker": "Michael Scott & Dwight Schrute"},
  {"season": 6, "episode": 1, "scene": 1, "line": "[inside refrigerator box, sounding injured] Parkour!", "order": 28870, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 1, "scene": 2, "line": "[on telephone] Good. Excellent. Thank you.", "order": 28871, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 1, "scene": 2, "line": "[hands Dwight a cup of water] There you go. Is there anything else I can do?", "order": 28872, "speaker": "Female Intern"},
  {"season": 6, "episode": 1, "scene": 2, "line": "[chugs water, throws away cup] Yes. Umm... see those files behind Kevin's desk?", "order": 28873, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 1, "scene": 2, "line": "[nods] Mm hmm.", "order": 28874, "speaker": "Female Intern"},
  {"season": 6, "episode": 1, "scene": 2, "line": "Go put them all in random order.", "order": 28875, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 1, "scene": 2, "line": "[confused] Mm-kay.", "order": 28876, "speaker": "Female intern"},
  {"season": 6, "episode": 1, "scene": 2, "line": "Then come back here for your next assignment concerning their order.", "order": 28877, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 1, "scene": 3, "line": "This is the last day of our summer interns. It's been nice. We haven't had interns for a while, ever since Michael's Monica Lewinsky incident. [interns are shown gathered around Kelly's desk, one holding a box of tissues, as Kelly cries and wipes tears] He didn't do anything sexual. [female intern opens a jar of salsa for Michael] He just made far, far too many Monica Lewinsky jokes. [Meredith rubs her breasts into Maurie's back] It was just easier for corporate to shut down the program.", "order": 28878, "speaker": "Pam Beesly"},
  {"season": 6, "episode": 1, "scene": 4, "line": "[to Oscar, motioning to male intern and female intern] Think she did him?", "order": 28879, "speaker": "Meredith Palmer"},
  {"season": 6, "episode": 1, "scene": 4, "line": "I bet there's something there. I was with them in the kitchen yesterday, and they were all too happy to be cleaning the freezer.", "order": 28880, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 1, "scene": 4, "line": "If they get married before I do, I'm going to kill myself.", "order": 28881, "speaker": "Kelly Kapoor"},
  {"season": 6, "episode": 1, "scene": 4, "line": "[entering break room] Who's getting married?", "order": 28882, "speaker": "Michael Scott"},
  {"season": 6, "episode": 1, "scene": 4, "line": "Nobody.", "order": 28883, "speaker": "Pam Beesly"},
  {"season": 6, "episode": 1, "scene": 4, "line": "If somebody doesn't tell me, I'm gonna start screaming.", "order": 28884, "speaker": "Michael Scott"},
  {"season": 6, "episode": 1, "scene": 4, "line": "It's Eric and Megan.", "order": 28885, "speaker": "Kelly Kapoor"},
  {"season": 6, "episode": 1, "scene": 4, "line": "Oh! Hey, I hired them! Matchmaker! This place is like Spaniard Fly.", "order": 28886, "speaker": "Michael Scott"},
  {"season": 6, "episode": 1, "scene": 4, "line": "They're not getting married. It's just talk.", "order": 28887, "speaker": "Phyllis Vance"},
  {"season": 6, "episode": 1, "scene": 4, "line": "Did everybody know about this but me?", "order": 28888, "speaker": "Michael Scott"},
  {"season": 6, "episode": 1, "scene": 4, "line": "There's nothing to know, it's just gossip.", "order": 28889, "speaker": "Phyllis Vance"},
  {"season": 6, "episode": 1, "scene": 4, "line": "[shaking head disgustedly] Okay... okay. [leaves room]", "order": 28890, "speaker": "Michael Scott"},
  {"season": 6, "episode": 1, "scene": 5, "line": "[talking head with Jim] We haven't told anyone I'm pregnant.", "order": 28891, "speaker": "Pam Beesly"},
  {"season": 6, "episode": 1, "scene": 5, "line": "Well, with her being unmarried... knocked up by some guy, I mean the yakity-yaks in this office would have a field day.", "order": 28892, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 1, "scene": 5, "line": "Yeah, we don't want them at the wedding thinking Jim's being marched down the aisle by my dad with a shotgum.", "order": 28893, "speaker": "Pam Beesly"},
  {"season": 6, "episode": 1, "scene": 5, "line": "Wait, there's not gonna be a shotgun?", "order": 28894, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 1, "scene": 5, "line": "Nope.", "order": 28895, "speaker": "Pam Beesly"},
  {"season": 6, "episode": 1, "scene": 5, "line": "No shotgun... 'cause that changes everything.", "order": 28896, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 1, "scene": 5, "line": "Can't back out now, Halpert.", "order": 28897, "speaker": "Pam Beesly"},
  {"season": 6, "episode": 1, "scene": 5, "line": "What are you gonna do about it? There's no shotgun, so... free at last, free at last!", "order": 28898, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 1, "scene": 5, "line": "No, keep it up.", "order": 28899, "speaker": "Pam Beesly"},
  {"season": 6, "episode": 1, "scene": 6, "line": "[approaches Dwight's desk] You'll never guess what I know.", "order": 28900, "speaker": "Michael Scott"},
  {"season": 6, "episode": 1, "scene": 6, "line": "[typing] Okay, let me finish this thought.", "order": 28901, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 1, "scene": 6, "line": "Eric likes Megan.", "order": 28902, "speaker": "Michael Scott"},
  {"season": 6, "episode": 1, "scene": 6, "line": "[still typing] He most definitely does. He's been asking her out repeatedly for weeks. She finally said yes. They went out on a group date the other night; apparently it went very well.", "order": 28903, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 1, "scene": 6, "line": "[annoyed] How do you know this?", "order": 28904, "speaker": "Michael Scott"},
  {"season": 6, "episode": 1, "scene": 6, "line": "[still typing] People tell me things. I guess I have a face you can trust. I think it's because of my low cheekbones. And... boom. [finishes typing, turns to face Michael] Okay, what is it, what can't I guess?", "order": 28905, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 1, "scene": 7, "line": "I hate, hate, hate being left out. Whether it's not being picked for a team, or being picked for a team and showing up and realizing the team doesn't exist. Or that the sport doesn't exist? I should've known. Poopball?", "order": 28906, "speaker": "Michael Scott"},
  {"season": 6, "episode": 1, "scene": 8, "line": "[to Maurie, the Asian intern] Man, I know how you feel. Third wheel. I have been the third wheel so many times in my life. My mom told me something once that made me feel a lot better. She said 'The third wheel is what makes it a tricycle.'", "order": 28907, "speaker": "Michael Scott"},
  {"season": 6, "episode": 1, "scene": 8, "line": "What are you talking about?", "order": 28908, "speaker": "Maurie"},
  {"season": 6, "episode": 1, "scene": 8, "line": "I'm talking about your group date with Megan and Eric.", "order": 28909, "speaker": "Michael Scott"},
  {"season": 6, "episode": 1, "scene": 8, "line": "At Tink's?", "order": 28910, "speaker": "Maurie"},
  {"season": 6, "episode": 1, "scene": 8, "line": "Yes", "order": 28911, "speaker": "Michael Scott"},
  {"season": 6, "episode": 1, "scene": 8, "line": "How'd you know about that?", "order": 28912, "speaker": "Maurie"},
  {"season": 6, "episode": 1, "scene": 8, "line": "Well...", "order": 28913, "speaker": "Michael Scott"},
  {"season": 6, "episode": 1, "scene": 8, "line": "Did Stanley tell you?", "order": 28914, "speaker": "Maurie"},
  {"season": 6, "episode": 1, "scene": 8, "line": "[confused] Stanley was on your group date?", "order": 28915, "speaker": "Michael Scott"},
  {"season": 6, "episode": 1, "scene": 8, "line": "No, Stanley was with his wife. I waved at him, but he didn't wave back.", "order": 28916, "speaker": "Maurie"},
  {"season": 6, "episode": 1, "scene": 8, "line": "[laughs] Does anybody else know about this?", "order": 28917, "speaker": "Michael Scott"},
  {"season": 6, "episode": 1, "scene": 8, "line": "No.", "order": 28918, "speaker": "Maurie"},
  {"season": 6, "episode": 1, "scene": 8, "line": "[laughs, looks to camera excitedly]", "order": 28919, "speaker": "Michael Scott"},
  {"season": 6, "episode": 1, "scene": 9, "line": "There he goes. There he goes. [Stanley gets up from his desk, walks into kitchen area]", "order": 28920, "speaker": "Michael Scott"},
  {"season": 6, "episode": 1, "scene": 9, "line": "So, what is the scuttlebutt? Anybody hear anything?", "order": 28921, "speaker": "Michael Scott"},
  {"season": 6, "episode": 1, "scene": 9, "line": "[stare blankly at Michael]", "order": 28922, "speaker": "Dwight Schrute, Jim Halpert & Pam Beesly"},
  {"season": 6, "episode": 1, "scene": 9, "line": "Nothing? Well I got a little somethin' somethin'. I don't know if I should say...", "order": 28923, "speaker": "Michael Scott"},
  {"season": 6, "episode": 1, "scene": 9, "line": "Just keep it to yourself then.", "order": 28924, "speaker": "Pam Beesly"},
  {"season": 6, "episode": 1, "scene": 9, "line": "Stanly is having a midlife crisis.", "order": 28925, "speaker": "Michael Scott"},
  {"season": 6, "episode": 1, "scene": 9, "line": "Stanley's way past the middle of his life. Especially considering his height to weight ratio.", "order": 28926, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 1, "scene": 9, "line": "Well apparently, he and his wife were out dancing at a club for young people.", "order": 28927, "speaker": "Michael Scott"},
  {"season": 6, "episode": 1, "scene": 9, "line": "Teri's out of town, Michael.", "order": 28928, "speaker": "Phyllis Vance"},
  {"season": 6, "episode": 1, "scene": 9, "line": "Who?", "order": 28929, "speaker": "Michael Scott"},
  {"season": 6, "episode": 1, "scene": 9, "line": "Teri, Stanley's wife. She's at an interior decorating expo in Philly. Stanley hates crowds, kids, and music. I think you should check your facts.", "order": 28930, "speaker": "Phyllis Vance"},
  {"season": 6, "episode": 1, "scene": 9, "line": "[starts to speak, says nothing, walks away]", "order": 28931, "speaker": "Michael Scott"},
  {"season": 6, "episode": 1, "scene": 10, "line": "[entering break room, to Maurie] You stupid son of a bitch. You set me up.", "order": 28932, "speaker": "Michael Scott"},
  {"season": 6, "episode": 1, "scene": 10, "line": "What are you talking about?", "order": 28933, "speaker": "Maurie"},
  {"season": 6, "episode": 1, "scene": 10, "line": "Stanley's wife is out of town.", "order": 28934, "speaker": "Michael Scott"},
  {"season": 6, "episode": 1, "scene": 10, "line": "He was with somebody.", "order": 28935, "speaker": "Maurie"},
  {"season": 6, "episode": 1, "scene": 10, "line": "He was definitely with somebody.", "order": 28936, "speaker": "Eric"},
  {"season": 6, "episode": 1, "scene": 10, "line": "Oh, was he? Well who, his sister?", "order": 28937, "speaker": "Michael Scott"},
  {"season": 6, "episode": 1, "scene": 10, "line": "[chuckles]", "order": 28938, "speaker": "Eric"},
  {"season": 6, "episode": 1, "scene": 10, "line": "[upset] I don't get it!", "order": 28939, "speaker": "Michael Scott"},
  {"season": 6, "episode": 1, "scene": 10, "line": "If that was his sister, then what they were doing was totally illegal.", "order": 28940, "speaker": "Eric"},
  {"season": 6, "episode": 1, "scene": 10, "line": "Drugs?", "order": 28941, "speaker": "Michael Scott"},
  {"season": 6, "episode": 1, "scene": 10, "line": "Making out.", "order": 28942, "speaker": "Eric"},
  {"season": 6, "episode": 1, "scene": 10, "line": "[fumbling for words] Mmm... okay. Uhh... hmm... okay. Never mind. Carry on.", "order": 28943, "speaker": "Michael Scott"},
  {"season": 6, "episode": 1, "scene": 11, "line": "[approaching Kelly's desk, whispering] Stanley is having an affir.", "order": 28944, "speaker": "Michael Scott"},
  {"season": 6, "episode": 1, "scene": 11, "line": "WHAT??", "order": 28945, "speaker": "Kelly Kapoor"},
  {"season": 6, "episode": 1, "scene": 12, "line": "I am very happy right now. Thrilled perhaps, to be part of the office, to be part of the conversation. When you have somebody's attenton, and their eyes are lighting up because they are very interested in what you have to say, [Michael is seen whispering to Jim, then Pam] that is a great feeling, and I experienced that firsthand today. It is wonderful to be the center of attention.", "order": 28946, "speaker": "Michael Scott"},
  {"season": 6, "episode": 1, "scene": 13, "line": "[motioning to pad of paper with hangman game, spelling out 'S_an_ey is chea_in_ _n _eri] That and that are the same letter.", "order": 28947, "speaker": "Michael Scott"},
  {"season": 6, "episode": 1, "scene": 13, "line": "Q.", "order": 28948, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 1, "scene": 13, "line": "No... no. He's holding a pencil. He could be doing a crossword puzzle with that pencil. What's that letter, right there? Same letter here and here. What haven't you guessed?", "order": 28949, "speaker": "Michael Scott"},
  {"season": 6, "episode": 1, "scene": 13, "line": "T.", "order": 28950, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 1, "scene": 13, "line": "T! Yes! Yes! That is a 'T', and there's another one right there. Just sound that out. You're almost there.", "order": 28951, "speaker": "Michael Scott"},
  {"season": 6, "episode": 1, "scene": 14, "line": "[opening blinds as Stanley walks past] Hey, Stanley! Where you goin', big guy? Up to no good?", "order": 28952, "speaker": "Michael Scott"},
  {"season": 6, "episode": 1, "scene": 14, "line": "I'm meeting a client. Do you have a problem with that?", "order": 28953, "speaker": "Stanley Hudson"},
  {"season": 6, "episode": 1, "scene": 14, "line": "No, no, no, no. I just had a quick question for you. I'm casting a movie and I'm looking for a woman who can dance, beautiful woman. Do you know anybody?", "order": 28954, "speaker": "Michael Scott"},
  {"season": 6, "episode": 1, "scene": 14, "line": "Goodbye. [walks away]", "order": 28955, "speaker": "Stanley Hudson"},
  {"season": 6, "episode": 1, "scene": 14, "line": "Pays five million dollars. Nudity required.", "order": 28956, "speaker": "Michael Scott"},
  {"season": 6, "episode": 1, "scene": 14, "line": "[approaching Michael] You have to stop this.", "order": 28957, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 1, "scene": 14, "line": "[giddy, whispering] Did you hear Stanley's having an affair?", "order": 28958, "speaker": "Michael Scott"},
  {"season": 6, "episode": 1, "scene": 14, "line": "I did. From you. You gotta stop telling people.", "order": 28959, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 1, "scene": 14, "line": "Oh, okay, I get it. You feel out of the loop, and you feel sorry for yourself, and it's really sad because everybody thinks you're a loser...", "order": 28960, "speaker": "Michael Scott"},
  {"season": 6, "episode": 1, "scene": 14, "line": "Do you know if it's true? Do you have any idea? Because you might be ruining his life.", "order": 28961, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 1, "scene": 14, "line": "[stares at Jim silently, then walks away]", "order": 28962, "speaker": "Michael Scott"},
  {"season": 6, "episode": 1, "scene": 15, "line": "[in parking lot, blocking path of Stanley's car] Stop. Hold up, hold up, hold up! No, no, no. Time to stop being polite and get real. [Stanley honks horn repeatedly] Stanley, are you having an affair?", "order": 28963, "speaker": "Michael Scott"},
  {"season": 6, "episode": 1, "scene": 15, "line": "[as Michael gets in car] That is ridiculous. Of course not, why would you think that?", "order": 28964, "speaker": "Stanley Hudson"},
  {"season": 6, "episode": 1, "scene": 15, "line": "So It's not true. Okay, well... ahhh, those interns, they told me they saw you at a club, and they all said it was you, it was clearly just... racial profiling gossip.", "order": 28965, "speaker": "Michael Scott"},
  {"season": 6, "episode": 1, "scene": 15, "line": "[hits steering wheel angrily] Damn! I told her it was a stupid idea to go up in that club.", "order": 28966, "speaker": "Stanley Hudson"},
  {"season": 6, "episode": 1, "scene": 15, "line": "[nodding] So stupid!", "order": 28967, "speaker": "Michael Scott"},
  {"season": 6, "episode": 1, "scene": 15, "line": "It's just that... Teri's been traveling a lot...", "order": 28968, "speaker": "Stanley Hudson"},
  {"season": 6, "episode": 1, "scene": 15, "line": "I know, I know.", "order": 28969, "speaker": "Michael Scott"},
  {"season": 6, "episode": 1, "scene": 15, "line": "And it's been lonely.", "order": 28970, "speaker": "Stanley Hudson"},
  {"season": 6, "episode": 1, "scene": 15, "line": "Oh, I bet.", "order": 28971, "speaker": "Michael Scott"},
  {"season": 6, "episode": 1, "scene": 15, "line": "Cynthia's been keeping me company. She was my nurse, when I was in rehabilitation. We used to go on these long walks on the treadmill... I didn't...", "order": 28972, "speaker": "Stanley Hudson"},
  {"season": 6, "episode": 1, "scene": 15, "line": "Wait, hold, hold, hold... so it IS true?", "order": 28973, "speaker": "Michael Scott"},
  {"season": 6, "episode": 1, "scene": 15, "line": "Yeah, but Michael, I'm going to go break it off. That's where I'm headed right now. I swear.", "order": 28974, "speaker": "Stanley Hudson"},
  {"season": 6, "episode": 1, "scene": 15, "line": "Oh my God! Wait a second, do people often say they're going on sales calls and then go someplace else? 'Cause that's not cool.", "order": 28975, "speaker": "Michael Scott"},
  {"season": 6, "episode": 1, "scene": 15, "line": "I just need you to promise not to say a word about this to anyone. Michael, please.", "order": 28976, "speaker": "Stanley Hudson"},
  {"season": 6, "episode": 1, "scene": 15, "line": "[muttering] I'm not saying... a damn thing.", "order": 28977, "speaker": "Michael Scott"},
  {"season": 6, "episode": 1, "scene": 15, "line": "I'm counting on you, Michael.", "order": 28978, "speaker": "Stanley Hudson"},
  {"season": 6, "episode": 1, "scene": 15, "line": "[looking afraid] You can count on me.", "order": 28979, "speaker": "Michael Scott"},
  {"season": 6, "episode": 1, "scene": 15, "line": "[sighs]", "order": 28980, "speaker": "Stanley Hudson"},
  {"season": 6, "episode": 1, "scene": 16, "line": "[entering kitchen area] Oh, that smells good.", "order": 28981, "speaker": "Michael Scott"},
  {"season": 6, "episode": 1, "scene": 16, "line": "I might have extra, it just depends on how many I eat.", "order": 28982, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 1, "scene": 16, "line": "Did you hear about Angela? That's pretty weird.", "order": 28983, "speaker": "Michael Scott"},
  {"season": 6, "episode": 1, "scene": 16, "line": "Yeah... what do you mean exactly?", "order": 28984, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 1, "scene": 16, "line": "Well, the fact that she is apparently dating an 81-year-old billionaire. Owns a Quizno's on the turnpike.", "order": 28985, "speaker": "Michael Scott"},
  {"season": 6, "episode": 1, "scene": 16, "line": "Reeeeally.", "order": 28986, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 1, "scene": 16, "line": "Uh huh. [gestures to Kevin to keep it quiet] Alright?", "order": 28987, "speaker": "Michael Scott"},
  {"season": 6, "episode": 1, "scene": 17, "line": "How do you un-tell something? You can't. You can't put words back in your mouth. What you can do is spread false gossip so that people think that everything that's been said is untrue, including 'Stanley is having an affair.' It's like the end of Spartacus. I've seen that movie half a dozen times, and I still don't know who the real Spartacus is, and that is what makes that movie a classic whodunit.", "order": 28988, "speaker": "Michael Scott"},
  {"season": 6, "episode": 1, "scene": 18, "line": "Kelly has an eating disorder?", "order": 28989, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 1, "scene": 18, "line": "Yeah.", "order": 28990, "speaker": "Michael Scott"},
  {"season": 6, "episode": 1, "scene": 18, "line": "She always eats my lunch.", "order": 28991, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 1, "scene": 18, "line": "Anorexia. She's an anorexatic.", "order": 28992, "speaker": "Michael Scott"},
  {"season": 6, "episode": 1, "scene": 18, "line": "We should do something.", "order": 28993, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 1, "scene": 18, "line": "Nothing can be done, we just have to tell everybody and hope for the best I guess.", "order": 28994, "speaker": "Michael Scott"},
  {"season": 6, "episode": 1, "scene": 19, "line": "[to Kelly] I'm worried about Erin, because she's not a very good worker. I don't know how long she's gonna last here.", "order": 28995, "speaker": "Michael Scott"},
  {"season": 6, "episode": 1, "scene": 19, "line": "Really?", "order": 28996, "speaker": "Kelly Kapoor"},
  {"season": 6, "episode": 1, "scene": 19, "line": "Yeah. So... and Andy is gay.", "order": 28997, "speaker": "Michael Scott"},
  {"season": 6, "episode": 1, "scene": 19, "line": "Andy Bernard??", "order": 28998, "speaker": "Kelly Kapoor"},
  {"season": 6, "episode": 1, "scene": 19, "line": "Mm hmm.", "order": 28999, "speaker": "Michael Scott"},
  {"season": 6, "episode": 1, "scene": 19, "line": "[whispering to Meredith] Did you hear Pam is pregnant?", "order": 29000, "speaker": "Michael Scott"},
  {"season": 6, "episode": 1, "scene": 19, "line": "Really?", "order": 29001, "speaker": "Meredith Palmer"},
  {"season": 6, "episode": 1, "scene": 19, "line": "Yeah!", "order": 29002, "speaker": "Michael Scott"},
  {"season": 6, "episode": 1, "scene": 19, "line": "She's gonna hate being a mom.", "order": 29003, "speaker": "Meredith Palmer"},
  {"season": 6, "episode": 1, "scene": 20, "line": "[making a cup of tea in kitchen area]", "order": 29004, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 1, "scene": 20, "line": "[looks at Andy and giggles]", "order": 29005, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 1, "scene": 20, "line": "What?", "order": 29006, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 1, "scene": 20, "line": "[still giggling] Tea.", "order": 29007, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 1, "scene": 20, "line": "So?", "order": 29008, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 1, "scene": 20, "line": "You would.", "order": 29009, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 1, "scene": 20, "line": "[laughing] I like tea!", "order": 29010, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 1, "scene": 20, "line": "[sarcastically] Oh, I bet you like it.", "order": 29011, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 1, "scene": 20, "line": "Hahaha! I like it a lot! I love tea!", "order": 29012, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 1, "scene": 20, "line": "Do you like it as much as you like mens' butts?", "order": 29013, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 1, "scene": 20, "line": "WHAT??", "order": 29014, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 1, "scene": 20, "line": "Because you're gay.", "order": 29015, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 1, "scene": 20, "line": "[serious] Who told you that? Was it Broccoli Rob? Someone from Chi Psi? Or did you run into someone from my high school?", "order": 29016, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 1, "scene": 20, "line": "This is not the first time rumors about me being gay have come up. Twice before, actually. Just a weird coincidence... a little too weird. [chuckles nervously] Almost makes you wonder if it's not a coincidence at all. Whoa! Which it is, of course. [long pause] But it makes you wonder.", "order": 29017, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 1, "scene": 21, "line": "[to Oscar in break room] Did you hear the rumor about me? That I'm gay?", "order": 29018, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 1, "scene": 21, "line": "[nodding] I did. Yes.", "order": 29019, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 1, "scene": 21, "line": "And?", "order": 29020, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 1, "scene": 21, "line": "What?", "order": 29021, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 1, "scene": 21, "line": "Do you think it's true? Do you think that I'm... gay?", "order": 29022, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 1, "scene": 21, "line": "Are you attracted to other men?", "order": 29023, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 1, "scene": 21, "line": "[scoffs and chuckles] No! But, let me kick you a scenario. I'm at a beach cabana, and Brad Pitt approaches. He tries to lean in and kiss me. I would definitely resist, like at first. But if he was persistent, [long pause] I think I might give in a little bit, just to see what it... felt like.", "order": 29024, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 1, "scene": 21, "line": "[stares at Andy incredulously]", "order": 29025, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 1, "scene": 21, "line": "Would I push him away? How hard? Like, what if he's like really aggressive?", "order": 29026, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 1, "scene": 21, "line": "If you resisted Brad Pitt a little bit, he would still... need to get to you?", "order": 29027, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 1, "scene": 21, "line": "It's not real Brad Pitt, this is like, this is my fantasy. Or it's like, not a fantasy, it's just... it's just a scenario.", "order": 29028, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 1, "scene": 21, "line": "Wow. I wish... I wish I could help you. I don't... you might be gay. You might be gay.", "order": 29029, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 1, "scene": 21, "line": "What exactly is my responsibility here? To comfort insecure heterosexual men? That can't possibly fall to me.", "order": 29030, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 1, "scene": 22, "line": "How far along is Pam?", "order": 29031, "speaker": "Phyllis Vance"},
  {"season": 6, "episode": 1, "scene": 22, "line": "A few months, that's why they're rushing the wedding.", "order": 29032, "speaker": "Meredith Palmer"},
  {"season": 6, "episode": 1, "scene": 22, "line": "[whispering] Guys, guys... you can't believe everything you hear. Like Stanley, having an affair? That is crazy! It's just... there's no stalking it. It's a weird day for false facts. Let it go.", "order": 29033, "speaker": "Michael Scott"},
  {"season": 6, "episode": 1, "scene": 22, "line": "[with everyone assembled in conference room] Okay everybody, big day. Last day for our interns, a lot of other stuff... who knows what to believe? Dwight, the honors.", "order": 29034, "speaker": "Michael Scott"},
  {"season": 6, "episode": 1, "scene": 22, "line": "[giving envelopes to interns] Present these to the dean for credit. And, as a gift, I've attached my card. Call me any time of the day or night.", "order": 29035, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 1, "scene": 22, "line": "Why would we call you at night?", "order": 29036, "speaker": "Eric"},
  {"season": 6, "episode": 1, "scene": 22, "line": "[grabs card from Eric, crumples it, throws it to the ground] Well now you can't call me at all. Problem solved.", "order": 29037, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 1, "scene": 22, "line": "[to Eric] You're gonna regret that when you find yourself between a moose and her cubs.", "order": 29038, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 1, "scene": 22, "line": "Look at the intern to your left. Now to your right. [interns do nothing] One of you will do exceedingly well in business, just unlimited potential. One of you will make a living, and nothing more. And one of you [looks directly at Megan] will make a great mother. It's up to you to choose which you want to be.", "order": 29039, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 1, "scene": 23, "line": "[eating cake with Kelly] I am so happy you're eating again.", "order": 29040, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 1, "scene": 23, "line": "[mouth full of cake] Me too!", "order": 29041, "speaker": "Kelly Kapoor"},
  {"season": 6, "episode": 1, "scene": 23, "line": "[to Jim] Tuna, how come you never try to set me up?", "order": 29042, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 1, "scene": 23, "line": "Oh, I don't really do that. But Pam might have some friends, though.", "order": 29043, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 1, "scene": 23, "line": "Women friends?", "order": 29044, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 1, "scene": 23, "line": "Yep.", "order": 29045, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 1, "scene": 23, "line": "Not guy friends?", "order": 29046, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 1, "scene": 23, "line": "Are you... interested in her guy friends?", "order": 29047, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 1, "scene": 23, "line": "No. I mean, for the record, I prefer women.", "order": 29048, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 1, "scene": 23, "line": "Okay.", "order": 29049, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 1, "scene": 23, "line": "But off the record, I'm kinda confused.", "order": 29050, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 1, "scene": 23, "line": "Really.", "order": 29051, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 1, "scene": 23, "line": "Yeah, the evidence is sort of stacked against me, I feel like...", "order": 29052, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 1, "scene": 23, "line": "Well you gotta figure this out.", "order": 29053, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 1, "scene": 23, "line": "Yeah, right?", "order": 29054, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 1, "scene": 23, "line": "Yeah!", "order": 29055, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 1, "scene": 23, "line": "How?", "order": 29056, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 1, "scene": 23, "line": "You gotta have sex with a woman.", "order": 29057, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 1, "scene": 23, "line": "Right-o.", "order": 29058, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 1, "scene": 23, "line": "And a man.", "order": 29059, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 1, "scene": 23, "line": "Ohh...", "order": 29060, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 1, "scene": 23, "line": "And then compare.", "order": 29061, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 1, "scene": 23, "line": "Alright. [looks around uneasily] Well hey...", "order": 29062, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 1, "scene": 23, "line": "Yep.", "order": 29063, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 1, "scene": 23, "line": "Congratulations to you.", "order": 29064, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 1, "scene": 23, "line": "On what?", "order": 29065, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 1, "scene": 23, "line": "[chuckles] Come on... little baby Tuna. Little junior toro.", "order": 29066, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 1, "scene": 23, "line": "[looks around room nervously]", "order": 29067, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 1, "scene": 23, "line": "[to Pam] Did you know a baby conceived out of wedlock is still a bastard?", "order": 29068, "speaker": "Angela Martin"},
  {"season": 6, "episode": 1, "scene": 23, "line": "What?", "order": 29069, "speaker": "Pam Beesly"},
  {"season": 6, "episode": 1, "scene": 23, "line": "Want me to say it again?", "order": 29070, "speaker": "Angela Martin"},
  {"season": 6, "episode": 1, "scene": 23, "line": "Why did that come into your brain?", "order": 29071, "speaker": "Pam Beesly"},
  {"season": 6, "episode": 1, "scene": 23, "line": "[grabs Pam by the arm] Can I talk to you for a second?", "order": 29072, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 1, "scene": 23, "line": "[whispering to Pam in corner of room] So, they know.", "order": 29073, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 1, "scene": 23, "line": "Who did you tell?", "order": 29074, "speaker": "Pam Beesly"},
  {"season": 6, "episode": 1, "scene": 23, "line": "I didn't tell anyone! Who did you tell?", "order": 29075, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 1, "scene": 23, "line": "Nobody!", "order": 29076, "speaker": "Pam Beesly"},
  {"season": 6, "episode": 1, "scene": 23, "line": "[approaching Jim and Pam] Hey, did one of you tell Stanley I have asthma? Because I don't, and if it gets out, they won't let me scuba.", "order": 29077, "speaker": "Creed Bratton"},
  {"season": 6, "episode": 1, "scene": 24, "line": "If I can't scuba, then what's this all been about? What am I working toward?", "order": 29078, "speaker": "Creed Bratton"},
  {"season": 6, "episode": 1, "scene": 25, "line": "[to Andy] What? You think I'm the voice of the Taco Bell dog?", "order": 29079, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 1, "scene": 25, "line": "[to Phyllis and Stanley] What are you guys talking about? I have a daughter. How can I be a virgin?", "order": 29080, "speaker": "Toby Flenderson"},
  {"season": 6, "episode": 1, "scene": 25, "line": "Hey, has everyone heard a crazy rumor about themselves today?", "order": 29081, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 1, "scene": 25, "line": "[in unison] Yes.", "order": 29082, "speaker": "Everyone"},
  {"season": 6, "episode": 1, "scene": 25, "line": "[entering conference room] Yeah, yeah... you know what? Let's discredit these rumors.", "order": 29083, "speaker": "Michael Scott"},
  {"season": 6, "episode": 1, "scene": 25, "line": "Yeah, sure, but... who's been saying all that stuff?", "order": 29084, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 1, "scene": 25, "line": "That's not important, Jim, I just think we shouldn't listen to any of that crap.", "order": 29085, "speaker": "Michael Scott"},
  {"season": 6, "episode": 1, "scene": 25, "line": "Sure, but we should get to the bottom of this. Let's pick a rumor and trace it back to the beginning.", "order": 29086, "speaker": "Pam Beesly"},
  {"season": 6, "episode": 1, "scene": 25, "line": "I need to do mine first. Who's been saying that there's another person inside of me, working me with controls?", "order": 29087, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 1, "scene": 25, "line": "I heard that from... Andy.", "order": 29088, "speaker": "Creed Bratton"},
  {"season": 6, "episode": 1, "scene": 25, "line": "I heard it from Erin.", "order": 29089, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 1, "scene": 25, "line": "I heard it from Michael.", "order": 29090, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 1, "scene": 25, "line": "[quietly leaves room]", "order": 29091, "speaker": "Michael Scott"},
  {"season": 6, "episode": 1, "scene": 26, "line": "[approaching Michael at elevators] Michael? [to others] He's over here!", "order": 29092, "speaker": "Pam Beesly"},
  {"season": 6, "episode": 1, "scene": 26, "line": "[to Michael] Where are you going?", "order": 29093, "speaker": "Pam Beesly"},
  {"season": 6, "episode": 1, "scene": 26, "line": "Nowhere.", "order": 29094, "speaker": "Michael Scott"},
  {"season": 6, "episode": 1, "scene": 26, "line": "Michael... [leads Michael to corner of vestibule, then whispers] You told people I use store-bought manure, when I showed you where my manure comes from. Hmm? Hmm? [Michael looks nervously to camera as elevator doors open]", "order": 29095, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 1, "scene": 26, "line": "Don't get on it. [Michael inches toward elevator]", "order": 29096, "speaker": "Pam Beesly"},
  {"season": 6, "episode": 1, "scene": 26, "line": "Michael, don't.", "order": 29097, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 1, "scene": 26, "line": "Don't... get... on. Do not.", "order": 29098, "speaker": "Pam Beesly"},
  {"season": 6, "episode": 1, "scene": 26, "line": "Michael...", "order": 29099, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 1, "scene": 26, "line": "Stay.", "order": 29100, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 1, "scene": 26, "line": "Step away.", "order": 29101, "speaker": "Pam Beesly"},
  {"season": 6, "episode": 1, "scene": 26, "line": "Come on, boss.", "order": 29102, "speaker": "Creed Bratton"},
  {"season": 6, "episode": 1, "scene": 26, "line": "[reluctantly exits elevator]", "order": 29103, "speaker": "Michael Scott"},
  {"season": 6, "episode": 1, "scene": 27, "line": "[to everyone, in conference room] Okay, I made it all up.", "order": 29104, "speaker": "Michael Scott"},
  {"season": 6, "episode": 1, "scene": 27, "line": "[sighs in unison]", "order": 29105, "speaker": "Everyone"},
  {"season": 6, "episode": 1, "scene": 27, "line": "Even the fact that I'm gay?", "order": 29106, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 1, "scene": 27, "line": "Yes.", "order": 29107, "speaker": "Michael Scott"},
  {"season": 6, "episode": 1, "scene": 27, "line": "[relieved] Yes!", "order": 29108, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 1, "scene": 27, "line": "But guys, I'm a victim here too, okay? People here have been saying that I'm a J. Crew model.", "order": 29109, "speaker": "Michael Scott"},
  {"season": 6, "episode": 1, "scene": 27, "line": "No. We heard that about Jim.", "order": 29110, "speaker": "Angela Martin"},
  {"season": 6, "episode": 1, "scene": 27, "line": "Jim? No, no. I spread the rumor that somebody here was a J. Crew model, and I was referring to myself.", "order": 29111, "speaker": "Michael Scott"},
  {"season": 6, "episode": 1, "scene": 27, "line": "How is that a bad rumor?", "order": 29112, "speaker": "Angela Martin"},
  {"season": 6, "episode": 1, "scene": 27, "line": "Because a lot of people think that models, even though they're very attractive, are somewhat vapid. It's set our industry back quite a bit.", "order": 29113, "speaker": "Michael Scott"},
  {"season": 6, "episode": 1, "scene": 27, "line": "Michael, why? Why say all these things? Why make up an eating disorder, Stanley's affair, me being a spokesdog...", "order": 29114, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 1, "scene": 27, "line": "[pissed off] You told everyone I was having an affair?", "order": 29115, "speaker": "Stanley Hudson"},
  {"season": 6, "episode": 1, "scene": 27, "line": "Okay, okay... here's the thing. There was one true rumor out there today. I didn't want everybody to know what it was. So all of you are off the hook, except for this one person.", "order": 29116, "speaker": "Michael Scott"},
  {"season": 6, "episode": 1, "scene": 27, "line": "Well then, what's the one true rumor?", "order": 29117, "speaker": "Kelly Kapoor"},
  {"season": 6, "episode": 1, "scene": 27, "line": "We have a right to know.", "order": 29118, "speaker": "Phyllis Vance"},
  {"season": 6, "episode": 1, "scene": 27, "line": "Michael... am I gay?", "order": 29119, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 1, "scene": 27, "line": "[sighs] The one true rumor, and this is going to ruin this person's life, is that...", "order": 29120, "speaker": "Michael Scott"},
  {"season": 6, "episode": 1, "scene": 27, "line": "[interrupting] That Pam's pregnant.", "order": 29121, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 1, "scene": 27, "line": "I knew it!", "order": 29122, "speaker": "Angela Martin"},
  {"season": 6, "episode": 1, "scene": 27, "line": "I knew it! Her breasts were a tiny bit bigger. At first I thought 'Oh, she has a new bra with padding.' But then I thought 'Pam doesn't need padding.' It just didn't add up, Jim.", "order": 29123, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 1, "scene": 27, "line": "Okay. Thank you.", "order": 29124, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 1, "scene": 27, "line": "Congratulations!", "order": 29125, "speaker": "Phyllis Vance"},
  {"season": 6, "episode": 1, "scene": 27, "line": "That's really great, you guys.", "order": 29126, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 1, "scene": 27, "line": "Don't vaccinate it.", "order": 29127, "speaker": "Ryan Howard"},
  {"season": 6, "episode": 1, "scene": 27, "line": "Who's the father?", "order": 29128, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 1, "scene": 27, "line": "Jim.", "order": 29129, "speaker": "Pam Beesly"},
  {"season": 6, "episode": 1, "scene": 27, "line": "How far along?", "order": 29130, "speaker": "Creed Bratton"},
  {"season": 6, "episode": 1, "scene": 27, "line": "Four months.", "order": 29131, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 1, "scene": 27, "line": "Who's the OB/GYN?", "order": 29132, "speaker": "Creed Bratton"},
  {"season": 6, "episode": 1, "scene": 27, "line": "Stop. Don't. Just...", "order": 29133, "speaker": "Pam Beesly"},
  {"season": 6, "episode": 1, "scene": 27, "line": "Alright, alright... you know, this is not the truth, and you can't fall on the sword this way. The real thing is that Stanley Hudson had an affair.", "order": 29134, "speaker": "Michael Scott"},
  {"season": 6, "episode": 1, "scene": 27, "line": "Nope.", "order": 29135, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 1, "scene": 27, "line": "Yes. Jim...", "order": 29136, "speaker": "Michael Scott"},
  {"season": 6, "episode": 1, "scene": 27, "line": "[arm around Pam] Thank you for trying to defend her. But actually, Pam is pregnant, and we can prove it.", "order": 29137, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 1, "scene": 27, "line": "Yes.", "order": 29138, "speaker": "Pam Beesly"},
  {"season": 6, "episode": 1, "scene": 27, "line": "If everyone would like to see proof.", "order": 29139, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 1, "scene": 27, "line": "Okay. [leaves room]", "order": 29140, "speaker": "Pam Beesly"},
  {"season": 6, "episode": 1, "scene": 27, "line": "Are we just gonna wait here? [Pam returns, displays sonogram]", "order": 29141, "speaker": "Kelly Kapoor"},
  {"season": 6, "episode": 1, "scene": 27, "line": "[gasps, then cheers]", "order": 29142, "speaker": "Everyone"},
  {"season": 6, "episode": 1, "scene": 27, "line": "It's true!", "order": 29143, "speaker": "Kelly Kapoor"},
  {"season": 6, "episode": 1, "scene": 27, "line": "Look at that! [grabs sonogram from Pam] That is the inside of your vagina! [Jim takes sonogram from Michael]", "order": 29144, "speaker": "Michael Scott"},
  {"season": 6, "episode": 1, "scene": 28, "line": "[talking head with Jim] It's clear why we were trying not to tell people. That's clear now. [Jim nods, looking at floor]", "order": 29145, "speaker": "Pam Beesly"},
  {"season": 6, "episode": 1, "scene": 29, "line": "[to Jim and Pam] How long have you known about the pregnancy?", "order": 29146, "speaker": "Michael Scott"},
  {"season": 6, "episode": 1, "scene": 29, "line": "[look at each other uncertainly]", "order": 29147, "speaker": "Jim Halpert & Pam Beesly"},
  {"season": 6, "episode": 1, "scene": 29, "line": "A week? A month? A year?", "order": 29148, "speaker": "Michael Scott"},
  {"season": 6, "episode": 1, "scene": 29, "line": "Michael, we only told our parents last week.", "order": 29149, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 1, "scene": 29, "line": "Did you pee on a stick?", "order": 29150, "speaker": "Michael Scott"},
  {"season": 6, "episode": 1, "scene": 29, "line": "I did. It was inconclusive.", "order": 29151, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 1, "scene": 29, "line": "You should've told me.", "order": 29152, "speaker": "Michael Scott"},
  {"season": 6, "episode": 1, "scene": 29, "line": "[exchanges looks with Jim] You're right. We should've realized that you are an equal part of this.", "order": 29153, "speaker": "Pam Beesly"},
  {"season": 6, "episode": 1, "scene": 29, "line": "[phone rings, hits speaker button]", "order": 29154, "speaker": "Michael Scott"},
  {"season": 6, "episode": 1, "scene": 29, "line": "Michael, Teri Hudson returning your call.", "order": 29155, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 1, "scene": 29, "line": "[gasps]", "order": 29156, "speaker": "Michael Scott"},
  {"season": 6, "episode": 1, "scene": 29, "line": "[in disbelief] You called Stanley's wife?", "order": 29157, "speaker": "Pam Beesly"},
  {"season": 6, "episode": 1, "scene": 29, "line": "Oh, oh... yeah, a long time ago, just to make sure she was out of town. Oh... oh God.", "order": 29158, "speaker": "Michael Scott"},
  {"season": 6, "episode": 1, "scene": 29, "line": "Don't take it.", "order": 29159, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 1, "scene": 29, "line": "No, I have to take it, or it'll seem suspicious.", "order": 29160, "speaker": "Michael Scott"},
  {"season": 6, "episode": 1, "scene": 29, "line": "If you talk to her, you're gonna tell her about Stanley, and that's gonna seem more suspicious.", "order": 29161, "speaker": "Pam Beesly"},
  {"season": 6, "episode": 1, "scene": 29, "line": "No, no, I can do this.", "order": 29162, "speaker": "Michael Scott"},
  {"season": 6, "episode": 1, "scene": 29, "line": "You can't do this.", "order": 29163, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 1, "scene": 29, "line": "Yes, I can. I am going to tell her that I need to redecorate my condo, and I need her help. We will haggle about money, and then I will back down, and then I won't talk to her until this whole Cynthia thing has blown over.", "order": 29164, "speaker": "Michael Scott"},
  {"season": 6, "episode": 1, "scene": 29, "line": "That sounds terrible.", "order": 29165, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 1, "scene": 29, "line": "Michael, please don't.", "order": 29166, "speaker": "Pam Beesly"},
  {"season": 6, "episode": 1, "scene": 29, "line": "[picking up phone] Hey, what up, Cynthia? [lowers phone, looking terrified]", "order": 29167, "speaker": "Michael Scott"},
  {"season": 6, "episode": 1, "scene": 29, "line": "[gets up to leave room, Michael gestures for him to stay]", "order": 29168, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 1, "scene": 29, "line": "Hold on a second, Cynthia.", "order": 29169, "speaker": "Michael Scott"},
  {"season": 6, "episode": 1, "scene": 29, "line": "[hangs up Michael's phone, leaves room]", "order": 29170, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 1, "scene": 30, "line": "Well, it turns out that Teri was already suspicious, because she had caught Stanley and Cynthia a few months ago. So Stanley promised that he would stop, and he did, end of story. Well, not exactly. [camera pans down from Michael's office window to parking lot below, Stanley is seen destroying Michael's Sebring with a crowbar] Uhh, Cynthia kept calling him, she would not stop calling him, and she was in love with him. Oh, and get this. Cynthia, also married. So... you can't stop love, I guess [Jim is seen displaying fetus picture on his desk as Pam smiles] and quite frankly, I don't think you should ever try.", "order": 29171, "speaker": "Michael Scott"},
  {"season": 6, "episode": 1, "scene": 31, "line": "[talking head with Eric and Megan] I learned that a company can describe their internship as 'full of exciting experiences,' even if that's a lie. There's no regulation.", "order": 29172, "speaker": "Maurie"},
  {"season": 6, "episode": 1, "scene": 31, "line": "I learned that half these people's email password is 'password.'", "order": 29173, "speaker": "Megan"},
  {"season": 6, "episode": 1, "scene": 31, "line": "I learned that a ream of paper is 300 sheets.", "order": 29174, "speaker": "Eric"},
  {"season": 6, "episode": 1, "scene": 31, "line": "I thought it was 500.", "order": 29175, "speaker": "Megan"},
  {"season": 6, "episode": 1, "scene": 31, "line": "Oh... I guess I didn't learn anything.", "order": 29176, "speaker": "Eric"},
  {"season": 6, "episode": 1, "scene": 31, "line": "I learned that if you look even the tiniest bit like Jet Li, Michael will call you 'Jet Li' all summer.", "order": 29177, "speaker": "Maurie"},
  {"season": 6, "episode": 1, "scene": 31, "line": "[raises hand] Julia Stiles.", "order": 29178, "speaker": "Megan"},
  {"season": 6, "episode": 1, "scene": 31, "line": "[raises hand] Alan Thicke.", "order": 29179, "speaker": "Eric"},
  {"season": 6, "episode": 2, "scene": 1, "line": "[knock at Michael's Office door] Yeah?", "order": 29180, "speaker": "Michael Scott"},
  {"season": 6, "episode": 2, "scene": 1, "line": "You wanted to see me?", "order": 29181, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 2, "scene": 1, "line": "Yes, Oscar come on in. Close the door if you would. Thank you, thanks so much for talking to me.", "order": 29182, "speaker": "Michael Scott"},
  {"season": 6, "episode": 2, "scene": 1, "line": "Yeah sure.", "order": 29183, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 2, "scene": 1, "line": "I'm going in for a procedure today.", "order": 29184, "speaker": "Michael Scott"},
  {"season": 6, "episode": 2, "scene": 1, "line": "Is everything okay?", "order": 29185, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 2, "scene": 1, "line": "Yeah, it's routine. I'm just a little bit scared.", "order": 29186, "speaker": "Michael Scott"},
  {"season": 6, "episode": 2, "scene": 1, "line": "I'm sure everything will be fine. [pulls up a chair] What do you? What's the procedure, if you don't mind my asking?", "order": 29187, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 2, "scene": 1, "line": "It is a colonoscopy.", "order": 29188, "speaker": "Michael Scott"},
  {"season": 6, "episode": 2, "scene": 1, "line": "Okay.", "order": 29189, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 2, "scene": 1, "line": "In your experience, what should I be expecting in terms of sensation or emotions? [Oscar looks irritated and frustrated] Is there anything I can do to make it more pleasurable for me or for Dr. Shaundry?", "order": 29190, "speaker": "Michael Scott"},
  {"season": 6, "episode": 2, "scene": 1, "line": "[sighs] Oh my God.", "order": 29191, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 2, "scene": 1, "line": "My main concern, should I have a safe word?", "order": 29192, "speaker": "Michael Scott"},
  {"season": 6, "episode": 2, "scene": 1, "line": "Yeah. [Oscar gets up and walks out]", "order": 29193, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 2, "scene": 2, "line": "So Michael, do you think you can give me a run down on the Buffalo clients by Monday?", "order": 29194, "speaker": "David Wallace"},
  {"season": 6, "episode": 2, "scene": 2, "line": "Abso... you know what? I'll do you one better, Sunday, Sunday night.", "order": 29195, "speaker": "Michael Scott"},
  {"season": 6, "episode": 2, "scene": 2, "line": "Okay, I will look at it Monday.", "order": 29196, "speaker": "David Wallace"},
  {"season": 6, "episode": 2, "scene": 2, "line": "Ho, Hol, hold on big guy, I'm gonna put it in the mail Sunday night and you'll get it Weds.", "order": 29197, "speaker": "Michael Scott"},
  {"season": 6, "episode": 2, "scene": 2, "line": "Okay, Jim you wanna hit the conference room?", "order": 29198, "speaker": "David Wallace"},
  {"season": 6, "episode": 2, "scene": 2, "line": "Sure.", "order": 29199, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 2, "scene": 2, "line": "Hey, Good luck.", "order": 29200, "speaker": "Pam Beesly"},
  {"season": 6, "episode": 2, "scene": 2, "line": "Oh right, this thing. I remember now. [whispers to Jim] What's this about?", "order": 29201, "speaker": "Michael Scott"},
  {"season": 6, "episode": 2, "scene": 2, "line": "Ah, this is just me and David, if that's okay.", "order": 29202, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 2, "scene": 2, "line": "It's okay with me but he's gonna want me in there.", "order": 29203, "speaker": "Michael Scott"},
  {"season": 6, "episode": 2, "scene": 2, "line": "No, ah it's okay Michael. We got it.", "order": 29204, "speaker": "David Wallace"},
  {"season": 6, "episode": 2, "scene": 2, "line": "Really?", "order": 29205, "speaker": "Michael Scott"},
  {"season": 6, "episode": 2, "scene": 2, "line": "Yeah.", "order": 29206, "speaker": "David Wallace"},
  {"season": 6, "episode": 2, "scene": 2, "line": "Alright, do you mind if it sit this out? I have so much work to do, I feel like I'm gonna blow my brains out.", "order": 29207, "speaker": "Michael Scott"},
  {"season": 6, "episode": 2, "scene": 2, "line": "[nods] Okay. [Jim smiles and waves to Pam]", "order": 29208, "speaker": "David Wallace"},
  {"season": 6, "episode": 2, "scene": 3, "line": "Am I worried that Jim and David are having a meeting without me? No, because we are the Three Amigos. And once in awhile one of the Amigos will go off... to the bathroom... while the other two have a secret meeting. [shrugs]", "order": 29209, "speaker": "Michael Scott"},
  {"season": 6, "episode": 2, "scene": 4, "line": "Hey let me escort you to your desk.", "order": 29210, "speaker": "Michael Scott"},
  {"season": 6, "episode": 2, "scene": 4, "line": "Okay, it's just three or four steps but thank you, thank you.", "order": 29211, "speaker": "Pam Beesly"},
  {"season": 6, "episode": 2, "scene": 4, "line": "You and Jim are close, huh?", "order": 29212, "speaker": "Michael Scott"},
  {"season": 6, "episode": 2, "scene": 4, "line": "Yeah, I think the pregnancy really brought us together.", "order": 29213, "speaker": "Pam Beesly"},
  {"season": 6, "episode": 2, "scene": 4, "line": "Hey, what do you think he and David are talking about? What do you...", "order": 29214, "speaker": "Michael Scott"},
  {"season": 6, "episode": 2, "scene": 4, "line": "Ummm, I don't know.", "order": 29215, "speaker": "Pam Beesly"},
  {"season": 6, "episode": 2, "scene": 4, "line": "Well, you said good luck to Jim as he walked in.", "order": 29216, "speaker": "Michael Scott"},
  {"season": 6, "episode": 2, "scene": 4, "line": "Did I? Doesn't sound like me, not very superstitious.", "order": 29217, "speaker": "Pam Beesly"},
  {"season": 6, "episode": 2, "scene": 4, "line": "If you are lying to me right now Pam, your baby is gonna come out a liar. That's how it works. They inherit things through the breast milk.", "order": 29218, "speaker": "Michael Scott"},
  {"season": 6, "episode": 2, "scene": 4, "line": "Please don't talk about my breast milk.", "order": 29219, "speaker": "Pam Beesly"},
  {"season": 6, "episode": 2, "scene": 4, "line": "I just don't want you to lie to me. I don't want you to ever lie to me. Have I ever lied to you?", "order": 29220, "speaker": "Michael Scott"},
  {"season": 6, "episode": 2, "scene": 4, "line": "Yeah.", "order": 29221, "speaker": "Pam Beesly"},
  {"season": 6, "episode": 2, "scene": 4, "line": "I just don't want you to.", "order": 29222, "speaker": "Michael Scott"},
  {"season": 6, "episode": 2, "scene": 5, "line": "I was rushing to fill an order. I put the ladder up to grab a box of three hole from the top shelf. Next thing I know, I'm on the ground and the ladder's on top of me.", "order": 29223, "speaker": "Darryl Philbin"},
  {"season": 6, "episode": 2, "scene": 5, "line": "And that's how you broke your ankle?", "order": 29224, "speaker": "Toby Flenderson"},
  {"season": 6, "episode": 2, "scene": 5, "line": "Yes.", "order": 29225, "speaker": "Darryl Philbin"},
  {"season": 6, "episode": 2, "scene": 5, "line": "Hmmm, interesting. How did the ladder end up on top of you, if you fell off of it?", "order": 29226, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 2, "scene": 5, "line": "This doesn't concern you man, you need to walk away.", "order": 29227, "speaker": "Darryl Philbin"},
  {"season": 6, "episode": 2, "scene": 5, "line": "Oh really, I'm sorry I thought this was a free country. I didn't know we were in communist Sweden.", "order": 29228, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 2, "scene": 5, "line": "If we were living in Sweden, I wouldn't have to worry about this 'cause we'd have universal healthcare.", "order": 29229, "speaker": "Darryl Philbin"},
  {"season": 6, "episode": 2, "scene": 5, "line": "Nnnnn, that's not... okay.", "order": 29230, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 2, "scene": 5, "line": "Be quiet.", "order": 29231, "speaker": "Darryl Philbin"},
  {"season": 6, "episode": 2, "scene": 5, "line": "I'm talking about... hmmm.", "order": 29232, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 2, "scene": 5, "line": "I will send this off to corporate. Make sure you hold on to your medical bills. Feel better.", "order": 29233, "speaker": "Toby Flenderson"},
  {"season": 6, "episode": 2, "scene": 5, "line": "Thanks T-Man, later. [fist bumps Toby}", "order": 29234, "speaker": "Darryl Philbin"},
  {"season": 6, "episode": 2, "scene": 5, "line": "So long Darryl, feel better... [to Toby] He's lying.", "order": 29235, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 2, "scene": 5, "line": "He has a doctor's note.", "order": 29236, "speaker": "Toby Flenderson"},
  {"season": 6, "episode": 2, "scene": 5, "line": "Oh? From who, Dr. J? Look, you really need to investigate this. People don't just fall off of ladders.", "order": 29237, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 2, "scene": 5, "line": "A guy on my street fell off a ladder painting his house. It was on the news.", "order": 29238, "speaker": "Toby Flenderson"},
  {"season": 6, "episode": 2, "scene": 5, "line": "Since when have you known Darryl to rush to do anything other than to come up here for birthday cake? [imitating Darryl] Y'all having birthday cake?", "order": 29239, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 2, "scene": 5, "line": "That's not a very good Darryl.", "order": 29240, "speaker": "Toby Flenderson"},
  {"season": 6, "episode": 2, "scene": 5, "line": "Please... and how many foremen do you know that pull boxes?", "order": 29241, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 2, "scene": 5, "line": "Hmmm.", "order": 29242, "speaker": "Toby Flenderson"},
  {"season": 6, "episode": 2, "scene": 5, "line": "It just doesn't add up.", "order": 29243, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 2, "scene": 6, "line": "Well, we did a little investigation and Dwight had a good point. The ladder didn't reach the top shelf. I don't know if I believe it, but I am a fan of the hardboiled detective novel. [impression] I'll punch you in da mush, see.", "order": 29244, "speaker": "Toby Flenderson"},
  {"season": 6, "episode": 2, "scene": 7, "line": "[walks into conference room on his cell phone, interrupting David and Jim] Uh huh, well if he doesn't like it you can tell that SOB that he is fired. Sorry, I'm going into a meeting right now. I will... I love you too. Bye.", "order": 29245, "speaker": "Michael Scott"},
  {"season": 6, "episode": 2, "scene": 7, "line": "Who was that?", "order": 29246, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 2, "scene": 7, "line": "Sorry about that. What'd I miss?", "order": 29247, "speaker": "Michael Scott"},
  {"season": 6, "episode": 2, "scene": 7, "line": "Michael, we would like to continue the rest of this meeting in private, please, if you don't mind.", "order": 29248, "speaker": "David Wallace"},
  {"season": 6, "episode": 2, "scene": 7, "line": "Do not mind! Yes, I do! No, I don't. Yes, I do! No, I don't mind. Catch you guys on the flippity flop. Oh this... call waiting. [answers his phone] Yeah, uh huh, well you tell the Mayor he just lost six votes.", "order": 29249, "speaker": "Michael Scott"},
  {"season": 6, "episode": 2, "scene": 8, "line": "[spying on David and Jim] They've been in there a while.", "order": 29250, "speaker": "Creed Bratton"},
  {"season": 6, "episode": 2, "scene": 8, "line": "Yeah.", "order": 29251, "speaker": "Michael Scott"},
  {"season": 6, "episode": 2, "scene": 8, "line": "Can't be good.", "order": 29252, "speaker": "Creed Bratton"},
  {"season": 6, "episode": 2, "scene": 8, "line": "Nope.", "order": 29253, "speaker": "Michael Scott"},
  {"season": 6, "episode": 2, "scene": 8, "line": "Think they're talking about me?", "order": 29254, "speaker": "Creed Bratton"},
  {"season": 6, "episode": 2, "scene": 8, "line": "No, I think they're talking about me.", "order": 29255, "speaker": "Michael Scott"},
  {"season": 6, "episode": 2, "scene": 8, "line": "Yeah, that makes way more sense. Okay thanks boss.", "order": 29256, "speaker": "Creed Bratton"},
  {"season": 6, "episode": 2, "scene": 9, "line": "What is Jim telling him? That I can't handle this job? That 's bull crap. That is bull crap. Although, it has been chaotic lately. Corporate shut down the Buffalo branch which left us to absorb all of their clients. I will tell you there has been work everyday. Had to come in on a Saturday. To retrieve... I left my cell phone here.", "order": 29257, "speaker": "Michael Scott"},
  {"season": 6, "episode": 2, "scene": 10, "line": "Hey Kelly.", "order": 29258, "speaker": "Pam Beesly"},
  {"season": 6, "episode": 2, "scene": 10, "line": "Ugh, so jealous of your boobs.", "order": 29259, "speaker": "Kelly Kapoor"},
  {"season": 6, "episode": 2, "scene": 10, "line": "Thank you. Um, listen I just wanted to confirm that you're not coming to the wedding, which is totally understandable and more than fine.", "order": 29260, "speaker": "Pam Beesly"},
  {"season": 6, "episode": 2, "scene": 10, "line": "Is Ryan going?", "order": 29261, "speaker": "Kelly Kapoor"},
  {"season": 6, "episode": 2, "scene": 10, "line": "I don't know, he hasn't RSVP'd yet.", "order": 29262, "speaker": "Pam Beesly"},
  {"season": 6, "episode": 2, "scene": 11, "line": "We invited everyone in the office to our wedding. Even though we realized most people wouldn't be able to make the drive to Niagara Falls. [whispers] Which is why we're having it in Niagara Falls. [normal volume] Then Michael told everyone they could have Friday and Monday off, if they came. So now, people have to decide if they want to come to our wedding or have to work.", "order": 29263, "speaker": "Pam Beesly"},
  {"season": 6, "episode": 2, "scene": 12, "line": "Here's the deal. I really want to go but I'm not gonna go if Ryan doesn't go, because it's kind of a waste of time. That came out wrong. It would awesome if you could try to get him to go because I'd really like to be there to support Jim.", "order": 29264, "speaker": "Kelly Kapoor"},
  {"season": 6, "episode": 2, "scene": 13, "line": "What can I do for ya Hoss?", "order": 29265, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 2, "scene": 13, "line": "[staring into the conference room] How are your sales doin'?", "order": 29266, "speaker": "Michael Scott"},
  {"season": 6, "episode": 2, "scene": 13, "line": "How are my sales doin'? Busted. My numbers are down a little bit and it's 'cause of the economy. You're not buying it, you're good. Okay the truth is, I have been having trouble focusing lately. I'm in this weird, flirty, nebulous thing with this cousin of mine and it's a total mind effer.", "order": 29267, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 2, "scene": 13, "line": "Again with the cousin.", "order": 29268, "speaker": "Phyllis Vance"},
  {"season": 6, "episode": 2, "scene": 13, "line": "Oh, I'm sorry Phyllis, you explain this email, okay. 'Hey Andy let's go visit grandma and then get drunk together, ha ha.'", "order": 29269, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 2, "scene": 13, "line": "Shhh, I'm just trying to figure out what's going on in that room, okay?", "order": 29270, "speaker": "Michael Scott"},
  {"season": 6, "episode": 2, "scene": 14, "line": "Wallace had to show up on the one four month period that I'm completely overwhelmed.", "order": 29271, "speaker": "Michael Scott"},
  {"season": 6, "episode": 2, "scene": 15, "line": "You know when they say it's so crazy it just might work? Well, I don't believe that. I say go for the air-tight plan and that is why I am having Andy wheel me into the conference room inside a makeshift cheese cart. It is just elegant.", "order": 29272, "speaker": "Michael Scott"},
  {"season": 6, "episode": 2, "scene": 16, "line": "[in the cheese cart] Be my eyes.", "order": 29273, "speaker": "Michael Scott"},
  {"season": 6, "episode": 2, "scene": 16, "line": "You got it.", "order": 29274, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 2, "scene": 16, "line": "What have we here?", "order": 29275, "speaker": "Stanley Hudson"},
  {"season": 6, "episode": 2, "scene": 16, "line": "Oh, just backing up and turning around.", "order": 29276, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 2, "scene": 16, "line": "Hey, we're gonna do this...", "order": 29277, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 2, "scene": 16, "line": "Dit, dit, dit, dit, it's not for you. [enters the conference room] Pardon moi, Messieurs. I took the liberty of preparing for you a cheese platter which I will place here on the eastern wall of the conference room.", "order": 29278, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 2, "scene": 16, "line": "Cheese platter?", "order": 29279, "speaker": "David Wallace"},
  {"season": 6, "episode": 2, "scene": 16, "line": "Oui, Oui, Monsieur. From the Wisconsin region, a nice firm cheddar. Also from the great state of Wisconsin, an aged parmesan. Here you will find a cheddar style spread which has reacted nicely with the air to form a light rind which I think you'll find both challenging and delicious. At that point, I would recommend you take a trip south of the border to the great state of Illinois where you will find this fine Bleu Cheese dressing. If I may be so bold, it's a lot of fun to let the goldfish take a little swim in the bleu cheese. Bon appetit.", "order": 29280, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 2, "scene": 16, "line": "Alright, well I should go. Thanks for this. You've given me a lot to think about. Please promise me you won't do anything until we speak.", "order": 29281, "speaker": "David Wallace"},
  {"season": 6, "episode": 2, "scene": 16, "line": "No, I won't. Alright, thanks for giving me your time, I appreciate it.", "order": 29282, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 2, "scene": 16, "line": "Thank you Jim.", "order": 29283, "speaker": "David Wallace"},
  {"season": 6, "episode": 2, "scene": 17, "line": "Erin, is Michael around?", "order": 29284, "speaker": "David Wallace"},
  {"season": 6, "episode": 2, "scene": 17, "line": "I think he's around here...", "order": 29285, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 2, "scene": 17, "line": "[crawls out of the cheese cart unseen] Hey, hey, you must have walked right past me. How you doin'?", "order": 29286, "speaker": "Michael Scott"},
  {"season": 6, "episode": 2, "scene": 17, "line": "Ah, yeah, could we talk in your office for a second?", "order": 29287, "speaker": "David Wallace"},
  {"season": 6, "episode": 2, "scene": 17, "line": "Yes, definitely.", "order": 29288, "speaker": "Michael Scott"},
  {"season": 6, "episode": 2, "scene": 18, "line": "[spying at Darryl's house] Sounds like a EMDP-40.", "order": 29289, "speaker": "Toby Flenderson"},
  {"season": 6, "episode": 2, "scene": 18, "line": "Nah, that's a GE. A P40 is much higher pitched.", "order": 29290, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 2, "scene": 18, "line": "You're into trains?", "order": 29291, "speaker": "Toby Flenderson"},
  {"season": 6, "episode": 2, "scene": 18, "line": "I have been my whole life.", "order": 29292, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 2, "scene": 18, "line": "Wow.", "order": 29293, "speaker": "Toby Flenderson"},
  {"season": 6, "episode": 2, "scene": 18, "line": "I'm rebuilding a turn of the century steam engine in my slaughter house.", "order": 29294, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 2, "scene": 18, "line": "That's so cool.", "order": 29295, "speaker": "Toby Flenderson"},
  {"season": 6, "episode": 2, "scene": 18, "line": "Yeah.", "order": 29296, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 2, "scene": 18, "line": "Wow, I'd love to take a look at that.", "order": 29297, "speaker": "Toby Flenderson"},
  {"season": 6, "episode": 2, "scene": 18, "line": "Yeah, it's just a run of the mill slaughter house, but sure anytime.", "order": 29298, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 2, "scene": 18, "line": "Well, you know, 'cause of the trains.", "order": 29299, "speaker": "Toby Flenderson"},
  {"season": 6, "episode": 2, "scene": 18, "line": "Wa... wa... wait , someone's coming.", "order": 29300, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 2, "scene": 18, "line": "Uhh, oh boy, oh boy.", "order": 29301, "speaker": "Toby Flenderson"},
  {"season": 6, "episode": 2, "scene": 18, "line": "Here, get down! He's not using crutches! Get the camera!", "order": 29302, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 2, "scene": 18, "line": "Get over there! Get over there!", "order": 29303, "speaker": "Toby Flenderson"},
  {"season": 6, "episode": 2, "scene": 18, "line": "Oh, worker's comp, my ass!", "order": 29304, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 2, "scene": 18, "line": "I can't believe this. [mistaking Darryl's sister for Darryl] Hey you, ass****!", "order": 29305, "speaker": "Toby Flenderson"},
  {"season": 6, "episode": 2, "scene": 18, "line": "Toby...", "order": 29306, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 2, "scene": 18, "line": "You gonna eat all that dog food yourself?", "order": 29307, "speaker": "Toby Flenderson"},
  {"season": 6, "episode": 2, "scene": 18, "line": "Ooohhh... [crashes car into garbage cans] Oh man! You okay?", "order": 29308, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 2, "scene": 18, "line": "Just keep going.", "order": 29309, "speaker": "Toby Flenderson"},
  {"season": 6, "episode": 2, "scene": 19, "line": "Hey Ryan.", "order": 29310, "speaker": "Pam Beesly"},
  {"season": 6, "episode": 2, "scene": 19, "line": "Hey.", "order": 29311, "speaker": "Ryan Howard"},
  {"season": 6, "episode": 2, "scene": 19, "line": "So, I just wanted to confirm that you're not coming to my wedding.", "order": 29312, "speaker": "Pam Beesly"},
  {"season": 6, "episode": 2, "scene": 19, "line": "Yeah, I might stop by.", "order": 29313, "speaker": "Ryan Howard"},
  {"season": 6, "episode": 2, "scene": 19, "line": "It costs about $75 per person.", "order": 29314, "speaker": "Pam Beesly"},
  {"season": 6, "episode": 2, "scene": 19, "line": "Okay, I once had a glass of Cognac that cost $77.", "order": 29315, "speaker": "Ryan Howard"},
  {"season": 6, "episode": 2, "scene": 19, "line": "Can you just tell me now if you're coming or not?", "order": 29316, "speaker": "Pam Beesly"},
  {"season": 6, "episode": 2, "scene": 19, "line": "Yes, I'm coming to your wedding.", "order": 29317, "speaker": "Ryan Howard"},
  {"season": 6, "episode": 2, "scene": 19, "line": "Okay, Ryan Howard, yes.", "order": 29318, "speaker": "Pam Beesly"},
  {"season": 6, "episode": 2, "scene": 19, "line": "Probably, yes.", "order": 29319, "speaker": "Ryan Howard"},
  {"season": 6, "episode": 2, "scene": 19, "line": "Kelly Kapoor, yes.", "order": 29320, "speaker": "Pam Beesly"},
  {"season": 6, "episode": 2, "scene": 20, "line": "Are you sure you're okay with these new responsibilites?", "order": 29321, "speaker": "David Wallace"},
  {"season": 6, "episode": 2, "scene": 20, "line": "Look who you're talking to.", "order": 29322, "speaker": "Michael Scott"},
  {"season": 6, "episode": 2, "scene": 20, "line": "I'm just trying to figure out the best way to utilize everyone's talents.", "order": 29323, "speaker": "David Wallace"},
  {"season": 6, "episode": 2, "scene": 20, "line": "Well I would say that my greatest talent is being the best man, woman, or child, to have ever run this branch, ever.", "order": 29324, "speaker": "Michael Scott"},
  {"season": 6, "episode": 2, "scene": 20, "line": "What about Jim? Do you think Jim is someone who is ready to move into a management position?", "order": 29325, "speaker": "David Wallace"},
  {"season": 6, "episode": 2, "scene": 20, "line": "[looks at Jim] Permission to speak on the record?", "order": 29326, "speaker": "Michael Scott"},
  {"season": 6, "episode": 2, "scene": 20, "line": "Please do.", "order": 29327, "speaker": "David Wallace"},
  {"season": 6, "episode": 2, "scene": 20, "line": "Jim is like Big Bird. He is tall and yellow and very nice. But would I put him in charge? No, I don't think so. Because Big Bird doesn't make the tough decisions. I... If I was gonna put someone in charge, I would put Burt in charge or I would put one of the real grown-ups in charge, like Maria or Gordon, maybe.", "order": 29328, "speaker": "Michael Scott"},
  {"season": 6, "episode": 2, "scene": 20, "line": "I thought you liked Jim?", "order": 29329, "speaker": "David Wallace"},
  {"season": 6, "episode": 2, "scene": 20, "line": "Very much, Jim is my best friend. But it's his performance report... right here. Now this was written by Toby, who we all know is extremely reliable.", "order": 29330, "speaker": "Michael Scott"},
  {"season": 6, "episode": 2, "scene": 20, "line": "[reading performance report] 'Constant office distractions, spends way too much time at reception, antagonizes other salesman, not at all what he thinks he is.'", "order": 29331, "speaker": "David Wallace"},
  {"season": 6, "episode": 2, "scene": 20, "line": "It's not very well written, but you get the gist.", "order": 29332, "speaker": "Michael Scott"},
  {"season": 6, "episode": 2, "scene": 20, "line": "Is there anybody else you think could run the day to day of the branch?", "order": 29333, "speaker": "David Wallace"},
  {"season": 6, "episode": 2, "scene": 20, "line": "I can just continue to run it myself.", "order": 29334, "speaker": "Michael Scott"},
  {"season": 6, "episode": 2, "scene": 20, "line": "Jim had an interesting idea to help you with this extra work load and it involved you being promoted to oversee all northeast sales and then Jim would be promoted to your position.", "order": 29335, "speaker": "David Wallace"},
  {"season": 6, "episode": 2, "scene": 20, "line": "This was Jim's idea?", "order": 29336, "speaker": "Michael Scott"},
  {"season": 6, "episode": 2, "scene": 20, "line": "Mmhmm.", "order": 29337, "speaker": "David Wallace"},
  {"season": 6, "episode": 2, "scene": 20, "line": "Wow. Ummm, well I'd have to talk to my mother and my guy at H and R Block.", "order": 29338, "speaker": "Michael Scott"},
  {"season": 6, "episode": 2, "scene": 20, "line": "Ummm... okay, here's the thing though. The plan doesn't work unless we have someone to replace you. I was hoping... plus we have to worry about Jim, he has another job offer. We may have to replace him.", "order": 29339, "speaker": "David Wallace"},
  {"season": 6, "episode": 2, "scene": 21, "line": "I didn't tell Michael because I thought he'd try to help. Example, he handed out Jello shots at the 23rd mile of the Steamtown marathon.", "order": 29340, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 2, "scene": 22, "line": "Jim can you walk me out to my car for a second.", "order": 29341, "speaker": "David Wallace"},
  {"season": 6, "episode": 2, "scene": 22, "line": "Sure.", "order": 29342, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 2, "scene": 23, "line": "[after talking to David, Jim looks dejected] I can't help but feel partially responsible.", "order": 29343, "speaker": "Michael Scott"},
  {"season": 6, "episode": 2, "scene": 24, "line": "[shakes jelly beans] Little pick me up? [Jim accepts] Oh those are the best. Little sugar rush.", "order": 29344, "speaker": "Michael Scott"},
  {"season": 6, "episode": 2, "scene": 24, "line": "When you were in the office earlier talking to Wallace, did I come up at all?", "order": 29345, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 2, "scene": 24, "line": "Well we did talk about how handsome you are.", "order": 29346, "speaker": "Michael Scott"},
  {"season": 6, "episode": 2, "scene": 24, "line": "Why don't we head in your office to talk?", "order": 29347, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 2, "scene": 24, "line": "Oh what a week, God. We said... I know there were certain things we said...", "order": 29348, "speaker": "Michael Scott"},
  {"season": 6, "episode": 2, "scene": 24, "line": "Michael, look I'll just be honest with you. Earlier today I spoke with Wallace about a promotion. I actually think that talk went really well. And then after he spoke with you, for some reason it felt like things had changed.", "order": 29349, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 2, "scene": 24, "line": "Hmmm, that's weird, that's weird... it's kinda weirding me out. Did you know that Lincoln's secretary was named Kennedy.", "order": 29350, "speaker": "Michael Scott"},
  {"season": 6, "episode": 2, "scene": 24, "line": "Michael, did you say anything?", "order": 29351, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 2, "scene": 24, "line": "Tell you what, when you leave this office I am going to call Wallace and I am going to tell him that you should get that promotion.", "order": 29352, "speaker": "Michael Scott"},
  {"season": 6, "episode": 2, "scene": 24, "line": "Really?", "order": 29353, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 2, "scene": 24, "line": "Yes!", "order": 29354, "speaker": "Michael Scott"},
  {"season": 6, "episode": 2, "scene": 24, "line": "You will? Wanna do it right now?", "order": 29355, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 2, "scene": 24, "line": "Yes.", "order": 29356, "speaker": "Michael Scott"},
  {"season": 6, "episode": 2, "scene": 25, "line": "I tried to keep Michael in the dark. I should have known that he can do just as much damage in the dark.", "order": 29357, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 2, "scene": 26, "line": "I need to see your pupils. I need to see if they're dilated.", "order": 29358, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 2, "scene": 26, "line": "If they were dilated...", "order": 29359, "speaker": "Toby Flenderson"},
  {"season": 6, "episode": 2, "scene": 26, "line": "Toby! Dwight! Is that them?", "order": 29360, "speaker": "Darryl Philbin"},
  {"season": 6, "episode": 2, "scene": 26, "line": "Definitely.", "order": 29361, "speaker": "Gwenneth"},
  {"season": 6, "episode": 2, "scene": 26, "line": "You come to my house, bust up my trash cans, call my baby sister an ass****, and told her to eat dog food.", "order": 29362, "speaker": "Darryl Philbin"},
  {"season": 6, "episode": 2, "scene": 26, "line": "We thought that she was you.", "order": 29363, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 2, "scene": 26, "line": "Why would you think a lady is me?", "order": 29364, "speaker": "Darryl Philbin"},
  {"season": 6, "episode": 2, "scene": 26, "line": "Are you... are you serious? Be... cause you look exactly alike. Am I the only one? Are you getting this?", "order": 29365, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 2, "scene": 26, "line": "No, nah. I don't see it.", "order": 29366, "speaker": "Toby Flenderson"},
  {"season": 6, "episode": 2, "scene": 26, "line": "Here's what I'm gonna do, I'm calling corporate and I'm filing a complaint against both of you.", "order": 29367, "speaker": "Darryl Philbin"},
  {"season": 6, "episode": 2, "scene": 26, "line": "Aww, Darryl.", "order": 29368, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 2, "scene": 26, "line": "C'mon Gwenneth.", "order": 29369, "speaker": "Darryl Philbin"},
  {"season": 6, "episode": 2, "scene": 26, "line": "Sad.", "order": 29370, "speaker": "Gwenneth"},
  {"season": 6, "episode": 2, "scene": 27, "line": "[on phone] This is David.", "order": 29371, "speaker": "David Wallace"},
  {"season": 6, "episode": 2, "scene": 27, "line": "Hey David, this is Michael Scott. [Jim pushes speaker phone button] Michael Scott here.", "order": 29372, "speaker": "Michael Scott"},
  {"season": 6, "episode": 2, "scene": 27, "line": "Yep.", "order": 29373, "speaker": "David Wallace"},
  {"season": 6, "episode": 2, "scene": 27, "line": "Just wanted to talk to you about Jim Halpert. I understand that he did not receive the promotion. And I wanted to see what I could do to nudge you in the right direction.", "order": 29374, "speaker": "Michael Scott"},
  {"season": 6, "episode": 2, "scene": 27, "line": "So, what, you're changing your mind about Jim?", "order": 29375, "speaker": "David Wallace"},
  {"season": 6, "episode": 2, "scene": 27, "line": "Absolutely, [turns speakerphone off] not. [turns speakerphone back on] Like I said before, Jim is fantastic and he deserves this job.", "order": 29376, "speaker": "Michael Scott "},
  {"season": 6, "episode": 2, "scene": 27, "line": "Michael, it seems like you're cutting in and out... This is not what you said earlier.", "order": 29377, "speaker": "David Wallace"},
  {"season": 6, "episode": 2, "scene": 27, "line": "Wha... here's the thing, Jim is the best man for this job, I think you should give it to him.", "order": 29378, "speaker": "Michael Scott"},
  {"season": 6, "episode": 2, "scene": 27, "line": "Well, it doesn't change what you showed me in Jim's file.", "order": 29379, "speaker": "David Wallace"},
  {"season": 6, "episode": 2, "scene": 27, "line": "Well, nnnn, that... that was an anomaly. That file had been falsified. Toby Flenderson is doing drugs.", "order": 29380, "speaker": "Michael Scott"},
  {"season": 6, "episode": 2, "scene": 27, "line": "Michael, I really don't know what's going on [police sirens in background] down there, but... oh, dammit, I am getting pulled over for talking on my cell, dammit.", "order": 29381, "speaker": "David Wallace"},
  {"season": 6, "episode": 2, "scene": 28, "line": "Hey Darryl, look ahhh, we're here to apologize ahh...", "order": 29382, "speaker": "Toby Flenderson"},
  {"season": 6, "episode": 2, "scene": 28, "line": "Cool.", "order": 29383, "speaker": "Darryl Philbin"},
  {"season": 6, "episode": 2, "scene": 28, "line": "Wait! They're using the lift as their own personal elevator.", "order": 29384, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 2, "scene": 28, "line": "What?", "order": 29385, "speaker": "Toby Flenderson"},
  {"season": 6, "episode": 2, "scene": 28, "line": "He broke his ankle climbing over the railing and he lied about it because it was a misuse of company equipment, case closed.", "order": 29386, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 2, "scene": 28, "line": "Stupidest thing I've ever heard.", "order": 29387, "speaker": "Darryl Philbin"},
  {"season": 6, "episode": 2, "scene": 28, "line": "Really? Then why is there new wood only on one railing? Hmmm? We should check the security tapes Toby.", "order": 29388, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 2, "scene": 28, "line": "Well, I don't think there's any reason to check 'em but I suppose if we wanted to be really certain...", "order": 29389, "speaker": "Toby Flenderson"},
  {"season": 6, "episode": 2, "scene": 28, "line": "Alright, yeah fine so whatever...", "order": 29390, "speaker": "Darryl Philbin"},
  {"season": 6, "episode": 2, "scene": 28, "line": "Yes!", "order": 29391, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 2, "scene": 28, "line": "You really did it...", "order": 29392, "speaker": "Toby Flenderson"},
  {"season": 6, "episode": 2, "scene": 28, "line": "Now, I would like to file an official complaint to corporate because Darryl lied on an official form.", "order": 29393, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 2, "scene": 28, "line": "And I'm telling them you guys sexually harassed my sister.", "order": 29394, "speaker": "Darryl Philbin"},
  {"season": 6, "episode": 2, "scene": 28, "line": "Phhhht. No judge is gonna believe that...", "order": 29395, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 2, "scene": 28, "line": "Ahhh, kay. Look, we could all file complaints against each other and just drown in a sea of paperwork, but you know, we can just move on with our... with our lives.", "order": 29396, "speaker": "Toby Flenderson"},
  {"season": 6, "episode": 2, "scene": 29, "line": "So Dwight and Darryl came to an agreement that both would file complaints with corporate and now I get to do all this paperwork. We worked it out.", "order": 29397, "speaker": "Toby Flenderson"},
  {"season": 6, "episode": 2, "scene": 30, "line": "Hey, hey Jim, it would make me feel really good right now if you would just punch me in the face. Alright, Just do it.", "order": 29398, "speaker": "Michael Scott"},
  {"season": 6, "episode": 2, "scene": 30, "line": "How many times have I asked you to put me up for a branch manager job.", "order": 29399, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 2, "scene": 30, "line": "I never recommended you because I didn't want to lose you and I didn't want to lose Pam, [starts to choke up] and now I don't wanna lose the baby.", "order": 29400, "speaker": "Michael Scott"},
  {"season": 6, "episode": 2, "scene": 30, "line": "So instead, you screwed me?", "order": 29401, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 2, "scene": 30, "line": "That's what she...", "order": 29402, "speaker": "Michael Scott"},
  {"season": 6, "episode": 2, "scene": 30, "line": "No.", "order": 29403, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 2, "scene": 30, "line": "David Wallace is calling.", "order": 29404, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 2, "scene": 30, "line": "Kind of in the middle of something here.", "order": 29405, "speaker": "Michael Scott"},
  {"season": 6, "episode": 2, "scene": 30, "line": "Should I tell him you'll call him back?", "order": 29406, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 2, "scene": 30, "line": "I, yeah... no, I'll tell him myself. Let's both.", "order": 29407, "speaker": "Michael Scott"},
  {"season": 6, "episode": 2, "scene": 31, "line": "[on phone] Hello.", "order": 29408, "speaker": "Michael Scott"},
  {"season": 6, "episode": 2, "scene": 31, "line": "Hey Michael, you got Jim there with you?", "order": 29409, "speaker": "David Wallace"},
  {"season": 6, "episode": 2, "scene": 31, "line": "No, it's just us.", "order": 29410, "speaker": "Michael Scott"},
  {"season": 6, "episode": 2, "scene": 31, "line": "Actually, can you call Jim in? I want him on, please.", "order": 29411, "speaker": "David Wallace"},
  {"season": 6, "episode": 2, "scene": 31, "line": "Oh, oh, well here he is right now. [Michael knocks on desk] Come in. [Makes sound effects for door opening and footsteps and points to Jim to answer]", "order": 29412, "speaker": "Michael Scott"},
  {"season": 6, "episode": 2, "scene": 31, "line": "Hi David.", "order": 29413, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 2, "scene": 31, "line": "Hey guys, so I spoke to Alan, we had kind of an unconventional idea, which I think is pretty cool. But it only works if everyone's on board.", "order": 29414, "speaker": "David Wallace"},
  {"season": 6, "episode": 2, "scene": 31, "line": "Well just as long as it means Jim becomes a manager.", "order": 29415, "speaker": "Michael Scott"},
  {"season": 6, "episode": 2, "scene": 31, "line": "We were thinking of having two branch managers in Scranton. Both of you guys working as co-managers. Jim would handle the day to day and Michael you would focus on clients and big picture stuff.", "order": 29416, "speaker": "David Wallace"},
  {"season": 6, "episode": 2, "scene": 31, "line": "Wow, that sounds pretty cool.", "order": 29417, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 2, "scene": 31, "line": "I like that, so manager and co-manager.", "order": 29418, "speaker": "Michael Scott"},
  {"season": 6, "episode": 2, "scene": 31, "line": "Co-manager and Co-manager. See, there are a lot of moving pieces and this is the only way I can sell it upstairs.", "order": 29419, "speaker": "David Wallace"},
  {"season": 6, "episode": 2, "scene": 31, "line": "Well that might be a little confusing for people because they know me as manager.", "order": 29420, "speaker": "Michael Scott"},
  {"season": 6, "episode": 2, "scene": 31, "line": "Alright, hey Michael can you pick up for one second. [Michael picks up phone] Okay look, I'm not going to force you into anything. If you're willing to lose Jim, fine, you just say so and we'll find another solution, okay. Is that what you want to do?", "order": 29421, "speaker": "David Wallace"},
  {"season": 6, "episode": 2, "scene": 32, "line": "Okay people, listen up, I have an announcement to make, fraud was committed...", "order": 29422, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 2, "scene": 32, "line": "Okay everybody, I have an announcement to make... whoa do you have an announcement?", "order": 29423, "speaker": "Michael Scott"},
  {"season": 6, "episode": 2, "scene": 32, "line": "I'm... I was making it.", "order": 29424, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 2, "scene": 32, "line": "Everybody, David Wallace and I have talked and we have decided to promote Jim to the position of co-manager.", "order": 29425, "speaker": "Michael Scott"},
  {"season": 6, "episode": 2, "scene": 32, "line": "Co-manager of what?", "order": 29426, "speaker": "Stanley Hudson"},
  {"season": 6, "episode": 2, "scene": 32, "line": "Of your butt, and your butt, and your butt, all of the Scranton branch butts.", "order": 29427, "speaker": "Michael Scott"},
  {"season": 6, "episode": 2, "scene": 32, "line": "What's happening to you Michael?", "order": 29428, "speaker": "Phyllis Vance"},
  {"season": 6, "episode": 2, "scene": 32, "line": "What's happening to me? I am also being promoted to co-manager, we will be co-managers together. Jim Halpert, welcome.", "order": 29429, "speaker": "Michael Scott"},
  {"season": 6, "episode": 2, "scene": 32, "line": "[shakes Michael's hand] Thank you. [Dwight looks disappointed and angry behind them]", "order": 29430, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 2, "scene": 33, "line": "AHHHHHHHHHHHH!", "order": 29431, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 2, "scene": 34, "line": "Niagara Falls in October? Pfft, hells yeah.", "order": 29432, "speaker": "Meredith Palmer"},
  {"season": 6, "episode": 2, "scene": 34, "line": "Do you think that you could send in your RSVP card?", "order": 29433, "speaker": "Pam Beesly"},
  {"season": 6, "episode": 2, "scene": 34, "line": "Nah, I'll just tell you now, easier.", "order": 29434, "speaker": "Meredith Palmer"},
  {"season": 6, "episode": 2, "scene": 34, "line": "Well, you have to choose a food option and there's information in there about directions and lodging...", "order": 29435, "speaker": "Pam Beesly"},
  {"season": 6, "episode": 2, "scene": 34, "line": "I'll just have whatever's fanciest, unless there's ribs. I'll just get the other information the day of, I'll text you.", "order": 29436, "speaker": "Meredith Palmer"},
  {"season": 6, "episode": 2, "scene": 34, "line": "You are going to text me, the morning of my wedding.", "order": 29437, "speaker": "Pam Beesly"},
  {"season": 6, "episode": 2, "scene": 34, "line": "Uh huh.", "order": 29438, "speaker": "Meredith Palmer"},
  {"season": 6, "episode": 2, "scene": 34, "line": "To ask for directions..", "order": 29439, "speaker": "Pam Beesly"},
  {"season": 6, "episode": 2, "scene": 34, "line": "Right...", "order": 29440, "speaker": "Meredith Palmer"},
  {"season": 6, "episode": 2, "scene": 34, "line": "And you will eat whatever is fanciest?", "order": 29441, "speaker": "Pam Beesly"},
  {"season": 6, "episode": 2, "scene": 34, "line": "Unless there's ribs. [leaves]", "order": 29442, "speaker": "Meredith Palmer"},
  {"season": 6, "episode": 2, "scene": 34, "line": "Rude.", "order": 29443, "speaker": "Angela Martin"},
  {"season": 6, "episode": 2, "scene": 34, "line": "So rude, right?", "order": 29444, "speaker": "Pam Beesly"},
  {"season": 6, "episode": 2, "scene": 34, "line": "Mmm hmmm.", "order": 29445, "speaker": "Angela Martin"},
  {"season": 6, "episode": 2, "scene": 34, "line": "[sits down] Oh my gosh, I have been chasing people down all day. It's incredible.", "order": 29446, "speaker": "Pam Beesly"},
  {"season": 6, "episode": 2, "scene": 34, "line": "Pam, my bag was there...", "order": 29447, "speaker": "Angela Martin"},
  {"season": 6, "episode": 3, "scene": 1, "line": "[in Michael's office] Could you please sign my expense report?", "order": 29448, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 3, "scene": 1, "line": "No way, no how. Expense reports are a day-to-day item. That is Jim's now. I am exclusively big picture, epic.", "order": 29449, "speaker": "Michael Scott"},
  {"season": 6, "episode": 3, "scene": 2, "line": "[in Jim's office] Sign this.", "order": 29450, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 3, "scene": 2, "line": "Ah, ah, ah. Where's the 'please'? We're not animals.", "order": 29451, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 3, "scene": 2, "line": "Sign it.", "order": 29452, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 3, "scene": 2, "line": "No, not without a 'please.'", "order": 29453, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 3, "scene": 2, "line": "Idiot.", "order": 29454, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 3, "scene": 3, "line": "[in Michael's office] I have a complaint about Jim.", "order": 29455, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 3, "scene": 3, "line": "That is not big picture.", "order": 29456, "speaker": "Michael Scott"},
  {"season": 6, "episode": 3, "scene": 3, "line": "I would like to file a huge, enormous, massive complaint about the tallest guy in our office.", "order": 29457, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 3, "scene": 3, "line": "... Okay.", "order": 29458, "speaker": "Michael Scott"},
  {"season": 6, "episode": 3, "scene": 3, "line": "Jim won't sign my expense report.", "order": 29459, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 3, "scene": 3, "line": "That is not-- okay-- that is day-to-day.", "order": 29460, "speaker": "Michael Scott"},
  {"season": 6, "episode": 3, "scene": 3, "line": "No, that is huge.", "order": 29461, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 3, "scene": 3, "line": "You're trying to trick me.", "order": 29462, "speaker": "Michael Scott"},
  {"season": 6, "episode": 3, "scene": 3, "line": "This has to do--", "order": 29463, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 3, "scene": 3, "line": "Get out, get out, get out, get out, get out.", "order": 29464, "speaker": "Michael Scott"},
  {"season": 6, "episode": 3, "scene": 4, "line": "[in Jim's office] I'd like to lodge a complaint.", "order": 29465, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 3, "scene": 4, "line": "I'm sorry to hear about that. Who is this about?", "order": 29466, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 3, "scene": 4, "line": "You!", "order": 29467, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 3, "scene": 4, "line": "Okay. Just want to let you know I take complaints very seriously. So... When did this happen?", "order": 29468, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 3, "scene": 4, "line": "One minute ago.", "order": 29469, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 3, "scene": 4, "line": "Okay. And how do you feel?", "order": 29470, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 3, "scene": 4, "line": "Angry.", "order": 29471, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 3, "scene": 4, "line": "All right. Did he hit you?", "order": 29472, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 3, "scene": 4, "line": "No.", "order": 29473, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 3, "scene": 4, "line": "Did you cry?", "order": 29474, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 3, "scene": 4, "line": "No.", "order": 29475, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 3, "scene": 4, "line": "Did you feel like crying?", "order": 29476, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 3, "scene": 4, "line": "No.", "order": 29477, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 3, "scene": 4, "line": "I'm just gonna write 'held back tears.'", "order": 29478, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 3, "scene": 4, "line": "Wait, stop writing that. That is not true.", "order": 29479, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 3, "scene": 4, "line": "If you stop crying, I'll stop writing it.", "order": 29480, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 3, "scene": 4, "line": "I'm not cr-- uhh!", "order": 29481, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 3, "scene": 5, "line": "I deserved that promotion, not Jim. Oh, man. Makes me want to put him in a triangle choke hold and force him down to the ground and just keep pressing and pressing. And then flip him over and then put him in a hammer lock! And he's gasping, he's panting! Every last breath! And the crowd is going crazy. And boom! I emerge victorious! [claps hands and stands up] Aha! $18,000 and a chance at the title! Ooh hah!", "order": 29482, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 3, "scene": 5, "line": "[at the door] Dwight, Jim wants you to keep it down.", "order": 29483, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 3, "scene": 6, "line": "Everyone, now would be a good time to freshen up, because I'd like everyone to meet in the conference room in five minutes. And I suggest that you bring a snack, because we may be in there for a while.", "order": 29484, "speaker": "Michael Scott"},
  {"season": 6, "episode": 3, "scene": 6, "line": "Michael...", "order": 29485, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 3, "scene": 6, "line": "Yes?", "order": 29486, "speaker": "Michael Scott"},
  {"season": 6, "episode": 3, "scene": 6, "line": "Can I talk to you in my office for a second?", "order": 29487, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 3, "scene": 6, "line": "Mmm, sure, but could I first talk to you in my office?", "order": 29488, "speaker": "Michael Scott"},
  {"season": 6, "episode": 3, "scene": 6, "line": "Can I also be a boss?", "order": 29489, "speaker": "Stanley Hudson"},
  {"season": 6, "episode": 3, "scene": 7, "line": "Look, it doesn't take a genius to know that any organization thrives when it has two leaders. Go ahead, name a country that doesn't have two presidents. A boat that sets sail without two captains. Where would Catholicism be without the popes?", "order": 29490, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 3, "scene": 8, "line": "I have noticed that we--", "order": 29491, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 3, "scene": 8, "line": "Welcome.", "order": 29492, "speaker": "Michael Scott"},
  {"season": 6, "episode": 3, "scene": 8, "line": "Thank you. I've noticed we've been having a lot of conference room meetings.", "order": 29493, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 3, "scene": 8, "line": "Mm-hmm.", "order": 29494, "speaker": "Michael Scott"},
  {"season": 6, "episode": 3, "scene": 8, "line": "And I'm wondEring if perhaps those are a bit, um--", "order": 29495, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 3, "scene": 8, "line": "Disruptive.", "order": 29496, "speaker": "Michael Scott"},
  {"season": 6, "episode": 3, "scene": 8, "line": "Yes.", "order": 29497, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 3, "scene": 8, "line": "No, I don't think they are. I think the meetings are very important.", "order": 29498, "speaker": "Michael Scott"},
  {"season": 6, "episode": 3, "scene": 8, "line": "Okay, maybe we should talk specifics.", "order": 29499, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 3, "scene": 8, "line": "Let's do.", "order": 29500, "speaker": "Michael Scott"},
  {"season": 6, "episode": 3, "scene": 8, "line": "Okay. Yesterday, we had a meeting about planets.", "order": 29501, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 3, "scene": 8, "line": "N-- well, to be fair, Jim... James... Jimothy... To be fair, Jimothy, the-- ah, that sounds weird. Are you okay with being called Jim?", "order": 29502, "speaker": "Michael Scott"},
  {"season": 6, "episode": 3, "scene": 8, "line": "I am.", "order": 29503, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 3, "scene": 8, "line": "Alright. Jim, to be fair, the conversation wasn't about planets...", "order": 29504, "speaker": "Michael Scott"},
  {"season": 6, "episode": 3, "scene": 8, "line": "Right.", "order": 29505, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 3, "scene": 8, "line": "At first, we were talking about introducing a line of toilet paper. And what part of the human body does one use toilet paper upon? So you draw a line from there to the other planets, and I think by the end, we all learned a little bit about how small we are.", "order": 29506, "speaker": "Michael Scott"},
  {"season": 6, "episode": 3, "scene": 8, "line": "Yes, I agree.", "order": 29507, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 3, "scene": 8, "line": "Because it's a big universe, and we're all just little, tiny specks of dust.", "order": 29508, "speaker": "Michael Scott"},
  {"season": 6, "episode": 3, "scene": 8, "line": "I just think that maybe they're eating large amounts of the day.", "order": 29509, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 3, "scene": 8, "line": "You know what eats a large amount of the day are naps. You go to sleep, it's light out, you wake up, it's dark. That's the whole day. Where did that day go? I have no idea.", "order": 29510, "speaker": "Michael Scott"},
  {"season": 6, "episode": 3, "scene": 8, "line": "You mean on a weekend?", "order": 29511, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 3, "scene": 8, "line": "Yes.", "order": 29512, "speaker": "Michael Scott"},
  {"season": 6, "episode": 3, "scene": 8, "line": "How about this? I think our goal should be we don't have meetings unless they're absolutely essential.", "order": 29513, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 3, "scene": 8, "line": "Alright. No more meetings this week.", "order": 29514, "speaker": "Michael Scott"},
  {"season": 6, "episode": 3, "scene": 8, "line": "Really? You just agreed to that?", "order": 29515, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 3, "scene": 8, "line": "I can be very suprising.", "order": 29516, "speaker": "Michael Scott"},
  {"season": 6, "episode": 3, "scene": 9, "line": "What's going on?", "order": 29517, "speaker": "Phyllis Vance"},
  {"season": 6, "episode": 3, "scene": 9, "line": "I need to fit into my wedding dress. However, I'm also pregnant.", "order": 29518, "speaker": "Pam Beesly"},
  {"season": 6, "episode": 3, "scene": 9, "line": "You know, it's a myth that women have to gain more than nine pounds in a pregnancy. Look at these actresses. Some of them lose weight.", "order": 29519, "speaker": "Ryan Howard"},
  {"season": 6, "episode": 3, "scene": 9, "line": "Hey, did you guys register for wedding gifts?", "order": 29520, "speaker": "Phyllis Vance"},
  {"season": 6, "episode": 3, "scene": 9, "line": "We did, but mostly because people expect us to. We're also accepting other gifts not on the registry... However much.", "order": 29521, "speaker": "Pam Beesly"},
  {"season": 6, "episode": 3, "scene": 10, "line": "It's awkward asking people for money, but we could really use it. Why doesn't Crate & Barrel let you register for a toaster full of cash?", "order": 29522, "speaker": "Pam Beesly"},
  {"season": 6, "episode": 3, "scene": 11, "line": "Perfect. I hate registries.", "order": 29523, "speaker": "Phyllis Vance"},
  {"season": 6, "episode": 3, "scene": 11, "line": "Oh, good.", "order": 29524, "speaker": "Pam Beesly"},
  {"season": 6, "episode": 3, "scene": 11, "line": "My cousin makes the most amazing romantic birdhouse mailboxes. I know I shouldn't tell you, but you'll still be suprised when you see it. You're not registered for a birdhouse, are you?", "order": 29525, "speaker": "Phyllis Vance"},
  {"season": 6, "episode": 3, "scene": 11, "line": "No, we're not.", "order": 29526, "speaker": "Pam Beesly"},
  {"season": 6, "episode": 3, "scene": 11, "line": "Good. Good.", "order": 29527, "speaker": "Phyllis Vance"},
  {"season": 6, "episode": 3, "scene": 12, "line": "Mm-hmm?", "order": 29528, "speaker": "Michael Scott"},
  {"season": 6, "episode": 3, "scene": 12, "line": "[walks in, see's Oscar, Stanley, Creed and Kelly sitting in Michaels office] Okay, I thought I saw people missing.", "order": 29529, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 3, "scene": 12, "line": "Yeah, just focus-grouping a product expansion idea to these folks. Got one of each.", "order": 29530, "speaker": "Michael Scott"},
  {"season": 6, "episode": 3, "scene": 12, "line": "Really? Hey, Stanley, what was the last thing Michael said before I came through the door?", "order": 29531, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 3, "scene": 12, "line": "Stanley, you don't need to answer that.", "order": 29532, "speaker": "Michael Scott"},
  {"season": 6, "episode": 3, "scene": 12, "line": "Stanley?", "order": 29533, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 3, "scene": 12, "line": "If you don't smell this, you're fired.'", "order": 29534, "speaker": "Stanley Hudson"},
  {"season": 6, "episode": 3, "scene": 12, "line": "Okay, Michael, I thought we agreed to not take up people's time with meetings like these.", "order": 29535, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 3, "scene": 12, "line": "What do you mean by 'these people'?", "order": 29536, "speaker": "Michael Scott"},
  {"season": 6, "episode": 3, "scene": 12, "line": "Michael, this is a conference room meeting.", "order": 29537, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 3, "scene": 12, "line": "Oh, hey, everybody, I think that Jim has gone insane, because he thinks that my office is a conference room.", "order": 29538, "speaker": "Michael Scott"},
  {"season": 6, "episode": 3, "scene": 12, "line": "That's not what I meant.", "order": 29539, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 3, "scene": 12, "line": "Listen, Jim, no offense, but when push comes to shove, what I want is more important that what you want.", "order": 29540, "speaker": "Michael Scott"},
  {"season": 6, "episode": 3, "scene": 12, "line": "I disagree.", "order": 29541, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 3, "scene": 12, "line": "What do you guys think? [all start talking at the same time]", "order": 29542, "speaker": "Michael Scott"},
  {"season": 6, "episode": 3, "scene": 12, "line": "Great. Guys, can I just have a moment alone with Michael?", "order": 29543, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 3, "scene": 12, "line": "No, no, no, you don't need to go anywhere. Anything that Jim has to say to me, he can say in front of these types of people.", "order": 29544, "speaker": "Michael Scott"},
  {"season": 6, "episode": 3, "scene": 12, "line": "That is not what I said, and, also, I am co-manager, meaning you are not the only manager anymore. I am also I co-manager.", "order": 29545, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 3, "scene": 12, "line": "That is not what Wallace told us.", "order": 29546, "speaker": "Michael Scott"},
  {"season": 6, "episode": 3, "scene": 12, "line": "That's exactly what Wallace told us.", "order": 29547, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 3, "scene": 12, "line": "That is not the way I heard it.", "order": 29548, "speaker": "Michael Scott"},
  {"season": 6, "episode": 3, "scene": 13, "line": "I love rivalries-- Michael or Jim, Paris or Nicole, Heidi or L.C. It's so much fun. But I guess if I'm really thinking about it and answEring your question honestly, I'd have to go with L.C. Heidi's a bad friend, and her skin is terrible.", "order": 29549, "speaker": "Kelly Kapoor"},
  {"season": 6, "episode": 3, "scene": 14, "line": "It is one thing, me giving you the co-manager title. It is another thing, you bossing me around.", "order": 29550, "speaker": "Michael Scott"},
  {"season": 6, "episode": 3, "scene": 14, "line": "I'm not bossing you around. I'm trying to do my job, which is sharing your job, so maybe we should just call David Wallace.", "order": 29551, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 3, "scene": 14, "line": "No, no, no. No, we will not call David Wallace. [Michael pulls out his cell phone] I will call David Wallace.", "order": 29552, "speaker": "Michael Scott"},
  {"season": 6, "episode": 3, "scene": 14, "line": "David Wallace is on line two for you guys.", "order": 29553, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 3, "scene": 14, "line": "Or David Wallace will call us on line two. Why don't we just--", "order": 29554, "speaker": "Michael Scott"},
  {"season": 6, "episode": 3, "scene": 14, "line": "Hey, David. You got Jim here.", "order": 29555, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 3, "scene": 14, "line": "Hey David, this is Michael, senior co-manager.", "order": 29556, "speaker": "Michael Scott"},
  {"season": 6, "episode": 3, "scene": 14, "line": "Hey guys, um, I'm calling, because, unfortunately, we will not be able to give out cost of living raises this year, and I know people expect it. However, there just is only a small amount of money for each branch, so we're leaving it up to the managers to decide how to distribute it.", "order": 29557, "speaker": "David Wallace"},
  {"season": 6, "episode": 3, "scene": 14, "line": "Got it, Dave. Sounds like your classice big-picture decision.", "order": 29558, "speaker": "Michael Scott"},
  {"season": 6, "episode": 3, "scene": 14, "line": "Which will clearly affect the day-to-day well-being of our employees.", "order": 29559, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 3, "scene": 14, "line": "It's probably a little of both.", "order": 29560, "speaker": "David Wallace"},
  {"season": 6, "episode": 3, "scene": 14, "line": "Which is it more of?", "order": 29561, "speaker": "Michael Scott"},
  {"season": 6, "episode": 3, "scene": 14, "line": "Excuse me?", "order": 29562, "speaker": "David Wallace"},
  {"season": 6, "episode": 3, "scene": 14, "line": "David, I know that I'm very new at this, but I have a very good perspective on how things work.", "order": 29563, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 3, "scene": 14, "line": "Wait, I'm sorry. Michael, are you-- are you texting me?", "order": 29564, "speaker": "David Wallace"},
  {"season": 6, "episode": 3, "scene": 14, "line": "I thought maybe we could talk after this is over.", "order": 29565, "speaker": "Michael Scott"},
  {"season": 6, "episode": 3, "scene": 14, "line": "Look, I need you both to take care of this. This is not going to be a problem, is it?", "order": 29566, "speaker": "David Wallace"},
  {"season": 6, "episode": 3, "scene": 14, "line": "Nope, not at all.", "order": 29567, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 3, "scene": 14, "line": "No, it will not be. I am on top of it.", "order": 29568, "speaker": "Michael Scott"},
  {"season": 6, "episode": 3, "scene": 14, "line": "Very good.", "order": 29569, "speaker": "David Wallace"},
  {"season": 6, "episode": 3, "scene": 14, "line": "Alright, thanks alot, David.", "order": 29570, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 3, "scene": 14, "line": "Talk to you later-- [Jim hangs up] Okay, you didn't let me say good-bye. [sighs, and claps hands once] Alright, I am looking forward to this.", "order": 29571, "speaker": "Michael Scott"},
  {"season": 6, "episode": 3, "scene": 14, "line": "You don't have to lie.", "order": 29572, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 3, "scene": 14, "line": "I hate the fact that we have to do this together. I think we work better separately. When I am irritated and I look at you, I don't see you anymore.", "order": 29573, "speaker": "Michael Scott"},
  {"season": 6, "episode": 3, "scene": 14, "line": "Right.", "order": 29574, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 3, "scene": 14, "line": "All I can see is how big and gross the pores on your nose are.", "order": 29575, "speaker": "Michael Scott"},
  {"season": 6, "episode": 3, "scene": 14, "line": "Alright. Let's get started.", "order": 29576, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 3, "scene": 15, "line": "Hey, why haven't we ever, um...", "order": 29577, "speaker": "Creed Bratton"},
  {"season": 6, "episode": 3, "scene": 15, "line": "We have.", "order": 29578, "speaker": "Meredith Palmer"},
  {"season": 6, "episode": 3, "scene": 16, "line": "You know, there's no easy way to do this. I say we add 1.5% to their paychecks and don't say anything about it.", "order": 29579, "speaker": "Michael Scott"},
  {"season": 6, "episode": 3, "scene": 16, "line": "Do you mean, like break-in in the middle of the night and change the numbers on payroll?", "order": 29580, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 3, "scene": 16, "line": "No, we can do it during the day. It doesn't have to be that dramatic, Jim.", "order": 29581, "speaker": "Michael Scott"},
  {"season": 6, "episode": 3, "scene": 16, "line": "Okay. You know what would help is a pros and cons list for our options.", "order": 29582, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 3, "scene": 16, "line": "Jim, don't take this the wrong way. Are you gonna take this the wrong way?", "order": 29583, "speaker": "Michael Scott"},
  {"season": 6, "episode": 3, "scene": 16, "line": "It's hard to tell so far.", "order": 29584, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 3, "scene": 16, "line": "You use your brain too much.", "order": 29585, "speaker": "Michael Scott"},
  {"season": 6, "episode": 3, "scene": 16, "line": "I'm sorry, are you advocating that I use it less?", "order": 29586, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 3, "scene": 16, "line": "Sometimes the smartest people don't think at all.", "order": 29587, "speaker": "Michael Scott"},
  {"season": 6, "episode": 3, "scene": 16, "line": "You just came up with that.", "order": 29588, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 3, "scene": 16, "line": "As I was saying it.", "order": 29589, "speaker": "Michael Scott"},
  {"season": 6, "episode": 3, "scene": 16, "line": "Yeah. Okay, so for the 1.5% increase, pro-- everyone gets a little bit.", "order": 29590, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 3, "scene": 16, "line": "[in nerdy voice] Con-- you look like a nerd.", "order": 29591, "speaker": "Michael Scott"},
  {"season": 6, "episode": 3, "scene": 16, "line": "Con-- no one gets as much as they did last year.", "order": 29592, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 3, "scene": 16, "line": "Pro-- you get to share your pros and cons list with the other nerds.", "order": 29593, "speaker": "Michael Scott"},
  {"season": 6, "episode": 3, "scene": 16, "line": "Pro-- no favoritism.", "order": 29594, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 3, "scene": 16, "line": "Con-- you unzip your pants, and you find that there's a calculator down there.", "order": 29595, "speaker": "Michael Scott"},
  {"season": 6, "episode": 3, "scene": 17, "line": "I've been studying Michael for years, and I've condensed what I've learned into this chart-- [pulls out a pie chart] How Michael spends his time. As you can see, we have procrastinating and distracting others, and this tiny sliver here is critical thinking. I made it bigger, so that you could see it.", "order": 29596, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 3, "scene": 18, "line": "Hey, Pam, I heard that you were registered, but I didn't hear where.", "order": 29597, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 3, "scene": 18, "line": "I don't think we registered anywhere. We just want cash.", "order": 29598, "speaker": "Pam Beesly"},
  {"season": 6, "episode": 3, "scene": 18, "line": "Like money? Like you-- you want my money?", "order": 29599, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 3, "scene": 18, "line": "Mm-hmm.", "order": 29600, "speaker": "Pam Beesly"},
  {"season": 6, "episode": 3, "scene": 18, "line": "Will you take a check?", "order": 29601, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 3, "scene": 18, "line": "Yeah.", "order": 29602, "speaker": "Pam Beesly"},
  {"season": 6, "episode": 3, "scene": 18, "line": "Cool. In the memo line, I'm gonna write, 'To love's eternal glory.'", "order": 29603, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 3, "scene": 18, "line": "Okay.", "order": 29604, "speaker": "Pam Beesly"},
  {"season": 6, "episode": 3, "scene": 18, "line": "Wait til' Monday.", "order": 29605, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 3, "scene": 19, "line": "Is this what I've become? Materialistic? Shallow? I feel horrible. [looks down at the check] oh look! 'Mrs. Pam Halpert!' That's the first time I've seen it in writing!", "order": 29606, "speaker": "Pam Beesly"},
  {"season": 6, "episode": 3, "scene": 20, "line": "You know, the more I think about it, the more I think we should give it to the salesmen.", "order": 29607, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 3, "scene": 20, "line": "That will not go over well.", "order": 29608, "speaker": "Michael Scott"},
  {"season": 6, "episode": 3, "scene": 20, "line": "I know. I just think it seems smart to keep the salesmen happy right now. 'Course I know that this might come off as biased, so you know what? Let us...", "order": 29609, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 3, "scene": 20, "line": "Oh, my God! Are you gonna make another pro and con list? I'm gonna kill myself.", "order": 29610, "speaker": "Michael Scott"},
  {"season": 6, "episode": 3, "scene": 20, "line": "I'm not gonna let you stop me from thinking.", "order": 29611, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 3, "scene": 20, "line": "No! I could not do that, Jim. I can see it in your crusty little eyes that you are going to make everything perfect. If I can just think this through, if I could just think it exactly right, I can make this perfect, and then I can go down every avenue, and every avenue off of that avenue, and then another avenue.", "order": 29612, "speaker": "Michael Scott"},
  {"season": 6, "episode": 3, "scene": 20, "line": "Michael.", "order": 29613, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 3, "scene": 20, "line": "And then another--", "order": 29614, "speaker": "Michael Scott"},
  {"season": 6, "episode": 3, "scene": 20, "line": "Look, you have a lot of strengths.", "order": 29615, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 3, "scene": 20, "line": "Oh, I do?! Tell me what they are.", "order": 29616, "speaker": "Michael Scott"},
  {"season": 6, "episode": 3, "scene": 20, "line": "I think that you are able to take constructive criticism very well.", "order": 29617, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 3, "scene": 20, "line": "Ha! That-- I am not known for that!", "order": 29618, "speaker": "Michael Scott"},
  {"season": 6, "episode": 3, "scene": 20, "line": "But there is a reason why I'm here!", "order": 29619, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 3, "scene": 20, "line": "Yes! You went over my head to Wallace!", "order": 29620, "speaker": "Michael Scott"},
  {"season": 6, "episode": 3, "scene": 20, "line": "No, it's because you also have a lot of weaknesses, Michael!", "order": 29621, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 3, "scene": 20, "line": "Okay. Well, why don't you tell me what those are, Jim? Why don't you enliven me?", "order": 29622, "speaker": "Michael Scott"},
  {"season": 6, "episode": 3, "scene": 20, "line": "I don't think you're good at making tough decisions!", "order": 29623, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 3, "scene": 20, "line": "Ah.", "order": 29624, "speaker": "Michael Scott"},
  {"season": 6, "episode": 3, "scene": 20, "line": "At thinking out the options, and then stating that decision very clearly, especially when it's an unpopular decision.", "order": 29625, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 3, "scene": 20, "line": "Okay, here's a tough decision for you. You suck! You suck! Is that clear enough for you?!", "order": 29626, "speaker": "Michael Scott"},
  {"season": 6, "episode": 3, "scene": 20, "line": "But maybe I'm here for a reason, because I might have some good ideas, too. I've been sitting out there, and I've been learning a lot, and maybe I can just bring something different to the table.", "order": 29627, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 3, "scene": 20, "line": "Okay, Skippy, here we go. Why don't you run yourself out there and tell them? [laughs] You call it.", "order": 29628, "speaker": "Michael Scott"},
  {"season": 6, "episode": 3, "scene": 20, "line": "[leave the conference room to address everyone]", "order": 29629, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 3, "scene": 20, "line": "[to the camera] This had better be terrible.", "order": 29630, "speaker": "Michael Scott"},
  {"season": 6, "episode": 3, "scene": 20, "line": "Could I have everyone's attention, please? I have some tough news, so I'm just gonna give it to you straight. Truth is, we only have so much money for raises this year, so the simple fact is some people will get raises, and some people won't. Now, what we've decided is, we are gonna give those raises to the sales staff.", "order": 29631, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 3, "scene": 20, "line": "Wait. Seriously?!", "order": 29632, "speaker": "Meredith Palmer"},
  {"season": 6, "episode": 3, "scene": 20, "line": "Look, you guys are smart, so I'm just leveling with you. I'm just trying to treat you like adults.", "order": 29633, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 3, "scene": 20, "line": "Thank you, Jim, for thinking that we're smart people.", "order": 29634, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 3, "scene": 20, "line": "But, I do not understand. If we're such smart adults, then why don't we get raises?", "order": 29635, "speaker": "Angela Martin"},
  {"season": 6, "episode": 3, "scene": 20, "line": "Excellent question, genius. It's because Jim wants to give the raises to his friends and the people he sleeps with.", "order": 29636, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 3, "scene": 20, "line": "Ooh, easy. That's not it at all.", "order": 29637, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 3, "scene": 20, "line": "Well?", "order": 29638, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 3, "scene": 20, "line": "Really what it is, is about the money the sales staff brings into the office.", "order": 29639, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 3, "scene": 20, "line": "[walks in from shopping, with bags in her hands] What's going on?", "order": 29640, "speaker": "Kelly Kapoor"},
  {"season": 6, "episode": 3, "scene": 20, "line": "Jim is only giving raises to the salesmen.", "order": 29641, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 3, "scene": 20, "line": "What the hell?! Why are you being such a jerk?!", "order": 29642, "speaker": "Kelly Kapoor"},
  {"season": 6, "episode": 3, "scene": 20, "line": "Hold on, Kelly. Would it help you to know that Jim thinks you're smart?", "order": 29643, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 3, "scene": 20, "line": "No! [put's down her bags] How is that gonna repair Ryan's car?!", "order": 29644, "speaker": "Kelly Kapoor"},
  {"season": 6, "episode": 3, "scene": 20, "line": "This is ridiculous! How could he do this to us?!", "order": 29645, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 3, "scene": 20, "line": "Okay, Dwight, you know what? You'd be getting the raise so--", "order": 29646, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 3, "scene": 20, "line": "What about all these people, huh?! How is it fair to them?!", "order": 29647, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 3, "scene": 20, "line": "Exactly!", "order": 29648, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 3, "scene": 20, "line": "Totally!", "order": 29649, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 3, "scene": 20, "line": "We work just as hard as the sales people. We deserve raises.", "order": 29650, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 3, "scene": 20, "line": "Yes.", "order": 29651, "speaker": "Creed Bratton"},
  {"season": 6, "episode": 3, "scene": 20, "line": "Yes. They don't get 'em, if we don't get 'em!", "order": 29652, "speaker": "Angela Martin"},
  {"season": 6, "episode": 3, "scene": 20, "line": "My kid needs shoes! You want to tell him he doesn't get shoes?!", "order": 29653, "speaker": "Meredith Palmer"},
  {"season": 6, "episode": 3, "scene": 20, "line": "Yeah.", "order": 29654, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 3, "scene": 20, "line": "Her kid needs shoes, Jim!", "order": 29655, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 3, "scene": 20, "line": "What the hell?! [everyone complaining at Jim]", "order": 29656, "speaker": "Meredith Palmer"},
  {"season": 6, "episode": 3, "scene": 20, "line": "Wow! I'm just gonna rewind and back off a bit, so... [imitates a tape rewinding and chuckles, Michael comes over by Jim laughing]", "order": 29657, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 3, "scene": 20, "line": "That was good! That was good! Okay, all right. Well, we all know it's hard to be a boss, right? You know what? Look around you. These are your best friends. These are the people who will open their hearts to you. They all have heart-ons for you [someone growns in disgust], and that is a gift. So in terms of gifts, we should be giving each other gifts. Angela, yes, lend Oscar a cup of sugar. This--", "order": 29658, "speaker": "Michael Scott"},
  {"season": 6, "episode": 3, "scene": 20, "line": "What are you talking about Michael?", "order": 29659, "speaker": "Angela Martin"},
  {"season": 6, "episode": 3, "scene": 20, "line": "We just need to hear your plan for our raise.", "order": 29660, "speaker": "Stanley Hudson"},
  {"season": 6, "episode": 3, "scene": 20, "line": "My plan-- a man-- panama.", "order": 29661, "speaker": "Michael Scott"},
  {"season": 6, "episode": 3, "scene": 20, "line": "That's not how that goes.", "order": 29662, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 3, "scene": 20, "line": "You're not saying anything. At least Jim was being direct...", "order": 29663, "speaker": "Meredith Palmer"},
  {"season": 6, "episode": 3, "scene": 20, "line": "Thank you, Meredith.", "order": 29664, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 3, "scene": 20, "line": "...when he was telling us his dumbass plan.", "order": 29665, "speaker": "Meredith Palmer"},
  {"season": 6, "episode": 3, "scene": 20, "line": "We would just like to know what's happening. Are we getting a raise, yes or no?", "order": 29666, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 3, "scene": 20, "line": "Alright! Let me get this clear! Does everybody want a raise? [all say yes] Alright, everyone wants a raise, so what we're going to do is go into here, and we will not come out until we do.", "order": 29667, "speaker": "Michael Scott"},
  {"season": 6, "episode": 3, "scene": 20, "line": "Again, that gives us no information.", "order": 29668, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 3, "scene": 20, "line": "This isn't a game, you know? It's our livelihood!", "order": 29669, "speaker": "Phyllis Vance"},
  {"season": 6, "episode": 3, "scene": 21, "line": "What about a raise based on merit?", "order": 29670, "speaker": "Michael Scott"},
  {"season": 6, "episode": 3, "scene": 21, "line": "Rank each person individually?", "order": 29671, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 3, "scene": 21, "line": "Mm-hmm. Piece of cake. Ryan, obviously the best.", "order": 29672, "speaker": "Michael Scott"},
  {"season": 6, "episode": 3, "scene": 21, "line": "[sarcastically] Well, obviously. Too bad he's a temp and doesn't count, so let's get started. [starts to write on a piece of paper]", "order": 29673, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 3, "scene": 21, "line": "Are you making a--", "order": 29674, "speaker": "Michael Scott"},
  {"season": 6, "episode": 3, "scene": 21, "line": "Nope. I'm numbering the paper.", "order": 29675, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 3, "scene": 22, "line": "[everyone talking to the camera] Just--", "order": 29676, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 3, "scene": 22, "line": "Yeah, I don't understand how they can even consider giving money to some people and not the rest of us.", "order": 29677, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 3, "scene": 22, "line": "It's not like we're not gonna see the checks. We're in accounting!", "order": 29678, "speaker": "Angela Martin"},
  {"season": 6, "episode": 3, "scene": 22, "line": "I am so pissed at this company!", "order": 29679, "speaker": "Meredith Palmer"},
  {"season": 6, "episode": 3, "scene": 22, "line": "[out the side of his mouth] And Jim!", "order": 29680, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 3, "scene": 22, "line": "Yeah. Who said that?", "order": 29681, "speaker": "Meredith Palmer"},
  {"season": 6, "episode": 3, "scene": 22, "line": "I think it was Creed.", "order": 29682, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 3, "scene": 22, "line": "Yep.", "order": 29683, "speaker": "Creed Bratton"},
  {"season": 6, "episode": 3, "scene": 23, "line": "[to camera] It's going really well, actually.", "order": 29684, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 3, "scene": 23, "line": "[to camera, holds up a bean] Each Boston Baked Bean represents half a percent raise. We each got 24 beans, so the idea is, you place the bean on the picture [walking around the table] of who you think deserv-- who's that? [picture that Michael drew of toby, looks like a monster]", "order": 29685, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 3, "scene": 23, "line": "Toby.", "order": 29686, "speaker": "Michael Scott"},
  {"season": 6, "episode": 3, "scene": 23, "line": "He's not a part of this. You know that.", "order": 29687, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 3, "scene": 23, "line": "Just wanted to draw a picture of him.", "order": 29688, "speaker": "Michael Scott"},
  {"season": 6, "episode": 3, "scene": 23, "line": "Okay, your move.", "order": 29689, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 3, "scene": 23, "line": "Alright! Who to pick? [walking around the table] Who to pick?", "order": 29690, "speaker": "Michael Scott"},
  {"season": 6, "episode": 3, "scene": 23, "line": "Here we go.", "order": 29691, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 3, "scene": 23, "line": "I... will skip a turn.", "order": 29692, "speaker": "Michael Scott"},
  {"season": 6, "episode": 3, "scene": 23, "line": "Okay, you're gonna still have to play that bean. You know that.", "order": 29693, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 3, "scene": 23, "line": "I need more time.", "order": 29694, "speaker": "Michael Scott"},
  {"season": 6, "episode": 3, "scene": 23, "line": "Wallace sent an e-mail about a meeting next week. He wants you both to respond. [Michael and Jim leave, Dwight sneaks in]", "order": 29695, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 3, "scene": 24, "line": "People are starting to notice how terrible Jim is. It's great. Eventually, they'll rise up and revolt. My only hope is that they do it sooner rather than later. If the people here were our founding fathers, the Revolutionary War would have been delayed ten years, because Stanley Washington was napping, and Phyllis Hancock was still signing the declaration, and Kevin Jefferson was distracted by a butterfly.", "order": 29696, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 3, "scene": 25, "line": "Come on in. [everyone looking around at all the pictures and beans on the table] That's right. Come in, feast your eyes. They determine our worth by putting beans upon our faces.", "order": 29697, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 3, "scene": 25, "line": "What the hell?!", "order": 29698, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 3, "scene": 25, "line": "Things were not this bad when just Michael was manager.", "order": 29699, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 3, "scene": 25, "line": "Hey, what are you guys doing in here?", "order": 29700, "speaker": "Michael Scott"},
  {"season": 6, "episode": 3, "scene": 25, "line": "What does a bean mean?", "order": 29701, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 3, "scene": 25, "line": "Why aren't there any beans on this very old, frizzy-haired picture of me?", "order": 29702, "speaker": "Pam Beesly"},
  {"season": 6, "episode": 3, "scene": 25, "line": "Michael, what does a bean mean?", "order": 29703, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 3, "scene": 25, "line": "Jim?", "order": 29704, "speaker": "Pam Beesly"},
  {"season": 6, "episode": 3, "scene": 25, "line": "I was just trying to be unbiased.", "order": 29705, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 3, "scene": 25, "line": "What does a bean mean?!", "order": 29706, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 3, "scene": 25, "line": "Someone please explain it to Kevin.", "order": 29707, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 3, "scene": 25, "line": "Why can't you? My time is just as valuable as yours.", "order": 29708, "speaker": "Meredith Palmer"},
  {"season": 6, "episode": 3, "scene": 25, "line": "Not according to the beans.", "order": 29709, "speaker": "Phyllis Vance"},
  {"season": 6, "episode": 3, "scene": 25, "line": "This is how you make this important decision?", "order": 29710, "speaker": "Angela Martin"},
  {"season": 6, "episode": 3, "scene": 25, "line": "There's no way that Stanley gets more beans than me. [starts to take Stanley's beans, Stanley swats Andy's hand with his crossword puzzle] That is ridiculous. This is how it works. [everyone continues to argue]", "order": 29711, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 3, "scene": 26, "line": "It's not so much that I might not get a raise. It's just demeaning!", "order": 29712, "speaker": "Phyllis Vance"},
  {"season": 6, "episode": 3, "scene": 26, "line": "Thank you! That is exactly-- by the way, I would also like a raise.", "order": 29713, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 3, "scene": 26, "line": "Yeah, I would, too.", "order": 29714, "speaker": "Phyllis Vance"},
  {"season": 6, "episode": 3, "scene": 26, "line": "Are we idiots? [gets up, starts walking around] What right does Jim have to claim authority, hmm? Is he as good a salesman as I? Is he as matronly as Phyllis? There are moments where we can affect change. For a few seconds every decade, we exist, and these are those seconds! Let us storm his castle! Come on! Tick-- let's get him. Tock-- let's get Jim! Tick-- and drag Jim out of his office! Tock-- take his keys away from him! Tick-- that's a clock! The time is getting very close! It's now or never! What say you?!", "order": 29715, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 3, "scene": 26, "line": "I say no.", "order": 29716, "speaker": "Phyllis Vance"},
  {"season": 6, "episode": 3, "scene": 26, "line": "No, I mean, what do you say to my plan? [all groan] Okay.", "order": 29717, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 3, "scene": 26, "line": "[leaving his office, hurrying to Jim's office]", "order": 29718, "speaker": "Michael Scott"},
  {"season": 6, "episode": 3, "scene": 26, "line": "What does a bean mean?", "order": 29719, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 3, "scene": 26, "line": "[in Jim's office] How you doing?", "order": 29720, "speaker": "Michael Scott"},
  {"season": 6, "episode": 3, "scene": 26, "line": "Uh, on a scale of one to ten, I'd say I'm about a four.", "order": 29721, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 3, "scene": 26, "line": "Oh, what are you usually?", "order": 29722, "speaker": "Michael Scott"},
  {"season": 6, "episode": 3, "scene": 26, "line": "Six. You?", "order": 29723, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 3, "scene": 26, "line": "Usually a ten, but I'm feeling like a zero.", "order": 29724, "speaker": "Michael Scott"},
  {"season": 6, "episode": 3, "scene": 26, "line": "I mean, a performance-based raise sounded like a good idea, right?", "order": 29725, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 3, "scene": 26, "line": "I know, I know.", "order": 29726, "speaker": "Michael Scott"},
  {"season": 6, "episode": 3, "scene": 26, "line": "We tried to find the fairest way to do it.", "order": 29727, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 3, "scene": 26, "line": "Yes, we did.", "order": 29728, "speaker": "Michael Scott"},
  {"season": 6, "episode": 3, "scene": 26, "line": "I just-- what?", "order": 29729, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 3, "scene": 26, "line": "Um, nothing. I just... I used to have to do this part alone, and it was the worst. I have something I would like to give you. [leaves office]", "order": 29730, "speaker": "Michael Scott"},
  {"season": 6, "episode": 3, "scene": 26, "line": "Michael!", "order": 29731, "speaker": "Phyllis Vance"},
  {"season": 6, "episode": 3, "scene": 26, "line": "Michael!", "order": 29732, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 3, "scene": 26, "line": "Come on! This isn't fair!", "order": 29733, "speaker": "Phyllis Vance"},
  {"season": 6, "episode": 3, "scene": 26, "line": "[to camera] Michael's my only friend left in the office. Except Pam... I think. Is she still upset?", "order": 29734, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 3, "scene": 26, "line": "[at Michael return to Jim's office] Michael, you cannot just keep avoiding us like this!", "order": 29735, "speaker": "Phyllis Vance"},
  {"season": 6, "episode": 3, "scene": 26, "line": "[Michael give Jim a 'World's Best Boss' mug] Thank you.", "order": 29736, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 3, "scene": 26, "line": "You're welcome.", "order": 29737, "speaker": "Michael Scott"},
  {"season": 6, "episode": 3, "scene": 26, "line": "What's in here?", "order": 29738, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 3, "scene": 26, "line": "Gin. [toast, sip]", "order": 29739, "speaker": "Michael Scott"},
  {"season": 6, "episode": 3, "scene": 26, "line": "Ooh!", "order": 29740, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 3, "scene": 26, "line": "[someone knocks] Just pretend we're not here.", "order": 29741, "speaker": "Michael Scott"},
  {"season": 6, "episode": 3, "scene": 27, "line": "Heard you guys are looking for cash for the wedding?", "order": 29742, "speaker": "Ryan Howard"},
  {"season": 6, "episode": 3, "scene": 27, "line": "Yeah, I mean, if it's all the same for you.", "order": 29743, "speaker": "Pam Beesly"},
  {"season": 6, "episode": 3, "scene": 27, "line": "Question for you. Would you guys rather have $100 now, or $5,000 a year from now?", "order": 29744, "speaker": "Ryan Howard"},
  {"season": 6, "episode": 3, "scene": 27, "line": "$100 now for sure.", "order": 29745, "speaker": "Pam Beesly"},
  {"season": 6, "episode": 3, "scene": 27, "line": "Because you just give me $50 to cover the broker fee, I put in a hundred of my own money as the gift...", "order": 29746, "speaker": "Ryan Howard"},
  {"season": 6, "episode": 3, "scene": 27, "line": "Yeah. No, no, I'll um-- the hundred. I'll just take the hundred.", "order": 29747, "speaker": "Pam Beesly"},
  {"season": 6, "episode": 3, "scene": 27, "line": "Instead of $5,000 a year from now?", "order": 29748, "speaker": "Ryan Howard"},
  {"season": 6, "episode": 3, "scene": 27, "line": "How sure is this?", "order": 29749, "speaker": "Pam Beesly"},
  {"season": 6, "episode": 3, "scene": 28, "line": "The guy has an algorithm to determine the winner of any given college basketball game.", "order": 29750, "speaker": "Pam Beesly"},
  {"season": 6, "episode": 4, "scene": 1, "line": "I'm sorry. You guys have probably noticed my stomach's a little more sensitive lately. So, if you wouldn't mind wearing a little less perfume... and if your lunch is especially pungent, maybe have it in the break room?", "order": 29751, "speaker": "Pam Beesly"},
  {"season": 6, "episode": 4, "scene": 1, "line": "We would really appreciate it.", "order": 29752, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 4, "scene": 2, "line": "Pam is constantly throwing up because of the pregnancy. If she eats something the fetus doesn't like she is screwed. It's amazing. A three ounce fetus is calling the shots. It's so bad ass.", "order": 29753, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 4, "scene": 3, "line": "Phyllis, if you could switch to a different soap, just for a month or two? Yours is kind of perfumy.", "order": 29754, "speaker": "Pam Beesly"},
  {"season": 6, "episode": 4, "scene": 3, "line": "Now this is getting ridiculous.", "order": 29755, "speaker": "Phyllis Vance"},
  {"season": 6, "episode": 4, "scene": 3, "line": "Oh, gee, I'm sorry. I guess I missed the meeting where we all all voted for you to get pregnant. No! I reserve the right to peel my hard-boiled eggs at my desk.", "order": 29756, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 4, "scene": 3, "line": "All morning I look forward to my afternoon cigars and I'm not stopping for anyone.", "order": 29757, "speaker": "Meredith Palmer"},
  {"season": 6, "episode": 4, "scene": 4, "line": "I don't think I'm asking for too much. I guess it's just the end of courtesy in the workplace.", "order": 29758, "speaker": "Pam Beesly"},
  {"season": 6, "episode": 4, "scene": 5, "line": "[Dwight peels a hard-boiled egg at his desk, sniffs it and takes a bite; Pam stares at him, picks up her trash can and pukes into it] Watching people get sick always makes me sick. And... frankly so does talking about it. So... wow... [Andy, Phyllis, Meredith, Erin, Oscar and Angela all proceed to throw up in various places while Creed looks on eating a bowl of noodles; Dwight stares shocked; Pam wipes her mouth with a satisfied look]", "order": 29759, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 4, "scene": 6, "line": "[standing next to a car decorated for leaving a wedding] What do you think? I spent all morning on it.", "order": 29760, "speaker": "Michael Scott"},
  {"season": 6, "episode": 4, "scene": 6, "line": "It is really special.", "order": 29761, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 4, "scene": 6, "line": "Yeah, but aren't you supposed to do that to our... no. It's great.", "order": 29762, "speaker": "Pam Beesly"},
  {"season": 6, "episode": 4, "scene": 6, "line": "It's just a really important day for me.", "order": 29763, "speaker": "Michael Scott"},
  {"season": 6, "episode": 4, "scene": 6, "line": "Well, congrats.", "order": 29764, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 4, "scene": 6, "line": "Thanks.", "order": 29765, "speaker": "Michael Scott"},
  {"season": 6, "episode": 4, "scene": 7, "line": "Dunder Mifflin Scranton will be closed today and Friday for a company wedding in Niagara Falls. So, please leave a message and we'll get back to you as soon as possible. Have a great day.", "order": 29766, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 4, "scene": 8, "line": "Believe it or not, Kevin, fire crackers are in the don't column.", "order": 29767, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 4, "scene": 8, "line": "So... you're going to provide them, then?", "order": 29768, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 4, "scene": 8, "line": "No. This is a fire cracker free wedding.", "order": 29769, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 4, "scene": 8, "line": "What the hell?", "order": 29770, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 4, "scene": 8, "line": "Come on. You've got to be kidding me.", "order": 29771, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 4, "scene": 8, "line": "Ok. All of these things are important to remember but the most important thing is no one say anything about my pregnancy at the wedding.", "order": 29772, "speaker": "Pam Beesly"},
  {"season": 6, "episode": 4, "scene": 8, "line": "Absolutely. 'Cause not everyone knows and some people may be offended.", "order": 29773, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 4, "scene": 8, "line": "Decent people everywhere will get offended.", "order": 29774, "speaker": "Angela Martin"},
  {"season": 6, "episode": 4, "scene": 8, "line": "Well, we're thinking of my grandmother who we haven't told and is very old-fashioned.", "order": 29775, "speaker": "Pam Beesly"},
  {"season": 6, "episode": 4, "scene": 8, "line": "Well, you're lucky you have a grandmother. Some of us have to be our own grandmothers.", "order": 29776, "speaker": "Angela Martin"},
  {"season": 6, "episode": 4, "scene": 8, "line": "That's nice.", "order": 29777, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 4, "scene": 8, "line": "You know, Angela, you don't have to come to the wedding.", "order": 29778, "speaker": "Pam Beesly"},
  {"season": 6, "episode": 4, "scene": 8, "line": "Really, Pam...", "order": 29779, "speaker": "Angela Martin"},
  {"season": 6, "episode": 4, "scene": 8, "line": "Yes she does. Yes she does. We're all gonna go and we're gonna have a good time. [hugs Angela]", "order": 29780, "speaker": "Michael Scott"},
  {"season": 6, "episode": 4, "scene": 8, "line": "Ow! He pinched me!", "order": 29781, "speaker": "Angela Martin"},
  {"season": 6, "episode": 4, "scene": 8, "line": "No.", "order": 29782, "speaker": "Michael Scott"},
  {"season": 6, "episode": 4, "scene": 8, "line": "Next time we're all in this room Jim and I will be married.", "order": 29783, "speaker": "Pam Beesly"},
  {"season": 6, "episode": 4, "scene": 8, "line": "We'll see.", "order": 29784, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 4, "scene": 8, "line": "Thank you, Dwight.", "order": 29785, "speaker": "Pam Beesly"},
  {"season": 6, "episode": 4, "scene": 8, "line": "Good-bye.", "order": 29786, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 4, "scene": 8, "line": "See you later. [others say good-bye]", "order": 29787, "speaker": "Pam Beesly"},
  {"season": 6, "episode": 4, "scene": 8, "line": "And, hey, don't embarrass me when we go to Niagara.", "order": 29788, "speaker": "Michael Scott"},
  {"season": 6, "episode": 4, "scene": 8, "line": "What happens in Niagara, stays in Niagara. [laughter]", "order": 29789, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 4, "scene": 8, "line": "Don't. Don't. Don't. You stole my joke. Don't steal my joke.", "order": 29790, "speaker": "Michael Scott"},
  {"season": 6, "episode": 4, "scene": 8, "line": "No... I didn't steal your joke.", "order": 29791, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 4, "scene": 8, "line": "Yes. I said that yesterday.", "order": 29792, "speaker": "Michael Scott"},
  {"season": 6, "episode": 4, "scene": 8, "line": "But you can say that about anything.", "order": 29793, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 4, "scene": 8, "line": "Dwight...", "order": 29794, "speaker": "Michael Scott"},
  {"season": 6, "episode": 4, "scene": 8, "line": "What happens in accounting, stays in accounting.", "order": 29795, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 4, "scene": 8, "line": "Oh yeah.", "order": 29796, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 4, "scene": 8, "line": "No. No. No. Please, please. Dwight, that's my joke.", "order": 29797, "speaker": "Michael Scott"},
  {"season": 6, "episode": 4, "scene": 8, "line": "It's easy. That's what I'm saying.", "order": 29798, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 4, "scene": 8, "line": "This is what I'm talking about. When we leave here and go up to Niagara Falls we are representing Dunder Mifflin, everyone. This is a very important wedding for the branch. The most important wedding until I get married. So, I want you all on your best behavior or so help me, God. So... I will see you up there in Viagra Falls!", "order": 29799, "speaker": "Michael Scott"},
  {"season": 6, "episode": 4, "scene": 9, "line": "Pam and Jim's wedding will be the single best pick up destination in the history of the universe.", "order": 29800, "speaker": "Michael Scott"},
  {"season": 6, "episode": 4, "scene": 10, "line": "I stole the guest list from Jim's desk and I search engined every female on both sides of the family.", "order": 29801, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 4, "scene": 10, "line": "Get out of here.", "order": 29802, "speaker": "Michael Scott"},
  {"season": 6, "episode": 4, "scene": 10, "line": "Yes.", "order": 29803, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 4, "scene": 10, "line": "Oh, my God, Dwight.", "order": 29804, "speaker": "Michael Scott"},
  {"season": 6, "episode": 4, "scene": 10, "line": "For instance, Pam's cousin, Jocelyn Webster.", "order": 29805, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 4, "scene": 10, "line": "There's... a name.", "order": 29806, "speaker": "Michael Scott"},
  {"season": 6, "episode": 4, "scene": 10, "line": "Two years ago she was selling a mountain bike.", "order": 29807, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 4, "scene": 10, "line": "Oh. Well, tell me about Jocelyn.", "order": 29808, "speaker": "Michael Scott"},
  {"season": 6, "episode": 4, "scene": 10, "line": "Well... she was really into mountain biking but not so much lately.", "order": 29809, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 4, "scene": 10, "line": "Ok.", "order": 29810, "speaker": "Michael Scott"},
  {"season": 6, "episode": 4, "scene": 10, "line": "She had a couple hundred dollars to spend, I mean, if she was able to sell her bike.", "order": 29811, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 4, "scene": 10, "line": "Is that all you have on her?", "order": 29812, "speaker": "Michael Scott"},
  {"season": 6, "episode": 4, "scene": 10, "line": "Well, if this is in fact her because it is a very common name.", "order": 29813, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 4, "scene": 10, "line": "You're an idiot.", "order": 29814, "speaker": "Michael Scott"},
  {"season": 6, "episode": 4, "scene": 11, "line": "People don't think of me as one of the sharper dressers in the office... but I'm going ot turn that around at this wedding. I thought, how could I take it to the next level? The hair. [Angela beeps her car horn.] It's the hair-- [beeps again.] Ok. Ok. I'm going. [long beep] God.", "order": 29815, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 4, "scene": 12, "line": "[in Andy's car, Andy driving] Hey, do one of you guys want to sit up front with me so I have someone to talk to? It's like a five hour drive-ish, you know.", "order": 29816, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 4, "scene": 12, "line": "This is so much cooler. We feel like wer're in a limo and you're our driver.", "order": 29817, "speaker": "Kelly Kapoor"},
  {"season": 6, "episode": 4, "scene": 12, "line": "Mmm-hmm. Erin?", "order": 29818, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 4, "scene": 12, "line": "Oh no. That wouldn't be fair to leave Kelly alone in back.", "order": 29819, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 4, "scene": 13, "line": "Not only is Erin really sweet and cute... she smell's like my mom.", "order": 29820, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 4, "scene": 14, "line": "[in car] Hey, my aunt told me something neat.", "order": 29821, "speaker": "Pam Beesly"},
  {"season": 6, "episode": 4, "scene": 14, "line": "Yeah?", "order": 29822, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 4, "scene": 14, "line": "She said everything with the wedding goes by so fast we should try to take mental pictures of the high points.", "order": 29823, "speaker": "Pam Beesly"},
  {"season": 6, "episode": 4, "scene": 14, "line": "Oh, wow. That's cool.", "order": 29824, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 4, "scene": 14, "line": "Yeah.", "order": 29825, "speaker": "Pam Beesly"},
  {"season": 6, "episode": 4, "scene": 14, "line": "[aims imaginary camera at Pam] Click. Oh, you blinked. Damn it. Now that's in my brain forever.", "order": 29826, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 4, "scene": 14, "line": "Oh.", "order": 29827, "speaker": "Pam Beesly"},
  {"season": 6, "episode": 4, "scene": 14, "line": "Lousy Picture.", "order": 29828, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 4, "scene": 14, "line": "We should have hired a professional to take our mental pictures.", "order": 29829, "speaker": "Pam Beesly"},
  {"season": 6, "episode": 4, "scene": 15, "line": "[in car, Michael driving] Oh! God! Wow! Oh. I was asleep.", "order": 29830, "speaker": "Michael Scott"},
  {"season": 6, "episode": 4, "scene": 15, "line": "What? No way!", "order": 29831, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 4, "scene": 15, "line": "Those glasses are super dark.", "order": 29832, "speaker": "Michael Scott"},
  {"season": 6, "episode": 4, "scene": 15, "line": "Oh. God.", "order": 29833, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 4, "scene": 15, "line": "Alright. We need some tunes, I think.", "order": 29834, "speaker": "Michael Scott"},
  {"season": 6, "episode": 4, "scene": 15, "line": "You know what? I made you a cd...", "order": 29835, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 4, "scene": 15, "line": "You did?", "order": 29836, "speaker": "Michael Scott"},
  {"season": 6, "episode": 4, "scene": 15, "line": "Mmm-hmm.", "order": 29837, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 4, "scene": 15, "line": "That was nice of you.", "order": 29838, "speaker": "Michael Scott"},
  {"season": 6, "episode": 4, "scene": 15, "line": "This is to play when you bring a woman back to your hotel room.", "order": 29839, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 4, "scene": 15, "line": "Oh, very thoughtful. A little mix to set the mood. Delightful. Pop that in.", "order": 29840, "speaker": "Michael Scott"},
  {"season": 6, "episode": 4, "scene": 15, "line": "You're gonna like this. [Dwight's voice on the cd] 'Hello. This is Dwight Schrute. If you are listening to this you are a lucky woman Michael has seduced. Ah to be in your shoes. 'What's next?', you're probably wondering. Don't be scared of you're night in heaven--' [Michael turns off cd]", "order": 29841, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 4, "scene": 15, "line": "Are you serious? You want me to play that for a woman coming to my room?", "order": 29842, "speaker": "Michael Scott"},
  {"season": 6, "episode": 4, "scene": 15, "line": "Yeah. It's practical.", "order": 29843, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 4, "scene": 15, "line": "No. No. That's not how it works.", "order": 29844, "speaker": "Michael Scott"},
  {"season": 6, "episode": 4, "scene": 16, "line": "Niagara Falls used to be, like, a spiritual experience for people. They stayed in tents and it blew their minds. It's really kitchy now, which is a lot of fun.", "order": 29845, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 4, "scene": 17, "line": "Halpert...", "order": 29846, "speaker": "Front Desk Clerk"},
  {"season": 6, "episode": 4, "scene": 17, "line": "And Beesly. Tonight we're in two seperate rooms and tomorrow night is the honeymoon suite.", "order": 29847, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 4, "scene": 17, "line": "Great.", "order": 29848, "speaker": "Front Desk Clerk"},
  {"season": 6, "episode": 4, "scene": 17, "line": "I know. We're pretty excited, too.", "order": 29849, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 4, "scene": 17, "line": "Can we take a look at the suite now?", "order": 29850, "speaker": "Pam Beesly"},
  {"season": 6, "episode": 4, "scene": 17, "line": "Oh, I'm sorry. Somebody just checked in.", "order": 29851, "speaker": "Front Desk Clerk"},
  {"season": 6, "episode": 4, "scene": 17, "line": "Oh... is there another wedding at the hotel this weekend?", "order": 29852, "speaker": "Pam Beesly"},
  {"season": 6, "episode": 4, "scene": 17, "line": "Oh, no. Just an individual. That man over there.", "order": 29853, "speaker": "Front Desk Clerk"},
  {"season": 6, "episode": 4, "scene": 17, "line": "Hey. I got the room the night before you guys. I'll break in the bed. [laughs]", "order": 29854, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 4, "scene": 17, "line": "I don't like that.", "order": 29855, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 4, "scene": 17, "line": "I'm gonna need the name and cell phone number of the housekeeper responsible for changing the sheets, please.", "order": 29856, "speaker": "Pam Beesly"},
  {"season": 6, "episode": 4, "scene": 18, "line": "Hello. Reservation for Michael Scott.", "order": 29857, "speaker": "Michael Scott"},
  {"season": 6, "episode": 4, "scene": 18, "line": "One moment while I check.", "order": 29858, "speaker": "Front Desk Clerk"},
  {"season": 6, "episode": 4, "scene": 18, "line": "The proximity to the falls makes everything smell like a basement.", "order": 29859, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 4, "scene": 18, "line": "Mmm-hmm.", "order": 29860, "speaker": "Michael Scott"},
  {"season": 6, "episode": 4, "scene": 18, "line": "I'm sorry, sir, but I'm not seeing you in here. When did you make your reservation?", "order": 29861, "speaker": "Front Desk Clerk"},
  {"season": 6, "episode": 4, "scene": 18, "line": "I don't have a reservation but I want a room in the Halpert-Beesly block of rooms.", "order": 29862, "speaker": "Michael Scott"},
  {"season": 6, "episode": 4, "scene": 18, "line": "Oh, ok. Unfortunately, sir, the block only applies to the rate. I'm afraid we're all sold out.", "order": 29863, "speaker": "Front Desk Clerk"},
  {"season": 6, "episode": 4, "scene": 18, "line": "Dwight Schrute. I have a reservation. Confirmation number: Romeo. Tango. G7745.", "order": 29864, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 4, "scene": 18, "line": "Yes. Schrute.", "order": 29865, "speaker": "Front Desk Clerk"},
  {"season": 6, "episode": 4, "scene": 18, "line": "And I had spoken to Teresa about a room with two safes?", "order": 29866, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 4, "scene": 18, "line": "Sure. No problem.", "order": 29867, "speaker": "Front Desk Clerk"},
  {"season": 6, "episode": 4, "scene": 18, "line": "Ok.", "order": 29868, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 4, "scene": 18, "line": "Here's your key, Mr. Schrute.", "order": 29869, "speaker": "Front Desk Clerk"},
  {"season": 6, "episode": 4, "scene": 18, "line": "Dwight. Dwight, Dwight. I need to stay in your room.", "order": 29870, "speaker": "Michael Scott"},
  {"season": 6, "episode": 4, "scene": 18, "line": "No way. What if I meet someone?", "order": 29871, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 4, "scene": 18, "line": "I'm staying in your-- Come on, Dwight.", "order": 29872, "speaker": "Michael Scott"},
  {"season": 6, "episode": 4, "scene": 18, "line": "No. No.", "order": 29873, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 4, "scene": 18, "line": "I would do the same for you.", "order": 29874, "speaker": "Michael Scott"},
  {"season": 6, "episode": 4, "scene": 18, "line": "You would?", "order": 29875, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 4, "scene": 18, "line": "Yes.", "order": 29876, "speaker": "Michael Scott"},
  {"season": 6, "episode": 4, "scene": 18, "line": "Really?", "order": 29877, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 4, "scene": 18, "line": "Yes... just go--", "order": 29878, "speaker": "Michael Scott"},
  {"season": 6, "episode": 4, "scene": 18, "line": "Wait a second. Oh. No, no, no. This must be some kind of mistake. This reservation seems to be under an M. Scott. This must be yours.", "order": 29879, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 4, "scene": 18, "line": "Oh. Thank God.", "order": 29880, "speaker": "Michael Scott"},
  {"season": 6, "episode": 4, "scene": 18, "line": "Oh no. Now that I don't have a room, can I stay with you?", "order": 29881, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 4, "scene": 18, "line": "Um... no. And you know what? I would say yes but you can't. And I'll tell you why.", "order": 29882, "speaker": "Michael Scott"},
  {"season": 6, "episode": 4, "scene": 18, "line": "Please?", "order": 29883, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 4, "scene": 18, "line": "If I have a woman up there and things are getting amorous, she's going to get turned off if she looks in he bathroom and sees you brushing your teeth with butter and clay.", "order": 29884, "speaker": "Michael Scott"},
  {"season": 6, "episode": 4, "scene": 18, "line": "Mmm-hmm.", "order": 29885, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 4, "scene": 18, "line": "Ok.", "order": 29886, "speaker": "Michael Scott"},
  {"season": 6, "episode": 4, "scene": 18, "line": "Ok. Ok. A-ha-ha! [rips reservation out of Michael's hand] That was a test. You have failed. For this is my room. You would not share with me.", "order": 29887, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 4, "scene": 18, "line": "I don't have a room?", "order": 29888, "speaker": "Michael Scott"},
  {"season": 6, "episode": 4, "scene": 18, "line": "No you do not.", "order": 29889, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 4, "scene": 18, "line": "Ok. 'Oooh. You must pass the dungeon wisdom test.'", "order": 29890, "speaker": "Michael Scott"},
  {"season": 6, "episode": 4, "scene": 18, "line": "It worked.", "order": 29891, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 4, "scene": 18, "line": "Jerk.", "order": 29892, "speaker": "Michael Scott"},
  {"season": 6, "episode": 4, "scene": 19, "line": "When Mary was denied a room at the inn... Jesus was born. When Michael was denied a room at the inn, we don't know what happens because that story hasn't been told yet.", "order": 29893, "speaker": "Michael Scott"},
  {"season": 6, "episode": 4, "scene": 20, "line": "Mr. and Mrs. Stanley Hudson.", "order": 29894, "speaker": "Stanley Hudson"},
  {"season": 6, "episode": 4, "scene": 20, "line": "Hey. Stanley? Can I stay in your room tonight?", "order": 29895, "speaker": "Michael Scott"},
  {"season": 6, "episode": 4, "scene": 20, "line": "Are you crazy? I brought Cynthia with me.", "order": 29896, "speaker": "Stanley Hudson"},
  {"season": 6, "episode": 4, "scene": 20, "line": "Not in the same bed. In the other bed.", "order": 29897, "speaker": "Michael Scott"},
  {"season": 6, "episode": 4, "scene": 20, "line": "I got one queen size bed.", "order": 29898, "speaker": "Stanley Hudson"},
  {"season": 6, "episode": 4, "scene": 20, "line": "You... are... kidding me.", "order": 29899, "speaker": "Michael Scott"},
  {"season": 6, "episode": 4, "scene": 20, "line": "A queen size bed is five feet wide. I am not five feet wide, Michael.", "order": 29900, "speaker": "Stanley Hudson"},
  {"season": 6, "episode": 4, "scene": 20, "line": "I'm not a physics major, Stanley. I'm just saying be careful. Hey, guys. Hey. Could I stay in your room tonight?", "order": 29901, "speaker": "Michael Scott"},
  {"season": 6, "episode": 4, "scene": 20, "line": "Oh. Gross.", "order": 29902, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 4, "scene": 20, "line": "Blow my brains out.", "order": 29903, "speaker": "Kelly Kapoor"},
  {"season": 6, "episode": 4, "scene": 20, "line": "That's rude.", "order": 29904, "speaker": "Michael Scott"},
  {"season": 6, "episode": 4, "scene": 20, "line": "Michael, I have one extra twin bed, if you want.", "order": 29905, "speaker": "Toby Flenderson"},
  {"season": 6, "episode": 4, "scene": 20, "line": "You are going to be slepping by yourself for the rest of your life, so you just get used to it.", "order": 29906, "speaker": "Michael Scott"},
  {"season": 6, "episode": 4, "scene": 21, "line": "So, which one is Pam's grandma?", "order": 29907, "speaker": "Mr. Halpert"},
  {"season": 6, "episode": 4, "scene": 21, "line": "Oh, Mema? She's the one in the teal suit. She's the only 80 year old with no smile wrinkles.", "order": 29908, "speaker": "Pam Beesly"},
  {"season": 6, "episode": 4, "scene": 21, "line": "Dad, remember, no mention of the baby, right?", "order": 29909, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 4, "scene": 21, "line": "No.", "order": 29910, "speaker": "Mr. Halpert"},
  {"season": 6, "episode": 4, "scene": 21, "line": "Yeah, yeah, yeah. She's very conservative. So, mum's the word.", "order": 29911, "speaker": "Michael Scott"},
  {"season": 6, "episode": 4, "scene": 21, "line": "Mom, Dad. This is Michael Scott.", "order": 29912, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 4, "scene": 21, "line": "Oh.", "order": 29913, "speaker": "Mr. Halpert"},
  {"season": 6, "episode": 4, "scene": 21, "line": "How ya doin'?", "order": 29914, "speaker": "Michael Scott"},
  {"season": 6, "episode": 4, "scene": 21, "line": "Hi.", "order": 29915, "speaker": "Mr. Halpert"},
  {"season": 6, "episode": 4, "scene": 21, "line": "Nice to meet you.", "order": 29916, "speaker": "Michael Scott"},
  {"season": 6, "episode": 4, "scene": 21, "line": "Nice to meet you.", "order": 29917, "speaker": "Mr. Halpert"},
  {"season": 6, "episode": 4, "scene": 21, "line": "Who's doing a toast? I would like to go third. Sort of bat clean up and--", "order": 29918, "speaker": "Michael Scott"},
  {"season": 6, "episode": 4, "scene": 21, "line": "Michael, I thought we discussed that we would rather you not speak, like, at all. Because it's just going to be blood relatives, I think.", "order": 29919, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 4, "scene": 21, "line": "[talking quietly, trying not to move his lips]That is seriously going to impeed my ability to hook up with your female relatives.", "order": 29920, "speaker": "Michael Scott"},
  {"season": 6, "episode": 4, "scene": 21, "line": "Pretty sure everyone heard that.", "order": 29921, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 4, "scene": 21, "line": "Didn't move my lips.", "order": 29922, "speaker": "Michael Scott"},
  {"season": 6, "episode": 4, "scene": 22, "line": "Hey, Mema.", "order": 29923, "speaker": "Pam Beesly"},
  {"season": 6, "episode": 4, "scene": 22, "line": "I wasn't sure about your branch of the family. After I heard about your parent's divorce. But you and Jim are just perfect. God bless you.", "order": 29924, "speaker": "Mema"},
  {"season": 6, "episode": 4, "scene": 22, "line": "Oh. Thank you... but nobody's perfect.", "order": 29925, "speaker": "Pam Beesly"},
  {"season": 6, "episode": 4, "scene": 22, "line": "Well... I wouldn't care to live if I thought that.", "order": 29926, "speaker": "Mema"},
  {"season": 6, "episode": 4, "scene": 23, "line": "Mr. Beesly. How are ya?", "order": 29927, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 4, "scene": 23, "line": "I'd like you to meet Christy Kelly.", "order": 29928, "speaker": "Mr. Beesly"},
  {"season": 6, "episode": 4, "scene": 23, "line": "Oh. Is this, uh, is this your niece?", "order": 29929, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 4, "scene": 23, "line": "[laughs] No no. She's my girlfriend. Hey? Can you make room at the head table?", "order": 29930, "speaker": "Mr. Beesly"},
  {"season": 6, "episode": 4, "scene": 24, "line": "From quarter three to quarter four up 17 percent, while his sales down two percent. It's all in the report.", "order": 29931, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 4, "scene": 24, "line": "Why would they make the bad man a boss if you're so much smarter?", "order": 29932, "speaker": "Little Girl"},
  {"season": 6, "episode": 4, "scene": 24, "line": "Excellent question. Because while I was busy trying to improve the company and make it a success, Jim... the bad man was busy kissing the boss man's butt.", "order": 29933, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 4, "scene": 24, "line": "Ewwww!", "order": 29934, "speaker": "Kids"},
  {"season": 6, "episode": 4, "scene": 24, "line": "That's right. It is ew. It is very ew.", "order": 29935, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 4, "scene": 25, "line": "Oscar, Kevin, this is my sister, Penny. She's also my maid of honor.", "order": 29936, "speaker": "Pam Beesly"},
  {"season": 6, "episode": 4, "scene": 25, "line": "Pleased to meet you.", "order": 29937, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 4, "scene": 25, "line": "I'm sorry, it's Kevin. I thought it was Gil?", "order": 29938, "speaker": "Penny"},
  {"season": 6, "episode": 4, "scene": 25, "line": "She thought I was your boyfriend.", "order": 29939, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 4, "scene": 25, "line": "You thought I was dating this? What the hell is wrong with you?", "order": 29940, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 4, "scene": 25, "line": "Oscar, it was an honest mistake.", "order": 29941, "speaker": "Pam Beesly"},
  {"season": 6, "episode": 4, "scene": 25, "line": "Him? Him?", "order": 29942, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 4, "scene": 25, "line": "Oscar, I would be proud to date you.", "order": 29943, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 4, "scene": 25, "line": "I'm sorry.", "order": 29944, "speaker": "Penny"},
  {"season": 6, "episode": 4, "scene": 25, "line": "I'm not gay. I'm Kevin.", "order": 29945, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 4, "scene": 25, "line": "Nice to meet you, Kevin.", "order": 29946, "speaker": "Penny"},
  {"season": 6, "episode": 4, "scene": 25, "line": "Yes.", "order": 29947, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 4, "scene": 25, "line": "You owe me and apology.", "order": 29948, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 4, "scene": 25, "line": "I'm so sorry.", "order": 29949, "speaker": "Penny"},
  {"season": 6, "episode": 4, "scene": 25, "line": "Are you seeing anyone right now?", "order": 29950, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 4, "scene": 25, "line": "She has a boyfriend. He's out of town.", "order": 29951, "speaker": "Pam Beesly"},
  {"season": 6, "episode": 4, "scene": 25, "line": "Cool.", "order": 29952, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 4, "scene": 26, "line": "[to a girl sitting next to him] I was the youngest VP in the company history.", "order": 29953, "speaker": "Ryan Howard"},
  {"season": 6, "episode": 4, "scene": 26, "line": "More recently, he worked in a bowling alley. Tell her one of your funny bowling alley stories.", "order": 29954, "speaker": "Meredith Palmer"},
  {"season": 6, "episode": 4, "scene": 26, "line": "Um... also--", "order": 29955, "speaker": "Ryan Howard"},
  {"season": 6, "episode": 4, "scene": 27, "line": "How did Meredith get put at the young people's table?", "order": 29956, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 4, "scene": 27, "line": "She probably switched cards with someone. Like I did with Erin.", "order": 29957, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 4, "scene": 27, "line": "What? You're kid-- That's-- You're--", "order": 29958, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 4, "scene": 28, "line": "Jim and Pam, I can't tell you how happy I am to be here.", "order": 29959, "speaker": "Isabel"},
  {"season": 6, "episode": 4, "scene": 28, "line": "Head table, where I belong.", "order": 29960, "speaker": "Michael Scott"},
  {"season": 6, "episode": 4, "scene": 28, "line": "It's just for family.", "order": 29961, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 4, "scene": 28, "line": "Well, who's that one?", "order": 29962, "speaker": "Michael Scott"},
  {"season": 6, "episode": 4, "scene": 28, "line": "Isabel Poreba. Oh, I've got stuff on her. [laughs] In 1996 her tenth grade volleyball team went 10-2.", "order": 29963, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 4, "scene": 28, "line": "What am I supposed to do with that, Dwight?", "order": 29964, "speaker": "Michael Scott"},
  {"season": 6, "episode": 4, "scene": 28, "line": "That's a very good record.", "order": 29965, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 4, "scene": 29, "line": "Alright, so I'll be like, 'You're so sweet guys and so kind'.", "order": 29966, "speaker": "Pete Miller"},
  {"season": 6, "episode": 4, "scene": 29, "line": "That's when I'll do the face, like-", "order": 29967, "speaker": "Tom"},
  {"season": 6, "episode": 4, "scene": 29, "line": "Like, 'What? What?'. And then we'll just give him a little punch in the back and--", "order": 29968, "speaker": "Pete Miller"},
  {"season": 6, "episode": 4, "scene": 29, "line": "A noogie?", "order": 29969, "speaker": "Tom"},
  {"season": 6, "episode": 4, "scene": 29, "line": "You know what? Never too married for a noogie.", "order": 29970, "speaker": "Pete Miller"},
  {"season": 6, "episode": 4, "scene": 30, "line": "They have hilarious material and they are going to totally deliver it wrong. I would kill with the brother stuff. It should be me... up there. It should be me and Pete, not Pete and Tom.", "order": 29971, "speaker": "Michael Scott"},
  {"season": 6, "episode": 4, "scene": 31, "line": "Pam, you've got the greatest smile and you're body is really fine.", "order": 29972, "speaker": "Tom"},
  {"season": 6, "episode": 4, "scene": 31, "line": "Smoking.", "order": 29973, "speaker": "Pete Miller"},
  {"season": 6, "episode": 4, "scene": 31, "line": "Hoping it'll make our wives take it up a notch.", "order": 29974, "speaker": "Tom"},
  {"season": 6, "episode": 4, "scene": 31, "line": "A little mo' cardio.", "order": 29975, "speaker": "Pete Miller"},
  {"season": 6, "episode": 4, "scene": 31, "line": "That's not appropriate. Hello. Hi, everybody. I promised I wasn't gonna make a toast and I'm not going to. Just going to do a little free standing comedy and if at the end everybody wants to raise their glasses to Pam and Jim, then so be it. Hey, what is the deal with the Smart Car? How smart is that? Those things are tiny. Can you even drive them in traffic? 'I'm so smart. E=mc... squared. I drive a Smart Car.' That's not smart in my book. The real smart car is Kitt from Knight Rider.", "order": 29976, "speaker": "Michael Scott"},
  {"season": 6, "episode": 4, "scene": 31, "line": "Knight Rider.", "order": 29977, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 4, "scene": 31, "line": "That's a car that can talk. Can Smart Car talk? Nope.", "order": 29978, "speaker": "Michael Scott"},
  {"season": 6, "episode": 4, "scene": 31, "line": "No.", "order": 29979, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 4, "scene": 31, "line": "That's not smart. And also, Chitty Chitty Bang Bang. [no one is laughing]", "order": 29980, "speaker": "Michael Scott"},
  {"season": 6, "episode": 4, "scene": 31, "line": "Very smart.", "order": 29981, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 4, "scene": 31, "line": "And... you... everybody can laugh. It doesn't have to just be the idiots. Everybody can laugh.", "order": 29982, "speaker": "Michael Scott"},
  {"season": 6, "episode": 4, "scene": 31, "line": "No.", "order": 29983, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 4, "scene": 31, "line": "Yeah. Go ahead.", "order": 29984, "speaker": "Michael Scott"},
  {"season": 6, "episode": 4, "scene": 31, "line": "Alright. Hey, everyone. Thanks for coming.", "order": 29985, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 4, "scene": 31, "line": "Douche.", "order": 29986, "speaker": "Pete Miller"},
  {"season": 6, "episode": 4, "scene": 31, "line": "Aw, thanks, Pete. That was really nice. I just want to say how happy we are that all of you are here tonight. And I want to especially thank those of you who traveled from far away to be here with us tonight... especially the Flordia cousins who, obviously, can't take a hint. [laughter] Four years ago, I was just a guy who had a crush on a girl who had a boyfriend. And I had to do the hardest thing that I've ever had to do... which was just to wait. Uh, don't get me wrong. I flirted with her. Pam, I can now admit in front of my friends and family that I do know how to make a photo copy. Didn't need your help that many times. And do you remember how long it took you to teach me how to drive a stick?", "order": 29987, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 4, "scene": 31, "line": "Like... a year.", "order": 29988, "speaker": "Pam Beesly"},
  {"season": 6, "episode": 4, "scene": 31, "line": "I've been driving stick since high school. So... yes. For a really long time that's all I had. I just had little moments with a girl who saw me as friend. And a lot of people told me I was crazy to wait this long for a date with a girl who I worked with, but I think even then I knew that... I was waiting for my wife. So... I would like to propose a toast. So if you'd all raise your glasses. Not Pam, for obvious reasons, but everyone else. If you would raise your glasses--", "order": 29989, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 4, "scene": 31, "line": "What's obvious? Why can't Pam drink?", "order": 29990, "speaker": "Mema"},
  {"season": 6, "episode": 4, "scene": 31, "line": "Pam can't drink? I didn't-- I shouldn't have said that. I don't know why I did. She can do whatever she wants, though she shouldn't. She shouldn't because she's an alcoholic. Pam is an alcoholic. That's not true. I-- no. What we want-- the real reason is that, that Pam's pregnant.", "order": 29991, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 4, "scene": 31, "line": "[clears throat] Ok. Ok. Hey. What I think Jim is trying to say is that... they had an accident and you know what? These two people, they're living together, they're having lots of consentual sex--", "order": 29992, "speaker": "Michael Scott"},
  {"season": 6, "episode": 4, "scene": 31, "line": "They were living together?", "order": 29993, "speaker": "Mema"},
  {"season": 6, "episode": 4, "scene": 31, "line": "Yes. Yes they were living together. Yes and you know what? You can't expect them to be careful every time. Because, frankly, it's just a different sensation.", "order": 29994, "speaker": "Michael Scott"},
  {"season": 6, "episode": 4, "scene": 31, "line": "Michael.", "order": 29995, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 4, "scene": 31, "line": "When you c-- well? Am I wrong? They say it's not different, but it's a different sensation.", "order": 29996, "speaker": "Michael Scott"},
  {"season": 6, "episode": 4, "scene": 31, "line": "Oh, my God. Please.", "order": 29997, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 4, "scene": 31, "line": "When you use something to block-- I think everybody knows what I am talking about. It's not necessarily different for the woman--", "order": 29998, "speaker": "Michael Scott"},
  {"season": 6, "episode": 4, "scene": 31, "line": "Michael.", "order": 29999, "speaker": "Pam Beesly"},
  {"season": 6, "episode": 4, "scene": 31, "line": "...but it's different for the-- ok. Ok.", "order": 30000, "speaker": "Michael Scott"},
  {"season": 6, "episode": 4, "scene": 31, "line": "Michael. Stop.", "order": 30001, "speaker": "Pam Beesly"},
  {"season": 6, "episode": 4, "scene": 31, "line": "Alright. My point is I said what I wanted to say and Mema, I hope you heard every word.", "order": 30002, "speaker": "Michael Scott"},
  {"season": 6, "episode": 4, "scene": 31, "line": "Alright.", "order": 30003, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 4, "scene": 31, "line": "Jim.", "order": 30004, "speaker": "Michael Scott"},
  {"season": 6, "episode": 4, "scene": 31, "line": "To waiting.", "order": 30005, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 4, "scene": 31, "line": "[quiet and scattered] To waiting.", "order": 30006, "speaker": "Everyone"},
  {"season": 6, "episode": 4, "scene": 32, "line": "I should have known. The hotels television set had a movie on called 'Bruno' last night. The remote control had so darn many buttons on it, I couldn't turn it off. So I had to just sit there while it happened to me. I wondered, 'How could they pick such a hotel?' Hmmm. Now I know.", "order": 30007, "speaker": "Mema"},
  {"season": 6, "episode": 4, "scene": 32, "line": "Mema.", "order": 30008, "speaker": "Pam Beesly"},
  {"season": 6, "episode": 4, "scene": 33, "line": "That was a little touch and go at first but I think we saved it.", "order": 30009, "speaker": "Michael Scott"},
  {"season": 6, "episode": 4, "scene": 33, "line": "I can't believe it was me.", "order": 30010, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 4, "scene": 33, "line": "I know. I can't believe it was you, either. I actually think it takes a lot of pressure off of me.", "order": 30011, "speaker": "Michael Scott"},
  {"season": 6, "episode": 4, "scene": 33, "line": "Is there something about being a manager that makes you say stupid things?", "order": 30012, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 4, "scene": 33, "line": "I have not found that to be the case.", "order": 30013, "speaker": "Michael Scott"},
  {"season": 6, "episode": 4, "scene": 33, "line": "Hey, smooth guys.", "order": 30014, "speaker": "Pam Beesly"},
  {"season": 6, "episode": 4, "scene": 33, "line": "I'm so sorry.", "order": 30015, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 4, "scene": 33, "line": "Can you believe it? He screwed up, not me.", "order": 30016, "speaker": "Michael Scott"},
  {"season": 6, "episode": 4, "scene": 33, "line": "Mema's not coming to the wedding. She's leaving tomorrow morning.", "order": 30017, "speaker": "Pam Beesly"},
  {"season": 6, "episode": 4, "scene": 33, "line": "Oh, my God. Are you serious?", "order": 30018, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 4, "scene": 33, "line": "There's gonna be a free room?", "order": 30019, "speaker": "Michael Scott"},
  {"season": 6, "episode": 4, "scene": 34, "line": "Hi, Mema. It's Michael.", "order": 30020, "speaker": "Michael Scott"},
  {"season": 6, "episode": 4, "scene": 34, "line": "Oh. You're that foul guy that kept talking about intercourse.", "order": 30021, "speaker": "Mema"},
  {"season": 6, "episode": 4, "scene": 34, "line": "Yes. Yep. One in the same. May I?", "order": 30022, "speaker": "Michael Scott"},
  {"season": 6, "episode": 4, "scene": 35, "line": "I am actually great with old women. In fact, for the longest time, my best friend was my grandma... and then she met Harriet and now she thinks she's better than everybody.", "order": 30023, "speaker": "Michael Scott"},
  {"season": 6, "episode": 4, "scene": 36, "line": "I want to go to bed but I can't. I can't turn that television off.", "order": 30024, "speaker": "Mema"},
  {"season": 6, "episode": 4, "scene": 36, "line": "Oh. Ok. Well... hook you up, there. [turns off tv]", "order": 30025, "speaker": "Michael Scott"},
  {"season": 6, "episode": 4, "scene": 36, "line": "Oh. Thank you.", "order": 30026, "speaker": "Mema"},
  {"season": 6, "episode": 4, "scene": 36, "line": "You're welcome.", "order": 30027, "speaker": "Michael Scott"},
  {"season": 6, "episode": 4, "scene": 36, "line": "It was that horrible Charlie Rose.", "order": 30028, "speaker": "Mema"},
  {"season": 6, "episode": 4, "scene": 36, "line": "Oh. Isn't he terrible? May I? Here's the thing. Umm... Mema, I think you just need to chill out about this whole Pam getting pregnant thing. It's not 1890 anymore. It's modern day. And women have sex before marriage and I think we need to celebrate that. And I know in your day she would be considered a whore but, now, women go out and they have sex and they get wild and they take their tops off and they have pictures taken of them and we need to encouage them. That's part of life.", "order": 30029, "speaker": "Michael Scott"},
  {"season": 6, "episode": 4, "scene": 36, "line": "People are like cats and dogs these days.", "order": 30030, "speaker": "Mema"},
  {"season": 6, "episode": 4, "scene": 36, "line": "Exactly.", "order": 30031, "speaker": "Michael Scott"},
  {"season": 6, "episode": 4, "scene": 36, "line": "This used to be such a great country.", "order": 30032, "speaker": "Mema"},
  {"season": 6, "episode": 4, "scene": 36, "line": "I know.", "order": 30033, "speaker": "Michael Scott"},
  {"season": 6, "episode": 4, "scene": 36, "line": "I don't know what happened to it.", "order": 30034, "speaker": "Mema"},
  {"season": 6, "episode": 4, "scene": 36, "line": "They're gonna name the baby after you, you know. They're gonna call it Mema.", "order": 30035, "speaker": "Michael Scott"},
  {"season": 6, "episode": 4, "scene": 36, "line": "You mean, Sylvia.", "order": 30036, "speaker": "Mema"},
  {"season": 6, "episode": 4, "scene": 36, "line": "Yes, and if it's a boy they will call it Sylvio.", "order": 30037, "speaker": "Michael Scott"},
  {"season": 6, "episode": 4, "scene": 37, "line": "Partay. Room 639.", "order": 30038, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 4, "scene": 37, "line": "Yes!", "order": 30039, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 4, "scene": 37, "line": "Chicks are gonna be off the hook. Guys, too, Oscar. Like Calvin Klein models.", "order": 30040, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 4, "scene": 37, "line": "That sounds epic. Can we bring anything?", "order": 30041, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 4, "scene": 37, "line": "Nothing. Except $40 for beer and any hot chicks you know. 'Cause that would help me deliver on some promises I made. Woo!", "order": 30042, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 4, "scene": 38, "line": "No one from the office has been to a real Bernard throw down.", "order": 30043, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 4, "scene": 39, "line": "If I was girl, I would seriously reconsdier coming to this party. But don't tell anyone that I said that, 'cause I want them to come.", "order": 30044, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 4, "scene": 40, "line": "And where do you think you're going?", "order": 30045, "speaker": "Angela Martin"},
  {"season": 6, "episode": 4, "scene": 40, "line": "I was just going to go down to the hotel bar for a little bit.", "order": 30046, "speaker": "Pam Beesly"},
  {"season": 6, "episode": 4, "scene": 40, "line": "Well, why don't I just save you some time and kick you in the stomach instead?", "order": 30047, "speaker": "Angela Martin"},
  {"season": 6, "episode": 4, "scene": 40, "line": "I just wanted to get out of my room for a little while.", "order": 30048, "speaker": "Pam Beesly"},
  {"season": 6, "episode": 4, "scene": 40, "line": "Mmm-hmm. Ok. I'll go with you. Come on.", "order": 30049, "speaker": "Angela Martin"},
  {"season": 6, "episode": 4, "scene": 40, "line": "[sighs] Nevermind.", "order": 30050, "speaker": "Pam Beesly"},
  {"season": 6, "episode": 4, "scene": 40, "line": "Are you sure?", "order": 30051, "speaker": "Angela Martin"},
  {"season": 6, "episode": 4, "scene": 40, "line": "Yeah.", "order": 30052, "speaker": "Pam Beesly"},
  {"season": 6, "episode": 4, "scene": 40, "line": "It'll be fun.", "order": 30053, "speaker": "Angela Martin"},
  {"season": 6, "episode": 4, "scene": 40, "line": "No.", "order": 30054, "speaker": "Pam Beesly"},
  {"season": 6, "episode": 4, "scene": 41, "line": "What are you wearing?", "order": 30055, "speaker": "Michael Scott"},
  {"season": 6, "episode": 4, "scene": 41, "line": "What? It's a casual, social outfit.", "order": 30056, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 4, "scene": 41, "line": "Looks like you're going to a fish fry, Dwight.", "order": 30057, "speaker": "Michael Scott"},
  {"season": 6, "episode": 4, "scene": 41, "line": "No. They're howling at the moon. It's suggestive to women because of the howling during sex.", "order": 30058, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 4, "scene": 41, "line": "Ok. [Dwight howls] That's not appropriate. [Dwight continues howling] Uncool. Uncool, Dwight. [they both howl] Ok. Ok. Come on. Let's go. [both howl as they exit]", "order": 30059, "speaker": "Michael Scott"},
  {"season": 6, "episode": 4, "scene": 42, "line": "It's a good idea.", "order": 30060, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 4, "scene": 42, "line": "It's not a good idea. There's no such thing as a good hairpiece.", "order": 30061, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 4, "scene": 42, "line": "Yeah. But that's easy enough for you to say, Oscar. You have that thick, beautiful, Chicano hair. So nice.", "order": 30062, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 4, "scene": 43, "line": "I'm just worried about the farm, ya know? Mose hates to geld the horses by himself.", "order": 30063, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 4, "scene": 43, "line": "Dwight, Dwight. Shut up about the farm. It's not relatable. Nobody owns a farm.", "order": 30064, "speaker": "Michael Scott"},
  {"season": 6, "episode": 4, "scene": 43, "line": "Wait. You're worried about your horses? That's cool. How many horses do you have?", "order": 30065, "speaker": "Isabel"},
  {"season": 6, "episode": 4, "scene": 43, "line": "Nine and three-quarters.", "order": 30066, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 4, "scene": 44, "line": "I invented a device called 'Burger on the Go'. It allows you to obtain 6 regular size hamburgers, or 12 sliders, from a horse without killing the animal. George Foreman is still considering it. Sharper Image is still considering it. Sky Mall's considering it. Hammacher Schlemer is still considering it. Sears said, 'No'.", "order": 30067, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 4, "scene": 45, "line": "[talking to an attractive woman] Toy Story, Finding Nemo, Up. I ball the entire time. I can not watch Pixar.", "order": 30068, "speaker": "Michael Scott"},
  {"season": 6, "episode": 4, "scene": 45, "line": "Michael.", "order": 30069, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 4, "scene": 45, "line": "Yes.", "order": 30070, "speaker": "Michael Scott"},
  {"season": 6, "episode": 4, "scene": 45, "line": "Drop this one. Abort.", "order": 30071, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 4, "scene": 45, "line": "Why?", "order": 30072, "speaker": "Michael Scott"},
  {"season": 6, "episode": 4, "scene": 45, "line": "I found twins.", "order": 30073, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 4, "scene": 45, "line": "Oh, my God. Twins. I'm sorry. You understand. Nice to meet you.", "order": 30074, "speaker": "Michael Scott"},
  {"season": 6, "episode": 4, "scene": 46, "line": "Aren't they magnificent?", "order": 30075, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 4, "scene": 46, "line": "They're men, Dwight.", "order": 30076, "speaker": "Michael Scott"},
  {"season": 6, "episode": 4, "scene": 46, "line": "I love finding a good set of twins.", "order": 30077, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 4, "scene": 46, "line": "Something is wrong with you.", "order": 30078, "speaker": "Michael Scott"},
  {"season": 6, "episode": 4, "scene": 47, "line": "[everyone is dancing] Go Meredith. Nice moves.", "order": 30079, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 4, "scene": 47, "line": "You call that a robot? Try being more robotic, ok? Like this.", "order": 30080, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 4, "scene": 47, "line": "Lame! What else you got, Andy?", "order": 30081, "speaker": "Kelly Kapoor"},
  {"season": 6, "episode": 4, "scene": 47, "line": "How about a little bit of this.", "order": 30082, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 4, "scene": 47, "line": "Oh yeah. What else you got?", "order": 30083, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 4, "scene": 47, "line": "What else you got?", "order": 30084, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 4, "scene": 47, "line": "Did someone change my name to Baskin Robbin? Because I feel like a banana split. Woo! [Andy does a split and screams out in pain]", "order": 30085, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 4, "scene": 47, "line": "What else you got?", "order": 30086, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 4, "scene": 48, "line": "It's gonna be fine. It's gonna be fine. Pam's here. [knocks on Pam's door] Pam? [knocks again]", "order": 30087, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 4, "scene": 48, "line": "I was dancing and I did a split and I landed on my car keys in my pocket.", "order": 30088, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 4, "scene": 48, "line": "What?", "order": 30089, "speaker": "Pam Beesly"},
  {"season": 6, "episode": 4, "scene": 48, "line": "I tore my scrotum. I need you to take me to the hospital.", "order": 30090, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 4, "scene": 49, "line": "Andy, I am getting married in 8 hours.", "order": 30091, "speaker": "Pam Beesly"},
  {"season": 6, "episode": 4, "scene": 49, "line": "Everyone else is too drunk. Just don't let me die here.", "order": 30092, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 4, "scene": 49, "line": "[on the phone] Hey, hey! Where are you? Can you take Andy to the hospital?", "order": 30093, "speaker": "Pam Beesly"},
  {"season": 6, "episode": 4, "scene": 49, "line": "[over phone] What?", "order": 30094, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 4, "scene": 49, "line": "He tore his scrotum dancing.", "order": 30095, "speaker": "Pam Beesly"},
  {"season": 6, "episode": 4, "scene": 49, "line": "[over phone] What?", "order": 30096, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 4, "scene": 49, "line": "He is in my room icing his balls.", "order": 30097, "speaker": "Pam Beesly"},
  {"season": 6, "episode": 4, "scene": 49, "line": "[over phone] What?", "order": 30098, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 4, "scene": 49, "line": "Please stop saying what. Can you take him?", "order": 30099, "speaker": "Pam Beesly"},
  {"season": 6, "episode": 4, "scene": 49, "line": "Look, I would so take him in any other circumstance, but I'm pretty certain I'm completely wasted.", "order": 30100, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 4, "scene": 49, "line": "Your brothers took you out drinking?", "order": 30101, "speaker": "Pam Beesly"},
  {"season": 6, "episode": 4, "scene": 49, "line": "Uh...", "order": 30102, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 4, "scene": 49, "line": "[over phone] Is that Pam? Hey, have her come out! Have her come out! It would be like Coyote Ugly.", "order": 30103, "speaker": "Michael Scott"},
  {"season": 6, "episode": 4, "scene": 49, "line": "That's Michael. You're out with Michael?", "order": 30104, "speaker": "Pam Beesly"},
  {"season": 6, "episode": 4, "scene": 49, "line": "[over phone] And Dwight.", "order": 30105, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 4, "scene": 49, "line": "[over phone] Hey-O!", "order": 30106, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 4, "scene": 49, "line": "Pam, it just happened.", "order": 30107, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 4, "scene": 49, "line": "Okay, fine. I'll take him.", "order": 30108, "speaker": "Pam Beesly"},
  {"season": 6, "episode": 4, "scene": 49, "line": "I love you. Okay, I gotta--I gotta go!", "order": 30109, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 4, "scene": 49, "line": "[over phone] I love you! [laughs]", "order": 30110, "speaker": "Michael Scott"},
  {"season": 6, "episode": 4, "scene": 49, "line": "Are you pushing me off the phone?", "order": 30111, "speaker": "Pam Beesly"},
  {"season": 6, "episode": 4, "scene": 49, "line": "No. Let's talk for a long time.", "order": 30112, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 4, "scene": 49, "line": "Goodbye. [hangs up phone]", "order": 30113, "speaker": "Pam Beesly"},
  {"season": 6, "episode": 4, "scene": 50, "line": "Mmm! It's after midnight. [points at Jim]", "order": 30114, "speaker": "Michael Scott"},
  {"season": 6, "episode": 4, "scene": 50, "line": "You're married. [hugs Jim] He's married!", "order": 30115, "speaker": "Michael Scott & Dwight Schrute"},
  {"season": 6, "episode": 4, "scene": 50, "line": "Congratulations.", "order": 30116, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 4, "scene": 50, "line": "That's not how that works.", "order": 30117, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 4, "scene": 50, "line": "Oh my goodness.", "order": 30118, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 4, "scene": 50, "line": "Oh my God. You're not going to be able to talk back.", "order": 30119, "speaker": "Michael Scott"},
  {"season": 6, "episode": 4, "scene": 50, "line": "You'll have Pam to answer to.", "order": 30120, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 4, "scene": 50, "line": "She'll be sitting home saying, 'Jim... take the baby to the zoo cause I want to sit at home and eat bon-bons.' [Dwight laughs] 'And... and clip my toenails.'", "order": 30121, "speaker": "Michael Scott"},
  {"season": 6, "episode": 4, "scene": 50, "line": "Jim... hey why don't you braid my hair. I want to watch TV.'", "order": 30122, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 4, "scene": 50, "line": "Now you sound like Kermit.", "order": 30123, "speaker": "Michael Scott"},
  {"season": 6, "episode": 4, "scene": 51, "line": "Are you sure this is the right way?", "order": 30124, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 4, "scene": 51, "line": "Nope. I, like you, have never been here before.", "order": 30125, "speaker": "Pam Beesly"},
  {"season": 6, "episode": 4, "scene": 51, "line": "Well, at least slow down a little bit because every little bump in the road is major pain on... my scrotum.", "order": 30126, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 4, "scene": 51, "line": "Look, I'm not the one who asked you to do a split when you've never done one before.", "order": 30127, "speaker": "Pam Beesly"},
  {"season": 6, "episode": 4, "scene": 51, "line": "I was trying to liven things up a little bit. I was kinda doing your job so--", "order": 30128, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 4, "scene": 51, "line": "My job? My job is to get married in the morning. That's my job.", "order": 30129, "speaker": "Pam Beesly"},
  {"season": 6, "episode": 4, "scene": 51, "line": "Well it's also to make sure that we have a great time at your stupid wedding, so--", "order": 30130, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 4, "scene": 51, "line": "[Pam swerves the car back and forth] Ow, ow!", "order": 30131, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 4, "scene": 52, "line": "[snickers] I spent the night with the bride the night before the wedding. Ah yeah. She stepped on my hand on her way to the bathroom.", "order": 30132, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 4, "scene": 52, "line": "Andy, did I dream that you were crying through the night?", "order": 30133, "speaker": "Pam Beesly"},
  {"season": 6, "episode": 4, "scene": 52, "line": "No. No that was real.", "order": 30134, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 4, "scene": 53, "line": "[folding his pants on top of the ice machine, man walks in with ice bucket] Why don't you take a picture. It'll last longer. God. Can't I get a little privacy?", "order": 30135, "speaker": "Michael Scott"},
  {"season": 6, "episode": 4, "scene": 54, "line": "[Kevin rings bell at the front desk] Good morning. How can I help you?", "order": 30136, "speaker": "Hotel Employee"},
  {"season": 6, "episode": 4, "scene": 54, "line": "I left a pair of dress shoes in a bag outside my door last night to be cleaned at polished, but they haven't been returned yet.", "order": 30137, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 4, "scene": 54, "line": "You must be Mr. Malone. One moment please.", "order": 30138, "speaker": "Hotel Employee"},
  {"season": 6, "episode": 4, "scene": 54, "line": "Thank you.", "order": 30139, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 4, "scene": 54, "line": "[whispers to manager] Sir. It's the man with the shoes.", "order": 30140, "speaker": "Hotel Employee"},
  {"season": 6, "episode": 4, "scene": 54, "line": "Mr. Malone. Your shoes are gone.", "order": 30141, "speaker": "Hotel Manager"},
  {"season": 6, "episode": 4, "scene": 54, "line": "They were stolen?", "order": 30142, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 4, "scene": 54, "line": "No. Destroyed.", "order": 30143, "speaker": "Hotel Manager"},
  {"season": 6, "episode": 4, "scene": 54, "line": "What?", "order": 30144, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 4, "scene": 54, "line": "The bag was opened by our shoe shine and the smell overcame him. I too smelled them and made the choice that they must be thrown away. Incinerated actually.", "order": 30145, "speaker": "Hotel Manager"},
  {"season": 6, "episode": 4, "scene": 54, "line": "But that was my only pair of shoes.", "order": 30146, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 4, "scene": 54, "line": "It became a safety issue, sir.", "order": 30147, "speaker": "Hotel Manager"},
  {"season": 6, "episode": 4, "scene": 54, "line": "Well... well damn-it.", "order": 30148, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 4, "scene": 54, "line": "I can offer you a complimentary breakfast.", "order": 30149, "speaker": "Hotel Employee"},
  {"season": 6, "episode": 4, "scene": 54, "line": "Okay.", "order": 30150, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 4, "scene": 55, "line": "[walking out of hotel room with a woman] You have everything?", "order": 30151, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 4, "scene": 55, "line": "Hmm-hmm.", "order": 30152, "speaker": "Isabel"},
  {"season": 6, "episode": 4, "scene": 55, "line": "Cell phone? Charger?", "order": 30153, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 4, "scene": 55, "line": "Yeah. So you sure you don't want breakfast? It's the most important meal of the day.", "order": 30154, "speaker": "Isabel"},
  {"season": 6, "episode": 4, "scene": 55, "line": "It really is. I'm not hungry. I ate a whole bunch of sunflower seeds after you went to sleep. And besides, I wouldn't want to take you away from Pam on the morning of her wedding. She needs you Isabel.", "order": 30155, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 4, "scene": 55, "line": "That's really sweet, Dwight. So I'll see you at the wedding?", "order": 30156, "speaker": "Isabel"},
  {"season": 6, "episode": 4, "scene": 55, "line": "You know it.", "order": 30157, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 4, "scene": 55, "line": "K. [they kiss]", "order": 30158, "speaker": "Isabel"},
  {"season": 6, "episode": 4, "scene": 55, "line": "Okay, get out of here.", "order": 30159, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 4, "scene": 55, "line": "Bye.", "order": 30160, "speaker": "Isabel"},
  {"season": 6, "episode": 4, "scene": 55, "line": "Okay.", "order": 30161, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 4, "scene": 55, "line": "[passes Isabel] Hey, good morning. [to Dwight] It's about damn time. I haven't gone to the bathroom in a day and a half. [walks into hotel room] Oh my God, Dwight. This room is a pit.", "order": 30162, "speaker": "Michael Scott"},
  {"season": 6, "episode": 4, "scene": 55, "line": "[lays on bed] Really? I hadn't noticed. Too busy knocking boots.", "order": 30163, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 4, "scene": 56, "line": "[watching Dwight eat breakfast] How can you eat like that?", "order": 30164, "speaker": "Michael Scott"},
  {"season": 6, "episode": 4, "scene": 56, "line": "I'm ravenous after a night of love making. You?", "order": 30165, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 4, "scene": 56, "line": "Yeah, I'm hungry, but I'm not going to make a pig out of myself.", "order": 30166, "speaker": "Michael Scott"},
  {"season": 6, "episode": 4, "scene": 56, "line": "Hey, what was she like?", "order": 30167, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 4, "scene": 56, "line": "She was cute. You know? She was hot. She was very hot. She made love like a tiger.", "order": 30168, "speaker": "Michael Scott"},
  {"season": 6, "episode": 4, "scene": 56, "line": "Brides side or grooms side? Or townie?", "order": 30169, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 4, "scene": 56, "line": "She was from Europe.", "order": 30170, "speaker": "Michael Scott"},
  {"season": 6, "episode": 4, "scene": 56, "line": "No kidding.", "order": 30171, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 4, "scene": 56, "line": "Uh-huh.", "order": 30172, "speaker": "Michael Scott"},
  {"season": 6, "episode": 4, "scene": 56, "line": "I bet she had hairy armpits.", "order": 30173, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 4, "scene": 56, "line": "No.", "order": 30174, "speaker": "Michael Scott"},
  {"season": 6, "episode": 4, "scene": 56, "line": "Isabel was nice, but I hope she doesn't think this is going anywhere.", "order": 30175, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 4, "scene": 56, "line": "Wait a second. You're not into her? Are you kidding me?", "order": 30176, "speaker": "Michael Scott"},
  {"season": 6, "episode": 4, "scene": 56, "line": "No.", "order": 30177, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 4, "scene": 56, "line": "She's Pam's best friend. You guys could double date. Swap maybe?", "order": 30178, "speaker": "Michael Scott"},
  {"season": 6, "episode": 4, "scene": 56, "line": "Oh please. Put a gun in my mouth.", "order": 30179, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 4, "scene": 56, "line": "No... you're crazy.", "order": 30180, "speaker": "Michael Scott"},
  {"season": 6, "episode": 4, "scene": 56, "line": "Look. She's a dental hygienist from Carbondale and she makes love like one. She's a bumpkin. Pass.", "order": 30181, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 4, "scene": 56, "line": "She's-- okay. Do you know how hard it is to be a hygienist? You have to take x-rays. You have to scrape the plaque off of people's teeth. You have to tell kids when to spit and make sure they're not scared when the dentist comes in. It-- it--", "order": 30182, "speaker": "Michael Scott"},
  {"season": 6, "episode": 4, "scene": 56, "line": "You should ask her out.", "order": 30183, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 4, "scene": 56, "line": "[stands up and walks away] I already have my European girlfriend.", "order": 30184, "speaker": "Michael Scott"},
  {"season": 6, "episode": 4, "scene": 57, "line": "If your hat hits me in the face one more time--", "order": 30185, "speaker": "Stanley Hudson"},
  {"season": 6, "episode": 4, "scene": 57, "line": "That's funny. Your wife loved this hat the last time she saw it. [glares at Stanley's date]", "order": 30186, "speaker": "Phyllis Vance"},
  {"season": 6, "episode": 4, "scene": 58, "line": "[sees Kevin's toupee] Oh my God.", "order": 30187, "speaker": "Angela Martin"},
  {"season": 6, "episode": 4, "scene": 58, "line": "Oh...", "order": 30188, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 4, "scene": 58, "line": "Oscar. Angela.", "order": 30189, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 4, "scene": 59, "line": "Are you in a lot of pain?", "order": 30190, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 4, "scene": 59, "line": "Oh cause of last night? No way. Reports have been exaggerated. Weddings make me very emotional. I um--I just have that side to me.", "order": 30191, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 4, "scene": 59, "line": "People say you cry all the time.", "order": 30192, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 4, "scene": 59, "line": "Well that's not--", "order": 30193, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 4, "scene": 59, "line": "Crotch injuries are the worst. You don't need to tell me.", "order": 30194, "speaker": "Meredith Palmer"},
  {"season": 6, "episode": 4, "scene": 59, "line": "I wasn't telling you.", "order": 30195, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 4, "scene": 59, "line": "Is there still something there?", "order": 30196, "speaker": "Meredith Palmer"},
  {"season": 6, "episode": 4, "scene": 59, "line": "Excuse me?", "order": 30197, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 4, "scene": 59, "line": "It didn't get torn off?", "order": 30198, "speaker": "Meredith Palmer"},
  {"season": 6, "episode": 4, "scene": 59, "line": "No, it didn't--nothing got torn off. Who told you that?", "order": 30199, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 4, "scene": 60, "line": "I may have told some people that. I'm nervous. I'm about to get married.", "order": 30200, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 4, "scene": 61, "line": "If you want to sit on this-- [hands Andy her wrap] I was thinking the wood might be too hard on your damaged penis. Make it softer.", "order": 30201, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 4, "scene": 61, "line": "It was my scrotum. Um, and it was. Thank you.", "order": 30202, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 4, "scene": 61, "line": "Oh, here we go. They asked for cash, but you know... I give them cash every week, so-- how much cash does a person need? I have taken it upon myself to do something a little more special. I have painted a portrait of the two of them from memory. [holds up painting] And I have another one of them in the nude. But that one is for me.", "order": 30203, "speaker": "Michael Scott"},
  {"season": 6, "episode": 4, "scene": 62, "line": "I got them a set of turtle boiling pots, a shell hammer and bibs.", "order": 30204, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 4, "scene": 63, "line": "Is it zipping over your belly? Don't squish the baby.", "order": 30205, "speaker": "Penny"},
  {"season": 6, "episode": 4, "scene": 63, "line": "Thank you weirdo.", "order": 30206, "speaker": "Pam Beesly"},
  {"season": 6, "episode": 4, "scene": 63, "line": "everybody see Kristie's stupid blonde extensions? It's like they were made from a plastic broom.", "order": 30207, "speaker": "Helene Beesly"},
  {"season": 6, "episode": 4, "scene": 63, "line": "Okay, here I come. [walks out in her wedding dress]", "order": 30208, "speaker": "Pam Beesly"},
  {"season": 6, "episode": 4, "scene": 63, "line": "Oh darling! You look beautiful.", "order": 30209, "speaker": "Helene Beesly"},
  {"season": 6, "episode": 4, "scene": 63, "line": "Thanks mom.", "order": 30210, "speaker": "Pam Beesly"},
  {"season": 6, "episode": 4, "scene": 63, "line": "Oh, I hope he deserves you.", "order": 30211, "speaker": "Helene Beesly"},
  {"season": 6, "episode": 4, "scene": 63, "line": "He does.", "order": 30212, "speaker": "Pam Beesly"},
  {"season": 6, "episode": 4, "scene": 63, "line": "Are you sure? Remember you don't have to do this. You don't.", "order": 30213, "speaker": "Helene Beesly"},
  {"season": 6, "episode": 4, "scene": 63, "line": "Mom, you're totally projecting. You're being a drag.", "order": 30214, "speaker": "Penny"},
  {"season": 6, "episode": 4, "scene": 63, "line": "Yes. Thank you, mom.", "order": 30215, "speaker": "Pam Beesly"},
  {"season": 6, "episode": 4, "scene": 63, "line": "Hey, I'm going to go outside and talk to Dwight.", "order": 30216, "speaker": "Isabel"},
  {"season": 6, "episode": 4, "scene": 63, "line": "Okay great. [Isabel kisses her cheek] Yeah, I'll see you in a second. [pauses] Wait, what? [tries to follow, but veil gets caught on wall and tears] Oh! Oh no!", "order": 30217, "speaker": "Pam Beesly"},
  {"season": 6, "episode": 4, "scene": 64, "line": "[answers cell phone] Hey!", "order": 30218, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 4, "scene": 64, "line": "Can you come here please?", "order": 30219, "speaker": "Pam Beesly"},
  {"season": 6, "episode": 4, "scene": 64, "line": "Is this allowed?", "order": 30220, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 4, "scene": 64, "line": "No. No, but I'm allowing it. Just come here.", "order": 30221, "speaker": "Pam Beesly"},
  {"season": 6, "episode": 4, "scene": 65, "line": "[talking to a woman] That was an intelligent comment.", "order": 30222, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 4, "scene": 65, "line": "Hello stranger. How do I look?", "order": 30223, "speaker": "Isabel"},
  {"season": 6, "episode": 4, "scene": 65, "line": "Oh. Fine. Isabel, [holds out hand] nice to see you. What do you want?", "order": 30224, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 4, "scene": 65, "line": "Um... [shakes head] nothing. Anymore.", "order": 30225, "speaker": "Isabel"},
  {"season": 6, "episode": 4, "scene": 65, "line": "[turns back to other woman] So, uh tell me again the difference between Filene's and Filene's basement.", "order": 30226, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 4, "scene": 65, "line": "[to Isabel] Hey, hey... He's not sitting with me either. Where are you sitting? You need a seat buddy?", "order": 30227, "speaker": "Michael Scott"},
  {"season": 6, "episode": 4, "scene": 65, "line": "I'm standing. I'm a bridesmaid.", "order": 30228, "speaker": "Isabel"},
  {"season": 6, "episode": 4, "scene": 65, "line": "Oh, I wish I was a bridesmaid. Where am I gonna go?", "order": 30229, "speaker": "Michael Scott"},
  {"season": 6, "episode": 4, "scene": 66, "line": "Hey.", "order": 30230, "speaker": "Pam Beesly"},
  {"season": 6, "episode": 4, "scene": 66, "line": "Wow. You look--", "order": 30231, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 4, "scene": 66, "line": "Terrible.", "order": 30232, "speaker": "Pam Beesly"},
  {"season": 6, "episode": 4, "scene": 66, "line": "So beautiful. [Pam's starts to cry. Jim pulls up chair to sit next to her] Hey--", "order": 30233, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 4, "scene": 66, "line": "My veil tore. I knew when we were getting married and I'm five months pregnant that I'm not going to be able to wear the dress that I always wanted or high heels--", "order": 30234, "speaker": "Pam Beesly"},
  {"season": 6, "episode": 4, "scene": 66, "line": "[takes her hand] Hey. You look just as I imagined you would. Pam, you're so pretty.", "order": 30235, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 4, "scene": 66, "line": "[sighs] Thank you.", "order": 30236, "speaker": "Pam Beesly"},
  {"season": 6, "episode": 4, "scene": 66, "line": "And who cares? It's a stupid veil, right?", "order": 30237, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 4, "scene": 66, "line": "No, this was the one thing I was supposed to be able to control, was this veil and-- [Jim cuts off half his tie]", "order": 30238, "speaker": "Pam Beesly"},
  {"season": 6, "episode": 4, "scene": 66, "line": "There. Now we're even. [Pam laughs and mimes taking a picture, they kiss]", "order": 30239, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 4, "scene": 66, "line": "[sighs] Everyone's driving me crazy. I know way too much about Andy's scrotum. [Jim chuckles] And my mom won't stop freaking out about my dad's new girlfriend. [sighs] This is supposed to be our wedding day. Why did we invite all these people?", "order": 30240, "speaker": "Pam Beesly"},
  {"season": 6, "episode": 4, "scene": 67, "line": "I think Pam ran away because she knew deep down she wouldn't be a good wife.", "order": 30241, "speaker": "Phyllis Vance"},
  {"season": 6, "episode": 4, "scene": 67, "line": "Yes.", "order": 30242, "speaker": "Angela Martin"},
  {"season": 6, "episode": 4, "scene": 68, "line": "[to Kevin who is bouncing up and down] What are you doing?", "order": 30243, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 4, "scene": 68, "line": "I'm trying to decide if I have time to pee.", "order": 30244, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 4, "scene": 68, "line": "How long do you take to pee?", "order": 30245, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 4, "scene": 68, "line": "The peeing is fast, Oscar. It's getting my tie back on.", "order": 30246, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 4, "scene": 69, "line": "[to Pam's mom] Hey. Hi. Do you--would you have a snack in your purse? You're a mom. I just figured you might have--", "order": 30247, "speaker": "Michael Scott"},
  {"season": 6, "episode": 4, "scene": 69, "line": "Oh, yeah--[hands Michael a snack]", "order": 30248, "speaker": "Helene Beesly"},
  {"season": 6, "episode": 4, "scene": 69, "line": "Oh! Eww... apricot. Do you have any of the Very Berry or Ocean Splash or--", "order": 30249, "speaker": "Michael Scott"},
  {"season": 6, "episode": 4, "scene": 69, "line": "No.", "order": 30250, "speaker": "Helene Beesly"},
  {"season": 6, "episode": 4, "scene": 69, "line": "Oh... okay. Were you saving it?", "order": 30251, "speaker": "Michael Scott"},
  {"season": 6, "episode": 4, "scene": 69, "line": "Oh, no, no, no. That's okay. I was just--I've had a very rough weekend.", "order": 30252, "speaker": "Helene Beesly"},
  {"season": 6, "episode": 4, "scene": 69, "line": "I'm sorry. [puts food in his mouth] Ugh. Apricot. Made of real apes.", "order": 30253, "speaker": "Michael Scott"},
  {"season": 6, "episode": 4, "scene": 70, "line": "Do you think they canceled the wedding?", "order": 30254, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 4, "scene": 70, "line": "Relax. You'll get your shot at Jim. Those two treat the whole office like a 1970's key party.", "order": 30255, "speaker": "Angela Martin"},
  {"season": 6, "episode": 4, "scene": 71, "line": "my weekend was bad so far.", "order": 30256, "speaker": "Michael Scott"},
  {"season": 6, "episode": 4, "scene": 71, "line": "Oh--", "order": 30257, "speaker": "Helene Beesly"},
  {"season": 6, "episode": 4, "scene": 71, "line": "I came here hoping to meet somebody. You know, as you do at weddings. End up going to sleep by the vending machine. It was loud, but it was warm.", "order": 30258, "speaker": "Michael Scott"},
  {"season": 6, "episode": 4, "scene": 71, "line": "Oh that sounds awful.", "order": 30259, "speaker": "Helene Beesly"},
  {"season": 6, "episode": 4, "scene": 71, "line": "And... and the love of my life is dating somebody else.", "order": 30260, "speaker": "Michael Scott"},
  {"season": 6, "episode": 4, "scene": 71, "line": "Oh--", "order": 30261, "speaker": "Helene Beesly"},
  {"season": 6, "episode": 4, "scene": 71, "line": "It is a terrible year for love.", "order": 30262, "speaker": "Michael Scott"},
  {"season": 6, "episode": 4, "scene": 71, "line": "Yeah. Guess it is.", "order": 30263, "speaker": "Helene Beesly"},
  {"season": 6, "episode": 4, "scene": 71, "line": "I'm thinking about having my sperm frozen.", "order": 30264, "speaker": "Michael Scott"},
  {"season": 6, "episode": 4, "scene": 71, "line": "Excuse me, are you Michael Scott?", "order": 30265, "speaker": "Tom Halpert"},
  {"season": 6, "episode": 4, "scene": 71, "line": "Um, yeah.", "order": 30266, "speaker": "Michael Scott"},
  {"season": 6, "episode": 4, "scene": 71, "line": "Heard you might have a whoopee cushion on you.", "order": 30267, "speaker": "Tom"},
  {"season": 6, "episode": 4, "scene": 71, "line": "Sisters in the can and we totally want to get her when she comes back.", "order": 30268, "speaker": "Pete Miller Halpert"},
  {"season": 6, "episode": 4, "scene": 71, "line": "Oh, wow. That sounds hilarious. I do actually. [pulls whoopee cushion out of pocket and hands it to them] There you go. Use it in good health.", "order": 30269, "speaker": "Michael Scott"},
  {"season": 6, "episode": 4, "scene": 71, "line": "[laughing] Yes!", "order": 30270, "speaker": "Tom & Pete Miller"},
  {"season": 6, "episode": 4, "scene": 72, "line": "Whoa, whoa. Wait a minute. What is the etiquette on taking the gifts? Can you only take your own back or is it a whatever you can carry type of thing?", "order": 30271, "speaker": "Meredith Palmer"},
  {"season": 6, "episode": 4, "scene": 72, "line": "Anyone have anything they want to trade for a toaster?", "order": 30272, "speaker": "Stanley Hudson"},
  {"season": 6, "episode": 4, "scene": 72, "line": "Oh, oh, does it have slots for hot dogs?", "order": 30273, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 4, "scene": 72, "line": "No.", "order": 30274, "speaker": "Stanley Hudson"},
  {"season": 6, "episode": 4, "scene": 72, "line": "Who would want it?", "order": 30275, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 4, "scene": 72, "line": "Guys, maybe we should wait a little more time before we start grabbing boxes.", "order": 30276, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 4, "scene": 73, "line": "Toby.", "order": 30277, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 4, "scene": 73, "line": "What?", "order": 30278, "speaker": "Toby Flenderson"},
  {"season": 6, "episode": 4, "scene": 73, "line": "I'd like to lodge a formal complaint against Jim for making us wait for over an hour.", "order": 30279, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 4, "scene": 73, "line": "[sighs]", "order": 30280, "speaker": "Toby Flenderson"},
  {"season": 6, "episode": 4, "scene": 73, "line": "[looks over and sees hole in his present] Crap. [sees turtle on the ground, goes over and picks it up] Come here you.", "order": 30281, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 4, "scene": 73, "line": "Well I guess this weddings not going to happen. I wonder if this is it for them. [Jim and Pam walk in laughing]", "order": 30282, "speaker": "Toby Flenderson"},
  {"season": 6, "episode": 4, "scene": 74, "line": "Hey. What happened?", "order": 30283, "speaker": "Pam Beesly's dad"},
  {"season": 6, "episode": 4, "scene": 74, "line": "Where were you guys? Do you know how long I've been waiting here?", "order": 30284, "speaker": "Ryan Howard"},
  {"season": 6, "episode": 4, "scene": 74, "line": "Well we are here now, so let's just--", "order": 30285, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 4, "scene": 74, "line": "[walks up] Yes! Yes! I have so much joy... in my heart... right now. How do I look?", "order": 30286, "speaker": "Michael Scott"},
  {"season": 6, "episode": 4, "scene": 74, "line": "You look great.", "order": 30287, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 4, "scene": 74, "line": "You look great.", "order": 30288, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 4, "scene": 75, "line": "[looks at Jim's cut tie] Wear a tie much?", "order": 30289, "speaker": "Tom"},
  {"season": 6, "episode": 4, "scene": 76, "line": "[music starts playing and people start dancing down the isle] I begged them not to.", "order": 30290, "speaker": "Penny"},
  {"season": 6, "episode": 4, "scene": 76, "line": "Mm-hmm.", "order": 30291, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 4, "scene": 76, "line": "I know you specifically put this song on your Do Not Play list.", "order": 30292, "speaker": "Penny"},
  {"season": 6, "episode": 4, "scene": 76, "line": "Yes I did.", "order": 30293, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 4, "scene": 76, "line": "I'm sorry.", "order": 30294, "speaker": "Penny"},
  {"season": 6, "episode": 4, "scene": 76, "line": "[smiles] Go ahead. I think it's your turn.", "order": 30295, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 4, "scene": 76, "line": "Wait, what happened? You're okay with this?", "order": 30296, "speaker": "Penny"},
  {"season": 6, "episode": 4, "scene": 76, "line": "Yeah, I'm okay.", "order": 30297, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 4, "scene": 76, "line": "Okay then! [takes flower girl to dance down the isle]", "order": 30298, "speaker": "Penny"},
  {"season": 6, "episode": 4, "scene": 76, "line": "Hey Pam, did you see this? It was on You Tube.", "order": 30299, "speaker": "Michael Scott"},
  {"season": 6, "episode": 4, "scene": 76, "line": "Saw it.", "order": 30300, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 4, "scene": 77, "line": "I bought those boat tickets the day I saw that You Tube video. I knew we'd need a back-up plan. The boat was actually Plan C. The church was Plan B and Plan A was marrying her a long, long time ago. Pretty much the day I met her.", "order": 30301, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 4, "scene": 78, "line": "What an awesome party. The best wedding I've ever been too. I got six numbers. One more would have been a complete telephone number. This was epic. My Kleenex shoes were a huge conversation piece, but man my dogs are barking. [sticks his feet into the hotel ice machine] Whoo. My feet were so sweaty I can't even feel the cold. What a lovely hotel.", "order": 30302, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 5, "scene": 1, "line": "The fundamentals of business. The funda-mentals of business. 'Mental' is part of the word, I have underlined it. Because you're mental, if you don't have a good time. You have to enjoy it.", "order": 30303, "speaker": "Michael Scott"},
  {"season": 6, "episode": 5, "scene": 1, "line": "Well the 'fun' is in it. [conference room group chimes agreement.]", "order": 30304, "speaker": "Toby Flenderson"},
  {"season": 6, "episode": 5, "scene": 1, "line": "Get out.", "order": 30305, "speaker": "Michael Scott"},
  {"season": 6, "episode": 5, "scene": 1, "line": "[halfway out] Yeah, I know.", "order": 30306, "speaker": "Toby Flenderson"},
  {"season": 6, "episode": 5, "scene": 1, "line": "Yes. So, it all starts with a handshake. But you can't just go right to the selling, you need 'small talk.' What topics can you use for small talk?", "order": 30307, "speaker": "Michael Scott"},
  {"season": 6, "episode": 5, "scene": 1, "line": "Golf.", "order": 30308, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 5, "scene": 1, "line": "Mmhm.", "order": 30309, "speaker": "Michael Scott"},
  {"season": 6, "episode": 5, "scene": 1, "line": "Stock market.", "order": 30310, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 5, "scene": 1, "line": "Mmhm.", "order": 30311, "speaker": "Michael Scott"},
  {"season": 6, "episode": 5, "scene": 1, "line": "Dave Matthews.", "order": 30312, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 5, "scene": 1, "line": "Yes, what else?", "order": 30313, "speaker": "Michael Scott"},
  {"season": 6, "episode": 5, "scene": 1, "line": "Uh, small things. Peas, ball bearings, dimes...", "order": 30314, "speaker": "Creed Bratton"},
  {"season": 6, "episode": 5, "scene": 1, "line": "No.", "order": 30315, "speaker": "Michael Scott"},
  {"season": 6, "episode": 5, "scene": 1, "line": "The weekend!", "order": 30316, "speaker": "Meredith Palmer"},
  {"season": 6, "episode": 5, "scene": 1, "line": "Yeah! That's good! Come on up! Meredith, come up here. Let's do a little something. So Meredith and I have just started conversing and I will say, 'So Meredith, how was your weekend, what did you do?'", "order": 30317, "speaker": "Michael Scott"},
  {"season": 6, "episode": 5, "scene": 1, "line": "Well I caught my son taking a dump on the upper part of the toilet.", "order": 30318, "speaker": "Meredith Palmer"},
  {"season": 6, "episode": 5, "scene": 1, "line": "All right...", "order": 30319, "speaker": "Michael Scott"},
  {"season": 6, "episode": 5, "scene": 1, "line": "He calls it an upper decker.", "order": 30320, "speaker": "Meredith Palmer"},
  {"season": 6, "episode": 5, "scene": 1, "line": "Okay, okay. God. What you people don't know about business, I could fill a book with.", "order": 30321, "speaker": "Michael Scott"},
  {"season": 6, "episode": 5, "scene": 1, "line": "Then do it.", "order": 30322, "speaker": "Ryan Howard"},
  {"season": 6, "episode": 5, "scene": 1, "line": "What?", "order": 30323, "speaker": "Michael Scott"},
  {"season": 6, "episode": 5, "scene": 1, "line": "Write a book.", "order": 30324, "speaker": "Ryan Howard"},
  {"season": 6, "episode": 5, "scene": 2, "line": "[into mini recorder] The fundamentals of business by Michael Scott. Over one billion sold. More than the Bible, I'm not surprised. Chapter one. The businessman...", "order": 30325, "speaker": "Michael Scott"},
  {"season": 6, "episode": 5, "scene": 3, "line": "[Michael walks in office, man waits on couch] Mr. Grotti, this is Michael Scott. He's the person you should talk to.", "order": 30326, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 5, "scene": 3, "line": "Oh hi. I'm sorry, just a sec. [whispers] Erin, you're supposed to be the gatekeeper, do you have any idea how valuable my time is?", "order": 30327, "speaker": "Michael Scott"},
  {"season": 6, "episode": 5, "scene": 3, "line": "In your schedule it just says nine til noon is 'creative space' and I thought this could be part of that.", "order": 30328, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 5, "scene": 3, "line": "Do you know how creative space works? Okay I just cancelled my afternoon.", "order": 30329, "speaker": "Michael Scott"},
  {"season": 6, "episode": 5, "scene": 3, "line": "You don't have anything in the afternoon. It just says 'free play.'", "order": 30330, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 5, "scene": 3, "line": "Push free play til tomorrow morning. [to Grotti] Hi. Sorry. Crazy day. You're seeing how the sausage gets made.", "order": 30331, "speaker": "Michael Scott"},
  {"season": 6, "episode": 5, "scene": 3, "line": "Ah.", "order": 30332, "speaker": "Grotti"},
  {"season": 6, "episode": 5, "scene": 3, "line": "Come in the conference room and I will show you a finished sausage.", "order": 30333, "speaker": "Michael Scott"},
  {"season": 6, "episode": 5, "scene": 4, "line": "As a manager of business, you have a lot of pride.", "order": 30334, "speaker": "Grotti"},
  {"season": 6, "episode": 5, "scene": 4, "line": "Mmhm.", "order": 30335, "speaker": "Michael Scott"},
  {"season": 6, "episode": 5, "scene": 4, "line": "But you also got a lot of responsibility", "order": 30336, "speaker": "Grotti"},
  {"season": 6, "episode": 5, "scene": 4, "line": "Yep.", "order": 30337, "speaker": "Michael Scott"},
  {"season": 6, "episode": 5, "scene": 4, "line": "None greater perhaps, than your need to be sure, that your small or large business is secure in the event of a covered loss.", "order": 30338, "speaker": "Grotti"},
  {"season": 6, "episode": 5, "scene": 4, "line": "Okay.", "order": 30339, "speaker": "Michael Scott"},
  {"season": 6, "episode": 5, "scene": 5, "line": "There is nothing more insulting to a great salesman, than having to listen to a bad salesman. It's like a great basketball player having to listen to a bad basketball player.", "order": 30340, "speaker": "Michael Scott"},
  {"season": 6, "episode": 5, "scene": 6, "line": "Jim's gone on his honeymoon. So I started borrowing his office to fart in. Then one day I came in and I just stayed. Cause this place is awesome. It feels like home now. Even better than my home. My home sucks.", "order": 30341, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 5, "scene": 7, "line": "What do you think?", "order": 30342, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 5, "scene": 7, "line": "I think you're right. It definitely looks suspicious. And his southern Italian heritage raises some flags.", "order": 30343, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 5, "scene": 7, "line": "God forbid you... should have a fire in the warehouse.", "order": 30344, "speaker": "Grotti"},
  {"season": 6, "episode": 5, "scene": 7, "line": "Oh yup. Yeah, definitely. All that paper burning up.", "order": 30345, "speaker": "Michael Scott"},
  {"season": 6, "episode": 5, "scene": 7, "line": "Yeah, and a truck, goes off the side of the road, there's injury.", "order": 30346, "speaker": "Grotti"},
  {"season": 6, "episode": 5, "scene": 7, "line": "Mmhm, I hear you. The truck.", "order": 30347, "speaker": "Michael Scott"},
  {"season": 6, "episode": 5, "scene": 7, "line": "You will be hearing from me Mr. Scott.", "order": 30348, "speaker": "Grotti"},
  {"season": 6, "episode": 5, "scene": 7, "line": "Okay, well.", "order": 30349, "speaker": "Michael Scott"},
  {"season": 6, "episode": 5, "scene": 7, "line": "I can be very very persistent.", "order": 30350, "speaker": "Grotti"},
  {"season": 6, "episode": 5, "scene": 7, "line": "Do your worst. [they shake hands]", "order": 30351, "speaker": "Michael Scott"},
  {"season": 6, "episode": 5, "scene": 7, "line": "[Grotti knocks over coat stand grabbing his overcoat] Oh, great.", "order": 30352, "speaker": "Michael Scott"},
  {"season": 6, "episode": 5, "scene": 7, "line": "Would you look at that people? What an unpredictable world we live in, huh?", "order": 30353, "speaker": "Grotti"},
  {"season": 6, "episode": 5, "scene": 7, "line": "Mmhm.", "order": 30354, "speaker": "Michael Scott"},
  {"season": 6, "episode": 5, "scene": 8, "line": "What happened in there?", "order": 30355, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 5, "scene": 8, "line": "Nothing, other than once again, I am just thankful that I am a paper salesman.", "order": 30356, "speaker": "Michael Scott"},
  {"season": 6, "episode": 5, "scene": 8, "line": "Did he threaten you?", "order": 30357, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 5, "scene": 8, "line": "No Dwight, not everything is a threat.", "order": 30358, "speaker": "Michael Scott"},
  {"season": 6, "episode": 5, "scene": 8, "line": "Mobsters are!", "order": 30359, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 5, "scene": 8, "line": "There is no such things as monsters.", "order": 30360, "speaker": "Michael Scott"},
  {"season": 6, "episode": 5, "scene": 8, "line": "He drives an SUV!", "order": 30361, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 5, "scene": 8, "line": "I knew it! More trunk space. Or should I say, corpse space.", "order": 30362, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 5, "scene": 8, "line": "Hey guys, I drive a SUV, does that mean I'm in the mob?", "order": 30363, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 5, "scene": 8, "line": "No, not that, by itself. But look at all the facts. He seems like a mobster.", "order": 30364, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 5, "scene": 8, "line": "Wait, when did we start talking about the mob? The guy was trying to sell me insurance.", "order": 30365, "speaker": "Michael Scott"},
  {"season": 6, "episode": 5, "scene": 8, "line": "All mobsters have a front, sometimes it's selling insurance, sometimes it's waste management or sanitation.", "order": 30366, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 5, "scene": 8, "line": "For the record, not all Italian-Americans are in the Mafia.", "order": 30367, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 5, "scene": 8, "line": "I think, he just seemed like he was just trying to sell me insurance.", "order": 30368, "speaker": "Michael Scott"},
  {"season": 6, "episode": 5, "scene": 8, "line": "Yeah, buy my insurance or I'll burn your warehouse down!", "order": 30369, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 5, "scene": 8, "line": "Exactly.", "order": 30370, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 5, "scene": 8, "line": "He did talk about a fire in the warehouse... and he also vaguely threatened me with testicular cancer.", "order": 30371, "speaker": "Michael Scott"},
  {"season": 6, "episode": 5, "scene": 8, "line": "Uhh. All right, who else is here? [looks around]", "order": 30372, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 5, "scene": 9, "line": "Pam and Jim are on their honeymoon. So there's not the usual balance between 'sane and others.' Toby has mentally checked out since June. It's a very dangerous time. The 'coalition for reason' is extremely weak.", "order": 30373, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 5, "scene": 10, "line": "Oscar says I checked out huh? Huh. [nods head]", "order": 30374, "speaker": "Toby Flenderson"},
  {"season": 6, "episode": 5, "scene": 11, "line": "[over chatter] Hey, hey hey, calm down. Calm down. Calm down. Ryan, you lived in New York, what do you think?", "order": 30375, "speaker": "Michael Scott"},
  {"season": 6, "episode": 5, "scene": 11, "line": "Well first of all, there is no such thing as 'The Mafia.'", "order": 30376, "speaker": "Ryan Howard"},
  {"season": 6, "episode": 5, "scene": 11, "line": "Okay.", "order": 30377, "speaker": "Michael Scott"},
  {"season": 6, "episode": 5, "scene": 11, "line": "What you have are specific families. What's the guy's last name?", "order": 30378, "speaker": "Ryan Howard"},
  {"season": 6, "episode": 5, "scene": 11, "line": "Um it is, Grotti.", "order": 30379, "speaker": "Michael Scott"},
  {"season": 6, "episode": 5, "scene": 11, "line": "[groans] Oh no. Fabulous.", "order": 30380, "speaker": "Andy Bernard & Dwight Schrute"},
  {"season": 6, "episode": 5, "scene": 11, "line": "What? What?", "order": 30381, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 5, "scene": 11, "line": "It's John Gotti, you idiot!", "order": 30382, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 5, "scene": 11, "line": "It's, it's a completely different name!", "order": 30383, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 5, "scene": 11, "line": "So he won't get caught!", "order": 30384, "speaker": "Phyllis Vance"},
  {"season": 6, "episode": 5, "scene": 11, "line": "Yeah. It's pretty close.", "order": 30385, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 5, "scene": 11, "line": "No, what are you talking about, what mobster would change his name from Gotti to Grotti. It weakens it.", "order": 30386, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 5, "scene": 11, "line": "No I disagree. 'R' is among the most menacing of sounds. That's why they call it 'murder.' And not 'muck-duck.'", "order": 30387, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 5, "scene": 11, "line": "Okay too many different words coming at me from too many different sentences.", "order": 30388, "speaker": "Michael Scott"},
  {"season": 6, "episode": 5, "scene": 11, "line": "Lock your door!", "order": 30389, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 5, "scene": 11, "line": "I'm not gonna lock my door. [door closes. Then clicks locked]", "order": 30390, "speaker": "Michael Scott"},
  {"season": 6, "episode": 5, "scene": 12, "line": "Hello?", "order": 30391, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 5, "scene": 12, "line": "Jim? It's Oscar. I'm so sorry to be calling you on your honeymoon.", "order": 30392, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 5, "scene": 12, "line": "Oscar! Uh, what is going on?", "order": 30393, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 5, "scene": 12, "line": "It's Michael, he thinks he's being shaken down by the mob. I don't know how you usually handle this.", "order": 30394, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 5, "scene": 12, "line": "Look, We're in Puerto Rico, so-", "order": 30395, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 5, "scene": 12, "line": "Hey Oscar. It's Pam. Hey. We're on our honeymoon.", "order": 30396, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 5, "scene": 12, "line": "Pam, I'm sorry--", "order": 30397, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 5, "scene": 12, "line": "Unless someone very close to us is in immediate physical danger, you should not be calling us.", "order": 30398, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 5, "scene": 12, "line": "You're right. You're right. [Pam hangs up] Oh, okay bye.", "order": 30399, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 5, "scene": 13, "line": "It's Grotti. He's following up.", "order": 30400, "speaker": "Michael Scott"},
  {"season": 6, "episode": 5, "scene": 13, "line": "Already? This, this guy is persistent!", "order": 30401, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 5, "scene": 13, "line": "[reads email] 'I feel that you will regret missing this great opportunity to be in business.'", "order": 30402, "speaker": "Michael Scott"},
  {"season": 6, "episode": 5, "scene": 13, "line": "That's bad.", "order": 30403, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 5, "scene": 13, "line": "Yeah.", "order": 30404, "speaker": "Michael Scott"},
  {"season": 6, "episode": 5, "scene": 13, "line": "That's bad.", "order": 30405, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 5, "scene": 13, "line": "Yeah. What are my options here? Do I just ignore it, or?", "order": 30406, "speaker": "Michael Scott"},
  {"season": 6, "episode": 5, "scene": 13, "line": "Yeah right! You heard him! He's gonna burn down the warehouse or run one of our trucks off the road.", "order": 30407, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 5, "scene": 13, "line": "Okay, I'm calling the police.", "order": 30408, "speaker": "Michael Scott"},
  {"season": 6, "episode": 5, "scene": 13, "line": "[hangs up, rips cord from phone] That is the stupidest thing you could do right now!", "order": 30409, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 5, "scene": 13, "line": "He's right. Cops can't do anything until a crime has been reported.", "order": 30410, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 5, "scene": 13, "line": "All right.", "order": 30411, "speaker": "Michael Scott"},
  {"season": 6, "episode": 5, "scene": 13, "line": "Not only that, but if they find out you snitched, you get a dead horses chopped off head in your bed!", "order": 30412, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 5, "scene": 13, "line": "Shh!", "order": 30413, "speaker": "Michael Scott"},
  {"season": 6, "episode": 5, "scene": 13, "line": "You know what?", "order": 30414, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 5, "scene": 13, "line": "That's not gonna happen.", "order": 30415, "speaker": "Michael Scott"},
  {"season": 6, "episode": 5, "scene": 13, "line": "That's an exaggeration.", "order": 30416, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 5, "scene": 13, "line": "That's how it works!", "order": 30417, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 5, "scene": 13, "line": "What am I supposed to do here?", "order": 30418, "speaker": "Michael Scott"},
  {"season": 6, "episode": 5, "scene": 13, "line": "When somebody threatens you, you give in right away. Okay you need to buy insurance from this guy and get him off your back", "order": 30419, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 5, "scene": 13, "line": "I was thinking exactly the same thing.", "order": 30420, "speaker": "Michael Scott"},
  {"season": 6, "episode": 5, "scene": 13, "line": "No, criminals are like raccoons. Okay, you give 'em a taste of cat food pretty soon they'll be back for the whole cat.", "order": 30421, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 5, "scene": 13, "line": "Dwight...", "order": 30422, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 5, "scene": 13, "line": "The only way to defeat a bully is to stand up to him. Trust me, I have bullied a lot of people.", "order": 30423, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 5, "scene": 13, "line": "I don't know, I don't know about that.", "order": 30424, "speaker": "Michael Scott"},
  {"season": 6, "episode": 5, "scene": 13, "line": "Wait let's hear him out, this is interesting.", "order": 30425, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 5, "scene": 13, "line": "Here's what we do. We meet him in a public place. Ask him to lunch or something like that, some place he can't be openly violent.", "order": 30426, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 5, "scene": 13, "line": "Okay.", "order": 30427, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 5, "scene": 13, "line": "Let him know you're not the typical kind of guy that he can shake down. That you're stubborn. That you might even be a little bit dangerous.", "order": 30428, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 5, "scene": 13, "line": "[snaps fingers] I like this plan. I'd like to officially withdraw my plan.", "order": 30429, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 5, "scene": 13, "line": "Hold on, hold on! Just-", "order": 30430, "speaker": "Michael Scott"},
  {"season": 6, "episode": 5, "scene": 13, "line": "No, no, no. My plan is out! We do this the hard way.", "order": 30431, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 5, "scene": 13, "line": "All right. I will meet with him, but I'm not going alone.", "order": 30432, "speaker": "Michael Scott"},
  {"season": 6, "episode": 5, "scene": 13, "line": "Well you're gonna have to. [overlaps] Dwight: We'll be right beside you.", "order": 30433, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 5, "scene": 13, "line": "What?", "order": 30434, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 5, "scene": 14, "line": "[Andy dressed as a mechanic, Cornell hat on] What are you wearing? Who's Pat?", "order": 30435, "speaker": "Michael Scott"},
  {"season": 6, "episode": 5, "scene": 14, "line": "Well if I'm gonna back you up, I need a weapon without drawing suspicion, and I have to justify it somehow so, I'm a mechanic with a tire thing.", "order": 30436, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 5, "scene": 14, "line": "Do you know how to use it?", "order": 30437, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 5, "scene": 14, "line": "To change tires, no. But it's metal, I can hit somebody with it.", "order": 30438, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 5, "scene": 14, "line": "Let's go, come on. [whispers] God!", "order": 30439, "speaker": "Michael Scott"},
  {"season": 6, "episode": 5, "scene": 14, "line": "Should I change?", "order": 30440, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 5, "scene": 14, "line": "You're wearing loafers!", "order": 30441, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 5, "scene": 14, "line": "Forget it! Forget it!", "order": 30442, "speaker": "Michael Scott"},
  {"season": 6, "episode": 5, "scene": 15, "line": "[Andy is playing with the tire iron] Take that thing off the table! Please!", "order": 30443, "speaker": "Michael Scott"},
  {"season": 6, "episode": 5, "scene": 15, "line": "Well then I can't use it. I'm just gonna hide it.", "order": 30444, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 5, "scene": 15, "line": "Hey. Bathroom checks out clean. Nothing behind the toilet except for this roach motel.", "order": 30445, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 5, "scene": 15, "line": "Oh! God! [smacks roaches]", "order": 30446, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 5, "scene": 15, "line": "Oh my God!", "order": 30447, "speaker": "Michael Scott"},
  {"season": 6, "episode": 5, "scene": 15, "line": "You'll never kill it that way. You want to separate the head from the thorax-", "order": 30448, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 5, "scene": 15, "line": "Guys, guys. Cool it. There he is, there he is.", "order": 30449, "speaker": "Michael Scott"},
  {"season": 6, "episode": 5, "scene": 16, "line": "Hello.", "order": 30450, "speaker": "Michael Scott"},
  {"season": 6, "episode": 5, "scene": 16, "line": "Mr. Scott.", "order": 30451, "speaker": "Grotti"},
  {"season": 6, "episode": 5, "scene": 16, "line": "Mr. Grotti we meet again. These are my associates.", "order": 30452, "speaker": "Michael Scott"},
  {"season": 6, "episode": 5, "scene": 16, "line": "Hi. Angelo Grotti.", "order": 30453, "speaker": "Grotti"},
  {"season": 6, "episode": 5, "scene": 16, "line": "Hi.", "order": 30454, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 5, "scene": 16, "line": "Hello.", "order": 30455, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 5, "scene": 16, "line": "So, you got this table?", "order": 30456, "speaker": "Grotti"},
  {"season": 6, "episode": 5, "scene": 16, "line": "Yes.", "order": 30457, "speaker": "Michael Scott"},
  {"season": 6, "episode": 5, "scene": 16, "line": "This is one of those half booths, can't-decide-what-it-is type of thing.", "order": 30458, "speaker": "Grotti"},
  {"season": 6, "episode": 5, "scene": 16, "line": "Well.", "order": 30459, "speaker": "Michael Scott"},
  {"season": 6, "episode": 5, "scene": 16, "line": "Waitress, we're gonna sit over here.", "order": 30460, "speaker": "Grotti"},
  {"season": 6, "episode": 5, "scene": 16, "line": "That's fine.", "order": 30461, "speaker": "Waitress"},
  {"season": 6, "episode": 5, "scene": 16, "line": "Okay.", "order": 30462, "speaker": "Michael Scott"},
  {"season": 6, "episode": 5, "scene": 17, "line": "[answers phone] Hello.", "order": 30463, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 5, "scene": 17, "line": "Hello Mr. Halpert. I'm calling from the identity theft department at Capital One. We've detected some unusual activity on your credit card.", "order": 30464, "speaker": "Credit card rep"},
  {"season": 6, "episode": 5, "scene": 17, "line": "Oh man, do you think it was stolen?", "order": 30465, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 5, "scene": 17, "line": "First would you mind verifying your home address?", "order": 30466, "speaker": "Rep"},
  {"season": 6, "episode": 5, "scene": 17, "line": "Um, yes. [looks at Jim's pay stub] Um, 383 Linden Ave., Scranton PA", "order": 30467, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 5, "scene": 17, "line": "And may I have the last four numbers of your Social Security Number?", "order": 30468, "speaker": "Rep"},
  {"season": 6, "episode": 5, "scene": 17, "line": "Six-six-five-zero.", "order": 30469, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 5, "scene": 17, "line": "Well Mr. Halpert. You're obviously not in San Juan Puerto Rico.", "order": 30470, "speaker": "Rep"},
  {"season": 6, "episode": 5, "scene": 17, "line": "Wait a minute. Yes I am.", "order": 30471, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 5, "scene": 17, "line": "I'm going to go ahead and put a hold on your card.", "order": 30472, "speaker": "Rep"},
  {"season": 6, "episode": 5, "scene": 17, "line": "No. That... I, I think that we should let the criminal use the card a little longer.", "order": 30473, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 5, "scene": 17, "line": "Very funny sir. We'll get a new card out to you right away.", "order": 30474, "speaker": "Rep"},
  {"season": 6, "episode": 5, "scene": 17, "line": "No-", "order": 30475, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 5, "scene": 17, "line": "Have a nice day, and thank you!", "order": 30476, "speaker": "Rep"},
  {"season": 6, "episode": 5, "scene": 17, "line": "Shoot.", "order": 30477, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 5, "scene": 18, "line": "If you want to supplement your coverage, we can do that. If you want to replace your current coverage, all the better. Ah, you seem like a nice guy.", "order": 30478, "speaker": "Grotti"},
  {"season": 6, "episode": 5, "scene": 18, "line": "Oh he's not that nice.", "order": 30479, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 5, "scene": 18, "line": "That's not true.", "order": 30480, "speaker": "Michael Scott"},
  {"season": 6, "episode": 5, "scene": 18, "line": "Hmm. Very true.", "order": 30481, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 5, "scene": 18, "line": "Okay shut up.", "order": 30482, "speaker": "Michael Scott"},
  {"season": 6, "episode": 5, "scene": 18, "line": "Have you decided?", "order": 30483, "speaker": "Waitress"},
  {"season": 6, "episode": 5, "scene": 18, "line": "Yeah, I'll have the linguini, red sauce on the side. If the sauce does not come on the side, I will send it back. I want garlic bread, toasted, not burnt. If it comes burnt, I will send it back.", "order": 30484, "speaker": "Grotti"},
  {"season": 6, "episode": 5, "scene": 18, "line": "Okay then. And for you sir?", "order": 30485, "speaker": "Waitress"},
  {"season": 6, "episode": 5, "scene": 18, "line": "I will have the gabba-gool.", "order": 30486, "speaker": "Michael Scott"},
  {"season": 6, "episode": 5, "scene": 18, "line": "The... what?", "order": 30487, "speaker": "Waitress"},
  {"season": 6, "episode": 5, "scene": 18, "line": "The gabba-gool.", "order": 30488, "speaker": "Michael Scott"},
  {"season": 6, "episode": 5, "scene": 18, "line": "I don't really know what that is.", "order": 30489, "speaker": "Waitress"},
  {"season": 6, "episode": 5, "scene": 18, "line": "[with Soprano's inflection] You know, gabba-gool.", "order": 30490, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 5, "scene": 18, "line": "I don't, I don't have to have that.", "order": 30491, "speaker": "Michael Scott"},
  {"season": 6, "episode": 5, "scene": 18, "line": "What he's trying to say is, Gabba. Gool.", "order": 30492, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 5, "scene": 18, "line": "Guys, guys-", "order": 30493, "speaker": "Michael Scott"},
  {"season": 6, "episode": 5, "scene": 18, "line": "I don't really think that we have that.", "order": 30494, "speaker": "Waitress"},
  {"season": 6, "episode": 5, "scene": 18, "line": "That's okay.", "order": 30495, "speaker": "Michael Scott"},
  {"season": 6, "episode": 5, "scene": 18, "line": "Bring him the gabba-gool!", "order": 30496, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 5, "scene": 18, "line": "Shh. I will have the spaghetti, with a side salad.", "order": 30497, "speaker": "Michael Scott"},
  {"season": 6, "episode": 5, "scene": 18, "line": "Okay.", "order": 30498, "speaker": "Waitress"},
  {"season": 6, "episode": 5, "scene": 18, "line": "If the salad is on top, I send it back.", "order": 30499, "speaker": "Michael Scott"},
  {"season": 6, "episode": 5, "scene": 19, "line": "Why would you cancel Jim's credit cards?", "order": 30500, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 5, "scene": 19, "line": "I usually can think quick on my feet, but they were so fast on the phone.", "order": 30501, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 5, "scene": 19, "line": "This constitutes identity fraud.", "order": 30502, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 5, "scene": 19, "line": "Oh God. I wouldn't last in jail Oscar. I'm not like you.", "order": 30503, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 5, "scene": 19, "line": "What's that supposed to mean?", "order": 30504, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 5, "scene": 19, "line": "Oh you don't know about jail? Oh you would love jail.", "order": 30505, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 5, "scene": 19, "line": "Why would I love jail?", "order": 30506, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 5, "scene": 19, "line": "Because... You would love it.", "order": 30507, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 5, "scene": 20, "line": "I don't think our company actually needs any more insurance. So I am out.", "order": 30508, "speaker": "Michael Scott"},
  {"season": 6, "episode": 5, "scene": 20, "line": "Look closely Michael. I feel there's a plan here for you.", "order": 30509, "speaker": "Grotti"},
  {"season": 6, "episode": 5, "scene": 20, "line": "Maybe we have a plan for you?", "order": 30510, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 5, "scene": 20, "line": "How about you? Maybe you can use supplemental coverage of some kind. Anybody can get hurt! You always think, it can't happen to you, and [drops hand loudly on table] Think about it.", "order": 30511, "speaker": "Grotti"},
  {"season": 6, "episode": 5, "scene": 20, "line": "[approaches with child] Excuse me. Sorry to bother you. Are you a mechanic?", "order": 30512, "speaker": "Woman"},
  {"season": 6, "episode": 5, "scene": 20, "line": "Yeeeeah.", "order": 30513, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 5, "scene": 20, "line": "My battery is dead, I've got my kid, can you please help?", "order": 30514, "speaker": "Woman"},
  {"season": 6, "episode": 5, "scene": 20, "line": "Yes I can.", "order": 30515, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 5, "scene": 20, "line": "No, no no, no. Come on. I'm sorry, we're having our salad.", "order": 30516, "speaker": "Michael Scott"},
  {"season": 6, "episode": 5, "scene": 20, "line": "Come on! Lady in distress? Go! Go!", "order": 30517, "speaker": "Grotti"},
  {"season": 6, "episode": 5, "scene": 20, "line": "Okay!", "order": 30518, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 5, "scene": 20, "line": "Thank you so much, I really appreciate it.", "order": 30519, "speaker": "Woman"},
  {"season": 6, "episode": 5, "scene": 20, "line": "Hey, do you need any help?", "order": 30520, "speaker": "Michael Scott"},
  {"season": 6, "episode": 5, "scene": 20, "line": "I'm sure he can handle a simple jump-start. Now come on, sit down.", "order": 30521, "speaker": "Grotti"},
  {"season": 6, "episode": 5, "scene": 20, "line": "He's a good mechanic.", "order": 30522, "speaker": "Michael Scott"},
  {"season": 6, "episode": 5, "scene": 20, "line": "Where were we?", "order": 30523, "speaker": "Grotti"},
  {"season": 6, "episode": 5, "scene": 20, "line": "I don't...", "order": 30524, "speaker": "Michael Scott"},
  {"season": 6, "episode": 5, "scene": 20, "line": "He was trying to force you to decide on a policy.", "order": 30525, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 5, "scene": 20, "line": "Okay, okay okay.", "order": 30526, "speaker": "Michael Scott"},
  {"season": 6, "episode": 5, "scene": 20, "line": "So we're choosing...", "order": 30527, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 5, "scene": 20, "line": "Yep, all right.", "order": 30528, "speaker": "Michael Scott"},
  {"season": 6, "episode": 5, "scene": 20, "line": "Check out Dental?", "order": 30529, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 5, "scene": 20, "line": "Put it down.", "order": 30530, "speaker": "Michael Scott"},
  {"season": 6, "episode": 5, "scene": 21, "line": "Black goes on the red. With the... If we... Positive... Mo- it being a motor drive, it's probably down.", "order": 30531, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 5, "scene": 21, "line": "He seems bad at this.", "order": 30532, "speaker": "Kid"},
  {"season": 6, "episode": 5, "scene": 21, "line": "You want to do this junior? I didn't think so. Sorry. It's kind of a long day at the... mechanic store. [Andy places connections wrong, smoke and explosion set off] Aaaah! You got a leaky spark tube.", "order": 30533, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 5, "scene": 21, "line": "What?!?", "order": 30534, "speaker": "Woman"},
  {"season": 6, "episode": 5, "scene": 21, "line": "So your car's totaled. Uh, you're just gonna want to get a refund on that. Or my guy could do it. He's great. But uh, I can't do that for you. I work exclusively on motorcycles. [Andy walks away, woman is exasperated]", "order": 30535, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 5, "scene": 22, "line": "Now if you could just sign this letter of intent, I'll bring this back to my boss, and we can get this in motion. [Andy clears throat loudly] You okay, Pat?", "order": 30536, "speaker": "Grotti"},
  {"season": 6, "episode": 5, "scene": 22, "line": "Yeah. Just thinking about how, uh, I had this car, this Italian car, and I was driving it, and it kept telling me how much it needed oil, but I wouldn't give it any oil. And then, one day it exploded and it killed everyone and that's what I'm afraid of.", "order": 30537, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 5, "scene": 22, "line": "Aren't you a mechanic? Why wouldn't you put oil in the car?", "order": 30538, "speaker": "Grotti"},
  {"season": 6, "episode": 5, "scene": 22, "line": "It was before, my tech- my technical training.", "order": 30539, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 5, "scene": 22, "line": "Don't do it!", "order": 30540, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 5, "scene": 22, "line": "Do it.", "order": 30541, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 5, "scene": 22, "line": "Don't.", "order": 30542, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 5, "scene": 22, "line": "Just do it.", "order": 30543, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 5, "scene": 22, "line": "Okay.", "order": 30544, "speaker": "Michael Scott"},
  {"season": 6, "episode": 5, "scene": 22, "line": "Look Mike, I don't know what your friends are telling you, but you have to decide for yourself. Are these guys gonna take care of your things if you die tomorrow?", "order": 30545, "speaker": "Grotti"},
  {"season": 6, "episode": 5, "scene": 22, "line": "Yes.", "order": 30546, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 5, "scene": 22, "line": "Okay.", "order": 30547, "speaker": "Michael Scott"},
  {"season": 6, "episode": 5, "scene": 23, "line": "I don't understand, why would you buy a policy?", "order": 30548, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 5, "scene": 23, "line": "It's just the cost of a cup of coffee an hour.", "order": 30549, "speaker": "Michael Scott"},
  {"season": 6, "episode": 5, "scene": 23, "line": "You were man enough to back down Michael, I'm proud of you.", "order": 30550, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 5, "scene": 23, "line": "I had to make a snap decision Dwight.", "order": 30551, "speaker": "Michael Scott"},
  {"season": 6, "episode": 5, "scene": 23, "line": "It wasn't a snap decision, you were sitting there for an hour.", "order": 30552, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 5, "scene": 23, "line": "It was a lot of snap decisions.", "order": 30553, "speaker": "Michael Scott"},
  {"season": 6, "episode": 5, "scene": 23, "line": "Do you know what 'snap decision' means?", "order": 30554, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 5, "scene": 23, "line": "Yes!", "order": 30555, "speaker": "Michael Scott"},
  {"season": 6, "episode": 5, "scene": 23, "line": "It means like this. [snaps fingers]", "order": 30556, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 5, "scene": 23, "line": "Just get in the car.", "order": 30557, "speaker": "Michael Scott"},
  {"season": 6, "episode": 5, "scene": 24, "line": "Hello?", "order": 30558, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 5, "scene": 24, "line": "Jim?", "order": 30559, "speaker": "Michael Scott"},
  {"season": 6, "episode": 5, "scene": 24, "line": "Michael?", "order": 30560, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 5, "scene": 24, "line": "Oh thank God.", "order": 30561, "speaker": "Michael Scott"},
  {"season": 6, "episode": 5, "scene": 24, "line": "How did you get this number? Michael, we're on a catamaran.", "order": 30562, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 5, "scene": 24, "line": "It wasn't easy. I had to tell the hotel it was a medical emergency. I chose massive coronary, cause you told me that your Dad had a bad heart. Listen man, I, I got a problem, I think I'm in trouble with the mob. Or a major insurance carrier.", "order": 30563, "speaker": "Michael Scott"},
  {"season": 6, "episode": 5, "scene": 24, "line": "That sounds bad.", "order": 30564, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 5, "scene": 24, "line": "Yeah, I know and you usually can get out of stuff like this, so I'm turning to you my friend.", "order": 30565, "speaker": "Michael Scott"},
  {"season": 6, "episode": 5, "scene": 24, "line": "I'm gonna help you through it all right?", "order": 30566, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 5, "scene": 24, "line": "Okay!", "order": 30567, "speaker": "Michael Scott"},
  {"season": 6, "episode": 5, "scene": 24, "line": "All you're gonna need to [faking a bad connection] and- it- and then go to--", "order": 30568, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 5, "scene": 24, "line": "Jim? Are you?", "order": 30569, "speaker": "Michael Scott"},
  {"season": 6, "episode": 5, "scene": 24, "line": "And then you'll be saved.", "order": 30570, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 5, "scene": 24, "line": "What? Wait, I didn't hear a thing you just said.", "order": 30571, "speaker": "Michael Scott"},
  {"season": 6, "episode": 5, "scene": 24, "line": "Just [drops] and then you'll be saved.", "order": 30572, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 5, "scene": 24, "line": "No! God! I missed, I missed the important part again!", "order": 30573, "speaker": "Michael Scott"},
  {"season": 6, "episode": 5, "scene": 24, "line": "A- ah--", "order": 30574, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 5, "scene": 24, "line": "No! Oh my God!", "order": 30575, "speaker": "Michael Scott"},
  {"season": 6, "episode": 5, "scene": 24, "line": "And you'll be saved.", "order": 30576, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 5, "scene": 24, "line": "No, Jim please, repeat what you're saying! I can't understand you!", "order": 30577, "speaker": "Michael Scott"},
  {"season": 6, "episode": 5, "scene": 24, "line": "I [drops out] at the Bermuda Triangle. An- M- please don't call again.", "order": 30578, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 5, "scene": 24, "line": "Jim?!? [dial tone] Oh my God.", "order": 30579, "speaker": "Michael Scott"},
  {"season": 6, "episode": 5, "scene": 25, "line": "Hey uh, question for you. I recently purchased some insurance that I can't afford given my present salary. Is there anything accounting-wise I can do to sort of make it all go away?", "order": 30580, "speaker": "Michael Scott"},
  {"season": 6, "episode": 5, "scene": 25, "line": "Accounting-wise, no. But phone-wise, just call up and cancel it.", "order": 30581, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 5, "scene": 25, "line": "Oh no. Um. What about this Cash For Clunkers thing?", "order": 30582, "speaker": "Michael Scott"},
  {"season": 6, "episode": 5, "scene": 25, "line": "Just- no. No.", "order": 30583, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 5, "scene": 25, "line": "All right. Well, it was a thought. Thanks.", "order": 30584, "speaker": "Michael Scott"},
  {"season": 6, "episode": 5, "scene": 26, "line": "We have let Michael down, and it's 85 percent your fault.", "order": 30585, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 5, "scene": 26, "line": "He's alive. So you're welcome.", "order": 30586, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 5, "scene": 26, "line": "Not on the inside he's not. Look at his life! Broke! Living in fear! No friends, dead end job.", "order": 30587, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 5, "scene": 26, "line": "Yeah, some of that existed before.", "order": 30588, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 5, "scene": 26, "line": "Not the living in fear, that's new.", "order": 30589, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 5, "scene": 26, "line": "You're right, that is new.", "order": 30590, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 5, "scene": 26, "line": "Yes. He's got to stand up to this mafia guy.", "order": 30591, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 5, "scene": 26, "line": "Well I don't see that happening.", "order": 30592, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 5, "scene": 26, "line": "Me neither. Not the way things are now. But what if Michael felt no fear toward the mafia guy?", "order": 30593, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 5, "scene": 26, "line": "Are you saying-", "order": 30594, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 5, "scene": 26, "line": "Yeah...", "order": 30595, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 5, "scene": 26, "line": "That we surgically remove the fear center from Michael's brain?", "order": 30596, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 5, "scene": 26, "line": "What is wrong with you? I am talking about convincing Michael that the guy's not mafia!", "order": 30597, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 5, "scene": 26, "line": "That seems a little far-fetched.", "order": 30598, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 5, "scene": 26, "line": "Well more far-fetched than a mobster walking into a paper company for a low-level shakedown? And that happened.", "order": 30599, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 5, "scene": 27, "line": "Michael. Incredible news. Grotti is clean.", "order": 30600, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 5, "scene": 27, "line": "No. He's not. He's just good. Nothing sticks to him. You still don't understand how this works.", "order": 30601, "speaker": "Michael Scott"},
  {"season": 6, "episode": 5, "scene": 27, "line": "No, Michael. What we're trying to say is, we made a mistake assuming he was mafia. I have a buddy who's a Fed, and we did a background check on the guy. His background is perfectly clean.", "order": 30602, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 5, "scene": 27, "line": "It's true, he's clean. I have a couple of friends still on the force. Checked with them. Ran his Fed friend up the flagpole to make sure he wasn't on the take. Turns out he's a totally lovely guy. Sweetest guy on the force really.", "order": 30603, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 5, "scene": 27, "line": "Class act. Boy scout.", "order": 30604, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 5, "scene": 27, "line": "But Grotti acts like he's mafia though.", "order": 30605, "speaker": "Michael Scott"},
  {"season": 6, "episode": 5, "scene": 27, "line": "He's trying to intimidate you to close sales. He's just a pushy salesman.", "order": 30606, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 5, "scene": 27, "line": "And he made us all look like chumps!", "order": 30607, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 5, "scene": 27, "line": "[grunts]", "order": 30608, "speaker": "Michael Scott"},
  {"season": 6, "episode": 5, "scene": 28, "line": "If there is one thing I hate more than the mafia it is a liar. I wish the mafia would go out and kill all the liars. Bury them in my yard. And I wouldn't tell the cops a thing. Not that I would be lying per se. But I would just get really quiet, all of a sudden.", "order": 30609, "speaker": "Michael Scott"},
  {"season": 6, "episode": 5, "scene": 29, "line": "This is Grotti.", "order": 30610, "speaker": "Grotti"},
  {"season": 6, "episode": 5, "scene": 29, "line": "This is Scott.", "order": 30611, "speaker": "Michael Scott"},
  {"season": 6, "episode": 5, "scene": 29, "line": "Oh! Great. Michael, I'm finishing up your paperwork right now.", "order": 30612, "speaker": "Grotti"},
  {"season": 6, "episode": 5, "scene": 29, "line": "Oh really? Is that supposed to scare me?", "order": 30613, "speaker": "Michael Scott"},
  {"season": 6, "episode": 5, "scene": 29, "line": "I, I thought you'd be pleased.", "order": 30614, "speaker": "Grotti"},
  {"season": 6, "episode": 5, "scene": 29, "line": "Well you thought wrong. Because I am not pleased. I'm actually kind of PO'd.", "order": 30615, "speaker": "Michael Scott"},
  {"season": 6, "episode": 5, "scene": 29, "line": "What?", "order": 30616, "speaker": "Grotti"},
  {"season": 6, "episode": 5, "scene": 29, "line": "I think you know exactly why, because you were trying to scare me into buying insurance.", "order": 30617, "speaker": "Michael Scott"},
  {"season": 6, "episode": 5, "scene": 29, "line": "I don't get it. How was I scaring you?", "order": 30618, "speaker": "Grotti"},
  {"season": 6, "episode": 5, "scene": 29, "line": "I think you knew exactly what you were doing. And frankly I think you were being a total and utter jerk.", "order": 30619, "speaker": "Michael Scott"},
  {"season": 6, "episode": 5, "scene": 29, "line": "Whoa. Okay.", "order": 30620, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 5, "scene": 29, "line": "You suck!", "order": 30621, "speaker": "Michael Scott"},
  {"season": 6, "episode": 5, "scene": 29, "line": "Okay that's-", "order": 30622, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 5, "scene": 29, "line": "And I'm not gonna buy your stupid insurance.", "order": 30623, "speaker": "Michael Scott"},
  {"season": 6, "episode": 5, "scene": 29, "line": "That's good, let's wrap it up.", "order": 30624, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 5, "scene": 29, "line": "How about that? The only person that actually needs insurance is you, if you show your face around here again, got it?", "order": 30625, "speaker": "Michael Scott"},
  {"season": 6, "episode": 5, "scene": 29, "line": "Look, Michael, when we all calm down here, maybe at some point in the future, you change your mind, why don't you give me a call?", "order": 30626, "speaker": "Grotti"},
  {"season": 6, "episode": 5, "scene": 29, "line": "Doubt it. [disconnects call]", "order": 30627, "speaker": "Michael Scott"},
  {"season": 6, "episode": 5, "scene": 29, "line": "[relaxing] Oh man.", "order": 30628, "speaker": "Dwight Schrute & Andy Bernard"},
  {"season": 6, "episode": 5, "scene": 29, "line": "What a tool. [Dwight and Andy exchange looks] What?", "order": 30629, "speaker": "Michael Scott"},
  {"season": 6, "episode": 5, "scene": 29, "line": "Next time you look in the mirror, you're gonna be looking at a guy who stood down the mafia!", "order": 30630, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 5, "scene": 29, "line": "No. What do you mean?", "order": 30631, "speaker": "Michael Scott"},
  {"season": 6, "episode": 5, "scene": 29, "line": "We just told you he wasn't mafia, so you wouldn't be scared.", "order": 30632, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 5, "scene": 29, "line": "What?", "order": 30633, "speaker": "Michael Scott"},
  {"season": 6, "episode": 5, "scene": 29, "line": "You successfully backed down the mob!", "order": 30634, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 5, "scene": 29, "line": "You made the mafia apologize to you! You made the mafia be polite!", "order": 30635, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 5, "scene": 29, "line": "Oh man. I should be mad at you guys. But I'm not.", "order": 30636, "speaker": "Michael Scott"},
  {"season": 6, "episode": 5, "scene": 30, "line": "So I looked him in the eye and I said, 'Not today Grotti, Not today. And not tomorrow, and not the next day. Or the day after that. And you can tell all your friends that if I see them, then they're already dead.' I said something like that.", "order": 30637, "speaker": "Michael Scott"},
  {"season": 6, "episode": 5, "scene": 30, "line": "Very close.", "order": 30638, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 5, "scene": 30, "line": "Just to be clear, he backed down an insurance agent from Mutual of Harrisburg.", "order": 30639, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 5, "scene": 30, "line": "Erin?", "order": 30640, "speaker": "Michael Scott"},
  {"season": 6, "episode": 5, "scene": 30, "line": "Yes?", "order": 30641, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 5, "scene": 30, "line": "Coffee?", "order": 30642, "speaker": "Michael Scott"},
  {"season": 6, "episode": 5, "scene": 30, "line": "Okay.", "order": 30643, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 5, "scene": 30, "line": "Not from the kitchen. Stop and Shop. If it's not Stop and Shop, I send it back.", "order": 30644, "speaker": "Michael Scott"},
  {"season": 6, "episode": 5, "scene": 30, "line": "Okay.", "order": 30645, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 5, "scene": 30, "line": "Large. If it's a medium I send it back. If it's an extra large I send it back.", "order": 30646, "speaker": "Michael Scott"},
  {"season": 6, "episode": 5, "scene": 30, "line": "How do you return coffee?", "order": 30647, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 5, "scene": 30, "line": "Go. Any questions?", "order": 30648, "speaker": "Michael Scott"},
  {"season": 6, "episode": 5, "scene": 31, "line": "[on phone] Are you kidding me?", "order": 30649, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 5, "scene": 31, "line": "Hi Pam, is Jim there?", "order": 30650, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 5, "scene": 31, "line": "Listen our credit card has been cancelled and we have to deal with that, and I really can't handle the fact that you're calling us here!", "order": 30651, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 5, "scene": 31, "line": "Okay, that sounds good. Um, I'll let you go, just -tell Jim, that I said hi.", "order": 30652, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 5, "scene": 31, "line": "Oh I will. I will Kevin. I will make that my top priority.", "order": 30653, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 5, "scene": 31, "line": "Cool. Okay. [Pam hangs up] Bye.", "order": 30654, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 5, "scene": 32, "line": "They have no idea what happened.", "order": 30655, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 6, "scene": 1, "line": "They're back!", "order": 30656, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 6, "scene": 1, "line": "Oooh yeah... [people chuckle] Jim and Pam!", "order": 30657, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 6, "scene": 1, "line": "How was Puerto Rico? Was it so romantic?", "order": 30658, "speaker": "Kelly Kapoor"},
  {"season": 6, "episode": 6, "scene": 1, "line": "It was.", "order": 30659, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 6, "scene": 1, "line": "It really was.", "order": 30660, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 6, "scene": 1, "line": "Really was.", "order": 30661, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 6, "scene": 1, "line": "[voice cracks] I'm so happy for you...", "order": 30662, "speaker": "Kelly Kapoor"},
  {"season": 6, "episode": 6, "scene": 2, "line": "Puerto Rico was awesome.", "order": 30663, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 6, "scene": 2, "line": "Oh my gosh. The honeymoon was great. We met this other couple at the resort, Frank and Benny. We hung out with them a lot.", "order": 30664, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 6, "scene": 2, "line": "[goofy voice] Frank and Beans. [laughs] Always makes her laugh.", "order": 30665, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 6, "scene": 2, "line": "[goofy voice] Frank and beans!", "order": 30666, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 6, "scene": 3, "line": "Is there someone there? Who is there? I hear voices, is somebody there?", "order": 30667, "speaker": "Michael Scott"},
  {"season": 6, "episode": 6, "scene": 3, "line": "Yeah!", "order": 30668, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 6, "scene": 3, "line": "Is someone there? I can't see you because I'm blind.", "order": 30669, "speaker": "Michael Scott"},
  {"season": 6, "episode": 6, "scene": 3, "line": "Its Jim and Pam, Michael.", "order": 30670, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 6, "scene": 3, "line": "[gasps] It is?", "order": 30671, "speaker": "Michael Scott"},
  {"season": 6, "episode": 6, "scene": 3, "line": "Yeah...", "order": 30672, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 6, "scene": 3, "line": "They're back? Oh! Oh! Oh, Pam! [reaching out with hands at chest level]", "order": 30673, "speaker": "Michael Scott"},
  {"season": 6, "episode": 6, "scene": 3, "line": "Nope.", "order": 30674, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 6, "scene": 3, "line": "And oh, Jim...", "order": 30675, "speaker": "Michael Scott"},
  {"season": 6, "episode": 6, "scene": 3, "line": "Hi, Michael.", "order": 30676, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 6, "scene": 3, "line": "Oh, I haven't see you since my accident that I had when I fell- I fell into the pool of acid, eyes first. Blind guy.", "order": 30677, "speaker": "Michael Scott"},
  {"season": 6, "episode": 6, "scene": 4, "line": "Blind-guy McSqueezy. How do I describe it? It is a character I've been workshopping whose lack of vision gets him into all sorts of trouble. The women in my improve class absolutely hate him. [groans]", "order": 30678, "speaker": "Michael Scott"},
  {"season": 6, "episode": 6, "scene": 5, "line": "So what'd you bring us?", "order": 30679, "speaker": "Meredith Palmer"},
  {"season": 6, "episode": 6, "scene": 5, "line": "Some candy.", "order": 30680, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 6, "scene": 5, "line": "What else?", "order": 30681, "speaker": "Meredith Palmer"},
  {"season": 6, "episode": 6, "scene": 5, "line": "That's it.", "order": 30682, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 6, "scene": 5, "line": "Oh, 'cause you spent so much on the wedding.", "order": 30683, "speaker": "Meredith Palmer"},
  {"season": 6, "episode": 6, "scene": 5, "line": "[behind Pam, acting out blind-guy, groaning]", "order": 30684, "speaker": "Michael Scott"},
  {"season": 6, "episode": 6, "scene": 5, "line": "It's good to be home.", "order": 30685, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 6, "scene": 6, "line": "This conversation has two items on the agenda.", "order": 30686, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 6, "scene": 6, "line": "Do we have a conversation scheduled?", "order": 30687, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 6, "scene": 6, "line": "Number one, do not leave your things on my desk. It's not some kind of personal pen receptacle for you. I don't care how high they promote you, which brings me to item number two... I never formally congratulated you on your promotion, so I'd just like to say... [high voice, holding up wooden mallard] con-quack-ulations!", "order": 30688, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 6, "scene": 6, "line": "Wow, that's... really thoughtful of you, Dwight, thank you.", "order": 30689, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 6, "scene": 6, "line": "[duck voice] You're welcome! [quacks, laughs]", "order": 30690, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 6, "scene": 7, "line": "I inserted a listening device into the belly of the mallard. Now I can observe Jim, trap Jim, and destroy Jim, just like in the Bavarian fairy tale. Only this time, the mallard skins the toad alive. And of course in this version you lose the whole veiled critique of the Kaiser thing.", "order": 30691, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 6, "scene": 8, "line": "I'm sorry to have been bugging you all these years.", "order": 30692, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 6, "scene": 8, "line": "It's a real handsome duck.", "order": 30693, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 6, "scene": 8, "line": "Mallard. Okay, I'll get out of your hair.", "order": 30694, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 6, "scene": 9, "line": "Hey!", "order": 30695, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 6, "scene": 9, "line": "Hi!", "order": 30696, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 6, "scene": 9, "line": "Uh, we brought back some Puerto Rican candy.", "order": 30697, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 6, "scene": 9, "line": "Coco Leche! That's my favorite!", "order": 30698, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 6, "scene": 9, "line": "Awesome! I'll leave it up here so everyone can enjoy it.", "order": 30699, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 6, "scene": 9, "line": "Oh, um, let me just check with Michael first.", "order": 30700, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 6, "scene": 9, "line": "[laughs] I think it'll be okay.", "order": 30701, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 6, "scene": 9, "line": "[laughs] I think it will too, but I'll just check with him, though.", "order": 30702, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 6, "scene": 9, "line": "Great.", "order": 30703, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 6, "scene": 9, "line": "Oops, sorry. [slides candy back to Pam] Oops.", "order": 30704, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 6, "scene": 10, "line": "I have recently taken a lover.", "order": 30705, "speaker": "Michael Scott"},
  {"season": 6, "episode": 6, "scene": 10, "line": "Well, that's great. Congratulations. Who's the lucky lady?", "order": 30706, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 6, "scene": 10, "line": "Pam's mom.", "order": 30707, "speaker": "Michael Scott"},
  {"season": 6, "episode": 6, "scene": 10, "line": "What?", "order": 30708, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 6, "scene": 10, "line": "Pam's mom, Helene. Remember from your wedding?", "order": 30709, "speaker": "Michael Scott"},
  {"season": 6, "episode": 6, "scene": 10, "line": "You're messing with me.", "order": 30710, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 6, "scene": 10, "line": "About what?", "order": 30711, "speaker": "Michael Scott"},
  {"season": 6, "episode": 6, "scene": 10, "line": "You did not have sex with Pam's mom.", "order": 30712, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 6, "scene": 10, "line": "Oh, big time.", "order": 30713, "speaker": "Michael Scott"},
  {"season": 6, "episode": 6, "scene": 10, "line": "What kind of car does she drive?", "order": 30714, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 6, "scene": 10, "line": "She drives a green camry.", "order": 30715, "speaker": "Michael Scott"},
  {"season": 6, "episode": 6, "scene": 10, "line": "[expletive]", "order": 30716, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 6, "scene": 10, "line": "And the seats go all the way down. All the way down.", "order": 30717, "speaker": "Michael Scott"},
  {"season": 6, "episode": 6, "scene": 10, "line": "Oh my God. [Michael bangs the table] Oh my God.", "order": 30718, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 6, "scene": 10, "line": "[laughs] What?", "order": 30719, "speaker": "Michael Scott"},
  {"season": 6, "episode": 6, "scene": 10, "line": "Okay, never tell Pam, and secondly-", "order": 30720, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 6, "scene": 10, "line": "Okay, good, a pact. A pact. Although I may have to break it tonight when Helene and I tell Pam over dinner. You alright?", "order": 30721, "speaker": "Michael Scott"},
  {"season": 6, "episode": 6, "scene": 10, "line": "Oh my God.", "order": 30722, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 6, "scene": 10, "line": "[walks in] Hey, Jim.", "order": 30723, "speaker": "Toby Flenderson"},
  {"season": 6, "episode": 6, "scene": 10, "line": "Not now, Toby, my God!", "order": 30724, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 6, "scene": 10, "line": "Oh, Jesus!", "order": 30725, "speaker": "Toby Flenderson"},
  {"season": 6, "episode": 6, "scene": 10, "line": "Get the hell out of here, idiot.", "order": 30726, "speaker": "Michael Scott"},
  {"season": 6, "episode": 6, "scene": 10, "line": "What did I do?", "order": 30727, "speaker": "Toby Flenderson"},
  {"season": 6, "episode": 6, "scene": 10, "line": "Okay, as far as dinner tonight, cancel that, and please, for both our sakes, never, ever, ever see her again.", "order": 30728, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 6, "scene": 10, "line": "I think you're underestimating Pam. I think more than anything, she wants me to be happy.", "order": 30729, "speaker": "Michael Scott"},
  {"season": 6, "episode": 6, "scene": 10, "line": "No, not more than anything.", "order": 30730, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 6, "scene": 10, "line": "Okay, I have a good thing with the mom-", "order": 30731, "speaker": "Michael Scott"},
  {"season": 6, "episode": 6, "scene": 10, "line": "Don't call her 'the mom.'", "order": 30732, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 6, "scene": 10, "line": "She's right on my way home from work.", "order": 30733, "speaker": "Michael Scott"},
  {"season": 6, "episode": 6, "scene": 10, "line": "Then take a different way home, man!", "order": 30734, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 6, "scene": 10, "line": "I di- alright, I'll take surface streets, its- the last thing in the world I would want to do is upset Pam.", "order": 30735, "speaker": "Michael Scott"},
  {"season": 6, "episode": 6, "scene": 10, "line": "Okay, so we're good.", "order": 30736, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 6, "scene": 10, "line": "Yeah.", "order": 30737, "speaker": "Michael Scott"},
  {"season": 6, "episode": 6, "scene": 11, "line": "Can you change my dinner reservations from four people to two?", "order": 30738, "speaker": "Michael Scott"},
  {"season": 6, "episode": 6, "scene": 11, "line": "Sure. Oh, is it okay if I put out some candy that Pam brought back from Puerto Rico?", "order": 30739, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 6, "scene": 11, "line": "Sure. Thanks for asking.", "order": 30740, "speaker": "Michael Scott"},
  {"season": 6, "episode": 6, "scene": 11, "line": "Pam, we're all set. [Pam places candy on Erin's desk] Yum.", "order": 30741, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 6, "scene": 12, "line": "Frank and beans!", "order": 30742, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 6, "scene": 12, "line": "Frank and beans! [both laugh] So what'd we decide for Michael, the bottle of rum, or the seashell alarm clock?", "order": 30743, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 6, "scene": 12, "line": "You know what? Can I have the weekend to decide?", "order": 30744, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 6, "scene": 12, "line": "Bottle of rum it is. [takes package out of bag] Alright, shall we?", "order": 30745, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 6, "scene": 12, "line": "You know what, I am really slammed, trying to catch up on everything here and I know that Michael's slammed too. So, maybe we should do this when things are a little less crazy.", "order": 30746, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 6, "scene": 12, "line": "Come on, it'll take two seconds.", "order": 30747, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 6, "scene": 12, "line": "No, it- [high-pitched feedback as Jim starts to leave, feedback changes as he moves, turns over the mallard to see the listening devics, sighs, places mallard back on the desk]", "order": 30748, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 6, "scene": 13, "line": "[unwraps rum gift] Oh, wow.", "order": 30749, "speaker": "Michael Scott"},
  {"season": 6, "episode": 6, "scene": 13, "line": "[giggles]", "order": 30750, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 6, "scene": 13, "line": "That is amazing. I feel like a real Puerto Rican.", "order": 30751, "speaker": "Michael Scott"},
  {"season": 6, "episode": 6, "scene": 13, "line": "Michael, you're all set at Botticelli's. I changed the reservation to two people.", "order": 30752, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 6, "scene": 13, "line": "Erin, look. [holds up parrot rum bottle]", "order": 30753, "speaker": "Michael Scott"},
  {"season": 6, "episode": 6, "scene": 13, "line": "Fun!", "order": 30754, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 6, "scene": 13, "line": "Yeah.", "order": 30755, "speaker": "Michael Scott"},
  {"season": 6, "episode": 6, "scene": 13, "line": "Wow, Botticelli's, that sounds like a special occasion.", "order": 30756, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 6, "scene": 13, "line": "Yeah, no, it's nobody.", "order": 30757, "speaker": "Michael Scott"},
  {"season": 6, "episode": 6, "scene": 13, "line": "[laughs] I don't know. I think Michael has a date.", "order": 30758, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 6, "scene": 13, "line": "[chuckles] Hmm... no.", "order": 30759, "speaker": "Michael Scott"},
  {"season": 6, "episode": 6, "scene": 13, "line": "[laughs] I think you have a date.", "order": 30760, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 6, "scene": 13, "line": "I don't.", "order": 30761, "speaker": "Michael Scott"},
  {"season": 6, "episode": 6, "scene": 13, "line": "Come on.", "order": 30762, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 6, "scene": 13, "line": "Uh, I think we should just drop it... 'cause obviously he doesn't want to talk about it.", "order": 30763, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 6, "scene": 13, "line": "[sighs] I don't deserve this, guys.", "order": 30764, "speaker": "Michael Scott"},
  {"season": 6, "episode": 6, "scene": 13, "line": "Yes, you do.", "order": 30765, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 6, "scene": 13, "line": "No, I don't.", "order": 30766, "speaker": "Michael Scott"},
  {"season": 6, "episode": 6, "scene": 13, "line": "Just take the parrot.", "order": 30767, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 6, "scene": 13, "line": "[long exhale]", "order": 30768, "speaker": "Michael Scott"},
  {"season": 6, "episode": 6, "scene": 13, "line": "Okay, back to the old grind.", "order": 30769, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 6, "scene": 13, "line": "I was probably going to break up with her anyway.", "order": 30770, "speaker": "Michael Scott"},
  {"season": 6, "episode": 6, "scene": 13, "line": "Oh, that's too bad.", "order": 30771, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 6, "scene": 13, "line": "Don't-", "order": 30772, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 6, "scene": 13, "line": "Pam, it is very complicated. There are a lot of moving parts here.", "order": 30773, "speaker": "Michael Scott"},
  {"season": 6, "episode": 6, "scene": 13, "line": "Sounds complicated.", "order": 30774, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 6, "scene": 13, "line": "It is.", "order": 30775, "speaker": "Michael Scott"},
  {"season": 6, "episode": 6, "scene": 13, "line": "Yeah, but I mean, if you really like this person, then you should see where it goes.", "order": 30776, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 6, "scene": 13, "line": "You want me to be happy?", "order": 30777, "speaker": "Michael Scott"},
  {"season": 6, "episode": 6, "scene": 13, "line": "Of course.", "order": 30778, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 6, "scene": 13, "line": "Part of the problem is, she is the mother of a close friend of mine.", "order": 30779, "speaker": "Michael Scott"},
  {"season": 6, "episode": 6, "scene": 13, "line": "Oh.", "order": 30780, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 6, "scene": 13, "line": "More than a friend, a co-worker.", "order": 30781, "speaker": "Michael Scott"},
  {"season": 6, "episode": 6, "scene": 13, "line": "Oh! Gossip, who is it? Who is it? Who is it, Michael? ...Who?", "order": 30782, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 6, "scene": 13, "line": "It's okay.", "order": 30783, "speaker": "Michael Scott"},
  {"season": 6, "episode": 6, "scene": 13, "line": "No, no, no, no, no. Oh, no. Oh, my God! Oh, my God! Nooooo!", "order": 30784, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 6, "scene": 14, "line": "That could have gone one of two ways, but I never expected her to get upset.", "order": 30785, "speaker": "Michael Scott"},
  {"season": 6, "episode": 6, "scene": 15, "line": "[screams out in the parking lot, Michael watches from his office window]", "order": 30786, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 6, "scene": 15, "line": "[to Michael] You seem tense. Hey, you want me to give you the chills?", "order": 30787, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 6, "scene": 15, "line": "Okay.", "order": 30788, "speaker": "Michael Scott"},
  {"season": 6, "episode": 6, "scene": 15, "line": "There's an egg on your head and the yolk is running down, the yolk is running down...", "order": 30789, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 6, "scene": 15, "line": "[sighs] Feels good.", "order": 30790, "speaker": "Michael Scott"},
  {"season": 6, "episode": 6, "scene": 15, "line": "There's a knife in your back and the blood is gushing down...", "order": 30791, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 6, "scene": 15, "line": "I'm sleeping with Pam's mom. Sometimes, dinner.", "order": 30792, "speaker": "Michael Scott"},
  {"season": 6, "episode": 6, "scene": 15, "line": "...the blood is gushing down, the blood is gushing down... You know, I really would have appreciated a heads-up that you were into dating mothers. I would have introduced you to mine.", "order": 30793, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 6, "scene": 16, "line": "[outside, on cell phone] How could you do this to me? He's my boss! How many times have I complained about him to you? No, I am not being dramatic, you are being crazy!", "order": 30794, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 6, "scene": 16, "line": "[singsong voice] Who wants a hot chocolate?", "order": 30795, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 6, "scene": 16, "line": "Thank you.", "order": 30796, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 6, "scene": 16, "line": "[sighs] Oh, so Dwight gave me this wooden mallard as a gift, and I found a recording device in it. Yes. So, I think if I played it just right I can get Dwight to play out the plot of National Treasure.", "order": 30797, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 6, "scene": 16, "line": "You need to be more upset about this. She's your mother too now. Your mother is sleeping with Michael Scott.", "order": 30798, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 6, "scene": 17, "line": "Andy, can I talk to you for a second?", "order": 30799, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 6, "scene": 17, "line": "Sure thing, Tuna Boss.", "order": 30800, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 6, "scene": 18, "line": "[holding up a sign that reads, 'Dwight picked the wrong day to put a wooden mallard in my office']", "order": 30801, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 6, "scene": 19, "line": "[in Jim's office] How may I be of service to you?", "order": 30802, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 6, "scene": 19, "line": "I am gonna need your advice [starts playing loud opera music, everyone can hear it, Creed starts crying] I was thinking about getting this opera for Dwight's birthday, what do you think?", "order": 30803, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 6, "scene": 19, "line": "[both look at Dwight, Jim waves] This aurea is a joke.", "order": 30804, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 6, "scene": 19, "line": "Really?", "order": 30805, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 6, "scene": 19, "line": "What are you thinking?", "order": 30806, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 6, "scene": 19, "line": "I was gonna go with this one.", "order": 30807, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 6, "scene": 19, "line": "[both look at Dwight who is standing right outside the office] Let me tell you something, if you respect him at all, you will get him something better.", "order": 30808, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 6, "scene": 19, "line": "[Andy gets up and leaves the office] The Nard Dog... what was that all about?", "order": 30809, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 6, "scene": 19, "line": "I know, right?", "order": 30810, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 6, "scene": 19, "line": "What were you talking about in there?", "order": 30811, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 6, "scene": 19, "line": "Trust me it would only make you mad. [opera continues, Dwight looks at Jim standing outside his office, waves, Jim waves back]", "order": 30812, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 6, "scene": 20, "line": "[in the conference room leading a meeting] Due to a certain recent incident, corporate has asked all the branches to come up with ideas as to how we can better communicate with our communities.", "order": 30813, "speaker": "Michael Scott"},
  {"season": 6, "episode": 6, "scene": 20, "line": "Is this because of the 60 Minutes segment about working conditions in our Peruvian paper mill?", "order": 30814, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 6, "scene": 20, "line": "That was a hit job. If you read the Dunder Mifflin press release it clearly states that they had absolutely nothing to do with that particular cancer cluster. So if there is a lesson to be learned here and I'm not sure that there is, it is that in order to help our communities, we need to put other people's needs ahead of our own.", "order": 30815, "speaker": "Michael Scott"},
  {"season": 6, "episode": 6, "scene": 20, "line": "Haha! Ha ha ha.", "order": 30816, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 6, "scene": 20, "line": "And whoever comes up with the best idea gets a $50 gift certificate to the restaurant of his or her choice. [everyone claps and makes comments]", "order": 30817, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 6, "scene": 20, "line": "Who wants to help the world one step at a time? [Dwight raises his hand] Alright, good.", "order": 30818, "speaker": "Michael Scott"},
  {"season": 6, "episode": 6, "scene": 20, "line": "Volunteerism is important. Every weekend I volunteer at the local animal shelter and they need a lot of help down there. Last Sunday I had to put down over 150 pets all by myself.", "order": 30819, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 6, "scene": 20, "line": "Alright, that's...", "order": 30820, "speaker": "Michael Scott"},
  {"season": 6, "episode": 6, "scene": 20, "line": "Paint a mural of Chicano leaders.", "order": 30821, "speaker": "Meredith Palmer"},
  {"season": 6, "episode": 6, "scene": 20, "line": "Alright...", "order": 30822, "speaker": "Michael Scott"},
  {"season": 6, "episode": 6, "scene": 20, "line": "I have a way to make Scranton a better place, you could leave it. [laughter]", "order": 30823, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 6, "scene": 20, "line": "Okay, I'm out of here, [pretends to leave] see you later guys...", "order": 30824, "speaker": "Michael Scott"},
  {"season": 6, "episode": 6, "scene": 20, "line": "Get out and stay out, bye...", "order": 30825, "speaker": "Various"},
  {"season": 6, "episode": 6, "scene": 20, "line": "Ok, oh no here's an idea... conservation...", "order": 30826, "speaker": "Michael Scott"},
  {"season": 6, "episode": 6, "scene": 20, "line": "I love it, conservation. Let's start by conserving our time and stop having these stupid meetings. [stands up and cheers] No more meetings! No more meetings! No more meetings! No more meetings! [Stanley cheers her on in the background]", "order": 30827, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 6, "scene": 20, "line": "Anybody else? Who else has an idea?", "order": 30828, "speaker": "Michael Scott"},
  {"season": 6, "episode": 6, "scene": 20, "line": "I have some ideas about conservation...", "order": 30829, "speaker": "Angela Martin"},
  {"season": 6, "episode": 6, "scene": 20, "line": "Yes! Angela! Please...", "order": 30830, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 6, "scene": 20, "line": "[Michael leaves to answer his phone] Can you hold down the fort? [on phone] Hey boo!", "order": 30831, "speaker": "Michael Scott"},
  {"season": 6, "episode": 6, "scene": 20, "line": "Thank you. Sure well first of all I think that we could totally...", "order": 30832, "speaker": "Angela Martin"},
  {"season": 6, "episode": 6, "scene": 20, "line": "[on phone] What's it... why are you crying?", "order": 30833, "speaker": "Michael Scott"},
  {"season": 6, "episode": 6, "scene": 20, "line": "Yes! I think we should look into that. If you could just speak up louder...", "order": 30834, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 6, "scene": 20, "line": "[to Angela] Shhhh...", "order": 30835, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 6, "scene": 20, "line": "Well, for our profit...", "order": 30836, "speaker": "Angela Martin"},
  {"season": 6, "episode": 6, "scene": 20, "line": "[to Angela] Shhh...", "order": 30837, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 6, "scene": 20, "line": "[on phone] No, no, no, I'll talk to her. I will... No... nobody talks to my baby that way... uh ah... yeah, I'll let you know how it goes. Alright, by pickle.", "order": 30838, "speaker": "Michael Scott"},
  {"season": 6, "episode": 6, "scene": 20, "line": "Who's pickle?", "order": 30839, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 6, "scene": 20, "line": "[hangs up phone and walks back into conference room] Pamela Morgan Beesly, you need to apologize to your mother right now.", "order": 30840, "speaker": "Michael Scott"},
  {"season": 6, "episode": 6, "scene": 20, "line": "I'm sorry, I was told I had the floor.", "order": 30841, "speaker": "Angela Martin"},
  {"season": 6, "episode": 6, "scene": 20, "line": "Yes.", "order": 30842, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 6, "scene": 20, "line": "Hold on, hold on, what's going on?", "order": 30843, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 6, "scene": 20, "line": "Nothing... nothing at all... it's all good!", "order": 30844, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 6, "scene": 20, "line": "I'm not apologizing to anyone. Michael owes me an apology.", "order": 30845, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 6, "scene": 20, "line": "For trying to find happiness in the arms of a lover?", "order": 30846, "speaker": "Michael Scott"},
  {"season": 6, "episode": 6, "scene": 20, "line": "Don't call my mother your lover.", "order": 30847, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 6, "scene": 20, "line": "Yes! That's what I'm talking about.", "order": 30848, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 6, "scene": 20, "line": "That is not okay dude.", "order": 30849, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 6, "scene": 20, "line": "Alright, in my defense...", "order": 30850, "speaker": "Michael Scott"},
  {"season": 6, "episode": 6, "scene": 20, "line": "Disgusting...", "order": 30851, "speaker": "Phyllis Vance"},
  {"season": 6, "episode": 6, "scene": 20, "line": "She's messed up man...", "order": 30852, "speaker": "Creed Bratton"},
  {"season": 6, "episode": 6, "scene": 20, "line": "Yes! Thank you, welcome to my personal hell!", "order": 30853, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 6, "scene": 20, "line": "You have no sense of boundaries Michael.", "order": 30854, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 6, "scene": 20, "line": "Shut up Oscar. Hey, alright, you know what, clearly I'm outnumbered here, but can I just say one thing, please? What is so wrong about me? I'm caring, I'm generous, I'm sensual. Is it really so horrible that I could possibly go out and find happiness.", "order": 30855, "speaker": "Michael Scott"},
  {"season": 6, "episode": 6, "scene": 20, "line": "Good luck Michael, I hope you find what you're looking for.", "order": 30856, "speaker": "Phyllis Vance"},
  {"season": 6, "episode": 6, "scene": 20, "line": "Maybe you're right, who are we to...", "order": 30857, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 6, "scene": 20, "line": "Shut up Oscar. What is wrong with all of you, he is sleeping with my mother!", "order": 30858, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 6, "scene": 20, "line": "I don't think there's a whole lot of sleeping going on.", "order": 30859, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 6, "scene": 20, "line": "Let's get back to the matter at hand.", "order": 30860, "speaker": "Michael Scott"},
  {"season": 6, "episode": 6, "scene": 20, "line": "Whatever, you know, sleep with my mom, sleep with everybody's mom...", "order": 30861, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 6, "scene": 20, "line": "No, no, no... no, no...", "order": 30862, "speaker": "Michael Scott"},
  {"season": 6, "episode": 6, "scene": 20, "line": "Whoa, that's my mother you're talking about...", "order": 30863, "speaker": "Ryan Howard"},
  {"season": 6, "episode": 6, "scene": 20, "line": "I don't like the tone here... this is a place of business, you are to listen to others, you are to give others respect, and you are to keep your personal issues out of it.", "order": 30864, "speaker": "Michael Scott"},
  {"season": 6, "episode": 6, "scene": 20, "line": "Uhh, huh, ho... oooh my God, you are ridiculous!", "order": 30865, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 6, "scene": 20, "line": "Do not talk to me that way! I am your boss and I may someday be your father, so get out.", "order": 30866, "speaker": "Michael Scott"},
  {"season": 6, "episode": 6, "scene": 20, "line": "You are never going to be my father, you get out!", "order": 30867, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 6, "scene": 20, "line": "I hope that you are willing to die in this office, because I am...", "order": 30868, "speaker": "Michael Scott"},
  {"season": 6, "episode": 6, "scene": 20, "line": "Me too...", "order": 30869, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 6, "scene": 21, "line": "Hey. [walks up to Oscar and hands him a report to sign]", "order": 30870, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 6, "scene": 21, "line": "Hey.", "order": 30871, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 6, "scene": 21, "line": "[in a sing-songy voice] Pam, how's your day going?", "order": 30872, "speaker": "Angela Martin"},
  {"season": 6, "episode": 6, "scene": 21, "line": "Pam, just for the record, I think you're overreacting a little bit, your mom's old enough to make her own decisions.", "order": 30873, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 6, "scene": 21, "line": "Oh, well, thanks Oscar. I was just wondering how would you feel if Michael was sleeping with your mom?", "order": 30874, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 6, "scene": 21, "line": "My mother's in a wheelchair.", "order": 30875, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 6, "scene": 21, "line": "Well, he could still... I'm sorry about that... Oh could I just get you to sign this second page.", "order": 30876, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 6, "scene": 22, "line": "[Dwight listening in his ear piece] Ryan, I have to ask you a personal question. Do you think that I should get a Fedora?", "order": 30877, "speaker": "Kelly Kapoor"},
  {"season": 6, "episode": 6, "scene": 22, "line": "Ahh, I don't think so, no...", "order": 30878, "speaker": "Ryan Howard"},
  {"season": 6, "episode": 6, "scene": 22, "line": "Well, I think I'd look really hot in one. Where'd you get your fedora?", "order": 30879, "speaker": "Kelly Kapoor"},
  {"season": 6, "episode": 6, "scene": 22, "line": "I'd rather not say.", "order": 30880, "speaker": "Ryan Howard"},
  {"season": 6, "episode": 6, "scene": 22, "line": "I think I'm gonna get the same fedora as you.", "order": 30881, "speaker": "Kelly Kapoor"},
  {"season": 6, "episode": 6, "scene": 22, "line": "Hi Dwight!", "order": 30882, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 6, "scene": 22, "line": "[Dwight storms through the break room listening to Kelly and Ryan in his ear piece] Shh!", "order": 30883, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 6, "scene": 22, "line": "[Ryan and Kelly talking] ...it should go with the persona you already have.", "order": 30884, "speaker": "Ryan Howard"},
  {"season": 6, "episode": 6, "scene": 22, "line": "Well, I think I have that persona.", "order": 30885, "speaker": "Kelly Kapoor"},
  {"season": 6, "episode": 6, "scene": 22, "line": "Where did you get that mallard?", "order": 30886, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 6, "scene": 22, "line": "What the hell is a mallard?", "order": 30887, "speaker": "Kelly Kapoor"},
  {"season": 6, "episode": 6, "scene": 22, "line": "That!", "order": 30888, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 6, "scene": 22, "line": "Oh, Professor Damien D. Duck, Jim gave him to me.", "order": 30889, "speaker": "Kelly Kapoor"},
  {"season": 6, "episode": 6, "scene": 22, "line": "Okay, I gave that to him that as a gift, I'm taking that back.", "order": 30890, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 6, "scene": 22, "line": "If you take it back, I'll scream.", "order": 30891, "speaker": "Kelly Kapoor"},
  {"season": 6, "episode": 6, "scene": 22, "line": "[sighs heavily] I'll give you five bucks for it.", "order": 30892, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 6, "scene": 22, "line": "Twenty.", "order": 30893, "speaker": "Ryan Howard"},
  {"season": 6, "episode": 6, "scene": 22, "line": "Ten.", "order": 30894, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 6, "scene": 22, "line": "Deal. [pays Ryan and takes the mallard back]", "order": 30895, "speaker": "Ryan Howard"},
  {"season": 6, "episode": 6, "scene": 22, "line": "[to Ryan] You're so cool.", "order": 30896, "speaker": "Kelly Kapoor"},
  {"season": 6, "episode": 6, "scene": 22, "line": "This reminds me, you owe me three bucks for gas.", "order": 30897, "speaker": "Ryan Howard"},
  {"season": 6, "episode": 6, "scene": 23, "line": "[walks into Toby's office] Hey Toby, could I talk to you for a minute?", "order": 30898, "speaker": "Michael Scott"},
  {"season": 6, "episode": 6, "scene": 23, "line": "Yeah, sure, what's up?", "order": 30899, "speaker": "Toby Flenderson"},
  {"season": 6, "episode": 6, "scene": 23, "line": "Um, I just wanted to apologize for taking that tone with you earlier that was... uncalled for, I'm sorry.", "order": 30900, "speaker": "Michael Scott"},
  {"season": 6, "episode": 6, "scene": 23, "line": "Um, yeah, ah, ah, that means a lot. Thank you for saying it.", "order": 30901, "speaker": "Toby Flenderson"},
  {"season": 6, "episode": 6, "scene": 23, "line": "Can I sit down for a second?", "order": 30902, "speaker": "Michael Scott"},
  {"season": 6, "episode": 6, "scene": 23, "line": "Yeah, er... pull up a chair or sit on the shredder.", "order": 30903, "speaker": "Toby Flenderson"},
  {"season": 6, "episode": 6, "scene": 23, "line": "This is gonna sound weird, but I think I may be the victim of a hostile work environment with this whole Pam situation.", "order": 30904, "speaker": "Michael Scott"},
  {"season": 6, "episode": 6, "scene": 23, "line": "Ah, you should probably deal with that outside of the work place.", "order": 30905, "speaker": "Toby Flenderson"},
  {"season": 6, "episode": 6, "scene": 23, "line": "She brought it into the work place so I feel like it has to be dealt with here.", "order": 30906, "speaker": "Michael Scott"},
  {"season": 6, "episode": 6, "scene": 23, "line": "Ok, I mean, I could talk to her.", "order": 30907, "speaker": "Toby Flenderson"},
  {"season": 6, "episode": 6, "scene": 23, "line": "Really? Would you do that?", "order": 30908, "speaker": "Michael Scott"},
  {"season": 6, "episode": 6, "scene": 23, "line": "Yeah... that's why they pay me the big bucks. [both laugh]", "order": 30909, "speaker": "Toby Flenderson"},
  {"season": 6, "episode": 6, "scene": 23, "line": "[hugs Toby, who smiles big] You're a good, good guy.", "order": 30910, "speaker": "Michael Scott"},
  {"season": 6, "episode": 6, "scene": 23, "line": "I'm good...", "order": 30911, "speaker": "Toby Flenderson"},
  {"season": 6, "episode": 6, "scene": 24, "line": "You know, I always knew if Michael just took the time to get to know me, we'd become friends.", "order": 30912, "speaker": "Toby Flenderson"},
  {"season": 6, "episode": 6, "scene": 25, "line": "[walks up to Pam's desk with a binder] Hey Pam, could I talk to you for a sec?", "order": 30913, "speaker": "Toby Flenderson"},
  {"season": 6, "episode": 6, "scene": 25, "line": "Sure, what's up?", "order": 30914, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 6, "scene": 25, "line": "Well, I was hoping that maybe in light of everything that's happened today, it would be a good idea if today, you, me, and Michael could head into the conference room for some conflict resolution.", "order": 30915, "speaker": "Toby Flenderson"},
  {"season": 6, "episode": 6, "scene": 25, "line": "[to Michael] What's the matter, you can't fight your own battles?", "order": 30916, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 6, "scene": 25, "line": "No... that's...", "order": 30917, "speaker": "Michael Scott"},
  {"season": 6, "episode": 6, "scene": 25, "line": "Maybe you could just take the rest of the day off... you know...", "order": 30918, "speaker": "Toby Flenderson"},
  {"season": 6, "episode": 6, "scene": 25, "line": "[to Michael] Oh, would that make you feel better?", "order": 30919, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 6, "scene": 25, "line": "I don't... um... I can't hear your conversation.", "order": 30920, "speaker": "Michael Scott"},
  {"season": 6, "episode": 6, "scene": 25, "line": "You can tell Michael that I'm not leaving.", "order": 30921, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 6, "scene": 25, "line": "[gets up and puts a hand on Michael's shoulder] Buddy, I think that...", "order": 30922, "speaker": "Toby Flenderson"},
  {"season": 6, "episode": 6, "scene": 25, "line": "Yeah, yeah, yeah-okay... you're a jackass. [to Pam] Hey, hey, you know what? You're just as stubborn as your mother, when you don't wanna do something you just don't do it.", "order": 30923, "speaker": "Michael Scott"},
  {"season": 6, "episode": 6, "scene": 25, "line": "Heh, heh, heh.", "order": 30924, "speaker": "Stanley Hudson"},
  {"season": 6, "episode": 6, "scene": 25, "line": "Michael, you're just her rebound!", "order": 30925, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 6, "scene": 25, "line": "You were right Jim, shoulda listened to you, should never have told her.", "order": 30926, "speaker": "Michael Scott"},
  {"season": 6, "episode": 6, "scene": 25, "line": "[to Jim] What! You knew?", "order": 30927, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 6, "scene": 25, "line": "Barely, I... I don't have all the facts. Frank and Bean...", "order": 30928, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 6, "scene": 25, "line": "[Pam storms into break room] Okay, do you want me to stop seeing your mom? Is that how we're gonna get past this? Cause, I will.", "order": 30929, "speaker": "Michael Scott"},
  {"season": 6, "episode": 6, "scene": 25, "line": "Mmmmm, yes!", "order": 30930, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 6, "scene": 25, "line": "Well, that is not gonna happen!", "order": 30931, "speaker": "Michael Scott"},
  {"season": 6, "episode": 6, "scene": 25, "line": "Then why'd you even offer!?", "order": 30932, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 6, "scene": 25, "line": "Because I assumed that you want me to be happy because I want you to be happy.", "order": 30933, "speaker": "Michael Scott"},
  {"season": 6, "episode": 6, "scene": 25, "line": "Michael, let me make this very easy for you, I could give a sh[beep] about your happiness! Stop dating my mother!", "order": 30934, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 6, "scene": 25, "line": "You know what? I'm gonna start dating her even harder.", "order": 30935, "speaker": "Michael Scott"},
  {"season": 6, "episode": 6, "scene": 25, "line": "What's that supposed to mean?", "order": 30936, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 6, "scene": 25, "line": "You know what it means.", "order": 30937, "speaker": "Michael Scott"},
  {"season": 6, "episode": 6, "scene": 25, "line": "[Pam storms out of the break room] Hey...", "order": 30938, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 6, "scene": 25, "line": "Shut it!", "order": 30939, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 6, "scene": 25, "line": "Yep...", "order": 30940, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 6, "scene": 26, "line": "I don't need to be friends with Pam. I have plenty of female friends. My mom, Pam's mom, my aunt... although she just blocked me on IM, what's her face from Quiznos, I see her four times a week.", "order": 30941, "speaker": "Michael Scott"},
  {"season": 6, "episode": 6, "scene": 27, "line": "[walks into his office and sees the mallard back on his desk] Dwight, you brought the mallard back.", "order": 30942, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 6, "scene": 27, "line": "Well, I had to, I mean... Kelly was not even...", "order": 30943, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 6, "scene": 27, "line": "[speaking into the mallard and looking a Dwight] Hi buddy.", "order": 30944, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 6, "scene": 27, "line": "[walks into Jim's office and takes out ear piece] I'm sorry.", "order": 30945, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 6, "scene": 27, "line": "A wooden duck?", "order": 30946, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 6, "scene": 27, "line": "Mallard. I put it in your office in order to surveil you. I was jealous that you got the promotion over me.", "order": 30947, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 6, "scene": 27, "line": "Okay, just to be clear, you're terrible at this and you are not equipped for espionage...", "order": 30948, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 6, "scene": 27, "line": "Oh, I'm equipped... I can...", "order": 30949, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 6, "scene": 27, "line": "Silence.", "order": 30950, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 6, "scene": 27, "line": "Don't tell Michael...", "order": 30951, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 6, "scene": 27, "line": "I won't. But, you will wash and buff our car.", "order": 30952, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 6, "scene": 27, "line": "Punishment fits the crime, I accept. [shakes Jim's hand and leaves]", "order": 30953, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 6, "scene": 28, "line": "[leaving his office with his rum] Night everyone. Night Pam, thanks again for the rum.", "order": 30954, "speaker": "Michael Scott"},
  {"season": 6, "episode": 6, "scene": 28, "line": "Night Michael.", "order": 30955, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 6, "scene": 28, "line": "Night Erin.", "order": 30956, "speaker": "Michael Scott"},
  {"season": 6, "episode": 6, "scene": 29, "line": "[in the conference room] Hey Pam, can I see you for a second? So, Dwight heard you were having a really rough day. So he generously offered to wash our car.", "order": 30957, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 6, "scene": 29, "line": "[looking out the window at Dwight washing the car] Awww, he did that for me?", "order": 30958, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 6, "scene": 29, "line": "Yes, he did. You know what was nice, nights swimming in Bayou bay.", "order": 30959, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 6, "scene": 29, "line": "Mmmm.", "order": 30960, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 6, "scene": 29, "line": "[Michael is seen debating on whether to come back in to the office] Remember that older couple whose kids were also named Jim and Pam?", "order": 30961, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 6, "scene": 29, "line": "Haha, yeah. Say more nice things.", "order": 30962, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 6, "scene": 29, "line": "Well, we went on a Segway tour and we're awesome at it and Frank and Beans!", "order": 30963, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 6, "scene": 29, "line": "Maybe I'm overreacting.", "order": 30964, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 6, "scene": 29, "line": "Yeah... maybe.", "order": 30965, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 6, "scene": 29, "line": "But I don't think I am.", "order": 30966, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 6, "scene": 29, "line": "You're not, nope. Nope.", "order": 30967, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 6, "scene": 30, "line": "[Ryan is walking out of the office ahead of him] Oh... where'd you get that hat?", "order": 30968, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 6, "scene": 30, "line": "I'd rather not say.", "order": 30969, "speaker": "Ryan Howard"},
  {"season": 6, "episode": 6, "scene": 31, "line": "[walks into Jims office, twists the top of the pen that he placed in Jim's pen holder which has a bug in it, pushes play] [Jim's voice] 'We have our high quality 28 pound bond, our heavier 38 pound bond, or our...' [pauses recording] I've got eight hours of this. Of course I wanted Jim to find the mallard, make him feel safe. Did you really think I would put my primary listening device in a wooden mallard? I'm not insane. [starts the recording again] '...65 pound cover stock, which is the heaviest paper that will still feed smoothly through your desktop printer.'", "order": 30970, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 7, "scene": 1, "line": "Welcome children of the Scranton Industrial Park Community.", "order": 30971, "speaker": "Michael Scott"},
  {"season": 6, "episode": 7, "scene": 1, "line": "YAY!", "order": 30972, "speaker": "Kids"},
  {"season": 6, "episode": 7, "scene": 1, "line": "Join your gangsta pumpkin on his palette truck of doom. Don't worry about a thing. Rest assured you will see me later. Bwah ha ha... [bumps into something] ... ha ha ha.", "order": 30973, "speaker": "Michael Scott"},
  {"season": 6, "episode": 7, "scene": 2, "line": "We are doing a haunted house this Halloween. Which is actually kinda spooky because, as legend has it, on this very site there used to be a productive paper company.", "order": 30974, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 7, "scene": 3, "line": "I am going to scare these kids so bad.", "order": 30975, "speaker": "Michael Scott"},
  {"season": 6, "episode": 7, "scene": 4, "line": "This is the spookiest warehouse in the world, kids. You don't believe me? Just take a look. [Kevin chops into Erin, who is dressed as Princess Fiona] Oh. Scary, huh? This is a surgery with an octopus and a burn victim.", "order": 30976, "speaker": "Darryl Philbin"},
  {"season": 6, "episode": 7, "scene": 4, "line": "Black widow.", "order": 30977, "speaker": "Angela Martin"},
  {"season": 6, "episode": 7, "scene": 4, "line": "Lulu from The Fifth Element.", "order": 30978, "speaker": "Kelly Kapoor"},
  {"season": 6, "episode": 7, "scene": 4, "line": "Nobody told me what people were, alright? So... label yourselves or take what you get.", "order": 30979, "speaker": "Darryl Philbin"},
  {"season": 6, "episode": 7, "scene": 4, "line": "I want to sell your blood!", "order": 30980, "speaker": "Creed Bratton"},
  {"season": 6, "episode": 7, "scene": 4, "line": "That's really not the trend in vampires right now.", "order": 30981, "speaker": "Ryan Howard"},
  {"season": 6, "episode": 7, "scene": 4, "line": "Here is an old man and a goth dude... and then the old crone from Drag Me to Hell.", "order": 30982, "speaker": "Darryl Philbin"},
  {"season": 6, "episode": 7, "scene": 4, "line": "I'm a hobo.", "order": 30983, "speaker": "Meredith Palmer"},
  {"season": 6, "episode": 7, "scene": 4, "line": "I asked for a list. [Dwight rides in on a tricycle, laughing diabolically] ...and a clown.", "order": 30984, "speaker": "Darryl Philbin"},
  {"season": 6, "episode": 7, "scene": 4, "line": "I'm Jigsaw, idiot.", "order": 30985, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 7, "scene": 4, "line": "You're not as scary as Book Face, over there.", "order": 30986, "speaker": "Darryl Philbin"},
  {"season": 6, "episode": 7, "scene": 4, "line": "Yes. I am the popular social networking site known as Book Face.", "order": 30987, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 7, "scene": 4, "line": "Ok, kids. You've all been so good and unbelievably patient so I think you are gonna get some candy!", "order": 30988, "speaker": "Darryl Philbin"},
  {"season": 6, "episode": 7, "scene": 4, "line": "Yeah! [a chair falls over, Michael is seen hanging from the ceiling with a noose around his neck - the kids scream]", "order": 30989, "speaker": "Kids"},
  {"season": 6, "episode": 7, "scene": 4, "line": "Kids, just remember, suicide is never the answer. Alright?", "order": 30990, "speaker": "Michael Scott"},
  {"season": 6, "episode": 7, "scene": 5, "line": "Why is Christmas the only holiday that can have a message?", "order": 30991, "speaker": "Michael Scott"},
  {"season": 6, "episode": 7, "scene": 6, "line": "It is the easy way out. You are not alone.", "order": 30992, "speaker": "Michael Scott"},
  {"season": 6, "episode": 7, "scene": 6, "line": "What the hell is wrong with you?", "order": 30993, "speaker": "Darryl Philbin"},
  {"season": 6, "episode": 7, "scene": 6, "line": "Who wants candy?", "order": 30994, "speaker": "Michael Scott"},
  {"season": 6, "episode": 7, "scene": 7, "line": "...and then I think I'm going to go to the Garlic Festival.", "order": 30995, "speaker": "Michael Scott"},
  {"season": 6, "episode": 7, "scene": 7, "line": "Wow.", "order": 30996, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 7, "scene": 7, "line": "Sounds like fun. You guys would love it.", "order": 30997, "speaker": "Michael Scott"},
  {"season": 6, "episode": 7, "scene": 7, "line": "I bet we would.", "order": 30998, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 7, "scene": 7, "line": "They have a TCBY booth.", "order": 30999, "speaker": "Michael Scott"},
  {"season": 6, "episode": 7, "scene": 7, "line": "Cool.", "order": 31000, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 7, "scene": 7, "line": "The same stuff you get downtown.", "order": 31001, "speaker": "Michael Scott"},
  {"season": 6, "episode": 7, "scene": 7, "line": "Mmm-hmm.", "order": 31002, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 7, "scene": 7, "line": "Do you like TCBY?", "order": 31003, "speaker": "Michael Scott"},
  {"season": 6, "episode": 7, "scene": 7, "line": "Who doesn't?", "order": 31004, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 7, "scene": 7, "line": "I can't believe it's- I can't believe it's yogurt. Uh... it'll be fun", "order": 31005, "speaker": "Michael Scott"},
  {"season": 6, "episode": 7, "scene": 7, "line": "I confirmed with Raskin Design and they're expecting the both of you in an hour.", "order": 31006, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 7, "scene": 7, "line": "Oh. Actually, it's just gonna be me.", "order": 31007, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 7, "scene": 7, "line": "They said the both of you.", "order": 31008, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 7, "scene": 7, "line": "That is a mistake.", "order": 31009, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 7, "scene": 7, "line": "You should give them a call. Check that out.", "order": 31010, "speaker": "Michael Scott"},
  {"season": 6, "episode": 7, "scene": 7, "line": "Alright. Let's clear this up. [on phone] Well, there's actually been a few changes in the company and Michael and I are actually at the same level. So, I can- no. Nope. Why would that be a problem?", "order": 31011, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 7, "scene": 8, "line": "Jim's a good kid. He can handle a lot but sometimes you have to call in a master. I, uh... why would you date an amateur when you could date a professional?", "order": 31012, "speaker": "Michael Scott"},
  {"season": 6, "episode": 7, "scene": 9, "line": "Great. See ya' then.", "order": 31013, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 7, "scene": 9, "line": "Wha- what?", "order": 31014, "speaker": "Michael Scott"},
  {"season": 6, "episode": 7, "scene": 9, "line": "They said that they would feel more comfortable if you came along.", "order": 31015, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 7, "scene": 9, "line": "Why don't they just want you to go by yourself? Why do they want me to come, too? I don't understand.", "order": 31016, "speaker": "Michael Scott"},
  {"season": 6, "episode": 7, "scene": 9, "line": "I absolutely don't understand, either.", "order": 31017, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 7, "scene": 9, "line": "I don't want you to feel like I'm baby-sitting you, or something. Alright.", "order": 31018, "speaker": "Michael Scott"},
  {"season": 6, "episode": 7, "scene": 9, "line": "Alright.", "order": 31019, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 7, "scene": 9, "line": "Let me get your stroller.", "order": 31020, "speaker": "Michael Scott"},
  {"season": 6, "episode": 7, "scene": 10, "line": "Sure. Michael's a good teacher. A teacher is someone who stands right next to you your whole life and never lets you do anything. That's what a teacher is, right?", "order": 31021, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 7, "scene": 11, "line": "What are you gonna call him?", "order": 31022, "speaker": "Michael Scott"},
  {"season": 6, "episode": 7, "scene": 11, "line": "Dave.", "order": 31023, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 7, "scene": 11, "line": "No. What is his name?", "order": 31024, "speaker": "Michael Scott"},
  {"season": 6, "episode": 7, "scene": 11, "line": "Dave.", "order": 31025, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 7, "scene": 11, "line": "Mr. Bourchard. They're very formal.", "order": 31026, "speaker": "Michael Scott"},
  {"season": 6, "episode": 7, "scene": 11, "line": "Yeah. I've spoken to them on the phone.", "order": 31027, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 7, "scene": 11, "line": "Yeah, well... this is not the phone. This is real life, baby, and you gotta own it. Is that what you are wearing?", "order": 31028, "speaker": "Michael Scott"},
  {"season": 6, "episode": 7, "scene": 11, "line": "Yes, it is.", "order": 31029, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 7, "scene": 11, "line": "...and that is the watch that you are going to wear? No. It is not. You should wear this watch. I will loan it to you. It is a Tankard. I highly recommend you wear that.", "order": 31030, "speaker": "Michael Scott"},
  {"season": 6, "episode": 7, "scene": 11, "line": "No thanks.", "order": 31031, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 7, "scene": 11, "line": "They are into style.", "order": 31032, "speaker": "Michael Scott"},
  {"season": 6, "episode": 7, "scene": 11, "line": "Mmm-hmm.", "order": 31033, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 7, "scene": 11, "line": "They are into appearance. We are selling success.", "order": 31034, "speaker": "Michael Scott"},
  {"season": 6, "episode": 7, "scene": 11, "line": "...and paper.", "order": 31035, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 7, "scene": 11, "line": "That's sorta secondary. [offers Jim the watch again]", "order": 31036, "speaker": "Michael Scott"},
  {"season": 6, "episode": 7, "scene": 11, "line": "Nope. No.", "order": 31037, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 7, "scene": 12, "line": "He's trying to micro-co-manage me... or co-micro... manage... me.", "order": 31038, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 7, "scene": 13, "line": "Here we are outside-", "order": 31039, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 7, "scene": 13, "line": "WB!", "order": 31040, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 7, "scene": 13, "line": "- the Wilkes-Barre Industrial Park.", "order": 31041, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 7, "scene": 13, "line": "Industrial P.! Makin' cold calls.", "order": 31042, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 7, "scene": 13, "line": "The two people with the lowest sales in the quarter have to do them.", "order": 31043, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 7, "scene": 14, "line": "[singing] Where are you? Dean Trophies. Suite 100. Sherman Blinds and Rugs. Suite 202.", "order": 31044, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 7, "scene": 14, "line": "Here it is. [Andy is still singing] Suite 401.", "order": 31045, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 7, "scene": 14, "line": "Yeah. I was gonna sing that part.", "order": 31046, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 7, "scene": 14, "line": "I know. Now you don't have to.", "order": 31047, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 7, "scene": 14, "line": "Except it was going to resolve the melody, so now my head hurts. Feels like I held in a sneeze. Mmm! I hate this feeling. [sings] Suite 401.", "order": 31048, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 7, "scene": 15, "line": "Alright. Hi. Any messages?", "order": 31049, "speaker": "Michael Scott"},
  {"season": 6, "episode": 7, "scene": 15, "line": "You're soaking wet.", "order": 31050, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 7, "scene": 15, "line": "Uh, well, Jim and I got caught in a little flash rain, flash wind, flash lightning.", "order": 31051, "speaker": "Michael Scott"},
  {"season": 6, "episode": 7, "scene": 15, "line": "Wow. Sounds scary.", "order": 31052, "speaker": "Phyllis Vance"},
  {"season": 6, "episode": 7, "scene": 15, "line": "It was. It was. And then in an instant it wasn't.", "order": 31053, "speaker": "Michael Scott"},
  {"season": 6, "episode": 7, "scene": 15, "line": "Why isn't Jim wet?", "order": 31054, "speaker": "Angela Martin"},
  {"season": 6, "episode": 7, "scene": 15, "line": "I outran it.", "order": 31055, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 7, "scene": 15, "line": "I don't think it rained. My hip would be throbbing.", "order": 31056, "speaker": "Meredith Palmer"},
  {"season": 6, "episode": 7, "scene": 15, "line": "It rained.", "order": 31057, "speaker": "Michael Scott"},
  {"season": 6, "episode": 7, "scene": 15, "line": "Michael, can I get you something? A towel? Some cocoa?", "order": 31058, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 7, "scene": 15, "line": "Nothing. Cocoa.", "order": 31059, "speaker": "Michael Scott"},
  {"season": 6, "episode": 7, "scene": 15, "line": "I'll just leave that suit in your office then.", "order": 31060, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 7, "scene": 15, "line": "Good. Yep.", "order": 31061, "speaker": "Michael Scott"},
  {"season": 6, "episode": 7, "scene": 16, "line": "Must be nice to have company on these cold calls.", "order": 31062, "speaker": "Secretary"},
  {"season": 6, "episode": 7, "scene": 16, "line": "We're kind of a dynamic duo. Or trio. [points to Pam's tummy]", "order": 31063, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 7, "scene": 16, "line": "How exciting! Do you guys know the sex yet?", "order": 31064, "speaker": "Secretary"},
  {"season": 6, "episode": 7, "scene": 16, "line": "[Andy shakes his head 'no'] Oh. No, no. [laughing] We're not together. No, no, no. Definitely not. Definitely not. No. We just work together.", "order": 31065, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 7, "scene": 16, "line": "[miffed] Looks like somebody's got a case of the definitelies.", "order": 31066, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 7, "scene": 17, "line": "[Michael is walking around in one of Jim's suits] Um, Michael? The custodian from Raskin Design is on the line. He said they found your keys in the Koi pond.", "order": 31067, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 7, "scene": 17, "line": "Ok. Thank you.", "order": 31068, "speaker": "Michael Scott"},
  {"season": 6, "episode": 7, "scene": 17, "line": "Did you say 'Koi pond'?", "order": 31069, "speaker": "Stanley Hudson"},
  {"season": 6, "episode": 7, "scene": 18, "line": "[phone rings] Yeah?", "order": 31070, "speaker": "Michael Scott"},
  {"season": 6, "episode": 7, "scene": 18, "line": "Michael, people are asking questions.", "order": 31071, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 7, "scene": 18, "line": "Ok. Put them on speaker. Hey guys, what's going on?", "order": 31072, "speaker": "Michael Scott"},
  {"season": 6, "episode": 7, "scene": 18, "line": "Michael, did you you fall into a Koi pond?", "order": 31073, "speaker": "Phyllis Vance"},
  {"season": 6, "episode": 7, "scene": 18, "line": "Uh... I can't really hear you. I think we have sort of a bad connection.", "order": 31074, "speaker": "Michael Scott"},
  {"season": 6, "episode": 7, "scene": 18, "line": "Jim, did Michael fall into a Koi pond?", "order": 31075, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 7, "scene": 18, "line": "Mmm... it's like Michael said. It was some- something else.", "order": 31076, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 7, "scene": 18, "line": "It was- Ok. This is what it was. It was these bunch of idiots who had put a fish tank in the ground with no cover and no railing.", "order": 31077, "speaker": "Michael Scott"},
  {"season": 6, "episode": 7, "scene": 18, "line": "So you fell in?", "order": 31078, "speaker": "Angela Martin"},
  {"season": 6, "episode": 7, "scene": 18, "line": "No. Maybe I was trying to save a child that had fallen in.", "order": 31079, "speaker": "Michael Scott"},
  {"season": 6, "episode": 7, "scene": 18, "line": "So a child had fallen in?", "order": 31080, "speaker": "Angela Martin"},
  {"season": 6, "episode": 7, "scene": 18, "line": "Not yet!", "order": 31081, "speaker": "Michael Scott"},
  {"season": 6, "episode": 7, "scene": 18, "line": "That is hilarious. [extends hand for a fist bump]", "order": 31082, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 7, "scene": 18, "line": "No it is- don't! I'm not gonna bump. I'm not gonna bump. And it was not hilarious. It was very, very terrifying. [Stanley laughs]", "order": 31083, "speaker": "Michael Scott"},
  {"season": 6, "episode": 7, "scene": 19, "line": "Truthfully, it wasn't the way he fell in. It was... how long it took him to get out.", "order": 31084, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 7, "scene": 20, "line": "And we'd like to offer you 15% off your first purchase as our way of welcoming you to the area.", "order": 31085, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 7, "scene": 20, "line": "Well that sounds like a really nice deal.", "order": 31086, "speaker": "Customer"},
  {"season": 6, "episode": 7, "scene": 20, "line": "Sha-bow.", "order": 31087, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 7, "scene": 20, "line": "And I must say, that since we are a family business it is nice to see that you are, too.", "order": 31088, "speaker": "Customer"},
  {"season": 6, "episode": 7, "scene": 20, "line": "Oh, wow. You thought that- oh, my gosh. Oh. Definitely not.", "order": 31089, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 7, "scene": 20, "line": "My mistake. Sorry.", "order": 31090, "speaker": "Customer"},
  {"season": 6, "episode": 7, "scene": 20, "line": "It's ok.", "order": 31091, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 7, "scene": 20, "line": "Nuh. Actually, it's kinda not ok. Um, I date models. Face models. My girlfriend, on a scale of one to Giselle, uh... a nine.", "order": 31092, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 7, "scene": 20, "line": "That- that's good for you.", "order": 31093, "speaker": "Customer"},
  {"season": 6, "episode": 7, "scene": 20, "line": "Anyway, we also have a special on envelopes.", "order": 31094, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 7, "scene": 20, "line": "Pam's carrying our surrogate. Because my girlfriend needed to keep her figure for fashion week. So, we, uh, we put our baby in Pam. Doesn't matter what Pam looks like.", "order": 31095, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 7, "scene": 20, "line": "Yeah.", "order": 31096, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 7, "scene": 21, "line": "You were way meaner to me than I was to you.", "order": 31097, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 7, "scene": 21, "line": "No I wasn't. Ok. The very idea of us together made you burst out laughing like you just bit into an Adam Sandler and jelly sandwich.", "order": 31098, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 7, "scene": 21, "line": "You blew the sale, you idiot.", "order": 31099, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 7, "scene": 21, "line": "Let me tell you something. I was never gonna make that sale.", "order": 31100, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 7, "scene": 22, "line": "Erin, do we have any of those clips that hold paper together?", "order": 31101, "speaker": "Michael Scott"},
  {"season": 6, "episode": 7, "scene": 22, "line": "Staples? [Kevin clears his throat] Uh, David Wallace called.", "order": 31102, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 7, "scene": 22, "line": "Oh. He did? What did he say?", "order": 31103, "speaker": "Michael Scott"},
  {"season": 6, "episode": 7, "scene": 22, "line": "He heard you made a big splash at the meeting. Oh, my God. That was so mean what I just said and I didn't mean it. It was Kevin and Meredith put me up to it.", "order": 31104, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 7, "scene": 22, "line": "Ok.", "order": 31105, "speaker": "Michael Scott"},
  {"season": 6, "episode": 7, "scene": 22, "line": "[whispers to Erin] I'm gonna kill you.", "order": 31106, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 7, "scene": 22, "line": "Michael, don't listen to them.", "order": 31107, "speaker": "Stanley Hudson"},
  {"season": 6, "episode": 7, "scene": 22, "line": "Thank you, Stanley.", "order": 31108, "speaker": "Michael Scott"},
  {"season": 6, "episode": 7, "scene": 22, "line": "You just ignore their carp.", "order": 31109, "speaker": "Stanley Hudson"},
  {"season": 6, "episode": 7, "scene": 22, "line": "Ok.", "order": 31110, "speaker": "Michael Scott"},
  {"season": 6, "episode": 7, "scene": 22, "line": "Michael?", "order": 31111, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 7, "scene": 22, "line": "Yes.", "order": 31112, "speaker": "Michael Scott"},
  {"season": 6, "episode": 7, "scene": 22, "line": "A carp is both a fish and a term for complaing. They're mocking you with wordplay.", "order": 31113, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 7, "scene": 22, "line": "Hey, boss, did you find Nemo?", "order": 31114, "speaker": "Creed Bratton"},
  {"season": 6, "episode": 7, "scene": 22, "line": "I can name Pixar movies, too. Toy Story.", "order": 31115, "speaker": "Michael Scott"},
  {"season": 6, "episode": 7, "scene": 22, "line": "Don't you mean 'Koi Story'?", "order": 31116, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 7, "scene": 22, "line": "And when you fell in, did you flounder?", "order": 31117, "speaker": "Phyllis Vance"},
  {"season": 6, "episode": 7, "scene": 22, "line": "Michael, flounder is both a kind of fish and -", "order": 31118, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 7, "scene": 22, "line": "I know what a flounder is.", "order": 31119, "speaker": "Michael Scott"},
  {"season": 6, "episode": 7, "scene": 23, "line": "I'm not usually the butt of the joke. I'm usually the face of the joke. I wish Jim had fallen into that pond and he'd have to put on my suit and it'd be too short and he'd look... Damn it! He'd still look good.", "order": 31120, "speaker": "Michael Scott"},
  {"season": 6, "episode": 7, "scene": 24, "line": "The most fundamental thing about sensitivity training is that you cannot make fun of a person for something or some action that they have done that they regret. Show of hands- who has been 'Koi-ponded'? Who here's been the butt of a joke that has gone too far? Phyllis?", "order": 31121, "speaker": "Michael Scott"},
  {"season": 6, "episode": 7, "scene": 24, "line": "Michael, you make fun of us every day.", "order": 31122, "speaker": "Phyllis Vance"},
  {"season": 6, "episode": 7, "scene": 24, "line": "Uh...", "order": 31123, "speaker": "Michael Scott"},
  {"season": 6, "episode": 7, "scene": 24, "line": "Yeah. Every single day.", "order": 31124, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 7, "scene": 24, "line": "You never said anything.", "order": 31125, "speaker": "Michael Scott"},
  {"season": 6, "episode": 7, "scene": 24, "line": "Uh, we have. Countless times.", "order": 31126, "speaker": "Meredith Palmer"},
  {"season": 6, "episode": 7, "scene": 24, "line": "Well, it is hard to tell the difference between you guys saying, 'Stop because I want you to stop' or STOP as in 'Stop. You're making me laugh so hard. What you're doing is so funny. You are on a roll. I am busting a gut. Stop!'", "order": 31127, "speaker": "Michael Scott"},
  {"season": 6, "episode": 7, "scene": 24, "line": "That's never the case.", "order": 31128, "speaker": "Angela Martin"},
  {"season": 6, "episode": 7, "scene": 24, "line": "We are going to make a 'Do Not Mock' list. Ok? Anything that we think might be out of bounds, we put on this list. Anything you put on this list you cannot be teased about. Got it? I'm gonna kick it off. Let's see what I have to put on the list, right? [writes 'Koi pond' on the list] Ok. I also have fallen into the fountain at the Steamtown Mall. Ok. Who else? Who else? Dwight, come on.", "order": 31129, "speaker": "Michael Scott"},
  {"season": 6, "episode": 7, "scene": 24, "line": "I don't want people making fun of my nose.", "order": 31130, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 7, "scene": 24, "line": "Your nose?", "order": 31131, "speaker": "Michael Scott"},
  {"season": 6, "episode": 7, "scene": 24, "line": "It's too small.", "order": 31132, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 7, "scene": 24, "line": "Alright.", "order": 31133, "speaker": "Michael Scott"},
  {"season": 6, "episode": 7, "scene": 24, "line": "Oh my. That is small.", "order": 31134, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 7, "scene": 24, "line": "Just, write it down, please.", "order": 31135, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 7, "scene": 24, "line": "Can you breathe okay?", "order": 31136, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 7, "scene": 24, "line": "What keeps your glasses on?", "order": 31137, "speaker": "Kelly Kapoor"},
  {"season": 6, "episode": 7, "scene": 24, "line": "Hey! It's on the list, everybody.", "order": 31138, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 7, "scene": 24, "line": "No. I haven't finished writing. Did you sneeze it off? That's it. No more. Ok. Who else? Who else? Everybody's getting their chance.", "order": 31139, "speaker": "Michael Scott"},
  {"season": 6, "episode": 7, "scene": 24, "line": "I don't want people making fun of my weight.", "order": 31140, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 7, "scene": 24, "line": "Ok. That's to broad. It's gotta be something else. How 'bout your stomach? Yeah? [writes Huge Gut] Meredith?", "order": 31141, "speaker": "Michael Scott"},
  {"season": 6, "episode": 7, "scene": 24, "line": "I don't want to say it out loud.", "order": 31142, "speaker": "Meredith Palmer"},
  {"season": 6, "episode": 7, "scene": 24, "line": "Ok. Fine. Come on up here. Write it yourself. And don't sign your name to it. And nobody look. Everybody look away. Look away.", "order": 31143, "speaker": "Michael Scott"},
  {"season": 6, "episode": 7, "scene": 25, "line": "I really didn't want to put it on the board but I thought maybe it was gonna come out somehow so... what are you gonna do? [she writes 'sex with a terrorist']", "order": 31144, "speaker": "Meredith Palmer"},
  {"season": 6, "episode": 7, "scene": 26, "line": "Ok.", "order": 31145, "speaker": "Michael Scott"},
  {"season": 6, "episode": 7, "scene": 27, "line": "That is our sales pitch and we are stickin' to it.", "order": 31146, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 7, "scene": 27, "line": "Well, you two are quite the salesmen and a very cute couple.", "order": 31147, "speaker": "Keena Gifford"},
  {"season": 6, "episode": 7, "scene": 27, "line": "Well-", "order": 31148, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 7, "scene": 27, "line": "Oh- [both look at each other and together say] Thank you.", "order": 31149, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 7, "scene": 27, "line": "Some couples don't seem like a good match. You two do.", "order": 31150, "speaker": "Keena Gifford"},
  {"season": 6, "episode": 7, "scene": 27, "line": "Well, you know.", "order": 31151, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 7, "scene": 27, "line": "Yeah. You know. Two peas in a pod. We complete each other. What can I say? She loves to cook-", "order": 31152, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 7, "scene": 27, "line": "He loves to eat.", "order": 31153, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 7, "scene": 27, "line": "I love to dance-", "order": 31154, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 7, "scene": 27, "line": "I love to watch him dance.", "order": 31155, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 7, "scene": 27, "line": "Right. Sometimes I'll just dance for hours in the living room-", "order": 31156, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 7, "scene": 27, "line": "And I'll just watch him.", "order": 31157, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 7, "scene": 27, "line": "And a baby on the way? You must be so excited.", "order": 31158, "speaker": "Keena Gifford"},
  {"season": 6, "episode": 7, "scene": 27, "line": "Yes.", "order": 31159, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 7, "scene": 27, "line": "Yeah. We are thrilled.", "order": 31160, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 7, "scene": 27, "line": "Mmm-hmm.", "order": 31161, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 7, "scene": 27, "line": "In fact, we spent the whole weekend researching various birthing coaches. Wasn't that fun, honey?", "order": 31162, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 7, "scene": 27, "line": "It was, sweetie.", "order": 31163, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 7, "scene": 27, "line": "Oh. I know the best teacher. Her name is Miss Janet.", "order": 31164, "speaker": "Keena Gifford"},
  {"season": 6, "episode": 7, "scene": 27, "line": "Yeah. On Clearview Avenue.", "order": 31165, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 7, "scene": 27, "line": "Yeah!", "order": 31166, "speaker": "Keena Gifford"},
  {"season": 6, "episode": 7, "scene": 27, "line": "Yeah.", "order": 31167, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 7, "scene": 28, "line": "This sounds ridiculous, I know, but some people say that I eat like a squirrel.", "order": 31168, "speaker": "Angela Martin"},
  {"season": 6, "episode": 7, "scene": 28, "line": "So now you're comparing yourself to a cute, tiny animal?", "order": 31169, "speaker": "Phyllis Vance"},
  {"season": 6, "episode": 7, "scene": 28, "line": "Um, Michael? The custodian called again. Apparently a Koi has died. [everyone shows remorse]", "order": 31170, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 7, "scene": 28, "line": "It's a fish.", "order": 31171, "speaker": "Michael Scott"},
  {"season": 6, "episode": 7, "scene": 28, "line": "They want you to pay for it.", "order": 31172, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 7, "scene": 28, "line": "It could've died of natural causes. So...", "order": 31173, "speaker": "Michael Scott"},
  {"season": 6, "episode": 7, "scene": 28, "line": "Well, they said you stepped on it's head. He did not suffer.", "order": 31174, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 7, "scene": 28, "line": "When is the funeral?", "order": 31175, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 7, "scene": 28, "line": "Do not mock, Oscar. Do not mock. How much do they want?", "order": 31176, "speaker": "Michael Scott"},
  {"season": 6, "episode": 7, "scene": 28, "line": "300", "order": 31177, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 7, "scene": 28, "line": "What? No. I could get a fish for a 5 cent worm.", "order": 31178, "speaker": "Michael Scott"},
  {"season": 6, "episode": 7, "scene": 28, "line": "Oh, you're payin' way too much for your worms, man. Who's your worm guy?", "order": 31179, "speaker": "Creed Bratton"},
  {"season": 6, "episode": 7, "scene": 28, "line": "Ok. Great meeting, everybody. That's it.", "order": 31180, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 7, "scene": 28, "line": "No, no.", "order": 31181, "speaker": "Michael Scott"},
  {"season": 6, "episode": 7, "scene": 28, "line": "Thank you. [everyone begins to leave]", "order": 31182, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 7, "scene": 28, "line": "No. We are not done here. This-", "order": 31183, "speaker": "Michael Scott"},
  {"season": 6, "episode": 7, "scene": 29, "line": "One of the baby books suggets the best birthing posture is on all fours, like an animal. I just wish I had a special telephone so I could find out exactly wht the little soy bean wants. Right? Hey, little soy bean. What do you want? Right. How do- It- I felt it kick!", "order": 31184, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 7, "scene": 29, "line": "That's great!", "order": 31185, "speaker": "Keena Gifford"},
  {"season": 6, "episode": 7, "scene": 29, "line": "Oh, my gosh. Like a little magical foot just high-fived me.", "order": 31186, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 7, "scene": 29, "line": "Yeah, well, that'll happen.", "order": 31187, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 7, "scene": 29, "line": "Oh, my gosh. It's like he's trying to say, 'I love you, too, Daddy'. I love you, too. [leans over and kisses Pam's belly]", "order": 31188, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 7, "scene": 29, "line": "Sometimes we're so excited we forget where we are. Like at a business meeting.", "order": 31189, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 7, "scene": 29, "line": "Message received, little soy bean.", "order": 31190, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 7, "scene": 30, "line": "I think you just gotta ride this one out, man.", "order": 31191, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 7, "scene": 30, "line": "No, Jim. You don't understand. Things like this don't just die. Kids in high school still call me 'Ponytail'.", "order": 31192, "speaker": "Michael Scott"},
  {"season": 6, "episode": 7, "scene": 30, "line": "No, they don't.", "order": 31193, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 7, "scene": 30, "line": "Yes, they do, Jim. Because of the time I got my ponytail stuck in the power trail.", "order": 31194, "speaker": "Michael Scott"},
  {"season": 6, "episode": 7, "scene": 30, "line": "Maybe if you make fun of yourself it'll all go away.", "order": 31195, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 7, "scene": 30, "line": "I want to make fun of you right now.", "order": 31196, "speaker": "Michael Scott"},
  {"season": 6, "episode": 7, "scene": 30, "line": "Really? Do it. I am a big, stupid goofball.", "order": 31197, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 7, "scene": 30, "line": "No. Don't do that. You're not. You're not stupid.", "order": 31198, "speaker": "Michael Scott"},
  {"season": 6, "episode": 7, "scene": 30, "line": "See?", "order": 31199, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 7, "scene": 30, "line": "Oh, my God.", "order": 31200, "speaker": "Michael Scott"},
  {"season": 6, "episode": 7, "scene": 31, "line": "Hey. I- I just wanna say that I cannot believe that I walked into a Koi pond. I mean, seriously. Walk much? [everyone starts laughing] Oh. I should wear a snorkel to the next business meeting that I go to.", "order": 31201, "speaker": "Michael Scott"},
  {"season": 6, "episode": 7, "scene": 31, "line": "Michael. You know, when you think about it, it's not all your fault. I mean, who puts a Koi pond in a lobby?", "order": 31202, "speaker": "Phyllis Vance"},
  {"season": 6, "episode": 7, "scene": 31, "line": "Well, you know what? You're right, Phyllis, but I've been there before. I've seen that pond. This is the thing, I am a world class moron. That's the problem.", "order": 31203, "speaker": "Michael Scott"},
  {"season": 6, "episode": 7, "scene": 31, "line": "Michael, please. Stop it now. You're embarrassing yourself.", "order": 31204, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 7, "scene": 31, "line": "It's ok. We're having fun. It's not actually the first time I've been embarrassed by a pond. In high school, the girls volleyball team always used to hrow me into the frozen lake. Four years in a row. Oh... it was freezing. [Jim signals for making to stop] No. No. No. Oh, this is even worse. [Michael begins to lose his cool] Couple weeks ago I went to get a new cellphone and I wanted on of those packages where you have, you know, the friends- the five- the friends and family thing and the guys was like, 'Who are your 5 friends?' and I'm, like, 'Uh... ' I didn't even know I couldn't even think. [realizing he's going downhill] Oh, my God. It was so embarrassing. That was- oh. I don't even have Jan's cell phone number and I hate her! She won't give it to me. I was like, 'Oh, I guess I'm a loser. 'A luh-whooooo a za her'. Too far! God! Thanks a lot, man. Thanks for the advice.", "order": 31205, "speaker": "Michael Scott"},
  {"season": 6, "episode": 7, "scene": 32, "line": "[watching Angela eat something] Enjoying your nut?", "order": 31206, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 7, "scene": 32, "line": "Kevin.", "order": 31207, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 7, "scene": 32, "line": "Why?", "order": 31208, "speaker": "Angela Martin"},
  {"season": 6, "episode": 7, "scene": 32, "line": "I'm not mocking. I was just making an observation about a nut.", "order": 31209, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 7, "scene": 32, "line": "I was.", "order": 31210, "speaker": "Angela Martin"},
  {"season": 6, "episode": 7, "scene": 32, "line": "Hey. Who wants to watch Michael's pond dive? My roommate's friend is the night janitor over there. He swiped the security tape for me and he's bringing it over.", "order": 31211, "speaker": "Michael Scott"},
  {"season": 6, "episode": 7, "scene": 32, "line": "Mm... I'm, know what? Maybe we should go easy on Michael, guys. You know, you watch that tape and you're gonna have to stay late for more sensitity training, so...", "order": 31212, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 7, "scene": 32, "line": "We'll stay late.", "order": 31213, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 7, "scene": 33, "line": "Well, that went pretty well.", "order": 31214, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 7, "scene": 33, "line": "Yeah, I guess.", "order": 31215, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 7, "scene": 33, "line": "We got a maybe. It was our first maybe. Going by the Nard Dog curve, I'd say we nailed it.", "order": 31216, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 7, "scene": 33, "line": "If I'm being completely honest, I could've done without the belly kiss.", "order": 31217, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 7, "scene": 33, "line": "You know what? I'm sorry. 'Cause in that moment I knew I was kissing your belly too much.", "order": 31218, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 7, "scene": 33, "line": "Yeah. Way too much.", "order": 31219, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 7, "scene": 33, "line": "Yeah.", "order": 31220, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 7, "scene": 33, "line": "I mean, what the hell was that?", "order": 31221, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 7, "scene": 33, "line": "What the heck was that?", "order": 31222, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 7, "scene": 33, "line": "When you cried?", "order": 31223, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 7, "scene": 33, "line": "Agh- try almost cried, ok? I just got caught up in the fantasy.", "order": 31224, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 7, "scene": 33, "line": "You're fantsy involves comparison shopping birthing classes?", "order": 31225, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 7, "scene": 33, "line": "No. I know I'm gonna go with Miss Janet. I just- I don't know. It was fun to role play, right?", "order": 31226, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 7, "scene": 33, "line": "Hmm.", "order": 31227, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 7, "scene": 33, "line": "I mean, it was fun for me. Having a wife and a little baby. Agh, I'm so sick of being single.", "order": 31228, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 7, "scene": 33, "line": "Well, are you dating anyone?", "order": 31229, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 7, "scene": 33, "line": "What do you think of Erin? I mean- she's- I- She's kinda cool.", "order": 31230, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 7, "scene": 33, "line": "Ah.", "order": 31231, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 7, "scene": 33, "line": "You think I can do better?", "order": 31232, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 7, "scene": 33, "line": "Ah.", "order": 31233, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 7, "scene": 33, "line": "Gotta get my goin' out on.", "order": 31234, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 7, "scene": 34, "line": "Hey, let's watch this thing.", "order": 31235, "speaker": "Meredith Palmer"},
  {"season": 6, "episode": 7, "scene": 34, "line": "Whoa, whoa, whoa. Ok. Yeah. We're not watchin' this.", "order": 31236, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 7, "scene": 34, "line": "How can we not watch this?", "order": 31237, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 7, "scene": 34, "line": "What happened to 'Do Not Mock'?", "order": 31238, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 7, "scene": 34, "line": "We're not mocking, we're watching.", "order": 31239, "speaker": "Phyllis Vance"},
  {"season": 6, "episode": 7, "scene": 34, "line": "That will inevitably lead to mocking. So...", "order": 31240, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 7, "scene": 34, "line": "Well, we'll deal with it as it comes.", "order": 31241, "speaker": "Phyllis Vance"},
  {"season": 6, "episode": 7, "scene": 34, "line": "Is this the tape of me falling?", "order": 31242, "speaker": "Michael Scott"},
  {"season": 6, "episode": 7, "scene": 34, "line": "Yes. Put the DVD in.", "order": 31243, "speaker": "Phyllis Vance"},
  {"season": 6, "episode": 7, "scene": 34, "line": "Open QuickTime.", "order": 31244, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 7, "scene": 34, "line": "It starts on it's own. Let it -", "order": 31245, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 7, "scene": 34, "line": "No! Use QuickTime. Trust me. I've done this.", "order": 31246, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 7, "scene": 34, "line": "Guys. Michael can't handle this and as your boss I'm saying we're not watching it.", "order": 31247, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 7, "scene": 34, "line": "No. It's ok. Watch it. He can't fire all of you.", "order": 31248, "speaker": "Michael Scott"},
  {"season": 6, "episode": 7, "scene": 34, "line": "What are you doing?", "order": 31249, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 7, "scene": 34, "line": "It's alright. I can handle it.", "order": 31250, "speaker": "Michael Scott"},
  {"season": 6, "episode": 7, "scene": 34, "line": "No.", "order": 31251, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 7, "scene": 34, "line": "I am a grown-up, Jim.", "order": 31252, "speaker": "Michael Scott"},
  {"season": 6, "episode": 7, "scene": 34, "line": "Shh. It's on.", "order": 31253, "speaker": "Meredith Palmer"},
  {"season": 6, "episode": 7, "scene": 34, "line": "Here they come. [everyone reacts to seeing Michael fall in] Boom!", "order": 31254, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 7, "scene": 34, "line": "Oh. Did - did you see that?", "order": 31255, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 7, "scene": 34, "line": "See what?", "order": 31256, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 7, "scene": 34, "line": "Why'd you stop it?", "order": 31257, "speaker": "Angela Martin"},
  {"season": 6, "episode": 7, "scene": 34, "line": "Oscar, yes. You're right to stop it. Thank you. Eject it.", "order": 31258, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 7, "scene": 34, "line": "Jim, you let Michael fall in.", "order": 31259, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 7, "scene": 34, "line": "Play it again. [everyone has the same reaction as before] He purposefully leaned away and let you fall in.", "order": 31260, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 7, "scene": 34, "line": "Oh... man. I thinke when I started to see you go in I think I just froze.", "order": 31261, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 7, "scene": 34, "line": "I don't think you froze.", "order": 31262, "speaker": "Michael Scott"},
  {"season": 6, "episode": 7, "scene": 34, "line": "It's a killer new dance move. [re-enacts leaning back] Do you wanna talk in your office?", "order": 31263, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 7, "scene": 34, "line": "No.", "order": 31264, "speaker": "Angela Martin"},
  {"season": 6, "episode": 7, "scene": 34, "line": "I didn't ask you. [back to Michael] Would you like to talk? [walks away]", "order": 31265, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 7, "scene": 34, "line": "Judas.", "order": 31266, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 7, "scene": 35, "line": "Jim is my enemy. But it turns out that Jim is also his own worst enemy. And the enemy of my enemy is my friend. So, Jim is actually my friend. But... because he is his own worst enemy, the enemy of my friend is my enemy. So, actually Jim is my enemy. But-", "order": 31267, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 7, "scene": 36, "line": "I should've grabbed you. I'm sorry. Look, I thought I could've done today's sales call alone. I mean, I may never be as good a salesman as you are, but I at least need the chance to do the job.", "order": 31268, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 7, "scene": 37, "line": "Jim is jealous of me? Jim is jealous of me.", "order": 31269, "speaker": "Michael Scott"},
  {"season": 6, "episode": 7, "scene": 38, "line": "[as Jim enters the kitchen] Hey, what's up lifeguard?", "order": 31270, "speaker": "Meredith Palmer"},
  {"season": 6, "episode": 7, "scene": 38, "line": "Jim, I think I'm in your way. [leans back - they both exit]", "order": 31271, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 7, "scene": 38, "line": "Oscar's a douche.", "order": 31272, "speaker": "Michael Scott"},
  {"season": 6, "episode": 7, "scene": 38, "line": "[laughing] He's alright.", "order": 31273, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 7, "scene": 38, "line": "No. He's a- yeah, he's alright. Ok. [holds door open as they exit]", "order": 31274, "speaker": "Michael Scott"},
  {"season": 6, "episode": 7, "scene": 38, "line": "Thanks Michael.", "order": 31275, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 7, "scene": 38, "line": "You're welcome. Whoa! [leans back] Almost fell. [they both laugh]", "order": 31276, "speaker": "Michael Scott"},
  {"season": 6, "episode": 7, "scene": 39, "line": "Hey, Erin. Do you mind faxing this for me?", "order": 31277, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 7, "scene": 39, "line": "Oh, sure. Oh, wait. Hand them to me upside down so I don't accidentally read them.", "order": 31278, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 7, "scene": 39, "line": "Ok.", "order": 31279, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 7, "scene": 39, "line": "Thanks.", "order": 31280, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 7, "scene": 39, "line": "Mmm-hmm.", "order": 31281, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 7, "scene": 39, "line": "How did today go, by the way? Did you make any sales?", "order": 31282, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 7, "scene": 39, "line": "No. It was a total waste of time. Um... it was fun, though, because I got to spend the day with Andy Bernard. He's really cool.", "order": 31283, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 7, "scene": 39, "line": "Yeah, he is!", "order": 31284, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 7, "scene": 39, "line": "Yeah, he is.", "order": 31285, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 7, "scene": 39, "line": "He's, like, the coolest person I've ever met.", "order": 31286, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 7, "scene": 39, "line": "That's... right. He's like Marlon Brando.", "order": 31287, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 7, "scene": 39, "line": "Oh. Do you mean Marlon Wayans? 'Cause he is.", "order": 31288, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 7, "scene": 39, "line": "I actually do mean Marlon Wayan. Yeah.", "order": 31289, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 8, "scene": 1, "line": "Good morning everybody. Who would like an authentic New York bagel? Hmmm? Stanley?", "order": 31290, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 8, "scene": 1, "line": "Thank you. [reaches for bagel]", "order": 31291, "speaker": "Stanley Hudson"},
  {"season": 6, "episode": 8, "scene": 1, "line": "No, no, no, no. I got a pumpernickel just for you.", "order": 31292, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 8, "scene": 1, "line": "Wow. H & H. You went all the way to New York City to get us bagels?", "order": 31293, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 8, "scene": 1, "line": "Oh, why? Is there a place closer that sells them?", "order": 31294, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 8, "scene": 1, "line": "This is really nice Dwight, thanks.", "order": 31295, "speaker": "Phyllis Vance"},
  {"season": 6, "episode": 8, "scene": 1, "line": "Thanks.", "order": 31296, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 8, "scene": 1, "line": "Oh, don't mention it. You owe me one. You all owe me one.", "order": 31297, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 8, "scene": 2, "line": "Good morning Michael.", "order": 31298, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 8, "scene": 2, "line": "Morning Dwight.", "order": 31299, "speaker": "Michael Scott"},
  {"season": 6, "episode": 8, "scene": 2, "line": "Hungry?", "order": 31300, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 8, "scene": 2, "line": "No. I had a fish stick sandwich. Actually I had two fish stick sandwiches. My girlfriend didn't want hers. Because I guess I'm the only aphrodisiac she needs.", "order": 31301, "speaker": "Michael Scott"},
  {"season": 6, "episode": 8, "scene": 2, "line": "Fish sticks are not an aphrodisiac.", "order": 31302, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 8, "scene": 2, "line": "Well, yeah...", "order": 31303, "speaker": "Michael Scott"},
  {"season": 6, "episode": 8, "scene": 2, "line": "You're thinking of deer penis.", "order": 31304, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 8, "scene": 2, "line": "It worked.", "order": 31305, "speaker": "Michael Scott"},
  {"season": 6, "episode": 8, "scene": 2, "line": "Regardless. That was over two hours ago and it is now a scientific fact that you are hungry. [holds up tray] Bagel?", "order": 31306, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 8, "scene": 2, "line": "I don't mind if I do.", "order": 31307, "speaker": "Michael Scott"},
  {"season": 6, "episode": 8, "scene": 2, "line": "K. I brought cheese too.", "order": 31308, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 8, "scene": 2, "line": "I'm taking one for my lady friend.", "order": 31309, "speaker": "Michael Scott"},
  {"season": 6, "episode": 8, "scene": 2, "line": "Excellent.", "order": 31310, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 8, "scene": 2, "line": "Brain food. Thank you very much.", "order": 31311, "speaker": "Michael Scott"},
  {"season": 6, "episode": 8, "scene": 2, "line": "Okay. [walks out of Michael's office] You owe me.", "order": 31312, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 8, "scene": 3, "line": "Can't a guy just buy some bagels for his friends so they'll owe him a favor which he can use to get someone fired who stole a co-manager position from him anymore? Geez. When did everyone get so cynical?", "order": 31313, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 8, "scene": 4, "line": "Pam, would you care for a bagel?", "order": 31314, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 8, "scene": 4, "line": "Oh, no thank you.", "order": 31315, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 8, "scene": 4, "line": "That's right. You're a woman and you need to refuse food the first time. I'll try again. Please Pam, reconsider and have a bagel.", "order": 31316, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 8, "scene": 4, "line": "I have an early lunch.", "order": 31317, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 8, "scene": 5, "line": "Michael's been trying to get Jim and me to hang out ever since he started dating my mom. I don't know. I really hoped this thing would just die out, but today he's planning a birthday lunch for my mom and we have to go. No way out. No way out.", "order": 31318, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 8, "scene": 6, "line": "These are amazing. You took all these?", "order": 31319, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 8, "scene": 6, "line": "I've always found beauty in uncommon places. Homeless people. Graffiti. Photography lets me capture all that. Have you ever been photographed before?", "order": 31320, "speaker": "Ryan Howard"},
  {"season": 6, "episode": 8, "scene": 6, "line": "Oh just by like friends.", "order": 31321, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 8, "scene": 6, "line": "Well here's what I've been doing around here. It's for a series on exposure in the workplace.", "order": 31322, "speaker": "Ryan Howard"},
  {"season": 6, "episode": 8, "scene": 6, "line": "[walks into the office] Whoohoo! Pammy?", "order": 31323, "speaker": "Helene Beesly"},
  {"season": 6, "episode": 8, "scene": 6, "line": "Hey! [gets up to greet Helene] Hey. Happy birthday.", "order": 31324, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 8, "scene": 6, "line": "Thank you. [they hug]", "order": 31325, "speaker": "Helene Beesly"},
  {"season": 6, "episode": 8, "scene": 6, "line": "[comes over to hug them both] My girls. There they are. [Pam walks away]", "order": 31326, "speaker": "Michael Scott"},
  {"season": 6, "episode": 8, "scene": 7, "line": "Birthday lunch. Birthday lunch-- there is no better medicine than birthday lunch. It'll cure all of your 'Gee I don't know if Michael should be dating my mother' and fixes all occurrences of 'I don't really see them together'. So, open wide Pam and take a big old spoonful of birthday lunch medicine. Take with food.", "order": 31327, "speaker": "Michael Scott"},
  {"season": 6, "episode": 8, "scene": 8, "line": "Pam, aren't you going to introduce us to Michael's girlfriend?", "order": 31328, "speaker": "Angela Martin"},
  {"season": 6, "episode": 8, "scene": 8, "line": "I think you guys all remember my mom from the wedding.", "order": 31329, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 8, "scene": 8, "line": "Yeah--", "order": 31330, "speaker": "Michael Scott"},
  {"season": 6, "episode": 8, "scene": 8, "line": "Hi.", "order": 31331, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 8, "scene": 8, "line": "I think everyone has meet Helene. Shall we?", "order": 31332, "speaker": "Michael Scott"},
  {"season": 6, "episode": 8, "scene": 9, "line": "Wait a minute. [looks at the bag in Michael's hand] Is that another birthday present for me cause you already gave me a necklace.", "order": 31333, "speaker": "Helene Beesly"},
  {"season": 6, "episode": 8, "scene": 9, "line": "Oh my God I love it. [looks at camera and shakes head]", "order": 31334, "speaker": "Kelly Kapoor"},
  {"season": 6, "episode": 8, "scene": 9, "line": "Tell her how you gave it to me.", "order": 31335, "speaker": "Helene Beesly"},
  {"season": 6, "episode": 8, "scene": 9, "line": "No, that wouldn't be--", "order": 31336, "speaker": "Michael Scott"},
  {"season": 6, "episode": 8, "scene": 9, "line": "He put it around my neck while I was still asleep this morning. What a nice way to wake up.", "order": 31337, "speaker": "Helene Beesly"},
  {"season": 6, "episode": 8, "scene": 9, "line": "Yeah. The rest of the story has been censored due to-- inappropriosity.", "order": 31338, "speaker": "Michael Scott"},
  {"season": 6, "episode": 8, "scene": 9, "line": "Because of sex?", "order": 31339, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 8, "scene": 9, "line": "Hey--", "order": 31340, "speaker": "Michael Scott"},
  {"season": 6, "episode": 8, "scene": 9, "line": "Kevin!", "order": 31341, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 8, "scene": 9, "line": "Please, Kevin. You're fired. [Kevin looks at Jim; Jim shakes his head] Sorry, sorry. Work with a bunch of idiots. Shall we go?", "order": 31342, "speaker": "Michael Scott"},
  {"season": 6, "episode": 8, "scene": 9, "line": "Yes, let's go. [looks at Erin and nods]", "order": 31343, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 8, "scene": 9, "line": "Oh, wait. Um-- Swartz Lumber is on the phone for you Pam and they say it's urgent.", "order": 31344, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 8, "scene": 9, "line": "Oh, you guys. Just one second. [picks up phone] Oh my gosh! Are you serious? Shipping emergency. I don't think I can go to lunch.", "order": 31345, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 8, "scene": 9, "line": "Oh man, that's crazy. Right as we were on our way out too.", "order": 31346, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 8, "scene": 9, "line": "I know. Isn't that always how it goes?", "order": 31347, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 8, "scene": 9, "line": "Rarely. If ever. Let me give it a whirl. I'll talk to them.", "order": 31348, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 8, "scene": 9, "line": "I want to handle it.", "order": 31349, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 8, "scene": 9, "line": "That's okay.", "order": 31350, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 8, "scene": 9, "line": "I feel like its--", "order": 31351, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 8, "scene": 9, "line": "It's my pleasure. [takes phone from Pam] Hello? Well that's great. [hangs up phone] Turns out the paper was there all along.", "order": 31352, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 8, "scene": 9, "line": "[sighs] Thank God.", "order": 31353, "speaker": "Creed Bratton"},
  {"season": 6, "episode": 8, "scene": 10, "line": "Something's up. That paper was never supposed to arrive.", "order": 31354, "speaker": "Creed Bratton"},
  {"season": 6, "episode": 8, "scene": 11, "line": "So, Pammy-- are you still liking sales?", "order": 31355, "speaker": "Helene Beesly"},
  {"season": 6, "episode": 8, "scene": 11, "line": "Yeah-- it's exciting.", "order": 31356, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 8, "scene": 11, "line": "Well, you know-- Michael and I were-- talking, and maybe if you put a little more face to face time with your clients you could improve your sales.", "order": 31357, "speaker": "Helene Beesly"},
  {"season": 6, "episode": 8, "scene": 11, "line": "Pam's sales are fine actually.", "order": 31358, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 8, "scene": 11, "line": "Just want you to succeed Pammy. You know what, we shouldn't even be talking about business today.", "order": 31359, "speaker": "Michael Scott"},
  {"season": 6, "episode": 8, "scene": 11, "line": "Thank you.", "order": 31360, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 8, "scene": 11, "line": "Today is about family.", "order": 31361, "speaker": "Michael Scott"},
  {"season": 6, "episode": 8, "scene": 12, "line": "Why did I get in the car? I could of struggled. I have a whistle in my purse I didn't even blow it.", "order": 31362, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 8, "scene": 13, "line": "Oh, Dwight. I have a little surprise for you.", "order": 31363, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 8, "scene": 13, "line": "Let me guess-- you ate the bagel I gave you and loved it.", "order": 31364, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 8, "scene": 13, "line": "Yes! [they laugh] And I wanted to return the favor. I was polishing my loafers and I happened to look over and noticed that your brief case was a little worse for the wear, so a little elbow grease and she's polished right up, back up to her former glory.", "order": 31365, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 8, "scene": 13, "line": "Wow--", "order": 31366, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 8, "scene": 13, "line": "Feel it against your cheek.", "order": 31367, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 8, "scene": 13, "line": "I will. [Andy rubs the briefcase against Dwight's face]", "order": 31368, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 8, "scene": 14, "line": "You give me a gift-- Bam! Thank you note. You invite me somewhere-- Pow! RSVP. You do me a favor-- Wham! Favor returned. Do not test my politeness.", "order": 31369, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 8, "scene": 15, "line": "Let me get that door for you-- [runs to open door]", "order": 31370, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 8, "scene": 15, "line": "Whoa--", "order": 31371, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 8, "scene": 15, "line": "There you go!", "order": 31372, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 8, "scene": 15, "line": "Thank you very much. Now I've got it for you. [holds open door]", "order": 31373, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 8, "scene": 15, "line": "Oh... goodness... thanks. You know, here's a healthy fitness tip. If you clench your buttocks together while walking you can really take the pressure off your knees.", "order": 31374, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 8, "scene": 15, "line": "Is that right?", "order": 31375, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 8, "scene": 15, "line": "Observe. [walks towards desk]", "order": 31376, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 8, "scene": 15, "line": "Huh. [laughs; follows Dwight]", "order": 31377, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 8, "scene": 15, "line": "Right?", "order": 31378, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 8, "scene": 15, "line": "That really works.", "order": 31379, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 8, "scene": 15, "line": "Yeah, feel free to use that. Anytime. Okay.", "order": 31380, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 8, "scene": 15, "line": "Hey-- oh, hold on a second. Do not walk around with your jacket cattywampus.", "order": 31381, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 8, "scene": 15, "line": "[straightens Andy's tie] And that needs to be straight as an arrow.", "order": 31382, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 8, "scene": 15, "line": "Whoa-- thank you very much. [takes off Dwight's glasses and blows on them] Oh boy.", "order": 31383, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 8, "scene": 15, "line": "Thank you very much for that.", "order": 31384, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 8, "scene": 15, "line": "[puts Dwight's glasses back on Dwight's face] You are very welcome.", "order": 31385, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 8, "scene": 15, "line": "[pulls out Andy's chair] Have a seat. Allow me.", "order": 31386, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 8, "scene": 15, "line": "[pulls out Dwight's chair] Have a seat yourself.", "order": 31387, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 8, "scene": 15, "line": "You know what? I am going to preemptively change the batteries in your wireless mouse.", "order": 31388, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 8, "scene": 15, "line": "Not necessary.", "order": 31389, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 8, "scene": 15, "line": "No, no, no! [takes mouse and runs away]", "order": 31390, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 8, "scene": 15, "line": "You didn't have to do that.", "order": 31391, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 8, "scene": 16, "line": "Andy is complicating things. But I'm not worried. This will only up my game.", "order": 31392, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 8, "scene": 17, "line": "Hello. Scott. Table for four.", "order": 31393, "speaker": "Michael Scott"},
  {"season": 6, "episode": 8, "scene": 17, "line": "Welcome Scott family. It'll be just a moment.", "order": 31394, "speaker": "Hostess"},
  {"season": 6, "episode": 8, "scene": 17, "line": "I use to love coming here. The chicken parm is good. Big part of my childhood. Oh, maybe Michael will start dating that too.", "order": 31395, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 8, "scene": 18, "line": "What table should we take? So many to choose from.", "order": 31396, "speaker": "Michael Scott"},
  {"season": 6, "episode": 8, "scene": 18, "line": "Wow.", "order": 31397, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 8, "scene": 18, "line": "[sees table decorated with a banner and balloons] Oh my God!", "order": 31398, "speaker": "Helene Beesly"},
  {"season": 6, "episode": 8, "scene": 18, "line": "What? All I see are boring old tables with no birthday decorations. Oh, wait a second! You mean this one? Wow!", "order": 31399, "speaker": "Michael Scott"},
  {"season": 6, "episode": 8, "scene": 18, "line": "Michael, you did all this?", "order": 31400, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 8, "scene": 18, "line": "Not about taking credit. Let's just say we all did it.", "order": 31401, "speaker": "Michael Scott"},
  {"season": 6, "episode": 8, "scene": 18, "line": "Well, thank you. All of you.", "order": 31402, "speaker": "Helene Beesly"},
  {"season": 6, "episode": 8, "scene": 18, "line": "Well, it was actually me alone, so-- wow look at this place. Really makes me want to go to Italy some day.", "order": 31403, "speaker": "Michael Scott"},
  {"season": 6, "episode": 8, "scene": 18, "line": "Oh you'll love it. You can have my guide books. I think I'm done with those really long plane rides.", "order": 31404, "speaker": "Helene Beesly"},
  {"season": 6, "episode": 8, "scene": 18, "line": "Oh. Thank you. Thanks for lending me the books.", "order": 31405, "speaker": "Michael Scott"},
  {"season": 6, "episode": 8, "scene": 18, "line": "So, mom... which birthday are we celebrating this year?", "order": 31406, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 8, "scene": 18, "line": "[laughs] Sticking with 49.", "order": 31407, "speaker": "Helene Beesly"},
  {"season": 6, "episode": 8, "scene": 18, "line": "49 again? That's nine years in a row. This is now our longest family tradition.", "order": 31408, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 8, "scene": 18, "line": "That's funny.", "order": 31409, "speaker": "Michael Scott"},
  {"season": 6, "episode": 8, "scene": 18, "line": "Well, as long as you're running numbers Pam, then help me out with this one. Let's see your six months pregnant, but you've only been married for 30 days, so what does that add up to?", "order": 31410, "speaker": "Helene Beesly"},
  {"season": 6, "episode": 8, "scene": 18, "line": "Oh, burn. Burn on you... and a little bit on me, too. [they laugh]", "order": 31411, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 8, "scene": 18, "line": "You're 54 years old.", "order": 31412, "speaker": "Michael Scott"},
  {"season": 6, "episode": 8, "scene": 18, "line": "No. I'm 58.", "order": 31413, "speaker": "Helene Beesly"},
  {"season": 6, "episode": 8, "scene": 18, "line": "You're 58 years old?", "order": 31414, "speaker": "Michael Scott"},
  {"season": 6, "episode": 8, "scene": 18, "line": "Uh huh.", "order": 31415, "speaker": "Helene Beesly"},
  {"season": 6, "episode": 8, "scene": 18, "line": "Congratulations.", "order": 31416, "speaker": "Michael Scott"},
  {"season": 6, "episode": 8, "scene": 19, "line": "The only time you should care about a woman's age is if she is too young for you and I am not robbing the cradle. If anything I am robbing the grave.", "order": 31417, "speaker": "Michael Scott"},
  {"season": 6, "episode": 8, "scene": 20, "line": "Mom, have you decided what you want to be called?", "order": 31418, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 8, "scene": 20, "line": "Oh, well I like Nana, but I also like the classic Grandma.", "order": 31419, "speaker": "Helene Beesly"},
  {"season": 6, "episode": 8, "scene": 20, "line": "Please be Grandma.", "order": 31420, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 8, "scene": 20, "line": "Definitely Grandma.", "order": 31421, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 8, "scene": 20, "line": "I want you to be Grandma.", "order": 31422, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 8, "scene": 20, "line": "Oh, Michael what do you think? Grandma?", "order": 31423, "speaker": "Helene Beesly"},
  {"season": 6, "episode": 8, "scene": 20, "line": "[nods uncomfortably] That--", "order": 31424, "speaker": "Michael Scott"},
  {"season": 6, "episode": 8, "scene": 20, "line": "Yay, Grandma!", "order": 31425, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 8, "scene": 21, "line": "Hey, would you guys ever do a triathlon, do you think?", "order": 31426, "speaker": "Michael Scott"},
  {"season": 6, "episode": 8, "scene": 21, "line": "Maybe.", "order": 31427, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 8, "scene": 21, "line": "Helene?", "order": 31428, "speaker": "Michael Scott"},
  {"season": 6, "episode": 8, "scene": 21, "line": "Oh, oh no. I don't think so.", "order": 31429, "speaker": "Helene Beesly"},
  {"season": 6, "episode": 8, "scene": 21, "line": "I am. I'm definitely going to do it. I've already got the run and the bike thing down. Just need to learn how to swim. Come on, you in?", "order": 31430, "speaker": "Michael Scott"},
  {"season": 6, "episode": 8, "scene": 21, "line": "Oh no, I think a triathlon is pretty much something I can rule out. It just-- doesn't interest me.", "order": 31431, "speaker": "Helene Beesly"},
  {"season": 6, "episode": 8, "scene": 21, "line": "So it's a matter of interest. Good, okay. It's not anything you would have ever done.", "order": 31432, "speaker": "Michael Scott"},
  {"season": 6, "episode": 8, "scene": 21, "line": "Hmm... I might have done it when I was younger, but now there's just other things I'd rather be doing, instead of running and jumping and swimming--", "order": 31433, "speaker": "Helene Beesly"},
  {"season": 6, "episode": 8, "scene": 21, "line": "Well there's no jumping in a triathlon.", "order": 31434, "speaker": "Michael Scott"},
  {"season": 6, "episode": 8, "scene": 21, "line": "Oh--", "order": 31435, "speaker": "Helene Beesly"},
  {"season": 6, "episode": 8, "scene": 21, "line": "You're thinking of the broad jump. Would you try-- bungee jumping?", "order": 31436, "speaker": "Michael Scott"},
  {"season": 6, "episode": 8, "scene": 21, "line": "No, no... [laughs]", "order": 31437, "speaker": "Helene Beesly"},
  {"season": 6, "episode": 8, "scene": 21, "line": "Snowboarding?", "order": 31438, "speaker": "Michael Scott"},
  {"season": 6, "episode": 8, "scene": 21, "line": "You want to go snowboarding?", "order": 31439, "speaker": "Helene Beesly"},
  {"season": 6, "episode": 8, "scene": 21, "line": "I might.", "order": 31440, "speaker": "Michael Scott"},
  {"season": 6, "episode": 8, "scene": 21, "line": "Michael, what are you talking about?", "order": 31441, "speaker": "Helene Beesly"},
  {"season": 6, "episode": 8, "scene": 21, "line": "[sighs] Nothing. Just life-- and doing things-- before you die. I don't know.", "order": 31442, "speaker": "Michael Scott"},
  {"season": 6, "episode": 8, "scene": 22, "line": "Do I really want to go snowboarding? No. But I would like to if I wanted to.", "order": 31443, "speaker": "Michael Scott"},
  {"season": 6, "episode": 8, "scene": 23, "line": "[singing fanfare] Aye-yi-yi! [lifts covers off trays of food]", "order": 31444, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 8, "scene": 23, "line": "What's going on in here?", "order": 31445, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 8, "scene": 23, "line": "Andy bought lunch.", "order": 31446, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 8, "scene": 23, "line": "Oh... no... really?", "order": 31447, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 8, "scene": 23, "line": "Yep, yep. Took a page right out of the old Schrute book of niceness.", "order": 31448, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 8, "scene": 23, "line": "There is no book. There's only a survival guide.", "order": 31449, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 8, "scene": 23, "line": "You bought breakfast for everyone, so I got lunch for everyone.", "order": 31450, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 8, "scene": 23, "line": "Okay, well, allow me. You know what-- gosh these tacos are awfully complicated to make. I will make everyone's tacos! Gra-ga-ga-ga-ga!", "order": 31451, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 8, "scene": 24, "line": "So we should get the check?", "order": 31452, "speaker": "Michael Scott"},
  {"season": 6, "episode": 8, "scene": 24, "line": "What about your gift Michael?", "order": 31453, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 8, "scene": 24, "line": "Oh, no, no, no. No. It's stupid. You'd hate it. I hate it.", "order": 31454, "speaker": "Michael Scott"},
  {"season": 6, "episode": 8, "scene": 24, "line": "I'm sure I won't.", "order": 31455, "speaker": "Helene Beesly"},
  {"season": 6, "episode": 8, "scene": 24, "line": "Yes you will.", "order": 31456, "speaker": "Michael Scott"},
  {"season": 6, "episode": 8, "scene": 24, "line": "He locked himself in his office all morning working on it.", "order": 31457, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 8, "scene": 24, "line": "Gimme. Look at this wrapping paper. It's got the word love on it in every language.", "order": 31458, "speaker": "Helene Beesly"},
  {"season": 6, "episode": 8, "scene": 24, "line": "Aww--", "order": 31459, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 8, "scene": 24, "line": "I literally-- I put no thought into the wrapping at all. Might as well of been toilet paper.", "order": 31460, "speaker": "Michael Scott"},
  {"season": 6, "episode": 8, "scene": 24, "line": "[opens present, reads inscription] A scrapbook of our first memories by Michael Gary Scott. For my girlfriend Helene on your birthday. [laughs]", "order": 31461, "speaker": "Helene Beesly"},
  {"season": 6, "episode": 8, "scene": 25, "line": "That's just an arbitrary title.", "order": 31462, "speaker": "Michael Scott"},
  {"season": 6, "episode": 8, "scene": 25, "line": "Ok, there's a penny. What's that from?", "order": 31463, "speaker": "Helene Beesly"},
  {"season": 6, "episode": 8, "scene": 25, "line": "Penny for your thoughts.", "order": 31464, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 8, "scene": 25, "line": "It was from the wishing well at the mall. You threw it in. I went back later, waded in and I found it.", "order": 31465, "speaker": "Helene Beesly"},
  {"season": 6, "episode": 8, "scene": 25, "line": "Wow.", "order": 31466, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 8, "scene": 25, "line": "That's amazing.", "order": 31467, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 8, "scene": 25, "line": "It's not amazing... at all. It's sort of weird that I went and did that. And now your wish won't come true, so that blows.", "order": 31468, "speaker": "Michael Scott"},
  {"season": 6, "episode": 8, "scene": 25, "line": "[looking through the book] A poem!", "order": 31469, "speaker": "Helene Beesly"},
  {"season": 6, "episode": 8, "scene": 25, "line": "Oh that I plagiarized I think.", "order": 31470, "speaker": "Michael Scott"},
  {"season": 6, "episode": 8, "scene": 25, "line": "[reading] I can not go to school today said little Peggy Ann McKay...", "order": 31471, "speaker": "Helene Beesly"},
  {"season": 6, "episode": 8, "scene": 25, "line": "[whispers to Pam] Shel Silverstein.", "order": 31472, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 8, "scene": 25, "line": "Yeah.", "order": 31473, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 8, "scene": 25, "line": "Um-- I have the measles and the mumps, a gash, a rash and purple bumps. And my teachers really mean-- Happy Birthday , I love you Helene.", "order": 31474, "speaker": "Helene Beesly"},
  {"season": 6, "episode": 8, "scene": 25, "line": "That's really nice Michael.", "order": 31475, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 8, "scene": 25, "line": "I think it sucks.", "order": 31476, "speaker": "Michael Scott"},
  {"season": 6, "episode": 8, "scene": 25, "line": "I think it's wonderful, absolutely wonderful.", "order": 31477, "speaker": "Helene Beesly"},
  {"season": 6, "episode": 8, "scene": 25, "line": "Well, that's because you and I have very different tastes and you like lame things. It's a generational-- sort of gap between us.", "order": 31478, "speaker": "Michael Scott"},
  {"season": 6, "episode": 8, "scene": 25, "line": "[hugging book] God... it's a scrapbook.", "order": 31479, "speaker": "Helene Beesly"},
  {"season": 6, "episode": 8, "scene": 25, "line": "Home run Michael.", "order": 31480, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 8, "scene": 25, "line": "You set the bar so high.", "order": 31481, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 8, "scene": 25, "line": "That was a bunt.", "order": 31482, "speaker": "Michael Scott"},
  {"season": 6, "episode": 8, "scene": 26, "line": "Whoa, whoa Dwight. I'm going to do this.", "order": 31483, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 8, "scene": 26, "line": "Hey, hey, hey-- relax I got it covered.", "order": 31484, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 8, "scene": 26, "line": "No, I insist.", "order": 31485, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 8, "scene": 26, "line": "No, no, no, no, no, no, I insist. [pushes Andy out of the room and locks the door]", "order": 31486, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 8, "scene": 26, "line": "Ok, Dwight this is ridiculous.", "order": 31487, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 8, "scene": 26, "line": "Don't worry about it. You can just owe me.", "order": 31488, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 8, "scene": 27, "line": "Hey. Where'd you go?", "order": 31489, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 8, "scene": 27, "line": "Oh, just went to the bathroom.", "order": 31490, "speaker": "Michael Scott"},
  {"season": 6, "episode": 8, "scene": 27, "line": "And then you stopped at the bar on the way back?", "order": 31491, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 8, "scene": 27, "line": "I got thirsty.", "order": 31492, "speaker": "Michael Scott"},
  {"season": 6, "episode": 8, "scene": 27, "line": "Okay, weirdo. Come on. It's time for cake.", "order": 31493, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 8, "scene": 28, "line": "Honestly, for a long time I thought I was going to grow old with Holly. And then I met Helene. And she is great and she already knows and has done everything that I have ever wanted to do and can tell me about it. But, I think I want to do those things myself.", "order": 31494, "speaker": "Michael Scott"},
  {"season": 6, "episode": 8, "scene": 29, "line": "Cakes really good.", "order": 31495, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 8, "scene": 29, "line": "Oh, I know. I love how they use butter cream frosting.", "order": 31496, "speaker": "Helene Beesly"},
  {"season": 6, "episode": 8, "scene": 29, "line": "Finish your cake, Helene.", "order": 31497, "speaker": "Michael Scott"},
  {"season": 6, "episode": 8, "scene": 29, "line": "Hmmm?", "order": 31498, "speaker": "Helene Beesly"},
  {"season": 6, "episode": 8, "scene": 29, "line": "I want you to enjoy that cake because I have something terrible I need to tell you. I want you to enjoy your cake before I tell you this terrible, terrible thing.", "order": 31499, "speaker": "Michael Scott"},
  {"season": 6, "episode": 8, "scene": 30, "line": "Helene I think you're a wonderful person and I-- God-- I've enjoyed dating you and being your boyfriend so much. But I have to consider Pam's feelings as a friend and a co-worker I can not in good conscience continue to date you, when I know what this is doing to her.", "order": 31500, "speaker": "Michael Scott"},
  {"season": 6, "episode": 8, "scene": 30, "line": "Michael. Michael ,it's okay.", "order": 31501, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 8, "scene": 30, "line": "No it's not.", "order": 31502, "speaker": "Michael Scott"},
  {"season": 6, "episode": 8, "scene": 30, "line": "I know that it took me a little while to come around and its still a little weird to get used to, but you obviously make my mom very happy. And that makes me happy.", "order": 31503, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 8, "scene": 30, "line": "Wow. That just-- you've really grown.", "order": 31504, "speaker": "Michael Scott"},
  {"season": 6, "episode": 8, "scene": 30, "line": "Well--", "order": 31505, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 8, "scene": 30, "line": "[covering eyes] Thank you Pam. And I hope you are as gracious during this next part. Helene, once again you are a wonderful person and you have lived a great life and I envy it. And I want it someday, but just in the future. You need somebody who-- who understands your references. Who is Kafkaesque? I've never-- I don't know him. There's another woman. And her name is Italy... and skydiving... and bungee jumping.", "order": 31506, "speaker": "Michael Scott"},
  {"season": 6, "episode": 8, "scene": 30, "line": "Okay, so--", "order": 31507, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 8, "scene": 30, "line": "And I want kids. And you... unfortunately, have already completed that part of your journey down there.", "order": 31508, "speaker": "Michael Scott"},
  {"season": 6, "episode": 8, "scene": 30, "line": "Michael.", "order": 31509, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 8, "scene": 30, "line": "It's not my decision. It's mother nature. And mother has very strict rules about fer-- [waitress comes over to pour water; long pause] -- tility.", "order": 31510, "speaker": "Michael Scott"},
  {"season": 6, "episode": 8, "scene": 30, "line": "I, um-- I got it.", "order": 31511, "speaker": "Helene Beesly"},
  {"season": 6, "episode": 8, "scene": 31, "line": "I'm 58. What am I supposed to do now?", "order": 31512, "speaker": "Helene Beesly"},
  {"season": 6, "episode": 8, "scene": 31, "line": "Well, hobbies--", "order": 31513, "speaker": "Michael Scott"},
  {"season": 6, "episode": 8, "scene": 31, "line": "Stop.", "order": 31514, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 8, "scene": 32, "line": "[reaching for something on the top shelf] Ahh.", "order": 31515, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 8, "scene": 32, "line": "What's going on?", "order": 31516, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 8, "scene": 32, "line": "Stupid things wedged up there. No one in this office will help me.", "order": 31517, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 8, "scene": 32, "line": "Alright, move it.", "order": 31518, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 8, "scene": 33, "line": "What if Dwight dies and I still owe him something? That is a recipe for a ghost.", "order": 31519, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 8, "scene": 34, "line": "Oh yeah, you're going to owe me big time.", "order": 31520, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 8, "scene": 34, "line": "It's like a little envelope-- there you go.", "order": 31521, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 8, "scene": 34, "line": "Got it. [looks at envelope] To Dwight. Wha-- [opens envelope] Starbucks gift card.", "order": 31522, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 8, "scene": 34, "line": "Surprise! [laughs] It's from everybody. $15 value.", "order": 31523, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 8, "scene": 35, "line": "Dammit. I am no closer to taking Jim down. What a waste of a day. I could of grown poisoned mushrooms that would have been this high by now. [bends over and hold hand about 1/2 an inch from the floor] They're mushrooms. They don't get that high.", "order": 31524, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 8, "scene": 36, "line": "Pamela Beesly Halpert--", "order": 31525, "speaker": "Michael Scott"},
  {"season": 6, "episode": 8, "scene": 36, "line": "What?", "order": 31526, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 8, "scene": 36, "line": "May I have a word with you in my office please?", "order": 31527, "speaker": "Michael Scott"},
  {"season": 6, "episode": 8, "scene": 36, "line": "I am working.", "order": 31528, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 8, "scene": 36, "line": "Well, this is a work related matter.", "order": 31529, "speaker": "Michael Scott"},
  {"season": 6, "episode": 8, "scene": 36, "line": "Really?", "order": 31530, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 8, "scene": 36, "line": "Yes.", "order": 31531, "speaker": "Michael Scott"},
  {"season": 6, "episode": 8, "scene": 36, "line": "Really?", "order": 31532, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 8, "scene": 36, "line": "Yes. Join me please, won't you? [Pam gets up and follows Michael into his office] I am going to give you a raise.", "order": 31533, "speaker": "Michael Scott"},
  {"season": 6, "episode": 8, "scene": 36, "line": "Why?", "order": 31534, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 8, "scene": 36, "line": "Because of all the good work you've done.", "order": 31535, "speaker": "Michael Scott"},
  {"season": 6, "episode": 8, "scene": 36, "line": "I have the lowest sales record of anyone here.", "order": 31536, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 8, "scene": 36, "line": "That's-- no, no-- it's not about numbers, Pam. It's about attitude.", "order": 31537, "speaker": "Michael Scott"},
  {"season": 6, "episode": 8, "scene": 36, "line": "I have the worst attitude of any person here.", "order": 31538, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 8, "scene": 36, "line": "Do you want the raise or what?", "order": 31539, "speaker": "Michael Scott"},
  {"season": 6, "episode": 8, "scene": 36, "line": "Yeah, I'll take it. [turns to leave]", "order": 31540, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 8, "scene": 36, "line": "Hey, Pam, Pam-- with this raise there are strings attached. [Pam sighs] And the string is attached from my heart to your mean attitude.", "order": 31541, "speaker": "Michael Scott"},
  {"season": 6, "episode": 8, "scene": 36, "line": "You're bribing me?", "order": 31542, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 8, "scene": 36, "line": "No! No-- no I am not. Unless you want me to. DO you want me to? Cause I will. I will bribe you. No. Your face is saying don't-- unless I haven't offered you enough... your face isn't changing. What is it? Talk to me face. Tell me what Pam's brain is thinking. Come on! What do you want? What do you want? Do you want a million dollars? Do you want to hit me? Want me to get down on one knee and beg you or--", "order": 31543, "speaker": "Michael Scott"},
  {"season": 6, "episode": 8, "scene": 36, "line": "I want to hit you.", "order": 31544, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 8, "scene": 36, "line": "What?", "order": 31545, "speaker": "Michael Scott"},
  {"season": 6, "episode": 8, "scene": 36, "line": "I want to hit you. I'll do that.", "order": 31546, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 8, "scene": 36, "line": "[nervous laughter] Oh-- okay , what? I don't-- Are you kidding?", "order": 31547, "speaker": "Michael Scott"},
  {"season": 6, "episode": 8, "scene": 36, "line": "No. Are you kidding?", "order": 31548, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 8, "scene": 36, "line": "Ye-- No. Alright. I'll take off my jacket and tense my stomach and you can just--", "order": 31549, "speaker": "Michael Scott"},
  {"season": 6, "episode": 8, "scene": 36, "line": "No. I don't think I can hit you in the office.", "order": 31550, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 8, "scene": 36, "line": "Okay.", "order": 31551, "speaker": "Michael Scott"},
  {"season": 6, "episode": 8, "scene": 36, "line": "So, um... we'll do it after work. In the parking lot. In front of everyone. I'm going to hit you as hard as I can.", "order": 31552, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 8, "scene": 36, "line": "Okay.", "order": 31553, "speaker": "Michael Scott"},
  {"season": 6, "episode": 8, "scene": 36, "line": "Okay.", "order": 31554, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 8, "scene": 36, "line": "Okay.", "order": 31555, "speaker": "Michael Scott"},
  {"season": 6, "episode": 8, "scene": 36, "line": "See you then.", "order": 31556, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 8, "scene": 36, "line": "See you.", "order": 31557, "speaker": "Michael Scott"},
  {"season": 6, "episode": 8, "scene": 37, "line": "Hey.", "order": 31558, "speaker": "Ryan Howard"},
  {"season": 6, "episode": 8, "scene": 37, "line": "Hey.", "order": 31559, "speaker": "Michael Scott"},
  {"season": 6, "episode": 8, "scene": 37, "line": "Quick question.", "order": 31560, "speaker": "Ryan Howard"},
  {"season": 6, "episode": 8, "scene": 37, "line": "Yeah.", "order": 31561, "speaker": "Kelly Kapoor"},
  {"season": 6, "episode": 8, "scene": 37, "line": "Are you scared?", "order": 31562, "speaker": "Ryan Howard"},
  {"season": 6, "episode": 8, "scene": 37, "line": "Never. About what? A little-- what are you talking--", "order": 31563, "speaker": "Michael Scott"},
  {"season": 6, "episode": 8, "scene": 37, "line": "We heard about the punch.", "order": 31564, "speaker": "Ryan Howard"},
  {"season": 6, "episode": 8, "scene": 37, "line": "What punch?", "order": 31565, "speaker": "Michael Scott"},
  {"season": 6, "episode": 8, "scene": 37, "line": "Pam. She's going to punch the crap out of your face after work.", "order": 31566, "speaker": "Kelly Kapoor"},
  {"season": 6, "episode": 8, "scene": 37, "line": "I'm pretty sure we said slap.", "order": 31567, "speaker": "Michael Scott"},
  {"season": 6, "episode": 8, "scene": 37, "line": "No, it's a punch and Pam has that crazy pregnancy strength now.", "order": 31568, "speaker": "Kelly Kapoor"},
  {"season": 6, "episode": 8, "scene": 37, "line": "I'm getting concerned that you don't seem to understand what's going to happen. Do you?", "order": 31569, "speaker": "Ryan Howard"},
  {"season": 6, "episode": 8, "scene": 37, "line": "I-- mmm-- I'm good.", "order": 31570, "speaker": "Michael Scott"},
  {"season": 6, "episode": 8, "scene": 37, "line": "alright. See you there.", "order": 31571, "speaker": "Ryan Howard"},
  {"season": 6, "episode": 8, "scene": 38, "line": "Hey.", "order": 31572, "speaker": "Michael Scott"},
  {"season": 6, "episode": 8, "scene": 38, "line": "Hey.", "order": 31573, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 8, "scene": 38, "line": "Do you and Pam ever get frisky?", "order": 31574, "speaker": "Michael Scott"},
  {"season": 6, "episode": 8, "scene": 38, "line": "Inappropriate.", "order": 31575, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 8, "scene": 38, "line": "I mean-- do you ever wrestle with each other?", "order": 31576, "speaker": "Michael Scott"},
  {"season": 6, "episode": 8, "scene": 38, "line": "All the time.", "order": 31577, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 8, "scene": 38, "line": "Really?", "order": 31578, "speaker": "Michael Scott"},
  {"season": 6, "episode": 8, "scene": 38, "line": "Yeah.", "order": 31579, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 8, "scene": 38, "line": "She strong?", "order": 31580, "speaker": "Michael Scott"},
  {"season": 6, "episode": 8, "scene": 38, "line": "She wants it bad Michael.", "order": 31581, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 8, "scene": 38, "line": "Can you stop this?", "order": 31582, "speaker": "Michael Scott"},
  {"season": 6, "episode": 8, "scene": 38, "line": "I can.", "order": 31583, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 8, "scene": 38, "line": "Okay.", "order": 31584, "speaker": "Michael Scott"},
  {"season": 6, "episode": 8, "scene": 38, "line": "And I don't support her choice to hit you.", "order": 31585, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 8, "scene": 38, "line": "I don't either.", "order": 31586, "speaker": "Michael Scott"},
  {"season": 6, "episode": 8, "scene": 38, "line": "But at the same time she looks crazy and I do understand her reasoning.", "order": 31587, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 8, "scene": 38, "line": "Mmm-hmm. I know.", "order": 31588, "speaker": "Michael Scott"},
  {"season": 6, "episode": 8, "scene": 38, "line": "I just need some time.", "order": 31589, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 8, "scene": 38, "line": "You gotta do something--", "order": 31590, "speaker": "Michael Scott"},
  {"season": 6, "episode": 8, "scene": 38, "line": "I just need some time. Just give me some time to make a decision.", "order": 31591, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 8, "scene": 38, "line": "How much time do you think you'll need?", "order": 31592, "speaker": "Michael Scott"},
  {"season": 6, "episode": 8, "scene": 38, "line": "No more than a week. Maybe two weeks.", "order": 31593, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 8, "scene": 38, "line": "Come on, man!", "order": 31594, "speaker": "Michael Scott"},
  {"season": 6, "episode": 8, "scene": 39, "line": "Pam, can I talk to you for a second?", "order": 31595, "speaker": "Toby Flenderson"},
  {"season": 6, "episode": 8, "scene": 39, "line": "Oh...", "order": 31596, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 8, "scene": 39, "line": "I heard about the hit. Just-- make sure it's off company property, right?", "order": 31597, "speaker": "Toby Flenderson"},
  {"season": 6, "episode": 8, "scene": 39, "line": "Right.", "order": 31598, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 8, "scene": 39, "line": "Okay, I think we should probably be okay.", "order": 31599, "speaker": "Toby Flenderson"},
  {"season": 6, "episode": 8, "scene": 39, "line": "Okay.", "order": 31600, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 8, "scene": 39, "line": "Okay. And-- the-the power-- comes from the back foot. So its-- its all one motion through the-- body. So you stay a little low and there's a twist. You keep your shoulder down and you kind of throw out the arm. [demonstrates punch] Pow!", "order": 31601, "speaker": "Toby Flenderson"},
  {"season": 6, "episode": 8, "scene": 39, "line": "[stands up; gets into stance] Okay wait-- it's-- you're saying it comes from the foot.", "order": 31602, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 8, "scene": 39, "line": "Yes.", "order": 31603, "speaker": "Toby Flenderson"},
  {"season": 6, "episode": 8, "scene": 39, "line": "Okay. [punches Toby's hand]", "order": 31604, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 8, "scene": 39, "line": "Yes.", "order": 31605, "speaker": "Toby Flenderson"},
  {"season": 6, "episode": 8, "scene": 39, "line": "Wow!", "order": 31606, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 8, "scene": 39, "line": "Okay.", "order": 31607, "speaker": "Toby Flenderson"},
  {"season": 6, "episode": 8, "scene": 40, "line": "Hey everybody, he's not in the men's room although the seat was warm so we may have just missed him.", "order": 31608, "speaker": "Meredith Palmer"},
  {"season": 6, "episode": 8, "scene": 40, "line": "My money's on Pam.", "order": 31609, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 8, "scene": 40, "line": "It's not a fight. What other outcome could there be?", "order": 31610, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 8, "scene": 40, "line": "Michael could win.", "order": 31611, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 8, "scene": 40, "line": "How? He can't hit back.", "order": 31612, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 8, "scene": 40, "line": "Why can't you just agree with me sometimes?", "order": 31613, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 8, "scene": 41, "line": "Am I scared of getting hit in the face? No. Everyday weirdo's pay dominatrix's hundreds of dollars for that very privilege. I'm scared I'm gonna love it. [claps hands] Let's do this.", "order": 31614, "speaker": "Michael Scott"},
  {"season": 6, "episode": 8, "scene": 42, "line": "It's okay guys... I can handle this. Everybody can go home.", "order": 31615, "speaker": "Michael Scott"},
  {"season": 6, "episode": 8, "scene": 42, "line": "We're here for the show, Michael.", "order": 31616, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 8, "scene": 42, "line": "You're just a bunch of voyeurs. Are you eating popcorn?", "order": 31617, "speaker": "Michael Scott"},
  {"season": 6, "episode": 8, "scene": 42, "line": "It has almost no calories.", "order": 31618, "speaker": "Kelly Kapoor"},
  {"season": 6, "episode": 8, "scene": 42, "line": "Are you ready?", "order": 31619, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 8, "scene": 42, "line": "Yes.", "order": 31620, "speaker": "Michael Scott"},
  {"season": 6, "episode": 8, "scene": 42, "line": "[goes to punch Michael; he flinches] You have to keep your hands down, Michael.", "order": 31621, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 8, "scene": 42, "line": "I know. [sighs] Okay. Alright. [Pam goes to punch him again] Oh-- God!", "order": 31622, "speaker": "Michael Scott"},
  {"season": 6, "episode": 8, "scene": 42, "line": "Michael, you have to put your hands down.", "order": 31623, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 8, "scene": 42, "line": "Put your hands in your pockets.", "order": 31624, "speaker": "Angela Martin"},
  {"season": 6, "episode": 8, "scene": 42, "line": "Okay! [braces himself] Pam, Pam-- I'm really sorry. I'm sorry.", "order": 31625, "speaker": "Michael Scott"},
  {"season": 6, "episode": 8, "scene": 42, "line": "What are you sorry for?", "order": 31626, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 8, "scene": 42, "line": "So many things. I don't know. It's hard to choose.", "order": 31627, "speaker": "Michael Scott"},
  {"season": 6, "episode": 8, "scene": 42, "line": "How about for dating my mom?", "order": 31628, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 8, "scene": 42, "line": "Maybe that--", "order": 31629, "speaker": "Michael Scott"},
  {"season": 6, "episode": 8, "scene": 42, "line": "And dumping her on her birthday.", "order": 31630, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 8, "scene": 42, "line": "Yes.", "order": 31631, "speaker": "Michael Scott"},
  {"season": 6, "episode": 8, "scene": 42, "line": "Okay-- just-- don't ever date a member of my family again.", "order": 31632, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 8, "scene": 42, "line": "Okay. I promise. [Pam starts to walk away] For the record your mom came on to me. [Pam turns around and slaps him; he gasps]", "order": 31633, "speaker": "Michael Scott"},
  {"season": 6, "episode": 8, "scene": 42, "line": "Holy crap.", "order": 31634, "speaker": "Phyllis Vance"},
  {"season": 6, "episode": 8, "scene": 42, "line": "Are you okay?", "order": 31635, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 8, "scene": 42, "line": "No!", "order": 31636, "speaker": "Michael Scott"},
  {"season": 6, "episode": 8, "scene": 42, "line": "You're okay.", "order": 31637, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 8, "scene": 42, "line": "Feel better?", "order": 31638, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 8, "scene": 42, "line": "No. You were right.", "order": 31639, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 8, "scene": 42, "line": "[walks with Michael back into the office; Michael is clutching is face and limping] Wait, why are you limping?", "order": 31640, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 8, "scene": 42, "line": "I don't know.", "order": 31641, "speaker": "Michael Scott"},
  {"season": 6, "episode": 8, "scene": 42, "line": "Let's get some raw meat on that face. I got a chicken in the freezer upstairs.", "order": 31642, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 8, "scene": 43, "line": "As I watched Pam's big, strong hand coming towards my face I saw my entire life flash before my eyes. And guess what? I have four kids. And I have a hover-car and a hover-house. And my wife is a runner and it shows. And Pam and Jim are my best friends and our kids play together. And I am happy and I am rich and I never die. It doesn't sound like much, but its enough for me.", "order": 31643, "speaker": "Michael Scott"},
  {"season": 6, "episode": 8, "scene": 44, "line": "Ahh... [Dwight is holding a frozen chicken to his face]", "order": 31644, "speaker": "Michael Scott"},
  {"season": 6, "episode": 8, "scene": 44, "line": "Good, good. Keep it on there for another five minutes. Just let the swelling go down.", "order": 31645, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 8, "scene": 44, "line": "Oh thank you. Thanks. I owe you one Dwight.", "order": 31646, "speaker": "Michael Scott"},
  {"season": 6, "episode": 8, "scene": 44, "line": "Fire Jim.", "order": 31647, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 8, "scene": 44, "line": "No.", "order": 31648, "speaker": "Michael Scott"},
  {"season": 6, "episode": 8, "scene": 44, "line": "I'm cashing in the favor. Fire him.", "order": 31649, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 8, "scene": 44, "line": "What are you talking about--", "order": 31650, "speaker": "Michael Scott"},
  {"season": 6, "episode": 8, "scene": 44, "line": "Do it. Promote me. Tri-managers.", "order": 31651, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 8, "scene": 44, "line": "Forget that.", "order": 31652, "speaker": "Michael Scott"},
  {"season": 6, "episode": 8, "scene": 44, "line": "Can I have an office?", "order": 31653, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 8, "scene": 44, "line": "No.", "order": 31654, "speaker": "Michael Scott"},
  {"season": 6, "episode": 8, "scene": 44, "line": "You know what you can keep your stupid favor. It's worthless. And this is my dinner on your face. I'm keeping it.", "order": 31655, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 8, "scene": 44, "line": "[pats face] Oh that feels better.", "order": 31656, "speaker": "Michael Scott"},
  {"season": 6, "episode": 9, "scene": 1, "line": "[screaming, Phyllis and Dwight wrestling] Got you! Broken arm! Boom Boom Boom! Broken Nose! Boom Boom Boom Boom! Broken Nose! Boom! Ha, gonna flip you!", "order": 31657, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 9, "scene": 2, "line": "Once a year Dwight holds a seminar updating us on the newest developments in the world of karate... because as we all know, the one thing thousand year martial arts do all the time is change.", "order": 31658, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 9, "scene": 3, "line": "How can I pose this to you in a relatable way? You are all members of the Yakuza and you happen to be visiting the Lackawanna trolley museum... and you are attacked by triads... how can you hold them off until your clan arrives? You cannot go wrong with a throat punch [screams, pretends to punch Meredith in the throat]", "order": 31659, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 9, "scene": 3, "line": "OK, so there's no defense for that, good to know.", "order": 31660, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 9, "scene": 3, "line": "No, there is a defense for that. Let me demonstrate. Someone attack me. Kevin, go!", "order": 31661, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 9, "scene": 3, "line": "No way. Last time you pulled my pants down and then you tried to choke me with my shoelace.", "order": 31662, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 9, "scene": 3, "line": "False. I did choke you with your shoelace. Now come at me!", "order": 31663, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 9, "scene": 3, "line": "OK, with all due respect to everyone here, I think the most worthy opponent of you is you.", "order": 31664, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 9, "scene": 3, "line": "That is correct. Unless there happened to be measles present.", "order": 31665, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 9, "scene": 3, "line": "So let's just say that Dwight has come at you with the throat punch. Now how would you, Dwight, defend against it?", "order": 31666, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 9, "scene": 3, "line": "Easy. Allow me to demonstrate. I am attacking myself with a throat punch. Here it comes. [gives himself a fake throat punch] Block. Grasp wrist as such. [grasps wrist]", "order": 31667, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 9, "scene": 3, "line": "And what if he comes at you with the other hand, because he does have two.", "order": 31668, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 9, "scene": 3, "line": "Good point. Second, throat punch, absorb the blow. [gives himself another throat punch and proceeds to both attack and defend himself] Groin punch, hip block, elbow to the gut. Uh oh, up to the nose. No, you're not. Ow! Oh!", "order": 31669, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 9, "scene": 3, "line": "Oh my God, he's making you look like such a fool.", "order": 31670, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 9, "scene": 3, "line": "He really is, but not for long. [steps on foot] Ow! Instep, oh, not again. [more screaming] You let go, you let go. Oh, you're right, I can't hold on.", "order": 31671, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 9, "scene": 3, "line": "You two are so evenly matched I don't know how one of you is going to get the upper hand.", "order": 31672, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 9, "scene": 3, "line": "The important thing to remember Jim... we always have what is called the element of surprise. [hits himself in the groin and moans]", "order": 31673, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 9, "scene": 4, "line": "But I would say my favorite art form is a tie between sculpting and stand up comedy.", "order": 31674, "speaker": "Michael Scott"},
  {"season": 6, "episode": 9, "scene": 4, "line": "I did stand up comedy once.", "order": 31675, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 9, "scene": 4, "line": "You did?", "order": 31676, "speaker": "Michael Scott"},
  {"season": 6, "episode": 9, "scene": 4, "line": "Yeah, I killed.", "order": 31677, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 9, "scene": 4, "line": "That sounds like it was hilarious.", "order": 31678, "speaker": "Michael Scott"},
  {"season": 6, "episode": 9, "scene": 4, "line": "It was hilarious.", "order": 31679, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 9, "scene": 5, "line": "Michael?", "order": 31680, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 9, "scene": 5, "line": "Yes?", "order": 31681, "speaker": "Michael Scott"},
  {"season": 6, "episode": 9, "scene": 5, "line": "What is the meaning of this email that everyone got?", "order": 31682, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 9, "scene": 5, "line": "You'll have to be more specific Dwight, I get like eight emails a day.", "order": 31683, "speaker": "Michael Scott"},
  {"season": 6, "episode": 9, "scene": 5, "line": "This one, from David Wallace to all Dunder Mifflin.", "order": 31684, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 9, "scene": 5, "line": "Woo hoo, shout out!", "order": 31685, "speaker": "Michael Scott"},
  {"season": 6, "episode": 9, "scene": 5, "line": "Hello everyone I am sure you have seen the item in the Journal. I just want to stress that it's all conjecture. If there's any concrete news you will know ASAP.", "order": 31686, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 9, "scene": 5, "line": "Erin, do we have the journal?", "order": 31687, "speaker": "Michael Scott"},
  {"season": 6, "episode": 9, "scene": 5, "line": "Your feelings journal? You told me to put it in the time capsule.", "order": 31688, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 9, "scene": 5, "line": "Did you?", "order": 31689, "speaker": "Michael Scott"},
  {"season": 6, "episode": 9, "scene": 5, "line": "Michael, he means the Wall Street Journal online.", "order": 31690, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 9, "scene": 5, "line": "Oh, the Wall.", "order": 31691, "speaker": "Michael Scott"},
  {"season": 6, "episode": 9, "scene": 6, "line": "I found the article. [everyone walks over to his desk, he begins reading the article] 'On a day marked by panicked corporate board meetings, one that is relatively not surprising is Dunder Mifflin's. It is rumored that they will recommend... ' and the article cuts off.", "order": 31692, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 9, "scene": 6, "line": "It's $1.99 to finish the article. I wonder what it was going to say? [no one makes a move to pay for the article]", "order": 31693, "speaker": "Michael Scott"},
  {"season": 6, "episode": 9, "scene": 6, "line": "Are you serious? [starts typing on the computer and Andy begins to lean in toward the computer]", "order": 31694, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 9, "scene": 6, "line": "I got it, I got it... Oh, Tuna beat me to it.", "order": 31695, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 9, "scene": 6, "line": "It is rumored that they will recommend declaring bankruptcy.'", "order": 31696, "speaker": "Phyllis Vance"},
  {"season": 6, "episode": 9, "scene": 6, "line": "No, no, that doesn't make any sense.", "order": 31697, "speaker": "Michael Scott"},
  {"season": 6, "episode": 9, "scene": 6, "line": "Oh lord, we're all gonna lose our jobs.", "order": 31698, "speaker": "Stanley Hudson"},
  {"season": 6, "episode": 9, "scene": 6, "line": "Not necessarily, bankruptcy could mean a lot of things. Maybe they're just restructuring to get out of debt.", "order": 31699, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 9, "scene": 6, "line": "Oh, that sounds awful.", "order": 31700, "speaker": "Michael Scott"},
  {"season": 6, "episode": 9, "scene": 6, "line": "Or it could mean the end of Dunder Mifflin.", "order": 31701, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 9, "scene": 6, "line": "Oh God. Well, that's an interesting theory.", "order": 31702, "speaker": "Michael Scott"},
  {"season": 6, "episode": 9, "scene": 6, "line": "You know what? You can all have jobs at Schrute Farms as human scarecrows. It doesn't pay much and you can't unionize.", "order": 31703, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 9, "scene": 7, "line": "I'm sorry, Michael, David's in a meeting.", "order": 31704, "speaker": "David Wallace's Secretary"},
  {"season": 6, "episode": 9, "scene": 7, "line": "Ah, well maybe you should spy on him. [in Valley Girl voice] Oh my God, wouldn't that be hilarious! [laughs]", "order": 31705, "speaker": "Michael Scott"},
  {"season": 6, "episode": 9, "scene": 7, "line": "Um, I'll just have him call you back.", "order": 31706, "speaker": "David Wallace's Secretary"},
  {"season": 6, "episode": 9, "scene": 7, "line": "OK, OK. Good. I'll catch you on the flippity flip. Bye. [hangs up phone] He's busy, he'll call me back when he's free.", "order": 31707, "speaker": "Michael Scott"},
  {"season": 6, "episode": 9, "scene": 8, "line": "[retching sounds coming from the men's bathroom, Kevin walks to the door and goes in] Michael, are you OK? Did you throw up in there?", "order": 31708, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 9, "scene": 8, "line": "No, I'm just poopin'. You know how I be.", "order": 31709, "speaker": "Michael Scott"},
  {"season": 6, "episode": 9, "scene": 8, "line": "It smells like throw up in here.", "order": 31710, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 9, "scene": 8, "line": "Crazy world. Lotta smells.", "order": 31711, "speaker": "Michael Scott"},
  {"season": 6, "episode": 9, "scene": 9, "line": "[in lunch room with several coworkers] Guys, what if this is our last day at work? What if we never see each other ever again? [Andy scoffs and looks over at Erin, Erin looks sad]", "order": 31712, "speaker": "Kelly Kapoor"},
  {"season": 6, "episode": 9, "scene": 10, "line": "I like Erin. There, I said it. I was kinda hoping she would ask me out, but things have not panned out on that front, so... it is time for the Nard Dog to take matters into his own paws.", "order": 31713, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 9, "scene": 11, "line": "[Michael in his office playing music loudly, Jim walks in and turns the volume down, Michael trailing off on singing] We have to do something because people are losing it out there wondering what's going to happen.", "order": 31714, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 9, "scene": 11, "line": "OK, we need some sort of distraction for everybody. Anything new on YouTube? Doesn't even need to be good. Oh God, I can't think, need more Mullins.", "order": 31715, "speaker": "Michael Scott"},
  {"season": 6, "episode": 9, "scene": 11, "line": "Listen, Wallace said these were just rumors, right? So we have no reason to think the company is anything but fine. So if we just go on with our work, you and me, they'll follow along.", "order": 31716, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 9, "scene": 11, "line": "Monkey see, monkey do.", "order": 31717, "speaker": "Michael Scott"},
  {"season": 6, "episode": 9, "scene": 11, "line": "That's it.", "order": 31718, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 9, "scene": 11, "line": "Monkey pee all over you.", "order": 31719, "speaker": "Michael Scott"},
  {"season": 6, "episode": 9, "scene": 11, "line": "That rhymes, so what have we on the docket today?", "order": 31720, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 9, "scene": 11, "line": "We have a monthly staff meeting", "order": 31721, "speaker": "Michael Scott"},
  {"season": 6, "episode": 9, "scene": 11, "line": "Alright, let's conference room it up! [everyone is now in the conference room] Here's the deal guys. There is no new information as of yet, so I suggest we all just keep working.", "order": 31722, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 9, "scene": 12, "line": "Sure I'm a little nervous, but doing our work will make us feel better. I only slack off when things are good.", "order": 31723, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 9, "scene": 13, "line": "Customers have to use the coupon code from the website. And as of now there is no differentiation between the letter O and the zero, but we are working on that. Stay tuned for that.", "order": 31724, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 9, "scene": 13, "line": "[Michael leaves] He needs me. Seat saved infinity. [leaves]", "order": 31725, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 9, "scene": 13, "line": "OK, great.", "order": 31726, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 9, "scene": 13, "line": "[walks back in, on cell phone] What? Oh my God! [everyone starts asking questions] There has been a murder. There's been a murder in Savannah. [runs back to his office]", "order": 31727, "speaker": "Michael Scott"},
  {"season": 6, "episode": 9, "scene": 14, "line": "Games have the power to distract people from stressful situations. Battleship got me through my parents' divorce. Operation got me through my vasectomy, i.e., my operation. I don't think I would have been able to endure my breakup with Holly had it not been for Toss Across.", "order": 31728, "speaker": "Michael Scott"},
  {"season": 6, "episode": 9, "scene": 15, "line": "[reading the game box cover] Belles, Bourbon and Bullets, a murder mystery dinner party game.", "order": 31729, "speaker": "Meredith Palmer"},
  {"season": 6, "episode": 9, "scene": 15, "line": "It is so much fun. Everybody plays a character, we go around the room, we try to figure out who did it...", "order": 31730, "speaker": "Michael Scott"},
  {"season": 6, "episode": 9, "scene": 15, "line": "Hey, I am wondering if this is a, uh, terrible idea.", "order": 31731, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 9, "scene": 15, "line": "This is my call Jim, big picture stuff, it's about murder.", "order": 31732, "speaker": "Michael Scott"},
  {"season": 6, "episode": 9, "scene": 15, "line": "I thought we agreed that we wouldn't do things like this.", "order": 31733, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 9, "scene": 15, "line": "Tube City, you owe me one.", "order": 31734, "speaker": "Michael Scott"},
  {"season": 6, "episode": 9, "scene": 16, "line": "Co-managing is a give and take. You have to pick your battles. One of the battles that I picked was to stop Michael from running plastic tubes all over the office and placing hamsters inside of them. He was going to call it Tube City. So, yes, I do owe him one.", "order": 31735, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 9, "scene": 17, "line": "August the 5th, 1955. It's a sad day down here in Savannah. Local magnate Bill Bourbon was killed last night and all y'all have congregated tonight for a meal to celebrate Bill as he passes on to his great reward. You're not just here to pay your respects, you have to figure out which of y'all is the no-count scoundrel who killed him.", "order": 31736, "speaker": "Voice on CD player"},
  {"season": 6, "episode": 9, "scene": 17, "line": "This is ridiculous. [gets up to leave, as does Angela]", "order": 31737, "speaker": "Stanley Hudson"},
  {"season": 6, "episode": 9, "scene": 17, "line": "There will be food. You leave, you do not get food.", "order": 31738, "speaker": "Michael Scott"},
  {"season": 6, "episode": 9, "scene": 17, "line": "What kind of food?", "order": 31739, "speaker": "Stanley Hudson"},
  {"season": 6, "episode": 9, "scene": 17, "line": "Sandwich platters.", "order": 31740, "speaker": "Michael Scott"},
  {"season": 6, "episode": 9, "scene": 17, "line": "I'm in. [sits back down]", "order": 31741, "speaker": "Stanley Hudson"},
  {"season": 6, "episode": 9, "scene": 17, "line": "Baby carrots. [Angela sits back down]", "order": 31742, "speaker": "Michael Scott"},
  {"season": 6, "episode": 9, "scene": 18, "line": "OK, here are your character cards. Take one, pass it down. Here is your prop box, some of your characters will have props. Now, on your character card, it tells you who you are, and what your alibi is. Everything else is up to your imagination. So, if you talk slowly in real life, your character could, say, have been kicked in the head by a horse.", "order": 31743, "speaker": "Michael Scott"},
  {"season": 6, "episode": 9, "scene": 18, "line": "Whoa, I'll try it.", "order": 31744, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 9, "scene": 18, "line": "OK, use your imaginations.", "order": 31745, "speaker": "Michael Scott"},
  {"season": 6, "episode": 9, "scene": 19, "line": "Who'd you get?", "order": 31746, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 9, "scene": 19, "line": "Naughty Nellie Nutmeg, a vivacious young socialite with a penchant for scandal.", "order": 31747, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 9, "scene": 19, "line": "Oooooo, saucy.", "order": 31748, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 9, "scene": 19, "line": "How about you?", "order": 31749, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 9, "scene": 19, "line": "Nathaniel Nutmeg, the local bartender and Nellie's brother.", "order": 31750, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 9, "scene": 20, "line": "Michael, I don't like this game. It's scary.", "order": 31751, "speaker": "Angela Martin"},
  {"season": 6, "episode": 9, "scene": 20, "line": "It's not scary.", "order": 31752, "speaker": "Michael Scott"},
  {"season": 6, "episode": 9, "scene": 20, "line": "I don't like my character.", "order": 31753, "speaker": "Angela Martin"},
  {"season": 6, "episode": 9, "scene": 20, "line": "Who are you?", "order": 31754, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 9, "scene": 20, "line": "Voodoo Mama Juju, the witch doctor of the Savannah swamps. I'm not comfortable with this. [holds up a head]", "order": 31755, "speaker": "Angela Martin"},
  {"season": 6, "episode": 9, "scene": 20, "line": "Well how do you think I feel? I mean I have to play Caleb Crawdad, handsome playboy. Every night, a different woman. Being oogled. Having to hug and kiss and spoon, I make them feel beautiful.", "order": 31756, "speaker": "Michael Scott"},
  {"season": 6, "episode": 9, "scene": 21, "line": "[looks at Oscar who is using his phone, grabs it] Well, well, well. What is this contraption, I do declare?", "order": 31757, "speaker": "Michael Scott"},
  {"season": 6, "episode": 9, "scene": 21, "line": "It's my blackberry Michael, I am trying to get updates on the company.", "order": 31758, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 9, "scene": 21, "line": "Who's Michael? I'm Caleb Crawdad, I do declare.", "order": 31759, "speaker": "Michael Scott"},
  {"season": 6, "episode": 9, "scene": 21, "line": "You don't have to keep saying 'I do declare'. Any time you say something it means you are declaring.", "order": 31760, "speaker": "Ryan Howard"},
  {"season": 6, "episode": 9, "scene": 21, "line": "That is the way Southern people talk.", "order": 31761, "speaker": "Michael Scott"},
  {"season": 6, "episode": 9, "scene": 21, "line": "And what Designing Woman are you basing that on?", "order": 31762, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 9, "scene": 21, "line": "Delta Burke, I do declare. Now, shall we get to the mystery?", "order": 31763, "speaker": "Michael Scott"},
  {"season": 6, "episode": 9, "scene": 21, "line": "I'll go. [stands up, begins speaking with a southern accent] My name is Deborah U. Tante. Deb for short.", "order": 31764, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 9, "scene": 21, "line": "That's clever, Debutante.", "order": 31765, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 9, "scene": 21, "line": "Bill Bourbon was my uncle. I would have never hurt him any more than I'd hurt a June bug.", "order": 31766, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 9, "scene": 21, "line": "Nice accent. You sound like Forrest Gump.", "order": 31767, "speaker": "Meredith Palmer"},
  {"season": 6, "episode": 9, "scene": 21, "line": "I do not.", "order": 31768, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 9, "scene": 21, "line": "Well, you do actually. You've got this kinda like Florida Panhandle thing going, whereas what you really want is more of a Savannah accent, which is more like molasses just sorta spillin' out of your mouth.", "order": 31769, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 9, "scene": 21, "line": "Oooo, now do the Swedish chef.", "order": 31770, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 9, "scene": 21, "line": "Uh, not familiar, what province is he from?", "order": 31771, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 9, "scene": 21, "line": "He lives on Sesame Street, dumbass.", "order": 31772, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 9, "scene": 22, "line": "You know, I think I'm supposed to say something to Pam, I mean, Deb, OK? [in character] I overheard you asking the butler where the pistol was kept.", "order": 31773, "speaker": "Phyllis Vance"},
  {"season": 6, "episode": 9, "scene": 22, "line": "Wait a minute, wait a minute. I'm the butler. You were listening in on that? You rich people. You think you can do whatever you want to the servant class!", "order": 31774, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 9, "scene": 22, "line": "No, no, no. Don't turn this into a political thing.", "order": 31775, "speaker": "Michael Scott"},
  {"season": 6, "episode": 9, "scene": 22, "line": "I will poison your food.", "order": 31776, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 9, "scene": 23, "line": "I reckon I got something to say to Miss Beatrix Bourbon, if'n she don't mind.", "order": 31777, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 9, "scene": 23, "line": "Oooooo, doggie, we got a party now!", "order": 31778, "speaker": "Michael Scott"},
  {"season": 6, "episode": 9, "scene": 24, "line": "I do believe that the game is a big hit. People are really diving into their characters.", "order": 31779, "speaker": "Michael Scott"},
  {"season": 6, "episode": 9, "scene": 25, "line": "Y'all.", "order": 31780, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 9, "scene": 26, "line": "[Creed pulls into the parking lot and goes into the office] Sorry I'm late, boss. What's going on?", "order": 31781, "speaker": "Creed Bratton"},
  {"season": 6, "episode": 9, "scene": 26, "line": "[accent] Sir, there has been a murder, and you are a suspect.", "order": 31782, "speaker": "Michael Scott"},
  {"season": 6, "episode": 9, "scene": 26, "line": "OK. Hang on just a second. Let me just settle in and I'll be right back.", "order": 31783, "speaker": "Creed Bratton"},
  {"season": 6, "episode": 9, "scene": 26, "line": "Very good, very good. Now, no one was there in the wine cellar. [Creed gets in his car and drives away]", "order": 31784, "speaker": "Michael Scott"},
  {"season": 6, "episode": 9, "scene": 27, "line": "[in character] Hey there young lady.", "order": 31785, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 9, "scene": 27, "line": "Hello.", "order": 31786, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 9, "scene": 27, "line": "I would be remiss if I did not ask the pretty young lady out on a date for this weekend.", "order": 31787, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 9, "scene": 27, "line": "Sounds like a plan, Sugar.", "order": 31788, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 9, "scene": 27, "line": "Alright, a plan it is.", "order": 31789, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 9, "scene": 28, "line": "Voodoo Mama Juju, explain your dalliance with the Dark Arts.", "order": 31790, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 9, "scene": 28, "line": "It's not my fault, I was exposed to Harry Potter.", "order": 31791, "speaker": "Angela Martin"},
  {"season": 6, "episode": 9, "scene": 28, "line": "I know you did it!", "order": 31792, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 9, "scene": 29, "line": "I know she didn't do it. It's never the person you most suspect. It's also never the person you least suspect, since anyone with half a brain would suspect them the most. Therefore I know the killer to be Phyllis, AKA Beatrix Bourbon, the person I most medium suspect.", "order": 31793, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 9, "scene": 30, "line": "[in character] Do you want to go out on a date with me? We can make out by the horses.", "order": 31794, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 9, "scene": 30, "line": "Well, you know ol' Nellie's always up for a romp in the hay.", "order": 31795, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 9, "scene": 30, "line": "How about a threesome?", "order": 31796, "speaker": "Meredith Palmer"},
  {"season": 6, "episode": 9, "scene": 30, "line": "Yeah, my boudoir's always open.", "order": 31797, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 9, "scene": 30, "line": "Nice.", "order": 31798, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 9, "scene": 31, "line": "I'm a little worried I might have asked out Naughty Nellie and not Erin. Which would be a whole lot less appealing, because Naughty Nellie says yes to everyone. And she might be a murderer.", "order": 31799, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 9, "scene": 32, "line": "I just got an email from corporate, specifically for Accounting to stop all payments to our vendors.", "order": 31800, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 9, "scene": 32, "line": "What does that mean?", "order": 31801, "speaker": "Phyllis Vance"},
  {"season": 6, "episode": 9, "scene": 32, "line": "Well, maybe nothing but it could mean a problem with liquidity-", "order": 31802, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 9, "scene": 32, "line": "[accent] Whoa. What's this strange Yankee accent coming out of your mouth, son? This here's Savannah.", "order": 31803, "speaker": "Michael Scott"},
  {"season": 6, "episode": 9, "scene": 32, "line": "[tries to speak in a high-pitched southern accent] This plantation, we're running low on greenbacks. We're having problems paying the people who give us the seeds and the dirt. We can't pay... [in regular voice] - Michael, I can't - Basically it could mean a lot of things, but it is unprecedented, so it's cause for concern.", "order": 31804, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 9, "scene": 32, "line": "OK, I'm going back to work.", "order": 31805, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 9, "scene": 32, "line": "No no no no no no no. [accent] I'm going to skip forward to a relevant clue. Here we go.", "order": 31806, "speaker": "Michael Scott"},
  {"season": 6, "episode": 9, "scene": 33, "line": "Well by now you've figured out that ol' Beatrix Bourbon was the killer.", "order": 31807, "speaker": "Voice on CD player"},
  {"season": 6, "episode": 9, "scene": 33, "line": "Michael, I was doing so well.", "order": 31808, "speaker": "Phyllis Vance"},
  {"season": 6, "episode": 9, "scene": 33, "line": "[accent] Are you going to believe that? That is a mis- that's a misdirection. We still don't know who the murderer is. [everyone leaves] Nellie Nutmeg, come back in here. I can't do this myself. Voodoo Mama Juju... what am I gonna do?", "order": 31809, "speaker": "Michael Scott"},
  {"season": 6, "episode": 9, "scene": 34, "line": "OK, so Corporate is still in their meeting.", "order": 31810, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 9, "scene": 34, "line": "That could be a good sign.", "order": 31811, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 9, "scene": 34, "line": "Hey everyone, Kevin's going to give us his take on the situation. Let's listen up.", "order": 31812, "speaker": "Angela Martin"},
  {"season": 6, "episode": 9, "scene": 34, "line": "Alright guys, it's a work day and you guys work here, so let's go do some work.", "order": 31813, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 9, "scene": 34, "line": "Now, Jim telling us all to work? I think that is a very good sign.", "order": 31814, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 9, "scene": 34, "line": "Don't just say things.", "order": 31815, "speaker": "Angela Martin"},
  {"season": 6, "episode": 9, "scene": 34, "line": "[Michael walks in] Oh, hey, Michael. Listen, I brought up those sales targets. Do you want to-", "order": 31816, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 9, "scene": 34, "line": "[accent] I do believe you have me mistaken, my name is Caleb Crawdad. Y'all skedaddled way too soon. We need to reopen this case.", "order": 31817, "speaker": "Michael Scott"},
  {"season": 6, "episode": 9, "scene": 34, "line": "No, you're not reopening the case. The case is closed.", "order": 31818, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 9, "scene": 34, "line": "No it ain't.", "order": 31819, "speaker": "Michael Scott"},
  {"season": 6, "episode": 9, "scene": 35, "line": "Frankly, I'm not surprised. A lot of the evidence seemed to be based on puns.", "order": 31820, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 9, "scene": 36, "line": "Just thinking about Weekend at Bernie's. It's funny, the guy's dead the whole time.", "order": 31821, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 9, "scene": 36, "line": "I haven't seen it.", "order": 31822, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 9, "scene": 36, "line": "Speaking of weekends, you excited about our date this weekend?", "order": 31823, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 9, "scene": 36, "line": "[accent] Of course, Nathaniel.", "order": 31824, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 9, "scene": 36, "line": "Yeah, OK.", "order": 31825, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 9, "scene": 36, "line": "[accent] Where are you taking me?", "order": 31826, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 9, "scene": 36, "line": "[accent] Well, finest steakhouse in all Savannah.", "order": 31827, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 9, "scene": 36, "line": "[accent] Savannah? That's a far way from Scranton.", "order": 31828, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 9, "scene": 36, "line": "Did you mean a real date?", "order": 31829, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 9, "scene": 36, "line": "No. [laughs] Did you?", "order": 31830, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 9, "scene": 36, "line": "Totally... not. [Erin gets up and walks away]", "order": 31831, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 9, "scene": 37, "line": "I thought it was for real. So I was excited, but it was just, it was part of the game.", "order": 31832, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 9, "scene": 38, "line": "You're sure it was water, it couldn't have been acid?", "order": 31833, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 9, "scene": 38, "line": "I already declared you, it was too dark to tell.", "order": 31834, "speaker": "Michael Scott"},
  {"season": 6, "episode": 9, "scene": 38, "line": "Stop playing games with me, Crawdad.", "order": 31835, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 9, "scene": 38, "line": "This is not a game, this is my life. You are out of order, sir.", "order": 31836, "speaker": "Michael Scott"},
  {"season": 6, "episode": 9, "scene": 38, "line": "Answer me.", "order": 31837, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 9, "scene": 38, "line": "You are out of order.", "order": 31838, "speaker": "Michael Scott"},
  {"season": 6, "episode": 9, "scene": 38, "line": "You are out of order.", "order": 31839, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 9, "scene": 38, "line": "You are-", "order": 31840, "speaker": "Michael Scott"},
  {"season": 6, "episode": 9, "scene": 39, "line": "I think Michael may have snapped.", "order": 31841, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 9, "scene": 39, "line": "Or maybe he's just stuck in character.", "order": 31842, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 9, "scene": 39, "line": "Well, which is worse? Snapped or stuck?", "order": 31843, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 9, "scene": 39, "line": "Both. They're both worse.", "order": 31844, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 9, "scene": 40, "line": "It's going to be alright. We're a good company, we'll figure this out. We're not going under.", "order": 31845, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 9, "scene": 40, "line": "Jim, what if we both lose our jobs? I'm trying to think of a way that this all ends up fine, and I can't think of one.", "order": 31846, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 9, "scene": 41, "line": "[comes into Jim's office using accent] Deb, what in the world - do you have the vapors?", "order": 31847, "speaker": "Michael Scott"},
  {"season": 6, "episode": 9, "scene": 41, "line": "Michael, not now.", "order": 31848, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 9, "scene": 41, "line": "Can't wait, we have to get you to our witch doctor right now. Come on. Come on.", "order": 31849, "speaker": "Michael Scott"},
  {"season": 6, "episode": 9, "scene": 41, "line": "I should just go. [accent] Where to now Caleb?", "order": 31850, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 9, "scene": 41, "line": "To Mama Juju Boo Boo.", "order": 31851, "speaker": "Michael Scott"},
  {"season": 6, "episode": 9, "scene": 42, "line": "Michael, can I... OK, I'm just going to skip right past the what and go with why.", "order": 31852, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 9, "scene": 42, "line": "Because this is the recreation of a crime scene.", "order": 31853, "speaker": "Michael Scott"},
  {"season": 6, "episode": 9, "scene": 42, "line": "[laying on the ground] I'm the dead body and these are my brain chunks.", "order": 31854, "speaker": "Meredith Palmer"},
  {"season": 6, "episode": 9, "scene": 42, "line": "Hey shut up. You're dead.", "order": 31855, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 9, "scene": 42, "line": "Michael, can I talk to you in your office?", "order": 31856, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 9, "scene": 42, "line": "No Michael here, my name is-", "order": 31857, "speaker": "Michael Scott"},
  {"season": 6, "episode": 9, "scene": 42, "line": "Caleb!", "order": 31858, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 9, "scene": 43, "line": "Today of all days...", "order": 31859, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 9, "scene": 43, "line": "No, you shut up. They need this game, Jim. Let us have this stupid little game, alright? [Jim nods]", "order": 31860, "speaker": "Michael Scott"},
  {"season": 6, "episode": 9, "scene": 44, "line": "[accent] Oh, you startled me. My stars, my heart nearly leapt out of my chest. What can I do you for?", "order": 31861, "speaker": "Michael Scott"},
  {"season": 6, "episode": 9, "scene": 44, "line": "Wallace is on the phone. He's returning your call.", "order": 31862, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 9, "scene": 44, "line": "Detective Wallace?", "order": 31863, "speaker": "Michael Scott"},
  {"season": 6, "episode": 9, "scene": 44, "line": "I don't think so. I think it's David Wallace from Dunder Mifflin.", "order": 31864, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 9, "scene": 44, "line": "Aw, shucks, tell him I'm not here.", "order": 31865, "speaker": "Michael Scott"},
  {"season": 6, "episode": 9, "scene": 44, "line": "You're not gonna answer the phone?", "order": 31866, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 9, "scene": 44, "line": "No, I only answer to Detective Wallace 'cause I got a warm body in the other room.", "order": 31867, "speaker": "Michael Scott"},
  {"season": 6, "episode": 9, "scene": 44, "line": "I'll take it.", "order": 31868, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 9, "scene": 45, "line": "Hey David.", "order": 31869, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 9, "scene": 45, "line": "Hey, sorry to be getting back to you guys so late. Things are still a bit unclear, we didn't officially decide anything yet.", "order": 31870, "speaker": "David Wallace"},
  {"season": 6, "episode": 9, "scene": 45, "line": "Oh, OK.", "order": 31871, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 9, "scene": 45, "line": "But if I can be candid with you here Jim, it's not good. We fully expect to be out of money by the end of the year. Insolvent. So, well... You know what? I'm sorry, I have to run. But we will talk soon, OK?", "order": 31872, "speaker": "David Wallace"},
  {"season": 6, "episode": 9, "scene": 45, "line": "Sure. Oh, you know, while I have you... today I was working on this pitch for a supermarket chain, and it would actually be a big account and I wanted to-", "order": 31873, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 9, "scene": 45, "line": "You know what? I can't really get my head around anything like that right now, but that's great. Keep after it, but I gotta run.", "order": 31874, "speaker": "David Wallace"},
  {"season": 6, "episode": 9, "scene": 45, "line": "OK.", "order": 31875, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 9, "scene": 45, "line": "Thanks Jim.", "order": 31876, "speaker": "David Wallace"},
  {"season": 6, "episode": 9, "scene": 45, "line": "See ya.", "order": 31877, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 9, "scene": 46, "line": "[Jim comes out of the office] What's the news?", "order": 31878, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 9, "scene": 46, "line": "Nothing yet.", "order": 31879, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 9, "scene": 46, "line": "Well, I guess that's not-", "order": 31880, "speaker": "Phyllis Vance"},
  {"season": 6, "episode": 9, "scene": 46, "line": "Well there is some bad news. [accent] There has been another murder.", "order": 31881, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 9, "scene": 46, "line": "A murder, you say? I do declare.", "order": 31882, "speaker": "Michael Scott"},
  {"season": 6, "episode": 9, "scene": 46, "line": "Conference room everyone.", "order": 31883, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 9, "scene": 46, "line": "Do we have to play?", "order": 31884, "speaker": "Stanley Hudson"},
  {"season": 6, "episode": 9, "scene": 46, "line": "No.", "order": 31885, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 9, "scene": 46, "line": "Super.", "order": 31886, "speaker": "Stanley Hudson"},
  {"season": 6, "episode": 9, "scene": 46, "line": "Oh, this could be a juicy one.", "order": 31887, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 9, "scene": 46, "line": "Get in there!", "order": 31888, "speaker": "Michael Scott"},
  {"season": 6, "episode": 9, "scene": 47, "line": "I think today was a good day to have two managers. 'Cause if you're a family stuck on a lifeboat in the middle of the ocean, one parent might want to just keep rowing. But if the other parent wants to play a game, it's not because they're crazy. It's because they're doing it for the kids. And I get that now.", "order": 31889, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 9, "scene": 48, "line": "There has been a lot of murder and a lot of intrigue. My little heart can barely take it no more. [regular voice] Today is the hardest I have worked in a long, long time.", "order": 31890, "speaker": "Michael Scott"},
  {"season": 6, "episode": 9, "scene": 49, "line": "[Andy, Michael, and Dwight are pointing imaginary guns at each other] I didn't do it!", "order": 31891, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 9, "scene": 49, "line": "OK, everybody just calm down.", "order": 31892, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 9, "scene": 49, "line": "I am calm.", "order": 31893, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 9, "scene": 49, "line": "On the count of three we're all going to put down our guns.", "order": 31894, "speaker": "Michael Scott"},
  {"season": 6, "episode": 9, "scene": 49, "line": "I have crossbows.", "order": 31895, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 9, "scene": 49, "line": "We'll put down our weaponry on the count of three, you ready?", "order": 31896, "speaker": "Michael Scott"},
  {"season": 6, "episode": 9, "scene": 49, "line": "OK, I'm ready.", "order": 31897, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 9, "scene": 49, "line": "One, two, three.. [all scream]", "order": 31898, "speaker": "Michael Scott"},
  {"season": 6, "episode": 9, "scene": 50, "line": "Andy revealed himself to be a double agent. At which point Dwight felt comfortable revealing that he also was double agent. And then Michael announced to everybody that - get this- he was a double agent. Oh, and it is 6:00.", "order": 31899, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 9, "scene": 51, "line": "[Michael, Dwight, Andy and now Pam are in the circle and training their 'weapons' on each other, Jim walks in] Really?", "order": 31900, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 9, "scene": 51, "line": "[accent] It wasn't me. I'm not goin' down for this!", "order": 31901, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 9, "scene": 51, "line": "Yeah, I want to go home.", "order": 31902, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 9, "scene": 51, "line": "Get the keys out of my purse, start the car. [Jim and Pam slowly back out of the office, Pam has her weapon trained on everyone and then they run out]", "order": 31903, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 10, "scene": 1, "line": "[Dwight enters dressed as Recyclops] Not again.", "order": 31904, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 10, "scene": 1, "line": "Bow down before Recyclops.", "order": 31905, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 10, "scene": 2, "line": "Five years ago, corporate said we had to start a recycling program for Earth Day so Dwight took the lead on that and introduced us to a very close friend of his named Recyclops.", "order": 31906, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 10, "scene": 3, "line": "Happy Earth Day, everyone. I'm Recyclops. Did you know that an old milk carton can be sawed in half and used as a planter?", "order": 31907, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 10, "scene": 4, "line": "The next year he really stepped things up.", "order": 31908, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 10, "scene": 5, "line": "Who has put a number seven plastic in a number four bin?", "order": 31909, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 10, "scene": 6, "line": "A year after that Recylcops really began to take shape.", "order": 31910, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 10, "scene": 7, "line": "Recylops will drown you in your over-watered lawns.", "order": 31911, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 10, "scene": 8, "line": "Then tragedy struck Recyclops when his fictional planet was attacked by some other fictitious thing... I can't remember.", "order": 31912, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 10, "scene": 9, "line": "Recyclops will have his revenge.", "order": 31913, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 10, "scene": 10, "line": "I think this was also the year he renounced Earth Day and vowed to the destroy the planet he once loved.", "order": 31914, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 10, "scene": 11, "line": "Oh my God, you guys, look. It's Recyclops.", "order": 31915, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 10, "scene": 11, "line": "Recyclops destroys! [starts tearing through the office]", "order": 31916, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 10, "scene": 11, "line": "Oh, is today Recyclops Day?", "order": 31917, "speaker": "Stanley Hudson"},
  {"season": 6, "episode": 10, "scene": 11, "line": "Yes.", "order": 31918, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 10, "scene": 11, "line": "I thought you were killed by Polluticorn.", "order": 31919, "speaker": "Stanley Hudson"},
  {"season": 6, "episode": 10, "scene": 11, "line": "Polluticorn wishes. [starts spraying the office with something]", "order": 31920, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 10, "scene": 11, "line": "That's aerosol spray. It's terrible for the environment.", "order": 31921, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 10, "scene": 11, "line": "Humans are terrible for the environment.", "order": 31922, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 10, "scene": 12, "line": "The thing I like most about Recyclops is that he's creating a different world for our child.", "order": 31923, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 10, "scene": 12, "line": "Mmm.", "order": 31924, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 10, "scene": 12, "line": "A world where you truly can be anything you want.", "order": 31925, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 10, "scene": 12, "line": "God bless you, Recyclops. And your cold robot heart. [Dwight throws an aluminum can towards the recycling bin and misses]", "order": 31926, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 10, "scene": 13, "line": "[seated] Ok. Ok. How 'bout this? [rises, waves, sits back down]", "order": 31927, "speaker": "Michael Scott"},
  {"season": 6, "episode": 10, "scene": 13, "line": "That's great.", "order": 31928, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 10, "scene": 13, "line": "I want it to be better than 'Great', Pam. How 'bout I remain seated... and I just do a little bit of a nod. Sort of a humble thing. [demonstrates]", "order": 31929, "speaker": "Michael Scott"},
  {"season": 6, "episode": 10, "scene": 13, "line": "Sure.", "order": 31930, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 10, "scene": 13, "line": "Nope. Then my face is down. They can't see my face.", "order": 31931, "speaker": "Michael Scott"},
  {"season": 6, "episode": 10, "scene": 13, "line": "And it looks like you're taking a dump.", "order": 31932, "speaker": "Meredith Palmer"},
  {"season": 6, "episode": 10, "scene": 14, "line": "Alan Brand is the CEO of the company, if title's important to you, has personally invited moi to go to New York to the shareholder's meeting and sit up on the stage with the board of directors. And at some point they are going to introduce me as the most successful branch manager that they have. And then Michael Scott turns and waves to the crowd... and the crowd goes wild.", "order": 31933, "speaker": "Michael Scott"},
  {"season": 6, "episode": 10, "scene": 15, "line": "[doing an announcer voice] Ladies and gentlemen from Scranton, Pennsylvania, please welcome Michael 'The Machine' Scott. [Michael waves, twirls and sits down]", "order": 31934, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 10, "scene": 15, "line": "Don't do the twirl.", "order": 31935, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 10, "scene": 15, "line": "Lose the twirl.", "order": 31936, "speaker": "Phyllis Vance"},
  {"season": 6, "episode": 10, "scene": 15, "line": "The twirl sucks.", "order": 31937, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 10, "scene": 15, "line": "Michael, I hated the twirl.", "order": 31938, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 10, "scene": 15, "line": "[still doing the voice & spinning in his chair] Hate the twirl!", "order": 31939, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 10, "scene": 15, "line": "Ok. Obviously I'm not going to do the twirl. I only did it because I nailed the wave.", "order": 31940, "speaker": "Michael Scott"},
  {"season": 6, "episode": 10, "scene": 15, "line": "Yeah, good. Don't do it.", "order": 31941, "speaker": "Phyllis Vance"},
  {"season": 6, "episode": 10, "scene": 16, "line": "I am not going to do the twirl. Alright? It's not even a twirl, it's a spin. I might do the spin.", "order": 31942, "speaker": "Michael Scott"},
  {"season": 6, "episode": 10, "scene": 17, "line": "The long term problem is bad investments that they need to dump. The short term problem is the company has no cash and there's no where to get it.", "order": 31943, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 10, "scene": 17, "line": "Ok, Oscar, I don't need the whole enchilada just the bullet points.", "order": 31944, "speaker": "Michael Scott"},
  {"season": 6, "episode": 10, "scene": 17, "line": "Those are the bullet points.", "order": 31945, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 10, "scene": 17, "line": "Well, could you condense it please?", "order": 31946, "speaker": "Michael Scott"},
  {"season": 6, "episode": 10, "scene": 17, "line": "That's as simple as I can make it.", "order": 31947, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 10, "scene": 17, "line": "Michael? The limo's here for you.", "order": 31948, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 10, "scene": 17, "line": "It's not a limo. It's a town car. Town cars are actually better, though. Better torque. Better handling.", "order": 31949, "speaker": "Michael Scott"},
  {"season": 6, "episode": 10, "scene": 17, "line": "He said limousine, so...", "order": 31950, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 10, "scene": 17, "line": "[at window in conference room] Check it out, guys. There's a limo down here. [everyone heads to the conference room] Michael, look. Oh, man.", "order": 31951, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 10, "scene": 17, "line": "They sent a limo.", "order": 31952, "speaker": "Michael Scott"},
  {"season": 6, "episode": 10, "scene": 18, "line": "Town cars suck. Town car is something that a company sends when they're in trouble. A limousine is something that a company sends when they have cause for celebration. And in this case I think we are celebrating me.", "order": 31953, "speaker": "Michael Scott"},
  {"season": 6, "episode": 10, "scene": 19, "line": "[everyone is rushing down the stairs to see the limo] Oh, wait. Wait. Wow. Wow! Oh, no way. Look at that.", "order": 31954, "speaker": "Michael Scott"},
  {"season": 6, "episode": 10, "scene": 19, "line": "What kind of mileage does this baby get?", "order": 31955, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 10, "scene": 19, "line": "It's like what high school kids take to prom on tv shows.", "order": 31956, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 10, "scene": 19, "line": "This is so typical of management to spend money on this. Ugh. Bunch of boobs.", "order": 31957, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 10, "scene": 19, "line": "Hate to break it to you Oscar but some of us like boobs.", "order": 31958, "speaker": "Michael Scott"},
  {"season": 6, "episode": 10, "scene": 19, "line": "Calves. Calves all the way.", "order": 31959, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 10, "scene": 19, "line": "I'm so jealous right now.", "order": 31960, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 10, "scene": 19, "line": "Hey, you know who you should be jealous of? Yourself. Because you're invited and you're invited and you're invited and you and you and you and you and you and you-", "order": 31961, "speaker": "Michael Scott"},
  {"season": 6, "episode": 10, "scene": 19, "line": "Car seats eight.", "order": 31962, "speaker": "Limo Driver"},
  {"season": 6, "episode": 10, "scene": 19, "line": "What?", "order": 31963, "speaker": "Michael Scott"},
  {"season": 6, "episode": 10, "scene": 19, "line": "The car seats eight.", "order": 31964, "speaker": "Limo Driver"},
  {"season": 6, "episode": 10, "scene": 19, "line": "The limo seats eight. Ok. Then Jim and Pam and Ryan plus a guest.", "order": 31965, "speaker": "Michael Scott"},
  {"season": 6, "episode": 10, "scene": 19, "line": "No thanks.", "order": 31966, "speaker": "Jim Halpert & Pam Halpert"},
  {"season": 6, "episode": 10, "scene": 19, "line": "I'll use it when you're done.", "order": 31967, "speaker": "Ryan Howard"},
  {"season": 6, "episode": 10, "scene": 19, "line": "[others start raising their hands to be picked] Mmm... whoa.", "order": 31968, "speaker": "Michael Scott"},
  {"season": 6, "episode": 10, "scene": 20, "line": "The Dunder Mifflin stock symbol is DMI. Do you know what that stands for? Dummies, morons and idiots. Because that's what you'd have to be to own it. And, as one of those idiots, I believe the board owes me answers.", "order": 31969, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 10, "scene": 21, "line": "[in the limo with Dwight, Andy and Oscar] I wish the windows weren't tinted so people could see us in here.", "order": 31970, "speaker": "Michael Scott"},
  {"season": 6, "episode": 10, "scene": 21, "line": "Hey, guys, do you think anyone might have had sex in here?", "order": 31971, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 10, "scene": 21, "line": "Definitely.", "order": 31972, "speaker": "Michael Scott"},
  {"season": 6, "episode": 10, "scene": 21, "line": "Smells like it.", "order": 31973, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 10, "scene": 21, "line": "Definitely. Look they got pillows. That. That's bigger than my bed.", "order": 31974, "speaker": "Michael Scott"},
  {"season": 6, "episode": 10, "scene": 21, "line": "Word.", "order": 31975, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 10, "scene": 21, "line": "Yeah! Yeah! Yeah!", "order": 31976, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 10, "scene": 21, "line": "[the limo driver raises the divider window] Who's playing with the button?", "order": 31977, "speaker": "Michael Scott"},
  {"season": 6, "episode": 10, "scene": 22, "line": "Hey, Ryan. Could you start consolidating all our Rolodex information into Outlook?", "order": 31978, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 10, "scene": 22, "line": "Uh, it doesn't seem like there's much of a point if the company's going under.", "order": 31979, "speaker": "Ryan Howard"},
  {"season": 6, "episode": 10, "scene": 22, "line": "But if the company doesn't go under then we'll finally have all our contacts in one program.", "order": 31980, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 10, "scene": 22, "line": "The company's probably going under, though.", "order": 31981, "speaker": "Ryan Howard"},
  {"season": 6, "episode": 10, "scene": 22, "line": "Could go either way.", "order": 31982, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 10, "scene": 22, "line": "Seems like it's leaning one way.", "order": 31983, "speaker": "Ryan Howard"},
  {"season": 6, "episode": 10, "scene": 22, "line": "Maybe we should just wait and find out.", "order": 31984, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 10, "scene": 22, "line": "Definitely.", "order": 31985, "speaker": "Ryan Howard"},
  {"season": 6, "episode": 10, "scene": 22, "line": "Ok.", "order": 31986, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 10, "scene": 22, "line": "So should we wait to find out before we start doing all this stuff?", "order": 31987, "speaker": "Ryan Howard"},
  {"season": 6, "episode": 10, "scene": 22, "line": "Nah. Might as well do it now.", "order": 31988, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 10, "scene": 23, "line": "Michael, what if somebody asks you a question at this meeting? Are you just gonna wave, or what?", "order": 31989, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 10, "scene": 23, "line": "I will have to answer.", "order": 31990, "speaker": "Michael Scott"},
  {"season": 6, "episode": 10, "scene": 23, "line": "I'll ask you a question.", "order": 31991, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 10, "scene": 23, "line": "Make it a softball. Something he can, like, crank out of the park.", "order": 31992, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 10, "scene": 23, "line": "Michael Scott you run the most profitable branch of Dunder Mifflin. How do you do it?", "order": 31993, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 10, "scene": 23, "line": "No, no. That's too hard. Say your name is Zamboni and then I will say, 'Well, we're sort of on thin ice.' [they all laugh] I won't say that. I'll something like that.", "order": 31994, "speaker": "Michael Scott"},
  {"season": 6, "episode": 10, "scene": 23, "line": "This is your big day. Come on.", "order": 31995, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 10, "scene": 23, "line": "Oh, my god. This is it.", "order": 31996, "speaker": "Michael Scott"},
  {"season": 6, "episode": 10, "scene": 24, "line": "Mr. Scott?", "order": 31997, "speaker": "Laurie"},
  {"season": 6, "episode": 10, "scene": 24, "line": "Yes.", "order": 31998, "speaker": "Michael Scott"},
  {"season": 6, "episode": 10, "scene": 24, "line": "I'm Laurie.", "order": 31999, "speaker": "Laurie"},
  {"season": 6, "episode": 10, "scene": 24, "line": "Oh, hi.", "order": 32000, "speaker": "Michael Scott"},
  {"season": 6, "episode": 10, "scene": 24, "line": "Thank you for joining us. Come with me.", "order": 32001, "speaker": "Laurie"},
  {"season": 6, "episode": 10, "scene": 24, "line": "Well, thank you and, and please call - continue to call me Mr. Scott.", "order": 32002, "speaker": "Michael Scott"},
  {"season": 6, "episode": 10, "scene": 24, "line": "Ok. If you just want to follow me I'll take you up to the lounge.", "order": 32003, "speaker": "Laurie"},
  {"season": 6, "episode": 10, "scene": 24, "line": "Well, I guess this is as far as I can take you guys. So -", "order": 32004, "speaker": "Michael Scott"},
  {"season": 6, "episode": 10, "scene": 24, "line": "Kay.", "order": 32005, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 10, "scene": 24, "line": "Good luck, Michael.", "order": 32006, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 10, "scene": 24, "line": "See you later.", "order": 32007, "speaker": "Michael Scott"},
  {"season": 6, "episode": 10, "scene": 25, "line": "[walking past bodyguards at the door] Officers. Thank you.", "order": 32008, "speaker": "Michael Scott"},
  {"season": 6, "episode": 10, "scene": 25, "line": "After you, Mr. Scott.", "order": 32009, "speaker": "Laurie"},
  {"season": 6, "episode": 10, "scene": 25, "line": "Thank you.", "order": 32010, "speaker": "Michael Scott"},
  {"season": 6, "episode": 10, "scene": 25, "line": "Michael.", "order": 32011, "speaker": "David Wallace"},
  {"season": 6, "episode": 10, "scene": 25, "line": "Hello.", "order": 32012, "speaker": "Michael Scott"},
  {"season": 6, "episode": 10, "scene": 25, "line": "So glad you could make it.", "order": 32013, "speaker": "David Wallace"},
  {"season": 6, "episode": 10, "scene": 25, "line": "Thank you.", "order": 32014, "speaker": "Michael Scott"},
  {"season": 6, "episode": 10, "scene": 25, "line": "How was the ride?", "order": 32015, "speaker": "David Wallace"},
  {"season": 6, "episode": 10, "scene": 25, "line": "Oh. It was awesome. Very, very sublime.", "order": 32016, "speaker": "Michael Scott"},
  {"season": 6, "episode": 10, "scene": 25, "line": "I'm glad. I want to introduce you to everyone. First we'll start with Alan Brand. Alan? CEO. Michael Scott, Branch manager, Scranton.", "order": 32017, "speaker": "David Wallace"},
  {"season": 6, "episode": 10, "scene": 25, "line": "It's nice to meet you, Michael.", "order": 32018, "speaker": "Alan"},
  {"season": 6, "episode": 10, "scene": 25, "line": "It's nice to meet you, too. It's an honor and a privilege.", "order": 32019, "speaker": "Michael Scott"},
  {"season": 6, "episode": 10, "scene": 25, "line": "Seated, we have the former congressman, Chris O'Keefe.", "order": 32020, "speaker": "David Wallace"},
  {"season": 6, "episode": 10, "scene": 25, "line": "Ah. [bows] Your eminence.", "order": 32021, "speaker": "Michael Scott"},
  {"season": 6, "episode": 10, "scene": 26, "line": "For those of you with questions, please line up behind one of the four microphones that have been placed in the aisles.", "order": 32022, "speaker": "Registrar"},
  {"season": 6, "episode": 10, "scene": 26, "line": "Coming through. Che, che, che, che, che, che. [spots microphone and gets in line, sees an empty mic and attempts to get there first] Excuse me. Excuse me. Excuse me. Excuse me. [a line appears from nowhere] Ah. Damn it. [ heads back to the original line which is now even longer] Wha- okay [grunts]", "order": 32023, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 10, "scene": 27, "line": "I was hoping to lob Michael his softball question early. I wanted to swing by the garment district, pick up a few crates of my shirts. I got a shirt guy.", "order": 32024, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 10, "scene": 28, "line": "Can you believe this? The money they spent on this convention hall. [finding a seat] Excuse me, miss. I just want to take the stupid board of directors by their necks. This is so simple.", "order": 32025, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 10, "scene": 28, "line": "Yeah. Well, you should do that. Get in line.", "order": 32026, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 10, "scene": 28, "line": "Oh, what a great idea and lose my job. No thank you.", "order": 32027, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 10, "scene": 28, "line": "Look, do you want to be able to tell your grandkids you stood up for yourself during the - America's biggest financial crisis?", "order": 32028, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 10, "scene": 28, "line": "How is he gonna have grandkids?", "order": 32029, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 10, "scene": 29, "line": "You guys ever protect the president?", "order": 32030, "speaker": "Michael Scott"},
  {"season": 6, "episode": 10, "scene": 29, "line": "No.", "order": 32031, "speaker": "Security"},
  {"season": 6, "episode": 10, "scene": 29, "line": "What about Madonna? Or Obama twins? Anybody famous?", "order": 32032, "speaker": "Michael Scott"},
  {"season": 6, "episode": 10, "scene": 29, "line": "Not supposed to talk about it. Nelly Furtado.", "order": 32033, "speaker": "Security"},
  {"season": 6, "episode": 10, "scene": 29, "line": "Alright, guys. You ready?", "order": 32034, "speaker": "Alan"},
  {"season": 6, "episode": 10, "scene": 29, "line": "Rock and roll. [they walk into convention room and are booed by everyone]", "order": 32035, "speaker": "Michael Scott"},
  {"season": 6, "episode": 10, "scene": 30, "line": "Dunder Mifflin is still a strong company. We are poised to come through this more streamlined and profitable than ever. [boos from the crowd]", "order": 32036, "speaker": "Alan"},
  {"season": 6, "episode": 10, "scene": 30, "line": "[to David] This is not as much fun as I thought it would be.", "order": 32037, "speaker": "Michael Scott"},
  {"season": 6, "episode": 10, "scene": 30, "line": "It was fun when we weren't on the brink of bankruptcy.", "order": 32038, "speaker": "David Wallace"},
  {"season": 6, "episode": 10, "scene": 30, "line": "[accidentally into his microphone] We're going bankrupt, you think? [angry jeering from the crowd] They are really angry.", "order": 32039, "speaker": "Michael Scott"},
  {"season": 6, "episode": 10, "scene": 30, "line": "The shareholders need to empower our leadership -", "order": 32040, "speaker": "Alan"},
  {"season": 6, "episode": 10, "scene": 31, "line": "I'm just gonna run to the bathroom. Will you save my place?", "order": 32041, "speaker": "Woman in line"},
  {"season": 6, "episode": 10, "scene": 31, "line": "Sure.", "order": 32042, "speaker": "Man in line"},
  {"season": 6, "episode": 10, "scene": 31, "line": "No. You will not.", "order": 32043, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 10, "scene": 31, "line": "Excuse me?", "order": 32044, "speaker": "Woman in line"},
  {"season": 6, "episode": 10, "scene": 31, "line": "Oh, I'm sorry. Were you raised in a household with no consequences?", "order": 32045, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 10, "scene": 31, "line": "It'll just be a second.", "order": 32046, "speaker": "Woman in line"},
  {"season": 6, "episode": 10, "scene": 31, "line": "Oh. If onlys and justs were candies and nuts then every day would be Erntedankfest.", "order": 32047, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 10, "scene": 32, "line": "... and we're confident. There are many things to be optimistic about in Dunder Mifflin's future and one of them is here with us today. Michael Scott, Scranton Branch manager.", "order": 32048, "speaker": "Alan"},
  {"season": 6, "episode": 10, "scene": 32, "line": "[loudly applauds] Yes.", "order": 32049, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 10, "scene": 32, "line": "In these uncertain times, Michael has managed to maintain steady profits from his Scranton branch. We all thank you, Michael. [scattered applause] Another bright spot in the Dunder Mifflin landscape, our new waste pulp re-purposing plant in Milford. [applause]", "order": 32050, "speaker": "Alan"},
  {"season": 6, "episode": 10, "scene": 33, "line": "Hey, Phyllis. Are you just getting back from lunch now?", "order": 32051, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 10, "scene": 33, "line": "Bob took me to Capello's. We got a little tipsy.", "order": 32052, "speaker": "Phyllis Vance"},
  {"season": 6, "episode": 10, "scene": 33, "line": "Ok. You shouldn't be telling me that stuff and also shouldn't be taking two hour lunches without telling me.", "order": 32053, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 10, "scene": 33, "line": "Oh, it's ok. Michael doesn't really care about these things.", "order": 32054, "speaker": "Phyllis Vance"},
  {"season": 6, "episode": 10, "scene": 33, "line": "I care about them and I'm just as much of a boss as Michael. [Stanley laughs] What's so funny? I'm a co-manager.", "order": 32055, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 10, "scene": 33, "line": "That doesn't make you a boss.", "order": 32056, "speaker": "Stanley Hudson"},
  {"season": 6, "episode": 10, "scene": 33, "line": "It's not like you can fire people or anything.", "order": 32057, "speaker": "Phyllis Vance"},
  {"season": 6, "episode": 10, "scene": 33, "line": "Well - who? How did you? Who, who told you this?", "order": 32058, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 10, "scene": 33, "line": "Ryan.", "order": 32059, "speaker": "Phyllis Vance"},
  {"season": 6, "episode": 10, "scene": 33, "line": "Can't say.", "order": 32060, "speaker": "Stanley Hudson"},
  {"season": 6, "episode": 10, "scene": 33, "line": "Sorry. Plastered.", "order": 32061, "speaker": "Phyllis Vance"},
  {"season": 6, "episode": 10, "scene": 33, "line": "Ok. Who else has heard the rumor that I am not as much in charge as Michael? [everyone raises there hands - including Pam]", "order": 32062, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 10, "scene": 33, "line": "Stuff gets around. I don't participate.", "order": 32063, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 10, "scene": 33, "line": "Who here heard it from Ryan?", "order": 32064, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 10, "scene": 33, "line": "Does and email count?", "order": 32065, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 10, "scene": 33, "line": "Yes.", "order": 32066, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 10, "scene": 33, "line": "Yeah. [everyone raises hands again]", "order": 32067, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 10, "scene": 33, "line": "I am just as much of a boss as Michael and I can do anything that Michael can do. Alright? Who here believes that I have as much power as Michael? [Pam, after a moment, is the only one who raises her hand]", "order": 32068, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 10, "scene": 34, "line": "I forgot I have to support him no matter what. Close one.", "order": 32069, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 10, "scene": 35, "line": "These questions are bush league.", "order": 32070, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 10, "scene": 35, "line": "You should get up and say something. You've got to be true to what's in there. [points to Oscar's heart] Don't be a wuss.", "order": 32071, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 10, "scene": 36, "line": "I've always been the guy who can rally other people to rebel. In high school I organized a walk out over standardized testing. Got over 500 students to just skip the SATs. At the last second I chickened out, took it anyway, got a 1220, always regretted it. I feel lachrymose .", "order": 32072, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 10, "scene": 37, "line": "[over the crowd booing] Believe me everyone is working very hard, working weekends, struggling, trying to right the ship.", "order": 32073, "speaker": "David Wallace"},
  {"season": 6, "episode": 10, "scene": 37, "line": "You're a criminal.", "order": 32074, "speaker": "Shareholder"},
  {"season": 6, "episode": 10, "scene": 37, "line": "Hey, hey, hey. I'm sorry. These are not criminals. They are nice. They invited me to come here today. They invited me to their hospitality suite where I had free food and it was delicious. Get this. Know how nice they are? The sent a stretch limo all the way to pick me up in Scranton. That's -", "order": 32075, "speaker": "Michael Scott"},
  {"season": 6, "episode": 10, "scene": 37, "line": "Limousine?", "order": 32076, "speaker": "Female Shareholder"},
  {"season": 6, "episode": 10, "scene": 37, "line": "Yes.", "order": 32077, "speaker": "Michael Scott"},
  {"season": 6, "episode": 10, "scene": 37, "line": "You're all corrupt. You should be in jail. [crowd agrees]", "order": 32078, "speaker": "Second Shareholder"},
  {"season": 6, "episode": 10, "scene": 37, "line": "Quiet! Quiet, please! Please, quiet! Quiet, please. We want to hear your questions.", "order": 32079, "speaker": "Alan"},
  {"season": 6, "episode": 10, "scene": 38, "line": "Do you love her or do you love the idea of her?", "order": 32080, "speaker": "Ryan Howard"},
  {"season": 6, "episode": 10, "scene": 38, "line": "I don't know, man. I just don't know.", "order": 32081, "speaker": "Creed Bratton"},
  {"season": 6, "episode": 10, "scene": 39, "line": "I gotta make an example out of him. Should I just fire him?", "order": 32082, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 10, "scene": 39, "line": "Can you actually fire people?", "order": 32083, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 10, "scene": 39, "line": "To be honest, I don't know, but maybe I could just yell at him in front of people.", "order": 32084, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 10, "scene": 39, "line": "Well, I can't really imagine you yelling at anyone.", "order": 32085, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 10, "scene": 39, "line": "Oh, well I yell. You've heard me yell.", "order": 32086, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 10, "scene": 39, "line": "Oh. Ok. I've heard you exclaim. Like the time you said, 'Hey, look. We parked over here!'.", "order": 32087, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 10, "scene": 39, "line": "Well, that was apple-picking day and there's no need to yell that day, you know. I was just excited to find the car. Perfect end to a perfect day.", "order": 32088, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 10, "scene": 39, "line": "Well, you'll figure it out.", "order": 32089, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 10, "scene": 40, "line": "Ok. We're gonna take a 15 minute break and then we're gonna answer more questions.", "order": 32090, "speaker": "Alan"},
  {"season": 6, "episode": 10, "scene": 40, "line": "You haven't said anything yet and we're headed for bankruptcy. [crowd agrees]", "order": 32091, "speaker": "Third Shareholder"},
  {"season": 6, "episode": 10, "scene": 40, "line": "[board of directors has started to exit] Yeah, well, that's not gonna happen. That's not gonna happen. We'll be back with some answers. [a few murmurs from the crowd of 'What answers?'] I - ok. I know that you're mad at me and you're mad at all them -", "order": 32092, "speaker": "Michael Scott"},
  {"season": 6, "episode": 10, "scene": 40, "line": "How are you gonna fix the company?", "order": 32093, "speaker": "Fourth Shareholder"},
  {"season": 6, "episode": 10, "scene": 40, "line": "Ok. Alright. We are gonna - we're gonna go out there during this break and we are gonna come back with a plan. We're gonna come back with a plan for you. It's a 45 day plan. 45 days to get us back on track. [crowd starts to agree and perk up] 45 points. It's a 45 day/45 point. One point per day. We get the 45 points we are back in business. [crowd applauds] And you can take that to the bank. [applause continue] And Limo Lady, we are going completely carbon neutral. [crowd has gotten very excited and is cheering] I love you, New York! [Michael does the spin and leaves the stage - then runs back across the stage] You. You.", "order": 32094, "speaker": "Michael Scott"},
  {"season": 6, "episode": 10, "scene": 41, "line": "Hey, Ryan. How's it going?", "order": 32095, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 10, "scene": 41, "line": "[playing Tetris on his computer] Here's the thing. Um, I've tried it like five different ways in my head and - Oh, got one. Um, I'm such a perfectionist...", "order": 32096, "speaker": "Ryan Howard"},
  {"season": 6, "episode": 10, "scene": 41, "line": "Mmm-hmm.", "order": 32097, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 10, "scene": 41, "line": "That I'd kinda rather not do it all then do a crappy version.", "order": 32098, "speaker": "Ryan Howard"},
  {"season": 6, "episode": 10, "scene": 41, "line": "Simple data entry, though. So there's really only one way to do it.", "order": 32099, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 10, "scene": 41, "line": "Sounds like you have a really specific vision for it. Do you wanna maybe just take a whack at it?", "order": 32100, "speaker": "Ryan Howard"},
  {"season": 6, "episode": 10, "scene": 41, "line": "You know what?", "order": 32101, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 10, "scene": 41, "line": "What?", "order": 32102, "speaker": "Ryan Howard"},
  {"season": 6, "episode": 10, "scene": 41, "line": "I think I know the problem.", "order": 32103, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 10, "scene": 41, "line": "Great.", "order": 32104, "speaker": "Ryan Howard"},
  {"season": 6, "episode": 10, "scene": 41, "line": "I think you seem distracted.", "order": 32105, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 10, "scene": 41, "line": "Yep, that is a problem.", "order": 32106, "speaker": "Ryan Howard"},
  {"season": 6, "episode": 10, "scene": 41, "line": "But, you know what? I came up with something and I think it's really gonna help.", "order": 32107, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 10, "scene": 41, "line": "Well, I'm glad you're finally being proactive, Jim.", "order": 32108, "speaker": "Ryan Howard"},
  {"season": 6, "episode": 10, "scene": 41, "line": "I am, too. Let me show you.", "order": 32109, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 10, "scene": 42, "line": "Wow. Things are really picking up.", "order": 32110, "speaker": "Michael Scott"},
  {"season": 6, "episode": 10, "scene": 42, "line": "What was that about?", "order": 32111, "speaker": "O'Keefe"},
  {"season": 6, "episode": 10, "scene": 42, "line": "What?", "order": 32112, "speaker": "Michael Scott"},
  {"season": 6, "episode": 10, "scene": 42, "line": "You were supposed to wave.", "order": 32113, "speaker": "Alan"},
  {"season": 6, "episode": 10, "scene": 42, "line": "I did wave. I did a lot more than wave.", "order": 32114, "speaker": "Michael Scott"},
  {"season": 6, "episode": 10, "scene": 42, "line": "What are we supposed to tell them now? Ok? You've dug us quite a hole.", "order": 32115, "speaker": "David Wallace"},
  {"season": 6, "episode": 10, "scene": 42, "line": "We tell them the plan, right?", "order": 32116, "speaker": "Michael Scott"},
  {"season": 6, "episode": 10, "scene": 42, "line": "There is no plan.", "order": 32117, "speaker": "David Wallace"},
  {"season": 6, "episode": 10, "scene": 42, "line": "Here's what we're gonna do. The 45 day thing that I outlined, we go with that. Day 45, company saved. Day 44, go. What do we got? We have 15 minutes.", "order": 32118, "speaker": "Michael Scott"},
  {"season": 6, "episode": 10, "scene": 42, "line": "Excuse me?", "order": 32119, "speaker": "O'Keefe"},
  {"season": 6, "episode": 10, "scene": 42, "line": "Just whatever comes to mind. Shout it out.", "order": 32120, "speaker": "Michael Scott"},
  {"season": 6, "episode": 10, "scene": 42, "line": "Who the hell is this guy?", "order": 32121, "speaker": "O'Keefe"},
  {"season": 6, "episode": 10, "scene": 42, "line": "I am the guy who roused that crowd.", "order": 32122, "speaker": "Michael Scott"},
  {"season": 6, "episode": 10, "scene": 42, "line": "Yes.", "order": 32123, "speaker": "David Wallace"},
  {"season": 6, "episode": 10, "scene": 42, "line": "Got them on their feet.", "order": 32124, "speaker": "Michael Scott"},
  {"season": 6, "episode": 10, "scene": 42, "line": "Yes.", "order": 32125, "speaker": "David Wallace"},
  {"season": 6, "episode": 10, "scene": 42, "line": "They were so happy down there.", "order": 32126, "speaker": "Michael Scott"},
  {"season": 6, "episode": 10, "scene": 42, "line": "Yes.", "order": 32127, "speaker": "David Wallace"},
  {"season": 6, "episode": 10, "scene": 42, "line": "Who cares?", "order": 32128, "speaker": "Alan"},
  {"season": 6, "episode": 10, "scene": 42, "line": "Ok. Alright. Well, I know a guy. This Mexican guy. He is a math whiz. He knows economics as well as he knows bull fighting and I am going to call in a little favor.", "order": 32129, "speaker": "Michael Scott"},
  {"season": 6, "episode": 10, "scene": 42, "line": "No. No. No. No. No. No. Don't call anybody, Michael.", "order": 32130, "speaker": "David Wallace"},
  {"season": 6, "episode": 10, "scene": 42, "line": "Well, I'm texting him, so.", "order": 32131, "speaker": "Michael Scott"},
  {"season": 6, "episode": 10, "scene": 42, "line": "Please do not text anybody, now, Michael.", "order": 32132, "speaker": "David Wallace"},
  {"season": 6, "episode": 10, "scene": 42, "line": "It's done. [Oscar, sitting in the lobby, receives a text]", "order": 32133, "speaker": "Michael Scott"},
  {"season": 6, "episode": 10, "scene": 43, "line": "Oh. There he is. Come on in. Come on in. Gentleman, I would like you to meet Oscar. Come on in. Don't be shy. This is Oscar Martinez. Oscar, why don't you come over here. Let me present you. Step up. Step up there. Oscar is an accountant extraordinaire at the Scranton branch and he has a wonderful idea as to how to get us out of this murky, murky situation. Take it away. [Oscar stands there stunned]", "order": 32134, "speaker": "Michael Scott"},
  {"season": 6, "episode": 10, "scene": 44, "line": "I think this is really gonna help. If you could just hold up here one second. [addresses the whole office] Hey, guys. Quick announcement. If I could just have everyone's attention. [turns back to Ryan] I just figured you needed a place where you can concentrate and not be bothered by bothering people.", "order": 32135, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 10, "scene": 44, "line": "Mmm-hmm.", "order": 32136, "speaker": "Ryan Howard"},
  {"season": 6, "episode": 10, "scene": 44, "line": "Let me show you what I mean. [Jim opens the closet door to reveal a work station] Your new office. How great is that, right? For a job well done. Well, not done.", "order": 32137, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 10, "scene": 44, "line": "I will, uh, I will do my work right now. I will stay late tonight.", "order": 32138, "speaker": "Ryan Howard"},
  {"season": 6, "episode": 10, "scene": 44, "line": "Right.", "order": 32139, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 10, "scene": 44, "line": "Uh, I'm very sorry... about everything.", "order": 32140, "speaker": "Ryan Howard"},
  {"season": 6, "episode": 10, "scene": 44, "line": "[laughs] You're a good kid. You know what? It gets bigger once you're in there. Enjoy it. [shuts the door and walks to his office]", "order": 32141, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 10, "scene": 44, "line": "[from behind the door] Is there internet?", "order": 32142, "speaker": "Ryan Howard"},
  {"season": 6, "episode": 10, "scene": 45, "line": "Ummm...", "order": 32143, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 10, "scene": 45, "line": "Tell them what you told me. This is genius.", "order": 32144, "speaker": "Michael Scott"},
  {"season": 6, "episode": 10, "scene": 45, "line": "Ok", "order": 32145, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 10, "scene": 45, "line": "Tell them about the cash flow and the dumping of properties and - this is all -", "order": 32146, "speaker": "Michael Scott"},
  {"season": 6, "episode": 10, "scene": 45, "line": "Um..", "order": 32147, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 10, "scene": 45, "line": "It's all good. It's all good stuff. Lay it on them.", "order": 32148, "speaker": "Michael Scott"},
  {"season": 6, "episode": 10, "scene": 45, "line": "I feel, as does Mr. Scott, that we are in the best of hands. Capable hands.", "order": 32149, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 10, "scene": 45, "line": "That wasn't what you were saying to me. He was much more articulate and that was better. Much better.", "order": 32150, "speaker": "Michael Scott"},
  {"season": 6, "episode": 10, "scene": 45, "line": "I think this has gone very well and I thank you.", "order": 32151, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 10, "scene": 45, "line": "O-kay. Oh... hey, hey. [follows Oscar into the hall] What are you doing?", "order": 32152, "speaker": "Michael Scott"},
  {"season": 6, "episode": 10, "scene": 45, "line": "Michael, I didn't ask to come up here.", "order": 32153, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 10, "scene": 45, "line": "Wow, man. That -", "order": 32154, "speaker": "Michael Scott"},
  {"season": 6, "episode": 10, "scene": 45, "line": "What?", "order": 32155, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 10, "scene": 45, "line": "That was embarrassing.", "order": 32156, "speaker": "Michael Scott"},
  {"season": 6, "episode": 10, "scene": 45, "line": "For me.", "order": 32157, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 10, "scene": 45, "line": "For me, too. You embarrassed me.", "order": 32158, "speaker": "Michael Scott"},
  {"season": 6, "episode": 10, "scene": 45, "line": "You -[Oscar walks away, Michael goes back in the room]", "order": 32159, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 10, "scene": 45, "line": "Well, that was a waste of a text. Let's get down to brass tacks. I think that we might be in trouble. We don't seem to have a plan so I'm thinking I go down there. Maybe rattle off a few jokes. Congressman could follow.", "order": 32160, "speaker": "Michael Scott"},
  {"season": 6, "episode": 10, "scene": 45, "line": "He's our best manager? Where's the off button on this moron?", "order": 32161, "speaker": "O'Keefe"},
  {"season": 6, "episode": 10, "scene": 45, "line": "Uh, I'm not a moron. Time after time my branch leads in sales. I have personally won over 17 Dundie awards. So, I am not a moron and I am just trying to help, you know? So... you're the moron.", "order": 32162, "speaker": "Michael Scott"},
  {"season": 6, "episode": 10, "scene": 46, "line": "[zipping through the hall while texting]", "order": 32163, "speaker": "Michael Scott"},
  {"season": 6, "episode": 10, "scene": 47, "line": "Yes it is a thrill to be honored by one's company. To have the people that you work for stop for a minute and say, 'Wow. Great job.' That is what it is all about. Not the perks. Perks? The perks. I could take or leave the perks. Limos are for people who make the company money not lose millions and have no plan. So we're leaving early. After all, we are the only ones with anything to celebrate.", "order": 32164, "speaker": "Michael Scott"},
  {"season": 6, "episode": 10, "scene": 48, "line": "He can take the bus. He's had his limo ride.", "order": 32165, "speaker": "O'Keefe"},
  {"season": 6, "episode": 10, "scene": 49, "line": "[running out of the building with Oscar, Andy, and Dwight] Oscar! Oscar! Come on! There he is! There he is! Go! Go! Go! Go! Go! Go! Go! Scranton! Scranton! Scranton! Dunder Mifflin!", "order": 32166, "speaker": "Michael Scott"},
  {"season": 6, "episode": 10, "scene": 50, "line": "The gentleman in the, uh, glasses.", "order": 32167, "speaker": "Alan"},
  {"season": 6, "episode": 10, "scene": 50, "line": "Dwight Schrute, Scranton branch. I just want to say that I have been standing in this line all day and if this line is any indication of how this company is being run then we are in big trouble. [cheers from crowd] Thank you. Right. I know. And I just want to say that I believe that there are options out there. [crowd still applauding] A take a number option like they have in a deli. What about line varieties? Like an express line for quick comments of ten words or less. They can move much more efficiently. What about ropes along the lines that you can hold on to.", "order": 32168, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 10, "scene": 50, "line": "Thank you. Thank you for your suggestions.", "order": 32169, "speaker": "Alan"},
  {"season": 6, "episode": 10, "scene": 50, "line": "Yeah.", "order": 32170, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 11, "scene": 1, "line": "You wanted to see me?", "order": 32171, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 11, "scene": 1, "line": "Yeah, Have a seat.", "order": 32172, "speaker": "Michael Scott"},
  {"season": 6, "episode": 11, "scene": 1, "line": "Is it serious? [Michael stares] Wow. Andy's a wittle scared.", "order": 32173, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 11, "scene": 1, "line": "Okay, right there is the problem. There have been reports around the office that you have been talking baby talk.", "order": 32174, "speaker": "Michael Scott"},
  {"season": 6, "episode": 11, "scene": 1, "line": "Why would people say that?", "order": 32175, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 11, "scene": 1, "line": "I have it on good authority that you said the following. [hands Andy a notecard] Can you read that back to me?", "order": 32176, "speaker": "Michael Scott"},
  {"season": 6, "episode": 11, "scene": 1, "line": "Andy have a boo-boo tummy.", "order": 32177, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 11, "scene": 1, "line": "Mmm-hmm.", "order": 32178, "speaker": "Michael Scott"},
  {"season": 6, "episode": 11, "scene": 1, "line": "Would you rather me say 'Hey guys, my irritable bowel syndrome is flaring up?'", "order": 32179, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 11, "scene": 1, "line": "Okay.", "order": 32180, "speaker": "Michael Scott"},
  {"season": 6, "episode": 11, "scene": 1, "line": "Crazy diarrhea happening right now?' Cause things can get real adult real fast.", "order": 32181, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 11, "scene": 1, "line": "You are also on record as saying 'wittle-ittle,' 'footy-wutties,' 'nummies,' 'jammies,' 'make boom-boom,' 'widiculous,' and 'wode iwand.'", "order": 32182, "speaker": "Michael Scott"},
  {"season": 6, "episode": 11, "scene": 1, "line": "Do I sometimes replace Rs with Ws? Yes. Do I sometimes repeat a word to get my point across? Well if I do, Andy's sowwy.", "order": 32183, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 11, "scene": 1, "line": "You can't be a baby in the office. It makes me look like I hire babies.", "order": 32184, "speaker": "Michael Scott"},
  {"season": 6, "episode": 11, "scene": 1, "line": "Well if I we're complaining, a lot of people think your Elvis voice is annoying.", "order": 32185, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 11, "scene": 1, "line": "Okay, who said that?", "order": 32186, "speaker": "Michael Scott"},
  {"season": 6, "episode": 11, "scene": 1, "line": "I don't-just people. For the record, I think it's pretty fantastic.", "order": 32187, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 11, "scene": 1, "line": "[Elvis voice] Well, thank you... thank you a lot. And for what it's worth I think your baby voice is tops.", "order": 32188, "speaker": "Michael Scott"},
  {"season": 6, "episode": 11, "scene": 1, "line": "[baby voice] Tank you Mr. Elwis.", "order": 32189, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 11, "scene": 1, "line": "[as Elvis] You're welcome, baby.", "order": 32190, "speaker": "Michael Scott"},
  {"season": 6, "episode": 11, "scene": 2, "line": "Dr. Tuna, MD, I have some terrible news.", "order": 32191, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 11, "scene": 2, "line": "Wait, are you the patient or the doctor?", "order": 32192, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 11, "scene": 2, "line": "The entire office has come down with a pernicious case of the Mondays.", "order": 32193, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 11, "scene": 2, "line": "Wow, what do you put our chances at?", "order": 32194, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 11, "scene": 2, "line": "0%, unless we perform an immediate emergency morale transplant, stat!", "order": 32195, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 11, "scene": 2, "line": "Hmm, sounds risky.", "order": 32196, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 11, "scene": 2, "line": "Don't worry. There is a surefire cure. Employee of the Month. Every awesome place I've worked has had one.", "order": 32197, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 11, "scene": 3, "line": "Bear Stearns, Lehman Brothers, AIG, my summer at Enron.", "order": 32198, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 11, "scene": 4, "line": "Wow, that's not such a bad idea.", "order": 32199, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 11, "scene": 4, "line": "Great! [sits down]", "order": 32200, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 11, "scene": 4, "line": "Anything else?", "order": 32201, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 11, "scene": 4, "line": "Nope! [stands up and leaves]", "order": 32202, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 11, "scene": 5, "line": "Frankie's Dirty Joke of the Day? There's a bunch of those.", "order": 32203, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 11, "scene": 5, "line": "Keep.", "order": 32204, "speaker": "Michael Scott"},
  {"season": 6, "episode": 11, "scene": 5, "line": "There's a bunch of Sent e-mails that jusy say 'Delivered.' Should I delete all of those?", "order": 32205, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 11, "scene": 5, "line": "I want to keep those so I can see what I sent.", "order": 32206, "speaker": "Michael Scott"},
  {"season": 6, "episode": 11, "scene": 5, "line": "That's why you have a 'Sent Mail' folder.", "order": 32207, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 11, "scene": 5, "line": "Keep.", "order": 32208, "speaker": "Michael Scott"},
  {"season": 6, "episode": 11, "scene": 5, "line": "There's about 30 news alerts for 'Nip Slip.'", "order": 32209, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 11, "scene": 5, "line": "For what?", "order": 32210, "speaker": "Michael Scott"},
  {"season": 6, "episode": 11, "scene": 5, "line": "Nip slip.'", "order": 32211, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 11, "scene": 5, "line": "Oh okay. I don't know how those got on there...", "order": 32212, "speaker": "Michael Scott"},
  {"season": 6, "episode": 11, "scene": 5, "line": "Well...", "order": 32213, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 11, "scene": 5, "line": "Must be hackers.", "order": 32214, "speaker": "Michael Scott"},
  {"season": 6, "episode": 11, "scene": 5, "line": "Hey. What's up?", "order": 32215, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 11, "scene": 5, "line": "What if I told you I had done the worst thing ever, would you still want to be my friend?", "order": 32216, "speaker": "Michael Scott"},
  {"season": 6, "episode": 11, "scene": 5, "line": "Did you murder someone?", "order": 32217, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 11, "scene": 5, "line": "Worse than that.", "order": 32218, "speaker": "Michael Scott"},
  {"season": 6, "episode": 11, "scene": 5, "line": "Oh, my God.", "order": 32219, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 11, "scene": 5, "line": "Lurk much? [Erin leaves] I miss Pam.", "order": 32220, "speaker": "Michael Scott"},
  {"season": 6, "episode": 11, "scene": 5, "line": "I think she's okay.", "order": 32221, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 11, "scene": 5, "line": "Is that what we're going for now? 'okay?' We used to go for 'pretty good.'", "order": 32222, "speaker": "Michael Scott"},
  {"season": 6, "episode": 11, "scene": 5, "line": "Great. Hey, how do you feel about starting an Employee of the Month program?", "order": 32223, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 11, "scene": 5, "line": "Yeah, you know what, that actually might make me feel better. I'm not in this for the trophies, but...", "order": 32224, "speaker": "Michael Scott"},
  {"season": 6, "episode": 11, "scene": 5, "line": "You're not in it at all, because you can't be employee of the month, you're a manager.", "order": 32225, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 11, "scene": 5, "line": "Well, technically, I'm a co-manager, and I barely have any responsibilities, but I work hard, I love this company, and for those reasons, I think I would make a good employee of the month.", "order": 32226, "speaker": "Michael Scott"},
  {"season": 6, "episode": 11, "scene": 5, "line": "It would look bad. Sorry.", "order": 32227, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 11, "scene": 5, "line": "It would look good, on my mantle.", "order": 32228, "speaker": "Michael Scott"},
  {"season": 6, "episode": 11, "scene": 6, "line": "Jim's talking to Michael right now. They totally went for our idea!", "order": 32229, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 11, "scene": 6, "line": "Yeah! Your idea.", "order": 32230, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 11, "scene": 7, "line": "My idea. I just need Andy to think it's his idea. So it won't get traced back to me. In approximately six hours, Jim will get a phone call from David Wallace. He will be fired.", "order": 32231, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 11, "scene": 8, "line": "Hey Pam, do you have a sec to check over this inventory?", "order": 32232, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 11, "scene": 8, "line": "[glances at it] Looks great.", "order": 32233, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 11, "scene": 8, "line": "Oh, did you really look it over? Felt like maybe you didn't.", "order": 32234, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 11, "scene": 8, "line": "Okay. [picks up and reads sheet] The Michael Scott Foundation is still in existence?", "order": 32235, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 11, "scene": 8, "line": "There you are. I don't think we have finished with my Inbox.", "order": 32236, "speaker": "Michael Scott"},
  {"season": 6, "episode": 11, "scene": 8, "line": "What's 'Scott's Tots?'", "order": 32237, "speaker": "Phyllis Vance"},
  {"season": 6, "episode": 11, "scene": 8, "line": "Has it really been ten years?", "order": 32238, "speaker": "Stanley Hudson"},
  {"season": 6, "episode": 11, "scene": 9, "line": "[shows newspaper article and reads] 'Local businessman pledges college tuition to third graders'", "order": 32239, "speaker": "Stanley Hudson"},
  {"season": 6, "episode": 11, "scene": 10, "line": "Michael, why did you promise that?", "order": 32240, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 11, "scene": 10, "line": "To change lives.", "order": 32241, "speaker": "Michael Scott"},
  {"season": 6, "episode": 11, "scene": 10, "line": "No Michael, why would you promise that?", "order": 32242, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 11, "scene": 10, "line": "Yeah, okay. Call the school, cancel. I can't go through with this.", "order": 32243, "speaker": "Michael Scott"},
  {"season": 6, "episode": 11, "scene": 10, "line": "We've already rescheduled seven times.", "order": 32244, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 11, "scene": 10, "line": "Michael, this is a terrible terrible thing you've done.", "order": 32245, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 11, "scene": 10, "line": "Well...", "order": 32246, "speaker": "Michael Scott"},
  {"season": 6, "episode": 11, "scene": 10, "line": "It's terrible.", "order": 32247, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 11, "scene": 10, "line": "No.", "order": 32248, "speaker": "Michael Scott"},
  {"season": 6, "episode": 11, "scene": 10, "line": "Just terrible. And the longer you put it off, the worse it's going to get.", "order": 32249, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 11, "scene": 11, "line": "I just, I fell in love with those kids. and I didn't want to see them fall victim to the system, so I made them a promise. I told them that if they graduated from high school, I would pay for their college education. I've made some empty promises in my life, but hands down, that was the most generous.", "order": 32250, "speaker": "Michael Scott"},
  {"season": 6, "episode": 11, "scene": 12, "line": "Just tell me it is going to be okay, all right?", "order": 32251, "speaker": "Michael Scott"},
  {"season": 6, "episode": 11, "scene": 12, "line": "No!", "order": 32252, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 11, "scene": 12, "line": "I'm not a bad person, I bring good news. Like when I promised those kids I'd pay for college. Okay, all right.", "order": 32253, "speaker": "Michael Scott"},
  {"season": 6, "episode": 11, "scene": 12, "line": "You have to tell them.", "order": 32254, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 11, "scene": 12, "line": "Would you come with me? You know, like old times, instead of... [makes yuck face at Erin]", "order": 32255, "speaker": "Michael Scott"},
  {"season": 6, "episode": 11, "scene": 12, "line": "I can print out a new itinerary with Pam's name on it.", "order": 32256, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 11, "scene": 12, "line": "It's fine. Erin, you're going to go. And you're going to make sure Michael tells the truth.", "order": 32257, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 11, "scene": 12, "line": "Agh, God, you know what, could this day get any worse?", "order": 32258, "speaker": "Michael Scott"},
  {"season": 6, "episode": 11, "scene": 13, "line": "Hey Jimmy, what's up?", "order": 32259, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 11, "scene": 13, "line": "Not much.", "order": 32260, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 11, "scene": 13, "line": "Cool. Very cool. I bet if you tried, you could grow the best beard of anyone in this office.", "order": 32261, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 11, "scene": 13, "line": "[laughs] Thanks Dwight.", "order": 32262, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 11, "scene": 13, "line": "That laugh is so infectious.", "order": 32263, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 11, "scene": 13, "line": "You're creeping me out. I'm gonna go.", "order": 32264, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 11, "scene": 13, "line": "I didn't mean any of those things I just said. And you can expect the same from everyone in this office if you don't nip this whole Employee of the Month situation in the bud. Make it about the work.", "order": 32265, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 11, "scene": 13, "line": "Let me guess, you think you should get it.", "order": 32266, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 11, "scene": 13, "line": "This encompasses all available data. Hours, tardiness, unconfirmed sick days, participation. Everyone has been assigned a random number for total fairness and transparency. Also, this should be accompanied by a monetary prize.", "order": 32267, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 11, "scene": 13, "line": "Well, in an ideal world...", "order": 32268, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 11, "scene": 13, "line": "In an ideal world, I would have all ten fingers on my left hand, so my right hand could just be a fist for punching.", "order": 32269, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 11, "scene": 13, "line": "Okay, if it's all the same to you, I'm just going to take away 'Survival Skills' and 'Self-defense.'", "order": 32270, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 11, "scene": 13, "line": "I'm going to run this by Toby and Accounting and have him assemble the data.", "order": 32271, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 11, "scene": 13, "line": "That's okay, I'll do it.", "order": 32272, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 11, "scene": 14, "line": "Mr. Scott?", "order": 32273, "speaker": "Mikela"},
  {"season": 6, "episode": 11, "scene": 14, "line": "Hey, Mikela Lasker, how are you?", "order": 32274, "speaker": "Michael Scott"},
  {"season": 6, "episode": 11, "scene": 14, "line": "I'm good.", "order": 32275, "speaker": "Mikela"},
  {"season": 6, "episode": 11, "scene": 14, "line": "Good to see you. Where's your saxaphone?", "order": 32276, "speaker": "Michael Scott"},
  {"season": 6, "episode": 11, "scene": 14, "line": "It's in the music room, Mr. Scott.", "order": 32277, "speaker": "Mikela"},
  {"season": 6, "episode": 11, "scene": 14, "line": "Eigth grade graduation, she gave a rendition of When The Saints Go Marching In that would blow your freaking mind.", "order": 32278, "speaker": "Michael Scott"},
  {"season": 6, "episode": 11, "scene": 14, "line": "Wow!", "order": 32279, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 11, "scene": 14, "line": "Well, you didn't even hear it.", "order": 32280, "speaker": "Michael Scott"},
  {"season": 6, "episode": 11, "scene": 14, "line": "Everyone's so excited that you're here today.", "order": 32281, "speaker": "Mikela"},
  {"season": 6, "episode": 11, "scene": 14, "line": "Oh, good.", "order": 32282, "speaker": "Michael Scott"},
  {"season": 6, "episode": 11, "scene": 14, "line": "Now, I know you probably want to see everyone, but I was wondering if I could ask you some questions first?", "order": 32283, "speaker": "Mikela"},
  {"season": 6, "episode": 11, "scene": 14, "line": "Sure.", "order": 32284, "speaker": "Michael Scott"},
  {"season": 6, "episode": 11, "scene": 14, "line": "[points to a sign saying The Michael Gary Scott Reading Room] You're famous.", "order": 32285, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 11, "scene": 14, "line": "I'm sure you remember this place.", "order": 32286, "speaker": "Mikela"},
  {"season": 6, "episode": 11, "scene": 14, "line": "Oh, yeah.", "order": 32287, "speaker": "Michael Scott"},
  {"season": 6, "episode": 11, "scene": 14, "line": "Do you want to go in?", "order": 32288, "speaker": "Mikela"},
  {"season": 6, "episode": 11, "scene": 14, "line": "No, not at all. Nope, come on.", "order": 32289, "speaker": "Michael Scott"},
  {"season": 6, "episode": 11, "scene": 15, "line": "Oh, you know what? You know what? I shouldn't go in there. I'm pretty busy, I should just...", "order": 32290, "speaker": "Michael Scott"},
  {"season": 6, "episode": 11, "scene": 15, "line": "We just want to say thanks.", "order": 32291, "speaker": "Mikela"},
  {"season": 6, "episode": 11, "scene": 15, "line": "Oh.", "order": 32292, "speaker": "Michael Scott"},
  {"season": 6, "episode": 11, "scene": 15, "line": "[cheering] Mr. Scott! Mr. Scott! Mr. Scott!", "order": 32293, "speaker": "Students"},
  {"season": 6, "episode": 11, "scene": 16, "line": "Mr. Scott, I know you're a busy man, and your schedule moves around a lot. But through all your generosity through the years, your tots, who are ready to graduate, thought it was time to give you a proper thank you.", "order": 32294, "speaker": "Teacher"},
  {"season": 6, "episode": 11, "scene": 16, "line": "[getting up and dancing] Oh! What? Oh! Break it down! Hey, Mr. Scott! What you gonna do? What you gonna do, make our dreams come true! Hey, Mr. Scott! What you gonna do? What you gonna do, make our dreams come true! Hey, Mr. Scott! What you gonna do? What you gonna do, make our dreams come true!", "order": 32295, "speaker": "Students"},
  {"season": 6, "episode": 11, "scene": 16, "line": "You came into our lives and made a promise, made us honest, made us realize, we don't need to compromise, cause we can have it all! Cause you made it possible, for us to achieve the improbable!", "order": 32296, "speaker": "Lefevre"},
  {"season": 6, "episode": 11, "scene": 16, "line": "Hey, Mr. Scott! What you gonna do? What you gonna do, make our dreams come true!", "order": 32297, "speaker": "Students"},
  {"season": 6, "episode": 11, "scene": 16, "line": "Wow!", "order": 32298, "speaker": "Michael Scott"},
  {"season": 6, "episode": 11, "scene": 17, "line": "Hey, so Jim thought it would be ideal if we all pitched in-", "order": 32299, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 11, "scene": 17, "line": "Hey.", "order": 32300, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 11, "scene": 17, "line": "So Jim thought it would be ideal if we all pitched in a 20 for this whole Employee of the Month thing.", "order": 32301, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 11, "scene": 17, "line": "Jim said that?", "order": 32302, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 11, "scene": 17, "line": "He thought you were doing an incredible job last month.", "order": 32303, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 11, "scene": 17, "line": "Jim said that?", "order": 32304, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 11, "scene": 17, "line": "You seem suprised.", "order": 32305, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 11, "scene": 17, "line": "No, no, that makes total sense.", "order": 32306, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 11, "scene": 17, "line": "Hey buddy, so every-", "order": 32307, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 11, "scene": 17, "line": "Sure. [puts in the twenty]", "order": 32308, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 11, "scene": 18, "line": "Five minutes ahead of schedule. Right on schedule.", "order": 32309, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 11, "scene": 19, "line": "Politicians are always coming around, telling us they're going to fix our schools, promising this and that. But you, Mr. Scott, you are actually doing it. You have taught these kids with hard work, that anything is possible. You are a dream maker and I thank you! I thank you, I thank you, I thank you, I thank you! So much.", "order": 32310, "speaker": "Teacher"},
  {"season": 6, "episode": 11, "scene": 20, "line": "There were a lot of times over the years where I was pressured to get into the drug game. But I always thought back to my guardian angel, and the gift that you gave me. So I just want to thank you for giving me the opportunity to go to college, educate myself, and become the next President Obama.", "order": 32311, "speaker": "Lefevre"},
  {"season": 6, "episode": 11, "scene": 20, "line": "[crying] Oh, God. Oh, God.", "order": 32312, "speaker": "Michael Scott"},
  {"season": 6, "episode": 11, "scene": 21, "line": "Okay, ladies and gentlemen, Welcome. I know you're all very excited, but no matter who gets this, I just want to say that you are all employees of the monthin my eyes. And the winner is, employee number nine. And that is?", "order": 32313, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 11, "scene": 21, "line": "Number nine, number nine. Jim Halpert.", "order": 32314, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 11, "scene": 21, "line": "Huh? What?", "order": 32315, "speaker": "Everyone"},
  {"season": 6, "episode": 11, "scene": 21, "line": "Are you kidding me?", "order": 32316, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 11, "scene": 21, "line": "Oh, no!", "order": 32317, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 11, "scene": 21, "line": "Okay, wait, I was not... I did not-", "order": 32318, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 11, "scene": 21, "line": "I... I... I did not... That was not... How come not?", "order": 32319, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 11, "scene": 21, "line": "Guys, listen. This was anonymous, all right? There is no way I could've given it to myself because I didn't even know who was on here. I just gave it to the employee with the highest overall score.", "order": 32320, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 11, "scene": 21, "line": "To my tally, you just won back your own parking spot, a vacation day, and a nice, tidy quarter of $1,000 from all of us.", "order": 32321, "speaker": "Angela Martin"},
  {"season": 6, "episode": 11, "scene": 21, "line": "Will everybody stop for a second, because obviously I'm not taking any of it. A mistake has been made and we'll figure it out. Second of all, there was no cash prize.", "order": 32322, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 11, "scene": 21, "line": "Yes!", "order": 32323, "speaker": "Angela Martin"},
  {"season": 6, "episode": 11, "scene": 21, "line": "Dwight?", "order": 32324, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 11, "scene": 21, "line": "Yeah, you said 'In an ideal world, there would be a cash prize.' Isn't that what you said?", "order": 32325, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 11, "scene": 21, "line": "No, Dwight, I meant... Yes, in a perfect world, someone would get money. But-", "order": 32326, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 11, "scene": 21, "line": "Yeah, but in your perfect world, only you get money.", "order": 32327, "speaker": "Phyllis Vance"},
  {"season": 6, "episode": 11, "scene": 22, "line": "Let's put your hands together and give a warm welcome for the man of the hour, Mr. Michael Scott.", "order": 32328, "speaker": "Teacher"},
  {"season": 6, "episode": 11, "scene": 22, "line": "All right. Wow. Um, I am never going to forget today. Not a chance. I don't think I could ever give back to you what you have given me today. Who here has done something stupid in their lives? Like skipped out on study hall or mix up the difference between 'A' gym and 'B' gym, that sort of thing? Show of hands, anybody, yes, a bunch of you, okay. Well, me too, I've done something stupid which I would like to share. Ummm. [period bell rings] Should we go?", "order": 32329, "speaker": "Michael Scott"},
  {"season": 6, "episode": 11, "scene": 22, "line": "Oh, no. We're okay. It's a double period", "order": 32330, "speaker": "Teacher"},
  {"season": 6, "episode": 11, "scene": 22, "line": "Ah. All right. I came here today because I promised you tuition and tuition is very valuable. But you know what's invaluable is intuition. You know what that is? That is the ability to know when something is about to happen. Does anybody out there have intuition? Know what's gonna happen next? Nobody? Okay, you're going to make me say it. All right, I am so proud of all of you. Derrick, and Lefevre, and Ben, and Ayana, and Mikela, and Nikki and Jason, and... I'm sorry, okay, sorry spacing, your name?", "order": 32331, "speaker": "Michael Scott"},
  {"season": 6, "episode": 11, "scene": 22, "line": "I'm Zion, I'm Mikela's younger brother.", "order": 32332, "speaker": "Zion"},
  {"season": 6, "episode": 11, "scene": 22, "line": "Well Zion, I am not going to be paying for your college tuition. Which brings me to my main point and that is that I will not be able to pay for anybody's tuition. I'm so, so sorry.", "order": 32333, "speaker": "Michael Scott"},
  {"season": 6, "episode": 11, "scene": 23, "line": "I'm sorry. I'm sorry. I'm so sorry.", "order": 32334, "speaker": "Michael Scott"},
  {"season": 6, "episode": 11, "scene": 23, "line": "You lied to us.", "order": 32335, "speaker": "Mikela"},
  {"season": 6, "episode": 11, "scene": 23, "line": "I lied to myself too. I'm not a millionaire. I thought I would be by the time I was 30, but I wasn't even close. And then I thought maybe by the time I was 40, but by 40, I had less money than when I was 30. Maybe by my 50s, I don't know. I wanted to pay for your education. I really did. It was my dream. Some people have evil drems, some people have selfish dreams or wet dreams. My dream was in the right place.", "order": 32336, "speaker": "Michael Scott"},
  {"season": 6, "episode": 11, "scene": 23, "line": "You owe this to us!", "order": 32337, "speaker": "Lefevre"},
  {"season": 6, "episode": 11, "scene": 23, "line": "Okay, hold on. Hold on. Now I can't pay for your college. But you don't have to go to class to be in class. Online courses are a viable option to a traditional college experience. And the best way to access those courses is with your own personal laptop. [students perk up] Which is rendered useless without batteries, and I have one for each of you.", "order": 32338, "speaker": "Michael Scott"},
  {"season": 6, "episode": 11, "scene": 23, "line": "[yelling]", "order": 32339, "speaker": "Students"},
  {"season": 6, "episode": 11, "scene": 23, "line": "Hey, hold-hold on, hold on, hold on, hold on. They're lithium.", "order": 32340, "speaker": "Michael Scott"},
  {"season": 6, "episode": 11, "scene": 24, "line": "Look, there was a big mistake obviously, so I will fix it. Okay? This was your idea anyway.", "order": 32341, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 11, "scene": 24, "line": "Correction. It was my idea.", "order": 32342, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 11, "scene": 24, "line": "Okay.", "order": 32343, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 11, "scene": 25, "line": "My idea!", "order": 32344, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 11, "scene": 26, "line": "Guys, I'm starting to think Pam's not really even pregnant.", "order": 32345, "speaker": "Creed Bratton"},
  {"season": 6, "episode": 11, "scene": 26, "line": "Ok, for the record, I am pregnant and, obviously there has been some kind of a mistake, so why doesn't Jim just pick the next highest score on the list and we'll move on.", "order": 32346, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 11, "scene": 26, "line": "That's fair.", "order": 32347, "speaker": "Phyllis Vance"},
  {"season": 6, "episode": 11, "scene": 26, "line": "Excellent idea, Pam.", "order": 32348, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 11, "scene": 26, "line": "Thank you.", "order": 32349, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 11, "scene": 26, "line": "That would be employee number three, which would be... son of a bitch, Pam Halpert.", "order": 32350, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 11, "scene": 26, "line": "How is that possible? No offense Pam, but how the hell is that possible?", "order": 32351, "speaker": "Phyllis Vance"},
  {"season": 6, "episode": 11, "scene": 26, "line": "There must be some reasonable explanation for this.", "order": 32352, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 11, "scene": 26, "line": "No, wait, come on. I didn't miss a day, I came in early, I stayed late, and I doubled my sales last month.", "order": 32353, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 11, "scene": 26, "line": "Oh, really? From what, two to four?", "order": 32354, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 11, "scene": 27, "line": "Yup.", "order": 32355, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 11, "scene": 28, "line": "Guys, this isn't some elaborate scam, okay?", "order": 32356, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 11, "scene": 28, "line": "Hi, I have an order here for a custom cake, Jim Halpert.", "order": 32357, "speaker": "Deliveryman"},
  {"season": 6, "episode": 11, "scene": 28, "line": "Okay. That is me, but I didn't order the cake.", "order": 32358, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 11, "scene": 28, "line": "Look who it is! [shows camera the cake, which has a picture of Jim]", "order": 32359, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 11, "scene": 28, "line": "It could only be you!' Way to go.", "order": 32360, "speaker": "Angela Martin"},
  {"season": 6, "episode": 11, "scene": 28, "line": "He knew all along!", "order": 32361, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 11, "scene": 28, "line": "I'm going to have some cake.", "order": 32362, "speaker": "Kelly Kapoor"},
  {"season": 6, "episode": 11, "scene": 29, "line": "David Wallace's office.", "order": 32363, "speaker": "Stephanie"},
  {"season": 6, "episode": 11, "scene": 29, "line": "[imitating Kevin] This is Kevin Malone, is David there?", "order": 32364, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 11, "scene": 29, "line": "No, he's in his weekly staff meeting, can I take a message?", "order": 32365, "speaker": "Stephanie"},
  {"season": 6, "episode": 11, "scene": 29, "line": "Tell him I'm mad at Jim, because he's asking us to give money to Pam.", "order": 32366, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 11, "scene": 30, "line": "[imitating Stanley] This is Stanley Hudson. Jim Halpert is a menace.", "order": 32367, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 11, "scene": 31, "line": "[imitating Toby] It's Toby Flenderson. Listen, things are getting really bad down here.", "order": 32368, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 11, "scene": 32, "line": "Hey, Mr. Scott.", "order": 32369, "speaker": "Lefevre"},
  {"season": 6, "episode": 11, "scene": 32, "line": "Erin, can you give us a second?", "order": 32370, "speaker": "Michael Scott"},
  {"season": 6, "episode": 11, "scene": 32, "line": "That was messed up what you did.", "order": 32371, "speaker": "Lefevre"},
  {"season": 6, "episode": 11, "scene": 32, "line": "Yeah. Yeah. I know, I know, I know, I'm sorry.", "order": 32372, "speaker": "Michael Scott"},
  {"season": 6, "episode": 11, "scene": 32, "line": "Who does something like that? Who promises that to a bunch of kids and then just doesn't come through like that?", "order": 32373, "speaker": "Lefevre"},
  {"season": 6, "episode": 11, "scene": 32, "line": "What can I do?", "order": 32374, "speaker": "Michael Scott"},
  {"season": 6, "episode": 11, "scene": 32, "line": "You can pay for my college.", "order": 32375, "speaker": "Lefevre"},
  {"season": 6, "episode": 11, "scene": 32, "line": "I can give you an extra laptop battery. Not everyone took one. How about this? If you can find a way to pay for your college tuition, let me buy your books, okay?", "order": 32376, "speaker": "Michael Scott"},
  {"season": 6, "episode": 11, "scene": 32, "line": "They're expensive.", "order": 32377, "speaker": "Lefevre"},
  {"season": 6, "episode": 11, "scene": 32, "line": "Yeah, well, I owe you that at least, right?", "order": 32378, "speaker": "Michael Scott"},
  {"season": 6, "episode": 11, "scene": 32, "line": "It's about $1,000.", "order": 32379, "speaker": "Lefevre"},
  {"season": 6, "episode": 11, "scene": 32, "line": "Really? Wow. That's over $200 a year.", "order": 32380, "speaker": "Michael Scott"},
  {"season": 6, "episode": 11, "scene": 32, "line": "No, $1,000 each year.", "order": 32381, "speaker": "Lefevre"},
  {"season": 6, "episode": 11, "scene": 32, "line": "For-okay, okay here's what I'm gonna do. I'm going to write 4 checks for $1,000 each. I'm going to date them one year apart. Now, you are to use one check a year. And please call me before you cash the check? Cause I've got monies to move around.", "order": 32382, "speaker": "Michael Scott"},
  {"season": 6, "episode": 11, "scene": 32, "line": "Okay. Okay. Make it out to my mom, Rosa.", "order": 32383, "speaker": "Lefevre"},
  {"season": 6, "episode": 11, "scene": 32, "line": "That's a lot of zeroes.", "order": 32384, "speaker": "Michael Scott"},
  {"season": 6, "episode": 11, "scene": 33, "line": "This is Jim.", "order": 32385, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 11, "scene": 33, "line": "Jim, what the hell is happening there?", "order": 32386, "speaker": "David Wallace"},
  {"season": 6, "episode": 11, "scene": 33, "line": "Hey, uh David.", "order": 32387, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 11, "scene": 33, "line": "Yes.", "order": 32388, "speaker": "David Wallace"},
  {"season": 6, "episode": 11, "scene": 33, "line": "So there was a little bit of a miscommunication here today. It's been a little wild, uh, but I am on it.", "order": 32389, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 11, "scene": 33, "line": "Just so I understand, you started Employee of the Month, give it to yourself, then people complain, so you give it to your wife?", "order": 32390, "speaker": "David Wallace"},
  {"season": 6, "episode": 11, "scene": 33, "line": "No.", "order": 32391, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 11, "scene": 33, "line": "Am I missing something?", "order": 32392, "speaker": "David Wallace"},
  {"season": 6, "episode": 11, "scene": 33, "line": "I really don't know how it happened, David.", "order": 32393, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 11, "scene": 34, "line": "I know how it happened.", "order": 32394, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 11, "scene": 35, "line": "Jim, I bumped you up because I thought you could make my life easier. If you can't do that, we're going to have a separate discussion.", "order": 32395, "speaker": "David Wallace"},
  {"season": 6, "episode": 11, "scene": 35, "line": "All right, it will not happen again. I promise.", "order": 32396, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 11, "scene": 35, "line": "Uh, hey, I'm sorry. I'm taking this out on you.", "order": 32397, "speaker": "David Wallace"},
  {"season": 6, "episode": 11, "scene": 35, "line": "No!", "order": 32398, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 11, "scene": 35, "line": "It's been crazy here. You know I think you're doing a great job, right?", "order": 32399, "speaker": "David Wallace"},
  {"season": 6, "episode": 11, "scene": 35, "line": "What?", "order": 32400, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 11, "scene": 35, "line": "Yeah. Thank you.", "order": 32401, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 11, "scene": 35, "line": "No.", "order": 32402, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 11, "scene": 35, "line": "Okay. Hey, we still on for dinner this weekend?", "order": 32403, "speaker": "David Wallace"},
  {"season": 6, "episode": 11, "scene": 35, "line": "What?", "order": 32404, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 11, "scene": 35, "line": "Yes.", "order": 32405, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 11, "scene": 35, "line": "Okay.", "order": 32406, "speaker": "David Wallace"},
  {"season": 6, "episode": 11, "scene": 35, "line": "Oh, you're kidding me!", "order": 32407, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 11, "scene": 35, "line": "See you.", "order": 32408, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 11, "scene": 35, "line": "Damn it!", "order": 32409, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 11, "scene": 36, "line": "Hey Mr. Scott, What you gonna do, What you gonna do make our dreams come true. Hey Mr. Scott, What you gonna do---", "order": 32410, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 11, "scene": 36, "line": "Please stop. 15 lives. I destroyed 15 young lives today.", "order": 32411, "speaker": "Michael Scott"},
  {"season": 6, "episode": 11, "scene": 36, "line": "No.", "order": 32412, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 11, "scene": 36, "line": "Yes.", "order": 32413, "speaker": "Michael Scott"},
  {"season": 6, "episode": 11, "scene": 36, "line": "No, there's financial aid, uh, they could join the army or the navy.", "order": 32414, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 11, "scene": 36, "line": "You're what, like, 12?", "order": 32415, "speaker": "Michael Scott"},
  {"season": 6, "episode": 11, "scene": 36, "line": "The principal told me that 90% of Scott's Tots are on track to graduate, and that's 35% higher than the rest of the school. So I think if you hadn't made that promise, a lot of them would've dropped out. Which is something to think about, I think.", "order": 32416, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 11, "scene": 36, "line": "I think you're doing a great job.", "order": 32417, "speaker": "Michael Scott"},
  {"season": 6, "episode": 11, "scene": 36, "line": "Really?", "order": 32418, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 11, "scene": 36, "line": "What do you want from this job, provided the company doesn't go out of business?", "order": 32419, "speaker": "Michael Scott"},
  {"season": 6, "episode": 11, "scene": 36, "line": "I've always wondered what it might be like to be an accountant.", "order": 32420, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 11, "scene": 36, "line": "Really?", "order": 32421, "speaker": "Michael Scott"},
  {"season": 6, "episode": 11, "scene": 36, "line": "Yes, but I'm terrible at math so...", "order": 32422, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 11, "scene": 36, "line": "You know, when I hired Kevin, he was actually applying for a job in the warehouse.", "order": 32423, "speaker": "Michael Scott"},
  {"season": 6, "episode": 11, "scene": 36, "line": "Seriously?", "order": 32424, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 11, "scene": 36, "line": "Yeah. I just sort've had a feeling about him. I have a feeling about you too.", "order": 32425, "speaker": "Michael Scott"},
  {"season": 6, "episode": 11, "scene": 36, "line": "Hey Mr. Scott, What you gonna do, What you gonna do make our dreams come true. Hey Mr. Scott, What you gonna do, What you gonna do make our dreams come true.", "order": 32426, "speaker": "Michael Scott & Erin Hannon"},
  {"season": 6, "episode": 11, "scene": 37, "line": "See you tomorrow, Dwight.", "order": 32427, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 11, "scene": 37, "line": "Apparently.", "order": 32428, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 11, "scene": 37, "line": "How's it going? Good day?", "order": 32429, "speaker": "Ryan Howard"},
  {"season": 6, "episode": 11, "scene": 37, "line": "Not now, Temp.", "order": 32430, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 11, "scene": 37, "line": "Um, okay, so, um, listen. I know about your diabolical plan.", "order": 32431, "speaker": "Ryan Howard"},
  {"season": 6, "episode": 11, "scene": 37, "line": "What? Diabolical plan? I wouldn't even know how to begin a diabol... [Ryan holds up a written copy of Dwight's daibolical plan]", "order": 32432, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 11, "scene": 37, "line": "I found a copy of it in the copier tray.", "order": 32433, "speaker": "Ryan Howard"},
  {"season": 6, "episode": 11, "scene": 37, "line": "So what do you want?", "order": 32434, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 11, "scene": 37, "line": "I want the same thing you want. I want to take Jim Halpert down. I want in.", "order": 32435, "speaker": "Ryan Howard"},
  {"season": 6, "episode": 12, "scene": 1, "line": "[yelling] Attention everyone! Jim and I, on behalf of--", "order": 32436, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 12, "scene": 1, "line": "Too loud.", "order": 32437, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 12, "scene": 1, "line": "--the party planning committee...", "order": 32438, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 12, "scene": 1, "line": "Too, too loud. Too Loud.", "order": 32439, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 12, "scene": 1, "line": "But effective, look! [everyone turns to pay attention] On behalf of Jim and I, Merry Christmas.", "order": 32440, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 12, "scene": 1, "line": "Merry Christmas.", "order": 32441, "speaker": "Everyone"},
  {"season": 6, "episode": 12, "scene": 1, "line": "Merry Christmas. Good.", "order": 32442, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 12, "scene": 2, "line": "My diabolical plot is on hold for Christmas. My heart just melts with the sound of children singing. Hmmph! [laughs] Not really! I'm just tired. The days are short. I don't know. Maybe I'm depressed.", "order": 32443, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 12, "scene": 3, "line": "It is time to unveil the tree.", "order": 32444, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 12, "scene": 3, "line": "Hey, Rockefeller Center!", "order": 32445, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 12, "scene": 3, "line": "Yeah.", "order": 32446, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 12, "scene": 3, "line": "Uh, I have actually been to Rock Center, and this is nothing like that.", "order": 32447, "speaker": "Ryan Howard"},
  {"season": 6, "episode": 12, "scene": 3, "line": "This is all we have.", "order": 32448, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 12, "scene": 3, "line": "Ugh.", "order": 32449, "speaker": "Ryan Howard"},
  {"season": 6, "episode": 12, "scene": 3, "line": "No, it's not 'ugh.' It is... office camaraderie.", "order": 32450, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 12, "scene": 3, "line": "It is warm feelings.", "order": 32451, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 12, "scene": 3, "line": "Why don't we talk more about it instead of doing it?", "order": 32452, "speaker": "Meredith Palmer"},
  {"season": 6, "episode": 12, "scene": 3, "line": "Thirty! Twenty-nine! Twenty-eight! [others join]", "order": 32453, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 12, "scene": 3, "line": "Why would you start so high?", "order": 32454, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 12, "scene": 3, "line": "Twenty- seven! Twenty-six! ...", "order": 32455, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 12, "scene": 3, "line": "Three, two, one! [pulls sheet to reveal Christmas tree]", "order": 32456, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 12, "scene": 3, "line": "Ooh.", "order": 32457, "speaker": "Phyllis Vance"},
  {"season": 6, "episode": 12, "scene": 3, "line": "Ah!", "order": 32458, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 12, "scene": 3, "line": "You didn't decorate it?", "order": 32459, "speaker": "Phyllis Vance"},
  {"season": 6, "episode": 12, "scene": 3, "line": "No.", "order": 32460, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 12, "scene": 3, "line": "Exactly.", "order": 32461, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 12, "scene": 3, "line": "We didn't, because we think it'd be better to do it together.", "order": 32462, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 12, "scene": 3, "line": "Everyone.", "order": 32463, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 12, "scene": 3, "line": "Why would you wrap it in a sheet, if you're not covering anything up?", "order": 32464, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 12, "scene": 3, "line": "Is it, is it fake?", "order": 32465, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 12, "scene": 3, "line": "Pam!", "order": 32466, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 12, "scene": 3, "line": "Yes, we are unveiling, an artificial tree, that will never die...", "order": 32467, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 12, "scene": 3, "line": "Yes.", "order": 32468, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 12, "scene": 3, "line": "Like the spirit of Christmas!", "order": 32469, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 12, "scene": 3, "line": "We're supposed to applaud you for taking a giant diaper off fake tree?", "order": 32470, "speaker": "Stanley Hudson"},
  {"season": 6, "episode": 12, "scene": 3, "line": "This was a successful unveiling! Go back to work! Merry Christmas!", "order": 32471, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 12, "scene": 4, "line": "Hello. Sorry guys. [louder, face is scratched up.] Sorry guys. I'm not sure I've earned the right to make announcements yet. But whoever is giving me the Twelve Days of Christmas? As my Secret Santa? Please stop. I can't take it anymore. My cat killed a turtledove; the French hens have started pulling out my hair to make a nest. Please. Stop.", "order": 32472, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 12, "scene": 4, "line": "What psycho would send that as a gift? [Kelly hugs Erin]", "order": 32473, "speaker": "Kelly Kapoor"},
  {"season": 6, "episode": 12, "scene": 5, "line": "I begged Dwight and Jim to give me Erin for Secret Santa. And I decided to give Erin the Twelve days of Christmas. Is it my fault that the first eight days is basically thirty birds? [six geese are inside Erin's car]", "order": 32474, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 12, "scene": 6, "line": "Ho ho ho! Merry Christmas boys and girls! It's me, Santa Claus!", "order": 32475, "speaker": "Phyllis Vance"},
  {"season": 6, "episode": 12, "scene": 6, "line": "Hey Santa!", "order": 32476, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 12, "scene": 6, "line": "They finally let you do it!", "order": 32477, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 12, "scene": 6, "line": "Yeah!", "order": 32478, "speaker": "Phyllis Vance"},
  {"season": 6, "episode": 12, "scene": 6, "line": "Congrats Phil!", "order": 32479, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 12, "scene": 6, "line": "It's so edgy!", "order": 32480, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 12, "scene": 7, "line": "I have been wanting to be Santa for years. I believe I have the right temperament, and the figure to do the job well. I slipped a note to Jim eleven weeks ago, and he said I could do it. [she awkwardly hugs Jim] It's been a long journey... but [sniffles] I'm Santa Claus!", "order": 32481, "speaker": "Phyllis Vance"},
  {"season": 6, "episode": 12, "scene": 8, "line": "So Santa, what can we expect from this party?", "order": 32482, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 12, "scene": 8, "line": "It's going to be a very jolly time if you've been good!", "order": 32483, "speaker": "Phyllis Vance"},
  {"season": 6, "episode": 12, "scene": 8, "line": "What if you've been bad?", "order": 32484, "speaker": "Creed Bratton"},
  {"season": 6, "episode": 12, "scene": 8, "line": "Oh then nothing but a lump of coal for you!", "order": 32485, "speaker": "Phyllis Vance"},
  {"season": 6, "episode": 12, "scene": 8, "line": "What if you've been really, really bad? More evil, and strictly wrong?", "order": 32486, "speaker": "Creed Bratton"},
  {"season": 6, "episode": 12, "scene": 8, "line": "Hey, Creed, we covered it. Lump of coal!", "order": 32487, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 12, "scene": 8, "line": "Yeah.", "order": 32488, "speaker": "Phyllis Vance"},
  {"season": 6, "episode": 12, "scene": 9, "line": "For several weeks, my Secret Santa has been giving me pieces of a machine that I've been attempting to assemble. I'm suspicious of this because I had the exact same idea, for catching Osama bin Laden. I would simply send him a different piece each day. He would assemble it, to find himself... in jail!", "order": 32489, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 12, "scene": 10, "line": "You know Oscar every time I make this lasagna people ask me if it's a family recipe, but really, I just get the recipe from the box!", "order": 32490, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 12, "scene": 10, "line": "That's funny.", "order": 32491, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 12, "scene": 10, "line": "Is it funny? I thought it was more ...interesting, than funny. [she walks over to see Oscar at the window looking down on man catching a football in parking lot] I think my water just broke!", "order": 32492, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 12, "scene": 10, "line": "That's too funny.", "order": 32493, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 12, "scene": 11, "line": "Oscar and the warehouse guy! Go Oscar! Go gay warehouse guy!", "order": 32494, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 12, "scene": 12, "line": "Heeeeeere's Santa! Hey little girl, what would you like for Christmas? Ooh, you have been a very naughty girl, I see. [points to Pam's' pregnant tummy]", "order": 32495, "speaker": "Michael Scott"},
  {"season": 6, "episode": 12, "scene": 12, "line": "Michael, we already have a Santa, Phyllis.", "order": 32496, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 12, "scene": 12, "line": "What the hell is going on?", "order": 32497, "speaker": "Michael Scott"},
  {"season": 6, "episode": 12, "scene": 13, "line": "Phyllis, Jim? Phyllis is Santa?", "order": 32498, "speaker": "Michael Scott"},
  {"season": 6, "episode": 12, "scene": 13, "line": "Yeah.", "order": 32499, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 12, "scene": 13, "line": "Phyllis?", "order": 32500, "speaker": "Michael Scott"},
  {"season": 6, "episode": 12, "scene": 13, "line": "Yeah I told her a long time ago she could do it.", "order": 32501, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 12, "scene": 13, "line": "Take it back. That is absurd.", "order": 32502, "speaker": "Michael Scott"},
  {"season": 6, "episode": 12, "scene": 13, "line": "No, I'm not gonna do that.", "order": 32503, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 12, "scene": 13, "line": "No? No! Jim! Come on!", "order": 32504, "speaker": "Michael Scott"},
  {"season": 6, "episode": 12, "scene": 13, "line": "I think she's doing a good job.", "order": 32505, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 12, "scene": 13, "line": "It's insane! A woman Santa, where does it stop? No! Jim, this may be the last Christmas that we have here, doesn't it make you a tiny bit anxious, me NOT playing Santa? Come on!", "order": 32506, "speaker": "Michael Scott"},
  {"season": 6, "episode": 12, "scene": 13, "line": "I'm not gonna go tell Phyllis that she can't be Santa.", "order": 32507, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 12, "scene": 13, "line": "Fine! Then do it anonymously. Ransom note style. You can- I -you know what, I have a bunch of letters cut out of magazines in my desk. You can use those.", "order": 32508, "speaker": "Michael Scott"},
  {"season": 6, "episode": 12, "scene": 14, "line": "If this were Russia, yeah sure. Everyone would go to one Santa, and there would be a line around the block and once you sat on her lap and she'd ask you what you wanted, you would say probably 'freedom.' At which point the KGB would arrest you and send you to Siberia. It's a good thing Russia doesn't exist anymore.", "order": 32509, "speaker": "Michael Scott"},
  {"season": 6, "episode": 12, "scene": 15, "line": "[santa voice] Ho ho ho! Why pay more to sit next to old Tranny Claus over there, when you can sit on my lap. Phyllis is only ... pretending to be a man, I'm the real thing. Sit down on my lap and there will be no doubt!", "order": 32510, "speaker": "Michael Scott"},
  {"season": 6, "episode": 12, "scene": 15, "line": "[mutters] Okay...", "order": 32511, "speaker": "Group"},
  {"season": 6, "episode": 12, "scene": 15, "line": "No it's not, not like penis-wise. [as Santa] Hello little boy, what's your name?", "order": 32512, "speaker": "Michael Scott"},
  {"season": 6, "episode": 12, "scene": 15, "line": "Michael it's me, Kevin. Phyllis says I'm too big for her lap.", "order": 32513, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 12, "scene": 15, "line": "Oh! I am so sorry that Phyllis hates you. And hates your body. But, Santa remembers a reindeer that was just a tiny bit different as well.", "order": 32514, "speaker": "Michael Scott"},
  {"season": 6, "episode": 12, "scene": 15, "line": "When can I sit on your lap?", "order": 32515, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 12, "scene": 15, "line": "Right now! Come on over here, big boy! There we go. [Kevin sits on his lap. It's a struggle] Oh my God.", "order": 32516, "speaker": "Michael Scott"},
  {"season": 6, "episode": 12, "scene": 15, "line": "That's really comfortable. [Michael gasps]", "order": 32517, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 12, "scene": 15, "line": "What would you like for Christmas little boy?", "order": 32518, "speaker": "Michael Scott"},
  {"season": 6, "episode": 12, "scene": 15, "line": "I don't know. I didn't know you were gonna ask me that.", "order": 32519, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 12, "scene": 15, "line": "What did you think was going to happen?", "order": 32520, "speaker": "Michael Scott"},
  {"season": 6, "episode": 12, "scene": 15, "line": "I didn't know. Nobody's ever let me sit on their lap before.", "order": 32521, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 12, "scene": 15, "line": "All right, just say 'some toys' please.", "order": 32522, "speaker": "Michael Scott"},
  {"season": 6, "episode": 12, "scene": 15, "line": "Can you give me some choices? Cause I really don't want to mess up on this list.", "order": 32523, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 12, "scene": 15, "line": "Damn it Kevin, come on.", "order": 32524, "speaker": "Michael Scott"},
  {"season": 6, "episode": 12, "scene": 15, "line": "What about if I tell you the things I don't want?", "order": 32525, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 12, "scene": 15, "line": "Okay get off, get off! [pushes Kevin off] Oh! Oh, God!", "order": 32526, "speaker": "Michael Scott"},
  {"season": 6, "episode": 12, "scene": 15, "line": "I didn't even get to tell you what I wanted!", "order": 32527, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 12, "scene": 15, "line": "Okay you know what you get? You get a thousand helium balloons attached to you so Santa doesn't have to go through this again.", "order": 32528, "speaker": "Michael Scott"},
  {"season": 6, "episode": 12, "scene": 15, "line": "Awesome.", "order": 32529, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 12, "scene": 16, "line": "Well?", "order": 32530, "speaker": "Phyllis Vance"},
  {"season": 6, "episode": 12, "scene": 16, "line": "I'm trying Phyllis. You're Santa.", "order": 32531, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 12, "scene": 16, "line": "The only Santa.", "order": 32532, "speaker": "Phyllis Vance"},
  {"season": 6, "episode": 12, "scene": 16, "line": "That's what I want.", "order": 32533, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 12, "scene": 16, "line": "You promised me this. Don't make me get Bob involved!", "order": 32534, "speaker": "Phyllis Vance"},
  {"season": 6, "episode": 12, "scene": 16, "line": "What would Bob do?", "order": 32535, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 12, "scene": 16, "line": "Never mind, I shouldn't have said that.", "order": 32536, "speaker": "Phyllis Vance"},
  {"season": 6, "episode": 12, "scene": 17, "line": "Does it hurt?", "order": 32537, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 12, "scene": 17, "line": "It stings a lot.", "order": 32538, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 12, "scene": 17, "line": "Hmm.", "order": 32539, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 12, "scene": 17, "line": "I keep trying to figure out who's sending me these gifts.", "order": 32540, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 12, "scene": 17, "line": "I know, right?", "order": 32541, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 12, "scene": 17, "line": "So far no one will admit to it.", "order": 32542, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 12, "scene": 17, "line": "Huh!", "order": 32543, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 12, "scene": 17, "line": "Is it you? [Andy stage face, eyes pop, big mouth smile, Erin laughs]", "order": 32544, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 12, "scene": 17, "line": "Yeah! Totally! I admit it! It's me.", "order": 32545, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 12, "scene": 17, "line": "Seriously?", "order": 32546, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 12, "scene": 17, "line": "Seriously?' Are you serious?", "order": 32547, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 12, "scene": 17, "line": "What?", "order": 32548, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 12, "scene": 17, "line": "Yeah! Totally serious! I'm your Secret Santa! Busted!", "order": 32549, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 12, "scene": 18, "line": "There he is.", "order": 32550, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 12, "scene": 18, "line": "Hey.", "order": 32551, "speaker": "Darryl Philbin"},
  {"season": 6, "episode": 12, "scene": 18, "line": "Is Matt around? I got his check.", "order": 32552, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 12, "scene": 18, "line": "Uh, Matt is on a delivery. Just leave it here, I'll take it.", "order": 32553, "speaker": "Darryl Philbin"},
  {"season": 6, "episode": 12, "scene": 18, "line": "Ah, I'll just... wait for him.", "order": 32554, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 12, "scene": 18, "line": "Matt's a pretty good-looking dude, don't you think?", "order": 32555, "speaker": "Darryl Philbin"},
  {"season": 6, "episode": 12, "scene": 18, "line": "I'll just leave it here with you. [drops checks, leaves. Daryl smirks]", "order": 32556, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 12, "scene": 19, "line": "Ryan come here!", "order": 32557, "speaker": "Michael Scott"},
  {"season": 6, "episode": 12, "scene": 19, "line": "Whoa, whoa.", "order": 32558, "speaker": "Ryan Howard"},
  {"season": 6, "episode": 12, "scene": 19, "line": "Come on, come on.", "order": 32559, "speaker": "Michael Scott"},
  {"season": 6, "episode": 12, "scene": 19, "line": "I'm doing something over here.", "order": 32560, "speaker": "Ryan Howard"},
  {"season": 6, "episode": 12, "scene": 19, "line": "Ahhh... Ho ho ho! [struggles to pull Ryan towards him] Come on I need this. Come over here!", "order": 32561, "speaker": "Michael Scott"},
  {"season": 6, "episode": 12, "scene": 19, "line": "What are you talking about?", "order": 32562, "speaker": "Ryan Howard"},
  {"season": 6, "episode": 12, "scene": 19, "line": "Just sit down!", "order": 32563, "speaker": "Michael Scott"},
  {"season": 6, "episode": 12, "scene": 19, "line": "No no no.", "order": 32564, "speaker": "Ryan Howard"},
  {"season": 6, "episode": 12, "scene": 19, "line": "Hey hey hey. Everything okay?", "order": 32565, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 12, "scene": 19, "line": "Yup. Ryan and I are just having some fun!", "order": 32566, "speaker": "Michael Scott"},
  {"season": 6, "episode": 12, "scene": 19, "line": "Can I just talk to you for a second?", "order": 32567, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 12, "scene": 19, "line": "Whoop! Okay, what?", "order": 32568, "speaker": "Michael Scott"},
  {"season": 6, "episode": 12, "scene": 19, "line": "You can't yell out 'I need this, I need this' as you pin down an employee on your lap!", "order": 32569, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 12, "scene": 19, "line": "Okay. You know what Jim, there are two Santas in the room. Things get ruthless!", "order": 32570, "speaker": "Michael Scott"},
  {"season": 6, "episode": 12, "scene": 19, "line": "Yup.", "order": 32571, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 12, "scene": 19, "line": "Do you understand, you forced my hand?", "order": 32572, "speaker": "Michael Scott"},
  {"season": 6, "episode": 12, "scene": 19, "line": "Michael, it's Phyllis. We want Phyllis as Santa.", "order": 32573, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 12, "scene": 19, "line": "Oh, do we? Do 'We?' Is that what 'we' want? Why don't 'we' take a vote on it?", "order": 32574, "speaker": "Michael Scott"},
  {"season": 6, "episode": 12, "scene": 19, "line": "Great!", "order": 32575, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 12, "scene": 19, "line": "Hey! Okay! Everybody! The office is being pulled apart! I realize that. There are traditions at stake. And there are--", "order": 32576, "speaker": "Michael Scott"},
  {"season": 6, "episode": 12, "scene": 19, "line": "Who wants Phyllis as Santa? [most everyone raises their hand]", "order": 32577, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 12, "scene": 19, "line": "No no no wait! No! There are other choices, there is information that we --[Dwight counts the raised hands] Are you kidding me? Kevin? After you almost killed me?", "order": 32578, "speaker": "Michael Scott"},
  {"season": 6, "episode": 12, "scene": 19, "line": "Michael I had you. I just want to try Phyllis.", "order": 32579, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 12, "scene": 19, "line": "And if you want Michael as Santa, raise your hand. [Michael, then Dwight raises hand] Can I get you some punch?", "order": 32580, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 12, "scene": 19, "line": "I'll get some punch. That looks delicious. [shoves Santa hat into punch]", "order": 32581, "speaker": "Michael Scott"},
  {"season": 6, "episode": 12, "scene": 19, "line": "Aw, Michael!", "order": 32582, "speaker": "Office"},
  {"season": 6, "episode": 12, "scene": 20, "line": "Excuse me. Whoever has been sending me the parts to this gun, I think you sent me a gear instead of a trigger.", "order": 32583, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 12, "scene": 20, "line": "How do you know it's a gun?", "order": 32584, "speaker": "Phyllis Vance"},
  {"season": 6, "episode": 12, "scene": 20, "line": "What else does it look like?", "order": 32585, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 12, "scene": 20, "line": "Not a gun.", "order": 32586, "speaker": "Stanley Hudson"},
  {"season": 6, "episode": 12, "scene": 20, "line": "Well I don't have all the pieces yet.", "order": 32587, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 12, "scene": 20, "line": "Well, unless the missing piece is a gun, you don't have a gun.", "order": 32588, "speaker": "Stanley Hudson"},
  {"season": 6, "episode": 12, "scene": 20, "line": "Not a gun. [crosses gun off his 'what could it be' list]", "order": 32589, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 12, "scene": 21, "line": "In the North Pole I spend many nights alone tinkering with toys. So today, let's put a twinkle in Ole Kris Kringle's eye! Let the party begin! [Dwight plugs in tree lights, office applauds.]", "order": 32590, "speaker": "Phyllis Vance"},
  {"season": 6, "episode": 12, "scene": 22, "line": "I don't ask for much for Christmas, I really don't. It's not like I'm begging people to buy me diamonds and ...broach pendants. 'Oh buy me something expensive or I'm gonna kill myself.' That's not - I don't care about that. All I want to be is Santa. And, you want to take that away from me? Fine. Go ahead. But when you need my help because I am ruining everything, don't look at me.", "order": 32591, "speaker": "Michael Scott"},
  {"season": 6, "episode": 12, "scene": 23, "line": "Behold! [extends his arms] Jesus Christ. And I bring to you glad Christmas tidings. I want to remind everyone the true meaning of Christmas. [Angela claps] Those of you who wish to join me, that's great. I'm excited by that. And those of you who don't, I forgive you. But I never forget.", "order": 32592, "speaker": "Michael Scott"},
  {"season": 6, "episode": 12, "scene": 23, "line": "Wow Michael, like this must be obvious how wrong this is.", "order": 32593, "speaker": "Toby Flenderson"},
  {"season": 6, "episode": 12, "scene": 23, "line": "Oh the Anti-Christ!", "order": 32594, "speaker": "Michael Scott"},
  {"season": 6, "episode": 12, "scene": 23, "line": "You can't, [Michael touches his forehead and makes sizzling noise] Ow. Look, you cannot push religion.", "order": 32595, "speaker": "Toby Flenderson"},
  {"season": 6, "episode": 12, "scene": 23, "line": "Oh but I can push drugs in here? Is that what you're saying?", "order": 32596, "speaker": "Michael Scott"},
  {"season": 6, "episode": 12, "scene": 23, "line": "No!", "order": 32597, "speaker": "Toby Flenderson"},
  {"season": 6, "episode": 12, "scene": 23, "line": "Well you have to pick one or the other. Your choice. Pick your poison. Get back to me. In the meantime, I am going to spread my goodness all over this company!", "order": 32598, "speaker": "Michael Scott"},
  {"season": 6, "episode": 12, "scene": 24, "line": "Hey Phyllis, could you not reveal to Erin that I'm her Secret Santa?", "order": 32599, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 12, "scene": 24, "line": "All right sweetie, I'll tell her its Michael.", "order": 32600, "speaker": "Phyllis Vance"},
  {"season": 6, "episode": 12, "scene": 24, "line": "Okay.", "order": 32601, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 12, "scene": 25, "line": "[singing] I saw three ships come sailing in, on Christmas day, on Christmas day...", "order": 32602, "speaker": "Kelly Kapoor & Erin Hannon"},
  {"season": 6, "episode": 12, "scene": 25, "line": "Have you talked to him?", "order": 32603, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 12, "scene": 25, "line": "Who?", "order": 32604, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 12, "scene": 25, "line": "Matt?", "order": 32605, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 12, "scene": 25, "line": "Is it that obvious? [Pam starts towards Matt] No- hey...", "order": 32606, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 12, "scene": 25, "line": "Mmm! Good pate, right? [Matt nods yes] Oscar made it. Have you met Oscar?", "order": 32607, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 12, "scene": 25, "line": "I don't think so.", "order": 32608, "speaker": "Matt"},
  {"season": 6, "episode": 12, "scene": 25, "line": "Hey, Oscar, come here. Oscar, this is Matt, and um, Matt loves your pate.", "order": 32609, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 12, "scene": 25, "line": "I had some.", "order": 32610, "speaker": "Matt"},
  {"season": 6, "episode": 12, "scene": 25, "line": "Oscar's pate is great. I could eat it all day, every day. Mmm. How come the good ones are never straight, right?", "order": 32611, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 12, "scene": 25, "line": "Okay Pam.", "order": 32612, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 12, "scene": 25, "line": "Okay.", "order": 32613, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 12, "scene": 26, "line": "Yes! They're the only two gay guys I know. But they should be together.", "order": 32614, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 12, "scene": 27, "line": "This just arrived from the Dunder Mifflin North Pole branch!", "order": 32615, "speaker": "Phyllis Vance"},
  {"season": 6, "episode": 12, "scene": 27, "line": "We don't have a North Pole branch. Idiot. [grabs package]", "order": 32616, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 12, "scene": 27, "line": "[on karaoke microphone] Uh oh, what's Dwight gonna get? What is iiiit?", "order": 32617, "speaker": "Michael Scott"},
  {"season": 6, "episode": 12, "scene": 27, "line": "Yes! [Dwight opens package to reveal another machine part]", "order": 32618, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 12, "scene": 27, "line": "Oh yes. It's space garbage. Dwight's gonna be able to build himself a friend! [sings] Deck the halls with crappy gifts...", "order": 32619, "speaker": "Michael Scott"},
  {"season": 6, "episode": 12, "scene": 27, "line": "Ho ho ho! For Stanley! Ho ho ho! You've been very good this year -", "order": 32620, "speaker": "Phyllis Vance"},
  {"season": 6, "episode": 12, "scene": 27, "line": "I have.", "order": 32621, "speaker": "Stanley Hudson"},
  {"season": 6, "episode": 12, "scene": 27, "line": "[on microphone] Yeah, except for cheating on your wife. Adultery's a sin, look it up in the Bible people. What'd you get?", "order": 32622, "speaker": "Michael Scott"},
  {"season": 6, "episode": 12, "scene": 27, "line": "He got scented candles!", "order": 32623, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 12, "scene": 27, "line": "[on microphone] Oh, that's appropriate. Lot of fire where you're going. Better get used to it. You're going to H-E-L-L double hockey sticks. You're going to Hell Stanley.", "order": 32624, "speaker": "Michael Scott"},
  {"season": 6, "episode": 12, "scene": 27, "line": "Amen.", "order": 32625, "speaker": "Angela Martin"},
  {"season": 6, "episode": 12, "scene": 27, "line": "And this brings us to you, little one.", "order": 32626, "speaker": "Phyllis Vance"},
  {"season": 6, "episode": 12, "scene": 27, "line": "[on microphone] I can't see from here people. Somebody shout it out, don't make me get up.", "order": 32627, "speaker": "Michael Scott"},
  {"season": 6, "episode": 12, "scene": 27, "line": "It's fabric! I really wanted this.", "order": 32628, "speaker": "Angela Martin"},
  {"season": 6, "episode": 12, "scene": 27, "line": "[on microphone] That's fantastic. You can make another dress that goes past your feet.", "order": 32629, "speaker": "Michael Scott"},
  {"season": 6, "episode": 12, "scene": 27, "line": "Andy, is this you?", "order": 32630, "speaker": "Angela Martin"},
  {"season": 6, "episode": 12, "scene": 27, "line": "[on microphone] No!", "order": 32631, "speaker": "Michael Scott"},
  {"season": 6, "episode": 12, "scene": 27, "line": "It's a secret.", "order": 32632, "speaker": "Phyllis Vance"},
  {"season": 6, "episode": 12, "scene": 27, "line": "[on microphone] Andy had Erin.", "order": 32633, "speaker": "Michael Scott"},
  {"season": 6, "episode": 12, "scene": 27, "line": "Nnnnya.", "order": 32634, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 12, "scene": 27, "line": "[on microphone] What? Was I not supposed to say it? [Jim unplugs mic] What? Turn it back on.", "order": 32635, "speaker": "Michael Scott"},
  {"season": 6, "episode": 12, "scene": 27, "line": "No. [Michael gets up and walks into his office]", "order": 32636, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 12, "scene": 28, "line": "Yes Michael, what is so urgent?", "order": 32637, "speaker": "David Wallace"},
  {"season": 6, "episode": 12, "scene": 28, "line": "David, guess who I'm sitting here dressed as.", "order": 32638, "speaker": "Michael Scott"},
  {"season": 6, "episode": 12, "scene": 28, "line": "I'm not going to guess. You can tell me or I am going to hang up.", "order": 32639, "speaker": "David Wallace"},
  {"season": 6, "episode": 12, "scene": 28, "line": "I'll give you a hint. His last name is Christ. He has the power of flight. He can heal leopards.", "order": 32640, "speaker": "Michael Scott"},
  {"season": 6, "episode": 12, "scene": 28, "line": "Michael...", "order": 32641, "speaker": "David Wallace"},
  {"season": 6, "episode": 12, "scene": 28, "line": "I'm Jesus, David, and you know why? Because Phyllis, a woman, has uslurped my role as Santa.", "order": 32642, "speaker": "Michael Scott"},
  {"season": 6, "episode": 12, "scene": 28, "line": "Michael-", "order": 32643, "speaker": "David Wallace"},
  {"season": 6, "episode": 12, "scene": 28, "line": "What?", "order": 32644, "speaker": "Michael Scott"},
  {"season": 6, "episode": 12, "scene": 28, "line": "This is a very very bad time.", "order": 32645, "speaker": "David Wallace"},
  {"season": 6, "episode": 12, "scene": 28, "line": "Really? What's going on?", "order": 32646, "speaker": "Michael Scott"},
  {"season": 6, "episode": 12, "scene": 28, "line": "Stephanie, can you hop off please?", "order": 32647, "speaker": "David Wallace"},
  {"season": 6, "episode": 12, "scene": 28, "line": "Sure David. [disconnect]", "order": 32648, "speaker": "Stephanie"},
  {"season": 6, "episode": 12, "scene": 28, "line": "What the hell? How rude! Does she do that all the time?", "order": 32649, "speaker": "Michael Scott"},
  {"season": 6, "episode": 12, "scene": 28, "line": "Do you want me off the call too Michael?", "order": 32650, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 12, "scene": 28, "line": "Hey! Get off! Get off the phone!", "order": 32651, "speaker": "Michael Scott"},
  {"season": 6, "episode": 12, "scene": 28, "line": "Listen, I shouldn't tell you this, but the company has a buyer. The board will have no choice to approve. They are going to clean house.", "order": 32652, "speaker": "David Wallace"},
  {"season": 6, "episode": 12, "scene": 28, "line": "What does that mean?", "order": 32653, "speaker": "Michael Scott"},
  {"season": 6, "episode": 12, "scene": 28, "line": "I'll be fired.", "order": 32654, "speaker": "David Wallace"},
  {"season": 6, "episode": 12, "scene": 28, "line": "Well, can't Alan protect you?", "order": 32655, "speaker": "Michael Scott"},
  {"season": 6, "episode": 12, "scene": 28, "line": "Alan will be out too. All of us.", "order": 32656, "speaker": "David Wallace"},
  {"season": 6, "episode": 12, "scene": 28, "line": "All of us?", "order": 32657, "speaker": "Michael Scott"},
  {"season": 6, "episode": 12, "scene": 28, "line": "Goodbye Michael.", "order": 32658, "speaker": "David Wallace"},
  {"season": 6, "episode": 12, "scene": 28, "line": "Oh my God.", "order": 32659, "speaker": "Michael Scott"},
  {"season": 6, "episode": 12, "scene": 29, "line": "Earlier today this office needed a Santa. And then it needed a second Santa. And then it needed a Jesus. And now, it needs a Michael. And that's one suit Phyllis cannot fit into.", "order": 32660, "speaker": "Michael Scott"},
  {"season": 6, "episode": 12, "scene": 30, "line": "Hey. How's everybody doing?", "order": 32661, "speaker": "Michael Scott"},
  {"season": 6, "episode": 12, "scene": 30, "line": "Not great. You heckled Santa for an hour and a half.", "order": 32662, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 12, "scene": 30, "line": "That was a different guy. That was Jesus. Jesus sort of ruined the party. Hurt petulant Jesus.", "order": 32663, "speaker": "Michael Scott"},
  {"season": 6, "episode": 12, "scene": 30, "line": "Are you serious? This is so offensive.", "order": 32664, "speaker": "Angela Martin"},
  {"season": 6, "episode": 12, "scene": 30, "line": "You know what's even more offensive, is baling on this party because some... jerk ruined the first part of it. Dwight come here. Here's my debit card, I want you to go pick up a bunch of pizzas and meet me in the conference room.", "order": 32665, "speaker": "Michael Scott"},
  {"season": 6, "episode": 12, "scene": 30, "line": "What's your pin number?", "order": 32666, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 12, "scene": 30, "line": "I don't want to say. But, 'it's fun to stay at the -' [nods to fill in blank, but Dwight doesn't know]", "order": 32667, "speaker": "Michael Scott"},
  {"season": 6, "episode": 12, "scene": 30, "line": "It's fun to stay at the -'", "order": 32668, "speaker": "Michael Scott"},
  {"season": 6, "episode": 12, "scene": 30, "line": "Where? Holiday Inn? I don't ...", "order": 32669, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 12, "scene": 30, "line": "[gestures the YMCA dance] Mmm, mm mm mm. [Dwight smirks, leaves]", "order": 32670, "speaker": "Michael Scott"},
  {"season": 6, "episode": 12, "scene": 31, "line": "I, I thought that you would like it.", "order": 32671, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 12, "scene": 31, "line": "It was a little much Andy.", "order": 32672, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 12, "scene": 31, "line": "Well it's the thought that counts.", "order": 32673, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 12, "scene": 31, "line": "What were you thinking? [Andy freezes]", "order": 32674, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 12, "scene": 32, "line": "I suppose a gentlemen might... throw in the towel, at this point. Guess what? Not gonna happen.", "order": 32675, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 12, "scene": 33, "line": "You know what, Christmas isn't about Santa, or Jesus. It's about the workplace. All of you feel like my family. Ryan, you are my son. And Pam, you're my wife. And Jim. And Angela and Phyllis, you are my Grandmas. And Stanley, you're our mailman. And I can't help but look at all your wonderful beautiful faces and think, 'How could they do this to us?'", "order": 32676, "speaker": "Michael Scott"},
  {"season": 6, "episode": 12, "scene": 33, "line": "What?", "order": 32677, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 12, "scene": 33, "line": "How could we do what? [general murmur]", "order": 32678, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 12, "scene": 33, "line": "It's not... Nevermind. Nothing. [arguing voices]", "order": 32679, "speaker": "Michael Scott"},
  {"season": 6, "episode": 12, "scene": 33, "line": "We deserve to know.", "order": 32680, "speaker": "Stanley Hudson"},
  {"season": 6, "episode": 12, "scene": 33, "line": "I didn't say anything!", "order": 32681, "speaker": "Michael Scott"},
  {"season": 6, "episode": 12, "scene": 33, "line": "If you know something Michael...", "order": 32682, "speaker": "Angela Martin"},
  {"season": 6, "episode": 12, "scene": 33, "line": "We're going out of business!", "order": 32683, "speaker": "Michael Scott"},
  {"season": 6, "episode": 12, "scene": 33, "line": "WHAT?", "order": 32684, "speaker": "Group"},
  {"season": 6, "episode": 12, "scene": 33, "line": "What?", "order": 32685, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 12, "scene": 33, "line": "You are kidding me!", "order": 32686, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 12, "scene": 33, "line": "Wait wait wait, who said that?", "order": 32687, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 12, "scene": 33, "line": "David told me on the phone. David told me.", "order": 32688, "speaker": "Michael Scott"},
  {"season": 6, "episode": 12, "scene": 33, "line": "When? When did he tell you? Today?", "order": 32689, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 12, "scene": 33, "line": "Earlier today.", "order": 32690, "speaker": "Michael Scott"},
  {"season": 6, "episode": 12, "scene": 33, "line": "Awww!", "order": 32691, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 12, "scene": 33, "line": "He said, we have been sold. [upset murmurs]", "order": 32692, "speaker": "Michael Scott"},
  {"season": 6, "episode": 12, "scene": 33, "line": "What?", "order": 32693, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 12, "scene": 33, "line": "Aaaarrhh! [kicks over Christmas tree]", "order": 32694, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 12, "scene": 33, "line": "Dwight! Jesus!", "order": 32695, "speaker": "Michael Scott"},
  {"season": 6, "episode": 12, "scene": 33, "line": "Come on!", "order": 32696, "speaker": "Angela Martin"},
  {"season": 6, "episode": 12, "scene": 33, "line": "Michael, wait. So they said we are sold?", "order": 32697, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 12, "scene": 33, "line": "David's gone. Alan's gone. We're all gone.", "order": 32698, "speaker": "Michael Scott"},
  {"season": 6, "episode": 12, "scene": 33, "line": "Okay. We haven't gone under. We've been sold and that can mean many different things.", "order": 32699, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 12, "scene": 33, "line": "It's hard for me to imagine a scenario, where Meredith Palmer keeps her job and David Wallace does not - no offense Meredith.", "order": 32700, "speaker": "Michael Scott"},
  {"season": 6, "episode": 12, "scene": 33, "line": "No, I get it.", "order": 32701, "speaker": "Meredith Palmer"},
  {"season": 6, "episode": 12, "scene": 33, "line": "I'll call him. I'll call David.", "order": 32702, "speaker": "Michael Scott"},
  {"season": 6, "episode": 12, "scene": 33, "line": "No, you won't, because he's not picking up his phone.", "order": 32703, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 12, "scene": 33, "line": "Yes, I will. Because I always know how to get through to him.", "order": 32704, "speaker": "Michael Scott"},
  {"season": 6, "episode": 12, "scene": 34, "line": "He told me where his kids go to school. I call the school, I tell them I'm a pediatrician. They patch me through to his secretary. I use my little girl voice. Badda bing, badda boom.", "order": 32705, "speaker": "Michael Scott"},
  {"season": 6, "episode": 12, "scene": 35, "line": "Hey Sweetie, what is it?", "order": 32706, "speaker": "David Wallace"},
  {"season": 6, "episode": 12, "scene": 35, "line": "It's not sweetie, it's Michael Scott.", "order": 32707, "speaker": "Michael Scott"},
  {"season": 6, "episode": 12, "scene": 35, "line": "What the hell?", "order": 32708, "speaker": "David Wallace"},
  {"season": 6, "episode": 12, "scene": 35, "line": "Stephanie could you hop off please?", "order": 32709, "speaker": "Michael Scott"},
  {"season": 6, "episode": 12, "scene": 35, "line": "Michael, I have never-", "order": 32710, "speaker": "David Wallace"},
  {"season": 6, "episode": 12, "scene": 35, "line": "David!", "order": 32711, "speaker": "Michael Scott"},
  {"season": 6, "episode": 12, "scene": 35, "line": "Ever, ever...", "order": 32712, "speaker": "David Wallace"},
  {"season": 6, "episode": 12, "scene": 35, "line": "David, you are on speakerphone with the entire branch, and the warehouse.", "order": 32713, "speaker": "Michael Scott"},
  {"season": 6, "episode": 12, "scene": 35, "line": "Hey David.", "order": 32714, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 12, "scene": 35, "line": "Hi David! [group hellos]", "order": 32715, "speaker": "Kelly Kapoor"},
  {"season": 6, "episode": 12, "scene": 35, "line": "Hello everyone.", "order": 32716, "speaker": "David Wallace"},
  {"season": 6, "episode": 12, "scene": 35, "line": "David, they do not believe me. When you told me everyone was canned.", "order": 32717, "speaker": "Michael Scott"},
  {"season": 6, "episode": 12, "scene": 35, "line": "You weren't supposed to tell anyone Michael.", "order": 32718, "speaker": "David Wallace"},
  {"season": 6, "episode": 12, "scene": 35, "line": "Well I think we're past that now.", "order": 32719, "speaker": "Michael Scott"},
  {"season": 6, "episode": 12, "scene": 35, "line": "I am not supposed to... Okay. Guys. I was really only talking about Alan, myself, a few other execs you don't know.", "order": 32720, "speaker": "David Wallace"},
  {"season": 6, "episode": 12, "scene": 35, "line": "Oh my God, David. That's horrible.", "order": 32721, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 12, "scene": 35, "line": "How 'bout us? It's Meredith. By the way, your wife is a very lucky woman.", "order": 32722, "speaker": "Meredith Palmer"},
  {"season": 6, "episode": 12, "scene": 35, "line": "Look. They're buying the company for the distribution. You guys are the only thing about this company that works. So congratulations.", "order": 32723, "speaker": "David Wallace"},
  {"season": 6, "episode": 12, "scene": 35, "line": "We're not fired?", "order": 32724, "speaker": "Michael Scott"},
  {"season": 6, "episode": 12, "scene": 35, "line": "No! No, and congratulations.", "order": 32725, "speaker": "David Wallace"},
  {"season": 6, "episode": 12, "scene": 35, "line": "Yeah! [office erupts in cheers and applause and hugs] It's a Christmas miracle!", "order": 32726, "speaker": "Michael Scott"},
  {"season": 6, "episode": 12, "scene": 35, "line": "Yeah!", "order": 32727, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 12, "scene": 35, "line": "Woo hoo! [climbs on desk, stage dives into crowd of employees]", "order": 32728, "speaker": "Michael Scott"},
  {"season": 6, "episode": 12, "scene": 36, "line": "[singing] I walk a lonely road, the only one that I have ever known. Don't know where it goes, but it's home to me and I walk alone. [Meredith and Madge help right the Christmas tree]", "order": 32729, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 12, "scene": 36, "line": "It's true. We all walk alone.", "order": 32730, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 12, "scene": 37, "line": "[opens 'New Moon' poster] Jim.... Jim! [Hugs him]", "order": 32731, "speaker": "Kelly Kapoor"},
  {"season": 6, "episode": 12, "scene": 38, "line": "[singing] My shadow's the only one that walks beside me. My shallow heart's the only thing that's beating...", "order": 32732, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 12, "scene": 39, "line": "[opening kite from Ryan] Wow! Cause... [reveals Kite Runner book]", "order": 32733, "speaker": "Toby Flenderson"},
  {"season": 6, "episode": 12, "scene": 39, "line": "Yeah! That was the idea.", "order": 32734, "speaker": "Ryan Howard"},
  {"season": 6, "episode": 12, "scene": 39, "line": "Thank you!", "order": 32735, "speaker": "Toby Flenderson"},
  {"season": 6, "episode": 12, "scene": 39, "line": "Awesome!", "order": 32736, "speaker": "Ryan Howard"},
  {"season": 6, "episode": 12, "scene": 39, "line": "Thanks man! [awkwardly tries to hug Ryan, pats him on the back]", "order": 32737, "speaker": "Toby Flenderson"},
  {"season": 6, "episode": 12, "scene": 40, "line": "[singing] Christmas tree, oh Christmas tree, thy leaves are so unchanging...", "order": 32738, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 12, "scene": 41, "line": "Oh! You got it? What is that? [Dwight building his gift]", "order": 32739, "speaker": "Michael Scott"},
  {"season": 6, "episode": 12, "scene": 41, "line": "I have no idea.", "order": 32740, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 12, "scene": 41, "line": "Huh. Well, maybe those will help. [puts bag of walnuts on his desk]", "order": 32741, "speaker": "Michael Scott"},
  {"season": 6, "episode": 12, "scene": 41, "line": "Michael? Please! Come on!", "order": 32742, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 12, "scene": 42, "line": "Nice to meet you Oscar.", "order": 32743, "speaker": "Matt"},
  {"season": 6, "episode": 12, "scene": 42, "line": "Nice to meet you Mark!", "order": 32744, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 12, "scene": 42, "line": "It's Matt.", "order": 32745, "speaker": "Matt"},
  {"season": 6, "episode": 12, "scene": 42, "line": "Right, Matt. [Matt leaves] I know what I'm doing, Pam.", "order": 32746, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 12, "scene": 43, "line": "Oh man! [Dwight dropped a walnut into the nutcracker] I can use this for so many nuts! Macadamias, Brazil nuts, pecans, almonds. Clams, snails...", "order": 32747, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 12, "scene": 44, "line": "Hi Santa.", "order": 32748, "speaker": "Michael Scott"},
  {"season": 6, "episode": 12, "scene": 44, "line": "Hi Michael. [Michael sits on her lap] Mm. 'kay.", "order": 32749, "speaker": "Phyllis Vance"},
  {"season": 6, "episode": 12, "scene": 44, "line": "I'd like to make a wish.", "order": 32750, "speaker": "Michael Scott"},
  {"season": 6, "episode": 12, "scene": 44, "line": "What?", "order": 32751, "speaker": "Phyllis Vance"},
  {"season": 6, "episode": 12, "scene": 44, "line": "I would like an Xbox, and a TV that's compatible with an Xbox. And, I'm sorry.", "order": 32752, "speaker": "Michael Scott"},
  {"season": 6, "episode": 12, "scene": 44, "line": "An Xbox it is. Because I've decided you're a good boy.", "order": 32753, "speaker": "Phyllis Vance"},
  {"season": 6, "episode": 12, "scene": 44, "line": "Scott! What in the hell is going on here? [Bob enters dressed as Santa too]", "order": 32754, "speaker": "Bob Vance"},
  {"season": 6, "episode": 12, "scene": 44, "line": "No Bob, we worked it out.", "order": 32755, "speaker": "Phyllis Vance"},
  {"season": 6, "episode": 12, "scene": 44, "line": "You called Bob?", "order": 32756, "speaker": "Michael Scott"},
  {"season": 6, "episode": 12, "scene": 44, "line": "I'm sorry,", "order": 32757, "speaker": "Phyllis Vance"},
  {"season": 6, "episode": 12, "scene": 44, "line": "Come on! Phyllis!", "order": 32758, "speaker": "Michael Scott"},
  {"season": 6, "episode": 12, "scene": 44, "line": "I'm really sorry. It was earlier today.", "order": 32759, "speaker": "Phyllis Vance"},
  {"season": 6, "episode": 12, "scene": 44, "line": "Okay.", "order": 32760, "speaker": "Michael Scott"},
  {"season": 6, "episode": 12, "scene": 44, "line": "Hi sweetie.", "order": 32761, "speaker": "Phyllis Vance"},
  {"season": 6, "episode": 12, "scene": 44, "line": "You okay baby?", "order": 32762, "speaker": "Bob"},
  {"season": 6, "episode": 12, "scene": 44, "line": "I'm good baby. [they kiss, both dressed as Santa]", "order": 32763, "speaker": "Phyllis Vance"},
  {"season": 6, "episode": 12, "scene": 44, "line": "Oh God. Get a room Santas!", "order": 32764, "speaker": "Michael Scott"},
  {"season": 6, "episode": 12, "scene": 45, "line": "Thank you Santa. Happy Holidays.", "order": 32765, "speaker": "Michael Scott"},
  {"season": 6, "episode": 12, "scene": 45, "line": "It's real slippery out here. [sound of drummer's starts]", "order": 32766, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 12, "scene": 45, "line": "Oh my God!", "order": 32767, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 12, "scene": 45, "line": "Twelve drummers drumming. [marching drum band lines up to perform, Andy steps out with cymbals]", "order": 32768, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 12, "scene": 45, "line": "Merry Christmas Erin! Take it away boys! [dances along to the drumming]", "order": 32769, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 13, "scene": 1, "line": "Dunder Mifflin is about to be sold. But first an investment banker has to drop by and sign off on our branch. And... I'm... pretty nervous about it. And... I'm... making some cosmetic tweaks to help create a more appealing environment. Is that dishonest? Well, think of it this way: when you look in the mirror and you see your push-up bra and your fake eyelashes and your make-up and your press-on nails; the principles that I am applying to the office are the same ones that have made Lady Gaga a star... or any number of drag queens.", "order": 32770, "speaker": "Michael Scott"},
  {"season": 6, "episode": 13, "scene": 2, "line": "Hi -", "order": 32771, "speaker": "Eric"},
  {"season": 6, "episode": 13, "scene": 2, "line": "[Dwight using a robotic voice over the PA system] Hello, Eric Ward. Welcome to Dunder Mifflin. I am Computron, your answer to everything.", "order": 32772, "speaker": "Computron"},
  {"season": 6, "episode": 13, "scene": 2, "line": "Hello.", "order": 32773, "speaker": "Eric"},
  {"season": 6, "episode": 13, "scene": 2, "line": "[entering on a Segway Scooter] I see you've met Computron, our virtual helper. I'm Michael Scott. Welcome. Welcome. Computron is just one of the many modern devices that I have incorporated into the office. Watch this. Computron?", "order": 32774, "speaker": "Michael Scott"},
  {"season": 6, "episode": 13, "scene": 2, "line": "Yes.", "order": 32775, "speaker": "Computron"},
  {"season": 6, "episode": 13, "scene": 2, "line": "What is the world's largest ocean?", "order": 32776, "speaker": "Michael Scott"},
  {"season": 6, "episode": 13, "scene": 2, "line": "Calculating. Calculating. Pacific!", "order": 32777, "speaker": "Computron"},
  {"season": 6, "episode": 13, "scene": 2, "line": "Pacific Ocean. Pretty cool, huh?", "order": 32778, "speaker": "Michael Scott"},
  {"season": 6, "episode": 13, "scene": 2, "line": "Great news, Michael. We're now the official paper supplier of the NFL.", "order": 32779, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 13, "scene": 2, "line": "That is fantastic. It's good, but it's not good enough. Keep working. And here's Pam. She's our international sales consultant.", "order": 32780, "speaker": "Michael Scott"},
  {"season": 6, "episode": 13, "scene": 2, "line": "Hello.", "order": 32781, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 13, "scene": 2, "line": "The NFL celebrated its 50th anniversary in 1972.", "order": 32782, "speaker": "Computron"},
  {"season": 6, "episode": 13, "scene": 2, "line": "Hola. Bonjour. Ni Hao.", "order": 32783, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 13, "scene": 3, "line": "It might seem crazy, but since there's no one left in New York, Michael is Dunder Mifflin's highest ranking employee. So, that's where we are.", "order": 32784, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 13, "scene": 4, "line": "Hello, Stanley.", "order": 32785, "speaker": "Michael Scott"},
  {"season": 6, "episode": 13, "scene": 4, "line": "Hi.", "order": 32786, "speaker": "Fake Stanley Hudson"},
  {"season": 6, "episode": 13, "scene": 5, "line": "For the record? Not on board with fake Stanley... although, I get it.", "order": 32787, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 13, "scene": 6, "line": "Uh, I just need to check out your warehouse and then talk to your HR guy. That is pretty much the only reason I'm here.", "order": 32788, "speaker": "Eric"},
  {"season": 6, "episode": 13, "scene": 6, "line": "Ok. Well, I thought I'd show you around a little bit -", "order": 32789, "speaker": "Michael Scott"},
  {"season": 6, "episode": 13, "scene": 6, "line": "Right.", "order": 32790, "speaker": "Eric"},
  {"season": 6, "episode": 13, "scene": 6, "line": "- since you made the trip out. And I got us reservations at Cooper's Seafood. You like lobster? You've had lobster before, right?", "order": 32791, "speaker": "Michael Scott"},
  {"season": 6, "episode": 13, "scene": 6, "line": "Yeah.", "order": 32792, "speaker": "Eric"},
  {"season": 6, "episode": 13, "scene": 6, "line": "They make the best Maine lobster in the world. You'll love it.", "order": 32793, "speaker": "Michael Scott"},
  {"season": 6, "episode": 13, "scene": 6, "line": "Mul Yam in Tel Aviv is better.", "order": 32794, "speaker": "Computron"},
  {"season": 6, "episode": 13, "scene": 6, "line": "No, Computron, actually I think Cooper's is the best. You're gonna love it.", "order": 32795, "speaker": "Michael Scott"},
  {"season": 6, "episode": 13, "scene": 6, "line": "Are you calling me wrong?", "order": 32796, "speaker": "Computron"},
  {"season": 6, "episode": 13, "scene": 6, "line": "[sighs] Oh, my God.", "order": 32797, "speaker": "Michael Scott"},
  {"season": 6, "episode": 13, "scene": 7, "line": "It's called 'due diligence'. Basically, I confirm inventory, take a head count, see if there are any HR liabilities. I'm a glorified fact checker. Actually, I am a fact checker.", "order": 32798, "speaker": "Eric"},
  {"season": 6, "episode": 13, "scene": 8, "line": "Alright. Excuse me. [slides past Eric to close the door to Ryan's closet office before Eric can see Ryan in there] If you will -", "order": 32799, "speaker": "Michael Scott"},
  {"season": 6, "episode": 13, "scene": 8, "line": "Michael, do you think I could get a space heater -", "order": 32800, "speaker": "Ryan Howard"},
  {"season": 6, "episode": 13, "scene": 8, "line": "- follow me this way. I'll introduce you to the crack HR rep that you requested. I give you Toby Flenderson. Have fun, you two. [Toby's chair spins around to reveal Dwight filling in for Toby]", "order": 32801, "speaker": "Michael Scott"},
  {"season": 6, "episode": 13, "scene": 8, "line": "Oh, we will. Have a seat. [Michael gives a pleased look then exits]", "order": 32802, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 13, "scene": 9, "line": "I left a copy of Best American Mystery Stories 1999 in Toby's favorite stall. So, yes, I think I bought us some time.", "order": 32803, "speaker": "Michael Scott"},
  {"season": 6, "episode": 13, "scene": 10, "line": "So, um, the manager, Michael Scott... is a bit of a character.", "order": 32804, "speaker": "Eric"},
  {"season": 6, "episode": 13, "scene": 10, "line": "He has more character in a single flake of his dandruff then you have in that entire snow bank on your shoulder. Wait, what was the question?", "order": 32805, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 13, "scene": 10, "line": "What are, are you doing at my desk?", "order": 32806, "speaker": "Toby Flenderson"},
  {"season": 6, "episode": 13, "scene": 10, "line": "Ignore him. He's the local lunatic.", "order": 32807, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 13, "scene": 10, "line": "Come on, Dwight. Get out of here.", "order": 32808, "speaker": "Toby Flenderson"},
  {"season": 6, "episode": 13, "scene": 10, "line": "Dwight? Who is this Dwight? Oh! You mean Dwight Schrute, the company's top salesman and the creator of Computron. I wear many hats but the one I'm currently wearing is that of gracious host. [laughs] Welcome. [Dwight exits]", "order": 32809, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 13, "scene": 10, "line": "Sorry. Hey, Toby Flenderson. Nice to meet you. How can I help?", "order": 32810, "speaker": "Toby Flenderson"},
  {"season": 6, "episode": 13, "scene": 10, "line": "Um, are there any outstanding liability issues to be aware of?", "order": 32811, "speaker": "Eric"},
  {"season": 6, "episode": 13, "scene": 10, "line": "What do you mean?", "order": 32812, "speaker": "Toby Flenderson"},
  {"season": 6, "episode": 13, "scene": 10, "line": "Well, safety issues, injuries that could leave the company open to potential lawsuits.", "order": 32813, "speaker": "Eric"},
  {"season": 6, "episode": 13, "scene": 10, "line": "Um... nothing comes to mind. [clip montage]", "order": 32814, "speaker": "Toby Flenderson"},
  {"season": 6, "episode": 13, "scene": 11, "line": "So, the staff?", "order": 32815, "speaker": "Eric"},
  {"season": 6, "episode": 13, "scene": 11, "line": "Mm-hmm.", "order": 32816, "speaker": "Toby Flenderson"},
  {"season": 6, "episode": 13, "scene": 11, "line": "Are people generally happy?", "order": 32817, "speaker": "Eric"},
  {"season": 6, "episode": 13, "scene": 11, "line": "[laughing] Happy's a funny word.", "order": 32818, "speaker": "Toby Flenderson"},
  {"season": 6, "episode": 13, "scene": 11, "line": "In what way?", "order": 32819, "speaker": "Eric"},
  {"season": 6, "episode": 13, "scene": 11, "line": "You know, uh, what does it mean to be happy. [shakes head] Keep philosophers busy for awhile.", "order": 32820, "speaker": "Toby Flenderson"},
  {"season": 6, "episode": 13, "scene": 11, "line": "So... generally?", "order": 32821, "speaker": "Eric"},
  {"season": 6, "episode": 13, "scene": 11, "line": "Yes.", "order": 32822, "speaker": "Toby Flenderson"},
  {"season": 6, "episode": 13, "scene": 11, "line": "Generally.", "order": 32823, "speaker": "Eric"},
  {"season": 6, "episode": 13, "scene": 11, "line": "Generally happy. [clip montage]", "order": 32824, "speaker": "Toby Flenderson"},
  {"season": 6, "episode": 13, "scene": 11, "line": "[whispering] Generally happy.", "order": 32825, "speaker": "Eric"},
  {"season": 6, "episode": 13, "scene": 12, "line": "What about non-safety issues, in terms of liability? Sexual harassment. Anything like that?", "order": 32826, "speaker": "Eric"},
  {"season": 6, "episode": 13, "scene": 12, "line": "Oh, I don't know.", "order": 32827, "speaker": "Toby Flenderson"},
  {"season": 6, "episode": 13, "scene": 12, "line": "You, you don't know.", "order": 32828, "speaker": "Eric"},
  {"season": 6, "episode": 13, "scene": 12, "line": "I don't know.", "order": 32829, "speaker": "Toby Flenderson"},
  {"season": 6, "episode": 13, "scene": 12, "line": "You're the head of Human Resources.", "order": 32830, "speaker": "Eric"},
  {"season": 6, "episode": 13, "scene": 12, "line": "I don't know. No.", "order": 32831, "speaker": "Toby Flenderson"},
  {"season": 6, "episode": 13, "scene": 12, "line": "Ok. [clip montage]", "order": 32832, "speaker": "Eric"},
  {"season": 6, "episode": 13, "scene": 13, "line": "I don't know how I'm going to get through this. I don't want to lie and I DON'T want to tell the truth.", "order": 32833, "speaker": "Toby Flenderson"},
  {"season": 6, "episode": 13, "scene": 14, "line": "Hey, Tobes. Whatcha doin'? Whatcha guys talkin' about?", "order": 32834, "speaker": "Michael Scott"},
  {"season": 6, "episode": 13, "scene": 14, "line": "Well, Eric's just going over some stuff.", "order": 32835, "speaker": "Toby Flenderson"},
  {"season": 6, "episode": 13, "scene": 14, "line": "Stuff. I love stuff.", "order": 32836, "speaker": "Michael Scott"},
  {"season": 6, "episode": 13, "scene": 14, "line": "It's, uh, it's HR stuff.", "order": 32837, "speaker": "Toby Flenderson"},
  {"season": 6, "episode": 13, "scene": 14, "line": "HR stuff? [pulls up a chair] HR Pufnstuf. Right up my alley. [pointing to Eric's notes] What is that? What kind of stuff is that?", "order": 32838, "speaker": "Michael Scott"},
  {"season": 6, "episode": 13, "scene": 14, "line": "It's uh, it's a company evaluation form.", "order": 32839, "speaker": "Eric"},
  {"season": 6, "episode": 13, "scene": 14, "line": "Ah.", "order": 32840, "speaker": "Michael Scott"},
  {"season": 6, "episode": 13, "scene": 14, "line": "Talkin' bout, uh -", "order": 32841, "speaker": "Eric"},
  {"season": 6, "episode": 13, "scene": 14, "line": "Talkin' bout, uh, what?", "order": 32842, "speaker": "Michael Scott"},
  {"season": 6, "episode": 13, "scene": 14, "line": "Waste is next.", "order": 32843, "speaker": "Eric"},
  {"season": 6, "episode": 13, "scene": 14, "line": "Waste? What does that even mean? Like garbage?", "order": 32844, "speaker": "Michael Scott"},
  {"season": 6, "episode": 13, "scene": 14, "line": "No, waste of time and resources.", "order": 32845, "speaker": "Eric"},
  {"season": 6, "episode": 13, "scene": 14, "line": "Oh, time and resources.", "order": 32846, "speaker": "Michael Scott"},
  {"season": 6, "episode": 13, "scene": 14, "line": "Look, you know, in any company there's going to be certain degrees -", "order": 32847, "speaker": "Toby Flenderson"},
  {"season": 6, "episode": 13, "scene": 14, "line": "No. No. No. No. No. No. Respectfully, Toby, no. No. This company does not waste time or resources, ever. [clip montage]", "order": 32848, "speaker": "Michael Scott"},
  {"season": 6, "episode": 13, "scene": 15, "line": "Is anyone near retirement age? [Michael and Toby look at each other - Creed clip montage]", "order": 32849, "speaker": "Eric"},
  {"season": 6, "episode": 13, "scene": 16, "line": "[on the phone] Hey, uh, yeah, I finally got a chance to sit down with HR. So... well, I think I'm gonna be here for awhile.", "order": 32850, "speaker": "Eric"},
  {"season": 6, "episode": 13, "scene": 17, "line": "This is a building where friends become lovers and lovers become sexually interactive. [to Toby] Right? Would you agree with that?", "order": 32851, "speaker": "Michael Scott"},
  {"season": 6, "episode": 13, "scene": 17, "line": "Michael, this is really inappropriate to talk about.", "order": 32852, "speaker": "Toby Flenderson"},
  {"season": 6, "episode": 13, "scene": 17, "line": "That's, that is true. [clip montage]", "order": 32853, "speaker": "Michael Scott"},
  {"season": 6, "episode": 13, "scene": 18, "line": "[as Eric is being escorted out by Michael and Toby] Thank you, so much.", "order": 32854, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 13, "scene": 18, "line": "Thank you.", "order": 32855, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 13, "scene": 18, "line": "Thank you.", "order": 32856, "speaker": "Angela Martin"},
  {"season": 6, "episode": 13, "scene": 18, "line": "Thank you. It's nice to meet you all.", "order": 32857, "speaker": "Eric"},
  {"season": 6, "episode": 13, "scene": 18, "line": "Good to meet you.", "order": 32858, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 13, "scene": 18, "line": "Have a great day.", "order": 32859, "speaker": "Angela Martin"},
  {"season": 6, "episode": 13, "scene": 18, "line": "Hope to see you soon.", "order": 32860, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 13, "scene": 18, "line": "Yeah.", "order": 32861, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 13, "scene": 19, "line": "I feel very sorry for that banker because he has to evaluate what we are worth. He has to decide what we are capable of and how do you do that? What is Jim capable of... or Pam... or Kevin? [clip montage over Daryl's Dunder Mifflin jingle]", "order": 32862, "speaker": "Michael Scott"},
  {"season": 6, "episode": 13, "scene": 20, "line": "I don't care if he goes and files a report and says that we're nothing special because I think our future is very bright. We have only just begun.", "order": 32863, "speaker": "Michael Scott"},
  {"season": 6, "episode": 13, "scene": 20, "line": "Computron experiencing emotion.", "order": 32864, "speaker": "Computron"},
  {"season": 6, "episode": 13, "scene": 20, "line": "Computron, I'm gonna pull your plug. Ok? Buddy?", "order": 32865, "speaker": "Michael Scott"},
  {"season": 6, "episode": 13, "scene": 20, "line": "Ok.", "order": 32866, "speaker": "Computron"},
  {"season": 6, "episode": 13, "scene": 20, "line": "Just - sh -", "order": 32867, "speaker": "Michael Scott"},
  {"season": 6, "episode": 13, "scene": 20, "line": "[after a pause] Please don't. Computron -", "order": 32868, "speaker": "Computron"},
  {"season": 6, "episode": 13, "scene": 20, "line": "Shut -", "order": 32869, "speaker": "Michael Scott"},
  {"season": 6, "episode": 13, "scene": 20, "line": "wants to live.", "order": 32870, "speaker": "Computron"},
  {"season": 6, "episode": 13, "scene": 20, "line": "Shut up. Shut up.", "order": 32871, "speaker": "Michael Scott"},
  {"season": 6, "episode": 13, "scene": 21, "line": "[extended Lazy Scranton video] Sittin' in my office with a plate of grilled bacon. Called my man, Dwight, just to see what was shakin'.", "order": 32872, "speaker": "Michael Scott"},
  {"season": 6, "episode": 13, "scene": 21, "line": "Yo, Mike, our town is dope and pretty.", "order": 32873, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 13, "scene": 21, "line": "So check out how we live", "order": 32874, "speaker": "Michael Scott"},
  {"season": 6, "episode": 13, "scene": 21, "line": "in the Electric City!", "order": 32875, "speaker": "Both"},
  {"season": 6, "episode": 13, "scene": 21, "line": "They call it Scranton!", "order": 32876, "speaker": "Michael Scott"},
  {"season": 6, "episode": 13, "scene": 21, "line": "What?", "order": 32877, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 13, "scene": 21, "line": "The Electric City. Scranton!", "order": 32878, "speaker": "Michael Scott"},
  {"season": 6, "episode": 13, "scene": 21, "line": "What?", "order": 32879, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 13, "scene": 21, "line": "The Electric City. Lazy Scranton, the Electric City. They call it that 'cause of the electricity. The city's laid out from East to West and our public parks are libraries are truly the best. Call poison control if you're bitten by a spider.", "order": 32880, "speaker": "Michael Scott"},
  {"season": 6, "episode": 13, "scene": 21, "line": "But check that it's covered by your", "order": 32881, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 13, "scene": 21, "line": "Healthcare Provider!", "order": 32882, "speaker": "Both"},
  {"season": 6, "episode": 13, "scene": 21, "line": "Plenty of space in the parking lot.", "order": 32883, "speaker": "Michael Scott"},
  {"season": 6, "episode": 13, "scene": 21, "line": "But the little cars go in the compact spot!", "order": 32884, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 13, "scene": 21, "line": "Spot. Spot. Spot. Spot. Spot.", "order": 32885, "speaker": "Both"},
  {"season": 6, "episode": 13, "scene": 21, "line": "Snack attack time.", "order": 32886, "speaker": "Michael Scott"},
  {"season": 6, "episode": 13, "scene": 21, "line": "Don't lose your head.", "order": 32887, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 13, "scene": 21, "line": "We like Cugino's", "order": 32888, "speaker": "Michael Scott"},
  {"season": 6, "episode": 13, "scene": 21, "line": "for the tasty bread.", "order": 32889, "speaker": "Both"},
  {"season": 6, "episode": 13, "scene": 21, "line": "They call it Scranton!", "order": 32890, "speaker": "Michael Scott"},
  {"season": 6, "episode": 13, "scene": 21, "line": "What?", "order": 32891, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 13, "scene": 21, "line": "The Electric City. Scranton!", "order": 32892, "speaker": "Michael Scott"},
  {"season": 6, "episode": 13, "scene": 21, "line": "What?", "order": 32893, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 13, "scene": 21, "line": "The Electric City. Scranton!", "order": 32894, "speaker": "Michael Scott"},
  {"season": 6, "episode": 13, "scene": 21, "line": "What?", "order": 32895, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 13, "scene": 21, "line": "The Electric City. Scranton!", "order": 32896, "speaker": "Michael Scott"},
  {"season": 6, "episode": 13, "scene": 21, "line": "What?", "order": 32897, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 13, "scene": 21, "line": "The Electric City. Scranton!", "order": 32898, "speaker": "Michael Scott"},
  {"season": 6, "episode": 13, "scene": 21, "line": "[pause] What?", "order": 32899, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 13, "scene": 21, "line": "Ugh.", "order": 32900, "speaker": "Michael Scott"},
  {"season": 6, "episode": 14, "scene": 1, "line": "Oh, yeah. [clapping and chanting] I got a big box, yes I do. I got a big box how 'bout you? [points to Erin]", "order": 32901, "speaker": "Michael Scott"},
  {"season": 6, "episode": 14, "scene": 1, "line": "[clapping] I got a big box, yes I do. I got a big box how 'bout you? [points to Oscar]", "order": 32902, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 14, "scene": 1, "line": "I think you don't know what you're saying.", "order": 32903, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 14, "scene": 1, "line": "It's from 'Sob-ray', our new owner, and it is to Sabre, us. I wonder what's inside. Scissor me. [Erin tosses Michael open scissors, Pam is appalled]", "order": 32904, "speaker": "Michael Scott"},
  {"season": 6, "episode": 14, "scene": 2, "line": "Dunder Mifflin was recently bought by an electronics company named Sabre. [mispronounced 'sob-ray'] They stepped in at the 11th hour, and they saved our asses. Although David Wallace said that we were the one branch that was actually working right, so we probably could have saved our own asses. We didn't need them touching our asses.", "order": 32905, "speaker": "Michael Scott"},
  {"season": 6, "episode": 14, "scene": 3, "line": "Oh! [rips open cardboard box] Wow. Awesome. [distributing items] For accounting, a brand new printer. And for the sales staff, a new fax machine. This cord has Creed written all over it. And whoever would like a brand new scanner can come visit it in my office. Thank you.", "order": 32906, "speaker": "Michael Scott"},
  {"season": 6, "episode": 14, "scene": 4, "line": "Here we go. [scans stuffed animal] Oh! Wow.", "order": 32907, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 14, "scene": 4, "line": "Cool. What next? [Dwight puts his face on scanner]", "order": 32908, "speaker": "Michael Scott"},
  {"season": 6, "episode": 14, "scene": 4, "line": "Guys, um, who's Gabe? This note says to put the box aside for Gabe Lewis, who's coming tomorrow?", "order": 32909, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 14, "scene": 5, "line": "Okay, everybody, listen up. I have some bad news. Due to circumstances beyond my control -", "order": 32910, "speaker": "Michael Scott"},
  {"season": 6, "episode": 14, "scene": 5, "line": "Impulsivity and inattention to detail.", "order": 32911, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 14, "scene": 5, "line": "Hey, hey. I have opened a box, which should not have been opened...", "order": 32912, "speaker": "Michael Scott"},
  {"season": 6, "episode": 14, "scene": 5, "line": "Terrible mistake.", "order": 32913, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 14, "scene": 5, "line": "And distributed things which should not have been distributed.", "order": 32914, "speaker": "Michael Scott"},
  {"season": 6, "episode": 14, "scene": 5, "line": "Undistributable.", "order": 32915, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 14, "scene": 5, "line": "Well, maybe we can put the box back together.", "order": 32916, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 14, "scene": 5, "line": "Impossible. He opened it like an ape.", "order": 32917, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 14, "scene": 5, "line": "I think we can do it.", "order": 32918, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 14, "scene": 6, "line": "[Pam and Oscar are trying to repack cardboard box] Yep, yep.", "order": 32919, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 14, "scene": 6, "line": "Right? Mm. Did we try printer first? Shredder at an angle -", "order": 32920, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 14, "scene": 6, "line": "Yep.", "order": 32921, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 14, "scene": 6, "line": "Fax, cable, then the scanner upside down?", "order": 32922, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 14, "scene": 6, "line": "Yes.", "order": 32923, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 14, "scene": 6, "line": "Have you tried making everything smaller?", "order": 32924, "speaker": "Creed Bratton"},
  {"season": 6, "episode": 14, "scene": 7, "line": "And - [Jim seals box with tape, rips covered with stickers] Wow. Really nice job. This group of people, when they put their minds together, they can do something great. And I think that you should all be proud of yourselves. [cell phone starts ringing from inside box]", "order": 32925, "speaker": "Michael Scott"},
  {"season": 6, "episode": 14, "scene": 7, "line": "Oh, Michael.", "order": 32926, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 14, "scene": 7, "line": "Alright, relax. We can do it. We just did it. So we can do this again. Erin, scissor me please. [Erin tosses open scissors across Jim and Pam]", "order": 32927, "speaker": "Michael Scott"},
  {"season": 6, "episode": 14, "scene": 7, "line": "No, don't!", "order": 32928, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 14, "scene": 7, "line": "[fumbles] Thank you. Oh! Got it. Ok.", "order": 32929, "speaker": "Michael Scott"},
  {"season": 6, "episode": 14, "scene": 7, "line": "Alright, he's on his way up. Okay. Are we ready?", "order": 32930, "speaker": "Michael Scott"},
  {"season": 6, "episode": 14, "scene": 7, "line": "What do you want us to do?", "order": 32931, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 14, "scene": 7, "line": "Why do I have to explain everything?", "order": 32932, "speaker": "Michael Scott"},
  {"season": 6, "episode": 14, "scene": 7, "line": "Because we're usually not on the same page.", "order": 32933, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 14, "scene": 7, "line": "Okay. [starts round of applause]", "order": 32934, "speaker": "Michael Scott"},
  {"season": 6, "episode": 14, "scene": 7, "line": "Oh. Okay. And to you. [begins applauding]", "order": 32935, "speaker": "Gabe Lewis"},
  {"season": 6, "episode": 14, "scene": 8, "line": "Gabe seems tall. Hope we get along. Had a very good thing going with David Wallace. He was a good guy. He was somebody I could trust. Here he is. [holding up photo of him and Wallace] You can really see that he is ok taking a picture with me. Even though I was there for disciplinary reasons.", "order": 32936, "speaker": "Michael Scott"},
  {"season": 6, "episode": 14, "scene": 9, "line": "Welcome. Scranton hot dog from Scranton?", "order": 32937, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 14, "scene": 9, "line": "You know what, Dwight? Let's give him a minute to settle in, shall we? Ok?", "order": 32938, "speaker": "Michael Scott"},
  {"season": 6, "episode": 14, "scene": 9, "line": "Um, hi, uh, my name is Gabe Lewis. I am coordinating director for merging regions.", "order": 32939, "speaker": "Gabe Lewis"},
  {"season": 6, "episode": 14, "scene": 9, "line": "Hello, Gabe. I am Michael Scott, co-manager. This is my protege, Jim Halpert.", "order": 32940, "speaker": "Michael Scott"},
  {"season": 6, "episode": 14, "scene": 9, "line": "Co-manager, actually.", "order": 32941, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 14, "scene": 9, "line": "In training.", "order": 32942, "speaker": "Michael Scott"},
  {"season": 6, "episode": 14, "scene": 9, "line": "Trained. Loving it. Good at it. So...", "order": 32943, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 14, "scene": 9, "line": "We are very impressed with this branch and we are so excited about this merger.", "order": 32944, "speaker": "Gabe Lewis"},
  {"season": 6, "episode": 14, "scene": 9, "line": "We're very excited too, aren't we guys? Right?", "order": 32945, "speaker": "Michael Scott"},
  {"season": 6, "episode": 14, "scene": 9, "line": "So excited.", "order": 32946, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 14, "scene": 9, "line": "We have a little song prepared for you.", "order": 32947, "speaker": "Michael Scott"},
  {"season": 6, "episode": 14, "scene": 9, "line": "Um, actually, if you don't mind, I would love to put this on the Sabre website. [correctly pronounced 'say-ber']", "order": 32948, "speaker": "Gabe Lewis"},
  {"season": 6, "episode": 14, "scene": 9, "line": "Sabre. Say-ber. Sabre.", "order": 32949, "speaker": "Michael Scott"},
  {"season": 6, "episode": 14, "scene": 9, "line": "Oh. Of course.", "order": 32950, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 14, "scene": 9, "line": "Yes, say-ber. Good. Take it away guys.", "order": 32951, "speaker": "Michael Scott"},
  {"season": 6, "episode": 14, "scene": 9, "line": "Ok. [playing guitar to the tune of Miley Cyrus' Party in the USA]", "order": 32952, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 14, "scene": 9, "line": "[singing] Hopped off the train in Scranton, PA, another cloudy gray afternoon. Jumped in the cab, here you are for the first time, look to the right see the 'Electric City' sign. This is gonna be good day, for Dunder Mifflin and Sob-ray. Sab - [guitar stops]", "order": 32953, "speaker": "Andy Bernard & Erin Hannon"},
  {"season": 6, "episode": 14, "scene": 9, "line": "Saber.", "order": 32954, "speaker": "Michael Scott"},
  {"season": 6, "episode": 14, "scene": 9, "line": "Saber. [sings] Dunder Mifflin and Saber. So yeah, yeah, yay, yay.", "order": 32955, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 14, "scene": 9, "line": "Dunder Mifflin is a part of Sob-ray. So yeah, yeah, yay, yay. Dunder Mifflin is a part of Sabre. [music stops]", "order": 32956, "speaker": "Andy Bernard & Erin Hannon"},
  {"season": 6, "episode": 14, "scene": 9, "line": "You sure it's saber?", "order": 32957, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 14, "scene": 10, "line": "Hey.", "order": 32958, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 14, "scene": 10, "line": "Thank you. Hey, we should be fine if we leave around 11:20.", "order": 32959, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 14, "scene": 10, "line": "Oh, you mapquested it. It's four blocks away.", "order": 32960, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 14, "scene": 10, "line": "Well, now we won't get lost. Or we could drive. And that takes one minute.", "order": 32961, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 14, "scene": 11, "line": "We found a great local daycare. It's rated really high by all the local parenting websites. But that means it's also really hard to get into.", "order": 32962, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 14, "scene": 11, "line": "Turns out, a lot of parents want the very best for their children. That's weird.", "order": 32963, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 14, "scene": 11, "line": "We're hoping our interview seals the deal.", "order": 32964, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 14, "scene": 11, "line": "But if not, there's always the army. The infantry.", "order": 32965, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 14, "scene": 11, "line": "Okay. Okay. Yeah.", "order": 32966, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 14, "scene": 12, "line": "So you've just been bought by Sabre. You've probably got a lot of questions. Hi. I'm Christian Slater. What's it like to work for Sabre? Let's find out together. Working at Sabre means taking on the challenge of the road that rises to meet you. Sabre is respecting the past, but opening a window to the future. Have you ever tasted a rainbow? At Sabre, you will.", "order": 32967, "speaker": "Christian Slater"},
  {"season": 6, "episode": 14, "scene": 12, "line": "You'll find it easy to embrace the Sabre spirit. Welcome. We're very excited to go on this journey with you.", "order": 32968, "speaker": "Jo Bennett"},
  {"season": 6, "episode": 14, "scene": 13, "line": "[Christian Slater impression] So you've been shown a nonsensical video. You're probably wondering, 'What's going on?' Well, you're not alone.", "order": 32969, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 14, "scene": 14, "line": "Wow. Really terrific.", "order": 32970, "speaker": "Michael Scott"},
  {"season": 6, "episode": 14, "scene": 14, "line": "Most of our business comes from selling printers. So don't think of yourselves as paper salesmen anymore, but as printer salesmen who also sell paper.", "order": 32971, "speaker": "Gabe Lewis"},
  {"season": 6, "episode": 14, "scene": 14, "line": "Perfect.", "order": 32972, "speaker": "Michael Scott"},
  {"season": 6, "episode": 14, "scene": 15, "line": "No. Don't like that.", "order": 32973, "speaker": "Michael Scott"},
  {"season": 6, "episode": 14, "scene": 16, "line": "At Sabre, we really encourage honest communication. You should always feel free to express your thoughts, your -", "order": 32974, "speaker": "Gabe Lewis"},
  {"season": 6, "episode": 14, "scene": 16, "line": "Talk about vacation days!", "order": 32975, "speaker": "Meredith Palmer"},
  {"season": 6, "episode": 14, "scene": 16, "line": "We have a policy here at Sabre where we are, uh, allowed to take two weeks.", "order": 32976, "speaker": "Gabe Lewis"},
  {"season": 6, "episode": 14, "scene": 16, "line": "But I banked six weeks.", "order": 32977, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 14, "scene": 16, "line": "I already booked a Great Lakes cruise.", "order": 32978, "speaker": "Angela Martin"},
  {"season": 6, "episode": 14, "scene": 16, "line": "Hey, who the hell's going through our stuff?", "order": 32979, "speaker": "Meredith Palmer"},
  {"season": 6, "episode": 14, "scene": 16, "line": "Actually, that's Nick. He's your new IT guy. Uh, he's setting up a site blocker. Mostly blocking the inappropriate sites. And then there are the time-wasting sites like Twitter, YouTube. We are blocking those as well.", "order": 32980, "speaker": "Gabe Lewis"},
  {"season": 6, "episode": 14, "scene": 17, "line": "Mm, nope. Don't like that.", "order": 32981, "speaker": "Michael Scott"},
  {"season": 6, "episode": 14, "scene": 18, "line": "Ok, did everyone get one? With these bottles, we eliminate the need for plastic water bottles, which are the scourge of the environment.", "order": 32982, "speaker": "Gabe Lewis"},
  {"season": 6, "episode": 14, "scene": 18, "line": "We'll still get to use the little cups, though, right?", "order": 32983, "speaker": "Michael Scott"},
  {"season": 6, "episode": 14, "scene": 18, "line": "Little cups? Like paper or plastic or... ?", "order": 32984, "speaker": "Gabe Lewis"},
  {"season": 6, "episode": 14, "scene": 18, "line": "I don't know what they're made of.", "order": 32985, "speaker": "Michael Scott"},
  {"season": 6, "episode": 14, "scene": 18, "line": "They're 2 ounce paper cups dipped in plastic. He goes through 20 a day.", "order": 32986, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 14, "scene": 18, "line": "Ok, well, I bet you can fit 20 little cups of water in your aluminum bottle.", "order": 32987, "speaker": "Gabe Lewis"},
  {"season": 6, "episode": 14, "scene": 18, "line": "You know what can't fit in a bottle, are the 20 little trips I take to the cooler, and the 20 little scans I do of everybody to make sure everything's running smoothly. And the 20 little conversations that I have with Stanley.", "order": 32988, "speaker": "Michael Scott"},
  {"season": 6, "episode": 14, "scene": 18, "line": "That's ok.", "order": 32989, "speaker": "Stanley Hudson"},
  {"season": 6, "episode": 14, "scene": 19, "line": "There is a small part of me that is actually very excited about this new company. But 70 percent of me is water, and the other part, the real part, the part that has feelings and emotions and thoughts and makes decisions and, if I can be crass, makes babies, that part thinks that all of these changes suck ba -", "order": 32990, "speaker": "Michael Scott"},
  {"season": 6, "episode": 14, "scene": 20, "line": "[in line to fill up water bottle behind Kevin and Toby] Really? You don't have enough water in there? Your stomach?", "order": 32991, "speaker": "Michael Scott"},
  {"season": 6, "episode": 14, "scene": 20, "line": "Oop.", "order": 32992, "speaker": "Toby Flenderson"},
  {"season": 6, "episode": 14, "scene": 20, "line": "What?", "order": 32993, "speaker": "Michael Scott"},
  {"season": 6, "episode": 14, "scene": 20, "line": "Looks like we kicked it. [indicates water jug is empty]", "order": 32994, "speaker": "Toby Flenderson"},
  {"season": 6, "episode": 14, "scene": 21, "line": "I miss the old Dunder Mifflin. Too much change is not a good thing. Ask the climate.", "order": 32995, "speaker": "Michael Scott"},
  {"season": 6, "episode": 14, "scene": 22, "line": "I don't want to appear ungrateful for everything Sabre's doing for us.", "order": 32996, "speaker": "Michael Scott"},
  {"season": 6, "episode": 14, "scene": 22, "line": "Oh, I don't get that sense at all from you, so...", "order": 32997, "speaker": "Gabe Lewis"},
  {"season": 6, "episode": 14, "scene": 22, "line": "Well, you should have a little. But it's great that you don't, because I am trying to embrace all of these changes, but I'm - I'll be honest, I'm having trouble wrapping my head around a couple of 'em.", "order": 32998, "speaker": "Michael Scott"},
  {"season": 6, "episode": 14, "scene": 22, "line": "Which ones?", "order": 32999, "speaker": "Gabe Lewis"},
  {"season": 6, "episode": 14, "scene": 22, "line": "All of them.", "order": 33000, "speaker": "Michael Scott"},
  {"season": 6, "episode": 14, "scene": 22, "line": "Well, I wish that I could do something, but it's kind of policy, you know? Above my pay grade. You know?", "order": 33001, "speaker": "Gabe Lewis"},
  {"season": 6, "episode": 14, "scene": 22, "line": "So maybe we should call. We should call someone. I could help. I'm good with bosses. Call one of the higher-ups?", "order": 33002, "speaker": "Michael Scott"},
  {"season": 6, "episode": 14, "scene": 22, "line": "You know what? Let me see what I can do.", "order": 33003, "speaker": "Gabe Lewis"},
  {"season": 6, "episode": 14, "scene": 23, "line": "[after entering daycare] Ok, this is officially the cutest thing I've ever seen. Cubbies. I totally forgot about cubbies.", "order": 33004, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 14, "scene": 23, "line": "There's a finger-painting station and a curly slide. Am I too old to go here?", "order": 33005, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 14, "scene": 23, "line": "Oh. Adorable, right? [opens kiddie bathroom, sees man on toilet] I walked in on someone in the bathroom.", "order": 33006, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 14, "scene": 23, "line": "What?", "order": 33007, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 14, "scene": 23, "line": "I just walked in on someone in the bathroom.", "order": 33008, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 14, "scene": 23, "line": "Who? The -", "order": 33009, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 14, "scene": 23, "line": "I don't know. I don't know.", "order": 33010, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 14, "scene": 23, "line": "The guy we're meeting with?", "order": 33011, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 14, "scene": 23, "line": "I don't know, I don't know.", "order": 33012, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 14, "scene": 24, "line": "I am telling you, there are no strawberries in here.", "order": 33013, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 14, "scene": 24, "line": "Well, I saw them in there.", "order": 33014, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 14, "scene": 24, "line": "Well maybe they ran away, because the pizza was like, [imitating Italian accent] 'Hey, get out of here, you stupid strawberries.'", "order": 33015, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 14, "scene": 25, "line": "I think when Andy finally asks me out he's going to try to top what he did with the drum line. I can't wait to see what he comes up with.", "order": 33016, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 14, "scene": 26, "line": "The ball's totally in Erin's court. After the whole drum line thing.", "order": 33017, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 14, "scene": 27, "line": "[on videochat] First let me say that I told them everything you wanted me to say, in just the way you wanted me to say it, so...", "order": 33018, "speaker": "Gabe Lewis"},
  {"season": 6, "episode": 14, "scene": 27, "line": "Gabe, honey, I love this. I love the sound of your voice. But I really need some new information now.", "order": 33019, "speaker": "Jo Bennett"},
  {"season": 6, "episode": 14, "scene": 27, "line": "Ok, um, I told the story of your uncle, and I have a feeling that you would have [Michael gesturing behind computer] really been proud of the way... Okay, um...", "order": 33020, "speaker": "Gabe Lewis"},
  {"season": 6, "episode": 14, "scene": 27, "line": "Wait a minute, who is it? Who's there? Is there somebody in the room with you? [Michael gesturing 'no'] I want to see who it is. Turn me around.", "order": 33021, "speaker": "Jo Bennett"},
  {"season": 6, "episode": 14, "scene": 27, "line": "I don't - it's just...", "order": 33022, "speaker": "Gabe Lewis"},
  {"season": 6, "episode": 14, "scene": 27, "line": "Turn me around. Now. [Michael starts to leave, Jo whistles] Hey, Buddy. Is it something I said?", "order": 33023, "speaker": "Jo Bennett"},
  {"season": 6, "episode": 14, "scene": 27, "line": "Hello. No. My name's Michael Scott. Hello.", "order": 33024, "speaker": "Michael Scott"},
  {"season": 6, "episode": 14, "scene": 27, "line": "Oh, hello, Mr. Scott. Pleased to meet you.", "order": 33025, "speaker": "Jo Bennett"},
  {"season": 6, "episode": 14, "scene": 27, "line": "Nice to meet you. We are very excited about the merger with Sabre. I think you have a great accent.", "order": 33026, "speaker": "Michael Scott"},
  {"season": 6, "episode": 14, "scene": 27, "line": "Aw, thank you. I've been working on it since I was a little girl. To, um, to what do I owe the pleasure of this videochat?", "order": 33027, "speaker": "Jo Bennett"},
  {"season": 6, "episode": 14, "scene": 27, "line": "Well, I am little concerned with all of these changes, to be quite frank. I think we have done things a certain way here at Dunder Mifflin for quite some time, and -", "order": 33028, "speaker": "Michael Scott"},
  {"season": 6, "episode": 14, "scene": 27, "line": "Pardon. Pardon me, Mr. Scott, but the last time I saw a company as mismanaged as Dunder Mifflin, it was my grandson's snowball company, so you'll excuse me if I prefer that you all adapt to the way that we do things.", "order": 33029, "speaker": "Jo Bennett"},
  {"season": 6, "episode": 14, "scene": 27, "line": "Ok. I feel very strongly that you can't just come in here and change everything that people have been doing forever. Is Christian Slater back there? Because... he knows. He'd know what to do.", "order": 33030, "speaker": "Michael Scott"},
  {"season": 6, "episode": 14, "scene": 27, "line": "So listen, why don't you tell Gabe if by the end of the day you can't handle the changes.", "order": 33031, "speaker": "Jo Bennett"},
  {"season": 6, "episode": 14, "scene": 27, "line": "I have already told him, but I will tell him again if you think that will help.", "order": 33032, "speaker": "Michael Scott"},
  {"season": 6, "episode": 14, "scene": 27, "line": "I would take until the end of the day if I were you. [Michael leaves conference room]", "order": 33033, "speaker": "Jo Bennett"},
  {"season": 6, "episode": 14, "scene": 29, "line": "Pam and Jim? Hey, I'm Jerry.", "order": 33034, "speaker": "Jerry"},
  {"season": 6, "episode": 14, "scene": 29, "line": "Hi Jerry.", "order": 33035, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 14, "scene": 29, "line": "Jerry.", "order": 33036, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 14, "scene": 29, "line": "Good to see you. Come on in. My office is right back here. This is the play room.", "order": 33037, "speaker": "Jerry"},
  {"season": 6, "episode": 14, "scene": 29, "line": "This is great. Got to confess, we came a little early, so we got a quick look around, but, uh -", "order": 33038, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 14, "scene": 29, "line": "Oh, so you saw it already?", "order": 33039, "speaker": "Jerry"},
  {"season": 6, "episode": 14, "scene": 29, "line": "No, no, no, no. Yes. We saw this, yes. No, it wasn't like a look around. We really just had a peek. Quick peek. Didn't focus on anything in particular.", "order": 33040, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 14, "scene": 29, "line": "It's lovely.", "order": 33041, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 14, "scene": 29, "line": "[cell phone rings] Oh. Got to take this. Sorry.", "order": 33042, "speaker": "Jerry"},
  {"season": 6, "episode": 14, "scene": 29, "line": "Do it.", "order": 33043, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 14, "scene": 29, "line": "Okay. Hi, this is Jerry.", "order": 33044, "speaker": "Jerry"},
  {"season": 6, "episode": 14, "scene": 29, "line": "Ok, you've got to pull it together.", "order": 33045, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 14, "scene": 29, "line": "We should just go. He's really uncomfortable.", "order": 33046, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 14, "scene": 29, "line": "He's uncomfortable because you're acting weird. So you have to stop acting weird.", "order": 33047, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 14, "scene": 29, "line": "What if we just bring it up? We just put it out in the open?", "order": 33048, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 14, "scene": 29, "line": "That's the weirdest possible solution to the problem.", "order": 33049, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 14, "scene": 29, "line": "Sorry about that. Right this way.", "order": 33050, "speaker": "Jerry"},
  {"season": 6, "episode": 14, "scene": 29, "line": "All right.", "order": 33051, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 14, "scene": 30, "line": "I have come here today to talk to Mr. David Wallace about this whole thing going on with Sabre. [rings doorbell] He will know exactly what to do. He is not a big fan of me dropping by announced. But then again, who is? [loud drumming as door opens] Hey. Hi, sorry.", "order": 33052, "speaker": "Michael Scott"},
  {"season": 6, "episode": 14, "scene": 30, "line": "Michael.", "order": 33053, "speaker": "David Wallace"},
  {"season": 6, "episode": 14, "scene": 30, "line": "I hope you're not busy doing something.", "order": 33054, "speaker": "Michael Scott"},
  {"season": 6, "episode": 14, "scene": 30, "line": "No, you kidding? Come on in. Come on.", "order": 33055, "speaker": "David Wallace"},
  {"season": 6, "episode": 14, "scene": 30, "line": "You sure?", "order": 33056, "speaker": "Michael Scott"},
  {"season": 6, "episode": 14, "scene": 30, "line": "You look great.", "order": 33057, "speaker": "David Wallace"},
  {"season": 6, "episode": 14, "scene": 30, "line": "You look great too.", "order": 33058, "speaker": "Michael Scott"},
  {"season": 6, "episode": 14, "scene": 30, "line": "You hungry? I'm just having lunch.", "order": 33059, "speaker": "David Wallace"},
  {"season": 6, "episode": 14, "scene": 30, "line": "Oh, yeah, that sounds good.", "order": 33060, "speaker": "Michael Scott"},
  {"season": 6, "episode": 14, "scene": 30, "line": "Fantastic. Teddy, look who just stopped in.", "order": 33061, "speaker": "David Wallace"},
  {"season": 6, "episode": 14, "scene": 30, "line": "Huh? Hey. [continues drumming]", "order": 33062, "speaker": "Teddy Wallace"},
  {"season": 6, "episode": 14, "scene": 30, "line": "Hey. Wow.", "order": 33063, "speaker": "Michael Scott"},
  {"season": 6, "episode": 14, "scene": 30, "line": "Keep it up, son.", "order": 33064, "speaker": "David Wallace"},
  {"season": 6, "episode": 14, "scene": 30, "line": "Really loud.", "order": 33065, "speaker": "Michael Scott"},
  {"season": 6, "episode": 14, "scene": 31, "line": "Wow. Oh, yummy. [David spreads marshmallow fluff on bread] You have got the life, my friend.", "order": 33066, "speaker": "Michael Scott"},
  {"season": 6, "episode": 14, "scene": 31, "line": "Yeah.", "order": 33067, "speaker": "David Wallace"},
  {"season": 6, "episode": 14, "scene": 31, "line": "Can I just stay here and never go back?", "order": 33068, "speaker": "Michael Scott"},
  {"season": 6, "episode": 14, "scene": 31, "line": "Things not going so well there?", "order": 33069, "speaker": "David Wallace"},
  {"season": 6, "episode": 14, "scene": 31, "line": "Oh, man. How much time do you have?", "order": 33070, "speaker": "Michael Scott"},
  {"season": 6, "episode": 14, "scene": 31, "line": "I have a doctor's appointment next Thursday.", "order": 33071, "speaker": "David Wallace"},
  {"season": 6, "episode": 14, "scene": 31, "line": "Yeah.", "order": 33072, "speaker": "Michael Scott"},
  {"season": 6, "episode": 14, "scene": 31, "line": "Hey, honey. How's your day going? Did you do anything cool?", "order": 33073, "speaker": "Mrs. Wallace"},
  {"season": 6, "episode": 14, "scene": 31, "line": "Uh, no, sweetie. I just sort of hung out.", "order": 33074, "speaker": "David Wallace"},
  {"season": 6, "episode": 14, "scene": 31, "line": "Oh, 'cause I saw you had shoes on, so I didn't know if you did anything.", "order": 33075, "speaker": "Mrs. Wallace"},
  {"season": 6, "episode": 14, "scene": 31, "line": "No. Just hanging out, honey. You remember Michael.", "order": 33076, "speaker": "David Wallace"},
  {"season": 6, "episode": 14, "scene": 31, "line": "Hi. Sorry, I was just in the neighborhood, thought I'd come by, see...", "order": 33077, "speaker": "Michael Scott"},
  {"season": 6, "episode": 14, "scene": 31, "line": "No problem at all. This is great. So what are you guys up to?", "order": 33078, "speaker": "Mrs. Wallace"},
  {"season": 6, "episode": 14, "scene": 31, "line": "I don't know.", "order": 33079, "speaker": "Michael Scott"},
  {"season": 6, "episode": 14, "scene": 31, "line": "Uh, but maybe, uh - actually, you know, I think maybe we'll go outside. May -maybe we'll go outside. [drumming intensifies] Outside.", "order": 33080, "speaker": "David Wallace"},
  {"season": 6, "episode": 14, "scene": 32, "line": "There was one thing we were curious about, uh, your flexibility on things like Easter or Memorial Day, because we might want to change our days around a little bit.", "order": 33081, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 14, "scene": 32, "line": "That seems a bit premature, don't you think? I don't even know if I have a space for you yet, and you're already lining up your holiday plans.", "order": 33082, "speaker": "Jerry"},
  {"season": 6, "episode": 14, "scene": 32, "line": "Oh, no, sorry. Just, um, we're kind of planners. But we're also flexible too, so you know what? Maybe we can just discuss it when the time comes.", "order": 33083, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 14, "scene": 32, "line": "Yeah, if the time comes, we can discuss it.", "order": 33084, "speaker": "Jerry"},
  {"season": 6, "episode": 14, "scene": 32, "line": "Is this because Jim walked in on you going to the bathroom?", "order": 33085, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 14, "scene": 32, "line": "What?", "order": 33086, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 14, "scene": 32, "line": "Seriously? You told her?", "order": 33087, "speaker": "Jerry"},
  {"season": 6, "episode": 14, "scene": 32, "line": "Did it? It might have come up while we were waiting for you.", "order": 33088, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 14, "scene": 32, "line": "And you - you thought that might have something to do with how the meeting is going?", "order": 33089, "speaker": "Jerry"},
  {"season": 6, "episode": 14, "scene": 32, "line": "No.", "order": 33090, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 14, "scene": 32, "line": "Maybe, because it doesn't seem to be going super well.", "order": 33091, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 14, "scene": 32, "line": "Well, you didn't consider the fact that it might not be going super well just because it might not be going super well?", "order": 33092, "speaker": "Jerry"},
  {"season": 6, "episode": 14, "scene": 32, "line": "Nope. 'Cause we're really nice people but you don't seem to like us.", "order": 33093, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 14, "scene": 32, "line": "I'm being perfectly pleasant. Did you ever consider that you might not be as charming as you think you are?", "order": 33094, "speaker": "Jerry"},
  {"season": 6, "episode": 14, "scene": 32, "line": "Oh, this coming from the guy who still uses a children's toilet? Why didn't you just lock the door, man?", "order": 33095, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 14, "scene": 32, "line": "It doesn't lock for the children's safety.", "order": 33096, "speaker": "Jerry"},
  {"season": 6, "episode": 14, "scene": 32, "line": "Anybody could have walked in.", "order": 33097, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 14, "scene": 32, "line": "It was story time.", "order": 33098, "speaker": "Jerry"},
  {"season": 6, "episode": 14, "scene": 33, "line": "Sabre is changing everything. [Michael and David sitting in hot tub] Site blockers. They don't let us use cups. So I started thinking, who could handle this? Who would know what to do? David Wallace would know what to do. What should I do, David Wallace?", "order": 33099, "speaker": "Michael Scott"},
  {"season": 6, "episode": 14, "scene": 33, "line": "I don't know. I mean, what can you do?", "order": 33100, "speaker": "David Wallace"},
  {"season": 6, "episode": 14, "scene": 33, "line": "Exactly. What are you thinking?", "order": 33101, "speaker": "Michael Scott"},
  {"season": 6, "episode": 14, "scene": 33, "line": "I, I - It's a tough one.", "order": 33102, "speaker": "David Wallace"},
  {"season": 6, "episode": 14, "scene": 33, "line": "It is a tough one.", "order": 33103, "speaker": "Michael Scott"},
  {"season": 6, "episode": 14, "scene": 33, "line": "I don't have any ideas for you there.", "order": 33104, "speaker": "David Wallace"},
  {"season": 6, "episode": 14, "scene": 33, "line": "Yeah. But one would be good.", "order": 33105, "speaker": "Michael Scott"},
  {"season": 6, "episode": 14, "scene": 33, "line": "Hey, oh, okay. Wait.", "order": 33106, "speaker": "David Wallace"},
  {"season": 6, "episode": 14, "scene": 33, "line": "Okay.", "order": 33107, "speaker": "Michael Scott"},
  {"season": 6, "episode": 14, "scene": 33, "line": "I want to tell you about a business idea I have.", "order": 33108, "speaker": "David Wallace"},
  {"season": 6, "episode": 14, "scene": 33, "line": "Okay.", "order": 33109, "speaker": "Michael Scott"},
  {"season": 6, "episode": 14, "scene": 33, "line": "Ok? You know how kids leave their toys everywhere? Okay. So this is a vacuum. It's like a shop-vac type of thing. [imitates vacuum] Teaches kids how to pick up their own toys. Right? Baseball gloves, hacky sacks, drumsticks. [sucking noise] Picks it up!", "order": 33110, "speaker": "David Wallace"},
  {"season": 6, "episode": 14, "scene": 33, "line": "So the kid's making the noise to pick it up.", "order": 33111, "speaker": "Michael Scott"},
  {"season": 6, "episode": 14, "scene": 33, "line": "No. No, no, no. That's the -that's the vacuum noise.", "order": 33112, "speaker": "David Wallace"},
  {"season": 6, "episode": 14, "scene": 33, "line": "Oh, okay.", "order": 33113, "speaker": "Michael Scott"},
  {"season": 6, "episode": 14, "scene": 33, "line": "You-you don't like it.", "order": 33114, "speaker": "David Wallace"},
  {"season": 6, "episode": 14, "scene": 33, "line": "No, I think it is-", "order": 33115, "speaker": "Michael Scott"},
  {"season": 6, "episode": 14, "scene": 33, "line": "No, it's ok. Another guy from Dunder Mifflin, Arnie from Research, he's gonna draw up the prototype. And, uh... It's like shop-vac. [sucking noise] Did I say that?", "order": 33116, "speaker": "David Wallace"},
  {"season": 6, "episode": 14, "scene": 33, "line": "Yep.", "order": 33117, "speaker": "Michael Scott"},
  {"season": 6, "episode": 14, "scene": 33, "line": "It'll be ok.", "order": 33118, "speaker": "David Wallace"},
  {"season": 6, "episode": 14, "scene": 34, "line": "Hey, any fun weekend plans?", "order": 33119, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 14, "scene": 34, "line": "No, you?", "order": 33120, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 14, "scene": 34, "line": "Uh, no, actually. So nothing? No movies, or parties, or anything you might want to invite someone to?", "order": 33121, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 14, "scene": 34, "line": "Nothing. It's wide open.", "order": 33122, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 14, "scene": 34, "line": "Okay.", "order": 33123, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 14, "scene": 35, "line": "That's as hard as I can hint.", "order": 33124, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 14, "scene": 36, "line": "Hey, you know, if you want, I'm sure I can get Arnie on board if you wanted to join us for suck it. Work together again.", "order": 33125, "speaker": "David Wallace"},
  {"season": 6, "episode": 14, "scene": 36, "line": "The what?", "order": 33126, "speaker": "Michael Scott"},
  {"season": 6, "episode": 14, "scene": 36, "line": "Suck it. That's what it's called.", "order": 33127, "speaker": "David Wallace"},
  {"season": 6, "episode": 14, "scene": 36, "line": "Oh, okay.", "order": 33128, "speaker": "Michael Scott"},
  {"season": 6, "episode": 14, "scene": 36, "line": "Huh? Catchy. Kids'll love it.", "order": 33129, "speaker": "David Wallace"},
  {"season": 6, "episode": 14, "scene": 36, "line": "Like it.", "order": 33130, "speaker": "Michael Scott"},
  {"season": 6, "episode": 14, "scene": 37, "line": "There are very few things that would make me not want to team up with David Wallace. And Suck It is one of 'em.", "order": 33131, "speaker": "Michael Scott"},
  {"season": 6, "episode": 14, "scene": 38, "line": "See you later. [hugs Wallace]", "order": 33132, "speaker": "Michael Scott"},
  {"season": 6, "episode": 14, "scene": 38, "line": "Oh, Michael.", "order": 33133, "speaker": "David Wallace"},
  {"season": 6, "episode": 14, "scene": 38, "line": "Bye now.", "order": 33134, "speaker": "Michael Scott"},
  {"season": 6, "episode": 14, "scene": 38, "line": "Thank you for coming.", "order": 33135, "speaker": "David Wallace"},
  {"season": 6, "episode": 14, "scene": 38, "line": "Thanks for having me.", "order": 33136, "speaker": "Michael Scott"},
  {"season": 6, "episode": 14, "scene": 39, "line": "Well, that's not the David Wallace that I remember. [reverses out of driveway, Wallace follows him] That is some sort of weird creature that lives in David Wallace's house. Oh, my god. Alright. Just get me out of here.", "order": 33137, "speaker": "Michael Scott"},
  {"season": 6, "episode": 14, "scene": 40, "line": "Hello. [comes in carrying 4 gallons of orange juice and his water bottle] Is anybody home? Oh, thank you, Erin. Now if everyone would please ready their canteens, so I can fill them with the sweet, sweet nectar of Gabe's homeland, and then propose a toast. Here we go.", "order": 33138, "speaker": "Michael Scott"},
  {"season": 6, "episode": 14, "scene": 41, "line": "You know when people say getting fired was the best thing that ever happened to them? I feel sorry for those people. That's the best thing? Really? Ugh.", "order": 33139, "speaker": "Michael Scott"},
  {"season": 6, "episode": 14, "scene": 42, "line": "Well, I'm not really one for making speeches.", "order": 33140, "speaker": "Michael Scott"},
  {"season": 6, "episode": 14, "scene": 42, "line": "Yes. Yes, you are. You make a lot.", "order": 33141, "speaker": "Everyone"},
  {"season": 6, "episode": 14, "scene": 42, "line": "But I feel pretty good right now. I really do. A lot better than I did earlier. Let me tell you.", "order": 33142, "speaker": "Michael Scott"},
  {"season": 6, "episode": 14, "scene": 42, "line": "That's your toast?", "order": 33143, "speaker": "Angela Martin"},
  {"season": 6, "episode": 14, "scene": 42, "line": "No. This is my toast. I think this whole thing with Sabre is going to work out. I have a very good feeling about it.", "order": 33144, "speaker": "Michael Scott"},
  {"season": 6, "episode": 14, "scene": 42, "line": "Michael, this isn't a toast. You're just thinking out loud.", "order": 33145, "speaker": "Phyllis Vance"},
  {"season": 6, "episode": 14, "scene": 42, "line": "Here's my toast. Orange juice is in here. And, like Saber, it is from Florida, and it is good.", "order": 33146, "speaker": "Michael Scott"},
  {"season": 6, "episode": 14, "scene": 42, "line": "Just 'cause you have liquid that doesn't make it a toast.", "order": 33147, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 14, "scene": 42, "line": "Here's the toast. I'm gonna do it now. Raise your container to us and to Sabre.", "order": 33148, "speaker": "Michael Scott"},
  {"season": 6, "episode": 14, "scene": 42, "line": "To us and to Sabre.", "order": 33149, "speaker": "All"},
  {"season": 6, "episode": 14, "scene": 42, "line": "Mm. Wow. That is metallicy. Ugh. That's like drinking a battery. Ah, really gets you in the fillings, doesn't it? Okay, anyway, welcome.", "order": 33150, "speaker": "Michael Scott"},
  {"season": 6, "episode": 14, "scene": 43, "line": "[singing] Well, it's a mess, what a mess. What you gonna do? You're going to take out your Suck It and you suck it. Suck it. Yeah, take out your Suck It and you suck it.", "order": 33151, "speaker": "David Wallace"},
  {"season": 6, "episode": 14, "scene": 43, "line": "Suck it!", "order": 33152, "speaker": "Teddy"},
  {"season": 6, "episode": 14, "scene": 43, "line": "Yeah!", "order": 33153, "speaker": "David Wallace"},
  {"season": 6, "episode": 14, "scene": 43, "line": "Suck it!", "order": 33154, "speaker": "Teddy"},
  {"season": 6, "episode": 14, "scene": 43, "line": "Yeah!", "order": 33155, "speaker": "David Wallace"},
  {"season": 6, "episode": 14, "scene": 43, "line": "Suck it!", "order": 33156, "speaker": "Teddy"},
  {"season": 6, "episode": 14, "scene": 43, "line": "Yeah! Take out the Suck It and we'll...", "order": 33157, "speaker": "David Wallace"},
  {"season": 6, "episode": 14, "scene": 43, "line": "Suck it!", "order": 33158, "speaker": "Teddy"},
  {"season": 6, "episode": 14, "scene": 43, "line": "Yeah!", "order": 33159, "speaker": "David Wallace"},
  {"season": 6, "episode": 14, "scene": 43, "line": "Suck it!", "order": 33160, "speaker": "Teddy"},
  {"season": 6, "episode": 14, "scene": 43, "line": "Yeah!", "order": 33161, "speaker": "David Wallace"},
  {"season": 6, "episode": 14, "scene": 43, "line": "Take out my Suck It and we'll suck it.", "order": 33162, "speaker": "Both"},
  {"season": 6, "episode": 14, "scene": 43, "line": "Yeah! Teddy!", "order": 33163, "speaker": "David Wallace"},
  {"season": 6, "episode": 14, "scene": 43, "line": "Yeah. [high-five each other]", "order": 33164, "speaker": "Teddy"},
  {"season": 6, "episode": 15, "scene": 1, "line": "[on phone] Vancouver Court Hotel, how many I help you?", "order": 33165, "speaker": "Hotel Employee"},
  {"season": 6, "episode": 15, "scene": 1, "line": "Hello Vancouver, this is Michael Scott calling from the United States of America! I have a reservation in your fair city from February 12th to the 19th, first week of the Olympics.", "order": 33166, "speaker": "Michael Scott"},
  {"season": 6, "episode": 15, "scene": 1, "line": "Well, we are looking forward to having you, so, let me just pull up your information here...", "order": 33167, "speaker": "Hotel Employee"},
  {"season": 6, "episode": 15, "scene": 1, "line": "Okey doke.", "order": 33168, "speaker": "Michael Scott"},
  {"season": 6, "episode": 15, "scene": 1, "line": "Um, I don't seem to have it, sir.", "order": 33169, "speaker": "Hotel Employee"},
  {"season": 6, "episode": 15, "scene": 1, "line": "What?", "order": 33170, "speaker": "Michael Scott"},
  {"season": 6, "episode": 15, "scene": 1, "line": "[looking through Michael's email] I'm not seeing anything under 'confirmation.'", "order": 33171, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 15, "scene": 1, "line": "No.", "order": 33172, "speaker": "Michael Scott"},
  {"season": 6, "episode": 15, "scene": 1, "line": "Could it be under 'affirmation?' Cause you have thousands of those.", "order": 33173, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 15, "scene": 1, "line": "No. [to hotel employee on phone] We're trying to look it up right now.", "order": 33174, "speaker": "Michael Scott"},
  {"season": 6, "episode": 15, "scene": 1, "line": "You [i]did[/i] get the e-vite to my barbeque!", "order": 33175, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 15, "scene": 1, "line": "Um, come on.", "order": 33176, "speaker": "Michael Scott"},
  {"season": 6, "episode": 15, "scene": 1, "line": "Michael...", "order": 33177, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 15, "scene": 1, "line": "Uh, sir? A lot of people are trying to get last-minute reservations at hotels here for the Olympics, but we've been fully booked for months.", "order": 33178, "speaker": "Hotel Employee"},
  {"season": 6, "episode": 15, "scene": 1, "line": "Well, I have been booked for three years, so you should have that. I've always heard that British Columbia is a very nice place, but I am not getting that from this conversation.", "order": 33179, "speaker": "Michael Scott"},
  {"season": 6, "episode": 15, "scene": 1, "line": "Wait, I got it! Confirmation code: XV2RDM!", "order": 33180, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 15, "scene": 1, "line": "...Oh, here it is. Yep.", "order": 33181, "speaker": "Hotel Employee"},
  {"season": 6, "episode": 15, "scene": 1, "line": "Oh.", "order": 33182, "speaker": "Michael Scott"},
  {"season": 6, "episode": 15, "scene": 1, "line": "Thank you.", "order": 33183, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 15, "scene": 1, "line": "Uh-huh. Seven nights in our street-view economy single?", "order": 33184, "speaker": "Hotel Employee"},
  {"season": 6, "episode": 15, "scene": 1, "line": "That's it!", "order": 33185, "speaker": "Michael Scott"},
  {"season": 6, "episode": 15, "scene": 1, "line": "Yeah, we changed it since it was a while back. I do apologize.", "order": 33186, "speaker": "Hotel Employee"},
  {"season": 6, "episode": 15, "scene": 1, "line": "Uh-huh, okay...", "order": 33187, "speaker": "Michael Scott & Dwight Schrute"},
  {"season": 6, "episode": 15, "scene": 1, "line": "Yeah, how may I help you Mr. Scott?", "order": 33188, "speaker": "Hotel Employee"},
  {"season": 6, "episode": 15, "scene": 1, "line": "Alright, I would like to cancel my reservation, please.", "order": 33189, "speaker": "Michael Scott"},
  {"season": 6, "episode": 15, "scene": 1, "line": "There is a cancellation fee for this, sir.", "order": 33190, "speaker": "Hotel Employee"},
  {"season": 6, "episode": 15, "scene": 1, "line": "Okay, put it on my card.", "order": 33191, "speaker": "Michael Scott"},
  {"season": 6, "episode": 15, "scene": 2, "line": "Today, Jo Bennett, the CEO from Sabre, is coming to see us here for the first time. She bought us sight unseen, like a mail-order bride, so she's gotta be kinda nervous. I mean, are we ugly? Are we smart? Are we cool? Are we too cool? Do we speak English?", "order": 33192, "speaker": "Michael Scott"},
  {"season": 6, "episode": 15, "scene": 3, "line": "[Jo Bennett enters with two humongous dogs, everyone stands to welcome her except Dwight] Why hello!", "order": 33193, "speaker": "Michael Scott"},
  {"season": 6, "episode": 15, "scene": 3, "line": "This is Michael Scott, co-regional manager of this branch.", "order": 33194, "speaker": "Gabe Lewis"},
  {"season": 6, "episode": 15, "scene": 3, "line": "Pleasure to meet you, Miss Bennett.", "order": 33195, "speaker": "Michael Scott"},
  {"season": 6, "episode": 15, "scene": 3, "line": "Oh, that's Mrs. Bennett, sweetheart. My husband and I are divorced, but I kept the 'Mrs.' just to piss off the new wife. Well, let's take a gander around this place.", "order": 33196, "speaker": "Jo Bennett"},
  {"season": 6, "episode": 15, "scene": 3, "line": "[Jo's dogs are sniffing at his crotch] These sure are pretty dogs...", "order": 33197, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 15, "scene": 3, "line": "They love a good crotch.", "order": 33198, "speaker": "Jo Bennett"},
  {"season": 6, "episode": 15, "scene": 3, "line": "They sure do.", "order": 33199, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 15, "scene": 3, "line": "You should take that as a compliment!", "order": 33200, "speaker": "Jo Bennett"},
  {"season": 6, "episode": 15, "scene": 3, "line": "Oh, I do!", "order": 33201, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 15, "scene": 3, "line": "[to Dwight] Hello.", "order": 33202, "speaker": "Jo Bennett"},
  {"season": 6, "episode": 15, "scene": 3, "line": "Hello.", "order": 33203, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 15, "scene": 3, "line": "Do you always stay seated when a lady enters the room?", "order": 33204, "speaker": "Jo Bennett"},
  {"season": 6, "episode": 15, "scene": 3, "line": "I am treating you the same as a man, for whom I would also not stand. Unless it was the President. Or Judge Judy.", "order": 33205, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 15, "scene": 3, "line": "I like that.", "order": 33206, "speaker": "Jo Bennett"},
  {"season": 6, "episode": 15, "scene": 3, "line": "This is Accounting.", "order": 33207, "speaker": "Gabe Lewis"},
  {"season": 6, "episode": 15, "scene": 3, "line": "Hi!", "order": 33208, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 15, "scene": 3, "line": "Good-looking group.", "order": 33209, "speaker": "Jo Bennett"},
  {"season": 6, "episode": 15, "scene": 3, "line": "Over here is Meredith Palmer and Creed Bratton.", "order": 33210, "speaker": "Gabe Lewis"},
  {"season": 6, "episode": 15, "scene": 3, "line": "Another couple of heartbreakers. Who is this tall drink of sun tea?", "order": 33211, "speaker": "Jo Bennett"},
  {"season": 6, "episode": 15, "scene": 3, "line": "That is Jim Halpert, he is the co-regional manager of this office.", "order": 33212, "speaker": "Gabe Lewis"},
  {"season": 6, "episode": 15, "scene": 3, "line": "[gesturing to Michael] I thought this guy was the manager?", "order": 33213, "speaker": "Jo Bennett"},
  {"season": 6, "episode": 15, "scene": 3, "line": "Oh, he is. He's the co-manager, and that's the other co-manager.", "order": 33214, "speaker": "Gabe Lewis"},
  {"season": 6, "episode": 15, "scene": 3, "line": "Two guys doing one job? We gotta do something about that!", "order": 33215, "speaker": "Jo Bennett"},
  {"season": 6, "episode": 15, "scene": 4, "line": "Got some Valentine's cards for my coworkers and my bro-workers.", "order": 33216, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 15, "scene": 4, "line": "[as Andy is passing her a card] No, no!", "order": 33217, "speaker": "Angela Martin"},
  {"season": 6, "episode": 15, "scene": 4, "line": "What, no candy?", "order": 33218, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 15, "scene": 5, "line": "Valentine's Day, right around the corner. Erin and I have been on zero dates. So I got her a Valentine's Day card, but I didn't wanna seem 'too eager,' so I got cards for everyone in the whole office to kind of dilute it a little bit.", "order": 33219, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 15, "scene": 6, "line": "[throwing a card at Meredith, which skims her throat]: Look alive.", "order": 33220, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 15, "scene": 6, "line": "Ow! Geez, you gave me a paper cut on my throat!", "order": 33221, "speaker": "Meredith Palmer"},
  {"season": 6, "episode": 15, "scene": 7, "line": "Yeah, I have this thing about men cutting or threatening to cut my throat. Don't try to cut my throat.", "order": 33222, "speaker": "Meredith Palmer"},
  {"season": 6, "episode": 15, "scene": 8, "line": "Jo, I don't know how things work in Florida, which from your description sounds like a colorful, lawless swamp, but here it takes two men to do one job, where in Florida it might take one very strong woman to do such a job.", "order": 33223, "speaker": "Michael Scott"},
  {"season": 6, "episode": 15, "scene": 8, "line": "Whoa, whoa.", "order": 33224, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 15, "scene": 8, "line": "Whoa, whoa!", "order": 33225, "speaker": "Michael Scott"},
  {"season": 6, "episode": 15, "scene": 8, "line": "I'll take over. Um, what we're doing here: Michael handles more of the 'big-picture' stuff, and I handle more of the day-to-day stuff, so together...", "order": 33226, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 15, "scene": 8, "line": "Yeah. I think I understand.", "order": 33227, "speaker": "Jo Bennett"},
  {"season": 6, "episode": 15, "scene": 8, "line": "Alright.", "order": 33228, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 15, "scene": 8, "line": "Each of you is doing half a job.", "order": 33229, "speaker": "Jo Bennett"},
  {"season": 6, "episode": 15, "scene": 8, "line": "No.", "order": 33230, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 15, "scene": 8, "line": "And sometimes I can hardly handle that!", "order": 33231, "speaker": "Michael Scott"},
  {"season": 6, "episode": 15, "scene": 8, "line": "This is knucklehead talk. I'm not gonna bite it, you know. You can't give me gravy and tell me it's jelly, because gravy ain't sweet! Is it, Jim?", "order": 33232, "speaker": "Jo Bennett"},
  {"season": 6, "episode": 15, "scene": 8, "line": "I don't think so.", "order": 33233, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 15, "scene": 8, "line": "Michael?", "order": 33234, "speaker": "Jo Bennett"},
  {"season": 6, "episode": 15, "scene": 8, "line": "Forget the question...", "order": 33235, "speaker": "Michael Scott"},
  {"season": 6, "episode": 15, "scene": 8, "line": "I think one of you should return to sales, and the other one be manager.", "order": 33236, "speaker": "Jo Bennett"},
  {"season": 6, "episode": 15, "scene": 8, "line": "I humbly accept the management position.", "order": 33237, "speaker": "Michael Scott"},
  {"season": 6, "episode": 15, "scene": 8, "line": "I... Why would you just say something like that?", "order": 33238, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 15, "scene": 8, "line": "Because, well, Jim. Where I'm from, two types of folk: those who ain't, and those who are knee-high on a grasshopper. Which type ain't you ain't? Ya'll come back now.", "order": 33239, "speaker": "Michael Scott"},
  {"season": 6, "episode": 15, "scene": 8, "line": "Do you even know what that means?", "order": 33240, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 15, "scene": 8, "line": "Yes.", "order": 33241, "speaker": "Michael Scott"},
  {"season": 6, "episode": 15, "scene": 8, "line": "Well, I'm gonna let this marinate. So you can go.", "order": 33242, "speaker": "Jo Bennett"},
  {"season": 6, "episode": 15, "scene": 8, "line": "Um...", "order": 33243, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 15, "scene": 8, "line": "You can go on. Shoo!", "order": 33244, "speaker": "Jo Bennett"},
  {"season": 6, "episode": 15, "scene": 9, "line": "I'm Jolene Bennett, Jo for short. I'm a breast cancer survivor, close, personal friends with Nancy Pelosi, and Truman Capote and I slept with three of the same guys. When I was a little girl, I was terrified to fly, and now I have my own pilot's license. I am CEO of Sabre International, and I sell the best damn printers and all-in-one machines Korea can make. Pleased to meet you.", "order": 33245, "speaker": "Jo Bennett"},
  {"season": 6, "episode": 15, "scene": 10, "line": "[almost running into Erin] Whoa-oh, traffic jam!", "order": 33246, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 15, "scene": 10, "line": "Uh-oh, traffic jam on route three!", "order": 33247, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 15, "scene": 10, "line": "[imitating car noises] Beep-beep! Beeooop-beep!", "order": 33248, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 15, "scene": 10, "line": "Beep! Twenty people dead in a pile-up!", "order": 33249, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 15, "scene": 10, "line": "There's blood everywhere! Um, I got you a Valentine's card.", "order": 33250, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 15, "scene": 10, "line": "[opening card] Oh, you did? Aw, a bird and a dog!", "order": 33251, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 15, "scene": 10, "line": "Yeah, well it's Snoopy and Woodstock.", "order": 33252, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 15, "scene": 10, "line": "You named them?", "order": 33253, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 15, "scene": 10, "line": "Uh, Charles Schultz did. I thought it was relevant cause I got you all those birds for Christmas, remember?", "order": 33254, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 15, "scene": 10, "line": "Uh, yeah I do remember. [reading card] Aw, they love each other.", "order": 33255, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 15, "scene": 10, "line": "Oh, look at that. They sure do. I hadn't noticed.", "order": 33256, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 15, "scene": 10, "line": "Wow, it smells really good too.", "order": 33257, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 15, "scene": 10, "line": "Yeah! It's Roger Federer for men, I sprayed some in there.", "order": 33258, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 15, "scene": 10, "line": "Andy, whoa! Thank you very much!", "order": 33259, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 15, "scene": 10, "line": "It's got pheromones in it.", "order": 33260, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 15, "scene": 11, "line": "Hey!", "order": 33261, "speaker": "Jo Bennett"},
  {"season": 6, "episode": 15, "scene": 11, "line": "Hi.", "order": 33262, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 15, "scene": 11, "line": "[to Angela, who's having a hard time deciding on a chair] Just choosing seats, not getting married. Chop, chop little onion!", "order": 33263, "speaker": "Jo Bennett"},
  {"season": 6, "episode": 15, "scene": 11, "line": "Jo, there's books in my chair.", "order": 33264, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 15, "scene": 11, "line": "That's right, darlin', now you're the proud new owner of a Sabre handbook and my autobiography. Now, you all must be in a tizzy. I can see it on your faces, I mean, what's going on now? I mean, who owns Dunder Mifflin? Right? I mean, Sabre? What's that? Some company I've never heard of? Down in Tallahassee? Where is that? Near Mars?", "order": 33265, "speaker": "Jo Bennett"},
  {"season": 6, "episode": 15, "scene": 11, "line": "No, we know. Texas!", "order": 33266, "speaker": "Michael Scott"},
  {"season": 6, "episode": 15, "scene": 11, "line": "Now Dunder Mifflin has an arm's reach in all these small businesses all over the northeast. Now we're gonna take that arm, and we're gonna start selling printers.", "order": 33267, "speaker": "Jo Bennett"},
  {"season": 6, "episode": 15, "scene": 11, "line": "I could manage my way around that.", "order": 33268, "speaker": "Michael Scott"},
  {"season": 6, "episode": 15, "scene": 11, "line": "They're the easiest-to-use printers on the market.", "order": 33269, "speaker": "Jo Bennett"},
  {"season": 6, "episode": 15, "scene": 11, "line": "I will try to manage my excitement!", "order": 33270, "speaker": "Michael Scott"},
  {"season": 6, "episode": 15, "scene": 12, "line": "I have been saying the word 'manager' a lot, so whenever Jo thinks 'manager' she thinks of me. Camel cigarettes did the same thing with Joe Camel by making him look like a penis. I can't even go near a cigarette now without thinking of a penis. And vise-versa.", "order": 33271, "speaker": "Michael Scott"},
  {"season": 6, "episode": 15, "scene": 13, "line": "I think that's it. So we're all good! [many employees raise their hands] Thanks ya'll.", "order": 33272, "speaker": "Jo Bennett"},
  {"season": 6, "episode": 15, "scene": 13, "line": "[Jo leaves] I, uh...", "order": 33273, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 15, "scene": 14, "line": "[reading her Valentine's card from Andy] Oh my God.", "order": 33274, "speaker": "Kelly Kapoor"},
  {"season": 6, "episode": 15, "scene": 15, "line": "I guess Andy likes me. I never thought of him in that way. But, I guess in most romantic comedies, the guy you're supposed to be with is the one you never thought of in that way. You might have even thought he was annoying or possibly homosexual.", "order": 33275, "speaker": "Kelly Kapoor"},
  {"season": 6, "episode": 15, "scene": 16, "line": "So, check this out. According to the handbook, you could make more money as a salesman than as a manager. They have all these incentive programs. I ran your numbers from last year.", "order": 33276, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 15, "scene": 16, "line": "This is way more than I make now.", "order": 33277, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 15, "scene": 17, "line": "I don't think I'm gonna miss being manager. You know how some people say they're not in it for the money? Well, with all due respect to this job, I think I'm definitely in it for the money. And quite honestly, the women.", "order": 33278, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 15, "scene": 18, "line": "Hey, you got a second?", "order": 33279, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 15, "scene": 18, "line": "Sure. You here to tell me that you think I would be a better manager and that you are sorry for being such a jerk?", "order": 33280, "speaker": "Michael Scott"},
  {"season": 6, "episode": 15, "scene": 18, "line": "Actually, yeah.", "order": 33281, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 15, "scene": 18, "line": "What?", "order": 33282, "speaker": "Michael Scott"},
  {"season": 6, "episode": 15, "scene": 18, "line": "Exactly that. I think that you absolutely deserve the manager position more than I do.", "order": 33283, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 15, "scene": 18, "line": "What? Really?", "order": 33284, "speaker": "Michael Scott"},
  {"season": 6, "episode": 15, "scene": 18, "line": "Yeah!", "order": 33285, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 15, "scene": 18, "line": "Wow!", "order": 33286, "speaker": "Michael Scott"},
  {"season": 6, "episode": 15, "scene": 19, "line": "La la la, hello Oscar!", "order": 33287, "speaker": "Michael Scott"},
  {"season": 6, "episode": 15, "scene": 19, "line": "Michael. Reading.", "order": 33288, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 15, "scene": 19, "line": "What are you reading?", "order": 33289, "speaker": "Michael Scott"},
  {"season": 6, "episode": 15, "scene": 19, "line": "The Atlantic.", "order": 33290, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 15, "scene": 19, "line": "Oh, that is my favorite ocean! I love it! I am so happy right now. No! I can't keep it a secret any longer. Jim is stepping down to salesman, I am going to be the sole manager once again.", "order": 33291, "speaker": "Michael Scott"},
  {"season": 6, "episode": 15, "scene": 19, "line": "I should step into sales myself.", "order": 33292, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 15, "scene": 19, "line": "Why, is there an untapped gay market?", "order": 33293, "speaker": "Michael Scott"},
  {"season": 6, "episode": 15, "scene": 19, "line": "Sabre has no caps on commissions. He can make a lot more money in sales.", "order": 33294, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 15, "scene": 19, "line": "...Where did you get that information.", "order": 33295, "speaker": "Michael Scott"},
  {"season": 6, "episode": 15, "scene": 19, "line": "Manual.", "order": 33296, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 15, "scene": 19, "line": "Manuel who?", "order": 33297, "speaker": "Michael Scott"},
  {"season": 6, "episode": 15, "scene": 20, "line": "Well, according to the manual, there is no cap on commissions. I have been hustled.", "order": 33298, "speaker": "Michael Scott"},
  {"season": 6, "episode": 15, "scene": 21, "line": "Son of a bitch. [enters conference room] Pardon me.", "order": 33299, "speaker": "Michael Scott"},
  {"season": 6, "episode": 15, "scene": 21, "line": "Oh, speak of the devil. We were just talking about you. Have a seat!", "order": 33300, "speaker": "Jo Bennett"},
  {"season": 6, "episode": 15, "scene": 21, "line": "Okay.", "order": 33301, "speaker": "Michael Scott"},
  {"season": 6, "episode": 15, "scene": 21, "line": "Now Jim here is thinking about taking himself out of the running for being manager.", "order": 33302, "speaker": "Jo Bennett"},
  {"season": 6, "episode": 15, "scene": 21, "line": "Really? Aw, that is so sweet of you! But, I cannot accept, because I have been thinking about it, and I think I wanna go back to sales.", "order": 33303, "speaker": "Michael Scott"},
  {"season": 6, "episode": 15, "scene": 21, "line": "Really?", "order": 33304, "speaker": "Jo Bennett"},
  {"season": 6, "episode": 15, "scene": 21, "line": "Really.", "order": 33305, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 15, "scene": 21, "line": "Yeah. You can take the man out of the salesman, but you can't take the sales out of salesman.", "order": 33306, "speaker": "Michael Scott"},
  {"season": 6, "episode": 15, "scene": 21, "line": "Well, I think you're both being a little too modest.", "order": 33307, "speaker": "Jo Bennett"},
  {"season": 6, "episode": 15, "scene": 21, "line": "No, I really, really think Michael is better at being manager for so many reasons.", "order": 33308, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 15, "scene": 21, "line": "No, I think I would be bad. I would sleep in my office, and I would sexually harass people.", "order": 33309, "speaker": "Michael Scott"},
  {"season": 6, "episode": 15, "scene": 21, "line": "Why would you do that?", "order": 33310, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 15, "scene": 21, "line": "I'm turning myself in right now!", "order": 33311, "speaker": "Michael Scott"},
  {"season": 6, "episode": 15, "scene": 21, "line": "You know, Michael, you have more experience in sales and management, so I'm gonna defer to your judgment.", "order": 33312, "speaker": "Jo Bennett"},
  {"season": 6, "episode": 15, "scene": 21, "line": "Thank you.", "order": 33313, "speaker": "Michael Scott"},
  {"season": 6, "episode": 15, "scene": 21, "line": "I think you [to Michael] will become the salesman, and congratulations! [to Jim] You're gonna be the new manager of this branch!", "order": 33314, "speaker": "Jo Bennett"},
  {"season": 6, "episode": 15, "scene": 21, "line": "Great! That's great. Congratulations. Have fun signing my commission checks, boss.", "order": 33315, "speaker": "Michael Scott"},
  {"season": 6, "episode": 15, "scene": 22, "line": "[pointing to the window in Michael's office] Oh, Michael marked his heights. He's grown!", "order": 33316, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 15, "scene": 22, "line": "Mm!", "order": 33317, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 15, "scene": 23, "line": "[on phone] Bobcat, this is Dragon. Listen. He's been promoted to sole manager. We've got to step this up. Meet me behind the dumpster in ninety seconds. [hangs up phone] 89... 88...", "order": 33318, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 15, "scene": 24, "line": "You're twenty minutes late!", "order": 33319, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 15, "scene": 24, "line": "[walking up] Um, I was at another dumpster!", "order": 33320, "speaker": "Ryan Howard"},
  {"season": 6, "episode": 15, "scene": 24, "line": "Just admit you lost track of time.", "order": 33321, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 15, "scene": 25, "line": "Ryan and I have been meeting up to work on our diabolical plot against Jim. Ryan is always late for our meetings. I wish I had a lair.", "order": 33322, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 15, "scene": 26, "line": "Did you see Saw?", "order": 33323, "speaker": "Ryan Howard"},
  {"season": 6, "episode": 15, "scene": 26, "line": "Of course I seesaw. Mose and I seesaw all the time.", "order": 33324, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 15, "scene": 26, "line": "No, uh, the movie. Did you see the movie Saw?", "order": 33325, "speaker": "Ryan Howard"},
  {"season": 6, "episode": 15, "scene": 26, "line": "Oh, yeah. Great film. Almost as fun as going on a seesaw.", "order": 33326, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 15, "scene": 26, "line": "Okay, the reason these movies are so popular, is the element of psychological torment.", "order": 33327, "speaker": "Ryan Howard"},
  {"season": 6, "episode": 15, "scene": 26, "line": "I like where you're going with this. Continue.", "order": 33328, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 15, "scene": 26, "line": "Could we lure him into an old warehouse or something?", "order": 33329, "speaker": "Ryan Howard"},
  {"season": 6, "episode": 15, "scene": 26, "line": "I have an old barn!", "order": 33330, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 15, "scene": 26, "line": "Yes!", "order": 33331, "speaker": "Ryan Howard"},
  {"season": 6, "episode": 15, "scene": 26, "line": "It's kinda smelly, but that might be a plus! And then what?", "order": 33332, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 15, "scene": 26, "line": "We do what they did in Saw! ... I mean, we don't kill him, obviously. I have a mask...", "order": 33333, "speaker": "Ryan Howard"},
  {"season": 6, "episode": 15, "scene": 26, "line": "Okay, that's your idea? Exactly like in the movie!? That is the most idiotic thing I have ever heard in my life!", "order": 33334, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 15, "scene": 26, "line": "Well suggest something else then! Don't just...", "order": 33335, "speaker": "Ryan Howard"},
  {"season": 6, "episode": 15, "scene": 26, "line": "He's supposed to cut his leg off? Think!", "order": 33336, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 15, "scene": 26, "line": "Don't just criticize my idea!", "order": 33337, "speaker": "Ryan Howard"},
  {"season": 6, "episode": 15, "scene": 26, "line": "Think!", "order": 33338, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 15, "scene": 26, "line": "You think of something then!", "order": 33339, "speaker": "Ryan Howard"},
  {"season": 6, "episode": 15, "scene": 27, "line": "Michael, I'm worried that, uh, not all your toys are gonna fit on your new desk.", "order": 33340, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 15, "scene": 27, "line": "How is that possible?", "order": 33341, "speaker": "Michael Scott"},
  {"season": 6, "episode": 15, "scene": 27, "line": "Well, in your old office there were all those ledges and extra space.", "order": 33342, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 15, "scene": 27, "line": "No excuses Erin, come on! Make it happen.", "order": 33343, "speaker": "Michael Scott"},
  {"season": 6, "episode": 15, "scene": 27, "line": "You know, I actually think I should get back to work. Jim asked me to do some stuff, and he's manager.", "order": 33344, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 15, "scene": 27, "line": "Oh, yeah. Fine, fine. Leave it here. I have to make some sales anyway. Who should I call with my magic sales fingers? [Michael's phone rings] Michael Scott, head of sales.", "order": 33345, "speaker": "Michael Scott"},
  {"season": 6, "episode": 15, "scene": 27, "line": "[on phone] You gotta do something, man. You can't just sit there.", "order": 33346, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 15, "scene": 28, "line": "The new IT guy. Nick.", "order": 33347, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 15, "scene": 28, "line": "Nick.", "order": 33348, "speaker": "Ryan Howard"},
  {"season": 6, "episode": 15, "scene": 28, "line": "I think he is the key. He's very trusting, he's looking for friends, he has been given an awesome amount of power, and does not know how to wield it.", "order": 33349, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 15, "scene": 28, "line": "Like Frodo!", "order": 33350, "speaker": "Ryan Howard"},
  {"season": 6, "episode": 15, "scene": 28, "line": "Why don't you just let me handle the Tolkien references, okay dumb jock?", "order": 33351, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 15, "scene": 28, "line": "Well, I think he can be corrupted. Like Gollum.", "order": 33352, "speaker": "Ryan Howard"},
  {"season": 6, "episode": 15, "scene": 28, "line": "Smeagol was corrupted and became Gollum.", "order": 33353, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 15, "scene": 29, "line": "I might start a diabolical plot against him after this one.", "order": 33354, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 15, "scene": 30, "line": "Who's hungry?", "order": 33355, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 15, "scene": 30, "line": "What is that?", "order": 33356, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 15, "scene": 30, "line": "It's ants on a log. Michael would always have me bring them in at 2:30 and say 'Who's hungry?'", "order": 33357, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 15, "scene": 30, "line": "Oh. I'm fine. Thank you, though.", "order": 33358, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 15, "scene": 30, "line": "Do you want me to spin you in your chair and make you dizzy?", "order": 33359, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 15, "scene": 30, "line": "Why would I wanna do that?", "order": 33360, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 15, "scene": 30, "line": "It's a thinking technique. All the top executives do it. It keeps the brain moving, and a spinning brain is a working brain.", "order": 33361, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 15, "scene": 30, "line": "For now, I'm just gonna go back to work here...", "order": 33362, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 15, "scene": 30, "line": "Oh sure.", "order": 33363, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 15, "scene": 30, "line": "Yeah. Thank you.", "order": 33364, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 15, "scene": 30, "line": "Well.", "order": 33365, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 15, "scene": 31, "line": "[on phone] It will be a pleasure doing business with you. Thanks, bye. [hangs up phone, blows train whistle] I just got a new account for the gentleman's club in Carbondale. It is called 'Curves.' I went by there the other day, saw some of the women walking in. Not really my cup of tea. Actually, Kevin, you might like it!", "order": 33366, "speaker": "Michael Scott"},
  {"season": 6, "episode": 15, "scene": 31, "line": "Nice!", "order": 33367, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 15, "scene": 31, "line": "You know, we really don't announce out loud our sales that much.", "order": 33368, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 15, "scene": 31, "line": "Why not? It's part of the sales experience.", "order": 33369, "speaker": "Michael Scott"},
  {"season": 6, "episode": 15, "scene": 31, "line": "It's not really 1992 anymore.", "order": 33370, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 15, "scene": 31, "line": "Well, okay...", "order": 33371, "speaker": "Michael Scott"},
  {"season": 6, "episode": 15, "scene": 32, "line": "[reading Andy's card] You brighten my day with the sound of your voice, you bring so much laughter and love, you're everything to me, and I was so blessed when God sent you here to me.", "order": 33372, "speaker": "Kelly Kapoor"},
  {"season": 6, "episode": 15, "scene": 32, "line": "Geez louise.", "order": 33373, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 15, "scene": 32, "line": "I know, obsessed with me much?", "order": 33374, "speaker": "Kelly Kapoor"},
  {"season": 6, "episode": 15, "scene": 32, "line": "Well, everyone got one.", "order": 33375, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 15, "scene": 32, "line": "What did yours say?", "order": 33376, "speaker": "Kelly Kapoor"},
  {"season": 6, "episode": 15, "scene": 32, "line": "Friends are worth sharing a doghouse with.", "order": 33377, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 15, "scene": 33, "line": "It's no wonder that Andy gave Kelly such a romantic card. I can't compete with her. That girl can sing, and dance, and gets all of her clothes at the mall. And I feel like such a fool for thinking that Andy was only going after one girl, cause Andy Bernard is a playboy. And, why shouldn't he be? He's got it all.", "order": 33378, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 15, "scene": 34, "line": "Ho-ho. There he is, the IT guy. So you discovered the break room.", "order": 33379, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 15, "scene": 34, "line": "Uh, yep.", "order": 33380, "speaker": "Nick"},
  {"season": 6, "episode": 15, "scene": 34, "line": "I imagine one of the best things about being an IT guy is, you get to know everyone's computer passwords.", "order": 33381, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 15, "scene": 34, "line": "No, I actually don't.", "order": 33382, "speaker": "Nick"},
  {"season": 6, "episode": 15, "scene": 34, "line": "Listen, I know you have to say that, but we got a little problem here. There is an employee named Jim Halpert, and he is doing some terrible things, okay? He is molesting people via the internet. And we need to stop him.", "order": 33383, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 15, "scene": 34, "line": "I think that you should call the cops.", "order": 33384, "speaker": "Nick"},
  {"season": 6, "episode": 15, "scene": 34, "line": "No, the cops called us. So I'm gonna need you to give me the password to Jim Halpert's computer.", "order": 33385, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 15, "scene": 34, "line": "I don't know what to tell you, man. I'm sorry, I just can't give out his password like that.", "order": 33386, "speaker": "Nick"},
  {"season": 6, "episode": 15, "scene": 34, "line": "Nick. We could make things very, very difficult for you.", "order": 33387, "speaker": "Ryan Howard"},
  {"season": 6, "episode": 15, "scene": 34, "line": "Are you... you threatening me?", "order": 33388, "speaker": "Nick"},
  {"season": 6, "episode": 15, "scene": 34, "line": "Threatening you? No. [tries to crush an aluminum can, Dwight crushes an apple]", "order": 33389, "speaker": "Ryan Howard"},
  {"season": 6, "episode": 15, "scene": 35, "line": "What is that smell? Do you smell that? What is that? Like a sulfur deposit under here?", "order": 33390, "speaker": "Michael Scott"},
  {"season": 6, "episode": 15, "scene": 35, "line": "Michael, stop.", "order": 33391, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 15, "scene": 35, "line": "No, I'm serious, we don't have to put up... Is it the dogs?", "order": 33392, "speaker": "Michael Scott"},
  {"season": 6, "episode": 15, "scene": 35, "line": "Michael...", "order": 33393, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 15, "scene": 35, "line": "You know what, we don't have to deal with this. I am going to Google sulfur maps.", "order": 33394, "speaker": "Michael Scott"},
  {"season": 6, "episode": 15, "scene": 35, "line": "Michael, it's Phyllis.", "order": 33395, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 15, "scene": 35, "line": "No, this is geological.", "order": 33396, "speaker": "Michael Scott"},
  {"season": 6, "episode": 15, "scene": 35, "line": "I sent an email out to everyone in this area that this might be a side effect to my new allergy medication I'm on.", "order": 33397, "speaker": "Phyllis Vance"},
  {"season": 6, "episode": 15, "scene": 35, "line": "Are you kidding me?", "order": 33398, "speaker": "Michael Scott"},
  {"season": 6, "episode": 15, "scene": 35, "line": "No.", "order": 33399, "speaker": "Phyllis Vance"},
  {"season": 6, "episode": 15, "scene": 35, "line": "And you guys are okay with this?", "order": 33400, "speaker": "Michael Scott"},
  {"season": 6, "episode": 15, "scene": 35, "line": "She sent an email, so.", "order": 33401, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 15, "scene": 35, "line": "I did.", "order": 33402, "speaker": "Phyllis Vance"},
  {"season": 6, "episode": 15, "scene": 35, "line": "[has two tissues in his nostrils] It's not cause of the smell. I'm just expecting a nosebleed.", "order": 33403, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 15, "scene": 35, "line": "Oh my God.", "order": 33404, "speaker": "Michael Scott"},
  {"season": 6, "episode": 15, "scene": 36, "line": "Oh! You scared me!", "order": 33405, "speaker": "Kelly Kapoor"},
  {"season": 6, "episode": 15, "scene": 36, "line": "I'm sorry, I thought you saw me.", "order": 33406, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 15, "scene": 36, "line": "I didn't see you. And you were there all along. [takes papers out of copy machine] Well, I warmed it up for you so, should be good to go.", "order": 33407, "speaker": "Kelly Kapoor"},
  {"season": 6, "episode": 15, "scene": 36, "line": "Cool, thanks.", "order": 33408, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 15, "scene": 36, "line": "Bye, Andy. [kisses his cheek]", "order": 33409, "speaker": "Kelly Kapoor"},
  {"season": 6, "episode": 15, "scene": 36, "line": "...That was weird.", "order": 33410, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 15, "scene": 36, "line": "No it ain't. We all saw the Valentine you gave her.", "order": 33411, "speaker": "Meredith Palmer"},
  {"season": 6, "episode": 15, "scene": 36, "line": "I don't even know what that card said.", "order": 33412, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 15, "scene": 36, "line": "Believe me, if I got that card? We'd be in the bathroom doin' it right... now.", "order": 33413, "speaker": "Meredith Palmer"},
  {"season": 6, "episode": 15, "scene": 37, "line": "Hey.", "order": 33414, "speaker": "Michael Scott"},
  {"season": 6, "episode": 15, "scene": 37, "line": "Hey.", "order": 33415, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 15, "scene": 37, "line": "You know, it's funny. I used to stand in here and wish I was out there. Now I'm out there, and I wish I was in here.", "order": 33416, "speaker": "Michael Scott"},
  {"season": 6, "episode": 15, "scene": 37, "line": "Well, the grass is always greener.", "order": 33417, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 15, "scene": 37, "line": "Yeah. Except there's no grass out there. It's just a farty dirt patch.", "order": 33418, "speaker": "Michael Scott"},
  {"season": 6, "episode": 15, "scene": 37, "line": "Well, it's what you wanted.", "order": 33419, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 15, "scene": 37, "line": "I really need my job back. We made a terrible mistake here.", "order": 33420, "speaker": "Michael Scott"},
  {"season": 6, "episode": 15, "scene": 37, "line": "For the record, I fought this. Alright? And now, I'm not really sure what we do.", "order": 33421, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 15, "scene": 37, "line": "We make a poster that says 'Happy Opposite Day!' and she sees it on the way out... Nah, that's stupid. Ugh! I wish we had one of those amnesia flashlights from Men in Black.", "order": 33422, "speaker": "Michael Scott"},
  {"season": 6, "episode": 15, "scene": 37, "line": "Hey, what was that movie where their boss was within earshot and they could've just gone and talked to her.", "order": 33423, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 15, "scene": 37, "line": "Lethal Weapon?", "order": 33424, "speaker": "Michael Scott"},
  {"season": 6, "episode": 15, "scene": 37, "line": "That's it. I think we should do it the Lethal Weapon way.", "order": 33425, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 15, "scene": 38, "line": "You two are grown-ass men. What do I have to do, fire you and get two people in here who don't need so much management?", "order": 33426, "speaker": "Jo Bennett"},
  {"season": 6, "episode": 15, "scene": 38, "line": "What? What I'm saying...", "order": 33427, "speaker": "Michael Scott"},
  {"season": 6, "episode": 15, "scene": 38, "line": "Michael's saying he's better at being manager, and I agree, cause he's a better people person, weirdly.", "order": 33428, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 15, "scene": 38, "line": "And weirdly I'm a good dog person, right guys? [dogs go to him] See? They love me.", "order": 33429, "speaker": "Michael Scott"},
  {"season": 6, "episode": 15, "scene": 38, "line": "Fine. I don't care which one of you does which job, just stop meeting with me to talk about it.", "order": 33430, "speaker": "Jo Bennett"},
  {"season": 6, "episode": 15, "scene": 38, "line": "Alright.", "order": 33431, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 15, "scene": 38, "line": "Alright!", "order": 33432, "speaker": "Michael Scott"},
  {"season": 6, "episode": 15, "scene": 38, "line": "[cell phone rings] Oh, I gotta take this. Yep. Uh, finish walking my dogs for me. And don't ride 'em. Lotta people try to ride 'em.", "order": 33433, "speaker": "Jo Bennett"},
  {"season": 6, "episode": 15, "scene": 39, "line": "[Erin leaves room] Excuse me? Everyone? Please check your emails, I just sent you the following message: 'Coworkers, you may have received a Valentine from me. Please understand this does not mean that I like you in any way.'", "order": 33434, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 15, "scene": 39, "line": "You don't even like us as friends?", "order": 33435, "speaker": "Phyllis Vance"},
  {"season": 6, "episode": 15, "scene": 39, "line": "Phyllis, you guys are like my closest friends. I just mean I don't like-like you.", "order": 33436, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 15, "scene": 39, "line": "What are we, five?", "order": 33437, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 15, "scene": 39, "line": "Please don't read into this card. Yours in professionalism, Nard dog.'", "order": 33438, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 15, "scene": 39, "line": "[entering with Erin] Um, why did I just receive a mass email from you that said that you don't like me? Do you realize how hard that makes me like you, Andy?", "order": 33439, "speaker": "Kelly Kapoor"},
  {"season": 6, "episode": 15, "scene": 39, "line": "It's n-- that wasn't... I like someone else.", "order": 33440, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 15, "scene": 39, "line": "Who?", "order": 33441, "speaker": "Kelly Kapoor"},
  {"season": 6, "episode": 15, "scene": 39, "line": "It's not... Whether or not, you...", "order": 33442, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 15, "scene": 39, "line": "Who, what?", "order": 33443, "speaker": "Kelly Kapoor"},
  {"season": 6, "episode": 15, "scene": 40, "line": "Yeah, I guess Andy never liked Kelly. How about that?", "order": 33444, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 15, "scene": 41, "line": "Oh, it smells good in here.", "order": 33445, "speaker": "Michael Scott"},
  {"season": 6, "episode": 15, "scene": 41, "line": "Sure does! Okay, we have your space heater, your humidifier, your dehumidifier, your fan, your foot fan, and your food dehydrator.", "order": 33446, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 15, "scene": 41, "line": "Erin, what about my keyboard? [Erin hits a button on an electronic keyboard, playing a beat] Aw, so good to be home. How about a little Bosa Nova?", "order": 33447, "speaker": "Michael Scott"},
  {"season": 6, "episode": 15, "scene": 41, "line": "Oh! [hits another key, they awkwardly dance together]", "order": 33448, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 15, "scene": 42, "line": "[gesturing to Michael dancing in his office] He looks happy.", "order": 33449, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 15, "scene": 42, "line": "Yep.", "order": 33450, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 15, "scene": 42, "line": "Well, well, well. Hm. Boss for, what was it? Oh, four and a half hours? New record. Low. Previous record? Henry Rosston. Boss for nine years, four months. And he only left because he had family matters to attend to and he [Jim dunks Dwight's tie in his coffee cup, Pam smiles] what? Michael!", "order": 33451, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 15, "scene": 43, "line": "[entering elevator with Ryan] I can't help but think that something we did made this possible.", "order": 33452, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 15, "scene": 43, "line": "Jim dug his own grave, but maybe we provided the shovel.", "order": 33453, "speaker": "Ryan Howard"},
  {"season": 6, "episode": 15, "scene": 43, "line": "Oh temp, I like that. I'm going to engrave that into a piece of wood. Care to celebrate with a drink?", "order": 33454, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 15, "scene": 43, "line": "I would love to. [elevator doors close, then reopen in the lobby] Why not?", "order": 33455, "speaker": "Ryan Howard"},
  {"season": 6, "episode": 15, "scene": 43, "line": "Because! Martini bars are pretentious. No thank you.", "order": 33456, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 15, "scene": 43, "line": "Well, I'm sorry that I don't want farm boy swill like you do, you know.", "order": 33457, "speaker": "Ryan Howard"},
  {"season": 6, "episode": 15, "scene": 43, "line": "It is not 'farm boy swill,' I will show you. It is beet Vodka and it is delicious.", "order": 33458, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 15, "scene": 43, "line": "I am not interested in anything I have to make myself!", "order": 33459, "speaker": "Ryan Howard"},
  {"season": 6, "episode": 16, "scene": 1, "line": "[on the phone with a client] I just wanted to check and see if there's anything you needed before I went on my maternity leave... Yeah, I'm pregnant... Great, well, I'll write up the order. Okay, thanks.", "order": 33460, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 16, "scene": 1, "line": "Wait a minute! You can't do that. You cannot exploit your baby for sales.", "order": 33461, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 16, "scene": 1, "line": "[on the phone] Hey, did I tell you we were going to have a baby? Oh, thank you very much. I'm excited. Oh, definitely.", "order": 33462, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 16, "scene": 1, "line": "No, no! You need to come by your sales honorably!", "order": 33463, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 16, "scene": 1, "line": "There is nothing dishonorable about talking about your life. People like it.", "order": 33464, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 16, "scene": 1, "line": "[on the phone with a client] Hey there. Dwight Schrute here. Listen, uh, would you be interested in restocking on paper? ... Yeah, I could sure use the money. My cousin, uh, came down with a case of that nasty new goat fungus. Oh, it's just horrific. The doctor says he'd never seen it beard so quickly. Okay.", "order": 33465, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 16, "scene": 2, "line": "I need a baby. I'll never outsell Jim and Pam without one. Also, I've been noticing a gaping hole in my life. Sometimes I wake up cradling a gourd.", "order": 33466, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 16, "scene": 3, "line": "Kevin, you're such a gourmand.", "order": 33467, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 16, "scene": 3, "line": "I cooked my way through Julia Childs' cookbook, and now, I'm halfway through the Twilight cookbook. Last night, I had Edward's corn flake chicken.", "order": 33468, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 16, "scene": 3, "line": "Hmm.", "order": 33469, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 16, "scene": 4, "line": "Pregnant Pam and I, we get hungry at the same times, so we've been eating together a lot. Not all meals. Just second breakfast, lunch, second lunch, and first dinner.", "order": 33470, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 16, "scene": 5, "line": "I thought that maybe we should do something special for early dinner. One last ultra feast.", "order": 33471, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 16, "scene": 5, "line": "Mmmm, that sounds great. What are you thinking?", "order": 33472, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 16, "scene": 5, "line": "I think it should be a surprise.", "order": 33473, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 16, "scene": 5, "line": "[After Pam has a contraction] Oh, getting there, huh?", "order": 33474, "speaker": "Meredith Palmer"},
  {"season": 6, "episode": 16, "scene": 5, "line": "No, no. I still have time.", "order": 33475, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 16, "scene": 6, "line": "I'm having contractions, but they're irregular and far apart. So I'm not really in labor, I'm near labor.", "order": 33476, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 16, "scene": 6, "line": "Yeah, we're slow-playing it because of our stupid HMO.", "order": 33477, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 16, "scene": 6, "line": "If we check in after midnight, I get an extra day to recuperate surrounded by doctors.", "order": 33478, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 16, "scene": 6, "line": "Not to mention the extra night's sleep in the hospital will be very nice because once we bring the baby home, if it's crying all night, one of us is going to have to take care of it. And I do not plan on helping unless it's a boy.", "order": 33479, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 16, "scene": 6, "line": "I cannot wait for that joke to be over.", "order": 33480, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 16, "scene": 7, "line": "Ooh.", "order": 33481, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 16, "scene": 7, "line": "Oh, oh, oh! Contraption! She's contrapting! Okay, you know what? I think I should drive you guys to the hospital, and here is why. I am a licensed, classy driver in the state of Pennsylvania. I gassed up the car...", "order": 33482, "speaker": "Michael Scott"},
  {"season": 6, "episode": 16, "scene": 7, "line": "Michael.", "order": 33483, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 16, "scene": 7, "line": "Actually, I put diesel in this time, trying to save some money.", "order": 33484, "speaker": "Michael Scott"},
  {"season": 6, "episode": 16, "scene": 7, "line": "Michael, you shouldn't have done that.", "order": 33485, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 16, "scene": 7, "line": "Happy to do it. Also, I did a heck of a job baby-proofing this office.", "order": 33486, "speaker": "Michael Scott"},
  {"season": 6, "episode": 16, "scene": 7, "line": "You know the baby's not going to live here, right?", "order": 33487, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 16, "scene": 7, "line": "Well, the baby was conceived here, so might as well live here a little bit, too.", "order": 33488, "speaker": "Michael Scott"},
  {"season": 6, "episode": 16, "scene": 7, "line": "Hmm, that logic's air-tight, but unfortunately it wasn't conceived here. Burning man, port-o-potty.", "order": 33489, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 16, "scene": 7, "line": "Oh, yuck! TMI! How was it? I don't want to know. Tell me later. Let's go! Let's go! Hospital!", "order": 33490, "speaker": "Michael Scott"},
  {"season": 6, "episode": 16, "scene": 7, "line": "Okay, okay, we're not going to the hospital. We are waiting until midnight.", "order": 33491, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 16, "scene": 7, "line": "Ooh, spooky. But why?", "order": 33492, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 16, "scene": 7, "line": "Because the insurance company only covers two nights.", "order": 33493, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 16, "scene": 7, "line": "Everything's fine. We have plenty of time.", "order": 33494, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 16, "scene": 7, "line": "Well, you don't want to wait too long, Pam. Otherwise the baby's going to become a teenager in there and you'll be up all night, from the rock music.", "order": 33495, "speaker": "Nick"},
  {"season": 6, "episode": 16, "scene": 7, "line": "Shut up, Nick. What a weird thing to say. Weird I.T. nerd. Don't get revenge on me, nerd.", "order": 33496, "speaker": "Michael Scott"},
  {"season": 6, "episode": 16, "scene": 7, "line": "[as Nick looks over at her] What are you looking at?", "order": 33497, "speaker": "Angela Martin"},
  {"season": 6, "episode": 16, "scene": 7, "line": "Ha, nerd.", "order": 33498, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 16, "scene": 8, "line": "Did you know that labor can last weeks? Then they take your insides out and they just plop them on a table, and sometimes epidurals don't work, and you can poop yourself.", "order": 33499, "speaker": "Kelly Kapoor"},
  {"season": 6, "episode": 16, "scene": 9, "line": "[with Angela in the break room] Bare my child.", "order": 33500, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 16, "scene": 9, "line": "Excuse me?", "order": 33501, "speaker": "Angela Martin"},
  {"season": 6, "episode": 16, "scene": 9, "line": "I want to have a child for business reasons and I want you to be the mother. If you agree, say nothing. If you disagree, say anything... Very well. Let's meet at 4:00 PM at our old meeting spot and bang it out.", "order": 33502, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 16, "scene": 10, "line": "[after Pam has another contraction] That's seven minutes. Here we go. This is happening. Come on.", "order": 33503, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 16, "scene": 10, "line": "Hold on, hold on. It isn't midnight yet.", "order": 33504, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 16, "scene": 10, "line": "Are you serious? Pam.", "order": 33505, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 16, "scene": 10, "line": "No, the doctor said every five to seven minutes.", "order": 33506, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 16, "scene": 10, "line": "I... Pam, please.", "order": 33507, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 16, "scene": 10, "line": "I'm going to be okay, we should really try to make it until midnight.", "order": 33508, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 16, "scene": 10, "line": "Yeah, no, you really should. Because if your baby's born tomorrow, he's going to have the same birthday as Butt-mud Brooks. My old roommate.", "order": 33509, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 16, "scene": 10, "line": "Did you hear that? Butt-mud Brooks.", "order": 33510, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 16, "scene": 10, "line": "Okay, but we are leaving at five minutes apart.", "order": 33511, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 16, "scene": 10, "line": "Five minutes apart.", "order": 33512, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 16, "scene": 11, "line": "So, the plan was seven minutes. But we're calling an audible, because that's her call. Because she's the quarterback. I'm just the left tackle who happened to get her pregnant.", "order": 33513, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 16, "scene": 12, "line": "Okay, stop watching me.", "order": 33514, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 16, "scene": 12, "line": "Okay, crazy. I think I have some better things to do with my day than worry about you, like sell printers.", "order": 33515, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 16, "scene": 12, "line": "Mm-hmm.", "order": 33516, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 16, "scene": 12, "line": "Well not until Friday 20% off toner cartridges, that's a big deal. While we're on the subject, why don't I just run you down to the hospital and we'll just do a quick check?", "order": 33517, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 16, "scene": 12, "line": "Not until midnight.", "order": 33518, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 16, "scene": 12, "line": "Guys. Word of advice. Speaking as a former baby. Don't get too hung up on baby names. I was named Walter Jr. after my father until I was about six or so, when my parents changed their minds.", "order": 33519, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 16, "scene": 12, "line": "I thought you said your younger brother was named Walter Jr.", "order": 33520, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 16, "scene": 12, "line": "My brother was born, and my parents felt he better exemplified the Walter Jr. name, so they gave it to him. I was given Andrew, which they got out of a baby name book.", "order": 33521, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 16, "scene": 12, "line": "You know, it's getting real crowded in here. Maybe you guys should all go back to work because the day's not out yet.", "order": 33522, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 16, "scene": 12, "line": "No, no, no, no, no. You know what? You can't tell us what to do because you are not co-manager anymore.", "order": 33523, "speaker": "Michael Scott"},
  {"season": 6, "episode": 16, "scene": 12, "line": "[as Andy and Kevin shout 'yeah!' in response to Michael] Okay, I feel like this noise is going to prevent Pam from being able to listen to her body's signals.", "order": 33524, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 16, "scene": 12, "line": "Actually, the distractions are good. I mean, I don't think I'm going to make it until midnight if I'm just sitting here thinking about it.", "order": 33525, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 16, "scene": 12, "line": "Distractions are good! That means conference room, five minutes! No, no, five seconds! Right now, right now! Conference room! Topic, potpourri! Let's go!", "order": 33526, "speaker": "Michael Scott"},
  {"season": 6, "episode": 16, "scene": 13, "line": "Um, I am sort of a master of distraction. When I was a kid, my mom received compliments left and right from my teachers on how I was always able to distract others in class. Try to think, what were the first thirteen colonies? Mm-hmm, mm-hmm. [Michael covers his mouth and makes fart noises.]", "order": 33527, "speaker": "Michael Scott"},
  {"season": 6, "episode": 16, "scene": 14, "line": "The purpose for this meeting is to take Pam's mind off of what's going on inside of her body.", "order": 33528, "speaker": "Michael Scott"},
  {"season": 6, "episode": 16, "scene": 14, "line": "Can we do sleight of hand tricks?", "order": 33529, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 16, "scene": 14, "line": "I will allow that.", "order": 33530, "speaker": "Michael Scott"},
  {"season": 6, "episode": 16, "scene": 14, "line": "Yes! Can anyone do those?", "order": 33531, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 16, "scene": 14, "line": "I'm going to go look at the Internet.", "order": 33532, "speaker": "Stanley Hudson"},
  {"season": 6, "episode": 16, "scene": 14, "line": "[as Pam has another contraction] Oh! Oh, alright. That's a good one.", "order": 33533, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 16, "scene": 14, "line": "Oh, okay, uh, sorry, guys. Just, um, keep talking.", "order": 33534, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 16, "scene": 14, "line": "Okay.", "order": 33535, "speaker": "Michael Scott"},
  {"season": 6, "episode": 16, "scene": 14, "line": "Oh my God, Pam, you are a woman warrior.", "order": 33536, "speaker": "Kelly Kapoor"},
  {"season": 6, "episode": 16, "scene": 14, "line": "Oh, thanks, Kelly.", "order": 33537, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 16, "scene": 14, "line": "Does anybody have anything? Anything interesting, any hobbies, uh, special skills?", "order": 33538, "speaker": "Michael Scott"},
  {"season": 6, "episode": 16, "scene": 14, "line": "Yes, this is the only time I'm ever going to make this request.", "order": 33539, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 16, "scene": 14, "line": "Yes, Phyl?", "order": 33540, "speaker": "Michael Scott"},
  {"season": 6, "episode": 16, "scene": 14, "line": "I can put on lipstick the way Molly Ringwald does in The Breakfast Club.", "order": 33541, "speaker": "Phyllis Vance"},
  {"season": 6, "episode": 16, "scene": 14, "line": "[ as Pam shakes her head] Nope, nope, I don't think anybody wants to see that.", "order": 33542, "speaker": "Michael Scott"},
  {"season": 6, "episode": 16, "scene": 14, "line": "I can do the evolution of dance-dance.", "order": 33543, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 16, "scene": 14, "line": "[as Pam gives the thumbs up] That sounds good! Do you need some music, or...? Okay.", "order": 33544, "speaker": "Michael Scott"},
  {"season": 6, "episode": 16, "scene": 14, "line": "Nope, actually music would just throw me off. I need complete silence. Okay... [Pam begins to clap as Andy dances] You're clapping. I need complete silence. Totally threw me off, so I'm going to have to start over. Evolution of dance-dance.", "order": 33545, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 16, "scene": 14, "line": "[reading from a book as Kelly admires] 'You let me in your bed. But now, I sleep alone. Trapped with the forgotten in my detritus home.'", "order": 33546, "speaker": "Ryan Howard"},
  {"season": 6, "episode": 16, "scene": 14, "line": "[naming race horses] Affirmed. Seattle Slew. Secretariat. Citation. Assault. Count Fleet. Rollaway. War Admiral. Omaha. Gallant Fox... And... I know this. Uh... The jockey was Johnny Loftus. Sired by Star Shoot.", "order": 33547, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 16, "scene": 15, "line": "Good afternoon.", "order": 33548, "speaker": "Angela Martin"},
  {"season": 6, "episode": 16, "scene": 15, "line": "Have a seat.", "order": 33549, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 16, "scene": 15, "line": "What is this?", "order": 33550, "speaker": "Angela Martin"},
  {"season": 6, "episode": 16, "scene": 15, "line": "Before we conceive a child, uh, it is important that we bang out a parenting contract.", "order": 33551, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 16, "scene": 15, "line": "Of course.", "order": 33552, "speaker": "Angela Martin"},
  {"season": 6, "episode": 16, "scene": 15, "line": "It's been a long time since we've come down here separately.", "order": 33553, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 16, "scene": 15, "line": "You know I was thinking...", "order": 33554, "speaker": "Angela Martin"},
  {"season": 6, "episode": 16, "scene": 15, "line": "Now, then. Let's get to it, shall we? Item one: [speaking into a voice recorder] Child will be breast fed by the mother for exactly six months, then weaned onto a nutrient-rich winter vegetable mash provided by the father, Dwight Schrute, hereafter referred to as Morpheus. Agreed?", "order": 33555, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 16, "scene": 15, "line": "Agreed.", "order": 33556, "speaker": "Angela Martin"},
  {"season": 6, "episode": 16, "scene": 16, "line": "[as Pam has another contraction] Where are we? We have every six minutes, ladies and gentlemen. Another seventy five contractions and you are going to be there.", "order": 33557, "speaker": "Michael Scott"},
  {"season": 6, "episode": 16, "scene": 16, "line": "For the love of God, Pam, do it for ultra feast!", "order": 33558, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 16, "scene": 16, "line": "Okay, you know what? I'm going to go give, uh, doctor Asmani a quick call. He'd probably know...", "order": 33559, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 16, "scene": 16, "line": "Jim, please. Happy thoughts here. Happy times.", "order": 33560, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 16, "scene": 16, "line": "Yeah, happy times. Come on. Let's have happy times.", "order": 33561, "speaker": "Michael Scott"},
  {"season": 6, "episode": 16, "scene": 16, "line": "Alright.", "order": 33562, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 16, "scene": 16, "line": "Jim, as a matter of fact, I have printed out ten ways to induce labor. And I'm thinking we just do the opposite of those things and we can slow down your labor. Erin, read the first one.", "order": 33563, "speaker": "Michael Scott"},
  {"season": 6, "episode": 16, "scene": 16, "line": "Um, stimulate the nipples.", "order": 33564, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 16, "scene": 16, "line": "Okay, nobody touch Pam's nipples. Think of Pam's nipples as Toby's grundle.", "order": 33565, "speaker": "Michael Scott"},
  {"season": 6, "episode": 16, "scene": 16, "line": "Her shirt is touching them. Maybe we should cut holes in her shirt.", "order": 33566, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 16, "scene": 16, "line": "I have a shirt like that in my car.", "order": 33567, "speaker": "Meredith Palmer"},
  {"season": 6, "episode": 16, "scene": 16, "line": "Okay, yeah, why don't you go get it?", "order": 33568, "speaker": "Michael Scott"},
  {"season": 6, "episode": 16, "scene": 16, "line": "Okay, uh, the second one is walk around. We're already doing the opposite of that. Perfect. Okay, number three, eat spicy foods.", "order": 33569, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 16, "scene": 16, "line": "Okay, the opposite of that?", "order": 33570, "speaker": "Michael Scott"},
  {"season": 6, "episode": 16, "scene": 16, "line": "Stick spicy food up her butt.", "order": 33571, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 16, "scene": 16, "line": "Nope, nope, nope, nope. Come on, let's go to the hospital. Pam, let's go to the hospital right now.", "order": 33572, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 16, "scene": 16, "line": "Jim, Jim, honey, I love you, but you're really distracting me from my distractions.", "order": 33573, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 16, "scene": 16, "line": "Mm-hmm. Okay, great. Well, sorry.", "order": 33574, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 16, "scene": 16, "line": "Why don't you go do some work?", "order": 33575, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 16, "scene": 16, "line": "Great. I will do that. Sorry, Pam, I just feel a little bit frazzled. And you know how very rarely I use that word. Frazzled.", "order": 33576, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 16, "scene": 16, "line": "I know, you don't like to be frazzled.", "order": 33577, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 16, "scene": 16, "line": "No, I don't.", "order": 33578, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 16, "scene": 16, "line": "Okay.", "order": 33579, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 16, "scene": 16, "line": "Oh, and by the way, hate that you're helping her with this right now. Totally.", "order": 33580, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 16, "scene": 16, "line": "Ooh, someone's freakin'.", "order": 33581, "speaker": "Michael Scott"},
  {"season": 6, "episode": 16, "scene": 16, "line": "A little frazzled.", "order": 33582, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 16, "scene": 16, "line": "I think he is.", "order": 33583, "speaker": "Michael Scott"},
  {"season": 6, "episode": 16, "scene": 17, "line": "I know Pam better than anyone in this office, and obviously she's gone crazy, but everybody wants to say that I'm crazy. But I'm not crazy, she's crazy. I'm not crazy, she's crazy. [reading from various books] Five to seven minutes. Five to seven minutes. Six minutes. Different, but not really. Five to seven minutes.", "order": 33584, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 16, "scene": 18, "line": "Acceptable names include and are limited to: Ebenezer.", "order": 33585, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 16, "scene": 18, "line": "Jedediah.", "order": 33586, "speaker": "Angela Martin"},
  {"season": 6, "episode": 16, "scene": 18, "line": "Jonas.", "order": 33587, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 16, "scene": 18, "line": "Jedediah.", "order": 33588, "speaker": "Angela Martin"},
  {"season": 6, "episode": 16, "scene": 18, "line": "Warf.", "order": 33589, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 16, "scene": 18, "line": "No Star Trek names.", "order": 33590, "speaker": "Angela Martin"},
  {"season": 6, "episode": 16, "scene": 18, "line": "Okay. Fine.", "order": 33591, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 16, "scene": 18, "line": "What if it's a girl?", "order": 33592, "speaker": "Angela Martin"},
  {"season": 6, "episode": 16, "scene": 18, "line": "Irrelevant question. Section 5A, child shall be male.", "order": 33593, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 16, "scene": 18, "line": "Hey. Uh-uh. I cannot control that. You can't put that in here.", "order": 33594, "speaker": "Angela Martin"},
  {"season": 6, "episode": 16, "scene": 18, "line": "Yes you can.", "order": 33595, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 16, "scene": 18, "line": "No.", "order": 33596, "speaker": "Angela Martin"},
  {"season": 6, "episode": 16, "scene": 18, "line": "It's as simple as keeping the womb extremely warm for two days after sex, and then extremely cold for five months.", "order": 33597, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 16, "scene": 18, "line": "Absolutely not.", "order": 33598, "speaker": "Angela Martin"},
  {"season": 6, "episode": 16, "scene": 19, "line": "[Pam approaches as he sits in his car] Hey.", "order": 33599, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 16, "scene": 19, "line": "Hey. I'm not going to get in the car, because I know if I do you'll try to drive me to the hospital.", "order": 33600, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 16, "scene": 19, "line": "Ah, you know me too well.", "order": 33601, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 16, "scene": 19, "line": "Okay, Jim.", "order": 33602, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 16, "scene": 19, "line": "Yeah? ... Oh.", "order": 33603, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 16, "scene": 19, "line": "Everything is fine. You don't have to worry. Try not to think about it. She's not coming out for a while, okay?", "order": 33604, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 16, "scene": 19, "line": "Did you say 'she?'", "order": 33605, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 16, "scene": 19, "line": "I called the doctor like a week ago. I couldn't wait... Oh, God, don't be mad.", "order": 33606, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 16, "scene": 19, "line": "Mad? How could I be mad? We're having a little girl.", "order": 33607, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 16, "scene": 19, "line": "Mm-hmm.", "order": 33608, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 16, "scene": 19, "line": "Wow, we're having a little girl. Oh, man.", "order": 33609, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 16, "scene": 19, "line": "I know.", "order": 33610, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 16, "scene": 19, "line": "Woo, alright. Well, I definitely feel better.", "order": 33611, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 16, "scene": 19, "line": "Good.", "order": 33612, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 16, "scene": 19, "line": "Yeah.", "order": 33613, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 16, "scene": 19, "line": "Okay.", "order": 33614, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 16, "scene": 19, "line": "Alright... Hey, did you change?", "order": 33615, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 16, "scene": 19, "line": "Oh, yeah, my water broke.", "order": 33616, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 16, "scene": 19, "line": "Oh. Oh...", "order": 33617, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 16, "scene": 20, "line": "[enters the office and has another contraction] Oh, whoa. Ha. Wow.", "order": 33618, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 16, "scene": 20, "line": "Pam, Pam! Okay, alright, okay, it's time. Time to go to the hospital. Somebody get Jim, please! Is it midnight yet?", "order": 33619, "speaker": "Michael Scott"},
  {"season": 6, "episode": 16, "scene": 20, "line": "No, it's 4:35.", "order": 33620, "speaker": "Phyllis Vance"},
  {"season": 6, "episode": 16, "scene": 20, "line": "4:35. Alright, almost made it. Almost made it. Too bad you didn't have sex like seven and a half hours later. But you had to have the afternoon delight. I understand. Sometimes you have to go for it. Let's go to the hospital, shall we?", "order": 33621, "speaker": "Michael Scott"},
  {"season": 6, "episode": 16, "scene": 20, "line": "No, not yet, Michael.", "order": 33622, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 16, "scene": 20, "line": "We can do...", "order": 33623, "speaker": "Michael Scott"},
  {"season": 6, "episode": 16, "scene": 20, "line": "Oh, wow, it's almost time for ultra feast! Where's Kevin?", "order": 33624, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 16, "scene": 20, "line": "Oh. What? You want to eat cat food with Kevin and not go to the hospital?", "order": 33625, "speaker": "Michael Scott"},
  {"season": 6, "episode": 16, "scene": 20, "line": "That's fancy feast. Ultra feast is something they made up so they can pig out together in the name of ceremony.", "order": 33626, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 16, "scene": 20, "line": "What is October feast?", "order": 33627, "speaker": "Michael Scott"},
  {"season": 6, "episode": 16, "scene": 21, "line": "There is no rush to get to the hospital. I am fine. I'll get there. And if I don't get there, I don't get there.", "order": 33628, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 16, "scene": 22, "line": "Our ultra feast menu's theme: Hollywood. We have Ratatouille, from Ratatouille, and tandoori chicken, from Born Into Brothels. I tried to bake a cake like that District 9 prawn thing, but I... Are you okay?", "order": 33629, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 16, "scene": 22, "line": "Mm-hmm. Yes, I'm fine. Um, yeah, the doctor said it's still considered a minor contraction as long as I can talk through it.", "order": 33630, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 16, "scene": 22, "line": "Okay, okay, Jim. I think this feast is over and it's time to go to the hospital.", "order": 33631, "speaker": "Michael Scott"},
  {"season": 6, "episode": 16, "scene": 22, "line": "Alright. Time to go. Okay.", "order": 33632, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 16, "scene": 22, "line": "That's right, let's do this.", "order": 33633, "speaker": "Michael Scott"},
  {"season": 6, "episode": 16, "scene": 22, "line": "Let's give it a shot.", "order": 33634, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 16, "scene": 22, "line": "Nope. No, no, that's better. That wasn't even the worst of them. I'm fine.", "order": 33635, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 16, "scene": 22, "line": "Hey, come on. Let's go to the hospital.", "order": 33636, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 16, "scene": 22, "line": "They're not that bad still, babe.", "order": 33637, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 16, "scene": 22, "line": "Pam, Pam, it's time. Let's go to the hospital.", "order": 33638, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 16, "scene": 22, "line": "No, it's passing, it's fine. It's okay.", "order": 33639, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 16, "scene": 22, "line": "Come on, Michael says we should go now.", "order": 33640, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 16, "scene": 22, "line": "Let's go, Pam. Yeah, I think we should head out.", "order": 33641, "speaker": "Michael Scott"},
  {"season": 6, "episode": 16, "scene": 22, "line": "No, it's passing, it's fine.", "order": 33642, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 16, "scene": 22, "line": "Pam.", "order": 33643, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 16, "scene": 22, "line": "Ah, no, it passed. It's good.", "order": 33644, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 16, "scene": 22, "line": "You know what? Let's go. We got to go to the hospital.", "order": 33645, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 16, "scene": 22, "line": "Okay, I'm not going. I'm not... It's fine. [Jim, Michael and Kevin try to help her up] Okay, come on, come on. No! I am not going! I am not going, okay!? I'm not going today because I can't do it, I don't think I can do it.", "order": 33646, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 16, "scene": 22, "line": "Hey, are you kidding me?", "order": 33647, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 16, "scene": 22, "line": "Are you kidding?", "order": 33648, "speaker": "Michael Scott"},
  {"season": 6, "episode": 16, "scene": 22, "line": "If anyone can do this, you can do this.", "order": 33649, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 16, "scene": 22, "line": "You can do this. You can do this.", "order": 33650, "speaker": "Michael Scott"},
  {"season": 6, "episode": 16, "scene": 22, "line": "Pam, I'm scared. I'm real scared.", "order": 33651, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 16, "scene": 22, "line": "I'm scared, too.", "order": 33652, "speaker": "Michael Scott"},
  {"season": 6, "episode": 16, "scene": 22, "line": "I'm petrified.", "order": 33653, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 16, "scene": 22, "line": "The best news is, we're going to have a baby today.", "order": 33654, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 16, "scene": 22, "line": "Yeah.", "order": 33655, "speaker": "Michael Scott"},
  {"season": 6, "episode": 16, "scene": 22, "line": "A really awesome baby.", "order": 33656, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 16, "scene": 22, "line": "We're going to have a baby.", "order": 33657, "speaker": "Michael Scott"},
  {"season": 6, "episode": 16, "scene": 22, "line": "So let's have it at the hospital.", "order": 33658, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 16, "scene": 22, "line": "Let's do that.", "order": 33659, "speaker": "Michael Scott"},
  {"season": 6, "episode": 16, "scene": 22, "line": "How are we doing on contractions?", "order": 33660, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 16, "scene": 22, "line": "Two minutes apart.", "order": 33661, "speaker": "Michael Scott"},
  {"season": 6, "episode": 16, "scene": 22, "line": "Two minutes...", "order": 33662, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 16, "scene": 22, "line": "Oh, God. Oh, no.", "order": 33663, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 16, "scene": 22, "line": "Michael, I told you.", "order": 33664, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 16, "scene": 22, "line": "It's okay.", "order": 33665, "speaker": "Michael Scott"},
  {"season": 6, "episode": 16, "scene": 22, "line": "No, I told you to warn me at five minutes.", "order": 33666, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 16, "scene": 22, "line": "Jim, we waited too long!", "order": 33667, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 16, "scene": 22, "line": "I know, I know, I know. It went by too soon.", "order": 33668, "speaker": "Michael Scott"},
  {"season": 6, "episode": 16, "scene": 22, "line": "We waited too long! Two minutes doesn't do us any good. Well, what happened to four and three minutes?", "order": 33669, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 16, "scene": 22, "line": "We're okay, Jim! Okay, Pamela. You know what time it is?", "order": 33670, "speaker": "Michael Scott"},
  {"season": 6, "episode": 16, "scene": 22, "line": "I don't want to have my baby here.", "order": 33671, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 16, "scene": 22, "line": "You're not going to. You know where you're going?", "order": 33672, "speaker": "Michael Scott"},
  {"season": 6, "episode": 16, "scene": 22, "line": "The hospital.", "order": 33673, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 16, "scene": 22, "line": "Yes, you are. And you know what you're going to have?", "order": 33674, "speaker": "Michael Scott"},
  {"season": 6, "episode": 16, "scene": 22, "line": "A baby.", "order": 33675, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 16, "scene": 22, "line": "Yes! We're going to the hospital and we're going to have a baby.", "order": 33676, "speaker": "Michael Scott"},
  {"season": 6, "episode": 16, "scene": 23, "line": "I got it! I got it! Everybody it's go time! At your stations. Stanley, man the phones. Meredith, please, get bottled water. Erin, call an ambulance, please!", "order": 33677, "speaker": "Michael Scott"},
  {"season": 6, "episode": 16, "scene": 23, "line": "No, no, no. Ambulances are emergencies only. You call an ambulance, I call the cops.", "order": 33678, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 16, "scene": 23, "line": "Alright, we're driving ourselves, actually.", "order": 33679, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 16, "scene": 23, "line": "No, Jim, you are in no condition to drive. I will drive you. Check! Got it.", "order": 33680, "speaker": "Michael Scott"},
  {"season": 6, "episode": 16, "scene": 23, "line": "Alright, I have my wallet.", "order": 33681, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 16, "scene": 23, "line": "Yes.", "order": 33682, "speaker": "Michael Scott"},
  {"season": 6, "episode": 16, "scene": 23, "line": "Go bag's in the car... Keys, my keys, where's my keys?", "order": 33683, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 16, "scene": 23, "line": "Go bag! Where's my go bag? Where's my go bag?", "order": 33684, "speaker": "Michael Scott"},
  {"season": 6, "episode": 16, "scene": 23, "line": "There's nothing in it.", "order": 33685, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 16, "scene": 23, "line": "You are telling me now that there is nothing in it. Okay, great! Oh, hey, hey, um, should I bring a dictionary to the hospital?", "order": 33686, "speaker": "Michael Scott"},
  {"season": 6, "episode": 16, "scene": 23, "line": "The hospital provides dictionaries, bring a thesaurus!", "order": 33687, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 16, "scene": 23, "line": "Has anyone checked how dilated she is? This is ridiculous!", "order": 33688, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 16, "scene": 23, "line": "Dwight, get away!", "order": 33689, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 16, "scene": 23, "line": "No, no, no. Dwight, let Jim do that, please.", "order": 33690, "speaker": "Michael Scott"},
  {"season": 6, "episode": 16, "scene": 23, "line": "I didn't know we had a tape measure.", "order": 33691, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 16, "scene": 23, "line": "[as he holds up the tape measure with his initials on it] 'We' don't.", "order": 33692, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 16, "scene": 23, "line": "Okay, I can't find my keys! I cannot find my keys! Found 'em. They're here.", "order": 33693, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 16, "scene": 23, "line": "Here we go!", "order": 33694, "speaker": "Dwight Schrute, Jim Halpert & Michael Scott"},
  {"season": 6, "episode": 16, "scene": 23, "line": "Good luck!", "order": 33695, "speaker": "Phyllis Vance"},
  {"season": 6, "episode": 16, "scene": 23, "line": "Good luck, Pam!", "order": 33696, "speaker": "Nick"},
  {"season": 6, "episode": 16, "scene": 23, "line": "Thank you! Wish me luck!", "order": 33697, "speaker": "Michael Scott"},
  {"season": 6, "episode": 16, "scene": 23, "line": "Have fun! [sigh]", "order": 33698, "speaker": "Creed Bratton"},
  {"season": 6, "episode": 16, "scene": 23, "line": "Hey, it's 5:00!", "order": 33699, "speaker": "Meredith Palmer"},
  {"season": 6, "episode": 16, "scene": 24, "line": "[entering the elevator] Here we go! Here we go! On our way!", "order": 33700, "speaker": "Michael Scott"},
  {"season": 6, "episode": 16, "scene": 24, "line": "Hold it!", "order": 33701, "speaker": "Stanley Hudson"},
  {"season": 6, "episode": 16, "scene": 24, "line": "Come on, Stanley! Okay. We're going now!", "order": 33702, "speaker": "Michael Scott"},
  {"season": 6, "episode": 16, "scene": 24, "line": "Oh, one more!", "order": 33703, "speaker": "Toby Flenderson"},
  {"season": 6, "episode": 16, "scene": 24, "line": "No, no, no, no! Out, out! Idiot.", "order": 33704, "speaker": "Michael Scott"},
  {"season": 6, "episode": 16, "scene": 25, "line": "Dwight, what is the traffic like?", "order": 33705, "speaker": "Michael Scott"},
  {"season": 6, "episode": 16, "scene": 25, "line": "Doesn't matter. I'll escort you!", "order": 33706, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 16, "scene": 25, "line": "Alright.", "order": 33707, "speaker": "Michael Scott"},
  {"season": 6, "episode": 16, "scene": 25, "line": "Let's go!", "order": 33708, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 16, "scene": 25, "line": "Geesh, Dwight!", "order": 33709, "speaker": "Michael Scott"},
  {"season": 6, "episode": 16, "scene": 25, "line": "[as he peels out of the parking lot and stops] Michael!", "order": 33710, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 16, "scene": 25, "line": "What!?", "order": 33711, "speaker": "Michael Scott"},
  {"season": 6, "episode": 16, "scene": 25, "line": "This is where I saw that deer last week.", "order": 33712, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 16, "scene": 25, "line": "Where?", "order": 33713, "speaker": "Michael Scott"},
  {"season": 6, "episode": 16, "scene": 25, "line": "Right over by that fence.", "order": 33714, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 16, "scene": 25, "line": "By the bushes?", "order": 33715, "speaker": "Michael Scott"},
  {"season": 6, "episode": 16, "scene": 25, "line": "Okay, Michael! Focus!", "order": 33716, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 16, "scene": 25, "line": "Okay, go, go, go, go!", "order": 33717, "speaker": "Michael Scott"},
  {"season": 6, "episode": 16, "scene": 25, "line": "Let's go!", "order": 33718, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 16, "scene": 26, "line": "I love escorting people. In fact, a few years back, I put an ad in the paper starting an escort service. I got a lot of responses. Mostly creeps. Made a few friends.", "order": 33719, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 16, "scene": 27, "line": "Alright, here we go! You're doing great! She'll be here soon.", "order": 33720, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 16, "scene": 27, "line": "Okay, just breathe... She? You found out? Come on, guys. I wanted to be surprised.", "order": 33721, "speaker": "Michael Scott"},
  {"season": 6, "episode": 16, "scene": 27, "line": "Michael!", "order": 33722, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 16, "scene": 27, "line": "Yeah?", "order": 33723, "speaker": "Michael Scott"},
  {"season": 6, "episode": 16, "scene": 27, "line": "Stop texting, put your phone away! Come on.", "order": 33724, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 16, "scene": 27, "line": "Michael, come on!", "order": 33725, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 16, "scene": 27, "line": "I'm texting about you, okay!?", "order": 33726, "speaker": "Michael Scott"},
  {"season": 6, "episode": 16, "scene": 28, "line": "Ugh, alright. [as he puts a police siren on top of his car] Let's move! ... What?", "order": 33727, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 16, "scene": 28, "line": "Pull over!", "order": 33728, "speaker": "Policeman"},
  {"season": 6, "episode": 16, "scene": 28, "line": "Are you kidding me?", "order": 33729, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 16, "scene": 28, "line": "Pull over! [Dwight begins throwing various weapons out of the window] You're not allowed to impersonate a police officer! Don't make this difficult, Dwight!", "order": 33730, "speaker": "Policeman"},
  {"season": 6, "episode": 16, "scene": 29, "line": "Wait, wait. My iPod's not in here!", "order": 33731, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 16, "scene": 29, "line": "Okay.", "order": 33732, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 16, "scene": 29, "line": "It has the birth song on it!", "order": 33733, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 16, "scene": 29, "line": "Okay. I know, I know. But my iPod is in the go bag. We'll be fine.", "order": 33734, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 16, "scene": 29, "line": "Jim, I don't want the first thing the baby hears to be the 8 Mile soundtrack.", "order": 33735, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 16, "scene": 29, "line": "Okay, so what do you want to do?", "order": 33736, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 16, "scene": 29, "line": "I don't know! Let's go by the house and get it. It's only twenty minutes past the hospital!", "order": 33737, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 16, "scene": 29, "line": "Pam, no! Are you nuts? We're going to the hospital now.", "order": 33738, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 16, "scene": 29, "line": "Guys, guys! Stop fighting, come on. Come on. Do you want your kid to come out a lawyer? Right? Okay, you know what? I am all over this. Here we go. [as he calls Dwight] Ready?", "order": 33739, "speaker": "Michael Scott"},
  {"season": 6, "episode": 16, "scene": 29, "line": "Dwight Schrute.", "order": 33740, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 16, "scene": 29, "line": "Hello, Dwight. Pam left her iPod at her house. I want you to swing by, pick it up, and bring it to the hospital. We need it yesterday.", "order": 33741, "speaker": "Michael Scott"},
  {"season": 6, "episode": 16, "scene": 29, "line": "Why didn't you ask me to do it yesterday? I kept IM'ing you how bored I was.", "order": 33742, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 16, "scene": 29, "line": "Okay.", "order": 33743, "speaker": "Michael Scott"},
  {"season": 6, "episode": 16, "scene": 29, "line": "Dwight! Go to my house. Get my iPod. I think it's on the kitchen table. Do not touch anything else. The key is under...", "order": 33744, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 16, "scene": 29, "line": "I don't need a key.", "order": 33745, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 16, "scene": 29, "line": "Okay, Dwight, but if you do need a key, just listen it's under the...", "order": 33746, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 16, "scene": 29, "line": "No, no, don't, don't tell me. Alalalalalalalalalala lalalalalala alalalalalalalala.", "order": 33747, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 16, "scene": 29, "line": "Dwight just listen! It's underneath... [Dwight hangs up as the policeman hands him a ticket]", "order": 33748, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 16, "scene": 30, "line": "Okay.", "order": 33749, "speaker": "Michael Scott"},
  {"season": 6, "episode": 16, "scene": 30, "line": "Wait, you alright?", "order": 33750, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 16, "scene": 30, "line": "Yes.", "order": 33751, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 16, "scene": 30, "line": "Do you have everything, guys?", "order": 33752, "speaker": "Michael Scott"},
  {"season": 6, "episode": 16, "scene": 30, "line": "Michael, just go park the car.", "order": 33753, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 16, "scene": 30, "line": "Okay, alright. [he parks in an ambulance zone]", "order": 33754, "speaker": "Michael Scott"},
  {"season": 6, "episode": 16, "scene": 30, "line": "Sir! You can't park here!", "order": 33755, "speaker": "Hospital employee"},
  {"season": 6, "episode": 16, "scene": 30, "line": "Dunder-Mifflin. It's okay. [he throws the keys into bushes across the street] I just did.", "order": 33756, "speaker": "Michael Scott"},
  {"season": 6, "episode": 16, "scene": 31, "line": "Pam's doing great. Uh, she's ten inches dilated now. Uh, sorry, meters. Centimeters. And she's also fully faced. Which I don't know what that is, uh, but no baby yet. It's only been six, uh, nineteen hours, and uh, I just went out for some ice chips because I might have passed out a little bit, but these are very refreshing, very good.", "order": 33757, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 16, "scene": 31, "line": "Daddy? She's ready to push.", "order": 33758, "speaker": "Nurse"},
  {"season": 6, "episode": 16, "scene": 31, "line": "Okay.", "order": 33759, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 16, "scene": 31, "line": "Where is my little nibblet? Halpert, room D1. Alright, family only beyond this point, thank you. Here we go.", "order": 33760, "speaker": "Michael Scott"},
  {"season": 6, "episode": 16, "scene": 31, "line": "[as Pam screams] Doing great, push again.", "order": 33761, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 16, "scene": 31, "line": "Not yet.", "order": 33762, "speaker": "Doctor"},
  {"season": 6, "episode": 16, "scene": 31, "line": "No, don't push. Pull. Pull.", "order": 33763, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 16, "scene": 31, "line": "Why don't you get more ice chips?", "order": 33764, "speaker": "Nurse"},
  {"season": 6, "episode": 16, "scene": 31, "line": "No, Jim, stay!", "order": 33765, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 16, "scene": 31, "line": "Okay. Really push this time, Pam.", "order": 33766, "speaker": "Doctor"},
  {"season": 6, "episode": 16, "scene": 31, "line": "Okay.", "order": 33767, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 16, "scene": 31, "line": "[looking horrified as he walks from the room back into the lobby] Okay, not yet, not yet. I'm going to go wash my eyes.", "order": 33768, "speaker": "Michael Scott"},
  {"season": 6, "episode": 16, "scene": 32, "line": "That kid's going to have a lot of hair.", "order": 33769, "speaker": "Michael Scott"},
  {"season": 6, "episode": 16, "scene": 33, "line": "Hey, have you guys seen her?", "order": 33770, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 16, "scene": 33, "line": "She hasn't popped yet.", "order": 33771, "speaker": "Meredith Palmer"},
  {"season": 6, "episode": 16, "scene": 33, "line": "What? God damn it. She was supposed to come out yesterday.", "order": 33772, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 16, "scene": 34, "line": "I decided to give baby Halpert a newspaper from the day she was born. This frame set me back fifty five bones. But she decided to take her sweet time, so now I have to switch it with today's paper.", "order": 33773, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 16, "scene": 35, "line": "This is ridiculous. We just can't wait here.", "order": 33774, "speaker": "Phyllis Vance"},
  {"season": 6, "episode": 16, "scene": 35, "line": "I think it's going to be any minute now.", "order": 33775, "speaker": "Michael Scott"},
  {"season": 6, "episode": 16, "scene": 35, "line": "But you don't know that. I mean, we could be here another half an hour.", "order": 33776, "speaker": "Phyllis Vance"},
  {"season": 6, "episode": 16, "scene": 35, "line": "Phyllis, what could you possibly have to do?", "order": 33777, "speaker": "Michael Scott"},
  {"season": 6, "episode": 16, "scene": 35, "line": "I have an ice cream cake in the car.", "order": 33778, "speaker": "Phyllis Vance"},
  {"season": 6, "episode": 16, "scene": 35, "line": "Oh, my God. Go, go, go! Are you insane? Alright.", "order": 33779, "speaker": "Michael Scott"},
  {"season": 6, "episode": 16, "scene": 36, "line": "[Pam screams as he approaches the door to their room] Guys? Hello? Um, sorry to be a bother, but if we could have an ETA when this is gonna...", "order": 33780, "speaker": "Michael Scott"},
  {"season": 6, "episode": 16, "scene": 36, "line": "This is happening!", "order": 33781, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 16, "scene": 36, "line": "You're starting, you're kinda losing them.", "order": 33782, "speaker": "Michael Scott"},
  {"season": 6, "episode": 16, "scene": 36, "line": "Oh, my God! Look at her! baby, she's so beautiful! Oh, my God.", "order": 33783, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 16, "scene": 36, "line": "Oh, my God.", "order": 33784, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 16, "scene": 37, "line": "[smoking a cigar] Yeah, that's right. It's a baby, see?", "order": 33785, "speaker": "Michael Scott"},
  {"season": 6, "episode": 16, "scene": 37, "line": "Sir! Sir, you can't smoke that in here. Put it, put it out.", "order": 33786, "speaker": "Doctor"},
  {"season": 6, "episode": 16, "scene": 37, "line": "Okay. You can't smoke anywhere these days.", "order": 33787, "speaker": "Michael Scott"},
  {"season": 6, "episode": 16, "scene": 38, "line": "[with Jim, holding the baby] She's incredible. Want to count her fingers and toes again?", "order": 33788, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 16, "scene": 38, "line": "No, let's let her rest. I'm sure there's still twelve on each.", "order": 33789, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 16, "scene": 38, "line": "Okay.", "order": 33790, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 16, "scene": 39, "line": "Guys, guys!", "order": 33791, "speaker": "Michael Scott"},
  {"season": 6, "episode": 16, "scene": 39, "line": "Her name is Cecelia Marie Halpert.", "order": 33792, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 16, "scene": 39, "line": "Eleven pounds...", "order": 33793, "speaker": "Michael Scott"},
  {"season": 6, "episode": 16, "scene": 39, "line": "She's seven pounds, two ounces, eighteen inches. Mother and daughter are doing great.", "order": 33794, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 16, "scene": 39, "line": "Yeah!", "order": 33795, "speaker": "Everyone"},
  {"season": 6, "episode": 16, "scene": 39, "line": "Thank you! Thank you!", "order": 33796, "speaker": "Michael Scott"},
  {"season": 6, "episode": 16, "scene": 39, "line": "Congratulations. That's great.", "order": 33797, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 16, "scene": 40, "line": "Hi there.", "order": 33798, "speaker": "Helene Beesly"},
  {"season": 6, "episode": 16, "scene": 40, "line": "Hey, grandma's back.", "order": 33799, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 16, "scene": 40, "line": "Well, it was an adventure and a half trying to find the cafeteria, but I have returned with the coffee.", "order": 33800, "speaker": "Helene Beesly"},
  {"season": 6, "episode": 16, "scene": 40, "line": "Great.", "order": 33801, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 16, "scene": 40, "line": "Oh, thank God. I haven't had caffeine in nine months.", "order": 33802, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 16, "scene": 40, "line": "Ooh, somebody has a full diaper.", "order": 33803, "speaker": "Helene Beesly"},
  {"season": 6, "episode": 16, "scene": 40, "line": "Oh. Let me get it.", "order": 33804, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 16, "scene": 41, "line": "I am a diapering master. I have done little else in the past two months. There is nothing I cannot diaper. Go ahead. Try to think of something. I dare you.", "order": 33805, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 16, "scene": 42, "line": "Where's the baby? I want to see the baby. Oh! Oh, Helene, hi. Oh, my goodness, what are the odds of this? Congratulations on being a grandma.", "order": 33806, "speaker": "Michael Scott"},
  {"season": 6, "episode": 16, "scene": 42, "line": "Hello, Michael.", "order": 33807, "speaker": "Helene Beesly"},
  {"season": 6, "episode": 16, "scene": 42, "line": "Hello. Oh, good for you. I worry about you.", "order": 33808, "speaker": "Michael Scott"},
  {"season": 6, "episode": 16, "scene": 42, "line": "I'm, uh... You know I think, uh, I think it's time for me to go.", "order": 33809, "speaker": "Helene Beesly"},
  {"season": 6, "episode": 16, "scene": 42, "line": "Okay.", "order": 33810, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 16, "scene": 42, "line": "Love you.", "order": 33811, "speaker": "Helene Beesly"},
  {"season": 6, "episode": 16, "scene": 42, "line": "Love you, as a friend.", "order": 33812, "speaker": "Michael Scott"},
  {"season": 6, "episode": 16, "scene": 42, "line": "Love you, mom.", "order": 33813, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 16, "scene": 42, "line": "Ooh, I want to hold the baby!", "order": 33814, "speaker": "Michael Scott"},
  {"season": 6, "episode": 16, "scene": 42, "line": "Okay, you just have to use the hand sanitizer first.", "order": 33815, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 16, "scene": 42, "line": "Alright.", "order": 33816, "speaker": "Michael Scott"},
  {"season": 6, "episode": 16, "scene": 42, "line": "Again, no pants.", "order": 33817, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 16, "scene": 42, "line": "Oh, okay.", "order": 33818, "speaker": "Michael Scott"},
  {"season": 6, "episode": 16, "scene": 42, "line": "Ready?", "order": 33819, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 16, "scene": 42, "line": "Mm-hmm. Oh. Ooh, wow. Michael. Michael.", "order": 33820, "speaker": "Michael Scott"},
  {"season": 6, "episode": 16, "scene": 42, "line": "It's so weird, she was saying it just before you got here.", "order": 33821, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 16, "scene": 43, "line": "Yesterday, I was dispatched to Jim and Pam's house to find Pam's iPod. I searched everywhere, but I didn't find it. What I did find, was mold, and lots of it. So, I did what anyone would do. Read a book, had a bath, I got a good night's sleep, and I made plans to eradicate it. I also made plans to ask Jim where he bought his marvelous sheets... Time to get to work [he begins to destroy the kitchen with a sledgehammer].", "order": 33822, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 16, "scene": 44, "line": "There she sat. Her name was Pam. She was a receptionist. She was engaged to an animal. There sat Jim. He was a gawky, tall salesman. The odds of them getting together were insur-mountain-able. I made a family! I got these two together, and I made a family.", "order": 33823, "speaker": "Michael Scott"},
  {"season": 6, "episode": 16, "scene": 44, "line": "This man has a gift!", "order": 33824, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 16, "scene": 44, "line": "Who else here is single?", "order": 33825, "speaker": "Michael Scott"},
  {"season": 6, "episode": 16, "scene": 44, "line": "I'm not single. I have a man. [she grasps Ryan's arm as Ryan has his hand raised to say he's single]", "order": 33826, "speaker": "Kelly Kapoor"},
  {"season": 6, "episode": 16, "scene": 44, "line": "I am offering up my services to you all. You saw what I did with Pam and Jim. I can help you, too.", "order": 33827, "speaker": "Michael Scott"},
  {"season": 6, "episode": 16, "scene": 44, "line": "Why do you find someone for yourself instead of meddling in our affairs?", "order": 33828, "speaker": "Stanley Hudson"},
  {"season": 6, "episode": 16, "scene": 44, "line": "Okay, show of hands. Who wants to live in a world where Stanley has two lovers and you don't have any? [Stanley raises his hand] Who else? Come on! People, I know models!", "order": 33829, "speaker": "Michael Scott"},
  {"season": 6, "episode": 16, "scene": 44, "line": "Ha ha, plus size models, maybe.", "order": 33830, "speaker": "Ryan Howard"},
  {"season": 6, "episode": 16, "scene": 44, "line": "Ha ha, you got that right! Meredith? Come on, you're obviously single.", "order": 33831, "speaker": "Michael Scott"},
  {"season": 6, "episode": 16, "scene": 44, "line": "You know it. I am never getting married.", "order": 33832, "speaker": "Meredith Palmer"},
  {"season": 6, "episode": 16, "scene": 45, "line": "Like Clooney.", "order": 33833, "speaker": "Meredith Palmer"},
  {"season": 6, "episode": 16, "scene": 46, "line": "Andrew, what about you?", "order": 33834, "speaker": "Michael Scott"},
  {"season": 6, "episode": 16, "scene": 46, "line": "Torn scrotum. Still on the mend, so not good timing.", "order": 33835, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 16, "scene": 47, "line": "Yes, I'm going to ask out Erin. I'm just waiting for the stars to align. Literally. I have a small skylight in my bedroom, and I'd like for the moon to be visible.", "order": 33836, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 16, "scene": 48, "line": "Well, when you least expect it, expect it. I am going to fill the empty voids in your life with love. I am going to fill that empty hole in your body with another person. And, like Cupid, I am going to shoot you with love.", "order": 33837, "speaker": "Michael Scott"},
  {"season": 6, "episode": 16, "scene": 49, "line": "Remember yesterday when we were terrified of being parents?", "order": 33838, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 16, "scene": 49, "line": "We were just kids. What did we know?", "order": 33839, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 16, "scene": 49, "line": "How we doing?", "order": 33840, "speaker": "Nurse"},
  {"season": 6, "episode": 16, "scene": 49, "line": "Great.", "order": 33841, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 16, "scene": 49, "line": "Good.", "order": 33842, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 16, "scene": 49, "line": "Would you like me to take her to the nursery for the night?", "order": 33843, "speaker": "Nurse"},
  {"season": 6, "episode": 16, "scene": 49, "line": "Doesn't she sleep here?", "order": 33844, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 16, "scene": 49, "line": "She can. But a lot of parents choose to have the baby spend the first night in the nursery to get some rest. You've been through a lot.", "order": 33845, "speaker": "Nurse"},
  {"season": 6, "episode": 16, "scene": 49, "line": "I think we'll be okay.", "order": 33846, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 16, "scene": 49, "line": "Okay, great.", "order": 33847, "speaker": "Nurse"},
  {"season": 6, "episode": 16, "scene": 49, "line": "[as the baby yawns] Oh, big yawn.", "order": 33848, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 16, "scene": 50, "line": "Ready? One, two, three...", "order": 33849, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 16, "scene": 50, "line": "One, and then wrap around. It's okay. Hold that arm down.", "order": 33850, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 16, "scene": 50, "line": "I can't. She's too strong. She's, careful, though. You don't want to break it.", "order": 33851, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 16, "scene": 50, "line": "She's not gonna... Just...", "order": 33852, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 16, "scene": 50, "line": "I got it. Alright.", "order": 33853, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 16, "scene": 50, "line": "Go.", "order": 33854, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 16, "scene": 50, "line": "Nurse!", "order": 33855, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 16, "scene": 51, "line": "How you doing?", "order": 33856, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 16, "scene": 51, "line": "I don't know. I just, I can't tell if she's getting anything.", "order": 33857, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 16, "scene": 51, "line": "Really?", "order": 33858, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 16, "scene": 51, "line": "Doesn't feel right.", "order": 33859, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 16, "scene": 51, "line": "Well, you're pushing the milk out, right?", "order": 33860, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 16, "scene": 51, "line": "How does one do that?", "order": 33861, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 16, "scene": 51, "line": "Wasn't it... It's kinda like a... Like that.", "order": 33862, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 16, "scene": 51, "line": "Do you want to try it, Jim?", "order": 33863, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 16, "scene": 51, "line": "I think you're good. Doing a good job.", "order": 33864, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 16, "scene": 51, "line": "Somebody buzzed?", "order": 33865, "speaker": "Nurse"},
  {"season": 6, "episode": 16, "scene": 51, "line": "Oh! Really? Must have sat on it, Pam.", "order": 33866, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 16, "scene": 51, "line": "I can't tell if she's getting anything. It just doesn't really feel right.", "order": 33867, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 16, "scene": 51, "line": "Well, maybe we should take a break for a little while. I can take her to the nursery and then bring her back and try again a little bit later.", "order": 33868, "speaker": "Nurse"},
  {"season": 6, "episode": 16, "scene": 51, "line": "Even if she's not getting anything?", "order": 33869, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 16, "scene": 51, "line": "Yeah, she'll be fine. I can always give her a bottle since we're in the nursery.", "order": 33870, "speaker": "Nurse"},
  {"season": 6, "episode": 16, "scene": 51, "line": "No. I read in the book about nipple confusion.", "order": 33871, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 16, "scene": 51, "line": "Oh, good. You know everything.", "order": 33872, "speaker": "Nurse"},
  {"season": 6, "episode": 16, "scene": 51, "line": "She's just, she's really tentative about latching, and I just, I want to keep her self-esteem up.", "order": 33873, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 16, "scene": 51, "line": "Well, I'll bring her back in a little while and we can try again, okay?", "order": 33874, "speaker": "Nurse"},
  {"season": 6, "episode": 16, "scene": 51, "line": "Alright.", "order": 33875, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 16, "scene": 51, "line": "Alright.", "order": 33876, "speaker": "Nurse"},
  {"season": 6, "episode": 16, "scene": 51, "line": "It's going to be alright.", "order": 33877, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 16, "scene": 51, "line": "Maybe it'll be good because then she can like socialize with the other babies.", "order": 33878, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 16, "scene": 51, "line": "Ha ha. No, yeah, that will be good.", "order": 33879, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 16, "scene": 52, "line": "[in Michael's office] You wanted to see me?", "order": 33880, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 16, "scene": 52, "line": "What would you say, if I told you that I was about to change your life?", "order": 33881, "speaker": "Michael Scott"},
  {"season": 6, "episode": 16, "scene": 52, "line": "Oh, boy! ... What's that sound?", "order": 33882, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 16, "scene": 52, "line": "[as he appears from behind the door] Ta-da!", "order": 33883, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 16, "scene": 52, "line": "I would like you to meet your new boyfriend.", "order": 33884, "speaker": "Michael Scott"},
  {"season": 6, "episode": 16, "scene": 52, "line": "Yes!", "order": 33885, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 16, "scene": 52, "line": "I don't know what to say.", "order": 33886, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 16, "scene": 52, "line": "Oh, say nothing. You will learn to love me.", "order": 33887, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 16, "scene": 52, "line": "Okay, hold on. Whoa, whoa, whoa. You got to let the cookies cool before you pop 'em in your mouth. Why don't you guys get to know each other? Maybe have lunch together?", "order": 33888, "speaker": "Michael Scott"},
  {"season": 6, "episode": 16, "scene": 52, "line": "Erin, would you have lunch with me?", "order": 33889, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 16, "scene": 52, "line": "Okay.", "order": 33890, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 16, "scene": 52, "line": "Good!", "order": 33891, "speaker": "Michael Scott"},
  {"season": 6, "episode": 16, "scene": 52, "line": "Yes!", "order": 33892, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 16, "scene": 52, "line": "Michael, could I talk to you privately?", "order": 33893, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 16, "scene": 52, "line": "Sure. Kevin, please leave.", "order": 33894, "speaker": "Michael Scott"},
  {"season": 6, "episode": 16, "scene": 52, "line": "Bye.", "order": 33895, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 16, "scene": 52, "line": "Bye.", "order": 33896, "speaker": "Michael Scott"},
  {"season": 6, "episode": 16, "scene": 52, "line": "I'm so sorry if I gave you the impression I'm into Kevin, but I'm not. I like Andy.", "order": 33897, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 16, "scene": 52, "line": "Okay. This is going to kill Kevin.", "order": 33898, "speaker": "Michael Scott"},
  {"season": 6, "episode": 16, "scene": 52, "line": "I'm sure he'll be fine.", "order": 33899, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 16, "scene": 52, "line": "I'm not so sure. Kevin has an enormous heart. Literally, he has an elephant heart. He had a transplant when he was seventeen. Had some problems, blah blah blah.", "order": 33900, "speaker": "Michael Scott"},
  {"season": 6, "episode": 16, "scene": 52, "line": "Really?", "order": 33901, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 16, "scene": 53, "line": "No, Kevin doesn't have an elephant heart. But he is very sensitive. And it won't kill Erin just to go and have lunch with him in the break room like I promised him... I bet his heart is enlarged, though.", "order": 33902, "speaker": "Michael Scott"},
  {"season": 6, "episode": 16, "scene": 54, "line": "I don't want anyone to die.", "order": 33903, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 16, "scene": 54, "line": "Just don't let him sit on you... I'm kidding. You'll have fun. It'll be good. It'll be good. There he is. Go to him.", "order": 33904, "speaker": "Michael Scott"},
  {"season": 6, "episode": 16, "scene": 54, "line": "Hi.", "order": 33905, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 16, "scene": 54, "line": "Hi.", "order": 33906, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 16, "scene": 54, "line": "She touched my shoulder.", "order": 33907, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 16, "scene": 55, "line": "Hey, guys. We're short on rooms, so this is Dale and Kathy.", "order": 33908, "speaker": "Nurse"},
  {"season": 6, "episode": 16, "scene": 55, "line": "Oh, hi.", "order": 33909, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 16, "scene": 55, "line": "Hi.", "order": 33910, "speaker": "Kathy"},
  {"season": 6, "episode": 16, "scene": 55, "line": "Hi, so sorry.", "order": 33911, "speaker": "Dale"},
  {"season": 6, "episode": 16, "scene": 55, "line": "Careful... Wow, she just shoves the nipple right in there.", "order": 33912, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 16, "scene": 55, "line": "I know.", "order": 33913, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 16, "scene": 55, "line": "You see that? I'm pretty sure she's... Hi.", "order": 33914, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 16, "scene": 57, "line": "Did you grow up around here?", "order": 33915, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 16, "scene": 57, "line": "No.", "order": 33916, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 16, "scene": 57, "line": "So, you must have grown up around somewhere else?", "order": 33917, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 16, "scene": 57, "line": "Yes.", "order": 33918, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 16, "scene": 58, "line": "Why was I hiding behind the plant? Ha, well, in college, I took a botony class, and there was lots of drama in that class. Uh, kids would gossip about me, so I would eavesdrop on them by hiding behind different plants in the botony class. And then, uh, they would say things like 'Oh, this guy's going to fail this class,' or, 'What's this guy doing spying on us from behind plants?' And then I would jump out of them and confront them, and be like, 'Oh, you think all I do is hide behind plants and spy on people? Busted.' ... Oh, the reason? The reason I was hiding behind that plant in this situation was that I thought that Erin and Kevin were kind of hitting it off, and... I was jealous.", "order": 33919, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 16, "scene": 59, "line": "Uh, hi, Pam Halpert?", "order": 33920, "speaker": "Hospital employee"},
  {"season": 6, "episode": 16, "scene": 59, "line": "Yes?", "order": 33921, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 16, "scene": 59, "line": "Hi. I'm Clark. Josie said you might benefit from a lactation consultant.", "order": 33922, "speaker": "Hospital employee"},
  {"season": 6, "episode": 16, "scene": 59, "line": "Um, yeah, that would be great.", "order": 33923, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 16, "scene": 59, "line": "Yup. Really great. When's she available?", "order": 33924, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 16, "scene": 59, "line": "Actually, uh, I'm the consultant. Got milk? Ha ha. Alright, let's see what we're working with.", "order": 33925, "speaker": "Clark Green"},
  {"season": 6, "episode": 16, "scene": 59, "line": "So, uh, biggest thing, besides not being able to get her to latch...", "order": 33926, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 16, "scene": 59, "line": "I'll get that for you.", "order": 33927, "speaker": "Clark Green"},
  {"season": 6, "episode": 16, "scene": 59, "line": "Oh, thank you. Is that um, I can't tell if I'm really producing. I don't know if she's getting anything.", "order": 33928, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 16, "scene": 59, "line": "Okay. Let me feel here.", "order": 33929, "speaker": "Clark Green"},
  {"season": 6, "episode": 16, "scene": 59, "line": "Okay.", "order": 33930, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 16, "scene": 59, "line": "Yeah, it's quite full. Why don't you put your hand on top of my hand.", "order": 33931, "speaker": "Clark Green"},
  {"season": 6, "episode": 16, "scene": 59, "line": "Okay.", "order": 33932, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 16, "scene": 59, "line": "When you're feeding, you want to press in like this, make your hand in a C. Uh, does that hurt?", "order": 33933, "speaker": "Clark Green"},
  {"season": 6, "episode": 16, "scene": 59, "line": "No, I mean, it feels... pressure.", "order": 33934, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 16, "scene": 59, "line": "Okay.", "order": 33935, "speaker": "Clark Green"},
  {"season": 6, "episode": 16, "scene": 59, "line": "Are you sure it doesn't hurt?", "order": 33936, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 16, "scene": 59, "line": "No. It just feels like pressure.", "order": 33937, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 16, "scene": 59, "line": "Okay, well. Feel how I'm flicking the nipple? Like that?", "order": 33938, "speaker": "Clark Green"},
  {"season": 6, "episode": 16, "scene": 59, "line": "Yup.", "order": 33939, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 16, "scene": 59, "line": "Stimulate it.", "order": 33940, "speaker": "Clark Green"},
  {"season": 6, "episode": 16, "scene": 59, "line": "Yeah.", "order": 33941, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 16, "scene": 59, "line": "Alright, so you just want to do that, and that will, uh...", "order": 33942, "speaker": "Clark Green"},
  {"season": 6, "episode": 16, "scene": 59, "line": "Perfect.", "order": 33943, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 16, "scene": 59, "line": "And you can just... The baby should grab on to that.", "order": 33944, "speaker": "Clark Green"},
  {"season": 6, "episode": 16, "scene": 59, "line": "I think she will.", "order": 33945, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 16, "scene": 59, "line": "Okay. So, I'm just not sure if I'm releasing, though. I'm not sure if...", "order": 33946, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 16, "scene": 59, "line": "We'll figure it out.", "order": 33947, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 16, "scene": 59, "line": "It doesn't... Why don't you bring your baby over here... She's beautiful.", "order": 33948, "speaker": "Clark Green"},
  {"season": 6, "episode": 16, "scene": 59, "line": "Okay. Aw, thank you.", "order": 33949, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 16, "scene": 59, "line": "Yes, congratulations.", "order": 33950, "speaker": "Clark Green"},
  {"season": 6, "episode": 16, "scene": 59, "line": "Thank you.", "order": 33951, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 16, "scene": 59, "line": "Well, you're doing a good job. Just stay relaxed, and I'll come back in a bit to check on you. Okay?", "order": 33952, "speaker": "Clark Green"},
  {"season": 6, "episode": 16, "scene": 59, "line": "Okay.", "order": 33953, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 16, "scene": 59, "line": "Please do.", "order": 33954, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 16, "scene": 59, "line": "Oh, shoot.", "order": 33955, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 16, "scene": 59, "line": "What?", "order": 33956, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 16, "scene": 59, "line": "Shoot, she fell off.", "order": 33957, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 16, "scene": 59, "line": "Oh, uh, try the torpedo thing.", "order": 33958, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 16, "scene": 59, "line": "Will you just, will you grab Clark real quick?", "order": 33959, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 16, "scene": 59, "line": "No need. I saw him do it. I can try it.", "order": 33960, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 16, "scene": 59, "line": "Um, Jim, please, please, please, I think it'd be weird if you did it.", "order": 33961, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 16, "scene": 59, "line": "Okay, I'll just go get the other guy.", "order": 33962, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 16, "scene": 60, "line": "[on the phone] Hey, what's up, kid?", "order": 33963, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 16, "scene": 60, "line": "Have you had a chance to look over the revisions on the contract I've prepared for you?", "order": 33964, "speaker": "Angela Martin"},
  {"season": 6, "episode": 16, "scene": 60, "line": "Nothing left to do except dot the I's, the J's, and the umlauts. Why don't you meet me here at exactly mid-late afternoon?", "order": 33965, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 16, "scene": 60, "line": "I look forward to it.", "order": 33966, "speaker": "Angela Martin"},
  {"season": 6, "episode": 16, "scene": 60, "line": "Very well.", "order": 33967, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 16, "scene": 60, "line": "Goodbye.", "order": 33968, "speaker": "Angela Martin"},
  {"season": 6, "episode": 16, "scene": 61, "line": "[trying to sleep as the baby cries] Jim, Jim, get her.", "order": 33969, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 16, "scene": 61, "line": "Hmm?", "order": 33970, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 16, "scene": 61, "line": "Get the baby. Get her. Come on.", "order": 33971, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 16, "scene": 61, "line": "Okay. Sshh. I know. [hands the baby to Pam]", "order": 33972, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 16, "scene": 61, "line": "I got her. Okay. Okay. Okay... Jim! She latched! She latched.", "order": 33973, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 16, "scene": 61, "line": "That's amazing.", "order": 33974, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 16, "scene": 61, "line": "Oh, my God. I didn't even have to do the 'C thing.'", "order": 33975, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 16, "scene": 61, "line": "That's awesome.", "order": 33976, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 16, "scene": 61, "line": "Aw, we're doing it.", "order": 33977, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 16, "scene": 61, "line": "that's great.", "order": 33978, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 16, "scene": 61, "line": "We're really parents now.", "order": 33979, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 16, "scene": 61, "line": "Mm-hmm.", "order": 33980, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 16, "scene": 62, "line": "Kevin. Erin would like a few words with you.", "order": 33981, "speaker": "Michael Scott"},
  {"season": 6, "episode": 16, "scene": 62, "line": "Hey.", "order": 33982, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 16, "scene": 62, "line": "Hi.", "order": 33983, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 16, "scene": 62, "line": "I have really enjoyed our time together.", "order": 33984, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 16, "scene": 62, "line": "Yeah, me too.", "order": 33985, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 16, "scene": 62, "line": "I want to continue working on our friendship.", "order": 33986, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 16, "scene": 62, "line": "Really, really fun.", "order": 33987, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 16, "scene": 62, "line": "Because I think our friendship could be a really cool thing.", "order": 33988, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 16, "scene": 62, "line": "Yeah, me too.", "order": 33989, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 16, "scene": 62, "line": "And, you're my friend.", "order": 33990, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 16, "scene": 62, "line": "Yeah.", "order": 33991, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 16, "scene": 62, "line": "And I hope that I'm your friend... And, maybe...", "order": 33992, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 16, "scene": 62, "line": "Yeah.", "order": 33993, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 16, "scene": 62, "line": "Oh, my God, this is agonizing. Look, Kevin, do you really think that you could have dated Erin?", "order": 33994, "speaker": "Michael Scott"},
  {"season": 6, "episode": 16, "scene": 62, "line": "You said she liked me.", "order": 33995, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 16, "scene": 62, "line": "Okay, even if someone told you that, you should know that that could never be possible, Kevin. And I'm surprised that you didn't question me in the first place.", "order": 33996, "speaker": "Michael Scott"},
  {"season": 6, "episode": 16, "scene": 62, "line": "I've dumped better than Erin.", "order": 33997, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 16, "scene": 62, "line": "No.", "order": 33998, "speaker": "Michael Scott"},
  {"season": 6, "episode": 16, "scene": 62, "line": "Lynn was way hotter than Erin, Michael.", "order": 33999, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 16, "scene": 62, "line": "Lynn was as hot as Erin.", "order": 34000, "speaker": "Michael Scott"},
  {"season": 6, "episode": 16, "scene": 62, "line": "Yeah, Michael, but you dated Holly and Jan, and they were so much hotter than you.", "order": 34001, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 16, "scene": 62, "line": "This isn't about me, and that is debatable. And I have a personality, where as you... Yes, Andy?", "order": 34002, "speaker": "Michael Scott"},
  {"season": 6, "episode": 16, "scene": 62, "line": "[he hits his head on the desk after ducking out of the way from hiding behind a plant] Ow.", "order": 34003, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 16, "scene": 62, "line": "Kev... Nice. Going.", "order": 34004, "speaker": "Michael Scott"},
  {"season": 6, "episode": 16, "scene": 62, "line": "Well...", "order": 34005, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 16, "scene": 63, "line": "Knock knock.", "order": 34006, "speaker": "Isabelle"},
  {"season": 6, "episode": 16, "scene": 63, "line": "Oh, hey, Isabelle.", "order": 34007, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 16, "scene": 63, "line": "Hey.", "order": 34008, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 16, "scene": 63, "line": "Hey. Pam, she's gorgeous. Can I hold her?", "order": 34009, "speaker": "Isabelle"},
  {"season": 6, "episode": 16, "scene": 63, "line": "Yes. She was a little fussy earlier, but she's totally quieted down. Here, let me just, uh, let me just burp her. I don't want her to spit up on you. Come here, sweety... Oh, my God! Wrong baby!", "order": 34010, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 16, "scene": 63, "line": "What?", "order": 34011, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 16, "scene": 63, "line": "Wrong baby! This is not our baby! Sshh.", "order": 34012, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 16, "scene": 63, "line": "Okay, okay.", "order": 34013, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 16, "scene": 63, "line": "Oh, my gosh.", "order": 34014, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 16, "scene": 63, "line": "[waking up] Oh,has she been fussing long?", "order": 34015, "speaker": "Kathy"},
  {"season": 6, "episode": 16, "scene": 63, "line": "No, not at all.", "order": 34016, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 16, "scene": 63, "line": "Perfect.", "order": 34017, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 16, "scene": 63, "line": "I was out like a light... Oh, she's not hungry...", "order": 34018, "speaker": "Kathy"},
  {"season": 6, "episode": 16, "scene": 63, "line": "How's it going?", "order": 34019, "speaker": "Nurse"},
  {"season": 6, "episode": 16, "scene": 63, "line": "Well, I feel like she needs to eat, but she won't latch on, which is weird, because the other baby di...", "order": 34020, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 16, "scene": 63, "line": "Ha ha ha.", "order": 34021, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 16, "scene": 63, "line": "Bottles are fine. A lot of babies grow up using bottles. So are you excited to bring your baby home?", "order": 34022, "speaker": "Nurse"},
  {"season": 6, "episode": 16, "scene": 63, "line": "We definitely are. At 3:00, right? You said we could stay until 3:00?", "order": 34023, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 16, "scene": 63, "line": "Yeah, you can. It's 2:35.", "order": 34024, "speaker": "Nurse"},
  {"season": 6, "episode": 16, "scene": 63, "line": "Half hour.", "order": 34025, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 16, "scene": 63, "line": "Twenty five minutes. And you're all set with the car seat?", "order": 34026, "speaker": "Nurse"},
  {"season": 6, "episode": 16, "scene": 63, "line": "Yes, car seat's right there.", "order": 34027, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 16, "scene": 63, "line": "That bottom part needs to go in the car.", "order": 34028, "speaker": "Nurse"},
  {"season": 6, "episode": 16, "scene": 63, "line": "Newsflash, the whole thing needs to go in the car.", "order": 34029, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 16, "scene": 63, "line": "Ha ha, be back in twenty five minutes.", "order": 34030, "speaker": "Nurse"},
  {"season": 6, "episode": 16, "scene": 63, "line": "Or it could be a half hour, if you need it to be... Can we get a late checkout? I don't... I don't think she heard me.", "order": 34031, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 16, "scene": 64, "line": "[entering the Halpert house] Hello? Dwight? What, what are you doing here?", "order": 34032, "speaker": "Isabelle"},
  {"season": 6, "episode": 16, "scene": 64, "line": "Isabelle. Hello... Yeah, the uh, kitchen was disgusting, so...", "order": 34033, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 16, "scene": 64, "line": "Wow, ha. New cabinets.", "order": 34034, "speaker": "Isabelle"},
  {"season": 6, "episode": 16, "scene": 64, "line": "Yup.", "order": 34035, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 16, "scene": 64, "line": "All I did was bring macaroni and cheese... Where's the fridge?", "order": 34036, "speaker": "Isabelle"},
  {"season": 6, "episode": 16, "scene": 64, "line": "Oh, it's... In the backyard. I'll take this.", "order": 34037, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 16, "scene": 64, "line": "Oh... Well, I'll let you get back to it.", "order": 34038, "speaker": "Isabelle"},
  {"season": 6, "episode": 16, "scene": 64, "line": "Hey, listen. I know that I'm an adult, but maybe I could come by sometime for a teeth cleaning. You know, just for fun.", "order": 34039, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 16, "scene": 64, "line": "Well, adults are supposed to go to the dentist, too.", "order": 34040, "speaker": "Isabelle"},
  {"season": 6, "episode": 16, "scene": 64, "line": "Are they now? Ha ha, how some people spend their money, right? Ha ha.", "order": 34041, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 16, "scene": 64, "line": "Yeah...", "order": 34042, "speaker": "Isabelle"},
  {"season": 6, "episode": 16, "scene": 64, "line": "Alright. I'll call you, kid.", "order": 34043, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 16, "scene": 64, "line": "Do that.", "order": 34044, "speaker": "Isabelle"},
  {"season": 6, "episode": 16, "scene": 65, "line": "Are you really sure we should be leaving?", "order": 34045, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 16, "scene": 65, "line": "Yeah.", "order": 34046, "speaker": "Hospital employee"},
  {"season": 6, "episode": 16, "scene": 65, "line": "But you hear the baby crying, right?", "order": 34047, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 16, "scene": 65, "line": "Mm-hmm... Where's your car?", "order": 34048, "speaker": "Hospital employee"},
  {"season": 6, "episode": 16, "scene": 65, "line": "Uh, it's in the lot.", "order": 34049, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 16, "scene": 65, "line": "Oh, a lot of fathers bring their car around.", "order": 34050, "speaker": "Hospital employee"},
  {"season": 6, "episode": 16, "scene": 65, "line": "Right, okay. Yes, that would be the smart thing to do. Uh, Pam, I will be right back.", "order": 34051, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 16, "scene": 65, "line": "Okay, please hurry.", "order": 34052, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 16, "scene": 66, "line": "Erin, uh, I need you to send this fax immediately. It's really important, so I'm going to stand here and wait for the confirmation.", "order": 34053, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 16, "scene": 66, "line": "Okay, good.", "order": 34054, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 16, "scene": 67, "line": "The fax says 'Erin, will you have dinner with me?' from Andy, and the number is our office fax number.", "order": 34055, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 16, "scene": 68, "line": "It's busy. Why don't I keep trying, and then I'll give you the confirmation in a bit?", "order": 34056, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 16, "scene": 68, "line": "Sorry, that's unacceptable. I need you to send it immediately, or you're fired.", "order": 34057, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 16, "scene": 68, "line": "[tearing up] You can't talk to me like that. I didn't do anything wrong. I've been having a tough day today.", "order": 34058, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 16, "scene": 68, "line": "Oh, God, no. I'm... Just read the fax.", "order": 34059, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 16, "scene": 68, "line": "You read the fax.", "order": 34060, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 16, "scene": 68, "line": "I'm... I'm asking you out.", "order": 34061, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 16, "scene": 68, "line": "Oh, my God. That's amazing. Let me just fax this, and I'll check my planner.", "order": 34062, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 16, "scene": 68, "line": "So, it's a date.", "order": 34063, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 16, "scene": 68, "line": "Yes. Do you have a day in mind?", "order": 34064, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 16, "scene": 68, "line": "Yeah, what day? What day?", "order": 34065, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 16, "scene": 68, "line": "Everyday is fine. Or...", "order": 34066, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 16, "scene": 68, "line": "Well, that, what's that one?", "order": 34067, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 16, "scene": 68, "line": "Thursday?", "order": 34068, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 16, "scene": 68, "line": "Okay. Let's do it.", "order": 34069, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 16, "scene": 68, "line": "Okay.", "order": 34070, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 16, "scene": 69, "line": "Would you like me to help you up out of the chair?", "order": 34071, "speaker": "Hospital employee"},
  {"season": 6, "episode": 16, "scene": 69, "line": "Oh, yeah, sorry.", "order": 34072, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 16, "scene": 69, "line": "Yeah, we just got a lot of discharges today.", "order": 34073, "speaker": "Hospital employee"},
  {"season": 6, "episode": 16, "scene": 69, "line": "Okay, alright, thanks... Okay, do you want to eat? You want to try eating? Okay... Okay, let me... Just you and me. Come on. There we go... Yeah... Oh, yeah. Like that. There we go. You got it.", "order": 34074, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 16, "scene": 69, "line": "Five tickets on the windshield... Hey.", "order": 34075, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 16, "scene": 69, "line": "Hi. We did it.", "order": 34076, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 16, "scene": 69, "line": "You used my move, didn't you?", "order": 34077, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 16, "scene": 69, "line": "I used a variation of your move.", "order": 34078, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 16, "scene": 70, "line": "[smoking a cigar] There is no greater feeling than when two people who are perfect for each other overcome all obstacles, and find true love. And that is what I thought that Erin and Kevin were going to find today... I think I'm going to be sick...", "order": 34079, "speaker": "Michael Scott"},
  {"season": 6, "episode": 16, "scene": 71, "line": "Two forms of ID, please.", "order": 34080, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 16, "scene": 71, "line": "And now you, two forms of ID, please... Alright, all is in order. I just need your signature... What is it?", "order": 34081, "speaker": "Angela Martin"},
  {"season": 6, "episode": 16, "scene": 71, "line": "Nothing.", "order": 34082, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 16, "scene": 71, "line": "Alright. How would you like to celebrate?", "order": 34083, "speaker": "Angela Martin"},
  {"season": 6, "episode": 16, "scene": 71, "line": "Just pour yourself a cup of apple juice. I feel sick... [Jim and Pam walk in to their house with the baby] I couldn't find the iPod... Give me a couple days. I'll be out of your hair.", "order": 34084, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 17, "scene": 1, "line": "Hello hello. Top of the morning to you! Ooh ooh! Green M&Ms! Nature's Viagra! [Grabs and pours Kevin-esque scoop of M&Ms into his coat pocket] Two of my favorite joke areas combined. It'll be a good day.", "order": 34085, "speaker": "Michael Scott"},
  {"season": 6, "episode": 17, "scene": 2, "line": "I don't want to bring my friends, why can't it just be the two of us?", "order": 34086, "speaker": "Kelly Kapoor"},
  {"season": 6, "episode": 17, "scene": 2, "line": "Because it's St. Patrick's Day, people go out in groups.", "order": 34087, "speaker": "Ryan Howard"},
  {"season": 6, "episode": 17, "scene": 2, "line": "Well why don't you invite your friends?", "order": 34088, "speaker": "Kelly Kapoor"},
  {"season": 6, "episode": 17, "scene": 2, "line": "Why are you being so weird about this?", "order": 34089, "speaker": "Ryan Howard"},
  {"season": 6, "episode": 17, "scene": 2, "line": "Stop fighting. Just on St Patrick's Day, okay? Just one perfect day a year. No hassles, no problems, no kids.", "order": 34090, "speaker": "Meredith Palmer"},
  {"season": 6, "episode": 17, "scene": 2, "line": "Why no kids?", "order": 34091, "speaker": "Ryan Howard"},
  {"season": 6, "episode": 17, "scene": 2, "line": "Yeah, where are your kids?", "order": 34092, "speaker": "Kelly Kapoor"},
  {"season": 6, "episode": 17, "scene": 2, "line": "Nope. Nuh -uh. Not today!", "order": 34093, "speaker": "Meredith Palmer"},
  {"season": 6, "episode": 17, "scene": 3, "line": "It is St. Patrick's Day, and here in Scranton that is a huge deal. It is the closest that the Irish will ever get to Christmas.", "order": 34094, "speaker": "Michael Scott"},
  {"season": 6, "episode": 17, "scene": 4, "line": "Welcome back, 'Dad!'", "order": 34095, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 17, "scene": 4, "line": "Oh, thank you!", "order": 34096, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 17, "scene": 4, "line": "Hey!", "order": 34097, "speaker": "Phyllis Vance"},
  {"season": 6, "episode": 17, "scene": 4, "line": "Hey!", "order": 34098, "speaker": "Stanley Hudson"},
  {"season": 6, "episode": 17, "scene": 4, "line": "Welcome back Tuna!", "order": 34099, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 17, "scene": 4, "line": "Hey... [reveal Dwight's Mega-Desk]", "order": 34100, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 17, "scene": 4, "line": "Very good. Okay. Will get back to you right away on that, thanks.", "order": 34101, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 17, "scene": 4, "line": "What do we got here?", "order": 34102, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 17, "scene": 4, "line": "Mega-Desk.", "order": 34103, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 17, "scene": 4, "line": "Of course.", "order": 34104, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 17, "scene": 4, "line": "Command central.", "order": 34105, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 17, "scene": 4, "line": "Hm hm.", "order": 34106, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 17, "scene": 4, "line": "Surveillance, gaming- and business.", "order": 34107, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 17, "scene": 4, "line": "Okay. [Pulls desks apart] Just gotta...", "order": 34108, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 17, "scene": 4, "line": "Okay. Come on! Jim!", "order": 34109, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 17, "scene": 5, "line": "Dweedle Dee and Dweedle Dumb-ass have been away on maternity leave. Now Dweedle Dumb-ass is back, and we have a problem. Yes, getting hooked on Mega-Desk was my own damn fault. But ... I don't care about assigning blame. All I care about is Mega-Desk. That is all I care about. Getting. More. Mega-Desk.", "order": 34110, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 17, "scene": 6, "line": "Oh, I love this, so much fun! There's such team spirit in this room! 'Morning Darling!", "order": 34111, "speaker": "Jo Bennett"},
  {"season": 6, "episode": 17, "scene": 6, "line": "Morning!", "order": 34112, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 17, "scene": 7, "line": "Well, this is my last day at the Scranton branch for a while. But I'm leaving it in the very capable hands of some of the loveliest people I've ever had the pleasure of knowing. Oh I'm gonna miss this place. And the snow! Hoo, my dogs love peeing in that snow! Makes me think they're onto something.", "order": 34113, "speaker": "Jo Bennett"},
  {"season": 6, "episode": 17, "scene": 8, "line": "Oh good morning sweetheart.", "order": 34114, "speaker": "Jo Bennett"},
  {"season": 6, "episode": 17, "scene": 8, "line": "Morning, honey-pile.", "order": 34115, "speaker": "Michael Scott"},
  {"season": 6, "episode": 17, "scene": 8, "line": "I'm sorry but is that a gift I see in your hands?", "order": 34116, "speaker": "Jo Bennett"},
  {"season": 6, "episode": 17, "scene": 8, "line": "It is. This is a little something for you to remember your time here in Scranton by.", "order": 34117, "speaker": "Michael Scott"},
  {"season": 6, "episode": 17, "scene": 8, "line": "[pulls out glass cube with coal.] Is that a lump of coal?", "order": 34118, "speaker": "Jo Bennett"},
  {"season": 6, "episode": 17, "scene": 8, "line": "Yes it is!", "order": 34119, "speaker": "Michael Scott"},
  {"season": 6, "episode": 17, "scene": 8, "line": "Have I been that naughty?", "order": 34120, "speaker": "Jo Bennett"},
  {"season": 6, "episode": 17, "scene": 8, "line": "No, no no- that is a good gift actually. Buildings here in Scranton are literally powered by coal.", "order": 34121, "speaker": "Michael Scott"},
  {"season": 6, "episode": 17, "scene": 8, "line": "Err, thank you very much. We, we don't get to see much coal in Tallahassee, I'm used to alligators, and some of the worst Chinese food you've ever tasted.", "order": 34122, "speaker": "Jo Bennett"},
  {"season": 6, "episode": 17, "scene": 8, "line": "Mm, that sounds great, actually.", "order": 34123, "speaker": "Michael Scott"},
  {"season": 6, "episode": 17, "scene": 8, "line": "Well, if you ever get down in my neck of the woods, you got a place to stay.", "order": 34124, "speaker": "Jo Bennett"},
  {"season": 6, "episode": 17, "scene": 9, "line": "When you work for Sabre, only one thing matters. And I don't care if you're a loser, or you practice bestiality, if Jo likes you, you are in. And I am in!", "order": 34125, "speaker": "Michael Scott"},
  {"season": 6, "episode": 17, "scene": 10, "line": "Erin go braugh!", "order": 34126, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 17, "scene": 10, "line": "Andy go braugh to you! Nice kilt!", "order": 34127, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 17, "scene": 10, "line": "Thanks. It's actually my sister's old field hockey skirt.", "order": 34128, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 17, "scene": 11, "line": "Erin and I have our first date tonight, and it has to be perfect. Why? Because according to 'How I Met Your Mother' that's the date that your kids are going to wait patiently to hear about, and you better have a good story for them.", "order": 34129, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 17, "scene": 12, "line": "No. [re-enters bullpen from kitchen.]", "order": 34130, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 17, "scene": 12, "line": "Great.", "order": 34131, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 17, "scene": 12, "line": "No.", "order": 34132, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 17, "scene": 12, "line": "Thank you. [hangs up phone]", "order": 34133, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 17, "scene": 12, "line": "No-no-no-no-no.", "order": 34134, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 17, "scene": 12, "line": "Oh come on. Come on. Five more minutes? Five more minutes of Mega-Desk? Please?", "order": 34135, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 17, "scene": 12, "line": "[swats over various photos and knickknacks, points at paperwork] Is this yours or mine?", "order": 34136, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 17, "scene": 13, "line": "They say that no man is an island. False! I am an island and this island is volcanic. And it is about to erupt. With the molten hot lava of strategy!", "order": 34137, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 17, "scene": 14, "line": "Good Lord! [Photo of Cece on Jim's desktop] I can't believe I'm missing this.", "order": 34138, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 17, "scene": 14, "line": "It's kind of like that lip thing she did last night, like a half snarl, half smile? I get the sense that she's very ironic.", "order": 34139, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 17, "scene": 14, "line": "Okay. Love you. We'll talk soon. [hangs up phone]", "order": 34140, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 17, "scene": 14, "line": "Must be amazing being a father right, a miracle of life?", "order": 34141, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 17, "scene": 14, "line": "It is. Big time.", "order": 34142, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 17, "scene": 14, "line": "Ah, that baby is just discovering the whole wide world right now.", "order": 34143, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 17, "scene": 14, "line": "Pretty amazing.", "order": 34144, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 17, "scene": 14, "line": "What up is, what down is, who Mom is, who Dad is. Must be tough being here with all that going on.", "order": 34145, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 17, "scene": 14, "line": "Oh it's tough being here for a lot of reasons.", "order": 34146, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 17, "scene": 14, "line": "I mean, you're here at work, and the baby thinks that the refrigerator is its father.", "order": 34147, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 17, "scene": 14, "line": "Is that what happened to you?", "order": 34148, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 17, "scene": 14, "line": "I'll tell you what happened to me. I didn't see my father for the first two years of my life. I thought my mother was my father, and my wet-nurse was my mother.", "order": 34149, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 17, "scene": 14, "line": "That's a common mistake.", "order": 34150, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 17, "scene": 14, "line": "Turned out fine for me. But Mose? Oh. Same story, different ending.", "order": 34151, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 17, "scene": 15, "line": "Hey! [Erin sneezing at desk] Are you sick?", "order": 34152, "speaker": "Angela Martin"},
  {"season": 6, "episode": 17, "scene": 15, "line": "Oh, no I'm fine, I just have a little indigestion.", "order": 34153, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 17, "scene": 15, "line": "In your nose?", "order": 34154, "speaker": "Angela Martin"},
  {"season": 6, "episode": 17, "scene": 15, "line": "Yes. [Angela returns to desk and puts embroidered breathing mask on]", "order": 34155, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 17, "scene": 16, "line": "I'm a little sick but I don't want to miss my date with Andy. I'll get better. Whenever I'm sick it goes away within a few hours. Except once, when I was in the hospital from age three to six.", "order": 34156, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 17, "scene": 17, "line": "Now, after all the talking I've been doing. It's your turn. This is a town hall meeting. I want to hear what you all sound like.", "order": 34157, "speaker": "Jo Bennett"},
  {"season": 6, "episode": 17, "scene": 17, "line": "That is a great idea JB.", "order": 34158, "speaker": "Michael Scott"},
  {"season": 6, "episode": 17, "scene": 17, "line": "Thank you. You know I get way too many ideas from the top. Now I want to hear your ideas. I mean, did you guys know that Liquid Paper? That wasn't invented by some fancy engineer. No, that was created by a lowly typist.", "order": 34159, "speaker": "Jo Bennett"},
  {"season": 6, "episode": 17, "scene": 17, "line": "Jo, I have an idea, for suntan lotion - soap.", "order": 34160, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 17, "scene": 17, "line": "You know it doesn't have to be an invention.", "order": 34161, "speaker": "Jo Bennett"},
  {"season": 6, "episode": 17, "scene": 17, "line": "I have a question.", "order": 34162, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 17, "scene": 17, "line": "Oscar, homosexual accountant.", "order": 34163, "speaker": "Michael Scott"},
  {"season": 6, "episode": 17, "scene": 17, "line": "In the training manual it says, that there is a minority executive training program in Tallahassee?", "order": 34164, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 17, "scene": 17, "line": "I am so proud of Sabre's 'Print In All Colors' initiative. Any Sabre employee of color, is welcome to apply. [Kelly applauds] Daryl?", "order": 34165, "speaker": "Jo Bennett"},
  {"season": 6, "episode": 17, "scene": 17, "line": "Daryl. Mellow, soulful, smart for warehouse...", "order": 34166, "speaker": "Michael Scott"},
  {"season": 6, "episode": 17, "scene": 17, "line": "Okay hush now.", "order": 34167, "speaker": "Jo Bennett"},
  {"season": 6, "episode": 17, "scene": 17, "line": "Okay.", "order": 34168, "speaker": "Michael Scott"},
  {"season": 6, "episode": 17, "scene": 17, "line": "The Sabre shipping method could be more efficient. Combining inventory systems makes sense on paper, but printers and paper ship differently. It'll be faster to deliver them separately, instead of waiting on everything to come in.", "order": 34169, "speaker": "Darryl Philbin"},
  {"season": 6, "episode": 17, "scene": 17, "line": "But you wouldn't need more trucks?", "order": 34170, "speaker": "Jo Bennett"},
  {"season": 6, "episode": 17, "scene": 17, "line": "Not at all. There's a way that it can be scheduled. I sketched this out downstairs.", "order": 34171, "speaker": "Darryl Philbin"},
  {"season": 6, "episode": 17, "scene": 17, "line": "Oh look at you.", "order": 34172, "speaker": "Jo Bennett"},
  {"season": 6, "episode": 17, "scene": 17, "line": "Look at that picture that you drew. Nice job! We're very proud of you. You know what, we're gonna tape that up on the refrigerator in the kitchen.", "order": 34173, "speaker": "Michael Scott"},
  {"season": 6, "episode": 17, "scene": 17, "line": "[Looks over his proposal] I like this Daryl. I like this a lot. Maybe you should be doing your sketching upstairs. Would you like an office up here?", "order": 34174, "speaker": "Jo Bennett"},
  {"season": 6, "episode": 17, "scene": 17, "line": "Are you serious?", "order": 34175, "speaker": "Darryl Philbin"},
  {"season": 6, "episode": 17, "scene": 17, "line": "Yeah. Take Jim's old office.", "order": 34176, "speaker": "Jo Bennett"},
  {"season": 6, "episode": 17, "scene": 17, "line": "Um. I set my stuff up in there. So... just give me a few minutes to clean that out for you.", "order": 34177, "speaker": "Gabe Lewis"},
  {"season": 6, "episode": 17, "scene": 17, "line": "Yeah, I want to hear more from you.", "order": 34178, "speaker": "Jo Bennett"},
  {"season": 6, "episode": 17, "scene": 17, "line": "Absolutely.", "order": 34179, "speaker": "Darryl Philbin"},
  {"season": 6, "episode": 17, "scene": 17, "line": "All right.", "order": 34180, "speaker": "Jo Bennett"},
  {"season": 6, "episode": 17, "scene": 17, "line": "Thank you.", "order": 34181, "speaker": "Darryl Philbin"},
  {"season": 6, "episode": 17, "scene": 17, "line": "Okay. Any questions? Anything on anybody's mind? I'm leaving tonight, this is your last chance for a while!", "order": 34182, "speaker": "Jo Bennett"},
  {"season": 6, "episode": 17, "scene": 17, "line": "Oh no! Say it's not so, Jo, we're gonna miss you, we're gonna miss you so much!", "order": 34183, "speaker": "Michael Scott"},
  {"season": 6, "episode": 17, "scene": 17, "line": "Yes, well okay. Florida ain't that far away.", "order": 34184, "speaker": "Jo Bennett"},
  {"season": 6, "episode": 17, "scene": 17, "line": "Well, I am heading down there.", "order": 34185, "speaker": "Michael Scott"},
  {"season": 6, "episode": 17, "scene": 17, "line": "Well, anytime now.", "order": 34186, "speaker": "Jo Bennett"},
  {"season": 6, "episode": 17, "scene": 17, "line": "How about July 4th weekend? [Reveals paper ticket]", "order": 34187, "speaker": "Michael Scott"},
  {"season": 6, "episode": 17, "scene": 17, "line": "Oh honey you didn't buy a ticket?", "order": 34188, "speaker": "Jo Bennett"},
  {"season": 6, "episode": 17, "scene": 17, "line": "I did!", "order": 34189, "speaker": "Michael Scott"},
  {"season": 6, "episode": 17, "scene": 17, "line": "Oh honey. I'm not home, very often. And uh, me and my relatives, they take up the guesthouse. I think you should check with my office, before you book any dates, okay?", "order": 34190, "speaker": "Jo Bennett"},
  {"season": 6, "episode": 17, "scene": 17, "line": "You know what, in the spirit of full disclosure, I have actually reserved a bunch of different seats on a bunch of different flights, but there are a couple of flights that only have two tickets left, so I think we should pull the trigger and -", "order": 34191, "speaker": "Michael Scott"},
  {"season": 6, "episode": 17, "scene": 17, "line": "Enough!", "order": 34192, "speaker": "Jo Bennett"},
  {"season": 6, "episode": 17, "scene": 17, "line": "All right, everybody, just try to put a brave face on.", "order": 34193, "speaker": "Michael Scott"},
  {"season": 6, "episode": 17, "scene": 18, "line": "Let's follow the chain of events. Jo likes Michael. Jo invites Michael to house. Jo doesn't like Michael anymore. Hmmm.", "order": 34194, "speaker": "Michael Scott"},
  {"season": 6, "episode": 17, "scene": 19, "line": "[singing] Moving on up, to the East Side, to the deluxe apartment in the sky...", "order": 34195, "speaker": "Darryl Philbin"},
  {"season": 6, "episode": 17, "scene": 20, "line": "Hello. I want you to stop what you're doing right now. You are stressed, and I'm taking you to lunch.", "order": 34196, "speaker": "Michael Scott"},
  {"season": 6, "episode": 17, "scene": 20, "line": "No, that's very generous of you, but I'm all set.", "order": 34197, "speaker": "Jo Bennett"},
  {"season": 6, "episode": 17, "scene": 20, "line": "No is not an option.", "order": 34198, "speaker": "Michael Scott"},
  {"season": 6, "episode": 17, "scene": 20, "line": "Yes it is.", "order": 34199, "speaker": "Jo Bennett"},
  {"season": 6, "episode": 17, "scene": 20, "line": "All right. Well if you need me, I'll be on the other side of that wall. Knock once for yes, twice for no.", "order": 34200, "speaker": "Michael Scott"},
  {"season": 6, "episode": 17, "scene": 20, "line": "How many knocks does it take to get you to do some work?", "order": 34201, "speaker": "Jo Bennett"},
  {"season": 6, "episode": 17, "scene": 20, "line": "[laughs] I'll be over there.", "order": 34202, "speaker": "Michael Scott"},
  {"season": 6, "episode": 17, "scene": 21, "line": "And I'm actually pleased to be able to offer you printer cartridges and toner now.", "order": 34203, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 17, "scene": 21, "line": "[Whispers] Oh Jim, Jim! Sorry to bother you.", "order": 34204, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 17, "scene": 21, "line": "What?", "order": 34205, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 17, "scene": 21, "line": "My headphones are broken.", "order": 34206, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 17, "scene": 21, "line": "Right.", "order": 34207, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 17, "scene": 21, "line": "Can I listen to my music at a low volume?", "order": 34208, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 17, "scene": 21, "line": "Yeah that's great. Great.", "order": 34209, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 17, "scene": 21, "line": "Okay? [Unplugs cord, sings] 'The cat's in the cradle and the silver spoon, little boy blue-'", "order": 34210, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 17, "scene": 21, "line": "[Overlapping] When's the last time you upgraded your printer?", "order": 34211, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 17, "scene": 21, "line": "- and the man in the moon. When you coming home Dad? I don't know when-'", "order": 34212, "speaker": "D"},
  {"season": 6, "episode": 17, "scene": 21, "line": "[Whispers] Please stop that!", "order": 34213, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 17, "scene": 21, "line": "-But we'll be together then-'", "order": 34214, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 17, "scene": 21, "line": "Can you grow up?", "order": 34215, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 17, "scene": 21, "line": "-Dad, you know we'll have a good time then.'", "order": 34216, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 17, "scene": 21, "line": "[Turns off Dwight's music] Whether it's this time or next time.", "order": 34217, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 17, "scene": 21, "line": "[Vocalizes and continues song] 'The cat's in the cradle and the silver spoon'", "order": 34218, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 17, "scene": 21, "line": "[On business call] Yeah.", "order": 34219, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 17, "scene": 21, "line": "[Singing, Dwight supplies beats] 'Little boy blue and the man in the moon.'", "order": 34220, "speaker": "Andy Bernard & Dwight Schrute"},
  {"season": 6, "episode": 17, "scene": 21, "line": "No, we definitely can talk about it in the next--", "order": 34221, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 17, "scene": 21, "line": "When you coming home Dad?", "order": 34222, "speaker": "Andy Bernard & Dwight Schrute"},
  {"season": 6, "episode": 17, "scene": 21, "line": "You know, can I call you back?", "order": 34223, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 17, "scene": 21, "line": "I don't know when-'", "order": 34224, "speaker": "Andy Bernard & Dwight Schrute"},
  {"season": 6, "episode": 17, "scene": 21, "line": "That would be great, thank you.", "order": 34225, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 17, "scene": 21, "line": "But we'll get together then, we're gonna have a good time-'", "order": 34226, "speaker": "Andy Bernard & Dwight Schrute"},
  {"season": 6, "episode": 17, "scene": 22, "line": "[On phone in hallway] No, I know I need to work, I just- I feel weird not being home.", "order": 34227, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 17, "scene": 23, "line": "I am not surprised that Dwight's using my baby to steal my desk. I'm a little surprised that it's working.", "order": 34228, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 17, "scene": 24, "line": "This fax came for you. [Coughs]", "order": 34229, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 17, "scene": 24, "line": "Oh no no no! Are you feeling ill?", "order": 34230, "speaker": "Jo Bennett"},
  {"season": 6, "episode": 17, "scene": 24, "line": "Uh...", "order": 34231, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 17, "scene": 24, "line": "Oh no honey, if you got a bug, I want you to go on home. I can't have you getting Callie and Jo Jr. sick. These dogs have got to be in a commercial with Dwight Howard next week.", "order": 34232, "speaker": "Jo Bennett"},
  {"season": 6, "episode": 17, "scene": 24, "line": "Oh! No. I feel like I could lift a car.", "order": 34233, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 17, "scene": 24, "line": "Yeah, but you sound like death, girl. Now why don't you go on home and take care of yourself and get in bed. And shred that and have them send me a clean fax, okay?", "order": 34234, "speaker": "Jo Bennett"},
  {"season": 6, "episode": 17, "scene": 24, "line": "Well... [Jo closes door on her]", "order": 34235, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 17, "scene": 25, "line": "She should go home. It's not the end of the world. We'll go on our date next week. She's still gonna like me in a week. Right?", "order": 34236, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 17, "scene": 26, "line": "Yes?", "order": 34237, "speaker": "Jo Bennett"},
  {"season": 6, "episode": 17, "scene": 26, "line": "I was just working at my desk and I wanted to run some new leads by you. [Turns feint]", "order": 34238, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 17, "scene": 26, "line": "Oh! You're sick! [Andy coughs] Dangit! That's exactly why I sent that receptionist home.", "order": 34239, "speaker": "Jo Bennett"},
  {"season": 6, "episode": 17, "scene": 26, "line": "No, no, no, no, no.", "order": 34240, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 17, "scene": 26, "line": "Get everybody sick in the office.", "order": 34241, "speaker": "Jo Bennett"},
  {"season": 6, "episode": 17, "scene": 26, "line": "I have some good ideas...", "order": 34242, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 17, "scene": 26, "line": "We don't need any heroes here honey. It's time for you to get on home now.", "order": 34243, "speaker": "Jo Bennett"},
  {"season": 6, "episode": 17, "scene": 26, "line": "You sure?", "order": 34244, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 17, "scene": 26, "line": "You'll probably feel better once you get some pants on. Come on.", "order": 34245, "speaker": "Jo Bennett"},
  {"season": 6, "episode": 17, "scene": 27, "line": "Very nice.", "order": 34246, "speaker": "Michael Scott"},
  {"season": 6, "episode": 17, "scene": 27, "line": "Not bad, huh?", "order": 34247, "speaker": "Darryl Philbin"},
  {"season": 6, "episode": 17, "scene": 27, "line": "A real Hoop Dreams story you got there. Oh man, you seem to have caught Jo's eye. How'd you make that happen?", "order": 34248, "speaker": "Michael Scott"},
  {"season": 6, "episode": 17, "scene": 27, "line": "I impressed her with my good ideas.", "order": 34249, "speaker": "Darryl Philbin"},
  {"season": 6, "episode": 17, "scene": 27, "line": "Hm mm. Seriously, how did you do it?", "order": 34250, "speaker": "Michael Scott"},
  {"season": 6, "episode": 17, "scene": 27, "line": "I made a suggestion at the meeting that was good. You were there.", "order": 34251, "speaker": "Darryl Philbin"},
  {"season": 6, "episode": 17, "scene": 27, "line": "How do I put this delicately? Does her family owe your family something, in terms of a past injustice?", "order": 34252, "speaker": "Michael Scott"},
  {"season": 6, "episode": 17, "scene": 27, "line": "Now Mike, I have to ask you to leave, so that I can learn about this tiny television.", "order": 34253, "speaker": "Darryl Philbin"},
  {"season": 6, "episode": 17, "scene": 27, "line": "Okay. All right.", "order": 34254, "speaker": "Michael Scott"},
  {"season": 6, "episode": 17, "scene": 28, "line": "Hey there.", "order": 34255, "speaker": "Jo Bennett"},
  {"season": 6, "episode": 17, "scene": 28, "line": "Hey there. So I think I'm done. Gonna head out. Unless you want to chat. Like we were doing earlier.", "order": 34256, "speaker": "Michael Scott"},
  {"season": 6, "episode": 17, "scene": 28, "line": "Well there's chatting time and there's working time. I'm still on working time.", "order": 34257, "speaker": "Jo Bennett"},
  {"season": 6, "episode": 17, "scene": 28, "line": "Mm? Well, the clock says chatting time, so.", "order": 34258, "speaker": "Michael Scott"},
  {"season": 6, "episode": 17, "scene": 28, "line": "Well, if you feel like you've done a solid days work...", "order": 34259, "speaker": "Jo Bennett"},
  {"season": 6, "episode": 17, "scene": 28, "line": "Right. What?", "order": 34260, "speaker": "Michael Scott"},
  {"season": 6, "episode": 17, "scene": 28, "line": "Well I mean, if you can put your name on this day, and be proud of the amount of work you've done, then, by all means, you should toodle on home.", "order": 34261, "speaker": "Jo Bennett"},
  {"season": 6, "episode": 17, "scene": 28, "line": "Mm. mm. Oh well.", "order": 34262, "speaker": "Michael Scott"},
  {"season": 6, "episode": 17, "scene": 28, "line": "Anything else I can help you with?", "order": 34263, "speaker": "Jo Bennett"},
  {"season": 6, "episode": 17, "scene": 28, "line": "No, no, no. That's super-clear-ish. [Exits, closes door, takes off coat.] Nope. Nope. Nope. Nope.", "order": 34264, "speaker": "Michael Scott"},
  {"season": 6, "episode": 17, "scene": 29, "line": "Just because Jo has no life, does not mean that the rest of us don't have lives. Oscar has a life. I think Ryan has a life. This is outrageous.", "order": 34265, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 17, "scene": 30, "line": "Hey Pack-man.", "order": 34266, "speaker": "Michael Scott"},
  {"season": 6, "episode": 17, "scene": 30, "line": "Hey what's up, Butt-plug?", "order": 34267, "speaker": "Todd Packer"},
  {"season": 6, "episode": 17, "scene": 30, "line": "How you doing stud? You already there?", "order": 34268, "speaker": "Michael Scott"},
  {"season": 6, "episode": 17, "scene": 30, "line": "Dude, I've been here since three! I've boot and rallied twice.", "order": 34269, "speaker": "Todd Packer"},
  {"season": 6, "episode": 17, "scene": 30, "line": "How's the lady situation?", "order": 34270, "speaker": "Michael Scott"},
  {"season": 6, "episode": 17, "scene": 30, "line": "Fat and ugly. You might actually have a chance.", "order": 34271, "speaker": "Todd Packer"},
  {"season": 6, "episode": 17, "scene": 30, "line": "Ah damn. That sounds great. Unfortunately I am stuck here, because my boss is making us work late.", "order": 34272, "speaker": "Michael Scott"},
  {"season": 6, "episode": 17, "scene": 30, "line": "Oh here's what you do. Hike up your skirt and pull out your tampon, borrow some balls and come meet me!", "order": 34273, "speaker": "Todd Packer"},
  {"season": 6, "episode": 17, "scene": 30, "line": "Yeah. Maybe next year.", "order": 34274, "speaker": "Michael Scott"},
  {"season": 6, "episode": 17, "scene": 30, "line": "Maybe next 'queer.' Hey ladies, who wants some bangers and mash?", "order": 34275, "speaker": "Todd Packer"},
  {"season": 6, "episode": 17, "scene": 31, "line": "[Opens door, surprised] Oh! Andy!", "order": 34276, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 17, "scene": 31, "line": "Hi!", "order": 34277, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 17, "scene": 31, "line": "I'm in my jammy-jams!", "order": 34278, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 17, "scene": 31, "line": "That's okay, I'm in my 'worky-works.' You look amazing!", "order": 34279, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 17, "scene": 31, "line": "Oh, thanks. Come in!", "order": 34280, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 17, "scene": 32, "line": "Hey! Me and Creed are in. Are you guys in?", "order": 34281, "speaker": "Meredith Palmer"},
  {"season": 6, "episode": 17, "scene": 32, "line": "Oh I'm in.", "order": 34282, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 17, "scene": 32, "line": "I'm in.", "order": 34283, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 17, "scene": 32, "line": "All right. [The four of them get out of chairs to run out, Jo exits her office. They retreat.]", "order": 34284, "speaker": "Meredith Palmer"},
  {"season": 6, "episode": 17, "scene": 32, "line": "Oh, you don't become the most powerful woman in Tallahassee by slacking off. [Scoffs] You do it by working hard. Or marrying rich. I did both!", "order": 34285, "speaker": "Jo Bennett"},
  {"season": 6, "episode": 17, "scene": 33, "line": "Ah. Got a little client meeting at Shanny O'Gannigan's tonight. It shouldn't go all night long, so if you'd like me to, I could swing by your house so the baby can experience a strong male presence. [Jim is silent] No? Nothing? Okay. Have fun working. [Whistles 'Cat's in the Cradle' song]", "order": 34286, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 17, "scene": 34, "line": "Hi there Jo. Sorry to interrupt. Um, this evening [phone rings] I have...", "order": 34287, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 17, "scene": 34, "line": "Hello?", "order": 34288, "speaker": "Jo Bennett"},
  {"season": 6, "episode": 17, "scene": 34, "line": "Hey Jo, it's Jim Halpert. I actually scheduled a meeting at 7:30 with a very important client. And it's so weird, because we never have meetings after 5pm. But I was hoping that maybe just this once, it would be okay.", "order": 34289, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 17, "scene": 34, "line": "Well sure. You know, you go ahead and push some printers.", "order": 34290, "speaker": "Jo Bennett"},
  {"season": 6, "episode": 17, "scene": 34, "line": "You know I will. And by the way, seriously, we never, never do this!", "order": 34291, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 17, "scene": 34, "line": "Okay.", "order": 34292, "speaker": "Jo Bennett"},
  {"season": 6, "episode": 17, "scene": 34, "line": "Okay.", "order": 34293, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 17, "scene": 34, "line": "Sorry Sugar. What'd you want?", "order": 34294, "speaker": "Jo Bennett"},
  {"season": 6, "episode": 17, "scene": 34, "line": "Well, you see, I actually do have a meeting. With a client. I'm just gonna reschedule for next week.", "order": 34295, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 17, "scene": 34, "line": "Thank you.", "order": 34296, "speaker": "Jo Bennett"},
  {"season": 6, "episode": 17, "scene": 34, "line": "[pops back into Jo's doorway] Thanks again by the way.", "order": 34297, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 17, "scene": 35, "line": "Hey! What are you fellas doing up here?", "order": 34298, "speaker": "Darryl Philbin"},
  {"season": 6, "episode": 17, "scene": 35, "line": "We're here to bust you out!", "order": 34299, "speaker": "Warehouse guy 1"},
  {"season": 6, "episode": 17, "scene": 35, "line": "I wish, but uh-", "order": 34300, "speaker": "Darryl Philbin"},
  {"season": 6, "episode": 17, "scene": 35, "line": "Dude! Your shirt tucked in?", "order": 34301, "speaker": "Warehouse guy 1"},
  {"season": 6, "episode": 17, "scene": 35, "line": "Oh. Yeah, um, I must have did that when I was in the bathroom. All right then, uh, let me get to it.", "order": 34302, "speaker": "Darryl Philbin"},
  {"season": 6, "episode": 17, "scene": 35, "line": "All right.", "order": 34303, "speaker": "Warehouse guy 1"},
  {"season": 6, "episode": 17, "scene": 35, "line": "See you later.", "order": 34304, "speaker": "Warehouse guy 2"},
  {"season": 6, "episode": 17, "scene": 36, "line": "Your hand's cold.", "order": 34305, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 17, "scene": 36, "line": "It is? I'm sorry.", "order": 34306, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 17, "scene": 36, "line": "Yeah, have some more blanket.", "order": 34307, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 17, "scene": 36, "line": "Okay, thanks.", "order": 34308, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 17, "scene": 36, "line": "What's up?", "order": 34309, "speaker": "Reid"},
  {"season": 6, "episode": 17, "scene": 36, "line": "Whoa!", "order": 34310, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 17, "scene": 36, "line": "You're awake. Andy, this is my brother, Reid.", "order": 34311, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 17, "scene": 36, "line": "I didn't know you had a brother.", "order": 34312, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 17, "scene": 36, "line": "He's my foster brother.", "order": 34313, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 17, "scene": 36, "line": "Well, any brother of Erin's is a friend of mine. Nice to meet you. Andy Bernard. [They shake hands]", "order": 34314, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 17, "scene": 36, "line": "Cold hands. [Takes spot next to Erin on love seat]", "order": 34315, "speaker": "Reid"},
  {"season": 6, "episode": 17, "scene": 36, "line": "Are we rotating seats, or?", "order": 34316, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 17, "scene": 36, "line": "Oh yeah, you're the guest. Take the easy chair, best seat in the house. Plus, you don't have to sit next to this big dork and her smelly feet.", "order": 34317, "speaker": "Reid"},
  {"season": 6, "episode": 17, "scene": 36, "line": "Hey! My feet aren't smelly, they smell like roses. Smell them! [puts her feet in his face]", "order": 34318, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 17, "scene": 36, "line": "Ooh! [Erin giggles]", "order": 34319, "speaker": "Reid"},
  {"season": 6, "episode": 17, "scene": 36, "line": "So, how does the whole foster sibling thing work? Do you guys share one parent or-?", "order": 34320, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 17, "scene": 36, "line": "None. We were in the same house from ages ten to twelve. And then from fifteen to eighteen.", "order": 34321, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 17, "scene": 36, "line": "All right. Formative years. [She laughs]", "order": 34322, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 17, "scene": 36, "line": "Nice skirt.", "order": 34323, "speaker": "Reid"},
  {"season": 6, "episode": 17, "scene": 36, "line": "Yeah, it's a kilt.", "order": 34324, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 17, "scene": 37, "line": "Oh! Sorry! Sorry, sorry. [They retreat. Pan around office still full with staff.]", "order": 34325, "speaker": "Night cleaning crew"},
  {"season": 6, "episode": 17, "scene": 38, "line": "Yes, I'm anxious to get off work. But let me be clear. It's not to celebrate St Patrick's Day. It's so I can protest St. Patrick's Day.", "order": 34326, "speaker": "Angela Martin"},
  {"season": 6, "episode": 17, "scene": 39, "line": "Overnight all my damn bags home. I'm carrying nothing but my Sable gloves. I've had it with Homeland Security. And I want you to put all those tracking numbers in my Blackberry.", "order": 34327, "speaker": "Jo Bennett"},
  {"season": 6, "episode": 17, "scene": 39, "line": "How late do we have to work tonight?", "order": 34328, "speaker": "Michael Scott"},
  {"season": 6, "episode": 17, "scene": 39, "line": "You never know with Jo. Sometimes we're here til midnight. Sometimes she doesn't show up for three days.", "order": 34329, "speaker": "Gabe Lewis"},
  {"season": 6, "episode": 17, "scene": 39, "line": "Why does she do that? Why doesn't she just tell you what your schedule is?", "order": 34330, "speaker": "Michael Scott"},
  {"season": 6, "episode": 17, "scene": 39, "line": "Yeah, that would be awesome. I could get a girlfriend. Wouldn't have to go to Amsterdam seven times a year. But uh, I'm young, right? I will date when I'm dead! [Laughs]", "order": 34331, "speaker": "Gabe Lewis"},
  {"season": 6, "episode": 17, "scene": 40, "line": "Do I really want to turn out like Gabe? Twenty-six. Single. Tied to my desk. No life, no family. I want to have been married by the time I would've turned thirty. That's just - that's just depressing.", "order": 34332, "speaker": "Michael Scott"},
  {"season": 6, "episode": 17, "scene": 41, "line": "Hello Jo.", "order": 34333, "speaker": "Michael Scott"},
  {"season": 6, "episode": 17, "scene": 41, "line": "Anything I can do for you Puddin'?", "order": 34334, "speaker": "Jo Bennett"},
  {"season": 6, "episode": 17, "scene": 41, "line": "There is, as a matter of fact. It's getting sorta late. It's 8:30. And it's St. Patrick's Day, which is a world ethnic holiday. So I have decided I'm going to dismiss my employees.", "order": 34335, "speaker": "Michael Scott"},
  {"season": 6, "episode": 17, "scene": 41, "line": "Hmm.", "order": 34336, "speaker": "Jo Bennett"},
  {"season": 6, "episode": 17, "scene": 41, "line": "I'm thrilled with the work they've done today, both quality and the quantity. Great performance, Very, very solid all the way around.", "order": 34337, "speaker": "Michael Scott"},
  {"season": 6, "episode": 17, "scene": 41, "line": "All right then.", "order": 34338, "speaker": "Jo Bennett"},
  {"season": 6, "episode": 17, "scene": 41, "line": "Okay. Happy St. Patrick's Day. [Starts to leave office] And also, I would like to say that I will be canceling my trip down to Tallahassee. Although I do look forward to our professional relationship.", "order": 34339, "speaker": "Michael Scott"},
  {"season": 6, "episode": 17, "scene": 41, "line": "Michael?", "order": 34340, "speaker": "Jo Bennett"},
  {"season": 6, "episode": 17, "scene": 41, "line": "Yes.", "order": 34341, "speaker": "Michael Scott"},
  {"season": 6, "episode": 17, "scene": 41, "line": "I look forward to that too.", "order": 34342, "speaker": "Jo Bennett"},
  {"season": 6, "episode": 17, "scene": 42, "line": "Ooh. It's coming down out there.", "order": 34343, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 17, "scene": 42, "line": "Yes. Thank you, for coming all the way here.", "order": 34344, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 17, "scene": 42, "line": "No, I-", "order": 34345, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 17, "scene": 42, "line": "I'm so sorry I was so sick. [Andy leans in. She leans in. Reid appears in background. Erin kisses Andy on the cheek.]", "order": 34346, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 17, "scene": 42, "line": "Ah. Oh great, now I'm gonna get sick. [They both laugh]", "order": 34347, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 17, "scene": 43, "line": "Excuse me, excuse me. Hey guys!", "order": 34348, "speaker": "Michael Scott"},
  {"season": 6, "episode": 17, "scene": 43, "line": "[cheering Michael]", "order": 34349, "speaker": "Meredith Palmer, Creed Bratton, Oscar Martinez & Matt"},
  {"season": 6, "episode": 17, "scene": 43, "line": "Drinks are on me!", "order": 34350, "speaker": "Michael Scott"},
  {"season": 6, "episode": 17, "scene": 43, "line": "No! No, no no! Put your credit cards away. Drinks are on us!", "order": 34351, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 17, "scene": 43, "line": "Oh -hoh! All right.", "order": 34352, "speaker": "Michael Scott"},
  {"season": 6, "episode": 17, "scene": 44, "line": "Did I mess up my career today? My future prospects at Sabre? I don't know. There is a chance. Yes. I tell you I love my job. But Jo wants me to put on a show for her, and pretend to work late? Nah. I spent all day, trying to make her like me, and I forgot to ask myself something: Do I even like her? As the Irish poet Bobby McFerrin says, 'Don't worry, be happy.'", "order": 34353, "speaker": "Michael Scott"},
  {"season": 6, "episode": 17, "scene": 44, "line": "Whoops! [Humps Michael]", "order": 34354, "speaker": "Todd Packer"},
  {"season": 6, "episode": 17, "scene": 44, "line": "Okay. Okay, all right. Best night ever. [Meredith joins behind Packer] What the hell is going on back there?", "order": 34355, "speaker": "Michael Scott"},
  {"season": 6, "episode": 17, "scene": 45, "line": "Yeah. We should be able to have that right over to you by Monday. Yeah no problem. [Reveal Jim behind Quad-Desk] Thank you.", "order": 34356, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 17, "scene": 45, "line": "What the hell is this?", "order": 34357, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 17, "scene": 45, "line": "Oh!", "order": 34358, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 17, "scene": 45, "line": "This is not Mega-Desk.", "order": 34359, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 17, "scene": 45, "line": "No, it's not. They call it Quad-Desk.", "order": 34360, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 17, "scene": 45, "line": "That's ridiculous, this is made up of three desks.", "order": 34361, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 17, "scene": 45, "line": "Oh my God. We're going to have to re-name it then aren't we? [Dwight's phone rings. He crawls into nook under Jim's Quad-Desk]", "order": 34362, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 17, "scene": 45, "line": "Hello, Dwight Schrute?", "order": 34363, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 18, "scene": 1, "line": "Hey Jim, Jim. Come here. [holds up photo] Do you know who that is?", "order": 34364, "speaker": "Michael Scott"},
  {"season": 6, "episode": 18, "scene": 1, "line": "No.", "order": 34365, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 18, "scene": 1, "line": "Look at him. Look at him - t-shirt, jeans...", "order": 34366, "speaker": "Michael Scott"},
  {"season": 6, "episode": 18, "scene": 1, "line": "Is he you?", "order": 34367, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 18, "scene": 1, "line": "[laughs] I am flattered. That's Johnny Depp.", "order": 34368, "speaker": "Michael Scott"},
  {"season": 6, "episode": 18, "scene": 1, "line": "Where did you take that?", "order": 34369, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 18, "scene": 1, "line": "In my condo complex.", "order": 34370, "speaker": "Michael Scott"},
  {"season": 6, "episode": 18, "scene": 1, "line": "Oh my God, that's right. I read in People magazine that he was looking for a two-bedroom condo in Scranton.", "order": 34371, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 18, "scene": 1, "line": "I am flipping out, man. I mean, you remember my idea for the fourth Pirates movie.", "order": 34372, "speaker": "Michael Scott"},
  {"season": 6, "episode": 18, "scene": 1, "line": "Sure. That they should do one.", "order": 34373, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 18, "scene": 1, "line": "[feigning accent] Hey, Jim, Jim - where, where do I find the Black Pearl?", "order": 34374, "speaker": "Michael Scott"},
  {"season": 6, "episode": 18, "scene": 1, "line": "Who's that?", "order": 34375, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 18, "scene": 1, "line": "Captain Jack Sparrow. Captain Jack Sparrow, Jim.", "order": 34376, "speaker": "Michael Scott"},
  {"season": 6, "episode": 18, "scene": 1, "line": "John Dillinger.", "order": 34377, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 18, "scene": 1, "line": "No. I'm Captain Jack Sparrow.", "order": 34378, "speaker": "Michael Scott"},
  {"season": 6, "episode": 18, "scene": 1, "line": "Captain Crunch.", "order": 34379, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 18, "scene": 1, "line": "Okay, okay. Screw you, Halpert. You know, it's not easy getting excited about stuff. Remember how you felt when you thought you'd seen Roger Clemens?", "order": 34380, "speaker": "Michael Scott"},
  {"season": 6, "episode": 18, "scene": 1, "line": "At the Yankees game. Yeah.", "order": 34381, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 18, "scene": 1, "line": "Yeah. Well, it wasn't him.", "order": 34382, "speaker": "Michael Scott"},
  {"season": 6, "episode": 18, "scene": 1, "line": "I had a little better reason to believe that... You're right. You're right. Well, you're not right, because Johnny Depp...", "order": 34383, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 18, "scene": 1, "line": "I know, I know.", "order": 34384, "speaker": "Michael Scott"},
  {"season": 6, "episode": 18, "scene": 1, "line": "...in your condo complex.", "order": 34385, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 18, "scene": 1, "line": "I know! It also explains why the name on his mailbox was M Shulman.", "order": 34386, "speaker": "Michael Scott"},
  {"season": 6, "episode": 18, "scene": 1, "line": "M. Night Shulman?", "order": 34387, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 18, "scene": 2, "line": "[speaking at a staff meeting] First up, the lost and found has gone missing. It itself is lost, so please, try not to lose anything until we find it. Ah, pet day. If you don't have a pet, please don't feel like -", "order": 34388, "speaker": "Michael Scott"},
  {"season": 6, "episode": 18, "scene": 2, "line": "When are we getting to sales topics?", "order": 34389, "speaker": "Stanley Hudson"},
  {"season": 6, "episode": 18, "scene": 2, "line": "Yeah, what about those leads? Did you hear anything from corporate?", "order": 34390, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 18, "scene": 2, "line": "Ah, yes, the leads. The leads that Sabre has spent $50,000 to get from a market research company... are not here yet. But will be.. [muttering, people rise to leave] No, no, meeting's not over.", "order": 34391, "speaker": "Michael Scott"},
  {"season": 6, "episode": 18, "scene": 2, "line": "But is there anything relevant to the sales staff here?", "order": 34392, "speaker": "Phyllis Vance"},
  {"season": 6, "episode": 18, "scene": 2, "line": "No sales topic per se.", "order": 34393, "speaker": "Michael Scott"},
  {"season": 6, "episode": 18, "scene": 2, "line": "Well then no Andy Bernard per se. [Andy and others begin exiting the room]", "order": 34394, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 18, "scene": 2, "line": "Dwight?", "order": 34395, "speaker": "Michael Scott"},
  {"season": 6, "episode": 18, "scene": 2, "line": "Sorry, Michael, I got calls to make.", "order": 34396, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 18, "scene": 2, "line": "I would like your undivided attention, please.", "order": 34397, "speaker": "Michael Scott"},
  {"season": 6, "episode": 18, "scene": 2, "line": "You couldn't handle my undivided attention.", "order": 34398, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 18, "scene": 3, "line": "Over the last few weeks, things have been changing here. Sabre says it is our duty to support the sales team and the salesmen are letting it go to their heads. I think it's kind of screwed up, because the way this place used to work was: make friends first, make sales second, make love third, in no particular order.", "order": 34399, "speaker": "Michael Scott"},
  {"season": 6, "episode": 18, "scene": 4, "line": "Listen, Michael - about what happened earlier.", "order": 34400, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 18, "scene": 4, "line": "Yeah.", "order": 34401, "speaker": "Michael Scott"},
  {"season": 6, "episode": 18, "scene": 4, "line": "When I was asking about the leads.", "order": 34402, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 18, "scene": 4, "line": "M-hmm.", "order": 34403, "speaker": "Michael Scott"},
  {"season": 6, "episode": 18, "scene": 4, "line": "... is there any news on the leads?", "order": 34404, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 18, "scene": 4, "line": "Is that all you have to say to me?", "order": 34405, "speaker": "Michael Scott"},
  {"season": 6, "episode": 18, "scene": 4, "line": "Yeah. You got any news on the leads? ... Okay, I'll tell you what. I'm going out on a very important sales call. You get any news about the leads, you try me. All of my numbers. All six of my numbers, okay? Including the car phone! [leaves office] Alright, Dwight out!", "order": 34406, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 18, "scene": 5, "line": "Salesman is king. As the best salesman, I am king of kings. Oh, you say Jesus is king of kings? Well. What does that say to you about how I think of myself?", "order": 34407, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 18, "scene": 6, "line": "Hm-hm. Do you not answer e-mails anymore? Because I've e-mailed you four times asking you to come to my desk.", "order": 34408, "speaker": "Angela Martin"},
  {"season": 6, "episode": 18, "scene": 6, "line": "Honey, if I don't have time to answer an e-mail, I definitely don't have time to walk over to your desk.", "order": 34409, "speaker": "Phyllis Vance"},
  {"season": 6, "episode": 18, "scene": 7, "line": "Wow, wowie wowie. Congratulations. It's a big cheque. [Jim stands in corner, texting]", "order": 34410, "speaker": "Michael Scott"},
  {"season": 6, "episode": 18, "scene": 7, "line": "Oh, sorry, wait one second.", "order": 34411, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 18, "scene": 7, "line": "Stop sexting Pam. I'm trying to congratulate you.", "order": 34412, "speaker": "Michael Scott"},
  {"season": 6, "episode": 18, "scene": 7, "line": "This is actually a big potential sale, so...", "order": 34413, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 18, "scene": 7, "line": "You writing your memoirs over there?", "order": 34414, "speaker": "Michael Scott"},
  {"season": 6, "episode": 18, "scene": 7, "line": "You writing your name over there?", "order": 34415, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 18, "scene": 7, "line": "Well, it's a pretty big check.", "order": 34416, "speaker": "Michael Scott"},
  {"season": 6, "episode": 18, "scene": 7, "line": "That's good. You know, with the kid.", "order": 34417, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 18, "scene": 7, "line": "Okay. Don't gloat. Here's the thing. This kind of money can corrupt people.", "order": 34418, "speaker": "Michael Scott"},
  {"season": 6, "episode": 18, "scene": 7, "line": "Alright, here we go. [taps page] Michael...", "order": 34419, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 18, "scene": 7, "line": "I'm just saying, that -", "order": 34420, "speaker": "Michael Scott"},
  {"season": 6, "episode": 18, "scene": 7, "line": "Michael - [Michael signs] Great.", "order": 34421, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 18, "scene": 8, "line": "[on phone] Very nice. Let me just, let me just write that down real quick. [motions to Darryl] Pencil? Can you give me that pencil? Hang on Teddy... [covers phone] I'm making a sale. Sales.", "order": 34422, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 18, "scene": 8, "line": "There's other pencil's in this office.", "order": 34423, "speaker": "Darryl Philbin"},
  {"season": 6, "episode": 18, "scene": 8, "line": "Give me that [wrestles with Darryl, Andy falls over chair and to the floor]", "order": 34424, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 18, "scene": 9, "line": "Honey and jelly sandwich time. [removes lunch from fridge]", "order": 34425, "speaker": "Michael Scott"},
  {"season": 6, "episode": 18, "scene": 9, "line": "Michael.", "order": 34426, "speaker": "Darryl Philbin"},
  {"season": 6, "episode": 18, "scene": 9, "line": "Oh, you got to be kidding me. [holds up squashed sandwich] Look at that. That's -", "order": 34427, "speaker": "Michael Scott"},
  {"season": 6, "episode": 18, "scene": 9, "line": "I know who did that.", "order": 34428, "speaker": "Darryl Philbin"},
  {"season": 6, "episode": 18, "scene": 9, "line": "You saw who did this and you didn't stop them?", "order": 34429, "speaker": "Michael Scott"},
  {"season": 6, "episode": 18, "scene": 9, "line": "Didn't have to see. It was sales. I can feel it. They are out of control.", "order": 34430, "speaker": "Darryl Philbin"},
  {"season": 6, "episode": 18, "scene": 9, "line": "The sales department smashed my sandwich.", "order": 34431, "speaker": "Michael Scott"},
  {"season": 6, "episode": 18, "scene": 9, "line": "Yes. All of 'em. Together. It's a conspiracy. Listen to me, Mike, you gotta do something about them.", "order": 34432, "speaker": "Darryl Philbin"},
  {"season": 6, "episode": 18, "scene": 9, "line": "You don't get it.", "order": 34433, "speaker": "Michael Scott"},
  {"season": 6, "episode": 18, "scene": 9, "line": "You need to get back on top.", "order": 34434, "speaker": "Darryl Philbin"},
  {"season": 6, "episode": 18, "scene": 9, "line": "That's what she said.", "order": 34435, "speaker": "Michael Scott"},
  {"season": 6, "episode": 18, "scene": 9, "line": "Yeah.", "order": 34436, "speaker": "Darryl Philbin"},
  {"season": 6, "episode": 18, "scene": 9, "line": "Yeah.", "order": 34437, "speaker": "Michael Scott"},
  {"season": 6, "episode": 18, "scene": 10, "line": "Something came for you, Michael. [hands him a parcel]", "order": 34438, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 18, "scene": 10, "line": "Thank you very much.", "order": 34439, "speaker": "Michael Scott"},
  {"season": 6, "episode": 18, "scene": 10, "line": "What'd you get?", "order": 34440, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 18, "scene": 10, "line": "Ah, just the stupid leads.", "order": 34441, "speaker": "Michael Scott"},
  {"season": 6, "episode": 18, "scene": 10, "line": "Alright!", "order": 34442, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 18, "scene": 10, "line": "About time.", "order": 34443, "speaker": "Stanley Hudson"},
  {"season": 6, "episode": 18, "scene": 10, "line": "Me likey!", "order": 34444, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 18, "scene": 10, "line": "Finally, Michael. Hand 'em over, numbnuts. [Michael stares at her incredulously] But seriously. It's your job to give us those leads.", "order": 34445, "speaker": "Phyllis Vance"},
  {"season": 6, "episode": 18, "scene": 10, "line": "Alright. Then I guess I should give them out. Hold on. You know what I'm thinking? I'm thinking that I have something that you want. You guys are acting like you own this place. And you don't. Not even Sabre owns this place. It's a rental. I'm kind of sick of the attitude around here. I'm sick of the cocky walk, I am sick of you throwing your paycheck in my face, I am sick of your uppity attitude, Jim... I think I am not going to give these to you. [Angela, Kevin and Oscar applaud]", "order": 34446, "speaker": "Michael Scott"},
  {"season": 6, "episode": 18, "scene": 10, "line": "We need those leads, Michael. It's our job Michael. Michael!", "order": 34447, "speaker": "Stanley Hudson"},
  {"season": 6, "episode": 18, "scene": 11, "line": "[on phone] Michael, we spent a lot of money on those leads. You have to give them out.", "order": 34448, "speaker": "Gabe Lewis"},
  {"season": 6, "episode": 18, "scene": 11, "line": "Then we are just rewarding their bad behaviour. Okay. Just - imagine that instead of going to jail for murdering someone, you got an ice-cream cone. If that were the case, then in the summertime, everyone would go around killing people for the pleasure of an ice-cream cone.", "order": 34449, "speaker": "Michael Scott"},
  {"season": 6, "episode": 18, "scene": 11, "line": "Michael, I don't want to incentivise murder. But - we've tried to make it clear that our policy emphasises sales staff -", "order": 34450, "speaker": "Gabe Lewis"},
  {"season": 6, "episode": 18, "scene": 11, "line": "They act like I have no power.", "order": 34451, "speaker": "Michael Scott"},
  {"season": 6, "episode": 18, "scene": 11, "line": "But you do. You are in charge -", "order": 34452, "speaker": "Gabe Lewis"},
  {"season": 6, "episode": 18, "scene": 11, "line": "Thank you.", "order": 34453, "speaker": "Miichael"},
  {"season": 6, "episode": 18, "scene": 11, "line": "- of supporting the sales staff. You are required to hand out those leads, Michael.", "order": 34454, "speaker": "Gabe Lewis"},
  {"season": 6, "episode": 18, "scene": 11, "line": "Hmm. Well, if that is what I am required to do, I will do exactly that.", "order": 34455, "speaker": "Michael Scott"},
  {"season": 6, "episode": 18, "scene": 11, "line": "Okay, good.", "order": 34456, "speaker": "Gabe Lewis"},
  {"season": 6, "episode": 18, "scene": 11, "line": "Exactly that.", "order": 34457, "speaker": "Michael Scott"},
  {"season": 6, "episode": 18, "scene": 11, "line": "Good.", "order": 34458, "speaker": "Gabe Lewis"},
  {"season": 6, "episode": 18, "scene": 11, "line": "Exactly that.", "order": 34459, "speaker": "Michael Scott"},
  {"season": 6, "episode": 18, "scene": 11, "line": "Why do you keep repeating - [Michael hangs up]", "order": 34460, "speaker": "Gabe Lewis"},
  {"season": 6, "episode": 18, "scene": 12, "line": "Hello. May I have everyone's attention, please? Gabe has instructed me to hand out the leads, so I'm going to give the leads to... King Creed! [hands him a lead]", "order": 34461, "speaker": "Michael Scott"},
  {"season": 6, "episode": 18, "scene": 12, "line": "What are you -", "order": 34462, "speaker": "Phyllis Vance"},
  {"season": 6, "episode": 18, "scene": 12, "line": "- and to King Meredith! [hands her a lead]", "order": 34463, "speaker": "Michael Scott"},
  {"season": 6, "episode": 18, "scene": 12, "line": "They aren't salespeople!", "order": 34464, "speaker": "Stanley Hudson"},
  {"season": 6, "episode": 18, "scene": 12, "line": "And to King Angela! [hands her a lead] Because today we are all kings. And queens [pats Oscar's shoulder].", "order": 34465, "speaker": "Michael Scott"},
  {"season": 6, "episode": 18, "scene": 12, "line": "What the hell do you think you're doing?", "order": 34466, "speaker": "Phyllis Vance"},
  {"season": 6, "episode": 18, "scene": 12, "line": "I'm giving them the leads, Phyllis.", "order": 34467, "speaker": "Michael Scott"},
  {"season": 6, "episode": 18, "scene": 13, "line": "Hey.", "order": 34468, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 18, "scene": 13, "line": "Hey.", "order": 34469, "speaker": "Michael Scott"},
  {"season": 6, "episode": 18, "scene": 13, "line": "So I'm going to go ahead and save you some time, and tell you that no-one's going to go along with this. But you knew that, right?", "order": 34470, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 18, "scene": 13, "line": "Mm-hmm.", "order": 34471, "speaker": "Michael Scott"},
  {"season": 6, "episode": 18, "scene": 13, "line": "Alright. So why don't you just give me my share of the leads, and I'll start making some calls?", "order": 34472, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 18, "scene": 13, "line": "Okay. [passes Jim some index cards]", "order": 34473, "speaker": "Michael Scott"},
  {"season": 6, "episode": 18, "scene": 13, "line": "Hey, alright.", "order": 34474, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 18, "scene": 13, "line": "Alright.", "order": 34475, "speaker": "Michael Scott"},
  {"season": 6, "episode": 18, "scene": 13, "line": "Ahh, these aren't leads. What are they?", "order": 34476, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 18, "scene": 13, "line": "Oh, right, those are clues. And within each clue is a lesson. You learn a lesson, and then you find a lead. The leads are scattered all over the industrial park. I am trying to make your kids respect you. Because a father needs to respect his boss, and kids don't respect the father who doesn't respect the boss. Do you understand that line of logic?", "order": 34477, "speaker": "Michael Scott"},
  {"season": 6, "episode": 18, "scene": 13, "line": "I don't think you understand -", "order": 34478, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 18, "scene": 13, "line": "I do understand it.", "order": 34479, "speaker": "Michael Scott"},
  {"season": 6, "episode": 18, "scene": 13, "line": "[holds up card] This one's a map.", "order": 34480, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 18, "scene": 13, "line": "Or is it?", "order": 34481, "speaker": "Michael Scott"},
  {"season": 6, "episode": 18, "scene": 14, "line": "Oh, how the tables have turned. I see you got my e-mail.", "order": 34482, "speaker": "Angela Martin"},
  {"season": 6, "episode": 18, "scene": 14, "line": "Are you gonna give me the leads or not?", "order": 34483, "speaker": "Phyllis Vance"},
  {"season": 6, "episode": 18, "scene": 14, "line": "I'll give you the leads. But you know what? It's going to cost you some clerical work [hands her a mountain of paperwork]", "order": 34484, "speaker": "Angela Martin"},
  {"season": 6, "episode": 18, "scene": 14, "line": "What are these for?", "order": 34485, "speaker": "Phyllis Vance"},
  {"season": 6, "episode": 18, "scene": 14, "line": "It doesn't matter. Fill them out. All of them. Then when you're done, you can watch me shred them.", "order": 34486, "speaker": "Angela Martin"},
  {"season": 6, "episode": 18, "scene": 15, "line": "I want to watch the Kardashians! I don't want to watch boring -", "order": 34487, "speaker": "Kelly Kapoor"},
  {"season": 6, "episode": 18, "scene": 15, "line": "It's my -", "order": 34488, "speaker": "Ryan Howard"},
  {"season": 6, "episode": 18, "scene": 15, "line": "The Kardashians is a good show.", "order": 34489, "speaker": "Stanley Hudson"},
  {"season": 6, "episode": 18, "scene": 15, "line": "No it's - how would he even know the Kardashians?", "order": 34490, "speaker": "Ryan Howard"},
  {"season": 6, "episode": 18, "scene": 15, "line": "It's about a family. A real-life family.", "order": 34491, "speaker": "Kelly Kapoor"},
  {"season": 6, "episode": 18, "scene": 15, "line": "No, Stanley, do you -", "order": 34492, "speaker": "Ryan Howard"},
  {"season": 6, "episode": 18, "scene": 16, "line": "[over phone] Hey baby, what's up?", "order": 34493, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 18, "scene": 16, "line": "I am currently reading incoherent riddles on blue index cards to find vital information that Michael has hidden all over the office. How are you?", "order": 34494, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 18, "scene": 16, "line": "Nothing but vomit and diapers over here.", "order": 34495, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 18, "scene": 16, "line": "Oh my god, I couldn't envy you more.", "order": 34496, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 18, "scene": 17, "line": "Having a baby is as exhausting as they say it is. Having two babies [holds up index cards] - that's just unfair.", "order": 34497, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 18, "scene": 18, "line": "How about this one - 'When arrogant salesmen are mean to my face, a certain manager will go to his moppy place.'", "order": 34498, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 18, "scene": 18, "line": "He means his mopey place, it's under that streetlamp that he thinks was in Casablanca.", "order": 34499, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 18, "scene": 18, "line": "I love you.", "order": 34500, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 18, "scene": 19, "line": "Today I turned an office crisis into a teachable moment. [cuts to Jim fishing one of the leads out of a catering size tin of Ravioli] A lesser manager would have screwed this day up royally [clue next to Jim reads: 'Now that's Italian!'] Some yokel sitting behind his desk saying take a lead, learn nothing [cut to scenes of chaos as everyone argues] Some people shouldn't be in this business.", "order": 34501, "speaker": "Michael Scott"},
  {"season": 6, "episode": 18, "scene": 20, "line": "Hey. I guess you probably won't give me your leads since I'm a jerk salesman.", "order": 34502, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 18, "scene": 20, "line": "Yeah. I basically wish you were dead. [grins] I hid the leads.", "order": 34503, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 18, "scene": 20, "line": "Where?", "order": 34504, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 18, "scene": 20, "line": "[mimes zipping mouth and throwing away key, Andy begins to walk away] Colder. Colder. Warmer. Warmer. Colder. Colder. Warmer. Warmer. Warmer. Hot. [Andy hovers his hands above her chest] Hotter. Pretty hot. Lower.", "order": 34505, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 18, "scene": 20, "line": "Are you...", "order": 34506, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 18, "scene": 20, "line": "Lower.", "order": 34507, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 18, "scene": 20, "line": "Are you sure?", "order": 34508, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 18, "scene": 20, "line": "Lower [tilts head toward her desk, then flips over her keyboard, revealing the leads. Andy scratches his head, recovering]", "order": 34509, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 18, "scene": 21, "line": "What are you doing, idiot? [Jim is crouched down, peering under a car]", "order": 34510, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 18, "scene": 21, "line": "Michael's stupid scavenger hunt.", "order": 34511, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 18, "scene": 21, "line": "Scavenger hunt? Here. I can solve it. Give it. [grabs card] 'The treasure you seek is in the parking lot under the first president.'", "order": 34512, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 18, "scene": 21, "line": "[taps car] Lincoln.", "order": 34513, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 18, "scene": 21, "line": "The prankster is getting pranked. Ha ha.", "order": 34514, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 18, "scene": 21, "line": "[holds up the retrieved lead] It involves you too.", "order": 34515, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 18, "scene": 21, "line": "The leads are in?", "order": 34516, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 18, "scene": 22, "line": "Michael? Michael, you were supposed to tell me when the leads came in.", "order": 34517, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 18, "scene": 22, "line": "Well, bigshot, 'If you want to find your leads, go to the man who never breathes'.", "order": 34518, "speaker": "Michael Scott"},
  {"season": 6, "episode": 18, "scene": 22, "line": "Kevin! Damnit [runs over to Kevin's desk] Kevin, give me those leads. Where are they? C'mon -", "order": 34519, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 18, "scene": 22, "line": "You are never going to find them.", "order": 34520, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 18, "scene": 22, "line": "Really.", "order": 34521, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 18, "scene": 22, "line": "I'm going to enjoy this.", "order": 34522, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 18, "scene": 22, "line": "[begins to strangle him] Gimme the leads. Where are my leads?", "order": 34523, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 18, "scene": 22, "line": "[muffled] I'm still enjoying it.", "order": 34524, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 18, "scene": 22, "line": "Where are they?", "order": 34525, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 18, "scene": 22, "line": "Turn the trash. Turn the trash.", "order": 34526, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 18, "scene": 22, "line": "Turn the trash [releases him - Kevin coughs] It's code. Alright, Meredith, take off your dress.", "order": 34527, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 18, "scene": 22, "line": "[stands up and reaches for her buttons] Okey-dokey.", "order": 34528, "speaker": "Meredith Palmer"},
  {"season": 6, "episode": 18, "scene": 22, "line": "No, dear god, no, it's in the trash can. In the kitchen.", "order": 34529, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 18, "scene": 22, "line": "It's coming off anyway.", "order": 34530, "speaker": "Meredith Palmer"},
  {"season": 6, "episode": 18, "scene": 22, "line": "[dashes to kitchen, overturns the trash can, tastes the bin liner] Clean sack.", "order": 34531, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 18, "scene": 22, "line": "What -", "order": 34532, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 18, "scene": 22, "line": "I emptied it in the dumpster. With Toby's baba ganoush.", "order": 34533, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 18, "scene": 23, "line": "If we don't patronise the only Syrian restaurant in town, there'll be nothing left but pan pizzas and make-your-own salads.", "order": 34534, "speaker": "Toby Flenderson"},
  {"season": 6, "episode": 18, "scene": 24, "line": "[dives into dumpster, rattling noises then he emerges] It's empty!", "order": 34535, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 18, "scene": 24, "line": "Wait. What day is today?", "order": 34536, "speaker": "Michael Scott"},
  {"season": 6, "episode": 18, "scene": 24, "line": "Um, tonight is Ghost Whisperer. So, Friday.", "order": 34537, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 18, "scene": 24, "line": "Oh my god. Oh my god [takes off running] oh my god, no, no no, hey, hey [chases after garbage van] wait, wait!", "order": 34538, "speaker": "Michael Scott"},
  {"season": 6, "episode": 18, "scene": 25, "line": "Okay. You know what, let's just go to the dump, start looking - Ryan, c'mon, shotgun in my car -", "order": 34539, "speaker": "Michael Scott"},
  {"season": 6, "episode": 18, "scene": 25, "line": "Michael, why would we all go to the dump?", "order": 34540, "speaker": "Phyllis Vance"},
  {"season": 6, "episode": 18, "scene": 25, "line": "Why? Because I am not going to call Sabre and say, 'Hi, you know those very valuable expensive leads that you gave us earlier today? Well, because of a screw-up by a staff member, they're now in the city dump.'", "order": 34541, "speaker": "Michael Scott"},
  {"season": 6, "episode": 18, "scene": 25, "line": "Not your staff, Michael. You.", "order": 34542, "speaker": "Stanley Hudson"},
  {"season": 6, "episode": 18, "scene": 25, "line": "Well, that's not the way it's going t sound. Here's what we're going to do. We'll go to the dump, we'll look around, then we'll all go out and get pizza, maybe catch a movie, late-night drink, some more pizza, call it a night. What do you -", "order": 34543, "speaker": "Michael Scott"},
  {"season": 6, "episode": 18, "scene": 25, "line": "Does it have to be pizza? Could go for a, falafel?", "order": 34544, "speaker": "Toby Flenderson"},
  {"season": 6, "episode": 18, "scene": 25, "line": "Really?", "order": 34545, "speaker": "Michael Scott"},
  {"season": 6, "episode": 18, "scene": 25, "line": "I'm not going. You did this, not us.", "order": 34546, "speaker": "Angela Martin"},
  {"season": 6, "episode": 18, "scene": 25, "line": "Okay, no, you encouraged it. You were complicit [looks to Jim for vocab-reassurance] Complicit. You were all successories!", "order": 34547, "speaker": "Michael Scott"},
  {"season": 6, "episode": 18, "scene": 25, "line": "That sounds fun, mucking around in the dump. Wish I could go.", "order": 34548, "speaker": "Darryl Philbin"},
  {"season": 6, "episode": 18, "scene": 25, "line": "You can.", "order": 34549, "speaker": "Michael Scott"},
  {"season": 6, "episode": 18, "scene": 25, "line": "I can't. You know that.", "order": 34550, "speaker": "Darryl Philbin"},
  {"season": 6, "episode": 18, "scene": 25, "line": "Okay fine, I get it. I'll just go by myself.", "order": 34551, "speaker": "Michael Scott"},
  {"season": 6, "episode": 18, "scene": 25, "line": "I'll go, Michael. You'll just screw it up.", "order": 34552, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 18, "scene": 26, "line": "[looks around dump] This place has gone to hell.", "order": 34553, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 18, "scene": 26, "line": "You know, Dwight, there was a time when you'd be pinching yourself to have the opportunity to look through a dump with me.", "order": 34554, "speaker": "Michael Scott"},
  {"season": 6, "episode": 18, "scene": 26, "line": "Yeah, well, the acorn becomes the oak.", "order": 34555, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 18, "scene": 26, "line": "Yeah. Sometimes the acorn just stays the acorn. If you don't believe me, look in my gutters.", "order": 34556, "speaker": "Michael Scott"},
  {"season": 6, "episode": 18, "scene": 27, "line": "[to Oscar] You're adorable. You need to go for it. [Jim enters the break room] I'm going to be, like, mad at you if you don't -", "order": 34557, "speaker": "Kelly Kapoor"},
  {"season": 6, "episode": 18, "scene": 27, "line": "Phew! Can't wait for this day to be over.", "order": 34558, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 18, "scene": 27, "line": "Why?", "order": 34559, "speaker": "Kelly Kapoor"},
  {"season": 6, "episode": 18, "scene": 27, "line": "Just all the - drama.", "order": 34560, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 18, "scene": 27, "line": "What drama?", "order": 34561, "speaker": "Kelly Kapoor"},
  {"season": 6, "episode": 18, "scene": 27, "line": "Between the - us and you guys. It's unnecessary, right?", "order": 34562, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 18, "scene": 27, "line": "So unnecessary.", "order": 34563, "speaker": "Kelly Kapoor"},
  {"season": 6, "episode": 18, "scene": 27, "line": "Oh, good, phew, thank god you said that.", "order": 34564, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 18, "scene": 27, "line": "I mean, if the salesmen weren't acting like such a bunch of stuck-up losers, then this day wouldn't be so bad - did you ever think of that?", "order": 34565, "speaker": "Kelly Kapoor"},
  {"season": 6, "episode": 18, "scene": 27, "line": "I have new baby pictures.", "order": 34566, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 18, "scene": 27, "line": "Don't use your cute baby to make us like you.", "order": 34567, "speaker": "Kelly Kapoor"},
  {"season": 6, "episode": 18, "scene": 27, "line": "She's wearing a onesie [holds out his cell phone, Oscar leans in but Kelly grabs his arm]", "order": 34568, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 18, "scene": 28, "line": "If we act nice now, then we're rewarding them for treating us poorly.", "order": 34569, "speaker": "Stanley Hudson"},
  {"season": 6, "episode": 18, "scene": 28, "line": "Didn't we kind of start it?", "order": 34570, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 18, "scene": 28, "line": "I think you're remembering that wrong?", "order": 34571, "speaker": "Phyllis Vance"},
  {"season": 6, "episode": 18, "scene": 28, "line": "Yeah.", "order": 34572, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 18, "scene": 28, "line": "I don't know about this. I mean, I think we should hold our ground. The company's changed, and if they don't like it, they can leave. I mean, a lot of their work can be done from India.", "order": 34573, "speaker": "Phyllis Vance"},
  {"season": 6, "episode": 18, "scene": 28, "line": "Can we at least all agree that this is uncomfortable, and may be heading for something bad?", "order": 34574, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 18, "scene": 28, "line": "All those who agree, say aye [all present - Stanley, Andy and Phyllis - raise their hands] All those opposed -", "order": 34575, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 18, "scene": 28, "line": "I don't think we need opposed.", "order": 34576, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 18, "scene": 29, "line": "[kicking through rubbish at the dump] You've changed, man.", "order": 34577, "speaker": "Michael Scott"},
  {"season": 6, "episode": 18, "scene": 29, "line": "Oh, why, because I have a shot at a $100k commission?", "order": 34578, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 18, "scene": 29, "line": "Since when do you care about money? When I first met you, you were a wide-eyed innocent.", "order": 34579, "speaker": "Michael Scott"},
  {"season": 6, "episode": 18, "scene": 29, "line": "Hey. There is nothing I can do about my wide-set eyes.", "order": 34580, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 18, "scene": 29, "line": "No, I'm talking about your personality, Dwight.", "order": 34581, "speaker": "Michael Scott"},
  {"season": 6, "episode": 18, "scene": 29, "line": "When I first met you, I had a lot of job offers. And I had an offer from Ivan Schartsky. The Ivan Schartsky. And if I'd assistant managed him -", "order": 34582, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 18, "scene": 29, "line": "Assistant to the managed him -", "order": 34583, "speaker": "Michael Scott"},
  {"season": 6, "episode": 18, "scene": 29, "line": "Oh, that's low! I would be number two right now at Home Depot!", "order": 34584, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 18, "scene": 29, "line": "Yeah.", "order": 34585, "speaker": "Michael Scott"},
  {"season": 6, "episode": 18, "scene": 29, "line": "Okay? Because they promote from within. Instead I had to follow you - you, going nowhere.", "order": 34586, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 18, "scene": 29, "line": "You think you would have done better without me? Really?", "order": 34587, "speaker": "Michael Scott"},
  {"season": 6, "episode": 18, "scene": 29, "line": "I hitched my wagon to a horse with no legs.", "order": 34588, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 18, "scene": 29, "line": "Wow. Ohhhkay. Man. When I think about all the time I wasted being your friend - I shouldn't have been hanging out watching karate movies with you -", "order": 34589, "speaker": "Michael Scott"},
  {"season": 6, "episode": 18, "scene": 29, "line": "Kung-fu movies!", "order": 34590, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 18, "scene": 29, "line": "You know what, I should have been doing? I should have been out at bars, finding my soul-mate, finding my wife, making babies -", "order": 34591, "speaker": "Michael Scott"},
  {"season": 6, "episode": 18, "scene": 29, "line": "Nice babies you're making with the floozies at the bars.", "order": 34592, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 18, "scene": 29, "line": "That's my wife you're talking about, man.", "order": 34593, "speaker": "Michael Scott"},
  {"season": 6, "episode": 18, "scene": 29, "line": "Your made-up wife? Who doesn't exist? [Michael picks up a piece of metal and hurls it at Dwight, who sidesteps] You watch it!", "order": 34594, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 18, "scene": 29, "line": "If I wanted to hit you, I would have hit you.", "order": 34595, "speaker": "Michael Scott"},
  {"season": 6, "episode": 18, "scene": 29, "line": "Don't do it [lobs something at Michael]", "order": 34596, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 18, "scene": 29, "line": "No no. You just made an enemy with the wrong guy, Dwight. [they throw volleys of rubbish at each other] No, time out, time out. [Michael inspects a box, then hurls it at Dwight] Time in!", "order": 34597, "speaker": "Michael Scott"},
  {"season": 6, "episode": 18, "scene": 29, "line": "No! [lunges for a large wooden spool]", "order": 34598, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 18, "scene": 29, "line": "Don't even think about that [Dwight begins rolling it towards Michael, as he reaches him Dwight kicks it and falls backward. The spool comes to a halt in front of Michael, and Michael pushes it towards Dwight.]", "order": 34599, "speaker": "Michael Scott"},
  {"season": 6, "episode": 18, "scene": 29, "line": "[kicks spool] Stop it, get out! That's my spool.", "order": 34600, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 18, "scene": 30, "line": "What's the least we can do to make this okay?", "order": 34601, "speaker": "Phyllis Vance"},
  {"season": 6, "episode": 18, "scene": 30, "line": "I'll text Pam. She's really good at this stuff.", "order": 34602, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 18, "scene": 30, "line": "And I'll text Erin. She's really good at this stuff too.", "order": 34603, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 18, "scene": 30, "line": "I guess we could give them some of our new commissions.", "order": 34604, "speaker": "Phyllis Vance"},
  {"season": 6, "episode": 18, "scene": 30, "line": "That is a dangerous precedent.", "order": 34605, "speaker": "Stanley Hudson"},
  {"season": 6, "episode": 18, "scene": 30, "line": "Pam texted back saying we could give them all iPods.", "order": 34606, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 18, "scene": 30, "line": "Oh, if they don't have an iPod by now they really don't want one.", "order": 34607, "speaker": "Phyllis Vance"},
  {"season": 6, "episode": 18, "scene": 30, "line": "Alright. Then we're back to cash. And I got to say, if giving a small percentage of our commissions is going to smooth all this over, I'm for it.", "order": 34608, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 18, "scene": 30, "line": "Okay, fine. Cash it is.", "order": 34609, "speaker": "Phyllis Vance"},
  {"season": 6, "episode": 18, "scene": 30, "line": "Erin just texted me back. 'People love shells from far-away beaches'.", "order": 34610, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 18, "scene": 31, "line": "Okay, Dwight. Here we go [picks up a sink, spills dump juice on his pants] oh god.", "order": 34611, "speaker": "Michael Scott"},
  {"season": 6, "episode": 18, "scene": 31, "line": "Oh [half-heartedly throws something]", "order": 34612, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 18, "scene": 31, "line": "We're never going to find those leads, are we? [they both sit on the edge of a claw-footed bathtub]", "order": 34613, "speaker": "Michael Scott"},
  {"season": 6, "episode": 18, "scene": 31, "line": "[surveys the mountains of trash surrounding them] Wow. Amazing, isn't it. No other animal on earth could do this. Maybe beavers. But not like this.", "order": 34614, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 18, "scene": 32, "line": "So how about, guys, one percent commission a month instead of two, what do you -", "order": 34615, "speaker": "Phyllis Vance"},
  {"season": 6, "episode": 18, "scene": 32, "line": "No, we agreed, two percent for the quarter. Okay [others enter] Hey everybody!", "order": 34616, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 18, "scene": 32, "line": "[stares at the table filled with treats] Nice spread. We get it. You eat like royalty.", "order": 34617, "speaker": "Meredith Palmer"},
  {"season": 6, "episode": 18, "scene": 32, "line": "No, no - this is a representation of how we feel. And how we feel is: really sorry.", "order": 34618, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 18, "scene": 32, "line": "Yeah. We wanted to bring you in and make a peace offering.", "order": 34619, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 18, "scene": 32, "line": "Yeah, we wanted to do the right thing. So... [Jim gestures for her to continue]", "order": 34620, "speaker": "Phyllis Vance"},
  {"season": 6, "episode": 18, "scene": 32, "line": "Way to go, guys. This was an integrity move.", "order": 34621, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 18, "scene": 32, "line": "Yeah. When we walked in here, we were all prepared to tell you to go to hell.", "order": 34622, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 18, "scene": 32, "line": "Do you have any pastries without fruit?", "order": 34623, "speaker": "Angela Martin"},
  {"season": 6, "episode": 18, "scene": 32, "line": "Yes! We do. [opens box] Eclairs.", "order": 34624, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 18, "scene": 32, "line": "[enters room] Well, you better be happy, taking two percent of our -", "order": 34625, "speaker": "Stanley Hudson"},
  {"season": 6, "episode": 18, "scene": 32, "line": "- two percent milk! What I forgot for the coffee.", "order": 34626, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 18, "scene": 32, "line": "Our treats, Stanley, they've accepted our simple offer of treats. Nothing more.", "order": 34627, "speaker": "Phyllis Vance"},
  {"season": 6, "episode": 18, "scene": 32, "line": "[beams] This is - nice! [pats Oscar's shoulder] All of us back together.", "order": 34628, "speaker": "Stanley Hudson"},
  {"season": 6, "episode": 18, "scene": 33, "line": "This [picks up racquet] Why would somebody throw that out?", "order": 34629, "speaker": "Michael Scott"},
  {"season": 6, "episode": 18, "scene": 33, "line": "Hey [holds up an old sweater] You know who'd like this? Phyllis. Purple, much?", "order": 34630, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 18, "scene": 33, "line": "Yeah, she does - she loves purple. [Dwight sniffs it] Does it stink?", "order": 34631, "speaker": "Michael Scott"},
  {"season": 6, "episode": 18, "scene": 33, "line": "Yeah [puts it back in the bath tub]", "order": 34632, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 18, "scene": 33, "line": "Know what would be a great picture here? Just this whole dump, and in the middle, one flower. That's it. And the caption would read:", "order": 34633, "speaker": "Michael Scott"},
  {"season": 6, "episode": 18, "scene": 33, "line": "Hope - grows.", "order": 34634, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 18, "scene": 33, "line": "In the dump.", "order": 34635, "speaker": "Michael Scott"},
  {"season": 6, "episode": 18, "scene": 34, "line": "[drives into office parking lot, honking horn. Enormous purple object strapped to the roof of their car] Woo hoo!", "order": 34636, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 18, "scene": 34, "line": "Good news that you found our leads?", "order": 34637, "speaker": "Stanley Hudson"},
  {"season": 6, "episode": 18, "scene": 34, "line": "No! Better!", "order": 34638, "speaker": "Michael Scott"},
  {"season": 6, "episode": 18, "scene": 34, "line": "We have an awesome bean bag chair that's perfect for the break room. [reaches out the window and pats it]", "order": 34639, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 18, "scene": 34, "line": "Yuck. I'm not going to sit on that disgusting seat.", "order": 34640, "speaker": "Phyllis Vance"},
  {"season": 6, "episode": 18, "scene": 34, "line": "Yeah, damn right you're not. 'Cause it's for me and Michael only [they both cheer and high-five]", "order": 34641, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 18, "scene": 35, "line": "[at dump] It's freezing out here.", "order": 34642, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 18, "scene": 35, "line": "[strips off jacket] Go on. I have warm blood. [tries to drape her small jacket across Andy's back]", "order": 34643, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 18, "scene": 35, "line": "Oh wow, thank you. You're the nicest person I've ever met. [Andy and Erin kiss]", "order": 34644, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 19, "scene": 1, "line": "[Michael grunts and strains while doing push-ups] Breathe. Work your core. Come on.", "order": 34645, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 19, "scene": 1, "line": "How many is that?", "order": 34646, "speaker": "Michael Scott"},
  {"season": 6, "episode": 19, "scene": 1, "line": "Not counting the last one, 25.", "order": 34647, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 19, "scene": 1, "line": "Count the last one.", "order": 34648, "speaker": "Michael Scott"},
  {"season": 6, "episode": 19, "scene": 1, "line": "Ok, 25 and one girl push-up!", "order": 34649, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 19, "scene": 1, "line": "Oh, new record!", "order": 34650, "speaker": "Michael Scott"},
  {"season": 6, "episode": 19, "scene": 1, "line": "Okay.", "order": 34651, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 19, "scene": 1, "line": "Oh, what did you do today?", "order": 34652, "speaker": "Michael Scott"},
  {"season": 6, "episode": 19, "scene": 1, "line": "I made a sale.", "order": 34653, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 19, "scene": 1, "line": "Oh, yeah, sitting on your big fat butt. Alright, that is the number to beat.", "order": 34654, "speaker": "Michael Scott"},
  {"season": 6, "episode": 19, "scene": 1, "line": "What do we get if we do 'em?", "order": 34655, "speaker": "Meredith Palmer"},
  {"season": 6, "episode": 19, "scene": 1, "line": "My respect. [everyone returns to work] Okay, I'll make it a little more interesting. Anyone who does more than 25 push-ups -", "order": 34656, "speaker": "Michael Scott"},
  {"season": 6, "episode": 19, "scene": 1, "line": "And one girl push-up.", "order": 34657, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 19, "scene": 1, "line": "Gets to go home. [everyone starts to do push-ups] Ooh! I say 'dance,' they say 'how high?' Here we go. Oh, no. No, no, no. Butt to high. [steps on Angela] Disqualified!", "order": 34658, "speaker": "Michael Scott"},
  {"season": 6, "episode": 19, "scene": 1, "line": "Ow!", "order": 34659, "speaker": "Angela Martin"},
  {"season": 6, "episode": 19, "scene": 1, "line": "What do we got? Creed, disqualified.", "order": 34660, "speaker": "Michael Scott"},
  {"season": 6, "episode": 19, "scene": 1, "line": "[from desk chair] Oh, come on!", "order": 34661, "speaker": "Creed Bratton"},
  {"season": 6, "episode": 19, "scene": 1, "line": "19. [grunts] I had a really hard work out this morning.", "order": 34662, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 19, "scene": 1, "line": "[Stanley straining and breathing heavily] Oh, wow, that is adorable!", "order": 34663, "speaker": "Michael Scott"},
  {"season": 6, "episode": 19, "scene": 1, "line": "Ten...", "order": 34664, "speaker": "Phyllis Vance"},
  {"season": 6, "episode": 19, "scene": 1, "line": "Yeah, I'm betting one more.", "order": 34665, "speaker": "Michael Scott"},
  {"season": 6, "episode": 19, "scene": 1, "line": "Eleven, wow!", "order": 34666, "speaker": "Phyllis Vance"},
  {"season": 6, "episode": 19, "scene": 1, "line": "Good.", "order": 34667, "speaker": "Michael Scott"},
  {"season": 6, "episode": 19, "scene": 1, "line": "[chanting] Stan-ley! Stan-ley! Stan-ley...", "order": 34668, "speaker": "Everyone"},
  {"season": 6, "episode": 19, "scene": 1, "line": "Alright, alright. [chanting continues]", "order": 34669, "speaker": "Michael Scott"},
  {"season": 6, "episode": 19, "scene": 2, "line": "Essentially, what we have here is one of those stories where a mother lifts a car to save her baby.", "order": 34670, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 19, "scene": 3, "line": "Well, it's not exactly fair. He's got all of his weight that's helping him go down.", "order": 34671, "speaker": "Michael Scott"},
  {"season": 6, "episode": 19, "scene": 4, "line": "25. Yeah! Come on, you can do it!", "order": 34672, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 19, "scene": 4, "line": "One more, one more! [cheers and applause]", "order": 34673, "speaker": "Phyllis Vance"},
  {"season": 6, "episode": 19, "scene": 4, "line": "You okay? You okay, Stanley?", "order": 34674, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 19, "scene": 4, "line": "Excuse me.", "order": 34675, "speaker": "Stanley Hudson"},
  {"season": 6, "episode": 19, "scene": 4, "line": "Wow. [applause]", "order": 34676, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 19, "scene": 5, "line": "Hey. Matt, right?", "order": 34677, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 19, "scene": 5, "line": "Hey, Oscar. You're here early.", "order": 34678, "speaker": "Matt"},
  {"season": 6, "episode": 19, "scene": 5, "line": "I always come in at 7.", "order": 34679, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 19, "scene": 5, "line": "No, you don't.", "order": 34680, "speaker": "Warehouse Guy"},
  {"season": 6, "episode": 19, "scene": 5, "line": "Well... Hey, uh, are you doing anything later tonight?", "order": 34681, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 19, "scene": 5, "line": "I don't know. I'm free. If you hear of anything going on, let me know.", "order": 34682, "speaker": "Matt"},
  {"season": 6, "episode": 19, "scene": 5, "line": "Yeah.", "order": 34683, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 19, "scene": 6, "line": "Yeah, we talked this morning and we talked at Christmas. So, a little momentum there. [tries to open door, locked] Just a couple of hours to kill before work.", "order": 34684, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 19, "scene": 7, "line": "Hey, what's up?", "order": 34685, "speaker": "Darryl Philbin"},
  {"season": 6, "episode": 19, "scene": 7, "line": "Hey, nice office.", "order": 34686, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 19, "scene": 7, "line": "Thanks, it's cool. So...", "order": 34687, "speaker": "Darryl Philbin"},
  {"season": 6, "episode": 19, "scene": 7, "line": "You know what we haven't done in a while? Happy hour. Upstairs, the warehouse, everybody just going out for a drink.", "order": 34688, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 19, "scene": 7, "line": "Has that ever happened? Ever?", "order": 34689, "speaker": "Darryl Philbin"},
  {"season": 6, "episode": 19, "scene": 7, "line": "Didn't we? I think we did.", "order": 34690, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 19, "scene": 7, "line": "You want me to invite Matt?", "order": 34691, "speaker": "Darryl Philbin"},
  {"season": 6, "episode": 19, "scene": 7, "line": "Yeah, the whole gang, Matt included.", "order": 34692, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 19, "scene": 7, "line": "Look, just be straight with me, man. You can be gay with Matt, just be straight with me.", "order": 34693, "speaker": "Darryl Philbin"},
  {"season": 6, "episode": 19, "scene": 7, "line": "So happy hour.", "order": 34694, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 19, "scene": 7, "line": "Happy hour. My pleasure.", "order": 34695, "speaker": "Darryl Philbin"},
  {"season": 6, "episode": 19, "scene": 7, "line": "All right.", "order": 34696, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 19, "scene": 8, "line": "So what do you think?", "order": 34697, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 19, "scene": 8, "line": "Hmmm, I saw a new drink on TV I'd like to try. I'll ask Bob.", "order": 34698, "speaker": "Phyllis Vance"},
  {"season": 6, "episode": 19, "scene": 9, "line": "Yeah, I love going to bars with Bob. I tend to wear something low-cut, get men to flirt with me, and Bob beats 'em up. What?", "order": 34699, "speaker": "Phyllis Vance"},
  {"season": 6, "episode": 19, "scene": 10, "line": "A bunch of us are talking about happy hour.", "order": 34700, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 19, "scene": 10, "line": "I would love, love, love to go. Only problem is, Pam's at home with the baby and I think she wants a night in.", "order": 34701, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 19, "scene": 10, "line": "Oh, baloney.", "order": 34702, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 19, "scene": 10, "line": "Good one.", "order": 34703, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 19, "scene": 10, "line": "Ring her up.", "order": 34704, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 19, "scene": 10, "line": "Absolutely, I will do that right now.", "order": 34705, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 19, "scene": 11, "line": "I gotta tell you, this baby is amazing. She gets me out of everything. And I - and I love her. I also love her, very much.", "order": 34706, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 19, "scene": 12, "line": "So some of the co-workers were thinking of going out tonight, but I told them-", "order": 34707, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 19, "scene": 12, "line": "[on phone] Yes! Yes! I would love to!", "order": 34708, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 19, "scene": 12, "line": "Ha! Knew it!", "order": 34709, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 19, "scene": 13, "line": "[through phone] It's been so long since I've been with adults. I am so excited to see everybody. Creed, Ryan. Oh my God, Stanley! Stanley's going to be there. Yes, oh my God!", "order": 34710, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 19, "scene": 13, "line": "I did not see this coming.", "order": 34711, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 19, "scene": 14, "line": "Erin, I need you to fax this and get me a confirmation, pronto. [whispering] Are you going later?", "order": 34712, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 19, "scene": 14, "line": "Sure, if you are.", "order": 34713, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 19, "scene": 14, "line": "Yes.", "order": 34714, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 19, "scene": 14, "line": "Talk to me that way again, and I'll cut your face off.", "order": 34715, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 19, "scene": 14, "line": "Whoa.", "order": 34716, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 19, "scene": 15, "line": "We recently struck up a romantic relationship. And, um... but we're kinda keeping it quiet for now 'cause it's still kind of a new thing. It's a little delicate, and we just don't want all the drama.", "order": 34717, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 19, "scene": 15, "line": "Exactly.", "order": 34718, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 19, "scene": 15, "line": "Yeah, cause when everyone knows- [knock on window, open blinds to Kevin giggling and making sexual gestures] That's actually pretty funny, but in general, you know.", "order": 34719, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 19, "scene": 15, "line": "Quiet.", "order": 34720, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 19, "scene": 16, "line": "Hey, boss man.", "order": 34721, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 19, "scene": 16, "line": "Yes?", "order": 34722, "speaker": "Michael Scott"},
  {"season": 6, "episode": 19, "scene": 16, "line": "A bunch of us are going to get some drinks, you in?", "order": 34723, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 19, "scene": 16, "line": "Ladies and gentleman, it is quitting time.", "order": 34724, "speaker": "Michael Scott"},
  {"season": 6, "episode": 19, "scene": 16, "line": "I'm sorry, I meant later.", "order": 34725, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 19, "scene": 16, "line": "Ok, yes. Sure.", "order": 34726, "speaker": "Michael Scott"},
  {"season": 6, "episode": 19, "scene": 16, "line": "For happy hour?", "order": 34727, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 19, "scene": 16, "line": "No, I got that.", "order": 34728, "speaker": "Michael Scott"},
  {"season": 6, "episode": 19, "scene": 16, "line": "Trying to get a head count.", "order": 34729, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 19, "scene": 16, "line": "I am in.", "order": 34730, "speaker": "Michael Scott"},
  {"season": 6, "episode": 19, "scene": 16, "line": "All right, yes! It's a deal.", "order": 34731, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 19, "scene": 16, "line": "It's a deal.", "order": 34732, "speaker": "Michael Scott"},
  {"season": 6, "episode": 19, "scene": 17, "line": "Oh, hey, I invited my friend Julie.", "order": 34733, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 19, "scene": 17, "line": "Okay.", "order": 34734, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 19, "scene": 17, "line": "I want her to meet Michael.", "order": 34735, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 19, "scene": 17, "line": "Why?", "order": 34736, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 19, "scene": 17, "line": "They're both single, I have a sense they might-", "order": 34737, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 19, "scene": 17, "line": "You've been gone for a long time.", "order": 34738, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 19, "scene": 17, "line": "It is not that. Kevin! Oh!", "order": 34739, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 19, "scene": 17, "line": "Yeah! [hugs Pam]", "order": 34740, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 19, "scene": 17, "line": "Hey, how are you?", "order": 34741, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 19, "scene": 17, "line": "Oh, I missed you so much.", "order": 34742, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 19, "scene": 17, "line": "Aw!", "order": 34743, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 19, "scene": 17, "line": "Yeah!", "order": 34744, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 19, "scene": 17, "line": "Yeah!", "order": 34745, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 19, "scene": 17, "line": "Waaah! [starts to make crying baby noises]", "order": 34746, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 19, "scene": 18, "line": "When a new mom hears a baby cry, her you-know-what's fill up with you-know-what, and then her shirt gets, you know... that would be funny.", "order": 34747, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 19, "scene": 19, "line": "Oh, wow, I cannot believe this is happening. This is everything I dreamed. Oh, my God! [laughs]", "order": 34748, "speaker": "Michael Scott"},
  {"season": 6, "episode": 19, "scene": 19, "line": "Easy.", "order": 34749, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 19, "scene": 19, "line": "It's not a birthday, it's not a good-bye party...", "order": 34750, "speaker": "Michael Scott"},
  {"season": 6, "episode": 19, "scene": 19, "line": "Oh, hey, Pam and I are gonna go play pool with one of her friends, and we need a fourth.", "order": 34751, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 19, "scene": 19, "line": "Sucks to be you.", "order": 34752, "speaker": "Michael Scott"},
  {"season": 6, "episode": 19, "scene": 19, "line": "Would you like to be our fourth?", "order": 34753, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 19, "scene": 19, "line": "That would be sublime.", "order": 34754, "speaker": "Michael Scott"},
  {"season": 6, "episode": 19, "scene": 19, "line": "All right.", "order": 34755, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 19, "scene": 20, "line": "So, the guy shows me the deck he's built. And I'm like, 'I'll call this a deck if it'll make you happy, but this is just a porch without a roof.' [laughter, Oscar looks toward door] It was ridiculous man, it was like-you could maybe get two chairs on the thing. Two lawn chairs.", "order": 34756, "speaker": "Darryl Philbin"},
  {"season": 6, "episode": 19, "scene": 21, "line": "Hey, Michael.", "order": 34757, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 19, "scene": 21, "line": "Yes.", "order": 34758, "speaker": "Michael Scott"},
  {"season": 6, "episode": 19, "scene": 21, "line": "This is my friend Julie.", "order": 34759, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 19, "scene": 21, "line": "Hello, how are you?", "order": 34760, "speaker": "Michael Scott"},
  {"season": 6, "episode": 19, "scene": 21, "line": "Good. Hi.", "order": 34761, "speaker": "Julie"},
  {"season": 6, "episode": 19, "scene": 21, "line": "What is a nice girl like you hanging out with these bums for? [Julie laughs]", "order": 34762, "speaker": "Michael Scott"},
  {"season": 6, "episode": 19, "scene": 22, "line": "Julie laughs at everything.", "order": 34763, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 19, "scene": 23, "line": "So you work with Pam and Jim?", "order": 34764, "speaker": "Julie"},
  {"season": 6, "episode": 19, "scene": 23, "line": "Oh, no, no. Pam and Jim work for me. And if they win, they are fired.", "order": 34765, "speaker": "Michael Scott"},
  {"season": 6, "episode": 19, "scene": 23, "line": "[giggles] I should hope not.", "order": 34766, "speaker": "Julie"},
  {"season": 6, "episode": 19, "scene": 23, "line": "No. No, not really. Not really, but they better not win.", "order": 34767, "speaker": "Michael Scott"},
  {"season": 6, "episode": 19, "scene": 23, "line": "Hey.", "order": 34768, "speaker": "Isabel"},
  {"season": 6, "episode": 19, "scene": 23, "line": "Hey, Isabel, you made it. Oh, my goodness.", "order": 34769, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 19, "scene": 23, "line": "Of course.", "order": 34770, "speaker": "Isabel"},
  {"season": 6, "episode": 19, "scene": 23, "line": "You want to play pool?", "order": 34771, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 19, "scene": 23, "line": "Um, I'm gonna do a lap. See if I know anyone.", "order": 34772, "speaker": "Isabel"},
  {"season": 6, "episode": 19, "scene": 23, "line": "Ok.", "order": 34773, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 19, "scene": 24, "line": "And then they said the most ridiculous thing about Anderson Cooper, which I do not have the decency to repeat, but trust me when I tell you that-", "order": 34774, "speaker": "Angela Martin"},
  {"season": 6, "episode": 19, "scene": 24, "line": "Hold that thought. Well, well, well. If it isn't Isabel.", "order": 34775, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 19, "scene": 24, "line": "Mm-hmm.", "order": 34776, "speaker": "Isabel"},
  {"season": 6, "episode": 19, "scene": 24, "line": "What's a girl like you doing in a place like this?", "order": 34777, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 19, "scene": 24, "line": "A girl like me is why a guy like you comes to a place like this.", "order": 34778, "speaker": "Isabel"},
  {"season": 6, "episode": 19, "scene": 24, "line": "Ooh, I love repartee.", "order": 34779, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 19, "scene": 24, "line": "Do you?", "order": 34780, "speaker": "Isabel"},
  {"season": 6, "episode": 19, "scene": 24, "line": "Usually means there's a battle scene coming.", "order": 34781, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 19, "scene": 25, "line": "So, what do you do?", "order": 34782, "speaker": "Michael Scott"},
  {"season": 6, "episode": 19, "scene": 25, "line": "I am an ESL teacher.", "order": 34783, "speaker": "Julie"},
  {"season": 6, "episode": 19, "scene": 25, "line": "Really?", "order": 34784, "speaker": "Michael Scott"},
  {"season": 6, "episode": 19, "scene": 25, "line": "Yeah.", "order": 34785, "speaker": "Julie"},
  {"season": 6, "episode": 19, "scene": 25, "line": "See, I didn't think you could teach that. I thought that was something you were born with. What am I thinking right now?", "order": 34786, "speaker": "Michael Scott"},
  {"season": 6, "episode": 19, "scene": 25, "line": "Are you thinking that I said 'ESP?'", "order": 34787, "speaker": "Julie"},
  {"season": 6, "episode": 19, "scene": 25, "line": "Yes. I feel like an idiot. Awesome.", "order": 34788, "speaker": "Michael Scott"},
  {"season": 6, "episode": 19, "scene": 26, "line": "I was a little nervous when Pam told me he was her boss, but he doesn't act like a boss at all. If I had a boss like that, we'd never get anything done.", "order": 34789, "speaker": "Julie"},
  {"season": 6, "episode": 19, "scene": 27, "line": "So what do you think?", "order": 34790, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 19, "scene": 27, "line": "About what?", "order": 34791, "speaker": "Michael Scott"},
  {"season": 6, "episode": 19, "scene": 27, "line": "About Julie?", "order": 34792, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 19, "scene": 27, "line": "She's nice.", "order": 34793, "speaker": "Michael Scott"},
  {"season": 6, "episode": 19, "scene": 27, "line": "Yeah.", "order": 34794, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 19, "scene": 27, "line": "Yeah.", "order": 34795, "speaker": "Michael Scott"},
  {"season": 6, "episode": 19, "scene": 27, "line": "So you like her?", "order": 34796, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 19, "scene": 27, "line": "Uh, yeah, sure.", "order": 34797, "speaker": "Michael Scott"},
  {"season": 6, "episode": 19, "scene": 27, "line": "So Pam was right?", "order": 34798, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 19, "scene": 27, "line": "About what?", "order": 34799, "speaker": "Michael Scott"},
  {"season": 6, "episode": 19, "scene": 27, "line": "About you two hitting it off.", "order": 34800, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 19, "scene": 28, "line": "[removing tie] Well, apparently, Michael Scott is on a date. And that, that my friend, changes everything. [puts on backwards golf cap]", "order": 34801, "speaker": "Michael Scott"},
  {"season": 6, "episode": 19, "scene": 29, "line": "My brother, good head, bad heart. Good head, bad heart.", "order": 34802, "speaker": "Hidetoshi Hasagawa"},
  {"season": 6, "episode": 19, "scene": 29, "line": "I know.", "order": 34803, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 19, "scene": 30, "line": "Hey, Michael, where have you been? [Michael shoots pool ball up into their faces]", "order": 34804, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 19, "scene": 30, "line": "Hey, you're supposed to hit the white ball first, buddy. Nice one. Can I talk to you for a sec? All right. Everything ok?", "order": 34805, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 19, "scene": 30, "line": "Why are you wearing a hat now?", "order": 34806, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 19, "scene": 30, "line": "Guys, come on, I'm on a date. Let me do my thang.", "order": 34807, "speaker": "Michael Scott"},
  {"season": 6, "episode": 19, "scene": 31, "line": "Hi, I'm date Mike. Nice to meet me. How do you like your eggs in the morning? [tries to wink]", "order": 34808, "speaker": "Michael Scott"},
  {"season": 6, "episode": 19, "scene": 32, "line": "You want to just make a run for it?", "order": 34809, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 19, "scene": 32, "line": "Maybe.", "order": 34810, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 19, "scene": 32, "line": "Waaaah! [Kevin fake-cries into Pam's chest] Waaaaah! Mommy!", "order": 34811, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 19, "scene": 32, "line": "What is happening?", "order": 34812, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 19, "scene": 33, "line": "Whoa! What is crackin?", "order": 34813, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 19, "scene": 33, "line": "Guys, one second. [on dance videogame]", "order": 34814, "speaker": "Ryan Howard"},
  {"season": 6, "episode": 19, "scene": 33, "line": "We're focusing, we're focusing, we can't talk.", "order": 34815, "speaker": "Kelly Kapoor"},
  {"season": 6, "episode": 19, "scene": 33, "line": "What's this game?", "order": 34816, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 19, "scene": 33, "line": "One second.", "order": 34817, "speaker": "Ryan Howard"},
  {"season": 6, "episode": 19, "scene": 33, "line": "Yeah, how do you play?", "order": 34818, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 19, "scene": 33, "line": "Guys, guys, guys, please. [game ends] Ok, all right, it's all yours now.", "order": 34819, "speaker": "Ryan Howard"},
  {"season": 6, "episode": 19, "scene": 33, "line": "Only three tickets.", "order": 34820, "speaker": "Kelly Kapoor"},
  {"season": 6, "episode": 19, "scene": 33, "line": "If we save 'em up, we can get more than a sticker this time.", "order": 34821, "speaker": "Ryan Howard"},
  {"season": 6, "episode": 19, "scene": 33, "line": "Stop telling me how to spend my tickets.", "order": 34822, "speaker": "Kelly Kapoor"},
  {"season": 6, "episode": 19, "scene": 33, "line": "I know, but you wanted the big thing.", "order": 34823, "speaker": "Ryan Howard"},
  {"season": 6, "episode": 19, "scene": 33, "line": "Wow, can you imagine what people would say if they saw us dancing together?", "order": 34824, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 19, "scene": 33, "line": "Oh I know.", "order": 34825, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 19, "scene": 33, "line": "They'd be like, 'what's up with those two?'", "order": 34826, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 19, "scene": 33, "line": "Hey, guys, get a bedroom already.'", "order": 34827, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 19, "scene": 33, "line": "Did we miss the wedding?' Um, I got it-I'll do this, and you play the racing game, and then we'll switch.", "order": 34828, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 19, "scene": 33, "line": "Yes, okay. Yeah, that's smart.", "order": 34829, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 19, "scene": 33, "line": "No drama. Ok.", "order": 34830, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 19, "scene": 34, "line": "Oh, air hockey, basketball, we could play that. Oh, whack-a-mole.", "order": 34831, "speaker": "Isabel"},
  {"season": 6, "episode": 19, "scene": 34, "line": "Any brothers or sisters?", "order": 34832, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 19, "scene": 34, "line": "Three brothers.", "order": 34833, "speaker": "Isabel"},
  {"season": 6, "episode": 19, "scene": 34, "line": "Really?", "order": 34834, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 19, "scene": 34, "line": "Two are in the Marines, one's a cop.", "order": 34835, "speaker": "Isabel"},
  {"season": 6, "episode": 19, "scene": 34, "line": "Vegetarian?", "order": 34836, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 19, "scene": 34, "line": "No. I love meat.", "order": 34837, "speaker": "Isabel"},
  {"season": 6, "episode": 19, "scene": 34, "line": "What's your blood type?", "order": 34838, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 19, "scene": 34, "line": "O-negative. Universal donor.", "order": 34839, "speaker": "Isabel"},
  {"season": 6, "episode": 19, "scene": 34, "line": "Universal donor. [startled by Angela, curses]", "order": 34840, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 19, "scene": 35, "line": "Angela versus Isabel. Height, advantage Isabel. Birthing hips, advantage Isabel. Remaining child-bearing years, advantage Isabel. Legal obligation, advantage Angela.", "order": 34841, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 19, "scene": 36, "line": "Hey, guys, guys, guys. [steals a cherry from waitress passing buy] Watch this. Ready?", "order": 34842, "speaker": "Michael Scott"},
  {"season": 6, "episode": 19, "scene": 36, "line": "What are you doing?", "order": 34843, "speaker": "Julie"},
  {"season": 6, "episode": 19, "scene": 36, "line": "I'm tying a knot in the stem with my tongue.", "order": 34844, "speaker": "Michael Scott"},
  {"season": 6, "episode": 19, "scene": 36, "line": "Michael, you don't have to do this.", "order": 34845, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 19, "scene": 36, "line": "[choking] Wow. Oh, wow, that was close.", "order": 34846, "speaker": "Michael Scott"},
  {"season": 6, "episode": 19, "scene": 37, "line": "I would say I kind of have an unfair advantage because I watch reality dating shows like a hawk, and I learn. I absorb information from the strategies of the winners and the losers. Actually, I probably learn more from the losers.", "order": 34847, "speaker": "Michael Scott"},
  {"season": 6, "episode": 19, "scene": 38, "line": "Hey, you two having fun?", "order": 34848, "speaker": "Phyllis Vance"},
  {"season": 6, "episode": 19, "scene": 38, "line": "Did you tell them?", "order": 34849, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 19, "scene": 38, "line": "No.", "order": 34850, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 19, "scene": 38, "line": "This is exactly what I don't want, the drama. I don't want the drama!", "order": 34851, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 19, "scene": 38, "line": "I get it.", "order": 34852, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 19, "scene": 39, "line": "[Isabel and Dwight playing whack-a-mole] You are amazing at this. How did you get so good?", "order": 34853, "speaker": "Isabel"},
  {"season": 6, "episode": 19, "scene": 39, "line": "Whacking moles. Hit 'em on the head. Whack. Say it with me.", "order": 34854, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 19, "scene": 39, "line": "Whack!", "order": 34855, "speaker": "Isabel"},
  {"season": 6, "episode": 19, "scene": 39, "line": "Extend the fingers more.", "order": 34856, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 19, "scene": 39, "line": "Whack!", "order": 34857, "speaker": "Isabel"},
  {"season": 6, "episode": 19, "scene": 39, "line": "Good.", "order": 34858, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 19, "scene": 39, "line": "This looks like a hoot.", "order": 34859, "speaker": "Angela Martin"},
  {"season": 6, "episode": 19, "scene": 39, "line": "Hey, monkey, how you doing?", "order": 34860, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 19, "scene": 39, "line": "Whack.", "order": 34861, "speaker": "Angela Martin"},
  {"season": 6, "episode": 19, "scene": 39, "line": "Listen, can I talk to you for a second?", "order": 34862, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 19, "scene": 39, "line": "Okay.", "order": 34863, "speaker": "Angela Martin"},
  {"season": 6, "episode": 19, "scene": 39, "line": "Look, I've been thinking. We had a good run. We really did. But you don't need to worry about this whole contract thing anymore.", "order": 34864, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 19, "scene": 39, "line": "It's no worry.", "order": 34865, "speaker": "Angela Martin"},
  {"season": 6, "episode": 19, "scene": 39, "line": "It's just that we both-we want different things. You know, I want a big family.", "order": 34866, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 19, "scene": 39, "line": "I could see enjoying that.", "order": 34867, "speaker": "Angela Martin"},
  {"season": 6, "episode": 19, "scene": 39, "line": "No, no, no, no. I want a big family. Tall. Thick. A big, physically big family. Listen, you go have fun. You're off the hook.", "order": 34868, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 19, "scene": 39, "line": "But we signed the contract.", "order": 34869, "speaker": "Angela Martin"},
  {"season": 6, "episode": 19, "scene": 39, "line": "Dissolved. Don't worry, you're free. Okay? Okay. See you later.", "order": 34870, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 19, "scene": 39, "line": "We both-you didn't dup-", "order": 34871, "speaker": "Angela Martin"},
  {"season": 6, "episode": 19, "scene": 40, "line": "[Michael playing air guitar on pool table] Wow. Maybe we should tell her that he's not normally like this.", "order": 34872, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 19, "scene": 40, "line": "Maybe it should come from a man.", "order": 34873, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 19, "scene": 40, "line": "Maybe it should come from a note... with flowers...tomorrow.", "order": 34874, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 19, "scene": 40, "line": "Hello.", "order": 34875, "speaker": "Donna Newton"},
  {"season": 6, "episode": 19, "scene": 40, "line": "Hello.", "order": 34876, "speaker": "Michael Scott"},
  {"season": 6, "episode": 19, "scene": 40, "line": "Hi.", "order": 34877, "speaker": "Donna Newton"},
  {"season": 6, "episode": 19, "scene": 40, "line": "Hi.", "order": 34878, "speaker": "Michael Scott"},
  {"season": 6, "episode": 19, "scene": 40, "line": "You wanna pay 400 bucks to re-felt this table?", "order": 34879, "speaker": "Donna Newton"},
  {"season": 6, "episode": 19, "scene": 40, "line": "Yeah, why don't you send the bill to 23 I Don't Care Lane, Scranton, Pennsylvania?", "order": 34880, "speaker": "Michael Scott"},
  {"season": 6, "episode": 19, "scene": 40, "line": "Hey, Michael, why don't you just get down.", "order": 34881, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 19, "scene": 40, "line": "Hey, she can tell I'm on a date, right? Right? I'm just having fun.", "order": 34882, "speaker": "Michael Scott"},
  {"season": 6, "episode": 19, "scene": 40, "line": "Ted, are we having fun?", "order": 34883, "speaker": "Donna Newton"},
  {"season": 6, "episode": 19, "scene": 40, "line": "Really? You told on me. That's lame.", "order": 34884, "speaker": "Michael Scott"},
  {"season": 6, "episode": 19, "scene": 40, "line": "We got a problem?", "order": 34885, "speaker": "Bouncer"},
  {"season": 6, "episode": 19, "scene": 40, "line": "Yes. Homelessness. What?", "order": 34886, "speaker": "Michael Scott"},
  {"season": 6, "episode": 19, "scene": 40, "line": "All right, go.", "order": 34887, "speaker": "Donna Newton"},
  {"season": 6, "episode": 19, "scene": 40, "line": "Where?", "order": 34888, "speaker": "Michael Scott"},
  {"season": 6, "episode": 19, "scene": 40, "line": "Get out. Now.", "order": 34889, "speaker": "Donna Newton"},
  {"season": 6, "episode": 19, "scene": 40, "line": "Okay, all right. Okay, whoa. I'm just kidding around. I'm sorry.", "order": 34890, "speaker": "Michael Scott"},
  {"season": 6, "episode": 19, "scene": 40, "line": "Ok, um, why don't we just finish the game? Michael, it's your shot.", "order": 34891, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 19, "scene": 40, "line": "She can't talk to us that way.", "order": 34892, "speaker": "Michael Scott"},
  {"season": 6, "episode": 19, "scene": 40, "line": "You guys are stripes, I think...", "order": 34893, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 19, "scene": 40, "line": "You let somebody talk to you like that, where does it stop?", "order": 34894, "speaker": "Michael Scott"},
  {"season": 6, "episode": 19, "scene": 40, "line": "It stopped.", "order": 34895, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 19, "scene": 40, "line": "Well, I am starting it again!", "order": 34896, "speaker": "Michael Scott"},
  {"season": 6, "episode": 19, "scene": 40, "line": "Do you guys want some food? The wings are really good here.", "order": 34897, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 19, "scene": 41, "line": "Hey, you embarrassed my friends in front of me and I'm gonna need you to go back over to the table and apologize.", "order": 34898, "speaker": "Michael Scott"},
  {"season": 6, "episode": 19, "scene": 41, "line": "Then I am sorry that I didn't kick you out.", "order": 34899, "speaker": "Donna Newton"},
  {"season": 6, "episode": 19, "scene": 41, "line": "I am here with my employees. I am here on a date. Hello.", "order": 34900, "speaker": "Michael Scott"},
  {"season": 6, "episode": 19, "scene": 41, "line": "Well, uh, I'm the manager here, sir.", "order": 34901, "speaker": "Donna Newton"},
  {"season": 6, "episode": 19, "scene": 41, "line": "Well, it just so happens that I am a manager too. And the way I manage people is that I touch their hearts and souls with humor, with love and maybe a dash of razzle-dazzle. And I don't see that from you.", "order": 34902, "speaker": "Michael Scott"},
  {"season": 6, "episode": 19, "scene": 41, "line": "Is that how you do it?", "order": 34903, "speaker": "Donna Newton"},
  {"season": 6, "episode": 19, "scene": 41, "line": "Yes it is. I am writing a book about it.", "order": 34904, "speaker": "Michael Scott"},
  {"season": 6, "episode": 19, "scene": 41, "line": "Really? How much have you written?", "order": 34905, "speaker": "Donna Newton"},
  {"season": 6, "episode": 19, "scene": 41, "line": "I've written all of it... in my head.", "order": 34906, "speaker": "Michael Scott"},
  {"season": 6, "episode": 19, "scene": 41, "line": "Oh.", "order": 34907, "speaker": "Donna Newton"},
  {"season": 6, "episode": 19, "scene": 41, "line": "If you're really interested, it's called 'Somehow I Manage' and there's going to be a picture of me on the cover, shrugging, with my sleeves rolled up.", "order": 34908, "speaker": "Michael Scott"},
  {"season": 6, "episode": 19, "scene": 41, "line": "Huh. Have you read Lee Iacocca's? It's a classic.", "order": 34909, "speaker": "Donna Newton"},
  {"season": 6, "episode": 19, "scene": 41, "line": "Read it? I own it. But no, I have not read it.", "order": 34910, "speaker": "Michael Scott"},
  {"season": 6, "episode": 19, "scene": 41, "line": "Dude, tonight! You're not going to want to put it down. It's gonna make you want to go out and buy a Chrysler tomorrow.", "order": 34911, "speaker": "Donna Newton"},
  {"season": 6, "episode": 19, "scene": 41, "line": "I own a Chrysler.", "order": 34912, "speaker": "Michael Scott"},
  {"season": 6, "episode": 19, "scene": 41, "line": "Shut up.", "order": 34913, "speaker": "Donna Newton"},
  {"season": 6, "episode": 19, "scene": 41, "line": "No, you shut up.", "order": 34914, "speaker": "Michael Scott"},
  {"season": 6, "episode": 19, "scene": 41, "line": "What's your drink?", "order": 34915, "speaker": "Donna Newton"},
  {"season": 6, "episode": 19, "scene": 41, "line": "Grenadine.", "order": 34916, "speaker": "Michael Scott"},
  {"season": 6, "episode": 19, "scene": 41, "line": "What?", "order": 34917, "speaker": "Donna Newton"},
  {"season": 6, "episode": 19, "scene": 42, "line": "We just have to throw everyone off the scent a little, so follow my lead.", "order": 34918, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 19, "scene": 42, "line": "Okay.", "order": 34919, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 19, "scene": 42, "line": "[Andy sits at table with another woman] Hi.", "order": 34920, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 19, "scene": 42, "line": "Hi.", "order": 34921, "speaker": "Girl at table"},
  {"season": 6, "episode": 19, "scene": 42, "line": "I don't normally do this, but...", "order": 34922, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 19, "scene": 42, "line": "Do what?", "order": 34923, "speaker": "Girl at table"},
  {"season": 6, "episode": 19, "scene": 42, "line": "Just sit down next to a beautiful woman and start talking to myself to confuse other people.", "order": 34924, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 19, "scene": 42, "line": "[Erin sits with man, rubs her hand on his thigh] Hey, big boy. Do you like it when I do that?", "order": 34925, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 19, "scene": 42, "line": "[Andy spits out his drink] What are you doing?", "order": 34926, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 19, "scene": 42, "line": "What we said to do.", "order": 34927, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 19, "scene": 42, "line": "We didn't say we were gonna, like, start groping strangers!", "order": 34928, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 19, "scene": 42, "line": "I was flirting with a man.", "order": 34929, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 19, "scene": 42, "line": "Get in here. [Andy and Erin go into photo booth] Where did you learn to talk like that?", "order": 34930, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 19, "scene": 42, "line": "The movies. I don't know.", "order": 34931, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 19, "scene": 42, "line": "Well, what movie? Black Snake Moan?", "order": 34932, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 19, "scene": 43, "line": "I manage a paper company-Dunder Mifflin/Sabre.", "order": 34933, "speaker": "Michael Scott"},
  {"season": 6, "episode": 19, "scene": 43, "line": "You have a card?", "order": 34934, "speaker": "Donna Newton"},
  {"season": 6, "episode": 19, "scene": 43, "line": "I did. I actually put it in your bowl.", "order": 34935, "speaker": "Michael Scott"},
  {"season": 6, "episode": 19, "scene": 43, "line": "Stanley Hudson?", "order": 34936, "speaker": "Donna Newton"},
  {"season": 6, "episode": 19, "scene": 43, "line": "No, no.", "order": 34937, "speaker": "Michael Scott"},
  {"season": 6, "episode": 19, "scene": 43, "line": "Whoa, a lot of Stanley Hudson's in here.", "order": 34938, "speaker": "Donna Newton"},
  {"season": 6, "episode": 19, "scene": 43, "line": "No, it's Michael Scott.", "order": 34939, "speaker": "Michael Scott"},
  {"season": 6, "episode": 19, "scene": 43, "line": "Michael Scott?", "order": 34940, "speaker": "Donna Newton"},
  {"season": 6, "episode": 19, "scene": 43, "line": "He is I.", "order": 34941, "speaker": "Michael Scott"},
  {"season": 6, "episode": 19, "scene": 43, "line": "You just won yourself a lunch.", "order": 34942, "speaker": "Donna Newton"},
  {"season": 6, "episode": 19, "scene": 43, "line": "Oh, hey guys. [thumbs up]", "order": 34943, "speaker": "Michael Scott"},
  {"season": 6, "episode": 19, "scene": 44, "line": "I think I'm gonna go.", "order": 34944, "speaker": "Julie"},
  {"season": 6, "episode": 19, "scene": 44, "line": "Really?", "order": 34945, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 19, "scene": 44, "line": "Yeah.", "order": 34946, "speaker": "Julie"},
  {"season": 6, "episode": 19, "scene": 44, "line": "I'm sorry, he's not usually like that.", "order": 34947, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 19, "scene": 44, "line": "What's he usually like?", "order": 34948, "speaker": "Julie"},
  {"season": 6, "episode": 19, "scene": 44, "line": "He's more, just... like... you can go.", "order": 34949, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 19, "scene": 44, "line": "Yeah.", "order": 34950, "speaker": "Julie"},
  {"season": 6, "episode": 19, "scene": 44, "line": "All right.", "order": 34951, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 19, "scene": 44, "line": "Okay. Bye.", "order": 34952, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 19, "scene": 44, "line": "See ya. Nice girl.", "order": 34953, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 19, "scene": 44, "line": "Yeah.", "order": 34954, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 19, "scene": 45, "line": "Hey, Julie! You having fun? [Julie leaves]", "order": 34955, "speaker": "Michael Scott"},
  {"season": 6, "episode": 19, "scene": 45, "line": "So... when are you coming in for that free lunch? You're gonna want to come in on a day that I'm working. Uh, maybe I can hear more about that book, too.", "order": 34956, "speaker": "Donna Newton"},
  {"season": 6, "episode": 19, "scene": 46, "line": "Hey, man, you put together a pretty fun night for everybody. I saw you talking to Hide. Did you hear that dude's life story? It's amazing, right?", "order": 34957, "speaker": "Darryl Philbin"},
  {"season": 6, "episode": 19, "scene": 46, "line": "I couldn't understand a word he said.", "order": 34958, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 19, "scene": 46, "line": "Let me tell you something, Oscar. All right, Matt's an okay dude, but he's a dummy. You guys got nothing in common.", "order": 34959, "speaker": "Darryl Philbin"},
  {"season": 6, "episode": 19, "scene": 46, "line": "Maybe you're right. I should count myself lucky.", "order": 34960, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 19, "scene": 46, "line": "Hey, what's up?", "order": 34961, "speaker": "Matt"},
  {"season": 6, "episode": 19, "scene": 46, "line": "There he is! Hey, hey, hey.", "order": 34962, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 19, "scene": 46, "line": "Anyone up for some hoops?", "order": 34963, "speaker": "Matt"},
  {"season": 6, "episode": 19, "scene": 46, "line": "Sure. Hoops!", "order": 34964, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 19, "scene": 46, "line": "Let's do it.", "order": 34965, "speaker": "Matt"},
  {"season": 6, "episode": 19, "scene": 46, "line": "Hoop it up, right.", "order": 34966, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 19, "scene": 47, "line": "This is not what I want my relationship to look like. [holding photo strip of he and Erin fighting]", "order": 34967, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 19, "scene": 48, "line": "[over PA] Hi, ladies and gentlemen. My name is Andrew Bernard, and I've been on two dates with Erin Hannon and they went well, and there will probably be more. Thank you.", "order": 34968, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 19, "scene": 49, "line": "You know, it got to the point where keeping it a secret was just too much drama. And I hate drama, so there you go.", "order": 34969, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 19, "scene": 49, "line": "[laughs] You love drama.", "order": 34970, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 19, "scene": 49, "line": "I know, I do, right? I'm a total drama queen.", "order": 34971, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 19, "scene": 50, "line": "With this move, he can't get you.", "order": 34972, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 19, "scene": 50, "line": "Well, I think that he could counter that move. The Scranton strangler is a professional strangler.", "order": 34973, "speaker": "Isabel"},
  {"season": 6, "episode": 19, "scene": 50, "line": "Oh, please. I wish he'd come after me. I would be like-aaah!", "order": 34974, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 19, "scene": 50, "line": "Dwight Schrute! Dwight Kurt Schrute.", "order": 34975, "speaker": "Angela Martin"},
  {"season": 6, "episode": 19, "scene": 50, "line": "Sh-what?", "order": 34976, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 19, "scene": 50, "line": "You are hereby served with a summons to appear in Lackawanna county court.", "order": 34977, "speaker": "Angela Martin"},
  {"season": 6, "episode": 19, "scene": 50, "line": "No, no, no. [trying to talk over her] Blah blah blah blah!", "order": 34978, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 19, "scene": 50, "line": "For breach of contract with Angela Noelle Martin.", "order": 34979, "speaker": "Angela Martin"},
  {"season": 6, "episode": 19, "scene": 50, "line": "What are you talking about?", "order": 34980, "speaker": "Isabel"},
  {"season": 6, "episode": 19, "scene": 50, "line": "What are you-", "order": 34981, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 19, "scene": 50, "line": "Dwight recently entered into a contract with me, establishing intent to conceive and raise a child with me.", "order": 34982, "speaker": "Angela Martin"},
  {"season": 6, "episode": 19, "scene": 50, "line": "Angela...", "order": 34983, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 19, "scene": 50, "line": "Did he not tell you that?", "order": 34984, "speaker": "Angela Martin"},
  {"season": 6, "episode": 19, "scene": 50, "line": "You're really putting me in an awkward position here.", "order": 34985, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 19, "scene": 50, "line": "Do you plan on raising a child with me? Or do you plan on breaking this contract?", "order": 34986, "speaker": "Angela Martin"},
  {"season": 6, "episode": 19, "scene": 50, "line": "Angela, not here!", "order": 34987, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 19, "scene": 50, "line": "Dwight?", "order": 34988, "speaker": "Angela Martin"},
  {"season": 6, "episode": 19, "scene": 50, "line": "Whack! [Isabel smacks Angela on forehead]", "order": 34989, "speaker": "Isabel"},
  {"season": 6, "episode": 19, "scene": 50, "line": "You'll see me in small claims court!", "order": 34990, "speaker": "Angela Martin"},
  {"season": 6, "episode": 19, "scene": 50, "line": "You are an impressive specimen.", "order": 34991, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 19, "scene": 50, "line": "Thank you. [Dwight and Isabel kiss]", "order": 34992, "speaker": "Isabel"},
  {"season": 6, "episode": 19, "scene": 51, "line": "[crying] Then, I spilled my drink, and they wouldn't give me a refill.", "order": 34993, "speaker": "Kelly Kapoor"},
  {"season": 6, "episode": 19, "scene": 51, "line": "Oh-oh, gosh. Oh.", "order": 34994, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 19, "scene": 51, "line": "You all right?", "order": 34995, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 19, "scene": 51, "line": "Okay, we have to get home.", "order": 34996, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 19, "scene": 51, "line": "Yeah!", "order": 34997, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 19, "scene": 51, "line": "Halperts, wait up. Oh, what a great night. Got to hang out with my peeps. Sort of did okay with a new young lady.", "order": 34998, "speaker": "Michael Scott"},
  {"season": 6, "episode": 19, "scene": 51, "line": "Actually, you didn't.", "order": 34999, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 19, "scene": 51, "line": "Not at all.", "order": 35000, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 19, "scene": 51, "line": "I think I did. But I can't take all of the credit. Some of the credit is due, in fact, to my good friend, Date Mike. Nice to meet me.", "order": 35001, "speaker": "Michael Scott"},
  {"season": 6, "episode": 19, "scene": 52, "line": "Tell 'em your story, Hide.", "order": 35002, "speaker": "Darryl Philbin"},
  {"season": 6, "episode": 19, "scene": 52, "line": "In Japan, heart surgeon. Number one. Steady hand. One day, yakuza boss need new heart. I do operation. But, mistake! Yakuza boss die. Yakuza very mad. I hide in fishing boat, come to America. No English, no food, no money. Darryl give me job. Now I have house, American car, and new woman. Darryl save life. My big secret: I kill yakuza boss on purpose. I good surgeon. The best!", "order": 35003, "speaker": "Hidetoshi Hasagawa"},
  {"season": 6, "episode": 20, "scene": 1, "line": "[Kevin's voice] Oscar, Toby said he left my Girl Scout cookies on my chair. Have you seen them? Wait I'm sitting on them. [all laugh]", "order": 35004, "speaker": "Cookie Monster"},
  {"season": 6, "episode": 20, "scene": 1, "line": "This is awesome!", "order": 35005, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 20, "scene": 1, "line": "Thank you. It didn't even take that long.", "order": 35006, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 20, "scene": 1, "line": "[Kevin's voice] What's the difference between a chimichanga, [Kevin arrives behind the group] a chalupa, and a tostada? Call me back ASAP. It's urgent.", "order": 35007, "speaker": "Cookie Monster"},
  {"season": 6, "episode": 20, "scene": 1, "line": "This isn't funny. I don't talk like that. [all continue laughing]", "order": 35008, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 20, "scene": 1, "line": "Say 'Me eat cookie.'", "order": 35009, "speaker": "Phyllis Vance"},
  {"season": 6, "episode": 20, "scene": 1, "line": "No. I won't say it.", "order": 35010, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 20, "scene": 1, "line": "Why is everyone clumped around accounting? Break it up, you clique.", "order": 35011, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 20, "scene": 1, "line": "It's Kevin as Cookie monster from Sesame Street.", "order": 35012, "speaker": "Ryan Howard"},
  {"season": 6, "episode": 20, "scene": 1, "line": "[gets up and starts to walk to Oscar's desk] Is that the program where all those puppets live in the barrio?", "order": 35013, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 20, "scene": 1, "line": "Mm-hmm.", "order": 35014, "speaker": "Phyllis Vance"},
  {"season": 6, "episode": 20, "scene": 1, "line": "I love that show.", "order": 35015, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 20, "scene": 1, "line": "[Kevin's voice] This is Kevin. This is Kevin.", "order": 35016, "speaker": "Cookie Monster"},
  {"season": 6, "episode": 20, "scene": 1, "line": "Good work, buddy.", "order": 35017, "speaker": "Ryan Howard"},
  {"season": 6, "episode": 20, "scene": 1, "line": "Thank you.", "order": 35018, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 20, "scene": 1, "line": "That is an amusing link. I'd like it sent to me, please.", "order": 35019, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 20, "scene": 1, "line": "Yes.", "order": 35020, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 20, "scene": 1, "line": "Me, too Oscar.", "order": 35021, "speaker": "Kelly Kapoor"},
  {"season": 6, "episode": 20, "scene": 1, "line": "C.C. me", "order": 35022, "speaker": "Ryan Howard"},
  {"season": 6, "episode": 20, "scene": 1, "line": "Angela, this is inappropriate.", "order": 35023, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 20, "scene": 1, "line": "This is my favorite day.", "order": 35024, "speaker": "Angela Martin"},
  {"season": 6, "episode": 20, "scene": 2, "line": "It's Secretary's Day. And it is Erin's and my three-week anniversary. So perfect storm for a romantic gesture. [Andy unfurling a giant 'Happy Secretary's Day' banner by reception, placing a teddy bear on Erin's chair]. Want to make sure the whole office remembers it's Secretary's Day. I sent an e-mail blast, a text blast, a good, old-fashioned talk blast. I sent a snail mail blast a week ago. And a Stern reminder via fax at 7:00 this morning, so people better step up and appreciate the crap out of Erin.", "order": 35025, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 20, "scene": 3, "line": "Here you go.", "order": 35026, "speaker": "Meredith Palmer"},
  {"season": 6, "episode": 20, "scene": 3, "line": "Oh, thank you.", "order": 35027, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 20, "scene": 3, "line": "I do.", "order": 35028, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 20, "scene": 3, "line": "[hands Erin a basket of beets]", "order": 35029, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 20, "scene": 3, "line": "Thank you.", "order": 35030, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 20, "scene": 4, "line": "If it wasn't for secretaries, I wouldn't have a stepmom.", "order": 35031, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 20, "scene": 5, "line": "[as Halperts arrive] Welcome back!", "order": 35032, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 20, "scene": 5, "line": "Hey, thanks.", "order": 35033, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 20, "scene": 6, "line": "It's my first day back after maternity leave. And I miss Cece, of course. But we need the money. What was maternity leave like? Oh, how do I explain it?... It rocked. It rocked my ass off.", "order": 35034, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 20, "scene": 7, "line": "[at her desk, grabs her plant and sees it dried up, looks at Jim] Oh. You couldn't have watered it?", "order": 35035, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 20, "scene": 7, "line": "I literally did not know that existed until this moment.", "order": 35036, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 20, "scene": 7, "line": "Well, I knew it existed, and I chose to let it die.", "order": 35037, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 20, "scene": 7, "line": "It's nice to see you again, Dwight.", "order": 35038, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 20, "scene": 7, "line": "Hello, Pam.", "order": 35039, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 20, "scene": 8, "line": "[arrives at Michael's office and sits] I was just wondering what you had planned for Secretary's Day.", "order": 35040, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 20, "scene": 8, "line": "I'm going to give Erin $15.", "order": 35041, "speaker": "Michael Scott"},
  {"season": 6, "episode": 20, "scene": 8, "line": "I know that Erin would be so psyched if you took her out to lunch.", "order": 35042, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 20, "scene": 8, "line": "A one-on-one lunch with Erin?", "order": 35043, "speaker": "Michael Scott"},
  {"season": 6, "episode": 20, "scene": 8, "line": "Yeah. She really looks up to you. And there's only so much we can do as her coworkers. Secretary's Day is really kind of a boss-secretary thing. [Michael glances over at Erin in reception, Erin waves her teddy bear's arm at Michael].", "order": 35044, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 20, "scene": 8, "line": "She's kind of a rube.", "order": 35045, "speaker": "Michael Scott"},
  {"season": 6, "episode": 20, "scene": 8, "line": "[slams his hand on Michael's desk] That's my girlfriend you're talking about.", "order": 35046, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 20, "scene": 8, "line": "Okay. All right. All right. Okay. I'll take her to lunch.", "order": 35047, "speaker": "Michael Scott"},
  {"season": 6, "episode": 20, "scene": 8, "line": "[taping desk] She's gonna be so psyched.", "order": 35048, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 20, "scene": 9, "line": "[walks over to Erin's desk] Hi, Erin. Happy Secretary's Day.", "order": 35049, "speaker": "Michael Scott"},
  {"season": 6, "episode": 20, "scene": 9, "line": "Well, happy Boss's day. There wouldn't be a secretary if there was no boss.", "order": 35050, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 20, "scene": 9, "line": "I wanted to know what your plans for lunch were because I was hoping to ask you to...[Erin snaps a picture of Michael] lunch.", "order": 35051, "speaker": "Michael Scott"},
  {"season": 6, "episode": 20, "scene": 9, "line": "I got a picture of you asking me to lunch. I was thinking we could go to Hayworth's.", "order": 35052, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 20, "scene": 9, "line": "Ugh.", "order": 35053, "speaker": "Michael Scott"},
  {"season": 6, "episode": 20, "scene": 9, "line": "Just the two of us.", "order": 35054, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 20, "scene": 9, "line": "Well, Hayworth's is more business casual, and they always screw up your order. So I'm...", "order": 35055, "speaker": "Michael Scott"},
  {"season": 6, "episode": 20, "scene": 9, "line": "Yeah, okay. Yeah that was a stupid suggestion. I was thinking someplace special, so I though Hayworth's, but...", "order": 35056, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 20, "scene": 9, "line": "Okay, well, we'll figure something. [Michael turns and starts back toward his office]", "order": 35057, "speaker": "Michael Scott"},
  {"season": 6, "episode": 20, "scene": 9, "line": "Oh.", "order": 35058, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 20, "scene": 9, "line": "[looks at Andy who is urging he agree with her, Michael turns around] Okay, let's go to Hayworth's.", "order": 35059, "speaker": "Michael Scott"},
  {"season": 6, "episode": 20, "scene": 9, "line": "[cheerfully] Okay.", "order": 35060, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 20, "scene": 9, "line": "All right.", "order": 35061, "speaker": "Michael Scott"},
  {"season": 6, "episode": 20, "scene": 10, "line": "[sitting at kitchen table with Darryl] Have a cookie, Kev.", "order": 35062, "speaker": "Meredith Palmer"},
  {"season": 6, "episode": 20, "scene": 10, "line": "Yeah, haha. Tray of cookies. I'm not falling for that.", "order": 35063, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 20, "scene": 10, "line": "I brought those in. It's my birthday. That's some stone-cold narcissism right there.", "order": 35064, "speaker": "Darryl Philbin"},
  {"season": 6, "episode": 20, "scene": 10, "line": "Man, Darryl, I'm sorry. [pats Darryl's back] Happy Birthday.", "order": 35065, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 20, "scene": 10, "line": "Thank you. [Kevin grabs cookie and starts eating it]", "order": 35066, "speaker": "Darryl Philbin"},
  {"season": 6, "episode": 20, "scene": 10, "line": "[as Cookie Monster] Yum, Yum, Yum, Yum, Yum, Yum.", "order": 35067, "speaker": "Darryl Philbin"},
  {"season": 6, "episode": 20, "scene": 11, "line": "They're making fun of Cookie Monster. I get that. But in a strange way, it feels like they're making fun of me.", "order": 35068, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 20, "scene": 12, "line": "[again on laptop as Cookie Monster as he shows Gabe the video] Oscar, did you eat some of my M&M's? The level...", "order": 35069, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 20, "scene": 12, "line": "This is violent and offensive.", "order": 35070, "speaker": "Gabe Lewis"},
  {"season": 6, "episode": 20, "scene": 12, "line": "Thank you. [computer continues] It really makes me self-conscious about my voice.", "order": 35071, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 20, "scene": 12, "line": "It's awful. [reaches for tissue box, hands it to Kevin]", "order": 35072, "speaker": "Gabe Lewis"},
  {"season": 6, "episode": 20, "scene": 12, "line": "I'm not crying.", "order": 35073, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 20, "scene": 12, "line": "You have some food on your face.", "order": 35074, "speaker": "Gabe Lewis"},
  {"season": 6, "episode": 20, "scene": 12, "line": "[on computer as Cookie Monster] Do my hands feel sweaty to you? [Kevin grabs a tissue and wipes his mouth]", "order": 35075, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 20, "scene": 13, "line": "The beginning here has been a little bit of a fiasco. Either they don't respect me or they respect me too much. And some of them still think that I'm the I.T. guy. This Cookie Monster thing is an opportunity to show people that I'm an authority figure.", "order": 35076, "speaker": "Gabe Lewis"},
  {"season": 6, "episode": 20, "scene": 14, "line": "Hello, everyone. If I might have a moment of your time. It's come to my attention that people have been watching and laughing at a hurtful parody video. It is now forbidden to talk, joke about, or e-mail this around.", "order": 35077, "speaker": "Gabe Lewis"},
  {"season": 6, "episode": 20, "scene": 14, "line": "Yeah.", "order": 35078, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 20, "scene": 14, "line": "And that's straight from corporate. So not to be scary, but yeah, I would listen to me. And that's all. Ciao.", "order": 35079, "speaker": "Gabe Lewis"},
  {"season": 6, "episode": 20, "scene": 15, "line": "[in his car with Erin driving to lunch] Would you mind if I listen to my book on tape? I'm kind of a bookworm. This is the novelization of the movie Precious based on the book Push by Sapphire.", "order": 35080, "speaker": "Michael Scott"},
  {"season": 6, "episode": 20, "scene": 15, "line": "I was thinking it would be fun to talk on the way over. So what decade would you have chosen to be a teenager?", "order": 35081, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 20, "scene": 15, "line": "[shakes head, annoyed] I don't know.", "order": 35082, "speaker": "Michael Scott"},
  {"season": 6, "episode": 20, "scene": 15, "line": "I would have chosen the 1490's.", "order": 35083, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 20, "scene": 15, "line": "Ahhh.", "order": 35084, "speaker": "Michael Scott"},
  {"season": 6, "episode": 20, "scene": 15, "line": "Cause America was discovered.", "order": 35085, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 20, "scene": 15, "line": "Right. [sighs]", "order": 35086, "speaker": "Michael Scott"},
  {"season": 6, "episode": 20, "scene": 16, "line": "[at lunch] And then my last job was at a Taco Bell express. But then it became a full Taco Bell, and I don't know, I couldn't keep up. My favorite part about being a receptionist is that I get to have my own desk. In my Foster home I never had a desk. [Michael keeps moving around, very clearly bored and annoyed]. So it's like... I don't mean that I didn't like my foster home. I did like it. I just didn't have a desk there. Did you have a favorite age? Or month?", "order": 35087, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 20, "scene": 16, "line": "An age or month?", "order": 35088, "speaker": "Michael Scott"},
  {"season": 6, "episode": 20, "scene": 16, "line": "Yeah, like a favorite month. Like I like April when I was seven.", "order": 35089, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 20, "scene": 17, "line": "I've been trying. I've been trying to keep it going. Erin is just weird.", "order": 35090, "speaker": "Michael Scott"},
  {"season": 6, "episode": 20, "scene": 18, "line": "How many pillows do you sleep on at night?", "order": 35091, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 20, "scene": 18, "line": "So how are things going with Andy?", "order": 35092, "speaker": "Michael Scott"},
  {"season": 6, "episode": 20, "scene": 18, "line": "He's the best boyfriend in the world. Tell me about him before I met him.", "order": 35093, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 20, "scene": 18, "line": "Well, let's see. Um... Andy. Plays the banjo.", "order": 35094, "speaker": "Michael Scott"},
  {"season": 6, "episode": 20, "scene": 18, "line": "Yeah I love that.", "order": 35095, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 20, "scene": 18, "line": "Other than the fact that he dated Angela, I don't think he is a snappy dresser.", "order": 35096, "speaker": "Michael Scott"},
  {"season": 6, "episode": 20, "scene": 18, "line": "What?", "order": 35097, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 20, "scene": 18, "line": "I don't think he is the best dresser. Reminds me of Easter.", "order": 35098, "speaker": "Michael Scott"},
  {"season": 6, "episode": 20, "scene": 18, "line": "Sorry. About Angela? Did you say he dated Angela?", "order": 35099, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 20, "scene": 18, "line": "Mm-hmm. You didn't know that? Oh.", "order": 35100, "speaker": "Michael Scott"},
  {"season": 6, "episode": 20, "scene": 18, "line": "Here we go. Hot plate.", "order": 35101, "speaker": "Hayworth's waiter"},
  {"season": 6, "episode": 20, "scene": 18, "line": "Okay.", "order": 35102, "speaker": "Michael Scott"},
  {"season": 6, "episode": 20, "scene": 18, "line": "And your salad, miss.", "order": 35103, "speaker": "Hayworth's waiter"},
  {"season": 6, "episode": 20, "scene": 18, "line": "Why wouldn't he tell me that?", "order": 35104, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 20, "scene": 18, "line": "I don't know. Probably didn't want you to have a mental image of him having sex with somebody else.", "order": 35105, "speaker": "Michael Scott"},
  {"season": 6, "episode": 20, "scene": 18, "line": "They had sex?", "order": 35106, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 20, "scene": 18, "line": "They were engaged, so... [Erin looks shocked] Okay, you know what, sir?", "order": 35107, "speaker": "Michael Scott"},
  {"season": 6, "episode": 20, "scene": 18, "line": "Yes?", "order": 35108, "speaker": "Hayworth's waiter"},
  {"season": 6, "episode": 20, "scene": 18, "line": "Yeah, I asked for pickles with my burger. And there are only, like five or six. Could I get some more pickles?", "order": 35109, "speaker": "Michael Scott"},
  {"season": 6, "episode": 20, "scene": 18, "line": "Of course. I'll get you a bowl of pickles.", "order": 35110, "speaker": "Hayworth's waiter"},
  {"season": 6, "episode": 20, "scene": 18, "line": "Thank you.", "order": 35111, "speaker": "Michael Scott"},
  {"season": 6, "episode": 20, "scene": 18, "line": "Uhhhh, they were engaged?", "order": 35112, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 20, "scene": 18, "line": "Mm-hmm. Yeah.", "order": 35113, "speaker": "Michael Scott"},
  {"season": 6, "episode": 20, "scene": 18, "line": "[face looks disgusted, moves around uncomfortably] Uh-oh...", "order": 35114, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 20, "scene": 18, "line": "Well...", "order": 35115, "speaker": "Michael Scott"},
  {"season": 6, "episode": 20, "scene": 18, "line": "[moans, pulls hair over her face]", "order": 35116, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 20, "scene": 18, "line": "What are you doing? What are you doing?", "order": 35117, "speaker": "Michael Scott"},
  {"season": 6, "episode": 20, "scene": 18, "line": "In the Foster home, my hair was my room. [exhales, prolonged exclaiming]", "order": 35118, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 20, "scene": 18, "line": "Okay, okay. You know what? You know what? You know what? Everybody's looking at you right now.", "order": 35119, "speaker": "Michael Scott"},
  {"season": 6, "episode": 20, "scene": 18, "line": "[breathes heavily, groans]", "order": 35120, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 20, "scene": 18, "line": "[turns to other restaurant patrons] I'll have what she's having!", "order": 35121, "speaker": "Michael Scott"},
  {"season": 6, "episode": 20, "scene": 19, "line": "[in Michael's car] Did you have a... did you like your lunch? Did you have a good lunch? Did you like that? Did you enjoy your food? It was good. I liked my lunch.", "order": 35122, "speaker": "Michael Scott"},
  {"season": 6, "episode": 20, "scene": 20, "line": "[feels a pain in her breast] Ooh. Ah-ah-ah. Ha-ha.", "order": 35123, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 20, "scene": 20, "line": "What's up?", "order": 35124, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 20, "scene": 20, "line": "Her milk is coming in. She's getting uncomfortable.", "order": 35125, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 20, "scene": 20, "line": "Dwight, don't be gross.", "order": 35126, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 20, "scene": 20, "line": "No, no, he's right.", "order": 35127, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 20, "scene": 20, "line": "Same thing happens with my cows if I don't tend them frequently enough. You gotta milk them, or else they'll moo like crazy.", "order": 35128, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 20, "scene": 20, "line": "That's weird, my breast pump is missing. Have you seen my breast pump?", "order": 35129, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 20, "scene": 20, "line": "[removes jacket, applies hand sanitizer] All right, this is gonna traumatize me a hell of a lot more than you, believe me.", "order": 35130, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 20, "scene": 20, "line": "You know what, Dwight? Let me just check the bathroom first. Okay?", "order": 35131, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 20, "scene": 20, "line": "Really? Fine. Let your breasts explode. Three squeezes and I would drain you.", "order": 35132, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 20, "scene": 20, "line": "Mm. [walks into bathroom, Meredith is using her brest pump] Meredith!", "order": 35133, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 20, "scene": 20, "line": "I just like the way it feels.", "order": 35134, "speaker": "Meredith Palmer"},
  {"season": 6, "episode": 20, "scene": 20, "line": "What are you doing?!", "order": 35135, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 20, "scene": 20, "line": "Just relax. Okay.", "order": 35136, "speaker": "Meredith Palmer"},
  {"season": 6, "episode": 20, "scene": 20, "line": "Relax?!", "order": 35137, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 20, "scene": 20, "line": "This is like the Cadillac of pumps.", "order": 35138, "speaker": "Meredith Palmer"},
  {"season": 6, "episode": 20, "scene": 20, "line": "Give it back to me now.", "order": 35139, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 20, "scene": 20, "line": "Okay, I was just warming it up.", "order": 35140, "speaker": "Meredith Palmer"},
  {"season": 6, "episode": 20, "scene": 20, "line": "It's disgusting!", "order": 35141, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 20, "scene": 20, "line": "It's not a big deal.", "order": 35142, "speaker": "Meredith Palmer"},
  {"season": 6, "episode": 20, "scene": 20, "line": "It's not sterile!", "order": 35143, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 20, "scene": 20, "line": "We're both girls. Who cares?", "order": 35144, "speaker": "Meredith Palmer"},
  {"season": 6, "episode": 20, "scene": 21, "line": "So... how was lunch?", "order": 35145, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 20, "scene": 21, "line": "Lunch was fine.", "order": 35146, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 20, "scene": 21, "line": "Was it everything that you hoped and dreamed it might be?", "order": 35147, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 20, "scene": 21, "line": "I don't know. It was lunch, Andy.", "order": 35148, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 20, "scene": 21, "line": "Well, I know. [stammers] Just want... I mean... [chuckles]", "order": 35149, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 20, "scene": 21, "line": "Erin? I think we're out of fax cover sheets.", "order": 35150, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 20, "scene": 21, "line": "God, Oscar, will you keep your pants on? It's easy.", "order": 35151, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 20, "scene": 22, "line": "[using a Cookie Monster voice] 'My name is Kevin, I'm an accountant.' See I did the voice.", "order": 35152, "speaker": "Kelly Kapoor"},
  {"season": 6, "episode": 20, "scene": 22, "line": "That's a little derivative.", "order": 35153, "speaker": "Ryan Howard"},
  {"season": 6, "episode": 20, "scene": 22, "line": "But parody is always derivative.", "order": 35154, "speaker": "Kelly Kapoor"},
  {"season": 6, "episode": 20, "scene": 22, "line": "Uh, it's not organic. Do you know what I mean?", "order": 35155, "speaker": "Ryan Howard"},
  {"season": 6, "episode": 20, "scene": 22, "line": "Jim does a really good one. Do yours.", "order": 35156, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 20, "scene": 22, "line": "Oh, guys. I really refuse to participate. 'Michael, can I have an advance on my paycheck because a Mrs. Fields Cookie just opened up at the mall.'", "order": 35157, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 20, "scene": 22, "line": "Guys, why don't we leave the parodies to the pros at Mad TV?", "order": 35158, "speaker": "Gabe Lewis"},
  {"season": 6, "episode": 20, "scene": 22, "line": "Sorry, Gabe, but that show hasn't been on in many cookies.'", "order": 35159, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 20, "scene": 22, "line": "Ok.", "order": 35160, "speaker": "Gabe Lewis"},
  {"season": 6, "episode": 20, "scene": 22, "line": "Hey. Can I have your attention please? First of all, thanks to everyone for helping put this awesome party together. And a very special shout-out to the chair of the Party Planning Committee, miss Angela Martin. You have outdone yourself.", "order": 35161, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 20, "scene": 22, "line": "You know what, I think we can all agree that Angela's not so great, so..", "order": 35162, "speaker": "Michael Scott"},
  {"season": 6, "episode": 20, "scene": 22, "line": "I am saying the exact opposite. Angela is fantastic.", "order": 35163, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 20, "scene": 22, "line": "No. No. No.", "order": 35164, "speaker": "Michael Scott"},
  {"season": 6, "episode": 20, "scene": 22, "line": "Specific shout-out..", "order": 35165, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 20, "scene": 22, "line": "Stop talking.", "order": 35166, "speaker": "Michael Scott"},
  {"season": 6, "episode": 20, "scene": 22, "line": "Ok. As some of you may know, I have a very special connection with Secretary's Day in the form of that 115 pound moonbeam over there named Erin Hannon. To a lot of you she may just be the person who brings you your fax comformations. But to me, she is my girlfriend... in addition to being the person who brings me my fax comformations. So I wrote [starts to use a British accent] a little ditty. That I would like to play for all of you right now. It's a little tune called Secretary of Love. A one, a two, a one, two, three..", "order": 35167, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 20, "scene": 22, "line": "[throws a piece of cake at Andy's face] I know about Angela! I know that you were engaged to her and that you were sleeping with her! Michael told me.", "order": 35168, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 20, "scene": 22, "line": "Oh God.", "order": 35169, "speaker": "Michael Scott"},
  {"season": 6, "episode": 20, "scene": 22, "line": "Can we talk about this in private?", "order": 35170, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 20, "scene": 22, "line": "I don't want to be in private. Is it true or is it not?", "order": 35171, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 20, "scene": 22, "line": "Yes.", "order": 35172, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 20, "scene": 22, "line": "Who else did you sleep with? Did you sleep with Phyllis or Kelly or Pam? Maybe all together?", "order": 35173, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 20, "scene": 22, "line": "No, no never.", "order": 35174, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 20, "scene": 22, "line": "Did everyone know? Was I the only one who didn't know?", "order": 35175, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 20, "scene": 22, "line": "Yeah pretty much.", "order": 35176, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 20, "scene": 22, "line": "Who are you?! I don't know you! [pushes someone away to leave]", "order": 35177, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 20, "scene": 23, "line": "I should probably get involved in this, but I think my energy is better spent on the Cookie Monster issue.", "order": 35178, "speaker": "Gabe Lewis"},
  {"season": 6, "episode": 20, "scene": 24, "line": "Erin, you know, if I had been engaged to anyone before you, I would've told you.", "order": 35179, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 20, "scene": 24, "line": "Thanks.", "order": 35180, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 20, "scene": 24, "line": "So if you need anything, I'm right over there.", "order": 35181, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 20, "scene": 24, "line": "[imitating Cookie Monster] 'Hey, Erin, You look delicious. I mean, beautiful.'", "order": 35182, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 20, "scene": 24, "line": "Ok. Ok there. I heard that. So I'm sorry, Pam, but that's it. I'm going to have to suspend you without pay for two days.", "order": 35183, "speaker": "Gabe Lewis"},
  {"season": 6, "episode": 20, "scene": 24, "line": "What? What do you mean, suspend me?", "order": 35184, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 20, "scene": 24, "line": "I think you need to go a little easy. You can't just suspend someone form work.", "order": 35185, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 20, "scene": 24, "line": "Yeah, you're right. You know what? Um, you're suspended too Jim. [Dwight starts to clap] Ok fine. You too Dwight.", "order": 35186, "speaker": "Gabe Lewis"},
  {"season": 6, "episode": 20, "scene": 24, "line": "Wait, What? I was just slow-clapping your no-nonsense decision-making.", "order": 35187, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 20, "scene": 24, "line": "I don't want to hear it. Suspended.", "order": 35188, "speaker": "Gabe Lewis"},
  {"season": 6, "episode": 20, "scene": 24, "line": "C' is for suspension.", "order": 35189, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 20, "scene": 25, "line": "Why would that be something to talk about on Secretary's Day?", "order": 35190, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 20, "scene": 25, "line": "Andy, she's not the easiest person in the world to have a conversation with. All right? And besides, who doesn't tell their girlfriend that they were engaged to someone who works four feet away from them? That's like.. that's like Mr. and Mrs. Smith crap.", "order": 35191, "speaker": "Michael Scott"},
  {"season": 6, "episode": 20, "scene": 25, "line": "I was going to tell her, just on my own time.", "order": 35192, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 20, "scene": 25, "line": "You know what, I resent the implication that I would keep that secret. Everyone here knows that I can't and won't keep a secret.", "order": 35193, "speaker": "Michael Scott"},
  {"season": 6, "episode": 20, "scene": 26, "line": "Who cares? I mean it's not like I killed someone. Big deal right? This can blow over in two seconds. She never asked me. So, if you think about it... I probably should have told her I guess. Everybody does stupid things.", "order": 35194, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 20, "scene": 27, "line": "Psst. Hey. Erin.", "order": 35195, "speaker": "Angela Martin"},
  {"season": 6, "episode": 20, "scene": 27, "line": "You want to talk to me, come to my desk.", "order": 35196, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 20, "scene": 27, "line": "You embarrassed me earlier.", "order": 35197, "speaker": "Angela Martin"},
  {"season": 6, "episode": 20, "scene": 27, "line": "Oh. Take it up with the chief of police.", "order": 35198, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 20, "scene": 27, "line": "You think I want people remembering I had sensual relations with Andy? It's the kind of thing you wish you could have annulled. I want to throw up just thinking about it.", "order": 35199, "speaker": "Angela Martin"},
  {"season": 6, "episode": 20, "scene": 27, "line": "I want to throw up just thinking about it.", "order": 35200, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 20, "scene": 27, "line": "You are throwing up for the wrong reasons!", "order": 35201, "speaker": "Angela Martin"},
  {"season": 6, "episode": 20, "scene": 27, "line": "[standing behind Erin] I don't think this fax is going through. Is there a different... I can just put it through again, you know.", "order": 35202, "speaker": "Toby Flenderson"},
  {"season": 6, "episode": 20, "scene": 28, "line": "Hey. You know I was engaged before Jim.", "order": 35203, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 20, "scene": 28, "line": "Really?", "order": 35204, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 20, "scene": 28, "line": "Yeah. And he worked here too.", "order": 35205, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 20, "scene": 28, "line": "It was Andy, wasn't it?", "order": 35206, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 20, "scene": 28, "line": "No, no it wasn't Andy. It wasn't. It doesn't matter. It's not about who you've been with. It's about who you end up with. Sometimes the heart doesn't know what it wants until it finds what it wants.", "order": 35207, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 20, "scene": 28, "line": "I hope you find what you're looking for.", "order": 35208, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 20, "scene": 28, "line": "Thank you. [kisses Jim on the cheek]", "order": 35209, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 20, "scene": 29, "line": "[talking on the phone] So I can reprimand them but I can't suspend them. Oh I can't do either. Uh huh. Fair enough.", "order": 35210, "speaker": "Gabe Lewis"},
  {"season": 6, "episode": 20, "scene": 30, "line": "[answers phone] Hey Toby.", "order": 35211, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 20, "scene": 30, "line": "I called Tallahassee. He can ask you not to come in, but he can't dock your pay. That's not legal.", "order": 35212, "speaker": "Toby Flenderson"},
  {"season": 6, "episode": 20, "scene": 30, "line": "Very interesting.", "order": 35213, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 20, "scene": 30, "line": "Yeah, I also learned some interesting things about how they structure...", "order": 35214, "speaker": "Toby Flenderson"},
  {"season": 6, "episode": 20, "scene": 30, "line": "[Gabe walks in] Thanks Toby.", "order": 35215, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 20, "scene": 30, "line": "Hey everyone. Hi. Quite an afternoon, huh? Cathartic in a way. I'm glad I got to share it with you. It makes you think about what's really important. [Pam hands Jim a note] It's not about showing you're in charge or flexing some sort of authority. It's about forgiveness. And yes, I'm talking about you three in this desk clump right here. I think that, if you were to apologize to me, then I would revoke your suspension.", "order": 35216, "speaker": "Gabe Lewis"},
  {"season": 6, "episode": 20, "scene": 30, "line": "I don't think an apology is enough. I really think the only way for me to learn my lesson is to take my suspension.", "order": 35217, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 20, "scene": 30, "line": "And I got to take this bad attitude, and I got to go home, and I got to adjust it. And I hope the suspension does that.", "order": 35218, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 20, "scene": 30, "line": "[gets up and walks towards Gabe] Prideful idiots. Watch how it's done. [Jim and Pam get their things together and start to leave] Gabriel, I apologize.", "order": 35219, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 20, "scene": 30, "line": "Great.", "order": 35220, "speaker": "Gabe Lewis"},
  {"season": 6, "episode": 20, "scene": 30, "line": "[gets on one knee] I kneel before you.", "order": 35221, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 20, "scene": 30, "line": "Guys, I would even take a contrite look as an apology.", "order": 35222, "speaker": "Gabe Lewis"},
  {"season": 6, "episode": 20, "scene": 30, "line": "Humbly taking your hand upon which I kiss to show my contrition.", "order": 35223, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 20, "scene": 30, "line": "[following Jim and Pam to their car] Guys, to err is human. [trying to block their car]", "order": 35224, "speaker": "Gabe Lewis"},
  {"season": 6, "episode": 20, "scene": 31, "line": "Trust is the most important thing to me. Is your name Andy Bernard? What's your real name? Lionel Frankenstein?", "order": 35225, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 20, "scene": 31, "line": "Will you stop walking for one second? That's it. There's no other secrets. Ok? I mean yeah, my chest is not naturally hairless, and my parents pay my credit card bill.", "order": 35226, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 20, "scene": 31, "line": "How long were you guys together?", "order": 35227, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 20, "scene": 31, "line": "A year.", "order": 35228, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 20, "scene": 31, "line": "A year? You were together for a year?", "order": 35229, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 20, "scene": 31, "line": "Yeah but that's it. Now you know everything about me. I promise. And if I think of anything else...", "order": 35230, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 20, "scene": 31, "line": "I think I have to be on my own for a little bit. Like the girl Precious in Precious. Based on the novel Push by Sapphire. Ok?", "order": 35231, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 20, "scene": 31, "line": "I didn't see that movie.", "order": 35232, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 20, "scene": 32, "line": "[walking back into the office] That was ugly. I'm sorry you had to see that.", "order": 35233, "speaker": "Gabe Lewis"},
  {"season": 6, "episode": 20, "scene": 32, "line": "Do they still get paid?", "order": 35234, "speaker": "Kelly Kapoor"},
  {"season": 6, "episode": 20, "scene": 32, "line": "It's tacky to discuss finances. It's best to pretend that this never happened.", "order": 35235, "speaker": "Gabe Lewis"},
  {"season": 6, "episode": 20, "scene": 32, "line": "Sounds like they just got to go home with pay.", "order": 35236, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 20, "scene": 32, "line": "Pathetic.", "order": 35237, "speaker": "Meredith Palmer"},
  {"season": 6, "episode": 20, "scene": 32, "line": "Can I buy everyone coffees? [everyone shakes their heads] [in Cookie Monster voice] 'Or cookies.'", "order": 35238, "speaker": "Gabe Lewis"},
  {"season": 6, "episode": 20, "scene": 32, "line": "What was that? Are you kidding me? Was that an impression?", "order": 35239, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 20, "scene": 32, "line": "Alright.", "order": 35240, "speaker": "Gabe Lewis"},
  {"season": 6, "episode": 20, "scene": 32, "line": "[imitating Gabe] Uh guys, I'm now going to apologize for the mess that we're in because corporate told me to. I just want you to know that I laugh like a crazy person.", "order": 35241, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 20, "scene": 32, "line": "You got to be able to laugh at yourself. I'm one of the gang.", "order": 35242, "speaker": "Gabe Lewis"},
  {"season": 6, "episode": 20, "scene": 32, "line": "I have to go back to the zoo to the stick insect exhibit.'", "order": 35243, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 20, "scene": 32, "line": "Ciao' Right? 'Ciao. I say Ciao because I'm fancy from Tallahassee.'", "order": 35244, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 20, "scene": 32, "line": "Why don't we say ciao to the impressions? [everyone says ciao]", "order": 35245, "speaker": "Gabe Lewis"},
  {"season": 6, "episode": 20, "scene": 33, "line": "[sitting on bench outside groaning]", "order": 35246, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 20, "scene": 33, "line": "Erin?", "order": 35247, "speaker": "Michael Scott"},
  {"season": 6, "episode": 20, "scene": 33, "line": "Oh. Sorry I thought everyone was gone.", "order": 35248, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 20, "scene": 33, "line": "No, no, I was just watching Cookie Monster. It finally downloaded. Mind if I sit down?", "order": 35249, "speaker": "Michael Scott"},
  {"season": 6, "episode": 20, "scene": 33, "line": "I can't trust Andy anymore.", "order": 35250, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 20, "scene": 33, "line": "Oh. Yep. I'm sorry about that. I feel... I'm sorry I caused that thing.", "order": 35251, "speaker": "Michael Scott"},
  {"season": 6, "episode": 20, "scene": 33, "line": "It's Andy's fault.", "order": 35252, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 20, "scene": 33, "line": "Well, yeah, yes. But I still feel sorry. You know what, I wouldn't worry about Angela. She doesn't hold a candle to you, Erin. She's old enough to be your mom for one thing. And she's, like, three feet tall. And she wears pioneer women clothing. And I don't think she's ever pooped in her life. And Andy, you know, come on. Andy, his butt looks big in those khakis.", "order": 35253, "speaker": "Michael Scott"},
  {"season": 6, "episode": 20, "scene": 33, "line": "Oh, I like his butt.", "order": 35254, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 20, "scene": 33, "line": "You said butt.", "order": 35255, "speaker": "Michael Scott"},
  {"season": 6, "episode": 20, "scene": 33, "line": "You tricked me!", "order": 35256, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 20, "scene": 33, "line": "Ahh! You said it! You know who you would love? Oh, this guy in my neighborhood, Tom Dizemore. This is him: 'Hey! Hey Scott!'", "order": 35257, "speaker": "Michael Scott"},
  {"season": 6, "episode": 20, "scene": 34, "line": "Sometimes telling someone something is hard. Well, at least someone made her happy on Secretary's Day.", "order": 35258, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 20, "scene": 35, "line": "Hey Kevin it's Oscar. I'm looking at the sheet you sent me. And I can't seem to find the column for shipping cost.", "order": 35259, "speaker": "Oscar Martinez's voice from the computer"},
  {"season": 6, "episode": 20, "scene": 36, "line": "I made the most brilliant retaliation video. It's awesome.", "order": 35260, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 20, "scene": 37, "line": "I don't get it.", "order": 35261, "speaker": "Phyllis Vance"},
  {"season": 6, "episode": 20, "scene": 37, "line": "Yeah, I mean, the Count has a very distinct voice.", "order": 35262, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 20, "scene": 37, "line": "Yeah. 'I vant to count to ten'.", "order": 35263, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 20, "scene": 37, "line": "But Oscar is an accountant, and the Count counts numbers.", "order": 35264, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 20, "scene": 37, "line": "But you're an accountant too.", "order": 35265, "speaker": "Kelly Kapoor"},
  {"season": 6, "episode": 20, "scene": 37, "line": "Why didn't you do Oscar the Grouch?", "order": 35266, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 20, "scene": 37, "line": "That would have been funny.", "order": 35267, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 20, "scene": 37, "line": "But the Count is purple and Oscar wears purple.", "order": 35268, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 20, "scene": 37, "line": "[walking up] Oh, oh, I was just watching that. Did you do that, man? 'Oscar. I am the Count.' Nailed him. Nailed him. [Gives Kevin a fist pump] Good work Cookie Monster.", "order": 35269, "speaker": "Michael Scott"},
  {"season": 6, "episode": 20, "scene": 37, "line": "Thank you.", "order": 35270, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 21, "scene": 1, "line": "Buenos dias, Erin.", "order": 35271, "speaker": "Michael Scott"},
  {"season": 6, "episode": 21, "scene": 1, "line": "Buenos dias, Miguel. [phone rings] Hello, Dunder-Mifflin.", "order": 35272, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 21, "scene": 1, "line": "No, no, no, no. Solamente en espanol, por favor.", "order": 35273, "speaker": "Michael Scott"},
  {"season": 6, "episode": 21, "scene": 2, "line": "I believe that every man, woman, and child in this country should learn how to speak Spanish. They are our neighbors to the South, and this would be a healing thing for... for all of North America. And... I am going on vacation next week to Cancun.", "order": 35274, "speaker": "Michael Scott"},
  {"season": 6, "episode": 21, "scene": 3, "line": "Buenos dias, Jaime.", "order": 35275, "speaker": "Michael Scott"},
  {"season": 6, "episode": 21, "scene": 3, "line": "Buenos dias, Miguel. Como estas? Bien? Claro que si! Yo estoy fantastico. Que pasa?", "order": 35276, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 21, "scene": 3, "line": "Ha ha! Buenos dias, Dwight!", "order": 35277, "speaker": "Michael Scott"},
  {"season": 6, "episode": 21, "scene": 3, "line": "Guten tag, Herr Michael.", "order": 35278, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 21, "scene": 4, "line": "I don't understand why Michael is wasting his time with Spanish. I have it on very good authority that within 20 years, everyone will be speaking German. Or a Chinese-German hybrid.", "order": 35279, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 21, "scene": 5, "line": "La telefona.", "order": 35280, "speaker": "Michael Scott"},
  {"season": 6, "episode": 21, "scene": 5, "line": "El telefono.", "order": 35281, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 21, "scene": 6, "line": "Michael's having a hard time with the gender part of Spanish. So I told him to mark everything with the international symbol for gender. And, um... [holds up two blurred-out drawings of male and female genitalia] I should have been more specific.", "order": 35282, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 21, "scene": 7, "line": "Your office is full of genitalia.", "order": 35283, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 21, "scene": 7, "line": "[clears throat] Oh. Eso es lo que dice, el!", "order": 35284, "speaker": "Michael Scott"},
  {"season": 6, "episode": 21, "scene": 7, "line": "That's what he says?'", "order": 35285, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 21, "scene": 7, "line": "Damn it. [knock on door] Ah, Angelo.", "order": 35286, "speaker": "Michael Scott"},
  {"season": 6, "episode": 21, "scene": 7, "line": "Angela. [Michael sticks post-it on Angela's forehead with female genitalia] Michael.", "order": 35287, "speaker": "Angela Martin"},
  {"season": 6, "episode": 21, "scene": 7, "line": "Yo soy Cancun.", "order": 35288, "speaker": "Michael Scott"},
  {"season": 6, "episode": 21, "scene": 7, "line": "[removes post-it, leaves] Uhh!", "order": 35289, "speaker": "Angela Martin"},
  {"season": 6, "episode": 21, "scene": 8, "line": "All right, so I'll finish up on bulk pricing, and then you go into delivery guarantee.", "order": 35290, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 21, "scene": 8, "line": "You know, maybe there's an opportunity for a joke there, like, um... like, [vaudeville delivery] 'I just delivered a baby. They didn't offer me a guarantee.'", "order": 35291, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 21, "scene": 8, "line": "Yeah, or maybe we don't even need that.", "order": 35292, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 21, "scene": 8, "line": "Can you smell the power of the Halperts? Power couple.", "order": 35293, "speaker": "Michael Scott"},
  {"season": 6, "episode": 21, "scene": 8, "line": "You know what? I should be getting this client, Michael. My numbers are better than either of theirs.", "order": 35294, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 21, "scene": 8, "line": "I went with the people I thought would put the best face on the company.", "order": 35295, "speaker": "Michael Scott"},
  {"season": 6, "episode": 21, "scene": 8, "line": "If you want people to put the best face on something, why would you get two people who probably never cut the face off of anything in their lives?", "order": 35296, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 21, "scene": 8, "line": "[whispers] That's a good point.", "order": 35297, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 21, "scene": 9, "line": "This is our first joint sales pitch. I think we're gonna work really well together.", "order": 35298, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 21, "scene": 9, "line": "Yeah I think we have complimentary strengths. We understand each other.", "order": 35299, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 21, "scene": 9, "line": "We have good give and take. [vaudeville delivery] I give, he takes.", "order": 35300, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 21, "scene": 9, "line": "[whispering] I don't even know who you are anymore.", "order": 35301, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 21, "scene": 9, "line": "[vaudeville delivery] Yee.", "order": 35302, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 21, "scene": 10, "line": "Hi, uh, Donna Newton, here to see Michael Scott.", "order": 35303, "speaker": "Donna Newton"},
  {"season": 6, "episode": 21, "scene": 10, "line": "Oh, hey, Miss Newton. Hi, I'm Jim.", "order": 35304, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 21, "scene": 10, "line": "Hi!", "order": 35305, "speaker": "Donna Newton"},
  {"season": 6, "episode": 21, "scene": 10, "line": "How are you?", "order": 35306, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 21, "scene": 10, "line": "Did somebody order a hooker over here?", "order": 35307, "speaker": "Michael Scott"},
  {"season": 6, "episode": 21, "scene": 10, "line": "[laughing] Oh, stop that.", "order": 35308, "speaker": "Donna Newton"},
  {"season": 6, "episode": 21, "scene": 10, "line": "Hi. How are you?", "order": 35309, "speaker": "Michael Scott"},
  {"season": 6, "episode": 21, "scene": 11, "line": "There's this woman I met a few weeks ago. Her name is Donna, the manager of Sid & Dexter's and, right now, we're in the midst of a passionate love affair. Um, no. But she is coming by today. Maybe to buy some printers, so...", "order": 35310, "speaker": "Michael Scott"},
  {"season": 6, "episode": 21, "scene": 12, "line": "Right here, we have the wonder twins, Jim and Pam Halpert. They will be assisting you today.", "order": 35311, "speaker": "Michael Scott"},
  {"season": 6, "episode": 21, "scene": 12, "line": "You look exactly alike.", "order": 35312, "speaker": "Donna Newton"},
  {"season": 6, "episode": 21, "scene": 12, "line": "Oh, no we're actually married. We're not brother and sister.", "order": 35313, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 21, "scene": 12, "line": "I have a sense about these things.", "order": 35314, "speaker": "Donna Newton"},
  {"season": 6, "episode": 21, "scene": 12, "line": "All right.", "order": 35315, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 21, "scene": 12, "line": "You have some ancestors in common... somewhere back.", "order": 35316, "speaker": "Donna Newton"},
  {"season": 6, "episode": 21, "scene": 12, "line": "I knew it. You should see their baby.", "order": 35317, "speaker": "Angela Martin"},
  {"season": 6, "episode": 21, "scene": 13, "line": "[explaining PowerPoint to Donna] So, yes, laser printers are more expensive. But they cost less to operate [knocking] So it's a tradeoff.", "order": 35318, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 21, "scene": 13, "line": "[barges in] Hi.", "order": 35319, "speaker": "Michael Scott"},
  {"season": 6, "episode": 21, "scene": 13, "line": "Hold on one second.", "order": 35320, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 21, "scene": 13, "line": "Sorry to interrupt. Sorry to interrupt. Do you, Donna, by any chance shop at Victoria's Secret?", "order": 35321, "speaker": "Michael Scott"},
  {"season": 6, "episode": 21, "scene": 13, "line": "What?", "order": 35322, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 21, "scene": 13, "line": "Because I keep getting these magazines sent to me via the address of the woman who used to live in my condo before me.", "order": 35323, "speaker": "Michael Scott"},
  {"season": 6, "episode": 21, "scene": 13, "line": "None of this is time sensitive.", "order": 35324, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 21, "scene": 13, "line": "Uh, yes, uh, the sale is on now through May.", "order": 35325, "speaker": "Michael Scott"},
  {"season": 6, "episode": 21, "scene": 13, "line": "Oh, yeah, thank you.", "order": 35326, "speaker": "Donna Newton"},
  {"season": 6, "episode": 21, "scene": 13, "line": "Okay, I have more of them.", "order": 35327, "speaker": "Michael Scott"},
  {"season": 6, "episode": 21, "scene": 14, "line": "You would have weekly conference calls with executives in corporate. A two-week training program at the Yale school of management, obviously you would be high on the list for advancement opportunities.", "order": 35328, "speaker": "Gabe Lewis"},
  {"season": 6, "episode": 21, "scene": 14, "line": "Sounds all right.", "order": 35329, "speaker": "Darryl Philbin"},
  {"season": 6, "episode": 21, "scene": 14, "line": "What is this?", "order": 35330, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 21, "scene": 14, "line": "Oh, this is 'Print in All Colors,' Sabre's minority executive training program.", "order": 35331, "speaker": "Gabe Lewis"},
  {"season": 6, "episode": 21, "scene": 14, "line": "It doesn't just sound a'ight, it sounds amazing.", "order": 35332, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 21, "scene": 14, "line": "I didn't say 'a'ight.'", "order": 35333, "speaker": "Darryl Philbin"},
  {"season": 6, "episode": 21, "scene": 14, "line": "How do I apply?", "order": 35334, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 21, "scene": 14, "line": "You have to be a minority.", "order": 35335, "speaker": "Gabe Lewis"},
  {"season": 6, "episode": 21, "scene": 14, "line": "Uh, glasses wearers. Cholera survivors. Geniuses. Non-organic family farmers. The list goes on and on. You want me to keep going?", "order": 35336, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 21, "scene": 14, "line": "Those don't really count. We're thinking more ethnic and racial minorities.", "order": 35337, "speaker": "Gabe Lewis"},
  {"season": 6, "episode": 21, "scene": 14, "line": "Come here. Come here.", "order": 35338, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 21, "scene": 14, "line": "Hmm?", "order": 35339, "speaker": "Gabe Lewis"},
  {"season": 6, "episode": 21, "scene": 14, "line": "Ten seconds ago, this guy was driving a forklift. Okay? Now all off a sudden he's Cinderella of the office. What are you guys thinking?", "order": 35340, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 21, "scene": 14, "line": "Well...", "order": 35341, "speaker": "Gabe Lewis"},
  {"season": 6, "episode": 21, "scene": 14, "line": "I like the sound of this. [looks at Dwight] Maybe one day I'll be sitting in Michael's chair. Wouldn't that be something?", "order": 35342, "speaker": "Darryl Philbin"},
  {"season": 6, "episode": 21, "scene": 15, "line": "One minority from this branch is going to get into this program. Who would I refer? Hmm... the competent, hardworking one who does things his own way, or the malleable simpleton who can be bought for a few fashion magazines?", "order": 35343, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 21, "scene": 16, "line": "[on phone] This is the second time that you've sent me the wrong size. I mean, I know what a four feels like. I've been a four my whole life. You know what? You can go to Hell. All right? Thanks for nothing.", "order": 35344, "speaker": "Kelly Kapoor"},
  {"season": 6, "episode": 21, "scene": 16, "line": "Oh, man. White people, right?", "order": 35345, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 21, "scene": 16, "line": "I don't know if she was white.", "order": 35346, "speaker": "Kelly Kapoor"},
  {"season": 6, "episode": 21, "scene": 16, "line": "Well, you can kinda tell from the voice.", "order": 35347, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 21, "scene": 16, "line": "Yeah.", "order": 35348, "speaker": "Kelly Kapoor"},
  {"season": 6, "episode": 21, "scene": 16, "line": "I bet you get pulled over by the cops a lot, just because of your race.", "order": 35349, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 21, "scene": 16, "line": "Well, they say it's because of texting, but maybe you're right.", "order": 35350, "speaker": "Kelly Kapoor"},
  {"season": 6, "episode": 21, "scene": 16, "line": "I think you should consider applying for the executive minority training program.", "order": 35351, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 21, "scene": 16, "line": "Never thought of myself as an executive before.", "order": 35352, "speaker": "Kelly Kapoor"},
  {"season": 6, "episode": 21, "scene": 16, "line": "I know, 'cause you have no role models. How many Indian CEO's can you think of?", "order": 35353, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 21, "scene": 16, "line": "I can't think of any CEO's, any race.", "order": 35354, "speaker": "Kelly Kapoor"},
  {"season": 6, "episode": 21, "scene": 16, "line": "You could be the Indian Bill Gates. You could be the Indian Ted Turner.", "order": 35355, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 21, "scene": 16, "line": "I could be the Indian Julia Roberts.", "order": 35356, "speaker": "Kelly Kapoor"},
  {"season": 6, "episode": 21, "scene": 16, "line": "That's not... she's... okay. Yes.", "order": 35357, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 21, "scene": 17, "line": "I took the liberty to scan a few things earlier, and I want to show you... there we go! [Michael's picture pops up on the PowerPoint screen] Whoa, look at those vivid colors. Look at my eyes. Those are Shrek-green eyes. That is me again. I think this displays the crisp, dazzling white.", "order": 35358, "speaker": "Michael Scott"},
  {"season": 6, "episode": 21, "scene": 17, "line": "Mm-hmm.", "order": 35359, "speaker": "Donna Newton"},
  {"season": 6, "episode": 21, "scene": 17, "line": "And that would be a display [image from a catalog of a man wearing only white boxers pops up] of the crisp, gorgeous black.", "order": 35360, "speaker": "Michael Scott"},
  {"season": 6, "episode": 21, "scene": 17, "line": "Yeah.", "order": 35361, "speaker": "Donna Newton"},
  {"season": 6, "episode": 21, "scene": 18, "line": "It's subtle. That's how it works. I show her an image that turns her on. And then she looks at me, then back at me, then back at the image. Soon, she doesn't know what is me, what is the image. She just knows that she's turned on.", "order": 35362, "speaker": "Michael Scott"},
  {"season": 6, "episode": 21, "scene": 19, "line": "This is a place that I like to go to be alone with my thoughts. I've never taken anybody there before.", "order": 35363, "speaker": "Michael Scott"},
  {"season": 6, "episode": 21, "scene": 19, "line": "Who took the photo?", "order": 35364, "speaker": "Donna Newton"},
  {"season": 6, "episode": 21, "scene": 19, "line": "Ryan.", "order": 35365, "speaker": "Michael Scott"},
  {"season": 6, "episode": 21, "scene": 19, "line": "Oh. [Michael quickly advances PowerPoint show to last slide which is 'SEX' in bold white letters on a black background and then exits the show quickly]", "order": 35366, "speaker": "Donna Newton"},
  {"season": 6, "episode": 21, "scene": 19, "line": "And that's it. That's me. That's who I am.", "order": 35367, "speaker": "Michael Scott"},
  {"season": 6, "episode": 21, "scene": 19, "line": "[giggles] Not bad.", "order": 35368, "speaker": "Donna Newton"},
  {"season": 6, "episode": 21, "scene": 19, "line": "Oh, thanks. [softly] You're not bad, either.", "order": 35369, "speaker": "Michael Scott"},
  {"season": 6, "episode": 21, "scene": 19, "line": "Thank you. [Michael tries t kiss Donna, Donna clears throat] Hmm.", "order": 35370, "speaker": "Donna Newton"},
  {"season": 6, "episode": 21, "scene": 19, "line": "Hmm.", "order": 35371, "speaker": "Michael Scott"},
  {"season": 6, "episode": 21, "scene": 19, "line": "Oh my God. [realizing Jim and Pam are looking through the conference room windows]", "order": 35372, "speaker": "Donna Newton"},
  {"season": 6, "episode": 21, "scene": 19, "line": "Jim! Could you come in here for a sec? What was that printer we were looking at?", "order": 35373, "speaker": "Michael Scott"},
  {"season": 6, "episode": 21, "scene": 20, "line": "[in his office] You didn't see it, Pam. She was giving me all sorts of signals that she wanted me to make a move. Shortbread?", "order": 35374, "speaker": "Michael Scott"},
  {"season": 6, "episode": 21, "scene": 20, "line": "No.", "order": 35375, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 21, "scene": 20, "line": "I'll have one.", "order": 35376, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 21, "scene": 20, "line": "Well, maybe you shouldn't try to kiss people at work.", "order": 35377, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 21, "scene": 20, "line": "Oh really, Jim? What about Pam? And you did a heck of a lot more than kiss. Maybe this could be my Pam.", "order": 35378, "speaker": "Michael Scott"},
  {"season": 6, "episode": 21, "scene": 20, "line": "Okay. Well, all that is irrelevant.", "order": 35379, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 21, "scene": 20, "line": "Maybe Michael has a point. I mean, if she was really that upset, she wouldn't still be here.", "order": 35380, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 21, "scene": 21, "line": "Was it professional? No. But I work in the nightlife industry. I get hit on all the time. In my 20's it would have been annoying. In my late 20's, I find it really flattering.", "order": 35381, "speaker": "Donna Newton"},
  {"season": 6, "episode": 21, "scene": 22, "line": "Look, I know that I screwed up in there. I know that I probably messed up your sale. I just want to go in and fix it.", "order": 35382, "speaker": "Michael Scott"},
  {"season": 6, "episode": 21, "scene": 22, "line": "What if I back out and you finish the pitch with Jim?", "order": 35383, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 21, "scene": 22, "line": "What?", "order": 35384, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 21, "scene": 23, "line": "What was that?", "order": 35385, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 21, "scene": 23, "line": "Just let him flirt with her. People meet each other all sorts of ways.", "order": 35386, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 21, "scene": 23, "line": "Pam, you know he's not gonna get anywhere, and he's gonna blow our sale.", "order": 35387, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 21, "scene": 23, "line": "Who cares? [Michael peeking at them through his office window] It's not that huge a sale.", "order": 35388, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 21, "scene": 23, "line": "Hey, the Schwab guy told us to treat every sale like it was a huge sale.", "order": 35389, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 21, "scene": 23, "line": "Don't act like you understood anything that guy said. [pat's Jim's arm] Good luck, wingman. [salutes]", "order": 35390, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 21, "scene": 24, "line": "How as being a minority affected you?", "order": 35391, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 21, "scene": 24, "line": "Well, there's a lot of pressure from my parents to settle down and marry an Indian guy.", "order": 35392, "speaker": "Kelly Kapoor"},
  {"season": 6, "episode": 21, "scene": 24, "line": "Oh, good, and you resent this because...", "order": 35393, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 21, "scene": 24, "line": "Indian guys always wear their cell phones outside their pants. It's so dorky.", "order": 35394, "speaker": "Kelly Kapoor"},
  {"season": 6, "episode": 21, "scene": 24, "line": "Oh, no, no. That's not dorky. [Dwight wears his phone like that] Look, it's easily accessible. Boom, like this. 911, hello. Scranton Strangler's in the house. Inside the house.", "order": 35395, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 21, "scene": 24, "line": "Just put it in your pocket.", "order": 35396, "speaker": "Kelly Kapoor"},
  {"season": 6, "episode": 21, "scene": 25, "line": "[indistinct chatter inside conference room]", "order": 35397, "speaker": "Michael Scott & Donna Newton"},
  {"season": 6, "episode": 21, "scene": 25, "line": "[looking it] Look at that. She's totally flirting with him.", "order": 35398, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 21, "scene": 25, "line": "You don't know that. Some people can't help oozing sexuality.", "order": 35399, "speaker": "Phyllis Vance"},
  {"season": 6, "episode": 21, "scene": 25, "line": "You ever notice you can ooze two things: sexuality and pus. Man, I tell ya.", "order": 35400, "speaker": "Creed Bratton"},
  {"season": 6, "episode": 21, "scene": 25, "line": "She just crossed her arms together... that's bad.", "order": 35401, "speaker": "Phyllis Vance"},
  {"season": 6, "episode": 21, "scene": 25, "line": "Maybe she's just pushing her breasts together to make them look bigger.", "order": 35402, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 21, "scene": 25, "line": "[tries it by crossing his arms] Like that? [to Andy]", "order": 35403, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 21, "scene": 26, "line": "Is this... is this the best you can do right there?", "order": 35404, "speaker": "Donna Newton"},
  {"season": 6, "episode": 21, "scene": 26, "line": "Uh, for that printer, yes. Unfortunately we don't have a lot of flexibility.", "order": 35405, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 21, "scene": 26, "line": "Oh. Well, I guess I'm just used to the restaurant business, [removes sweater revealing her revealing top] where, if you're in charge, then you can always get discounts for the people that you like.", "order": 35406, "speaker": "Donna Newton"},
  {"season": 6, "episode": 21, "scene": 26, "line": "Yeah. [chuckles]", "order": 35407, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 21, "scene": 26, "line": "So maybe we could find a little wiggle room, right?", "order": 35408, "speaker": "Michael Scott"},
  {"season": 6, "episode": 21, "scene": 26, "line": "What's that?", "order": 35409, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 21, "scene": 26, "line": "Maybe wee find some wiggle room?", "order": 35410, "speaker": "Michael Scott"},
  {"season": 6, "episode": 21, "scene": 26, "line": "I don't... I don't think so.", "order": 35411, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 21, "scene": 26, "line": "I think we could. [Donna giggles]", "order": 35412, "speaker": "Michael Scott"},
  {"season": 6, "episode": 21, "scene": 27, "line": "I don't see how we could possibly sell these for that little without losing money. Delivery alone will cost...", "order": 35413, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 21, "scene": 27, "line": "Okay, well sometimes... sometimes it makes financial sense to lose money, right? Like for tax purposes.", "order": 35414, "speaker": "Michael Scott"},
  {"season": 6, "episode": 21, "scene": 27, "line": "Actually, ran the numbers on this, and in this case, it makes more financial sense to gain money.", "order": 35415, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 21, "scene": 27, "line": "Why don't you run them again?", "order": 35416, "speaker": "Michael Scott"},
  {"season": 6, "episode": 21, "scene": 27, "line": "What if she's just flirting with you to get a better price?", "order": 35417, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 21, "scene": 27, "line": "If she is, it's working.", "order": 35418, "speaker": "Michael Scott"},
  {"season": 6, "episode": 21, "scene": 27, "line": "Yeah, Michael, here's a trick. Ask her if she wants a mint. If she says 'no,' then she is not interested.", "order": 35419, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 21, "scene": 28, "line": "She does not like him.", "order": 35420, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 21, "scene": 28, "line": "You can flirt with someone to get what you want and also be attracted to them. How do you think we got together?", "order": 35421, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 21, "scene": 28, "line": "cause I stopped by your desk like 15 times a day.", "order": 35422, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 21, "scene": 28, "line": "I was after your money.", "order": 35423, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 21, "scene": 28, "line": "Well, the joke was on you.", "order": 35424, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 21, "scene": 28, "line": "Yes, it was.", "order": 35425, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 21, "scene": 29, "line": "[typing] Can you stop micromanaging? I know how to do this.", "order": 35426, "speaker": "Kelly Kapoor"},
  {"season": 6, "episode": 21, "scene": 29, "line": "Okay...", "order": 35427, "speaker": "Ryan Howard"},
  {"season": 6, "episode": 21, "scene": 29, "line": "Hey... what are you guys doing?", "order": 35428, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 21, "scene": 29, "line": "This girl was really rude to me at the mall. So I created a fake I.M. account from a hot guy at her high school, and now I'm trying to make her anorexic.", "order": 35429, "speaker": "Kelly Kapoor"},
  {"season": 6, "episode": 21, "scene": 29, "line": "Tell her everyone in home room thinks she's fat.", "order": 35430, "speaker": "Ryan Howard"},
  {"season": 6, "episode": 21, "scene": 29, "line": "Oh, that is so good.", "order": 35431, "speaker": "Kelly Kapoor"},
  {"season": 6, "episode": 21, "scene": 29, "line": "You know, we really should keep practicing for this interview.", "order": 35432, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 21, "scene": 29, "line": "Oh, that's okay. Ryan coached me.", "order": 35433, "speaker": "Kelly Kapoor"},
  {"season": 6, "episode": 21, "scene": 29, "line": "Ryan? What does he know?", "order": 35434, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 21, "scene": 29, "line": "It's easy... you just turn every question around on them. Do you think you're treated differently because of your race?", "order": 35435, "speaker": "Ryan Howard"},
  {"season": 6, "episode": 21, "scene": 29, "line": "Would you ask that same question if I was white? We're so in.", "order": 35436, "speaker": "Kelly Kapoor"},
  {"season": 6, "episode": 21, "scene": 29, "line": "We?'", "order": 35437, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 21, "scene": 29, "line": "When I become executive, I'm gonna make Ryan manager.", "order": 35438, "speaker": "Kelly Kapoor"},
  {"season": 6, "episode": 21, "scene": 29, "line": "And then the two of us are cleanin' house.", "order": 35439, "speaker": "Ryan Howard"},
  {"season": 6, "episode": 21, "scene": 30, "line": "Kelly will be even worse than Darryl. If you'd have told me this morning that today I'd be creating a monster capable f my own destruction, I 'd have thought you were referring to the bull Mose and I are trying to reanimate.", "order": 35440, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 21, "scene": 31, "line": "So corporate approval of this contract is contingent upon buyer and seller...", "order": 35441, "speaker": "Michael Scott"},
  {"season": 6, "episode": 21, "scene": 31, "line": "Mm-hmm.", "order": 35442, "speaker": "Donna Newton"},
  {"season": 6, "episode": 21, "scene": 31, "line": "Operating blahdy blahdy blah. Boilerplate. Pound of fish. [Donna laughing] Just kidding. Do you like mints?", "order": 35443, "speaker": "Michael Scott"},
  {"season": 6, "episode": 21, "scene": 31, "line": "Yes.", "order": 35444, "speaker": "Donna Newton"},
  {"season": 6, "episode": 21, "scene": 31, "line": "I carry mints with me sometimes. You ever tried those?", "order": 35445, "speaker": "Michael Scott"},
  {"season": 6, "episode": 21, "scene": 31, "line": "Oh, I haven't tried those. No.", "order": 35446, "speaker": "Donna Newton"},
  {"season": 6, "episode": 21, "scene": 31, "line": "They're good [Donna grabs mint]", "order": 35447, "speaker": "Michael Scott"},
  {"season": 6, "episode": 21, "scene": 31, "line": "You want one?", "order": 35448, "speaker": "Donna Newton"},
  {"season": 6, "episode": 21, "scene": 31, "line": "Sure.", "order": 35449, "speaker": "Michael Scott"},
  {"season": 6, "episode": 21, "scene": 31, "line": "It's like cool... 'certified cool.' I like how they say 'certified.' Like there's some consortium of... [Michael grabs the mint from Donna's hand with his mouth, Donna pulls her hand away] Michael!", "order": 35450, "speaker": "Donna Newton"},
  {"season": 6, "episode": 21, "scene": 32, "line": "I can't stop myself from kissing her.", "order": 35451, "speaker": "Michael Scott"},
  {"season": 6, "episode": 21, "scene": 32, "line": "Yeah, now you know how I feel sitting next to those M&M's all day.", "order": 35452, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 21, "scene": 32, "line": "Well, why don't you just move the M&M's?", "order": 35453, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 21, "scene": 32, "line": "Well, why don't you shut up!", "order": 35454, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 21, "scene": 32, "line": "Okay, guys, guys, guys. Just tell me it's not totally insane to try one more time.", "order": 35455, "speaker": "Michael Scott"},
  {"season": 6, "episode": 21, "scene": 32, "line": "It is totally insane.", "order": 35456, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 21, "scene": 32, "line": "Michael, he's right. You are turning into a stalker.", "order": 35457, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 21, "scene": 32, "line": "Okay. What do you think?", "order": 35458, "speaker": "Michael Scott"},
  {"season": 6, "episode": 21, "scene": 32, "line": "I think it's over, man.", "order": 35459, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 21, "scene": 32, "line": "Andy?", "order": 35460, "speaker": "Michael Scott"},
  {"season": 6, "episode": 21, "scene": 32, "line": "Ehh.", "order": 35461, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 21, "scene": 32, "line": "Okay, some for, some against.", "order": 35462, "speaker": "Michael Scott"},
  {"season": 6, "episode": 21, "scene": 32, "line": "Nobody's for.", "order": 35463, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 21, "scene": 32, "line": "Pam?", "order": 35464, "speaker": "Michael Scott"},
  {"season": 6, "episode": 21, "scene": 32, "line": "Hmm?", "order": 35465, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 21, "scene": 32, "line": "What do you think?", "order": 35466, "speaker": "Mihael"},
  {"season": 6, "episode": 21, "scene": 32, "line": "Um... I think it's really, really unlikely.", "order": 35467, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 21, "scene": 32, "line": "Okay.", "order": 35468, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 21, "scene": 32, "line": "Really unlikely. But I can't say that it's impossible.", "order": 35469, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 21, "scene": 32, "line": "What?", "order": 35470, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 21, "scene": 33, "line": "I'm not saying she's in love with him. But she could have left a while ago. Most printer sales are done over the phone, Ms. Boob shirt.", "order": 35471, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 21, "scene": 34, "line": "I'm going with Pam's group.", "order": 35472, "speaker": "Michael Scott"},
  {"season": 6, "episode": 21, "scene": 34, "line": "Wait.", "order": 35473, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 21, "scene": 34, "line": "Here we go.", "order": 35474, "speaker": "Michael Scott"},
  {"season": 6, "episode": 21, "scene": 34, "line": "Michael. Michael.", "order": 35475, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 21, "scene": 34, "line": "Okay.", "order": 35476, "speaker": "Michael Scott"},
  {"season": 6, "episode": 21, "scene": 34, "line": "Michael.", "order": 35477, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 21, "scene": 34, "line": "No, guys, guys, guys. Guys, just... thank you for talking. Wish me luck.", "order": 35478, "speaker": "Michael Scott"},
  {"season": 6, "episode": 21, "scene": 34, "line": "No.", "order": 35479, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 21, "scene": 35, "line": "What sort of movie would Rudy have been if he had just stopped, given up, after two rejections? Would have been a lot shorter. Probably been a lot funnier. But it would have ultimately been a disappointment. I still would have seen it, but that's not... the point.", "order": 35480, "speaker": "Michael Scott"},
  {"season": 6, "episode": 21, "scene": 36, "line": "Kelly is disqualified!", "order": 35481, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 21, "scene": 36, "line": "What?", "order": 35482, "speaker": "Gabe Lewis"},
  {"season": 6, "episode": 21, "scene": 36, "line": "You said the program is not open to Caucasians. Well... anthropologically, she is Indian. Indians migrated from the caucuses region of Europe. Therefore, technically, she is Caucasian. You're welcome, America.", "order": 35483, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 21, "scene": 36, "line": "Yeah, but she's not white, though.", "order": 35484, "speaker": "Gabe Lewis"},
  {"season": 6, "episode": 21, "scene": 36, "line": "Well, obviously, she is brownish. But come on, I mean, Darryl is far more ethnic.", "order": 35485, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 21, "scene": 36, "line": "Darryl withdrew his application. He said the Yale program interfered with his softball league, you know? He's gone.", "order": 35486, "speaker": "Gabe Lewis"},
  {"season": 6, "episode": 21, "scene": 37, "line": "I got my whole life to be a minority executive. I only have about a year left in these knees, though.", "order": 35487, "speaker": "Darryl Philbin"},
  {"season": 6, "episode": 21, "scene": 38, "line": "Kelly is the only applicant.", "order": 35488, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 21, "scene": 38, "line": "Yeah, unless somebody else applies today.", "order": 35489, "speaker": "Gabe Lewis"},
  {"season": 6, "episode": 21, "scene": 38, "line": "Namaste.", "order": 35490, "speaker": "Kelly Kapoor"},
  {"season": 6, "episode": 21, "scene": 38, "line": "Oh, dear God. Okay. Don't make any decisions just yet. Okay? I'll be right back.", "order": 35491, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 21, "scene": 38, "line": "Hello.", "order": 35492, "speaker": "Kelly Kapoor"},
  {"season": 6, "episode": 21, "scene": 38, "line": "That's very nice. [referring to Kelly's bindi] I never noticed that before.", "order": 35493, "speaker": "Gabe Lewis"},
  {"season": 6, "episode": 21, "scene": 38, "line": "Sometimes my bangs cover it.", "order": 35494, "speaker": "Kelly Kapoor"},
  {"season": 6, "episode": 21, "scene": 38, "line": "Yeah. I don't want to be offensive but, uh... may I ask you what that means?", "order": 35495, "speaker": "Gabe Lewis"},
  {"season": 6, "episode": 21, "scene": 38, "line": "I do find that offensive, actually.", "order": 35496, "speaker": "Kelly Kapoor"},
  {"season": 6, "episode": 21, "scene": 39, "line": "We should have your contract done by Thursday.", "order": 35497, "speaker": "Michael Scott"},
  {"season": 6, "episode": 21, "scene": 39, "line": "Oh, great. Great, I will look out for that stuff, then.", "order": 35498, "speaker": "Donna Newton"},
  {"season": 6, "episode": 21, "scene": 39, "line": "Okay.", "order": 35499, "speaker": "Michael Scott"},
  {"season": 6, "episode": 21, "scene": 39, "line": "And, um... you know I just wanted to say it was really... really nice to do business with you.", "order": 35500, "speaker": "Donna Newton"},
  {"season": 6, "episode": 21, "scene": 39, "line": "Yeah it was nice to do business with you, too.", "order": 35501, "speaker": "Michael Scott"},
  {"season": 6, "episode": 21, "scene": 39, "line": "Thank you. Did I... get everything?", "order": 35502, "speaker": "Donna Newton"},
  {"season": 6, "episode": 21, "scene": 39, "line": "I don't know. I think so.", "order": 35503, "speaker": "Michael Scott"},
  {"season": 6, "episode": 21, "scene": 39, "line": "Yeah, I think so.", "order": 35504, "speaker": "Donna Newton"},
  {"season": 6, "episode": 21, "scene": 39, "line": "[whispers] Okay. So... Oh, Donna?", "order": 35505, "speaker": "Michael Scott"},
  {"season": 6, "episode": 21, "scene": 39, "line": "Yeah? Do you need validation? We don't... we don't validate.", "order": 35506, "speaker": "Donna Newton"},
  {"season": 6, "episode": 21, "scene": 39, "line": "No, it's just in the lot, so...", "order": 35507, "speaker": "Donna Newton"},
  {"season": 6, "episode": 21, "scene": 39, "line": "Thank you so much.", "order": 35508, "speaker": "Michael Scott"},
  {"season": 6, "episode": 21, "scene": 39, "line": "You're welcome. [they hug] You're... welcome.", "order": 35509, "speaker": "Donna Newton"},
  {"season": 6, "episode": 21, "scene": 39, "line": "Mmm. [Donna giggles]", "order": 35510, "speaker": "Michael Scott"},
  {"season": 6, "episode": 21, "scene": 39, "line": "Bye.", "order": 35511, "speaker": "Donna Newton"},
  {"season": 6, "episode": 21, "scene": 39, "line": "[follows her out of office] Ahem. So, um... okay, if you have... if there's any other questions that you have...", "order": 35512, "speaker": "Michael Scott"},
  {"season": 6, "episode": 21, "scene": 39, "line": "I don't have, uh, any other questions. [blind clanking as she leans against door to prevent from Michael kissing her] I'm just gonna... make my way down to...", "order": 35513, "speaker": "Donna Newton"},
  {"season": 6, "episode": 21, "scene": 39, "line": "All right. All right. Bye.", "order": 35514, "speaker": "Michael Scott"},
  {"season": 6, "episode": 21, "scene": 39, "line": "Bye-bye!", "order": 35515, "speaker": "Donna Newton"},
  {"season": 6, "episode": 21, "scene": 39, "line": "And have a good elevator ride.", "order": 35516, "speaker": "Michael Scott"},
  {"season": 6, "episode": 21, "scene": 39, "line": "Okay.", "order": 35517, "speaker": "Donna Newton"},
  {"season": 6, "episode": 21, "scene": 39, "line": "Oh, what's that out there?", "order": 35518, "speaker": "Michael Scott"},
  {"season": 6, "episode": 21, "scene": 39, "line": "Michael, how did it go? [Michael goes in his office and closes door]", "order": 35519, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 21, "scene": 40, "line": "How would one of you feel if I told you I could put you on a fast track to an executive position at this company?", "order": 35520, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 21, "scene": 40, "line": "Holy cow. I'd be so happy.", "order": 35521, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 21, "scene": 40, "line": "I wasn't talking to you, pale-face.", "order": 35522, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 21, "scene": 40, "line": "I know. I meant I'd be happy for them.", "order": 35523, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 21, "scene": 40, "line": "What I'm offering is a ticket on a bullet train straight to middle management.", "order": 35524, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 21, "scene": 40, "line": "Dwight, I know these programs. 'Every color is important because, together, we make a rainbow.'", "order": 35525, "speaker": "Stanley Hudson"},
  {"season": 6, "episode": 21, "scene": 40, "line": "Yes.", "order": 35526, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 21, "scene": 40, "line": "I'll slap you in the face with a rainbow.", "order": 35527, "speaker": "Stanley Hudson"},
  {"season": 6, "episode": 21, "scene": 40, "line": "Mm!", "order": 35528, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 21, "scene": 40, "line": "Speaking of rainbows, Oscar... you are kind of a double minority. Gay. So we at Sabre could really benefit from your perspective.", "order": 35529, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 21, "scene": 40, "line": "Dwight, we know Kelly applied. We're not gonna cross her. No matter how good the program is.", "order": 35530, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 21, "scene": 40, "line": "I can protect you from Kelly. [Erin stifles laugh] Will you get out of here? Seriously. [Erin turns and leaves kitchen]", "order": 35531, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 21, "scene": 41, "line": "For hobbies, um, yoga, belly dancing, snake charming. Beds of nails. I like lying on them.", "order": 35532, "speaker": "Kelly Kapoor"},
  {"season": 6, "episode": 21, "scene": 41, "line": "Whoa, whoa. Whoa, whoa, whoa! This is Hide Toshi Hasagawa. He would like to apply for the Sabre minority executive training program 'print in all colors' initiative.", "order": 35533, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 21, "scene": 41, "line": "Dwight: What are you doing?", "order": 35534, "speaker": "Kelly Kapoor"},
  {"season": 6, "episode": 21, "scene": 41, "line": "Helping heal America in a dramatic fashion in the 11th hour.", "order": 35535, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 21, "scene": 41, "line": "God, I hate you so much! [gets up, leaves]", "order": 35536, "speaker": "Kelly Kapoor"},
  {"season": 6, "episode": 21, "scene": 41, "line": "Caucasians, am I right? [to Hide] Have a seat. I'll translate.", "order": 35537, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 21, "scene": 41, "line": "Why? I don't need translator.", "order": 35538, "speaker": "Hidetoshi Hasagawa"},
  {"season": 6, "episode": 21, "scene": 41, "line": "You don't know what you need. Just...", "order": 35539, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 21, "scene": 41, "line": "Well, uh... why don't you just tell me a little about yourself, Hide.", "order": 35540, "speaker": "Gabe Lewis"},
  {"season": 6, "episode": 21, "scene": 41, "line": "In Japan, heart surgeon, number one. Steady hand.", "order": 35541, "speaker": "Hidetoshi Hasagawa"},
  {"season": 6, "episode": 21, "scene": 42, "line": "Hey, Michael. [Michael is sitting on couch outside his office] How you doing?", "order": 35542, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 21, "scene": 42, "line": "I don't know. I don't know. Can't really trust my feelings anymore.", "order": 35543, "speaker": "Michael Scott"},
  {"season": 6, "episode": 21, "scene": 42, "line": "You know what, Michael? For what it's worth... I was wrong, too. I thought she was interested in you.", "order": 35544, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 21, "scene": 42, "line": "She suckered you too.", "order": 35545, "speaker": "Michael Scott"},
  {"season": 6, "episode": 21, "scene": 42, "line": "Mm-hmm.", "order": 35546, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 21, "scene": 42, "line": "Was it the cleavage?", "order": 35547, "speaker": "Michael Scott"},
  {"season": 6, "episode": 21, "scene": 42, "line": "Yeah, and the shoulder cutouts.", "order": 35548, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 21, "scene": 42, "line": "Yep.", "order": 35549, "speaker": "Michael Scott"},
  {"season": 6, "episode": 21, "scene": 42, "line": "Michael, you know, it's easy to get fooled. Bob's warehouse guys flirt with me all the time. It... it's mostly harmless. Usually I don't let it go too far.", "order": 35550, "speaker": "Phyllis Vance"},
  {"season": 6, "episode": 21, "scene": 42, "line": "You know, when I tore my scrote, I was, uh... I was seeing this really hot urologist about it, and thought she was into me. But now I think she was just doing a bunch of stuff to bill my HMO. You know, she's touching around down there. It's easy to get confused.", "order": 35551, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 21, "scene": 42, "line": "Michael, I think Donna left this here. Should I put it in the lost and found for 14 days and then I can take it home?", "order": 35552, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 21, "scene": 42, "line": "God. She left this here?", "order": 35553, "speaker": "Michael Scott"},
  {"season": 6, "episode": 21, "scene": 42, "line": "Yeah.", "order": 35554, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 21, "scene": 42, "line": "Okay. Maybe she wants me to return this to her at her work.", "order": 35555, "speaker": "Michael Scott"},
  {"season": 6, "episode": 21, "scene": 42, "line": "What?", "order": 35556, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 21, "scene": 42, "line": "No.", "order": 35557, "speaker": "All"},
  {"season": 6, "episode": 21, "scene": 42, "line": "Yeah. This could be a signal. People don't just take barrettes off.", "order": 35558, "speaker": "Michael Scott"},
  {"season": 6, "episode": 21, "scene": 42, "line": "It's not a signal. It's just a coincidence. No, it's not even a coincidence. It's just something that happened.", "order": 35559, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 21, "scene": 42, "line": "Michael...", "order": 35560, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 21, "scene": 42, "line": "[hold up barrette] Well...", "order": 35561, "speaker": "Michael Scott"},
  {"season": 6, "episode": 21, "scene": 42, "line": "She doesn't want you to return the barrette. She's not interested in you.", "order": 35562, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 21, "scene": 42, "line": "Yeah, I know. I know.", "order": 35563, "speaker": "Michael Scott"},
  {"season": 6, "episode": 21, "scene": 42, "line": "I'm sorry.", "order": 35564, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 21, "scene": 42, "line": "I know. You're right. Thank you. Thanks everybody. [waves, returns to his office, clears throat, turns around and runs out of office]", "order": 35565, "speaker": "Michael Scott"},
  {"season": 6, "episode": 21, "scene": 42, "line": "Michael! [sighs]", "order": 35566, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 21, "scene": 43, "line": "[runs down to parking lot, sighs]", "order": 35567, "speaker": "Michael Scott"},
  {"season": 6, "episode": 21, "scene": 43, "line": "[still in parking lot sitting in her car] Michael!", "order": 35568, "speaker": "Donna Newton"},
  {"season": 6, "episode": 21, "scene": 43, "line": "Oh. You're still here. I have your baguette.", "order": 35569, "speaker": "Michael Scott"},
  {"season": 6, "episode": 21, "scene": 44, "line": "Everyone, it is my pleasure to announce our newest member of the Sabre minority executive training program. Kelly Rajnigandha Kapoor. [clapping] All right.", "order": 35570, "speaker": "Gabe Lewis"},
  {"season": 6, "episode": 21, "scene": 45, "line": "They're going to be pretty pleased in Tallahassee that I snagged an Indian for the program. She'll be the first. The program's mostly black. It's almost too black. That didn't sound right.", "order": 35571, "speaker": "Gabe Lewis"},
  {"season": 6, "episode": 21, "scene": 46, "line": "[puts a bindi on Erin's forehead] And the best part is is that I get a business stipend to buy all new clothes. So you can totally buy any of my old clothes that I'm not gonna need anymore.", "order": 35572, "speaker": "Kelly Kapoor"},
  {"season": 6, "episode": 21, "scene": 46, "line": "Oh, my God, thank you so much. I love that denim jumpsuit you have. How much?", "order": 35573, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 21, "scene": 46, "line": "Oh. Actually I was thinking about clothes that I was just gonna give to Goodwill anyway.", "order": 35574, "speaker": "Kelly Kapoor"},
  {"season": 6, "episode": 21, "scene": 46, "line": "Great.", "order": 35575, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 21, "scene": 46, "line": "Hey, kiddo. Congratulations are in order. You deserve it. [Dwight extends his hand, but Kelly hugs him]", "order": 35576, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 21, "scene": 46, "line": "Thank you, Dwight.", "order": 35577, "speaker": "Kelly Kapoor"},
  {"season": 6, "episode": 21, "scene": 46, "line": "Oh.", "order": 35578, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 21, "scene": 46, "line": "I'll never forget everything that you've done for me.", "order": 35579, "speaker": "Kelly Kapoor"},
  {"season": 6, "episode": 21, "scene": 46, "line": "Gosh. He he.", "order": 35580, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 21, "scene": 46, "line": "Because I never forget anything.", "order": 35581, "speaker": "Kelly Kapoor"},
  {"season": 6, "episode": 21, "scene": 47, "line": "Just once, I would like to be a puppet master and have nothing go wrong. Is that too much to ask?", "order": 35582, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 21, "scene": 48, "line": "You know what? Everybody told me that you weren't interested. Everybody. And I didn't believe 'em. And they were right. So... there's your barrette.", "order": 35583, "speaker": "Michael Scott"},
  {"season": 6, "episode": 21, "scene": 48, "line": "No, y-you were... right.", "order": 35584, "speaker": "Donna Newton"},
  {"season": 6, "episode": 21, "scene": 48, "line": "Who?", "order": 35585, "speaker": "Michael Scott"},
  {"season": 6, "episode": 21, "scene": 48, "line": "You were right.", "order": 35586, "speaker": "Donna Newton"},
  {"season": 6, "episode": 21, "scene": 48, "line": "About what?", "order": 35587, "speaker": "Michael Scott"},
  {"season": 6, "episode": 21, "scene": 48, "line": "You were right. [Michael leans into Donna's car to kiss her and they kiss passionately] I'm sorry.", "order": 35588, "speaker": "Donna Newton"},
  {"season": 6, "episode": 21, "scene": 48, "line": "No, no. No.", "order": 35589, "speaker": "Michael Scott"},
  {"season": 6, "episode": 21, "scene": 48, "line": "I'm sor...", "order": 35590, "speaker": "Donna Newton"},
  {"season": 6, "episode": 21, "scene": 48, "line": "No... [they kiss again]", "order": 35591, "speaker": "Michael Scott"},
  {"season": 6, "episode": 21, "scene": 49, "line": "[returns to office] What happened?", "order": 35592, "speaker": "Michael Scott"},
  {"season": 6, "episode": 21, "scene": 49, "line": "Kelly got into the minority training program.", "order": 35593, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 21, "scene": 49, "line": "Really?! Oh, that's great. That's such good news. I did it! [all look at him] I kissed. We kissed.", "order": 35594, "speaker": "Michael Scott"},
  {"season": 6, "episode": 21, "scene": 49, "line": "Ooh.", "order": 35595, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 21, "scene": 49, "line": "Oh, Michael.", "order": 35596, "speaker": "Phyllis Vance"},
  {"season": 6, "episode": 21, "scene": 49, "line": "No, I'm serious. It... this happened. I went down to the parking lot and I was... I returned her barrette [holds up barrette] We ki... We did. Okay. We did. We did.", "order": 35597, "speaker": "Michael Scott"},
  {"season": 6, "episode": 21, "scene": 50, "line": "You know what? It doesn't matter. Because I know it happened. And that is all that counts. [shouts] I did do it!", "order": 35598, "speaker": "Michael Scott"},
  {"season": 6, "episode": 22, "scene": 1, "line": "Stop it!", "order": 35599, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 22, "scene": 1, "line": "Stop what?", "order": 35600, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 22, "scene": 1, "line": "You're talking about me in Morse Code. Well, you know what? Joke's on you 'cause I know Morse Code. Ha!", "order": 35601, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 22, "scene": 1, "line": "[chuckles] Yeah. That's what we're doing. In our very limited free time and with our very limited budget, we went and got a nanny and then we went out and took a class on a very outmoded and very unnecessary form of communication just so we could talk about you in front of you.", "order": 35602, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 22, "scene": 2, "line": "Yup. That's exactly what we did.", "order": 35603, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 22, "scene": 2, "line": "It all started when Dwight was tapping his foot against the leg of his desk. When I asked him to stop, he said, 'I will when you lose the baby weight.'", "order": 35604, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 22, "scene": 3, "line": "Very well. I must have imagined it. I apologize. [Pam clacking her stapler and Jim responding with tapping his keyboard rhythmically] Detonator. Detonator where? Michael!", "order": 35605, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 22, "scene": 4, "line": "Jim. Are you clicking a detonator?", "order": 35606, "speaker": "Michael Scott"},
  {"season": 6, "episode": 22, "scene": 4, "line": "It's a pen.", "order": 35607, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 22, "scene": 4, "line": "Michael, come on.", "order": 35608, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 22, "scene": 4, "line": "Get back to work, Dwight. Please.", "order": 35609, "speaker": "Michael Scott"},
  {"season": 6, "episode": 22, "scene": 4, "line": "Fine. [clears throat] Hey. Tap away. [Dwight puts noise-cancelling headphones on and Pam and Jim begin to blink rhythmically]", "order": 35610, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 22, "scene": 5, "line": "Ooh! Things with Donna are so... oh-ho-ho! They're going great. I, uh... we're just clicking on every level. Emotionally and sexually and... orally and I am not used to relationships going this well. I'm actually having trouble focusing on my job. And I like it!", "order": 35611, "speaker": "Michael Scott"},
  {"season": 6, "episode": 22, "scene": 6, "line": "Who enjoys the weekends? [all raise hands] Of course. Now the weekend is always great if you have someone, which I do. I have Donna. She is hot. She has a Pilates butt. But we need to find something to do this weekend beside have sex. Did I say that? Yes, I did. [all nod] And the reason you are here is that I need ideas for things that Donna and I could do on the weekend. So just shout it out.", "order": 35612, "speaker": "Michael Scott"},
  {"season": 6, "episode": 22, "scene": 6, "line": "I have an idea for your weekend.", "order": 35613, "speaker": "Stanley Hudson"},
  {"season": 6, "episode": 22, "scene": 6, "line": "Okay.", "order": 35614, "speaker": "Michael Scott"},
  {"season": 6, "episode": 22, "scene": 6, "line": "Let me get back to my desk right now.", "order": 35615, "speaker": "Stanley Hudson"},
  {"season": 6, "episode": 22, "scene": 6, "line": "Okay, you get out of here, big dog. [high-fives Stanley] Ah, no, no, no, no. You guys sit down. I need ideas.", "order": 35616, "speaker": "Michael Scott"},
  {"season": 6, "episode": 22, "scene": 6, "line": "Stanley got to go.", "order": 35617, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 22, "scene": 6, "line": "Yeah, well, Stanley doesn't help with anything. Come on. Shout 'em out, shout 'em out.", "order": 35618, "speaker": "Michael Scott"},
  {"season": 6, "episode": 22, "scene": 6, "line": "Walk around apple orchards.", "order": 35619, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 22, "scene": 6, "line": "Oh!", "order": 35620, "speaker": "Michael Scott"},
  {"season": 6, "episode": 22, "scene": 6, "line": "Super romantic.", "order": 35621, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 22, "scene": 6, "line": "That's fun.", "order": 35622, "speaker": "Michael Scott"},
  {"season": 6, "episode": 22, "scene": 6, "line": "Eel fishing.", "order": 35623, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 22, "scene": 6, "line": "All right.", "order": 35624, "speaker": "Michael Scott"},
  {"season": 6, "episode": 22, "scene": 6, "line": "Curl up with your favorite DVD.", "order": 35625, "speaker": "Darryl Philbin"},
  {"season": 6, "episode": 22, "scene": 6, "line": "You and Donna should hit the Poconos. They have heart-shaped Jacuzzis. Room enough for three.", "order": 35626, "speaker": "Meredith Palmer"},
  {"season": 6, "episode": 22, "scene": 6, "line": "We actually went to the Poconos last Tuesday. We headed up there, we went to a little Chinese bistro, um, P.F. Chang's.", "order": 35627, "speaker": "Michael Scott"},
  {"season": 6, "episode": 22, "scene": 6, "line": "Wait, why would you go all the way to the Poconos to P.F. Chang's when we have the Great Wall in Scranton.", "order": 35628, "speaker": "Kelly Kapoor"},
  {"season": 6, "episode": 22, "scene": 6, "line": "Because when your super-hot girlfriend says, 'I wanna go to Mount Pocono,' you go to Mount Pocono and you do her. And we screwed. Whoops. TMI.", "order": 35629, "speaker": "Michael Scott"},
  {"season": 6, "episode": 22, "scene": 6, "line": "Wait, that's crazy far. Are you sure she's not cheating?", "order": 35630, "speaker": "Kelly Kapoor"},
  {"season": 6, "episode": 22, "scene": 6, "line": "You know what, Kelly? This is the real world. Not The Real World: Scranton. Oh my God, this is super weird. When Ryan had two girlfriends, he used to take me to some diner in Hazelton just so the other girl wouldn't see.", "order": 35631, "speaker": "Michael Scott"},
  {"season": 6, "episode": 22, "scene": 6, "line": "Some diner?! It was the Starlight Diner! It's in a LIFE magazine spread about Americana.", "order": 35632, "speaker": "Ryan Howard"},
  {"season": 6, "episode": 22, "scene": 6, "line": "You guys think Donna's cheating on me?", "order": 35633, "speaker": "Michael Scott"},
  {"season": 6, "episode": 22, "scene": 6, "line": "No, Michael, no.", "order": 35634, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 22, "scene": 6, "line": "Are you nuts?", "order": 35635, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 22, "scene": 6, "line": "You had a nice meal with your girlfriend in a lovely place...", "order": 35636, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 22, "scene": 6, "line": "... and... we had sex, too.", "order": 35637, "speaker": "Michael Scott"},
  {"season": 6, "episode": 22, "scene": 6, "line": "That is right. Don't make any more of it.", "order": 35638, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 22, "scene": 6, "line": "How?", "order": 35639, "speaker": "Michael Scott"},
  {"season": 6, "episode": 22, "scene": 6, "line": "How what?", "order": 35640, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 22, "scene": 6, "line": "How do I not make any more of it?", "order": 35641, "speaker": "Michael Scott"},
  {"season": 6, "episode": 22, "scene": 6, "line": "You could start by concluding this meeting.", "order": 35642, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 22, "scene": 6, "line": "All right. [all stand up to leave] And she won't say, 'I love you.'", "order": 35643, "speaker": "Michael Scott"},
  {"season": 6, "episode": 22, "scene": 6, "line": "Oh, no.", "order": 35644, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 22, "scene": 6, "line": "How many dates have you been on?", "order": 35645, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 22, "scene": 6, "line": "Nine dates. I said it on the second date.", "order": 35646, "speaker": "Michael Scott"},
  {"season": 6, "episode": 22, "scene": 6, "line": "Mm.", "order": 35647, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 22, "scene": 6, "line": "That seems... quick. Even for lesbians.", "order": 35648, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 22, "scene": 6, "line": "Does she keep her phone locked around you, Michael? Does she watch how much she drinks around you, Michael?", "order": 35649, "speaker": "Ryan Howard"},
  {"season": 6, "episode": 22, "scene": 7, "line": "Does she leave the room when she takes phone calls? Does she keep perfume in her purse? Does she shower before sex? Does she shower after sex? Does she...", "order": 35650, "speaker": "Ryan Howard"},
  {"season": 6, "episode": 22, "scene": 8, "line": "Yeah, she does all that.", "order": 35651, "speaker": "Michael Scott"},
  {"season": 6, "episode": 22, "scene": 8, "line": "Sorry, dude.", "order": 35652, "speaker": "Ryan Howard"},
  {"season": 6, "episode": 22, "scene": 8, "line": "No, no...", "order": 35653, "speaker": "Michael Scott"},
  {"season": 6, "episode": 22, "scene": 8, "line": "Michael, do not let your imagination run amok.", "order": 35654, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 22, "scene": 8, "line": "Run what?", "order": 35655, "speaker": "Michael Scott"},
  {"season": 6, "episode": 22, "scene": 8, "line": "Amok. It means, don't let your imagination run out of control.", "order": 35656, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 22, "scene": 8, "line": "Why didn't you just say that, Pam?", "order": 35657, "speaker": "Michael Scott"},
  {"season": 6, "episode": 22, "scene": 8, "line": "Michael, do not let your imagination run out of control.", "order": 35658, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 22, "scene": 8, "line": "Well, that's easy for you to say. You have a bad imagination. It's stupid. I live in a fantasy world.", "order": 35659, "speaker": "Michael Scott"},
  {"season": 6, "episode": 22, "scene": 8, "line": "You do?", "order": 35660, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 22, "scene": 8, "line": "Yes, Jim, I do. And I can't stay in a relationship that is full of lies and deceit.", "order": 35661, "speaker": "Michael Scott"},
  {"season": 6, "episode": 22, "scene": 8, "line": "But you didn't believe any of this was true five minutes ago.", "order": 35662, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 22, "scene": 8, "line": "That's what makes it so wrong.", "order": 35663, "speaker": "Michael Scott"},
  {"season": 6, "episode": 22, "scene": 9, "line": "Ever since I found out that Donna might be cheating on me, I have not eaten or slept. This not knowing, that's what's killing me.", "order": 35664, "speaker": "Michael Scott"},
  {"season": 6, "episode": 22, "scene": 10, "line": "Oh, God, that tickles. What did...", "order": 35665, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 22, "scene": 10, "line": "[whispers] I want someone to follow Donna. I want her tailed. I need the name of a good private investigator.", "order": 35666, "speaker": "Michael Scott"},
  {"season": 6, "episode": 22, "scene": 10, "line": "I think I've got one for you. [hands Michael a business card]", "order": 35667, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 22, "scene": 10, "line": "This is you. How much do you charge?", "order": 35668, "speaker": "Michael Scott"},
  {"season": 6, "episode": 22, "scene": 10, "line": "$100 a day, plus expenses.", "order": 35669, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 22, "scene": 10, "line": "I'll give you $50. Money's no object.", "order": 35670, "speaker": "Michael Scott"},
  {"season": 6, "episode": 22, "scene": 10, "line": "I'm just gonna warn you... and I say this to all my clients... you might not like what I find.", "order": 35671, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 22, "scene": 10, "line": "Okay.", "order": 35672, "speaker": "Michael Scott"},
  {"season": 6, "episode": 22, "scene": 10, "line": "And you might not like how I find it. [slides over table, leaves]", "order": 35673, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 22, "scene": 11, "line": "Of course. Yeah, that's terrible. Okay, let me get back to you. Hey, Stanley. One of my clients just called and said that their Sabre printer started smoking and caught on fire.", "order": 35674, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 22, "scene": 11, "line": "My doctor told me to cut out hot dogs. We all got problems.", "order": 35675, "speaker": "Stanley Hudson"},
  {"season": 6, "episode": 22, "scene": 12, "line": "Hey, Gabe I need to talk to you about something. It's really important.", "order": 35676, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 22, "scene": 12, "line": "There's no way that you guys have any almond butter, right?", "order": 35677, "speaker": "Gabe Lewis"},
  {"season": 6, "episode": 22, "scene": 12, "line": "Yeah, I don't know. Look, one of my clients called. He was in the middle of a big printing job and the back of the printer started smoking and then the paper tray caught on fire.", "order": 35678, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 22, "scene": 12, "line": "That's weird. I haven't heard of that happening. I would even settle for apricot preserves.", "order": 35679, "speaker": "Gabe Lewis"},
  {"season": 6, "episode": 22, "scene": 12, "line": "What are we gonna do about this?", "order": 35680, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 22, "scene": 12, "line": "I don't know. Call HQ, see if they know anything. Yeah. That's what I'll do today. All right. Yeah.", "order": 35681, "speaker": "Gabe Lewis"},
  {"season": 6, "episode": 22, "scene": 12, "line": "Let me know what they say.", "order": 35682, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 22, "scene": 12, "line": "[puts newspaper down] Wow. That dude is good.", "order": 35683, "speaker": "Darryl Philbin"},
  {"season": 6, "episode": 22, "scene": 12, "line": "What do you mean?", "order": 35684, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 22, "scene": 12, "line": "You didn't feel like he was hiding something?", "order": 35685, "speaker": "Darryl Philbin"},
  {"season": 6, "episode": 22, "scene": 12, "line": "I don't know.", "order": 35686, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 22, "scene": 12, "line": "Like he was... covering something up? Maybe.", "order": 35687, "speaker": "Darryl Philbin"},
  {"season": 6, "episode": 22, "scene": 13, "line": "Two years ago, Andy blamed the warehouse for a late shipment that he forgot to process. We got yelled at pretty bad. Almost lost my job, and I was mad as hell at the time. But I said 'Darryl, just wait. He's a fool. There's gonna be an opportunity. Just be patient.' [smiles]", "order": 35688, "speaker": "Darryl Philbin"},
  {"season": 6, "episode": 22, "scene": 14, "line": "[sitting on his Firebird's hood in front of a gym] Hi stranger.", "order": 35689, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 22, "scene": 14, "line": "Oh, hi. You work for Michael.", "order": 35690, "speaker": "Donna Newton"},
  {"season": 6, "episode": 22, "scene": 14, "line": "I work with Michael.", "order": 35691, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 22, "scene": 14, "line": "Right.", "order": 35692, "speaker": "Donna Newton"},
  {"season": 6, "episode": 22, "scene": 14, "line": "Dwight Schrute. [they shake hands]", "order": 35693, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 22, "scene": 14, "line": "Donna, hi.", "order": 35694, "speaker": "Donna Newton"},
  {"season": 6, "episode": 22, "scene": 15, "line": "All cases are solved with logic. The only logical way to find out if Donna Is a cheater is to seduce her, bring her to orgasm, then call Michael and tell him the sad news.", "order": 35695, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 22, "scene": 16, "line": "That's interesting. Wow, it's a little early for ice cream, don't you think?", "order": 35696, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 22, "scene": 16, "line": "It's never too early for ice cream, Jim. But we didn't have any ice cream, so this is mayonnaise and black olives.", "order": 35697, "speaker": "Michael Scott"},
  {"season": 6, "episode": 22, "scene": 16, "line": "Oh!...", "order": 35698, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 22, "scene": 16, "line": "Oh, my God", "order": 35699, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 22, "scene": 16, "line": "It's comfort food, all right? [disgustedly] God.", "order": 35700, "speaker": "Michael Scott"},
  {"season": 6, "episode": 22, "scene": 16, "line": "You know Michael, this whole Donna thing is gonna be okay, you just... stop beating yourself up.", "order": 35701, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 22, "scene": 16, "line": "I know. Well, I hope you're right. We'll see what Dwight says.", "order": 35702, "speaker": "Michael Scott"},
  {"season": 6, "episode": 22, "scene": 16, "line": "Why do we have to see what Dwight says?", "order": 35703, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 22, "scene": 16, "line": "Because I have him investigating her. I'm waiting for a text update.", "order": 35704, "speaker": "Michael Scott"},
  {"season": 6, "episode": 22, "scene": 16, "line": "Michael, no...", "order": 35705, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 22, "scene": 16, "line": "No, no, no, no. Undo that. Undo that.", "order": 35706, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 22, "scene": 16, "line": "It's too late to undo it. I need to know. Otherwise this thing is going to spiral out of amok.", "order": 35707, "speaker": "Michael Scott"},
  {"season": 6, "episode": 22, "scene": 16, "line": "Michael. Okay, I'm... I'm going to talk straight to you because I think you need to hear it. Michael.", "order": 35708, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 22, "scene": 16, "line": "God, this is so disgusting.", "order": 35709, "speaker": "Michael Scott"},
  {"season": 6, "episode": 22, "scene": 16, "line": "Stop eating it! Do you wanna be happy? Look at you. You have a major self-destructive streak in you.", "order": 35710, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 22, "scene": 16, "line": "I know.", "order": 35711, "speaker": "Michael Scott"},
  {"season": 6, "episode": 22, "scene": 16, "line": "And you kind of torpedo every romantic relationship you're in.", "order": 35712, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 22, "scene": 16, "line": "That's not true. [Pam stares him down] You're right, I ruin everything. And I've known some wonderful women. Holly, Carol, Jan.", "order": 35713, "speaker": "Michael Scott"},
  {"season": 6, "episode": 22, "scene": 16, "line": "Helene.", "order": 35714, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 22, "scene": 16, "line": "Helene?", "order": 35715, "speaker": "Michael Scott"},
  {"season": 6, "episode": 22, "scene": 16, "line": "My mother.", "order": 35716, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 22, "scene": 16, "line": "Oh.", "order": 35717, "speaker": "Michael Scott"},
  {"season": 6, "episode": 22, "scene": 16, "line": "My mother, Helene. [Jim shakes his head]", "order": 35718, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 22, "scene": 16, "line": "Oh, yeah. Yes, yes. Yes. All of, all of the greatest loves of my life.", "order": 35719, "speaker": "Michael Scott"},
  {"season": 6, "episode": 22, "scene": 16, "line": "You should stop this, [pulls away mayo and olives bowl] and you should call Dwight right now.", "order": 35720, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 22, "scene": 16, "line": "[sighs] All right. [calls Dwight]", "order": 35721, "speaker": "Michael Scott"},
  {"season": 6, "episode": 22, "scene": 17, "line": "[at gym, looks ready to work out, checks phone, and tries to grab Donna's attention] Oh, gosh, we were both going for the same weight at the same time; you go ahead.", "order": 35722, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 22, "scene": 17, "line": "Thank you.", "order": 35723, "speaker": "Donna Newton"},
  {"season": 6, "episode": 22, "scene": 17, "line": "It's all yours. [strains loudly to lift two dumbbells and a free weight chained to strap around his head] Ah! [after first rep, Dwight is injured]", "order": 35724, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 22, "scene": 17, "line": "You okay?", "order": 35725, "speaker": "Donna Newton"},
  {"season": 6, "episode": 22, "scene": 17, "line": "Yeah, I'm good. Hey, you know an exercise for two people that uses the whole body?", "order": 35726, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 22, "scene": 17, "line": "[chuckles] Yeah, I think I know what you're talking about.", "order": 35727, "speaker": "Donna Newton"},
  {"season": 6, "episode": 22, "scene": 17, "line": "Tractor pulling. Too bad there's not a tractor here.", "order": 35728, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 22, "scene": 18, "line": "[moaning loudly on exercise machine while staring at Donna who's next to him] Oh. Oh. Oh. [groans loudly] One thing you need to know about me. I don't quit until something tears or pops. [chuckles] You look like you're getting a good workout. Can I feel your pulse?", "order": 35729, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 22, "scene": 18, "line": "Nope. I'm good, thanks.", "order": 35730, "speaker": "Donna Newton"},
  {"season": 6, "episode": 22, "scene": 18, "line": "Really? Hey, um... [Dwight gets up and walks sorely from his 'workout']", "order": 35731, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 22, "scene": 18, "line": "Look, young man, can you wipe down that seat?", "order": 35732, "speaker": "Old lady"},
  {"season": 6, "episode": 22, "scene": 18, "line": "Get out of my way. Huh!", "order": 35733, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 22, "scene": 19, "line": "Tomorrow's fertilizer, am I right?", "order": 35734, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 22, "scene": 19, "line": "I'm out of here.", "order": 35735, "speaker": "Donna Newton"},
  {"season": 6, "episode": 22, "scene": 19, "line": "Donna. Donna, wait, please. I'm sorry. Okay? Listen. We both know why I'm here: to see... you... naked... while... I'm... naked.", "order": 35736, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 22, "scene": 19, "line": "You stay away from me, or I'm calling security.", "order": 35737, "speaker": "Donna Newton"},
  {"season": 6, "episode": 22, "scene": 19, "line": "Donna, come... Ah! Grr! Ugh! [Dwight is very sore from his 'workout' and cannot chase Donna]", "order": 35738, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 22, "scene": 20, "line": "You're back. What happened?", "order": 35739, "speaker": "Michael Scott"},
  {"season": 6, "episode": 22, "scene": 20, "line": "Oh, I pulled muscles in both my thighs. Thanks for asking.", "order": 35740, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 22, "scene": 20, "line": "No, what happened with Donna?", "order": 35741, "speaker": "Michael Scott"},
  {"season": 6, "episode": 22, "scene": 20, "line": "Yeah, no. She's not cheating. Oh, man! Will you help me work out this knot? Right here. [near his groin]", "order": 35742, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 22, "scene": 20, "line": "Ugh!", "order": 35743, "speaker": "Michael Scott"},
  {"season": 6, "episode": 22, "scene": 20, "line": "Put your fingers here.", "order": 35744, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 22, "scene": 20, "line": "No. No! Are you sure?", "order": 35745, "speaker": "Michael Scott"},
  {"season": 6, "episode": 22, "scene": 20, "line": "I'm positive. Yeah, oh, and here's your expense receipts right there.", "order": 35746, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 22, "scene": 20, "line": "Who eats eight protein bars?", "order": 35747, "speaker": "Michael Scott"},
  {"season": 6, "episode": 22, "scene": 20, "line": "People who don't trust egg whites.", "order": 35748, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 22, "scene": 20, "line": "Okay. Well, I am just glad this is all over.", "order": 35749, "speaker": "Michael Scott"},
  {"season": 6, "episode": 22, "scene": 20, "line": "Oh, me too. And by the way, uh, I told her not to, but she's coming over here and she's furious.", "order": 35750, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 22, "scene": 20, "line": "What? No, she didn't say that.", "order": 35751, "speaker": "Michael Scott"},
  {"season": 6, "episode": 22, "scene": 20, "line": "You're right. I was paraphrasing. What she actually said was, [pulls out notebook] 'What is with him? He is crazy. I'm coming over there to talk to him.' And this was after I have no other recourse but to tell her and gym security that you had me sent there to see if she was cheating. Also, I joined the gym. You'll be billed monthly. [lays down gym membership receipt on a chair in Michael's office.", "order": 35752, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 22, "scene": 20, "line": "I am not paying for that membership.", "order": 35753, "speaker": "Michael Scott"},
  {"season": 6, "episode": 22, "scene": 21, "line": "[sitting on the floor behind Erin's desk, sighs] Ohhh... mmm...", "order": 35754, "speaker": "Michael Scott"},
  {"season": 6, "episode": 22, "scene": 21, "line": "Maybe you'd be more comfortable in your own office.", "order": 35755, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 22, "scene": 21, "line": "No, I like the attention. Is she here yet?", "order": 35756, "speaker": "Michael Scott"},
  {"season": 6, "episode": 22, "scene": 21, "line": "Uh, no. [Michael sighs, Donna enters] Wait, yes.", "order": 35757, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 22, "scene": 21, "line": "That's her?", "order": 35758, "speaker": "Michael Scott"},
  {"season": 6, "episode": 22, "scene": 21, "line": "Yeah.", "order": 35759, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 22, "scene": 21, "line": "Mm-hmm. Right. Right. Right. [Michael pretends to be speaking on the phone as Donna approaches] Okay, I'll talk to you later.", "order": 35760, "speaker": "Michael Scott"},
  {"season": 6, "episode": 22, "scene": 22, "line": "How could you think I would cheat on you?", "order": 35761, "speaker": "Donna Newton"},
  {"season": 6, "episode": 22, "scene": 22, "line": "I didn't. Everybody else here did. Everybody convinced me that something was up. They poisoned my mind.", "order": 35762, "speaker": "Michael Scott"},
  {"season": 6, "episode": 22, "scene": 22, "line": "That's pathetic.", "order": 35763, "speaker": "Donna Newton"},
  {"season": 6, "episode": 22, "scene": 22, "line": "Ye... pfff. Well, no. It's a lie. That's not what happened. I just like you. I can't believe I get to be with you. You work at an adult arcade. You could have any man you want.", "order": 35764, "speaker": "Michael Scott"},
  {"season": 6, "episode": 22, "scene": 22, "line": "When I tell you I like you, you need to trust me, not some freak. [Dwight is gulping some sort of power shake]", "order": 35765, "speaker": "Donna Newton"},
  {"season": 6, "episode": 22, "scene": 22, "line": "If you wanna dump me, I totally get it.", "order": 35766, "speaker": "Michael Scott"},
  {"season": 6, "episode": 22, "scene": 22, "line": "I told you I like you.", "order": 35767, "speaker": "Donna Newton"},
  {"season": 6, "episode": 22, "scene": 22, "line": "Well, you are boner-ific. [Donna laughs]", "order": 35768, "speaker": "Michael Scott"},
  {"season": 6, "episode": 22, "scene": 22, "line": "Hey, if I said that we should go away for a couple of days, you would...", "order": 35769, "speaker": "Donna Newton"},
  {"season": 6, "episode": 22, "scene": 22, "line": "...poop my pants.", "order": 35770, "speaker": "Michael Scott"},
  {"season": 6, "episode": 22, "scene": 22, "line": "Have you ever been to Vero Beach?", "order": 35771, "speaker": "Donna Newton"},
  {"season": 6, "episode": 22, "scene": 22, "line": "Oh, my God, Vero Beach. No. Is that on the water?", "order": 35772, "speaker": "Michael Scott"},
  {"season": 6, "episode": 22, "scene": 22, "line": "We're going.", "order": 35773, "speaker": "Donna Newton"},
  {"season": 6, "episode": 22, "scene": 22, "line": "We are?", "order": 35774, "speaker": "Michael Scott"},
  {"season": 6, "episode": 22, "scene": 22, "line": "Yeah.", "order": 35775, "speaker": "Donna Newton"},
  {"season": 6, "episode": 22, "scene": 23, "line": "What's up? I got your e-mail.", "order": 35776, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 22, "scene": 23, "line": "Close the door.", "order": 35777, "speaker": "Darryl Philbin"},
  {"season": 6, "episode": 22, "scene": 23, "line": "Okay.", "order": 35778, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 22, "scene": 24, "line": "I don't have a plan exactly. More of a loose structure. Gives me freedom to improvise. It's like jazz. [scatting] Andy don't mess with me. [continues scatting] I'll figure something out.", "order": 35779, "speaker": "Darryl Philbin"},
  {"season": 6, "episode": 22, "scene": 25, "line": "Some freaky stuff going on. I was walking behind Gabe and I heard some things.", "order": 35780, "speaker": "Darryl Philbin"},
  {"season": 6, "episode": 22, "scene": 25, "line": "Such as?", "order": 35781, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 22, "scene": 25, "line": "It was kind of mumbled, I don't know, uh, only thing I could make out clearly was 'Andy,' 'Problem,' 'Eliminate,' something. I don't know what it meant.", "order": 35782, "speaker": "Darryl Philbin"},
  {"season": 6, "episode": 22, "scene": 25, "line": "What? You don't know what it meant? How about 'Andy is a problem and we must eliminate him?'", "order": 35783, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 22, "scene": 25, "line": "Whoa. I hadn't even thought of that.", "order": 35784, "speaker": "Darryl Philbin"},
  {"season": 6, "episode": 22, "scene": 25, "line": "[chuckles nervously] Yeah. Hah. Hoo.", "order": 35785, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 22, "scene": 26, "line": "Oh, hey, I love your earrings.", "order": 35786, "speaker": "Kelly Kapoor"},
  {"season": 6, "episode": 22, "scene": 26, "line": "Thank you.", "order": 35787, "speaker": "Donna Newton"},
  {"season": 6, "episode": 22, "scene": 26, "line": "Did Michael get them for you?", "order": 35788, "speaker": "Kelly Kapoor"},
  {"season": 6, "episode": 22, "scene": 26, "line": "No, I bought them myself.", "order": 35789, "speaker": "Donna Newton"},
  {"season": 6, "episode": 22, "scene": 26, "line": "Where?", "order": 35790, "speaker": "Kelly Kapoor"},
  {"season": 6, "episode": 22, "scene": 26, "line": "Steamtown Mall.", "order": 35791, "speaker": "Donna Newton"},
  {"season": 6, "episode": 22, "scene": 26, "line": "Claire's? Zales? Ricky's? Earring, Earrings? Fancy Girl? Platinum Cat? Where?", "order": 35792, "speaker": "Kelly Kapoor"},
  {"season": 6, "episode": 22, "scene": 26, "line": "You know, I actually got them in Philadelphia, in a mall down there.", "order": 35793, "speaker": "Donna Newton"},
  {"season": 6, "episode": 22, "scene": 26, "line": "Franklin Mills? King of Prussia? Springfield? Governor's Place?", "order": 35794, "speaker": "Kelly Kapoor"},
  {"season": 6, "episode": 22, "scene": 26, "line": "Uh, Franklin Mills.", "order": 35795, "speaker": "Donna Newton"},
  {"season": 6, "episode": 22, "scene": 26, "line": "[to Pam] What?", "order": 35796, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 22, "scene": 26, "line": "Hmm? Oh. It's probably nothing.", "order": 35797, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 22, "scene": 27, "line": "Okay, heart-shaped jewelry is not something that a woman buys for herself. A man definitely bought it for her recently, and it wasn't Michael.", "order": 35798, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 22, "scene": 27, "line": "Wait, so are you... you like heart-shaped jewelry, though, right?", "order": 35799, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 22, "scene": 27, "line": "No. Except for the pendant that you bought me. Which I love.", "order": 35800, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 22, "scene": 28, "line": "Psst... [Creed walks by Andy and draws his right index finger across his neck, further scaring Andy, Andy looks over at Darryl who is staring him down]", "order": 35801, "speaker": "Creed Bratton"},
  {"season": 6, "episode": 22, "scene": 29, "line": "Creed's head of quality assurance. So he'd definitely be wrapped up in this.", "order": 35802, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 22, "scene": 30, "line": "So there I am, minding my own business and Darnell offers me three bucks. All I gotta do is walk by Andy and go like this. [draws finger across neck] Darnell's a chump. I would have done it for anything. I've done a lot more for a lot less.", "order": 35803, "speaker": "Creed Bratton"},
  {"season": 6, "episode": 22, "scene": 31, "line": "So it turns out Donna and I have a facebook friend of a friend in common, so I was able to see some of her pictures online. [pulls out picture of Donna embracing a man and smiling] This was taken two weeks ago. And this was taken the same night. [pulls out a picture of Donna kissing the same man] This photo was taken this morning. [shows a baby picture] It's Cece. [laughs] She's never gonna do anything wrong.", "order": 35804, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 22, "scene": 32, "line": "Isn't that something?", "order": 35805, "speaker": "Donna Newton"},
  {"season": 6, "episode": 22, "scene": 32, "line": "Wow. [Pam knocks on Michaels door and goes in] That's exciting.", "order": 35806, "speaker": "Michael Scott"},
  {"season": 6, "episode": 22, "scene": 32, "line": "Hey, Michael.", "order": 35807, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 22, "scene": 32, "line": "Hey.", "order": 35808, "speaker": "Michael Scott"},
  {"season": 6, "episode": 22, "scene": 32, "line": "I'm sorry to interrupt you. Um, I actually have something very important to talk to you about, business related.", "order": 35809, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 22, "scene": 32, "line": "Well it can wait. It can wait.", "order": 35810, "speaker": "Michael Scott"},
  {"season": 6, "episode": 22, "scene": 32, "line": "I lied it's personal. It's about me and Jim. We're... I just... you're the only person I can talk to.", "order": 35811, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 22, "scene": 32, "line": "Jim is her husband.", "order": 35812, "speaker": "Michael Scott"},
  {"season": 6, "episode": 22, "scene": 32, "line": "Oh.", "order": 35813, "speaker": "Donna Newton"},
  {"season": 6, "episode": 22, "scene": 32, "line": "And...", "order": 35814, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 22, "scene": 32, "line": "And they are having problems, so sh...", "order": 35815, "speaker": "Michael Scott"},
  {"season": 6, "episode": 22, "scene": 32, "line": "No, not... we're not... we're not having problems. But it is personal. And I would love...", "order": 35816, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 22, "scene": 32, "line": "Good in bed.", "order": 35817, "speaker": "Michael Scott"},
  {"season": 6, "episode": 22, "scene": 32, "line": "Yes. Yes, I desperately want to speak with you about my sex life with Jim.", "order": 35818, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 22, "scene": 32, "line": "Oh, my God. Look at how cheap street level rooms are. Am I the only person who enjoys people watching?", "order": 35819, "speaker": "Michael Scott"},
  {"season": 6, "episode": 22, "scene": 32, "line": "[loudly] I need you to sign this! So bad!", "order": 35820, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 22, "scene": 32, "line": "Okay, weirdo.", "order": 35821, "speaker": "Michael Scott"},
  {"season": 6, "episode": 22, "scene": 32, "line": "I love [leans over Michael's desk to look at his computer screen]... this idea is neat. I've never been. It sounds lovely. [meanwhile Michael looks at the pictures Pam printed out]", "order": 35822, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 22, "scene": 32, "line": "Yeah I think it'll be a nice trip. We're gonna get a lot done.", "order": 35823, "speaker": "Donna Newton"},
  {"season": 6, "episode": 22, "scene": 32, "line": "[to Michael] You're gonna wanna look at the date on that. Oh, wow. Look at... golfing. [to Donna] Are you a golfer?", "order": 35824, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 22, "scene": 32, "line": "I am, but I, I gave my clubs away. I swear too much. [Pam and Donna both laugh] Hey, you okay? [to Michael, walking away disappointedly]", "order": 35825, "speaker": "Donna Newton"},
  {"season": 6, "episode": 22, "scene": 32, "line": "Yeah, I just remembered that I have to go to the bathroom. Pam?", "order": 35826, "speaker": "Michael Scott"},
  {"season": 6, "episode": 22, "scene": 32, "line": "Absolutely. [both leave Michael's office]", "order": 35827, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 22, "scene": 33, "line": "Look, I'm not down there anymore, so if the guys start making fun of you, you just, you gotta stand up for yourself.", "order": 35828, "speaker": "Darryl Philbin"},
  {"season": 6, "episode": 22, "scene": 33, "line": "I know, it's just, I'm scared...", "order": 35829, "speaker": "Glen"},
  {"season": 6, "episode": 22, "scene": 33, "line": "Your text said 911.", "order": 35830, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 22, "scene": 33, "line": "Glen, could you excuse us? [Andy slams the door after Glen leaves, panting heavily]", "order": 35831, "speaker": "Darryl Philbin"},
  {"season": 6, "episode": 22, "scene": 33, "line": "It's bad.", "order": 35832, "speaker": "Darryl Philbin"},
  {"season": 6, "episode": 22, "scene": 33, "line": "What's bad?", "order": 35833, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 22, "scene": 33, "line": "It's real bad.", "order": 35834, "speaker": "Darryl Philbin"},
  {"season": 6, "episode": 22, "scene": 34, "line": "Still no plan.", "order": 35835, "speaker": "Darryl Philbin"},
  {"season": 6, "episode": 22, "scene": 35, "line": "Oh, God.", "order": 35836, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 22, "scene": 35, "line": "It's getting bigger.", "order": 35837, "speaker": "Darryl Philbin"},
  {"season": 6, "episode": 22, "scene": 35, "line": "I might have to go public here, but no one's gonna believe me. Uh... I need proof. I need, like, a printer to catch on fire.", "order": 35838, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 22, "scene": 35, "line": "I can videotape it.", "order": 35839, "speaker": "Darryl Philbin"},
  {"season": 6, "episode": 22, "scene": 35, "line": "Yeah.", "order": 35840, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 22, "scene": 35, "line": "[to camera] There it is.", "order": 35841, "speaker": "Darryl Philbin"},
  {"season": 6, "episode": 22, "scene": 35, "line": "There what is?", "order": 35842, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 22, "scene": 35, "line": "What?", "order": 35843, "speaker": "Darryl Philbin"},
  {"season": 6, "episode": 22, "scene": 36, "line": "Who the hell is this? Who is this guy?", "order": 35844, "speaker": "Michael Scott"},
  {"season": 6, "episode": 22, "scene": 36, "line": "I don't know who he is.", "order": 35845, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 22, "scene": 36, "line": "God! Lowest of the low. That guy. Just a notch above Toby. You know what?", "order": 35846, "speaker": "Michael Scott"},
  {"season": 6, "episode": 22, "scene": 36, "line": "What?", "order": 35847, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 22, "scene": 36, "line": "I'm gonna kill him. No, I'm not. I feel... I... no, I'm not going to kill him.", "order": 35848, "speaker": "Michael Scott"},
  {"season": 6, "episode": 22, "scene": 36, "line": "You just have to go in there. You have to hear it from her.", "order": 35849, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 22, "scene": 36, "line": "I have to hear it from her.", "order": 35850, "speaker": "Michael Scott"},
  {"season": 6, "episode": 22, "scene": 36, "line": "You have to settle down first.", "order": 35851, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 22, "scene": 36, "line": "I need to have her tell me herself. Right?", "order": 35852, "speaker": "Michael Scott"},
  {"season": 6, "episode": 22, "scene": 36, "line": "Okay. Okay. Yes.", "order": 35853, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 22, "scene": 36, "line": "I need to have her say...", "order": 35854, "speaker": "Michael Scott"},
  {"season": 6, "episode": 22, "scene": 36, "line": "Yeah, but you have to calm...", "order": 35855, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 22, "scene": 36, "line": "I need to say, 'What the hell is that? What the hell is that?'", "order": 35856, "speaker": "Michael Scott"},
  {"season": 6, "episode": 22, "scene": 36, "line": "Okay. Look at this, look at this. Baby picture.", "order": 35857, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 22, "scene": 36, "line": "No, God! No, no, oh, my God!", "order": 35858, "speaker": "Michael Scott"},
  {"season": 6, "episode": 22, "scene": 36, "line": "[in baby voice] Hi, Michael, hi, Michael", "order": 35859, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 22, "scene": 36, "line": "[calms down] Okay. Okay. Okay.", "order": 35860, "speaker": "Michael Scott"},
  {"season": 6, "episode": 22, "scene": 37, "line": "[to Andy] I talked to corporate. Turns out there have been 12 reports of faulty printers. Out of 400,000. [smiles] We've investigated. Every time it's been user error. They block the vents or something, I don't know. That's why we have the fine print. Thank you for bringing this to our attention. So I'd like to reward you for that. [pulls out gift card] That's god for five bucks at Dunkin' Donuts. Any Dunkin' Donuts. [Andy looks over at Darryl and Darryl motions for Andy to go to him]", "order": 35861, "speaker": "Gabe Lewis"},
  {"season": 6, "episode": 22, "scene": 38, "line": "You know what? We should really do something fun this week.", "order": 35862, "speaker": "Michael Scott"},
  {"season": 6, "episode": 22, "scene": 38, "line": "Yeah, we should.", "order": 35863, "speaker": "Donna Newton"},
  {"season": 6, "episode": 22, "scene": 38, "line": "Wouldn't that be fun?", "order": 35864, "speaker": "Michael Scott"},
  {"season": 6, "episode": 22, "scene": 38, "line": "Yeah.", "order": 35865, "speaker": "Donna Newton"},
  {"season": 6, "episode": 22, "scene": 38, "line": "How about Thursday?", "order": 35866, "speaker": "Michael Scott"},
  {"season": 6, "episode": 22, "scene": 38, "line": "Thursday works. Yeah, what do you wanna do?", "order": 35867, "speaker": "Donna Newton"},
  {"season": 6, "episode": 22, "scene": 38, "line": "I can't do Thursday. Book club. How about Friday?", "order": 35868, "speaker": "Michael Scott"},
  {"season": 6, "episode": 22, "scene": 38, "line": "Oh, Friday doesn't work.", "order": 35869, "speaker": "Donna Newton"},
  {"season": 6, "episode": 22, "scene": 38, "line": "Oh, really? 'cause I was thinking we could go to this concert. Spice Girls are opening for Weird Al. Front row. It'd be a great, great concert.", "order": 35870, "speaker": "Michael Scott"},
  {"season": 6, "episode": 22, "scene": 38, "line": "Shoot. I'm working.", "order": 35871, "speaker": "Donna Newton"},
  {"season": 6, "episode": 22, "scene": 38, "line": "Oh okay. Well, maybe I could stop by.", "order": 35872, "speaker": "Michael Scott"},
  {"season": 6, "episode": 22, "scene": 38, "line": "Well, won't you be at the concert?", "order": 35873, "speaker": "Donna Newton"},
  {"season": 6, "episode": 22, "scene": 38, "line": "Nope, that's Tuesday.", "order": 35874, "speaker": "Michael Scott"},
  {"season": 6, "episode": 22, "scene": 38, "line": "Oh, well, I can make it on Tuesday.", "order": 35875, "speaker": "Donna Newton"},
  {"season": 6, "episode": 22, "scene": 38, "line": "You're cheating. You're cheating on me.", "order": 35876, "speaker": "Michael Scott"},
  {"season": 6, "episode": 22, "scene": 38, "line": "How do you know?", "order": 35877, "speaker": "Donna Newton"},
  {"season": 6, "episode": 22, "scene": 38, "line": "Pam told me. [looks up at 2nd floor windows and so does Donna; Jim, Dwight, and Pam are visible on conference room window, they all scramble as soon as Donna and Michael look up, Pam throwing herself on the floor]", "order": 35878, "speaker": "Michael Scott"},
  {"season": 6, "episode": 22, "scene": 38, "line": "[gasps] Did she see me?", "order": 35879, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 22, "scene": 38, "line": "[to Pam on floor] Nice effort.", "order": 35880, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 22, "scene": 39, "line": "[in old Michael Scott Paper Company's 'office'] We're printing on 24-pound premium stock paper, approximately 300 sheets in. So far, no signs of distress. [Darryl is filming Andy's demonstration]", "order": 35881, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 22, "scene": 39, "line": "You haven't even introduced yourself.", "order": 35882, "speaker": "Darryl Philbin"},
  {"season": 6, "episode": 22, "scene": 39, "line": "Right. My name is Andrew Baines Bernard, and if you're watching this, it's because I've turned State's witness because I'm in danger because I know too much.", "order": 35883, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 22, "scene": 39, "line": "You should talk in a higher voice 'cause the camera makes you sound weird.", "order": 35884, "speaker": "Darryl Philbin"},
  {"season": 6, "episode": 22, "scene": 39, "line": "Higher? Okay. [speaking slightly higher] Recently certain events have come to my attention...", "order": 35885, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 22, "scene": 39, "line": "Higher. [motions with hand to go up more]", "order": 35886, "speaker": "Darryl Philbin"},
  {"season": 6, "episode": 22, "scene": 39, "line": "Make it higher? Okay.", "order": 35887, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 22, "scene": 39, "line": "Mm-hmm", "order": 35888, "speaker": "Darryl Philbin"},
  {"season": 6, "episode": 22, "scene": 39, "line": "[high-pitched] I have come to the conclusion that the Sabre corporation...", "order": 35889, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 22, "scene": 39, "line": "One more, yeah. [Again motions to go even higher]", "order": 35890, "speaker": "Darryl Philbin"},
  {"season": 6, "episode": 22, "scene": 39, "line": "[higher] May be overlooking certain safety regulations. At the danger... [printer starts smoking and explodes] ah! [speaking lower] It's working. [in normal voice] It's... I knew it!", "order": 35891, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 22, "scene": 39, "line": "This... [removes camera headset]", "order": 35892, "speaker": "Darryl Philbin"},
  {"season": 6, "episode": 22, "scene": 39, "line": "We are blowin' the roof off! Blowin' the roof off! [Darryl discharges fire extinguisher onto printer] Nice. Nice. This is my partner, Darryl Philbin. He's been my partner through this entire thing.", "order": 35893, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 22, "scene": 40, "line": "I don't wanna prank anymore. Things get real. It's not funny. I'm just gonna be good, stay in my room, go to church, try to do one nice thing per day. I do not wanna prank anymore.", "order": 35894, "speaker": "Darryl Philbin"},
  {"season": 6, "episode": 22, "scene": 41, "line": "Who is he?", "order": 35895, "speaker": "Michael Scott"},
  {"season": 6, "episode": 22, "scene": 41, "line": "What, what do you mean?", "order": 35896, "speaker": "Donna Newton"},
  {"season": 6, "episode": 22, "scene": 41, "line": "The other man. Who's the guy? Who is it?", "order": 35897, "speaker": "Michael Scott"},
  {"season": 6, "episode": 22, "scene": 41, "line": "It's you. I'm married.", "order": 35898, "speaker": "Donna Newton"},
  {"season": 6, "episode": 22, "scene": 41, "line": "I'm the mistress?", "order": 35899, "speaker": "Michael Scott"},
  {"season": 6, "episode": 22, "scene": 42, "line": "[on workout bicycle at gym] Okay, everybody, let's take this next hill.", "order": 35900, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 22, "scene": 42, "line": "Excuse me. Yeah, I'd appreciate it if you'd just let me run this.", "order": 35901, "speaker": "Gym Instructor"},
  {"season": 6, "episode": 22, "scene": 42, "line": "You know what? You had your chance. You're no leader. Out of your seat, let's blast!", "order": 35902, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 22, "scene": 42, "line": "Don't listen to him, we're approaching a cooldown down a gentle hill.", "order": 35903, "speaker": "Gym instructor"},
  {"season": 6, "episode": 22, "scene": 42, "line": "No! The hill's a trap. Let's take the dirt road off to the side.", "order": 35904, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 22, "scene": 42, "line": "No, guys, no. We're just cooling down...", "order": 35905, "speaker": "Gym instructor"},
  {"season": 6, "episode": 22, "scene": 42, "line": "If they catch us, they will rape us. Go for the cliff. And three, two, one... jump! No! [points to those around him] You're dead, you're dead, you're dead. Good jump. You're barely alive. Okay, now nice cooldown. Check your pulse rate.", "order": 35906, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 23, "scene": 1, "line": "This here is a radon test kit. [holding out tiny cylinder disc] Okay I will be putting 'em everywhere. And... please don't throw these out. [starts snapping towards Michael's face] This is a radon test kit. [continues snapping] Please don't throw these out. [Michael gives annoyed look] See them all over the office.", "order": 35907, "speaker": "Toby Flenderson"},
  {"season": 6, "episode": 23, "scene": 2, "line": "Toby has been leaving radon kits everywhere, like he owns the place. The first time I threw mine away, I thought it was an ant trap. [finds radon kit in between his blinds in his office] But I figured I'd rather live with ants than with his creepy little disc. [throws it away] The second time... I thought it was one of those, you know, those things you turn over and it moos. [finds another radon kit on top of his cabinet] Like a cow thing. But upon closer examination, it was another ant trap, so I threw it away. And the third time... I did it out of spite. [takes radon kit from the top of the blinds of his office window and slams it into the trash can]", "order": 35908, "speaker": "Michael Scott"},
  {"season": 6, "episode": 23, "scene": 3, "line": "Pfffffttt.", "order": 35909, "speaker": "Michael Scott"},
  {"season": 6, "episode": 23, "scene": 3, "line": "Come on, Michael. You're interrupting.", "order": 35910, "speaker": "Toby Flenderson"},
  {"season": 6, "episode": 23, "scene": 3, "line": "You're kidding me? God! You say radon is silent, but deadly, and then you expect me not to make farting noises with my mouth? What is this?", "order": 35911, "speaker": "Michael Scott"},
  {"season": 6, "episode": 23, "scene": 3, "line": "Please sit down.", "order": 35912, "speaker": "Toby Flenderson"},
  {"season": 6, "episode": 23, "scene": 3, "line": "You know what? We're not gonna die of radon, we're gonna die of boredom.", "order": 35913, "speaker": "Michael Scott"},
  {"season": 6, "episode": 23, "scene": 3, "line": "YEAH! [laughter]", "order": 35914, "speaker": "Everyone"},
  {"season": 6, "episode": 23, "scene": 3, "line": "Right? And if I had a gun with two bullets and I was in a room with Hitler, Bin Laden, and Toby, I would shoot Toby twice.", "order": 35915, "speaker": "Michael Scott"},
  {"season": 6, "episode": 23, "scene": 3, "line": "No. No!", "order": 35916, "speaker": "Everyone"},
  {"season": 6, "episode": 23, "scene": 3, "line": "That's... not okay.", "order": 35917, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 23, "scene": 3, "line": "Okay, alright.", "order": 35918, "speaker": "Michael Scott"},
  {"season": 6, "episode": 23, "scene": 3, "line": "You were being really funny, and then you went too far.", "order": 35919, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 23, "scene": 3, "line": "I would kill Bin Laden and then Toby.", "order": 35920, "speaker": "Michael Scott"},
  {"season": 6, "episode": 23, "scene": 3, "line": "No, hmmm... that's still...", "order": 35921, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 23, "scene": 3, "line": "Mm-mm.", "order": 35922, "speaker": "Everyone"},
  {"season": 6, "episode": 23, "scene": 3, "line": "Okay geniuses, how would you do it?", "order": 35923, "speaker": "Michael Scott"},
  {"season": 6, "episode": 23, "scene": 3, "line": "Curve the bullet. Like in my favorite James McAvoy film, Wanted.", "order": 35924, "speaker": "Creed Bratton"},
  {"season": 6, "episode": 23, "scene": 3, "line": "All that does is help you shoot around things. What does Bin Laden...", "order": 35925, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 23, "scene": 3, "line": "Is there a curtain rod in the room?", "order": 35926, "speaker": "Ryan Howard"},
  {"season": 6, "episode": 23, "scene": 3, "line": "I don't know.", "order": 35927, "speaker": "Michael Scott"},
  {"season": 6, "episode": 23, "scene": 3, "line": "How about make-believe land has anything you want?", "order": 35928, "speaker": "Stanley Hudson"},
  {"season": 6, "episode": 23, "scene": 3, "line": "Stanley, please, this is serious!", "order": 35929, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 23, "scene": 3, "line": "Is this the thing where they use an icicle so there's no evidence?", "order": 35930, "speaker": "Angela Martin"},
  {"season": 6, "episode": 23, "scene": 3, "line": "Yes, we stab Toby through the heart with an icicle.", "order": 35931, "speaker": "Michael Scott"},
  {"season": 6, "episode": 23, "scene": 3, "line": "Come on, the whole two bullet thing is a red herring. Here's how you do it: You line them all up, you take one bullet, shoot them all through the throat at the same time. Watch this. [stands up and points to Phyllis] Phyllis, you're Hitler. Come up here. Toby, you're Toby. Andy, you're Bin Laden. Line up, throats together.", "order": 35932, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 23, "scene": 3, "line": "I don't wanna do this.", "order": 35933, "speaker": "Toby Flenderson"},
  {"season": 6, "episode": 23, "scene": 3, "line": "[shouting] Toby, just do it! God!", "order": 35934, "speaker": "Michael Scott"},
  {"season": 6, "episode": 23, "scene": 3, "line": "Toby, come on. [Andy lines up to the right of Toby and Phyllis is on his left]", "order": 35935, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 23, "scene": 3, "line": "Ready, one bullet. And boom! [makes shooting noises and mimics a bullet with his fingers tapping Phyllis' throat, then Toby's, and finally Andy's]", "order": 35936, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 23, "scene": 3, "line": "Yeah, that works. That works!", "order": 35937, "speaker": "Everyone"},
  {"season": 6, "episode": 23, "scene": 3, "line": "Good work. [clapping]", "order": 35938, "speaker": "Michael Scott"},
  {"season": 6, "episode": 23, "scene": 4, "line": "What flavors did you get? [yawns]", "order": 35939, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 23, "scene": 4, "line": "It's so exciting Pam. The Eagles are doing a theme of ice creams in honor of turning 60.", "order": 35940, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 23, "scene": 4, "line": "[reading flavors] Despera-dough. Witchy-womanilla. Why do they do this?", "order": 35941, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 23, "scene": 4, "line": "Do you want me to go back to the store?", "order": 35942, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 23, "scene": 4, "line": "No, no, no, it's fine. I was just commenting. We gotta get these out, open 'em up.", "order": 35943, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 23, "scene": 5, "line": "This is all for Michael. It turns out his girlfriend was married.", "order": 35944, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 23, "scene": 5, "line": "Yeah, and when Michael gets a broken heart, this whole place comes to a halt, so we're just trying to get out in front of this.", "order": 35945, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 23, "scene": 5, "line": "After his last breakup, he ate 40,000 calories in three hours. Right, Pam? That's what Pam told me.", "order": 35946, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 23, "scene": 5, "line": "[yawns] Yeah, or no... well... I don't know. I'm sorry. I was up all night with Cece, otherwise I'd be running this.", "order": 35947, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 23, "scene": 5, "line": "That's okay. You probably shouldn't keep a baby up that late, though.", "order": 35948, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 23, "scene": 6, "line": "We have Mr. Bean playing in the conference room and the Pink Panther movie in his office. With half hour shifts to watch it with him.", "order": 35949, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 23, "scene": 6, "line": "And we need bodies in both these rooms, people. So please sign up.", "order": 35950, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 23, "scene": 6, "line": "He's coming.", "order": 35951, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 23, "scene": 6, "line": "Oh, okay. Everyone, remember, when he get's like this, he will wallow, if you empathize. Keep conversations light and if you get stuck, and you don't know what to do, make a random sound effect okay? Farting noise, whatever. Okay.", "order": 35952, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 23, "scene": 6, "line": "[walks in] Hey everyone.", "order": 35953, "speaker": "Toby Flenderson"},
  {"season": 6, "episode": 23, "scene": 6, "line": "No, out! Get out now! Leave now.", "order": 35954, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 23, "scene": 6, "line": "Leave, get outta here.", "order": 35955, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 23, "scene": 6, "line": "Leave!", "order": 35956, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 23, "scene": 6, "line": "Right now!", "order": 35957, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 23, "scene": 6, "line": "Oh, and if he makes a joke, just laugh it up, no matter what. Okay?", "order": 35958, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 23, "scene": 6, "line": "[walks in] Morning everyone.", "order": 35959, "speaker": "Michael Scott"},
  {"season": 6, "episode": 23, "scene": 6, "line": "Hey! [applauses] Hey, hey!", "order": 35960, "speaker": "Everyone"},
  {"season": 6, "episode": 23, "scene": 6, "line": "Hi Michael. [gives him a hug]", "order": 35961, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 23, "scene": 6, "line": "Hello!", "order": 35962, "speaker": "Michael Scott"},
  {"season": 6, "episode": 23, "scene": 6, "line": "Michael, nice tie or something.", "order": 35963, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 23, "scene": 6, "line": "Oh, thank you. It's reversible, I think. [everyone laughs]", "order": 35964, "speaker": "Michael Scott"},
  {"season": 6, "episode": 23, "scene": 6, "line": "That's hilarious.", "order": 35965, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 23, "scene": 6, "line": "That's awesome. You want some ice cream?", "order": 35966, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 23, "scene": 6, "line": "Oh! Oh wow, are those Eagles' flavors? Cake it to the Limit. That's my favorite! I love that! Too much for me to have by myself. Anybody wanna share?", "order": 35967, "speaker": "Michael Scott"},
  {"season": 6, "episode": 23, "scene": 6, "line": "Boi-oi-oi-ing", "order": 35968, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 23, "scene": 6, "line": "Okay. Well... that's random. I will dig into those later.", "order": 35969, "speaker": "Michael Scott"},
  {"season": 6, "episode": 23, "scene": 7, "line": "I don't follow this exactly. Uh, 'The Descendants of any replicants from this union shall have...'", "order": 35970, "speaker": "Lawyer"},
  {"season": 6, "episode": 23, "scene": 7, "line": "Joint custody", "order": 35971, "speaker": "Dwight Schrute & Angela Martin"},
  {"season": 6, "episode": 23, "scene": 7, "line": "Are we talking about your grandchildren?", "order": 35972, "speaker": "Lawyer"},
  {"season": 6, "episode": 23, "scene": 7, "line": "No.", "order": 35973, "speaker": "Angela Martin"},
  {"season": 6, "episode": 23, "scene": 7, "line": "No.", "order": 35974, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 23, "scene": 8, "line": "Rather than spend all day in court, we've decided to settle our dispute using a mediator. It was an option spelled out in our childrearing contract.", "order": 35975, "speaker": "Angela Martin"},
  {"season": 6, "episode": 23, "scene": 8, "line": "Alleged contract.", "order": 35976, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 23, "scene": 8, "line": "Alleged? I'm going to own your farm by the time this is over.", "order": 35977, "speaker": "Angela Martin"},
  {"season": 6, "episode": 23, "scene": 8, "line": "Just try. I will do legal jujitsu on you. Gyah! [pretends to karate chop her neck] See, don't even need to make contact. The law will do it.", "order": 35978, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 23, "scene": 9, "line": "So much of this is just impossible to verify. Item five, point 'B', uh... the beet juice cleanse?", "order": 35979, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 23, "scene": 9, "line": "I'm doing it. You know I am. It's disgusting.", "order": 35980, "speaker": "Angela Martin"},
  {"season": 6, "episode": 23, "scene": 9, "line": "How do we know this? I'd like to see a stool sample.", "order": 35981, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 23, "scene": 9, "line": "Dwight, look at my teeth. [shows teeth, beet red]", "order": 35982, "speaker": "Angela Martin"},
  {"season": 6, "episode": 23, "scene": 9, "line": "Eughh. [looks disgusted]", "order": 35983, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 23, "scene": 10, "line": "No secret crying. Hasn't even opened the tissue box in there.", "order": 35984, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 23, "scene": 10, "line": "Could he still be seeing her?", "order": 35985, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 23, "scene": 10, "line": "I don't think he'd do that.", "order": 35986, "speaker": "Phyllis Vance"},
  {"season": 6, "episode": 23, "scene": 10, "line": "So we're gonna say the most likely scenerio is that Michael matured overnight?", "order": 35987, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 23, "scene": 10, "line": "Well, it happened to Tom Hanks in Big.", "order": 35988, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 23, "scene": 10, "line": "Exactly. It happened in Big.", "order": 35989, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 23, "scene": 11, "line": "No, I gotta go. Text me later.", "order": 35990, "speaker": "Michael Scott"},
  {"season": 6, "episode": 23, "scene": 11, "line": "I'm really too tired to do this.", "order": 35991, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 23, "scene": 11, "line": "Me too!", "order": 35992, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 23, "scene": 11, "line": "Then let's just not do this. Do you have any idea the risks involved?", "order": 35993, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 23, "scene": 11, "line": "Hey Michael.", "order": 35994, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 23, "scene": 11, "line": "Hey.", "order": 35995, "speaker": "Michael Scott"},
  {"season": 6, "episode": 23, "scene": 11, "line": "We were wondering if you would like to have dinner with us tonight, in our home, and play with our baby.", "order": 35996, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 23, "scene": 11, "line": "We can order in from Hooters.", "order": 35997, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 23, "scene": 11, "line": "Oh, Hooters to go. Nice.", "order": 35998, "speaker": "Michael Scott"},
  {"season": 6, "episode": 23, "scene": 11, "line": "We can watch a movie and play Rock Band.", "order": 35999, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 23, "scene": 11, "line": "Billy Joel Rock Band.", "order": 36000, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 23, "scene": 11, "line": "That... exists?", "order": 36001, "speaker": "Michael Scott"},
  {"season": 6, "episode": 23, "scene": 11, "line": "Yes.", "order": 36002, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 23, "scene": 11, "line": "Okay, well, I'll have to take a rain check, but thanks for the offer.", "order": 36003, "speaker": "Michael Scott"},
  {"season": 6, "episode": 23, "scene": 11, "line": "[shouts] Michael Scott, are you still seeing Donna?", "order": 36004, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 23, "scene": 11, "line": "Okay, Pam... she's not invisible so stop asking silly questions.", "order": 36005, "speaker": "Michael Scott"},
  {"season": 6, "episode": 23, "scene": 11, "line": "Oh Michael.", "order": 36006, "speaker": "Phyllis Vance"},
  {"season": 6, "episode": 23, "scene": 11, "line": "Since when is this an office where we delve into each other's personal lives?", "order": 36007, "speaker": "Michael Scott"},
  {"season": 6, "episode": 23, "scene": 12, "line": "I like Donna. Is it wrong to keep seeing her? Depends on who you ask. I mean, if you ask her husband... or you took a random poll, yeah, it's wrong.", "order": 36008, "speaker": "Michael Scott"},
  {"season": 6, "episode": 23, "scene": 13, "line": "That is something I would never do.", "order": 36009, "speaker": "Meredith Palmer"},
  {"season": 6, "episode": 23, "scene": 13, "line": "Well, I think we all know what you're capable of Meredith.", "order": 36010, "speaker": "Michael Scott"},
  {"season": 6, "episode": 23, "scene": 13, "line": "Hey, I have never cheated on, been cheated on, or been used to cheat with.", "order": 36011, "speaker": "Meredith Palmer"},
  {"season": 6, "episode": 23, "scene": 14, "line": "I ask... everyone in the room, 'Are you in a relationship?'.", "order": 36012, "speaker": "Meredith Palmer"},
  {"season": 6, "episode": 23, "scene": 15, "line": "People, this is Scranton. And many people consider that to be the Paris of northeastern Pennsylvania. And in Paris, it is rude for a woman to have less than four lovers.", "order": 36013, "speaker": "Michael Scott"},
  {"season": 6, "episode": 23, "scene": 15, "line": "Eughh, Ryan, I do not want you hanging around Michael anymore.", "order": 36014, "speaker": "Kelly Kapoor"},
  {"season": 6, "episode": 23, "scene": 15, "line": "Okay.", "order": 36015, "speaker": "Ryan Howard"},
  {"season": 6, "episode": 23, "scene": 15, "line": "I will have you know that I asked Donna about this and she is fine with it. And just to be sure, I asked her again afterward. Same answer.", "order": 36016, "speaker": "Michael Scott"},
  {"season": 6, "episode": 23, "scene": 15, "line": "How does he feel about it?", "order": 36017, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 23, "scene": 15, "line": "He is never gonna find out. He is a high school baseball coach. He wakes up early. He goes to bed early. Look at how long it took for him to find out. [points to Andy] It was right under his nose. I had to tell him.", "order": 36018, "speaker": "Michael Scott"},
  {"season": 6, "episode": 23, "scene": 15, "line": "Now you're just being hurtful.", "order": 36019, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 23, "scene": 15, "line": "I'm sorry, it... why is it okay for Stanley to cheat or for Phyllis to cheat...", "order": 36020, "speaker": "Michael Scott"},
  {"season": 6, "episode": 23, "scene": 15, "line": "Hey!", "order": 36021, "speaker": "Phyllis Vance"},
  {"season": 6, "episode": 23, "scene": 15, "line": "On her diet! He's a sports guy. He's scummy. Dogfighting. Drugs. They spit.", "order": 36022, "speaker": "Michael Scott"},
  {"season": 6, "episode": 23, "scene": 15, "line": "Did Donna tell you that?", "order": 36023, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 23, "scene": 15, "line": "Real sports with Bryant Gumble.", "order": 36024, "speaker": "Michael Scott"},
  {"season": 6, "episode": 23, "scene": 15, "line": "So you're just making a giant assumption. You don't even know the guy.", "order": 36025, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 23, "scene": 15, "line": "You know what? You know what? I am declaring a moment of silence right now. Ten minutes of silence honoring Michael Jackson. just sit there and think about Michael jackson.", "order": 36026, "speaker": "Michael Scott"},
  {"season": 6, "episode": 23, "scene": 16, "line": "My name is Andy Bernard and I am a cuckold. For those of you unfamiliar with William Shakespeare, a cuckold is a man whose woman is cheating on him. I've lived the part. And let me tell you, I'd so much rather play the part on stage.", "order": 36027, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 23, "scene": 17, "line": "So... here's the thing about infidelity.", "order": 36028, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 23, "scene": 17, "line": "Mm, no, when are you people going to stop casting the first stone? I am not in the wrong here. I am the good guy.", "order": 36029, "speaker": "Michael Scott"},
  {"season": 6, "episode": 23, "scene": 17, "line": "How does that work?", "order": 36030, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 23, "scene": 17, "line": "Do you think this husband is a super sweet nice guy? Do you think he's an angel? I don't think so. Why is his wife going off and having a little something-something with me? There has to be a problem with him.", "order": 36031, "speaker": "Michael Scott"},
  {"season": 6, "episode": 23, "scene": 17, "line": "In any cheating movie, the person getting cheated on is the hero. You're Ali Larter, I'm Beyonce.", "order": 36032, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 23, "scene": 17, "line": "I am Beyonce always.", "order": 36033, "speaker": "Michael Scott"},
  {"season": 6, "episode": 23, "scene": 17, "line": "Not this time.", "order": 36034, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 23, "scene": 17, "line": "Yes, I am.", "order": 36035, "speaker": "Michael Scott"},
  {"season": 6, "episode": 23, "scene": 17, "line": "This guy's a high school baseball coach, right? Let's go check him out, see what a horrible person he is.", "order": 36036, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 23, "scene": 17, "line": "I would love that.", "order": 36037, "speaker": "Michael Scott"},
  {"season": 6, "episode": 23, "scene": 17, "line": "Yeah. Those teams have games and practices every day. Let's go check it out.", "order": 36038, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 23, "scene": 17, "line": "I have work to do.", "order": 36039, "speaker": "Michael Scott"},
  {"season": 6, "episode": 23, "scene": 17, "line": "Oh, really? I thought you were the boss.", "order": 36040, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 23, "scene": 17, "line": "Let's go. [starts walking towards the elevator] I can't wait to see this jerk who is making me cheat on his wife. I should punch him in the nose for what he's making me do to her.", "order": 36041, "speaker": "Michael Scott"},
  {"season": 6, "episode": 23, "scene": 18, "line": "[humming Call to Post] Bum bum BUM BUM BUM BUM BUM BUM BUM BUM bum bum", "order": 36042, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 23, "scene": 18, "line": "All right, Andy, please.", "order": 36043, "speaker": "Michael Scott"},
  {"season": 6, "episode": 23, "scene": 18, "line": "Baseball!", "order": 36044, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 23, "scene": 18, "line": "Shh! Try not to draw attention, please. That's him. That's him.", "order": 36045, "speaker": "Michael Scott"},
  {"season": 6, "episode": 23, "scene": 18, "line": "Where?", "order": 36046, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 23, "scene": 18, "line": "Right there. The coach!", "order": 36047, "speaker": "Michael Scott"},
  {"season": 6, "episode": 23, "scene": 18, "line": "Stage right or stage left? I played batboy in damn yankees!", "order": 36048, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 23, "scene": 18, "line": "Right there. Look, look. Follow my finger. Okay? see? The grownup by the base?", "order": 36049, "speaker": "Michael Scott"},
  {"season": 6, "episode": 23, "scene": 18, "line": "[announcer's voice] And Now, coaching third base with two arms, two legs, and no heart. Capable of feeling pain.", "order": 36050, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 23, "scene": 18, "line": "All right. Can you just-- can you just act normal for a second, please?", "order": 36051, "speaker": "Michael Scott"},
  {"season": 6, "episode": 23, "scene": 18, "line": "Whatever. I'm the one blending in.", "order": 36052, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 23, "scene": 19, "line": "[ahem][Pam snores] Guys?", "order": 36053, "speaker": "Gabe Lewis"},
  {"season": 6, "episode": 23, "scene": 19, "line": "Pam.", "order": 36054, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 23, "scene": 19, "line": "Mm. Hey. Oh, hi, Gabe.", "order": 36055, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 23, "scene": 19, "line": "Can you two please join me in my office? Now.", "order": 36056, "speaker": "Gabe Lewis"},
  {"season": 6, "episode": 23, "scene": 19, "line": "You were supposed to be the Lookout.", "order": 36057, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 23, "scene": 19, "line": "Yeah, well it's really warm in here. It's like a sleeping bag.", "order": 36058, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 23, "scene": 20, "line": "I don't wanna be the heavy here, but honestly, guys, this makes us all look bad.", "order": 36059, "speaker": "Gabe Lewis"},
  {"season": 6, "episode": 23, "scene": 20, "line": "Sorry about that.", "order": 36060, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 23, "scene": 20, "line": "So embarrassing.", "order": 36061, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 23, "scene": 20, "line": "Good. Okay. End of the dressing down. I just hate that part of the job, you know, the power dynamics.", "order": 36062, "speaker": "Gabe Lewis"},
  {"season": 6, "episode": 23, "scene": 20, "line": "Well, you were surprisingly restrained.", "order": 36063, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 23, "scene": 20, "line": "We will be well-rested tomorrow.", "order": 36064, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 23, "scene": 20, "line": "Okay. That's great, actually, because I wanted to talk to you about something else.", "order": 36065, "speaker": "Gabe Lewis"},
  {"season": 6, "episode": 23, "scene": 20, "line": "Excellent. Go for it.", "order": 36066, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 23, "scene": 20, "line": "I'm sure you got my email about the printer fires. The one with the branching decision tree? I actually designed that chart. Kinda hoping it catches on. [Jim and Pam struggling to stay awake] Anyway, the question is, what is the best way to disseminate important information in an efficient way?", "order": 36067, "speaker": "Gabe Lewis"},
  {"season": 6, "episode": 23, "scene": 20, "line": "Right? So, uh, let's uh...", "order": 36068, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 23, "scene": 21, "line": "Finally there's a provision here in the occurrence that the child is born an old man, a la Benjamin Button.", "order": 36069, "speaker": "Lawyer"},
  {"season": 6, "episode": 23, "scene": 21, "line": "Same page. Go to the next one.", "order": 36070, "speaker": "Angela Martin"},
  {"season": 6, "episode": 23, "scene": 21, "line": "Same page. Just keep moving.", "order": 36071, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 23, "scene": 21, "line": "Despite the provision covering whether this is all part of the matrix--", "order": 36072, "speaker": "Lawyer"},
  {"season": 6, "episode": 23, "scene": 21, "line": "Which we can't know.", "order": 36073, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 23, "scene": 21, "line": "This is essentially...", "order": 36074, "speaker": "Lawyer"},
  {"season": 6, "episode": 23, "scene": 21, "line": "Unless we're unplugged.", "order": 36075, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 23, "scene": 21, "line": "Equivalent to a donor or surrogate contractor.", "order": 36076, "speaker": "Lawyer"},
  {"season": 6, "episode": 23, "scene": 21, "line": "And wake up in the future.", "order": 36077, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 23, "scene": 21, "line": "Which is actually pretty common. So I have to tell you that this is a solid contract.", "order": 36078, "speaker": "Lawyer"},
  {"season": 6, "episode": 23, "scene": 21, "line": "What?", "order": 36079, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 23, "scene": 21, "line": "Look, I can't enforce that anybody have a child with anyone else.", "order": 36080, "speaker": "Lawyer"},
  {"season": 6, "episode": 23, "scene": 21, "line": "What?", "order": 36081, "speaker": "Angela Martin"},
  {"season": 6, "episode": 23, "scene": 21, "line": "So essentially, this comes down to damages. Now, there is some precedence for the range of $30,000.", "order": 36082, "speaker": "Lawyer"},
  {"season": 6, "episode": 23, "scene": 21, "line": "What?", "order": 36083, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 23, "scene": 22, "line": "I don't have $30,000 lying around. I have it buried very deeply, and I don't want to dig past a certain someone to get it.", "order": 36084, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 23, "scene": 23, "line": "Chase Dixon is up. Could be a big play.", "order": 36085, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 23, "scene": 23, "line": "You have no idea what you're talking about.", "order": 36086, "speaker": "Michael Scott"},
  {"season": 6, "episode": 23, "scene": 23, "line": "I know it's like cricket.", "order": 36087, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 23, "scene": 23, "line": "You don't. No, no.", "order": 36088, "speaker": "Michael Scott"},
  {"season": 6, "episode": 23, "scene": 23, "line": "And it's--there's-- well, home plate is like a wicket.", "order": 36089, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 23, "scene": 23, "line": "You don't know. You don't-- you don't know anything. Look at him. Look at him over there. High-fiving? He didn't even do anything. Kid did all the work. I bet he does that at home. I bet it's like, 'Hey, Donna, why don't you wash the dishes?' 'High-five, Donna.' 'Hey, why don't you bring me my slippers?' 'Hi-five. I'm taking all the credit.'", "order": 36090, "speaker": "Michael Scott"},
  {"season": 6, "episode": 23, "scene": 23, "line": "Seems pretty well liked by the team.", "order": 36091, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 23, "scene": 23, "line": "Okay, well, that's because he's paying their salary.", "order": 36092, "speaker": "Michael Scott"},
  {"season": 6, "episode": 23, "scene": 23, "line": "You do know that high school coaches don't pay their players.", "order": 36093, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 23, "scene": 23, "line": "I know, I know, I know. It--I'm--it-- that was a euphemism.", "order": 36094, "speaker": "Michael Scott"},
  {"season": 6, "episode": 23, "scene": 24, "line": "$30,000? There's gotta be another way.", "order": 36095, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 23, "scene": 24, "line": "He has a 60-acre beet farm that is worth a small fortune.", "order": 36096, "speaker": "Angela Martin"},
  {"season": 6, "episode": 23, "scene": 24, "line": "Look, I will not pay.", "order": 36097, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 23, "scene": 24, "line": "Well, I have an alternative.[hands Dwight a document]", "order": 36098, "speaker": "Angela Martin"},
  {"season": 6, "episode": 23, "scene": 24, "line": "Intercourse to completion. Five individual times rendered at my discretion?'", "order": 36099, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 23, "scene": 24, "line": "Look, I don't think that's actually legal.", "order": 36100, "speaker": "Lawyer"},
  {"season": 6, "episode": 23, "scene": 24, "line": "Agreed. [shakes hands with Angela]", "order": 36101, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 23, "scene": 25, "line": "Five times for $30,000? Not a bad stud fee. Better than most horses.", "order": 36102, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 23, "scene": 26, "line": "But not in your bed. It's lumpy.", "order": 36103, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 23, "scene": 26, "line": "Those lumps are cats, and those cats have names, and those names are Ember, Milky Way, Diane, and Lumpy.", "order": 36104, "speaker": "Angela Martin"},
  {"season": 6, "episode": 23, "scene": 26, "line": "Fine. Five times. [signs contract]", "order": 36105, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 23, "scene": 26, "line": "Look, I can't legally watch this unfold. It's coming a little dangerously close to prostitution.", "order": 36106, "speaker": "Lawyer"},
  {"season": 6, "episode": 23, "scene": 26, "line": "I want eye contact.", "order": 36107, "speaker": "Angela Martin"},
  {"season": 6, "episode": 23, "scene": 26, "line": "No.", "order": 36108, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 23, "scene": 26, "line": "Yes.", "order": 36109, "speaker": "Angela Martin"},
  {"season": 6, "episode": 23, "scene": 26, "line": "Do you understand how rare is that in nature?", "order": 36110, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 23, "scene": 26, "line": "I'm not some farm animal. [Dwight smirks at the camera]", "order": 36111, "speaker": "Angela Martin"},
  {"season": 6, "episode": 23, "scene": 27, "line": "Great game, coach.", "order": 36112, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 23, "scene": 27, "line": "Shh! Okay, all right. Don't, don't.", "order": 36113, "speaker": "Michael Scott"},
  {"season": 6, "episode": 23, "scene": 27, "line": "You guys are awesome! Best game I've ever seen in my life.", "order": 36114, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 23, "scene": 27, "line": "Yeah, right. You must be rootin' for the other guys.", "order": 36115, "speaker": "Shane"},
  {"season": 6, "episode": 23, "scene": 27, "line": "Ha. The other guys can go die.", "order": 36116, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 23, "scene": 27, "line": "[whispers] Okay.", "order": 36117, "speaker": "Michael Scott"},
  {"season": 6, "episode": 23, "scene": 27, "line": "Baseball! Go talk to him.", "order": 36118, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 23, "scene": 27, "line": "No. God. You go talk to him.", "order": 36119, "speaker": "Michael Scott"},
  {"season": 6, "episode": 23, "scene": 28, "line": "Let's go.", "order": 36120, "speaker": "Shane"},
  {"season": 6, "episode": 23, "scene": 28, "line": "Shane. Great game man!", "order": 36121, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 23, "scene": 28, "line": "Do I know you?", "order": 36122, "speaker": "Shane"},
  {"season": 6, "episode": 23, "scene": 28, "line": "No. Uh, just big fans. Both of us.", "order": 36123, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 23, "scene": 28, "line": "Are you somebody's... parents? Oh, are you guys... Kenny's dads?", "order": 36124, "speaker": "Shane"},
  {"season": 6, "episode": 23, "scene": 28, "line": "Ha. No. No. But we're gay for baseball. [chuckles]", "order": 36125, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 23, "scene": 28, "line": "Okay.", "order": 36126, "speaker": "Shane"},
  {"season": 6, "episode": 23, "scene": 28, "line": "But I have a wife, actually, who I really love a lot.", "order": 36127, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 23, "scene": 28, "line": "Okay. Let's go!", "order": 36128, "speaker": "Shane"},
  {"season": 6, "episode": 23, "scene": 28, "line": "Yeah. I am so into the institution of marriage.", "order": 36129, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 23, "scene": 28, "line": "Whoa!", "order": 36130, "speaker": "Shane"},
  {"season": 6, "episode": 23, "scene": 28, "line": "I mean, isn't marriage the best, you know? Two good people finding each other, getting all committed to each other. So you love baseball. What else do you love? Let's round you out as a person.", "order": 36131, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 23, "scene": 28, "line": "Hey, I'm really sorry, I'm just trying to... focus here on the game.", "order": 36132, "speaker": "Shane"},
  {"season": 6, "episode": 23, "scene": 28, "line": "I get it, man. I love it. That's what makes you a good coach. You know, but as a fan, it just helps me enjoy the game better if I know the coach loves his wife.", "order": 36133, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 23, "scene": 28, "line": "Of course. Okay? I gotta... get back to this.", "order": 36134, "speaker": "Shane"},
  {"season": 6, "episode": 23, "scene": 28, "line": "Of course.", "order": 36135, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 23, "scene": 28, "line": "All right.", "order": 36136, "speaker": "Shane"},
  {"season": 6, "episode": 23, "scene": 28, "line": "But real quick, I want you to meet my associate. Sheldon!", "order": 36137, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 23, "scene": 28, "line": "No, no, no.", "order": 36138, "speaker": "Michael Scott"},
  {"season": 6, "episode": 23, "scene": 28, "line": "Come on, get over here.", "order": 36139, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 23, "scene": 28, "line": "I'm good. Oh, my God.", "order": 36140, "speaker": "Michael Scott"},
  {"season": 6, "episode": 23, "scene": 28, "line": "Get- Get over here. Sheldon, say hi to the nice coach.", "order": 36141, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 23, "scene": 28, "line": "Sorry. I got some stomach cramps.", "order": 36142, "speaker": "Michael Scott"},
  {"season": 6, "episode": 23, "scene": 28, "line": "It's all right. It's all right. Keep it going.", "order": 36143, "speaker": "Shane"},
  {"season": 6, "episode": 23, "scene": 28, "line": "Well, that was not at all what I expected. Hoo hoo! Whoo. My heart's still racing. I just looked a man in the eyes and I shook his hand. All the time I was thinking, 'I'm sleeping with your wife.' And you know who does that? James freakin' Bond.", "order": 36144, "speaker": "Michael Scott"},
  {"season": 6, "episode": 23, "scene": 29, "line": "Okay, orange slices. There ya go.", "order": 36145, "speaker": "Michael Scott"},
  {"season": 6, "episode": 23, "scene": 29, "line": "Thanks, Michael.", "order": 36146, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 23, "scene": 29, "line": "Don't thank me. Thank Dylan's mom. Here you go! Oh! Ooh Hoo Hoo Hoo hoo!", "order": 36147, "speaker": "Michael Scott"},
  {"season": 6, "episode": 23, "scene": 29, "line": "Why would you throw something wet at me?", "order": 36148, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 23, "scene": 29, "line": "You love it. There ya go.", "order": 36149, "speaker": "Michael Scott"},
  {"season": 6, "episode": 23, "scene": 29, "line": "Now, were those for the team?", "order": 36150, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 23, "scene": 29, "line": "Uh, no, they didn't say team. They just said coal Hawks.", "order": 36151, "speaker": "Michael Scott"},
  {"season": 6, "episode": 23, "scene": 29, "line": "Ah.", "order": 36152, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 23, "scene": 29, "line": "I don't think those were yours to take.", "order": 36153, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 23, "scene": 29, "line": "Well, then it wouldn't be the first time I stole something away from Coach Shane.", "order": 36154, "speaker": "Michael Scott"},
  {"season": 6, "episode": 23, "scene": 29, "line": "I just wanna go on record as saying that I do not condone this affair, and I went so far as to force Michael to confront the victim of his behavior.", "order": 36155, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 23, "scene": 29, "line": "Did you talk to him?", "order": 36156, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 23, "scene": 29, "line": "I did.", "order": 36157, "speaker": "Michael Scott"},
  {"season": 6, "episode": 23, "scene": 29, "line": "You talked to the man whose wife you're having an affair with.", "order": 36158, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 23, "scene": 29, "line": "Wow. That is crazy.", "order": 36159, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 23, "scene": 29, "line": "Was there a fistfight?", "order": 36160, "speaker": "Phyllis Vance"},
  {"season": 6, "episode": 23, "scene": 29, "line": "No. We just talked. He's very nice.", "order": 36161, "speaker": "Michael Scott"},
  {"season": 6, "episode": 23, "scene": 29, "line": "And it didn't change your mind.", "order": 36162, "speaker": "Phyllis Vance"},
  {"season": 6, "episode": 23, "scene": 29, "line": "It did not.", "order": 36163, "speaker": "Michael Scott"},
  {"season": 6, "episode": 23, "scene": 29, "line": "Michael, that's awful.", "order": 36164, "speaker": "Phyllis Vance"},
  {"season": 6, "episode": 23, "scene": 29, "line": "Isn't it?", "order": 36165, "speaker": "Michael Scott"},
  {"season": 6, "episode": 23, "scene": 29, "line": "Yes.", "order": 36166, "speaker": "Phyllis Vance"},
  {"season": 6, "episode": 23, "scene": 29, "line": "I'm awful, aren't I?", "order": 36167, "speaker": "Michael Scott"},
  {"season": 6, "episode": 23, "scene": 29, "line": "Yeah. That's pretty bad.", "order": 36168, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 23, "scene": 29, "line": "I'm an awful guy! Ooh!", "order": 36169, "speaker": "Michael Scott"},
  {"season": 6, "episode": 23, "scene": 29, "line": "How can you live with yourself?", "order": 36170, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 23, "scene": 29, "line": "I am what I am, Oscar. And I want what I want. And right now, I want a piece of cake. From now on, when I'm hungry, I am going to eat whatever I am hungry for.", "order": 36171, "speaker": "Michael Scott"},
  {"season": 6, "episode": 23, "scene": 29, "line": "That is a dangerous game, friendo.", "order": 36172, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 23, "scene": 30, "line": "Well, because I thought that it was a book about Anthropology the store.", "order": 36173, "speaker": "Kelly Kapoor"},
  {"season": 6, "episode": 23, "scene": 30, "line": "I don't know why you were in that part of the bookstore.", "order": 36174, "speaker": "Ryan Howard"},
  {"season": 6, "episode": 23, "scene": 30, "line": "Cause it's next to they baby section, okay?", "order": 36175, "speaker": "Kelly Kapoor"},
  {"season": 6, "episode": 23, "scene": 30, "line": "All right, that makes more sense. You should have said that at the beginning when you said, 'I read a book about anthropology.'", "order": 36176, "speaker": "Ryan Howard"},
  {"season": 6, "episode": 23, "scene": 30, "line": "I don't really know why you're screaming at me right now.", "order": 36177, "speaker": "Kelly Kapoor"},
  {"season": 6, "episode": 23, "scene": 30, "line": "I'm not scream-- I'm not screaming.", "order": 36178, "speaker": "Ryan Howard"},
  {"season": 6, "episode": 23, "scene": 30, "line": "That's Meredith's cake. It's her birthday.", "order": 36179, "speaker": "Kelly Kapoor"},
  {"season": 6, "episode": 23, "scene": 30, "line": "I don't care. I have an appetite for life! [eats cake] Mmm. Mmm! Oh, god. That's Lemon.", "order": 36180, "speaker": "Michael Scott"},
  {"season": 6, "episode": 23, "scene": 30, "line": "Good for you, man. Good for you.", "order": 36181, "speaker": "Ryan Howard"},
  {"season": 6, "episode": 23, "scene": 31, "line": "He takes what he wants.", "order": 36182, "speaker": "Ryan Howard"},
  {"season": 6, "episode": 23, "scene": 32, "line": "You know what? I think you're attractive, and I wanna sleep with you.", "order": 36183, "speaker": "Ryan Howard"},
  {"season": 6, "episode": 23, "scene": 32, "line": "What about Kelly?", "order": 36184, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 23, "scene": 32, "line": "You read my mind.", "order": 36185, "speaker": "Ryan Howard"},
  {"season": 6, "episode": 23, "scene": 32, "line": "[quietly] Is this a joke?", "order": 36186, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 23, "scene": 32, "line": "Yep.", "order": 36187, "speaker": "Ryan Howard"},
  {"season": 6, "episode": 23, "scene": 33, "line": "It's hard to live that way man. You gotta really not care what people think about you. I-I don't know how you do it, Michael, I-I-I can't be that cold.", "order": 36188, "speaker": "Ryan Howard"},
  {"season": 6, "episode": 23, "scene": 33, "line": "You'll learn, baby. You'll learn.", "order": 36189, "speaker": "Michael Scott"},
  {"season": 6, "episode": 23, "scene": 34, "line": "[pressing buttons to start microwave and moves towards his crotch] I am an honorable man, and I will honor the contract. But I don't have to give her the good stuff. Schrute sperm are strong, [banging on his crotch with drum sticks] but they're no match for a grown Schrute man. [drops yellow pages against his crotch] [screaming] [chuckling] Let's see what she gets. [bouncing crotch on bike and crashes into glass door] [thud] Aah!", "order": 36190, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 23, "scene": 35, "line": "[Michael leaving copy machine] Uh, boss, we're out of paper.", "order": 36191, "speaker": "Creed Bratton"},
  {"season": 6, "episode": 23, "scene": 35, "line": "Yeah, I noticed that.", "order": 36192, "speaker": "Michael Scott"},
  {"season": 6, "episode": 23, "scene": 35, "line": "Are you gonna add any more?", "order": 36193, "speaker": "Creed Bratton"},
  {"season": 6, "episode": 23, "scene": 35, "line": "Nope.", "order": 36194, "speaker": "Michael Scott"},
  {"season": 6, "episode": 23, "scene": 36, "line": "He don't give an 'F' about nothin'!", "order": 36195, "speaker": "Creed Bratton"},
  {"season": 6, "episode": 23, "scene": 37, "line": "I have got big balls.", "order": 36196, "speaker": "Michael Scott"},
  {"season": 6, "episode": 23, "scene": 38, "line": "I don't think I'm gonna make it.", "order": 36197, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 23, "scene": 38, "line": "[yawning] What about an energy drink or something?", "order": 36198, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 23, "scene": 38, "line": "[shakes head] It gets in the breast milk. If I drink it, Cece drinks it six hours later.", "order": 36199, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 23, "scene": 38, "line": "Well, it doesn't mean I can't drink it.", "order": 36200, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 23, "scene": 38, "line": "Well, it does and it doesn't.", "order": 36201, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 23, "scene": 38, "line": "Probably shouldn't tell you this, but a lotta guys in the warehouse work multiple jobs, so... we have a place.", "order": 36202, "speaker": "Darryl Philbin"},
  {"season": 6, "episode": 23, "scene": 38, "line": "A place?", "order": 36203, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 23, "scene": 38, "line": "A restful location. Talk to Glen. He'll take you up in the lift.", "order": 36204, "speaker": "Darryl Philbin"},
  {"season": 6, "episode": 23, "scene": 38, "line": "You sleep in the warehouse?", "order": 36205, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 23, "scene": 38, "line": "Lightbulb's burnt out, so it's dark. And the heat from the backup generator keeps it nice and warm. Sometimes I think about it when I'm trying to fall asleep at home. But as far as the rest of the office goes, it doesn't exist, okay?", "order": 36206, "speaker": "Darryl Philbin"},
  {"season": 6, "episode": 23, "scene": 38, "line": "Okay.", "order": 36207, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 23, "scene": 38, "line": "Pam.", "order": 36208, "speaker": "Darryl Philbin"},
  {"season": 6, "episode": 23, "scene": 38, "line": "Okay.", "order": 36209, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 23, "scene": 39, "line": "For your signature. [drops document on Michael's desk]", "order": 36210, "speaker": "Phyllis Vance"},
  {"season": 6, "episode": 23, "scene": 39, "line": "Okay. [Phyllis avoids looking at Michael] Phyllis. Okay. Look at me. [continues to look away] Phyllis, look at-- [Phyllis turns to leave] okay, that's... [chases after her] All right, all right. You know what? I'm sick of this. I'm sick of the little disapproving head shakes. If you think that's going to have any effect on me, it will not. I am going to leave right now and go to a motel to meet Donna.", "order": 36211, "speaker": "Michael Scott"},
  {"season": 6, "episode": 23, "scene": 39, "line": "Why not your condo?", "order": 36212, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 23, "scene": 39, "line": "Because a motel is dirty and it is sexy. Like me. And like Donna. And frankly, the stuff that we're into isn't very condo-appropriate.", "order": 36213, "speaker": "Michael Scott"},
  {"season": 6, "episode": 23, "scene": 39, "line": "Oh, Michael, will you drop it? Everybody's spoken their mind, and no one's changing their mind.", "order": 36214, "speaker": "Stanley Hudson"},
  {"season": 6, "episode": 23, "scene": 39, "line": "Okay, Morgan Freeman-narrating-everything.", "order": 36215, "speaker": "Michael Scott"},
  {"season": 6, "episode": 23, "scene": 39, "line": "Do you want someone to stop you? 'Cause no one's going to.", "order": 36216, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 23, "scene": 39, "line": "Nobody better try to stop me. Good. [leaves the office. door closes]", "order": 36217, "speaker": "Michael Scott"},
  {"season": 6, "episode": 23, "scene": 39, "line": "I could have stopped him.", "order": 36218, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 23, "scene": 40, "line": "Are you warmed up? [outside sliding door in warehouse]", "order": 36219, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 23, "scene": 40, "line": "No.", "order": 36220, "speaker": "Angela Martin"},
  {"season": 6, "episode": 23, "scene": 40, "line": "God, Angela, why is that always my responsibility? [zipper sound]", "order": 36221, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 23, "scene": 40, "line": "What did you do to yourself? [camera pans to Jim and Pam laying down in restful location]", "order": 36222, "speaker": "Angela Martin"},
  {"season": 6, "episode": 23, "scene": 40, "line": "Never mind. Just never mind.", "order": 36223, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 23, "scene": 40, "line": "Well, it better work.", "order": 36224, "speaker": "Angela Martin"},
  {"season": 6, "episode": 23, "scene": 40, "line": "Oh, it'll work. [Jim and Pam look shocked and disturbed] Mm. Stop kissing me. It's not in the contract.", "order": 36225, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 23, "scene": 41, "line": "[driving in car; returns to office; grabs ice cream out of freezer] How do I feel about breaking up with Donna? Good. Moral. I feel proud. [beeping; cut to Donna who is waiting in the parking lot of the motel] Like a grownup.[Donna receives text from phone] That was not easy because I really liked her a lot. And I'm a little bit emotional right now because I know that I absolutely made the right decision. [Donna has disappointed look; cut back to Michael in his office opening ice cream] At the end of the day, we have to do what's right. And it was either living with myself or... being happy. And I picked... the... former.", "order": 36226, "speaker": "Michael Scott"},
  {"season": 6, "episode": 23, "scene": 42, "line": "Michael Scott?", "order": 36227, "speaker": "Reporter"},
  {"season": 6, "episode": 23, "scene": 42, "line": "Yes?", "order": 36228, "speaker": "Michael Scott"},
  {"season": 6, "episode": 23, "scene": 42, "line": "Do you wanna make a comment on the rumors?", "order": 36229, "speaker": "Reporter"},
  {"season": 6, "episode": 23, "scene": 42, "line": "[sighs] Umm... I have done some very bad things, things of which I am not proud. I would like to publicly apologize to... the coach and the players. And I vow to never listen to my bodily instincts ever again.", "order": 36230, "speaker": "Michael Scott"},
  {"season": 6, "episode": 23, "scene": 42, "line": "I'm talking about the Sabre printers that catch on fire.", "order": 36231, "speaker": "Reporter"},
  {"season": 6, "episode": 23, "scene": 42, "line": "Oh. Okay. I was talking about... what do-- what? What's going on?", "order": 36232, "speaker": "Michael Scott"},
  {"season": 6, "episode": 24, "scene": 1, "line": "[on Youtube video] There is nothing wrong, nor will there ever be wrong with any Sabre printers. Case closed. [phone rings, Michael picks it up] Michael Scott, as seen on TV.", "order": 36233, "speaker": "Michael Scott"},
  {"season": 6, "episode": 24, "scene": 1, "line": "[high-pitched voice] I saw you on the news and I want to pinch your tiny wiener... [normal voice] It's Packer!", "order": 36234, "speaker": "Todd Packer"},
  {"season": 6, "episode": 24, "scene": 1, "line": "OH! Pack Man, I thought you were a girl!", "order": 36235, "speaker": "Michael Scott"},
  {"season": 6, "episode": 24, "scene": 2, "line": "Apparently, there are allegations that Sabre printers can cause a fire. So they asked me to give a statement to the press. I'm like, what? All right. So I do it. It's on TV last night. And it's in the paper today. And it's online. And then I call Froggy 98.7, the request line. I talk to the host about it on the air. It's like, come on, people, enough.", "order": 36236, "speaker": "Michael Scott"},
  {"season": 6, "episode": 24, "scene": 3, "line": "Everybody, we are at DEFCON 5. I am officially the second-most watched clip of the day on the WBRE news site.", "order": 36237, "speaker": "Michael Scott"},
  {"season": 6, "episode": 24, "scene": 3, "line": "What's number one?", "order": 36238, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 24, "scene": 3, "line": "Oh, that teacher who was wrongfully accused of being a pedophile. Now, we cannot let the pedophile win again! I would like you all to go to the website and watch my clip eleven times.", "order": 36239, "speaker": "Michael Scott"},
  {"season": 6, "episode": 24, "scene": 3, "line": "So instead of working, you want...", "order": 36240, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 24, "scene": 3, "line": "Yes.", "order": 36241, "speaker": "Michael Scott"},
  {"season": 6, "episode": 24, "scene": 3, "line": "Okay.", "order": 36242, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 24, "scene": 3, "line": "Yes. Come on! Get it up! That's what... let's do it!", "order": 36243, "speaker": "Michael Scott"},
  {"season": 6, "episode": 24, "scene": 3, "line": "Oh, did you see this report that the zoo got a baby otter? It's on the same site!", "order": 36244, "speaker": "Angela Martin"},
  {"season": 6, "episode": 24, "scene": 3, "line": "Awww, it's kissing its mommy!", "order": 36245, "speaker": "Phyllis Vance"},
  {"season": 6, "episode": 24, "scene": 3, "line": "Please.", "order": 36246, "speaker": "Michael Scott"},
  {"season": 6, "episode": 24, "scene": 3, "line": "Michael, you have to see, this is like, the cutest thing ever.", "order": 36247, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 24, "scene": 4, "line": "Really? A baby otter? Okay, um, count me in as who cares. It's not even that interesting a baby otter, it can't even stand up. [looks toward computer] It's trying to stand up... [choking up] there it goes.", "order": 36248, "speaker": "Michael Scott"},
  {"season": 6, "episode": 24, "scene": 5, "line": "Howdy. I hope I'm not interrupting anything.", "order": 36249, "speaker": "Jo Bennett"},
  {"season": 6, "episode": 24, "scene": 5, "line": "Jo! We were not expecting you!", "order": 36250, "speaker": "Michael Scott"},
  {"season": 6, "episode": 24, "scene": 5, "line": "Course you all, no doubt, know why I'm here.", "order": 36251, "speaker": "Jo Bennett"},
  {"season": 6, "episode": 24, "scene": 5, "line": "No.", "order": 36252, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 24, "scene": 5, "line": "Turns out our printers are famous. They're all over the news. It's an interesting story. Cheap foreign printers attacking innocent Americans. Well, actually the, the real story isn't quite as racy, but uh... let's give it a go.", "order": 36253, "speaker": "Jo Bennett"},
  {"season": 6, "episode": 24, "scene": 5, "line": "Jo, I think that I know what happened.", "order": 36254, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 24, "scene": 5, "line": "I'm not sure you do, teddy bear.", "order": 36255, "speaker": "Jo Bennett"},
  {"season": 6, "episode": 24, "scene": 5, "line": "Well, now I think I might not.", "order": 36256, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 24, "scene": 5, "line": "Not long ago, we discovered a defect in one of our printers, so we got a software patch and fixed it right up, just like that. I don't know how it works. But just as we were about to send out a letter to our affected customers, giving 'em free toner, and we keep 'em, happy, but somebody here, they liked that first story better. The one where we lose half our clients for no damn good reason! Whoever it was who talked to the press, they should come forward, please.", "order": 36257, "speaker": "Jo Bennett"},
  {"season": 6, "episode": 24, "scene": 5, "line": "Jo, Jo, I can assure you it was no one in this office.", "order": 36258, "speaker": "Michael Scott"},
  {"season": 6, "episode": 24, "scene": 5, "line": "Can you now?", "order": 36259, "speaker": "Jo Bennett"},
  {"season": 6, "episode": 24, "scene": 5, "line": "Absolutely. Anyone who talked to the press, please raise your hand.", "order": 36260, "speaker": "Michael Scott"},
  {"season": 6, "episode": 24, "scene": 5, "line": "[quietly, to Andy] Put your hand up, Norma Rae.", "order": 36261, "speaker": "Phyllis Vance"},
  {"season": 6, "episode": 24, "scene": 5, "line": "If you say anything, so help me God, I'll break off the temples of your glasses and stick them in your eye sockets.", "order": 36262, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 24, "scene": 6, "line": "So unfair. Even if I thought that our printers killed baby seals, I would not be a whistleblower. The Bernards, for generations, have silenced whistleblowers. It's how we made all our money. Woody Guthrie wrote a song about us. [sings] Old Mr. Bernard, old Mr. Bernard, who have you silenced today?", "order": 36263, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 24, "scene": 7, "line": "[as Gabe hands out forms to everyone] It's a little form, says 'I did not do it.'", "order": 36264, "speaker": "Jo Bennett"},
  {"season": 6, "episode": 24, "scene": 7, "line": "There is no reason for anyone here to sign this, because I know everything there is to know about these people. I know when their birthdays are, I know what their favorite kind of cake is, I know what color streamers they like...", "order": 36265, "speaker": "Michael Scott"},
  {"season": 6, "episode": 24, "scene": 7, "line": "All that's just birthday information, Michael.", "order": 36266, "speaker": "Jo Bennett"},
  {"season": 6, "episode": 24, "scene": 7, "line": "Yes, yes, but it shows a bigger picture.", "order": 36267, "speaker": "Michael Scott"},
  {"season": 6, "episode": 24, "scene": 7, "line": "Why don't you come with me? We'll start out with the honcho, what'cha say?", "order": 36268, "speaker": "Jo Bennett"},
  {"season": 6, "episode": 24, "scene": 7, "line": "Okay.", "order": 36269, "speaker": "Michael Scott"},
  {"season": 6, "episode": 24, "scene": 8, "line": "When Mama was working as a prison guard and something went missing, she'd ask one question: What do we do when we find the guilty party? And if they said, 'Come down on him with that swift hammer of justice!', innocent. A clear conscience don't need no mercy. But if they said 'Officer Bessie, well they may have had a reason, blah blah blah blah', well nine times out of ten, that's the anus they'd check.", "order": 36270, "speaker": "Jo Bennett"},
  {"season": 6, "episode": 24, "scene": 9, "line": "So say we catch this whistleblower, what do you think I should do with him?", "order": 36271, "speaker": "Jo Bennett"},
  {"season": 6, "episode": 24, "scene": 9, "line": "We should give him a one-way ticket to Montego Bay, where they keep all the al-Qaeda.", "order": 36272, "speaker": "Michael Scott"},
  {"season": 6, "episode": 24, "scene": 9, "line": "Uh, that's, uh, Guantanamo Bay.", "order": 36273, "speaker": "Jo Bennett"},
  {"season": 6, "episode": 24, "scene": 9, "line": "Yes. You put them in jail for a long time, you put them in jail for as long as you can.", "order": 36274, "speaker": "Michael Scott"},
  {"season": 6, "episode": 24, "scene": 9, "line": "Well, I guess we're all right, Michael.", "order": 36275, "speaker": "Jo Bennett"},
  {"season": 6, "episode": 24, "scene": 9, "line": "I want these people to really pay, I want them to suffer. I'd prefer it if they died, 'cause it's not right.", "order": 36276, "speaker": "Michael Scott"},
  {"season": 6, "episode": 24, "scene": 9, "line": "Yeah.", "order": 36277, "speaker": "Jo Bennett"},
  {"season": 6, "episode": 24, "scene": 9, "line": "Okay.", "order": 36278, "speaker": "Michael Scott"},
  {"season": 6, "episode": 24, "scene": 10, "line": "Hey Dwight.", "order": 36279, "speaker": "Nick"},
  {"season": 6, "episode": 24, "scene": 10, "line": "Hey, IT guy.", "order": 36280, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 24, "scene": 10, "line": "Mind if I get in there for a second?", "order": 36281, "speaker": "Nick"},
  {"season": 6, "episode": 24, "scene": 10, "line": "Oh, sure thing, go ahead.", "order": 36282, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 24, "scene": 10, "line": "Just one... [Dwight pushes Nick's neck to the desk and pushes his arm up] Gah! Ah! Dwight, what the hell?", "order": 36283, "speaker": "Nick"},
  {"season": 6, "episode": 24, "scene": 10, "line": "Apache persuasion hold, that's the hell! What are you doing to my computer?", "order": 36284, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 24, "scene": 10, "line": "The lawyers are searching our hard drives for information on the leak. Thanks a lot, Big Brother.", "order": 36285, "speaker": "Oscar Martinez"},
  {"season": 6, "episode": 24, "scene": 10, "line": "You're with Big Brother? Okay, go ahead. I got nothing to hide.", "order": 36286, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 24, "scene": 10, "line": "Wait, are they searching all our computers?", "order": 36287, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 24, "scene": 10, "line": "Yeah. [Kevin runs to his desk] I already got to yours, Kevin. [Kevin runs back]", "order": 36288, "speaker": "Nick"},
  {"season": 6, "episode": 24, "scene": 10, "line": "No, that's cool. Sometimes... sometimes I run. I'm a runner.", "order": 36289, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 24, "scene": 11, "line": "Everybody knows it was Andy, and it is not fair for us all to take the fall for his big stupid mouth!", "order": 36290, "speaker": "Angela Martin"},
  {"season": 6, "episode": 24, "scene": 11, "line": "Ridiculous, Angela. And like I'm going to believe one of his spermed lovers.", "order": 36291, "speaker": "Michael Scott"},
  {"season": 6, "episode": 24, "scene": 11, "line": "Look, he's been complaining about this for a while, it's not crazy.", "order": 36292, "speaker": "Phyllis Vance"},
  {"season": 6, "episode": 24, "scene": 12, "line": "Okay Dwight.", "order": 36293, "speaker": "Jo Bennett"},
  {"season": 6, "episode": 24, "scene": 12, "line": "Hold that thought. I don't want to waste your time, and I wouldn't dare waste mine. I didn't do it. Now, I don't know exactly who did it, but I have a list right here... [hands Jo a piece of paper] You should fire the following people.", "order": 36294, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 24, "scene": 12, "line": "Well, I'm inclined to believe you.", "order": 36295, "speaker": "Jo Bennett"},
  {"season": 6, "episode": 24, "scene": 12, "line": "Why would I disparage a company that has made me rich beyond my wildest dreams?", "order": 36296, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 24, "scene": 12, "line": "Yeah, I noticed you've had a great year. Good boy... you turning that money into more money?", "order": 36297, "speaker": "Jo Bennett"},
  {"season": 6, "episode": 24, "scene": 12, "line": "Are you referring to alchemy?", "order": 36298, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 24, "scene": 12, "line": "I don't like to tell a man what to do with his money, but if you ain't investing in property, then you're dumber than a dummy.", "order": 36299, "speaker": "Jo Bennett"},
  {"season": 6, "episode": 24, "scene": 12, "line": "I'm not dumb. I'm smart.", "order": 36300, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 24, "scene": 12, "line": "Well, buy property. That's my advice.", "order": 36301, "speaker": "Jo Bennett"},
  {"season": 6, "episode": 24, "scene": 13, "line": "Is there something that you would like to say to me?", "order": 36302, "speaker": "Michael Scott"},
  {"season": 6, "episode": 24, "scene": 13, "line": "Uh...", "order": 36303, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 24, "scene": 13, "line": "About talking to the press?", "order": 36304, "speaker": "Michael Scott"},
  {"season": 6, "episode": 24, "scene": 13, "line": "I, I didn't know. I didn't do that!", "order": 36305, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 24, "scene": 13, "line": "Mmhmm.", "order": 36306, "speaker": "Michael Scott"},
  {"season": 6, "episode": 24, "scene": 13, "line": "Okay? I, I... didn't do it.", "order": 36307, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 24, "scene": 13, "line": "I don't believe you.", "order": 36308, "speaker": "Michael Scott"},
  {"season": 6, "episode": 24, "scene": 13, "line": "I swear on the graves of my parents who aren't even dead yet.", "order": 36309, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 24, "scene": 13, "line": "That's a little much. All right, all right, all right.", "order": 36310, "speaker": "Michael Scott"},
  {"season": 6, "episode": 24, "scene": 13, "line": "I don't care, that's how much I swear!", "order": 36311, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 24, "scene": 13, "line": "Okay, I believe you, I believe you.", "order": 36312, "speaker": "Michael Scott"},
  {"season": 6, "episode": 24, "scene": 13, "line": "I don't know who's giving Darryl any crap. He was more bothered about it than me.", "order": 36313, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 24, "scene": 14, "line": "Did you tell anyone outside of this office that the printers were catching on fire?", "order": 36314, "speaker": "Michael Scott"},
  {"season": 6, "episode": 24, "scene": 14, "line": "Yeah, I did. I, I was talking to this girl at a bar.", "order": 36315, "speaker": "Darryl Philbin"},
  {"season": 6, "episode": 24, "scene": 14, "line": "Oh, no, no, no...", "order": 36316, "speaker": "Michael Scott"},
  {"season": 6, "episode": 24, "scene": 14, "line": "I think she could... sense my sadness, and I, and I found out too late that she... she's the copy editor at the Trib.", "order": 36317, "speaker": "Darryl Philbin"},
  {"season": 6, "episode": 24, "scene": 14, "line": "Oh my god. Was she cute?", "order": 36318, "speaker": "Michael Scott"},
  {"season": 6, "episode": 24, "scene": 14, "line": "No.", "order": 36319, "speaker": "Darryl Philbin"},
  {"season": 6, "episode": 24, "scene": 14, "line": "Oh, god, Darryl!", "order": 36320, "speaker": "Michael Scott"},
  {"season": 6, "episode": 24, "scene": 15, "line": "I basically swore up and down that none of my employees did it, and then I find out that one of my best ones did. And now he's probably going to get fired for it. And if that is not poetic justice, I don't know what is.", "order": 36321, "speaker": "Michael Scott"},
  {"season": 6, "episode": 24, "scene": 16, "line": "Sounds like you were as blindsided by this as I was.", "order": 36322, "speaker": "Jo Bennett"},
  {"season": 6, "episode": 24, "scene": 16, "line": "Well, that's...", "order": 36323, "speaker": "Toby Flenderson"},
  {"season": 6, "episode": 24, "scene": 16, "line": "And we didn't find anything on your computer.", "order": 36324, "speaker": "Jo Bennett"},
  {"season": 6, "episode": 24, "scene": 16, "line": "Good.", "order": 36325, "speaker": "Toby Flenderson"},
  {"season": 6, "episode": 24, "scene": 16, "line": "Except this. [hands stack of paper to Toby]", "order": 36326, "speaker": "Jo Bennett"},
  {"season": 6, "episode": 24, "scene": 16, "line": "Oh, wow, this is, uh, just a mystery novel that I've been working on.", "order": 36327, "speaker": "Toby Flenderson"},
  {"season": 6, "episode": 24, "scene": 16, "line": "I know what it is. I skimmed the first chapter. I'm just curious, why would a man who hates people want to have a relationship with a maid?", "order": 36328, "speaker": "Jo Bennett"},
  {"season": 6, "episode": 24, "scene": 16, "line": "Oh, uh, I don't know, uh...", "order": 36329, "speaker": "Toby Flenderson"},
  {"season": 6, "episode": 24, "scene": 16, "line": "The way I look at it, there's only one of two reasons. He knows a secret about her that she doesn't know herself, or he wants to use her services to mop up after a murder.", "order": 36330, "speaker": "Jo Bennett"},
  {"season": 6, "episode": 24, "scene": 16, "line": "Oh... yes.", "order": 36331, "speaker": "Toby Flenderson"},
  {"season": 6, "episode": 24, "scene": 17, "line": "Write your own damn novel.", "order": 36332, "speaker": "Toby Flenderson"},
  {"season": 6, "episode": 24, "scene": 18, "line": "It was me.", "order": 36333, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 24, "scene": 18, "line": "What was you? You were the leak?", "order": 36334, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 24, "scene": 18, "line": "Okay. I'm talking to this woman at daycare...", "order": 36335, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 24, "scene": 18, "line": "Uh-huh.", "order": 36336, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 24, "scene": 18, "line": "She's telling me about all her amazing trip to Vietnam, I have nothing. I tell her our printers catch on fire, spontaneously!", "order": 36337, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 24, "scene": 18, "line": "Why'd you do that?", "order": 36338, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 24, "scene": 18, "line": "Her husband's a reporter.", "order": 36339, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 24, "scene": 18, "line": "Yeah.", "order": 36340, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 24, "scene": 18, "line": "So now everyone hates Andy, and it's this whole mess, and I don't know what to do.", "order": 36341, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 24, "scene": 18, "line": "Okay, just relax. Just need to relax.", "order": 36342, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 24, "scene": 18, "line": "I could tell Jo... or I could tell Michael.", "order": 36343, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 24, "scene": 18, "line": "This is a very good idea. This is good. Let's get all the bad ideas out now. Flush them out.", "order": 36344, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 24, "scene": 19, "line": "You leaked it?", "order": 36345, "speaker": "Michael Scott"},
  {"season": 6, "episode": 24, "scene": 19, "line": "I don't know what to do! Do I go tell Jo, or - I don't want everyone to keep blaming the wrong person!", "order": 36346, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 24, "scene": 19, "line": "I don't know what the best plan is, Pam. Oh god... my mind is going a mile an hour.", "order": 36347, "speaker": "Michael Scott"},
  {"season": 6, "episode": 24, "scene": 19, "line": "That fast?", "order": 36348, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 24, "scene": 19, "line": "Oh.", "order": 36349, "speaker": "Michael Scott"},
  {"season": 6, "episode": 24, "scene": 20, "line": "Two whistleblowers... two! I always thought Darryl and Pam might get me fired for something I said.", "order": 36350, "speaker": "Michael Scott"},
  {"season": 6, "episode": 24, "scene": 21, "line": "[Michael knocks on Pam's desk] Yeah? [Michael points at Pam, himself, and Meredith, mimes drinking, driving, then points to his watch and holds up five fingers] I weirdly know exactly what you're saying to me.", "order": 36351, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 24, "scene": 21, "line": "So see you in Meredith's van in five minutes.", "order": 36352, "speaker": "Michael Scott"},
  {"season": 6, "episode": 24, "scene": 21, "line": "Yeah, you didn't need to actually say it.", "order": 36353, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 24, "scene": 22, "line": "[in Meredith's van] I have never seen so many parking tickets.", "order": 36354, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 24, "scene": 22, "line": "All right, this is just messed up.", "order": 36355, "speaker": "Darryl Philbin"},
  {"season": 6, "episode": 24, "scene": 22, "line": "No, you know what's messed up? This situation all up in here is what's messed up! We need to brainstorm, we need to get out of this! Brain hurricane. Come on, think.", "order": 36356, "speaker": "Michael Scott"},
  {"season": 6, "episode": 24, "scene": 22, "line": "All right.", "order": 36357, "speaker": "Darryl Philbin"},
  {"season": 6, "episode": 24, "scene": 22, "line": "What do we got?", "order": 36358, "speaker": "Michael Scott"},
  {"season": 6, "episode": 24, "scene": 22, "line": "Um...", "order": 36359, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 24, "scene": 22, "line": "[opening door] Hey guys, sorry I'm late.", "order": 36360, "speaker": "Kelly Kapoor"},
  {"season": 6, "episode": 24, "scene": 22, "line": "We're not going for yogurt.", "order": 36361, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 24, "scene": 22, "line": "It's okay, she's cool, she also whistle-blew.", "order": 36362, "speaker": "Michael Scott"},
  {"season": 6, "episode": 24, "scene": 22, "line": "Guys, I couldn't help it, it is so boring where we work. I mean, it's as interesting as a morgue. It might be less interesting than a morgue.", "order": 36363, "speaker": "Kelly Kapoor"},
  {"season": 6, "episode": 24, "scene": 22, "line": "Hey, hey, it's as interesting as a morgue.", "order": 36364, "speaker": "Michael Scott"},
  {"season": 6, "episode": 24, "scene": 23, "line": "Of course I'm the leak! I think I Tweeted it! I can't control what I say to people, I spend the whole day talking! I mean, I video chat, I Skype, I text, I Tweet, I phone, I Woof...", "order": 36365, "speaker": "Kelly Kapoor"},
  {"season": 6, "episode": 24, "scene": 24, "line": "Woof is a site that I'm launching to be the last word in social networking. For just $12.99 a month, Woof links up all your communication portals so you are always within reach. It's part of the dog pack, as I call it. But, look, why tell you when I can show you. [types on his phone] I just sent myself a Woof. [fax machine makes noise, several windows pop up on computer behind Ryan with accompanying sounds, including barking]", "order": 36366, "speaker": "Ryan Howard"},
  {"season": 6, "episode": 24, "scene": 24, "line": "[on phone] Ryan, you have a Woof on line 1.", "order": 36367, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 24, "scene": 24, "line": "Thank you, Erin. Woof!", "order": 36368, "speaker": "Ryan Howard"},
  {"season": 6, "episode": 24, "scene": 25, "line": "Okay, here's what we do. I say we just smash all the computers. We destroy the evidence. No evidence, no case.", "order": 36369, "speaker": "Michael Scott"},
  {"season": 6, "episode": 24, "scene": 25, "line": "Wouldn't we get fired for smashing all the computers?", "order": 36370, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 24, "scene": 25, "line": "No. Okay, not all the computers, just our computers.", "order": 36371, "speaker": "Michael Scott"},
  {"season": 6, "episode": 24, "scene": 25, "line": "That idea sucks.", "order": 36372, "speaker": "Kelly Kapoor"},
  {"season": 6, "episode": 24, "scene": 25, "line": "I don't think that's a good idea.", "order": 36373, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 24, "scene": 25, "line": "Just don't say no.", "order": 36374, "speaker": "Michael Scott"},
  {"season": 6, "episode": 24, "scene": 25, "line": "Michael, you know you don't have to turn us all in. All you need is one scapegoat.", "order": 36375, "speaker": "Darryl Philbin"},
  {"season": 6, "episode": 24, "scene": 25, "line": "Uh, if you turn me in, I'm turning in Darryl.", "order": 36376, "speaker": "Kelly Kapoor"},
  {"season": 6, "episode": 24, "scene": 25, "line": "That's just what we need, another black man in prison. You know, let's just...", "order": 36377, "speaker": "Michael Scott"},
  {"season": 6, "episode": 24, "scene": 25, "line": "Nobody's going to prison, okay? Um... all right, Michael, you need to convince Jo to go easy on us. And then we'll all confess once we know our jobs are safe.", "order": 36378, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 24, "scene": 25, "line": "Okay. You can count on me.", "order": 36379, "speaker": "Michael Scott"},
  {"season": 6, "episode": 24, "scene": 26, "line": "[sees Toby banging on the vending machine] Let me give you a hand.", "order": 36380, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 24, "scene": 26, "line": "I'm hungry.", "order": 36381, "speaker": "Toby Flenderson"},
  {"season": 6, "episode": 24, "scene": 26, "line": "Ready? [they pull the vending machine towards them; Dwight's phone rings] Got it? Oh shoot. Got it? [walks away, opens phone] Dwight Schrute.", "order": 36382, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 24, "scene": 26, "line": "[on phone] The property you're looking at is in great shape. By the way, it has a very spacious basement office.", "order": 36383, "speaker": "Realtor"},
  {"season": 6, "episode": 24, "scene": 26, "line": "Basement office? You mean like a lair?", "order": 36384, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 24, "scene": 27, "line": "Hey Jo.", "order": 36385, "speaker": "Michael Scott"},
  {"season": 6, "episode": 24, "scene": 27, "line": "Michael.", "order": 36386, "speaker": "Jo Bennett"},
  {"season": 6, "episode": 24, "scene": 27, "line": "I was thinking about our little leak problem. I was up all lunch thinking about what we should do to this individual. You know what I think we should do? We make 'em come to work, and we work 'em, and we make 'em sit next to all the people they screwed over. And, and we pay 'em but we make 'em feel like they did something really wrong. The one question I have is, do we give them a Christmas bonus? I say yes, it's Christmas, but right after they're back in the thick of it.", "order": 36387, "speaker": "Michael Scott"},
  {"season": 6, "episode": 24, "scene": 27, "line": "Michael Scott... what do you know?", "order": 36388, "speaker": "Jo Bennett"},
  {"season": 6, "episode": 24, "scene": 27, "line": "What?", "order": 36389, "speaker": "Michael Scott"},
  {"season": 6, "episode": 24, "scene": 28, "line": "It doesn't matter what I know.", "order": 36390, "speaker": "Michael Scott"},
  {"season": 6, "episode": 24, "scene": 28, "line": "If it doesn't matter, then tell me.", "order": 36391, "speaker": "Jo Bennett"},
  {"season": 6, "episode": 24, "scene": 28, "line": "Why?", "order": 36392, "speaker": "Michael Scott"},
  {"season": 6, "episode": 24, "scene": 28, "line": "Because I want to deal with it the way I deal with it.", "order": 36393, "speaker": "Jo Bennett"},
  {"season": 6, "episode": 24, "scene": 28, "line": "Well, deal with this. [crosses arms]", "order": 36394, "speaker": "Michael Scott"},
  {"season": 6, "episode": 24, "scene": 28, "line": "Empathize with me for a moment. I came up here with a big problem, and now I got a branch manager who's giving me the silent treatment... Speak to me... speak. [Jo's dog barks] Come with me.", "order": 36395, "speaker": "Jo Bennett"},
  {"season": 6, "episode": 24, "scene": 28, "line": "What?", "order": 36396, "speaker": "Michael Scott"},
  {"season": 6, "episode": 24, "scene": 29, "line": "[as Michael and Jo leave] Are you leaving? Oh, am I... should I continue with the investigation?", "order": 36397, "speaker": "Gabe Lewis"},
  {"season": 6, "episode": 24, "scene": 30, "line": "Are you going to kill me?", "order": 36398, "speaker": "Michael Scott"},
  {"season": 6, "episode": 24, "scene": 30, "line": "Hahahahahaha.", "order": 36399, "speaker": "Jo Bennett"},
  {"season": 6, "episode": 24, "scene": 31, "line": "[on phone] I just need you to come by later and sign a few forms.", "order": 36400, "speaker": "Realtor"},
  {"season": 6, "episode": 24, "scene": 31, "line": "Okay.", "order": 36401, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 24, "scene": 31, "line": "What time works for you? [Dwight looks across the street] Mr. Schrute, what time works?", "order": 36402, "speaker": "Realtor"},
  {"season": 6, "episode": 24, "scene": 31, "line": "Cancel it. I want you to make an offer at seventeen twenty-five Slough Avenue. Make 'em an offer they can't refuse. No, on second thought, low ball them. Don't call me 'til you have it.", "order": 36403, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 24, "scene": 31, "line": "But... [Dwight closes phone]", "order": 36404, "speaker": "Realtor"},
  {"season": 6, "episode": 24, "scene": 32, "line": "[on Jo's jet] Are we going some place far away? I know you said no questions, but... I have an early dinner that I need to get to... with the Chief of Police.", "order": 36405, "speaker": "Michael Scott"},
  {"season": 6, "episode": 24, "scene": 33, "line": "I'm very relieved to learn it wasn't you.", "order": 36406, "speaker": "Creed Bratton"},
  {"season": 6, "episode": 24, "scene": 33, "line": "All right. Uh, Stan, you're up.", "order": 36407, "speaker": "Gabe Lewis"},
  {"season": 6, "episode": 24, "scene": 33, "line": "It wasn't me.", "order": 36408, "speaker": "Stanley Hudson"},
  {"season": 6, "episode": 24, "scene": 33, "line": "What a rich timbre your voice has. Okay, I am prepared to conclude the investigation. And... you did it. [nods at Andy]", "order": 36409, "speaker": "Gabe Lewis"},
  {"season": 6, "episode": 24, "scene": 33, "line": "What? Based on what?", "order": 36410, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 24, "scene": 33, "line": "Uh, just all the evidence. And it really seems like it was you. Can we all agree to say that it was Andy for now, and sit with it, see how it feels?", "order": 36411, "speaker": "Gabe Lewis"},
  {"season": 6, "episode": 24, "scene": 33, "line": "All right. Sounds good.", "order": 36412, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 24, "scene": 33, "line": "Guys, I think that seems a little unfair. I mean, I feel like we don't definitely know it was Andy.", "order": 36413, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 24, "scene": 33, "line": "Yeah. You know, I mean, for all we know it could have been... Jim.", "order": 36414, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 24, "scene": 33, "line": "Really?", "order": 36415, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 24, "scene": 34, "line": "Michael.", "order": 36416, "speaker": "Jo Bennett"},
  {"season": 6, "episode": 24, "scene": 34, "line": "I have rights.", "order": 36417, "speaker": "Michael Scott"},
  {"season": 6, "episode": 24, "scene": 34, "line": "Let's just talk.", "order": 36418, "speaker": "Jo Bennett"},
  {"season": 6, "episode": 24, "scene": 34, "line": "I am not going to tell you anything.", "order": 36419, "speaker": "Michael Scott"},
  {"season": 6, "episode": 24, "scene": 34, "line": "Honey, you don't seem like your normal self.", "order": 36420, "speaker": "Jo Bennett"},
  {"season": 6, "episode": 24, "scene": 34, "line": "Well, I'm going through a little bit of a rough patch.", "order": 36421, "speaker": "Michael Scott"},
  {"season": 6, "episode": 24, "scene": 34, "line": "Mama Jo knew there was something up.", "order": 36422, "speaker": "Jo Bennett"},
  {"season": 6, "episode": 24, "scene": 34, "line": "Whole year, actually. My favorite restaurant closed down.", "order": 36423, "speaker": "Michael Scott"},
  {"season": 6, "episode": 24, "scene": 34, "line": "Oh, I hate that.", "order": 36424, "speaker": "Jo Bennett"},
  {"season": 6, "episode": 24, "scene": 34, "line": "And my new favorite restaurant sucks... I bought a video camera last year, and I was looking at the tapes, and there were only like twelve minutes that I felt was worth taping the whole year. And most of that was just birds in my condo complex. What is that? ... I miss Holly.", "order": 36425, "speaker": "Michael Scott"},
  {"season": 6, "episode": 24, "scene": 34, "line": "Who's Holly?", "order": 36426, "speaker": "Jo Bennett"},
  {"season": 6, "episode": 24, "scene": 34, "line": "Holly Flax from the Nashua branch. Best HR rep that Dunder Mifflin has ever seen. It's not been a blockbuster year for me financially. My Blockbuster stock is down.", "order": 36427, "speaker": "Michael Scott"},
  {"season": 6, "episode": 24, "scene": 35, "line": "Well, I think that the real question is: Who is the whistleblower?", "order": 36428, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 24, "scene": 35, "line": "Oh, just...", "order": 36429, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 24, "scene": 36, "line": "I may have heard from an old client, and I may have immediately started spreading the news to other clients and potential clients, yeah. But I'm not here to talk about that. I am here to talk about Suck It. Suck it.", "order": 36430, "speaker": "David Wallace"},
  {"season": 6, "episode": 24, "scene": 37, "line": "Gabe, I told you all about the printers.", "order": 36431, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 24, "scene": 37, "line": "I mean, it could be you, Gabe. I mean, that's the point.", "order": 36432, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 24, "scene": 37, "line": "Yes, it's totally obvious.", "order": 36433, "speaker": "Pam Halpert"},
  {"season": 6, "episode": 24, "scene": 37, "line": "I think we all can agree that it's either Gabe or Angela. [flips a coin] It's Angela. Get her, boys.", "order": 36434, "speaker": "Creed Bratton"},
  {"season": 6, "episode": 24, "scene": 37, "line": "Hey guys, uh, sorry to interrupt. I just wanted to say goodbye to everyone. Through Teach for America, I'm going to go down to Detroit and teach, uh, inner city kids about computers.", "order": 36435, "speaker": "Nick"},
  {"season": 6, "episode": 24, "scene": 37, "line": "Uh, not now.", "order": 36436, "speaker": "Gabe Lewis"},
  {"season": 6, "episode": 24, "scene": 37, "line": "Oh yeah, it's just that my friends are in the car waiting, so I thought I would...", "order": 36437, "speaker": "Nick"},
  {"season": 6, "episode": 24, "scene": 37, "line": "[quietly] Phyllis, what's this guy's name again?", "order": 36438, "speaker": "Stanley Hudson"},
  {"season": 6, "episode": 24, "scene": 37, "line": "I don't know, is it Shadow or Garth, it's something weird, I...", "order": 36439, "speaker": "Phyllis Vance"},
  {"season": 6, "episode": 24, "scene": 37, "line": "My name is Nick.", "order": 36440, "speaker": "Nick"},
  {"season": 6, "episode": 24, "scene": 37, "line": "Okay, well, Nick, we're in a meeting.", "order": 36441, "speaker": "Angela Martin"},
  {"season": 6, "episode": 24, "scene": 37, "line": "Okay, look, I get it, people. I'm the lame IT guy, and everybody hates me.", "order": 36442, "speaker": "Nick"},
  {"season": 6, "episode": 24, "scene": 37, "line": "Hey, listen man, you can't take it personally.", "order": 36443, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 24, "scene": 37, "line": "You called me man? I just said my name just now, did you forget it already?", "order": 36444, "speaker": "Nick"},
  {"season": 6, "episode": 24, "scene": 37, "line": "No... sport.", "order": 36445, "speaker": "Jim Halpert"},
  {"season": 6, "episode": 24, "scene": 37, "line": "You, you guys have fifteen parties a week, you can't learn my name?", "order": 36446, "speaker": "Nick"},
  {"season": 6, "episode": 24, "scene": 37, "line": "Hey, hey, hey, hey, hey, hey, hey, hey IT guy. Here's the story, champ. None of us have spent a lot of time getting to know you, okay? We liked the last guy, Siddiq, because he kept to himself, and we also thought he might have been a terrorist. You know what, I'm going to leave you with one other thought. Inner city kids use computers for two things, games and porn. So good luck wasting your life, lurch.", "order": 36447, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 24, "scene": 37, "line": "Thank you so much for that. I saw all your hard drives, and guess what? [gestures to Ryan] You're not a photographer. [gestures to Kelly] And you definitely can't fit into a size two. Darryl, man, you're on Facebook. Why you been telling people you're not on Facebook? People want to be your friend, man! All right? And you. [points to Andy] This guy, you're the one who told the press. You wrote an e-mail to the editor. I saw it, and I also saw a QuickTime movie of your little printer fire test on your hard drive. This guy's the snitch, he's the snitch. So that's it, check it out. [gives the finger]", "order": 36448, "speaker": "Nick"},
  {"season": 6, "episode": 24, "scene": 37, "line": "You're going to believe that guy?", "order": 36449, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 24, "scene": 38, "line": "When I was growing up, there was nothing better than being a big old business tycoon. And I thought I'd break that glass ceiling and be a hero to all those little girls out there... and they'd make a Barbie out of me.", "order": 36450, "speaker": "Jo Bennett"},
  {"season": 6, "episode": 24, "scene": 38, "line": "Hmm, hmm.", "order": 36451, "speaker": "Michael Scott"},
  {"season": 6, "episode": 24, "scene": 38, "line": "I, did I sell cheap printers? I do. But if I have to go out there in front of the press and make one of them public apology recalls, I mean, it's all I'll ever be remembered for. Nobody will want to play with my Barbie.", "order": 36452, "speaker": "Jo Bennett"},
  {"season": 6, "episode": 24, "scene": 38, "line": "You know, I would be willing, under the right circumstances, to do that for you.", "order": 36453, "speaker": "Michael Scott"},
  {"season": 6, "episode": 24, "scene": 38, "line": "Oh honey, surely you don't want that.", "order": 36454, "speaker": "Jo Bennett"},
  {"season": 6, "episode": 24, "scene": 38, "line": "I, I surely do, and don't call me honey. [Jo laughs] You were playing too.", "order": 36455, "speaker": "Michael Scott"},
  {"season": 6, "episode": 24, "scene": 39, "line": "I'm about to buy this building, you know.", "order": 36456, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 24, "scene": 39, "line": "You don't say? I own a one-eighth share in a rental property down in Pittston.", "order": 36457, "speaker": "Hank Tate"},
  {"season": 6, "episode": 24, "scene": 39, "line": "Well, I'm one-eighths proud of you... enjoy that chair for now... 'cause pretty soon, you will be on your feet, at Buckingham Palace.", "order": 36458, "speaker": "Dwight Schrute"},
  {"season": 6, "episode": 24, "scene": 40, "line": "Hey Phyllis, have you seen my bag?", "order": 36459, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 24, "scene": 40, "line": "[points to the ceiling] You deserve it.", "order": 36460, "speaker": "Phyllis Vance"},
  {"season": 6, "episode": 24, "scene": 41, "line": "Look, I didn't want houses and schools to burn down, and children to die. Does that make me a hero? I...", "order": 36461, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 24, "scene": 41, "line": "[offscreen] No, it does not!", "order": 36462, "speaker": "Kevin Malone"},
  {"season": 6, "episode": 24, "scene": 41, "line": "Well, it doesn't make me the worst guy in the world, either.", "order": 36463, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 24, "scene": 41, "line": "It does!", "order": 36464, "speaker": "Angela Martin"},
  {"season": 6, "episode": 24, "scene": 42, "line": "Andy?", "order": 36465, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 24, "scene": 42, "line": "Hey.", "order": 36466, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 24, "scene": 42, "line": "I wanted to say that I think it was very brave of you to go to the press.", "order": 36467, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 24, "scene": 42, "line": "Uh, thanks. Yeah, you know, just seemed like the right thing to do.", "order": 36468, "speaker": "Andy Bernard"},
  {"season": 6, "episode": 24, "scene": 42, "line": "Yeah.", "order": 36469, "speaker": "Erin Hannon"},
  {"season": 6, "episode": 24, "scene": 43, "line": "[to press] We at Sabre have betrayed the trust that we have built with our customers. We regret our slow response and our lapse in candor and judgment. At this time, we are issuing a full recall of all Sabre GH400 printers. We will not rest until this problem is solved. There will be no questions. Are there any questions?", "order": 36470, "speaker": "Michael Scott"},
  {"season": 6, "episode": 24, "scene": 44, "line": "Hey, I appreciate you reading that statement. You looked pretty up there.", "order": 36471, "speaker": "Jo Bennett"},
  {"season": 6, "episode": 24, "scene": 44, "line": "That was fun.", "order": 36472, "speaker": "Michael Scott"},
  {"season": 6, "episode": 24, "scene": 44, "line": "I hope your rough patch ends soon.", "order": 36473, "speaker": "Jo Bennett"},
  {"season": 6, "episode": 24, "scene": 44, "line": "Thanks. Today helped.", "order": 36474, "speaker": "Michael Scott"},
  {"season": 6, "episode": 24, "scene": 44, "line": "Well, give me a shout if I can brighten your life.", "order": 36475, "speaker": "Jo Bennett"},
  {"season": 6, "episode": 24, "scene": 44, "line": "Okay. Hey, you could transfer Holly back from Nashua.", "order": 36476, "speaker": "Michael Scott"},
  {"season": 6, "episode": 24, "scene": 44, "line": "Let me see what I can do.", "order": 36477, "speaker": "Jo Bennett"}
]