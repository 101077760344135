import React from 'react'
import SearchIcon from '@material-ui/icons/Search'

type SearchBarProps = {
    searchPhrase: string
    setSearchPhrase: (value: string) => void
}

const SearchBar: React.FC<SearchBarProps> = (props) => {
    return (
        <div role="searchbox" className="relative flex self-center w-full border rounded-sm">
            <input
                id="searchbar"
                tabIndex={1}
                placeholder="Search..."
                type="text"
                onChange={(e) => props.setSearchPhrase(e.target.value)}
                value={props.searchPhrase}
                className="self-center w-full h-10 px-4 py-2 dark:text-gray-200 dark:bg-black"
            />
            <span className="absolute flex self-center bg-white dark:bg-black right-2">
                <SearchIcon className="self-center dark:text-gray-200" />
            </span>
        </div>
    )
}

export default SearchBar
