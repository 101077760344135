import { singleFuse } from '../utils/fuse'
import { useState, useEffect } from 'react'

const defaultState: SearchResult = {
    quotes: [],
    _metadata: {
        currentPage: 1,
        searchPhrase: '',
        resultCount: 0,
        pageCount: 0,
    },
}

const createInitialQuoteIdArray = (startNumber: number, previousCount: number, nextCount: number) => {
    let result = [startNumber]

    let previous = previousCount > 10 ? 10 : previousCount
    let next = nextCount > 10 ? 10 : nextCount

    for (let i = 1; i <= previous; i++) {
        result.splice(0, 0, startNumber - i)
    }

    for (let i = 1; i <= next; i++) {
        result.push(startNumber + i)
    }

    return result
}

const useQuote = (quoteIds: number[], previous: number, next: number) => {
    const initialQuoteIds = createInitialQuoteIdArray(quoteIds[0], previous, next)

    const [results, setResults] = useState(defaultState)
    const [ids, setIds] = useState([...initialQuoteIds])

    useEffect(() => {
        const quote = singleFuse.search(ids.map((i) => `=${i}`).join('|'))
        setResults({ ...defaultState, quotes: quote })
    }, [ids])

    const seePrevQuote = () => {
        const prevQuoteId: number = results.quotes[0].item.order - 1
        setIds([prevQuoteId, ...ids])
    }

    const seeNextQuote = () => {
        const nextQuoteId: number = results.quotes[results.quotes.length - 1].item.order + 1
        setIds([...ids, nextQuoteId])
    }
    return { quotes: results, seePrevQuote, seeNextQuote }
}

export default useQuote
