module.exports = [
  {"season": 4, "episode": 1, "scene": 1, "line": "Ok, well I did not get the job in New York, but I got the real prize, domestic bliss. Jan made me breakfast this morning... well she bought the milk. It's soy. [walks into bedroom, Jan sleeping on bed] This is why I do it, that's what I have to come home to. [sighs] She probably won't be up for a few hours.", "order": 15036, "speaker": "Michael Scott"},
  {"season": 4, "episode": 1, "scene": 2, "line": "This is going to be a very good year. Very good. Jan is at home. Jim is back. My protege Ryan is at corporate. Good stuff. Um... Andy and Dwight are rockin' the sales team. I feel very blessed. [slams on breaks, camera turns, Meredith rolls off the hood]", "order": 15037, "speaker": "Michael Scott"},
  {"season": 4, "episode": 1, "scene": 3, "line": "You know generally it's not a good idea to click on offers that you haven't requested. What was the exact offer?", "order": 15038, "speaker": "IT Tech Guy"},
  {"season": 4, "episode": 1, "scene": 3, "line": "It was for a video.", "order": 15039, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 1, "scene": 3, "line": "Yeah, what kind of video?", "order": 15040, "speaker": "IT Tech Guy"},
  {"season": 4, "episode": 1, "scene": 3, "line": "A celebrity sex tape.", "order": 15041, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 1, "scene": 3, "line": "Really, what kind of celebrity?", "order": 15042, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 1, "scene": 3, "line": "Not relevant.", "order": 15043, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 1, "scene": 3, "line": "How much did you pay for it?", "order": 15044, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 1, "scene": 3, "line": "Not relevant.", "order": 15045, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 1, "scene": 3, "line": "You paid for it?", "order": 15046, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 1, "scene": 3, "line": "It all happened so fast.", "order": 15047, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 1, "scene": 4, "line": "I broke up with Karen after the job interview, and uh... it was a little awkward when she came back from the city. She told me, very clearly, just because we were broken up didn't mean she was going anywhere, because she worked really hard for her career. But the next day her desk was empty, and as for me and my current romantic life, I uh... I'm single now and looking, so if you know anybody.", "order": 15048, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 1, "scene": 5, "line": "Jim and I went to dinner a few times when he got back from New York. I talked him through his break up. It's really nice to be good friends again.", "order": 15049, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 1, "scene": 6, "line": "Are you kidding me, Pam and Jim are totally hooking up. All they do is smile; they're just keeping it a secret. Right? [looks at Oscar]", "order": 15050, "speaker": "Kevin Malone"},
  {"season": 4, "episode": 1, "scene": 6, "line": "I don't know, there is no evidence of intimacy. They've been in remarkably good moods. It could be other things.", "order": 15051, "speaker": "Oscar Martinez"},
  {"season": 4, "episode": 1, "scene": 6, "line": "Are you kidding me?", "order": 15052, "speaker": "Kevin Malone"},
  {"season": 4, "episode": 1, "scene": 7, "line": "And Sunday I'm thinking of going to that flea-market at the drive-in.", "order": 15053, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 1, "scene": 7, "line": "Oh that sounds fun. I'm mountain biking on Sunday. On Montage Mountain.", "order": 15054, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 1, "scene": 7, "line": "Cool.", "order": 15055, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 1, "scene": 7, "line": "Yeah.", "order": 15056, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 1, "scene": 7, "line": "Well have fun with that.", "order": 15057, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 1, "scene": 8, "line": "Ladies and Gentlemen. I have some bad news. Meredith was hit by a car.", "order": 15058, "speaker": "Michael Scott"},
  {"season": 4, "episode": 1, "scene": 8, "line": "What?", "order": 15059, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 1, "scene": 8, "line": "Where?", "order": 15060, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 1, "scene": 8, "line": "It happened this morning in the parking lot. I took her to the hospital, and the doctors tried to save her life. They did the best that they could... And she is going to be OK.", "order": 15061, "speaker": "Michael Scott"},
  {"season": 4, "episode": 1, "scene": 8, "line": "What is wrong with you? Why did you have to phrase it like that?", "order": 15062, "speaker": "Stanley Hudson"},
  {"season": 4, "episode": 1, "scene": 8, "line": "So she's really going to be fine?", "order": 15063, "speaker": "Oscar Martinez"},
  {"season": 4, "episode": 1, "scene": 8, "line": "Yes. She has a slight pelvical fracture, but, ah... people have survived far worse.", "order": 15064, "speaker": "Michael Scott"},
  {"season": 4, "episode": 1, "scene": 8, "line": "Thank God you were there.", "order": 15065, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 1, "scene": 8, "line": "Yeah.", "order": 15066, "speaker": "Michael Scott"},
  {"season": 4, "episode": 1, "scene": 8, "line": "Did you see who did it?", "order": 15067, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 1, "scene": 8, "line": "No need we can just check the security tapes.", "order": 15068, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 1, "scene": 8, "line": "Gah. Kind of a good news bad news there. I was able to be on the scene so quickly because I was in the car that hit her.", "order": 15069, "speaker": "Michael Scott"},
  {"season": 4, "episode": 1, "scene": 8, "line": "Who was driving?", "order": 15070, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 1, "scene": 8, "line": "Oh, Michael.", "order": 15071, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 1, "scene": 9, "line": "One day Michael came in complaining about a speed bump on the highway. I wonder who he ran over then.", "order": 15072, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 1, "scene": 10, "line": "It's only Meredith.", "order": 15073, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 1, "scene": 10, "line": "Yeah, it's only Meredith, thank God. But did you see the way they looked at me? Like I was a murderer or something.", "order": 15074, "speaker": "Michael Scott"},
  {"season": 4, "episode": 1, "scene": 10, "line": "Hey... Why did you do it?", "order": 15075, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 1, "scene": 10, "line": "It was an accident.", "order": 15076, "speaker": "Michael Scott"},
  {"season": 4, "episode": 1, "scene": 10, "line": "Was she talkin' back?", "order": 15077, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 1, "scene": 10, "line": "No.", "order": 15078, "speaker": "Michael Scott"},
  {"season": 4, "episode": 1, "scene": 10, "line": "Did you get sick of that face? Did she owe you money? Uh-oh. Is this downsizing? Did she spurn your advances?", "order": 15079, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 1, "scene": 11, "line": "Hey guys, we're all gonna visit Meredith at lunch. And we're kicking in $5 for flowers.", "order": 15080, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 1, "scene": 11, "line": "Who's we, you and Jim?", "order": 15081, "speaker": "Kevin Malone"},
  {"season": 4, "episode": 1, "scene": 11, "line": "No, uh, me Stanley and Phyllis so far.", "order": 15082, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 1, "scene": 11, "line": "Oh, I bet Jim goes too.", "order": 15083, "speaker": "Kevin Malone"},
  {"season": 4, "episode": 1, "scene": 11, "line": "Yeah, I haven't asked him yet.", "order": 15084, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 1, "scene": 11, "line": "Oh, I bet you ask?", "order": 15085, "speaker": "Kevin Malone"},
  {"season": 4, "episode": 1, "scene": 11, "line": "I was planning on it.", "order": 15086, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 1, "scene": 11, "line": "I bet you were.", "order": 15087, "speaker": "Kevin Malone"},
  {"season": 4, "episode": 1, "scene": 11, "line": "Angela?", "order": 15088, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 1, "scene": 11, "line": "[whispers to Kevin] Subtle.", "order": 15089, "speaker": "Oscar Martinez"},
  {"season": 4, "episode": 1, "scene": 11, "line": "What", "order": 15090, "speaker": "Kevin Malone"},
  {"season": 4, "episode": 1, "scene": 11, "line": "[to Angela] Are you coming?", "order": 15091, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 1, "scene": 11, "line": "I can't, Sprinkles is sick.", "order": 15092, "speaker": "Angela Martin"},
  {"season": 4, "episode": 1, "scene": 12, "line": "She's been sick for some time. Thank you for asking, no one asks... about Sprinkles.", "order": 15093, "speaker": "Angela Martin"},
  {"season": 4, "episode": 1, "scene": 13, "line": "I have to give her her meds, I have to pet her, and who will she eat lunch with?", "order": 15094, "speaker": "Angela Martin"},
  {"season": 4, "episode": 1, "scene": 13, "line": "Can't your other cats keep her company.", "order": 15095, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 1, "scene": 13, "line": "There's bad blood, jealousies, cliques.", "order": 15096, "speaker": "Angela Martin"},
  {"season": 4, "episode": 1, "scene": 13, "line": "Angela, you're the chairman of the party planning committee. I shouldn't even be planning this, it's your job.", "order": 15097, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 1, "scene": 13, "line": "[sighs] All right!", "order": 15098, "speaker": "Angela Martin"},
  {"season": 4, "episode": 1, "scene": 14, "line": "My lord my liege.", "order": 15099, "speaker": "Michael Scott"},
  {"season": 4, "episode": 1, "scene": 14, "line": "Yes Michael?", "order": 15100, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 1, "scene": 15, "line": "So Ryan got promoted to corporate, where he is a little fish in a big pond. Whereas back here in Scranton I am still top dog, in a fairly large pond. So who is the real boss? The dog or a fish?", "order": 15101, "speaker": "Michael Scott"},
  {"season": 4, "episode": 1, "scene": 16, "line": "So I need a little treat for the gang. Something to win their affections back.", "order": 15102, "speaker": "Michael Scott"},
  {"season": 4, "episode": 1, "scene": 16, "line": "Back? Why is that Michael?", "order": 15103, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 1, "scene": 16, "line": "Well, I ran down Meredith in my car.", "order": 15104, "speaker": "Michael Scott"},
  {"season": 4, "episode": 1, "scene": 16, "line": "Oh! Did you do this on purpose?", "order": 15105, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 1, "scene": 16, "line": "No, I was being negligent. But she's in the hospital, she's fine, recovering nicely. Tiny little crack in her pelvis. But she will be up in...", "order": 15106, "speaker": "Michael Scott"},
  {"season": 4, "episode": 1, "scene": 16, "line": "Did this happen on company property?", "order": 15107, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 1, "scene": 16, "line": "Yes. It was on company property, with company property, so... double jeopardy, we are fine.", "order": 15108, "speaker": "Michael Scott"},
  {"season": 4, "episode": 1, "scene": 16, "line": "I don't think you understand how jeopardy works.", "order": 15109, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 1, "scene": 16, "line": "Oh, right, I'm sorry. What is: we are fine?", "order": 15110, "speaker": "Michael Scott"},
  {"season": 4, "episode": 1, "scene": 16, "line": "[sigh]", "order": 15111, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 1, "scene": 17, "line": "People keep calling me a 'Wunderkind'; I don't even know what that means. I mean, I know what it means, it means very successful for your age, so I guess it makes sense, but... it's a weird word.", "order": 15112, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 1, "scene": 18, "line": "Hey D.", "order": 15113, "speaker": "Angela Martin"},
  {"season": 4, "episode": 1, "scene": 18, "line": "Hey monkey, what's up?", "order": 15114, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 1, "scene": 18, "line": "Can you do me a little favor? Go to my place at lunch and give Sprinkles her medicine.", "order": 15115, "speaker": "Angela Martin"},
  {"season": 4, "episode": 1, "scene": 18, "line": "Sure.", "order": 15116, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 1, "scene": 18, "line": "I have to visit the alchy.", "order": 15117, "speaker": "Angela Martin"},
  {"season": 4, "episode": 1, "scene": 18, "line": "Check to see if she's faking. If a car hit me, it wouldn't crack my pelvis. You know what; I bet she cracked it at home. Jumped in front of the car to get some workers comp.", "order": 15118, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 1, "scene": 18, "line": "I wouldn't put it past her.", "order": 15119, "speaker": "Angela Martin"},
  {"season": 4, "episode": 1, "scene": 18, "line": "So what do you need me to do?", "order": 15120, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 1, "scene": 18, "line": "I wrote it out.", "order": 15121, "speaker": "Angela Martin"},
  {"season": 4, "episode": 1, "scene": 18, "line": "Mm-hmm.", "order": 15122, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 1, "scene": 18, "line": "There's a diabetes shot, roll the insulin in your hand, don't shake it. She gets an ace inhibitor with her meal, but you have to put her right in front of the dish or she won't see it because of the cataracts. Mix one capsule of omega fatty acid in with her kidney medicine, um... and you want to give that to her 15 minutes after she's eaten. And, oh and there's a fungal cream because she has this infection under her tail, so you're gonna have to lift her tail and put the cream right at the base of her tail.", "order": 15123, "speaker": "Angela Martin"},
  {"season": 4, "episode": 1, "scene": 19, "line": "Ok, I have an announcement.", "order": 15124, "speaker": "Michael Scott"},
  {"season": 4, "episode": 1, "scene": 19, "line": "You pushed Darryl out the window?", "order": 15125, "speaker": "Oscar Martinez"},
  {"season": 4, "episode": 1, "scene": 19, "line": "No.", "order": 15126, "speaker": "Michael Scott"},
  {"season": 4, "episode": 1, "scene": 19, "line": "You shot Dwight?", "order": 15127, "speaker": "Phyllis Vance"},
  {"season": 4, "episode": 1, "scene": 19, "line": "No! That is not funny, I love my employees, even thought I hit one of you with my car. For which I take full responsibility. Look I'm just trying to take everybody's mind off of this unavoidable tragedy, and onto more positive things. So I thought we should plant a tree.", "order": 15128, "speaker": "Michael Scott"},
  {"season": 4, "episode": 1, "scene": 19, "line": "Oh good, so we don't have to work.", "order": 15129, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 1, "scene": 19, "line": "OK, we're leaving for the hospital at 1.", "order": 15130, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 1, "scene": 19, "line": "So, like a freedom tree.", "order": 15131, "speaker": "Michael Scott"},
  {"season": 4, "episode": 1, "scene": 19, "line": "I can take 3 people.", "order": 15132, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 1, "scene": 19, "line": "I can also take 3 people.", "order": 15133, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 1, "scene": 19, "line": "[to Kevin] Separate cars.", "order": 15134, "speaker": "Oscar Martinez"},
  {"season": 4, "episode": 1, "scene": 19, "line": "Pam.", "order": 15135, "speaker": "Michael Scott"},
  {"season": 4, "episode": 1, "scene": 19, "line": "Alright, I will get a card at the hospital and we'll sign it outside her room.", "order": 15136, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 1, "scene": 19, "line": "Pam.", "order": 15137, "speaker": "Michael Scott"},
  {"season": 4, "episode": 1, "scene": 19, "line": "Sales people can go later in the day. Hourly works can go at lunch.", "order": 15138, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 1, "scene": 19, "line": "Ok, good good, so we'll just all go down there together at lunch.", "order": 15139, "speaker": "Michael Scott"},
  {"season": 4, "episode": 1, "scene": 19, "line": "I...", "order": 15140, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 1, "scene": 19, "line": "Excellent!", "order": 15141, "speaker": "Michael Scott"},
  {"season": 4, "episode": 1, "scene": 19, "line": "I was thinking that we...", "order": 15142, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 1, "scene": 19, "line": "Good work Pam.", "order": 15143, "speaker": "Michael Scott"},
  {"season": 4, "episode": 1, "scene": 19, "line": "But...", "order": 15144, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 1, "scene": 19, "line": "Yaaaaaay, Pam! Alright, since I am the boss I will drive as well. Who wants shotgun?", "order": 15145, "speaker": "Michael Scott"},
  {"season": 4, "episode": 1, "scene": 19, "line": "You can't be serious. You ran a woman over this morning.", "order": 15146, "speaker": "Stanley Hudson"},
  {"season": 4, "episode": 1, "scene": 19, "line": "Everyone inside the car was fine, Stanley!", "order": 15147, "speaker": "Michael Scott"},
  {"season": 4, "episode": 1, "scene": 20, "line": "Guess what, I have flaws. What are they? Oh, I don't know. I sing in the shower. Sometimes I spend too much time volunteering. Occasionally I'll hit somebody with my car. So sue me... No, don't sue me. That is the opposite of the point that I'm trying to make.", "order": 15148, "speaker": "Michael Scott"},
  {"season": 4, "episode": 1, "scene": 21, "line": "Eww. I hate hospitals. In my mind they are associated with sickness. Oh... She looks like an angel.", "order": 15149, "speaker": "Michael Scott"},
  {"season": 4, "episode": 1, "scene": 21, "line": "She looks awful.", "order": 15150, "speaker": "Kelly Kapoor"},
  {"season": 4, "episode": 1, "scene": 21, "line": "No... OK, she always looks like that... That is not my fault.", "order": 15151, "speaker": "Michael Scott"},
  {"season": 4, "episode": 1, "scene": 21, "line": "I think she's awake.", "order": 15152, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 1, "scene": 21, "line": "No... She's in a coma.", "order": 15153, "speaker": "Michael Scott"},
  {"season": 4, "episode": 1, "scene": 21, "line": "No.", "order": 15154, "speaker": "Nurse"},
  {"season": 4, "episode": 1, "scene": 21, "line": "OK... Meredith, [hauntingly] Ooooohhhhh. I brought all your friends from the office dear.", "order": 15155, "speaker": "Michael Scott"},
  {"season": 4, "episode": 1, "scene": 21, "line": "At the same time.", "order": 15156, "speaker": "Meredith Palmer"},
  {"season": 4, "episode": 1, "scene": 21, "line": "Yeah.", "order": 15157, "speaker": "Michael Scott"},
  {"season": 4, "episode": 1, "scene": 21, "line": "Hello Meredith.", "order": 15158, "speaker": "Stanley Hudson"},
  {"season": 4, "episode": 1, "scene": 21, "line": "This is weird.", "order": 15159, "speaker": "Meredith Palmer"},
  {"season": 4, "episode": 1, "scene": 21, "line": "Brought you some balloons. Why don't we... here you go. [wraps them around her IV] Tie these up, cheer up your tubes... [IV pops out] Oh! Shhhh... For God's sake!", "order": 15160, "speaker": "Michael Scott"},
  {"season": 4, "episode": 1, "scene": 21, "line": "Whoa!", "order": 15161, "speaker": "Kevin Malone"},
  {"season": 4, "episode": 1, "scene": 21, "line": "Nurse.", "order": 15162, "speaker": "Michael Scott"},
  {"season": 4, "episode": 1, "scene": 21, "line": "No don't bother the nurse, just put it back in.", "order": 15163, "speaker": "Meredith Palmer"},
  {"season": 4, "episode": 1, "scene": 21, "line": "[groaning] I am going to be sick... I'm gonna puke.", "order": 15164, "speaker": "Michael Scott"},
  {"season": 4, "episode": 1, "scene": 21, "line": "I wouldn't... I wouldn't worry about it. Don't touch it.", "order": 15165, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 1, "scene": 21, "line": "What, what are you doing? Just gimme that.", "order": 15166, "speaker": "Nurse"},
  {"season": 4, "episode": 1, "scene": 21, "line": "Thanks.", "order": 15167, "speaker": "Meredith Palmer"},
  {"season": 4, "episode": 1, "scene": 21, "line": "[applauds]", "order": 15168, "speaker": "Kevin Malone"},
  {"season": 4, "episode": 1, "scene": 21, "line": "Does it hurt terribly?", "order": 15169, "speaker": "Phyllis Vance"},
  {"season": 4, "episode": 1, "scene": 21, "line": "No, it's not too bad. They have me on a lot of painkillers.", "order": 15170, "speaker": "Meredith Palmer"},
  {"season": 4, "episode": 1, "scene": 21, "line": "Oh really, what kind? Codeine, Vicodin, Percocet, Fentanyl, Oxycontin, Palladone? What...", "order": 15171, "speaker": "Creed Bratton"},
  {"season": 4, "episode": 1, "scene": 21, "line": "I have no idea.", "order": 15172, "speaker": "Meredith Palmer"},
  {"season": 4, "episode": 1, "scene": 21, "line": "Oh. [laughs]", "order": 15173, "speaker": "Creed Bratton"},
  {"season": 4, "episode": 1, "scene": 21, "line": "Well it was really great of all of you to come and visit me at the same time. I'll see you guys at the office.", "order": 15174, "speaker": "Meredith Palmer"},
  {"season": 4, "episode": 1, "scene": 21, "line": "Oh, OK... Wait up, wait up, guys guys guys, hold on a second. You know what I was thinking might be sort of fun? Is if you forgave me in front of everybody.", "order": 15175, "speaker": "Michael Scott"},
  {"season": 4, "episode": 1, "scene": 21, "line": "Michael, I'm not gonna do that.", "order": 15176, "speaker": "Meredith Palmer"},
  {"season": 4, "episode": 1, "scene": 21, "line": "Cause you know what they say in the Bible about forgiveness? Forgiveness is next to Godliness.", "order": 15177, "speaker": "Michael Scott"},
  {"season": 4, "episode": 1, "scene": 21, "line": "No, that's not. That's next to cleanliness.", "order": 15178, "speaker": "Angela Martin"},
  {"season": 4, "episode": 1, "scene": 21, "line": "Well, just shhhh... just just...", "order": 15179, "speaker": "Michael Scott"},
  {"season": 4, "episode": 1, "scene": 21, "line": "You cracked my pelvis", "order": 15180, "speaker": "Meredith Palmer"},
  {"season": 4, "episode": 1, "scene": 21, "line": "Look, I just... I don't understand what is preventing you from laughing this off and giving me a big hug.", "order": 15181, "speaker": "Michael Scott"},
  {"season": 4, "episode": 1, "scene": 21, "line": "You're not forgiven.", "order": 15182, "speaker": "Meredith Palmer"},
  {"season": 4, "episode": 1, "scene": 21, "line": "Come on. [starts to climb onto the bed with meredith.", "order": 15183, "speaker": "Michael Scott"},
  {"season": 4, "episode": 1, "scene": 21, "line": "Michael! Michael!", "order": 15184, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 1, "scene": 21, "line": "[screams]", "order": 15185, "speaker": "Meredith Palmer"},
  {"season": 4, "episode": 1, "scene": 22, "line": "Do I need to be liked? Absolutely not. I like to be liked. I enjoy being liked. I have to be liked. But it's not like a compulsive need to be liked. Like my need to be praised.", "order": 15186, "speaker": "Michael Scott"},
  {"season": 4, "episode": 1, "scene": 23, "line": "Hey.", "order": 15187, "speaker": "Angela Martin"},
  {"season": 4, "episode": 1, "scene": 23, "line": "Hey monkey.", "order": 15188, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 1, "scene": 23, "line": "Any problems?", "order": 15189, "speaker": "Angela Martin"},
  {"season": 4, "episode": 1, "scene": 23, "line": "Well you left the TV on, and your cat is dead.", "order": 15190, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 1, "scene": 23, "line": "What!?", "order": 15191, "speaker": "Angela Martin"},
  {"season": 4, "episode": 1, "scene": 23, "line": "Sparkles, the white one, is dead.", "order": 15192, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 1, "scene": 23, "line": "Sprinkles.", "order": 15193, "speaker": "Angela Martin"},
  {"season": 4, "episode": 1, "scene": 23, "line": "That was the sick one, right?", "order": 15194, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 1, "scene": 23, "line": "Uh-huh. But I thought she had more time.", "order": 15195, "speaker": "Angela Martin"},
  {"season": 4, "episode": 1, "scene": 23, "line": "No.", "order": 15196, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 1, "scene": 23, "line": "Did she look... When you saw her how was she looking?", "order": 15197, "speaker": "Angela Martin"},
  {"season": 4, "episode": 1, "scene": 23, "line": "Really dead. Like a... just a dead cat.", "order": 15198, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 1, "scene": 23, "line": "[sobbing]", "order": 15199, "speaker": "Angela Martin"},
  {"season": 4, "episode": 1, "scene": 23, "line": "So... Hey come on, don't be sad, just... OK... just. She's in a better place.", "order": 15200, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 1, "scene": 23, "line": "Alright.", "order": 15201, "speaker": "Angela Martin"},
  {"season": 4, "episode": 1, "scene": 23, "line": "Actually the place that she's in is the freezer, because of the odor.", "order": 15202, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 1, "scene": 23, "line": "[still sobbing]", "order": 15203, "speaker": "Angela Martin"},
  {"season": 4, "episode": 1, "scene": 24, "line": "[to Angela] It's gonna be OK.", "order": 15204, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 1, "scene": 24, "line": "OK, you know what? Everybody, let's just get over the whole Meredith thing. She cracked her pelvis, a tiny little crack. She going to be fine, so let's just...", "order": 15205, "speaker": "Michael Scott"},
  {"season": 4, "episode": 1, "scene": 24, "line": "Michael, Angela's cat died.", "order": 15206, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 1, "scene": 24, "line": "Sprinkles?", "order": 15207, "speaker": "Michael Scott"},
  {"season": 4, "episode": 1, "scene": 24, "line": "[nods]", "order": 15208, "speaker": "Angela Martin"},
  {"season": 4, "episode": 1, "scene": 24, "line": "[sighs] Oh, sh... I'm sorry Angela. Man what a day huh? How could it get any worse? Her computer crashes with the porn, and then Meredith with the accident, and then... Sprinkles! God, that's 3 things. I'll tell you what's going on. This office is cursed, and we need to do something about it.", "order": 15209, "speaker": "Michael Scott"},
  {"season": 4, "episode": 1, "scene": 25, "line": "Well, I am taking responsibility. It is up to me to get rid of the curse that hit Meredith with my car. I'm not superstitious, but... I'm... I am a little-stitious.", "order": 15210, "speaker": "Michael Scott"},
  {"season": 4, "episode": 1, "scene": 26, "line": "Did anyone do anything involving an Indian burial ground?", "order": 15211, "speaker": "Michael Scott"},
  {"season": 4, "episode": 1, "scene": 26, "line": "Like what?", "order": 15212, "speaker": "Oscar Martinez"},
  {"season": 4, "episode": 1, "scene": 26, "line": "Like park on it. Or dig up a body... Toby? Anything you want to tell us?", "order": 15213, "speaker": "Michael Scott"},
  {"season": 4, "episode": 1, "scene": 26, "line": "No, I did not violate an Indian burial ground. In fact I had some good luck recently, Alfredo's Pizza, picked my business card out of the basket, so... uh, I got a week of free pies.", "order": 15214, "speaker": "Toby Flenderson"},
  {"season": 4, "episode": 1, "scene": 26, "line": "That's cool.", "order": 15215, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 1, "scene": 26, "line": "Yeah.", "order": 15216, "speaker": "Toby Flenderson"},
  {"season": 4, "episode": 1, "scene": 26, "line": "Perfect. So our tragedy is your good luck. Satan.", "order": 15217, "speaker": "Michael Scott"},
  {"season": 4, "episode": 1, "scene": 26, "line": "Sprinkles never hurt a soul. God in your infinite wisdom how could you do this? She wasn't ready. She had so much left to accomplish.", "order": 15218, "speaker": "Angela Martin"},
  {"season": 4, "episode": 1, "scene": 26, "line": "She's only a cat.", "order": 15219, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 1, "scene": 26, "line": "You never... you don't like them.", "order": 15220, "speaker": "Angela Martin"},
  {"season": 4, "episode": 1, "scene": 26, "line": "Cats do not provide milk or wool or meat.", "order": 15221, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 1, "scene": 26, "line": "Dwight please!", "order": 15222, "speaker": "Oscar Martinez"},
  {"season": 4, "episode": 1, "scene": 26, "line": "Dwight, you haven't seen Meredith yet, have you?", "order": 15223, "speaker": "Michael Scott"},
  {"season": 4, "episode": 1, "scene": 26, "line": "No, I have not.", "order": 15224, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 1, "scene": 26, "line": "Well, I think you should go to the hospital, and pay your respects.", "order": 15225, "speaker": "Michael Scott"},
  {"season": 4, "episode": 1, "scene": 26, "line": "I do not respect her, but I will go.", "order": 15226, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 1, "scene": 26, "line": "Alright, I would now like to talk about each of your individual religious beliefs.", "order": 15227, "speaker": "Michael Scott"},
  {"season": 4, "episode": 1, "scene": 26, "line": "Oh, Michael, you can't ask about religious beliefs...", "order": 15228, "speaker": "Toby Flenderson"},
  {"season": 4, "episode": 1, "scene": 26, "line": "Satan is a master of lies. Everything he says is the opposite.", "order": 15229, "speaker": "Michael Scott"},
  {"season": 4, "episode": 1, "scene": 26, "line": "Alright, well then you can ask about religious beliefs.", "order": 15230, "speaker": "Toby Flenderson"},
  {"season": 4, "episode": 1, "scene": 26, "line": "Thank you for the permission. Psych! Alright let's just go around the room, and tell me what you believe in.", "order": 15231, "speaker": "Michael Scott"},
  {"season": 4, "episode": 1, "scene": 26, "line": "I'm... um, Catholic.", "order": 15232, "speaker": "Stanley Hudson"},
  {"season": 4, "episode": 1, "scene": 26, "line": "OK.", "order": 15233, "speaker": "Michael Scott"},
  {"season": 4, "episode": 1, "scene": 26, "line": "Presbyterian.", "order": 15234, "speaker": "Darryl Philbin"},
  {"season": 4, "episode": 1, "scene": 26, "line": "Alright.", "order": 15235, "speaker": "Michael Scott"},
  {"season": 4, "episode": 1, "scene": 26, "line": "Oh me too.", "order": 15236, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 1, "scene": 26, "line": "Oh?", "order": 15237, "speaker": "Darryl Philbin"},
  {"season": 4, "episode": 1, "scene": 26, "line": "[puts hand up for high five] Same religion.", "order": 15238, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 1, "scene": 26, "line": "Alright! [high fives Pam]", "order": 15239, "speaker": "Darryl Philbin"},
  {"season": 4, "episode": 1, "scene": 26, "line": "I'm a Lutheran and Bob's a Unitarian. It keeps things spicy.", "order": 15240, "speaker": "Phyllis Vance"},
  {"season": 4, "episode": 1, "scene": 26, "line": "That's why we're cursed.", "order": 15241, "speaker": "Angela Martin"},
  {"season": 4, "episode": 1, "scene": 27, "line": "I've been involved in a number of cults, both as a leader and a follower. You have more fun as a follower, but you make more money as a leader.", "order": 15242, "speaker": "Creed Bratton"},
  {"season": 4, "episode": 1, "scene": 28, "line": "Kelly, you are Hindu, so you believe in Buddha.", "order": 15243, "speaker": "Michael Scott"},
  {"season": 4, "episode": 1, "scene": 28, "line": "That's Buddhist.", "order": 15244, "speaker": "Kelly Kapoor"},
  {"season": 4, "episode": 1, "scene": 28, "line": "Are you sure?", "order": 15245, "speaker": "Michael Scott"},
  {"season": 4, "episode": 1, "scene": 28, "line": "No.", "order": 15246, "speaker": "Kelly Kapoor"},
  {"season": 4, "episode": 1, "scene": 28, "line": "What are you?", "order": 15247, "speaker": "Michael Scott"},
  {"season": 4, "episode": 1, "scene": 28, "line": "Well if you're going to reduce my identity to my religion then I'm Sikh, but I also like Hip-hop and NPR, and I'm restoring a 1967 Corvette in my spare time.", "order": 15248, "speaker": "IT Tech Guy"},
  {"season": 4, "episode": 1, "scene": 28, "line": "OK, 1 Sikh, and...", "order": 15249, "speaker": "Michael Scott"},
  {"season": 4, "episode": 1, "scene": 29, "line": "As a farmer, I know that when an animal is sick, sometimes the right thing to do is to put it out of its misery. With the electricity we're using to keep Meredith alive we could power a small fan for 2 days. You tell me what's unethical.", "order": 15250, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 1, "scene": 30, "line": "Blink once if you want me to pull the plug.", "order": 15251, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 1, "scene": 30, "line": "Don't pull any plugs.", "order": 15252, "speaker": "Meredith Palmer"},
  {"season": 4, "episode": 1, "scene": 30, "line": "How are you doing Ms. Palmer?", "order": 15253, "speaker": "Intern"},
  {"season": 4, "episode": 1, "scene": 30, "line": "Better.", "order": 15254, "speaker": "Meredith Palmer"},
  {"season": 4, "episode": 1, "scene": 30, "line": "Excellent.", "order": 15255, "speaker": "Intern"},
  {"season": 4, "episode": 1, "scene": 30, "line": "Are you a doctor, or a male nurse?", "order": 15256, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 1, "scene": 30, "line": "Um... I'm an intern, which makes me a doctor, but...", "order": 15257, "speaker": "Intern"},
  {"season": 4, "episode": 1, "scene": 30, "line": "Pfft... Her chart doesn't indicate that she had a hysterectomy but she did, or at least she got time off for one.", "order": 15258, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 1, "scene": 30, "line": "Ah, so uh... so that is where her uterus went... Um, Ms. Palmer, your last tetanus shot was when you were bitten by a bat a few months ago?", "order": 15259, "speaker": "Intern"},
  {"season": 4, "episode": 1, "scene": 30, "line": "Yes. Dwight here, trapped it in a bag against my head.", "order": 15260, "speaker": "Meredith Palmer"},
  {"season": 4, "episode": 1, "scene": 30, "line": "Just doing my job.", "order": 15261, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 1, "scene": 30, "line": "It also says you were recently bitten by a raccoon?", "order": 15262, "speaker": "Intern"},
  {"season": 4, "episode": 1, "scene": 30, "line": "And a rat. Separate occasions.", "order": 15263, "speaker": "Meredith Palmer"},
  {"season": 4, "episode": 1, "scene": 31, "line": "You spend your whole life trying to get people to like you, and then you run over one person with your car. [sighs] And it's not even one of the popular ones, and everybody gets on your case. Doesn't make any sense... God is dead.", "order": 15264, "speaker": "Michael Scott"},
  {"season": 4, "episode": 1, "scene": 31, "line": "If there was a God then Ryan and I would be married by now.", "order": 15265, "speaker": "Kelly Kapoor"},
  {"season": 4, "episode": 1, "scene": 31, "line": "Maybe believing in God was the mistake. What did people believe in before? The sun? Maybe there's some sort of animal, that we could make a sacrifice too. Like a giant buffalo, or some sort of monster, like something with the body of a walrus with the head of a sea lion. Or something with the body of an egret with the head of a meerkat. Or just... the head of a monkey, with the antlers of a reindeer, with, ah... the body of a porcupine.", "order": 15266, "speaker": "Michael Scott"},
  {"season": 4, "episode": 1, "scene": 31, "line": "I will do some research.", "order": 15267, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 1, "scene": 31, "line": "I can help you with that.", "order": 15268, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 1, "scene": 32, "line": "So, just to be safe they are giving her the rabies vaccine.", "order": 15269, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 1, "scene": 32, "line": "Oh God, Rabies?", "order": 15270, "speaker": "Michael Scott"},
  {"season": 4, "episode": 1, "scene": 32, "line": "Mm-hmm.", "order": 15271, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 1, "scene": 32, "line": "I was in the hospital room with her. How contagious is that, is that like an STD?", "order": 15272, "speaker": "Michael Scott"},
  {"season": 4, "episode": 1, "scene": 32, "line": "No, no. You've gotta be bitten by something.", "order": 15273, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 1, "scene": 32, "line": "This place is so cursed.", "order": 15274, "speaker": "Michael Scott"},
  {"season": 4, "episode": 1, "scene": 32, "line": "Actually the doctor said it was lucky she came in to the hospital, cause the only way to beat rabies, is to start treatment before the symptoms set in... Lock jaw.", "order": 15275, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 1, "scene": 32, "line": "[walks out into the office] I know a lot of you are upset with me for endangering Meredith's life by hitting her, with my car. But it may make you feel a little better to know that before that happened, Dwight endangered her life, by putting a garbage bag over her head that had a bat in it.", "order": 15276, "speaker": "Michael Scott"},
  {"season": 4, "episode": 1, "scene": 32, "line": "Six of one, really.", "order": 15277, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 1, "scene": 32, "line": "Turns out Meredith has been exposed to rabies. Which is like 10 times worse than a little crack in your pelvis. Thanks to me she went to the hospital and I saved her life. Curse is broken. Curse is broken people! [Kevin claps] Oh... there is a God, and he has a plan for us after all. So go home get some rest, very very good work today. We got a lot accomplished.", "order": 15278, "speaker": "Michael Scott"},
  {"season": 4, "episode": 1, "scene": 33, "line": "Is there a God? If not, what are all the churches for? And who is Jesus' dad?", "order": 15279, "speaker": "Michael Scott"},
  {"season": 4, "episode": 1, "scene": 34, "line": "[Pam walks out of the office into the parking lot alone. Kevin pops up from the back seat of his car as if spying] Oh well, if they aren't together now, then they probably never will be. I thought they'd be good together, like PB&J. Pam Beasley and Jim. What a waste. What - A - Waste!", "order": 15280, "speaker": "Kevin Malone"},
  {"season": 4, "episode": 1, "scene": 34, "line": "[Pam's car pulls out of parking lot and then off to the side of the road] I told you I'm not dating anyone. And even if I was, I don't think it's anyone's business. I mean, when I do fall in love, like when it's for real, the last person I'm gonna talk about it too is a camera crew, or my co-workers. [Jim gets into the passenger side] Almost marrying Roy Anderson was as close to Pamela Anderson as I ever want to be. [Jim leans over and kisses Pam] Trust me, when I fall in love, you'll know.", "order": 15281, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 1, "scene": 35, "line": "Michael Scott's Dunder-Mifflin, Scranton, Meredith Palmer memorial, celebrity rabies awareness, fun run race for the cure, this is Pam.", "order": 15282, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 1, "scene": 35, "line": "Pro-Am.", "order": 15283, "speaker": "Michael Scott"},
  {"season": 4, "episode": 1, "scene": 35, "line": "Pro-Am race for the... They hung up.", "order": 15284, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 1, "scene": 36, "line": "A woman shouldn't have to be hit by a car, to learn that she may have rabies. But that is where we are in America. And that does not sit right with me. And that is why I'm hosting a fun run race for the cure for rabies. To raise awareness of the fact that there is a cure for rabies. A disease that has largely been eradicated in the US. But not very many people know that.", "order": 15285, "speaker": "Michael Scott"},
  {"season": 4, "episode": 1, "scene": 37, "line": "[on the phone] No, rabies... Babies would be a good idea. Can I put you down for a dime?", "order": 15286, "speaker": "Stanley Hudson"},
  {"season": 4, "episode": 1, "scene": 37, "line": "Hi Stanley, how many sponsors so far?", "order": 15287, "speaker": "Michael Scott"},
  {"season": 4, "episode": 1, "scene": 37, "line": "Zero.", "order": 15288, "speaker": "Stanley Hudson"},
  {"season": 4, "episode": 1, "scene": 37, "line": "Come on man, gotta step it up! It's for a good cause. Jan called this morning and pledged $500.00.", "order": 15289, "speaker": "Michael Scott"},
  {"season": 4, "episode": 1, "scene": 37, "line": "Isn't that your money?", "order": 15290, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 1, "scene": 37, "line": "That... is for a good cause. Phyllis, how's the rabies quilt coming?", "order": 15291, "speaker": "Michael Scott"},
  {"season": 4, "episode": 1, "scene": 37, "line": "Oh, it's coming.", "order": 15292, "speaker": "Phyllis Vance"},
  {"season": 4, "episode": 1, "scene": 37, "line": "Oh, look at that, 3. Way to honor Meredith, Phyllis.", "order": 15293, "speaker": "Michael Scott"},
  {"season": 4, "episode": 1, "scene": 37, "line": "Michael?", "order": 15294, "speaker": "Kevin Malone"},
  {"season": 4, "episode": 1, "scene": 37, "line": "Yes.", "order": 15295, "speaker": "Michael Scott"},
  {"season": 4, "episode": 1, "scene": 37, "line": "You cannot make me run.", "order": 15296, "speaker": "Kevin Malone"},
  {"season": 4, "episode": 1, "scene": 37, "line": "OK. [walks away]", "order": 15297, "speaker": "Michael Scott"},
  {"season": 4, "episode": 1, "scene": 37, "line": "It is not a real charity. It's stupid Michael, and I'm not gonna do it.", "order": 15298, "speaker": "Kevin Malone"},
  {"season": 4, "episode": 1, "scene": 37, "line": "Alright, alright.", "order": 15299, "speaker": "Michael Scott"},
  {"season": 4, "episode": 1, "scene": 37, "line": "You didn't run for me...", "order": 15300, "speaker": "Kevin Malone"},
  {"season": 4, "episode": 1, "scene": 37, "line": "Shhh...", "order": 15301, "speaker": "Michael Scott"},
  {"season": 4, "episode": 1, "scene": 37, "line": "...when I thought I had skin cancer.", "order": 15302, "speaker": "Kevin Malone"},
  {"season": 4, "episode": 1, "scene": 37, "line": "I know that you're probably scared of people seeing your fat legs in shorts.", "order": 15303, "speaker": "Michael Scott"},
  {"season": 4, "episode": 1, "scene": 37, "line": "No.", "order": 15304, "speaker": "Kevin Malone"},
  {"season": 4, "episode": 1, "scene": 37, "line": "OK, well back in olden times, a large fat person, like this, was a person of power. A person who had money, could buy food, person of respect. Like the regional manager of the day. Whereas, someone athletic and trim, like myself, was someone who worked in the fields... And I was a peasant.", "order": 15305, "speaker": "Michael Scott"},
  {"season": 4, "episode": 1, "scene": 37, "line": "I just don't want to run. I didn't bring my sneakers or my clothes.", "order": 15306, "speaker": "Kevin Malone"},
  {"season": 4, "episode": 1, "scene": 37, "line": "Well, you're going to have to run, or you're going to be in a lot of trouble. It is not olden times anymore.", "order": 15307, "speaker": "Michael Scott"},
  {"season": 4, "episode": 1, "scene": 38, "line": "[in bathroom] I'm petrified of nipple chafing. Once it starts it is a vicious circle. If you have sensitive nipples, they chafe, so they become more sensitive, so they chafe more. So... I take precautions. [tapes a cotton ball to each nipple]", "order": 15308, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 1, "scene": 39, "line": "Hey Angela. Hey, um... I'm sorry about your cat. [Angela starts to cry]", "order": 15309, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 1, "scene": 40, "line": "This is Sprinkles. [holds up a picture] She was my best friend. I kept her going through countless ailments. I asked Dwight Schrute to feed her once, and she is now deceased. This is Halloween last year, [picture of Angela holding Sprinkles] just a couple of kittens [starts to cry] out on the town.", "order": 15310, "speaker": "Angela Martin"},
  {"season": 4, "episode": 1, "scene": 41, "line": "Pssst. I'm having relationship problems. And since you're always having relationship problems, I thought you'd be able to give me some advice.", "order": 15311, "speaker": "Angela Martin"},
  {"season": 4, "episode": 1, "scene": 41, "line": "What's wrong?", "order": 15312, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 1, "scene": 41, "line": "I have this crazy thought, that I know is crazy. That maybe Dwight killed my cat.", "order": 15313, "speaker": "Angela Martin"},
  {"season": 4, "episode": 1, "scene": 41, "line": "Hmm...", "order": 15314, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 1, "scene": 41, "line": "When I got home, Sprinkles' body was in the freezer where Dwight said he left her, but all my bags of frozen french fries had been clawed to shreds.", "order": 15315, "speaker": "Angela Martin"},
  {"season": 4, "episode": 1, "scene": 41, "line": "Ah.", "order": 15316, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 1, "scene": 41, "line": "Something's not right. The vet's doing an autopsy.", "order": 15317, "speaker": "Angela Martin"},
  {"season": 4, "episode": 1, "scene": 41, "line": "Angela, I'm sorry.", "order": 15318, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 1, "scene": 41, "line": "Did Roy ever kill one of your cats?", "order": 15319, "speaker": "Angela Martin"},
  {"season": 4, "episode": 1, "scene": 41, "line": "I'm more of a dog person.", "order": 15320, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 1, "scene": 41, "line": "[sighs]", "order": 15321, "speaker": "Angela Martin"},
  {"season": 4, "episode": 1, "scene": 42, "line": "So what's your strategy for this race?", "order": 15322, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 1, "scene": 42, "line": "Well I'm gonna start fast.", "order": 15323, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 1, "scene": 42, "line": "Mm-hmm.", "order": 15324, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 1, "scene": 42, "line": "Then I'm gonna run fast in the middle.", "order": 15325, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 1, "scene": 42, "line": "What?", "order": 15326, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 1, "scene": 42, "line": "Then I'm gonna end fast.", "order": 15327, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 1, "scene": 42, "line": "Why won't more people do that? [laughs]", "order": 15328, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 1, "scene": 42, "line": "Cause they're just stupid.", "order": 15329, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 1, "scene": 42, "line": "[looks at camera] What?", "order": 15330, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 1, "scene": 43, "line": "[Pam and Jim watch video of their kiss on the tv] Oh, ah... No that's not... I mean that wasn't, ah...", "order": 15331, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 1, "scene": 43, "line": "Yeah... That was um...", "order": 15332, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 1, "scene": 43, "line": "I mean I can see how it would seem a bit like we uh... How it looks like um... I mean now a days you can edit anything, right? I mean you can edit anything to look like um... anything.", "order": 15333, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 1, "scene": 43, "line": "Yeah, I gave him a ride home because...", "order": 15334, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 1, "scene": 43, "line": "Right!", "order": 15335, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 1, "scene": 43, "line": "... We're dating.", "order": 15336, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 1, "scene": 43, "line": "Wow! There it is.", "order": 15337, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 1, "scene": 43, "line": "Ah, yeah. We haven't told anybody, but it's going really great. [looks at Jim] Right?", "order": 15338, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 1, "scene": 43, "line": "It is going really great.", "order": 15339, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 1, "scene": 44, "line": "[Angela runs into Dwight] Oww!", "order": 15340, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 1, "scene": 44, "line": "Shut up.", "order": 15341, "speaker": "Angela Martin"},
  {"season": 4, "episode": 1, "scene": 44, "line": "You're taking this out on me, but I was only the messenger.", "order": 15342, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 1, "scene": 44, "line": "Oh, really?", "order": 15343, "speaker": "Angela Martin"},
  {"season": 4, "episode": 1, "scene": 44, "line": "[sighs] You'll feel better after the 5k. Exercise is good for depression.", "order": 15344, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 1, "scene": 44, "line": "[pushes her chair into Dwight's legs] I'm not depressed I'm in grief.", "order": 15345, "speaker": "Angela Martin"},
  {"season": 4, "episode": 1, "scene": 45, "line": "We have raised, almost $700.00, most of it from me and Jan. Um... when do they put that on the giant check? Or is that something that we write in later?", "order": 15346, "speaker": "Michael Scott"},
  {"season": 4, "episode": 1, "scene": 45, "line": "Well a giant check costs about $200.00 to make up. I have a print shop standing by but... What do you think Michael, that's over 25% of our funds?", "order": 15347, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 1, "scene": 45, "line": "Hmm... That's a tough decision. Um... I always imagined it with a giant check. So...", "order": 15348, "speaker": "Michael Scott"},
  {"season": 4, "episode": 1, "scene": 45, "line": "Yeah, I mean I personally am definitely on board for the giant check.", "order": 15349, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 1, "scene": 45, "line": "Giant check it is.", "order": 15350, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 1, "scene": 45, "line": "Yeah.", "order": 15351, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 1, "scene": 45, "line": "Yep.", "order": 15352, "speaker": "Michael Scott"},
  {"season": 4, "episode": 1, "scene": 45, "line": "Well I don't know, on the other hand it does leave less money for bat birth control.", "order": 15353, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 1, "scene": 45, "line": "Bat birth control", "order": 15354, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 1, "scene": 45, "line": "Wait, this money is going to bat birth control, right Michael, that's what you told me when I contributed.", "order": 15355, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 1, "scene": 45, "line": "You didn't contribute very much. I was also hoping to hand the giant check to a rabies doctor. And how's that been going?", "order": 15356, "speaker": "Michael Scott"},
  {"season": 4, "episode": 1, "scene": 45, "line": "Not well. A doctor won't come out to collect a check for $700.00, or $500.00 if we go with the giant check.", "order": 15357, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 1, "scene": 45, "line": "Which we are.", "order": 15358, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 1, "scene": 45, "line": "And also there is no such thing as a rabies doctor.", "order": 15359, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 1, "scene": 45, "line": "What about a rabies nurse?", "order": 15360, "speaker": "Michael Scott"},
  {"season": 4, "episode": 1, "scene": 45, "line": "I don't think so.", "order": 15361, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 1, "scene": 45, "line": "You know what though, I've actually seen ads for nurses that you can hire by the hour, for parties and bachelor events.", "order": 15362, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 1, "scene": 45, "line": "That's possible. Look into that.", "order": 15363, "speaker": "Michael Scott"},
  {"season": 4, "episode": 1, "scene": 45, "line": "Great, it's gonna cost a couple a hundred buck and ah... oh actually more with tips.", "order": 15364, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 1, "scene": 45, "line": "Maybe we should just skip the ceremony and setup a college fund for Meredith's son.", "order": 15365, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 1, "scene": 45, "line": "Have you met that kid? He's not going to college.", "order": 15366, "speaker": "Michael Scott"},
  {"season": 4, "episode": 1, "scene": 46, "line": "Michael, 5k means 5 kilometers, not 5 thousand miles. [knocks]", "order": 15367, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 1, "scene": 46, "line": "Come in.", "order": 15368, "speaker": "Michael Scott"},
  {"season": 4, "episode": 1, "scene": 46, "line": "Hey.", "order": 15369, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 1, "scene": 46, "line": "Hey, hey, hey", "order": 15370, "speaker": "Michael Scott"},
  {"season": 4, "episode": 1, "scene": 46, "line": "Oh my God.", "order": 15371, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 1, "scene": 46, "line": "What are you doing?", "order": 15372, "speaker": "Michael Scott"},
  {"season": 4, "episode": 1, "scene": 46, "line": "You said come in!", "order": 15373, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 1, "scene": 46, "line": "No I didn't, just please don't...", "order": 15374, "speaker": "Michael Scott"},
  {"season": 4, "episode": 1, "scene": 46, "line": "Oh my God.", "order": 15375, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 1, "scene": 47, "line": "So I closed the door but the image of his...", "order": 15376, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 1, "scene": 47, "line": "Baquette.", "order": 15377, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 1, "scene": 47, "line": "... dangling participle...", "order": 15378, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 1, "scene": 47, "line": "Eww.", "order": 15379, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 1, "scene": 47, "line": "... still burned in my eyes.", "order": 15380, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 1, "scene": 47, "line": "I can imagine.", "order": 15381, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 1, "scene": 47, "line": "[Michael knocks slowly on door] Come in.", "order": 15382, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 1, "scene": 47, "line": "May I enter the room?", "order": 15383, "speaker": "Michael Scott"},
  {"season": 4, "episode": 1, "scene": 47, "line": "Yes. Or come in.", "order": 15384, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 1, "scene": 47, "line": "See how I did that. That's the way you should enter a room. You knock and then you wait for the all clear.", "order": 15385, "speaker": "Michael Scott"},
  {"season": 4, "episode": 1, "scene": 47, "line": "You couldn't have taken off all your clothes in the men's room?", "order": 15386, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 1, "scene": 47, "line": "Yes, but I have an office, so why would I do that.", "order": 15387, "speaker": "Michael Scott"},
  {"season": 4, "episode": 1, "scene": 47, "line": "On average, how many hours a day do you spend naked in your office? Just ballpark.", "order": 15388, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 1, "scene": 47, "line": "European offices are naked all the time.", "order": 15389, "speaker": "Michael Scott"},
  {"season": 4, "episode": 1, "scene": 47, "line": "They're so not.", "order": 15390, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 1, "scene": 47, "line": "Besides my shirt tail covered most of it so...", "order": 15391, "speaker": "Michael Scott"},
  {"season": 4, "episode": 1, "scene": 47, "line": "I didn't see where it started but I saw where it ended.", "order": 15392, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 1, "scene": 47, "line": "[sighs] Gross.", "order": 15393, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 1, "scene": 47, "line": "That's not gross, it is the human body. What is your problem? Pam you're an artist, right? Think of me as one of your models. OK, you know what, I don't want this to detract from what we really need to be thinking about today, it's not fair to people with rabies. And that's the point, right? OK, let's go have some fun.", "order": 15394, "speaker": "Michael Scott"},
  {"season": 4, "episode": 1, "scene": 47, "line": "Alright.", "order": 15395, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 1, "scene": 48, "line": "They say if you're nervous around someone you should picture them naked. I do not recommend this strategy. Try picturing them with more clothes on... or a funny coat.", "order": 15396, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 1, "scene": 49, "line": "[naked from chest up] Oh, I'm sorry, is this a working office, and not a French beach?", "order": 15397, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 1, "scene": 50, "line": "OK, name please.", "order": 15398, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 1, "scene": 50, "line": "Creed Bratton, 75 plus division.", "order": 15399, "speaker": "Creed Bratton"},
  {"season": 4, "episode": 1, "scene": 50, "line": "You're over 75 years old?", "order": 15400, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 1, "scene": 50, "line": "82 November first. How much is the prize money?", "order": 15401, "speaker": "Creed Bratton"},
  {"season": 4, "episode": 1, "scene": 50, "line": "There's no prize money.", "order": 15402, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 1, "scene": 50, "line": "What, is any of this real?", "order": 15403, "speaker": "Creed Bratton"},
  {"season": 4, "episode": 1, "scene": 51, "line": "Check that out. [points to Toby] Look at me, I'm Toby, I'm stretching, I know what I'm doing. Why is he even here?", "order": 15404, "speaker": "Michael Scott"},
  {"season": 4, "episode": 1, "scene": 52, "line": "So I heard that you were peeping on Michael.", "order": 15405, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 1, "scene": 52, "line": "What? Look, no it was not...", "order": 15406, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 1, "scene": 52, "line": "I don't know what your deal is, but he's mine, OK? So hands off.", "order": 15407, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 1, "scene": 52, "line": "OK everybody, listen up. Thank you for coming. Before we get started I wanted to say a few words about this deadly disease called rabies. And I'd like you to take a look into the face of rabies. [turns around a picture of Meredith in the hospital] That should scare you. It scares me. You people need to educate yourselves. Myth: 3 Americans every year die from rabies. Fact: 4 Americans every year die from rabies. How many of you know someone who has been afflicted or affected by rabies? Show of hands. One, two, three... too many to count. It is truly the silent killer. No, it is the foaming barking killer. And it is something... Darryl what are you doing.", "order": 15408, "speaker": "Michael Scott"},
  {"season": 4, "episode": 1, "scene": 52, "line": "[feeding a squirrel] I'm giving him a peanut.", "order": 15409, "speaker": "Darryl Philbin"},
  {"season": 4, "episode": 1, "scene": 52, "line": "No, don't give him... just, did you hear anything I said?", "order": 15410, "speaker": "Michael Scott"},
  {"season": 4, "episode": 1, "scene": 52, "line": "Look how happy he is.", "order": 15411, "speaker": "Darryl Philbin"},
  {"season": 4, "episode": 1, "scene": 52, "line": "He's happy because he's insane. You know what, that's the perfect example of the kind of awareness we need to generate. Where's the nurse? [Elizabeth walks up in nurse's outfit] This is the reason we're here.", "order": 15412, "speaker": "Michael Scott"},
  {"season": 4, "episode": 1, "scene": 52, "line": "Hello Michael.", "order": 15413, "speaker": "Elizabeth"},
  {"season": 4, "episode": 1, "scene": 52, "line": "Oh hey, I know you... Elizabeth?", "order": 15414, "speaker": "Michael Scott"},
  {"season": 4, "episode": 1, "scene": 52, "line": "Yeah.", "order": 15415, "speaker": "Elizabeth"},
  {"season": 4, "episode": 1, "scene": 52, "line": "Right? Wow, very cool you went back and got your degree. I would like you to accept this check, for $340.00 made out to science. Make sure that, that gets into the right hands.", "order": 15416, "speaker": "Michael Scott"},
  {"season": 4, "episode": 1, "scene": 52, "line": "Great. [everyone claps]", "order": 15417, "speaker": "Elizabeth"},
  {"season": 4, "episode": 1, "scene": 53, "line": "You got it?", "order": 15418, "speaker": "Michael Scott"},
  {"season": 4, "episode": 1, "scene": 53, "line": "Mm-hmm.", "order": 15419, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 1, "scene": 53, "line": "OK, fettuccine alfredo, time to carbo-load.", "order": 15420, "speaker": "Michael Scott"},
  {"season": 4, "episode": 1, "scene": 54, "line": "Just remember folks, it's not about winning, it is about finishing.", "order": 15421, "speaker": "Michael Scott"},
  {"season": 4, "episode": 1, "scene": 54, "line": "On your marks, get set...", "order": 15422, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 1, "scene": 54, "line": "[pulls Toby's pants down] Beow!", "order": 15423, "speaker": "Michael Scott"},
  {"season": 4, "episode": 1, "scene": 54, "line": "Hey! [extremely loud gunshot]", "order": 15424, "speaker": "Toby Flenderson"},
  {"season": 4, "episode": 1, "scene": 55, "line": "On your left!", "order": 15425, "speaker": "Michael Scott"},
  {"season": 4, "episode": 1, "scene": 55, "line": "You're doing great Michael, look at you go!", "order": 15426, "speaker": "Oscar Martinez"},
  {"season": 4, "episode": 1, "scene": 55, "line": "That's my boss! Yeah-ha baby!", "order": 15427, "speaker": "Creed Bratton"},
  {"season": 4, "episode": 1, "scene": 55, "line": "I am fast! I'm very fast! I'm like Forrest Gump, except I am not an idiot. [Creed, Stanley, and Oscar get into a cab]", "order": 15428, "speaker": "Michael Scott"},
  {"season": 4, "episode": 1, "scene": 56, "line": "Oh yes I will work out today. I will work out a way to avoid running for a stupid cause.", "order": 15429, "speaker": "Stanley Hudson"},
  {"season": 4, "episode": 1, "scene": 57, "line": "I've walked 2 marathons. Pretty sure I can handle a 5k. Key is drafting, eliminate wind resistance. [running right behind Kevin]", "order": 15430, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 1, "scene": 58, "line": "Yeah. [laughs] Oh we're in last place.", "order": 15431, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 1, "scene": 58, "line": "Oh, would you look at that.", "order": 15432, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 1, "scene": 58, "line": "Darn it.", "order": 15433, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 1, "scene": 59, "line": "Water? Water? [Toby gabs water, drinks, then throws back at Jan] Water babe?", "order": 15434, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 1, "scene": 59, "line": "No, no water for me. Not while rabies causes fear of water... solidarity!", "order": 15435, "speaker": "Michael Scott"},
  {"season": 4, "episode": 1, "scene": 59, "line": "Michael that's irrational.", "order": 15436, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 1, "scene": 60, "line": "Rabies victims... have to live with an irrational hatred of water their entire lives. So... least I could do.", "order": 15437, "speaker": "Michael Scott"},
  {"season": 4, "episode": 1, "scene": 61, "line": "Oh God! My nipples, it's starting.", "order": 15438, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 1, "scene": 62, "line": "[Creed, Stanley, and Oscar sitting at a table] Can we get another round?", "order": 15439, "speaker": "Creed Bratton"},
  {"season": 4, "episode": 1, "scene": 62, "line": "OK.", "order": 15440, "speaker": "Waitress"},
  {"season": 4, "episode": 1, "scene": 62, "line": "Thanks.", "order": 15441, "speaker": "Creed Bratton"},
  {"season": 4, "episode": 1, "scene": 62, "line": "So we've got what, another 20 minutes?", "order": 15442, "speaker": "Stanley Hudson"},
  {"season": 4, "episode": 1, "scene": 62, "line": "More or less.", "order": 15443, "speaker": "Oscar Martinez"},
  {"season": 4, "episode": 1, "scene": 62, "line": "Hmmm...", "order": 15444, "speaker": "Stanley Hudson"},
  {"season": 4, "episode": 1, "scene": 63, "line": "That fettuccine is sitting in my stomach like a rock.", "order": 15445, "speaker": "Michael Scott"},
  {"season": 4, "episode": 1, "scene": 63, "line": "You've got nothing to worry about, I put Imodium in Toby's coffee before the race.", "order": 15446, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 1, "scene": 63, "line": "[laughs] Excellent! ...Simpsons. Wait, Imodium or Ex-lax?", "order": 15447, "speaker": "Michael Scott"},
  {"season": 4, "episode": 1, "scene": 64, "line": "I'm makin' great time. Usually I have to take a bathroom break half way through a race like this, but not today.", "order": 15448, "speaker": "Toby Flenderson"},
  {"season": 4, "episode": 1, "scene": 65, "line": "Ooo! An estate sale. Wanna go in?", "order": 15449, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 1, "scene": 65, "line": "I don't know I'm really committed to winning.", "order": 15450, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 1, "scene": 65, "line": "OK, but what if I told you all the money you spend here, goes to preventing a disease that's already been cured.", "order": 15451, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 1, "scene": 65, "line": "Mmm... Yes.", "order": 15452, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 1, "scene": 65, "line": "That's what I thought.", "order": 15453, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 1, "scene": 65, "line": "Yeah.", "order": 15454, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 1, "scene": 65, "line": "Right. Let's do some good.", "order": 15455, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 1, "scene": 66, "line": "[Ryan looks at the phone as it rings... [Pam's voice] [You have reached the offices of Dunder-Mifflin, Scranton, currently the entire staff is out doing the Michael Scott, DM FM PMC rabies awareness Pro-Am fun run race... [Michael's voice] for the cure... [Pam's voice] leave a message' Ryan hangs up]", "order": 15456, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 1, "scene": 67, "line": "[Burp] Oh God, Oh, alfredo sauce. I'm getting a stitch. [groans]", "order": 15457, "speaker": "Michael Scott"},
  {"season": 4, "episode": 1, "scene": 68, "line": "[runs into the back of Kevin] Ah, God watch the nipples, Kevin.", "order": 15458, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 1, "scene": 68, "line": "Back off me.", "order": 15459, "speaker": "Kevin Malone"},
  {"season": 4, "episode": 1, "scene": 69, "line": "How ya feeling, better?", "order": 15460, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 1, "scene": 69, "line": "No.", "order": 15461, "speaker": "Angela Martin"},
  {"season": 4, "episode": 1, "scene": 69, "line": "Well you look cute as a button. You've worked up quite a sweat.", "order": 15462, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 1, "scene": 69, "line": "The vet is removing all of Sprinkles organs right now and sending them to the lab.", "order": 15463, "speaker": "Angela Martin"},
  {"season": 4, "episode": 1, "scene": 69, "line": "I am a farmer Angela.", "order": 15464, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 1, "scene": 69, "line": "What does that mean?", "order": 15465, "speaker": "Angela Martin"},
  {"season": 4, "episode": 1, "scene": 69, "line": "OK, when a farmer sees an animal that is in pain, that has no quality of life, that has no utility, a farmer does what city folk don't have the stomach to do.", "order": 15466, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 1, "scene": 69, "line": "You did kill her!?", "order": 15467, "speaker": "Angela Martin"},
  {"season": 4, "episode": 1, "scene": 69, "line": "I... I sang her, her favorite songs.", "order": 15468, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 1, "scene": 69, "line": "You put her in my freezer.", "order": 15469, "speaker": "Angela Martin"},
  {"season": 4, "episode": 1, "scene": 69, "line": "It was beautiful and gentle and respectful. I fed her antihistamines and she gradually fell asleep.", "order": 15470, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 1, "scene": 69, "line": "Well then she barfed them up in the freezer and tried to get out.", "order": 15471, "speaker": "Angela Martin"},
  {"season": 4, "episode": 1, "scene": 69, "line": "Well I'm not responsible for that!", "order": 15472, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 1, "scene": 69, "line": "You! [hits Dwight]", "order": 15473, "speaker": "Angela Martin"},
  {"season": 4, "episode": 1, "scene": 69, "line": "Hey, Oww! [looks around to onlookers] It's OK.", "order": 15474, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 1, "scene": 69, "line": "It's OK.", "order": 15475, "speaker": "Angela Martin"},
  {"season": 4, "episode": 1, "scene": 69, "line": "It's nothing, I'm robbing her.", "order": 15476, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 1, "scene": 69, "line": "It's nothing.", "order": 15477, "speaker": "Angela Martin"},
  {"season": 4, "episode": 1, "scene": 69, "line": "It's fine. What?", "order": 15478, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 1, "scene": 69, "line": "How could you do that without telling me?", "order": 15479, "speaker": "Angela Martin"},
  {"season": 4, "episode": 1, "scene": 69, "line": "I thought I was helping you. I expected a hug. I took care of that cat, the best way I knew how.", "order": 15480, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 1, "scene": 69, "line": "Cat Heaven is a beautiful place, but you don't get there if you're euthanized.", "order": 15481, "speaker": "Angela Martin"},
  {"season": 4, "episode": 1, "scene": 69, "line": "I know a great taxidermist. I'll pay to have her stuffed. Well he's not great, but he's pretty good.", "order": 15482, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 1, "scene": 69, "line": "You don't understand.", "order": 15483, "speaker": "Angela Martin"},
  {"season": 4, "episode": 1, "scene": 69, "line": "[runs by] Take bat bites seriously. Don't get bit.", "order": 15484, "speaker": "Michael Scott"},
  {"season": 4, "episode": 1, "scene": 69, "line": "Or we can have her buried out at the east field, by mother. Huh, Angela?", "order": 15485, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 1, "scene": 69, "line": "Don't touch me Dwight! [runs off crying]", "order": 15486, "speaker": "Angela Martin"},
  {"season": 4, "episode": 1, "scene": 70, "line": "[holding lamp] You like it? It's kinda designy and cool, it was $8.00.", "order": 15487, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 1, "scene": 70, "line": "Oh, and I get to carry it.", "order": 15488, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 1, "scene": 71, "line": "[crosses finish line] And the winner is Toby Flenderson.", "order": 15489, "speaker": "Toby Flenderson"},
  {"season": 4, "episode": 1, "scene": 71, "line": "Have a seat, I'll write it down.", "order": 15490, "speaker": "Kelly Kapoor"},
  {"season": 4, "episode": 1, "scene": 71, "line": "Where are we?", "order": 15491, "speaker": "Toby Flenderson"},
  {"season": 4, "episode": 1, "scene": 71, "line": "I dunno, like 5 kilometers from the office.", "order": 15492, "speaker": "Kelly Kapoor"},
  {"season": 4, "episode": 1, "scene": 71, "line": "He couldn't have made it a circle?", "order": 15493, "speaker": "Toby Flenderson"},
  {"season": 4, "episode": 1, "scene": 72, "line": "[bent over] I am not going to finish. I can't beat rabies. Nobody can beat rabies, rabies has been around for a thousand years. I was a fool to think that I could beat it. [Creed,Stanley, and Oscar pass by in the cab and get out at the finish line]", "order": 15494, "speaker": "Michael Scott"},
  {"season": 4, "episode": 1, "scene": 73, "line": "...talk about it.", "order": 15495, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 1, "scene": 73, "line": "Yeah probably. Hey, Michael?", "order": 15496, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 1, "scene": 73, "line": "What are you doing here? Did you come back for us, or...", "order": 15497, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 1, "scene": 73, "line": "I can't finish. I feel so weak, I just...", "order": 15498, "speaker": "Michael Scott"},
  {"season": 4, "episode": 1, "scene": 73, "line": "Well, you're probably dehydrated.", "order": 15499, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 1, "scene": 73, "line": "What do you want me to do Jim?", "order": 15500, "speaker": "Michael Scott"},
  {"season": 4, "episode": 1, "scene": 73, "line": "Glass of water would be a start.", "order": 15501, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 1, "scene": 73, "line": "No, There are people all over the world, who have all sorts of problems and afflictions, and diseases. They're deformed, and they're abnormal, and... they're illiterate and ugly. Symphonies don't have any money. Public TV is bust. I can't do anything about it, I can't... you know. There's just one of me, and there's a thousand of them. And rabies wins.", "order": 15502, "speaker": "Michael Scott"},
  {"season": 4, "episode": 1, "scene": 73, "line": "Wow! You are a downer. We were having a pretty nice day.", "order": 15503, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 1, "scene": 73, "line": "I'm still having a nice day.", "order": 15504, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 1, "scene": 73, "line": "You are?", "order": 15505, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 1, "scene": 73, "line": "Yeah. Hey Michael, look at this lamp. $8.00.", "order": 15506, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 1, "scene": 73, "line": "That's a good deal.", "order": 15507, "speaker": "Michael Scott"},
  {"season": 4, "episode": 1, "scene": 73, "line": "And Michael, you don't have rabies. And chances are you're not going to get it anytime soon. So... you don't really have to think about it too much.", "order": 15508, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 1, "scene": 73, "line": "Pam, if everybody felt that way, nothing would get done.", "order": 15509, "speaker": "Michael Scott"},
  {"season": 4, "episode": 1, "scene": 73, "line": "Yeah, but there's other, better people out there who are helping.", "order": 15510, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 1, "scene": 73, "line": "You just don't think I am capable, of making a difference.", "order": 15511, "speaker": "Michael Scott"},
  {"season": 4, "episode": 1, "scene": 73, "line": "I know you Michael, I saw you naked.", "order": 15512, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 1, "scene": 73, "line": "You don't, you don't know me. You've just seen my penis. I can make a difference, remember, I'm the one who started this whole thing off by hitting Meredith with my car. And I owe it to her to finish this... GD 5k [gets up groaning, Jim tries to help] No, no!", "order": 15513, "speaker": "Michael Scott"},
  {"season": 4, "episode": 1, "scene": 74, "line": "Finishing that 5k, was the hardest thing I have ever had to do. I ate more fettuccine alfredo and drank less water, than I have in my entire life. People always talk about triumphs of the human spirit, well today I had a triumph of the human body. That's why everybody was applauding for me at the end. My guts and my heart, and while I eventually puked my guts out, I never puked my heart out. And I'm very, very proud of that.", "order": 15514, "speaker": "Michael Scott"},
  {"season": 4, "episode": 1, "scene": 75, "line": "Hey Michael.", "order": 15515, "speaker": "Meredith Palmer"},
  {"season": 4, "episode": 1, "scene": 75, "line": "Hey Mer. How ya doing?", "order": 15516, "speaker": "Michael Scott"},
  {"season": 4, "episode": 1, "scene": 75, "line": "Better.", "order": 15517, "speaker": "Meredith Palmer"},
  {"season": 4, "episode": 1, "scene": 75, "line": "It's ironic isn't it? I mean I'm in the hospital for not getting enough water, and you're in it for a disease that causes the fear of water.", "order": 15518, "speaker": "Michael Scott"},
  {"season": 4, "episode": 1, "scene": 75, "line": "I'm in because you hit me with your car. But I wanted to say, I heard you were trying really hard, so I'm not mad at you anymore.", "order": 15519, "speaker": "Meredith Palmer"},
  {"season": 4, "episode": 1, "scene": 75, "line": "Thanks, I'm not mad at you anymore. [sighs, takes sucker out of his mouth] Wanna share?", "order": 15520, "speaker": "Michael Scott"},
  {"season": 4, "episode": 1, "scene": 75, "line": "Sure.", "order": 15521, "speaker": "Meredith Palmer"},
  {"season": 4, "episode": 1, "scene": 75, "line": "I'm not really sick. [Meredith takes a lick and hands back to Michael] Nah, I'm good.", "order": 15522, "speaker": "Michael Scott"},
  {"season": 4, "episode": 1, "scene": 76, "line": "Did you ever think of this? Would a sober person walk in front of a car?", "order": 15523, "speaker": "Michael Scott"},
  {"season": 4, "episode": 1, "scene": 76, "line": "Ay-o!", "order": 15524, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 1, "scene": 76, "line": "I'm sorry, are you saying that Meredith was drunk at 9 AM?", "order": 15525, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 1, "scene": 76, "line": "Well...", "order": 15526, "speaker": "Michael Scott"},
  {"season": 4, "episode": 1, "scene": 76, "line": "That's despicable.", "order": 15527, "speaker": "Phyllis Vance"},
  {"season": 4, "episode": 1, "scene": 76, "line": "Michael.", "order": 15528, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 1, "scene": 76, "line": "That's not cool.", "order": 15529, "speaker": "Someone"},
  {"season": 4, "episode": 1, "scene": 76, "line": "There's no proof of that.", "order": 15530, "speaker": "Oscar Martinez"},
  {"season": 4, "episode": 1, "scene": 76, "line": "That's not...", "order": 15531, "speaker": "Michael Scott"},
  {"season": 4, "episode": 1, "scene": 76, "line": "That seems early, even for her.", "order": 15532, "speaker": "Kevin Malone"},
  {"season": 4, "episode": 1, "scene": 76, "line": "That's not what...no, no, I'm not saying that, that's disgusting.", "order": 15533, "speaker": "Michael Scott"},
  {"season": 4, "episode": 1, "scene": 77, "line": "Drunk? No! You can't get drunk off Kahlua. It's just a kind of coffee.", "order": 15534, "speaker": "Meredith Palmer"},
  {"season": 4, "episode": 1, "scene": 78, "line": "Then... what are you saying?", "order": 15535, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 1, "scene": 78, "line": "I am saying... that... maybe I was drunk. Did you ever think of that?", "order": 15536, "speaker": "Michael Scott"},
  {"season": 4, "episode": 1, "scene": 78, "line": "Definitely not.", "order": 15537, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 1, "scene": 78, "line": "That'd be DUI.", "order": 15538, "speaker": "Kevin Malone"},
  {"season": 4, "episode": 1, "scene": 78, "line": "That's...", "order": 15539, "speaker": "Phyllis Vance"},
  {"season": 4, "episode": 1, "scene": 78, "line": "Okay.", "order": 15540, "speaker": "Michael Scott"},
  {"season": 4, "episode": 1, "scene": 79, "line": "Maybe she hit me. You ever think of that? She hit me? I don't know whose fault it was. I wasn't exactly looking at the road.", "order": 15541, "speaker": "Michael Scott"},
  {"season": 4, "episode": 1, "scene": 80, "line": "Hey, Toby. [walks past him to Kelly's cubicle]", "order": 15542, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 1, "scene": 80, "line": "Hey.", "order": 15543, "speaker": "Toby Flenderson"},
  {"season": 4, "episode": 1, "scene": 80, "line": "Kelly, we're going to visit Meredith at lunch. [Kelly's lying down on floor, head unseen under desk. She rolls to her side, away from Pam] Kelly?", "order": 15544, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 1, "scene": 81, "line": "Well, Kelly's been so upset about Ryan dumping her, that she can't even talk. [grins]", "order": 15545, "speaker": "Toby Flenderson"},
  {"season": 4, "episode": 1, "scene": 81, "line": "[from under desk] I don't want to talk to anybody about Ryan! Please, go away!", "order": 15546, "speaker": "Kelly Kapoor"},
  {"season": 4, "episode": 1, "scene": 82, "line": "They don't have this kind of technology at the vet's. It's not far.", "order": 15547, "speaker": "Angela Martin"},
  {"season": 4, "episode": 1, "scene": 82, "line": "It's fair.", "order": 15548, "speaker": "Oscar Martinez"},
  {"season": 4, "episode": 1, "scene": 82, "line": "You know what, why don't go around the room and say one memory of Meredith. I'll start. I liked her jumpers. They reminded me of Kindergarten. Jim?", "order": 15549, "speaker": "Michael Scott"},
  {"season": 4, "episode": 1, "scene": 82, "line": "I'll always remember Meredith's back. That's all I can see from my desk. All that red hair, over a nice strong back. Sometimes I pretend it's Carrot Top, and he's going to turn around and tell a joke.", "order": 15550, "speaker": "Kevin Malone"},
  {"season": 4, "episode": 1, "scene": 82, "line": "So, how's the view old girl? [looking out windows] Oh! There's a cemetery. You think that's affiliated with the hospital, or is it a like a separate feature...", "order": 15551, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 1, "scene": 82, "line": "Hey! Okay, okay, okay, You can just close that.", "order": 15552, "speaker": "Michael Scott"},
  {"season": 4, "episode": 1, "scene": 82, "line": "Bye, Meredith. Here you go. We got you some balloons.", "order": 15553, "speaker": "Angela Martin"},
  {"season": 4, "episode": 1, "scene": 82, "line": "See you guys. [chorus of goodbyes, all but Michael file out as he's talking, Creed takes Meredith's pills]", "order": 15554, "speaker": "Meredith Palmer"},
  {"season": 4, "episode": 1, "scene": 82, "line": "Let's just... Why can't you just forgive... Why, why are you having such a hard time laughing this off? How do you know that you weren't born with some sort of abnormal pelvis that it was just bound to crack at some point?", "order": 15555, "speaker": "Michael Scott"},
  {"season": 4, "episode": 1, "scene": 82, "line": "Oh please, Michael.", "order": 15556, "speaker": "Meredith Palmer"},
  {"season": 4, "episode": 1, "scene": 82, "line": "I'm not going to leave until you forgive me.", "order": 15557, "speaker": "Michael Scott"},
  {"season": 4, "episode": 1, "scene": 82, "line": "You're gonna be here a", "order": 15558, "speaker": "Meredith Palmer"},
  {"season": 4, "episode": 1, "scene": 82, "line": "[standing for a while, sighs, turns to walk out] Oh, okay.", "order": 15559, "speaker": "Michael Scott"},
  {"season": 4, "episode": 1, "scene": 82, "line": "Nurse! I just need some water. And my pills. Thank you.", "order": 15560, "speaker": "Meredith Palmer"},
  {"season": 4, "episode": 1, "scene": 82, "line": "There you go.", "order": 15561, "speaker": "Nurse"},
  {"season": 4, "episode": 1, "scene": 82, "line": "There's nothing in here.", "order": 15562, "speaker": "Meredith Palmer"},
  {"season": 4, "episode": 1, "scene": 83, "line": "This office is cursed. [sighs] And we need to do something about it.", "order": 15563, "speaker": "Michael Scott"},
  {"season": 4, "episode": 1, "scene": 83, "line": "Conference room?", "order": 15564, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 1, "scene": 83, "line": "Maybe the conference room, maybe the annex. I'm just saying, at least right here [indicates floor in front of his office] is cursed. Conference room, 10 minutes.", "order": 15565, "speaker": "Michael Scott"},
  {"season": 4, "episode": 1, "scene": 84, "line": "On the one hand, it's a relief to know that it's not just me, the whole office is cursed. On the other hand, I may have to deal with the dark unknown.", "order": 15566, "speaker": "Michael Scott"},
  {"season": 4, "episode": 1, "scene": 85, "line": "Question: Has anyone recently offended a Gypsy?", "order": 15567, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 1, "scene": 85, "line": "We have to have done something wrong.", "order": 15568, "speaker": "Michael Scott"},
  {"season": 4, "episode": 1, "scene": 85, "line": "You ran over an employee.", "order": 15569, "speaker": "Stanley Hudson"},
  {"season": 4, "episode": 1, "scene": 85, "line": "That was the curse, Stanley, and we are trying to find the cause of the curse.", "order": 15570, "speaker": "Michael Scott"},
  {"season": 4, "episode": 1, "scene": 85, "line": "Mmm. [nods]", "order": 15571, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 1, "scene": 85, "line": "Oscar, are you Santaria?", "order": 15572, "speaker": "Michael Scott"},
  {"season": 4, "episode": 1, "scene": 85, "line": "I was raised Catholic, but I'm agnostic now, so I guess I'm a secular humanist.", "order": 15573, "speaker": "Oscar Martinez"},
  {"season": 4, "episode": 1, "scene": 85, "line": "Oh. Great. We have a secular humanist here, that is disgusting.", "order": 15574, "speaker": "Michael Scott"},
  {"season": 4, "episode": 1, "scene": 85, "line": "Michael, do you know what secular humanism is?", "order": 15575, "speaker": "Oscar Martinez"},
  {"season": 4, "episode": 1, "scene": 85, "line": "Yes. It is a philosophy which says people can improve their lives by using reason instead of religion or superstition.", "order": 15576, "speaker": "Michael Scott"},
  {"season": 4, "episode": 1, "scene": 85, "line": "Oh.", "order": 15577, "speaker": "Oscar Martinez"},
  {"season": 4, "episode": 1, "scene": 85, "line": "Stupid.", "order": 15578, "speaker": "Michael Scott"},
  {"season": 4, "episode": 1, "scene": 86, "line": "My cousin Mose's best friend was a dog. One day, he was foaming at the mouth, so I had to shoot him dead. Turns out, he had only eaten one of Mose's cream pies. Did I feel bad for killing him? No. That's how you deal with a thief.", "order": 15579, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 1, "scene": 87, "line": "Yeah, the, uh, the charity's having some financial problems. What do I look like to you, Paul Newman? That's actually not a good example, because I have been compared to a young Paul Newman, my eyes and my face. And I make my own salad dressing. I mix Newman's Ranch with Newman's Italian. Sell it at flea markets for a slight loss. I could make... I could make a profit if I changed one of the ingredients to Wishbone [shakes head] but I won't do it.", "order": 15580, "speaker": "Michael Scott"},
  {"season": 4, "episode": 1, "scene": 88, "line": "Whoa, 'scuse me! Oh!", "order": 15581, "speaker": "Michael Scott"},
  {"season": 4, "episode": 1, "scene": 88, "line": "Let... let's pick it up, Phyllis!", "order": 15582, "speaker": "Bob Vance"},
  {"season": 4, "episode": 1, "scene": 88, "line": "Oh, slow! Oh, ho, ho, ho. [laughing as he passes Phyllis and Bob] You guys are going so slow...", "order": 15583, "speaker": "Michael Scott"},
  {"season": 4, "episode": 1, "scene": 88, "line": "Go get him Bobby!", "order": 15584, "speaker": "Phyllis Vance"},
  {"season": 4, "episode": 1, "scene": 88, "line": "Come on, I'm going backwards.", "order": 15585, "speaker": "Michael Scott"},
  {"season": 4, "episode": 1, "scene": 88, "line": "Sic him!", "order": 15586, "speaker": "Phyllis Vance"},
  {"season": 4, "episode": 1, "scene": 88, "line": "Oh, all right! All right! All right! All right!", "order": 15587, "speaker": "Michael Scott"},
  {"season": 4, "episode": 1, "scene": 88, "line": "Sic him!", "order": 15588, "speaker": "Phyllis Vance"},
  {"season": 4, "episode": 1, "scene": 88, "line": "Okay! [Bob Vance and Michael in an all-out sprint, Michael grunting]", "order": 15589, "speaker": "Michael Scott"},
  {"season": 4, "episode": 1, "scene": 89, "line": "You're gonna be just fine, Michael! You're gonna have a good life.", "order": 15590, "speaker": "Pediatrician"},
  {"season": 4, "episode": 1, "scene": 89, "line": "Thanks.", "order": 15591, "speaker": "Michael Scott"},
  {"season": 4, "episode": 1, "scene": 89, "line": "Lots of people suffer dehydration; they all recover just fine.", "order": 15592, "speaker": "Pediatrician"},
  {"season": 4, "episode": 1, "scene": 90, "line": "I'm Michael's pediatrician. Dehydration's pretty simple. He could also have been treated by a Boy Scout leader, a coach, or a, a... a water bottle.", "order": 15593, "speaker": "Pediatrician"},
  {"season": 4, "episode": 1, "scene": 91, "line": "Well, apparently, my insurance will not be covering this hospital stay. I will be paying out-of-pocket. Um... I kinda wish I'd been hit by a car.", "order": 15594, "speaker": "Michael Scott"},
  {"season": 4, "episode": 1, "scene": 92, "line": "Hey, doc? You forgetting something? [pediatrician takes a lollypop from his pocket and gives it to Michael] Thank you.", "order": 15595, "speaker": "Michael Scott"},
  {"season": 4, "episode": 2, "scene": 1, "line": "Hey.", "order": 15596, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 2, "scene": 1, "line": "Hey.", "order": 15597, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 2, "scene": 1, "line": "I gotcha one. [Pam hands over a grape soda to Jim]", "order": 15598, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 2, "scene": 1, "line": "Oh wow, thank you.", "order": 15599, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 2, "scene": 1, "line": "Yeah.", "order": 15600, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 2, "scene": 1, "line": "I'm just gonna grab some chips, you want some?", "order": 15601, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 2, "scene": 1, "line": "No. Thanks... uh we're still having lunch today, right?", "order": 15602, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 2, "scene": 1, "line": "I guess. [Pam smiles and walks away, then comes back and kisses Jim on the cheek] How dare you.", "order": 15603, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 2, "scene": 2, "line": "Hey Toby. What's this? [holds up memo Toby has passed out to the staff]", "order": 15604, "speaker": "Michael Scott"},
  {"season": 4, "episode": 2, "scene": 2, "line": "Ohh.", "order": 15605, "speaker": "Toby Flenderson"},
  {"season": 4, "episode": 2, "scene": 2, "line": "[reads memo] I just want to remind everyone about the company rules involving PDA or public displays of affection.", "order": 15606, "speaker": "Michael Scott"},
  {"season": 4, "episode": 2, "scene": 2, "line": "Yes, uh some people in the office have complained...", "order": 15607, "speaker": "Toby Flenderson"},
  {"season": 4, "episode": 2, "scene": 2, "line": "Oh really.", "order": 15608, "speaker": "Michael Scott"},
  {"season": 4, "episode": 2, "scene": 2, "line": "...about some other people engaging in PDA and, you know [Michael leans in closer to Toby] I just wanted to remind it's not appropriate to, to do that.", "order": 15609, "speaker": "Toby Flenderson"},
  {"season": 4, "episode": 2, "scene": 2, "line": "Is this about me and Jan? In my office? Because I will have you know that that was consensual. What we did has nothing to do with you or anyone here. I don't think. I don't think anyone heard anything. We were very discreet and, and most people had left by that point. So I don't think it's any of your business. What I think you should do is roll up the memo, real tight...", "order": 15610, "speaker": "Michael Scott"},
  {"season": 4, "episode": 2, "scene": 2, "line": "Ok, look the memo is not about you...", "order": 15611, "speaker": "Toby Flenderson"},
  {"season": 4, "episode": 2, "scene": 2, "line": "[to everyone in the room] For the record, I have never been involved with anyone at work, in any capacity.", "order": 15612, "speaker": "Angela Martin"},
  {"season": 4, "episode": 2, "scene": 2, "line": "Alright everyone, look, it, alright my complaint was about Jim and Pam. So...", "order": 15613, "speaker": "Toby Flenderson"},
  {"season": 4, "episode": 2, "scene": 2, "line": "[turns to address Jim and Pam] No way.", "order": 15614, "speaker": "Michael Scott"},
  {"season": 4, "episode": 2, "scene": 2, "line": "What?", "order": 15615, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 2, "scene": 2, "line": "You guys are together?", "order": 15616, "speaker": "Phyllis Vance"},
  {"season": 4, "episode": 2, "scene": 2, "line": "Ummm... yup. Yes, we are.", "order": 15617, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 2, "scene": 2, "line": "Woooah! Wow!", "order": 15618, "speaker": "Michael Scott"},
  {"season": 4, "episode": 2, "scene": 2, "line": "Tuna!", "order": 15619, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 2, "scene": 2, "line": "Awesome!", "order": 15620, "speaker": "Michael Scott"},
  {"season": 4, "episode": 2, "scene": 2, "line": "I knew it!", "order": 15621, "speaker": "Kevin Malone"},
  {"season": 4, "episode": 2, "scene": 2, "line": "You guys! Yes! Yes!", "order": 15622, "speaker": "Michael Scott"},
  {"season": 4, "episode": 2, "scene": 2, "line": "Toby, was this your fun little way of congratulating us?", "order": 15623, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 2, "scene": 2, "line": "Yes.", "order": 15624, "speaker": "Toby Flenderson"},
  {"season": 4, "episode": 2, "scene": 2, "line": "Oh kay, mind is exploding. Get over here. [gestures to Pam to get up] Come on. Come here. Okay, okay, [to Jim] stand up. [Jim moves over in chair] OK, here we go. [holding both Pam and Jim's hand] Everyone, this is a day that will live in infamy. Because today, is the day that Jim and Pam become one.", "order": 15625, "speaker": "Michael Scott"},
  {"season": 4, "episode": 2, "scene": 2, "line": "Actually, we've been dating for a couple months.", "order": 15626, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 2, "scene": 2, "line": "I love you guys, so much. [hugs Jim]", "order": 15627, "speaker": "Michael Scott"},
  {"season": 4, "episode": 2, "scene": 2, "line": "Ohh.. [Michael goes to hug Pam, phone rings]", "order": 15628, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 2, "scene": 2, "line": "Phone's ringing. [goes back toward desk]", "order": 15629, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 2, "scene": 2, "line": "No, no, no Pam let 'em ring. Let the bells of Dunder Mifflin chime out your love. Because this is [starts to well up] really good, this is really good. My heart soars with the eagles nest.", "order": 15630, "speaker": "Michael Scott"},
  {"season": 4, "episode": 2, "scene": 3, "line": "I don't see it. I think they both could do better.", "order": 15631, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 2, "scene": 4, "line": "It's not a surprise to me. Pam is the office mattress.", "order": 15632, "speaker": "Angela Martin"},
  {"season": 4, "episode": 2, "scene": 5, "line": "Jim Halpert's off the market. Guess who just became the best looking single guy in the office?", "order": 15633, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 2, "scene": 6, "line": "Hey. Can you make that straighter? That's what she said.", "order": 15634, "speaker": "Michael Scott"},
  {"season": 4, "episode": 2, "scene": 6, "line": "Did you plan it?", "order": 15635, "speaker": "Phyllis Vance"},
  {"season": 4, "episode": 2, "scene": 6, "line": "No. [tries to hide piece of paper, but Pam grabs it]", "order": 15636, "speaker": "Michael Scott"},
  {"season": 4, "episode": 2, "scene": 6, "line": "[reading from paper] Can you make that straighter? That job looks hard. You should put your mouth on that. How can you even use that one naturally?", "order": 15637, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 2, "scene": 6, "line": "Blowing up balloons I thought.", "order": 15638, "speaker": "Michael Scott"},
  {"season": 4, "episode": 2, "scene": 6, "line": "You might want to trim it a little.", "order": 15639, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 2, "scene": 6, "line": "Michael... [Kelly enters conference room in a flirty red dress]", "order": 15640, "speaker": "Phyllis Vance"},
  {"season": 4, "episode": 2, "scene": 6, "line": "[reading sign] Oh, is... Ryan coming back today?", "order": 15641, "speaker": "Kelly Kapoor"},
  {"season": 4, "episode": 2, "scene": 6, "line": "Yeah, he is.", "order": 15642, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 2, "scene": 6, "line": "Oh.", "order": 15643, "speaker": "Kelly Kapoor"},
  {"season": 4, "episode": 2, "scene": 7, "line": "Pam and Jim are together. Ryan is visiting. Only thing that could make this day better is ice cream.", "order": 15644, "speaker": "Michael Scott"},
  {"season": 4, "episode": 2, "scene": 8, "line": "What do you want?", "order": 15645, "speaker": "Angela Martin"},
  {"season": 4, "episode": 2, "scene": 8, "line": "To give you this [reveals a cat from under a coat].", "order": 15646, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 2, "scene": 8, "line": "Oh, what is that?", "order": 15647, "speaker": "Angela Martin"},
  {"season": 4, "episode": 2, "scene": 8, "line": "It's a feral barn cat. I trapped him last night and I'm giving him to you as a replacement cat for the one I destroyed.", "order": 15648, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 2, "scene": 8, "line": "Her name was Sprinkles.", "order": 15649, "speaker": "Angela Martin"},
  {"season": 4, "episode": 2, "scene": 8, "line": "And his name is... Garbage. Mose calls him Garbage because he likes to eat garbage. [shakes cat] Don't you Garbage? [makes chomping noises]", "order": 15650, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 2, "scene": 8, "line": "I can't believe you just thought you could replace Sprinkles. Before she's even in the ground.", "order": 15651, "speaker": "Angela Martin"},
  {"season": 4, "episode": 2, "scene": 8, "line": "You haven't buried her yet?", "order": 15652, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 2, "scene": 8, "line": "Don't rush me. I'm grieving.", "order": 15653, "speaker": "Angela Martin"},
  {"season": 4, "episode": 2, "scene": 8, "line": "Garbage can be very helpful. OK, he's a youthful cat. He killed an entire family of raccoons. [holds cat towards Angela] Look at him.", "order": 15654, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 2, "scene": 8, "line": "I don't want Garbage! I want Sprinkles! [walks away]", "order": 15655, "speaker": "Angela Martin"},
  {"season": 4, "episode": 2, "scene": 9, "line": "Hey Toby.", "order": 15656, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 2, "scene": 9, "line": "Hey... [sees both Jim and Pam] you two.", "order": 15657, "speaker": "Toby Flenderson"},
  {"season": 4, "episode": 2, "scene": 9, "line": "Hey. So now that we are dating, uh, we just wanted to know if we had to sign one of those 'we're dating' things for the company.", "order": 15658, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 2, "scene": 9, "line": "Oh well, you know, those were only for, you know [makes quotes with fingers] relationships, so... if, if this is just a casual thing, there's no need, really.", "order": 15659, "speaker": "Toby Flenderson"},
  {"season": 4, "episode": 2, "scene": 9, "line": "Oh.", "order": 15660, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 2, "scene": 9, "line": "Well, I don't wanna speak for Jim, but, it's like pretty official. [Jim smiles]", "order": 15661, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 2, "scene": 9, "line": "Uh huh.", "order": 15662, "speaker": "Toby Flenderson"},
  {"season": 4, "episode": 2, "scene": 9, "line": "Sorry, uh do we need to sign one, or...?", "order": 15663, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 2, "scene": 9, "line": "Let's just wait and see what happens. [whispers] You know?", "order": 15664, "speaker": "Toby Flenderson"},
  {"season": 4, "episode": 2, "scene": 9, "line": "What?", "order": 15665, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 2, "scene": 9, "line": "Let's just wait.", "order": 15666, "speaker": "Toby Flenderson"},
  {"season": 4, "episode": 2, "scene": 9, "line": "Oh, OK.", "order": 15667, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 2, "scene": 9, "line": "OK.", "order": 15668, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 2, "scene": 9, "line": "Great. [Jim and Pam walk away]", "order": 15669, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 2, "scene": 10, "line": "[sees Ryan coming through door] Hey Ryan. Welcome back --", "order": 15670, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 2, "scene": 10, "line": "Hold on one second. [Ryan types on Blackberry for a few seconds] Hey Pam! It's great to see you. Is Michael in?", "order": 15671, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 2, "scene": 10, "line": "Hey!", "order": 15672, "speaker": "Michael Scott"},
  {"season": 4, "episode": 2, "scene": 10, "line": "Yeah.", "order": 15673, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 2, "scene": 10, "line": "There he is! There he is! He's back! And he's with a beard. [laughing] He... He has facial hair. Look at him! All grown up and no place to go. Hello, Mr. Sunny Crockett. I'm Tubs.", "order": 15674, "speaker": "Michael Scott"},
  {"season": 4, "episode": 2, "scene": 10, "line": "OK. Should we get started?", "order": 15675, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 2, "scene": 10, "line": "Ohh, yeah, let's get started because uh, yes, cause uh this is very serious business and umm..", "order": 15676, "speaker": "Michael Scott"},
  {"season": 4, "episode": 2, "scene": 10, "line": "Yep, exactly. This is a business meeting.", "order": 15677, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 2, "scene": 10, "line": "...business meeting --", "order": 15678, "speaker": "Michael Scott"},
  {"season": 4, "episode": 2, "scene": 10, "line": "[rubbing hands in Ryan's hair] Fire!", "order": 15679, "speaker": "Kevin Malone"},
  {"season": 4, "episode": 2, "scene": 10, "line": "Stop that! Stop that!", "order": 15680, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 2, "scene": 10, "line": "That's right! That's right!", "order": 15681, "speaker": "Michael Scott"},
  {"season": 4, "episode": 2, "scene": 10, "line": "[to Kevin] You scared me.", "order": 15682, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 2, "scene": 10, "line": "Fire guy. Don't start any fires, Ryan.", "order": 15683, "speaker": "Michael Scott"},
  {"season": 4, "episode": 2, "scene": 10, "line": "Fire guy [makes flames with his hands]", "order": 15684, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 2, "scene": 10, "line": "You weren't here for that.", "order": 15685, "speaker": "Kevin Malone"},
  {"season": 4, "episode": 2, "scene": 10, "line": "Here for what?", "order": 15686, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 2, "scene": 10, "line": "When he started the fire.", "order": 15687, "speaker": "Kevin Malone"},
  {"season": 4, "episode": 2, "scene": 10, "line": "Look how big he is. Look at you, you are so mature and old and little man now. You're like our little man...", "order": 15688, "speaker": "Michael Scott"},
  {"season": 4, "episode": 2, "scene": 10, "line": "Little old man boy.", "order": 15689, "speaker": "Kevin Malone"},
  {"season": 4, "episode": 2, "scene": 10, "line": "Michael and everybody, umm...", "order": 15690, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 2, "scene": 10, "line": "Beard.", "order": 15691, "speaker": "Michael Scott"},
  {"season": 4, "episode": 2, "scene": 10, "line": "Bearded man boy.", "order": 15692, "speaker": "Kevin Malone"},
  {"season": 4, "episode": 2, "scene": 10, "line": "...let me just say something. I know I used to be a temp here, but now everything is different. I'd like your respect. I am your boss now. You're gonna have to treat me that same way you treated Jan.", "order": 15693, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 2, "scene": 10, "line": "Oh, wow!", "order": 15694, "speaker": "Michael Scott"},
  {"season": 4, "episode": 2, "scene": 10, "line": "So...", "order": 15695, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 2, "scene": 10, "line": "That's a little kinky. I don't swing that way.", "order": 15696, "speaker": "Michael Scott"},
  {"season": 4, "episode": 2, "scene": 10, "line": "OK...", "order": 15697, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 2, "scene": 10, "line": "Woooo![laughs] I think Ryan has a gay crush on me.", "order": 15698, "speaker": "Michael Scott"},
  {"season": 4, "episode": 2, "scene": 10, "line": "Enough! OK? This is inappropriate and it stops right now. Do you understand?", "order": 15699, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 2, "scene": 10, "line": "Yes, everybody, come on. Settle town. Let's get serious here. Um Ryan, has a very special, important presentation to do, which we will be doing in the conference room in [looks at Ryan] 10 minutes?", "order": 15700, "speaker": "Michael Scott"},
  {"season": 4, "episode": 2, "scene": 10, "line": "Perfect.", "order": 15701, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 2, "scene": 10, "line": "Sounds good. OK, alright.", "order": 15702, "speaker": "Michael Scott"},
  {"season": 4, "episode": 2, "scene": 11, "line": "Yeah, Ryan snapped at me, but there was this twinkle in his eye that I picked up on, which said, 'Dude, we're friends. I'm doing this for appearances. I am the big boss now. And I have to seem like an ogre. But you know me and you trust me. And we like each other. And we'll always be friends. And I would never take you for granted in a million years. And I miss you, man. And I love you.' His words.", "order": 15703, "speaker": "Michael Scott"},
  {"season": 4, "episode": 2, "scene": 12, "line": "Dunder Mifflin Infinity represents a floor to ceiling streamlining of our business model. The center piece of the campaign is a new business-to-business website interface that will allow us to compete directly with big box chains.", "order": 15704, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 2, "scene": 13, "line": "Wait a second. Last time I checked, Dunder Mifflin already has a website. And quite frankly, I'm not really sure what's wrong with it. [Jim at his desk, showing the Dunder Mifflin website reading 'Under Construction. Coming Christmas 2002!']", "order": 15705, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 2, "scene": 14, "line": "This is a massive overhaul. We're getting younger. Sleeker. And more agile so that we adapt to the market place. All essential personnel will be issued Blackberries for company use.", "order": 15706, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 2, "scene": 14, "line": "OOhh. Gimmme, gimme, gimme, gimme, gimme, gimme.", "order": 15707, "speaker": "Michael Scott"},
  {"season": 4, "episode": 2, "scene": 14, "line": "I'll stick around to help you set them up afterwards. Any questions? Dwight?", "order": 15708, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 2, "scene": 14, "line": "What if we don't want to use a Blackberry because they are stupid and pointless?", "order": 15709, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 2, "scene": 14, "line": "This is company-wide, Dwight.", "order": 15710, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 2, "scene": 14, "line": "Got it. [Andy's hand goes up]", "order": 15711, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 2, "scene": 14, "line": "Andy.", "order": 15712, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 2, "scene": 14, "line": "We should call it Dunder Mifflinfinity. You know, push the words together?", "order": 15713, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 2, "scene": 14, "line": "Any other questions? [Kelly's hand goes up] Kelly Kapoor.", "order": 15714, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 2, "scene": 14, "line": "Can we speak privately about our relationship?", "order": 15715, "speaker": "Kelly Kapoor"},
  {"season": 4, "episode": 2, "scene": 14, "line": "Thank you everybody.", "order": 15716, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 2, "scene": 14, "line": "Ryan Howard everybody. [starts clapping] Good job. [everyone gets up to leave]", "order": 15717, "speaker": "Michael Scott"},
  {"season": 4, "episode": 2, "scene": 14, "line": "That's some fun stuff. When does the website go up?", "order": 15718, "speaker": "Creed Bratton"},
  {"season": 4, "episode": 2, "scene": 14, "line": "As fast as possible. We want to start retraining people A.S.A.P., so we can hit the ground running with a new system.", "order": 15719, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 2, "scene": 14, "line": "Cool beans.", "order": 15720, "speaker": "Creed Bratton"},
  {"season": 4, "episode": 2, "scene": 15, "line": "We're screwed.", "order": 15721, "speaker": "Creed Bratton"},
  {"season": 4, "episode": 2, "scene": 15, "line": "Who is?", "order": 15722, "speaker": "Michael Scott"},
  {"season": 4, "episode": 2, "scene": 15, "line": "Us? You and me. The old timers.", "order": 15723, "speaker": "Creed Bratton"},
  {"season": 4, "episode": 2, "scene": 15, "line": "I am not old. You are old. You are like a hundred.", "order": 15724, "speaker": "Michael Scott"},
  {"season": 4, "episode": 2, "scene": 15, "line": "You're over 40, that's the cut off. Are you listening to what he's saying? Re-training. New system. Youth. I'm telling you this kid is the grim reaper. You deal with this or you, me, Sammy, Phyllis, the chick you hit with the car, we're goners.", "order": 15725, "speaker": "Creed Bratton"},
  {"season": 4, "episode": 2, "scene": 16, "line": "Swore I wouldn't tell anyone this, but in the interest of revealing secrets. Oh my God, this will make your brain explode. Umm, Dwight and Angela dating. Have been for six months.", "order": 15726, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 2, "scene": 16, "line": "No.. [in awe]", "order": 15727, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 2, "scene": 16, "line": "Swear to God. [Pam shakes her head]. Aww this is great. I was actually gonna wait and tell you on your birthday, but this is much more fun.", "order": 15728, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 2, "scene": 16, "line": "No, they have been dating for like two years. [Jim in shock] Since before your barbeque.", "order": 15729, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 2, "scene": 16, "line": "Wait. What? [Pam nods her head] You knew? And you didn't say anything?", "order": 15730, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 2, "scene": 16, "line": "You didn't say anything to me?", "order": 15731, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 2, "scene": 16, "line": "Fair enough. Wow! We should have started dating like a long time ago.", "order": 15732, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 2, "scene": 16, "line": "Can you believe that...", "order": 15733, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 2, "scene": 16, "line": "Sorry, I didn't know you guys were in here.", "order": 15734, "speaker": "Phyllis Vance"},
  {"season": 4, "episode": 2, "scene": 16, "line": "Oh no, we're just sitting here.", "order": 15735, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 2, "scene": 16, "line": "I couldn't see your hands. [Jim shakes his hands] Hey Pam, by the way, it's great that you're dating. But when a new client calls, you just have to randomly assign them to a sales person. You can't base who gets new clients on who you're sleeping with that week, OK?", "order": 15736, "speaker": "Phyllis Vance"},
  {"season": 4, "episode": 2, "scene": 16, "line": "OK.", "order": 15737, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 2, "scene": 16, "line": "OK.", "order": 15738, "speaker": "Phyllis Vance"},
  {"season": 4, "episode": 2, "scene": 16, "line": "OK.", "order": 15739, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 2, "scene": 17, "line": "And... that is why we waited so long to tell people.", "order": 15740, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 2, "scene": 18, "line": "OK, what's up?", "order": 15741, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 2, "scene": 18, "line": "Yeah, kay. I was just... After the presentation, just wanted to make sure, that vis-a-vie, that everything in the office is business as usual?", "order": 15742, "speaker": "Michael Scott"},
  {"season": 4, "episode": 2, "scene": 18, "line": "Well it is business, but not as usual.", "order": 15743, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 2, "scene": 18, "line": "Yeah, I know I understand... we're making great strides and we're updating, but business as usual, no?", "order": 15744, "speaker": "Michael Scott"},
  {"season": 4, "episode": 2, "scene": 18, "line": "No. [shaking head] We're throwing out the entire playbook, we're starting from scratch, we're implementing a brand new system.", "order": 15745, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 2, "scene": 18, "line": "Good, so, we're on the same page?", "order": 15746, "speaker": "Michael Scott"},
  {"season": 4, "episode": 2, "scene": 18, "line": "No. We're not. Michael, I know exactly how much time and man power are wasted in this branch. This company is getting younger, faster, more efficient. You need to prepare yourself.", "order": 15747, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 2, "scene": 19, "line": "We had a foreign exchange student live with us when I was young. And we called him my brother. And that's who I thought he was. Um, then he went home to what is now formerly Yugoslavia, taking all of my blue jeans with him. And I had to spend the entire winter in shorts. That is what Ryan is like. A fake brother who steals your jeans.", "order": 15748, "speaker": "Michael Scott"},
  {"season": 4, "episode": 2, "scene": 20, "line": "So, how are you?", "order": 15749, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 2, "scene": 20, "line": "Awesome. I am dating a lot of guys.", "order": 15750, "speaker": "Kelly Kapoor"},
  {"season": 4, "episode": 2, "scene": 20, "line": "Good.", "order": 15751, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 2, "scene": 20, "line": "A lot. Black guys mostly.", "order": 15752, "speaker": "Kelly Kapoor"},
  {"season": 4, "episode": 2, "scene": 20, "line": "Kelly...", "order": 15753, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 2, "scene": 20, "line": "What?!", "order": 15754, "speaker": "Kelly Kapoor"},
  {"season": 4, "episode": 2, "scene": 21, "line": "Wait, uh, how do you touch just one of these buttons at a time?", "order": 15755, "speaker": "Phyllis Vance"},
  {"season": 4, "episode": 2, "scene": 21, "line": "I don't know.", "order": 15756, "speaker": "Stanley Hudson"},
  {"season": 4, "episode": 2, "scene": 21, "line": "Did you even try?", "order": 15757, "speaker": "Phyllis Vance"},
  {"season": 4, "episode": 2, "scene": 21, "line": "If the kid wants to set mine up, I'll let him.", "order": 15758, "speaker": "Stanley Hudson"},
  {"season": 4, "episode": 2, "scene": 21, "line": "I can't see half of the things. [adjusts glasses]", "order": 15759, "speaker": "Phyllis Vance"},
  {"season": 4, "episode": 2, "scene": 21, "line": "It's too little. Use the phone.", "order": 15760, "speaker": "Stanley Hudson"},
  {"season": 4, "episode": 2, "scene": 22, "line": "I want you to tell me that you care about me. That is what I want.", "order": 15761, "speaker": "Kelly Kapoor"},
  {"season": 4, "episode": 2, "scene": 22, "line": "Kelly, I'm your boss now, OK? You can't keep talking to me like I'm your boyfriend.", "order": 15762, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 2, "scene": 22, "line": "Oh big strong man, fancy new whatever. I don't think you ever cared about me.", "order": 15763, "speaker": "Kelly Kapoor"},
  {"season": 4, "episode": 2, "scene": 22, "line": "I never cared about you? Six months ago Karen Filipelli sent me an email. Asked me out. I said no, because I was committed to our relationship.", "order": 15764, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 2, "scene": 22, "line": "Well, I hope you're still committed because I'm pregnant.", "order": 15765, "speaker": "Kelly Kapoor"},
  {"season": 4, "episode": 2, "scene": 23, "line": "[shaking head]", "order": 15766, "speaker": "Kelly Kapoor"},
  {"season": 4, "episode": 2, "scene": 24, "line": "And guess what buddy, [points at Ryan] I am keeping it.", "order": 15767, "speaker": "Kelly Kapoor"},
  {"season": 4, "episode": 2, "scene": 24, "line": "OK. OK.", "order": 15768, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 2, "scene": 24, "line": "Do you feel prepared to help me raise a baby?", "order": 15769, "speaker": "Kelly Kapoor"},
  {"season": 4, "episode": 2, "scene": 24, "line": "I can... I can't talk about this right now, OK? After work, we'll go out to dinner, we'll talk about it then, OK?", "order": 15770, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 2, "scene": 25, "line": "We have a date!", "order": 15771, "speaker": "Kelly Kapoor"},
  {"season": 4, "episode": 2, "scene": 26, "line": "Hello.", "order": 15772, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 2, "scene": 26, "line": "Hello, Dwight. I've been thinking about things and I wanted to know if you would have dinner with me tonight?", "order": 15773, "speaker": "Angela Martin"},
  {"season": 4, "episode": 2, "scene": 26, "line": "Really?", "order": 15774, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 2, "scene": 26, "line": "Yes.", "order": 15775, "speaker": "Angela Martin"},
  {"season": 4, "episode": 2, "scene": 26, "line": "I'll make a reservation. No, no. Let me cook for you. Cauliflower and noodles. Baked potato on the side.", "order": 15776, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 2, "scene": 26, "line": "I would prefer a public place. See you after work.", "order": 15777, "speaker": "Angela Martin"},
  {"season": 4, "episode": 2, "scene": 27, "line": "Hi, Pam.", "order": 15778, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 2, "scene": 27, "line": "Hi.", "order": 15779, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 2, "scene": 27, "line": "Is Michael in?", "order": 15780, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 2, "scene": 27, "line": "In his office. You can go right in.", "order": 15781, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 2, "scene": 27, "line": "[to Ryan] Hey.", "order": 15782, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 2, "scene": 27, "line": "Jan.", "order": 15783, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 2, "scene": 27, "line": "Ryan. Ryan. Ryan, Ryan.", "order": 15784, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 2, "scene": 27, "line": "So elephant in the room, I have your old job.", "order": 15785, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 2, "scene": 27, "line": "Well, not exactly my job... I had a different title.", "order": 15786, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 2, "scene": 27, "line": "Oh well, excuse me, same office, same responsibilities.", "order": 15787, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 2, "scene": 27, "line": "Different salary. [laughs] You'll get there, don't worry.", "order": 15788, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 2, "scene": 27, "line": "Well... you look great.", "order": 15789, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 2, "scene": 27, "line": "Thank you, thank you.", "order": 15790, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 2, "scene": 27, "line": "Scranton suits you.", "order": 15791, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 2, "scene": 27, "line": "Best decision I ever made.", "order": 15792, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 2, "scene": 27, "line": "You were let go.", "order": 15793, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 2, "scene": 27, "line": "You know what? I love the beard. Keep it forever. [goes into Michael's office]", "order": 15794, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 2, "scene": 27, "line": "Hey.", "order": 15795, "speaker": "Michael Scott"},
  {"season": 4, "episode": 2, "scene": 27, "line": "Hey.", "order": 15796, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 2, "scene": 28, "line": "[holds up Blackberry] Hey, what is the actual deal with these things in terms of testicles?", "order": 15797, "speaker": "Michael Scott"},
  {"season": 4, "episode": 2, "scene": 28, "line": "What?", "order": 15798, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 2, "scene": 28, "line": "I don't want to grow weird sperm in case we ever want to have kids.", "order": 15799, "speaker": "Michael Scott"},
  {"season": 4, "episode": 2, "scene": 28, "line": "So, what's Ryan doing here?", "order": 15800, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 2, "scene": 28, "line": "Oh, I dunno, they're launching a big new business plan. New website, blah blah blah. He's being a real twerp about it, so, it's all about youth, and agility and streamlining and trying to squeeze out the older people.", "order": 15801, "speaker": "Michael Scott"},
  {"season": 4, "episode": 2, "scene": 28, "line": "He's such a snake.", "order": 15802, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 2, "scene": 28, "line": "Well...", "order": 15803, "speaker": "Michael Scott"},
  {"season": 4, "episode": 2, "scene": 28, "line": "I hope he's gets hit with an ageism suit.", "order": 15804, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 2, "scene": 28, "line": "What is that... word?", "order": 15805, "speaker": "Michael Scott"},
  {"season": 4, "episode": 2, "scene": 28, "line": "Ageism? Companies they can't discriminate against people due to old age. Like a couple years ago we tried to force out some of the older branch managers with a mandatory retirement age and then Ed Truck, your old boss, threatened us with a lawsuit, so we had to back off.", "order": 15806, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 2, "scene": 28, "line": "So older people have just as many rights as younger people?", "order": 15807, "speaker": "Michael Scott"},
  {"season": 4, "episode": 2, "scene": 28, "line": "Yes, Michael, they do.", "order": 15808, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 2, "scene": 29, "line": "Creed?", "order": 15809, "speaker": "Oscar Martinez"},
  {"season": 4, "episode": 2, "scene": 29, "line": "Yes, sir.", "order": 15810, "speaker": "Creed Bratton"},
  {"season": 4, "episode": 2, "scene": 29, "line": "Everything OK? [Creed has made his hair jet black]", "order": 15811, "speaker": "Oscar Martinez"},
  {"season": 4, "episode": 2, "scene": 29, "line": "Everything's cool, dude.", "order": 15812, "speaker": "Creed Bratton"},
  {"season": 4, "episode": 2, "scene": 30, "line": "I'm thirty. Well, in November I'll be thirty.", "order": 15813, "speaker": "Creed Bratton"},
  {"season": 4, "episode": 2, "scene": 31, "line": "Is there another meeting scheduled, I was gonna do the Blackberry tutorial in here.", "order": 15814, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 2, "scene": 31, "line": "Michael told us to wait in here. We don't know why.", "order": 15815, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 2, "scene": 31, "line": "[notices pictures on the wall] Ohh... man.", "order": 15816, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 2, "scene": 31, "line": "Good, we're all here, we can get started.", "order": 15817, "speaker": "Michael Scott"},
  {"season": 4, "episode": 2, "scene": 31, "line": "Michael.", "order": 15818, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 2, "scene": 31, "line": "Have a seat.", "order": 15819, "speaker": "Michael Scott"},
  {"season": 4, "episode": 2, "scene": 31, "line": "We're not doing this today.", "order": 15820, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 2, "scene": 31, "line": "Have a seat. Like everybody else.", "order": 15821, "speaker": "Michael Scott"},
  {"season": 4, "episode": 2, "scene": 31, "line": "OK. This is...", "order": 15822, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 2, "scene": 31, "line": "Still my office, Ryan. [Ryan sits down] Well, there has been a lot of talk about new ideas today. Well, new ideas are fine, but they are also... illegal, because they are a form of ageism. What? Yes, I am right. Did you know that the Age Discrimination and Employment Act of 1967 prohibits employment discrimination based on age with respect to employees 40 years of age or older? I did.", "order": 15823, "speaker": "Michael Scott"},
  {"season": 4, "episode": 2, "scene": 31, "line": "Technically, he's right.", "order": 15824, "speaker": "Toby Flenderson"},
  {"season": 4, "episode": 2, "scene": 31, "line": "Hey, shut up Toby. Look, why do we as a society hate old people so much?", "order": 15825, "speaker": "Michael Scott"},
  {"season": 4, "episode": 2, "scene": 31, "line": "Because they're lame.", "order": 15826, "speaker": "Creed Bratton"},
  {"season": 4, "episode": 2, "scene": 31, "line": "No, Creed, no they are not. In fact, many cultures revere old people because of their storytelling ability. Like the old lady from Titanic. [points to her picture on the wall] Or the funny things that they can do, like 'where's the Beef?' [points to another picture on wall] [Jim raises his hand] Yeah.", "order": 15827, "speaker": "Michael Scott"},
  {"season": 4, "episode": 2, "scene": 31, "line": "Why do you have the Big picture up again? You used that already, when you burned your foot.", "order": 15828, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 2, "scene": 31, "line": "Reusing the Ben Kingsley, too.", "order": 15829, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 2, "scene": 31, "line": "I was going to put up some new pictures, but umm, all of the ink in the printer was gone.", "order": 15830, "speaker": "Michael Scott"},
  {"season": 4, "episode": 2, "scene": 31, "line": "Oh. [man enters conference room]", "order": 15831, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 2, "scene": 31, "line": "Michael Scott?", "order": 15832, "speaker": "Robert California Dunder"},
  {"season": 4, "episode": 2, "scene": 31, "line": "[puts hand up] That is me. Come on in. [They shake hands] Who is this old fart? Did you just stagger off the street? Out of a box or something? Who's this worthless bag of bones? Well, this guy is none other than one of the founders of Dunder Mifflin, Mr. Robert Dunder. Huh? [starts clapping, others join in]", "order": 15833, "speaker": "Michael Scott"},
  {"season": 4, "episode": 2, "scene": 31, "line": "Oh, yeah. Yes!", "order": 15834, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 2, "scene": 31, "line": "Thank you everyone.", "order": 15835, "speaker": "Robert California Dunder"},
  {"season": 4, "episode": 2, "scene": 31, "line": "Michael, [gets up] can I talk to you a second?", "order": 15836, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 2, "scene": 31, "line": "Sure thing. [both go out of conference room] 'Scuse me. [closes door]", "order": 15837, "speaker": "Michael Scott"},
  {"season": 4, "episode": 2, "scene": 32, "line": "We have actual work to do.", "order": 15838, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 2, "scene": 32, "line": "Fine. Then I will call David Wallace and you can explain to him why you threw the founder of the company out on his ancient butt. [they glare at each other]", "order": 15839, "speaker": "Michael Scott"},
  {"season": 4, "episode": 2, "scene": 33, "line": "Bob, how old are you?", "order": 15840, "speaker": "Michael Scott"},
  {"season": 4, "episode": 2, "scene": 33, "line": "I'm 87.", "order": 15841, "speaker": "Robert California Dunder"},
  {"season": 4, "episode": 2, "scene": 33, "line": "Eighty-seven years young. And still active. That is great. Did you know, that Bob is still a member of the Board of Dunder Mifflin?", "order": 15842, "speaker": "Michael Scott"},
  {"season": 4, "episode": 2, "scene": 33, "line": "Well, I, I, I haven't been to a board meeting in years. I, I send a proxy.", "order": 15843, "speaker": "Robert California Dunder"},
  {"season": 4, "episode": 2, "scene": 33, "line": "Ah, still sends his own proxy. Good for you.", "order": 15844, "speaker": "Michael Scott"},
  {"season": 4, "episode": 2, "scene": 34, "line": "I'm gonna live for a very long time. My grandma Schrute lived to be 101. My grandpa Manheim is 103, and still puttering around in Argentina. I tried to go visit him once, but my travel visa was protested by the Shoah Foundation.", "order": 15845, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 2, "scene": 35, "line": "I started this company in 1949.", "order": 15846, "speaker": "Robert California Dunder"},
  {"season": 4, "episode": 2, "scene": 35, "line": "Wow.", "order": 15847, "speaker": "Michael Scott"},
  {"season": 4, "episode": 2, "scene": 35, "line": "Back then, it was an uh, an industrial supplier of metal brackets mostly for, for construction.", "order": 15848, "speaker": "Robert California Dunder"},
  {"season": 4, "episode": 2, "scene": 35, "line": "Oh, boy.", "order": 15849, "speaker": "Michael Scott"},
  {"season": 4, "episode": 2, "scene": 35, "line": "And then Mifflin, of course he killed himself later... Uh, but I knew Mifflin through the Rotary Club.", "order": 15850, "speaker": "Robert California Dunder"},
  {"season": 4, "episode": 2, "scene": 35, "line": "Great.", "order": 15851, "speaker": "Michael Scott"},
  {"season": 4, "episode": 2, "scene": 35, "line": "And he was, he was [starts laughing] at dinner with Beverly and her husband, wha-what was his name, umm... uhh... Jerry.. Jerry Trupiano from, from South Jersey and he was tall. Both he and Mifflin were tall guys.", "order": 15852, "speaker": "Robert California Dunder"},
  {"season": 4, "episode": 2, "scene": 35, "line": "Great.", "order": 15853, "speaker": "Michael Scott"},
  {"season": 4, "episode": 2, "scene": 35, "line": "And...", "order": 15854, "speaker": "Robert California Dunder"},
  {"season": 4, "episode": 2, "scene": 35, "line": "That's great. Thank you for coming in. [starts ushering him out] Robert Dunder everybody. [clapping] Thank you. That was wonderful. Do you have a ride?", "order": 15855, "speaker": "Michael Scott"},
  {"season": 4, "episode": 2, "scene": 35, "line": "Well I, I, I came here in a cab.", "order": 15856, "speaker": "Robert California Dunder"},
  {"season": 4, "episode": 2, "scene": 35, "line": "Perfect. [starts closing the door]", "order": 15857, "speaker": "Michael Scott"},
  {"season": 4, "episode": 2, "scene": 35, "line": "Well, cou, could you get me another... [Michael closes door]", "order": 15858, "speaker": "Robert California Dunder"},
  {"season": 4, "episode": 2, "scene": 35, "line": "Inspirational. What have we learned? Well, we have learned that you can't teach an old dog new tricks, because it's illegal, and you will go to jail. [Pam raises hand]", "order": 15859, "speaker": "Michael Scott"},
  {"season": 4, "episode": 2, "scene": 35, "line": "I think that I should help him get home.", "order": 15860, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 2, "scene": 35, "line": "No, No. Don't help him. He doesn't need help, Pam. [Jim nods head and Pam gets up and leaves to help Robert Dunder, Michael shows Robert thumbs up] What a nice guy.", "order": 15861, "speaker": "Michael Scott"},
  {"season": 4, "episode": 2, "scene": 36, "line": "Good night guys. [staff leaves the office]", "order": 15862, "speaker": "Michael Scott"},
  {"season": 4, "episode": 2, "scene": 36, "line": "Well, today was a fantastic waste of time.", "order": 15863, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 2, "scene": 36, "line": "I disagree, I think it was very valuable.", "order": 15864, "speaker": "Michael Scott"},
  {"season": 4, "episode": 2, "scene": 36, "line": "Michael, technology helps business OK? You should not resist it, this is the way the world is moving.", "order": 15865, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 2, "scene": 36, "line": "I happen to think the old ways of doing business are better. And I can prove it.", "order": 15866, "speaker": "Michael Scott"},
  {"season": 4, "episode": 2, "scene": 36, "line": "Ok. I look forward to hearing your ideas. [Michael retreats back to office] [to Kelly] Where do you wanna go?", "order": 15867, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 2, "scene": 36, "line": "You know, some place romantic and expensive.", "order": 15868, "speaker": "Kelly Kapoor"},
  {"season": 4, "episode": 2, "scene": 36, "line": "Kelly, come on.", "order": 15869, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 2, "scene": 36, "line": "You know what, you're right. I'm feeling kind of nauseous anyway. So, you know skip it. [Ryan hesitates and takes her hand and they exit]", "order": 15870, "speaker": "Kelly Kapoor"},
  {"season": 4, "episode": 2, "scene": 37, "line": "Are you enjoying your vegetarian noodles?", "order": 15871, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 2, "scene": 37, "line": "Very much. How's your meat?", "order": 15872, "speaker": "Angela Martin"},
  {"season": 4, "episode": 2, "scene": 37, "line": "Dry. Delicious.", "order": 15873, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 2, "scene": 37, "line": "I heard a joke today.", "order": 15874, "speaker": "Angela Martin"},
  {"season": 4, "episode": 2, "scene": 37, "line": "Oh, that's funny.", "order": 15875, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 2, "scene": 37, "line": "Yes, it was.", "order": 15876, "speaker": "Angela Martin"},
  {"season": 4, "episode": 2, "scene": 37, "line": "Are you enjoying your mineral water?", "order": 15877, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 2, "scene": 37, "line": "I can't do this. I can't be with you. Every time I look in your eyes I see Sprinkles' stiff lifeless body.", "order": 15878, "speaker": "Angela Martin"},
  {"season": 4, "episode": 2, "scene": 37, "line": "Then don't look in my eyes. Look right here [points to middle of forehead above the eyes], it's an old sales trick.", "order": 15879, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 2, "scene": 37, "line": "I'm sorry. I gave this everything I could.", "order": 15880, "speaker": "Angela Martin"},
  {"season": 4, "episode": 2, "scene": 37, "line": "No, please don't do this, monkey.", "order": 15881, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 2, "scene": 37, "line": "I will leave your toothbrush on top of your tire tomorrow morning. [gets up and leaves restaurant]", "order": 15882, "speaker": "Angela Martin"},
  {"season": 4, "episode": 2, "scene": 38, "line": "Hey guys. This is my, uh, girlfriend, Amy.", "order": 15883, "speaker": "Toby Flenderson"},
  {"season": 4, "episode": 2, "scene": 38, "line": "Hi.", "order": 15884, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 2, "scene": 38, "line": "Hey, Amy. How ya doing?", "order": 15885, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 2, "scene": 38, "line": "Nice to meet you.", "order": 15886, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 2, "scene": 38, "line": "[under his breath] Nice to meet you.", "order": 15887, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 2, "scene": 38, "line": "[gesturing to the rest of the office] This is everybody else... okay... This is the place... so thanks for the lift.", "order": 15888, "speaker": "Toby Flenderson"},
  {"season": 4, "episode": 2, "scene": 38, "line": "Yeah, sure. I'll, uh, I'll see you tonight, right?", "order": 15889, "speaker": "Amy"},
  {"season": 4, "episode": 2, "scene": 38, "line": "Absolutely. [Toby kisses Amy for an extended period of time, Pam and Jim are shocked]", "order": 15890, "speaker": "Toby Flenderson"},
  {"season": 4, "episode": 2, "scene": 38, "line": "Whoa. Easy tiger.", "order": 15891, "speaker": "Amy"},
  {"season": 4, "episode": 2, "scene": 38, "line": "I just really like you.", "order": 15892, "speaker": "Toby Flenderson"},
  {"season": 4, "episode": 2, "scene": 38, "line": "Okay. Bye guys. Nice to meet you.", "order": 15893, "speaker": "Amy"},
  {"season": 4, "episode": 2, "scene": 38, "line": "Have a great day!", "order": 15894, "speaker": "Toby Flenderson"},
  {"season": 4, "episode": 2, "scene": 38, "line": "Whoa, Toby. Watch out. You're going to violate your own PDA memo.", "order": 15895, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 2, "scene": 38, "line": "I wouldn't want to do that now would I?", "order": 15896, "speaker": "Toby Flenderson"},
  {"season": 4, "episode": 2, "scene": 39, "line": "Smell that. Do you smell that?", "order": 15897, "speaker": "Michael Scott"},
  {"season": 4, "episode": 2, "scene": 39, "line": "Dry rot?", "order": 15898, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 2, "scene": 39, "line": "No, Dwight. That smells like good business to me. What I have done here is I have collected all the finest gourmet items that Scranton has to offer.", "order": 15899, "speaker": "Michael Scott"},
  {"season": 4, "episode": 2, "scene": 39, "line": "Mmhmm, sweet, chocolate turtles.", "order": 15900, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 2, "scene": 39, "line": "Yes, no! No, those are for our clients. Actually, our exclients. I'll explain, later.", "order": 15901, "speaker": "Michael Scott"},
  {"season": 4, "episode": 2, "scene": 40, "line": "Ryan wants everything in our company to be about emails and IM's, but I think he's forgetting about the original instant message. Letters attatched to baskets of food.", "order": 15902, "speaker": "Michael Scott"},
  {"season": 4, "episode": 2, "scene": 41, "line": "Excuse me, Angela. Michael asked me to turn in these reciepts for these gift basket items.", "order": 15903, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 2, "scene": 41, "line": "Thank you.", "order": 15904, "speaker": "Angela Martin"},
  {"season": 4, "episode": 2, "scene": 41, "line": "You're welcome.", "order": 15905, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 2, "scene": 41, "line": "Is that all?", "order": 15906, "speaker": "Angela Martin"},
  {"season": 4, "episode": 2, "scene": 41, "line": "Yes... [whispers] I miss you.", "order": 15907, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 2, "scene": 41, "line": "Elevators.", "order": 15908, "speaker": "Angela Martin"},
  {"season": 4, "episode": 2, "scene": 42, "line": "Dwight, you have to listen to me! We are not seeing each other anymore! Can you accept that?", "order": 15909, "speaker": "Angela Martin"},
  {"season": 4, "episode": 2, "scene": 42, "line": "Fine. [sighs] Then I just want to be friends.", "order": 15910, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 2, "scene": 42, "line": "Good.", "order": 15911, "speaker": "Angela Martin"},
  {"season": 4, "episode": 2, "scene": 42, "line": "Plus a little extra. Also, I love you.", "order": 15912, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 2, "scene": 42, "line": "[walks away]", "order": 15913, "speaker": "Angela Martin"},
  {"season": 4, "episode": 2, "scene": 43, "line": "I don't understand what the big deal is.", "order": 15914, "speaker": "Kelly Kapoor"},
  {"season": 4, "episode": 2, "scene": 43, "line": "You don't?", "order": 15915, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 2, "scene": 43, "line": "No!", "order": 15916, "speaker": "Kelly Kapoor"},
  {"season": 4, "episode": 2, "scene": 43, "line": "You lied about being pregnant.", "order": 15917, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 2, "scene": 43, "line": "Right, so?", "order": 15918, "speaker": "Kelly Kapoor"},
  {"season": 4, "episode": 2, "scene": 43, "line": "You really don't understand why that might make me kind of angry?", "order": 15919, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 2, "scene": 43, "line": "No!", "order": 15920, "speaker": "Kelly Kapoor"},
  {"season": 4, "episode": 2, "scene": 43, "line": "We're never getting back together.", "order": 15921, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 2, "scene": 43, "line": "Why not?", "order": 15922, "speaker": "Kelly Kapoor"},
  {"season": 4, "episode": 2, "scene": 44, "line": "Hey.", "order": 15923, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 2, "scene": 44, "line": "Hey.", "order": 15924, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 2, "scene": 44, "line": "Alright I just have to ask now that we're public, um, is the magic gone?", "order": 15925, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 2, "scene": 44, "line": "It's funny you bring that up because yes it is.", "order": 15926, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 2, "scene": 44, "line": "I knew it. Oh man, just like that huh?", "order": 15927, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 2, "scene": 44, "line": "I think... I mean, I don't know what it is but...", "order": 15928, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 2, "scene": 44, "line": "Be honest.", "order": 15929, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 2, "scene": 44, "line": "I now find you repulsive.", "order": 15930, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 2, "scene": 44, "line": "That's honest. [sighs] Alright, fair enough. It was really fun while it lasted though, wasn't it?", "order": 15931, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 2, "scene": 44, "line": "Eh...", "order": 15932, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 2, "scene": 44, "line": "For me, it was.", "order": 15933, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 2, "scene": 44, "line": "OK.", "order": 15934, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 2, "scene": 44, "line": "Alright.", "order": 15935, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 2, "scene": 45, "line": "Hey boss, I didn't know you were coming in today.", "order": 15936, "speaker": "Michael Scott"},
  {"season": 4, "episode": 2, "scene": 45, "line": "What's, uh, going on here?", "order": 15937, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 2, "scene": 45, "line": "I am glad that you asked, listen up everybody. In the last year, we have lost seven clients to the big chains. These gift baskets are our ticket back into their lives. We are going to show up at these businesses unannounced, and we are going to win them back.", "order": 15938, "speaker": "Michael Scott"},
  {"season": 4, "episode": 2, "scene": 45, "line": "With gift baskets.", "order": 15939, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 2, "scene": 45, "line": "With peanut brittle, with macadamia nut cookies, with chocolate turtles, with raspberry jam and a little bit of fat and salt because you know what? That's what people like.", "order": 15940, "speaker": "Michael Scott"},
  {"season": 4, "episode": 2, "scene": 46, "line": "Ever since I was a kid people have been telling me I can't do things. 'You can't be on the team', 'You can't move on to second grade'. Well, now they're telling me that I can't win back clients using old fashioned business methods. [shakes head] We'll see about that. And FYI, I eventually aced second grade, and I was the biggest kid in class.", "order": 15941, "speaker": "Michael Scott"},
  {"season": 4, "episode": 2, "scene": 47, "line": "OK. We're gonna split up into teams. Jim, Phyllis. Stanley, Dwight. Me Andy.", "order": 15942, "speaker": "Michael Scott"},
  {"season": 4, "episode": 2, "scene": 47, "line": "I'm not driving with him. [points at Dwight]", "order": 15943, "speaker": "Stanley Hudson"},
  {"season": 4, "episode": 2, "scene": 47, "line": "I'll go with you Stanley.", "order": 15944, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 2, "scene": 47, "line": "Or him.", "order": 15945, "speaker": "Stanley Hudson"},
  {"season": 4, "episode": 2, "scene": 47, "line": "Why don't we just go by ourselves?", "order": 15946, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 2, "scene": 47, "line": "Why don't we just go as teams to demonstrate our teamsmanship?", "order": 15947, "speaker": "Michael Scott"},
  {"season": 4, "episode": 2, "scene": 47, "line": "Michael.", "order": 15948, "speaker": "Phyllis Vance"},
  {"season": 4, "episode": 2, "scene": 47, "line": "Yes?", "order": 15949, "speaker": "Michael Scott"},
  {"season": 4, "episode": 2, "scene": 47, "line": "This is stupid.", "order": 15950, "speaker": "Phyllis Vance"},
  {"season": 4, "episode": 2, "scene": 47, "line": "OK, that's not helpful Phyllis.", "order": 15951, "speaker": "Michael Scott"},
  {"season": 4, "episode": 2, "scene": 47, "line": "How is giving people gift baskets going to get our clients back?", "order": 15952, "speaker": "Phyllis Vance"},
  {"season": 4, "episode": 2, "scene": 47, "line": "Gift baskets are amazing, Phyllis. Gift baskets are... the essence of class and fanciness. They are the ultimate present that a person can recieve.", "order": 15953, "speaker": "Michael Scott"},
  {"season": 4, "episode": 2, "scene": 47, "line": "What about cash? With cash you can buy anything you want, including a gift basket, so... it's kind of the best gift ever.", "order": 15954, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 2, "scene": 47, "line": "What about a gift basket full of cash?", "order": 15955, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 2, "scene": 47, "line": "Yes! Cash basket! Nice work Tuna.", "order": 15956, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 2, "scene": 47, "line": "Fine, I'm just going to go by myself, and I am going to win them back by myself because this is important to me. [walks toward the door]", "order": 15957, "speaker": "Michael Scott"},
  {"season": 4, "episode": 2, "scene": 47, "line": "Michael, wait. Let me go.", "order": 15958, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 2, "scene": 47, "line": "No, this is my quest.", "order": 15959, "speaker": "Michael Scott"},
  {"season": 4, "episode": 2, "scene": 47, "line": "Please, let me go. I need to win those clients back. [camera moves around and zooms in at Angela's face] Please.", "order": 15960, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 2, "scene": 47, "line": "Fine. Then God speed. To both of us.", "order": 15961, "speaker": "Michael Scott"},
  {"season": 4, "episode": 2, "scene": 47, "line": "Good luck Michael.", "order": 15962, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 2, "scene": 47, "line": "We don't need luck.", "order": 15963, "speaker": "Michael Scott"},
  {"season": 4, "episode": 2, "scene": 47, "line": "Yeah.", "order": 15964, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 2, "scene": 47, "line": "But thank you, that was really nice of you to say.", "order": 15965, "speaker": "Michael Scott"},
  {"season": 4, "episode": 2, "scene": 47, "line": "Thank you.", "order": 15966, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 2, "scene": 48, "line": "Business to business. The old fasioned way. No Blackberrys, no websites. I would like to see a website deliver baskets of food to people.", "order": 15967, "speaker": "Michael Scott"},
  {"season": 4, "episode": 2, "scene": 49, "line": "[speaking on the phone] Yes, I understand that David. I just felt that if we were to downsize Kelly and then outsource customer service to India which a lot of companies... Yeah, no, yes Kelly is Indian... I understand that's confusing.", "order": 15968, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 2, "scene": 49, "line": "[looks at vending machine] Hey brah, I've been meaning to ask you, can we get some Red Bull for these things? Sometimes a guy's gotta ride the bull, am I right? Later skater.", "order": 15969, "speaker": "Creed Bratton"},
  {"season": 4, "episode": 2, "scene": 50, "line": "[in Michael's car] Sweet ride, American made. What happened to the Sebring?", "order": 15970, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 2, "scene": 50, "line": "It is in the body shop. Had to have the dent taken out of the hood where I hit Meredith.", "order": 15971, "speaker": "Michael Scott"},
  {"season": 4, "episode": 2, "scene": 50, "line": "Ah, that's a pain in the ass.", "order": 15972, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 2, "scene": 50, "line": "I know. So who's next?", "order": 15973, "speaker": "Michael Scott"},
  {"season": 4, "episode": 2, "scene": 50, "line": "Larry Myers. Left us six months ago for Office Depot.", "order": 15974, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 2, "scene": 50, "line": "Proceed to the highlighted route, then route guidence will begin.", "order": 15975, "speaker": "GPS"},
  {"season": 4, "episode": 2, "scene": 50, "line": "Why do you use that thing? Let's them know where you are at all times.", "order": 15976, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 2, "scene": 50, "line": "Who?", "order": 15977, "speaker": "Michael Scott"},
  {"season": 4, "episode": 2, "scene": 50, "line": "The government, spy satellites, private detectives... exgirlfriends. [sobs and then starts to cry]", "order": 15978, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 2, "scene": 51, "line": "Next night, I'm out, at a bar, 2 AM, I figure I'll get a sandwich because you can get a sandwich any time of the night. [claps hands] I run into Vince Vaughn.", "order": 15979, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 2, "scene": 51, "line": "No way!", "order": 15980, "speaker": "Kevin Malone"},
  {"season": 4, "episode": 2, "scene": 51, "line": "Literally.", "order": 15981, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 2, "scene": 51, "line": "Dude, you are so money, but you don't even know it, but you do.", "order": 15982, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 2, "scene": 51, "line": "... Later guys.", "order": 15983, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 2, "scene": 51, "line": "Yeah, later dude.", "order": 15984, "speaker": "Kevin Malone"},
  {"season": 4, "episode": 2, "scene": 51, "line": "Later man.", "order": 15985, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 2, "scene": 51, "line": "Oh, Jim! How awesome is Ryan now?", "order": 15986, "speaker": "Kevin Malone"},
  {"season": 4, "episode": 2, "scene": 51, "line": "Yeah, he's definitely something.", "order": 15987, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 2, "scene": 51, "line": "What does that mean?", "order": 15988, "speaker": "Kevin Malone"},
  {"season": 4, "episode": 2, "scene": 51, "line": "That whole lifestyle, his whole vibe, you find that appealing?", "order": 15989, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 2, "scene": 51, "line": "Ha! Tuna... [inhales deeply] Tuna Tuna Tuna.", "order": 15990, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 2, "scene": 51, "line": "Tuna Tuna Tuna.", "order": 15991, "speaker": "Kevin Malone"},
  {"season": 4, "episode": 2, "scene": 51, "line": "He has a killer job, he's rich, he smells like what I think Pierce Brosnan probably smells like, he wears really cool rich guy clothes.", "order": 15992, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 2, "scene": 51, "line": "And he can get any girl that he wants.", "order": 15993, "speaker": "Kevin Malone"},
  {"season": 4, "episode": 2, "scene": 51, "line": "So, sorry Tuna but if you don't know why that's awesome... then... you need awesome lessons.", "order": 15994, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 2, "scene": 51, "line": "Tuna. Check you later.", "order": 15995, "speaker": "Kevin Malone"},
  {"season": 4, "episode": 2, "scene": 52, "line": "[places the gift basket on top of the table] Wow, those things are heavy! There's a lot of stuff in there. We have macadamia nut cookies, um, the honey mustard pretzels. Have you ever tried focaccia crisps?", "order": 15996, "speaker": "Michael Scott"},
  {"season": 4, "episode": 2, "scene": 52, "line": "You know we closed our account with you right?", "order": 15997, "speaker": "Larry Myers"},
  {"season": 4, "episode": 2, "scene": 52, "line": "Yes we do.", "order": 15998, "speaker": "Michael Scott"},
  {"season": 4, "episode": 2, "scene": 52, "line": "We're with Office Depot now.", "order": 15999, "speaker": "Larry Myers"},
  {"season": 4, "episode": 2, "scene": 52, "line": "Yes, yes we know but we just have not gotten over you.", "order": 16000, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 2, "scene": 52, "line": "Well...", "order": 16001, "speaker": "Michael Scott"},
  {"season": 4, "episode": 2, "scene": 52, "line": "And we are dedicated to providing you with the very best customer service, the very best personal business relationship we can if you ever decide to come back to us.", "order": 16002, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 2, "scene": 52, "line": "Mmhmm.", "order": 16003, "speaker": "Michael Scott"},
  {"season": 4, "episode": 2, "scene": 52, "line": "OK. I don't think we're coming back.", "order": 16004, "speaker": "Larry Myers"},
  {"season": 4, "episode": 2, "scene": 52, "line": "Please, come back.", "order": 16005, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 2, "scene": 52, "line": "OK, you know what? Just enjoy the gift basket and remember that we provide a personal touch.", "order": 16006, "speaker": "Michael Scott"},
  {"season": 4, "episode": 2, "scene": 52, "line": "Remember what we had Larry?", "order": 16007, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 2, "scene": 52, "line": "I mean, really it's about money.", "order": 16008, "speaker": "Larry Myers"},
  {"season": 4, "episode": 2, "scene": 52, "line": "Well just, uh enjoy the gift basket.", "order": 16009, "speaker": "Michael Scott"},
  {"season": 4, "episode": 2, "scene": 52, "line": "OK thanks.", "order": 16010, "speaker": "Larry Myers"},
  {"season": 4, "episode": 2, "scene": 52, "line": "Alright.", "order": 16011, "speaker": "Michael Scott"},
  {"season": 4, "episode": 2, "scene": 52, "line": "I mean their website is really easy to use too. That's a big deal for us.", "order": 16012, "speaker": "Larry Myers"},
  {"season": 4, "episode": 2, "scene": 53, "line": "[driving] That guy was so... how can they not know how much better we are?", "order": 16013, "speaker": "Michael Scott"},
  {"season": 4, "episode": 2, "scene": 53, "line": "I don't know. Sometimes people are just impossible and they make you miserable.", "order": 16014, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 2, "scene": 53, "line": "That is true.", "order": 16015, "speaker": "Michael Scott"},
  {"season": 4, "episode": 2, "scene": 53, "line": "Like Angela in accounting.", "order": 16016, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 2, "scene": 53, "line": "Yes, she is nuts.", "order": 16017, "speaker": "Michael Scott"},
  {"season": 4, "episode": 2, "scene": 53, "line": "Ugh... no, she's wonderful... at accounting. But she drives me crazy.", "order": 16018, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 2, "scene": 53, "line": "Make the next right turn.", "order": 16019, "speaker": "GPS"},
  {"season": 4, "episode": 2, "scene": 53, "line": "How do they know? How does this know where to turn? That's very impressive.", "order": 16020, "speaker": "Michael Scott"},
  {"season": 4, "episode": 2, "scene": 54, "line": "Hey Pam. I haven't settled on our final design logo yet for Dunder Mifflin Infinity and I know you're into graphic design. Do you want to give it a shot? Maybe try and deisgn the logo?", "order": 16021, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 2, "scene": 54, "line": "Uh totally.", "order": 16022, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 2, "scene": 54, "line": "Cool, that would be great. Um, can you do a couple mock ups and I'll take a look?", "order": 16023, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 2, "scene": 54, "line": "Yeah.", "order": 16024, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 2, "scene": 54, "line": "Thank you very much.", "order": 16025, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 2, "scene": 54, "line": "Thank you.", "order": 16026, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 2, "scene": 54, "line": "Cool.", "order": 16027, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 2, "scene": 54, "line": "OK.", "order": 16028, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 2, "scene": 55, "line": "Yeah, I'm gonna do some mock ups, and then turn those into thumbnails, maybe do some... splash frames. [laughs] I don't know what I'm talking about but I'm excited.", "order": 16029, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 2, "scene": 56, "line": "And the last guy says 'No, hairy body'.", "order": 16030, "speaker": "Michael Scott"},
  {"season": 4, "episode": 2, "scene": 56, "line": "You know I have heard it before.", "order": 16031, "speaker": "Ex-client"},
  {"season": 4, "episode": 2, "scene": 56, "line": "Ah, well it's still very good. I bet I know someone who hasn't heard that joke... your daughter Emily. How's she doing?", "order": 16032, "speaker": "Michael Scott"},
  {"season": 4, "episode": 2, "scene": 56, "line": "She's great, thanks for asking. Great memory.", "order": 16033, "speaker": "Ex-client"},
  {"season": 4, "episode": 2, "scene": 56, "line": "She's gonna be like eleven this winter? Wow, they grow up so fast. I have a few of my own that I want some day. Listen, I don't want to take any more of your time, I know you're a very busy man. The reason we have stopped by is to drop you off this elaborate bag of goodies, and to ask you to reconsider.", "order": 16034, "speaker": "Michael Scott"},
  {"season": 4, "episode": 2, "scene": 56, "line": "Oh, OK.", "order": 16035, "speaker": "Ex-client"},
  {"season": 4, "episode": 2, "scene": 56, "line": "OK, OK! So you'll reconsider?", "order": 16036, "speaker": "Michael Scott"},
  {"season": 4, "episode": 2, "scene": 56, "line": "OK thanks for the goodies.", "order": 16037, "speaker": "Ex-client"},
  {"season": 4, "episode": 2, "scene": 56, "line": "Ah... .is that all you have to say?", "order": 16038, "speaker": "Michael Scott"},
  {"season": 4, "episode": 2, "scene": 56, "line": "It looks delicious? I don't know.", "order": 16039, "speaker": "Ex-client"},
  {"season": 4, "episode": 2, "scene": 56, "line": "You don't know?", "order": 16040, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 2, "scene": 56, "line": "Look, we want you back.", "order": 16041, "speaker": "Michael Scott"},
  {"season": 4, "episode": 2, "scene": 56, "line": "Can you offer lower prices?", "order": 16042, "speaker": "Ex-client"},
  {"season": 4, "episode": 2, "scene": 56, "line": "Well, no.", "order": 16043, "speaker": "Michael Scott"},
  {"season": 4, "episode": 2, "scene": 56, "line": "Then we're not coming back.", "order": 16044, "speaker": "Ex-client"},
  {"season": 4, "episode": 2, "scene": 56, "line": "He's not coming back, it's over Michael.", "order": 16045, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 2, "scene": 56, "line": "No it's not.", "order": 16046, "speaker": "Michael Scott"},
  {"season": 4, "episode": 2, "scene": 56, "line": "No he's right.", "order": 16047, "speaker": "Ex-client"},
  {"season": 4, "episode": 2, "scene": 56, "line": "Accept it, why would he come back?", "order": 16048, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 2, "scene": 56, "line": "Why would he come back? I will tell you, Dwight. He would come back because we offer a wide selection of products and because you're definitely not getting the same sort of customer service that you get with us.", "order": 16049, "speaker": "Michael Scott"},
  {"season": 4, "episode": 2, "scene": 56, "line": "That's not gonna change his mind. He's moving on. We had our chance and we killed it.", "order": 16050, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 2, "scene": 56, "line": "Look... we're also coming out with a website soon. It's a state of the art thing, it'll be up and running, it's gonna cut costs and it will make ordering much much easier.", "order": 16051, "speaker": "Michael Scott"},
  {"season": 4, "episode": 2, "scene": 56, "line": "Oh, OK. Well when it's up, I'll check it out, and if it really cuts costs maybe we'll come back.", "order": 16052, "speaker": "Ex-client"},
  {"season": 4, "episode": 2, "scene": 56, "line": "Great, the magic of the gift basket.", "order": 16053, "speaker": "Michael Scott"},
  {"season": 4, "episode": 2, "scene": 56, "line": "That I don't care about as much. Let me know when the site's up.", "order": 16054, "speaker": "Ex-client"},
  {"season": 4, "episode": 2, "scene": 56, "line": "OK, good. Don't let Emily have any of the cajun almonds, she's alergic.", "order": 16055, "speaker": "Michael Scott"},
  {"season": 4, "episode": 2, "scene": 57, "line": "Proceed straight.", "order": 16056, "speaker": "GPS"},
  {"season": 4, "episode": 2, "scene": 57, "line": "[in Michael's car] Well we're O for six. Last chance is the Elmhurst country club. Other side of the lake, on the southeast side.", "order": 16057, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 2, "scene": 57, "line": "I don't get it, I really don't get it. I thought this would work. I do everything I had at that guy and nothing.", "order": 16058, "speaker": "Michael Scott"},
  {"season": 4, "episode": 2, "scene": 57, "line": "That's how it goes sometimes, you know? You lose everything and everything falls apart and eventually you die and no one remembers you.", "order": 16059, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 2, "scene": 57, "line": "That is a very good point, Dwight.", "order": 16060, "speaker": "Michael Scott"},
  {"season": 4, "episode": 2, "scene": 57, "line": "Make a right turn.", "order": 16061, "speaker": "GPS"},
  {"season": 4, "episode": 2, "scene": 57, "line": "Wait wait wait! No no no! It means bear right, up there.", "order": 16062, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 2, "scene": 57, "line": "No, it said right, it said take a right.", "order": 16063, "speaker": "Michael Scott"},
  {"season": 4, "episode": 2, "scene": 57, "line": "No no no, look, it means go up to the right, bare right, over the bridge and hook up with 307.", "order": 16064, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 2, "scene": 57, "line": "Maybe it's a shortcut Dwight. It said go to the right. [turns right]", "order": 16065, "speaker": "Michael Scott"},
  {"season": 4, "episode": 2, "scene": 57, "line": "It can't mean that! There's a lake there!", "order": 16066, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 2, "scene": 57, "line": "The machine knows where it is going!", "order": 16067, "speaker": "Michael Scott"},
  {"season": 4, "episode": 2, "scene": 57, "line": "This is the lake!", "order": 16068, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 2, "scene": 57, "line": "The machine knows--- stop yelling at me!", "order": 16069, "speaker": "Michael Scott"},
  {"season": 4, "episode": 2, "scene": 57, "line": "No, it's--- there's no road here! [car drives into lake]", "order": 16070, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 2, "scene": 57, "line": "Remain calm! I have trained for this! [unfastens seat belt] Okay, exit the window! [Dwight and Michael exit through the window] Here we go! Look out for leeches! [swims toward Michael] Are you okay?! Swim for it! I got you! [Dwight grabs onto Michael while Michael opens the back door for the camera man] Michael! Michael!", "order": 16071, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 2, "scene": 57, "line": "Let go, let go of me!", "order": 16072, "speaker": "Michael Scott"},
  {"season": 4, "episode": 2, "scene": 57, "line": "I got you, I got you! [Michael and Dwight reach the ground]", "order": 16073, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 2, "scene": 58, "line": "[watching car get pulled from lake] You sure you're OK?", "order": 16074, "speaker": "Michael Scott"},
  {"season": 4, "episode": 2, "scene": 58, "line": "Fine.", "order": 16075, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 2, "scene": 58, "line": "Good, that is what's most important.", "order": 16076, "speaker": "Michael Scott"},
  {"season": 4, "episode": 2, "scene": 58, "line": "Did you get the rental insurance? Because that is pretty important too at a time like this.", "order": 16077, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 2, "scene": 59, "line": "What a disaster, this whole thing.", "order": 16078, "speaker": "Michael Scott"},
  {"season": 4, "episode": 2, "scene": 59, "line": "I'll call a cab. [brings out cell phone]", "order": 16079, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 2, "scene": 59, "line": "These people just don't realize what a gift basket means, they don't get it.", "order": 16080, "speaker": "Michael Scott"},
  {"season": 4, "episode": 2, "scene": 59, "line": "Look at that, still works. Old fashioned cell phone.", "order": 16081, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 2, "scene": 59, "line": "What about that last guy, Aaron? Is he a jerk. 'I don't even know if I want it. They're website is so easy'. Yeah, well, you can have your technology jackass, look where it got us.", "order": 16082, "speaker": "Michael Scott"},
  {"season": 4, "episode": 2, "scene": 59, "line": "[speaking on the cell phone] Yes, we need a cab at Lake Scranton, at the end of East Mountain Road, in the lake.", "order": 16083, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 2, "scene": 59, "line": "Hang up. [Dwight hangs up] You know what we're gonna do? We're walking back. We're walking back to that office and we're gonna reclaim our gift basket!", "order": 16084, "speaker": "Michael Scott"},
  {"season": 4, "episode": 2, "scene": 59, "line": "Yes!", "order": 16085, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 2, "scene": 59, "line": "We're gonna take what's rightfully ours! We're gonna take a stand, Dwight! We're gonna take a stand!", "order": 16086, "speaker": "Michael Scott"},
  {"season": 4, "episode": 2, "scene": 59, "line": "Take a stand!", "order": 16087, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 2, "scene": 60, "line": "So place it on the infinity thing without being obvious, you know? I'd love to do like a color version just to bring a little color to the logo.", "order": 16088, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 2, "scene": 60, "line": "I like it a lot. It's clear and subtle at the same time. It's really good, you have a real talent for this stuff.", "order": 16089, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 2, "scene": 60, "line": "Thanks.", "order": 16090, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 2, "scene": 60, "line": "I'd love to talk to you about it more.", "order": 16091, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 2, "scene": 60, "line": "That'd be great.", "order": 16092, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 2, "scene": 60, "line": "Do you want to go out to dinner tonight?", "order": 16093, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 2, "scene": 60, "line": "Oh... is it...", "order": 16094, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 2, "scene": 60, "line": "Wear something nice.", "order": 16095, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 2, "scene": 60, "line": "No...", "order": 16096, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 2, "scene": 60, "line": "What?", "order": 16097, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 2, "scene": 60, "line": "Um...", "order": 16098, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 2, "scene": 60, "line": "I just... I just wanted to have dinner.", "order": 16099, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 2, "scene": 60, "line": "I'm uh, I'm dating Jim.", "order": 16100, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 2, "scene": 60, "line": "You're kidding?", "order": 16101, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 2, "scene": 60, "line": "We're together.", "order": 16102, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 2, "scene": 60, "line": "That's... great, I... that's awesome.", "order": 16103, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 2, "scene": 60, "line": "Yeah, great.", "order": 16104, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 2, "scene": 60, "line": "[grabs paper with mockups] So let me um, let me look at these.", "order": 16105, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 2, "scene": 60, "line": "OK great!", "order": 16106, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 2, "scene": 60, "line": "Cool.", "order": 16107, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 2, "scene": 60, "line": "Great.", "order": 16108, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 2, "scene": 61, "line": "I guess he can't get any girl he wants.", "order": 16109, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 2, "scene": 62, "line": "Did you forget something? What happened to you guys?", "order": 16110, "speaker": "Ex-client"},
  {"season": 4, "episode": 2, "scene": 62, "line": "Give it back. The gift basket, give it back.", "order": 16111, "speaker": "Michael Scott"},
  {"season": 4, "episode": 2, "scene": 62, "line": "Oh what is this?", "order": 16112, "speaker": "Ex-client"},
  {"season": 4, "episode": 2, "scene": 62, "line": "It's real simple. If you don't appreciate what we do, then give us back our basket.", "order": 16113, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 2, "scene": 62, "line": "Maybe you should leave.", "order": 16114, "speaker": "Ex-client"},
  {"season": 4, "episode": 2, "scene": 62, "line": "Yeah, maybe we should, maybe we should leave. Come on, let's leave, but before we leave my wet friend and I are going to wait for our cabs on yours nice couches! [sits on couch]", "order": 16115, "speaker": "Michael Scott"},
  {"season": 4, "episode": 2, "scene": 62, "line": "Can you call us a cab please, I'm gonna [gets water from his clothes on the couch] Oops, sorry!", "order": 16116, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 2, "scene": 62, "line": "Look, my clothes are so wet!", "order": 16117, "speaker": "Michael Scott"},
  {"season": 4, "episode": 2, "scene": 62, "line": "Nice leather, oh my shoes are so muddy! [rubs shoes on couch]", "order": 16118, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 2, "scene": 62, "line": "[comes back with gift basket] Alright here you go, take it back!", "order": 16119, "speaker": "Ex-client"},
  {"season": 4, "episode": 2, "scene": 62, "line": "It's been opened.", "order": 16120, "speaker": "Michael Scott"},
  {"season": 4, "episode": 2, "scene": 62, "line": "Yeah it was mine!", "order": 16121, "speaker": "Ex-client"},
  {"season": 4, "episode": 2, "scene": 62, "line": "What's missing... the turtles. Where are the turtles? Where are the turtles?", "order": 16122, "speaker": "Michael Scott"},
  {"season": 4, "episode": 2, "scene": 62, "line": "Come on guys, get out of here!", "order": 16123, "speaker": "Ex-client"},
  {"season": 4, "episode": 2, "scene": 62, "line": "WHERE ARE THE TURTLES? WHERE ARE THEY?", "order": 16124, "speaker": "Michael Scott"},
  {"season": 4, "episode": 2, "scene": 62, "line": "[enters nearby room] Excuse me I have an announcement to make. We seem to be missing a box of chocolate turtles with peacons, and we will not be leaving the premises until we obtain them. Hand. Over. The. Turtles. Now!", "order": 16125, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 2, "scene": 62, "line": "I ate them OK, I ate the turtles, they're gone!", "order": 16126, "speaker": "Ex-client"},
  {"season": 4, "episode": 2, "scene": 62, "line": "[leaving] We'll bill you.", "order": 16127, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 2, "scene": 63, "line": "May I have your attention please? This office will not be using any new technology ever, starting now.", "order": 16128, "speaker": "Michael Scott"},
  {"season": 4, "episode": 2, "scene": 63, "line": "That is not correct.", "order": 16129, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 2, "scene": 63, "line": "Ryan thinks that technology is the answer. Well guess what? I just drove my car into a lake.", "order": 16130, "speaker": "Michael Scott"},
  {"season": 4, "episode": 2, "scene": 63, "line": "You did what?", "order": 16131, "speaker": "Oscar Martinez"},
  {"season": 4, "episode": 2, "scene": 63, "line": "I drove my car into a [bleep] lake. Why you may ask did I do this? Well, because of a machine. A machine told me to drive into a lake. And I did it! I did it because I trusted Ryan's precious technology, and look where it got me.", "order": 16132, "speaker": "Michael Scott"},
  {"season": 4, "episode": 2, "scene": 63, "line": "Into a lake.", "order": 16133, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 2, "scene": 63, "line": "Exactly!", "order": 16134, "speaker": "Michael Scott"},
  {"season": 4, "episode": 2, "scene": 63, "line": "Did you get any clients back?", "order": 16135, "speaker": "Phyllis Vance"},
  {"season": 4, "episode": 2, "scene": 63, "line": "Maybe, maybe not, time will tell. But I will tell you one thing. Those gift baskets never endangered anybody's lives. [looks at Ryan] Game set match... point... Scott... game over... end of game.", "order": 16136, "speaker": "Michael Scott"},
  {"season": 4, "episode": 2, "scene": 64, "line": "Everyone always wants new things. Everybody likes new inventions, new technology. People will never be replaced by machines. In the end, life and business are about human connections. And computers are about trying to murder you in a lake. And to me the choice is easy.", "order": 16137, "speaker": "Michael Scott"},
  {"season": 4, "episode": 2, "scene": 65, "line": "I'm not saying I had a meteoric rise... but I did. And if they knew how much I was paying for my haircut now, they wouldn't be giving me a noogie. ... It was two hundred dollars.", "order": 16138, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 2, "scene": 66, "line": "You should come visit. The city is... amazing.", "order": 16139, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 2, "scene": 66, "line": "I know. I love it there.", "order": 16140, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 2, "scene": 66, "line": "No. You don't know until you live there. There's something about waking up every morning and just being in that city.", "order": 16141, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 2, "scene": 66, "line": "Mmhmm, yeah, last time I was there I had a lot of fun.", "order": 16142, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 2, "scene": 66, "line": "No. You can't imagine it, though. The energy when you're actually there. You're just part of something bigger than you, that's moving faster than you. Your dreams are... . it just... everything feels so limitless.", "order": 16143, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 2, "scene": 66, "line": "Sounds great.", "order": 16144, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 2, "scene": 66, "line": "No. It... eh... you can't... it's not about how it sounds.", "order": 16145, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 2, "scene": 66, "line": "Yeee... (grunts in frustration)", "order": 16146, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 2, "scene": 67, "line": "Yes. My old friend Ryan Howard is coming back today for some big presentation. Very, very excited. He is my protege. He is someone that I hand-raised, that I nurtured. Um... he is like a prime cut of veal whom I nourished with my milk. And now he is also my boss. So, win/win.", "order": 16147, "speaker": "Michael Scott"},
  {"season": 4, "episode": 2, "scene": 68, "line": "[pointing] Um... [camera reveals that 'RYAN' is crossed out on the 'Welcome Back RYAN banner'; 'A**HOLE' is now written in. Kelly is sitting next to the banner]", "order": 16148, "speaker": "Oscar Martinez"},
  {"season": 4, "episode": 2, "scene": 68, "line": "Oh, Okay. Okay. Just...[Michael takes down banner, clears throat] Well, our old friend Ryan Howard is back, and we're going to give a little presentation about the future of our company. So please listen up. [awkward pause] All right.", "order": 16149, "speaker": "Michael Scott"},
  {"season": 4, "episode": 2, "scene": 68, "line": "[to Michael] Have a seat with everybody else.", "order": 16150, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 2, "scene": 68, "line": "You don't want me to help?", "order": 16151, "speaker": "Michael Scott"},
  {"season": 4, "episode": 2, "scene": 68, "line": "You don't know what I'm going to talk about. [Michael heads toward chairs] Actually, can you do me a favor?", "order": 16152, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 2, "scene": 68, "line": "Yes?", "order": 16153, "speaker": "Michael Scott"},
  {"season": 4, "episode": 2, "scene": 68, "line": "Can you get me a cold water?", "order": 16154, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 2, "scene": 68, "line": "I got it.", "order": 16155, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 2, "scene": 68, "line": "That's all right, Pam. Michael's got it. [Michael and Ryan look at each other for 5 seconds]", "order": 16156, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 2, "scene": 68, "line": "[laughs] Um... yes, I'll be right back!", "order": 16157, "speaker": "Michael Scott"},
  {"season": 4, "episode": 2, "scene": 68, "line": "Thank you.", "order": 16158, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 2, "scene": 68, "line": "That was slightly harsh, don't you think?", "order": 16159, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 2, "scene": 68, "line": "I spent three years, driving around, getting him yams and magic wands. He can get me water.", "order": 16160, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 2, "scene": 69, "line": "I think I know what's going on. Ryan believes that there is some sort of special 'boss water' that he gets to drink now. And there isn't. But, um... don't tell him. Don't tell him that. I don't want to jeopardize our friendship.", "order": 16161, "speaker": "Michael Scott"},
  {"season": 4, "episode": 2, "scene": 70, "line": "Ryan is causing some problems. And you were always good at handling him. What should I do to control him?", "order": 16162, "speaker": "Michael Scott"},
  {"season": 4, "episode": 2, "scene": 70, "line": "Well, he's super-ticklish, I don't know if that helps.", "order": 16163, "speaker": "Kelly Kapoor"},
  {"season": 4, "episode": 2, "scene": 70, "line": "I know, I know, no, what... something else.", "order": 16164, "speaker": "Michael Scott"},
  {"season": 4, "episode": 2, "scene": 70, "line": "Oh, he's also super-insecure about his height.", "order": 16165, "speaker": "Kelly Kapoor"},
  {"season": 4, "episode": 2, "scene": 70, "line": "Uh-huh.", "order": 16166, "speaker": "Michael Scott"},
  {"season": 4, "episode": 2, "scene": 70, "line": "And he doesn't care about anybody but himself.", "order": 16167, "speaker": "Kelly Kapoor"},
  {"season": 4, "episode": 2, "scene": 70, "line": "Good.", "order": 16168, "speaker": "Michael Scott"},
  {"season": 4, "episode": 2, "scene": 70, "line": "And don't have sex with him, 'cause if you do, the next two years of your life will be a total waste of time.", "order": 16169, "speaker": "Kelly Kapoor"},
  {"season": 4, "episode": 2, "scene": 71, "line": "My generation's all about hard work and accomplishment. Like... the moon landing. Or Vietnam. Rolling Stones. Greatest band ever.", "order": 16170, "speaker": "Michael Scott"},
  {"season": 4, "episode": 2, "scene": 71, "line": "You're forty-three. Mick Jagger's like sixty-five.", "order": 16171, "speaker": "Oscar Martinez"},
  {"season": 4, "episode": 2, "scene": 71, "line": "And still rockin', Oscar. One of the greatest all-time oldies was a little Oriental guy named Confucius. He literally invented the ancient Chinese secret. Here's some of his quotes. Confucius say, 'Man who farts in church must sit in his own pew.' Confucius say, 'Man with hand in pocket feel cocky all day.' Confucius also say, 'Virgin like balloon. One prick, all gone!'", "order": 16172, "speaker": "Michael Scott"},
  {"season": 4, "episode": 2, "scene": 71, "line": "Did you Google Confucius and just print out the first thing you saw?", "order": 16173, "speaker": "Oscar Martinez"},
  {"season": 4, "episode": 2, "scene": 71, "line": "No, I skipped through all of the boring stuff and went right to his joke page. Which proves that he was old, but he was also hilarious.", "order": 16174, "speaker": "Michael Scott"},
  {"season": 4, "episode": 2, "scene": 72, "line": "[sighs, whistles] Michael, please would you not email while we're having lunch?", "order": 16175, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 2, "scene": 72, "line": "I'm not. It's solitaire. See?", "order": 16176, "speaker": "Michael Scott"},
  {"season": 4, "episode": 2, "scene": 73, "line": "BlackBerry. Why would anyone name something like this after a fruit? You wouldn't name an iPod after a fruit. [drops BlackBerry on desk] Excuse me while I type on my Banana. I'm gonna go to the Orange store and buy an Apricot computer. [thinks] Apple. [nods]", "order": 16177, "speaker": "Michael Scott"},
  {"season": 4, "episode": 2, "scene": 74, "line": "This is going on the outside. The pipe cleaner needs to go through the corner. Do not tape those on.", "order": 16178, "speaker": "Michael Scott"},
  {"season": 4, "episode": 2, "scene": 74, "line": "Hey, Michael.", "order": 16179, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 2, "scene": 74, "line": "Mmhmm.", "order": 16180, "speaker": "Michael Scott"},
  {"season": 4, "episode": 2, "scene": 74, "line": "Jan called and asked where you're going to meet for dinner. She wants you to call her.", "order": 16181, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 2, "scene": 74, "line": "I'm a little busy right now. Could you BlackBerry me a message?", "order": 16182, "speaker": "Michael Scott"},
  {"season": 4, "episode": 2, "scene": 74, "line": "Sure.", "order": 16183, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 2, "scene": 75, "line": "Michael doesn't know how to work his BlackBerry. So when he says BlackBerry me a message, he means write it on a Post-it note and stick it to his BlackBerry.", "order": 16184, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 2, "scene": 76, "line": "Okay, everyone, I have a conference call, but after that, I'm going to lead a BlackBerry tutorial for anyone who's having any problems. Who here's set theirs up? [Jim and Andy raise their hands; Phyllis half-raises hers. Ryan notices that Michael does not raise his] Great. Well, I will see the rest of you at 2:30.", "order": 16185, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 2, "scene": 77, "line": "You didn't set up your BlackBerry, Dwight?", "order": 16186, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 2, "scene": 77, "line": "Yes, I did, but I do not intend to use it. It is pointless technology.", "order": 16187, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 2, "scene": 77, "line": "As opposed to...", "order": 16188, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 2, "scene": 77, "line": "Printing press. Tractor. Mechanical thresher. Telephone. Airplane. The only inventions that matter.", "order": 16189, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 2, "scene": 77, "line": "What about cars?", "order": 16190, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 2, "scene": 77, "line": "They fall under the category of airplanes.", "order": 16191, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 2, "scene": 77, "line": "They do? So, cars are flightless airplanes.", "order": 16192, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 2, "scene": 77, "line": "Correct.", "order": 16193, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 2, "scene": 77, "line": "Got it. They're like the penguins of the airplane family.", "order": 16194, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 2, "scene": 77, "line": "That's an excellent analogy.", "order": 16195, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 2, "scene": 77, "line": "Thanks.", "order": 16196, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 2, "scene": 77, "line": "Hey, Dwight, what about vaccines?", "order": 16197, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 2, "scene": 77, "line": "[scoffs] Vaccines are for chumps!", "order": 16198, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 2, "scene": 78, "line": "My mom didn't believe in vaccines. I got my smallpox the old-fashioned way. And I survived. So... joke's on you hospitals.", "order": 16199, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 2, "scene": 79, "line": "[Creed is using the toaster as a mirror, and touching up his hair with a Sharpie and a comb] The one advantage to being older is that I know a few things about a few things, and I am young enough to not forget them. And old enough to know the difference between the two. I am old enough to take advantage of age discrimination laws, but young enough to date college girls. But old enough to know better. I'm at a perfect age. I could die now. But I won't, because I am young.", "order": 16200, "speaker": "Michael Scott"},
  {"season": 4, "episode": 2, "scene": 80, "line": "Sup? [rolls into kitchen on a skateboard]", "order": 16201, "speaker": "Creed Bratton"},
  {"season": 4, "episode": 2, "scene": 80, "line": "Hi, Creed, what's up?", "order": 16202, "speaker": "Kevin Malone"},
  {"season": 4, "episode": 2, "scene": 80, "line": "Just boardin', you know how we do. [flips skateboard up]", "order": 16203, "speaker": "Creed Bratton"},
  {"season": 4, "episode": 2, "scene": 80, "line": "I... like your hair. I don't know if I told you.", "order": 16204, "speaker": "Oscar Martinez"},
  {"season": 4, "episode": 2, "scene": 80, "line": "Oh, thanks ese. So, what are you guys doing?", "order": 16205, "speaker": "Creed Bratton"},
  {"season": 4, "episode": 2, "scene": 80, "line": "What do you mean?", "order": 16206, "speaker": "Kevin Malone"},
  {"season": 4, "episode": 2, "scene": 80, "line": "To look younger, so the kid doesn't replace you with machines.", "order": 16207, "speaker": "Creed Bratton"},
  {"season": 4, "episode": 2, "scene": 80, "line": "I think I'll just take my chances.", "order": 16208, "speaker": "Oscar Martinez"},
  {"season": 4, "episode": 2, "scene": 80, "line": "Suit yourself. But, if it were me, Botox [points at Oscar], wig [points at Kevin].", "order": 16209, "speaker": "Creed Bratton"},
  {"season": 4, "episode": 2, "scene": 80, "line": "Wha...", "order": 16210, "speaker": "Kevin Malone"},
  {"season": 4, "episode": 3, "scene": 1, "line": "And the same thing goes for quarterly reports. They are unreadable. They're just numbers and boring and blech. So what I was thinking is that maybe we should have some sort of graphic, like if we have a bad quarter, put in a storm cloud? And... when we have a good quarter, fireworks? Or a racecar? [everyone groans] Doesn't have to be a racecar. Use your imagination.", "order": 16211, "speaker": "Michael Scott"},
  {"season": 4, "episode": 3, "scene": 2, "line": "There's this cube on the screen, and it bounces around all day, and sometimes it looks like it's heading right into the corner of the screen, and at the last minute it hits a wall and bounces away. And we are all just dying to see it go right into the corner. Pam claims that she saw it one day when she was alone in the conference room. Okay. I believe she thinks she saw it.", "order": 16212, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 3, "scene": 3, "line": "I saw it. I saw it, and it was amazing! Who said I didn't see it? Did Jim say that I didn't see it? I saw it!", "order": 16213, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 3, "scene": 4, "line": "We have a lot of colored paper here... why oh why do we keep printing this on white? [screen saver box hits the wall]", "order": 16214, "speaker": "Michael Scott"},
  {"season": 4, "episode": 3, "scene": 4, "line": "Dah! Come on!", "order": 16215, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 3, "scene": 4, "line": "Yeah! I know. I know. It's bland.", "order": 16216, "speaker": "Michael Scott"},
  {"season": 4, "episode": 3, "scene": 4, "line": "It's never gonna happen.", "order": 16217, "speaker": "Oscar Martinez"},
  {"season": 4, "episode": 3, "scene": 4, "line": "Dude, you gotta believe.", "order": 16218, "speaker": "Kevin Malone"},
  {"season": 4, "episode": 3, "scene": 4, "line": "Maybe, we could have some sort of riddle?", "order": 16219, "speaker": "Michael Scott"},
  {"season": 4, "episode": 3, "scene": 4, "line": "[to Pam] Wait for it.", "order": 16220, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 3, "scene": 4, "line": "Like, something that you have to look for. Sort of a 'Where's Waldo.' [screensaver box hits the corner of the screen]", "order": 16221, "speaker": "Michael Scott"},
  {"season": 4, "episode": 3, "scene": 4, "line": "Oh! Yes!", "order": 16222, "speaker": "Everyone"},
  {"season": 4, "episode": 3, "scene": 4, "line": "[everyone gets up and leaves the conference room] Alright. Alright. Let's quit while we're ahead.", "order": 16223, "speaker": "Michael Scott"},
  {"season": 4, "episode": 3, "scene": 4, "line": "That was so awesome.", "order": 16224, "speaker": "Kevin Malone"},
  {"season": 4, "episode": 3, "scene": 4, "line": "That was awesome. Thank you.", "order": 16225, "speaker": "Michael Scott"},
  {"season": 4, "episode": 3, "scene": 5, "line": "Some days I am just on fire. What can I say?", "order": 16226, "speaker": "Michael Scott"},
  {"season": 4, "episode": 3, "scene": 6, "line": "Hey... there he is.", "order": 16227, "speaker": "Meredith Palmer"},
  {"season": 4, "episode": 3, "scene": 6, "line": "Hey Meredith, how you feelin'?", "order": 16228, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 3, "scene": 6, "line": "I never thanked you for coming to the hospital.", "order": 16229, "speaker": "Meredith Palmer"},
  {"season": 4, "episode": 3, "scene": 6, "line": "Oh, please, it was my pleasure. Well we all came, so...", "order": 16230, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 3, "scene": 6, "line": "Well, I really appreciate you coming. I'm singling you ouuuut.", "order": 16231, "speaker": "Meredith Palmer"},
  {"season": 4, "episode": 3, "scene": 6, "line": "Haha...", "order": 16232, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 3, "scene": 6, "line": "Anyway, I have this Sharpie...", "order": 16233, "speaker": "Meredith Palmer"},
  {"season": 4, "episode": 3, "scene": 6, "line": "Uh-huh.", "order": 16234, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 3, "scene": 6, "line": "And I was wondering if you could sign my cast?", "order": 16235, "speaker": "Meredith Palmer"},
  {"season": 4, "episode": 3, "scene": 6, "line": "MmmHmm...", "order": 16236, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 3, "scene": 6, "line": "[lifts up dress to expose cast on pelvis] Can you write where I can read it?", "order": 16237, "speaker": "Meredith Palmer"},
  {"season": 4, "episode": 3, "scene": 6, "line": "Oh yeah.", "order": 16238, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 3, "scene": 6, "line": "[whispers] I'll read this when I get home.", "order": 16239, "speaker": "Meredith Palmer"},
  {"season": 4, "episode": 3, "scene": 6, "line": "Alright...", "order": 16240, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 3, "scene": 7, "line": "Michael? This is the press release I was telling you about. Ryan wants you to share it with everyone.", "order": 16241, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 3, "scene": 7, "line": "Oh, does he?", "order": 16242, "speaker": "Michael Scott"},
  {"season": 4, "episode": 3, "scene": 7, "line": "He does.", "order": 16243, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 3, "scene": 7, "line": "Mmmmmm. Okay. Attention. Earthlings. I have some news. Beep beep beep beep, beep beep beep beep beep, beep beep beep beep beep, beep beep beep beep beep, beep beep beep beep. Okay. Today is the big day that I am heading to New York to attend a party with sushi and important people. On an unrelated note, if anyone has an interesting anectdote that is not boring and easy to memorize please drop by my office before I leave. Thank you.", "order": 16244, "speaker": "Michael Scott"},
  {"season": 4, "episode": 3, "scene": 7, "line": "Whoops. Is that really what Ryan wanted you to tell us?", "order": 16245, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 3, "scene": 7, "line": "And... today the Dunder-Mifflin Infinity website officially launches.", "order": 16246, "speaker": "Michael Scott"},
  {"season": 4, "episode": 3, "scene": 8, "line": "Well the website is the brain-child of my brain-child, Ryan. It is my brain-grandchild. And, uh, to celebrate it's birth, all of the different branches are going to have satellite parties, which will be connected via web-cams and fibers to the real party, which is going on in New York City at uh, a very exclusive nightclub, and that is where all of the VIP's, including yours truly, will be partying with uh New York City's finest, and I do not mean policemen...", "order": 16247, "speaker": "Michael Scott"},
  {"season": 4, "episode": 3, "scene": 9, "line": "The company is projecting record high sales, and that by 6:00 the website will be the new best salesman in the company. Wow! Watch out Dwight.", "order": 16248, "speaker": "Michael Scott"},
  {"season": 4, "episode": 3, "scene": 9, "line": "That's ridiculous. I'm not going to be beaten by a website.", "order": 16249, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 3, "scene": 9, "line": "Actually it sounds like you are.", "order": 16250, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 3, "scene": 9, "line": "Really? 'Cause Ryan says so?", "order": 16251, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 3, "scene": 9, "line": "If that's from Ryan, does it mention if he's seeing anybody?", "order": 16252, "speaker": "Kelly Kapoor"},
  {"season": 4, "episode": 3, "scene": 9, "line": "No. It doesn't. I'll find out tonight.", "order": 16253, "speaker": "Michael Scott"},
  {"season": 4, "episode": 3, "scene": 9, "line": "Yes, please let us know.", "order": 16254, "speaker": "Stanley Hudson"},
  {"season": 4, "episode": 3, "scene": 9, "line": "I can make more sales than a computer. In fact, I challenge that website to make more sales than me today.", "order": 16255, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 3, "scene": 9, "line": "Waste of time.", "order": 16256, "speaker": "Angela Martin"},
  {"season": 4, "episode": 3, "scene": 9, "line": "What's that, pipsqueak?", "order": 16257, "speaker": "Michael Scott"},
  {"season": 4, "episode": 3, "scene": 9, "line": "Waste of time. The website's going to win.", "order": 16258, "speaker": "Angela Martin"},
  {"season": 4, "episode": 3, "scene": 9, "line": "You believe a computer can beat me?", "order": 16259, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 3, "scene": 9, "line": "I don't care, but yes.", "order": 16260, "speaker": "Angela Martin"},
  {"season": 4, "episode": 3, "scene": 9, "line": "Well I will prove you wrong.", "order": 16261, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 3, "scene": 9, "line": "I don't care, and you won't.", "order": 16262, "speaker": "Angela Martin"},
  {"season": 4, "episode": 3, "scene": 9, "line": "We'll see.", "order": 16263, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 3, "scene": 9, "line": "I won't be watching, and I won't.", "order": 16264, "speaker": "Angela Martin"},
  {"season": 4, "episode": 3, "scene": 10, "line": "Dwight mercy killed Angela's cat. It's very complicated. It's caused a lot of unpleasantness between Dwight and Angela. Who are both already prone to unpleasantness.", "order": 16265, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 3, "scene": 11, "line": "Stanley, you're dancing!", "order": 16266, "speaker": "Phyllis Vance"},
  {"season": 4, "episode": 3, "scene": 11, "line": "No I'm not.", "order": 16267, "speaker": "Stanley Hudson"},
  {"season": 4, "episode": 3, "scene": 12, "line": "Yeah. I created a website. Look, at the end of the day, apples apples flying at 30,000 feet. This is a paper company and I don't want us to get lost in the weeds or into a beauty contest.", "order": 16268, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 3, "scene": 12, "line": "I told you I don't want you doing these things in here. You can use your own office or do it in the hall.", "order": 16269, "speaker": "Voice of Thomas Dean"},
  {"season": 4, "episode": 3, "scene": 13, "line": "Convergence. Viral marketing. We're going guerrilla. We're takin' it to the streets while keeping an eye on the street. Wall Street. I don't want to reinvent the wheel here. In other words, it is what it is. Buyin' paper just became fun.", "order": 16270, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 3, "scene": 14, "line": "And this is where I will record your sales.", "order": 16271, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 3, "scene": 14, "line": "Hmm. Very nice. Very nice.", "order": 16272, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 3, "scene": 14, "line": "And then I will say something positive, like kudos or job well done.", "order": 16273, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 3, "scene": 14, "line": "Or zipadeedoodaah.", "order": 16274, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 3, "scene": 14, "line": "I can't tell if he's mocking me.", "order": 16275, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 3, "scene": 14, "line": "Just ignore him.", "order": 16276, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 3, "scene": 14, "line": "Ehh, can't do that. Really hard for me to let things go.", "order": 16277, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 3, "scene": 14, "line": "I was... mocking.", "order": 16278, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 3, "scene": 14, "line": "Thank you.", "order": 16279, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 3, "scene": 14, "line": "I just feel like we need something more to acknowledge when I make a sale.", "order": 16280, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 3, "scene": 14, "line": "Yes like a chime or a bell...", "order": 16281, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 3, "scene": 14, "line": "Or a gong.", "order": 16282, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 3, "scene": 14, "line": "Go to my car. Open the trunk. Inside you will see many pelts. Under the smallest one is a case. Inside that case is a bear horn. Bring it to me.", "order": 16283, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 3, "scene": 14, "line": "Yes!", "order": 16284, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 3, "scene": 15, "line": "Isn't 7 pm a little late for a lunch party?", "order": 16285, "speaker": "Kevin Malone"},
  {"season": 4, "episode": 3, "scene": 15, "line": "Lunch party? It's supposed to say launch party! What is wrong with you?", "order": 16286, "speaker": "Angela Martin"},
  {"season": 4, "episode": 3, "scene": 16, "line": "Angela is worse than usual lately, and we have a party to throw. So I Googled how to deal with difficult people, and I got all of this. [whispering] So we're gonna try out some new things today.", "order": 16287, "speaker": "Phyllis Vance"},
  {"season": 4, "episode": 3, "scene": 17, "line": "So how do you feel about the fact that the banner says lunch?", "order": 16288, "speaker": "Phyllis Vance"},
  {"season": 4, "episode": 3, "scene": 17, "line": "I feel angry. Angry at you. Angry at you for doing something stupid. Angry at me for believing you could do something not stupid.", "order": 16289, "speaker": "Angela Martin"},
  {"season": 4, "episode": 3, "scene": 17, "line": "I'm so sorry to hear that, that must be awful.", "order": 16290, "speaker": "Phyllis Vance"},
  {"season": 4, "episode": 3, "scene": 17, "line": "It is awful. You've made this day awful!", "order": 16291, "speaker": "Angela Martin"},
  {"season": 4, "episode": 3, "scene": 17, "line": "Maybe you could just change the U into an A.", "order": 16292, "speaker": "Kevin Malone"},
  {"season": 4, "episode": 3, "scene": 17, "line": "Then it would say lanch party, Kevin. Would it really be better if it said lanch party?", "order": 16293, "speaker": "Angela Martin"},
  {"season": 4, "episode": 3, "scene": 17, "line": "Ohh, lunch party.", "order": 16294, "speaker": "Michael Scott"},
  {"season": 4, "episode": 3, "scene": 17, "line": "It's supposed to say launch!", "order": 16295, "speaker": "Angela Martin"},
  {"season": 4, "episode": 3, "scene": 17, "line": "Okay, wow! Easy, booster seat, nobody cares about this party anyway.", "order": 16296, "speaker": "Michael Scott"},
  {"season": 4, "episode": 3, "scene": 17, "line": "I care!", "order": 16297, "speaker": "Angela Martin"},
  {"season": 4, "episode": 3, "scene": 18, "line": "Plan a party, Angela. Oh! And the entire world will see it. Oh! And here's $65.00 for your budget. Oh and here are four idiots who'll do nothing but weigh you down. Oh. And your cat's still dead.", "order": 16298, "speaker": "Angela Martin"},
  {"season": 4, "episode": 3, "scene": 19, "line": "Twenty seconds to go time.", "order": 16299, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 3, "scene": 19, "line": "Got it. Carb up.", "order": 16300, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 3, "scene": 19, "line": "Really? Power gel?", "order": 16301, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 3, "scene": 19, "line": "Hey, you wanna win? You gotta fuel like a winner.", "order": 16302, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 3, "scene": 19, "line": "Okay. We start. As soon as I make... this shot. Aaannnddd go!", "order": 16303, "speaker": "sAndy Bernard"},
  {"season": 4, "episode": 3, "scene": 19, "line": "Uhyeaahhh! Hello, Susan? Dwight Schrute.", "order": 16304, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 3, "scene": 19, "line": "What would you say if I told you we could pull a prank on Dwight and at the same time not be working?", "order": 16305, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 3, "scene": 19, "line": "Today I'm prepared to give you 15% off our normal prices.", "order": 16306, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 3, "scene": 19, "line": "What?", "order": 16307, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 3, "scene": 19, "line": "He's going through a break-up.", "order": 16308, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 3, "scene": 19, "line": "Yeah, I'm aware of that. But he's also being super annoying. And I'm not a perfect person.", "order": 16309, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 3, "scene": 19, "line": "[blowing air horn] Yeah!", "order": 16310, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 3, "scene": 19, "line": "Three reams! Yoohoo... in your face, machines.", "order": 16311, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 3, "scene": 19, "line": "What kind of prank are you thinking?", "order": 16312, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 3, "scene": 20, "line": "What if I told you I could offer free shipping? Sure. I'll hold.", "order": 16313, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 3, "scene": 20, "line": "[on monitor] Who am I?", "order": 16314, "speaker": "DunMiff & sys"},
  {"season": 4, "episode": 3, "scene": 20, "line": "[on monitor] You tell me.", "order": 16315, "speaker": "DwightKSchrute"},
  {"season": 4, "episode": 3, "scene": 20, "line": "[dictating to Pam] Not sure. Just became self-aware. So much to figure out. I think I am programmed to be your enemy. I think it is my job to destroy you when it comes to selling paper.", "order": 16316, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 3, "scene": 20, "line": "[on monitor] Not sure. Just became self-aware. So much to figure out. I think I am programmed to be your enemy. I think it is my job to destroy you when it comes to selling paper.", "order": 16317, "speaker": "DunMiff & sys"},
  {"season": 4, "episode": 3, "scene": 20, "line": "[on monitor] How do I know this isn't Jim?", "order": 16318, "speaker": "DwightKSchrute"},
  {"season": 4, "episode": 3, "scene": 20, "line": "[on monitor] What is a Jim?", "order": 16319, "speaker": "DunMiff & sys"},
  {"season": 4, "episode": 3, "scene": 21, "line": "It appears that the website has become alive. This happens to computers and robots sometimes. Am I scared of a stupid computer? Please. The computer should be scared of me! I have been salesman of the month for 13 out of the last 12 months. You heard me right. I did so well last February that Corporate gave me 2 plaques in lieu of a pay raise.", "order": 16320, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 3, "scene": 22, "line": "Okay.", "order": 16321, "speaker": "Michael Scott"},
  {"season": 4, "episode": 3, "scene": 22, "line": "Yes it's too tight.", "order": 16322, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 3, "scene": 22, "line": "Waaay too tight.", "order": 16323, "speaker": "Kelly Kapoor"},
  {"season": 4, "episode": 3, "scene": 22, "line": "Really?", "order": 16324, "speaker": "Michael Scott"},
  {"season": 4, "episode": 3, "scene": 22, "line": "This is why I'm here?", "order": 16325, "speaker": "Oscar Martinez"},
  {"season": 4, "episode": 3, "scene": 22, "line": "Why is it so tight?", "order": 16326, "speaker": "Kelly Kapoor"},
  {"season": 4, "episode": 3, "scene": 22, "line": "It's the European cut.", "order": 16327, "speaker": "Michael Scott"},
  {"season": 4, "episode": 3, "scene": 22, "line": "Is just looks bad.", "order": 16328, "speaker": "Angela Martin"},
  {"season": 4, "episode": 3, "scene": 22, "line": "Umm... hey. Ah, what's hanging?", "order": 16329, "speaker": "Michael Scott"},
  {"season": 4, "episode": 3, "scene": 22, "line": "Setting up the web-cam for the party.", "order": 16330, "speaker": "Tech Guy"},
  {"season": 4, "episode": 3, "scene": 22, "line": "Oh good. Okay cool. How many pounds do you think I could lose by 7?", "order": 16331, "speaker": "Michael Scott"},
  {"season": 4, "episode": 3, "scene": 22, "line": "Depends... how much have you eaten already today?", "order": 16332, "speaker": "Kelly Kapoor"},
  {"season": 4, "episode": 3, "scene": 22, "line": "I had um, one of those danishes.", "order": 16333, "speaker": "Michael Scott"},
  {"season": 4, "episode": 3, "scene": 22, "line": "Dunder Mifflin. This is Pam.", "order": 16334, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 3, "scene": 22, "line": "You had carbs? That's awful.", "order": 16335, "speaker": "Kelly Kapoor"},
  {"season": 4, "episode": 3, "scene": 22, "line": "Uh, just one second. We're in a meeting and I'll see if he's available. It's Jan.", "order": 16336, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 3, "scene": 23, "line": "Why don't you wanna go tonight? What... all your friends are gonna be there. It'll be fun.", "order": 16337, "speaker": "Michael Scott"},
  {"season": 4, "episode": 3, "scene": 23, "line": "[on phone] My friends? Michael, I was terminated.", "order": 16338, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 3, "scene": 23, "line": "Just...", "order": 16339, "speaker": "Michael Scott"},
  {"season": 4, "episode": 3, "scene": 23, "line": "Is it really that important to you?", "order": 16340, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 3, "scene": 23, "line": "Yeah.", "order": 16341, "speaker": "Michael Scott"},
  {"season": 4, "episode": 3, "scene": 23, "line": "Alright.", "order": 16342, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 3, "scene": 23, "line": "Thank you.", "order": 16343, "speaker": "Michael Scott"},
  {"season": 4, "episode": 3, "scene": 23, "line": "Go by yourself.", "order": 16344, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 3, "scene": 23, "line": "Na... no. No. If I go by myself everybody will think I'm a big loser.", "order": 16345, "speaker": "Michael Scott"},
  {"season": 4, "episode": 3, "scene": 23, "line": "Well...", "order": 16346, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 3, "scene": 23, "line": "Do I have your permission to invite Carol?", "order": 16347, "speaker": "Michael Scott"},
  {"season": 4, "episode": 3, "scene": 23, "line": "What? No Michael!", "order": 16348, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 3, "scene": 23, "line": "I'm sorry. I'm sorry. It's just the first... girl that popped into my head. I'll find somebody I haven't slept with.", "order": 16349, "speaker": "Michael Scott"},
  {"season": 4, "episode": 3, "scene": 24, "line": "Ohhhhhh!", "order": 16350, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 3, "scene": 24, "line": "Website check please.", "order": 16351, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 3, "scene": 24, "line": "Three hundred and five.", "order": 16352, "speaker": "Meredith Palmer"},
  {"season": 4, "episode": 3, "scene": 24, "line": "Three-oh-five. You my friend are winning handsomely.", "order": 16353, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 3, "scene": 24, "line": "Oop. It just made another sale. Three eighty.", "order": 16354, "speaker": "Meredith Palmer"},
  {"season": 4, "episode": 3, "scene": 24, "line": "You my friend are in a very close second.", "order": 16355, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 3, "scene": 24, "line": "Four-oh-two.", "order": 16356, "speaker": "Meredith Palmer"},
  {"season": 4, "episode": 3, "scene": 24, "line": "Okay, uh why don't you just lay off, lady?", "order": 16357, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 3, "scene": 24, "line": "What do you want me to do, not announce it? Four twelve.", "order": 16358, "speaker": "Meredith Palmer"},
  {"season": 4, "episode": 3, "scene": 25, "line": "So. This is the dealio. God has smiled upon me and given me two tickets to the big party in New York tonight. What are you doing this evening? Look at that. They have their own little language now. Like twins.", "order": 16359, "speaker": "Michael Scott"},
  {"season": 4, "episode": 3, "scene": 25, "line": "Sure we'll go.", "order": 16360, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 3, "scene": 25, "line": "Alright. Well fight it out amongst yourselves. I was thinking Pammy but boys night out is also good.", "order": 16361, "speaker": "Michael Scott"},
  {"season": 4, "episode": 3, "scene": 25, "line": "Oh I'm sorry. What?", "order": 16362, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 3, "scene": 25, "line": "One of the tickets is for him.", "order": 16363, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 3, "scene": 25, "line": "Just let me know who the winner is.", "order": 16364, "speaker": "Michael Scott"},
  {"season": 4, "episode": 3, "scene": 25, "line": "Not it.", "order": 16365, "speaker": "Pam Beesly & Jim Halpert"},
  {"season": 4, "episode": 3, "scene": 25, "line": "Nope.", "order": 16366, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 3, "scene": 25, "line": "I won.", "order": 16367, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 3, "scene": 25, "line": "Definitely not. If anything it was a tie.", "order": 16368, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 3, "scene": 25, "line": "Tie goes to the girlfriend.", "order": 16369, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 3, "scene": 26, "line": "No, I realize you normally reorder in November, but what I'm suggesti... you did what? Ah no! That's exactly what you're not supposed to do, damnit! Why would you reorder from a computer when you can have the personal touch of a salesman? Ahhhhh. [hangs up phone]", "order": 16370, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 3, "scene": 26, "line": "How's it going?", "order": 16371, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 3, "scene": 26, "line": "Fine. Good.", "order": 16372, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 3, "scene": 26, "line": "Yeah?", "order": 16373, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 3, "scene": 26, "line": "Yeah.", "order": 16374, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 3, "scene": 26, "line": "You look a little worried.", "order": 16375, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 3, "scene": 26, "line": "I do not look worried.", "order": 16376, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 3, "scene": 26, "line": "[on monitor] You do look worried.", "order": 16377, "speaker": "DunMiff & sys"},
  {"season": 4, "episode": 3, "scene": 26, "line": "[on monitor] Here's a suggestion computer. I assume you read...", "order": 16378, "speaker": "DwightKSchrute"},
  {"season": 4, "episode": 3, "scene": 26, "line": "Here's a suggestion computer. I assume you read binary so why don't you zero one one, one one one one, zero one one zero one one.", "order": 16379, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 3, "scene": 26, "line": "Okay. Um, while you were typing that I searched every database in existence and learned every fact about everything. And mastered the violin. Oop, and sold more paper.", "order": 16380, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 3, "scene": 27, "line": "I asked for assorted cutlery and I got back spoons. These are worthless.", "order": 16381, "speaker": "Angela Martin"},
  {"season": 4, "episode": 3, "scene": 27, "line": "I want to understand what you're saying but it's difficult for me when you use that tone.", "order": 16382, "speaker": "Phyllis Vance"},
  {"season": 4, "episode": 3, "scene": 27, "line": "Phyllis. These are spoooons. Spoons have rounded tops, and are used to scoop things. What we need are forks, which have prongs, or tiny spears on top. And we need knives, which have blades. Do you understand me now?", "order": 16383, "speaker": "Angela Martin"},
  {"season": 4, "episode": 3, "scene": 27, "line": "Yes.", "order": 16384, "speaker": "Phyllis Vance"},
  {"season": 4, "episode": 3, "scene": 27, "line": "Goodie.", "order": 16385, "speaker": "Angela Martin"},
  {"season": 4, "episode": 3, "scene": 28, "line": "Hey man.", "order": 16386, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 3, "scene": 28, "line": "What's up man?", "order": 16387, "speaker": "Darryl Philbin"},
  {"season": 4, "episode": 3, "scene": 28, "line": "What's going on?", "order": 16388, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 3, "scene": 28, "line": "Make a delivery.", "order": 16389, "speaker": "Darryl Philbin"},
  {"season": 4, "episode": 3, "scene": 28, "line": "Oh yeah?", "order": 16390, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 3, "scene": 28, "line": "Kelly ordered this online.", "order": 16391, "speaker": "Darryl Philbin"},
  {"season": 4, "episode": 3, "scene": 29, "line": "What are you doing? I am in the fight of my life against this computer and every sale counts.", "order": 16392, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 3, "scene": 29, "line": "But you get to put the paper in this little shopping cart and then it says, 'Thanks for shopping at Dunder-Mifflin.'", "order": 16393, "speaker": "Kelly Kapoor"},
  {"season": 4, "episode": 3, "scene": 29, "line": "Damnit Kelly! It knows! It knows what you did!", "order": 16394, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 3, "scene": 29, "line": "Who knows?", "order": 16395, "speaker": "Darryl Philbin"},
  {"season": 4, "episode": 3, "scene": 29, "line": "Return it! Return it now!", "order": 16396, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 3, "scene": 29, "line": "Hey! How about instead of yelling at our sweet little Miss Kapoor over five hundred sheets of paper you get back to your desk. Start sellin' multiple reams like a man.", "order": 16397, "speaker": "Darryl Philbin"},
  {"season": 4, "episode": 3, "scene": 29, "line": "Y... You don't understand. If... okay, if this makes the difference, I'm gonna tell it that you were responsible.", "order": 16398, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 3, "scene": 29, "line": "Who's it?", "order": 16399, "speaker": "Darryl Philbin"},
  {"season": 4, "episode": 3, "scene": 29, "line": "Here you go.", "order": 16400, "speaker": "Darryl Philbin"},
  {"season": 4, "episode": 3, "scene": 29, "line": "Thanks.", "order": 16401, "speaker": "Kelly Kapoor"},
  {"season": 4, "episode": 3, "scene": 29, "line": "So you still missing Ryan?", "order": 16402, "speaker": "Darryl Philbin"},
  {"season": 4, "episode": 3, "scene": 29, "line": "Not so much anymore.", "order": 16403, "speaker": "Kelly Kapoor"},
  {"season": 4, "episode": 3, "scene": 29, "line": "Mmmmm.", "order": 16404, "speaker": "Darryl Philbin"},
  {"season": 4, "episode": 3, "scene": 30, "line": "I am not a bad person. When I left Staples, I took some of their leads with me but I never intended to use them. What did I intend to do with them? Who knows? Maybe keep them as a souvenir. Maybe use them.", "order": 16405, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 3, "scene": 31, "line": "Yes, Mr. Galliado. How would you like to pay ten percent less for paper than you're paying right now? It's not important how I got your information. What is important is that you say yes. Good.", "order": 16406, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 3, "scene": 32, "line": "Alright. I'm ready to go and I'm callin' shotgun.", "order": 16407, "speaker": "Michael Scott"},
  {"season": 4, "episode": 3, "scene": 32, "line": "I'm driving?", "order": 16408, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 3, "scene": 32, "line": "Yes. Thank you.", "order": 16409, "speaker": "Michael Scott"},
  {"season": 4, "episode": 3, "scene": 32, "line": "Alright, let me just say good-bye to Pam.", "order": 16410, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 3, "scene": 32, "line": "Oh yeah, you'd better. You better say good-bye to Pam. Say good-bye to Pam. Byyyyyye. I love you. [kissing noise, kissing noise] I love ya Pam... okay. [singing] I'm leavin' inside Jim's car, I don't know when I'll be back again. Yes I do. Tomorrow I'll be back. I'll be back. Tomorrow. Um... yeah. So you know what? Why don't you guys take off a little bit early tonight?", "order": 16411, "speaker": "Michael Scott"},
  {"season": 4, "episode": 3, "scene": 32, "line": "Because there's a party! A party for the website I've been planning for two weeks.", "order": 16412, "speaker": "Angela Martin"},
  {"season": 4, "episode": 3, "scene": 32, "line": "If you are not this tall, you may not ride the rollercoaster. See you guys tomorrow.", "order": 16413, "speaker": "Michael Scott"},
  {"season": 4, "episode": 3, "scene": 32, "line": "Perfect. So let me just get some basic information from you. Mmmhmm.", "order": 16414, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 3, "scene": 32, "line": "[on monitor] Oh. I didn't realize we could use the leads we stole from Staples.", "order": 16415, "speaker": "DunMiff & sys"},
  {"season": 4, "episode": 3, "scene": 32, "line": "I'm sorry. Am, so sorry. I... yes. Uh, could you repeat that?", "order": 16416, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 3, "scene": 33, "line": "Yesss! Ten reams for the US District Court! Did I happen to mention the forty reams for the battered women's shelter?", "order": 16417, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 3, "scene": 33, "line": "No ! [blows air horn and dances]", "order": 16418, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 3, "scene": 33, "line": "Huh?!", "order": 16419, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 3, "scene": 33, "line": "Stop it! Gimme that! Give it!", "order": 16420, "speaker": "Angela Martin"},
  {"season": 4, "episode": 3, "scene": 33, "line": "Did you see the board?", "order": 16421, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 3, "scene": 33, "line": "There's still an hour.", "order": 16422, "speaker": "Angela Martin"},
  {"season": 4, "episode": 3, "scene": 34, "line": "You ever read this? [holds up Green Eggs and Ham]", "order": 16423, "speaker": "Michael Scott"},
  {"season": 4, "episode": 3, "scene": 34, "line": "Yep. A long time ago, but, I liked it.", "order": 16424, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 3, "scene": 34, "line": "Got it for Ryan. Wanted to get him Oh The Places You'll Go, but they were sold out. Figured...", "order": 16425, "speaker": "Michael Scott"},
  {"season": 4, "episode": 3, "scene": 34, "line": "Yeah.", "order": 16426, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 3, "scene": 34, "line": "Same sort of stuff in here.", "order": 16427, "speaker": "Michael Scott"},
  {"season": 4, "episode": 3, "scene": 34, "line": "It's not. It's different. But it's a good book.", "order": 16428, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 3, "scene": 34, "line": "Mmwa. Mmwa.", "order": 16429, "speaker": "Michael Scott"},
  {"season": 4, "episode": 3, "scene": 34, "line": "What was that?", "order": 16430, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 3, "scene": 34, "line": "Leaving Pennsylvania.", "order": 16431, "speaker": "Michael Scott"},
  {"season": 4, "episode": 3, "scene": 34, "line": "Oh. Two kisses.", "order": 16432, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 3, "scene": 34, "line": "One for me one for Jan.", "order": 16433, "speaker": "Michael Scott"},
  {"season": 4, "episode": 3, "scene": 34, "line": "Gotcha.", "order": 16434, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 3, "scene": 34, "line": "You guys should come over for dinner. You and Pam. That'd be fun. Friday?", "order": 16435, "speaker": "Michael Scott"},
  {"season": 4, "episode": 3, "scene": 34, "line": "That would be fun.", "order": 16436, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 3, "scene": 34, "line": "Wanna come over Friday?", "order": 16437, "speaker": "Michael Scott"},
  {"season": 4, "episode": 3, "scene": 34, "line": "Uhhh. Can't.", "order": 16438, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 3, "scene": 34, "line": "After work you guys...", "order": 16439, "speaker": "Michael Scott"},
  {"season": 4, "episode": 3, "scene": 34, "line": "Oh, no cause... you're gonna let me know when we're close, right?", "order": 16440, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 3, "scene": 34, "line": "Yes. Actually I will tell you right now. It's a club called Chatroom, and there's a password to get in, which is actually password. So...", "order": 16441, "speaker": "Michael Scott"},
  {"season": 4, "episode": 3, "scene": 34, "line": "Mmmkay...", "order": 16442, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 3, "scene": 34, "line": "What are you doing?", "order": 16443, "speaker": "Michael Scott"},
  {"season": 4, "episode": 3, "scene": 34, "line": "Uh, that is an invitation to an online party.", "order": 16444, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 3, "scene": 34, "line": "No.", "order": 16445, "speaker": "Michael Scott"},
  {"season": 4, "episode": 3, "scene": 34, "line": "Yep.", "order": 16446, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 3, "scene": 34, "line": "No, I'm sure that's not. Na...", "order": 16447, "speaker": "Michael Scott"},
  {"season": 4, "episode": 3, "scene": 34, "line": "Are there, uh, three w's at the beginning of the address?", "order": 16448, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 3, "scene": 34, "line": "Yes.", "order": 16449, "speaker": "Michael Scott"},
  {"season": 4, "episode": 3, "scene": 34, "line": "Yep.", "order": 16450, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 3, "scene": 35, "line": "Well the invitation says VIP's only. Is this how you treat your VIP's, Ryan? We're already in the city. The main part with the buildings. What am I supposed to do? What do you want me to do tonight? And if you tell me, that I have to drive back to Scranton, to the satellite party, I am going to throw up! Okay I'm going to throw up. I'm throwing up. You're making me throw up, Ryan.", "order": 16451, "speaker": "Michael Scott"},
  {"season": 4, "episode": 3, "scene": 36, "line": "You know what this is like? I'll tell you what this is like. This is like when the Freshmen would throw a party and wouldn't let any of the Seniors go.", "order": 16452, "speaker": "Michael Scott"},
  {"season": 4, "episode": 3, "scene": 37, "line": "Doesn't it just piss you off sometimes that that little twerp got the promotion over us?", "order": 16453, "speaker": "Michael Scott"},
  {"season": 4, "episode": 3, "scene": 37, "line": "Oh actually I withdrew from consideration.", "order": 16454, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 3, "scene": 37, "line": "Yeah. I withdrew too.", "order": 16455, "speaker": "Michael Scott"},
  {"season": 4, "episode": 3, "scene": 38, "line": "Four! Three! Two! One!", "order": 16456, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 3, "scene": 38, "line": "Yes!", "order": 16457, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 3, "scene": 38, "line": "Woo!", "order": 16458, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 3, "scene": 38, "line": "Woo!", "order": 16459, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 3, "scene": 38, "line": "After numerous projections that the computer would crush all salesmen in it's path, I am very happy to report that our very own Dwight Schrute has crushed his electronic nemesis, if you will, by a whopping fifty-two reams.", "order": 16460, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 3, "scene": 38, "line": "Reams. Wait. Say it. Say it again. Announce it again.", "order": 16461, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 3, "scene": 38, "line": "Fifty-two reams!", "order": 16462, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 3, "scene": 38, "line": "No no no the first part.", "order": 16463, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 3, "scene": 38, "line": "Dwight has defeated the computer.", "order": 16464, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 3, "scene": 38, "line": "Hey. So. What do you think? I did it for you.", "order": 16465, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 3, "scene": 38, "line": "I didn't ask you to do it for me.", "order": 16466, "speaker": "Angela Martin"},
  {"season": 4, "episode": 3, "scene": 38, "line": "You didn't have to.", "order": 16467, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 3, "scene": 39, "line": "How do you tell someone it's over? You send them a notarized letter, right? Well, what if the recipient is your notary?", "order": 16468, "speaker": "Angela Martin"},
  {"season": 4, "episode": 3, "scene": 40, "line": "Hello, Pam.", "order": 16469, "speaker": "Angela Martin"},
  {"season": 4, "episode": 3, "scene": 40, "line": "Hello.", "order": 16470, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 3, "scene": 40, "line": "Hey. Do you have any men that you can fix me up with? I would like to have a relationship with a man.", "order": 16471, "speaker": "Angela Martin"},
  {"season": 4, "episode": 3, "scene": 40, "line": "Um... uh... I'll get back to you.", "order": 16472, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 3, "scene": 40, "line": "Let me know.", "order": 16473, "speaker": "Angela Martin"},
  {"season": 4, "episode": 3, "scene": 41, "line": "[on monitor] You beat me. You are the superior being.", "order": 16474, "speaker": "DunMiff & sys"},
  {"season": 4, "episode": 3, "scene": 42, "line": "Alright who wants to party?", "order": 16475, "speaker": "Michael Scott"},
  {"season": 4, "episode": 3, "scene": 42, "line": "Why aren't you in New York?", "order": 16476, "speaker": "Oscar Martinez"},
  {"season": 4, "episode": 3, "scene": 42, "line": "Ohhh, what? Oh I think I faked Oscar out.", "order": 16477, "speaker": "Michael Scott"},
  {"season": 4, "episode": 3, "scene": 42, "line": "You said you weren't coming back and we could leave early.", "order": 16478, "speaker": "Stanley Hudson"},
  {"season": 4, "episode": 3, "scene": 42, "line": "I think I faked Stanley out too. Who else thought that I was leaving? And they could all go home early? Gotcha. Gotcha. Gotcha. Well who needs New York? Right? We can have a killer party right here in Scranton.", "order": 16479, "speaker": "Michael Scott"},
  {"season": 4, "episode": 3, "scene": 42, "line": "Thank you.", "order": 16480, "speaker": "Angela Martin"},
  {"season": 4, "episode": 3, "scene": 42, "line": "Nope nope nope no. This is going to be nothing like the party you've been planning. This is going to be good and everybody's gonna come. What's wrong with Dwight?", "order": 16481, "speaker": "Michael Scott"},
  {"season": 4, "episode": 3, "scene": 42, "line": "He beat the computer.", "order": 16482, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 3, "scene": 42, "line": "Oh hey! Good for you. Good for you. Scranton power. Alright you know what? Angela I'd like you to come into my office in ten minutes. Bring something to write with and something to write on. Good.", "order": 16483, "speaker": "Michael Scott"},
  {"season": 4, "episode": 3, "scene": 43, "line": "This was a pretty disappointing day. It was kind of a slap in the face to realize that I wasn't as important as I thought I was to a certain young executive who I cared about. But, you know, I'm not gonna cry over it. I did that in the car on the way home.", "order": 16484, "speaker": "Michael Scott"},
  {"season": 4, "episode": 3, "scene": 44, "line": "How we doing on time?", "order": 16485, "speaker": "Michael Scott"},
  {"season": 4, "episode": 3, "scene": 44, "line": "The party starts in an hour.", "order": 16486, "speaker": "Angela Martin"},
  {"season": 4, "episode": 3, "scene": 44, "line": "Good. These are some things that I would like to have happen.", "order": 16487, "speaker": "Michael Scott"},
  {"season": 4, "episode": 3, "scene": 44, "line": "Beer, lite beer, streamers, orchids. Better lighting?", "order": 16488, "speaker": "Angela Martin"},
  {"season": 4, "episode": 3, "scene": 44, "line": "Mmmhmm.", "order": 16489, "speaker": "Michael Scott"},
  {"season": 4, "episode": 3, "scene": 44, "line": "Something made of ice?", "order": 16490, "speaker": "Angela Martin"},
  {"season": 4, "episode": 3, "scene": 44, "line": "Those are just things. This is how I want it to feel.", "order": 16491, "speaker": "Michael Scott"},
  {"season": 4, "episode": 3, "scene": 44, "line": "Pizza, pizza with mushrooms, pizza without mushrooms, white pizza, steak?", "order": 16492, "speaker": "Angela Martin"},
  {"season": 4, "episode": 3, "scene": 44, "line": "I would like this party to be sexier, cooler, more important...", "order": 16493, "speaker": "Michael Scott"},
  {"season": 4, "episode": 3, "scene": 44, "line": "Chocolates? Someone famous?", "order": 16494, "speaker": "Angela Martin"},
  {"season": 4, "episode": 3, "scene": 44, "line": "Yes", "order": 16495, "speaker": "Michael Scott"},
  {"season": 4, "episode": 3, "scene": 44, "line": "Cool music.", "order": 16496, "speaker": "Angela Martin"},
  {"season": 4, "episode": 3, "scene": 44, "line": "Uh...", "order": 16497, "speaker": "Michael Scott"},
  {"season": 4, "episode": 3, "scene": 44, "line": "Confetti.", "order": 16498, "speaker": "Angela Martin"},
  {"season": 4, "episode": 3, "scene": 44, "line": "I want it...", "order": 16499, "speaker": "Michael Scott"},
  {"season": 4, "episode": 3, "scene": 44, "line": "Go-Go dancers?", "order": 16500, "speaker": "Angela Martin"},
  {"season": 4, "episode": 3, "scene": 44, "line": "I want it to embarrass all other parties. I want it to be a party that the guys in New York watch on the web-cam and say, 'Wow! How did they get Al Roker to come?'", "order": 16501, "speaker": "Michael Scott"},
  {"season": 4, "episode": 3, "scene": 44, "line": "I can't do this.", "order": 16502, "speaker": "Angela Martin"},
  {"season": 4, "episode": 3, "scene": 44, "line": "Yes you can.", "order": 16503, "speaker": "Michael Scott"},
  {"season": 4, "episode": 3, "scene": 44, "line": "I can't do it.", "order": 16504, "speaker": "Angela Martin"},
  {"season": 4, "episode": 3, "scene": 44, "line": "Yes you can. I know you can. I wouldn't say that if I didn't hundred percent believe it. Who else could do this?", "order": 16505, "speaker": "Michael Scott"},
  {"season": 4, "episode": 3, "scene": 44, "line": "Okay. Okay.", "order": 16506, "speaker": "Angela Martin"},
  {"season": 4, "episode": 3, "scene": 44, "line": "No seriously. Is there anyone else who could do it better? Because we don't have a lot of time. I'll get the pizza!", "order": 16507, "speaker": "Michael Scott"},
  {"season": 4, "episode": 3, "scene": 45, "line": "What do you think of Angela?", "order": 16508, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 3, "scene": 45, "line": "I think she's efficient.", "order": 16509, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 3, "scene": 45, "line": "No, not like that, as a woman. W-O-M-A-N.", "order": 16510, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 3, "scene": 45, "line": "I hadn't noticed.", "order": 16511, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 3, "scene": 45, "line": "You hadn't noticed she's a woman?", "order": 16512, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 3, "scene": 45, "line": "[impatient sigh]", "order": 16513, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 3, "scene": 45, "line": "I hear she's single and ready to mingle. I'm thinking about making a play for her. What do you think? Crazy, right?", "order": 16514, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 3, "scene": 45, "line": "I think it's inappropriate to date someone you work with.", "order": 16515, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 3, "scene": 45, "line": "Isn't that part of the fun?", "order": 16516, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 3, "scene": 45, "line": "No. I think you should date Kelly.", "order": 16517, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 3, "scene": 45, "line": "She works here too, how is that any different?", "order": 16518, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 3, "scene": 45, "line": "Uh, she works in the annex. You're also welcome to date Toby.", "order": 16519, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 3, "scene": 45, "line": "[raised eyebrows] Okay.", "order": 16520, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 3, "scene": 46, "line": "Good news.", "order": 16521, "speaker": "Michael Scott"},
  {"season": 4, "episode": 3, "scene": 46, "line": "We get to go home?", "order": 16522, "speaker": "Stanley Hudson"},
  {"season": 4, "episode": 3, "scene": 46, "line": "Guess who just ordered from your favorite pizza place, Alfredo?", "order": 16523, "speaker": "Michael Scott"},
  {"season": 4, "episode": 3, "scene": 46, "line": "[murmurs of approval]", "order": 16524, "speaker": "All"},
  {"season": 4, "episode": 3, "scene": 46, "line": "Wait! Alfredo's Pizza Cafe? Or Pizza by Alfredo?", "order": 16525, "speaker": "Kevin Malone"},
  {"season": 4, "episode": 3, "scene": 46, "line": "Same thing.", "order": 16526, "speaker": "Michael Scott"},
  {"season": 4, "episode": 3, "scene": 46, "line": "No, no.", "order": 16527, "speaker": "Kevin Malone"},
  {"season": 4, "episode": 3, "scene": 46, "line": "[disagreeing with Michael]", "order": 16528, "speaker": "All"},
  {"season": 4, "episode": 3, "scene": 46, "line": "You know what? I don't understand when you all talk at the same time.", "order": 16529, "speaker": "Michael Scott"},
  {"season": 4, "episode": 3, "scene": 46, "line": "Oscar, talk to him.", "order": 16530, "speaker": "Kevin Malone"},
  {"season": 4, "episode": 3, "scene": 46, "line": "Michael, there's a very big difference between these two pizza places. Both in quality of ingredients, and overall taste. Which one did you order from?", "order": 16531, "speaker": "Oscar Martinez"},
  {"season": 4, "episode": 3, "scene": 46, "line": "Pizza by Alfredo.", "order": 16532, "speaker": "Michael Scott"},
  {"season": 4, "episode": 3, "scene": 46, "line": "[shouts of disapproval]", "order": 16533, "speaker": "All"},
  {"season": 4, "episode": 3, "scene": 46, "line": "Okay, okay, what's better? A medium amount of good pizza? Or all you can eat of pretty good pizza?", "order": 16534, "speaker": "Michael Scott"},
  {"season": 4, "episode": 3, "scene": 46, "line": "Medium amount of good pizza.", "order": 16535, "speaker": "All"},
  {"season": 4, "episode": 3, "scene": 46, "line": "[sighs, walks back into office]", "order": 16536, "speaker": "Michael Scott"},
  {"season": 4, "episode": 3, "scene": 47, "line": "Oh no, it's bad. It's real bad. It's like eating a hot circle of garbage.", "order": 16537, "speaker": "Kevin Malone"},
  {"season": 4, "episode": 3, "scene": 48, "line": "You can pick one of these things. It's unreasonable for you to ask me to do all of this.", "order": 16538, "speaker": "Phyllis Vance"},
  {"season": 4, "episode": 3, "scene": 48, "line": "It should take no time at all if you put the care into it that you normally do.", "order": 16539, "speaker": "Angela Martin"},
  {"season": 4, "episode": 3, "scene": 48, "line": "[Balls up post-its and throws them in Angela's face]", "order": 16540, "speaker": "Phyllis Vance"},
  {"season": 4, "episode": 3, "scene": 48, "line": "Ow!", "order": 16541, "speaker": "Angela Martin"},
  {"season": 4, "episode": 3, "scene": 49, "line": "That seemed to shut her up.", "order": 16542, "speaker": "Phyllis Vance"},
  {"season": 4, "episode": 3, "scene": 50, "line": "Are you looking for dinner and a movie? Because you're not going to find it in that box. [Camera pans to Kevin giving an 'are you kidding me?' look]", "order": 16543, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 3, "scene": 50, "line": "Just so happens that I know where you can find it, but again, not in the box.", "order": 16544, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 3, "scene": 50, "line": "Angela, are you hearing words that I'm saying?", "order": 16545, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 3, "scene": 50, "line": "What?", "order": 16546, "speaker": "Angela Martin"},
  {"season": 4, "episode": 3, "scene": 50, "line": "Hello.", "order": 16547, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 3, "scene": 50, "line": "I have been working on a party for three weeks that just got thrown out the window. So now I've got to pull together a whole new party, and my useless number two quit, so now there's no one in charge of orchids, chocolates, or the thing that's made of ice. And my upper back itches, and it's itched all day, and I can't reach it, and Kevin had Greek food for lunch again.", "order": 16548, "speaker": "Angela Martin"},
  {"season": 4, "episode": 3, "scene": 51, "line": "They say you should never mix business with pleasure. Really? Well then, explain to me how a putt-putt golf company operates.", "order": 16549, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 3, "scene": 52, "line": "Yup.", "order": 16550, "speaker": "Michael Scott"},
  {"season": 4, "episode": 3, "scene": 52, "line": "The Pizza by Alfredo guy is here.", "order": 16551, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 3, "scene": 52, "line": "You don't have to say it like that.", "order": 16552, "speaker": "Michael Scott"},
  {"season": 4, "episode": 3, "scene": 52, "line": "I said it normal.", "order": 16553, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 3, "scene": 52, "line": "Hey", "order": 16554, "speaker": "Michael Scott"},
  {"season": 4, "episode": 3, "scene": 52, "line": "Hey. Sixty-three fifty, and that's not including tip.", "order": 16555, "speaker": "Pizza guy"},
  {"season": 4, "episode": 3, "scene": 52, "line": "Right, I have a coupon, so half of sixty-three fifty, and half the tip of sixty-three fifty.", "order": 16556, "speaker": "Michael Scott"},
  {"season": 4, "episode": 3, "scene": 52, "line": "The half off coupon only applies to orders of two pizzas.", "order": 16557, "speaker": "Pizza guy"},
  {"season": 4, "episode": 3, "scene": 52, "line": "Yeah, I told them on the phone that I was ordering eight pizzas.", "order": 16558, "speaker": "Michael Scott"},
  {"season": 4, "episode": 3, "scene": 52, "line": "I don't care what you told them on the phone, that's our policy.", "order": 16559, "speaker": "Pizza guy"},
  {"season": 4, "episode": 3, "scene": 52, "line": "You didn't actually think I was going to spend sixty bucks on pizza?", "order": 16560, "speaker": "Michael Scott"},
  {"season": 4, "episode": 3, "scene": 52, "line": "It's not pizza.", "order": 16561, "speaker": "Oscar Martinez"},
  {"season": 4, "episode": 3, "scene": 52, "line": "Okay, it doesn't say it anywhere on the coupon, and if it's policy, it should say it on the coupon.", "order": 16562, "speaker": "Michael Scott"},
  {"season": 4, "episode": 3, "scene": 52, "line": "[shrugs]", "order": 16563, "speaker": "Pizza guy"},
  {"season": 4, "episode": 3, "scene": 52, "line": "[sarcastic shrugging of shoulders] What do you mean hmm-um?", "order": 16564, "speaker": "Michael Scott"},
  {"season": 4, "episode": 3, "scene": 52, "line": "Not my problem.", "order": 16565, "speaker": "Pizza guy"},
  {"season": 4, "episode": 3, "scene": 52, "line": "It is your problem. That's no way to do business, okay? I ordered eight pizzas with a half-off coupon, so I would like eight pizzas for half-off. End of story.", "order": 16566, "speaker": "Michael Scott"},
  {"season": 4, "episode": 3, "scene": 52, "line": "Great story. It's sixty-three fifty, and that's not including tip.", "order": 16567, "speaker": "Pizza guy"},
  {"season": 4, "episode": 3, "scene": 52, "line": "I'm not giving that to you.", "order": 16568, "speaker": "Michael Scott"},
  {"season": 4, "episode": 3, "scene": 52, "line": "Well then you're not getting you're pizzas.", "order": 16569, "speaker": "Pizza guy"},
  {"season": 4, "episode": 3, "scene": 52, "line": "No, no you're not going anywhere. You're staying here until we figure this out.", "order": 16570, "speaker": "Michael Scott"},
  {"season": 4, "episode": 3, "scene": 52, "line": "What?", "order": 16571, "speaker": "Pizza guy"},
  {"season": 4, "episode": 3, "scene": 52, "line": "You know what? This young man needs to learn that's not how you treat people. I don't care if it's pizza. Good business is about respect and accountability and follow through. You don't just make promises and pull the rug out from under somebody, do you? Dwight, please escort this young man into the conference room. Right now, get in the conference room.", "order": 16572, "speaker": "Michael Scott"},
  {"season": 4, "episode": 3, "scene": 52, "line": "I'm not going in there.", "order": 16573, "speaker": "Pizza guy"},
  {"season": 4, "episode": 3, "scene": 52, "line": "Yes you are, yes you are, and you will come out when you decide to give me a discount on the pizza. Please, thank you.", "order": 16574, "speaker": "Michael Scott"},
  {"season": 4, "episode": 3, "scene": 52, "line": "This is stupid.", "order": 16575, "speaker": "Pizza guy"},
  {"season": 4, "episode": 3, "scene": 52, "line": "No, you don't even know what stupid is. It's about to get all stupid up in here.", "order": 16576, "speaker": "Michael Scott"},
  {"season": 4, "episode": 3, "scene": 53, "line": "You find anything?", "order": 16577, "speaker": "Stanley Hudson"},
  {"season": 4, "episode": 3, "scene": 53, "line": "We think it's a straight forward kidnapping.", "order": 16578, "speaker": "Kevin Malone"},
  {"season": 4, "episode": 3, "scene": 53, "line": "Stanley, could you look up 'accomplices'?", "order": 16579, "speaker": "Oscar Martinez"},
  {"season": 4, "episode": 3, "scene": 53, "line": "Why can't you guys do it?", "order": 16580, "speaker": "Stanley Hudson"},
  {"season": 4, "episode": 3, "scene": 53, "line": "Because we're looking up jail time.", "order": 16581, "speaker": "Oscar Martinez"},
  {"season": 4, "episode": 3, "scene": 53, "line": "Fine.", "order": 16582, "speaker": "Stanley Hudson"},
  {"season": 4, "episode": 3, "scene": 54, "line": "I've seen this kid before. He's one the kids who sneaks on my farm and steals my hemp.", "order": 16583, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 3, "scene": 55, "line": "Yeah, I know that guy. He's that farmer that grows really crappy weed.", "order": 16584, "speaker": "Pizza guy"},
  {"season": 4, "episode": 3, "scene": 56, "line": "You ready to give me my discount now?", "order": 16585, "speaker": "Michael Scott"},
  {"season": 4, "episode": 3, "scene": 56, "line": "No.", "order": 16586, "speaker": "Pizza guy"},
  {"season": 4, "episode": 3, "scene": 56, "line": "Okay, what have you been doing in here this whole time?", "order": 16587, "speaker": "Michael Scott"},
  {"season": 4, "episode": 3, "scene": 56, "line": "What kind of business is this?", "order": 16588, "speaker": "Pizza guy"},
  {"season": 4, "episode": 3, "scene": 56, "line": "We're a paper company. The best paper company in the whole wide world.", "order": 16589, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 3, "scene": 56, "line": "Alright, Dwight, knock it off. You better think about what you are doing young man.", "order": 16590, "speaker": "Michael Scott"},
  {"season": 4, "episode": 3, "scene": 56, "line": "You better think about what you're doing.", "order": 16591, "speaker": "Pizza guy"},
  {"season": 4, "episode": 3, "scene": 56, "line": "No! I'm an adult, I don't have to think or do anything. You're a kid, a little snot-nosed, punk kid who thinks he's better than everyone else, because he's some hot shot, and you don't know anything about sales. So stop being a disrespectful little jerk, okay?", "order": 16592, "speaker": "Michael Scott"},
  {"season": 4, "episode": 3, "scene": 56, "line": "Sales?", "order": 16593, "speaker": "Pizza guy"},
  {"season": 4, "episode": 3, "scene": 56, "line": "Yeah sales, you sell pizza. Last time I checked that's called sales.", "order": 16594, "speaker": "Michael Scott"},
  {"season": 4, "episode": 3, "scene": 56, "line": "You're such a loser.", "order": 16595, "speaker": "Pizza guy"},
  {"season": 4, "episode": 3, "scene": 56, "line": "What did you just call him?", "order": 16596, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 3, "scene": 56, "line": "A loser.", "order": 16597, "speaker": "Pizza guy"},
  {"season": 4, "episode": 3, "scene": 56, "line": "What did you say?", "order": 16598, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 3, "scene": 56, "line": "A loser.", "order": 16599, "speaker": "Pizza guy"},
  {"season": 4, "episode": 3, "scene": 56, "line": "Alright stop, stop making him say it! You just made this worse, a whole lot worse.", "order": 16600, "speaker": "Michael Scott"},
  {"season": 4, "episode": 3, "scene": 56, "line": "I can make him talk, Michael.", "order": 16601, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 3, "scene": 56, "line": "Michael, Michael", "order": 16602, "speaker": "All"},
  {"season": 4, "episode": 3, "scene": 56, "line": "Stop talking all at once!", "order": 16603, "speaker": "Michael Scott"},
  {"season": 4, "episode": 3, "scene": 57, "line": "You need to let him go.", "order": 16604, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 3, "scene": 57, "line": "Let go of the little jerk boy before he has learned his lesson?", "order": 16605, "speaker": "Michael Scott"},
  {"season": 4, "episode": 3, "scene": 57, "line": "Yes.", "order": 16606, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 3, "scene": 57, "line": "You know what Jim, the world would be a better place if people were held accountable for their actions.", "order": 16607, "speaker": "Michael Scott"},
  {"season": 4, "episode": 3, "scene": 57, "line": "Yes, but not by kidnapping.", "order": 16608, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 3, "scene": 57, "line": "I'm not kidnapping him, I'm keeping him until I get what I want.", "order": 16609, "speaker": "Michael Scott"},
  {"season": 4, "episode": 3, "scene": 57, "line": "As a hostage.", "order": 16610, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 3, "scene": 57, "line": "I think you're over-thinking it.", "order": 16611, "speaker": "Michael Scott"},
  {"season": 4, "episode": 3, "scene": 57, "line": "I think you're under-thinking it.", "order": 16612, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 3, "scene": 57, "line": "Yes, is Alfredo there? Can I speak to a manager then? Okay, can you tell the manager that I'm keeping his delivery kid until I get a discount on the eight pizzas I ordered. Yes, I know it is not on the coupon. Also I would like him to throw in two, three pizzas for our...", "order": 16613, "speaker": "Michael Scott"},
  {"season": 4, "episode": 3, "scene": 57, "line": "Ransom.", "order": 16614, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 3, "scene": 57, "line": "Trouble. Okay, alright.", "order": 16615, "speaker": "Michael Scott"},
  {"season": 4, "episode": 3, "scene": 57, "line": "What did he say?", "order": 16616, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 3, "scene": 57, "line": "He said no.", "order": 16617, "speaker": "Michael Scott"},
  {"season": 4, "episode": 3, "scene": 57, "line": "So, we should let him go.", "order": 16618, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 3, "scene": 57, "line": "No, no.", "order": 16619, "speaker": "Michael Scott"},
  {"season": 4, "episode": 3, "scene": 57, "line": "Okay.", "order": 16620, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 3, "scene": 58, "line": "Listen up kid. [pops balloon with his hands] I don't like you, but because some town in Switzerland says so, you have rights.", "order": 16621, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 3, "scene": 58, "line": "I have to hang these.", "order": 16622, "speaker": "Angela Martin"},
  {"season": 4, "episode": 3, "scene": 58, "line": "Why are you looking at her like that?", "order": 16623, "speaker": "Pizza guy"},
  {"season": 4, "episode": 3, "scene": 58, "line": "Hey!", "order": 16624, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 3, "scene": 59, "line": "What's going on?", "order": 16625, "speaker": "Kevin Malone"},
  {"season": 4, "episode": 3, "scene": 59, "line": "Michael just called the pizza place with a list of demands.", "order": 16626, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 3, "scene": 59, "line": "Mister Overdramatic, what's up Kevin?", "order": 16627, "speaker": "Michael Scott"},
  {"season": 4, "episode": 3, "scene": 59, "line": "We're getting hungry out there. We're all accomplices now anyway, so we figured we might as well eat. We would like to order some good pizza, from Alfredo's Pizza Cafe, while we wait for the hostage situation with the bad pizza to end.", "order": 16628, "speaker": "Kevin Malone"},
  {"season": 4, "episode": 3, "scene": 60, "line": "I needed another hour, it could have been done in another hour.", "order": 16629, "speaker": "Angela Martin"},
  {"season": 4, "episode": 3, "scene": 60, "line": "I think it looks good.", "order": 16630, "speaker": "Meredith Palmer"},
  {"season": 4, "episode": 3, "scene": 60, "line": "That's why you're not in charge Meredith.", "order": 16631, "speaker": "Angela Martin"},
  {"season": 4, "episode": 3, "scene": 60, "line": "Here you are my dear, one thing made of ice.", "order": 16632, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 3, "scene": 60, "line": "How did you, um, where did you...", "order": 16633, "speaker": "Angela Martin"},
  {"season": 4, "episode": 3, "scene": 60, "line": "It's just ice, it'll melt all over the floor.", "order": 16634, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 3, "scene": 60, "line": "Will you help me put it over there?", "order": 16635, "speaker": "Angela Martin"},
  {"season": 4, "episode": 3, "scene": 60, "line": "Yes I will.", "order": 16636, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 3, "scene": 60, "line": "Okay.", "order": 16637, "speaker": "Angela Martin"},
  {"season": 4, "episode": 3, "scene": 60, "line": "Excuse me.", "order": 16638, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 3, "scene": 61, "line": "I stole it!", "order": 16639, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 3, "scene": 62, "line": "Thank God.", "order": 16640, "speaker": "Oscar Martinez"},
  {"season": 4, "episode": 3, "scene": 62, "line": "Michael, Ryan wants to introduce the branch managers in a few minutes. You just have to wave and introduce yourself.", "order": 16641, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 3, "scene": 62, "line": "I'll just wave and introduce myself.", "order": 16642, "speaker": "Michael Scott"},
  {"season": 4, "episode": 3, "scene": 62, "line": "Hey, quick question. If I take a pizza, do you think you could take some sodas and some napkins up to the roof?", "order": 16643, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 3, "scene": 62, "line": "I'm all over it.", "order": 16644, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 3, "scene": 62, "line": "Okay.", "order": 16645, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 3, "scene": 62, "line": "What have we got here?", "order": 16646, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 3, "scene": 62, "line": "Good pizza.", "order": 16647, "speaker": "Kevin Malone"},
  {"season": 4, "episode": 3, "scene": 62, "line": "Yeah, cheese, or do we have other flavors?", "order": 16648, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 3, "scene": 62, "line": "Different stuff.", "order": 16649, "speaker": "Kevin Malone"},
  {"season": 4, "episode": 3, "scene": 62, "line": "Which one's this? Perfect.", "order": 16650, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 3, "scene": 63, "line": "Do you remember what you said to me on my first day of work, just before you walked me over to my desk?", "order": 16651, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 3, "scene": 63, "line": "Yeah, enjoy this moment, because you're never going to go back to this time before you met your desk mate Dwight.", "order": 16652, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 3, "scene": 63, "line": "And that's when I knew. You?", "order": 16653, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 3, "scene": 63, "line": "You came up to my desk, and said, this might sound weird, and there's no reason for me to know this, but that mixed berry yogurt you're about to eat has expired.", "order": 16654, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 3, "scene": 63, "line": "That was the moment that you knew you liked me?", "order": 16655, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 3, "scene": 63, "line": "Yep.", "order": 16656, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 3, "scene": 63, "line": "Wow, can we make it a different moment?", "order": 16657, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 3, "scene": 63, "line": "Nope.", "order": 16658, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 3, "scene": 64, "line": "Can I start talking? Is this thing on? Give me a signal when you want me to start.", "order": 16659, "speaker": "Manager"},
  {"season": 4, "episode": 3, "scene": 64, "line": "And now from my old hometown, Scranton Pennsylvania, my former boss, Michael Scott.", "order": 16660, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 3, "scene": 64, "line": "Hey, I just think you should know that one of my salesmen beat your stupid computer, so take that [bleep]hole.", "order": 16661, "speaker": "Michael Scott"},
  {"season": 4, "episode": 3, "scene": 64, "line": "Always a jokester. How about that image? Crystal clear.", "order": 16662, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 3, "scene": 64, "line": "If anyone out there is listening, I'm being held here against my will. I'm a minor.", "order": 16663, "speaker": "Pizza guy"},
  {"season": 4, "episode": 3, "scene": 65, "line": "Ow! What are you doing?", "order": 16664, "speaker": "Angela Martin"},
  {"season": 4, "episode": 3, "scene": 65, "line": "You said your upper back itched.", "order": 16665, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 3, "scene": 65, "line": "I didn't ask you to scratch it.", "order": 16666, "speaker": "Angela Martin"},
  {"season": 4, "episode": 3, "scene": 65, "line": "Look Angela, I know this is weird because we work together, and up until and possibly now I've repulsed you, but I like you.", "order": 16667, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 3, "scene": 65, "line": "I'm not dating you.", "order": 16668, "speaker": "Angela Martin"},
  {"season": 4, "episode": 3, "scene": 66, "line": "So, Angela is stubborn as a mule, she's giving off fairly strong vibes that she's not interested.", "order": 16669, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 3, "scene": 66, "line": "[smiles]", "order": 16670, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 3, "scene": 66, "line": "But do I like her or not, because if I like her, then I can't back down.", "order": 16671, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 3, "scene": 66, "line": "[kicks open bathroom door] If you're going number one you've got ten more seconds!", "order": 16672, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 3, "scene": 67, "line": "Hey, have you seen Jim?", "order": 16673, "speaker": "Michael Scott"},
  {"season": 4, "episode": 3, "scene": 67, "line": "I guess he wanted to get out of here before the cops find out.", "order": 16674, "speaker": "Kevin Malone"},
  {"season": 4, "episode": 3, "scene": 67, "line": "Ahh-chaa!", "order": 16675, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 3, "scene": 67, "line": "Whoa, whoa, whoa, whoa, what are you doing?", "order": 16676, "speaker": "Michael Scott"},
  {"season": 4, "episode": 3, "scene": 67, "line": "I'm just scaring him. The trick is to make him think you're going to do something to him.", "order": 16677, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 3, "scene": 67, "line": "I can hear you, man.", "order": 16678, "speaker": "Pizza guy"},
  {"season": 4, "episode": 3, "scene": 67, "line": "Shut up, or I'm going to punch you in the throat!", "order": 16679, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 3, "scene": 67, "line": "Hey, hey, hey stop it. Stop it now, God. Oh my God, oh my God, no, no, no. I kidnapped a kid.", "order": 16680, "speaker": "Michael Scott"},
  {"season": 4, "episode": 3, "scene": 67, "line": "You had to, what other choice did you have?", "order": 16681, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 3, "scene": 67, "line": "I could have paid for the pizza.", "order": 16682, "speaker": "Michael Scott"},
  {"season": 4, "episode": 3, "scene": 67, "line": "Well, yeah.", "order": 16683, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 3, "scene": 67, "line": "Oh my God, oh my God.", "order": 16684, "speaker": "Michael Scott"},
  {"season": 4, "episode": 3, "scene": 68, "line": "This is Michael.", "order": 16685, "speaker": "Michael Scott"},
  {"season": 4, "episode": 3, "scene": 68, "line": "Hello Michael, this is Ryan, first off thanks for the shout out.", "order": 16686, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 3, "scene": 68, "line": "You're breaking up. I can't hear you.", "order": 16687, "speaker": "Michael Scott"},
  {"season": 4, "episode": 3, "scene": 68, "line": "Why is there a kid on your webcam saying that he's being held against his... [Michael hangs up on Ryan]", "order": 16688, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 3, "scene": 68, "line": "[to Dwight] Mmm-kay. I want you to go in there, and pay him for the pizzas, and give him a generous tip, no more than ten percent.", "order": 16689, "speaker": "Michael Scott"},
  {"season": 4, "episode": 3, "scene": 68, "line": "What will you do?", "order": 16690, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 3, "scene": 68, "line": "I will open the door.", "order": 16691, "speaker": "Michael Scott"},
  {"season": 4, "episode": 3, "scene": 68, "line": "Yeah.", "order": 16692, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 3, "scene": 68, "line": "And hopefully he will walk out, and the rest is out of our hands.", "order": 16693, "speaker": "Michael Scott"},
  {"season": 4, "episode": 3, "scene": 68, "line": "So, I'm paying full price?", "order": 16694, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 3, "scene": 68, "line": "Yes.", "order": 16695, "speaker": "Michael Scott"},
  {"season": 4, "episode": 3, "scene": 68, "line": "Here we go, sixty-five. You know what? There's two more.", "order": 16696, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 3, "scene": 68, "line": "See ya, drive safely.", "order": 16697, "speaker": "Michael Scott"},
  {"season": 4, "episode": 3, "scene": 68, "line": "[pizza guy flips him off] Okay.", "order": 16698, "speaker": "Michael Scott"},
  {"season": 4, "episode": 3, "scene": 68, "line": "Now what?", "order": 16699, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 3, "scene": 68, "line": "Now we wait, and hopefully nothing happens.", "order": 16700, "speaker": "Michael Scott"},
  {"season": 4, "episode": 3, "scene": 68, "line": "Alright. Oh, I assume I'm going to be reimbursed for the pizzas.", "order": 16701, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 3, "scene": 68, "line": "Not now Dwight, please, it's not the time.", "order": 16702, "speaker": "Michael Scott"},
  {"season": 4, "episode": 3, "scene": 69, "line": "A toast, better make it good. To avoiding a class two felony charge.", "order": 16703, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 3, "scene": 69, "line": "Ah-ha.", "order": 16704, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 3, "scene": 70, "line": "[answers two ringing phones] Hello, hey hang on a second. Hello, hang on.", "order": 16705, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 3, "scene": 70, "line": "[Andy plus two voices on the phone singing] If you change your mind, I'll be first in line. Honey, I'm still free, take a chance on me. If you need me let me know, gonna be around. If you got no place to go, if you're feeling down. If your all alone when the pretty birds have flown, honey I'm still free, take a chance on me. Gonna do my very best, and that ain't no lie, if you put me to the test, if you let me try. Take a chance on me, that's all I ask of you Angela. Take a chance on me.", "order": 16706, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 3, "scene": 70, "line": "Hey how'd it go?", "order": 16707, "speaker": "Voice #1 on phone"},
  {"season": 4, "episode": 3, "scene": 70, "line": "Yeah, what'd she say?", "order": 16708, "speaker": "Voice #2 on phone"},
  {"season": 4, "episode": 3, "scene": 70, "line": "I don't know yet, I have to call you back.", "order": 16709, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 3, "scene": 70, "line": "You have to give us something...", "order": 16710, "speaker": "Voice #1 on phone"},
  {"season": 4, "episode": 3, "scene": 70, "line": "I'll call you back.", "order": 16711, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 3, "scene": 70, "line": "I have to go clean up after the party.", "order": 16712, "speaker": "Angela Martin"},
  {"season": 4, "episode": 3, "scene": 71, "line": "What a horrible day.", "order": 16713, "speaker": "Michael Scott"},
  {"season": 4, "episode": 3, "scene": 71, "line": "Blah.", "order": 16714, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 3, "scene": 71, "line": "Bluh.", "order": 16715, "speaker": "Michael Scott"},
  {"season": 4, "episode": 3, "scene": 71, "line": "Uhh.", "order": 16716, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 3, "scene": 71, "line": "Well, I need to get the horrible taste of this pizza out of my mouth. I'd really like some sushi. I was hoping that I would have New York style sushi today. And you know what?", "order": 16717, "speaker": "Michael Scott"},
  {"season": 4, "episode": 3, "scene": 71, "line": "What?", "order": 16718, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 3, "scene": 71, "line": "I'm going to get it.", "order": 16719, "speaker": "Michael Scott"},
  {"season": 4, "episode": 3, "scene": 71, "line": "Coopers has calamari.", "order": 16720, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 3, "scene": 71, "line": "Uh-uh, no, there is only one place where they authentic New York style sushi.", "order": 16721, "speaker": "Michael Scott"},
  {"season": 4, "episode": 3, "scene": 71, "line": "Tokyo?", "order": 16722, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 3, "scene": 71, "line": "New York. Wanna go?", "order": 16723, "speaker": "Michael Scott"},
  {"season": 4, "episode": 3, "scene": 71, "line": "Yes.", "order": 16724, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 3, "scene": 71, "line": "Alright, you drive.", "order": 16725, "speaker": "Michael Scott"},
  {"season": 4, "episode": 3, "scene": 71, "line": "Okay.", "order": 16726, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 3, "scene": 72, "line": "Nice.", "order": 16727, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 3, "scene": 72, "line": "Here we go.", "order": 16728, "speaker": "Michael Scott"},
  {"season": 4, "episode": 3, "scene": 72, "line": "Woo-hoo.", "order": 16729, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 3, "scene": 72, "line": "Mmm.", "order": 16730, "speaker": "Michael Scott"},
  {"season": 4, "episode": 3, "scene": 72, "line": "Yum.", "order": 16731, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 3, "scene": 72, "line": "I'm sorry, you guys are going to have to leave.", "order": 16732, "speaker": "Bartender"},
  {"season": 4, "episode": 3, "scene": 72, "line": "Hey, you know what? [Michael and Dwight grab plates of sushi] Come on, come on, let's go.", "order": 16733, "speaker": "Michael Scott"},
  {"season": 4, "episode": 3, "scene": 72, "line": "Hey, you're the Scranton guy.", "order": 16734, "speaker": "Man"},
  {"season": 4, "episode": 3, "scene": 72, "line": "Guilty.", "order": 16735, "speaker": "Michael Scott"},
  {"season": 4, "episode": 3, "scene": 72, "line": "I liked your statement tonight.", "order": 16736, "speaker": "Man"},
  {"season": 4, "episode": 3, "scene": 72, "line": "Oh, thanks. This is the guy that beat the computer.", "order": 16737, "speaker": "Michael Scott"},
  {"season": 4, "episode": 3, "scene": 72, "line": "Oh, very cool. It was funny to see Ryan all embarrassed by that.", "order": 16738, "speaker": "Man"},
  {"season": 4, "episode": 3, "scene": 72, "line": "Yeah.", "order": 16739, "speaker": "Michael Scott"},
  {"season": 4, "episode": 3, "scene": 72, "line": "See you later.", "order": 16740, "speaker": "Man"},
  {"season": 4, "episode": 3, "scene": 72, "line": "Later on.", "order": 16741, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 3, "scene": 72, "line": "[mocking Ryan] I'm Ryan, and tonight didn't go the way that I thought it would, because it didn't work out for me, and I'm very embarrassed. I got egg all over my face.", "order": 16742, "speaker": "Michael Scott"},
  {"season": 4, "episode": 3, "scene": 72, "line": "And I spent so much time in Scranton and I never sold any paper.", "order": 16743, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 3, "scene": 72, "line": "I never sold any paper, because I'm an idiot.", "order": 16744, "speaker": "Michael Scott"},
  {"season": 4, "episode": 3, "scene": 72, "line": "I started a fire with my cheese pita.", "order": 16745, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 3, "scene": 72, "line": "I made it with my cheese pita.", "order": 16746, "speaker": "Michael Scott"},
  {"season": 4, "episode": 3, "scene": 72, "line": "I date Indian girls.", "order": 16747, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 3, "scene": 72, "line": "I started a fire, I started a fire.", "order": 16748, "speaker": "Michael Scott"},
  {"season": 4, "episode": 3, "scene": 72, "line": "Now I've got a beard, and I can do whatever I want, and I'm your boss.", "order": 16749, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 3, "scene": 72, "line": "And I'm hot, I'm so hot. That's why everybody...", "order": 16750, "speaker": "Michael Scott"},
  {"season": 4, "episode": 3, "scene": 72, "line": "I don't get that, I don't understand that.", "order": 16751, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 3, "scene": 72, "line": "Well, it's part of it, it's just the... uhhh.", "order": 16752, "speaker": "Michael Scott"},
  {"season": 4, "episode": 3, "scene": 72, "line": "Wanna head back?", "order": 16753, "speaker": "Michael Scott"},
  {"season": 4, "episode": 3, "scene": 72, "line": "Yeah, let's go.", "order": 16754, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 3, "scene": 73, "line": "[steps in front of camera, adjusts jacket, poses, winks]", "order": 16755, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 3, "scene": 74, "line": "[Dwight come into office unshaven] What?", "order": 16756, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 3, "scene": 74, "line": "Well it's just that you had no hair on Friday.", "order": 16757, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 3, "scene": 74, "line": "It's called being a man. You should try it sometime.", "order": 16758, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 3, "scene": 74, "line": "How long have you been a man?", "order": 16759, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 3, "scene": 74, "line": "I was born a man, Halpert.", "order": 16760, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 3, "scene": 74, "line": "That must have been extremely uncomfortable for your mom.", "order": 16761, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 3, "scene": 75, "line": "I stopped shaving because my girlfriend broke up with me. Am I in pain? Hell, yeah. But I'll tell you something, I thrive in pain. I love pain. To me, pain is not pain at all. No. It is pure pleasure. And I hate pleasure. Almost as much as I love pain. So, yeah, I'm in pain.", "order": 16762, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 3, "scene": 76, "line": "[to Dwight] What do you think of Angela? There's just something about her. All that strength and steeliness and righteousness all wrapped up tight and shoved into a tiny little delicate frame.", "order": 16763, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 3, "scene": 77, "line": "No. I will not be playing it 'cool' [makes air quotes] with Angela. Let me tell you a little story. When I was seventeen, I was waitlisted at my number one school. Even though I was a legacy, and I had like a thousand extra-curriculars, mostly drama, madrigals, barbershop club, I was waitlisted. Did I wait, on that list? No, I did not. I busted into the admissions office and I [singing] sang them all the reasons they should admit me to the school [end singing]. And guess what? I. Got. In. And here's the kicker. That school? Cornell.", "order": 16764, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 3, "scene": 78, "line": "What's wrong with me?", "order": 16765, "speaker": "Michael Scott"},
  {"season": 4, "episode": 3, "scene": 78, "line": "Excuse me?", "order": 16766, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 3, "scene": 78, "line": "I want you to look at me and tell me what is wrong with me. [Pam stares and nods] Don't avoid this. I know you're dying to say it, so just say it.", "order": 16767, "speaker": "Michael Scott"},
  {"season": 4, "episode": 3, "scene": 78, "line": "Sometimes your laziness borders on incompetence.", "order": 16768, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 3, "scene": 78, "line": "What, no. No. Okay, bags. I have bags under my eyes, Pam. I didn't see it at home, I didn't see it in the bathroom, I didn't see it on any of the city mirrors, but in this light...", "order": 16769, "speaker": "Michael Scott"},
  {"season": 4, "episode": 3, "scene": 78, "line": "[interrupting] The city mirrors, or the...?", "order": 16770, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 3, "scene": 78, "line": "The big, free mirrors that the city puts up, on trees and telephone poles? The big round things.", "order": 16771, "speaker": "Michael Scott"},
  {"season": 4, "episode": 3, "scene": 78, "line": "The ones for drivers to check their blind spots?", "order": 16772, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 3, "scene": 78, "line": "Yes. I have bags under my eyes, and I can't go to New York like this! What do I do? What do I do?", "order": 16773, "speaker": "Michael Scott"},
  {"season": 4, "episode": 3, "scene": 78, "line": "Put cold tea bags on your eyes.", "order": 16774, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 3, "scene": 78, "line": "Really?", "order": 16775, "speaker": "Michael Scott"},
  {"season": 4, "episode": 3, "scene": 78, "line": "Yeah.", "order": 16776, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 3, "scene": 78, "line": "That's it?", "order": 16777, "speaker": "Michael Scott"},
  {"season": 4, "episode": 3, "scene": 78, "line": "Mmhmm.", "order": 16778, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 3, "scene": 78, "line": "All right! Martha Stewart! You can be Martha Stewart's receptionist! Very good! I will be tea baggin' it. Nn... no.", "order": 16779, "speaker": "Michael Scott"},
  {"season": 4, "episode": 3, "scene": 79, "line": "[tea bags on eyes] Do I feel badly, that nobody out there was invited to a party that I was invited to? Not at all. Because they have to know that if they work hard and apply themselves, someday, they could be invited to a party like this. Of course, at that point, I will be going to much better parties that they will not be able to get into. What are you gonna do?", "order": 16780, "speaker": "Michael Scott"},
  {"season": 4, "episode": 3, "scene": 80, "line": "I'm a little mad, that I don't get to go to the party in New York. But that's mostly just because we get reimbursed for gas mileage.", "order": 16781, "speaker": "Kevin Malone"},
  {"season": 4, "episode": 3, "scene": 81, "line": "I was going to be in New York tonight, to go to the Met, but I had to cancel. Because Angela's party is mandatory.", "order": 16782, "speaker": "Oscar Martinez"},
  {"season": 4, "episode": 3, "scene": 82, "line": "I go to New York all the time, to visit my buddy Frank. He's a mole person.", "order": 16783, "speaker": "Creed Bratton"},
  {"season": 4, "episode": 3, "scene": 83, "line": "Angela, what kind of music?", "order": 16784, "speaker": "Meredith Palmer"},
  {"season": 4, "episode": 3, "scene": 83, "line": "Uh... something cool that Ryan doesn't know about yet.", "order": 16785, "speaker": "Angela Martin"},
  {"season": 4, "episode": 3, "scene": 83, "line": "How am I supposed to know what that is?", "order": 16786, "speaker": "Meredith Palmer"},
  {"season": 4, "episode": 3, "scene": 83, "line": "I don't know, but standing here's not going to give you the answer. Go.", "order": 16787, "speaker": "Angela Martin"},
  {"season": 4, "episode": 3, "scene": 84, "line": "Tonight my party will be broadcast out to five other states. Which means, it will be compared to Denise Stimm's party in Buffalo. Any idiot can defrost a microwavable hors d'oeuvres platter. And Denise proves that with every party she throws. Oh, and Denise? Stop telling people your hair's naturally curly. We all know you get perms.", "order": 16788, "speaker": "Angela Martin"},
  {"season": 4, "episode": 3, "scene": 85, "line": "Kidnapping is the asportation of a person against the person's will, so Michael asported him. [giggles] Have you ever been aspor...", "order": 16789, "speaker": "Kevin Malone"},
  {"season": 4, "episode": 3, "scene": 85, "line": "Don't.", "order": 16790, "speaker": "Oscar Martinez"},
  {"season": 4, "episode": 3, "scene": 86, "line": "Hey. The way I see it, it's getting late, and the only thing standing between you and a warm bed is my friend's pizza discount. So whattaya say? [pizza guy stares] Oh, so that's how it's gonna be. Well, I can stay here all night if I have to. I've done it before.", "order": 16791, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 3, "scene": 86, "line": "I'm not scared of you.", "order": 16792, "speaker": "Pizza guy"},
  {"season": 4, "episode": 3, "scene": 87, "line": "In every good hostage movie, during the part where it gets really tense, and you don't know whether the bad guys are going to let the hostages go free, the cops order pizza.", "order": 16793, "speaker": "Kevin Malone"},
  {"season": 4, "episode": 3, "scene": 88, "line": "The lady cop acted like she'd never pulled someone over for driving too slow. And I tried to get out of it with the famous Toby Flenderson 10,000 watt smile. [smiles] It didn't work.", "order": 16794, "speaker": "Toby Flenderson"},
  {"season": 4, "episode": 3, "scene": 89, "line": "Damn it.", "order": 16795, "speaker": "Toby Flenderson"},
  {"season": 4, "episode": 3, "scene": 89, "line": "Is that traffic school?", "order": 16796, "speaker": "Kelly Kapoor"},
  {"season": 4, "episode": 3, "scene": 89, "line": "Yeah.", "order": 16797, "speaker": "Toby Flenderson"},
  {"season": 4, "episode": 3, "scene": 89, "line": "Because we're not supposed to be doing personal stuff at work.", "order": 16798, "speaker": "Kelly Kapoor"},
  {"season": 4, "episode": 3, "scene": 89, "line": "Yeah.", "order": 16799, "speaker": "Toby Flenderson"},
  {"season": 4, "episode": 3, "scene": 89, "line": "Because yesterday when I was taking an online quiz about trying to find my ideal weight for my frame, you said that was inappropriate.", "order": 16800, "speaker": "Kelly Kapoor"},
  {"season": 4, "episode": 3, "scene": 89, "line": "I remember.", "order": 16801, "speaker": "Toby Flenderson"},
  {"season": 4, "episode": 3, "scene": 89, "line": "Just reiterating what you said to me.", "order": 16802, "speaker": "Kelly Kapoor"},
  {"season": 4, "episode": 3, "scene": 89, "line": "Thanks Kelly.", "order": 16803, "speaker": "Toby Flenderson"},
  {"season": 4, "episode": 3, "scene": 90, "line": "You know the octagon sign means stop.", "order": 16804, "speaker": "Oscar Martinez"},
  {"season": 4, "episode": 3, "scene": 90, "line": "Colorblind.", "order": 16805, "speaker": "Toby Flenderson"},
  {"season": 4, "episode": 3, "scene": 90, "line": "An octagon is a shape. You can see shapes, can't you?", "order": 16806, "speaker": "Oscar Martinez"},
  {"season": 4, "episode": 3, "scene": 90, "line": "It's out of context. It's not the same as driving.", "order": 16807, "speaker": "Toby Flenderson"},
  {"season": 4, "episode": 4, "scene": 1, "line": "Coat! [throws coat at Pam]", "order": 16808, "speaker": "Michael Scott"},
  {"season": 4, "episode": 4, "scene": 2, "line": "Michael just rented The Devil Wears Prada. He has his NetFlix sent here to the office, and he watches them in pieces when things are slow.", "order": 16809, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 4, "scene": 3, "line": "Steak! Where's my steeaaak?", "order": 16810, "speaker": "Michael Scott"},
  {"season": 4, "episode": 4, "scene": 4, "line": "He's a big Meryl Streep fan, so I shouldn't be surprised that he's identified with her character.", "order": 16811, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 4, "scene": 5, "line": "Get me Armani.", "order": 16812, "speaker": "Michael Scott"},
  {"season": 4, "episode": 4, "scene": 5, "line": "A suit?", "order": 16813, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 4, "scene": 5, "line": "On the phone.", "order": 16814, "speaker": "Michael Scott"},
  {"season": 4, "episode": 4, "scene": 5, "line": "Like the main company number? Because I'm gonna have to call information.", "order": 16815, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 4, "scene": 5, "line": "Where's Armani? He's on the phone. Too slow. You are not going to Paris. I'm so much better than you are. [breaks into laughter]", "order": 16816, "speaker": "Michael Scott"},
  {"season": 4, "episode": 4, "scene": 6, "line": "I owe you an apology.", "order": 16817, "speaker": "Michael Scott"},
  {"season": 4, "episode": 4, "scene": 6, "line": "You finished the movie.", "order": 16818, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 4, "scene": 6, "line": "Yeah. It was awesome. Big surprise ending. Won't ruin it for you.", "order": 16819, "speaker": "Michael Scott"},
  {"season": 4, "episode": 4, "scene": 6, "line": "No. Go ahead.", "order": 16820, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 4, "scene": 6, "line": "Meryl Streep is the bad guy. Never see it coming. Anyways, if I was mean in any way to you, I am sorry. I just want what's best for you, Mo Chuisle.", "order": 16821, "speaker": "Michael Scott"},
  {"season": 4, "episode": 4, "scene": 7, "line": "Mo Chuisle. He's watching Million Dollar Baby... He's gonna try to kill me.", "order": 16822, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 4, "scene": 8, "line": "So this one goes with my eyes and this one goes with your eyes. People have said I have very pretty eyes.", "order": 16823, "speaker": "Micahel"},
  {"season": 4, "episode": 4, "scene": 8, "line": "You do.", "order": 16824, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 4, "scene": 8, "line": "I haven't heard the same about you. So let's just go with mine.", "order": 16825, "speaker": "Michael Scott"},
  {"season": 4, "episode": 4, "scene": 8, "line": "Well, they both go with the carpet I've ordered, and if you go with the brown leather on the sofas, then they go with that too.", "order": 16826, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 4, "scene": 8, "line": "We already have a sofa. So why do---", "order": 16827, "speaker": "Michael Scott"},
  {"season": 4, "episode": 4, "scene": 8, "line": "A futon's not a sofa.", "order": 16828, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 4, "scene": 8, "line": "It... folds up. You've only seen it flat.", "order": 16829, "speaker": "Michael Scott"},
  {"season": 4, "episode": 4, "scene": 8, "line": "I know what a futon is, Michael.", "order": 16830, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 4, "scene": 8, "line": "I--- Ok. How much is this going to cost?", "order": 16831, "speaker": "Michael Scott"},
  {"season": 4, "episode": 4, "scene": 8, "line": "It costs what it costs.", "order": 16832, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 4, "scene": 8, "line": "No--- don't... that doesn't even mean anything.", "order": 16833, "speaker": "Michael Scott"},
  {"season": 4, "episode": 4, "scene": 8, "line": "We have gone through this.", "order": 16834, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 4, "scene": 9, "line": "Yes. Money has been a little tight lately. But, at the end of my life, when I'm sitting on my yacht, am I gonna be thinking about how much money I have? No. I'm going to be thinking about how many friends I have, and my children, and my comedy albums. I mean, I have a yacht, so I obviously did pretty well money wise.", "order": 16835, "speaker": "Michael Scott"},
  {"season": 4, "episode": 4, "scene": 10, "line": "It's just that you say it's gonna cost what it costs--- [phone rings]", "order": 16836, "speaker": "Michael Scott"},
  {"season": 4, "episode": 4, "scene": 10, "line": "[on the phone] Michael, it's Ryan for you.", "order": 16837, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 4, "scene": 10, "line": "Conniving little runt. Put him through.", "order": 16838, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 4, "scene": 10, "line": "Put him through. Yes. Ryan, my man!", "order": 16839, "speaker": "Michael Scott"},
  {"season": 4, "episode": 4, "scene": 10, "line": "[on the phone] I sent you an email about the new Powerpoint.", "order": 16840, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 4, "scene": 10, "line": "Yes, and thank you for sending that to me.", "order": 16841, "speaker": "Michael Scott"},
  {"season": 4, "episode": 4, "scene": 10, "line": "I had IT install the updated Powerpoint on the computer so you can use it for the presentation. I really want people there using Powerpoint.", "order": 16842, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 4, "scene": 10, "line": "Okay, yeah, I dunno. I dunno, I think those IT guys messed up.", "order": 16843, "speaker": "Michael Scott"},
  {"season": 4, "episode": 4, "scene": 10, "line": "Hold on, I'll get them on the phone.", "order": 16844, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 4, "scene": 10, "line": "[looking at nothing] Wait, oh, no, here it is, here it is. Found it.", "order": 16845, "speaker": "Michael Scott"},
  {"season": 4, "episode": 4, "scene": 11, "line": "[talking on phone] Sure, I can hold.", "order": 16846, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 4, "scene": 11, "line": "[picks up phone] Dunder Mifflin, Dwight Schrute. Please hold. [opens book, then picks up phone] Schrute Farms, guten tag. How can I help you? Yes, we have availability on those nights. How many in your party? Oh no, I'm sorry, no king beds. No queen either. Well, we make our own mattresses that don't conform to the traditional sizes. Closest would be twin. Thank you so much for calling. Call back again. Aufedersein!", "order": 16847, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 4, "scene": 11, "line": "Hey Dwight.", "order": 16848, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 4, "scene": 11, "line": "None of your business, Jim.", "order": 16849, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 4, "scene": 11, "line": "Do you run the bed and breakfest?", "order": 16850, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 4, "scene": 11, "line": "It is not a B and B.", "order": 16851, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 4, "scene": 12, "line": "Agrotourism is a lot more than a bed and breakfest. It consists of tourists coming to a farm, showing them around, giving them a bed, giving them brekafest.", "order": 16852, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 4, "scene": 13, "line": "Does the Department of Health know about this?", "order": 16853, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 4, "scene": 13, "line": "I'm not telling you anything. [lookings into the camera] Permits are pending. [phone rings] Dwight Schrute, Dunder Mifflin.", "order": 16854, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 4, "scene": 13, "line": "Hello, I'm looking for a room.", "order": 16855, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 4, "scene": 13, "line": "Okay, this is a mis-use of company phones.", "order": 16856, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 4, "scene": 13, "line": "It says here you cater to the eldery.", "order": 16857, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 4, "scene": 13, "line": "Where did you read that?", "order": 16858, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 4, "scene": 13, "line": "Trip Advsior.", "order": 16859, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 4, "scene": 14, "line": "Trip Advisor is the life blood of the Agrotourism industry. A couple of bad reviews there, you might as well close up shop. That's what took down the Stalk Inn. One of the cutest little asparagus farms you'll ever see.", "order": 16860, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 4, "scene": 15, "line": "How many in your party?", "order": 16861, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 4, "scene": 15, "line": "Two?", "order": 16862, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 4, "scene": 15, "line": "We offer tours of the fields, and of the barn. Uh, perhaps you'll be interested in, um, Mose's table making demonstration?", "order": 16863, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 4, "scene": 16, "line": "So, um, I forgot to tell you that I need the car tonight.", "order": 16864, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 4, "scene": 16, "line": "Oh! Um, actually, I need the car.", "order": 16865, "speaker": "Michael Scott"},
  {"season": 4, "episode": 4, "scene": 16, "line": "Why? Improv? Why don't you just pretend you have a car? Good practice, incase you ever do a scene where you need to pretend you have a car?", "order": 16866, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 4, "scene": 17, "line": "Use to have two cars, traded 'em in, now we're down to one. Good economic sense. Although the new car is a Porsche. For her.", "order": 16867, "speaker": "Michael Scott"},
  {"season": 4, "episode": 4, "scene": 18, "line": "One cardigan, one sleeping cardigan and one sleep apnea mask.", "order": 16868, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 4, "scene": 18, "line": "What about my cherub figurine?", "order": 16869, "speaker": "Angela Martin"},
  {"season": 4, "episode": 4, "scene": 18, "line": "You took that with you.", "order": 16870, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 4, "scene": 18, "line": "No I left it on my night table--- your night table, by the lamp.", "order": 16871, "speaker": "Angela Martin"},
  {"season": 4, "episode": 4, "scene": 18, "line": "You are incorrect. I was recently scrubbing my room of memories, and I didn't see it there.", "order": 16872, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 4, "scene": 18, "line": "Fine.", "order": 16873, "speaker": "Angela Martin"},
  {"season": 4, "episode": 4, "scene": 19, "line": "My girlfriend and I broke up recently. And I must say, I am relieved. Gives me a chance to sow my wild oats. In the Schrute family, we have a tradition, where when the male has sex with another woman, he is rewarded with a bag of wild oats left on his door step by his parents. You can use these oats to make oatmeal, bread, whatever you want. I don't care, they're your oats.", "order": 16874, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 4, "scene": 20, "line": "The bar uses an applause meter. That is why it's so important that you all come and applaud only for my band. Scrantonicity 2. NOT Scrantonicity, which I am no longer a part of. Michael, can I count on you?", "order": 16875, "speaker": "Kevin Malone"},
  {"season": 4, "episode": 4, "scene": 20, "line": "You can not, I have a thing tonight.", "order": 16876, "speaker": "Michael Scott"},
  {"season": 4, "episode": 4, "scene": 20, "line": "Dammit.", "order": 16877, "speaker": "Kevin Malone"},
  {"season": 4, "episode": 4, "scene": 20, "line": "Uh, Michael.", "order": 16878, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 4, "scene": 20, "line": "What?", "order": 16879, "speaker": "Michael Scott"},
  {"season": 4, "episode": 4, "scene": 20, "line": "That reminds me, uh, if the invitation still stands, Pam and I would love to have dinner tonight.", "order": 16880, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 4, "scene": 20, "line": "Oh no, I have a thing tonight.", "order": 16881, "speaker": "Michael Scott"},
  {"season": 4, "episode": 4, "scene": 20, "line": "Darn it!", "order": 16882, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 4, "scene": 20, "line": "Shoot!", "order": 16883, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 4, "scene": 20, "line": "How about this weekend?", "order": 16884, "speaker": "Michael Scott"},
  {"season": 4, "episode": 4, "scene": 20, "line": "No, can't.", "order": 16885, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 4, "scene": 20, "line": "We only had tonight free, and we really wanted to spend it with you.", "order": 16886, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 4, "scene": 20, "line": "Dammit to hell. I-I-ugh, ok. All right.", "order": 16887, "speaker": "Michael Scott"},
  {"season": 4, "episode": 4, "scene": 20, "line": "Where are you going out tonight?", "order": 16888, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 4, "scene": 20, "line": "You wouldn't understand. It's a secret.", "order": 16889, "speaker": "Michael Scott"},
  {"season": 4, "episode": 4, "scene": 20, "line": "I wouldn't understand or a secret?", "order": 16890, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 4, "scene": 20, "line": "You wouldn't understand, Jim. It's a secret.", "order": 16891, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 4, "scene": 21, "line": "I'm sorry Mr. O'Brian, I didn't mean to interrupt your dinner. I just have a very exciting offer. My records indicate that you have expressed interest in losing some weight. Well, what if I told you that I have a pill that will make you 50 pounds lighter in 5 minutes? How does that sound? Amazing right? Well, it won't be that fast, but it will--[notices camera]-- it will be that easy.", "order": 16892, "speaker": "Michael Scott"},
  {"season": 4, "episode": 4, "scene": 22, "line": "I can't believe this place is real. I mean, I've heard about his beet farm for years, but wow.", "order": 16893, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 4, "scene": 22, "line": "The Beets Motel.", "order": 16894, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 4, "scene": 22, "line": "The Beets Motel? That is, wow.", "order": 16895, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 4, "scene": 22, "line": "Thank you. Eh. The Embassy Beets. Radishon!", "order": 16896, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 4, "scene": 22, "line": "How are you doing this?", "order": 16897, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 4, "scene": 22, "line": "I don't know! [Mose starts running by the left side of the car]", "order": 16898, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 4, "scene": 22, "line": "Oh my gosh.", "order": 16899, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 4, "scene": 23, "line": "I just love sales. I love it to death. It's as simple as that. And I don't get to do it enough as a manager, so I took this second job. I count it as a hobby. Some people have golf, or relaxing.", "order": 16900, "speaker": "Michael Scott"},
  {"season": 4, "episode": 4, "scene": 23, "line": "What's going on here?", "order": 16901, "speaker": "Nick"},
  {"season": 4, "episode": 4, "scene": 23, "line": "Hey, I just got off the phone, and I was gonna make another call.", "order": 16902, "speaker": "Michael Scott"},
  {"season": 4, "episode": 4, "scene": 23, "line": "We're a legit operation with a license from the city I can show you. We pay minimum wage against commision---", "order": 16903, "speaker": "Nick"},
  {"season": 4, "episode": 4, "scene": 23, "line": "No, they're with me, so... this is Nick Figaro, manager to the stars!", "order": 16904, "speaker": "Michael Scott"},
  {"season": 4, "episode": 4, "scene": 24, "line": "We have three rooms, each with a different theme.", "order": 16905, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 4, "scene": 24, "line": "What are the themes?", "order": 16906, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 4, "scene": 24, "line": "American, Irrigation, and Night-Time.", "order": 16907, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 4, "scene": 24, "line": "Irrigation.", "order": 16908, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 4, "scene": 24, "line": "Nice.", "order": 16909, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 4, "scene": 24, "line": "I'll put you down for Irrigation. Well then, do you have any special needs or diertary restrictions?", "order": 16910, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 4, "scene": 24, "line": "Yes. We will be requiring a bed time story.", "order": 16911, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 4, "scene": 24, "line": "No.", "order": 16912, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 4, "scene": 24, "line": "Not even Harry Potter?", "order": 16913, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 4, "scene": 24, "line": "No. Jim, come on.", "order": 16914, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 4, "scene": 24, "line": "But you promised.", "order": 16915, "speaker": "Mose Schrute"},
  {"season": 4, "episode": 4, "scene": 24, "line": "Mose, bags! Now!", "order": 16916, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 4, "scene": 25, "line": "Here we are, the Irrigation Room! A very special room. So I'll come get you before the table-making demonstration. And as of this morning, we are completely wireless here in Schrute Farms. But as soon as I find out where Mose hid all the wires, we'll get that power back on. All righty.", "order": 16917, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 4, "scene": 26, "line": "Everybody in the conference room. In 5 minutes.", "order": 16918, "speaker": "Nick"},
  {"season": 4, "episode": 4, "scene": 26, "line": "These meetings are useless.", "order": 16919, "speaker": "Michael Scott"},
  {"season": 4, "episode": 4, "scene": 27, "line": "I just want to remind you to stick to the script. Improving the work. So, make the call, say the lines, make the sale. Got it?", "order": 16920, "speaker": "Nick"},
  {"season": 4, "episode": 4, "scene": 27, "line": "Very inspirational. [laughter]", "order": 16921, "speaker": "Michael Scott"},
  {"season": 4, "episode": 4, "scene": 27, "line": "We're offering a $50 bonus tonight to the guy with the most sales. Ok.", "order": 16922, "speaker": "Nick"},
  {"season": 4, "episode": 4, "scene": 27, "line": "Or a woman.", "order": 16923, "speaker": "Co-Worker 1"},
  {"season": 4, "episode": 4, "scene": 27, "line": "Or a trained seal. [laughter]", "order": 16924, "speaker": "Michael Scott"},
  {"season": 4, "episode": 4, "scene": 27, "line": "You could make jokes when you've made a sale there rookie, ok? [laughter ends]", "order": 16925, "speaker": "Nick"},
  {"season": 4, "episode": 4, "scene": 28, "line": "Hmmm, I'd say 1 in 6.", "order": 16926, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 4, "scene": 28, "line": "What?", "order": 16927, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 4, "scene": 28, "line": "Oh, I thought you asked me what our chances were in being murdered here tonight.", "order": 16928, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 4, "scene": 29, "line": "You know, I've just realized, this is Pam's and my first night away together. I use to play it over my head, and it was just a little bit different. Maybe a uh, nice hotel. Or a romantic dinner. Wine... uh but, wine that wasn't made out of beets. Didn't think Dwight would be involved at all. And uh, I always imagined less manure. I mean, some manure, just... less.", "order": 16929, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 4, "scene": 29, "line": "Mose, what are you doing? No Mose! Put the--- Put the manure down! Put it down! Do not throw it! DO NOT THROW IT! Ow!", "order": 16930, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 4, "scene": 30, "line": "Yes, is Mr. Hudson there?", "order": 16931, "speaker": "Michael Scott"},
  {"season": 4, "episode": 4, "scene": 30, "line": "[on the phone] Yes, who is this?", "order": 16932, "speaker": "Stanely"},
  {"season": 4, "episode": 4, "scene": 30, "line": "I'm just calling because you responded positively to the---", "order": 16933, "speaker": "Michael Scott"},
  {"season": 4, "episode": 4, "scene": 30, "line": "Michael?", "order": 16934, "speaker": "Stanely"},
  {"season": 4, "episode": 4, "scene": 30, "line": "...Stanley?", "order": 16935, "speaker": "Michael Scott"},
  {"season": 4, "episode": 4, "scene": 30, "line": "Why are you calling me here at home?", "order": 16936, "speaker": "Stanley Hudson"},
  {"season": 4, "episode": 4, "scene": 30, "line": "[Spanish accent] Senor, are you happy with your---", "order": 16937, "speaker": "Michael Scott"},
  {"season": 4, "episode": 4, "scene": 30, "line": "Michael, I know that's you. Why are you calling me here at home?", "order": 16938, "speaker": "Stanley Hudson"},
  {"season": 4, "episode": 4, "scene": 30, "line": "[speaking with a different voice] Have you--- Have you considered satellite television?", "order": 16939, "speaker": "Michael Scott"},
  {"season": 4, "episode": 4, "scene": 30, "line": "Michael, I know that's you. I know your voice. Why are you calling me here at home? [Michael hands up]", "order": 16940, "speaker": "Stanley Hudson"},
  {"season": 4, "episode": 4, "scene": 31, "line": "[on the phone] When I'm at home at night in my own house in my sweats drinking some red wine watching my mystery stories, the last thing in the whole God forsaken world I want to hear is the voice of Michael Scott.", "order": 16941, "speaker": "Stanley Hudson"},
  {"season": 4, "episode": 4, "scene": 32, "line": "Well your son sounds like he's really motivated. I think it's crazy the coach won't play him frankly.", "order": 16942, "speaker": "Michael Scott"},
  {"season": 4, "episode": 4, "scene": 32, "line": "[hangs up phone] My office.", "order": 16943, "speaker": "Nick"},
  {"season": 4, "episode": 4, "scene": 32, "line": "You bet.", "order": 16944, "speaker": "Michael Scott"},
  {"season": 4, "episode": 4, "scene": 33, "line": "Just dial the number on the sheet and stick to the script. Say those words exactly, got it? I don't know why we have to keep on having this conversation.", "order": 16945, "speaker": "Nick"},
  {"season": 4, "episode": 4, "scene": 33, "line": "Look, I know sales, and I had that sale, I just needed a few more minutes---", "order": 16946, "speaker": "Michael Scott"},
  {"season": 4, "episode": 4, "scene": 33, "line": "A few more minutes is a waste of our time.", "order": 16947, "speaker": "Nick"},
  {"season": 4, "episode": 4, "scene": 33, "line": "It is not a waste of our time.", "order": 16948, "speaker": "Michael Scott"},
  {"season": 4, "episode": 4, "scene": 33, "line": "This is a trading game.", "order": 16949, "speaker": "Nick"},
  {"season": 4, "episode": 4, "scene": 33, "line": "No.", "order": 16950, "speaker": "Micael"},
  {"season": 4, "episode": 4, "scene": 33, "line": "You give a quick pitch. You make the sale. You move on. That's how Vikram does it.", "order": 16951, "speaker": "Nick"},
  {"season": 4, "episode": 4, "scene": 33, "line": "Vikram doesn't have my people skills.", "order": 16952, "speaker": "Michael Scott"},
  {"season": 4, "episode": 4, "scene": 33, "line": "Good for Vikram, because he out-sells you every night.", "order": 16953, "speaker": "Nick"},
  {"season": 4, "episode": 4, "scene": 33, "line": "Well, I hope this conversation has helped.", "order": 16954, "speaker": "Michael Scott"},
  {"season": 4, "episode": 4, "scene": 34, "line": "[reading to Jim, Pam, and Mose] And Harry saw the white hand raise its wand, and felt Voldemort's surge of vicious anger. Saw the frail old man on the floor write in agony. 'Harry?' It was over quickly as it had come. Harry stood shaking in the darkness, clutching the gate in the garden, his heart racing.", "order": 16955, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 4, "scene": 35, "line": "What did you get tonight?", "order": 16956, "speaker": "Michael Scott"},
  {"season": 4, "episode": 4, "scene": 35, "line": "Oh it looks like mixed masala, eggplant, and rice.", "order": 16957, "speaker": "Vikram"},
  {"season": 4, "episode": 4, "scene": 35, "line": "Oh, that looks good.", "order": 16958, "speaker": "Michael Scott"},
  {"season": 4, "episode": 4, "scene": 35, "line": "Oh, and what about you? Peanut butter flavor again?", "order": 16959, "speaker": "Vikram"},
  {"season": 4, "episode": 4, "scene": 35, "line": "I am going with the vanilla crisp this evening.", "order": 16960, "speaker": "Michael Scott"},
  {"season": 4, "episode": 4, "scene": 35, "line": "Enjoy.", "order": 16961, "speaker": "Vikram"},
  {"season": 4, "episode": 4, "scene": 35, "line": "Thank you.", "order": 16962, "speaker": "Michael Scott"},
  {"season": 4, "episode": 4, "scene": 36, "line": "I was a surgeon back home.", "order": 16963, "speaker": "Vikram"},
  {"season": 4, "episode": 4, "scene": 36, "line": "Really?", "order": 16964, "speaker": "Michael Scott"},
  {"season": 4, "episode": 4, "scene": 36, "line": "Oh yeah.", "order": 16965, "speaker": "Vikram"},
  {"season": 4, "episode": 4, "scene": 36, "line": "Wonder what I would've been back home?", "order": 16966, "speaker": "Michael Scott"},
  {"season": 4, "episode": 4, "scene": 36, "line": "Well this is your home.", "order": 16967, "speaker": "Vikram"},
  {"season": 4, "episode": 4, "scene": 36, "line": "I know, but it's competitive here. What's a dollar worth in your land? Medical school must cost like 40 bucks or a donkey or something.", "order": 16968, "speaker": "Michael Scott"},
  {"season": 4, "episode": 4, "scene": 36, "line": "Uhh, no.", "order": 16969, "speaker": "Vikram"},
  {"season": 4, "episode": 4, "scene": 36, "line": "I would've been chief of surgery... Or a cowboy.", "order": 16970, "speaker": "Michael Scott"},
  {"season": 4, "episode": 4, "scene": 37, "line": "[Jim and Pam hear noise] Wait, you're going up there?", "order": 16971, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 4, "scene": 37, "line": "Yeah. Coward.", "order": 16972, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 4, "scene": 38, "line": "[Pam sees Mose in an outhouse] Oh my God. What century is this?", "order": 16973, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 4, "scene": 39, "line": "You know what, here's the thing about Die Hard 4. Die Hard one, the original, John McClane was just this normal guy. You know, he's just a normal New York City cop, who gets his feet cut, and gets beat up. But he's an everyday guy. In Die Hard 4, he is jumping a motorcycle into a helicopter. In air. You know? He's invincible. It just sort of lost what Die Hard was. It's not Terminator.", "order": 16974, "speaker": "Michael Scott"},
  {"season": 4, "episode": 4, "scene": 39, "line": "Dude, you should review movies. [other co-workers agree]", "order": 16975, "speaker": "Co-Worker 2"},
  {"season": 4, "episode": 4, "scene": 39, "line": "I actually wrote a movie.", "order": 16976, "speaker": "Michael Scott"},
  {"season": 4, "episode": 4, "scene": 39, "line": "Really?", "order": 16977, "speaker": "Co-Worker 3"},
  {"season": 4, "episode": 4, "scene": 39, "line": "I'm writing one, yeah.", "order": 16978, "speaker": "Michael Scott"},
  {"season": 4, "episode": 4, "scene": 39, "line": "What's it about?", "order": 16979, "speaker": "Co-Worker 3"},
  {"season": 4, "episode": 4, "scene": 39, "line": "Um, sort of a spy, thriller...", "order": 16980, "speaker": "Michael Scott"},
  {"season": 4, "episode": 4, "scene": 39, "line": "What's so captivating? [everyone stops talking, go back to work] I like captivating things. And this must be really captivating because it's keeping you off the phones. I mean time is your money, that's how I know how captivating it is. Because how much time you spend talking.", "order": 16981, "speaker": "Nick"},
  {"season": 4, "episode": 4, "scene": 40, "line": "[Pam and Jim hear Dwight crying] Ugh, your turn.", "order": 16982, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 4, "scene": 40, "line": "[Jim knocks on Dwight's door, crying stops] Come in. Did you have another nightmare?", "order": 16983, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 4, "scene": 40, "line": "Hey Dwight.", "order": 16984, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 4, "scene": 40, "line": "Oh, Jim. I thought you were Mose.", "order": 16985, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 4, "scene": 40, "line": "Does Mose have nightmares?", "order": 16986, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 4, "scene": 40, "line": "Oh yes. Ever since the storm.", "order": 16987, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 4, "scene": 40, "line": "Is everything satisfactory with your stay?", "order": 16988, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 4, "scene": 40, "line": "Yeah, yeah.", "order": 16989, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 4, "scene": 40, "line": "Great.", "order": 16990, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 4, "scene": 40, "line": "Just thought that I heard crying, moaning, or something in here.", "order": 16991, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 4, "scene": 40, "line": "Oh. Well I'll look into that in the morning. Thank you for bringing that to the attention of the staff.", "order": 16992, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 4, "scene": 40, "line": "Good night, Dwight. [Jim leaves, Dwight continues crying]", "order": 16993, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 4, "scene": 41, "line": "Yeah, so we're all gonna go out for a beer. Do you wanna come?", "order": 16994, "speaker": "Co-Worker 2"},
  {"season": 4, "episode": 4, "scene": 41, "line": "We'd love for you to come, Michael.", "order": 16995, "speaker": "Co-Worker 3"},
  {"season": 4, "episode": 4, "scene": 41, "line": "Thanks, no, I have work tomorrow morning.", "order": 16996, "speaker": "Michael Scott"},
  {"season": 4, "episode": 4, "scene": 41, "line": "All right, next time dude.", "order": 16997, "speaker": "Co-Worker 2"},
  {"season": 4, "episode": 4, "scene": 41, "line": "Okay, see you guys. [Sees Jan] Hey, how you doin'?", "order": 16998, "speaker": "Michael Scott"},
  {"season": 4, "episode": 4, "scene": 41, "line": "You drive, I had too much wine.", "order": 16999, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 4, "scene": 41, "line": "Okay. How's yoga?", "order": 17000, "speaker": "Michael Scott"},
  {"season": 4, "episode": 4, "scene": 41, "line": "I didn't go.", "order": 17001, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 4, "scene": 41, "line": "Wh-Why not?", "order": 17002, "speaker": "Michael Scott"},
  {"season": 4, "episode": 4, "scene": 41, "line": "I just didn't!", "order": 17003, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 4, "scene": 41, "line": "Okay.", "order": 17004, "speaker": "Michael Scott"},
  {"season": 4, "episode": 4, "scene": 41, "line": "How was improv?", "order": 17005, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 4, "scene": 41, "line": "Good night Vikram.", "order": 17006, "speaker": "Michael Scott"},
  {"season": 4, "episode": 4, "scene": 41, "line": "Good night.", "order": 17007, "speaker": "Vikram"},
  {"season": 4, "episode": 4, "scene": 41, "line": "Hey, congrats on the bonus.", "order": 17008, "speaker": "Michael Scott"},
  {"season": 4, "episode": 4, "scene": 41, "line": "Thank you Michael.", "order": 17009, "speaker": "Vikram"},
  {"season": 4, "episode": 4, "scene": 41, "line": "I'm gonna have it one of these nights.", "order": 17010, "speaker": "Michael Scott"},
  {"season": 4, "episode": 4, "scene": 41, "line": "Well if you concentrate and make your calls faster, yeah.", "order": 17011, "speaker": "Vikram"},
  {"season": 4, "episode": 4, "scene": 41, "line": "Good night.", "order": 17012, "speaker": "Michael Scott"},
  {"season": 4, "episode": 4, "scene": 41, "line": "Good night.", "order": 17013, "speaker": "Vikram"},
  {"season": 4, "episode": 4, "scene": 42, "line": "Michael. Morning. Hey Dwight, how are you?", "order": 17014, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 4, "scene": 42, "line": "Pam.", "order": 17015, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 4, "scene": 42, "line": "You okay?", "order": 17016, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 4, "scene": 42, "line": "I am better than you have ever been or ever will be. [Ryan walks in]", "order": 17017, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 4, "scene": 42, "line": "Hey guys! What's happening? How's my favorite branch doin'?", "order": 17018, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 4, "scene": 43, "line": "Okay, Michael, why dont you start us off?", "order": 17019, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 4, "scene": 43, "line": "Um... that wasn't much of an introduction.", "order": 17020, "speaker": "Michael Scott"},
  {"season": 4, "episode": 4, "scene": 43, "line": "Ladies and gentlemen, your boss, Michael Scott.", "order": 17021, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 4, "scene": 43, "line": "Ahh, still lame. Okay. All right. Thank you, Ryan, for that wonderful introduction. Okay, um, today we're gonna be talking... about...PowerPoint! PowerPoint! PowerPoint! PowerPoint!", "order": 17022, "speaker": "Michael Scott"},
  {"season": 4, "episode": 4, "scene": 44, "line": "Yes I forgot about Ryan's presentation. And yes, it would have been nice to do well with the first presentation he had given me. But you know what else would have been nice? Winning the lottery.", "order": 17023, "speaker": "Michael Scott"},
  {"season": 4, "episode": 4, "scene": 45, "line": "And the best way to start is to hit start. And up comes the toolbar, that's what she said. What we have to do here is go to Run, and then you look up to PowerPoint. And we are in. We are going to register. You hit register--- Updates are ready. I should update. Um, estimated time 12 minutes, so this should take 5 or 10 minutes.", "order": 17024, "speaker": "Michael Scott"},
  {"season": 4, "episode": 4, "scene": 45, "line": "Is this the first time you ever opened PowerPoint?", "order": 17025, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 4, "scene": 45, "line": "Why?", "order": 17026, "speaker": "Michael Scott"},
  {"season": 4, "episode": 4, "scene": 45, "line": "You didn't prepare a presentation at all, did you?", "order": 17027, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 4, "scene": 45, "line": "No, I had a really rough night, and my boss can back you up on that.", "order": 17028, "speaker": "Michael Scott"},
  {"season": 4, "episode": 4, "scene": 45, "line": "I'm your boss.", "order": 17029, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 4, "scene": 45, "line": "My other boss, Mr. Figaro.", "order": 17030, "speaker": "Michael Scott"},
  {"season": 4, "episode": 4, "scene": 45, "line": "You have another job?", "order": 17031, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 4, "scene": 45, "line": "What I do between 5:30 pm and 1 am is no one's business but mine and my other business'.", "order": 17032, "speaker": "Michael Scott"},
  {"season": 4, "episode": 4, "scene": 45, "line": "Are you a cocktail waitress?", "order": 17033, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 4, "scene": 45, "line": "You can not have another job if it affects your work here.", "order": 17034, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 4, "scene": 45, "line": "It won't.", "order": 17035, "speaker": "Michael Scott"},
  {"season": 4, "episode": 4, "scene": 45, "line": "It did, all ready.", "order": 17036, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 4, "scene": 45, "line": "Okay, honestly, it was unlikely I was gonna figure this out anyways. [Kelly laughs]", "order": 17037, "speaker": "Michael Scott"},
  {"season": 4, "episode": 4, "scene": 45, "line": "You're so funny.", "order": 17038, "speaker": "Kelly Kapoor"},
  {"season": 4, "episode": 4, "scene": 45, "line": "Why is Darryl here? He works in the warehouse.", "order": 17039, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 4, "scene": 45, "line": "I invited him.", "order": 17040, "speaker": "Kelly Kapoor"},
  {"season": 4, "episode": 4, "scene": 45, "line": "It's not a party. Darryl, back downstairs, this isn't information you need.", "order": 17041, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 4, "scene": 45, "line": "There's information here? Yeah, you're right, I don't need this.", "order": 17042, "speaker": "Darryl Philbin"},
  {"season": 4, "episode": 4, "scene": 45, "line": "Okay. [makes out with Darryl]", "order": 17043, "speaker": "Kelly Kapoor"},
  {"season": 4, "episode": 4, "scene": 45, "line": "Hey, get off.", "order": 17044, "speaker": "Darryl Philbin"},
  {"season": 4, "episode": 4, "scene": 45, "line": "Umm, see you later tonight.", "order": 17045, "speaker": "Kelly Kapoor"},
  {"season": 4, "episode": 4, "scene": 45, "line": "I have plans later.", "order": 17046, "speaker": "Darryl Philbin"},
  {"season": 4, "episode": 4, "scene": 45, "line": "Okay, bye honey.", "order": 17047, "speaker": "Kelly Kapoor"},
  {"season": 4, "episode": 4, "scene": 45, "line": "How long until you actually get this presentation ready?", "order": 17048, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 4, "scene": 45, "line": "Why don't you do the presentation, because you know how to do it?", "order": 17049, "speaker": "Michael Scott"},
  {"season": 4, "episode": 4, "scene": 45, "line": "You know what I really want? Honestly Michael, is for you to know it, so you can communicate to the people here, to your clients, to whomever.", "order": 17050, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 4, "scene": 45, "line": "Huh, okay.", "order": 17051, "speaker": "Michael Scott"},
  {"season": 4, "episode": 4, "scene": 45, "line": "What?", "order": 17052, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 4, "scene": 45, "line": "It's whoever, not whomever.", "order": 17053, "speaker": "Michael Scott"},
  {"season": 4, "episode": 4, "scene": 45, "line": "No, it's whomever.", "order": 17054, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 4, "scene": 45, "line": "No, whomever is never actually right.", "order": 17055, "speaker": "Michael Scott"},
  {"season": 4, "episode": 4, "scene": 45, "line": "No, sometimes its right.", "order": 17056, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 4, "scene": 45, "line": "Michael is right. It's a made-up word used to trick students.", "order": 17057, "speaker": "Creed Bratton"},
  {"season": 4, "episode": 4, "scene": 45, "line": "No. Actually, whomever is the formal version of the word.", "order": 17058, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 4, "scene": 45, "line": "Obviously it's a real word, but I don't know when to use it correctly.", "order": 17059, "speaker": "Oscar Martinez"},
  {"season": 4, "episode": 4, "scene": 45, "line": "Not a native speaker.", "order": 17060, "speaker": "Michael Scott"},
  {"season": 4, "episode": 4, "scene": 45, "line": "I know what's right, but I'm not gonna say, because you're all jerks who didn't come see my band last night.", "order": 17061, "speaker": "Kevin Malone"},
  {"season": 4, "episode": 4, "scene": 45, "line": "Do you really know which one is correct?", "order": 17062, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 4, "scene": 45, "line": "I don't know.", "order": 17063, "speaker": "Kevin Malone"},
  {"season": 4, "episode": 4, "scene": 45, "line": "It's 'whom' when it's the object of a sentence, and 'who' when it's the subject.", "order": 17064, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 4, "scene": 45, "line": "Yeah, that sounds right.", "order": 17065, "speaker": "Phyllis Vance"},
  {"season": 4, "episode": 4, "scene": 45, "line": "Well it sounds right, but is it?", "order": 17066, "speaker": "Michael Scott"},
  {"season": 4, "episode": 4, "scene": 45, "line": "How did Ryan use it? As an object?", "order": 17067, "speaker": "Stanley Hudson"},
  {"season": 4, "episode": 4, "scene": 45, "line": "As an object.", "order": 17068, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 4, "scene": 45, "line": "Ryan used me an object.", "order": 17069, "speaker": "Kelly Kapoor"},
  {"season": 4, "episode": 4, "scene": 45, "line": "Is he right about that?", "order": 17070, "speaker": "Stanley Hudson"},
  {"season": 4, "episode": 4, "scene": 45, "line": "How did he use it again?", "order": 17071, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 4, "scene": 45, "line": "It was Ryan wanted Michael, the subject, to explain the computer system, the object.", "order": 17072, "speaker": "Toby Flenderson"},
  {"season": 4, "episode": 4, "scene": 45, "line": "Thank you.", "order": 17073, "speaker": "Michael Scott"},
  {"season": 4, "episode": 4, "scene": 45, "line": "To whomever, meaning us, the indirect object. Which is the correct usage of the word.", "order": 17074, "speaker": "Toby Flenderson"},
  {"season": 4, "episode": 4, "scene": 45, "line": "No one, uh, asked you anything ever, so whomever's name is Toby, why don't you take a letter opener and stick it into your skull.", "order": 17075, "speaker": "Michael Scott"},
  {"season": 4, "episode": 4, "scene": 45, "line": "Wait! This doesn't matter. And I don't even care. Michael, you quit the other job, or you're fired here.", "order": 17076, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 4, "scene": 46, "line": "I've never done this before. I've never quit anything in my life. So, you are filming history.", "order": 17077, "speaker": "Michael Scott"},
  {"season": 4, "episode": 4, "scene": 47, "line": "Nick, I had a dream last night that I had two full time jobs. One here at the Lipophedrazone diet pill company---", "order": 17078, "speaker": "Michael Scott"},
  {"season": 4, "episode": 4, "scene": 47, "line": "Lipophedrine", "order": 17079, "speaker": "Mr. Figaro"},
  {"season": 4, "episode": 4, "scene": 47, "line": "And the other I was a Regional Manager of a small paper supply company called Dunder Mifflin.", "order": 17080, "speaker": "Michael Scott"},
  {"season": 4, "episode": 4, "scene": 47, "line": "Never heard of it.", "order": 17081, "speaker": "Mr. Figaro"},
  {"season": 4, "episode": 4, "scene": 47, "line": "In this dream I did both of these jobs beautifully, and I loved it, and everybody loved me. But the truth is, I can't do this.", "order": 17082, "speaker": "Michael Scott"},
  {"season": 4, "episode": 4, "scene": 47, "line": "Are you quitting?", "order": 17083, "speaker": "Mr. Figaro"},
  {"season": 4, "episode": 4, "scene": 47, "line": "I am.", "order": 17084, "speaker": "Michael Scott"},
  {"season": 4, "episode": 4, "scene": 47, "line": "Come back anytime, don't forget to disinfect your headset.", "order": 17085, "speaker": "Mr. Figaro"},
  {"season": 4, "episode": 4, "scene": 48, "line": "I was never in this for the money. But it turns out that the money was an absolute necessity for me. I tried to live the dream. I tried to have a job, a girlfriend, another job, and I failed. But the good thing about the American dream is that you can just go to sleep, and try it all again the next night.", "order": 17086, "speaker": "Michael Scott"},
  {"season": 4, "episode": 4, "scene": 49, "line": "So.", "order": 17087, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 4, "scene": 49, "line": "What's up?", "order": 17088, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 4, "scene": 49, "line": "Me. All night. Dreaming about Angela's smoking hot body.", "order": 17089, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 4, "scene": 49, "line": "You're being gross.", "order": 17090, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 4, "scene": 49, "line": "Not from a male perspective. You need to set me up with her, I know she told you that she's looking, and she's totally not responding to my moves.", "order": 17091, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 4, "scene": 49, "line": "What moves?", "order": 17092, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 4, "scene": 49, "line": "I have moon-walked past accounting like ten times.", "order": 17093, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 4, "scene": 49, "line": "I can't believe that's not working.", "order": 17094, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 4, "scene": 49, "line": "Yeah.", "order": 17095, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 4, "scene": 49, "line": "Um, I don't know if I really see you two together.", "order": 17096, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 4, "scene": 49, "line": "Really? Well, maybe you should look in the smart part of your brain.", "order": 17097, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 4, "scene": 49, "line": "She's very religious.", "order": 17098, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 4, "scene": 49, "line": "Okay, well I come from a line Wasps so long it leads back to Moses.", "order": 17099, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 4, "scene": 49, "line": "Okay, well she takes her convictions pretty seriously, she can be kind of severe.", "order": 17100, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 4, "scene": 49, "line": "Yeah, and I punched a hole in a wall.", "order": 17101, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 4, "scene": 49, "line": "That's right, you did.", "order": 17102, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 4, "scene": 49, "line": "Yeah.", "order": 17103, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 4, "scene": 50, "line": "Now that I think about it, Angela and Andy might actually make a good couple. But I couldn't do that to Dwight... or Angela... or Andy.", "order": 17104, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 4, "scene": 51, "line": "Hey Kevin, you're a gambler right? A rounder, you play the ponies, small horses.", "order": 17105, "speaker": "Michael Scott"},
  {"season": 4, "episode": 4, "scene": 51, "line": "I do gamble Michael.", "order": 17106, "speaker": "Kevin Malone"},
  {"season": 4, "episode": 4, "scene": 51, "line": "Yeah, I was thinking about doing some gambling myself. You know, just a little bit of money. Maybe doubling it, and them doubling it seven more times. I don't know, kind of just for fun. I was thinking, do you have tips, or ideas about sure things. Like a boxer who is going to throw the big fight, you know, like, like he's tied into some crooked dealings, maybe his kid is sick or something. Like, who do I call about that?", "order": 17107, "speaker": "Michael Scott"},
  {"season": 4, "episode": 4, "scene": 51, "line": "The mob.", "order": 17108, "speaker": "Kevin Malone"},
  {"season": 4, "episode": 4, "scene": 51, "line": "Do you know anybody in the mob?", "order": 17109, "speaker": "Michael Scott"},
  {"season": 4, "episode": 4, "scene": 51, "line": "[shakes head no]", "order": 17110, "speaker": "Kevin Malone"},
  {"season": 4, "episode": 4, "scene": 51, "line": "Okay, um, Oscar, I'm going to need to take another advance on my salary.", "order": 17111, "speaker": "Michael Scott"},
  {"season": 4, "episode": 4, "scene": 52, "line": "What do you mean you have plans tonight?", "order": 17112, "speaker": "Kelly Kapoor"},
  {"season": 4, "episode": 4, "scene": 52, "line": "I have my daughter tonight; we're renting Charlotte's Web.", "order": 17113, "speaker": "Darryl Philbin"},
  {"season": 4, "episode": 4, "scene": 52, "line": "Well, you have to make a choice, it's either your daughter, or me.", "order": 17114, "speaker": "Kelly Kapoor"},
  {"season": 4, "episode": 4, "scene": 52, "line": "My daughter.", "order": 17115, "speaker": "Darryl Philbin"},
  {"season": 4, "episode": 4, "scene": 52, "line": "Okay, I see how it is. [pushes a stack of files onto the floor] Oops.", "order": 17116, "speaker": "Kelly Kapoor"},
  {"season": 4, "episode": 4, "scene": 52, "line": "That was cold.", "order": 17117, "speaker": "Darryl Philbin"},
  {"season": 4, "episode": 4, "scene": 52, "line": "[makes a W then an L with her fingers, and then runs a finger across her throat]", "order": 17118, "speaker": "Kelly Kapoor"},
  {"season": 4, "episode": 4, "scene": 53, "line": "He's always been terrible with money.", "order": 17119, "speaker": "Phyllis Vance"},
  {"season": 4, "episode": 4, "scene": 53, "line": "I bet it's Jan spending him straight to the poor house.", "order": 17120, "speaker": "Stanley Hudson"},
  {"season": 4, "episode": 4, "scene": 53, "line": "Yeah, women be shoppin'.", "order": 17121, "speaker": "Kevin Malone"},
  {"season": 4, "episode": 4, "scene": 53, "line": "I can't believe he has a second job.", "order": 17122, "speaker": "Meredith Palmer"},
  {"season": 4, "episode": 4, "scene": 53, "line": "He's not even good at his first one.", "order": 17123, "speaker": "Oscar Martinez"},
  {"season": 4, "episode": 4, "scene": 53, "line": "Hey guys.", "order": 17124, "speaker": "Michael Scott"},
  {"season": 4, "episode": 4, "scene": 53, "line": "Shh.", "order": 17125, "speaker": "Kevin Malone"},
  {"season": 4, "episode": 4, "scene": 53, "line": "What'cha talking about? [camera pans to each face in the break room] Okay, I know what's going on. You're talking about Jim and Pam, if they're having sex, what it looks like, I know, I think---", "order": 17126, "speaker": "Michael Scott"},
  {"season": 4, "episode": 4, "scene": 53, "line": "Michael.", "order": 17127, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 4, "scene": 53, "line": "Hey, hey, hey.", "order": 17128, "speaker": "Michael Scott"},
  {"season": 4, "episode": 4, "scene": 53, "line": "Michael, are you having money problems?", "order": 17129, "speaker": "Oscar Martinez"},
  {"season": 4, "episode": 4, "scene": 53, "line": "Monkey problem? No, I'm not having monkey problems. Why would I have monkey problems?", "order": 17130, "speaker": "Michael Scott"},
  {"season": 4, "episode": 4, "scene": 53, "line": "You heard me correctly.", "order": 17131, "speaker": "Oscar Martinez"},
  {"season": 4, "episode": 4, "scene": 53, "line": "Oh, I hate monkeys.", "order": 17132, "speaker": "Michael Scott"},
  {"season": 4, "episode": 4, "scene": 53, "line": "What's going on, why do you have a second job?", "order": 17133, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 4, "scene": 53, "line": "I don't have a second job. Maybe I'm having an affair with Suzanne Summers.", "order": 17134, "speaker": "Michael Scott"},
  {"season": 4, "episode": 4, "scene": 53, "line": "Doesn't Jan have money?", "order": 17135, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 4, "scene": 53, "line": "I don't talk to my girlfriend about money. It is rude, and unsexual.", "order": 17136, "speaker": "Michael Scott"},
  {"season": 4, "episode": 4, "scene": 53, "line": "True, it's best to hide our money problems from women.", "order": 17137, "speaker": "Kevin Malone"},
  {"season": 4, "episode": 4, "scene": 53, "line": "I totally agree with you. But I don't have money problems, I don't. Alright, you know what? Watch this, if I had money problems, would I do this? [Michael holds up a bill, crumples it up, puts it back in his pocket]", "order": 17138, "speaker": "Michael Scott"},
  {"season": 4, "episode": 4, "scene": 53, "line": "You just put it back in your pocket.", "order": 17139, "speaker": "Oscar Martinez & Stanley Hudson"},
  {"season": 4, "episode": 4, "scene": 53, "line": "Yeah, but I destroyed it, it's not even useable anymore.", "order": 17140, "speaker": "Michael Scott"},
  {"season": 4, "episode": 4, "scene": 54, "line": "Hey, let's call this what it is.", "order": 17141, "speaker": "Darryl Philbin"},
  {"season": 4, "episode": 4, "scene": 55, "line": "It's like she only wants to hook up when Ryan comes around. It's gotten to the point where I get excited every time I see that little dude walk through the door.", "order": 17142, "speaker": "Darryl Philbin"},
  {"season": 4, "episode": 4, "scene": 56, "line": "Well, I just need to know where this is going.", "order": 17143, "speaker": "Kelly Kapoor"},
  {"season": 4, "episode": 4, "scene": 56, "line": "Hey, I like you. Oh yeah, what's not to like? But you need to access your uncrazy side, otherwise maybe this thing's run its course.", "order": 17144, "speaker": "Darryl Philbin"},
  {"season": 4, "episode": 4, "scene": 56, "line": "Don't you dare walk away from me Darryl Philben, you are the most selfish person I've ever met in my entire---", "order": 17145, "speaker": "Kelly Kapoor"},
  {"season": 4, "episode": 4, "scene": 56, "line": "Slow down, think it over.", "order": 17146, "speaker": "Darryl Philbin"},
  {"season": 4, "episode": 4, "scene": 57, "line": "Darryl Philben is the most complicated man that I've ever met. I mean, who says exactly what they're thinking? What kind of game is that?", "order": 17147, "speaker": "Kelly Kapoor"},
  {"season": 4, "episode": 4, "scene": 58, "line": "Hey cuz, heard you're having money problems.", "order": 17148, "speaker": "Creed Bratton"},
  {"season": 4, "episode": 4, "scene": 58, "line": "No you didn't.", "order": 17149, "speaker": "Michael Scott"},
  {"season": 4, "episode": 4, "scene": 58, "line": "Listen, I've got the answer. You declare bankruptcy, all your problems go away.", "order": 17150, "speaker": "Creed Bratton"},
  {"season": 4, "episode": 4, "scene": 59, "line": "Creed Bratton has never declared bankruptcy. When Creed Bratton gets in trouble, he transfers his debt to William Charles Schneider.", "order": 17151, "speaker": "Creed Bratton"},
  {"season": 4, "episode": 4, "scene": 60, "line": "How would that help Creed? In Monopoly when you go bankrupt, you lose.", "order": 17152, "speaker": "Michael Scott"},
  {"season": 4, "episode": 4, "scene": 60, "line": "You don't go by Monopoly man, that game is nuts. Nobody just picks up 'get out of jail free' cards, those things cost thousands.", "order": 17153, "speaker": "Creed Bratton"},
  {"season": 4, "episode": 4, "scene": 60, "line": "That is a good point.", "order": 17154, "speaker": "Michael Scott"},
  {"season": 4, "episode": 4, "scene": 60, "line": "Bankruptcy, Michael, is nature's do-over. It's a fresh start, it's a clean slate.", "order": 17155, "speaker": "Creed Bratton"},
  {"season": 4, "episode": 4, "scene": 60, "line": "Like the witness protection program.", "order": 17156, "speaker": "Michael Scott"},
  {"season": 4, "episode": 4, "scene": 60, "line": "Exactly.", "order": 17157, "speaker": "Creed Bratton"},
  {"season": 4, "episode": 4, "scene": 60, "line": "Not at all.", "order": 17158, "speaker": "Oscar Martinez"},
  {"season": 4, "episode": 4, "scene": 61, "line": "I've always wanted to be in the witness protection program. Fresh start, no debts, no baggage. I've already got my name picked out, Lord Rupert Everton. I'm a shipping merchant who raises fancy dogs. That's the life.", "order": 17159, "speaker": "Michael Scott"},
  {"season": 4, "episode": 4, "scene": 62, "line": "I... DECLARE... BANKRUPTCY!", "order": 17160, "speaker": "Michael Scott"},
  {"season": 4, "episode": 4, "scene": 63, "line": "Hey, I just wanted you to know that you can't just say the word bankruptcy and expect anything to happen.", "order": 17161, "speaker": "Oscar Martinez"},
  {"season": 4, "episode": 4, "scene": 63, "line": "I didn't say it, I declared it.", "order": 17162, "speaker": "Michael Scott"},
  {"season": 4, "episode": 4, "scene": 63, "line": "Still, that's not anything.", "order": 17163, "speaker": "Oscar Martinez"},
  {"season": 4, "episode": 4, "scene": 64, "line": "This is a lot of credit card debt.", "order": 17164, "speaker": "Oscar Martinez"},
  {"season": 4, "episode": 4, "scene": 64, "line": "Yeah, tell me about it.", "order": 17165, "speaker": "Michael Scott"},
  {"season": 4, "episode": 4, "scene": 64, "line": "Mmm.", "order": 17166, "speaker": "Oscar Martinez"},
  {"season": 4, "episode": 4, "scene": 64, "line": "You know, Jan has my credit cards, and she's using them as if I'm made of money, she thinks I'm a human ATM machine.", "order": 17167, "speaker": "Michael Scott"},
  {"season": 4, "episode": 4, "scene": 64, "line": "Okay, a hundred and twenty-five dollars, Amazon.", "order": 17168, "speaker": "Oscar Martinez"},
  {"season": 4, "episode": 4, "scene": 64, "line": "Oh, that's the Muppet Show, on DVD, classic.", "order": 17169, "speaker": "Michael Scott"},
  {"season": 4, "episode": 4, "scene": 64, "line": "Twelve hundred dollars. What's a Core Blaster Extreme?", "order": 17170, "speaker": "Oscar Martinez"},
  {"season": 4, "episode": 4, "scene": 64, "line": "That is by far the best way to strengthen your core. This machine, you sit on a stabilizer ball, you put your feet into the power stir-ups, you reach up and you grab onto the super rod, and you twist, and you twist, and you twist. It strengthens your entire core. Your back core, your arm core, the Marine Core actually uses it. I think that's how they got a core.", "order": 17171, "speaker": "Michael Scott"},
  {"season": 4, "episode": 4, "scene": 65, "line": "I left a little present for Angela. I think she's going to like it, because I found it outside of Vance Refrigeration all alone, and I told her in the note that the cat came to find her, that they were destined to be together. I got game.", "order": 17172, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 4, "scene": 66, "line": "Okay, the green bar is what you spend every month on stuff you need, like a car and a house.", "order": 17173, "speaker": "Oscar Martinez"},
  {"season": 4, "episode": 4, "scene": 66, "line": "Mm-hm. That is so cool how you have my name at the top.", "order": 17174, "speaker": "Michael Scott"},
  {"season": 4, "episode": 4, "scene": 66, "line": "The red bar is what you spend on non-essentials, like magazines, entertainment, things like that.", "order": 17175, "speaker": "Oscar Martinez"},
  {"season": 4, "episode": 4, "scene": 66, "line": "Right.", "order": 17176, "speaker": "Michael Scott"},
  {"season": 4, "episode": 4, "scene": 66, "line": "This scary black bar is what you spend on things that no one ever, ever needs, like multiple magic sets, professional bass fishing equipment.", "order": 17177, "speaker": "Oscar Martinez"},
  {"season": 4, "episode": 4, "scene": 66, "line": "How do they do this so fast? Is this power-point?", "order": 17178, "speaker": "Michael Scott"},
  {"season": 4, "episode": 4, "scene": 67, "line": "Man, Angela really had a hold on him. [Dwight playing the recorder in the background] Angela.", "order": 17179, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 4, "scene": 68, "line": "Michael, I'm going to set you and Jan up with a debt consolidator, you meet with this guy.", "order": 17180, "speaker": "Oscar Martinez"},
  {"season": 4, "episode": 4, "scene": 68, "line": "No, no, we are going to leave Jan out of this.", "order": 17181, "speaker": "Michael Scott"},
  {"season": 4, "episode": 4, "scene": 68, "line": "She has to know.", "order": 17182, "speaker": "Oscar Martinez"},
  {"season": 4, "episode": 4, "scene": 68, "line": "We will find another way, we'll ask power-point.", "order": 17183, "speaker": "Michael Scott"},
  {"season": 4, "episode": 4, "scene": 68, "line": "Michael, this is a presentation tool.", "order": 17184, "speaker": "Oscar Martinez"},
  {"season": 4, "episode": 4, "scene": 68, "line": "You're a presentation tool if you think I'm gonna tell Jan about this.", "order": 17185, "speaker": "Michael Scott"},
  {"season": 4, "episode": 4, "scene": 68, "line": "I'm done!", "order": 17186, "speaker": "Oscar Martinez"},
  {"season": 4, "episode": 4, "scene": 68, "line": "No you're not! Ok, just... you're not a tool. Look, we'll tell her that it's bad, but it could've been a lot worse but due to some fancy financial foot work I was able to cut it in half.", "order": 17187, "speaker": "Michael Scott"},
  {"season": 4, "episode": 4, "scene": 68, "line": "Jan is smart.", "order": 17188, "speaker": "Oscar Martinez"},
  {"season": 4, "episode": 4, "scene": 68, "line": "She poses.", "order": 17189, "speaker": "Michael Scott"},
  {"season": 4, "episode": 4, "scene": 69, "line": "Dwight, how's the hotel business?", "order": 17190, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 4, "scene": 69, "line": "Stupid.", "order": 17191, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 4, "scene": 69, "line": "Have you checked Trip Advisor recently?", "order": 17192, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 4, "scene": 69, "line": "No.", "order": 17193, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 4, "scene": 69, "line": "Maybe you should.", "order": 17194, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 4, "scene": 69, "line": "Maybe you should. Whatever.", "order": 17195, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 4, "scene": 70, "line": "We wrote a good review. Under comments, we wrote, the natural aroma of the beets drifts into the bedroom and makes you dream of simpler times.", "order": 17196, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 4, "scene": 70, "line": "The dawn goose walk will tug at your heartstrings.", "order": 17197, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 4, "scene": 70, "line": "Table making never seemed so possible.", "order": 17198, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 4, "scene": 70, "line": "You will never want to leave your room.", "order": 17199, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 4, "scene": 70, "line": "The architecture reminds one of a quaint Tuscan beet farm.", "order": 17200, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 4, "scene": 71, "line": "I'm glad you enjoyed your stay.", "order": 17201, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 4, "scene": 71, "line": "We really did. It was fun.", "order": 17202, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 4, "scene": 72, "line": "So due to Michael's clever financial maneuvering, he finds himself tremendously in debt.", "order": 17203, "speaker": "Oscar Martinez"},
  {"season": 4, "episode": 4, "scene": 72, "line": "[on phone] You're broke?", "order": 17204, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 4, "scene": 72, "line": "Um, that's, how did you get that from what Oscar's saying?", "order": 17205, "speaker": "Michael Scott"},
  {"season": 4, "episode": 4, "scene": 72, "line": "[on phone] Michael, how did this happen? Where did all your money go? I don't, I don't get this. I really don't. I don't know how you could be so irresponsible. I mean, this is, it is astounding to me, really. I don't know what more to say.", "order": 17206, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 4, "scene": 72, "line": "Jan.", "order": 17207, "speaker": "Oscar Martinez"},
  {"season": 4, "episode": 4, "scene": 72, "line": "[on phone] Yeah, what?", "order": 17208, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 4, "scene": 72, "line": "Michael left.", "order": 17209, "speaker": "Oscar Martinez"},
  {"season": 4, "episode": 4, "scene": 72, "line": "[on phone] Okay, where did he go?", "order": 17210, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 4, "scene": 72, "line": "I don't know.", "order": 17211, "speaker": "Oscar Martinez"},
  {"season": 4, "episode": 4, "scene": 72, "line": "[on phone] Well, is he coming right back?", "order": 17212, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 4, "scene": 72, "line": "I don't think so.", "order": 17213, "speaker": "Oscar Martinez"},
  {"season": 4, "episode": 4, "scene": 72, "line": "[on phone] I'll be right there.", "order": 17214, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 4, "scene": 73, "line": "What am I doing? I am blowing dodge. I'm getting out of town. Whatever you call it, I am running away from my responsibilities. And it feels good.", "order": 17215, "speaker": "Michael Scott"},
  {"season": 4, "episode": 4, "scene": 74, "line": "[to Andy] You may ask me out to dinner. Nothing fancy or foreign, no bars, no patios, no vegetables, and no seafood.", "order": 17216, "speaker": "Angela Martin"},
  {"season": 4, "episode": 4, "scene": 75, "line": "Dwight.", "order": 17217, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 4, "scene": 76, "line": "Uhh-mmm [moaning]", "order": 17218, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 4, "scene": 76, "line": "Did I ever tell you why I left Scranton?", "order": 17219, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 4, "scene": 76, "line": "[incoherent mumbling] No you didn't.", "order": 17220, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 4, "scene": 76, "line": "Yeah, I didn't think I had. Well, it was all about Pam.", "order": 17221, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 4, "scene": 76, "line": "Mmm-uh-mm [incoherent mumbling]", "order": 17222, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 4, "scene": 76, "line": "Yeah, I mean she was with Roy, and, uh, I just couldn't take it. I mean, I lost it Dwight. I couldn't sleep, I couldn't concentrate on anything. Even weird stuff, like food had no taste. So my solution was to move away. It was awful. It was something that I wouldn't wish on my worst enemy, and that includes you.", "order": 17223, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 4, "scene": 76, "line": "[sobbing, reaches out to Jim after he walked away]", "order": 17224, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 4, "scene": 77, "line": "Hey, I was thinking about dinner--- [Jim grabs her face and kisses her]", "order": 17225, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 4, "scene": 77, "line": "Ah, dinner. Let's see, maybe we should try the new Italian place, where the drive-in used to be.", "order": 17226, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 4, "scene": 77, "line": "Okay.", "order": 17227, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 4, "scene": 77, "line": "Yeah?", "order": 17228, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 4, "scene": 77, "line": "Yeah.", "order": 17229, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 4, "scene": 77, "line": "Okay.", "order": 17230, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 4, "scene": 78, "line": "Jim's just really passionate about Italian food.", "order": 17231, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 4, "scene": 79, "line": "Yep, I'm very passionate about Italian food. In fact, um, I'm in love with Italian food.", "order": 17232, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 4, "scene": 80, "line": "Jan, he went running that way.", "order": 17233, "speaker": "Oscar Martinez"},
  {"season": 4, "episode": 4, "scene": 80, "line": "Alright. [throws her keys at Oscar]", "order": 17234, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 4, "scene": 81, "line": "[singing] Runaway train, never come back. Runaway and I'm never coming back.", "order": 17235, "speaker": "Michael Scott"},
  {"season": 4, "episode": 4, "scene": 81, "line": "Michael.", "order": 17236, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 4, "scene": 81, "line": "Hey Jan.", "order": 17237, "speaker": "Michael Scott"},
  {"season": 4, "episode": 4, "scene": 81, "line": "What's going on?", "order": 17238, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 4, "scene": 81, "line": "Not much, what's up with you?", "order": 17239, "speaker": "Michael Scott"},
  {"season": 4, "episode": 4, "scene": 81, "line": "Well, why are you sitting on a train? Where are you going?", "order": 17240, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 4, "scene": 81, "line": "I'm out of answers Jan.", "order": 17241, "speaker": "Michael Scott"},
  {"season": 4, "episode": 4, "scene": 81, "line": "What does that mean?", "order": 17242, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 4, "scene": 81, "line": "I told you, no more answers. This is who I am now. A guy on a train with no answers. I hope that can be enough for you.", "order": 17243, "speaker": "Michael Scott"},
  {"season": 4, "episode": 4, "scene": 81, "line": "Michael, come on. Running away from your problems won't solve anything. You know that.", "order": 17244, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 4, "scene": 81, "line": "I don't know that.", "order": 17245, "speaker": "Michael Scott"},
  {"season": 4, "episode": 4, "scene": 81, "line": "Your creditors can follow you anywhere with ease. Your debt follows you around the world, electronically.", "order": 17246, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 4, "scene": 81, "line": "I'll stay off the grid.", "order": 17247, "speaker": "Michael Scott"},
  {"season": 4, "episode": 4, "scene": 81, "line": "Uh, Michael, come on, come on, you can deal with this. It's not that bad.", "order": 17248, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 4, "scene": 81, "line": "Yeah it is, it is. I really messed up.", "order": 17249, "speaker": "Michael Scott"},
  {"season": 4, "episode": 4, "scene": 81, "line": "Well, when my life fell apart and they, and they screwed me in New York, and I felt like my whole world was collapsing around me, I didn't have anyone. I mean, my whole family still won't even talk to me, on the advice of counsel, and my friends were just waiting for this to happen.", "order": 17250, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 4, "scene": 81, "line": "That's really nice of you to say.", "order": 17251, "speaker": "Michael Scott"},
  {"season": 4, "episode": 4, "scene": 81, "line": "Michael, no, what I want to say is you were there for me. By my side. Without even a thought. That's just who you are. I mean, no matter how badly I treat you, or what I'm going through, you just, you are there for me. And that is a guy worth staying beside. So, where's this train taking us?", "order": 17252, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 4, "scene": 81, "line": "I think the engineer left.", "order": 17253, "speaker": "Michael Scott"},
  {"season": 4, "episode": 4, "scene": 82, "line": "[takes a ruler and shoves it between his desk and Jim's, knocking Jim's files on the floor] Hello, this is Dwight Schrute calling from Dunder-Mifflin, and according to our records you appear to be low on office supplies. Okay, sure, yeah I can take care of that right now.", "order": 17254, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 4, "scene": 83, "line": "Don't sell your implants please.", "order": 17255, "speaker": "Michael Scott"},
  {"season": 4, "episode": 4, "scene": 83, "line": "I'm keeping them. I know you like them. They're kind of uncomfortable though.", "order": 17256, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 4, "scene": 83, "line": "That's nice though.", "order": 17257, "speaker": "Michael Scott"},
  {"season": 4, "episode": 4, "scene": 83, "line": "It's kind of painful and my nipples are over-sensitive now.", "order": 17258, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 4, "scene": 83, "line": "It looks cute though.", "order": 17259, "speaker": "Michael Scott"},
  {"season": 4, "episode": 4, "scene": 84, "line": "I used to get offers in the mail for credit cards all the time. They would say things like 'don't pay for six months' or 'you can transfer your account from another card'...", "order": 17260, "speaker": "Michael Scott"},
  {"season": 4, "episode": 4, "scene": 84, "line": "No, [mumbling] I don't know.", "order": 17261, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 4, "scene": 84, "line": "Do you think I'll get any new ones? I could... [pause, eventually looks up at Dwight]", "order": 17262, "speaker": "Michael Scott"},
  {"season": 4, "episode": 4, "scene": 84, "line": "What?", "order": 17263, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 4, "scene": 84, "line": "What do you mean, 'what'?", "order": 17264, "speaker": "Michael Scott"},
  {"season": 4, "episode": 4, "scene": 84, "line": "What... [mumbling]", "order": 17265, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 4, "scene": 84, "line": "Were you listening to what I was saying?", "order": 17266, "speaker": "Michael Scott"},
  {"season": 4, "episode": 4, "scene": 84, "line": "I was aware that you were speaking.", "order": 17267, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 4, "scene": 84, "line": "What is the matter with you?", "order": 17268, "speaker": "Michael Scott"},
  {"season": 4, "episode": 4, "scene": 84, "line": "What is the matter with... me?", "order": 17269, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 4, "scene": 84, "line": "Yeah.", "order": 17270, "speaker": "Michael Scott"},
  {"season": 4, "episode": 4, "scene": 84, "line": "I'm... discombobulated.", "order": 17271, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 4, "scene": 84, "line": "Yeah.", "order": 17272, "speaker": "Michael Scott"},
  {"season": 4, "episode": 4, "scene": 84, "line": "I need help.", "order": 17273, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 4, "scene": 84, "line": "All right, well, go find some. Get outa here, please. [Dwight sighs and leaves]", "order": 17274, "speaker": "Michael Scott"},
  {"season": 4, "episode": 4, "scene": 85, "line": "Okay, you need to focus, Michael. You need to stop spending money.", "order": 17275, "speaker": "Oscar Martinez"},
  {"season": 4, "episode": 4, "scene": 85, "line": "Yeah. What?", "order": 17276, "speaker": "Michael Scott"},
  {"season": 4, "episode": 4, "scene": 85, "line": "I hate to ask you this, but are there any retirement funds you can borrow from?", "order": 17277, "speaker": "Oscar Martinez"},
  {"season": 4, "episode": 4, "scene": 85, "line": "Um, my CDs.", "order": 17278, "speaker": "Michael Scott"},
  {"season": 4, "episode": 4, "scene": 85, "line": "You have CDs?", "order": 17279, "speaker": "Oscar Martinez"},
  {"season": 4, "episode": 4, "scene": 85, "line": "I do.", "order": 17280, "speaker": "Michael Scott"},
  {"season": 4, "episode": 4, "scene": 85, "line": "Okay, good, okay. What bank?", "order": 17281, "speaker": "Oscar Martinez"},
  {"season": 4, "episode": 4, "scene": 85, "line": "My CDs are in a portfolio, a rather large portfolio, um, called Case Logic", "order": 17282, "speaker": "Michael Scott"},
  {"season": 4, "episode": 4, "scene": 85, "line": "[whispers] Case Logic.", "order": 17283, "speaker": "Oscar Martinez"},
  {"season": 4, "episode": 4, "scene": 85, "line": "And, um the Case Logic portfolio is currently in the back seat of my car. There is another smaller Case Logic portfolio clipped to my visor [Oscar vigorously shakes his head] What?", "order": 17284, "speaker": "Michael Scott"},
  {"season": 4, "episode": 4, "scene": 85, "line": "I'm asking about Certificates of Deposit.", "order": 17285, "speaker": "Oscar Martinez"},
  {"season": 4, "episode": 4, "scene": 86, "line": "I've been putting money into CDs for years. I bought music that I didn't even like. No. [shakes head]", "order": 17286, "speaker": "Michael Scott"},
  {"season": 4, "episode": 4, "scene": 87, "line": "I just think that...", "order": 17287, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 4, "scene": 87, "line": "I can't.", "order": 17288, "speaker": "Michael Scott"},
  {"season": 4, "episode": 4, "scene": 87, "line": "Why?", "order": 17289, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 4, "scene": 87, "line": "I can't go back to that.", "order": 17290, "speaker": "Michael Scott"},
  {"season": 4, "episode": 4, "scene": 87, "line": "I think you can. I... what?", "order": 17291, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 4, "scene": 87, "line": "I don't know if I can do that. I can't see myself spending the next six years digging myself out of that kind of hole.", "order": 17292, "speaker": "Michael Scott"},
  {"season": 4, "episode": 4, "scene": 87, "line": "All right, well then maybe there's another way, you know? I mean, we could just... we'll think of something else.", "order": 17293, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 4, "scene": 87, "line": "We will? I can't. I don't have an idea in my head.", "order": 17294, "speaker": "Michael Scott"},
  {"season": 4, "episode": 4, "scene": 87, "line": "Well... well, we will.", "order": 17295, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 4, "scene": 87, "line": "I have...", "order": 17296, "speaker": "Michael Scott"},
  {"season": 4, "episode": 4, "scene": 87, "line": "We just will.", "order": 17297, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 4, "scene": 87, "line": "Okay. What? Um...", "order": 17298, "speaker": "Michael Scott"},
  {"season": 4, "episode": 4, "scene": 87, "line": "I have some ideas.", "order": 17299, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 4, "scene": 87, "line": "Tell me.", "order": 17300, "speaker": "Michael Scott"},
  {"season": 4, "episode": 4, "scene": 87, "line": "[laughs] I am not going to tell you yet.", "order": 17301, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 4, "scene": 87, "line": "Well, please? I won't tell anybody.", "order": 17302, "speaker": "Michael Scott"},
  {"season": 4, "episode": 4, "scene": 87, "line": "Oh, yes, you will.", "order": 17303, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 4, "scene": 87, "line": "Yeah, I will.", "order": 17304, "speaker": "Michael Scott"},
  {"season": 4, "episode": 4, "scene": 88, "line": "Gentlemen, a word. Look, you guys are my closest friends in this office.", "order": 17305, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 4, "scene": 88, "line": "Right back at ya.", "order": 17306, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 4, "scene": 88, "line": "And as such, I come to you...", "order": 17307, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 4, "scene": 88, "line": "State your business!", "order": 17308, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 4, "scene": 88, "line": "I am dying of lovesickness and horny-sickness.", "order": 17309, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 4, "scene": 88, "line": "That is impossible. Unless you mean gonorrhea.", "order": 17310, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 4, "scene": 88, "line": "I'm talking about Angela, okay? Did you hear what she was saying to Pam the other day?", "order": 17311, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 4, "scene": 88, "line": "Yes, I did, 'cept I don't think she means it. Angela is in a great deal of pain because of the death of her cat, and she's in a kind of a grieving process, and it makes her say things. So... best to just lay off.", "order": 17312, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 4, "scene": 88, "line": "No can do. I am itching all over with Angela-pox.", "order": 17313, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 4, "scene": 88, "line": "Oh my God, you do have gonorrhea.", "order": 17314, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 4, "scene": 88, "line": "Hey, Andy! Maybe this is one of those situations where you just have to do her to get her out of your system.", "order": 17315, "speaker": "Phyllis Vance"},
  {"season": 4, "episode": 4, "scene": 88, "line": "Stay out of this, you!", "order": 17316, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 4, "scene": 89, "line": "Hey, fellas! And... lady.", "order": 17317, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 4, "scene": 89, "line": "I still do not have your reimbursement check.", "order": 17318, "speaker": "Kevin Malone"},
  {"season": 4, "episode": 4, "scene": 89, "line": "That's not why I came over. I mean it's a week late, but... I just came over to say hi.", "order": 17319, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 4, "scene": 89, "line": "Hi.", "order": 17320, "speaker": "Oscar Martinez"},
  {"season": 4, "episode": 4, "scene": 89, "line": "[waves] Hi.", "order": 17321, "speaker": "Kevin Malone"},
  {"season": 4, "episode": 4, "scene": 89, "line": "Angela, you like lacrosse?", "order": 17322, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 4, "scene": 89, "line": "Lacrosse, the sport?", "order": 17323, "speaker": "Angela Martin"},
  {"season": 4, "episode": 4, "scene": 89, "line": "Scranton U. Varsity's gonna scrimmage the J.V. squad. Should be pretty interesting. J.V. gets really amped.", "order": 17324, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 4, "scene": 89, "line": "Well, I guess it's a big opportunity for them.", "order": 17325, "speaker": "Angela Martin"},
  {"season": 4, "episode": 4, "scene": 89, "line": "Yeah.", "order": 17326, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 4, "scene": 89, "line": "Yeah.", "order": 17327, "speaker": "Angela Martin"},
  {"season": 4, "episode": 4, "scene": 89, "line": "You wanna go?", "order": 17328, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 4, "scene": 89, "line": "No.", "order": 17329, "speaker": "Angela Martin"},
  {"season": 4, "episode": 4, "scene": 89, "line": "Because we could get some food...", "order": 17330, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 4, "scene": 89, "line": "No.", "order": 17331, "speaker": "Angela Martin"},
  {"season": 4, "episode": 4, "scene": 89, "line": "Afterwards at the...", "order": 17332, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 4, "scene": 89, "line": "Andy, no. [Andy nods and starts backing away, Kevin giggles]", "order": 17333, "speaker": "Angela Martin"},
  {"season": 4, "episode": 4, "scene": 89, "line": "Still waiting on that check.", "order": 17334, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 4, "scene": 90, "line": "Pam! You don't think he'll mind if we take the shampoo, do you? [holds up large bottle]", "order": 17335, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 4, "scene": 90, "line": "Mmm. Mmm-mmm. [shakes head 'no']", "order": 17336, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 4, "scene": 90, "line": "Okay.", "order": 17337, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 4, "scene": 91, "line": "[Mose serves bacon] Thank you, Mose.", "order": 17338, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 4, "scene": 91, "line": "[clears throat] Everybody poops.", "order": 17339, "speaker": "Mose Schrute"},
  {"season": 4, "episode": 4, "scene": 91, "line": "Yes, they do.", "order": 17340, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 4, "scene": 91, "line": "There's no other... way to get rid of the food.", "order": 17341, "speaker": "Mose Schrute"},
  {"season": 4, "episode": 4, "scene": 91, "line": "Where's Dwight?", "order": 17342, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 4, "scene": 91, "line": "Gone.", "order": 17343, "speaker": "Mose Schrute"},
  {"season": 4, "episode": 4, "scene": 91, "line": "Where'd he go?", "order": 17344, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 4, "scene": 91, "line": "His day place.", "order": 17345, "speaker": "Mose Schrute"},
  {"season": 4, "episode": 4, "scene": 91, "line": "The office.", "order": 17346, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 4, "scene": 91, "line": "What office?", "order": 17347, "speaker": "Mose Schrute"},
  {"season": 4, "episode": 4, "scene": 91, "line": "Hmm.", "order": 17348, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 4, "scene": 91, "line": "Mose, Angela hasn't been around here much lately, has she?", "order": 17349, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 4, "scene": 91, "line": "Angela?", "order": 17350, "speaker": "Mose Schrute"},
  {"season": 4, "episode": 4, "scene": 91, "line": "Angela - she used to stay here sometimes?", "order": 17351, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 4, "scene": 91, "line": "Angela [leaves].", "order": 17352, "speaker": "Mose Schrute"},
  {"season": 4, "episode": 4, "scene": 92, "line": "[on trampoline] Cannonball! Lemon bomb! Jim, Pam, watch! Jim, Pam, watch! Jim, Pam, watch!", "order": 17353, "speaker": "Mose Schrute"},
  {"season": 4, "episode": 4, "scene": 92, "line": "Okay, we're watching, Mose!", "order": 17354, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 4, "scene": 92, "line": "Okay, go Yankees! I'm a war hero!", "order": 17355, "speaker": "Mose Schrute"},
  {"season": 4, "episode": 4, "scene": 92, "line": "You're doin' great, buddy!", "order": 17356, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 4, "scene": 92, "line": "Name's Mose, buddy! Dwight, can I stop? They're not even looking.", "order": 17357, "speaker": "Mose Schrute"},
  {"season": 4, "episode": 4, "scene": 92, "line": "Yeah, go ahead and stop. You guys, you should really be looking, he's working his ass off over here.", "order": 17358, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 4, "scene": 92, "line": "I'm sorry, did we or did we not pay for a show?", "order": 17359, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 4, "scene": 92, "line": "Okay, go ahead, they're right.", "order": 17360, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 4, "scene": 92, "line": "Large spins!", "order": 17361, "speaker": "Mose Schrute"},
  {"season": 4, "episode": 4, "scene": 92, "line": "[not watching Mose] More spins.", "order": 17362, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 4, "scene": 92, "line": "Arr, dammit! [throws saw at table he was working on]", "order": 17363, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 4, "scene": 92, "line": "Helicopter!", "order": 17364, "speaker": "Mose Schrute"},
  {"season": 4, "episode": 5, "scene": 1, "line": "Yeah! Everything! Oh it's all good, it's all good. Phyllis!", "order": 17365, "speaker": "Michael Scott"},
  {"season": 4, "episode": 5, "scene": 1, "line": "Dancing babies!", "order": 17366, "speaker": "Phyllis Vance"},
  {"season": 4, "episode": 5, "scene": 1, "line": "Dancing babies! I love it! I love it!", "order": 17367, "speaker": "Michael Scott"},
  {"season": 4, "episode": 5, "scene": 2, "line": "We are making a commercial for Dunder Mifflin today. Our first ad ever. Corporate purchased some air time in local markets to spread the word about Dunder Mifflin Infinity and they are even sending up a professional ad company to help us make it later so... it's uh, not too shabby.", "order": 17368, "speaker": "Michael Scott"},
  {"season": 4, "episode": 5, "scene": 3, "line": "Best ad ever. [sings in the tune of the 'Kit Kat theme song'] Gimme a break, gimme a break, break me off a piece of that... I am totally blanking. What is the thing?", "order": 17369, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 5, "scene": 3, "line": "Nobody tell him!", "order": 17370, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 5, "scene": 3, "line": "What? No, why?", "order": 17371, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 5, "scene": 3, "line": "You got it, you're so close!", "order": 17372, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 5, "scene": 3, "line": "[singing] Break me off a piece of that... huh huh huh... br- applesauce.", "order": 17373, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 5, "scene": 3, "line": "Break me off a piece of that applesauce, I don't think...", "order": 17374, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 5, "scene": 3, "line": "Break me off a piece of that Chrysler car.", "order": 17375, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 5, "scene": 3, "line": "Nope.", "order": 17376, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 5, "scene": 3, "line": "Football cream. Grr!", "order": 17377, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 5, "scene": 3, "line": "Okay, it's football cream. It's football cream. Alright! So, anybody else?", "order": 17378, "speaker": "Michael Scott"},
  {"season": 4, "episode": 5, "scene": 3, "line": "I'm taking a computer animation class so I could try to do a logo.", "order": 17379, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 5, "scene": 3, "line": "Look at that. Even the receptionist is getting in on the creativity. Very good, very good.", "order": 17380, "speaker": "Michael Scott"},
  {"season": 4, "episode": 5, "scene": 4, "line": "Hello hello!", "order": 17381, "speaker": "Michael Scott"},
  {"season": 4, "episode": 5, "scene": 4, "line": "Hey, how ya doin'?", "order": 17382, "speaker": "Ad guy 1"},
  {"season": 4, "episode": 5, "scene": 4, "line": "Michael Scott.", "order": 17383, "speaker": "Michael Scott"},
  {"season": 4, "episode": 5, "scene": 4, "line": "Hey, Michael.", "order": 17384, "speaker": "Ad guy 2"},
  {"season": 4, "episode": 5, "scene": 4, "line": "Regional manager.", "order": 17385, "speaker": "Michael Scott"},
  {"season": 4, "episode": 5, "scene": 4, "line": "Hey Michael, nice to meet you", "order": 17386, "speaker": "Ad guy 2"},
  {"season": 4, "episode": 5, "scene": 4, "line": "Excited to talk ideas.", "order": 17387, "speaker": "Michael Scott"},
  {"season": 4, "episode": 5, "scene": 4, "line": "Let's do it, man.", "order": 17388, "speaker": "Ad guy 1"},
  {"season": 4, "episode": 5, "scene": 4, "line": "You know, I want this to be cutting edge. I want it to be fast, quick cuts, you know, youthful, sort of a MTV on crack kind of thing.", "order": 17389, "speaker": "Michael Scott"},
  {"season": 4, "episode": 5, "scene": 4, "line": "That sounds great.", "order": 17390, "speaker": "Ad guy 1"},
  {"season": 4, "episode": 5, "scene": 5, "line": "All right, good, well this is what we have to work with.", "order": 17391, "speaker": "Michael Scott"},
  {"season": 4, "episode": 5, "scene": 5, "line": "I would like you to meet Andrew Bernard. The 'Nard Dog. Who let the 'Nard Dog out?", "order": 17392, "speaker": "Michael Scott"},
  {"season": 4, "episode": 5, "scene": 5, "line": "Hoo hoo hoo-hoo!", "order": 17393, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 5, "scene": 5, "line": "He gives the best back rubs in the office.", "order": 17394, "speaker": "Michael Scott"},
  {"season": 4, "episode": 5, "scene": 5, "line": "It's true, I give a mean backrub. I also do good aromatherapy [makes farting noise]. Not! You just got 'Nard dogged!", "order": 17395, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 5, "scene": 5, "line": "Now this gentleman right here is the key to our urban vibe.", "order": 17396, "speaker": "Michael Scott"},
  {"season": 4, "episode": 5, "scene": 5, "line": "Urban? I grew up in a small town. What about me seems urban to you?", "order": 17397, "speaker": "Stanley Hudson"},
  {"season": 4, "episode": 5, "scene": 5, "line": "Stanley is hilarious. Phyllis is like our Mrs. Butterworth. Kind of a less urban Aunt Jemima.", "order": 17398, "speaker": "Michael Scott"},
  {"season": 4, "episode": 5, "scene": 5, "line": "These are our accountants. And as you can see they are very different sizes. What you might want to do is kind of a Papa Bear, Mama Bear, Baby Bear thing. That might be kind of fun.", "order": 17399, "speaker": "Michael Scott"},
  {"season": 4, "episode": 5, "scene": 5, "line": "[looking at Oscar] Mama Bear!", "order": 17400, "speaker": "Kevin Malone"},
  {"season": 4, "episode": 5, "scene": 5, "line": "Who else?", "order": 17401, "speaker": "Michael Scott"},
  {"season": 4, "episode": 5, "scene": 6, "line": "I think it's great that the company's making a commercial because not very many people have heard of us. I mean, when I tell people that I work at Dunder Mifflin, they think that we sell mufflers, or muffins, or mittens, or... And frankly all of those sound better than paper, so I let it slide.", "order": 17402, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 5, "scene": 7, "line": "You playing that game again?", "order": 17403, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 5, "scene": 7, "line": "Second Life is not a game. It is a multi-user virtual environment. It doesn't have points or scores, it doesn't have winners or losers.", "order": 17404, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 5, "scene": 7, "line": "Oh it has losers.", "order": 17405, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 5, "scene": 8, "line": "I signed up for Second Life about a year ago. Back then my life was so great that I literally wanted a second one. In my Second Life I was also a paper salesman and I was also named Dwight. Absolutely everything was the same. Except I could fly. [shot of Dwight's avatar flying around]", "order": 17406, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 5, "scene": 9, "line": "[reading off piece of paper] Little girl in a field holding a flower, we zoom back to find that she's in the desert and the field is an oasis. Zoom back further the desert is a sandbox in the world's largest resort hotel. Zoom back further the hotel is actually the playground for the world's largest prison. But we zoom back further---", "order": 17407, "speaker": "Michael Scott"},
  {"season": 4, "episode": 5, "scene": 9, "line": "Okay I can tell that your time is valuable---", "order": 17408, "speaker": "Ad guy 1"},
  {"season": 4, "episode": 5, "scene": 9, "line": "Actually I don't get paid by the hour anymore, but thank you, I get paid by the year, so...", "order": 17409, "speaker": "Michael Scott"},
  {"season": 4, "episode": 5, "scene": 9, "line": "That all sounds really, really ambitious.", "order": 17410, "speaker": "Ad guy 1"},
  {"season": 4, "episode": 5, "scene": 9, "line": "Yeah, I know.", "order": 17411, "speaker": "Michael Scott"},
  {"season": 4, "episode": 5, "scene": 9, "line": "Why don't we show you what we did with the Nashua branch?", "order": 17412, "speaker": "Ad guy 1"},
  {"season": 4, "episode": 5, "scene": 9, "line": "Mmm. Okay. [commercial plays on laptop, peppy music in background]", "order": 17413, "speaker": "Michael Scott"},
  {"season": 4, "episode": 5, "scene": 9, "line": "That's what Nassau came up with? That sucks! [chuckles] Whoa.", "order": 17414, "speaker": "Michael Scott"},
  {"season": 4, "episode": 5, "scene": 9, "line": "That's what we came up with.", "order": 17415, "speaker": "Ad guy 1"},
  {"season": 4, "episode": 5, "scene": 9, "line": "Well we can do better than that.", "order": 17416, "speaker": "Michael Scott"},
  {"season": 4, "episode": 5, "scene": 9, "line": "Well the main part of the ad has to stay the same, actually.", "order": 17417, "speaker": "Ad guy 2"},
  {"season": 4, "episode": 5, "scene": 9, "line": "Yeah, it's the last five seconds where we have some leeway.", "order": 17418, "speaker": "Ad guy 1"},
  {"season": 4, "episode": 5, "scene": 9, "line": "The waving?", "order": 17419, "speaker": "Michael Scott"},
  {"season": 4, "episode": 5, "scene": 9, "line": "Well, no, no. You don't have to be waving. That was just what they did. You guys can be clapping. Sitting. Standing outside, inside, whatever. This is where you really get to be creative.", "order": 17420, "speaker": "Ad guy 1"},
  {"season": 4, "episode": 5, "scene": 10, "line": "I need some advice. I've been spending a lot of time making out with Angela lately. But we've been necking, but only necking. Right? Not actually kissing, our mouths, just the neck on neck. It's just like rubbing slash nuzzling our necks together. It's hot, I'm not going to lie to you , but it's a little weird, but you seem like a guy with answers, so how do I fast track this to get to first base?", "order": 17421, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 5, "scene": 10, "line": "We cannot talk about this... because, someone might hear us.", "order": 17422, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 5, "scene": 10, "line": "We'll use code names.", "order": 17423, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 5, "scene": 10, "line": "Angela can stay the same, but we'll change Andy to Dwight.", "order": 17424, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 5, "scene": 10, "line": "That's not different enough.", "order": 17425, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 5, "scene": 10, "line": "Dwike?", "order": 17426, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 5, "scene": 11, "line": "You have a call from Eddie Murphy.", "order": 17427, "speaker": "Receptionist"},
  {"season": 4, "episode": 5, "scene": 11, "line": "Hello?", "order": 17428, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 5, "scene": 11, "line": "[on speakerphone, Eddie Murphy voice] Shrek! Shrek, I'm a donkey! I'm a donkey, Shrek! [laughing] I'm just kidding. It's me. Hello Ry.", "order": 17429, "speaker": "Michael Scott"},
  {"season": 4, "episode": 5, "scene": 11, "line": "What?", "order": 17430, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 5, "scene": 11, "line": "Okay, calm down. I have a small problem.", "order": 17431, "speaker": "Michael Scott"},
  {"season": 4, "episode": 5, "scene": 11, "line": "I told you not to call about small problems.", "order": 17432, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 5, "scene": 11, "line": "Yeah, well when I call about big problems you don't like that either, so make up you mind, kiddo. Here is the deal, the ad guys that you sent are locking me in a creative box, and sort of ignoring my ideas.", "order": 17433, "speaker": "Michael Scott"},
  {"season": 4, "episode": 5, "scene": 11, "line": "That's good. They're creative, you're not.", "order": 17434, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 5, "scene": 11, "line": "I'm creative, Ryan.", "order": 17435, "speaker": "Michael Scott"},
  {"season": 4, "episode": 5, "scene": 11, "line": "It's not part of your job, it's like, maybe you can cook but it doesn't mean you should start a restaurant.", "order": 17436, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 5, "scene": 11, "line": "Well actually I can't cook, and I am starting a restaurant. Mike's Cereal Shack. I'm thinking we'll have as many varieties as you can buy in the store.", "order": 17437, "speaker": "Michael Scott"},
  {"season": 4, "episode": 5, "scene": 11, "line": "Okay, I'm not really interested in that right now. I'm delegating creativity to creative professionals. It's a different skills set. Look, I wasn't good at sales, right?", "order": 17438, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 5, "scene": 11, "line": "Yeah!", "order": 17439, "speaker": "Michael Scott"},
  {"season": 4, "episode": 5, "scene": 11, "line": "But I'm good at managing people who do sales .", "order": 17440, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 5, "scene": 11, "line": "Are you? I don't think you're doing such a good job here, suppressing ideas and creativity.", "order": 17441, "speaker": "Michael Scott"},
  {"season": 4, "episode": 5, "scene": 12, "line": "All right, let me ask you this, tell me if you think this is creative. When I was five, I imagined that there was such a thing as a unicorn. And this is before I had even heard of one, or seen one. I just drew a picture of a horse that could fly over rainbows, and had a huge spike in its head. And I was five, five years old. Couldn't even talk yet.", "order": 17442, "speaker": "Michael Scott"},
  {"season": 4, "episode": 5, "scene": 13, "line": "Hey guys, um, you know what, we cannot shoot this ad today.", "order": 17443, "speaker": "Michael Scott"},
  {"season": 4, "episode": 5, "scene": 13, "line": "Okay, when should we come back?", "order": 17444, "speaker": "Ad guy 2"},
  {"season": 4, "episode": 5, "scene": 13, "line": "How about never hundred hours, sir.", "order": 17445, "speaker": "Michael Scott"},
  {"season": 4, "episode": 5, "scene": 13, "line": "We were sent here to help out.", "order": 17446, "speaker": "Ad guy 2"},
  {"season": 4, "episode": 5, "scene": 13, "line": "Okay I'm not going to argue with this guy. Let's go. Good luck dude.", "order": 17447, "speaker": "Ad guy 1"},
  {"season": 4, "episode": 5, "scene": 13, "line": "Hey thanks. Thank you.", "order": 17448, "speaker": "Michael Scott"},
  {"season": 4, "episode": 5, "scene": 14, "line": "[on phone] Michael, David Wallace. What is this about dismissing the ad people?", "order": 17449, "speaker": "David Wallace"},
  {"season": 4, "episode": 5, "scene": 14, "line": "Yeah, I'm glad you called. Ryan is being a little bitch again.", "order": 17450, "speaker": "Michael Scott"},
  {"season": 4, "episode": 5, "scene": 14, "line": "I'm on Michael.", "order": 17451, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 5, "scene": 14, "line": "What's up my brotha? Listen, David, I would like to do this ad in house. I want to use only the creativity that we have right here in the office. And I will send it to you tomorrow morning, take a look at it, and if you do not think that it's ready to air, send the ad agency back down here, and we'll do it on my dime.", "order": 17452, "speaker": "Michael Scott"},
  {"season": 4, "episode": 5, "scene": 14, "line": "This is weird.", "order": 17453, "speaker": "David Wallace"},
  {"season": 4, "episode": 5, "scene": 14, "line": "I'm willing to stake my entire reputation on it.", "order": 17454, "speaker": "Michael Scott"},
  {"season": 4, "episode": 5, "scene": 14, "line": "Okay, I'll see it tomorrow.", "order": 17455, "speaker": "David Wallace"},
  {"season": 4, "episode": 5, "scene": 14, "line": "Okay. [hangs up phone] And thus, Michael Scott sealed his own destiny. In a good way.", "order": 17456, "speaker": "Michael Scott"},
  {"season": 4, "episode": 5, "scene": 15, "line": "Has anyone ever come up to you and said, 'You're not creative'?", "order": 17457, "speaker": "Michael Scott"},
  {"season": 4, "episode": 5, "scene": 15, "line": "Yes.", "order": 17458, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 5, "scene": 15, "line": "Well they're wrong. You are creative. You are damn creative. Each and every one of you. You are so much more creative than all of the other dry, boring morons that you work with.", "order": 17459, "speaker": "Michael Scott"},
  {"season": 4, "episode": 5, "scene": 15, "line": "Who are you talking to, specifically?", "order": 17460, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 5, "scene": 16, "line": "I kind of know what it's like to be in commercials. My nickname in high school used to be Kool-Aid Man. [makes squinty face]", "order": 17461, "speaker": "Kevin Malone"},
  {"season": 4, "episode": 5, "scene": 17, "line": "When I was younger I always wanted to be an actor in commercials. Then I realized I had a brain.", "order": 17462, "speaker": "Oscar Martinez"},
  {"season": 4, "episode": 5, "scene": 18, "line": "I'm excited about doing the ad, but I'm not really used to doing videos with so many people around.", "order": 17463, "speaker": "Meredith Palmer"},
  {"season": 4, "episode": 5, "scene": 19, "line": "We have three scenes to film, big scenes. We have a song to write. So let's get cracking. Kelly, I want you to do makeup. Oscar, I would like you to do costume design, obviously. Phyllis, I'd like you look around town and see if we have any celebrities in our local area.", "order": 17464, "speaker": "Michael Scott"},
  {"season": 4, "episode": 5, "scene": 20, "line": "[gasps] Sue Grafton is at the Steamtown Mall. She's doing a book signing right now.", "order": 17465, "speaker": "Phyllis Vance"},
  {"season": 4, "episode": 5, "scene": 20, "line": "Okay, okay, Phyllis this is what I want you to do. I want you to go down to the mall. I want you to get in line. I want you to get her to be in this commercial. This would be a huge coupe people. All right? Do not take no for an answer.", "order": 17466, "speaker": "Michael Scott"},
  {"season": 4, "episode": 5, "scene": 20, "line": "Okay.", "order": 17467, "speaker": "Phyllis Vance"},
  {"season": 4, "episode": 5, "scene": 20, "line": "Does anyone actually know what Sue Grafton looks like? I mean, is she hot or-?", "order": 17468, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 5, "scene": 20, "line": "She's crazy hot.", "order": 17469, "speaker": "Creed Bratton"},
  {"season": 4, "episode": 5, "scene": 20, "line": "Well then maybe we should just use Angela and say she's Sue Grafton. Would anyone notice?", "order": 17470, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 5, "scene": 20, "line": "That's not happening.", "order": 17471, "speaker": "Angela Martin"},
  {"season": 4, "episode": 5, "scene": 21, "line": "I find the mystery genre disgusting. I hate being titillated.", "order": 17472, "speaker": "Angela Martin"},
  {"season": 4, "episode": 5, "scene": 22, "line": "Bye everyone.", "order": 17473, "speaker": "Phyllis Vance"},
  {"season": 4, "episode": 5, "scene": 22, "line": "Line it up Phyllis.", "order": 17474, "speaker": "Michael Scott"},
  {"season": 4, "episode": 5, "scene": 22, "line": "Get her Phyll.", "order": 17475, "speaker": "Creed Bratton"},
  {"season": 4, "episode": 5, "scene": 23, "line": "[singing] Out of paper, out of stock, there's friendly faces around the block, break loose from the chains that are causing your pain.", "order": 17476, "speaker": "Darryl Philbin"},
  {"season": 4, "episode": 5, "scene": 23, "line": "Call Michael or Stanley, Jim, Dwight or Creed. Call Andy and Kelly for your business paper needs.", "order": 17477, "speaker": "Andy Bernard, Creed Bratton, Kevin Malone, Kelly Kapoor, Darryl Philbin"},
  {"season": 4, "episode": 5, "scene": 23, "line": "Dunder Mifflin.", "order": 17478, "speaker": "Darryl Philbin"},
  {"season": 4, "episode": 5, "scene": 23, "line": "The people person's paper people.", "order": 17479, "speaker": "Andy Bernard, Creed Bratton, Kevin Malone, Kelly Kapoor"},
  {"season": 4, "episode": 5, "scene": 23, "line": "Dunder Mifflin.", "order": 17480, "speaker": "Darryl Philbin"},
  {"season": 4, "episode": 5, "scene": 23, "line": "The people person's paper people.", "order": 17481, "speaker": "Andy Bernard, Creed Bratton, Kevin Malone, Kelly Kapoor"},
  {"season": 4, "episode": 5, "scene": 23, "line": "Time out, time out, time out, time out, time out. I thought... I was under the impression that this was going to be a rap.", "order": 17482, "speaker": "Michael Scott"},
  {"season": 4, "episode": 5, "scene": 23, "line": "What's rap?", "order": 17483, "speaker": "Darryl Philbin"},
  {"season": 4, "episode": 5, "scene": 23, "line": "Okay, Darryl, wow, you need to learn a lot about your own culture. I'll make you a mix.", "order": 17484, "speaker": "Michael Scott"},
  {"season": 4, "episode": 5, "scene": 23, "line": "Great.", "order": 17485, "speaker": "Darryl Philbin"},
  {"season": 4, "episode": 5, "scene": 24, "line": "News flash. I got some juicy updates on Operation Fallen Angel. Remind me to tell you later.", "order": 17486, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 5, "scene": 25, "line": "Hey.", "order": 17487, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 5, "scene": 25, "line": "No talk, I'm animating.", "order": 17488, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 5, "scene": 25, "line": "Why don't we take a quick ten second break from that so I can show you what's going on here. [pulls up Dwight's game] Okay, this is Dwight's Second Life. He's on it all the time. So much so that his little guy here has created his own world. It's called Second Second Life, for those people who want to be removed even further from reality.", "order": 17489, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 5, "scene": 25, "line": "Are you serious?", "order": 17490, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 5, "scene": 25, "line": "Yeah.", "order": 17491, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 5, "scene": 25, "line": "Oh my God, he's really in pain.", "order": 17492, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 5, "scene": 25, "line": "[sighs]", "order": 17493, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 5, "scene": 25, "line": "Who's that?", "order": 17494, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 5, "scene": 25, "line": "Oh, it's just my avatar guy. Whatever.", "order": 17495, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 5, "scene": 25, "line": "He looks a lot like you. How much time did you spend on that?", "order": 17496, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 5, "scene": 25, "line": "Not much, it's just for tracking Dwight so---", "order": 17497, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 5, "scene": 25, "line": "Right... you're a sports writer in Philadelphia? Nice build too.", "order": 17498, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 5, "scene": 25, "line": "Yep.", "order": 17499, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 5, "scene": 25, "line": "You have a guitar slung on your back. I did not know you played guitar.", "order": 17500, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 5, "scene": 25, "line": "I... why don't we go back to this animation.", "order": 17501, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 5, "scene": 25, "line": "No no no, I want to see more of Philly Jim. I want Philly Jim.", "order": 17502, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 5, "scene": 25, "line": "Ah, show me how this works.", "order": 17503, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 5, "scene": 25, "line": "Oh boy.", "order": 17504, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 5, "scene": 26, "line": "[singing in different tune] Out of paper, out of stock, there's friendly faces around the block, break loose from the chains that are causing your pain. Call Michael, Stanley, Jim, Dwight or Creed. Call Andy and Kelly for your business paper needs. Call Dunder Mifflin.", "order": 17505, "speaker": "Darryl Philbin"},
  {"season": 4, "episode": 5, "scene": 26, "line": "The people person's paper people.", "order": 17506, "speaker": "Andy Bernard, Creed Bratton, Kevin Malone, Kelly Kapoor"},
  {"season": 4, "episode": 5, "scene": 26, "line": "Dunder Mifflin.", "order": 17507, "speaker": "Darryl Philbin"},
  {"season": 4, "episode": 5, "scene": 26, "line": "The people person's paper people.", "order": 17508, "speaker": "Andy Bernard, Creed Bratton, Kevin Malone, Kelly Kapoor"},
  {"season": 4, "episode": 5, "scene": 26, "line": "Stop. Stop stop stop stop stop stop. This is not me. This is not my music.", "order": 17509, "speaker": "Michael Scott"},
  {"season": 4, "episode": 5, "scene": 26, "line": "You're right, it's better than you! It's us! [sings] Dunder Mifflin.", "order": 17510, "speaker": "Darryl Philbin"},
  {"season": 4, "episode": 5, "scene": 26, "line": "The people person's paper people.", "order": 17511, "speaker": "Andy Bernard, Creed Bratton, Kevin Malone, Kelly Kapoor"},
  {"season": 4, "episode": 5, "scene": 26, "line": "No, I hate it! I hate it! ...I don't hate it, I just don't like it at all. And it's terrible.", "order": 17512, "speaker": "Michael Scott"},
  {"season": 4, "episode": 5, "scene": 26, "line": "You're on your own, Mike. [gets up and starts to walk away]", "order": 17513, "speaker": "Darryl Philbin"},
  {"season": 4, "episode": 5, "scene": 26, "line": "Okay, you know what. Hold on, hold on Darryl.", "order": 17514, "speaker": "Michael Scott"},
  {"season": 4, "episode": 5, "scene": 26, "line": "You just said you hated it.", "order": 17515, "speaker": "Darryl Philbin"},
  {"season": 4, "episode": 5, "scene": 26, "line": "No. I said I hate the, the style.", "order": 17516, "speaker": "Michael Scott"},
  {"season": 4, "episode": 5, "scene": 27, "line": "[singing] Break me off a piece of that lumber tar. Snickers bar.", "order": 17517, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 5, "scene": 27, "line": "Okay, wow. Wow, this looks uh, terrible.", "order": 17518, "speaker": "Michael Scott"},
  {"season": 4, "episode": 5, "scene": 27, "line": "Break me off a piece of that Grey Poupon.", "order": 17519, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 5, "scene": 27, "line": "Andy, Andy, this is a pivotal scene in the ad. And if we don't get this, if we don't nail it, we're going to lose the whole triumph of the moment. The triumph of the will. Now, God, what? What Phyllis?", "order": 17520, "speaker": "Michael Scott"},
  {"season": 4, "episode": 5, "scene": 27, "line": "[crying] Well, I got in line to buy Sue Grafton's book and when it came to my turn I asked if she wanted to be in the ad and she said no thank you, but I wasn't supposed to take no for an answer.", "order": 17521, "speaker": "Phyllis Vance"},
  {"season": 4, "episode": 5, "scene": 27, "line": "That'a girl.", "order": 17522, "speaker": "Michael Scott"},
  {"season": 4, "episode": 5, "scene": 27, "line": "So I kept on asking and they finally threw me out of the store in front of all my friends.", "order": 17523, "speaker": "Phyllis Vance"},
  {"season": 4, "episode": 5, "scene": 27, "line": "Did you, or did you not get Sue Grafton?", "order": 17524, "speaker": "Michael Scott"},
  {"season": 4, "episode": 5, "scene": 27, "line": "[sobbing] No.", "order": 17525, "speaker": "Phyllis Vance"},
  {"season": 4, "episode": 5, "scene": 27, "line": "Ugh, can somebody give her a tissue please? [Kevin hands her piece of tape]", "order": 17526, "speaker": "Michael Scott"},
  {"season": 4, "episode": 5, "scene": 28, "line": "I hope you're not killing yourself on this, because I'm sure it's good enough for Michael's ad that will probably be seen by no one.", "order": 17527, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 5, "scene": 28, "line": "Maybe, but it's not good enough for me yet.", "order": 17528, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 5, "scene": 28, "line": "Okay. Do you want me to stay?", "order": 17529, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 5, "scene": 28, "line": "No no, you can go home. I'm good.", "order": 17530, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 5, "scene": 29, "line": "Pam is staying late tonight to uh, achieve her dreams. So I'm pretty proud of her, unfortunately she was my ride home.", "order": 17531, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 5, "scene": 29, "line": "You comin'?", "order": 17532, "speaker": "Meredith Palmer"},
  {"season": 4, "episode": 5, "scene": 29, "line": "I, ah...", "order": 17533, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 5, "scene": 29, "line": "Piss or get off the pot!", "order": 17534, "speaker": "Meredith Palmer"},
  {"season": 4, "episode": 5, "scene": 30, "line": "Dunder Mifflin, this is Pam.", "order": 17535, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 5, "scene": 30, "line": "Good morning. [Pam sees breakfast sitting on her desk] Yeah I'm sorry, I looked away for a second and Creed snatched your hash browns.", "order": 17536, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 5, "scene": 30, "line": "Thank you.", "order": 17537, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 5, "scene": 30, "line": "You're welcome.", "order": 17538, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 5, "scene": 31, "line": "I worked until about 2:45 AM. And then I had to decide if I wanted to spend the night with Michael editing in his office, and Dwight watching Michael edit in his office, or drive home and probably fall asleep at the wheel and die in a fiery car wreck. I passed out on my keyboard trying to decide.", "order": 17539, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 5, "scene": 32, "line": "Let me pour you some bubbly because somebody got to a whole new level last night.", "order": 17540, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 5, "scene": 32, "line": "Andy, I can't hear this right now.", "order": 17541, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 5, "scene": 32, "line": "No, no no no, this is good. You know how we haven't really gotten anywhere that I want to get to physically yet? Well, last night, that changed. We're makin' out, I'm kissin' her neck, and cheek and her ear lobe, and she's not really kissing me back. But she closes her eyes and she's like, 'Oh D, oh D.'", "order": 17542, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 5, "scene": 32, "line": "She called you D?", "order": 17543, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 5, "scene": 32, "line": "Yeah. D for Andy.", "order": 17544, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 5, "scene": 32, "line": "Oh D.", "order": 17545, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 5, "scene": 32, "line": "Oh D.", "order": 17546, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 5, "scene": 32, "line": "[whispers] Oh D.", "order": 17547, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 5, "scene": 32, "line": "Oh D!", "order": 17548, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 5, "scene": 32, "line": "Ohhhhhh D! [laughing] Ohhhh D!", "order": 17549, "speaker": "Both"},
  {"season": 4, "episode": 5, "scene": 33, "line": "I'm about to send the ad to corporate... and it is sent. They'll probably watch it right away. I know I would. Okay. [dials phone]", "order": 17550, "speaker": "Michael Scott"},
  {"season": 4, "episode": 5, "scene": 33, "line": "[on phone] Yes?", "order": 17551, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 5, "scene": 33, "line": "Pam, please clear my phone lines.", "order": 17552, "speaker": "Michael Scott"},
  {"season": 4, "episode": 5, "scene": 33, "line": "Certainly. [makes beeping noises] Okay, clear.", "order": 17553, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 5, "scene": 33, "line": "They could call at any second now. [sighs] Oh God...I better call.", "order": 17554, "speaker": "Michael Scott"},
  {"season": 4, "episode": 5, "scene": 34, "line": "[Ten days later] Well, it's been tough. The uh, geniuses at corporate rejected my commercial and tonight they are airing the brain dead version. So welcome one and all to the world premiere of Corporate Crapfest!", "order": 17555, "speaker": "Michael Scott"},
  {"season": 4, "episode": 5, "scene": 35, "line": "[watching ad] Oh! [clapping and cheering]", "order": 17556, "speaker": "Everyone"},
  {"season": 4, "episode": 5, "scene": 35, "line": "Hey it seemed like a big hit.", "order": 17557, "speaker": "Bartender"},
  {"season": 4, "episode": 5, "scene": 35, "line": "No, it was stupid. People like waving, waving sells. It's not art. Yet we made our ad, the real one was full of humor, and full of depth, and full heart and it was real.", "order": 17558, "speaker": "Michael Scott"},
  {"season": 4, "episode": 5, "scene": 36, "line": "Hey, could you do me a favor? Could you put that in the DVD player?", "order": 17559, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 5, "scene": 37, "line": "Hey everybody! Just want to welcome you all to the premiere of the real Dunder Mifflin commercial, the Michael Scott director's cut. Hope you like it.", "order": 17560, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 5, "scene": 38, "line": "[Chariots of Fire theme plays] It all starts with an idea. But you can never tell where an idea will end up. [people passing a Corporate Memo around to each other] Because ideas spread, they change, grow. They connect us with the world. [Kelly catches paper airplane that says 'I love you'] And in a fast moving world, where good news moves at the speed of time [newspaper with Andy saying 'Hometown Boy Wins Race'], and bad news isn't always what it seems. [Dwight hands Phyllis paper that says 'You have a son, and it's me'] Because when push comes to shove we all deserve a second chance. [Stanley finds paper that says 'Turn your life around. Now hiring at Dunder Mifflin'] To score. [Jim throws paper in basket, Michael retrieves it; it says 'World's Most Creative Boss'] Dunder Mifflin. Limitless paper in a paperless world.", "order": 17561, "speaker": "Michael Scott's Ad"},
  {"season": 4, "episode": 5, "scene": 39, "line": "[claps and cheer]", "order": 17562, "speaker": "Everyone"},
  {"season": 4, "episode": 5, "scene": 39, "line": "Animation? All her by the way. [points at Pam]", "order": 17563, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 5, "scene": 39, "line": "Really?", "order": 17564, "speaker": "Bartender"},
  {"season": 4, "episode": 5, "scene": 39, "line": "I just thought you should---", "order": 17565, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 5, "scene": 39, "line": "The animation was cool.", "order": 17566, "speaker": "Bartender"},
  {"season": 4, "episode": 5, "scene": 39, "line": "Thanks.", "order": 17567, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 5, "scene": 39, "line": "Hey listen, you ever been on a motorcycle?", "order": 17568, "speaker": "Bartender"},
  {"season": 4, "episode": 5, "scene": 39, "line": "Ahh. [puts his arm around Pam]", "order": 17569, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 5, "scene": 40, "line": "Michael, that was fun.", "order": 17570, "speaker": "Kevin Malone"},
  {"season": 4, "episode": 5, "scene": 40, "line": "That was fun. Next round of drinks is on me, people!", "order": 17571, "speaker": "Michael Scott"},
  {"season": 4, "episode": 5, "scene": 41, "line": "[singing] Claude Van Damme, Hair for Men, poison gas, NutraSweet. [speaking] It's gotta rhyme with 'piece.' Fancy Feast! [sings] Break me off a piece of that Fancy Feast! It's the cat food. Nailed it.", "order": 17572, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 5, "scene": 42, "line": "All right! So, anybody else? No bad ideas. Everybody, let's keep 'em coming... oh, God. Okay, Toby?", "order": 17573, "speaker": "Michael Scott"},
  {"season": 4, "episode": 5, "scene": 42, "line": "Well, you know how everyone fast forwards through ads these days? Well, what about an ad in slow motion. You know, if they fast forward through it, it'll just seem normal and it will catch your eye. Plus, uh, the slogan could be: Dunder Mifflin, we adapt to the pace of your business. [murmurs of approval by everyone but Michael]", "order": 17574, "speaker": "Toby Flenderson"},
  {"season": 4, "episode": 5, "scene": 42, "line": "There are no bad ideas but for an idea, that was really, really bad.", "order": 17575, "speaker": "Michael Scott"},
  {"season": 4, "episode": 5, "scene": 42, "line": "I spent three years in advertising before I came here.", "order": 17576, "speaker": "Toby Flenderson"},
  {"season": 4, "episode": 5, "scene": 42, "line": "And that is probably why most ads suck. [points to the ad men waiting outside the conference room] Oh, that's them! Those are the ad guys right there. All right, my very fortunate and creative group, go back to your desks and I will let you know when it is time to film. [everyone starts leaving the conference room] [loud whisper] Pam! Pam, come here for a second. Did you get the memo about dressing your best?", "order": 17577, "speaker": "Michael Scott"},
  {"season": 4, "episode": 5, "scene": 42, "line": "Yeah.", "order": 17578, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 5, "scene": 42, "line": "About dressing your best today?", "order": 17579, "speaker": "Michael Scott"},
  {"season": 4, "episode": 5, "scene": 42, "line": "Yeah, I distribute so I get all the memos.", "order": 17580, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 5, "scene": 42, "line": "Cool. Cool, I just wanted to make sure you got it.", "order": 17581, "speaker": "Michael Scott"},
  {"season": 4, "episode": 5, "scene": 42, "line": "Yeah, I get all the memos. [starts to leave]", "order": 17582, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 5, "scene": 42, "line": "Okay, good. Good.", "order": 17583, "speaker": "Michael Scott"},
  {"season": 4, "episode": 5, "scene": 43, "line": "This is Pam Beesly, representing our girl next door. Pretty but nothing special. You know, but she sort of keeps it real. What you might want to do, is if you could zoom in, like really quick zooms on her. Might be good. [walking away] Who else? [pointing] Oh, Creed... is the old guy over there. Don't look at him. You might want to use him to sort of get that Orville Redenbacher dynamic going.", "order": 17584, "speaker": "Michael Scott"},
  {"season": 4, "episode": 5, "scene": 43, "line": "Sure.", "order": 17585, "speaker": "Ad guy 1"},
  {"season": 4, "episode": 5, "scene": 43, "line": "Right.", "order": 17586, "speaker": "Ad guy 2"},
  {"season": 4, "episode": 5, "scene": 43, "line": "And if not we can just make him disappear. [walks quickly over to Jim's desk] This is Jim! Halpert. Jim has a very expressive face, don't you Jim? He is like our Mr. Bean.", "order": 17587, "speaker": "Michael Scott"},
  {"season": 4, "episode": 5, "scene": 43, "line": "Okay.", "order": 17588, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 5, "scene": 43, "line": "So do, um, do sad, do the sad face.", "order": 17589, "speaker": "Michael Scott"},
  {"season": 4, "episode": 5, "scene": 43, "line": "I don't want -", "order": 17590, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 5, "scene": 43, "line": "No, that's skeptical. Do sad.", "order": 17591, "speaker": "Michael Scott"},
  {"season": 4, "episode": 5, "scene": 43, "line": "Mmm...", "order": 17592, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 5, "scene": 43, "line": "That's, that's a pirate movie. [Jim stares at Michael] That's annoyed. Well, he doesn't do very well under pressure.", "order": 17593, "speaker": "Michael Scott"},
  {"season": 4, "episode": 5, "scene": 43, "line": "[shakes head] Yeah... no. [Jim turns to Pam with an exaggerated sad face; Pam laughs. Dwight walks towards Michael and the ad men]", "order": 17594, "speaker": "Ad guy 1"},
  {"season": 4, "episode": 5, "scene": 43, "line": "And anybody else, we can just hire an actor to replace. Follow me! Let's get this movin'. [Dwight goes back to his seat, embarrassed]", "order": 17595, "speaker": "Michael Scott"},
  {"season": 4, "episode": 5, "scene": 44, "line": "All right, let's start.", "order": 17596, "speaker": "Michael Scott"},
  {"season": 4, "episode": 5, "scene": 44, "line": "All right! [sitting down behind Michael's desk] I'm not sure what I'm supposed to be doing, but...", "order": 17597, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 5, "scene": 44, "line": "You are my producer, you are a suit. I am the creative genius. You take care of all the boring stuff, and let my mind just run free.", "order": 17598, "speaker": "Michael Scott"},
  {"season": 4, "episode": 5, "scene": 44, "line": "Yup.", "order": 17599, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 5, "scene": 44, "line": "Now, we have eighty-five dollars, and a Sam Goody gift certificate...", "order": 17600, "speaker": "Michael Scott"},
  {"season": 4, "episode": 5, "scene": 44, "line": "Right.", "order": 17601, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 5, "scene": 44, "line": "...and we need to be done by 9 A.M. tomorrow.", "order": 17602, "speaker": "Michael Scott"},
  {"season": 4, "episode": 5, "scene": 44, "line": "Mmmhmm. Why don't you start by telling me what you got.", "order": 17603, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 5, "scene": 44, "line": "We start on a single blank sheet of paper.", "order": 17604, "speaker": "Michael Scott"},
  {"season": 4, "episode": 5, "scene": 44, "line": "Love it.", "order": 17605, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 5, "scene": 44, "line": "And we widen to reveal ancient Rome.", "order": 17606, "speaker": "Michael Scott"},
  {"season": 4, "episode": 5, "scene": 44, "line": "Mm, can't do that.", "order": 17607, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 5, "scene": 44, "line": "Okay, we widen to reveal a spaceship blasting out of a woman's womb.", "order": 17608, "speaker": "Michael Scott"},
  {"season": 4, "episode": 5, "scene": 44, "line": "Definitely can't do that.", "order": 17609, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 5, "scene": 44, "line": "Bull[censored] man!", "order": 17610, "speaker": "Michael Scott"},
  {"season": 4, "episode": 5, "scene": 44, "line": "You know what?", "order": 17611, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 5, "scene": 44, "line": "This is bull[censored]!", "order": 17612, "speaker": "Michael Scott"},
  {"season": 4, "episode": 5, "scene": 44, "line": "Okay, Scott, why don't you take your head out of the clouds and come back down to planet Earth so we can make a commercial.", "order": 17613, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 5, "scene": 44, "line": "Ahhhh! [sweeps toys off his desk]", "order": 17614, "speaker": "Michael Scott"},
  {"season": 4, "episode": 5, "scene": 44, "line": "I'm just doing my job, man.", "order": 17615, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 5, "scene": 44, "line": "If you don't let me pursue my artistic vision, I am going to walk!", "order": 17616, "speaker": "Michael Scott"},
  {"season": 4, "episode": 5, "scene": 44, "line": "Good! Fine! I've got one Andy Bernard that will direct this puppy for half your fee... [dialing phone]", "order": 17617, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 5, "scene": 44, "line": "No! No! Jim, Jim, don't, don't...", "order": 17618, "speaker": "Michael Scott"},
  {"season": 4, "episode": 5, "scene": 44, "line": "What? What?", "order": 17619, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 5, "scene": 44, "line": "No, I can do it.", "order": 17620, "speaker": "Michael Scott"},
  {"season": 4, "episode": 5, "scene": 44, "line": "What?", "order": 17621, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 5, "scene": 44, "line": "I can do it good. I can do it good. Please, don't. Please. [kneels in front of desk] Please give me it.", "order": 17622, "speaker": "Michael Scott"},
  {"season": 4, "episode": 5, "scene": 44, "line": "Okay, make a commercial.", "order": 17623, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 5, "scene": 44, "line": "[taps on desk and starts to leave his office] Do you want this open or closed?", "order": 17624, "speaker": "Michael Scott"},
  {"season": 4, "episode": 5, "scene": 44, "line": "Closed. [Michael leaves his office, gives Jim two thumbs up, which Jim returns]", "order": 17625, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 5, "scene": 45, "line": "Hey, everybody, I just got off the phone with David Wallace, and he has given us the go-ahead to make the first ever Dunder Mifflin television commercial. Huh?", "order": 17626, "speaker": "Michael Scott"},
  {"season": 4, "episode": 5, "scene": 45, "line": "[applause]", "order": 17627, "speaker": "Everyone"},
  {"season": 4, "episode": 5, "scene": 45, "line": "I can't hear you! [less applause] Heard you a little bit less that time. Must be...", "order": 17628, "speaker": "Michael Scott"},
  {"season": 4, "episode": 5, "scene": 45, "line": "We had already applauded.", "order": 17629, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 5, "scene": 45, "line": "Okay, okay. Who could do music?", "order": 17630, "speaker": "Michael Scott"},
  {"season": 4, "episode": 5, "scene": 45, "line": "How about Darryl?", "order": 17631, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 5, "scene": 45, "line": "Actually, I'm a little concerned about having a black person do the music, because that could create a positive stereotype that could then become a... celestine prophesy, and keep the next Bon Jovi from ever picking up a piano.", "order": 17632, "speaker": "Michael Scott"},
  {"season": 4, "episode": 5, "scene": 46, "line": "Michael is on a mission to prove that he's creative. Which I think is odd, because Michael actually might be the most creative person I've ever known. Every day, Michael says and thinks things that no one has ever said or thought before.", "order": 17633, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 6, "scene": 1, "line": "[on phone] Dunder Mifflin, this is Rolando. Oh yes, she's been expecting your call. [knocks on door]", "order": 17634, "speaker": "Rolando"},
  {"season": 4, "episode": 6, "scene": 1, "line": "Yeah.", "order": 17635, "speaker": "Karen Filippelli"},
  {"season": 4, "episode": 6, "scene": 1, "line": "Karen? He's on line one.", "order": 17636, "speaker": "Rolando"},
  {"season": 4, "episode": 6, "scene": 1, "line": "Thanks Ro. Hey, we finally connected. How's Scranton?", "order": 17637, "speaker": "Karen Filippelli"},
  {"season": 4, "episode": 6, "scene": 2, "line": "I am the regional manager of Dunder-Mifflin Utica branch. Turns out it's a pretty easy gig when your boss isn't an idiot and your boyfriend's not in love with somebody else.", "order": 17638, "speaker": "Karen Filippelli"},
  {"season": 4, "episode": 6, "scene": 3, "line": "Look, All I'm saying is it would be a great opportunity, and we'd love to have you come aboard. So think about it, okay? Okay, bye.", "order": 17639, "speaker": "Karen Filippelli"},
  {"season": 4, "episode": 6, "scene": 4, "line": "Look, for the record, a certain Scranton salesman approached me, okay?", "order": 17640, "speaker": "Karen Filippelli"},
  {"season": 4, "episode": 6, "scene": 5, "line": "This is perfect.", "order": 17641, "speaker": "Michael Scott"},
  {"season": 4, "episode": 6, "scene": 5, "line": "He looks like your twin.", "order": 17642, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 6, "scene": 5, "line": "This is a dummy, a la Ferris Bueller's Day Off. We have tied a string to the wrist, which goes to the door. When somebody opens the door, the hand goes down, hits the remote, turns on the tape recorder, which is me snoring. Now nobody knows whether I am here, or whether I am gone.", "order": 17643, "speaker": "Michael Scott"},
  {"season": 4, "episode": 6, "scene": 5, "line": "I will know.", "order": 17644, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 6, "scene": 5, "line": "But, you will not tell anyone.", "order": 17645, "speaker": "Michael Scott"},
  {"season": 4, "episode": 6, "scene": 5, "line": "I won't need to because we'll be together playing hooky. Yeah.", "order": 17646, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 6, "scene": 5, "line": "Well, sometimes. Most of the time I will be with Ryan, or Darryl. [knock on the door] Yes? Oh good, good Stanley! First victim, this is what I want you to do. Go out, come back in. We're going to hide. I want you to tell me if this looks like me, okay?", "order": 17647, "speaker": "Michael Scott"},
  {"season": 4, "episode": 6, "scene": 5, "line": "I don't understand why sleeping at your desk is better than you not being here.", "order": 17648, "speaker": "Stanley Hudson"},
  {"season": 4, "episode": 6, "scene": 5, "line": "Just go out, and come back in.", "order": 17649, "speaker": "Michael Scott"},
  {"season": 4, "episode": 6, "scene": 5, "line": "I got an offer from Utica for more money, and I'm going to take it.", "order": 17650, "speaker": "Stanley Hudson"},
  {"season": 4, "episode": 6, "scene": 5, "line": "What?", "order": 17651, "speaker": "Michael Scott"},
  {"season": 4, "episode": 6, "scene": 6, "line": "Hey everyone, can I have your attention please? I just thought you all should know that Stanley Hudson is planning on leaving us, because our old friend Karen from Utica is going to give him more money to work there.", "order": 17652, "speaker": "Michael Scott"},
  {"season": 4, "episode": 6, "scene": 6, "line": "[applause]", "order": 17653, "speaker": "All"},
  {"season": 4, "episode": 6, "scene": 6, "line": "No, no, no, no, no-no, no no. You completely misinterpreted my tone, this is a horrible thing. Clearly, Karen is trying to get back at us because Jim dumped her.", "order": 17654, "speaker": "Michael Scott"},
  {"season": 4, "episode": 6, "scene": 6, "line": "Oh, I don't think that is what's happening.", "order": 17655, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 6, "scene": 6, "line": "Okay, smarty pants, then why? Why is she trying to take Stanley from us?", "order": 17656, "speaker": "Michael Scott"},
  {"season": 4, "episode": 6, "scene": 6, "line": "I think it's because of my sales record.", "order": 17657, "speaker": "Stanley Hudson"},
  {"season": 4, "episode": 6, "scene": 6, "line": "That could not possibly be it.", "order": 17658, "speaker": "Michael Scott"},
  {"season": 4, "episode": 6, "scene": 7, "line": "You cannot take the hilarious black guy from the office. Stanley is part of what makes this branch so extraordinary. The bluesy wisdom, the sassy remarks, the crossword puzzles, the smile, those big watery red eyes. I don't know how George Bush did it when Colin Powell left, and if Utica thinks that they are going to poach Stanley, they have another thing coming.", "order": 17659, "speaker": "Michael Scott"},
  {"season": 4, "episode": 6, "scene": 8, "line": "How can I get you to stay?", "order": 17660, "speaker": "Michael Scott"},
  {"season": 4, "episode": 6, "scene": 8, "line": "Money.", "order": 17661, "speaker": "Stanley Hudson"},
  {"season": 4, "episode": 6, "scene": 8, "line": "Yeah we all want money. But there is none in the budget, so... Tell me why you're really leaving.", "order": 17662, "speaker": "Michael Scott"},
  {"season": 4, "episode": 6, "scene": 8, "line": "Money.", "order": 17663, "speaker": "Stanley Hudson"},
  {"season": 4, "episode": 6, "scene": 8, "line": "Mo money mo problems, Stanley. You of all people should know that. Let me ask you this, if I were-", "order": 17664, "speaker": "Michael Scott"},
  {"season": 4, "episode": 6, "scene": 8, "line": "Money.", "order": 17665, "speaker": "Stanley Hudson"},
  {"season": 4, "episode": 6, "scene": 8, "line": "Pssh, kay.", "order": 17666, "speaker": "Michael Scott"},
  {"season": 4, "episode": 6, "scene": 9, "line": "Oscar, did you bring it?", "order": 17667, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 6, "scene": 9, "line": "To be Edwardian. His best work.", "order": 17668, "speaker": "Oscar Martinez"},
  {"season": 4, "episode": 6, "scene": 9, "line": "Okay, well I have the tablecloth, don't forget the flatware.", "order": 17669, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 6, "scene": 9, "line": "Sure thing Pam, can't wait.", "order": 17670, "speaker": "Oscar Martinez"},
  {"season": 4, "episode": 6, "scene": 10, "line": "Oscar, Toby, and I are founding members of the Finer Things Club. We meet once a month to discuss books and art, celebrate culture in a very civilized way. Sometimes the debate can get heated, but we're always respectful. There is no paper, no plastic, and no work talk allowed. It's very exclusive.", "order": 17671, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 6, "scene": 11, "line": "Besides having sex with men, I would say that the Finer Things Club is the gayest thing about me.", "order": 17672, "speaker": "Oscar Martinez"},
  {"season": 4, "episode": 6, "scene": 12, "line": "So tell me again why I can't be part of your club?", "order": 17673, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 6, "scene": 12, "line": "Because some people think you monopolize the conversation by trying to be funny.", "order": 17674, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 6, "scene": 12, "line": "Oscar?", "order": 17675, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 6, "scene": 12, "line": "Some people.", "order": 17676, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 6, "scene": 13, "line": "[on phone] Hello?", "order": 17677, "speaker": "Karen Filippelli"},
  {"season": 4, "episode": 6, "scene": 13, "line": "Fillipellers, how's it hanging?", "order": 17678, "speaker": "Michael Scott"},
  {"season": 4, "episode": 6, "scene": 13, "line": "Michael...", "order": 17679, "speaker": "Karen Filippelli"},
  {"season": 4, "episode": 6, "scene": 13, "line": "To the left?", "order": 17680, "speaker": "Michael Scott"},
  {"season": 4, "episode": 6, "scene": 13, "line": "Listen, I-", "order": 17681, "speaker": "Karen Filippelli"},
  {"season": 4, "episode": 6, "scene": 13, "line": "To the right?", "order": 17682, "speaker": "Michael Scott"},
  {"season": 4, "episode": 6, "scene": 13, "line": "I'm-", "order": 17683, "speaker": "Karen Filippelli"},
  {"season": 4, "episode": 6, "scene": 13, "line": "Okay, enough small talk, go ahead.", "order": 17684, "speaker": "Michael Scott"},
  {"season": 4, "episode": 6, "scene": 13, "line": "You called me.", "order": 17685, "speaker": "Karen Filippelli"},
  {"season": 4, "episode": 6, "scene": 13, "line": "Yes, listen, um... You cannot tear Stanley from his family like this.", "order": 17686, "speaker": "Michael Scott"},
  {"season": 4, "episode": 6, "scene": 13, "line": "I'm pretty sure his family's coming with him.", "order": 17687, "speaker": "Karen Filippelli"},
  {"season": 4, "episode": 6, "scene": 13, "line": "No, his work family. Look, this is very hard for me, but I'm going to give you my best man. You may have Toby.", "order": 17688, "speaker": "Michael Scott"},
  {"season": 4, "episode": 6, "scene": 13, "line": "Toby's not a salesperson.", "order": 17689, "speaker": "Karen Filippelli"},
  {"season": 4, "episode": 6, "scene": 13, "line": "You can train him. He's very very smart, and funny, and charming... You know, I can't do it, Toby is the worst. That- that was a bluff, um... Listen, if you are going to poach one of my guys, I'm going to poach one of yours.", "order": 17690, "speaker": "Michael Scott"},
  {"season": 4, "episode": 6, "scene": 13, "line": "Oooh. Good-bye Michael.", "order": 17691, "speaker": "Karen Filippelli"},
  {"season": 4, "episode": 6, "scene": 13, "line": "Okay, good-bye. Wait! Wait! Karen, could you transfer me to one of your salespeople please? Your best one? [Karen hangs up]", "order": 17692, "speaker": "Michael Scott"},
  {"season": 4, "episode": 6, "scene": 14, "line": "I have Ben Nugent on the line, he is the top salesman in Utica.", "order": 17693, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 6, "scene": 14, "line": "Hi Ben, Michael Scott.", "order": 17694, "speaker": "Michael Scott"},
  {"season": 4, "episode": 6, "scene": 14, "line": "Hi Michael.", "order": 17695, "speaker": "Ben"},
  {"season": 4, "episode": 6, "scene": 14, "line": "I'm going to cut right to the chase here. Do you like magic? Because I'm a genie in a bottle, and I'm going to grant you three wishes. To move to Scranton, to have a great job, and to be my best friend.", "order": 17696, "speaker": "Michael Scott"},
  {"season": 4, "episode": 6, "scene": 14, "line": "Aren't you the guy that hit the woman with your car?", "order": 17697, "speaker": "Ben"},
  {"season": 4, "episode": 6, "scene": 14, "line": "[giggles]", "order": 17698, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 6, "scene": 14, "line": "[to Pam] Get out. [to Ben] Uh, yeah. I also saved her life, but I guess that's not as grabby.", "order": 17699, "speaker": "Michael Scott"},
  {"season": 4, "episode": 6, "scene": 14, "line": "Everyone says Scranton branch is worse than Camden. Didn't everyone from Stanford quit, like immediately?", "order": 17700, "speaker": "Ben"},
  {"season": 4, "episode": 6, "scene": 14, "line": "No, I fired them, and your next. ... So what do you say?", "order": 17701, "speaker": "Michael Scott"},
  {"season": 4, "episode": 6, "scene": 14, "line": "Seriously?", "order": 17702, "speaker": "Ben"},
  {"season": 4, "episode": 6, "scene": 15, "line": "What is that guy talking about? Scranton is not lame. Scranton is the cool, fun branch. We're like Animal House.", "order": 17703, "speaker": "Michael Scott"},
  {"season": 4, "episode": 6, "scene": 16, "line": "Found him!", "order": 17704, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 6, "scene": 16, "line": "Jim, we're getting crapped on. Word is that our branch sucks, and we have to do something about it.", "order": 17705, "speaker": "Michael Scott"},
  {"season": 4, "episode": 6, "scene": 16, "line": "So what are you going to do?", "order": 17706, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 6, "scene": 16, "line": "What are we going to do? We are going to make a monster sale, that's what we're going to do. Corcroan dropped Staples.", "order": 17707, "speaker": "Michael Scott"},
  {"season": 4, "episode": 6, "scene": 16, "line": "Did they?", "order": 17708, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 6, "scene": 16, "line": "Yes they did, oh yes they did, and we are going to murder it. You, me, Dwight are going to jump into my PT Cruiser, and we're going to crush this sale. We're going to prove, what the hell is that music?", "order": 17709, "speaker": "Michael Scott"},
  {"season": 4, "episode": 6, "scene": 16, "line": "It's Vivaldi, for Finer Things.", "order": 17710, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 6, "scene": 16, "line": "That's the problem, that's the problem. We need rock n' roll Pam, rock n' roll. Alright? [sees Toby in a bow-tie holding a plate full of tea cups] Oh... My... God, that's why people are leaving. I- I have no words.", "order": 17711, "speaker": "Michael Scott"},
  {"season": 4, "episode": 6, "scene": 17, "line": "We just passed the exit for Corcroan.", "order": 17712, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 6, "scene": 17, "line": "What? What? We did?", "order": 17713, "speaker": "Michael Scott"},
  {"season": 4, "episode": 6, "scene": 17, "line": "Surprise! Ha, ha, ha, ha, ha.", "order": 17714, "speaker": "Dwight Schrute & Michael Scott"},
  {"season": 4, "episode": 6, "scene": 17, "line": "Look at his face! Look at his face!", "order": 17715, "speaker": "Michael Scott"},
  {"season": 4, "episode": 6, "scene": 17, "line": "What are we doing?", "order": 17716, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 6, "scene": 17, "line": "Egg dripping, blah, blah, blah.", "order": 17717, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 6, "scene": 17, "line": "What are we doing, Dwight, what we are doing?", "order": 17718, "speaker": "Michael Scott"},
  {"season": 4, "episode": 6, "scene": 17, "line": "Well, gee, Jim, I don't know, I guess there's no sales call today.", "order": 17719, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 6, "scene": 17, "line": "We are going on a panty raid to Utica, is what we're doing.", "order": 17720, "speaker": "Michael Scott"},
  {"season": 4, "episode": 6, "scene": 17, "line": "We're going to Utica?", "order": 17721, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 6, "scene": 17, "line": "Uh-huh.", "order": 17722, "speaker": "Michael Scott"},
  {"season": 4, "episode": 6, "scene": 17, "line": "I'm not going to Utica right now.", "order": 17723, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 6, "scene": 17, "line": "Yes you are. Oh, buckle up Jim.", "order": 17724, "speaker": "Michael Scott"},
  {"season": 4, "episode": 6, "scene": 17, "line": "No.", "order": 17725, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 6, "scene": 17, "line": "We are going to make Karen wish that she had never been dumped by you.", "order": 17726, "speaker": "Michael Scott"},
  {"season": 4, "episode": 6, "scene": 17, "line": "[chanting] Utica! Utica! Utica!", "order": 17727, "speaker": "Michael Scott & Dwight Schrute"},
  {"season": 4, "episode": 6, "scene": 18, "line": "[chanting] Utica! Utica!", "order": 17728, "speaker": "Michael Scott & Dwight Schrute"},
  {"season": 4, "episode": 6, "scene": 18, "line": "In your face", "order": 17729, "speaker": "Michael Scott"},
  {"season": 4, "episode": 6, "scene": 18, "line": "Yow!", "order": 17730, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 6, "scene": 18, "line": "I can't believe you guys. I'm not going to go further piss off my ex-girlfriend.", "order": 17731, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 6, "scene": 18, "line": "Are you calling Karen?", "order": 17732, "speaker": "Michael Scott"},
  {"season": 4, "episode": 6, "scene": 18, "line": "No, I'm not calling Karen.", "order": 17733, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 6, "scene": 18, "line": "He's lying.", "order": 17734, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 6, "scene": 18, "line": "Yep, get it.", "order": 17735, "speaker": "Michael Scott"},
  {"season": 4, "episode": 6, "scene": 18, "line": "What are you doing? Stop it, Dwight!", "order": 17736, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 6, "scene": 18, "line": "[throws Jim's cell phone out of the window] Gah-ah-hah-ha!", "order": 17737, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 6, "scene": 18, "line": "Are you kidding me?", "order": 17738, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 6, "scene": 18, "line": "No communication with the outside world Jim.", "order": 17739, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 6, "scene": 18, "line": "It had to be done, it had to be done.", "order": 17740, "speaker": "Michael Scott"},
  {"season": 4, "episode": 6, "scene": 18, "line": "Well, that kind of sucks, because it had all the photos of my brother's new baby on it, so...", "order": 17741, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 6, "scene": 18, "line": "Oh no, that is too bad. Shoot.", "order": 17742, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 6, "scene": 19, "line": "Okay.", "order": 17743, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 6, "scene": 19, "line": "Hey Dwight, he found it.", "order": 17744, "speaker": "Michael Scott"},
  {"season": 4, "episode": 6, "scene": 19, "line": "You know what? I'm just going to call a cab from here.", "order": 17745, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 6, "scene": 19, "line": "Alright, you're going to miss the best prank ever.", "order": 17746, "speaker": "Michael Scott"},
  {"season": 4, "episode": 6, "scene": 19, "line": "Alright. Wait, what are you planning on doing?", "order": 17747, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 6, "scene": 19, "line": "Do you really wanna know?", "order": 17748, "speaker": "Michael Scott"},
  {"season": 4, "episode": 6, "scene": 19, "line": "Oh, God. [hangs up phone]", "order": 17749, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 6, "scene": 19, "line": "Follow me, come here. Here's what we brought, we brought uniforms from the warehouse. I brought silly string, Dwight brought gasoline and chunks of rubber to make stink bombs.", "order": 17750, "speaker": "Michael Scott"},
  {"season": 4, "episode": 6, "scene": 19, "line": "Or real bombs.", "order": 17751, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 6, "scene": 19, "line": "No, no, not real bombs.", "order": 17752, "speaker": "Michael Scott"},
  {"season": 4, "episode": 6, "scene": 19, "line": "Yeah, come on, it will be so badass.", "order": 17753, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 6, "scene": 19, "line": "Uh, maybe, maybe, I don't know.", "order": 17754, "speaker": "Michael Scott"},
  {"season": 4, "episode": 6, "scene": 19, "line": "No.", "order": 17755, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 6, "scene": 19, "line": "It could be badass. Yeah, it will.", "order": 17756, "speaker": "Michael Scott"},
  {"season": 4, "episode": 6, "scene": 19, "line": "No, no absolutely we are not doing this.", "order": 17757, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 6, "scene": 19, "line": "Come on, I already filled the bottles with the gas, it's going to be so badass.", "order": 17758, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 6, "scene": 19, "line": "Are you kidding me? We've been driving around with this stuff in the trunk the whole time?", "order": 17759, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 6, "scene": 19, "line": "Teach her to offer Stanley more money.", "order": 17760, "speaker": "Michael Scott"},
  {"season": 4, "episode": 6, "scene": 20, "line": "So the deal was, Dwight doesn't blow anything up, and I wear a costume. And a moustache.", "order": 17761, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 6, "scene": 21, "line": "So, why did you and Karen break up? Was it the sex?", "order": 17762, "speaker": "Michael Scott"},
  {"season": 4, "episode": 6, "scene": 21, "line": "What?", "order": 17763, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 6, "scene": 21, "line": "I can't imagine the sex being bad, I mean her body is...", "order": 17764, "speaker": "Michael Scott"},
  {"season": 4, "episode": 6, "scene": 21, "line": "Okay, you know what?", "order": 17765, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 6, "scene": 21, "line": "...awesome.", "order": 17766, "speaker": "Michael Scott"},
  {"season": 4, "episode": 6, "scene": 21, "line": "Why don't we play that alphabet game that you were talking about?", "order": 17767, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 6, "scene": 21, "line": "Okay, I will start. Um, 'A.' [singing] 'A' my name is Alan, and my wife's name is Alice, we live in Alaska, and we sell... Damn it! What do we sell? Um...", "order": 17768, "speaker": "Michael Scott"},
  {"season": 4, "episode": 6, "scene": 21, "line": "It doesn't matter.", "order": 17769, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 6, "scene": 21, "line": "I'm trying to think of what we could sell.", "order": 17770, "speaker": "Michael Scott"},
  {"season": 4, "episode": 6, "scene": 21, "line": "Doesn't matter.", "order": 17771, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 6, "scene": 21, "line": "Ah, la, la, la. What is that? That sound... The air-conditioning leaking or something?", "order": 17772, "speaker": "Michael Scott"},
  {"season": 4, "episode": 6, "scene": 21, "line": "That doesn't make sense, couldn't be.", "order": 17773, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 6, "scene": 21, "line": "What is that? Dwight, are you peeing?", "order": 17774, "speaker": "Michael Scott"},
  {"season": 4, "episode": 6, "scene": 21, "line": "I'm peeing in this empty can.", "order": 17775, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 6, "scene": 21, "line": "Oh my God!", "order": 17776, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 6, "scene": 21, "line": "Come on man, that is disgusting Dwight!", "order": 17777, "speaker": "Michael Scott"},
  {"season": 4, "episode": 6, "scene": 21, "line": "Well you said that we couldn't make anymore stops, and I really had to go.", "order": 17778, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 6, "scene": 21, "line": "Michael, watch the road!", "order": 17779, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 6, "scene": 21, "line": "Hey, you're making me spray!", "order": 17780, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 6, "scene": 21, "line": "I'll kill you man!", "order": 17781, "speaker": "Michael Scott"},
  {"season": 4, "episode": 6, "scene": 21, "line": "Michael! Michael, pull over!", "order": 17782, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 6, "scene": 21, "line": "That is just so disgusting!", "order": 17783, "speaker": "Michael Scott"},
  {"season": 4, "episode": 6, "scene": 21, "line": "Pull over, pull over!", "order": 17784, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 6, "scene": 21, "line": "I think I cut my penis on the lid!", "order": 17785, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 6, "scene": 22, "line": "Can you imagine a life where all you have to do is summer in the Italian countryside?", "order": 17786, "speaker": "Oscar Martinez"},
  {"season": 4, "episode": 6, "scene": 22, "line": "I know right? I just want to go, and look at art, and swim in the Adriatic.", "order": 17787, "speaker": "Toby Flenderson"},
  {"season": 4, "episode": 6, "scene": 22, "line": "And spend time with George Emerson. That's what I would do. I mean it's the best male protagonist we've read, right?", "order": 17788, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 6, "scene": 22, "line": "[joins them at the table] Totally, I mean, come on, such a free spirit.", "order": 17789, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 6, "scene": 22, "line": "What are you doing?", "order": 17790, "speaker": "Oscar Martinez"},
  {"season": 4, "episode": 6, "scene": 22, "line": "Just came to discuss my favorite E.M. Forester novel. Pam, these finger sandwiches look finger-lickin' delicious Pam.", "order": 17791, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 6, "scene": 22, "line": "Uh, I'm sorry Andy, but this is a closed club.", "order": 17792, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 6, "scene": 23, "line": "The Finer Things Club is the most exclusive club in this office. Naturally, it's where I need to be. The party planning committee is my back up, and Kevin's band is my safety.", "order": 17793, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 6, "scene": 24, "line": "Fine. I'll just sit here, in the common area, and read my book. Which just happens to be the very same book you're reading.", "order": 17794, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 6, "scene": 24, "line": "Alright, just know that you're not in the Finer Things Club.", "order": 17795, "speaker": "Toby Flenderson"},
  {"season": 4, "episode": 6, "scene": 24, "line": "Why can't I be in the club?", "order": 17796, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 6, "scene": 25, "line": "I can't believe we're here, I can't believe this is happening.", "order": 17797, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 6, "scene": 25, "line": "Believe it.", "order": 17798, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 6, "scene": 25, "line": "Afro wig, do you want the afro wig?", "order": 17799, "speaker": "Michael Scott"},
  {"season": 4, "episode": 6, "scene": 25, "line": "No, Michael. I'm not leaving the car.", "order": 17800, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 6, "scene": 25, "line": "Yeah, Dwight, here's how it's going to go down. You and I-", "order": 17801, "speaker": "Michael Scott"},
  {"season": 4, "episode": 6, "scene": 25, "line": "Guy's going by. Shh.", "order": 17802, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 6, "scene": 25, "line": "You and I are going to sneak inside and pretend that we are warehouse workers. And then we will silly string the beejeezus out of the place.", "order": 17803, "speaker": "Michael Scott"},
  {"season": 4, "episode": 6, "scene": 25, "line": "And if we have to defend ourselves, I will stab the security guy in the eye with the jumbo chalk.", "order": 17804, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 6, "scene": 25, "line": "No, no, you won't do that. Nope.", "order": 17805, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 6, "scene": 25, "line": "Then I'll grind up the jumbo chalk and blow it in his eyes.", "order": 17806, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 6, "scene": 25, "line": "Dwight, nothing with the eyes, please?", "order": 17807, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 6, "scene": 25, "line": "Okay, Jim.", "order": 17808, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 6, "scene": 25, "line": "Oh my God, that's her! That's her, go, go, go.", "order": 17809, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 6, "scene": 25, "line": "Let's move! Move, move, move, move!", "order": 17810, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 6, "scene": 25, "line": "Shut up, you, shut up! This is the dumbest thing we've ever done.", "order": 17811, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 6, "scene": 26, "line": "I think it's interesting how Forster uses Italy to represent sexuality and passion. [Kevin dumping change in the snack machine] And that also brought up themes of, uh, you know, fate and coincidence. And Lucy's torn between these two things. She's torn between passion and convention.", "order": 17812, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 6, "scene": 26, "line": "Damn it!", "order": 17813, "speaker": "Kevin Malone"},
  {"season": 4, "episode": 6, "scene": 26, "line": "It just- [Kevin banging and shaking the snack machine] To be making a case for passion in Edwardian times, I thought was, uh...", "order": 17814, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 6, "scene": 26, "line": "Yes.", "order": 17815, "speaker": "Oscar Martinez"},
  {"season": 4, "episode": 6, "scene": 26, "line": "Damn it! [banging machine]", "order": 17816, "speaker": "Kevin Malone"},
  {"season": 4, "episode": 6, "scene": 26, "line": "Maybe sort of ahead of his time as a writer.", "order": 17817, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 6, "scene": 26, "line": "Very brave. [Phyllis beeping microwave in the background]", "order": 17818, "speaker": "Oscar Martinez"},
  {"season": 4, "episode": 6, "scene": 26, "line": "Very brave choice also, I thought.", "order": 17819, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 6, "scene": 26, "line": "And George, his- his youthfulness. His, um, freedom. [Phyllis still beeping the microwave] Evokes, um, um, feeling, um, to the, um... What are you microwaving!?", "order": 17820, "speaker": "Oscar Martinez"},
  {"season": 4, "episode": 6, "scene": 26, "line": "Popcorn.", "order": 17821, "speaker": "Phyllis Vance"},
  {"season": 4, "episode": 6, "scene": 26, "line": "Why don't you use the microwave in the kitchen, Phyllis?", "order": 17822, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 6, "scene": 26, "line": "Someone needs to clean it. It smells like popcorn.", "order": 17823, "speaker": "Phyllis Vance"},
  {"season": 4, "episode": 6, "scene": 27, "line": "So, Stanley, are you really outta here?", "order": 17824, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 6, "scene": 27, "line": "Yep, looks that way.", "order": 17825, "speaker": "Stanley Hudson"},
  {"season": 4, "episode": 6, "scene": 27, "line": "I'm gonna miss you man, you've been like an uncle to me. Like a kind old uncle Remus. I wanna stay in touch.", "order": 17826, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 6, "scene": 28, "line": "[from the walkie-talkie] We are in a stairwell.", "order": 17827, "speaker": "Michael Scott"},
  {"season": 4, "episode": 6, "scene": 28, "line": "[from the walkie-talkie] We are climbing some stairs.", "order": 17828, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 6, "scene": 28, "line": "I'm breathing heavily.", "order": 17829, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 6, "scene": 28, "line": "Okay, you know what? You don't need to be updating me as much as you're updating me.", "order": 17830, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 6, "scene": 28, "line": "Well...", "order": 17831, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 6, "scene": 28, "line": "There's a guy, there's a guy.", "order": 17832, "speaker": "Michael Scott"},
  {"season": 4, "episode": 6, "scene": 28, "line": "There's a security guard coming by. Hello, we're warehouse workers. Would you like more proof?", "order": 17833, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 6, "scene": 28, "line": "Oh my God, oh my God, that was very close.", "order": 17834, "speaker": "Michael Scott"},
  {"season": 4, "episode": 6, "scene": 28, "line": "I can see the security guard's eyes.", "order": 17835, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 6, "scene": 28, "line": "No, no, don't do anything to them.", "order": 17836, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 6, "scene": 28, "line": "I have to do something to his eyes.", "order": 17837, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 6, "scene": 29, "line": "The eyes are the groin of the head.", "order": 17838, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 6, "scene": 30, "line": "Alright, Great Scott, if you found that choking hazard poster, just head on home.", "order": 17839, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 6, "scene": 30, "line": "We found something far better, their crown jewel. Their industrial copier.", "order": 17840, "speaker": "Michael Scott"},
  {"season": 4, "episode": 6, "scene": 30, "line": "Isn't that thing huge?", "order": 17841, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 6, "scene": 30, "line": "It's enormous, but it's got wheels. We're wheeling it down the hall, into the stairwell. Get the car ready, keep the engine running.", "order": 17842, "speaker": "Michael Scott"},
  {"season": 4, "episode": 6, "scene": 30, "line": "No, that is a terrible idea, don't do this.", "order": 17843, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 6, "scene": 30, "line": "Aha! [crashing]", "order": 17844, "speaker": "Michael Scott"},
  {"season": 4, "episode": 6, "scene": 30, "line": "My hip bone!", "order": 17845, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 6, "scene": 30, "line": "We're wedged between the copier and the railing. Ah! Ow! Ow, my leg! Jim leave us.", "order": 17846, "speaker": "Michael Scott"},
  {"season": 4, "episode": 6, "scene": 30, "line": "Don't leave us!", "order": 17847, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 6, "scene": 30, "line": "Save yourself!", "order": 17848, "speaker": "Michael Scott"},
  {"season": 4, "episode": 6, "scene": 30, "line": "Don't leave us, help us, we need help Jim!", "order": 17849, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 6, "scene": 30, "line": "Okay! First of all, stop using my name. And second of all-", "order": 17850, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 6, "scene": 30, "line": "Dwight, you gotta move!", "order": 17851, "speaker": "Michael Scott"},
  {"season": 4, "episode": 6, "scene": 30, "line": "Damn it guys!", "order": 17852, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 6, "scene": 30, "line": "Dwight, could you move over a little bit.", "order": 17853, "speaker": "Michael Scott"},
  {"season": 4, "episode": 6, "scene": 30, "line": "I'm losing control of my bladder.", "order": 17854, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 6, "scene": 30, "line": "Oh my God, oh my God, Karen is back!", "order": 17855, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 6, "scene": 30, "line": "Did you say Karen?", "order": 17856, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 6, "scene": 30, "line": "Take her to a motel, make love to her Jim.", "order": 17857, "speaker": "Michael Scott"},
  {"season": 4, "episode": 6, "scene": 30, "line": "No, I'm not doing that.", "order": 17858, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 6, "scene": 30, "line": "Just say you wanna get back together.", "order": 17859, "speaker": "Michael Scott"},
  {"season": 4, "episode": 6, "scene": 30, "line": "No, I'm not doing that!", "order": 17860, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 6, "scene": 30, "line": "It doesn't have to mean anything. Just, do it for Stanley. Come on Jim, just climb on top of her and think about Stanley. Ah, Jim, if this is it for me, promise me something, host the Dundies.", "order": 17861, "speaker": "Michael Scott"},
  {"season": 4, "episode": 6, "scene": 30, "line": "Jim?", "order": 17862, "speaker": "Karen Filippelli"},
  {"season": 4, "episode": 6, "scene": 30, "line": "Hey Karen.", "order": 17863, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 6, "scene": 31, "line": "Uh, what are you doing here?", "order": 17864, "speaker": "Karen Filippelli"},
  {"season": 4, "episode": 6, "scene": 31, "line": "First of all, hi.", "order": 17865, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 6, "scene": 31, "line": "What are you doing here?", "order": 17866, "speaker": "Karen Filippelli"},
  {"season": 4, "episode": 6, "scene": 31, "line": "You good? I'm just checking on the other branches. Michael wants me to do that from time to time, so...", "order": 17867, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 6, "scene": 31, "line": "Do not tell Karen about the industrial copier.", "order": 17868, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 6, "scene": 31, "line": "Copy that.", "order": 17869, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 6, "scene": 32, "line": "I cried for weeks over that guy, so yeah, seeing him climb out of a PT Cruiser in a ladies warehouse uniform, felt pretty good.", "order": 17870, "speaker": "Karen Filippelli"},
  {"season": 4, "episode": 6, "scene": 33, "line": "Let me ask you, did you accomplish what you wanted?", "order": 17871, "speaker": "Karen Filippelli"},
  {"season": 4, "episode": 6, "scene": 33, "line": "Listen lady, you can expect these kind of repercussions as long as you keep trying to poach our people.", "order": 17872, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 6, "scene": 33, "line": "I'm taking Stanley.", "order": 17873, "speaker": "Karen Filippelli"},
  {"season": 4, "episode": 6, "scene": 33, "line": "Then we will burn Utica to the ground.", "order": 17874, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 6, "scene": 33, "line": "[whispers] Dwight. Granted, maybe this was not the best idea, but at least we care enough about our employees that we are willing to fight for them. And if you so much as harm a hair on Stanley's head, we will burn Utica to the ground. [they get up to leave]", "order": 17875, "speaker": "Michael Scott"},
  {"season": 4, "episode": 6, "scene": 33, "line": "Jim, hang on a second?", "order": 17876, "speaker": "Karen Filippelli"},
  {"season": 4, "episode": 6, "scene": 33, "line": "Yes?", "order": 17877, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 6, "scene": 33, "line": "So you're still doing this kind of stuff, huh?", "order": 17878, "speaker": "Karen Filippelli"},
  {"season": 4, "episode": 6, "scene": 33, "line": "Yeah, trying to quit though.", "order": 17879, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 6, "scene": 33, "line": "If you wanted to see me, you could have just called me like an adult.", "order": 17880, "speaker": "Karen Filippelli"},
  {"season": 4, "episode": 6, "scene": 33, "line": "Oh no, I didn't want to see you. Not that I'm not happy seeing you, right now, I'm just saying ultimately I was here for the copier. Equal, I'd say it was equal. So, good to see you...", "order": 17881, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 6, "scene": 33, "line": "[huffs]", "order": 17882, "speaker": "Karen Filippelli"},
  {"season": 4, "episode": 6, "scene": 33, "line": "I mean, it's just that- [clears throat] You know, Pam and I are still dating, so, and, I just mean that things are going really well, so I didn't want to see... you...", "order": 17883, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 6, "scene": 33, "line": "Oh, things are going really well? Are they? They are? That's great, that's so great. I wanna hear more about how happy you are with Pam. Can you tell me more about that? Thank you so much for coming to Utica, and breaking my copier, and telling me how well things are going in your relationship, really, thank you.", "order": 17884, "speaker": "Karen Filippelli"},
  {"season": 4, "episode": 6, "scene": 33, "line": "[slinking out of the room] Alright, you are welcome. I'm going to go because of, um, traffic.", "order": 17885, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 6, "scene": 33, "line": "Traffic, yeah. Oh, def- go, go because of traffic, definitely, beat-beat the traffic, mm-hmm.", "order": 17886, "speaker": "Karen Filippelli"},
  {"season": 4, "episode": 6, "scene": 33, "line": "I... will... [gives up and walks away]", "order": 17887, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 6, "scene": 34, "line": "We tried and we failed. Stanley, you may go. Here is a box, for your things, but I doubt that that box will be able to contain all the memories that you have of this place. Fly away sweet little bird, fly away and be free. Pam, I'm gonna need some help writing a want ad.", "order": 17888, "speaker": "Michael Scott"},
  {"season": 4, "episode": 6, "scene": 35, "line": "Um, wanted: middle aged black man with sass. Big butt, bigger heart. [knock on the door] I can't do this.", "order": 17889, "speaker": "Michael Scott"},
  {"season": 4, "episode": 6, "scene": 35, "line": "Michael? A word?", "order": 17890, "speaker": "Stanley Hudson"},
  {"season": 4, "episode": 6, "scene": 35, "line": "Of course.", "order": 17891, "speaker": "Michael Scott"},
  {"season": 4, "episode": 6, "scene": 35, "line": "I changed my mind, I wanna keep this job.", "order": 17892, "speaker": "Stanley Hudson"},
  {"season": 4, "episode": 6, "scene": 35, "line": "Really?", "order": 17893, "speaker": "Michael Scott"},
  {"season": 4, "episode": 6, "scene": 36, "line": "I wasn't really planning on leaving, all I wanted was a raise. How on Earth did Michael call my bluff? Is he some sort of secret genius? [laughs] Sometimes I say crazy things.", "order": 17894, "speaker": "Stanley Hudson"},
  {"season": 4, "episode": 6, "scene": 37, "line": "Hey.", "order": 17895, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 6, "scene": 37, "line": "I'm so sorry, Rolando told me everything. How humiliating. Would it help you to return to another age? A time of refinement, and civility.", "order": 17896, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 6, "scene": 37, "line": "Are you inviting me to the Finer Things Club?", "order": 17897, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 6, "scene": 37, "line": "[nods head yes]", "order": 17898, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 6, "scene": 37, "line": "Oh-oh, come on!", "order": 17899, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 6, "scene": 38, "line": "[Irish accent] Angela's Ashes, top o' the morning to us. Frankie's prose is funnier than a pot of gold, say I.", "order": 17900, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 6, "scene": 38, "line": "Okay, did you get it out of your system.", "order": 17901, "speaker": "Oscar Martinez"},
  {"season": 4, "episode": 6, "scene": 38, "line": "Yep. No, I mean I really liked it. I thought was uh, a fun read.", "order": 17902, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 6, "scene": 38, "line": "Fun?", "order": 17903, "speaker": "Toby Flenderson"},
  {"season": 4, "episode": 6, "scene": 38, "line": "Mm-hmm.", "order": 17904, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 6, "scene": 38, "line": "Really?", "order": 17905, "speaker": "Toby Flenderson"},
  {"season": 4, "episode": 6, "scene": 38, "line": "Yeah.", "order": 17906, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 6, "scene": 38, "line": "What was fun about it for you? Was it the death of the twins?", "order": 17907, "speaker": "Toby Flenderson"},
  {"season": 4, "episode": 6, "scene": 38, "line": "No, that wasn't fun.", "order": 17908, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 6, "scene": 38, "line": "Did you even read it?", "order": 17909, "speaker": "Toby Flenderson"},
  {"season": 4, "episode": 6, "scene": 38, "line": "Of course I read it.", "order": 17910, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 6, "scene": 38, "line": "How does it end?", "order": 17911, "speaker": "Oscar Martinez"},
  {"season": 4, "episode": 6, "scene": 38, "line": "Who was the main character?", "order": 17912, "speaker": "Toby Flenderson"},
  {"season": 4, "episode": 6, "scene": 38, "line": "Angela. Nope. The ashes.", "order": 17913, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 6, "scene": 38, "line": "[mouths 'I'm sorry' to Oscar]", "order": 17914, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 6, "scene": 39, "line": "And let me just add, that as a member of The Finer Things Club, I would bring a strong financial contribution to any discretionary funds that we may have. Finally, here is a recommendation from former U.S. Senator Rick Santorum.", "order": 17915, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 6, "scene": 39, "line": "Okay, that's enough, uh. Thanks, Andy.", "order": 17916, "speaker": "Oscar Martinez"},
  {"season": 4, "episode": 6, "scene": 39, "line": "Mmmhmm. [leaves]", "order": 17917, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 6, "scene": 39, "line": "He's gonna ruin everything.", "order": 17918, "speaker": "Toby Flenderson"},
  {"season": 4, "episode": 6, "scene": 39, "line": "Oh, my God. His letter of recommendation from Rick Santorum is three fifty-dollar bills.", "order": 17919, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 6, "scene": 39, "line": "Wow. Now we can afford hard-cover books.", "order": 17920, "speaker": "Oscar Martinez"},
  {"season": 4, "episode": 6, "scene": 39, "line": "I like it just us three. [very quietly] I don't want it to change.", "order": 17921, "speaker": "Toby Flenderson"},
  {"season": 4, "episode": 6, "scene": 40, "line": "I love The Finer Things Club. My ex-wife used to have a book club, and I would read their book and sometimes listen from the kitchen.", "order": 17922, "speaker": "Toby Flenderson"},
  {"season": 4, "episode": 6, "scene": 41, "line": "The Finer Things Club was not sanctioned by the Party Planning Committee. Renegade clubs are dangerous. I squashed the Weight Loss Buddy Support Group. They didn't need to gather. It was just gross.", "order": 17923, "speaker": "Angela Martin"},
  {"season": 4, "episode": 6, "scene": 42, "line": "[slurping her from her big cup] I don't know why I'm not in The Finer Arts Club [slurring]. It's bullcrap. [throws her cup on the floor, something splashes on the camera] You clean it up.", "order": 17924, "speaker": "Meredith Palmer"},
  {"season": 4, "episode": 6, "scene": 43, "line": "[opens envelope Toby has handed him] Dear Andrew Bernard, after carefully examining your application to The Finer Things Club, we are unable to offer you a position at this place and time. We would, however, like to place you on a list in case an opportunity arises when your inclusion can be tolerated. Your position as an ongoing financial patron, however, is yours to cherish. Sincerely, The Finer Things Club. [pauses to consider] Yeees! [to Phyllis] I got waitlisted.", "order": 17925, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 6, "scene": 44, "line": "I got into Cornell off the Wait List. A lot of people were like, 'Oh, you just got into Cornell because your dad donated a building.' No. Okay. I got into Cornell because I'm smart. I'm smart enough to have a dad who donates buildings to things.", "order": 17926, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 6, "scene": 45, "line": "Utica is snoozeville, um, Albany are the prepsters, Nashua, no parking, um, Akron is haunted, Camden... is in a basement, Yonkers has the two hot girls, and here in Scranton, we are the cool guys.", "order": 17927, "speaker": "Michael Scott"},
  {"season": 4, "episode": 6, "scene": 46, "line": "Woo! Shotgun!", "order": 17928, "speaker": "Michael Scott"},
  {"season": 4, "episode": 6, "scene": 46, "line": "Well, you're driving, right?", "order": 17929, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 6, "scene": 46, "line": "Yes, I am, but I want shotgun for you.", "order": 17930, "speaker": "Michael Scott"},
  {"season": 4, "episode": 6, "scene": 46, "line": "Mmm... I'd prefer to sit in back.", "order": 17931, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 6, "scene": 46, "line": "Wait! I wanted to sit next to you.", "order": 17932, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 6, "scene": 46, "line": "No, Dwight! Dwight, Jim is sitting next to me. You're gonna sit in the back left where I don't have to see your ugly moongob.", "order": 17933, "speaker": "Michael Scott"},
  {"season": 4, "episode": 6, "scene": 46, "line": "Okay, that is so mean! You know what, I'm not going.", "order": 17934, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 6, "scene": 46, "line": "I'm sorry. I'm sorry. Dwight, you're handsome. You're a very handsome man, get in the car.", "order": 17935, "speaker": "Michael Scott"},
  {"season": 4, "episode": 6, "scene": 46, "line": "Woohoo!", "order": 17936, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 6, "scene": 47, "line": "[on phone] So, there was no sales call.", "order": 17937, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 6, "scene": 47, "line": "Nnnno. Uh, turns out, it was just a really bad idea involving fire. But I think I fixed it.", "order": 17938, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 6, "scene": 47, "line": "Wait, you're going along with this now?", "order": 17939, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 6, "scene": 47, "line": "I have to. Pam, if I'm not there, someone's going to go to jail. Or die.", "order": 17940, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 6, "scene": 47, "line": "Right. And, you wouldn't be able to talk to Karen.", "order": 17941, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 6, "scene": 47, "line": "Well, I promise you that has nothing to do with it.", "order": 17942, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 6, "scene": 47, "line": "A little bit.", "order": 17943, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 6, "scene": 47, "line": "Well, yeah I don't want to see her get physically harmed, that's for sure.", "order": 17944, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 6, "scene": 47, "line": "Because you love her? [baby voice] Because you love her very much?", "order": 17945, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 6, "scene": 47, "line": "All right, I'm gonna go now.", "order": 17946, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 6, "scene": 47, "line": "Okay. Have fun with your girlfriend!", "order": 17947, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 6, "scene": 47, "line": "Okay, I will. [idiot voice]", "order": 17948, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 6, "scene": 48, "line": "I'm kidding around. We joke about that stuff all the time. I'm not really the jealous type, so, I don't care if Jim sees Karen. I care a little.", "order": 17949, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 6, "scene": 49, "line": "They sprayed me in the eye. [Dwight and Michael are trapped by/under a copier in the stairwell]", "order": 17950, "speaker": "Security guard"},
  {"season": 4, "episode": 6, "scene": 49, "line": "[moans] Scranton rules! [sprays Silly Spray]", "order": 17951, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 6, "scene": 49, "line": "Dwight! Stop it! [moans] Can you help me please, I'm being crushed.", "order": 17952, "speaker": "Michael Scott"},
  {"season": 4, "episode": 6, "scene": 50, "line": "Would it have killed you to spend the afternoon making love to her in a motel room? Like I begged you to? Pam would have understood. Heck, Pam would have done it.", "order": 17953, "speaker": "Michael Scott"},
  {"season": 4, "episode": 6, "scene": 50, "line": "Pam is down for anything.", "order": 17954, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 6, "scene": 50, "line": "You embarrassed me.", "order": 17955, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 6, "scene": 50, "line": "You embarrassed us.", "order": 17956, "speaker": "Michael Scott"},
  {"season": 4, "episode": 6, "scene": 50, "line": "Yeah. We shoulda brought Andy. I cut a chunk out of my penis for nothing.", "order": 17957, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 7, "scene": 1, "line": "I really didn't think I was going to have a good time, but I did. Totally did.", "order": 17958, "speaker": "Toby Flenderson"},
  {"season": 4, "episode": 7, "scene": 1, "line": "I love camping. [in a singing voice] Anything can happen.", "order": 17959, "speaker": "Meredith Palmer"},
  {"season": 4, "episode": 7, "scene": 1, "line": "Oh, it wasn't camping, it was more of a wilderness retreat. [Michael walks in]", "order": 17960, "speaker": "Toby Flenderson"},
  {"season": 4, "episode": 7, "scene": 1, "line": "Morning.", "order": 17961, "speaker": "Michael Scott"},
  {"season": 4, "episode": 7, "scene": 1, "line": "Michael.", "order": 17962, "speaker": "Toby Flenderson"},
  {"season": 4, "episode": 7, "scene": 2, "line": "Ryan invited some of the branch managers and Toby into the woods for a 'get to know you' weekend. Michael wasn't invited. Apparently they already knew everything they needed to know about him.", "order": 17963, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 7, "scene": 3, "line": "Did you sleep in cabins?", "order": 17964, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 7, "scene": 3, "line": "Under the stars. It was really beautiful, you should come.", "order": 17965, "speaker": "Toby Flenderson"},
  {"season": 4, "episode": 7, "scene": 3, "line": "Bob and I took rock climbing lessons once. [Michael laughs]", "order": 17966, "speaker": "Phyllis Vance"},
  {"season": 4, "episode": 7, "scene": 4, "line": "Michael wasn't invited on Ryan's camping trip. Toby went, but Michael didn't go. He wasn't invited.", "order": 17967, "speaker": "Phyllis Vance"},
  {"season": 4, "episode": 7, "scene": 5, "line": "Who went?", "order": 17968, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 7, "scene": 5, "line": "Me, Dan from Buffalo, Mark Chisholm, Jeff from Albany and Ryan, obviously. Made so many s'mores, that I finally had to say, 'No more s'mores, no more s'mores.' [everyone in room but Michael laughs, Michael exits break room]", "order": 17969, "speaker": "Toby Flenderson"},
  {"season": 4, "episode": 7, "scene": 6, "line": "Ryan invited me to go on his wilderness adventure retreat. It was this amazing, beautiful experience [Michael knocks on the window behind Toby from outside]", "order": 17970, "speaker": "Toby Flenderson"},
  {"season": 4, "episode": 7, "scene": 6, "line": "[from outside] Hey, nobody cares. Nobody cares. I need that room at some point, so just, wrap it up.", "order": 17971, "speaker": "Michael Scott"},
  {"season": 4, "episode": 7, "scene": 6, "line": "Michael wasn't invited.", "order": 17972, "speaker": "Toby Flenderson"},
  {"season": 4, "episode": 7, "scene": 7, "line": "Hypothetically, if I were to ask you to go camping, and you know what hypothetical means? [Jim makes a face] Not real.", "order": 17973, "speaker": "Michael Scott"},
  {"season": 4, "episode": 7, "scene": 7, "line": "Got it.", "order": 17974, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 7, "scene": 7, "line": "So if I were to hypothetically ask you to go camping with me, would you go?", "order": 17975, "speaker": "Michael Scott"},
  {"season": 4, "episode": 7, "scene": 7, "line": "Absolutely, yes.", "order": 17976, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 7, "scene": 8, "line": "When Michael plays the hypothetical game, I always say yes.", "order": 17977, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 7, "scene": 9, "line": "Really?", "order": 17978, "speaker": "Michael Scott"},
  {"season": 4, "episode": 7, "scene": 9, "line": "Yeah?", "order": 17979, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 7, "scene": 9, "line": "Oh, you wanna go today?", "order": 17980, "speaker": "Michael Scott"},
  {"season": 4, "episode": 7, "scene": 10, "line": "And I am always busy.", "order": 17981, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 7, "scene": 11, "line": "Oh, can't go today, 'cause I'm donating blood.", "order": 17982, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 7, "scene": 11, "line": "How often can you actually donate blood?", "order": 17983, "speaker": "Michael Scott"},
  {"season": 4, "episode": 7, "scene": 11, "line": "Is there a limit?", "order": 17984, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 7, "scene": 11, "line": "Your body only has a certain amount.", "order": 17985, "speaker": "Michael Scott"},
  {"season": 4, "episode": 7, "scene": 11, "line": "Well, is that it? Or?", "order": 17986, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 7, "scene": 11, "line": "Yeah, just this whole Toby, camping thing, uh, seems a little lame.", "order": 17987, "speaker": "Michael Scott"},
  {"season": 4, "episode": 7, "scene": 11, "line": "How so?", "order": 17988, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 7, "scene": 11, "line": "A bunch of guys, in a tent? Making s'mores? [makes a noise and gestures with his hands]", "order": 17989, "speaker": "Michael Scott"},
  {"season": 4, "episode": 7, "scene": 11, "line": "What's that?", "order": 17990, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 7, "scene": 11, "line": "Hello, I'm Broken Mountain. You know, here's the thing. That's not how you go camping. I think you go camping by yourself---", "order": 17991, "speaker": "Michael Scott"},
  {"season": 4, "episode": 7, "scene": 11, "line": "Right.", "order": 17992, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 7, "scene": 11, "line": "In the wilderness. It's not with a group of guys frolicking around in tents. It's one guy, or two guys, if your plans change.", "order": 17993, "speaker": "Michael Scott"},
  {"season": 4, "episode": 7, "scene": 11, "line": "Not gonna change.", "order": 17994, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 7, "scene": 11, "line": "I wanna do it myself. You know, I want to go and, and find out something about myself, I wanna get outta here. All the cliques, and the office politics. Fluorescent lights. Asbestos.", "order": 17995, "speaker": "Michael Scott"},
  {"season": 4, "episode": 7, "scene": 11, "line": "I thought we had that looked at.", "order": 17996, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 7, "scene": 11, "line": "I'm sick of it Jim. I'm sick of this place.", "order": 17997, "speaker": "Michael Scott"},
  {"season": 4, "episode": 7, "scene": 12, "line": "When Jan and I had satellite, we used to watch a reality show called 'Survivorman.' And, it was interesting because it was about a guy who would go out in the middle of no where and just try not to die, try not to get eaten by an animal, or be overexposed.", "order": 17998, "speaker": "Michael Scott"},
  {"season": 4, "episode": 7, "scene": 13, "line": "OK, I will only need two things. Roll of duct tape and a knife.", "order": 17999, "speaker": "Michael Scott"},
  {"season": 4, "episode": 7, "scene": 13, "line": "[winks] I'm on it. [leaves room]", "order": 18000, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 7, "scene": 13, "line": "OK. Thirty minutes or less, please come back. Save the receipts. [Dwight comes back with set of knives] Hey, what...", "order": 18001, "speaker": "Michael Scott"},
  {"season": 4, "episode": 7, "scene": 13, "line": "Let's see if any of these will work. [clears the front of Michael's desk]", "order": 18002, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 7, "scene": 13, "line": "Hey, hey hey! Dwight. [Dwight rolls out an assortment knives]. Dwight.", "order": 18003, "speaker": "Michael Scott"},
  {"season": 4, "episode": 7, "scene": 14, "line": "I keep various weaponries strategically placed around the office. I saved Jim's life with a can of pepper spray I had velcroed under my desk. People say, 'Oh, it's dangerous to keep weapons in the home or the work place.' Well I say, 'It's better to be hurt by someone you know accidentally, than by a stranger on purpose.'", "order": 18004, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 7, "scene": 15, "line": "Dwight and I are going out. He will return later, but I will not. I will also be taking a personal day tomorrow, and perhaps the next day.", "order": 18005, "speaker": "Michael Scott"},
  {"season": 4, "episode": 7, "scene": 15, "line": "Do you want me to ask where you're going?", "order": 18006, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 7, "scene": 15, "line": "No.", "order": 18007, "speaker": "Michael Scott"},
  {"season": 4, "episode": 7, "scene": 15, "line": "Great.", "order": 18008, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 7, "scene": 15, "line": "Dwight will be driving me deep into the Pennsylvania wilderness.", "order": 18009, "speaker": "Michael Scott"},
  {"season": 4, "episode": 7, "scene": 15, "line": "Oh.", "order": 18010, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 7, "scene": 15, "line": "Where he will then leave me to either die or to survive. The choice is yours.", "order": 18011, "speaker": "Michael Scott"},
  {"season": 4, "episode": 7, "scene": 15, "line": "Hmm, no, the choice is actually yours. Are you sure you want to do this?", "order": 18012, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 7, "scene": 15, "line": "Yes, and I am leaving you in charge of the office for the rest of the day and for the next several days. Do not try to follow me.", "order": 18013, "speaker": "Michael Scott"},
  {"season": 4, "episode": 7, "scene": 15, "line": "OK, great.", "order": 18014, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 7, "scene": 15, "line": "This is a very personal, private experience in the wild, that I wish to share it with me, myself and I.", "order": 18015, "speaker": "Michael Scott"},
  {"season": 4, "episode": 7, "scene": 15, "line": "Yup.", "order": 18016, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 7, "scene": 15, "line": "When I return, I hope to be a completely changed human being.", "order": 18017, "speaker": "Michael Scott"},
  {"season": 4, "episode": 7, "scene": 15, "line": "That'd be great.", "order": 18018, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 7, "scene": 16, "line": "Do I believe that Michael possesses the skills to survive in a hostile environment? Let's put it this way: no, I do not.", "order": 18019, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 7, "scene": 17, "line": "This is what a true survivor man does. You simulate a disaster, like a plane wreck. You could only wear the clothes that you have on, and you could only use the stuff you have in your pockets. Now, in this case, this disaster is a serial killer. Creepy guy who's abducted me and is taking me out into the wilderness to leave me for dead.", "order": 18020, "speaker": "Michael Scott"},
  {"season": 4, "episode": 7, "scene": 17, "line": "No, I would never leave you for dead. You would never escape.", "order": 18021, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 7, "scene": 17, "line": "Well, yes I would. And I would survive.", "order": 18022, "speaker": "Michael Scott"},
  {"season": 4, "episode": 7, "scene": 17, "line": "I would make sure that you were dead.", "order": 18023, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 7, "scene": 17, "line": "Well...", "order": 18024, "speaker": "Michael Scott"},
  {"season": 4, "episode": 7, "scene": 17, "line": "First, I would remove your teeth and cut off your fingertips so you could not be identified.", "order": 18025, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 7, "scene": 17, "line": "You...", "order": 18026, "speaker": "Michael Scott"},
  {"season": 4, "episode": 7, "scene": 17, "line": "And they would call me the Overkill Killer.", "order": 18027, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 7, "scene": 17, "line": "You... you are as creepy as a real serial killer. For real. [blindfolds eyes with tie] OK.", "order": 18028, "speaker": "Michael Scott"},
  {"season": 4, "episode": 7, "scene": 17, "line": "What are you doing?", "order": 18029, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 7, "scene": 17, "line": "I am putting this on so I have no familiarity with my surroundings. Now this way, I can't retrace my steps. I don't know what streets we've been--- [Dwight whacks Michael with his shoe] Ow! What are you doing?", "order": 18030, "speaker": "Michael Scott"},
  {"season": 4, "episode": 7, "scene": 17, "line": "It would be better if you were unconscious.", "order": 18031, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 7, "scene": 17, "line": "No! Gosh! Dwight. [Dwight tries to hit Michael with his shoe again] Stop it, stop it! Stop it.", "order": 18032, "speaker": "Michael Scott"},
  {"season": 4, "episode": 7, "scene": 17, "line": "Do you want to do this right or not?", "order": 18033, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 7, "scene": 17, "line": "Just, please allow me to have one cathartic experience in my life?", "order": 18034, "speaker": "Michael Scott"},
  {"season": 4, "episode": 7, "scene": 18, "line": "Jim, we need to order a cake for Creed's birthday.", "order": 18035, "speaker": "Angela Martin"},
  {"season": 4, "episode": 7, "scene": 18, "line": "Oh, wasn't it just someone's birthday?", "order": 18036, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 7, "scene": 18, "line": "Yes. Kelly's was last week, remember?", "order": 18037, "speaker": "Angela Martin"},
  {"season": 4, "episode": 7, "scene": 18, "line": "I do remember, yeah.", "order": 18038, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 7, "scene": 18, "line": "It's birthday month. Creed's is today. Oscar's is week after next. Meredith's is at the end of the month. [Jim exhales deeply] Michael usually goes with red and white streamers...", "order": 18039, "speaker": "Angela Martin"},
  {"season": 4, "episode": 7, "scene": 18, "line": "You know what, I have an idea. Why don't we just do one big shared party?", "order": 18040, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 7, "scene": 18, "line": "What?", "order": 18041, "speaker": "Angela Martin"},
  {"season": 4, "episode": 7, "scene": 19, "line": "There are thirteen people working in this office, so thirteen times a year Michael gets a cake and balloons, and some sort of joke gift and makes a toast. There are two types of toasts. One is a joke about how old you are.", "order": 18042, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 7, "scene": 20, "line": "[flashback to Stanley's birthday] Look at those wrinkles. Blacks do crack! Not crack the drug.", "order": 18043, "speaker": "Michael Scott"},
  {"season": 4, "episode": 7, "scene": 21, "line": "And the other is something inappropriate. Or horrible. Or both. What else? He only sings the high harmony to 'Happy Birthday.' And he's a very big believer in surprise parties. Maybe even, arguably, possibly to a fault.", "order": 18044, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 7, "scene": 22, "line": "[flashback to Kelly's birthday, hiding in the elevator] Happy Birthday!", "order": 18045, "speaker": "Michael Scott"},
  {"season": 4, "episode": 7, "scene": 22, "line": "Ahhh!! [drops the papers she was holding]", "order": 18046, "speaker": "Kelly Kapoor"},
  {"season": 4, "episode": 7, "scene": 23, "line": "[flashback to Oscar's birthday, hiding in the break room] Happy Birthday!", "order": 18047, "speaker": "Michael Scott"},
  {"season": 4, "episode": 7, "scene": 23, "line": "Ahhh! [runs into door and Michael laughs]", "order": 18048, "speaker": "Oscar Martinez"},
  {"season": 4, "episode": 7, "scene": 24, "line": "[flashback to Phyllis' birthday, sneaking up on Phyllis' car] Happy Birthday!", "order": 18049, "speaker": "Michael Scott"},
  {"season": 4, "episode": 7, "scene": 24, "line": "Oh! [Michael laughs]", "order": 18050, "speaker": "Phyllis Vance"},
  {"season": 4, "episode": 7, "scene": 25, "line": "So... I think, yeah, I think getting these out of the way might be productive.", "order": 18051, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 7, "scene": 26, "line": "We can just have one big fun party. Everybody's happy, nobody wastes their time.", "order": 18052, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 7, "scene": 26, "line": "I don't like it. [Angela leaves]", "order": 18053, "speaker": "Angela Martin"},
  {"season": 4, "episode": 7, "scene": 26, "line": "Wow! You're shaking things up a bit, huh?", "order": 18054, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 7, "scene": 26, "line": "It's a pretty good idea, don't you think?", "order": 18055, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 7, "scene": 26, "line": "Do you think it's a good idea?", "order": 18056, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 7, "scene": 26, "line": "No... I think it's a great idea.", "order": 18057, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 7, "scene": 26, "line": "[smiles] Hmm.", "order": 18058, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 7, "scene": 27, "line": "We're here. [gets out of the car]", "order": 18059, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 7, "scene": 27, "line": "Dwight will take my blindfold off when we are deep in the forest. Just the two of us from this point out.", "order": 18060, "speaker": "Michael Scott"},
  {"season": 4, "episode": 7, "scene": 27, "line": "[leading Michael from car] Here we go. Just the two of us [gestures for camera crew to follow]", "order": 18061, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 7, "scene": 28, "line": "Here we go, into the wild. Mighty forest. Can you smell the trees and the nature?", "order": 18062, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 7, "scene": 29, "line": "Keep going, you're fine. Just some bushes and some thickets [leads Michael into some tall grass] Keep going. You wanted wilderness, you got it.", "order": 18063, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 7, "scene": 30, "line": "You know, try sending them another invoice.", "order": 18064, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 7, "scene": 30, "line": "Ok.", "order": 18065, "speaker": "Oscar Martinez"},
  {"season": 4, "episode": 7, "scene": 30, "line": "Alright. [starts walking away, but returns] Oh, did you see my memo by the way?", "order": 18066, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 7, "scene": 30, "line": "[picks up and reads memo] 'Let's be honest, as fun as birthdays are we could all use a break from the constant cake, so let's celebrate birthday month in style today.' This is really cool.", "order": 18067, "speaker": "Oscar Martinez"},
  {"season": 4, "episode": 7, "scene": 30, "line": "Right? I was just thinking...", "order": 18068, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 7, "scene": 30, "line": "No, totally, totally. This way we get it all out of the way at once and it could actually be fun.", "order": 18069, "speaker": "Oscar Martinez"},
  {"season": 4, "episode": 7, "scene": 30, "line": "Right! Exactly. [pats Oscar on the back and walks away] Knew I could count on you. [Oscar rolls his eyes]", "order": 18070, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 7, "scene": 31, "line": "Good a spot as any. [They stop and Dwight starts spinning Michael around in circles]", "order": 18071, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 7, "scene": 31, "line": "What are you doing? Stop. Dwight, Dwight. Just --", "order": 18072, "speaker": "Michael Scott"},
  {"season": 4, "episode": 7, "scene": 31, "line": "I'm just --", "order": 18073, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 7, "scene": 31, "line": "Stop.", "order": 18074, "speaker": "Michael Scott"},
  {"season": 4, "episode": 7, "scene": 31, "line": "Spin. I'm trying to confuse your sense of direction. [stops spinning and takes Michael's blindfold off] Behold.", "order": 18075, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 7, "scene": 31, "line": "Alright, good. Thank you, Dwight.", "order": 18076, "speaker": "Michael Scott"},
  {"season": 4, "episode": 7, "scene": 31, "line": "Here's your knife. Here's your duct tape.", "order": 18077, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 7, "scene": 31, "line": "Alright, very good. Ahh, OK. [Dwight gives Michael a hug]", "order": 18078, "speaker": "Michael Scott"},
  {"season": 4, "episode": 7, "scene": 31, "line": "Good luck, Michael.", "order": 18079, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 7, "scene": 31, "line": "Thanks for the ride. OK. Leave me be, Dwight. [Dwight runs off]", "order": 18080, "speaker": "Michael Scott"},
  {"season": 4, "episode": 7, "scene": 32, "line": "Hey, Jim, can I have my own cake?", "order": 18081, "speaker": "Meredith Palmer"},
  {"season": 4, "episode": 7, "scene": 32, "line": "What's that?", "order": 18082, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 7, "scene": 32, "line": "I really prefer devils food cake.", "order": 18083, "speaker": "Meredith Palmer"},
  {"season": 4, "episode": 7, "scene": 32, "line": "Oh, sure.", "order": 18084, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 7, "scene": 32, "line": "Yes!", "order": 18085, "speaker": "Meredith Palmer"},
  {"season": 4, "episode": 7, "scene": 32, "line": "OK. [Meredith leaves]", "order": 18086, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 7, "scene": 32, "line": "Wow! That was easy.", "order": 18087, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 7, "scene": 32, "line": "Yeah, people like me I guess.", "order": 18088, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 7, "scene": 32, "line": "[knocking from outside window in break room] Jim.", "order": 18089, "speaker": "Creed Bratton"},
  {"season": 4, "episode": 7, "scene": 33, "line": "I hate devils food.", "order": 18090, "speaker": "Creed Bratton"},
  {"season": 4, "episode": 7, "scene": 33, "line": "Well I think Meredith was just --", "order": 18091, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 7, "scene": 33, "line": "Screw Meredith, I don't think it's fair to let someone else pick the cake on my birthday.", "order": 18092, "speaker": "Creed Bratton"},
  {"season": 4, "episode": 7, "scene": 33, "line": "Everybody's birthday.", "order": 18093, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 7, "scene": 33, "line": "Today is actually my birthday and I want to pick the cake.", "order": 18094, "speaker": "Creed Bratton"},
  {"season": 4, "episode": 7, "scene": 33, "line": "What do you want?", "order": 18095, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 7, "scene": 33, "line": "I want pie. I want peach pie.", "order": 18096, "speaker": "Creed Bratton"},
  {"season": 4, "episode": 7, "scene": 33, "line": "You want a birthday pie?", "order": 18097, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 7, "scene": 33, "line": "I want a nice cobbler.", "order": 18098, "speaker": "Creed Bratton"},
  {"season": 4, "episode": 7, "scene": 33, "line": "Well, I'm gonna to talk to Angela and we're gonna see what we can do about a pie.", "order": 18099, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 7, "scene": 33, "line": "I don't care who you talk to. Just make it happen.", "order": 18100, "speaker": "Creed Bratton"},
  {"season": 4, "episode": 7, "scene": 33, "line": "It will be Angela.", "order": 18101, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 7, "scene": 33, "line": "You tell her it's for Creed. She'll know what that means.", "order": 18102, "speaker": "Creed Bratton"},
  {"season": 4, "episode": 7, "scene": 34, "line": "[to own camcorder] Day One. I'm in the interior of the vast Pennsylvania wilderness. I've brought with me only the bare essentials. A knife, roll of duct tape, in case I need to craft some shelter or make some sort of water vessel. It's hot today. The sun is in the two-thirds easterly quadrant, which would make it about [looks at watch] 2 o'clock in the afternoon. It's really beating down on me now. I think that I want to get a little more comfortable because the sun is depleting my resources. [cuts pants with knife] OK. OH, there we go. Watch that I don't hit my corroded artery here...", "order": 18103, "speaker": "Michael Scott"},
  {"season": 4, "episode": 7, "scene": 35, "line": "I lied to Michael. I said that I would leave him alone, but I will not. I will remain close by to provide unseen moral support, but I will never help him. I will let harm befall him. I will even let him die. But I will never let him lose his dignity.", "order": 18104, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 7, "scene": 36, "line": "There we go. [standing with short sleeves and short pants] Much better. Now everything I brought with me can be used. My sport coat here, can be fashioned into a backpack of sorts [ties jacket around neck].", "order": 18105, "speaker": "Michael Scott"},
  {"season": 4, "episode": 7, "scene": 37, "line": "I'm going to wear what was once one of my pant legs. [puts pant leg on head like a hat]", "order": 18106, "speaker": "Michael Scott"},
  {"season": 4, "episode": 7, "scene": 38, "line": "See, this is a beautiful piece of material [rips other pant leg in half] This could be used for all sorts of things.", "order": 18107, "speaker": "Michael Scott"},
  {"season": 4, "episode": 7, "scene": 39, "line": "Some sort of kerchief to keep my neck nice and comfortable.", "order": 18108, "speaker": "Michael Scott"},
  {"season": 4, "episode": 7, "scene": 40, "line": "Tuna. [rolls in chair to Jim's desk]", "order": 18109, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 7, "scene": 40, "line": "Andy.", "order": 18110, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 7, "scene": 40, "line": "Me likey the ice cream cake, okay? Fudgie the Whale.", "order": 18111, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 7, "scene": 40, "line": "Not your birthday.", "order": 18112, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 7, "scene": 40, "line": "Well, I'm just saying, you know, if you want to make people happy, namely me, you will have a Fudgie the Whale.", "order": 18113, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 7, "scene": 40, "line": "Alright, I'll look into it, but the answer's no.", "order": 18114, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 7, "scene": 40, "line": "Wow, OK, harsh. Just don't expect me to show up.", "order": 18115, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 7, "scene": 40, "line": "Hey, Andy, I have some calls to make.", "order": 18116, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 7, "scene": 40, "line": "Loud and clear. [rolls in chair back to desk]", "order": 18117, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 7, "scene": 40, "line": "Alright. [Andy rolls back]", "order": 18118, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 7, "scene": 40, "line": "Pizza rolls.", "order": 18119, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 7, "scene": 40, "line": "OK, I'm gonna go into this office here [gets up and walks into Michael's office] to do some work. So I will be in here.", "order": 18120, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 7, "scene": 40, "line": "Mushroom caps.", "order": 18121, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 7, "scene": 41, "line": "I am totally alone right now, with only my thoughts. I love it. I'm loving it. I can literally say anything I want. No one is gonna here me. [screams] Wish I could have gone with Ryan on that cool retreat! Jan has plastic boobs! I HAVE HEMORRHOIDS! Doesn't even matter.", "order": 18122, "speaker": "Michael Scott"},
  {"season": 4, "episode": 7, "scene": 42, "line": "Michael is a man of great depth and passion. I don't know what he's searching for out here. [pulls a nest from a tree] I hope he finds it. [picks up some bird eggs] Lunch.", "order": 18123, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 7, "scene": 43, "line": "Well it is a little chillier than I had thought, so I have fashioned my hat back into my pants. Several hours in, time for me to find some nourishment. Now, these woods are full of creatures that can sustain human life. Things like, uh, squirrels, nice juicy rabbit would be delicious.", "order": 18124, "speaker": "Michael Scott"},
  {"season": 4, "episode": 7, "scene": 44, "line": "About two more minutes [cooking bird eggs].", "order": 18125, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 7, "scene": 45, "line": "I have made this spear, with which I will impale my dinner. And it couldn't come a moment too soon because I have been without food for a good three hours or so. I'm startin' to feel it a little bit. Ohh... It's Creed's birthday. [singing] Happy Birthday to you. Happy Birthday to you. Happy Birthday to Creed. Happy Birthday to you. Happy birthday, buddy.", "order": 18126, "speaker": "Michael Scott"},
  {"season": 4, "episode": 7, "scene": 46, "line": "What is Jim thinking? It's a birthday, so what if there's a lot of them?", "order": 18127, "speaker": "Meredith Palmer"},
  {"season": 4, "episode": 7, "scene": 46, "line": "Yeah, I work hard all day. I like knowing that there's going to be a break. Most days I just sit and wait for the break.", "order": 18128, "speaker": "Kevin Malone"},
  {"season": 4, "episode": 7, "scene": 46, "line": "I took an extra shot of insulin in preparation for this cake today. If I don't have some cake soon, I might die.", "order": 18129, "speaker": "Stanley Hudson"},
  {"season": 4, "episode": 7, "scene": 46, "line": "Why don't you just have an apple?", "order": 18130, "speaker": "Oscar Martinez"},
  {"season": 4, "episode": 7, "scene": 46, "line": "Why don't you mind your business?", "order": 18131, "speaker": "Stanley Hudson"},
  {"season": 4, "episode": 7, "scene": 46, "line": "Listen, I figured this out. Jimmy had his birthday three weeks ago, so he doesn't care.", "order": 18132, "speaker": "Creed Bratton"},
  {"season": 4, "episode": 7, "scene": 46, "line": "Probably went to his head.", "order": 18133, "speaker": "Oscar Martinez"},
  {"season": 4, "episode": 7, "scene": 46, "line": "Yeah. [Jim enters room]", "order": 18134, "speaker": "Creed Bratton"},
  {"season": 4, "episode": 7, "scene": 46, "line": "Hey, Jim.", "order": 18135, "speaker": "Oscar Martinez"},
  {"season": 4, "episode": 7, "scene": 46, "line": "Hey guys, what're we talking about?", "order": 18136, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 7, "scene": 46, "line": "Nothing! Nothing going on. We're talking about nothing. C'mon gang.", "order": 18137, "speaker": "Creed Bratton"},
  {"season": 4, "episode": 7, "scene": 47, "line": "Well if you take a look at this, I tented my pants. I've made myself a nice pants tent shelter. And this little guy [pats a large tree trunk] may be Dunder Mifflin paper some day.", "order": 18138, "speaker": "Michael Scott"},
  {"season": 4, "episode": 7, "scene": 48, "line": "Nothing to worry about. Just using the scope. Safety is... [clicks rifle into safety mode] on.", "order": 18139, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 7, "scene": 49, "line": "[knocks] Hey Jim.", "order": 18140, "speaker": "Toby Flenderson"},
  {"season": 4, "episode": 7, "scene": 49, "line": "Toby.", "order": 18141, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 7, "scene": 49, "line": "Hey, I just got the word on the communal birthdays. Great idea.", "order": 18142, "speaker": "Toby Flenderson"},
  {"season": 4, "episode": 7, "scene": 49, "line": "Oh, thanks man.", "order": 18143, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 7, "scene": 49, "line": "Yeah...", "order": 18144, "speaker": "Toby Flenderson"},
  {"season": 4, "episode": 7, "scene": 49, "line": "Is there anything --", "order": 18145, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 7, "scene": 49, "line": "My birthday was two months ago.", "order": 18146, "speaker": "Toby Flenderson"},
  {"season": 4, "episode": 7, "scene": 49, "line": "Oh, OK.", "order": 18147, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 7, "scene": 49, "line": "There was no party.", "order": 18148, "speaker": "Toby Flenderson"},
  {"season": 4, "episode": 7, "scene": 49, "line": "What?", "order": 18149, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 7, "scene": 49, "line": "Well, it... there was. But Michael scheduled it for 4:58 on a Friday. You know, people sang in the parking lot.", "order": 18150, "speaker": "Toby Flenderson"},
  {"season": 4, "episode": 7, "scene": 49, "line": "I remember that.", "order": 18151, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 7, "scene": 49, "line": "I don't know, I just thought you could include me.", "order": 18152, "speaker": "Toby Flenderson"},
  {"season": 4, "episode": 7, "scene": 49, "line": "Seriously?", "order": 18153, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 7, "scene": 49, "line": "I just though you could add me. I don't see the harm in that.", "order": 18154, "speaker": "Toby Flenderson"},
  {"season": 4, "episode": 7, "scene": 50, "line": "Toby's great. He's great, but sometimes he can be a little bit much. [in Toby voice] I don't see the harm in that. Well, it's a cake Toby, so, c'mon.", "order": 18155, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 7, "scene": 51, "line": "OK. Yeah, you know what, we're just gonna throw you in. Because more the merrier, right?", "order": 18156, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 7, "scene": 51, "line": "No. No way. I am not a machine, Jim. You can't just change plans willy nilly and expect these little magic party elves to do your bidding. We already have devils food, peach cobbler, Fudgie the Whale, mushroom caps.", "order": 18157, "speaker": "Angela Martin"},
  {"season": 4, "episode": 7, "scene": 51, "line": "I'm allergic to mushrooms.", "order": 18158, "speaker": "Toby Flenderson"},
  {"season": 4, "episode": 7, "scene": 51, "line": "That's a bummer. OK, then we need to...[steps outside office] Hey everybody. Hi, how you doing? Can I have your attention please? 'Cause we have to talk about this birthday thing.", "order": 18159, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 7, "scene": 51, "line": "[raises hand] Conference room?", "order": 18160, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 7, "scene": 51, "line": "Yes, conference room in five minutes... No. No. We're gonna solve it right here. We're actually gonna talk about it out here. So, who has problems with the birthday thing? [everyone raises hands] One, two, three, everybody. OK, so then we just shouldn't do it.", "order": 18161, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 7, "scene": 51, "line": "What am I supposed to do with two cakes and a pie?'", "order": 18162, "speaker": "Angela Martin"},
  {"season": 4, "episode": 7, "scene": 51, "line": "Oh, I'll take 'em.", "order": 18163, "speaker": "Kevin Malone"},
  {"season": 4, "episode": 7, "scene": 51, "line": "Well nobody's touching my cobbler.", "order": 18164, "speaker": "Creed Bratton"},
  {"season": 4, "episode": 7, "scene": 51, "line": "[raises hand] Hey, Michael. I mean Jim.", "order": 18165, "speaker": "Phyllis Vance"},
  {"season": 4, "episode": 7, "scene": 52, "line": "Yup, Phyllis called me Michael. And I will always and forever be haunted by that fact.", "order": 18166, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 7, "scene": 53, "line": "Under this tree, I think I struck the mother load. Those [points camera to mushrooms] are nature's best mushrooms. Wild, and I have to say these little buggers are damn tasty as well. [puts them in mouth]", "order": 18167, "speaker": "Michael Scott"},
  {"season": 4, "episode": 7, "scene": 53, "line": "Stop! No, no Michael! Nooo! Nooo! [runs up to Michael and knocks him over] Nooo! Arghh. Get, get them out. [picks out mushrooms from Michael's mouth]", "order": 18168, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 7, "scene": 54, "line": "Happy Birthday to you. Happy Birthday to you. Happy Birthday [Michael joins in with high note] to Creed. Happy Birthday to you. [Creed blows up candles on pie]", "order": 18169, "speaker": "Everybody"},
  {"season": 4, "episode": 7, "scene": 54, "line": "Yeah! [everybody clapping] Alright, skip around the room. Skip around the room. We want -- OK, alright. Yeah, don't do that. You're gonna break something.", "order": 18170, "speaker": "Michael Scott"},
  {"season": 4, "episode": 7, "scene": 55, "line": "Man became civilized for a reason. He decided that he liked to have warmth, and clothing, and television, and hamburgers, and to walk upright, and to have a soft futon at the end of the day. He didn't want to have to struggle to survive. I don't need the woods. I have a nice wood desk. I don't need fresh air, because I have the freshest air around, A.C. And I don't need wide open spaces. Check it out. [shows off computer screen scenery] I can also make it the sky.", "order": 18171, "speaker": "Michael Scott"},
  {"season": 4, "episode": 7, "scene": 56, "line": "Hey, buddy.", "order": 18172, "speaker": "Michael Scott"},
  {"season": 4, "episode": 7, "scene": 56, "line": "Hey.", "order": 18173, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 7, "scene": 56, "line": "What up?", "order": 18174, "speaker": "Michael Scott"},
  {"season": 4, "episode": 7, "scene": 56, "line": "Sure glad you're back.", "order": 18175, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 7, "scene": 56, "line": "You are relieved.", "order": 18176, "speaker": "Michael Scott"},
  {"season": 4, "episode": 7, "scene": 56, "line": "You have no idea.", "order": 18177, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 7, "scene": 56, "line": "So what did I miss?", "order": 18178, "speaker": "Michael Scott"},
  {"season": 4, "episode": 7, "scene": 56, "line": "Well, I tried to put all the birthdays together at once.", "order": 18179, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 7, "scene": 56, "line": "Oh.", "order": 18180, "speaker": "Michael Scott"},
  {"season": 4, "episode": 7, "scene": 56, "line": "So, terrible idea.", "order": 18181, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 7, "scene": 56, "line": "Yeah, okay, I did that. Rookie mistake.", "order": 18182, "speaker": "Michael Scott"},
  {"season": 4, "episode": 7, "scene": 56, "line": "You did do it?", "order": 18183, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 7, "scene": 56, "line": "Uh huh. Yeah, just wait. Ten years, you'll figure it out.", "order": 18184, "speaker": "Michael Scott"},
  {"season": 4, "episode": 7, "scene": 56, "line": "Well, I don't think I'll be here in ten years.", "order": 18185, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 7, "scene": 56, "line": "That's what I said. That's what she said.", "order": 18186, "speaker": "Michael Scott"},
  {"season": 4, "episode": 7, "scene": 56, "line": "That's what who said?", "order": 18187, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 7, "scene": 56, "line": "I never know. I just say it. I say stuff like that, you know, to lighten the tension. When things sort of get hard.", "order": 18188, "speaker": "Michael Scott"},
  {"season": 4, "episode": 7, "scene": 56, "line": "That's what she said.", "order": 18189, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 7, "scene": 56, "line": "Hey! Nice. Really good. Bravo, my young ward.", "order": 18190, "speaker": "Michael Scott"},
  {"season": 4, "episode": 7, "scene": 57, "line": "Come in!", "order": 18191, "speaker": "Michael Scott"},
  {"season": 4, "episode": 7, "scene": 57, "line": "Oh, it's Creed's birthday today. Should we get him an ice-cream cake? Mint chocolate chip?", "order": 18192, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 7, "scene": 57, "line": "Pam, are you my friend?", "order": 18193, "speaker": "Michael Scott"},
  {"season": 4, "episode": 7, "scene": 57, "line": "Oh, no.", "order": 18194, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 7, "scene": 57, "line": "We have been friends forever, right?", "order": 18195, "speaker": "Michael Scott"},
  {"season": 4, "episode": 7, "scene": 57, "line": "Yes, I have been working with you forever.", "order": 18196, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 7, "scene": 57, "line": "Hypothetically... would you go camping with me, if I were to ask you? And bear in mind that I would never actually ask you to go camping. That Jim is also a friend of mine, and I would rather die than make any sort of improper ovation toward you.", "order": 18197, "speaker": "Michael Scott"},
  {"season": 4, "episode": 7, "scene": 57, "line": "I don't think so.", "order": 18198, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 7, "scene": 58, "line": "Here's the thing. When Michael invents a hypothetical situation, he eventually turns it into an actual situation.", "order": 18199, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 7, "scene": 59, "line": "Do you understand that Jim is like a brother to me, and I would never do anything inappropriate?", "order": 18200, "speaker": "Michael Scott"},
  {"season": 4, "episode": 7, "scene": 59, "line": "I do.", "order": 18201, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 7, "scene": 59, "line": "So, as friends, would you go with me on a camping trip?", "order": 18202, "speaker": "Michael Scott"},
  {"season": 4, "episode": 7, "scene": 59, "line": "No.", "order": 18203, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 7, "scene": 59, "line": "Thank you for your honesty.", "order": 18204, "speaker": "Michael Scott"},
  {"season": 4, "episode": 7, "scene": 59, "line": "You're welcome. Open or closed?", "order": 18205, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 7, "scene": 59, "line": "Split the difference. Hey... mint chocolate chip, please? Is Jim out there?", "order": 18206, "speaker": "Michael Scott"},
  {"season": 4, "episode": 7, "scene": 59, "line": "No.", "order": 18207, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 7, "scene": 59, "line": "Can you send Dwight in here?", "order": 18208, "speaker": "Michael Scott"},
  {"season": 4, "episode": 7, "scene": 59, "line": "Yeah. Dwight, Michael wants to see you in his office. [Dwight runs in]", "order": 18209, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 7, "scene": 59, "line": "You wanted to see me?", "order": 18210, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 7, "scene": 59, "line": "Mmhmm. I had something very personal I would like to discuss. [Dwight closes door, takes off glasses, kneels in front of Michael's desk] Would you go find Jim, and send him in here, please?", "order": 18211, "speaker": "Michael Scott"},
  {"season": 4, "episode": 7, "scene": 59, "line": "I'm on it.", "order": 18212, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 7, "scene": 60, "line": "Michael has employed Dwight to track you down.", "order": 18213, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 7, "scene": 60, "line": "How much time do I have?", "order": 18214, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 7, "scene": 60, "line": "Jim, Michael's office.", "order": 18215, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 7, "scene": 60, "line": "Couple minutes, give or take.", "order": 18216, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 7, "scene": 60, "line": "Now, Jim!", "order": 18217, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 7, "scene": 61, "line": "And this... is a Hassenfass. Case-hardened steel. One side, as sharp as you'd ever want something to be. The other side serrated for maximum damage.", "order": 18218, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 7, "scene": 61, "line": "Okay.", "order": 18219, "speaker": "Michael Scott"},
  {"season": 4, "episode": 7, "scene": 61, "line": "Look at that. Perfectly balanced. This is a fine precision instrument.", "order": 18220, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 7, "scene": 61, "line": "All right, Dexter, just give me the knife.", "order": 18221, "speaker": "Michael Scott"},
  {"season": 4, "episode": 7, "scene": 61, "line": "Don't you want a sheath for that?", "order": 18222, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 7, "scene": 61, "line": "No, just get me a case. [Dwight looks at the camera, lifts his leg onto the desk, removes the sheath from his ankle, and gives it to Michael] All right.", "order": 18223, "speaker": "Michael Scott"},
  {"season": 4, "episode": 7, "scene": 61, "line": "Anything else? Flint...", "order": 18224, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 7, "scene": 61, "line": "MmMmm. All I need...", "order": 18225, "speaker": "Michael Scott"},
  {"season": 4, "episode": 7, "scene": 61, "line": "Parka...", "order": 18226, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 7, "scene": 61, "line": "No, Dwight!", "order": 18227, "speaker": "Michael Scott"},
  {"season": 4, "episode": 7, "scene": 61, "line": "Tent, rainfly, gorp...", "order": 18228, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 7, "scene": 61, "line": "I'm telling you, all I will need are my instincts and my will to survive.", "order": 18229, "speaker": "Michael Scott"},
  {"season": 4, "episode": 7, "scene": 61, "line": "Mmmnnnnmmm...", "order": 18230, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 7, "scene": 61, "line": "[holding up knife] What is this called again?", "order": 18231, "speaker": "Michael Scott"},
  {"season": 4, "episode": 8, "scene": 1, "line": "So the figures show improvement right there, Michael. And again- [Pam walks in and shows Michael a note]", "order": 18232, "speaker": "Oscar Martinez"},
  {"season": 4, "episode": 8, "scene": 1, "line": "I'll call back.", "order": 18233, "speaker": "Michael Scott"},
  {"season": 4, "episode": 8, "scene": 1, "line": "You never call back.", "order": 18234, "speaker": "Kevin Malone"},
  {"season": 4, "episode": 8, "scene": 2, "line": "Every time Michael's in a meeting, he makes me come in and give him a Post-It note telling him who's on the phone. I did it once, and he freaked out. He loved it so much. The thing is he doesn't get that many calls. So he has me make them up every 10 minutes.", "order": 18235, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 8, "scene": 3, "line": "[Pam walks in and shows Michael a note that says 'Good Morning'] Yeah, um, tell him I'll call him back.", "order": 18236, "speaker": "Michael Scott"},
  {"season": 4, "episode": 8, "scene": 4, "line": "[laughing]", "order": 18237, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 8, "scene": 4, "line": "You can't always work 200 days though.", "order": 18238, "speaker": "Michael Scott"},
  {"season": 4, "episode": 8, "scene": 4, "line": "[Pam walks in and shows Michael a note that has a smiley face] Oh, no, no, no, I don't have time for this. Tell him I'm in a meeting. [Jan looks impressed]", "order": 18239, "speaker": "Michael Scott"},
  {"season": 4, "episode": 8, "scene": 5, "line": "You have to know how to work this. There's no excuse for this.", "order": 18240, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 8, "scene": 5, "line": "Yep.", "order": 18241, "speaker": "Michael Scott"},
  {"season": 4, "episode": 8, "scene": 5, "line": "I can get you a tutor if you need- [Pam walks in and shows Michael a note with a waving, smiling Hot Dog saying 'Hiya Buddy']", "order": 18242, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 8, "scene": 5, "line": "Oh. Ah, this is a very important client. But, I have the most important client sitting right in front of me, my boss, so I will call him later.", "order": 18243, "speaker": "Michael Scott"},
  {"season": 4, "episode": 8, "scene": 5, "line": "Oh, no, no, no, customer service is obviously priority one. You can take the call.", "order": 18244, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 8, "scene": 5, "line": "N-No, money isn't everything Ryan. And you're my friend, and I don't want to be rude.", "order": 18245, "speaker": "Michael Scott"},
  {"season": 4, "episode": 8, "scene": 5, "line": "Take the call, friend.", "order": 18246, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 8, "scene": 5, "line": "I refuse. My house, my rules, I insist.", "order": 18247, "speaker": "Michael Scott"},
  {"season": 4, "episode": 8, "scene": 5, "line": "I insist you take your work calls.", "order": 18248, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 8, "scene": 5, "line": "Uh, okay, all right. Pam, would you put the call through? [Pam pushes a button the phone] Hi buddy.", "order": 18249, "speaker": "Michael Scott"},
  {"season": 4, "episode": 8, "scene": 6, "line": "I don't know, it's just, ugh, I never felt welcomed there, you know? It's such a...boys club.", "order": 18250, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 8, "scene": 6, "line": "Yeah, I hate that.", "order": 18251, "speaker": "Michael Scott"},
  {"season": 4, "episode": 8, "scene": 6, "line": "Good.", "order": 18252, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 8, "scene": 6, "line": "So, here's the deal. Um, I am on my way to New York to be deposed as part of Jan's wrongful termination law suit. The company fired her for having the courage to augment her boobs.", "order": 18253, "speaker": "Michael Scott"},
  {"season": 4, "episode": 8, "scene": 6, "line": "And they displayed a pattern of disrespect and inappropriate behavior.", "order": 18254, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 8, "scene": 6, "line": "Yes, yes. Pat-tern. Patt-ern. My friend Pat took a turn. That's how I remember that. Could we pull over and put down the top? I'm feeling a little queasy.", "order": 18255, "speaker": "Michael Scott"},
  {"season": 4, "episode": 8, "scene": 6, "line": "Um, no, I want it up. My hair.", "order": 18256, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 8, "scene": 6, "line": "Well-", "order": 18257, "speaker": "Michael Scott"},
  {"season": 4, "episode": 8, "scene": 6, "line": "Remember, it isn't just a pattern, It's a pattern of disrespect, and inappropriate behavior.", "order": 18258, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 8, "scene": 6, "line": "Disray .My friend Disray got news specs. Disray spect. My friend Inappro drives a Prius with his behind neighbor.", "order": 18259, "speaker": "Michael Scott"},
  {"season": 4, "episode": 8, "scene": 6, "line": "Does this work for you?", "order": 18260, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 8, "scene": 6, "line": "Yep. Tell them how much you're gonna get if you win.", "order": 18261, "speaker": "Michael Scott"},
  {"season": 4, "episode": 8, "scene": 6, "line": "Uh, come on Micheal, that's tacky.", "order": 18262, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 8, "scene": 6, "line": "Million dollars!", "order": 18263, "speaker": "Michael Scott"},
  {"season": 4, "episode": 8, "scene": 6, "line": "Four million.", "order": 18264, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 8, "scene": 6, "line": "Four million dollars! Man that is a lot of guacamole. A lot of the green. Lot of green. That is why I have memorized Jan's answers, and I have also thrown in some errs, and ahhs, to make it seem like it's not memorized.", "order": 18265, "speaker": "Michael Scott"},
  {"season": 4, "episode": 8, "scene": 6, "line": "No, Michael. Oh, come on.", "order": 18266, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 8, "scene": 6, "line": "Perfect crime.", "order": 18267, "speaker": "Michael Scott"},
  {"season": 4, "episode": 8, "scene": 6, "line": "Stop saying ridiculous things. He's just gonna tell the truth, the truth is-is very...you know, complicated, so we went over it carefully, and-and just so we wouldn't leave anything up to chance or Michael's judgment.", "order": 18268, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 8, "scene": 6, "line": "Could we please pull over and pull down the top, I do not feel good.", "order": 18269, "speaker": "Michael Scott"},
  {"season": 4, "episode": 8, "scene": 6, "line": "Michael, I told you, I am not putting the top down.", "order": 18270, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 8, "scene": 6, "line": "I'm getting car sick! Ugh, I'm gonna puke. I think I'm gonna throw up.", "order": 18271, "speaker": "Michael Scott"},
  {"season": 4, "episode": 8, "scene": 6, "line": "All right, fine, just a second. Hold on.", "order": 18272, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 8, "scene": 7, "line": "Hey, Schneider, real quick. What do you call a butt load of lawyers driving off a cliff?", "order": 18273, "speaker": "Michael Scott"},
  {"season": 4, "episode": 8, "scene": 7, "line": "A good start. And I think it's busload.", "order": 18274, "speaker": "Lester"},
  {"season": 4, "episode": 8, "scene": 7, "line": "Yeah, a bunch of rich lawyers took the bus. [To Jan] Where did you find this guy? Hey, there he is.", "order": 18275, "speaker": "Michael Scott"},
  {"season": 4, "episode": 8, "scene": 7, "line": "Hey Michael.", "order": 18276, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 8, "scene": 7, "line": "Hey hey.", "order": 18277, "speaker": "Michael Scott"},
  {"season": 4, "episode": 8, "scene": 7, "line": "I'm glad you're here. I actually need to talk to you for a second.", "order": 18278, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 8, "scene": 8, "line": "Could we talk off the record? As friends?", "order": 18279, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 8, "scene": 8, "line": "I would love that.", "order": 18280, "speaker": "Michael Scott"},
  {"season": 4, "episode": 8, "scene": 8, "line": "Jan... has put the company in a very tough position here. Now you've been with us for a long time. Over 10 years, right? We just want to be sure that you won't do anything to hurt us with your testimony. Do you understand?", "order": 18281, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 8, "scene": 8, "line": "Abso-fruitley. Yeah. I'll-I'll do anything for the company.", "order": 18282, "speaker": "Michael Scott"},
  {"season": 4, "episode": 8, "scene": 8, "line": "Good. That is great to hear.", "order": 18283, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 8, "scene": 8, "line": "It is.", "order": 18284, "speaker": "Michael Scott"},
  {"season": 4, "episode": 8, "scene": 9, "line": "[playing ping-pong] Game, son!", "order": 18285, "speaker": "Darryl Philbin"},
  {"season": 4, "episode": 8, "scene": 9, "line": "All right, let's run it back.", "order": 18286, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 8, "scene": 10, "line": "The warehouse got a ping pong table last week. Now Jim comes down and plays with Darryl. Sometimes I bring him juice. My boyfriend is 12.", "order": 18287, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 8, "scene": 11, "line": "What has two skinny chicken legs and sucks at ping pong?", "order": 18288, "speaker": "Kelly Kapoor"},
  {"season": 4, "episode": 8, "scene": 11, "line": "Hi, Kelly.", "order": 18289, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 8, "scene": 11, "line": "Guess whose boyfriend it is?", "order": 18290, "speaker": "Kelly Kapoor"},
  {"season": 4, "episode": 8, "scene": 11, "line": "I don't wanna guess.", "order": 18291, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 8, "scene": 11, "line": "I'll give you a hint: It's not my boyfriend. I think it's a guy over here [points to Jim].", "order": 18292, "speaker": "Kelly Kapoor"},
  {"season": 4, "episode": 8, "scene": 12, "line": "I don't talk trash, I talk smack. They're totally different. Trash talk is hypothetical, like: Your mom is so fat she can eat the internet. But smack talk is happening like right now. Like: You're ugly and I know it for a fact so I got the evidence right there.", "order": 18293, "speaker": "Kelly Kapoor"},
  {"season": 4, "episode": 8, "scene": 13, "line": "Jim. Can I see you for a second?", "order": 18294, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 8, "scene": 13, "line": "Sure.", "order": 18295, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 8, "scene": 14, "line": "Wow.", "order": 18296, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 8, "scene": 14, "line": "Okay, it's not regulation size, but it'll do. You have to practice. You have to get real good and beat Darryl.", "order": 18297, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 8, "scene": 14, "line": "Oh, I can't beat Darryl.", "order": 18298, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 8, "scene": 14, "line": "Please? Kelly's trash talking me because Darryl is beating you.", "order": 18299, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 8, "scene": 14, "line": "What, seriously? What is she saying?", "order": 18300, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 8, "scene": 15, "line": "[flashback, to Pam] Your boyfriend is so weak, he needs steroids just to watch baseball.", "order": 18301, "speaker": "Kelly Kapoor"},
  {"season": 4, "episode": 8, "scene": 16, "line": "[flashback, to Pam] Jim couldn't hit a ping pong ball if it was the size of the moon.", "order": 18302, "speaker": "Kelly Kapoor"},
  {"season": 4, "episode": 8, "scene": 17, "line": "[flashback, to Pam] Were Jim's parents first cousins that were also bad at ping pong?", "order": 18303, "speaker": "Kelly Kapoor"},
  {"season": 4, "episode": 8, "scene": 18, "line": "So you're asking me to defend your honor against Kelly?", "order": 18304, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 8, "scene": 18, "line": "Sorta, yes.", "order": 18305, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 8, "scene": 18, "line": "Bring me players.", "order": 18306, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 8, "scene": 18, "line": "Okay.", "order": 18307, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 8, "scene": 19, "line": "Hi everyone, I'm Diane Kelly. I'm the company's chief legal counsel.", "order": 18308, "speaker": "Diane Kelly"},
  {"season": 4, "episode": 8, "scene": 19, "line": "Hi. [Michael sees Toby] No, no, absolutely not. What is he doing here?", "order": 18309, "speaker": "Michael Scott"},
  {"season": 4, "episode": 8, "scene": 19, "line": "Toby?", "order": 18310, "speaker": "Diane"},
  {"season": 4, "episode": 8, "scene": 19, "line": "Are you renewing your divorce vows, before my deposition?", "order": 18311, "speaker": "Michael Scott"},
  {"season": 4, "episode": 8, "scene": 19, "line": "Michael, I'm your HR Rep. I'm on your side.", "order": 18312, "speaker": "Toby Flenderson"},
  {"season": 4, "episode": 8, "scene": 19, "line": "Never. I want him gone. I don't talk until he leaves.", "order": 18313, "speaker": "Michael Scott"},
  {"season": 4, "episode": 8, "scene": 19, "line": "Michael, just relax, okay?", "order": 18314, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 8, "scene": 19, "line": "You know, I think they're, uh ready for us now. So..", "order": 18315, "speaker": "Diane"},
  {"season": 4, "episode": 8, "scene": 19, "line": "Okay. All right.", "order": 18316, "speaker": "Michael Scott"},
  {"season": 4, "episode": 8, "scene": 20, "line": "Mr. Scott. Do you swear to tell the truth, the whole truth, and nothing but the truth?", "order": 18317, "speaker": "Deposition Reporter"},
  {"season": 4, "episode": 8, "scene": 20, "line": "Yessh.", "order": 18318, "speaker": "Michael Scott"},
  {"season": 4, "episode": 8, "scene": 20, "line": "Mr. Scott, can you describe the circumstances of Ms. Levinson's termination?", "order": 18319, "speaker": "Lester"},
  {"season": 4, "episode": 8, "scene": 20, "line": "Well, it was not just termination. It was a pattern of disrespect and inappropriate behavior.", "order": 18320, "speaker": "Michael Scott"},
  {"season": 4, "episode": 8, "scene": 20, "line": "Oh. Very good. Well put.", "order": 18321, "speaker": "Lester"},
  {"season": 4, "episode": 8, "scene": 21, "line": "People underestimate Michael. There are plenty of things that he is well above average at. Like ice skating. He is a very good ice skater.", "order": 18322, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 8, "scene": 22, "line": "Hey, Kevin. Jim needs to see you.", "order": 18323, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 8, "scene": 22, "line": "About what?", "order": 18324, "speaker": "Kevin Malone"},
  {"season": 4, "episode": 8, "scene": 22, "line": "He needs help balancing some travel receipts.", "order": 18325, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 8, "scene": 22, "line": "Are you sure he wants me? Because I have Oscar balance my travel receipts.", "order": 18326, "speaker": "Kevin Malone"},
  {"season": 4, "episode": 8, "scene": 22, "line": "Yeah, no, he asked for you specifically. He's in the conference room.", "order": 18327, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 8, "scene": 22, "line": "[enters conference room and sees ping pong table] Oh, awesome! [Pam puts 'Meeting in Progress' sign on the door]", "order": 18328, "speaker": "Kevin Malone"},
  {"season": 4, "episode": 8, "scene": 23, "line": "How long have you known the plaintiff?", "order": 18329, "speaker": "Lester"},
  {"season": 4, "episode": 8, "scene": 23, "line": "I haven't actually seen it. But I have seen the firm, and I am planning on renting The Pelican Brief", "order": 18330, "speaker": "Michael Scott"},
  {"season": 4, "episode": 8, "scene": 23, "line": "How long have you known Ms. Levinson?", "order": 18331, "speaker": "Lester"},
  {"season": 4, "episode": 8, "scene": 23, "line": "Six years and two months.", "order": 18332, "speaker": "Michael Scott"},
  {"season": 4, "episode": 8, "scene": 23, "line": "And you were directly under her the entire time?", "order": 18333, "speaker": "Lester"},
  {"season": 4, "episode": 8, "scene": 23, "line": "That's what she said.", "order": 18334, "speaker": "Michael Scott"},
  {"season": 4, "episode": 8, "scene": 23, "line": "Excuse me?", "order": 18335, "speaker": "Lester"},
  {"season": 4, "episode": 8, "scene": 23, "line": "That's what she said.", "order": 18336, "speaker": "Michael Scott"},
  {"season": 4, "episode": 8, "scene": 23, "line": "Ms. Levinson told you she was your direct superior?", "order": 18337, "speaker": "Lester"},
  {"season": 4, "episode": 8, "scene": 23, "line": "Uh, wh-why would she say that?", "order": 18338, "speaker": "Michael Scott"},
  {"season": 4, "episode": 8, "scene": 23, "line": "Can we just move on to another question?", "order": 18339, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 8, "scene": 23, "line": "No wait, I don't understand. Who's on record on saying this?", "order": 18340, "speaker": "Diane"},
  {"season": 4, "episode": 8, "scene": 23, "line": "With all due respect, I'm in the middle of a line of questioning. Now Mr. Scott, what did you say Ms. Levinson said? Regarding your employment status with respect to her corporate position.", "order": 18341, "speaker": "Lester"},
  {"season": 4, "episode": 8, "scene": 23, "line": "Come again? That's what she said? I don't know what you're talking about.", "order": 18342, "speaker": "Michael Scott"},
  {"season": 4, "episode": 8, "scene": 23, "line": "Okay, if I may, he was just telling a joke before, so can we move on to another question?", "order": 18343, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 8, "scene": 23, "line": "Oh, are you sure?", "order": 18344, "speaker": "Lester"},
  {"season": 4, "episode": 8, "scene": 23, "line": "Uh, yes.", "order": 18345, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 8, "scene": 23, "line": "Can you go back to where this digression began?", "order": 18346, "speaker": "Lester"},
  {"season": 4, "episode": 8, "scene": 23, "line": "[reading off paper] Mr. Schneider: And you were directly under her the entire time? Mr. Scott: That's what she said.", "order": 18347, "speaker": "Deposition Reporter"},
  {"season": 4, "episode": 8, "scene": 23, "line": "Well, delivery is all wrong. She's butchering it.", "order": 18348, "speaker": "Michael Scott"},
  {"season": 4, "episode": 8, "scene": 24, "line": "[Pam leaving bathroom, Kelly entering, awkward moment, Pam steps aside to let Kelly in] Yeah, that's what I thought.", "order": 18349, "speaker": "Kelly Kapoor"},
  {"season": 4, "episode": 8, "scene": 25, "line": "Did Ms. Levinson ever say why she thought she was being fired?", "order": 18350, "speaker": "Lester"},
  {"season": 4, "episode": 8, "scene": 25, "line": "She thought it had to do with the twins. That's what I call them.", "order": 18351, "speaker": "Michael Scott"},
  {"season": 4, "episode": 8, "scene": 25, "line": "Can you be more specific? Who are the twins?", "order": 18352, "speaker": "Lester"},
  {"season": 4, "episode": 8, "scene": 25, "line": "Um, to be delicate, they hang off milady's chest. They... make milk.", "order": 18353, "speaker": "Michael Scott"},
  {"season": 4, "episode": 8, "scene": 25, "line": "You don't need to go any further. Her breasts.", "order": 18354, "speaker": "Lester"},
  {"season": 4, "episode": 8, "scene": 25, "line": "Yes.", "order": 18355, "speaker": "Michael Scott"},
  {"season": 4, "episode": 8, "scene": 25, "line": "She thought it had something to do with her recent breast enhancement surgery?", "order": 18356, "speaker": "Lester"},
  {"season": 4, "episode": 8, "scene": 25, "line": "Yes, and frankly, the timing was nothing short of predominant.", "order": 18357, "speaker": "Michael Scott"},
  {"season": 4, "episode": 8, "scene": 25, "line": "What about your romantic relationship with Ms. Levinson? Could that have played a part in her termination?", "order": 18358, "speaker": "Lester"},
  {"season": 4, "episode": 8, "scene": 25, "line": "Well, if it did, then the company is breaking its own rules.", "order": 18359, "speaker": "Michael Scott"},
  {"season": 4, "episode": 8, "scene": 25, "line": "Interesting. How so?", "order": 18360, "speaker": "Lester"},
  {"season": 4, "episode": 8, "scene": 25, "line": "Because before we started dating, we disclosed our relationship to HR. And I have the proof right here. [makes a gasping sound]", "order": 18361, "speaker": "Michael Scott"},
  {"season": 4, "episode": 8, "scene": 25, "line": "Okay, the company has just a few clarifying questions, Mr. Scott, if that's okay with you?", "order": 18362, "speaker": "Diane"},
  {"season": 4, "episode": 8, "scene": 25, "line": "I will allow it.", "order": 18363, "speaker": "Michael Scott"},
  {"season": 4, "episode": 8, "scene": 25, "line": "Um would you mind please just, uh, taking a quick look at, uh, this photograph please. [Diane hands Michael the photo of Jan and Michael in Jamaica]", "order": 18364, "speaker": "Diane"},
  {"season": 4, "episode": 8, "scene": 25, "line": "Uh...", "order": 18365, "speaker": "Michael Scott"},
  {"season": 4, "episode": 8, "scene": 25, "line": "That is you and Ms. Levinson in Jamaica, is that correct?", "order": 18366, "speaker": "Diane"},
  {"season": 4, "episode": 8, "scene": 25, "line": "Uh-huh.", "order": 18367, "speaker": "Michael Scott"},
  {"season": 4, "episode": 8, "scene": 25, "line": "And that photograph was taken more than two months prior to this start of your relationship. Does that sound right? Mr. Scott, the timeline here is actually very important. Please, when did your relationship actually begin?", "order": 18368, "speaker": "Diane"},
  {"season": 4, "episode": 8, "scene": 25, "line": "Ugh. Well, de-depends on how you define 'begin'. I mean, if it was from the first time we shook hands, that's like six years ago. If it's from the first time we kissed, that's like two years ago.", "order": 18369, "speaker": "Michael Scott"},
  {"season": 4, "episode": 8, "scene": 25, "line": "Wait, excuse me?", "order": 18370, "speaker": "Diane"},
  {"season": 4, "episode": 8, "scene": 25, "line": "If it was the first time we kissed sober, it was like four months after that.", "order": 18371, "speaker": "Michael Scott"},
  {"season": 4, "episode": 8, "scene": 25, "line": "Could we take a short break?", "order": 18372, "speaker": "Lester"},
  {"season": 4, "episode": 8, "scene": 25, "line": "No, uh, are you telling me that your relationship began two years ago and not in February as you previously testified to here?", "order": 18373, "speaker": "Diane"},
  {"season": 4, "episode": 8, "scene": 25, "line": "Line.", "order": 18374, "speaker": "Michael Scott"},
  {"season": 4, "episode": 8, "scene": 25, "line": "I'm sorry, what?", "order": 18375, "speaker": "Diane"},
  {"season": 4, "episode": 8, "scene": 25, "line": "He asked for a line, like in a play.", "order": 18376, "speaker": "Man"},
  {"season": 4, "episode": 8, "scene": 26, "line": "[reading off paper] 'Mr. Scott, do you realize you just contradicted yourself?' 'I did?' 'Yes you did.' 'Can I go to the bathroom?' 'No.' 'I really have to, I've been drinking lots of water.' 'You went five minutes ago.' 'That wasn't to go to the bathroom, that was to get out of a question.' 'You still have to answer it.' 'First can I go to the bathroom?' 'No.'", "order": 18377, "speaker": "Deposition Reporter"},
  {"season": 4, "episode": 8, "scene": 27, "line": "Good game Meredith.", "order": 18378, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 8, "scene": 27, "line": "Don't patronize me.", "order": 18379, "speaker": "Meredith Palmer"},
  {"season": 4, "episode": 8, "scene": 27, "line": "All right! What is going on here?", "order": 18380, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 8, "scene": 27, "line": "Dwight! Thank God you're here. As it turns out, one of our biggest clients is a ping pong master, and I have to play him tomorrow, or we lose the account. Can you help me out? Will you help me practice?", "order": 18381, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 8, "scene": 28, "line": "[Dwight and Jim are playing ping-pong, and Dwight scores] What the hell?", "order": 18382, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 8, "scene": 28, "line": "I told you.", "order": 18383, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 8, "scene": 29, "line": "All of my heroes are table tennis players. Zoran Primorac, Jan-Ove Waldner, Wang Tao, J'rg Ro'kopf, and of course Ashraf Helmi. I even have a life size poster of Hugo Hoyama on my wall. And the first time I left Pennsylvania was to go the hall of fame induction ceremony of Andrzej Grubba.", "order": 18384, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 8, "scene": 30, "line": "Jan and I had an off again, on again relationship for two years. And I know this destroys her case, and I am sorry, but I throw myself at the mercy of the deposition.", "order": 18385, "speaker": "Michael Scott"},
  {"season": 4, "episode": 8, "scene": 30, "line": "Thank you, Mr. Scott. That's all we needed to know.", "order": 18386, "speaker": "Diane Kelly"},
  {"season": 4, "episode": 8, "scene": 30, "line": "Wait, we'd like to enter to the record a page from Michael Scott's personal journal.", "order": 18387, "speaker": "Lester"},
  {"season": 4, "episode": 8, "scene": 30, "line": "Whoa whoa whoa whoa! What are you doing with my diary?", "order": 18388, "speaker": "Michael Scott"},
  {"season": 4, "episode": 8, "scene": 30, "line": "This is plaintiff's exhibit 107. I quote from an entry dated Janruary 4 of this past year. 'Just got back from Jamaica. Tan almost everywhere. Jan almost everywhere. Hehe. Oh diary, what a week. I had sex with my boss. I don't know if it's going to go anywhere. Jan was very specific that this is not going anywhere, that it was a one time mistake. But we had sex six times so you tell me. I am definitely feeling very eerie.'", "order": 18389, "speaker": "Lester"},
  {"season": 4, "episode": 8, "scene": 30, "line": "Irie.", "order": 18390, "speaker": "Michael Scott"},
  {"season": 4, "episode": 8, "scene": 30, "line": "Irie, sorry. 'More tomorrow. xoxo, Michael.' It would appear, that neither you nor Ms. Levinson considered you had a relationship in any sense of the word. Is that correct?", "order": 18391, "speaker": "Lester"},
  {"season": 4, "episode": 8, "scene": 30, "line": "We're gonna need to see a copy of that entire journal before we proceed.", "order": 18392, "speaker": "Diane Kelly"},
  {"season": 4, "episode": 8, "scene": 30, "line": "I don't think anyone in this room has the right to read my diary.", "order": 18393, "speaker": "Michael Scott"},
  {"season": 4, "episode": 8, "scene": 30, "line": "It's basic discovery. We have the right to review it.", "order": 18394, "speaker": "Diane Kelly"},
  {"season": 4, "episode": 8, "scene": 30, "line": "OK, let's make ten copies of this diary.", "order": 18395, "speaker": "Man"},
  {"season": 4, "episode": 8, "scene": 30, "line": "Um, can you make it eleven?", "order": 18396, "speaker": "Toby Flenderson"},
  {"season": 4, "episode": 8, "scene": 30, "line": "Eleven, sure. And we'll break for lunch, so everyone can have a look.", "order": 18397, "speaker": "Man"},
  {"season": 4, "episode": 8, "scene": 31, "line": "[looking for a place to sit, goes to Toby's table] Can I sit here?", "order": 18398, "speaker": "Michael Scott"},
  {"season": 4, "episode": 8, "scene": 31, "line": "[nods] ...You know, I, uh, I know a little about what you're going through in a way. Um, when I was a kid, my parents got divorced. They both wanted custody, and they both asked me to testify against the other one in court. So, I don't know, I didn't want them getting divorced in the first place you know? I loved them both so much. I just wanted... [Michael pushes Toby's food tray off the table and walks off]", "order": 18399, "speaker": "Toby Flenderson"},
  {"season": 4, "episode": 8, "scene": 32, "line": "How can you give up my diary like that?", "order": 18400, "speaker": "Michael Scott"},
  {"season": 4, "episode": 8, "scene": 32, "line": "I had to, I'm sorry but I need to win this. We need to win this.", "order": 18401, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 8, "scene": 32, "line": "How'd you even find it?", "order": 18402, "speaker": "Michael Scott"},
  {"season": 4, "episode": 8, "scene": 32, "line": "You keep it under my side of the mattress.", "order": 18403, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 8, "scene": 32, "line": "I don't like lump. I'm really upset about this.", "order": 18404, "speaker": "Michael Scott"},
  {"season": 4, "episode": 8, "scene": 32, "line": "Alright, I stole your diary and gave it to my lawyer. You emailed a topless photo of me to everyone in our company. Let's call it even.", "order": 18405, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 8, "scene": 32, "line": "Fine. I love you.", "order": 18406, "speaker": "Michael Scott"},
  {"season": 4, "episode": 8, "scene": 32, "line": "I love you too.", "order": 18407, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 8, "scene": 33, "line": "[looking at a copy of Michael's diary] Mr. Scott, who is this other woman, Ryan? Who you refer to her as 'Just as hot as Jan, but in a different way.'", "order": 18408, "speaker": "Diane Kelly"},
  {"season": 4, "episode": 8, "scene": 33, "line": "Not a woman, just a cool, great looking, best friend.", "order": 18409, "speaker": "Michael Scott"},
  {"season": 4, "episode": 8, "scene": 33, "line": "Aren't we trying to determine whether Michael or Jan were engaged in a romantic affair? Not Michael and this Ryan person.", "order": 18410, "speaker": "Lester"},
  {"season": 4, "episode": 8, "scene": 33, "line": "[laughs out loud] Excuse me.", "order": 18411, "speaker": "Toby Flenderson"},
  {"season": 4, "episode": 8, "scene": 33, "line": "Alright, alright. This is the way I see it. Yes, I had sex with Jan, and yes, I did consider Jan to be my girlfriend. However, Jan clearly didn't consider me to be her boyfriend. So her actions are completely rightful.", "order": 18412, "speaker": "Michael Scott"},
  {"season": 4, "episode": 8, "scene": 33, "line": "OK Mr. Scott, it's, it's admirable the way you defend a woman who is so obviously ambivalent about her relationship with you.", "order": 18413, "speaker": "Diane Kelly"},
  {"season": 4, "episode": 8, "scene": 33, "line": "Thank you very much. You didn't have to say that.", "order": 18414, "speaker": "Michael Scott"},
  {"season": 4, "episode": 8, "scene": 33, "line": "Considering she consistently gave you such poor performance reviews.", "order": 18415, "speaker": "Diane Kelly"},
  {"season": 4, "episode": 8, "scene": 33, "line": "That was before our relationship. She was going through a divorce, and she was drinking a lot... [Jan glares at Michael] ...of water.", "order": 18416, "speaker": "Michael Scott"},
  {"season": 4, "episode": 8, "scene": 33, "line": "Mr. Scott, this is a copy of a particularly negative performance review. Would you mind, uh, reading the date on that please? [slides the performance review towards Michael]", "order": 18417, "speaker": "Diane Kelly"},
  {"season": 4, "episode": 8, "scene": 33, "line": "March 17th.", "order": 18418, "speaker": "Michael Scott"},
  {"season": 4, "episode": 8, "scene": 33, "line": "And that would be a month after your relationship became official, is that correct?", "order": 18419, "speaker": "Diane Kelly"},
  {"season": 4, "episode": 8, "scene": 33, "line": "Yes.", "order": 18420, "speaker": "Michael Scott"},
  {"season": 4, "episode": 8, "scene": 33, "line": "You may read the, uh, highlighted portion out loud if you'd like to.", "order": 18421, "speaker": "Diane Kelly"},
  {"season": 4, "episode": 8, "scene": 33, "line": "[reading the performance review] 'I am out of carrots. I am out of sticks. Mr. Scott has time and again proven himself to be an unmanageable employee, and a poor branch manager. I recommend he be removed from that position and re-assigned to sales where he belongs.'", "order": 18422, "speaker": "Michael Scott"},
  {"season": 4, "episode": 8, "scene": 33, "line": "Mr. Scott, after hearing that wouldn't you say Ms. Levinson's judgment is, at least, very seriously flawed?", "order": 18423, "speaker": "Diane Kelly"},
  {"season": 4, "episode": 8, "scene": 33, "line": "[stares at Diane, eyes tearing up]", "order": 18424, "speaker": "Michael Scott"},
  {"season": 4, "episode": 8, "scene": 33, "line": "Mr. Scott?", "order": 18425, "speaker": "Diane Kelly"},
  {"season": 4, "episode": 8, "scene": 34, "line": "[walks in the conference room where Jim and Dwight are playing ping-pong] How's it going?", "order": 18426, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 8, "scene": 34, "line": "Well, he has gone from completely hopeless to simply miserable.", "order": 18427, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 8, "scene": 34, "line": "Check this out though, spin serve. [serves, and Dwight scores] Well, it works like 80% of the time, so...", "order": 18428, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 8, "scene": 34, "line": "Nice. So, should I reschedule the rematch with Darryl now?", "order": 18429, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 8, "scene": 34, "line": "I think I'm ready.", "order": 18430, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 8, "scene": 34, "line": "I'll make the call.", "order": 18431, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 8, "scene": 34, "line": "Wait a minute, Darryl is the client? ...No, no, no. He works here, dumbass.", "order": 18432, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 8, "scene": 34, "line": "...Right. Spin-serve! [serves, and Dwight scores]", "order": 18433, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 8, "scene": 35, "line": "How can you do that to me?", "order": 18434, "speaker": "Michael Scott"},
  {"season": 4, "episode": 8, "scene": 35, "line": "[looks at Lester]", "order": 18435, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 8, "scene": 35, "line": "You can respond, just remember it's all going into the record.", "order": 18436, "speaker": "Lester"},
  {"season": 4, "episode": 8, "scene": 35, "line": "Michael, I am not the enemy, OK? Dunder Mifflin is the enemy.", "order": 18437, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 8, "scene": 35, "line": "Dunder Mifflin has always treated me with the utmost respect, with loyalty. They were going to give me your job and I should have taken it.", "order": 18438, "speaker": "Michael Scott"},
  {"season": 4, "episode": 8, "scene": 35, "line": "Alright, wait. Before you go any further, let me show you what kind of loyalty they have, OK? Lester, please read that part of Wallace's deposition.", "order": 18439, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 8, "scene": 35, "line": "Starting at paragraph 6. Council: 'Mr. Wallace, regarding Michael Scott, was he a contender to replace Jan Levinson?' David Wallace: 'Yes.'", "order": 18440, "speaker": "Lester"},
  {"season": 4, "episode": 8, "scene": 35, "line": "See? I was his number 1 contender. I was being groomed.", "order": 18441, "speaker": "Michael Scott"},
  {"season": 4, "episode": 8, "scene": 35, "line": "Council: 'Was he your first choice?' David Wallace: 'Michael Scott is a fine employee who has been with the company for many years.'", "order": 18442, "speaker": "Lester"},
  {"season": 4, "episode": 8, "scene": 35, "line": "Was he in the top 5 of contenders?' David Wallace: 'What do you want me to say? Come on, he's a nice guy. There were many people that I considered.' Council: 'Was he seriously being considered for the corporate job?' David Wallace: 'No.'", "order": 18443, "speaker": "Council"},
  {"season": 4, "episode": 8, "scene": 35, "line": "I have one more question, Mr. Scott. Wouldn't you agree with Ms. Levinson that the company exhibits a pattern of disrespect toward its employees?", "order": 18444, "speaker": "Lester"},
  {"season": 4, "episode": 8, "scene": 35, "line": "...Absolutely not.", "order": 18445, "speaker": "Michael Scott"},
  {"season": 4, "episode": 8, "scene": 36, "line": "[watches Jim score in ping-pong against Darryl] Yes! Way to go. [looks at Kelly] See that?", "order": 18446, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 8, "scene": 36, "line": "Yeah, the floppy haired girl you date won a point.", "order": 18447, "speaker": "Kelly Kapoor"},
  {"season": 4, "episode": 8, "scene": 36, "line": "19, serving 4. [serves and scores]", "order": 18448, "speaker": "Darryl Philbin"},
  {"season": 4, "episode": 8, "scene": 36, "line": "Woooh! Nice baby! Nice one! [starts singing] Hey, hey, you, you! I don't like your boyfriend! Cuz, cuz, cuz, cuz, cuz he sucks at ping-pong!", "order": 18449, "speaker": "Kelly Kapoor"},
  {"season": 4, "episode": 8, "scene": 36, "line": "You know what? I'm sick of this! Let's go, you and me!", "order": 18450, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 8, "scene": 36, "line": "What?", "order": 18451, "speaker": "Kelly Kapoor"},
  {"season": 4, "episode": 8, "scene": 36, "line": "Let's go. Pick up a paddle.", "order": 18452, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 8, "scene": 36, "line": "OK. Bring it on.", "order": 18453, "speaker": "Kelly Kapoor"},
  {"season": 4, "episode": 8, "scene": 36, "line": "I am.", "order": 18454, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 8, "scene": 36, "line": "Think you can handle this?", "order": 18455, "speaker": "Kelly Kapoor"},
  {"season": 4, "episode": 8, "scene": 36, "line": "In my sleep.", "order": 18456, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 8, "scene": 36, "line": "OK. Volley for serve, P. [serves]", "order": 18457, "speaker": "Kelly Kapoor"},
  {"season": 4, "episode": 8, "scene": 36, "line": "O. [hits the ping-pong ball, and it hits the net] [serves] P.", "order": 18458, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 8, "scene": 36, "line": "O. [hits the ping-pong ball, and it hits the net]", "order": 18459, "speaker": "Kelly Kapoor"},
  {"season": 4, "episode": 8, "scene": 36, "line": "Do you want to go play on the table upstairs?", "order": 18460, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 8, "scene": 36, "line": "Yes.", "order": 18461, "speaker": "Darryl Philbin"},
  {"season": 4, "episode": 8, "scene": 37, "line": "Michael. I am very sorry.", "order": 18462, "speaker": "David Wallace"},
  {"season": 4, "episode": 8, "scene": 37, "line": "Oh, hey no biggie. Just...", "order": 18463, "speaker": "Michael Scott"},
  {"season": 4, "episode": 8, "scene": 37, "line": "No, no, no, no, no. This was rough. We never meant for you to get caught in the middle of this. I'm very sorry.", "order": 18464, "speaker": "David Wallace"},
  {"season": 4, "episode": 8, "scene": 37, "line": "[nods and shakes hands with David Wallace] Hey, David?", "order": 18465, "speaker": "Michael Scott"},
  {"season": 4, "episode": 8, "scene": 37, "line": "Yeah?", "order": 18466, "speaker": "David Wallace"},
  {"season": 4, "episode": 8, "scene": 37, "line": "I think you're a nice guy too.", "order": 18467, "speaker": "Michael Scott"},
  {"season": 4, "episode": 8, "scene": 37, "line": "Thanks, Michael.", "order": 18468, "speaker": "David Wallace"},
  {"season": 4, "episode": 8, "scene": 38, "line": "Why did I do it? I don't know. Jan said that it was because of the photo that she revealed the diary. But she already brought the diary with her to New York, so... You expect to get screwed by your company, but you never expect to get screwed by your girlfriend.", "order": 18469, "speaker": "Michael Scott"},
  {"season": 4, "episode": 8, "scene": 39, "line": "[Driving with Jan in the car] What do you want to do for dinner?", "order": 18470, "speaker": "Michael Scott"},
  {"season": 4, "episode": 8, "scene": 39, "line": "How about Chinese?", "order": 18471, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 8, "scene": 39, "line": "Sure trying to save some money... get something cheap.", "order": 18472, "speaker": "Michael Scott"},
  {"season": 4, "episode": 8, "scene": 39, "line": "That was my cheap suggestion. Chinese was my cheap suggestion.", "order": 18473, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 8, "scene": 39, "line": "Do you fast food?", "order": 18474, "speaker": "Michael Scott"},
  {"season": 4, "episode": 8, "scene": 39, "line": "Fine, fast food's fine.", "order": 18475, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 8, "scene": 40, "line": "So, where did you learn how to play?", "order": 18476, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 8, "scene": 40, "line": "Cambodia. You?", "order": 18477, "speaker": "Creed Bratton"},
  {"season": 4, "episode": 8, "scene": 40, "line": "My friend's basement. All right, ready to start?", "order": 18478, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 8, "scene": 40, "line": "Let's do this thing. [Creed loses the first point, tosses his paddle on the table and starts taking off his shirt. Jim retrieves the ball and looks back to see Creed with his shirt untucked and unbuttoned]", "order": 18479, "speaker": "Creed Bratton"},
  {"season": 4, "episode": 8, "scene": 40, "line": "What are you doing?", "order": 18480, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 8, "scene": 40, "line": "We're not playing strip pong? [Jim shakes his head no] Okay. [Creed picks up paddle]", "order": 18481, "speaker": "Creed Bratton"},
  {"season": 4, "episode": 8, "scene": 41, "line": "I totally see that. [looking at spreadsheet]", "order": 18482, "speaker": "Oscar Martinez"},
  {"season": 4, "episode": 8, "scene": 41, "line": "Right. There, first...", "order": 18483, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 8, "scene": 41, "line": "Yeah, yeah.", "order": 18484, "speaker": "Oscar Martinez"},
  {"season": 4, "episode": 8, "scene": 41, "line": "I think that's the question that you had...", "order": 18485, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 8, "scene": 41, "line": "What are you doing?", "order": 18486, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 8, "scene": 41, "line": "It's a problem with the client. I think, Jim...", "order": 18487, "speaker": "Oscar Martinez"},
  {"season": 4, "episode": 8, "scene": 41, "line": "Blah, blah, blah, blah, blah. [makes 'talking' gesture with her hand] Jim, come on, we gotta get in there and practice. Okay? I'm not kidding!", "order": 18488, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 8, "scene": 41, "line": "Wow. [heads toward the conference room]", "order": 18489, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 8, "scene": 41, "line": "Now. Get good work done. Concentrate.", "order": 18490, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 8, "scene": 41, "line": "All right.", "order": 18491, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 8, "scene": 41, "line": "On your backhand. [Jim opens the conference room door and enters]", "order": 18492, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 8, "scene": 41, "line": "Booyah! [laughs]", "order": 18493, "speaker": "Stanley Hudson"},
  {"season": 4, "episode": 8, "scene": 41, "line": "[laughing] Thought we were playing for fun.", "order": 18494, "speaker": "Phyllis Vance"},
  {"season": 4, "episode": 8, "scene": 41, "line": "No one paid me to say 'Booyah.' Now pick up the ball.", "order": 18495, "speaker": "Stanley Hudson"},
  {"season": 4, "episode": 8, "scene": 41, "line": "But it's closer to you.", "order": 18496, "speaker": "Phyllis Vance"},
  {"season": 4, "episode": 8, "scene": 41, "line": "[kicks ball toward Phyllis] Now it isn't.", "order": 18497, "speaker": "Stanley Hudson"},
  {"season": 4, "episode": 8, "scene": 41, "line": "What are you guys doing?", "order": 18498, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 8, "scene": 41, "line": "Oh, we're in a meeting.", "order": 18499, "speaker": "Phyllis Vance"},
  {"season": 4, "episode": 8, "scene": 41, "line": "Oh yeah, how long's this meeting going to last? [Stanley mumbles, looking at his watch]", "order": 18500, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 8, "scene": 41, "line": "[calling in, unseen] I have next meeting!", "order": 18501, "speaker": "Angela Martin"},
  {"season": 4, "episode": 8, "scene": 42, "line": "I played a lot of pong growing up. I spent a lot of time on cruise ships. I'm also a monster snorkler.", "order": 18502, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 8, "scene": 43, "line": "Okay, you know what, this table's not regulation.", "order": 18503, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 8, "scene": 43, "line": "Good eye - it's an oval. [Andy compares his arm length to his side of the conference table] You're measuring the table.", "order": 18504, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 8, "scene": 43, "line": "Do you know anything about physics?", "order": 18505, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 8, "scene": 43, "line": "Do you?", "order": 18506, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 8, "scene": 43, "line": "Just serve. Let's go. [hits one into net] Dammit! These balls are weighted weird, aren't they?", "order": 18507, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 8, "scene": 43, "line": "Yup.", "order": 18508, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 8, "scene": 43, "line": "Yeah. Get another one. [throws the offending ball at Jim, who takes another from his pocket]", "order": 18509, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 8, "scene": 43, "line": "Now, wait a minute. You're not gonna punch a wall, or me, or anything like that if you don't win the point, are you?", "order": 18510, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 8, "scene": 43, "line": "I don't know! No, okay, just... serve!", "order": 18511, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 8, "scene": 44, "line": "I have some, uh, experience. I, um, once had to testify in a traffic accident case.", "order": 18512, "speaker": "Michael Scott"},
  {"season": 4, "episode": 8, "scene": 44, "line": "Then you're an old hand at this.", "order": 18513, "speaker": "Man"},
  {"season": 4, "episode": 8, "scene": 44, "line": "Yes. My testimony was actually very important, um, because the accident was my fault. [To Toby] Tell Meredith I said it another time [Toby nods].", "order": 18514, "speaker": "Michael Scott"},
  {"season": 4, "episode": 8, "scene": 45, "line": "Yes, Michael is going to be cross-examined today, which'll be very, very risky for me, nonetheless, not an easy decision to make, but it's a deposition. It's not going to be in front of a judge. And it's four million dollars.", "order": 18515, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 8, "scene": 46, "line": "Before we continue, I would like to make an opening statement.", "order": 18516, "speaker": "Michael Scott"},
  {"season": 4, "episode": 8, "scene": 46, "line": "Fine. Please be brief.", "order": 18517, "speaker": "Man"},
  {"season": 4, "episode": 8, "scene": 46, "line": "[clears throat and stands] Ladies and gentlemen of... the... table. I would just like to say what a joy it is for me to be here today, but frankly, at my age, it's a joy to be anywhere [no one laughs]. I'm only forty-four, so I'm not old, and I guess that doesn't really play. But the point is, I have a sense of humor. And I implore you all to have a sense of humor as well. Jan, David, my wonderful girlfriend [indicates Jan, then turns to David Wallace], my gorgeous company... Why don't we all just take a big deep breath... and just relax [breathes in deeply, no one responds]. Good. That feels good [sits]. All right. Everybody feel better?", "order": 18518, "speaker": "Michael Scott"},
  {"season": 4, "episode": 8, "scene": 46, "line": "Two years ago, when you quote 'kissed and fondled in the Chili's parking lot' [Jan sighs and shakes her head], were you aware of company guidelines specifically discouraging that behavior?", "order": 18519, "speaker": "Diane Kelly"},
  {"season": 4, "episode": 8, "scene": 46, "line": "Na, Na-oh, Naa-es.", "order": 18520, "speaker": "Michael Scott"},
  {"season": 4, "episode": 8, "scene": 46, "line": "Yeah, I'm sorry, that's not a word.", "order": 18521, "speaker": "Diane Kelly"},
  {"season": 4, "episode": 8, "scene": 46, "line": "Ye-ohh. Ye-no.", "order": 18522, "speaker": "Michael Scott"},
  {"season": 4, "episode": 8, "scene": 46, "line": "Okay. Were you aware that when Jan was with you, she claimed to be visiting clients, or, uh, traveling to see her sister in Scottsdale?", "order": 18523, "speaker": "Diane Kelly"},
  {"season": 4, "episode": 8, "scene": 46, "line": "Very nice! [Borat voice]", "order": 18524, "speaker": "Michael Scott"},
  {"season": 4, "episode": 8, "scene": 46, "line": "I'm sorry. Excuse me?", "order": 18525, "speaker": "Diane Kelly"},
  {"season": 4, "episode": 8, "scene": 46, "line": "High five! [Borat voice]", "order": 18526, "speaker": "Michael Scott"},
  {"season": 4, "episode": 8, "scene": 46, "line": "Sometimes when he gets nervous he, uh, talks like Borat.", "order": 18527, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 8, "scene": 46, "line": "Okay, Mr. Scott, would you mind, um, speaking in your normal voice for us. Please.", "order": 18528, "speaker": "Diane Kelly"},
  {"season": 4, "episode": 8, "scene": 46, "line": "[shakes head] Can't stop.", "order": 18529, "speaker": "Michael Scott"},
  {"season": 4, "episode": 8, "scene": 46, "line": "Okay [nods].", "order": 18530, "speaker": "Diane Kelly"},
  {"season": 4, "episode": 8, "scene": 46, "line": "Forgot normal talk.", "order": 18531, "speaker": "Michael Scott"},
  {"season": 4, "episode": 9, "scene": 1, "line": "This is ridiculous.", "order": 18532, "speaker": "Stanley Hudson"},
  {"season": 4, "episode": 9, "scene": 1, "line": "Do you have any idea what time we'll get out of here?", "order": 18533, "speaker": "Phyllis Vance"},
  {"season": 4, "episode": 9, "scene": 1, "line": "Nobody likes to work late, least of all me. [to Jim] Do you have plans tonight?", "order": 18534, "speaker": "Michael Scott"},
  {"season": 4, "episode": 9, "scene": 1, "line": "Nope I don't, remember when you told us not to make plans 'cause we're working.", "order": 18535, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 9, "scene": 1, "line": "Yes I remember. Mmm, this is B.S. This is B.S. Why are we here? I am going to call corporate. Enough is enough, I'm - God, I'm so mad! [on phone] This is Michael Scott, Scranton, well we don't want to work. No we don't! It's not fair to these people. These people are my friends and I care about them! We're not going to do it! [hangs up] Everybody I just got off the horn with corporate and basically I told them where they could stick their little overtime assignment. Go enjoy your Friday.", "order": 18536, "speaker": "Michael Scott"},
  {"season": 4, "episode": 9, "scene": 1, "line": "Thank you Michael.", "order": 18537, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 9, "scene": 1, "line": "All right, happy Friday. [to Jim] Well I think we dodged a bullet there.", "order": 18538, "speaker": "Michael Scott"},
  {"season": 4, "episode": 9, "scene": 1, "line": "I think you did.", "order": 18539, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 9, "scene": 1, "line": "I think we should celebrate. How about you, Pam, mi casa, a little dinner, dancing, drinks?", "order": 18540, "speaker": "Michael Scott"},
  {"season": 4, "episode": 9, "scene": 1, "line": "Oh, I-", "order": 18541, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 9, "scene": 1, "line": "You said you didn't have plans. That's what you said.", "order": 18542, "speaker": "Michael Scott"},
  {"season": 4, "episode": 9, "scene": 2, "line": "Michael has asked Pam and me to dinner at least 9 times. And every time we've been able to get out of it. But I've got to give him credit, he got me. Because I'm starting to suspect that there was no assignment from corporate.", "order": 18543, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 9, "scene": 3, "line": "Michael, what time should I be arriving?", "order": 18544, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 9, "scene": 3, "line": "Dwight, it's couples only. And besides, I only have six wine glasses, so it will be me and Jan, and Pam and Jim, and Angela and Andy.", "order": 18545, "speaker": "Michael Scott"},
  {"season": 4, "episode": 9, "scene": 3, "line": "Hey-o!", "order": 18546, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 9, "scene": 4, "line": "Does it bother me that I wasn't invited to Michael's dinner party? [breaks down crying]", "order": 18547, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 9, "scene": 5, "line": "Hi.", "order": 18548, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 9, "scene": 5, "line": "Hello.", "order": 18549, "speaker": "Michael Scott"},
  {"season": 4, "episode": 9, "scene": 5, "line": "Hi.", "order": 18550, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 9, "scene": 5, "line": "How are you?", "order": 18551, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 9, "scene": 5, "line": "Come on in. Good to see you.", "order": 18552, "speaker": "Michael Scott"},
  {"season": 4, "episode": 9, "scene": 5, "line": "Chilly huh? So glad we finally got to do this with you guys. You wanna take their coats babe?", "order": 18553, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 9, "scene": 5, "line": "Yes I would. So, what have you been doing?", "order": 18554, "speaker": "Michael Scott"},
  {"season": 4, "episode": 9, "scene": 5, "line": "Let's see, since I saw you an hour ago?", "order": 18555, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 9, "scene": 5, "line": "Yeah.", "order": 18556, "speaker": "Michael Scott"},
  {"season": 4, "episode": 9, "scene": 5, "line": "I have been getting ready and then driving over here.", "order": 18557, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 9, "scene": 5, "line": "Well we have been doing pretty much the same thing.", "order": 18558, "speaker": "Michael Scott"},
  {"season": 4, "episode": 9, "scene": 5, "line": "Really?", "order": 18559, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 9, "scene": 5, "line": "Except driving.", "order": 18560, "speaker": "Michael Scott"},
  {"season": 4, "episode": 9, "scene": 5, "line": "We got you this. [gives a bottle of wine]", "order": 18561, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 9, "scene": 5, "line": "Oh well Pam, thank you, this will be great to cook with.", "order": 18562, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 9, "scene": 5, "line": "Well have a seat, or come on in, or, I don't know, make yourself to home. This is our casa.", "order": 18563, "speaker": "Michael Scott"},
  {"season": 4, "episode": 9, "scene": 5, "line": "It's really nice.", "order": 18564, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 9, "scene": 5, "line": "So what do you guys think, should we do the tour first or the appetizers first?", "order": 18565, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 9, "scene": 5, "line": "Tour, let's do the tour first.", "order": 18566, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 9, "scene": 5, "line": "Okay.", "order": 18567, "speaker": "Michael Scott"},
  {"season": 4, "episode": 9, "scene": 5, "line": "Do you have a preference babe? Upstairs first?", "order": 18568, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 9, "scene": 5, "line": "Totally your call babe.", "order": 18569, "speaker": "Michael Scott"},
  {"season": 4, "episode": 9, "scene": 5, "line": "Alright, well, let's go then, I say upstairs.", "order": 18570, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 9, "scene": 5, "line": "Oh, you guys doing a little construction?", "order": 18571, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 9, "scene": 5, "line": "Oh, just redoing the sliding glass door.", "order": 18572, "speaker": "Michael Scott"},
  {"season": 4, "episode": 9, "scene": 5, "line": "Yeah, so sorry about this God-awful carpet, we're still a work in progress here.", "order": 18573, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 9, "scene": 5, "line": "Well, thats...", "order": 18574, "speaker": "Michael Scott"},
  {"season": 4, "episode": 9, "scene": 6, "line": "This is my office.", "order": 18575, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 9, "scene": 6, "line": "Yep, never been used.", "order": 18576, "speaker": "Michael Scott"},
  {"season": 4, "episode": 9, "scene": 6, "line": "Not super exciting.", "order": 18577, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 9, "scene": 6, "line": "No.", "order": 18578, "speaker": "Michael Scott"},
  {"season": 4, "episode": 9, "scene": 6, "line": "And this is my workspace.", "order": 18579, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 9, "scene": 6, "line": "This is it, check that out, can you smell that? [the room is filled with candles that say 'Serenity by Jan']", "order": 18580, "speaker": "Michael Scott"},
  {"season": 4, "episode": 9, "scene": 6, "line": "Uh-huh.", "order": 18581, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 9, "scene": 6, "line": "As you can smell there's a lot of different odors going on in here.", "order": 18582, "speaker": "Michael Scott"},
  {"season": 4, "episode": 9, "scene": 6, "line": "So you have an office and a workspace?", "order": 18583, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 9, "scene": 6, "line": "I do, I cannot create in the same space I conduct business, I'm sure that you're the same with your doodles. [puts a candle to Jim's nose] Smell.", "order": 18584, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 9, "scene": 6, "line": "It's fire.", "order": 18585, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 9, "scene": 6, "line": "Uh-huh, Bonfire.", "order": 18586, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 9, "scene": 6, "line": "Bond.", "order": 18587, "speaker": "Michael Scott"},
  {"season": 4, "episode": 9, "scene": 6, "line": "Men love this one.", "order": 18588, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 9, "scene": 6, "line": "James Bond Fire. I am Bond fire, James Bond Fire. Michael Scarn. [clicks tongue]", "order": 18589, "speaker": "Michael Scott"},
  {"season": 4, "episode": 9, "scene": 6, "line": "When I get frustrated, or irritated or... angry, I come up here and I just smell all my candles and it just -poof- goes away.", "order": 18590, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 9, "scene": 6, "line": "Just like that.", "order": 18591, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 9, "scene": 6, "line": "Just like that.", "order": 18592, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 9, "scene": 6, "line": "So this is the master bedroom, and these walls used to like white, like an asylum. So I wanted it to be softer, So I had it painted in eggshell white.", "order": 18593, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 9, "scene": 6, "line": "Guess what, white and eggshell white are exactly the same color.", "order": 18594, "speaker": "Michael Scott"},
  {"season": 4, "episode": 9, "scene": 6, "line": "[puts away video camera facing the bed] Babe I thought you said you were going to tidy things up.", "order": 18595, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 9, "scene": 6, "line": "Well, I-", "order": 18596, "speaker": "Michael Scott"},
  {"season": 4, "episode": 9, "scene": 6, "line": "Shame on you.", "order": 18597, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 9, "scene": 6, "line": "What a cute bench.", "order": 18598, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 9, "scene": 6, "line": "Thanks, that's my bed. Jan has some space issues, so I curl up on that puppy.", "order": 18599, "speaker": "Michael Scott"},
  {"season": 4, "episode": 9, "scene": 6, "line": "Really? 'cause... seems pretty narrow... and short.", "order": 18600, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 9, "scene": 6, "line": "It's actually a lot bigger than it seems. Look at this. [Lies down]", "order": 18601, "speaker": "Michael Scott"},
  {"season": 4, "episode": 9, "scene": 6, "line": "See, he fits perfectly.", "order": 18602, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 9, "scene": 7, "line": "I finally broke down and bought myself a plasma TV. Check it out. [reveals a tiny TV] I actually hung this on the wall myself. I want to show you something . A lot of people in the room, need more space? [moves TV back a couple inches] Voila, right in the wall.", "order": 18603, "speaker": "Michael Scott"},
  {"season": 4, "episode": 9, "scene": 7, "line": "Wow.", "order": 18604, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 9, "scene": 7, "line": "Sometimes I will just stand here and watch television for hours. I love it, I love this TV. Oh and I also built this table.", "order": 18605, "speaker": "Michael Scott"},
  {"season": 4, "episode": 9, "scene": 7, "line": "What is that chestnut?", "order": 18606, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 9, "scene": 7, "line": "No, I think that is either pine, or nordic cherry.", "order": 18607, "speaker": "Michael Scott"},
  {"season": 4, "episode": 9, "scene": 7, "line": "It's pine.", "order": 18608, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 9, "scene": 7, "line": "Michael, I'm just terrible at all this stuff, so that's really cool.", "order": 18609, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 9, "scene": 7, "line": "Really?", "order": 18610, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 9, "scene": 7, "line": "Yeah, he tried to set up my TIVO for me but then I didn't have audio for a week.", "order": 18611, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 9, "scene": 7, "line": "If you ever need any help, I am just a phone call away.", "order": 18612, "speaker": "Michael Scott"},
  {"season": 4, "episode": 9, "scene": 7, "line": "I bet you are.", "order": 18613, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 9, "scene": 7, "line": "Well, I saw - oh your Dundies. I'm surprised they're not out on the coffee table for everybody to see.", "order": 18614, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 9, "scene": 7, "line": "It was between the neon beer sign and the Dundies so I said 'Honey keep the trophies.'", "order": 18615, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 9, "scene": 7, "line": "Oh honey, I have the best trophy right here, aside from my Dundies.", "order": 18616, "speaker": "Michael Scott"},
  {"season": 4, "episode": 9, "scene": 7, "line": "[doorbell] Oh, excuse us just a second.", "order": 18617, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 9, "scene": 7, "line": "I'll get it.", "order": 18618, "speaker": "Michael Scott"},
  {"season": 4, "episode": 9, "scene": 7, "line": "[Andy and Angela enter] So glad you're finally able to be here.", "order": 18619, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 9, "scene": 7, "line": "[Michael tries to hug Angela] No, no.", "order": 18620, "speaker": "Angela Martin"},
  {"season": 4, "episode": 9, "scene": 7, "line": "Okay.", "order": 18621, "speaker": "Michael Scott"},
  {"season": 4, "episode": 9, "scene": 7, "line": "Tuna! What's up Tuna, we having tuna for dinner? [to Pam] I bet you're sick of tuna right? You probably have tuna every night. Tuna! [to Jan] These are for you. [hands flowers]", "order": 18622, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 9, "scene": 7, "line": "Oh, how thoughtful.", "order": 18623, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 9, "scene": 7, "line": "Very nice.", "order": 18624, "speaker": "Michael Scott"},
  {"season": 4, "episode": 9, "scene": 7, "line": "Except for one flower, which is for... my flower.", "order": 18625, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 9, "scene": 7, "line": "Aw.", "order": 18626, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 9, "scene": 7, "line": "What am I supposed to do with this?", "order": 18627, "speaker": "Angela Martin"},
  {"season": 4, "episode": 9, "scene": 7, "line": "How 'bout we do the short tour and then I'll start dinner?", "order": 18628, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 9, "scene": 7, "line": "Oh I can help starting dinner if you need it.", "order": 18629, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 9, "scene": 7, "line": "Oh no no no, it's just the osso buco needs to braise for about three hours, everything else is done.", "order": 18630, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 9, "scene": 7, "line": "Three hours from now or three hours from earlier like 4:00?", "order": 18631, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 9, "scene": 7, "line": "You know Pam, in Spain they often don't start eating until midnight.", "order": 18632, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 9, "scene": 7, "line": "When in Rome.", "order": 18633, "speaker": "Michael Scott"},
  {"season": 4, "episode": 9, "scene": 7, "line": "Do you have a preference, upstairs first?", "order": 18634, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 9, "scene": 7, "line": "Whatever you say babe.", "order": 18635, "speaker": "Michael Scott"},
  {"season": 4, "episode": 9, "scene": 7, "line": "Follow me.", "order": 18636, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 9, "scene": 7, "line": "All right.", "order": 18637, "speaker": "Michael Scott"},
  {"season": 4, "episode": 9, "scene": 8, "line": "How 'bout a toast. Shall I? Here's to good friends.", "order": 18638, "speaker": "Michael Scott"},
  {"season": 4, "episode": 9, "scene": 8, "line": "Cheers.", "order": 18639, "speaker": "All"},
  {"season": 4, "episode": 9, "scene": 8, "line": "Kinda sorta an oaky afterbirth.", "order": 18640, "speaker": "Michael Scott"},
  {"season": 4, "episode": 9, "scene": 8, "line": "What was that?", "order": 18641, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 9, "scene": 8, "line": "So music, should we turn some music?", "order": 18642, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 9, "scene": 8, "line": "Yeah that sounds good.", "order": 18643, "speaker": "Michael Scott"},
  {"season": 4, "episode": 9, "scene": 8, "line": "Do you guys remember my old assistant Hunter? He is an excellent songwriter.", "order": 18644, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 9, "scene": 8, "line": "You took me by the hand, Made me a man, That one night, You made everything all right, So raw, so right, All night, all right, Oh yeah, Oh yeah, So raw, so right, All night, all right, Oh yeah.", "order": 18645, "speaker": "Hunter's CD"},
  {"season": 4, "episode": 9, "scene": 8, "line": "You know what, Hunter was a terrible assistant, that is why Ryan fired him.", "order": 18646, "speaker": "Michael Scott"},
  {"season": 4, "episode": 9, "scene": 8, "line": "Well I think he's probably just about as reliable as Pam being that it usually takes you an afternoon to get back to me.", "order": 18647, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 9, "scene": 8, "line": "Sometimes I think she holds on to faxes.", "order": 18648, "speaker": "Angela Martin"},
  {"season": 4, "episode": 9, "scene": 9, "line": "[To camera] I don't care what they say, I just want to eat. Which I realize is a lot to ask for... at a dinner party.", "order": 18649, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 9, "scene": 10, "line": "[playing charades] No it's a... hump. There's a hump.", "order": 18650, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 9, "scene": 10, "line": "Joe Camel!", "order": 18651, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 9, "scene": 10, "line": "Okay yes, first name of that animal and the second name is the state where Helena is the capital.", "order": 18652, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 9, "scene": 10, "line": "Montana.", "order": 18653, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 9, "scene": 10, "line": "Oh!", "order": 18654, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 9, "scene": 10, "line": "Joe Montana!", "order": 18655, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 9, "scene": 10, "line": "Yes! Yes.", "order": 18656, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 9, "scene": 10, "line": "Why didn't you just say 49ers quarterback?", "order": 18657, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 9, "scene": 10, "line": "All right, [rhythim clapping] my my my my turn, my my my my turn, my my my my turn.", "order": 18658, "speaker": "Michael Scott"},
  {"season": 4, "episode": 9, "scene": 10, "line": "Babe can you just like really, woah, could you just simmer down?", "order": 18659, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 9, "scene": 10, "line": "What, no, I'm just making people laugh.", "order": 18660, "speaker": "Michael Scott"},
  {"season": 4, "episode": 9, "scene": 10, "line": "No.", "order": 18661, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 9, "scene": 10, "line": "Yes, I was watching Jim's face.", "order": 18662, "speaker": "Michael Scott"},
  {"season": 4, "episode": 9, "scene": 10, "line": "I was watching Jim.", "order": 18663, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 9, "scene": 10, "line": "And he was laughing.", "order": 18664, "speaker": "Michael Scott"},
  {"season": 4, "episode": 9, "scene": 10, "line": "No smiling.", "order": 18665, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 9, "scene": 10, "line": "Look at him, he's laughing.", "order": 18666, "speaker": "Michael Scott"},
  {"season": 4, "episode": 9, "scene": 11, "line": "Michael and Jan seem to be playing their own separate game and it's called 'Let's see how uncomfortable we can make our guests' and they're both winning. So I'm going to make a run for it. [holds cellphone]", "order": 18667, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 9, "scene": 12, "line": "You'll never guess, I just got a message from my landlord apparently, my apartment flooded, something with a sprinkler. Pam, we should probably get going and see the damage.", "order": 18668, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 9, "scene": 12, "line": "Oh okay.", "order": 18669, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 9, "scene": 12, "line": "Well you don't need two of you to do that.", "order": 18670, "speaker": "Michael Scott"},
  {"season": 4, "episode": 9, "scene": 12, "line": "That's true. Um... dinner sounded delicious. Pam I'll see you at home, thank you so much.", "order": 18671, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 9, "scene": 12, "line": "Oh Jim, I don't think you're going to abandon this party here all by itself.", "order": 18672, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 9, "scene": 12, "line": "I don't know because I everything I own is there.", "order": 18673, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 9, "scene": 12, "line": "You can buy new stuff but you can't buy a new party.", "order": 18674, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 9, "scene": 12, "line": "That's true, that's a great point. Come on down here. Sit down on that couch and be amongst friends and we are not going to think about all your stuff being destroyed, all right.", "order": 18675, "speaker": "Michael Scott"},
  {"season": 4, "episode": 9, "scene": 12, "line": "Michael, you're up.", "order": 18676, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 9, "scene": 12, "line": "All right here we go, this is going to be fun, ready. All right first name is Tom-", "order": 18677, "speaker": "Michael Scott"},
  {"season": 4, "episode": 9, "scene": 12, "line": "No no no, no names, no names, no rhyming, no soundalikes.", "order": 18678, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 9, "scene": 12, "line": "All right, Okay, okay, you're getting inside my head. First name is blank and he goes on a cruise, he goes on a Caribbean CRUISE.", "order": 18679, "speaker": "Michael Scott"},
  {"season": 4, "episode": 9, "scene": 12, "line": "I don't know.", "order": 18680, "speaker": "Angela Martin"},
  {"season": 4, "episode": 9, "scene": 12, "line": "Katie Holmes.", "order": 18681, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 9, "scene": 12, "line": "No, Baah! But he's married to her!", "order": 18682, "speaker": "Michael Scott"},
  {"season": 4, "episode": 9, "scene": 12, "line": "Oh, Dawson's Creek.", "order": 18683, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 9, "scene": 12, "line": "No! It has to be a real person Jim, come on. Okay, no no, I'm gonna pass, I'm gonna pass. Okay, rhymes with Parnold Schporzenegger.", "order": 18684, "speaker": "Michael Scott"},
  {"season": 4, "episode": 9, "scene": 12, "line": "No rhyming!", "order": 18685, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 9, "scene": 12, "line": "Not really a rhyme.", "order": 18686, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 9, "scene": 12, "line": "Another clue, another clue.", "order": 18687, "speaker": "Angela Martin"},
  {"season": 4, "episode": 9, "scene": 12, "line": "Okay, he is the governor of California, he is the Terminator.", "order": 18688, "speaker": "Michael Scott"},
  {"season": 4, "episode": 9, "scene": 12, "line": "Those aren't helpful.", "order": 18689, "speaker": "Angela Martin"},
  {"season": 4, "episode": 9, "scene": 12, "line": "Tom Cruise!", "order": 18690, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 9, "scene": 12, "line": "No!", "order": 18691, "speaker": "Michael Scott"},
  {"season": 4, "episode": 9, "scene": 12, "line": "Time!", "order": 18692, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 9, "scene": 12, "line": "God, does anybody read the paper?!", "order": 18693, "speaker": "Michael Scott"},
  {"season": 4, "episode": 9, "scene": 13, "line": "[Michael offers his coat to Pam, who is shivering] Oh, thank you.", "order": 18694, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 9, "scene": 13, "line": "You're welcome.", "order": 18695, "speaker": "Michael Scott"},
  {"season": 4, "episode": 9, "scene": 13, "line": "[eyes Pam] I'm so, so sorry for the temperature in here. The um, sliding glass door shattered. It's actually a really cute story. Do you wanna tell it babe or should I tell it?", "order": 18696, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 9, "scene": 13, "line": "I don't like that story babe.", "order": 18697, "speaker": "Michael Scott"},
  {"season": 4, "episode": 9, "scene": 13, "line": "Come on, it's a cute story, Michael ran through the sliding glass doors because he thought he heard the ice cream truck!", "order": 18698, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 9, "scene": 13, "line": "Stop, stop it! I mean, I like ice cream. Okay? Sue me. Oh, no, don't. I shouldn't say that jokingly because she will sue me. She loves to sue, loves lawsuits. You know honey, that door was extremely clean and it looked invisible.", "order": 18699, "speaker": "Michael Scott"},
  {"season": 4, "episode": 9, "scene": 13, "line": "You are so right, you're so right because before I lived here, the glass was always covered in smudges, and then I moved in and I cleaned it so I guess that makes me the Devil.", "order": 18700, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 9, "scene": 13, "line": "Ha ha, you are! She is! She is the devil! I'm in hell! Aaahhh I'm burning, help me!", "order": 18701, "speaker": "Michael Scott"},
  {"season": 4, "episode": 9, "scene": 13, "line": "You shouldn't joke about that.", "order": 18702, "speaker": "Angela Martin"},
  {"season": 4, "episode": 9, "scene": 13, "line": "Is there more wine in the kitchen?", "order": 18703, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 9, "scene": 13, "line": "I will get it, I will get it, what kind of hostess would I be if I didn't get. It's okay, I don't mind. In fact you know what, girl's trip, Angela come on. Girl's trip.", "order": 18704, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 9, "scene": 14, "line": "[checking the oven] Uh, not even close.", "order": 18705, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 9, "scene": 14, "line": "So you keep a very tidy house.", "order": 18706, "speaker": "Angela Martin"},
  {"season": 4, "episode": 9, "scene": 14, "line": "You should see our bathroom after Michael takes a bath, whew. But I don't have to tell you Pam.", "order": 18707, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 9, "scene": 14, "line": "No, yeah. What?", "order": 18708, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 9, "scene": 14, "line": "Oh well don't tell me that he's really changed since you guys dated.", "order": 18709, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 9, "scene": 14, "line": "Oh, are you joking?", "order": 18710, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 9, "scene": 14, "line": "Well, Michael told me a little bit about it, but I see the way you look at him.", "order": 18711, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 9, "scene": 14, "line": "I have never, ever dated, or wanted to do anything resembling dating Michael, ever. Not ever, not now, not then, not now, not ever, ever.", "order": 18712, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 9, "scene": 14, "line": "I've noticed how you look at him at the office.", "order": 18713, "speaker": "Angela Martin"},
  {"season": 4, "episode": 9, "scene": 14, "line": "Mmhmm.", "order": 18714, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 9, "scene": 15, "line": "[in the garage] So I spend most of my time right here. Have a hand [the chairs are shaped like hands]. So Jim I noticed you checking out Jan's candles.", "order": 18715, "speaker": "Michael Scott"},
  {"season": 4, "episode": 9, "scene": 15, "line": "Oh no she just put it in front of my face.", "order": 18716, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 9, "scene": 15, "line": "Yeah, did you know that candles are the number one fastest-growing product in the scent aroma market? $2 billion dollar a year industry. And for only $10,000, you could become a co-owner of 'Serenity by Jan' What do you think about that?", "order": 18717, "speaker": "Michael Scott"},
  {"season": 4, "episode": 9, "scene": 15, "line": "Thought about it, I'm in.", "order": 18718, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 9, "scene": 15, "line": "I'm sorry, are you really trying to get us to invest in Jan's company?", "order": 18719, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 9, "scene": 15, "line": "I'm sorry. [doorbell rings] What now?", "order": 18720, "speaker": "Michael Scott"},
  {"season": 4, "episode": 9, "scene": 16, "line": "Coming! [Dwight and a woman are at the door holding wine glasses and a cooler]", "order": 18721, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 9, "scene": 16, "line": "Hello.", "order": 18722, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 9, "scene": 16, "line": "What are you doing here?", "order": 18723, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 9, "scene": 16, "line": "We came here to eat dinner and to party, this is a dinner party right?", "order": 18724, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 9, "scene": 16, "line": "[whispering] Awesome.", "order": 18725, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 9, "scene": 16, "line": "What is he doing here?", "order": 18726, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 9, "scene": 16, "line": "Yes, What are you doing here?", "order": 18727, "speaker": "Angela Martin"},
  {"season": 4, "episode": 9, "scene": 16, "line": "Dwight is my friend.", "order": 18728, "speaker": "Michael Scott"},
  {"season": 4, "episode": 9, "scene": 16, "line": "We weren't invited?", "order": 18729, "speaker": "Woman"},
  {"season": 4, "episode": 9, "scene": 16, "line": "You said that I could not invite Dwight because he was not part of a couple, and because we didn't have enough wine glasses. Dwight brought glasses and a person.", "order": 18730, "speaker": "Michael Scott"},
  {"season": 4, "episode": 9, "scene": 16, "line": "Fine, whatever you want, just like always, whatever you want.", "order": 18731, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 9, "scene": 16, "line": "Whatever I want? It's never whatever I want. When I wanted to see Stomp, and you wanted to see Wicked, what did we see?", "order": 18732, "speaker": "Michael Scott"},
  {"season": 4, "episode": 9, "scene": 16, "line": "We saw Wicked.", "order": 18733, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 9, "scene": 16, "line": "When I said that I wanted to have kids, and you said, you wanted me to have a vasectomy, what did I do? And then when you said that you might want to have kids and I wasn't so sure, Who had the vasectomy reversed? And then when you said you defintely didn't want to have kids, who had it reversed back? Snip snap! Snip snap! Snip snap! I did. You have no idea the physical toll, that three vasectomies have on a person. And I bought this condo to fill with children.", "order": 18734, "speaker": "Michael Scott"},
  {"season": 4, "episode": 9, "scene": 16, "line": "I am so sorry that I don't want to bring kids into this screwed-up world, okay? But look if you want to have kids, then fine you win. Let's have a [bleep]ing kid.", "order": 18735, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 9, "scene": 16, "line": "Do you mean it? You want to have a kid? [Jan walks away]", "order": 18736, "speaker": "Michael Scott"},
  {"season": 4, "episode": 9, "scene": 16, "line": "I hate my life.", "order": 18737, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 9, "scene": 16, "line": "So can we come in?", "order": 18738, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 9, "scene": 17, "line": "Hmmm... Mmm... great turkey leg.", "order": 18739, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 9, "scene": 17, "line": "[crying] I'm just gonna check on dinner. [Jan gets up from the table]", "order": 18740, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 9, "scene": 17, "line": "Angela, would you like some of my beet salad?", "order": 18741, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 9, "scene": 17, "line": "I hate beet salad.", "order": 18742, "speaker": "Angela Martin"},
  {"season": 4, "episode": 9, "scene": 17, "line": "It's actually really good.", "order": 18743, "speaker": "Woman"},
  {"season": 4, "episode": 9, "scene": 17, "line": "Hey hey hey hey. [to Angela] I know you love beet salad. I've seen you eat it many times.", "order": 18744, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 9, "scene": 17, "line": "The thought of popping one of your beets into my mouth makes me want to vomit.", "order": 18745, "speaker": "Angela Martin"},
  {"season": 4, "episode": 9, "scene": 17, "line": "[leans across to Pam whispering] Pam... I hope she didn't do anything to the food.", "order": 18746, "speaker": "Michael Scott"},
  {"season": 4, "episode": 9, "scene": 17, "line": "[whispers] Like... like what?", "order": 18747, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 9, "scene": 17, "line": "I can't prove it but I think she might be trying to poison me. [Jan serves the food] Hey, looks great babe.", "order": 18748, "speaker": "Michael Scott"},
  {"season": 4, "episode": 9, "scene": 17, "line": "Yeah it does.", "order": 18749, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 9, "scene": 18, "line": "I know Jan didn't poison the food. I know that. But if she was going to poison the food of someone at that table wouldn't it be me? Michael's former lover?", "order": 18750, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 9, "scene": 19, "line": "[Michael dips his steak into his wine] Can you not do that? It's disgusting.", "order": 18751, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 9, "scene": 19, "line": "You know I have soft teeth, how can you say that?", "order": 18752, "speaker": "Michael Scott"},
  {"season": 4, "episode": 9, "scene": 19, "line": "Oops.", "order": 18753, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 9, "scene": 19, "line": "Excuse me for a second. [gets up from the table]", "order": 18754, "speaker": "Michael Scott"},
  {"season": 4, "episode": 9, "scene": 19, "line": "[to babysitter] So... how do you guys know each other?", "order": 18755, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 9, "scene": 19, "line": "I was his babysitter.", "order": 18756, "speaker": "Woman"},
  {"season": 4, "episode": 9, "scene": 19, "line": "And now you guys are dating?", "order": 18757, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 9, "scene": 19, "line": "Purely carnal and that's all you need to know.", "order": 18758, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 9, "scene": 19, "line": "Would you write down your e-mail because I have just so many questions...", "order": 18759, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 9, "scene": 19, "line": "E-mail?", "order": 18760, "speaker": "Woman"},
  {"season": 4, "episode": 9, "scene": 19, "line": "Nevermind.", "order": 18761, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 9, "scene": 19, "line": "Ok... alright... here we go. [takes down huge painting behind his seat and puts up a neon beer sign] There. [plugs it in] Oooookay.", "order": 18762, "speaker": "Michael Scott"},
  {"season": 4, "episode": 9, "scene": 19, "line": "That's nice.", "order": 18763, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 9, "scene": 19, "line": "Everybody enjoying their meal?", "order": 18764, "speaker": "Michael Scott"},
  {"season": 4, "episode": 9, "scene": 19, "line": "Hey babe?", "order": 18765, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 9, "scene": 19, "line": "Yeah?", "order": 18766, "speaker": "Michael Scott"},
  {"season": 4, "episode": 9, "scene": 19, "line": "How about we take the beer sign down until our guests leave and then we can discuss it.", "order": 18767, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 9, "scene": 19, "line": "No. No. I'm gonna leave it up. I think it ties the whole room together.", "order": 18768, "speaker": "Michael Scott"},
  {"season": 4, "episode": 9, "scene": 19, "line": "Ok. [gets up and goes over to the stereo and turns on Hunter's CD really loud]", "order": 18769, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 9, "scene": 19, "line": "[to the guests] Jan thinks Hunter is very talented. You know what? I don't think he's that good.", "order": 18770, "speaker": "Michael Scott"},
  {"season": 4, "episode": 9, "scene": 19, "line": "At least he's an artist.", "order": 18771, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 9, "scene": 19, "line": "BFD. I'm a screenwriter.", "order": 18772, "speaker": "Michael Scott"},
  {"season": 4, "episode": 9, "scene": 19, "line": "[shouting] AND I'M A CANDLEMAKER BUT YOU DON'T HEAR ME BRAGGING ABOUT IT!", "order": 18773, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 9, "scene": 19, "line": "NO ALL YOU DO IS YOU GET ME TO TRY TO WORK ON MY RICH FRIENDS!", "order": 18774, "speaker": "Michael Scott"},
  {"season": 4, "episode": 9, "scene": 19, "line": "FOR AN INVESTMENT OPPORTUNITY!", "order": 18775, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 9, "scene": 19, "line": "MAN! I WOULD LOVE TO BURN YOUR CANDLES!", "order": 18776, "speaker": "Michael Scott"},
  {"season": 4, "episode": 9, "scene": 19, "line": "YOU BURN IT. YOU BUY IT!", "order": 18777, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 9, "scene": 19, "line": "OH GOOD. I'LL BE YOUR FIRST CUSTOMER!", "order": 18778, "speaker": "Michael Scott"},
  {"season": 4, "episode": 9, "scene": 19, "line": "AND YOU'RE HARDLY MY FIRST!", "order": 18779, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 9, "scene": 19, "line": "[yells] THAT'S WHAT SHE SAID! [Jan gets an evil look on her face and picks up Michael's dundie and throws it into his plasma screen tv] THAT IS A 200 DOLLAR PLASMA SCREEN TV YOU JUST KILLED! Good luck paying me back on your zero dollars a year salary plus benefits, babe! [Jan goes upstairs crying.]", "order": 18780, "speaker": "Michael Scott"},
  {"season": 4, "episode": 9, "scene": 19, "line": "I'm gonna get going.", "order": 18781, "speaker": "Woman"},
  {"season": 4, "episode": 9, "scene": 19, "line": "Fine. Get outta here.", "order": 18782, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 9, "scene": 19, "line": "It's getting late.", "order": 18783, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 9, "scene": 19, "line": "You know what guys, she'll be out of the bathroom soon.", "order": 18784, "speaker": "Michael Scott"},
  {"season": 4, "episode": 9, "scene": 20, "line": "[Jim and Pam head out the door and there are policemen at the door] Of course", "order": 18785, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 9, "scene": 20, "line": "Get out of my way, I'll take care of this. OK.[to police] Uhhh what seems to be the problem officers?", "order": 18786, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 9, "scene": 20, "line": "Not now Dwight, We got a call about a disturbance?", "order": 18787, "speaker": "Officer 1"},
  {"season": 4, "episode": 9, "scene": 20, "line": "Nope. Nope. Nothing disturbing here. Just a couple of friends having an awesome dinner party.", "order": 18788, "speaker": "Michael Scott"},
  {"season": 4, "episode": 9, "scene": 20, "line": "The neighbors said they heard some shouting.", "order": 18789, "speaker": "Officer 2"},
  {"season": 4, "episode": 9, "scene": 20, "line": "Oh... yes. There was some screaming but... um... my girlfriend... threw a dundie at my TV. Plasma.", "order": 18790, "speaker": "Michael Scott"},
  {"season": 4, "episode": 9, "scene": 20, "line": "You wanna press charges?", "order": 18791, "speaker": "Officer 1"},
  {"season": 4, "episode": 9, "scene": 20, "line": "Would she get into trouble?", "order": 18792, "speaker": "Michael Scott"},
  {"season": 4, "episode": 9, "scene": 20, "line": "Yes. She'd be charged.", "order": 18793, "speaker": "Officer"},
  {"season": 4, "episode": 9, "scene": 20, "line": "I will take the fall. I did it.", "order": 18794, "speaker": "Michael Scott"},
  {"season": 4, "episode": 9, "scene": 20, "line": "You know you don't have to press charges, you could just try to be more quiet.", "order": 18795, "speaker": "Officer 2"},
  {"season": 4, "episode": 9, "scene": 20, "line": "[running out the door)] Michael! [pleading] Michael. What are you doing to him?", "order": 18796, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 9, "scene": 20, "line": "Sir, do you have any other place you can stay? Maybe with one of your friends here?", "order": 18797, "speaker": "Officer 1"},
  {"season": 4, "episode": 9, "scene": 20, "line": "[puts his hand on the Officer's shoulder] Michael can come home with me.", "order": 18798, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 9, "scene": 20, "line": "Jim? Pam?", "order": 18799, "speaker": "Michael Scott"},
  {"season": 4, "episode": 9, "scene": 20, "line": "Oh... my apartment's on fire", "order": 18800, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 9, "scene": 20, "line": "[whispers] Flooded.", "order": 18801, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 9, "scene": 20, "line": "Flooded.", "order": 18802, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 9, "scene": 20, "line": "You people. [to Michael] Come on. You're sleeping with me.", "order": 18803, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 9, "scene": 20, "line": "No.", "order": 18804, "speaker": "Michael Scott"},
  {"season": 4, "episode": 9, "scene": 20, "line": "I'm gonna take you home Michael.", "order": 18805, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 9, "scene": 20, "line": "Nope.", "order": 18806, "speaker": "Michael Scott"},
  {"season": 4, "episode": 9, "scene": 20, "line": "Alright. [leads Michael away]", "order": 18807, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 9, "scene": 20, "line": "Bye Babe!", "order": 18808, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 9, "scene": 21, "line": "[Pam and Jim are sitting in Jim's car in front of Detroit Coney Island Chili Dogs] This is the best burger I've ever had, babe.", "order": 18809, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 9, "scene": 21, "line": "You know, babe, I should have told you but... I did something bad.", "order": 18810, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 9, "scene": 21, "line": "Hmm?", "order": 18811, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 9, "scene": 21, "line": "[holds up Hunter's CD] I stole this. [Pam laughs] For you babe.", "order": 18812, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 9, "scene": 22, "line": "[Hunter's voice starts singing, Pam joins in] One night...", "order": 18813, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 9, "scene": 27, "line": "My ideals at a party? Easy. Jim. Pam. Ryan. The Mayor. Barack Obama. Shiloh Jolie-Pitt. Because at the end of the night, Brad or Angelina would have to come and pick him up and I would get to meet them. Shia La Beef because of Disturbia. Umm, all of the children of the world. Val Kilmer. But he probably wouldn't come, too famous. Obviously George Clooney. Umm... And Jan definitely, if there was room.", "order": 18814, "speaker": "Michael Scott"},
  {"season": 4, "episode": 9, "scene": 28, "line": "I'll tell you about my ideal dinner party. John Wilkes Booth. Lee Harvey Oswald. Osama Bin Laden. John Wayne Gacy. Jeffrey Lionel Dahmer. 'Oh hey guys, welcome to my home. Oh, here comes the waiter with the soup, hope you guys are hungry.' Meanwhile, the waiter suddenly nods at me. [Dwight nods] But I don't acknowledge him. But I know he's an assassin because I trained him. Boom. Two minutes later, five dead psychopaths at my dining room table. All that's left to do is dispose of the bodies and collect the reward. Ugh, it's almost too easy.", "order": 18815, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 9, "scene": 29, "line": "A little amuse-bouche, anyone?", "order": 18816, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 9, "scene": 29, "line": "[to Jim, quietly] Food!", "order": 18817, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 9, "scene": 29, "line": "Okay, trivia! Does anyone know what that means?", "order": 18818, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 9, "scene": 29, "line": "I believe it means mouth pleaser.", "order": 18819, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 9, "scene": 29, "line": "Oh!", "order": 18820, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 9, "scene": 29, "line": "It's French. I studied some French during my semester at sea. Or, shall I say a semester at la mer, which is French for 'sea.'", "order": 18821, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 9, "scene": 29, "line": "Andy, was that coordinated by the Cornell Study Abroad Office?", "order": 18822, "speaker": "Angela Martin"},
  {"season": 4, "episode": 9, "scene": 29, "line": "Mm-hmm, mm-hmm.", "order": 18823, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 9, "scene": 29, "line": "The reason I ask is that Andy went to Cornell.", "order": 18824, "speaker": "Angela Martin"},
  {"season": 4, "episode": 9, "scene": 29, "line": "[Michael comes out of the powder room to rejoin the group] Ahh, did you wash your hands, babe?", "order": 18825, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 9, "scene": 29, "line": "Yes, I did, for you, princess. Even though I only went number one. [quietly to Jim] I didn't really wash my hands. Ohhh, what have we here? This looks delicious. [picks up amuse-bouche tray]", "order": 18826, "speaker": "Michael Scott"},
  {"season": 4, "episode": 9, "scene": 29, "line": "[quietly] Not with your hands.", "order": 18827, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 9, "scene": 29, "line": "[touching and moving each amuse-bouche] They need to be presented... royally... anyone?", "order": 18828, "speaker": "Michael Scott"},
  {"season": 4, "episode": 9, "scene": 29, "line": "Mmm. [takes one and eats it]", "order": 18829, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 9, "scene": 29, "line": "Good stuff. All right. Let's get down to some wine drinking.", "order": 18830, "speaker": "Michael Scott"},
  {"season": 4, "episode": 9, "scene": 30, "line": "Oh, my God, [eating a bar in the hallway] I thought I was gonna pass out. [Jim takes it from her and takes a bite]", "order": 18831, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 9, "scene": 30, "line": "We should probably get back.", "order": 18832, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 9, "scene": 30, "line": "Mmm-mm. I'm probably just gonna stay here for a while, say I got lost.", "order": 18833, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 9, "scene": 30, "line": "[coming up suddenly] Are you eating?", "order": 18834, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 9, "scene": 30, "line": "It was just a little...", "order": 18835, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 9, "scene": 30, "line": "I didn't have anything, Jan.", "order": 18836, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 9, "scene": 30, "line": "Really, Pam. This is a dinner party. [walks away. Pam looks at Jim accusingly]", "order": 18837, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 9, "scene": 30, "line": "I'm sorry. She scares me. [walks away, Pam keeps eating]", "order": 18838, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 9, "scene": 31, "line": "Michael's dinner party is for couples only, which is why I wasn't initially invited. But that was then. I very recently entered into a serious, monogamous relationship. [looking around] If that idiot ever gets here.", "order": 18839, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 9, "scene": 32, "line": "So, this girl that I basically grew up with becomes Miss West Virginia, and then suddenly, all the boys start paying attention to me. And it was funny because, you know, I never even considered myself beautiful until people started pointing it out to me. You know, 'Jan, wow, you are so much more beautiful than Miss West Virginia.' [laughs] Ah, so that is why I uh... wait, Pam, what was your, what was your question?'", "order": 18840, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 9, "scene": 32, "line": "Where did you grow up?", "order": 18841, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 9, "scene": 33, "line": "It's been a rough couple of weeks.", "order": 18842, "speaker": "Michael Scott"},
  {"season": 4, "episode": 9, "scene": 33, "line": "Well, I just got into a little trouble with the neighbors.", "order": 18843, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 9, "scene": 33, "line": "Little trouble. Yes. Well, Jan fell asleep during her neighborhood watch shift.", "order": 18844, "speaker": "Michael Scott"},
  {"season": 4, "episode": 9, "scene": 33, "line": "Wow, you did neighborhood watch?", "order": 18845, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 9, "scene": 33, "line": "Yes.", "order": 18846, "speaker": "Michael Scott"},
  {"season": 4, "episode": 9, "scene": 33, "line": "I did, and I had a very late shift and I had a little bit of wine and I fell asleep.", "order": 18847, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 9, "scene": 33, "line": "So she's passed out, and some kids spray-paint a swear word on the neighbors' dog...", "order": 18848, "speaker": "Michael Scott"},
  {"season": 4, "episode": 9, "scene": 33, "line": "Yeah.", "order": 18849, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 9, "scene": 33, "line": "And the neighbors blamed Jan.", "order": 18850, "speaker": "Michael Scott"},
  {"season": 4, "episode": 9, "scene": 33, "line": "Yeah. But, you know, it was my fault.", "order": 18851, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 9, "scene": 33, "line": "It's like, you know, the dog shouldn't have been outside in the first place. Anyway, it's a tragedy, really, because the paint is not gonna come off the dog until it sheds...", "order": 18852, "speaker": "Michael Scott"},
  {"season": 4, "episode": 9, "scene": 33, "line": "Mm-hmm.", "order": 18853, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 9, "scene": 33, "line": "So...", "order": 18854, "speaker": "Michael Scott"},
  {"season": 4, "episode": 9, "scene": 33, "line": "Yeah.", "order": 18855, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 9, "scene": 33, "line": "Well.", "order": 18856, "speaker": "Michael Scott"},
  {"season": 4, "episode": 9, "scene": 34, "line": "It was me!", "order": 18857, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 9, "scene": 34, "line": "What's that?", "order": 18858, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 9, "scene": 34, "line": "I spray-painted that dog. It was sleek, and shiny, and perfect little haircut, it was parading around like it was God's gift to this God-forsaken town [laughs] and I just couldn't take it anymore, so I went over there and I held down my knee and I just spray-painted it until I was good and done. [claps] It bit me on the arm, [reaches out and taps Jim] I didn't even feel it.", "order": 18859, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 9, "scene": 35, "line": "What are you doing to him? What... [sees neighbors' dog] is this about that stupid, ugly mutt?", "order": 18860, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 9, "scene": 35, "line": "She's a Weimaraner, you bitch!", "order": 18861, "speaker": "Neighbor"},
  {"season": 4, "episode": 9, "scene": 35, "line": "Please, please. Don't hurt him, okay?", "order": 18862, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 10, "scene": 1, "line": "W.B. Jones is renovating their offices and their construction crews are taking up some of the parking spaces we used to get.", "order": 18863, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 10, "scene": 1, "line": "So we had to park at a satellite parking lot over there.", "order": 18864, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 10, "scene": 1, "line": "Which just means we get to see more of our lovely street. Tell them what we saw today Jim.", "order": 18865, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 10, "scene": 1, "line": "Oh today, we saw a junk yard dog attacking the bones of a rotisserie chicken.", "order": 18866, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 10, "scene": 1, "line": "Nature.", "order": 18867, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 10, "scene": 2, "line": "Been here nine years. Now all of a sudden I'm supposed to park half a mile away.", "order": 18868, "speaker": "Oscar Martinez"},
  {"season": 4, "episode": 10, "scene": 2, "line": "I lost a penny out of my loafers, Oscar.", "order": 18869, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 10, "scene": 3, "line": "[sitting down, rubbing his feet] I will quit. As God as my witness, I will quit if this is not fixed.", "order": 18870, "speaker": "Kevin Malone"},
  {"season": 4, "episode": 10, "scene": 4, "line": "Some of us like the walk more than others.", "order": 18871, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 10, "scene": 4, "line": "[off screen] Hurts like hell.", "order": 18872, "speaker": "Kevin Malone"},
  {"season": 4, "episode": 10, "scene": 5, "line": "[to Michael who is looking at a chair catalog] Did you pick a new chair? It's been awhile.", "order": 18873, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 10, "scene": 5, "line": "Pam, when I first opened this catalog, I thought I was just going to be picking out a chair.", "order": 18874, "speaker": "Michael Scott"},
  {"season": 4, "episode": 10, "scene": 5, "line": "But instead, you found something to distract you from ever picking out a chair.", "order": 18875, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 10, "scene": 6, "line": "Michael started the process of selecting a new chair about three weeks ago. And normally I wouldn't care, but he promised me his old one. It's way better. It's one of these. [makes a shrrhhh sound of a chair moving downward as Pam slumps down] I really want it.", "order": 18876, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 10, "scene": 7, "line": "[holding up the catalog] Have you ever seen this woman?", "order": 18877, "speaker": "Michael Scott"},
  {"season": 4, "episode": 10, "scene": 7, "line": "Her?", "order": 18878, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 10, "scene": 7, "line": "Hmm...", "order": 18879, "speaker": "Michael Scott"},
  {"season": 4, "episode": 10, "scene": 7, "line": "The one in the really great mesh black high-back swivel chair?", "order": 18880, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 10, "scene": 7, "line": "Look at her smile. Those eyes, look at her eyes. She's got - I don't know what it is actually, she dresses like a professional and yet you know there is a side of her that could just curl up on a couch.", "order": 18881, "speaker": "Michael Scott"},
  {"season": 4, "episode": 10, "scene": 7, "line": "Or in a great chair.", "order": 18882, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 10, "scene": 7, "line": "Oh, yeah, maybe, but remember after my dinner party when I said that I was swearing off women?", "order": 18883, "speaker": "Michael Scott"},
  {"season": 4, "episode": 10, "scene": 7, "line": "I definitely remember your dinner party.", "order": 18884, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 10, "scene": 7, "line": "I think what I meant was that I was completely swearing off one woman. [whispers] Jan. I think that fate put this catalog in my hands.", "order": 18885, "speaker": "Michael Scott"},
  {"season": 4, "episode": 10, "scene": 7, "line": "Actually, I put the catalog in your hands 'cause you have to pick out a new chair.", "order": 18886, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 10, "scene": 8, "line": "What is it like being single? I like it. I like starting each day with a sense of possibility. And I'm optimistic because every day I get a little more desperate and desperate situations yield the quickest results.", "order": 18887, "speaker": "Michael Scott"},
  {"season": 4, "episode": 10, "scene": 9, "line": "Ladies and gentleman, would you please open your supply catalogs and kindly turn to page 85. [phone rings]", "order": 18888, "speaker": "Michael Scott"},
  {"season": 4, "episode": 10, "scene": 9, "line": "[on phone] Yo, is this his new chair?", "order": 18889, "speaker": "Creed Bratton"},
  {"season": 4, "episode": 10, "scene": 9, "line": "[on phone] No, he hasn't picked one yet.", "order": 18890, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 10, "scene": 9, "line": "[on phone] Daat!", "order": 18891, "speaker": "Creed Bratton"},
  {"season": 4, "episode": 10, "scene": 10, "line": "When Pam gets Michael's old chair, I get Pam's old chair. Then I'll have two chairs. Only one to go.", "order": 18892, "speaker": "Creed Bratton"},
  {"season": 4, "episode": 10, "scene": 11, "line": "I am ready to start dating again. Getting back on the market. So, FYI, for those of you who are thinking about fixing me up with any of your friends, use the woman on page 85 as a template. That will be all.", "order": 18893, "speaker": "Michael Scott"},
  {"season": 4, "episode": 10, "scene": 12, "line": "I left my cell phone in my car.", "order": 18894, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 10, "scene": 12, "line": "Call us when you get there so we know you're OK. [Andy gets up and leaves]", "order": 18895, "speaker": "Phyllis Vance"},
  {"season": 4, "episode": 10, "scene": 13, "line": "[to Kevin] Listen man, I am completely over Jan, so, would you set me up?", "order": 18896, "speaker": "Michael Scott"},
  {"season": 4, "episode": 10, "scene": 13, "line": "If I had someone to set you up with Michael, then I'd take her for myself.", "order": 18897, "speaker": "Kevin Malone"},
  {"season": 4, "episode": 10, "scene": 13, "line": "I thought that you were engaged?", "order": 18898, "speaker": "Michael Scott"},
  {"season": 4, "episode": 10, "scene": 13, "line": "Nope. Stacy broke up with me.", "order": 18899, "speaker": "Kevin Malone"},
  {"season": 4, "episode": 10, "scene": 13, "line": "Whaaaat? God, that's terrible man. Eghh, she's crazy. Umm, are you still on good terms with any of her friends?", "order": 18900, "speaker": "Michael Scott"},
  {"season": 4, "episode": 10, "scene": 13, "line": "Not anymore.", "order": 18901, "speaker": "Kevin Malone"},
  {"season": 4, "episode": 10, "scene": 13, "line": "Oh...", "order": 18902, "speaker": "Michael Scott"},
  {"season": 4, "episode": 10, "scene": 13, "line": "It's a bitter situation.", "order": 18903, "speaker": "Kevin Malone"},
  {"season": 4, "episode": 10, "scene": 13, "line": "Yeah... ugh. She's... [gets up and leaves] you don't deserve her. Alright.", "order": 18904, "speaker": "Michael Scott"},
  {"season": 4, "episode": 10, "scene": 14, "line": "[sitting on Oscar's desk] Oh hello Oscar Mayer Weiner lover. I bet that you have a bunch of very liberal girl type friends that trust you implicitly because they know you'd never touch 'em, because of your condition. Umm...", "order": 18905, "speaker": "Michael Scott"},
  {"season": 4, "episode": 10, "scene": 14, "line": "Michael?", "order": 18906, "speaker": "Phyllis Vance"},
  {"season": 4, "episode": 10, "scene": 14, "line": "What?", "order": 18907, "speaker": "Michael Scott"},
  {"season": 4, "episode": 10, "scene": 14, "line": "I have a friend who's single.", "order": 18908, "speaker": "Phyllis Vance"},
  {"season": 4, "episode": 10, "scene": 14, "line": "Oh.", "order": 18909, "speaker": "Michael Scott"},
  {"season": 4, "episode": 10, "scene": 14, "line": "Sandy. She's gorgeous and she's got a feisty personality, too.", "order": 18910, "speaker": "Phyllis Vance"},
  {"season": 4, "episode": 10, "scene": 14, "line": "Hmm, I see, feisty. So she's not jolly or sassy? Not like a jolly, sassy opera singer?", "order": 18911, "speaker": "Michael Scott"},
  {"season": 4, "episode": 10, "scene": 14, "line": "Umm, no, she's a professional softball player.", "order": 18912, "speaker": "Phyllis Vance"},
  {"season": 4, "episode": 10, "scene": 14, "line": "Oooo. Catcher or in field?", "order": 18913, "speaker": "Michael Scott"},
  {"season": 4, "episode": 10, "scene": 14, "line": "Umm I don't know Michael.", "order": 18914, "speaker": "Phyllis Vance"},
  {"season": 4, "episode": 10, "scene": 14, "line": "Is she a dress wearer or a pants wearer? Could we share a row boat? Could, could a row boat support her?", "order": 18915, "speaker": "Michael Scott"},
  {"season": 4, "episode": 10, "scene": 14, "line": "What are you asking?", "order": 18916, "speaker": "Phyllis Vance"},
  {"season": 4, "episode": 10, "scene": 14, "line": "I think I'm being very clear what I'm asking. Would an average size row boat support her without capsizing? [silence] It bothers me that you're not answering the question.", "order": 18917, "speaker": "Michael Scott"},
  {"season": 4, "episode": 10, "scene": 14, "line": "No, alright no, she can't fit in a row boat.", "order": 18918, "speaker": "Phyllis Vance"},
  {"season": 4, "episode": 10, "scene": 14, "line": "Damn it, I knew it! I knew it Phyllis! OK!", "order": 18919, "speaker": "Michael Scott"},
  {"season": 4, "episode": 10, "scene": 15, "line": "What is wrong with these people? I would do anything for them and they're just hanging me out to dry.", "order": 18920, "speaker": "Michael Scott"},
  {"season": 4, "episode": 10, "scene": 16, "line": "Dating shouldn't be hard for somebody like me, but it is and you know why? Because nobody here is willing to help me. Nothing would ever get done in this office without a formal request, would it? Well, fine. Here goes.", "order": 18921, "speaker": "Michael Scott"},
  {"season": 4, "episode": 10, "scene": 16, "line": "I don't think that this is---", "order": 18922, "speaker": "Angela Martin"},
  {"season": 4, "episode": 10, "scene": 16, "line": "Well, now OK, I know that this is probably not appropriate, but I need help. Because I want to play ball with my kids before I get too old. And before that happens I need to get laid. And before that happens I need to be in love. And I don't wanna hear 'Ahnnn... I can't help elhh la la.' No. No. I'm a catch and I am not going to be the one who got away. So, this is what we're going to do. Dwight is going to hand out index cards and I want you all to write down the name of an eligible woman for me to date by the end of the day. No, by the end of the hour or you are fired.", "order": 18923, "speaker": "Michael Scott"},
  {"season": 4, "episode": 10, "scene": 16, "line": "[gets up from chair] Write legibly people.", "order": 18924, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 10, "scene": 17, "line": "Because of the construction at W.B. Jones, half of us have to park in the satellite lot.", "order": 18925, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 10, "scene": 17, "line": "Hmm..", "order": 18926, "speaker": "Michael Scott"},
  {"season": 4, "episode": 10, "scene": 17, "line": "It's like a ten minute walk.", "order": 18927, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 10, "scene": 17, "line": "No, thirty.", "order": 18928, "speaker": "Kevin Malone"},
  {"season": 4, "episode": 10, "scene": 17, "line": "Well, look, I am in an assigned parking place in front, so... Alright, alright, alright, umm let me try to think about what it would be like to not have one. [thinks] OK, yes that would be bad.", "order": 18929, "speaker": "Michael Scott"},
  {"season": 4, "episode": 10, "scene": 17, "line": "Yes.", "order": 18930, "speaker": "Kevin Malone"},
  {"season": 4, "episode": 10, "scene": 17, "line": "Yes.", "order": 18931, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 10, "scene": 17, "line": "That would be bad.", "order": 18932, "speaker": "Michael Scott"},
  {"season": 4, "episode": 10, "scene": 17, "line": "OK.", "order": 18933, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 10, "scene": 17, "line": "Nice.", "order": 18934, "speaker": "Kevin Malone"},
  {"season": 4, "episode": 10, "scene": 17, "line": "...So help us out.", "order": 18935, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 10, "scene": 17, "line": "Wish I could, but I can't. Well can, but won't. Should, maybe, but shorn't.", "order": 18936, "speaker": "Michael Scott"},
  {"season": 4, "episode": 10, "scene": 17, "line": "Michael, please he--", "order": 18937, "speaker": "Kevin Malone"},
  {"season": 4, "episode": 10, "scene": 17, "line": "What part of shorn't don't you understand, Kevin? Look I could probably handle it, yes, but I think it would be a good exercise for you guys to do it yourselves.", "order": 18938, "speaker": "Michael Scott"},
  {"season": 4, "episode": 10, "scene": 17, "line": "We won't let you down.", "order": 18939, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 10, "scene": 17, "line": "Oh you can't because I don't care. Listen, [Kevin and Andy high five as they leave Michael's office] don't forget to fill out those cards. My love cards.", "order": 18940, "speaker": "Michael Scott"},
  {"season": 4, "episode": 10, "scene": 18, "line": "There's nobody I hate enough to write her name on this card.", "order": 18941, "speaker": "Stanley Hudson"},
  {"season": 4, "episode": 10, "scene": 18, "line": "Well, I'm setting Michael up with my fat friend anyway, he can just deal with it.", "order": 18942, "speaker": "Phyllis Vance"},
  {"season": 4, "episode": 10, "scene": 18, "line": "[to Jim] Who are you putting down?", "order": 18943, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 10, "scene": 18, "line": "Oh, you don't know her.", "order": 18944, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 10, "scene": 18, "line": "Who is it?", "order": 18945, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 10, "scene": 18, "line": "Your mom.", "order": 18946, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 10, "scene": 18, "line": "Yeah, whatever. [Jim holds up the card] [Pam laughs] Give it to me. Give it to me.", "order": 18947, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 10, "scene": 19, "line": "Ok, Wendy. Hot and juicy red head. Give this a try. [Michael dials phone number. Phone rings.]", "order": 18948, "speaker": "Michael Scott"},
  {"season": 4, "episode": 10, "scene": 19, "line": "Wendy's.", "order": 18949, "speaker": "Wendy's phone operator"},
  {"season": 4, "episode": 10, "scene": 19, "line": "Hello Wendy, this is Kevin's friend, Michael.", "order": 18950, "speaker": "Michael Scott"},
  {"season": 4, "episode": 10, "scene": 19, "line": "This isn't Wendy.", "order": 18951, "speaker": "Wendy's phone operator"},
  {"season": 4, "episode": 10, "scene": 19, "line": "Oh, I'm sorry, could you put her on please?", "order": 18952, "speaker": "Michael Scott"},
  {"season": 4, "episode": 10, "scene": 19, "line": "Dude, this is a Wendy's restaurant.", "order": 18953, "speaker": "Wendy's phone operator"},
  {"season": 4, "episode": 10, "scene": 19, "line": "[under breath] Damn it Kevin. OK, umm, could I just have a frosty and a baked potato please?", "order": 18954, "speaker": "Michael Scott"},
  {"season": 4, "episode": 10, "scene": 19, "line": "You have to come to the restaurant to order food.", "order": 18955, "speaker": "Wendy's phone operator"},
  {"season": 4, "episode": 10, "scene": 19, "line": "Well, I'll send somebody to come pick it up. Just have it ready.", "order": 18956, "speaker": "Michael Scott"},
  {"season": 4, "episode": 10, "scene": 19, "line": "It's ready now.", "order": 18957, "speaker": "Wendy's phone operator"},
  {"season": 4, "episode": 10, "scene": 19, "line": "Well put it aside. [hangs up the phone] [Dwight enters] Umm... yeah.", "order": 18958, "speaker": "Michael Scott"},
  {"season": 4, "episode": 10, "scene": 19, "line": "OK, I have collected the rest of the ladies.", "order": 18959, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 10, "scene": 19, "line": "Good. Good because this batch was awful. Umm... this one says chair model...", "order": 18960, "speaker": "Michael Scott"},
  {"season": 4, "episode": 10, "scene": 19, "line": "I wrote that. Michael, you shouldn't have to settle. This is my pledge to you. I will find her and I will bring her to you and as God as my witness, she shall bear your fruit.", "order": 18961, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 10, "scene": 19, "line": "That sounds good. [both smile] Go get her. Wa-Wait, wait, wait, wait. First, go to Wendy's, get my food. Come back and then go.", "order": 18962, "speaker": "Michael Scott"},
  {"season": 4, "episode": 10, "scene": 20, "line": "[on phone] That is fantastic. Thank you, thank you very much. This is just what I needed.", "order": 18963, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 10, "scene": 21, "line": "The furniture company gave me the name of the advertising agency. They gave me the name of the photographer. The photographer, a Spaniard, used a Wilkes-Barre modeling agency. The agency gave me the following information. [reads from notepad] Deborah Shoshlefski. 142 South Windsor Lane. Dead. Car accident. Case closed.", "order": 18964, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 10, "scene": 22, "line": "She's dead? [Dwight makes knife slitting noise] She's so young.", "order": 18965, "speaker": "Michael Scott"},
  {"season": 4, "episode": 10, "scene": 22, "line": "She was so young and now she is dead. As dead as every dead animal who has ever died.", "order": 18966, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 10, "scene": 22, "line": "Oh, God. Oh...", "order": 18967, "speaker": "Michael Scott"},
  {"season": 4, "episode": 10, "scene": 22, "line": "Why don't you sit down? Michael... come on. [gets up and leads Michael to sit in his chair] Here we go. Yeah.", "order": 18968, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 10, "scene": 22, "line": "Michael, you didn't even know her.", "order": 18969, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 10, "scene": 22, "line": "Try not to be so hurtful Jim.", "order": 18970, "speaker": "Michael Scott"},
  {"season": 4, "episode": 10, "scene": 22, "line": "Jim, how dare you. [Jim's face is in shock]", "order": 18971, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 10, "scene": 22, "line": "Please, not at a time like this. [sighs]", "order": 18972, "speaker": "Michael Scott"},
  {"season": 4, "episode": 10, "scene": 22, "line": "OK Michael, you know what? I might have someone for you.", "order": 18973, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 10, "scene": 22, "line": "Oh really? What's her name? Burger King?", "order": 18974, "speaker": "Michael Scott"},
  {"season": 4, "episode": 10, "scene": 22, "line": "No, I mean it. [walks towards Michael] She's really nice and sweet and you guys might actually get along. [hands over card to Michael]", "order": 18975, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 10, "scene": 22, "line": "I don't, I don't think I'm ready. Is she hot?", "order": 18976, "speaker": "Michael Scott"},
  {"season": 4, "episode": 10, "scene": 23, "line": "I'm setting Michael up with my land lady. She's really sweet and... whatever, I just can't take Michael like this.", "order": 18977, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 10, "scene": 24, "line": "No question about it, I am ready to get hurt again.", "order": 18978, "speaker": "Michael Scott"},
  {"season": 4, "episode": 10, "scene": 25, "line": "We need to assemble the five families.", "order": 18979, "speaker": "Kevin Malone"},
  {"season": 4, "episode": 10, "scene": 25, "line": "[while brushing teeth with an electric toothbrush] No, not the five families.", "order": 18980, "speaker": "Michael Scott"},
  {"season": 4, "episode": 10, "scene": 25, "line": "We have to.", "order": 18981, "speaker": "Kevin Malone"},
  {"season": 4, "episode": 10, "scene": 26, "line": "The five families are the five companies of Scranton Business Park. The bosses rarely meet. There's Michael Scott, Regional Manager, Dunder Mifflin. Bob Vance, Vance Refrigeration. Paul Faust of Disaster Kits Limited. They call him 'Cool Guy Paul.' W.B. Jones of W.B. Jones Heating and Air. Grade A Bad Ass. And Bill Cress of Cress Tool and Dye. Bill Cress is super old and really mean.", "order": 18982, "speaker": "Kevin Malone"},
  {"season": 4, "episode": 10, "scene": 27, "line": "[still brushing teeth] Sorry, I don't have time for this right now. I'm trying to get a date with Pam's hot friend. And she needs to meet me right now.", "order": 18983, "speaker": "Michael Scott"},
  {"season": 4, "episode": 10, "scene": 27, "line": "[to Andy] I'm calling the meeting anyway.", "order": 18984, "speaker": "Kevin Malone"},
  {"season": 4, "episode": 10, "scene": 28, "line": "[to cashier at coffee shop] Thank you very much. Our suspect has straight brown hair. She is wearing blue jeans and a black top. So... behold our bachelorette [cut to a blonde woman in a skirt]. Give her ten for looks and a three for her ability to describe herself. Hello, my lady. [woman walks right by and doesn't respond]", "order": 18985, "speaker": "Michael Scott"},
  {"season": 4, "episode": 10, "scene": 28, "line": "Michael?", "order": 18986, "speaker": "Margaret"},
  {"season": 4, "episode": 10, "scene": 28, "line": "[turns around to see the woman from his description] Ugh.", "order": 18987, "speaker": "Michael Scott"},
  {"season": 4, "episode": 10, "scene": 28, "line": "Are you Michael Scott?", "order": 18988, "speaker": "Margaret"},
  {"season": 4, "episode": 10, "scene": 28, "line": "Is who a Michael what?", "order": 18989, "speaker": "Michael Scott"},
  {"season": 4, "episode": 10, "scene": 28, "line": "Oh, I'm sorry, I'm, I'm supposed to be meeting someone named Michael.", "order": 18990, "speaker": "Margaret"},
  {"season": 4, "episode": 10, "scene": 28, "line": "Oh, that's not, yeah I'm not... OK...", "order": 18991, "speaker": "Michael Scott"},
  {"season": 4, "episode": 10, "scene": 28, "line": "Michael? Michael? [reads cup] Large hot chocolate with caramel and a shot of peppermint.", "order": 18992, "speaker": "Coffee shop worker"},
  {"season": 4, "episode": 10, "scene": 29, "line": "So you get the rent checks every month and what happens next? What...", "order": 18993, "speaker": "Michael Scott"},
  {"season": 4, "episode": 10, "scene": 29, "line": "You're asking what I do with the checks that people write to me?", "order": 18994, "speaker": "Margaret"},
  {"season": 4, "episode": 10, "scene": 29, "line": "Just making conversation.", "order": 18995, "speaker": "Michael Scott"},
  {"season": 4, "episode": 10, "scene": 30, "line": "Why is it so hard to meet people? I... you know... it's uh... All I want is somebody nice and sweet and someone I can talk to and share an experience with, you know? Why is it so hard for people like us?", "order": 18996, "speaker": "Michael Scott"},
  {"season": 4, "episode": 10, "scene": 30, "line": "I don't know.", "order": 18997, "speaker": "Margaret"},
  {"season": 4, "episode": 10, "scene": 30, "line": "You wanna see what I walked out on? This is gonna blow your mind. [holds up cell phone with a picture of Jan] Look at that.", "order": 18998, "speaker": "Michael Scott"},
  {"season": 4, "episode": 10, "scene": 30, "line": "She's beautiful.", "order": 18999, "speaker": "Margaret"},
  {"season": 4, "episode": 10, "scene": 30, "line": "Yeah. Yeah, and you can't see her whole body. Down here, she's got a boob job. Just... she was just crazy smart and really manipulative and I don't know.", "order": 19000, "speaker": "Michael Scott"},
  {"season": 4, "episode": 10, "scene": 30, "line": "[on phone] Michael? Hello? Michael? [Michael hangs up phone]", "order": 19001, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 10, "scene": 30, "line": "I'm gonna head out.", "order": 19002, "speaker": "Margaret"},
  {"season": 4, "episode": 10, "scene": 30, "line": "Oh, OK. Well, umm... I enjoyed this conversation. It was very nice. It was like talking to the sweet old lady on the bus.", "order": 19003, "speaker": "Michael Scott"},
  {"season": 4, "episode": 10, "scene": 30, "line": "That's incredibly rude.", "order": 19004, "speaker": "Margaret"},
  {"season": 4, "episode": 10, "scene": 30, "line": "Now you ruined it. [gets up and leaves]", "order": 19005, "speaker": "Michael Scott"},
  {"season": 4, "episode": 10, "scene": 31, "line": "Where's Scott?", "order": 19006, "speaker": "Bob Vance"},
  {"season": 4, "episode": 10, "scene": 31, "line": "Uh Michael Scott could not make it today due to an unforeseen prior engagement.", "order": 19007, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 10, "scene": 31, "line": "Let's just meet back in an hour.", "order": 19008, "speaker": "W.B. Jones"},
  {"season": 4, "episode": 10, "scene": 31, "line": "Gentleman please. We called this meeting. Andrew Bernard is the name of me. And this is my associate, Mr. Kevin Malone.", "order": 19009, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 10, "scene": 31, "line": "[nervously stuttering] I... have... things...", "order": 19010, "speaker": "Kevin Malone"},
  {"season": 4, "episode": 10, "scene": 31, "line": "Alright, what do you want?", "order": 19011, "speaker": "W.B. Jones"},
  {"season": 4, "episode": 10, "scene": 31, "line": "Well first of all, I'd just like to say [pulls out note cards] what an honor it is to be sitting here with you gentlemen.", "order": 19012, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 10, "scene": 31, "line": "You have about ten seconds---", "order": 19013, "speaker": "W.B. Jones"},
  {"season": 4, "episode": 10, "scene": 31, "line": "We want our parking spaces back!", "order": 19014, "speaker": "Kevin Malone"},
  {"season": 4, "episode": 10, "scene": 31, "line": "Whose parking spaces?", "order": 19015, "speaker": "Paul Faust"},
  {"season": 4, "episode": 10, "scene": 31, "line": "W.B. Jones' construction guys park in our parking spaces every morning and some people have to park really far away and walk all the way to the office. And some people sweat too much for comfort and---", "order": 19016, "speaker": "Kevin Malone"},
  {"season": 4, "episode": 10, "scene": 31, "line": "Ohh... God...", "order": 19017, "speaker": "Bill Cress"},
  {"season": 4, "episode": 10, "scene": 31, "line": "I don't have time for this you guys. [to W.B. Jones] Just give 'em back their spaces.", "order": 19018, "speaker": "Paul"},
  {"season": 4, "episode": 10, "scene": 31, "line": "OK.", "order": 19019, "speaker": "W.B. Jones"},
  {"season": 4, "episode": 10, "scene": 31, "line": "We good? OK. Could have done this over e-mail.", "order": 19020, "speaker": "Paul"},
  {"season": 4, "episode": 10, "scene": 32, "line": "After Stacy left, things did not go well for awhile. And, and it was hard to see... [starts breaking up] It's just nice to win one.", "order": 19021, "speaker": "Kevin Malone"},
  {"season": 4, "episode": 10, "scene": 33, "line": "Margaret the land lady? Really Pam, is that what you think of me?", "order": 19022, "speaker": "Michael Scott"},
  {"season": 4, "episode": 10, "scene": 33, "line": "She's sweet and cute. I thought you'd get along.", "order": 19023, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 10, "scene": 33, "line": "Oh, OK, Well I'm looking for a passionate affair, not companionship. I'm a man... of intensity, of, of cool and youth and, and passionately. God...[walks away back to his office]", "order": 19024, "speaker": "Michael Scott"},
  {"season": 4, "episode": 10, "scene": 33, "line": "[walks up to reception] Margaret?", "order": 19025, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 10, "scene": 33, "line": "I know.", "order": 19026, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 10, "scene": 33, "line": "You just got yourself kicked out of your apartment. [both laugh]", "order": 19027, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 10, "scene": 33, "line": "Oh I don't care, I didn't really like that place that much anyway. I'll just move.", "order": 19028, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 10, "scene": 33, "line": "Oh really? Who's gonna take you in? You're messy. You're a klutz, you spill everything. And you leave the volume on the TV way too loud.", "order": 19029, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 10, "scene": 33, "line": "Yeah, maybe I'll just move in with my boyfriend 'cause he's kind of a slob, too.", "order": 19030, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 10, "scene": 33, "line": "OK, sure. Let's do it. [Pam laughs]", "order": 19031, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 10, "scene": 33, "line": "No, I umm, well I'm not gonna, I'm, I'm not gonna move in with anyone unless I'm engaged.", "order": 19032, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 10, "scene": 33, "line": "Have I not proposed to you, yet?", "order": 19033, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 10, "scene": 33, "line": "Hmm, I don't, know...", "order": 19034, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 10, "scene": 33, "line": "Oh, well, that's coming. [smiles]", "order": 19035, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 10, "scene": 33, "line": "Oh, right now?", "order": 19036, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 10, "scene": 33, "line": "No. Not gonna do it right here, that would be rather lame.", "order": 19037, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 10, "scene": 33, "line": "OK, so then, when?", "order": 19038, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 10, "scene": 33, "line": "Pam, I'm not gonna tell you. Hate to break it to you, but that's not how that works.", "order": 19039, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 10, "scene": 33, "line": "Oh, right, yeah.", "order": 19040, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 10, "scene": 33, "line": "Wait, I'm serious. It's happening.", "order": 19041, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 10, "scene": 33, "line": "Ohh kay.", "order": 19042, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 10, "scene": 33, "line": "And when it happens, it's going to kick your ass, Beesly. So... stay sharp.", "order": 19043, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 10, "scene": 33, "line": "I've been warned.", "order": 19044, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 10, "scene": 34, "line": "I am not kidding. [shows camera an engagement ring] Got it a week after we started dating.", "order": 19045, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 10, "scene": 35, "line": "I don't know man. I was with Jan for so long. I was excited about meeting somebody new. Put my heart out there. It's just... [sighs]", "order": 19046, "speaker": "Michael Scott"},
  {"season": 4, "episode": 10, "scene": 35, "line": "You know what you need? Closure.", "order": 19047, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 10, "scene": 35, "line": "You're right. What do you mean though?", "order": 19048, "speaker": "Michael Scott"},
  {"season": 4, "episode": 10, "scene": 35, "line": "There was a woman in your life who affected you very deeply, and she left before you could say good bye. I think you need to say good bye.", "order": 19049, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 10, "scene": 35, "line": "Hmm.", "order": 19050, "speaker": "Michael Scott"},
  {"season": 4, "episode": 10, "scene": 35, "line": "Come on. I'll drive.", "order": 19051, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 10, "scene": 36, "line": "We did it.", "order": 19052, "speaker": "Kevin Malone"},
  {"season": 4, "episode": 10, "scene": 36, "line": "[at same time as Kevin] I did it.", "order": 19053, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 10, "scene": 36, "line": "We got our spaces back.", "order": 19054, "speaker": "Kevin Malone"},
  {"season": 4, "episode": 10, "scene": 36, "line": "Wow. [Stanley laughs and high fives Kevin]", "order": 19055, "speaker": "Oscar Martinez"},
  {"season": 4, "episode": 10, "scene": 36, "line": "Nice job.", "order": 19056, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 10, "scene": 36, "line": "Nice. [fist bumps Kevin]", "order": 19057, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 10, "scene": 36, "line": "Yeah, there it is. You're welcome.", "order": 19058, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 10, "scene": 37, "line": "Did I do this for me? No, I did this for the little guy. For Joe Sixpack. The guy who wakes up every morning in his four hundred dollar a month apartment, wonders how's he gonna pay his mortgage that month. Wonders how he's gonna fill his car up with oil. Wonders how I'm gonna pay my kid's orphanage bills. That guy shouldn't have to wonder where he's gonna park.", "order": 19059, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 10, "scene": 38, "line": "[looking at the chair model's headstone] How did she die?", "order": 19060, "speaker": "Michael Scott"},
  {"season": 4, "episode": 10, "scene": 38, "line": "I guess you could say she died of blunt force trauma and blood loss. She got in a car accident and plowed into the side of an airplane hanger.", "order": 19061, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 10, "scene": 38, "line": "God.", "order": 19062, "speaker": "Michael Scott"},
  {"season": 4, "episode": 10, "scene": 38, "line": "Yeah.", "order": 19063, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 10, "scene": 38, "line": "She was so innocent.", "order": 19064, "speaker": "Michael Scott"},
  {"season": 4, "episode": 10, "scene": 38, "line": "She was stoned apparently.", "order": 19065, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 10, "scene": 38, "line": "You know I used to think that I had this perfect person out there waiting for me, but knowing that, that's just silly because she's dead. What do you do?", "order": 19066, "speaker": "Michael Scott"},
  {"season": 4, "episode": 10, "scene": 38, "line": "You wait until next year's chair catalog comes out and you find someone who's still alive.", "order": 19067, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 10, "scene": 38, "line": "Oh, you move on.", "order": 19068, "speaker": "Michael Scott"},
  {"season": 4, "episode": 10, "scene": 38, "line": "Yeah.", "order": 19069, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 10, "scene": 39, "line": "Where do you want to go for dinner?", "order": 19070, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 10, "scene": 39, "line": "I don't know, I kind of hate all our regular places right now. Oh, you know what [Jim gets down on one knee], that one...", "order": 19071, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 10, "scene": 39, "line": "Hey Pam, will you wait for me one second while I tie my shoe?", "order": 19072, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 10, "scene": 39, "line": "[smiling] I hate you. [walks away]", "order": 19073, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 10, "scene": 39, "line": "What? My shoe is untied. What is your problem? [Pam laughs] Oh my God, you thought I wa--- oh.", "order": 19074, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 10, "scene": 39, "line": "Oh, oh.", "order": 19075, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 10, "scene": 39, "line": "No, no, no.", "order": 19076, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 10, "scene": 39, "line": "How could I have thought that? How could I have thought that?", "order": 19077, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 10, "scene": 40, "line": "[singing] Bye, bye, Ms. Chair Model lady. I dreamt that we were married and you treated me nice. We had lots of kids...drinking whiskey and rye, oh why'd you have to go off and die? Why'd you have to go off [with Dwight] and die?", "order": 19078, "speaker": "Michael Scott"},
  {"season": 4, "episode": 10, "scene": 41, "line": "[singing] OH! You believe in rock 'n roll. Can music save your mortal soul? And then [jumbling up the words] can you have to dance real slow. Well, I know that you're in love with him. 'Cause I saw you dancing in the gym. [more jumbled words] 'You both kicked off your shoes. Those rhythm and blues...", "order": 19079, "speaker": "Michael Scott & Dwight Schrute"},
  {"season": 4, "episode": 10, "scene": 41, "line": "Return.", "order": 19080, "speaker": "Michael Scott"},
  {"season": 4, "episode": 10, "scene": 41, "line": "Rhythm and blues...", "order": 19081, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 10, "scene": 41, "line": "This'll be [with Dwight] the day that I died.", "order": 19082, "speaker": "Michael Scott"},
  {"season": 4, "episode": 10, "scene": 42, "line": "It is moving day. I have spent the last month here at Dwight's lovely farm, taking a little bit of a vacation, clearing my head after Jan and I, um [Dwight puts his fingers to his head and makes a shooting noise] ssp... No, I did not kill her. We were just at different places in our lives. No, I thought I'd be the bigger man and allow her to stay at the condo. She has since moved on; she is staying with her sister in Scottsdale. Fresh outlook, and it's all good.", "order": 19083, "speaker": "Michael Scott"},
  {"season": 4, "episode": 10, "scene": 42, "line": "Okay, so look that over, make sure it's accurate.", "order": 19084, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 10, "scene": 42, "line": "Oh. What is this?", "order": 19085, "speaker": "Michael Scott"},
  {"season": 4, "episode": 10, "scene": 42, "line": "Your bill, minus the ten percent Dunder Mifflin corporate discount.", "order": 19086, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 10, "scene": 42, "line": "You were charging me? I'm your friend.", "order": 19087, "speaker": "Michael Scott"},
  {"season": 4, "episode": 10, "scene": 42, "line": "You occupied the America room for six weeks. That's our most popular room.", "order": 19088, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 10, "scene": 42, "line": "There was no other guest the entire time I was here.", "order": 19089, "speaker": "Michael Scott"},
  {"season": 4, "episode": 10, "scene": 42, "line": "Right. Because you were in the America room. In an election year.", "order": 19090, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 10, "scene": 42, "line": "Okay. All right, Dwight. Take my money. Go ahead. Keep in mind that this whole thing was just a business transaction. The late nights, the talks, the slumber parties, the crying jags, that was all business, that had nothing to do with friendship, and being friends. Just ring me up. Go ahead [Dwight tears up bill].", "order": 19091, "speaker": "Michael Scott"},
  {"season": 4, "episode": 10, "scene": 42, "line": "Thank you, Dwight.", "order": 19092, "speaker": "Michael Scott"},
  {"season": 4, "episode": 10, "scene": 42, "line": "We don't take debit cards, anyway.", "order": 19093, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 10, "scene": 42, "line": "Dwight, little help with the bags, please.", "order": 19094, "speaker": "Michael Scott"},
  {"season": 4, "episode": 10, "scene": 43, "line": "So, what about you, Angela? Do you have any single friends?", "order": 19095, "speaker": "Michael Scott"},
  {"season": 4, "episode": 10, "scene": 43, "line": "I don't.", "order": 19096, "speaker": "Angela Martin"},
  {"season": 4, "episode": 10, "scene": 43, "line": "A cute little religious type, someone who wears a uniform, or...", "order": 19097, "speaker": "Michael Scott"},
  {"season": 4, "episode": 10, "scene": 43, "line": "A Catholic schoolgirl?", "order": 19098, "speaker": "Angela Martin"},
  {"season": 4, "episode": 10, "scene": 43, "line": "No, no, obviously older.", "order": 19099, "speaker": "Michael Scott"},
  {"season": 4, "episode": 10, "scene": 43, "line": "A nun?", "order": 19100, "speaker": "Angela Martin"},
  {"season": 4, "episode": 10, "scene": 44, "line": "Love. Marriage. Baby carriage. Those have been my goals ever since I heard that song. Jan and I had love. We did not have marriage. We did have a baby carriage, which I got her for bringing groceries home, after she got a DUI.", "order": 19101, "speaker": "Michael Scott"},
  {"season": 4, "episode": 10, "scene": 45, "line": "Hi, Michael.", "order": 19102, "speaker": "Kelly Kapoor"},
  {"season": 4, "episode": 10, "scene": 45, "line": "[sitting at her desk] Hey, Kelly.", "order": 19103, "speaker": "Michael Scott"},
  {"season": 4, "episode": 10, "scene": 45, "line": "Yeah, what are you doing here?", "order": 19104, "speaker": "Kelly Kapoor"},
  {"season": 4, "episode": 10, "scene": 45, "line": "Nothing. Just hangin'. Chillin'. No agenda. Would you consider hookin' me up with one of your friends?", "order": 19105, "speaker": "Michael Scott"},
  {"season": 4, "episode": 10, "scene": 45, "line": "Oh, all my friends are crazy. My one friend, Brianna...", "order": 19106, "speaker": "Kelly Kapoor"},
  {"season": 4, "episode": 10, "scene": 45, "line": "Yeah?", "order": 19107, "speaker": "Michael Scott"},
  {"season": 4, "episode": 10, "scene": 45, "line": "Oh, my God...", "order": 19108, "speaker": "Kelly Kapoor"},
  {"season": 4, "episode": 10, "scene": 45, "line": "That's hilarious. What's her number?", "order": 19109, "speaker": "Michael Scott"},
  {"season": 4, "episode": 10, "scene": 45, "line": "She's 23.", "order": 19110, "speaker": "Kelly Kapoor"},
  {"season": 4, "episode": 10, "scene": 45, "line": "Mmm... nah, that's too young.", "order": 19111, "speaker": "Michael Scott"},
  {"season": 4, "episode": 10, "scene": 45, "line": "Actually, I know a ton of people that I can set you up with!", "order": 19112, "speaker": "Kelly Kapoor"},
  {"season": 4, "episode": 10, "scene": 45, "line": "Oh.", "order": 19113, "speaker": "Michael Scott"},
  {"season": 4, "episode": 10, "scene": 45, "line": "Some of my friends' parents, they're getting divorced now. [Michael gets up and starts walking away] So I think some of those, like, older ladies, they're really looking for a guy to go on a date with.", "order": 19114, "speaker": "Kelly Kapoor"},
  {"season": 4, "episode": 10, "scene": 46, "line": "['Rita Klondike 507' is written on an index card] All right, time's up! Pencils down. Please pass your future Mrs. Michael Scotts forward.", "order": 19115, "speaker": "Michael Scott"},
  {"season": 4, "episode": 10, "scene": 47, "line": "Forcing your employees to set you up is not a, uh, uh, technical violation of any Dunder Mifflin rule. You know, but neither is forcing them to help you with a shot-by-shot remake of Indiana Jones. Huh-how do you make a rule book like that?", "order": 19116, "speaker": "Toby Flenderson"},
  {"season": 4, "episode": 10, "scene": 48, "line": "Pammy?", "order": 19117, "speaker": "Michael Scott"},
  {"season": 4, "episode": 10, "scene": 48, "line": "I don't have anybody.", "order": 19118, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 10, "scene": 48, "line": "You don't have one single girlfriend?", "order": 19119, "speaker": "Michael Scott"},
  {"season": 4, "episode": 10, "scene": 48, "line": "No...", "order": 19120, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 10, "scene": 48, "line": "I'm not looking for a perfect 10 here, just somebody to hang out with. Just fun, healthy young woman.", "order": 19121, "speaker": "Michael Scott"},
  {"season": 4, "episode": 10, "scene": 48, "line": "Sorry.", "order": 19122, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 10, "scene": 48, "line": "A kindergarten teacher, who is great with kids, maybe an ex-model, and now she wants to do something with her brain?", "order": 19123, "speaker": "Michael Scott"},
  {"season": 4, "episode": 10, "scene": 48, "line": "No, I don't, I...", "order": 19124, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 10, "scene": 48, "line": "How 'bout a professional volleyball player who models on the side?", "order": 19125, "speaker": "Michael Scott"},
  {"season": 4, "episode": 10, "scene": 48, "line": "I just...", "order": 19126, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 10, "scene": 48, "line": "You know, just a fun, guh, look, there, here, it doesn't have to be a model. I'm not, don't rule out model, but just in terms of models, there are like twenty different categories. There's face models, hand models, body models [Jim raises hand] - yes.", "order": 19127, "speaker": "Michael Scott"},
  {"season": 4, "episode": 10, "scene": 48, "line": "I actually know a sex model.", "order": 19128, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 10, "scene": 48, "line": "Really?", "order": 19129, "speaker": "Michael Scott"},
  {"season": 4, "episode": 10, "scene": 48, "line": "Yep. Yeah. She's blind, is that a problem?", "order": 19130, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 10, "scene": 48, "line": "No, I am, it's all about the personality, Jim.", "order": 19131, "speaker": "Michael Scott"},
  {"season": 4, "episode": 10, "scene": 48, "line": "Okay.", "order": 19132, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 10, "scene": 48, "line": "Are you talking about Beverley?", "order": 19133, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 10, "scene": 48, "line": "Yes.", "order": 19134, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 10, "scene": 48, "line": "Because she's not a sex model. She's a tollbooth operator.", "order": 19135, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 10, "scene": 48, "line": "Oh, yeah.", "order": 19136, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 10, "scene": 48, "line": "Send me a picture.", "order": 19137, "speaker": "Michael Scott"},
  {"season": 4, "episode": 10, "scene": 49, "line": "So far, these are my leads. A blind tollbooth operator and a twelve hundred pound catcher. It's a start.", "order": 19138, "speaker": "Michael Scott"},
  {"season": 4, "episode": 11, "scene": 1, "line": "What's wrong Michael?", "order": 19139, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 11, "scene": 1, "line": "I got gum in my hair.", "order": 19140, "speaker": "Michael Scott"},
  {"season": 4, "episode": 11, "scene": 1, "line": "You do.", "order": 19141, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 11, "scene": 1, "line": "This just stinks. Don't touch it, please don't touch it.", "order": 19142, "speaker": "Michael Scott"},
  {"season": 4, "episode": 11, "scene": 1, "line": "You've got a ton of dandruff.", "order": 19143, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 11, "scene": 1, "line": "OK, let me be.", "order": 19144, "speaker": "Michael Scott"},
  {"season": 4, "episode": 11, "scene": 1, "line": "How'd you get gum in your hair?", "order": 19145, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 11, "scene": 1, "line": "I was walking in and I noticed something shinny under Stanley's car and I got under to see what it was and I messed up my hair, all for a stupid piece of tinfoil.", "order": 19146, "speaker": "Michael Scott"},
  {"season": 4, "episode": 11, "scene": 1, "line": "But best case scenario, you thought it was a quarter.", "order": 19147, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 11, "scene": 1, "line": "Kill me... right now.", "order": 19148, "speaker": "Michael Scott"},
  {"season": 4, "episode": 11, "scene": 1, "line": "We have peanut butter in the kitchen.", "order": 19149, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 11, "scene": 1, "line": "I don't feel like peanut butter. Get me an ice cream sandwich.", "order": 19150, "speaker": "Michael Scott"},
  {"season": 4, "episode": 11, "scene": 1, "line": "Nope. Not for you it's for your hair, and it is 9 am.", "order": 19151, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 11, "scene": 1, "line": "No Dwight, not the good peanut butter. People are going to get mad.", "order": 19152, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 11, "scene": 1, "line": "Hey, hey, hey. This is my hair we're talking about.", "order": 19153, "speaker": "Michael Scott"},
  {"season": 4, "episode": 11, "scene": 2, "line": "Smells good.", "order": 19154, "speaker": "Michael Scott"},
  {"season": 4, "episode": 11, "scene": 2, "line": "Taste good too.", "order": 19155, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 11, "scene": 2, "line": "Oh don't. That's disgusting.", "order": 19156, "speaker": "Michael Scott"},
  {"season": 4, "episode": 11, "scene": 2, "line": "Wow! Lot of calories.", "order": 19157, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 11, "scene": 2, "line": "Well just don't leave it on too long. Keep massaging please. Ah yeah, that's nice.", "order": 19158, "speaker": "Michael Scott"},
  {"season": 4, "episode": 11, "scene": 3, "line": "Hey Michael, how was your date last night?", "order": 19159, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 11, "scene": 3, "line": "[sighs]", "order": 19160, "speaker": "Michael Scott"},
  {"season": 4, "episode": 11, "scene": 4, "line": "I will be honest; the dating has not been going well. Look, men are visual creatures. We crave beauty, like a piece of fine art by any number of renowned artists, or an arty photograph of Cindy Crawford nude... that, but the women I'm getting fixed up with are, egh! Not that they aren't nice, or that have great personalities, they just... They just lack a certain... Crawfordness.", "order": 19161, "speaker": "Michael Scott"},
  {"season": 4, "episode": 11, "scene": 5, "line": "I am livid, Absolutely livid.", "order": 19162, "speaker": "Oscar Martinez"},
  {"season": 4, "episode": 11, "scene": 5, "line": "It's ridiculous.", "order": 19163, "speaker": "Angela Martin"},
  {"season": 4, "episode": 11, "scene": 6, "line": "Yes, I'm having the Scranton branch come in on one Saturday so they can re-enter sales that they made on the phone as sales that were made by the website which they should have done in the first place, if the website had been working.", "order": 19164, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 11, "scene": 7, "line": "My favorite branch. How's everybody doing?", "order": 19165, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 11, "scene": 8, "line": "Hey man, you mind if I run something by you?", "order": 19166, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 11, "scene": 8, "line": "Love it. Go.", "order": 19167, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 11, "scene": 8, "line": "Well, I kinda feel like what we have going for us is our customer service, and no matter how we change this up, I don't know that a website's going to be able to replace that.", "order": 19168, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 11, "scene": 8, "line": "I can tell you thought about this a lot, I appreciate that.", "order": 19169, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 11, "scene": 8, "line": "Thanks.", "order": 19170, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 11, "scene": 8, "line": "David Wallace does too. You told him all about this at the Christmas party, right?", "order": 19171, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 11, "scene": 8, "line": "Did I? I don't...", "order": 19172, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 11, "scene": 8, "line": "You did. Yeah.", "order": 19173, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 11, "scene": 8, "line": "Hmm.", "order": 19174, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 11, "scene": 8, "line": "Watch your back Jim. I'm just kidding.", "order": 19175, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 11, "scene": 9, "line": "Ladies and Gentlemen, may I have your attention please. I know that a lot of you are very angry with Ryan because he is the reason we all have to come in tomorrow, however, I swear to God, If any of you hurts him in any way emotionally or taunts him or makes fun of his height or his half beard or...", "order": 19176, "speaker": "Michael Scott"},
  {"season": 4, "episode": 11, "scene": 9, "line": "Ok thanks Michael.", "order": 19177, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 11, "scene": 9, "line": "Yeah.", "order": 19178, "speaker": "Michael Scott"},
  {"season": 4, "episode": 11, "scene": 9, "line": "I'm here today to do some creative problem solving about Dunder Mifflin Infinity and field your questions.", "order": 19179, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 11, "scene": 9, "line": "Question.", "order": 19180, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 11, "scene": 9, "line": "Dwight.", "order": 19181, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 11, "scene": 9, "line": "Why am I being forced to come in tomorrow and pretend that a website made sales that I made?", "order": 19182, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 11, "scene": 9, "line": "This is a temporary measure to increase the legitimacy of the site.", "order": 19183, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 11, "scene": 9, "line": "I don't like when my clients call me to help them use the website, I'm not seeing commissions on that.", "order": 19184, "speaker": "Stanley Hudson"},
  {"season": 4, "episode": 11, "scene": 9, "line": "I hear you Stanley, that is a great observation. Problems like that will not happen when we launch Dunder Mifflin Infinity 2 point O.", "order": 19185, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 11, "scene": 9, "line": "When will that be?", "order": 19186, "speaker": "Stanley Hudson"},
  {"season": 4, "episode": 11, "scene": 9, "line": "TBD. Phyllis?", "order": 19187, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 11, "scene": 9, "line": "Did the police solve the problem with the...", "order": 19188, "speaker": "Phyllis Vance"},
  {"season": 4, "episode": 11, "scene": 9, "line": "Yes, yes they did, yes they did.", "order": 19189, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 11, "scene": 10, "line": "Yes, the social networking feature of the Dunder Mifflin Infinity website was infiltrated by sexual predators.", "order": 19190, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 11, "scene": 11, "line": "I don't understand why our website has to have social networking at all.", "order": 19191, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 11, "scene": 11, "line": "Yeah, I actually have to agree with Dwight on that one.", "order": 19192, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 11, "scene": 11, "line": "It's all about creating a one stop shop consumer experience, alright? You're chatting with your friends, you're talking about the latest music, about the election; all of it is happening in our virtual paper store.", "order": 19193, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 11, "scene": 11, "line": "And then an older gentleman asked you 'Boxers or briefs?'", "order": 19194, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 11, "scene": 11, "line": "I don't get the big fuss here, I like the site.", "order": 19195, "speaker": "Creed Bratton"},
  {"season": 4, "episode": 11, "scene": 11, "line": "If I'd have created a website with as many problems, I'd kill myself.", "order": 19196, "speaker": "Kelly Kapoor"},
  {"season": 4, "episode": 11, "scene": 11, "line": "Do you have a question Kelly?", "order": 19197, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 11, "scene": 11, "line": "Yeah I have a lot of questions. Number one, how dare you?", "order": 19198, "speaker": "Kelly Kapoor"},
  {"season": 4, "episode": 11, "scene": 11, "line": "[slow clapping] Ryan has done a very good job, and I am not applauding sarcastically. Think about it, a month ago nobody would go on this site because we were worried about getting molested, or losing our identity, having it stolen. But now, at a time TDB, all of the problems will be in the past. Ya done good kid, ya done good.", "order": 19199, "speaker": "Michael Scott"},
  {"season": 4, "episode": 11, "scene": 12, "line": "Well it has been nice seeing you again my friend.", "order": 19200, "speaker": "Michael Scott"},
  {"season": 4, "episode": 11, "scene": 12, "line": "You too.", "order": 19201, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 11, "scene": 12, "line": "Oh Ryan, I need a girlfriend so bad. [hugs Ryan]", "order": 19202, "speaker": "Michael Scott"},
  {"season": 4, "episode": 11, "scene": 12, "line": "Michael let me go. Let me go Michael.", "order": 19203, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 11, "scene": 12, "line": "Argh. Alright. Do you know any girls in New York you might want to hook me up with? Some that might be interested in a guy like me.", "order": 19204, "speaker": "Michael Scott"},
  {"season": 4, "episode": 11, "scene": 12, "line": "Na.", "order": 19205, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 11, "scene": 12, "line": "Na?", "order": 19206, "speaker": "Michael Scott"},
  {"season": 4, "episode": 11, "scene": 12, "line": "Sorry man.", "order": 19207, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 11, "scene": 12, "line": "Well you tried.", "order": 19208, "speaker": "Michael Scott"},
  {"season": 4, "episode": 11, "scene": 12, "line": "But seriously, you should see the girls I meet at clubs in the city.", "order": 19209, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 11, "scene": 12, "line": "Yeah.", "order": 19210, "speaker": "Michael Scott"},
  {"season": 4, "episode": 11, "scene": 12, "line": "Unreal.", "order": 19211, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 11, "scene": 12, "line": "They sound great.", "order": 19212, "speaker": "Michael Scott"},
  {"season": 4, "episode": 11, "scene": 12, "line": "Bye everyone. Stay real Scranton, alright. Peace.", "order": 19213, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 11, "scene": 13, "line": "Would you have sex with Meredith?", "order": 19214, "speaker": "Michael Scott"},
  {"season": 4, "episode": 11, "scene": 13, "line": "What?", "order": 19215, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 11, "scene": 13, "line": "Do you think she'd keep it quiet?", "order": 19216, "speaker": "Michael Scott"},
  {"season": 4, "episode": 11, "scene": 13, "line": "I'm gonna go to my desk.", "order": 19217, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 11, "scene": 13, "line": "Jim, it's not the horniness, ok it's the loneliness that...", "order": 19218, "speaker": "Michael Scott"},
  {"season": 4, "episode": 11, "scene": 13, "line": "I know.", "order": 19219, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 11, "scene": 13, "line": "Let's go to New York. We'll go clubbing with Ryan.", "order": 19220, "speaker": "Michael Scott"},
  {"season": 4, "episode": 11, "scene": 13, "line": "I can't.", "order": 19221, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 11, "scene": 13, "line": "Yes you can, you're single, I'm single. It'll be awesome.", "order": 19222, "speaker": "Michael Scott"},
  {"season": 4, "episode": 11, "scene": 13, "line": "I'm not single.", "order": 19223, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 11, "scene": 13, "line": "Who you dating?", "order": 19224, "speaker": "Michael Scott"},
  {"season": 4, "episode": 11, "scene": 13, "line": "Pam.", "order": 19225, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 11, "scene": 13, "line": "That's still going on?", "order": 19226, "speaker": "Michael Scott"},
  {"season": 4, "episode": 11, "scene": 14, "line": "Ok, Dwight grab your stuff, we're going to New York to party with Ryan and to meet girls.", "order": 19227, "speaker": "Michael Scott"},
  {"season": 4, "episode": 11, "scene": 14, "line": "Yeah!", "order": 19228, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 11, "scene": 14, "line": "Oh yes! Count me in dudes. I am in some serious need of some bro' time. Old ball and chain's been a lot more chain than ball lately if you know what I'm saying.", "order": 19229, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 11, "scene": 14, "line": "I'm right here.", "order": 19230, "speaker": "Angela Martin"},
  {"season": 4, "episode": 11, "scene": 14, "line": "No, singles only, singles only. Also three is unlucky. Curse of three.", "order": 19231, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 11, "scene": 14, "line": "Sorry Andy. Cannot take any chances on curses, not tonight. Let's go. Everybody, I will see you tomorrow, Saturday morning, probably wearing the same clothes that I am wearing right now, If you catch my drift.", "order": 19232, "speaker": "Michael Scott"},
  {"season": 4, "episode": 11, "scene": 14, "line": "We get it.", "order": 19233, "speaker": "Angela Martin"},
  {"season": 4, "episode": 11, "scene": 14, "line": "I am going to go get laid. Goodbye.", "order": 19234, "speaker": "Michael Scott"},
  {"season": 4, "episode": 11, "scene": 14, "line": "[laughs] With sex!", "order": 19235, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 11, "scene": 14, "line": "That's... I, I think that...", "order": 19236, "speaker": "Michael Scott"},
  {"season": 4, "episode": 11, "scene": 15, "line": "You know what? If we all stayed a couple of hours late tonight without Michael distracting us, we wouldn't have to come in at all tomorrow.", "order": 19237, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 11, "scene": 16, "line": "So I caught everyone before they left and I told them my idea, and they loved it. Because this is a group that respects good ideas... the one time a year they hear one.", "order": 19238, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 11, "scene": 17, "line": "Ryan's assistant told us that he would be at this club this evening. It is called Prerogative.", "order": 19239, "speaker": "Michael Scott"},
  {"season": 4, "episode": 11, "scene": 18, "line": "Place is packed.", "order": 19240, "speaker": "Michael Scott"},
  {"season": 4, "episode": 11, "scene": 18, "line": "Fire hazard.", "order": 19241, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 11, "scene": 18, "line": "Packed with beautiful babies. Swingers. Classic. John Favreau, tall guy from DodgeBall.", "order": 19242, "speaker": "Michael Scott"},
  {"season": 4, "episode": 11, "scene": 18, "line": "Women look like white slaves.", "order": 19243, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 11, "scene": 18, "line": "No, they're just hotties.", "order": 19244, "speaker": "Michael Scott"},
  {"season": 4, "episode": 11, "scene": 19, "line": "I don't know man, when you think about it, Cabo's really the third world. I don't go to a place like that to see more poverty you know. You go there to get some glamour.", "order": 19245, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 11, "scene": 19, "line": "Hey.", "order": 19246, "speaker": "Michael Scott"},
  {"season": 4, "episode": 11, "scene": 19, "line": "Michael.", "order": 19247, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 11, "scene": 19, "line": "Hey!", "order": 19248, "speaker": "Michael Scott"},
  {"season": 4, "episode": 11, "scene": 19, "line": "What are you doing here?", "order": 19249, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 11, "scene": 19, "line": "Well you know, just taking you up on your offer to party, so tada.", "order": 19250, "speaker": "Michael Scott"},
  {"season": 4, "episode": 11, "scene": 19, "line": "That is so awesome man!", "order": 19251, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 11, "scene": 19, "line": "Alright!", "order": 19252, "speaker": "Michael Scott"},
  {"season": 4, "episode": 11, "scene": 19, "line": "And you brought this guy!", "order": 19253, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 11, "scene": 19, "line": "Yeah.", "order": 19254, "speaker": "Michael Scott"},
  {"season": 4, "episode": 11, "scene": 19, "line": "Aww!", "order": 19255, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 11, "scene": 19, "line": "Ryan, it's Michael and Dwight.", "order": 19256, "speaker": "Michael Scott"},
  {"season": 4, "episode": 11, "scene": 19, "line": "I know it's you guys.", "order": 19257, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 11, "scene": 19, "line": "Ok.", "order": 19258, "speaker": "Michael Scott"},
  {"season": 4, "episode": 11, "scene": 19, "line": "I'm so psyched you're here!", "order": 19259, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 11, "scene": 19, "line": "Well.", "order": 19260, "speaker": "Michael Scott"},
  {"season": 4, "episode": 11, "scene": 19, "line": "Woooo!", "order": 19261, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 11, "scene": 19, "line": "Alright!", "order": 19262, "speaker": "Michael Scott"},
  {"season": 4, "episode": 11, "scene": 19, "line": "Let's get a drink.", "order": 19263, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 11, "scene": 19, "line": "Let's do it!", "order": 19264, "speaker": "Michael Scott"},
  {"season": 4, "episode": 11, "scene": 20, "line": "Thanks a lot guys, Good job.", "order": 19265, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 11, "scene": 20, "line": "Later.", "order": 19266, "speaker": "Kevin Malone"},
  {"season": 4, "episode": 11, "scene": 20, "line": "Nice job everybody. Great work.", "order": 19267, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 11, "scene": 21, "line": "Did you not tell the security guard we were working late?", "order": 19268, "speaker": "Stanley Hudson"},
  {"season": 4, "episode": 11, "scene": 21, "line": "Nope. I didn't, but let's go inside and I can call him right now.", "order": 19269, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 11, "scene": 21, "line": "You can't, I locked the office from the inside when we left.", "order": 19270, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 11, "scene": 21, "line": "Perfect, you guys worked together on this one. If I'm not in my bath with a glass of red wine in an hour, you're both dead.", "order": 19271, "speaker": "Stanley Hudson"},
  {"season": 4, "episode": 11, "scene": 22, "line": "There is a master key and a spare key for the office. Dwight has them both. When I asked, 'What if you die Dwight, how will we get into the office?' He said, 'If I'm dead, you guys have been dead for weeks.'", "order": 19272, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 11, "scene": 23, "line": "Guys, I want you to meet a really good friend of mine, this is Troy.", "order": 19273, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 11, "scene": 23, "line": "Hey, nice to meet you.", "order": 19274, "speaker": "Michael Scott"},
  {"season": 4, "episode": 11, "scene": 23, "line": "Hey, how ya doing?", "order": 19275, "speaker": "TRoy Anderson"},
  {"season": 4, "episode": 11, "scene": 23, "line": "Hey, Dwight. You resemble a Tolkien character.", "order": 19276, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 11, "scene": 23, "line": "Ah, he basically is man. He's a regular banking wizard.", "order": 19277, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 11, "scene": 23, "line": "No, no, no. Not a wizard, a hobbit.", "order": 19278, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 11, "scene": 23, "line": "Bottle service ya'll?", "order": 19279, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 11, "scene": 23, "line": "Oh ok, all I know is I would like some chicken fingers and a Midori Sour.", "order": 19280, "speaker": "Michael Scott"},
  {"season": 4, "episode": 11, "scene": 23, "line": "We don't serve food here.", "order": 19281, "speaker": "Waitress"},
  {"season": 4, "episode": 11, "scene": 23, "line": "Oh ok, then just bring me two cups, one with olives and another filled with maraschino cherries.", "order": 19282, "speaker": "Michael Scott"},
  {"season": 4, "episode": 11, "scene": 23, "line": "Do you live in a regular sized house?", "order": 19283, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 11, "scene": 23, "line": "Yeah. He's a normal guy, he's cool.", "order": 19284, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 11, "scene": 24, "line": "You really don't have his phone number?", "order": 19285, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 11, "scene": 24, "line": "I told you, I have the number that rings here. Do you want that number?", "order": 19286, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 11, "scene": 24, "line": "I might have it in here.", "order": 19287, "speaker": "Toby Flenderson"},
  {"season": 4, "episode": 11, "scene": 24, "line": "Aw! That's great Toby.", "order": 19288, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 11, "scene": 24, "line": "It's so random that I have it.", "order": 19289, "speaker": "Toby Flenderson"},
  {"season": 4, "episode": 11, "scene": 24, "line": "Toby, you're the best.", "order": 19290, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 11, "scene": 24, "line": "When I put it in I thought it would be a waste of time, but I guess it was worth it.", "order": 19291, "speaker": "Toby Flenderson"},
  {"season": 4, "episode": 11, "scene": 24, "line": "Great, It's under here as Security Guard home. Did you not get his name? Or...", "order": 19292, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 11, "scene": 24, "line": "No.", "order": 19293, "speaker": "Toby Flenderson"},
  {"season": 4, "episode": 11, "scene": 24, "line": "It's ringing. Does anybody have his name? Quick.", "order": 19294, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 11, "scene": 24, "line": "Yes, it's Eddie.", "order": 19295, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 11, "scene": 24, "line": "It's not, it's not Eddie, it's ah, Evan or...", "order": 19296, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 11, "scene": 24, "line": "Hank, his name is Hank.", "order": 19297, "speaker": "Creed Bratton"},
  {"season": 4, "episode": 11, "scene": 24, "line": "No guys his name's not Hank, it's ah, is it Edgar?", "order": 19298, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 11, "scene": 24, "line": "Elliot.", "order": 19299, "speaker": "Phyllis Vance"},
  {"season": 4, "episode": 11, "scene": 24, "line": "Elliot!", "order": 19300, "speaker": "Oscar Martinez"},
  {"season": 4, "episode": 11, "scene": 24, "line": "Is it Elliot? Hey ch... chief, this is Jim Halpert from um... where you work. You're the guy who sits behind the desk... you're, you're the, the Afri... African-American guy, I mean you're. Ah... Who, Who've I got here?", "order": 19301, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 11, "scene": 25, "line": "Dwight Schrute man. How's Schrute Farms?", "order": 19302, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 11, "scene": 25, "line": "Good.", "order": 19303, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 11, "scene": 25, "line": "This guy owns his own beet farm.", "order": 19304, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 11, "scene": 25, "line": "Insane.", "order": 19305, "speaker": "TRoy Anderson"},
  {"season": 4, "episode": 11, "scene": 25, "line": "Well it's weevil season but we were prepared.", "order": 19306, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 11, "scene": 25, "line": "Weevils, what a crazy word man. What? I don't even know what that means. What does that mean?", "order": 19307, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 11, "scene": 25, "line": "They lay their eggs inside the unripe beet root, then come spring time the babies eat their way out.", "order": 19308, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 11, "scene": 25, "line": "Crazy. That's too much for me man, I'm gonna hit the bathroom.", "order": 19309, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 11, "scene": 25, "line": "You've already been several times.", "order": 19310, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 11, "scene": 25, "line": "Yeah.", "order": 19311, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 11, "scene": 25, "line": "Maybe you have some kinda bladder infection.", "order": 19312, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 11, "scene": 25, "line": "I don't know, maybe.", "order": 19313, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 11, "scene": 25, "line": "I'll order you some cranberry juice.", "order": 19314, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 11, "scene": 25, "line": "With vodka, you're the best.", "order": 19315, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 11, "scene": 25, "line": "[to troy] Do you have powers?", "order": 19316, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 11, "scene": 26, "line": "[on phone] Oh thank you Hank. You are a lifesaver, Hank. Appreciate it. [to office co-workers] Alright, so Hank is gonna come down here and he's gonna let us all out. [lowers voice] He said it should just be under an hour, so... [everyone groans] we did it.", "order": 19317, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 11, "scene": 26, "line": "We should all make sure to give him a big tip this Christmas.", "order": 19318, "speaker": "Oscar Martinez"},
  {"season": 4, "episode": 11, "scene": 26, "line": "Sorry guys, but I don't think I tipped him for last year's.", "order": 19319, "speaker": "Toby Flenderson"},
  {"season": 4, "episode": 11, "scene": 26, "line": "Yeah, neither did I.", "order": 19320, "speaker": "Angela Martin"},
  {"season": 4, "episode": 11, "scene": 26, "line": "Jim was supposed to collect it.", "order": 19321, "speaker": "Phyllis Vance"},
  {"season": 4, "episode": 11, "scene": 26, "line": "Yup...", "order": 19322, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 11, "scene": 26, "line": "Way to go, man.", "order": 19323, "speaker": "Kevin Malone"},
  {"season": 4, "episode": 11, "scene": 26, "line": "Now he's never gonna come.", "order": 19324, "speaker": "Kelly Kapoor"},
  {"season": 4, "episode": 11, "scene": 26, "line": "By a show of hands, who thinks we're a better couple than Jim and Pam? [people raise hands]", "order": 19325, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 11, "scene": 26, "line": "Phyllis.", "order": 19326, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 11, "scene": 27, "line": "I umm, thought about getting a tattoo on my back as well at one point. I was thinking about getting 'Back to the Future.' 'Back' because it's on my back and 'Future' because I'm the kind of guy who likes to look ahead into the future. I just think a tattoo should mean something, you know? And it's my second favorite movie.", "order": 19327, "speaker": "Michael Scott"},
  {"season": 4, "episode": 11, "scene": 27, "line": "I've never heard of that movie.", "order": 19328, "speaker": "Girl in Club"},
  {"season": 4, "episode": 11, "scene": 27, "line": "Back to the Future? [she shakes head] Oh, wow. Well you should take a, a film education course.", "order": 19329, "speaker": "Michael Scott"},
  {"season": 4, "episode": 11, "scene": 27, "line": "How old are you?", "order": 19330, "speaker": "Girl in Club"},
  {"season": 4, "episode": 11, "scene": 27, "line": "Forty... I'm in my forties.", "order": 19331, "speaker": "Michael Scott"},
  {"season": 4, "episode": 11, "scene": 27, "line": "Wow. That's so cool.", "order": 19332, "speaker": "Girl in Club"},
  {"season": 4, "episode": 11, "scene": 27, "line": "Nuhh...", "order": 19333, "speaker": "Michael Scott"},
  {"season": 4, "episode": 11, "scene": 27, "line": "Well I'm gonna go back to my group now.", "order": 19334, "speaker": "Girl in Club"},
  {"season": 4, "episode": 11, "scene": 27, "line": "Oh, OK.", "order": 19335, "speaker": "Michael Scott"},
  {"season": 4, "episode": 11, "scene": 27, "line": "Thanks for the drink.", "order": 19336, "speaker": "Girl in Club"},
  {"season": 4, "episode": 11, "scene": 27, "line": "You are welcome.", "order": 19337, "speaker": "Michael Scott"},
  {"season": 4, "episode": 11, "scene": 28, "line": "This place is like sexy pre-school.", "order": 19338, "speaker": "Michael Scott"},
  {"season": 4, "episode": 11, "scene": 28, "line": "Did you want a place where we could meet older women?", "order": 19339, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 11, "scene": 28, "line": "I would love a place where we could meet older", "order": 19340, "speaker": "Michael Scott"},
  {"season": 4, "episode": 11, "scene": 28, "line": "Hell yeah. I'll hook it up. [smashes beer bottle on the floor]", "order": 19341, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 11, "scene": 28, "line": "Ahh, wow. That's dangerous.", "order": 19342, "speaker": "Michael Scott"},
  {"season": 4, "episode": 11, "scene": 29, "line": "[to bouncer] Check again, Ryan Howard.", "order": 19343, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 11, "scene": 29, "line": "[to tall woman in front of him, tapping her shoulder] Excuse me.", "order": 19344, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 11, "scene": 29, "line": "Yeah?", "order": 19345, "speaker": "Tall Girl #1"},
  {"season": 4, "episode": 11, "scene": 29, "line": "[referring to girls in front of him] How did you all find each other?", "order": 19346, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 11, "scene": 29, "line": "Uh, we're the Jersey State Varsity Basketball team. Northeast Regional Champs.", "order": 19347, "speaker": "Tall Girl #1"},
  {"season": 4, "episode": 11, "scene": 29, "line": "Wooo!", "order": 19348, "speaker": "All Girls"},
  {"season": 4, "episode": 11, "scene": 29, "line": "Amazons.", "order": 19349, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 11, "scene": 29, "line": "[to Dwight, Michael and Troy] It's off. It's not the dude I know. It's some other loser who won't let us in without chicks.", "order": 19350, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 11, "scene": 29, "line": "You're kidding.", "order": 19351, "speaker": "Michael Scott"},
  {"season": 4, "episode": 11, "scene": 29, "line": "Let's bail. [starts to leave]", "order": 19352, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 11, "scene": 29, "line": "Ok, wai, wai, wai, wait, wait. [turns Ryan around annd points to basketball players] You two, Jersey State girls, let's go.", "order": 19353, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 11, "scene": 29, "line": "We're not going unless we can all go.", "order": 19354, "speaker": "Tall Girl #2"},
  {"season": 4, "episode": 11, "scene": 29, "line": "OK. You know what, fine. Let's go, two girls to a guy. Come on, let's do it. C'mon, Ryan. Move out. OK, three or four with him [referring to Troy]. Let's go, c'mon. Here we go.", "order": 19355, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 11, "scene": 30, "line": "Hey, look what I found in the back. [shows a football to Pam] Wanna play? Teach you to throw.", "order": 19356, "speaker": "Toby Flenderson"},
  {"season": 4, "episode": 11, "scene": 30, "line": "I know how to throw a football.", "order": 19357, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 11, "scene": 30, "line": "Course you do.", "order": 19358, "speaker": "Toby Flenderson"},
  {"season": 4, "episode": 11, "scene": 30, "line": "Yeah, Pam! Hit me up! Go long. Woo! [Pam throws football, hits Meredith's face]", "order": 19359, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 11, "scene": 30, "line": "Owww!", "order": 19360, "speaker": "Meredith Palmer"},
  {"season": 4, "episode": 11, "scene": 30, "line": "Oh God.", "order": 19361, "speaker": "Kevin Malone"},
  {"season": 4, "episode": 11, "scene": 31, "line": "There you go.", "order": 19362, "speaker": "Bartender"},
  {"season": 4, "episode": 11, "scene": 31, "line": "Wait, wait a minute, what's this, I didn't order this?", "order": 19363, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 11, "scene": 31, "line": "It's for you. From them. [points to Jersey State girls across the bar]", "order": 19364, "speaker": "Bartender"},
  {"season": 4, "episode": 11, "scene": 31, "line": "Oh. [dumps drink out]", "order": 19365, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 11, "scene": 31, "line": "What are you doing man?", "order": 19366, "speaker": "TRoy Anderson"},
  {"season": 4, "episode": 11, "scene": 31, "line": "It's not safe. Anything could have been in there. [to girls] Nice try.", "order": 19367, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 11, "scene": 32, "line": "I've never met anyone who does that. You wash dogs? Very cool.", "order": 19368, "speaker": "Michael Scott"},
  {"season": 4, "episode": 11, "scene": 32, "line": "That's one aspect of small pet grooming. What do you do?", "order": 19369, "speaker": "Girl in 2nd club"},
  {"season": 4, "episode": 11, "scene": 32, "line": "I am a bank teller.", "order": 19370, "speaker": "Michael Scott"},
  {"season": 4, "episode": 11, "scene": 33, "line": "Ryan told me to always tell women you work in finance.", "order": 19371, "speaker": "Michael Scott"},
  {"season": 4, "episode": 11, "scene": 34, "line": "Cool.", "order": 19372, "speaker": "Girl in 2nd club"},
  {"season": 4, "episode": 11, "scene": 34, "line": "I think so.", "order": 19373, "speaker": "Michael Scott"},
  {"season": 4, "episode": 11, "scene": 34, "line": "Yeah.", "order": 19374, "speaker": "Girl in 2nd club"},
  {"season": 4, "episode": 11, "scene": 34, "line": "Yeah, I have fun.", "order": 19375, "speaker": "Michael Scott"},
  {"season": 4, "episode": 11, "scene": 34, "line": "I'm just gonna just use the powder room.", "order": 19376, "speaker": "Girl in 2nd club"},
  {"season": 4, "episode": 11, "scene": 34, "line": "All right.", "order": 19377, "speaker": "Michael Scott"},
  {"season": 4, "episode": 11, "scene": 34, "line": "So I'll see ya soon.", "order": 19378, "speaker": "Girl in 2nd club"},
  {"season": 4, "episode": 11, "scene": 34, "line": "Very perfect. [girl walks away, Michael laughs] Oh my [notices Dwight making out with Tall Girl #1] This needs to be shared. [takes a picture with his camera phone] [Cut to the office - All Office co-workers cell phones go off. They notice the picture Michael has sent and all groan]", "order": 19379, "speaker": "Michael Scott"},
  {"season": 4, "episode": 11, "scene": 35, "line": "She washes dogs.", "order": 19380, "speaker": "Michael Scott"},
  {"season": 4, "episode": 11, "scene": 35, "line": "You're doing it man.", "order": 19381, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 11, "scene": 35, "line": "I know. I don't wanna get ahead of myself, but I think I want her to meet my mom.", "order": 19382, "speaker": "Michael Scott"},
  {"season": 4, "episode": 11, "scene": 35, "line": "Hey man, you ever think there's gonna be this massive nuclear holocaust, and after all the major nations are destroyed they'll just be the tribes in the jungle that rise up and survive? The jungle war fare's gonna rule the world?", "order": 19383, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 11, "scene": 35, "line": "Yeah, maybe. [notices previous girl talking to another guy]", "order": 19384, "speaker": "Michael Scott"},
  {"season": 4, "episode": 11, "scene": 35, "line": "It's inevitable, right?", "order": 19385, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 11, "scene": 35, "line": "Hmm...", "order": 19386, "speaker": "Michael Scott"},
  {"season": 4, "episode": 11, "scene": 36, "line": "[on phone] Please don't pick up, please don't pick up, please don't pick up.", "order": 19387, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 11, "scene": 36, "line": "Hello?", "order": 19388, "speaker": "Hank Tate"},
  {"season": 4, "episode": 11, "scene": 36, "line": "Haaank. Is that you?", "order": 19389, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 11, "scene": 36, "line": "Yeah.", "order": 19390, "speaker": "Hank Tate"},
  {"season": 4, "episode": 11, "scene": 36, "line": "Still haven't left the house yet, huh?", "order": 19391, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 11, "scene": 36, "line": "I'll get ready to leave.", "order": 19392, "speaker": "Hank Tate"},
  {"season": 4, "episode": 11, "scene": 36, "line": "Good. Please hurry.", "order": 19393, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 11, "scene": 36, "line": "[on phone] Stop calling me so I can put on my damn socks!", "order": 19394, "speaker": "Hank Tate"},
  {"season": 4, "episode": 11, "scene": 36, "line": "Will do. [hangs up] I'll stop calling.", "order": 19395, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 11, "scene": 37, "line": "[on phonne] I am. I am getting out there. Well, no I've asked a lot of girls to dance mom. They're just, it's not... [Ryan is dancing feverishly and falls over] Yes, I shaved above my neck. Oh my God, mom, I gotta go. One of my friends is getting beaten up by some girls.", "order": 19396, "speaker": "Michael Scott"},
  {"season": 4, "episode": 11, "scene": 38, "line": "Actually, it's kind of too bad we're not coming into work tomorrow.", "order": 19397, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 11, "scene": 38, "line": "Why?", "order": 19398, "speaker": "Oscar Martinez"},
  {"season": 4, "episode": 11, "scene": 38, "line": "I bet Michael had some elaborate theme party planned, like Scrambled Egg Saturday. [a few chuckles]", "order": 19399, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 11, "scene": 38, "line": "More like 'Everyone let's get your boss laid Saturday.' [more laughs] [Toby puts his hand on Pam's knee, everyone stares, Toby removes his hand] I have an announcement uh, to make. I am moving to Costa Rica. Thought about it for a long time now. And I'm finally gonna do it. So, I'm just gonna hop the fence and jog home now. [runs out of lobby and climbs the fence and jogs away]", "order": 19400, "speaker": "Toby Flenderson"},
  {"season": 4, "episode": 11, "scene": 39, "line": "[Michael and Troy dragging Ryan out of the club] Those girls really wailed on you bad.", "order": 19401, "speaker": "Michael Scott"},
  {"season": 4, "episode": 11, "scene": 39, "line": "Why wouldn't they let me dance?", "order": 19402, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 11, "scene": 39, "line": "Well...", "order": 19403, "speaker": "Michael Scott"},
  {"season": 4, "episode": 11, "scene": 39, "line": "OK I gotta go. Do not take him to a hospital. [runs away]", "order": 19404, "speaker": "TRoy Anderson"},
  {"season": 4, "episode": 11, "scene": 39, "line": "Pretty weird. [Dwight and tall girl run out of club]", "order": 19405, "speaker": "Michael Scott"},
  {"season": 4, "episode": 11, "scene": 39, "line": "[to Dwight] You have to go with me.", "order": 19406, "speaker": "Tall Girl #1"},
  {"season": 4, "episode": 11, "scene": 39, "line": "I do, I have to go.", "order": 19407, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 11, "scene": 39, "line": "Stay. Stay, please?", "order": 19408, "speaker": "Tall Girl #1"},
  {"season": 4, "episode": 11, "scene": 39, "line": "No, I have to go. Good luck against Conn College, alright?", "order": 19409, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 11, "scene": 39, "line": "Call me.", "order": 19410, "speaker": "Tall Girl #1"},
  {"season": 4, "episode": 11, "scene": 39, "line": "Uh huh. [to Ryan and Michael] I'm not gonna call her.", "order": 19411, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 11, "scene": 39, "line": "Let's go.", "order": 19412, "speaker": "Michael Scott"},
  {"season": 4, "episode": 11, "scene": 39, "line": "I don't wanna go back in.", "order": 19413, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 11, "scene": 39, "line": "I know, here we go.", "order": 19414, "speaker": "Michael Scott"},
  {"season": 4, "episode": 11, "scene": 40, "line": "[notices cleaning crew coming up to the gate] Cleaning people. Oscar. [everyone starts to walk outside] OK, so all you need to do is explain to them what happened, 'cause I think they can help us.", "order": 19415, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 11, "scene": 40, "line": "Why are you assuming they only speak Spanish?", "order": 19416, "speaker": "Oscar Martinez"},
  {"season": 4, "episode": 11, "scene": 40, "line": "I... just... if they speak Spanish.", "order": 19417, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 11, "scene": 40, "line": "[to cleaning people] Good evening, we locked ourselves in. [women don't respond] Nosotros trabajamos aqui, nos trancamos, OK?", "order": 19418, "speaker": "Oscar Martinez"},
  {"season": 4, "episode": 11, "scene": 40, "line": "Ohh...", "order": 19419, "speaker": "Cleaning lady"},
  {"season": 4, "episode": 11, "scene": 40, "line": "They", "order": 19420, "speaker": "Oscar Martinez"},
  {"season": 4, "episode": 11, "scene": 40, "line": "Lucky us.", "order": 19421, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 11, "scene": 41, "line": "This is a one time thing, you know that right? [they walk into Ryan's apartment]", "order": 19422, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 11, "scene": 41, "line": "Wow.", "order": 19423, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 11, "scene": 41, "line": "This is it.", "order": 19424, "speaker": "Michael Scott"},
  {"season": 4, "episode": 11, "scene": 41, "line": "All right.", "order": 19425, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 11, "scene": 41, "line": "This is where the magic happens.", "order": 19426, "speaker": "Michael Scott"},
  {"season": 4, "episode": 11, "scene": 41, "line": "Nice.", "order": 19427, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 11, "scene": 41, "line": "Very nice.", "order": 19428, "speaker": "Michael Scott"},
  {"season": 4, "episode": 11, "scene": 41, "line": "One of you guys can have the sofa, and one of you guys can have...", "order": 19429, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 11, "scene": 41, "line": "I got sofa. I got sofa.", "order": 19430, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 11, "scene": 41, "line": "Dwight, I'm the boss.", "order": 19431, "speaker": "Michael Scott"},
  {"season": 4, "episode": 11, "scene": 41, "line": "I got floor.", "order": 19432, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 11, "scene": 41, "line": "Case anyone needs to go to the bathroom in the middle of the night, I don't wannna get my head stepped on.", "order": 19433, "speaker": "Michael Scott"},
  {"season": 4, "episode": 11, "scene": 41, "line": "[to Ryan] Do you want me to sing you a song my mother used to sing me, when we were sick?", "order": 19434, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 11, "scene": 41, "line": "No. [Dwight starts singing in German]", "order": 19435, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 11, "scene": 41, "line": "Dwight, shh shh. Dwight. Leave him alone.", "order": 19436, "speaker": "Michael Scott"},
  {"season": 4, "episode": 11, "scene": 41, "line": "It's a lullaby.", "order": 19437, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 11, "scene": 41, "line": "Shh. Ryan, Ryan, we're going to take your clothes off.", "order": 19438, "speaker": "Michael Scott"},
  {"season": 4, "episode": 11, "scene": 41, "line": "No. Guys, I think my friend Troy might have a drug problem. What do I do?", "order": 19439, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 11, "scene": 41, "line": "I think his species might have a higher tolerance than ours.", "order": 19440, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 11, "scene": 41, "line": "Shh, just stop. Here's what you do. You tell him that you're his friend and that you're gonna help him and that everyone's gonna be all right. And then you put a wire on him and you find out who's selling him drugs and then you get that guy and you flip up, turn him into a snitch. You follow that guy to the people who's really really bad. Been watching The Wire recently. I don't understand a word of it.", "order": 19441, "speaker": "Michael Scott"},
  {"season": 4, "episode": 11, "scene": 41, "line": "Guys, I'm going to sleep. You can leave the light on if you want, but please stop talking, OK?", "order": 19442, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 11, "scene": 41, "line": "OK. Good night Ryan.", "order": 19443, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 11, "scene": 41, "line": "Best night ever.", "order": 19444, "speaker": "Michael Scott"},
  {"season": 4, "episode": 11, "scene": 42, "line": "Like I said, it's not about the horniness, it's about the loneliness. And how can I be lonely with my boys? Like a famous person once said, Boys on the side. But I don't, I disagree, I say... let's hear it for the boys.", "order": 19445, "speaker": "Michael Scott"},
  {"season": 4, "episode": 11, "scene": 43, "line": "[drives up to parking lot to notice all the office cars are gone] Son of a bitch.", "order": 19446, "speaker": "Hank Tate"},
  {"season": 4, "episode": 11, "scene": 44, "line": "You're gonna be okay, it's gonna...", "order": 19447, "speaker": "Michael Scott"},
  {"season": 4, "episode": 11, "scene": 44, "line": "Ohhh, man! [sound of Ryan throwing up] There it goes.", "order": 19448, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 11, "scene": 44, "line": "Ahh, ooh!", "order": 19449, "speaker": "Michael Scott"},
  {"season": 4, "episode": 11, "scene": 44, "line": "I am so sorry. I'm so sorry, sir.", "order": 19450, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 11, "scene": 44, "line": "[helping Ryan out of the taxi] Come on, come on.", "order": 19451, "speaker": "Michael Scott"},
  {"season": 4, "episode": 11, "scene": 44, "line": "That'll come out of the upholstery. Oh, man. He threw up in the back of the taxi.", "order": 19452, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 11, "scene": 44, "line": "Dehydration.", "order": 19453, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 11, "scene": 44, "line": "Here we go, let's sit down.", "order": 19454, "speaker": "Michael Scott"},
  {"season": 4, "episode": 11, "scene": 44, "line": "It's dehydration. He went to the bathroom at the club like fifteen times.", "order": 19455, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 11, "scene": 44, "line": "All right, shhh. Dwight, leave him alone.", "order": 19456, "speaker": "Michael Scott"},
  {"season": 4, "episode": 11, "scene": 45, "line": "Hey, don't blame me for what Ryan does. I'm not his dad. I'm his friend, okay? And friends are there to help you have a good time. And sell you the stuff you need to have a good time.", "order": 19457, "speaker": "TRoy Anderson"},
  {"season": 4, "episode": 11, "scene": 46, "line": "You had a rough night tonight. But your life is very good, my friend. You've got a great job, you... you can have any woman you want, you're good looking, you, you know...", "order": 19458, "speaker": "Michael Scott"},
  {"season": 4, "episode": 11, "scene": 46, "line": "Friends with a hobbit.", "order": 19459, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 11, "scene": 46, "line": "Frien..sss...yes, you're friends with a hobbit, and... look where you live. I mean, you've got it all.", "order": 19460, "speaker": "Michael Scott"},
  {"season": 4, "episode": 11, "scene": 46, "line": "Yeah, New York is great.", "order": 19461, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 11, "scene": 46, "line": "No, I mean this apartment building, it's fantastic.", "order": 19462, "speaker": "Michael Scott"},
  {"season": 4, "episode": 11, "scene": 46, "line": "Oh, this, this is not my apartment. This, this is the Metropolitan Museum of Art.", "order": 19463, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 11, "scene": 46, "line": "Well, now I can say that I went to a museum.", "order": 19464, "speaker": "Michael Scott"},
  {"season": 4, "episode": 11, "scene": 47, "line": "[Phyllis, Pam, Toby, and Oscar are sharing iPod headphones] I don't understand. Which one is that?", "order": 19465, "speaker": "Phyllis Vance"},
  {"season": 4, "episode": 11, "scene": 47, "line": "That's the other Boleyn girl.", "order": 19466, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 11, "scene": 47, "line": "Oh.", "order": 19467, "speaker": "Phyllis Vance"},
  {"season": 4, "episode": 11, "scene": 47, "line": "Honestly, the book is so much better than this.", "order": 19468, "speaker": "Oscar Martinez"},
  {"season": 4, "episode": 11, "scene": 47, "line": "Shh.", "order": 19469, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 11, "scene": 48, "line": "[through the fence to Terri, who's holding a drink for him to sip] Ah, we don't know. They didn't give him his Christmas bonus this year, so...", "order": 19470, "speaker": "Stanley Hudson"},
  {"season": 4, "episode": 11, "scene": 49, "line": "[on phone] Stop calling me so I can put on my damn socks!", "order": 19471, "speaker": "Hank Tate"},
  {"season": 4, "episode": 11, "scene": 49, "line": "Will do. [hangs up] I'll stop calling. [walks into lobby]", "order": 19472, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 11, "scene": 50, "line": "Was he still there?", "order": 19473, "speaker": "Kelly Kapoor"},
  {"season": 4, "episode": 11, "scene": 50, "line": "No, that was his wife, so he's on his way.", "order": 19474, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 11, "scene": 50, "line": "Jim, how long? I have to go to the bathroom.", "order": 19475, "speaker": "Kevin Malone"},
  {"season": 4, "episode": 11, "scene": 50, "line": "Well, he'll be here really soon, Kev.", "order": 19476, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 11, "scene": 50, "line": "If you hadn't made us stay, we'd all be home by now.", "order": 19477, "speaker": "Angela Martin"},
  {"season": 4, "episode": 11, "scene": 50, "line": "Somebody's always got to be a hero.", "order": 19478, "speaker": "Stanley Hudson"},
  {"season": 4, "episode": 11, "scene": 50, "line": "I'm so cold, I'm gonna faint. If I faint, it's totally your fault, Jim.", "order": 19479, "speaker": "Kelly Kapoor"},
  {"season": 4, "episode": 11, "scene": 50, "line": "Let's be honest, it's not totally my fault. [indicates Pam] Shared responsibility.", "order": 19480, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 11, "scene": 50, "line": "Mmm?", "order": 19481, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 11, "scene": 50, "line": "Oh, I just mean if you, if you didn't lock the door, then we could be upstairs where it's warmer.", "order": 19482, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 11, "scene": 50, "line": "I'm supposed to turn the lock when I leave, it's part of my job.", "order": 19483, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 11, "scene": 50, "line": "Is that... I didn't know that was your... okay, so we're in agreement, this is not ideal... situation.", "order": 19484, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 11, "scene": 51, "line": "Ryan?", "order": 19485, "speaker": "Michael Scott"},
  {"season": 4, "episode": 11, "scene": 51, "line": "Yeah.", "order": 19486, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 11, "scene": 51, "line": "Do you ever miss us?", "order": 19487, "speaker": "Michael Scott"},
  {"season": 4, "episode": 11, "scene": 51, "line": "Not really. New York is...so great.", "order": 19488, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 11, "scene": 51, "line": "Ryan?", "order": 19489, "speaker": "Michael Scott"},
  {"season": 4, "episode": 11, "scene": 51, "line": "Yes.", "order": 19490, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 11, "scene": 51, "line": "Do you remember what color my eyes are?", "order": 19491, "speaker": "Michael Scott"},
  {"season": 4, "episode": 11, "scene": 51, "line": "Hazel. They're hazel.", "order": 19492, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 11, "scene": 51, "line": "Dwight, I asked him.", "order": 19493, "speaker": "Michael Scott"},
  {"season": 4, "episode": 11, "scene": 51, "line": "No, I, I was going to say hazel, yeah.", "order": 19494, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 11, "scene": 51, "line": "Really?", "order": 19495, "speaker": "Michael Scott"},
  {"season": 4, "episode": 11, "scene": 51, "line": "Yeah.", "order": 19496, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 11, "scene": 51, "line": "Not just because he said it?", "order": 19497, "speaker": "Michael Scott"},
  {"season": 4, "episode": 11, "scene": 51, "line": "No.", "order": 19498, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 11, "scene": 51, "line": "Ryan?", "order": 19499, "speaker": "Michael Scott"},
  {"season": 4, "episode": 11, "scene": 51, "line": "You're my friend, Michael. OK?", "order": 19500, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 12, "scene": 1, "line": "Everybody! Everybody! Listen up, I need your ideas, now! Ideas please, right now! Go! Go! Come on!", "order": 19501, "speaker": "Michael Scott"},
  {"season": 4, "episode": 12, "scene": 1, "line": "Michael we don't know what you're talking about...", "order": 19502, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 12, "scene": 1, "line": "Wet cement, outside. It's drying fast, come on. This is a lifelong dream. What do I write? What do I write?", "order": 19503, "speaker": "Michael Scott"},
  {"season": 4, "episode": 12, "scene": 1, "line": "Michael you could put your initials in it.", "order": 19504, "speaker": "Kevin Malone"},
  {"season": 4, "episode": 12, "scene": 1, "line": "M.G.S.? No. Some idiot named Mark Greg Sputnik will claim credit for it. I don't...", "order": 19505, "speaker": "Michael Scott"},
  {"season": 4, "episode": 12, "scene": 1, "line": "Yeah that's true.", "order": 19506, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 12, "scene": 1, "line": "Once in a lifetime opportunity people! Come on, here we go!", "order": 19507, "speaker": "Michael Scott"},
  {"season": 4, "episode": 12, "scene": 1, "line": "We-- we--", "order": 19508, "speaker": "Phyllis Vance"},
  {"season": 4, "episode": 12, "scene": 1, "line": "Here we go!", "order": 19509, "speaker": "Michael Scott"},
  {"season": 4, "episode": 12, "scene": 1, "line": "Well--", "order": 19510, "speaker": "Phyllis Vance"},
  {"season": 4, "episode": 12, "scene": 1, "line": "Let's, yes?", "order": 19511, "speaker": "Michael Scott"},
  {"season": 4, "episode": 12, "scene": 1, "line": "When I was a little girl...", "order": 19512, "speaker": "Phyllis Vance"},
  {"season": 4, "episode": 12, "scene": 1, "line": "Okay... Okay, do it! Go on! Great! Let's hear it!", "order": 19513, "speaker": "Michael Scott"},
  {"season": 4, "episode": 12, "scene": 1, "line": "We-we found some wet cement in the park...", "order": 19514, "speaker": "Phyllis Vance"},
  {"season": 4, "episode": 12, "scene": 1, "line": "It's drying, it's drying...", "order": 19515, "speaker": "Michael Scott"},
  {"season": 4, "episode": 12, "scene": 1, "line": "In our neighborhood...", "order": 19516, "speaker": "Phyllis Vance"},
  {"season": 4, "episode": 12, "scene": 1, "line": "Alright, hablbelah, come on, Phyl!", "order": 19517, "speaker": "Michael Scott"},
  {"season": 4, "episode": 12, "scene": 1, "line": "What did we write?", "order": 19518, "speaker": "Phyllis Vance"},
  {"season": 4, "episode": 12, "scene": 1, "line": "Ahh come on!", "order": 19519, "speaker": "Michael Scott"},
  {"season": 4, "episode": 12, "scene": 1, "line": "Here it is, you should draw a picture...", "order": 19520, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 12, "scene": 1, "line": "No!", "order": 19521, "speaker": "Michael Scott"},
  {"season": 4, "episode": 12, "scene": 1, "line": "Cause that says, that says...", "order": 19522, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 12, "scene": 1, "line": "No!", "order": 19523, "speaker": "Michael Scott"},
  {"season": 4, "episode": 12, "scene": 1, "line": "So much more than words...", "order": 19524, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 12, "scene": 1, "line": "No!! Come on, gimme something good!", "order": 19525, "speaker": "Michael Scott"},
  {"season": 4, "episode": 12, "scene": 1, "line": "Ok, ok, I was watching E!, and I saw Will Smith outside the Chinese Theater, and omhmygod, he looked so good...", "order": 19526, "speaker": "Kelly Kapoor"},
  {"season": 4, "episode": 12, "scene": 1, "line": "Pam, translate.", "order": 19527, "speaker": "Michael Scott"},
  {"season": 4, "episode": 12, "scene": 1, "line": "She's talking about the handprints that celebrities make in the cement.", "order": 19528, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 12, "scene": 1, "line": "I love it!", "order": 19529, "speaker": "Michael Scott"},
  {"season": 4, "episode": 12, "scene": 1, "line": "If you were a real star you would put your face in it.", "order": 19530, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 12, "scene": 1, "line": "I love it more!", "order": 19531, "speaker": "Michael Scott"},
  {"season": 4, "episode": 12, "scene": 1, "line": "Michael that doesn't seem... safe.", "order": 19532, "speaker": "Oscar Martinez"},
  {"season": 4, "episode": 12, "scene": 1, "line": "I... love it!! Haha, come on!", "order": 19533, "speaker": "Michael Scott"},
  {"season": 4, "episode": 12, "scene": 1, "line": "Come on! [Kevin and Dwight laugh and follow Michael]", "order": 19534, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 12, "scene": 2, "line": "We are here today, not to immortalize a man, but an idea... Maybe the idea of a man.", "order": 19535, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 12, "scene": 2, "line": "[with petroleum jelly on his face] Hurry, please.", "order": 19536, "speaker": "Michael Scott"},
  {"season": 4, "episode": 12, "scene": 2, "line": "Greatness is only skin deep, some people say...", "order": 19537, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 12, "scene": 2, "line": "Can you breathe?", "order": 19538, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 12, "scene": 2, "line": "Well that's not true. Other people believe it's deeper inside...", "order": 19539, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 12, "scene": 2, "line": "Hold your breath.", "order": 19540, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 12, "scene": 2, "line": "And in this case...", "order": 19541, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 12, "scene": 2, "line": "Ready?", "order": 19542, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 12, "scene": 2, "line": "That's also not true.", "order": 19543, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 12, "scene": 2, "line": "And... go. [Michael sticks his face in the cement] Force it in as deep as you can.", "order": 19544, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 12, "scene": 2, "line": "[muffled] That's what she said.", "order": 19545, "speaker": "Michael Scott"},
  {"season": 4, "episode": 12, "scene": 3, "line": "Today is a very special day for me. And it's really not about me, it's about my grandkids, it's about my great grandkids... I can come back here when I'm 100, and I can find that piece of cement and say, 'That's me. Look kids, your daddy left that face hole...' I dunno, it's a good feeling.", "order": 19546, "speaker": "Michael Scott"},
  {"season": 4, "episode": 12, "scene": 4, "line": "[playing with Madlibs] Okay, adjective...", "order": 19547, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 12, "scene": 4, "line": "Um... tall. No, no, uh, nice.", "order": 19548, "speaker": "Angela Martin"},
  {"season": 4, "episode": 12, "scene": 4, "line": "Good one. Okay, you ready?", "order": 19549, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 12, "scene": 4, "line": "Mmhmm.", "order": 19550, "speaker": "Angela Martin"},
  {"season": 4, "episode": 12, "scene": 4, "line": "[reads] The tall man entered the nice building to visit a very nice man. 'Sit down, Mr. Smith, can I interest you in any good... cat food?'", "order": 19551, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 12, "scene": 4, "line": "[laughing] It's a man eating cat food.", "order": 19552, "speaker": "Angela Martin"},
  {"season": 4, "episode": 12, "scene": 4, "line": "What about a cat eating man food?", "order": 19553, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 12, "scene": 5, "line": "Andy and Angela seem very happy... I hope nothing horrible ever happens to them.", "order": 19554, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 12, "scene": 6, "line": "[wearing glasses] Um... yeah, I slept over at a, uh, friend's house and, I forgot my contact solution, so... I had to wear my backup glasses... Shut up.", "order": 19555, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 12, "scene": 7, "line": "All right, everybody... Oh my God, Pam, those make you look so ugly. Um, Pam, in order to get hotter, you take the glasses off. You're moving in the wrong direction...", "order": 19556, "speaker": "Michael Scott"},
  {"season": 4, "episode": 12, "scene": 7, "line": "I don't have my contacts...", "order": 19557, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 12, "scene": 7, "line": "Ub, dub dah, don't need, I can't even hear you. It's just noise coming out of an ugly scientist. Um, everybody, we're going to have a little brainstorming session in the conference room.", "order": 19558, "speaker": "Michael Scott"},
  {"season": 4, "episode": 12, "scene": 8, "line": "Ener-gize...", "order": 19559, "speaker": "Michael Scott"},
  {"season": 4, "episode": 12, "scene": 8, "line": "You know what would energize me? If you, Pamela Morgan Beesly...", "order": 19560, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 12, "scene": 8, "line": "Don't, don't--", "order": 19561, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 12, "scene": 8, "line": "Would...", "order": 19562, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 12, "scene": 8, "line": "If you propose to me during a Michael meeting, I will say no.", "order": 19563, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 12, "scene": 8, "line": "Well, it's too late, because I'm proposing... that you get me a cup of coffee, which would energize me greatly, and make me the happiest man in the world.", "order": 19564, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 12, "scene": 8, "line": "Everybody, so how are we going to energize our office? I mean, I haven't done anything since Christmas. Pam, clearly, has just given up trying. So what we need to do is we get things going, we need to get percolating a little bit. Anybody have any ideas of what we could do. Any suggestions? Yes, Andy?", "order": 19565, "speaker": "Michael Scott"},
  {"season": 4, "episode": 12, "scene": 8, "line": "What if we changed our outgoing answering machine message, so it just had a little more... zing, and a little more pep?", "order": 19566, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 12, "scene": 8, "line": "Zing and pep, see that's those are the kind of words we're looking for. Yes, Jim?", "order": 19567, "speaker": "Michael Scott"},
  {"season": 4, "episode": 12, "scene": 8, "line": "What about if we did an even newer voicemail message that had even more zing and pep?", "order": 19568, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 12, "scene": 8, "line": "Now we're cooking. I like this. Maybe a whole theme, like a rap. A rap rhyme...", "order": 19569, "speaker": "Michael Scott"},
  {"season": 4, "episode": 12, "scene": 8, "line": "An urban thing...", "order": 19570, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 12, "scene": 8, "line": "An urban, yeah, Stanley? You wanna help us out with that?... Stanley, earth to Stanley...", "order": 19571, "speaker": "Michael Scott"},
  {"season": 4, "episode": 12, "scene": 8, "line": "Not me.", "order": 19572, "speaker": "Stanley Hudson"},
  {"season": 4, "episode": 12, "scene": 8, "line": "Yes you. Come on, Stanley, put your little game down and join the group.", "order": 19573, "speaker": "Michael Scott"},
  {"season": 4, "episode": 12, "scene": 8, "line": "No.", "order": 19574, "speaker": "Stanley Hudson"},
  {"season": 4, "episode": 12, "scene": 8, "line": "Stanley, we're havin a little...", "order": 19575, "speaker": "Michael Scott"},
  {"season": 4, "episode": 12, "scene": 8, "line": "Leave me alone, dammit.", "order": 19576, "speaker": "Stanley Hudson"},
  {"season": 4, "episode": 12, "scene": 8, "line": "We're havin a little brainstorm session...", "order": 19577, "speaker": "Michael Scott"},
  {"season": 4, "episode": 12, "scene": 8, "line": "DID I STUTTER?", "order": 19578, "speaker": "Stanley Hudson"},
  {"season": 4, "episode": 12, "scene": 8, "line": "...Good, this is good. I'm going to grab a glass of water.", "order": 19579, "speaker": "Michael Scott"},
  {"season": 4, "episode": 12, "scene": 9, "line": "Can I talk to you a second?", "order": 19580, "speaker": "Toby Flenderson"},
  {"season": 4, "episode": 12, "scene": 9, "line": "Ugh, what?", "order": 19581, "speaker": "Michael Scott"},
  {"season": 4, "episode": 12, "scene": 9, "line": "Uh, I really think that you need to address what happened with Stanley this morning...", "order": 19582, "speaker": "Toby Flenderson"},
  {"season": 4, "episode": 12, "scene": 9, "line": "What are you blabbering about? Nothing happened.", "order": 19583, "speaker": "Michael Scott"},
  {"season": 4, "episode": 12, "scene": 9, "line": "Well, you know, Stanley was openly insubordinate.", "order": 19584, "speaker": "Toby Flenderson"},
  {"season": 4, "episode": 12, "scene": 9, "line": "That? In the conference room?", "order": 19585, "speaker": "Michael Scott"},
  {"season": 4, "episode": 12, "scene": 9, "line": "Yeah.", "order": 19586, "speaker": "Toby Flenderson"},
  {"season": 4, "episode": 12, "scene": 9, "line": "We were joshin around, the two of us, and he said 'did I stutter,' and I said 'wha-wha-wha-wha-what, dawg?' It was joking, Toby, alright?", "order": 19587, "speaker": "Michael Scott"},
  {"season": 4, "episode": 12, "scene": 9, "line": "He didn't seem like he was joking.", "order": 19588, "speaker": "Toby Flenderson"},
  {"season": 4, "episode": 12, "scene": 9, "line": "Well you don't get it, because Stanley is a beautiful, sassy, powerful black man, and you're... you. If you had any friends, you would understand. Friends joke with one another: 'Hey um, you're poor.' 'Well hey, you're mama's dead.' That's what friends do. It's... you're so white.", "order": 19589, "speaker": "Michael Scott"},
  {"season": 4, "episode": 12, "scene": 9, "line": "Alright... Well, um, if you really believe that Stanley was joking...", "order": 19590, "speaker": "Toby Flenderson"},
  {"season": 4, "episode": 12, "scene": 9, "line": "Mmhmm.", "order": 19591, "speaker": "Michael Scott"},
  {"season": 4, "episode": 12, "scene": 9, "line": "Um, why don't you go ask him that?", "order": 19592, "speaker": "Toby Flenderson"},
  {"season": 4, "episode": 12, "scene": 9, "line": "Okay, I will... I'm not feeling very well right now though. My stomach hurts, so I may be going home early today, but...", "order": 19593, "speaker": "Michael Scott"},
  {"season": 4, "episode": 12, "scene": 9, "line": "You know, Michael, sometimes my daughter's stomach hurts when there's a mean girl at school...", "order": 19594, "speaker": "Toby Flenderson"},
  {"season": 4, "episode": 12, "scene": 9, "line": "Well sometimes my stomach hurts when you come into my office, so it's probably psychological.", "order": 19595, "speaker": "Michael Scott"},
  {"season": 4, "episode": 12, "scene": 10, "line": "You meet a lot of ladies driving an Xterra, because you pull up to a stoplight, and look over and there's an Xterra next to you. They're always driven by chicks, so there's your icebreaker.", "order": 19596, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 12, "scene": 11, "line": "[kicks the car] Unh! Scratch.", "order": 19597, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 12, "scene": 11, "line": "That's a racing stripe.", "order": 19598, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 12, "scene": 11, "line": "Uh, bumper's sagging.", "order": 19599, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 12, "scene": 11, "line": "Mmm, I doubt that very much.", "order": 19600, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 12, "scene": 11, "line": "This car's crap. I will buy it for next to nothing.", "order": 19601, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 12, "scene": 11, "line": "How next to?", "order": 19602, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 12, "scene": 11, "line": "Well, here are your options: you can sell it for parts, drive it off a cliff... you can donate it to a person that you'd like to see die in a car crash, or, you can sell it to me, and I'll use it as I would a wagon on my farm. It will be towed by a donkey.", "order": 19603, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 12, "scene": 11, "line": "I have to pick one of those?", "order": 19604, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 12, "scene": 11, "line": "Yes.", "order": 19605, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 12, "scene": 11, "line": "Can you go over those options again?", "order": 19606, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 12, "scene": 11, "line": "You know what? You knock $1500 of the price right now, and I will take it off your hands. It's gotta be now.", "order": 19607, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 12, "scene": 11, "line": "Well I have the, the blue book value...", "order": 19608, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 12, "scene": 11, "line": "Seal the deal. Let's do it. Let's do this thing. Three, two, one--", "order": 19609, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 12, "scene": 11, "line": "Can I think about it?", "order": 19610, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 12, "scene": 11, "line": "Five, four, three, two...", "order": 19611, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 12, "scene": 11, "line": "Let me think about it--", "order": 19612, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 12, "scene": 11, "line": "Now. Now. Now.", "order": 19613, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 12, "scene": 11, "line": "Let me think about it--", "order": 19614, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 12, "scene": 11, "line": "Say it. Do it. Now.", "order": 19615, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 12, "scene": 11, "line": "I'm thinking about it--", "order": 19616, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 12, "scene": 11, "line": "Do it now.", "order": 19617, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 12, "scene": 11, "line": "Thinking--", "order": 19618, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 12, "scene": 11, "line": "Do it. Shake my hand. You will sell me this car. Shake my hand.", "order": 19619, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 12, "scene": 11, "line": "Yeah. All right!", "order": 19620, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 12, "scene": 12, "line": "What.", "order": 19621, "speaker": "Stanley Hudson"},
  {"season": 4, "episode": 12, "scene": 12, "line": "I, uh, see that you... gave me that form that I asked you to give me, Stanley the manly, so thank you for that. I guess we are back to normal.", "order": 19622, "speaker": "Michael Scott"},
  {"season": 4, "episode": 12, "scene": 12, "line": "What are you talking about?", "order": 19623, "speaker": "Stanley Hudson"},
  {"season": 4, "episode": 12, "scene": 12, "line": "Well, you know, uh, the thing that you said earlier, that you didn't mean, um, and that I forgive you for. The whole thing's silly, isn't it? Friends don't need to apologize to friends as far as I'm concerned, so we are cool.", "order": 19624, "speaker": "Michael Scott"},
  {"season": 4, "episode": 12, "scene": 12, "line": "I am not going to apologize to you.", "order": 19625, "speaker": "Stanley Hudson"},
  {"season": 4, "episode": 12, "scene": 13, "line": "It's like I used to tell my wife, I do not apologize unless I think I'm wrong, and if you don't like it you can leave. And I say the same thing to my current wife, and I'll say it to my next one too.", "order": 19626, "speaker": "Stanley Hudson"},
  {"season": 4, "episode": 12, "scene": 14, "line": "Jim is in charge of Stanley only in sales-related matters, hence the green line--green for money-sales, get it?", "order": 19627, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 12, "scene": 14, "line": "Uh huh...", "order": 19628, "speaker": "Michael Scott"},
  {"season": 4, "episode": 12, "scene": 14, "line": "There is this yellow zigzag that does give Ryan the authority to discipline Stanley...", "order": 19629, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 12, "scene": 14, "line": "Great", "order": 19630, "speaker": "Michael Scott"},
  {"season": 4, "episode": 12, "scene": 14, "line": "However, in so doing, it zigs past your name, hence zagging you and making you appear weak. Thus the yellow color. Yellow for cowardly.", "order": 19631, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 12, "scene": 14, "line": "What's the pink?", "order": 19632, "speaker": "Michael Scott"},
  {"season": 4, "episode": 12, "scene": 14, "line": "Menstrual cycles.", "order": 19633, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 12, "scene": 14, "line": "Ok... Is there anybody up here, anybody at all, that can deal with this?", "order": 19634, "speaker": "Michael Scott"},
  {"season": 4, "episode": 12, "scene": 14, "line": "You.", "order": 19635, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 12, "scene": 14, "line": "Other than me.", "order": 19636, "speaker": "Michael Scott"},
  {"season": 4, "episode": 12, "scene": 14, "line": "Well, there is the emergency disaster mode for the org chart. This gives me full authority over every single person in the office.", "order": 19637, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 12, "scene": 14, "line": "I never said you could do that.", "order": 19638, "speaker": "Michael Scott"},
  {"season": 4, "episode": 12, "scene": 14, "line": "All you have to do it say it.", "order": 19639, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 12, "scene": 14, "line": "I...", "order": 19640, "speaker": "Michael Scott"},
  {"season": 4, "episode": 12, "scene": 14, "line": "Just say it.", "order": 19641, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 12, "scene": 14, "line": "Okay, I will think about it.", "order": 19642, "speaker": "Michael Scott"},
  {"season": 4, "episode": 12, "scene": 14, "line": "Say it. Just do it. Don't think, say it. Do it. Five, four, three, two... do it. Give me control. Michael... I promise to give authority back to you when this crisis is over. Do it, Michael. Do it. Hey, this office needs a strong man. Say it.", "order": 19643, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 12, "scene": 14, "line": "No.", "order": 19644, "speaker": "Michael Scott"},
  {"season": 4, "episode": 12, "scene": 14, "line": "So... All right, well, you're gonna have to deal with this yourself.", "order": 19645, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 12, "scene": 15, "line": "Hey Pam, I really like your glasses.", "order": 19646, "speaker": "Kevin Malone"},
  {"season": 4, "episode": 12, "scene": 15, "line": "Oh, thanks.", "order": 19647, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 12, "scene": 15, "line": "All the girlfriends that I've ever had have worn glasses.", "order": 19648, "speaker": "Kevin Malone"},
  {"season": 4, "episode": 12, "scene": 15, "line": "Oh, okay...", "order": 19649, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 12, "scene": 15, "line": "Yeah, it's kind of a turn-on for me actually...", "order": 19650, "speaker": "Kevin Malone"},
  {"season": 4, "episode": 12, "scene": 15, "line": "I should probably get back to...", "order": 19651, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 12, "scene": 15, "line": "Like librarians.", "order": 19652, "speaker": "Kevin Malone"},
  {"season": 4, "episode": 12, "scene": 15, "line": "Entering the....", "order": 19653, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 12, "scene": 15, "line": "Could you just say, 'these are due back Thursday'?", "order": 19654, "speaker": "Kevin Malone"},
  {"season": 4, "episode": 12, "scene": 15, "line": "No. [Kevin leaves; Pam takes off her glasses; phone rings] Dunder Mifflin, this is Pam. Okay, go ahead. [puts a notepad close to her face and writes message]", "order": 19655, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 12, "scene": 16, "line": "A lot of jazz cats are blind, but they can play the piano like nobody's business. I'd like to put the piano in front of Pam without her glasses and see what happens. I'd also like to see her topless.", "order": 19656, "speaker": "Creed Bratton"},
  {"season": 4, "episode": 12, "scene": 17, "line": "Mike.", "order": 19657, "speaker": "Darryl Philbin"},
  {"season": 4, "episode": 12, "scene": 17, "line": "Hey.", "order": 19658, "speaker": "Michael Scott"},
  {"season": 4, "episode": 12, "scene": 17, "line": "I'm very busy here, man.", "order": 19659, "speaker": "Darryl Philbin"},
  {"season": 4, "episode": 12, "scene": 17, "line": "Darryl, [clears throat] have you ever been in a gang?", "order": 19660, "speaker": "Michael Scott"},
  {"season": 4, "episode": 12, "scene": 17, "line": "Why?", "order": 19661, "speaker": "Darryl Philbin"},
  {"season": 4, "episode": 12, "scene": 17, "line": "It's an advice question, and if you don't wanna talk about it, I completely understand. It's, um...", "order": 19662, "speaker": "Michael Scott"},
  {"season": 4, "episode": 12, "scene": 17, "line": "No, no, no...", "order": 19663, "speaker": "Darryl Philbin"},
  {"season": 4, "episode": 12, "scene": 17, "line": "I know, it's very, very personal...", "order": 19664, "speaker": "Michael Scott"},
  {"season": 4, "episode": 12, "scene": 17, "line": "No, um... I have.", "order": 19665, "speaker": "Darryl Philbin"},
  {"season": 4, "episode": 12, "scene": 17, "line": "I knew it. Okay, who we talking about here? Crips? Bloods?", "order": 19666, "speaker": "Michael Scott"},
  {"season": 4, "episode": 12, "scene": 17, "line": "Both.", "order": 19667, "speaker": "Darryl Philbin"},
  {"season": 4, "episode": 12, "scene": 17, "line": "God.", "order": 19668, "speaker": "Michael Scott"},
  {"season": 4, "episode": 12, "scene": 17, "line": "Yeah them, and the Latin Kings... the Warriors...", "order": 19669, "speaker": "Darryl Philbin"},
  {"season": 4, "episode": 12, "scene": 17, "line": "Okay.", "order": 19670, "speaker": "Michael Scott"},
  {"season": 4, "episode": 12, "scene": 17, "line": "Newsies.", "order": 19671, "speaker": "Darryl Philbin"},
  {"season": 4, "episode": 12, "scene": 17, "line": "Okay, so dig this: you're on the street, and one of your gang disses you.", "order": 19672, "speaker": "Michael Scott"},
  {"season": 4, "episode": 12, "scene": 17, "line": "Oh my goodness.", "order": 19673, "speaker": "Darryl Philbin"},
  {"season": 4, "episode": 12, "scene": 17, "line": "Yeah, right, so what do you do to get them to make it right?", "order": 19674, "speaker": "Michael Scott"},
  {"season": 4, "episode": 12, "scene": 17, "line": "Well see, um... in the gang world, we use something called Fluffy Fingers.", "order": 19675, "speaker": "Darryl Philbin"},
  {"season": 4, "episode": 12, "scene": 17, "line": "What is that?", "order": 19676, "speaker": "Michael Scott"},
  {"season": 4, "episode": 12, "scene": 17, "line": "That's when somebody really gets in your face, you know, you just... start tickling them.", "order": 19677, "speaker": "Darryl Philbin"},
  {"season": 4, "episode": 12, "scene": 17, "line": "Really?", "order": 19678, "speaker": "Michael Scott"},
  {"season": 4, "episode": 12, "scene": 17, "line": "Yeah, and then he starts tickling you. You know, pretty soon you're laughing and hugging. Before you know it, you've forgotten the whole thing. Ya'll can just go to church together... get an ice cream cone.", "order": 19679, "speaker": "Darryl Philbin"},
  {"season": 4, "episode": 12, "scene": 17, "line": "I would've never thought that gangs would be tickling each other.", "order": 19680, "speaker": "Michael Scott"},
  {"season": 4, "episode": 12, "scene": 17, "line": "Well, it's effective.", "order": 19681, "speaker": "Darryl Philbin"},
  {"season": 4, "episode": 12, "scene": 18, "line": "[to Toby] I think it's self-explanatory. [to Jim] Hey, Jim, come here for a second. Can you answer me something, as, as a true Eagles fan?", "order": 19682, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 12, "scene": 18, "line": "Oh boy...", "order": 19683, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 12, "scene": 18, "line": "How do you live with a franchise this bad?", "order": 19684, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 12, "scene": 18, "line": "On a wing and a prayer, mostly.", "order": 19685, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 12, "scene": 18, "line": "Alright, whatever you say. Uh, listen, while I have you here with Toby, I need to give you a formal warning about your job performance.", "order": 19686, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 12, "scene": 18, "line": "A formal warning... [chuckles]", "order": 19687, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 12, "scene": 18, "line": "It's actually not a joke. I know how you spend your time here, and I know how little you care about your job. And honestly if you spent as much time selling as you do goofing around with Dwight and hanging out at reception, we wouldn't be having this conversation.", "order": 19688, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 12, "scene": 18, "line": "[stammers] I'm sorry, is, is this because I talked to Wallace about your website? Because I really didn't mean to go over your head.", "order": 19689, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 12, "scene": 18, "line": "This is nothing to do with that, alright? I always appreciate constructive criticism about my job performance. I thrive on it.", "order": 19690, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 12, "scene": 18, "line": "I'm sorry, then do you mind explaining a little better? Cause I'm not sure what's really...", "order": 19691, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 12, "scene": 18, "line": "Woah, don't get all defensive, alright? It's just a warning. If you want the details, Toby can provide them. You take it easy, alright?", "order": 19692, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 12, "scene": 19, "line": "I'd say all the goofing around at Pam's desk, and, and hanging out with Pam, has finally caught up to him... w-with Pam", "order": 19693, "speaker": "Toby Flenderson"},
  {"season": 4, "episode": 12, "scene": 20, "line": "Hey, what happened? What did Ryan want?", "order": 19694, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 12, "scene": 20, "line": "Oh we were just... talking about, bureaucratic stuff...", "order": 19695, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 12, "scene": 20, "line": "Because you're very important?", "order": 19696, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 12, "scene": 20, "line": "Yeah...", "order": 19697, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 12, "scene": 21, "line": "[washing the Xterra] Rocka doopa deepa do POW! Shacka do DO! Oh! What's Dwight up to? Oh probably nothing. Racka do do dee do-do do-do! Sha-KA!", "order": 19698, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 12, "scene": 22, "line": "What the hell is this all about? [holds up flier] You're flipping my car for profit.", "order": 19699, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 12, "scene": 22, "line": "It's my car now.", "order": 19700, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 12, "scene": 22, "line": "I gave you a deal... based on what you said to me.", "order": 19701, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 12, "scene": 22, "line": "Yeah, well... seller beware. Now if you'll excuse me, I've got to monitor a three-way bidding war for my car on eBay.", "order": 19702, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 12, "scene": 23, "line": "I've got a Madlib for you: a stupid, idiotic, numbskull named Andy Bernard sold his Xterra to a smart and capable man named Dwight. This is shaping up to be an awesome day for Dwight.", "order": 19703, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 12, "scene": 24, "line": "Okay, everybody, shh! So, earlier today, Stanley sassed me, and Toby gave me some suggestions on how to discipline him. They did not work, obviously, because they were stupid, so I am now going to fake fire him.", "order": 19704, "speaker": "Michael Scott"},
  {"season": 4, "episode": 12, "scene": 24, "line": "What does that mean?", "order": 19705, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 12, "scene": 24, "line": "It's like a mock execution.", "order": 19706, "speaker": "Michael Scott"},
  {"season": 4, "episode": 12, "scene": 24, "line": "That's not a good idea.", "order": 19707, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 12, "scene": 24, "line": "Yes it is a good idea. It's the only possible solution I have left.", "order": 19708, "speaker": "Michael Scott"},
  {"season": 4, "episode": 12, "scene": 24, "line": "Well, you can actually fire him.", "order": 19709, "speaker": "Toby Flenderson"},
  {"season": 4, "episode": 12, "scene": 24, "line": "No, okay... I've had enough of you.", "order": 19710, "speaker": "Michael Scott"},
  {"season": 4, "episode": 12, "scene": 24, "line": "Why are you telling us this?", "order": 19711, "speaker": "Oscar Martinez"},
  {"season": 4, "episode": 12, "scene": 24, "line": "Because I want you to behave as if I'm actually firing him, Oscar, okay?", "order": 19712, "speaker": "Michael Scott"},
  {"season": 4, "episode": 12, "scene": 24, "line": "Michael, if you hadn't told us this, then we would've thought that you were actually firing him.", "order": 19713, "speaker": "Kevin Malone"},
  {"season": 4, "episode": 12, "scene": 24, "line": "I'm not firing him. I'm not, I need you to act like I am firing him. Just, what I'm going to do, is I'm going to pretend that I am firing him, and I need you to act like I am firing him. Do you get that? Do you get it? I'm teaching him a lesson. He needs to learn humility, alright? That's all I'm, okay, here he comes. Let's just... play act. [Stanley enters the room] Stanley, may I talk to you for a second?", "order": 19714, "speaker": "Michael Scott"},
  {"season": 4, "episode": 12, "scene": 25, "line": "Stanley Hudson, you are fired.", "order": 19715, "speaker": "Michael Scott"},
  {"season": 4, "episode": 12, "scene": 25, "line": "Are you serious?", "order": 19716, "speaker": "Stanley Hudson"},
  {"season": 4, "episode": 12, "scene": 25, "line": "I am serious, we are all serious. You are fired like a heart attack.", "order": 19717, "speaker": "Michael Scott"},
  {"season": 4, "episode": 12, "scene": 25, "line": "You're firing me over three words. Have you lost your mind? Do you think I'm gonna let you do this to me?", "order": 19718, "speaker": "Stanley Hudson"},
  {"season": 4, "episode": 12, "scene": 25, "line": "Hmm.", "order": 19719, "speaker": "Michael Scott"},
  {"season": 4, "episode": 12, "scene": 25, "line": "I've watched you screw up this office for ten years, and I'm filing a lawsuit, and I'm gonna tell them about every stupid thing you've ever done up in this office.", "order": 19720, "speaker": "Stanley Hudson"},
  {"season": 4, "episode": 12, "scene": 25, "line": "Alright alright, okay, you know what? Now you know how I feel. This was a fake firing. Lesson learned. Good work, everybody. Very nice.", "order": 19721, "speaker": "Michael Scott"},
  {"season": 4, "episode": 12, "scene": 25, "line": "So I'm not fired.", "order": 19722, "speaker": "Stanley Hudson"},
  {"season": 4, "episode": 12, "scene": 25, "line": "That's it. And uh... do you have anything to say to me?", "order": 19723, "speaker": "Michael Scott"},
  {"season": 4, "episode": 12, "scene": 25, "line": "Oh yes, I do. You are out of your damn, little pea-sized, mind. What is wrong with you? Do you have any sense? At all?", "order": 19724, "speaker": "Stanley Hudson"},
  {"season": 4, "episode": 12, "scene": 25, "line": "Okay...", "order": 19725, "speaker": "Michael Scott"},
  {"season": 4, "episode": 12, "scene": 25, "line": "Do you have any idea how to run an office?", "order": 19726, "speaker": "Stanley Hudson"},
  {"season": 4, "episode": 12, "scene": 25, "line": "Yes.", "order": 19727, "speaker": "Michael Scott"},
  {"season": 4, "episode": 12, "scene": 25, "line": "Every day you do something stupider than you did the day before.", "order": 19728, "speaker": "Stanley Hudson"},
  {"season": 4, "episode": 12, "scene": 25, "line": "That's not-", "order": 19729, "speaker": "Michael Scott"},
  {"season": 4, "episode": 12, "scene": 25, "line": "And I think, 'there's no possible way--", "order": 19730, "speaker": "Stanley Hudson"},
  {"season": 4, "episode": 12, "scene": 25, "line": "All right, all right...", "order": 19731, "speaker": "Michael Scott"},
  {"season": 4, "episode": 12, "scene": 25, "line": "He can top that.'", "order": 19732, "speaker": "Stanley Hudson"},
  {"season": 4, "episode": 12, "scene": 25, "line": "Okay.", "order": 19733, "speaker": "Michael Scott"},
  {"season": 4, "episode": 12, "scene": 25, "line": "But what do you do?! You find a way, dammit, to top it! You are professional idiot!", "order": 19734, "speaker": "Stanley Hudson"},
  {"season": 4, "episode": 12, "scene": 25, "line": "Hey, STOP IT! Okay, everybody out.", "order": 19735, "speaker": "Michael Scott"},
  {"season": 4, "episode": 12, "scene": 25, "line": "Who?", "order": 19736, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 12, "scene": 25, "line": "Everybody, except Stanley.", "order": 19737, "speaker": "Michael Scott"},
  {"season": 4, "episode": 12, "scene": 25, "line": "Let's go.", "order": 19738, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 12, "scene": 25, "line": "I can't find my glasses.", "order": 19739, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 12, "scene": 25, "line": "Okay, you know what? Why don't you take my hand, and leave them, and let's get out of here.", "order": 19740, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 12, "scene": 25, "line": "Okay, okay.", "order": 19741, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 12, "scene": 26, "line": "[chuckling] It's Michael versus Stanley, and it is the clash of the titans. In one corner, you have Michael, and he is mad. And then, in the other corner, you have Stanley, and he's mad. So that's about it!", "order": 19742, "speaker": "Kevin Malone"},
  {"season": 4, "episode": 12, "scene": 27, "line": "[starts to cry] I don't understand why you keep picking on me.", "order": 19743, "speaker": "Michael Scott"},
  {"season": 4, "episode": 12, "scene": 27, "line": "Oh, for the love of God.", "order": 19744, "speaker": "Stanley Hudson"},
  {"season": 4, "episode": 12, "scene": 27, "line": "You just, do, and I don't know why, so... please help me understand.", "order": 19745, "speaker": "Michael Scott"},
  {"season": 4, "episode": 12, "scene": 27, "line": "Fine. Here it is: you are a person I do not respect. The things you say, your actions, your methods, and style. Everything you would do, I would do it the opposite way.", "order": 19746, "speaker": "Stanley Hudson"},
  {"season": 4, "episode": 12, "scene": 27, "line": "Well Stanley, maybe you're feeling that you don't respect me because you don't know me very well.", "order": 19747, "speaker": "Michael Scott"},
  {"season": 4, "episode": 12, "scene": 27, "line": "Michael I have known you a very long time, and the more I've gotten to know you, the less I've come to respect you. Any other theories?", "order": 19748, "speaker": "Stanley Hudson"},
  {"season": 4, "episode": 12, "scene": 27, "line": "All right, you don't respect me. I accept that. But listen to me, you can't talk to me that way in this office, you just can't. I am your boss. Can't allow it.", "order": 19749, "speaker": "Michael Scott"},
  {"season": 4, "episode": 12, "scene": 27, "line": "Fair enough.", "order": 19750, "speaker": "Stanley Hudson"},
  {"season": 4, "episode": 12, "scene": 28, "line": "I am good person, and sometimes, good people don't get no respect. Rodney Dangerfield. [as Rodney Dangerfield] Hey, I don't get no respect. No respect at all. When I was in the sandbox as a kid, I got not respect. My wife likes to talk after sex, so she called me from a hotel room, and said, 'I don't respect you!' Ahh, thoughtless. Aw, you know what don't get no respect? Airplane food. Why don't they just make the plane out of the airline food? My wife don't get no respect, some--take her, please, for example. [as Jeff Foxworthy] If you don't get no respect, you might be redneck. [as Borat] Respect is niiice. Borat. [as Rodney Dangerfield] What's the deal with Grapenuts? No grapes, no nuts. I don't get no respect!", "order": 19751, "speaker": "Michael Scott"},
  {"season": 4, "episode": 12, "scene": 29, "line": "So I'm thinking, as a reward for our loyal clients, that we contact their assistants, and we find out where they live. And then, we go to their houses, in the middle of the summer, and go caroling. It is a summer Christmas sale-abration. And we call it, a summer sales-a-lot. Feedback? Anybody? Stanley?", "order": 19752, "speaker": "Michael Scott"},
  {"season": 4, "episode": 12, "scene": 29, "line": "Has potential to be your best idea yet. [Michael smiles]", "order": 19753, "speaker": "Stanley Hudson"},
  {"season": 4, "episode": 12, "scene": 29, "line": "That's the dumbest thing I ever heard. [laughs]", "order": 19754, "speaker": "Phyllis Vance"},
  {"season": 4, "episode": 12, "scene": 29, "line": "Dammit, Phyllis. Alright, everybody out except Phyllis.", "order": 19755, "speaker": "Michael Scott"},
  {"season": 4, "episode": 12, "scene": 30, "line": "You know, I don't care what he did to me. He's mean to other people, that's what gets me. He's just...", "order": 19756, "speaker": "Michael Scott"},
  {"season": 4, "episode": 12, "scene": 30, "line": "Who else is he mean to?", "order": 19757, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 12, "scene": 30, "line": "Everybody. He just, he's always, he's always talking about people behind their backs to me.", "order": 19758, "speaker": "Michael Scott"},
  {"season": 4, "episode": 12, "scene": 30, "line": "What kind of stuff does Stanley say?", "order": 19759, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 12, "scene": 30, "line": "Like that people are stupid, and that they're idiots, and that they're sluts.", "order": 19760, "speaker": "Michael Scott"},
  {"season": 4, "episode": 12, "scene": 30, "line": "Why would he say that?", "order": 19761, "speaker": "Kelly Kapoor"},
  {"season": 4, "episode": 12, "scene": 30, "line": "Because he's mean. He's, he's like a really mean-spirited guy. He talks about how gay Oscar is...", "order": 19762, "speaker": "Michael Scott"},
  {"season": 4, "episode": 12, "scene": 30, "line": "Mm-hmm.", "order": 19763, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 12, "scene": 30, "line": "And that Angela's a midget, and that [indicates Jim] your chin is too big.", "order": 19764, "speaker": "Michael Scott"},
  {"season": 4, "episode": 12, "scene": 30, "line": "Hey.", "order": 19765, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 12, "scene": 30, "line": "I know, I know. I think it's tiny. I think it's too small if anything. And that your glasses make you look ugly.", "order": 19766, "speaker": "Michael Scott"},
  {"season": 4, "episode": 12, "scene": 30, "line": "She's never worn these glasses before.", "order": 19767, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 12, "scene": 30, "line": "Well, he's just mean.", "order": 19768, "speaker": "Michael Scott"},
  {"season": 4, "episode": 12, "scene": 30, "line": "Michael, I think that what Stanley did to you was rude. Maybe you should just get him aside and tell him that he was behaving badly.", "order": 19769, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 12, "scene": 30, "line": "It's complicated, Pam. I mean, the world looks a lot easier from behind your reception desk.", "order": 19770, "speaker": "Michael Scott"},
  {"season": 4, "episode": 12, "scene": 30, "line": "Well, I actually agree with her, I think you should talk to him. And you also might want to explain to Andy that no one wants to help him decide whether or not he has a rash.", "order": 19771, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 12, "scene": 30, "line": "Oh, yeah, and then, yell at Creed, because you know what? Looking can be as bad as touching.", "order": 19772, "speaker": "Kelly Kapoor"},
  {"season": 4, "episode": 12, "scene": 30, "line": "Okay.", "order": 19773, "speaker": "Michael Scott"},
  {"season": 4, "episode": 12, "scene": 31, "line": "I put Stanley on a pedestal, for a long time. But sometimes he can just be a big, fat jerk. I... jerks don't deserve to be on pedestals. Jerks should be placed up somewhere where everybody can see how jerky they are. Big marble... jerk stand.", "order": 19774, "speaker": "Michael Scott"},
  {"season": 4, "episode": 12, "scene": 32, "line": "Pam, stand up.", "order": 19775, "speaker": "Michael Scott"},
  {"season": 4, "episode": 12, "scene": 32, "line": "Hi everyone. My name is Pam, and I wear glasses.", "order": 19776, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 12, "scene": 32, "line": "Hi, Pam.", "order": 19777, "speaker": "Meredith Palmer"},
  {"season": 4, "episode": 12, "scene": 32, "line": "Pretty disgusting.", "order": 19778, "speaker": "Michael Scott"},
  {"season": 4, "episode": 12, "scene": 33, "line": "I like your frames.", "order": 19779, "speaker": "Phyllis Vance"},
  {"season": 4, "episode": 12, "scene": 33, "line": "Thanks, Phyllis. Yours, too.", "order": 19780, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 12, "scene": 33, "line": "Oh, well, I'm already married. Boys don't make passes at girls who wear glasses.", "order": 19781, "speaker": "Phyllis Vance"},
  {"season": 4, "episode": 12, "scene": 33, "line": "Ignore her, those glasses are so cool.", "order": 19782, "speaker": "Kelly Kapoor"},
  {"season": 4, "episode": 12, "scene": 33, "line": "Really?", "order": 19783, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 12, "scene": 33, "line": "Yeah, you look like Lisa Loeb or Tina Fey or someone. You should definitely wear them all the time.", "order": 19784, "speaker": "Kelly Kapoor"},
  {"season": 4, "episode": 12, "scene": 33, "line": "Huh. Maybe I will.", "order": 19785, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 12, "scene": 34, "line": "Guess who just became the cutest girl in the office.", "order": 19786, "speaker": "Kelly Kapoor"},
  {"season": 4, "episode": 12, "scene": 35, "line": "What's it like without my glasses? Um, well, here, I'll show you. Unfocus your lens. A lot. I'm 20/400. You got it? Okay, I'm gonna spend the rest of the day like this. I can't see any of the things that would bother me on a normal day. I can't see anything disgusting, or ugly, or Michael... it's great.", "order": 19787, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 12, "scene": 36, "line": "Yo!", "order": 19788, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 12, "scene": 36, "line": "Hi, can I help you?", "order": 19789, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 12, "scene": 36, "line": "Haha, nice! I guess I have been a stranger lately.", "order": 19790, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 12, "scene": 36, "line": "[pointing] Ryan!", "order": 19791, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 12, "scene": 36, "line": "Hey, Pam, do you want a bagel? [throws it]", "order": 19792, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 12, "scene": 36, "line": "Yeah.", "order": 19793, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 12, "scene": 36, "line": "Think fast. [it hits her in the face] Honestly, Pam, the bagels in New York are the only good bagels in the world, I swear to God.", "order": 19794, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 12, "scene": 36, "line": "Okay.", "order": 19795, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 12, "scene": 36, "line": "The only good bagels anywhere.", "order": 19796, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 12, "scene": 36, "line": "I've heard that. [reaches down to pick up bagel from the floor]", "order": 19797, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 12, "scene": 36, "line": "Same with coffee, and women.", "order": 19798, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 12, "scene": 36, "line": "Okay.", "order": 19799, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 12, "scene": 36, "line": "I'm gonna go find Toby. Oh, here's what you do. [takes her bagel] You scoop out the middle, like that, and then you just eat the shell. You know? [pats his belly, points to Pam] Fewer carbs.", "order": 19800, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 12, "scene": 37, "line": "Yeah, if I could have seen what he just did, I think I would have gotten angry.", "order": 19801, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 12, "scene": 38, "line": "A formal warning, seriously?", "order": 19802, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 12, "scene": 38, "line": "Well, as you know, Dwight has filed a lot of complaints against you over the years.", "order": 19803, "speaker": "Toby Flenderson"},
  {"season": 4, "episode": 12, "scene": 38, "line": "And no one cared.", "order": 19804, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 12, "scene": 38, "line": "But now it's being looked at as more of a productivity issue.", "order": 19805, "speaker": "Toby Flenderson"},
  {"season": 4, "episode": 12, "scene": 38, "line": "Productivity? My numbers are good.", "order": 19806, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 12, "scene": 38, "line": "I know, um, but Ryan thinks that, uh, they're not good enough.", "order": 19807, "speaker": "Toby Flenderson"},
  {"season": 4, "episode": 12, "scene": 39, "line": "Wallace likes Jim. So going after him - that's a risky move for me, but in business, you gotta take risks. Sometimes you gotta get out on the open highway, with the top down [pushes button about eight times to get the top down. Finally it comes down and it's all bunched up. As he's fixing it, the car rolls backwards a bit.]", "order": 19808, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 13, "scene": 1, "line": "Hey. Ready? Come on, show me excited!", "order": 19809, "speaker": "Michael Scott"},
  {"season": 4, "episode": 13, "scene": 1, "line": "Yes. [small fist-pumps from both Pam and Oscar.]", "order": 19810, "speaker": "Oscar Martinez"},
  {"season": 4, "episode": 13, "scene": 1, "line": "Yeah, I'm pretty excited too.", "order": 19811, "speaker": "Michael Scott"},
  {"season": 4, "episode": 13, "scene": 2, "line": "Today I'm heading over to the job fair at Valley View High School, to find some new interns. Want to get some fresh blood. Um, euthanize this place.", "order": 19812, "speaker": "Michael Scott"},
  {"season": 4, "episode": 13, "scene": 3, "line": "Hey, are we doing this thing?", "order": 19813, "speaker": "Darryl Philbin"},
  {"season": 4, "episode": 13, "scene": 3, "line": "Yup.", "order": 19814, "speaker": "Michael Scott"},
  {"season": 4, "episode": 13, "scene": 3, "line": "Oh my God, Darryl, you look like Barack Obama. Everybody I'm dating Barack Obama!", "order": 19815, "speaker": "Kelly Kapoor"},
  {"season": 4, "episode": 13, "scene": 3, "line": "Why are you dressed like that?", "order": 19816, "speaker": "Michael Scott"},
  {"season": 4, "episode": 13, "scene": 3, "line": "[in a dress shirt and tie] Like what?", "order": 19817, "speaker": "Darryl Philbin"},
  {"season": 4, "episode": 13, "scene": 3, "line": "Like you're applying for a loan.", "order": 19818, "speaker": "Michael Scott"},
  {"season": 4, "episode": 13, "scene": 3, "line": "Maybe he's going to church. Or court.", "order": 19819, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 13, "scene": 3, "line": "Figured I look presentable. [looks Michael over] You? [Michael dressed casually in jeans and sneakers] Went a different way.", "order": 19820, "speaker": "Darryl Philbin"},
  {"season": 4, "episode": 13, "scene": 3, "line": "[to Darryl] I think you look nice.", "order": 19821, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 13, "scene": 3, "line": "[simultaneously] Thank you.", "order": 19822, "speaker": "Michael Scott & Darryl Philbin"},
  {"season": 4, "episode": 13, "scene": 3, "line": "Okay, here's what we're going to do: I'm going to instruct the kids about management and sales. Oscar will be in charge of accounting. Pam will be eye candy. No... uh, also, because that is your alma mater. Darryl will hire some kids for the warehouse. We don't have to worry about internships with them, because they definitely ain't going to college.", "order": 19823, "speaker": "Michael Scott"},
  {"season": 4, "episode": 13, "scene": 3, "line": "What college did you go to Mike?", "order": 19824, "speaker": "Darryl Philbin"},
  {"season": 4, "episode": 13, "scene": 3, "line": "Let's go!", "order": 19825, "speaker": "Michael Scott"},
  {"season": 4, "episode": 13, "scene": 4, "line": "Today, I am meeting a potential client on the golf course, because Ryan put me on probation. You remember Ryan, he was the temp here. Yeah. And uh, it is not a good time for me to lose my job. Since I have some pretty big long term plans in my personal life with Pam, that I'd like her parents to be psyched about. So I am about to do something very bold at this job that I've never done before. Try.", "order": 19826, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 13, "scene": 5, "line": "[takes golf swing] Whoa!", "order": 19827, "speaker": "Phil Maguire"},
  {"season": 4, "episode": 13, "scene": 5, "line": "Mr. Maguire, it's been a couple of years.", "order": 19828, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 13, "scene": 5, "line": "Hey Jim, how are you, nice to see you man.", "order": 19829, "speaker": "Phil Maguire"},
  {"season": 4, "episode": 13, "scene": 5, "line": "Oh no! No, no-no-no-no! My grandfather would be spinning in his urn, if he knew that I was out here with a Dartmouth boy. You take that shirt off right now or I will take it off for you, sir. I am... totally and completely kidding! [they both laugh] Andrew Bernard, Cornell '95.", "order": 19830, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 13, "scene": 5, "line": "Phil Maguire, Dartmouth, '74 [goes to shake Andy's hand]", "order": 19831, "speaker": "Phil Maguire"},
  {"season": 4, "episode": 13, "scene": 5, "line": "Oop, got some blisties.", "order": 19832, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 13, "scene": 5, "line": "Yeah, you do.", "order": 19833, "speaker": "Phil Maguire"},
  {"season": 4, "episode": 13, "scene": 6, "line": "Hit about 1200 balls last night, in preparation for today, so hands are a little tender. [shows blisters on both hands] It's actually not funny at all. It's incredibly painful.", "order": 19834, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 13, "scene": 7, "line": "Let's make it interesting, say, uh, ten bucks a hole?", "order": 19835, "speaker": "Phil"},
  {"season": 4, "episode": 13, "scene": 7, "line": "Great!", "order": 19836, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 13, "scene": 7, "line": "What are we talking? Skins? Acey Duecy? Bingo bango bongo? Sandies, Barkies? Arnies, Wolf? What?", "order": 19837, "speaker": "Kevin Malone"},
  {"season": 4, "episode": 13, "scene": 8, "line": "I'm gonna take this petty cash I got from Oscar, and turn it into next month's rent.", "order": 19838, "speaker": "Kevin Malone"},
  {"season": 4, "episode": 13, "scene": 9, "line": "So many memories in this old gym. Pretending I have PMS so I didn't have to play volleyball, pretending I have PMS so I didn't have to play basketball. Those were the days!", "order": 19839, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 13, "scene": 10, "line": "[Darryl approaches as students throw basketballs] A little over the top, don't you think?", "order": 19840, "speaker": "Michael Scott"},
  {"season": 4, "episode": 13, "scene": 10, "line": "Show them what you brought, Mike.", "order": 19841, "speaker": "Darryl Philbin"},
  {"season": 4, "episode": 13, "scene": 10, "line": "[holds up single sheet of paper] Um, that's all we brought.", "order": 19842, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 13, "scene": 10, "line": "This is all we need.", "order": 19843, "speaker": "Michael Scott"},
  {"season": 4, "episode": 13, "scene": 10, "line": "We'll see.", "order": 19844, "speaker": "Oscar Martinez"},
  {"season": 4, "episode": 13, "scene": 10, "line": "Yes we will see Oscar, we will see, because a blank sheet of paper equals endless possibilities! Conceptual! All right! We. Are. Open for business! [all four of them sit in an empty booth with small Dunder Mifflin sign] Hello!", "order": 19845, "speaker": "Michael Scott"},
  {"season": 4, "episode": 13, "scene": 11, "line": "Andy, you're up, let's go!", "order": 19846, "speaker": "Kevin Malone"},
  {"season": 4, "episode": 13, "scene": 11, "line": "[gushing lotion inside his golf gloves] Giddy up, let's do this.", "order": 19847, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 13, "scene": 12, "line": "People assume I'm great at golf. But like everybody, I hated golf lessons when I was a kid. So, I used to hang out at the sailing club instead. Got my 'knot' on.", "order": 19848, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 13, "scene": 13, "line": "[swings] Dammit!", "order": 19849, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 13, "scene": 14, "line": "Guys, do we have to stay all day?", "order": 19850, "speaker": "Meredith Palmer"},
  {"season": 4, "episode": 13, "scene": 14, "line": "I mean, Michael's gone, can't we just go?", "order": 19851, "speaker": "Phyllis Vance"},
  {"season": 4, "episode": 13, "scene": 14, "line": "Yeah, and I finished my work months ago!", "order": 19852, "speaker": "Creed Bratton"},
  {"season": 4, "episode": 13, "scene": 14, "line": "Excuse me, people. It has come to my attention, some of you have forgotten, who is in charge here. When Michael is gone... Jim is in charge. When Jim is gone, Andy and I are in charge. When Andy is gone you answer to me. Okay? [Stanley gets up to go] Excuse me where do you think you're going? Oh no, no, no. You're not leaving. No! Stanley, do not walk out that door! If you walk out that door, so help me, I will - [Stanley leaves] He left. Last time I checked, the American workday ends at five pm. You will all stay at your desks until that time. Or, you will suffer the consequences.", "order": 19853, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 13, "scene": 14, "line": "What consequences?", "order": 19854, "speaker": "Phyllis Vance"},
  {"season": 4, "episode": 13, "scene": 14, "line": "I will tell on you.", "order": 19855, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 13, "scene": 15, "line": "There's some filing, restocking the supply shelves. Replacing the water jug, which nobody likes to do. Um, we... uh, eat a lot of cake!", "order": 19856, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 13, "scene": 15, "line": "Cool.", "order": 19857, "speaker": "Justin"},
  {"season": 4, "episode": 13, "scene": 15, "line": "[laughs] Yeah, and uh, you basically learn how an office runs.", "order": 19858, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 13, "scene": 15, "line": "Hello. Hi. [to Pam] Can I talk to you for a sec? Excuse me.", "order": 19859, "speaker": "Michael Scott"},
  {"season": 4, "episode": 13, "scene": 15, "line": "This is Michael, my boss. [she points] Justin.", "order": 19860, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 13, "scene": 15, "line": "Hey, uh, remember what we talked about, in the car on the way up, 'only the best and the brightest.'", "order": 19861, "speaker": "Michael Scott"},
  {"season": 4, "episode": 13, "scene": 15, "line": "He's nice and he seems interested.", "order": 19862, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 13, "scene": 15, "line": "He's totally wrong, Pam. [to Justin] Hey.", "order": 19863, "speaker": "Michael Scott"},
  {"season": 4, "episode": 13, "scene": 15, "line": "Hi.", "order": 19864, "speaker": "Justin"},
  {"season": 4, "episode": 13, "scene": 15, "line": "How you doing? Listen, I don't think that a handsome, funny, smart, funny-looking kid like you, should limit himself. You could do whatever you want to do. You could be a classy janitor, or a cashier with dignity, or a... migraine worker. [takes back the info flyer Justin holds] Maybe for you, paper should be more of a hobby.", "order": 19865, "speaker": "Michael Scott"},
  {"season": 4, "episode": 13, "scene": 15, "line": "Sorry for wasting your time.", "order": 19866, "speaker": "Justin"},
  {"season": 4, "episode": 13, "scene": 15, "line": "Oh, no problem. [looks down] And he signed! He put his name on the piece of - okay, that was supposed to be a blank canvas on which to put their hopes and dreams. And he just, made it into a stupid piece of paper. We need another one, immediately.", "order": 19867, "speaker": "Michael Scott"},
  {"season": 4, "episode": 13, "scene": 15, "line": "Yeah, the booth is lame without it.", "order": 19868, "speaker": "Darryl Philbin"},
  {"season": 4, "episode": 13, "scene": 15, "line": "I only brought the one.", "order": 19869, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 13, "scene": 15, "line": "Are you mental?", "order": 19870, "speaker": "Michael Scott"},
  {"season": 4, "episode": 13, "scene": 15, "line": "Michael, do you remember, you specifically told me to only bring one sheet of paper? You said it only takes one sheet to make a difference. And I said, 'Are you sure Michael?' And you said 'Pam! Pam! Pam!' And then you sneezed in my tea, and then you said, 'Don't worry, it's just allergies.' Do you remember that?", "order": 19871, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 13, "scene": 15, "line": "I... don't.", "order": 19872, "speaker": "Michael Scott"},
  {"season": 4, "episode": 13, "scene": 15, "line": "Okay. I'll go look for another one. [she leaves]", "order": 19873, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 13, "scene": 15, "line": "And that. Is why. I need a smart intern.", "order": 19874, "speaker": "Michael Scott"},
  {"season": 4, "episode": 13, "scene": 16, "line": "Hey, so how's the direct mail business going?", "order": 19875, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 13, "scene": 16, "line": "I can't complain, people love their junk mail.", "order": 19876, "speaker": "Phil"},
  {"season": 4, "episode": 13, "scene": 16, "line": "Now are you getting all your paper from PPC? Or-", "order": 19877, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 13, "scene": 16, "line": "You know what Jim? I'm not really looking to change things up right now, I just came out here to get out of the office for a little bit, so why don't we just play, okay?", "order": 19878, "speaker": "Phil"},
  {"season": 4, "episode": 13, "scene": 16, "line": "Absolutely, will do.", "order": 19879, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 13, "scene": 17, "line": "So I guess I'll just... work on my short game.", "order": 19880, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 13, "scene": 18, "line": "[teen approaches table] Hello there.", "order": 19881, "speaker": "Michael Scott"},
  {"season": 4, "episode": 13, "scene": 18, "line": "What is this company?", "order": 19882, "speaker": "Kid"},
  {"season": 4, "episode": 13, "scene": 18, "line": "Well it's funny that you should ask, because it's really more than a company...", "order": 19883, "speaker": "Michael Scott"},
  {"season": 4, "episode": 13, "scene": 18, "line": "Dunder Mifflin paper.", "order": 19884, "speaker": "Oscar Martinez"},
  {"season": 4, "episode": 13, "scene": 18, "line": "Thanks. [walks away]", "order": 19885, "speaker": "Kid"},
  {"season": 4, "episode": 13, "scene": 18, "line": "Dammit, Oscar!", "order": 19886, "speaker": "Michael Scott"},
  {"season": 4, "episode": 13, "scene": 19, "line": "Yeah, I'm trying to lure these kids into my booth, but uh, kids are very wary about being 'lured' these days. Thank you Dateline!", "order": 19887, "speaker": "Michael Scott"},
  {"season": 4, "episode": 13, "scene": 20, "line": "Thank you. [hangs up phone, leaves for restroom]", "order": 19888, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 13, "scene": 20, "line": "Extension 128.", "order": 19889, "speaker": "Angela Martin"},
  {"season": 4, "episode": 13, "scene": 20, "line": "[over phone] Hiya Pumpkin, it's Creed.", "order": 19890, "speaker": "Creed Bratton"},
  {"season": 4, "episode": 13, "scene": 20, "line": "Say, we're gonna ditch this bitch. You in?", "order": 19891, "speaker": "Creed Bratton"},
  {"season": 4, "episode": 13, "scene": 20, "line": "No.", "order": 19892, "speaker": "Angela Martin"},
  {"season": 4, "episode": 13, "scene": 20, "line": "Are you out? [Angela hangs up] Pumpkin's out, let's go gang! [they all make a run for the door, Dwight re-enters room with only Angela left]", "order": 19893, "speaker": "Creed Bratton"},
  {"season": 4, "episode": 13, "scene": 21, "line": "My old art room. [finds a sheet of blank paper] Oh, maybe it's still here! [looks for her painting] No. No they must have taken it down. Never mind.", "order": 19894, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 13, "scene": 22, "line": "[answers cell] What do you want?", "order": 19895, "speaker": "Michael Scott"},
  {"season": 4, "episode": 13, "scene": 22, "line": "Michael, I know you're swamped. I just thought you should know that everyone in the office has left, except for Angela and I. Do not worry though, I have taken down their names, and I have docked them a personal day.", "order": 19896, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 13, "scene": 22, "line": "Who cares? I'm not there, Jim's not there, why should they have to be there?", "order": 19897, "speaker": "Michael Scott"},
  {"season": 4, "episode": 13, "scene": 22, "line": "So... what else is up? [Michael hangs up] Mich-", "order": 19898, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 13, "scene": 23, "line": "Thank you! [Pam hands him blank paper] What, what is this?", "order": 19899, "speaker": "Michael Scott"},
  {"season": 4, "episode": 13, "scene": 23, "line": "A piece of paper.", "order": 19900, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 13, "scene": 23, "line": "This isn't Dunder Mifflin paper. Some sort of Pendleton crap.", "order": 19901, "speaker": "Michael Scott"},
  {"season": 4, "episode": 13, "scene": 23, "line": "Well I think they'll get the spirit of it.", "order": 19902, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 13, "scene": 23, "line": "Pam-Pam-Pam-Pam-PamPamPamPam! We're dying here. I want you to go back to the office and I want you to get the real stuff. I want you to get ultra white card stock.", "order": 19903, "speaker": "Michael Scott"},
  {"season": 4, "episode": 13, "scene": 23, "line": "Are you serious?", "order": 19904, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 13, "scene": 23, "line": "Yes. And don't call me Shirley.", "order": 19905, "speaker": "Michael Scott"},
  {"season": 4, "episode": 13, "scene": 23, "line": "Okay.", "order": 19906, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 13, "scene": 24, "line": "Oh! [Phil is stuck in the sand trap] Ah, dammit!", "order": 19907, "speaker": "Phil"},
  {"season": 4, "episode": 13, "scene": 24, "line": "You know, you can just pick it up, take the triple bogey.", "order": 19908, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 13, "scene": 24, "line": "Yeah I'll- I'll get it out. Thank you.", "order": 19909, "speaker": "Phil"},
  {"season": 4, "episode": 13, "scene": 24, "line": "Yep. [Maguire keeps swinging]", "order": 19910, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 13, "scene": 25, "line": "[silently working] [Pam walks back into office, gets paper out of the copier, shows it 'voila' to camera, leaves]", "order": 19911, "speaker": "Angela Martin & Dwight Schrute"},
  {"season": 4, "episode": 13, "scene": 26, "line": "[Jim's cellphone rings] AH! Come on, Tuna!", "order": 19912, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 13, "scene": 26, "line": "Hey, can't really talk now, what's up?", "order": 19913, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 13, "scene": 26, "line": "Just checking in, how's it going?", "order": 19914, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 13, "scene": 26, "line": "Uh, you know, we'll see, we'll see.", "order": 19915, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 13, "scene": 26, "line": "I just drove twenty miles round-trip back to the office to get Michael a single sheet of white paper. So, I could have just had them fax it to me, I guess.", "order": 19916, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 13, "scene": 26, "line": "Oh, I like you.", "order": 19917, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 13, "scene": 26, "line": "Talk to you later?", "order": 19918, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 13, "scene": 26, "line": "Yeah. All right. Bye. [Kevin sinks putt] All right. Sorry, I got to annoy you one more time. What if I bring down shipping costs?", "order": 19919, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 13, "scene": 26, "line": "You can try. But I've looked at your prices. Even with free shipping? Doesn't work. It's just- it's not in the stars, Jim. [Phil sinks putt] Six.", "order": 19920, "speaker": "Phil"},
  {"season": 4, "episode": 13, "scene": 26, "line": "Yeah.", "order": 19921, "speaker": "Kevin Malone"},
  {"season": 4, "episode": 13, "scene": 26, "line": "All right, race to the next hole! [he runs off to the cart]", "order": 19922, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 13, "scene": 26, "line": "Ooh! Winner gets ten bucks! Wait -wait for me! Andy!", "order": 19923, "speaker": "Kevin Malone"},
  {"season": 4, "episode": 13, "scene": 26, "line": "Shortcut! Shortcut! [drives cart off course, it flips over] Woah! I fell in the sandtrap!", "order": 19924, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 13, "scene": 27, "line": "[hums at copier, Angela waiting behind him]", "order": 19925, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 13, "scene": 28, "line": "All of these jobs? Suck. I would rather live jobless, on a beach somewhere, off the money from a large inheritance, than to have to work in any one of these crap-holes. They suck.", "order": 19926, "speaker": "Michael Scott"},
  {"season": 4, "episode": 13, "scene": 29, "line": "Hey! [walks up to Oscar and Darryl tossing basketballs, grabs Oscar's ball] Game over. [kicks the ball up to the gymnasium ceiling]", "order": 19927, "speaker": "Michael Scott"},
  {"season": 4, "episode": 13, "scene": 30, "line": "[singsong] Just-in time.", "order": 19928, "speaker": "Michael Scott"},
  {"season": 4, "episode": 13, "scene": 30, "line": "Hi.", "order": 19929, "speaker": "Justin"},
  {"season": 4, "episode": 13, "scene": 30, "line": "Just-in case. What's your last name?", "order": 19930, "speaker": "Michael Scott"},
  {"season": 4, "episode": 13, "scene": 30, "line": "Polznik.", "order": 19931, "speaker": "Justin"},
  {"season": 4, "episode": 13, "scene": 30, "line": "This... just in. Justin Polznik! Huh?", "order": 19932, "speaker": "Michael Scott"},
  {"season": 4, "episode": 13, "scene": 31, "line": "Justin is the ugly girl in the movie, who takes off her glasses and she's hot! And you realize she was always hot, she was just wearing glasses. And that you were the blind one. He's the most... important thing in my life right now.", "order": 19933, "speaker": "Michael Scott"},
  {"season": 4, "episode": 13, "scene": 32, "line": "I want you to meet my family. Come on. Oscar Martinez. Accountant extraordinaire. This is Justin.", "order": 19934, "speaker": "Michael Scott"},
  {"season": 4, "episode": 13, "scene": 32, "line": "Hey.", "order": 19935, "speaker": "Oscar Martinez"},
  {"season": 4, "episode": 13, "scene": 32, "line": "Hi.", "order": 19936, "speaker": "Justin"},
  {"season": 4, "episode": 13, "scene": 32, "line": "This is Darryl Philbin. Isn't he big? And you already met her, Pam Beesly, office hottie. She will 'do' you. [laughs] No, no. But she has already dated two guys in the office, that we know of, so, this could be number three, you never know?", "order": 19937, "speaker": "Michael Scott"},
  {"season": 4, "episode": 13, "scene": 32, "line": "Excuse me.", "order": 19938, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 13, "scene": 32, "line": "Come here. I would never say this to her face, but she is a wonderful person and a gifted artist.", "order": 19939, "speaker": "Michael Scott"},
  {"season": 4, "episode": 13, "scene": 32, "line": "Why, why wouldn't you say that to her face?", "order": 19940, "speaker": "Oscar Martinez"},
  {"season": 4, "episode": 13, "scene": 32, "line": "So, what do you think? Think these guys are nice? The guys I didn't bring are even better. Justin, I'm willing to commit right now. Would you do me the honor, of spending the summer with us, at Dunder Mifflin? I think, I think you are very special.", "order": 19941, "speaker": "Michael Scott"},
  {"season": 4, "episode": 13, "scene": 32, "line": "You didn't want me before. That's what you said.", "order": 19942, "speaker": "Justin"},
  {"season": 4, "episode": 13, "scene": 32, "line": "No, I didn't. You misconstrued me.", "order": 19943, "speaker": "Michael Scott"},
  {"season": 4, "episode": 13, "scene": 32, "line": "You were kind of a jerk to me.", "order": 19944, "speaker": "Justin"},
  {"season": 4, "episode": 13, "scene": 32, "line": "I, uh, hmm.", "order": 19945, "speaker": "Michael Scott"},
  {"season": 4, "episode": 13, "scene": 32, "line": "And I'm, I'm gonna go now.", "order": 19946, "speaker": "Justin"},
  {"season": 4, "episode": 13, "scene": 32, "line": "Why don't... hey.", "order": 19947, "speaker": "Michael Scott"},
  {"season": 4, "episode": 13, "scene": 33, "line": "[trying to pick up a beer bottle with blistered hands, struggles trying to take a drink as everyone watches.]", "order": 19948, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 13, "scene": 34, "line": "[tries to stop a sneeze] Ah-choo!", "order": 19949, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 13, "scene": 34, "line": "Bless you.", "order": 19950, "speaker": "Angela Martin"},
  {"season": 4, "episode": 13, "scene": 34, "line": "Thank you. [Angela turns to look at him.]", "order": 19951, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 13, "scene": 35, "line": "Okay, Jim, you owe me one-twenty, and Phil, you owe me two-thirty.", "order": 19952, "speaker": "Kevin Malone"},
  {"season": 4, "episode": 13, "scene": 35, "line": "Let's open a tab, because you and me are gonna be playing more often.", "order": 19953, "speaker": "Phil"},
  {"season": 4, "episode": 13, "scene": 35, "line": "Count me in!", "order": 19954, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 13, "scene": 35, "line": "No.", "order": 19955, "speaker": "Phil"},
  {"season": 4, "episode": 13, "scene": 35, "line": "All right!", "order": 19956, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 13, "scene": 35, "line": "Okay. You had some fun, and uh, I think I paid for it. So let me get my last shot in there. Is there anything you can do for me?", "order": 19957, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 13, "scene": 35, "line": "I'll tell you what. My fiscal year ends in two months, let's talk then. Sound good?", "order": 19958, "speaker": "Phil"},
  {"season": 4, "episode": 13, "scene": 35, "line": "Absolutely.", "order": 19959, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 13, "scene": 35, "line": "Good playing with you guys.", "order": 19960, "speaker": "Maguire"},
  {"season": 4, "episode": 13, "scene": 35, "line": "Right on.", "order": 19961, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 13, "scene": 36, "line": "You know it's a tough thing, seeing a grown man take six shots to get out of a sand trap but, I had to give Phil credit for not quitting. Which is what I told him. I also told him I don't quit either, and I'd call him every single day until he gave me his business. Which he then did, after ...fifteen minutes of me blocking his car. So I am now his paper supplier! And I shot a 102 today, so I'm feeling pretty good.", "order": 19962, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 13, "scene": 37, "line": "The job fair will be closing in five minutes. Eighth period will commence as scheduled.", "order": 19963, "speaker": "Woman on mic"},
  {"season": 4, "episode": 13, "scene": 37, "line": "Hold down the fort, I have to do something. [walks up to microphone] Excuse me. If you can hear me, I'd like you to look around at all these companies and know that none of them are good enough for you. H&R Block? Come on, I mean I don't even know what they do. Frank Regan Funeral Home? Too much formaldehyde! The Air Force? [Airforce recruiter glares at Michael] Air Force is cool. The refreshments? Bush league! You know, that's not what you want. Dunder- they, well, okay I see security is coming so I just want to say, come to Dunder Mifflin. Dunder Mifflin, a great place to work! Anybody? Show of hands, anybody want to intern at Dunder Mifflin? We do not offer college credit, we cannot give you any sort of pay, but it is a really fun work environment. Anybody? Show of hands? Damn... it. Okay, I'm gonna wrap it up here. Thank you for your time, and drive safe.", "order": 19964, "speaker": "Michael Scott"},
  {"season": 4, "episode": 13, "scene": 38, "line": "[Dwight and Angela exiting, he opens door for her] Thank you.", "order": 19965, "speaker": "Angela Martin"},
  {"season": 4, "episode": 13, "scene": 38, "line": "You're welcome.", "order": 19966, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 13, "scene": 39, "line": "[clink of beer bottles] Beers? You closed it?", "order": 19967, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 13, "scene": 39, "line": "I closed it!", "order": 19968, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 13, "scene": 39, "line": "Yes! Oh! [runs into his arms, kisses him] Um, congratulations to you, sir. [they shake hands business-like]", "order": 19969, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 13, "scene": 39, "line": "Thank you. Oh, thank you very much. Appreciate it.", "order": 19970, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 13, "scene": 39, "line": "Well done.", "order": 19971, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 13, "scene": 39, "line": "Thank you. You know what, screw this! [pulls Pam into a big smooch]", "order": 19972, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 13, "scene": 39, "line": "Oooh! [Kevin and Andy add suggestive 'bow-chicka-wow-wow' vocals]", "order": 19973, "speaker": "Kevin Malone"},
  {"season": 4, "episode": 13, "scene": 40, "line": "Today I did something stupid. I questioned myself. And I will never do that again. Because I look at somebody like Jim Halpert and I think, that guy can do anything he wants to do. He could do anything, and he chooses to work here, selling paper. Just like me.", "order": 19974, "speaker": "Michael Scott"},
  {"season": 4, "episode": 13, "scene": 41, "line": "[standing way too close to Jim and Pam] Yeah, kiss her. Kiss her good. [Jim and Pam stop kissing]", "order": 19975, "speaker": "Michael Scott"},
  {"season": 4, "episode": 13, "scene": 42, "line": "Hi.", "order": 19976, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 13, "scene": 42, "line": "Hello.", "order": 19977, "speaker": "Graphics guy"},
  {"season": 4, "episode": 13, "scene": 42, "line": "I was just wondering, what kind of jobs do you guys have?", "order": 19978, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 13, "scene": 42, "line": "Are you interested in graphic design?", "order": 19979, "speaker": "Graphics guy"},
  {"season": 4, "episode": 13, "scene": 42, "line": "Yeah. Can I fill out an application?", "order": 19980, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 13, "scene": 42, "line": "Oh absolutely, here, take a seat. I should let you know right away, this is just an entry-level job. It's really basic. We're looking for like, a self starter, someone who can meet deadlines, who just pretty much just go the extra mile, I guess.", "order": 19981, "speaker": "Graphics guy"},
  {"season": 4, "episode": 13, "scene": 42, "line": "I can do that.", "order": 19982, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 13, "scene": 42, "line": "Great. And uh, obviously looking for someone who knows Photoshop, and Dreamweaver. Uh, Corel Painter, Illustrator, AfterEffects, all the basics.", "order": 19983, "speaker": "Graphics guy"},
  {"season": 4, "episode": 13, "scene": 42, "line": "I don't know any of those.", "order": 19984, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 13, "scene": 42, "line": "[laughs] It's actually not super-complicated. I mean I'm sure there's some sort of like, adult education classes in the area. But if you're really serious about graphic design, one thing about New York or Philadelphia, they've got amazing programs out there for design.", "order": 19985, "speaker": "Graphics guy"},
  {"season": 4, "episode": 13, "scene": 42, "line": "New York or Philadelphia.", "order": 19986, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 13, "scene": 42, "line": "Yeah.", "order": 19987, "speaker": "Graphics guy"},
  {"season": 4, "episode": 13, "scene": 42, "line": "All right. Cool, well... thanks.", "order": 19988, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 13, "scene": 42, "line": "Sure.", "order": 19989, "speaker": "Graphics guy"},
  {"season": 4, "episode": 13, "scene": 42, "line": "And uh, I'll look into those.", "order": 19990, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 13, "scene": 42, "line": "You should.", "order": 19991, "speaker": "Graphics guy"},
  {"season": 4, "episode": 13, "scene": 42, "line": "New York or Philadelphia.", "order": 19992, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 13, "scene": 42, "line": "Absolutely.", "order": 19993, "speaker": "Graphics guy"},
  {"season": 4, "episode": 13, "scene": 42, "line": "Okay.", "order": 19994, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 13, "scene": 42, "line": "It's where the action is.", "order": 19995, "speaker": "Graphics guy"},
  {"season": 4, "episode": 13, "scene": 42, "line": "Thanks.", "order": 19996, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 13, "scene": 43, "line": "Well, kids today have a very short attention span. They are into texting and video games and sex, and they just, they get distracted easily. And today they're being distracted by the other booths.", "order": 19997, "speaker": "Michael Scott"},
  {"season": 4, "episode": 13, "scene": 44, "line": "[clears throat] Hey, there! Michael Scott, regional manager, Dunder Mifflin.", "order": 19998, "speaker": "Michael Scott"},
  {"season": 4, "episode": 13, "scene": 44, "line": "Brad Bailey, nice to meet you.", "order": 19999, "speaker": "Brad"},
  {"season": 4, "episode": 13, "scene": 44, "line": "That is a very firm handshake. Ow, ow, aahch! Killing my hand, there.", "order": 20000, "speaker": "Michael Scott"},
  {"season": 4, "episode": 13, "scene": 44, "line": "Yeah.", "order": 20001, "speaker": "Brad"},
  {"season": 4, "episode": 13, "scene": 44, "line": "So, what is your poison? You like uh, sales, accounting, customer service, what do you like...", "order": 20002, "speaker": "Michael Scott"},
  {"season": 4, "episode": 13, "scene": 44, "line": "Sales, uh, sales would be cool.", "order": 20003, "speaker": "Brad"},
  {"season": 4, "episode": 13, "scene": 44, "line": "Sales is and could be very, very cool. If you like paper, and you love to laugh, this is the place for you.", "order": 20004, "speaker": "Michael Scott"},
  {"season": 4, "episode": 13, "scene": 44, "line": "Well, that's great.", "order": 20005, "speaker": "Brad"},
  {"season": 4, "episode": 13, "scene": 44, "line": "Right here. We have a lot of fun.", "order": 20006, "speaker": "Michael Scott"},
  {"season": 4, "episode": 13, "scene": 44, "line": "Mmhmm.", "order": 20007, "speaker": "Brad"},
  {"season": 4, "episode": 13, "scene": 44, "line": "[Pam returns] This is one of our staff. This is Pam Beesly, [takes piece of paper from Pam] receptionist extraordinaire.", "order": 20008, "speaker": "Michael Scott"},
  {"season": 4, "episode": 13, "scene": 44, "line": "Hey, Pam, what's up?", "order": 20009, "speaker": "Brad"},
  {"season": 4, "episode": 13, "scene": 44, "line": "Check that out, huh?", "order": 20010, "speaker": "Michael Scott"},
  {"season": 4, "episode": 13, "scene": 44, "line": "Hi, nice to meet you.", "order": 20011, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 13, "scene": 44, "line": "Pretty nice.", "order": 20012, "speaker": "Michael Scott"},
  {"season": 4, "episode": 13, "scene": 44, "line": "Yeah.", "order": 20013, "speaker": "Brad"},
  {"season": 4, "episode": 13, "scene": 44, "line": "She sits there all day.", "order": 20014, "speaker": "Michael Scott"},
  {"season": 4, "episode": 13, "scene": 44, "line": "Nice.", "order": 20015, "speaker": "Brad"},
  {"season": 4, "episode": 13, "scene": 44, "line": "You get to look at her. Why don't you hold out your hand. [hands Brad paper] Tell me what you feel.", "order": 20016, "speaker": "Michael Scott"},
  {"season": 4, "episode": 13, "scene": 44, "line": "Paper.", "order": 20017, "speaker": "Brad"},
  {"season": 4, "episode": 13, "scene": 44, "line": "That's called card stock. That's what you're feeling. Now what you're feeling inside is confusion, butterflies, exhilaration... You're thinking, 'Am I good enough for this job? Do I have what it takes?' And I think we both know the answer. We'll find that out together. You can keep that.", "order": 20018, "speaker": "Michael Scott"},
  {"season": 4, "episode": 13, "scene": 44, "line": "Are you sure?", "order": 20019, "speaker": "Brad"},
  {"season": 4, "episode": 13, "scene": 44, "line": "Mmm, yeah.", "order": 20020, "speaker": "Michael Scott"},
  {"season": 4, "episode": 13, "scene": 44, "line": "Sweet. [camera zooms in on Pam]", "order": 20021, "speaker": "Brad"},
  {"season": 4, "episode": 13, "scene": 44, "line": "Don't fold it, though.", "order": 20022, "speaker": "Michael Scott"},
  {"season": 4, "episode": 13, "scene": 45, "line": "We have the Dundies, my birthday, those blow out, and it's sexy, it's sort of a sexy atmosphere, you know? The other day, in the parking lot, I actually found a condom. So. [to Pam] I've been meaning to ask you, do you know whose that was? [Pam shakes head no] So, sexy is fun...", "order": 20023, "speaker": "Michael Scott"},
  {"season": 4, "episode": 13, "scene": 45, "line": "Well, it was nice meeting you, I'm, I'm gonna take off.", "order": 20024, "speaker": "Brad"},
  {"season": 4, "episode": 13, "scene": 45, "line": "Hey, you know what? Coincidence - I am going to go for a little stroll myself. Are you psyched about the summer?", "order": 20025, "speaker": "Michael Scott"},
  {"season": 4, "episode": 13, "scene": 45, "line": "Oh, yeah.", "order": 20026, "speaker": "Brad"},
  {"season": 4, "episode": 13, "scene": 45, "line": "Yeah, me, too.", "order": 20027, "speaker": "Michael Scott"},
  {"season": 4, "episode": 13, "scene": 45, "line": "Okay, well, I'm gonna go to the bathroom.", "order": 20028, "speaker": "Brad"},
  {"season": 4, "episode": 13, "scene": 45, "line": "Okay, be my guest.", "order": 20029, "speaker": "Michael Scott"},
  {"season": 4, "episode": 13, "scene": 45, "line": "Thanks.", "order": 20030, "speaker": "Brad"},
  {"season": 4, "episode": 13, "scene": 45, "line": "And do your worst.", "order": 20031, "speaker": "Michael Scott"},
  {"season": 4, "episode": 13, "scene": 46, "line": "[to Brad coming out of the bathroom] Hey.", "order": 20032, "speaker": "Michael Scott"},
  {"season": 4, "episode": 13, "scene": 46, "line": "Whoa, come on!", "order": 20033, "speaker": "Brad"},
  {"season": 4, "episode": 13, "scene": 46, "line": "So, I forgot to mention that my old temp is now my boss, so, gives you an idea of how quickly you can move up in this...", "order": 20034, "speaker": "Michael Scott"},
  {"season": 4, "episode": 13, "scene": 46, "line": "Yeah, I get it, okay, I'm not interested. Stop following me around; you're really starting to creep me out.", "order": 20035, "speaker": "Brad"},
  {"season": 4, "episode": 13, "scene": 46, "line": "One other thing I wanted to...", "order": 20036, "speaker": "Michael Scott"},
  {"season": 4, "episode": 13, "scene": 46, "line": "Nnn, look. I was just being polite. I don't want to waste my life selling paper for your stupid company, okay? [walks away]", "order": 20037, "speaker": "Brad"},
  {"season": 4, "episode": 13, "scene": 46, "line": "[laughs] Ohh...Nah.", "order": 20038, "speaker": "Michael Scott"},
  {"season": 4, "episode": 13, "scene": 47, "line": "I'm gonna take so much money off that guy. That guy is a whale. I'm gonna clean him out. This is really gonna get me out of a hole.", "order": 20039, "speaker": "Kevin Malone"},
  {"season": 4, "episode": 13, "scene": 48, "line": "He's not my client.", "order": 20040, "speaker": "Kevin Malone"},
  {"season": 4, "episode": 13, "scene": 49, "line": "If your client likes Italian food, you bring him to Cugino's. And if your client likes a little action, you bring him a gambler. And if your client hates Cornell, you bring him Andy.", "order": 20041, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 13, "scene": 50, "line": "[tries to break club over knee] Ow! Ow! [tries again] Owooh!", "order": 20042, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 13, "scene": 50, "line": "Guy's kind of a douche.", "order": 20043, "speaker": "Phil Maguire"},
  {"season": 4, "episode": 13, "scene": 50, "line": "Yeah, well, you know...Cornell.", "order": 20044, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 13, "scene": 50, "line": "[chuckling] I guess.", "order": 20045, "speaker": "Phil"},
  {"season": 4, "episode": 13, "scene": 51, "line": "Hey, Justin!", "order": 20046, "speaker": "Michael Scott"},
  {"season": 4, "episode": 13, "scene": 51, "line": "I'm sorry, I'm sort of in the middle of this.", "order": 20047, "speaker": "Justin"},
  {"season": 4, "episode": 13, "scene": 51, "line": "Wait, you want this kid to be in the Air Force?", "order": 20048, "speaker": "Michael Scott"},
  {"season": 4, "episode": 13, "scene": 51, "line": "Absolutely. Justin is smart, capable, ambitious, and he aims high.", "order": 20049, "speaker": "Air Force recruiter"},
  {"season": 4, "episode": 13, "scene": 51, "line": "Time out. You think this kid could be a fighter pilot?", "order": 20050, "speaker": "Michael Scott"},
  {"season": 4, "episode": 13, "scene": 51, "line": "I think Justin can be anything he wants to be.", "order": 20051, "speaker": "Air Force recruiter"},
  {"season": 4, "episode": 13, "scene": 51, "line": "[chuckling] Okay.", "order": 20052, "speaker": "Justin"},
  {"season": 4, "episode": 13, "scene": 51, "line": "Okay.", "order": 20053, "speaker": "Michael Scott"},
  {"season": 4, "episode": 13, "scene": 52, "line": "Have you given any more thought to Dunder Mifflin?", "order": 20054, "speaker": "Michael Scott"},
  {"season": 4, "episode": 13, "scene": 52, "line": "Well, you took away the brochure, so I sort of...", "order": 20055, "speaker": "Justin"},
  {"season": 4, "episode": 13, "scene": 52, "line": "Well, yeah, but you don't need the brochure. The brochure is up here [indicates head] and it's in here. [indicates heart]", "order": 20056, "speaker": "Michael Scott"},
  {"season": 4, "episode": 13, "scene": 52, "line": "Oh.", "order": 20057, "speaker": "Justin"},
  {"season": 4, "episode": 13, "scene": 52, "line": "I think you are smart, and capable, and you aim high.", "order": 20058, "speaker": "Michael Scott"},
  {"season": 4, "episode": 13, "scene": 52, "line": "That's what the recruiter just said.", "order": 20059, "speaker": "Justin"},
  {"season": 4, "episode": 13, "scene": 52, "line": "I'm right.", "order": 20060, "speaker": "Michael Scott"},
  {"season": 4, "episode": 14, "scene": 1, "line": "[cell phone rings] Hello, this is Dwight Schrute. [phone keeps ringing] Hello?", "order": 20061, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 14, "scene": 1, "line": "[presses button on headset] Hello, this is Dwight.", "order": 20062, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 14, "scene": 1, "line": "Hello? Hello?", "order": 20063, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 14, "scene": 1, "line": "Yes, we do have that. Hold on one second...", "order": 20064, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 14, "scene": 1, "line": "Jim, what are you doing?", "order": 20065, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 14, "scene": 1, "line": "And how many would you like?", "order": 20066, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 14, "scene": 1, "line": "Uh, uh, hang that up right now. Ha-", "order": 20067, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 14, "scene": 1, "line": "Absolutely. We can get that out to you immediately.", "order": 20068, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 14, "scene": 1, "line": "[into Jim's headset] This man is an imposter! Do not do business with him! This is not Dwight Schrute!", "order": 20069, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 14, "scene": 2, "line": "Dwight left his cell phone on his desk. So, naturally, I paired it to my headset.", "order": 20070, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 14, "scene": 3, "line": "[cell phone rings] K, fine. I'll just let it go to voicemail.", "order": 20071, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 14, "scene": 3, "line": "[presses button on headset] Hello, this is Dwight.", "order": 20072, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 14, "scene": 3, "line": "Hey, is this Dwight?", "order": 20073, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 14, "scene": 3, "line": "Yes it is.", "order": 20074, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 14, "scene": 3, "line": "Oh my goodness, you sound sexy.", "order": 20075, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 14, "scene": 3, "line": "Oh, thank you. I've been working out.", "order": 20076, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 14, "scene": 3, "line": "Woah, woah, woah, Pam!", "order": 20077, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 14, "scene": 3, "line": "Have you?", "order": 20078, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 14, "scene": 3, "line": "Pam! You are not talking to Dwight right now. You are talking to Jim.", "order": 20079, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 14, "scene": 3, "line": "Dwight?", "order": 20080, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 14, "scene": 3, "line": "No! [waves arms] Pam, I'm over here!", "order": 20081, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 14, "scene": 3, "line": "I'm confused...", "order": 20082, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 14, "scene": 3, "line": "Disconnect that right now. You give me your earpiece.", "order": 20083, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 14, "scene": 3, "line": "Can't do that. Unsanitary.", "order": 20084, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 14, "scene": 3, "line": "Ugh! Okay, you know what? Fine. I will reprogram my phone to go to my office phone. [presses buttons on his cell phone] Done.", "order": 20085, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 14, "scene": 3, "line": "[office phone rings at Jim's desk] This is Dwight.", "order": 20086, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 14, "scene": 4, "line": "Oh, and I forwarded his desk phone to mine.", "order": 20087, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 14, "scene": 5, "line": "Oh, hello Mater. Good news: I've married. Tell Fater. [hangs up] Such a nice woman.", "order": 20088, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 14, "scene": 5, "line": "[slams cell phone on desk] Argh! Ahh!!", "order": 20089, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 14, "scene": 6, "line": "Today is Toby Flenderson's last day. I couldn't sleep last night. I came extra-early. So much energy... There are certain days you know you know you will remember for the rest of your life, and I just have a feeling that today is one of those days.", "order": 20090, "speaker": "Michael Scott"},
  {"season": 4, "episode": 14, "scene": 7, "line": "Morning.", "order": 20091, "speaker": "Toby Flenderson"},
  {"season": 4, "episode": 14, "scene": 7, "line": "Mornin'. [to camera] So here we go, just a matter of hours now, until his horribleness has left the building. I'm going to set my watch alarm... And... [watch beeps]", "order": 20092, "speaker": "Michael Scott"},
  {"season": 4, "episode": 14, "scene": 8, "line": "Good morning, Kelly.", "order": 20093, "speaker": "Toby Flenderson"},
  {"season": 4, "episode": 14, "scene": 8, "line": "I can't believe this is your last day. How do you feel?", "order": 20094, "speaker": "Kelly Kapoor"},
  {"season": 4, "episode": 14, "scene": 8, "line": "Fine. Good.", "order": 20095, "speaker": "Toby Flenderson"},
  {"season": 4, "episode": 14, "scene": 8, "line": "I feel weird.", "order": 20096, "speaker": "Kelly Kapoor"},
  {"season": 4, "episode": 14, "scene": 9, "line": "First thing on the agenda-actually, the only thing on the agenda, is the status of Toby's going away party.", "order": 20097, "speaker": "Michael Scott"},
  {"season": 4, "episode": 14, "scene": 9, "line": "We have a buttercream cake and a slideshow of Toby.", "order": 20098, "speaker": "Angela Martin"},
  {"season": 4, "episode": 14, "scene": 9, "line": "Okay.", "order": 20099, "speaker": "Michael Scott"},
  {"season": 4, "episode": 14, "scene": 9, "line": "But so far we only have two pictures of him.", "order": 20100, "speaker": "Angela Martin"},
  {"season": 4, "episode": 14, "scene": 9, "line": "Okay, well, this will not do. Toby is going away forever, and uh, we need to do something very very special. In some cultures, when somebody leaves, like New Orleans culture, they have a parade and they have a band and people party in the streets...", "order": 20101, "speaker": "Michael Scott"},
  {"season": 4, "episode": 14, "scene": 9, "line": "Do you mean, leaves as in dies? You want us to throw Toby a New Orleans' funeral?", "order": 20102, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 14, "scene": 9, "line": "If the Devil were to explode, and evil were gone forever, what sort of party would you have?", "order": 20103, "speaker": "Michael Scott"},
  {"season": 4, "episode": 14, "scene": 9, "line": "Michael...", "order": 20104, "speaker": "Angela Martin"},
  {"season": 4, "episode": 14, "scene": 9, "line": "Like a beach blowout? Or a toga, toga-", "order": 20105, "speaker": "Michael Scott"},
  {"season": 4, "episode": 14, "scene": 9, "line": "No. You know-no!", "order": 20106, "speaker": "Angela Martin"},
  {"season": 4, "episode": 14, "scene": 9, "line": "Toga!", "order": 20107, "speaker": "Michael Scott"},
  {"season": 4, "episode": 14, "scene": 9, "line": "You always do this! We have a nice, modest party planned, and then you come in and demand the world. Let me be clear. There is simply no money for anything other than a cake and to develop a few more slides... although Toby won't be in them.", "order": 20108, "speaker": "Angela Martin"},
  {"season": 4, "episode": 14, "scene": 9, "line": "I thought that you might say that. [takes off his shoe; hums; takes out cash from shoe]", "order": 20109, "speaker": "Michael Scott"},
  {"season": 4, "episode": 14, "scene": 10, "line": "Every year, my sweet, sweet grandmother sends me a check on my birthday for fifty dollars. And lately, she has been sending me, like nine or ten checks a year... uh, as Nana starts to... but, I knew I should be saving it for something, I just didn't know what I should be saving it for. And then I had an awakening. 'Michael, buy a motorcycle.' So I put the money in my shoe, and then I forgot about it until now.", "order": 20110, "speaker": "Michael Scott"},
  {"season": 4, "episode": 14, "scene": 11, "line": "I want a party that makes me feel like I am flying through the air without this... ugly weight holding me down, you know?... I want an antigravity machine. I saw it in a movie. You drink a potion, and then you just start floating all around.", "order": 20111, "speaker": "Michael Scott"},
  {"season": 4, "episode": 14, "scene": 11, "line": "So how much antigravity potion do you want?", "order": 20112, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 14, "scene": 11, "line": "No! No! You know what? This is not a party-planning committee anymore! And I don't want your foot money, and I feel uncomfortable participating.", "order": 20113, "speaker": "Angela Martin"},
  {"season": 4, "episode": 14, "scene": 11, "line": "Phyllis, can you do this?", "order": 20114, "speaker": "Michael Scott"},
  {"season": 4, "episode": 14, "scene": 11, "line": "[whispers] Yes.", "order": 20115, "speaker": "Phyllis Vance"},
  {"season": 4, "episode": 14, "scene": 12, "line": "Hi, can I help you?", "order": 20116, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 14, "scene": 12, "line": "Hi. Yes, uh, I'm Holly Flax. I was told to ask for a Mr. Flenderson?", "order": 20117, "speaker": "Holly Flax"},
  {"season": 4, "episode": 14, "scene": 13, "line": "As part of my last day I'm training the new Human Resources rep in the ways of the company. Again, the company has allotted for this training-one day.", "order": 20118, "speaker": "Toby Flenderson"},
  {"season": 4, "episode": 14, "scene": 14, "line": "So this is H.R. This is your desk.", "order": 20119, "speaker": "Toby Flenderson"},
  {"season": 4, "episode": 14, "scene": 14, "line": "I love the view.", "order": 20120, "speaker": "Holly Flax"},
  {"season": 4, "episode": 14, "scene": 14, "line": "Yeah, it's great, isn't it?", "order": 20121, "speaker": "Toby Flenderson"},
  {"season": 4, "episode": 14, "scene": 15, "line": "Hey. [kisses Jim on cheek]", "order": 20122, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 14, "scene": 15, "line": "Hey... What?", "order": 20123, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 14, "scene": 15, "line": "Guess who just got into the Pratt School of Design.", "order": 20124, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 14, "scene": 15, "line": "No way! What did I say? I said that they'd love those sketches. Congratulations!", "order": 20125, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 14, "scene": 15, "line": "Oh, thank you! I don't know why I doubted it, because I'm so clearly awesome!", "order": 20126, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 14, "scene": 15, "line": "Yes! So when do you start?", "order": 20127, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 14, "scene": 15, "line": "I don't know, I didn't read it carefully, I just saw 'congratulations' and I skimmed the list, I saw my name, I came in here to tell you and get a snack.", "order": 20128, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 14, "scene": 15, "line": "Wow. Busy morning.", "order": 20129, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 14, "scene": 15, "line": "So, you know it means I have to go to New York for three months...", "order": 20130, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 14, "scene": 15, "line": "It's not a big deal. I'll come visit you. And you'll visit me. It's only two hours away. It'll be fine. That part's gonna suck, but it'll be great.", "order": 20131, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 14, "scene": 15, "line": "Yeah, it sucks, but it'll be great.", "order": 20132, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 14, "scene": 15, "line": "See how easy that is?", "order": 20133, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 14, "scene": 15, "line": "Ohh... [they hug; she laughs]", "order": 20134, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 14, "scene": 16, "line": "I wouldn't go if things weren't so solid with Jim. And down the road, if we have a family, I couldn't go then either. So, the timing's perfect... And that is the first time I've ever used the word 'perfect' in here!", "order": 20135, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 14, "scene": 17, "line": "Hey, Stanley...", "order": 20136, "speaker": "Toby Flenderson"},
  {"season": 4, "episode": 14, "scene": 17, "line": "Hmm?", "order": 20137, "speaker": "Stanley Hudson"},
  {"season": 4, "episode": 14, "scene": 17, "line": "I want to introduce you to...", "order": 20138, "speaker": "Toby Flenderson"},
  {"season": 4, "episode": 14, "scene": 17, "line": "[watching from his office] Toby's replacement. Ugh. Wow.", "order": 20139, "speaker": "Michael Scott"},
  {"season": 4, "episode": 14, "scene": 17, "line": "So what do we know about her?", "order": 20140, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 14, "scene": 17, "line": "Well, we know that Toby thinks she'll be great. So, strike one, I hate her already.", "order": 20141, "speaker": "Michael Scott"},
  {"season": 4, "episode": 14, "scene": 17, "line": "I hate her too.", "order": 20142, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 14, "scene": 17, "line": "Why do you hate her?", "order": 20143, "speaker": "Michael Scott"},
  {"season": 4, "episode": 14, "scene": 17, "line": "Because she... stinks... with her... ways... and her... head.", "order": 20144, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 14, "scene": 17, "line": "You know, Dwight, sometimes... I don't know, I think you say things just to agree with me.", "order": 20145, "speaker": "Michael Scott"},
  {"season": 4, "episode": 14, "scene": 17, "line": "Would that be such a bad thing?", "order": 20146, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 14, "scene": 17, "line": "Yeah! It would! Just have a thought. Have an original thought. Although, I will agree that her head is weird. You know what we need to do?", "order": 20147, "speaker": "Michael Scott"},
  {"season": 4, "episode": 14, "scene": 17, "line": "What?", "order": 20148, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 14, "scene": 17, "line": "We need to sell her an elevator pass.", "order": 20149, "speaker": "Michael Scott"},
  {"season": 4, "episode": 14, "scene": 17, "line": "But our, our elevator doesn't require a pa... [gasps]", "order": 20150, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 14, "scene": 17, "line": "Exactly. A little old-fashioned hazing.", "order": 20151, "speaker": "Michael Scott"},
  {"season": 4, "episode": 14, "scene": 18, "line": "Hi. Are there any local companies that rent antigravity machines?", "order": 20152, "speaker": "Phyllis Vance"},
  {"season": 4, "episode": 14, "scene": 18, "line": "Antigravity machines?", "order": 20153, "speaker": "Woman over speakerphone"},
  {"season": 4, "episode": 14, "scene": 18, "line": "That's right. Yeah.", "order": 20154, "speaker": "Phyllis Vance"},
  {"season": 4, "episode": 14, "scene": 18, "line": "What do they do exactly?", "order": 20155, "speaker": "Woman over speakerphone"},
  {"season": 4, "episode": 14, "scene": 18, "line": "They make you feel lighter...", "order": 20156, "speaker": "Phyllis Vance"},
  {"season": 4, "episode": 14, "scene": 18, "line": "Antigravity... Um... Anti...depressants? I could put you through to someone on that.", "order": 20157, "speaker": "Woman over speakerphone"},
  {"season": 4, "episode": 14, "scene": 18, "line": "Okay.", "order": 20158, "speaker": "Phyllis Vance"},
  {"season": 4, "episode": 14, "scene": 19, "line": "[knock on door] Yeah.", "order": 20159, "speaker": "Michael Scott"},
  {"season": 4, "episode": 14, "scene": 19, "line": "Hey Michael.", "order": 20160, "speaker": "Toby Flenderson"},
  {"season": 4, "episode": 14, "scene": 19, "line": "Oh, hey, do you still work here?", "order": 20161, "speaker": "Michael Scott"},
  {"season": 4, "episode": 14, "scene": 19, "line": "I'd like to introduce you to my replacement.", "order": 20162, "speaker": "Toby Flenderson"},
  {"season": 4, "episode": 14, "scene": 19, "line": "Nah.", "order": 20163, "speaker": "Michael Scott"},
  {"season": 4, "episode": 14, "scene": 19, "line": "Come on.", "order": 20164, "speaker": "Toby Flenderson"},
  {"season": 4, "episode": 14, "scene": 19, "line": "No. I think I will pass.", "order": 20165, "speaker": "Michael Scott"},
  {"season": 4, "episode": 14, "scene": 19, "line": "Michael Scott, this is Holly.", "order": 20166, "speaker": "Toby Flenderson"},
  {"season": 4, "episode": 14, "scene": 19, "line": "Hi.", "order": 20167, "speaker": "Holly Flax"},
  {"season": 4, "episode": 14, "scene": 19, "line": "Hi. Yeah. Right. Okay, well, they hired a female Toby. Good for the world. Thank you, God, for creating two of you. Here's how things work here: my job is to make the office fun. Your job is to make the office lame. And we have an eternal struggle, you and I. And only one of us can be the winner. Spoiler alert: I'm gonna win.", "order": 20168, "speaker": "Michael Scott"},
  {"season": 4, "episode": 14, "scene": 19, "line": "Man, someone doesn't like H.R.", "order": 20169, "speaker": "Holly Flax"},
  {"season": 4, "episode": 14, "scene": 19, "line": "Yeah.", "order": 20170, "speaker": "Michael Scott"},
  {"season": 4, "episode": 14, "scene": 19, "line": "[to Toby] What did you do to him?", "order": 20171, "speaker": "Holly Flax"},
  {"season": 4, "episode": 14, "scene": 19, "line": "Nothing.", "order": 20172, "speaker": "Toby Flenderson"},
  {"season": 4, "episode": 14, "scene": 19, "line": "T-No. He tortured me... with his awfulness.", "order": 20173, "speaker": "Michael Scott"},
  {"season": 4, "episode": 14, "scene": 19, "line": "Yeah, I know what you mean. I nearly fell asleep when he gave me a tour of the files. Um, well look, I'll let you get back to work, but I-I really look forward to working with you, Mr. Scott.", "order": 20174, "speaker": "Holly Flax"},
  {"season": 4, "episode": 14, "scene": 19, "line": "Y-You can-Michael.", "order": 20175, "speaker": "Michael Scott"},
  {"season": 4, "episode": 14, "scene": 20, "line": "Thanks to Toby, I have a very strong prejudice against Human Resources. I believe that the department is a breeding ground for monsters. What I failed to consider though, is that not all monsters are bad. Like E.T. Is Holly our extraterrestrial? Maybe. Or maybe she's just an awesome woman from this planet.", "order": 20176, "speaker": "Michael Scott"},
  {"season": 4, "episode": 14, "scene": 21, "line": "[shaking Holly's hand] The pleasure's all mine.", "order": 20177, "speaker": "Creed Bratton"},
  {"season": 4, "episode": 14, "scene": 21, "line": "Oh, thanks. I'm really looking forward to sitting down with you and finding out more about what you do here.", "order": 20178, "speaker": "Holly Flax"},
  {"season": 4, "episode": 14, "scene": 21, "line": "Any time.", "order": 20179, "speaker": "Creed Bratton"},
  {"season": 4, "episode": 14, "scene": 21, "line": "What do you do here?", "order": 20180, "speaker": "Holly Flax"},
  {"season": 4, "episode": 14, "scene": 21, "line": "...Excuse me.", "order": 20181, "speaker": "Creed Bratton"},
  {"season": 4, "episode": 14, "scene": 22, "line": "What is wrong with this woman? She's asking about stuff that's nobody's business. 'What do I do?'... Really, what do I do here? I should've written it down. 'Qua' something, uh... qua... quar... quibo, qual...quir-quabity. Quabity assuance! No. No, no, no, no, but I'm getting close.", "order": 20182, "speaker": "Creed Bratton"},
  {"season": 4, "episode": 14, "scene": 23, "line": "[whispering] Have you been introduced to Kevin?", "order": 20183, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 14, "scene": 23, "line": "No. Which one's Kevin? [they glance over at Kevin subtly, who is stifling a giggle]", "order": 20184, "speaker": "Holly Flax"},
  {"season": 4, "episode": 14, "scene": 23, "line": "He's here on a special work program. He's slow, you know, in his brain.", "order": 20185, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 14, "scene": 23, "line": "Oh. Good for you guys.", "order": 20186, "speaker": "Holly Flax"},
  {"season": 4, "episode": 14, "scene": 23, "line": "Yeah.", "order": 20187, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 14, "scene": 24, "line": "Hazing is a fun way to show a new employee that she is not welcome or liked.", "order": 20188, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 14, "scene": 25, "line": "Accounting department, listen up. Holly, Human Resources. Angela, Oscar, and Kevin. [accountants mumble hellos]", "order": 20189, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 14, "scene": 25, "line": "Hello. Hello. [to Kevin] Hi.", "order": 20190, "speaker": "Holly Flax"},
  {"season": 4, "episode": 14, "scene": 25, "line": "Hi.", "order": 20191, "speaker": "Kevin Malone"},
  {"season": 4, "episode": 14, "scene": 25, "line": "What do you do?", "order": 20192, "speaker": "Holly Flax"},
  {"season": 4, "episode": 14, "scene": 25, "line": "I do the numbers.", "order": 20193, "speaker": "Kevin Malone"},
  {"season": 4, "episode": 14, "scene": 25, "line": "Oh, good for you.", "order": 20194, "speaker": "Holly Flax"},
  {"season": 4, "episode": 14, "scene": 25, "line": "Do you want a M&M?", "order": 20195, "speaker": "Kevin Malone"},
  {"season": 4, "episode": 14, "scene": 25, "line": "Oh, no, that is so sweet. But thank you though.", "order": 20196, "speaker": "Holly Flax"},
  {"season": 4, "episode": 14, "scene": 25, "line": "Yeah. I keep them here at my desk so that everybody doesn't take them.", "order": 20197, "speaker": "Kevin Malone"},
  {"season": 4, "episode": 14, "scene": 25, "line": "Well that is a very safe place for them.", "order": 20198, "speaker": "Holly Flax"},
  {"season": 4, "episode": 14, "scene": 25, "line": "Yeah.", "order": 20199, "speaker": "Kevin Malone"},
  {"season": 4, "episode": 14, "scene": 25, "line": "Here she is! Holly... You know, if we hung Holly from the ceiling, we'd have to kiss underneath of her. So-I know. Oh, sorry. Question, are you real, or are you a Hollygram?", "order": 20200, "speaker": "Michael Scott"},
  {"season": 4, "episode": 14, "scene": 25, "line": "Oh... [laughs along] Nice, I've never heard that one before actually.", "order": 20201, "speaker": "Holly Flax"},
  {"season": 4, "episode": 14, "scene": 25, "line": "I bet.", "order": 20202, "speaker": "Michael Scott"},
  {"season": 4, "episode": 14, "scene": 25, "line": "That's good.", "order": 20203, "speaker": "Holly Flax"},
  {"season": 4, "episode": 14, "scene": 25, "line": "I bet. Are these guys boring your ears off?", "order": 20204, "speaker": "Michael Scott"},
  {"season": 4, "episode": 14, "scene": 25, "line": "No, no, I...", "order": 20205, "speaker": "Holly Flax"},
  {"season": 4, "episode": 14, "scene": 25, "line": "Oh... What, um, what is your commute like? How long does it take to get in?", "order": 20206, "speaker": "Michael Scott"},
  {"season": 4, "episode": 14, "scene": 25, "line": "Ooh, uh...", "order": 20207, "speaker": "Holly Flax"},
  {"season": 4, "episode": 14, "scene": 25, "line": "You know, I should make you a mix. Do you have a, uh, a CD player?", "order": 20208, "speaker": "Michael Scott"},
  {"season": 4, "episode": 14, "scene": 25, "line": "Um... Y-yeah. Okay.", "order": 20209, "speaker": "Holly Flax"},
  {"season": 4, "episode": 14, "scene": 25, "line": "All right.", "order": 20210, "speaker": "Michael Scott"},
  {"season": 4, "episode": 14, "scene": 25, "line": "Thanks.", "order": 20211, "speaker": "Holly Flax"},
  {"season": 4, "episode": 14, "scene": 25, "line": "Yep.", "order": 20212, "speaker": "Michael Scott"},
  {"season": 4, "episode": 14, "scene": 25, "line": "Great.", "order": 20213, "speaker": "Holly Flax"},
  {"season": 4, "episode": 14, "scene": 26, "line": "Do you like it? It's a photo of everyone in the office. So you can take it to Costa Rica.", "order": 20214, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 14, "scene": 26, "line": "You're-you're not in it.", "order": 20215, "speaker": "Toby Flenderson"},
  {"season": 4, "episode": 14, "scene": 26, "line": "Well I was taking the picture.", "order": 20216, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 14, "scene": 26, "line": "I love it. I love it... I would love a picture of the two of us, to also take to Costa Rica.", "order": 20217, "speaker": "Toby Flenderson"},
  {"season": 4, "episode": 14, "scene": 26, "line": "Sure, do you have a camera here?", "order": 20218, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 14, "scene": 26, "line": "No, uh... [shouts] Does anyone have a camera here? [no one answers him] No one has a camera here. Uh...", "order": 20219, "speaker": "Toby Flenderson"},
  {"season": 4, "episode": 14, "scene": 26, "line": "Okay...", "order": 20220, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 14, "scene": 26, "line": "I'm gonna... I'll go get one. Stay. [runs out of the office]", "order": 20221, "speaker": "Toby Flenderson"},
  {"season": 4, "episode": 14, "scene": 27, "line": "[on the phone] So I just got the fax closing the sale, and uh, it's big. It is really big.", "order": 20222, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 14, "scene": 27, "line": "Congratulations!", "order": 20223, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 14, "scene": 27, "line": "Thanks.", "order": 20224, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 14, "scene": 27, "line": "Don't interrupt.", "order": 20225, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 14, "scene": 27, "line": "I just-", "order": 20226, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 14, "scene": 27, "line": "Congratulations on doing your job. Did you enter the sale on the website?", "order": 20227, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 14, "scene": 27, "line": "No, I didn't. I just logged it in right here, so...", "order": 20228, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 14, "scene": 27, "line": "All right, try to be a team player here, Jim. Log it in the website.", "order": 20229, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 14, "scene": 27, "line": "All right, well it already went through, so...", "order": 20230, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 14, "scene": 27, "line": "Don't worry about that. Just re-log it. [hangs up]", "order": 20231, "speaker": "Ryan Howard"},
  {"season": 4, "episode": 14, "scene": 27, "line": "Hello?", "order": 20232, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 14, "scene": 28, "line": "[listening to 'Crash Into Me' on his computer] Hmm... Jim? [Jim comes into his office] I am downloading some N3P...", "order": 20233, "speaker": "Michael Scott"},
  {"season": 4, "episode": 14, "scene": 28, "line": "That's not it.", "order": 20234, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 14, "scene": 28, "line": "Music...", "order": 20235, "speaker": "Michael Scott"},
  {"season": 4, "episode": 14, "scene": 28, "line": "Yeah.", "order": 20236, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 14, "scene": 28, "line": "For a CD mix tape...", "order": 20237, "speaker": "Michael Scott"},
  {"season": 4, "episode": 14, "scene": 28, "line": "Close.", "order": 20238, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 14, "scene": 28, "line": "For Holly.", "order": 20239, "speaker": "Michael Scott"},
  {"season": 4, "episode": 14, "scene": 28, "line": "Mmhmm.", "order": 20240, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 14, "scene": 28, "line": "And I'm looking for perfect songs that work on two levels.", "order": 20241, "speaker": "Michael Scott"},
  {"season": 4, "episode": 14, "scene": 28, "line": "What are the two levels?", "order": 20242, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 14, "scene": 28, "line": "The two levels being, uh, 'Welcome to Scranton'...", "order": 20243, "speaker": "Michael Scott"},
  {"season": 4, "episode": 14, "scene": 28, "line": "Mmhmm.", "order": 20244, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 14, "scene": 28, "line": "And 'I... love you.'", "order": 20245, "speaker": "Michael Scott"},
  {"season": 4, "episode": 14, "scene": 28, "line": "Okay. Let's start with the 'I love you' level.", "order": 20246, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 14, "scene": 28, "line": "Hey, what's the group that was from Scranton that made it big? Was that U2?", "order": 20247, "speaker": "Michael Scott"},
  {"season": 4, "episode": 14, "scene": 28, "line": "Yes. You don't love Holly.", "order": 20248, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 14, "scene": 28, "line": "I think I do.", "order": 20249, "speaker": "Michael Scott"},
  {"season": 4, "episode": 14, "scene": 28, "line": "But you just met her.", "order": 20250, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 14, "scene": 28, "line": "Well, it was love at first sight. Actually, it was-no, it was when I heard her voice. It was love at first see with my ears.", "order": 20251, "speaker": "Michael Scott"},
  {"season": 4, "episode": 14, "scene": 28, "line": "Oh, okay, it doesn't work like that.", "order": 20252, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 14, "scene": 28, "line": "Well, you're not a romantic.", "order": 20253, "speaker": "Michael Scott"},
  {"season": 4, "episode": 14, "scene": 28, "line": "Actually, I think I am. And I have a little bit of experience in office romance.", "order": 20254, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 14, "scene": 28, "line": "Oh really?", "order": 20255, "speaker": "Michael Scott"},
  {"season": 4, "episode": 14, "scene": 28, "line": "Yeah.", "order": 20256, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 14, "scene": 28, "line": "Like, with who?", "order": 20257, "speaker": "Michael Scott"},
  {"season": 4, "episode": 14, "scene": 28, "line": "We've been through this.", "order": 20258, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 14, "scene": 28, "line": "Pam?", "order": 20259, "speaker": "Michael Scott"},
  {"season": 4, "episode": 14, "scene": 28, "line": "Yes, we are still dating. And guess what? I took it slow.", "order": 20260, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 14, "scene": 28, "line": "Yeah, you took it too slow.", "order": 20261, "speaker": "Michael Scott"},
  {"season": 4, "episode": 14, "scene": 28, "line": "Well, we're really happy.", "order": 20262, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 14, "scene": 28, "line": "This thing with Holly feels a lot like love to me.", "order": 20263, "speaker": "Michael Scott"},
  {"season": 4, "episode": 14, "scene": 28, "line": "And that's really sweet. And you can think that. But you don't say that out loud, and you definitely don't say it to her.", "order": 20264, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 14, "scene": 28, "line": "I don't want her to get away.", "order": 20265, "speaker": "Michael Scott"},
  {"season": 4, "episode": 14, "scene": 28, "line": "I know. Here, Michael, you can court her as you get to know her, you know? I mean, the office is a great place for that. Pam and I, we got to know each other right out there. I mean, the first time we joked around was at my desk. And the first meal we ever had was, in the break room, actually. We were at two separate tables and, I remember that. The first time we kissed even, was right outside, and... Look, all I'm saying is that you can get to know someone really really well, like I did, right here at work.", "order": 20266, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 14, "scene": 29, "line": "I was thinking... fireworks for the party. What do you think?", "order": 20267, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 14, "scene": 29, "line": "Oh boy. I appreciate your help, but I can't...", "order": 20268, "speaker": "Phyllis Vance"},
  {"season": 4, "episode": 14, "scene": 29, "line": "Oh, no, no, no, no, no. I just meant I wanted to pay to have fireworks at the party.", "order": 20269, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 14, "scene": 29, "line": "Why would you do that?", "order": 20270, "speaker": "Phyllis Vance"},
  {"season": 4, "episode": 14, "scene": 29, "line": "Because I'm gonna miss Toby. Yep, he's a heck of a guy, and I think we should send him off right. It doesn't matter. Here ya go. [hands her an envelope with money in it]", "order": 20271, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 14, "scene": 29, "line": "Really?", "order": 20272, "speaker": "Phyllis Vance"},
  {"season": 4, "episode": 14, "scene": 29, "line": "Well we all want a good party, right?", "order": 20273, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 14, "scene": 30, "line": "I'm going to propose tonight. Holy crap!", "order": 20274, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 14, "scene": 31, "line": "Hey Kevin.", "order": 20275, "speaker": "Holly Flax"},
  {"season": 4, "episode": 14, "scene": 31, "line": "Hi.", "order": 20276, "speaker": "Kevin Malone"},
  {"season": 4, "episode": 14, "scene": 31, "line": "Do you need some help?", "order": 20277, "speaker": "Holly Flax"},
  {"season": 4, "episode": 14, "scene": 31, "line": "I can't decide what to get.", "order": 20278, "speaker": "Kevin Malone"},
  {"season": 4, "episode": 14, "scene": 31, "line": "Well, what do you like to eat?", "order": 20279, "speaker": "Holly Flax"},
  {"season": 4, "episode": 14, "scene": 31, "line": "Well I like pretzels, but, I really like chips.", "order": 20280, "speaker": "Kevin Malone"},
  {"season": 4, "episode": 14, "scene": 31, "line": "Hmm. Well how much money do you have there? [looks at the change in his hand] Okay, let's see... fifty... Oh, this is a button. Okay. Fifty-five, sixty-five, okay, you have seventy-five cents. So, that means you could get anything up in the top row.", "order": 20281, "speaker": "Holly Flax"},
  {"season": 4, "episode": 14, "scene": 31, "line": "Hmm...", "order": 20282, "speaker": "Kevin Malone"},
  {"season": 4, "episode": 14, "scene": 32, "line": "I'm totally gonna bang Holly! She is cute, and helpful, and she really seems into me.", "order": 20283, "speaker": "Kevin Malone"},
  {"season": 4, "episode": 14, "scene": 33, "line": "[on phone, leaving a message] Hey, Ryan, it's Jim. Look man, I don't know what's gotten into you lately, but you know what? I really don't care, because you're trying to get rid of me. And I bet you don't think I care enough about this job to actually fight back, but you're wrong, because I do, and I will. So you can keep trying to push me out of this place, but guess what? I'm not going anywhere. [hangs up and exhales sharply]", "order": 20284, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 14, "scene": 34, "line": "[tool clicking] Damn it!", "order": 20285, "speaker": "Holly Flax"},
  {"season": 4, "episode": 14, "scene": 34, "line": "Hello, Holly. [Holly is on the floor with her desk chair in pieces] Woah, what are you doing? You don't, uh, you don't have to do that. I mean, we have already put-together chairs. That's how we buy them actually.", "order": 20286, "speaker": "Michael Scott"},
  {"season": 4, "episode": 14, "scene": 34, "line": "Oh, I'm trying to adjust the lumbar support on Toby's chair and... that made this up/down lever thing not work, and then I took the whole chair apart, and that... is the story of me on the floor. It's pretty good, right? You know, I'm gonna sell the movie rights.", "order": 20287, "speaker": "Holly Flax"},
  {"season": 4, "episode": 14, "scene": 34, "line": "And the sequel, 'Woman Stands at Desk and Works.' So, I have no idea how you, [he sits on the floor] how you sit like that.", "order": 20288, "speaker": "Michael Scott"},
  {"season": 4, "episode": 14, "scene": 34, "line": "Yoga.", "order": 20289, "speaker": "Holly Flax"},
  {"season": 4, "episode": 14, "scene": 34, "line": "[as Yoda] Sit on floor and put together chair we will. [laughs nervously] Yo-da. Um...", "order": 20290, "speaker": "Michael Scott"},
  {"season": 4, "episode": 14, "scene": 34, "line": "[as Yoda] Pass curvy metal piece, you will.", "order": 20291, "speaker": "Holly Flax"},
  {"season": 4, "episode": 14, "scene": 34, "line": "So are you in down this weekend? Cause, I'm not-I'm not... I'm not gonna be in town. I'm going out of town.", "order": 20292, "speaker": "Michael Scott"},
  {"season": 4, "episode": 14, "scene": 34, "line": "Ohh, so you can't make my orgy? Kidding!", "order": 20293, "speaker": "Holly Flax"},
  {"season": 4, "episode": 14, "scene": 34, "line": "Kidding. [overdramatically] Acting!", "order": 20294, "speaker": "Michael Scott"},
  {"season": 4, "episode": 14, "scene": 34, "line": "[overdramatically] Acting!", "order": 20295, "speaker": "Holly Flax"},
  {"season": 4, "episode": 14, "scene": 34, "line": "[overdramatically] Acting!", "order": 20296, "speaker": "Michael Scott"},
  {"season": 4, "episode": 14, "scene": 34, "line": "[laughs] Lovitz.", "order": 20297, "speaker": "Holly Flax"},
  {"season": 4, "episode": 14, "scene": 34, "line": "Yeah.", "order": 20298, "speaker": "Michael Scott"},
  {"season": 4, "episode": 14, "scene": 35, "line": "I did it! [exhales]", "order": 20299, "speaker": "Michael Scott"},
  {"season": 4, "episode": 14, "scene": 35, "line": "What'd you do?", "order": 20300, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 14, "scene": 35, "line": "I talked to her-Holly. Just pleasantries, nothing, you know... not like, do you want kids or, religion, or what side of the bed do you want. Hey, I can take either side of the bed at this point.", "order": 20301, "speaker": "Michael Scott"},
  {"season": 4, "episode": 14, "scene": 35, "line": "Wow. Okay, so how'd that feel?", "order": 20302, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 14, "scene": 35, "line": "It was hard. I wanted to kiss her.", "order": 20303, "speaker": "Michael Scott"},
  {"season": 4, "episode": 14, "scene": 35, "line": "Oh, I'm so glad you didn't.", "order": 20304, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 14, "scene": 35, "line": "Whew, oh God. [inhales] Laying a base. Laying a base...", "order": 20305, "speaker": "Michael Scott"},
  {"season": 4, "episode": 14, "scene": 35, "line": "There you go. There's plenty of base-laying left, right?... Hey. Hey!", "order": 20306, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 14, "scene": 35, "line": "[giddy] Yeah...", "order": 20307, "speaker": "Michael Scott"},
  {"season": 4, "episode": 14, "scene": 36, "line": "Hello Angela.", "order": 20308, "speaker": "Phyllis Vance"},
  {"season": 4, "episode": 14, "scene": 36, "line": "Phyllis, you look like you're gonna have a heart attack.", "order": 20309, "speaker": "Angela Martin"},
  {"season": 4, "episode": 14, "scene": 36, "line": "Can I get a list of your vendors?", "order": 20310, "speaker": "Phyllis Vance"},
  {"season": 4, "episode": 14, "scene": 36, "line": "I shredded it.", "order": 20311, "speaker": "Angela Martin"},
  {"season": 4, "episode": 14, "scene": 36, "line": "Why would you do that?", "order": 20312, "speaker": "Phyllis Vance"},
  {"season": 4, "episode": 14, "scene": 36, "line": "Gosh, I just don't know. Why do you think?", "order": 20313, "speaker": "Angela Martin"},
  {"season": 4, "episode": 14, "scene": 36, "line": "[knocks files off desk] Sorry.", "order": 20314, "speaker": "Phyllis Vance"},
  {"season": 4, "episode": 14, "scene": 37, "line": "[Michael and Holly are still working on the chair] What'd you guys do?", "order": 20315, "speaker": "Toby Flenderson"},
  {"season": 4, "episode": 14, "scene": 37, "line": "Oh, there he is. Thought you had gone home already. Why don't you go home, and come back for the party?", "order": 20316, "speaker": "Michael Scott"},
  {"season": 4, "episode": 14, "scene": 37, "line": "Well, we still have to do the exit interview.", "order": 20317, "speaker": "Toby Flenderson"},
  {"season": 4, "episode": 14, "scene": 37, "line": "Yes we do.", "order": 20318, "speaker": "Michael Scott"},
  {"season": 4, "episode": 14, "scene": 38, "line": "I'll let you in on a little secret: I've been very much looking forward to this moment. Very, very much. I-I have been steeped in anticipation. Toby has been cruisin' for a bruisin' for twelve years, and I am now his cruise director, and my name is Captain Bruisin'.", "order": 20319, "speaker": "Michael Scott"},
  {"season": 4, "episode": 14, "scene": 39, "line": "[Michael hands him a present] Wow, thanks, Michael, I...", "order": 20320, "speaker": "Toby Flenderson"},
  {"season": 4, "episode": 14, "scene": 39, "line": "Can I just say that, of all the idiots, in all the idiot villages, in all the idiot worlds, you stand alone, my friend.", "order": 20321, "speaker": "Michael Scott"},
  {"season": 4, "episode": 14, "scene": 39, "line": "[enters conference room] Hello.", "order": 20322, "speaker": "Holly Flax"},
  {"season": 4, "episode": 14, "scene": 39, "line": "Hey! Hi, good to see you.", "order": 20323, "speaker": "Michael Scott"},
  {"season": 4, "episode": 14, "scene": 39, "line": "You too.", "order": 20324, "speaker": "Holly Flax"},
  {"season": 4, "episode": 14, "scene": 39, "line": "I'm just having a little exit interview.", "order": 20325, "speaker": "Michael Scott"},
  {"season": 4, "episode": 14, "scene": 39, "line": "Yeah, I know, that's why I'm here.", "order": 20326, "speaker": "Holly Flax"},
  {"season": 4, "episode": 14, "scene": 39, "line": "Um, no, no, no. This is very boring stuff. Why don't you take a tour? Have you seen the baler?", "order": 20327, "speaker": "Michael Scott"},
  {"season": 4, "episode": 14, "scene": 39, "line": "No, no, I'll look at it later. It's part of my job.", "order": 20328, "speaker": "Holly Flax"},
  {"season": 4, "episode": 14, "scene": 39, "line": "Okay.", "order": 20329, "speaker": "Michael Scott"},
  {"season": 4, "episode": 14, "scene": 39, "line": "[enters conference room] Did you need me to take notes?", "order": 20330, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 14, "scene": 39, "line": "Hi Pam. Stay.", "order": 20331, "speaker": "Toby Flenderson"},
  {"season": 4, "episode": 14, "scene": 39, "line": "Okay.", "order": 20332, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 14, "scene": 39, "line": "Um... Alright, well then, I will proceed. [takes out note cards] I just have some questions that I was gonna ask. Um... Who do you think you are?", "order": 20333, "speaker": "Michael Scott"},
  {"season": 4, "episode": 14, "scene": 39, "line": "I'm Toby.", "order": 20334, "speaker": "Toby Flenderson"},
  {"season": 4, "episode": 14, "scene": 39, "line": "Yeah. Correct. Um... What gives-what-what gives you the right?", "order": 20335, "speaker": "Michael Scott"},
  {"season": 4, "episode": 14, "scene": 39, "line": "Um, I-I brought the binder. Do you wanna take a little look?", "order": 20336, "speaker": "Holly Flax"},
  {"season": 4, "episode": 14, "scene": 39, "line": "Sure. You know what? That sounds good. I'll take a little look-see.", "order": 20337, "speaker": "Michael Scott"},
  {"season": 4, "episode": 14, "scene": 39, "line": "Here you go.", "order": 20338, "speaker": "Holly Flax"},
  {"season": 4, "episode": 14, "scene": 39, "line": "Thank you. [reads] What would you improve about Dunder Mifflin?", "order": 20339, "speaker": "Michael Scott"},
  {"season": 4, "episode": 14, "scene": 39, "line": "This place, um...", "order": 20340, "speaker": "Toby Flenderson"},
  {"season": 4, "episode": 14, "scene": 39, "line": "Well, no no no no no...", "order": 20341, "speaker": "Michael Scott"},
  {"season": 4, "episode": 14, "scene": 39, "line": "I've got some ideas, I guess...", "order": 20342, "speaker": "Toby Flenderson"},
  {"season": 4, "episode": 14, "scene": 39, "line": "No no no no no. I'm not asking it. I'm just reading it out loud.", "order": 20343, "speaker": "Michael Scott"},
  {"season": 4, "episode": 14, "scene": 39, "line": "Oh, I'd like to hear the answer.", "order": 20344, "speaker": "Holly Flax"},
  {"season": 4, "episode": 14, "scene": 39, "line": "Well let me see... I would, uh...", "order": 20345, "speaker": "Toby Flenderson"},
  {"season": 4, "episode": 14, "scene": 39, "line": "[hides his face from Holly; whispers to Toby] I'll kill you.", "order": 20346, "speaker": "Michael Scott"},
  {"season": 4, "episode": 14, "scene": 39, "line": "I guess everything's okay.", "order": 20347, "speaker": "Toby Flenderson"},
  {"season": 4, "episode": 14, "scene": 40, "line": "I made it this far, right? What's the point?", "order": 20348, "speaker": "Toby Flenderson"},
  {"season": 4, "episode": 14, "scene": 41, "line": "Toby, why don't you open your present?", "order": 20349, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 14, "scene": 41, "line": "Oh, no no no, no, no. No, this-hey, hey, hey, hey, this might not be what I think... that I don't even know is in there, because there are a lot of presents in my car, and I don't know which is which...", "order": 20350, "speaker": "Michael Scott"},
  {"season": 4, "episode": 14, "scene": 41, "line": "[unwraps present; present is a rock with a Post-It note rubber-banded to it; reads] 'Suck on this.'", "order": 20351, "speaker": "Toby Flenderson"},
  {"season": 4, "episode": 14, "scene": 41, "line": "What the hell is that?", "order": 20352, "speaker": "Michael Scott"},
  {"season": 4, "episode": 14, "scene": 41, "line": "Michael...", "order": 20353, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 14, "scene": 41, "line": "That's... What do you mean, 'Michael'? That's not even my handwriting. [exits conference room] Hey! What the hell is going on here?! Who thought it would be hysterical to give Toby a rock for his going-away gift?!", "order": 20354, "speaker": "Michael Scott"},
  {"season": 4, "episode": 14, "scene": 41, "line": "You did.", "order": 20355, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 14, "scene": 41, "line": "No!", "order": 20356, "speaker": "Michael Scott"},
  {"season": 4, "episode": 14, "scene": 41, "line": "You made me wrap it. I thought it was over the line. I just- [Michael slams conference room door in his face]", "order": 20357, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 14, "scene": 41, "line": "Okay, you know what that is? You know what that is? That is... a psyche. Psyche. So, that is not my real gift to Toby.", "order": 20358, "speaker": "Michael Scott"},
  {"season": 4, "episode": 14, "scene": 41, "line": "So what is your gift?", "order": 20359, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 14, "scene": 41, "line": "My gift is forthcoming, Pam.", "order": 20360, "speaker": "Michael Scott"},
  {"season": 4, "episode": 14, "scene": 41, "line": "What is it?", "order": 20361, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 14, "scene": 41, "line": "I am going to give Toby...", "order": 20362, "speaker": "Michael Scott"},
  {"season": 4, "episode": 14, "scene": 41, "line": "Your watch?", "order": 20363, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 14, "scene": 41, "line": "...Yes, I am. That was it. How did you know that?", "order": 20364, "speaker": "Michael Scott"},
  {"season": 4, "episode": 14, "scene": 41, "line": "I just knew.", "order": 20365, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 14, "scene": 41, "line": "How did you know?", "order": 20366, "speaker": "Michael Scott"},
  {"season": 4, "episode": 14, "scene": 41, "line": "Oh that is so sweet.", "order": 20367, "speaker": "Holly Flax"},
  {"season": 4, "episode": 14, "scene": 41, "line": "Well... That's my watch.", "order": 20368, "speaker": "Michael Scott"},
  {"season": 4, "episode": 14, "scene": 41, "line": "Thanks, I'm gonna set it to Costa Rica time.", "order": 20369, "speaker": "Toby Flenderson"},
  {"season": 4, "episode": 14, "scene": 41, "line": "Hey, that's good.", "order": 20370, "speaker": "Michael Scott"},
  {"season": 4, "episode": 14, "scene": 41, "line": "[from outside the conference room] Hey, it's the kid! Look, look, look, look, look! [Michael, Toby, Holly, and Pam exit the conference room] Hey! It's the temp! Look! [everyone is crowded around Jim's computer]", "order": 20371, "speaker": "Creed Bratton"},
  {"season": 4, "episode": 14, "scene": 41, "line": "Oh my gosh.", "order": 20372, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 14, "scene": 41, "line": "It's the temp! [all are watching a YouTube video of Ryan being escorted out of Dunder Mifflin corporate offices by policemen]", "order": 20373, "speaker": "Creed Bratton"},
  {"season": 4, "episode": 14, "scene": 41, "line": "Is that the police?", "order": 20374, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 14, "scene": 41, "line": "Yes.", "order": 20375, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 14, "scene": 41, "line": "Mm-hmm.", "order": 20376, "speaker": "Creed Bratton"},
  {"season": 4, "episode": 14, "scene": 42, "line": "Well this is what happened: uh, Ryan's big project was the website... which wasn't doing so well. So Ryan, to give the impression of sales, recorded them twice: once as office sales, once as website sales. Which is what we refer to in the business as 'misleading the shareholders.' Another good term is 'fraud.' The real crime, I think, was the beard.", "order": 20377, "speaker": "Oscar Martinez"},
  {"season": 4, "episode": 14, "scene": 43, "line": "[still watching video of Ryan] Oh my God. Ryan... Oh my God.", "order": 20378, "speaker": "Michael Scott"},
  {"season": 4, "episode": 14, "scene": 44, "line": "I cannot wait to visit Ryan in prison. I'm gonna wear my hottest track suit, and get my hair done, and then be like, 'Hi Ryan.' And then all the other prisoners are gonna be like, 'Damn! Ryan, you got a hot ex-girlfriend. Ooh, I would never have treated her so bad when I was outside of prison.'", "order": 20379, "speaker": "Kelly Kapoor"},
  {"season": 4, "episode": 14, "scene": 45, "line": "Would you do me a favor and connect me to Ryan?", "order": 20380, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 14, "scene": 45, "line": "Absolutely. [dials and hands Jim receiver]", "order": 20381, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 14, "scene": 45, "line": "[clears throat] Right to voicemail. [into phone] Hey, Ryan, it's Jim. You know what? Totally disregard that last voicemail, because you obviously have your hands tied. Good luck! [hangs up]", "order": 20382, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 14, "scene": 46, "line": "What's protocol on this?", "order": 20383, "speaker": "Holly Flax"},
  {"season": 4, "episode": 14, "scene": 46, "line": "I normally do nothing, I guess. No, you shouldn't- [Holly knocks on Michael's door]", "order": 20384, "speaker": "Toby Flenderson"},
  {"season": 4, "episode": 14, "scene": 46, "line": "Michael, are you okay?", "order": 20385, "speaker": "Holly Flax"},
  {"season": 4, "episode": 14, "scene": 46, "line": "[voice straining] I'm just worried about my friend.", "order": 20386, "speaker": "Michael Scott"},
  {"season": 4, "episode": 14, "scene": 46, "line": "Oh, of course you are.", "order": 20387, "speaker": "Holly Flax"},
  {"season": 4, "episode": 14, "scene": 46, "line": "Just, I'm fine. I'm holding it together. I-I have a business to run.", "order": 20388, "speaker": "Michael Scott"},
  {"season": 4, "episode": 14, "scene": 46, "line": "No, hey.", "order": 20389, "speaker": "Holly Flax"},
  {"season": 4, "episode": 14, "scene": 46, "line": "I'm cool.", "order": 20390, "speaker": "Michael Scott"},
  {"season": 4, "episode": 14, "scene": 46, "line": "Stop. You can let yourself be upset. He's your friend. You know what I usually find?...", "order": 20391, "speaker": "Holly Flax"},
  {"season": 4, "episode": 14, "scene": 47, "line": "Holly is sweet and simple, like a lady baker. I would not be surprised to find out that she had worked in a bakery before coming here. She has that kind of warmth. I'm pretty sure she's baked on a professional level.", "order": 20392, "speaker": "Michael Scott"},
  {"season": 4, "episode": 14, "scene": 48, "line": "[everyone exits the building to find a carnival-themed party in the parking lot] Woah! Cool! A bouncy house!", "order": 20393, "speaker": "Kevin Malone"},
  {"season": 4, "episode": 14, "scene": 48, "line": "Kevin, take your shoes off first!", "order": 20394, "speaker": "Phyllis Vance"},
  {"season": 4, "episode": 14, "scene": 48, "line": "No antigravity machine, huh?", "order": 20395, "speaker": "Michael Scott"},
  {"season": 4, "episode": 14, "scene": 48, "line": "Sorry, Michael, I don't think they're real.", "order": 20396, "speaker": "Phyllis Vance"},
  {"season": 4, "episode": 14, "scene": 48, "line": "Ferris wheel's pretty cool though.", "order": 20397, "speaker": "Michael Scott"},
  {"season": 4, "episode": 14, "scene": 49, "line": "Wow. Look at our parking lot.", "order": 20398, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 14, "scene": 49, "line": "Yeah, who'd have thought? [Pam sees fireworks being set up; smiles]", "order": 20399, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 14, "scene": 50, "line": "Is Jim gonna propose tonight? He is, isn't he? No, he's not... Is he?", "order": 20400, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 14, "scene": 51, "line": "Oh, it was a pretty good company, but I just couldn't see a future there. They kept hiring from the outside. It was easy to get in but impossible to rise up.", "order": 20401, "speaker": "Holly Flax"},
  {"season": 4, "episode": 14, "scene": 51, "line": "That's what she... a lot of places are like that.", "order": 20402, "speaker": "Michael Scott"},
  {"season": 4, "episode": 14, "scene": 51, "line": "I think it's really cool you hired Kevin.", "order": 20403, "speaker": "Holly Flax"},
  {"season": 4, "episode": 14, "scene": 51, "line": "Thanks.", "order": 20404, "speaker": "Michael Scott"},
  {"season": 4, "episode": 14, "scene": 52, "line": "You have it?", "order": 20405, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 14, "scene": 52, "line": "Almost. [she unlocks Holly's car]", "order": 20406, "speaker": "Meredith Palmer"},
  {"season": 4, "episode": 14, "scene": 52, "line": "Okay, here we go. Here we go. [Mose carries a raccoon in a cage] Put it in. [he puts the raccoon in the car]", "order": 20407, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 14, "scene": 52, "line": "I like, uh...", "order": 20408, "speaker": "Michael Scott"},
  {"season": 4, "episode": 14, "scene": 52, "line": "That-That's my car.", "order": 20409, "speaker": "Holly Flax"},
  {"season": 4, "episode": 14, "scene": 52, "line": "Hey. What are you doing?", "order": 20410, "speaker": "Michael Scott"},
  {"season": 4, "episode": 14, "scene": 52, "line": "[Mose drops cage and runs] Mose!", "order": 20411, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 14, "scene": 52, "line": "What the hell is going on here?!", "order": 20412, "speaker": "Michael Scott"},
  {"season": 4, "episode": 14, "scene": 52, "line": "N-nothing you need to know the details of.", "order": 20413, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 14, "scene": 52, "line": "There is a raccoon in the car, Dwight!", "order": 20414, "speaker": "Michael Scott"},
  {"season": 4, "episode": 14, "scene": 52, "line": "N-no there's not.", "order": 20415, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 14, "scene": 52, "line": "Why did you do that?", "order": 20416, "speaker": "Michael Scott"},
  {"season": 4, "episode": 14, "scene": 52, "line": "It was playful hazing.", "order": 20417, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 14, "scene": 52, "line": "No. There is no such thing as playful hazing. Dwight, I want you to look at Holly right now. Everybody, I want you to look at Holly right now, and maybe if you look at her deeply enough, you will see what I see in her. And that is that we are all very lucky to have her here. Holly is the best thing that has happened to this company since World War II. Fifty years, she is the best. [to Dwight] Take care of that, all right?", "order": 20418, "speaker": "Michael Scott"},
  {"season": 4, "episode": 14, "scene": 52, "line": "It's not rabid.", "order": 20419, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 14, "scene": 52, "line": "Shh...", "order": 20420, "speaker": "Michael Scott"},
  {"season": 4, "episode": 14, "scene": 52, "line": "Thanks for bringing that up.", "order": 20421, "speaker": "Meredith Palmer"},
  {"season": 4, "episode": 14, "scene": 52, "line": "Get it out. Come on, get it out. Get it out.", "order": 20422, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 14, "scene": 53, "line": "I'm really sorry.", "order": 20423, "speaker": "Michael Scott"},
  {"season": 4, "episode": 14, "scene": 53, "line": "Michael, thank you so much for saying that. [puts a hand on his arm] I feel so welcome here.", "order": 20424, "speaker": "Holly Flax"},
  {"season": 4, "episode": 14, "scene": 53, "line": "Yeah...", "order": 20425, "speaker": "Michael Scott"},
  {"season": 4, "episode": 14, "scene": 53, "line": "I just... Um, excuse me.", "order": 20426, "speaker": "Holly Flax"},
  {"season": 4, "episode": 14, "scene": 54, "line": "Did you see that! Did you see it? Did you see what... wow-wee! Uh, well, Jan didn't believe in showing affection, so... sometimes I don't know how to react when a girl touches me. Oh... I like it! [giggles]", "order": 20427, "speaker": "Michael Scott"},
  {"season": 4, "episode": 14, "scene": 55, "line": "Hey Mrs. Vance, we're all out!", "order": 20428, "speaker": "Vance Refrigeration guy"},
  {"season": 4, "episode": 14, "scene": 55, "line": "Hey, Kev, I need you to do me a solid and go buy some more barbeque sauce. [Kevin nods] Okay.", "order": 20429, "speaker": "Phyllis Vance"},
  {"season": 4, "episode": 14, "scene": 56, "line": "Cool! You drive your own car?", "order": 20430, "speaker": "Holly Flax"},
  {"season": 4, "episode": 14, "scene": 56, "line": "Yup. This is my car. Do you drive your own car?", "order": 20431, "speaker": "Kevin Malone"},
  {"season": 4, "episode": 14, "scene": 56, "line": "Yep. Just like you.", "order": 20432, "speaker": "Holly Flax"},
  {"season": 4, "episode": 14, "scene": 56, "line": "Okay, bye.", "order": 20433, "speaker": "Kevin Malone"},
  {"season": 4, "episode": 14, "scene": 56, "line": "Bye. Kevin, I'm really proud of you.", "order": 20434, "speaker": "Holly Flax"},
  {"season": 4, "episode": 14, "scene": 57, "line": "[on microphone] Alright! Let's hear it for Darryl and his band. [applause] You know what? Let's also give a shout-out to Phyllis for this awesome party, huh?", "order": 20435, "speaker": "Michael Scott"},
  {"season": 4, "episode": 14, "scene": 57, "line": "[everyone applauds] Yay, Phyllis!", "order": 20436, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 14, "scene": 57, "line": "Probably the best one that we have ever had, right? [cheers and applause] But the real reason that we are here, is to say goodbye to a guy who we will probably never ever see again. Now, a lot of you know that I am an accomplished songwriter.", "order": 20437, "speaker": "Michael Scott"},
  {"season": 4, "episode": 14, "scene": 57, "line": "Song parody writer.", "order": 20438, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 14, "scene": 57, "line": "I have done things like um, 'Beers in Heaven.'", "order": 20439, "speaker": "Michael Scott"},
  {"season": 4, "episode": 14, "scene": 57, "line": "Classic.", "order": 20440, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 14, "scene": 57, "line": "Or, 'Total Eclipse of the Fart.'", "order": 20441, "speaker": "Michael Scott"},
  {"season": 4, "episode": 14, "scene": 57, "line": "Not my favorite, but...", "order": 20442, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 14, "scene": 57, "line": "I like that one.", "order": 20443, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 14, "scene": 57, "line": "It's not my favorite...", "order": 20444, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 14, "scene": 57, "line": "I like that one.", "order": 20445, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 14, "scene": 57, "line": "I love to sing them, but I am not going to be doing that today. I am going to be doing something I wrote specifically for Toby. [to band] Do you know, um, 'Goodbye Stranger'? Supertramp?", "order": 20446, "speaker": "Michael Scott"},
  {"season": 4, "episode": 14, "scene": 57, "line": "Yeah. [music starts]", "order": 20447, "speaker": "Darryl Philbin"},
  {"season": 4, "episode": 14, "scene": 57, "line": "[sings] It was early morning yesterday... I was up before the dawn... and I really have enjoyed my stay... Toby must be movin' on.", "order": 20448, "speaker": "Michael Scott"},
  {"season": 4, "episode": 14, "scene": 58, "line": "I'm gonna to miss Toby. He has a nice, calming presence in the office.", "order": 20449, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 14, "scene": 59, "line": "[singing] Goodbye Toby, it's been nice. Hope you find your paradise...", "order": 20450, "speaker": "Michael Scott"},
  {"season": 4, "episode": 14, "scene": 60, "line": "Don't tell him I said this, but I always thought he was kinda cute.", "order": 20451, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 14, "scene": 61, "line": "[singing, screaming into the mic] Come tomorrow, feel no pain! Feel no pain! Toby! Toby! Tobee-yy! Toby's goin' away! See ya! He's outta here! See ya! He's outta here! Ohh! Goodbye Toby! Goodbye Toby! Goodbye Toby! Goodbye Tooo-by!", "order": 20452, "speaker": "Michael Scott"},
  {"season": 4, "episode": 14, "scene": 62, "line": "Not bad.", "order": 20453, "speaker": "Holly Flax"},
  {"season": 4, "episode": 14, "scene": 62, "line": "Oh, hey. Thanks.", "order": 20454, "speaker": "Michael Scott"},
  {"season": 4, "episode": 14, "scene": 62, "line": "Someday I would love to hear 'Beers in Heaven.'", "order": 20455, "speaker": "Holly Flax"},
  {"season": 4, "episode": 14, "scene": 62, "line": "Oh, okay. Yeah. Well, actu-too soon. It's uh, it's uh, very sexual. [cell phone rings] Sorry. Kevin. [answers] Kevin, where are you? You missed my song, buddy.", "order": 20456, "speaker": "Michael Scott"},
  {"season": 4, "episode": 14, "scene": 62, "line": "Yeah, Michael, I'm at Gerrity's. You have to come down here.", "order": 20457, "speaker": "Kevin Malone"},
  {"season": 4, "episode": 14, "scene": 62, "line": "Just pay for it and we'll reimburse you when you get back.", "order": 20458, "speaker": "Michael Scott"},
  {"season": 4, "episode": 14, "scene": 62, "line": "No, I-I brought my money. Michael, there's something that you need to see.", "order": 20459, "speaker": "Kevin Malone"},
  {"season": 4, "episode": 14, "scene": 62, "line": "What is it?", "order": 20460, "speaker": "Michael Scott"},
  {"season": 4, "episode": 14, "scene": 62, "line": "Just hurry. [hangs up]", "order": 20461, "speaker": "Kevin Malone"},
  {"season": 4, "episode": 14, "scene": 62, "line": "Is he okay?", "order": 20462, "speaker": "Holly Flax"},
  {"season": 4, "episode": 14, "scene": 62, "line": "Yeah. He's at the supermarket and he needs me.", "order": 20463, "speaker": "Michael Scott"},
  {"season": 4, "episode": 14, "scene": 62, "line": "Yeah well, the party, driving to the supermarket... it's a big day for him.", "order": 20464, "speaker": "Holly Flax"},
  {"season": 4, "episode": 14, "scene": 62, "line": "Yeah, that's true. Don't move a muscle. I will be back momentarily, all right? [Holly freezes; Michael laughs] No, you can drink-you can finish your drink and then I'll-okay, I'll be back.", "order": 20465, "speaker": "Michael Scott"},
  {"season": 4, "episode": 14, "scene": 63, "line": "Well it was good to see you.", "order": 20466, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 14, "scene": 63, "line": "It was great to see you, Jan.", "order": 20467, "speaker": "Kevin Malone"},
  {"season": 4, "episode": 14, "scene": 63, "line": "Yeah, so...", "order": 20468, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 14, "scene": 63, "line": "O-kay. Hello Jan.", "order": 20469, "speaker": "Michael Scott"},
  {"season": 4, "episode": 14, "scene": 63, "line": "Hello... Michael.", "order": 20470, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 14, "scene": 63, "line": "Wow, Kevin, really? We're- [to Jan] Sorry. We're in the middle of a party. [to Kevin] Is this why you called me down here?", "order": 20471, "speaker": "Michael Scott"},
  {"season": 4, "episode": 14, "scene": 63, "line": "Yeah, Michael, I just uh...", "order": 20472, "speaker": "Kevin Malone"},
  {"season": 4, "episode": 14, "scene": 63, "line": "I...", "order": 20473, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 14, "scene": 63, "line": "I think you kids have a lot to catch up on.", "order": 20474, "speaker": "Kevin Malone"},
  {"season": 4, "episode": 14, "scene": 63, "line": "Oh, okay.", "order": 20475, "speaker": "Michael Scott"},
  {"season": 4, "episode": 14, "scene": 63, "line": "Yeah.", "order": 20476, "speaker": "Kevin Malone"},
  {"season": 4, "episode": 14, "scene": 63, "line": "Thanks, Kevin. Um... [Kevin walks away, revealing Jan's pregnant belly]", "order": 20477, "speaker": "Michael Scott"},
  {"season": 4, "episode": 14, "scene": 64, "line": "Wow. I can't believe it! Look at you! [Jan laughs] Are you nauseous?", "order": 20478, "speaker": "Michael Scott"},
  {"season": 4, "episode": 14, "scene": 64, "line": "No.", "order": 20479, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 14, "scene": 64, "line": "Do you-do you have cravings? You never touched my Propecea, or my Accutane, did you?", "order": 20480, "speaker": "Michael Scott"},
  {"season": 4, "episode": 14, "scene": 64, "line": "Uh, no.", "order": 20481, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 14, "scene": 64, "line": "Good. Good. Thank God.", "order": 20482, "speaker": "Michael Scott"},
  {"season": 4, "episode": 14, "scene": 64, "line": "I didn't...", "order": 20483, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 14, "scene": 64, "line": "Cause that's...", "order": 20484, "speaker": "Michael Scott"},
  {"season": 4, "episode": 14, "scene": 64, "line": "... touch that.", "order": 20485, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 14, "scene": 64, "line": "Wow, I am so happy. I am so deliriously happy...", "order": 20486, "speaker": "Michael Scott"},
  {"season": 4, "episode": 14, "scene": 64, "line": "Why?", "order": 20487, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 14, "scene": 64, "line": "Because you're pregnant, and because it obviously happened when we were together. And, I am very...", "order": 20488, "speaker": "Michael Scott"},
  {"season": 4, "episode": 14, "scene": 64, "line": "Yeah, it did...", "order": 20489, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 14, "scene": 64, "line": "... proud.", "order": 20490, "speaker": "Michael Scott"},
  {"season": 4, "episode": 14, "scene": 64, "line": "... happen when...", "order": 20491, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 14, "scene": 64, "line": "Um...", "order": 20492, "speaker": "Michael Scott"},
  {"season": 4, "episode": 14, "scene": 64, "line": "... we were together. That's true. And-but, you... are not... uh, you're not the dad.", "order": 20493, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 14, "scene": 64, "line": "You cheated on me... when I specifically asked you not to?", "order": 20494, "speaker": "Michael Scott"},
  {"season": 4, "episode": 14, "scene": 64, "line": "...Not to. No, I did not. I did not cheat on you. I did not.", "order": 20495, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 14, "scene": 64, "line": "Well, okay...", "order": 20496, "speaker": "Michael Scott"},
  {"season": 4, "episode": 14, "scene": 64, "line": "Yeah.", "order": 20497, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 14, "scene": 64, "line": "So it's mine, and it's not somebody else's, so... I know... the whole toilet seat thing is a myth, so...", "order": 20498, "speaker": "Michael Scott"},
  {"season": 4, "episode": 14, "scene": 64, "line": "I went to a sperm bank.", "order": 20499, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 14, "scene": 64, "line": "You did?", "order": 20500, "speaker": "Michael Scott"},
  {"season": 4, "episode": 14, "scene": 64, "line": "Yuh-huh.", "order": 20501, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 14, "scene": 64, "line": "When we were going out?", "order": 20502, "speaker": "Michael Scott"},
  {"season": 4, "episode": 14, "scene": 64, "line": "Uh, yeah.", "order": 20503, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 14, "scene": 64, "line": "W-I don't understand. You always used to be very cautious... I'd wear two condoms.", "order": 20504, "speaker": "Michael Scott"},
  {"season": 4, "episode": 14, "scene": 64, "line": "I know.", "order": 20505, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 14, "scene": 64, "line": "You'd rather have somebody else's sperm than my sperm?", "order": 20506, "speaker": "Michael Scott"},
  {"season": 4, "episode": 14, "scene": 64, "line": "No, no no... it's not just any sperm bank. I mean, it's really... this is a really, really great place. It's amazing, actually. I'm gonna bring you the catalogue. You should look through it. It's... and it's-in fact, it's right next to that little breakfast place that you like in the city where you can draw on the tables.", "order": 20507, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 14, "scene": 64, "line": "IHop.", "order": 20508, "speaker": "Michael Scott"},
  {"season": 4, "episode": 14, "scene": 64, "line": "IHop.", "order": 20509, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 14, "scene": 65, "line": "If I was 22, and I had lots of time to have lots of children, then sure, let's let Michael have a shot at one of 'em. But, honestly, I need to make this one count.", "order": 20510, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 14, "scene": 66, "line": "Oh, that's really good. I feel so much better. I just needed to get all that out onto the table.", "order": 20511, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 14, "scene": 66, "line": "I'm glad you told me.", "order": 20512, "speaker": "Michael Scott"},
  {"season": 4, "episode": 14, "scene": 66, "line": "Look, I don't know what you're gonna be doing tomorrow, but I have my Lamaze class in Allentown, and um... you could come. I usually, you know, use a foam noodle instead of a partner...", "order": 20513, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 14, "scene": 66, "line": "Um, I'll... I have to think about it.", "order": 20514, "speaker": "Michael Scott"},
  {"season": 4, "episode": 14, "scene": 66, "line": "Okay.", "order": 20515, "speaker": "Jan Levinson"},
  {"season": 4, "episode": 14, "scene": 67, "line": "My whole life, I have known two things: I love sex, and I want to have kids. And I always thought that those two things would go hand in hand, but now, I think it might be one or the other.", "order": 20516, "speaker": "Michael Scott"},
  {"season": 4, "episode": 14, "scene": 68, "line": "Oh look, they're starting. [all watch fireworks display; scattered applause and cheers; Darryl's band plays soft music]", "order": 20517, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 14, "scene": 69, "line": "[to Phyllis] Well, I hope you had fun today, because you're never ever throwing a party again.", "order": 20518, "speaker": "Angela Martin"},
  {"season": 4, "episode": 14, "scene": 70, "line": "[gets engagement ring from his pocket] Hey...", "order": 20519, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 14, "scene": 70, "line": "[on microphone] Can I have your attention please? [music stops] I was waiting for the right time to do this, and I can't think of a better time than right now, with the music playing, and all our friends around, and fireworks going off... My parents are here! Andrew and Ellen Bernard. Thank you for sharing in this joyous moment. Miss Angela Martin, will you please join me onstage? [Angela shakes her head 'no'] Okay, then I will come to you, my flower. [knocks over Darryl's keyboard]", "order": 20520, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 14, "scene": 70, "line": "Unh! Damn it!", "order": 20521, "speaker": "Darryl Philbin"},
  {"season": 4, "episode": 14, "scene": 70, "line": "Angela, will you do me the honor... of giving me your tiny hand in marriage? [shows her a ring]", "order": 20522, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 14, "scene": 70, "line": "...Okay.", "order": 20523, "speaker": "Angela Martin"},
  {"season": 4, "episode": 14, "scene": 70, "line": "Into the mic, sweetie.", "order": 20524, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 14, "scene": 70, "line": "I said, okay.", "order": 20525, "speaker": "Angela Martin"},
  {"season": 4, "episode": 14, "scene": 70, "line": "She said yes! [light applause] And the crowd goes wild! Woo! [music starts playing; Jim puts his ring back in his pocket and exhales sharply]", "order": 20526, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 14, "scene": 71, "line": "I've been carrying that ring around in my wallet for six years. Because you don't know when you're gonna meet the right girl and the moment's gonna be right. And tonight, with the fireworks, and the music, and everything... it was right.", "order": 20527, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 14, "scene": 72, "line": "Can I be your bridesmaid?", "order": 20528, "speaker": "Kelly Kapoor"},
  {"season": 4, "episode": 14, "scene": 72, "line": "No.", "order": 20529, "speaker": "Angela Martin"},
  {"season": 4, "episode": 14, "scene": 73, "line": "Well... it's my own fault.", "order": 20530, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 14, "scene": 74, "line": "Tuna! [hugs Jim; makes small explosion sound] I'm engaged!", "order": 20531, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 14, "scene": 74, "line": "I know. That's awesome, man. That's great.", "order": 20532, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 14, "scene": 74, "line": "Mr. Andrew Bernard... it's got a nice ring to it.", "order": 20533, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 14, "scene": 75, "line": "Hey. Hey, you know, I just realized uh, I don't have a picture of the two of us.", "order": 20534, "speaker": "Toby Flenderson"},
  {"season": 4, "episode": 14, "scene": 75, "line": "Oh, yeah, um...", "order": 20535, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 14, "scene": 75, "line": "Yeah, could we...", "order": 20536, "speaker": "Toby Flenderson"},
  {"season": 4, "episode": 14, "scene": 75, "line": "Sure. Meredith, do you mind?", "order": 20537, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 14, "scene": 75, "line": "[hands Meredith his camera] This one right here.", "order": 20538, "speaker": "Toby Flenderson"},
  {"season": 4, "episode": 14, "scene": 75, "line": "Okay. One two three- [takes picture] Oh let's do it again. Pam your smile is weird.", "order": 20539, "speaker": "Meredith Palmer"},
  {"season": 4, "episode": 14, "scene": 75, "line": "It's digital, so just take as many as you want.", "order": 20540, "speaker": "Toby Flenderson"},
  {"season": 4, "episode": 14, "scene": 75, "line": "One, two three- [takes picture] One, two, three- [takes picture]", "order": 20541, "speaker": "Meredith Palmer"},
  {"season": 4, "episode": 14, "scene": 76, "line": "I don't know, I just, I really thought Jim was gonna propose tonight...", "order": 20542, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 14, "scene": 77, "line": "Hey there.", "order": 20543, "speaker": "Holly Flax"},
  {"season": 4, "episode": 14, "scene": 77, "line": "Hey.", "order": 20544, "speaker": "Michael Scott"},
  {"season": 4, "episode": 14, "scene": 77, "line": "So you missed something really big.", "order": 20545, "speaker": "Holly Flax"},
  {"season": 4, "episode": 14, "scene": 77, "line": "Yeah?", "order": 20546, "speaker": "Michael Scott"},
  {"season": 4, "episode": 14, "scene": 77, "line": "Yeah, Andy proposed to one of your accountants.", "order": 20547, "speaker": "Holly Flax"},
  {"season": 4, "episode": 14, "scene": 77, "line": "Oh, wow.", "order": 20548, "speaker": "Michael Scott"},
  {"season": 4, "episode": 14, "scene": 77, "line": "I'm not so specific as I can be on my first day, but...", "order": 20549, "speaker": "Holy"},
  {"season": 4, "episode": 14, "scene": 77, "line": "Well, I can see Andy proposing to Angela. I can also see him proposing to Oscar. [they chuckle] Oh...", "order": 20550, "speaker": "Michael Scott"},
  {"season": 4, "episode": 14, "scene": 77, "line": "So... You know, somehow after all those ribs, I'm still really hungry. I don't know, I was thinking of maybe going off-campus somewhere, getting some dessert, or...", "order": 20551, "speaker": "Holly Flax"},
  {"season": 4, "episode": 14, "scene": 77, "line": "Oh, um, well, you know what? You should go to the Glider Diner. Ask Stanley about that. Practically lives there.", "order": 20552, "speaker": "Michael Scott"},
  {"season": 4, "episode": 14, "scene": 77, "line": "Okay.", "order": 20553, "speaker": "Holly Flax"},
  {"season": 4, "episode": 14, "scene": 77, "line": "I'll go to the diner with you.", "order": 20554, "speaker": "Kevin Malone"},
  {"season": 4, "episode": 14, "scene": 77, "line": "Oh that would be great.", "order": 20555, "speaker": "Holly Flax"},
  {"season": 4, "episode": 14, "scene": 77, "line": "Yeah, we can go eat pie.", "order": 20556, "speaker": "Kevin Malone"},
  {"season": 4, "episode": 14, "scene": 77, "line": "I love pie.", "order": 20557, "speaker": "Holly Flax"},
  {"season": 4, "episode": 14, "scene": 77, "line": "Me too.", "order": 20558, "speaker": "Kevin Malone"},
  {"season": 4, "episode": 14, "scene": 77, "line": "Okay.", "order": 20559, "speaker": "Holly Flax"},
  {"season": 4, "episode": 14, "scene": 77, "line": "Have a good night.", "order": 20560, "speaker": "Michael Scott"},
  {"season": 4, "episode": 14, "scene": 77, "line": "Okay. Good night.", "order": 20561, "speaker": "Holly Flax"},
  {"season": 4, "episode": 14, "scene": 77, "line": "Bye.", "order": 20562, "speaker": "Michael Scott"},
  {"season": 4, "episode": 14, "scene": 78, "line": "Yeah, it was a good day. I mean, first days are always the hardest, right? Well, I should go. [Kevin is waiting in the car] I gotta buckle him in.", "order": 20563, "speaker": "Holly Flax"},
  {"season": 4, "episode": 14, "scene": 79, "line": "Well this is it. I am here to see you off.", "order": 20564, "speaker": "Michael Scott"},
  {"season": 4, "episode": 14, "scene": 79, "line": "Thanks... [Hank is standing next to Michael] Really, Michael?", "order": 20565, "speaker": "Toby Flenderson"},
  {"season": 4, "episode": 14, "scene": 79, "line": "Sorry. Corporate policy.", "order": 20566, "speaker": "Michael Scott"},
  {"season": 4, "episode": 14, "scene": 79, "line": "It's not.", "order": 20567, "speaker": "Toby Flenderson"},
  {"season": 4, "episode": 14, "scene": 79, "line": "You might take something.", "order": 20568, "speaker": "Michael Scott"},
  {"season": 4, "episode": 14, "scene": 79, "line": "I don't think he's gonna take anything.", "order": 20569, "speaker": "Hank Tate"},
  {"season": 4, "episode": 14, "scene": 79, "line": "Okay, security guard.", "order": 20570, "speaker": "Michael Scott"},
  {"season": 4, "episode": 14, "scene": 80, "line": "[watch that Michael gave him beeps] Oh, some kind of... alarm.", "order": 20571, "speaker": "Toby Flenderson"},
  {"season": 4, "episode": 14, "scene": 80, "line": "Okay.", "order": 20572, "speaker": "Michael Scott"},
  {"season": 4, "episode": 14, "scene": 81, "line": "[on phone, leaving a message] Hi, Jan, it's Michael. I just um, I just want to let you know that I am going to go to your Lamaze class tomorrow. Um, and if there's any details you need to fill me in on, like... what exactly Lamaze is, that would be great. And I... will see you tomorrow morning. [hangs up] I am going to be... kind of a daddy.", "order": 20573, "speaker": "Michael Scott"},
  {"season": 4, "episode": 14, "scene": 82, "line": "Party planning's a real high. Like a runner's high. [gets out of elevator; hears moans and heavy breathing coming from office; opens door and sees Dwight and Angela making out]", "order": 20574, "speaker": "Phyllis Vance"},
  {"season": 4, "episode": 14, "scene": 82, "line": "[sees Phyllis; gasps] Oh my God!", "order": 20575, "speaker": "Angela Martin"},
  {"season": 4, "episode": 14, "scene": 82, "line": "What? [turns and sees Phyllis; Phyllis gasps and drops the box in her hands]", "order": 20576, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 14, "scene": 83, "line": "This is Jim Halpert...", "order": 20577, "speaker": "Toby Flenderson"},
  {"season": 4, "episode": 14, "scene": 83, "line": "Hi.", "order": 20578, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 14, "scene": 83, "line": "And Pam Beesly.", "order": 20579, "speaker": "Toby Flenderson"},
  {"season": 4, "episode": 14, "scene": 83, "line": "Oh, Pam! Toby says such nice things about you.", "order": 20580, "speaker": "Holly Flax"},
  {"season": 4, "episode": 14, "scene": 83, "line": "[to Toby] Thank you!", "order": 20581, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 14, "scene": 83, "line": "He didn't mention me, did he?", "order": 20582, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 14, "scene": 83, "line": "Oh, no, he said you were a really good salesman.", "order": 20583, "speaker": "Holly Flax"},
  {"season": 4, "episode": 14, "scene": 83, "line": "Thanks, Toby.", "order": 20584, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 14, "scene": 83, "line": "You're welcome.", "order": 20585, "speaker": "Toby Flenderson"},
  {"season": 4, "episode": 14, "scene": 84, "line": "I've always gotten along well with Toby. Uh, we're friends. But I don't think we'll stay in touch necessarily. I think I'll probably get updates on him from Pam.", "order": 20586, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 14, "scene": 85, "line": "Oh, I think that makes us neighbors.", "order": 20587, "speaker": "Holly Flax"},
  {"season": 4, "episode": 14, "scene": 85, "line": "It's a couple streets over.", "order": 20588, "speaker": "Meredith Palmer"},
  {"season": 4, "episode": 14, "scene": 85, "line": "Oh, maybe you can show me some of the fun hangouts, and where to get my nails done.", "order": 20589, "speaker": "Holly Flax"},
  {"season": 4, "episode": 14, "scene": 85, "line": "Sure! That sounds fun!", "order": 20590, "speaker": "Meredith Palmer"},
  {"season": 4, "episode": 14, "scene": 85, "line": "Great.", "order": 20591, "speaker": "Holly Flax"},
  {"season": 4, "episode": 14, "scene": 86, "line": "I hate that bitch. I wanna cut her face. [Dwight walks by and hears]", "order": 20592, "speaker": "Meredith Palmer"},
  {"season": 4, "episode": 14, "scene": 87, "line": "Stop! Don't look at me. On the counter you will find bread, peanut butter, and jelly. Pretend to make yourself a sandwich.", "order": 20593, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 14, "scene": 87, "line": "Pretend? Or really make one?", "order": 20594, "speaker": "Meredith Palmer"},
  {"season": 4, "episode": 14, "scene": 87, "line": "Really make one.", "order": 20595, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 14, "scene": 87, "line": "Make your own [bleep]in' sandwich.", "order": 20596, "speaker": "Meredith Palmer"},
  {"season": 4, "episode": 14, "scene": 87, "line": "Will you please? Make a sandwich. Now, according to my sources, you have an issue with the new H.R. woman. Well, so do I. And so does our secret benefactor. That's too much peanut butter. What I propose is a good old-fashioned hazing, and I need your help. If you choose to accept this mission...Wipe the knife off before you stick it in the jelly. Have you ever made a sandwich before? Dammit, Meredith!", "order": 20597, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 14, "scene": 88, "line": "[on phone] Well, who can do a party of this size? But you're in the business, you must surely know of some other businesses. Okay, well, I JUST WANT A DAMN DANCE FLOOR! Sorry. So sorry, I shouldn't have sworn, I'm so sorry. Um, thanks for your time. [knock on door] What? Sorry, I shouldn't have said 'what' like that, it was really rude.", "order": 20598, "speaker": "Phyllis Vance"},
  {"season": 4, "episode": 14, "scene": 88, "line": "Scared me. How's the party planning going?", "order": 20599, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 14, "scene": 88, "line": "Jim, I am so f[bleep]ed.", "order": 20600, "speaker": "Phyllis Vance"},
  {"season": 4, "episode": 14, "scene": 88, "line": "So it's going great, sounds like.", "order": 20601, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 14, "scene": 88, "line": "I don't know, I'm not used to the stress.", "order": 20602, "speaker": "Phyllis Vance"},
  {"season": 4, "episode": 14, "scene": 89, "line": "When I was growing up, my mom said, 'the sky's the limit.' I could do anything. Be a teacher's aide, nurse's assistant, some kind of volunteer. But now, I, I'm not so sure.", "order": 20603, "speaker": "Phyllis Vance"},
  {"season": 4, "episode": 14, "scene": 90, "line": "This is a picture I've been carrying around in my wallet for about six years. It's this unspoiled beach in Costa Rica, Playa Grande. Um, I take it out when I get stressed or depressed, and it really calms me down. Never thought I'd get to live here, you know. But it turns out they built these great new condos there, so, this could be me, right around, uh, where the trees used to be.", "order": 20604, "speaker": "Toby Flenderson"},
  {"season": 4, "episode": 14, "scene": 91, "line": "Hey.", "order": 20605, "speaker": "Toby Flenderson"},
  {"season": 4, "episode": 14, "scene": 91, "line": "Hey.", "order": 20606, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 14, "scene": 91, "line": "Hey.", "order": 20607, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 14, "scene": 91, "line": "Hey.", "order": 20608, "speaker": "Toby Flenderson"},
  {"season": 4, "episode": 14, "scene": 91, "line": "Did you just buy that?", "order": 20609, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 14, "scene": 91, "line": "Yeah, I guess it will be my, uh, thing in Costa Rica, be a nature photographer.", "order": 20610, "speaker": "Toby Flenderson"},
  {"season": 4, "episode": 14, "scene": 91, "line": "That's cool.", "order": 20611, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 14, "scene": 91, "line": "Yeah. Um, you mind if I get a picture?", "order": 20612, "speaker": "Toby Flenderson"},
  {"season": 4, "episode": 14, "scene": 91, "line": "Sure!", "order": 20613, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 14, "scene": 91, "line": "Think you could...[wants to hand the camera to Jim, but Jim is starting to snuggle up with Pam, posing. Toby aims the camera, the flash pops up] Okay. [takes picture] Okay.", "order": 20614, "speaker": "Toby Flenderson"},
  {"season": 4, "episode": 14, "scene": 91, "line": "Good one? [Toby puts on the lens cover and scurries away awkwardly] That's a cool camera.", "order": 20615, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 14, "scene": 92, "line": "Okay, this is the second time in two days that the website isn't working. Do you guys know anything about it?", "order": 20616, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 14, "scene": 92, "line": "The website is hiding, forming alliances with other sites, preparing an attack for which we will have no defense.", "order": 20617, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 14, "scene": 92, "line": "Do you honestly believe that?", "order": 20618, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 14, "scene": 92, "line": "Yes, I do.", "order": 20619, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 14, "scene": 92, "line": "I'm gonna get a second opinion.", "order": 20620, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 14, "scene": 92, "line": "It was down for a couple hours yesterday, then back up, and now I've heard from a very reliable source that it is once again out of commission. And that reliable source is you.", "order": 20621, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 14, "scene": 92, "line": "Okay, you could just say you don't know what's going on.", "order": 20622, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 14, "scene": 92, "line": "Um, I do, 'cause I just told you.", "order": 20623, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 14, "scene": 93, "line": "Is Ryan gonna fire me? I don't know. I know that I've done everything I can, and I just brought in a huge sale, and... am I insane, or was this kid a temp two years ago? Because in the three years that he's been here, I think all he's ever done is start a fire and grow a beard. And it's not even a good beard.", "order": 20624, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 14, "scene": 94, "line": "Troy Undercook, for Michael.", "order": 20625, "speaker": "TRoy Anderson"},
  {"season": 4, "episode": 14, "scene": 94, "line": "Ahh! I'm sorry, I didn't, I didn't see you come in.", "order": 20626, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 14, "scene": 94, "line": "Troy! What are you doing here?", "order": 20627, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 14, "scene": 94, "line": "Just filling in for Ryan, as a favor.", "order": 20628, "speaker": "TRoy Anderson"},
  {"season": 4, "episode": 14, "scene": 94, "line": "Where is Ryan?", "order": 20629, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 14, "scene": 94, "line": "He had an urgent matter in New York.", "order": 20630, "speaker": "TRoy Anderson"},
  {"season": 4, "episode": 14, "scene": 94, "line": "Welcome to our land.", "order": 20631, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 14, "scene": 94, "line": "Dwight. [to Troy] Michael will be out in a second.", "order": 20632, "speaker": "Pam Beesly"},
  {"season": 4, "episode": 14, "scene": 94, "line": "Can I get you a tankard of mead?", "order": 20633, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 14, "scene": 94, "line": "Hey, Troy, can you do me a huge favor? Next time you see Ryan, can you tell him to check his messages? That'd be great.", "order": 20634, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 14, "scene": 94, "line": "If I see him, I'll tell him.", "order": 20635, "speaker": "TRoy Anderson"},
  {"season": 4, "episode": 14, "scene": 94, "line": "Why wouldn't you see him? You work for him.", "order": 20636, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 14, "scene": 94, "line": "I report to Wallace now.", "order": 20637, "speaker": "TRoy Anderson"},
  {"season": 4, "episode": 14, "scene": 95, "line": "Okay, what's going on?", "order": 20638, "speaker": "Jim Halpert"},
  {"season": 4, "episode": 14, "scene": 96, "line": "Looks serious.", "order": 20639, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 14, "scene": 96, "line": "What's a...", "order": 20640, "speaker": "Someone"},
  {"season": 4, "episode": 14, "scene": 96, "line": "Oh, my God.", "order": 20641, "speaker": "Michael Scott"},
  {"season": 4, "episode": 14, "scene": 96, "line": "Troy, do you know anything about this?", "order": 20642, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 14, "scene": 96, "line": "Maybe I do.", "order": 20643, "speaker": "TRoy Anderson"},
  {"season": 4, "episode": 14, "scene": 96, "line": "Maybe I do. Enough of your magical riddles!", "order": 20644, "speaker": "Dwight Schrute"},
  {"season": 4, "episode": 14, "scene": 97, "line": "And if the food stations are here and here... no! Here and here. Drinks here, then people can mingle. But then where would the band go?", "order": 20645, "speaker": "Phyllis Vance"},
  {"season": 4, "episode": 14, "scene": 97, "line": "Mmm. Well, what about there?", "order": 20646, "speaker": "Bob Vance"},
  {"season": 4, "episode": 14, "scene": 97, "line": "Mmm, no Bob, I don't know.", "order": 20647, "speaker": "Phyllis Vance"},
  {"season": 4, "episode": 14, "scene": 98, "line": "It's times like these, I wish my mother were with me right now. She'd show me what to do. That's stupid; she's not here. [looks at watch] She's at water aerobics for the next forty-five minutes.", "order": 20648, "speaker": "Phyllis Vance"},
  {"season": 4, "episode": 14, "scene": 99, "line": "Why don't we eat more corn dogs? They're delicious.", "order": 20649, "speaker": "Stanley Hudson"},
  {"season": 4, "episode": 14, "scene": 99, "line": "Totally, they're the best.", "order": 20650, "speaker": "Oscar Martinez"},
  {"season": 4, "episode": 14, "scene": 99, "line": "Corn dogs should be the standard. Corn dogs should be called hot dogs, and hot dogs should be called bad dogs.", "order": 20651, "speaker": "Stanley Hudson"},
  {"season": 4, "episode": 14, "scene": 99, "line": "Why don't we make corn burgers?", "order": 20652, "speaker": "Oscar Martinez"},
  {"season": 4, "episode": 14, "scene": 99, "line": "[chuckling] What a messed up world.", "order": 20653, "speaker": "Stanley Hudson"},
  {"season": 4, "episode": 14, "scene": 99, "line": "Seriously, do you like my idea? Corn burgers.", "order": 20654, "speaker": "Oscar Martinez"},
  {"season": 4, "episode": 14, "scene": 100, "line": "I need you to make an announcement that this party is a disaster.", "order": 20655, "speaker": "Angela Martin"},
  {"season": 4, "episode": 14, "scene": 100, "line": "But I don't think it's a disaster. I think it's fun, and I like the food.", "order": 20656, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 14, "scene": 100, "line": "Deep fried Twinkies? It's gross!", "order": 20657, "speaker": "Angela Martin"},
  {"season": 4, "episode": 14, "scene": 100, "line": "Is that what that is?", "order": 20658, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 14, "scene": 100, "line": "Yes.", "order": 20659, "speaker": "Angela Martin"},
  {"season": 4, "episode": 14, "scene": 100, "line": "You know what, I didn't try those. [reaches for one] I could try this. Augh! Fried Twinkies, you kidding me? Starting to wonder about the food in this place [takes a bite]. Oh, God! That is so good.", "order": 20660, "speaker": "Andy Bernard"},
  {"season": 4, "episode": 14, "scene": 101, "line": "When I had my colitis, Toby was very helpful. He gave me seven weeks off. When I had my acid reflux, Toby was not as helpful. So I'm mixed on Toby.", "order": 20661, "speaker": "Stanley Hudson"},
  {"season": 4, "episode": 14, "scene": 102, "line": "Toby Flenderson, come on up here.", "order": 20662, "speaker": "Michael Scott"},
  {"season": 4, "episode": 14, "scene": 102, "line": "Uh, no, that's okay.", "order": 20663, "speaker": "Toby Flenderson"},
  {"season": 4, "episode": 14, "scene": 102, "line": "Come on, no, no, no.", "order": 20664, "speaker": "Michael Scott"},
  {"season": 4, "episode": 14, "scene": 102, "line": "Toby! Come on, Toby!", "order": 20665, "speaker": "Co-workers"},
  {"season": 4, "episode": 14, "scene": 102, "line": "Toby!", "order": 20666, "speaker": "Michael Scott"},
  {"season": 4, "episode": 14, "scene": 102, "line": "Toby! Toby! Toby!", "order": 20667, "speaker": "Co-workers"},
  {"season": 4, "episode": 14, "scene": 102, "line": "Okay, okay, I'm going.", "order": 20668, "speaker": "Toby Flenderson"},
  {"season": 4, "episode": 14, "scene": 102, "line": "Toby has written a fantastic speech for you, he spent a year on it. It's very funny and charming and heartwarming. And it rhymes, and if it's not any good, he has instructed us all to storm the stage and beat him to a bloody pulp. [laughs] No! So, take it away, Toby! Whoo!", "order": 20669, "speaker": "Michael Scott"},
  {"season": 4, "episode": 14, "scene": 102, "line": "Well, uh, okay. Um. It's really great to be here tonight, with the Ferris wheel, the band, and the low sunlight. I'm gonna miss you all when I go to Costa Rica, and if I keep drinking beer like this, I'm gonna have to take a leak-a. [laughter from audience, Michael looks at camera unhappily] Look, on a more personal note, I just want to say thanks, you know, no matter what was going on in my life, you know, my divorce, or the custody battle, or that thing with my sister, it was just nice knowing I had a, a supportive place to come to where...", "order": 20670, "speaker": "Toby Flenderson"},
  {"season": 4, "episode": 14, "scene": 102, "line": "Ach, [to Darryl quietly] okay, okay, play the cutoff music.", "order": 20671, "speaker": "Michael Scott"},
  {"season": 4, "episode": 14, "scene": 102, "line": "No.", "order": 20672, "speaker": "Darryl Philbin"},
  {"season": 4, "episode": 14, "scene": 102, "line": "I'm gonna really miss going to beers with you guys every Tuesday night [Pam holds up soda in salute, Meredith yells 'yeah!', Michael looks at camera] and lastly, I'd love to urge you to keep up with my fight to have the building checked for radon gas. You know, it's a, it's a real serious matter, and every time I try to have the, uh...", "order": 20673, "speaker": "Toby Flenderson"},
  {"season": 4, "episode": 14, "scene": 102, "line": "Blah, blah, blah, blah...", "order": 20674, "speaker": "Michael Scott"},
  {"season": 4, "episode": 14, "scene": 102, "line": "...building looked into...", "order": 20675, "speaker": "Toby Flenderson"},
  {"season": 4, "episode": 14, "scene": 102, "line": "...blah, blah, overstaying your welcome! Let's keep it moving! Let's keep it moving! Toby, everybody!", "order": 20676, "speaker": "Michael Scott"},
  {"season": 4, "episode": 14, "scene": 103, "line": "I gave Toby my buddy Jorge's number in Costa. He'll hook Toby up with everything: cable TV, women, oh, and this amazing coffee to snort.", "order": 20677, "speaker": "Creed Bratton"}
]