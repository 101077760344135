module.exports = [
  {"season": 9, "episode": 1, "scene": 1, "line": "Andy's coming back today! Andy's coming back today! Ha! Andy's coming back today. David Wallace sent him on an outward bound wilderness adventure for a whole month to make him more decisive and confident. He sent his own son too. And the counselors said they both grew up, big time.", "order": 50985, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 1, "scene": 2, "line": "How was my summer? It was pretty mixed. I invented a new power drink made out of beet runoff. Mmm, mmm! So that's really good. But I got some disappointing medical news.", "order": 50986, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 1, "scene": 3, "line": "Mr. Schrute, the results are in. You are not the father.", "order": 50987, "speaker": "Doctor"},
  {"season": 9, "episode": 1, "scene": 3, "line": "I told you [Dwight vomits power drink on Angela]", "order": 50988, "speaker": "Angela Martin"},
  {"season": 9, "episode": 1, "scene": 4, "line": "Oh, what a summer! An emotional roller-coaster. I ran over a turtle in the parking lot, but then I saved him by gluing his shell back together. But I'm not that good at puzzles.", "order": 50989, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 1, "scene": 5, "line": "[as Kevin reassembles shell] That piece doesn't go there. [Kevin shushes him]", "order": 50990, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 1, "scene": 6, "line": "So I patched him with stuff from around the office. But I couldn't get the pieces to fit right. Then one day, when I was reaching for the glue, I crushed his shell again. But I rebuilt him even better that time. But it turned out the turtle was already day. Probably when I ran over him the first time.", "order": 50991, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 1, "scene": 7, "line": "For us, um, nothing new, really the kids are great.", "order": 50992, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 1, "scene": 7, "line": "You are really good at modesty. She's a genius. She painted this incredible mural in the kids' room. There's a hippo leading a parade of 500 children'", "order": 50993, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 1, "scene": 7, "line": "Kay, well, you had that interesting thing.", "order": 50994, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 1, "scene": 7, "line": "Oh, yeah", "order": 50995, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 1, "scene": 7, "line": "Yes, Jim's friend is starting anew company based on this idea Jim had when they were back in college.", "order": 50996, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 1, "scene": 7, "line": "Its sports marketing basically. But the athletes are partners.", "order": 50997, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 1, "scene": 7, "line": "And he wanted Jim to be a part of it too.", "order": 50998, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 1, "scene": 7, "line": "In Philly, so, that doesn't really work for the family. Watch this guy make a billion dollars off my idea.", "order": 50999, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 1, "scene": 7, "line": "He said if it takes off he's gonna buy us a new car.", "order": 51000, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 1, "scene": 7, "line": "An Altima or better.", "order": 51001, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 1, "scene": 8, "line": "Kelly Kapoor is gone. Her fianc Ravi was hired as a pediatrics professor at Miami university.", "order": 51002, "speaker": "Toby Flenderson"},
  {"season": 9, "episode": 1, "scene": 8, "line": "[tossing out winter coats] I don't need em anymore. I am going to Miami biotches. To hang with Lebron James and Gloria Estefan.", "order": 51003, "speaker": "Kelly Kapoor"},
  {"season": 9, "episode": 1, "scene": 9, "line": "Miami University in Ohio. On her last day, Kelly was still a little confused about it", "order": 51004, "speaker": "Toby Flenderson"},
  {"season": 9, "episode": 1, "scene": 10, "line": "Enjoy the snow losers.", "order": 51005, "speaker": "Kelly Kapoor"},
  {"season": 9, "episode": 1, "scene": 10, "line": "I'm so happy for you Kelly.", "order": 51006, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 1, "scene": 11, "line": "Shortly after Kelly left, Ryan quit and also moved to Ohio, for what he claimed were unrelated reasons.", "order": 51007, "speaker": "Toby Flenderson"},
  {"season": 9, "episode": 1, "scene": 12, "line": "I've actually done a lot of market research and it actually turns out that southwestern Ohio is going to be the next Silicon Valley. They call it the Silicon Prairie. It's a big university town. And, uh, that's not garbage, it's out clothes.", "order": 51008, "speaker": "Ryan Howard"},
  {"season": 9, "episode": 1, "scene": 13, "line": "And that was our summer.", "order": 51009, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 1, "scene": 13, "line": "we good?", "order": 51010, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 1, "scene": 13, "line": "Yeah", "order": 51011, "speaker": "Off-camera"},
  {"season": 9, "episode": 1, "scene": 13, "line": "Don't you guys have everything. I mean, its just a paper company.", "order": 51012, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 1, "scene": 13, "line": "Well we're more following you guys, to see how you turn out.", "order": 51013, "speaker": "Off-camera"},
  {"season": 9, "episode": 1, "scene": 13, "line": "Oh, yeah, because we were kind of dramatic in the beginning. Well I don't think anything's gonna change in our lives now. With work and two kids there's just-nothing interesting is going to happen for us in a long, long time.", "order": 51014, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 1, "scene": 14, "line": "Andy's coming back today!", "order": 51015, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 1, "scene": 15, "line": "Hey, uh, does anybody know where we throw these out?", "order": 51016, "speaker": "Clark Green"},
  {"season": 9, "episode": 1, "scene": 15, "line": "Oh, my god. It's called a garbage can.", "order": 51017, "speaker": "Stanley Hudson"},
  {"season": 9, "episode": 1, "scene": 15, "line": "Helpless.", "order": 51018, "speaker": "Phyllis Vance"},
  {"season": 9, "episode": 1, "scene": 16, "line": "There's two new guys back there with me now. They're in their 20s. And we really get along.", "order": 51019, "speaker": "Toby Flenderson"},
  {"season": 9, "episode": 1, "scene": 17, "line": "[Playing computer game with Toby] You're looking good.", "order": 51020, "speaker": "Clark Green"},
  {"season": 9, "episode": 1, "scene": 18, "line": "Just three single guys. Getting into trouble.", "order": 51021, "speaker": "Toby Flenderson"},
  {"season": 9, "episode": 1, "scene": 19, "line": "They're like the new Jim and Dwight.", "order": 51022, "speaker": "Stanley Hudson"},
  {"season": 9, "episode": 1, "scene": 19, "line": "Oh, yes, yes I see that! Perfect.", "order": 51023, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 1, "scene": 19, "line": "Hey, new Jim, come sit on my face.", "order": 51024, "speaker": "Meredith Palmer"},
  {"season": 9, "episode": 1, "scene": 19, "line": "No thank you. My name's Pete.", "order": 51025, "speaker": "Pete Miller"},
  {"season": 9, "episode": 1, "scene": 20, "line": "No, Pete is not the new Jim. The only we have in common is that neither of us wants to sit on Meredith's face. And if that makes him the new Jim, then every human being in the world is the new Jim.", "order": 51026, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 1, "scene": 21, "line": "Andy's here! Old Dwight, new Dwight, Andy's here. New Jim, Tuna, Andy's here. Pam, he's here. [squeals as Andy walks in]", "order": 51027, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 1, "scene": 21, "line": "Hey! Wow, I thought about this place a lot when I was on solo. Three days on a mountain, it'll change you. The things we think we need. Clocks, yeah right!", "order": 51028, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 1, "scene": 21, "line": "It sounds amazing, tell us all about it.", "order": 51029, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 1, "scene": 21, "line": "What're you still doing here?", "order": 51030, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 1, "scene": 21, "line": "Wonderful, thanks.", "order": 51031, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 1, "scene": 21, "line": "You know I had this really funny dream during Outward Bound that you died.", "order": 51032, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 1, "scene": 21, "line": "Brilliant.", "order": 51033, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 1, "scene": 21, "line": "See me in my office later. The new guys, alright. It's Clark and, um'", "order": 51034, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 1, "scene": 21, "line": "Pete!", "order": 51035, "speaker": "Pete Miller"},
  {"season": 9, "episode": 1, "scene": 21, "line": "In Outward Bound it was all about nicknames. They called me Iceman. You will be called Plop.", "order": 51036, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 1, "scene": 21, "line": "What? Why?", "order": 51037, "speaker": "Pete Miller"},
  {"season": 9, "episode": 1, "scene": 21, "line": "Cause you're always taking dumps.", "order": 51038, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 1, "scene": 21, "line": "No I'm not.", "order": 51039, "speaker": "Pete Miller"},
  {"season": 9, "episode": 1, "scene": 21, "line": "Come on, everybody defecates. Relax, Plop. And you will be called Fart cause you fart all the time.", "order": 51040, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 1, "scene": 21, "line": "I love it.", "order": 51041, "speaker": "Clark Green"},
  {"season": 9, "episode": 1, "scene": 21, "line": "Actually, Andy, we call this one Dwight, Jr.", "order": 51042, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 1, "scene": 21, "line": "No, I prefer Fart.", "order": 51043, "speaker": "Clark Green"},
  {"season": 9, "episode": 1, "scene": 21, "line": "No, Dwight Jr. Infinitely better. You guys look exactly alike. Dwight go stand next to him. This is insane.", "order": 51044, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 1, "scene": 21, "line": "I don't see it.", "order": 51045, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 1, "scene": 21, "line": "I don't either.", "order": 51046, "speaker": "Clark Green"},
  {"season": 9, "episode": 1, "scene": 21, "line": "Whoa! Mind blown. It's like father and son! Dwight, you cool if we call him Dwight Jr?", "order": 51047, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 1, "scene": 21, "line": "Yes, yes I am. [puts his arm around Clark]", "order": 51048, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 1, "scene": 21, "line": "Dwight, Jr! [Dwight gives Clark a noogie]", "order": 51049, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 1, "scene": 22, "line": "In a way it's like I have a son. And who knows? Maybe someday they'll hire someone who looks like a younger version of him. And then I'll have a grandson.", "order": 51050, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 1, "scene": 23, "line": "Well, I need to give my cat up for adoption.", "order": 51051, "speaker": "Angela Martin"},
  {"season": 9, "episode": 1, "scene": 23, "line": "The one who uses the doorbell, or the one with the Mexican hat, or the one with the rain galoshes, or the one you let go around naked?", "order": 51052, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 1, "scene": 24, "line": "Angela's cats are cute. So cute that you just want to eat them. But you can't eat cats. You can't eat cats, Kevin.", "order": 51053, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 1, "scene": 25, "line": "No, the one with the long hair and the denim pants, Comstock! Ok, look [starts playing video on computer]. He's such a special kitty. I just want to find him a good home. He loves those pants", "order": 51054, "speaker": "Angela Martin"},
  {"season": 9, "episode": 1, "scene": 25, "line": "I'll take him.", "order": 51055, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 1, "scene": 25, "line": "Please, after the turtle?", "order": 51056, "speaker": "Angela Martin"},
  {"season": 9, "episode": 1, "scene": 25, "line": "I am enormously proud of what I did for that turtle!", "order": 51057, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 1, "scene": 25, "line": "Oscar, Oscar, will you take him?", "order": 51058, "speaker": "Angela Martin"},
  {"season": 9, "episode": 1, "scene": 25, "line": "No, I'm a dog person.", "order": 51059, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 1, "scene": 25, "line": "If you pray enough, you can change yourself into a cat person.", "order": 51060, "speaker": "Angela Martin"},
  {"season": 9, "episode": 1, "scene": 25, "line": "Those guys always change back, Angela.", "order": 51061, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 1, "scene": 26, "line": "David, outward bound was incredible. I was the best at slack lining, I ate a worm.", "order": 51062, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 1, "scene": 26, "line": "Glad to hear it. You sound very confident and decisive.", "order": 51063, "speaker": "David Wallace"},
  {"season": 9, "episode": 1, "scene": 26, "line": "Hey you wanted to see me?", "order": 51064, "speaker": "Toby Flenderson"},
  {"season": 9, "episode": 1, "scene": 26, "line": "Yeah, I gotta go David. [hangs up phone] Why is Nellie still here?", "order": 51065, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 1, "scene": 26, "line": "You can only fire Nellie for cause.", "order": 51066, "speaker": "Toby Flenderson"},
  {"season": 9, "episode": 1, "scene": 26, "line": "Mm, then I will make up a cause.", "order": 51067, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 1, "scene": 26, "line": "Except you just told me you were gonna make it up. Now if she sues, I have to testify against you.", "order": 51068, "speaker": "Toby Flenderson"},
  {"season": 9, "episode": 1, "scene": 26, "line": "Then forget I just told you that.", "order": 51069, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 1, "scene": 26, "line": "Can't. I took a course at the Weintraub Memory Academy. I sat next to this woman named Beverly Brook. She had a Greek salad for lunch. See what I mean?", "order": 51070, "speaker": "Toby Flenderson"},
  {"season": 9, "episode": 1, "scene": 26, "line": "Now I know why Michael hated you so much.", "order": 51071, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 1, "scene": 27, "line": "Good boy, getting big and strong. Snack foods!", "order": 51072, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 1, "scene": 27, "line": "Yep, body by Cheez-it", "order": 51073, "speaker": "Clark Green"},
  {"season": 9, "episode": 1, "scene": 27, "line": "Ah, humor. I have it too. I have a couple tickets to the slayer concert 10 months from now. You interested?", "order": 51074, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 1, "scene": 27, "line": "I think I'm busy that night. Or I would.", "order": 51075, "speaker": "Clark Green"},
  {"season": 9, "episode": 1, "scene": 27, "line": "Well, we're both just kinda learning as we go, aren't we?", "order": 51076, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 1, "scene": 27, "line": "Learning what?", "order": 51077, "speaker": "Clark Green"},
  {"season": 9, "episode": 1, "scene": 27, "line": "Just how this going to be, you know? You have a beautiful round head.", "order": 51078, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 1, "scene": 27, "line": "Kay.", "order": 51079, "speaker": "Clark Green"},
  {"season": 9, "episode": 1, "scene": 27, "line": "So what kind of farming you into, huh? You more of a fruit man or a root man?", "order": 51080, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 1, "scene": 27, "line": "Is this'is this like code for gay stuff? Cause if so, that's totally fine. Like, I'm fine with it. But you should know that I'm into the ladies.", "order": 51081, "speaker": "Clark Green"},
  {"season": 9, "episode": 1, "scene": 27, "line": "Spoken like a true root man.", "order": 51082, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 1, "scene": 27, "line": "You know, if you're ever swamped, I could make some sales calls for you.", "order": 51083, "speaker": "Clark Green"},
  {"season": 9, "episode": 1, "scene": 27, "line": "My calls?", "order": 51084, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 1, "scene": 27, "line": "So could you give me a list of all your clients? Or just the leads that you haven't had time to pursue yet?", "order": 51085, "speaker": "Clark Green"},
  {"season": 9, "episode": 1, "scene": 27, "line": "You want a list of my clients? You want my leads?", "order": 51086, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 1, "scene": 27, "line": "Yeah, I'll just give it a glance'", "order": 51087, "speaker": "Clark Green"},
  {"season": 9, "episode": 1, "scene": 27, "line": "Ok", "order": 51088, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 1, "scene": 27, "line": "I'll give it a read.", "order": 51089, "speaker": "Clark Green"},
  {"season": 9, "episode": 1, "scene": 27, "line": "With my leads and stuff like that?", "order": 51090, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 1, "scene": 27, "line": "Yeah.", "order": 51091, "speaker": "Clark Green"},
  {"season": 9, "episode": 1, "scene": 27, "line": "Cause you're interested in going into sales?", "order": 51092, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 1, "scene": 27, "line": "I am.", "order": 51093, "speaker": "Clark Green"},
  {"season": 9, "episode": 1, "scene": 27, "line": "Wow, that's great. That's great. Ok, good, let's talk about that, ok?", "order": 51094, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 1, "scene": 27, "line": "Ok.", "order": 51095, "speaker": "Clark Green"},
  {"season": 9, "episode": 1, "scene": 27, "line": "You're not getting my clients! Nice try.", "order": 51096, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 1, "scene": 28, "line": "Paranoid? I'm not familiar with the word. And I really don't have time to learn new words right now, ok? A pudgy 22-year-old is trying to take my job.", "order": 51097, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 1, "scene": 29, "line": "Jim, get ready for the battle of your life.", "order": 51098, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 1, "scene": 29, "line": "Done. Explain.", "order": 51099, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 1, "scene": 29, "line": "The new guys. Dwight Jr is after my job. Yeah. There is a shark hiding inside that adorable little cherub.", "order": 51100, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 1, "scene": 29, "line": "Oh, now I get what Pete was talking about at the sales meeting yesterday.", "order": 51101, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 1, "scene": 29, "line": "Wait, sales'what sales meeting?", "order": 51102, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 1, "scene": 29, "line": "The new guys called one.", "order": 51103, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 1, "scene": 29, "line": "The new guys called a sales meeting?", "order": 51104, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 1, "scene": 29, "line": "Clark ended the whole thing with a karate demonstration. Did you know there was a belt above black?", "order": 51105, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 1, "scene": 29, "line": "There's no belt above black. Is there a belt above black?", "order": 51106, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 1, "scene": 29, "line": "You should ask him. It's a color you would never expect. [Dwight storms off] Too easy.", "order": 51107, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 1, "scene": 30, "line": "Attention everyone. Comstock is still available. Oscar has been flirting with adopting him. But still hasn't given me a definite yes or no.", "order": 51108, "speaker": "Angela Martin"},
  {"season": 9, "episode": 1, "scene": 30, "line": "Definitely no.", "order": 51109, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 1, "scene": 30, "line": "Fine. For any interested candidates, I will be conducting 20 minute interviews. My ideal situation would an independently wealthy cute couple with a strong commitment to education. Black or white, I'm fine with either, but not both. [to Pam] Listen, you're in this, but you need to wow me, ok?", "order": 51110, "speaker": "Angela Martin"},
  {"season": 9, "episode": 1, "scene": 31, "line": "I wanted to talk to you about our working relationship this year. It's going to be terrible.", "order": 51111, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 1, "scene": 31, "line": "Not necessarily", "order": 51112, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 1, "scene": 31, "line": "No I'm going to make sure that it is. And if it isn't, I'm going to take immediate action to rectify that. Now, I don't like to throw around the b-word, but I'm going to be a huge bitch to you.", "order": 51113, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 1, "scene": 32, "line": "Now this is called slack lining.", "order": 51114, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 1, "scene": 33, "line": "I set up the old slack line to teach the office the skills of focus and discipline. Or in the case of certain individuals, the skills of humiliation and looking really dumb.", "order": 51115, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 1, "scene": 34, "line": "If you make it all the way across, your confidence will soar. But, if you Toby out, then you'll feel like a real Nellie. How about a hand, screw those guys. Now, these are my actual Outward Bound counselors, Rafe and Feather.", "order": 51116, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 1, "scene": 34, "line": "Iceman.", "order": 51117, "speaker": "Rafe"},
  {"season": 9, "episode": 1, "scene": 34, "line": "That's me. The powder is for your protection, Feather has permanent athlete's foot. Feather, show them how you dance upon the line of slack.", "order": 51118, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 1, "scene": 35, "line": "You a sports fan?", "order": 51119, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 1, "scene": 35, "line": "Sure, boxing, tennis.", "order": 51120, "speaker": "Pete Miller"},
  {"season": 9, "episode": 1, "scene": 35, "line": "Oh. Any team sports?", "order": 51121, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 1, "scene": 35, "line": "NASCAR. The Amazing Race.", "order": 51122, "speaker": "Pete Miller"},
  {"season": 9, "episode": 1, "scene": 35, "line": "Phillies fan, though, right?", "order": 51123, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 1, "scene": 35, "line": "You mean horses?", "order": 51124, "speaker": "Pete Miller"},
  {"season": 9, "episode": 1, "scene": 35, "line": "No, like baseball. A baseball team.", "order": 51125, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 1, "scene": 35, "line": "Ah, I like the Red Sox. I'm from Vermont.", "order": 51126, "speaker": "Pete Miller"},
  {"season": 9, "episode": 1, "scene": 35, "line": "Ok. Good talk.", "order": 51127, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 1, "scene": 36, "line": "I have nothing in common with Plop.", "order": 51128, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 1, "scene": 37, "line": "All right, who's next? Nellie?", "order": 51129, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 1, "scene": 37, "line": "Oh, I would rather watch you and your talented friends.", "order": 51130, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 1, "scene": 37, "line": "Nonsense. Get up here.", "order": 51131, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 1, "scene": 37, "line": "Go ahead", "order": 51132, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 1, "scene": 37, "line": "Come on. Uh, are you gonna wear your heels?", "order": 51133, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 1, "scene": 37, "line": "I'm very self-conscious about my feet.", "order": 51134, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 1, "scene": 37, "line": "Ok, sure. There ya go. Atta girl. Hey, alright. Whoa! [pushes Nellie off slack line] You suck. Alright, who's next? Darryl?", "order": 51135, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 1, "scene": 37, "line": "This seems like the kind of thing white people with dreadlocks do.", "order": 51136, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 1, "scene": 37, "line": "Dwight Jr? This looks like your speed.", "order": 51137, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 1, "scene": 37, "line": "Uh, I guess I could give it shot.", "order": 51138, "speaker": "Clark Green"},
  {"season": 9, "episode": 1, "scene": 37, "line": "Yeah, come on. Get up here. Just hop on up.", "order": 51139, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 1, "scene": 37, "line": "Ok", "order": 51140, "speaker": "Clark Green"},
  {"season": 9, "episode": 1, "scene": 37, "line": "Focus on the horizon. [Clark successfully walks the slack line] Whoa! Look at this guy.", "order": 51141, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 1, "scene": 38, "line": "I've always been good at anything that required balance. My doctor says I have gigantic inner ears.", "order": 51142, "speaker": "Clark Green"},
  {"season": 9, "episode": 1, "scene": 39, "line": "Go, Dwight Jr!", "order": 51143, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 1, "scene": 39, "line": "Hey, yes.", "order": 51144, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 1, "scene": 39, "line": "Boo, unimpressed. It's a tightrope for babies. Boo!", "order": 51145, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 1, "scene": 39, "line": "Alright, let's see you do it.", "order": 51146, "speaker": "Clark Green"},
  {"season": 9, "episode": 1, "scene": 39, "line": "Alright. Let me show you how a real man walks across a flaccid cord.", "order": 51147, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 1, "scene": 39, "line": "Ooh! Who ordered the hot apple fail?", "order": 51148, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 1, "scene": 39, "line": "Ok, I am just getting started, ok?", "order": 51149, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 1, "scene": 39, "line": "You got this, it's all you. [as Dwight repeatedly fails and gets smacked around] Got ourselves a yard sale. Balls in face. It's not a race, Dwight.", "order": 51150, "speaker": "Clark Green"},
  {"season": 9, "episode": 1, "scene": 39, "line": "Well it's official. Old Dwight is lame and New Dwight is cool.", "order": 51151, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 1, "scene": 39, "line": "[bleeding heavily from the mouth] That's not true. Just give me another chance.", "order": 51152, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 1, "scene": 39, "line": "But your mouth is ble'", "order": 51153, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 1, "scene": 39, "line": "This is a bloodbath. Alright, I'm calling this.", "order": 51154, "speaker": "Toby Flenderson"},
  {"season": 9, "episode": 1, "scene": 39, "line": "What?", "order": 51155, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 1, "scene": 39, "line": "It is enough of this. Everyone, let's go back to work.", "order": 51156, "speaker": "Toby Flenderson"},
  {"season": 9, "episode": 1, "scene": 39, "line": "This is a stupid activity. I would be embarrassed to be good at it. [tries to go in locked door] Idiots.", "order": 51157, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 1, "scene": 40, "line": "Slack lining, please. Untie that rope, give it to a couple of pig-tailed school girls, let them start jumping with it while chanting a rhyme and giggling about boys. Doesn't seem so macho now, does it? It's a jump rope!", "order": 51158, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 1, "scene": 41, "line": "Dwight!", "order": 51159, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 1, "scene": 41, "line": "Pam? Hey come up here, up the ladder. Come on.", "order": 51160, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 1, "scene": 41, "line": "[sees trapeze contraption] What are you doing?", "order": 51161, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 1, "scene": 41, "line": "Come here, I'll show you.", "order": 51162, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 1, "scene": 42, "line": "Why do you want this cat?", "order": 51163, "speaker": "Angela Martin"},
  {"season": 9, "episode": 1, "scene": 42, "line": "I don't want it, really.", "order": 51164, "speaker": "Pete Miller"},
  {"season": 9, "episode": 1, "scene": 42, "line": "How would you support the cat? What are your ambitions?", "order": 51165, "speaker": "Angela Martin"},
  {"season": 9, "episode": 1, "scene": 42, "line": "I want to start my own business. I want to be a millionaire. Lots of things. Travel, make the world a better place, earn an MBA at night.", "order": 51166, "speaker": "Pete Miller"},
  {"season": 9, "episode": 1, "scene": 42, "line": "Have you taken any concrete steps?", "order": 51167, "speaker": "Angela Martin"},
  {"season": 9, "episode": 1, "scene": 42, "line": "Well, I'm still just fitting in here, you know? Getting used to the new job. But, definitely on the agenda. But that is a good idea, Angela. I should make a list.", "order": 51168, "speaker": "Pete Miller"},
  {"season": 9, "episode": 1, "scene": 43, "line": "Oh, come on, Pete! God, that's just sad. If he doesn't watch himself, he's gonna be here for years, doing nothing. [sober realization] Wow, maybe Pete is the new Jim.", "order": 51169, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 1, "scene": 44, "line": "Do you see how the trapeze completely surrounds the wire? That means it's literally impossible for anyone to fall off. So you will merely sit below and be my counterweight as I pedal across the parking lot to that telephone pole.", "order": 51170, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 1, "scene": 44, "line": "I will?", "order": 51171, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 1, "scene": 44, "line": "You will. Yeah we weigh about the same, wouldn't you say?", "order": 51172, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 1, "scene": 44, "line": "Sure, if you weigh 105 pounds.", "order": 51173, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 1, "scene": 44, "line": "So you'll do it?", "order": 51174, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 1, "scene": 44, "line": "No.", "order": 51175, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 1, "scene": 44, "line": "Come on, this will be the only thrill of your boring life.", "order": 51176, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 1, "scene": 44, "line": "Dwight, you may find this hard to believe, but I love my boring life.", "order": 51177, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 1, "scene": 44, "line": "Come on.", "order": 51178, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 1, "scene": 44, "line": "Exactly the way it is.", "order": 51179, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 1, "scene": 44, "line": "No, Pam.", "order": 51180, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 1, "scene": 44, "line": "Yes, and there's nothing you could say that would get me to run the slightest risk of losing it.", "order": 51181, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 1, "scene": 44, "line": "Please? Please, Pam?", "order": 51182, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 1, "scene": 44, "line": "Find someone else, I don't know. Ask Phyllis.", "order": 51183, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 1, "scene": 44, "line": "I can't use Phyllis. Are you kidding me? The moment she steps off this bar I'll be launched into space. God, you're so insensitive.", "order": 51184, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 1, "scene": 45, "line": "Why are you getting rid of it?", "order": 51185, "speaker": "Pete Miller"},
  {"season": 9, "episode": 1, "scene": 45, "line": "Allergies.", "order": 51186, "speaker": "Angela Martin"},
  {"season": 9, "episode": 1, "scene": 45, "line": "Your husband?", "order": 51187, "speaker": "Pete Miller"},
  {"season": 9, "episode": 1, "scene": 45, "line": "No, the baby. Please, it's my husband's favorite cat. He's broken up about it. It's the only time I've seen him cry other than our wedding night.", "order": 51188, "speaker": "Angela Martin"},
  {"season": 9, "episode": 1, "scene": 45, "line": "You know what? I will take Comstock.", "order": 51189, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 1, "scene": 45, "line": "Really? Oh, Oscar, thank you!", "order": 51190, "speaker": "Angela Martin"},
  {"season": 9, "episode": 1, "scene": 45, "line": "I'll come by after work and pick him up.", "order": 51191, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 1, "scene": 45, "line": "Yay! Oh, poor Robert. He won't get to say goodbye. He has this business dinner tonight", "order": 51192, "speaker": "Angela Martin"},
  {"season": 9, "episode": 1, "scene": 45, "line": "C'est la vie.", "order": 51193, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 1, "scene": 45, "line": "Please don't teach the cat French.", "order": 51194, "speaker": "Angela Martin"},
  {"season": 9, "episode": 1, "scene": 46, "line": "[on phone] Yeah. Good news, tonight when you come over for dinner, you can play with your old buddy Comstock.", "order": 51195, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 1, "scene": 47, "line": "You ready?", "order": 51196, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 1, "scene": 47, "line": "Yeah. You know what? I'll meet you down there. Just gotta make a quick call.", "order": 51197, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 1, "scene": 47, "line": "Kay.", "order": 51198, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 1, "scene": 48, "line": "Ok. [prepares bike trapeze]Oh, god.", "order": 51199, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 1, "scene": 49, "line": "Attention, employees of Dunder Mifflin. Everyone thinks the new guys are so cool cause they can slack line. Boo. Hey Clark, this is what a areal salesman looks like. They say that you only live once and I'm about to prove it. Dwight Schrute!", "order": 51200, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 1, "scene": 50, "line": "Hey, man. It's halpert. Did you go to the other guy yet? Great, don't. I'm in. Yeah, yep, I'm all in. Ok, talk to you soon. Bye. [hangs up] Yeah!", "order": 51201, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 1, "scene": 51, "line": "Woo! [starts pedaling out until trapeze bike flips over, he dangles from the handlebars] Ahh!!", "order": 51202, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 1, "scene": 52, "line": "In the parking lot today there was a circus. The copier did tricks on the high-wire, a lady tried to give away a baby that looked like a cat. There was a Dwight impersonator and a Jim impersonator; a strong man crushed a turtle. I laughed and I cried. Not bad for a day in the life of a dog food company.", "order": 51203, "speaker": "Creed Bratton"},
  {"season": 9, "episode": 1, "scene": 53, "line": "Crap. Sorry, Nellie.", "order": 51204, "speaker": "Meredith Palmer"},
  {"season": 9, "episode": 1, "scene": 53, "line": "Once again, I understand that old habits die hard, but if everyone would kindly walk their refuse to the trash bins instead of tossing them, then we would'[hit in the face with trash]", "order": 51205, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 1, "scene": 53, "line": "Mm, swish.", "order": 51206, "speaker": "Stanley Hudson"},
  {"season": 9, "episode": 1, "scene": 54, "line": "Oh, it's nothing to do with me. I just happen to be sitting near to where the bins are.", "order": 51207, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 1, "scene": 55, "line": "There are two things that I am passionate about. Recycling and revenge.", "order": 51208, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 1, "scene": 56, "line": "[As trash is thrown at her] Fluke.", "order": 51209, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 2, "scene": 1, "line": "[surveying an untidy office] The building's custodian is on vacation for the month and Dwight is too cheap to hire a replacement. So, instead, we're living in filth. But, not for long. Because I have created the chore wheel.", "order": 51210, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 2, "scene": 2, "line": "[viewing the chore wheel for the first time] Oh, yeah! Can I spin first?", "order": 51211, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 2, "scene": 2, "line": "Well, it doesn't spin. We'll just move the wheel one notch each morning and' you see what chore you get that day.", "order": 51212, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 2, "scene": 2, "line": "A wheel is supposed to spin.", "order": 51213, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 2, "scene": 2, "line": "Yeah, you know, like [motions arm in circles] guh, guh, guh, guh, guh, guh, guh' guh.", "order": 51214, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 2, "scene": 2, "line": "No, I'm familiar with spinning. It's just that wouldn't work with a chore wheel because people might get the same chore-", "order": 51215, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 2, "scene": 2, "line": "[interrupting] Bugh, boring. All she talks about is chores.", "order": 51216, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 2, "scene": 2, "line": "A wheel wants to spin, Pam.", "order": 51217, "speaker": "Creed Bratton"},
  {"season": 9, "episode": 2, "scene": 2, "line": "[reluctantly] Spinning would be more fun.", "order": 51218, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 2, "scene": 2, "line": "[frustrated] 'Kay.", "order": 51219, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 2, "scene": 3, "line": "[spinning a new 'chore wheel' while everyone claps] Okay, that's what I'm talking about! Big money, big money! [wheel stops on 'mug duty', disappointed] Mug duty?", "order": 51220, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 2, "scene": 3, "line": "Yes, you clean all the mugs in the sink.", "order": 51221, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 2, "scene": 3, "line": "This sucks.", "order": 51222, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 2, "scene": 3, "line": "Yeah, seriously, it's like everything on there is work.", "order": 51223, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 2, "scene": 3, "line": "I don't think you guys understand why we're doing this. It's-", "order": 51224, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 2, "scene": 3, "line": "Yeah, I don't think that you understand wheels.", "order": 51225, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 2, "scene": 4, "line": "I've been through several rounds of development with the team and here's where we stand with the chore wheel. [introducing a third 'chore wheel', excited] We've got prizes! Ten bucks, candy bar, manager for an hour but, there are also penalties. Like, no internet, Stanley gets your lunch. The one thing that is not on the chore wheel is chores. But they were right; it's more fun this way.", "order": 51226, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 2, "scene": 5, "line": "[everyone cheers as Meredith spins and lands on 'tiny wheel'] The tiny wheel actually does have chores. It's so cute no one seems to mind.", "order": 51227, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 2, "scene": 6, "line": "[referencing the 'tiny wheel'] Toilets! [everyone cheers as Meredith accepts her chore]", "order": 51228, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 2, "scene": 7, "line": "[at the Halpert residence as Cece twirls in the front yard, exiting front door] Pam, we gotta go. Cece, go back inside. Grandma's got breakfast, OK? [to camera] We are going to Roy's wedding. Yep, Roy. I think the only weird thing about going to your wife's ex-fianc's wedding on a weekday at eight AM is that it's your wife's ex-fianc.", "order": 51229, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 2, "scene": 7, "line": "[exiting house] Thanks, mom!", "order": 51230, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 2, "scene": 7, "line": "[to Pam] A banana?", "order": 51231, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 2, "scene": 7, "line": "Yeah. I'm afraid he's only gonna have hot dogs.", "order": 51232, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 2, "scene": 7, "line": "Oh, come on. Not even Roy will have hot dogs at his wedding.", "order": 51233, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 2, "scene": 7, "line": "Planned a wedding with him. He wanted hot dogs.", "order": 51234, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 2, "scene": 7, "line": "[to camera] Just' so weird.", "order": 51235, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 2, "scene": 8, "line": "[as Pete and Clark enter office] Oh, Pete, you've got mail.", "order": 51236, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 2, "scene": 8, "line": "Really? I got something?", "order": 51237, "speaker": "Pete Miller"},
  {"season": 9, "episode": 2, "scene": 8, "line": "Well, it's addressed to Customer Service so, it's your mail.", "order": 51238, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 2, "scene": 8, "line": "Well, you know, I'm also Customer Service.", "order": 51239, "speaker": "Clark Green"},
  {"season": 9, "episode": 2, "scene": 8, "line": "Yeah, I'm alternating. [gestures both men]", "order": 51240, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 2, "scene": 8, "line": "[sarcastically] Yay, another person yelling at me.", "order": 51241, "speaker": "Pete Miller"},
  {"season": 9, "episode": 2, "scene": 8, "line": "Well, it could be a nice letter. I write nice letters to companies all the time.", "order": 51242, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 2, "scene": 8, "line": "That's really nice.", "order": 51243, "speaker": "Pete Miller"},
  {"season": 9, "episode": 2, "scene": 8, "line": "[looking down at Erin's desk] Wow, Newsweek, huh? This election' thing is crazy, right?", "order": 51244, "speaker": "Clark Green"},
  {"season": 9, "episode": 2, "scene": 8, "line": "[referring to the letter] It's open.", "order": 51245, "speaker": "Pete Miller"},
  {"season": 9, "episode": 2, "scene": 8, "line": "Oh yeah, I accidentally read it. It's not a nice letter.", "order": 51246, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 2, "scene": 8, "line": "[whispering] OK.", "order": 51247, "speaker": "Pete Miller"},
  {"season": 9, "episode": 2, "scene": 9, "line": "[distributing paperwork to the office] Get excited. The special projects fairy has arrived.", "order": 51248, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 2, "scene": 9, "line": "I know you don't really exist.", "order": 51249, "speaker": "Creed Bratton"},
  {"season": 9, "episode": 2, "scene": 10, "line": "Today, I launch my big charity initiative 'Operation Give Back'. Andy has shot down all of my special projects thus far but, this one is about charity. So, I'd like to see him piss on that one.", "order": 51250, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 2, "scene": 11, "line": "[at Roy's wedding] Darryl! Ha-ha! What's happening?", "order": 51251, "speaker": "Roy Anderson"},
  {"season": 9, "episode": 2, "scene": 11, "line": "Congratulations, baby!", "order": 51252, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 2, "scene": 11, "line": "Ah, thank you!", "order": 51253, "speaker": "Roy Anderson"},
  {"season": 9, "episode": 2, "scene": 12, "line": "[Jim and Pam approaching the wedding venue] Is this his house?", "order": 51254, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 2, "scene": 12, "line": "Think so.", "order": 51255, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 2, "scene": 12, "line": "[approaching Jim and Pam] Mimosa?", "order": 51256, "speaker": "Server"},
  {"season": 9, "episode": 2, "scene": 12, "line": "[taking glass] Thank you.", "order": 51257, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 2, "scene": 12, "line": "Would you like me to take your peel?", "order": 51258, "speaker": "Server"},
  {"season": 9, "episode": 2, "scene": 12, "line": "[embarrassed] Yes, thank you. [finishes banana and hands server the peel]", "order": 51259, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 2, "scene": 12, "line": "OK.", "order": 51260, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 2, "scene": 12, "line": "[as Jim receives and declines a call] Who's that?", "order": 51261, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 2, "scene": 12, "line": "Uh, my ex-fianc.", "order": 51262, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 2, "scene": 12, "line": "[sarcastically] Ha-ha.", "order": 51263, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 2, "scene": 13, "line": "I started a new business with my college friend. But, Pam doesn't know. Um' actually I did tell Pam and we decided 'no'. But, then I decided 'yes' anyway. So, I'm thinking there's another conversation coming. And, it's hard to know when that will be.", "order": 51264, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 2, "scene": 14, "line": "With Operation Give Back, you pick the charities Dunder-Mifflin supports.", "order": 51265, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 2, "scene": 14, "line": "[grinning] Oh boy! What's happening?", "order": 51266, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 2, "scene": 14, "line": "There's four thousand dollars to give away. So, who has filled out their form? Stanley?", "order": 51267, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 2, "scene": 14, "line": "American Diabetes Association.", "order": 51268, "speaker": "Stanley Hudson"},
  {"season": 9, "episode": 2, "scene": 14, "line": "Um, you have diabetes, Stanley. [to Nellie] I'm sorry, is the assignment to pick a selfish charity?", "order": 51269, "speaker": "Angela Martin"},
  {"season": 9, "episode": 2, "scene": 14, "line": "I w-, uh, I would love to give uh-", "order": 51270, "speaker": "Toby Flenderson"},
  {"season": 9, "episode": 2, "scene": 14, "line": "[excited] Heifer's International. Listen to this. They give a poor person like, a goat or something. It's a great prank.", "order": 51271, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 2, "scene": 14, "line": "I wanna work with, uh, Jimmy Carter and help build gnomes.", "order": 51272, "speaker": "Creed Bratton"},
  {"season": 9, "episode": 2, "scene": 14, "line": "Dwight, what about you?", "order": 51273, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 2, "scene": 14, "line": "I will not be participating as there is no evidence that charity works.", "order": 51274, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 2, "scene": 14, "line": "Uh, correction, I give to a foundation that teaches homeless children nautical flag signaling. Changes lives.", "order": 51275, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 2, "scene": 14, "line": "Thank you, Andy.", "order": 51276, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 2, "scene": 14, "line": "I'm not doing it for you. I'm doing it for the preservation of nautical flag signaling.", "order": 51277, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 2, "scene": 14, "line": "Dwight, you will be participating.", "order": 51278, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 2, "scene": 14, "line": "No thank you.", "order": 51279, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 2, "scene": 14, "line": "Participation is mandatory; Dwight, but you can choose whatever cause you like.", "order": 51280, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 2, "scene": 14, "line": "Fine, I chose the Global Relief Foundation.", "order": 51281, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 2, "scene": 14, "line": "Great, thank you.", "order": 51282, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 2, "scene": 14, "line": "Which was recently discovered to be a front for the Taliban.", "order": 51283, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 2, "scene": 14, "line": "[everyone groans] Oh, for goodness' sake, Dwight.", "order": 51284, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 2, "scene": 14, "line": "Yeah. The Taliban in Afa-ghanistan.", "order": 51285, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 2, "scene": 15, "line": "The Taliban is the worst. Great heroine, though.", "order": 51286, "speaker": "Creed Bratton"},
  {"season": 9, "episode": 2, "scene": 16, "line": "Dwight, I cannot have the Taliban on the roster of Operation Give Back.", "order": 51287, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 2, "scene": 16, "line": "Well, it looks like there won't be any Operation Give Back. Ha, ha. The nanny state is over, ladies and gentlemen. You're welcome. [wads up Nellie's charity sheet and tosses it toward her]", "order": 51288, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 2, "scene": 17, "line": "[approaches Roy back at the wedding] There he is. Is it almost time to cut the pancake, or what?", "order": 51289, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 2, "scene": 17, "line": "Aw, yeah, god, we appreciate everyone coming so early. It's, everyone's just been so nice.", "order": 51290, "speaker": "Roy Anderson"},
  {"season": 9, "episode": 2, "scene": 17, "line": "Thanks for inviting us, by the way-", "order": 51291, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 2, "scene": 17, "line": "Are you kidding?", "order": 51292, "speaker": "Roy Anderson"},
  {"season": 9, "episode": 2, "scene": 17, "line": "That was, that was a surprise.", "order": 51293, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 2, "scene": 17, "line": "Come on. If it wasn't for you, I never would've met Laura. I mean, seriously, kinda dodged a bullet on that one. [sees Jim's reaction] Just kidding.", "order": 51294, "speaker": "Roy Anderson"},
  {"season": 9, "episode": 2, "scene": 17, "line": "You're welcome.", "order": 51295, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 2, "scene": 17, "line": "Thanks...aw.", "order": 51296, "speaker": "Roy Anderson"},
  {"season": 9, "episode": 2, "scene": 17, "line": "By the way, man, this place is... beautiful.", "order": 51297, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 2, "scene": 17, "line": "Started a gravel company. I mean, who knew it'd take off?", "order": 51298, "speaker": "Roy Anderson"},
  {"season": 9, "episode": 2, "scene": 17, "line": "Gravel company?", "order": 51299, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 2, "scene": 17, "line": "Yeah. What about you? What are you doing?", "order": 51300, "speaker": "Roy Anderson"},
  {"season": 9, "episode": 2, "scene": 17, "line": "Not gravel, obviously. [both chuckle] No, things are good. Things are good. Got some stuff in the works. So-", "order": 51301, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 2, "scene": 17, "line": "Oh, cool.", "order": 51302, "speaker": "Roy Anderson"},
  {"season": 9, "episode": 2, "scene": 17, "line": "Yeah, you never know.", "order": 51303, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 2, "scene": 17, "line": "Hey bro, stop wasting time with this haircut. [referring to Jim] He's got a fifty-thousand dollar sports car. Cheers.", "order": 51304, "speaker": "Kenny"},
  {"season": 9, "episode": 2, "scene": 17, "line": "OK, Kenny.", "order": 51305, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 2, "scene": 18, "line": "[talking on phone] Duncan, listen, I would love to help you out but where am I gonna find somebody that's pretty, smart and well-informed? Huh? Hold on, hold on. [covers phone's receiver] Erin, do you know anybody that might want... [has realization] Oh my gosh. You love the news, right?", "order": 51306, "speaker": "Clark Green"},
  {"season": 9, "episode": 2, "scene": 18, "line": "Well, it depends. I mean, sometimes I find out things that are really sad.", "order": 51307, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 2, "scene": 18, "line": "Well, I got a buddy that's a big time local news producer and I can't tell you his name, but it'd blow your mind.", "order": 51308, "speaker": "Clark Green"},
  {"season": 9, "episode": 2, "scene": 18, "line": "Uh, is it Duncan?", "order": 51309, "speaker": "Pete Miller"},
  {"season": 9, "episode": 2, "scene": 18, "line": "He's looking for on-air talent and he'd kill me if I didn't get you on tape.", "order": 51310, "speaker": "Clark Green"},
  {"season": 9, "episode": 2, "scene": 18, "line": "You mean, put me on the news?", "order": 51311, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 2, "scene": 18, "line": "You'll just come over, put on little lipstick, loosen up with a glass of red wine and then we'll just run through a few stories. You know, a couple different ways, with a couple different outfits. It'd be really tasteful and it'd really help me out with Duncan... with my friend. [Erin considers] What do you say? Huh?", "order": 51312, "speaker": "Clark Green"},
  {"season": 9, "episode": 2, "scene": 18, "line": "Maybe, ok? Let me think about it.", "order": 51313, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 2, "scene": 18, "line": "[Pete looks on disappointed] Ok.", "order": 51314, "speaker": "Clark Green"},
  {"season": 9, "episode": 2, "scene": 18, "line": "Whoo!", "order": 51315, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 2, "scene": 18, "line": "Think it over. [punches Pete]", "order": 51316, "speaker": "Clark Green"},
  {"season": 9, "episode": 2, "scene": 19, "line": "No, Clark's not my friend. He is the douche that sits next to me at the office. My friends are Scott, Glenn, and Rob. But, you don't know them.", "order": 51317, "speaker": "Pete Miller"},
  {"season": 9, "episode": 2, "scene": 20, "line": "I never really thought much about being more than a receptionist. But, why? Because I happened to answer help wanted ad to be a receptionist? I mean, what if the ad had been for a CEO? Or for a brain surgeon?", "order": 51318, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 2, "scene": 21, "line": "So, ah, it was a year ago today that I met Laura. I thought she was my waitress and, uh, took her three weeks to tell me that she actually owned the place. You are full of surprises. You are my beautiful mystery girl. And, today, I have a surprise for you. [approaches piano]", "order": 51319, "speaker": "Roy Anderson"},
  {"season": 9, "episode": 2, "scene": 21, "line": "He plays piano?", "order": 51320, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 2, "scene": 21, "line": "No. Roy? No.", "order": 51321, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 2, "scene": 21, "line": "You know how I said I was taking boxing lessons? Actually, I was doing this. Um, so, I hope that you like it. [sits in front of piano]", "order": 51322, "speaker": "Roy Anderson"},
  {"season": 9, "episode": 2, "scene": 21, "line": "[clapping] You got this, Roy. [Roy begins playing and singing She's Got a Way by Billy Joel, Pam and Jim looked shocked, Phyllis and Bob begin making out]", "order": 51323, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 2, "scene": 22, "line": "[in the car] We still surprise each other.", "order": 51324, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 2, "scene": 22, "line": "Definitely.", "order": 51325, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 2, "scene": 22, "line": "You know, I never did it, but for your 30th birthday I really wanted to surprise you with-", "order": 51326, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 2, "scene": 22, "line": "Courtside seats. Sixers.", "order": 51327, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 2, "scene": 22, "line": "Yes. But, what I didn't tell you is that I actually bought the tickets. We only didn't go because it was-", "order": 51328, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 2, "scene": 22, "line": "It was an away game.", "order": 51329, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 2, "scene": 22, "line": "In Phoenix. They should really tell you that more specifically. I mean, every game is an away game for one of the teams. [long pause] Do we know everything about each other?", "order": 51330, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 2, "scene": 22, "line": "No.", "order": 51331, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 2, "scene": 22, "line": "Tell me one thing about you I don't know.", "order": 51332, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 2, "scene": 22, "line": "[laughs] Um, ok. [thinks but says nothing]", "order": 51333, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 2, "scene": 23, "line": "[in the kitchen] Oh, here's one. Did I ever tell you about the time that my brothers videotaped the lottery announcement? And bought the winning numbers the next day? And then, played the tape for me the next week, and I-", "order": 51334, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 2, "scene": 23, "line": "And you thought you guys were millionaires.", "order": 51335, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 2, "scene": 23, "line": "You heard that one.", "order": 51336, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 2, "scene": 23, "line": "Yes. But there's, wait, oh, there's a funny ending to that story. I can't remember.", "order": 51337, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 2, "scene": 23, "line": "That I thought we were millionaires.", "order": 51338, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 2, "scene": 23, "line": "Thought you were millionaires, yeah. That's funny. Shoot, I knew that one.", "order": 51339, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 2, "scene": 23, "line": "That's all right.", "order": 51340, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 2, "scene": 23, "line": "The senator and I still have mystery. I'm always waiting to see what he's gonna surprise me with next. [Oscar chokes on his coffee]", "order": 51341, "speaker": "Angela Martin"},
  {"season": 9, "episode": 2, "scene": 23, "line": "You all right? [Oscar nods]", "order": 51342, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 2, "scene": 24, "line": "You support the Taliban abroad. So I assume you're willing to live by their rules here.", "order": 51343, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 2, "scene": 24, "line": "Anything else would be inconsistent.", "order": 51344, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 2, "scene": 24, "line": "Will you join me then in a pledge to live by Taliban law in this office? [offers Dwight a contract]", "order": 51345, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 2, "scene": 24, "line": "[takes contract] Absolutely, I will.", "order": 51346, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 2, "scene": 25, "line": "I feared Nellie had some sort of a plan but she used a ridiculous font. Huh. [in a fake English accent] You don't have a plan.", "order": 51347, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 2, "scene": 26, "line": "When you use a ridiculous font, no one thinks you have a plan.", "order": 51348, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 2, "scene": 27, "line": "[holds up pen as Dwight searches his desk] Looking for this?", "order": 51349, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 2, "scene": 27, "line": "What the? [follows Nellie into the break room]", "order": 51350, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 2, "scene": 27, "line": "Oh, this is a lovely pen. Ah. But, it's mine now because I stole it.", "order": 51351, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 2, "scene": 27, "line": "[taking pen back] Gimme that.", "order": 51352, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 2, "scene": 27, "line": "Didn't you sign a contract to live under Taliban law? And now, there's been theft. That means, you're not serious or... someone's getting their hand cut off.", "order": 51353, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 2, "scene": 27, "line": "You're insane.", "order": 51354, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 2, "scene": 27, "line": "I know. So, it's better that you pick another charity.", "order": 51355, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 2, "scene": 27, "line": "Oh, and let your precious Operation Power Grab proceed unchecked? No thank you.", "order": 51356, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 2, "scene": 27, "line": "In that case, you... [pulls out a cleaver] will have to chop off my hand.", "order": 51357, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 2, "scene": 27, "line": "[considers then takes cleaver] This cleaver appears to need sharpening. I suggest you spend some quality time together just you and your hand. Tie a shoe, toss a salad. Do any of the two hand activities that you'll miss the most.", "order": 51358, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 2, "scene": 27, "line": "I tell you what. I need to make three calls. And then after that, you can become a person who chops off people's hands.", "order": 51359, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 2, "scene": 27, "line": "Sounds like a plan.", "order": 51360, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 2, "scene": 28, "line": "[in his office] Clark wants to film you in different outfits inside his apartment?", "order": 51361, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 2, "scene": 28, "line": "Yeah.", "order": 51362, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 2, "scene": 29, "line": "[entering annex] Clark, you and I need to have a little chat. What clothes does Erin need for this news audition?", "order": 51363, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 2, "scene": 29, "line": "Uh... couple of button-downs, a camisole, a blazer, a pencil skirt, stilettos, maybe, no, no, not maybe, definitely open-toed. Uh, something low-cut because the camera makes everything seem higher cut.", "order": 51364, "speaker": "Clark Green"},
  {"season": 9, "episode": 2, "scene": 29, "line": "Really?", "order": 51365, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 2, "scene": 29, "line": "Yeah, industry secret. You're gonna want some of those panty hose with the seam up the back.", "order": 51366, "speaker": "Clark Green"},
  {"season": 9, "episode": 2, "scene": 29, "line": "Seems unnecessary for an audition.", "order": 51367, "speaker": "Pete Miller"},
  {"season": 9, "episode": 2, "scene": 29, "line": "And then, you know, maybe just a robe to wear in between takes but I probably got one she can borrow at my place, so-", "order": 51368, "speaker": "Clark Green"},
  {"season": 9, "episode": 2, "scene": 29, "line": "Thank god someone here knows what their talking about. I want you to take this credit card, take Erin to the mall and get that stuff.", "order": 51369, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 2, "scene": 29, "line": "I absolutely will do that.", "order": 51370, "speaker": "Clark Green"},
  {"season": 9, "episode": 2, "scene": 29, "line": "Awesome. [to Pete] Plop!", "order": 51371, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 2, "scene": 29, "line": "Pff. Guess I'll just head over to the mall then. Buy Erin some sexy, fun outfits.", "order": 51372, "speaker": "Clark Green"},
  {"season": 9, "episode": 2, "scene": 30, "line": "That Clark, huh? Errr [punches palm] Ca!", "order": 51373, "speaker": "Pete Miller"},
  {"season": 9, "episode": 2, "scene": 31, "line": "[in kitchen with several people around] Next question for our oldie-weds-", "order": 51374, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 2, "scene": 31, "line": "What's the craziest place you've ever made whoopie?", "order": 51375, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 2, "scene": 31, "line": "[to Kevin] Language.", "order": 51376, "speaker": "Angela Martin"},
  {"season": 9, "episode": 2, "scene": 31, "line": "Who was Pam's first celebrity crush?", "order": 51377, "speaker": "Phyllis Vance"},
  {"season": 9, "episode": 2, "scene": 31, "line": "Pam's first celebrity crush.", "order": 51378, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 2, "scene": 31, "line": "[whispering] John Stamos.", "order": 51379, "speaker": "Toby Flenderson"},
  {"season": 9, "episode": 2, "scene": 31, "line": "Ready?", "order": 51380, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 2, "scene": 31, "line": "Uh-huh.", "order": 51381, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 2, "scene": 31, "line": "[as he and Pam flip their cards] John Stamos.", "order": 51382, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 2, "scene": 31, "line": "Oh!", "order": 51383, "speaker": "Angela Martin"},
  {"season": 9, "episode": 2, "scene": 31, "line": "Yes. But John Stamos was temporary. I quickly moved on to-", "order": 51384, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 2, "scene": 31, "line": "Johnny Depp.", "order": 51385, "speaker": "Toby Flenderson"},
  {"season": 9, "episode": 2, "scene": 31, "line": "Johnny Depp. [everyone looks confused toward Toby]", "order": 51386, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 2, "scene": 31, "line": "[after awkward pause] Sh, uh, um, I was having this separate conversation with Kevin. Uh, Johnny Depp.", "order": 51387, "speaker": "Toby Flenderson"},
  {"season": 9, "episode": 2, "scene": 31, "line": "Totally. George Clooney.", "order": 51388, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 2, "scene": 31, "line": "Uh-huh.", "order": 51389, "speaker": "Toby Flenderson"},
  {"season": 9, "episode": 2, "scene": 31, "line": "OK, I have one. I have one. [Jim's phone rings and he motions he's taking the call elsewhere]", "order": 51390, "speaker": "Angela Martin"},
  {"season": 9, "episode": 2, "scene": 31, "line": "[while Angela tries asking a question] What is the craziest place you've ever made whoopie?", "order": 51391, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 2, "scene": 31, "line": "[as Pam wonders about Jim] Kevin, stop it with that question.", "order": 51392, "speaker": "Angela Martin"},
  {"season": 9, "episode": 2, "scene": 32, "line": "[in the stairwell on his phone] Right. And did you show them the market? Yeah. And what'd they say? That's awesome! That, oh my god! Wow!", "order": 51393, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 2, "scene": 33, "line": "It's not even real yet. And I'm not gonna tell her 'til it's real.", "order": 51394, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 2, "scene": 34, "line": "I think maybe there actually is something I don't know about Jim.", "order": 51395, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 2, "scene": 35, "line": "Everyone, we have a voluntary meeting in the conference room to discuss Erin's confidence.", "order": 51396, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 2, "scene": 35, "line": "Her body. We're gonna talk about her body. [to Darryl] Good. Great job.", "order": 51397, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 2, "scene": 36, "line": "Andy made me his consigliere. Which means Assistant Regional Manager. I guess he thought I'd be into The Godfather 'cause I'm black. Wrong! I'm into The Godfather 'cause I'm a cinephile. I like Scarface 'cause I'm black.", "order": 51398, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 2, "scene": 37, "line": "[to a full conference room] Erin has an audition to be a newscaster. [general acknowledgement]", "order": 51399, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 2, "scene": 37, "line": "Uh-huh. And I want her to feel very comfortable in her very sexy skin. So, everybody say something that you like about her body. All right? Darryl.", "order": 51400, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 2, "scene": 37, "line": "OK, um, I like Erin's hair. It's a very pretty color.", "order": 51401, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 2, "scene": 37, "line": "Yeah, right?", "order": 51402, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 2, "scene": 37, "line": "I'm sorry. This is for a news audition?", "order": 51403, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 2, "scene": 37, "line": "Yeah.", "order": 51404, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 2, "scene": 37, "line": "Then why are we talking about her looks? Why not her credibility? Or her reliability?", "order": 51405, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 2, "scene": 37, "line": "News flash everyone. The human race finds attractive people more trustworthy.", "order": 51406, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 2, "scene": 37, "line": "I'm so sorry, Andy, but for twenty years, the most trusted man in America - Walter Cronkite.", "order": 51407, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 2, "scene": 37, "line": "Cronkite was hot. If I could go back in time, I'd take that mustache ride.", "order": 51408, "speaker": "Meredith Palmer"},
  {"season": 9, "episode": 2, "scene": 37, "line": "Does Erin have any experience? Has she taken any journalism classes, maybe? Or...", "order": 51409, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 2, "scene": 37, "line": "Has she done the pageant circuit?", "order": 51410, "speaker": "Angela Martin"},
  {"season": 9, "episode": 2, "scene": 37, "line": "No, I watch the news.", "order": 51411, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 2, "scene": 37, "line": "She's gonna be amazing. Look at her. She's gonna light up the screen.", "order": 51412, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 2, "scene": 37, "line": "This is a first for me. And, I don't get a lot of chances, so I have to take them really seriously. I will do whatever it takes to get the job.", "order": 51413, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 2, "scene": 37, "line": "[whispering to Pete] Whatever it takes.", "order": 51414, "speaker": "Clark Green"},
  {"season": 9, "episode": 2, "scene": 37, "line": "[whispering] Yeah, I know, I heard. I heard.", "order": 51415, "speaker": "Pete Miller"},
  {"season": 9, "episode": 2, "scene": 38, "line": "[places Nellie's hand on a board and holds up cleaver] This is it. Any questions?", "order": 51416, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 2, "scene": 38, "line": "Is it gonna be long?", "order": 51417, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 2, "scene": 38, "line": "No. It's gonna be over before you know it.", "order": 51418, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 2, "scene": 39, "line": "[pretending to read news] Afghan president Hamid Karzai declared a new policy of dollar days throughout the country. Promising low, low prices on all 2012 Kia Sentras and Sonatas. Aren't you glad you waited? Karzai commented.", "order": 51419, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 2, "scene": 39, "line": "Um, where did you get that story?", "order": 51420, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 2, "scene": 39, "line": "A little bit here, a little bit there. I bet you didn't think I knew current events.", "order": 51421, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 2, "scene": 39, "line": "I love it. It's fantastic. Now, tag it with your name.", "order": 51422, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 2, "scene": 39, "line": "For Channel 11 news, I'm Erin Hannon.", "order": 51423, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 2, "scene": 39, "line": "Pause after 'news'.", "order": 51424, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 2, "scene": 39, "line": "For Channel 11 news... I'm Erin Hannon.", "order": 51425, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 2, "scene": 39, "line": "No, pause longer.", "order": 51426, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 2, "scene": 39, "line": "That was a good one.", "order": 51427, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 2, "scene": 39, "line": "Pause, pause longer. OK? Builds suspense. Don't be shy.", "order": 51428, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 2, "scene": 39, "line": "Got it. For Channel 11 news... [very long pause]", "order": 51429, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 2, "scene": 39, "line": "Wha, it's-", "order": 51430, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 2, "scene": 39, "line": "I'm Erin Hannon.", "order": 51431, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 2, "scene": 39, "line": "OK. All right. Great. Some great stuff in there.", "order": 51432, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 2, "scene": 40, "line": "[as Pam stares at him] I can feel you looking at me.", "order": 51433, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 2, "scene": 40, "line": "OK. Well, here's something you don't know. A couple of weeks ago-", "order": 51434, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 2, "scene": 40, "line": "Uh-huh.", "order": 51435, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 2, "scene": 40, "line": "I ran into this guy from my high school who has just gotten divorced and he hit on me. In the mall. And I didn't tell you because, I don't know, I felt embarrassed. And, I didn't know if you would be mad or worried. But, anyway, I thought you'd want to know.", "order": 51436, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 2, "scene": 40, "line": "[after a pause] That didn't happen. You would've told me right away.", "order": 51437, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 2, "scene": 40, "line": "Yeah, I would've. What about you? Come on, there's gotta be something. Between your birth and the last two days, something you just haven't had the chance to tell me.", "order": 51438, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 2, "scene": 40, "line": "Uh... [looks at Pam]", "order": 51439, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 2, "scene": 40, "line": "[looks knowingly back] Just tell me.", "order": 51440, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 2, "scene": 40, "line": "God, give it up, Beesley. You know me too well.", "order": 51441, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 2, "scene": 41, "line": "Heh! Ha! [making various sounds and movements while preparing to severe Nellie's hand] [Darryl enters, sees what's happening, and quietly exits]", "order": 51442, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 2, "scene": 42, "line": "Hey, um, I knew something bothered me and I finally figured it out. What news producers are gonna want to see is how Erin relates to the other people on the news team.", "order": 51443, "speaker": "Pete Miller"},
  {"season": 9, "episode": 2, "scene": 42, "line": "Oh.", "order": 51444, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 2, "scene": 42, "line": "The weird thing is Erin is doing the audition alone.", "order": 51445, "speaker": "Pete Miller"},
  {"season": 9, "episode": 2, "scene": 42, "line": "Oh god.", "order": 51446, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 2, "scene": 42, "line": "Now, I'd say Clark could be your co-host... he's already doing camera.", "order": 51447, "speaker": "Pete Miller"},
  {"season": 9, "episode": 2, "scene": 42, "line": "Tuh.", "order": 51448, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 2, "scene": 42, "line": "Someone who's already got rapport with Erin, maybe. I don't know. [Andy smiles at camera]", "order": 51449, "speaker": "Pete Miller"},
  {"season": 9, "episode": 2, "scene": 43, "line": "Ha! [raises cleaver] This is for real this time.", "order": 51450, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 2, "scene": 43, "line": "It's getting late. I thought you guys could use a little [sets up laptop] inspiration.", "order": 51451, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 2, "scene": 43, "line": "[Darryl hits play] Oh, a movie. What is this?", "order": 51452, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 2, "scene": 43, "line": "127 hours. It's about this guy who-", "order": 51453, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 2, "scene": 43, "line": "No, no. No spoilers. Please.", "order": 51454, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 2, "scene": 43, "line": "My bad.", "order": 51455, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 2, "scene": 43, "line": "No.", "order": 51456, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 2, "scene": 43, "line": "Goodnight.", "order": 51457, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 2, "scene": 44, "line": "[answers his door to find Erin, Andy and Pete] Hey! Everybody.", "order": 51458, "speaker": "Clark Green"},
  {"season": 9, "episode": 2, "scene": 44, "line": "Yeah.", "order": 51459, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 2, "scene": 44, "line": "Come in.", "order": 51460, "speaker": "Clark Green"},
  {"season": 9, "episode": 2, "scene": 44, "line": "All right!", "order": 51461, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 2, "scene": 44, "line": "Come on in.", "order": 51462, "speaker": "Clark Green"},
  {"season": 9, "episode": 2, "scene": 44, "line": "Nice.", "order": 51463, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 2, "scene": 44, "line": "[to Pete] What're you doing here?", "order": 51464, "speaker": "Clark Green"},
  {"season": 9, "episode": 2, "scene": 44, "line": "Sorry, man. Andy though Erin needed a co-anchor. I'm his makeup guy. My hands are tied.", "order": 51465, "speaker": "Pete Miller"},
  {"season": 9, "episode": 2, "scene": 45, "line": "[news anchor audition] The victim was released from the hospital with second-degree burns.", "order": 51466, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 2, "scene": 45, "line": "[also as anchor] Wow. You know what they say Erin. If you can't take the heat, get out of the kitchen.", "order": 51467, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 2, "scene": 45, "line": "Well, he tried to but the fire door was blocked.", "order": 51468, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 2, "scene": 45, "line": "He sure did.", "order": 51469, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 2, "scene": 45, "line": "All right. We got that. That's a rap, everybody.", "order": 51470, "speaker": "Clark Green"},
  {"season": 9, "episode": 2, "scene": 45, "line": "Aw!", "order": 51471, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 2, "scene": 45, "line": "Just, you sure Clark?", "order": 51472, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 2, "scene": 45, "line": "Yep, she's done.", "order": 51473, "speaker": "Clark Green"},
  {"season": 9, "episode": 2, "scene": 45, "line": "No, I just, I don't mean Erin. I mean for me. I didn't feel good about that.", "order": 51474, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 2, "scene": 45, "line": "No, we got it. We got it.", "order": 51475, "speaker": "Clark Green"},
  {"season": 9, "episode": 2, "scene": 45, "line": "Great! Let's get some food. I'm starving.", "order": 51476, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 2, "scene": 45, "line": "I don't think we did get it. I could do, uh, a couple more takes. We could do it in close-up. That might even be better for editing.", "order": 51477, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 2, "scene": 45, "line": "OK, I don't.", "order": 51478, "speaker": "Clark Green"},
  {"season": 9, "episode": 2, "scene": 45, "line": "All right.", "order": 51479, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 2, "scene": 45, "line": "I'm hungry.", "order": 51480, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 2, "scene": 45, "line": "Uh, Pete, you wanna take Erin to get a burger or something?", "order": 51481, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 2, "scene": 45, "line": "Yeah, whatever you say, boss.", "order": 51482, "speaker": "Pete Miller"},
  {"season": 9, "episode": 2, "scene": 45, "line": "OK. [to Erin] I'll call you later.", "order": 51483, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 2, "scene": 45, "line": "OK.", "order": 51484, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 2, "scene": 45, "line": "[to Clark] So this is a single.", "order": 51485, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 2, "scene": 46, "line": "Hey, even if this doesn't work out for me, I'm just glad I had the guts to do it. And, maybe it'll work out for Andy.", "order": 51486, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 2, "scene": 47, "line": "[as he and Nellie watch the movie] Oh god.", "order": 51487, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 2, "scene": 47, "line": "That is absolutely revolting!", "order": 51488, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 2, "scene": 47, "line": "Yeah.", "order": 51489, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 2, "scene": 47, "line": "He is so good, though.", "order": 51490, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 2, "scene": 47, "line": "Yes.", "order": 51491, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 2, "scene": 47, "line": "The way he just cuts off his arm.", "order": 51492, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 2, "scene": 47, "line": "If you like James Franco, we really should watch Rise of the Planet of the Apes.", "order": 51493, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 2, "scene": 47, "line": "Well, he's a genius, you know? He was in graduate programs at Yale, Columbia and NYU all at the same time.", "order": 51494, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 2, "scene": 47, "line": "Whoopty doo. That doesn't make you a genius.", "order": 51495, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 2, "scene": 47, "line": "Well, it doesn't make you stupid.", "order": 51496, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 2, "scene": 47, "line": "Yeah, it does, actually. It makes you real stupid.", "order": 51497, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 2, "scene": 47, "line": "Stupid like you.", "order": 51498, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 2, "scene": 47, "line": "No, like you.", "order": 51499, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 2, "scene": 47, "line": "Like you.", "order": 51500, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 2, "scene": 47, "line": "You're the stupid one.", "order": 51501, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 2, "scene": 47, "line": "You're the stupid one.", "order": 51502, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 2, "scene": 47, "line": "You're the stupid one.", "order": 51503, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 2, "scene": 47, "line": "You're the stupid one.", "order": 51504, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 2, "scene": 47, "line": "You, you, you, you...", "order": 51505, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 3, "scene": 1, "line": "Morning, Dwight", "order": 51506, "speaker": "Fake Jim Halpert"},
  {"season": 9, "episode": 3, "scene": 1, "line": "Who are you?", "order": 51507, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 3, "scene": 1, "line": "Who am I? I'm Jim. We've been working together for twelve years. Ha, Weird joke, Dwight.", "order": 51508, "speaker": "Fake Jim Halpert"},
  {"season": 9, "episode": 3, "scene": 1, "line": "You're not Jim. Jim's not Asian", "order": 51509, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 3, "scene": 1, "line": "You seriously never noticed? Hey, hats off to you for not seeing race.", "order": 51510, "speaker": "Fake Jim Halpert"},
  {"season": 9, "episode": 3, "scene": 1, "line": "Alright then Jim. Ahhh, why don't you tell me about that sale that you made yesterday?", "order": 51511, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 3, "scene": 1, "line": "Uh, Wellington systems? Sold them 10 cases of 24-pound letter stock. Or, were you talking about Krieger-Murphy? Because I didn't close that one yet, but I'm hoping I've got a voicemail from Paul Krieger waiting for me.", "order": 51512, "speaker": "Fake Jim Halpert"},
  {"season": 9, "episode": 3, "scene": 1, "line": "Please enter your password.", "order": 51513, "speaker": "Voicemail"},
  {"season": 9, "episode": 3, "scene": 1, "line": "You have one new message.", "order": 51514, "speaker": "Voicemail"},
  {"season": 9, "episode": 3, "scene": 1, "line": "How did you know? No! No, no! That is sensitive information only for employees, not outsiders!", "order": 51515, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 3, "scene": 1, "line": "Dwight, cut it out, I'm trying to work.", "order": 51516, "speaker": "Fake Jim Halpert"},
  {"season": 9, "episode": 3, "scene": 1, "line": "You don't work here! You're not Jim!", "order": 51517, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 3, "scene": 1, "line": "Jim, I got us that dinner reservation. Grico's at 7:30.", "order": 51518, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 3, "scene": 1, "line": "Oh great, can't wait. [Kisses Pam]", "order": 51519, "speaker": "Fake Jim Halpert"},
  {"season": 9, "episode": 3, "scene": 2, "line": "Jim's at the dentist this morning. And Steve is an actor friend of ours.", "order": 51520, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 3, "scene": 3, "line": "I don't know who you are, but you are not Jim. This is Jim!", "order": 51521, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 3, "scene": 3, "line": "Oh my'! Oh d'! Oh, how did'? [gasps] Huhhhhh!", "order": 51522, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 3, "scene": 4, "line": "Heyyy! Study buddies!", "order": 51523, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 3, "scene": 4, "line": "Oh, ok.", "order": 51524, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 3, "scene": 4, "line": "Getting things done. Awesome!", "order": 51525, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 3, "scene": 4, "line": "It's all about finding ways to make yourself more efficient. Life hacking, baby. This morning, I brushed my teeth in the shower. Saved my self 90 seconds. Which I just used to explain this to you. Damn it!", "order": 51526, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 3, "scene": 5, "line": "Since Andy promoted me to assistant regional manager, I've been trying to step my game up, you know, be more productive. In fact, you know what? Let's knock out a few more of these sound bites while we're here. [pauses] Whoa! That person has really gotten him or herself into quite a predicament.", "order": 51527, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 3, "scene": 6, "line": "[speaking in French] S'il vous plait'dites-Moi'Ugggghhh les Bleagh!", "order": 51528, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 3, "scene": 6, "line": "Ah, French. It's a great language. If you're a chain-smoking acrobat.", "order": 51529, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 3, "scene": 6, "line": "I'm just trying to fit in better with Andy's family. They all speak more than one language. Usually when I'm there!", "order": 51530, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 3, "scene": 6, "line": "You wanna learn a really impressive second language? Try dothraki. Win over any man in my guild.", "order": 51531, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 3, "scene": 7, "line": "Dothraki is the native tongue of the nomadic war-mongering horse lords of Essos as featured in the superb Home Box Office series, Game of Thrones. It has a lot of nudity. Which I fast-forward through to get to the chopped-off heads.", "order": 51532, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 3, "scene": 8, "line": "I could teach you if you want. It's a lot easier than French.", "order": 51533, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 3, "scene": 8, "line": "Yeah! Let's do it!", "order": 51534, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 3, "scene": 8, "line": "[exclaims] Atherozar!", "order": 51535, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 3, "scene": 8, "line": "[shocked] Oh!", "order": 51536, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 3, "scene": 8, "line": "It means 'excellent'. And we have begun.", "order": 51537, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 3, "scene": 9, "line": "[asks Nellie who is tapping a pen on her desk]. Is everything okay?", "order": 51538, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 3, "scene": 9, "line": "Hmm? Oh'oh, yeah. Yeah, yeah, yeah, fine. Fine, fine, fine. I'm mean, I d'I just don't wanna'burden you with my massive stress freak outs!", "order": 51539, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 3, "scene": 9, "line": "Great.", "order": 51540, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 3, "scene": 9, "line": "It's just that I am taking my driver's license test in two days, and I need to practice with an experienced driver in the car. But I've had no time to do that, thanks to 'Demandy'...[Points to Andy's office with her thumb]. I just want to hit the open road and drive, man! But'in who's car?", "order": 51541, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 3, "scene": 9, "line": "[yells] Nellie! Get your wrinkly old balls in here.", "order": 51542, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 3, "scene": 9, "line": "[sighs]", "order": 51543, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 3, "scene": 9, "line": "Nellie, I could practice with you in my car at lunchtime.", "order": 51544, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 3, "scene": 9, "line": "Oh, Pam, thank you! You are my savior! [hugs Pam]", "order": 51545, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 3, "scene": 9, "line": "[giggling] ohhhhhhhhhhhhhh!!!", "order": 51546, "speaker": "Nellie Bertram & Pam Halpert"},
  {"season": 9, "episode": 3, "scene": 9, "line": "[to Jim] I'm sorry, I'm leaving you alone for lunch.", "order": 51547, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 3, "scene": 9, "line": "Don't worry about it. I have a thing. A thing of soup. Which I've been wanting to try.", "order": 51548, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 3, "scene": 10, "line": "This is my research into how we might produce child-proof paper that doesn't give you paper cuts. We can't. And here is a print out of your genealogy from thisisyourfamilytree.com", "order": 51549, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 3, "scene": 10, "line": "Executive-summary me. Hit the highlights.", "order": 51550, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 3, "scene": 10, "line": "Well, it turns out, you are a distant blood relative of Michelle Obama!", "order": 51551, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 3, "scene": 10, "line": "As in...", "order": 51552, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 3, "scene": 10, "line": "Wife of Barack, loves gardening, wants to wipe out fat children.", "order": 51553, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 3, "scene": 10, "line": "[silently mouths] Wow!", "order": 51554, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 3, "scene": 11, "line": "This is super-flattering. She's the most popular person in America. This is a big day for both of us.", "order": 51555, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 3, "scene": 12, "line": "My fellow Americans, I have a feeling my approval rating is about to go through the roof. Turns out, I am related to Michelle Obama.", "order": 51556, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 3, "scene": 13, "line": "I was intimidated by Andy's family before. And now I have to see the First Lady at holidays? She's gonna be like, 'What's your stance on politics?' Or, 'What is the best war to do?' And, I will just be like, 'Duhhhh!'", "order": 51557, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 3, "scene": 14, "line": "Alright! We gotta get rid of all this junk food. Get fit, America! Not sure if the buzz has reached the annex yet, but uh...I'm related to Michelle Obama.", "order": 51558, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 3, "scene": 15, "line": "Yeah we noticed early on, Andy really appreciates enthusiasm.", "order": 51559, "speaker": "Pete Miller"},
  {"season": 9, "episode": 3, "scene": 15, "line": "So we decided the best way to get ahead here is to be his cheerleaders.", "order": 51560, "speaker": "Clark Green"},
  {"season": 9, "episode": 3, "scene": 16, "line": "Oooohhhhhhhh!", "order": 51561, "speaker": "Clark Green"},
  {"season": 9, "episode": 3, "scene": 16, "line": "It's starting to have this reverse effect, though, where I really do think the stuff he does is awesome.", "order": 51562, "speaker": "Clark Green"},
  {"season": 9, "episode": 3, "scene": 16, "line": "Yeah, me too. It's weird. Hard to remember what's real at this point.", "order": 51563, "speaker": "Pete Miller"},
  {"season": 9, "episode": 3, "scene": 16, "line": "Just clap through it, man.", "order": 51564, "speaker": "Clark Green"},
  {"season": 9, "episode": 3, "scene": 17, "line": "You log in sales at ten different times. If you log 'em all at once, you save a lot of time. It's called batching.", "order": 51565, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 3, "scene": 17, "line": "That was really good, Darryl.", "order": 51566, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 3, "scene": 17, "line": "Life hacking, man.", "order": 51567, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 3, "scene": 17, "line": "There he is!", "order": 51568, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 3, "scene": 17, "line": "Andy, you gotta check this out. He just showed me'[gets interrupted by Andy].", "order": 51569, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 3, "scene": 17, "line": "[shushing Jim] Ahhhthathathathathta. Right now I need canned tuna, okay? Darryl, guess which talented individual, who also has a killer singing voice, is related to the First Lady?", "order": 51570, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 3, "scene": 17, "line": "Tracee Ellis Ross. Daughter of the First Lady of Motown, Diana Ross.", "order": 51571, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 3, "scene": 17, "line": "It's me! [chuckles] I am related to Michelle Obama.", "order": 51572, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 3, "scene": 17, "line": "What?! Really?", "order": 51573, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 3, "scene": 17, "line": "I mean, it's distant, but...", "order": 51574, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 3, "scene": 17, "line": "[chuckles in agreement] Huh ha! That's cool, man.", "order": 51575, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 3, "scene": 17, "line": "Right?", "order": 51576, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 3, "scene": 18, "line": "Darryl said, 'Cool, man.' He called me as cool man.", "order": 51577, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 3, "scene": 19, "line": "You know, I really do think it would be worth it to pull over and just take ten minutes to eat.", "order": 51578, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 3, "scene": 19, "line": "Mmmm'the thing is Pam, I'm gonna be eating while I'm driving, so, I might as well get good at it.", "order": 51579, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 3, "scene": 19, "line": "Brake lights. Break lights! Break lights! Break lights!!!", "order": 51580, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 3, "scene": 19, "line": "Whhooooo!", "order": 51581, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 3, "scene": 20, "line": "I'm just saying, what does it imply in this country when a white person shares an ancestor with a black person?", "order": 51582, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 3, "scene": 20, "line": "You think that Andy's family owned slaves?", "order": 51583, "speaker": "Phyllis Vance"},
  {"season": 9, "episode": 3, "scene": 20, "line": "Well somebody owned somebody. And I don't think anybody would buy an Andy.", "order": 51584, "speaker": "Stanley Hudson"},
  {"season": 9, "episode": 3, "scene": 21, "line": "I throat-rip.", "order": 51585, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 3, "scene": 21, "line": "Foth aggendak!", "order": 51586, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 3, "scene": 21, "line": "You throat-rip.", "order": 51587, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 3, "scene": 21, "line": "Foth aggendi!", "order": 51588, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 3, "scene": 21, "line": "He/she/it throat rips.", "order": 51589, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 3, "scene": 21, "line": "Foth aggenda!", "order": 51590, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 3, "scene": 21, "line": "More of a, barbaric growl.", "order": 51591, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 3, "scene": 21, "line": "[in a barbaric growl] Forth aggenda!", "order": 51592, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 3, "scene": 21, "line": "Louder! You're shouting it from the back of a horse!", "order": 51593, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 3, "scene": 21, "line": "Wah!! Aggenda!!", "order": 51594, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 3, "scene": 22, "line": "Pretty crazy about Andy and Michelle Obama, huh?", "order": 51595, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 3, "scene": 22, "line": "[laughs mischievously] Yeeeeahhhh! It's almost unbelievable!", "order": 51596, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 3, "scene": 22, "line": "What?", "order": 51597, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 3, "scene": 22, "line": "Well, you know how Andy has been really salting my onions, lately.", "order": 51598, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 3, "scene": 22, "line": "Sure.", "order": 51599, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 3, "scene": 22, "line": "Well, when he asked me to look up his ancestry online, I remembered that news story about Michelle Obama having white relatives, and I just knew he would eat that up!", "order": 51600, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 3, "scene": 22, "line": "So he's not related to Michelle Obama?", "order": 51601, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 3, "scene": 22, "line": "Pam, I barely know how to turn on my computer.", "order": 51602, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 3, "scene": 22, "line": "[laughs]", "order": 51603, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 3, "scene": 23, "line": "Nellie's pretty fearless. And I think she might be maybe even almost sort of fun.", "order": 51604, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 3, "scene": 24, "line": "[whispers] Pam! [she pretends to slam a wrench over the tire service guy's head].", "order": 51605, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 3, "scene": 24, "line": "[laughs]", "order": 51606, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 3, "scene": 25, "line": "Well, if there's another explanation, I don't really see what it could possibly be. I'[gets cut by Andy barging into the convo]", "order": 51607, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 3, "scene": 25, "line": "What's going on here? I'm related to the first lady, okay? Get over it. [chuckles] I still need weekly status reports from most of you, sooooo, can we get back to work, please? Get back to work! [Mimicks smacking everyone with a whipl].", "order": 51608, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 3, "scene": 25, "line": "Andy! Andy! No! I would be very polite today.", "order": 51609, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 3, "scene": 25, "line": "Why? Is it employee's day or something? I cannot keep track of these BS holidays.", "order": 51610, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 3, "scene": 25, "line": "Your connection. To Michelle Obama has certain... negative connotations. Most likely, your family were'slave owners.", "order": 51611, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 3, "scene": 25, "line": "Does anyone else think it's possible that I come from slave owners?", "order": 51612, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 3, "scene": 26, "line": "Whoa! That person has really gotten him or herself into quite a predicament.", "order": 51613, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 3, "scene": 27, "line": "Hey mom, it's Andy. Give me a call when you get a chance gotta quick question for you, uhhhh no big deal just about America's national shame, thanks, Bye.", "order": 51614, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 3, "scene": 27, "line": "Yes, yes, yes.", "order": 51615, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 3, "scene": 27, "line": "Great. Ooo! Spreadsheets! Yum, yum!", "order": 51616, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 3, "scene": 27, "line": "I included some time saving ideas...", "order": 51617, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 3, "scene": 27, "line": "Huh huh huh. Look, I'm not gonna lie to ya, I'm a teensy bit distracted right now.", "order": 51618, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 3, "scene": 27, "line": "Look, Andy, even if your ancestors did own slaves, it wouldn't be your fault. This is only weird if you make it weird.", "order": 51619, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 3, "scene": 27, "line": "[snaps his fingers] Right on, brotha. Wurddd.", "order": 51620, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 3, "scene": 28, "line": "Addor!", "order": 51621, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 3, "scene": 28, "line": "Daraas!", "order": 51622, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 3, "scene": 28, "line": "Qazer!", "order": 51623, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 3, "scene": 28, "line": "Daraas! [asks the rest of the office] Does anyone here have fermented mare's milk?", "order": 51624, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 3, "scene": 29, "line": "Hey Erin!", "order": 51625, "speaker": "Pete Miller"},
  {"season": 9, "episode": 3, "scene": 29, "line": "Azem choma! Chomakka-attun!", "order": 51626, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 3, "scene": 29, "line": "Oh'okay. Sorry.", "order": 51627, "speaker": "Pete Miller"},
  {"season": 9, "episode": 3, "scene": 30, "line": "Oh, still, it must great to have something else going on outside of work.", "order": 51628, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 3, "scene": 30, "line": "Yeah'turn signal. It's exciting to be painting again'those are the wipers. So'the'its'just. There you go! Yeah. [chuckles] Yeah, things get so busy with the kids'red light'that it's nice to have that creative outlet'red light! Red light! Red! Red!", "order": 51629, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 3, "scene": 30, "line": "That is brilliant, Pam. I would love to see some of your work.", "order": 51630, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 3, "scene": 30, "line": "Well, since we're stopped at a light, uhhh, here is...the mural I did for Angela's baby.", "order": 51631, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 3, "scene": 30, "line": "That's amazing, Pam! Oh, I love the lion in the tuxedo!", "order": 51632, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 3, "scene": 30, "line": "Angela insisted that all the animals be fully clothed.", "order": 51633, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 3, "scene": 31, "line": "[to Erin]. Heyyy, has anyone turned in their status reports up here?", "order": 51634, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 3, "scene": 31, "line": "Vos!", "order": 51635, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 3, "scene": 31, "line": "So, no? You think maybe you could remind people? I'm trying to down play the whole bossy boss thing today.", "order": 51636, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 3, "scene": 31, "line": "Because of your slaves.", "order": 51637, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 3, "scene": 31, "line": "Not my slaves, my ancestors'. Maybe. Probably not.", "order": 51638, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 3, "scene": 31, "line": "Well, if it makes you feel any better, the dothraki word for 'slave master', 'attafrauk!', is a term of respect. I'm learning how to speak dothraki! Color you impressed?", "order": 51639, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 3, "scene": 31, "line": "That you're learning a made-up language from HBO's Game of Thrones? I have a lot going on today...but this was a great nerd-out!", "order": 51640, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 3, "scene": 31, "line": "Dwight, you didn't tell me you were teaching me a fake language.", "order": 51641, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 3, "scene": 31, "line": "People laughed at Klingon at first, and now you can major in it.", "order": 51642, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 3, "scene": 32, "line": "Hi guys.", "order": 51643, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 3, "scene": 32, "line": "Hey boss! I am so thirsty. Could I have a scoop of water?", "order": 51644, "speaker": "Phyllis Vance"},
  {"season": 9, "episode": 3, "scene": 32, "line": "Yeah. You don't have to ask me.", "order": 51645, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 3, "scene": 32, "line": "Ha ha! Okay. Great. Very funny. I get it. Just because my ancestors happen to be'[ringtone of Dixie plays]. Very funny, Kevin. Changed my ringtone. Very funny. I liked the original song on my ringtone, which, you may remember, was 'Diamonds on the Soles of Her Shoes' by Paul Simon, featurinnnngg Lady Smith'African American'Mambazo.", "order": 51646, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 3, "scene": 33, "line": "Good. Very good. [cell phone beeping]Oh'no! Here. It's, uh, a text from Andy. 'New special proj. Need fam tree for evbody. Really dig up dirt A.S.A.P.' And then in parentheses, he wrote out 'as soon as possible.'", "order": 51647, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 3, "scene": 33, "line": "Mm. Ugh, looks like its pretend-y time again. Write back, 'looking for dirt.'", "order": 51648, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 3, "scene": 33, "line": "Oh, can I help? We could say someone is related to, uhm, Tonya Harding.", "order": 51649, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 3, "scene": 33, "line": "Pam, I'm related to Tonya Harding.", "order": 51650, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 3, "scene": 33, "line": "Oh'gee'I'm'", "order": 51651, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 3, "scene": 33, "line": "No! I'm just practicing my lyyiiinggg! [whispers] I love it.", "order": 51652, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 3, "scene": 33, "line": "Brilliant!", "order": 51653, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 3, "scene": 33, "line": "What should we say about Jim?", "order": 51654, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 3, "scene": 33, "line": "Ummm. Oh! I'll say he's related to Richard Nixon. It's an inside joke. He looks really Nixon-y when he wakes up.", "order": 51655, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 3, "scene": 33, "line": "My ex behaved like Nixon. All of the lying. None of the sexual charisma. [pauses] I just made a joke there.", "order": 51656, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 3, "scene": 34, "line": "I'm sorry. It's just, uhm, I actually do have this weird feeling that there's something Jim isn't telling me.", "order": 51657, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 3, "scene": 34, "line": "Oh no! Oh! An affair! It is always an affair!", "order": 51658, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 3, "scene": 34, "line": "Jim? No.", "order": 51659, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 3, "scene": 34, "line": "[sighs]. How can you be sure?", "order": 51660, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 3, "scene": 34, "line": "Because he just loves me too much.", "order": 51661, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 3, "scene": 34, "line": "You're a cocky little thing, aren't you, Pam?", "order": 51662, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 3, "scene": 35, "line": "I've done a little genealogy research of my own. Turn out I'm not the only one with a few skeletons in the ol' family closet. For example, Phyllis's great-great grandmother was responsible for spreading cholera to the United States.", "order": 51663, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 3, "scene": 35, "line": "Ew.", "order": 51664, "speaker": "Angela Martin"},
  {"season": 9, "episode": 3, "scene": 35, "line": "Kevin is related to both John Wayne Gacy and John Wayne Bobbitt.", "order": 51665, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 3, "scene": 35, "line": "And John Wayne?", "order": 51666, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 3, "scene": 35, "line": "No. Not that I see here.", "order": 51667, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 3, "scene": 35, "line": "Wayne Johnson? The Rock?", "order": 51668, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 3, "scene": 35, "line": "You mean Dwayne? And no. What about Jim Halpert? Uh oh! Turns out, distant relative of the reviled, Richard Nixon!", "order": 51669, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 3, "scene": 36, "line": "Pam always says I look like Nixon. That's crazy, right? I mean there's nothing there. True'[touches his nose] Oh no.", "order": 51670, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 3, "scene": 37, "line": "Dwight's grandfather was a'[is interrupted by Dwight].", "order": 51671, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 3, "scene": 37, "line": "Was a member of the Bund. Which is not technically the same thing as the Nazi party. So'[clears throat]", "order": 51672, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 3, "scene": 37, "line": "I was gonna say he was a tax evader.", "order": 51673, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 3, "scene": 37, "line": "Oh. I was joking about that whole Bund thing. Oh ho, the look on your faces! Hahhahahahahah! Hahahahahah!", "order": 51674, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 3, "scene": 37, "line": "And Meredith is a blood relative of Lizzie Borden.", "order": 51675, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 3, "scene": 37, "line": "Cool! Ah! Ah! Ah! Ah! [mimics stabbing into Angela's throat]", "order": 51676, "speaker": "Meredith Palmer"},
  {"season": 9, "episode": 3, "scene": 37, "line": "Stop it! Stop it! You're frightening me!", "order": 51677, "speaker": "Angela Martin"},
  {"season": 9, "episode": 3, "scene": 37, "line": "Andy, did you call this meeting just to talk junk about our families?", "order": 51678, "speaker": "Phyllis Vance"},
  {"season": 9, "episode": 3, "scene": 37, "line": "Yeah, that'you're being really mean, Andy.", "order": 51679, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 3, "scene": 37, "line": "Yeah, Andy.", "order": 51680, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 3, "scene": 37, "line": "No, I'm proving a point, okay? We all have ancestors who may have done horrible things in the past. But it's in the past and it's not our fault. So we don't have to talk about it.", "order": 51681, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 3, "scene": 37, "line": "The difference is, Andy, that you're the only be here still benefitting from the terrible things that your ancestors did.", "order": 51682, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 3, "scene": 37, "line": "Might've done. And how' do you figure?", "order": 51683, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 3, "scene": 37, "line": "Your family's rich! I have to believe that a big part of the Bernard fortune was earned on the backs of slaves.", "order": 51684, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 3, "scene": 37, "line": "You know, there's nothing wrong with being successful in America, Oscar! I'm not gonna apologize for my family's wealth. That wealth, could one day benefit society...if capital gains are ever taxed as the same rate as earned income.", "order": 51685, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 3, "scene": 37, "line": "Really?", "order": 51686, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 3, "scene": 37, "line": "We merely transported them. Which at worst, makes us amoral middlemen.", "order": 51687, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 3, "scene": 38, "line": "Yo, d-dog. I need your help. I'm trying to think of things I can say that make it sound like I had a more difficult childhood than I actually had.", "order": 51688, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 3, "scene": 38, "line": "You're gonna po' mouth", "order": 51689, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 3, "scene": 38, "line": "Exactly. Help me po' mouth, Darryl.", "order": 51690, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 3, "scene": 38, "line": "Actually, Andy, you promised me five minutes to talk about productivity suggestions'", "order": 51691, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 3, "scene": 38, "line": "What if I said that my dad beat me. And, I just left out the croquet of it all. Or, I could just go all the way and just say I grew up in an apartment. Or is that too crazy?", "order": 51692, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 3, "scene": 38, "line": "That could work.", "order": 51693, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 3, "scene": 38, "line": "You now, Darryl, this is textbook assistant regional manager stuff here, and I feel like I'm doing all the heavy lifting. I'm coming up with all the ideas here.", "order": 51694, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 3, "scene": 38, "line": "I'm going for a walk.", "order": 51695, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 3, "scene": 38, "line": "[with a sigh] Okay.", "order": 51696, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 3, "scene": 39, "line": "Good. Good. And'[Nellie hits the car against the bushes]", "order": 51697, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 3, "scene": 40, "line": "You doing alright, man?", "order": 51698, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 3, "scene": 40, "line": "I'm done. I gotta get out of here.", "order": 51699, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 3, "scene": 40, "line": "Yeah. Not the easiest day to be assistant regional manager.", "order": 51700, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 3, "scene": 40, "line": "It's not just today, it's everyday. It seems like the better title I have, the stupider my job gets.", "order": 51701, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 3, "scene": 40, "line": "Oh, come on, it can always get better. Right?", "order": 51702, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 3, "scene": 40, "line": "Hmm. Yeah right", "order": 51703, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 3, "scene": 40, "line": "No, I'm serious. There's always something better.", "order": 51704, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 3, "scene": 40, "line": "Like what?", "order": 51705, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 3, "scene": 40, "line": "Like hypothetically' if I said there was another job. That you and I could both have.", "order": 51706, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 3, "scene": 40, "line": "What kind of job?", "order": 51707, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 3, "scene": 40, "line": "Something cool. Like, sports marketing or... that sound something like you'd be into?", "order": 51708, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 3, "scene": 40, "line": "Hell yeah!", "order": 51709, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 3, "scene": 40, "line": "Right?", "order": 51710, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 3, "scene": 40, "line": "That sounds awesome!", "order": 51711, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 3, "scene": 40, "line": "Ok, but wait. What if I told you that it was in Philly! So you'd have to...", "order": 51712, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 3, "scene": 40, "line": "I love Philly!", "order": 51713, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 3, "scene": 40, "line": "Right?", "order": 51714, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 3, "scene": 40, "line": "It's not even a thought'", "order": 51715, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 3, "scene": 40, "line": "Not even a thought! It's not even that far away! I could still commute! Exactly. Exactly! Alright!", "order": 51716, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 3, "scene": 40, "line": "What? Wait, wait, wait? So what? This happening?", "order": 51717, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 3, "scene": 40, "line": "Oh, it's happening! Let's just keep it between you and me for right now.", "order": 51718, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 3, "scene": 40, "line": "Yeah. Yeah, yeah, yeah. For sure. For sure. for sure. Man! And Pam's into it?", "order": 51719, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 3, "scene": 40, "line": "We, uh, we haven't talked about. But I think that she's'I think she understands... what this is.", "order": 51720, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 3, "scene": 40, "line": "Oh, come on, man. I thought you had something real.", "order": 51721, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 3, "scene": 40, "line": "What? No, no, no! Come on! This is real!", "order": 51722, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 3, "scene": 40, "line": "It's not real... until your wife is on board.", "order": 51723, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 3, "scene": 41, "line": "So what did you want to show me?", "order": 51724, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 3, "scene": 41, "line": "That is quite an ugly wall, isn't it?", "order": 51725, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 3, "scene": 41, "line": "Yeah. It's really ugly", "order": 51726, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 3, "scene": 41, "line": "Needs something, doesn't it? I'm thinking'a mural.", "order": 51727, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 3, "scene": 41, "line": "You mean me?", "order": 51728, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 3, "scene": 41, "line": "Yes! You! You are soo talented! It's going to be my next special project. Hiring Scranton's most dangerous young muralist to paint the warehouse wall.", "order": 51729, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 3, "scene": 41, "line": "Oh my god! I love it! Uh, I'Nellie, this is brilliant! [sees Jim] Hey!", "order": 51730, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 3, "scene": 41, "line": "Hey! Can I talk you? For a second?", "order": 51731, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 3, "scene": 41, "line": "Anything you have to say to her, you can say to me. She never loved you!", "order": 51732, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 3, "scene": 41, "line": "What?! No! I-I got this. [Laughs] Okay?", "order": 51733, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 3, "scene": 41, "line": "[quietly] This is his fault. It is not your fault. I'm gonna find you someone better, and rich.", "order": 51734, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 3, "scene": 42, "line": "And Filipino. But we'll break that to her later.", "order": 51735, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 3, "scene": 43, "line": "[to Darryl] You know what this is all about.", "order": 51736, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 3, "scene": 43, "line": "Yeah. You too, huh?", "order": 51737, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 3, "scene": 43, "line": "Yeah. Go on, spill it. Tell her all the gory details, youuuu sssnake!", "order": 51738, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 3, "scene": 44, "line": "Hey! He deserves this. And he said I could get in on it too. [whispers] Yeah.", "order": 51739, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 3, "scene": 44, "line": "Ohhh, Pam, nooo! Oh, I can't bear to watch this.", "order": 51740, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 3, "scene": 45, "line": "I don't know what I was so worried about. I have the best wife in the world.", "order": 51741, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 3, "scene": 46, "line": "I still can't believe he didn't tell me.", "order": 51742, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 3, "scene": 47, "line": "I was helping Nellie drive'[Stanley interrupts]", "order": 51743, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 3, "scene": 47, "line": "Do not care.", "order": 51744, "speaker": "Stanley Hudson"},
  {"season": 9, "episode": 3, "scene": 48, "line": "Fonas chek!", "order": 51745, "speaker": "Pete Miller"},
  {"season": 9, "episode": 3, "scene": 48, "line": "Dothraas! chek! [giggles]", "order": 51746, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 3, "scene": 48, "line": "I like that guy. We should hook him up with Meredith.", "order": 51747, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 3, "scene": 48, "line": "[in agreement] Hmmm!", "order": 51748, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 4, "scene": 1, "line": "Alright everybody, great season of softball, I'm super proud of you guys and I think you're gonna like this little highlight reel that I put together. [Andy plays video]", "order": 51749, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 4, "scene": 1, "line": "Andy Bernard presents: Summer Softball Epic Fails! [Kevin swings bat on screen, fart noise follows] Fail. [repeats] Fail", "order": 51750, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 4, "scene": 1, "line": "[repeats] Fail", "order": 51751, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 4, "scene": 1, "line": "A blooper reel? What is this, 2005? I look like Bob Saget? Fail! [Points to video] Who's this guy?", "order": 51752, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 4, "scene": 1, "line": "Fail!", "order": 51753, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 4, "scene": 1, "line": "I deserved that.", "order": 51754, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 4, "scene": 1, "line": "[Darryl runs in slow motion on video]", "order": 51755, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 4, "scene": 1, "line": "That was a triple.", "order": 51756, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 4, "scene": 1, "line": "Can't take the fail? Get out of the fail video!", "order": 51757, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 4, "scene": 1, "line": "My pleasure.", "order": 51758, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 4, "scene": 1, "line": "[Video Andy] Hey, I'm Pete, puberty is such a drag, man. And I'm Clark! I like to eat toilet paper. [Clark and Pete wave at camera] We fail! [Video shows memorial of Jerry]", "order": 51759, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 4, "scene": 1, "line": "What was that? That was just a normal video with you making vulgar noises.", "order": 51760, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 4, "scene": 1, "line": "Well, I worked with what I had, Oscar. Next time do more failure stuff, OK?", "order": 51761, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 4, "scene": 1, "line": "Uh, what happened to that video I sent you?", "order": 51762, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 4, "scene": 1, "line": "Oh that wasn't...that didn't work. That was not the right..[Group protests]", "order": 51763, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 4, "scene": 1, "line": "I think I got it right here.", "order": 51764, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 4, "scene": 1, "line": "That was not a fail.", "order": 51765, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 4, "scene": 1, "line": "Fail! Fail! Fail!...", "order": 51766, "speaker": "Group"},
  {"season": 9, "episode": 4, "scene": 1, "line": "[Group continues chanting 'Fail!']That was actually a serious accident that could have resulted in severe bodily harm. [Group keeps chanting] You're all failing right now. [Group continues] Congratulations on your epic fail of the use of the word fail! [Group claps and chants]", "order": 51767, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 4, "scene": 2, "line": "[Toby uses tester on wall as Erin marks wall with red tape X] Oh, god.", "order": 51768, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 4, "scene": 2, "line": "What's going on?", "order": 51769, "speaker": "Phyllis Vance"},
  {"season": 9, "episode": 4, "scene": 2, "line": "Nothing! Nothing is going on. Keep moving please.", "order": 51770, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 4, "scene": 2, "line": "What's he measuring?", "order": 51771, "speaker": "Stanley Hudson"},
  {"season": 9, "episode": 4, "scene": 2, "line": "OK, excuse me. I am the landlord. This is between me and the management, no one else. Please.", "order": 51772, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 4, "scene": 2, "line": "It's an EMF hotspot.", "order": 51773, "speaker": "Toby Flenderson"},
  {"season": 9, "episode": 4, "scene": 2, "line": "[Gasps] Oh my god!", "order": 51774, "speaker": "Phyllis Vance"},
  {"season": 9, "episode": 4, "scene": 2, "line": "It stands for electromagnetic field. Generally cause by a concentration of wiring in one area [Erin marks red tape X on the floor] Especially if they're poorly insulated. Dwight.", "order": 51775, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 4, "scene": 2, "line": "Um, OK I'm just walking into this. Am I to understand there is a bee hive in the wall?", "order": 51776, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 4, "scene": 2, "line": "You think I have a machine for measuring bee hives?", "order": 51777, "speaker": "Toby Flenderson"},
  {"season": 9, "episode": 4, "scene": 2, "line": "I was just asking a question, Toby. How are you not murdered every hour?", "order": 51778, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 4, "scene": 2, "line": "Well I'm not getting paid to work in a microwave oven.", "order": 51779, "speaker": "Stanley Hudson"},
  {"season": 9, "episode": 4, "scene": 2, "line": "OK, listen. Everything here is up to code.", "order": 51780, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 4, "scene": 3, "line": "[mocking] Oh, the wires need insulation. [normal voice] It's a wire people. I'm not buying it a fur coat.", "order": 51781, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 4, "scene": 4, "line": "[Jim rushes to open door for her] Thank you.", "order": 51782, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 4, "scene": 4, "line": "You got it.", "order": 51783, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 4, "scene": 5, "line": "Last week, I finally told Pam about the other job I took in Philly...the side job. And she was so incredibly cool about it. And now I just wanna do something huge for her. Like if we were in some biker bar and she mouthed off to some bikers and they came lumbering over and I was like wham! [mimes punch] Gotta go through me first.", "order": 51784, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 4, "scene": 6, "line": "Andy, could I have a word please? Um, it won't take a moment. It's extremely important and it really has to happen now.", "order": 51785, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 4, "scene": 6, "line": "Fine. I will give you one minute.", "order": 51786, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 4, "scene": 6, "line": "Oh, please don't use the hourglass.", "order": 51787, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 4, "scene": 6, "line": "You have one minute and your minute has begun and no time will be added at the end, even to accommodate this sentence with all of it's baroque dependent clauses and cascading turns of phrase.", "order": 51788, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 4, "scene": 6, "line": "I'm trying to adopt a baby.", "order": 51789, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 4, "scene": 6, "line": "A baby what? A human?!", "order": 51790, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 4, "scene": 6, "line": "And the...agency require a character reference from my employer.", "order": 51791, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 4, "scene": 6, "line": "Oh.", "order": 51792, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 4, "scene": 6, "line": "You wouldn't have to do anything. I would write the letter myself and you just simply sign it. So.", "order": 51793, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 4, "scene": 6, "line": "Oh, OK. And fall right into your plagiarism entrapment scheme? I don't think so.", "order": 51794, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 4, "scene": 6, "line": "It's not..it's-", "order": 51795, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 4, "scene": 6, "line": "And I happen to notice you're down to about thirty seconds here.", "order": 51796, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 4, "scene": 6, "line": "Well then if I could just convince...", "order": 51797, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 4, "scene": 6, "line": "And those sand grains are tumbling..", "order": 51798, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 4, "scene": 6, "line": "You.", "order": 51799, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 4, "scene": 6, "line": "With fury...", "order": 51800, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 4, "scene": 6, "line": "It's not..it's not", "order": 51801, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 4, "scene": 6, "line": "Down the sides..", "order": 51802, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 4, "scene": 6, "line": "Entrapment if I'm..", "order": 51803, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 4, "scene": 6, "line": "Of the hourglass..", "order": 51804, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 4, "scene": 6, "line": "..writing..", "order": 51805, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 4, "scene": 6, "line": "Time's up!", "order": 51806, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 4, "scene": 6, "line": "Fine.", "order": 51807, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 4, "scene": 7, "line": "Sure. I'll read her letter. And if she tells the truth about how evil and unfit to be a mother she is, then yeah. I'll sign that.", "order": 51808, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 4, "scene": 8, "line": "[Reading from computer] 'Statistical correlations exist between EMF radiation and various health hazards. But mainstream studies are inconclusive!' That means you can't make me do squat.", "order": 51809, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 4, "scene": 8, "line": "You better fix this. I already ditched my uterus and I ain't losing any more good parts.", "order": 51810, "speaker": "Meredith Palmer"},
  {"season": 9, "episode": 4, "scene": 8, "line": "You people don't realize what you're asking. I'd have to rip open the walls. We'd have to shut this place down for a week.", "order": 51811, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 4, "scene": 8, "line": "Week off. That'd be great.", "order": 51812, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 4, "scene": 9, "line": "[Jim puts popcorn bag in microwave] Hey, if you don't want to teach me Power Point, just say so.", "order": 51813, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 4, "scene": 9, "line": "I don't want to teach you Power Point.", "order": 51814, "speaker": "Clark Green"},
  {"season": 9, "episode": 4, "scene": 9, "line": "Come on! Just show me the Power Point.", "order": 51815, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 4, "scene": 9, "line": "Just do the tutorial.", "order": 51816, "speaker": "Clark Green"},
  {"season": 9, "episode": 4, "scene": 9, "line": "You're the tutorial.", "order": 51817, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 4, "scene": 9, "line": "No, dude, I'm not. I'm not the tutorial.", "order": 51818, "speaker": "Clark Green"},
  {"season": 9, "episode": 4, "scene": 9, "line": "You could be.", "order": 51819, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 4, "scene": 9, "line": "Mm-mm.", "order": 51820, "speaker": "Clark Green"},
  {"season": 9, "episode": 4, "scene": 9, "line": "[to Jim] What are you doing?", "order": 51821, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 4, "scene": 9, "line": "Getting my wife a week off from work.", "order": 51822, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 4, "scene": 9, "line": "You popped one kernel.", "order": 51823, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 4, "scene": 9, "line": "Awesome, right? [leaves]", "order": 51824, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 4, "scene": 9, "line": "So Creed is that dude's step dad?", "order": 51825, "speaker": "Clark Green"},
  {"season": 9, "episode": 4, "scene": 9, "line": "Correct.", "order": 51826, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 4, "scene": 10, "line": "Well, I don't know about the rest of you but I'm just gonna say it. I'm nervous. I have no idea what health problems this is all gonna cause. [group agrees, protests]", "order": 51827, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 4, "scene": 10, "line": "What? Come on.", "order": 51828, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 4, "scene": 10, "line": "I'm getting older. I'm losing my hair...", "order": 51829, "speaker": "Creed Bratton"},
  {"season": 9, "episode": 4, "scene": 10, "line": "I'm not gonna grow a third arm!", "order": 51830, "speaker": "Meredith Palmer"},
  {"season": 9, "episode": 4, "scene": 11, "line": "I know what Jim is trying to do. He's trying to get big bad Dwight to shell out for a huge repair job while lucky Jimbo gets a paid vacation. Well sorry, lucky Jimbo, I can live very happily in a magnetic field. Most of my childhood heroes got their power that way.", "order": 51831, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 4, "scene": 12, "line": "[reading from computer] 'Side effects of EMF include: headaches...'", "order": 51832, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 4, "scene": 12, "line": "Had 'em all my life.", "order": 51833, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 4, "scene": 12, "line": "..breast pain...'", "order": 51834, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 4, "scene": 12, "line": "No nobbies, no probbies. Nice try Jim.", "order": 51835, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 4, "scene": 12, "line": "Oof. 'Infertility.'", "order": 51836, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 4, "scene": 12, "line": "[scoffs] Yeah right. [Dwight moves mouse pad over his crotch]", "order": 51837, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 4, "scene": 12, "line": "Ah! There's my popcorn. Can you just grab that for me?", "order": 51838, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 4, "scene": 12, "line": "Psh. Keep your snacks on your side, Jim. Idiot. [notices popped kernels in the bag] What the?", "order": 51839, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 4, "scene": 12, "line": "What?", "order": 51840, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 4, "scene": 12, "line": "Some of these kernels have crowned.", "order": 51841, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 4, "scene": 12, "line": "That's impossible, cause that's a brand new bag...[looks up to ceiling where there is a red tape X over Dwight's chair] Oh my god.", "order": 51842, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 4, "scene": 12, "line": "Andy! [Jim mimes basketball shot]", "order": 51843, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 4, "scene": 13, "line": "I'm gonna drive you up to the lake, give you a whole week on the water. Just you, me and the kids.", "order": 51844, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 4, "scene": 13, "line": "Oh, can we stop by that pie stand on the way?", "order": 51845, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 4, "scene": 13, "line": "You mean Laverne's Pies Tires Fixed Also? [Pam nods] Yes we will be doing that. We'll be getting a dozen.", "order": 51846, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 4, "scene": 13, "line": "A dozen different pies? Cause that means rhubarb.", "order": 51847, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 4, "scene": 13, "line": "Why would you say that? I meant 4 apple, 4 blueberry, 2 cherry, 1 peach and 1 chocolate. I thought that was implied.", "order": 51848, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 4, "scene": 13, "line": "Yeah, OK then.", "order": 51849, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 4, "scene": 13, "line": "Ladies and gentlemen, I have heard your complaints and we reached a settlement.", "order": 51850, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 4, "scene": 13, "line": "So, we will be leaving the office for one whole week.", "order": 51851, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 4, "scene": 13, "line": "Nice job.", "order": 51852, "speaker": "Meredith Palmer"},
  {"season": 9, "episode": 4, "scene": 13, "line": "In my contract, it is stipulated that I provide a temporary work space. It will arrive in one hour.", "order": 51853, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 4, "scene": 13, "line": "What?", "order": 51854, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 4, "scene": 14, "line": "What's this?", "order": 51855, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 4, "scene": 14, "line": "Whoa!", "order": 51856, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 4, "scene": 14, "line": "What?", "order": 51857, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 4, "scene": 14, "line": "[Bus pulls into lot] Bring it in!", "order": 51858, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 4, "scene": 15, "line": "Roll into the future with Work Bus. Say goodbye to wasteful buildings. These days a mobile office isn't just for hotshot politicians. Now anyone can rent a work bus. [Meredith and Kevin bump chairs in bus angrily] If you've got a parking lot, a work space is just a phone call away. [Erin tapes candy dish to pole] In this age of belt tightening and less empowered workers, a work bus is how tomorrow gets things done.", "order": 51859, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 4, "scene": 16, "line": "[on phone] There are a hundred packs..", "order": 51860, "speaker": "Stanley Hudson"},
  {"season": 9, "episode": 4, "scene": 16, "line": "[On phone] No six after the eight, no....", "order": 51861, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 4, "scene": 16, "line": "Shh!", "order": 51862, "speaker": "Stanley Hudson"},
  {"season": 9, "episode": 4, "scene": 16, "line": "Shh!", "order": 51863, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 4, "scene": 16, "line": "Ninety nine cases..yeah.", "order": 51864, "speaker": "Stanley Hudson"},
  {"season": 9, "episode": 4, "scene": 16, "line": "Six. [Pete opens air vent over Angela, papers go flying]", "order": 51865, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 4, "scene": 16, "line": "Ooh!", "order": 51866, "speaker": "Angela Martin"},
  {"season": 9, "episode": 4, "scene": 16, "line": "Oh sorry! Sorry.", "order": 51867, "speaker": "Pete Miller"},
  {"season": 9, "episode": 4, "scene": 16, "line": "Oh my god!", "order": 51868, "speaker": "Angela Martin"},
  {"season": 9, "episode": 4, "scene": 17, "line": "[bumps into Meredith] Sorry.", "order": 51869, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 4, "scene": 17, "line": "Lose weight.", "order": 51870, "speaker": "Meredith Palmer"},
  {"season": 9, "episode": 4, "scene": 17, "line": "I'm trying. Sorry.", "order": 51871, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 4, "scene": 18, "line": "[handing Nelly envelope] Oh, the Pennsylvania Department of Public Welfare Pre-Adoption Standard.", "order": 51872, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 4, "scene": 18, "line": "Oh of course, you were adopted.", "order": 51873, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 4, "scene": 18, "line": "[laughs] I wish! No, I um, I made some short lists. I had a couple sleepovers, but I never managed to get in the end zone. I don't know what it was. Not loveable maybe? [laughs] Oh well.", "order": 51874, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 4, "scene": 18, "line": "Listen, I'm really struggling with this form. But as you know the system, you think maybe you could..?", "order": 51875, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 4, "scene": 18, "line": "Absolutely. I know exactly what they want to hear. I would love to help.", "order": 51876, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 4, "scene": 18, "line": "Oh thank you so much!", "order": 51877, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 4, "scene": 18, "line": "[whispers] Just don't tell Andy, because..", "order": 51878, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 4, "scene": 18, "line": "He hates me and thinks I'm a monster. Should go back to Loch Ness. [Erin nods]", "order": 51879, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 4, "scene": 19, "line": "Stretch. Alright.", "order": 51880, "speaker": "Clark Green"},
  {"season": 9, "episode": 4, "scene": 19, "line": "How many times do you need to take a stroll?", "order": 51881, "speaker": "Stanley Hudson"},
  {"season": 9, "episode": 4, "scene": 19, "line": "I, my legs cramp up! Ok, it's a circulation issue.", "order": 51882, "speaker": "Clark Green"},
  {"season": 9, "episode": 4, "scene": 19, "line": "Boy, I will hammer spank your rear.", "order": 51883, "speaker": "Stanley Hudson"},
  {"season": 9, "episode": 4, "scene": 19, "line": "Alright, alright, gang. Let's just settle down. You're yelling in her face.", "order": 51884, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 4, "scene": 19, "line": "It's a medical thing.", "order": 51885, "speaker": "Clark Green"},
  {"season": 9, "episode": 4, "scene": 19, "line": "Just...you good?", "order": 51886, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 4, "scene": 19, "line": "I'm good. I..", "order": 51887, "speaker": "Clark Green"},
  {"season": 9, "episode": 4, "scene": 19, "line": "[to Pam] I'm so sorry for all of this.", "order": 51888, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 4, "scene": 19, "line": "It's OK. You know what they say, a change is as good as a rest.", "order": 51889, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 4, "scene": 19, "line": "I, I need to get to the paper please. [Reaching for overhead bin above Pam, papers fall and Pam has liquid spilled on her]", "order": 51890, "speaker": "Angela Martin"},
  {"season": 9, "episode": 4, "scene": 19, "line": "Oh my god! Ah!", "order": 51891, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 4, "scene": 19, "line": "I'll get you a napkin. Someone get napkins please!", "order": 51892, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 4, "scene": 19, "line": "You know what? It's fine, it's fine. Let me just...it's fine. [Pam leaves work bus]", "order": 51893, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 4, "scene": 19, "line": "Pam, I'm really sorry. I- I'm really sorry about...all that. [Dwight smiles at Jim] Really? Smirking?", "order": 51894, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 4, "scene": 19, "line": "What can I say? I love justice. You forced me to spend money on needless repairs, and uh now you're locked in a prison bus and your woman drips with beverage.", "order": 51895, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 4, "scene": 19, "line": "Hey, Dwight. I was trying to do something nice for Pam. Can you just, help me out? Can we maybe take this thing somewhere? Or do something to not make this the worst day ever?", "order": 51896, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 4, "scene": 19, "line": "It's not my responsibility to solve your marriage problems by spending my money on gas.", "order": 51897, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 4, "scene": 19, "line": "Andy!", "order": 51898, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 4, "scene": 19, "line": "Yo. Dudeces.", "order": 51899, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 4, "scene": 19, "line": "You're the boss. Don't you think we'd all be a lot more productive if while we were doing work we looked up and saw the best rural pie stand in Pennsylvania?", "order": 51900, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 4, "scene": 19, "line": "Oh, I know I'd be more productive.", "order": 51901, "speaker": "Phyllis Vance"},
  {"season": 9, "episode": 4, "scene": 19, "line": "As would I.", "order": 51902, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 4, "scene": 19, "line": "No question.", "order": 51903, "speaker": "Stanley Hudson"},
  {"season": 9, "episode": 4, "scene": 19, "line": "No. No! This is a work bus. The wheels are for transporting the work space to and from the work site.", "order": 51904, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 4, "scene": 19, "line": "What are you talking about? You're not the boss. Andy is. Andy?", "order": 51905, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 4, "scene": 19, "line": "Pies! Pies! Pies! Pies! Pies!", "order": 51906, "speaker": "Phyllis Vance, Kevin Malone & Stanley Hudson"},
  {"season": 9, "episode": 4, "scene": 19, "line": "Alright! The fat people have spoken! Dwight, get this bus moving.", "order": 51907, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 4, "scene": 19, "line": "Yes! [Group cheers]", "order": 51908, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 4, "scene": 19, "line": "Next stop: Laverne's Pies Tires Fixed Also.", "order": 51909, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 4, "scene": 19, "line": "Oh! Yes! [Group cheers, Dwight moves to driver seat]", "order": 51910, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 4, "scene": 19, "line": "[To Dwight] So it looks like this work bus was a pretty good idea after all, huh?", "order": 51911, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 4, "scene": 19, "line": "Get your foot behind the yellow line.", "order": 51912, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 4, "scene": 19, "line": "You got it.", "order": 51913, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 4, "scene": 19, "line": "Yeah Jim! [Group claps]", "order": 51914, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 4, "scene": 20, "line": "[Sitting outside building] Stop.", "order": 51915, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 4, "scene": 20, "line": "Come back.", "order": 51916, "speaker": "Clark Green"},
  {"season": 9, "episode": 4, "scene": 20, "line": "Too late.", "order": 51917, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 4, "scene": 20, "line": "Mmm.", "order": 51918, "speaker": "Clark Green"},
  {"season": 9, "episode": 4, "scene": 21, "line": "Shabooyah, role call. Shabooyah, yah yah shabooyah, role call.", "order": 51919, "speaker": "Group"},
  {"season": 9, "episode": 4, "scene": 21, "line": "My name is Pam.", "order": 51920, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 4, "scene": 21, "line": "Yeah!", "order": 51921, "speaker": "Group"},
  {"season": 9, "episode": 4, "scene": 21, "line": "I like to paint.", "order": 51922, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 4, "scene": 21, "line": "Yeah!", "order": 51923, "speaker": "Group"},
  {"season": 9, "episode": 4, "scene": 21, "line": "You think you're better?", "order": 51924, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 4, "scene": 21, "line": "Yeah!", "order": 51925, "speaker": "Group"},
  {"season": 9, "episode": 4, "scene": 21, "line": "Oh no you ain't!", "order": 51926, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 4, "scene": 21, "line": "Role Call! Shabooyah, yah yah shabooyah role call! Shabooyah, yah yah shabooyah, role call!", "order": 51927, "speaker": "Group"},
  {"season": 9, "episode": 4, "scene": 21, "line": "My name is Kevin.", "order": 51928, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 4, "scene": 21, "line": "Yeah!", "order": 51929, "speaker": "Group"},
  {"season": 9, "episode": 4, "scene": 21, "line": "That is my name.", "order": 51930, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 4, "scene": 21, "line": "Yeah!", "order": 51931, "speaker": "Group"},
  {"season": 9, "episode": 4, "scene": 21, "line": "They call me Kevin.", "order": 51932, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 4, "scene": 21, "line": "Yeah!", "order": 51933, "speaker": "Group"},
  {"season": 9, "episode": 4, "scene": 21, "line": "Cause that's my name.", "order": 51934, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 4, "scene": 21, "line": "Role call! Shabooyah, yah yah shabooyah role call! Shabooyah, yah yah shabooyah role call!", "order": 51935, "speaker": "Group"},
  {"season": 9, "episode": 4, "scene": 22, "line": "[Dwight pulls over to pick up hitchhiker] Thanks. Playing a little hookey from work today....Oh my god.", "order": 51936, "speaker": "Creed Bratton"},
  {"season": 9, "episode": 4, "scene": 23, "line": "Dunder Mifflin road trip twenty twelve! [group takes pictures] Ah OK, now a serious one.", "order": 51937, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 4, "scene": 23, "line": "Hey, where's Dwight? He should be a part of this. Has he been acting kinda weird to you lately?", "order": 51938, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 4, "scene": 23, "line": "If by lately you mean the last twelve years, yeah.", "order": 51939, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 4, "scene": 23, "line": "No, I mean he's sulking. That's not like him.", "order": 51940, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 4, "scene": 23, "line": "He's just mad that we're all having fun.", "order": 51941, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 4, "scene": 23, "line": "Then why isn't he scheming? Or preparing to avenge?", "order": 51942, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 4, "scene": 23, "line": "He's fine. He's indestructible.", "order": 51943, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 4, "scene": 24, "line": "Always say that a child is placed for adoption, never surrendered. We're not hostages.", "order": 51944, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 4, "scene": 24, "line": "[laughs] Well, I have considered kidnapping one.", "order": 51945, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 4, "scene": 24, "line": "Never say that.", "order": 51946, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 4, "scene": 25, "line": "I am so excited thinking about this child you're going to adopt.", "order": 51947, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 4, "scene": 25, "line": "I wish I could just wave a magic wand and make you a parentless five year old again. I would snap you up.", "order": 51948, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 4, "scene": 26, "line": "Next stop pies! [group joins in]", "order": 51949, "speaker": "Stanley Hudson"},
  {"season": 9, "episode": 4, "scene": 26, "line": "Next stop pies!", "order": 51950, "speaker": "Group"},
  {"season": 9, "episode": 4, "scene": 26, "line": "Next stop pies!", "order": 51951, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 4, "scene": 26, "line": "Let's go driver! [clapping] Laverne packs up the pie wagon at five so...", "order": 51952, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 4, "scene": 26, "line": "At five? That's only twenty minutes from now. The pie shop is thirteen miles away. So at fifty five miles an hour that just gives us five minutes to spare.", "order": 51953, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 4, "scene": 26, "line": "So wait, when pies are involved you can suddenly do math in your head?", "order": 51954, "speaker": "Angela Martin"},
  {"season": 9, "episode": 4, "scene": 26, "line": "Wh...", "order": 51955, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 4, "scene": 26, "line": "Hold on, Kevin, how much is 19,154 pies divided by 61 pies?", "order": 51956, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 4, "scene": 26, "line": "314 pies.", "order": 51957, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 4, "scene": 26, "line": "What if it were salads?", "order": 51958, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 4, "scene": 26, "line": "Well, it's the...carry the four...and...it doesn't work.", "order": 51959, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 4, "scene": 26, "line": "I'm sorry to spoil Jim's fantastic voyage everyone, but we're almost out of gas.", "order": 51960, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 4, "scene": 26, "line": "OK, well I saw a station about a mile back so, chop chop.", "order": 51961, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 4, "scene": 26, "line": "That name brand place? Nope forget about it, sorry. The tanks are so big on this thing, five cents a gallon extra, that really adds up.", "order": 51962, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 4, "scene": 26, "line": "Are you kidding me? Dwight, come on!", "order": 51963, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 4, "scene": 26, "line": "Hey honey, I don't think we should push him.", "order": 51964, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 4, "scene": 26, "line": "Oh no, I'm gonna push him. You know why? Because you're getting a pie. Why? Cause you deserve it. And what is he doing? He's trying to drive us all around the countryside looking for gas because he knows we won't get there. Is that what we want?!", "order": 51965, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 4, "scene": 26, "line": "No!", "order": 51966, "speaker": "Group"},
  {"season": 9, "episode": 4, "scene": 26, "line": "Stop ordering me around, Jim!", "order": 51967, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 4, "scene": 26, "line": "What do we want?!", "order": 51968, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 4, "scene": 26, "line": "Pies!", "order": 51969, "speaker": "Group"},
  {"season": 9, "episode": 4, "scene": 26, "line": "When do we want it?!", "order": 51970, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 4, "scene": 26, "line": "Pies!", "order": 51971, "speaker": "Group"},
  {"season": 9, "episode": 4, "scene": 26, "line": "OK, fine. You win. Jim, you win. We have been battling for a long time, but you know what? You win, cause you are the winner, you are the alpha male, there you go. [drops keys in Jim's lap] Alpha male, go buy your wife a pie. Go buy the whole world a pie.", "order": 51972, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 4, "scene": 26, "line": "That's impossible. [Dwight climbs through ceiling hatch] Dwight!", "order": 51973, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 4, "scene": 26, "line": "Oh my!", "order": 51974, "speaker": "Meredith Palmer"},
  {"season": 9, "episode": 4, "scene": 26, "line": "What are you doing?", "order": 51975, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 4, "scene": 26, "line": "Dwight, what the hell?", "order": 51976, "speaker": "Meredith Palmer"},
  {"season": 9, "episode": 4, "scene": 26, "line": "Dwight!", "order": 51977, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 4, "scene": 26, "line": "What?", "order": 51978, "speaker": "Clark Green"},
  {"season": 9, "episode": 4, "scene": 26, "line": "Well now I don't even feel like pie. Wait...no it's back.", "order": 51979, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 4, "scene": 27, "line": "[Dwight's footsteps sound from the ceiling] Just drive away. Just..", "order": 51980, "speaker": "Phyllis Vance"},
  {"season": 9, "episode": 4, "scene": 27, "line": "Phyllis! That's not safe.", "order": 51981, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 4, "scene": 27, "line": "Guys, we only have eighteen minutes left. At sixty one miles an hour we're just gonna barely make it.", "order": 51982, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 4, "scene": 27, "line": "[to Jim] Go up and check on him. He's upset.", "order": 51983, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 4, "scene": 27, "line": "You know he's doing all this on purpose.", "order": 51984, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 4, "scene": 27, "line": "Please? Just make sure he's OK? [Jim climbs through hatch]", "order": 51985, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 4, "scene": 27, "line": "Hurry it up for god's sake. They're gonna be out of banana cream!", "order": 51986, "speaker": "Stanley Hudson"},
  {"season": 9, "episode": 4, "scene": 27, "line": "Banana cream is the first to go. We'll be lucky to get pumpkin at this point! [group gasps]", "order": 51987, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 4, "scene": 27, "line": "What?", "order": 51988, "speaker": "Meredith Palmer"},
  {"season": 9, "episode": 4, "scene": 28, "line": "Dwight? Why are you such a jerk? I am trying to do something for my wife and you keep derailing-", "order": 51989, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 4, "scene": 28, "line": "I'm barren, Jim.", "order": 51990, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 4, "scene": 28, "line": "What?", "order": 51991, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 4, "scene": 28, "line": "My trouser hives are void of honey. I had congress with Angela and the child that she bore did not issue from my loins. I thought I would be a father and instead I am a eunuch. Neutered by my own building.", "order": 51992, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 4, "scene": 28, "line": "Is this about the popcorn? Or the X on the ceiling? Dwight, that was a prank.", "order": 51993, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 4, "scene": 28, "line": "You mean you flooded my building with dangerous electromagnetic radiation as a prank?", "order": 51994, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 4, "scene": 28, "line": "No.", "order": 51995, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 4, "scene": 28, "line": "That's genius. That's the best prank you've ever done. [laughs]", "order": 51996, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 4, "scene": 28, "line": "I'll take it.", "order": 51997, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 4, "scene": 29, "line": "Andy?", "order": 51998, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 4, "scene": 29, "line": "Who is it?", "order": 51999, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 4, "scene": 29, "line": "Um, is this a good time?", "order": 52000, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 4, "scene": 29, "line": "Yeah. Perfect time. I'm right in the middle of a rooftop crisis. [takes paper from Nelly] Fine, let me read it. What do we have here? Uh, ok, [reading] blah blah blah blah blah, dah dah dah dah dah dah, you've made this very easy for me. It's unsignable.", "order": 52001, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 4, "scene": 29, "line": "Oh, why, is there something?", "order": 52002, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 4, "scene": 29, "line": "It's inaccurate, dishonest and...in a word? Dongwater.", "order": 52003, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 4, "scene": 29, "line": "Ah well, perhaps I could rewrite some of the-", "order": 52004, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 4, "scene": 29, "line": "Here's the thing, you asked me to do you a favor? I did it. I read it. Thank you very much to me for my time. Good luck with your impossible dream.", "order": 52005, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 4, "scene": 29, "line": "Alright then.", "order": 52006, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 4, "scene": 30, "line": "Dwight, sometimes it takes couples years to get pregnant.", "order": 52007, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 4, "scene": 30, "line": "Really? How long did it take you and Pam to conceive?", "order": 52008, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 4, "scene": 30, "line": "That doesn't matter.", "order": 52009, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 4, "scene": 30, "line": "What position did you use to conceive?", "order": 52010, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 4, "scene": 30, "line": "Ok...that's not...", "order": 52011, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 4, "scene": 30, "line": "Regular? Or lady on her back? You used lady on her back, didn't you, you freak. Yuck, gross. Never mind, Jim.", "order": 52012, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 4, "scene": 31, "line": "[To Pete. Crying sounds come from behind Nelly's curtain] British women. Famously overemotional. Am I right?", "order": 52013, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 4, "scene": 31, "line": "I don't think that's Nelly.", "order": 52014, "speaker": "Pete Miller"},
  {"season": 9, "episode": 4, "scene": 31, "line": "What?", "order": 52015, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 4, "scene": 31, "line": "Oh, oh no, no. Look, it's alright. [Erin cries, Nelly comforts her] It really isn't your fault. No, no. Look, it's...you were so kind. And it isn't anything to do with you.", "order": 52016, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 4, "scene": 32, "line": "Did you ever think that because you own the building, everyone in it, we're all kinda like your children?", "order": 52017, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 4, "scene": 32, "line": "You know there's a phrase about that in German. Bildenkinder. Used almost exclusively by childless landlords to console themselves. But now? I really understand it.", "order": 52018, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 4, "scene": 32, "line": "Well, now you have a bus full of real..bilden..kin..", "order": 52019, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 4, "scene": 32, "line": "Bildenkinder.", "order": 52020, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 4, "scene": 32, "line": "OK. And they're all dangerously close to not getting pie. And there's only one guy who can save them. It's not me.", "order": 52021, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 4, "scene": 33, "line": "Oh! [Jim reenters bus through hatch] Hey! How'd it go?", "order": 52022, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 4, "scene": 33, "line": "It's pretty good actually.", "order": 52023, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 4, "scene": 33, "line": "Yeah?", "order": 52024, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 4, "scene": 33, "line": "We bonded. We got to- [Dwight starts dropping into the bus on top of Jim]", "order": 52025, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 4, "scene": 33, "line": "Whoa! Whoa! Dwight! [group reacts]", "order": 52026, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 4, "scene": 33, "line": "When you don't get out of the way! Out of the way!", "order": 52027, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 4, "scene": 33, "line": "You feel OK now?", "order": 52028, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 4, "scene": 33, "line": "Oh, better than OK. [grabs Pam's shoulders] You know what honey? I'm gonna get you that rhubarb pie.", "order": 52029, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 4, "scene": 33, "line": "Well, actually, rhubarb is-", "order": 52030, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 4, "scene": 33, "line": "Don't..", "order": 52031, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 4, "scene": 33, "line": "the one pie that I don't.", "order": 52032, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 4, "scene": 33, "line": "Don't..", "order": 52033, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 4, "scene": 33, "line": "Everybody! Hang on! [Dwight pulls out quickly]", "order": 52034, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 4, "scene": 33, "line": "Oh! [bus tears around corners as group crashes into each other]", "order": 52035, "speaker": "Angela Martin"},
  {"season": 9, "episode": 4, "scene": 34, "line": "[Group chants along] Pie! Pie! Pie!", "order": 52036, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 4, "scene": 34, "line": "Pie! Pie! Pie! Pie! [cheers as they arrive]", "order": 52037, "speaker": "Group"},
  {"season": 9, "episode": 4, "scene": 35, "line": "Oh.", "order": 52038, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 4, "scene": 35, "line": "I changed my mind. [gives Nelly papers]", "order": 52039, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 4, "scene": 35, "line": "Oh, you signed it?", "order": 52040, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 4, "scene": 35, "line": "Yeah. Not as is, obviously. Made a couple changes. Added some sentences at the end. Trust me it needed it. [laughs] Well, yeah, whatever. So. [walks away]", "order": 52041, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 4, "scene": 36, "line": "[Reading] 'She's tough in business, but tender with the people she cares about. She'll make a wonderful mother to any child who can overlook weird accents.'", "order": 52042, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 4, "scene": 37, "line": "I insult you, Oscar.", "order": 52043, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 4, "scene": 37, "line": "What?", "order": 52044, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 4, "scene": 37, "line": "I insult you! To your face!", "order": 52045, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 4, "scene": 37, "line": "I don't know what you're talking about.", "order": 52046, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 4, "scene": 37, "line": "Then why don't you do something about it?", "order": 52047, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 4, "scene": 37, "line": "[laughs] Kevin, are you trying to get me to hit you? In the face with my pie?", "order": 52048, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 4, "scene": 37, "line": "You don't have the guts. You stupid, dumb, doo doo face! [Oscar pies Kevin] Yes!", "order": 52049, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 4, "scene": 38, "line": "Oh my god. I'm getting so stuffed.", "order": 52050, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 4, "scene": 38, "line": "We did it.", "order": 52051, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 4, "scene": 38, "line": "You did it.", "order": 52052, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 4, "scene": 39, "line": "My name is Andy!", "order": 52053, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 4, "scene": 39, "line": "[bored] Yeah.", "order": 52054, "speaker": "Group"},
  {"season": 9, "episode": 4, "scene": 39, "line": "I don't do drugs!", "order": 52055, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 4, "scene": 39, "line": "Yeah.", "order": 52056, "speaker": "Group"},
  {"season": 9, "episode": 4, "scene": 39, "line": "Now check the style!", "order": 52057, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 4, "scene": 39, "line": "Yeah.", "order": 52058, "speaker": "Group"},
  {"season": 9, "episode": 4, "scene": 39, "line": "Of Flatt & Scruggs!", "order": 52059, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 4, "scene": 39, "line": "Yeah. [Andy plays banjo]", "order": 52060, "speaker": "Group"},
  {"season": 9, "episode": 4, "scene": 39, "line": "Role call.", "order": 52061, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 4, "scene": 39, "line": "Role call.", "order": 52062, "speaker": "Phyllis Vance"},
  {"season": 9, "episode": 4, "scene": 39, "line": "Role call.", "order": 52063, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 4, "scene": 39, "line": "What?", "order": 52064, "speaker": "Creed Bratton"},
  {"season": 9, "episode": 5, "scene": 1, "line": "This year I decided to really get into the spirit of Halloween.", "order": 52065, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 5, "scene": 2, "line": "[screaming upon seeing pumpkin-headed Dwight]", "order": 52066, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 5, "scene": 2, "line": "[screaming then laughing] It may have been the costliest decision I've ever made.", "order": 52067, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 5, "scene": 3, "line": "My greased up head went into the pumpkin no problem, but ...", "order": 52068, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 5, "scene": 3, "line": "It won't budge.", "order": 52069, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 5, "scene": 3, "line": "I can't get it out. Try again!", "order": 52070, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 5, "scene": 4, "line": "I mean, I could try destroying the pumpkin...", "order": 52071, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 5, "scene": 4, "line": "[as Jim approaches with a knife] Jim, no. No. No! No!", "order": 52072, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 5, "scene": 5, "line": "But as Jim and I discovered... No! ... any blow to the pumpkin itself could prove fatal to me.", "order": 52073, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 5, "scene": 6, "line": "At first I drove myself crazy thinking about the things I should have done differently. I never should have played that joke on Erin. I never should have hollowed out this damn pumpkin in the first place. Then I realized that I was being silly. I mean the pumpkin should rot off of my head in a month or two. Right?", "order": 52074, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 5, "scene": 7, "line": "Hey guys.", "order": 52075, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 5, "scene": 7, "line": "Hey.", "order": 52076, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 5, "scene": 7, "line": "Pam, what are you?", "order": 52077, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 5, "scene": 7, "line": "I am Dr. Cinderella.", "order": 52078, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 5, "scene": 7, "line": "Cece's really into princesses now. So we decided to turn them into a positive female role models.", "order": 52079, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 5, "scene": 7, "line": "I'm an oncologist and you are a dog.", "order": 52080, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 5, "scene": 7, "line": "No, I'm a puppy. Dang it! I was worried that would happen.", "order": 52081, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 5, "scene": 7, "line": "It's Okay.", "order": 52082, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 5, "scene": 7, "line": "Uh, Jim, you're not dressed up at all.", "order": 52083, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 5, "scene": 7, "line": "Sure I am. I am... one of the Men in Black guys. [to Pam, under his breath] Can I have your sunglasses?", "order": 52084, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 5, "scene": 7, "line": "Jim, come on. I thought we were past this.", "order": 52085, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 5, "scene": 8, "line": "So the sports marketing business that Jim told everyone about except for me?", "order": 52086, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 5, "scene": 8, "line": "There's a big investment lunch today, so I decided to skip the costume.", "order": 52087, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 5, "scene": 8, "line": "Unless he has a secret costume that he told everyone about except for me.", "order": 52088, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 5, "scene": 8, "line": "Gettin' a lot of mileage out of this, aren't ya?", "order": 52089, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 5, "scene": 8, "line": "Yeah, well, get used to it, bud.", "order": 52090, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 5, "scene": 9, "line": "A jitterbug. [giggles] You guys look great! Just a reminder. The party is right after lunch, so make sure you get all your work done before that or throw it out. Any questions?", "order": 52091, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 5, "scene": 9, "line": "The senator will be joining us later.", "order": 52092, "speaker": "Angela Martin"},
  {"season": 9, "episode": 5, "scene": 9, "line": "Not a question.", "order": 52093, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 5, "scene": 9, "line": "No, it wasn't.", "order": 52094, "speaker": "Angela Martin"},
  {"season": 9, "episode": 5, "scene": 9, "line": "Excellent. That reminds me, has anyone seen Treble? Anyone? I could have sworn I saw some Treble somewhere.", "order": 52095, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 5, "scene": 9, "line": "[singing]", "order": 52096, "speaker": "HCT"},
  {"season": 9, "episode": 5, "scene": 9, "line": "Oh, ladies and gentlemen, Here Comes Treble!", "order": 52097, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 5, "scene": 9, "line": "[sings Karma Chameleon]", "order": 52098, "speaker": "HCT"},
  {"season": 9, "episode": 5, "scene": 9, "line": "Aaah! [everyone clapping] So good!", "order": 52099, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 5, "scene": 9, "line": "What lab did these little clones escape from?", "order": 52100, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 5, "scene": 9, "line": "My Cornell a capella group.", "order": 52101, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 5, "scene": 9, "line": "You were in an a capella group?", "order": 52102, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 5, "scene": 9, "line": "You went to Cornell?", "order": 52103, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 5, "scene": 9, "line": "Yah! ah. Okay. Ha ha ha ha. But you have no idea how lucky you are because HCT is doing a set at our halloween party.", "order": 52104, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 5, "scene": 9, "line": "Ugh. I don't want to sit through a whole concert of that.", "order": 52105, "speaker": "Stanley Hudson"},
  {"season": 9, "episode": 5, "scene": 9, "line": "I do. I love the boss's interests.", "order": 52106, "speaker": "Clark Green"},
  {"season": 9, "episode": 5, "scene": 9, "line": "Atta boy Clark!", "order": 52107, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 5, "scene": 9, "line": "Where you boys stayin? How does it work in the rooms? Do you get a privacy partition?", "order": 52108, "speaker": "Meredith Palmer"},
  {"season": 9, "episode": 5, "scene": 9, "line": "No. Nope. Nope nope nope. Stay away.", "order": 52109, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 5, "scene": 10, "line": "You know what I just realized? They might actually call me up to solo on George Michael's Faith. That was one of my signature songs. Oh, man. That would be insane. I'm so not prepared.", "order": 52110, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 5, "scene": 11, "line": "Are you sure you're okay with me putting in this much money.", "order": 52111, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 5, "scene": 11, "line": "Yeah. I mean, listen if we're gonna do this thing, we should do it right.", "order": 52112, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 5, "scene": 11, "line": "You're the best.", "order": 52113, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 5, "scene": 11, "line": "I kind of am. It's crazy.", "order": 52114, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 5, "scene": 11, "line": "Okay. I'll see you in a little bit.", "order": 52115, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 5, "scene": 11, "line": "Okay.", "order": 52116, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 5, "scene": 11, "line": "Jim. Look I'm eating you.", "order": 52117, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 5, "scene": 11, "line": "Shut up.", "order": 52118, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 5, "scene": 11, "line": "Ha ha. Hey Erin, look, these are Nerds. I'm eating Jims.", "order": 52119, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 5, "scene": 11, "line": "[laughs]", "order": 52120, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 5, "scene": 11, "line": "[laughing] Must eat more Jims. Oink oink oink oink.", "order": 52121, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 5, "scene": 11, "line": "[laughing hysterically] Stop it! Stop it! Stop it!", "order": 52122, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 5, "scene": 11, "line": "Oh no, I'm spilling Jim all over the carpet. [laughing]", "order": 52123, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 5, "scene": 11, "line": "Stop it stop it stop it stop it!", "order": 52124, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 5, "scene": 11, "line": "[picking up spilled candy] Hello little pill. What do you do?", "order": 52125, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 5, "scene": 12, "line": "Dumatril is licensed to treat anxiety symptoms, such as panic attacks, excessive worrying, and fear. Translation: There's a madman in our midst.", "order": 52126, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 5, "scene": 13, "line": "Okay I give up. What are you?", "order": 52127, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 5, "scene": 13, "line": "I'm sexy Toby.", "order": 52128, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 5, "scene": 13, "line": "[laughing] Gross. I love it.", "order": 52129, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 5, "scene": 13, "line": "Dumatril!", "order": 52130, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 5, "scene": 13, "line": "Something wrong Dwight?", "order": 52131, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 5, "scene": 13, "line": "Dumatril.", "order": 52132, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 5, "scene": 13, "line": "Yes?", "order": 52133, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 5, "scene": 13, "line": "This is a pill that I found here in the office. But it's not for worms or eczema like any normal pill. It's not for any disorder of the body. [whispering] It's for a disorder of the mind.", "order": 52134, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 5, "scene": 13, "line": "The mind is part of the body.", "order": 52135, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 5, "scene": 13, "line": "Okay, this is a pill that combats insanity, okay? Whoever is taking it is not only insane... [whispering] They are now off their meds.", "order": 52136, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 5, "scene": 13, "line": "Dwight, our co-workers' health issues are really none of our business so-", "order": 52137, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 5, "scene": 13, "line": "Why are you trying so hard to bury this thing, huh? What's going on Nellie? Talk to me.", "order": 52138, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 5, "scene": 13, "line": "Hm?", "order": 52139, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 5, "scene": 14, "line": "It's my pill. I have an anxiety issue and I'm not ashamed of that, But I'm not loving the idea of Dwight having that information. I once saw him yell at Phyllis for sneezing wrong.", "order": 52140, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 5, "scene": 15, "line": "Yeah, you're right. This man needs to be apprehended.", "order": 52141, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 5, "scene": 15, "line": "I'll get my apprehension kit.", "order": 52142, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 5, "scene": 16, "line": "Ruh duh duh da dudes! What's up?", "order": 52143, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 5, "scene": 16, "line": "[mumbled responses]", "order": 52144, "speaker": "HCT"},
  {"season": 9, "episode": 5, "scene": 17, "line": "I know that it's pathetic to re-live your college years, but cut me some slack, Okay? Because I was a freaking rock star in college. When I joined Here Comes Treble, that's when I became somebody. When I got the nickname 'Boner Champ,' that is when I became me.", "order": 52145, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 5, "scene": 18, "line": "You didn't come here to sit in a room, right? You came here for some intergenerational bro time. Well, now's your chance! I'm here. You got Qs; I got As.", "order": 52146, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 5, "scene": 18, "line": "Did you say you've got AIDS?", "order": 52147, "speaker": "HCT Member #1"},
  {"season": 9, "episode": 5, "scene": 18, "line": "No, I don't have AIDS. That's not what I said. Next question.", "order": 52148, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 5, "scene": 18, "line": "You don't have any-- Any questions about the old days? [clears throat] You at least want to know why they call me Boner Champ?", "order": 52149, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 5, "scene": 18, "line": "I thought Broccoli Rob was the Boner Champ.", "order": 52150, "speaker": "HCT Member #2"},
  {"season": 9, "episode": 5, "scene": 18, "line": "I'm sorry, what?", "order": 52151, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 5, "scene": 18, "line": "I just thought Broccoli Rob was the Boner Champ.", "order": 52152, "speaker": "HCT Member #2"},
  {"season": 9, "episode": 5, "scene": 18, "line": "Broccoli Rob was Broccoli Rob. Andy Bernard is the Boner Champ.", "order": 52153, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 5, "scene": 19, "line": "I didn't realize that everybody here dresses up every year.", "order": 52154, "speaker": "Pete Miller"},
  {"season": 9, "episode": 5, "scene": 19, "line": "Me neither.", "order": 52155, "speaker": "Creed Bratton"},
  {"season": 9, "episode": 5, "scene": 20, "line": "It's Halloween. That is really, really good timing.", "order": 52156, "speaker": "Creed Bratton"},
  {"season": 9, "episode": 5, "scene": 21, "line": "I was just talking to some of the actives, and they think that you're Boner Champ.", "order": 52157, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 5, "scene": 21, "line": "[on computer monitor] I'm so sorry! I don't know how that could have happened.", "order": 52158, "speaker": "Broccoli Rob"},
  {"season": 9, "episode": 5, "scene": 21, "line": "Did you maybe tell them that or...?", "order": 52159, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 5, "scene": 21, "line": "I just-- I just started yappin' about the old days, and I guess the wine coolers were flowing, and, you know, somehow things just got hinky.", "order": 52160, "speaker": "Broccoli Rob"},
  {"season": 9, "episode": 5, "scene": 21, "line": "Could you just call them and tell them the truth? 'Cause I know it's really stupid, but it's also really, really, really important.", "order": 52161, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 5, "scene": 21, "line": "Will do. I love you, Andy.", "order": 52162, "speaker": "Broccoli Rob"},
  {"season": 9, "episode": 5, "scene": 21, "line": "Love you too.", "order": 52163, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 5, "scene": 22, "line": "So the workspace looks awesome.", "order": 52164, "speaker": "Businessman #1"},
  {"season": 9, "episode": 5, "scene": 22, "line": "Wow.", "order": 52165, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 5, "scene": 22, "line": "And the graphic designer is going to be sending in some of the logo treatments. I can't wait to see them.", "order": 52166, "speaker": "Businessman #2"},
  {"season": 9, "episode": 5, "scene": 22, "line": "You guys rock. [round of fistbumps]", "order": 52167, "speaker": "Businessman #3"},
  {"season": 9, "episode": 5, "scene": 22, "line": "I killed it.", "order": 52168, "speaker": "Businessman #1"},
  {"season": 9, "episode": 5, "scene": 22, "line": "I've also been running the numbers and between our backers and our own investments, we're looking great for a full year on this.", "order": 52169, "speaker": "Businessman #2"},
  {"season": 9, "episode": 5, "scene": 22, "line": "Oh, uh, is it too late to get in?", "order": 52170, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 5, "scene": 22, "line": "Oh Jim, I explained everything. So you're all set.", "order": 52171, "speaker": "Businessman #3"},
  {"season": 9, "episode": 5, "scene": 22, "line": "Oh, I actually talked to my wife and we'd really like to uh you know, invest - get in on the ground floor.", "order": 52172, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 5, "scene": 22, "line": "Wow, well what level of investment were you thinking about?", "order": 52173, "speaker": "Businessman #2"},
  {"season": 9, "episode": 5, "scene": 22, "line": "We were thinking somewhere between five - ten thousand? I can do the full ten thousand. We should just - [blows air] - all in.", "order": 52174, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 5, "scene": 22, "line": "Welcome aboard!", "order": 52175, "speaker": "Businessman #3"},
  {"season": 9, "episode": 5, "scene": 22, "line": "All right. [laughs] Awesome. Cool.", "order": 52176, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 5, "scene": 23, "line": "Hi Daryl. I'm just here to smear some peanut butter on my forehead.", "order": 52177, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 5, "scene": 23, "line": "You know, to uh, to protect his brain from the nanobots that the government put in the air conditioning.", "order": 52178, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 5, "scene": 23, "line": "That makes sense to you, right? Or does it sound... crazy?", "order": 52179, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 5, "scene": 23, "line": "I can't really picture it. Can you... get it on there. Yeah. And maybe, get the cheeks.", "order": 52180, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 5, "scene": 23, "line": "So this makes sense then. Or is it crazy?", "order": 52181, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 5, "scene": 23, "line": "Get under your chin first. Yeah.", "order": 52182, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 5, "scene": 23, "line": "Is that where the nanobots like to come in?", "order": 52183, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 5, "scene": 23, "line": "Take it all the way up to your lip, yeah.", "order": 52184, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 5, "scene": 23, "line": "Is that how they like to get in?", "order": 52185, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 5, "scene": 23, "line": "Yeah, that's crazy.", "order": 52186, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 5, "scene": 23, "line": "[whispering] I don't know. I just don't know.", "order": 52187, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 5, "scene": 24, "line": "Yo! Bad boys of a capella.", "order": 52188, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 5, "scene": 24, "line": "[mumbling] Hey.", "order": 52189, "speaker": "HCT"},
  {"season": 9, "episode": 5, "scene": 24, "line": "Heard any good stories lately? Or new twists on old stories?", "order": 52190, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 5, "scene": 24, "line": "Yeah, I got a call from Broccoli Rob. I guess you really are the Boner Champ.", "order": 52191, "speaker": "HCT Member #3"},
  {"season": 9, "episode": 5, "scene": 24, "line": "Did he tell you how I got the name?", "order": 52192, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 5, "scene": 24, "line": "No.", "order": 52193, "speaker": "HCT Member #3"},
  {"season": 9, "episode": 5, "scene": 24, "line": "Spring sing '95. Got completely ripped on Bud Dries. I had sex with a snowman. I just went at that thing. Cold would have stopped most people but I stayed locked in, you know. Took the face off. It just seemed easier that way.", "order": 52194, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 5, "scene": 25, "line": "And I told Phyllis not to put it out, but she insisted. So, anyway... Hi! Oh, Oscar, remember my husband, the senator?", "order": 52195, "speaker": "Angela Martin"},
  {"season": 9, "episode": 5, "scene": 25, "line": "Senator Lipton, nice to see you.", "order": 52196, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 5, "scene": 25, "line": "Nice to see you Oscar.", "order": 52197, "speaker": "Senator Lipton"},
  {"season": 9, "episode": 5, "scene": 25, "line": "Wait a second, who designed this spread? The sweets and savories are all mixed together. This is mayhem!", "order": 52198, "speaker": "Angela Martin"},
  {"season": 9, "episode": 5, "scene": 25, "line": "So Oscar, you're a dinosaur.", "order": 52199, "speaker": "Senator Lipton"},
  {"season": 9, "episode": 5, "scene": 25, "line": "Actually I'm the electoral college.", "order": 52200, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 5, "scene": 25, "line": "Ouch! Right on target.", "order": 52201, "speaker": "Senator Lipton"},
  {"season": 9, "episode": 5, "scene": 25, "line": "You know what? This is outrageous. I have to find Phyllis. You two talk, okay? Sorry babe.. Phyllis!", "order": 52202, "speaker": "Angela Martin"},
  {"season": 9, "episode": 5, "scene": 25, "line": "God, it's just so good to see you.", "order": 52203, "speaker": "Senator Lipton"},
  {"season": 9, "episode": 5, "scene": 25, "line": "I, uh, huh, just [chuckles nervously]", "order": 52204, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 5, "scene": 25, "line": "All having this wonderful Halloween gathering. Excuse me, is that punch?", "order": 52205, "speaker": "Senator Lipton"},
  {"season": 9, "episode": 5, "scene": 26, "line": "They didn't know about the snowman story, and when I told 'em, they were not impressed.", "order": 52206, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 5, "scene": 26, "line": "What is with these turkeys?", "order": 52207, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 5, "scene": 26, "line": "Right?", "order": 52208, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 5, "scene": 27, "line": "Hey! You better do 'Faith.' You get me?", "order": 52209, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 5, "scene": 27, "line": "We don't know it.", "order": 52210, "speaker": "HCT Member #3"},
  {"season": 9, "episode": 5, "scene": 27, "line": "So learn it. You all go to Cornell, you're like eight Rain men. Just learning the friggin' song.", "order": 52211, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 5, "scene": 27, "line": "Look, I know it was big with the old guys, but--", "order": 52212, "speaker": "HCT Member #3"},
  {"season": 9, "episode": 5, "scene": 27, "line": "Buts... are for pooping. Okay? Make it work. You have to or Andy will flip out... And make it a surprise, please. [to Pete] This isn't stupid.", "order": 52213, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 5, "scene": 27, "line": "What?", "order": 52214, "speaker": "Pete Miller"},
  {"season": 9, "episode": 5, "scene": 28, "line": "Hey.", "order": 52215, "speaker": "Toby Flenderson"},
  {"season": 9, "episode": 5, "scene": 28, "line": "Hey.", "order": 52216, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 5, "scene": 28, "line": "Hey Toby.", "order": 52217, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 5, "scene": 28, "line": "Are... are you me?", "order": 52218, "speaker": "Toby Flenderson"},
  {"season": 9, "episode": 5, "scene": 28, "line": "Yes.", "order": 52219, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 5, "scene": 28, "line": "Oh my goodness, look. Look at this.", "order": 52220, "speaker": "Toby Flenderson"},
  {"season": 9, "episode": 5, "scene": 28, "line": "Yeah. I...", "order": 52221, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 5, "scene": 28, "line": "[unintelligible mumbling]", "order": 52222, "speaker": "Toby Flenderson"},
  {"season": 9, "episode": 5, "scene": 28, "line": "Yes. I thought I'd you know, be you.", "order": 52223, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 5, "scene": 28, "line": "Look at.. Look at me. [laughs]", "order": 52224, "speaker": "Toby Flenderson"},
  {"season": 9, "episode": 5, "scene": 28, "line": "[laughing] It's funny right? [Toby starts to lean in for a kiss, then runs away]", "order": 52225, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 5, "scene": 28, "line": "All right. All right, just ...stay focused on the pill.", "order": 52226, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 5, "scene": 28, "line": "Okay, look Dwight, let's just call this thing off. I mean, it's just an anxiety pill. Lots of people have anxiety.", "order": 52227, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 5, "scene": 28, "line": "You think I don't have anxiety? I have anxiety all the time. Every waking moment of my life is sheer torture. I have land disputes I've got to settle and idiot cousins to protect. And ne'er- do- well siblings to take care of. But I don't need some stupid pill to get me through all this.", "order": 52228, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 5, "scene": 28, "line": "Cool. Free upper.", "order": 52229, "speaker": "Meredith Palmer"},
  {"season": 9, "episode": 5, "scene": 28, "line": "Ah ha! Ha!!! The jig is up, psychopath! Ah yeah! Gotcha!", "order": 52230, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 5, "scene": 28, "line": "Don't dog catch me!", "order": 52231, "speaker": "Meredith Palmer"},
  {"season": 9, "episode": 5, "scene": 28, "line": "Gotcha! Yeah! Let's see ya get out of this web, huh?", "order": 52232, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 5, "scene": 28, "line": "Let me out!", "order": 52233, "speaker": "Meredith Palmer"},
  {"season": 9, "episode": 5, "scene": 28, "line": "The pill is mine.", "order": 52234, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 5, "scene": 28, "line": "What?", "order": 52235, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 5, "scene": 28, "line": "Get her out.", "order": 52236, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 5, "scene": 28, "line": "Oh.", "order": 52237, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 5, "scene": 28, "line": "Stop baggin' my head!", "order": 52238, "speaker": "Meredith Palmer"},
  {"season": 9, "episode": 5, "scene": 28, "line": "Oh Dwight, look, its just a pill, all right? It's for anxiety. I take it every day. And it makes me feel better. And maybe it could help you too.", "order": 52239, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 5, "scene": 29, "line": "Hey, how'd it go?", "order": 52240, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 5, "scene": 29, "line": "Oh man, it was great. They were great.", "order": 52241, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 5, "scene": 29, "line": "Did you end up investing?", "order": 52242, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 5, "scene": 29, "line": "I did, yeah.", "order": 52243, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 5, "scene": 29, "line": "How much?", "order": 52244, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 5, "scene": 29, "line": "Uh, man, by the end I guess it was about... ten...", "order": 52245, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 5, "scene": 29, "line": "About ten?", "order": 52246, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 5, "scene": 29, "line": "Ten. It was the full ten.", "order": 52247, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 5, "scene": 29, "line": "Wow.", "order": 52248, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 5, "scene": 29, "line": "Yeah.", "order": 52249, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 5, "scene": 29, "line": "Wow.", "order": 52250, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 5, "scene": 29, "line": "Yeah. Yeah. It's a good thing we talked about it though, because we had to...", "order": 52251, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 5, "scene": 29, "line": "No yeah. Yeah.", "order": 52252, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 5, "scene": 29, "line": "Yeah.", "order": 52253, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 5, "scene": 29, "line": "So did everybody ...", "order": 52254, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 5, "scene": 29, "line": "What is it?", "order": 52255, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 5, "scene": 29, "line": "Did everybody end up investing ten thousand?", "order": 52256, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 5, "scene": 29, "line": "Um, oh man, I don't actually know.", "order": 52257, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 5, "scene": 29, "line": "What?", "order": 52258, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 5, "scene": 29, "line": "They weren't really talking that much about money. They just said, We're good with investing and then I...and I...", "order": 52259, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 5, "scene": 29, "line": "Ladies and gentlemen, Here Comes Treble!", "order": 52260, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 5, "scene": 29, "line": "[vocalizing]", "order": 52261, "speaker": "HCT"},
  {"season": 9, "episode": 5, "scene": 29, "line": "They said they were done with the investing and then you volunteered ten thousand dollars?", "order": 52262, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 5, "scene": 29, "line": "No, no, I had to. Look, I needed to look like a team player, Pam.", "order": 52263, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 5, "scene": 29, "line": "So you invested ten thousand dollars to look like a team player?", "order": 52264, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 5, "scene": 29, "line": "You weren't there.", "order": 52265, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 5, "scene": 29, "line": "[singing 'I'll Be']", "order": 52266, "speaker": "HCT"},
  {"season": 9, "episode": 5, "scene": 29, "line": "It was very clear that ten thousand was what we had...we should talk about it later.", "order": 52267, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 5, "scene": 29, "line": "[singing 'I'll Be' directly to Pam]", "order": 52268, "speaker": "HCT"},
  {"season": 9, "episode": 5, "scene": 29, "line": "Talk about it now.", "order": 52269, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 5, "scene": 29, "line": "Pam.", "order": 52270, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 5, "scene": 29, "line": "Jim, that was most of our savings.", "order": 52271, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 5, "scene": 29, "line": "[still singing]", "order": 52272, "speaker": "HCT"},
  {"season": 9, "episode": 5, "scene": 29, "line": "Wait! Wait. Hold on. Where's the band? 'Cause there's just no way you guys are making this magic with just your mouths.", "order": 52273, "speaker": "Clark Green"},
  {"season": 9, "episode": 5, "scene": 29, "line": "Yeah. That's what she said.", "order": 52274, "speaker": "Creed Bratton"},
  {"season": 9, "episode": 5, "scene": 30, "line": "What, am I overdoing it? No. No.", "order": 52275, "speaker": "Clark Green"},
  {"season": 9, "episode": 5, "scene": 31, "line": "[singing]", "order": 52276, "speaker": "HCT"},
  {"season": 9, "episode": 5, "scene": 31, "line": "We said some. We said 'some.'", "order": 52277, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 5, "scene": 31, "line": "We'll talk about it later.", "order": 52278, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 5, "scene": 31, "line": "[singing]", "order": 52279, "speaker": "HCT"},
  {"season": 9, "episode": 5, "scene": 31, "line": "We said part not all.", "order": 52280, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 5, "scene": 32, "line": "[singing 'Car Wash']", "order": 52281, "speaker": "HCT"},
  {"season": 9, "episode": 5, "scene": 32, "line": "Yes! All right!", "order": 52282, "speaker": "Clark Green"},
  {"season": 9, "episode": 5, "scene": 32, "line": "Oh Man! [clapping]", "order": 52283, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 5, "scene": 32, "line": "That's how you do that! Whoo!", "order": 52284, "speaker": "Clark Green"},
  {"season": 9, "episode": 5, "scene": 32, "line": "Thank you.", "order": 52285, "speaker": "HCT"},
  {"season": 9, "episode": 5, "scene": 32, "line": "Show some pride. This is crap.", "order": 52286, "speaker": "Stanley Hudson"},
  {"season": 9, "episode": 5, "scene": 32, "line": "I agree. Yes, crap. Continue.", "order": 52287, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 5, "scene": 32, "line": "Now folks, by special request, we're going to take it a little old school. There is a former Trebler in this room.", "order": 52288, "speaker": "HCT Member #3"},
  {"season": 9, "episode": 5, "scene": 32, "line": "Who?", "order": 52289, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 5, "scene": 32, "line": "It's Mr. Andy Bernard!", "order": 52290, "speaker": "HCT Member #3"},
  {"season": 9, "episode": 5, "scene": 32, "line": "[clapping]", "order": 52291, "speaker": "All"},
  {"season": 9, "episode": 5, "scene": 32, "line": "[singing 'Faith']", "order": 52292, "speaker": "HCT"},
  {"season": 9, "episode": 5, "scene": 32, "line": "No. Do not sing that. Do not... Oh man...", "order": 52293, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 5, "scene": 32, "line": "He reminded us today of how much he means to us. And we certainly seem to mean a lot to him, so without any further ado, here's an old Treble classic.", "order": 52294, "speaker": "HCT Member #3"},
  {"season": 9, "episode": 5, "scene": 32, "line": "[singing 'Faith']", "order": 52295, "speaker": "HCT"},
  {"season": 9, "episode": 5, "scene": 32, "line": "[on flat screen tv] [singing lead of Faith]", "order": 52296, "speaker": "Broccoli Rob"},
  {"season": 9, "episode": 5, "scene": 32, "line": "Whoa! Whoa! What the hell is Broccoli Rob doing here?", "order": 52297, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 5, "scene": 32, "line": "She said you wanted to hear 'Faith'. That's Broccoli Rob's signature song.", "order": 52298, "speaker": "HCT Member #3"},
  {"season": 9, "episode": 5, "scene": 32, "line": "That's my signature song.", "order": 52299, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 5, "scene": 32, "line": "I really didn't know that man. I just thought you wanted to hear it.", "order": 52300, "speaker": "HCT Member #3"},
  {"season": 9, "episode": 5, "scene": 32, "line": "Russell, I'm dressed like George Michael.", "order": 52301, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 5, "scene": 32, "line": "I thought you were Adam Lambert.", "order": 52302, "speaker": "HCT Member #3"},
  {"season": 9, "episode": 5, "scene": 32, "line": "Wha...?", "order": 52303, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 5, "scene": 33, "line": "The more I hear about all this a capella drama, the more I think it's kind of pathetic. But when you're with someone, you put up with the stuff that makes you lose respect for them, and that is love.", "order": 52304, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 5, "scene": 34, "line": "Are you okay?", "order": 52305, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 5, "scene": 34, "line": "[on screen] He's still mad.", "order": 52306, "speaker": "Broccoli Rob"},
  {"season": 9, "episode": 5, "scene": 34, "line": "Shut up, Broccoli.", "order": 52307, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 5, "scene": 34, "line": "Champ, I feel awful about this whole thing. Russell called me up. And they said they needed 20 cc's of George Michael stat. So just... Wham! I sprang into action. You know me. I assumed you wanted to hear me do your signature number.", "order": 52308, "speaker": "Broccoli Rob"},
  {"season": 9, "episode": 5, "scene": 34, "line": "You thought I wanted to sit in the audience like some slutty Treb rat? A man's signature solo is his for life, okay? That's group policy and you know it.", "order": 52309, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 5, "scene": 34, "line": "Look, it's not my fault that I still live near campus, and it's my duty as an alum to be friendly to the young guys., and stop in two, three times a week.", "order": 52310, "speaker": "Broccoli Rob"},
  {"season": 9, "episode": 5, "scene": 34, "line": "Just don't do the song anymore.", "order": 52311, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 5, "scene": 34, "line": "I tell you what, we'll have a sing-off for it. You pick twelve alums from any year to back you up and I'll do the same, and I'm so confident that I'll win, I won't even warm up.", "order": 52312, "speaker": "Broccoli Rob"},
  {"season": 9, "episode": 5, "scene": 34, "line": "Fine, go ahead. Thrash your pipes.", "order": 52313, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 5, "scene": 34, "line": "My pipes are primo, Champ. Why don't you ask Trey Anastasio about my pipes?", "order": 52314, "speaker": "Broccoli Rob"},
  {"season": 9, "episode": 5, "scene": 34, "line": "I knew you would go there, you son of a bitch!", "order": 52315, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 5, "scene": 34, "line": "He said, and I quote, 'Hey Rob, nice pipes'. That happened!", "order": 52316, "speaker": "Broccoli Rob"},
  {"season": 9, "episode": 5, "scene": 34, "line": "OK, fine, yeah. that's one guy's opinion!", "order": 52317, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 5, "scene": 34, "line": "That's real. 'That'll never change!", "order": 52318, "speaker": "Broccoli Rob"},
  {"season": 9, "episode": 5, "scene": 34, "line": "Okay!", "order": 52319, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 5, "scene": 34, "line": "Doesn't mean you're the best singer ever. Dick.", "order": 52320, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 5, "scene": 35, "line": "I thought that concert was pretty great.", "order": 52321, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 5, "scene": 35, "line": "Oh yeah. I decided that acapelca music is awesome.", "order": 52322, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 5, "scene": 35, "line": "They lost me when they sang 'Monster Mash'. That song obviously glorifies the occult.", "order": 52323, "speaker": "Angela Martin"},
  {"season": 9, "episode": 5, "scene": 35, "line": "Angela, it's Halloween. You have to sing 'Monster Mash'.", "order": 52324, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 5, "scene": 35, "line": "Oh you have to Jim? You literally have to?", "order": 52325, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 5, "scene": 35, "line": "Uh...", "order": 52326, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 5, "scene": 35, "line": "No I'm just, I'm saying, what would happen if they didn't sing it? Would they go to jail? Would they be shot?", "order": 52327, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 5, "scene": 35, "line": "Okay. We'll, just forget it.", "order": 52328, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 5, "scene": 35, "line": "No! No, I'm interested. I mean I think everybody's interested in why they have to sing it.", "order": 52329, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 5, "scene": 35, "line": "Because it is Halloween. So if you're going to sing a concert, it's a good idea to throw that one in.", "order": 52330, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 5, "scene": 35, "line": "Yeah, yeah, no, no. It's a good idea to brush your teeth. But you have to um, feed your children. Send them to school. You know, all things you can't do if you just keep singing 'Monster Mash.'", "order": 52331, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 5, "scene": 36, "line": "It turns out, that Pam? Really, really hates 'Monster Mash.' I mean like, never bring that song up in front of her. Even though Jim was making great points, like, in favor of the song, Pam was like, No! Hate it! Stupid!", "order": 52332, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 5, "scene": 37, "line": "This is all so silly right? What am I gonna do? Move back to Cornell?", "order": 52333, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 5, "scene": 37, "line": "[laughing] Yeah.", "order": 52334, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 5, "scene": 37, "line": "I mean, what if we did that? Like we got jobs and we were happy all the time?", "order": 52335, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 5, "scene": 37, "line": "Oh well, Andy, we're not moving to Cornell.", "order": 52336, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 5, "scene": 37, "line": "Duh. I know. That would be insane.", "order": 52337, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 5, "scene": 37, "line": "Yeah.", "order": 52338, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 5, "scene": 37, "line": "It could totally work though. I don't know why we wouldn't. Oh my god are we doing this?", "order": 52339, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 5, "scene": 37, "line": "Oy. Andy, what's going on?", "order": 52340, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 5, "scene": 37, "line": "If I am not Boner Champ, I don't know who I am.", "order": 52341, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 5, "scene": 37, "line": "Well, um, you know maybe you're the wise old guy that the new uh, B-O-N-E-R champ looks up to. You know, you could just--", "order": 52342, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 5, "scene": 37, "line": "Make a donation.", "order": 52343, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 5, "scene": 37, "line": "Well, I was gonna say, be a mentor.", "order": 52344, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 5, "scene": 37, "line": "Yes. I am gonna make a donation. And it just so happens that I know someone who works at the Bernard Family Foundation. Her name is mom.", "order": 52345, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 5, "scene": 37, "line": "Oh.", "order": 52346, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 5, "scene": 38, "line": "[whispering] I want some of those pills.", "order": 52347, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 5, "scene": 38, "line": "Oh, well good for you. I mean, you'll need a prescription.", "order": 52348, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 5, "scene": 38, "line": "Oh, no. No, no, no. no. They're not for me. They're for my cousin Mose. He's just having a tough time, being wifeless, and a high pressure job and his crazy cousin Mose. Other cousin Mose.", "order": 52349, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 5, "scene": 38, "line": "Mm. Got it. Well, you tell Mose that he's a good man and that I hope he feels better.", "order": 52350, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 5, "scene": 38, "line": "Which one? Mose or the real Mose?", "order": 52351, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 5, "scene": 38, "line": "The real Mose.", "order": 52352, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 5, "scene": 38, "line": "He says Thank you.", "order": 52353, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 5, "scene": 39, "line": "[on phone] Mom, I had this really charitable idea to set up this scholarship for a capella kids at Cornell and just need to wire some money over there. [pause] What?", "order": 52354, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 5, "scene": 39, "line": "What's wrong?", "order": 52355, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 5, "scene": 39, "line": "My parents are broke.", "order": 52356, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 6, "scene": 1, "line": "[to camera crew] Can you guys come with me for a minute?", "order": 52357, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 6, "scene": 2, "line": "I know you saw me with the Senator. I think I'm in love, possibly for the first time. So yes, Senator Lipton and I are having an affair. I hope that I can count on your sensitivity, maturity and discretion. [turns to see Kevin has overheard him]...Why?", "order": 52358, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 6, "scene": 3, "line": "Alright, good. Yeah. Just sell it and get the best price you can, okay?", "order": 52359, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 6, "scene": 3, "line": "Or we can hope for a holiday rally?", "order": 52360, "speaker": "White"},
  {"season": 9, "episode": 6, "scene": 3, "line": "White, I'm not a day trader, I'm just a first responder in a disaster.", "order": 52361, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 6, "scene": 4, "line": "Andy's family had a total meltdown. His dad blew through all their money and took off to Argentina with a younger woman.", "order": 52362, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 6, "scene": 4, "line": "And his brother, Walt Jr., locked himself in the wine cellar.", "order": 52363, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 6, "scene": 4, "line": "The weirdest part is Andy. The aftermath got dumped on him and the way he's been handling it is just..so..", "order": 52364, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 6, "scene": 4, "line": "Competent.", "order": 52365, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 6, "scene": 4, "line": "Right?", "order": 52366, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 6, "scene": 5, "line": "Are the 'Nard's hurting? Yeah, you bet. Got kicked pretty hard. Family shattered, super sad. But, I'm kinda crushing it in the damage control department. So, that's cool. I wish my dad could see me now. Of course he caused this whole mess, so, [bleep] him.", "order": 52367, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 6, "scene": 6, "line": "Kevin, listen to me. I'm in love with the Senator. And I need time to sort this out in a responsible matter so I need your help in keeping it a secret because this means the world to me. Ok?", "order": 52368, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 6, "scene": 6, "line": "That's beautiful. No, I totally get that.", "order": 52369, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 6, "scene": 6, "line": "Can you do this, Kevin?", "order": 52370, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 6, "scene": 6, "line": "I really want to. Whatever happens, always remember that.", "order": 52371, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 6, "scene": 6, "line": "I don't know what that means. Hey, hey-", "order": 52372, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 6, "scene": 7, "line": "[On phone] Oh, great! Yeah, um, I'll get right back to you. [hangs up] Hey guys! Anyone ever heard of Iris Black on the radio?", "order": 52373, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 6, "scene": 7, "line": "Yeah, she hosts the Dr. Laura Show.", "order": 52374, "speaker": "Creed Bratton"},
  {"season": 9, "episode": 6, "scene": 7, "line": "Nope, that's Dr. Laura. Well, her show Biz Whiz wants someone from Dunder Mifflin to come on the air today. Does anybody feel particularly passionate about local business issues? [Dwight raises hand]", "order": 52375, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 6, "scene": 7, "line": "I do! I do. I do. I do. I do.", "order": 52376, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 6, "scene": 8, "line": "The media can make you famous. And do you have any idea how easy it is to sell something when you're famous? [Pretends to be on phone] 'Uh, yeah wow. 10 reams of 40 pound bond at only $690 after discount? Um, whatever you say, Brad Pitt.' It's that easy.", "order": 52377, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 6, "scene": 9, "line": "Hey, so that good looking single brother of yours? Heard he's on a downward spiral with booze.", "order": 52378, "speaker": "Meredith Palmer"},
  {"season": 9, "episode": 6, "scene": 9, "line": "Yeah. He's in rehab actually.", "order": 52379, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 6, "scene": 9, "line": "Which place? The one right near Philly? I could be there in an hour.", "order": 52380, "speaker": "Meredith Palmer"},
  {"season": 9, "episode": 6, "scene": 9, "line": "No, it's in New Mexico.", "order": 52381, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 6, "scene": 9, "line": "Oh...So..- how are you holding up?", "order": 52382, "speaker": "Meredith Palmer"},
  {"season": 9, "episode": 6, "scene": 9, "line": "We're done Meredith.", "order": 52383, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 6, "scene": 10, "line": "[wagging tongue for voice exercises] blah blah blah, rat tat tat tittle tattled and prattled on about the little metal bottle, she spat a bit of spittle...[Group protests]", "order": 52384, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 6, "scene": 10, "line": "Dwight!", "order": 52385, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 6, "scene": 10, "line": "...In a bitter battle..Hey, hey! Stop questioning my methods, OK? I was chosen for this task for a very good reason.", "order": 52386, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 6, "scene": 10, "line": "No, you chose yourself.", "order": 52387, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 6, "scene": 10, "line": "[walking away] Tricky siskel spat a bit of wicked biscuit....", "order": 52388, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 6, "scene": 11, "line": "Alright, guys. A lot of assets here that my dad couldn't steal. Now, first of all, there is the family boat. 43 foot Tartan Sloop. My lawyer has lined up a buyer in the Bahamas, the sale would cover the cost of a condo and living expenses for my mom.", "order": 52389, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 6, "scene": 11, "line": "Sounds great.", "order": 52390, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 6, "scene": 11, "line": "Perfect.", "order": 52391, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 6, "scene": 11, "line": "Totally, except it is a no go. This boat was the heart and soul of the family. So...what else we got? Non-boat ideas. [Oscar looks outside conference room at Kevin and Angela at the copier]", "order": 52392, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 6, "scene": 11, "line": "What happened?", "order": 52393, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 6, "scene": 11, "line": "Jammed. This day couldn't get worse. [Kevin laughs]", "order": 52394, "speaker": "Angela Martin"},
  {"season": 9, "episode": 6, "scene": 11, "line": "Yeah, I think this day could get worse.", "order": 52395, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 6, "scene": 11, "line": "What does that mean?", "order": 52396, "speaker": "Angela Martin"},
  {"season": 9, "episode": 6, "scene": 11, "line": "Kevin!", "order": 52397, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 6, "scene": 11, "line": "[to Angela] I don't know what you're talking about. [Thumbs up Oscar]", "order": 52398, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 6, "scene": 11, "line": "She could get like sixty packages of Ramen noodles for five bucks. She could eat for a month.", "order": 52399, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 6, "scene": 11, "line": "Alright, what's the grand total? Did we make our nut?", "order": 52400, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 6, "scene": 11, "line": "If we sell everything but the boat, your mom should be set for about six months.", "order": 52401, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 6, "scene": 11, "line": "Guys, you don't understand, this boat's been in our family since before I was born.", "order": 52402, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 6, "scene": 11, "line": "Don't you guys have that place where you used to spend your summers and have all those memories?", "order": 52403, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 6, "scene": 11, "line": "I worked at a Jiffy Lube.", "order": 52404, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 6, "scene": 11, "line": "See? I bet you wouldn't sell that Jiffy Lube for all the money in the world. Would you Darryl?", "order": 52405, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 6, "scene": 11, "line": "I would if I owned it and I went broke.", "order": 52406, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 6, "scene": 11, "line": "Alright, uh...alright. I'm gonna tell the lawyer to pull the trigger on the boat.", "order": 52407, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 6, "scene": 12, "line": "Yes the boat means everything to my family, but we need the money. Got the memories, don't need the boat. Can't cry about it. What are you gonna do? Cry about it? [chokes up] Excuse me.", "order": 52408, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 6, "scene": 13, "line": "[On phone] Oh, OK. No problem. Thanks. Hey guys, WPTU called. The interview's off. They're opening a new cupcake store at the Steamtown Mall and Iris wants to cover that, so...", "order": 52409, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 6, "scene": 13, "line": "Well why don't I go and tell Dwight so he can stop being such a complete nipple.", "order": 52410, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 6, "scene": 13, "line": "He's gonna be disappointed.", "order": 52411, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 6, "scene": 13, "line": "Yes. He is. And you know what? We cannot let that happen.", "order": 52412, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 6, "scene": 14, "line": "Fax?", "order": 52413, "speaker": "Pete Miller"},
  {"season": 9, "episode": 6, "scene": 14, "line": "Oh yeah, just. Watermelon teeth. This isn't how I would cheer up just anyone. But, it's a girlfriend's job to know her man and I know Andy. [laughs] He's seriously juvenile.", "order": 52414, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 6, "scene": 14, "line": "Cool. He's like 40 though, right?", "order": 52415, "speaker": "Pete Miller"},
  {"season": 9, "episode": 6, "scene": 14, "line": "Oh no, he couldn't be more than late thirty's. Tops.", "order": 52416, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 6, "scene": 14, "line": "Awesome. Have fun.", "order": 52417, "speaker": "Pete Miller"},
  {"season": 9, "episode": 6, "scene": 15, "line": "Vroom! Dunder Mifflin. Dunder Mifflin. Good. Sounds- [phone rings in break room, Dwight picks up] This is Dwight Shrute.", "order": 52418, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 6, "scene": 15, "line": "[on other end in conference room] Please hold for Ms. Black.", "order": 52419, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 6, "scene": 15, "line": "[Darryl plays radio show music on keyboard] And welcome back to Biz Whiz. I'm Iris Black. On the line we have Dunder Mifflin's senior sales associate Dwight Shrute.", "order": 52420, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 6, "scene": 15, "line": "Iris, thank you so much for having me.", "order": 52421, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 6, "scene": 16, "line": "[muffled by watermelon teeth] Hey! My teeth are all this stuff in my mouth. [laughs]", "order": 52422, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 6, "scene": 16, "line": "Uh, did you need something?", "order": 52423, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 6, "scene": 16, "line": "Oh, I just need your signature on this.", "order": 52424, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 6, "scene": 16, "line": "Ok. [signs paper] thanks.", "order": 52425, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 6, "scene": 17, "line": "Kevin, we're out of 11-38 forms. Did you order more?", "order": 52426, "speaker": "Angela Martin"},
  {"season": 9, "episode": 6, "scene": 17, "line": "I... did not.", "order": 52427, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 6, "scene": 17, "line": "I don't know why I'm surprised. Literally nothing you do could surprise me anymore.", "order": 52428, "speaker": "Angela Martin"},
  {"season": 9, "episode": 6, "scene": 17, "line": "[laughing in Oscar's direction] Oh really, Angela? That's interesting. Cause I do think that I could surprise you. I think that I could surprise you..oh! [Oscar makes sound trying to get him to shut up] I have to go to the bathroom!", "order": 52429, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 6, "scene": 17, "line": "That doesn't surprise me.", "order": 52430, "speaker": "Angela Martin"},
  {"season": 9, "episode": 6, "scene": 18, "line": "That actually wasn't the worst cover. I'd say at least once a week, Kevin runs out of the room shouting that he has to go to the bathroom.", "order": 52431, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 6, "scene": 19, "line": "[In staff meeting] See these forms...", "order": 52432, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 6, "scene": 19, "line": "I have to go to the bathroom! [runs out]", "order": 52433, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 6, "scene": 20, "line": "[running from elevator] I have to go to the bathroom!", "order": 52434, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 6, "scene": 21, "line": "[Drives away waving, then screeches to a stop and runs from car]", "order": 52435, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 6, "scene": 22, "line": "Ok, who wants to go for a super fun lunch with a super fun girlfriend?", "order": 52436, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 6, "scene": 22, "line": "Look, it's not that I don't want-", "order": 52437, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 6, "scene": 22, "line": "Ok, come on. I got someone to cover the phones, I heard on the radio about a new cupcake place we could go for dessert...perfect.", "order": 52438, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 6, "scene": 22, "line": "All I ever wanted to do was sail the damn thing. But dad wouldn't let me. Said 'You can't be a skipper until you're a man.' You know, I'd reach for the wheel and he'd smack my hand away. Well guess what? Now I'm the man of the family and... we're selling the damn thing. So I'm never gonna have the chance.", "order": 52439, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 6, "scene": 22, "line": "Well, when does the boat leave, exactly?", "order": 52440, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 6, "scene": 22, "line": "Tonight.", "order": 52441, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 6, "scene": 22, "line": "Then screw lunch. Let's go for a sunset sail.", "order": 52442, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 6, "scene": 22, "line": "Yeah right. It's in Stamford, Connecticut. We have to leave like right now.", "order": 52443, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 6, "scene": 22, "line": "Ok, well then let's leave like right now.", "order": 52444, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 6, "scene": 22, "line": "Yeah, ok.", "order": 52445, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 6, "scene": 22, "line": "Yeah! OK! Let's go.", "order": 52446, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 6, "scene": 22, "line": "Seriously?", "order": 52447, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 6, "scene": 22, "line": "Of course, seriously. Get your coat on.", "order": 52448, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 6, "scene": 22, "line": "Alright.", "order": 52449, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 6, "scene": 22, "line": "Alright.", "order": 52450, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 6, "scene": 22, "line": "Let's do it.", "order": 52451, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 6, "scene": 22, "line": "Let's do it!", "order": 52452, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 6, "scene": 23, "line": "[On speakerphone] Iris, let me tell you. David Wallace is the CEO, but he's not hands on.", "order": 52453, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 6, "scene": 23, "line": "[As Iris] So the day-to-day operations are entirely under your command?", "order": 52454, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 6, "scene": 23, "line": "Entirely is the perfect way to describe it, Iris.", "order": 52455, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 6, "scene": 23, "line": "Uh, excuse me. [reading card held up by Jim] I'm being told by my sound engineer, Steve that uh there is a clinking sound coming from your end. Does your shirt have buttons?", "order": 52456, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 6, "scene": 23, "line": "Yes..?", "order": 52457, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 6, "scene": 23, "line": "[Jim mimes taking shirt off] I'm so sorry we're going to have to ask you to remove the shirt all together.", "order": 52458, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 6, "scene": 23, "line": "[Takes shirt off] Now then, we were saying. When my workers-gather-", "order": 52459, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 6, "scene": 23, "line": "Oh, I'm so sorry. I am told we are still having problems Mr. Shrute. [Jim holds up card that says 'Now Pants'] Your voice, it's sounding a little feminine.", "order": 52460, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 6, "scene": 23, "line": "That's impossible.", "order": 52461, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 6, "scene": 23, "line": "Are you by any chance wearing pants with a metallic zipper?", "order": 52462, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 6, "scene": 24, "line": "The Senator is exhausted. This campaign is wearing him out.", "order": 52463, "speaker": "Angela Martin"},
  {"season": 9, "episode": 6, "scene": 24, "line": "That's a tough one.", "order": 52464, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 6, "scene": 24, "line": "That man he's up against is so dirty. And the senator's just pushing back as hard as he can.", "order": 52465, "speaker": "Angela Martin"},
  {"season": 9, "episode": 6, "scene": 24, "line": "Please, stop.", "order": 52466, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 6, "scene": 24, "line": "What?", "order": 52467, "speaker": "Angela Martin"},
  {"season": 9, "episode": 6, "scene": 24, "line": "Please, stop.", "order": 52468, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 6, "scene": 24, "line": "Anyways, last night he was tired and just wanted a little Mexican brought in. [Kevin laughs]", "order": 52469, "speaker": "Angela Martin"},
  {"season": 9, "episode": 6, "scene": 24, "line": "[Getting up to leave] I can't, it's too much! [laughing]", "order": 52470, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 6, "scene": 25, "line": "I'm in big trouble.", "order": 52471, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 6, "scene": 26, "line": "Yeah, Oscar's in big trouble.", "order": 52472, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 6, "scene": 27, "line": "[pantless] OK, how is my voice now?", "order": 52473, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 6, "scene": 27, "line": "I'm getting the all clear from Steve, so Mr. Shrute, what is your response to the consumer product safety commission that says Dunder Mifflin paper is toxic?", "order": 52474, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 6, "scene": 27, "line": "[holding hand over phone] This is gotcha journalism. You know what? They're not gonna gotch me.", "order": 52475, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 6, "scene": 28, "line": "[looking at folder he brought Toby]It's clearly not an accounting mistake.", "order": 52476, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 6, "scene": 28, "line": "Yeah.", "order": 52477, "speaker": "Toby Flenderson"},
  {"season": 9, "episode": 6, "scene": 28, "line": "So...", "order": 52478, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 6, "scene": 28, "line": "Kevin. His gambling problem must've resurfaced. I'm gonna have to send him home until I can do an investigation.", "order": 52479, "speaker": "Toby Flenderson"},
  {"season": 9, "episode": 6, "scene": 28, "line": "Well, you've gotta do what you've gotta do.", "order": 52480, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 6, "scene": 29, "line": "This is slander, Ms. Black. Slander I say! [Pam tells Jim to leave the conference room] I dare you to produce one credible source about this.", "order": 52481, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 6, "scene": 29, "line": "Well, as it happens we have with us the foreman of your upstate New York paper mill, Sandra Mc...Sandra Mick [points to Pam]", "order": 52482, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 6, "scene": 29, "line": "[changing voice] Good afternoon Iris, it's a pleasure.", "order": 52483, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 6, "scene": 29, "line": "Let's get straight to the point. Is your paper toxic?", "order": 52484, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 6, "scene": 29, "line": "No the paper's not toxic.", "order": 52485, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 6, "scene": 29, "line": "Thank you Sandra!", "order": 52486, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 6, "scene": 29, "line": "Unless it's exposed to oxygen. Then it becomes extremely toxic!", "order": 52487, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 6, "scene": 29, "line": "Do not listen to her! This employee is obviously disgruntled! [Jim runs panicked into break room]", "order": 52488, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 6, "scene": 29, "line": "What the heck is going on?! The stock prices are plummeting! Are you gonna take control of the message or do I have to send in someone who understands the media?!", "order": 52489, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 6, "scene": 29, "line": "Get out of here moron! [Jim leaves]", "order": 52490, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 6, "scene": 29, "line": "Excuse me, Mr. Dwight, who are you talking to?", "order": 52491, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 6, "scene": 29, "line": "uhhhh....no one.", "order": 52492, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 6, "scene": 29, "line": "Did you just call Ms. Mick a moron?", "order": 52493, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 6, "scene": 29, "line": "No, everything's fine.", "order": 52494, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 6, "scene": 29, "line": "Are you insulting my guests?", "order": 52495, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 6, "scene": 29, "line": "[chokes up]", "order": 52496, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 6, "scene": 30, "line": "[on phone] Yeah, I will be right there. Hey Oscar, what if I'm getting a promotion?", "order": 52497, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 6, "scene": 30, "line": "I hope that's it, Kevin.", "order": 52498, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 6, "scene": 30, "line": "Me too. Cause then, I would get my own office. And I wouldn't screw up your secret with Angela. I've been really worried about that.", "order": 52499, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 6, "scene": 31, "line": "Hi, Kevin. Look, I need to talk to you about-", "order": 52500, "speaker": "Toby Flenderson"},
  {"season": 9, "episode": 6, "scene": 31, "line": "Hi.", "order": 52501, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 6, "scene": 31, "line": "Guys? Excuse me. Um, a quick word, please just..", "order": 52502, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 6, "scene": 31, "line": "Oscar, we're in the middle of talking.", "order": 52503, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 6, "scene": 31, "line": "Oh, you're right I'm so sorry. Snack machine on me. [gives Kevin money]", "order": 52504, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 6, "scene": 31, "line": "Oh that is nice. Classy move. [leaves]", "order": 52505, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 6, "scene": 31, "line": "[yelling in break room] That's what I'm saying! No of course not!", "order": 52506, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 6, "scene": 31, "line": "What are you doing?", "order": 52507, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 6, "scene": 32, "line": "Those figures I gave you? They're false.", "order": 52508, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 6, "scene": 32, "line": "How?", "order": 52509, "speaker": "Toby Flenderson"},
  {"season": 9, "episode": 6, "scene": 32, "line": "I was mad at Kevin, we had a fight and I acted vindictively.", "order": 52510, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 6, "scene": 32, "line": "So you set him up.", "order": 52511, "speaker": "Toby Flenderson"},
  {"season": 9, "episode": 6, "scene": 32, "line": "Yes, he's innocent.", "order": 52512, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 6, "scene": 32, "line": "I knew it. I knew it from the beginning it was possible.", "order": 52513, "speaker": "Toby Flenderson"},
  {"season": 9, "episode": 6, "scene": 32, "line": "What are you talking about? I just did this now.", "order": 52514, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 6, "scene": 32, "line": "A few years ago, when I was on the jury of the Scranton Strangler..", "order": 52515, "speaker": "Toby Flenderson"},
  {"season": 9, "episode": 6, "scene": 32, "line": "Sure.", "order": 52516, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 6, "scene": 32, "line": "I always thought he might have been set up but I felt pressured to convict.", "order": 52517, "speaker": "Toby Flenderson"},
  {"season": 9, "episode": 6, "scene": 32, "line": "That's gotta be tough.", "order": 52518, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 6, "scene": 32, "line": "Tough? I put an innocent man on death row.", "order": 52519, "speaker": "Toby Flenderson"},
  {"season": 9, "episode": 6, "scene": 33, "line": "The fallout from this morning's revelation continues to mount, since Mr. Dwight Shrute began speaking, Dunder Mifflin share prices fell 73%. Mr. Shrute, shareholders demand accountability from corporate leadership. Can we announce your resignation at this time?", "order": 52520, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 6, "scene": 33, "line": "My resignation? What are you talking about? No! I was just following orders! Listen, the person responsible for this catastrophe is the CEO and chairman, David Wallace!", "order": 52521, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 6, "scene": 34, "line": "That's yours?", "order": 52522, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 6, "scene": 34, "line": "That's the family boat!", "order": 52523, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 6, "scene": 34, "line": "Oh my gosh, Andy! This is enormous!", "order": 52524, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 6, "scene": 34, "line": "Right?", "order": 52525, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 6, "scene": 34, "line": "I thought it was gonna be tiny. Oh my gosh it's beautiful. So this is how your family came to America.", "order": 52526, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 6, "scene": 35, "line": "Move.", "order": 52527, "speaker": "Boat Guy"},
  {"season": 9, "episode": 6, "scene": 35, "line": "Sorry?", "order": 52528, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 6, "scene": 35, "line": "Trying to rig a boat here. I don't know how to do that when you're standing in the way.", "order": 52529, "speaker": "Boat Guy"},
  {"season": 9, "episode": 6, "scene": 35, "line": "Sure, I um..I didn't know.", "order": 52530, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 6, "scene": 35, "line": "I'm not a ghost, so I can't walk through people.", "order": 52531, "speaker": "Boat Guy"},
  {"season": 9, "episode": 6, "scene": 35, "line": "Ok.", "order": 52532, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 6, "scene": 35, "line": "Gee, he was salty.", "order": 52533, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 6, "scene": 35, "line": "Geez, a little bit. [to boat] How you doing old buddy? Missed you.", "order": 52534, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 6, "scene": 35, "line": "What does that one do?", "order": 52535, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 6, "scene": 35, "line": "It raises the main sail. That was my job when we went sailing.", "order": 52536, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 6, "scene": 35, "line": "I wish I had seen you do it.", "order": 52537, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 6, "scene": 35, "line": "Really?", "order": 52538, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 6, "scene": 35, "line": "Yeah.", "order": 52539, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 6, "scene": 35, "line": "[Begins raising sail] Up she goes!", "order": 52540, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 6, "scene": 35, "line": "Don't do that. Don't do that.", "order": 52541, "speaker": "Boat Guy"},
  {"season": 9, "episode": 6, "scene": 35, "line": "Oh, it's OK. My girlfriend and I were actually gonna take her for a little spin and a picnic before you guys head out tonight. You know what? Get some dinner on me.", "order": 52542, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 6, "scene": 35, "line": "Nope. Can't do that.", "order": 52543, "speaker": "Boat Guy"},
  {"season": 9, "episode": 6, "scene": 35, "line": "You got it. You know what then, we'll just take it for a quick little spin around the harbor. [begins raising sail, boat guy slaps his hand]", "order": 52544, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 6, "scene": 35, "line": "We've already started boarding. And no one is insured to rig her up right now except us.", "order": 52545, "speaker": "Boat Guy"},
  {"season": 9, "episode": 6, "scene": 35, "line": "You know what? Chill, ok? I own the boat. Not gonna sue myself. Alright, so just- [Raises sail, guys slaps hand again] wow.", "order": 52546, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 6, "scene": 35, "line": "I can smack you all day if you keep touching what you're not supposed to touch.", "order": 52547, "speaker": "Boat Guy"},
  {"season": 9, "episode": 6, "scene": 35, "line": "Ok, fine.", "order": 52548, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 6, "scene": 35, "line": "OK.", "order": 52549, "speaker": "Boat Guy"},
  {"season": 9, "episode": 6, "scene": 35, "line": "Yeah, fine.", "order": 52550, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 6, "scene": 35, "line": "Good stuff.", "order": 52551, "speaker": "Boat Guy"},
  {"season": 9, "episode": 6, "scene": 35, "line": "Good stuff. Nice stuff. [begins raising sail again when guy leaves, but he comes back and smacks Andy's hand again] [bleep] Damn it! Screw you dad! ..ah.", "order": 52552, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 6, "scene": 36, "line": "Well, we're doing it. We're finally having a picnic on the boat.", "order": 52553, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 6, "scene": 36, "line": "I've had a thousand picnics on this boat. The point was for me to sail it.", "order": 52554, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 6, "scene": 36, "line": "Andy, you never had to sail the boat to be a man.", "order": 52555, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 6, "scene": 36, "line": "Fine. But I could have.", "order": 52556, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 6, "scene": 36, "line": "As long as we're on this boat, as far as I'm concerned, you're the captain.", "order": 52557, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 6, "scene": 36, "line": "I am the captain.", "order": 52558, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 6, "scene": 36, "line": "Yeah.", "order": 52559, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 6, "scene": 36, "line": "Right?", "order": 52560, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 6, "scene": 36, "line": "Yeah.", "order": 52561, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 6, "scene": 36, "line": "I'm the captain. [to boat guy] Hey, charm school.", "order": 52562, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 6, "scene": 36, "line": "What?", "order": 52563, "speaker": "Boat Guy"},
  {"season": 9, "episode": 6, "scene": 36, "line": "I'm taking it over from here.", "order": 52564, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 6, "scene": 36, "line": "Nice!", "order": 52565, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 6, "scene": 36, "line": "I hate to uh, ruin this moment...or breakdown, but you already signed the papers. So, if you want your boat back, you can pick it up there in the Bahamas in 10 days.", "order": 52566, "speaker": "Boat Guy"},
  {"season": 9, "episode": 6, "scene": 36, "line": "Yeah, I know cause I'm sailing it there.", "order": 52567, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 6, "scene": 36, "line": "OK, no. I already said, you're not getting back your deposit.", "order": 52568, "speaker": "Boat Guy"},
  {"season": 9, "episode": 6, "scene": 36, "line": "Fine. Good. Keep it. Just leave the supplies, I paid for those. But how much for that cool fisherman sweater?", "order": 52569, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 6, "scene": 37, "line": "For those just joining us, terror in Greenwich. Where police have surrounded the house of Dunder Mifflin CEO David Wallace. Wallace is said to be despondent over the company's stock plummet and is taking a mailman hostage. On the line, we have chief of Greenwich Police, Mr. Bill Jackson [points to Jim].", "order": 52570, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 6, "scene": 37, "line": "[using black voice] Good afternoon! [Darryl smacks his arm]", "order": 52571, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 6, "scene": 37, "line": "Please sir! Spare him. Please.", "order": 52572, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 6, "scene": 37, "line": "Uh, this Wallace guy is lookin' at hard time. And we only know this because of what Dwight Snoot said on record!", "order": 52573, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 6, "scene": 37, "line": "Ok, everyone. Everyone, hold on! I've got a solution. I know Wallace's phone number, everyone hold, I'll conference him in.", "order": 52574, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 6, "scene": 37, "line": "Oh, Mr. Shrute, there's really no need to, um involve Mr... Wallace.", "order": 52575, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 6, "scene": 38, "line": "Do you even know what you're doing?", "order": 52576, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 6, "scene": 38, "line": "Yeah. Yeah I know how to hoist the mail sail, I know to...I, I, these buttons control boat pumps and stuff. I also know where the booze stash is. So, hello. [Opens door, Andy's brother is passed out inside] Walt?", "order": 52577, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 6, "scene": 38, "line": "Oh god, thank goodness you're here. I was having a little trouble with this door. Yesterday. I um, I think it was yesterday.", "order": 52578, "speaker": "Walt Jr."},
  {"season": 9, "episode": 6, "scene": 38, "line": "I thought you were in rehab.", "order": 52579, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 6, "scene": 38, "line": "Yeah, uh, I just, I figured I'd get that first relapse out of the way. God, what's happened to our family? Everything is so messed up! How did you even know I was in here?", "order": 52580, "speaker": "Walt Jr."},
  {"season": 9, "episode": 6, "scene": 38, "line": "I didn't. I, I just came to say goodbye to the boat. But I've decided to sail it to Bermuda.", "order": 52581, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 6, "scene": 38, "line": "Bahamas, Andy.", "order": 52582, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 6, "scene": 38, "line": "Same thing. [To Walt] Come on. I need a crew. You should be my crew. Three weeks, open ocean, no booze. You need this. I need it, we need this. Serious bro time, come on.", "order": 52583, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 6, "scene": 39, "line": "[On phone] Hello?", "order": 52584, "speaker": "David Wallace"},
  {"season": 9, "episode": 6, "scene": 39, "line": "David, is that you?", "order": 52585, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 6, "scene": 39, "line": "Dwight?", "order": 52586, "speaker": "David Wallace"},
  {"season": 9, "episode": 6, "scene": 39, "line": "Oh, thank god. Oh, thank god. Are you ok? Is everyone ok?", "order": 52587, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 6, "scene": 39, "line": "Yeah? Are you ok?", "order": 52588, "speaker": "David Wallace"},
  {"season": 9, "episode": 6, "scene": 39, "line": "Oh, I'm OK. I just want you to know that I believe in you. I really do. And I believe in your ability to make the right choices. I always have, David.", "order": 52589, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 6, "scene": 39, "line": "Well, thanks Dwight.", "order": 52590, "speaker": "David Wallace"},
  {"season": 9, "episode": 6, "scene": 39, "line": "You're welcome, sir. But David, listen to me carefully. I'm gonna need you to let the mailman go. Ok?", "order": 52591, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 6, "scene": 39, "line": "Dwight?", "order": 52592, "speaker": "David Wallace"},
  {"season": 9, "episode": 6, "scene": 39, "line": "Walk out of the house...", "order": 52593, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 6, "scene": 39, "line": "What?", "order": 52594, "speaker": "David Wallace"},
  {"season": 9, "episode": 6, "scene": 39, "line": "...with your hands on top of your head, everything is going to be fine. Dunder Mifflin will be in good hands while you're away in prison.", "order": 52595, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 6, "scene": 39, "line": "Ok, Dwight, gonna ask you to not call my cell anymore. Gotta go.", "order": 52596, "speaker": "David Wallace"},
  {"season": 9, "episode": 6, "scene": 40, "line": "[enters main office, group claps] Wait a minute, you guys? You heard?", "order": 52597, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 6, "scene": 40, "line": "Shrute! Shrute! Shrute!Shrute! Shrute! Shrute! Shrute! Shrute!", "order": 52598, "speaker": "All"},
  {"season": 9, "episode": 6, "scene": 40, "line": "[joins in] Shrute! Shrute!", "order": 52599, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 6, "scene": 41, "line": "Overall, I'd say my first radio interview went pretty much the way I expected.", "order": 52600, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 6, "scene": 42, "line": "Well Oscar, I did not get the promotion. He just wanted to update my personal information.", "order": 52601, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 6, "scene": 42, "line": "Well, I am sorry Kevin.", "order": 52602, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 6, "scene": 42, "line": "Why on earth would you think you were getting a promotion?", "order": 52603, "speaker": "Angela Martin"},
  {"season": 9, "episode": 6, "scene": 42, "line": "You know what Angela? I- Oh my god. [Senator enters]", "order": 52604, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 6, "scene": 42, "line": "Honey! What are you doing here?", "order": 52605, "speaker": "Angela Martin"},
  {"season": 9, "episode": 6, "scene": 42, "line": "I just had a little intuition that someone I loved needed a little bit of attention today. Oscar, you're looking very healthy. Getting lots of vigorous exercise? [touches Oscar's shoulder. Oscar jumps away quickly]", "order": 52606, "speaker": "Senator Liptop"},
  {"season": 9, "episode": 6, "scene": 42, "line": "No.", "order": 52607, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 6, "scene": 42, "line": "Oscar? What is going on? What was that?", "order": 52608, "speaker": "Angela Martin"},
  {"season": 9, "episode": 6, "scene": 42, "line": "What was that? I'm sorry, I didn't mean any offense. I was just trying to be friendly.", "order": 52609, "speaker": "Senator Liptop"},
  {"season": 9, "episode": 6, "scene": 42, "line": "You know what? I'm sorry. I overreacted. Because I'm stressed out. Why am I stressed out? Who's not stressed out? Who's not stressed out? Who-", "order": 52610, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 6, "scene": 42, "line": "Come on Oscar, we're not just gonna sit here and ignore the obvious. Senator Lipton has a big election next week. We all need to give him our support. [claps, group joins]", "order": 52611, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 6, "scene": 42, "line": "Well, thanks everybody.", "order": 52612, "speaker": "Senator Liptop"},
  {"season": 9, "episode": 6, "scene": 42, "line": "It is really cool! U-S-A! U-S-A!", "order": 52613, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 6, "scene": 42, "line": "U..s..a. U-", "order": 52614, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 6, "scene": 43, "line": "I have to say I'm impressed with Kevin. Uh, he showed a lot of self-control.", "order": 52615, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 6, "scene": 44, "line": "I totally forgot about the affair for a minute. [laughs] Oscar is having sex with the senator and Angela doesn't even know. [laughing still] Her life is a complete sham!", "order": 52616, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 6, "scene": 45, "line": "Alright guys, cast us off. Walt, all aboard! Erin, this is because of you. Do you realize that? You're the best ever!", "order": 52617, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 6, "scene": 45, "line": "[Standing on dock] Oh, you know. Just being a good girlfriend.", "order": 52618, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 6, "scene": 45, "line": "[pulling away from dock] Good? Come on. Above and beyond. World's Greatest. You did this! Dammit I'm happy!", "order": 52619, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 6, "scene": 46, "line": "Yes, I am very pleased I did help Andy. Would I have gone with him if he'd asked me? On his sailboat cruise to the Caribbean? Yes. I think it would have been really fun and romantic.", "order": 52620, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 6, "scene": 47, "line": "[waving from boat] I'll see you in three weeks!", "order": 52621, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 6, "scene": 47, "line": "OK!", "order": 52622, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 6, "scene": 47, "line": "Hey bro! It was here all along. [holding guitar]", "order": 52623, "speaker": "Walt Jr."},
  {"season": 9, "episode": 6, "scene": 47, "line": "No way!", "order": 52624, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 6, "scene": 47, "line": "Way!", "order": 52625, "speaker": "Walt Jr."},
  {"season": 9, "episode": 6, "scene": 47, "line": "Erin I gotta go![Walt begins playing] Them's my chords!", "order": 52626, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 6, "scene": 48, "line": "Hey. She's back.", "order": 52627, "speaker": "Pete Miller"},
  {"season": 9, "episode": 6, "scene": 48, "line": "Thanks for covering the phones.", "order": 52628, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 6, "scene": 48, "line": "Yeah, no problem. How was it?", "order": 52629, "speaker": "Pete Miller"},
  {"season": 9, "episode": 6, "scene": 48, "line": "Fine.", "order": 52630, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 6, "scene": 48, "line": "Hey, some buddies of mine are going to Poor Richard's for beers and pool, you wanna come? I can't promise you too much, but uh, you might get to meet my friend flipper.", "order": 52631, "speaker": "Pete Miller"},
  {"season": 9, "episode": 6, "scene": 48, "line": "Does he have a flipper?", "order": 52632, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 6, "scene": 48, "line": "Oh. Nope. It's not that, he uh, uh he flipped a table one time when he was drunk.", "order": 52633, "speaker": "Pete Miller"},
  {"season": 9, "episode": 6, "scene": 48, "line": "He sounds like an idiot.", "order": 52634, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 6, "scene": 48, "line": "Yeah, he is.", "order": 52635, "speaker": "Pete Miller"},
  {"season": 9, "episode": 6, "scene": 48, "line": "Ok.", "order": 52636, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 6, "scene": 48, "line": "Ok.", "order": 52637, "speaker": "Pete Miller"},
  {"season": 9, "episode": 6, "scene": 48, "line": "Thanks.", "order": 52638, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 6, "scene": 48, "line": "Yeah.", "order": 52639, "speaker": "Pete Miller"},
  {"season": 9, "episode": 7, "scene": 1, "line": "[on computer screen] Ah, what else? I've seen Dirty Dancing like, ten times. Breaks my heart every time, you know?", "order": 52640, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 7, "scene": 1, "line": "Whoa!", "order": 52641, "speaker": "Everyone"},
  {"season": 9, "episode": 7, "scene": 1, "line": "Andy.", "order": 52642, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 7, "scene": 1, "line": "That Swayze sure can dance dirty.", "order": 52643, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 7, "scene": 1, "line": "Don't you have any sunblock?", "order": 52644, "speaker": "Phyllis Vance"},
  {"season": 9, "episode": 7, "scene": 1, "line": "No, Walter J has been hoarding it. You want to worry about a part of my body, worry about my eyes. They're like two flaming meatballs in my skull.", "order": 52645, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 7, "scene": 2, "line": "Last week Andy set sail for the Bahamas to sell his family's boat, and he took his brother, but not me. I was kind of sad at first, but then I remembered that Bob Marley song -- No, woman. No cry.", "order": 52646, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 7, "scene": 3, "line": "[on computer] Check this out. Keeps my hair out of my stare, also helps me combat the glare bear. That's what I call the sun now.", "order": 52647, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 7, "scene": 3, "line": "Andy, it's Darryl. Take your drawers off your head.", "order": 52648, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 7, "scene": 3, "line": "What else can I show you? Oh, damn it!", "order": 52649, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 7, "scene": 3, "line": "Oh, Andy, was.. was that your drinking water?", "order": 52650, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 7, "scene": 3, "line": "Yeah, it was. That's okay though. I got this cool desalinator device. It sucks up sea water through this hose and pumps out fresh water. See, check it out. Ow!", "order": 52651, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 7, "scene": 3, "line": "Oh!", "order": 52652, "speaker": "Everyone"},
  {"season": 9, "episode": 7, "scene": 3, "line": "Ah! That's not good. I better sign off. I hate to get going. I mean these skype sessions are, like, the only thing that keep me sane out here, you know? [laughs crazily]", "order": 52653, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 7, "scene": 4, "line": "He's been sailing for two days.", "order": 52654, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 7, "scene": 5, "line": "[on computer] I will leave you with this. The image of a man and his boat. Burn this into your brains.", "order": 52655, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 7, "scene": 5, "line": "[laughing] Yeah.", "order": 52656, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 7, "scene": 5, "line": "No. No! Nooo! [computer falls into ocean]", "order": 52657, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 7, "scene": 5, "line": "Andy? Andy! Andy! Andy. Oh.", "order": 52658, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 7, "scene": 6, "line": "[answering phone] Dwight Schrute. [turns on speakerphone] Well, hi there David Wallace. Why would you ever call me when the manager is out of town?", "order": 52659, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 7, "scene": 6, "line": "[on speakerphone] Well, I have some very exciting news.", "order": 52660, "speaker": "David Wallace"},
  {"season": 9, "episode": 7, "scene": 6, "line": "And you didn't call Jim - that seems significant.", "order": 52661, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 7, "scene": 6, "line": "Hi, David.", "order": 52662, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 7, "scene": 6, "line": "Jim, good! You should hear this too.", "order": 52663, "speaker": "David Wallace"},
  {"season": 9, "episode": 7, "scene": 6, "line": "No, he shouldn't.", "order": 52664, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 7, "scene": 6, "line": "[taking away Dwight's handset] Go ahead David, I'm listening.", "order": 52665, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 7, "scene": 6, "line": "Okay, David, I want to take you off speaker but... I don't know where I'd put you.", "order": 52666, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 7, "scene": 6, "line": "Guys, listen, this is big news. The Scranton White Pages just got in contact with my office the day before yesterday. They've apparently just dropped the supplier they've been with for the last ten years. .", "order": 52667, "speaker": "David Wallace"},
  {"season": 9, "episode": 7, "scene": 6, "line": "The White Pages.", "order": 52668, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 7, "scene": 7, "line": "The White Pages: Do you want it? No. Do you use it? No. Does it inexplicably show up on your doorstep three times a year? Yes, yes, and yes. There's a reason that we in the paper industry call this thing 'the White Whale'. Look at all that sweet blubber.", "order": 52669, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 7, "scene": 8, "line": "Look, we need our top salesman running point on this and Dwight, that is you.", "order": 52670, "speaker": "David Wallace"},
  {"season": 9, "episode": 7, "scene": 8, "line": "I'm gonna need to put you on hold for a second. [presses hold button] Hah! Yah! Woooo! Eat it Jim! Eat it Phyllis! Eat... where's Stanley?", "order": 52671, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 7, "scene": 8, "line": "He's in the bathroom.", "order": 52672, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 7, "scene": 8, "line": "Will you run into the bathroom and tell him to eat it?", "order": 52673, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 7, "scene": 8, "line": "Of course.", "order": 52674, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 7, "scene": 8, "line": "Yeah! Okay. [presses button again] Hey David, I'm back.", "order": 52675, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 7, "scene": 8, "line": "[from the kitchen] Eat it Stanley!", "order": 52676, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 7, "scene": 8, "line": "So uh, last I remember Tom Peterman was in charge of that account?", "order": 52677, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 7, "scene": 8, "line": "No, I spoke with the receptionist over there. It's someone new but she didn't catch her name.", "order": 52678, "speaker": "David Wallace"},
  {"season": 9, "episode": 7, "scene": 8, "line": "Her name?", "order": 52679, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 7, "scene": 8, "line": "No, hey, Dwight shouldn't...", "order": 52680, "speaker": "Phyllis Vance"},
  {"season": 9, "episode": 7, "scene": 8, "line": "Shhh! [clears throat] Thanks David! Thank you so much for calling me!", "order": 52681, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 7, "scene": 8, "line": "Good luck!", "order": 52682, "speaker": "David Wallace"},
  {"season": 9, "episode": 7, "scene": 8, "line": "Good luck to you. [disconnects call]", "order": 52683, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 7, "scene": 8, "line": "Dwight, you can't go. You have a problem with women. You can't sell to them.", "order": 52684, "speaker": "Phyllis Vance"},
  {"season": 9, "episode": 7, "scene": 8, "line": "That is a damnable lie. I love women.", "order": 52685, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 7, "scene": 8, "line": "Gina Rogers at Apex Technology said you called her 'gy-na' for your entire meeting.", "order": 52686, "speaker": "Phyllis Vance"},
  {"season": 9, "episode": 7, "scene": 8, "line": "Ew. That's not good.", "order": 52687, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 7, "scene": 8, "line": "Yeah, she said she corrected him five times.", "order": 52688, "speaker": "Phyllis Vance"},
  {"season": 9, "episode": 7, "scene": 8, "line": "Gy-na' said that?", "order": 52689, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 7, "scene": 8, "line": "Guys, we can't let Dwight blow this. An account this size could double our growth. That means raises, bonuses... Pizza Friday could come back.", "order": 52690, "speaker": "Phyllis Vance"},
  {"season": 9, "episode": 7, "scene": 8, "line": "Hey remember that week in the 90's when we got bagels?", "order": 52691, "speaker": "Meredith Palmer"},
  {"season": 9, "episode": 7, "scene": 8, "line": "I miss Clinton.", "order": 52692, "speaker": "Creed Bratton"},
  {"season": 9, "episode": 7, "scene": 8, "line": "Can you go instead?", "order": 52693, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 7, "scene": 8, "line": "I can't. I have the thing.", "order": 52694, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 7, "scene": 9, "line": "I have this conference call today with this company in Philly that I'm helping start. Ah, first board meeting. Also, the first time I've ever been excited about work. So, that feels... wrong.", "order": 52695, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 7, "scene": 10, "line": "Okay, call down. It's just me, not Tom Selleck.", "order": 52696, "speaker": "Pete Miller"},
  {"season": 9, "episode": 7, "scene": 10, "line": "[laughter]", "order": 52697, "speaker": "Guys in breakroom"},
  {"season": 9, "episode": 7, "scene": 10, "line": "Nice!", "order": 52698, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 7, "scene": 11, "line": "Toby got us all to participate in Movember. It's a charity for prostate cancer. You pledge money and then you grow a mustache for the month of November.", "order": 52699, "speaker": "Pete Miller"},
  {"season": 9, "episode": 7, "scene": 11, "line": "So, this is how we look now. I hope you like being turned on all the time.", "order": 52700, "speaker": "Clark Green"},
  {"season": 9, "episode": 7, "scene": 12, "line": "Daaaaamn! It just keeps on coming, huh?", "order": 52701, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 7, "scene": 12, "line": "I have very fertile hair glands.", "order": 52702, "speaker": "Toby Flenderson"},
  {"season": 9, "episode": 7, "scene": 13, "line": "I am so glad I got all of the dudes to do Movember. We have the dopest time back in the annex.", "order": 52703, "speaker": "Toby Flenderson"},
  {"season": 9, "episode": 7, "scene": 14, "line": "God!", "order": 52704, "speaker": "Clark Green"},
  {"season": 9, "episode": 7, "scene": 15, "line": "We even go to lunch, pick up babes.", "order": 52705, "speaker": "Toby Flenderson"},
  {"season": 9, "episode": 7, "scene": 16, "line": "[to passing female pedestrian] Smile if you love men's prostates.", "order": 52706, "speaker": "Toby Flenderson"},
  {"season": 9, "episode": 7, "scene": 17, "line": "Hi.", "order": 52707, "speaker": "Angela Martin"},
  {"season": 9, "episode": 7, "scene": 17, "line": "Hi.", "order": 52708, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 7, "scene": 17, "line": "Spring cleaning?", "order": 52709, "speaker": "Angela Martin"},
  {"season": 9, "episode": 7, "scene": 17, "line": "More like fall cleaning. [chuckles]", "order": 52710, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 7, "scene": 17, "line": "[whispering] I think the senator is having an affair.", "order": 52711, "speaker": "Angela Martin"},
  {"season": 9, "episode": 7, "scene": 17, "line": "[dropping desk drawer] This doesn't... I'm sorry. Wha... what?", "order": 52712, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 7, "scene": 17, "line": "I think the senator is having an affair.", "order": 52713, "speaker": "Angela Martin"},
  {"season": 9, "episode": 7, "scene": 18, "line": "I literally have nightmares in which what just happened happens. I wake up in a sweat. And then I make Angela's husband spoon me back to bed.", "order": 52714, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 7, "scene": 19, "line": "When he comes home in the morning, he has this secret little smile.", "order": 52715, "speaker": "Angela Martin"},
  {"season": 9, "episode": 7, "scene": 19, "line": "Oh, I'm sure ...that's nothing.", "order": 52716, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 7, "scene": 19, "line": "And he's always at the yoga studio. He never misses the noon class - it's Hot Yoga with Blake.", "order": 52717, "speaker": "Angela Martin"},
  {"season": 9, "episode": 7, "scene": 19, "line": "Angela, Blake's also a guy's name so he... may be spending his afternoons with a guy named Blake. So nothing to worry about. Huh. Blake. Who is Blake?", "order": 52718, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 7, "scene": 19, "line": "I don't know.", "order": 52719, "speaker": "Angela Martin"},
  {"season": 9, "episode": 7, "scene": 19, "line": "I just never heard about the senator and yoga...", "order": 52720, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 7, "scene": 19, "line": "Right.", "order": 52721, "speaker": "Angela Martin"},
  {"season": 9, "episode": 7, "scene": 19, "line": "From you. I'm sure it's probably nothing. But what's with the yoga already?", "order": 52722, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 7, "scene": 19, "line": "Right?", "order": 52723, "speaker": "Angela Martin"},
  {"season": 9, "episode": 7, "scene": 19, "line": "And Blake! All right Angela, calm down! We need to go check this out.", "order": 52724, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 7, "scene": 19, "line": "What?", "order": 52725, "speaker": "Angela Martin"},
  {"season": 9, "episode": 7, "scene": 19, "line": "Angela, I'll go with you.", "order": 52726, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 7, "scene": 20, "line": "So, uh, show us how you'd normally sell to a female client.", "order": 52727, "speaker": "Phyllis Vance"},
  {"season": 9, "episode": 7, "scene": 20, "line": "Okay. With pleasure. Get ready to learn a few new tricks, old dog.", "order": 52728, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 7, "scene": 20, "line": "You've got this Schrute.", "order": 52729, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 7, "scene": 20, "line": "Okay, you just walked into her office and begin.", "order": 52730, "speaker": "Phyllis Vance"},
  {"season": 9, "episode": 7, "scene": 20, "line": "Hello.", "order": 52731, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 7, "scene": 20, "line": "Hello.", "order": 52732, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 7, "scene": 20, "line": "May I please speak to your boss?", "order": 52733, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 7, "scene": 20, "line": "No, she is the boss.", "order": 52734, "speaker": "Phyllis Vance"},
  {"season": 9, "episode": 7, "scene": 20, "line": "I am? Hmm.. [deep voice] Hi, I'm Mr. Hannon. How can I help you?", "order": 52735, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 7, "scene": 20, "line": "Okay, this isn't working for me, 'cause no one would ever believe that she would be a boss.", "order": 52736, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 7, "scene": 20, "line": "He's absolutely right. I'm really struggling.", "order": 52737, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 7, "scene": 20, "line": "Oh, I'll be the buyer.", "order": 52738, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 7, "scene": 20, "line": "[sighing]", "order": 52739, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 7, "scene": 20, "line": "Hello, Mr. Schrute, nice to see you. Please have a seat.", "order": 52740, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 7, "scene": 20, "line": "I never sit down during sales meetings. I want to appear aggressive and imposing. I am going to sell to you in twelve minutes", "order": 52741, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 7, "scene": 20, "line": "No actually, she likes to take her time discussing her needs.", "order": 52742, "speaker": "Phyllis Vance"},
  {"season": 9, "episode": 7, "scene": 20, "line": "I will tell her what her needs are and then fill them. So this is going to work out best for you if you just relax and do nothing. And once I'm finished, it's over.", "order": 52743, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 7, "scene": 20, "line": "Okay, let's stop here. Anyone have any thoughts?", "order": 52744, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 7, "scene": 20, "line": "I thought it went great.", "order": 52745, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 7, "scene": 20, "line": "I have uh, written down a few questions. One, have you ever killed a woman? Two, how many women have you killed? Please, sir, will you not kill me?", "order": 52746, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 7, "scene": 21, "line": "[on phone] Okay lets get started.", "order": 52747, "speaker": "Business partner"},
  {"season": 9, "episode": 7, "scene": 21, "line": "Yeah, I'm here. Are we all on?", "order": 52748, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 7, "scene": 21, "line": "Uh,, well you're the only one 'on' - we're all here.", "order": 52749, "speaker": "Business partner"},
  {"season": 9, "episode": 7, "scene": 21, "line": "[nervous laughter] Right. Okay, uh, over the next three months...", "order": 52750, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 7, "scene": 21, "line": "That's the winter season., three months.", "order": 52751, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 7, "scene": 21, "line": "I uh, I have some ideas, actually...", "order": 52752, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 7, "scene": 21, "line": "Are you at your office right now?", "order": 52753, "speaker": "Business partner"},
  {"season": 9, "episode": 7, "scene": 21, "line": "[hushed voice] Uh yeah. Trust me, I'd rather be with you guys.", "order": 52754, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 7, "scene": 21, "line": "[laughing] Uh, yeah, that sounded kinda spooky-sexy, over here Halpert.", "order": 52755, "speaker": "Business partner"},
  {"season": 9, "episode": 7, "scene": 21, "line": "Oh, [clearing throat, deeper voice] Sorry, I uh... was just saying that we should uh...", "order": 52756, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 7, "scene": 21, "line": "Whoa. [laughing] I think there's been a bit of a mistake. We're trying to reach Jim Halpert, not Batman.", "order": 52757, "speaker": "Business partner"},
  {"season": 9, "episode": 7, "scene": 21, "line": "[laughing] Um, you know what? I.. should have just had... I should have just had you call me on my cell.", "order": 52758, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 7, "scene": 21, "line": "Uh, yeah...", "order": 52759, "speaker": "Business partner"},
  {"season": 9, "episode": 7, "scene": 21, "line": "I'm gonna try a different spot. Okay?", "order": 52760, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 7, "scene": 21, "line": "Okay, yeah.", "order": 52761, "speaker": "Business partner"},
  {"season": 9, "episode": 7, "scene": 21, "line": "Okay, I'll call you right back.", "order": 52762, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 7, "scene": 21, "line": "What's happening in three months?", "order": 52763, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 7, "scene": 22, "line": "Okay, when you're selling to women, it is crucial that you listen, Dwight. Also you want to respect their... Are you listening now?", "order": 52764, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 7, "scene": 22, "line": "Yes.", "order": 52765, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 7, "scene": 22, "line": "Okay, well you have to show us.", "order": 52766, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 7, "scene": 22, "line": "That's impossible. Listening happens in the ear and in the brain. I mean, some organisms have external hairs that vibrate to indicate auditory stimulation but unfortunately, our external hairs don't vibrate at all.", "order": 52767, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 7, "scene": 22, "line": "Huh. [nodding] Uh huh.", "order": 52768, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 7, "scene": 22, "line": "What are you doing?", "order": 52769, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 7, "scene": 22, "line": "A little smile and a nod shows that I hear you. Got it?", "order": 52770, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 7, "scene": 22, "line": "Kind of.", "order": 52771, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 7, "scene": 22, "line": "Nellie, why don't you tell Dwight what we were doing earlier today. And Dwight, you show us that you're listening.", "order": 52772, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 7, "scene": 22, "line": "Well, we were in the warehouse, where we were discussing a mural that I've commissioned Pam to paint there. We were talking color schemes and the major themes we want to hit. Children of the world, coming together, cutting down trees to make paper. But not in a child labor-y way.", "order": 52773, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 7, "scene": 22, "line": "It's just up and down, just a regular nod, like a person.", "order": 52774, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 7, "scene": 22, "line": "I am a person.", "order": 52775, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 7, "scene": 22, "line": "Yes.", "order": 52776, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 7, "scene": 22, "line": "And then we thought we'd ' I can't. I just can't carry on with that face. Look at it. I'm gonna get nightmares with that face. I mean he looks like he's laboring over a stool having just eaten human flesh.", "order": 52777, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 7, "scene": 22, "line": "That's a bit extreme.", "order": 52778, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 7, "scene": 22, "line": "No, I'm sorry but that is true.", "order": 52779, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 7, "scene": 22, "line": "He's screwed. They're meeting in less than an hour.", "order": 52780, "speaker": "Meredith Palmer"},
  {"season": 9, "episode": 7, "scene": 22, "line": "Oh, all right. God, Dwight, just ignore every instinct you have. It's all garbage okay? You're the woman, I'm the salesman, watch what I do and try to learn.", "order": 52781, "speaker": "Phyllis Vance"},
  {"season": 9, "episode": 7, "scene": 22, "line": "Okay, I'm a woman. [high voice] I'm a woman. Good?", "order": 52782, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 7, "scene": 22, "line": "Ms. Thomas, so good to see you.", "order": 52783, "speaker": "Phyllis Vance"},
  {"season": 9, "episode": 7, "scene": 22, "line": "Hello.", "order": 52784, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 7, "scene": 22, "line": "Oh, are those your kids? They're so cute! They could be models.", "order": 52785, "speaker": "Phyllis Vance"},
  {"season": 9, "episode": 7, "scene": 22, "line": "Thank you. I'm so proud of them. I carried each one of them for nine months inside of my torso and then pushed them out of my vagina.", "order": 52786, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 7, "scene": 22, "line": "Booo! Weird.", "order": 52787, "speaker": "Meredith Palmer"},
  {"season": 9, "episode": 7, "scene": 22, "line": "No.", "order": 52788, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 7, "scene": 22, "line": "Okay, yeah. This is a lost cause. It's hopeless.", "order": 52789, "speaker": "Phyllis Vance"},
  {"season": 9, "episode": 7, "scene": 23, "line": "Ten years ago, I didn't care if Dwight got married or died a beet-farming bachelor. But having kids makes you so soft. I used to watch Pulp Fiction and laugh, and now I'm like, that poor gimp is somebody's child.", "order": 52790, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 7, "scene": 24, "line": "You know, I think there could be a lot of benefits if you could learn to get along with women.", "order": 52791, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 7, "scene": 24, "line": "Look, I have no problem with women. It's businesswomen and their, their power suits and their shoulder pads. Don't lie about your shoulders!", "order": 52792, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 7, "scene": 24, "line": "Dwight, listen to me. Businesswomen are just normal, nice, reasonable people. Who is a nice, reasonable person in your experience?", "order": 52793, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 7, "scene": 24, "line": "I had a barber once who used to comb my hair gently.", "order": 52794, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 7, "scene": 24, "line": "Okay, so, when you're selling to this woman, just imagine that she's that nice, reasonable barber.", "order": 52795, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 7, "scene": 24, "line": "Okay, I can do that.", "order": 52796, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 7, "scene": 24, "line": "Mm-hmm. Good. Baby steps.", "order": 52797, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 7, "scene": 24, "line": "He used to fight dogs.", "order": 52798, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 7, "scene": 24, "line": "Like, he used to make dogs fight? Or he actually fought dogs?", "order": 52799, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 7, "scene": 24, "line": "Little of this, little of that.", "order": 52800, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 7, "scene": 25, "line": "Which one is the instructor? There all fatties.", "order": 52801, "speaker": "Angela Martin"},
  {"season": 9, "episode": 7, "scene": 25, "line": "Angela! [whispering] Angela. There.", "order": 52802, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 7, "scene": 25, "line": "Where?", "order": 52803, "speaker": "Angela Martin"},
  {"season": 9, "episode": 7, "scene": 25, "line": "[whispering] On the stairs. Stay calm. Stay down. Oh, so wait. Blake is a her.", "order": 52804, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 7, "scene": 25, "line": "Oh my God! She's so stunningly tiny! She's like a petite double zero, for sure. For sure! Holy cow! Look at what they're doing.", "order": 52805, "speaker": "Angela Martin"},
  {"season": 9, "episode": 7, "scene": 25, "line": "She's repositioning his hips for downward facing dog.", "order": 52806, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 7, "scene": 25, "line": "[gasps] I've heard of this - dog style. Oh wait. Oh look Oscar, Thumbelina has a boyfriend! And he has a ponytail - ew. I'd like to see that run for office. Oscar, you were right. I had nothing to be worried about. Thank you. Let's go.", "order": 52807, "speaker": "Angela Martin"},
  {"season": 9, "episode": 7, "scene": 25, "line": "Whoa, whoa, whoa, whoa, whoa...", "order": 52808, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 7, "scene": 25, "line": "Ow.", "order": 52809, "speaker": "Angela Martin"},
  {"season": 9, "episode": 7, "scene": 25, "line": "Whoa, whoa, whoa, whoa. Shut up, hold on. Shh. Hold on. Sorry. Look.", "order": 52810, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 7, "scene": 26, "line": "[sound effect of throwing ball]", "order": 52811, "speaker": "Pete Miller"},
  {"season": 9, "episode": 7, "scene": 26, "line": "This is fun. You know? I mean, this is fun.", "order": 52812, "speaker": "Toby Flenderson"},
  {"season": 9, "episode": 7, "scene": 27, "line": "What I was saying is the genius of Air Jordan was not in the market saturation, it was in --", "order": 52813, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 7, "scene": 27, "line": "[on phone] It was in what? Jim, we're having a lot of trouble hearing you.", "order": 52814, "speaker": "Business partner"},
  {"season": 9, "episode": 7, "scene": 27, "line": "The... the... the... what I was saying is the real genius was...[car alarm blaring] was in the...", "order": 52815, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 7, "scene": 27, "line": "Hey! Are those skateboarders back?", "order": 52816, "speaker": "Hank Tate"},
  {"season": 9, "episode": 7, "scene": 27, "line": "[on phone] Jim? Jim, are you there?", "order": 52817, "speaker": "Business partner"},
  {"season": 9, "episode": 7, "scene": 27, "line": "Where are they?", "order": 52818, "speaker": "Hank Tate"},
  {"season": 9, "episode": 7, "scene": 27, "line": "It was, uh in the authentic design, right? So I mean, you really felt like Michael Jordan was wearing these shoes, so ...", "order": 52819, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 7, "scene": 27, "line": "Who was messing with my van?", "order": 52820, "speaker": "Meredith Palmer"},
  {"season": 9, "episode": 7, "scene": 27, "line": "Nobody!", "order": 52821, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 7, "scene": 27, "line": "[on phone] Jordan wore them for nobody? We're not following you, Halpert.", "order": 52822, "speaker": "Business partner"},
  {"season": 9, "episode": 7, "scene": 27, "line": "No, no, no.", "order": 52823, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 7, "scene": 27, "line": "This ends now!", "order": 52824, "speaker": "Hank Tate"},
  {"season": 9, "episode": 7, "scene": 28, "line": "Have a seat. Um, she will be right in.", "order": 52825, "speaker": "Secretary"},
  {"season": 9, "episode": 7, "scene": 28, "line": "Oh, great. Oh, I'm sorry. Do you mind telling me her name? I realized we don't have it.", "order": 52826, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 7, "scene": 28, "line": "Uh... um she'll be right in.", "order": 52827, "speaker": "Secretary"},
  {"season": 9, "episode": 7, "scene": 28, "line": "Okay, great.", "order": 52828, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 7, "scene": 28, "line": "[to himself] Just a little off the top and then a nice combing. Yeah, just comb it.", "order": 52829, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 7, "scene": 28, "line": "Oh my God. It's Jan.", "order": 52830, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 7, "scene": 28, "line": "Oh, dear God in heaven.", "order": 52831, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 7, "scene": 29, "line": "Jan used to be one of my superiors, and she is one of the most erratic and terrifying people I have ever met.", "order": 52832, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 7, "scene": 30, "line": "You son of a bitch. You're firing me? Where the hell do you get off?", "order": 52833, "speaker": "Jan Levinson"},
  {"season": 9, "episode": 7, "scene": 31, "line": "Jim and I are pretty sure she had an affair with her ex-assistant Hunter. He was 17. But she looks great. If she asks, will you tell her I said that?", "order": 52834, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 7, "scene": 32, "line": "Forget everything we taught you. Hey, Jan! It's so great to see you.", "order": 52835, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 7, "scene": 32, "line": "Where's Wallace?", "order": 52836, "speaker": "Jan Levinson"},
  {"season": 9, "episode": 7, "scene": 32, "line": "What?", "order": 52837, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 7, "scene": 32, "line": "I was under the impression that David Wallace would be coming. He bought back Dunder Mifflin, correct?", "order": 52838, "speaker": "Jan Levinson"},
  {"season": 9, "episode": 7, "scene": 32, "line": "Hey. Your daughter could be a bubble bath model. I could just bite her head off. [laughs]", "order": 52839, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 7, "scene": 32, "line": "Sorry. Um, David is in Vermont. Did you speak with him? He sent Dwight instead.", "order": 52840, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 7, "scene": 32, "line": "Molly! David Wallace is in Vermont.", "order": 52841, "speaker": "Jan Levinson"},
  {"season": 9, "episode": 7, "scene": 32, "line": "Oh, my God. Um, I talked to his assistant. And I guess it did get a little confusing 'cause you said not to tell anyone your name. And then also, those bluetooths are very hard to hear with. I know you love the way they look, but Tom never had us use them...", "order": 52842, "speaker": "Molly"},
  {"season": 9, "episode": 7, "scene": 32, "line": "Molly. I am not Tom. I am Jan.", "order": 52843, "speaker": "Jan Levinson"},
  {"season": 9, "episode": 7, "scene": 32, "line": "I'm so sorry Jan.", "order": 52844, "speaker": "Molly"},
  {"season": 9, "episode": 7, "scene": 32, "line": "I thought it would be fun to have a little chat with uh, David Wallace after all these years. Oh, well. What are you doing?", "order": 52845, "speaker": "Jan Levinson"},
  {"season": 9, "episode": 7, "scene": 32, "line": "Listening.", "order": 52846, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 7, "scene": 32, "line": "Stop.", "order": 52847, "speaker": "Jan Levinson"},
  {"season": 9, "episode": 7, "scene": 32, "line": "Sorry.", "order": 52848, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 7, "scene": 32, "line": "Stop that.", "order": 52849, "speaker": "Jan Levinson"},
  {"season": 9, "episode": 7, "scene": 32, "line": "Okay.", "order": 52850, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 7, "scene": 32, "line": "So this was all just a trick. You don't really have any business to give?", "order": 52851, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 7, "scene": 32, "line": "No, I do.", "order": 52852, "speaker": "Jan Levinson"},
  {"season": 9, "episode": 7, "scene": 32, "line": "But not to us.", "order": 52853, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 7, "scene": 32, "line": "Insightful, Pam.", "order": 52854, "speaker": "Jan Levinson"},
  {"season": 9, "episode": 7, "scene": 32, "line": "You did good, Dwight. It's okay. I mean, seriously, Jan's not normal. Let's just go. She's not going to sell to us.", "order": 52855, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 7, "scene": 32, "line": "Yes, she is. Now, I may not have any instincts with women, but I have an instinct for sales. You keep her occupied. I'll be right back.", "order": 52856, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 7, "scene": 32, "line": "What?", "order": 52857, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 7, "scene": 32, "line": "Pam?", "order": 52858, "speaker": "Jan Levinson"},
  {"season": 9, "episode": 7, "scene": 32, "line": "Yeah.", "order": 52859, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 7, "scene": 32, "line": "I'm a very busy woman, so...", "order": 52860, "speaker": "Jan Levinson"},
  {"season": 9, "episode": 7, "scene": 32, "line": "Yeah. Um, do you have any other pictures of Astrid?", "order": 52861, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 7, "scene": 32, "line": "Fine. I will show you one... slide show.", "order": 52862, "speaker": "Jan Levinson"},
  {"season": 9, "episode": 7, "scene": 33, "line": "Erin, did this call...", "order": 52863, "speaker": "Pete Miller"},
  {"season": 9, "episode": 7, "scene": 33, "line": "Uh!", "order": 52864, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 7, "scene": 33, "line": "What?", "order": 52865, "speaker": "Pete Miller"},
  {"season": 9, "episode": 7, "scene": 33, "line": "Sorry, I uh, just saw your face.", "order": 52866, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 7, "scene": 33, "line": "Oh, I'm sorry. It's for the thing.", "order": 52867, "speaker": "Pete Miller"},
  {"season": 9, "episode": 7, "scene": 33, "line": "I know. That's great. It just - it makes it look like there's an eyebrow in the middle of your face.", "order": 52868, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 7, "scene": 33, "line": "Wow.", "order": 52869, "speaker": "Pete Miller"},
  {"season": 9, "episode": 7, "scene": 33, "line": "A handsome eyebrow, but, um... it makes your mouth look like an eye socket... which isn't bad.", "order": 52870, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 7, "scene": 33, "line": "Uh-huh.", "order": 52871, "speaker": "Pete Miller"},
  {"season": 9, "episode": 7, "scene": 33, "line": "But um, you look like a cyclops whose eye... fell out... Which is great. It's such a great cause.", "order": 52872, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 7, "scene": 33, "line": "Yeah.", "order": 52873, "speaker": "Pete Miller"},
  {"season": 9, "episode": 7, "scene": 33, "line": "[chuckles]", "order": 52874, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 7, "scene": 34, "line": "[audio from slide show, singing] Mommy, you're a princess. Mommy, you're a superstar. Mommy you're the greatest. How can I ever fill your sho-o-o-es?", "order": 52875, "speaker": "Jan Levinson"},
  {"season": 9, "episode": 7, "scene": 34, "line": "Wow. Your voice is as lovely as ever.", "order": 52876, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 7, "scene": 34, "line": "Aw.", "order": 52877, "speaker": "Jan Levinson"},
  {"season": 9, "episode": 7, "scene": 34, "line": "And it is so cute how she signs her name.", "order": 52878, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 7, "scene": 34, "line": "[chuckles] Well, that -- that was -- that was me too.", "order": 52879, "speaker": "Jan Levinson"},
  {"season": 9, "episode": 7, "scene": 34, "line": "Oh, okay. It's just that's how Cece does it with the backwards 'E's.", "order": 52880, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 7, "scene": 34, "line": "Cece can't spell her name.", "order": 52881, "speaker": "Jan Levinson"},
  {"season": 9, "episode": 7, "scene": 34, "line": "Oh, actually she can.", "order": 52882, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 7, "scene": 34, "line": "Well, it's not really much of a comparison, is it? I mean, 'Cece' is two letters and 'Astrid' is... I mean, there's even some adults who -- who -- who can't spell it.", "order": 52883, "speaker": "Jan Levinson"},
  {"season": 9, "episode": 7, "scene": 34, "line": "Of course.", "order": 52884, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 7, "scene": 34, "line": "Can you spell it? Try to spell it, Pam.", "order": 52885, "speaker": "Jan Levinson"},
  {"season": 9, "episode": 7, "scene": 34, "line": "Um... 'A'... 'X'? I don't -- you got me.", "order": 52886, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 7, "scene": 34, "line": "Don't patronize me.", "order": 52887, "speaker": "Jan Levinson"},
  {"season": 9, "episode": 7, "scene": 34, "line": "[whispering] I'm so sorry. I hate this. You're better.", "order": 52888, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 7, "scene": 35, "line": "[whispering] Okay, we should go now. Let's go.", "order": 52889, "speaker": "Angela Martin"},
  {"season": 9, "episode": 7, "scene": 35, "line": "[stammering wildly] Just wait. Just a minute. Just watch. [scoffs]", "order": 52890, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 7, "scene": 35, "line": "Wait. Why are you... Oh. are you getting your jollies right now? Can't get enough of the show? Your jollies are all on fire --", "order": 52891, "speaker": "Angela Martin"},
  {"season": 9, "episode": 7, "scene": 35, "line": "[whispering] Please. It's Robert who's enjoying it.", "order": 52892, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 7, "scene": 35, "line": "What?", "order": 52893, "speaker": "Angela Martin"},
  {"season": 9, "episode": 7, "scene": 35, "line": "This could be the affair that you're scared of. Politicians are wonderful liars. You never know who they really are. [pause] But uh, he's probably not gay. He's straight. He's straight, so...", "order": 52894, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 7, "scene": 36, "line": "Mm. Excuse me. Could I get some more water?", "order": 52895, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 7, "scene": 36, "line": "No.", "order": 52896, "speaker": "Jan Levinson"},
  {"season": 9, "episode": 7, "scene": 36, "line": "Jan... You thought I had no more cards left to play. Well I've got one. Man-boy! The Ace of Babes.", "order": 52897, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 7, "scene": 36, "line": "Oh, my God.", "order": 52898, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 7, "scene": 36, "line": "Where's the Quizno's?", "order": 52899, "speaker": "Clark Green"},
  {"season": 9, "episode": 7, "scene": 36, "line": "You're the Quizno's. [chuckles] Jan, may I introduce to you your own personal Dunder Mifflin liaison, devoted to servicing this account with total client satisfaction. I sensed that Molly wasn't quite meeting your needs -- nothing like, uh, your old assistant... Hunter. Was that his name?", "order": 52900, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 7, "scene": 36, "line": "I -- I --", "order": 52901, "speaker": "Jan Levinson"},
  {"season": 9, "episode": 7, "scene": 36, "line": "Hmm?", "order": 52902, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 7, "scene": 36, "line": "I don't recall. And yes, Molly is crap.", "order": 52903, "speaker": "Jan Levinson"},
  {"season": 9, "episode": 7, "scene": 36, "line": "Okay, you do not have to do this.", "order": 52904, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 7, "scene": 36, "line": "Do what? Get into sales? That's what I want.", "order": 52905, "speaker": "Clark Green"},
  {"season": 9, "episode": 7, "scene": 36, "line": "He's been growing that mustache for weeks. Best he can do... So young.", "order": 52906, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 7, "scene": 36, "line": "Will you uh, [clicks tongue] you. Can you turn around for me, please? Dwight you can go. I will call you in a week or so and let you know whether I want your business.", "order": 52907, "speaker": "Jan Levinson"},
  {"season": 9, "episode": 7, "scene": 36, "line": "Very good.", "order": 52908, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 7, "scene": 36, "line": "[to Clark] Do you have a valid passport?", "order": 52909, "speaker": "Jan Levinson"},
  {"season": 9, "episode": 7, "scene": 37, "line": "Jim Halpert.", "order": 52910, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 7, "scene": 37, "line": "[on phone] Hey, it's Colin.", "order": 52911, "speaker": "Colin"},
  {"season": 9, "episode": 7, "scene": 37, "line": "Hey man. I am so sorry about that.", "order": 52912, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 7, "scene": 37, "line": "I know. Don't worry about it.", "order": 52913, "speaker": "Colin"},
  {"season": 9, "episode": 7, "scene": 37, "line": "[laughs]", "order": 52914, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 7, "scene": 37, "line": "It's just... it's not totally working.", "order": 52915, "speaker": "Colin"},
  {"season": 9, "episode": 7, "scene": 37, "line": "Yeah. No, I know. This whole telecommuting thing -- not ideal. But don't worry. I'll figure it out.", "order": 52916, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 7, "scene": 37, "line": "Yeah, well, it's not just not ideal. I mean, with you there, I don't know how we're gonna do this.", "order": 52917, "speaker": "Colin"},
  {"season": 9, "episode": 7, "scene": 37, "line": "Uh, what does -- what does that mean?", "order": 52918, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 7, "scene": 38, "line": "Oscar, what is happening here? Why would you say you think the senator might be gay?", "order": 52919, "speaker": "Angela Martin"},
  {"season": 9, "episode": 7, "scene": 38, "line": "I don't know, Angela. I'm dehydrated. Maybe... You heard me wrong. We should just go.", "order": 52920, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 7, "scene": 38, "line": "Look, look, look. Here he comes. Here he comes. What is he doing?", "order": 52921, "speaker": "Angela Martin"},
  {"season": 9, "episode": 7, "scene": 38, "line": "He's making a phone call.", "order": 52922, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 7, "scene": 38, "line": "[ducking under table with Oscar] Oh. Oh.", "order": 52923, "speaker": "Angela Martin"},
  {"season": 9, "episode": 7, "scene": 38, "line": "[phone vibrates, rings]", "order": 52924, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 7, "scene": 39, "line": "Oh, hey, Molly. You should just quit.", "order": 52925, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 7, "scene": 39, "line": "Thanks. Okay.", "order": 52926, "speaker": "Molly"},
  {"season": 9, "episode": 7, "scene": 39, "line": "Oh and uh, Molly... I know it can't be easy working for Jan. Good luck with your feelings.", "order": 52927, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 7, "scene": 39, "line": "Dwight, that was really nice. You should ask for her number.", "order": 52928, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 7, "scene": 39, "line": "Oh, I got her number. 415-YCL.", "order": 52929, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 7, "scene": 39, "line": "That's a license number?", "order": 52930, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 7, "scene": 39, "line": "That's all you need. And when I have curried favor with her, I will let you know.", "order": 52931, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 7, "scene": 39, "line": "Oh. Why me?", "order": 52932, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 7, "scene": 39, "line": "Because you are my friend and you are a woman... And women love gossip. It's like air to you people. Ugh, God. [retching]", "order": 52933, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 8, "scene": 1, "line": "Yesterday, Angela may or may not have figured out that I'm having an affair with her husband. So I just have to wait and see. When she comes in, if she's cold and awkward and cruel to me, then great, it's business as usual.", "order": 52934, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 8, "scene": 2, "line": "Good morning. [clears throat]", "order": 52935, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 8, "scene": 2, "line": "Oscar... [sighs] can I ask you a question?", "order": 52936, "speaker": "Angela Martin"},
  {"season": 9, "episode": 8, "scene": 2, "line": "[whispering] Of course, ask me a que-- questions.", "order": 52937, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 8, "scene": 2, "line": "Is it cool in here to you?", "order": 52938, "speaker": "Angela Martin"},
  {"season": 9, "episode": 8, "scene": 2, "line": "[hoarsely] Yes, a little bit. [normal voice] Yes.", "order": 52939, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 8, "scene": 2, "line": "I think the thermostat is acting up again.", "order": 52940, "speaker": "Angela Martin"},
  {"season": 9, "episode": 8, "scene": 2, "line": "It's the stupid thermostat! That thing is a catastrophe. So I'm gonna, um, on your suggestion, get someone to fix it. I'll just go downstairs.", "order": 52941, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 8, "scene": 2, "line": "Thank you.", "order": 52942, "speaker": "Angela Martin"},
  {"season": 9, "episode": 8, "scene": 2, "line": "No, thank you, Angela.", "order": 52943, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 8, "scene": 3, "line": "She doesn't know. I shouldn't be surprised. This is a woman who married a man who is obviously a homosexual. Basically, she has her head in the sand. In a way I feel sorry for her. I guess the universe rewards true love.", "order": 52944, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 8, "scene": 4, "line": "Well, well, well, it's finally happened. Pam has ceased caring.", "order": 52945, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 8, "scene": 4, "line": "These are my painting clothes.I think I'm gonna do it. I am really gonna start painting the warehouse mural today. [Jim applauds]", "order": 52946, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 8, "scene": 4, "line": "Sure you don't want to put another coat of primer on that Pam?Queen of the primer, that one.", "order": 52947, "speaker": "Meredith Palmer"},
  {"season": 9, "episode": 8, "scene": 4, "line": "You got this, Beesley. Actually, do you want me to come down and help you get started?", "order": 52948, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 8, "scene": 4, "line": "Are you avoiding your phone call?", "order": 52949, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 8, "scene": 4, "line": "What? Yeah, right. As if.", "order": 52950, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 8, "scene": 5, "line": "Today I will be asking David Wallace if I can start working part-time, because the sports marketing company that I started really needs me to be there.", "order": 52951, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 8, "scene": 5, "line": "Last week Jim wasn't there, and they named the company Athlead.", "order": 52952, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 8, "scene": 5, "line": "I could have prevented that. So I have to talk to Wallace.", "order": 52953, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 8, "scene": 5, "line": "Tell them your opening line.", "order": 52954, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 8, "scene": 5, "line": "[sighs] Hey David, how would you like a guy who's not here as much, gets paid the same amount of salary, and has bigger fish to fry in Philadelphia?", "order": 52955, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 8, "scene": 5, "line": "I think it's good. He likes fishing.", "order": 52956, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 8, "scene": 5, "line": "This is gonna be awful.", "order": 52957, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 8, "scene": 6, "line": "One of my jobs is to input customer complaints into the computer. And when they're in, I fill out one of these cards. But the information's already on the computer, so....why am I filling out the card? I asked Andy, and he told me to 'chillax,' and then went away on a big, long boat ride. So here we are. Don't give me a pointless office chore, because I will build a little paper house. Fight the power.", "order": 52958, "speaker": "Pete Miller"},
  {"season": 9, "episode": 8, "scene": 7, "line": "Meet me in the old place, five minutes. I need you.", "order": 52959, "speaker": "Angela Martin"},
  {"season": 9, "episode": 8, "scene": 8, "line": "[upon seeing Dwight naked] Ugh!", "order": 52960, "speaker": "Angela Martin"},
  {"season": 9, "episode": 8, "scene": 8, "line": "Come on in, the water's fine.", "order": 52961, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 8, "scene": 8, "line": "Dwight, it's not that kind of meeting. Put your clothes back on.", "order": 52962, "speaker": "Angela Martin"},
  {"season": 9, "episode": 8, "scene": 8, "line": "I know. That's not why I'm naked. I always work out without my clothes. [does jumping jacks]", "order": 52963, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 8, "scene": 8, "line": "Just put them on! Put on your clothes. I need your help. I need someone who can operate outside of the law. Ugh.", "order": 52964, "speaker": "Angela Martin"},
  {"season": 9, "episode": 8, "scene": 8, "line": "Oh, I'm sorry, your vigilante privileges ended when you broke up with me. If I'm not in your panties, I don't go vigilantes. Why don't you ask your husband?", "order": 52965, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 8, "scene": 8, "line": "My marriage is in danger. I don't know who I can trust. I need someone to be there for me.", "order": 52966, "speaker": "Angela Martin"},
  {"season": 9, "episode": 8, "scene": 8, "line": "[sighs] All right, what are we talking? Surveillance, wire-tapping?", "order": 52967, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 8, "scene": 8, "line": "Something like that.", "order": 52968, "speaker": "Angela Martin"},
  {"season": 9, "episode": 8, "scene": 8, "line": "Yeah, the less I know, the better. I know just the guy. He was a volunteer sheriff too. Kicked off the force.", "order": 52969, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 8, "scene": 8, "line": "Can you arrange a meeting?", "order": 52970, "speaker": "Angela Martin"},
  {"season": 9, "episode": 8, "scene": 8, "line": "I can try. I'm gonna use SMS text.", "order": 52971, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 8, "scene": 8, "line": "Okay.", "order": 52972, "speaker": "Angela Martin"},
  {"season": 9, "episode": 8, "scene": 8, "line": "Text went through.", "order": 52973, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 8, "scene": 8, "line": "Okay.", "order": 52974, "speaker": "Angela Martin"},
  {"season": 9, "episode": 8, "scene": 8, "line": "All we can do is sit and wait.", "order": 52975, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 8, "scene": 8, "line": "Okay.", "order": 52976, "speaker": "Angela Martin"},
  {"season": 9, "episode": 8, "scene": 8, "line": "[phone vibrates] Oh, look at that. Yeah, he's free anytime. Not a problem.", "order": 52977, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 8, "scene": 9, "line": "I mean, I can handle any client issues from Philly.", "order": 52978, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 8, "scene": 9, "line": "[on phone] Yeah, but I really need someone in the office. If there's a crisis -- the more I think about it --", "order": 52979, "speaker": "David Wallace"},
  {"season": 9, "episode": 8, "scene": 9, "line": "Oh, you mean handle it in person. Oh, well, Phyllis and Stanley have agree to cover for me while I'm gone.", "order": 52980, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 8, "scene": 9, "line": "They did?", "order": 52981, "speaker": "David Wallace"},
  {"season": 9, "episode": 8, "scene": 9, "line": "Yep.", "order": 52982, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 8, "scene": 9, "line": "Oh, okay. Well, that is different. In that case, yes-- Maybe this can work.", "order": 52983, "speaker": "David Wallace"},
  {"season": 9, "episode": 8, "scene": 9, "line": "Oh, great.", "order": 52984, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 8, "scene": 10, "line": "Why should we help you?", "order": 52985, "speaker": "Stanley Hudson"},
  {"season": 9, "episode": 8, "scene": 10, "line": "Because we're friends.", "order": 52986, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 8, "scene": 10, "line": "When is my birthday?", "order": 52987, "speaker": "Stanley Hudson"},
  {"season": 9, "episode": 8, "scene": 10, "line": "Unfair. When's my birthday?", "order": 52988, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 8, "scene": 10, "line": "I don't know, because we're not friends.", "order": 52989, "speaker": "Stanley Hudson"},
  {"season": 9, "episode": 8, "scene": 10, "line": "How about this-- You let me take you to lunch, and I make my case?", "order": 52990, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 8, "scene": 10, "line": "Now we're talkin'.", "order": 52991, "speaker": "Stanley Hudson"},
  {"season": 9, "episode": 8, "scene": 10, "line": "All right.", "order": 52992, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 8, "scene": 10, "line": "Yeah.", "order": 52993, "speaker": "Phyllis Vance"},
  {"season": 9, "episode": 8, "scene": 11, "line": "Make it go taller.", "order": 52994, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 8, "scene": 11, "line": "That's the idea.", "order": 52995, "speaker": "Pete Miller"},
  {"season": 9, "episode": 8, "scene": 11, "line": "No, not taller this way, taller this way. [gesturing with hands]", "order": 52996, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 8, "scene": 11, "line": "Well, I've gotta build a wider base first before I can go higher.", "order": 52997, "speaker": "Pete Miller"},
  {"season": 9, "episode": 8, "scene": 11, "line": "You're not getting this, Peter. Make it go wider... up!", "order": 52998, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 8, "scene": 11, "line": "Will do.", "order": 52999, "speaker": "Pete Miller"},
  {"season": 9, "episode": 8, "scene": 11, "line": "[entering] What are y'all doing?", "order": 53000, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 8, "scene": 11, "line": "Me and Pete are building a tower.", "order": 53001, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 8, "scene": 11, "line": "Cool. It should be taller though, right?", "order": 53002, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 8, "scene": 11, "line": "Obviously. He's a sweet kid, Darryl. But he's not the sharpest guy in the drawer.", "order": 53003, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 8, "scene": 11, "line": "Kevin, I can hear you.", "order": 53004, "speaker": "Pete Miller"},
  {"season": 9, "episode": 8, "scene": 11, "line": "Huh?", "order": 53005, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 8, "scene": 12, "line": "Ow! Dwight! Ow!", "order": 53006, "speaker": "Angela Martin"},
  {"season": 9, "episode": 8, "scene": 12, "line": "Get in the van.", "order": 53007, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 8, "scene": 12, "line": "God!", "order": 53008, "speaker": "Angela Martin"},
  {"season": 9, "episode": 8, "scene": 12, "line": "Is it safe to talk?", "order": 53009, "speaker": "Trevor"},
  {"season": 9, "episode": 8, "scene": 12, "line": "Well, this documentary crew has been following our every move for the past nine years, but I don't see them so I think we're good.", "order": 53010, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 8, "scene": 12, "line": "So what are your credentials?", "order": 53011, "speaker": "Angela Martin"},
  {"season": 9, "episode": 8, "scene": 12, "line": "I started following people around to get exercise. Turns out, I'm damn good at it.", "order": 53012, "speaker": "Trevor"},
  {"season": 9, "episode": 8, "scene": 12, "line": "Do you have a gun?", "order": 53013, "speaker": "Angela Martin"},
  {"season": 9, "episode": 8, "scene": 12, "line": "[snickers] Does he own a gun? Show her.", "order": 53014, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 8, "scene": 12, "line": "You tell me.", "order": 53015, "speaker": "Trevor"},
  {"season": 9, "episode": 8, "scene": 12, "line": "What is this?", "order": 53016, "speaker": "Angela Martin"},
  {"season": 9, "episode": 8, "scene": 12, "line": "It's the receipt for my gun.", "order": 53017, "speaker": "Trevor"},
  {"season": 9, "episode": 8, "scene": 12, "line": "You don't carry it with you?", "order": 53018, "speaker": "Angela Martin"},
  {"season": 9, "episode": 8, "scene": 12, "line": "Read the receipt. That's a $300 gun. Someone could steal it.", "order": 53019, "speaker": "Trevor"},
  {"season": 9, "episode": 8, "scene": 12, "line": "Do you have any idea how many guns Trevor's had stolen from him?", "order": 53020, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 8, "scene": 12, "line": "Now I keep it in a safe.", "order": 53021, "speaker": "Trevor"},
  {"season": 9, "episode": 8, "scene": 12, "line": "Mm-hmm. Good safe?", "order": 53022, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 8, "scene": 12, "line": "Oh, you tell me. [shows Dwight receipt]", "order": 53023, "speaker": "Trevor"},
  {"season": 9, "episode": 8, "scene": 12, "line": "Wow!", "order": 53024, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 8, "scene": 13, "line": "[studying mural wall] I guess if I make a mistake, I can just paint over it with a shrub or something. It's just, I think less of paintings with a lot of shrubs. So, I'm gonna limit myself to one shrub.", "order": 53025, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 8, "scene": 13, "line": "You paint wall now?", "order": 53026, "speaker": "Hidetoshi Hasagawa"},
  {"season": 9, "episode": 8, "scene": 13, "line": "Yeah. Painting now. I just want to make sure that...", "order": 53027, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 8, "scene": 13, "line": "You paint now.", "order": 53028, "speaker": "Hidetoshi Hasagawa"},
  {"season": 9, "episode": 8, "scene": 13, "line": "It's probably gonna be a few minutes. So you can just go back to doing whatever you were doing.", "order": 53029, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 8, "scene": 13, "line": "I wait.", "order": 53030, "speaker": "Hidetoshi Hasagawa"},
  {"season": 9, "episode": 8, "scene": 14, "line": "Sweet.", "order": 53031, "speaker": "Meredith Palmer"},
  {"season": 9, "episode": 8, "scene": 14, "line": "Yay!", "order": 53032, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 8, "scene": 14, "line": "That's what I'm talkin' about.", "order": 53033, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 8, "scene": 14, "line": "This next card comes to us thanks to Meredith Palmer, who called Eastern Pennsylvania Seminary a, quote 'sausage factory.'", "order": 53034, "speaker": "Pete Miller"},
  {"season": 9, "episode": 8, "scene": 14, "line": "Oh OOOH!", "order": 53035, "speaker": "Meredith Palmer"},
  {"season": 9, "episode": 8, "scene": 14, "line": "[approving cheers]", "order": 53036, "speaker": "Everyone"},
  {"season": 9, "episode": 8, "scene": 14, "line": "Boom!", "order": 53037, "speaker": "Meredith Palmer"},
  {"season": 9, "episode": 8, "scene": 14, "line": "Bang.", "order": 53038, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 8, "scene": 14, "line": "Yep, yep, yep.", "order": 53039, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 8, "scene": 14, "line": "All right. Up next we got a whole lotta Creed.", "order": 53040, "speaker": "Pete Miller"},
  {"season": 9, "episode": 8, "scene": 14, "line": "Let's find out what I did.", "order": 53041, "speaker": "Creed Bratton"},
  {"season": 9, "episode": 8, "scene": 14, "line": "All right.", "order": 53042, "speaker": "Pete Miller"},
  {"season": 9, "episode": 8, "scene": 15, "line": "You get half now and half upon completion of said job.", "order": 53043, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 8, "scene": 15, "line": "And that's all off the books?", "order": 53044, "speaker": "Trevor"},
  {"season": 9, "episode": 8, "scene": 15, "line": "Obviously.", "order": 53045, "speaker": "Angela Martin"},
  {"season": 9, "episode": 8, "scene": 15, "line": "Nice. No taxes.", "order": 53046, "speaker": "Trevor"},
  {"season": 9, "episode": 8, "scene": 15, "line": "Okay, so everything you need to know about the target is in here.", "order": 53047, "speaker": "Angela Martin"},
  {"season": 9, "episode": 8, "scene": 15, "line": "So what's the job?", "order": 53048, "speaker": "Trevor"},
  {"season": 9, "episode": 8, "scene": 15, "line": "Murder.", "order": 53049, "speaker": "Angela Martin"},
  {"season": 9, "episode": 8, "scene": 15, "line": "Okay, that's the big one. That's the big 'M.'", "order": 53050, "speaker": "Trevor"},
  {"season": 9, "episode": 8, "scene": 16, "line": "You can't have someone murdered.", "order": 53051, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 8, "scene": 16, "line": "What if they deserved it?", "order": 53052, "speaker": "Angela Martin"},
  {"season": 9, "episode": 8, "scene": 16, "line": "What did they do to you, Angela?", "order": 53053, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 8, "scene": 16, "line": "They're sleeping with my husband.", "order": 53054, "speaker": "Angela Martin"},
  {"season": 9, "episode": 8, "scene": 16, "line": "Oh, Monkey. Oh, I feel for you.", "order": 53055, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 8, "scene": 16, "line": "This seems a little crazy.", "order": 53056, "speaker": "Trevor"},
  {"season": 9, "episode": 8, "scene": 16, "line": "Yes. Crazy. Thank you.", "order": 53057, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 8, "scene": 16, "line": "But I think I'm up for it.", "order": 53058, "speaker": "Trevor"},
  {"season": 9, "episode": 8, "scene": 16, "line": "No! No!", "order": 53059, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 8, "scene": 16, "line": "Thank you.", "order": 53060, "speaker": "Angela Martin"},
  {"season": 9, "episode": 8, "scene": 16, "line": "Absolutely not. There are a lot of different ways to get revenge. I've had great success by defecating in a paper bag, put it on the porch--", "order": 53061, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 8, "scene": 16, "line": "That's very effective. I've been on the receiving end of that quite a few times. It's devastating.", "order": 53062, "speaker": "Trevor"},
  {"season": 9, "episode": 8, "scene": 16, "line": "No, no, no. It has to be physical. I want this person to suffer.", "order": 53063, "speaker": "Angela Martin"},
  {"season": 9, "episode": 8, "scene": 16, "line": "What about a knee-capping?", "order": 53064, "speaker": "Trevor"},
  {"season": 9, "episode": 8, "scene": 16, "line": "No! You're not helping, Trevor!", "order": 53065, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 8, "scene": 16, "line": "Yes, a knee-capping could work.", "order": 53066, "speaker": "Angela Martin"},
  {"season": 9, "episode": 8, "scene": 16, "line": "No. Angela! What are you saying?", "order": 53067, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 8, "scene": 16, "line": "You said you would be there for me.", "order": 53068, "speaker": "Angela Martin"},
  {"season": 9, "episode": 8, "scene": 16, "line": "I'm trying, but what you're asking is--", "order": 53069, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 8, "scene": 16, "line": "It's the only thing that will make this right.", "order": 53070, "speaker": "Angela Martin"},
  {"season": 9, "episode": 8, "scene": 16, "line": "Okay. But it's cruel, because a woman with damaged knees can't scrub worth a damn.", "order": 53071, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 8, "scene": 16, "line": "All right, then it's settled. One knee-capping. Now, the hit goes down at 4:00. Keep in mind, once I leave, there's no turning back.", "order": 53072, "speaker": "Trevor"},
  {"season": 9, "episode": 8, "scene": 17, "line": "You know, truth be told, I think all you'll really be doing is accepting calls from my clients while I'm gone.", "order": 53073, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 8, "scene": 17, "line": "We've got all afternoon to talk about that.", "order": 53074, "speaker": "Stanley Hudson"},
  {"season": 9, "episode": 8, "scene": 17, "line": "Morning, folks.", "order": 53075, "speaker": "Waiter"},
  {"season": 9, "episode": 8, "scene": 17, "line": "I'll have the surf and turf with a side order of lobster.", "order": 53076, "speaker": "Stanley Hudson"},
  {"season": 9, "episode": 8, "scene": 17, "line": "Actually, the surf and turf does come with lob--", "order": 53077, "speaker": "Waiter"},
  {"season": 9, "episode": 8, "scene": 17, "line": "Not enough lobster. Side order.", "order": 53078, "speaker": "Stanley Hudson"},
  {"season": 9, "episode": 8, "scene": 17, "line": "How much wine do you have?", "order": 53079, "speaker": "Phyllis Vance"},
  {"season": 9, "episode": 8, "scene": 18, "line": "I brought you a cookie.", "order": 53080, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 8, "scene": 18, "line": "Oh, thanks, Oscar. You're such an angel.", "order": 53081, "speaker": "Angela Martin"},
  {"season": 9, "episode": 8, "scene": 19, "line": "[talking into phone] I just gave her a cookie, and she called me an angel, so... yeah, we're good. Yeah. [exhales] We dodged a bullet, yes. Okay. Yeah. Yeah, yeah-- Well, I gotta go now, but-- Okay, bye. Bye.", "order": 53082, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 8, "scene": 20, "line": "There we go.", "order": 53083, "speaker": "Pete Miller"},
  {"season": 9, "episode": 8, "scene": 20, "line": "[cheers and applause]", "order": 53084, "speaker": "Everyone"},
  {"season": 9, "episode": 8, "scene": 20, "line": "Nicely done. Very nicely done. All right, this next one goes to Darryl for pocket dialing a customer while having sex.", "order": 53085, "speaker": "Pete Miller"},
  {"season": 9, "episode": 8, "scene": 20, "line": "Oooohh... you salty dog.", "order": 53086, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 8, "scene": 20, "line": "Well, yeah, what can I say, a player's gotta play.", "order": 53087, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 8, "scene": 20, "line": "There you go.", "order": 53088, "speaker": "Pete Miller"},
  {"season": 9, "episode": 8, "scene": 21, "line": "Actually, that was the sound of me eating spaghetti. But I'm gonna let them think the other thing.", "order": 53089, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 8, "scene": 22, "line": "Okay, I got this one.", "order": 53090, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 8, "scene": 22, "line": "Oh, be careful! Be careful! Be careful", "order": 53091, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 8, "scene": 22, "line": "No, I got it. Easy does it, everyone. Nobody even take a breath.", "order": 53092, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 8, "scene": 22, "line": "[shouting as tower collapses] Oh no! Kevin!", "order": 53093, "speaker": "Everyone"},
  {"season": 9, "episode": 8, "scene": 22, "line": "What did I just say? What did I just say?", "order": 53094, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 8, "scene": 22, "line": "Hey, hey, hey, it's just a mistake. Just a mistake. That's what this tower's all about -- mistakes. Okay, if you're afraid of screwing up, the tower's not for you. Show of hands --- who here has never had a complaint? That's right. Nobody. See that? Nobody. Okay? Let's get back to work, huh? Come on, you in?", "order": 53095, "speaker": "Pete Miller"},
  {"season": 9, "episode": 8, "scene": 22, "line": "Yeah.", "order": 53096, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 8, "scene": 22, "line": "There we go. All right, let's do it.", "order": 53097, "speaker": "Pete Miller"},
  {"season": 9, "episode": 8, "scene": 23, "line": "You know, at the end of the day, it's really only two days. I mean, I'll be back in the office. If you need me for an emergency, call me. I'll be there...", "order": 53098, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 8, "scene": 23, "line": "You know what? I don't know where the years went. 'Cause sometimes when I look at my hands, I don't even recognize them.", "order": 53099, "speaker": "Phyllis Vance"},
  {"season": 9, "episode": 8, "scene": 23, "line": "Tell me about it.", "order": 53100, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 8, "scene": 23, "line": "Whose hands are these? [holding out her hands to Jim] Theyre not my hands. I don't know.", "order": 53101, "speaker": "Phyllis Vance"},
  {"season": 9, "episode": 8, "scene": 23, "line": "All right. You know what? Maybe we'll just... We'll go slow.", "order": 53102, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 8, "scene": 23, "line": "No. Jim... [wine bottle clangs on plate]", "order": 53103, "speaker": "Phyllis Vance"},
  {"season": 9, "episode": 8, "scene": 24, "line": "All right. Check it out.", "order": 53104, "speaker": "Pete Miller"},
  {"season": 9, "episode": 8, "scene": 24, "line": "[cheers and applause]", "order": 53105, "speaker": "Everyone"},
  {"season": 9, "episode": 8, "scene": 24, "line": "Like a Phoenix from the ashes. Ksshhhh!", "order": 53106, "speaker": "Pete Miller"},
  {"season": 9, "episode": 8, "scene": 24, "line": "Nice. Pretty soon, we're gonna be at the ceiling.", "order": 53107, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 8, "scene": 24, "line": "Whoo!", "order": 53108, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 8, "scene": 24, "line": "Can you hand me a card?", "order": 53109, "speaker": "Pete Miller"},
  {"season": 9, "episode": 8, "scene": 24, "line": "Um,. it's empty.", "order": 53110, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 8, "scene": 24, "line": "What?", "order": 53111, "speaker": "Pete Miller"},
  {"season": 9, "episode": 8, "scene": 24, "line": "Oh, come on. We could use a blank card.", "order": 53112, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 8, "scene": 24, "line": "No!!", "order": 53113, "speaker": "Everyone"},
  {"season": 9, "episode": 8, "scene": 24, "line": "That's cheating.", "order": 53114, "speaker": "Meredith Palmer"},
  {"season": 9, "episode": 8, "scene": 24, "line": "I could get us a complaint.", "order": 53115, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 8, "scene": 24, "line": "You? Little Miss Priss? You wouldn't fart on a butterfly.", "order": 53116, "speaker": "Meredith Palmer"},
  {"season": 9, "episode": 8, "scene": 24, "line": "No, I wouldn't. I can't even relate to that impulse. But I bet I could get us a customer complaint. I'd like to try.", "order": 53117, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 8, "scene": 24, "line": "Hmm. Yeah. All right! Yea, go, Pam! Pam...", "order": 53118, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 8, "scene": 24, "line": "[chanting] 'Pam!'", "order": 53119, "speaker": "Everyone"},
  {"season": 9, "episode": 8, "scene": 25, "line": "What? Why did you call me out here?", "order": 53120, "speaker": "Angela Martin"},
  {"season": 9, "episode": 8, "scene": 25, "line": "The target-- it's Oscar, isn't it? He and the senator are gaying each other.", "order": 53121, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 8, "scene": 25, "line": "I don't know what you're talking about.", "order": 53122, "speaker": "Angela Martin"},
  {"season": 9, "episode": 8, "scene": 25, "line": "Your nostrils tell a different story. They flare like that every time you're engaging in deception. Hello again, naughty nostrils.", "order": 53123, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 8, "scene": 25, "line": "Fine! It's Oscar. So what?", "order": 53124, "speaker": "Angela Martin"},
  {"season": 9, "episode": 8, "scene": 25, "line": "Well I could understand you wanting to get a stranger's knees whacked. But a co-worker-- Dare I say, a friend?", "order": 53125, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 8, "scene": 25, "line": "Exactly, a friend. Someone who sits next to you year after year, making chitchat and smiling, and all the while running behind your back and defiling your marriage and seducing your husband.", "order": 53126, "speaker": "Angela Martin"},
  {"season": 9, "episode": 8, "scene": 25, "line": "I can't even imagine how painful this must be for you. But the first ones to break your marriage vows were you and me.", "order": 53127, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 8, "scene": 25, "line": "Well, you might be right. But it's too late now.", "order": 53128, "speaker": "Angela Martin"},
  {"season": 9, "episode": 8, "scene": 25, "line": "What do you mean?", "order": 53129, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 8, "scene": 25, "line": "He's here.", "order": 53130, "speaker": "Angela Martin"},
  {"season": 9, "episode": 8, "scene": 25, "line": "No! No, no! [groans]", "order": 53131, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 8, "scene": 26, "line": "Oscar? Oscar. Oscar, good. Hey come with me.", "order": 53132, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 8, "scene": 26, "line": "What?", "order": 53133, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 8, "scene": 26, "line": "Come -- come with me.", "order": 53134, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 8, "scene": 26, "line": "What are you doing?", "order": 53135, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 8, "scene": 26, "line": "There are a bunch of construction workers in the warehouse without their trousers, drinking diet sodas. You have got to see this. They're extraordinary.", "order": 53136, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 8, "scene": 26, "line": "Yahtzee.", "order": 53137, "speaker": "Meredith Palmer"},
  {"season": 9, "episode": 8, "scene": 26, "line": "Sandwich delivery for Mr. Oscar Martinez.", "order": 53138, "speaker": "Trevor"},
  {"season": 9, "episode": 8, "scene": 26, "line": "I am Oscar Martinez.", "order": 53139, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 8, "scene": 26, "line": "No, not him, not him. Outside. Outside.", "order": 53140, "speaker": "Angela Martin"},
  {"season": 9, "episode": 8, "scene": 26, "line": "Wha-- wh--", "order": 53141, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 8, "scene": 26, "line": "You know, there's doughnuts in the break room.", "order": 53142, "speaker": "Angela Martin"},
  {"season": 9, "episode": 8, "scene": 26, "line": "Nice! Yeah.", "order": 53143, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 8, "scene": 27, "line": "Listen, I was really wondering if maybe we could lock this down before lunch is over.", "order": 53144, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 8, "scene": 27, "line": "Don't be pushy Jim. It's tacky.", "order": 53145, "speaker": "Stanley Hudson"},
  {"season": 9, "episode": 8, "scene": 27, "line": "All right. Phyllis! Phyllis, that's-- that's decorative.", "order": 53146, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 8, "scene": 27, "line": "No, there's wine in here.", "order": 53147, "speaker": "Phyllis Vance"},
  {"season": 9, "episode": 8, "scene": 27, "line": "Still decorative.", "order": 53148, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 8, "scene": 27, "line": "Is it white wine?", "order": 53149, "speaker": "Stanley Hudson"},
  {"season": 9, "episode": 8, "scene": 27, "line": "No.", "order": 53150, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 8, "scene": 27, "line": "[to customer] Can you help me?", "order": 53151, "speaker": "Phyllis Vance"},
  {"season": 9, "episode": 8, "scene": 27, "line": "Don't-- don't-- don't pole people with knives.", "order": 53152, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 8, "scene": 27, "line": "[groaning while trying to open giant wine bottle] ha ha!", "order": 53153, "speaker": "Phyllis Vance"},
  {"season": 9, "episode": 8, "scene": 27, "line": "Phyllis! Wow.", "order": 53154, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 8, "scene": 27, "line": "Ooh, bring it over.", "order": 53155, "speaker": "Stanley Hudson"},
  {"season": 9, "episode": 8, "scene": 27, "line": "Got it.", "order": 53156, "speaker": "Phyllis Vance"},
  {"season": 9, "episode": 8, "scene": 28, "line": "There's no time to explain. [descending stairs] Okay, actually, there is time to explain. When Angela found out that you seduced her husband, we hired a guy to break your knee caps.", "order": 53157, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 8, "scene": 28, "line": "Oh, my God! What is wrong wiht you?", "order": 53158, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 8, "scene": 28, "line": "What is wrong with you? There are a million gorgeous guys in the Scranton Wilkes-Barre area, and you choose the man who's the father of her child?", "order": 53159, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 8, "scene": 28, "line": "I don't know what you're talking about.", "order": 53160, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 8, "scene": 28, "line": "Oh, don't lie. I'm trying to save those precious knees you're always bragging about. Now, let's get out of here. He could be right behind us.", "order": 53161, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 8, "scene": 29, "line": "Aah! Actually, he's right in front of us.", "order": 53162, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 8, "scene": 29, "line": "Let's get it on. I'm gonna do this. I might-- I might puke, but I'm gonna do this.", "order": 53163, "speaker": "Trevor"},
  {"season": 9, "episode": 8, "scene": 29, "line": "No, Trevor, I am not gonna let you. He's a Dunder-Mifflin man. He's my tribe.", "order": 53164, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 8, "scene": 29, "line": "I'm sorry, Dwight, but for once in my stupid, stupid life, I'm gonna follow through on something, all right? I have masculinity issues-- Stop! No!", "order": 53165, "speaker": "Trevor"},
  {"season": 9, "episode": 8, "scene": 29, "line": "I got it.", "order": 53166, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 8, "scene": 29, "line": "Disarm!", "order": 53167, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 8, "scene": 29, "line": "You don't-- [all three grunting]", "order": 53168, "speaker": "Trevor"},
  {"season": 9, "episode": 8, "scene": 29, "line": "Don't move. And disarm now! [Oscar gets weapon away from others]", "order": 53169, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 8, "scene": 29, "line": "Okay, okay, okay, okay.", "order": 53170, "speaker": "Trevor"},
  {"season": 9, "episode": 8, "scene": 29, "line": "No! No, Oscar. He's a friend. He's a friend.", "order": 53171, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 8, "scene": 30, "line": "Okay, the client is Heymont Brake and Tire. They're family-owned, but don't let that take away from your edge!", "order": 53172, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 8, "scene": 30, "line": "Come on, Pam, I know you can fail. I see failure in you.", "order": 53173, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 8, "scene": 30, "line": "Remember, you're a scumbag, so you think scummy thoughts. Like this.", "order": 53174, "speaker": "Creed Bratton"},
  {"season": 9, "episode": 8, "scene": 30, "line": "[into phone] Hello, this is Pam Halpert. I'm calling from Dunder-Mifflin. Yes, your paper provider. And I just called to say... your mama is so fat, when she wears red, people yell, 'Hey, kool-aid.' Yeah, your mama's fat. This is Pam Halpert.", "order": 53175, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 8, "scene": 30, "line": "Did she buy it?", "order": 53176, "speaker": "Pete Miller"},
  {"season": 9, "episode": 8, "scene": 30, "line": "Basically I couldn't tell, but I think...", "order": 53177, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 8, "scene": 30, "line": "Were they angry?", "order": 53178, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 8, "scene": 30, "line": "I-- I thought they were confused at least...", "order": 53179, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 8, "scene": 30, "line": "Okay.", "order": 53180, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 8, "scene": 30, "line": "Dunder Mifflin. This is Erin. Yes, you can. Okay. I will make sure that goes on file. [hangs up] Ladies and gentlemen, we just lost a client!", "order": 53181, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 8, "scene": 30, "line": "[cheering]", "order": 53182, "speaker": "Everyone"},
  {"season": 9, "episode": 8, "scene": 30, "line": "Nice. Nice.", "order": 53183, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 8, "scene": 30, "line": "You did good. You did good.", "order": 53184, "speaker": "Creed Bratton"},
  {"season": 9, "episode": 8, "scene": 30, "line": "See ya later, Heymont.", "order": 53185, "speaker": "Pete Miller"},
  {"season": 9, "episode": 8, "scene": 31, "line": "No.", "order": 53186, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 8, "scene": 31, "line": "If you chase me, I will run so fast. If you catch me I will bite so hard. Got it? Good-bye, my friend.", "order": 53187, "speaker": "Trevor"},
  {"season": 9, "episode": 8, "scene": 31, "line": "What the hell, Dwight?", "order": 53188, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 8, "scene": 31, "line": "See ya later, Trevor.", "order": 53189, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 8, "scene": 31, "line": "you are incorrigible!", "order": 53190, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 8, "scene": 31, "line": "I just saved your life. You're welcome!", "order": 53191, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 8, "scene": 31, "line": "You hired someone to hit me with a pipe!", "order": 53192, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 8, "scene": 31, "line": "You deserved every bit of it! You made my husband gay.", "order": 53193, "speaker": "Angela Martin"},
  {"season": 9, "episode": 8, "scene": 31, "line": "What-- what I did was wrong, and I have to live with that every day. But your husband is... gay. He was gay when you married him!", "order": 53194, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 8, "scene": 31, "line": "No. No.", "order": 53195, "speaker": "Angela Martin"},
  {"season": 9, "episode": 8, "scene": 31, "line": "Angela, until you face that, you're gonna be confused and angry for all the wrong reasons. But if you want to blame me for the whole thing, go ahead -- I won't stop you. Hit me. you have my blessing. Hit me.", "order": 53196, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 8, "scene": 31, "line": "Well are you gonna let go of it? Because part of the blame is definitely on you.", "order": 53197, "speaker": "Angela Martin"},
  {"season": 9, "episode": 8, "scene": 31, "line": "Angela, it's a lead freaking pipe.", "order": 53198, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 8, "scene": 31, "line": "God! [kicks Oscar]", "order": 53199, "speaker": "Angela Martin"},
  {"season": 9, "episode": 8, "scene": 31, "line": "Aah!", "order": 53200, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 8, "scene": 31, "line": "You were supposed to be my friend.", "order": 53201, "speaker": "Angela Martin"},
  {"season": 9, "episode": 8, "scene": 31, "line": "I'm so sorry. Angela--", "order": 53202, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 8, "scene": 31, "line": "Oscar.", "order": 53203, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 8, "scene": 32, "line": "Our crowning complaint card comes to us thanks to Pamela Halpert...", "order": 53204, "speaker": "Pete Miller"},
  {"season": 9, "episode": 8, "scene": 32, "line": "[cheers and applause]", "order": 53205, "speaker": "Everyone"},
  {"season": 9, "episode": 8, "scene": 32, "line": "For insulting a client's recently deceased mother.", "order": 53206, "speaker": "Pete Miller"},
  {"season": 9, "episode": 8, "scene": 32, "line": "Yes!", "order": 53207, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 8, "scene": 32, "line": "I did not know that.", "order": 53208, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 8, "scene": 32, "line": "Well, a woman who struggled with obesity all her life.", "order": 53209, "speaker": "Pete Miller"},
  {"season": 9, "episode": 8, "scene": 32, "line": "I'm so sorry.", "order": 53210, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 8, "scene": 32, "line": "Wow.", "order": 53211, "speaker": "Meredith Palmer"},
  {"season": 9, "episode": 8, "scene": 32, "line": "yeah. That's-- that is terrible.", "order": 53212, "speaker": "Pete Miller"},
  {"season": 9, "episode": 8, "scene": 32, "line": "[cheers and applause as Pete puts last card on tower]", "order": 53213, "speaker": "Everyone"},
  {"season": 9, "episode": 8, "scene": 32, "line": "You did it.", "order": 53214, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 8, "scene": 32, "line": "Yeah!", "order": 53215, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 8, "scene": 33, "line": "I feel so stupid. I sit next to him every day.", "order": 53216, "speaker": "Angela Martin"},
  {"season": 9, "episode": 8, "scene": 33, "line": "You're not stupid. Jazz is stupid.", "order": 53217, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 8, "scene": 33, "line": "[crying] Jazz is stupid! I mean, just play the right notes!", "order": 53218, "speaker": "Angela Martin"},
  {"season": 9, "episode": 8, "scene": 33, "line": "I know. You're gonna be okay, Monkey.", "order": 53219, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 8, "scene": 33, "line": "I don't like your friend Trevor.", "order": 53220, "speaker": "Angela Martin"},
  {"season": 9, "episode": 8, "scene": 33, "line": "I don't like him either. And yet I really like him.", "order": 53221, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 8, "scene": 34, "line": "Well, we're here. Perfect. [covers sleeping Phyllis with his jacket]", "order": 53222, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 8, "scene": 34, "line": "We're gonna cover for you, ya know.", "order": 53223, "speaker": "Phyllis Vance"},
  {"season": 9, "episode": 8, "scene": 34, "line": "[chuckles]", "order": 53224, "speaker": "Stanley Hudson"},
  {"season": 9, "episode": 8, "scene": 34, "line": "Phyllis, what was that?Phyllis are you dreaming, or--", "order": 53225, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 8, "scene": 34, "line": "[laughing] I did enjoy grinding your beans, son.", "order": 53226, "speaker": "Stanley Hudson"},
  {"season": 9, "episode": 8, "scene": 34, "line": "[laughing] Yeah, we really did peel your grapes.", "order": 53227, "speaker": "Phyllis Vance"},
  {"season": 9, "episode": 8, "scene": 34, "line": "This is hilarious, but we're gonna stop with all--", "order": 53228, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 8, "scene": 34, "line": "Shuckin' your peas.", "order": 53229, "speaker": "Stanley Hudson"},
  {"season": 9, "episode": 8, "scene": 34, "line": "Shuckin' the peas. You should go back to the first part, though. You are gonna cover for me?", "order": 53230, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 8, "scene": 34, "line": "Yeah.", "order": 53231, "speaker": "Stanley Hudson"},
  {"season": 9, "episode": 8, "scene": 34, "line": "Of course we are, Jimmy. We love you guys.", "order": 53232, "speaker": "Phyllis Vance"},
  {"season": 9, "episode": 8, "scene": 34, "line": "Oh, my God, thank you. [hugging both] Thank you.", "order": 53233, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 8, "scene": 35, "line": "If you're an artist, you have to be okay with the idea that you can't please everybody all the time.", "order": 53234, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 8, "scene": 35, "line": "You paint very bad--", "order": 53235, "speaker": "Hidetoshi Hasagawa"},
  {"season": 9, "episode": 8, "scene": 35, "line": "Shut up, Hide! I mean, do you think Kevin cares what people think about him-- or Creed or Meredith? Oh my gosh, these are my role models now. You know what? I'm okay with that.", "order": 53236, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 8, "scene": 36, "line": "Where does gayness come from? And how is it transmitted?", "order": 53237, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 8, "scene": 36, "line": "That is-- that is a loaded question.", "order": 53238, "speaker": "Toby Flenderson"},
  {"season": 9, "episode": 8, "scene": 36, "line": "My pastor said it can come from breast feeding.", "order": 53239, "speaker": "Angela Martin"},
  {"season": 9, "episode": 8, "scene": 36, "line": "He said that?", "order": 53240, "speaker": "Toby Flenderson"},
  {"season": 9, "episode": 8, "scene": 36, "line": "Well, he didn't fight me hard on it.", "order": 53241, "speaker": "Angela Martin"},
  {"season": 9, "episode": 8, "scene": 36, "line": "I-- I don't know if there's truth to-- to, uh, to that.", "order": 53242, "speaker": "Toby Flenderson"},
  {"season": 9, "episode": 8, "scene": 36, "line": "What is it called when two men intertwine their penises like the snakes on the medic-alert bracelet?", "order": 53243, "speaker": "Angela Martin"},
  {"season": 9, "episode": 8, "scene": 36, "line": "Oh... uh...", "order": 53244, "speaker": "Toby Flenderson"},
  {"season": 9, "episode": 8, "scene": 36, "line": "Is it called red-vining?", "order": 53245, "speaker": "Angela Martin"},
  {"season": 9, "episode": 8, "scene": 36, "line": "Is it called red-vining?", "order": 53246, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 8, "scene": 36, "line": "I don't...", "order": 53247, "speaker": "Toby Flenderson"},
  {"season": 9, "episode": 8, "scene": 36, "line": "We heard it was called red-vining.", "order": 53248, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 8, "scene": 36, "line": "People red vine.", "order": 53249, "speaker": "Angela Martin"},
  {"season": 9, "episode": 8, "scene": 36, "line": "Where are gay mens' vaginas?", "order": 53250, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 8, "scene": 36, "line": "They don't have vaginas.", "order": 53251, "speaker": "Toby Flenderson"},
  {"season": 9, "episode": 8, "scene": 36, "line": "What?", "order": 53252, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 8, "scene": 36, "line": "No. They're just regular men.", "order": 53253, "speaker": "Toby Flenderson"},
  {"season": 9, "episode": 8, "scene": 36, "line": "When two gay men have sex, how do they know whose penis will open up to accept the other person's penis?", "order": 53254, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 8, "scene": 36, "line": "Uh... wow....", "order": 53255, "speaker": "Toby Flenderson"},
  {"season": 9, "episode": 9, "scene": 1, "line": "[Sighs] I'm having second thoughts about one of the shirts I packed. The blue one with the stripes.", "order": 53256, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 9, "scene": 1, "line": "I'm sure it's fine. it's a blue shirt.", "order": 53257, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 9, "scene": 1, "line": "Well, yeah, but I'm wondering if it's too blue. Like, am I making a statement with the blueness?", "order": 53258, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 9, "scene": 2, "line": "I am leaving early today for Philly, because tomorrow is the first day of my new job. So I figure I'd get in at 5:00, check into a hotel at about 6:00 so I can get a real good night of restless sleep and nervous puking.", "order": 53259, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 9, "scene": 3, "line": "Uh, excuse me. Trying to get work done. Some people don't care about Jim's new sports job in Phila-Whatever.", "order": 53260, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 9, "scene": 3, "line": "So you know my job has something to do with sports, but you don't know the end of the word 'Philadelphia'?", "order": 53261, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 9, "scene": 3, "line": "Philadelphia. From the greek phila meaning 'Love,' and adelph, meaning 'Adolf.' 'the city that loves Adolf.' Good luck with your new enterprise and don't wear the blue striped shirt. It makes your neck look like an old mop handle.", "order": 53262, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 9, "scene": 3, "line": "Oh...Hey, guys, the Christmas party is today. Merry Christmas everyone!", "order": 53263, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 9, "scene": 3, "line": "No.", "order": 53264, "speaker": "Angela Martin"},
  {"season": 9, "episode": 9, "scene": 3, "line": "Is It?", "order": 53265, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 9, "scene": 3, "line": "I mean, it says 'X-Mas party,' but I think we all know what that's code for.", "order": 53266, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 9, "scene": 3, "line": "So we're not going to have a Christmas party this year? Angela, how could you do this to us?", "order": 53267, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 9, "scene": 3, "line": "Oh, right, like I'm responsible 'cause I'm in charge of the party planning committee.", "order": 53268, "speaker": "Angela Martin"},
  {"season": 9, "episode": 9, "scene": 3, "line": "Well, you are the one in charge of it.", "order": 53269, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 9, "scene": 3, "line": "Don't blame me for something we all forgot.", "order": 53270, "speaker": "Angela Martin"},
  {"season": 9, "episode": 9, "scene": 3, "line": "Yeah, let's not blame her for something we all forgot.", "order": 53271, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 9, "scene": 4, "line": "I didn't realize how many of Angela's opinions I agreed with, until she tried to have my kneecap shattered for sleeping with her husband. She makes a lot of very sound points.", "order": 53272, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 9, "scene": 5, "line": "I knew the party was today, but nobody asked me to plan it, so I didn't. Hmm, funny how that works.", "order": 53273, "speaker": "Phyllis Vance"},
  {"season": 9, "episode": 9, "scene": 5, "line": "[Scoffs] We're out there sweating our balls off every day, bustin' our balls. We deserve a Christmas party!", "order": 53274, "speaker": "Meredith Palmer"},
  {"season": 9, "episode": 9, "scene": 5, "line": "Well, then, why don't we just get some liquor and those mini-Cupcakes?", "order": 53275, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 9, "scene": 5, "line": "Mini-Cupcakes? As in the mini version of regular cupcakes, which is already a mini version of cake? Honestly, where does it end with you people?", "order": 53276, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 9, "scene": 5, "line": "What about an authentic Pennsylvania Dutch Christmas? Drink some gluhwein, enjoy some hasenpfeffer. Enjoy Christmas with saint Nicolas' rural German companion, Belsnickel.", "order": 53277, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 9, "scene": 5, "line": "Yes! That, that, that! We're definitely doing that. Are we all in agreement?", "order": 53278, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 9, "scene": 5, "line": "No.", "order": 53279, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 9, "scene": 5, "line": "No!", "order": 53280, "speaker": "Angela Martin"},
  {"season": 9, "episode": 9, "scene": 5, "line": "Done, right?", "order": 53281, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 9, "scene": 5, "line": "No.", "order": 53282, "speaker": "Angela Martin"},
  {"season": 9, "episode": 9, "scene": 5, "line": "No. I want Tropical Christmas.", "order": 53283, "speaker": "Stanley Hudson"},
  {"season": 9, "episode": 9, "scene": 5, "line": "Topless Christmas.", "order": 53284, "speaker": "Meredith Palmer"},
  {"season": 9, "episode": 9, "scene": 5, "line": "Tapas swiss miss.", "order": 53285, "speaker": "Creed Bratton"},
  {"season": 9, "episode": 9, "scene": 6, "line": "Spanish tapas, and swiss miss hot cocoa--What's so hard to understand?", "order": 53286, "speaker": "Creed Bratton"},
  {"season": 9, "episode": 9, "scene": 7, "line": "Or, who was it that suggested the authentic Pennsylvania Dutch Christmas? I think it was someone really popular.", "order": 53287, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 9, "scene": 7, "line": "We already said no.", "order": 53288, "speaker": "Phyllis Vance"},
  {"season": 9, "episode": 9, "scene": 7, "line": "No, no, no.", "order": 53289, "speaker": "Angela Martin"},
  {"season": 9, "episode": 9, "scene": 7, "line": "Too weird.", "order": 53290, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 9, "scene": 8, "line": "[Holds up a picture] This is me and my family celebrating Pennsylvania Dutch Christmas in 1982 on the farm. There's me and my brother Jeb breaking the ceremonial pig rib. He doesn't come back for Christmas anymore. The sepia tint is from an app on my phone. This is the same photo, matrix style.", "order": 53291, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 9, "scene": 9, "line": "I'm not understanding the confusion. Am I the only one who wants to try hufflepuffs and schnauzerhosen, and meet this glenpickle guy?", "order": 53292, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 9, "scene": 9, "line": "[Blows a weak whistle] Party planning cimmittee, emgergency meeting. Now.", "order": 53293, "speaker": "Angela Martin"},
  {"season": 9, "episode": 9, "scene": 9, "line": "I'm on it.", "order": 53294, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 9, "scene": 9, "line": "Right.", "order": 53295, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 9, "scene": 10, "line": "I'm just hoping German terrorists don't take over this Christmas party. Make me go all John McClane on their asses.", "order": 53296, "speaker": "Pete Miller"},
  {"season": 9, "episode": 9, "scene": 10, "line": "Wait, German terrorists? That's oddly specific. And I think--I think you mean John McCain.", "order": 53297, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 9, "scene": 10, "line": "Die hard reference.", "order": 53298, "speaker": "Pete Miller"},
  {"season": 9, "episode": 9, "scene": 10, "line": "I haven't seen it.", "order": 53299, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 9, "scene": 10, "line": "You haven't seen die hard?", "order": 53300, "speaker": "Pete Miller"},
  {"season": 9, "episode": 9, "scene": 10, "line": "Mm-Mmm.", "order": 53301, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 9, "scene": 10, "line": "Why haven't you seen die hard?", "order": 53302, "speaker": "Pete Miller"},
  {"season": 9, "episode": 9, "scene": 10, "line": "I don't know, I just haven't.", "order": 53303, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 9, "scene": 10, "line": "Come on, you had to have at least seen some of it.", "order": 53304, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 9, "scene": 10, "line": "No.", "order": 53305, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 9, "scene": 10, "line": "now, i have a machine gun. Ho, ho, ho.'", "order": 53306, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 9, "scene": 10, "line": "Come out to the coast, we'll get together, have a few laughs.'", "order": 53307, "speaker": "Pete Miller"},
  {"season": 9, "episode": 9, "scene": 10, "line": "None of this makes any sense to me!", "order": 53308, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 9, "scene": 10, "line": "Oh, yeah, and when he takes the gun off his back, and he's like, 'Yippie-Ki-Yay, mother--'", "order": 53309, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 9, "scene": 10, "line": "Actually, he doesn't say that there. He says it earlier when he's on the radio with Hans.", "order": 53310, "speaker": "Pete Miller"},
  {"season": 9, "episode": 9, "scene": 10, "line": "Yes, you are right. Forgive me.", "order": 53311, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 9, "scene": 10, "line": "Oh, that's okay, bud.", "order": 53312, "speaker": "Pete Miller"},
  {"season": 9, "episode": 9, "scene": 10, "line": "Nope. [Kevin walks out the door into the kitchen]", "order": 53313, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 9, "scene": 10, "line": "Common mistake.", "order": 53314, "speaker": "Pete Miller"},
  {"season": 9, "episode": 9, "scene": 10, "line": "No, it's not.", "order": 53315, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 9, "scene": 10, "line": "[Points and Pete] Nerd! Do you know every line of the movie?", "order": 53316, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 9, "scene": 10, "line": "My brother dared me to memorize it, and I did it, and loved doing it.", "order": 53317, "speaker": "Pete Miller"},
  {"season": 9, "episode": 9, "scene": 10, "line": "Okay, let's hear it.", "order": 53318, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 9, "scene": 10, "line": "Hear what?", "order": 53319, "speaker": "Pete Miller"},
  {"season": 9, "episode": 9, "scene": 10, "line": "Die hard. Every line. Go.", "order": 53320, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 9, "scene": 10, "line": "You don't like flying, do you?'", "order": 53321, "speaker": "Pete Miller"},
  {"season": 9, "episode": 9, "scene": 10, "line": "Don't change the subject.", "order": 53322, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 9, "scene": 10, "line": "No, that's the--", "order": 53323, "speaker": "Pete Miller"},
  {"season": 9, "episode": 9, "scene": 10, "line": "Movie is starting.", "order": 53324, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 9, "scene": 11, "line": "[In Kitchen] We're thinking of collaborating with Mike Schmidt on some of his charity work.", "order": 53325, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 9, "scene": 11, "line": "I love Philly. Dirty town.", "order": 53326, "speaker": "Stanley Hudson"},
  {"season": 9, "episode": 9, "scene": 11, "line": "[Comes up behind Jim and puts his hands on Jim's shoulders] Ah-Ha-Ha! Philly! Exciting stuff, man.", "order": 53327, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 9, "scene": 11, "line": "Whoo!", "order": 53328, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 9, "scene": 11, "line": "So you sure you remembered everything for your trip?", "order": 53329, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 9, "scene": 11, "line": "Yeah, I think so.", "order": 53330, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 9, "scene": 11, "line": "Think that through for a sec.", "order": 53331, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 9, "scene": 12, "line": "Yeah, Jim's going to philly tomorrow. He said he was gonna take me with him, but that was two months ago. And I haven't heard anything since. I mean, he said that, right?", "order": 53332, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 9, "scene": 13, "line": "What else could you possibly be forgetting?", "order": 53333, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 9, "scene": 13, "line": "Uh--", "order": 53334, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 9, "scene": 13, "line": "Things? People?", "order": 53335, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 9, "scene": 13, "line": "You know who's not gonna get to work on some exciting new startup this year? George Howard Skub, a.k.a. the alleged Scranton strangler, because he's in prison [Jim and Daryl walk out of the kitchen] for something he may not have done.", "order": 53336, "speaker": "Toby Flenderson"},
  {"season": 9, "episode": 9, "scene": 13, "line": "I'm sorry, the--Uh, the Scranton who?", "order": 53337, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 9, "scene": 13, "line": "The Scranton strangler. George Howard Skub. Haven't I told you about the jury I was on a couple years ago?", "order": 53338, "speaker": "Toby Flenderson"},
  {"season": 9, "episode": 9, "scene": 13, "line": "[Gasps] Oh, what happened? I mean, if you can talk about it, obviously. Because if you can't, I mean, I understand.", "order": 53339, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 9, "scene": 13, "line": "I--I could--I could talk about it.", "order": 53340, "speaker": "Toby Flenderson"},
  {"season": 9, "episode": 9, "scene": 13, "line": "[Pats Nellie on the shoulder as he's walking by her] See you next Christmas.", "order": 53341, "speaker": "Stanley Hudson"},
  {"season": 9, "episode": 9, "scene": 13, "line": "I could, sure. Have a seat. I would start at the beginning, but I think I need to go farther back.", "order": 53342, "speaker": "Toby Flenderson"},
  {"season": 9, "episode": 9, "scene": 14, "line": "We're the party planning committee, and we did not get where we are by playing it safe. We got here by being risk takers. And, yeah, Dwight's party is gonna be terrible. Maybe. Maybe it's not. Maybe it's going to be great. And if it's great...I think we all know what that would mean to us.", "order": 53343, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 9, "scene": 14, "line": "Let's do it!", "order": 53344, "speaker": "Phyllis Vance"},
  {"season": 9, "episode": 9, "scene": 14, "line": "Yes! Phyllis!", "order": 53345, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 9, "scene": 14, "line": "No. I don't want my name attached to this party.", "order": 53346, "speaker": "Angela Martin"},
  {"season": 9, "episode": 9, "scene": 14, "line": "What does that even mean? Where would your name appear?", "order": 53347, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 9, "scene": 14, "line": "Please just take my name off of everything.", "order": 53348, "speaker": "Angela Martin"},
  {"season": 9, "episode": 9, "scene": 14, "line": "Just take her name off of everything.", "order": 53349, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 9, "scene": 14, "line": "[Clears her throat] The party planning committee, minus Angela, has decided that we're all going to do Dwight's Christmas.", "order": 53350, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 9, "scene": 14, "line": "Yes! Yeah! [High Fives Jim] Yeah! Okay! Yes! It's a Christmas Miracle! [High Fives Pam] Yeah! Whoo-Hoo!", "order": 53351, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 9, "scene": 14, "line": "Dwight, there is one rule that you need to take very seriously.", "order": 53352, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 9, "scene": 14, "line": "Uh-huh.", "order": 53353, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 9, "scene": 14, "line": "And that is...", "order": 53354, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 9, "scene": 14, "line": "Uh, Oh.", "order": 53355, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 9, "scene": 14, "line": "That there are no rules.", "order": 53356, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 9, "scene": 14, "line": "You have never been cooler.", "order": 53357, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 9, "scene": 15, "line": "Best Christmas ever.", "order": 53358, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 9, "scene": 15, "line": "You're welcome.", "order": 53359, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 9, "scene": 15, "line": "Thank you.", "order": 53360, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 9, "scene": 16, "line": "[Plays a trumpet] And Pennsylvania Dutch Christmas has officially begun.", "order": 53361, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 9, "scene": 16, "line": "Ugh. What is this stuff, lava?", "order": 53362, "speaker": "Meredith Palmer"},
  {"season": 9, "episode": 9, "scene": 16, "line": "That is gluhwein, otherwise known as glow-wine, also used to sterilize medical instruments. And, interesting factoid, this is the very spoon that guided my soft skull through the birth canal when I was born. Enjoy.", "order": 53363, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 9, "scene": 16, "line": "What is it? [Jim lifts the hog maw with a fork] Don't touch it.", "order": 53364, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 9, "scene": 16, "line": "[Comes running up at startles Pam] Somebody's found the hog maw.", "order": 53365, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 9, "scene": 16, "line": "Oh!", "order": 53366, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 9, "scene": 16, "line": "What? It's a Pennsylvania Dutch specialty. Hog maw. That's a beauty, isn't it? I don't even want to tell you what it's made of until after you try it.", "order": 53367, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 9, "scene": 16, "line": "Ugh. I'm not eating mystery meat.", "order": 53368, "speaker": "Stanley Hudson"},
  {"season": 9, "episode": 9, "scene": 16, "line": "It is stuffed pig stomach. And, after we finish it, we get to break the pig rib.", "order": 53369, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 9, "scene": 16, "line": "Can't wait.", "order": 53370, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 9, "scene": 16, "line": "Weird. Where are the sugar cookies? Where's the karaoke machine?", "order": 53371, "speaker": "Meredith Palmer"},
  {"season": 9, "episode": 9, "scene": 16, "line": "This is austere, Meredith. This is celebrating Christmas for all of the right reasons. The cookies and toys and sweets are mere distractions. I mean, most people don't even know that a candy cane represents a Shepherd's Crook, which I assure you does not taste like peppermint. It tastes like sheep feces.", "order": 53372, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 9, "scene": 16, "line": "How would anyone even know--", "order": 53373, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 9, "scene": 16, "line": "Have you ever tasted a Shepherd's Crook?", "order": 53374, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 9, "scene": 17, "line": "I don't know. Is it what you expected?", "order": 53375, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 9, "scene": 17, "line": "I feel like [Pam look's out the window and camera pans out in The Office]--Dwight is holding a candle and reading a poem.", "order": 53376, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 9, "scene": 17, "line": "So happy right now.", "order": 53377, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 9, "scene": 18, "line": "[Speaking German] Wait, what is that I hear? Is someone on the roof? How strange. [Blows out the candle] Excuse me. I have to run to my car to take a dump.", "order": 53378, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 9, "scene": 18, "line": "I wish my car had a bathroom.", "order": 53379, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 9, "scene": 19, "line": "Let Heinrich plant the detonators and Theo prepare the vault. After that--'", "order": 53380, "speaker": "Pete Miller"},
  {"season": 9, "episode": 9, "scene": 19, "line": "[On a computer, checking the script] Eh. That wasn't the line. I'm sorry, Pete.", "order": 53381, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 9, "scene": 19, "line": "That is transcribed by some fan. They make mistakes.", "order": 53382, "speaker": "Pete Miller"},
  {"season": 9, "episode": 9, "scene": 19, "line": "I don't know. This looks pretty legit. [Erin's cell phone chimes] Oh, my gosh. I just got an email from Andy. That means they hit land.", "order": 53383, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 9, "scene": 20, "line": "[Reading the email from Andy] 'What up, shorty? We got in last night, sold the boat, went out, got drunk, saw life of pi, got super depressed and introspective. Gonna hang out here a while. Maybe a couple of weeks. Figure this whole life thing out. Maybe see hobbit. Lates.'", "order": 53384, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 9, "scene": 21, "line": "Hey. Everything okay?", "order": 53385, "speaker": "Pete Miller"},
  {"season": 9, "episode": 9, "scene": 21, "line": "So what comes next?", "order": 53386, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 9, "scene": 21, "line": "Right, um, okay. So he says, uh, [Pete stands up] 'After we call the police, they'll waste hours trying to negotiate--'", "order": 53387, "speaker": "Pete Miller"},
  {"season": 9, "episode": 9, "scene": 21, "line": "We should just watch the movie. That makes much more sense.", "order": 53388, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 9, "scene": 21, "line": "Or we can just sit and talk, though.", "order": 53389, "speaker": "Pete Miller"},
  {"season": 9, "episode": 9, "scene": 21, "line": "No. No more talking. It's movie time.", "order": 53390, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 9, "scene": 21, "line": "I mean, are you sure...Um, yeah, I probably have a copy or two in my car.", "order": 53391, "speaker": "Pete Miller"},
  {"season": 9, "episode": 9, "scene": 21, "line": "Oh, great. Great! So we're watching die hard, now. Good! This is a good plan.", "order": 53392, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 9, "scene": 21, "line": "Okay.", "order": 53393, "speaker": "Pete Miller"},
  {"season": 9, "episode": 9, "scene": 21, "line": "[To Nellie in the kitchen] Here's the thing about moonlight. It's not sunlight.", "order": 53394, "speaker": "Toby Flenderson"},
  {"season": 9, "episode": 9, "scene": 22, "line": "[With his mouth full] I love this hog mama.", "order": 53395, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 9, "scene": 22, "line": "Dwight said it's 'Hog maw.'", "order": 53396, "speaker": "Phyllis Vance"},
  {"season": 9, "episode": 9, "scene": 22, "line": "[Chokes and spits it out] What is maw?!", "order": 53397, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 9, "scene": 22, "line": "It's the lining of the stomach of--", "order": 53398, "speaker": "Phyllis Vance"},
  {"season": 9, "episode": 9, "scene": 22, "line": "[Cackling] Ohh...[In a German Accent] Judgment is nigh, for the belsnickel ist I!", "order": 53399, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 9, "scene": 22, "line": "Yes, he is finally nigh.", "order": 53400, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 9, "scene": 22, "line": "I am nigh!", "order": 53401, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 9, "scene": 23, "line": "Every year, my grandfather would dress up as belsnickel at Christmas. He was...okay at it. I am great. You know how they say some people were born to be bad? Well, I was born to be belsnickel.", "order": 53402, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 9, "scene": 24, "line": "Ooh, belsnickel has traveled from distant lands to discover how all the boys and the girls have been behaving this last year. Whoo hoo HOO hoo hoo! [Runs over to Stanley and points at his stomach with a stick] Ohh...Too much strudel.", "order": 53403, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 9, "scene": 24, "line": "So he's kind of like santa, except dirty and worse.", "order": 53404, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 9, "scene": 24, "line": "No, much better--No one fears santa the way they fear belsnickel.", "order": 53405, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 9, "scene": 24, "line": "Wow! It's my favorite part of Christmas. The authority.", "order": 53406, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 9, "scene": 24, "line": "And the fear.", "order": 53407, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 9, "scene": 24, "line": "Yes, exactly!", "order": 53408, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 9, "scene": 24, "line": "Come on, Dwight, you're making this up.", "order": 53409, "speaker": "Phyllis Vance"},
  {"season": 9, "episode": 9, "scene": 24, "line": "No.", "order": 53410, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 9, "scene": 24, "line": "[Reading from his phone] This is a real thing. 'Belsnickel is a crotchety, fur-clad gift giver related to other companions of st. Nicholas in the folklore of southwestern Germany.'", "order": 53411, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 9, "scene": 24, "line": "Huh.", "order": 53412, "speaker": "Angela Martin"},
  {"season": 9, "episode": 9, "scene": 24, "line": "Wow.", "order": 53413, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 9, "scene": 24, "line": "Okay, great. Seriously, you guys? Now you believe in Dwight's traditions, when some democrat looks it up on wikipedia?", "order": 53414, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 9, "scene": 24, "line": "[Continues reading] 'His partner, zwarte piet, or black Peter, a slave boy, often portrayed in colorful pantaloons and blackface.'", "order": 53415, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 9, "scene": 24, "line": "Uh-Uh. No, Dwight. No.", "order": 53416, "speaker": "Stanley Hudson"},
  {"season": 9, "episode": 9, "scene": 24, "line": "Oh, come on. We don't blindly stick to every outmoded aspect of our traditions. Come on, get with the spirit of it, you guys. [Texts warehouse worker, Nate, to forget showing up as zwarte piet]", "order": 53417, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 9, "scene": 25, "line": "Okay, Karl was actually a ballet dancer in real life. Isn't that crazy?", "order": 53418, "speaker": "Pete Miller"},
  {"season": 9, "episode": 9, "scene": 25, "line": "Mm. [Chuckles]", "order": 53419, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 9, "scene": 26, "line": "[Talks drunk angry] Jim, that guy. [Scoffs] You gotta stick to your word! Like when you say something to a buddy, a real buddy...what are you gonna do, lie? To your buddy? [Sighs] It's awful.", "order": 53420, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 9, "scene": 27, "line": "Take a bowl and pass it down.", "order": 53421, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 9, "scene": 27, "line": "Thank you, Dwight. These are nice.", "order": 53422, "speaker": "Phyllis Vance"},
  {"season": 9, "episode": 9, "scene": 27, "line": "No, these are gift bowls. When you recieve a gift, it will go in the bowl, but the bowls must be returned at the end. They're a set. Now, hold your bowls forward. The belsnickel will decide if you are impish or admirable.", "order": 53423, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 9, "scene": 27, "line": "Oh, it's like naughty or nice.", "order": 53424, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 9, "scene": 27, "line": "No, impish or admirable.", "order": 53425, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 9, "scene": 27, "line": "Quick question--Do you just decide who gets what in the moment, or did you make a list?", "order": 53426, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 9, "scene": 27, "line": "I decided earlier.", "order": 53427, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 9, "scene": 27, "line": "Oh, nice. Did you check that list?", "order": 53428, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 9, "scene": 27, "line": "Of course I checked it.", "order": 53429, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 9, "scene": 27, "line": "But more than once? 'cause you could have made a mistake.", "order": 53430, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 9, "scene": 27, "line": "I checked it more than once.", "order": 53431, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 9, "scene": 27, "line": "Okay, so you made a list, you checked it twice, and now you're gonna find out who's--", "order": 53432, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 9, "scene": 27, "line": "Impish or admirable.", "order": 53433, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 9, "scene": 27, "line": "Damn.", "order": 53434, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 9, "scene": 27, "line": "Phyllis Vance! Cheer or fear? Belsnickel is here! I judge your year as...admirable. [Jim claps] There you are.", "order": 53435, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 9, "scene": 27, "line": "[Takes here gift out of her bowl] Oh. What are these?", "order": 53436, "speaker": "Phyllis Vance"},
  {"season": 9, "episode": 9, "scene": 27, "line": "It's a set of rubber gaskets for canning jars.", "order": 53437, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 9, "scene": 27, "line": "I'd rather have the bowl.", "order": 53438, "speaker": "Phyllis Vance"},
  {"season": 9, "episode": 9, "scene": 27, "line": "You can't have the bowl! Oscar Martinez, cheer or fear? Belsnickel is here! I judge your year [looks over at Angela, then back to Oscar]...as impish! [Smacks Oscar with a stick]", "order": 53439, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 9, "scene": 27, "line": "Ow! You hit people with that thing?", "order": 53440, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 9, "scene": 27, "line": "No, I'm carrying around the stick in order to look cool. For the kinder [puts a mouse trap in Pam's bowl]", "order": 53441, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 9, "scene": 27, "line": "Ooh.", "order": 53442, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 9, "scene": 27, "line": "[Holds the mouse trap up] Mouse trap.", "order": 53443, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 9, "scene": 28, "line": "In a head-to-head contest, people prefer belsnickel over santa every time. There aren't as many songs about him, but I attribute that more to rhyming difficulties. My brother and I wrote one once. it was about a fickle pickle salesman who would tickle you for a nickel.", "order": 53444, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 9, "scene": 29, "line": "Oh, you know what? I gotta go.", "order": 53445, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 9, "scene": 29, "line": "Stop giggling.", "order": 53446, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 9, "scene": 29, "line": "Oh, really? Already?", "order": 53447, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 9, "scene": 29, "line": "Yeah, it's all right.", "order": 53448, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 9, "scene": 29, "line": "It's a punishment. [Turns to Jim] Hey, where are you going?", "order": 53449, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 9, "scene": 29, "line": "I have to go to philly. But this was amazing. Okay.", "order": 53450, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 9, "scene": 29, "line": "But you work tomorrow.", "order": 53451, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 9, "scene": 29, "line": "Yeah, I know, I'd just like to settle in and get a good night's sleep.", "order": 53452, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 9, "scene": 29, "line": "But we were gonna break the pig rib.", "order": 53453, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 9, "scene": 29, "line": "Ooh.", "order": 53454, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 9, "scene": 29, "line": "Remember?", "order": 53455, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 9, "scene": 29, "line": "That's right.", "order": 53456, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 9, "scene": 29, "line": "No matter! Belsnickel cares not about this--off with you!", "order": 53457, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 9, "scene": 29, "line": "Perfect. Merry Christmas, everybody.", "order": 53458, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 9, "scene": 29, "line": "Wait, wait, wait, wait! Don't you want to know your present?", "order": 53459, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 9, "scene": 29, "line": "You know what? Yeah. Have at it.", "order": 53460, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 9, "scene": 29, "line": "Jim Halpert. Cheer or fear? Belsnickel is here! [Jim holds his hands like a bowl] I judge your year as impish. [Hits Jim with stick]", "order": 53461, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 9, "scene": 29, "line": "Ooh! Are you nuts?", "order": 53462, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 9, "scene": 29, "line": "[Hits Jim three more times] I judge you impish!", "order": 53463, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 9, "scene": 29, "line": "Ow! Ow! Okay, that is three, and you didn't hit anybody that hard.", "order": 53464, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 9, "scene": 29, "line": "They're not abandoning the party. [Hit's Jim again]", "order": 53465, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 9, "scene": 29, "line": "Just--Just--Hey!", "order": 53466, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 9, "scene": 29, "line": "[As he's walking out] That's enough, I'm done! Okay? Agh!", "order": 53467, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 9, "scene": 29, "line": "[Hits Jim some more] Impish! [Chases Jim out]", "order": 53468, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 9, "scene": 29, "line": "Aah! Ow!", "order": 53469, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 9, "scene": 29, "line": "Belsnickel, I gotta run out early too. [Leans up against the wall as Dwight comes back in]", "order": 53470, "speaker": "Meredith Palmer"},
  {"season": 9, "episode": 9, "scene": 30, "line": "[In the parking lot, holding his side, walking to the taxi, Pam follows] Oh...what was that? Now I'm gonna be all whipped for my first day at work. God. [To taxi driver] One second. Oh...", "order": 53471, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 9, "scene": 30, "line": "Well...this is it.", "order": 53472, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 9, "scene": 30, "line": "I'm probably never gonna see you again.", "order": 53473, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 9, "scene": 30, "line": "Shut up. I'm trying to be serious.", "order": 53474, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 9, "scene": 30, "line": "Okay, sorry, sorry.", "order": 53475, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 9, "scene": 30, "line": "I can't believe this is actually happening.", "order": 53476, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 9, "scene": 30, "line": "Listen, thank you so much for putting that all together. That was the perfect last Christmas party. All right, wish me luck.", "order": 53477, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 9, "scene": 30, "line": "Okay. [Jim and Pam hug] Good luck. You're gonna be great.", "order": 53478, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 9, "scene": 30, "line": "I'll call you when I get in.", "order": 53479, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 9, "scene": 30, "line": "Okay.", "order": 53480, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 9, "scene": 30, "line": "All right.", "order": 53481, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 9, "scene": 30, "line": "Love you.", "order": 53482, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 9, "scene": 30, "line": "Love you.", "order": 53483, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 9, "scene": 31, "line": "What's going on?", "order": 53484, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 9, "scene": 31, "line": "Party's over. You quit on Christmas, Christmas quits on you. [To camera] And guess what, kids. Belsnickel isn't real. It's me, Dwight! [Takes off hat and beard]", "order": 53485, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 9, "scene": 32, "line": "We found some old decorations in the warehouse. Oscar ran to the store for some food and drink, and I dipped into my stash of eggnog. I guess they needed me after all. It's like in it's a wonderful life when Jimmy Stewart realizes that all thos people at the building and loan were just jerks, and he was the real hero.", "order": 53486, "speaker": "Phyllis Vance"},
  {"season": 9, "episode": 9, "scene": 32, "line": "Kevin. Kevin! Aaah. Stop it!", "order": 53487, "speaker": "Angela Martin"},
  {"season": 9, "episode": 9, "scene": 33, "line": "Oh, it sounds like the party's starting up out there.", "order": 53488, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 9, "scene": 33, "line": "Yeah. So anyway, the entire jury wants me to vote guilty. But then I did a little research of my own, and forget everything you thought you knew about fingerprinting.", "order": 53489, "speaker": "Toby Flenderson"},
  {"season": 9, "episode": 9, "scene": 34, "line": "Meredith's a little cute. I'm just realizing. She got, like, a Emma Stone thing.", "order": 53490, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 9, "scene": 35, "line": "[At Meredtith] Boo!", "order": 53491, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 9, "scene": 35, "line": "For what it's worth, I liked your party better.", "order": 53492, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 9, "scene": 35, "line": "Everyone thought the food was gross, and that belsnickel was some darkly erotic freak.", "order": 53493, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 9, "scene": 35, "line": "I don't think anyone thought that.", "order": 53494, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 9, "scene": 35, "line": "Jim couldn't even stay till the end of the party.", "order": 53495, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 9, "scene": 35, "line": "Well, that didn't have anything to do with you.", "order": 53496, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 9, "scene": 35, "line": "I don't care. Guess how much I care on a scale of one to ten.", "order": 53497, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 9, "scene": 35, "line": "Zero.", "order": 53498, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 9, "scene": 35, "line": "Damn It!", "order": 53499, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 9, "scene": 36, "line": "I'm gonna tell Jim to go [Bleep] himself.", "order": 53500, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 9, "scene": 37, "line": "Do you think Andy would ever jump off an exploding building tied to a hose for me?", "order": 53501, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 9, "scene": 37, "line": "Yeah. Definitely. [Erin starts to cry] Hey, what's--Oh, no, come here. [Starts to put his arm around Erin] Hey, hey. Come on, huh?", "order": 53502, "speaker": "Pete Miller"},
  {"season": 9, "episode": 9, "scene": 37, "line": "[Sliding away from Pete] I'm still Andy's girlfriend.", "order": 53503, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 9, "scene": 37, "line": "Oh, yeah, of course. I know.", "order": 53504, "speaker": "Pete Miller"},
  {"season": 9, "episode": 9, "scene": 37, "line": "But you can leave your arm.", "order": 53505, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 9, "scene": 38, "line": "Dwight, want me to get you some eggnog?", "order": 53506, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 9, "scene": 38, "line": "No, thanks. I'll just have another dumatril. Jim taught me this really cool way to take it. You crush it into a powder, and you snort it up your butt.", "order": 53507, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 9, "scene": 38, "line": "[Walks back into The Office] Yep, I did say that.", "order": 53508, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 9, "scene": 38, "line": "Jim!", "order": 53509, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 9, "scene": 38, "line": "What's going on? Where's the belsnickel?", "order": 53510, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 9, "scene": 38, "line": "Oh--Oh my god!", "order": 53511, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 9, "scene": 38, "line": "What are you doing? Last time I saw you, you were whipping me out of the building.", "order": 53512, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 9, "scene": 38, "line": "[Holds his finger to Jim's lips] Shh. Let's not speak of that. The Pig Rib! We could totally break the pig rib! I'm gonna dig it out of the trash! [High fives Jim]", "order": 53513, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 9, "scene": 38, "line": "What happened? Did you miss your bus?", "order": 53514, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 9, "scene": 38, "line": "No. I just missed my wife.[Gives Pam a hug and a kiss]", "order": 53515, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 9, "scene": 38, "line": "[Comes back in, holding up the pig rib] I found it!", "order": 53516, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 9, "scene": 38, "line": "And I found out that there's a bus at 5:00 a.m.", "order": 53517, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 9, "scene": 39, "line": "Oh! [Pig rib breaks and Jim wins, and stuff splatters people. Everyone groans] Damn It! Jim got the bigger half.", "order": 53518, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 9, "scene": 39, "line": "[To himself] Back for more, huh? [Gets up and walks towards Jim]", "order": 53519, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 9, "scene": 39, "line": "Ooh, nog it. Let me get some nog. [Everyone talking Phyllis pours Jim some eggnog, as Daryl walks in] Thanks, Phyllis. Where's Andy?", "order": 53520, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 9, "scene": 39, "line": "You!", "order": 53521, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 9, "scene": 39, "line": "Oh, hey, man. [Daryl walks over to Jim, angrily and stares at Jim] Oh, you know what? Before I forget again, I talked to the guys about hiring you. They're gonna bring you in for an interview.", "order": 53522, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 9, "scene": 39, "line": "[Looking at Jim confused] That's great.", "order": 53523, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 9, "scene": 39, "line": "Right?", "order": 53524, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 9, "scene": 39, "line": "Thanks, man.", "order": 53525, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 9, "scene": 39, "line": "Hey, of course.", "order": 53526, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 9, "scene": 39, "line": "I shall come by at your convenience.", "order": 53527, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 9, "scene": 39, "line": "Thank you, sir. [Tips his glass to Daryl]", "order": 53528, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 9, "scene": 39, "line": "[Claps his hands once, spins around] Whoo!", "order": 53529, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 9, "scene": 39, "line": "Go get 'em.", "order": 53530, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 9, "scene": 39, "line": "[Falls backwards onto the food table] Oh! Whoa!", "order": 53531, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 9, "scene": 39, "line": "Very impish.", "order": 53532, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 9, "scene": 40, "line": "Two dimes, seven nickels--Well, okay, no, that doesn't add up. It was one quarter and--", "order": 53533, "speaker": "Toby Flenderson"},
  {"season": 9, "episode": 9, "scene": 40, "line": "Shh. [Puts her finger to Toby's lips]", "order": 53534, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 9, "scene": 40, "line": "What? I was just explaining--", "order": 53535, "speaker": "Toby Flenderson"},
  {"season": 9, "episode": 9, "scene": 40, "line": "Shh.", "order": 53536, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 9, "scene": 40, "line": "Why wouldn't it--", "order": 53537, "speaker": "Toby Flenderson"},
  {"season": 9, "episode": 9, "scene": 40, "line": "[Put her finger to his lips again] Shh.", "order": 53538, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 9, "scene": 40, "line": "But why?", "order": 53539, "speaker": "Toby Flenderson"},
  {"season": 9, "episode": 9, "scene": 40, "line": "No...more...talking.", "order": 53540, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 9, "scene": 40, "line": "Are you gonna kiss me?", "order": 53541, "speaker": "Toby Flenderson"},
  {"season": 9, "episode": 9, "scene": 40, "line": "Yes. [Kisses Toby]", "order": 53542, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 10, "scene": 1, "line": "To me, the Sixers are a five seed tops.", "order": 53543, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 10, "scene": 1, "line": "I think they're going to the conference finals.", "order": 53544, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 10, "scene": 1, "line": "No.", "order": 53545, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 10, "scene": 1, "line": "Bynum, obvious monster and the three point shooting, don't even-... [Jim bites his lip]", "order": 53546, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 10, "scene": 2, "line": "I bit my lip at lunch today.", "order": 53547, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 10, "scene": 3, "line": "Sucks. Anyway, I just don't see it. The Knicks are tough.The Nets are tough.", "order": 53548, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 10, "scene": 3, "line": "Oh, the Nets are super tough. [Jim in pain from his lip]", "order": 53549, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 10, "scene": 4, "line": "Now I have this big bump in my lip that hurts. I hate it.", "order": 53550, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 10, "scene": 5, "line": "The Hawks are terrible. They're always terrible.", "order": 53551, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 10, "scene": 5, "line": "Okay, you need to think about it before you come with the... [Jim bites his lip again and pounds on table]", "order": 53552, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 10, "scene": 6, "line": "Whatever. It's not a big deal. You know I always tell my three year old, if this is the worst thing that's gonna happen...[Jim bites his lip again] You gotta be kidding me! Ok! Pam! That's it, I'm going home!", "order": 53553, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 10, "scene": 7, "line": "Good morning, Meredith.", "order": 53554, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 10, "scene": 7, "line": "What?", "order": 53555, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 10, "scene": 7, "line": "Oh, sorry, Pam. Yikes.", "order": 53556, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 10, "scene": 8, "line": "Jim's been spending a few days a week in Philly and I'm not gonna lie, it's been challenging. Yesterday, things took a turn for the worse. I found out Cece has lice. So I was up all night disinfecting every sheet, towel, toy, item of clothing in the entire house. I'm exhausted. But don't tell Jim. He has a huge meeting today, under a lot of pressure and he's doing it all for the family.", "order": 53557, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 10, "scene": 9, "line": "I am meeting Dr. J today, otherwise known as Julius Irving, famed 76er and my own personal hero. But i have to be careful not to rub it in Pam's face because let's be honest, how would I feel if I was at home stuck with the kids while she was go carting with John Stamos.", "order": 53558, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 10, "scene": 10, "line": "[talking on phone] You sound tired. Everything ok?", "order": 53559, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 10, "scene": 10, "line": "Great. Everything's great. Hey are you nervous about your big meeting?", "order": 53560, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 10, "scene": 10, "line": "Uhh, a little bit. It's a lot of pressure, you know, but that's what I signed up for right?", "order": 53561, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 10, "scene": 10, "line": "Jim, limo's here.", "order": 53562, "speaker": "Worker"},
  {"season": 9, "episode": 10, "scene": 10, "line": "Uh, you know what, I gotta hop off because my, uh, taxi is here to take me to the meeting.", "order": 53563, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 10, "scene": 10, "line": "Oh, call me later!", "order": 53564, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 10, "scene": 10, "line": "Ok", "order": 53565, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 10, "scene": 10, "line": "Good luck!", "order": 53566, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 10, "scene": 10, "line": "Thanks!", "order": 53567, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 10, "scene": 10, "line": "Love you.", "order": 53568, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 10, "scene": 10, "line": "Love you too. Bye.", "order": 53569, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 10, "scene": 11, "line": "How you doing?", "order": 53570, "speaker": "Val Johnson"},
  {"season": 9, "episode": 10, "scene": 11, "line": "Alright. I mean, it's what you want, so...", "order": 53571, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 10, "scene": 11, "line": "It's how it has to be.", "order": 53572, "speaker": "Val Johnson"},
  {"season": 9, "episode": 10, "scene": 11, "line": "I know, I know. It's just hard.", "order": 53573, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 10, "scene": 12, "line": "Me and Val were going nowhere and if I'm gonna be working in Philly, I'm gonna need my freedom. So, I convinced Val to breakup with me. Here's how you do it. You say, 'What are you gonna do, breakup with me?' Like it's a joke, and then you gain a lot of weight.", "order": 53574, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 10, "scene": 13, "line": "I'll never be sorry, not for a moment of it.", "order": 53575, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 10, "scene": 13, "line": "Me neither. Come here. [she hugs Darryl, and Darryl smiles behind her back]", "order": 53576, "speaker": "Val Johnson"},
  {"season": 9, "episode": 10, "scene": 14, "line": "Hey Meredith, I need your supplier requests today.", "order": 53577, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 10, "scene": 14, "line": "Jeez H. in the morning. Will you stop your nagging already? No wonder Jim left you.", "order": 53578, "speaker": "Meredith Palmer"},
  {"season": 9, "episode": 10, "scene": 14, "line": "He didn't leave me. He just went part time. [Meredith scratches her head vigorously] Can you just fill out the form please?", "order": 53579, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 10, "scene": 14, "line": "Yes.", "order": 53580, "speaker": "Meredith Palmer"},
  {"season": 9, "episode": 10, "scene": 15, "line": "I'm sure she's just confused. People scratch their heads when they're confused. Not always like an ape, the way Meredith just did, but it happens.", "order": 53581, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 10, "scene": 16, "line": "Hurry up already.", "order": 53582, "speaker": "Meredith Palmer"},
  {"season": 9, "episode": 10, "scene": 16, "line": "[checking Meredith's head] Trust me, I know what I'm doing. Between the foster homes and the orphanage, I had lice 22 times.", "order": 53583, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 10, "scene": 16, "line": "Holy wow, that's a big one. Alright, pencils down everyone, we got lice!", "order": 53584, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 10, "scene": 16, "line": "Oh God, Meredith, lice? Did you not sign a pledge to shower?", "order": 53585, "speaker": "Angela Martin"},
  {"season": 9, "episode": 10, "scene": 16, "line": "What? Lice? Oh my god. Lock the doors, we are on full quarantine.", "order": 53586, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 10, "scene": 16, "line": "Dwight, relax. It's just lice. Maybe, possibly.", "order": 53587, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 10, "scene": 16, "line": "Just lice Pam?", "order": 53588, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 10, "scene": 17, "line": "Of all of the vermin in God's great green kingdom, lice are the ones I detest the most. My first day of school, I had lice, and no one would play with me. For 15 years, they called me freak and four eyes and sci-fi nerd and girl puncher. All because I had lice when I was 7.", "order": 53589, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 10, "scene": 18, "line": "Make way, nope, inspect me.", "order": 53590, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 10, "scene": 18, "line": "Oh, this is the cleanest scalp I've ever seen. You are all clear.", "order": 53591, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 10, "scene": 18, "line": "Should be. I use lice shampoo every morning. [Dwight starts spraying everyone with disinfectant]", "order": 53592, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 10, "scene": 18, "line": "Stop that!", "order": 53593, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 10, "scene": 18, "line": "Dwight!", "order": 53594, "speaker": "Angela Martin"},
  {"season": 9, "episode": 10, "scene": 18, "line": "Next!", "order": 53595, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 10, "scene": 18, "line": "[getting her head checked] Geez Meredith, you know, this is an office not one of your bean bag orgies.", "order": 53596, "speaker": "Angela Martin"},
  {"season": 9, "episode": 10, "scene": 18, "line": "Alright, let's give her a break. We don't know for sure this is Meredith's fault.", "order": 53597, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 10, "scene": 18, "line": "Pam, really? Come on.", "order": 53598, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 10, "scene": 18, "line": "Hey, what do you want? I know who I am. Nobody's taken Meredith Palmer to the opera to meet the queen.", "order": 53599, "speaker": "Meredith Palmer"},
  {"season": 9, "episode": 10, "scene": 18, "line": "Lice. More lice. [Angela shrieks in disgust] Angela has lice.", "order": 53600, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 10, "scene": 18, "line": "Ew! Oh.", "order": 53601, "speaker": "Angela Martin"},
  {"season": 9, "episode": 10, "scene": 18, "line": "[checking Stanley's head] Ooh yabber, lice!", "order": 53602, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 10, "scene": 18, "line": "[checking Pam's head] Yikers, lice.", "order": 53603, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 10, "scene": 18, "line": "No! How? I'm so clean!", "order": 53604, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 10, "scene": 18, "line": "[checking Oscar's head] Oh yeah, big time lice.", "order": 53605, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 10, "scene": 19, "line": "I wash my hands at least six times a day. Toilet seat covers? Yes, thank you, even when I pee. Apparently, none of that is protection enough. Not when it comes to Meredith.", "order": 53606, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 10, "scene": 20, "line": "What are you wearing?", "order": 53607, "speaker": "Phyllis Vance"},
  {"season": 9, "episode": 10, "scene": 20, "line": "It's a Hazmat suit. That stands for hazaderous materials men's suit wearing. If you rent more than four times a year, it just makes sense to buy. Is there anyone else here that is lice free? [Phyllis, Kevin, Darryl. and Nellie raise their hands] Excellent. Do you have your own hazmat suits?", "order": 53608, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 10, "scene": 20, "line": "No.", "order": 53609, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 10, "scene": 20, "line": "No.", "order": 53610, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 10, "scene": 20, "line": "Renters. Ok, I'm gonna need you to gather your belongings, retreat to the warehouse, conduct your business there until the infected have been deloused. Let's get going. I'm gonna stay here and fight. If you don't hear from me by lunch, call me. I might want lunch.", "order": 53611, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 10, "scene": 21, "line": "Alright, we're going down to my warehouse, that means we're playing by my rules. No messing with the baler and be cool in front of me and Val. We just broke up.", "order": 53612, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 10, "scene": 21, "line": "You got dumped?", "order": 53613, "speaker": "Phyllis Vance"},
  {"season": 9, "episode": 10, "scene": 21, "line": "Yeah, she ended things.", "order": 53614, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 10, "scene": 21, "line": "Breakups are the worst. The only thing that got me through mine are large amounts of shepard's pie and Brandy. The singer, not the drink.", "order": 53615, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 10, "scene": 21, "line": "Here man. [Kevin hands Daryll some chocolate] You need that more than me.", "order": 53616, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 10, "scene": 21, "line": "Thanks, man.", "order": 53617, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 10, "scene": 21, "line": "Yeah [kisses Darryl on the cheek]", "order": 53618, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 10, "scene": 22, "line": "Guys, I think we should all ease up on Meredith. This has got to be hard for her.", "order": 53619, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 10, "scene": 22, "line": "Oh, no no no no no. I have not yet begun to shame.", "order": 53620, "speaker": "Angela Martin"},
  {"season": 9, "episode": 10, "scene": 22, "line": "That's it. I'm getting my stuff. I'm leaving for the day.", "order": 53621, "speaker": "Stanley Hudson"},
  {"season": 9, "episode": 10, "scene": 22, "line": "No! Stanley! If you leave now then you'll get it in your car and then you'll get it in your house! [Erin jumps on Stanley's back]", "order": 53622, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 10, "scene": 22, "line": "Get off me!", "order": 53623, "speaker": "Stanley Hudson"},
  {"season": 9, "episode": 10, "scene": 22, "line": "I'm trying to save you from yourself!", "order": 53624, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 10, "scene": 22, "line": "Alright, I'll stay! There's a pencil broken in my rolls.", "order": 53625, "speaker": "Stanley Hudson"},
  {"season": 9, "episode": 10, "scene": 23, "line": "Maybe it was Meredith. Maybe she brought in lice that are totally different than the lice that i got from Cece. So let's not jump to the simplest conclusion that she got her lice from me. That is how wars get started. Fine, I'll tell her it was me.", "order": 53626, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 10, "scene": 24, "line": "Oh man, that's fresh squeezed. And, uh are all the snacks complimentary?", "order": 53627, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 10, "scene": 24, "line": "Yeah, take some home if you want.", "order": 53628, "speaker": "Limo Driver"},
  {"season": 9, "episode": 10, "scene": 24, "line": "Oh no, no, no. That's ok. [Jim shoves some snacks in his pocket as he gets out of limo] Um I'm sorry, is this the conference center?", "order": 53629, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 10, "scene": 24, "line": "No, Mr. Irving called. Said he'd rather meet you at his private court.", "order": 53630, "speaker": "Limo Driver"},
  {"season": 9, "episode": 10, "scene": 24, "line": "You gotta be kidding me.", "order": 53631, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 10, "scene": 25, "line": "Hey, Meredith I need to tell you something.", "order": 53632, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 10, "scene": 25, "line": "[shaving her own head] Yes, Pam what do you want?", "order": 53633, "speaker": "Meredith Palmer"},
  {"season": 9, "episode": 10, "scene": 25, "line": "Oh my God, Meredith what are you doing!?", "order": 53634, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 10, "scene": 25, "line": "Baking a cake, what does it look like I'm doing? Getting rid of the lice.", "order": 53635, "speaker": "Meredith Palmer"},
  {"season": 9, "episode": 10, "scene": 25, "line": "Oh stop! I am so sorry!", "order": 53636, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 10, "scene": 25, "line": "Oh, can you hold that thought? That's my wax. You know, I think these critters migrated from down south. What were you saying?", "order": 53637, "speaker": "Meredith Palmer"},
  {"season": 9, "episode": 10, "scene": 25, "line": "Nothing.", "order": 53638, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 10, "scene": 26, "line": "I am going to tell her, but now is clearly not the time. I will buy her a wig, we'll have a few laughs. There's a right way to do this.", "order": 53639, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 10, "scene": 27, "line": "Scalp leeches, skull vampires, follicle gypsies, hair lawyers. One thing is clear, it's kill or be killed!", "order": 53640, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 10, "scene": 27, "line": "No, no. It's more of a nuisance really. It's not that big a deal. So, I collected your hats and your coats...", "order": 53641, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 10, "scene": 27, "line": "To be burned!", "order": 53642, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 10, "scene": 27, "line": "To be washed.", "order": 53643, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 10, "scene": 27, "line": "What do we do about our heads?", "order": 53644, "speaker": "Angela Martin"},
  {"season": 9, "episode": 10, "scene": 27, "line": "I'm not gonna lie. Lye!", "order": 53645, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 10, "scene": 27, "line": "No, all we need is mayonnaise.", "order": 53646, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 10, "scene": 27, "line": "Excuse me?", "order": 53647, "speaker": "Angela Martin"},
  {"season": 9, "episode": 10, "scene": 27, "line": "She's right, it works. I would imagine.", "order": 53648, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 10, "scene": 27, "line": "Yes, it helps to suffrocate the little buddies and it's a really healthy alternative to the chemicals found in most lice shampoos.", "order": 53649, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 10, "scene": 27, "line": "I can appreciate that. I also only eat local, organic produce.", "order": 53650, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 10, "scene": 27, "line": "[walks in conference room] Shaboom! How do you like me now!?", "order": 53651, "speaker": "Meredith Palmer"},
  {"season": 9, "episode": 10, "scene": 27, "line": "Oh.", "order": 53652, "speaker": "Angela Martin"},
  {"season": 9, "episode": 10, "scene": 27, "line": "Wow.", "order": 53653, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 10, "scene": 27, "line": "Take a picture. It will last longer.", "order": 53654, "speaker": "Meredith Palmer"},
  {"season": 9, "episode": 10, "scene": 27, "line": "We don't want it to last longer. It's horrible.", "order": 53655, "speaker": "Angela Martin"},
  {"season": 9, "episode": 10, "scene": 27, "line": "Bald people make me sick.", "order": 53656, "speaker": "Creed Bratton"},
  {"season": 9, "episode": 10, "scene": 27, "line": "Yeah, yeah, everybody pile on Meredith. But I'm the only one with the balls to show them lice who's boss.", "order": 53657, "speaker": "Meredith Palmer"},
  {"season": 9, "episode": 10, "scene": 27, "line": "Ok, nobody panic. If everybody just follows my instructions, then nobody else needs to end up bald. Not that it looks bad, Meredith. It looks...It looks awesome. You look like a baby who suddenly aged 50 years. A cute baby, but something sucked the life force out of it. I'm so sorry. We need mayonnaise! We need it now.", "order": 53658, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 10, "scene": 27, "line": "Oh! Uh, uh I'll go.", "order": 53659, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 10, "scene": 27, "line": "Okay. Oh, thank you.", "order": 53660, "speaker": "Angela Martin"},
  {"season": 9, "episode": 10, "scene": 27, "line": "You're a saint, Pam.", "order": 53661, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 10, "scene": 27, "line": "No, no, it's not a big deal, please. No. Um, Meredith can I get you something special while I'm at the store, candy, or...or one of those stylish turbans?", "order": 53662, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 10, "scene": 27, "line": "Thanks Pam! I'll take a pack of Nicorette gum and a pack of Kools.", "order": 53663, "speaker": "Meredith Palmer"},
  {"season": 9, "episode": 10, "scene": 27, "line": "Okay.", "order": 53664, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 10, "scene": 27, "line": "Let's talk pubes, people.", "order": 53665, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 10, "scene": 28, "line": "Hey, Darryl.", "order": 53666, "speaker": "Val Johnson"},
  {"season": 9, "episode": 10, "scene": 28, "line": "Oh, hey Val.", "order": 53667, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 10, "scene": 28, "line": "Oh, that was tragic, Oh, that was like a car crash. I couldn't look away. You two clearly still have feelings for each other.", "order": 53668, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 10, "scene": 28, "line": "Yeah.", "order": 53669, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 10, "scene": 28, "line": "What's gonna make you feel better, big guy?", "order": 53670, "speaker": "Phyllis Vance"},
  {"season": 9, "episode": 10, "scene": 28, "line": "When I was a kid, my grandmother used to make me chocolate cake when I wasn't feeling good. She's not around to do that anymore. I'm sorry, I just need a moment to myself just to clear my head.", "order": 53671, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 10, "scene": 28, "line": "Aw, poor Darryl. I can't bear to see him suffer like this.", "order": 53672, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 10, "scene": 28, "line": "I think he needs some hugs, and maybe some chocolate cake.", "order": 53673, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 10, "scene": 28, "line": "Mmm, this won't help him, it's a muffin, not cake.", "order": 53674, "speaker": "Phyllis Vance"},
  {"season": 9, "episode": 10, "scene": 28, "line": "Listen, let's try and get Darryl the one thing in the world he clearly wants and needs the most.", "order": 53675, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 10, "scene": 28, "line": "An Escalade.", "order": 53676, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 10, "scene": 28, "line": "Or what's her name.", "order": 53677, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 10, "scene": 28, "line": "Val.", "order": 53678, "speaker": "Phyllis Vance"},
  {"season": 9, "episode": 10, "scene": 28, "line": "Let's get Darryl Val.", "order": 53679, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 10, "scene": 29, "line": "I hope you don't mind me bringing you out here. I can't get my knees under a desk.", "order": 53680, "speaker": "Julius Irving"},
  {"season": 9, "episode": 10, "scene": 29, "line": "Are you kidding me? I can literally scalp tickets to this.", "order": 53681, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 10, "scene": 29, "line": "Well, do you think you can sink one from deep?", "order": 53682, "speaker": "Julius"},
  {"season": 9, "episode": 10, "scene": 29, "line": "Listen, I don't mean to intimidate you, but I did play a little high school ball.", "order": 53683, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 10, "scene": 29, "line": "Okay. [Jim shoots the basket and it goes in] Wow, Halpert's got game.", "order": 53684, "speaker": "Julius"},
  {"season": 9, "episode": 10, "scene": 29, "line": "Alright. [Jim's phone rings] Oh, sorry. I'm so sorry. It's my wife, can I take this?", "order": 53685, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 10, "scene": 29, "line": "It's your wife? You better take it.", "order": 53686, "speaker": "Julius"},
  {"season": 9, "episode": 10, "scene": 29, "line": "Right! [Jim laughs as he answers the phone] Hey, what's up? Everything ok?", "order": 53687, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 10, "scene": 29, "line": "Oh, no everything's great! Um, I was just calling to see how the meeting went.", "order": 53688, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 10, "scene": 29, "line": "It's still happening right now actually, so uh...", "order": 53689, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 10, "scene": 29, "line": "He's really making you work for it, huh?", "order": 53690, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 10, "scene": 29, "line": "Yes, it is very stressful. You sure everything's okay?", "order": 53691, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 10, "scene": 29, "line": "Great. I am killing it over here. [drops mayonnaise on the ground] Jim, I gotta go.", "order": 53692, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 10, "scene": 29, "line": "Okay, bye. [Jim turns back to Julius] So sorry about that.", "order": 53693, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 10, "scene": 29, "line": "No problem. Hey Jim, what size do you wear, man? I got a pair of japanese Nikes with your name on them. What do you think?", "order": 53694, "speaker": "Julius"},
  {"season": 9, "episode": 10, "scene": 29, "line": "I love it.", "order": 53695, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 10, "scene": 30, "line": "[handing out mayonnaise jars] Once it's all over your head, just leavr it there for four hours. That will be enough time for the lice to fall asleep, suffrocate, and then pass away.", "order": 53696, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 10, "scene": 30, "line": "Oh sure, and when you're ready to get serious, come to me to get it done right. [Dwight holds up a pair of scissors]", "order": 53697, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 10, "scene": 30, "line": "Put those away before you hurt yourself.", "order": 53698, "speaker": "Stanley Hudson"},
  {"season": 9, "episode": 10, "scene": 30, "line": "[swings the scissors around and puts them into his pocket, putting a hole in his Hazmat suit] Oh God, oh no. No. No. No! No! No! No! No!", "order": 53699, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 10, "scene": 30, "line": "Okay, it's easier with a buddy, so everybody pair up.", "order": 53700, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 10, "scene": 30, "line": "Oscar, do you want to be my partner?", "order": 53701, "speaker": "Angela Martin"},
  {"season": 9, "episode": 10, "scene": 30, "line": "Yeah, yeah, sure. I'd love to..", "order": 53702, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 10, "scene": 30, "line": "Hey, uh, mayo buddy, five dollar tip, you unclog my ears. Okay?", "order": 53703, "speaker": "Creed Bratton"},
  {"season": 9, "episode": 10, "scene": 30, "line": "Oh, Creed, I'm so sorry. I'm...I'm already partners with Pete, right Pete?", "order": 53704, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 10, "scene": 30, "line": "Uh, yeah. We promised each other if we ever got lice, we'd buddy up. Sorry, man.", "order": 53705, "speaker": "Pete Miller"},
  {"season": 9, "episode": 10, "scene": 30, "line": "Hey, Creed, wanna be my buddy?", "order": 53706, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 10, "scene": 30, "line": "Oh God. Stuck with the weirdo.", "order": 53707, "speaker": "Creed Bratton"},
  {"season": 9, "episode": 10, "scene": 31, "line": "[Angela is slapping mayo on Oscar's head] Angela.", "order": 53708, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 10, "scene": 31, "line": "You don't want bugs. You know? Who knows where those bugs will end up?", "order": 53709, "speaker": "Angela Martin"},
  {"season": 9, "episode": 10, "scene": 32, "line": "[putting mayo on Stanley's head] You're getting a bargain. I ain't got no hair no more.", "order": 53710, "speaker": "Meredith Palmer"},
  {"season": 9, "episode": 10, "scene": 33, "line": "[putting mayo on Creed's head] Creed, I'm all done. My turn.", "order": 53711, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 10, "scene": 33, "line": "I'm sorry, Pam. It looks a little messy.", "order": 53712, "speaker": "Creed Bratton"},
  {"season": 9, "episode": 10, "scene": 33, "line": "Well, wait.", "order": 53713, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 10, "scene": 34, "line": "[putting mayo on Erin's head] How's that?", "order": 53714, "speaker": "Pete Miller"},
  {"season": 9, "episode": 10, "scene": 34, "line": "I feel it working. [Erin and Pete playing around with the mayo] Eiffel tower!", "order": 53715, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 10, "scene": 35, "line": "Can I help you?", "order": 53716, "speaker": "Val Johnson"},
  {"season": 9, "episode": 10, "scene": 35, "line": "Well, we don't want to pry but we heard you broke up with Darryl.", "order": 53717, "speaker": "Phyllis Vance"},
  {"season": 9, "episode": 10, "scene": 35, "line": "Yeah, that's true.", "order": 53718, "speaker": "Val Johnson"},
  {"season": 9, "episode": 10, "scene": 35, "line": "We think you made a big, big mistake.", "order": 53719, "speaker": "Phyllis Vance"},
  {"season": 9, "episode": 10, "scene": 35, "line": "Big mistake.", "order": 53720, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 10, "scene": 35, "line": "Quite enormous.", "order": 53721, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 10, "scene": 35, "line": "He's a real catch and you should take him back.", "order": 53722, "speaker": "Phyllis Vance"},
  {"season": 9, "episode": 10, "scene": 35, "line": "Ok, thank you. Is that all?", "order": 53723, "speaker": "Val Johnson"},
  {"season": 9, "episode": 10, "scene": 35, "line": "No. No, that is not all. Let me tell you what real life is like. The men dry up, and the nights get lonely. The only calls on your machine are for collection agencies about that pair of motorcycle boots you never even wore. You stalk your old high school boyfriend online, go to his daughter's soccer games, and make a scene. You buy a diamond ring for yourself, wear it on your right hand, and tell yourself, you're all you need. One day, you're alone, tired. At your feet, a dying bird. But where did it come from? Why did you kill it? Is it because in some strange way it is you?", "order": 53724, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 10, "scene": 35, "line": "Thank you all for your concern, but I'm gonna have to ask you to leave, okay?", "order": 53725, "speaker": "Val Johnson"},
  {"season": 9, "episode": 10, "scene": 35, "line": "So does this mean you're gonna take Darryl back?", "order": 53726, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 10, "scene": 35, "line": "No.", "order": 53727, "speaker": "Val Johnson"},
  {"season": 9, "episode": 10, "scene": 35, "line": "Well, then, what do you say to you and me hitting the town? 'Cause I'm free, literally forever.", "order": 53728, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 10, "scene": 36, "line": "Yeah, Kevin asked me out. I was kinda feeling good about reentering the dating pool, but then Kevin asked me out. Thought I might trade up to a new level of man. Then Kevin asked me out.", "order": 53729, "speaker": "Val Johnson"},
  {"season": 9, "episode": 10, "scene": 37, "line": "[phone rings] Hello?", "order": 53730, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 10, "scene": 37, "line": "[on speaker phone] Hi, honey, it's mom. Look, Cece's school just called. She still has lice.", "order": 53731, "speaker": "Helene Beesly"},
  {"season": 9, "episode": 10, "scene": 37, "line": "What, no. That can't...I don't...", "order": 53732, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 10, "scene": 37, "line": "Now, don't worry, don't worry. I'm on my way to pick her up, but you might have lice too. You know, lice can be tricky. Sometimes lice, like...[Pam hangs up phone]", "order": 53733, "speaker": "Helene Beesly"},
  {"season": 9, "episode": 10, "scene": 37, "line": "Oh my God.", "order": 53734, "speaker": "Angela Martin"},
  {"season": 9, "episode": 10, "scene": 37, "line": "Shave her head. Shave her head!", "order": 53735, "speaker": "Meredith Palmer"},
  {"season": 9, "episode": 10, "scene": 38, "line": "[everyone yelling at Pam]You guys, I am so sorry! It's just been so chaotic with Jim gone, that...", "order": 53736, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 10, "scene": 38, "line": "Yeah yeah, Princess Fancypants let Jane 12-pack over here take the fall.", "order": 53737, "speaker": "Meredith Palmer"},
  {"season": 9, "episode": 10, "scene": 38, "line": "[talking through the loud speaker] She's right Pam.", "order": 53738, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 10, "scene": 38, "line": "Is there a volume knob on that thing?", "order": 53739, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 10, "scene": 38, "line": "Yeah there's a volume knob on that thing.", "order": 53740, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 10, "scene": 38, "line": "Meredith, I am so sorry.", "order": 53741, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 10, "scene": 38, "line": "Well, a lot of good your sorrys are doing her now, Pam. She's a monster.", "order": 53742, "speaker": "Angela Martin"},
  {"season": 9, "episode": 10, "scene": 38, "line": "I meant to say something earlier. I just...", "order": 53743, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 10, "scene": 38, "line": "Just? Just what? Just forgot?", "order": 53744, "speaker": "Meredith Palmer"},
  {"season": 9, "episode": 10, "scene": 39, "line": "Who's the one who didn't bring lice into the office? Meredith. Sure I gave everybody pink eye once, and my ex keyed a few of their cars, and yeah I BMed in the shredder on New Years. But I didn't bring the lice in. That was all Pam.", "order": 53745, "speaker": "Meredith Palmer"},
  {"season": 9, "episode": 10, "scene": 40, "line": "Attention, people of the office. You have exactly 60 secondes to evacutate the bull pen. At that time, I will be tossing this powerful insecticidal grenade, which contains piperonyl butoxide, as well as...[Dwight drops the grenade and it goes off inside Andy's office]", "order": 53746, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 10, "scene": 40, "line": "Dwight, are you okay!?", "order": 53747, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 10, "scene": 40, "line": "Whoa. Hypertoxide has a mild hallucigenic effect, but I don't think it's kicked in yet. I'm gonna count down from ten. Nine, yellow, cold, sad, purple. [Dwight collapses]", "order": 53748, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 10, "scene": 40, "line": "Wow. He got to purple.", "order": 53749, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 10, "scene": 41, "line": "[Angela rinsing Oscar's hair, and she bumps his head] Ow!", "order": 53750, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 10, "scene": 41, "line": "Sorry.", "order": 53751, "speaker": "Angela Martin"},
  {"season": 9, "episode": 10, "scene": 41, "line": "You're waterboarding me!", "order": 53752, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 10, "scene": 41, "line": "Oops.", "order": 53753, "speaker": "Angela Martin"},
  {"season": 9, "episode": 10, "scene": 42, "line": "[shoving large amounts of bubble wrap into the baler, as a warehouse worker passes] No, this is fine. We are allowed to do this. This is okay.", "order": 53754, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 10, "scene": 43, "line": "You're up.", "order": 53755, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 10, "scene": 43, "line": "Yeah, I'm kinda sad to see this baby go.", "order": 53756, "speaker": "Pete Miller"},
  {"season": 9, "episode": 10, "scene": 43, "line": "You do kinda look like Elvis, but we should probably wash all the dead lice out.", "order": 53757, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 10, "scene": 43, "line": "Okay.", "order": 53758, "speaker": "Pete Miller"},
  {"season": 9, "episode": 10, "scene": 43, "line": "Okay. Okay. [rinsing Pete's hair out] Is that too cold?", "order": 53759, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 10, "scene": 43, "line": "No, it actually feels kinda nice.", "order": 53760, "speaker": "Pete Miller"},
  {"season": 9, "episode": 10, "scene": 44, "line": "I wonder what happened over there. I've been sitting here the whole time. [as the baler crushes the bubble wrap, making loud noises]", "order": 53761, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 10, "scene": 45, "line": "Okay.", "order": 53762, "speaker": "Val Johnson"},
  {"season": 9, "episode": 10, "scene": 45, "line": "Huh?", "order": 53763, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 10, "scene": 45, "line": "Let's give it a shot. Let's make it work.", "order": 53764, "speaker": "Val Johnson"},
  {"season": 9, "episode": 10, "scene": 45, "line": "Oh, no. I mean, I want to, I just...I don't want to force you into something you don't want to do.", "order": 53765, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 10, "scene": 45, "line": "You're not. I believe in us.", "order": 53766, "speaker": "Val Johnson"},
  {"season": 9, "episode": 10, "scene": 45, "line": "But... [Val kisses Darryl]", "order": 53767, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 10, "scene": 46, "line": "I'm back together with Val. Yay...", "order": 53768, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 10, "scene": 47, "line": "I had a pretty good day today. Not everything went exactly according to plan, but lice if you are watching, I am ready for you anytime, anywhere. [Dwight gets into his car and another insecticidal grenade goes off]", "order": 53769, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 10, "scene": 48, "line": "Hey, did Erin already take off?", "order": 53770, "speaker": "Pete Miller"},
  {"season": 9, "episode": 10, "scene": 48, "line": "I guess so.", "order": 53771, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 10, "scene": 48, "line": "Okay.", "order": 53772, "speaker": "Pete Miller"},
  {"season": 9, "episode": 10, "scene": 49, "line": "You're Kareem, coming to help.", "order": 53773, "speaker": "Julius"},
  {"season": 9, "episode": 10, "scene": 49, "line": "Okay, I'm Kareem. That makes total sense. I'm Kareem. You're Dr. J. Just sounds weird to say out loud.", "order": 53774, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 10, "scene": 49, "line": "And it went like this. [Jim's phone starts ringing]", "order": 53775, "speaker": "Julius"},
  {"season": 9, "episode": 10, "scene": 49, "line": "And that's all?", "order": 53776, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 10, "scene": 49, "line": "That's it.", "order": 53777, "speaker": "Julius"},
  {"season": 9, "episode": 10, "scene": 49, "line": "No one will believe this back home, but that's okay. Let me show you how it's done.", "order": 53778, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 10, "scene": 49, "line": "Yeah, you show me how.", "order": 53779, "speaker": "Julius"},
  {"season": 9, "episode": 10, "scene": 50, "line": "[Jim's phone goes to voicemail] This is Jim Halpert, leave a message.", "order": 53780, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 10, "scene": 50, "line": "Hey honey, it's me. Just trying to catch you before going home. Um, oh, well just give me a call whenever. Hope your day got better. Love you.", "order": 53781, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 10, "scene": 50, "line": "Hey, what you doing right now?", "order": 53782, "speaker": "Meredith Palmer"},
  {"season": 9, "episode": 10, "scene": 50, "line": "I'm just gonna go home. My mom's been watching the kids all day.", "order": 53783, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 10, "scene": 50, "line": "Let her stay another hour. Let's go get a beer.", "order": 53784, "speaker": "Meredith Palmer"},
  {"season": 9, "episode": 10, "scene": 50, "line": "Really? A beer sounds incredible right now.", "order": 53785, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 10, "scene": 50, "line": "No duh. Let's go. You're buying.", "order": 53786, "speaker": "Meredith Palmer"},
  {"season": 9, "episode": 10, "scene": 51, "line": "Meredith, I am so sorry about today.", "order": 53787, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 10, "scene": 51, "line": "Forget about it.", "order": 53788, "speaker": "Meredith Palmer"},
  {"season": 9, "episode": 10, "scene": 51, "line": "I just did not realize how hard it was gonna be without Jim. I mean, I really respect you for being a single mom all these years. It whipped my ass in half a week. I have to say, there are not a lot of people who could pull off a shaved head, but you are rocking it.", "order": 53789, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 10, "scene": 51, "line": "Right. I got the bartender's phone number when you were in the john. I'm gonna take that freak to bone town before the night is over.", "order": 53790, "speaker": "Meredith Palmer"},
  {"season": 9, "episode": 10, "scene": 51, "line": "Get it, girl!", "order": 53791, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 10, "scene": 52, "line": "[Pam and Meredith singing karaoke] This one's for all you ladies out there.", "order": 53792, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 10, "scene": 52, "line": "[starts singing] I come...", "order": 53793, "speaker": "Meredith Palmer"},
  {"season": 9, "episode": 10, "scene": 52, "line": "Not yet.", "order": 53794, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 10, "scene": 52, "line": "[singing] I come home in the middle of the night. My mother says when you gonna live your life right? Oh Mother dear, we're not the fortunate ones. And girls, they want to have fun. Oh girls just want to have fun.", "order": 53795, "speaker": "Both"},
  {"season": 9, "episode": 11, "scene": 1, "line": "Stone & Son Suit Warehouse contract expired with their paper provider! Go get it! 'David Wallace", "order": 53796, "speaker": "New Instant Message"},
  {"season": 9, "episode": 11, "scene": 2, "line": "Stone and Son Suit Warehouse recently lost their paper provider. They're a family owned business [chuckles]. Jim and I used to clean up at those. We'd go in pretending to be family ' brothers. We did it at a family owned law firm, at a family owned construction company, and a family owned motorcycle store. Jim and Dwight Shrupert. I was the dynamic, likeable winner that was doted upon by Mom. And Jim was the closeted foot fetishist pretending to belong. The client never knew any of that, but I knew.", "order": 53797, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 11, "scene": 3, "line": "[on phone with Jim] I hope you've been paying your wig storage bills Jimbo, because it's time for another episode of 'Handsome and Stinky, paper brothers for hire'.", "order": 53798, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 11, "scene": 3, "line": "Sorry, Stinky, can't do it. Too busy.", "order": 53799, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 11, "scene": 3, "line": "Oh, god, this again? You're Stinky.", "order": 53800, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 11, "scene": 3, "line": "Okay. There's no possible way I can get there, so just ask Phyllis. She can be your older sister or something.", "order": 53801, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 11, "scene": 3, "line": "Phyllis, my sister? More like my dead great great grandmother who died of stupidity.", "order": 53802, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 11, "scene": 3, "line": "I have ears, Dwight.", "order": 53803, "speaker": "Phyllis Vance"},
  {"season": 9, "episode": 11, "scene": 3, "line": "Oh, do you really have ears, Phyllis? Like all human beings? We all have ears. [back to Jim] See what you leave me with here, Jim?", "order": 53804, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 11, "scene": 3, "line": "Hey, I'm in Philadelphia right now.", "order": 53805, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 11, "scene": 3, "line": "How is that my problem? Get in your car and drive down here. You can make it in 30 minutes if you drive 240 miles per hour.", "order": 53806, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 11, "scene": 3, "line": "Huh. How long would it take me if I drove 300 miles an hour?", "order": 53807, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 11, "scene": 3, "line": "Mmm. That's a good' question. 300 times' [Jim hangs up] 180' Um that comes to 25 minutes. [realizes Jim hung up] Yes. Oh, well, thank you Jim. Yes, I am better than you. Thanks for acknowledging that. Okay, bye bye. Love you.", "order": 53808, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 11, "scene": 4, "line": "Darryl, looking good!", "order": 53809, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 11, "scene": 4, "line": "Heading to Philly. Interview with Jim's company.", "order": 53810, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 11, "scene": 4, "line": "Hey, good luck. When you get to Philly, will you tell Jim I miss him?", "order": 53811, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 11, "scene": 4, "line": "Why don't you come along and tell him yourself?", "order": 53812, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 11, "scene": 4, "line": "Ha ha. That'd be great. Kind of like ditching school, except instead of getting suspended, you get' What do you get? Oh my god! Why didn't I think of this? Andy's not here. Oh, hey, Erin, um, I'm expecting a shipment of pens. Can you cover that?", "order": 53813, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 11, "scene": 4, "line": "Mhm. Cover that. What does that mean, exactly? What are we talking here? Details.", "order": 53814, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 11, "scene": 4, "line": "A delivery guy will deliver a box of pens, and you just make sure everything's in order.", "order": 53815, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 11, "scene": 4, "line": "What? Everything? What, how do I make sure it's in order? I [laughs] haven't been trained for this.", "order": 53816, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 11, "scene": 4, "line": "[giggles] Okay, see you later.", "order": 53817, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 11, "scene": 5, "line": "Hey guys.", "order": 53818, "speaker": "Clark Green"},
  {"season": 9, "episode": 11, "scene": 5, "line": "Clark!", "order": 53819, "speaker": "Phyllis Vance"},
  {"season": 9, "episode": 11, "scene": 5, "line": "Hey!", "order": 53820, "speaker": "Meredith Palmer"},
  {"season": 9, "episode": 11, "scene": 5, "line": "Hey, look who's back, Dwight Junior.", "order": 53821, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 11, "scene": 5, "line": "Hey, so how was it? I mean' the sex with Jan.", "order": 53822, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 11, "scene": 5, "line": "A gentleman doesn't discuss such matters. Especially when the feelings of a lady are involved.", "order": 53823, "speaker": "Clark Green"},
  {"season": 9, "episode": 11, "scene": 6, "line": "Women reach their sexual peak at whatever age Jan was last week. I mean it was' like making love with a wild animal. But not like a cougar like you might think. It was uh' like a swarm of bees. Bees that just find something wrong with every hotel room.", "order": 53824, "speaker": "Clark Green"},
  {"season": 9, "episode": 11, "scene": 7, "line": "Nothing wrong with being a gentleman. Like my mom used to say, 'Talk classy, act nasty'.", "order": 53825, "speaker": "Meredith Palmer"},
  {"season": 9, "episode": 11, "scene": 7, "line": "What's with the wig Meredith?", "order": 53826, "speaker": "Clark Green"},
  {"season": 9, "episode": 11, "scene": 7, "line": "What, is it on backwards? [partially removes wig, revealing her bald head]", "order": 53827, "speaker": "Meredith Palmer"},
  {"season": 9, "episode": 11, "scene": 7, "line": "Nope, you got it. You fixed it. Uh, I have a gift from Jan. It's an espresso maker! We came to be quite fond of espresso on the Amalfi Coast so' bon appetite.", "order": 53828, "speaker": "Clark Green"},
  {"season": 9, "episode": 11, "scene": 7, "line": "Ooo! 16 types of espresso! Now that's Italian.", "order": 53829, "speaker": "Phyllis Vance"},
  {"season": 9, "episode": 11, "scene": 7, "line": "My friend has one of these. Fool-proof espresso every time.", "order": 53830, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 11, "scene": 7, "line": "Always with the friends Oscar. Can't we just enjoy the new espresso machine?", "order": 53831, "speaker": "Meredith Palmer"},
  {"season": 9, "episode": 11, "scene": 7, "line": "Actually, it's pronounced 'espresso'' Wait. That's what you said. I apologize, I just assumed you would mispronounce it. So'", "order": 53832, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 11, "scene": 8, "line": "There he is! [chuckles] We are all so proud of you for the small part that you played in landing the white pages. Prouder of me, but'", "order": 53833, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 11, "scene": 8, "line": "If you really want to show appreciation, just give me half the commission.", "order": 53834, "speaker": "Clark Green"},
  {"season": 9, "episode": 11, "scene": 8, "line": "Gosh, you know, I wish I could but, uh' No. Hey, listen, remember how everyone used to call you Dwight Junior and how much you loved that?", "order": 53835, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 11, "scene": 8, "line": "[sarcastically chuckling] I loved it.", "order": 53836, "speaker": "Clark Green"},
  {"season": 9, "episode": 11, "scene": 8, "line": "How would you like to pretend to by my son in order to land a sale?", "order": 53837, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 11, "scene": 8, "line": "If it'll lead to me being a salesman, I'll pretend to be your friend.", "order": 53838, "speaker": "Clark Green"},
  {"season": 9, "episode": 11, "scene": 8, "line": "Then looks like we have a deal' Son [holds up a suit identical to his]", "order": 53839, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 11, "scene": 9, "line": "Hi guys!", "order": 53840, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 11, "scene": 9, "line": "Hey, look who it is!", "order": 53841, "speaker": "Pete Miller"},
  {"season": 9, "episode": 11, "scene": 9, "line": "Sorry. Super-busy. Pens coming in later, just grabbing a Java before all hell breaks loose.", "order": 53842, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 11, "scene": 9, "line": "Try this one. [handing Erin a cup of espresso]", "order": 53843, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 11, "scene": 9, "line": "Thank you! [leaves the room]", "order": 53844, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 11, "scene": 10, "line": "I don't really have time to think about Pete right now. I just have a lot going on with this whole shipment of pens. And I have a lot of people trusting me, and I would feel super guilty if I broke anyone's trust. About the pens.", "order": 53845, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 11, "scene": 11, "line": "You ready for your interview?", "order": 53846, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 11, "scene": 11, "line": "I was born ready! No, I suck at interviews. I had Andy's job in the bag until my interview.", "order": 53847, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 11, "scene": 11, "line": "Well, you shouldn't be nervous about this. This is a tiny start-up with a bunch of guys just as dorky as Jim.", "order": 53848, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 11, "scene": 11, "line": "Yeah, I guess'", "order": 53849, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 11, "scene": 11, "line": "Really, you can't be scared of a room full of Jims. I love the guy, but he's basically Gumby with hair. [Darryl laughs]", "order": 53850, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 11, "scene": 12, "line": "[chuckling] It's kind of funny, a father and son sales team meeting with a father and son suit store.", "order": 53851, "speaker": "Suit Store Father"},
  {"season": 9, "episode": 11, "scene": 12, "line": "You know, you're right! We hadn't even thought of that, had we boy? [to Clark]", "order": 53852, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 11, "scene": 12, "line": "No, we sure hadn't Pop. [both chuckling]", "order": 53853, "speaker": "Clark Green"},
  {"season": 9, "episode": 11, "scene": 12, "line": "[Takes picture from desk] Oh! You're a hunter I see. Spend your early mornings out in the blinds, like Clarky and me. Huh? [grabs Clark's shoulder]", "order": 53854, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 11, "scene": 12, "line": "He calls me Clarky 'cause he's my Dad.", "order": 53855, "speaker": "Clark Green"},
  {"season": 9, "episode": 11, "scene": 12, "line": "Guilty!", "order": 53856, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 11, "scene": 12, "line": "You don't meet many hunters these days.", "order": 53857, "speaker": "Suit Store Father"},
  {"season": 9, "episode": 11, "scene": 12, "line": "My dad is the best hunter there is. I mean, he's like a serial killer' of animals. One time, he snuck up behind a sleeping deer and just sawed its head right of. It was sick! [Dwight imitates sawing and blood spurting out of his neck, then makes a dying noise]", "order": 53858, "speaker": "Clark Green"},
  {"season": 9, "episode": 11, "scene": 12, "line": "It was his birthday, just turned three. So'", "order": 53859, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 11, "scene": 12, "line": "Ahh'", "order": 53860, "speaker": "Suit Store Father"},
  {"season": 9, "episode": 11, "scene": 13, "line": "You guys, I kind of think I want to try them all. Is that crazy?", "order": 53861, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 11, "scene": 13, "line": "No. Look, without a taste test, how are we supposed to know which flavors we like to reorder?", "order": 53862, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 11, "scene": 13, "line": "Taste test? I'm in!", "order": 53863, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 11, "scene": 13, "line": "YOLO! [singing and dancing]", "order": 53864, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 11, "scene": 13, "line": "What? [everyone is confused]", "order": 53865, "speaker": "Phyllis Vance"},
  {"season": 9, "episode": 11, "scene": 13, "line": "It's a thing. It means 'you only live once'.", "order": 53866, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 11, "scene": 13, "line": "Yeah, we're aware of what it means Oscar, you just do not look cool saying it.", "order": 53867, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 11, "scene": 13, "line": "Well'", "order": 53868, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 11, "scene": 13, "line": "Alright everybody, who's in? [everyone except Angela puts their hand in the middle of the group, like a huddle] Angela? [Angela shakes her head] Don't make us come over there.", "order": 53869, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 11, "scene": 13, "line": "No, I don't' no.", "order": 53870, "speaker": "Angela Martin"},
  {"season": 9, "episode": 11, "scene": 13, "line": "Alright, let's go. [starts shuffling the whole group towards Angela]", "order": 53871, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 11, "scene": 13, "line": "Can't I just agree without putting my hand in?", "order": 53872, "speaker": "Angela Martin"},
  {"season": 9, "episode": 11, "scene": 13, "line": "Absolutely not!", "order": 53873, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 11, "scene": 13, "line": "No way.", "order": 53874, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 11, "scene": 13, "line": "Oh, fine. [puts a napkin on top of everyone else's hands and reluctantly puts her own hand on the napkin]", "order": 53875, "speaker": "Angela Martin"},
  {"season": 9, "episode": 11, "scene": 13, "line": "Ok guys, 'we all drink them all'.", "order": 53876, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 11, "scene": 13, "line": "[Meredith, Phyllis, Nellie, Angela, Creed, Oscar, Stanley] We all drink them all!", "order": 53877, "speaker": "Everyone"},
  {"season": 9, "episode": 11, "scene": 13, "line": "Yes!", "order": 53878, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 11, "scene": 13, "line": "Yes!", "order": 53879, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 11, "scene": 13, "line": "Whoo!", "order": 53880, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 11, "scene": 13, "line": "Hey, where's my ring?", "order": 53881, "speaker": "Phyllis Vance"},
  {"season": 9, "episode": 11, "scene": 13, "line": "I'm sure it'll turn up.", "order": 53882, "speaker": "Creed Bratton"},
  {"season": 9, "episode": 11, "scene": 14, "line": "[in the background] Yes ma'am, you want to go east on Franklin, take that all the way to Commonweath. You'll see us on the right side, you can't miss us.", "order": 53883, "speaker": "Athlead Employee"},
  {"season": 9, "episode": 11, "scene": 14, "line": "And I want to reach every demographic possible.", "order": 53884, "speaker": "Dennis"},
  {"season": 9, "episode": 11, "scene": 14, "line": "No, I hear what you're saying, and we will. The thing is, we gotta lock down this key demographic first. The rest will come, I promise. Just give me a couple weeks, alright?", "order": 53885, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 11, "scene": 14, "line": "We're talking weeks here, not months, right?", "order": 53886, "speaker": "Dennis"},
  {"season": 9, "episode": 11, "scene": 14, "line": "Weeks. Always weeks. Man, the last time I talked months was like, a million weeks ago.", "order": 53887, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 11, "scene": 14, "line": "Alright. Good.", "order": 53888, "speaker": "Dennis"},
  {"season": 9, "episode": 11, "scene": 14, "line": "Thanks Dennis, I appreciate it. [To Pam] Hey! How are you?", "order": 53889, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 11, "scene": 14, "line": "Good!", "order": 53890, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 11, "scene": 14, "line": "What are you doing here?", "order": 53891, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 11, "scene": 14, "line": "I just wanted to see you!", "order": 53892, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 11, "scene": 14, "line": "That's so great!", "order": 53893, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 11, "scene": 14, "line": "Damn! Jim, you got a real Facebook energy going on here man. You Zuckerberged this place out!", "order": 53894, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 11, "scene": 14, "line": "It's pretty great, right? I mean, we're coming along' [to coworker] Hey, Wade, we gotta just push up Dennis's timeline", "order": 53895, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 11, "scene": 14, "line": "Okay", "order": 53896, "speaker": "Wade"},
  {"season": 9, "episode": 11, "scene": 14, "line": "[back to Pam] Actually, you know what? Give me one second, okay? Make yourselves at home.", "order": 53897, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 11, "scene": 15, "line": "But I think that all of your concerns will be answered when you see the brochure that Clark's getting out of my car. He's a good boy, does whatever I say.", "order": 53898, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 11, "scene": 15, "line": "[sighs] I can't relate to that, my son hates my guts.", "order": 53899, "speaker": "Suit Store Father"},
  {"season": 9, "episode": 11, "scene": 15, "line": "Oh' really.", "order": 53900, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 11, "scene": 15, "line": "Bring him into the business, and he resents me. How do you like that?", "order": 53901, "speaker": "Suit Store Father"},
  {"season": 9, "episode": 11, "scene": 15, "line": "[fumbling] Well, things between me and Clark are good, but not great. In fact, I will say that they're not even good. Really, they're bad. Like you and your son.", "order": 53902, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 11, "scene": 15, "line": "[enters] Here you go, Pop! I know it was just a couple minutes, but' God I missed you!", "order": 53903, "speaker": "Clark Green"},
  {"season": 9, "episode": 11, "scene": 15, "line": "What took you so long? Were you loitering out there like a hooligan, smoking a doobie?", "order": 53904, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 11, "scene": 15, "line": "[confused] I sure was. Just like he taught me, looking cool.", "order": 53905, "speaker": "Clark Green"},
  {"season": 9, "episode": 11, "scene": 15, "line": "You're being disrespectful!", "order": 53906, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 11, "scene": 15, "line": "And I love you, for saying that.", "order": 53907, "speaker": "Clark Green"},
  {"season": 9, "episode": 11, "scene": 15, "line": "[whispers to Clark] We don't get along.", "order": 53908, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 11, "scene": 15, "line": "[catches on] Ha ha! I just burned him. Because the truth is' our relationship is' terr-", "order": 53909, "speaker": "Clark Green"},
  {"season": 9, "episode": 11, "scene": 15, "line": "Terrible!", "order": 53910, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 11, "scene": 15, "line": "Terrible.", "order": 53911, "speaker": "Clark Green"},
  {"season": 9, "episode": 11, "scene": 15, "line": "Yes! Genius. Stupid, stupid genius!", "order": 53912, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 11, "scene": 16, "line": "Wow, this place is' so great. I had no idea ' on the phone, you made it sound kind of dinky.", "order": 53913, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 11, "scene": 16, "line": "Well, I mean, I don't even have the paycheck yet. It is a startup, so' These things go down all the time.", "order": 53914, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 11, "scene": 16, "line": "If this company's going down, I wanna go down on it. With it. I wanna go down with it.", "order": 53915, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 11, "scene": 16, "line": "[to Pam] Are you cool to just hang out?", "order": 53916, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 11, "scene": 16, "line": "Sure, no problem.", "order": 53917, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 11, "scene": 16, "line": "Yeah? [to Darryl] You ready?", "order": 53918, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 11, "scene": 16, "line": "Yeah.", "order": 53919, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 11, "scene": 16, "line": "Let's do it!", "order": 53920, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 11, "scene": 17, "line": "The pen delivery went amazing, and now I've got all these pens just waiting to be unpacked. But Pam did not tell me to unpack the pens, and I'm not one of those people who's just like, 'Uh, sure. I'll accept the pens when they come in, and then as soon as your back's turned, I unpack the pens and get all this credit as some great pen unpacker. On the other hand' they are just sitting here. Pam didn't tell me not to unpack them. Don't want to be a busybody, but I don't want to be a lazybones. Busybody, lazybones. Busybody, lazybones. Ahh! My brain is ping-ponging around in my head right now, it's insane! I'm sorry, what was your question again? Oh yeah, no. I've never had an espresso before. They're good though.", "order": 53921, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 11, "scene": 18, "line": "A-bam! [slams espresso cup on table] My favorite is Viennese Amaretto. And the worst flavor I've tried so far is Alpine Select!", "order": 53922, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 11, "scene": 18, "line": "Yes! [giggles and picks up Angela]", "order": 53923, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 11, "scene": 18, "line": "Ugh!", "order": 53924, "speaker": "Angela Martin"},
  {"season": 9, "episode": 11, "scene": 18, "line": "One! [as he picks Angela up in the air]", "order": 53925, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 11, "scene": 18, "line": "Kevin! Kevin! Kevin!", "order": 53926, "speaker": "Angela Martin"},
  {"season": 9, "episode": 11, "scene": 18, "line": "Two' [picks Angela up again]", "order": 53927, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 11, "scene": 18, "line": "Stop it!", "order": 53928, "speaker": "Angela Martin"},
  {"season": 9, "episode": 11, "scene": 18, "line": "Three'", "order": 53929, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 11, "scene": 18, "line": "That's enough Kevin.", "order": 53930, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 11, "scene": 18, "line": "Stop it Kevin!", "order": 53931, "speaker": "Angela Martin"},
  {"season": 9, "episode": 11, "scene": 18, "line": "Four!", "order": 53932, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 11, "scene": 18, "line": "That's enough!", "order": 53933, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 11, "scene": 18, "line": "Kevin!", "order": 53934, "speaker": "Angela Martin"},
  {"season": 9, "episode": 11, "scene": 19, "line": "So for your menswear catalogue, I think we can offer you some very competitive pricing.", "order": 53935, "speaker": "Clark Green"},
  {"season": 9, "episode": 11, "scene": 19, "line": "I heard that before'", "order": 53936, "speaker": "Suit Store Father"},
  {"season": 9, "episode": 11, "scene": 19, "line": "Well, I understand, but '", "order": 53937, "speaker": "Clark Green"},
  {"season": 9, "episode": 11, "scene": 19, "line": "I bet you have. He knows nothing about what people have heard before, my son.", "order": 53938, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 11, "scene": 19, "line": "Here we go again, another lecture from the old man.", "order": 53939, "speaker": "Clark Green"},
  {"season": 9, "episode": 11, "scene": 19, "line": "Listen to him. He created you.", "order": 53940, "speaker": "Suit Store Father"},
  {"season": 9, "episode": 11, "scene": 19, "line": "Maybe if you listened a little bit, you'd improve. No wonder women despise you.", "order": 53941, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 11, "scene": 19, "line": "Women don't despise me'", "order": 53942, "speaker": "Clark Green"},
  {"season": 9, "episode": 11, "scene": 19, "line": "His last girlfriend was a transvestite. I knew it right away. Adam's apple like the prow of a ship, thumbs like a lowland gorilla. Ha, but this one couldn't see it, or didn't wanna see it.", "order": 53943, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 11, "scene": 19, "line": "Alright, that's enough, 'cause I can say some things about him too.", "order": 53944, "speaker": "Clark Green"},
  {"season": 9, "episode": 11, "scene": 19, "line": "Yeah? Like what?", "order": 53945, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 11, "scene": 19, "line": "Like the time that you got drunk and, and then' Killed those kids on their way to prom!", "order": 53946, "speaker": "Clark Green"},
  {"season": 9, "episode": 11, "scene": 19, "line": "That never happened. He's always been a liar. Ever since he was a little kid. He got caught 'saving treats' from the kitty litter box.", "order": 53947, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 11, "scene": 19, "line": "Really shameful'", "order": 53948, "speaker": "Suit Store Father"},
  {"season": 9, "episode": 11, "scene": 19, "line": "So we can offer you matte or glossy printing-", "order": 53949, "speaker": "Clark Green"},
  {"season": 9, "episode": 11, "scene": 19, "line": "Glistening brown morsels tumbling from every pouch in his trousers.", "order": 53950, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 11, "scene": 19, "line": "There's obviously a volume discount uh, if you-", "order": 53951, "speaker": "Clark Green"},
  {"season": 9, "episode": 11, "scene": 19, "line": "Following the cat around on his knees with his hands cupped beneath its tail, going 'please kitty, may I have some more?' You can't make this stuff up!", "order": 53952, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 11, "scene": 19, "line": "No, I think someone could make it up. Someone with very few friends.", "order": 53953, "speaker": "Clark Green"},
  {"season": 9, "episode": 11, "scene": 19, "line": "Hey fellas, sorry to keep you waiting.", "order": 53954, "speaker": "Suit Store Son"},
  {"season": 9, "episode": 11, "scene": 19, "line": "Here he is, my son.", "order": 53955, "speaker": "Suit Store Father"},
  {"season": 9, "episode": 11, "scene": 19, "line": "[scoffs] Got cat turd collector written all over him.", "order": 53956, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 11, "scene": 19, "line": "Did you say cat turd collector?", "order": 53957, "speaker": "Suit Store Son"},
  {"season": 9, "episode": 11, "scene": 20, "line": "...So definitely looking to expand our market, but for now we're just concentrating on athletes that are popular here in the northeast. Of course, that's not a mandate.", "order": 53958, "speaker": "Athlead Employee"},
  {"season": 9, "episode": 11, "scene": 20, "line": "Mandate'- Always think of two men on a date. [awkward moment] I have gay friends' I have one gay friend. [to Jim] Oscar?", "order": 53959, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 11, "scene": 20, "line": "Mm-hm", "order": 53960, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 11, "scene": 20, "line": "Alright, so what makes you think you'd be a good fit here?", "order": 53961, "speaker": "Athlead Employee"},
  {"season": 9, "episode": 11, "scene": 20, "line": "[freezes] Ummm' [a moment later] Alright. Obviously y'all look really busy, and uh, I don't want to waste your time anymore. Sorry, I just uhh' Obviously I'm not qualified to be here man, I'm' I'm a warehouse manager, you know'", "order": 53962, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 11, "scene": 20, "line": "Darryl, I was a newspaper editor.", "order": 53963, "speaker": "Athlead Employee"},
  {"season": 9, "episode": 11, "scene": 20, "line": "Science teacher, volleyball coach.", "order": 53964, "speaker": "Female Athlead Employee"},
  {"season": 9, "episode": 11, "scene": 20, "line": "I work at a home shopping network.", "order": 53965, "speaker": "3rd Athlead Employee"},
  {"season": 9, "episode": 11, "scene": 20, "line": "I'm a laywer. I'm the only one here who can honestly help. [group chuckles]", "order": 53966, "speaker": "4th Athlead Employee"},
  {"season": 9, "episode": 11, "scene": 20, "line": "And as you know, I was a paper salesman. [whispers to Darryl] Hey, I find it helps if you just picture everybody naked. [group overhears and chuckles]", "order": 53967, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 11, "scene": 20, "line": "Cool. Hey, thanks'", "order": 53968, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 11, "scene": 20, "line": "So how about we start over, hmm? Darryl, do you have any thoughts on the company?", "order": 53969, "speaker": "Athlead Employee"},
  {"season": 9, "episode": 11, "scene": 20, "line": "Yes, as a matter of fact, I uh' [pulls booklets from his bag] I wrote some down. There you go. [passes out booklets] Wow, this guy came prepared, it impresses me! [group laughs]", "order": 53970, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 11, "scene": 21, "line": "Ah! So, this is what 2:00 P.M. looks like around here!", "order": 53971, "speaker": "Stanley Hudson"},
  {"season": 9, "episode": 11, "scene": 22, "line": "I usually take a siesta about now.", "order": 53972, "speaker": "Stanley Hudson"},
  {"season": 9, "episode": 11, "scene": 23, "line": "[to Erin] Stocking pens, huh? You're like the new office administrator.", "order": 53973, "speaker": "Pete Miller"},
  {"season": 9, "episode": 11, "scene": 23, "line": "No, I just took over the pen shipment because Pam had to leave. When I say it out loud, I know that sounds insane, but it's the truth, I swear.", "order": 53974, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 11, "scene": 23, "line": "Pam! Pam, look out! Erin's gunning for your job!", "order": 53975, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 11, "scene": 23, "line": "No, I'm not! [Kevin imitates gunfire] It's not like that at all! Forget it, I'm so sorry.", "order": 53976, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 11, "scene": 23, "line": "Pam, look out!", "order": 53977, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 11, "scene": 23, "line": "Pens, you did not buy into this. I am sorry, what a day you've had.", "order": 53978, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 11, "scene": 24, "line": "By the way, Jim talks about you all the time.", "order": 53979, "speaker": "Athlead Employee"},
  {"season": 9, "episode": 11, "scene": 24, "line": "That's sweet that he talks about me.", "order": 53980, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 11, "scene": 24, "line": "It's too bad he still hast to work part time in Scranton though'", "order": 53981, "speaker": "Athlead Employee"},
  {"season": 9, "episode": 11, "scene": 24, "line": "Well, that's funny because I think of him as working part time in Philadelphia.", "order": 53982, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 11, "scene": 24, "line": "We can't wait until you move here.", "order": 53983, "speaker": "Athlead Employee"},
  {"season": 9, "episode": 11, "scene": 25, "line": "I'm sorry, you guys are here to sell us paper?", "order": 53984, "speaker": "Suit Store Son"},
  {"season": 9, "episode": 11, "scene": 25, "line": "Do you mind? The men are talking.", "order": 53985, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 11, "scene": 25, "line": "Sons used to idolize their fathers.", "order": 53986, "speaker": "Suit Store Father"},
  {"season": 9, "episode": 11, "scene": 25, "line": "Us old timers need to stick together. And how better than by signing a contract?", "order": 53987, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 11, "scene": 25, "line": "I'd love to! Sam Junior here, he runs the business now. Kind of pushed me out, truth be told. I'm just here for human contract.", "order": 53988, "speaker": "Suit Store Father"},
  {"season": 9, "episode": 11, "scene": 25, "line": "Okay Pop'", "order": 53989, "speaker": "Suit Store Son"},
  {"season": 9, "episode": 11, "scene": 25, "line": "Wait, so you're the boss?", "order": 53990, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 11, "scene": 25, "line": "That's right.", "order": 53991, "speaker": "Suit Store Son"},
  {"season": 9, "episode": 11, "scene": 25, "line": "Hi, I'm Clark.", "order": 53992, "speaker": "Clark Green"},
  {"season": 9, "episode": 11, "scene": 25, "line": "Hey.", "order": 53993, "speaker": "Suit Store Son"},
  {"season": 9, "episode": 11, "scene": 25, "line": "Let's talk.", "order": 53994, "speaker": "Clark Green"},
  {"season": 9, "episode": 11, "scene": 25, "line": "Okay.", "order": 53995, "speaker": "Suit Store Son"},
  {"season": 9, "episode": 11, "scene": 25, "line": "So if you look at our catalog here'", "order": 53996, "speaker": "Clark Green"},
  {"season": 9, "episode": 11, "scene": 26, "line": "Well, thanks for coming down Darryl.", "order": 53997, "speaker": "Athlead Employee"},
  {"season": 9, "episode": 11, "scene": 26, "line": "It was nice meeting you Darryl, I think you'd fit in great here.", "order": 53998, "speaker": "Female Athlead Employee"},
  {"season": 9, "episode": 11, "scene": 26, "line": "Yeah, yeah me too. I think it'd be like' You know what? [grabs basketball] I think it'd be like a Kevin Durant jump shot' Perfecto! [shoots basketball at hoop on wall, basketball misses and lands in fish tank, killing the fish] Oh my god'", "order": 53999, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 11, "scene": 27, "line": "They're the ones who put a fish tank next to a basketball hoop. It's like if I put a glass of milk on the edge of the table and Cece knocks it over, I don't blame Cece.", "order": 54000, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 11, "scene": 27, "line": "So I'm like a three year old girl in this scenario'", "order": 54001, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 11, "scene": 27, "line": "Say they don't hire you. It's not like you're out on the street. You have a great job with people who love you.", "order": 54002, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 11, "scene": 27, "line": "And I'd only get to see Jada on the weekends. You know, I'm' I'm not so sure I'd like living in Philadelphia.", "order": 54003, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 11, "scene": 27, "line": "Right? Thank you! It's just Philly. Everyone's acting like it's New York or Paris or London.", "order": 54004, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 11, "scene": 27, "line": "Who needs it?", "order": 54005, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 11, "scene": 27, "line": "Not us.", "order": 54006, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 11, "scene": 27, "line": "[enters] Okay, so the consensus was that that was unique. They're going to make you pay for the fish, and' they wanted to know when you can start.", "order": 54007, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 11, "scene": 27, "line": "What? [Jim shrugs] How about yesterday?", "order": 54008, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 11, "scene": 27, "line": "Congratulations.", "order": 54009, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 11, "scene": 27, "line": "Oh, thank you.", "order": 54010, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 11, "scene": 27, "line": "I guess you gotta move to Philly after all.", "order": 54011, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 11, "scene": 27, "line": "Yes, I love Philly! Two-One-Five or die!", "order": 54012, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 11, "scene": 28, "line": "No, I'm not upset. I'm really excited for Darryl' Maybe I'm a little disappointed that we'll be losing him.", "order": 54013, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 11, "scene": 29, "line": "It feels really hot in here. Is it hot in here? It feels really really hot in here.", "order": 54014, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 11, "scene": 29, "line": "It's insane! They need to have the A.C. on year round! January too!", "order": 54015, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 11, "scene": 29, "line": "[banging on window] I don't get the point of this stupid window!", "order": 54016, "speaker": "Angela Martin"},
  {"season": 9, "episode": 11, "scene": 30, "line": "I mean, look, you and I both know that in paper or fashion, styles change. Check out my dad's suit. You are looking at pure acrylic. That's why his face always breaks out.", "order": 54017, "speaker": "Clark Green"},
  {"season": 9, "episode": 11, "scene": 30, "line": "Does that suit come with a fire extinguisher? [Clark chuckles]", "order": 54018, "speaker": "Suit Store Son"},
  {"season": 9, "episode": 11, "scene": 30, "line": "You know what Dad? Maybe you should buy me a suit. I mean, I'm going to need one right? If I'm ever going to get a 'real job' and move my 'lazy ass' out of your 'G.D. house'.", "order": 54019, "speaker": "Clark Green"},
  {"season": 9, "episode": 11, "scene": 30, "line": "He's got you there'", "order": 54020, "speaker": "Suit Store Son"},
  {"season": 9, "episode": 11, "scene": 31, "line": "[to Clark in dressing room] That's Italian silk. Very comfortable. Very tasteful' Although expensive.", "order": 54021, "speaker": "Suit Store Son"},
  {"season": 9, "episode": 11, "scene": 31, "line": "Yeah, you don't want Italian. You'll look like a mafia don. Next thing you know, you'll be doing life in Rikers Island.", "order": 54022, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 11, "scene": 31, "line": "Well, that's better than looking like the undertaker from boring island.", "order": 54023, "speaker": "Clark Green"},
  {"season": 9, "episode": 11, "scene": 31, "line": "That place doesn't exist. It's not a documented island. Ha ha, cartograph much?", "order": 54024, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 11, "scene": 31, "line": "[emerges from dressing room wearing a sharp black suit] How do I look?", "order": 54025, "speaker": "Clark Green"},
  {"season": 9, "episode": 11, "scene": 31, "line": "Actually' You look great. They steered you right. I guess it does make sense buying from a father son team. You know what? I'll take one too.", "order": 54026, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 11, "scene": 32, "line": "Everyone! Now that we have all this energy, why don't we move the copier into the annex like we've always wanted to?", "order": 54027, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 11, "scene": 32, "line": "Whoo!", "order": 54028, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 11, "scene": 32, "line": "Frickin' A!", "order": 54029, "speaker": "Meredith Palmer"},
  {"season": 9, "episode": 11, "scene": 32, "line": "Huh?", "order": 54030, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 11, "scene": 32, "line": "So long, noise!", "order": 54031, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 11, "scene": 32, "line": "One, two, three! [all gather around copier and push, tearing carpet beneath]", "order": 54032, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 11, "scene": 32, "line": "And we have torn up the carpet! Oh, we're going to be in so much trouble!", "order": 54033, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 11, "scene": 32, "line": "Wait! [looking at the hardwood floor beneath the carpet] It's beautiful. Hardwood! I always knew it was down here, I just never dreamed that I would actually see it.", "order": 54034, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 11, "scene": 32, "line": "Tear up the carpet!", "order": 54035, "speaker": "Meredith Palmer"},
  {"season": 9, "episode": 11, "scene": 32, "line": "Whoo!", "order": 54036, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 11, "scene": 33, "line": "Kill their fish, and they still hire me. That's how you do that, baby. It's all good, I'm ready. Y'all ready for this? [sings intro to 'Everybody dance now', points to Pam to sing first line]", "order": 54037, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 11, "scene": 33, "line": "[reluctantly] Everybody dance now' [Darryl continues singing]", "order": 54038, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 11, "scene": 34, "line": "Kevin, move. I can't pull up the rug if you're standing on it with your rhinoceros feet.", "order": 54039, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 11, "scene": 34, "line": "Well I can't stand here if you pull the carpet out from under me.", "order": 54040, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 11, "scene": 34, "line": "Oh, my head is killing me. Does anyone have a baby asprin?", "order": 54041, "speaker": "Angela Martin"},
  {"season": 9, "episode": 11, "scene": 34, "line": "Oh, enough with the whining already! Why don't you just have some more coffee?", "order": 54042, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 11, "scene": 34, "line": "It's all gone. I didn't get a chance to try them all because Creed poured my Bogota Sunrise in the plant.", "order": 54043, "speaker": "Stanley Hudson"},
  {"season": 9, "episode": 11, "scene": 34, "line": "I saw the leaves twitch!", "order": 54044, "speaker": "Creed Bratton"},
  {"season": 9, "episode": 11, "scene": 34, "line": "Shut up!", "order": 54045, "speaker": "Angela Martin"},
  {"season": 9, "episode": 11, "scene": 34, "line": "You shut up!", "order": 54046, "speaker": "Creed Bratton"},
  {"season": 9, "episode": 11, "scene": 34, "line": "Everybody shut up and work!", "order": 54047, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 11, "scene": 34, "line": "We don't work for you!", "order": 54048, "speaker": "Phyllis Vance"},
  {"season": 9, "episode": 11, "scene": 34, "line": "Yeah!", "order": 54049, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 11, "scene": 34, "line": "Yeah!", "order": 54050, "speaker": "Angela Martin"},
  {"season": 9, "episode": 11, "scene": 34, "line": "Hey, it's five o'clock. [everyone leaves and a traffic backup occurs in the parking lot]", "order": 54051, "speaker": "Stanley Hudson"},
  {"season": 9, "episode": 11, "scene": 34, "line": "[with horns honking] Kevin, can-", "order": 54052, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 11, "scene": 34, "line": "What's going on?", "order": 54053, "speaker": "Angela Martin"},
  {"season": 9, "episode": 11, "scene": 35, "line": "Yes! We did it! [leaving the suit store]", "order": 54054, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 11, "scene": 35, "line": "You opened the door-", "order": 54055, "speaker": "Clark Green"},
  {"season": 9, "episode": 11, "scene": 35, "line": "And you closed it. The boys are back in town. [high fives Clark] High fives! Ha ha!", "order": 54056, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 11, "scene": 35, "line": "Hey, so all that really specific cat turd business, that was about you, right?", "order": 54057, "speaker": "Clark Green"},
  {"season": 9, "episode": 11, "scene": 35, "line": "You got me! [tickles Clark and chuckles] I used to collect them!", "order": 54058, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 11, "scene": 35, "line": "Why?", "order": 54059, "speaker": "Clark Green"},
  {"season": 9, "episode": 11, "scene": 35, "line": "Each one is very different, like a snowflake.", "order": 54060, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 11, "scene": 36, "line": "[shocked after seeing the torn up carpet] Hey' What happened here?", "order": 54061, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 11, "scene": 36, "line": "You left me in charge of the pens, Pam. That's what happened. The pens happened.", "order": 54062, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 11, "scene": 36, "line": "Are the pens here?", "order": 54063, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 12, "scene": 1, "line": "Gotta clear out these file cabinets people, a lot of these are dead accounts. 'Scranton Mimeograph Corp?' I don't think we're doing business with them any time soon. That's odd. A letter from Robert Dunder. 'A valuable artifact has come into my possession. I have hidden it until such time as a person of strong intellect may safely recover it. This golden chalice is of immeasurable historical and religious significance.' The Holy Grail.", "order": 54064, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 12, "scene": 2, "line": "[on phone]: Did you send Dwight on a quest for the Holy Grail?", "order": 54065, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 12, "scene": 2, "line": "I think I'm a little too busy these days to s--- [whispering] Oh ,my God. I did send Dwight on a quest for the Holy Grail.", "order": 54066, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 12, "scene": 3, "line": "The Dunder Code! I completely forgot about that prank. That had to be like six or seven years ago. Stayed late every night for a month. Had a lot more free time back then.", "order": 54067, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 12, "scene": 4, "line": "I don't get it.", "order": 54068, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 12, "scene": 4, "line": "Aha! A lightbulb.", "order": 54069, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 12, "scene": 4, "line": "A lightbul--", "order": 54070, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 12, "scene": 4, "line": "A lightbulb. Okay. Okay. [holding note over lamp] Invisible ink.", "order": 54071, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 12, "scene": 4, "line": "Whoa.", "order": 54072, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 12, "scene": 4, "line": "Higher than numbers go.' The ceiling above accounting!", "order": 54073, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 12, "scene": 4, "line": "Dwight! Down, Dwight! Dwight!", "order": 54074, "speaker": "Angela Martin"},
  {"season": 9, "episode": 12, "scene": 4, "line": "[on phone]: Man, I wish I was there to see his face when he gets to the end ...", "order": 54075, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 12, "scene": 5, "line": "...and finds the... fake grail? No grail?", "order": 54076, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 12, "scene": 6, "line": "You don't remember?", "order": 54077, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 12, "scene": 7, "line": "I don't.", "order": 54078, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 12, "scene": 8, "line": "An 'X.'", "order": 54079, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 12, "scene": 8, "line": "Annex. It must open something in the annex.", "order": 54080, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 12, "scene": 9, "line": "Oh.", "order": 54081, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 12, "scene": 9, "line": "Whoa.", "order": 54082, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 12, "scene": 9, "line": "Sedes introiti.' Seat of entrance.", "order": 54083, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 12, "scene": 10, "line": "[shouts while cutting up seat cushion - gasps upon finding playing cards]", "order": 54084, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 12, "scene": 10, "line": "What?", "order": 54085, "speaker": "Pete Miller"},
  {"season": 9, "episode": 12, "scene": 10, "line": "What?", "order": 54086, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 12, "scene": 10, "line": "That's a flush.", "order": 54087, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 12, "scene": 11, "line": "[murmuring as Dwight holds up toy forklift]", "order": 54088, "speaker": "All"},
  {"season": 9, "episode": 12, "scene": 11, "line": "The warehouse.", "order": 54089, "speaker": "All"},
  {"season": 9, "episode": 12, "scene": 12, "line": "[on phone to Jim] There's nothing down here.", "order": 54090, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 12, "scene": 13, "line": "Oh, I expected more from young Halpert.", "order": 54091, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 12, "scene": 14, "line": "Let's just forget it. Forget it. [warehouse worker dips donut into golden chalice]", "order": 54092, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 12, "scene": 15, "line": "So, how are we getting on with our grand social media initiative?", "order": 54093, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 12, "scene": 15, "line": "Well, we created a fake profile for a really cool guy named Derek McBlack.", "order": 54094, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 12, "scene": 15, "line": "Wow.", "order": 54095, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 12, "scene": 15, "line": "It's just Pete in sunglasses.", "order": 54096, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 12, "scene": 15, "line": "Oh.", "order": 54097, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 12, "scene": 15, "line": "And then we had him 'Like' Dunder Mifflin.", "order": 54098, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 12, "scene": 15, "line": "Then we created a bunch of fake friends for Derek, and we had all of them 'Like' Derek's 'Likes.'", "order": 54099, "speaker": "Pete Miller"},
  {"season": 9, "episode": 12, "scene": 15, "line": "So far, we're only popular with imaginary people, but we think this is the start of something big.", "order": 54100, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 12, "scene": 15, "line": "You two are geniuses. And I am a genius putting you two together.", "order": 54101, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 12, "scene": 15, "line": "All right.", "order": 54102, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 12, "scene": 16, "line": "Pete and I work well together -- not that there's anything special about Pete. It could be any guy... or girl-- not that I'm into girls. Not that I'm into Pete. Ugh! What was the question?", "order": 54103, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 12, "scene": 17, "line": "Hey.", "order": 54104, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 12, "scene": 17, "line": "Hey Jim. Peter Rowley at Bridgeport Capital requested a meeting at lunch.", "order": 54105, "speaker": "Co-worker"},
  {"season": 9, "episode": 12, "scene": 17, "line": "Okay. Can we keep it on the early side, though? I got that thing in Scranton later.", "order": 54106, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 12, "scene": 17, "line": "Uh sure. No problem.", "order": 54107, "speaker": "Co-worker #2"},
  {"season": 9, "episode": 12, "scene": 18, "line": "Cece's ballet recital is today. I cannot wait. I've been working with her on her move. It's called the Cece Spin and Kiss. Do you want to see it? It's kind of like this. It's pretty cute, right?", "order": 54108, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 12, "scene": 19, "line": "Pfft, 'Athlead'? Please. They're too lazy to call it Athletes Lead? Jim should just call it 'Stumpany,' for 'Stupid Company.'", "order": 54109, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 12, "scene": 19, "line": "Nothing stupid about working for yourself. Hanging out with pro athletes, getting free tickets to the games. That's why I'm doing it.", "order": 54110, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 12, "scene": 19, "line": "You're working for 'Stumpany' too?", "order": 54111, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 12, "scene": 19, "line": "Yeah, just weekends for now, but pretty soon, I'm switching over.", "order": 54112, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 12, "scene": 19, "line": "What?", "order": 54113, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 12, "scene": 19, "line": "Yep.", "order": 54114, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 12, "scene": 20, "line": "[on phone] Hey, Halpert, what's the big idea? First you jump ship. Now you're stealing Darryl too. When will it end?", "order": 54115, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 12, "scene": 20, "line": "Well, take your worst fear and multiply it by infinity.", "order": 54116, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 12, "scene": 20, "line": "You won't stop until you've poached us all.", "order": 54117, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 12, "scene": 20, "line": "Yeah. Even you.", "order": 54118, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 12, "scene": 20, "line": "No. I'll be damned if I'm gonna let us lose me.", "order": 54119, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 12, "scene": 20, "line": "Bye, Dwight.", "order": 54120, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 12, "scene": 21, "line": "Hi. How are ya? Good to see you.", "order": 54121, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 12, "scene": 22, "line": "Hey, Angela? Did you hear about the Irish-American Cultural Center mural?", "order": 54122, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 12, "scene": 22, "line": "Ooh, I haven't heard it. So, what about the Irish-American Cultural Center mural? Potato?", "order": 54123, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 12, "scene": 22, "line": "Oh, no, Kevin, it's not a joke. Angela's husband put me up for a-- um, just never mind.", "order": 54124, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 12, "scene": 22, "line": "I have no information. But I'm sure as soon as they know, they'll call you.", "order": 54125, "speaker": "Angela Martin"},
  {"season": 9, "episode": 12, "scene": 23, "line": "Senator Lipton helped me submit my design for a new mural on a building downtown. Now I'm just waiting to hear from the selection committee. But, you know, let's be realistic. There are a ton of great artists in Scranton with way more experience. I mean, who are they gonna go with -- some nobody like me or a big name like Tracy Fleeb?", "order": 54126, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 12, "scene": 24, "line": "Well, I'm heading out to Cece's dance recital.", "order": 54127, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 12, "scene": 24, "line": "Aw.", "order": 54128, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 12, "scene": 25, "line": "Hey, Cece, Daddy's gonna have dinner with us tonight after he comes to your recital. Are you excited?", "order": 54129, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 12, "scene": 25, "line": "Yeah.", "order": 54130, "speaker": "Cece"},
  {"season": 9, "episode": 12, "scene": 25, "line": "Let's call him.", "order": 54131, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 12, "scene": 25, "line": "Hey. I was just about to call you.", "order": 54132, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 12, "scene": 25, "line": "Hey, Hon, are you close?", "order": 54133, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 12, "scene": 25, "line": "I am still in Philly, actually.", "order": 54134, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 12, "scene": 25, "line": "What?", "order": 54135, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 12, "scene": 25, "line": "It's insane. This huge investor got cold feet, so I'm stuck here trying to keep him on board.", "order": 54136, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 12, "scene": 25, "line": "Hon, I wish you would have told me an hour ago, when you knew you weren't gonna make it.", "order": 54137, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 12, "scene": 25, "line": "Pam, I couldn't get out. I barely made it out just to make this phone call.", "order": 54138, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 12, "scene": 25, "line": "I want Daddy.", "order": 54139, "speaker": "Cece"},
  {"season": 9, "episode": 12, "scene": 25, "line": "You're gonna do great. And you know what? Mommy's gonna record it. So we'll watch it together. Do you mind doing that, Pam?", "order": 54140, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 12, "scene": 25, "line": "Of course.", "order": 54141, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 12, "scene": 25, "line": "Okay. Are you sure you know how to do video on the phone?", "order": 54142, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 12, "scene": 25, "line": "Yes, Jim, I think I know how to point a rectangle at something.", "order": 54143, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 12, "scene": 25, "line": "Okay, okay, you know, just sometimes you're not the best with the phone.", "order": 54144, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 12, "scene": 25, "line": "I know how to operate my phone, okay? Listen, we're getting close. We will talk to you later. Say, 'Bye, Daddy.'", "order": 54145, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 12, "scene": 25, "line": "Bye, Daddy.", "order": 54146, "speaker": "Cece"},
  {"season": 9, "episode": 12, "scene": 25, "line": "Bye. [pause] Yep. See, you've still got to press 'End,' Pam.", "order": 54147, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 12, "scene": 25, "line": "Press 'End.'", "order": 54148, "speaker": "Cece"},
  {"season": 9, "episode": 12, "scene": 26, "line": "Oh, check it out. This is our first real 'Like.'", "order": 54149, "speaker": "Pete Miller"},
  {"season": 9, "episode": 12, "scene": 26, "line": "Oh, my gosh!", "order": 54150, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 12, "scene": 26, "line": "Oh! All right, Alan Olson from North Dakota. He also likes Hammermill and Georgia Pacific.", "order": 54151, "speaker": "Pete Miller"},
  {"season": 9, "episode": 12, "scene": 26, "line": "Wow. That guy's really into paper.", "order": 54152, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 12, "scene": 26, "line": "Yeah.", "order": 54153, "speaker": "Pete Miller"},
  {"season": 9, "episode": 12, "scene": 26, "line": "Well done, you two.", "order": 54154, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 12, "scene": 26, "line": "We did it. Youth task force forever.", "order": 54155, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 12, "scene": 26, "line": "Yes.", "order": 54156, "speaker": "Pete Miller"},
  {"season": 9, "episode": 12, "scene": 26, "line": "[doing a series of hand bumps with Erin] Bap, bup, bup, bap. Wait. Did I go first and then you?", "order": 54157, "speaker": "Pete Miller"},
  {"season": 9, "episode": 12, "scene": 26, "line": "No, you go -- okay.", "order": 54158, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 12, "scene": 26, "line": "All right, all right.Okay, start over.", "order": 54159, "speaker": "Pete Miller"},
  {"season": 9, "episode": 12, "scene": 27, "line": "Oh, no, no, no, no. Oh God. Andy has just started to be nice to me, I just sent his girlfriend into the arms of a younger man. 'Our social media presence should be hot, hot, hot. Go at it vigorously.' I did everything but unzip their pants for them. Oh, God, Nellie.", "order": 54160, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 12, "scene": 28, "line": "Hey, everybody. Uh, coffee order is going around -- on me. Just sign your name below.", "order": 54161, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 12, "scene": 28, "line": "[murmuring]", "order": 54162, "speaker": "All"},
  {"season": 9, "episode": 12, "scene": 28, "line": "You're welcome.", "order": 54163, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 12, "scene": 28, "line": "Wait. This says 'Dunder Mifflin Loyalty Pledge'?", "order": 54164, "speaker": "Phyllis Vance"},
  {"season": 9, "episode": 12, "scene": 28, "line": "This -- uh, what? Double mocha latte, please? You got it. Just sign your name below. Whatever you want.", "order": 54165, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 12, "scene": 28, "line": "Loyalty pledge'?", "order": 54166, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 12, "scene": 28, "line": "Come on, Dwight, stop overreacting. I'm getting all my work done here. No customers have complained. Nobody even knows.", "order": 54167, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 12, "scene": 28, "line": "Yeah, we'll see about that.", "order": 54168, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 12, "scene": 29, "line": "Bust out your complaint files. I need everything you've got on Darryl since he started working for Jim in the last few weeks. Break it down by keyword, okay? 'Infuriating, irresponsible'...", "order": 54169, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 12, "scene": 29, "line": "They got us set up with Windows 95, so you're kind of dreaming here.", "order": 54170, "speaker": "Clark Green"},
  {"season": 9, "episode": 12, "scene": 29, "line": "Okay, I'm gonna need you to print it out.", "order": 54171, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 12, "scene": 30, "line": "[writing on white board] Customer Loyalty. What is it? Can you hold it in your hand? Can you nudge it with your finger? Can you dump it on a woman? No. Why? Because it's an idea. But what does it mean?", "order": 54172, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 12, "scene": 30, "line": "Ooh, it's when you get a free sandwich after you've already eaten ten sandwiches.", "order": 54173, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 12, "scene": 30, "line": "Not even close. Mr. Romanko has been a client for 20 years. He came in today in a rage. Why? Because of Darryl. Because Darryl was focusing on outside projects and needs to remember his first priority-- the client.", "order": 54174, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 12, "scene": 30, "line": "I wouldn't say a rage.", "order": 54175, "speaker": "Mr. Romanko"},
  {"season": 9, "episode": 12, "scene": 30, "line": "Mr. Romanko, we were a day late on one of your deliveries, but two of our trucks were down for repairs. It's very unlikely it would happen again.", "order": 54176, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 12, "scene": 30, "line": "Thank you. Thank you.", "order": 54177, "speaker": "Mr. Romanko"},
  {"season": 9, "episode": 12, "scene": 30, "line": "No, you're not going anywhere, okay? You are angry, and we are gonna hear you out, all right? Because business is about relationships, and the key to relationships is what, Darryl? L--", "order": 54178, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 12, "scene": 30, "line": "Loyalty.", "order": 54179, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 12, "scene": 30, "line": "Loyalty.", "order": 54180, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 12, "scene": 30, "line": "Loyalty is exactly right.", "order": 54181, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 12, "scene": 30, "line": "Thank you.", "order": 54182, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 12, "scene": 30, "line": "Yes. I mean, it is everything. Let's all ask ourselves, have we been faithful in our relationships?", "order": 54183, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 12, "scene": 30, "line": "That's none of your damn business.", "order": 54184, "speaker": "Stanley Hudson"},
  {"season": 9, "episode": 12, "scene": 30, "line": "Darryl is 'dating' Dunder Mifflin.", "order": 54185, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 12, "scene": 30, "line": "Darryl is dating Val... still.", "order": 54186, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 12, "scene": 30, "line": "But he's flirting with Jim's company on the side. And we all know what flirting can lead to.", "order": 54187, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 12, "scene": 30, "line": "I'm sorry. Do I still need to be here?", "order": 54188, "speaker": "Mr. Romanko"},
  {"season": 9, "episode": 12, "scene": 30, "line": "Yes.", "order": 54189, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 12, "scene": 30, "line": "I'm having a bit of trouble understanding the importance of loyalty. So let's-- let's use an example. Take Erin. Erin's boyfriend Andy, is away across the ocean.", "order": 54190, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 12, "scene": 30, "line": "Yeah.", "order": 54191, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 12, "scene": 30, "line": "So is it all right for her to flirt with Creed, for example?", "order": 54192, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 12, "scene": 30, "line": "Let's try it out.", "order": 54193, "speaker": "Creed Bratton"},
  {"season": 9, "episode": 12, "scene": 30, "line": "No, let's-- let's-- let's not say Creed. Let's say Mr. X.", "order": 54194, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 12, "scene": 30, "line": "Well, I think it would be immoral for Erin to cheat on Andy.", "order": 54195, "speaker": "Angela Martin"},
  {"season": 9, "episode": 12, "scene": 30, "line": "Oh, I'm sorry. Didn't you cheat on Andy?", "order": 54196, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 12, "scene": 30, "line": "Yes. And he didn't like it.", "order": 54197, "speaker": "Angela Martin"},
  {"season": 9, "episode": 12, "scene": 30, "line": "Does Mr. X know that Erin has a boyfriend, or did Erin keep that from Mr. X?", "order": 54198, "speaker": "Phyllis Vance"},
  {"season": 9, "episode": 12, "scene": 30, "line": "Okay, this is really hard to follow. Can we just say Pete because that's the guy that Erin's flirting with?", "order": 54199, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 12, "scene": 31, "line": "Ladies and gentlemen, the Little Ladybug Ballerinas.", "order": 54200, "speaker": "Dance Teacher"},
  {"season": 9, "episode": 12, "scene": 31, "line": "One, two, three.", "order": 54201, "speaker": "Ballerinas"},
  {"season": 9, "episode": 12, "scene": 31, "line": "Really?", "order": 54202, "speaker": "Parent in Audience"},
  {"season": 9, "episode": 12, "scene": 31, "line": "Oh, I'm sorry. Sorry.", "order": 54203, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 12, "scene": 31, "line": "One, two, three.", "order": 54204, "speaker": "Ballerinas"},
  {"season": 9, "episode": 12, "scene": 31, "line": "[whispering] Hello? Yes, this is Pam Halpert. I got the mural? [Loudly] Oh, my God!", "order": 54205, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 12, "scene": 31, "line": "Shh!", "order": 54206, "speaker": "Audience"},
  {"season": 9, "episode": 12, "scene": 31, "line": "[whispering] Um, I'm sorry. I have to go. My daughter's a ladybug. I know that doesn't make any sense. Um, thank you, thank you.", "order": 54207, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 12, "scene": 31, "line": "You're very rude.", "order": 54208, "speaker": "Parent in audience #2"},
  {"season": 9, "episode": 12, "scene": 31, "line": "Yes, very rude.", "order": 54209, "speaker": "Parent in audience #1"},
  {"season": 9, "episode": 12, "scene": 31, "line": "Shh! Kids are dancing.", "order": 54210, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 12, "scene": 32, "line": "Thank you so much for coming. Darryl and I will be here to serve you for years and years and years and years. [chuckles] So, should we go call Jim and tell him to go screw himself?", "order": 54211, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 12, "scene": 32, "line": "Dwight, look, I'm sorry you're having a hard time with this, okay? Athlead is my future. No disrespect to Dunder Mifflin, but if I can have fun at work, I want that.", "order": 54212, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 12, "scene": 32, "line": "It can't be more fun than selling paper and paper products.", "order": 54213, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 12, "scene": 32, "line": "It can.", "order": 54214, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 12, "scene": 32, "line": "Are you pretending to be crazy, huh?", "order": 54215, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 12, "scene": 33, "line": "Well, Andy's cute, but he's too vanilla, whereas Pete - he's just one sick dude. I mean, you know this guy likes to get weird.", "order": 54216, "speaker": "Phyllis Vance"},
  {"season": 9, "episode": 12, "scene": 33, "line": "I'm just gonna say this one more time. Pete and I haven't done anything.", "order": 54217, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 12, "scene": 34, "line": "Yeah right. With slammin' bods like that, they ain't playing checkers.", "order": 54218, "speaker": "Meredith Palmer"},
  {"season": 9, "episode": 12, "scene": 34, "line": "People, it's 2013. Erin is a strong, independent woman. Who says she has to end up with any man?", "order": 54219, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 12, "scene": 34, "line": "Okay, can everyone please stop speaking for me? Andy is my boyfriend. Pete and I are just friends. And that's the end of it. Right, Pete?", "order": 54220, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 12, "scene": 34, "line": "That is correct. Come on, guys, where is this even coming from?", "order": 54221, "speaker": "Pete Miller"},
  {"season": 9, "episode": 12, "scene": 34, "line": "Your feelings for Erin? Probably your heart... and a little bit your penis.", "order": 54222, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 12, "scene": 34, "line": "[groan]", "order": 54223, "speaker": "All"},
  {"season": 9, "episode": 12, "scene": 34, "line": "Nellie, you have to shut down the task force. I'm not sure if you need to start a new task force to do that, but please just shut down the task force.", "order": 54224, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 12, "scene": 34, "line": "I suppose that will-- that will be all right, yeah.", "order": 54225, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 12, "scene": 35, "line": "Most relationships eventually die on their own, but sometimes they just need a little pillow over the face. You're welcome, Andy. And you're welcome, my own ass.", "order": 54226, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 12, "scene": 36, "line": "Listen, I appreciate you taking the time with me.", "order": 54227, "speaker": "Investor"},
  {"season": 9, "episode": 12, "scene": 36, "line": "Peter, please. We completely understand your concerns here. Let us just show you the latest projections. Jim?", "order": 54228, "speaker": "Co-worker"},
  {"season": 9, "episode": 12, "scene": 36, "line": "Uh, Wade actually has those on the road, but he's gonna be calling in with those numbers any second. So just hold on one s--", "order": 54229, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 12, "scene": 36, "line": "Oh, actually... uh, nope. That is my wife. Again, he'll be calling in any second.", "order": 54230, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 12, "scene": 37, "line": "[on phone] Hey, uh, give me a call back when you get a chance. Cece did great, and I want to tell you something.", "order": 54231, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 12, "scene": 38, "line": "Hey boss, you gotta jump on a delivery.", "order": 54232, "speaker": "Lonnie"},
  {"season": 9, "episode": 12, "scene": 38, "line": "I don't do that anymore.", "order": 54233, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 12, "scene": 38, "line": "That's what they told me.", "order": 54234, "speaker": "Lonnie"},
  {"season": 9, "episode": 12, "scene": 38, "line": "[sighs]", "order": 54235, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 12, "scene": 39, "line": "All right, let's get this over with.", "order": 54236, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 12, "scene": 39, "line": "You looking for fun? It just found you! Woo! [laughs] Here we go! Yeah!", "order": 54237, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 12, "scene": 40, "line": "Yes, we will be delivering a shipment of paper today, but I will also be delivering you a big shipment of fun!", "order": 54238, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 12, "scene": 40, "line": "How far is this place? [turns off radio]", "order": 54239, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 12, "scene": 40, "line": "Uh, not far at all, but I thought along the way, we could play some sports. [throws small ball at Darryl]", "order": 54240, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 12, "scene": 40, "line": "Fun.", "order": 54241, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 12, "scene": 41, "line": "Hey, really nice job in that meeting in there.", "order": 54242, "speaker": "Toby Flenderson"},
  {"season": 9, "episode": 12, "scene": 41, "line": "Oh, thank you. I don't know, I sort of thought--", "order": 54243, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 12, "scene": 41, "line": "[hugging Nellie] So proud of you, Hon.", "order": 54244, "speaker": "Toby Flenderson"},
  {"season": 9, "episode": 12, "scene": 41, "line": "Oh, I don't know. Okay.", "order": 54245, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 12, "scene": 41, "line": "I'm just curious though, what do you have against Pete and Erin?", "order": 54246, "speaker": "Toby Flenderson"},
  {"season": 9, "episode": 12, "scene": 41, "line": "Well, Andy wrote me a really nice recommendation letter for the adoption agency, and I-- I just sort of felt I owed him one.", "order": 54247, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 12, "scene": 41, "line": "Pete's a pretty cool guy, though, and Andy was a terrible lover to Erin. He basically ignored her and left on a boat.", "order": 54248, "speaker": "Toby Flenderson"},
  {"season": 9, "episode": 12, "scene": 41, "line": "I didn't really think about it like that.", "order": 54249, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 12, "scene": 41, "line": "Yeah, well... Not everybody has what we have.", "order": 54250, "speaker": "Toby Flenderson"},
  {"season": 9, "episode": 12, "scene": 41, "line": "What we have'?", "order": 54251, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 12, "scene": 41, "line": "Mm-hmm.", "order": 54252, "speaker": "Toby Flenderson"},
  {"season": 9, "episode": 12, "scene": 42, "line": "I just remembered... I kissed that man. Oh, no. Oh, no.", "order": 54253, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 12, "scene": 43, "line": "Yes. Fast food. I'll take a burger over a gross Philly cheese-steak any day.", "order": 54254, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 12, "scene": 43, "line": "They have fast food in Philly.", "order": 54255, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 12, "scene": 43, "line": "Not like this.", "order": 54256, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 12, "scene": 43, "line": "Exactly like this.", "order": 54257, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 12, "scene": 43, "line": "And your milkshake.", "order": 54258, "speaker": "Fast Food Worker"},
  {"season": 9, "episode": 12, "scene": 43, "line": "Thank you.", "order": 54259, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 12, "scene": 43, "line": "It's 30 degrees out. You drinkin' a mildshake?", "order": 54260, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 12, "scene": 43, "line": "Nope. [throwing milkshake] Fire in the hole! [laughs] Oh, yeah! Now that's what working at a paper company's all about!", "order": 54261, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 12, "scene": 43, "line": "Wait, what you doing? No, I need the k-- We have to go.", "order": 54262, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 12, "scene": 43, "line": "You just threw a milkshake in a restaurant where they make minimum wage.", "order": 54263, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 12, "scene": 43, "line": "It's a youtube thing! Let's go. Let's go. Come on, they're coming.", "order": 54264, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 12, "scene": 44, "line": "Anyone want to see the video from Cece's recital?", "order": 54265, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 12, "scene": 44, "line": "Oh, I would love to, but I am swamped... with that [picks up piece of paper].", "order": 54266, "speaker": "Angela Martin"},
  {"season": 9, "episode": 12, "scene": 44, "line": "I'll watch it. Let's get this over with.", "order": 54267, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 12, "scene": 44, "line": "All right, you don't have to.", "order": 54268, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 12, "scene": 44, "line": "Let's get this over with, Pam.", "order": 54269, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 12, "scene": 44, "line": "Okay.", "order": 54270, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 12, "scene": 44, "line": "Oh.", "order": 54271, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 12, "scene": 44, "line": "Wait. What?", "order": 54272, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 12, "scene": 44, "line": "[recorded on phone] Excuse me, I have to get back to work.", "order": 54273, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 12, "scene": 44, "line": "Oh, no. I took a phone call in the middle of taping, and then when I went to turn it back on, I must have turned it off.", "order": 54274, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 12, "scene": 44, "line": "User error. I've heard of that happening to other people.", "order": 54275, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 12, "scene": 44, "line": "Oscar, don't rub it in. I'm sure Pam is already kicking herself for choosing a phone call over her child.", "order": 54276, "speaker": "Angela Martin"},
  {"season": 9, "episode": 12, "scene": 44, "line": "Well, it was an important phone call.", "order": 54277, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 12, "scene": 45, "line": "I haven't told anyone here about the mural yet. I want Jim to be the first to know. Whenever I tell him good news, he's always like, 'Beesly!' I love that. Only thing better than getting the job-- 'Beesly!' [chuckles]", "order": 54278, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 12, "scene": 46, "line": "Hey.", "order": 54279, "speaker": "Pete Miller"},
  {"season": 9, "episode": 12, "scene": 46, "line": "Hello, Peter.", "order": 54280, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 12, "scene": 47, "line": "[cleaning up milkshake in restaurant] It barely even feels like a prank anymore.", "order": 54281, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 12, "scene": 47, "line": "You missed a spot.", "order": 54282, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 12, "scene": 47, "line": "Fire in the hole! [throwing milkshake] Go! Go! Go! Go! [tires screech]", "order": 54283, "speaker": "Drive Thru Customer"},
  {"season": 9, "episode": 12, "scene": 47, "line": "Ha ha! Yeah, joke's on you buddy! They make you come back and clean it up!", "order": 54284, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 12, "scene": 48, "line": "I am sorry that was so awkward for you two in that meeting. But I am going to have to reassemble the youth task force.", "order": 54285, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 12, "scene": 48, "line": "Oh, no, I really don't think that's a good idea.", "order": 54286, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 12, "scene": 48, "line": "Well, that is not your call. I made the decision. You have no choice in the matter, and everybody knows it.", "order": 54287, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 12, "scene": 48, "line": "Well, okay, then.", "order": 54288, "speaker": "Pete Miller"},
  {"season": 9, "episode": 12, "scene": 48, "line": "I guess we don't have a choice.", "order": 54289, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 12, "scene": 48, "line": "Nope.", "order": 54290, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 12, "scene": 48, "line": "yeah.", "order": 54291, "speaker": "Pete Miller"},
  {"season": 9, "episode": 12, "scene": 48, "line": "Yeah.", "order": 54292, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 12, "scene": 48, "line": "Lady...you never stop surprising me.", "order": 54293, "speaker": "Toby Flenderson"},
  {"season": 9, "episode": 12, "scene": 49, "line": "[sighs]", "order": 54294, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 12, "scene": 49, "line": "[clears throat]", "order": 54295, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 12, "scene": 49, "line": "What? What was that? Did I just hear you laughing with glee?", "order": 54296, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 12, "scene": 49, "line": "No. I was clearing my throat.", "order": 54297, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 12, "scene": 50, "line": "Good night.", "order": 54298, "speaker": "Meredith Palmer"},
  {"season": 9, "episode": 12, "scene": 50, "line": "Night", "order": 54299, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 12, "scene": 50, "line": "Night", "order": 54300, "speaker": "Creed Bratton"},
  {"season": 9, "episode": 12, "scene": 50, "line": "Night, Creed.", "order": 54301, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 12, "scene": 50, "line": "Hey.", "order": 54302, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 12, "scene": 50, "line": "Hey.", "order": 54303, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 12, "scene": 50, "line": "So, how's it going?", "order": 54304, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 12, "scene": 50, "line": "We lost Bridgeport Capital. I have no idea what happened. It's like everything I did, he just wouldn't go for it.", "order": 54305, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 12, "scene": 50, "line": "I'm so sorry.", "order": 54306, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 12, "scene": 50, "line": "I have no idea where we're gonna come up with this money, and we have to work insanely hard over the next few weeks.", "order": 54307, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 12, "scene": 50, "line": "[sighs] Oh, I'm sorry. I feel like you've already been working insanely hard.", "order": 54308, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 12, "scene": 50, "line": "Can you figure out how to upload Cece's dance recital? I definitely could use a pick-me-up.", "order": 54309, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 12, "scene": 50, "line": "Um, actually, funny story -- I didn't get it. I shouldn't have been so cocky about my rectangle-holding skills after all.", "order": 54310, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 12, "scene": 50, "line": "You're not serious, are you? You didn't get any of the recital?", "order": 54311, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 12, "scene": 50, "line": "No, I got the teacher introducing them and then the applause afterwards. But not so much of the middle part.", "order": 54312, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 12, "scene": 50, "line": "Come on, Pam. Pam, I asked you if you could use the phone, and you swore that you knew how.", "order": 54313, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 12, "scene": 50, "line": "Yeah, okay, I'm sorry. I'm sure we can get a copy. A lot of people were taping it.", "order": 54314, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 12, "scene": 50, "line": "Oh, great. So we'll see somebody else's kid with Cece in the background? I mean, it's really not that hard to film a video.", "order": 54315, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 12, "scene": 50, "line": "Is there-- um... you want to ease up a little bit?", "order": 54316, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 12, "scene": 50, "line": "Look, Pam, I don't know what to tell you. I mean, what do I do? It's gone. That moment's just gone. I missed it.", "order": 54317, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 12, "scene": 50, "line": "I don't know, Jim -- maybe you should have been there.", "order": 54318, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 12, "scene": 50, "line": "You're not serious, right? I mean, how is that fair? I'm in Philly. These are my days in Philly. You've agreed to this.", "order": 54319, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 12, "scene": 50, "line": "You know what? I-- I-- I don't think you want to start a conversation with me about what's fair. Okay? This is way more intense than I ever --", "order": 54320, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 12, "scene": 50, "line": "Pam, I'm not explaining this to you-- Pam, I'm not going over this again.", "order": 54321, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 12, "scene": 50, "line": "Jim. We need you.", "order": 54322, "speaker": "Co-worker"},
  {"season": 9, "episode": 12, "scene": 50, "line": "I don't know how else to tell you, okay? I'm doing everything I can every week to bring home something...", "order": 54323, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 12, "scene": 50, "line": "I am-- I am-- I am trying to make everything perfect here, okay? So that you can have everything that you want.", "order": 54324, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 12, "scene": 50, "line": "I'm doing this just for me? Is that what I'm doing? I'm doing it just for me. If that's what you think, then this is a really sad night. But you know what? I got to go. Okay?", "order": 54325, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 12, "scene": 50, "line": "Yep.", "order": 54326, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 12, "scene": 50, "line": "We'll talk tomorrow?", "order": 54327, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 12, "scene": 50, "line": "Yep. I'll talk to you tomorrow.", "order": 54328, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 12, "scene": 50, "line": "Okay. I'll talk to you tomorrow.", "order": 54329, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 12, "scene": 50, "line": "Bye. [quietly crying, sniffling]", "order": 54330, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 12, "scene": 50, "line": "Hey, you okay?", "order": 54331, "speaker": "Brian"},
  {"season": 9, "episode": 12, "scene": 50, "line": "What am I doing wrong, Brian?", "order": 54332, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 12, "scene": 50, "line": "Nothing. You're doing the best you can.", "order": 54333, "speaker": "Brian"},
  {"season": 9, "episode": 12, "scene": 50, "line": "Brian.", "order": 54334, "speaker": "Cameraman"},
  {"season": 9, "episode": 12, "scene": 50, "line": "Give her a minute. Give her a minute.", "order": 54335, "speaker": "Brian"},
  {"season": 9, "episode": 12, "scene": 50, "line": "[crying]", "order": 54336, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 12, "scene": 50, "line": "Hey, it's just a tough situation, all right?", "order": 54337, "speaker": "Brian"},
  {"season": 9, "episode": 12, "scene": 50, "line": "It's getting tougher. I just didn't know that it was gonna be this hard.", "order": 54338, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 12, "scene": 50, "line": "Yeah. Let's turn the cameras off. Seriously, guys. Enough. Enough.", "order": 54339, "speaker": "Brian"},
  {"season": 9, "episode": 12, "scene": 50, "line": "Thank you.", "order": 54340, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 12, "scene": 51, "line": "[on video] Fire in the hole! Go! Go! Go! Go!", "order": 54341, "speaker": "Guy"},
  {"season": 9, "episode": 12, "scene": 51, "line": "[watching you tube video, laughing]", "order": 54342, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 12, "scene": 51, "line": "[on video] I nailed that guy, dude. Oh yeah.", "order": 54343, "speaker": "Guy"},
  {"season": 9, "episode": 12, "scene": 51, "line": "And replay.", "order": 54344, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 12, "scene": 51, "line": "[on video] Fire in the hole! Go! Go! Go! Go! I nailed that guy, dude. Oh, yeah.", "order": 54345, "speaker": "Guy"},
  {"season": 9, "episode": 12, "scene": 51, "line": "[sighs] I'm gonna miss the paper business.", "order": 54346, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 13, "scene": 1, "line": "Hey Brian, you got a sec?", "order": 54347, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 13, "scene": 1, "line": "Yeah, hold on a sec.", "order": 54348, "speaker": "Brian"},
  {"season": 9, "episode": 13, "scene": 1, "line": "I feel awful.", "order": 54349, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 13, "scene": 1, "line": "It's fine. It was my first slip up in nine years of miking you. [phone rings]", "order": 54350, "speaker": "Brian"},
  {"season": 9, "episode": 13, "scene": 1, "line": "[in background] Dunder Mifflin, this is Erin.", "order": 54351, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 13, "scene": 1, "line": "Well, thanks for being a good friend.", "order": 54352, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 13, "scene": 1, "line": "Sure, anytime. Uh, how about you and Jim? Everything squared away?", "order": 54353, "speaker": "Brian"},
  {"season": 9, "episode": 13, "scene": 1, "line": "Yeah, mostly.", "order": 54354, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 13, "scene": 1, "line": "Pam, phone call.", "order": 54355, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 13, "scene": 1, "line": "Um, hey, say Hi to Alyssa.", "order": 54356, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 13, "scene": 1, "line": "Will do.", "order": 54357, "speaker": "Brian"},
  {"season": 9, "episode": 13, "scene": 1, "line": "Okay. Thank you.", "order": 54358, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 13, "scene": 1, "line": "Sure.", "order": 54359, "speaker": "Brian"},
  {"season": 9, "episode": 13, "scene": 1, "line": "Hey, boom guy.", "order": 54360, "speaker": "Meredith Palmer"},
  {"season": 9, "episode": 13, "scene": 1, "line": "Oh, hey Meredith.", "order": 54361, "speaker": "Brian"},
  {"season": 9, "episode": 13, "scene": 1, "line": "When are you gonna boom me?", "order": 54362, "speaker": "Meredith Palmer"},
  {"season": 9, "episode": 13, "scene": 1, "line": "Uh, listen, they're cracking down on us talking to the subjects. It's a lame rule, but, you know, I wanna' I, I'll see you later.", "order": 54363, "speaker": "Brian"},
  {"season": 9, "episode": 13, "scene": 1, "line": "Got it.", "order": 54364, "speaker": "Meredith Palmer"},
  {"season": 9, "episode": 13, "scene": 2, "line": "Hey, so I hear you're bringing in some people to interview for the sales job?", "order": 54365, "speaker": "Clark Green"},
  {"season": 9, "episode": 13, "scene": 2, "line": "That's right, a couple of old friends. Ballers only. Must be this cool to ride.", "order": 54366, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 13, "scene": 2, "line": "Well, uh' see, you raised it.", "order": 54367, "speaker": "Clark Green"},
  {"season": 9, "episode": 13, "scene": 2, "line": "Oh, did I? Oh, yes, I did.", "order": 54368, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 13, "scene": 3, "line": "Wallace is letting me hire a junior sales associate to sit at Jim's desk while he's away in Philly. Finally I'll have someone at my desk clump who gets me. It's like, 'Really, Jim? You don't understand the difference between a slaughterhouse and a rendering plant? Uh, remind me not to lend you any dead cows or horses.' Wow.", "order": 54369, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 13, "scene": 4, "line": "You know what, man? I deserve this job.", "order": 54370, "speaker": "Clark Green"},
  {"season": 9, "episode": 13, "scene": 4, "line": "Mm-hmm.", "order": 54371, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 13, "scene": 4, "line": "I scored Stone and Son Suit Warehouse with you, and God knows, to get the Scranton White Pages with Jan, I went above and beyond' and under.", "order": 54372, "speaker": "Clark Green"},
  {"season": 9, "episode": 13, "scene": 4, "line": "You know what? You're gonna get your interview, okay? I know that you're going head-to-head against some real superstars, but you got a really good chance.", "order": 54373, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 13, "scene": 5, "line": "Clark has no chance. I mean, he's up against my buddy Rolf, for God's sake. Guy goes fishing with hand grenades. And Trevor ' he'll make you laugh so hard, you'll puke your pants.", "order": 54374, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 13, "scene": 6, "line": "This sucks, you know? You put in 12 grueling weeks at a company, and what do they do? They make you compete for a promotion, like an animal. You know, I thought this was an office, not the Thunderdome.", "order": 54375, "speaker": "Clark Green"},
  {"season": 9, "episode": 13, "scene": 7, "line": "Big changes coming to the old desk clump. No longer a Pam-Jim alliance against Dwight. Now it is Dwight and a friend axis against Pam.", "order": 54376, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 13, "scene": 7, "line": "You could've just called that an alliance too, right?", "order": 54377, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 13, "scene": 7, "line": "I chose my words very carefully.", "order": 54378, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 13, "scene": 8, "line": "Things are a little delicate with me and Pam right now. And if my working in Philly is gonna end up doubling the Dwight in her life, that's only gonna make things worse.", "order": 54379, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 13, "scene": 9, "line": "[sighs] You interviewing for the sales job too?", "order": 54380, "speaker": "Clark Green"},
  {"season": 9, "episode": 13, "scene": 9, "line": "No. You're interviewing for it. I'm getting it.", "order": 54381, "speaker": "Rolf"},
  {"season": 9, "episode": 13, "scene": 9, "line": "Well, I wouldn't be so sure about that. I mean, I've been working here 12 weeks. That's a full season of Homeland. A ton of things can happen in that amount of time, as we've seen.", "order": 54382, "speaker": "Clark Green"},
  {"season": 9, "episode": 13, "scene": 9, "line": "I'm Rolf. Rolf Ahl.", "order": 54383, "speaker": "Rolf"},
  {"season": 9, "episode": 13, "scene": 9, "line": "Rolf Ahl? Sounds kinda like Roald Dahl.", "order": 54384, "speaker": "Clark Green"},
  {"season": 9, "episode": 13, "scene": 9, "line": "Go to hell.", "order": 54385, "speaker": "Rolf"},
  {"season": 9, "episode": 13, "scene": 9, "line": "There he is.", "order": 54386, "speaker": "Clark Green"},
  {"season": 9, "episode": 13, "scene": 9, "line": "Hey. Come on, buddy. Let's do this. Sorry, Rolf goes first. You don't compare. When you're with the R-O-L-F, you're literally Rolling on the Laughing Floor. [laughs]", "order": 54387, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 13, "scene": 9, "line": "Nice.", "order": 54388, "speaker": "Rolf"},
  {"season": 9, "episode": 13, "scene": 10, "line": "Rolf is my best friend, and he is the man. Cool, calm, and collected 24/7. Just try and rattle Rolf. I dare you. Such a sweet guy.", "order": 54389, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 13, "scene": 11, "line": "When are you talking to David Wallace?", "order": 54390, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 13, "scene": 11, "line": "I'm talking to him this afternoon, but don't get your hopes up.", "order": 54391, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 13, "scene": 11, "line": "Too late. My hopes are up.", "order": 54392, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 13, "scene": 12, "line": "Last week, my company in Philly lost a big investor, so we're scrambling to find new funding. Luckily, my partners have a fix ' me asking the boss of the company I'm abandoning to give us a gigantic influx of cash. So' problem solved. Thanks, guys.", "order": 54393, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 13, "scene": 13, "line": "And this chair's gonna be yours. And this desk. Ha!", "order": 54394, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 13, "scene": 13, "line": "I'm on the phone. I'm on the phone.", "order": 54395, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 13, "scene": 13, "line": "No doy!", "order": 54396, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 13, "scene": 13, "line": "I'm just gonna call you back. Thanks.", "order": 54397, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 13, "scene": 13, "line": "I hope you like Norwegian black metal, because I don't do earbuds.", "order": 54398, "speaker": "Rolf"},
  {"season": 9, "episode": 13, "scene": 13, "line": "[laughs] No earbuds! [both laugh]", "order": 54399, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 13, "scene": 13, "line": "I'm sure he's just nervous.", "order": 54400, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 13, "scene": 13, "line": "[sighs] It's fine. It's just a seating arrangement. Doesn't matter.", "order": 54401, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 13, "scene": 14, "line": "So I've got your resume here, but it's not telling me everything.", "order": 54402, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 13, "scene": 14, "line": "Well, a lot of that information is private. How do I know you're qualified to evaluate me?", "order": 54403, "speaker": "Rolf"},
  {"season": 9, "episode": 13, "scene": 14, "line": "Well, I'm the one offering the job.", "order": 54404, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 13, "scene": 14, "line": "What are your credentials?", "order": 54405, "speaker": "Rolf"},
  {"season": 9, "episode": 13, "scene": 14, "line": "I've worked here for 12 years. I won salesman of the year. I'm an Assistant to the Regional Manager.", "order": 54406, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 13, "scene": 14, "line": "I think I've heard everything I need to hear.", "order": 54407, "speaker": "Rolf"},
  {"season": 9, "episode": 13, "scene": 14, "line": "Wait, wait, wait, no, I mean, if you need to know more, you can call David Wallace. I'm sure he'd give a reference.", "order": 54408, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 13, "scene": 14, "line": "Thank you, Dwight. I'll be in touch.", "order": 54409, "speaker": "Rolf"},
  {"season": 9, "episode": 13, "scene": 15, "line": "Well, they can't all be winners. But Trevor's next and he's a real professional. You say, 'Jump,' and he says, 'Oh who?' He loves to jump on people, that Trevor.", "order": 54410, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 13, "scene": 16, "line": "What makes you think you'd be an effective paper salesman?", "order": 54411, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 13, "scene": 16, "line": "Ooh, okay. Didn't see that one coming. Can I take a 20 on that? Maybe we can circle back around to it.", "order": 54412, "speaker": "Trevor"},
  {"season": 9, "episode": 13, "scene": 16, "line": "Well, it's a pretty basic question for a potential paper salesman.", "order": 54413, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 13, "scene": 16, "line": "Pass. Next one.", "order": 54414, "speaker": "Trevor"},
  {"season": 9, "episode": 13, "scene": 16, "line": "All right. Do you see yourself as more of a team player or a self-starter?", "order": 54415, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 13, "scene": 16, "line": "No, no and no.", "order": 54416, "speaker": "Trevor"},
  {"season": 9, "episode": 13, "scene": 16, "line": "There were only two options.", "order": 54417, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 13, "scene": 16, "line": "Checkmate. You win this one, my friend. Do you validate parking?", "order": 54418, "speaker": "Trevor"},
  {"season": 9, "episode": 13, "scene": 16, "line": "This is a bus transfer.", "order": 54419, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 13, "scene": 16, "line": "[chuckles] Nothing gets by this guy.", "order": 54420, "speaker": "Trevor"},
  {"season": 9, "episode": 13, "scene": 17, "line": "Hey Jim, do you mind if I look over these price sheets before my interview?", "order": 54421, "speaker": "Clark Green"},
  {"season": 9, "episode": 13, "scene": 17, "line": "Sure, yeah, go ahead.", "order": 54422, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 13, "scene": 17, "line": "Thanks, dude. You'you sure it's okay? 'Cause you're kinda'", "order": 54423, "speaker": "Clark Green"},
  {"season": 9, "episode": 13, "scene": 17, "line": "Yeah, absolutely. Do people like sitting next to you? You're clean, right?", "order": 54424, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 13, "scene": 17, "line": "Oh, Dove Men.", "order": 54425, "speaker": "Clark Green"},
  {"season": 9, "episode": 13, "scene": 17, "line": "Nice. Music. Do you listen to it in earbuds? You don't listen to it at all because we're at work, not a Florence and the Machine concert, so'", "order": 54426, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 13, "scene": 17, "line": "Yeah, could I just have a minute to prepare for this?", "order": 54427, "speaker": "Clark Green"},
  {"season": 9, "episode": 13, "scene": 17, "line": "Sure, yeah. Do whatever you need to do.", "order": 54428, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 13, "scene": 17, "line": "Thanks, dude.", "order": 54429, "speaker": "Clark Green"},
  {"season": 9, "episode": 13, "scene": 17, "line": "Right after you do one thing for me. I need you to breathe in my face.", "order": 54430, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 13, "scene": 17, "line": "Why?", "order": 54431, "speaker": "Clark Green"},
  {"season": 9, "episode": 13, "scene": 17, "line": "I need you to breathe in my face right now.", "order": 54432, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 13, "scene": 17, "line": "[exhales]", "order": 54433, "speaker": "Clark Green"},
  {"season": 9, "episode": 13, "scene": 17, "line": "What are we working with, peppermint or wintergreen?", "order": 54434, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 13, "scene": 17, "line": "Wintergreen.", "order": 54435, "speaker": "Clark Green"},
  {"season": 9, "episode": 13, "scene": 17, "line": "I knew it, I knew it.", "order": 54436, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 13, "scene": 17, "line": "Yeah, good nose.", "order": 54437, "speaker": "Clark Green"},
  {"season": 9, "episode": 13, "scene": 17, "line": "I looked at you coming around, and I said, 'Wintergreen.'", "order": 54438, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 13, "scene": 18, "line": "I can't hire Clark. Yeah, he looks like a Schrute, but he thinks like a Halpert and he acts like a Beesly.", "order": 54439, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 13, "scene": 19, "line": "Okay, here's one. A customer who ordered enough paper to qualify for a volume discount now wants to return half the stock. You can't rebate the sales price or credit for future purchases because you brokered the deal for a third party.", "order": 54440, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 13, "scene": 19, "line": "That's just a classic no-win situation.", "order": 54441, "speaker": "Clark Green"},
  {"season": 9, "episode": 13, "scene": 19, "line": "Thank you.", "order": 54442, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 13, "scene": 19, "line": "So I'd Kobayashi Maru it.", "order": 54443, "speaker": "Clark Green"},
  {"season": 9, "episode": 13, "scene": 19, "line": "Damn it! Perfect answer, again.", "order": 54444, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 13, "scene": 19, "line": "Yep.", "order": 54445, "speaker": "Clark Green"},
  {"season": 9, "episode": 13, "scene": 19, "line": "Think Dwight, think. You have a ream of 16-bond '", "order": 54446, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 13, "scene": 19, "line": "You know what, Dwight?", "order": 54447, "speaker": "Clark Green"},
  {"season": 9, "episode": 13, "scene": 19, "line": "And anoth'", "order": 54448, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 13, "scene": 19, "line": "This interview's over, and I get the job. I just Kobayashi Maru'd the whole process.", "order": 54449, "speaker": "Clark Green"},
  {"season": 9, "episode": 13, "scene": 19, "line": "No.", "order": 54450, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 13, "scene": 19, "line": "Yeah. Star Trek rules.", "order": 54451, "speaker": "Clark Green"},
  {"season": 9, "episode": 13, "scene": 19, "line": "It does, but still no.", "order": 54452, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 13, "scene": 19, "line": "Come on, man. I mean, did Trevor do that? Did Rolf do that?", "order": 54453, "speaker": "Clark Green"},
  {"season": 9, "episode": 13, "scene": 19, "line": "Oh, you think they're my only friends? I've got way more friends than that, and they're all better than the losers who work here.", "order": 54454, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 13, "scene": 20, "line": "This is not natural.", "order": 54455, "speaker": "Stanley Hudson"},
  {"season": 9, "episode": 13, "scene": 21, "line": "Just ' I don't wanna make assumptions based on people's physical appearances.", "order": 54456, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 13, "scene": 21, "line": "Well, of course not, but does physical appearance include smell?", "order": 54457, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 13, "scene": 21, "line": "They smell so bad.", "order": 54458, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 13, "scene": 21, "line": "If I ever get that bad, you'd tell me, right?", "order": 54459, "speaker": "Meredith Palmer"},
  {"season": 9, "episode": 13, "scene": 21, "line": "Meredith, I tell you all the time.", "order": 54460, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 13, "scene": 21, "line": "[chuckles] Walked right into that one.", "order": 54461, "speaker": "Meredith Palmer"},
  {"season": 9, "episode": 13, "scene": 22, "line": "Next up, my cousin Mose. Mose could make a great paper salesman. He's got a natural fear of paper, which would motivate him to get as much of it out of this office as possible. I've got big expectations, Mose-wise.", "order": 54462, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 13, "scene": 23, "line": "What quality would make you a good sales associate?", "order": 54463, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 13, "scene": 23, "line": "People person.", "order": 54464, "speaker": "Mose Schrute"},
  {"season": 9, "episode": 13, "scene": 23, "line": "It says here on your resume that you spent the last 15 years as a sales rep for Dow Chemical.", "order": 54465, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 13, "scene": 23, "line": "That's right.", "order": 54466, "speaker": "Mose Schrute"},
  {"season": 9, "episode": 13, "scene": 23, "line": "You know we live together, right?", "order": 54467, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 13, "scene": 23, "line": "Yes.", "order": 54468, "speaker": "Mose Schrute"},
  {"season": 9, "episode": 13, "scene": 23, "line": "And I've never seen you go to work, ever.", "order": 54469, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 13, "scene": 23, "line": "Okay.", "order": 54470, "speaker": "Mose Schrute"},
  {"season": 9, "episode": 13, "scene": 23, "line": "So why is this on your resume? [door slams]", "order": 54471, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 13, "scene": 24, "line": "So how'd you guys hear about the position?", "order": 54472, "speaker": "Clark Green"},
  {"season": 9, "episode": 13, "scene": 24, "line": "My'my mom.", "order": 54473, "speaker": "Gabor"},
  {"season": 9, "episode": 13, "scene": 24, "line": "Dwight called my house, but he didn't realize that I had already moved out, because my mom and I are quarrelling because I- I can't stay out of her stuff.", "order": 54474, "speaker": "Nate Nickerson"},
  {"season": 9, "episode": 13, "scene": 24, "line": "Dwight's my cousin, so I overheard him telling my brother Mose about the job opportunity in the shower.", "order": 54475, "speaker": "Zeke"},
  {"season": 9, "episode": 13, "scene": 24, "line": "You were in the shower or he was in the shower?", "order": 54476, "speaker": "Clark Green"},
  {"season": 9, "episode": 13, "scene": 24, "line": "Everyone was in the shower. It's a cow shower, so there's like, a ton of people in there.", "order": 54477, "speaker": "Zeke"},
  {"season": 9, "episode": 13, "scene": 24, "line": "So you guys all know Dwight already?", "order": 54478, "speaker": "Clark Green"},
  {"season": 9, "episode": 13, "scene": 24, "line": "I was his babysitter, and then we dated for a while. He was a passionate lover and the sweetest little baby.", "order": 54479, "speaker": "Melvina"},
  {"season": 9, "episode": 13, "scene": 24, "line": "I knew you looked familiar. You used to pick up Dwight from school.", "order": 54480, "speaker": "Gabor"},
  {"season": 9, "episode": 13, "scene": 24, "line": "You went to X-Men school too? [exhales]", "order": 54481, "speaker": "Melvina"},
  {"season": 9, "episode": 13, "scene": 24, "line": "X-Men school?", "order": 54482, "speaker": "Clark Green"},
  {"season": 9, "episode": 13, "scene": 25, "line": "When I was young, I spent several years at a private school where I was told I would be taught to harness my mutant abilities. Turned out it was a conman copying Charles Xavier's School for Gifted Youngsters from the X-Men comic books. Took me years to figure out that it was a con. Some people never figured it out.", "order": 54483, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 13, "scene": 26, "line": "Oh, I have a few powers. Night hearing. Dogs understand where I point. And our training included picking carrots, scrubbing tubs, sewing imitation Levi's. A lot of telemarketing.", "order": 54484, "speaker": "Gabor"},
  {"season": 9, "episode": 13, "scene": 27, "line": "I don't want to sit near any of those people for the next 20 years. Someone say something.", "order": 54485, "speaker": "Angela Martin"},
  {"season": 9, "episode": 13, "scene": 27, "line": "I said something when they were thinking of hiring Jim. Didn't work then. And now look what he's doing to us.", "order": 54486, "speaker": "Stanley Hudson"},
  {"season": 9, "episode": 13, "scene": 27, "line": "Yeah, Jim, this is all your fault.", "order": 54487, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 13, "scene": 27, "line": "How is it my fault?", "order": 54488, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 13, "scene": 27, "line": "Here's an exercise for you, Jim. Imagine there are consequences to your actions. Imagine the whole world does not revolve around this. There are others.", "order": 54489, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 13, "scene": 27, "line": "But it's Dwight who's bring in all the weirdos.", "order": 54490, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 13, "scene": 27, "line": "Yeah, but Jim, Dwight's a weirdo. We can't blame a weirdo for bringing in weirdos. We can blame a normal for creating a situation where a weirdo was allowed to bring in weirdos.", "order": 54491, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 13, "scene": 27, "line": "Hey, I'm the one who has to sit next to this weirdo when Jim's away. I'm in a position where I'm rooting for Nate, and that just feels wrong. [sighs] Forget it. I need to work on my mural. I have some pointy trees that I need to round off.", "order": 54492, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 13, "scene": 28, "line": "[on phone] Hey Jim, I thought our call was for later.", "order": 54493, "speaker": "David Wallace"},
  {"season": 9, "episode": 13, "scene": 28, "line": "Yeah, this is actually about the new sales guy. Uh, Dwight has brought in a bunch of real weirdos. And I was wondering if I could have some input'", "order": 54494, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 13, "scene": 28, "line": "[presses speaker button] This is Dwight Schrute. Who am I speaking to? And don't lie. I can tell if you're lying.", "order": 54495, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 13, "scene": 28, "line": "Hey, Dwight. It's David.", "order": 54496, "speaker": "David Wallace"},
  {"season": 9, "episode": 13, "scene": 28, "line": "David.", "order": 54497, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 13, "scene": 28, "line": "Jim says he'd like some say in the hiring process.", "order": 54498, "speaker": "David Wallace"},
  {"season": 9, "episode": 13, "scene": 28, "line": "Really? That's interesting. 'Cause I was thinking that since Jim is only here part time, he might not be as invested in the decision-making process as someone like me who's here every day. And frankly, killing it lately.", "order": 54499, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 13, "scene": 28, "line": "I was just thinking that because this person is gonna be sitting at my desk, near my wife'", "order": 54500, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 13, "scene": 28, "line": "Jim, another thing. Since we are gonna have to hire this junior sales associate to cover for you, I am going to have to pay you only for the days that you actually work.", "order": 54501, "speaker": "David Wallace"},
  {"season": 9, "episode": 13, "scene": 28, "line": "Oh. Um'", "order": 54502, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 13, "scene": 28, "line": "Yeah.", "order": 54503, "speaker": "David Wallace"},
  {"season": 9, "episode": 13, "scene": 28, "line": "Okay. I can't say that that's not fair.", "order": 54504, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 13, "scene": 28, "line": "Sounds fair to me, David.", "order": 54505, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 13, "scene": 28, "line": "And I know we have a call scheduled for later '", "order": 54506, "speaker": "David Wallace"},
  {"season": 9, "episode": 13, "scene": 28, "line": "Oh, yeah, so we'll just do that later.", "order": 54507, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 13, "scene": 28, "line": "No. Why not do it now?", "order": 54508, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 13, "scene": 28, "line": "Yeah. What's up Jim?", "order": 54509, "speaker": "David Wallace"},
  {"season": 9, "episode": 13, "scene": 28, "line": "Uh' [clears throat] Well, it's about Athlead. I'm sorry. Does Dwight have to be on this part of the phone call?", "order": 54510, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 13, "scene": 28, "line": "I'd love to be in the loop, David.", "order": 54511, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 13, "scene": 28, "line": "It's okay. Go ahead, Jim.", "order": 54512, "speaker": "David Wallace"},
  {"season": 9, "episode": 13, "scene": 28, "line": "There's a very exciting opportunity to be a core investor '", "order": 54513, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 13, "scene": 28, "line": "Okay. Jim, I'm gonna have to stop you right there.", "order": 54514, "speaker": "David Wallace"},
  {"season": 9, "episode": 13, "scene": 28, "line": "Yes, okay. Bye.", "order": 54515, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 13, "scene": 28, "line": "[whispers] I'd love to invest.", "order": 54516, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 13, "scene": 28, "line": "No, thanks.", "order": 54517, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 13, "scene": 28, "line": "I'd like to give you $100 million. [snickers] [phone ringing]", "order": 54518, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 13, "scene": 29, "line": "Why you make trees into bushes? You don't make paper from bushes.", "order": 54519, "speaker": "Hidetoshi Hasagawa"},
  {"season": 9, "episode": 13, "scene": 29, "line": "Hide, they're giving out jobs upstairs. Why don't you go up and get one?", "order": 54520, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 13, "scene": 29, "line": "Thank you.", "order": 54521, "speaker": "Hidetoshi Hasagawa"},
  {"season": 9, "episode": 13, "scene": 29, "line": "Yeah.", "order": 54522, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 13, "scene": 30, "line": "Whoops.", "order": 54523, "speaker": "TRoy Anderson"},
  {"season": 9, "episode": 13, "scene": 31, "line": "[patting Darryl's hair] It's dense. Like bread.", "order": 54524, "speaker": "Zeke"},
  {"season": 9, "episode": 13, "scene": 32, "line": "Dwight, you can't just hire someone 'cause they're your friend.", "order": 54525, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 13, "scene": 32, "line": "I'm not. These people are the best of the best. I find talent an attractive quality in a friend.", "order": 54526, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 13, "scene": 32, "line": "They're freaks, Dwight. All your friends are weirdos and freaks.", "order": 54527, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 13, "scene": 32, "line": "You know who else was a freak? Spider-man. And he was also a hero.", "order": 54528, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 13, "scene": 32, "line": "Your friends are like Spider-man, if he had gotten bitten by a spider and then got really into masturbating.", "order": 54529, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 13, "scene": 33, "line": "Man, how cool is it gonna be when I start working here? Paintball fights at lunch.", "order": 54530, "speaker": "Wolf"},
  {"season": 9, "episode": 13, "scene": 33, "line": "Mandatory paintball. Uh, wolf, please report to the parking lot for mandatory paintball at lunch.", "order": 54531, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 13, "scene": 34, "line": "Wolf is hilarious. He has executed me over 100 times at point-blank range. Half of 'em, we were on the same team. Oh my God. How I screamed. Ah, that goof.", "order": 54532, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 13, "scene": 35, "line": "Sell me this piece of paper. Watch this.", "order": 54533, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 13, "scene": 35, "line": "Do you want this paper?", "order": 54534, "speaker": "Wolf"},
  {"season": 9, "episode": 13, "scene": 35, "line": "I sure do.", "order": 54535, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 13, "scene": 35, "line": "It's not very good.", "order": 54536, "speaker": "Wolf"},
  {"season": 9, "episode": 13, "scene": 35, "line": "I will pay you whatever it takes.", "order": 54537, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 13, "scene": 35, "line": "I think I wanna keep it now. It must be pretty special if you want it so bad.", "order": 54538, "speaker": "Wolf"},
  {"season": 9, "episode": 13, "scene": 35, "line": "No, you have lots of other pieces of paper that are just like it. So here, just take my money.", "order": 54539, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 13, "scene": 35, "line": "Stop trying to get my paper buddy. Okay read my lips. It's over.", "order": 54540, "speaker": "Wolf"},
  {"season": 9, "episode": 13, "scene": 35, "line": "Okay. Good. That was great. So. Wow. You're still at the 570 number?", "order": 54541, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 13, "scene": 35, "line": "I am.", "order": 54542, "speaker": "Wolf"},
  {"season": 9, "episode": 13, "scene": 35, "line": "Okay. Good, good, good. [sighs]", "order": 54543, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 13, "scene": 36, "line": "I have eight years experience selling electronics in Sanyo store in downtown Tokyo. I was a doctor. And I have a business degree from Tokyo University.", "order": 54544, "speaker": "Hidetoshi Hasagawa"},
  {"season": 9, "episode": 13, "scene": 36, "line": "This isn't gonna work out.", "order": 54545, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 13, "scene": 36, "line": "Thank you. [chuckles]", "order": 54546, "speaker": "Hidetoshi Hasagawa"},
  {"season": 9, "episode": 13, "scene": 37, "line": "Nate is a proven entity, but not without his handicaps; hearing, vision, basic cognition. Trevor is great, but I saw no fire in him today. And this is a guy who loves to start fires. Troy is literally one of a kind. He's a goblin, or a hobbit, or a kobold, which is a type of gremlin. And yet I'm hesitant. Why can't I pull the trigger on any of them?", "order": 54547, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 13, "scene": 38, "line": "[groaning] No, no, no. I just need to tell them. I just need to tell them. [knock at door]", "order": 54548, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 13, "scene": 38, "line": "Do you need to be changed?", "order": 54549, "speaker": "Melvina"},
  {"season": 9, "episode": 13, "scene": 38, "line": "I do that myself now.", "order": 54550, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 13, "scene": 38, "line": "Mm. Are you going to make a decision soon? I've been double-parked for five hours. I'm wondering if I should move my car.", "order": 54551, "speaker": "Melvina"},
  {"season": 9, "episode": 13, "scene": 39, "line": "No, you've been towed by now. They tow after about 45 minutes.", "order": 54552, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 13, "scene": 39, "line": "Well, the joke's on them. I live right next to the tow yard. All they did was save me some gas.", "order": 54553, "speaker": "Melvina"},
  {"season": 9, "episode": 13, "scene": 39, "line": "Hey man, we get how difficult this is. And no matter how you choose, we're still gonna be your friend.", "order": 54554, "speaker": "Trevor"},
  {"season": 9, "episode": 13, "scene": 39, "line": "Yeah, whether it's me or Troy Underbridge, or Gabor, or Melvina'", "order": 54555, "speaker": "Wolf"},
  {"season": 9, "episode": 13, "scene": 39, "line": "Or none of you [chuckles]", "order": 54556, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 13, "scene": 39, "line": "Yeah, you'd bring us all down here, put us through the wringer and then choose none of us.", "order": 54557, "speaker": "Wolf"},
  {"season": 9, "episode": 13, "scene": 39, "line": "Can you imagine how insulting that would be? The contempt that a person like that would have to have for you.", "order": 54558, "speaker": "Trevor"},
  {"season": 9, "episode": 13, "scene": 39, "line": "I wish I could hire all of you.", "order": 54559, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 13, "scene": 39, "line": "I could start Monday.", "order": 54560, "speaker": "Zeke"},
  {"season": 9, "episode": 13, "scene": 40, "line": "Psst. Jim Jim? [whispering] Jim, turn around. Jim, turn around. Jim, turn around.", "order": 54561, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 13, "scene": 40, "line": "Ahh, I love staring off in one direction. If I'm not looking south, I'm not livin'. That's what I always say.", "order": 54562, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 13, "scene": 40, "line": "Just act natural. [grunts]", "order": 54563, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 13, "scene": 41, "line": "And I was thinking it's only fair that you help make this decision since they'll be sitting at your desk next to your wife.", "order": 54564, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 13, "scene": 41, "line": "But you know I wouldn't hire any of these all-stars.", "order": 54565, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 13, "scene": 41, "line": "Aah! God, that sucks! Aah! What are you gonna do? I mean, it's your call.", "order": 54566, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 13, "scene": 41, "line": "Nope. Your friends not turning out to be as great as you thought? Not even Gabor?", "order": 54567, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 13, "scene": 41, "line": "I guess I just have higher standards for my work colleagues than for my friends. I just couldn't picture any of them in the old gold and gray.", "order": 54568, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 13, "scene": 41, "line": "I knew it. You designed a uniform for Dunder Mifflin.", "order": 54569, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 13, "scene": 42, "line": "Summer. Winter. Jungle. Formal.", "order": 54570, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 13, "scene": 43, "line": "Well, I for one, was amazed at how qualified everyone was. You?", "order": 54571, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 13, "scene": 43, "line": "Yes. Thank you. Amazed.", "order": 54572, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 13, "scene": 43, "line": "And I gotta say, this was a tough decision. And we had to go with none of you.", "order": 54573, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 13, "scene": 43, "line": "I'm sorry?", "order": 54574, "speaker": "Sensei Ira"},
  {"season": 9, "episode": 13, "scene": 43, "line": "What? This is such bullcrap!", "order": 54575, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 13, "scene": 43, "line": "Well, you know, Wallace put me in charge, so you have no say.", "order": 54576, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 13, "scene": 43, "line": "Wow. So much crap. It's just a load of B.C. How could you do this to them?", "order": 54577, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 13, "scene": 43, "line": "Too much now.", "order": 54578, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 13, "scene": 43, "line": "Okay.", "order": 54579, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 13, "scene": 43, "line": "Do we get our resumes back or do you keep them? Because I only have the one, and I have a chili recipe on the back that I really wanna keep.", "order": 54580, "speaker": "Nate Nickerson"},
  {"season": 9, "episode": 13, "scene": 43, "line": "Okay, this is an outrage. Ugh! You know what? This is Jim Halpert's home address, in case you guys wanna toilet paper his house or whatever.", "order": 54581, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 13, "scene": 43, "line": "That seems inconsiderate.", "order": 54582, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 13, "scene": 43, "line": "No. We get it. Thanks so much for the opportunity, Dwight. Don't open any suspicious packages you may receive. No, wait. Do open them. Totally safe.", "order": 54583, "speaker": "Rolf"},
  {"season": 9, "episode": 13, "scene": 43, "line": "Guys, it wasn't up to me. Rolf, come on. Guys, it wasn't my choice! I would have hired all of you! Gabor, Gabor.", "order": 54584, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 13, "scene": 43, "line": "Well, my day's shot.", "order": 54585, "speaker": "Trevor"},
  {"season": 9, "episode": 13, "scene": 43, "line": "Yeah, it's that weird hour where it's too late to start a slow roast and too early for a Swanson's.", "order": 54586, "speaker": "Rolf"},
  {"season": 9, "episode": 13, "scene": 43, "line": "I got it. Paintball.", "order": 54587, "speaker": "Wolf"},
  {"season": 9, "episode": 13, "scene": 43, "line": "Oh, that sounds awesome. Can we wait till I get off work?", "order": 54588, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 13, "scene": 43, "line": "[yelling] And what are we supposed to do until then?", "order": 54589, "speaker": "Trevor"},
  {"season": 9, "episode": 13, "scene": 43, "line": "Okay.", "order": 54590, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 13, "scene": 43, "line": "Let's just go, you guys.", "order": 54591, "speaker": "Wolf"},
  {"season": 9, "episode": 13, "scene": 43, "line": "Yeah. No limit on weapons class, right?", "order": 54592, "speaker": "Rolf"},
  {"season": 9, "episode": 13, "scene": 43, "line": "All right. I think that went well.", "order": 54593, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 13, "scene": 44, "line": "So, uh, if you just take a look at this, and then that'll print out'Hey. Meet your new desk mate.", "order": 54594, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 13, "scene": 44, "line": "What's up good lookin'?", "order": 54595, "speaker": "Clark Green"},
  {"season": 9, "episode": 13, "scene": 44, "line": "Oh, cool. Hey Clark.", "order": 54596, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 13, "scene": 44, "line": "Trust me, this is the least of all evils. It took me all day to pull this off, so you should be thrilled, considering.", "order": 54597, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 13, "scene": 44, "line": "Yeah, I guess. I mean, I kinda liked my old desk mate.", "order": 54598, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 13, "scene": 44, "line": "Okay. I'm really sorry I told the guys I'd be there for the board meeting.", "order": 54599, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 13, "scene": 44, "line": "Of course.", "order": 54600, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 13, "scene": 44, "line": "I'll call you when I get there.", "order": 54601, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 13, "scene": 44, "line": "Okay.", "order": 54602, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 13, "scene": 44, "line": "All right.", "order": 54603, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 13, "scene": 44, "line": "Bye.", "order": 54604, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 13, "scene": 44, "line": "Take care of my wife. I will be back.", "order": 54605, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 13, "scene": 45, "line": "They say that everyone outgrows their friends at some point in their lives. Well I just outgrew them all in the span of three hours.", "order": 54606, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 13, "scene": 46, "line": "Hey, Pam, I'm going to the kitchen. You want anything?", "order": 54607, "speaker": "Clark Green"},
  {"season": 9, "episode": 13, "scene": 46, "line": "I'm good.", "order": 54608, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 13, "scene": 46, "line": "Oh, hey, I'll take a coffee.", "order": 54609, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 13, "scene": 46, "line": "Oh, I'm sorry. You gotta be this cool for coffee.", "order": 54610, "speaker": "Clark Green"},
  {"season": 9, "episode": 13, "scene": 46, "line": "[scoffs]", "order": 54611, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 13, "scene": 46, "line": "Hey, Dwight. Wanna haze the new guy?", "order": 54612, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 13, "scene": 46, "line": "Who, me?", "order": 54613, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 13, "scene": 46, "line": "Us.", "order": 54614, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 13, "scene": 46, "line": "Absolutely, I do. [giggles]", "order": 54615, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 13, "scene": 46, "line": "Okay. Okay, here. Okay, so the next time he goes to the bathroom, I'll distract him, you take that.", "order": 54616, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 13, "scene": 46, "line": "Okay, yeah, I know what to do. Okay. Oh, that's great.", "order": 54617, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 13, "scene": 46, "line": "[shushes]", "order": 54618, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 13, "scene": 46, "line": "[laughs]", "order": 54619, "speaker": "Pam Halpert & Dwight Schrute"},
  {"season": 9, "episode": 13, "scene": 46, "line": "Aah!", "order": 54620, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 13, "scene": 46, "line": "No!", "order": 54621, "speaker": "Clark Green"},
  {"season": 9, "episode": 13, "scene": 46, "line": "Welcome to the club, pig! [laughs]", "order": 54622, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 13, "scene": 46, "line": "No, Dwight!", "order": 54623, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 13, "scene": 46, "line": "Aah!", "order": 54624, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 13, "scene": 47, "line": "No, it does matter who ends up sitting next to Pam when I'm gone. The people around you are basically who you end up spending your life with. I mean, because of where my desk was, I spent all those years looking at Pam, and I fell in love. So, that stuff matters. Definitely does.", "order": 54625, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 14, "scene": 1, "line": "Have you seen Darryl?", "order": 54626, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 14, "scene": 1, "line": "He's around here somewhere.", "order": 54627, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 14, "scene": 1, "line": "Mmm. [exits, Erin smiles deviously]", "order": 54628, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 14, "scene": 2, "line": "Yeah, Darryl's here. So is Santa Claus. It's just a regular Thursday. [checks for anyone around] Neither guy is here. And, it's Friday. Welcome to me and Darryl's world of lies.", "order": 54629, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 14, "scene": 3, "line": "Nobody knows it yet, but Darryl already started working in Philly. So now, he has to sneak out of here like the sneakiest little sneaky-sneak you ever saw. And I'm his helper. It's so fun.", "order": 54630, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 14, "scene": 4, "line": "[as Darryl descends stairs, Erin ascends carrying a giant teddy bear] Darryl. Meet... bear... ull.", "order": 54631, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 14, "scene": 4, "line": "How much did you pay for that?", "order": 54632, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 14, "scene": 4, "line": "Nothing. Won him at the carnival. Spent a ton on tickets, though. Drive safe.", "order": 54633, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 14, "scene": 5, "line": "Everybody knows I go to Philly. I've just been using sick days I saved up. But, Erin was so excited about being sneaky-sneaks, I went along with it.", "order": 54634, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 14, "scene": 6, "line": "[exiting Darryl's office] Ha, ha, ha! Darryl, you are too much! [to Phyllis] That guy's hilarious. He's here today. [giant teddy bear is viewed in Darryl's office]", "order": 54635, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 14, "scene": 7, "line": "I've really been putting in the hours on this mural. And my boss is totally OK with it, because he's in the Bahamas and has no clue what anybody is doing. I'm usually very self-critical. I hate what I paint. But, I don't know, this time I feel like it's, um, it's really coming together... [sees mural] Oh my god! [camera pans to mural with butts painted over it] Wha? You've gotta be kidding me! What it... are those... are those butts? [to warehouse crew] Huh? No way. No way!", "order": 54636, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 14, "scene": 8, "line": "Excuse me, everyone. Is it OK if I leave early from work today? It's Phillip's first birthday and the senator and I are hosting a party at our house.", "order": 54637, "speaker": "Angela Martin"},
  {"season": 9, "episode": 14, "scene": 8, "line": "Aw, cute! So there'll be a bunch of kids.", "order": 54638, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 14, "scene": 8, "line": "No. No children. Our house is not kid-friendly. Most of our furniture is sharp. Also, ew. It will mostly be campaign donors.", "order": 54639, "speaker": "Angela Martin"},
  {"season": 9, "episode": 14, "scene": 8, "line": "Angela, I am prepared to donate a whopping... [pulls cash from wallet] 8 dollars to Lipton For America to have an invitation...", "order": 54640, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 14, "scene": 8, "line": "No, no, please. You know, actually, none of you could even really make the cut for this thing. Which I am so sad about.", "order": 54641, "speaker": "Angela Martin"},
  {"season": 9, "episode": 14, "scene": 8, "line": "[undertone] Angela. You're going to find out, so I thought I'd let you know that, uh, Robert invited me too.", "order": 54642, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 14, "scene": 8, "line": "What?", "order": 54643, "speaker": "Angela Martin"},
  {"season": 9, "episode": 14, "scene": 8, "line": "He said he wanted me there for support.", "order": 54644, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 14, "scene": 8, "line": "I'm his! His... wife.", "order": 54645, "speaker": "Angela Martin"},
  {"season": 9, "episode": 14, "scene": 9, "line": "Angela's husband and I are in love. But, as a politician in this town, you still need the conventional blonde wife on your lawn signs. He is risking everything to have me there today. [laughs] Me.", "order": 54646, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 14, "scene": 10, "line": "[on phone with Robert] We agreed that you wouldn't be seen with him in public. It's humiliating for me. Well, if you get to bring a stud, maybe I do too.", "order": 54647, "speaker": "Angela Martin"},
  {"season": 9, "episode": 14, "scene": 11, "line": "[in Darryl and Jim's Philly apartment] Hey.", "order": 54648, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 14, "scene": 11, "line": "Hey.", "order": 54649, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 14, "scene": 12, "line": "We are splitting a sublet on a place near Philly.", "order": 54650, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 14, "scene": 12, "line": "Just a couple of grown, sexy-ass roommates.", "order": 54651, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 14, "scene": 12, "line": "And, as much as I miss Pam and the kids, it's, uh, kinda nice to live the bachelor life again. You know, let your hair down.", "order": 54652, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 14, "scene": 12, "line": "Jim was nice enough to give me his bedroom.", "order": 54653, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 14, "scene": 12, "line": "I'm couching it!", "order": 54654, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 14, "scene": 12, "line": "Which usually means there's clothes all over the living room.", "order": 54655, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 14, "scene": 12, "line": "And this dude labels his food. He's the clean one. And, I'm the messy one. How much fun is this?", "order": 54656, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 14, "scene": 12, "line": "I love Jim. I love that he hooked me up with a job. It's just, he uses old t-shirts as wash rags. He doesn't wash his dishes. Apparently, they need to [quote-unquote fingers] soak... He hooked me up with a job.", "order": 54657, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 14, "scene": 13, "line": "[ascends on lift, using megaphone] Attention, everyone. Can I have your attention, please? Yeah, I don't know everybody's name down here, but whoever did this will you please raise your hand. [no one replies] Hello? This is not over. OK? I will stay up here all day if I have to. Is that what you want? [descends on lift] Yeah, I will also come down if I want to. It's my choice.", "order": 54658, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 14, "scene": 14, "line": "I don't demand justice often. I'm not like Angela, who calls a lawyer every time someone watches a YouTube video of animals doing it. But, someone should get fired over this, right? Val's no help. Andy's gone. Jim's out. I just feel like I'm on my own here. [boom microphone bumps her head, she smiles] I mean, OK, not completely on my own. But, in terms of people who can do something. Thank you, Brian.", "order": 54659, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 14, "scene": 15, "line": "Hey. So, Wade wants to send people to the Sloan conference. We gotta compile a list of our target clients.", "order": 54660, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 14, "scene": 15, "line": "Already on it. I ordered them by their Google trend ranking so we know who to hit first.", "order": 54661, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 14, "scene": 15, "line": "[to camera] Who is this guy? We are killing it.", "order": 54662, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 14, "scene": 15, "line": "Yes sir. [notices Jim's using his coffee mug]", "order": 54663, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 14, "scene": 15, "line": "Yeah!", "order": 54664, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 14, "scene": 16, "line": "[entering office] Conference room. Everybody. Now!", "order": 54665, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 14, "scene": 16, "line": "You don't have the clearance to call a conference room meeting.", "order": 54666, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 14, "scene": 16, "line": "Yes, but David Wallace does. And he asked me to gather everyone to talk about stuff... That's gonna be revealed once we're in the conference room for the meeting.", "order": 54667, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 14, "scene": 16, "line": "You're telling me, David Wallace asked you to call a super secret, classified conference room meeting?", "order": 54668, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 14, "scene": 16, "line": "Yeah.", "order": 54669, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 14, "scene": 16, "line": "Let's go everyone. Super secret, classified conference room meeting, now!", "order": 54670, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 14, "scene": 16, "line": "[to everyone in conference room] I have terrible news. Someone defaced my mural. They painted all over it.", "order": 54671, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 14, "scene": 16, "line": "I thought that's what you were doing.", "order": 54672, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 14, "scene": 16, "line": "Yeah, but this is different.", "order": 54673, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 14, "scene": 16, "line": "Oh. They used worse paint than your paint?", "order": 54674, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 14, "scene": 16, "line": "I don't think so, but they put paint where I didn't want paint. So...", "order": 54675, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 14, "scene": 16, "line": "I thought you wanted paint on the whole thing.", "order": 54676, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 14, "scene": 16, "line": "Different colored paint. I wanted different colored paint in the spots where they put their paint. So, it just is... OK, the point is, these warehouse guys are vandals. And, they need to be stopped.", "order": 54677, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 14, "scene": 16, "line": "Yeah, and somebody had the balls to put my phone number on the men's room wall. Which is so messed up. It's 6782 not 83.", "order": 54678, "speaker": "Meredith Palmer"},
  {"season": 9, "episode": 14, "scene": 16, "line": "Uh, 6783's also a good time. Less mileage.", "order": 54679, "speaker": "Creed Bratton"},
  {"season": 9, "episode": 14, "scene": 16, "line": "Pam, what can be done?", "order": 54680, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 14, "scene": 16, "line": "Yes. Thank you. Let's answer that question.", "order": 54681, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 14, "scene": 16, "line": "I was politely saying nothing can be done. I thought I was clear.", "order": 54682, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 14, "scene": 16, "line": "What? Come on guys. We need to figure out who did this and punish them. This isn't just about me. This is about all of us. This is our mural. Don't you see? How much we worked on this? How much time and energy? We put our heart and soul into this thing.", "order": 54683, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 14, "scene": 16, "line": "David Wallace called this meeting?", "order": 54684, "speaker": "Phyllis Vance"},
  {"season": 9, "episode": 14, "scene": 16, "line": "Sure did. I was as surprised as you, but apparently, he is very passionate about public art.", "order": 54685, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 14, "scene": 16, "line": "[as everyone begin to leave] No, no. Come on, guys. Don't go.", "order": 54686, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 14, "scene": 16, "line": "Pam, I'll help you.", "order": 54687, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 14, "scene": 16, "line": "You will?", "order": 54688, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 14, "scene": 16, "line": "If there's anything I hate worse than art, it's crime.", "order": 54689, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 14, "scene": 16, "line": "Thank you.", "order": 54690, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 14, "scene": 16, "line": "I am in too, Pam.", "order": 54691, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 14, "scene": 16, "line": "Yeah?", "order": 54692, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 14, "scene": 16, "line": "Yes, of course. I believe in you. I believe in your art. And I am bored.", "order": 54693, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 14, "scene": 16, "line": "Great.", "order": 54694, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 14, "scene": 17, "line": "I was hoping for a righteous mob, and I ended up with Dwight and Nellie. But, they both have a mob mentality. And, I'm pretty sure Dwight has a pitchfork in his car.", "order": 54695, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 14, "scene": 17, "line": "[enters quickly] You need my pitchfork?", "order": 54696, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 14, "scene": 18, "line": "[approaches Dwight as he leaves] Hey!", "order": 54697, "speaker": "Angela Martin"},
  {"season": 9, "episode": 14, "scene": 18, "line": "What is it? I have vengeance to exact.", "order": 54698, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 14, "scene": 18, "line": "Exciting news. There's room for one more at my son's birthday event. And, I want you.", "order": 54699, "speaker": "Angela Martin"},
  {"season": 9, "episode": 14, "scene": 18, "line": "Ah, I'm not interested.", "order": 54700, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 14, "scene": 18, "line": "What? Wait. The state transportation secretary will be there. You could sell your beet salt idea to the highway people.", "order": 54701, "speaker": "Angela Martin"},
  {"season": 9, "episode": 14, "scene": 18, "line": "If I get the deicing gig, it's not gonna be on merit. Not because I played politics. [exits]", "order": 54702, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 14, "scene": 18, "line": "Hey. So, a little birdie just told me that Dwight can't come to your son's birthday. So, do you want me to go with you?", "order": 54703, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 14, "scene": 18, "line": "No.", "order": 54704, "speaker": "Angela Martin"},
  {"season": 9, "episode": 14, "scene": 18, "line": "OK. I understand. I'll just stay here, then. Alone with the money.", "order": 54705, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 14, "scene": 18, "line": "Aw, crap.", "order": 54706, "speaker": "Angela Martin"},
  {"season": 9, "episode": 14, "scene": 19, "line": "[in warehouse] I am handing out pieces of paper. On which, you will draw one human butt. What I do with said drawings, is no one's business but my own.", "order": 54707, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 14, "scene": 19, "line": "You're gonna to compare 'um to the butts up there. [gestures mural]", "order": 54708, "speaker": "Frank"},
  {"season": 9, "episode": 14, "scene": 19, "line": "Incorrect. It is my fetish. Oh, also, sign them. My fetish is signed drawings of butts.", "order": 54709, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 14, "scene": 19, "line": "I'm not drawing a butt.", "order": 54710, "speaker": "Glenn"},
  {"season": 9, "episode": 14, "scene": 19, "line": "All right then, they're bottoms. Uh, we should ask you to do big, rounded Ws.", "order": 54711, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 14, "scene": 19, "line": "Yes. Or nipplous breasts. Perhaps.", "order": 54712, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 14, "scene": 19, "line": "OK, OK. I think it's time to get back to work. Come on, fellas. Let's go.", "order": 54713, "speaker": "Val Johnson"},
  {"season": 9, "episode": 14, "scene": 19, "line": "Or melons. Like cantaloups. With the halves are cut off. And then, just the bottom parts...", "order": 54714, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 14, "scene": 19, "line": "Well, this is getting us nowhere.", "order": 54715, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 14, "scene": 19, "line": "We need another approach. Um, we need to fine the weakest one and separate him from the group. [they notice Nate struggling with a box] Yeah. I think if we could get Nate alone, we could crack him.", "order": 54716, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 14, "scene": 19, "line": "We just need a pretense to talk to him. We could tell him that his mother is dying. That usually works on him. [holds up phone] Nate. Your mother is dying. [Nate reacts with grief]", "order": 54717, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 14, "scene": 19, "line": "See, I feel bad about that.", "order": 54718, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 14, "scene": 19, "line": "It's all right. It's all right.", "order": 54719, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 14, "scene": 20, "line": "So, she's gonna pull through again? [all nod] That's great. Can I talk to her?", "order": 54720, "speaker": "Nate Nickerson"},
  {"season": 9, "episode": 14, "scene": 20, "line": "No. She needs her rest again.", "order": 54721, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 14, "scene": 20, "line": "Now listen. Now that we have got you here, let's talk about this mural business.", "order": 54722, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 14, "scene": 20, "line": "You know who the vandal is. Now, I know a lot of these warehouse guys are your friends, but we've got chewing gum.", "order": 54723, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 14, "scene": 20, "line": "Gum's gotten mintier lately. Have you noticed? Like, some of it's just too minty. It's like they're literally trying to hurt...", "order": 54724, "speaker": "Nate Nickerson"},
  {"season": 9, "episode": 14, "scene": 20, "line": "[interrupting] Tell us who defaced the mural!", "order": 54725, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 14, "scene": 20, "line": "[points at Warehouse Worker Frank] He did it.", "order": 54726, "speaker": "Nate Nickerson"},
  {"season": 9, "episode": 14, "scene": 20, "line": "All right. You can go. Give him his gum.", "order": 54727, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 14, "scene": 20, "line": "There's no gum. There never was any gum.", "order": 54728, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 14, "scene": 20, "line": "[leaving] That's really rude.", "order": 54729, "speaker": "Nate Nickerson"},
  {"season": 9, "episode": 14, "scene": 21, "line": "[at Phillip's party] Opening with pub pastries? That's a bold play. They're saying, it's only gonna get better from here? Good luck.", "order": 54730, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 14, "scene": 21, "line": "[noticing a portrait of Angela on the wall] That painting is just... How can anyone that weighs less than a guinea hen be so terrifying?", "order": 54731, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 14, "scene": 21, "line": "Ladies and gentlemen, Senator and Angela Lipton. [guests applaud]", "order": 54732, "speaker": "Party Announcer"},
  {"season": 9, "episode": 14, "scene": 21, "line": "Thanks, everybody. Thanks for coming. Phillip had no idea he was so popular.", "order": 54733, "speaker": "Senator"},
  {"season": 9, "episode": 14, "scene": 21, "line": "[laughs] Oh, Robert, you're horrible. [to other guests] Simon, Maxine. [sarcastically] Who let you guys in here?", "order": 54734, "speaker": "Angela Martin"},
  {"season": 9, "episode": 14, "scene": 22, "line": "[points to Jim and Darryl] This is the team.", "order": 54735, "speaker": "Athlead Employee"},
  {"season": 9, "episode": 14, "scene": 22, "line": "Nice job, man.", "order": 54736, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 14, "scene": 22, "line": "You too... Darryl.", "order": 54737, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 14, "scene": 22, "line": "[confused] What? [Darryl points at his thermos Jim has been using] Oh, man! I'm sorry about that.", "order": 54738, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 14, "scene": 22, "line": "It's cool. Reading's tricky sometimes.", "order": 54739, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 14, "scene": 22, "line": "Oh, um... Are you really mad about this?", "order": 54740, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 14, "scene": 22, "line": "That's my go-to thermos. That's all.", "order": 54741, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 14, "scene": 22, "line": "Oh. It's your go-to therm. Oh, man. That's a bummer. I'm sorry about that.", "order": 54742, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 14, "scene": 22, "line": "No big deal. No big deal.", "order": 54743, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 14, "scene": 22, "line": "No, no. If it's a big deal, it's a big deal.", "order": 54744, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 14, "scene": 22, "line": "Nah, nah...", "order": 54745, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 14, "scene": 22, "line": "No big deal.", "order": 54746, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 14, "scene": 22, "line": "[as Jim empties thermos into trash] Nah, nah, nah. You don't have to do that.", "order": 54747, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 14, "scene": 22, "line": "Honestly, I don't mind. [long, awkward pause until thermos is empty] Did you want me to wash it for you or...", "order": 54748, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 14, "scene": 22, "line": "I don't know. You gonna wash it? Or you gonna let it soak?", "order": 54749, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 14, "scene": 22, "line": "[after tense pause] OK. Here ya go. [roughly hands Darryl his thermos]", "order": 54750, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 14, "scene": 22, "line": "Thank you.", "order": 54751, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 14, "scene": 23, "line": "So, Frank. Do you have any thoughts about what was done? By you?", "order": 54752, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 14, "scene": 23, "line": "[to Nellie] Maybe, maybe I could, could get the ball rolling. [Nellie nods] Um, Frank? Hi. Pam. Um, I am so sorry if I've done anything to offend you. I'm sure it isn't easy for you guys to have an upstairs person coming down in your space. So. Truly. If, if, I apologize. But enough about me. Your turn.", "order": 54753, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 14, "scene": 23, "line": "[Warehouse Worker Frank remains silent, picks ear] You sort of deserve an apology here. Frank?", "order": 54754, "speaker": "Toby Flenderson"},
  {"season": 9, "episode": 14, "scene": 23, "line": "I'm sorry I didn't like your crappy doodles. I drew a butt. Big deal. Butts are funny.", "order": 54755, "speaker": "Frank"},
  {"season": 9, "episode": 14, "scene": 23, "line": "Well, I didn't think that butt was funny.", "order": 54756, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 14, "scene": 23, "line": "Well, maybe if you got the stick out of yours.", "order": 54757, "speaker": "Frank"},
  {"season": 9, "episode": 14, "scene": 23, "line": "What was that?", "order": 54758, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 14, "scene": 23, "line": "You know what? You people can't fire me. So, screw you. [exits]", "order": 54759, "speaker": "Frank"},
  {"season": 9, "episode": 14, "scene": 23, "line": "Whoa! Hey! That is not OK. What are you gonna do about that?", "order": 54760, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 14, "scene": 23, "line": "Uh... it... compliment...", "order": 54761, "speaker": "Toby Flenderson"},
  {"season": 9, "episode": 14, "scene": 23, "line": "The first 'sorry' sounded sincere.", "order": 54762, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 14, "scene": 23, "line": "There were two or three 'sorry's in there. [mumbles along with Nellie]", "order": 54763, "speaker": "Toby Flenderson"},
  {"season": 9, "episode": 14, "scene": 24, "line": "That sucked. He didn't apologize. There's no talking to that guy.", "order": 54764, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 14, "scene": 24, "line": "Oh, your little feelings party didn't work out? Huh? Who won the hugging contest? Oh, let me guess. Everyone tied for first.", "order": 54765, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 14, "scene": 24, "line": "We should just take him down.", "order": 54766, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 14, "scene": 24, "line": "Wait. Are you saying...", "order": 54767, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 14, "scene": 24, "line": "I'm saying we should go scorched earth on that guy's face.", "order": 54768, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 14, "scene": 25, "line": "Normally, I find Pam to be a comforting, if unarousing, presence around the office. Like a well-watered fern. But, today, she has tapped into this vengeful, violent side. And I'm like, wow, Pam has kind of a good butt.", "order": 54769, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 14, "scene": 26, "line": "He messed with something that was important to you. We need to mess with something that's important to him.", "order": 54770, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 14, "scene": 26, "line": "Yes!", "order": 54771, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 14, "scene": 26, "line": "A little eye for an eye action.", "order": 54772, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 14, "scene": 26, "line": "Yes!", "order": 54773, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 14, "scene": 26, "line": "Go all Hammurabi on this clown.", "order": 54774, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 14, "scene": 26, "line": "We need an infiltrator.", "order": 54775, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 14, "scene": 26, "line": "I know just the man for the job. [looks at Clark]", "order": 54776, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 14, "scene": 26, "line": "Clark?", "order": 54777, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 14, "scene": 26, "line": "He even looks like a mole.", "order": 54778, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 14, "scene": 27, "line": "One of his tee shots can wipe out a whole owl population.", "order": 54779, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 14, "scene": 27, "line": "You two seem very close.", "order": 54780, "speaker": "Party Guest"},
  {"season": 9, "episode": 14, "scene": 27, "line": "Yes. We're good friends. Good friends.", "order": 54781, "speaker": "Senator"},
  {"season": 9, "episode": 14, "scene": 27, "line": "Yeah.", "order": 54782, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 14, "scene": 27, "line": "You know, I suppose that may ruffle a few feathers. For a long time, our party has turned it's back on the Hispanic people. Well, that is not who I am. [puts arms around Oscar] I am a friend of the Latino community. And if you ask me, it's time we bid bigotry hasta luego. [guests laugh] Now, does my embrace of Hispanics make me more electable? Given demographic trends? I don't know. And I don't care. What I care about is Oscar. [slaps Oscar's cheek] My friend. Mi amigo. Oscar. [guests applaud as he hugs Oscar]", "order": 54783, "speaker": "Senator"},
  {"season": 9, "episode": 14, "scene": 28, "line": "Maybe I should be insulted that he only invited me here to be his token Mexican friend. But, he could of invited any number of Hispanics that he knows. His gardener, Rogelio. Or he could've invited... Rogelio. But, he chose me. Rogelio's Malaysian... The son of a bitch is Malaysian.", "order": 54784, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 14, "scene": 29, "line": "Oh, hey.", "order": 54785, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 14, "scene": 29, "line": "Hey.", "order": 54786, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 14, "scene": 29, "line": "I usually watch TV during my lunch breaks. It's cool?", "order": 54787, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 14, "scene": 29, "line": "Yeah. Totally.", "order": 54788, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 14, "scene": 29, "line": "All right.", "order": 54789, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 14, "scene": 29, "line": "[after Darryl eyes him drinking from a thermos] It's mine. Don't worry.", "order": 54790, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 14, "scene": 29, "line": "I didn't say anything.", "order": 54791, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 14, "scene": 29, "line": "I don't think you had to.", "order": 54792, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 14, "scene": 29, "line": "Excuse me?", "order": 54793, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 14, "scene": 29, "line": "I think you might be going a little crazy with this labeling thing, man. I mean, you put your name on a five pound bag of flour. Are you honestly saying that if I needed flour I couldn't use that?", "order": 54794, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 14, "scene": 29, "line": "What you need flour for, Jim?", "order": 54795, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 14, "scene": 29, "line": "That's not the point.", "order": 54796, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 14, "scene": 29, "line": "What? You making bread?", "order": 54797, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 14, "scene": 29, "line": "No, I'm not making bread.", "order": 54798, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 14, "scene": 29, "line": "What kind of bread you making? Pumpernickel?", "order": 54799, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 14, "scene": 29, "line": "Darryl, it doesn't matter. I think you know the point I'm trying to make.", "order": 54800, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 14, "scene": 29, "line": "All right, I'm being a jerk. You got me this job. I should be grateful. I am, I just... you know, I get finicky about my stuff. That's all.", "order": 54801, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 14, "scene": 29, "line": "It's all good. Are we all good?", "order": 54802, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 14, "scene": 29, "line": "We good.", "order": 54803, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 14, "scene": 29, "line": "What's that cooler?", "order": 54804, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 14, "scene": 29, "line": "Nothing. [pulls out a can of soda] It's mine. [Jim shakes his head] [after looking through DVR] What happened to my Tavis Smileys?", "order": 54805, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 14, "scene": 29, "line": "Oh, crap. Were those yours?", "order": 54806, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 14, "scene": 30, "line": "[pulling Clark across the parking lot] I never want to see you working in the upstairs office again. Do you hear me?", "order": 54807, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 14, "scene": 30, "line": "Well, my only crime was loving the local sports teams and trying to be one of the guys.", "order": 54808, "speaker": "Clark Green"},
  {"season": 9, "episode": 14, "scene": 30, "line": "Silence. You'll now be working in the warehouse with the untouchables. Now, go make your hands rough with work.", "order": 54809, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 14, "scene": 30, "line": "OK, boss. [quietly] Pam, you know this is ridiculous, right? Like you're smarter than this.", "order": 54810, "speaker": "Clark Green"},
  {"season": 9, "episode": 14, "scene": 30, "line": "Shh, shh.", "order": 54811, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 14, "scene": 30, "line": "This is never gonna work.", "order": 54812, "speaker": "Clark Green"},
  {"season": 9, "episode": 14, "scene": 30, "line": "Shh. Remember your lines.", "order": 54813, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 14, "scene": 30, "line": "What lines?", "order": 54814, "speaker": "Clark Green"},
  {"season": 9, "episode": 14, "scene": 30, "line": "Go move some paper!", "order": 54815, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 14, "scene": 31, "line": "[to Darryl on phone] Hey, you know that guy Frank who works in the warehouse?", "order": 54816, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 14, "scene": 31, "line": "He's not my hire, but I know who he is.", "order": 54817, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 14, "scene": 31, "line": "OK. What does he like? What's important to him? Does he have like a favorite pair of boots or a lunch box or...", "order": 54818, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 14, "scene": 31, "line": "What? Is he retiring? You getting him a gift or something?", "order": 54819, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 14, "scene": 31, "line": "Yeah, something like that.", "order": 54820, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 14, "scene": 31, "line": "I know he loves his pick up truck.", "order": 54821, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 14, "scene": 31, "line": "Oh, great! [to Dwight] His truck!", "order": 54822, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 14, "scene": 31, "line": "Great. Get the plate number.", "order": 54823, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 14, "scene": 31, "line": "OK. [to Darryl] Do you know the plate... never mind. Why would you know that? And why would I be asking that?", "order": 54824, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 14, "scene": 31, "line": "So we know which truck to van...", "order": 54825, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 14, "scene": 31, "line": "Hey. While I got you on the phone, your husband's like a sloppy, homeless hobo. Can you fix that?", "order": 54826, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 14, "scene": 31, "line": "Yeah. I was kinda hoping you could. I gotta go. Bye. [to Dwight] Come on. [camera pans to Clark duct taped to a chair]", "order": 54827, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 14, "scene": 32, "line": "Hold on a second. Uh, Sandeep? Let's get you closer to the senator. Just about there. Great. And, Oscar? I'm gonna need you to step a little closer to the senator, as well. Somewhere there.", "order": 54828, "speaker": "Party Photographer"},
  {"season": 9, "episode": 14, "scene": 32, "line": "He's blocking me.", "order": 54829, "speaker": "Angela Martin"},
  {"season": 9, "episode": 14, "scene": 32, "line": "It's only a photo, honey.", "order": 54830, "speaker": "Senator"},
  {"season": 9, "episode": 14, "scene": 32, "line": "[to party waiter] Excuse me, uh, what's your name?", "order": 54831, "speaker": "Party Photographer"},
  {"season": 9, "episode": 14, "scene": 32, "line": "Sean.", "order": 54832, "speaker": "Party Waiter"},
  {"season": 9, "episode": 14, "scene": 32, "line": "LaShawn. Great. You wanna be in a photo? Right this way. [notices overweight man following] Not you. No.", "order": 54833, "speaker": "Party Photographer"},
  {"season": 9, "episode": 14, "scene": 32, "line": "He put me here.", "order": 54834, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 14, "scene": 32, "line": "He put you right in front of me?", "order": 54835, "speaker": "Angela Martin"},
  {"season": 9, "episode": 14, "scene": 32, "line": "Let's just wheel Margaret right in front...", "order": 54836, "speaker": "Party Photographer"},
  {"season": 9, "episode": 14, "scene": 32, "line": "Ow! Robert?!", "order": 54837, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 14, "scene": 32, "line": "Angela.", "order": 54838, "speaker": "Senator"},
  {"season": 9, "episode": 14, "scene": 32, "line": "Smile. [Angela attempts to get in front of Oscar as pictures are taken]", "order": 54839, "speaker": "Party Photographer"},
  {"season": 9, "episode": 14, "scene": 33, "line": "I'm done. What are you... Is that supposed to be my mural?", "order": 54840, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 14, "scene": 33, "line": "Yeah. Frank draws a butt on your mural, I'm drawing your mural on Frank's truck's butt. Eye for an eye, mamacita.", "order": 54841, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 14, "scene": 33, "line": "Aw, Dwight. That's really sweet.", "order": 54842, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 14, "scene": 33, "line": "Let's see yours.", "order": 54843, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 14, "scene": 33, "line": "Oh, no. Um, I, I'm embarrassed. It's stupid.", "order": 54844, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 14, "scene": 33, "line": "This is amazing! Frank! And he's leaving a trail of poops?", "order": 54845, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 14, "scene": 33, "line": "Yeah. And he has saggy boobs.", "order": 54846, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 14, "scene": 33, "line": "I saw that. That's great!", "order": 54847, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 14, "scene": 33, "line": "Yeah. I feel better.", "order": 54848, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 14, "scene": 33, "line": "Good. I'm glad you feel better. This has been a wonderful day. I have to say, I like hanging out with a vengeful bitch.", "order": 54849, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 14, "scene": 33, "line": "I know. You miss Angela, don't you?", "order": 54850, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 14, "scene": 33, "line": "Ugh! Don't sympathize. You're ruining the mood. Back to work. Draw his penis.", "order": 54851, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 14, "scene": 34, "line": "I got back at Frank in the most fitting way possible. With my art. The paints are water-based. It's gonna come off with a hose. But, I think the lesson will last a very...", "order": 54852, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 14, "scene": 34, "line": "[exiting building and approaching Pam] Lady! My truck? You had no right!", "order": 54853, "speaker": "Frank"},
  {"season": 9, "episode": 14, "scene": 34, "line": "No, you had no right!", "order": 54854, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 14, "scene": 34, "line": "It's a $40,000 truck!", "order": 54855, "speaker": "Frank"},
  {"season": 9, "episode": 14, "scene": 34, "line": "So? You started it!", "order": 54856, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 14, "scene": 34, "line": "So? So someone need to shut you up! [attempts to attack Pam]", "order": 54857, "speaker": "Frank"},
  {"season": 9, "episode": 14, "scene": 34, "line": "Hey, hey, hey! [knocks Frank down with boom microphone]", "order": 54858, "speaker": "Brian"},
  {"season": 9, "episode": 14, "scene": 34, "line": "Whoa.", "order": 54859, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 14, "scene": 34, "line": "[as Frank gets up] Easy! [Frank grabs him]", "order": 54860, "speaker": "Brian"},
  {"season": 9, "episode": 14, "scene": 34, "line": "You son of a bitch!", "order": 54861, "speaker": "Frank"},
  {"season": 9, "episode": 14, "scene": 34, "line": "You're gonna hit a woman?", "order": 54862, "speaker": "Brian"},
  {"season": 9, "episode": 14, "scene": 35, "line": "Thanks so much for coming. Thanks so much. [to Oscar and Angela] Well. Have we all calmed down yet?", "order": 54863, "speaker": "Senator"},
  {"season": 9, "episode": 14, "scene": 35, "line": "Yes. Sorry about that.", "order": 54864, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 14, "scene": 35, "line": "It was all my fault.", "order": 54865, "speaker": "Angela Martin"},
  {"season": 9, "episode": 14, "scene": 35, "line": "Let's all try to do better next time. Kevin, great to see you.", "order": 54866, "speaker": "Senator"},
  {"season": 9, "episode": 14, "scene": 35, "line": "Yeah. Thank you for the food. Oh, and also, you suck.", "order": 54867, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 14, "scene": 35, "line": "I beg your pardon.", "order": 54868, "speaker": "Senator"},
  {"season": 9, "episode": 14, "scene": 35, "line": "You are like a terrible person. These guys care about you and you're just using them. Again, the food was very good. [Oscar and Angela exit looking pleased]", "order": 54869, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 14, "scene": 36, "line": "Well, I'm gonna say something to the producers.", "order": 54870, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 14, "scene": 36, "line": "No. No, it's...", "order": 54871, "speaker": "Brian"},
  {"season": 9, "episode": 14, "scene": 36, "line": "You shouldn't be fired. I mean, you were just protecting me.", "order": 54872, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 14, "scene": 36, "line": "It's all good. I knew what I was doing. It's... I'm sorry about your mural, though. I mean, because you put so much into that.", "order": 54873, "speaker": "Brian"},
  {"season": 9, "episode": 14, "scene": 36, "line": "Forget about my mural. It's stupid.", "order": 54874, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 14, "scene": 36, "line": "No. You, you worked hard on that. That guy's an animal. I'm glad they're firing him too.", "order": 54875, "speaker": "Brian"},
  {"season": 9, "episode": 14, "scene": 36, "line": "It's crazy. Brian, I'm so sorry.", "order": 54876, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 14, "scene": 36, "line": "Look. I don't, I don't wanna put myself where I don't belong. If you ever need me, you just call me. And I'll be there for you.", "order": 54877, "speaker": "Brian"},
  {"season": 9, "episode": 14, "scene": 36, "line": "Thanks, Brian.", "order": 54878, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 14, "scene": 36, "line": "Seeya.", "order": 54879, "speaker": "Brian"},
  {"season": 9, "episode": 14, "scene": 37, "line": "Wow. This whole Philly thing has been so much fun that I may have lost sight of what really matters. I mean, having fun is not nearly as important as being good to the people who you really care about. I mean, that's just 'Roommates 101'.", "order": 54880, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 14, "scene": 38, "line": "[playing video game with Jim] Oh. Come on!", "order": 54881, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 14, "scene": 38, "line": "Oh. Oh!", "order": 54882, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 14, "scene": 38, "line": "Damn!", "order": 54883, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 14, "scene": 38, "line": "[imitating game announcer] You win. [Darryl throws empty can on floor] How good did that feel?", "order": 54884, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 14, "scene": 38, "line": "That felt really good, actually.", "order": 54885, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 15, "scene": 1, "line": "[to Jim] Hey. I feel so lucky we're in the same city for Valentine's Day.", "order": 54886, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 15, "scene": 1, "line": "It's like magic. Or, it's like me getting on my hands and knees and begging my partners to switch a Tuesday for a Thursday.", "order": 54887, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 15, "scene": 1, "line": "[makes magic trick hand gesture] Alakazam!", "order": 54888, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 15, "scene": 1, "line": "By the way, they do need an extra day next week.", "order": 54889, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 15, "scene": 1, "line": "And, poof! He disappears. [Jim snaps, playing along]", "order": 54890, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 15, "scene": 2, "line": "[to Pete] Hey! Wanna play hookey today?", "order": 54891, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 15, "scene": 2, "line": "Oh, maybe. What do you have in mind?", "order": 54892, "speaker": "Pete Miller"},
  {"season": 9, "episode": 15, "scene": 2, "line": "We can do anything you want.", "order": 54893, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 15, "scene": 3, "line": "I really wanna have fun today because tomorrow is going to be a nightmare. Andy's coming back from his stupid, dumb boat trip. He's been rude. He's been selfish. I think he's a big jerk. And I'm breaking up with him. Bam, Andy! How do you like me now?... I hope as a friend.", "order": 54894, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 15, "scene": 4, "line": "Erin says she's gonna break up with Andy, but I'm not sure. He's coming back tomorrow and surprise, today, she wants to do whatever I'd like. You know, when I was a kid, we had a dog who go real sick and we had to 'send him to a farm'. And on his last day, we did everything he loved.", "order": 54895, "speaker": "Pete Miller"},
  {"season": 9, "episode": 15, "scene": 5, "line": "[has an idea and reveals a frisbee from under her desk] Wanna play catch in the parking lot?", "order": 54896, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 15, "scene": 5, "line": "[slightly surprised] Sure.", "order": 54897, "speaker": "Pete Miller"},
  {"season": 9, "episode": 15, "scene": 5, "line": "Great.", "order": 54898, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 15, "scene": 5, "line": "I'll get my coat.", "order": 54899, "speaker": "Pete Miller"},
  {"season": 9, "episode": 15, "scene": 5, "line": "Perfect. [Pete walks away eying the camera knowingly]", "order": 54900, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 15, "scene": 6, "line": "[knocks on Andy's door, then pretends to answer as Andy] Come in.", "order": 54901, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 15, "scene": 6, "line": "[talking to Andy's empty chair] Andy, hi. I just made another huge sale for the company that you manage. I need you to authorize that expense report and sign off on that contract.", "order": 54902, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 15, "scene": 6, "line": "[pretending to be Andy] I would be happy to. It would be my pleasure, Mr. Schrute. Rick-a-dick-doo, rick-a-dick-dick-dick, rick-a-dick-doo.", "order": 54903, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 15, "scene": 7, "line": "I really like Andy these days. He's pretend and he does exactly as I tell him to. All that will change when real Andy comes back tomorrow. [thinks for a moment] Unless he comes back as pretend Dwight. In which case, we're in for an epic, confusing showdown.", "order": 54904, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 15, "scene": 8, "line": "Andy left a carton of milk in the fridge. So, I've been sneaking a little bit every day for the last three months. It's been yummy. But now, Andy's coming back. So, I guess it's goodbye chunky, lemon milk.", "order": 54905, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 15, "scene": 9, "line": "[to entire office]OK, I'll say it. I wish Andy had stayed on his trip.", "order": 54906, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 15, "scene": 9, "line": "I say we all have one last fun boss-less day.", "order": 54907, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 15, "scene": 9, "line": "Yeah, let's get some booze and some cocaine and just blow it out. No consequences.", "order": 54908, "speaker": "Meredith Palmer"},
  {"season": 9, "episode": 15, "scene": 9, "line": "Or the mini mall. They have all these Valentine Day deals. You can get 20% off if you come in with your husband or your [to Nellie] boyfriend. But, I mean, if you don't have one of those you can probably just bring whoever it is you use to kill your loneliness.", "order": 54909, "speaker": "Phyllis Vance"},
  {"season": 9, "episode": 15, "scene": 9, "line": "Phil, I'll pretend to be your husband. I'm already sick of you, so it'll seem realistic.", "order": 54910, "speaker": "Stanley Hudson"},
  {"season": 9, "episode": 15, "scene": 9, "line": "Oh...", "order": 54911, "speaker": "Phyllis Vance"},
  {"season": 9, "episode": 15, "scene": 9, "line": "They have a nail salon there where I get my feet detailed. [to Meredith] They use a watch repair kit.", "order": 54912, "speaker": "Angela Martin"},
  {"season": 9, "episode": 15, "scene": 9, "line": "[to Angela] Ew! I'll be your foot buddy.", "order": 54913, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 15, "scene": 9, "line": "Everyone, it is our last day here with no manager. I say we go to the mini mall. Clark, you will be my fake boyfriend so I can get the discount.", "order": 54914, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 15, "scene": 9, "line": "It's what I do. [everyone begins to leave]", "order": 54915, "speaker": "Clark Green"},
  {"season": 9, "episode": 15, "scene": 9, "line": "Darryl, everyone seems to be pairing off. Do you want to pretend to be a couple so we get the...", "order": 54916, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 15, "scene": 9, "line": "No... yes, yes. Why wouldn't I... wanna pretend to be gay? Got no problem with that.", "order": 54917, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 15, "scene": 9, "line": "All right, it'll be easy. Don't be nervous, just follow my...", "order": 54918, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 15, "scene": 9, "line": "[interrupting] Stop talking 'bout it. I said I'm fine with it.", "order": 54919, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 15, "scene": 10, "line": "[eating] These are gross.", "order": 54920, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 15, "scene": 10, "line": "They are terrible.", "order": 54921, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 15, "scene": 10, "line": "Oh, hey, don't fill up on chocolates. I made us a lunch reservation at State Street Grill.", "order": 54922, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 15, "scene": 10, "line": "Oh my god. That's so romantic.", "order": 54923, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 15, "scene": 10, "line": "It's with Brian and Alyssa.", "order": 54924, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 15, "scene": 10, "line": "Oh my god. That's less romantic.", "order": 54925, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 15, "scene": 10, "line": "I know. But we should go. We need to thank him for, you know, saving my life.", "order": 54926, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 15, "scene": 10, "line": "Yeah, yeah. No, totally. That's good. So, should we just get a bottle of wine later and celebrate?", "order": 54927, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 15, "scene": 10, "line": "That sounds nice.", "order": 54928, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 15, "scene": 11, "line": "I'm very excited to see Brian. Brian's a great guy. And Pam and I have gotten really close to he and his wife, Alyssa over the years. And he got fired for protecting my wife from a jerk in the warehouse. I'm sorry, but you know him. He's a good guy.", "order": 54929, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 15, "scene": 12, "line": "Oh, tiny, poor lady is back. Oh, get the baby clipper. [other nails stylists gush over Angela]", "order": 54930, "speaker": "Nail stylist 1"},
  {"season": 9, "episode": 15, "scene": 12, "line": "[to Clark] You take off your glasses.", "order": 54931, "speaker": "Nail stylist 2"},
  {"season": 9, "episode": 15, "scene": 12, "line": "Kay. [removes glasses]", "order": 54932, "speaker": "Clark Green"},
  {"season": 9, "episode": 15, "scene": 12, "line": "[nail stylist 2 giggles] What?", "order": 54933, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 15, "scene": 12, "line": "Your boyfriend. He look like a pretty girl.", "order": 54934, "speaker": "Nail stylist 2"},
  {"season": 9, "episode": 15, "scene": 12, "line": "My boyfriend does look like a pretty girl, doesn't he? Yes, a very little pretty girl. And you know what, now that you are developing, we should go and get you a training bra. [both laugh at Clark]", "order": 54935, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 15, "scene": 12, "line": "Oh, you guys think this is funny? You know what? No more discount. [to nail manager] Excuse me. [gesturing he and Nellie] Full price. We're not together.", "order": 54936, "speaker": "Clark Green"},
  {"season": 9, "episode": 15, "scene": 12, "line": "Oh, come on!", "order": 54937, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 15, "scene": 12, "line": "She's living a lie.", "order": 54938, "speaker": "Clark Green"},
  {"season": 9, "episode": 15, "scene": 13, "line": "Turns out, I can't even be in a pretend relationship.", "order": 54939, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 15, "scene": 14, "line": "[to nail manager] Hi. We'd like a couples discount on a pair of foot massages.", "order": 54940, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 15, "scene": 14, "line": "No. No discount for two men. Two men are not a couple.", "order": 54941, "speaker": "Nail manager"},
  {"season": 9, "episode": 15, "scene": 14, "line": "We are together. Romantically.", "order": 54942, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 15, "scene": 14, "line": "Two men? [other nail stylist speaks Korean to manager, both laugh] [gestures index fingers bumping together] Doesn't work. No discount.", "order": 54943, "speaker": "Nail manager"},
  {"season": 9, "episode": 15, "scene": 14, "line": "Oh, it works. Him and me, all right, we are crazy in love. More love than your small mind can comprehend. And we have two disposable incomes. And no kids. And we're taking our business elsewhere. [Oscar and Darryl exit holding hands]", "order": 54944, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 15, "scene": 15, "line": "[enters restaurant with Pam] Hey, Brian.", "order": 54945, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 15, "scene": 15, "line": "Hey.", "order": 54946, "speaker": "Brian"},
  {"season": 9, "episode": 15, "scene": 15, "line": "Sorry we're late.", "order": 54947, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 15, "scene": 15, "line": "Oh, uh, no problem. I finished all the bread.", "order": 54948, "speaker": "Brian"},
  {"season": 9, "episode": 15, "scene": 15, "line": "Ha,ha,ha, yeah OK.", "order": 54949, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 15, "scene": 15, "line": "What?", "order": 54950, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 15, "scene": 15, "line": "He's on a no carb thing. Supposedly.", "order": 54951, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 15, "scene": 15, "line": "Oh.", "order": 54952, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 15, "scene": 15, "line": "It's, uh, great to see you guys. Thanks for coming.", "order": 54953, "speaker": "Brian"},
  {"season": 9, "episode": 15, "scene": 15, "line": "Yeah.", "order": 54954, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 15, "scene": 15, "line": "Are you kidding? Thank you, man. I mean, I've wanted the opportunity to say thanks for... everything. And I'm really sorry about the job. That just seems crazy.", "order": 54955, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 15, "scene": 15, "line": "It's fine. What are you gonna do, you know? But, if you guys know of any work, I'm fully available.", "order": 54956, "speaker": "Brian"},
  {"season": 9, "episode": 15, "scene": 15, "line": "Well, my dad can't hear a thing. You could boom his whole life for him.", "order": 54957, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 15, "scene": 15, "line": "That's... OK, great. Does he pay well?", "order": 54958, "speaker": "Brian"},
  {"season": 9, "episode": 15, "scene": 15, "line": "Where's Alyssa?", "order": 54959, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 15, "scene": 15, "line": "Uh, yeah. Um, you know, Alyssa's, she's not gonna make it today.", "order": 54960, "speaker": "Brian"},
  {"season": 9, "episode": 15, "scene": 15, "line": "Oh.", "order": 54961, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 15, "scene": 15, "line": "Actually, we're not gonna make it. Um... we're splitting up.", "order": 54962, "speaker": "Brian"},
  {"season": 9, "episode": 15, "scene": 16, "line": "[to Andy's empty chair] I have yet another sales order for you to sign.", "order": 54963, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 15, "scene": 16, "line": "[acting as Andy] Why thank you Mr. Schrute. I don't know how you do it. You're a god. Rick-a-dick-dick-doo.", "order": 54964, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 15, "scene": 16, "line": "[in his office doorway, bearded and unkept] Hi Dwight.", "order": 54965, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 15, "scene": 16, "line": "You're back. [surveys Andy] And you're disgusting.", "order": 54966, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 15, "scene": 17, "line": "[as everyone returns to the office] Ah, geez. My nails aren't dry yet. I don't think I can work for at least a couple hours.", "order": 54967, "speaker": "Phyllis Vance"},
  {"season": 9, "episode": 15, "scene": 17, "line": "Well, well, well, look who it is.", "order": 54968, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 15, "scene": 17, "line": "Andy.", "order": 54969, "speaker": "Phyllis Vance"},
  {"season": 9, "episode": 15, "scene": 17, "line": "I guess I can cancel my order from Zappos.com because, oh, the loafers have arrived.", "order": 54970, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 15, "scene": 17, "line": "Andy!", "order": 54971, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 15, "scene": 17, "line": "Hey! Sweetheart! [approaches Erin] I have missed you so much.", "order": 54972, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 15, "scene": 17, "line": "[obviously avoiding Andy's embrace] Yes. [gives Andy high fives] Welcome back, buddy.", "order": 54973, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 15, "scene": 17, "line": "[attempting to hug Erin as she resists] I have been dreaming of this moment.", "order": 54974, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 15, "scene": 17, "line": "Me too. So much. I'm so happy.", "order": 54975, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 15, "scene": 18, "line": "I am really, really bad at break ups. Technically, I'm still dating my first grade boyfriend. I mean, we just had our 20th anniversary. And, I forgot to get him something.", "order": 54976, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 15, "scene": 19, "line": "What happened? We thought you were coming back tomorrow.", "order": 54977, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 15, "scene": 19, "line": "Well, Valentine's surprise for Erin. Hello? Super romantic. And I got you something. [removes wooden instruments from bag] Oh, it's a couple of pieces of bamboo. Big deal, right? No. These are musical instruments. It's so we can play island music together. Cause I have this. [removes giro and begins playing and singing] Clop the cloppers. Yeah, clop 'em. It's called Bembe. [sings while Kevin echoes]", "order": 54978, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 15, "scene": 19, "line": "Hey, Burning Man, if it's not selling out too much, you might want to throw on a tie. David Wallace is gonna be here in an hour.", "order": 54979, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 15, "scene": 19, "line": "Obviously, that's why I'm here. I mean, I came back early to surprise Erin. Happy Valentine's Day, sweetheart. [tries to embrace Erin but she begins playing cloppers] But, I'm just saying, I'm also excited about the Wallace meeting.", "order": 54980, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 15, "scene": 19, "line": "Why? Isn't he just coming in to rip you a new one for being gone three months?", "order": 54981, "speaker": "Clark Green"},
  {"season": 9, "episode": 15, "scene": 19, "line": "No. Please. Come on, Clark. Wallace knows that he's been gone for the last three months. [Andy stalls] Right? Wallace does know that you've been gone for the last three months?", "order": 54982, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 15, "scene": 19, "line": "I have no idea. I don't know what he knows or doesn't know. But we've been in touch the whole time. I mean, it's not hard to get high-speed internet in Turks de Caicos, people. It's in every Bembe cafe.", "order": 54983, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 15, "scene": 20, "line": "He only emailed me four times.", "order": 54984, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 15, "scene": 21, "line": "Question. Where's Jim?", "order": 54985, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 15, "scene": 21, "line": "He and Pam are having their Valentine's Day lunch.", "order": 54986, "speaker": "Phyllis Vance"},
  {"season": 9, "episode": 15, "scene": 21, "line": "For two hours? Really?", "order": 54987, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 15, "scene": 21, "line": "So, you're concerned about peoples' long absence from their place of work?", "order": 54988, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 15, "scene": 21, "line": "If the shoe fits. [plays giro and sings, Kevin echoes]", "order": 54989, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 15, "scene": 22, "line": "We were telling two different versions of the same story. And then, everything just went numb.", "order": 54990, "speaker": "Brian"},
  {"season": 9, "episode": 15, "scene": 22, "line": "Well, I mean, that's OK. It doesn't mean that it's over. Right? I mean, couples fight.", "order": 54991, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 15, "scene": 22, "line": "Yeah. That's the thing. When we were fighting, it weirdly felt like the relationship was still alive. And, it wasn't until we stopped fighting that, we realized that it was over. You know, it's over. [overcome by emotion] I'm sorry, this is... oh my god, OK. [to Pam] We have to stop seeing each other like this. We have to find a different way to communicate other than breaking down in front of each other.", "order": 54992, "speaker": "Brian"},
  {"season": 9, "episode": 15, "scene": 22, "line": "[obviously flustered] Yeah.", "order": 54993, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 15, "scene": 22, "line": "What?", "order": 54994, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 15, "scene": 22, "line": "At least my crying won't get you fired.", "order": 54995, "speaker": "Brian"},
  {"season": 9, "episode": 15, "scene": 22, "line": "Crying?", "order": 54996, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 15, "scene": 23, "line": "I noticed that you landed the Scranton White Pages account. That is tremendous.", "order": 54997, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 15, "scene": 23, "line": "Thank you.", "order": 54998, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 15, "scene": 23, "line": "And you sold it to Jan too.", "order": 54999, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 15, "scene": 23, "line": "Yes!", "order": 55000, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 15, "scene": 23, "line": "I mean... I'm impressed.", "order": 55001, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 15, "scene": 23, "line": "[laughing together] Yeah!", "order": 55002, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 15, "scene": 23, "line": "Well, there's one problem. Couldn't help but notice that you offered a price point that was not approved by the head office. So... gotta run that stuff by me, Dwight.", "order": 55003, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 15, "scene": 23, "line": "You were on a boat.", "order": 55004, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 15, "scene": 23, "line": "I was...", "order": 55005, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 15, "scene": 23, "line": "On a boat.", "order": 55006, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 15, "scene": 23, "line": "That...", "order": 55007, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 15, "scene": 23, "line": "In the ocean.", "order": 55008, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 15, "scene": 23, "line": "OK. The issue is that you need to run this stuff by me. Coolio? Are we coolio? [Dwight resists] Just say the word 'coolio'.", "order": 55009, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 15, "scene": 23, "line": "I'm not gonna say it.", "order": 55010, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 15, "scene": 23, "line": "Say it.", "order": 55011, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 15, "scene": 23, "line": "Not a word.", "order": 55012, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 15, "scene": 23, "line": "Coolio.", "order": 55013, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 15, "scene": 23, "line": "No! [Andy makes a call] What do you think you're doing?", "order": 55014, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 15, "scene": 23, "line": "Just gonna call the Scranton White Pages and clear this right up.", "order": 55015, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 15, "scene": 23, "line": "Don't you dare! Andy!", "order": 55016, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 15, "scene": 23, "line": "[on phone] Hello?", "order": 55017, "speaker": "Jan Levinson"},
  {"season": 9, "episode": 15, "scene": 23, "line": "Hey, Jan. Nard dog here.", "order": 55018, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 15, "scene": 23, "line": "Oh, Andy.", "order": 55019, "speaker": "Jan Levinson"},
  {"season": 9, "episode": 15, "scene": 23, "line": "I was just looking over the paperwork. I found a little hiccup.", "order": 55020, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 15, "scene": 23, "line": "Really?", "order": 55021, "speaker": "Jan Levinson"},
  {"season": 9, "episode": 15, "scene": 23, "line": "Yeah. It appears my employee offered you a price that he was not authorized to.", "order": 55022, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 15, "scene": 23, "line": "Hmm.", "order": 55023, "speaker": "Jan Levinson"},
  {"season": 9, "episode": 15, "scene": 23, "line": "[whispers] Coolio.", "order": 55024, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 15, "scene": 23, "line": "Seriously? You're calling me a few weeks after finalizing our contract to gouge me now for more money? Is that what you're doing?", "order": 55025, "speaker": "Jan Levinson"},
  {"season": 9, "episode": 15, "scene": 23, "line": "Coolio. Coolio.", "order": 55026, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 15, "scene": 23, "line": "No. No, no, no Jan I think you misunderstood.", "order": 55027, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 15, "scene": 23, "line": "Yeah.", "order": 55028, "speaker": "Jan Levinson"},
  {"season": 9, "episode": 15, "scene": 23, "line": "Coolio.", "order": 55029, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 15, "scene": 23, "line": "It, it's, it's actually just an issue...", "order": 55030, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 15, "scene": 23, "line": "You know what? You know what, uh, Nard dog? There is an option in the contract that allows me to back out within 30 days of signing. So, I would like to exercise that option.", "order": 55031, "speaker": "Jan Levinson"},
  {"season": 9, "episode": 15, "scene": 23, "line": "No, Jan! Please do not listen to this boob! Remember Clark. He gave you everything. Everything.", "order": 55032, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 15, "scene": 23, "line": "Jan, I don't know what he's talking about but...", "order": 55033, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 15, "scene": 23, "line": "Tell Angela to send me a final invoice.", "order": 55034, "speaker": "Jan Levinson"},
  {"season": 9, "episode": 15, "scene": 23, "line": "Well, ah, ah...", "order": 55035, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 15, "scene": 23, "line": "Please Ja, Ja... [Jan hangs up]", "order": 55036, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 15, "scene": 23, "line": "Aw! That was not how I had hoped that would go.", "order": 55037, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 15, "scene": 24, "line": "[approaching Accounting] Hey, everybody, great job. [to Angela] Listen, we're a smidge behind on my paychecks.", "order": 55038, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 15, "scene": 24, "line": "Yes, well, as you know, we get paid on Fridays. And you haven't been here for 12 Fridays. [hands Andy a folder]", "order": 55039, "speaker": "Angela Martin"},
  {"season": 9, "episode": 15, "scene": 24, "line": "All right. Thank you very much. [examines checks] Looking good. [after noticing something on Angela's desk] Who's that little fella?", "order": 55040, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 15, "scene": 24, "line": "It's a bonus check. For you. From Wallace. Because the branch exceeded it's targets over the past quarter.", "order": 55041, "speaker": "Angela Martin"},
  {"season": 9, "episode": 15, "scene": 24, "line": "Wow, that's wonderful!", "order": 55042, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 15, "scene": 24, "line": "A quarter's three months. That's how long you've been gone.", "order": 55043, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 15, "scene": 24, "line": "Uh-huh.", "order": 55044, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 15, "scene": 24, "line": "Uh-huh.", "order": 55045, "speaker": "Angela Martin"},
  {"season": 9, "episode": 15, "scene": 24, "line": "Uh-huh... [after awkward pause] Uh-huh. [Angela hands him the bonus check] Thank you. Great. Well, we're all up to speed.", "order": 55046, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 15, "scene": 25, "line": "Two seconds of the turd dog and he loses the biggest sale this branch has ever seen.", "order": 55047, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 15, "scene": 25, "line": "[emotional] Do you have any idea what I had to do to get that sale from Jan? I mean, I went all out. All out. I mean like everything was out the whole week.", "order": 55048, "speaker": "Clark Green"},
  {"season": 9, "episode": 15, "scene": 25, "line": "He just waltzes back in here like he owns the chunky, lemon milk. Who needs him, right?", "order": 55049, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 15, "scene": 25, "line": "God! I just don't know what we'd do. I mean, short of telling David Wallace that he was gone for three months.", "order": 55050, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 15, "scene": 26, "line": "I'd like to rat out Andy. Unfortunately, I have a bit of a boy-who-cried-wolf dynamic with David Wallace. Except, instead of a boy, I'm a man. And instead of a wolf, I cried genetically-engineered monster wolf.", "order": 55051, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 15, "scene": 27, "line": "I'm not going to rat on him. No, Andy gave me a second chance. So, the least I could do is let somebody else rat on him. Meredith, why don't you?", "order": 55052, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 15, "scene": 27, "line": "Meredith Palmer ain't never been called no nark. Floozy? Yes. Alkie? Check. Einstein sarcastically? You bet. But never no nark. Vomit mop? Sure. Floor meat? That's me. Flesh hoover?", "order": 55053, "speaker": "Meredith Palmer"},
  {"season": 9, "episode": 15, "scene": 27, "line": "Hey!", "order": 55054, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 15, "scene": 27, "line": "Meredith, that's plenty. All right? That's more than plenty. Why does no one stop her?", "order": 55055, "speaker": "Pete Miller"},
  {"season": 9, "episode": 15, "scene": 27, "line": "Guys. I know that a lot of people are mad at Andy and, believe me, I am too. But, he has been through a lot and we all used to love him, right? So, if he's gonna get in trouble, just let it be his fault not ours. I don't want that on my hands.", "order": 55056, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 15, "scene": 27, "line": "Fine! The state he's in, Wallace will take one look at him and probably fire him anyway.", "order": 55057, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 15, "scene": 27, "line": "[appears approaching group, shaved and in a suit] What's going on in here, dirty players? Let's get back to busting some paper rhymes. Come on. [singing] Who's that girl? Who's that girl? It's Andy! [resumes talking] All right. Back to work.", "order": 55058, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 15, "scene": 28, "line": "OK, I can tell you're mad at me. Do you wanna just, um, I don't know, tell me why?", "order": 55059, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 15, "scene": 28, "line": "I don't know. I thought that was a little weird. You told me Brian got fired for the whole warehouse thing. And you intentionally left out a kind of major, intimate detail. I don't know, Pam, I guess I just feel like a chump. Who knows less about his marriage than the sound guy.", "order": 55060, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 15, "scene": 28, "line": "I didn't tell you about the crying because I didn't want you to know how upset I was. Because it would've stressed you out and you're always saying how much you don't want more stress.", "order": 55061, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 15, "scene": 28, "line": "Yeah, well. Yeah, OK. Well, then thank you. Thanks to both of you.", "order": 55062, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 15, "scene": 28, "line": "It's not Brian's fault.", "order": 55063, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 15, "scene": 28, "line": "No, you're right. And, and I'm not mad at Brian. And to be honest, I probably don't have any reason to be mad at all because I wasn't there. So, let's just forget about it.", "order": 55064, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 15, "scene": 28, "line": "OK.", "order": 55065, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 15, "scene": 29, "line": "I need you guys to tell me all the highlights from the last three months in case David asks. Just a few things I could sprinkle into conversation. Any big sales or office gossip.", "order": 55066, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 15, "scene": 29, "line": "Well, we had the Scranton White Pages.", "order": 55067, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 15, "scene": 29, "line": "Not helpful. Let's stay positive, people. OK?", "order": 55068, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 15, "scene": 29, "line": "[enters] Hey guys.", "order": 55069, "speaker": "David Wallace"},
  {"season": 9, "episode": 15, "scene": 29, "line": "Hey! David! How are ya? Ah, we were just having our weekly round table where we motivate each other. Not gonna lie, I get as much out of it as they do.", "order": 55070, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 15, "scene": 29, "line": "Sounds great. Don't let me interrupt. What ever you guys have been doing this last quarter, I couldn't be happier with the numbers.", "order": 55071, "speaker": "David Wallace"},
  {"season": 9, "episode": 15, "scene": 29, "line": "Thank you.", "order": 55072, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 15, "scene": 29, "line": "Well, finish up. I'm gonna meet with Val about that warehouse guy you had to let go and you and I will talk in 15 minutes?", "order": 55073, "speaker": "David Wallace"},
  {"season": 9, "episode": 15, "scene": 29, "line": "Great!", "order": 55074, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 15, "scene": 29, "line": "[leaving] Great job, everybody!", "order": 55075, "speaker": "David Wallace"},
  {"season": 9, "episode": 15, "scene": 29, "line": "[whispering] We had to let a warehouse guy go?!", "order": 55076, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 15, "scene": 29, "line": "You know Pam's mural? Well, Frank...", "order": 55077, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 15, "scene": 29, "line": "[interrupting] ' lit the whole thing on fire. It was crazy.", "order": 55078, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 15, "scene": 29, "line": "What?!", "order": 55079, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 15, "scene": 29, "line": "Yeah.", "order": 55080, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 15, "scene": 29, "line": "There was a fire in the warehouse?", "order": 55081, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 15, "scene": 29, "line": "The whole thing is in ashes. Fire department was here. It was in all the papers.", "order": 55082, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 15, "scene": 29, "line": "Whoa.", "order": 55083, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 15, "scene": 29, "line": "This is what I'm talking about! This would be good to know. All right, what else?", "order": 55084, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 15, "scene": 29, "line": "We started selling balloons.", "order": 55085, "speaker": "Phyllis Vance"},
  {"season": 9, "episode": 15, "scene": 29, "line": "What?!", "order": 55086, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 15, "scene": 29, "line": "Yeah. And, uh, Kathy Ireland signed on as the official spokes-babe of Dunder-Mifflin.", "order": 55087, "speaker": "Clark Green"},
  {"season": 9, "episode": 15, "scene": 29, "line": "No kidding?", "order": 55088, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 15, "scene": 29, "line": "Yeah. In the European billboards, she's gonna be topless.", "order": 55089, "speaker": "Clark Green"},
  {"season": 9, "episode": 15, "scene": 29, "line": "Wow. Go Kathy. She's like 50.", "order": 55090, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 15, "scene": 29, "line": "They're tasteful.", "order": 55091, "speaker": "Clark Green"},
  {"season": 9, "episode": 15, "scene": 29, "line": "Good, good. What else?", "order": 55092, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 15, "scene": 30, "line": "Who knew the balloon game would be so lucrative? And thank god, right? We needed the income after the fire.", "order": 55093, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 15, "scene": 30, "line": "What fire?", "order": 55094, "speaker": "David Wallace"},
  {"season": 9, "episode": 15, "scene": 30, "line": "The warehouse fire. Weren't you just down there? It's like burnt to ashes.", "order": 55095, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 15, "scene": 30, "line": "It looked fine to me.", "order": 55096, "speaker": "David Wallace"},
  {"season": 9, "episode": 15, "scene": 30, "line": "[catches on to the ruse] I am speaking metaphorically, of course. You know I have lots of irons in quote-unquote fire. Well, that's one of them. You know, making sure that the warehouse logistics is a well-oiled, properly-stoked fire.", "order": 55097, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 15, "scene": 30, "line": "OK.", "order": 55098, "speaker": "David Wallace"},
  {"season": 9, "episode": 15, "scene": 30, "line": "I think you'll agree I explained that pretty well.", "order": 55099, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 15, "scene": 31, "line": "[preparing to leave] Thanks, Andy.", "order": 55100, "speaker": "David Wallace"},
  {"season": 9, "episode": 15, "scene": 31, "line": "Thank you.", "order": 55101, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 15, "scene": 31, "line": "All right. Everyone! [waves to office and exits]", "order": 55102, "speaker": "David Wallace"},
  {"season": 9, "episode": 15, "scene": 32, "line": "[enters Andy's office to find him playing giro] Fish sounds great.", "order": 55103, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 15, "scene": 32, "line": "Yeah, I guess.", "order": 55104, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 15, "scene": 32, "line": "Really playing the scales, huh?", "order": 55105, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 15, "scene": 32, "line": "Yeah, it just sort of sounds like noise to me now. You think I need a new fish?", "order": 55106, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 15, "scene": 32, "line": "I don't love you anymore.", "order": 55107, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 15, "scene": 32, "line": "What?", "order": 55108, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 15, "scene": 32, "line": "I still like you, but you were gone a really long time. And, you didn't really email me all that much. You retweeted me, a lot to be fair. But I don't love you.", "order": 55109, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 15, "scene": 32, "line": "OK, I get it. You're unhappy. I've been gone a long time and we lost a little bit of juju. But, you and me, we have a future. There is a lot of love here.", "order": 55110, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 15, "scene": 32, "line": "I just said there isn't love.", "order": 55111, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 15, "scene": 32, "line": "On your side. But there's tons on my side. It's gushing. We're just out of sync right now. But that's just timing, it's timing. I mean, my parents lasted 38, 40 years. They were never happy at the same time.", "order": 55112, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 15, "scene": 32, "line": "I guess.", "order": 55113, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 15, "scene": 32, "line": "I mean, what do we have left? 35, maybe 40 years? If we're lucky. I mean, I have spent a lot of time in the sun.", "order": 55114, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 15, "scene": 32, "line": "You got really sunburned.", "order": 55115, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 15, "scene": 32, "line": "I'm gonna be a prune in like, 3 years.", "order": 55116, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 15, "scene": 32, "line": "Ugh.", "order": 55117, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 15, "scene": 32, "line": "I know you may not be feeling love for me right now but, if you fake it, I won't be able to tell the difference. So, I'll feel good. And then, eventually, maybe, you'll actually start to love me again.", "order": 55118, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 15, "scene": 32, "line": "You really think we can get that back?", "order": 55119, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 15, "scene": 32, "line": "Yes. [hugs Erin] Come on. Totally.", "order": 55120, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 15, "scene": 33, "line": "You know what? Maybe we should cancel that bottle of wine tonight.", "order": 55121, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 15, "scene": 33, "line": "Oh?", "order": 55122, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 15, "scene": 33, "line": "Yeah. I just feel like I got a bunch of stuff to do in Philly and I'm sure you have stuff to do. So we can just... I don't know, drop me at the bus station?", "order": 55123, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 15, "scene": 33, "line": "Are you sure?", "order": 55124, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 15, "scene": 33, "line": "I just feel like we're gonna fight.", "order": 55125, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 15, "scene": 33, "line": "[obviously hurt] Yeah.", "order": 55126, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 15, "scene": 33, "line": "So... how 'bout let's not?", "order": 55127, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 15, "scene": 33, "line": "OK. [they begin to leave]", "order": 55128, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 15, "scene": 33, "line": "Oh, um. [pulls item from bag and hands to Pam] Happy Valentine's Day. Sorry, I didn't have time to wrap it.", "order": 55129, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 15, "scene": 33, "line": "[sees it's a drawing of hers, framed] Wow. I didn't know you kept this.", "order": 55130, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 15, "scene": 33, "line": "Yeah, yeah.", "order": 55131, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 15, "scene": 33, "line": "Thank you.", "order": 55132, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 15, "scene": 33, "line": "No problem.", "order": 55133, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 15, "scene": 33, "line": "I don't think you should go to Philly tonight. I think that you should stay and I think we should fight.", "order": 55134, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 15, "scene": 33, "line": "You really wanna fight on Valentine's Day?", "order": 55135, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 15, "scene": 33, "line": "Yeah, I do.", "order": 55136, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 15, "scene": 33, "line": "OK. All right, put your dukes up, Beesly.", "order": 55137, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 15, "scene": 34, "line": "Hi.", "order": 55138, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 15, "scene": 34, "line": "Hey, you OK?", "order": 55139, "speaker": "Pete Miller"},
  {"season": 9, "episode": 15, "scene": 34, "line": "I couldn't do it.", "order": 55140, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 15, "scene": 34, "line": "Oh.", "order": 55141, "speaker": "Pete Miller"},
  {"season": 9, "episode": 15, "scene": 34, "line": "I'm sorry.", "order": 55142, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 15, "scene": 34, "line": "Oh, you don't have to apologize. I just... I just want you to be happy. OK? [Erin smiles and kisses Pete]", "order": 55143, "speaker": "Pete Miller"},
  {"season": 9, "episode": 15, "scene": 35, "line": "[bursts into Andy's office] We're breaking up. And just so you know, I was worried that you were dead. You were gone for three months.", "order": 55144, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 15, "scene": 35, "line": "[on phone] Hey, Andy. It's David. Still here. What was that about three months?", "order": 55145, "speaker": "David Wallace"},
  {"season": 9, "episode": 16, "scene": 1, "line": "...No, you lied to me Andy. You pretended to be in this office for three months and you were sailing on your boat!", "order": 55146, "speaker": "David Wallace"},
  {"season": 9, "episode": 16, "scene": 1, "line": "I resent that. I, I never lied to you.", "order": 55147, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 16, "scene": 1, "line": "Really? [reading from phone] 'Hey David, all is good in Scranton PA.'", "order": 55148, "speaker": "David Wallace"},
  {"season": 9, "episode": 16, "scene": 1, "line": "And all was good in Scranton PA that day.", "order": 55149, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 16, "scene": 1, "line": "By the way, Oscar says 'hi''", "order": 55150, "speaker": "David Wallace"},
  {"season": 9, "episode": 16, "scene": 1, "line": "Oscar says 'hi' all the time. He says all kinds of greetings. 'Hi' 'Hello' 'Hola' You're telling me you've never heard Oscar say 'hi'?", "order": 55151, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 16, "scene": 1, "line": "Andy.", "order": 55152, "speaker": "David Wallace"},
  {"season": 9, "episode": 16, "scene": 1, "line": "But you're calling me a liar.", "order": 55153, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 16, "scene": 1, "line": "Andy!", "order": 55154, "speaker": "David Wallace"},
  {"season": 9, "episode": 16, "scene": 1, "line": "By the way, that reminds me, I want to ask you about some of the lies you've been telling lately.", "order": 55155, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 16, "scene": 1, "line": "Watch it Andy!", "order": 55156, "speaker": "David Wallace"},
  {"season": 9, "episode": 16, "scene": 1, "line": "Oh, here we go, January seventh 2013. [Reading from phone] 'Hey Andy, all's well. Been meaning to make it down there but my wife's sick.' Well, which is it?! Is all well or is your wife sick?! BUSTED!", "order": 55157, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 16, "scene": 1, "line": "My wife?", "order": 55158, "speaker": "David Wallace"},
  {"season": 9, "episode": 16, "scene": 1, "line": "This has been a really tough time.", "order": 55159, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 16, "scene": 1, "line": "Yeah?", "order": 55160, "speaker": "David Wallace"},
  {"season": 9, "episode": 16, "scene": 1, "line": "Yeah.", "order": 55161, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 16, "scene": 1, "line": "Has it?", "order": 55162, "speaker": "David Wallace"},
  {"season": 9, "episode": 16, "scene": 1, "line": "Erin just dumped me and I can't remember any of the aha moments I had on the boat, and I know it sounds weird to say but I really miss my beard.", "order": 55163, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 16, "scene": 1, "line": "Shh.", "order": 55164, "speaker": "David Wallace"},
  {"season": 9, "episode": 16, "scene": 1, "line": "It was like a security blan-", "order": 55165, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 16, "scene": 1, "line": "Andy.", "order": 55166, "speaker": "David Wallace"},
  {"season": 9, "episode": 16, "scene": 1, "line": "-ket", "order": 55167, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 16, "scene": 1, "line": "Shh. I'm not gonna fire you.", "order": 55168, "speaker": "David Wallace"},
  {"season": 9, "episode": 16, "scene": 1, "line": "You're not?", "order": 55169, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 16, "scene": 1, "line": "No.", "order": 55170, "speaker": "David Wallace"},
  {"season": 9, "episode": 16, "scene": 1, "line": "That's awesome.", "order": 55171, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 16, "scene": 1, "line": "I wouldn't own the company if it wasn't for you. So...I owe you that.", "order": 55172, "speaker": "David Wallace"},
  {"season": 9, "episode": 16, "scene": 1, "line": "David, I'll be there for you. These five words I swear to you. When you breathe, I want to be here for you. I'll be there for you. That's a poem by J.B. Jovi. I want you to have it.", "order": 55173, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 16, "scene": 1, "line": "We are even...now. Understand? Got it?", "order": 55174, "speaker": "David Wallace"},
  {"season": 9, "episode": 16, "scene": 1, "line": "Crystal.", "order": 55175, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 16, "scene": 1, "line": "You are on very- Hey, very thin ice.", "order": 55176, "speaker": "David Wallace"},
  {"season": 9, "episode": 16, "scene": 1, "line": "Vanilla. [David looks confused] Vanilla Ice. It was a band.", "order": 55177, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 16, "scene": 2, "line": "Good morning.", "order": 55178, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 16, "scene": 2, "line": "Good morning.", "order": 55179, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 16, "scene": 2, "line": "And how are you on this fine- [chokes up, runs into office and slams door pulling the blinds closed]", "order": 55180, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 16, "scene": 3, "line": "[Crying] Ok, we ready? [cut] Don't use that part. Last week, Erin told me that our relationship would be proceeding without me. Now I have to see her everyday at work. Which is...brutal. When people say office relationships are a good idea, they never talk about what might happen if you break up.", "order": 55181, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 16, "scene": 4, "line": "[Moaning from office] Ahhh! Oooooh! ahh", "order": 55182, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 16, "scene": 4, "line": "He sounds like a wounded animal.", "order": 55183, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 16, "scene": 4, "line": "Should've put him out of his misery and just fired her.", "order": 55184, "speaker": "Stanley Hudson"},
  {"season": 9, "episode": 16, "scene": 4, "line": "I can't be around sad people, it makes me sad.", "order": 55185, "speaker": "Phyllis Vance"},
  {"season": 9, "episode": 16, "scene": 4, "line": "I'm the same way with horny people.", "order": 55186, "speaker": "Stanley Hudson"},
  {"season": 9, "episode": 16, "scene": 4, "line": "Ok. [Andy continues moaning]", "order": 55187, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 16, "scene": 5, "line": "Yes. Pete and I have started seeing each other and Andy still doesn't know. We thought that keeping it secret was more considerate to him. And hot for us. I mean I saw Pete's butt. It's sick.", "order": 55188, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 16, "scene": 6, "line": "[taking paper from Kevin] Oh that's mine! Um, I'll just, I'll get it out of the way for you.", "order": 55189, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 16, "scene": 7, "line": "Jim set up a job interview for me today in Philly. It's um, with a real estate company, which is a great fit for me because I live in a house and I know what a bathroom is. [laughs] I'm sorry, I am just very nervous because honestly this is all moving a lot faster than I expected. And, and because my resume can fit on a post-it note.", "order": 55190, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 16, "scene": 8, "line": "[on phone] No, don't just let her eat the grass, she'll puke it right up. OK, just put out two bowls and see which feed she prefers. I'm sorry to be taking up so much of your precious time, Mose, but she's your aunt too. Fine. I'll see what I can do.", "order": 55191, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 16, "scene": 9, "line": "[Jumps out from behind vending machine] I need you.", "order": 55192, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 16, "scene": 9, "line": "Ahh! Dwight!", "order": 55193, "speaker": "Angela Martin"},
  {"season": 9, "episode": 16, "scene": 9, "line": "And you should take breaks more often, I've been waiting there for 45 minutes.", "order": 55194, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 16, "scene": 9, "line": "What? What is it?", "order": 55195, "speaker": "Angela Martin"},
  {"season": 9, "episode": 16, "scene": 9, "line": "It's my aunt Shirley, she's on her last legs.", "order": 55196, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 16, "scene": 9, "line": "Dwight, that's awful.", "order": 55197, "speaker": "Angela Martin"},
  {"season": 9, "episode": 16, "scene": 9, "line": "You have no idea. I mean her hair, clothes, it's all falling off in great big clumps. And we need someone to go out there and clean her up. We had a nurse, but she quit because she was 'poisoned' by Aunt Shirley.", "order": 55198, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 16, "scene": 9, "line": "What do you mean by 'poisoned'?", "order": 55199, "speaker": "Angela Martin"},
  {"season": 9, "episode": 16, "scene": 9, "line": "Probably nothing, or strychnine. Or lemonade and strychnine. Which is actually what it was.", "order": 55200, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 16, "scene": 9, "line": "Ok, well I'm very sorry about your aunt.", "order": 55201, "speaker": "Angela Martin"},
  {"season": 9, "episode": 16, "scene": 9, "line": "Thank you.", "order": 55202, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 16, "scene": 9, "line": "But I don't see how this is my problem.", "order": 55203, "speaker": "Angela Martin"},
  {"season": 9, "episode": 16, "scene": 9, "line": "Angela. You owe me one, remember? Now please, she's an old woman Angela. She needs a woman's touch. It's all hanging out-", "order": 55204, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 16, "scene": 9, "line": "Ugh.", "order": 55205, "speaker": "Angela Martin"},
  {"season": 9, "episode": 16, "scene": 9, "line": "And there's parts of her I don't even recognize.", "order": 55206, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 16, "scene": 9, "line": "Gah.", "order": 55207, "speaker": "Angela Martin"},
  {"season": 9, "episode": 16, "scene": 9, "line": "There's this one hanging part in particular, that's some sort of flap.", "order": 55208, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 16, "scene": 9, "line": "It's fine.", "order": 55209, "speaker": "Angela Martin"},
  {"season": 9, "episode": 16, "scene": 9, "line": "It's like a prehensile wing or something, you know?", "order": 55210, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 16, "scene": 9, "line": "Ugh! God, I can't. OK, I'll-", "order": 55211, "speaker": "Angela Martin"},
  {"season": 9, "episode": 16, "scene": 9, "line": "It's a divet...", "order": 55212, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 16, "scene": 9, "line": "I'll help you!", "order": 55213, "speaker": "Angela Martin"},
  {"season": 9, "episode": 16, "scene": 9, "line": "..where it was and it needs, it needs a...", "order": 55214, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 16, "scene": 9, "line": "Ugh.", "order": 55215, "speaker": "Angela Martin"},
  {"season": 9, "episode": 16, "scene": 10, "line": "Where are you going?", "order": 55216, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 16, "scene": 10, "line": "Uh, not on a three month boat trip.", "order": 55217, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 16, "scene": 10, "line": "Wha? Oh, burn. [laughs, Angela and Dwight move to leave.] Uh, excuse me. What, everyone can just leave whenever they want now?", "order": 55218, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 16, "scene": 10, "line": "How dare you?", "order": 55219, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 16, "scene": 10, "line": "I'm still the boss!", "order": 55220, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 16, "scene": 11, "line": "I...", "order": 55221, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 16, "scene": 11, "line": "The answer is yes.", "order": 55222, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 16, "scene": 11, "line": "..just have some messages for you.", "order": 55223, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 16, "scene": 11, "line": "Are they from you?", "order": 55224, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 16, "scene": 11, "line": "Well no, they're from clients.", "order": 55225, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 16, "scene": 11, "line": "Well then, I don't want them. You can keep 'em. In fact, you can keep that big blue Nautica sweater, I know how much you love it.", "order": 55226, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 16, "scene": 11, "line": "I can't. I-", "order": 55227, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 16, "scene": 11, "line": "Nope, I insist upon it. It's an awesome sweater. Some great memories tied up in that thing.", "order": 55228, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 16, "scene": 11, "line": "These are messages from clients who want to buy paper.", "order": 55229, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 16, "scene": 11, "line": "I don't want to talk about work right now.", "order": 55230, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 16, "scene": 11, "line": "Well I only want to talk about work right now.", "order": 55231, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 16, "scene": 11, "line": "Then I want my big blue sweater back.", "order": 55232, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 16, "scene": 11, "line": "Well, I gave it to the Salvation Army.", "order": 55233, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 16, "scene": 12, "line": "I've got the seat adjusted right.", "order": 55234, "speaker": "Pete Miller"},
  {"season": 9, "episode": 16, "scene": 12, "line": "Perfect height, yeah.", "order": 55235, "speaker": "Clark Green"},
  {"season": 9, "episode": 16, "scene": 12, "line": "She got rid of it. My blue sweater. What is that about?", "order": 55236, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 16, "scene": 12, "line": "I was just leaving.", "order": 55237, "speaker": "Pete Miller"},
  {"season": 9, "episode": 16, "scene": 12, "line": "Stay! We are in the bro-zone layer. Ok? Nard dog, Plop and Clarker Posey, AKA Clarkwork Orange. Here's the sitch: Erin dumped me, natch. But she got rid of my blue sweater, which was her favorite. Is she moving on a little fast, or am I being a total psycho? Plop, you go first.", "order": 55238, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 16, "scene": 12, "line": "I don't know. Women do tend to move on quicker than men.", "order": 55239, "speaker": "Pete Miller"},
  {"season": 9, "episode": 16, "scene": 12, "line": "Survey says: ENH! Doesn't make me feel better at all. Zero Clark Thirty, what do you got?", "order": 55240, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 16, "scene": 12, "line": "Look at it this way, being a bachelor is not all bad. I mean, you've got your freedom now.", "order": 55241, "speaker": "Clark Green"},
  {"season": 9, "episode": 16, "scene": 12, "line": "Last night I ordered a pizza by myself and I ate it over the sink like a rat.", "order": 55242, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 16, "scene": 12, "line": "There you go, good for you.", "order": 55243, "speaker": "Clark Green"},
  {"season": 9, "episode": 16, "scene": 12, "line": "No.", "order": 55244, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 16, "scene": 12, "line": "You just let it all hang out, that's what...", "order": 55245, "speaker": "Clark Green"},
  {"season": 9, "episode": 16, "scene": 12, "line": "May not seem like it, but this really helped. So, thank you. [Andy leaves]", "order": 55246, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 16, "scene": 12, "line": "I'll give you a hundred dollars to wear that sweater to work tomorrow.", "order": 55247, "speaker": "Clark Green"},
  {"season": 9, "episode": 16, "scene": 13, "line": "Aunt Shirley, Hello! It's me Dwight.", "order": 55248, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 16, "scene": 13, "line": "Oh, lookie here. It's big city Dwight. Careful you don't get mud on those fancy town shoes, big city Dwight.", "order": 55249, "speaker": "Shirley"},
  {"season": 9, "episode": 16, "scene": 13, "line": "Hello Aunt Shirley.", "order": 55250, "speaker": "Angela Martin"},
  {"season": 9, "episode": 16, "scene": 13, "line": "Who's this little kitchen witch? She's so tiny like a little kitchen witch.", "order": 55251, "speaker": "Shirley"},
  {"season": 9, "episode": 16, "scene": 13, "line": "This is Angela, and we brought you some new clothes!", "order": 55252, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 16, "scene": 13, "line": "New clothes? What for? [Aunt Shirley's boob shows]", "order": 55253, "speaker": "Shirley"},
  {"season": 9, "episode": 16, "scene": 13, "line": "OK", "order": 55254, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 16, "scene": 13, "line": "Oh, God.", "order": 55255, "speaker": "Angela Martin"},
  {"season": 9, "episode": 16, "scene": 13, "line": "You know what? Before you put the new clothes on, Angela's gonna get you cleaned up a little bit.", "order": 55256, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 16, "scene": 13, "line": "Yes. How would you like a nice warm bath?", "order": 55257, "speaker": "Angela Martin"},
  {"season": 9, "episode": 16, "scene": 13, "line": "How would you like a mean cold slap? [slaps Angela]", "order": 55258, "speaker": "Shirley"},
  {"season": 9, "episode": 16, "scene": 13, "line": "Ow!", "order": 55259, "speaker": "Angela Martin"},
  {"season": 9, "episode": 16, "scene": 13, "line": "Ok, Aunt Shirley, dear, uh, can I get you a nice crisp liter of schnapps?", "order": 55260, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 16, "scene": 13, "line": "I could do that.", "order": 55261, "speaker": "Shirley"},
  {"season": 9, "episode": 16, "scene": 13, "line": "Ok.", "order": 55262, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 16, "scene": 13, "line": "No. No.", "order": 55263, "speaker": "Angela Martin"},
  {"season": 9, "episode": 16, "scene": 13, "line": "Yeah, trust me.", "order": 55264, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 16, "scene": 13, "line": "No.", "order": 55265, "speaker": "Angela Martin"},
  {"season": 9, "episode": 16, "scene": 13, "line": "It keeps her docile. Go to the kitchen, get the largest receptacle you can find.", "order": 55266, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 16, "scene": 13, "line": "Step on it!", "order": 55267, "speaker": "Shirley"},
  {"season": 9, "episode": 16, "scene": 14, "line": "Why doesn't Erin seem sad? Has she found someone new or something? And if so, why hasn't she told me? Is she trying to spare my feelings? During breakups, the mind goes to some crazy dark places.", "order": 55268, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 16, "scene": 15, "line": "Andy, don't. No good can come from snooping.", "order": 55269, "speaker": "Phyllis Vance"},
  {"season": 9, "episode": 16, "scene": 15, "line": "I'm not snooping, there's just some crud on her screen.", "order": 55270, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 16, "scene": 15, "line": "You're clearly snooping.", "order": 55271, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 16, "scene": 15, "line": "That's kinda uncool, man.", "order": 55272, "speaker": "Creed Bratton"},
  {"season": 9, "episode": 16, "scene": 15, "line": "Ah, come on.", "order": 55273, "speaker": "Meredith Palmer"},
  {"season": 9, "episode": 16, "scene": 15, "line": "Andy!", "order": 55274, "speaker": "Phyllis Vance"},
  {"season": 9, "episode": 16, "scene": 15, "line": "That's her private property.", "order": 55275, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 16, "scene": 15, "line": "Tell us!", "order": 55276, "speaker": "Meredith Palmer"},
  {"season": 9, "episode": 16, "scene": 15, "line": "Uh, hello! Who's snooping on who now?", "order": 55277, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 16, "scene": 15, "line": "What does that even mean?", "order": 55278, "speaker": "Phyllis Vance"},
  {"season": 9, "episode": 16, "scene": 15, "line": "What's it say?", "order": 55279, "speaker": "Meredith Palmer"},
  {"season": 9, "episode": 16, "scene": 15, "line": "Put it down.", "order": 55280, "speaker": "Phyllis Vance"},
  {"season": 9, "episode": 16, "scene": 15, "line": "Everyone please, just-", "order": 55281, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 16, "scene": 15, "line": "It's not cool.", "order": 55282, "speaker": "Creed Bratton"},
  {"season": 9, "episode": 16, "scene": 15, "line": "Put it down", "order": 55283, "speaker": "Phyllis Vance"},
  {"season": 9, "episode": 16, "scene": 15, "line": "Andy. That is her private property.", "order": 55284, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 16, "scene": 15, "line": "Boo.", "order": 55285, "speaker": "Meredith Palmer"},
  {"season": 9, "episode": 16, "scene": 15, "line": "Oh my god.", "order": 55286, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 16, "scene": 15, "line": "Uh huh.", "order": 55287, "speaker": "Stanley Hudson"},
  {"season": 9, "episode": 16, "scene": 15, "line": "See?", "order": 55288, "speaker": "Phyllis Vance"},
  {"season": 9, "episode": 16, "scene": 15, "line": "That's where nosey'll get you.", "order": 55289, "speaker": "Stanley Hudson"},
  {"season": 9, "episode": 16, "scene": 15, "line": "Told you so.", "order": 55290, "speaker": "Phyllis Vance"},
  {"season": 9, "episode": 16, "scene": 16, "line": "Darryl, Clark, Toby, Kevin, Plop. Take a knee. Alright, you guys are gonna think I'm psycho again. Uh, couldn't shake this feeling that Erin's dating someone so I looked at her phone.", "order": 55291, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 16, "scene": 16, "line": "Man, you can't do that stuff. You'll only find pain. When my ex-wife got into my e-mail account, she was devastated.", "order": 55292, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 16, "scene": 16, "line": "Too late. I found out she's been texting a guy named Pete. Does anybody know a Pete?", "order": 55293, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 16, "scene": 16, "line": "Pete...", "order": 55294, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 16, "scene": 16, "line": "Hmm.", "order": 55295, "speaker": "Clark Green"},
  {"season": 9, "episode": 16, "scene": 16, "line": "Pete what?", "order": 55296, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 16, "scene": 17, "line": "It just occurred to me that Andy has been calling me Plop for so long, he forgot my real name. Which is Pete.", "order": 55297, "speaker": "Pete Miller"},
  {"season": 9, "episode": 16, "scene": 18, "line": "[whispers] hello!", "order": 55298, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 16, "scene": 18, "line": "Hey! There she is. [Pam laughs] How you doin?", "order": 55299, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 16, "scene": 18, "line": "Hi! Hey, do I look ok?", "order": 55300, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 16, "scene": 18, "line": "You look great.", "order": 55301, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 16, "scene": 18, "line": "Ok", "order": 55302, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 16, "scene": 18, "line": "Yeah.", "order": 55303, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 16, "scene": 18, "line": "[pointing to Jim's bluetooth headset] What's that? Is that a-", "order": 55304, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 16, "scene": 18, "line": "Ok, I know where you're going with this, and this is who I am now. I'm a douche. But look what I can do with my hands. [Pam laughs]", "order": 55305, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 16, "scene": 18, "line": "Gotta go, VIP just walked through the door.", "order": 55306, "speaker": "Isaac"},
  {"season": 9, "episode": 16, "scene": 18, "line": "How are you, Isaac? You have something in your ear.", "order": 55307, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 16, "scene": 18, "line": "It's a phone?", "order": 55308, "speaker": "Isaac"},
  {"season": 9, "episode": 16, "scene": 18, "line": "Yes. It is. And thank you so much for setting up this opportunity, I really appreciate it.", "order": 55309, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 16, "scene": 18, "line": "Of course, anything for Team Halpert. You're gonna crush it, Pam.", "order": 55310, "speaker": "Isaac"},
  {"season": 9, "episode": 16, "scene": 18, "line": "Thanks.", "order": 55311, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 16, "scene": 18, "line": "You are gonna crush it. Wow, I missed you.", "order": 55312, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 16, "scene": 18, "line": "Wait, are you saying that into the phone or are you saying that to me.", "order": 55313, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 16, "scene": 18, "line": "[touches headset] Call you right back. What were you saying?", "order": 55314, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 16, "scene": 18, "line": "Ha ha.", "order": 55315, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 16, "scene": 18, "line": "Hey, Jim! We've got Trent Edwards on the line.", "order": 55316, "speaker": "Athlead Coworker"},
  {"season": 9, "episode": 16, "scene": 18, "line": "I've gotta go, but you know what? Team Halpert, ok? You're gonna crush it, you're gonna smash it.[Pam laughs] OK, good luck!", "order": 55317, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 16, "scene": 18, "line": "Bye.", "order": 55318, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 16, "scene": 19, "line": "Gosh, she drank so much. And so quickly.", "order": 55319, "speaker": "Angela Martin"},
  {"season": 9, "episode": 16, "scene": 19, "line": "In her prime, Shirles could put away homemade schnapps morning noon and night.", "order": 55320, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 16, "scene": 19, "line": "[coughs and laughs in her sleep]", "order": 55321, "speaker": "Shirley"},
  {"season": 9, "episode": 16, "scene": 19, "line": "Now all it takes is half a liter. She's dreaming. Alright, let's get her out to the yard so you can spray her down.", "order": 55322, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 16, "scene": 19, "line": "Spray her down?", "order": 55323, "speaker": "Angela Martin"},
  {"season": 9, "episode": 16, "scene": 19, "line": "No, it's a lot better than it sounds. There's a private shower area. Very tasteful, very rustic.", "order": 55324, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 16, "scene": 20, "line": "Hey.", "order": 55325, "speaker": "Pete Miller"},
  {"season": 9, "episode": 16, "scene": 20, "line": "Come on in. Have a seat. Uh, thanks for coming in, I just gotta get something off my chest. I just got some really weird news and uh, I'm just gonna come out and say it. I just got off the phone with my doctor, and it turns out I contracted [reading computer screen] shlmydia...from Erin. And it's incurable. Pretty lame huh?", "order": 55326, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 16, "scene": 20, "line": "Yeah. [long pause] You were gone.", "order": 55327, "speaker": "Pete Miller"},
  {"season": 9, "episode": 16, "scene": 20, "line": "I knew it!", "order": 55328, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 16, "scene": 20, "line": "For a long time, Andy.", "order": 55329, "speaker": "Pete Miller"},
  {"season": 9, "episode": 16, "scene": 20, "line": "You and Erin are fuhhhhhhherraaaaa!", "order": 55330, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 16, "scene": 20, "line": "Andy, just so you know, there was no overlap.", "order": 55331, "speaker": "Pete Miller"},
  {"season": 9, "episode": 16, "scene": 20, "line": "No overlap? Great. Good. Wow. This is suddenly so easy. Guess what? You're fired!", "order": 55332, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 16, "scene": 20, "line": "What?", "order": 55333, "speaker": "Pete Miller"},
  {"season": 9, "episode": 16, "scene": 20, "line": "Yeah. You. Are. Fired! One of the perks of being boss. I can fire anyone who steals my girlfriend. And wow, that turns out to be you. Yup. Sorry. [singing] So you had a bad day-", "order": 55334, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 16, "scene": 20, "line": "Andy?", "order": 55335, "speaker": "Pete Miller"},
  {"season": 9, "episode": 16, "scene": 20, "line": "..The camera don't lie!", "order": 55336, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 16, "scene": 20, "line": "Andy.", "order": 55337, "speaker": "Pete Miller"},
  {"season": 9, "episode": 16, "scene": 20, "line": "You're being an idiot get..", "order": 55338, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 16, "scene": 20, "line": "I'm trying..", "order": 55339, "speaker": "Pete Miller"},
  {"season": 9, "episode": 16, "scene": 20, "line": "Out of my office, turns out you're fired...", "order": 55340, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 16, "scene": 20, "line": "Andy.", "order": 55341, "speaker": "Pete Miller"},
  {"season": 9, "episode": 16, "scene": 20, "line": "Because you suck.", "order": 55342, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 16, "scene": 20, "line": "You can't fire-", "order": 55343, "speaker": "Pete Miller"},
  {"season": 9, "episode": 16, "scene": 20, "line": "And you're fired...", "order": 55344, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 16, "scene": 20, "line": "If you want to talk to me", "order": 55345, "speaker": "Pete Miller"},
  {"season": 9, "episode": 16, "scene": 20, "line": "So you had a bad day...", "order": 55346, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 16, "scene": 20, "line": "I'll be in the annex.", "order": 55347, "speaker": "Pete Miller"},
  {"season": 9, "episode": 16, "scene": 20, "line": "Rut ti doh doh...", "order": 55348, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 16, "scene": 20, "line": "Alright? I'll be in the annex.", "order": 55349, "speaker": "Pete Miller"},
  {"season": 9, "episode": 16, "scene": 20, "line": "Rut tit doh doh...", "order": 55350, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 16, "scene": 20, "line": "Toby!", "order": 55351, "speaker": "Pete Miller"},
  {"season": 9, "episode": 16, "scene": 20, "line": "Rut ti doh doo doh", "order": 55352, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 16, "scene": 20, "line": "Toby?", "order": 55353, "speaker": "Pete Miller"},
  {"season": 9, "episode": 16, "scene": 21, "line": "You can't fire Pete. You understand why, right?", "order": 55354, "speaker": "Toby Flenderson"},
  {"season": 9, "episode": 16, "scene": 21, "line": "No.", "order": 55355, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 16, "scene": 21, "line": "Oh, Andy, we had this exact same conversation when you wanted to get rid of Nellie. You can't just get rid of people over grudges.", "order": 55356, "speaker": "Toby Flenderson"},
  {"season": 9, "episode": 16, "scene": 21, "line": "Nellie was a professional grudge. This is a purely personal grudge.", "order": 55357, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 16, "scene": 21, "line": "Alright, well look. While I have you here, this is a relationship disclosure form for Pete & Erin.", "order": 55358, "speaker": "Toby Flenderson"},
  {"season": 9, "episode": 16, "scene": 21, "line": "They already have a contract? [Reading] 'Mutually agree to-' Ah, every phrase is like a dagger in my crotch.", "order": 55359, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 16, "scene": 21, "line": "It's just boiler plating, you don't have to read it.", "order": 55360, "speaker": "Toby Flenderson"},
  {"season": 9, "episode": 16, "scene": 21, "line": "Well, I'm not signing away my rights.", "order": 55361, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 16, "scene": 21, "line": "I already signed it. I was just showing you.", "order": 55362, "speaker": "Toby Flenderson"},
  {"season": 9, "episode": 16, "scene": 21, "line": "Ok, well we'll see about that. [crumples paper]", "order": 55363, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 16, "scene": 21, "line": "Andy, it's not the original. And destroying it will not stop them from dating. Andy. [Andy throws paper at him and leaves, Toby straightens paper out] It's the original.", "order": 55364, "speaker": "Toby Flenderson"},
  {"season": 9, "episode": 16, "scene": 22, "line": "...[singing] talking Chester avenue, talking triplex, talking converting...Is that her? Hey guys! Say something.", "order": 55365, "speaker": "Mark"},
  {"season": 9, "episode": 16, "scene": 22, "line": "Hello.", "order": 55366, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 16, "scene": 22, "line": "Hi, I'm Mark.", "order": 55367, "speaker": "Mark"},
  {"season": 9, "episode": 16, "scene": 22, "line": "Hi, Pam, hello.", "order": 55368, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 16, "scene": 22, "line": "I'm the horrible boss around here, but please don't hire Jamie Foxx to kill me. D'Jango! I don't agree with the use of the 'N' word in that movie. It's, it's too soon.", "order": 55369, "speaker": "Mark"},
  {"season": 9, "episode": 16, "scene": 22, "line": "I'm Pam Halpert.", "order": 55370, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 16, "scene": 22, "line": "Oh, hi. They call me Marky Mark around here, because here at Simon Realty, we are one funky bunch! Come on you guys, raise the roof when I say that! I- what are you all temps again today? Let's go, Gangnam style. [laughs] He's heard Gangnam style, he knows it. Right? That's cause he's American. This is Carl. Uh, he's from here. Our neck of the woods. But Gangnam style is great, isn't it?", "order": 55371, "speaker": "Mark"},
  {"season": 9, "episode": 16, "scene": 23, "line": "Oh my god. He's Michael Scott.", "order": 55372, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 16, "scene": 24, "line": "Time to get clean!", "order": 55373, "speaker": "Shirley"},
  {"season": 9, "episode": 16, "scene": 24, "line": "It's hosing time Aunt Shirley, have a seat. You're gonna have a hard time hearing her over the roar of the hose.", "order": 55374, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 16, "scene": 24, "line": "You have to use chains?", "order": 55375, "speaker": "Angela Martin"},
  {"season": 9, "episode": 16, "scene": 24, "line": "You'll see. Here we go.", "order": 55376, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 16, "scene": 24, "line": "Let's get this show on the road.", "order": 55377, "speaker": "Shirley"},
  {"season": 9, "episode": 16, "scene": 24, "line": "Let's do it! Ready? Here's a box cutter to get her clothes off.", "order": 55378, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 16, "scene": 24, "line": "Dwight!", "order": 55379, "speaker": "Angela Martin"},
  {"season": 9, "episode": 16, "scene": 24, "line": "Let's get to it.", "order": 55380, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 16, "scene": 24, "line": "No! No!", "order": 55381, "speaker": "Angela Martin"},
  {"season": 9, "episode": 16, "scene": 24, "line": "Give it a whirl.", "order": 55382, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 16, "scene": 24, "line": "Dwight, I am not gonna hose your aunt down like some animal.", "order": 55383, "speaker": "Angela Martin"},
  {"season": 9, "episode": 16, "scene": 24, "line": "Stop your belly-aching and hose me.", "order": 55384, "speaker": "Shirley"},
  {"season": 9, "episode": 16, "scene": 24, "line": "I need you to hose my aunt.", "order": 55385, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 16, "scene": 24, "line": "No Dwight!", "order": 55386, "speaker": "Angela Martin"},
  {"season": 9, "episode": 16, "scene": 24, "line": "OK you are useless.", "order": 55387, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 16, "scene": 24, "line": "No, Dwight!", "order": 55388, "speaker": "Angela Martin"},
  {"season": 9, "episode": 16, "scene": 24, "line": "Give me the hose!", "order": 55389, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 16, "scene": 24, "line": "No Dwight, I won't- [hoses Dwight]", "order": 55390, "speaker": "Angela Martin"},
  {"season": 9, "episode": 16, "scene": 24, "line": "Ahh! OK!", "order": 55391, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 16, "scene": 24, "line": "[grunting] I'm gonna give your aunt a proper bath and a haircut like a lady! And you two are gonna shut up about it! Do you have a bathtub?", "order": 55392, "speaker": "Angela Martin"},
  {"season": 9, "episode": 16, "scene": 24, "line": "Yes ma'am.", "order": 55393, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 16, "scene": 24, "line": "Good.", "order": 55394, "speaker": "Angela Martin"},
  {"season": 9, "episode": 16, "scene": 25, "line": "This is not an office so much as it is a uh, rec room with a bunch of computers in it. Frankly, if I had my way, I'd toss the computers in the gar-bage. But unfortunately we use them for practically everything we do. So. That ain't gonna happen. He's a temp, don't worry about him. Alice! Alright, stay awake ok?", "order": 55395, "speaker": "Mark"},
  {"season": 9, "episode": 16, "scene": 26, "line": "Hey Nellie. Mmm. I am so sick of February. It's the shortest month but it sure doesn't feel that way. We should catch up.", "order": 55396, "speaker": "Toby Flenderson"},
  {"season": 9, "episode": 16, "scene": 26, "line": "Um.", "order": 55397, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 16, "scene": 26, "line": "Y'know I've been going over my notes from the trial...", "order": 55398, "speaker": "Toby Flenderson"},
  {"season": 9, "episode": 16, "scene": 26, "line": "Oh no.", "order": 55399, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 16, "scene": 26, "line": "...feel like I may have glossed over a few...", "order": 55400, "speaker": "Toby Flenderson"},
  {"season": 9, "episode": 16, "scene": 26, "line": "No no no no.", "order": 55401, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 16, "scene": 26, "line": "...minor points.", "order": 55402, "speaker": "Toby Flenderson"},
  {"season": 9, "episode": 16, "scene": 26, "line": "No. Toby, you cannot keep blathering on about this Scranton Strangler. Do something about it. Get it out of your system, whatever it takes.", "order": 55403, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 16, "scene": 26, "line": "I've been drafting a letter.", "order": 55404, "speaker": "Toby Flenderson"},
  {"season": 9, "episode": 16, "scene": 26, "line": "For two years! Then what? Another year picking out a stamp? Another six months before you decide to lick it? Just- I don't want to hear it!", "order": 55405, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 16, "scene": 27, "line": "My aunt Joan. Oh, uh well, she uh, she worked here before I did, so there's no nepotism involved. In fact, to be honest with you I'm probably a little harder on her than I am on the rest of these people. Ah, cute. Not work on this...work on this months. Ok Nana? Uh, when I say 'Chillax' people chillax. Watch this. Hey Roger, chillax! Must not have heard me.", "order": 55406, "speaker": "Mark"},
  {"season": 9, "episode": 16, "scene": 27, "line": "No. I heard you.", "order": 55407, "speaker": "Roger"},
  {"season": 9, "episode": 16, "scene": 27, "line": "Step this way for The Spanish Inquisition! [laughs] Kids in the Hall. Just, it's not high pressure. Just a little Coffee Talk. Ok? Like butta. Come on in here. Mike Myers.", "order": 55408, "speaker": "Mark"},
  {"season": 9, "episode": 16, "scene": 27, "line": "[Mouths] Oh my god.", "order": 55409, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 16, "scene": 28, "line": "Thank you! Thanks, thanks a lot. Really appreciate it. You guys are supposed to have my back. OK? Instead you let a guy named Plop steal my girlfriend.", "order": 55410, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 16, "scene": 28, "line": "Hey boss, I did everything I could. I invited Pete out for drinks, I emailed him shots of my junk....", "order": 55411, "speaker": "Meredith Palmer"},
  {"season": 9, "episode": 16, "scene": 28, "line": "Ugh.", "order": 55412, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 16, "scene": 28, "line": "Kid doesn't have a romantic bone in his body.", "order": 55413, "speaker": "Meredith Palmer"},
  {"season": 9, "episode": 16, "scene": 28, "line": "Come on Andy, they're a good match.", "order": 55414, "speaker": "Phyllis Vance"},
  {"season": 9, "episode": 16, "scene": 28, "line": "That doesn't matter. Ok? What matters is that I am hurt! Deep hurt inside of me. I don't care if they're Romeo and freaking Juliet! I feel like the guy that Juliet dated before Romeo. Probably her boss. And guess what? Juliet's boss also had feelings.", "order": 55415, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 16, "scene": 28, "line": "Hey, Andy.", "order": 55416, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 16, "scene": 28, "line": "What?!", "order": 55417, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 16, "scene": 28, "line": "You've got a booger bubble going on there.", "order": 55418, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 16, "scene": 28, "line": "[wipes nose] Sorry.", "order": 55419, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 16, "scene": 28, "line": "It's ok.", "order": 55420, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 16, "scene": 28, "line": "My whole life is a booger bubble!", "order": 55421, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 16, "scene": 29, "line": "This is a tiny resume. Papa Smurf! Come back to the mushroom. [Pam laughs] From The Smurfs movie.", "order": 55422, "speaker": "Mark"},
  {"season": 9, "episode": 16, "scene": 29, "line": "Yeah, I've seen that with my kids. Um, it is tiny, but I've actually been commissioned by the City of Scranton to paint a mural-", "order": 55423, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 16, "scene": 29, "line": "What does this say here? To ti te per tat... what language is this? Swahili? Oh wait a second, now I can read it.", "order": 55424, "speaker": "Mark"},
  {"season": 9, "episode": 16, "scene": 29, "line": "Oh, cause it was upside down. [both laugh]", "order": 55425, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 16, "scene": 29, "line": "You're a good audience. [Pam laughs]", "order": 55426, "speaker": "Mark"},
  {"season": 9, "episode": 16, "scene": 29, "line": "So um-", "order": 55427, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 16, "scene": 29, "line": "Unlike some of these people around here.", "order": 55428, "speaker": "Mark"},
  {"season": 9, "episode": 16, "scene": 29, "line": "Yeah, the um, the city commissioned me to do the-", "order": 55429, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 16, "scene": 29, "line": "Yeah, we don't have a lot of call for doodling around here. But I like this resume and here's why. It shows that you stick around. Yeah. You don't jump ship easily. Like a lot of these people. I mean they worship me you know? But do they like me? I mean...you think they like me, Pam?", "order": 55430, "speaker": "Mark"},
  {"season": 9, "episode": 16, "scene": 29, "line": "Yes.", "order": 55431, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 16, "scene": 29, "line": "[gets guitar] What if Bob Dylan was your boss? I'm gonna do Dylan! [playing guitar and imitating Bob Dylan] Pam Halpert is my name, and I've been at Dunder Mifflin for seven years? Eight years? Eight years, man. Got the Dunder Mifflin blues. Got the Pam Halpert blues. Got the pra- went to the Pratt Institute...You have children?", "order": 55432, "speaker": "Mark"},
  {"season": 9, "episode": 16, "scene": 29, "line": "Two children, yes.", "order": 55433, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 16, "scene": 29, "line": "You wrote Art and Painting, kinda the same thing. Kinda the same thing. Sometimes I repeat myself, but that's just being Pam. Well I'm kinda cute and I'm- but I'm married so...leave that be.", "order": 55434, "speaker": "Mark"},
  {"season": 9, "episode": 16, "scene": 30, "line": "Hey. You got a sec to talk?", "order": 55435, "speaker": "Pete Miller"},
  {"season": 9, "episode": 16, "scene": 30, "line": "Kinda painful to chat with you Pete. Ever since the old one-two punch to my scrotum pole. Translation: penis. Translation? My manhood.", "order": 55436, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 16, "scene": 30, "line": "Yeah. Look, uh I understand breakups are tough. You know, it happens to all of us at some point in life. But you've gotta move on.", "order": 55437, "speaker": "Pete Miller"},
  {"season": 9, "episode": 16, "scene": 30, "line": "Great advice. Thank you, you can leave now.", "order": 55438, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 16, "scene": 30, "line": "Listen to him, Andy. He's trying to tell you something that you really need to hear.", "order": 55439, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 16, "scene": 30, "line": "Awesome perspective. Thanks for butting in.", "order": 55440, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 16, "scene": 30, "line": "Well, I've been where you are now. I dated this girl Alice and it was an ugly breakup. She worked at a marketing agency right next to my house. I'd run into her every day but I had to grow up and deal with it, and I did. We're even Facebook friends now.", "order": 55441, "speaker": "Pete Miller"},
  {"season": 9, "episode": 16, "scene": 30, "line": "See? We can all be friends!", "order": 55442, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 16, "scene": 30, "line": "Yeah.", "order": 55443, "speaker": "Pete Miller"},
  {"season": 9, "episode": 16, "scene": 30, "line": "Just, get over it. It doesn't have to be awkward.", "order": 55444, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 16, "scene": 30, "line": "I do think we can have a fair....", "order": 55445, "speaker": "Pete Miller"},
  {"season": 9, "episode": 16, "scene": 31, "line": "Yeah, so life gives you lemons and you've just gotta eat them, rinds and all. And if you don't want to eat them? Your ex-girlfriend will shove them down your throat with the help of her hunky new boyfriend. So that's fun.", "order": 55446, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 16, "scene": 32, "line": "Nothing to see here! Boss at work. This will be your desk. Right up front. Best seat on the roller coaster, you must be this tall to ride this ride. No pregnant women allowed. Are you? Uh, yeah I don't- not allowed to ask. So...", "order": 55447, "speaker": "Mark"},
  {"season": 9, "episode": 16, "scene": 32, "line": "I am not pr-", "order": 55448, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 16, "scene": 32, "line": "You're not.", "order": 55449, "speaker": "Mark"},
  {"season": 9, "episode": 16, "scene": 32, "line": "Pregnant, no.", "order": 55450, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 16, "scene": 32, "line": "I didn't ask her if she was pregnant. She just offered it. The last three girls here all got pregnant.", "order": 55451, "speaker": "Mark"},
  {"season": 9, "episode": 16, "scene": 32, "line": "Wow.", "order": 55452, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 16, "scene": 32, "line": "Don't be afraid, it's a different chair. I don't want a guy up here. I want to you know, see a woman come in and do a great job. Something that, I have to look out this window, I want someone-", "order": 55453, "speaker": "Mark"},
  {"season": 9, "episode": 16, "scene": 32, "line": "I'm sorry I thought this job was for the position of office manager.", "order": 55454, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 16, "scene": 32, "line": "It is. Yeah, you would uh manage this office. Answer the phones and forward the calls and uh you know, go for a coffee run now and again.", "order": 55455, "speaker": "Mark"},
  {"season": 9, "episode": 16, "scene": 32, "line": "So, kinda like a receptionist.", "order": 55456, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 16, "scene": 32, "line": "Yeah, like a receptionist, but we call you the office manager because uh, it's less demeaning. By the way, how long are these uh, cameras gonna be following you around? Because I think this is pretty cool. Pretty pretty cool. Larry David, Curb Your Enthusiasm. Do you like that show?", "order": 55457, "speaker": "Mark"},
  {"season": 9, "episode": 16, "scene": 32, "line": "Yes.", "order": 55458, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 16, "scene": 32, "line": "Well, I think they indulge themselves a little too much. I like scripted.", "order": 55459, "speaker": "Mark"},
  {"season": 9, "episode": 16, "scene": 33, "line": "I spent 10 years as a receptionist, to Michael Scott. And I have kids now. And I just, I can't.", "order": 55460, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 16, "scene": 34, "line": "Ow! The braid is too tight.", "order": 55461, "speaker": "Shirley"},
  {"season": 9, "episode": 16, "scene": 34, "line": "Oh hush. Loose braids reflect a loose character. Now stay still.", "order": 55462, "speaker": "Angela Martin"},
  {"season": 9, "episode": 16, "scene": 34, "line": "Yes ma'am.", "order": 55463, "speaker": "Shirley"},
  {"season": 9, "episode": 16, "scene": 34, "line": "I think your hair is much too long for your age, by the way.", "order": 55464, "speaker": "Angela Martin"},
  {"season": 9, "episode": 16, "scene": 34, "line": "Ok.", "order": 55465, "speaker": "Shirley"},
  {"season": 9, "episode": 16, "scene": 34, "line": "There.", "order": 55466, "speaker": "Angela Martin"},
  {"season": 9, "episode": 16, "scene": 34, "line": "Thank you Angela.", "order": 55467, "speaker": "Shirley"},
  {"season": 9, "episode": 16, "scene": 35, "line": "I'm going to the prison. This afternoon. I'm gonna talk to the strangler.", "order": 55468, "speaker": "Toby Flenderson"},
  {"season": 9, "episode": 16, "scene": 35, "line": "Probably best to use his real name rather than strangler.", "order": 55469, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 16, "scene": 35, "line": "Don't use his real name. George Howard Scubb. It's a devil name.", "order": 55470, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 16, "scene": 35, "line": "Well I just wanted to say I'm doing it. [Toby leaves]", "order": 55471, "speaker": "Toby Flenderson"},
  {"season": 9, "episode": 16, "scene": 35, "line": "He's doing it.", "order": 55472, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 16, "scene": 36, "line": "[on phone] hey!", "order": 55473, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 16, "scene": 36, "line": "Hey, how'd the interview go?", "order": 55474, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 16, "scene": 36, "line": "Oh my gosh, wait until you. This guy was unbelievable. Ok so-", "order": 55475, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 16, "scene": 36, "line": "I can't wait to hear about it later. Do you want to come in at eight? And uh, don't eat because I'm ordering in.", "order": 55476, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 16, "scene": 36, "line": "Eight? Really?", "order": 55477, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 16, "scene": 36, "line": "I'll make it worth your while, I promise.", "order": 55478, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 16, "scene": 36, "line": "Sure. I mean, it's Philly. I can kill four hours. So uh, yeah. I'll see you at eight.", "order": 55479, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 16, "scene": 36, "line": "Alright, love you.", "order": 55480, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 16, "scene": 36, "line": "Love you.", "order": 55481, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 16, "scene": 37, "line": "Hi.", "order": 55482, "speaker": "Alice"},
  {"season": 9, "episode": 16, "scene": 37, "line": "Hi.", "order": 55483, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 16, "scene": 37, "line": "I'm here from BCI Marketing Consultants to meet with Andrew Bernard.", "order": 55484, "speaker": "Alice"},
  {"season": 9, "episode": 16, "scene": 37, "line": "Yes. The consultant. Andy said you can start right away so I will take you to your desk.", "order": 55485, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 16, "scene": 37, "line": "Ok, great. Thanks.", "order": 55486, "speaker": "Alice"},
  {"season": 9, "episode": 16, "scene": 37, "line": "Fresh meat! Fresh meat! [Making kissing noises]", "order": 55487, "speaker": "Meredith Palmer"},
  {"season": 9, "episode": 16, "scene": 37, "line": "Just keep walking, don't give her anything. She'll take it and run. I'm Erin by the way.", "order": 55488, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 16, "scene": 37, "line": "Nice to meet you. Cute sweater.", "order": 55489, "speaker": "Alice"},
  {"season": 9, "episode": 16, "scene": 37, "line": "Oh, thanks. Your shoes match. I'm bad at small talk.", "order": 55490, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 16, "scene": 37, "line": "I'm Kevin.", "order": 55491, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 16, "scene": 37, "line": "Pete?", "order": 55492, "speaker": "Alice"},
  {"season": 9, "episode": 16, "scene": 37, "line": "Alice. Oh man.", "order": 55493, "speaker": "Pete Miller"},
  {"season": 9, "episode": 16, "scene": 37, "line": "It's uh, been a while, huh?", "order": 55494, "speaker": "Alice"},
  {"season": 9, "episode": 16, "scene": 37, "line": "What, do you two know each other?", "order": 55495, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 16, "scene": 37, "line": "Yeah. We uh, have a history.", "order": 55496, "speaker": "Pete Miller"},
  {"season": 9, "episode": 16, "scene": 37, "line": "Oh.", "order": 55497, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 16, "scene": 37, "line": "History. Wow, ok. We dated for two years.", "order": 55498, "speaker": "Alice"},
  {"season": 9, "episode": 16, "scene": 37, "line": "That's so random.", "order": 55499, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 16, "scene": 37, "line": "Well. Is it?", "order": 55500, "speaker": "Pete Miller"},
  {"season": 9, "episode": 16, "scene": 37, "line": "Andy also hired a management consultant today. Oh no. [runs to front office]", "order": 55501, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 16, "scene": 37, "line": "Hey Erin, look who's back. The bird man.", "order": 55502, "speaker": "Creed Bratton"},
  {"season": 9, "episode": 16, "scene": 37, "line": "Hello beautiful.", "order": 55503, "speaker": "Gabe Lewis"},
  {"season": 9, "episode": 16, "scene": 37, "line": "Didn't you two used to do it?", "order": 55504, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 16, "scene": 37, "line": "We absolutely did. Thank you for remembering that.", "order": 55505, "speaker": "Gabe Lewis"},
  {"season": 9, "episode": 16, "scene": 37, "line": "She's looking good.", "order": 55506, "speaker": "Creed Bratton"},
  {"season": 9, "episode": 16, "scene": 38, "line": "Hi, I'm uh, Toby Flenderson. I'm here to see George Howard Scubb.", "order": 55507, "speaker": "Toby Flenderson"},
  {"season": 9, "episode": 16, "scene": 39, "line": "This is the prison. Uh, I am not going in there with expectations, per say. Uh, I will meet George Howard Scubb. I will tell him that I believe he is innocent. I would understand if he felt motivated to hug me. I would understand if a friendship began. How did, how did Bogart put it? [imitating Humphrey Bogart] I think this is a start of my first friendship.", "order": 55508, "speaker": "Toby Flenderson"},
  {"season": 9, "episode": 16, "scene": 40, "line": "So Pete was a librarian?", "order": 55509, "speaker": "Clark Green"},
  {"season": 9, "episode": 16, "scene": 40, "line": "He worked as a librarian freshman year.", "order": 55510, "speaker": "Alice"},
  {"season": 9, "episode": 16, "scene": 40, "line": "Was he like the sexy librarian?", "order": 55511, "speaker": "Clark Green"},
  {"season": 9, "episode": 16, "scene": 40, "line": "Ok.", "order": 55512, "speaker": "Pete Miller"},
  {"season": 9, "episode": 16, "scene": 40, "line": "Is there like somebody who's in charge of marketing? Maybe I should sit near him or her.", "order": 55513, "speaker": "Alice"},
  {"season": 9, "episode": 16, "scene": 40, "line": "Hi.", "order": 55514, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 16, "scene": 40, "line": "Hi.", "order": 55515, "speaker": "Alice"},
  {"season": 9, "episode": 16, "scene": 40, "line": "Hi, how's it going?", "order": 55516, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 16, "scene": 40, "line": "Hi. [laughs] Good. I'd love to discuss strategy with you if you have a marketing p-", "order": 55517, "speaker": "Alice"},
  {"season": 9, "episode": 16, "scene": 40, "line": "Wow this sure is intense. Having to share a workspace with someone you used to get it on with?", "order": 55518, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 16, "scene": 40, "line": "Andy, that is really inappropriate.", "order": 55519, "speaker": "Pete Miller"},
  {"season": 9, "episode": 16, "scene": 40, "line": "Awkward.", "order": 55520, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 16, "scene": 40, "line": "It is awkward. This is a really uncomfortable situation that you've contrived.", "order": 55521, "speaker": "Pete Miller"},
  {"season": 9, "episode": 16, "scene": 40, "line": "[high pitched] Really uncomfortable situation.", "order": 55522, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 16, "scene": 40, "line": "Yeah.", "order": 55523, "speaker": "Pete Miller"},
  {"season": 9, "episode": 16, "scene": 40, "line": "It's alright Pete, you can handle it. I mean we all just gotta 'move on'. Ain't that right professor lecture much? Uh, question. How's that medicine taste? Your own flavored? Is it just me or have these tables turned? Hmm. Hmm. [leaves]", "order": 55524, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 16, "scene": 40, "line": "So there's no marketing department.", "order": 55525, "speaker": "Alice"},
  {"season": 9, "episode": 16, "scene": 40, "line": "No.", "order": 55526, "speaker": "Clark Green"},
  {"season": 9, "episode": 16, "scene": 40, "line": "No.", "order": 55527, "speaker": "Pete Miller"},
  {"season": 9, "episode": 16, "scene": 41, "line": "You know, times were tough. I was unemployed, I was still heart-broken over you, I've lost a good fifty pounds. But as you can see I put all that weight right back on. Feel how fat my buttocks are. Yeah, it's crazy. Touch it. It's like a warm pumpkin.", "order": 55528, "speaker": "Gabe Lewis"},
  {"season": 9, "episode": 16, "scene": 41, "line": "So Andy just called you up out of the blue?", "order": 55529, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 16, "scene": 41, "line": "Yeah. He told me you two broke up.", "order": 55530, "speaker": "Gabe Lewis"},
  {"season": 9, "episode": 16, "scene": 41, "line": "Yeah.", "order": 55531, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 16, "scene": 41, "line": "You must be pretty horny. [Erin shakes head no]", "order": 55532, "speaker": "Gabe Lewis"},
  {"season": 9, "episode": 16, "scene": 42, "line": "well, the good news is no more guilty conscience. At least you know he is the strangler. The proof is in the grip. Did they say when the vocal cords would heal? [Toby nods] One week? [Toby shakes head] Ok, two weeks? [Toby nods] Ok. You offered your neck in search of the truth. The proud neck of justice. Isn't that the expression? No. Well, anyway, it was, it was very brave. It really was quite brave.", "order": 55533, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 16, "scene": 43, "line": "I feel like a show pony.", "order": 55534, "speaker": "Shirley"},
  {"season": 9, "episode": 16, "scene": 43, "line": "And you look like one too. Thank you Angela.", "order": 55535, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 16, "scene": 43, "line": "You're welcome. Would you like some stew?", "order": 55536, "speaker": "Angela Martin"},
  {"season": 9, "episode": 16, "scene": 43, "line": "By all means. And I will carve the roast skunk. Angela?", "order": 55537, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 16, "scene": 43, "line": "Mmhm.", "order": 55538, "speaker": "Angela Martin"},
  {"season": 9, "episode": 16, "scene": 43, "line": "Would you like the stink sack?", "order": 55539, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 16, "scene": 43, "line": "Is it any good?", "order": 55540, "speaker": "Angela Martin"},
  {"season": 9, "episode": 16, "scene": 43, "line": "No, you don't eat it. It's a toy, like a wish bone. You know, prettiest girl gets the stink sack.", "order": 55541, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 16, "scene": 43, "line": "Thank you. [both laugh]", "order": 55542, "speaker": "Angela Martin"},
  {"season": 9, "episode": 16, "scene": 43, "line": "So, when's the wedding?", "order": 55543, "speaker": "Shirley"},
  {"season": 9, "episode": 16, "scene": 43, "line": "Oh, um actually uh, we are just friends.", "order": 55544, "speaker": "Angela Martin"},
  {"season": 9, "episode": 16, "scene": 43, "line": "That's what Mose said about his lady scarecrow and look what he did to that poor thing.", "order": 55545, "speaker": "Shirley"},
  {"season": 9, "episode": 16, "scene": 44, "line": "Hello?", "order": 55546, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 16, "scene": 44, "line": "Hey! Back here.", "order": 55547, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 16, "scene": 44, "line": "Oh, wow. Seriously? Oh my gosh, is that champagne?", "order": 55548, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 16, "scene": 44, "line": "Si, senor.", "order": 55549, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 16, "scene": 44, "line": "Oh, Jim I should have told you I didn't get the job.", "order": 55550, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 16, "scene": 44, "line": "Oh man. I'm so sorry. Are you alright?", "order": 55551, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 16, "scene": 44, "line": "Oh, yeah. I'm more than alright. There's just nothing to celebrate.", "order": 55552, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 16, "scene": 44, "line": "Are you kidding? We're in Philly. We're having dinner together. And this is just consolation champagne. It's from the part of France that immediately gave up to the Nazis. Here.", "order": 55553, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 16, "scene": 44, "line": "[laughs] You're very quick on your feet. I remember you. Funny.", "order": 55554, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 16, "scene": 44, "line": "Alright. So, tell me all about it.", "order": 55555, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 16, "scene": 45, "line": "Ok. Well, gosh. Thank you for your help today. Your perspective was very useful. Thank you.", "order": 55556, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 16, "scene": 45, "line": "It was not an unpleasant way to spend an afternoon. [They shake hands. Then kiss] Dwight, Dwight.", "order": 55557, "speaker": "Angela Martin"},
  {"season": 9, "episode": 16, "scene": 45, "line": "Right. Not outside. The horseflies. You know what? My farm is only a few acres East of here. Or, we could use the slaughterhouse.", "order": 55558, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 16, "scene": 45, "line": "No, Dwight. The Senator.", "order": 55559, "speaker": "Angela Martin"},
  {"season": 9, "episode": 16, "scene": 45, "line": "Leave him. He probably won't even notice that you're gone. Be with me, Monkey.", "order": 55560, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 16, "scene": 45, "line": "I can't be your monkey, Dwight.", "order": 55561, "speaker": "Angela Martin"},
  {"season": 9, "episode": 16, "scene": 45, "line": "I'm not talking about some frisky romp in the warehouse. We have wasted too much of our lives ignoring the fact that we belong together. The eighty or ninety years that I have left in this life...I want to spend with you.", "order": 55562, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 16, "scene": 45, "line": "I made a vow. I gave my word.", "order": 55563, "speaker": "Angela Martin"},
  {"season": 9, "episode": 16, "scene": 45, "line": "Stand by your man. It's what I would want if you were mine.", "order": 55564, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 16, "scene": 45, "line": "Good night, D.", "order": 55565, "speaker": "Angela Martin"},
  {"season": 9, "episode": 16, "scene": 46, "line": "How are you doing? Is it really rough?", "order": 55566, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 16, "scene": 46, "line": "It is so unpleasant. You?", "order": 55567, "speaker": "Pete Miller"},
  {"season": 9, "episode": 16, "scene": 46, "line": "Hey, love turds. Conference room, now.", "order": 55568, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 16, "scene": 47, "line": "Thank you all for coming in. Just wanted to check in. How is everyone's day?", "order": 55569, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 16, "scene": 47, "line": "Honestly, it was a little weird.", "order": 55570, "speaker": "Gabe Lewis"},
  {"season": 9, "episode": 16, "scene": 47, "line": "Really? Hmm. That's interesting. Because Erin and Pete thought it wouldn't be weird at all. Why do you think it was weird, Gabe? Maybe because you and Erin used to be an item?", "order": 55571, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 16, "scene": 47, "line": "I still wear Erin's button-downs around the condo sometimes. So it feels to me like we're still in a relationship-", "order": 55572, "speaker": "Gabe Lewis"},
  {"season": 9, "episode": 16, "scene": 47, "line": "Gabe!", "order": 55573, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 16, "scene": 47, "line": "...a lot of the time.", "order": 55574, "speaker": "Gabe Lewis"},
  {"season": 9, "episode": 16, "scene": 47, "line": "And Alice, uh, I understand you once dumped Pete, ouch.", "order": 55575, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 16, "scene": 47, "line": "Dude, it was an amicable break up Andy.", "order": 55576, "speaker": "Pete Miller"},
  {"season": 9, "episode": 16, "scene": 47, "line": "Ok, while we're rewriting history, you never had a drinking problem.", "order": 55577, "speaker": "Alice"},
  {"season": 9, "episode": 16, "scene": 47, "line": "It was college. That is what you do.", "order": 55578, "speaker": "Pete Miller"},
  {"season": 9, "episode": 16, "scene": 47, "line": "Yeah you're also supposed to go to classes, so there's that.", "order": 55579, "speaker": "Alice"},
  {"season": 9, "episode": 16, "scene": 47, "line": "Hey, Andy, is this at all work related?", "order": 55580, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 16, "scene": 47, "line": "We'll get to that. Gabe, did Erin ever tell you that she loves you?", "order": 55581, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 16, "scene": 47, "line": "[laughs] Oh no no no no no no. She wouldn't even let me say it. It was adorable. She would plug her ears and scream her heat out.", "order": 55582, "speaker": "Gabe Lewis"},
  {"season": 9, "episode": 16, "scene": 47, "line": "Gabe, can you stop talking? Cause every word out of your mouth is like the squawk of an ugly pelican.", "order": 55583, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 16, "scene": 47, "line": "I got a tattoo for you.", "order": 55584, "speaker": "Gabe Lewis"},
  {"season": 9, "episode": 16, "scene": 47, "line": "I didn't ask you to get that Nike Swoosh. Nobody did! You did that for you!", "order": 55585, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 16, "scene": 47, "line": "Just do it. You were the it that I was just doing.", "order": 55586, "speaker": "Gabe Lewis"},
  {"season": 9, "episode": 16, "scene": 47, "line": "So you're dating a secretary now? Moving up in the world, Pete.", "order": 55587, "speaker": "Alice"},
  {"season": 9, "episode": 16, "scene": 47, "line": "She's nice to me.", "order": 55588, "speaker": "Pete Miller"},
  {"season": 9, "episode": 16, "scene": 47, "line": "How's that P.E. degree coming? That's what he wanted to be. His dream in college was to be a gym teacher.", "order": 55589, "speaker": "Alice"},
  {"season": 9, "episode": 16, "scene": 47, "line": "Well, guess what? He could still be a gym teacher. In fact, we could all still be gym teachers, so, let's-", "order": 55590, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 16, "scene": 47, "line": "I technically cannot. I don't have the lung capacity to blow a whistle.", "order": 55591, "speaker": "Gabe Lewis"},
  {"season": 9, "episode": 16, "scene": 47, "line": "Oh my god.", "order": 55592, "speaker": "Pete Miller"},
  {"season": 9, "episode": 16, "scene": 47, "line": "What kind of music are you into, Peter?", "order": 55593, "speaker": "Gabe Lewis"},
  {"season": 9, "episode": 16, "scene": 47, "line": "Uh, I like all kinds of music, Gabe.", "order": 55594, "speaker": "Pete Miller"},
  {"season": 9, "episode": 16, "scene": 47, "line": "Really? All kinds? So you like songs of hate written by the white knights of the Ku Klux Klan?", "order": 55595, "speaker": "Gabe Lewis"},
  {"season": 9, "episode": 16, "scene": 47, "line": "No!", "order": 55596, "speaker": "Pete Miller"},
  {"season": 9, "episode": 16, "scene": 47, "line": "Erin, are you even hearing this?", "order": 55597, "speaker": "Gabe Lewis"},
  {"season": 9, "episode": 16, "scene": 47, "line": "He didn't even say that.", "order": 55598, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 16, "scene": 47, "line": "He is not a very sophisticated man, I mean he can't even use chopsticks, so. Do I need to say anything else?", "order": 55599, "speaker": "Alice"},
  {"season": 9, "episode": 16, "scene": 47, "line": "Erin, I've been to Japan. I know how to use chopsticks so well. Come back. One night.", "order": 55600, "speaker": "Gabe Lewis"},
  {"season": 9, "episode": 16, "scene": 47, "line": "Gabe, I don't-", "order": 55601, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 16, "scene": 47, "line": "Give me one night with you...", "order": 55602, "speaker": "Gabe Lewis"},
  {"season": 9, "episode": 16, "scene": 47, "line": "What is that supposed to mean?", "order": 55603, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 16, "scene": 47, "line": "I have shaved everything...", "order": 55604, "speaker": "Gabe Lewis"},
  {"season": 9, "episode": 16, "scene": 47, "line": "I don't want you to shave everything.", "order": 55605, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 16, "scene": 47, "line": "I wasted two years of my life on you, you realize that right?!", "order": 55606, "speaker": "Alice"},
  {"season": 9, "episode": 16, "scene": 47, "line": "I just want to be real clear that chopsticks is not the measure of a man. [Erin and Gabe argue in background]", "order": 55607, "speaker": "Pete Miller"},
  {"season": 9, "episode": 16, "scene": 47, "line": "I am as smooth as a porpoise. [Erin argues]", "order": 55608, "speaker": "Gabe Lewis"},
  {"season": 9, "episode": 16, "scene": 47, "line": "Why don't you say in the beginning: 'This isn't really going that well'", "order": 55609, "speaker": "Pete Miller"},
  {"season": 9, "episode": 16, "scene": 47, "line": "Shove his sashimi!", "order": 55610, "speaker": "Gabe Lewis"},
  {"season": 9, "episode": 16, "scene": 47, "line": "What do you-!", "order": 55611, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 16, "scene": 47, "line": "Because I had to wait- [all argue]", "order": 55612, "speaker": "Alice"},
  {"season": 9, "episode": 16, "scene": 48, "line": "Alright, yes. That is a legitimate question. Does making Erin and Pete feel bad make me feel better? [Erin & Gabe and Alice & Pete argue in background] Yeah. Yeah, it does.", "order": 55613, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 16, "scene": 49, "line": "So, imagine like the real estate version of Michael Scott and that was this guy. He did half the interview as Ace Ventura.", "order": 55614, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 16, "scene": 49, "line": "Tell me about the cologne. How much?", "order": 55615, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 16, "scene": 49, "line": "Oh, uh, entire bottle. At least.", "order": 55616, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 16, "scene": 49, "line": "You're definitely hoarding this by the way.", "order": 55617, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 16, "scene": 49, "line": "Guess what poster he had on his wall?", "order": 55618, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 16, "scene": 49, "line": "Austin Powers.", "order": 55619, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 16, "scene": 49, "line": "MmMm.", "order": 55620, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 16, "scene": 49, "line": "Ferris Bueller.", "order": 55621, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 16, "scene": 49, "line": "MmMm. You're getting colder.", "order": 55622, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 16, "scene": 49, "line": "Not Night at the Roxbury.", "order": 55623, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 16, "scene": 49, "line": "[laughs] No. The Odd Life of Timothy Green.", "order": 55624, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 16, "scene": 49, "line": "I'm sorry, how did you think I was expected to guess that?", "order": 55625, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 16, "scene": 49, "line": "I don't know, but it's interesting right?", "order": 55626, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 16, "scene": 49, "line": "It's fascinating.", "order": 55627, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 16, "scene": 49, "line": "He said he can't help but tear up when he looks at it. It's like right next to his desk. He must look at it twenty times a day.", "order": 55628, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 16, "scene": 49, "line": "That's amazing. Well, listen. You can't win 'em all, right?", "order": 55629, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 16, "scene": 49, "line": "Mmhm.", "order": 55630, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 16, "scene": 49, "line": "So, next interview has to be better.", "order": 55631, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 16, "scene": 49, "line": "I don't know.", "order": 55632, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 16, "scene": 49, "line": "What do you mean? Of course it will. You're amazing.", "order": 55633, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 16, "scene": 49, "line": "I know. It's just even if it was, a great boss and a great job, I just, I don't know, I don't know if I want, um, I don't know if I want this.", "order": 55634, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 16, "scene": 49, "line": "[long pause] Huh. This is a little out of left field.", "order": 55635, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 16, "scene": 49, "line": "Is it? I just, I liked our life in Scranton.", "order": 55636, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 16, "scene": 49, "line": "And I have started a business in Philadelphia. [Pam shrugs]", "order": 55637, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 16, "scene": 50, "line": "My grandfather know. Mah Jong will be here to stay. Hobbies of the East continues in a moment.", "order": 55638, "speaker": "Oscar Martinez's Computer"},
  {"season": 9, "episode": 16, "scene": 50, "line": "You could all be doing this, just saying.", "order": 55639, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 16, "scene": 51, "line": "I watch way too many ads online and I don't do enough situps. So I bought these. Now, every time an ad pops up online, I get 30 seconds of ab blasting. I call it Ads for Abs. Ironically, I learned about the boots from an ad online.", "order": 55640, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 16, "scene": 52, "line": "Why can't you just do regular sit-ups?", "order": 55641, "speaker": "Stanley Hudson"},
  {"season": 9, "episode": 16, "scene": 52, "line": "I'll tell you why. Because...the floor...is...disgusting. Yeah, my trainer said everybody fails working out, that's how you win. Alright [tries to pull himself up] Ok. Kevin? A little help buddy?", "order": 55642, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 16, "scene": 52, "line": "Oh, why don't your famous stomachs help you now?", "order": 55643, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 16, "scene": 52, "line": "Can someone please help me?", "order": 55644, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 16, "scene": 52, "line": "Ow, these teas are hot, can someone help me please? [Erin moves Oscar out of the way for Phyllis]", "order": 55645, "speaker": "Phyllis Vance"},
  {"season": 9, "episode": 16, "scene": 52, "line": "Just- People! I'm not going anywhere. Soon, my core will get strong again and when that happens I'll be able to- Head rush! Ah! Can someone please help me? [Kevin shuts door] I- Hey! I'm not going anywhere! I'll be right here! Oh that's not good. [Oscars computer reads: Coming this May: The Office: An American Workplace. Ten years in the making, a look at the lives and loves of an average American small business office.] Hey guys! I've got twenty bucks for anyone who will help me. Kevin, would you like a pizza?", "order": 55646, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 17, "scene": 1, "line": "[as elevator door closes] Hold it!", "order": 55647, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 17, "scene": 1, "line": "[notices something new about Dwight] New glasses.", "order": 55648, "speaker": "Creed Bratton"},
  {"season": 9, "episode": 17, "scene": 2, "line": "Dwight. What a ridiculous, fancy clown you are.", "order": 55649, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 17, "scene": 2, "line": "I am dressed according to the Schrute codes of mourning. My aunt Shirley has died.", "order": 55650, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 17, "scene": 2, "line": "Oh, Dwight. I'm so sorry. Were you guys close?", "order": 55651, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 17, "scene": 2, "line": "I would say that she raised me, but let's not kid each other. I raised myself. She was, however, the closest thing I had to a mother.", "order": 55652, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 17, "scene": 3, "line": "My actual mother was very cold and distant. I'd say she was the closest thing I had to an aunt.", "order": 55653, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 17, "scene": 4, "line": "My condolences.", "order": 55654, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 17, "scene": 4, "line": "Keep them.", "order": 55655, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 17, "scene": 4, "line": "OK. Now, what do we got in these two pails?", "order": 55656, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 17, "scene": 4, "line": "In keeping with Schrute custom, I will either invite you to Saturday's funeral by sprinkling red, fertile dirt in your face. Or, I will ask you to keep a respectful distance during my time of grief, with a dusting of black, slightly acidic soil.", "order": 55657, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 17, "scene": 4, "line": "[after Dwight tosses dirt on her face] What color is it?", "order": 55658, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 17, "scene": 4, "line": "It looks pretty black.", "order": 55659, "speaker": "Phyllis Vance"},
  {"season": 9, "episode": 17, "scene": 4, "line": "[after Dwight tosses dirt in his face] Yep. Acidic, all right.", "order": 55660, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 17, "scene": 4, "line": "[while Dwight prepares to toss dirt] Oh, thank god.", "order": 55661, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 17, "scene": 4, "line": "Excuse me?", "order": 55662, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 17, "scene": 4, "line": "I'm so sorry, Dwight. And if you want me to be there, of course I will go. I just... have a personal training session...", "order": 55663, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 17, "scene": 4, "line": "OK. That's not... [tosses red dirt in Oscar's face]", "order": 55664, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 17, "scene": 5, "line": "I get red dirt. Nobody is getting red dirt. I should've kept my mouth shut. We're not even that close. I've only known Dwight... 12 years. 12 years. Time is a son of a bitch.", "order": 55665, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 17, "scene": 6, "line": "[as Dwight prepares to throw dirt in his face] I'm sure... I'm sure she's in a better place.", "order": 55666, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 17, "scene": 6, "line": "I really hope so. [unexpectedly tosses dirt in Pam's face]", "order": 55667, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 17, "scene": 6, "line": "OK. [as Dwight mixes coffee with dirt in his hand] This is crazy. You can't make a dirt ball.", "order": 55668, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 17, "scene": 6, "line": "I miss her so much.", "order": 55669, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 17, "scene": 6, "line": "OK.", "order": 55670, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 17, "scene": 6, "line": "You know? [Dwight screams and throws dirt ball at Jim]", "order": 55671, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 17, "scene": 7, "line": "[playing guitar and singing 'Oh What a Beautiful Morning'] Zeke's here.", "order": 55672, "speaker": "Mose Schrute"},
  {"season": 9, "episode": 17, "scene": 7, "line": "Zeke.", "order": 55673, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 17, "scene": 7, "line": "Hey, Dwight. Mose, Mom says 'hi'.", "order": 55674, "speaker": "Zeke"},
  {"season": 9, "episode": 17, "scene": 7, "line": "Hi' to Mom.", "order": 55675, "speaker": "Mose Schrute"},
  {"season": 9, "episode": 17, "scene": 7, "line": "Are you going?", "order": 55676, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 17, "scene": 7, "line": "Will there be ghosts there?", "order": 55677, "speaker": "Mose Schrute"},
  {"season": 9, "episode": 17, "scene": 7, "line": "[simultaneously with Zeke] Get in the sidecar. Get in the sidecar.", "order": 55678, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 17, "scene": 7, "line": "You get in the sidecar.", "order": 55679, "speaker": "Zeke"},
  {"season": 9, "episode": 17, "scene": 7, "line": "Get...", "order": 55680, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 17, "scene": 8, "line": "[at the grave site, to Oscar] What are you doing here?", "order": 55681, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 17, "scene": 8, "line": "You invited me. You threw the red dirt in my face.", "order": 55682, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 17, "scene": 8, "line": "[smirking] Oh, yeah.", "order": 55683, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 17, "scene": 8, "line": "[as a car approaches the grave site] Who is that?", "order": 55684, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 17, "scene": 8, "line": "Jeb, my brother.", "order": 55685, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 17, "scene": 8, "line": "You have a brother?", "order": 55686, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 17, "scene": 8, "line": "Uh-huh.", "order": 55687, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 17, "scene": 8, "line": "I didn't really see a better parking spot. [drives into the dug grave] Whoa! Look what I did. It's a rental. [Dwight approaches and hugs him] You wanna go in? [both attempt to put the other in the dug grave]", "order": 55688, "speaker": "Jeb"},
  {"season": 9, "episode": 17, "scene": 9, "line": "[upon seeing Todd Packer enter the office] Oh, gosh.", "order": 55689, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 17, "scene": 9, "line": "Hey, Moonface. Nice to see those shiny, little Chinese eyes of yours.", "order": 55690, "speaker": "Todd Packer"},
  {"season": 9, "episode": 17, "scene": 9, "line": "[to Pam] Who's this guy?", "order": 55691, "speaker": "Clark Green"},
  {"season": 9, "episode": 17, "scene": 9, "line": " bad.", "order": 55692, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 17, "scene": 9, "line": "Hi, all.", "order": 55693, "speaker": "Todd Packer"},
  {"season": 9, "episode": 17, "scene": 9, "line": "Why are you here, Todd?", "order": 55694, "speaker": "Phyllis Vance"},
  {"season": 9, "episode": 17, "scene": 9, "line": "OK. Let's get right to it. I guess. My name is Todd Packer and I am in recovery. I'm working the steps. I'm on step eight of Alcoholics Anonymous and step nine of Narcotics Anonymous. I'm here to make amends. I've been hard to deal with over the past years. Kind of a jerk. I know it. I don't need you to accept my apology, but I'd love it if you did.", "order": 55695, "speaker": "Todd Packer"},
  {"season": 9, "episode": 17, "scene": 9, "line": "Packer, we accept. [others disagree]", "order": 55696, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 17, "scene": 9, "line": "Actually, they have a specific way I need to do this. And, I have to go through examples of stuff. OK. Uh, where to begin. [to Pam] Hey. Pam-pam and her pam-pams. Wow. I have said some crude things about those. But, they are beautiful. And, I guess that's why I acted out. Pam, I'm sorry I objectified you. And, personified your breasts. Sorry, guys. [to Phyllis] Oh boy. I have not been nice to you. Philly, I'm sorry for the things I said about your size. To your face, behind your back, and in the form of drawings. Actually, that goes to all you double XLs. Stanley, Kevin, [points to Clark] this kid in a few years.", "order": 55697, "speaker": "Todd Packer"},
  {"season": 9, "episode": 17, "scene": 9, "line": "Todd, you're just saying insults in the form of an apology.", "order": 55698, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 17, "scene": 9, "line": "Why can't I just be nice? Truth is, I really like you guys. I really do. OK. The apology's just half of it. The big thing is making amends. And, that's why I brought these. I went out to the Steamtown Mall and I got you all cupcakes. From that place 'Nipples'.", "order": 55699, "speaker": "Todd Packer"},
  {"season": 9, "episode": 17, "scene": 9, "line": "I think it's called 'Nibbles'.", "order": 55700, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 17, "scene": 9, "line": "Huh. The mind sees what it wants to, huh? [hands cupcake to Erin] There you go.", "order": 55701, "speaker": "Todd Packer"},
  {"season": 9, "episode": 17, "scene": 9, "line": "Hey, hey, guys. Wait. Before we accept these cupcakes, I think we need to have a conversation privately in the conference room. Don't eat the cupcake.", "order": 55702, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 17, "scene": 10, "line": "We are here today to join this woman and the ground. Man is born of woman and his life is full of turmoil.", "order": 55703, "speaker": "German Minister"},
  {"season": 9, "episode": 17, "scene": 10, "line": "[gathers and tastes soil] Huh. It's crap soil. Nothing's going to grow here.", "order": 55704, "speaker": "Jeb"},
  {"season": 9, "episode": 17, "scene": 10, "line": "Doesn't matter. It's a cemetery.", "order": 55705, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 17, "scene": 10, "line": "Yeah, well, I'm just saying it's garbage soil, that's all.", "order": 55706, "speaker": "Jeb"},
  {"season": 9, "episode": 17, "scene": 10, "line": "Well, the only thing we're planting here is dead bodies.", "order": 55707, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 17, "scene": 10, "line": "It's fine 'cause they're not going to grow.", "order": 55708, "speaker": "Jeb"},
  {"season": 9, "episode": 17, "scene": 10, "line": "Well, thank god they're not because we don't want to make zombies.", "order": 55709, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 17, "scene": 10, "line": "Good. I agree. Don't worry about it. You won't get any.", "order": 55710, "speaker": "Jeb"},
  {"season": 9, "episode": 17, "scene": 10, "line": "Would the family care to say something?", "order": 55711, "speaker": "German Minister"},
  {"season": 9, "episode": 17, "scene": 10, "line": "You had black hair and then gray hair.", "order": 55712, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 17, "scene": 10, "line": "You were the aunt to my cousins. Most of your life you were 5'4', at the end you were 5'1'.", "order": 55713, "speaker": "Zeke"},
  {"season": 9, "episode": 17, "scene": 10, "line": "They're a descriptive people.", "order": 55714, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 17, "scene": 10, "line": "[approaches and hugs Dwight] I see you started without me.", "order": 55715, "speaker": "Fannie"},
  {"season": 9, "episode": 17, "scene": 10, "line": "You were late. So, what am I supposed to do?", "order": 55716, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 17, "scene": 10, "line": "Well, I told you...", "order": 55717, "speaker": "Fannie"},
  {"season": 9, "episode": 17, "scene": 10, "line": "Hi, Fannie.", "order": 55718, "speaker": "Jeb"},
  {"season": 9, "episode": 17, "scene": 10, "line": "Hey.", "order": 55719, "speaker": "Fannie"},
  {"season": 9, "episode": 17, "scene": 10, "line": "Who is that?", "order": 55720, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 17, "scene": 10, "line": "Dwight's sister.", "order": 55721, "speaker": "Zeke"},
  {"season": 9, "episode": 17, "scene": 10, "line": "Dwight has a sister?", "order": 55722, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 17, "scene": 10, "line": "Yeah.", "order": 55723, "speaker": "Zeke"},
  {"season": 9, "episode": 17, "scene": 10, "line": "She's beautiful.", "order": 55724, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 17, "scene": 10, "line": "Blugh.", "order": 55725, "speaker": "Zeke"},
  {"season": 9, "episode": 17, "scene": 10, "line": "[to his nephew Cameron] Hello, little man. Haven't seen you in a few years. [after a weak handshake] What is this? Oh, god. [after a truck with many girls in the bed pulls up] Henry.", "order": 55726, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 17, "scene": 10, "line": "Dwight.", "order": 55727, "speaker": "Henry"},
  {"season": 9, "episode": 17, "scene": 10, "line": "I see Esther's back in town.", "order": 55728, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 17, "scene": 10, "line": "Hi Dwight.", "order": 55729, "speaker": "Esther"},
  {"season": 9, "episode": 17, "scene": 10, "line": "Hi Esther. Nice of you to come today.", "order": 55730, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 17, "scene": 10, "line": "This was on the way. We're going into town after. I need yarn.", "order": 55731, "speaker": "Esther"},
  {"season": 9, "episode": 17, "scene": 10, "line": "Well, if you can snap two chicken necks with a single motion, why use two motions to slaughter those chickens.", "order": 55732, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 17, "scene": 10, "line": "We're at a funeral. There's a funeral going on here.", "order": 55733, "speaker": "Fannie"},
  {"season": 9, "episode": 17, "scene": 10, "line": "OK.", "order": 55734, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 17, "scene": 10, "line": "Anyone mention her height?", "order": 55735, "speaker": "Henry"},
  {"season": 9, "episode": 17, "scene": 10, "line": "Yep.", "order": 55736, "speaker": "Zeke"},
  {"season": 9, "episode": 17, "scene": 10, "line": "Land size? [all shake heads] [removes hat] Shirley, at 1600 acres, you have the largest farm in the area. Sharing borders with six other farms. Including my own farm. And your nephew Dwight's. [replaces hat] OK. [drives away]", "order": 55737, "speaker": "Henry"},
  {"season": 9, "episode": 17, "scene": 10, "line": "So, let's get it going.", "order": 55738, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 17, "scene": 10, "line": "[approaches Fannie, offering a shotgun] Would you want to do the honors?", "order": 55739, "speaker": "German Minister"},
  {"season": 9, "episode": 17, "scene": 10, "line": "Oh, right. Uh, you know, I don't think we have to do this.", "order": 55740, "speaker": "Fannie"},
  {"season": 9, "episode": 17, "scene": 11, "line": "We Schrutes don't need some Harvard doctor to tell us who's alive and who's dead. But, there was an unlucky streak of burying some heavy sleepers. And, when grave robbers discovered some scratch marks on the inside of some of the coffins, we decided to make sure that our dead were completely dead. Out of kindness.", "order": 55741, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 17, "scene": 12, "line": "[after Dwight opens coffin and begins firing into it] That's it for me. [exits]", "order": 55742, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 17, "scene": 13, "line": "I don't think we should eat Packers' cupcakes. We can't give him the satisfaction.", "order": 55743, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 17, "scene": 13, "line": "I agree. Even though that place has a way of making those cupcakes so they're dense. But, they're also really fluffy.", "order": 55744, "speaker": "Phyllis Vance"},
  {"season": 9, "episode": 17, "scene": 13, "line": "We can't let him buy our forgiveness with cupcakes. He was awful to us. And, he still is. How much is a cupcake? $2.50? Is that the price of our dignity?", "order": 55745, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 17, "scene": 13, "line": "$3.75 a cupcake, actually. $3.67 if you buy a dozen.", "order": 55746, "speaker": "Creed Bratton"},
  {"season": 9, "episode": 17, "scene": 14, "line": "I never forget a number. Names? In one ear and out the other. Places? Nope. Faces? That's rich. But, numbers? I have a gift. I guess that's why I'm an accountant.", "order": 55747, "speaker": "Creed Bratton"},
  {"season": 9, "episode": 17, "scene": 15, "line": "Hey, man. I don't think we've met. I'm Clark.", "order": 55748, "speaker": "Clark Green"},
  {"season": 9, "episode": 17, "scene": 15, "line": "Oh. I'm Todd. Oh, sorry for calling you a fat, little runt earlier.", "order": 55749, "speaker": "Todd Packer"},
  {"season": 9, "episode": 17, "scene": 15, "line": "You didn't actually say that.", "order": 55750, "speaker": "Clark Green"},
  {"season": 9, "episode": 17, "scene": 15, "line": "No? Wow. I'm in this mode now where I'm apologizing for thoughts that are in my head. [Clark fake laughs] Hey. I have a crazy feeling [hands Clark a cupcake] that you are really gonna like this.", "order": 55751, "speaker": "Todd Packer"},
  {"season": 9, "episode": 17, "scene": 16, "line": "Maybe we should eat the cupcakes. Haven't we done enough to Packer? I mean, we sent him down to Florida on a prank. [to Nellie] And you did fire him.", "order": 55752, "speaker": "Stanley Hudson"},
  {"season": 9, "episode": 17, "scene": 16, "line": "I did. I did, yes. And it was purely political. He did nothing wrong.", "order": 55753, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 17, "scene": 16, "line": "Would any of you be saying any of this if the cupcakes were from Supermart?", "order": 55754, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 17, "scene": 16, "line": "Do they even have a bakery anymore?", "order": 55755, "speaker": "Angela Martin"},
  {"season": 9, "episode": 17, "scene": 16, "line": "They do. It's awful. And, it's getting worse every day.", "order": 55756, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 17, "scene": 16, "line": "So, it really is just about the cupcakes.", "order": 55757, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 17, "scene": 16, "line": "Wow, you're right. It is just about the cupcakes.", "order": 55758, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 17, "scene": 16, "line": "So, we're all agreed? No one touches those cupcakes?", "order": 55759, "speaker": "Phyllis Vance"},
  {"season": 9, "episode": 17, "scene": 16, "line": "[as all agree] OK.", "order": 55760, "speaker": "Meredith Palmer"},
  {"season": 9, "episode": 17, "scene": 16, "line": "OK. Packer can go to hell.", "order": 55761, "speaker": "Phyllis Vance"},
  {"season": 9, "episode": 17, "scene": 17, "line": "[on a recorded video] Thank you for coming to my funeral. As I gaze at life's big sunset, I can't help but wonder where it all went wrong. You've all disappointed me greatly. Fannie. A single mamma in the city.", "order": 55762, "speaker": "Aunt Shirley"},
  {"season": 9, "episode": 17, "scene": 17, "line": "[agreeing with Shirley] Thank you.", "order": 55763, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 17, "scene": 17, "line": "Jeb. A street pusher.", "order": 55764, "speaker": "Aunt Shirley"},
  {"season": 9, "episode": 17, "scene": 18, "line": "After I left the army, I bought a 9-acre worm farm from a Californian. Turns out 'worm' means something else out there. And, I am now in the business of... pain management. Or, the smoking of pain management.", "order": 55765, "speaker": "Jeb"},
  {"season": 9, "episode": 17, "scene": 19, "line": "I can't stand the fact that Jeb is a pot farmer. He could've grown anything. Anything in the world. He used to talk about growing a peanut-grape hybrid. One plant, one sandwhich.", "order": 55766, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 17, "scene": 20, "line": "We can't just sit by and watch our family farm disappear. So, here are my terms. Dwight, Fannie, Jeb. If you come back home, I will leave you my farm. So, there, you have it.", "order": 55767, "speaker": "Aunt Shirley"},
  {"season": 9, "episode": 17, "scene": 20, "line": "Is she crazy? [as Dwight considers] Dwight?", "order": 55768, "speaker": "Fannie"},
  {"season": 9, "episode": 17, "scene": 20, "line": "Buddy? Buddy?", "order": 55769, "speaker": "Jeb"},
  {"season": 9, "episode": 17, "scene": 20, "line": "No, no, no.", "order": 55770, "speaker": "Fannie"},
  {"season": 9, "episode": 17, "scene": 20, "line": "Snap out of it. Dwight?", "order": 55771, "speaker": "Jeb"},
  {"season": 9, "episode": 17, "scene": 21, "line": "Let's do this. Let's run Aunt Shirley's farm. I'm in. Boom.", "order": 55772, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 17, "scene": 21, "line": "No, no, no. I'm not moving back here. Are you crazy?", "order": 55773, "speaker": "Fannie"},
  {"season": 9, "episode": 17, "scene": 21, "line": "Of course you are.", "order": 55774, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 17, "scene": 21, "line": "Look. I, I don't want to be mean or like insulting. I know that you like it here. But, Dwight, it's just that farm life lacks a certain... sort of sophistication.", "order": 55775, "speaker": "Fannie"},
  {"season": 9, "episode": 17, "scene": 21, "line": "Oh my god.", "order": 55776, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 17, "scene": 21, "line": "That Cammy and I are drawn to. And, I don't know. The men are just... it's almost like there's a... a willing ignorance.", "order": 55777, "speaker": "Fannie"},
  {"season": 9, "episode": 17, "scene": 22, "line": "Yes. I, thank you for asking me. I actually have written a little bit of poetry. That's crazy. And, I was recently published. Which is just... [reaches in to bag, removes folded paper] maybe I have. Yep. I do. Here it is from the um, Hartford Women's Lit Quarterly.com. A Willing Ignorance by Fannie Schrute.", "order": 55778, "speaker": "Fannie"},
  {"season": 9, "episode": 17, "scene": 23, "line": "Totally. Yep. The people here are like [makes farting sound, laughs] Like a fart. You know what I'm saying? Like a fart?", "order": 55779, "speaker": "Jeb"},
  {"season": 9, "episode": 17, "scene": 23, "line": "You know what? Let's take a couple of nights and stay here and think about Aunt Shirley's offer.", "order": 55780, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 17, "scene": 23, "line": "Couple of nights couldn't hurt.", "order": 55781, "speaker": "Jeb"},
  {"season": 9, "episode": 17, "scene": 23, "line": "Uh, somehow how I think a couple of nights could hurt.", "order": 55782, "speaker": "Fannie"},
  {"season": 9, "episode": 17, "scene": 23, "line": "You will say 'yes' on one. Five, four, three, two, get ready to say 'yes', one. Yes.", "order": 55783, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 17, "scene": 23, "line": "[as Dwight says 'yes'] Absolutely.", "order": 55784, "speaker": "Zeke"},
  {"season": 9, "episode": 17, "scene": 24, "line": "Growing up with Dwight and Mose was not easy. Uh, Dwight was obviously the cool one. And, Mose was the visionary. Which left me to be the comedian.", "order": 55785, "speaker": "Zeke"},
  {"season": 9, "episode": 17, "scene": 25, "line": "That doesn't work on me.", "order": 55786, "speaker": "Fannie"},
  {"season": 9, "episode": 17, "scene": 25, "line": "OK.", "order": 55787, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 17, "scene": 25, "line": "By the way, that's not...", "order": 55788, "speaker": "Fannie"},
  {"season": 9, "episode": 17, "scene": 25, "line": "[counts down in French]", "order": 55789, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 17, "scene": 25, "line": "Very funny. OK. Oui, oui, oui.", "order": 55790, "speaker": "Fannie"},
  {"season": 9, "episode": 17, "scene": 25, "line": "Oui.", "order": 55791, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 17, "scene": 25, "line": "Oh my goodness.", "order": 55792, "speaker": "Fannie"},
  {"season": 9, "episode": 17, "scene": 26, "line": "[while everyone is gathered playing and singing 'Sons & Daughters'] People underestimate the power of nostalgia. If baseball can use it to get people to care about that worthless sport, then I can use it to get my siblings to care about the farm. Nostalgia is truly one of the great human weaknesses. Second only to the neck.", "order": 55793, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 17, "scene": 27, "line": "[after seeing Dwight lay something down in front of Esther] So, I forgot about this old custom. If a man is interested in courting a woman, he may throw the beaks of a crow at her. And then, if she's interested in accepting the courtship, she has to destroy the beaks. [Esther crushes the beaks leaving Dwight satisfied]", "order": 55794, "speaker": "Fannie"},
  {"season": 9, "episode": 17, "scene": 28, "line": "Hey, uh, I just wanted to say I'm sorry for screwing you.", "order": 55795, "speaker": "Todd Packer"},
  {"season": 9, "episode": 17, "scene": 28, "line": "I'm not sorry about it.", "order": 55796, "speaker": "Meredith Palmer"},
  {"season": 9, "episode": 17, "scene": 28, "line": "I am. It was my rock bottom.", "order": 55797, "speaker": "Todd Packer"},
  {"season": 9, "episode": 17, "scene": 29, "line": "[while Kevin stares at his cupcake] Kevin, you can do this.", "order": 55798, "speaker": "Angela Martin"},
  {"season": 9, "episode": 17, "scene": 29, "line": "You don't know that.", "order": 55799, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 17, "scene": 29, "line": "Kevin, um, let's think of something to distract us. Uh, like the movie Skyfall. You loved the movie Skyfall, right?", "order": 55800, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 17, "scene": 29, "line": "Uh-huh.", "order": 55801, "speaker": "Angela Martin"},
  {"season": 9, "episode": 17, "scene": 29, "line": "James Bond was a spy.", "order": 55802, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 17, "scene": 29, "line": "Yes. He was a good spy.", "order": 55803, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 17, "scene": 29, "line": "Yeah. He was the best. James Bond would love this cupcake.", "order": 55804, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 17, "scene": 29, "line": "Hey, you know what? It was, uh, great to see you all again. Take care. [exits]", "order": 55805, "speaker": "Todd Packer"},
  {"season": 9, "episode": 17, "scene": 30, "line": "I am going through a twelve-step program. I'm currently on step zero. Which is have a [expletive] of fun. I spent six hours carefully removing the frosting and then layering in a variety of drugs, some legal, some not. Some laxative, some constipating. You don't fire the Pac-Man and expect to get away with it.", "order": 55806, "speaker": "Todd Packer"},
  {"season": 9, "episode": 17, "scene": 31, "line": "Guys, I'm proud of us. I think we did the right thing. [after noticing Angela easting cupcake] Hey!", "order": 55807, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 17, "scene": 31, "line": "What, Pam?", "order": 55808, "speaker": "Angela Martin"},
  {"season": 9, "episode": 17, "scene": 31, "line": "[as others eat] Wait!", "order": 55809, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 17, "scene": 31, "line": "He's gone. It's just a cupcake now.", "order": 55810, "speaker": "Angela Martin"},
  {"season": 9, "episode": 17, "scene": 31, "line": "No, no. It's the principle of the thing.", "order": 55811, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 17, "scene": 31, "line": "[exits office, chewing] Oh my god. I forgive him so much. [Kevin begins making choking sounds] Whoa, is he choking?", "order": 55812, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 17, "scene": 31, "line": "Kevin?", "order": 55813, "speaker": "Angela Martin"},
  {"season": 9, "episode": 17, "scene": 31, "line": "[after long choking and refusing help from Meredith] Oh, that was fantastic.", "order": 55814, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 17, "scene": 32, "line": "[after following Dwight to the chicken coop, before sunrise] Is it dangerous to take the eggs in front of them?", "order": 55815, "speaker": "Cameron"},
  {"season": 9, "episode": 17, "scene": 32, "line": "Yes, very. You really need to stand back because these are killer chickens.", "order": 55816, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 17, "scene": 32, "line": "I was just asking you something I didn't know.", "order": 55817, "speaker": "Cameron"},
  {"season": 9, "episode": 17, "scene": 32, "line": "Which is fine. And, you learned something. But, it was kind of a stupid question so you're gonna get made fun of a little bit.", "order": 55818, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 17, "scene": 32, "line": "[after grabbing an egg] Are we gonna eat these today?", "order": 55819, "speaker": "Cameron"},
  {"season": 9, "episode": 17, "scene": 32, "line": "If you want.", "order": 55820, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 17, "scene": 33, "line": "Is that a guillotine?", "order": 55821, "speaker": "Cameron"},
  {"season": 9, "episode": 17, "scene": 33, "line": "This? No. That'd be cool, though. It's for milking.", "order": 55822, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 17, "scene": 33, "line": "[referencing goat] That's a cow?", "order": 55823, "speaker": "Cameron"},
  {"season": 9, "episode": 17, "scene": 33, "line": "Did you just ask me if this was a cow?", "order": 55824, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 17, "scene": 33, "line": "[after seeing Dwight smelling milk] What are you doing now?", "order": 55825, "speaker": "Cameron"},
  {"season": 9, "episode": 17, "scene": 33, "line": "Come here. Come on. Grab a teat with each hand. Sit down. There you go. Squeeze from top to bottom. Really pull. Use some muscle. Draw the milk out. All right. Going all right?", "order": 55826, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 17, "scene": 33, "line": "I don't know.", "order": 55827, "speaker": "Cameron"},
  {"season": 9, "episode": 17, "scene": 33, "line": "Well, is the udder hot? Is the milk clumpy?", "order": 55828, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 17, "scene": 33, "line": "I don't know any of that.", "order": 55829, "speaker": "Cameron"},
  {"season": 9, "episode": 17, "scene": 33, "line": "OK, you suck at this. Give me those teats. Didn't your father teach you anything?", "order": 55830, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 17, "scene": 33, "line": "I never met him.", "order": 55831, "speaker": "Cameron"},
  {"season": 9, "episode": 17, "scene": 34, "line": "Good morning, Erin.", "order": 55832, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 17, "scene": 34, "line": "[looking disheveled] Hey.", "order": 55833, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 17, "scene": 34, "line": "Hey, everybody. Um, I decided to eat my cupcake. I thought about it last night. I talked to Jim. I talked to my sister. And, I think that, as long as Todd Packer doesn't know, it's OK.", "order": 55834, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 17, "scene": 34, "line": "I don't care what you do, Pam. Just please stop making noise.", "order": 55835, "speaker": "Phyllis Vance"},
  {"season": 9, "episode": 17, "scene": 34, "line": "Phyllis!", "order": 55836, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 17, "scene": 34, "line": "Packer laced the cupcakes.", "order": 55837, "speaker": "Clark Green"},
  {"season": 9, "episode": 17, "scene": 34, "line": "No!", "order": 55838, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 17, "scene": 34, "line": "Did you get diarrhea or were you stoned?", "order": 55839, "speaker": "Meredith Palmer"},
  {"season": 9, "episode": 17, "scene": 34, "line": "Some of us got both. [raises hand]", "order": 55840, "speaker": "Angela Martin"},
  {"season": 9, "episode": 17, "scene": 34, "line": "Oh my god! Is everyone OK?", "order": 55841, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 17, "scene": 34, "line": "Last night, I got out all of my old dolls and played with them on the living room floor. Then, I ordered ten American Girl outfits online. It was thousands of dollars.", "order": 55842, "speaker": "Phyllis Vance"},
  {"season": 9, "episode": 17, "scene": 34, "line": "Yeah? Well, count yourself lucky, Phyllis. I got the toilet.", "order": 55843, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 17, "scene": 34, "line": "Me too. [holds up four fingers]", "order": 55844, "speaker": "Stanley Hudson"},
  {"season": 9, "episode": 17, "scene": 34, "line": "I went Christmas caroling in March and I fertilized some bushes along the way. So, not my best night. But, not my worst night.", "order": 55845, "speaker": "Clark Green"},
  {"season": 9, "episode": 17, "scene": 34, "line": "What did you do, Andy?", "order": 55846, "speaker": "Phyllis Vance"},
  {"season": 9, "episode": 17, "scene": 34, "line": "Last night? Oh, god, um, I don't know. [cuts to him arm wrestling Kevin] Na, nothing really. Kevin?", "order": 55847, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 17, "scene": 34, "line": "Why would you ask me?", "order": 55848, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 17, "scene": 34, "line": "I'm not, a... I'm just. I don't know. [cuts to him spinning a plate like a record and Kevin dancing] Oh. Gosh. I actually. It is hard to recall. Um. [cuts to he and Kevin sitting on a table touching each others face] Pretty normal night.", "order": 55849, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 17, "scene": 34, "line": "[he and Andy embrace each other in tears] My night was just like that. [cuts to him and Andy trading clothes] Meaning normal.", "order": 55850, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 17, "scene": 34, "line": "Yeah. I didn't see you last night at all. [cuts to him dancing by prostrate Kevin]", "order": 55851, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 17, "scene": 34, "line": "Nope. I did not see you either.", "order": 55852, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 17, "scene": 34, "line": "Wow. He is officially the worst human being.", "order": 55853, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 17, "scene": 34, "line": "[as Pam attempts to throw cupcake away] Ah. [stops Pam and takes cupcake]", "order": 55854, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 17, "scene": 34, "line": "But, Kevin that's...", "order": 55855, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 17, "scene": 34, "line": "Yeah. No, I understand, Pam. I understand.", "order": 55856, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 17, "scene": 34, "line": "[shaking head in disbelief] No.", "order": 55857, "speaker": "Angela Martin"},
  {"season": 9, "episode": 17, "scene": 35, "line": "So, that's it. I just wanted to make amends. [slides cupcakes across table to Darryl and Jim]", "order": 55858, "speaker": "Todd Packer"},
  {"season": 9, "episode": 17, "scene": 35, "line": "That's big of you, man. Takes a lot of courage.", "order": 55859, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 17, "scene": 35, "line": "Ooh, red velvet. I should apologize too.", "order": 55860, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 17, "scene": 35, "line": "It's OK. I'm over it.", "order": 55861, "speaker": "Todd Packer"},
  {"season": 9, "episode": 17, "scene": 36, "line": "Oof. This is no nine-acre worm farm. This is a beast. Whoever's managing this thing is gonna have a hell of a job. Not it.", "order": 55862, "speaker": "Jeb"},
  {"season": 9, "episode": 17, "scene": 36, "line": "Not it.", "order": 55863, "speaker": "Fannie"},
  {"season": 9, "episode": 17, "scene": 36, "line": "It. [to Fannie] That kid doesn't put in some farm time, he's gonna stay like that.", "order": 55864, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 17, "scene": 36, "line": "All right.", "order": 55865, "speaker": "Fannie"},
  {"season": 9, "episode": 18, "scene": 1, "line": "Uh oh. She's doing it again.", "order": 55866, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 18, "scene": 2, "line": "Phyllis has gotten into audio books and lately she's been listening to 50 Shades of Grey. Which, if you don't know what it is, it's a book about um...", "order": 55867, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 18, "scene": 2, "line": "It's porn.", "order": 55868, "speaker": "Clark Green"},
  {"season": 9, "episode": 18, "scene": 2, "line": "Yeah.", "order": 55869, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 18, "scene": 3, "line": "[Phyllis rocks her chair into his provocatively] Seriously. Ugh. Ok, this is unacceptable. It's officially a hostile work environment.", "order": 55870, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 18, "scene": 3, "line": "Why?", "order": 55871, "speaker": "Phyllis Vance"},
  {"season": 9, "episode": 18, "scene": 3, "line": "Somebody just needs to get her attention, tell her it's not OK to do this in public.", "order": 55872, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 18, "scene": 3, "line": "Be careful. I pulled my mom's dog off a pillow once, and I got bit.", "order": 55873, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 18, "scene": 3, "line": "Just ignore her. Sooner or later she'll finish.", "order": 55874, "speaker": "Meredith Palmer"},
  {"season": 9, "episode": 18, "scene": 3, "line": "Ugh. Ew.", "order": 55875, "speaker": "Group"},
  {"season": 9, "episode": 18, "scene": 4, "line": "What?", "order": 55876, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 18, "scene": 4, "line": "Phyllis is masturbating. In the office right now as we speak.", "order": 55877, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 18, "scene": 4, "line": "Can we skip the color commentary and just have Andy go out there and fire her?", "order": 55878, "speaker": "Angela Martin"},
  {"season": 9, "episode": 18, "scene": 4, "line": "He- he can't do that. Turns out she's allowed to have feelings of sexual arousal. It doesn't become a violation until she physically acts on it.", "order": 55879, "speaker": "Toby Flenderson"},
  {"season": 9, "episode": 18, "scene": 4, "line": "No!", "order": 55880, "speaker": "Group"},
  {"season": 9, "episode": 18, "scene": 4, "line": "Toby, how do you propose that we-", "order": 55881, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 18, "scene": 4, "line": "Whoa, whoa, whoa, wait a minute. Why is Phyllis so aroused?", "order": 55882, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 18, "scene": 4, "line": "She's listening to 50 Shades of Grey.", "order": 55883, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 18, "scene": 4, "line": "Well there you go. That's muy caliente.", "order": 55884, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 18, "scene": 4, "line": "OK, you are useless. I'll take care of this. [walks out of Andy's office and dumps water on Phyllis]", "order": 55885, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 18, "scene": 4, "line": "What the hell?!", "order": 55886, "speaker": "Phyllis Vance"},
  {"season": 9, "episode": 18, "scene": 4, "line": "It's OK guys, she's no longer horny.", "order": 55887, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 18, "scene": 4, "line": "Excuse me, dirty birdie [takes Phyllis' iPod]", "order": 55888, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 18, "scene": 4, "line": "Wait, what?", "order": 55889, "speaker": "Phyllis Vance"},
  {"season": 9, "episode": 18, "scene": 4, "line": "You can have this back at the end of the day. [cut to Andy listening to the iPod and looking aroused, water is dumped on him] Oh!!", "order": 55890, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 18, "scene": 5, "line": "Clark, I need your advice. I'm having some lady troubles.", "order": 55891, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 18, "scene": 5, "line": "What's her name?", "order": 55892, "speaker": "Clark Green"},
  {"season": 9, "episode": 18, "scene": 5, "line": "Esther Ruger. [Angela makes a face in the background]", "order": 55893, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 18, "scene": 5, "line": "Sweet.", "order": 55894, "speaker": "Clark Green"},
  {"season": 9, "episode": 18, "scene": 5, "line": "Lives on the neighboring farm. 85 acres.", "order": 55895, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 18, "scene": 5, "line": "Oh yeah. Keep talkin'.", "order": 55896, "speaker": "Clark Green"},
  {"season": 9, "episode": 18, "scene": 5, "line": "Well, we've been out three times, there has been physicality but the thing is we were hanging out with her father the other night looking at a farm catalog, next thing I know he wants to lease a tractor with me.", "order": 55897, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 18, "scene": 5, "line": "Oh.", "order": 55898, "speaker": "Clark Green"},
  {"season": 9, "episode": 18, "scene": 5, "line": "[laughs] What do you think?", "order": 55899, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 18, "scene": 5, "line": "The same thing that you think.", "order": 55900, "speaker": "Clark Green"},
  {"season": 9, "episode": 18, "scene": 5, "line": "A long term tractor lease is going to put pressure on a relationship.", "order": 55901, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 18, "scene": 5, "line": "God, one of my buddies is going through the same thing right now. Something in the air.", "order": 55902, "speaker": "Clark Green"},
  {"season": 9, "episode": 18, "scene": 5, "line": "Here you go. And good for you Dwight, I'm so glad you found someone. I bet she's got kind eyes.", "order": 55903, "speaker": "Angela Martin"},
  {"season": 9, "episode": 18, "scene": 6, "line": "Dwight is dating a brussel sprout farmer named Esther. She's coming here this afternoon with her father. Who knows? Maybe she'll be pulling the horse cart! [laughs]", "order": 55904, "speaker": "Angela Martin"},
  {"season": 9, "episode": 18, "scene": 7, "line": "[on phone] Cici has been calling me 'Pamela'. Like four times this week.", "order": 55905, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 18, "scene": 7, "line": "Oh man. [laughs]", "order": 55906, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 18, "scene": 7, "line": "I wonder if she'll start calling you 'Jim'.", "order": 55907, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 18, "scene": 7, "line": "Oh boy, please don't. Let's not let that happen.", "order": 55908, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 18, "scene": 7, "line": "[laughs] Um.", "order": 55909, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 18, "scene": 7, "line": "What was I gonna- What was I gonna say?", "order": 55910, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 18, "scene": 7, "line": "You're- You know your assistant told me that you have a big pitch meeting today. With Ryan Howard. How did that happen?", "order": 55911, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 18, "scene": 7, "line": "Not that Ryan Howard. Um, the Phillies' first baseman.", "order": 55912, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 18, "scene": 7, "line": "Oh, oh OK.", "order": 55913, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 18, "scene": 7, "line": "Yeah, yeah. Exactly.", "order": 55914, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 18, "scene": 7, "line": "[sighs] Um, Ok. Well?", "order": 55915, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 18, "scene": 7, "line": "Yeah. So, uh, I'll uh, talk to you later?", "order": 55916, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 18, "scene": 7, "line": "Yeah, sounds good. Ok.", "order": 55917, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 18, "scene": 7, "line": "Ok great.", "order": 55918, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 18, "scene": 7, "line": "Ok.", "order": 55919, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 18, "scene": 7, "line": "Bye.", "order": 55920, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 18, "scene": 7, "line": "Bye.", "order": 55921, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 18, "scene": 8, "line": "[Meredith laughs] What's going on? Oh, did Gangnam Style put out a new song?", "order": 55922, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 18, "scene": 8, "line": "There's a promo for the new documentary on the web.", "order": 55923, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 18, "scene": 8, "line": "Play it again.", "order": 55924, "speaker": "Phyllis Vance"},
  {"season": 9, "episode": 18, "scene": 8, "line": "[Music begins, Michael Scott is shown] The boss. [Pam and Dwight are shown in episodes past] The workers. [Ryan and Michael are shown] The lives. [Jim and Pam and Dwight and Angela are shown] The loves. [More flashback clips] The people. The paper. The Office: An American Workplace. Coming soon on WVIA.", "order": 55925, "speaker": "Promo Voice"},
  {"season": 9, "episode": 18, "scene": 8, "line": "Whoa. You go to the bathroom for 45 minutes and everything changes.", "order": 55926, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 18, "scene": 9, "line": "This is a documentary? Oh, I always thought we were like specimens in a human zoo.", "order": 55927, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 18, "scene": 10, "line": "[Angela is rewatching the promo and looks nervous about the part with her and Dwight] Did you see this? [lifts monitor in her direction]", "order": 55928, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 18, "scene": 10, "line": "Your screen is all black. You just unplugged your computer.", "order": 55929, "speaker": "Angela Martin"},
  {"season": 9, "episode": 18, "scene": 11, "line": "[Pam smiles as she watches the promo with her and Jim on the roof] Oh my god, is that you and Jim?", "order": 55930, "speaker": "Clark Green"},
  {"season": 9, "episode": 18, "scene": 11, "line": "Uh huh.", "order": 55931, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 18, "scene": 11, "line": "Jeez, you fell in love with that hair? Really? [laughs] Yikes. That is awful.", "order": 55932, "speaker": "Clark Green"},
  {"season": 9, "episode": 18, "scene": 11, "line": "It wasn't so bad.", "order": 55933, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 18, "scene": 11, "line": "Guys, are you reading the online comments? Somebody commented on my banjo playing. 'Banjo at 0:19 is aight' Internet, calm down! I must be really connected with this guy. I mean that's the guy's name, right? ChobbleGobbler?", "order": 55934, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 18, "scene": 12, "line": "Hey man, how you doin'? Jim Halpert.", "order": 55935, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 18, "scene": 12, "line": "Nice to meet you. Eat Fresh.", "order": 55936, "speaker": "Ryan Howard Howard"},
  {"season": 9, "episode": 18, "scene": 12, "line": "Hey, man. Darryl.", "order": 55937, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 18, "scene": 12, "line": "Hey there, Ryan. Nice to meet you. Eat Fresh. [to camera] Eat Fresh.", "order": 55938, "speaker": "Ryan Howard Howard"},
  {"season": 9, "episode": 18, "scene": 12, "line": "Let's go to the conference room.", "order": 55939, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 18, "scene": 13, "line": "Esther's on her way up. I wonder if she wants a snack. Let's see, I know she likes apples and carrots.", "order": 55940, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 18, "scene": 13, "line": "I bet she does. I bet she'll eat them right out of your hand with those big strong teeth.", "order": 55941, "speaker": "Angela Martin"},
  {"season": 9, "episode": 18, "scene": 13, "line": "Did I tell you about her teeth?", "order": 55942, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 18, "scene": 13, "line": "Hey Dwight, you have some guests. I think they're from the forest where we harvest our paper.", "order": 55943, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 18, "scene": 13, "line": "Yes.", "order": 55944, "speaker": "Angela Martin"},
  {"season": 9, "episode": 18, "scene": 14, "line": "Ah, the Ruger family. Welcome.", "order": 55945, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 18, "scene": 14, "line": "Fine office you have here. Sturdy walls.", "order": 55946, "speaker": "Mr. Ruger"},
  {"season": 9, "episode": 18, "scene": 14, "line": "Yes. Esther, you look radiant as always.", "order": 55947, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 18, "scene": 14, "line": "Thank you. [Dwight kisses her forehead]", "order": 55948, "speaker": "Esther"},
  {"season": 9, "episode": 18, "scene": 15, "line": "I guess men find Esther attractive. I mean if there are chubby chasers, then there are men that like that....thing.", "order": 55949, "speaker": "Angela Martin"},
  {"season": 9, "episode": 18, "scene": 16, "line": "Weird to see how we used to look in those promos. Some of us have changed so much.", "order": 55950, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 18, "scene": 16, "line": "[eating soft pretzel] We've all changed.", "order": 55951, "speaker": "Stanley Hudson"},
  {"season": 9, "episode": 18, "scene": 17, "line": "With our firm, you'll be building equity for long after they've retired your number.", "order": 55952, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 18, "scene": 17, "line": "And we all know, baseball does not last forever.", "order": 55953, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 18, "scene": 17, "line": "I look at these actors on TV and I think: 'C'mon, I can do that.'", "order": 55954, "speaker": "Ryan Howard Howard"},
  {"season": 9, "episode": 18, "scene": 17, "line": "Right? [laughs]", "order": 55955, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 18, "scene": 17, "line": "Watch this: Eat Fresh. Now what does that make you think of?", "order": 55956, "speaker": "Ryan Howard Howard"},
  {"season": 9, "episode": 18, "scene": 17, "line": "Subway sandwiches.", "order": 55957, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 18, "scene": 17, "line": "Yep.", "order": 55958, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 18, "scene": 17, "line": "How? I didn't say Subway sandwiches. It's called playing the subtext.", "order": 55959, "speaker": "Ryan Howard Howard"},
  {"season": 9, "episode": 18, "scene": 17, "line": "Wow.", "order": 55960, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 18, "scene": 17, "line": "I actually wrote a screenplay, it's called 'The Big Piece'", "order": 55961, "speaker": "Ryan Howard Howard"},
  {"season": 9, "episode": 18, "scene": 17, "line": "Based on his nickname. Like it already. Let me guess, it's autobiographical.", "order": 55962, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 18, "scene": 17, "line": "Half biopic and half superhero movie. A mild mannered professional baseball player, Ryan Howard-", "order": 55963, "speaker": "Ryan Howard Howard"},
  {"season": 9, "episode": 18, "scene": 17, "line": "OK", "order": 55964, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 18, "scene": 17, "line": "-hits a home run into outer space. Ball comes back with space dust on it, which transforms him into: The Big Piece.", "order": 55965, "speaker": "Ryan Howard Howard"},
  {"season": 9, "episode": 18, "scene": 17, "line": "The space dust does it.", "order": 55966, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 18, "scene": 17, "line": "Space dust.", "order": 55967, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 18, "scene": 17, "line": "Yeah.", "order": 55968, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 18, "scene": 17, "line": "I actually brought a- some copies of my script if you guys wanna read it together.", "order": 55969, "speaker": "Ryan Howard Howard"},
  {"season": 9, "episode": 18, "scene": 17, "line": "Sure, yeah. [laughs] Hollywood. [Ryan Howard pulls out 3 thick scripts] Alright.", "order": 55970, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 18, "scene": 17, "line": "Wow. Yeah, we gonna read it.", "order": 55971, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 18, "scene": 17, "line": "Ok, great.", "order": 55972, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 18, "scene": 18, "line": "Andy, are there documentary groupies?", "order": 55973, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 18, "scene": 18, "line": "Of course there are!", "order": 55974, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 18, "scene": 18, "line": "Of course.", "order": 55975, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 18, "scene": 18, "line": "A little ironic that I'm going to be kind of a TV star, because my last Chad Flendermen novel [groups groans] was based on a murdered TV star. The small screen-", "order": 55976, "speaker": "Toby Flenderson"},
  {"season": 9, "episode": 18, "scene": 18, "line": "Oh, I don't care.", "order": 55977, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 18, "scene": 18, "line": "Hey guys, I just found another promo. It's in Danish. I guess it's gonna start airing in Denmark.", "order": 55978, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 18, "scene": 18, "line": "Oh my god!", "order": 55979, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 18, "scene": 19, "line": "[Speaks Danish]", "order": 55980, "speaker": "Promo Announcer"},
  {"season": 9, "episode": 18, "scene": 19, "line": "What was that word they said when they showed me 'Skrald mand'? What's that mean in Danish? Cool guy? [Oscar looks it up]", "order": 55981, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 18, "scene": 19, "line": "Dumpster Man.", "order": 55982, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 18, "scene": 19, "line": "Cool. Superhero.", "order": 55983, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 18, "scene": 19, "line": "What about me? 'Klokken tre pige'", "order": 55984, "speaker": "Angela Martin"},
  {"season": 9, "episode": 18, "scene": 19, "line": "Three PM Girl'", "order": 55985, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 18, "scene": 19, "line": "What? Why would they...wait a second, wait a second! What was that? [pauses on her and Dwight leaving the warehouse area where they've just had sex] Oh! I didn't know they were filming then!", "order": 55986, "speaker": "Angela Martin"},
  {"season": 9, "episode": 18, "scene": 19, "line": "It looks like the camera man was hiding behind the shelves.", "order": 55987, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 18, "scene": 19, "line": "Wait. So they were filming all the time? Even when we didn't know it?", "order": 55988, "speaker": "Phyllis Vance"},
  {"season": 9, "episode": 18, "scene": 19, "line": "Oh my god. [All look at camera horrified]", "order": 55989, "speaker": "Angela Martin"},
  {"season": 9, "episode": 18, "scene": 20, "line": "There was much more secret filming than I expected. [laughs] But I am fine with it, I mean it. I am.", "order": 55990, "speaker": "Angela Martin"},
  {"season": 9, "episode": 18, "scene": 20, "line": "Are you kidding me? It's like half the show is secret footage.", "order": 55991, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 18, "scene": 20, "line": "I am a very private person. I show 'em when I wanna show 'em. Who wants a taste? [lifts shirt to flash camera] Boob sauce!", "order": 55992, "speaker": "Meredith Palmer"},
  {"season": 9, "episode": 18, "scene": 20, "line": "No!", "order": 55993, "speaker": "Group"},
  {"season": 9, "episode": 18, "scene": 20, "line": "Meredith!", "order": 55994, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 18, "scene": 20, "line": "Come on!", "order": 55995, "speaker": "Angela Martin"},
  {"season": 9, "episode": 18, "scene": 20, "line": "Oh my god. Do they film us at night when we're sleeping?", "order": 55996, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 18, "scene": 20, "line": "Yes, Erin. They film us at night when we're sleeping. Cause that makes great TV!", "order": 55997, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 18, "scene": 20, "line": "Hey.", "order": 55998, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 18, "scene": 20, "line": "Oscar.", "order": 55999, "speaker": "Angela Martin"},
  {"season": 9, "episode": 18, "scene": 20, "line": "I'm sorry sweetie. This whole thing is just freaking me out.", "order": 56000, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 18, "scene": 21, "line": "I have been very honest with you guys. In a way that could seriously impact the political career of a very good friend of mine. You're not going to use any of that, are you?", "order": 56001, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 18, "scene": 22, "line": "People, relax! We are killing it online. Have you guys checked the comments? SmokeThatSkinwagon says: 'You guys are killing it!' I mean, we're internet sensations guys!", "order": 56002, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 18, "scene": 22, "line": "I think we need to figure out what's going on. I might just take a little walk.", "order": 56003, "speaker": "Angela Martin"},
  {"season": 9, "episode": 18, "scene": 22, "line": "Yes, a little walk sounds like a good idea. [groups moves to warehouse and you can hear Erin whispering something]", "order": 56004, "speaker": "Stanley Hudson"},
  {"season": 9, "episode": 18, "scene": 22, "line": "Ok everyone, turn off your mikes.", "order": 56005, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 18, "scene": 22, "line": "We need to know more. Did their shots have sound? What exactly did they get on tape?", "order": 56006, "speaker": "Angela Martin"},
  {"season": 9, "episode": 18, "scene": 22, "line": "I sneezed into my hands without using Purell and then dipped into the candy jar. Did they film that?", "order": 56007, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 18, "scene": 22, "line": "My first week here I sneezed directly into the candy jar because I thought I'd get more [Angela and Oscar make disgusted faces] I thought I'd get more screen time than anyone.", "order": 56008, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 18, "scene": 22, "line": "Ok, Pam. Why don't you visit your buddy, the crew guy that got fired? Find out what they got.", "order": 56009, "speaker": "Pete Miller"},
  {"season": 9, "episode": 18, "scene": 22, "line": "Brian?", "order": 56010, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 18, "scene": 22, "line": "Yeah.", "order": 56011, "speaker": "Pete Miller"},
  {"season": 9, "episode": 18, "scene": 22, "line": "Yeah, I guess I could.", "order": 56012, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 18, "scene": 22, "line": "Hurry Pam, I need to know how much hellfire is going to rain down on me.", "order": 56013, "speaker": "Stanley Hudson"},
  {"season": 9, "episode": 18, "scene": 22, "line": "I thought Terry knew about Cynthia?", "order": 56014, "speaker": "Phyllis Vance"},
  {"season": 9, "episode": 18, "scene": 22, "line": "She does. But neither of them know about Lydia.", "order": 56015, "speaker": "Stanley Hudson"},
  {"season": 9, "episode": 18, "scene": 22, "line": "Oh!", "order": 56016, "speaker": "Group"},
  {"season": 9, "episode": 18, "scene": 22, "line": "Wow.", "order": 56017, "speaker": "Meredith Palmer"},
  {"season": 9, "episode": 18, "scene": 23, "line": "Whoa! Whoa! OK, so what's this lever do?", "order": 56018, "speaker": "Clark Green"},
  {"season": 9, "episode": 18, "scene": 23, "line": "That manipulates the secondary shaft.", "order": 56019, "speaker": "Ruger Sister 1"},
  {"season": 9, "episode": 18, "scene": 23, "line": "Oh, the secondary shaft. Eeeh, oooga! [laughs]", "order": 56020, "speaker": "Clark Green"},
  {"season": 9, "episode": 18, "scene": 23, "line": "The lift capacity's up at two thousand pounds. That's a lot of beets.", "order": 56021, "speaker": "Salesmen"},
  {"season": 9, "episode": 18, "scene": 23, "line": "Let's talk terms. If you agree to a forty sixty split on this tractor, I'll store it in one of my barns.", "order": 56022, "speaker": "Mr. Ruger"},
  {"season": 9, "episode": 18, "scene": 23, "line": "Mr. Ruger, are you trying to take advantage of me because I'm interested in your daughter? Fifty fifty split or no deal.", "order": 56023, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 18, "scene": 23, "line": "Esther, get in the truck.", "order": 56024, "speaker": "Mr. Ruger"},
  {"season": 9, "episode": 18, "scene": 23, "line": "Ok ok ok, wait! You win. [shakes hands]", "order": 56025, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 18, "scene": 23, "line": "Let's get the paperwork started.", "order": 56026, "speaker": "Salesman"},
  {"season": 9, "episode": 18, "scene": 24, "line": "We should buy an auger together.", "order": 56027, "speaker": "Ruger Sister 2"},
  {"season": 9, "episode": 18, "scene": 24, "line": "Oh, yeah. Yeah.", "order": 56028, "speaker": "Clark Green"},
  {"season": 9, "episode": 18, "scene": 24, "line": "You would be a great one to buy an auger with.", "order": 56029, "speaker": "Ruger Sister 1"},
  {"season": 9, "episode": 18, "scene": 25, "line": "[on phone] Hello, honey? I just spoke to the TV repair man, he says we need to keep our TVs turned off for a couple of months. Something about the wiring.", "order": 56030, "speaker": "Stanley Hudson"},
  {"season": 9, "episode": 18, "scene": 26, "line": "Oh! Seven new comments. 'The guy at 0:19 is hawt!' [typing] 'Hi Bongripper, it's me, Andy. The guy from 0:19, I'm glad that you enjoyed my work in that promo. I really enjoyed your comment, going to read some more comments now. Have a great day!'[reading] 'He's not hawt, he's gay.' [typing] 'Dear JasonJasonJason, it's me, Andy. Nice name. Not! Guess what? I'm not gay! So you are an IDIOT. And I am hawt, according to people on this site who have a brain. Never comment on this page ever again.' [reading] 'He is hawt!' See, thank you, that's more like it. 'He is butt.' God dammit! I'm about to lose my FREAKING MIND! Screw you TexasPoonTappa! Uh!", "order": 56031, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 18, "scene": 27, "line": "Security deposit. That's been-", "order": 56032, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 18, "scene": 27, "line": "Standard.", "order": 56033, "speaker": "Mr. Ruger"},
  {"season": 9, "episode": 18, "scene": 27, "line": "Right, standard.", "order": 56034, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 18, "scene": 27, "line": "Hey, can I talk to you for one second?", "order": 56035, "speaker": "Clark Green"},
  {"season": 9, "episode": 18, "scene": 27, "line": "No.", "order": 56036, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 18, "scene": 27, "line": "One second.", "order": 56037, "speaker": "Clark Green"},
  {"season": 9, "episode": 18, "scene": 27, "line": "No.", "order": 56038, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 18, "scene": 27, "line": "One second.", "order": 56039, "speaker": "Clark Green"},
  {"season": 9, "episode": 18, "scene": 27, "line": "I am closing a deal on a tractor with the father of a woman I plan to inseminate.", "order": 56040, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 18, "scene": 27, "line": "Don't do it. [takes Dwight's pen]", "order": 56041, "speaker": "Clark Green"},
  {"season": 9, "episode": 18, "scene": 27, "line": "What? Don't you cap that pen. Do not cap that pen! Do not! Ugh! You capped it. Wow. You are serious. Ok, you've got two minutes and then the cap comes off.", "order": 56042, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 18, "scene": 27, "line": "Dude, we're being conned.", "order": 56043, "speaker": "Clark Green"},
  {"season": 9, "episode": 18, "scene": 27, "line": "Go on.", "order": 56044, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 18, "scene": 27, "line": "These chicks are way too hot to be into us. Esther's just pretending to like you so that you'll buy her daddy a new tractor.", "order": 56045, "speaker": "Clark Green"},
  {"season": 9, "episode": 18, "scene": 27, "line": "No.", "order": 56046, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 18, "scene": 27, "line": "Yes. Her sister's trying to seduce me into buying an auger with her.", "order": 56047, "speaker": "Clark Green"},
  {"season": 9, "episode": 18, "scene": 27, "line": "What? Has the warranty expired on the auger you have now?", "order": 56048, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 18, "scene": 27, "line": "I don't even know what an auger is!", "order": 56049, "speaker": "Clark Green"},
  {"season": 9, "episode": 18, "scene": 27, "line": "No woman would ever want a man who doesn't know what an auger is.", "order": 56050, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 18, "scene": 27, "line": "Hey, you ready to sign?", "order": 56051, "speaker": "Mr. Ruger"},
  {"season": 9, "episode": 18, "scene": 27, "line": "I just need a moment to consider your offer. Excuse me. [grabs Clark]", "order": 56052, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 18, "scene": 28, "line": "Oh, hey!", "order": 56053, "speaker": "Brian"},
  {"season": 9, "episode": 18, "scene": 28, "line": "Hey!", "order": 56054, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 18, "scene": 28, "line": "Hi. [laughs]", "order": 56055, "speaker": "Brian"},
  {"season": 9, "episode": 18, "scene": 28, "line": "Hi, um. I'm sorry, do you have a minute? Is this a bad time?", "order": 56056, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 18, "scene": 28, "line": "No, no, please, yeah, come on in. It's good to see you. Sorry, my place is usually not this-", "order": 56057, "speaker": "Brian"},
  {"season": 9, "episode": 18, "scene": 28, "line": "Oh my gosh, please, don't.", "order": 56058, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 18, "scene": 28, "line": "Yeah, no, I- actually it's always like this. [laughs] Do you want to go outside? It's a little less cluttered out there.", "order": 56059, "speaker": "Brian"},
  {"season": 9, "episode": 18, "scene": 28, "line": "Sure, yeah. Yeah.", "order": 56060, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 18, "scene": 28, "line": "Let me grab a couple drinks.", "order": 56061, "speaker": "Brian"},
  {"season": 9, "episode": 18, "scene": 28, "line": "OK. [Goes out onto terrace] Oh wow, you have a nice view.", "order": 56062, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 18, "scene": 28, "line": "Alright, that's for you. [hands her beer]", "order": 56063, "speaker": "Brian"},
  {"season": 9, "episode": 18, "scene": 28, "line": "Oh, thank you.", "order": 56064, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 18, "scene": 28, "line": "Cheers.", "order": 56065, "speaker": "Brian"},
  {"season": 9, "episode": 18, "scene": 28, "line": "Cheers.", "order": 56066, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 18, "scene": 29, "line": "[Plays boom box to drown out sound] Alright, how much have you revealed on camera about your relationship with the senator?", "order": 56067, "speaker": "Angela Martin"},
  {"season": 9, "episode": 18, "scene": 29, "line": "They caught us kissing on Halloween.", "order": 56068, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 18, "scene": 29, "line": "Oh! He was dressed like Ronald Reagan! [Slaps Oscar]", "order": 56069, "speaker": "Angela Martin"},
  {"season": 9, "episode": 18, "scene": 29, "line": "Oh!", "order": 56070, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 18, "scene": 29, "line": "God!", "order": 56071, "speaker": "Angela Martin"},
  {"season": 9, "episode": 18, "scene": 29, "line": "Angela! Well he kissed like Jack Kennedy!", "order": 56072, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 18, "scene": 29, "line": "Oh! [slaps him again] Stop it! Stop kissing him! Someone needs to call and warn him. This could ruin his career.", "order": 56073, "speaker": "Angela Martin"},
  {"season": 9, "episode": 18, "scene": 29, "line": "Well, I don't like giving him bad news.", "order": 56074, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 18, "scene": 29, "line": "Call him!", "order": 56075, "speaker": "Angela Martin"},
  {"season": 9, "episode": 18, "scene": 29, "line": "You call him!", "order": 56076, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 18, "scene": 29, "line": "Call him! [hits Oscar]", "order": 56077, "speaker": "Angela Martin"},
  {"season": 9, "episode": 18, "scene": 29, "line": "Stop hitting me!", "order": 56078, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 18, "scene": 29, "line": "Call him! Call him!", "order": 56079, "speaker": "Angela Martin"},
  {"season": 9, "episode": 18, "scene": 29, "line": "No!", "order": 56080, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 18, "scene": 30, "line": "So...", "order": 56081, "speaker": "Brian"},
  {"season": 9, "episode": 18, "scene": 30, "line": "So..", "order": 56082, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 18, "scene": 30, "line": "What brings you by?", "order": 56083, "speaker": "Brian"},
  {"season": 9, "episode": 18, "scene": 30, "line": "Well, the promo for the documentary aired today.", "order": 56084, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 18, "scene": 30, "line": "Oh yeah, that's right.", "order": 56085, "speaker": "Brian"},
  {"season": 9, "episode": 18, "scene": 30, "line": "Yeah. It's kinda crazy.", "order": 56086, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 18, "scene": 30, "line": "Yeah, it is.", "order": 56087, "speaker": "Brian"},
  {"season": 9, "episode": 18, "scene": 30, "line": "See all this like old stuff, like um, there's that shot of Jim and I up on the roof?", "order": 56088, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 18, "scene": 30, "line": "Oh yeah, that was, that was a good moment.", "order": 56089, "speaker": "Brian"},
  {"season": 9, "episode": 18, "scene": 30, "line": "Yeah, wasn't that neat?", "order": 56090, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 18, "scene": 30, "line": "Yeah, it was cool.", "order": 56091, "speaker": "Brian"},
  {"season": 9, "episode": 18, "scene": 30, "line": "Yeah, and there's this one when we were listening to music and it's like, it's like w were in love and we didn't even know we were in love and it's...but- Do you think Jim's changed?", "order": 56092, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 18, "scene": 30, "line": "Um...", "order": 56093, "speaker": "Brian"},
  {"season": 9, "episode": 18, "scene": 30, "line": "I'm sorry. Did that? That was out of the blue-", "order": 56094, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 18, "scene": 30, "line": "No no, it's-", "order": 56095, "speaker": "Brian"},
  {"season": 9, "episode": 18, "scene": 30, "line": "I just mean because you know us and you like observed us for ten years and I feel like he's- I just feel like...he's so into his work right now and....I don't know, am I crazy?", "order": 56096, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 18, "scene": 30, "line": "No, you're not crazy.", "order": 56097, "speaker": "Brian"},
  {"season": 9, "episode": 18, "scene": 30, "line": "Well, I wish that made me feel better. Listen, so everybody saw the promos and they're kinda freaking out. [Brian laughs] Cause it seems like you got a lot of private stuff on camera-", "order": 56098, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 18, "scene": 30, "line": "Yeah.", "order": 56099, "speaker": "Brian"},
  {"season": 9, "episode": 18, "scene": 30, "line": "You know, stuff people didn't intend for everyone to see. And they kinda want to know how much.", "order": 56100, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 18, "scene": 30, "line": "They want to know how much what?", "order": 56101, "speaker": "Brian"},
  {"season": 9, "episode": 18, "scene": 30, "line": "How much stuff you got.", "order": 56102, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 18, "scene": 30, "line": "Pretty much everything.", "order": 56103, "speaker": "Brian"},
  {"season": 9, "episode": 18, "scene": 30, "line": "Well yeah, but what if we turned our mike packs?", "order": 56104, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 18, "scene": 30, "line": "They've got parabolic mikes, they can pick you up a hundred yards away, so...no if you were around there, they got you.", "order": 56105, "speaker": "Brian"},
  {"season": 9, "episode": 18, "scene": 30, "line": "So we basically had no privacy for ten years.", "order": 56106, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 18, "scene": 30, "line": "That's not really true, I mean-", "order": 56107, "speaker": "Brian"},
  {"season": 9, "episode": 18, "scene": 30, "line": "Um...yeah, I gotta, I gotta go.", "order": 56108, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 18, "scene": 30, "line": "Oh no, hang on a second. Pam, I'm sorry, I- I- I can explain this so much better.", "order": 56109, "speaker": "Brian"},
  {"season": 9, "episode": 18, "scene": 30, "line": "No I think you explained it.", "order": 56110, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 18, "scene": 30, "line": "Look, if you give me a chance I can, I- [Pam leaves] Pam.", "order": 56111, "speaker": "Brian"},
  {"season": 9, "episode": 18, "scene": 31, "line": "Together we will win this baseball game against the evil space Yankees. Eat Fresh.'", "order": 56112, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 18, "scene": 31, "line": "That'll pay for the exploding helicopter.", "order": 56113, "speaker": "Ryan Howard Howard"},
  {"season": 9, "episode": 18, "scene": 31, "line": "Smart.", "order": 56114, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 18, "scene": 31, "line": "Suddenly, the evil thugs break in to the stadium. The Big Piece hits baseballs at the evil thugs.'", "order": 56115, "speaker": "Ryan Howard Howard"},
  {"season": 9, "episode": 18, "scene": 31, "line": "Buy me some peanuts and cracker jacks.'", "order": 56116, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 18, "scene": 31, "line": "Come on man, sell it!", "order": 56117, "speaker": "Ryan Howard Howard"},
  {"season": 9, "episode": 18, "scene": 31, "line": "Yeah, Jim.", "order": 56118, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 18, "scene": 31, "line": "[louder] 'Buy me some peanuts and cracker jacks!'", "order": 56119, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 18, "scene": 31, "line": "Yeah, that's better. A bunch of hot women go: 'Oh yeah!'", "order": 56120, "speaker": "Ryan Howard Howard"},
  {"season": 9, "episode": 18, "scene": 31, "line": "Megan, I was too shy to tell you this when I was just a normal professional baseball player, but I love you.'", "order": 56121, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 18, "scene": 31, "line": "They kiss. It is super emotional. Like in Toy Story.'", "order": 56122, "speaker": "Ryan Howard Howard"},
  {"season": 9, "episode": 18, "scene": 31, "line": "Wow. I tell you what, it's really strong. I can't wait to read the rest of it later and see how it ends.", "order": 56123, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 18, "scene": 31, "line": "It's so strong.", "order": 56124, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 18, "scene": 31, "line": "Keep reading then.", "order": 56125, "speaker": "Ryan Howard Howard"},
  {"season": 9, "episode": 18, "scene": 31, "line": "Gotta go! Darth Vader's launching a huge attack.'", "order": 56126, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 18, "scene": 31, "line": "Um another thing. I'm gonna need you to get me the rights to Darth Vader.", "order": 56127, "speaker": "Ryan Howard Howard"},
  {"season": 9, "episode": 18, "scene": 31, "line": "I don't know how we'd go about doing that.", "order": 56128, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 18, "scene": 31, "line": "We can look into it.", "order": 56129, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 18, "scene": 31, "line": "We'll look into it.", "order": 56130, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 18, "scene": 31, "line": "We need Darth.", "order": 56131, "speaker": "Ryan Howard Howard"},
  {"season": 9, "episode": 18, "scene": 31, "line": "We gotta get him.", "order": 56132, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 18, "scene": 31, "line": "We'll go after Darth then.", "order": 56133, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 18, "scene": 31, "line": "We're gonna go get him.", "order": 56134, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 18, "scene": 31, "line": "That's what we gotta do.", "order": 56135, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 18, "scene": 31, "line": "We're gonna get him.", "order": 56136, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 18, "scene": 32, "line": "Maybe you're right. Esther's a ten and the best I've ever done is Angela who's a nine and she rejected me.", "order": 56137, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 18, "scene": 32, "line": "A Scranton nine, but yeah, point taken. Hey, let's go out tonight and just score a couple fours huh? I mean there are no games with fours.", "order": 56138, "speaker": "Clark Green"},
  {"season": 9, "episode": 18, "scene": 32, "line": "Who needs a new tractor anyway? Maybe we're the kinda guys who end up with a tractor that's already been rode hard and put away muddy.", "order": 56139, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 18, "scene": 32, "line": "Screw new tractors. Guys like us, we gotta plant our seed a different way.", "order": 56140, "speaker": "Clark Green"},
  {"season": 9, "episode": 18, "scene": 32, "line": "By hand.", "order": 56141, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 18, "scene": 32, "line": "Dwight, we need to talk.", "order": 56142, "speaker": "Esther"},
  {"season": 9, "episode": 18, "scene": 32, "line": "I don't know that there's anything left for us to talk about, Esther.", "order": 56143, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 18, "scene": 32, "line": "Look, we're gonna have the tractor for the same amount of work days but my dad is planning on leasing it to the Vanderkirk brothers on the weekends.", "order": 56144, "speaker": "Esther"},
  {"season": 9, "episode": 18, "scene": 32, "line": "No.", "order": 56145, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 18, "scene": 32, "line": "So you're going to be paying more, but he's putting on ten times the miles and he's pocketing a profit behind your back.", "order": 56146, "speaker": "Esther"},
  {"season": 9, "episode": 18, "scene": 32, "line": "That snake!", "order": 56147, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 18, "scene": 32, "line": "You need to tell him you want a deal based on miles or he can just stick that tractor where the sun don't shine.", "order": 56148, "speaker": "Esther"},
  {"season": 9, "episode": 18, "scene": 32, "line": "That shady grove out by Willard's pond.", "order": 56149, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 18, "scene": 32, "line": "Mmhm.", "order": 56150, "speaker": "Esther"},
  {"season": 9, "episode": 18, "scene": 32, "line": "So, you're fine with me not leasing the tractor? I mean, our courtship can proceed?", "order": 56151, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 18, "scene": 32, "line": "Of course. [laughs] You didn't just think I was tractor bait, did you?", "order": 56152, "speaker": "Esther"},
  {"season": 9, "episode": 18, "scene": 32, "line": "[laughs] No!", "order": 56153, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 18, "scene": 32, "line": "Hey Dwight, what's an auger used for?", "order": 56154, "speaker": "Clark Green"},
  {"season": 9, "episode": 18, "scene": 32, "line": "Post hole digging. [points and laughs with Esther]", "order": 56155, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 18, "scene": 32, "line": "Stupid.", "order": 56156, "speaker": "Esther"},
  {"season": 9, "episode": 18, "scene": 33, "line": "[On speakerphone] You've reached Senator Robert Lipton, please leave a message. [beep]", "order": 56157, "speaker": "Senator"},
  {"season": 9, "episode": 18, "scene": 33, "line": "Hi honey!", "order": 56158, "speaker": "Angela Martin & Oscar Martinez"},
  {"season": 9, "episode": 18, "scene": 33, "line": "Oh you?", "order": 56159, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 18, "scene": 33, "line": "No you go. Hi honey!", "order": 56160, "speaker": "Angela Martin"},
  {"season": 9, "episode": 18, "scene": 33, "line": "Hey, Hey Robert!", "order": 56161, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 18, "scene": 33, "line": "It's Angela and Oscar.", "order": 56162, "speaker": "Angela Martin"},
  {"season": 9, "episode": 18, "scene": 33, "line": "Hey.", "order": 56163, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 18, "scene": 33, "line": "Just a few quick things.", "order": 56164, "speaker": "Angela Martin"},
  {"season": 9, "episode": 18, "scene": 33, "line": "Um the documentary's going to be airing soon, and, and you look great in the promos.", "order": 56165, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 18, "scene": 33, "line": "Oh you look so handsome! Very presidential.", "order": 56166, "speaker": "Angela Martin"},
  {"season": 9, "episode": 18, "scene": 33, "line": "Very much so.", "order": 56167, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 18, "scene": 33, "line": "Yeah.", "order": 56168, "speaker": "Angela Martin"},
  {"season": 9, "episode": 18, "scene": 33, "line": "Absolutely.", "order": 56169, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 18, "scene": 33, "line": "Yeah!", "order": 56170, "speaker": "Angela Martin"},
  {"season": 9, "episode": 18, "scene": 33, "line": "Hey, I get the sense you're gonna be outed as gay.", "order": 56171, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 18, "scene": 33, "line": "Yes and I cheated on you with Dwight, it looks like they got it on film. I didn't tell you about it.", "order": 56172, "speaker": "Angela Martin"},
  {"season": 9, "episode": 18, "scene": 33, "line": "I think that's it!", "order": 56173, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 18, "scene": 33, "line": "I think we're good.", "order": 56174, "speaker": "Angela Martin"},
  {"season": 9, "episode": 18, "scene": 33, "line": "Done!", "order": 56175, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 18, "scene": 33, "line": "Bye!", "order": 56176, "speaker": "Angela Martin"},
  {"season": 9, "episode": 18, "scene": 33, "line": "Ok!", "order": 56177, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 18, "scene": 33, "line": "Love you! [Oscar hangs up] God.", "order": 56178, "speaker": "Angela Martin"},
  {"season": 9, "episode": 18, "scene": 34, "line": "[on video] Hey! TexasPoonTappa and JasonJasonJason and all the rest of you haters out there! Check this out. [plays banjo, screen types out 'you suck my nutz' from TexasPoonTappa, camera zooms out to reveal Nellie]", "order": 56179, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 18, "scene": 34, "line": "Good night Andy.", "order": 56180, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 18, "scene": 34, "line": "Huh? Yeah, see ya. [sees comment] Oh! What?! [starts crying]", "order": 56181, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 18, "scene": 35, "line": "[Speaks Danish]", "order": 56182, "speaker": "Promo Announcer"},
  {"season": 9, "episode": 18, "scene": 35, "line": "I hope you got sound on everything. I'd love a DVD of that. [at computer opens translator. Types in 'Elskere' which comes back as 'lovers' Pam smiles]", "order": 56183, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 19, "scene": 1, "line": "[humming] What the hell? No. This is NOT happening.", "order": 56184, "speaker": "Stanley Hudson"},
  {"season": 9, "episode": 19, "scene": 1, "line": "Didn't you get the memo? It's Stairmageddon! [claps and opens the door to the stairwell] Come on Stanley! [claps]", "order": 56185, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 19, "scene": 2, "line": "Dwight is having maintenance done on the elevator today, and he was really on top of it. Weeks ago, he started the Stairmageddon Awareness campaign. The idea was to get us prepared, both mentally and physically, for a day that hopefully comes once in a 100 years. It's a' 'Mageddon!'", "order": 56186, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 19, "scene": 3, "line": "Come on! Come on, Stanley! Stay in it!", "order": 56187, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 19, "scene": 3, "line": "[growls] I put 17 damn years into this company, and now they're making me climb Stair Mountain!", "order": 56188, "speaker": "Stanley Hudson"},
  {"season": 9, "episode": 19, "scene": 3, "line": "Come on! [claps]", "order": 56189, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 19, "scene": 4, "line": "Our office has an unusually large number of' unusually large people.", "order": 56190, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 19, "scene": 5, "line": "This is an abomination.", "order": 56191, "speaker": "Stanley Hudson"},
  {"season": 9, "episode": 19, "scene": 5, "line": "Come on. You got this. Here.", "order": 56192, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 19, "scene": 6, "line": "So when something as routine as elevator maintenance happens, and people are forced to expend cardiovascular effort, we have to compare it to the end of time.", "order": 56193, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 19, "scene": 7, "line": "Red alert! Red alert! The reviews are in! I repeat, the reviews are in.", "order": 56194, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 19, "scene": 7, "line": "What?", "order": 56195, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 19, "scene": 7, "line": "I just got a text from my brother. Scranton Times Tribune.com. There's a review of the documentary!", "order": 56196, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 19, "scene": 7, "line": "[gasps] What does it say?", "order": 56197, "speaker": "Phyllis Vance"},
  {"season": 9, "episode": 19, "scene": 7, "line": "I don't know, Phyllis! I just got the text and started screaming, 'Red Alert.'", "order": 56198, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 19, "scene": 7, "line": "Well, the alert was already set to 'Red' because of Stairmageddon. You think I should set it to 'Double Red'?", "order": 56199, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 19, "scene": 7, "line": "I think we should.", "order": 56200, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 19, "scene": 8, "line": "[drinks a 5-Hour Energy shot and groans]", "order": 56201, "speaker": "Stanley Hudson"},
  {"season": 9, "episode": 19, "scene": 9, "line": "The Office: An American Workplace airing on PBS next month is a documentary following the employees of Scranton's own Dunder Mifflin Paper Company!'", "order": 56202, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 19, "scene": 9, "line": "Whoo! [applause]", "order": 56203, "speaker": "Everyone"},
  {"season": 9, "episode": 19, "scene": 9, "line": "In this series, which will air starting in May, we get an in-depth look at many interesting local people. There's Kevin Malone, the falstaffian accountant. Dwight Schrute, the head salesman forever chasing a manager position he will never get.'", "order": 56204, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 19, "scene": 10, "line": "What does Josh McAuliffe know about the paper business? He works for a news'thing.", "order": 56205, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 19, "scene": 11, "line": "Andy Bernard, the rudderless trust fund child-slash-middle manager, whose incompetence is emblematic of a declining American economy.'", "order": 56206, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 19, "scene": 11, "line": "Ouch. Sorry, Andy, that's-.", "order": 56207, "speaker": "Phyllis Vance"},
  {"season": 9, "episode": 19, "scene": 11, "line": "It's okay. [chuckles] The hell does he know?", "order": 56208, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 19, "scene": 11, "line": "A possible explanation for his lack of career focus is his surprising musical talent.'", "order": 56209, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 19, "scene": 11, "line": "I want you to print that out for me.", "order": 56210, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 19, "scene": 11, "line": "I will.", "order": 56211, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 19, "scene": 12, "line": "Now that this documentary is coming out, my days at Dunder Mifflin are probably limited. And you know what? Good. Because this is not what I wanted to do with my life. I wanted to be my generation's Lisa Loeb.", "order": 56212, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 19, "scene": 13, "line": "Though it mostly focuses on the daily realities of office life, a lurid subplot reveals the hypocrisy of a local public figure embroiled in a gay affair while preaching family values.'", "order": 56213, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 19, "scene": 13, "line": "Oh! Which public figure?", "order": 56214, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 19, "scene": 13, "line": "I bet it's Katie Couric. I've been saying this for years.", "order": 56215, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 19, "scene": 13, "line": "No, I think they mean more like a politician.", "order": 56216, "speaker": "Phyllis Vance"},
  {"season": 9, "episode": 19, "scene": 13, "line": "[walks into the office grunting and wheezing] You own the building. Why can't you fix the elevator in the middle of the night? Who do I look like? Jackie Joyner-Kersee?", "order": 56217, "speaker": "Stanley Hudson"},
  {"season": 9, "episode": 19, "scene": 13, "line": "Well, I did say it would be an inconvenience. You should have called me from downstairs. We could've met in the lobby. It's time to go out on a sale! Here we go.", "order": 56218, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 19, "scene": 13, "line": "Son, you've lost your mind. I'm not going anywhere until you fix my elevator.", "order": 56219, "speaker": "Stanley Hudson"},
  {"season": 9, "episode": 19, "scene": 13, "line": "The buyer is your sister's friend. This is the printing paper for the entire school district of Lackawanna. You are coming, and that's an order.", "order": 56220, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 19, "scene": 13, "line": "You are not my damn boss and you never will be! Guess what? Never gonna happen! Pete! Iced tea. Three sugars, five creams.", "order": 56221, "speaker": "Stanley Hudson"},
  {"season": 9, "episode": 19, "scene": 13, "line": "Your morning 3-by-5. Coming right up.", "order": 56222, "speaker": "Pete Miller"},
  {"season": 9, "episode": 19, "scene": 14, "line": "Well, we won't be late. I love you, mom. Thanks.", "order": 56223, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 19, "scene": 14, "line": "Oh, your mom's watching the kids tonight. So what are you two up to? [chuckles] Oh, um, Embassy Suites. 'Do Not Disturb' sign on the door. Mommy and Daddy are on the floor.", "order": 56224, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 19, "scene": 14, "line": "[laughs] I wish.", "order": 56225, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 19, "scene": 14, "line": "[chuckles] What, then?", "order": 56226, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 19, "scene": 14, "line": "Oh, nothing that exciting.", "order": 56227, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 19, "scene": 15, "line": "Marriage counseling?", "order": 56228, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 19, "scene": 15, "line": "Hmm.", "order": 56229, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 19, "scene": 15, "line": "Did you know that is the only kind of counseling I have never had?", "order": 56230, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 19, "scene": 15, "line": "You know, Jim's kind of nervous about it, but I think it could really help.", "order": 56231, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 19, "scene": 15, "line": "Mmm.", "order": 56232, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 19, "scene": 15, "line": "I mean, we've having issues. It can't hurt to talk about them, right?", "order": 56233, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 19, "scene": 16, "line": "Hey, Toby. Um, I wanted to ask you a question.", "order": 56234, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 19, "scene": 16, "line": "Oh, sure.", "order": 56235, "speaker": "Toby Flenderson"},
  {"season": 9, "episode": 19, "scene": 16, "line": "It's a little, uh, personal.", "order": 56236, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 19, "scene": 16, "line": "Let's do it. Let's get personal.", "order": 56237, "speaker": "Toby Flenderson"},
  {"season": 9, "episode": 19, "scene": 16, "line": "I wanted to talk to you about your divorce.", "order": 56238, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 19, "scene": 16, "line": "Whoa.", "order": 56239, "speaker": "Toby Flenderson"},
  {"season": 9, "episode": 19, "scene": 16, "line": "Sorry. I'no, no, no, what I meant'what I meant actually was'", "order": 56240, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 19, "scene": 16, "line": "Oh, yeah, no, it's okay. I can handle it. Um, so'", "order": 56241, "speaker": "Toby Flenderson"},
  {"season": 9, "episode": 19, "scene": 16, "line": "You guys obviously went through some tough times leading up to it.", "order": 56242, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 19, "scene": 16, "line": "[chuckles, snorts]", "order": 56243, "speaker": "Toby Flenderson"},
  {"season": 9, "episode": 19, "scene": 16, "line": "Okay. I was wondering if you ever did any couple's counseling.", "order": 56244, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 19, "scene": 16, "line": "Oh, sure, lots of times. Yeah. Wait, you and Pam aren't in couple's counseling, are you? Oh, God.", "order": 56245, "speaker": "Toby Flenderson"},
  {"season": 9, "episode": 19, "scene": 16, "line": "No, no, no, no, no. Uh, we're just starting couple's counseling. Uh, which doesn't sound any better.", "order": 56246, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 19, "scene": 16, "line": "Oh, you guys. Kelly called it. 2013. So s'hey! Hey! Hey, no! No! Get outta here. Clark, get outta here.", "order": 56247, "speaker": "Toby Flenderson"},
  {"season": 9, "episode": 19, "scene": 16, "line": "My mistake.", "order": 56248, "speaker": "Clark Green"},
  {"season": 9, "episode": 19, "scene": 16, "line": "Yes, it is your mistake. He's lingering. So annoying. I'm gonna kill him. How can I help? I'm here.", "order": 56249, "speaker": "Toby Flenderson"},
  {"season": 9, "episode": 19, "scene": 16, "line": "That's all right.", "order": 56250, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 19, "scene": 17, "line": "Hello, William Morris Agency. I need to speak with your best agent who represents your biggest stars. Yes, I'll hold. I'm sorry. I misunderstood. Goodbye. [hangs up phone]", "order": 56251, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 19, "scene": 18, "line": "Thank you, Stan. Oh, honey. [Stancloses limo door]Look, I just want our life to get back to normal. Ribbon cuttings, charity balls.", "order": 56252, "speaker": "Angela Martin"},
  {"season": 9, "episode": 19, "scene": 18, "line": "Don't worry. I've scheduled a press conference for later today. We just need to face the camera together. A beloved public servant and his devoted wife. And move on.", "order": 56253, "speaker": "Robert California"},
  {"season": 9, "episode": 19, "scene": 18, "line": "All right, if I have to be the good wife, I'll be the best damn wife there is. Correction. Best darn wife. Sorry, I'm a better wife than that. [sighs]", "order": 56254, "speaker": "Angela Martin"},
  {"season": 9, "episode": 19, "scene": 19, "line": "Andy'[knocks] I need to talk to you.", "order": 56255, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 19, "scene": 19, "line": "Yeah, come on in. I'm just on hold with another talent agency. It's insane. This promo with me playing banjo has 250 views already. And every time I click, there's more. 251. 252. I can't even keep up!", "order": 56256, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 19, "scene": 19, "line": "Stanley is refusing to go out on a sales call.", "order": 56257, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 19, "scene": 19, "line": "[grunts intensely] I hate people! Why do they never do what you need them to do? Stanley has to go. That's final.", "order": 56258, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 19, "scene": 19, "line": "So what I'm hearing you say is, 'Make Stanley go out on the sales call by whatever means possible'?", "order": 56259, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 19, "scene": 19, "line": "Yes! I'm sorry I'm being curt, it's just I'm about to land a top talent agent.", "order": 56260, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 19, "scene": 19, "line": "Mm-hmm. Good luck.", "order": 56261, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 19, "scene": 19, "line": "[on the phone] Directory? Movie Star department. Back. Directory.", "order": 56262, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 19, "scene": 20, "line": "For five years I've held my instincts in check because I wanted to be made manager. Maybe it's time for me to just let that thought go. It's kind of painful, but it's also freeing in a way. Now it's all about my instincts.", "order": 56263, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 19, "scene": 21, "line": "Hey, Dwight.", "order": 56264, "speaker": "Clark Green"},
  {"season": 9, "episode": 19, "scene": 21, "line": "Stanley, one way or another, you are gonna come with me to make this sale.", "order": 56265, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 19, "scene": 21, "line": "Pass.", "order": 56266, "speaker": "Stanley Hudson"},
  {"season": 9, "episode": 19, "scene": 21, "line": "Hey, c-c-can you just let me out of here before whatever comes next?", "order": 56267, "speaker": "Clark Green"},
  {"season": 9, "episode": 19, "scene": 21, "line": "Don't worry, it's just a bull tranquilizer. Nothing to be alarmed about. It's just a man pointing a bull tranquilizer at a coworker.", "order": 56268, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 19, "scene": 21, "line": "Dwight, you do not learn, do you? For a threat to be credible, you ha'", "order": 56269, "speaker": "Stanley Hudson"},
  {"season": 9, "episode": 19, "scene": 21, "line": "[fires tranquilizer gun at Stanley]", "order": 56270, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 19, "scene": 21, "line": "Holy [bleep].", "order": 56271, "speaker": "Clark Green"},
  {"season": 9, "episode": 19, "scene": 21, "line": "No, you didn't. Sick of you and your'ooh'", "order": 56272, "speaker": "Stanley Hudson"},
  {"season": 9, "episode": 19, "scene": 21, "line": "[fires tranquilizer gun twice]", "order": 56273, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 19, "scene": 21, "line": "[falls to the floor with a loud thud]", "order": 56274, "speaker": "Stanley Hudson"},
  {"season": 9, "episode": 19, "scene": 21, "line": "It's all right. Andy approved it.", "order": 56275, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 19, "scene": 22, "line": "Man, he's really in twinkle town now.", "order": 56276, "speaker": "Meredith Palmer"},
  {"season": 9, "episode": 19, "scene": 22, "line": "Is he gonna be okay? I mean, weren't those darts intended for an animal, like, two to three times larger than him?", "order": 56277, "speaker": "Clark Green"},
  {"season": 9, "episode": 19, "scene": 22, "line": "Okay, this dosage was meant for a very small bull, and Stanley's got way more body fat than they do.", "order": 56278, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 19, "scene": 22, "line": "You gave him three shots.", "order": 56279, "speaker": "Clark Green"},
  {"season": 9, "episode": 19, "scene": 22, "line": "Shh. Got about 45 minutes to get him to the client before he comes to. [claps] Grab his feet. [grunts] Let's go!", "order": 56280, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 19, "scene": 22, "line": "All right.", "order": 56281, "speaker": "Clark Green"},
  {"season": 9, "episode": 19, "scene": 22, "line": "Move it! One, two, three. [grunts]", "order": 56282, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 19, "scene": 23, "line": "He's like a manatee. Ready? Let's go again. Come on. We can do this. One, two, three! [Stanley's head hits the door frame] Oh, God. No wonder my elevator cables are under such strain.", "order": 56283, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 19, "scene": 23, "line": "We gotta get a wheelbarrow or something.", "order": 56284, "speaker": "Clark Green"},
  {"season": 9, "episode": 19, "scene": 23, "line": "Yes.", "order": 56285, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 19, "scene": 24, "line": "[grunts] Okay, we're good. We're good. Let's go. Push!", "order": 56286, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 19, "scene": 24, "line": "I am!", "order": 56287, "speaker": "Clark Green"},
  {"season": 9, "episode": 19, "scene": 25, "line": "Any good weekend plans?", "order": 56288, "speaker": "Clark Green"},
  {"season": 9, "episode": 19, "scene": 25, "line": "I might see a movie.", "order": 56289, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 19, "scene": 25, "line": "Nice.", "order": 56290, "speaker": "Clark Green"},
  {"season": 9, "episode": 19, "scene": 25, "line": "What about you?", "order": 56291, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 19, "scene": 25, "line": "Uh, I don't know yet.", "order": 56292, "speaker": "Clark Green"},
  {"season": 9, "episode": 19, "scene": 25, "line": "[grunts] Let's take him'", "order": 56293, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 19, "scene": 25, "line": "I was thinking about'", "order": 56294, "speaker": "Clark Green"},
  {"season": 9, "episode": 19, "scene": 25, "line": "Let's go right to the top of the stairs, okay?", "order": 56295, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 19, "scene": 25, "line": "And then what?", "order": 56296, "speaker": "Clark Green"},
  {"season": 9, "episode": 19, "scene": 25, "line": "Okay, listen. I'm kind of embarrassed to admit this, but I've never actually done this before.", "order": 56297, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 19, "scene": 25, "line": "Well, if I may, you're a natural.", "order": 56298, "speaker": "Clark Green"},
  {"season": 9, "episode": 19, "scene": 25, "line": "Thank you. I mean, I've rehearsed it in my head like 1,000 times, but, uh'", "order": 56299, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 19, "scene": 25, "line": "That's a little weird.", "order": 56300, "speaker": "Clark Green"},
  {"season": 9, "episode": 19, "scene": 25, "line": "I know. Evel Knievel.", "order": 56301, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 19, "scene": 26, "line": "[wraps tape around Stanley's body]", "order": 56302, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 19, "scene": 26, "line": "[connect helmet straps around Stanley's head] That's about as good as that's gonna be.", "order": 56303, "speaker": "Clark Green"},
  {"season": 9, "episode": 19, "scene": 26, "line": "Okay, now here's the plan. I'm gonna launch him. I need you to go to the bottom and catch him.", "order": 56304, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 19, "scene": 26, "line": "Catch?", "order": 56305, "speaker": "Clark Green"},
  {"season": 9, "episode": 19, "scene": 26, "line": "Yeah.", "order": 56306, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 19, "scene": 26, "line": "I can't catch him. He's like, 250 pounds.", "order": 56307, "speaker": "Clark Green"},
  {"season": 9, "episode": 19, "scene": 26, "line": "You use your hands and just blunt his descent, okay? He's gonna be moving slowly. It's only'", "order": 56308, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 19, "scene": 26, "line": "Blunt?", "order": 56309, "speaker": "Clark Green"},
  {"season": 9, "episode": 19, "scene": 26, "line": "It's 15 feet down, it's at a 45-degree angle. Get set in your haunches, it's like your catching a medicine'", "order": 56310, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 19, "scene": 26, "line": "Dude, this size of my haunches' [Stanley's body slips down the stairs and his head thumps into a wall]", "order": 56311, "speaker": "Clark Green"},
  {"season": 9, "episode": 19, "scene": 26, "line": "Okay. Good call. He would have put a hole in your chest same as he put a hole in that wall.", "order": 56312, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 19, "scene": 26, "line": "We should probably call a doctor or something, dude.", "order": 56313, "speaker": "Clark Green"},
  {"season": 9, "episode": 19, "scene": 26, "line": "[slides down the stairs and lands next to Stanley] You okay?", "order": 56314, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 19, "scene": 27, "line": "So how does it work? It's like, you know, the action of talking to a third party breaks up the log jam, or'", "order": 56315, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 19, "scene": 27, "line": "You're really there to talk to each other. I would say that the therapist is more of a facilitator.", "order": 56316, "speaker": "Toby Flenderson"},
  {"season": 9, "episode": 19, "scene": 27, "line": "Mm-hmm.", "order": 56317, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 19, "scene": 27, "line": "He might start by asking each of you, 'Why do you think you're here?'", "order": 56318, "speaker": "Toby Flenderson"},
  {"season": 9, "episode": 19, "scene": 28, "line": "He took this job in Philly without telling me. He bought our house without telling me. At a certain point, heshouldn'tbe rewarded for that.", "order": 56319, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 19, "scene": 29, "line": "If I didn't do certain things without telling Pam, she'd be married to Roy. [chuckles]", "order": 56320, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 19, "scene": 30, "line": "I feel like he's always making these decisions for the family, and then I'm left playing catch-up.", "order": 56321, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 19, "scene": 31, "line": "If she can just hang on for a little while longer, I'this will be so huge for our family.", "order": 56322, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 19, "scene": 31, "line": "Well, what's a little while?", "order": 56323, "speaker": "Toby Flenderson"},
  {"season": 9, "episode": 19, "scene": 31, "line": "What do you mean?", "order": 56324, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 19, "scene": 31, "line": "I mean, what's the end date? It must be really hard for her to sign on to be unhappy if she doesn't know when it's gonna end.", "order": 56325, "speaker": "Toby Flenderson"},
  {"season": 9, "episode": 19, "scene": 31, "line": "That's kind of an impossible question.", "order": 56326, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 19, "scene": 32, "line": "Oh, guys, it's starting! Hurry!", "order": 56327, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 19, "scene": 32, "line": "Ooh, there's Angela! I work with her!", "order": 56328, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 19, "scene": 32, "line": "Huh, yeah. [chuckles] I mean, I'm happy Angela's the first one getting famous, but it's a little weird, no? I mean, she can't sing or act, so it's actually kind of insane, if you think about it.", "order": 56329, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 19, "scene": 32, "line": "Her hair looks beautiful.", "order": 56330, "speaker": "Phyllis Vance"},
  {"season": 9, "episode": 19, "scene": 32, "line": "Yeah, we get it, Phyllis, she looks like a freakin' movie star! [kicks trash can]", "order": 56331, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 19, "scene": 32, "line": "Andy!", "order": 56332, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 19, "scene": 32, "line": "[on the computer screen] I would just to start by saying that there have been some rumors about my personal life that I would like to settle once and for all. As my long-suffering wife can attest' I am gay.", "order": 56333, "speaker": "Robert California"},
  {"season": 9, "episode": 19, "scene": 32, "line": "[surprised chatter]", "order": 56334, "speaker": "Crowd"},
  {"season": 9, "episode": 19, "scene": 32, "line": "Senator, were always gay, or did your wife turn you gay?", "order": 56335, "speaker": "Reporter #1"},
  {"season": 9, "episode": 19, "scene": 32, "line": "Question for the Senator's beard.", "order": 56336, "speaker": "Reporter #2"},
  {"season": 9, "episode": 19, "scene": 33, "line": "[on the computer screen] I'll say it again for mis amigos latinos. Yo soy homosexual.", "order": 56337, "speaker": "Robert California"},
  {"season": 9, "episode": 19, "scene": 33, "line": "Poor Angela.", "order": 56338, "speaker": "Pete Miller"},
  {"season": 9, "episode": 19, "scene": 33, "line": "Yeah. Poor Angela.", "order": 56339, "speaker": "Phyllis Vance"},
  {"season": 9, "episode": 19, "scene": 33, "line": "I once believed that a gay person could be somewhat straight. It wasn't until my marriage to Angela that I realized how'charmless I find the female body.", "order": 56340, "speaker": "Robert California"},
  {"season": 9, "episode": 19, "scene": 33, "line": "Oof. Always hurts to hear that one.", "order": 56341, "speaker": "Meredith Palmer"},
  {"season": 9, "episode": 19, "scene": 33, "line": "There's someone else I need to thank. His name is Oscar Martinez.", "order": 56342, "speaker": "Robert California"},
  {"season": 9, "episode": 19, "scene": 33, "line": "Come on!", "order": 56343, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 19, "scene": 33, "line": "Oscar is the one who opened my eyes to who I really am. For the first time'", "order": 56344, "speaker": "Robert California"},
  {"season": 9, "episode": 19, "scene": 33, "line": "Oscar is with the Senator too?", "order": 56345, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 19, "scene": 33, "line": "Yes! And I knew it the whole time! I kept the secret. I kept the secret so good. You didn't know, you didn't know, and you didn't freaking know. But I knew! [claps]", "order": 56346, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 19, "scene": 33, "line": "He knew!", "order": 56347, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 19, "scene": 33, "line": "Yes, we did it!", "order": 56348, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 19, "scene": 33, "line": "You did it, Kevin.", "order": 56349, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 19, "scene": 33, "line": "Yes! Ohh! I did it. Ohh, I did it.", "order": 56350, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 19, "scene": 33, "line": "--with this new self-awareness, I was finally able to find love at long last. With my amazing' Chief of Staff Wesley Silver.", "order": 56351, "speaker": "Robert California"},
  {"season": 9, "episode": 19, "scene": 33, "line": "What?!", "order": 56352, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 19, "scene": 33, "line": "Wesley Silver's gay?", "order": 56353, "speaker": "Creed Bratton"},
  {"season": 9, "episode": 19, "scene": 33, "line": "[surprised chatter]", "order": 56354, "speaker": "Crowd"},
  {"season": 9, "episode": 19, "scene": 33, "line": "[gasps]", "order": 56355, "speaker": "Angela Martin"},
  {"season": 9, "episode": 19, "scene": 33, "line": "Ohh.", "order": 56356, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 19, "scene": 33, "line": "They make a nice couple.", "order": 56357, "speaker": "Creed Bratton"},
  {"season": 9, "episode": 19, "scene": 33, "line": "[gags]", "order": 56358, "speaker": "Angela Martin"},
  {"season": 9, "episode": 19, "scene": 34, "line": "Almost there. Almost there. [opens car door] Okay. We're running late. Let's get him inside.", "order": 56359, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 19, "scene": 34, "line": "We can't just leave him bubble wrapped like this.", "order": 56360, "speaker": "Clark Green"},
  {"season": 9, "episode": 19, "scene": 34, "line": "Are you kidding me? The bubble wrap is the only thing that's stopping his suit from getting wrinkled. These meetings are all about presentation.", "order": 56361, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 19, "scene": 34, "line": "That's actually really smart.", "order": 56362, "speaker": "Clark Green"},
  {"season": 9, "episode": 19, "scene": 34, "line": "Thank you.", "order": 56363, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 19, "scene": 34, "line": "God, if only there was any other use or situation for that kind of knowledge.", "order": 56364, "speaker": "Clark Green"},
  {"season": 9, "episode": 19, "scene": 34, "line": "Let's get him inside.", "order": 56365, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 19, "scene": 35, "line": "Carla Fern is not just an actor's agent. She does writers, directors, travel, and real estate.", "order": 56366, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 19, "scene": 36, "line": "Actor?", "order": 56367, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 19, "scene": 36, "line": "Oh, no. [chuckles] Well, I have an act. Dog, cat, mouse.", "order": 56368, "speaker": "Man"},
  {"season": 9, "episode": 19, "scene": 36, "line": "Yeah, wow, cool. Is it hard to train them to do that?", "order": 56369, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 19, "scene": 36, "line": "Eh, you go through a lot of mice.", "order": 56370, "speaker": "Man"},
  {"season": 9, "episode": 19, "scene": 37, "line": "If started by accident as these things tend to do. You know, I was setting down my cat, and I accidently put her on top of my dog, and I was, like, so mad at myself at first. And then I was, like, wait. Wait a second.", "order": 56371, "speaker": "Man"},
  {"season": 9, "episode": 19, "scene": 38, "line": "Does anything go on top of the mouse?", "order": 56372, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 19, "scene": 38, "line": "Yeah. Little hat.", "order": 56373, "speaker": "Man"},
  {"season": 9, "episode": 19, "scene": 38, "line": "Aw, that's cute.", "order": 56374, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 19, "scene": 38, "line": "Yeah.", "order": 56375, "speaker": "Man"},
  {"season": 9, "episode": 19, "scene": 38, "line": "What's the mouse's name?", "order": 56376, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 19, "scene": 38, "line": "Eh, you know, it really doesn't make sense to name the mice. They're kinda like cannon fodder, you know? You're not one of those PETA guys , are you? [cat jumps off dog] Oh, great.", "order": 56377, "speaker": "Man"},
  {"season": 9, "episode": 19, "scene": 39, "line": "Hey, wait, wait, how'how are we doing this?", "order": 56378, "speaker": "Clark Green"},
  {"season": 9, "episode": 19, "scene": 39, "line": "Well, I'll grab the forelegs, and you push his hindquarters.", "order": 56379, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 19, "scene": 39, "line": "Just say 'arms' and 'legs,' okay? That just'that's the vernacular that I'm comfortable with.", "order": 56380, "speaker": "Clark Green"},
  {"season": 9, "episode": 19, "scene": 39, "line": "Fine, let's go. [grunts]", "order": 56381, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 19, "scene": 39, "line": "[sighs]", "order": 56382, "speaker": "Clark Green"},
  {"season": 9, "episode": 19, "scene": 39, "line": "Hoist his shank on three.", "order": 56383, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 19, "scene": 39, "line": "Wha'What's a shank?", "order": 56384, "speaker": "Clark Green"},
  {"season": 9, "episode": 19, "scene": 39, "line": "It's by the tenderloin.", "order": 56385, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 19, "scene": 40, "line": "Roll him, roll him, roll him. Good. Good. Okay, careful, he's slouching. Okay, can you'sl-slouch him into the seat. [sighs] Here. Here. Go around and get his seatbelt. [grunts]", "order": 56386, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 19, "scene": 40, "line": "All right.", "order": 56387, "speaker": "Clark Green"},
  {"season": 9, "episode": 19, "scene": 40, "line": "Got it?", "order": 56388, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 19, "scene": 40, "line": "Yeah'yup yup.", "order": 56389, "speaker": "Clark Green"},
  {"season": 9, "episode": 19, "scene": 40, "line": "[grunts and closes car door] Get in the back.", "order": 56390, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 19, "scene": 40, "line": "What?", "order": 56391, "speaker": "Clark Green"},
  {"season": 9, "episode": 19, "scene": 40, "line": "Get in the back.", "order": 56392, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 19, "scene": 40, "line": "Aw, come on.", "order": 56393, "speaker": "Clark Green"},
  {"season": 9, "episode": 19, "scene": 40, "line": "Get in.", "order": 56394, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 19, "scene": 40, "line": "Damn it.", "order": 56395, "speaker": "Clark Green"},
  {"season": 9, "episode": 19, "scene": 41, "line": "Okay, Stanley? Do you understand what we're about to do?", "order": 56396, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 19, "scene": 41, "line": "Helllloooo!", "order": 56397, "speaker": "Stanley Hudson"},
  {"season": 9, "episode": 19, "scene": 41, "line": "Okay. We, hey'hey, listen, listen. We are going to go discuss paper contracts for city of Lackawanna public schools, okay?", "order": 56398, "speaker": "Clark Green"},
  {"season": 9, "episode": 19, "scene": 41, "line": "Pigeons.", "order": 56399, "speaker": "Stanley Hudson"},
  {"season": 9, "episode": 19, "scene": 41, "line": "Oh, God, this is bad. Looks like we've got no choice. You, my friend, are going to have to be Stanley Hudson.", "order": 56400, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 19, "scene": 41, "line": "Isn't the client, like, best friends with his sister?", "order": 56401, "speaker": "Clark Green"},
  {"season": 9, "episode": 19, "scene": 41, "line": "New plan, okay? We get him a cup of coffee and we go back to the old plan. Let's go! Gimme a hand. Here we go. Come on, Stanley! Here we go. Upsie-doozie. There we go. Okay, all right. Come on, big guy. You can walk, right? Yeah. What a pretty smile. Let's go.", "order": 56402, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 19, "scene": 42, "line": "I'm sure Athlead will be a huge success. But I don't want him to do it anymore, and I don't want to give him an ultimatum, but I am not moving our family to Philly.", "order": 56403, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 19, "scene": 43, "line": "Well, if Pam says she won't go, then'[chuckles] we're gonna need a lot more than counseling. Hmph.", "order": 56404, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 19, "scene": 44, "line": "That was exhausting.", "order": 56405, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 19, "scene": 44, "line": "Blah blah blah blah. Jim.", "order": 56406, "speaker": "Toby Flenderson"},
  {"season": 9, "episode": 19, "scene": 44, "line": "Well, they deserve each other, then.", "order": 56407, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 19, "scene": 44, "line": "They do. That they do.", "order": 56408, "speaker": "Toby Flenderson"},
  {"season": 9, "episode": 19, "scene": 44, "line": "That is for sure.", "order": 56409, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 19, "scene": 44, "line": "[whispers] That they do.", "order": 56410, "speaker": "Toby Flenderson"},
  {"season": 9, "episode": 19, "scene": 45, "line": "And for'oh, whoopsie daisy. [chuckles]", "order": 56411, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 19, "scene": 45, "line": "Stanley, what is going on here?", "order": 56412, "speaker": "Mrs. Davis"},
  {"season": 9, "episode": 19, "scene": 45, "line": "He's fine. He gets carsick really easily.", "order": 56413, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 19, "scene": 45, "line": "Driving.", "order": 56414, "speaker": "Clark Green"},
  {"season": 9, "episode": 19, "scene": 45, "line": "It's a long drive. He was in the backseat. But right now we're talking to Mrs. Davis about the full range of the products that we offer and our competitive rates, right, Stanley?", "order": 56415, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 19, "scene": 45, "line": "Ooh-hoo, look at that baby'", "order": 56416, "speaker": "Stanley Hudson"},
  {"season": 9, "episode": 19, "scene": 45, "line": "Stanley.", "order": 56417, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 19, "scene": 45, "line": "Ohhh'", "order": 56418, "speaker": "Stanley Hudson"},
  {"season": 9, "episode": 19, "scene": 45, "line": "That's Benji in the middle.", "order": 56419, "speaker": "Mrs. Davis"},
  {"season": 9, "episode": 19, "scene": 45, "line": "That's Benji. Oh, he's precious. That's a healthy-looking baby.", "order": 56420, "speaker": "Stanley Hudson"},
  {"season": 9, "episode": 19, "scene": 45, "line": "Very special little boy.", "order": 56421, "speaker": "Mrs. Davis"},
  {"season": 9, "episode": 19, "scene": 45, "line": "Look at him. I've never seen such a beautiful child.", "order": 56422, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 19, "scene": 45, "line": "Funny sense of humor. If you push on his nose, he'll go, 'eee.'", "order": 56423, "speaker": "Mrs. Davis"},
  {"season": 9, "episode": 19, "scene": 45, "line": "Like this? Watch.", "order": 56424, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 19, "scene": 45, "line": "Eee.", "order": 56425, "speaker": "Stanley Hudson"},
  {"season": 9, "episode": 19, "scene": 45, "line": "[laughs]", "order": 56426, "speaker": "Everyone"},
  {"season": 9, "episode": 19, "scene": 45, "line": "Like that.", "order": 56427, "speaker": "Mrs. Davis"},
  {"season": 9, "episode": 19, "scene": 45, "line": "A beautiful family.", "order": 56428, "speaker": "Stanley Hudson"},
  {"season": 9, "episode": 19, "scene": 46, "line": "[laughs] Right? Come on!", "order": 56429, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 19, "scene": 47, "line": "Yeah, maybe I'll never be manager, but I just managed to get our most stubborn salesman to close a sale with one of our biggest clients, and I must say, it's the most pleasant I've ever seen Stanley. I think we should consider injecting him with bull tranquilizer on a daily basis.", "order": 56430, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 19, "scene": 47, "line": "So, what can you do?", "order": 56431, "speaker": "Carla"},
  {"season": 9, "episode": 19, "scene": 47, "line": "[Australian accent] Well, what can't I do? Right, I can sing, I can dance, I can play the banjo, innit? And if you hadn't noticed, I've got a pretty good British accent.", "order": 56432, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 19, "scene": 47, "line": "Can you drive a car?", "order": 56433, "speaker": "Carla"},
  {"season": 9, "episode": 19, "scene": 47, "line": "At the risk of sounding arrogant, I did drive myself here.", "order": 56434, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 19, "scene": 47, "line": "Why do you have, uh, a high school musical here on your resume? What are you, like, 40? 45?", "order": 56435, "speaker": "Carla"},
  {"season": 9, "episode": 19, "scene": 47, "line": "My exact age is 28 to 34, so basically just send me out on whatever Jake Gyllenhaal's going out on.", "order": 56436, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 19, "scene": 47, "line": "Gyllenhaal, got it. Can you juggle and crap?", "order": 56437, "speaker": "Carla"},
  {"season": 9, "episode": 19, "scene": 47, "line": "Yes. And yes. [chuckles]", "order": 56438, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 19, "scene": 47, "line": "Would you dress up as, say, a birthday clown and go to a kid's party, let 'em throw pies at ya?", "order": 56439, "speaker": "Carla"},
  {"season": 9, "episode": 19, "scene": 47, "line": "Whereas that is not why I have entered show business, I do understand that you have to build credibility. [sniffs] I'm all for it.", "order": 56440, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 19, "scene": 47, "line": "Well, Mr. Bernard, I'm gonna be honest with you.", "order": 56441, "speaker": "Carla"},
  {"season": 9, "episode": 19, "scene": 47, "line": "Well, at least I tried. Thank you very much.", "order": 56442, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 19, "scene": 47, "line": "Uh, no. We're'we're gonna take you on as a client.", "order": 56443, "speaker": "Carla"},
  {"season": 9, "episode": 19, "scene": 47, "line": "You are? Yes! Yes. Are you being for real right now? Oh, man. Ah, yes! I need this so bad. I really think this is what could fix me.", "order": 56444, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 19, "scene": 47, "line": "We are extremely excited to be working with you too, sir. Pay Todd on your way out.", "order": 56445, "speaker": "Carla"},
  {"season": 9, "episode": 19, "scene": 48, "line": "Most talent agents take 10% of whatever jobs they get you, but with Carla you pay a flat rate of $5,000 up front. And that includes headshots.", "order": 56446, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 19, "scene": 48, "line": "Uh, it doesn't include headshots.", "order": 56447, "speaker": "Todd Packer"},
  {"season": 9, "episode": 19, "scene": 48, "line": "It doesn't include headshots.", "order": 56448, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 19, "scene": 48, "line": "No.", "order": 56449, "speaker": "Todd Packer"},
  {"season": 9, "episode": 19, "scene": 48, "line": "Well, of course not, because that would be insane if it did. Still getting a bargain, though.", "order": 56450, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 19, "scene": 49, "line": "So I just smiled and complimented her grandkids, and we closed it?", "order": 56451, "speaker": "Stanley Hudson"},
  {"season": 9, "episode": 19, "scene": 49, "line": "You earned yourself a nice, fat commission and you didn't even know it. I'll go tell Andy the good news. Oh. [chuckles] Silly me. Gotta take the stairs.", "order": 56452, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 19, "scene": 49, "line": "Oh, no, I'm not doing that again. You got me down, you gotta get me back up.", "order": 56453, "speaker": "Stanley Hudson"},
  {"season": 9, "episode": 19, "scene": 49, "line": "Well'no, no'n'", "order": 56454, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 19, "scene": 49, "line": "[stabs his own leg with a tranquilizer dart and falls to the ground]", "order": 56455, "speaker": "Stanley Hudson"},
  {"season": 9, "episode": 19, "scene": 49, "line": "[stomps on the ground] We need a winch and a hoist.", "order": 56456, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 20, "scene": 1, "line": "Oh. Hey, champions.", "order": 56457, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 20, "scene": 1, "line": "Good morning.", "order": 56458, "speaker": "Toby Flenderson"},
  {"season": 9, "episode": 20, "scene": 1, "line": "Quater finals in an hour. Hope you got some sleep cause I am going to be haunting your nightmares tonight.", "order": 56459, "speaker": "Clark Green"},
  {"season": 9, "episode": 20, "scene": 1, "line": "I did. I got some really good sleep.", "order": 56460, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 20, "scene": 1, "line": "Did you? [pushes pencil cup at Erin]", "order": 56461, "speaker": "Clark Green"},
  {"season": 9, "episode": 20, "scene": 1, "line": "[grabs pencils out of cup quickly as cup falls] Bzzz.", "order": 56462, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 20, "scene": 2, "line": "Yesterday was the first round of a branch wide paper airplane contest. It was being sponsored by Weyer-Hammer Paper in an effort to get us to sell more of their new product Airstream Deluxe A4, the Cadillac of paper. It's not so easy on the environment, if you know what I mean. [whispers] Practically made of plastic.", "order": 56463, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 20, "scene": 3, "line": "We started with sixteen brave aviators. Some use skill [cut to Kevin flying paper airplane], others relied on showmanship [cut to Dwight throwing airplane at Nate with an apple on his head] others seem not to comprehend what a paper airplane is. [cut to Creed throwing a melon] And of course, there was the odd moment of heartbreak and disaster.", "order": 56464, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 20, "scene": 4, "line": "Hey I left my glasses down here somewhere. Crossing through, beep beep. [Pam's plane hit Toby in the eye]", "order": 56465, "speaker": "Toby Flenderson"},
  {"season": 9, "episode": 20, "scene": 4, "line": "Ow! Ow!", "order": 56466, "speaker": "Toby Flenderson"},
  {"season": 9, "episode": 20, "scene": 4, "line": "I didn't see you! You should have yelled 'Crossing!'", "order": 56467, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 20, "scene": 4, "line": "[crying] I'm sorry!", "order": 56468, "speaker": "Toby Flenderson"},
  {"season": 9, "episode": 20, "scene": 4, "line": "Ok, so is that my spot?", "order": 56469, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 20, "scene": 5, "line": "We are now down to an elite eight. Well, seven and Toby.", "order": 56470, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 20, "scene": 6, "line": "[Reading from script] Be careful of that beaker, it contains dangerous acid!", "order": 56471, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 20, "scene": 6, "line": "It does not say dangerous. And there's no exclamation point.", "order": 56472, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 20, "scene": 6, "line": "Well I'm just trying to bring some life to it.", "order": 56473, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 20, "scene": 7, "line": "Last week I got an agent and uh, this week I got a movie. HRPDC chemical handling protocols. It's gonna be seen by tons of workers in the industrial chemical community. One of whom could have a cousin whose brother's Brad Pitt. And boom, next thing you know, I'm in Moneyballs Two.", "order": 56474, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 20, "scene": 8, "line": "Andy?", "order": 56475, "speaker": "Toby Flenderson"},
  {"season": 9, "episode": 20, "scene": 8, "line": "Go away, we're running lines.", "order": 56476, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 20, "scene": 8, "line": "You wanted to see the gooey eye.", "order": 56477, "speaker": "Toby Flenderson"},
  {"season": 9, "episode": 20, "scene": 8, "line": "Oh yeah. Alright, get over here. I am so freaked out by things going into eyes. I just- wow. [moves to lift Toby's eye patch] Uggggh! I can't even, I'm so freaked out by that, just go. Go go go go go.", "order": 56478, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 20, "scene": 8, "line": "Alright, it's getting gooier so we'll just do it later.", "order": 56479, "speaker": "Toby Flenderson"},
  {"season": 9, "episode": 20, "scene": 8, "line": "Yeah, Ok. [Closes door] Alright. Where were we?", "order": 56480, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 20, "scene": 8, "line": "Something full of acid.", "order": 56481, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 20, "scene": 8, "line": "And remember: Do not attempt to extinguish a chemical fire with water. You will only exacerbate the flame. The dangerous flame.", "order": 56482, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 20, "scene": 8, "line": "[claps] That was great man. Alright, I'm out of here.", "order": 56483, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 20, "scene": 8, "line": "W-w-w-wait. Stop stop stop. Don't go anywhere. I just need to find more colors. Let's do it six more times.", "order": 56484, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 20, "scene": 9, "line": "Hey. I just wanted to say that you woke up early with the kids and let me sleep and I really appreciate that.", "order": 56485, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 20, "scene": 9, "line": "Thank you. I appreciate that you appreciate that.", "order": 56486, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 20, "scene": 10, "line": "So, we had couple's therapy.", "order": 56487, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 20, "scene": 10, "line": "No shame in that. Get it all out in the open.", "order": 56488, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 20, "scene": 10, "line": "And we have homework.", "order": 56489, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 20, "scene": 10, "line": "Yes, we are supposed to look for every chance to acknowledge and appreciate each other's sacrifices. Because I need to appreciate what Pam's been doing to run the house while I'm in Philly.", "order": 56490, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 20, "scene": 10, "line": "And we're also supposed to speak our truths.", "order": 56491, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 20, "scene": 10, "line": "Mmhm.", "order": 56492, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 20, "scene": 10, "line": "Because if I had spoken my truth sooner about not wanting to move to Philly, then maybe we wouldn't have had this opportunity for couple's therapy.", "order": 56493, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 20, "scene": 10, "line": "Oh, we're supposed to call everything we don't want to do 'opportunities.'", "order": 56494, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 20, "scene": 11, "line": "Heads up everyone. If you really need something from me today, let's get it done tomorrow. Carla Fern got me a gig.", "order": 56495, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 20, "scene": 11, "line": "Hey!", "order": 56496, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 20, "scene": 11, "line": "Yeah.", "order": 56497, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 20, "scene": 11, "line": "Who's Carla Fern?", "order": 56498, "speaker": "Stanley Hudson"},
  {"season": 9, "episode": 20, "scene": 11, "line": "Who is Carla Fern? Well, wow. Uh, she's my agent and my drill sargent. And one of my best friends. Oh, and Oscar, I already figured it out. If I have to get emotional in the film, I'm just going to think about you getting dumped by the Senator.", "order": 56499, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 20, "scene": 11, "line": "Why wouldn't you use your own life? Erin just dumped you.", "order": 56500, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 20, "scene": 11, "line": "Little raw. Not cool Oscar.", "order": 56501, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 20, "scene": 11, "line": "But you just!", "order": 56502, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 20, "scene": 11, "line": "Not cool.", "order": 56503, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 20, "scene": 11, "line": "Hey. Day two. Drama in the warehouse skies. This is Robert from Weyer-Hammer Paper, who will be joining us to judge the final rounds.", "order": 56504, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 20, "scene": 11, "line": "Who's pumped for the quarter finals, huh?", "order": 56505, "speaker": "Robert California"},
  {"season": 9, "episode": 20, "scene": 11, "line": "I'm pumped!", "order": 56506, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 20, "scene": 11, "line": "Clark's a dead man.", "order": 56507, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 20, "scene": 11, "line": "Alright. Sounds like somebody wants to walk away with this. [holds up large check for $2,000]", "order": 56508, "speaker": "Robert California"},
  {"season": 9, "episode": 20, "scene": 11, "line": "Oh my god!", "order": 56509, "speaker": "Angela Martin"},
  {"season": 9, "episode": 20, "scene": 11, "line": "Whoa.", "order": 56510, "speaker": "Group"},
  {"season": 9, "episode": 20, "scene": 11, "line": "Nellie you didn't tell us we could win money.", "order": 56511, "speaker": "Angela Martin"},
  {"season": 9, "episode": 20, "scene": 11, "line": "Oh yes I did, I told you all. It was..um, cause that's an awful lot of money for me to forget.", "order": 56512, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 20, "scene": 11, "line": "Not one of us remembers you saying anything about two thousand dollars.", "order": 56513, "speaker": "Stanley Hudson"},
  {"season": 9, "episode": 20, "scene": 12, "line": "I forgot. I completely forgot. But at least now that large piece of cardboard that man was carrying around makes sense.", "order": 56514, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 20, "scene": 13, "line": "Nellie, this is a competition. Please take it seriously.", "order": 56515, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 20, "scene": 13, "line": "Oh please. [Dwight watches Angela folding paper airplane]", "order": 56516, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 20, "scene": 14, "line": "Me? Oh, I'm fine. I mean, sure, times are leaner now that I'm separated from the Senator. But my new studio apartment is just fine [camera shows small cluttered space with excessive cats and Phillip crying] for me.. and Phillip... and Tinky... and Crinklepuss, and Bandit 2, and Pawlick Baggins, and Lady Aragorn and their 10 kittens.", "order": 56517, "speaker": "Angela Martin"},
  {"season": 9, "episode": 20, "scene": 15, "line": "[cries]", "order": 56518, "speaker": "Phillip"},
  {"season": 9, "episode": 20, "scene": 15, "line": "Come here, come here. Let's go.", "order": 56519, "speaker": "Angela Martin"},
  {"season": 9, "episode": 20, "scene": 16, "line": "I had a chance with Dwight, but I didn't take it. And if I went back now, when I'm broke and he just inherited a farm, I'd be one of those gold-digging tramps you read about that try to bag a farmer.", "order": 56520, "speaker": "Angela Martin"},
  {"season": 9, "episode": 20, "scene": 17, "line": "I offered myself to Angela and she turned me down. If she changes her mind, the next move is hers. I'm with Esther now. She's younger than Angela, sturdier, more comfortable with the scent of a manured field. Let's be honest. When it came to manured fields, Angela was at best indifferent.", "order": 56521, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 20, "scene": 18, "line": "Oh, wide wings, interesting.", "order": 56522, "speaker": "Clark Green"},
  {"season": 9, "episode": 20, "scene": 18, "line": "Hey. Why don't you back off?[looks at Pete]...I mean best of luck to you in the competition.", "order": 56523, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 20, "scene": 19, "line": "Growing up in an orphanage, you have to fight other kids. For everything. Snacks, pillows, parents. I'm kinda worried about Pete seeing that side of me. I once ripped greedy Susan's pigtail right off her head. Just for a handful of Crispix.", "order": 56524, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 20, "scene": 20, "line": "Are you in that paper documentary too?", "order": 56525, "speaker": "Carla Fern"},
  {"season": 9, "episode": 20, "scene": 20, "line": "Yep.", "order": 56526, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 20, "scene": 20, "line": "Do you need an agent?", "order": 56527, "speaker": "Carla Fern"},
  {"season": 9, "episode": 20, "scene": 20, "line": "No. He's- I mean, you've never acted in anything before. He's just my entourage.", "order": 56528, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 20, "scene": 20, "line": "I was in The Whiz in high school.", "order": 56529, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 20, "scene": 21, "line": "That's the cleaky clacker! He clicks that and then the guy says 'action.'", "order": 56530, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 20, "scene": 21, "line": "Hey, I made them get you a chair. All my clients sit.", "order": 56531, "speaker": "Carla Fern"},
  {"season": 9, "episode": 20, "scene": 22, "line": "Can I take your picture?", "order": 56532, "speaker": "Woman"},
  {"season": 9, "episode": 20, "scene": 22, "line": "I guess it's starting. Um, yeah. Of course. Yeah sure. Tell you what. I'll put my arm around you and then I can take it-", "order": 56533, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 20, "scene": 22, "line": "We just need a picture of the top of your head in case we burn some of your hair off.", "order": 56534, "speaker": "Woman"},
  {"season": 9, "episode": 20, "scene": 22, "line": "Got it. [lowers head to show hair, but keeps looking up just as she tries to take the picture]", "order": 56535, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 20, "scene": 22, "line": "No, if you could just keep it down until..", "order": 56536, "speaker": "Woman"},
  {"season": 9, "episode": 20, "scene": 22, "line": "Sorry.", "order": 56537, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 20, "scene": 23, "line": "OK, next up we have two creatures great and small. Kevin versus Angela.", "order": 56538, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 20, "scene": 23, "line": "Yes!", "order": 56539, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 20, "scene": 23, "line": "Hey, that is a really nice plane. You make that yourself?", "order": 56540, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 20, "scene": 23, "line": "Uh huh.", "order": 56541, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 20, "scene": 23, "line": "Well, what am I thinking? Of course you made that yourself. Cause it's in the rules that you have to fold your own plane.", "order": 56542, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 20, "scene": 23, "line": "Of course.", "order": 56543, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 20, "scene": 23, "line": "Kevin, did you make that yourself?", "order": 56544, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 20, "scene": 23, "line": "Yes. In a way. From one that I bought on Craigslist.", "order": 56545, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 20, "scene": 23, "line": "Oh man.", "order": 56546, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 20, "scene": 23, "line": "I call for a refold!", "order": 56547, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 20, "scene": 23, "line": "No.", "order": 56548, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 20, "scene": 23, "line": "Really? [to Dwight] Thank you.", "order": 56549, "speaker": "Angela Martin"},
  {"season": 9, "episode": 20, "scene": 23, "line": "This is flatter.", "order": 56550, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 20, "scene": 23, "line": "It's a piece of paper. You fold it into an airplane.", "order": 56551, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 20, "scene": 24, "line": "Ok, that's enough. This is the end of the ring now. You have to pick one.", "order": 56552, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 20, "scene": 24, "line": "I can't. I love them all too much. And, none of them fly. So that makes it harder.", "order": 56553, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 20, "scene": 24, "line": "You have to choose one now.", "order": 56554, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 20, "scene": 24, "line": "Fine. [throws plane, doesn't fly]", "order": 56555, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 20, "scene": 24, "line": "Angela advances.", "order": 56556, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 20, "scene": 24, "line": "Nice.", "order": 56557, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 20, "scene": 24, "line": "[clapping] Whoo!", "order": 56558, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 20, "scene": 25, "line": "Was Dwight rooting for me? Hmm. I hadn't noticed.", "order": 56559, "speaker": "Angela Martin"},
  {"season": 9, "episode": 20, "scene": 26, "line": "I'm not giving up. I'm going to keep making planes until one of them flies. Like Wilbur and Orville Redenbacher.", "order": 56560, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 20, "scene": 27, "line": "An Earl Grey tea for the lady.", "order": 56561, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 20, "scene": 27, "line": "Oh, thank you. I acknowledge and appreciate that you went out of your way to get me tea.", "order": 56562, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 20, "scene": 27, "line": "Thank you. I like being appreciated. But, to speak my truth, it wasn't out of the way because I felt like a tea anyway. So, one trip.", "order": 56563, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 20, "scene": 27, "line": "Well, to speak my truth, I switched to coffee in March. There's a new espresso machine. But I still acknowledge and appreciate the gesture.", "order": 56564, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 20, "scene": 28, "line": "We are rolling and...action!", "order": 56565, "speaker": "Director"},
  {"season": 9, "episode": 20, "scene": 28, "line": "[Happily] This video is to demonstrate the HPRDC chemical handling protocols. These protocols could save you from severe injury, even death.", "order": 56566, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 20, "scene": 28, "line": "Ok, um, stop. Um, why are you smiling so much?", "order": 56567, "speaker": "Producer"},
  {"season": 9, "episode": 20, "scene": 28, "line": "Just made a character choice to be a scientist who really likes what he does and enjoys his job.", "order": 56568, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 20, "scene": 28, "line": "Ok, well maybe no smiling on this one.", "order": 56569, "speaker": "Director"},
  {"season": 9, "episode": 20, "scene": 28, "line": "So how do you want, how should I do it?", "order": 56570, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 20, "scene": 28, "line": "I don't know, just like you're reporting the news or something. Ok?", "order": 56571, "speaker": "Director"},
  {"season": 9, "episode": 20, "scene": 28, "line": "[ridiculous news voice] This video is to demonstrate the HPRDC chemical handling protocols.", "order": 56572, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 20, "scene": 28, "line": "Ok.", "order": 56573, "speaker": "Director"},
  {"season": 9, "episode": 20, "scene": 28, "line": "It's Tom Brokaw, it's a newscaster.", "order": 56574, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 20, "scene": 28, "line": "Who was that?", "order": 56575, "speaker": "Director"},
  {"season": 9, "episode": 20, "scene": 28, "line": "Tom Brokaw!", "order": 56576, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 20, "scene": 29, "line": "Come on Clark!", "order": 56577, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 20, "scene": 29, "line": "Come on Clark. [Clark throws plane, Erin throws plane it goes much farther]", "order": 56578, "speaker": "Angela Martin"},
  {"season": 9, "episode": 20, "scene": 29, "line": "Whoa!", "order": 56579, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 20, "scene": 29, "line": "Yeah! Oh! Eat it piggy! Eat it! Oink oink oink oink!", "order": 56580, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 20, "scene": 29, "line": "We've still gotta work together, so we should keep it civil.", "order": 56581, "speaker": "Clark Green"},
  {"season": 9, "episode": 20, "scene": 29, "line": "[Snorts and grunts] I can't hear what you said. [snorting, grunting] You got your slop? [Squeals]", "order": 56582, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 20, "scene": 29, "line": "You know what, this is completely unnecessary. You already won...", "order": 56583, "speaker": "Clark Green"},
  {"season": 9, "episode": 20, "scene": 29, "line": "Erin! Erin! Just stop.", "order": 56584, "speaker": "Pete Miller"},
  {"season": 9, "episode": 20, "scene": 29, "line": "What?", "order": 56585, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 20, "scene": 29, "line": "Relax.", "order": 56586, "speaker": "Pete Miller"},
  {"season": 9, "episode": 20, "scene": 30, "line": "Next up we have Dwight and Phyllis.", "order": 56587, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 20, "scene": 30, "line": "You can do it baby!", "order": 56588, "speaker": "Bob Vance, Vance Refrigeration"},
  {"season": 9, "episode": 20, "scene": 30, "line": "No you can't baby! [to Dwight, whispers] Good luck.", "order": 56589, "speaker": "Angela Martin"},
  {"season": 9, "episode": 20, "scene": 30, "line": "Watch and learn.", "order": 56590, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 20, "scene": 30, "line": "Wow.", "order": 56591, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 20, "scene": 30, "line": "Oh!", "order": 56592, "speaker": "Group"},
  {"season": 9, "episode": 20, "scene": 30, "line": "Dwight defeats Phyllis, Dwight you are through to the semi-finals.", "order": 56593, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 20, "scene": 30, "line": "Yes!", "order": 56594, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 20, "scene": 30, "line": "Whoo!", "order": 56595, "speaker": "Angela Martin"},
  {"season": 9, "episode": 20, "scene": 30, "line": "Oh, Esther.", "order": 56596, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 20, "scene": 30, "line": "Hey.", "order": 56597, "speaker": "Esther"},
  {"season": 9, "episode": 20, "scene": 30, "line": "What are you- [Esther kisses him] You're here early.", "order": 56598, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 20, "scene": 30, "line": "Yeah, I plucked the chickens extra fast cause I knew I was seeing you tonight. There might just be a little feather in your nuggets or a little bit of meat inside of your pillow.", "order": 56599, "speaker": "Esther"},
  {"season": 9, "episode": 20, "scene": 30, "line": "I like a little feather in my nuggets. [both laugh]", "order": 56600, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 20, "scene": 30, "line": "Alright ladies and gentlemen, we are down to the final four. Dwight, Erin, Angela and god only knows how, but Toby. One of you will walk away with two thousand dollars.", "order": 56601, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 20, "scene": 30, "line": "Yeah!", "order": 56602, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 20, "scene": 31, "line": "Ok here you have just knocked over the beaker, the chemicals splashed in your eye.", "order": 56603, "speaker": "Director"},
  {"season": 9, "episode": 20, "scene": 31, "line": "Which is insanely painful.", "order": 56604, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 20, "scene": 31, "line": "Yeah.", "order": 56605, "speaker": "Director"},
  {"season": 9, "episode": 20, "scene": 31, "line": "And I've already picked a few childhood memories to tap in to, to really express that pain.", "order": 56606, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 20, "scene": 31, "line": "That's great. So what you're gonna do then is come over here to the eye washing station and then just kinda flush out your eyes. You know, get the chemicals out. Alright?", "order": 56607, "speaker": "Director"},
  {"season": 9, "episode": 20, "scene": 31, "line": "So I just lean over this thing and then you'll add the water special effect later?", "order": 56608, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 20, "scene": 31, "line": "What water special effect?", "order": 56609, "speaker": "Director"},
  {"season": 9, "episode": 20, "scene": 31, "line": "Yeah, yeah just hold your lids open with one hand and let the stream bathe your eyeballs.", "order": 56610, "speaker": "Producer"},
  {"season": 9, "episode": 20, "scene": 31, "line": "I'm not comfortable doing my own stunts. I'll get nude if you want me to, I'll go full Lena Dunham but I-", "order": 56611, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 20, "scene": 31, "line": "Dude, we don't need you to go nude, OK? So just do the eyewash, Ok? That's all we're asking.", "order": 56612, "speaker": "Director"},
  {"season": 9, "episode": 20, "scene": 32, "line": "Darryl, what do I do?", "order": 56613, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 20, "scene": 32, "line": "Hold up, I'm looking at my spit with a microscope.", "order": 56614, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 20, "scene": 32, "line": "They want me to use real water in the eye wash scene.", "order": 56615, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 20, "scene": 32, "line": "So?", "order": 56616, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 20, "scene": 32, "line": "I can't squirt stuff in my eyeball. I've never even used an eye dropper.", "order": 56617, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 20, "scene": 32, "line": "So Andy, so you know how to use this. You step on the pedal, water squirts in your eyes.", "order": 56618, "speaker": "Production Assistant"},
  {"season": 9, "episode": 20, "scene": 32, "line": "Carla! Carla!", "order": 56619, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 20, "scene": 33, "line": "It is time for a little T & A. I give you: Toby and Angela.", "order": 56620, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 20, "scene": 33, "line": "[throws plane far] Oh my god! Oh my god. Ok it's your turn. [Toby crumples plane and steps aside.", "order": 56621, "speaker": "Angela Martin"},
  {"season": 9, "episode": 20, "scene": 33, "line": "Well, Angela is the winner.", "order": 56622, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 20, "scene": 33, "line": "Yes!", "order": 56623, "speaker": "Angela Martin"},
  {"season": 9, "episode": 20, "scene": 33, "line": "[to Dwight] Is there a reason that we're excited for that little woman?", "order": 56624, "speaker": "Esther"},
  {"season": 9, "episode": 20, "scene": 33, "line": "Yes. I pity her. She was recently in a situation where she could have had it all, and instead she lost everything.", "order": 56625, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 20, "scene": 33, "line": "Oh. Is she a gambler?", "order": 56626, "speaker": "Esther"},
  {"season": 9, "episode": 20, "scene": 33, "line": "In a way. But not in a stand up and cheer kind of way, like the song.", "order": 56627, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 20, "scene": 33, "line": "Hmm. That is sad.", "order": 56628, "speaker": "Esther"},
  {"season": 9, "episode": 20, "scene": 34, "line": "[grabs Andy by the cheeks] Andy! If you don't stick your eyes in that machine, I'm going to call every production in North Eastern Pennsylvania. You won't even make an appearance on a security camera! [Andy starts to cry]", "order": 56629, "speaker": "Carla Fern"},
  {"season": 9, "episode": 20, "scene": 34, "line": "What's the hold up here?", "order": 56630, "speaker": "Producer"},
  {"season": 9, "episode": 20, "scene": 34, "line": "The actor's crying.", "order": 56631, "speaker": "Director"},
  {"season": 9, "episode": 20, "scene": 34, "line": "Oh god.", "order": 56632, "speaker": "Producer"},
  {"season": 9, "episode": 20, "scene": 35, "line": "She yelled at me. I can't wash my eyeball. I can't do that. I can't.", "order": 56633, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 20, "scene": 35, "line": "Andy Bernard can't squirt water in his eye and act like it doesn't freak him out. But you know who can? Older Male Lab Assistant Number One.", "order": 56634, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 20, "scene": 35, "line": "Do you believe in me?", "order": 56635, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 20, "scene": 35, "line": "I believe I want to go home.", "order": 56636, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 20, "scene": 36, "line": "[throws plane far] Yeah! Ok! Beat that! [Erin throws plane, loses, Dwight laughs] Whoo! Ok.", "order": 56637, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 20, "scene": 36, "line": "Dammit! Dammit, god. [To Pete] Sorry. Yeah I'm fine. I'm fine. I got mad. Cause I don't like losing. I'm just gonna- Sorry I'm mad! I'm mad, I'm really mad. I wanted to win. We were gonna win a lot of money, I was gonna buy you a sweater. It's stupid. Just the whole contest is stupid. That's how it feels.", "order": 56638, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 20, "scene": 36, "line": "Yeah. [Erin kicks box] Whoa!", "order": 56639, "speaker": "Pete Miller"},
  {"season": 9, "episode": 20, "scene": 36, "line": "Sorry I'm mad! I don't like losing! I thought I was going to win!", "order": 56640, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 20, "scene": 36, "line": "Ok [tries to help her] Hey hey hey, whoa.", "order": 56641, "speaker": "Pete Miller"},
  {"season": 9, "episode": 20, "scene": 36, "line": "Sorry. Sorry. I'm going to go upstairs and just...", "order": 56642, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 20, "scene": 37, "line": "Oh, I made us a date to take my mom out to dinner to thank her for all that extra babysitting.", "order": 56643, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 20, "scene": 37, "line": "Well, you know how much I appreciate the opportunity to hang out with your mom more. So let me just put this in my calendar.", "order": 56644, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 20, "scene": 37, "line": "I acknowledge with gratitude that you are being kind and responsible enough to include it in your calendar.", "order": 56645, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 20, "scene": 37, "line": "Thank you. Your mom is a treasure.", "order": 56646, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 20, "scene": 37, "line": "Well, I appreciate that some opportunities can be unpleasant- [Jim's phone rings, he answers]", "order": 56647, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 20, "scene": 37, "line": "Hey that's work, hold on. Hey Wade, did Cole Hamels call back or what? Great. Good.", "order": 56648, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 20, "scene": 37, "line": "Uh, to speak my truth, I'd appreciate if you hung that up cause we were in the middle of a conversation. [Jim hangs up phone] I appreciate the sacrifice.", "order": 56649, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 20, "scene": 37, "line": "Ok to speak my truth, that was a little sarcastic. I think that's a little unfair.", "order": 56650, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 20, "scene": 37, "line": "Really? I've been putting the kids to bed by myself every night for a months. And you had to miss one phone call. Is that your truth, Jim? That's really your truth?", "order": 56651, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 20, "scene": 37, "line": "I guess I will swallow my truth.", "order": 56652, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 20, "scene": 37, "line": "Are you guys high? Because if so, to speak my truth, I would appreciate the sacrifice of including me in some hits off your kind buds.", "order": 56653, "speaker": "Clark Green"},
  {"season": 9, "episode": 20, "scene": 37, "line": "We're not high.", "order": 56654, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 20, "scene": 38, "line": "I wish we'd started this exercise six months ago. My heart just feels so... blocked up.", "order": 56655, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 20, "scene": 39, "line": "The Mark 47 is ready for launch. [throws plane but it sticks to his hand] Less paste.", "order": 56656, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 20, "scene": 40, "line": "Here we go. Rolling and... action!", "order": 56657, "speaker": "Director"},
  {"season": 9, "episode": 20, "scene": 40, "line": "[doing eyewash] AHHH! AHHH! AHHH! AHHH!!", "order": 56658, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 20, "scene": 40, "line": "And cut. We can fix the sound in post.", "order": 56659, "speaker": "Director"},
  {"season": 9, "episode": 20, "scene": 40, "line": "Yeah.", "order": 56660, "speaker": "Producer"},
  {"season": 9, "episode": 20, "scene": 40, "line": "I can do a better one.", "order": 56661, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 20, "scene": 40, "line": "That's fine, we'll move on.", "order": 56662, "speaker": "Producer"},
  {"season": 9, "episode": 20, "scene": 40, "line": "I said I can do a better one. Darryl...", "order": 56663, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 20, "scene": 40, "line": "Action!", "order": 56664, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 20, "scene": 40, "line": "[eyewashes again] AHH! AHH! AHH!", "order": 56665, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 20, "scene": 40, "line": "Kid can act!", "order": 56666, "speaker": "Carla Fern"},
  {"season": 9, "episode": 20, "scene": 40, "line": "Yeah! Yeah.", "order": 56667, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 20, "scene": 41, "line": "Each contestant will throw two aeroplanes.", "order": 56668, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 20, "scene": 41, "line": "After you.", "order": 56669, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 20, "scene": 41, "line": "Thank you. [plane loops up and falls at her feet] Oh god!", "order": 56670, "speaker": "Angela Martin"},
  {"season": 9, "episode": 20, "scene": 41, "line": "Angela's first throw, terrible. Dwight.", "order": 56671, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 20, "scene": 41, "line": "[fake throws and drops plane] Oh! Oh man! It slipped out of my hand, what a whiff. Ah, how'd that happen? God. [Angela smiles]", "order": 56672, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 20, "scene": 41, "line": "We want you to win. Dwight told me about your situation. It's such a pity. Use the money wisely. [Angela looks disgusted]", "order": 56673, "speaker": "Esther"},
  {"season": 9, "episode": 20, "scene": 41, "line": "Alright.", "order": 56674, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 20, "scene": 41, "line": "Don't you dare tank this. [Dwight throws plane far, Angela fake throws and drops her plane]", "order": 56675, "speaker": "Angela Martin"},
  {"season": 9, "episode": 20, "scene": 41, "line": "And we have a winner. And it's Dwight. And it is everyone because this is over.", "order": 56676, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 20, "scene": 41, "line": "Two grand huh? I know a guy who can turn that into eight hundred dollars. And it's me.", "order": 56677, "speaker": "Creed Bratton"},
  {"season": 9, "episode": 20, "scene": 41, "line": "[To Esther] Well, I guess you needed the money more than me huh? Use it wisely.", "order": 56678, "speaker": "Angela Martin"},
  {"season": 9, "episode": 20, "scene": 42, "line": "I was disappointed in Dwight today. He showed a weakness that was unbecoming. Even if he did do it for me. I don't need pity and I don't need charity. I have my dignity and that's enough. And as long as I have that, I'll be ok.", "order": 56679, "speaker": "Angela Martin"},
  {"season": 9, "episode": 20, "scene": 43, "line": "I know this was really weird, and it was really hard. But I think we're making progress. So I'm really sorry that I have to go but let's keep at this. Ok?", "order": 56680, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 20, "scene": 43, "line": "Ok. [intense moment where Jim leaves and Pam seems conflicted. She notices his umbrella and runs out to follow him]", "order": 56681, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 20, "scene": 44, "line": "Jim! [hands him umbrella]", "order": 56682, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 20, "scene": 44, "line": "Thanks.", "order": 56683, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 20, "scene": 44, "line": "Alright, have a good trip.", "order": 56684, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 20, "scene": 44, "line": "Bye.", "order": 56685, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 20, "scene": 44, "line": "Bye. [walks away]", "order": 56686, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 20, "scene": 44, "line": "Hey! [Runs after her and looks into her eyes] I... [hugs her tightly, Pam doesn't hug back]", "order": 56687, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 20, "scene": 45, "line": "[Flashback to Jim & Pam's wedding] Love suffers long and is kind. It is not proud. Love bears all things, believes all things, hopes all things, endures all things. Love never fails. [Pam hugs Jim back finally] And now these three remain: Faith, hope and love. But the greatest of these is love. [Pam kisses Jim]", "order": 56688, "speaker": "Pastor"},
  {"season": 9, "episode": 20, "scene": 46, "line": "I love you.", "order": 56689, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 20, "scene": 46, "line": "I love you.", "order": 56690, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 21, "scene": 1, "line": "I'm taking some time off from work'well, my other work'because we needed it.", "order": 56691, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 21, "scene": 1, "line": "It's great.", "order": 56692, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 21, "scene": 1, "line": "It's great.", "order": 56693, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 21, "scene": 1, "line": "The phone has been ringing off the hook. The guys in Philly are kind of going nuts.", "order": 56694, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 21, "scene": 1, "line": "But that doesn't matter. This does. It's the only thing that matters. We've had some really nice days together.", "order": 56695, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 21, "scene": 1, "line": "Nice morning, too.", "order": 56696, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 21, "scene": 1, "line": "Beesly! Oh, my god.", "order": 56697, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 21, "scene": 2, "line": "Hey. Which tie makes me look like a guy who likes sofas? My agent's putting me up for a furniture commercial.", "order": 56698, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 21, "scene": 2, "line": "Ah, definitely blue.", "order": 56699, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 21, "scene": 2, "line": "Totally, right? So, Big Tuna, what's up? Back in the small pond?", "order": 56700, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 21, "scene": 2, "line": "For now, yeah. I was spreading myself way too thin'", "order": 56701, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 21, "scene": 2, "line": "Thin-sliced tuna. Carpaccio. Go on.", "order": 56702, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 21, "scene": 2, "line": "Uh, well, it took me a while, but I finally realized that I can't give 100% to two things at once you know.", "order": 56703, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 21, "scene": 2, "line": "Tell me about it, you know? I've been trying to act and manage this branch. Half the time I don't know if I'm wearing my stage makeup or my work makeup.", "order": 56704, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 21, "scene": 2, "line": "Huh. Well, you know, you can't have everything so you gotta ask yourself what makes you the happiest. You just go all in for what's most important. That's my new thing.", "order": 56705, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 21, "scene": 3, "line": "[noticing Angela looking very unkempt] Is everything ok?", "order": 56706, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 21, "scene": 3, "line": "No. Everything is not okay. The county took my cats.", "order": 56707, "speaker": "Angela Martin"},
  {"season": 9, "episode": 21, "scene": 3, "line": "Wait, all of them?", "order": 56708, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 21, "scene": 3, "line": "Two sacks' worth. Apparently my apartment complex has rules about how many pets are too many for a studio. And while I was out picking Phillip up from daycare, they came. They came into my house.", "order": 56709, "speaker": "Angela Martin"},
  {"season": 9, "episode": 21, "scene": 3, "line": "That's'that's awful, Angela. I'm so sorry.", "order": 56710, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 21, "scene": 3, "line": "It's the [bleep] that lives downstairs. She's this uptight, judgmental shrew. You know the type.", "order": 56711, "speaker": "Angela Martin"},
  {"season": 9, "episode": 21, "scene": 3, "line": "I've never met anyone like that.", "order": 56712, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 21, "scene": 3, "line": "And they're gone. And I have no one left. Without my cats, I am utterly and completely alone.", "order": 56713, "speaker": "Angela Martin"},
  {"season": 9, "episode": 21, "scene": 3, "line": "Angela, you still have your son.", "order": 56714, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 21, "scene": 3, "line": "I guess.", "order": 56715, "speaker": "Angela Martin"},
  {"season": 9, "episode": 21, "scene": 4, "line": "Attention, everyone. May I have your attention? There are four new deadly weapons in this office. [kicking and punching] Basher, Thrasher, Crasher and'", "order": 56716, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 21, "scene": 4, "line": "Smasher!", "order": 56717, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 21, "scene": 4, "line": "Smasher? No, where'd you get that? Fireball. This morning after hours of combat with some of the city's best teenagers I earned my black belt in Goju Ryu martial arts.", "order": 56718, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 21, "scene": 5, "line": "I had to find a new dojo after sensei Ira and I parted ways. My new sensei, sensei Billy, thought I had more than enough training to take the test. Turns out, sensei Ira was a bit of a shyster. Sensei Billy says most students don't spend $150,000 over 20 years to get their black belt.", "order": 56719, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 21, "scene": 6, "line": "I would like to invite you all to my black belt ceremony, right here in the office at lunch, lunch not provided.", "order": 56720, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 21, "scene": 6, "line": "Fireball!", "order": 56721, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 21, "scene": 6, "line": "Ah! [throws punch] That's how it's done.", "order": 56722, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 21, "scene": 6, "line": "That's pretty good. I feel safe.", "order": 56723, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 21, "scene": 7, "line": "Hey, Erin. Is Andy in?", "order": 56724, "speaker": "David Wallace"},
  {"season": 9, "episode": 21, "scene": 7, "line": "Oh, is Andy in? Sorry, I thought you said 'is Indian' and was like, 'Is Indian what?' Is Indian food good? Is Indian jewelry pretty? Is Indian hair an expensive kind of wig? Yes, to all three, by the way.", "order": 56725, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 21, "scene": 8, "line": "Lately, I've been having a lot of trouble keeping track of Andy's calendar. His student film audition schedule is crazy hectic.", "order": 56726, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 21, "scene": 9, "line": "Yes, there's Andy! Andy is in. I'm a good receptionist, I know he's in.", "order": 56727, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 21, "scene": 9, "line": "David Walrus, in his native habitat.", "order": 56728, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 21, "scene": 9, "line": "Hey, Andy. Can we go in your office and talk?", "order": 56729, "speaker": "David Wallace"},
  {"season": 9, "episode": 21, "scene": 9, "line": "Yeah.", "order": 56730, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 21, "scene": 10, "line": "This isn't going to be an easy conversation. I told Andy that he was on very thin ice when he snuck away from three months to the Caribbean. Then last week he used company money to buy a top-of-the-line photo printer. In his words, 'The kind that's good for head shots.' And yesterday, he asked me to pay for cheek implants. Claimed it's gonna boost office morale. Now, he's a good guy. But honestly, at some point, the ice gets too thin and you fall through. And that is when you get fired.", "order": 56731, "speaker": "David Wallace"},
  {"season": 9, "episode": 21, "scene": 11, "line": "Andy'", "order": 56732, "speaker": "David Wallace"},
  {"season": 9, "episode": 21, "scene": 11, "line": "I'm gonna stop you right there. David, this documentary is going to air in two weeks. I feel like it's a once-in-a-lifetime opportunity to pursue my dream.", "order": 56733, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 21, "scene": 11, "line": "Uh-huh, but'", "order": 56734, "speaker": "David Wallace"},
  {"season": 9, "episode": 21, "scene": 11, "line": "Every minute that I spend here is time not spent making audition videos for reality dating, cooking or singing shows. I got a real shot here. And I'll never be able to forgive myself if I blew it because I was too focused on my stupid paper company job. No offense.", "order": 56735, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 21, "scene": 11, "line": "So you think you've been too focused on your job?", "order": 56736, "speaker": "David Wallace"},
  {"season": 9, "episode": 21, "scene": 11, "line": "At my last head shot sitting, I was so distracted wondering what I was missing at work that I came across totally manic. And I was going for zany.", "order": 56737, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 21, "scene": 11, "line": "So you'you want to quit Dunder Mifflin to pursue acting?", "order": 56738, "speaker": "David Wallace"},
  {"season": 9, "episode": 21, "scene": 11, "line": "Well, no, actually. I see no reason to limit myself to just acting. I am pursuing fame of any kind. Could be singing, could be dancing. I don't'it just' I owe it to myself and my future fans.", "order": 56739, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 21, "scene": 11, "line": "Uh, well, I guess I can't stand in the way of a man's dream. And it seems like you have the gift.", "order": 56740, "speaker": "David Wallace"},
  {"season": 9, "episode": 21, "scene": 11, "line": "Thank you, David.", "order": 56741, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 21, "scene": 11, "line": "There's probably no way I can talk you into staying at this point, can I?", "order": 56742, "speaker": "David Wallace"},
  {"season": 9, "episode": 21, "scene": 11, "line": "Nope. I have made up my mind. I'm really sorry.", "order": 56743, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 21, "scene": 11, "line": "Well, good luck.", "order": 56744, "speaker": "David Wallace"},
  {"season": 9, "episode": 21, "scene": 11, "line": "Thank you. Not gonna need it.", "order": 56745, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 21, "scene": 11, "line": "Okay.", "order": 56746, "speaker": "David Wallace"},
  {"season": 9, "episode": 21, "scene": 12, "line": "Well that kind of worked out.", "order": 56747, "speaker": "David Wallace"},
  {"season": 9, "episode": 21, "scene": 13, "line": "I think just anybody could be a star. My postman, the night janitor here, but Andy? No, definitely not. Charisma black hole.", "order": 56748, "speaker": "Creed Bratton"},
  {"season": 9, "episode": 21, "scene": 14, "line": "Oh, Clark. I'm actually here today. Surprise! So I was wondering if I could maybe have my desk back.", "order": 56749, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 21, "scene": 14, "line": "Right. Yeah. But, you know, I've actually been working pretty hard here on a daily basis. So, I kind of feel like I've earned this. I mean, you know?", "order": 56750, "speaker": "Clark Green"},
  {"season": 9, "episode": 21, "scene": 14, "line": "You totally have. You have earned this. But maybe I could be with my wife. Kind of the whole reason that I'm here.", "order": 56751, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 21, "scene": 14, "line": "Right. Well, I'm here to sell paper.", "order": 56752, "speaker": "Clark Green"},
  {"season": 9, "episode": 21, "scene": 14, "line": "Wow.", "order": 56753, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 21, "scene": 14, "line": "All right, Jim, look, I just got made junior salesman. Right? And'and Wallace is here today. And I don't him to just think of me as a customer service rep that sits in the annex. I mean, you can get that, right? Right? And what do you need more face time with Wallace for? You trying to get a second second job here?", "order": 56754, "speaker": "Clark Green"},
  {"season": 9, "episode": 21, "scene": 14, "line": "it's okay. You can sit in the annex.", "order": 56755, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 21, "scene": 14, "line": "What?", "order": 56756, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 21, "scene": 14, "line": "I'll come visit you.", "order": 56757, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 21, "scene": 14, "line": "Okay. The annex it is. I'll be sitting at your desk if that's okay with you.", "order": 56758, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 21, "scene": 14, "line": "That's fine with me. But be careful, it is very easy to get lost in Pete's beautiful, dead eyes.", "order": 56759, "speaker": "Clark Green"},
  {"season": 9, "episode": 21, "scene": 14, "line": "Everyone, a little breaking news for ya. Just had a little chat with David Wallace. And, um, I'm taking a leave of absence from Dunder Mifflin, forever.", "order": 56760, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 21, "scene": 14, "line": "I can't say we didn't see it coming. But it's a sad day when anybody is fired. We're so sorry, Andy.", "order": 56761, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 21, "scene": 14, "line": "Uh, uh, uh, uh, Andy was not fired.", "order": 56762, "speaker": "David Wallace"},
  {"season": 9, "episode": 21, "scene": 14, "line": "I wasn't fired. What are you talking about? I'm fired up, yes. Guys, I'm'I'm leaving to pursue my lifelong dream of being famous.", "order": 56763, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 21, "scene": 14, "line": "Oh, Andy.", "order": 56764, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 21, "scene": 14, "line": "Yeah, so, I'll see you on the red carpet. See, that's how it works.", "order": 56765, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 21, "scene": 15, "line": "Andy sings beautifully. And he's really good at dancing. He's a good speaker. But there's just something there you don't want to look at.", "order": 56766, "speaker": "Phyllis Vance"},
  {"season": 9, "episode": 21, "scene": 16, "line": "Hey, how am I doing as your desk mate, by the way? You probably miss Clark.", "order": 56767, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 21, "scene": 16, "line": "Yeah, a little bit.", "order": 56768, "speaker": "Pete Miller"},
  {"season": 9, "episode": 21, "scene": 16, "line": "Oh, wow. But, um'Oh, I get that.", "order": 56769, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 21, "scene": 16, "line": "Oh, no, no, no. No it's cool.", "order": 56770, "speaker": "Pete Miller"},
  {"season": 9, "episode": 21, "scene": 16, "line": "I get it.", "order": 56771, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 21, "scene": 16, "line": "It's cool, man. I'm sure you and I will have our own thing.", "order": 56772, "speaker": "Pete Miller"},
  {"season": 9, "episode": 21, "scene": 16, "line": "Yeah. Definitely. Go Phillies, right. You don't watch baseball. I keep forgetting that.", "order": 56773, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 21, "scene": 17, "line": "I just think you're going into this a little fast.", "order": 56774, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 21, "scene": 17, "line": "I'm 38, Darryl, how much slower should I go?", "order": 56775, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 21, "scene": 17, "line": "Show business is cold. Let's say you get a job, which you probably won't. They're not gonna cut you any slack. You're meant for a job with lots and lots of slack.", "order": 56776, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 21, "scene": 17, "line": "All right. I get it.", "order": 56777, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 21, "scene": 18, "line": "The male is a funny species. We don't just tell each other how we feel, that's chick stuff. So instead of saying, 'Hey, Andy, I love you, man. I don't want you to leave.' You say something like, 'Hey, Andy, you're making the worst mistake of your life. You're not talented.' Well' right back at you, Darryl.", "order": 56778, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 21, "scene": 19, "line": "[hugs Darryl] I'm gonna miss you too. Mmm!", "order": 56779, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 21, "scene": 20, "line": "Andy's from the generation that thinks they should all be famous. What happened to the generation that knew you shut up, did your work, and died quietly from a heart attack?", "order": 56780, "speaker": "Stanley Hudson"},
  {"season": 9, "episode": 21, "scene": 21, "line": "Could Andy make it as an entertainer? I don't know. You know who's really funny? This bird, in the park, that can't fly right. I'd pay to see him. But I don't have to cause the park is free!", "order": 56781, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 21, "scene": 22, "line": "I will now perform the ceremonial changing of the belts.", "order": 56782, "speaker": "Sensei"},
  {"season": 9, "episode": 21, "scene": 22, "line": "He will now perform the ceremonial changing of the belts!", "order": 56783, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 21, "scene": 22, "line": "It's not a large room, I think they heard me.", "order": 56784, "speaker": "Sensei"},
  {"season": 9, "episode": 21, "scene": 22, "line": "Take my belt, master. I now submit to you every part of myself. [thrusts hips at Ira]", "order": 56785, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 21, "scene": 22, "line": "That's really ok. I'm mostly focused on the belt here.", "order": 56786, "speaker": "Sensei"},
  {"season": 9, "episode": 21, "scene": 22, "line": "Just slip it off my'Slip it off my hips.", "order": 56787, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 21, "scene": 22, "line": "Hold it'Take a step back. Take a step back. [they bow, then Dwight thrusts again] Okay, okay. I can't'I can't do this if you're gonna be thrusting like that, okay? I think we're gonna have to cut this off.", "order": 56788, "speaker": "Sensei"},
  {"season": 9, "episode": 21, "scene": 22, "line": "He will now perform the ceremonial cutting-off-of-the-belt.", "order": 56789, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 21, "scene": 23, "line": "Dwight has been practicing karate for years. When we were dating, I would help him with his strength training. He would strap me to his chest in a baby Bjorn made for fat children and do lunges across the farm. It felt like I was flying.", "order": 56790, "speaker": "Angela Martin"},
  {"season": 9, "episode": 21, "scene": 24, "line": "There it is. [everyone applauds]", "order": 56791, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 21, "scene": 24, "line": "Congratulations.", "order": 56792, "speaker": "Sensei"},
  {"season": 9, "episode": 21, "scene": 24, "line": "We did it, we did it. I love you.", "order": 56793, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 21, "scene": 24, "line": "Okay.", "order": 56794, "speaker": "Sensei"},
  {"season": 9, "episode": 21, "scene": 24, "line": "Thanks. I will now perform my final kata forms. You're gonna watch me right?", "order": 56795, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 21, "scene": 24, "line": "Yes, I will. [Dwight starts doing karate]", "order": 56796, "speaker": "Sensei"},
  {"season": 9, "episode": 21, "scene": 24, "line": "Sensei, you're not watching.", "order": 56797, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 21, "scene": 24, "line": "Yeah, I'm watching. Just do it.", "order": 56798, "speaker": "Sensei"},
  {"season": 9, "episode": 21, "scene": 24, "line": "Watching?", "order": 56799, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 21, "scene": 24, "line": "I'm watching.", "order": 56800, "speaker": "Sensei"},
  {"season": 9, "episode": 21, "scene": 24, "line": "Watch this part, okay?", "order": 56801, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 21, "scene": 24, "line": "Sensei, do you generally do house calls like this?", "order": 56802, "speaker": "David Wallace"},
  {"season": 9, "episode": 21, "scene": 24, "line": "Uh, you can just call me Billy. And no. No, but Dwight insisted. He wanted to receive his black belt in the place he loves most in the world.", "order": 56803, "speaker": "Sensei"},
  {"season": 9, "episode": 21, "scene": 24, "line": "He said that? He's an odd guy, isn't he?", "order": 56804, "speaker": "David Wallace"},
  {"season": 9, "episode": 21, "scene": 24, "line": "Yes. Irritating, also yes. But I gotta hand it to him, he's one of the most tenacious and determined men I've ever met. [Dwight finishes his routines and everyone applauds]", "order": 56805, "speaker": "Sensei"},
  {"season": 9, "episode": 21, "scene": 24, "line": "Oh! I'm am so proud of you, Schru-berry blue.", "order": 56806, "speaker": "Esther"},
  {"season": 9, "episode": 21, "scene": 25, "line": "I really felt like I almost lost her, and'and nothing is worth that.", "order": 56807, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 21, "scene": 25, "line": "Well, I gotta tell you, Jim, a lot of guys in my circle? They wouldn't even change their golf schedule to shore up their marriage let alone their professional goals.", "order": 56808, "speaker": "David Wallace"},
  {"season": 9, "episode": 21, "scene": 26, "line": "Dude, there is no way that Jim is just back here to hang out with Pam.", "order": 56809, "speaker": "Clark Green"},
  {"season": 9, "episode": 21, "scene": 26, "line": "You did not just say that! You don't know Pam. She is really cool.", "order": 56810, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 21, "scene": 26, "line": "All I'm saying is, forget about my chair. He wants the manager's chair. And I thought you wanted that job.", "order": 56811, "speaker": "Clark Green"},
  {"season": 9, "episode": 21, "scene": 26, "line": "Yeah, I did. But I made too many mistakes. It's out of my reach now. Besides, I think Jim would be a fine manager. I'd be happy to see Jim as manager.", "order": 56812, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 21, "scene": 27, "line": "So, the reason that I called you in here is because Andy is moving on.", "order": 56813, "speaker": "David Wallace"},
  {"season": 9, "episode": 21, "scene": 27, "line": "Again.", "order": 56814, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 21, "scene": 27, "line": "Again! And I'm looking for a new manager. And with his performance this year, I have been considering Dwight. Am I crazy?", "order": 56815, "speaker": "David Wallace"},
  {"season": 9, "episode": 21, "scene": 27, "line": "Not at all. It should be Dwight.", "order": 56816, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 21, "scene": 27, "line": "You sure?", "order": 56817, "speaker": "David Wallace"},
  {"season": 9, "episode": 21, "scene": 27, "line": "You're gonna want to invest in a lot more liability insurance, but, uh'", "order": 56818, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 21, "scene": 27, "line": "Yeah.", "order": 56819, "speaker": "David Wallace"},
  {"season": 9, "episode": 21, "scene": 27, "line": "Hey, if there is someone out there who loves paper more than Dwight, I definitely don't want to meet that person.", "order": 56820, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 21, "scene": 28, "line": "Andy, we just wondered if we could have a word.", "order": 56821, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 21, "scene": 28, "line": "It's now or never.", "order": 56822, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 21, "scene": 28, "line": "Well, we just had a quick question about this decision of yours. You know, to leave a stable job and pursue a career in the entertainment business. In your late 30s. With no savings to fall back on. And no real connections in that business, which can be competitive.", "order": 56823, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 21, "scene": 28, "line": "Yeah, sure. What's your question?", "order": 56824, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 21, "scene": 28, "line": "Our question is'it seems dumb.", "order": 56825, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 21, "scene": 28, "line": "Well, it's better than sticking around here and half-assing it, right?", "order": 56826, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 21, "scene": 28, "line": "Of course. But what if you were to stay here, you know, and 'full-ass' it? Um, really give it a go. Be the greatest manager in the history of this branch and in that way achieve the fame and immortality that you seek. Hmm?", "order": 56827, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 21, "scene": 28, "line": "Nah. I like my plan better.", "order": 56828, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 21, "scene": 28, "line": "Well, Andy, your plan sucks, okay? Nobody is going to hire you ever. You're too character-y to be a lead and you're not fat enough to be a great character actor.", "order": 56829, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 21, "scene": 28, "line": "What?", "order": 56830, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 21, "scene": 29, "line": "No, I don't think that he can make it as an actor. But, he also can't make it as an employee in an office, so why not go nuts with it?", "order": 56831, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 21, "scene": 30, "line": "Hey, are you still in charge of office supplies?", "order": 56832, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 21, "scene": 30, "line": "Yes. Yeah.", "order": 56833, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 21, "scene": 30, "line": "I seem to have sticky not emergency where I grab it on the wrong end, and this happens.", "order": 56834, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 21, "scene": 30, "line": "Oh, boy. Um'", "order": 56835, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 21, "scene": 30, "line": "If you could help me out, that would be'", "order": 56836, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 21, "scene": 30, "line": "I could give you some beginner stickies?", "order": 56837, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 21, "scene": 30, "line": "Anything would help.", "order": 56838, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 21, "scene": 30, "line": "Here you go.", "order": 56839, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 21, "scene": 30, "line": "Oh, also, while you're at it, if you did have a salt packet, three tacks and some aspirin, that would be great. Oh, wow. You have that.", "order": 56840, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 21, "scene": 30, "line": "Mm-hmm.", "order": 56841, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 21, "scene": 30, "line": "Wow, that's'", "order": 56842, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 21, "scene": 30, "line": "It's all yours.", "order": 56843, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 21, "scene": 30, "line": "You come so prepared. Aspirin.", "order": 56844, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 21, "scene": 30, "line": "You wanna get rid of a headache, you sit on something sharp. Any sensei will tell you that.", "order": 56845, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 21, "scene": 30, "line": "Hey, congratulations on that black belt, man. It's really great.", "order": 56846, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 21, "scene": 30, "line": "Thank you. So I saw you talking to Wallace earlier. Is he going to offer you the manager's job?", "order": 56847, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 21, "scene": 30, "line": "No. He was maybe thinking of you for it.", "order": 56848, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 21, "scene": 30, "line": "Yeah, right. I'm afraid that ship has sailed.", "order": 56849, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 21, "scene": 30, "line": "I wouldn't be too sure about that. Just saying.", "order": 56850, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 21, "scene": 31, "line": "Hello.", "order": 56851, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 21, "scene": 31, "line": "Well, hello.", "order": 56852, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 21, "scene": 31, "line": "Big day for you.", "order": 56853, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 21, "scene": 31, "line": "Big day for you.", "order": 56854, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 21, "scene": 31, "line": "Thank you.", "order": 56855, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 21, "scene": 31, "line": "Love the belt.", "order": 56856, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 21, "scene": 31, "line": "Oh, yes.", "order": 56857, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 21, "scene": 31, "line": "You know, I don't know anything about karate but I have broken a few boards in my day. Diving boards, at my family pool in Redding. I was an obese child. I never talk about that here, but Nard-dog's outta here, so letting it all hang out!", "order": 56858, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 21, "scene": 31, "line": "This is exciting! You're finally gonna get to go out and flap your wings.", "order": 56859, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 21, "scene": 31, "line": "Thank you, I appreciate that. 'Cause a lot of people are saying I might not make it.", "order": 56860, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 21, "scene": 31, "line": "Oh, I doubt you'll make it. Very few do who've tried to be a star. But, listen, you've saved up enough money to take a couple of years off to pursue your dream and have some fun, right?", "order": 56861, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 21, "scene": 31, "line": "No, I just applied for more overdraft protection.", "order": 56862, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 21, "scene": 31, "line": "Andy, I have nothing to gain from getting you to stay, and everything to gain from you leaving. But please, I have known you for years, I have seen you perform. Dear god, don't quit your day job.", "order": 56863, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 21, "scene": 31, "line": "[cockney accent] Nothing is impossible to him who will try. [normal] Alexander the Great, if he were cockney.", "order": 56864, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 21, "scene": 31, "line": "You're bad.", "order": 56865, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 21, "scene": 32, "line": "I'm gonna make it. Every person that has been on Conan has a crazy story about how they made it. Every person.", "order": 56866, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 21, "scene": 33, "line": "Erin. Honest Erin, cannot tell a lie. We lay together. That's something you can't take back.", "order": 56867, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 21, "scene": 33, "line": "So true. Is there a question, or are'what?", "order": 56868, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 21, "scene": 33, "line": "Will you tell me bluntly, do you think I am making a terrible mistake quitting my job to become an actor?", "order": 56869, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 21, "scene": 33, "line": "Bluntly? Yes. Huge mistake. Andy, honestly, I think you might become homeless. Or maybe even starve.", "order": 56870, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 21, "scene": 33, "line": "Thank you. [steps into conference room]", "order": 56871, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 21, "scene": 33, "line": "You can stay on as a salesman, Andy.", "order": 56872, "speaker": "David Wallace"},
  {"season": 9, "episode": 21, "scene": 33, "line": "Thank you. [leaves]", "order": 56873, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 21, "scene": 33, "line": "Dwight, could you come in here for a second, please?", "order": 56874, "speaker": "David Wallace"},
  {"season": 9, "episode": 21, "scene": 34, "line": "Say it again.", "order": 56875, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 21, "scene": 34, "line": "Will you be the new manager?", "order": 56876, "speaker": "David Wallace"},
  {"season": 9, "episode": 21, "scene": 34, "line": "Where?", "order": 56877, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 21, "scene": 34, "line": "Where?", "order": 56878, "speaker": "David Wallace"},
  {"season": 9, "episode": 21, "scene": 34, "line": "What branch?", "order": 56879, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 21, "scene": 34, "line": "Here. Scranton. [Dwight falls to his knees] Come on. Come on, Dwight. Get up. Let's go. It's good news.", "order": 56880, "speaker": "David Wallace"},
  {"season": 9, "episode": 21, "scene": 34, "line": "I'm sorry. I've just waited for this moment my entire life. I mean, I was interim manager once, but then I shot that gun.", "order": 56881, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 21, "scene": 34, "line": "What?", "order": 56882, "speaker": "David Wallace"},
  {"season": 9, "episode": 21, "scene": 34, "line": "But this isn't interim manager. No. It's Dwight K. Schrute' [pulls a business card out of his wallet] Manager.", "order": 56883, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 21, "scene": 34, "line": "Why do you already have this?", "order": 56884, "speaker": "David Wallace"},
  {"season": 9, "episode": 21, "scene": 34, "line": "In case Michael or Andy ever got killed in a traffic accident and there was a trade show on the same day. You will not regret this decision, David.", "order": 56885, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 21, "scene": 34, "line": "I know.", "order": 56886, "speaker": "David Wallace"},
  {"season": 9, "episode": 21, "scene": 34, "line": "I will never, ever let you down.", "order": 56887, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 21, "scene": 34, "line": "I know, Dwight. I know. [reaches out to shake Dwight's hand, Dwight hugs him] Okay. Okay. All righty. You're gonna do great.", "order": 56888, "speaker": "David Wallace"},
  {"season": 9, "episode": 21, "scene": 35, "line": "Hey.", "order": 56889, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 21, "scene": 35, "line": "Wow, hey.", "order": 56890, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 21, "scene": 35, "line": "Hi.", "order": 56891, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 21, "scene": 35, "line": "What's up?", "order": 56892, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 21, "scene": 35, "line": "Um, I have a question.", "order": 56893, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 21, "scene": 35, "line": "Okay.", "order": 56894, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 21, "scene": 35, "line": "Oh, I had a question.", "order": 56895, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 21, "scene": 35, "line": "Really?", "order": 56896, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 21, "scene": 35, "line": "I did!", "order": 56897, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 21, "scene": 35, "line": "Yeah, totally you did.", "order": 56898, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 21, "scene": 35, "line": "Super important.", "order": 56899, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 21, "scene": 35, "line": "I need you to stay right here while you think about it.", "order": 56900, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 21, "scene": 35, "line": "Okay.", "order": 56901, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 21, "scene": 35, "line": "All right? I'm gonna wait.", "order": 56902, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 21, "scene": 35, "line": "All right. I did not come back here just to see you.", "order": 56903, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 21, "scene": 35, "line": "I'm sure you did not. What was your question?", "order": 56904, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 21, "scene": 35, "line": "I don't know, but it might take me a long time to figure it out.", "order": 56905, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 21, "scene": 35, "line": "Well, then, I should figure out things to do while I'm waiting.", "order": 56906, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 21, "scene": 35, "line": "Exactly.", "order": 56907, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 21, "scene": 36, "line": "Hey, everybody, I changed my mind. Not leaving. I'm gonna stay on in sales.", "order": 56908, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 21, "scene": 36, "line": "Oh, thank god. Because sales could be your best role yet.", "order": 56909, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 21, "scene": 36, "line": "Hey, good choice, man. Seriously. Don't want to see you in a porn next year.", "order": 56910, "speaker": "Meredith Palmer"},
  {"season": 9, "episode": 21, "scene": 36, "line": "Okay.", "order": 56911, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 21, "scene": 36, "line": "Ooh, there's a great play about a salesman.", "order": 56912, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 21, "scene": 36, "line": "Death of a salesman.", "order": 56913, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 21, "scene": 36, "line": "I don't think so.", "order": 56914, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 21, "scene": 36, "line": "Sure, 'Death of a Salesman' by Arthur Miller, it's a great play about crushed dreams.", "order": 56915, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 21, "scene": 36, "line": "No, this one was written by Spongebob Squarepants.", "order": 56916, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 21, "scene": 36, "line": "[sits at Dwight's desk] Got any hot leads?", "order": 56917, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 21, "scene": 37, "line": "See, so you just push from under, and turn it around, and boom. No the staple crimps outward.", "order": 56918, "speaker": "Pete Miller"},
  {"season": 9, "episode": 21, "scene": 37, "line": "I had no idea. And here I've been stapling the same way for 20 years like a frickin' sheep.", "order": 56919, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 21, "scene": 37, "line": "Look who's back.", "order": 56920, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 21, "scene": 37, "line": "I'm back. Oh, hey, look, and now it's like a double date.", "order": 56921, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 21, "scene": 37, "line": "Wow. Cause, uh [gestures to him and Erin and then to Pam and Jim with sound effects]", "order": 56922, "speaker": "Pete Miller"},
  {"season": 9, "episode": 21, "scene": 37, "line": "Actually, maybe we should go on a double date some time. That'd be fun.", "order": 56923, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 21, "scene": 37, "line": "Yeah, we should do that for real sometime.", "order": 56924, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 21, "scene": 37, "line": "Well, how about Thursday?", "order": 56925, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 21, "scene": 37, "line": "Oh, well, Thursday's tough, because of'", "order": 56926, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 21, "scene": 37, "line": "Weeknights are actually tough just because'", "order": 56927, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 21, "scene": 37, "line": "They are.", "order": 56928, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 21, "scene": 37, "line": "That's true, yeah.", "order": 56929, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 21, "scene": 37, "line": "Just forget it. Forget I said anything.", "order": 56930, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 21, "scene": 38, "line": "Attention, everyone, just a quick announcement. Little reconfiguration to the staff. Dwight Schrute'", "order": 56931, "speaker": "David Wallace"},
  {"season": 9, "episode": 21, "scene": 38, "line": "David. Can I just do one thing while you're making this announcement and then I'll never, ever do it again?", "order": 56932, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 21, "scene": 38, "line": "I don't think so.", "order": 56933, "speaker": "David Wallace"},
  {"season": 9, "episode": 21, "scene": 38, "line": "It's just one thing. Just let me'let me do this'", "order": 56934, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 21, "scene": 38, "line": "Dwight, Dwight, Dwight. Come on'what I was about to say was Dwight'[phone buzzes] Oh, I'm sorry, I gotta'This'll be a second, sorry.", "order": 56935, "speaker": "David Wallace"},
  {"season": 9, "episode": 21, "scene": 38, "line": "Just wait and send it to voicemail.", "order": 56936, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 21, "scene": 38, "line": "Yeah.", "order": 56937, "speaker": "David Wallace"},
  {"season": 9, "episode": 21, "scene": 38, "line": "Come on. Come on.", "order": 56938, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 21, "scene": 38, "line": "[on phone] Then we'll get him a new set of drums.", "order": 56939, "speaker": "David Wallace"},
  {"season": 9, "episode": 21, "scene": 38, "line": "[climbs up on desk] Dwight Schrute is manager! [everyone cheers and applauds]", "order": 56940, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 21, "scene": 38, "line": "Brava, brava.", "order": 56941, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 21, "scene": 38, "line": "[from atop his desk] Creed Bratton is the new manager! [nobody responds]", "order": 56942, "speaker": "Creed Bratton"},
  {"season": 9, "episode": 21, "scene": 38, "line": "What's going on?", "order": 56943, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 21, "scene": 38, "line": "Dwight's the new manager. He freaking did it.", "order": 56944, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 21, "scene": 38, "line": "[hugs Dwight] Congratulations, Dwight.", "order": 56945, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 21, "scene": 38, "line": "Pam.", "order": 56946, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 21, "scene": 38, "line": "[hugs Dwight] Congratulations, buddy.", "order": 56947, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 21, "scene": 38, "line": "Thank you, Jim.", "order": 56948, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 21, "scene": 38, "line": "Congrats, Dwight.", "order": 56949, "speaker": "Clark Green"},
  {"season": 9, "episode": 21, "scene": 38, "line": "Get out of Jim's seat.", "order": 56950, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 21, "scene": 38, "line": "But I fought for this seat.", "order": 56951, "speaker": "Clark Green"},
  {"season": 9, "episode": 21, "scene": 38, "line": "You're an annex kid. You might be bullpen, we'll see. Give it a couple of years. Scram.", "order": 56952, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 21, "scene": 38, "line": "It's nice to have you back.", "order": 56953, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 21, "scene": 38, "line": "So'", "order": 56954, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 21, "scene": 38, "line": "So.", "order": 56955, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 21, "scene": 38, "line": "I wanted to offer you a new position.", "order": 56956, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 21, "scene": 38, "line": "Let's hear it.", "order": 56957, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 21, "scene": 38, "line": "Assistant regional manager.", "order": 56958, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 21, "scene": 38, "line": "Nope. Can't accept that job. It's not a real job.", "order": 56959, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 21, "scene": 38, "line": "Jim.", "order": 56960, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 21, "scene": 38, "line": "I'll tell you what I could accept is assistant to the regional manager. That is a real job and one I'd be proud to take.", "order": 56961, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 21, "scene": 38, "line": "Shake on it? Done. Way to negotiate, idiot.", "order": 56962, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 21, "scene": 39, "line": "Don't get me wrong, I am definitely here for Pam. But this is an awesome added bonus.", "order": 56963, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 21, "scene": 40, "line": "So, all the numbers adding up?", "order": 56964, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 21, "scene": 40, "line": "Hey, I didn't get a chance to say it, but' congratulations, Dwight.", "order": 56965, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 21, "scene": 40, "line": "Thank you.", "order": 56966, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 21, "scene": 40, "line": "Yes. Congratulations.", "order": 56967, "speaker": "Angela Martin"},
  {"season": 9, "episode": 21, "scene": 40, "line": "Yeah, and Dwight, I'd like to be the first to say congratulations.", "order": 56968, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 21, "scene": 40, "line": "This is a big day for you.", "order": 56969, "speaker": "Angela Martin"},
  {"season": 9, "episode": 21, "scene": 40, "line": "Yes, it is. Carry on.", "order": 56970, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 21, "scene": 41, "line": "How did I just abandon my dreams so quickly? It's cause I had a fallback. That's the problem. When you have fallbacks, it's just easy to give up. When Cortez landed in Mexico, only way he got his men to defeat the Aztecs was by burning all of his own boats. So they could never return home. Huge dick move but very effective. I need to be that same kind of dick to myself.", "order": 56971, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 21, "scene": 42, "line": "Everyone! Changed my mind again.", "order": 56972, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 21, "scene": 42, "line": "What's it now, dream or no dream?", "order": 56973, "speaker": "Phyllis Vance"},
  {"season": 9, "episode": 21, "scene": 42, "line": "Uh, dream. Goin' with my dream. Gotta go all in, isn't that right, Jim?", "order": 56974, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 21, "scene": 42, "line": "Oh, don't look at me cause I think you're making a terrible choice.", "order": 56975, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 21, "scene": 42, "line": "All in! Whoo!", "order": 56976, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 21, "scene": 43, "line": "Toby! Hey, I changed my mind again. I am gonna leave Dunder Mifflin to pursue acting after all.", "order": 56977, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 21, "scene": 43, "line": "Okay, then, Andy.", "order": 56978, "speaker": "Toby Flenderson"},
  {"season": 9, "episode": 21, "scene": 43, "line": "Yeah, but here's the thing. I can't have good old Dunder Mifflin to fall back on or else I'll never succeed. Gotta burn those boats! So I need you to go into my file and put down that I was fired for theft and/or groping wieners.", "order": 56979, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 21, "scene": 43, "line": "Andy, you know I can't do that. It'd be lying.", "order": 56980, "speaker": "Toby Flenderson"},
  {"season": 9, "episode": 21, "scene": 43, "line": "Seriously?", "order": 56981, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 21, "scene": 43, "line": "Yeah, I'm'", "order": 56982, "speaker": "Toby Flenderson"},
  {"season": 9, "episode": 21, "scene": 43, "line": "Come on, just do it.", "order": 56983, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 21, "scene": 43, "line": "I can't.", "order": 56984, "speaker": "Toby Flenderson"},
  {"season": 9, "episode": 21, "scene": 43, "line": "All right, fine, just know that you made me do this. [starts touching Toby's thighs]", "order": 56985, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 21, "scene": 43, "line": "[fending Andy off] Oh, come on. Andy, no.", "order": 56986, "speaker": "Toby Flenderson"},
  {"season": 9, "episode": 21, "scene": 43, "line": "God, Toby, don't'stop blocking my hand.", "order": 56987, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 21, "scene": 43, "line": "No, no.", "order": 56988, "speaker": "Toby Flenderson"},
  {"season": 9, "episode": 21, "scene": 43, "line": "This is your'you brought this on.", "order": 56989, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 21, "scene": 43, "line": "No, no. Andy.", "order": 56990, "speaker": "Toby Flenderson"},
  {"season": 9, "episode": 21, "scene": 43, "line": "Okay, all right. Groped you good. Off to Hollywood!", "order": 56991, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 21, "scene": 44, "line": "[answering phone] This is Angela. Oh. Hello, Miss Polodnikovski. Uh, how can I help you? Did my rent check not clear? Because I just transferred another $25 to that account. So if there's a problem it's clearly on your end. And'oh. Oh. Oh, okay. Good. Then... um' what is this about? No, no. Hey, hey! No, you are out of line Miss Polodnikovski. No, no you are. No you are! Evicted? Fine! I didn't want to live in that cesspool anyway! Listen, I get my security deposit back. Yes, I do. This is not fair! That is not fair! Well, you know what? You have so many hairs on your chin that Animal Control should've taken you away. That is very unladylike! You are disgusting! [hangs up phone]", "order": 56992, "speaker": "Angela Martin"},
  {"season": 9, "episode": 21, "scene": 44, "line": "What do you think that was about?", "order": 56993, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 21, "scene": 45, "line": "David. I lost the Scranton White Pages account. Do you have any idea how much paper that is? And I'd just like to point out, I was mad at Dwight. I did it out of spite.", "order": 56994, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 21, "scene": 45, "line": "We put the past behind us, though, Andy.", "order": 56995, "speaker": "David Wallace"},
  {"season": 9, "episode": 21, "scene": 45, "line": "What if I told you that I hate you and I hate this company?", "order": 56996, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 21, "scene": 45, "line": "Enough, Andy. Enough!", "order": 56997, "speaker": "David Wallace"},
  {"season": 9, "episode": 21, "scene": 45, "line": "Just stop forgiving me, David, please? This is my last chance to honor what is best inside myself. What if I took a dump on your new car?", "order": 56998, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 21, "scene": 45, "line": "Oh my god.", "order": 56999, "speaker": "David Wallace"},
  {"season": 9, "episode": 21, "scene": 46, "line": "Eleanor Roosevelt once said 'the future belongs to those who believe in the beauty of their dreams.' [clip of him flipping off David Wallace] I think she's right. [clip of David Wallace yelling at Andy to get out] I feel calm now. [clip of Andy taking a dump on a car] I feel, like, for the first time in a long time, I'm doing the right thing.", "order": 57000, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 21, "scene": 47, "line": "Andy.", "order": 57001, "speaker": "Angela Martin"},
  {"season": 9, "episode": 21, "scene": 47, "line": "Yeah.", "order": 57002, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 21, "scene": 47, "line": "What are you doing?", "order": 57003, "speaker": "Angela Martin"},
  {"season": 9, "episode": 21, "scene": 47, "line": "Uh, heavy stuff like books on the bottom. So it don't squash my knickety-knacks on top.", "order": 57004, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 21, "scene": 47, "line": "Yeah, okay. That's not what I mean. You don't have to leave because you said you would. Don't let pride ruin your whole life. Okay? It's not worth it.", "order": 57005, "speaker": "Angela Martin"},
  {"season": 9, "episode": 21, "scene": 47, "line": "Wow. Angela. What we had was great, and, honestly I think about it a lot too'", "order": 57006, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 21, "scene": 47, "line": "Ugh.", "order": 57007, "speaker": "Angela Martin"},
  {"season": 9, "episode": 21, "scene": 47, "line": "But I just'it's in the past. And I feel like we shouldn't'", "order": 57008, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 21, "scene": 47, "line": "No, that's not'", "order": 57009, "speaker": "Angela Martin"},
  {"season": 9, "episode": 21, "scene": 47, "line": "Rehash'", "order": 57010, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 21, "scene": 47, "line": "No, none'No, stop. It's just'", "order": 57011, "speaker": "Angela Martin"},
  {"season": 9, "episode": 21, "scene": 47, "line": "Exactly.", "order": 57012, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 21, "scene": 47, "line": "Okay. Well, have a good trip. Good luck, Andy.", "order": 57013, "speaker": "Angela Martin"},
  {"season": 9, "episode": 21, "scene": 47, "line": "Thanks, Angela. You too.", "order": 57014, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 21, "scene": 47, "line": "Thanks.", "order": 57015, "speaker": "Angela Martin"},
  {"season": 9, "episode": 21, "scene": 48, "line": "A-bridge, a-burnt. No turning back now. Everybody, Lorelai and I would like to say thank you and goodbye the only way we know how.", "order": 57016, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 21, "scene": 48, "line": "Oh, good lord.", "order": 57017, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 21, "scene": 48, "line": "Can't you just leave?", "order": 57018, "speaker": "Stanley Hudson"},
  {"season": 9, "episode": 21, "scene": 48, "line": "You know, Andy, you could just say a really nice goodbye.", "order": 57019, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 21, "scene": 48, "line": "Tuna, I'm a performer. And perform I shall. [sings 'I Will Remember You''everyone is slightly impressed]", "order": 57020, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 21, "scene": 48, "line": "[whispering] You okay?", "order": 57021, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 21, "scene": 48, "line": "[crying] It's just a really nice song.", "order": 57022, "speaker": "Angela Martin"},
  {"season": 9, "episode": 21, "scene": 48, "line": "[everyone applauding] Awesome!", "order": 57023, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 21, "scene": 49, "line": "Who knows? Maybe Andy will make it. He's not terrible.", "order": 57024, "speaker": "Phyllis Vance"},
  {"season": 9, "episode": 21, "scene": 49, "line": "Yeah. And people worse than him make it all the time. Like Lil Romeo.", "order": 57025, "speaker": "Stanley Hudson"},
  {"season": 9, "episode": 21, "scene": 49, "line": "No, he's good.", "order": 57026, "speaker": "Phyllis Vance"},
  {"season": 9, "episode": 21, "scene": 49, "line": "He was good.", "order": 57027, "speaker": "Stanley Hudson"},
  {"season": 9, "episode": 21, "scene": 50, "line": "Good night, Kevin.", "order": 57028, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 21, "scene": 50, "line": "Night, Oscar.", "order": 57029, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 21, "scene": 50, "line": "Tents? Are you thinking of going camping? I thought you found nature vulgar.", "order": 57030, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 21, "scene": 50, "line": "Well, I've changed my mind.", "order": 57031, "speaker": "Angela Martin"},
  {"season": 9, "episode": 21, "scene": 50, "line": "Wait a minute. You're not thinking of living in a'", "order": 57032, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 21, "scene": 50, "line": "Oh, god, could you just mind your own business?", "order": 57033, "speaker": "Angela Martin"},
  {"season": 9, "episode": 21, "scene": 50, "line": "Okay, I'm just gonna say this. You are not going to live in a tent.", "order": 57034, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 21, "scene": 50, "line": "Oh, god.", "order": 57035, "speaker": "Angela Martin"},
  {"season": 9, "episode": 21, "scene": 50, "line": "Come stay with me.", "order": 57036, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 21, "scene": 50, "line": "You don't want me at your place.", "order": 57037, "speaker": "Angela Martin"},
  {"season": 9, "episode": 21, "scene": 50, "line": "I do. Yes. Not forever. But until you get back on your feet. Which won't be long. It's the least I could do.", "order": 57038, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 21, "scene": 50, "line": "Well'", "order": 57039, "speaker": "Angela Martin"},
  {"season": 9, "episode": 21, "scene": 50, "line": "Separate bathrooms.", "order": 57040, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 21, "scene": 50, "line": "Thank you.", "order": 57041, "speaker": "Angela Martin"},
  {"season": 9, "episode": 21, "scene": 50, "line": "You're welcome. Let's go get Phillip. Then we'll get your stuff'", "order": 57042, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 21, "scene": 50, "line": "Okay.", "order": 57043, "speaker": "Angela Martin"},
  {"season": 9, "episode": 21, "scene": 50, "line": "And get you the hell outta that place.", "order": 57044, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 21, "scene": 50, "line": "Are you allowed to have pets?", "order": 57045, "speaker": "Angela Martin"},
  {"season": 9, "episode": 21, "scene": 50, "line": "Oh, Angela.", "order": 57046, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 21, "scene": 51, "line": "Yesterday I was just your average, ordinary paper salesman with a farm and a bunch of pipe dreams about beet fuel. Today, I leave here a regional manager with a black belt. It really is amazing how your life can change in one day.", "order": 57047, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 21, "scene": 52, "line": "You talk to Wade and Colin?", "order": 57048, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 21, "scene": 52, "line": "No, I just saw I missed their call. Why? What's up?", "order": 57049, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 21, "scene": 52, "line": "We got an offer on the table.", "order": 57050, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 21, "scene": 52, "line": "What kind of offer?", "order": 57051, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 21, "scene": 52, "line": "A buyout.", "order": 57052, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 21, "scene": 52, "line": "What?", "order": 57053, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 21, "scene": 52, "line": "We're in play, baby.", "order": 57054, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 21, "scene": 52, "line": "Oh, my god!", "order": 57055, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 21, "scene": 52, "line": "We did it! Hey, and look, the buyers wanna make sure it's not just a Philly play, so get this: they're gonna pay for us to go pitch out west. We talking Spurs, the Jazz, Cowboys. Blake Griffin, baby.", "order": 57056, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 21, "scene": 52, "line": "Wow, that is' wow.", "order": 57057, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 21, "scene": 52, "line": "Yeah, we did it.", "order": 57058, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 21, "scene": 52, "line": "Yeah, we did. Hey, how long'how long do we think that's gonna take?", "order": 57059, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 21, "scene": 52, "line": "Wade said we could do the whole country in three months.", "order": 57060, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 21, "scene": 52, "line": "Oh, man. Yeah, I can't do it.", "order": 57061, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 21, "scene": 52, "line": "Can't do what?", "order": 57062, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 21, "scene": 52, "line": "This, man. I can't do this to Pam.", "order": 57063, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 21, "scene": 52, "line": "No, no, Jim. This is different. This is everything.", "order": 57064, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 21, "scene": 52, "line": "I know. And I can't do it.", "order": 57065, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 21, "scene": 53, "line": "So is your place all bachelor-slobby and gross?", "order": 57066, "speaker": "Angela Martin"},
  {"season": 9, "episode": 21, "scene": 53, "line": "No, it's neat and tasteful, like most gay men's homes. The stereotype holds up.", "order": 57067, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 21, "scene": 53, "line": "I wouldn't know. I never lived with a gay guy.", "order": 57068, "speaker": "Angela Martin"},
  {"season": 9, "episode": 21, "scene": 53, "line": "Angela, you just were'", "order": 57069, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 21, "scene": 53, "line": "[starts sobbing] I love him.", "order": 57070, "speaker": "Angela Martin"},
  {"season": 9, "episode": 21, "scene": 53, "line": "I know. I understand more than most, but we both have to move on. You'you can't'", "order": 57071, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 21, "scene": 53, "line": "No, not the senator. I love Dwight.", "order": 57072, "speaker": "Angela Martin"},
  {"season": 9, "episode": 22, "scene": 1, "line": "The tea in Nepal is very hot.", "order": 57073, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 22, "scene": 1, "line": "But the coffee in Peru is much hotter. [Erin buzzes him into the office]", "order": 57074, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 22, "scene": 2, "line": "Last week I finally became permanent manager of Dunder Mifflin Scranton. My first project: increase security. I got these doors from a jewelry store that had recently gone out of business. Now they're protecting America's real treasure, paper. Every morning I email the day's security codes. Something that's been really missing from my life has been writing secret codes. It's not the KGB, but it's a start.", "order": 57075, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 22, "scene": 3, "line": "The tea in Nepal is very hot.", "order": 57076, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 22, "scene": 3, "line": "But the coffee in Peru is far hotter.", "order": 57077, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 22, "scene": 3, "line": "Close.", "order": 57078, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 22, "scene": 3, "line": "This is Tuesday, right? The coffee in Paraguay is far hotter?", "order": 57079, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 22, "scene": 3, "line": "Colder.", "order": 57080, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 22, "scene": 3, "line": "The coffee in Paraguay is colder?", "order": 57081, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 22, "scene": 3, "line": "No, I meant you're getting colder. The correct response is, 'the coffee in Peru is much hotter.'", "order": 57082, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 22, "scene": 3, "line": "Ah, much, ok.", "order": 57083, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 22, "scene": 3, "line": "But, that's three wrong, so I gotta give you the steam. [Dwight begins to protest] Unless you want me to break protocol?", "order": 57084, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 22, "scene": 3, "line": "No, no. Give me the steam.", "order": 57085, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 22, "scene": 4, "line": "It's just harmless steam to panic intruders. I'd like to get harmful steam, but the prices are absurd.", "order": 57086, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 22, "scene": 5, "line": "[while getting steamed] Break protocol! Break protocol! Break protocol!", "order": 57087, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 22, "scene": 5, "line": "[carrying in clothes on hangers] Oh, I'm saving a fortune on dry cleaning.", "order": 57088, "speaker": "Creed Bratton"},
  {"season": 9, "episode": 22, "scene": 6, "line": "Mama!", "order": 57089, "speaker": "Philip"},
  {"season": 9, "episode": 22, "scene": 6, "line": "Angela, someone wants you.", "order": 57090, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 22, "scene": 6, "line": "Ok, coming. Hi, baby.", "order": 57091, "speaker": "Angela Martin"},
  {"season": 9, "episode": 22, "scene": 7, "line": "Angela's divorce from the senator has been very difficult for her. When she got kicked out of her apartment, I invited her to move in with me. Ironic that it's Angela who's living in the closet. Hey-o.", "order": 57092, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 22, "scene": 8, "line": "Saddle shoes. With denim? I will literally call child services.", "order": 57093, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 22, "scene": 9, "line": "Go get 'em, honey.", "order": 57094, "speaker": "Esther"},
  {"season": 9, "episode": 22, "scene": 9, "line": "Oh, don't worry. They'll get got.", "order": 57095, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 22, "scene": 10, "line": "Manager of Dunder Mifflin? Check. Owner of a 1600-acre beet farm? Check. Engaged to be married to an actual milkmaid? Check' on that later today. This is my grandmother's ring. It was made from a bullet I took out of her left buttock. She was a moonshiner shot by Adolph Coors. This is my grandmother's buttock bullet ring.", "order": 57096, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 22, "scene": 11, "line": "Hello, good morning. My name is Andrew Bernard. You might know me from a TV documentary that's premiering tonight. I also killed it in local theater and I am fresh off of a hot industrial flick.", "order": 57097, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 22, "scene": 11, "line": "Ok, thanks. Go ahead and get in the back of the line.", "order": 57098, "speaker": "Check-in guy"},
  {"season": 9, "episode": 22, "scene": 11, "line": "Yes!", "order": 57099, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 22, "scene": 12, "line": "Well, burned all my bridges at Dunder Mifflin, and time to become the next American Idol. By winning America's Next A Cappella Sensation. On channel TBD. It's a really cool show, it's like a revision of the whole American Idol, Voice, Sing-Off phenomenon. On this show, all three judges are mean!", "order": 57100, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 22, "scene": 13, "line": "Sales form for you to sign.", "order": 57101, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 22, "scene": 13, "line": "You know what to do. [waits for Jim to put form in inbox, signs it and returns it to outbox] Ok", "order": 57102, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 22, "scene": 14, "line": "Behind every great regional manager is a great assistant to the regional manager, and I have chosen one of the best.", "order": 57103, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 22, "scene": 14, "line": "Aw, thanks, man.", "order": 57104, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 22, "scene": 14, "line": "Once upon a time we were natural enemies, but we've overcome our differences. Much like Germany and Italy in World War'", "order": 57105, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 22, "scene": 14, "line": "No.", "order": 57106, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 22, "scene": 14, "line": "Good call. Together we run a no-nonsense office.", "order": 57107, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 22, "scene": 15, "line": "Pre-conference room meeting with Dwight went really well.", "order": 57108, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 22, "scene": 15, "line": "Oh, bodes well for the post-conference room meeting.", "order": 57109, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 22, "scene": 15, "line": "All depends on the conference room meeting itself. [phone chimes] Uh, okay.", "order": 57110, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 22, "scene": 15, "line": "Is that them again?", "order": 57111, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 22, "scene": 15, "line": "Yeah.", "order": 57112, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 22, "scene": 15, "line": "Maybe you should call back?", "order": 57113, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 22, "scene": 15, "line": "I will. I will.", "order": 57114, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 22, "scene": 16, "line": "The guys at Athlead are still bugging me about this three month roadshow thing. Meeting athletes on their home turf, building relationships. Yeah, it sounds exciting. But I said no, and that's final. I almost lost Pam over this business. I'm not risking that again.", "order": 57115, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 22, "scene": 17, "line": "When Jim decided to come back to Scranton full-time, I was relieved, but I also feel a little guilty. I mean, he's giving up this big thing for me. But he seems happy. I mean, he's certainly been goofing around a lot. I love goofy Jim.", "order": 57116, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 22, "scene": 18, "line": "Welcome.", "order": 57117, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 22, "scene": 18, "line": "He welcomes you.", "order": 57118, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 22, "scene": 18, "line": "Please take an agenda item.", "order": 57119, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 22, "scene": 18, "line": "Your agenda-taking pleases him.", "order": 57120, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 22, "scene": 18, "line": "Have a seat, Phyllis. There we go. As you know, I like to begin each day with an inspirational quote. 'Some say the only failure there is is the failure to try.' [buzzer sound] That is wrong. Failure of any kind is failure. Jim, over to you.", "order": 57121, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 22, "scene": 18, "line": "Let's not get crazy and ruin our no-nonsense streak, all right? So, for instance, if you're expecting a fax today, please don't yell out, 'Michael J. Fax from Fax to the Future.' Ok? That's nonsense.", "order": 57122, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 22, "scene": 18, "line": "Question.", "order": 57123, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 22, "scene": 18, "line": "Yes, beautiful girl in the front.", "order": 57124, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 22, "scene": 18, "line": "We are expecting a water delivery today at 10am. What if, as they're delivering the water jugs, someone screams out, 'Nice jugs'?", "order": 57125, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 22, "scene": 18, "line": "That's obviously nonsense. Nonsense. And what percentage of nonsense do we tolerate in this office?", "order": 57126, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 22, "scene": 18, "line": "[overlapping] Zero. No nonsense. You can't have nonsense.", "order": 57127, "speaker": "Everyone"},
  {"season": 9, "episode": 22, "scene": 18, "line": "[as Angela enters with Phillip] What is going on?", "order": 57128, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 22, "scene": 18, "line": "Daycare won't take Phillip anymore.", "order": 57129, "speaker": "Angela Martin"},
  {"season": 9, "episode": 22, "scene": 18, "line": "Why?", "order": 57130, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 22, "scene": 18, "line": "Apparently my station in life has descended to a depth even they won't forgive. So, hi.", "order": 57131, "speaker": "Angela Martin"},
  {"season": 9, "episode": 22, "scene": 18, "line": "Hi, buddy.", "order": 57132, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 22, "scene": 18, "line": "Ok. Um, new agenda item. Phillip will be joining us in the office today.", "order": 57133, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 22, "scene": 18, "line": "Gotta write that down. Ok, big day today. Airing of the documentary. Who's excited?", "order": 57134, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 22, "scene": 19, "line": "I'd better come out of this smelling like a rose. I've been on my best behavior for nine years. If it wasn't for the cameras, I would've done some truly vulgar crap.", "order": 57135, "speaker": "Meredith Palmer"},
  {"season": 9, "episode": 22, "scene": 20, "line": "Over the course of this documentary I've had three affairs. If you find my body in a ditch, let me save the police some trouble: my wife did it.", "order": 57136, "speaker": "Stanley Hudson"},
  {"season": 9, "episode": 22, "scene": 21, "line": "I'm letting you all off half an hour early to view the documentary. So you can make it up to me by working an extra half an hour tomorrow, or a minute extra for the rest of the month.", "order": 57137, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 22, "scene": 21, "line": "Ooh, announcement! Some of us whose televisions got broken during an all-you-can-eat shrimp commercial will be watching tonight at Poor Richard's. But note, all are welcome, not just those whose saw an all-you-can-eat shrimp commercial and charged their televisions.", "order": 57138, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 22, "scene": 22, "line": "I can't believe the doc is finally going to air. When this thing started, I was still having sex with women. As was Kevin, I believe.", "order": 57139, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 22, "scene": 23, "line": "This airs tonight? Oh my god. If my parents see this, I am toast.", "order": 57140, "speaker": "Creed Bratton"},
  {"season": 9, "episode": 22, "scene": 24, "line": "This is really huge. This is like the March on Washington but for a singing show. Can you imagine if Martin Luther King were here? And sang 'I Dreamed a Dream' from Les Mis? With that baritone? That would be historic.", "order": 57141, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 22, "scene": 24, "line": "Whoo-hoo! Casey Dean! Cincinnati, Ohio! [singing] Doctor, doctor, gimme the news, I got a great Casey Dean for you.", "order": 57142, "speaker": "Casey"},
  {"season": 9, "episode": 22, "scene": 24, "line": "No!", "order": 57143, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 22, "scene": 24, "line": "America wants it!", "order": 57144, "speaker": "Casey"},
  {"season": 9, "episode": 22, "scene": 24, "line": "No, this is my time! You don't belt on my time! I belt on my time.", "order": 57145, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 22, "scene": 24, "line": "[singing] Casey Dean!", "order": 57146, "speaker": "Casey"},
  {"season": 9, "episode": 22, "scene": 24, "line": "[vocalizing over her] Casey Dean!", "order": 57147, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 22, "scene": 24, "line": "Man, those are some nice pipes.", "order": 57148, "speaker": "Casey"},
  {"season": 9, "episode": 22, "scene": 24, "line": "Yeah.", "order": 57149, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 22, "scene": 24, "line": "What's your name?", "order": 57150, "speaker": "Casey"},
  {"season": 9, "episode": 22, "scene": 24, "line": "Andy. What's yours?", "order": 57151, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 22, "scene": 25, "line": "Why didn't you pack the apple snacks?", "order": 57152, "speaker": "Angela Martin"},
  {"season": 9, "episode": 22, "scene": 25, "line": "Why didn't you pack the apple snacks?", "order": 57153, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 22, "scene": 25, "line": "Guys.", "order": 57154, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 22, "scene": 25, "line": "Because whenever I pack the bag, you say I do it wrong.", "order": 57155, "speaker": "Angela Martin"},
  {"season": 9, "episode": 22, "scene": 25, "line": "Guys.", "order": 57156, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 22, "scene": 25, "line": "Why don't you pack it?", "order": 57157, "speaker": "Angela Martin"},
  {"season": 9, "episode": 22, "scene": 25, "line": "Guys.", "order": 57158, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 22, "scene": 25, "line": "Kevin, what?", "order": 57159, "speaker": "Angela Martin & Oscar Martinez"},
  {"season": 9, "episode": 22, "scene": 25, "line": "Do you want to see a video of a weatherman who says 'bold front' instead of 'cold front'? It's insane.", "order": 57160, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 22, "scene": 25, "line": "Not now, Kevin. Can't you see we're busy? Phillip needs his apple snacks.", "order": 57161, "speaker": "Angela Martin"},
  {"season": 9, "episode": 22, "scene": 25, "line": "Seriously, Kevin. I'm just gonna have to go to the store.", "order": 57162, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 22, "scene": 25, "line": "Ok, you go to the store.", "order": 57163, "speaker": "Angela Martin"},
  {"season": 9, "episode": 22, "scene": 26, "line": "Philllip, Phillip, Phillip. It's all about Phillip. I hate Phillip.", "order": 57164, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 22, "scene": 27, "line": "Not now! Private time!", "order": 57165, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 22, "scene": 27, "line": "I love Star Wars as much as the next guy'", "order": 57166, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 22, "scene": 27, "line": "Hey, hey! Seriously? [holds up Battlestar Galactica model box]", "order": 57167, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 22, "scene": 27, "line": "My god, I'm so sorry. Well this might make up for it: I think I have found an enormous source of overlooked PFN.", "order": 57168, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 22, "scene": 27, "line": "Which is, of course'", "order": 57169, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 22, "scene": 27, "line": "Potential future nonsense.", "order": 57170, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 22, "scene": 27, "line": "Yes, good abbreviating, Jim. That saved some time. Now, hurry up, shut the door. Break it down for me.", "order": 57171, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 22, "scene": 27, "line": "I'm gonna need you to look at your hierarchy mobile. You've got a regional manager.", "order": 57172, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 22, "scene": 27, "line": "The power source.", "order": 57173, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 22, "scene": 27, "line": "Obviously, the assistant to the regional manager.", "order": 57174, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 22, "scene": 27, "line": "A loyal, but bungling apostle.", "order": 57175, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 22, "scene": 27, "line": "But what about the assistant assistant to the regional manager?", "order": 57176, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 22, "scene": 27, "line": "Someone to whisper in the ear of the consigliore.", "order": 57177, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 22, "scene": 27, "line": "Exactly.", "order": 57178, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 22, "scene": 27, "line": "I'd have to get some more wire and string, but it's doable. Do you think any of them out there are capable?", "order": 57179, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 22, "scene": 27, "line": "Yeah, right. I mean, unless they're willing to pass some tests.", "order": 57180, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 22, "scene": 27, "line": "I like the sound of that. Who do you have in mind?", "order": 57181, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 22, "scene": 27, "line": "Well, I know this sounds crazy, but how would King Arthur choose the next knight of his round table?", "order": 57182, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 22, "scene": 27, "line": "That doesn't sound crazy, Jim. That's the sanest thing I've ever heard.", "order": 57183, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 22, "scene": 28, "line": "What're you listening to?", "order": 57184, "speaker": "Casey"},
  {"season": 9, "episode": 22, "scene": 28, "line": "Hmm? Um, uh, locking in my starting note. A 440.", "order": 57185, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 22, "scene": 28, "line": "Oh, sweet.", "order": 57186, "speaker": "Casey"},
  {"season": 9, "episode": 22, "scene": 28, "line": "A cappella is all about pitch, and I am nothing if not a total pitch bitch.", "order": 57187, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 22, "scene": 28, "line": "What's up, everybody? And welcome to America's Next A Cappella Singing Sensation!", "order": 57188, "speaker": "Mark McGrath"},
  {"season": 9, "episode": 22, "scene": 28, "line": "Ah! It's Mark McGrath! Oh my god! You're gorgeous!", "order": 57189, "speaker": "Casey"},
  {"season": 9, "episode": 22, "scene": 28, "line": "Thank you, thank you so much. And thank you for your patience. And we hope to see you guys within the next five to seven hours. Now, just to give you a couple parameters of the show, each of you will sing a song for thirty seconds, after which our judges will decide if they want you in their a cappella group. Now, each group will start with 90 singers, which will compete in a series of singing and physical challenges. Oh, and look out for that pesky mole!", "order": 57190, "speaker": "Mark McGrath"},
  {"season": 9, "episode": 22, "scene": 28, "line": "There's a mole?", "order": 57191, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 22, "scene": 28, "line": "Oops. I'm not supposed to'I'm not supposed to say that. All right, no mole. Forget I said it, all right?", "order": 57192, "speaker": "Mark McGrath"},
  {"season": 9, "episode": 22, "scene": 28, "line": "What mole? What are you talking about? I already forgot about it.", "order": 57193, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 22, "scene": 28, "line": "Good man right there. I'll see you guys inside. Good luck, all right?", "order": 57194, "speaker": "Mark McGrath"},
  {"season": 9, "episode": 22, "scene": 28, "line": "Yeah! Pour some Sugar Ray on me!", "order": 57195, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 22, "scene": 28, "line": "What? On a roll much?", "order": 57196, "speaker": "Casey"},
  {"season": 9, "episode": 22, "scene": 28, "line": "I don't know where it came from.", "order": 57197, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 22, "scene": 28, "line": "That was amazing, man!", "order": 57198, "speaker": "Casey"},
  {"season": 9, "episode": 22, "scene": 28, "line": "Yeah. Yeah, it felt good. It felt funny.", "order": 57199, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 22, "scene": 28, "line": "Yeah, you made a personal connection with him.", "order": 57200, "speaker": "Casey"},
  {"season": 9, "episode": 22, "scene": 28, "line": "I did, I felt it.", "order": 57201, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 22, "scene": 28, "line": "Big time.", "order": 57202, "speaker": "Casey"},
  {"season": 9, "episode": 22, "scene": 29, "line": "What are you so excited about?", "order": 57203, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 22, "scene": 29, "line": "Nothing.", "order": 57204, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 22, "scene": 29, "line": "What are you up to?", "order": 57205, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 22, "scene": 30, "line": "Members of the office, hear ye.", "order": 57206, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 22, "scene": 30, "line": "That means ye, Plop!", "order": 57207, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 22, "scene": 30, "line": "Plop? Still?", "order": 57208, "speaker": "Pete Miller"},
  {"season": 9, "episode": 22, "scene": 30, "line": "We owe Andy that much. Am I right people?", "order": 57209, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 22, "scene": 30, "line": "Fine.", "order": 57210, "speaker": "Pete Miller"},
  {"season": 9, "episode": 22, "scene": 30, "line": "Today we will be testing candidates for the position of assistant to the assistant to the regional manager.", "order": 57211, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 22, "scene": 30, "line": "Aw, heck ya!", "order": 57212, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 22, "scene": 30, "line": "Nice.", "order": 57213, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 22, "scene": 30, "line": "You'll always have the upper hand, when you've got a good a-arm. Trademark pending.", "order": 57214, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 22, "scene": 30, "line": "This is not an excuse to blow off work doing carnival-like activities. Sure, every participant will be getting a corndog, but that's for fueling only. No savoring.", "order": 57215, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 22, "scene": 30, "line": "Wow, the honor. God, I envy them.", "order": 57216, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 22, "scene": 30, "line": "He envies you.", "order": 57217, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 22, "scene": 30, "line": "You don't need to repeat right now, when I'm saying it.", "order": 57218, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 22, "scene": 30, "line": "Alright.", "order": 57219, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 22, "scene": 31, "line": "By 2:00, Dwight will chose himself to be assistant to his own assistant, me.", "order": 57220, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 22, "scene": 32, "line": "What up?", "order": 57221, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 22, "scene": 32, "line": "What's going on? How was the delivery?", "order": 57222, "speaker": "Glenn"},
  {"season": 9, "episode": 22, "scene": 32, "line": "Delivered all my furniture to Philly. Hey, keep it down, though. Nobody knows I'm here.", "order": 57223, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 22, "scene": 33, "line": "I hate goodbyes, so last week, when I left Dunder Mifflin for good, I pulled the old Irish Exit. Just slipped out without making a big deal. No hard feelings. No feelings at all.", "order": 57224, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 22, "scene": 34, "line": "A good assistant knows what their superior is thinking before they even think it. Meredith, what number am I thinking of right now?", "order": 57225, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 22, "scene": 34, "line": "Uh, two.", "order": 57226, "speaker": "Meredith Palmer"},
  {"season": 9, "episode": 22, "scene": 34, "line": "985000000000017", "order": 57227, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 22, "scene": 34, "line": "Not even close, Meredith. Come on!", "order": 57228, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 22, "scene": 34, "line": "Okay, Pam. What song is running through my head right now?", "order": 57229, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 22, "scene": 34, "line": "Theme song from Saved by the Bell.", "order": 57230, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 22, "scene": 34, "line": "Oh, my god! It was the theme song to Boy Meets World.", "order": 57231, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 22, "scene": 34, "line": "Wait, no, no, no, stop. Spouses can read each other's minds. You're trying to give your wife this job.", "order": 57232, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 22, "scene": 34, "line": "That's exactly what I was doing. Plop, what animal am I picturing?", "order": 57233, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 22, "scene": 34, "line": "A horse.", "order": 57234, "speaker": "Pete Miller"},
  {"season": 9, "episode": 22, "scene": 34, "line": "Ew, the exact opposite, actually.", "order": 57235, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 22, "scene": 34, "line": "What's the opposite of a horse?", "order": 57236, "speaker": "Pete Miller"},
  {"season": 9, "episode": 22, "scene": 34, "line": "Come on.", "order": 57237, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 22, "scene": 34, "line": "Sea horse.", "order": 57238, "speaker": "Jim Halpert & Dwight Schrute"},
  {"season": 9, "episode": 22, "scene": 34, "line": "Whoa. How did you know that I was gonna'", "order": 57239, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 22, "scene": 34, "line": "Say that? Uncanny.", "order": 57240, "speaker": "Jim Halpert & Dwight Schrute"},
  {"season": 9, "episode": 22, "scene": 35, "line": "Challenge number two, protocol. Clark?", "order": 57241, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 22, "scene": 35, "line": "Yeah.", "order": 57242, "speaker": "Clark Green"},
  {"season": 9, "episode": 22, "scene": 35, "line": "Do you want a corn dog?", "order": 57243, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 22, "scene": 35, "line": "I would love a corn dog.", "order": 57244, "speaker": "Clark Green"},
  {"season": 9, "episode": 22, "scene": 35, "line": "We'll see. You are an assistant who's just gotten a phone message. I am in a meeting with Dwight, Robert Dunder, and his niece. Uh-oh, look who came to join us. The Turkish ambassador to Armenia, Yuri Slovak, who, by the way, is extremely embarrassed about the size of his nose. Go ahead and read that phone message.", "order": 57245, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 22, "scene": 35, "line": "[reading] Mr. Halpert, your wife called to find out how your meeting with Yuri Big Nose went.'", "order": 57246, "speaker": "Clark Green"},
  {"season": 9, "episode": 22, "scene": 35, "line": "No, no, no, no! You don't read it aloud like that! God! Besides, the whole thing is a trick question. There's no Turkish ambassador to Armenia. The two countries don't have diplomatic relations.", "order": 57247, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 22, "scene": 35, "line": "Uncanny.", "order": 57248, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 22, "scene": 36, "line": "I just hope that if my buddies who are still in Afghanistan see me win, they'll feel like anything is possible.", "order": 57249, "speaker": "Soldier"},
  {"season": 9, "episode": 22, "scene": 36, "line": "Great, more screen time for the war vet. All you gotta do is risk your life for this country and everyone goes gaga for you.", "order": 57250, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 22, "scene": 36, "line": "Over at the porta-potties, they were interviewing a homeless, single mother with three kids.", "order": 57251, "speaker": "Casey"},
  {"season": 9, "episode": 22, "scene": 36, "line": "What?", "order": 57252, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 22, "scene": 36, "line": "Yeah.", "order": 57253, "speaker": "Casey"},
  {"season": 9, "episode": 22, "scene": 36, "line": "Is this a show about the resiliency of the human spirit? Or is it a show about singing?", "order": 57254, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 22, "scene": 36, "line": "I don't know. But I'm getting really worried here.", "order": 57255, "speaker": "Casey"},
  {"season": 9, "episode": 22, "scene": 36, "line": "Me too. Hold my place in line.", "order": 57256, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 22, "scene": 36, "line": "Where are you going?", "order": 57257, "speaker": "Casey"},
  {"season": 9, "episode": 22, "scene": 36, "line": "They want feel-good stories? Wait until they get a good feel of me.", "order": 57258, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 22, "scene": 37, "line": "Thanks, man.", "order": 57259, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 22, "scene": 37, "line": "Thank you.", "order": 57260, "speaker": "Hank Tate"},
  {"season": 9, "episode": 22, "scene": 37, "line": "Yep. Take care.", "order": 57261, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 22, "scene": 37, "line": "Darryl, hey.", "order": 57262, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 22, "scene": 37, "line": "Hey.", "order": 57263, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 22, "scene": 37, "line": "Cool, are you coming back to say hi?", "order": 57264, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 22, "scene": 37, "line": "No, no, I'm not here actually. These donuts are part of my escape from the guys at the warehouse I didn't say goodbye to.", "order": 57265, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 22, "scene": 37, "line": "Aha. How's Athlead?", "order": 57266, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 22, "scene": 37, "line": "We livin' like rock stars. I'm about to eat free steaks with my sports heroes in 32 different cities.", "order": 57267, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 22, "scene": 37, "line": "Wow.", "order": 57268, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 22, "scene": 37, "line": "Jim really doesn't want to come?", "order": 57269, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 22, "scene": 37, "line": "He says he doesn't want to.", "order": 57270, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 22, "scene": 37, "line": "Wow. Man. I hope he doesn't regret it.", "order": 57271, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 22, "scene": 37, "line": "Well, he seems really happy being back here at Dunder Mifflin.", "order": 57272, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 22, "scene": 37, "line": "Jim is happy here, selling paper at Dunder Mifflin?", "order": 57273, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 22, "scene": 37, "line": "That's what he says.", "order": 57274, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 22, "scene": 37, "line": "If you say so. Hey, good seeing you. Remember, I was never here. All right, then.", "order": 57275, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 22, "scene": 38, "line": "An assistant brings their boss coffee with speed and dexterity. But an assistant to the assistant has a thousand times more to prove, am I right?", "order": 57276, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 22, "scene": 38, "line": "A thousand times more.", "order": 57277, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 22, "scene": 38, "line": "I'll try this one.", "order": 57278, "speaker": "Phyllis Vance"},
  {"season": 9, "episode": 22, "scene": 38, "line": "Phyllis! Grab both these coffees, double-fist it, and head through this obstacle course.", "order": 57279, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 22, "scene": 38, "line": "[grabbing the coffee] Hot!", "order": 57280, "speaker": "Phyllis Vance"},
  {"season": 9, "episode": 22, "scene": 38, "line": "Yeah. It's real. It's the only way you'll learn. OK, and go ahead. [Phyllis carefully makes her way through the obstacle course] Oh, god, nice! She's through the green, everybody. Here comes yellow, real doozy. Careful!", "order": 57281, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 22, "scene": 38, "line": "[over Jim] No, no, no! Phyllis, seriously?", "order": 57282, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 22, "scene": 38, "line": "Look at that form.", "order": 57283, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 22, "scene": 38, "line": "[running over and taking the coffee cups from Phyllis] Oh, god, this is pathetic! The boss needs his coffee! [races through obstacles, spilling coffee] Augh! Ah! Here you are, sir! Here's your coffee! Ah, my skin, ow, ow! It burns! Ah!", "order": 57284, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 22, "scene": 38, "line": "Uncanny. [everyone applauds]", "order": 57285, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 22, "scene": 38, "line": "Darryl?! Darryl!", "order": 57286, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 22, "scene": 38, "line": "Whoa.", "order": 57287, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 22, "scene": 38, "line": "Oh, hey. Hey, what's up, y'all?", "order": 57288, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 22, "scene": 38, "line": "You left us without saying goodbye.", "order": 57289, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 22, "scene": 38, "line": "Oh, my bad. Goodbye, everybody.", "order": 57290, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 22, "scene": 38, "line": "Hey! No way!", "order": 57291, "speaker": "Meredith Palmer"},
  {"season": 9, "episode": 22, "scene": 38, "line": "That's totally uncool.", "order": 57292, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 22, "scene": 38, "line": "Are you kidding? You broke our hearts. Get upstairs.", "order": 57293, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 22, "scene": 38, "line": "I don't think I sh-", "order": 57294, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 22, "scene": 38, "line": "Get upstairs, mister!", "order": 57295, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 22, "scene": 38, "line": "Yeah!", "order": 57296, "speaker": "Meredith Palmer"},
  {"season": 9, "episode": 22, "scene": 38, "line": "Right. Now.", "order": 57297, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 22, "scene": 38, "line": "Guess I'm going upstairs.", "order": 57298, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 22, "scene": 38, "line": "You know, Dwight, this whole search for the assistant thing'none of these people are good enough.", "order": 57299, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 22, "scene": 38, "line": "I know.", "order": 57300, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 22, "scene": 38, "line": "What I'm about to say makes no logical sense, and yet, it might be the most logical thing I've ever said.", "order": 57301, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 22, "scene": 38, "line": "Jim, this is gonna come as no surprise but I know exactly what you are going to say. The only possible assistant to my assistant-", "order": 57302, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 22, "scene": 38, "line": "Is-", "order": 57303, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 22, "scene": 38, "line": "Me.", "order": 57304, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 22, "scene": 39, "line": "The new assistant to the assistant to the regional manager is Dwight K. Schrute.", "order": 57305, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 22, "scene": 39, "line": "Yes! [weak applause] Thank you.", "order": 57306, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 22, "scene": 39, "line": "I think you might want to kneel for this. And yet, the manager for Dunder Mifflin kneels for no one. [Dwight awkwardly squats] That's it. You look really, really good.", "order": 57307, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 22, "scene": 39, "line": "Okay, from now on, anyone who needs to speak to me has got to go through me first, all right?", "order": 57308, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 22, "scene": 40, "line": "Hey.", "order": 57309, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 22, "scene": 40, "line": "Hey.", "order": 57310, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 22, "scene": 40, "line": "You all right? What's going on?", "order": 57311, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 22, "scene": 40, "line": "Are you happy?", "order": 57312, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 22, "scene": 40, "line": "Yes, I'm happy.", "order": 57313, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 22, "scene": 40, "line": "No, I know that you're, like, happy and, like, you had fun today.", "order": 57314, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 22, "scene": 40, "line": "Yeah.", "order": 57315, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 22, "scene": 40, "line": "And that was fun. But what about a year from now?", "order": 57316, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 22, "scene": 40, "line": "What?", "order": 57317, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 22, "scene": 40, "line": "What about five years from now?", "order": 57318, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 22, "scene": 40, "line": "Pam.", "order": 57319, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 22, "scene": 40, "line": "Because I'm so glad you're back, baby, but I'm just'I was talking to Darryl, and he was talking about the trip, and I just feel like you're giving up so much.", "order": 57320, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 22, "scene": 40, "line": "This was my decision, not yours.", "order": 57321, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 22, "scene": 40, "line": "Okay.", "order": 57322, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 22, "scene": 40, "line": "You didn't force me.", "order": 57323, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 22, "scene": 40, "line": "I kind of forced you to do it.", "order": 57324, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 22, "scene": 40, "line": "You did not force me to do this.", "order": 57325, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 22, "scene": 40, "line": "Yes, I did.", "order": 57326, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 22, "scene": 40, "line": "I don't know how else to tell you.", "order": 57327, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 22, "scene": 40, "line": "I'm afraid that you're gonna resent me and I'm afraid that'", "order": 57328, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 22, "scene": 40, "line": "Resent you?", "order": 57329, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 22, "scene": 40, "line": "This is not enough for you and I'm afraid that I'm not enough for you.", "order": 57330, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 22, "scene": 40, "line": "Is that really what you think?", "order": 57331, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 22, "scene": 41, "line": "Not enough? I don't know how else to explain it to her, so, you know what? I know it's against the rules but I'm gonna need a favor from you guys.", "order": 57332, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 22, "scene": 41, "line": "Okay. You got it, man.", "order": 57333, "speaker": "Camera Crew"},
  {"season": 9, "episode": 22, "scene": 42, "line": "I didn't realize we were this close.", "order": 57334, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 22, "scene": 42, "line": "We're all a little hormonal with the doc airing.", "order": 57335, "speaker": "Phyllis Vance"},
  {"season": 9, "episode": 22, "scene": 42, "line": "Are you gonna come to Poor Richard's and watch with us tonight?", "order": 57336, "speaker": "Meredith Palmer"},
  {"season": 9, "episode": 22, "scene": 42, "line": "Uh' yeah. Depending on traffic.", "order": 57337, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 22, "scene": 42, "line": "He ain't coming.", "order": 57338, "speaker": "Stanley Hudson"},
  {"season": 9, "episode": 22, "scene": 42, "line": "Oh, god!", "order": 57339, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 22, "scene": 43, "line": "These dudes are definitely in a weird mood. Picked the wrong day to return a truck.", "order": 57340, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 22, "scene": 44, "line": "Well, it's been great.", "order": 57341, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 22, "scene": 44, "line": "Eleven years. A guy is in your life for 11 years and then he's gone for who knows how long.", "order": 57342, "speaker": "Phyllis Vance"},
  {"season": 9, "episode": 22, "scene": 44, "line": "Maybe forever. Anyhow'", "order": 57343, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 22, "scene": 44, "line": "Did we ever have lunch together, just'just the two of us? You know what, I'm gonna make reservations right now at Cugino's.", "order": 57344, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 22, "scene": 44, "line": "Question for Darryl. Did we ever get loaded and listen to Zeppelin in my van?", "order": 57345, "speaker": "Meredith Palmer"},
  {"season": 9, "episode": 22, "scene": 44, "line": "Oh, oh, I'm sure we did.", "order": 57346, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 22, "scene": 44, "line": "Nah, I call one hour van time with Darryl.", "order": 57347, "speaker": "Meredith Palmer"},
  {"season": 9, "episode": 22, "scene": 44, "line": "Darryl, you know, I would love to just record some of your stories, just let the tape roll for six or eight or ten hours and just see what we get.", "order": 57348, "speaker": "Clark Green"},
  {"season": 9, "episode": 22, "scene": 44, "line": "Listen guys, we can do it all. We just have to divide Darryl's next 12 hours into 90-minute segments. I will go watch an eHow video on how to use Excel, and then we'll get this started.", "order": 57349, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 22, "scene": 44, "line": "Whoa, whoa, whoa, whoa. Wait a minute. Okay, I made the mistake of sneaking out of here and that's on me. But I'm not gonna spend the rest of the day here doing stuff with you cause you're feeling sentimental.", "order": 57350, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 22, "scene": 44, "line": "You have to! [everyone grumbling]", "order": 57351, "speaker": "Meredith Palmer"},
  {"season": 9, "episode": 22, "scene": 44, "line": "Hey, hey, hey. I'll do one thing with y'all.", "order": 57352, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 22, "scene": 44, "line": "Which thing?", "order": 57353, "speaker": "Phyllis Vance"},
  {"season": 9, "episode": 22, "scene": 44, "line": "I don't care, choose amongst yourselves. Not the van though.", "order": 57354, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 22, "scene": 45, "line": "Kevin, Kevin.", "order": 57355, "speaker": "Angela Martin"},
  {"season": 9, "episode": 22, "scene": 45, "line": "Oh, you know my name. Well, that is shocking. [continues making noise]", "order": 57356, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 22, "scene": 45, "line": "Kevin, could you not do that?", "order": 57357, "speaker": "Angela Martin"},
  {"season": 9, "episode": 22, "scene": 45, "line": "What? I'm moving the ink down in my pen, for work.", "order": 57358, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 22, "scene": 45, "line": "Here, use my pen.", "order": 57359, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 22, "scene": 45, "line": "Don't tell me what to do!", "order": 57360, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 22, "scene": 45, "line": "Shhh!", "order": 57361, "speaker": "Angela Martin & Oscar Martinez"},
  {"season": 9, "episode": 22, "scene": 45, "line": "No, I don't need this! And you obviously don't need me.", "order": 57362, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 22, "scene": 45, "line": "Kevin, where are you going?", "order": 57363, "speaker": "Angela Martin"},
  {"season": 9, "episode": 22, "scene": 45, "line": "Away. Tell Phillip that his stupid little baby wish came true.", "order": 57364, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 22, "scene": 45, "line": "He just won't go down. It's as if he's excited by all this paper.", "order": 57365, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 22, "scene": 45, "line": "I know. Earlier today he tried to eat some of the 24-weight letter bond.", "order": 57366, "speaker": "Angela Martin"},
  {"season": 9, "episode": 22, "scene": 45, "line": "Smart baby. That's the most flavorful bond.", "order": 57367, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 22, "scene": 46, "line": "Hey, what's goin' on over here? Some sort of singing competition for the young'uns?", "order": 57368, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 22, "scene": 46, "line": "You're back!", "order": 57369, "speaker": "Casey"},
  {"season": 9, "episode": 22, "scene": 46, "line": "No, it's me, Andy!", "order": 57370, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 22, "scene": 46, "line": "No, I know.", "order": 57371, "speaker": "Casey"},
  {"season": 9, "episode": 22, "scene": 46, "line": "No, no, no. I'm wearing makeup.", "order": 57372, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 22, "scene": 46, "line": "You did a really great job. You even look shorter.", "order": 57373, "speaker": "Casey"},
  {"season": 9, "episode": 22, "scene": 46, "line": "Oh, I took out my lifts.", "order": 57374, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 22, "scene": 46, "line": "Oh.", "order": 57375, "speaker": "Casey"},
  {"season": 9, "episode": 22, "scene": 46, "line": "Yeah, unlike Andy Bernard, this character is my real height.", "order": 57376, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 22, "scene": 46, "line": "Oh.", "order": 57377, "speaker": "Casey"},
  {"season": 9, "episode": 22, "scene": 47, "line": "All day long, it's moo the cows and cluck the hens. Get the sheep baa-ed. Oink the pigs.", "order": 57378, "speaker": "Esther"},
  {"season": 9, "episode": 22, "scene": 47, "line": "Oink the pigs, that is very important. [watching Phillip]", "order": 57379, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 22, "scene": 47, "line": "Dwight, I'm telling you about all the things that ma said after the horse kicked her in the head. Where are you? Dwight?", "order": 57380, "speaker": "Esther"},
  {"season": 9, "episode": 22, "scene": 48, "line": "The way that boy looks at the Galactica is precisely the way I look at the Galactica. And he eats the same kind of paper I do. Hmm.", "order": 57381, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 22, "scene": 49, "line": "[puts his grandmother's ring away] Thank you, Esther.", "order": 57382, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 22, "scene": 49, "line": "Bye.", "order": 57383, "speaker": "Esther"},
  {"season": 9, "episode": 22, "scene": 49, "line": "You threw the summoning bag at me, sir?", "order": 57384, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 22, "scene": 49, "line": "I need you to perform a test.", "order": 57385, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 22, "scene": 49, "line": "Perform a test.", "order": 57386, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 22, "scene": 49, "line": "On an innocent baby.", "order": 57387, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 22, "scene": 49, "line": "Ooh, I like where this is going. Unfortunately I have a lot of work today so I'm gonna have to hand this off to my number two. But, don't worry, he's the best in the biz.", "order": 57388, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 22, "scene": 49, "line": "Damn straight.", "order": 57389, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 22, "scene": 49, "line": "Unless you think he can't handle it.", "order": 57390, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 22, "scene": 49, "line": "Hey, he can handle it.", "order": 57391, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 22, "scene": 49, "line": "All right.", "order": 57392, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 22, "scene": 50, "line": "[holding Phillip] Listen, listen. Shh, buddy. Stanley's sleeping. You don't want to wake up the grumpy old walrus, do you?", "order": 57393, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 22, "scene": 50, "line": "I heard that.", "order": 57394, "speaker": "Stanley Hudson"},
  {"season": 9, "episode": 22, "scene": 50, "line": "Mama.", "order": 57395, "speaker": "Phillip"},
  {"season": 9, "episode": 22, "scene": 50, "line": "Hey, you want me to take the little diaper blaster? Pam can attest, there's no one better at getting brats to shut their yaps.", "order": 57396, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 22, "scene": 50, "line": "He does have a gift.", "order": 57397, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 22, "scene": 50, "line": "Well, he's calling for his mom, but, okay, here, here you go. Careful, he bites.", "order": 57398, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 22, "scene": 50, "line": "Mama.", "order": 57399, "speaker": "Phillip"},
  {"season": 9, "episode": 22, "scene": 50, "line": "Okay. [takes Phillip who immediately stops crying] You ever been in a manager's office before?", "order": 57400, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 22, "scene": 51, "line": "Phillip, you wanna play a little game? It's called 'Schrute or Consequences.' You're gonna choose one of these two things. A check for a million dollars, or this dirty old beet. Yuck, pew! Which will it be? Money or the beet?", "order": 57401, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 22, "scene": 51, "line": "Beet.", "order": 57402, "speaker": "Phillip"},
  {"season": 9, "episode": 22, "scene": 51, "line": "Yeah. Any ordinary child would have taken the money, but you're no ordinary child are you? No. I can tell by your gorgeous, widely-set eyes.", "order": 57403, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 22, "scene": 52, "line": "Sorry, folks, the judges are totally swamped. We are all done taking auditions.", "order": 57404, "speaker": "Check-in guy"},
  {"season": 9, "episode": 22, "scene": 52, "line": "Oh come on! Hey!", "order": 57405, "speaker": "People in line"},
  {"season": 9, "episode": 22, "scene": 52, "line": "Whoa, whoa, whoa, whoa, what?", "order": 57406, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 22, "scene": 52, "line": "But thanks for coming out and be sure to watch America's Next A Cappella Sensa'", "order": 57407, "speaker": "Check-in guy"},
  {"season": 9, "episode": 22, "scene": 52, "line": "Whoa, whoa, whoa, whoa. You can't do that. You can't do that, we've all been waiting.", "order": 57408, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 22, "scene": 52, "line": "Okay.", "order": 57409, "speaker": "Check-in guy"},
  {"season": 9, "episode": 22, "scene": 52, "line": "I am going in there! Don't'", "order": 57410, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 22, "scene": 52, "line": "No, you're not, sir.", "order": 57411, "speaker": "Check-in guy"},
  {"season": 9, "episode": 22, "scene": 52, "line": "Don't'don't'", "order": 57412, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 22, "scene": 52, "line": "Please don't.", "order": 57413, "speaker": "Check-in guy"},
  {"season": 9, "episode": 22, "scene": 52, "line": "Don't touch me.", "order": 57414, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 22, "scene": 52, "line": "I'm not touching you. Okay? [Andy makes a break for it]", "order": 57415, "speaker": "Check-in guy"},
  {"season": 9, "episode": 22, "scene": 52, "line": "Run, old man! Run! Run! Run for your life! Get off. Don't you dare. Don't get'I could see it. You were gonna get handsy with me. Not interested. I'm Casey Dean! You'll be seeing the last of me. Er, I meant you won't be seeing the last of me!", "order": 57416, "speaker": "Casey"},
  {"season": 9, "episode": 22, "scene": 53, "line": "Hey. Did you manage to feed him? I don't know what it is. He just keeps spitting out the nipple.", "order": 57417, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 22, "scene": 53, "line": "That is because this baby is of superior intelligence. He can tell when he's being tricked out of the experience of a real human breast.", "order": 57418, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 22, "scene": 53, "line": "He's not that smart. He doesn't know where I hid his duck.", "order": 57419, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 22, "scene": 54, "line": "[interrupting contestant singing 'Beautiful' by Christina Aguilera] Hi. You're still here. Oh, thank god. Clay Aiken, Santigold, Aaron Rodgers. You're like, my three favorite people ever.", "order": 57420, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 22, "scene": 54, "line": "What is this?", "order": 57421, "speaker": "Santigold"},
  {"season": 9, "episode": 22, "scene": 54, "line": "[shushing contestant] Sweetheart, you're amazing, okay? You're obviously gonna be on the show, so it's someone else's turn now. Yeah, go ahead. All right, [old man voice] my name is Ezra Cornell and I'm just a kindly old fellow with a song in my heart.", "order": 57422, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 22, "scene": 54, "line": "No, you're a middle-aged man with a lot of makeup on.", "order": 57423, "speaker": "Aaron Rodgers"},
  {"season": 9, "episode": 22, "scene": 54, "line": "Busted. Yes, got it. All right. Tried to get your attention with tricks, but you just want to hear me sing, I respect that.", "order": 57424, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 22, "scene": 54, "line": "No, we do not want to hear you sing.", "order": 57425, "speaker": "Clay Aiken"},
  {"season": 9, "episode": 22, "scene": 54, "line": "Gabriella was our last audition. Thank you. Goodbye.", "order": 57426, "speaker": "Santigold"},
  {"season": 9, "episode": 22, "scene": 54, "line": "Nope! Can't end like this. Slept in my car last night, quit my job, burned all my bridges. I went to the bathroom on my boss's car. And I did unspeakable things with Carla Fern.", "order": 57427, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 22, "scene": 54, "line": "Flag on the play.", "order": 57428, "speaker": "Aaron Rodgers"},
  {"season": 9, "episode": 22, "scene": 54, "line": "That's'that's what'yeah. Okay, all right, well, here's the song. [singing] Far above Cayuga's waters, with her waves so blue, stands our noble alma mater'", "order": 57429, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 22, "scene": 54, "line": "What is this song?", "order": 57430, "speaker": "Santigold"},
  {"season": 9, "episode": 22, "scene": 54, "line": "Are you insane? It's the Cornell fight song.", "order": 57431, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 22, "scene": 54, "line": "Listen, all right, thank you very much, we're not interested.", "order": 57432, "speaker": "Clay Aiken"},
  {"season": 9, "episode": 22, "scene": 54, "line": "You didn't let me finish. That's not fair.", "order": 57433, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 22, "scene": 54, "line": "Look, man, you're not terrible. We've heard a lot of really good singers today and you're just not good enough.", "order": 57434, "speaker": "Aaron Rodgers"},
  {"season": 9, "episode": 22, "scene": 54, "line": "Wow, you guys are really mean. I guess that's the show. Let me try a different song, okay?", "order": 57435, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 22, "scene": 54, "line": "Can he do this?", "order": 57436, "speaker": "Aaron Rodgers"},
  {"season": 9, "episode": 22, "scene": 54, "line": "[singing] Hey, hobo man, hey, dapper Dan, you both got your style, but, brother, you're never fully dressed without a smile. [falters at the judges' reactions] Yeah. Your clothes may be beau brummelly'", "order": 57437, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 22, "scene": 54, "line": "Look, you gotta go. [Andy falls to his knees, sobbing] You can't just sit here and cry.", "order": 57438, "speaker": "Santigold"},
  {"season": 9, "episode": 22, "scene": 54, "line": "Oh I can so just sit here and cry!", "order": 57439, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 22, "scene": 55, "line": "[addressing camera after opening envelope on his desk] Thank you.", "order": 57440, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 22, "scene": 56, "line": "Hey, Kev, how you doing, buddy?", "order": 57441, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 22, "scene": 56, "line": "Can't hear you. I'm giving you the silence treatment. How does it feel being ignored?", "order": 57442, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 22, "scene": 56, "line": "Okay, I guess, it's just that Phillip got you something.", "order": 57443, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 22, "scene": 56, "line": "Yeah, a $25 gift card, iTunes.", "order": 57444, "speaker": "Angela Martin"},
  {"season": 9, "episode": 22, "scene": 56, "line": "I think there's, like, $7 left.", "order": 57445, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 22, "scene": 56, "line": "It's just his way of saying, 'Thanks for letting me hang out in Accounting.'", "order": 57446, "speaker": "Angela Martin"},
  {"season": 9, "episode": 22, "scene": 56, "line": "Phillip got this for me?", "order": 57447, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 22, "scene": 56, "line": "Sure.", "order": 57448, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 22, "scene": 56, "line": "That was a really cool move.", "order": 57449, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 22, "scene": 56, "line": "Would you like to hold Phillip? [hands off Phillip] Yeah. That's Kevin.", "order": 57450, "speaker": "Angela Martin"},
  {"season": 9, "episode": 22, "scene": 56, "line": "Whoa.", "order": 57451, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 22, "scene": 56, "line": "Easy.", "order": 57452, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 22, "scene": 56, "line": "What a chubbers. Whoa.", "order": 57453, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 22, "scene": 56, "line": "Okay, watch it.", "order": 57454, "speaker": "Angela Martin"},
  {"season": 9, "episode": 22, "scene": 56, "line": "I'm losing my balance.", "order": 57455, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 22, "scene": 56, "line": "No, Kevin, no.", "order": 57456, "speaker": "Angela Martin"},
  {"season": 9, "episode": 22, "scene": 56, "line": "Hey, no.", "order": 57457, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 22, "scene": 56, "line": "Whoa!", "order": 57458, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 22, "scene": 56, "line": "No horseplay.", "order": 57459, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 22, "scene": 56, "line": "Stop it.", "order": 57460, "speaker": "Angela Martin"},
  {"season": 9, "episode": 22, "scene": 56, "line": "You wanna play with the cactus?", "order": 57461, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 22, "scene": 56, "line": "No, no!", "order": 57462, "speaker": "Angela Martin"},
  {"season": 9, "episode": 22, "scene": 57, "line": "So, me and Phillip were just talking and we decided we're gonna be best friends. He's a little standoffish at first. But once he starts buying you things, man, you can tell he likes you.", "order": 57463, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 22, "scene": 58, "line": "We have our decision.", "order": 57464, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 22, "scene": 58, "line": "You chose one thing?", "order": 57465, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 22, "scene": 58, "line": "We want to dance with you.", "order": 57466, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 22, "scene": 58, "line": "You want to dance?", "order": 57467, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 22, "scene": 58, "line": "One dance, all of us together.", "order": 57468, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 22, "scene": 58, "line": "This is what you want?", "order": 57469, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 22, "scene": 58, "line": "Absolutely.", "order": 57470, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 22, "scene": 58, "line": "Better get some decent speakers up here then, cause we're gonna do this right.", "order": 57471, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 22, "scene": 58, "line": "Yes! [Stanley, Creed, Meredith and Creed all cheer]", "order": 57472, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 22, "scene": 59, "line": "Hey. You wanted to see me?", "order": 57473, "speaker": "Angela Martin"},
  {"season": 9, "episode": 22, "scene": 59, "line": "Door. Chair. It's about Phillip.", "order": 57474, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 22, "scene": 59, "line": "I am sorry he's here today but I had'", "order": 57475, "speaker": "Angela Martin"},
  {"season": 9, "episode": 22, "scene": 59, "line": "I believe that boy may be a Schrute. And if he is, that child needs to be accorded what is his. An enormous farm, an inheritance, and the right to be raised under rigorous Schrute traditions. You will, of course, be compensated with a marriage proposal.", "order": 57476, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 22, "scene": 59, "line": "How thoughtful.", "order": 57477, "speaker": "Angela Martin"},
  {"season": 9, "episode": 22, "scene": 59, "line": "Then the two of you would move to my 1,600-acre estate, which, let's face it, is a big step up from living in a gay man's closet.", "order": 57478, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 22, "scene": 59, "line": "If he is your son, that's a great plan. But he's not. He's not your son.", "order": 57479, "speaker": "Angela Martin"},
  {"season": 9, "episode": 22, "scene": 59, "line": "Very well.", "order": 57480, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 22, "scene": 59, "line": "Can I go back to my desk now?", "order": 57481, "speaker": "Angela Martin"},
  {"season": 9, "episode": 22, "scene": 59, "line": "Yes.", "order": 57482, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 22, "scene": 60, "line": "What is this?", "order": 57483, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 22, "scene": 60, "line": "Well I've been trying to tell you how I feel, and you wouldn't believe me, so [shows her DVD] I needed a little help.", "order": 57484, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 22, "scene": 60, "line": "Jim! I need my assistant to the regional manager. Code red.", "order": 57485, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 22, "scene": 60, "line": "OK, I don't have my pocket code chart on me, right now, so.", "order": 57486, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 22, "scene": 60, "line": "[throws 'now' beanbag at Jim] Now.", "order": 57487, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 22, "scene": 60, "line": "I have an assistant now, who can help you with whatever you need. He is lazy, so crack the whip.", "order": 57488, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 22, "scene": 60, "line": "Jim. I'm not kidding. I need you.", "order": 57489, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 22, "scene": 60, "line": "Go ahead.", "order": 57490, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 22, "scene": 60, "line": "Ok, um, this is' [leaves DVD with her]'I'll be right back.", "order": 57491, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 22, "scene": 61, "line": "What do we got?", "order": 57492, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 22, "scene": 61, "line": "I was thinking of proposing to Esther today.", "order": 57493, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 22, "scene": 61, "line": "Wow! Congratulations, that's a really big step.", "order": 57494, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 22, "scene": 61, "line": "She's got a ton of great qualities. She's young, she's beautiful, genes so pure you could lick them. Her family admires me, my family tolerates hers. A lot of them are the same people because we're third cousins, which is great for bloodlines and isn't technically incest.", "order": 57495, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 22, "scene": 61, "line": "Right in the sweet spot. I think you're gonna be really happy.", "order": 57496, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 22, "scene": 61, "line": "Plus her dowry contains a walk-in freezer full of frozen, premium cattle sperm.", "order": 57497, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 22, "scene": 61, "line": "[whistles] That's a lot of pros.", "order": 57498, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 22, "scene": 61, "line": "And did I mention that she weaves? Colorful, durable blankets and rugs! It all adds up.", "order": 57499, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 22, "scene": 61, "line": "So what is the problem?", "order": 57500, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 22, "scene": 61, "line": "Angela.", "order": 57501, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 22, "scene": 62, "line": "I don't know what you want me to tell you, man. All I know is that every time I've been faced with a tough decision, there's only one thing that outweighs every other concern. One thing that will make you give up on everything you thought you knew, every instinct, every rational calculation.", "order": 57502, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 22, "scene": 62, "line": "Some sort of virus?", "order": 57503, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 22, "scene": 62, "line": "Love.", "order": 57504, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 22, "scene": 62, "line": "Oh.", "order": 57505, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 22, "scene": 63, "line": "Dwight, listen: no matter what happens, you gotta forget about all the other stuff. You gotta forget about logic and fear and doubt. You just gotta do everything you can to get to the one woman who's gonna make all this worth it. At the end of the day, you gotta jump. You love Angela, Dwight. I think you always have.", "order": 57506, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 22, "scene": 63, "line": "You're a good assistant, Jim.", "order": 57507, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 22, "scene": 63, "line": "Not as good as you.", "order": 57508, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 22, "scene": 63, "line": "That's very true. Get the hell outta here.", "order": 57509, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 22, "scene": 63, "line": "You got it.", "order": 57510, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 22, "scene": 64, "line": "You watched it.", "order": 57511, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 22, "scene": 64, "line": "Yeah.", "order": 57512, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 22, "scene": 64, "line": "Well, then I guess you're ready for this. [gives her the Christmas card]", "order": 57513, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 22, "scene": 64, "line": "What's that?", "order": 57514, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 22, "scene": 64, "line": "It's from the teapot. Everything you'll ever need to know is in that note. [Pam reads the card] Not enough for me? You are everything.", "order": 57515, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 22, "scene": 64, "line": "Thank you.", "order": 57516, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 22, "scene": 65, "line": "Ok, everybody ready?", "order": 57517, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 22, "scene": 65, "line": "Hit it, red! ['Boogie Wonderland' by Earth Wind & Fire plays as Darryl dances with each member of the office]", "order": 57518, "speaker": "Phyllis Vance"},
  {"season": 9, "episode": 22, "scene": 66, "line": "I wanted to leave quietly. It seemed dignified. But having Kevin grind up on my front while Erin pretends to hump me from behind is a more accurate tribute to my years here. I'm gonna miss these guys.", "order": 57519, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 22, "scene": 67, "line": "Ok, I've got my'", "order": 57520, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 22, "scene": 67, "line": "See you guys at Poor Richard's, all right?", "order": 57521, "speaker": "Meredith Palmer"},
  {"season": 9, "episode": 22, "scene": 67, "line": "All right, Meredith.", "order": 57522, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 22, "scene": 67, "line": "Okay, bye.", "order": 57523, "speaker": "Angela Martin"},
  {"season": 9, "episode": 22, "scene": 67, "line": "See you there.", "order": 57524, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 22, "scene": 67, "line": "Bye, Phillip. High five.", "order": 57525, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 22, "scene": 67, "line": "Yay! I am gonna drop Phillip off at my mother's, and I'll meet you at Poor Richard's in an hour.", "order": 57526, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 22, "scene": 67, "line": "Are you sure you don't want me to drop him off?", "order": 57527, "speaker": "Angela Martin"},
  {"season": 9, "episode": 22, "scene": 67, "line": "She doesn't know I'm living with a straight woman. I don't want to get her hopes up.", "order": 57528, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 22, "scene": 67, "line": "All right. Bye, buddy. Bye, bye, bye!", "order": 57529, "speaker": "Angela Martin"},
  {"season": 9, "episode": 22, "scene": 67, "line": "Oh, my goodness.", "order": 57530, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 22, "scene": 68, "line": "[in bullhorn] Pull over!", "order": 57531, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 22, "scene": 68, "line": "Dwight?", "order": 57532, "speaker": "Angela Martin"},
  {"season": 9, "episode": 22, "scene": 68, "line": "Move to the side of the road!", "order": 57533, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 22, "scene": 68, "line": "Why?", "order": 57534, "speaker": "Angela Martin"},
  {"season": 9, "episode": 22, "scene": 68, "line": "Pull over!", "order": 57535, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 22, "scene": 68, "line": "What do you'Dwight! [Dwight cuts her off in his car, they pull over]", "order": 57536, "speaker": "Angela Martin"},
  {"season": 9, "episode": 22, "scene": 69, "line": "[getting out of her car] Dwight! What the [bleep] is your problem!", "order": 57537, "speaker": "Angela Martin"},
  {"season": 9, "episode": 22, "scene": 69, "line": "[on bullhorn still] Shut up, woman!", "order": 57538, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 22, "scene": 69, "line": "Who drives like that?", "order": 57539, "speaker": "Angela Martin"},
  {"season": 9, "episode": 22, "scene": 69, "line": "Listen to me! I love you! And I don't care that Phillip's not my son. I will raise 100 children with 100 of your lovers if it means that I can be with you!", "order": 57540, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 22, "scene": 69, "line": "Can you put that down?", "order": 57541, "speaker": "Angela Martin"},
  {"season": 9, "episode": 22, "scene": 69, "line": "This expresses how loudly I love you.", "order": 57542, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 22, "scene": 69, "line": "It's too loud.", "order": 57543, "speaker": "Angela Martin"},
  {"season": 9, "episode": 22, "scene": 69, "line": "[puts down bullhorn and kneels] This is a ring, taken from the buttocks of my grandmother, put there by the gangster patriarch of the Coors dynasty, melted in a foundry run by Mennonites.", "order": 57544, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 22, "scene": 69, "line": "Okay, yes'yes, I will! [they kiss] I love you!", "order": 57545, "speaker": "Angela Martin"},
  {"season": 9, "episode": 22, "scene": 69, "line": "I love you!", "order": 57546, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 22, "scene": 69, "line": "And I lied to you.", "order": 57547, "speaker": "Angela Martin"},
  {"season": 9, "episode": 22, "scene": 69, "line": "What?", "order": 57548, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 22, "scene": 69, "line": "Phillip's your son.", "order": 57549, "speaker": "Angela Martin"},
  {"season": 9, "episode": 22, "scene": 69, "line": "What? Why would you say that'", "order": 57550, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 22, "scene": 69, "line": "I just needed you to want to marry me because you wanted to marry me.", "order": 57551, "speaker": "Angela Martin"},
  {"season": 9, "episode": 22, "scene": 69, "line": "[excited] Get out! I'm a dad!", "order": 57552, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 22, "scene": 69, "line": "You're a dad!", "order": 57553, "speaker": "Angela Martin"},
  {"season": 9, "episode": 22, "scene": 70, "line": "Hey, grab a seat. We have that table and that table, but not that table or that table or that table. Floor's up for grabs. Meredith has been hogging the can. [Dwight kisses Creed's head] Oh!", "order": 57554, "speaker": "Creed Bratton"},
  {"season": 9, "episode": 22, "scene": 70, "line": "You have to change the channel to PBS.", "order": 57555, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 22, "scene": 70, "line": "Yeah.", "order": 57556, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 22, "scene": 70, "line": "College baseball is on.", "order": 57557, "speaker": "Bartender"},
  {"season": 9, "episode": 22, "scene": 70, "line": "But there's a documentary coming up. Everyone in the bar will love it.", "order": 57558, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 22, "scene": 70, "line": "What's it about?", "order": 57559, "speaker": "Bartender"},
  {"season": 9, "episode": 22, "scene": 70, "line": "A paper company.", "order": 57560, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 22, "scene": 70, "line": "How many people want the game? [half the bar cheers] Who wants PBS? [other half cheers] Sorry. Tie means I do nothing.", "order": 57561, "speaker": "Bartender"},
  {"season": 9, "episode": 22, "scene": 70, "line": "Sir, please. This show is about me and my attempts to find love in all the wrong places.", "order": 57562, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 22, "scene": 70, "line": "One more for the doc. [the office staff cheers]", "order": 57563, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 22, "scene": 70, "line": "All right.", "order": 57564, "speaker": "Bartender"},
  {"season": 9, "episode": 22, "scene": 70, "line": "Yes!", "order": 57565, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 22, "scene": 70, "line": "Hey, how was the singing show audition?", "order": 57566, "speaker": "Clark Green"},
  {"season": 9, "episode": 22, "scene": 70, "line": "Oh. Eh, whatever. No big deal.", "order": 57567, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 22, "scene": 70, "line": "Ok, thirty seconds to showtime!", "order": 57568, "speaker": "Creed Bratton"},
  {"season": 9, "episode": 22, "scene": 70, "line": "I feel scared a little.", "order": 57569, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 22, "scene": 70, "line": "Yeah, I'm not ready for this.", "order": 57570, "speaker": "Phyllis Vance"},
  {"season": 9, "episode": 22, "scene": 70, "line": "No one is ready for this. You can't be ready for this. We don't even know what this is.", "order": 57571, "speaker": "Stanley Hudson"},
  {"season": 9, "episode": 22, "scene": 70, "line": "One thing we do know, nothing will ever be the same.", "order": 57572, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 22, "scene": 70, "line": "Here we go. [documentary starts with the first scene of 'Pilot']", "order": 57573, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 23, "scene": 1, "line": "The documentary series finished airing ages ago. Why is PBS sending another crew?", "order": 57574, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 23, "scene": 1, "line": "We're getting bonus footage for the DVD.", "order": 57575, "speaker": "CameraMan"},
  {"season": 9, "episode": 23, "scene": 1, "line": "pff, Nobody buys DVDs anymore.", "order": 57576, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 23, "scene": 1, "line": "It'll be a pledge gift.", "order": 57577, "speaker": "CameraMan"},
  {"season": 9, "episode": 23, "scene": 1, "line": "PBS. The propaganda wing of Bill and Melinda Gates and viewers like you.", "order": 57578, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 23, "scene": 2, "line": "In the past year, I have consolidated the entire Scranton paper market. We regained the white pages, the school district, Lackawanna county. We supply them all. I'm getting married tomorrow afternoon, and in the morning, there's a mini-reunion. A kind of a 'where are they now' panel at a local theatre. It'll be nice to see everyone again. [laughs] I haven't seen Kevin since we let him go.", "order": 57579, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 23, "scene": 3, "line": "[mimicking trumpet] Today marks several important milestones. Stanley, as you know, is retiring.", "order": 57580, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 23, "scene": 3, "line": "Yes! Whoo, whoo, whoo!", "order": 57581, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 23, "scene": 3, "line": "Ah, I've been looking forward to this day since I was 18 years old.", "order": 57582, "speaker": "Stanley Hudson"},
  {"season": 9, "episode": 23, "scene": 3, "line": "Speech!", "order": 57583, "speaker": "Meredith Palmer"},
  {"season": 9, "episode": 23, "scene": 3, "line": "No! And our next and most thickly frosted cake is'for'Kevin.", "order": 57584, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 23, "scene": 3, "line": "Yes! Wait, why?", "order": 57585, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 23, "scene": 3, "line": "Go ahead and just read the frosting.", "order": 57586, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 23, "scene": 3, "line": "Get out.'", "order": 57587, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 23, "scene": 3, "line": "Uh-huh.", "order": 57588, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 23, "scene": 3, "line": "What does that mean?", "order": 57589, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 23, "scene": 3, "line": "It's a colloquial way of saying 'you're fired,' Kevin, which you are.", "order": 57590, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 23, "scene": 3, "line": "What? Dwight, you can't do that.", "order": 57591, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 23, "scene": 3, "line": "The cake has spoken Pam. Sorry.", "order": 57592, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 23, "scene": 3, "line": "What?!", "order": 57593, "speaker": "All"},
  {"season": 9, "episode": 23, "scene": 3, "line": "Well if anyone here can make a case for Kevin staying.", "order": 57594, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 23, "scene": 3, "line": "Dwight'[overlapping objections]", "order": 57595, "speaker": "All"},
  {"season": 9, "episode": 23, "scene": 3, "line": "Based'on his merit.", "order": 57596, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 23, "scene": 3, "line": "Ooh.", "order": 57597, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 23, "scene": 3, "line": "Umm'.", "order": 57598, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 23, "scene": 3, "line": "I'm'good.", "order": 57599, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 23, "scene": 3, "line": "Well, Toby will stop it. Anytime anyone's ever been fired, Toby's blocked it, so'", "order": 57600, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 23, "scene": 3, "line": "Yeah. Yeah, I don't think'", "order": 57601, "speaker": "Toby Flenderson"},
  {"season": 9, "episode": 23, "scene": 3, "line": "Toby, wait. Wait. Hold that thought. Here's your cake. [squirts frosting on the top] Bye, bye Toby.", "order": 57602, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 23, "scene": 3, "line": "[crying] At least I got chocolate.", "order": 57603, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 23, "scene": 4, "line": "I bike to work now. Saves on gas, cheaper than a vasectomy and, uh, oh, yeah, it's good for the environment too.", "order": 57604, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 23, "scene": 5, "line": "Pam and I are great. She just recently finished her mural for the Irish cultural center.", "order": 57605, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 23, "scene": 6, "line": "Whoo! Yeah", "order": 57606, "speaker": "Crowd"},
  {"season": 9, "episode": 23, "scene": 6, "line": "[to Cici] Can you clap! Can you clap for mom?", "order": 57607, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 23, "scene": 7, "line": "And Dwight is imitating Japanese business practices for reasons he explained to us in Japanese.", "order": 57608, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 23, "scene": 8, "line": "[whispering] Angela, are you ready for the wedding?", "order": 57609, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 23, "scene": 8, "line": "[whispering] Yes. My heart is so open, I am so at peace. [scoffs] Look at Meredith. She's disgusting. Those feet. They're like the paws of an orangutan.", "order": 57610, "speaker": "Angela Martin"},
  {"season": 9, "episode": 23, "scene": 8, "line": "I think she looks good. Now that she's wearing sports bras, we don't see her boobs as much.", "order": 57611, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 23, "scene": 8, "line": "That is all. Have a good morning.", "order": 57612, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 23, "scene": 8, "line": "Thank you.", "order": 57613, "speaker": "Meredith Palmer"},
  {"season": 9, "episode": 23, "scene": 8, "line": "[after Angela kisses Dwight] What was that for?", "order": 57614, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 23, "scene": 8, "line": "[laughing] To remind you that our wedding's gonna be wonderful.", "order": 57615, "speaker": "Angela Martin"},
  {"season": 9, "episode": 23, "scene": 8, "line": "Ah. I know. It just feels so empty with so many of the old gang gone.", "order": 57616, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 23, "scene": 8, "line": "D, it's gonna be perfect. The only people that need to be there are you and me.", "order": 57617, "speaker": "Angela Martin"},
  {"season": 9, "episode": 23, "scene": 8, "line": "Oh, and the old man to feed us the cheese that he's been fermenting since the day of my birth. You keep forgetting about him.", "order": 57618, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 23, "scene": 8, "line": "I don't'I don't know why.", "order": 57619, "speaker": "Angela Martin"},
  {"season": 9, "episode": 23, "scene": 9, "line": "I brought in some new faces, and one old. I always like Devon. I hired him back after Creed faked his own death in the baler the day after the doc aired. The only person he fooled was Kevin. Then the police showed up. Turns out, Creed was in the band 'The Grass Roots' in the 1960's. During that time, the police say he sold drugs and trafficked in endangered species meat and stole weapons-grade LSD from the military.", "order": 57620, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 23, "scene": 10, "line": "Oh, some fudge?", "order": 57621, "speaker": "Phyllis Vance"},
  {"season": 9, "episode": 23, "scene": 10, "line": "Oh, thanks. I love your fudge.", "order": 57622, "speaker": "Malcolm"},
  {"season": 9, "episode": 23, "scene": 10, "line": "Thanks.", "order": 57623, "speaker": "Phyllis Vance"},
  {"season": 9, "episode": 23, "scene": 10, "line": "I think I gained a couple of pounds since I got here.", "order": 57624, "speaker": "Malcolm"},
  {"season": 9, "episode": 23, "scene": 10, "line": "[laughing] Oh, you can afford it.", "order": 57625, "speaker": "Phyllis Vance"},
  {"season": 9, "episode": 23, "scene": 11, "line": "After 16 years, it's strange sitting across from somebody who isn't Stanley. But'he'll get there.", "order": 57626, "speaker": "Phyllis Vance"},
  {"season": 9, "episode": 23, "scene": 12, "line": "Okay, the limo's gonna be here at five. I need everybody to be ready 'cause I want to pack in a lot.", "order": 57627, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 23, "scene": 12, "line": "Party time! Whorehouse!", "order": 57628, "speaker": "Zeke"},
  {"season": 9, "episode": 23, "scene": 12, "line": "Uh, no. No whorehouse. This is Dwight's night, okay?", "order": 57629, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 23, "scene": 12, "line": "Well, you're the bestisch mensch.", "order": 57630, "speaker": "Zeke"},
  {"season": 9, "episode": 23, "scene": 13, "line": "Dwight has made me his bestisch mensch. Which is Schrute for best man. He's putting himself entirely in my hands tonight. And I know for over 12 years I've done nothing but trick and prank him but tonight'only good surprises. 'Guten Pranken'. [chuckles]", "order": 57631, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 23, "scene": 14, "line": "Oh hey, Jim. I forgot to mention. Oftentimes, in Hollywood portrayals of bachelor parties, there are accidental murders. That won't be necessary tonight.", "order": 57632, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 23, "scene": 14, "line": "Great, now we got three hours to fill.", "order": 57633, "speaker": "Clark Green"},
  {"season": 9, "episode": 23, "scene": 14, "line": "Okay, hold on. Are you sure Mose isn't going to show up?", "order": 57634, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 23, "scene": 14, "line": "Ever since Angela moved in and Mose had to stop sleeping at the foot of my bed, he's been acting pretty weird about this whole wedding thing.", "order": 57635, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 23, "scene": 14, "line": "Mose has been weird? That's so unlike him.", "order": 57636, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 23, "scene": 15, "line": "Yeah, sure, I'll talk about it. Why not? American's next A Cappella Sensation aired my audition. And when I started sobbing uncontrollably, apparently that struck a chord with quite a lot of people. Not a very compassionate chord. The clip went viral, as they say", "order": 57637, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 23, "scene": 16, "line": "You can't just sit here and cry.", "order": 57638, "speaker": "Casey Dean"},
  {"season": 9, "episode": 23, "scene": 16, "line": "Oh, I can so just sit here and cry!", "order": 57639, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 23, "scene": 17, "line": "Two million hits in the first week and then the parodies started. One from the Philippines got 12 million hits. And the late night comedy guys had a field day with it.", "order": 57640, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 23, "scene": 18, "line": "[fake crying]", "order": 57641, "speaker": "Bill Hader"},
  {"season": 9, "episode": 23, "scene": 18, "line": "Oh, Don't. Aw, come on, Baby Wawa. Don't be such a baby.", "order": 57642, "speaker": "Seth Mayers"},
  {"season": 9, "episode": 23, "scene": 18, "line": "[crying and pouring eye drops in his eyes]", "order": 57643, "speaker": "Bill Hader"},
  {"season": 9, "episode": 23, "scene": 18, "line": "Oh, No. It's gonna be alright.", "order": 57644, "speaker": "Seth Mayers"},
  {"season": 9, "episode": 23, "scene": 18, "line": "[pulling multiple tissues out]", "order": 57645, "speaker": "Bill Hader"},
  {"season": 9, "episode": 23, "scene": 18, "line": "It's gonna be all right. For Weekend Update I'm Seth Meyers. That's Baby Wawa. Good night.", "order": 57646, "speaker": "Seth Mayers"},
  {"season": 9, "episode": 23, "scene": 18, "line": "[still crying] Bye!", "order": 57647, "speaker": "Bill Hader"},
  {"season": 9, "episode": 23, "scene": 19, "line": "After my clip blew up, I actually got a call from the double rainbow guy and the fat Star Wars kid. Turns out they have a support group. [pause] Not really my scene.", "order": 57648, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 23, "scene": 20, "line": "[on the phone] Years ago, the senator promised a left turn lane by the Arby's. So I wanna know where in the name of horsey sauce is it? Well, yeah, you'hold on.", "order": 57649, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 23, "scene": 20, "line": "Hi. I keep seeing this symbol in the accounts from last year. It's..it's all over the place. I don't know what it means.", "order": 57650, "speaker": "Dakota"},
  {"season": 9, "episode": 23, "scene": 20, "line": "That's the reason Kevin got fired. It's his magic number. He used to use it to balance his accounts. He used to call it a Keleven. He told Dwight, [imitating Kevin] 'A mistake plus Keleven gets you home by seven.' He was home by 4:45 that day.", "order": 57651, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 23, "scene": 21, "line": "Oh, I live in Poland now. The Scranton of the E.U. Thank you for flying me out here for the weekend. I'm looking forward to the reunion panel tomorrow. Can't wait to see everyone. Well, almost everyone.", "order": 57652, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 23, "scene": 22, "line": "Hello! [laughing] Hello!", "order": 57653, "speaker": "Toby Flenderson"},
  {"season": 9, "episode": 23, "scene": 22, "line": "Oh.", "order": 57654, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 23, "scene": 22, "line": "Hi! [goes to hug Nellie]", "order": 57655, "speaker": "Toby Flenderson"},
  {"season": 9, "episode": 23, "scene": 22, "line": "No.", "order": 57656, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 23, "scene": 22, "line": "Oh. [laughs nervously]", "order": 57657, "speaker": "Toby Flenderson"},
  {"season": 9, "episode": 23, "scene": 22, "line": "How did you now my plane had arrived? How long have you been stalking me?", "order": 57658, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 23, "scene": 22, "line": "Oh, no, no. no. My plane just got in. From New York. Are you still with Piotr?", "order": 57659, "speaker": "Toby Flenderson"},
  {"season": 9, "episode": 23, "scene": 22, "line": "No. And I thought I unfriended you.", "order": 57660, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 23, "scene": 22, "line": "Anyone can follow a Twitter feed. Wanna share a cab?", "order": 57661, "speaker": "Toby Flenderson"},
  {"season": 9, "episode": 23, "scene": 23, "line": "[alone in cab} After Dwight fired me, I moved to New York to write the great American novel. I have six roommates. Heh. Which are better than friends, you know 'cause they have to give you one month's notice before they leave.", "order": 57662, "speaker": "Toby Flenderson"},
  {"season": 9, "episode": 23, "scene": 24, "line": "Hey-OH!", "order": 57663, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 23, "scene": 24, "line": "Andy?", "order": 57664, "speaker": "Meredith Palmer"},
  {"season": 9, "episode": 23, "scene": 24, "line": "You're back?", "order": 57665, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 23, "scene": 24, "line": "Yeah.", "order": 57666, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 23, "scene": 24, "line": "[whispering to Phyllis] That's Baby Wawa, right? Oh, my God!", "order": 57667, "speaker": "Malcolm"},
  {"season": 9, "episode": 23, "scene": 24, "line": "Hey man, good to see you.", "order": 57668, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 23, "scene": 24, "line": "Thank you. Yeah,", "order": 57669, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 23, "scene": 24, "line": "[hugging Andy]. Andy'", "order": 57670, "speaker": "Phyllis Vance"},
  {"season": 9, "episode": 23, "scene": 24, "line": "Hey! Aw! A bear hug from my favorite mama grizzly.", "order": 57671, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 23, "scene": 24, "line": "Andy, I've been worried about you. How are you?", "order": 57672, "speaker": "Phyllis Vance"},
  {"season": 9, "episode": 23, "scene": 24, "line": "A little warm.", "order": 57673, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 23, "scene": 24, "line": "Poor, poor Andy.", "order": 57674, "speaker": "Phyllis Vance"},
  {"season": 9, "episode": 23, "scene": 24, "line": "[struggling] Okay. Aw. Thank you Phyllis.", "order": 57675, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 23, "scene": 24, "line": "Hello, hello.", "order": 57676, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 23, "scene": 24, "line": "Hey!", "order": 57677, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 23, "scene": 24, "line": "Darryl!", "order": 57678, "speaker": "Meredith Palmer"},
  {"season": 9, "episode": 23, "scene": 24, "line": "Hey!", "order": 57679, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 23, "scene": 24, "line": "Hey, what's up?", "order": 57680, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 23, "scene": 24, "line": "Didn't I just see you at the airport jumping in a limo?", "order": 57681, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 23, "scene": 24, "line": "What? Must've been another devilishly handsome debonair individual.", "order": 57682, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 23, "scene": 24, "line": "Hmm.", "order": 57683, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 23, "scene": 24, "line": "Hey, man. How are you doing? I, um'I didn't call 'cause I figured you changed the number.", "order": 57684, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 23, "scene": 24, "line": "No I didn't change the number. All good though. Phone never rings.", "order": 57685, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 23, "scene": 24, "line": "What? Ooh!", "order": 57686, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 23, "scene": 24, "line": "Surprise!", "order": 57687, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 23, "scene": 24, "line": "I thought you guys couldn't come.", "order": 57688, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 23, "scene": 24, "line": "Yeah, but then they moved the panel to the same weekend and the Doc crew paid to fly us in. It was kismet.", "order": 57689, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 23, "scene": 25, "line": "Kismet? Yeah, right. Pam and I came up with excuses for every other weekend. You remember my two lap band surgeries, right? Neither do I? 'Guten Prank' number one.", "order": 57690, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 23, "scene": 26, "line": "You ready for tonight? We gonna tear up the town?", "order": 57691, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 23, "scene": 26, "line": "Uh, better ask Jim.", "order": 57692, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 23, "scene": 26, "line": "And Jim will say nothing.", "order": 57693, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 23, "scene": 26, "line": "[enters] Darryl! Andy!", "order": 57694, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 23, "scene": 26, "line": "Hey! Pam!", "order": 57695, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 23, "scene": 26, "line": "Hey, Pam! How are you?", "order": 57696, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 23, "scene": 26, "line": "We're fine. Yeah. [hugs both Darryl and Andy] And I'm sure that you guys are fine too. Because why wouldn't you be?", "order": 57697, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 23, "scene": 26, "line": "Mm-hmm.", "order": 57698, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 23, "scene": 26, "line": "Darryl, oh, my gosh. How is Austin? Tell me everything. How's the merger? I feel like I read about Athlead all the time.", "order": 57699, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 23, "scene": 26, "line": "I love it. And it's Athleap now. And the city is amazing.", "order": 57700, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 23, "scene": 26, "line": "Yeah?", "order": 57701, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 23, "scene": 26, "line": "Yeah. Yeah, it's hot. The music is awesome. And the tacos are'for real.", "order": 57702, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 23, "scene": 26, "line": "Wow! That sounds incredible. [checking cell phone]. Oh, guys. Limo's here. Let's do this. Change if you need to.", "order": 57703, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 23, "scene": 26, "line": "Whoo, whoo, whoo.", "order": 57704, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 23, "scene": 26, "line": "Okay, you guys, have fun. You too, Andy.", "order": 57705, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 23, "scene": 27, "line": "Yeah!", "order": 57706, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 23, "scene": 27, "line": "Aw!", "order": 57707, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 23, "scene": 27, "line": "Woah-Oh! Haven't been in one of these in forever.", "order": 57708, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 23, "scene": 28, "line": "They wanted me to go to the bachelorette party with the girls. Really? Such a clich. I'm a man. So I'm going to the bachelor party with the boys. I just have to remember how I acted before I came out.", "order": 57709, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 23, "scene": 29, "line": "WASSUUUUP! [laughing]", "order": 57710, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 23, "scene": 30, "line": "Wait, why are we stopping? Jim, this isn't on the itinerary.", "order": 57711, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 23, "scene": 30, "line": "Get out.", "order": 57712, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 23, "scene": 30, "line": "Jim'wha' Come on! What'what are you gonna whack me, Jim?", "order": 57713, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 23, "scene": 30, "line": "No, Dwight. You'll be doing the whacking.", "order": 57714, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 23, "scene": 30, "line": "A bazooka. You remembered.", "order": 57715, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 23, "scene": 30, "line": "Of course I did.", "order": 57716, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 23, "scene": 30, "line": "[tearful laugh]. Get out of my way.", "order": 57717, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 23, "scene": 31, "line": "OH! [all laugh and applaud after Dwight fires the bazooka]", "order": 57718, "speaker": "All"},
  {"season": 9, "episode": 23, "scene": 31, "line": "Woo! Okay!", "order": 57719, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 23, "scene": 31, "line": "Yeah! [to camera] Guten Prank number two.", "order": 57720, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 23, "scene": 32, "line": "Private Room.", "order": 57721, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 23, "scene": 32, "line": "Yeah, brosef.", "order": 57722, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 23, "scene": 32, "line": "Amazing.", "order": 57723, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 23, "scene": 32, "line": "Enjoy, enjoy.", "order": 57724, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 23, "scene": 32, "line": "Hey, I know you. Are you gonna sit here and cry?", "order": 57725, "speaker": "Guy"},
  {"season": 9, "episode": 23, "scene": 32, "line": "Okay, man, easy.", "order": 57726, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 23, "scene": 32, "line": "[fake cries]", "order": 57727, "speaker": "Guy"},
  {"season": 9, "episode": 23, "scene": 32, "line": "Hey, dude, leave him alone. What are you doing?", "order": 57728, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 23, "scene": 32, "line": "Jim, it's fine. He'll just get you on his cell phone and then that'll go viral.", "order": 57729, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 23, "scene": 32, "line": "That happens a lot?", "order": 57730, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 23, "scene": 32, "line": "Yeah, I guess. But things are going well actually. I spoke at Cornell during commencement week. I mean, the seniors invited me as a joke but it was a huge success.", "order": 57731, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 23, "scene": 32, "line": "Well, I bet it was a smash.", "order": 57732, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 23, "scene": 32, "line": "That's how I got my new job in the admissions office.", "order": 57733, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 23, "scene": 32, "line": "Is that a volunteer program or'", "order": 57734, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 23, "scene": 32, "line": "No. It's a job. Things are going great.", "order": 57735, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 23, "scene": 32, "line": "Let's get a drink in you, huh?", "order": 57736, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 23, "scene": 32, "line": "Yes.", "order": 57737, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 23, "scene": 33, "line": "Whoo-hoo. Let's get this party started!", "order": 57738, "speaker": "Meredith Palmer"},
  {"season": 9, "episode": 23, "scene": 33, "line": "Where's my angel.", "order": 57739, "speaker": "Rachel"},
  {"season": 9, "episode": 23, "scene": 34, "line": "This is my big sister Rachel.", "order": 57740, "speaker": "Angela Martin"},
  {"season": 9, "episode": 23, "scene": 34, "line": "No, this is my big sister Angela.", "order": 57741, "speaker": "Rachel"},
  {"season": 9, "episode": 23, "scene": 34, "line": "[laughs] We're very close. We even have our own special language.", "order": 57742, "speaker": "Angela Martin"},
  {"season": 9, "episode": 23, "scene": 35, "line": "[speaks in a special language]", "order": 57743, "speaker": "Rachel"},
  {"season": 9, "episode": 23, "scene": 36, "line": "People love it.", "order": 57744, "speaker": "Angela Martin"},
  {"season": 9, "episode": 23, "scene": 36, "line": "They do.", "order": 57745, "speaker": "Rachel"},
  {"season": 9, "episode": 23, "scene": 37, "line": "[sighs] Man, how long have we been sitting here?", "order": 57746, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 23, "scene": 37, "line": "Hi, boys!", "order": 57747, "speaker": "Stripper"},
  {"season": 9, "episode": 23, "scene": 37, "line": "Ohh'", "order": 57748, "speaker": "All"},
  {"season": 9, "episode": 23, "scene": 37, "line": "Here we go!", "order": 57749, "speaker": "Clark Green"},
  {"season": 9, "episode": 23, "scene": 37, "line": "Oh, Thank God. We are famished!", "order": 57750, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 23, "scene": 37, "line": "Hey, did somebody order the chef special?", "order": 57751, "speaker": "Stripper"},
  {"season": 9, "episode": 23, "scene": 37, "line": "Right there.", "order": 57752, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 23, "scene": 37, "line": "No, we haven't ordered anything. No one's even taken our drinks. Uh, what is the chef special? [music plays and stripper starts dancing on Dwight]", "order": 57753, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 23, "scene": 37, "line": "I bet you boys have a big appetite.", "order": 57754, "speaker": "Stripper"},
  {"season": 9, "episode": 23, "scene": 37, "line": "Oh, we do. So we'll have an onion loaf for the table, please. And tell us about your heartiest soups.", "order": 57755, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 23, "scene": 37, "line": "Mm, I know what you want. I know exactly what you want.", "order": 57756, "speaker": "Stripper"},
  {"season": 9, "episode": 23, "scene": 37, "line": "Yes, an onion loaf for the table but that's not all. Now the chef special sounded good. What is it exactly?", "order": 57757, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 23, "scene": 37, "line": "Ohh, shh. [places finger over Dwight's lips]", "order": 57758, "speaker": "Stripper"},
  {"season": 9, "episode": 23, "scene": 37, "line": "Okay, what are you doing? Are you giving me a taste of the chef's special? Tastes like cigarettes. That won't work. That's no good.", "order": 57759, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 23, "scene": 38, "line": "Whoo!", "order": 57760, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 23, "scene": 38, "line": "What? [knock at the door] Okay.", "order": 57761, "speaker": "Angela Martin"},
  {"season": 9, "episode": 23, "scene": 38, "line": "Oh, boy.", "order": 57762, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 23, "scene": 38, "line": "Oh!", "order": 57763, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 23, "scene": 38, "line": "Heard you guys needed some pipes fixed or cleaned or whatever.", "order": 57764, "speaker": "Jakey"},
  {"season": 9, "episode": 23, "scene": 38, "line": "Angela's special repairman is here.", "order": 57765, "speaker": "Phyllis Vance"},
  {"season": 9, "episode": 23, "scene": 38, "line": "Wait, what is this?", "order": 57766, "speaker": "Angela Martin"},
  {"season": 9, "episode": 23, "scene": 38, "line": "Shut up. Jakey?", "order": 57767, "speaker": "Meredith Palmer"},
  {"season": 9, "episode": 23, "scene": 38, "line": "Mom?", "order": 57768, "speaker": "Jakey"},
  {"season": 9, "episode": 23, "scene": 38, "line": "Wait, wait. What?", "order": 57769, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 23, "scene": 38, "line": "Oh, man.", "order": 57770, "speaker": "Jakey"},
  {"season": 9, "episode": 23, "scene": 38, "line": "No, no, no, no. Just do your wok. Pretend mom's not here.", "order": 57771, "speaker": "Meredith Palmer"},
  {"season": 9, "episode": 23, "scene": 38, "line": "Uh, that seems inappropriate.", "order": 57772, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 23, "scene": 38, "line": "Give the good show, my little entrepreneur.", "order": 57773, "speaker": "Meredith Palmer"},
  {"season": 9, "episode": 23, "scene": 38, "line": "Okay.", "order": 57774, "speaker": "Jakey"},
  {"season": 9, "episode": 23, "scene": 38, "line": "Take it off. [music play]. Yeah! Good song choice, Jakey. Stripper's only as good as his song.", "order": 57775, "speaker": "Meredith Palmer"},
  {"season": 9, "episode": 23, "scene": 38, "line": "oh, wowee wow.", "order": 57776, "speaker": "Rachel"},
  {"season": 9, "episode": 23, "scene": 38, "line": "Okay'", "order": 57777, "speaker": "Angela Martin"},
  {"season": 9, "episode": 23, "scene": 38, "line": "Whoo-hoo'go, Meredith's stripper son.", "order": 57778, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 23, "scene": 38, "line": "Rachel, are you all right?", "order": 57779, "speaker": "Angela Martin"},
  {"season": 9, "episode": 23, "scene": 38, "line": "I don't know! I don't know.", "order": 57780, "speaker": "Rachel"},
  {"season": 9, "episode": 23, "scene": 38, "line": "Oh geeze. [Jakey starts dancing on Angela]. Oh, my God!", "order": 57781, "speaker": "Angela Martin"},
  {"season": 9, "episode": 23, "scene": 38, "line": "Be gentle Jakey. Gentle. One second. Just one second. Look. [pushes Jakey aside and starts dancing on Angela]", "order": 57782, "speaker": "Meredith Palmer"},
  {"season": 9, "episode": 23, "scene": 38, "line": "Okay, if anything, this is rougher. Stop it Meredith.", "order": 57783, "speaker": "Angela Martin"},
  {"season": 9, "episode": 23, "scene": 38, "line": "Fine. My bad. Go ahead Jakey.", "order": 57784, "speaker": "Meredith Palmer"},
  {"season": 9, "episode": 23, "scene": 38, "line": "[Jakey resumes dancing] Uh, no. It's o'thank you. You know what? You don't have to'oh no, no, no. No, no, no. It's okay.", "order": 57785, "speaker": "Angela Martin"},
  {"season": 9, "episode": 23, "scene": 39, "line": "Now, for the last time, I'd like a side salad with balsamic.", "order": 57786, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 23, "scene": 39, "line": "Dwight, for the last time, she's not a waitress.", "order": 57787, "speaker": "Clark Green"},
  {"season": 9, "episode": 23, "scene": 39, "line": "You're telling me!", "order": 57788, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 23, "scene": 39, "line": "If you want her to leave, just tip her.", "order": 57789, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 23, "scene": 39, "line": "What for? We haven't even gotten bread yet. Does anyone wanna split a twice baked potato? [to the dancing stripper] Do you have those? Those aren't deep fried, right?", "order": 57790, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 23, "scene": 40, "line": "That was interesting. [creaking sound] What was that?", "order": 57791, "speaker": "Angela Martin"},
  {"season": 9, "episode": 23, "scene": 40, "line": "It's just the wind.", "order": 57792, "speaker": "Phyllis Vance"},
  {"season": 9, "episode": 23, "scene": 40, "line": "Will you lock the door?", "order": 57793, "speaker": "Angela Martin"},
  {"season": 9, "episode": 23, "scene": 40, "line": "Okay. [opens door] It's just the wind, see? Nothing.", "order": 57794, "speaker": "Phyllis Vance"},
  {"season": 9, "episode": 23, "scene": 40, "line": "Alright, see, you don't have to leave the door wide open. We get it. It's the wind. Just come and shut'[Mose grabs Angela and takes her away] OH! My God!", "order": 57795, "speaker": "Angela Martin"},
  {"season": 9, "episode": 23, "scene": 40, "line": "[screams]", "order": 57796, "speaker": "All"},
  {"season": 9, "episode": 23, "scene": 40, "line": "Phyllis!", "order": 57797, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 23, "scene": 40, "line": "What's happening?", "order": 57798, "speaker": "Rachel"},
  {"season": 9, "episode": 23, "scene": 41, "line": "Oh, man, never thought I'd say this but I think I ate too much bone marrow. [phone rings]", "order": 57799, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 23, "scene": 41, "line": "Oh, it's the girls. Hey Pam. What? Angela's been kidnapped! Phyllis left the door open and some freak came and grabbed her and fled.", "order": 57800, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 23, "scene": 41, "line": "Good old Mose. [laughing]", "order": 57801, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 23, "scene": 41, "line": "They think it was Mose.", "order": 57802, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 23, "scene": 41, "line": "Oh, great. He's getting into the spirit of the festivities. Fantastic. He just pulled off a Braut Entfuhrung.", "order": 57803, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 23, "scene": 41, "line": "What is that?", "order": 57804, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 23, "scene": 41, "line": "A ceremonial bridal kidnapping. He will take the bride and hide her at a local pub and when I find the place, I have to buy everyone drinks. Ah, all right! Oh Mose.", "order": 57805, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 23, "scene": 41, "line": "Wait a minute. You said the tradition is for the groom is search for her and it ends at a pub. So how about the last pub you'd ever set foot in in this town?", "order": 57806, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 23, "scene": 41, "line": "Right. Which one is that?", "order": 57807, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 23, "scene": 41, "line": "Mm, hey driver. Why don't you take us to 3030 Adams?", "order": 57808, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 23, "scene": 42, "line": "Is that Mose?", "order": 57809, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 23, "scene": 42, "line": "Yes, it is! I am here for my bride!", "order": 57810, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 23, "scene": 42, "line": "Well, first, buy us a drink.", "order": 57811, "speaker": "Mose Schrute"},
  {"season": 9, "episode": 23, "scene": 42, "line": "If you want your bride, buy us a drink! If you want your bride, buy us a drink.", "order": 57812, "speaker": "All"},
  {"season": 9, "episode": 23, "scene": 42, "line": "Very well! Drinks on me! Bartender! [Kevin turns around] Oh.", "order": 57813, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 23, "scene": 42, "line": "Well, well, well, well, well, well. That's six 'wells.' Did I get that number right, Dwight?", "order": 57814, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 23, "scene": 42, "line": "I heard you bought a bar, Kevin.", "order": 57815, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 23, "scene": 42, "line": "Yes. I did. This one. Now get out!", "order": 57816, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 23, "scene": 42, "line": "[to Mose] Why did you pick this place? [Mose points to Jim]", "order": 57817, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 23, "scene": 42, "line": "Ooh.", "order": 57818, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 23, "scene": 42, "line": "You? You did this as a prank. My own Bestich Mensch.", "order": 57819, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 23, "scene": 42, "line": "Nope, not a prank. I think it's time for you to bury the hatchet.", "order": 57820, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 23, "scene": 42, "line": "Waste of a good hatchet.", "order": 57821, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 23, "scene": 42, "line": "Okay, just talk.", "order": 57822, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 23, "scene": 42, "line": "Well, w'", "order": 57823, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 23, "scene": 42, "line": "I heard you say 'well' the first time.", "order": 57824, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 23, "scene": 43, "line": "I know Dwight misses Kevin. I saw him make his portrait out of a Wooly Willy. Tomorrow's his wedding day. You can't be anything but happy on your wedding day.", "order": 57825, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 23, "scene": 44, "line": "It was nothing personal. It's just that you were terrible at your job.", "order": 57826, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 23, "scene": 44, "line": "You're just saying that to make me feel better.", "order": 57827, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 23, "scene": 44, "line": "No really. You were terrible at math and organization, time management, personal hygiene. Your internet searches were so filthy we had to throw our your computer.", "order": 57828, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 23, "scene": 44, "line": "Is that all it was?", "order": 57829, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 23, "scene": 44, "line": "That's it.", "order": 57830, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 23, "scene": 44, "line": "Come here [hugs Dwight]", "order": 57831, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 23, "scene": 44, "line": "I missed you Kevin.", "order": 57832, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 23, "scene": 44, "line": "I missed you!", "order": 57833, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 23, "scene": 44, "line": "Ohh.", "order": 57834, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 23, "scene": 45, "line": "Guten Prank.", "order": 57835, "speaker": "Mose Schrute"},
  {"season": 9, "episode": 23, "scene": 45, "line": "Yes, Mose. Guten Prank number three.", "order": 57836, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 23, "scene": 46, "line": "You've had your drink. Now where is my bride?", "order": 57837, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 23, "scene": 47, "line": "Mose! What..ooh, Mose. [Mose opens trunk]", "order": 57838, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 23, "scene": 47, "line": "What the [bleep] is your problem you [bleep] [bleep] [bleep]?!", "order": 57839, "speaker": "Angela Martin"},
  {"season": 9, "episode": 23, "scene": 48, "line": "Hey. Hey, is Angela coming or'", "order": 57840, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 23, "scene": 48, "line": "No. We can't see each other on our wedding day. And her legs are still numb from being in the trunk.", "order": 57841, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 23, "scene": 48, "line": "Right.", "order": 57842, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 23, "scene": 48, "line": "Hey guys.", "order": 57843, "speaker": "Stanley Hudson"},
  {"season": 9, "episode": 23, "scene": 48, "line": "Stanley!", "order": 57844, "speaker": "All"},
  {"season": 9, "episode": 23, "scene": 48, "line": "How's Florida?", "order": 57845, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 23, "scene": 48, "line": "Oh, great.", "order": 57846, "speaker": "Stanley Hudson"},
  {"season": 9, "episode": 23, "scene": 49, "line": "Yes, I'm living in Florida now. Little town called Florida City, just on the edge of the everglades. The man who delivered my divorce papers came by fan boat which was kinda fun. I sit on my porch all day, carving birds.", "order": 57847, "speaker": "Stanley Hudson"},
  {"season": 9, "episode": 23, "scene": 50, "line": "Am I the only one that's nervous? How are you doing with this? Should we have bailed?", "order": 57848, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 23, "scene": 50, "line": "No, no, I'm fine.", "order": 57849, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 23, "scene": 50, "line": "Doesn't seem like anyone cares about us anyway.", "order": 57850, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 23, "scene": 50, "line": "I guess this was work being filmed nonstop for nine years.", "order": 57851, "speaker": "Stanley Hudson"},
  {"season": 9, "episode": 23, "scene": 50, "line": "I'm sorry guys. This is probably on me. I got hated on pretty hard when that auto-tune went viral.", "order": 57852, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 23, "scene": 51, "line": "[watching at his bar] People actually dance to this. It's in my juke box. None of the money goes to Andy though.", "order": 57853, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 23, "scene": 52, "line": "[laughing] Yeah, people hate you.", "order": 57854, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 23, "scene": 52, "line": "I'll go talk to the manager. Maybe we should just go home. [walks down the hall]", "order": 57855, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 23, "scene": 53, "line": "Hey! It's Andy Bernard!", "order": 57856, "speaker": "Man"},
  {"season": 9, "episode": 23, "scene": 53, "line": "Nard Dog! [cheers and claps] Ree-De-De-De-Doo! [repeats]", "order": 57857, "speaker": "All"},
  {"season": 9, "episode": 23, "scene": 53, "line": "Ree-De-De-De-Doo!", "order": 57858, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 23, "scene": 54, "line": "How did it feel to see your lives played out on tv?", "order": 57859, "speaker": "Man 1"},
  {"season": 9, "episode": 23, "scene": 54, "line": "It's like seeing a documentary about how your food is made. It's kinda disgusting. You learn a lot, but I didn't wanna know any of it.", "order": 57860, "speaker": "David Wallace"},
  {"season": 9, "episode": 23, "scene": 54, "line": "With today's modern surveillance technology we are in a constant state of being watched weather it's our government or the government of other countries a.k.a. Google. You guys are being filmed way more than we ever were.", "order": 57861, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 23, "scene": 54, "line": "Uh, no one recognizes me. But not all my friends call me Plop. So' thanks PBS.", "order": 57862, "speaker": "Pete Miller"},
  {"season": 9, "episode": 23, "scene": 55, "line": "Uh, Jim, that DVD in the last episode was so romantic. And, um, I think we'd all love to know, Pam, what romantic thing did you do to pay Jim back for leaving Athlead?", "order": 57863, "speaker": "Woman 1"},
  {"season": 9, "episode": 23, "scene": 55, "line": "Well, I mean, How do you pay back someone for something like that? But, uh, I don't know. I'm working on something.", "order": 57864, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 23, "scene": 55, "line": "She pays me back every day just by being my wife so that's fine.", "order": 57865, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 23, "scene": 55, "line": "Awww.", "order": 57866, "speaker": "All"},
  {"season": 9, "episode": 23, "scene": 55, "line": "O-Kay.", "order": 57867, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 23, "scene": 56, "line": "All I can say is, if I had Jim, he would have a free pass to do anything. I mean if I lucked into that' he could do anything. Anything.", "order": 57868, "speaker": "Woman 2"},
  {"season": 9, "episode": 23, "scene": 56, "line": "[pause] I'm sorry. Is there a question?", "order": 57869, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 23, "scene": 56, "line": "Uh, no.", "order": 57870, "speaker": "Woman 2"},
  {"season": 9, "episode": 23, "scene": 57, "line": "Pam, what was in that teapot letter?", "order": 57871, "speaker": "Man 2"},
  {"season": 9, "episode": 23, "scene": 57, "line": "Oh, um, well, you know, I' I just' I just think I would rather keep that private. You know, if you'd been filmed for nine years of your life, there'd be some things that you just wanna keep to yourself.", "order": 57872, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 23, "scene": 57, "line": "I hear ya. What did it say?", "order": 57873, "speaker": "Man 2"},
  {"season": 9, "episode": 23, "scene": 57, "line": "We're gonna move on. Next question please.", "order": 57874, "speaker": "Moderator"},
  {"season": 9, "episode": 23, "scene": 58, "line": "Do you find that your life feels pointless now that nobody's actually filming you anymore?", "order": 57875, "speaker": "Man 3"},
  {"season": 9, "episode": 23, "scene": 58, "line": "Yes.", "order": 57876, "speaker": "Toby Flenderson"},
  {"season": 9, "episode": 23, "scene": 59, "line": "I wanna know how everyone felt they were portrayed. Was it accurate?", "order": 57877, "speaker": "Woman 3"},
  {"season": 9, "episode": 23, "scene": 59, "line": "I got a beef with that. Um, for the first seven years, I was getting my PhD in School Psychology and they didn't show it. Yes, I was getting hammered but, hey, it was college.", "order": 57878, "speaker": "Meredith Palmer"},
  {"season": 9, "episode": 23, "scene": 60, "line": "I have a question for Jim and Pam. Everyone watching sees how much you love each other and how you're soul mates. So, Pam, how could you doubt that when Jim moved to Philadelphia?", "order": 57879, "speaker": "Woman 4"},
  {"season": 9, "episode": 23, "scene": 60, "line": "Um, you know what, I actually didn't handle that move, uh, very gracefully. From not communicating to being a little selfish.", "order": 57880, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 23, "scene": 60, "line": "Listen, um' I was scared, you know? I loved what I had, and I didn't want to risk it. And I think that maybe I did doubt him a little too. Which was wrong because he's shown me time and again. But when the documentary started airing, people on the street told me that I had this fairy-tale romance. But there were a lot of times last year where it did not feel like a fairy tale. But then it got deeper, and it got stronger and now it's better than a fairy tale. It's like a long book that you never want to end. And you're fine with that because you just never, ever wanna leave it.", "order": 57881, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 23, "scene": 60, "line": "Like Harry Potter.", "order": 57882, "speaker": "Woman 4"},
  {"season": 9, "episode": 23, "scene": 60, "line": "Yeah, like Harry Potter.", "order": 57883, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 23, "scene": 61, "line": "I have a question for Erin.", "order": 57884, "speaker": "Joan"},
  {"season": 9, "episode": 23, "scene": 61, "line": "Really?", "order": 57885, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 23, "scene": 61, "line": "Yes. Um, the thing I found most compelling about the documentary was your search for your birth mother.", "order": 57886, "speaker": "Joan"},
  {"season": 9, "episode": 23, "scene": 61, "line": "Thank you.", "order": 57887, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 23, "scene": 61, "line": "So my question is, um, do' don't you hate her? I mean, I would just imagine that you were so angry at her that you would hate her.", "order": 57888, "speaker": "Joan"},
  {"season": 9, "episode": 23, "scene": 61, "line": "Maybe sometimes. But not like 'hate' hate. More just like, 'Mom, I hate you!' And then she would say 'go to your room, young lady.' And I'd stamp my foot and run upstairs and I have a room, which is really cool. And then we'd just have dinner together. But I don't know. I'd have to meet her. Thanks.", "order": 57889, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 23, "scene": 61, "line": "Erin'", "order": 57890, "speaker": "Joan"},
  {"season": 9, "episode": 23, "scene": 61, "line": "Yes?", "order": 57891, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 23, "scene": 61, "line": "Um'Oh, Erin..", "order": 57892, "speaker": "Joan"},
  {"season": 9, "episode": 23, "scene": 61, "line": "Yes? Is there a follow-up question? [realizes] Mom? [they hug]", "order": 57893, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 23, "scene": 61, "line": "Erin' Same question but about your dad? [they hug]", "order": 57894, "speaker": "Ed"},
  {"season": 9, "episode": 23, "scene": 61, "line": "Well, this feels like a good place to stop. Let's thank all of our wonderful panelists for being here on this wonderful panel today. [audience claps] Next week at the Scranton Cultural Center don't forget, Irish Step Dancing semifinals. Winning team to Mid-Atlantic. [applause and scattering cheers]", "order": 57895, "speaker": "Moderator"},
  {"season": 9, "episode": 23, "scene": 62, "line": "Where's the, uh, basket for gifts?", "order": 57896, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 23, "scene": 62, "line": "Right there, sir.", "order": 57897, "speaker": "Usher"},
  {"season": 9, "episode": 23, "scene": 62, "line": "Okay.", "order": 57898, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 23, "scene": 63, "line": "Hi, I'm Dakota.", "order": 57899, "speaker": "Dakota"},
  {"season": 9, "episode": 23, "scene": 63, "line": "Jeff Bomondo. I sell ceramic tile out of Newark.", "order": 57900, "speaker": "Creed Bratton"},
  {"season": 9, "episode": 23, "scene": 63, "line": "Nice.", "order": 57901, "speaker": "Dakota"},
  {"season": 9, "episode": 23, "scene": 63, "line": "My wife's name is Kathryn. I can show you my social security card if it helps.", "order": 57902, "speaker": "Creed Bratton"},
  {"season": 9, "episode": 23, "scene": 64, "line": "Uck, Look at all this mud. Can you imagine if I had worn my Jimmy Choos? I just saved you 600 bucks mister.", "order": 57903, "speaker": "Kelly Kapoor"},
  {"season": 9, "episode": 23, "scene": 64, "line": "Thanks for helping out, sweetie.", "order": 57904, "speaker": "Ravi"},
  {"season": 9, "episode": 23, "scene": 64, "line": "Kelly, you're here.", "order": 57905, "speaker": "Ryan Howard"},
  {"season": 9, "episode": 23, "scene": 64, "line": "Hey, Ryan!", "order": 57906, "speaker": "Kelly Kapoor"},
  {"season": 9, "episode": 23, "scene": 64, "line": "Hey.", "order": 57907, "speaker": "Ryan Howard"},
  {"season": 9, "episode": 23, "scene": 64, "line": "Whose baby is this? Oh, are, like, a nanny now?", "order": 57908, "speaker": "Kelly Kapoor"},
  {"season": 9, "episode": 23, "scene": 64, "line": "No, this little guy is mine.", "order": 57909, "speaker": "Ryan Howard"},
  {"season": 9, "episode": 23, "scene": 65, "line": "So I was dating this girl, and one day, she went out to get a new charger for her e-cigarette. Never came back. Oldest story in the book. [baby squeals]", "order": 57910, "speaker": "Ryan Howard"},
  {"season": 9, "episode": 23, "scene": 66, "line": "Say 'hi', Drake.", "order": 57911, "speaker": "Ryan Howard"},
  {"season": 9, "episode": 23, "scene": 66, "line": "Drake is your baby's name?", "order": 57912, "speaker": "Kelly Kapoor"},
  {"season": 9, "episode": 23, "scene": 66, "line": "Yeah.", "order": 57913, "speaker": "Ryan Howard"},
  {"season": 9, "episode": 23, "scene": 66, "line": "That is an amazing name. I'm obsessed with Drake. His last album' it just touched me to pieces.", "order": 57914, "speaker": "Kelly Kapoor"},
  {"season": 9, "episode": 23, "scene": 66, "line": "No Kelly, he's no named after a hip-hop artist from 2011. It's Drake, like a mix of Drew and Blake.", "order": 57915, "speaker": "Ryan Howard"},
  {"season": 9, "episode": 23, "scene": 66, "line": "Cool. Well, he is so cute. Ravi, check out this cute baby. I'm obsessed with him. Ravi is a pediatrician and some of his patients are total uggos.", "order": 57916, "speaker": "Kelly Kapoor"},
  {"season": 9, "episode": 23, "scene": 66, "line": "They're called premature, sweetie. It's good to see you again Ryan.", "order": 57917, "speaker": "Ravi"},
  {"season": 9, "episode": 23, "scene": 67, "line": "Let me help you us.", "order": 57918, "speaker": "Phyllis Vance"},
  {"season": 9, "episode": 23, "scene": 67, "line": "Thanks. [knees buckle] Oh. Ouch.", "order": 57919, "speaker": "Angela Martin"},
  {"season": 9, "episode": 23, "scene": 67, "line": "Oh, boy. Maybe your heels are too high.", "order": 57920, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 23, "scene": 67, "line": "No, my heels aren't too high. It's because I spent three hours in a car trunk. Thanks for not locking the door when I asked you to, Phyllis. [sighs] Sorry Phyllis. You didn't know. As long as I can get to the altar.", "order": 57921, "speaker": "Angela Martin"},
  {"season": 9, "episode": 23, "scene": 67, "line": "Oh, I'm gonna get you to that altar. You can take that to the bank.", "order": 57922, "speaker": "Phyllis Vance"},
  {"season": 9, "episode": 23, "scene": 68, "line": "You ready?", "order": 57923, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 23, "scene": 68, "line": "[chuckles] You kidding? I was born ready. [mimicking heavy metal guitars]", "order": 57924, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 23, "scene": 68, "line": "Dwight, Dwight, Dwight. Um' I don't know how to tell you this, but' we have a little bit of a problem.", "order": 57925, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 23, "scene": 68, "line": "Oh, no. What?", "order": 57926, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 23, "scene": 68, "line": "The minister just told me that it's tradition for the Bestish Mensch to be older than the groom.", "order": 57927, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 23, "scene": 68, "line": "Oh, come on. I've never heard of such a thing.", "order": 57928, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 23, "scene": 68, "line": "I haven't heard of it, obviously. But I'm out because I am significantly younger than you-", "order": 57929, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 23, "scene": 68, "line": "Not' significant is a big word'", "order": 57930, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 23, "scene": 68, "line": "I think it's definitely' Well, okay. Either way'", "order": 57931, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 23, "scene": 68, "line": "I think you're only a teeny'", "order": 57932, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 23, "scene": 68, "line": "Either way, Dwight' I can't be there for you. I'm sorry.", "order": 57933, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 23, "scene": 68, "line": "Jim.", "order": 57934, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 23, "scene": 68, "line": "I just'really wish there was something I could do. [looks off]", "order": 57935, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 23, "scene": 68, "line": "[turns around] [whispering] Michael. I can't believe you came.", "order": 57936, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 23, "scene": 68, "line": "That's what she said.", "order": 57937, "speaker": "Michael Scott"},
  {"season": 9, "episode": 23, "scene": 69, "line": "Best prank ever.", "order": 57938, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 23, "scene": 70, "line": "As it is traditional to the Schrutes, the lovers are standing in their own graves as a reminder that this is the only escape from what they are about to do.", "order": 57939, "speaker": "Minister"},
  {"season": 9, "episode": 23, "scene": 70, "line": "[to Joan] See, I get what they are trying to do, but why are the graves so shallow?", "order": 57940, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 23, "scene": 71, "line": "[reading] 'Arise, my love, my fair one, and come away. For lo, the winter is passed. The rain is over and gone. The time of singing has come and the voice of the turtledove is heard in our land. Let me see your face. Let me hear your voice for your voice is sweet and your face is comely. '", "order": 57941, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 23, "scene": 72, "line": "I now pronounce you man and wife. [everyone cheers] Release the doves! [doves do not move right away]", "order": 57942, "speaker": "Minister"},
  {"season": 9, "episode": 23, "scene": 72, "line": "Oh.", "order": 57943, "speaker": "Kelly Kapoor"},
  {"season": 9, "episode": 23, "scene": 72, "line": "That's not'", "order": 57944, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 23, "scene": 72, "line": "Ladies and gentlemen. Thank you so much for coming. Now, please take your hay bales to the reception. They'll be used for seating. Complimentary hay hooks are placed alone the aisles. Just stab 'em on in there.", "order": 57945, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 23, "scene": 73, "line": "Everybody!", "order": 57946, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 23, "scene": 74, "line": "[dancing with Pam. They kiss] See, now you don't owe me anything.", "order": 57947, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 23, "scene": 75, "line": "Ravi? Ravi? Drake has a bit of a rash. And he's hot.", "order": 57948, "speaker": "Ryan Howard"},
  {"season": 9, "episode": 23, "scene": 75, "line": "Oh, no.", "order": 57949, "speaker": "Ravi"},
  {"season": 9, "episode": 23, "scene": 75, "line": "I was wondering could you maybe examine him for a second?", "order": 57950, "speaker": "Ryan Howard"},
  {"season": 9, "episode": 23, "scene": 75, "line": "Oh, yeah, you're not feeling well little guy? Okay, sure. Should we go inside?", "order": 57951, "speaker": "Ravi"},
  {"season": 9, "episode": 23, "scene": 75, "line": "Actually, could you go inside? I'm feeling a little dehydrated. I could really use a little break, have some water.", "order": 57952, "speaker": "Ryan Howard"},
  {"season": 9, "episode": 23, "scene": 75, "line": "Are you sure you don't wanna go in?", "order": 57953, "speaker": "Ravi"},
  {"season": 9, "episode": 23, "scene": 75, "line": "Ravi, just do it. That baby is burning up. Isn't this, like what you live for? Just go. Go.", "order": 57954, "speaker": "Kelly Kapoor"},
  {"season": 9, "episode": 23, "scene": 75, "line": "Thank you Ravi. Thank you so much. This is great for me and for Drake. Thank you.", "order": 57955, "speaker": "Ryan Howard"},
  {"season": 9, "episode": 23, "scene": 75, "line": "I don't know, Ryan. Baby Drake didn't look so good.", "order": 57956, "speaker": "Kelly Kapoor"},
  {"season": 9, "episode": 23, "scene": 75, "line": "He'll be fine. I let me suck on a strawberry. He's allergic but he'll get over it fast. I had to talk to you.", "order": 57957, "speaker": "Ryan Howard"},
  {"season": 9, "episode": 23, "scene": 75, "line": "You gave your baby an allergic reaction just to talk to me? [they kiss]", "order": 57958, "speaker": "Kelly Kapoor"},
  {"season": 9, "episode": 23, "scene": 76, "line": "Michael has so many pictures of his kids he had to get two phones with two numbers and he pays two bills.", "order": 57959, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 23, "scene": 76, "line": "[with Michael] Oh, my gosh, it's choreographed!", "order": 57960, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 23, "scene": 76, "line": "He just so happy to have a family plan.", "order": 57961, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 23, "scene": 77, "line": "Where'd you learn to dance like that?", "order": 57962, "speaker": "Ed"},
  {"season": 9, "episode": 23, "scene": 77, "line": "I don't know. I've just always been really good at dancing.", "order": 57963, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 23, "scene": 78, "line": "[dancing with Pam] [sobbing]", "order": 57964, "speaker": "Toby Flenderson"},
  {"season": 9, "episode": 23, "scene": 78, "line": "Is it me? Is it Nellie?", "order": 57965, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 23, "scene": 78, "line": "[sobbing] It's everything.", "order": 57966, "speaker": "Toby Flenderson"},
  {"season": 9, "episode": 23, "scene": 79, "line": "[dancing with Stanley] I missed you.", "order": 57967, "speaker": "Phyllis Vance"},
  {"season": 9, "episode": 23, "scene": 79, "line": "I missed you too.", "order": 57968, "speaker": "Stanley Hudson"},
  {"season": 9, "episode": 23, "scene": 80, "line": "Lots of people think that Stanley Hudson's a mean old grump. [laughs] But [crying] would a grump make this? It's me. It's me.", "order": 57969, "speaker": "Phyllis Vance"},
  {"season": 9, "episode": 23, "scene": 81, "line": "I love you.", "order": 57970, "speaker": "Ryan Howard"},
  {"season": 9, "episode": 23, "scene": 81, "line": "[laughing] I love you too.", "order": 57971, "speaker": "Kelly Kapoor"},
  {"season": 9, "episode": 23, "scene": 81, "line": "We're gonna be together forever.", "order": 57972, "speaker": "Ryan Howard"},
  {"season": 9, "episode": 23, "scene": 81, "line": "We're running off into the sunset.", "order": 57973, "speaker": "Kelly Kapoor"},
  {"season": 9, "episode": 23, "scene": 81, "line": "I finally mastered commitment.", "order": 57974, "speaker": "Ryan Howard"},
  {"season": 9, "episode": 23, "scene": 82, "line": "[to Oscar] What was that stuff?", "order": 57975, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 23, "scene": 82, "line": "Hey, has anyone seen Ryan' or Kelly?", "order": 57976, "speaker": "Ravi"},
  {"season": 9, "episode": 23, "scene": 82, "line": "Ooh! Yes. Uh, They left together a little while ago. Kelly was hoping that you would keep the baby so they can start a new life together.", "order": 57977, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 23, "scene": 82, "line": "Oh, that's it. Here. [hands Drake to Kevin] Call child services and report an abandoned baby. We'll find a better parent than Ryan in no time.", "order": 57978, "speaker": "Ravi"},
  {"season": 9, "episode": 23, "scene": 82, "line": "Oh, I don't know.", "order": 57979, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 23, "scene": 82, "line": "Um, Kevin? Oh, I can help you with that. Yeah. I mean, I can find someone who will' who will love that beautiful little boy the way he deserves.", "order": 57980, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 23, "scene": 82, "line": "Hey, this is better. [hands Drake to Nellie]", "order": 57981, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 23, "scene": 82, "line": "Yes. It is. Oh yes it is.", "order": 57982, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 23, "scene": 83, "line": "If Ryan wants his baby back, please tell him where to fine me. We'll be somewhere in Europe. [looking at Drake] Won't we?", "order": 57983, "speaker": "Nellie Bertram"},
  {"season": 9, "episode": 23, "scene": 84, "line": "[crying] I feel like all my kids grew up and then they married each other. It's every parent's dream.", "order": 57984, "speaker": "Michael Scott"},
  {"season": 9, "episode": 23, "scene": 85, "line": "Thank you go much.", "order": 57985, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 23, "scene": 85, "line": "Good night.", "order": 57986, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 23, "scene": 85, "line": "Good luck.", "order": 57987, "speaker": "Toby Flenderson"},
  {"season": 9, "episode": 23, "scene": 85, "line": "Great. It was really fun.", "order": 57988, "speaker": "Val Johnson"},
  {"season": 9, "episode": 23, "scene": 85, "line": "Good evening.", "order": 57989, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 23, "scene": 85, "line": "The Doc crew's throwing a big after party in the warehouse tonight.", "order": 57990, "speaker": "Val Johnson"},
  {"season": 9, "episode": 23, "scene": 85, "line": "Yeah, I heard. It sounds kinda lame. [to camera] No offense. You going, Toby?", "order": 57991, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 23, "scene": 85, "line": "Oh, I don't know. I might turn in early.", "order": 57992, "speaker": "Toby Flenderson"},
  {"season": 9, "episode": 23, "scene": 85, "line": "It's only 6:00. Come on, everybody's going.", "order": 57993, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 23, "scene": 85, "line": "You have to go, Toby.", "order": 57994, "speaker": "Meredith Palmer"},
  {"season": 9, "episode": 23, "scene": 85, "line": "Yeah, come on, Tobes.", "order": 57995, "speaker": "Pete Miller"},
  {"season": 9, "episode": 23, "scene": 85, "line": "Come on.", "order": 57996, "speaker": "Val Johnson"},
  {"season": 9, "episode": 23, "scene": 85, "line": "Yeah. I'll stop by.", "order": 57997, "speaker": "Toby Flenderson"},
  {"season": 9, "episode": 23, "scene": 85, "line": "There he is!", "order": 57998, "speaker": "Pete Miller"},
  {"season": 9, "episode": 23, "scene": 86, "line": "I need to get out of this dress.", "order": 57999, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 23, "scene": 86, "line": "I gotta get out of this dress.", "order": 58000, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 23, "scene": 86, "line": "Oh, I'm sorry Pam.", "order": 58001, "speaker": "Carol Stills"},
  {"season": 9, "episode": 23, "scene": 86, "line": "What's going on?", "order": 58002, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 23, "scene": 86, "line": "[sighs] [whispering] Why are you still here?", "order": 58003, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 23, "scene": 86, "line": "[whispering] I'm so sorry. They were an hour late.", "order": 58004, "speaker": "Carol Stills"},
  {"season": 9, "episode": 23, "scene": 86, "line": "No, really, what's going on?", "order": 58005, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 23, "scene": 86, "line": "Okay, Okay. Um' so' this past year has been really great, and you've been great and I just' I know that you had to make this choice and you had to give something up for me. But I never want you to have to give up anything. I just thought if I could get us an offer then there wouldn't be anything standing in our way and I could come to you with this big Jim gesture' and show you all at once just how much I love you and how much I really do believe in your future.", "order": 58006, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 23, "scene": 86, "line": "Sorry, how long have you been showing the house?", "order": 58007, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 23, "scene": 86, "line": "About 2 months", "order": 58008, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 23, "scene": 86, "line": "That's why it's so clean.", "order": 58009, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 23, "scene": 86, "line": "Yeah.", "order": 58010, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 23, "scene": 86, "line": "I mean, you were gonna do this without me?", "order": 58011, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 23, "scene": 86, "line": "Well, you' you bought the house without telling me, so I thought I could sell it without telling you.", "order": 58012, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 23, "scene": 86, "line": "Oh' okay. Where would we go?", "order": 58013, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 23, "scene": 86, "line": "Austin? Maybe.", "order": 58014, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 23, "scene": 86, "line": "I promise you, you don't have to do this for me.", "order": 58015, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 23, "scene": 86, "line": "I'm doing it for us.", "order": 58016, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 23, "scene": 86, "line": "The last'", "order": 58017, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 23, "scene": 86, "line": "Okay. We'll take it.", "order": 58018, "speaker": "Buyer"},
  {"season": 9, "episode": 23, "scene": 86, "line": "[to the buyer] Hold on a second. [to Pam] The last few months have meant the world to me and all I care about'", "order": 58019, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 23, "scene": 86, "line": "Hold on a second. [turning] Wait, what did you just say?", "order": 58020, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 23, "scene": 86, "line": "We wanna buy your house.", "order": 58021, "speaker": "Buyer"},
  {"season": 9, "episode": 23, "scene": 86, "line": "You wanna do this?", "order": 58022, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 23, "scene": 86, "line": "I wanna do this.", "order": 58023, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 23, "scene": 86, "line": "You really want to do this?", "order": 58024, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 23, "scene": 86, "line": "I really want to do this!", "order": 58025, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 23, "scene": 86, "line": "[hugging Pam] [laughing] Oh my God!", "order": 58026, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 23, "scene": 87, "line": "Hey, Darryl. [whispers in his ear]", "order": 58027, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 23, "scene": 87, "line": "What? All right!", "order": 58028, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 23, "scene": 87, "line": "We're so excited.", "order": 58029, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 23, "scene": 88, "line": "Cool.", "order": 58030, "speaker": "Phyllis Vance"},
  {"season": 9, "episode": 23, "scene": 88, "line": "Oh, that's for PBS executives only.", "order": 58031, "speaker": "Woman"},
  {"season": 9, "episode": 23, "scene": 88, "line": "I had to pledge $50 to my local PBS station just to get this.", "order": 58032, "speaker": "Stanley Hudson"},
  {"season": 9, "episode": 23, "scene": 89, "line": "I think your ideas are fantastic, Oscar. I'd love to contribute to your campaign.", "order": 58033, "speaker": "David Wallace"},
  {"season": 9, "episode": 23, "scene": 89, "line": "Thank you David.", "order": 58034, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 23, "scene": 90, "line": "Uh, could I please have your attention? So a year ago, I got my first art commission to paint a mural for Dunder Mifflin and I decided to paint the history of paper',which was just some trees and stuff. And then someone spray painted a bunch of butts on it and I had to start from the beginning. But it all worked out for the best because I think that I've painted the perfect thing which is the history of us. All of us. And this is for you Jim. [applause] And go ahead! [drape drops and everyone cheers]", "order": 58035, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 23, "scene": 90, "line": "Everyone, Let's take our picture in front of the mural. Oh, um, I just'I kind of meant just everybody from the office.", "order": 58036, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 23, "scene": 91, "line": "[taking pictures] Very nice. Just a couple dozen more. Smiles, everybody, smiles. All right, that's great. I got enough. Thank you, everybody.", "order": 58037, "speaker": "Photographer"},
  {"season": 9, "episode": 23, "scene": 92, "line": "[whispering to Pam] Let's go outside", "order": 58038, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 23, "scene": 92, "line": "Okay.", "order": 58039, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 23, "scene": 93, "line": "Okay, I need a drink.", "order": 58040, "speaker": "Stanley Hudson"},
  {"season": 9, "episode": 23, "scene": 93, "line": "Yeah, we all need a drink. [opening Meredith's drawer] What the'there's only dandelion tea and raisins in here.", "order": 58041, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 23, "scene": 93, "line": "Hey, bottom drawer.", "order": 58042, "speaker": "Meredith Palmer"},
  {"season": 9, "episode": 23, "scene": 93, "line": "Yes!", "order": 58043, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 23, "scene": 94, "line": "[answering the phone] Dunder Mifflin, This is Pam. Oh, I'm sorry. Jim Halpert doesn't work here anymore.", "order": 58044, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 23, "scene": 95, "line": "I didn't watch the whole documentary. After a few episodes, it was too painful. I kept wanting to scream at Pam. It took me so long to do so many important things. It's just hard to accept that I spent so many years being less happy than I could have been. Jim was 5 feet from my desk and it took me four years to get to him. It'd be great if people saw this documentary and learned from my mistakes. Not that I'm a tragic person. I'm really happy now. But'it would just'just make my heart soar if someone out there saw this and she said to herself 'be strong, trust yourself, love yourself. Conquer your fears. Just go after what you want and act fast, because life just isn't that long.'", "order": 58045, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 23, "scene": 96, "line": "If there is one thing that I have learned through this whole experience, it's that if you film anybody long enough, they're going to do something stupid. It's only human natural.", "order": 58046, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 23, "scene": 97, "line": "Hey guys.", "order": 58047, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 23, "scene": 97, "line": "Hi.", "order": 58048, "speaker": "Angela Martin"},
  {"season": 9, "episode": 23, "scene": 97, "line": "Hey!", "order": 58049, "speaker": "All"},
  {"season": 9, "episode": 23, "scene": 97, "line": "What about the honeymoon?", "order": 58050, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 23, "scene": 97, "line": "Oh, the honeymoon can wait till tomorrow. We wanted to hang out with you guys. I mean, when are we all going to be here together again?", "order": 58051, "speaker": "Angela Martin"},
  {"season": 9, "episode": 23, "scene": 97, "line": "Aww, J, P and D. The Three Amigos. Ah, it warms my heart. Hey, what do you say, when I get back from out honeymoon, the three of us have a conference room meeting just for fun? [pause] What is that meaningful look?", "order": 58052, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 23, "scene": 97, "line": "You know what? Maybe we should talk.", "order": 58053, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 23, "scene": 98, "line": "Hey, come here. You guys need to see this.", "order": 58054, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 23, "scene": 98, "line": "What is it?", "order": 58055, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 23, "scene": 98, "line": "[on the computer] You're probably sitting there wondering what will be your mark. What will you be known for? It might surprise you to learn...", "order": 58056, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 23, "scene": 99, "line": "I finally feel ready. Athlead is growing'", "order": 58057, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 23, "scene": 99, "line": "Athleap.", "order": 58058, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 23, "scene": 99, "line": "And Jim can jump back in without skipping a beat. We'll come back to visit. But I think it's time for us to officially'", "order": 58059, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 23, "scene": 99, "line": "No, don't say it. You're fired! You're both fired!", "order": 58060, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 23, "scene": 99, "line": "Dwight, come on. Don't end on a bad note.", "order": 58061, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 23, "scene": 99, "line": "Don't be an idiot. It's for the severance. The best I can do one month for every year you've been here. That's the max.", "order": 58062, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 23, "scene": 99, "line": "Thanks Dwight.", "order": 58063, "speaker": "Pam Halpert"},
  {"season": 9, "episode": 23, "scene": 99, "line": "Hey, and if you're ever in Austin...", "order": 58064, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 23, "scene": 99, "line": "Woah. Right. For what, the art? The music? The incredible nightlife? No thank you. But if you're ever in the area, you'll always have a place to stay'in my barn.", "order": 58065, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 23, "scene": 99, "line": "There it is.", "order": 58066, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 23, "scene": 100, "line": "Do I get along with my co-workers? Well, first of all, I don't have co-workers anymore, I have subordinates. So' have I gotten along with my subordinates? Let's see. My supplier relations rep, Meredith Palmer, is the only person I know who knows how to properly head bang to Motorhead. Oscar Martinez, my accountant, is now godfather to my son. Angela Schrute, my former accountant is now my wife. My top salesman, Jim Halpert was best man at my wedding and office administrator Pamela Beesley Halpert is my best friend. So'yes. I'd say I have gotten along with my subordinates.", "order": 58067, "speaker": "Dwight Schrute"},
  {"season": 9, "episode": 23, "scene": 101, "line": "[on computer] You might feel sorry for me but I actually feel sorry for you. Because you're about to leave Cornell. So say it with me: [all]: Oh, I can so just sit here and cry.", "order": 58068, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 23, "scene": 101, "line": "Oh, Yes!", "order": 58069, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 23, "scene": 101, "line": "You did good. Real good.", "order": 58070, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 23, "scene": 101, "line": "Thanks Dad'Darryl.", "order": 58071, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 23, "scene": 102, "line": "I spent so much of my time here at Dunder Mifflin thinking about my old pals, my college a cappella group. The weird thing is now, I'm exactly where I wanna be. I got my dream job at Cornell and I'm still just thinking about my old pals. Only now they're the ones I made here. I wish there was a way to know you're in the good old days before you've actually left them. [pause] Someone should write a song about that.", "order": 58072, "speaker": "Andy Bernard"},
  {"season": 9, "episode": 23, "scene": 103, "line": "You take something ordinary, like a piece of paper. It's not much. But if you see it in the right way'And that's what you did with this documentary. But seriously, you made a nine-year documentary and you couldn't once show me doing my origami.", "order": 58073, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 23, "scene": 104, "line": "[walking out of the bathroom discovered by all] Uh..ohh!", "order": 58074, "speaker": "Creed Bratton"},
  {"season": 9, "episode": 23, "scene": 105, "line": "[playing guitar and singing] I saw a friend today. It had been a while. And we forgot each others names. But it didn't matter. 'Cause deep inside the feeling still remained the same'", "order": 58075, "speaker": "Creed Bratton"},
  {"season": 9, "episode": 23, "scene": 106, "line": "Imagine going back and watching a tape of your life. You could see yourself change and make mistakes'and grow up. You could watch yourself fall in love, watch yourself become a husband, become a father. You guys gave that to me. And that's'an amazing gift.", "order": 58076, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 23, "scene": 107, "line": "Hey, Jim, remember flonkerton when you did Office Olympics? It was awesome.", "order": 58077, "speaker": "Phyllis Vance"},
  {"season": 9, "episode": 23, "scene": 107, "line": "Thanks Phyl.", "order": 58078, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 23, "scene": 107, "line": "I still have my medal from that.", "order": 58079, "speaker": "Creed Bratton"},
  {"season": 9, "episode": 23, "scene": 107, "line": "Do you even have a mattress?", "order": 58080, "speaker": "Angela Martin"},
  {"season": 9, "episode": 23, "scene": 107, "line": "No, but I still have my medal from that.", "order": 58081, "speaker": "Creed Bratton"},
  {"season": 9, "episode": 23, "scene": 108, "line": "Oscar. Oscar. [crying] I think I'm gay.", "order": 58082, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 23, "scene": 108, "line": "Why do you say that?", "order": 58083, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 23, "scene": 108, "line": "[sniffles] It's just that I'm so emotional.", "order": 58084, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 23, "scene": 108, "line": "Yeah, but you're not gay. You're not gay.", "order": 58085, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 23, "scene": 108, "line": "No, but maybe the reason'", "order": 58086, "speaker": "Kevin Malone"},
  {"season": 9, "episode": 23, "scene": 108, "line": "You're not gay.", "order": 58087, "speaker": "Oscar Martinez"},
  {"season": 9, "episode": 23, "scene": 109, "line": "[still playing and singing] And all the faces that I know have that same familiar glow. I think I must have known them somewhere once before.", "order": 58088, "speaker": "Creed Bratton"},
  {"season": 9, "episode": 23, "scene": 110, "line": "How did you do it? How did you capture what it was really like? How we felt and how made each other laugh and how we got through the day? How did you do it? Also, how do cameras work?", "order": 58089, "speaker": "Erin Hannon"},
  {"season": 9, "episode": 23, "scene": 111, "line": "Everyday when I came into work, all I wanted to do was leave. So why in the world does it feel so hard to leave right now?", "order": 58090, "speaker": "Darryl Philbin"},
  {"season": 9, "episode": 23, "scene": 112, "line": "It all seems so very arbitrary. I applied for a job at this company because they were hiring. I took a desk at the back because it was empty. But'[chuckles] no matter how you get there or where you end up, human beings have this miraculous gift to make that place home. [standing with two cops] Let's do this.", "order": 58091, "speaker": "Creed Bratton"},
  {"season": 9, "episode": 23, "scene": 113, "line": "I just feel lucky that I got a chance to share my crummy story with anyone out there who thinks they're the only one to take a dump in a paper shredder. You're not alone sister. Let's get a beer sometime.", "order": 58092, "speaker": "Meredith Palmer"},
  {"season": 9, "episode": 23, "scene": 114, "line": "I'm happy that this was all filmed so I can remember everyone and what we did. I worked for a paper company all these years and I never wrote anything down.", "order": 58093, "speaker": "Phyllis Vance"},
  {"season": 9, "episode": 23, "scene": 115, "line": "I sold paper at this company for 12 years. My job was to speak to clients on the phone about quantities and types of copier paper. Even if I didn't love every minute of it, everything I have, I owe to this job. This stupid'wonderful'boring'amazing job.", "order": 58094, "speaker": "Jim Halpert"},
  {"season": 9, "episode": 23, "scene": 116, "line": "I thought it was weird when you picked us to make a documentary. But all in all'I think an ordinary paper company like Dunder Mifflin was a great subject for a documentary. There's a lot of beauty in ordinary things. Isn't that kind of the point?", "order": 58095, "speaker": "Pam Halpert"}
]
