import { useState, useEffect, useMemo } from 'react'
import useDebounce from './useDebounce'
import { fuzzyFuse as fuse } from '../utils/fuse'
import useLocalStorage from './useLocalStorage'

const defaultState: SearchResult = {
    quotes: [],
    _metadata: {
        currentPage: 1,
        searchPhrase: '',
        resultCount: 0,
        pageCount: 0,
    },
}

const useSearch = (delay: number = 1000, pageSize: number = 25) => {
    const [searchPhrase, setSearchPhrase] = useLocalStorage('searchPhrase', '')
    const [season, setSeason] = useLocalStorage('season', '')

    const [currentPage, setCurrentPage] = useState(1)
    const [results, setResults] = useState(defaultState)

    const debouncedSearchPhrase = useDebounce(searchPhrase, delay)

    const hasMore = currentPage < results._metadata.pageCount

    useEffect(() => {
        setCurrentPage(1)
        window.scrollTo(0, 0)
    }, [debouncedSearchPhrase])

    const searchResults = useMemo(() => {
        let filter: SearchFilter = [{ line: debouncedSearchPhrase }]

        if (season) {
            filter.push({ season })
        }
        const resultSet = fuse.search({
            $and: filter,
        })

        const res: SearchResult = {
            quotes: resultSet.slice(0, pageSize + pageSize * (currentPage - 1)),
            _metadata: {
                currentPage: currentPage,
                searchPhrase: debouncedSearchPhrase,
                resultCount: resultSet.length,
                pageCount: Math.ceil(resultSet.length / pageSize),
            },
        }

        return res
    }, [debouncedSearchPhrase, currentPage, season, pageSize])

    useEffect(() => {
        setResults(searchResults)
    }, [searchResults])

    return {
        searchPhrase,
        debouncedSearchPhrase,
        setSearchPhrase,
        results,
        setResults,
        currentPage,
        setCurrentPage,
        hasMore,
        season,
        setSeason,
    }
}

export default useSearch
