import Fuse from 'fuse.js'
import Header from '../components/Header'
import PageWrapper from '../components/PageWrapper'
import QuoteCard from './../components/QuoteCard'
import React from 'react'
import RedoIcon from '@material-ui/icons/Redo'
import UndoIcon from '@material-ui/icons/Undo'
import useQuote from './../hooks/useQuote'
import { Footer } from '../components/Footer'
import { Link, useLocation, useParams } from 'react-router-dom'
import { useState } from 'react'

type QuotePageProps = {}

interface ParamTypes {
    quoteId: string
}

const useQuery = () => {
    return new URLSearchParams(useLocation().search)
}

const QuotePage: React.FC<QuotePageProps> = () => {
    const { quoteId } = useParams<ParamTypes>()
    const query = useQuery()

    const [prev, setPrev] = useState(Number(query.get('prev')))
    const [next, setNext] = useState(Number(query.get('next')))

    const { quotes, seePrevQuote, seeNextQuote } = useQuote([Number(quoteId)], prev, next)

    const handleSeePrevQuote = () => {
        seePrevQuote()
        setPrev((prev) => prev + 1)
    }

    const handleSeeNextQuote = () => {
        seeNextQuote()
        setNext((prev) => prev + 1)
    }

    return (
        <>
            <Header
                backButton={
                    <Link to="/" className="p-2 border rounded-sm hover:opacity-50">
                        Back to search
                    </Link>
                }
            />
            <PageWrapper>
                <div className="flex flex-col flex-1">
                    {/* <CopyToClipboard text={window.location.href.split('?')[0] + `?prev=${prev}&next=${next}`}>
                        <button className="mb-6 btn dark:text-gray-200">Share with friends</button>
                    </CopyToClipboard> */}
                    <button onClick={handleSeePrevQuote} className="mb-6 btn dark:text-gray-200 hover:opacity-50">
                        <UndoIcon className="mr-2" />
                        View previous quote
                    </button>
                    {quotes.quotes.map((q: Fuse.FuseResult<Quote>, i: number) => {
                        return <QuoteCard key={i} quote={q} searchPhrase={''} showContextArrow={false} />
                    })}
                    <button onClick={handleSeeNextQuote} className="mb-6 btn dark:text-gray-200 hover:opacity-50">
                        View next quote <RedoIcon className="ml-2" />
                    </button>
                </div>
                <Footer text={`Built with ❤️ by amac.`} />
            </PageWrapper>
        </>
    )
}

export default QuotePage
