const data = [
    ...require('./seasons/s1'),
    ...require('./seasons/s2'),
    ...require('./seasons/s3'),
    ...require('./seasons/s4'),
    ...require('./seasons/s5'),
    ...require('./seasons/s6'),
    ...require('./seasons/s7'),
    ...require('./seasons/s8'),
    ...require('./seasons/s9'),
]

export default data
