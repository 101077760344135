module.exports = [
  {"season": 8, "episode": 1, "scene": 1, "line": "Oh, for God's sake. [notices Erin planking on parking lot curb]", "order": 43902, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 1, "scene": 2, "line": "Planking is a very stupid and dangerous trend. Basically, you lie like a plank in weird places. That's it. Sometimes you get run over. Welcome to the Internet.", "order": 43903, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 1, "scene": 3, "line": "Planking is one of those things where, eh, you either get it or you don't. And I don't. But I am so excited to be a part of it.", "order": 43904, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 1, "scene": 4, "line": "[exiting elevator] After you my good sir.", "order": 43905, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 1, "scene": 4, "line": "No, I insist.", "order": 43906, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 1, "scene": 4, "line": "I insist.", "order": 43907, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 1, "scene": 5, "line": "The Search Committee finally decided, after a lot of deliberation, on Robert California' for the manager position. Who took one look around and left. He drove down to Florida and convinced Joe to make him CEO... CEO... her own job. He talked her out of her own job. And I don't really know how someone does that. But, anyway, then the position was his to fill.'� And he chose'", "order": 43908, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 1, "scene": 6, "line": "[drumroll with hands] I' it's unbelievable. True, I may have been the second choice, but I was the first-choice's first choice. And, about Dwight, I sensed that he might have some resentment about not getting the job so I sat him down and we had a talk. And I told him, 'I need a really strong number two. I want you to be my enforcer.' Smart, right?", "order": 43909, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 1, "scene": 6, "line": "(planking on top of cabinet] Very smart.", "order": 43910, "speaker": "Kelly Kapoor"},
  {"season": 8, "episode": 1, "scene": 6, "line": "Uh, this has got to stop.", "order": 43911, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 1, "scene": 6, "line": "I can't get down.", "order": 43912, "speaker": "Kelly Kapoor"},
  {"season": 8, "episode": 1, "scene": 7, "line": "K... Kevin! [Kevin planking on Dwight's desk]", "order": 43913, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 1, "scene": 8, "line": "Yeah, at first, I was really disappointed, but I've got a great daily routine going right now. I have upped my karate to eight times a week. I've added boxing - lunches and on weekends. I do kickboxing three times a week. Krav Maga four times a week. An hour of meditation every morning at sunrise and again at sunset. So yeah, I'm doin' great.", "order": 43914, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 1, "scene": 9, "line": "K, I'm gonna need some help. Pam?", "order": 43915, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 1, "scene": 9, "line": "I don't think I should.", "order": 43916, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 1, "scene": 10, "line": "Oh, yeah. Pregnant.", "order": 43917, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 1, "scene": 10, "line": "Right here. Little Michael Scott- [points to her belly]", "order": 43918, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 1, "scene": 10, "line": "No, I told you I don't like that joke. It is a boy. We found out early.", "order": 43919, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 1, "scene": 10, "line": "Much different the second time around. And I have to say; it is nice not being the only pregnant woman in the office.", "order": 43920, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 1, "scene": 11, "line": "Look, it's a 'Little Pregs' [points to her belly] and 'Big Pregs' [points to Pam's]", "order": 43921, "speaker": "Angela Martin"},
  {"season": 8, "episode": 1, "scene": 11, "line": "Wait, when did we start calling it that-", "order": 43922, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 1, "scene": 11, "line": "Isn't it amazing, the difference in our sizes?", "order": 43923, "speaker": "Angela Martin"},
  {"season": 8, "episode": 1, "scene": 11, "line": "Well, I am a few months ahead of you.", "order": 43924, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 1, "scene": 11, "line": "I am having a child with my husband, the Senator. And Pam is having a child with Jim... the great salesman.", "order": 43925, "speaker": "Angela Martin"},
  {"season": 8, "episode": 1, "scene": 12, "line": "Hoist him aloft. C'mon Darryl. Lift, lift Darryl. [trying to lift Kevin off desk]", "order": 43926, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 1, "scene": 12, "line": "I'm lifting.", "order": 43927, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 1, "scene": 13, "line": "Yeah, I wanted the manager job, but I got somethin' much better. This soda. This is mine.", "order": 43928, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 1, "scene": 14, "line": "It might be easier if you take a deep breath, lift from the knees, and shove it up your butt.", "order": 43929, "speaker": "Stanley Hudson"},
  {"season": 8, "episode": 1, "scene": 15, "line": "I came up with a new thing this summer. I act like I'm telling someone how to do somethin'. I go on with a long description and then I say, 'and shove it up your butt.' It's stupid, but it's my thing now.", "order": 43930, "speaker": "Stanley Hudson"},
  {"season": 8, "episode": 1, "scene": 16, "line": "No one should be planking at all.", "order": 43931, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 1, "scene": 16, "line": "Thank you, yes. Dwight, my enforcer, my number two, can you take care of this?", "order": 43932, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 1, "scene": 16, "line": "Say no more.", "order": 43933, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 1, "scene": 17, "line": "[starts attacking plankers] Kids, don't try planking. It's dangerous. [knocks Toby off table] 'Specially with me around.", "order": 43934, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 1, "scene": 18, "line": "You watching that commercial again? [Pam nods] Why do you keep watching it, if you know it's just gonna make you cry?", "order": 43935, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 1, "scene": 18, "line": "Because everything makes me cry, so what's the difference? This dog, he just wants to protect his bone.", "order": 43936, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 1, "scene": 18, "line": "He's got a bank vault. That's a start.", "order": 43937, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 1, "scene": 18, "line": "Not enough though.", "order": 43938, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 1, "scene": 19, "line": "The new CEO works out of the conference room about half the time. But whenever he takes a break, he does these weird walks around the office. And you never know who he's gonna zero in on for these really intense small-talks. You just hope it's not you. And yet, you hope it is you too. It's strange.", "order": 43939, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 1, "scene": 20, "line": "Here we go.", "order": 43940, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 1, "scene": 20, "line": "Hello.", "order": 43941, "speaker": "Robert California"},
  {"season": 8, "episode": 1, "scene": 20, "line": "Robert California. Let's have a conversation.", "order": 43942, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 1, "scene": 20, "line": "Describe your day so far.", "order": 43943, "speaker": "Robert California"},
  {"season": 8, "episode": 1, "scene": 20, "line": "Well, I woke up. And I hit the snooze-", "order": 43944, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 1, "scene": 20, "line": "And when you recount your day, never say you woke up. It's a waste of your time. That's how every day's begun, for everyone, since the dawn of man.", "order": 43945, "speaker": "Robert California"},
  {"season": 8, "episode": 1, "scene": 20, "line": "Very smart, very smart. Suddenly, I was awake. And I've been doing this thing-", "order": 43946, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 1, "scene": 20, "line": "Hey, Robert. We have that 9:30am casual chit-chat scheduled. I emailed you about it last night to confirm. And again this morning.", "order": 43947, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 1, "scene": 21, "line": "First item on the agenda: can I get everyone an extra long Columbus Day weekend. Item number two, connect with the guy. Robert California, what does he think of me? Don't know, super care. Number three, time permitting, we lost our biggest client.", "order": 43948, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 1, "scene": 22, "line": "Pam, pam. Psst. Pam. [motions her over, both looking at Robert's notebook]", "order": 43949, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 1, "scene": 22, "line": "Jim. [motions him over]", "order": 43950, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 1, "scene": 22, "line": "[motions he is on the phone] Can I call you right back? Thank you very much. [comes over] Okay. [sees notebook with two lists of staff names] What is this?", "order": 43951, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 1, "scene": 23, "line": "The Friday before Columbus Day. Thoughts?", "order": 43952, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 1, "scene": 23, "line": "What are your thoughts.", "order": 43953, "speaker": "Robert California"},
  {"season": 8, "episode": 1, "scene": 23, "line": "Just making chit-chat. Kind of a medium year for women's soccer, no?", "order": 43954, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 1, "scene": 24, "line": "What are you looking at?", "order": 43955, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 1, "scene": 24, "line": "It's, it's nothing.", "order": 43956, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 1, "scene": 24, "line": "It can't be nothing.", "order": 43957, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 1, "scene": 24, "line": "Yeah, it can't be nothing.", "order": 43958, "speaker": "Phyllis Vance"},
  {"season": 8, "episode": 1, "scene": 24, "line": "It's just a list of our names, split into two columns.", "order": 43959, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 1, "scene": 24, "line": "What?", "order": 43960, "speaker": "Stanley Hudson"},
  {"season": 8, "episode": 1, "scene": 24, "line": "Okay, just wait one second, alright? I will copy it. Do you have a pen?", "order": 43961, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 1, "scene": 24, "line": "No.", "order": 43962, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 1, "scene": 24, "line": "No. Okay. Um, I'll take a photo of it. Dwight, can you throw me my phone?", "order": 43963, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 1, "scene": 24, "line": "[throws hard, Jim doesn't catch] Nice catch.", "order": 43964, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 1, "scene": 24, "line": "If he comes out, distract him. [takes list to copier]", "order": 43965, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 1, "scene": 24, "line": "We need a warning signal.", "order": 43966, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 1, "scene": 24, "line": "We don't need a warning signal, Kevin. We can see him right there-", "order": 43967, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 1, "scene": 24, "line": "We do.", "order": 43968, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 1, "scene": 24, "line": "I promise you, we don't need a warning-", "order": 43969, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 1, "scene": 24, "line": "WARNING! WARNING! WARNING! WARNING! WARNING!", "order": 43970, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 1, "scene": 24, "line": "Ahhh! [falls out of chair]", "order": 43971, "speaker": "Phyllis Vance"},
  {"season": 8, "episode": 1, "scene": 24, "line": "Phyllis! Phyllis! You okay? You okay?", "order": 43972, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 1, "scene": 24, "line": "Yeah. Yeah. Oh.", "order": 43973, "speaker": "Phyllis Vance"},
  {"season": 8, "episode": 1, "scene": 24, "line": " 2, 3! [pulls Phyllis up]", "order": 43974, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 1, "scene": 24, "line": "Thanks guys.", "order": 43975, "speaker": "Phyllis Vance"},
  {"season": 8, "episode": 1, "scene": 24, "line": "Okay, which side of the list am I on?", "order": 43976, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 1, "scene": 24, "line": "Left.", "order": 43977, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 1, "scene": 24, "line": "Yes!", "order": 43978, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 1, "scene": 24, "line": "Why are you- How do you know?", "order": 43979, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 1, "scene": 25, "line": "Really great list of names guys. Thank you so much. Good work.", "order": 43980, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 1, "scene": 25, "line": "Uh, no, actually. That was in Robert's notebook.", "order": 43981, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 1, "scene": 25, "line": "He left it at reception and we photocopied it.", "order": 43982, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 1, "scene": 25, "line": "Oh, okay. I don't want any part of this.", "order": 43983, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 1, "scene": 25, "line": "Maybe it's a list of people he's gonna fire.", "order": 43984, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 1, "scene": 25, "line": "Okay, it's not that Pam. You know, I was thinking it reminds me of those lists Dwight used to make.", "order": 43985, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 1, "scene": 26, "line": "This is if we were all on a cruise ship and had to divide into lifeboats. This is if we were on a cruise ship and had to divide into life rafts. Here's something. Who would eat who in an 'Alive' situation. No... that can't be it.", "order": 43986, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 1, "scene": 26, "line": "I gotta say. Kinda seems like the left side's the side to be on. Me, Jim, Dwight, Darryl. No offense Pam.", "order": 43987, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 1, "scene": 26, "line": "I don't think it's-", "order": 43988, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 1, "scene": 26, "line": "Scuse me?", "order": 43989, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 1, "scene": 26, "line": "Shhh, Pam. C'mon, don't be such a right-sider.", "order": 43990, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 1, "scene": 27, "line": "Did you guys figure it out?", "order": 43991, "speaker": "Angela Martin"},
  {"season": 8, "episode": 1, "scene": 27, "line": "We couldn't crack it.", "order": 43992, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 1, "scene": 27, "line": "Go in there and just ask the man what it means.", "order": 43993, "speaker": "Stanley Hudson"},
  {"season": 8, "episode": 1, "scene": 27, "line": "He'll know that we looked at his private notebook.", "order": 43994, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 1, "scene": 27, "line": "C'mon, just say you saw the list by accident.", "order": 43995, "speaker": "Phyllis Vance"},
  {"season": 8, "episode": 1, "scene": 27, "line": "I'm already working on this Columbus Day thing for you guys and it's starting to stack up. Feels like a lot. One thing at a time.", "order": 43996, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 1, "scene": 27, "line": "Yeah, that's all you had to do today was ask about Columbus Day?", "order": 43997, "speaker": "Phyllis Vance"},
  {"season": 8, "episode": 1, "scene": 28, "line": "[Andy continuously knocking on door] Yes, for god's sake Andy. Yes, come in.", "order": 43998, "speaker": "Robert California"},
  {"season": 8, "episode": 1, "scene": 28, "line": "What's up? Weird thing. Totally awkward, but you left your notebook on the reception desk.", "order": 43999, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 1, "scene": 28, "line": "Great. Thank you.", "order": 44000, "speaker": "Robert California"},
  {"season": 8, "episode": 1, "scene": 28, "line": "And it was open. And people saw this. And they're just kinda going nuts and like wondering what it is.", "order": 44001, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 1, "scene": 28, "line": "What is this?", "order": 44002, "speaker": "Robert California"},
  {"season": 8, "episode": 1, "scene": 28, "line": "It's a photocopy from your notebook.", "order": 44003, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 1, "scene": 28, "line": "You read my notebook? And photocopied it. And distributed it.", "order": 44004, "speaker": "Robert California"},
  {"season": 8, "episode": 1, "scene": 28, "line": "No. They did. And they asked me to ask about it.", "order": 44005, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 1, "scene": 28, "line": "Ah, please. Here's what it is. It's a doodle.", "order": 44006, "speaker": "Robert California"},
  {"season": 8, "episode": 1, "scene": 28, "line": "What?", "order": 44007, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 1, "scene": 28, "line": "Some people doodle at work when they let their mind run. They draw houses, penises. Funny how the houses are always colonials and the penises are always circumcised. Don't you think? Well, I doodle too, but I'm not an artist. So I draw words and lists.", "order": 44008, "speaker": "Robert California"},
  {"season": 8, "episode": 1, "scene": 28, "line": "That is fascinating. And, by the way, I am so glad I asked. People were just sort of- Did you just move my name?", "order": 44009, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 1, "scene": 28, "line": "Might as well have been sketching a cube.", "order": 44010, "speaker": "Robert California"},
  {"season": 8, "episode": 1, "scene": 29, "line": "Okay. Robert's in the annex, everybody think quick. What do these groups have in common?", "order": 44011, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 1, "scene": 29, "line": "Maybe we're s'posed to do it with people in our group.", "order": 44012, "speaker": "Meredith Palmer"},
  {"season": 8, "episode": 1, "scene": 29, "line": "That's not it.", "order": 44013, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 1, "scene": 29, "line": "People in the other group.", "order": 44014, "speaker": "Meredith Palmer"},
  {"season": 8, "episode": 1, "scene": 29, "line": "Mmum, still wrong.", "order": 44015, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 1, "scene": 29, "line": "Stanley, you do puzzles all day. Whatdo we got?", "order": 44016, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 1, "scene": 29, "line": "Well, you take the first letter from each name, assign it a number, add 'em all up, and SHOVE IT UP YOUR BUTT!", "order": 44017, "speaker": "Stanley Hudson"},
  {"season": 8, "episode": 1, "scene": 29, "line": "Thank you. A little much needed comic relief, but we really need to figure this out guys.", "order": 44018, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 1, "scene": 29, "line": "I know! It's alphabetical.", "order": 44019, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 1, "scene": 29, "line": "[separately] No.", "order": 44020, "speaker": "Everyone"},
  {"season": 8, "episode": 1, "scene": 29, "line": "No, it's not. Here's how we find out. Let's line up and compare the lines, see if we learn anything. Okay, left-siders over here. Right-siders, line up over here. Face each other. Match it by height and relative weight. Let's just size each other up here and left side of the list' ATTACK!", "order": 44021, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 1, "scene": 29, "line": "Wait, wait, wait! Stop! Stop! Stop! Will you stop?! [Kelly and Erin screaming as Dwight bashes their heads together, everyone attacking each other and yelling]", "order": 44022, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 1, "scene": 29, "line": "Dwight!", "order": 44023, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 1, "scene": 29, "line": "Hey!", "order": 44024, "speaker": "Meredith Palmer"},
  {"season": 8, "episode": 1, "scene": 29, "line": "Warning! Warning! Warning!", "order": 44025, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 1, "scene": 29, "line": "[enters room, everyone quiets and separates] I'd like to invite the following people to join me for lunch: Jim, Dwight, Angela, Darryl, Kevin, Toby, Phyllis, Oscar.", "order": 44026, "speaker": "Robert California"},
  {"season": 8, "episode": 1, "scene": 29, "line": "That's great. Let's do this guys.", "order": 44027, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 1, "scene": 29, "line": "Alright, well, I will see you in a bit.", "order": 44028, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 1, "scene": 29, "line": "I love you so much. [starting to cry]", "order": 44029, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 1, "scene": 29, "line": "Hey. It's nothing. Alright? I'll text you when we get there. Let you know what's going on.", "order": 44030, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 1, "scene": 29, "line": "Okay. [turns to computer]", "order": 44031, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 1, "scene": 29, "line": "Nope. No dog video.", "order": 44032, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 1, "scene": 29, "line": "Okay.", "order": 44033, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 1, "scene": 29, "line": "Okay. See you guys.", "order": 44034, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 1, "scene": 29, "line": "Well, we should all be really excited about our very own pizza party. Pizza partay! Pizza! Partay! Pizza! Partay!", "order": 44035, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 1, "scene": 30, "line": "Jim, your daughter, Cecilia. What does she think of the street?", "order": 44036, "speaker": "Robert California"},
  {"season": 8, "episode": 1, "scene": 30, "line": "Uhhh, the street?", "order": 44037, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 1, "scene": 30, "line": "Sesame street.", "order": 44038, "speaker": "Robert California"},
  {"season": 8, "episode": 1, "scene": 30, "line": "Oh, I didn't know anybody called it- She likes it a lot- she, uh, loves Elmo.", "order": 44039, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 1, "scene": 30, "line": "Elmo, god's sake, it's the Elmo era.", "order": 44040, "speaker": "Robert California"},
  {"season": 8, "episode": 1, "scene": 30, "line": "Right.", "order": 44041, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 1, "scene": 30, "line": "Sesame Street was created to reflect the environment of the children watching it. Complete self-absorption of Elmo is brilliantly reflective of our time. Ours is a cultural ghetto. Wouldn't you agree.", "order": 44042, "speaker": "Robert California"},
  {"season": 8, "episode": 1, "scene": 30, "line": "Yeah, she does like Elmo.", "order": 44043, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 1, "scene": 30, "line": "Cultural ghetto? Totally- totally agree.", "order": 44044, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 1, "scene": 30, "line": "Completely.", "order": 44045, "speaker": "Phyllis Vance"},
  {"season": 8, "episode": 1, "scene": 30, "line": "Apt. Apt analysis, Robert.", "order": 44046, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 1, "scene": 30, "line": "The thing that I like about Elmo is the tickling.", "order": 44047, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 1, "scene": 30, "line": "I should not be here. I'm in the- I was in the wrong- I'm- I'm sorry. Uh, just picture me back there. I- I was never here.", "order": 44048, "speaker": "Toby Flenderson"},
  {"season": 8, "episode": 1, "scene": 31, "line": "Great group! Pizza party!", "order": 44049, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 1, "scene": 31, "line": "How is this a pizza party?", "order": 44050, "speaker": "Kelly Kapoor"},
  {"season": 8, "episode": 1, "scene": 31, "line": "Well, why don't you ask me again when the five pizzas get here.", "order": 44051, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 1, "scene": 31, "line": "Yeah, well, that's just pizza. You need at least one other element for it to be a party.", "order": 44052, "speaker": "Kelly Kapoor"},
  {"season": 8, "episode": 1, "scene": 31, "line": "Okay. You guys ever had Margarita pizza?", "order": 44053, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 1, "scene": 31, "line": "What's that?", "order": 44054, "speaker": "Stanley Hudson"},
  {"season": 8, "episode": 1, "scene": 31, "line": "Fresh tomato with a dollop of mozzarella cheese.", "order": 44055, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 1, "scene": 31, "line": "That's pizza.", "order": 44056, "speaker": "Stanley Hudson"},
  {"season": 8, "episode": 1, "scene": 31, "line": "That's regular pizza.", "order": 44057, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 1, "scene": 32, "line": "You know, I feel comfortable enough now to ask you this question... what made you pick this group?", "order": 44058, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 1, "scene": 32, "line": "I just think you guys are winners and I wanted to have lunch with you.", "order": 44059, "speaker": "Robert California"},
  {"season": 8, "episode": 1, "scene": 32, "line": "Okay!", "order": 44060, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 1, "scene": 32, "line": "[separately] Awww.", "order": 44061, "speaker": "Everyone"},
  {"season": 8, "episode": 1, "scene": 32, "line": "Well, what about the other guys.", "order": 44062, "speaker": "Phyllis Vance"},
  {"season": 8, "episode": 1, "scene": 32, "line": "Losers.", "order": 44063, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 1, "scene": 32, "line": "No.", "order": 44064, "speaker": "Robert California"},
  {"season": 8, "episode": 1, "scene": 32, "line": "C'mon.", "order": 44065, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 1, "scene": 32, "line": "I don't- I don't wanna say-", "order": 44066, "speaker": "Robert California"},
  {"season": 8, "episode": 1, "scene": 32, "line": "C'mon, c'mon.", "order": 44067, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 1, "scene": 32, "line": "No, no.", "order": 44068, "speaker": "Robert California"},
  {"season": 8, "episode": 1, "scene": 32, "line": "C'mon.", "order": 44069, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 1, "scene": 32, "line": "Ha, I guess I think they're losers.", "order": 44070, "speaker": "Robert California"},
  {"season": 8, "episode": 1, "scene": 32, "line": "Ha ha, I knew it! Ha ha! Yes! Woo!", "order": 44071, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 1, "scene": 32, "line": "Probably shouldn't have said that. [Jim's jaw drops]", "order": 44072, "speaker": "Robert California"},
  {"season": 8, "episode": 1, "scene": 33, "line": "Ah hah! Their interpretation of Margarita pizza. Fans of classic pizza will be psyched.", "order": 44073, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 1, "scene": 33, "line": "[Pam's phone vibrates] Oh, text from Jim. 'This is getting very weird. Will explain later.'", "order": 44074, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 1, "scene": 33, "line": "[Everyone's phone vibrates] Oh, text from Kevin.", "order": 44075, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 1, "scene": 33, "line": "Suck it losers.'", "order": 44076, "speaker": "Meredith Palmer"},
  {"season": 8, "episode": 1, "scene": 34, "line": "Okay, not to point out the glaringly obvious, but doesn't the fact that I'm in this group make anyone feel just a little bit better? Oh! This crust is sharp!", "order": 44077, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 1, "scene": 34, "line": "I used to be young and cute and sorta funny and I could do those cute, little cartoons. And everyone who came through here was like, 'Who's that receptionist? I like her.' Now I'm just a fat mom. Yeah, and you take one look at me and you're like, 'Oh, loser.'", "order": 44078, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 1, "scene": 34, "line": "C'mon Pam. Chins up, okay? Bad joke. Look around this room. Does this look like a group of losers? Seriously?", "order": 44079, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 1, "scene": 34, "line": "Oh. Oh God.", "order": 44080, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 1, "scene": 34, "line": "[door opens, others enter] Oh. Hey guys! We had so much fun. We had Margarita pizza. We all hung out. Got to know each other better. How was your lunch?", "order": 44081, "speaker": "Kelly Kapoor"},
  {"season": 8, "episode": 1, "scene": 34, "line": "It was excellent.", "order": 44082, "speaker": "Angela Martin"},
  {"season": 8, "episode": 1, "scene": 34, "line": "Good times.", "order": 44083, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 1, "scene": 34, "line": "Yeah?", "order": 44084, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 1, "scene": 34, "line": "Oh, we did. It was the best time. And you know what? Now it's over. Back to work everyone. You too Andy.", "order": 44085, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 1, "scene": 35, "line": "I knew it. I just knew, my whole life, that everyone was wrong about me. My parents, my teachers, my friends, the doctors. Everyone.", "order": 44086, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 1, "scene": 36, "line": "Well, that was certainly an odd lunch. Is everything alright?", "order": 44087, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 1, "scene": 36, "line": "Yeah, I'm fine.", "order": 44088, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 1, "scene": 37, "line": "[spraying Meredith and Creed with water gun] Loser. Losers. [Dwight holding up 'L' to his forehead]", "order": 44089, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 1, "scene": 37, "line": "[on the phone, while getting sprayed] Just take the casserole out of the- Take it out of the refrigerator and put it in the oven. It'll be fine, just leave it in for 20 minutes.", "order": 44090, "speaker": "Meredith Palmer"},
  {"season": 8, "episode": 1, "scene": 38, "line": "When I was a salesman I could just be like 'Not my job, not my prob. I'm going to the warehouse to polish my knob.' Metaphorically, of course. But now, it is my job and my prob.", "order": 44091, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 1, "scene": 39, "line": "Hi, Robert? Can you come out here please it's really important. Just wanted to clarify something. Some people here are under the misconception that some people may be considered, uh, let's say top-tier and others would be second-tier.", "order": 44092, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 1, "scene": 39, "line": "I never said that.", "order": 44093, "speaker": "Robert California"},
  {"season": 8, "episode": 1, "scene": 39, "line": "Thank you. Great. Robert. I said winners and losers. Is that what you're talking about?", "order": 44094, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 1, "scene": 39, "line": "Oh, that, it might- That might actually be what I'm thinking of. Can you clarify that?", "order": 44095, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 1, "scene": 39, "line": "Let me tell you some things I find productive. Positive reinforcement. Negative reinforcement. Honesty. I'll tell you some things I find unproductive. Constantly worrying about where you stand based on inscrutable social clues. And then, inevitably reframing it all in a reassuring way so that you can get to sleep at night. No, I do not believe in that at all. If I invited you to lunch, I think you're a winner. If I didn't, I don't. But I just met you all. Life is long, opinions change. Winners prove me right. Losers, prove me wrong.", "order": 44096, "speaker": "Robert California"},
  {"season": 8, "episode": 1, "scene": 39, "line": "Whew, well. I guess that's that.", "order": 44097, "speaker": "Phyllis Vance"},
  {"season": 8, "episode": 1, "scene": 39, "line": "No. No, no.", "order": 44098, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 1, "scene": 39, "line": "Andy, don't go in there!", "order": 44099, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 1, "scene": 39, "line": "I'm going in there.", "order": 44100, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 1, "scene": 40, "line": "I know that every time I talk to you things just seem to get worse. But, you don't know these people and I do. And if I let you work with faulty information, well, then I'm not doing my job as regional manager. So, please take this pen and change your list.", "order": 44101, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 1, "scene": 40, "line": "I'm not gonna change my list, Andy, and I don't use ballpoint pens.", "order": 44102, "speaker": "Robert California"},
  {"season": 8, "episode": 1, "scene": 40, "line": "Well, then I will make a new list for you. Stanley... you may think he's a lazy grump, but did you know that he has the most consistently high sales numbers of anyone in this office? And you may think he's hard to love, but did you know that he's in, not one, but two long-term romantic relationships?", "order": 44103, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 1, "scene": 40, "line": "I did not know about the sales figures.", "order": 44104, "speaker": "Robert California"},
  {"season": 8, "episode": 1, "scene": 40, "line": "Meredith Palmer... Supplier relations. The word 'no' not even in her vocabulary. And just to show you that I'm being fair. You had Gabe in the loser column. I think that is astute. Good call. Pam, easily the most creative and kind person I have ever worked with.", "order": 44105, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 1, "scene": 40, "line": "Jim, shut the door. This is just gross.", "order": 44106, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 1, "scene": 40, "line": "Shhh.", "order": 44107, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 1, "scene": 40, "line": "Erin Hannon, the receptionist and my closest confidant. A winner if there ever was one.", "order": 44108, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 1, "scene": 41, "line": "I like my new group. I liked my old group.", "order": 44109, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 1, "scene": 42, "line": "Are we done?", "order": 44110, "speaker": "Robert California"},
  {"season": 8, "episode": 1, "scene": 42, "line": "Yes. No! The Friday before Columbus Day, we're gonna take a half-day, so that everyone can get a jump on the long weekend.", "order": 44111, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 1, "scene": 42, "line": "You want a three and half day weekend for Columbus Day?", "order": 44112, "speaker": "Robert California"},
  {"season": 8, "episode": 1, "scene": 42, "line": "Yes, I do.", "order": 44113, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 1, "scene": 42, "line": "And you are aware that Columbus and his legions committed genocide against an entire civilization of Native Americans.", "order": 44114, "speaker": "Robert California"},
  {"season": 8, "episode": 1, "scene": 42, "line": "I don't care. [Robert smiles as Andy exits]", "order": 44115, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 1, "scene": 43, "line": "Hey guys. So Columbus Day, we got that half-day on Friday.", "order": 44116, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 1, "scene": 43, "line": "We get that every year.", "order": 44117, "speaker": "Stanley Hudson"},
  {"season": 8, "episode": 1, "scene": 43, "line": "Well, you got it this year too.", "order": 44118, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 1, "scene": 43, "line": "Good night, Andy.", "order": 44119, "speaker": "Phyllis Vance"},
  {"season": 8, "episode": 1, "scene": 43, "line": "Night.", "order": 44120, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 1, "scene": 43, "line": "Bye.", "order": 44121, "speaker": "Angela Martin"},
  {"season": 8, "episode": 1, "scene": 43, "line": "Good night Andy.", "order": 44122, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 1, "scene": 43, "line": "Good night.", "order": 44123, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 1, "scene": 43, "line": "Night.", "order": 44124, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 1, "scene": 44, "line": "Alright, I'm gonna go warm up the car.", "order": 44125, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 1, "scene": 44, "line": "Okay. Oh, uh, you dropped something. Jim?", "order": 44126, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 1, "scene": 45, "line": "Okay, I know I've been crying easily today, but- [shows unfolded paper Jim dropped, shows two lists, 'Pam' and 'Cece' on one side, 'Everything else' on the other] I mean, that's just pretty killer, right? I mean, maybe it's stupid. No. [starts crying] It's wonderful. I'm gonna frame it. I can always unframe it.", "order": 44127, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 1, "scene": 46, "line": "I gotta say, I think it's kinda cool not knowing. It's like one of those movies that ends on a note of mystery. Did the butler do it? Are they ghosts? You know? It's kinda sophisticated.", "order": 44128, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 1, "scene": 46, "line": "Everybody hates those endings!", "order": 44129, "speaker": "Kelly Kapoor"},
  {"season": 8, "episode": 1, "scene": 46, "line": "[weepy] Yeah, I hate those endings.", "order": 44130, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 1, "scene": 47, "line": "I- [Sees Kevin looking at the list upside down and turns it.]", "order": 44131, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 1, "scene": 47, "line": "I was looking for patterns.", "order": 44132, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 1, "scene": 47, "line": "I know.", "order": 44133, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 1, "scene": 48, "line": "It's all a mind game. He wanted us to see the list. He's a genius. You guys just don't get him.", "order": 44134, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 1, "scene": 48, "line": "I made a list like this for Congress when I worked in Hollywood in the 50's, they're meaningless.", "order": 44135, "speaker": "Creed Bratton"},
  {"season": 8, "episode": 1, "scene": 48, "line": "Guys. Really, it's ok. Everyone has made a really solid first impression, I don't think there's any reason for anybody to worry.", "order": 44136, "speaker": "Toby Flenderson"},
  {"season": 8, "episode": 1, "scene": 49, "line": "I am on the wrong side, and I am freaking out here.", "order": 44137, "speaker": "Toby Flenderson"},
  {"season": 8, "episode": 1, "scene": 50, "line": "[grunting, trying with Jim to lift Kevin planking on his desk] Man, it's like hoisting a manatee. I can't get a grip it's so vast.", "order": 44138, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 1, "scene": 50, "line": "Ryan? A little help?", "order": 44139, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 1, "scene": 50, "line": "God. How do whalers do it?", "order": 44140, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 1, "scene": 51, "line": "Here's the way trends move across the country. They start in Japan. L.A. And New York get it soon after that. Seattle looks at it, decides not to do it. Chicago gets it three months later. Then it travels down the Mississippi, OK? All the red states start doing it. Good Morning America does a piece about it. And then, it shows up in Scranton.", "order": 44141, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 1, "scene": 52, "line": "Creed! Wha- [Shoves Creed with his foot, Creed grunts] This is a safety issue with you. There's no way to know if you're dead!", "order": 44142, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 1, "scene": 53, "line": "Good morning. [suitcase falls and he laughs awkwardly]", "order": 44143, "speaker": "Gabe Lewis"},
  {"season": 8, "episode": 1, "scene": 54, "line": "Corporate wants me up here, Dunder Mifflin wants me down there. What they worked out is that Monday, Wednesday and Friday I'm here in Scranton, Tuesday Thursday I'm in Tallahassee, I mean I feel like Vera Farmiga in Up in the Air, you know? More money more problems. Although, I didn't get a raise, so you know. Same money, more problems.", "order": 44144, "speaker": "Gabe Lewis"},
  {"season": 8, "episode": 1, "scene": 55, "line": "It's called owling. You'll read about it in like eight months.", "order": 44145, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 1, "scene": 56, "line": "Uh, I will have the chicken piccata with a side salad.", "order": 44146, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 1, "scene": 56, "line": "That sounds good.", "order": 44147, "speaker": "Robert California"},
  {"season": 8, "episode": 1, "scene": 56, "line": "Chicken piccata, side salad.", "order": 44148, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 1, "scene": 56, "line": "Chicken piccata, salad on the side please.", "order": 44149, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 1, "scene": 56, "line": "I'll start with a side salad and uh, oh, chicken piccata.", "order": 44150, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 1, "scene": 56, "line": "Chicken piccata, side salad.", "order": 44151, "speaker": "Toby Flenderson"},
  {"season": 8, "episode": 1, "scene": 56, "line": "Side salad, chicken piccata on the side.", "order": 44152, "speaker": "Angela Martin"},
  {"season": 8, "episode": 1, "scene": 56, "line": "I'll start with a side salad, then a chicken piccata.", "order": 44153, "speaker": "Phyllis Vance"},
  {"season": 8, "episode": 1, "scene": 56, "line": "Steak. Rare.", "order": 44154, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 1, "scene": 56, "line": "Jim, you alright? What are you doing?", "order": 44155, "speaker": "Robert California"},
  {"season": 8, "episode": 1, "scene": 56, "line": "Good. What? Nothin'", "order": 44156, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 1, "scene": 57, "line": "Oh! Text from Jim. 'This is...' hmm.", "order": 44157, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 1, "scene": 57, "line": "Profound man, your husband.", "order": 44158, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 1, "scene": 58, "line": "One more. To our boss!", "order": 44159, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 1, "scene": 58, "line": "Hey!", "order": 44160, "speaker": "All"},
  {"season": 8, "episode": 1, "scene": 58, "line": "I don't think we have to do this again cause it's gonna take a whole. [everyone clinks glasses]", "order": 44161, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 1, "scene": 58, "line": "You know what? Let us treat you. What do you say? It has been such a pleasure.", "order": 44162, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 1, "scene": 58, "line": "I can expense it.", "order": 44163, "speaker": "Robert California"},
  {"season": 8, "episode": 1, "scene": 58, "line": "Let him expense it, Dwight.", "order": 44164, "speaker": "Darryl Philbin & Oscar Martinez"},
  {"season": 8, "episode": 1, "scene": 58, "line": "I insist. It is gonna be our treat, please?", "order": 44165, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 1, "scene": 58, "line": "Thank you.", "order": 44166, "speaker": "Robert California"},
  {"season": 8, "episode": 1, "scene": 58, "line": "Hi, Mr. Shrute. I've waited on you before. I just wanted to let you know that gratuity is not included in the bill and tips are expected.", "order": 44167, "speaker": "Waiter"},
  {"season": 8, "episode": 1, "scene": 58, "line": "Oh, tips are expected?", "order": 44168, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 1, "scene": 58, "line": "Yes sir.", "order": 44169, "speaker": "Waiter"},
  {"season": 8, "episode": 1, "scene": 58, "line": "Then expect to be shocked. Jim, you owe me $14.75. Oscar, $14.75. Darryl....", "order": 44170, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 1, "scene": 59, "line": "My entire childhood, I was the one left out, and I said to myself: Gabe, if you just achieve some success, you'll be part of them. You'll be part of the happy ones. But instead, people just used my success as a new way to shut me out.", "order": 44171, "speaker": "Gabe Lewis"},
  {"season": 8, "episode": 1, "scene": 59, "line": "Hey, this ain't no Breakfast Club, bitch.", "order": 44172, "speaker": "Meredith Palmer"},
  {"season": 8, "episode": 1, "scene": 60, "line": "Whoo! Yeah! Yeah!", "order": 44173, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 1, "scene": 60, "line": "Come on, man.", "order": 44174, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 1, "scene": 60, "line": "Winners!", "order": 44175, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 2, "scene": 1, "line": "Hey, so this isn't matching up with this'and I'm not sure which one's right. Can you just hunt down the original for me?", "order": 44176, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 2, "scene": 1, "line": "Yes. Me do.", "order": 44177, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 2, "scene": 1, "line": "Alright.", "order": 44178, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 2, "scene": 2, "line": "Hey Kev, what were you saying before about the paperwork?", "order": 44179, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 2, "scene": 2, "line": "Me do it now. Go. Stop worry.", "order": 44180, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 2, "scene": 2, "line": "Kevin, do you feel OK?", "order": 44181, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 2, "scene": 2, "line": "Me feel good. Body strong. Sleep big last night.", "order": 44182, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 2, "scene": 2, "line": "Yeah, I think we should get him to the hospital.", "order": 44183, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 2, "scene": 2, "line": "Yeah, alright Kev why don't you come with us?", "order": 44184, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 2, "scene": 2, "line": "No, guys.", "order": 44185, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 2, "scene": 2, "line": "No, he's fine.", "order": 44186, "speaker": "Angela Martin"},
  {"season": 8, "episode": 2, "scene": 2, "line": "He's fine.", "order": 44187, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 2, "scene": 2, "line": "He's always been like that.", "order": 44188, "speaker": "Angela Martin"},
  {"season": 8, "episode": 2, "scene": 2, "line": "No he hasn't.", "order": 44189, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 2, "scene": 2, "line": "I mean, he's gotten worse over the years'.", "order": 44190, "speaker": "Angela Martin"},
  {"season": 8, "episode": 2, "scene": 2, "line": "He's making a statement. It's an ironic comment on our expectations of him. A funhouse image of our model of Kevin.", "order": 44191, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 2, "scene": 2, "line": "You keep think that.", "order": 44192, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 2, "scene": 3, "line": "Me mechanic not speak English. But he know what me mean when me say 'car no go', and we best friends. So me think: why waste time, say lot word when few word do trick?", "order": 44193, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 2, "scene": 4, "line": "Kevin, I appreciate what you're trying to do.", "order": 44194, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 2, "scene": 4, "line": "Thank.", "order": 44195, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 2, "scene": 4, "line": "Here, we have a word code, the same way we have a dress code. And what we're talking about is'basically the speech equivalent' to just wearing underpants. Sometimes words, you no need use'but need need for talk talk.", "order": 44196, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 2, "scene": 4, "line": "But save time. More success.", "order": 44197, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 2, "scene": 4, "line": "Does it save time though? 'Cause we've been here for about an hour.", "order": 44198, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 2, "scene": 4, "line": "No me fault.", "order": 44199, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 2, "scene": 4, "line": "Kevin, at most you're saving a microscopic amount of time.", "order": 44200, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 2, "scene": 4, "line": "Many small time make big time.", "order": 44201, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 2, "scene": 4, "line": "What are you gonna do with all this time?", "order": 44202, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 2, "scene": 4, "line": "See world.", "order": 44203, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 2, "scene": 4, "line": "Kevin, you cannot possibly save enough time to see the world.", "order": 44204, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 2, "scene": 4, "line": "K, Kevin, are you saying 'See the world'? or 'Sea World?'", "order": 44205, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 2, "scene": 4, "line": "See world. Oceans. Fish. Jump. China.", "order": 44206, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 2, "scene": 4, "line": "No, see? Right there, that's the problem with your method. 'Cause I still don't know if you're saying 'Sea World' or 'see the world,' and it's taking a lot of time to explain it.", "order": 44207, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 2, "scene": 4, "line": "Fine, fine. I'll talk normally.", "order": 44208, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 2, "scene": 5, "line": "When me President, they see. [Nodding and smiling] They see.", "order": 44209, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 2, "scene": 6, "line": "This week we are rolling out the brand new Sabre tablet'.the Pyramid. [holds up triangle shaped touch pad]", "order": 44210, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 2, "scene": 6, "line": "Ooh, why is it shaped like that?", "order": 44211, "speaker": "Phyllis Vance"},
  {"season": 8, "episode": 2, "scene": 6, "line": "So, you can tell your clients: 'Unleash the power of the pyramid.'", "order": 44212, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 2, "scene": 6, "line": "It's huge. How much does it weigh?", "order": 44213, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 2, "scene": 6, "line": "Oh no no no. Without the battery pack and optional memory booster, it's barely three pounds.", "order": 44214, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 2, "scene": 6, "line": "How much memory does it have without the booster?", "order": 44215, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 2, "scene": 6, "line": "Fifty L.", "order": 44216, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 2, "scene": 6, "line": "I'm sorry,' L'?", "order": 44217, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 2, "scene": 6, "line": "How many L to a K?", "order": 44218, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 2, "scene": 6, "line": "You're really going to want the booster.", "order": 44219, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 2, "scene": 6, "line": "How on earth are we supposed to sell'?", "order": 44220, "speaker": "Stanley Hudson"},
  {"season": 8, "episode": 2, "scene": 6, "line": "I'll take five.", "order": 44221, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 2, "scene": 6, "line": "Andy, don't make us sell this stupid thing.", "order": 44222, "speaker": "Phyllis Vance"},
  {"season": 8, "episode": 2, "scene": 6, "line": "Oh, no no no no no. This is Dwight's meeting.", "order": 44223, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 2, "scene": 6, "line": "Thank you.", "order": 44224, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 2, "scene": 6, "line": "I just wanted to pop in and get your opinions on ties'and tie clips. Which combo do you think Robert is going to like more? [To Oscar] What do you think, C-SPAN?", "order": 44225, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 2, "scene": 6, "line": "'C-SPAN'?", "order": 44226, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 2, "scene": 6, "line": "Yeah. C-SPAN, cocker spaniel. Spaniel because of your Spanish bloodline and cocker cause''", "order": 44227, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 2, "scene": 6, "line": "Is this really the best use of our collective time?", "order": 44228, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 2, "scene": 6, "line": "I am still forming a first impression with Robert. Once it is formed, we can all relax. Ok, I'm sorry to hijack your meeting , D dub dog'had to pull rank.", "order": 44229, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 2, "scene": 6, "line": "[to group] OK, let's look at some ties.", "order": 44230, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 2, "scene": 7, "line": "Here's how I'm going to help out from now on. I'm going to not care, and I'm going to sit around quietly waiting for Andy's inevitable demise.", "order": 44231, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 2, "scene": 8, "line": "[To Oscar] Your friend Neil Patrick Harris really made me laugh the other night.", "order": 44232, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 2, "scene": 8, "line": "Um, D-Dog, you have a message.", "order": 44233, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 2, "scene": 8, "line": "Erin, you don't need to call him that.", "order": 44234, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 2, "scene": 8, "line": "Andy wants us to, P-Dog.", "order": 44235, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 2, "scene": 8, "line": "It's ok E-Dog, just who called?", "order": 44236, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 2, "scene": 8, "line": "Justine. She said she's coming by later.", "order": 44237, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 2, "scene": 8, "line": "Your ex-wife?", "order": 44238, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 2, "scene": 8, "line": "Wait, I thought she was a **** and you ******* hated her guts?", "order": 44239, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 2, "scene": 8, "line": "No no no no. I like her.", "order": 44240, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 2, "scene": 8, "line": "Well I'm just quoting you. I would never say that about her. I don't know the woman.", "order": 44241, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 2, "scene": 8, "line": "Nah man, we get along now. Real well.", "order": 44242, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 2, "scene": 8, "line": "Wow. Alright. Can't wait to meet her.", "order": 44243, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 2, "scene": 8, "line": "I'll introduce you.", "order": 44244, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 2, "scene": 9, "line": "Yeah, we had a few fights, I suppose. But last night we put a lot of that to bed. I can't tell you what I did with my ex wife last night'. I have to sing it. [singing] We took a shower, we were naked. We ska dap dap doo doo doo doo doo doo doo.", "order": 44245, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 2, "scene": 10, "line": "[to Robert California] Hi Dad!...... Ahhh'.oh boy.", "order": 44246, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 2, "scene": 10, "line": "Hello, Andy. Excellent tie.", "order": 44247, "speaker": "Robert California"},
  {"season": 8, "episode": 2, "scene": 10, "line": "Would you be requiring a cold beverage while you're here?", "order": 44248, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 2, "scene": 10, "line": "I'd love some coffee.", "order": 44249, "speaker": "Robert California"},
  {"season": 8, "episode": 2, "scene": 11, "line": "I was looking over your projections, and I think we can do better.", "order": 44250, "speaker": "Robert California"},
  {"season": 8, "episode": 2, "scene": 11, "line": "Are you factoring in the' whole national 'economy'. declining and all that?", "order": 44251, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 2, "scene": 11, "line": "Andy, do you know why I chose you?", "order": 44252, "speaker": "Robert California"},
  {"season": 8, "episode": 2, "scene": 11, "line": "I think I can sum it up with what I think is your favorite ice cream flavor'.vanilla?", "order": 44253, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 2, "scene": 11, "line": "Vanilla? No no no no. You'll never guess in a million billion years you'll never guess.", "order": 44254, "speaker": "Robert California"},
  {"season": 8, "episode": 2, "scene": 11, "line": "You were saying you chose me'. There was a reason?", "order": 44255, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 2, "scene": 11, "line": "Andy, can you inspire? Do you have that skill set?", "order": 44256, "speaker": "Robert California"},
  {"season": 8, "episode": 2, "scene": 12, "line": "Can I inspire? [laughs] I don't know!.....I don't know.", "order": 44257, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 2, "scene": 13, "line": "Oh! Thank you. Uh'.", "order": 44258, "speaker": "Robert California"},
  {"season": 8, "episode": 2, "scene": 13, "line": "Oh, sorry.", "order": 44259, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 2, "scene": 13, "line": "You can just put it down.", "order": 44260, "speaker": "Robert California"},
  {"season": 8, "episode": 2, "scene": 13, "line": "Oh", "order": 44261, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 2, "scene": 13, "line": "That is very cold.", "order": 44262, "speaker": "Robert California"},
  {"season": 8, "episode": 2, "scene": 13, "line": "Yeah. It's old. [smiling]", "order": 44263, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 2, "scene": 13, "line": "Why would I'..?", "order": 44264, "speaker": "Robert California"},
  {"season": 8, "episode": 2, "scene": 13, "line": "I asked if you wanted a cold beverage and you said 'coffee''.", "order": 44265, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 2, "scene": 13, "line": "Why don't we get Robert a nice hot fresh cup and I will have this.", "order": 44266, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 2, "scene": 13, "line": "Andy, you don't want that.", "order": 44267, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 2, "scene": 13, "line": "I've been craving a freezing cup of old coffee. [sips] Mmm'.", "order": 44268, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 2, "scene": 13, "line": "Sorry.", "order": 44269, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 2, "scene": 13, "line": "You like her.", "order": 44270, "speaker": "Robert California"},
  {"season": 8, "episode": 2, "scene": 13, "line": "I do.", "order": 44271, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 2, "scene": 13, "line": "She likes you.", "order": 44272, "speaker": "Robert California"},
  {"season": 8, "episode": 2, "scene": 13, "line": "You know, we've both been into each other at different times and just never really synced up. Now we're in this weird dance'.", "order": 44273, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 2, "scene": 13, "line": "[interrupting] I'm afraid you've lost my interest.", "order": 44274, "speaker": "Robert California"},
  {"season": 8, "episode": 2, "scene": 14, "line": "Let me call you back.", "order": 44275, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 2, "scene": 14, "line": "I gotta go.", "order": 44276, "speaker": "Meredith Palmer"},
  {"season": 8, "episode": 2, "scene": 14, "line": "If the office superstore was supposed to put us little suppliers out of business, why are we still here?", "order": 44277, "speaker": "Robert California"},
  {"season": 8, "episode": 2, "scene": 14, "line": "Ah'", "order": 44278, "speaker": "Robert California"},
  {"season": 8, "episode": 2, "scene": 14, "line": "This is where we go", "order": 44279, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 2, "scene": 14, "line": "[chuckling] Oh, you'd go someplace else. That's not it, that's not the answer.", "order": 44280, "speaker": "Robert California"},
  {"season": 8, "episode": 2, "scene": 14, "line": "It's a answer.", "order": 44281, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 2, "scene": 14, "line": "It's a wrong answer.", "order": 44282, "speaker": "Robert California"},
  {"season": 8, "episode": 2, "scene": 14, "line": "There are no wrong answers.", "order": 44283, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 2, "scene": 14, "line": "Take a look at where you are, where you once worked in a dying industry, you now work at it's birth. Those superstores are terrified of us. Anybody know why?", "order": 44284, "speaker": "Robert California"},
  {"season": 8, "episode": 2, "scene": 14, "line": "Wait'.they're terrified?", "order": 44285, "speaker": "Phyllis Vance"},
  {"season": 8, "episode": 2, "scene": 14, "line": "Let me tell you how I buy something these days. I know what I want I go on the internet, I get the best price. Or I don't know what I want and I go to a small store that can help me. The era of personal service is back. You are back. You'll find that customers will pay our higher prices and then they will thank us, and we will say to them 'you are welcome.' [Applause] Andrew, I chose you for a reason. Lead these people. Show me the best numbers this place has ever seen. Last quarter we saw 4% growth. Double it.", "order": 44286, "speaker": "Robert California"},
  {"season": 8, "episode": 2, "scene": 14, "line": "You got it.", "order": 44287, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 2, "scene": 14, "line": "Double.", "order": 44288, "speaker": "Robert California"},
  {"season": 8, "episode": 2, "scene": 14, "line": "Done.", "order": 44289, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 2, "scene": 14, "line": "I'm not kidding.", "order": 44290, "speaker": "Robert California"},
  {"season": 8, "episode": 2, "scene": 14, "line": "Neither am I, it's already done. Hah, I'm just kidding, it's going to take some time.", "order": 44291, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 2, "scene": 14, "line": "Double.", "order": 44292, "speaker": "Robert California"},
  {"season": 8, "episode": 2, "scene": 15, "line": "Hey. [Andy pushes things aside and sits on top of Jim's desk, kicking things in the process.]", "order": 44293, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 2, "scene": 15, "line": "What's up, guys? Just thought we'd have a little rap session, talk about business'see how things are going? Ahem'", "order": 44294, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 2, "scene": 15, "line": "Why don't you start?", "order": 44295, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 2, "scene": 15, "line": "If no one else wants to? I was just thinking about Robert'man. What a boss. Just throws down goals, you know? Anyway, how's the sales doubling 'project going?", "order": 44296, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 2, "scene": 15, "line": "Yeah, how are we supposed to do that? We can't just press a magic button.", "order": 44297, "speaker": "Phyllis Vance"},
  {"season": 8, "episode": 2, "scene": 15, "line": "OF course not. There's no magic button. You have to summon that.", "order": 44298, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 2, "scene": 15, "line": "If we could just double our sales, we already would have. You're not making any sense.", "order": 44299, "speaker": "Stanley Hudson"},
  {"season": 8, "episode": 2, "scene": 15, "line": "He brings up two good points. Do you have any new leads? Any new territories you want us to look into? Maybe have an in with a big client that we can get our foot in the door?", "order": 44300, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 2, "scene": 15, "line": "Dwight, anything?", "order": 44301, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 2, "scene": 15, "line": "We could talk about how fast children grow up, and before you know it they're out of the house.", "order": 44302, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 2, "scene": 15, "line": "You know what? We need to get our heads out of the box. If we did have something, what would it look like, what would it be? [Jim raises hand] Tuna.", "order": 44303, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 2, "scene": 15, "line": "New leads, a new territory to look into, maybe an in with a big company that we could get our foot in the door'", "order": 44304, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 2, "scene": 15, "line": "Fart'.. good Sesh. [gets up] That leg's asleep.", "order": 44305, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 2, "scene": 16, "line": "Hello. I'm looking for Darryl Philbin?", "order": 44306, "speaker": "Justine"},
  {"season": 8, "episode": 2, "scene": 16, "line": "Don't! oh, you must think'I'm not. I'm using the fax, this isn't, no wait. I'm not supposed to represent the company. Right? There's usually an Erin here.", "order": 44307, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 2, "scene": 16, "line": "Ok'.?", "order": 44308, "speaker": "Justine"},
  {"season": 8, "episode": 2, "scene": 16, "line": "So'DARRYL! A GIRL!", "order": 44309, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 2, "scene": 16, "line": "[Singing] rub a dub dub'I got scrubbed. 'Sup, darlin'? Everybody, this is Justine. [murmurs of hello from the group] This is Jim and Oscar, everybody.", "order": 44310, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 2, "scene": 16, "line": "Hi.", "order": 44311, "speaker": "Meredith Palmer"},
  {"season": 8, "episode": 2, "scene": 16, "line": "Kevin.", "order": 44312, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 2, "scene": 16, "line": "Can we'. go some place private?", "order": 44313, "speaker": "Justine"},
  {"season": 8, "episode": 2, "scene": 16, "line": "Follow me, I got a space. After you. I've been thinking about you all mornin'. I don't know what you did, I can barely walk today.", "order": 44314, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 2, "scene": 17, "line": "D-Bone. There you are.", "order": 44315, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 2, "scene": 17, "line": "Let me guess, somebody needs a brownie'? Lick the spoon?", "order": 44316, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 2, "scene": 17, "line": "No, that's ok. Just, I want, I wanted to ask you about'", "order": 44317, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 2, "scene": 17, "line": "Is this about the profits? Because if it is I just don't see the point. It's so Wall Street.", "order": 44318, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 2, "scene": 17, "line": "I know, right?", "order": 44319, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 2, "scene": 17, "line": "Right?", "order": 44320, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 2, "scene": 17, "line": "Yeah'um, how is everything?", "order": 44321, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 2, "scene": 17, "line": "Good. Really really good.", "order": 44322, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 2, "scene": 17, "line": "Must be a tough time to be a family farm.", "order": 44323, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 2, "scene": 17, "line": "Oh, it is. And beets are not immune. We made some inroads in salads, but heirloom tomatoes are pushing back.", "order": 44324, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 2, "scene": 17, "line": "Oof. What are you gonna do about that?", "order": 44325, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 2, "scene": 17, "line": "I don't know, what do you mean?", "order": 44326, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 2, "scene": 17, "line": "There's gotta be some way you can double your beet sales'", "order": 44327, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 2, "scene": 17, "line": "You wanted the job, the job is yours. Just do the job! [Licks brownie battered finger] And I'll do mine'.. walnuts?", "order": 44328, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 2, "scene": 17, "line": "No!", "order": 44329, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 2, "scene": 18, "line": "Have you seen this? [Hands Pam Parenting magazine]", "order": 44330, "speaker": "Angela Martin"},
  {"season": 8, "episode": 2, "scene": 18, "line": "Parenting? Yeah. This is Cici's favorite magazine. She loves the pictures of babies.", "order": 44331, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 2, "scene": 18, "line": "She looks at it when she's on the potty, and she makes the faces.", "order": 44332, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 2, "scene": 18, "line": "Have you read it? Has an adult member of your family read this particular issue?", "order": 44333, "speaker": "Angela Martin"},
  {"season": 8, "episode": 2, "scene": 18, "line": "I flipped through it.", "order": 44334, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 2, "scene": 18, "line": "So you saw the article on the importance of taking frequent walks while you're pregnant?", "order": 44335, "speaker": "Angela Martin"},
  {"season": 8, "episode": 2, "scene": 18, "line": "Of course.", "order": 44336, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 2, "scene": 18, "line": "We know that.", "order": 44337, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 2, "scene": 18, "line": "Yeah.", "order": 44338, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 2, "scene": 18, "line": "I will be taking a morning walk and an afternoon walk. Would you like to join me?", "order": 44339, "speaker": "Angela Martin"},
  {"season": 8, "episode": 2, "scene": 18, "line": "That sounds nice.", "order": 44340, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 2, "scene": 18, "line": "Great.[Angela walks away]", "order": 44341, "speaker": "Angela Martin"},
  {"season": 8, "episode": 2, "scene": 18, "line": "You have a walking buddy.", "order": 44342, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 2, "scene": 18, "line": "I do.", "order": 44343, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 2, "scene": 19, "line": "Thanks for coming in guys.", "order": 44344, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 2, "scene": 19, "line": "You don't have to thank us for coming in, it's our job.", "order": 44345, "speaker": "Phyllis Vance"},
  {"season": 8, "episode": 2, "scene": 19, "line": "Well I never got thanked for coming into a meeting and I always wanted to be so I'm gonna thank people.", "order": 44346, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 2, "scene": 19, "line": "What's with the blanket?", "order": 44347, "speaker": "Meredith Palmer"},
  {"season": 8, "episode": 2, "scene": 19, "line": "This is what's under the blanket.", "order": 44348, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 2, "scene": 19, "line": "We don't get it.", "order": 44349, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 2, "scene": 19, "line": "These are incentives. It's how we're gonna double growth. Now, you're probably all asking yourselves: 'Well, how does this work?'", "order": 44350, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 2, "scene": 19, "line": "Seems like a basic reward system where you give us points, and then we redeem those points for prizes.", "order": 44351, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 2, "scene": 19, "line": "You're exactly right and you get a point.", "order": 44352, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 2, "scene": 19, "line": "Oh. [smiling]", "order": 44353, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 2, "scene": 19, "line": "Uh, is that a vibrator?", "order": 44354, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 2, "scene": 19, "line": "Twenty points.", "order": 44355, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 2, "scene": 19, "line": "How does one get a point?", "order": 44356, "speaker": "Meredith Palmer"},
  {"season": 8, "episode": 2, "scene": 19, "line": "I've outlined the exact parameters in an email, so check your spam folders, but basically you do your job better, you get points. So, collect fifteen points and redeem them for this polar bear.", "order": 44357, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 2, "scene": 19, "line": "Why is it all kid stuff and a vibrator? It's so gross.", "order": 44358, "speaker": "Kelly Kapoor"},
  {"season": 8, "episode": 2, "scene": 19, "line": "There's lots of stuff. John Irving, collected works, Twenty-two points. Or, you can pool your points and redeem fifty-five for this maternity shirt.", "order": 44359, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 2, "scene": 19, "line": "How 'bout you want us to work harder, pay us more.", "order": 44360, "speaker": "Stanley Hudson"},
  {"season": 8, "episode": 2, "scene": 19, "line": "I can't.", "order": 44361, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 2, "scene": 19, "line": "This point system is really insulting.", "order": 44362, "speaker": "Kelly Kapoor"},
  {"season": 8, "episode": 2, "scene": 19, "line": "Ooh I didn't mean to offend you, and I hope you'll forgive me because I am very very' Sari. [tosses yellow print material over shoulder] Sixteen points.", "order": 44363, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 2, "scene": 19, "line": "That's a tablecloth.", "order": 44364, "speaker": "Kelly Kapoor"},
  {"season": 8, "episode": 2, "scene": 19, "line": "What if we went all the way up to five hundred points?", "order": 44365, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 2, "scene": 19, "line": "That's a crazy amount of points.", "order": 44366, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 2, "scene": 19, "line": "But, what if?", "order": 44367, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 2, "scene": 19, "line": "Well, what do you want?", "order": 44368, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 2, "scene": 19, "line": "I don't know, for such a crazy number I'd like something pretty crazy.", "order": 44369, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 2, "scene": 19, "line": "Alright. For five hundred points, I will wear a dress to work. [laughter]", "order": 44370, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 2, "scene": 19, "line": "That's pretty good. What about uh, for a thousand points?", "order": 44371, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 2, "scene": 19, "line": "I'll run naked through the parking lot with a donut on my ding-dong. [laughter] Yeah? You like that? Alright! For five thousand points, I will let you tattoo whatever you want on the stern of the old SS Bernard![Andy points to his rear-end]", "order": 44372, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 2, "scene": 19, "line": "Ooooh!", "order": 44373, "speaker": "Group"},
  {"season": 8, "episode": 2, "scene": 19, "line": "Really?", "order": 44374, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 2, "scene": 19, "line": "Alright, alright. And you are totally serious?", "order": 44375, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 2, "scene": 19, "line": "Swear to God, hope to die. Now let's get to work!", "order": 44376, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 2, "scene": 19, "line": "Wait. You did say we could pool our points, right? In that case'let's get to work.", "order": 44377, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 2, "scene": 19, "line": "Yeah!", "order": 44378, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 2, "scene": 19, "line": "Yeah! Whoo! [Group cheers]", "order": 44379, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 2, "scene": 20, "line": "[on the phone]I can have it to you by the beginning of next week. Alright? Thanks very much. [hangs up] Uh, Phyllis! Bracken Auto?", "order": 44380, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 2, "scene": 20, "line": "[hands Jim folder] Ask for Donald, Karen's bananas.", "order": 44381, "speaker": "Phyllis Vance"},
  {"season": 8, "episode": 2, "scene": 20, "line": "Ok.", "order": 44382, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 2, "scene": 20, "line": "T-bag bone'", "order": 44383, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 2, "scene": 20, "line": "Andrew.", "order": 44384, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 2, "scene": 20, "line": "Have you noticed a little bit of a mood shift around here?", "order": 44385, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 2, "scene": 20, "line": "No, I didn't notice anything.", "order": 44386, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 2, "scene": 20, "line": "Really? Because I sat next to Stanley for years'.and this is nap time. Open eye nap time. He balances the phone on his shoulder and just powers down. Now look at him.", "order": 44387, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 2, "scene": 20, "line": "[On the phone] You've got to unleash the power of the Pyramid!", "order": 44388, "speaker": "Stanley Hudson"},
  {"season": 8, "episode": 2, "scene": 20, "line": "I don't know what to tell you, man.", "order": 44389, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 2, "scene": 20, "line": "You think it has something to do with that incentive program?", "order": 44390, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 2, "scene": 20, "line": "Oh, one hundred percent. We all want to see you tattoo your ass.", "order": 44391, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 2, "scene": 20, "line": "Bah'I think people thought I was kidding when I said that.", "order": 44392, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 2, "scene": 20, "line": "Nah, you definitely weren't kidding. And that came across loud and clear. Oh, by the way, I want to hand this in. [hands Andy slip of paper]", "order": 44393, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 2, "scene": 20, "line": "Hundred and twenty points.", "order": 44394, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 2, "scene": 20, "line": "Yeah. Big sale. Don't worry about it though, I don't really care about the points. I would like a point receipt though'[on phone] Hey! Yes, this is Jim Halpert calling for Donald'can you hold on for one second? Thank you very much. [gets up to give Erin his point receipt]", "order": 44395, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 2, "scene": 20, "line": "Who talked to Maggie at Kaufman's?", "order": 44396, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 2, "scene": 20, "line": "Yes! [adds receipt to growing pile and thumbs up Andy.]", "order": 44397, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 2, "scene": 20, "line": "[on the phone]and I'm back. How are you sir?....I think we can squeeze a couple more golf games in, right?", "order": 44398, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 2, "scene": 20, "line": "Where's Angela? [Hands paper over her shoulder]", "order": 44399, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 2, "scene": 20, "line": "Hey Kevin, what are you doing?", "order": 44400, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 2, "scene": 20, "line": "Don't talk to me! [Everyone continues to work busily]", "order": 44401, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 2, "scene": 21, "line": "[on phone] Hi, Professor Frank, Andy Bernard, class of '95. Hey there, um, I'm a huge fan of your management book, Management. Um, quick question. I may be missing a chapter here'De-Incentivizing. What are your strategies? Looking for a real blow to morale'uh why? Well, um I guess you could say I'm in one of those classic ass tattoo incentive situations. [laughs]", "order": 44402, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 2, "scene": 22, "line": "Took 'em one day.", "order": 44403, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 2, "scene": 22, "line": "Ready! [flips sketch pad showing tattoo possibilities] 'I'm not as think as you drunk I am!' [crowd claps and cheers]", "order": 44404, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 2, "scene": 22, "line": "I like it, I like it! [Pam flips the page]", "order": 44405, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 2, "scene": 22, "line": "Do Not Resuscitate'[mild cheering]", "order": 44406, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 2, "scene": 22, "line": "OK, keep in mind, it's not too late to choose another prize and there are some great new additions. My car [nervous laughter] for a thousand points'.or best offer.", "order": 44407, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 2, "scene": 22, "line": "What else you got?", "order": 44408, "speaker": "Phyllis Vance"},
  {"season": 8, "episode": 2, "scene": 22, "line": "Oh, and then this was Phyllis's idea'[shocked cheers] So nasty Phyllis!", "order": 44409, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 2, "scene": 23, "line": "We were hoping you could do something like this'", "order": 44410, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 2, "scene": 23, "line": "So, coming out of his butt is a'", "order": 44411, "speaker": "Tattoo Artist"},
  {"season": 8, "episode": 2, "scene": 23, "line": "Baby.", "order": 44412, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 2, "scene": 23, "line": "Baby'", "order": 44413, "speaker": "Tattoo Artist"},
  {"season": 8, "episode": 2, "scene": 23, "line": "Yes.", "order": 44414, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 2, "scene": 23, "line": "Yeah, no problem.", "order": 44415, "speaker": "Tattoo Artist"},
  {"season": 8, "episode": 2, "scene": 23, "line": "We should think about this'does anyone have any better ideas?", "order": 44416, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 2, "scene": 23, "line": "I like what we have.", "order": 44417, "speaker": "Stanley Hudson"},
  {"season": 8, "episode": 2, "scene": 23, "line": "Looks good.", "order": 44418, "speaker": "Meredith Palmer"},
  {"season": 8, "episode": 2, "scene": 23, "line": "Yeah.", "order": 44419, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 2, "scene": 23, "line": "For sure. [murmurs from group in agreement]", "order": 44420, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 2, "scene": 23, "line": "Just need a second outside.", "order": 44421, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 2, "scene": 24, "line": "Gettin' psyched up?", "order": 44422, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 2, "scene": 24, "line": "Yeah.", "order": 44423, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 2, "scene": 24, "line": "Andy, nobody really expects you to go through with this'", "order": 44424, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 2, "scene": 24, "line": "Tunes..what am I doing here? Why did Robert pick me? '.Confession: I don't know what I'm doing.", "order": 44425, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 2, "scene": 24, "line": "I mean, do you like it? You having fun? [Andy snorts, unsure how to answer] Tell you this, everybody else is having a lot of fun'.and you did that.", "order": 44426, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 2, "scene": 24, "line": "My ass is only so big, I mean I can't do this everyday.", "order": 44427, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 2, "scene": 24, "line": "But I think it's big enough to buy you some time till your next great idea'.which, by the way, I can't wait for.", "order": 44428, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 2, "scene": 24, "line": "No one expects me to go through with this, right?", "order": 44429, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 2, "scene": 24, "line": "Absolutely not.", "order": 44430, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 2, "scene": 25, "line": "Let's ink'my stink! [crowd cheers]", "order": 44431, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 2, "scene": 26, "line": "My heart belongs to music. But my ass belongs to these people.", "order": 44432, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 2, "scene": 27, "line": "Whoa!", "order": 44433, "speaker": "Group"},
  {"season": 8, "episode": 2, "scene": 27, "line": "[lying on table] Do your worst!", "order": 44434, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 2, "scene": 27, "line": "Uh, you can keep your pants on actually'if just drop 'em down a bit, that'd be great.", "order": 44435, "speaker": "Tattoo Artist"},
  {"season": 8, "episode": 2, "scene": 27, "line": "They are already off, my good sir.", "order": 44436, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 2, "scene": 27, "line": "I'd really prefer they not be down.", "order": 44437, "speaker": "Tattoo Artist"},
  {"season": 8, "episode": 2, "scene": 27, "line": "Well, I think down's better. Sweating pretty heavily down there.", "order": 44438, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 2, "scene": 27, "line": "Do you think you could work from this? [showing Tattoo artist sketch pad] we made some small adjustments.", "order": 44439, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 2, "scene": 27, "line": "OK, you want me to'", "order": 44440, "speaker": "Tattoo Artist"},
  {"season": 8, "episode": 2, "scene": 27, "line": "Just a few adjustments", "order": 44441, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 2, "scene": 27, "line": "Alright, let's begin.", "order": 44442, "speaker": "Tattoo Artist"},
  {"season": 8, "episode": 2, "scene": 27, "line": "This is where I grin and be-YOW OW!", "order": 44443, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 2, "scene": 27, "line": "That was just the cotton swab.", "order": 44444, "speaker": "Tattoo Artist"},
  {"season": 8, "episode": 2, "scene": 27, "line": "Invest in softer cotton, sir. [tattooing begins] OW! Oh! Oooh! [Crowd cheers] oh, whoa!!", "order": 44445, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 2, "scene": 28, "line": "Obviously you can go the ass tattoo route and obviously, I'm gonna like it.", "order": 44446, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 2, "scene": 29, "line": "DRAW SOME BLOOD!", "order": 44447, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 2, "scene": 29, "line": "AHHHHH!!!!!!!", "order": 44448, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 2, "scene": 30, "line": "Why did I choose Andy to run the office? Because he's all surface, uncomplicated. What you see is what you get. Could be a recipe for mediocrity, yes, but then again, it might just be why people fight for him.", "order": 44449, "speaker": "Robert California"},
  {"season": 8, "episode": 2, "scene": 31, "line": "[removes tape to reveal tattoo of puppy] It's a Nard Dog! [group cheers] That's my nickity-name! I love it, I love it!", "order": 44450, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 2, "scene": 31, "line": "Pull up your pants.", "order": 44451, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 2, "scene": 32, "line": "There's something about an underdog that really inspires'the unexceptional.", "order": 44452, "speaker": "Robert California"},
  {"season": 8, "episode": 2, "scene": 33, "line": "Um, what should we talk about?", "order": 44453, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 2, "scene": 33, "line": "Well, we could talk about an ethical dilemma I'm having.", "order": 44454, "speaker": "Angela Martin"},
  {"season": 8, "episode": 2, "scene": 33, "line": "Oh yeah, sure. Um, I hope I can help.", "order": 44455, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 2, "scene": 33, "line": "A coworker of mine is drinking caffeine while pregnant, and I don't know if I should call social services about it.", "order": 44456, "speaker": "Angela Martin"},
  {"season": 8, "episode": 2, "scene": 33, "line": "Angela, that's pretty transparently me.", "order": 44457, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 2, "scene": 33, "line": "Maybe.", "order": 44458, "speaker": "Angela Martin"},
  {"season": 8, "episode": 2, "scene": 33, "line": "You know it's just herbal tea.", "order": 44459, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 2, "scene": 33, "line": "In mugs with trace amounts of coffee!", "order": 44460, "speaker": "Angela Martin"},
  {"season": 8, "episode": 2, "scene": 33, "line": "Yeah. I think you should call social services.", "order": 44461, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 2, "scene": 33, "line": "I already did.", "order": 44462, "speaker": "Angela Martin"},
  {"season": 8, "episode": 2, "scene": 33, "line": "You know, maybe we should just have our own pregnancies and not pretend like we're in this together.", "order": 44463, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 2, "scene": 33, "line": "Fine.", "order": 44464, "speaker": "Angela Martin"},
  {"season": 8, "episode": 2, "scene": 33, "line": "Fine.", "order": 44465, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 2, "scene": 34, "line": "When you're a kid, you picture a pilot kinda like this [imitates plane noises while steering wildly] But then you learn that's crashing the plane. The right way to do it is more like this. [calmly pulls imaginary steering back and forth] Or better yet, like this. [takes hands off imaginary wheel] Now that I'm manager, I think about that a lot. I let Dwight run the meetings, I let good people do good work and I stay out of the way.", "order": 44466, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 2, "scene": 35, "line": "Which combo do you think Robert's gonna like better?", "order": 44467, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 2, "scene": 35, "line": "Just wear one of your bowties.", "order": 44468, "speaker": "Phyllis Vance"},
  {"season": 8, "episode": 2, "scene": 35, "line": "No! I mean, let him, he should just explore other options...", "order": 44469, "speaker": "Kelly Kapoor"},
  {"season": 8, "episode": 2, "scene": 36, "line": "Bowties, no offense, are a black thing. They're for rappers and NBA players. I cringe when I see Tucker Carlson trying to pull off a bowtie. It's like yeah Tucker, you're so street.", "order": 44470, "speaker": "Kelly Kapoor"},
  {"season": 8, "episode": 2, "scene": 37, "line": "This is combo number seven.", "order": 44471, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 2, "scene": 37, "line": "Hmm, no.", "order": 44472, "speaker": "All"},
  {"season": 8, "episode": 2, "scene": 37, "line": "I don't like that clip.", "order": 44473, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 2, "scene": 37, "line": "You're right, you're right, you're right. Uh! It's so hard to find an occasion for this clip.", "order": 44474, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 2, "scene": 37, "line": "I'd hang on to it though, cause I can think of a bunch. Like a 70's theme party, or a 70's theme meeting, or a 70's theme convention...", "order": 44475, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 2, "scene": 37, "line": "Sure.", "order": 44476, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 2, "scene": 37, "line": "1870's...", "order": 44477, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 2, "scene": 37, "line": "Thanks T-Dog.", "order": 44478, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 2, "scene": 37, "line": "T-Dog?", "order": 44479, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 2, "scene": 37, "line": "I thought I was T-Dog.", "order": 44480, "speaker": "Toby Flenderson"},
  {"season": 8, "episode": 2, "scene": 37, "line": "Nope, he means tuna dog.", "order": 44481, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 2, "scene": 37, "line": "Tuna dog!", "order": 44482, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 2, "scene": 38, "line": "I thought the plane was flying pretty well on auto-pilot. And then Robert California ran into the cockpit with a gun and he was like 'Fly this plane to Cuba, and on the way bomb Texas' Well I don't know how to get there. Or even how to fly, even. And I don't have any bombs.", "order": 44483, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 2, "scene": 39, "line": "Hey. Maybe it'd help to just talk it over. Over a cup of cool old tea.", "order": 44484, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 2, "scene": 39, "line": "[sighs] Yeah.", "order": 44485, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 2, "scene": 40, "line": "What if you were to just start with your ideas about how to double profits. Just say them all and maybe one of them will rise to the top.", "order": 44486, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 2, "scene": 40, "line": "I don't have any ideas though.", "order": 44487, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 2, "scene": 40, "line": "Well I have an idea.", "order": 44488, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 2, "scene": 40, "line": "You do?", "order": 44489, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 2, "scene": 40, "line": "What if Dunder Mifflin were to take on 15-20 foster kids? You'd get huge checks from the government and honestly they would be ecstatic to live in the warehouse. It would be like Hogwart's.", "order": 44490, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 2, "scene": 40, "line": "Yeah....I don't think that's legal. It's a nice thought though. [Erin hands Andy a gift] What is this?", "order": 44491, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 2, "scene": 40, "line": "I just wanted to say congratulations on your new job, officially.", "order": 44492, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 2, "scene": 40, "line": "You didn't have to do that. [opens box to reveal red mouse cat toy attached to key chain]", "order": 44493, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 2, "scene": 40, "line": "I saw it at the checkout counter, it made me think of you. I don't know why. I made it into a key chain.", "order": 44494, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 2, "scene": 40, "line": "You're the only person who's congratulated me.", "order": 44495, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 3, "scene": 1, "line": "....back orders and you never called them.", "order": 44496, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 3, "scene": 1, "line": "Can you believe this?", "order": 44497, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 3, "scene": 1, "line": "There's a dog in the car.", "order": 44498, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 3, "scene": 1, "line": "You can't leave a dog in a parked car. [points to 'I'd rather be snowboarding' bumper sticker] Snowboarder, it figures.", "order": 44499, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 3, "scene": 1, "line": "Do Snowboarders hate animals?", "order": 44500, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 3, "scene": 1, "line": "I bet this guy didn't leave his weed in the car. OK we have to do something because this is incredibly dangerous.", "order": 44501, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 3, "scene": 1, "line": "Oscar, it's not that hot out.", "order": 44502, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 3, "scene": 1, "line": "A car parked in the sun is like a toaster oven.", "order": 44503, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 3, "scene": 1, "line": "Well, we don't know how long the driver's been gone and it's not in direct sunlight.", "order": 44504, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 3, "scene": 1, "line": "So what, Andy, you wanna just let him die, you scumbag?", "order": 44505, "speaker": "Kelly Kapoor"},
  {"season": 8, "episode": 3, "scene": 1, "line": "Here, I'm gonna get in my car. When I start dying, I will honk the horn three times. That means save the dog.", "order": 44506, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 3, "scene": 1, "line": "OK, you know what? I'm gonna give him something to drink. Come here, doggy. [Whistling and pouring water from bottle into sunroof of car] Come on.", "order": 44507, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 3, "scene": 1, "line": "Dwight! At least aim it.", "order": 44508, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 3, "scene": 1, "line": "There you go! Here doggy! He's not even trying. Come here doggy, come on.", "order": 44509, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 3, "scene": 1, "line": "We're losing cloud cover.", "order": 44510, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 3, "scene": 1, "line": "Oh don't try to get in on it now, Michael Vick.", "order": 44511, "speaker": "Kelly Kapoor"},
  {"season": 8, "episode": 3, "scene": 1, "line": "Hey, hey, hey. Vick did his time.", "order": 44512, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 3, "scene": 1, "line": "This guy's been gone long enough. He's lost his right to a window. [Oscar approaches the vehicle with a tire iron]", "order": 44513, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 3, "scene": 1, "line": "Whoa whoa whoa whoa!", "order": 44514, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 3, "scene": 1, "line": "Come on buddy, get back.", "order": 44515, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 3, "scene": 1, "line": "Whoa, Oscar! What are you- What? No, hey! [Oscar busts out back window, group cries out in protest, then cheers]", "order": 44516, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 3, "scene": 1, "line": "Alright! Nice job, Oscar!", "order": 44517, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 3, "scene": 1, "line": "And one for good measure! [Busts out taillight, group applauds]", "order": 44518, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 3, "scene": 1, "line": "So...ah, who's gonna take the dog?", "order": 44519, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 3, "scene": 1, "line": "Why would we take the dog?", "order": 44520, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 3, "scene": 1, "line": "What if he jumps out the window and runs away?", "order": 44521, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 3, "scene": 1, "line": "Jim, he's not gonna star- [Dog lunges for open window and barks]", "order": 44522, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 3, "scene": 1, "line": "Whoa!", "order": 44523, "speaker": "Meredith Palmer"},
  {"season": 8, "episode": 3, "scene": 1, "line": "Shh! Shh! Stay there, stay.", "order": 44524, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 3, "scene": 1, "line": "Nein. Sits. [snaps as dog calms] Goot.", "order": 44525, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 3, "scene": 1, "line": "Oscar, what do you wanna do, this is kinda your deal. You wanna dog?", "order": 44526, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 3, "scene": 1, "line": "[Oscar pokes holes in cardboard now taped over window] There we go. That should do it.", "order": 44527, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 3, "scene": 1, "line": "Yeah, that's pretty good.", "order": 44528, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 3, "scene": 1, "line": "Yeah, that'll work.", "order": 44529, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 3, "scene": 1, "line": "That'll work.", "order": 44530, "speaker": "Kelly Kapoor"},
  {"season": 8, "episode": 3, "scene": 1, "line": "Nice job.", "order": 44531, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 3, "scene": 1, "line": "Bye poochie!", "order": 44532, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 3, "scene": 1, "line": "Bye. [Andy barks]", "order": 44533, "speaker": "Kelly Kapoor"},
  {"season": 8, "episode": 3, "scene": 1, "line": "Bye! [Kevin's horn honks twice shortly, then one long honk. Shot shows him passed out on his steering wheel.]", "order": 44534, "speaker": "Meredith Palmer"},
  {"season": 8, "episode": 3, "scene": 2, "line": "What's that come to? Like, what did they each win?", "order": 44535, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 3, "scene": 2, "line": "Oh man, it's gotta be over a hundred thousand dollars.", "order": 44536, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 3, "scene": 2, "line": "Awesome.", "order": 44537, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 3, "scene": 2, "line": "Before taxes.", "order": 44538, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 3, "scene": 2, "line": "That's still a lot of money!", "order": 44539, "speaker": "Phyllis Vance"},
  {"season": 8, "episode": 3, "scene": 3, "line": "The warehouse crew won the lottery yesterday. Nine hundred and fifty thousand dollars. And then they quit!...and no one else can focus. [shot shows warehouse crew going wild in the office] This is it. This is all on my shoulders. I'm the one who has to tell everyone to get back to work, I'm the one who has to tell Darryl to hire a new warehouse crew. I'm the one who has to say those things.", "order": 44540, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 3, "scene": 4, "line": "[On phone] Hello?....Justine! [laughs] Nice surprise! How you doin' baby?....Nah. No no, I didn't win. When I got promoted I stop-...what?...Yeah. Yeah, Glenn won....Oh, you wanna call him? Yeah, you should call him, congratulate him. That'll be-...What?....Oh, his number's in your old phone. Oh, you know what? I might have it right- [hangs up] Whoops.", "order": 44541, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 3, "scene": 5, "line": "When I worked in the warehouse, I was part of that lotto pool. They won ' playing my birthday.", "order": 44542, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 3, "scene": 6, "line": "What really interests me is the group dynamic of six people winning the lottery. This will not end well. Right?", "order": 44543, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 3, "scene": 6, "line": "Yeah.", "order": 44544, "speaker": "Phyllis Vance"},
  {"season": 8, "episode": 3, "scene": 6, "line": "We're lookin' at at least one suicide and one weird sex thing.", "order": 44545, "speaker": "Meredith Palmer"},
  {"season": 8, "episode": 3, "scene": 6, "line": "At least.", "order": 44546, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 3, "scene": 6, "line": "I mean, I don't even know what I'd do with all that money.", "order": 44547, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 3, "scene": 6, "line": "I know what you'd do with all that money. [imitating Jim] 'Hey Pam, let's buy expensive bathrobes and hug.'", "order": 44548, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 3, "scene": 6, "line": "No, I'd probably buy a big piece of land in Maine, build a house, work in town. Somewhere I could bike to or kayak to. I'd either bike to my job at the kayak shop or kayak to my job at the bike shop.", "order": 44549, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 3, "scene": 6, "line": "And then on the weekends, would you hacky sack back to reality and spend time with your wife and kids?", "order": 44550, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 3, "scene": 6, "line": "Whoa. Saucy. I thought you liked Maine?", "order": 44551, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 3, "scene": 6, "line": "I think we should get a townhouse in SoHo...", "order": 44552, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 3, "scene": 6, "line": "SoHo's mostly lofts but OK.", "order": 44553, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 3, "scene": 6, "line": "And then every morning, I'd walk out on to my terrace and I would breathe in the inspiration of the city. You know? And just gather ideas for my painting...", "order": 44554, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 3, "scene": 6, "line": "Oh, god.", "order": 44555, "speaker": "Kelly Kapoor"},
  {"season": 8, "episode": 3, "scene": 6, "line": "And then my handsome husband...", "order": 44556, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 3, "scene": 6, "line": "Which ideally would be me....", "order": 44557, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 3, "scene": 6, "line": "Would bring me a flavored coffee.", "order": 44558, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 3, "scene": 6, "line": "Stop. I'm a barista in your fantasy?", "order": 44559, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 3, "scene": 6, "line": "Well in your fantasy we're Stephen King characters.", "order": 44560, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 3, "scene": 6, "line": "I don't know about Stephen King, I mean...", "order": 44561, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 3, "scene": 6, "line": "[under her breath] get a divorce...get a divorce...", "order": 44562, "speaker": "Meredith Palmer"},
  {"season": 8, "episode": 3, "scene": 7, "line": "I think I would keep working. And for my salary I guess I would take like a dollar a year....I mean obviously I wouldn't come in till noon and I wouldn't do anything I didn't wanna do. I mean I'm getting paid a dollar a year, OK? You can chill.", "order": 44563, "speaker": "Kelly Kapoor"},
  {"season": 8, "episode": 3, "scene": 8, "line": "Are you kidding me?! Guys if I have to ask you to get back to work one more time, I'm gonna change my tone. [lowers voice] To down here like Mr. T. and this will get seriously annoying. I feel sympathy for the jerks who have to listen to this all day. [normal voice] Darryl, how we doin' on the new warehouse guys?", "order": 44564, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 3, "scene": 8, "line": "I don't know.", "order": 44565, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 3, "scene": 8, "line": "What d-? What..what? Wuh, do we have new guys, or what?", "order": 44566, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 3, "scene": 8, "line": "No.", "order": 44567, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 3, "scene": 8, "line": "Are they on their way over?", "order": 44568, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 3, "scene": 8, "line": "I haven't hired anyone.", "order": 44569, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 3, "scene": 8, "line": "What? No warehouse guys? I have an important order that has to go out by five. I emailed you about it.", "order": 44570, "speaker": "Phyllis Vance"},
  {"season": 8, "episode": 3, "scene": 8, "line": "I'm not checkin' email till lunch. Four hour work week.", "order": 44571, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 3, "scene": 8, "line": "This is kinda time sensitive.", "order": 44572, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 3, "scene": 8, "line": "I got it. I'm doin' it.", "order": 44573, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 3, "scene": 8, "line": "Andy, this is a seriously big order. I can't lose this client.", "order": 44574, "speaker": "Phyllis Vance"},
  {"season": 8, "episode": 3, "scene": 8, "line": "Alright, well until we have a new crew, let's get some volunteers for warehouse duty. Who's in? [Erin raises hand]", "order": 44575, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 3, "scene": 8, "line": "As long as you guys don't need me up here.", "order": 44576, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 3, "scene": 8, "line": "No..we don't", "order": 44577, "speaker": "Phyllis Vance"},
  {"season": 8, "episode": 3, "scene": 8, "line": "I think we'll be fine. [group murmurs in agreement]", "order": 44578, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 3, "scene": 8, "line": "Really, nobody's gonna help her? Is chivalry dead?", "order": 44579, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 3, "scene": 8, "line": "Are you volunteering?", "order": 44580, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 3, "scene": 8, "line": "Of course. I would. But my hip....I would kill to be at a hundred percent. [Angela rolls eyes]", "order": 44581, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 3, "scene": 8, "line": "Jim! How 'bout you?", "order": 44582, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 3, "scene": 8, "line": "Uh, yeah. I mean, as the strongest person in this office, I guess I should go down with you...", "order": 44583, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 3, "scene": 8, "line": "Hey...OK no. No. That. You are so not...oh god. [grunts] False. Andy, I will volunteer.", "order": 44584, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 3, "scene": 8, "line": "Great. And Kevin.", "order": 44585, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 3, "scene": 9, "line": "Good old Kevin. He'll do anything. Well guess what? I will not do a good job.", "order": 44586, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 3, "scene": 10, "line": "Oh, thank you.", "order": 44587, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 3, "scene": 10, "line": "Sure.", "order": 44588, "speaker": "Angela Martin"},
  {"season": 8, "episode": 3, "scene": 10, "line": "Wait, wait. What's this? [holds up clipboard]", "order": 44589, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 3, "scene": 10, "line": "Oh, sorry. I thought it was a guess your baby's birth weight pool.", "order": 44590, "speaker": "Angela Martin"},
  {"season": 8, "episode": 3, "scene": 10, "line": "It says 'Lotto Pool', right on top. [points to obvious title]", "order": 44591, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 3, "scene": 10, "line": "Yeah. And I said sorry.", "order": 44592, "speaker": "Angela Martin"},
  {"season": 8, "episode": 3, "scene": 10, "line": "Oh come on. You really think I'm gonna have a fourteen pound baby?", "order": 44593, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 3, "scene": 11, "line": "When did I get so fat?", "order": 44594, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 3, "scene": 11, "line": "You look awesome.", "order": 44595, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 3, "scene": 11, "line": "I didn't hire anyone if that's why you're here.", "order": 44596, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 3, "scene": 11, "line": "Where are we in the process?", "order": 44597, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 3, "scene": 11, "line": "I have a file of applicants here. I just gotta open it, look at it, interview a bunch of guys,hire some of 'em. So I'd say we're in the early stages of the process.", "order": 44598, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 3, "scene": 11, "line": "Did you go out celebrating with the guys last night?", "order": 44599, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 3, "scene": 11, "line": "The guys did invite me out to celebrate but I decided to just stay home. Eat a bunch of tacos in my basement.", "order": 44600, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 3, "scene": 11, "line": "You do have a fantastic basement.", "order": 44601, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 3, "scene": 11, "line": "I did. I did have a fantastic basement. Now it smells like tacos. You can't air out a basement and taco air is heavy. Settles at the lowest point.", "order": 44602, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 3, "scene": 11, "line": "Right. Um, well how 'bout we take a look at some applications? ...This guy wrote his in green ink, that's pretty cool. Check it out. [attempts to give Darryl application who ignores him at first but then takes it]Hey! There ya go...there he is.", "order": 44603, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 3, "scene": 12, "line": "That is not Darryl. I don't know where Darryl is. I suspect probably our Darryl is inside of fat Darryl.", "order": 44604, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 3, "scene": 13, "line": "OK. Three hundred boxes of twenty pound white. That's seventy-five boxes per person, so that's not so bad.", "order": 44605, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 3, "scene": 13, "line": "Negative! Three hundred boxes for me, zero for you chumps. Deal with it! [climbs into forklift]", "order": 44606, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 3, "scene": 13, "line": "Nice. [Dwight runs forklift into wall of warehouse] Whoa whoa whoa whoa! Oh!", "order": 44607, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 3, "scene": 13, "line": "Damn! [Dwight reverses pulling the wall with him. Erin screams. Dwight leaves forklift and begins lifting boxes by hand.]", "order": 44608, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 3, "scene": 13, "line": "Yup.", "order": 44609, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 3, "scene": 14, "line": "Welcome, everybody! My name is Andy and this is my other brother Darryl.[no one laughs] What? No Newhart fans? OK...Darryl, how do we usually kick these things off?", "order": 44610, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 3, "scene": 14, "line": "You mean what did we do the last time the warehouse won the lottery?", "order": 44611, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 3, "scene": 14, "line": "Your old crew won the lottery?", "order": 44612, "speaker": "Female Applicant"},
  {"season": 8, "episode": 3, "scene": 14, "line": "Does anyone have experience? Shelving, storing, keeping track. What do we use, the Dewey Decimal system?", "order": 44613, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 3, "scene": 14, "line": "Wait, wait. So all the old guys quit?", "order": 44614, "speaker": "Male Applicant 1"},
  {"season": 8, "episode": 3, "scene": 14, "line": "Oh yeah.", "order": 44615, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 3, "scene": 14, "line": "Well-", "order": 44616, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 3, "scene": 14, "line": "One of 'em, Glenn, is starting one of those fat camps where he steals your kid in the middle of the night. Madge and a couple other guys might start a strip club, but on a boat. And Heday is investing in an energy drink for Asian homosexuals. [Andy laughs awkwardly]", "order": 44617, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 3, "scene": 14, "line": "Um, can you guys give us a minute? But stay close, you're all doing great. [group begins leaving] maybe grab a coffee..or if there's any donuts out you can split one. You know they're for everybody so people get fussy....You know what? Just have a donut. [shuts door and sits, gesturing for Darryl to sit next to him. Then gets up to stand near Darryl.] Do you wanna talk about this not winning the lottery thing?", "order": 44618, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 3, "scene": 14, "line": "I don't", "order": 44619, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 3, "scene": 14, "line": "You sure? Cause you keep talking about it, so...", "order": 44620, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 3, "scene": 14, "line": "Nope. I'm good. I'm here. Let's find some warehouse workers.", "order": 44621, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 3, "scene": 14, "line": "Good. Great. Then can you say things that aren't like a huge bummer to everybody? Cause the more I talk, the more they're gonna realize I don't know what I'm talking about.", "order": 44622, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 3, "scene": 14, "line": "OK.", "order": 44623, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 3, "scene": 14, "line": "We need you, OK?", "order": 44624, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 3, "scene": 14, "line": "OK.", "order": 44625, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 3, "scene": 14, "line": "OK?", "order": 44626, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 3, "scene": 14, "line": "Yeah.", "order": 44627, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 3, "scene": 14, "line": "Alright.", "order": 44628, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 3, "scene": 15, "line": "[Grunts while lifting box into truck] What's the problem? Grunting is scientifically proven to add more power. Ask any female tennis player. Or her husband. [Erin grunts loudly and tosses box toward truck but misses]", "order": 44629, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 3, "scene": 15, "line": "I didn't feel anything.", "order": 44630, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 3, "scene": 16, "line": "Alright! Thank you for coming back in, again. Uh, now we're gonna ask you a few questions. Darryl, you have the floor.", "order": 44631, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 3, "scene": 16, "line": "Why do you wanna work here?", "order": 44632, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 3, "scene": 16, "line": "I need a job.", "order": 44633, "speaker": "Male Applicant 1"},
  {"season": 8, "episode": 3, "scene": 16, "line": "That's not a good reason.", "order": 44634, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 3, "scene": 16, "line": "Good. Keepin' 'em honest.", "order": 44635, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 3, "scene": 16, "line": "Don't just take the first job that comes your way. Cause next thing you know, it's ten years later and you're still there. Could write your obituary tomorrow, it's not gonna change.", "order": 44636, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 3, "scene": 16, "line": "Are we scaring them straight....?", "order": 44637, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 3, "scene": 16, "line": "I hope so. Think about this carefully. There's better lives than this one.", "order": 44638, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 3, "scene": 17, "line": "I've never been lucky. And I'm not talkin' about the lottery, I'm talkin' 'bout stuff like developing a soy allergy at thirty-five. Who gets a soy allergy at thirty-five? And why is soy in everything?", "order": 44639, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 3, "scene": 18, "line": "Nice. Right back where I like you. [Pam is sitting at reception covering for Erin] Can you make ten copies of this for me?", "order": 44640, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 3, "scene": 18, "line": "No.", "order": 44641, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 3, "scene": 18, "line": "Why not? What are you doing?", "order": 44642, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 3, "scene": 18, "line": "Uh, buying lottery tickets online. [Ryan laughs]", "order": 44643, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 3, "scene": 18, "line": "Ah, everyone wants to be rich, but nobody wants to work for it.", "order": 44644, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 3, "scene": 18, "line": "You came in at 10:30 today, right?", "order": 44645, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 3, "scene": 18, "line": "OK, (we'll just dismiss it.)??????", "order": 44646, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 3, "scene": 19, "line": "Is everyone licensed?", "order": 44647, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 3, "scene": 19, "line": "Like a driver's license?", "order": 44648, "speaker": "Male Applicant 2"},
  {"season": 8, "episode": 3, "scene": 19, "line": "No. Warehouse license....Masters in warehouse sciences?...I, I feel like Darryl has talked about a license of some kind.", "order": 44649, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 3, "scene": 19, "line": "Is this a joke?", "order": 44650, "speaker": "Female Applicant"},
  {"season": 8, "episode": 3, "scene": 19, "line": "No. Not joking. This is real....painfully real, what is happening right now.", "order": 44651, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 3, "scene": 20, "line": "[On all fours with a box on his back] OK, I'm not gonna make it. I'm turning back.", "order": 44652, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 3, "scene": 20, "line": "There's gotta be a better way to do this. This is literally how they built the pyramids.", "order": 44653, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 3, "scene": 20, "line": "Well, they whipped people which was helpful. But you're right. We should be able to find a more efficient way of moving boxes than Madge or Heday.", "order": 44654, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 3, "scene": 20, "line": "Yeah [laughs and then notices camera] Not that they're not smart people.", "order": 44655, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 3, "scene": 20, "line": "[Noticing camera] No, no. Very smart. Uh, theirs is more of a physical intelligence.", "order": 44656, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 3, "scene": 20, "line": "I'd go with that.", "order": 44657, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 3, "scene": 20, "line": "Like baboons or elephants.", "order": 44658, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 3, "scene": 20, "line": "Not that, don't...", "order": 44659, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 3, "scene": 20, "line": "Guys! When I was a kid, my sisters used to butter me up and slide me across the linoleum floor of the kitchen. Then really made them laugh.", "order": 44660, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 3, "scene": 20, "line": "It's a great idea Kev, I don't think it applies here though, so maybe we just-", "order": 44661, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 3, "scene": 20, "line": "Yeah we move stuff and it was fun.", "order": 44662, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 3, "scene": 20, "line": "Kevin! Doesn't apply.", "order": 44663, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 3, "scene": 20, "line": "Right. My mom-", "order": 44664, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 3, "scene": 20, "line": "[grabbing Kevin's arm] You need to drop it, OK? They hate it. I like it a lot but they hate it so drop it! [Kevin tears up]", "order": 44665, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 3, "scene": 21, "line": "Does anyone get distracted easily by bubble wrap? [raises hand and laughs] You'll be dealing with lots of bubble wrap obviously. Um...", "order": 44666, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 3, "scene": 21, "line": "How much longer is this gonna take?", "order": 44667, "speaker": "Male Applicant 1"},
  {"season": 8, "episode": 3, "scene": 22, "line": "Did you hire 'em?", "order": 44668, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 3, "scene": 22, "line": "No. Because they all left.", "order": 44669, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 3, "scene": 22, "line": "What do you mean 'they left'?", "order": 44670, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 3, "scene": 22, "line": "I mean, after you bailed? I got confused and frankly a little weird and the stuff that you said certainly didn't help.", "order": 44671, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 3, "scene": 22, "line": "Then I think you should fire me.", "order": 44672, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 3, "scene": 22, "line": "What are you talking about? I'm not gonna fire you.", "order": 44673, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 3, "scene": 22, "line": "Yeah. Just put me out of my misery.", "order": 44674, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 3, "scene": 22, "line": "..OK, this is weird. I don't, I don't get the joke.", "order": 44675, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 3, "scene": 22, "line": "No? OK. I don't wanna be here anymore. Fire me.", "order": 44676, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 3, "scene": 23, "line": "So Darryl says to me 'fire me'. But what he really means is 'I'm gonna say something really weird, try and figure out what it means.' So I say 'No, you're not fired.' But what I really mean is 'I have no idea what your talking about, but I'm gonna go ahead and hire some people for the warehouse and hope that you eventually start feeling better.'........I really hope that's what he and I mean.", "order": 44677, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 3, "scene": 24, "line": "Attention! Does anyone know anyone who could work in the warehouse? We can pay. Come on Oscar, who's the most jacked guy in all of Scranton? Like your wildest fantasy guy.", "order": 44678, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 3, "scene": 24, "line": "Bulk or definition?", "order": 44679, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 3, "scene": 24, "line": "Definition.", "order": 44680, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 3, "scene": 24, "line": "Bruce Kenwood. He hangs out at Plant Fitness.", "order": 44681, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 3, "scene": 24, "line": "Are those just show muscles? Or is he really strong?", "order": 44682, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 3, "scene": 24, "line": "Oh, he's plenty strong.", "order": 44683, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 3, "scene": 25, "line": "It used to be Reggie Winters out at Gold's Gym. But he moved away. And then it was between Bruce and this guy Dean. Um, but Dean got fixated on his calves and uh, and his triceps went to hell.", "order": 44684, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 3, "scene": 26, "line": "So. I've been thinking, after we win the lottery, we take our winnings....", "order": 44685, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 3, "scene": 26, "line": "Our fake winnings", "order": 44686, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 3, "scene": 26, "line": "And we move to the south of France. See? No, there's plenty of bicycling for you. I think that's where they do the Tour de France.", "order": 44687, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 3, "scene": 26, "line": "It is, yeah. I mean I just don't know why I'm compromising if it's my fantasy. Cause in my fantasy it's Maine and you love it.", "order": 44688, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 3, "scene": 26, "line": "Because I'm never gonna act like that, even in your fantasy.", "order": 44689, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 3, "scene": 26, "line": "Nope. You're, you're doing a great job of it in my fantasy right now. [Pam sighs]", "order": 44690, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 3, "scene": 26, "line": "Hey, idiot. What did Erin want again?", "order": 44691, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 3, "scene": 26, "line": "A...hot chocolate tea", "order": 44692, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 3, "scene": 27, "line": "Gideon. You are a PhD candidate studying America's diminishing blue-collar workforce?", "order": 44693, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 3, "scene": 27, "line": "North America...and, diminishing is a little reductive, but uh sure. That's the headline version.", "order": 44694, "speaker": "Gideon"},
  {"season": 8, "episode": 3, "scene": 27, "line": "Great....Well, it'll bring a fresh new perspective to the warehouse.", "order": 44695, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 3, "scene": 27, "line": "FYI, Wednesday through Friday I have a pretty full teaching schedule.", "order": 44696, "speaker": "Gideon"},
  {"season": 8, "episode": 3, "scene": 27, "line": "Eh, cool. We'll figure that out.", "order": 44697, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 3, "scene": 27, "line": "Also, FYI, ah, I don't techinically have a hearing problem, but sometimes when there's a lot of noises occurring uh at the same time, I'll hear 'em as one big jumble. Uh, again it's not that I can't hear, uh because that's false. I can. Um, I just can't distinguish between everything I'm hearing.", "order": 44698, "speaker": "Nate Nickerson"},
  {"season": 8, "episode": 3, "scene": 27, "line": "Got it. Dually noted. You! [points to Bruce] Coolest tank top I have ever seen. Where did you get that?", "order": 44699, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 3, "scene": 27, "line": "Made it.", "order": 44700, "speaker": "Bruce"},
  {"season": 8, "episode": 3, "scene": 27, "line": "So cool! What a cross-section we have here. That's what I love about interviewing. I get to meet all these people I wouldn't ordinarily meet or know or even talk to.", "order": 44701, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 3, "scene": 28, "line": "Message in a Bottle, The Postman...", "order": 44702, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 3, "scene": 28, "line": "Kevin Costner.", "order": 44703, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 3, "scene": 28, "line": "Kevin Costner.", "order": 44704, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 3, "scene": 28, "line": "Yeah. [Shot shows Kevin and Erin greasing floor of warehouse]", "order": 44705, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 3, "scene": 28, "line": "So I found this grease. And then I remembered that you thought it was a great idea.", "order": 44706, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 3, "scene": 28, "line": "You did say it was a great idea. I heard you say it!", "order": 44707, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 3, "scene": 29, "line": "So, it's not the dumbest idea.", "order": 44708, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 3, "scene": 29, "line": "It's not the greatest one either...", "order": 44709, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 3, "scene": 29, "line": "But, the fact remains we gotta move these boxes.", "order": 44710, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 3, "scene": 29, "line": "And it's clear we're not going to carry them. [Dwight grunts loudly in shot showing them sliding boxes over the greased floor]", "order": 44711, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 3, "scene": 29, "line": "So sadly, it's the best idea on the table.", "order": 44712, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 3, "scene": 29, "line": "Exactly.", "order": 44713, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 3, "scene": 29, "line": "I think we're ready to give thi- [Kevin slips on grease and falls] Jim?", "order": 44714, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 3, "scene": 29, "line": "Is he OK?", "order": 44715, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 3, "scene": 29, "line": "Yep. He'll be fine.", "order": 44716, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 3, "scene": 30, "line": "Surprise! Your new crew.", "order": 44717, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 3, "scene": 30, "line": "Would you just fire me, man?", "order": 44718, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 3, "scene": 30, "line": "Why? Because you didn't win the lottery? How am I supposed to make you happy?", "order": 44719, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 3, "scene": 30, "line": "You wanna make me happy? Huh?", "order": 44720, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 3, "scene": 30, "line": "Yeah.", "order": 44721, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 3, "scene": 30, "line": "Give me your job.", "order": 44722, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 3, "scene": 30, "line": "Haha, what?", "order": 44723, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 3, "scene": 30, "line": "I'll do it better than you. I earned it. I deserve it. I got passed over, God knows why, reasons I cannot and will not understand. The job was mine Andy, everyone said it was mine. Make me manager or fire me.", "order": 44724, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 3, "scene": 30, "line": "I'm not gonna give you my job! It's my job! I earned it! And here's the thing, you weren't even next in line. I asked about you, I saw your file. You have a history of being short with people and you hired Glenn, your buddy! To replace you in the warehouse. He was under qualified. They saw that.", "order": 44725, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 3, "scene": 30, "line": "Also, Darryl, FYI, I already told this to Andy, but uh, you should probably know I technically don't have a hearing problem, it's just when there's a lot of noises...", "order": 44726, "speaker": "Nate Nickerson"},
  {"season": 8, "episode": 3, "scene": 30, "line": "Nate! Please....thank you. You have no business education, you were gonna take classes under D'Angelo, what happened to that?", "order": 44727, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 3, "scene": 30, "line": "He died.", "order": 44728, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 3, "scene": 30, "line": "He didn't die, his brain died. And my brain is still very much alive and I'd be happy to give you business classes. How come you haven't asked me about it?", "order": 44729, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 3, "scene": 30, "line": "What wa-, what was the last...I'm havin' a-", "order": 44730, "speaker": "Nate Nickerson"},
  {"season": 8, "episode": 3, "scene": 30, "line": "None, no part of this has anything to do with you.", "order": 44731, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 3, "scene": 30, "line": "I didn't have time because of my daughter.", "order": 44732, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 3, "scene": 30, "line": "Oh, but you had time for a softball clinic, and a Mediterranean cooking class.", "order": 44733, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 3, "scene": 30, "line": "Hey I'm not gonna tell you this stuff if you gonna throw it back in my face.", "order": 44734, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 3, "scene": 30, "line": "Hey. Here's the thing. Jo saw something in you. She loved you! She gave you a shot and then you stopped pushing. She noticed. [long pause]", "order": 44735, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 3, "scene": 30, "line": "OK.", "order": 44736, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 3, "scene": 30, "line": "OK what?", "order": 44737, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 3, "scene": 30, "line": "OK, don't fire me.", "order": 44738, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 3, "scene": 30, "line": "Ah, OK.", "order": 44739, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 3, "scene": 31, "line": "My future's not gonna be determined by seven little white lotto balls. It's gonna be determined by two big black balls. I control my destiny. I do.", "order": 44740, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 3, "scene": 32, "line": "I put some guys on tonight. Best of your bunch and my bunch. Tell you now though, it's gonna be mostly my bunch.", "order": 44741, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 3, "scene": 32, "line": "Yeah. [laughs] That makes sense. OK. Good, alright.", "order": 44742, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 3, "scene": 32, "line": "Here...", "order": 44743, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 3, "scene": 32, "line": "What is goin' on?! [shot shows greased aisle flanked by rows of boxes]", "order": 44744, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 3, "scene": 32, "line": "Oh hey guys.", "order": 44745, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 3, "scene": 32, "line": "Why is the forklift in the wall?", "order": 44746, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 3, "scene": 32, "line": "Why is the truck empty?", "order": 44747, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 3, "scene": 32, "line": "Uh, it's not totally empty.", "order": 44748, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 3, "scene": 32, "line": "Is that grease on my floor?", "order": 44749, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 3, "scene": 32, "line": "OK, I can see why you're angry, you're coming into this cold. But believe me a lot of thought went into this.", "order": 44750, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 3, "scene": 32, "line": "And did your brains tell you to ruin these boxes with grease?", "order": 44751, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 3, "scene": 32, "line": "OK Darryl, listen and then you will understand. The boxes were ruined during our first trial testing so now it's cool cause we found another use for them.", "order": 44752, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 3, "scene": 32, "line": "OK, alright, that's...look. All we were trying to do is we thought we could come up with a more efficient way to do things.", "order": 44753, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 3, "scene": 32, "line": "And?", "order": 44754, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 3, "scene": 32, "line": "And we did.", "order": 44755, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 3, "scene": 32, "line": "I don't know.", "order": 44756, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 3, "scene": 32, "line": "Jim? Tell them what it's called.", "order": 44757, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 3, "scene": 32, "line": "That's alright.", "order": 44758, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 3, "scene": 32, "line": "No Jim, tell 'em what a name is.", "order": 44759, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 3, "scene": 32, "line": "Doesn't matter what the name is. Seor Loadenstein, that's stupid.", "order": 44760, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 3, "scene": 32, "line": "[laughing] Seor Loadenstein. Tell 'em why it's called that, Jim.", "order": 44761, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 3, "scene": 32, "line": "That's OK, we're good.", "order": 44762, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 3, "scene": 32, "line": "Jim...", "order": 44763, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 3, "scene": 32, "line": "No, Jim. Tell us why it's called Seor Loadenstein.", "order": 44764, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 3, "scene": 32, "line": "Porque es muy rapido.", "order": 44765, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 3, "scene": 32, "line": "OK. You know what? It's been a real busy day, what do you say we put all this away.", "order": 44766, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 3, "scene": 32, "line": "Let me see it.", "order": 44767, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 3, "scene": 32, "line": "It's uh, it's in beta testing.", "order": 44768, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 3, "scene": 32, "line": "Let me see it!", "order": 44769, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 3, "scene": 32, "line": "Get the thing, go! Lube it up, Kevin! Start mopping.", "order": 44770, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 3, "scene": 32, "line": "Uno! Dos! Tres! [Shot shows Jim & Dwight pulling ropes attached to a plank holding Erin in a helmet and boxes of paper down the greased runway, Dwight grunts]", "order": 44771, "speaker": "Dwight Schrute, Erin Hannon, Jim Halpert & Kevin Malone"},
  {"season": 8, "episode": 3, "scene": 33, "line": "Yeah, I lost my client.", "order": 44772, "speaker": "Phyllis Vance"},
  {"season": 8, "episode": 3, "scene": 34, "line": "I already won the lottery. I was born in the US of A, baby. And as backup I have a Swiss passport.", "order": 44773, "speaker": "Creed Bratton"},
  {"season": 8, "episode": 3, "scene": 35, "line": "I would spend a lot of time launching my true crime podcast, The Flenderson Files. Dum bum buh. [whispers] Flenderson files.", "order": 44774, "speaker": "Toby Flenderson"},
  {"season": 8, "episode": 3, "scene": 36, "line": "We came to an agreement. We're going to live in a stunning pre-war brownstone at the top of a mountain.", "order": 44775, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 3, "scene": 36, "line": "Right. It's city and country combined.", "order": 44776, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 3, "scene": 36, "line": "Just a subway stop away are the best museums in the world.", "order": 44777, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 3, "scene": 36, "line": "And I can fish right from the window of Pam's pottery studio. And we can chat any time we want.", "order": 44778, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 3, "scene": 36, "line": "Just like now.", "order": 44779, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 3, "scene": 36, "line": "[laughs] Just like now....too bad the schools are terrible.", "order": 44780, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 3, "scene": 36, "line": "Oh..", "order": 44781, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 3, "scene": 36, "line": "But what are you gonna do about that?", "order": 44782, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 3, "scene": 36, "line": "What are you gonna do?", "order": 44783, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 3, "scene": 37, "line": "If I won the lottery, I don't know. I don't think I'd make any changes to my life. Quit my job, move, meet someone....", "order": 44784, "speaker": "Toby Flenderson"},
  {"season": 8, "episode": 3, "scene": 38, "line": "I'd give 35% to AIDS related charities. 25%. If they can't cure AIDS with 25%, the extra ten's not gonna make a difference. At some point, you're just throwing good money after bad.", "order": 44785, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 3, "scene": 39, "line": "The first thing I'd buy is new boobs. For my mom. She has the worst boobs. It- It's embarrassing.", "order": 44786, "speaker": "Phyllis Vance"},
  {"season": 8, "episode": 3, "scene": 40, "line": "Ok, ok. We are so close. All we have to do is figure out that corner and we're basically there.", "order": 44787, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 3, "scene": 40, "line": "I know. Kevin, we've been friends for a long time, right?", "order": 44788, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 3, "scene": 40, "line": "Egons.", "order": 44789, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 3, "scene": 40, "line": "So if I was to ask you to sacrifice your body and lay down on a greasy corner and act as a human bumper shield-", "order": 44790, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 3, "scene": 40, "line": "Ok, Dwight! Come on. Here, I think I have an idea.", "order": 44791, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 3, "scene": 40, "line": "I wouldn't be asking you lightly, now would I?", "order": 44792, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 3, "scene": 40, "line": "No.", "order": 44793, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 3, "scene": 40, "line": "Right. Now do you wanna wear a trash bag, er...", "order": 44794, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 3, "scene": 40, "line": "Dammit Dwight!", "order": 44795, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 3, "scene": 40, "line": "However it's normally done.", "order": 44796, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 3, "scene": 41, "line": "Ok, I have a question. Why is the truck so far away?", "order": 44797, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 3, "scene": 41, "line": "Yeah Jim, why's it so far?", "order": 44798, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 3, "scene": 41, "line": "Why's it so far away?", "order": 44799, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 3, "scene": 41, "line": "Ok, I just asked that ques-, I don't know. I mean it seems like the door is huge, right? So you should be able to back the truck up to the paper.", "order": 44800, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 3, "scene": 41, "line": "Yeah, why is it so far away Jim?", "order": 44801, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 3, "scene": 42, "line": "So this warehouse has been around for what? Like a thousand years? And they never thought to back the truck up into it? I guess sometimes it just takes a fresh set of eyes. Alright! [knocks on side of truck]", "order": 44802, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 3, "scene": 42, "line": "Back...", "order": 44803, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 3, "scene": 42, "line": "Yeah.", "order": 44804, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 3, "scene": 42, "line": "That looks good. Back. Whoa whoa whoa!", "order": 44805, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 3, "scene": 42, "line": "Whoa whoa whoa!", "order": 44806, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 3, "scene": 42, "line": "You're doing great! A little farther away from the wall!", "order": 44807, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 3, "scene": 42, "line": "No no no!", "order": 44808, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 3, "scene": 42, "line": "Good...", "order": 44809, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 3, "scene": 42, "line": "No! Stop! Stop stop stop stop! You're way over! Ok, you gotta cut it! Cut it hard! (bleep!) Stop stop stop stop! Stop, stop! Dammit Dwight. Great.", "order": 44810, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 3, "scene": 42, "line": "Come on!", "order": 44811, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 3, "scene": 42, "line": "Good.", "order": 44812, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 4, "scene": 1, "line": "[entering office] Hey. So, we saw a new billboard.", "order": 44813, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 4, "scene": 1, "line": "Yeah? Pretty cool, huh?", "order": 44814, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 4, "scene": 2, "line": "What better way to announce our new slightly lower prices than with an ad campaign? And what better face for an ad campaign than our new regional manager?", "order": 44815, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 4, "scene": 3, "line": "How'd it look?", "order": 44816, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 4, "scene": 3, "line": "You've seen it, right?", "order": 44817, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 4, "scene": 3, "line": "No.", "order": 44818, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 4, "scene": 3, "line": "Andy, somebody defaced it.", "order": 44819, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 4, "scene": 3, "line": "What? [Stanley enters office laughing] Morning, Stanley.", "order": 44820, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 4, "scene": 4, "line": "There's this thing that people tend to do with billboards. How do I put this? If there's an opportunity for a graffiti artist to work in a... phallic shape, interacting with the artwork, it'll happen, and Andy gave them that opportunity.", "order": 44821, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 4, "scene": 5, "line": "Erin, how long did we order those billboards for?", "order": 44822, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 4, "scene": 5, "line": "Six months.", "order": 44823, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 4, "scene": 5, "line": "Oh, god. I need you to call the billboard company.", "order": 44824, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 4, "scene": 5, "line": "[entering office] Great billboard! Funny, edgy, right up to the line without crossing it, loved it.", "order": 44825, "speaker": "Meredith Palmer"},
  {"season": 8, "episode": 4, "scene": 5, "line": "Which one did you see?", "order": 44826, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 4, "scene": 5, "line": "Washington Street, the one with, like, twenty dongs on it.", "order": 44827, "speaker": "Meredith Palmer"},
  {"season": 8, "episode": 4, "scene": 5, "line": "Okay, don't be gross.", "order": 44828, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 4, "scene": 5, "line": "[entering office] What's going on?", "order": 44829, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 4, "scene": 5, "line": "Somebody defaced the billboards we just put out in an inappropriate way.", "order": 44830, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 4, "scene": 5, "line": "Oh, that's funny. Wait, from the photo shoot with you and me?", "order": 44831, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 4, "scene": 5, "line": "Yeah, Dwight.", "order": 44832, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 4, "scene": 5, "line": "No. No. No! NO! NO! NO! NO! NO! [running from building] No, no, no, no, no, no, No, No, No, NO, NO! NO! NO! NO! [stopping in front of defaced billboard] NOOOOOOOOO!", "order": 44833, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 4, "scene": 6, "line": "Schrute Farms is very easy to find, it's right in the middle of the root vegetable district. If the soil starts to get acidic, you've probably gone too far.", "order": 44834, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 4, "scene": 6, "line": "Just give us the address. We'll look it up online.", "order": 44835, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 4, "scene": 6, "line": "It's simpler this way.", "order": 44836, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 4, "scene": 6, "line": "It's really not.", "order": 44837, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 4, "scene": 6, "line": "Now, stated arrival time is 3 p.m.", "order": 44838, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 4, "scene": 6, "line": "I don't get the reason for this party.", "order": 44839, "speaker": "Kelly Kapoor"},
  {"season": 8, "episode": 4, "scene": 6, "line": "Yeah, what's the reason?", "order": 44840, "speaker": "Phyllis Vance"},
  {"season": 8, "episode": 4, "scene": 6, "line": "What's the reason, Andy? What's the reason?", "order": 44841, "speaker": "Kelly Kapoor"},
  {"season": 8, "episode": 4, "scene": 6, "line": "It's just a garden party. Sheesh.", "order": 44842, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 4, "scene": 7, "line": "You don't need a reason to throw a garden party anymore than you need a reason to throw a birthday party. It's a garden party. You don't need a reason.", "order": 44843, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 4, "scene": 8, "line": "Few other super simple reminders ' no burping, no slurping, when eating take small bites and chew thoroughly with your mouth closed. Yes, Darryl?", "order": 44844, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 4, "scene": 8, "line": "What happens when we're done chewing? Do we spit it out on the floor, or keep it in our mouths the rest of the time?", "order": 44845, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 4, "scene": 8, "line": "I get it, I'm being a little overbearing. But I promise to be underbearing for a week if you guys just all... step it up.", "order": 44846, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 4, "scene": 8, "line": "Andy, we understand basic table manners, we're not children.", "order": 44847, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 4, "scene": 8, "line": "Yeah, it's just a picnic.", "order": 44848, "speaker": "Phyllis Vance"},
  {"season": 8, "episode": 4, "scene": 8, "line": "This is not a picnic, Phyllis, it's a garden party.", "order": 44849, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 4, "scene": 8, "line": "There's a grill in the warehouse I could bring.", "order": 44850, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 4, "scene": 8, "line": "Aw, ew, please don't. Not a barbecue. It's better than a barbecue. It's dignified, quieter, there are rules.", "order": 44851, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 4, "scene": 8, "line": "How is that better than a barbecue?", "order": 44852, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 4, "scene": 8, "line": "What's the dress code on this?", "order": 44853, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 4, "scene": 8, "line": "I'm glad you asked ' Connecticut Casual.", "order": 44854, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 4, "scene": 8, "line": "Any chance Connecticut Casual is Pennsylvania Business, i.e. this is what I'm wearing to your party.", "order": 44855, "speaker": "Stanley Hudson"},
  {"season": 8, "episode": 4, "scene": 8, "line": "Guys, my family just threw a garden party to celebrate my brother's promotion. It was a huge success. Check it out, there's a video online. Heartwarming impromptu father-son duet. [video playing on computer]", "order": 44856, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 4, "scene": 8, "line": "Yeah, Oscar, you showed me this.", "order": 44857, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 4, "scene": 8, "line": "I've seen this, that's not you.", "order": 44858, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 4, "scene": 8, "line": "Yeah, whoa, who's the sausage?", "order": 44859, "speaker": "Meredith Palmer"},
  {"season": 8, "episode": 4, "scene": 8, "line": "It's my dad and my brother. I would have joined in but this melody doesn't really support another harmony part, so... Check it out, that's my brother's boss. Look how psyched he is, he's having an awesome time.", "order": 44860, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 4, "scene": 8, "line": "Ah, and did Robert California get an invite to your party?", "order": 44861, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 4, "scene": 8, "line": "Yeah, of course. Ye... I mean, uh, yeah, I think he did. [groans from the group]", "order": 44862, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 4, "scene": 8, "line": "Why didn't you just say this was to impress Robert California?", "order": 44863, "speaker": "Phyllis Vance"},
  {"season": 8, "episode": 4, "scene": 9, "line": "I cannot believe that Andy is throwing a party like this just to impress the CEO. Classic Gabe move. Hey Andy, how about you don't steal my business strategies, and I won't dress like my life is just one long brunch?", "order": 44864, "speaker": "Gabe Lewis"},
  {"season": 8, "episode": 4, "scene": 10, "line": "See you all there at 3 p.m., Connecticut Casual, remember your manners, and have a wonderful time.", "order": 44865, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 4, "scene": 10, "line": "And please refer to the map, stay off of the web. Thank you.", "order": 44866, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 4, "scene": 11, "line": "[pointing to computer monitor] So, we've typed in the address, now let's take a look at the street view. [picture of Dwight and Mose on a see-saw]", "order": 44867, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 4, "scene": 11, "line": "They don't warn you when the cameras are driving by.", "order": 44868, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 4, "scene": 11, "line": "Ah. Why do you keep reading that garden party book? I mean, how hard are finger sandwiches and tea?", "order": 44869, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 4, "scene": 11, "line": "There's so much more to it than that.", "order": 44870, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 4, "scene": 12, "line": "I've been wanting Schrute Farms to break into the high-end event hosting industry for some time, and this party is a great opportunity. Plus, I've got a secret weapon. [holds up book, Throwing A Garden Party by James Trickington] Only one copy in the world and some sucker on the internet sold it to me for two dollars. [laughs]", "order": 44871, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 4, "scene": 13, "line": "I'm actually really disappointed in how poorly my book is doing. [holds up same book] I've only sold one copy.", "order": 44872, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 4, "scene": 14, "line": "Is there anything you wish you had done differently to avoid cankles?", "order": 44873, "speaker": "Angela Martin"},
  {"season": 8, "episode": 4, "scene": 14, "line": "Nope.", "order": 44874, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 4, "scene": 14, "line": "I've already gone up another cup size. The senator is grossed out. When do you start feeling it kick?", "order": 44875, "speaker": "Angela Martin"},
  {"season": 8, "episode": 4, "scene": 14, "line": "Cece was around 22 weeks, but Phillip was much earlier.", "order": 44876, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 4, "scene": 14, "line": "Phillip?", "order": 44877, "speaker": "Angela Martin"},
  {"season": 8, "episode": 4, "scene": 14, "line": "Oh, oh my gosh. Yes, but don't, don't say anything, okay? It's after my grandfather.", "order": 44878, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 4, "scene": 14, "line": "Phillip is the name that we're using. It's after my favorite cat.", "order": 44879, "speaker": "Angela Martin"},
  {"season": 8, "episode": 4, "scene": 14, "line": "[giggling] Oh, wow. Funny. It's after my grandfather.", "order": 44880, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 4, "scene": 14, "line": "It's after my cat.", "order": 44881, "speaker": "Angela Martin"},
  {"season": 8, "episode": 4, "scene": 15, "line": "[on the phone] Andrew, I've picked up two possible gifts to bring this afternoon. One, a pot of marmalade.", "order": 44882, "speaker": "Robert California"},
  {"season": 8, "episode": 4, "scene": 15, "line": "Ooh, that sounds great.", "order": 44883, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 4, "scene": 15, "line": "Well, that, that is what you want then, the marmalade.", "order": 44884, "speaker": "Robert California"},
  {"season": 8, "episode": 4, "scene": 15, "line": "Sure.", "order": 44885, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 4, "scene": 15, "line": "You don't want to hear the other one. You love marmalade.", "order": 44886, "speaker": "Robert California"},
  {"season": 8, "episode": 4, "scene": 15, "line": "Uh, I'll hear the other one.", "order": 44887, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 4, "scene": 15, "line": "It's a basil plant.", "order": 44888, "speaker": "Robert California"},
  {"season": 8, "episode": 4, "scene": 15, "line": "You know, the marmalade sounds great.", "order": 44889, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 4, "scene": 15, "line": "I also mentioned the marmalade to my sister and she's very interested.", "order": 44890, "speaker": "Robert California"},
  {"season": 8, "episode": 4, "scene": 15, "line": "Then the basil will be fine.", "order": 44891, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 4, "scene": 15, "line": "Well, you clearly want the marmalade. Gretchen, I need another marmalade. Alright. [hangs up]", "order": 44892, "speaker": "Robert California"},
  {"season": 8, "episode": 4, "scene": 16, "line": "Hey, where do I park?", "order": 44893, "speaker": "Toby Flenderson"},
  {"season": 8, "episode": 4, "scene": 16, "line": "I'm the valet. You have to give me your car.", "order": 44894, "speaker": "Mose Schrute"},
  {"season": 8, "episode": 4, "scene": 16, "line": "Uh, you know what, I can go park it myself.", "order": 44895, "speaker": "Toby Flenderson"},
  {"season": 8, "episode": 4, "scene": 16, "line": "I'm the... I'm the valet. You have to give me your car.", "order": 44896, "speaker": "Mose Schrute"},
  {"season": 8, "episode": 4, "scene": 16, "line": "It's probably okay...", "order": 44897, "speaker": "Toby Flenderson"},
  {"season": 8, "episode": 4, "scene": 16, "line": "Give me your car.", "order": 44898, "speaker": "Mose Schrute"},
  {"season": 8, "episode": 4, "scene": 16, "line": "I think it's better that I...", "order": 44899, "speaker": "Toby Flenderson"},
  {"season": 8, "episode": 4, "scene": 16, "line": "Give me your car.", "order": 44900, "speaker": "Mose Schrute"},
  {"season": 8, "episode": 4, "scene": 16, "line": "It's a finicky car...", "order": 44901, "speaker": "Toby Flenderson"},
  {"season": 8, "episode": 4, "scene": 16, "line": "Get out. Have a good time at the thing.", "order": 44902, "speaker": "Mose Schrute"},
  {"season": 8, "episode": 4, "scene": 16, "line": "It's a little tricky, you might have to... take... [Mose speeds away]", "order": 44903, "speaker": "Toby Flenderson"},
  {"season": 8, "episode": 4, "scene": 17, "line": "MR. RYAN HOWARD!", "order": 44904, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 4, "scene": 18, "line": "Chapter 2 ' Announcing guests as they enter is the height of decorum. The more volume displayed, the more honor is bestowed upon everyone present.", "order": 44905, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 4, "scene": 19, "line": "STANLEY HUDSON AND HIS MISTRESS CYNTHIA! JAMES, PAMELA, AND PEEPEE HALPERT!", "order": 44906, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 4, "scene": 20, "line": "How's it going over here, guys?", "order": 44907, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 4, "scene": 20, "line": "Andy, stop hovering, you're being really annoying.", "order": 44908, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 4, "scene": 20, "line": "Didn't mean to bother you, Mabel. Mabel, Mabel, if you're able, keep your elbows off the table.", "order": 44909, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 4, "scene": 20, "line": "Wow.", "order": 44910, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 4, "scene": 20, "line": "MR. AND MRS. WALTER AND ELLEN BERNARD!", "order": 44911, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 4, "scene": 21, "line": "So, you all work with Andy.", "order": 44912, "speaker": "Walter"},
  {"season": 8, "episode": 4, "scene": 21, "line": "Well, technically FOR Andy.", "order": 44913, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 4, "scene": 21, "line": "Technically FOR Robert California. He's our CEO.", "order": 44914, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 4, "scene": 21, "line": "I thought you were the CEO.", "order": 44915, "speaker": "Ellen"},
  {"season": 8, "episode": 4, "scene": 21, "line": "I don't know how you got there.", "order": 44916, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 4, "scene": 21, "line": "You said you were running the company.", "order": 44917, "speaker": "Walter"},
  {"season": 8, "episode": 4, "scene": 21, "line": "This branch, I'm the regional manager.", "order": 44918, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 4, "scene": 21, "line": "Yes, that makes more sense. Are you all regional managers?", "order": 44919, "speaker": "Walter"},
  {"season": 8, "episode": 4, "scene": 22, "line": "Did I throw this party to impress my parents? That's crazy. Now, if they wanted a garden party, they could throw one themselves, which, as a matter of fact, they did, last week. They threw one for my baby brother who is totally amazing, but I couldn't care less.", "order": 44920, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 4, "scene": 23, "line": "Can I introduce you around now?", "order": 44921, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 4, "scene": 23, "line": "Uh, yeah, we don't have too long, we have theater tickets.", "order": 44922, "speaker": "Walter"},
  {"season": 8, "episode": 4, "scene": 23, "line": "What are you going to see?", "order": 44923, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 4, "scene": 23, "line": "Money Ball. Walter Jr.'s choice.", "order": 44924, "speaker": "Walter"},
  {"season": 8, "episode": 4, "scene": 23, "line": "What??", "order": 44925, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 4, "scene": 23, "line": "Hey Bronard!", "order": 44926, "speaker": "Walter Jr"},
  {"season": 8, "episode": 4, "scene": 23, "line": "I didn't know you were going to be here.", "order": 44927, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 4, "scene": 23, "line": "I wouldn't have missed it.", "order": 44928, "speaker": "Walter Jr"},
  {"season": 8, "episode": 4, "scene": 24, "line": "Ahh! [bird steals her hat]", "order": 44929, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 4, "scene": 25, "line": "I'm Walter, Andy's younger brother.", "order": 44930, "speaker": "Walter Jr"},
  {"season": 8, "episode": 4, "scene": 25, "line": "Oh, hey, how are you doing, I'm Jim.", "order": 44931, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 4, "scene": 25, "line": "Tuna, right? And this must be your lovely wife Pam [looking at Meredith].", "order": 44932, "speaker": "Walter Jr"},
  {"season": 8, "episode": 4, "scene": 25, "line": "No.", "order": 44933, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 4, "scene": 25, "line": "Hell no. She wishes.", "order": 44934, "speaker": "Meredith Palmer"},
  {"season": 8, "episode": 4, "scene": 25, "line": "No, no, no, no, no. No.", "order": 44935, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 4, "scene": 26, "line": "Sir, I need to be able to feed all the guests.", "order": 44936, "speaker": "Waiter"},
  {"season": 8, "episode": 4, "scene": 26, "line": "[mouthful of food] I understand.", "order": 44937, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 4, "scene": 27, "line": "Set that down. [grabs waitress] Present yourself.", "order": 44938, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 4, "scene": 28, "line": "Chapter 4 ' One of the host's most important duties is as Dance Master. A proper courtly dance sets the tone for the entire afternoon.", "order": 44939, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 4, "scene": 29, "line": "LADIES AND GENTLEMEN, MAY I PRESENT, ROBERT CALIFORNIA!", "order": 44940, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 4, "scene": 29, "line": "Hello Andy.", "order": 44941, "speaker": "Robert California"},
  {"season": 8, "episode": 4, "scene": 29, "line": "Welcome.", "order": 44942, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 4, "scene": 29, "line": "Thank you.", "order": 44943, "speaker": "Robert California"},
  {"season": 8, "episode": 4, "scene": 29, "line": "Hey, I want you to meet my first bosses, Mom and Dad.", "order": 44944, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 4, "scene": 29, "line": "Yes, hello, nice to meet you.", "order": 44945, "speaker": "Robert California"},
  {"season": 8, "episode": 4, "scene": 29, "line": "Pleasure.", "order": 44946, "speaker": "Walter"},
  {"season": 8, "episode": 4, "scene": 29, "line": "Andy, where shall I put your basil plant?", "order": 44947, "speaker": "Robert California"},
  {"season": 8, "episode": 4, "scene": 29, "line": "Oh, I thought you were going to bring marmalade.", "order": 44948, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 4, "scene": 29, "line": "No, I'm certain you said basil.", "order": 44949, "speaker": "Robert California"},
  {"season": 8, "episode": 4, "scene": 30, "line": "This is not funny, man, let me down.", "order": 44950, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 4, "scene": 30, "line": "I think this thing is broken.", "order": 44951, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 4, "scene": 30, "line": "It's not broken, Kevin. This is how it works.", "order": 44952, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 4, "scene": 31, "line": "So pretty today.", "order": 44953, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 4, "scene": 31, "line": "It's getting chilly.", "order": 44954, "speaker": "Ellen"},
  {"season": 8, "episode": 4, "scene": 31, "line": "We really should be leaving.", "order": 44955, "speaker": "Walter"},
  {"season": 8, "episode": 4, "scene": 31, "line": "Yeah, um, it would be weird if the boss man didn't make a toast, so, hang on. Everybody, I just want to take this opportunity to thank you all for coming, and to raise a glass to my amazing staff.", "order": 44956, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 4, "scene": 32, "line": "Toasts are great. I mean, you toast somebody, they toast you back. It just goes round and round. That's my favorite part about toasts, the reciprocity.", "order": 44957, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 4, "scene": 33, "line": "Let's hear from you guys, who do you want to toast?", "order": 44958, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 4, "scene": 33, "line": "Yeah, I'll say something. I think we should be acknowledging our boss, because none of us would be here without him. Robert California! [everyone toasts]", "order": 44959, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 4, "scene": 34, "line": "I can't believe I didn't think of toasting Robert. Get in the game, Gabriel! Why are you talking to Stanley's mistress?", "order": 44960, "speaker": "Gabe Lewis"},
  {"season": 8, "episode": 4, "scene": 35, "line": "I would like to toast someone who isn't here but who will be in just four short months. Welcome to the world, Phillip Lipton!", "order": 44961, "speaker": "Angela Martin"},
  {"season": 8, "episode": 4, "scene": 35, "line": "I also would like to toast Phillip Halpert, who is due even sooner. May he be a good namesake to my grandfather who I promised as a child long before tonight that I would one day name my son after him. To Phillip Halpert!", "order": 44962, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 4, "scene": 36, "line": "She just always has to copy anything I do! It's the Ford Taurus situation all over again!", "order": 44963, "speaker": "Angela Martin"},
  {"season": 8, "episode": 4, "scene": 37, "line": "I'd like to make a toast to the troops. All the troops. Both sides.", "order": 44964, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 4, "scene": 37, "line": "I think we should toast...", "order": 44965, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 4, "scene": 38, "line": "Ladies and gentlemen! The last supper.", "order": 44966, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 4, "scene": 39, "line": "Chapter 9 ' The tableau vivant is not only welcomed, but expected entertainment at any garden party.", "order": 44967, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 4, "scene": 40, "line": "Andy? I think we should acknowledge the man who has led us to such a profitable quarter. To Robert California.", "order": 44968, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 4, "scene": 40, "line": "I would also like to toast Robert California. Mr. California,...", "order": 44969, "speaker": "Gabe Lewis"},
  {"season": 8, "episode": 4, "scene": 40, "line": "You can't triple toast somebody! At least not until we get everyone once.", "order": 44970, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 4, "scene": 40, "line": "I'll say a few words if that's alright with you, Andy.", "order": 44971, "speaker": "Robert California"},
  {"season": 8, "episode": 4, "scene": 40, "line": "Yeah, yeah.", "order": 44972, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 4, "scene": 40, "line": "You people say I led you, but it wasn't me. You want to toast the man who led you to success, but the boss is irrelevant. Andy and I, we produce nothing. We do nothing. We sit in our offices and demand, I want this and that right now, like petulant children. You know, the difference between a crying baby and a manager, one day the baby will grow up. But, without you, Andy and I would be sitting in our dirty diapers, waiting for someone to change us, wipe us. I should be toasting you, thanking you, for allowing me to have the easiest job in the universe. Cheers.", "order": 44973, "speaker": "Robert California"},
  {"season": 8, "episode": 4, "scene": 40, "line": "To Robert California, from the moment you entered our building and our hearts, you...", "order": 44974, "speaker": "Gabe Lewis"},
  {"season": 8, "episode": 4, "scene": 40, "line": "Some of you know that we have an internet star in our midst. Mr. Walter Baynes Bernard Sr., please report to the stage!", "order": 44975, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 4, "scene": 40, "line": "I'm eating, Andy.", "order": 44976, "speaker": "Walter"},
  {"season": 8, "episode": 4, "scene": 40, "line": "Ah, come on!", "order": 44977, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 4, "scene": 40, "line": "Okay. Okay.", "order": 44978, "speaker": "Walter"},
  {"season": 8, "episode": 4, "scene": 40, "line": "Yes!", "order": 44979, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 4, "scene": 40, "line": "What do you want to do?", "order": 44980, "speaker": "Walter"},
  {"season": 8, "episode": 4, "scene": 40, "line": "Well, how about one of our classic father/son duets? [starts playing guitar]", "order": 44981, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 4, "scene": 40, "line": "Oh, whoa, sure? Really? It's a little tricky.", "order": 44982, "speaker": "Walter"},
  {"season": 8, "episode": 4, "scene": 40, "line": "[singing] Saying I love you is not the words...", "order": 44983, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 4, "scene": 40, "line": "It's too high.", "order": 44984, "speaker": "Walter"},
  {"season": 8, "episode": 4, "scene": 40, "line": "Yeah?", "order": 44985, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 4, "scene": 40, "line": "It's high but it's not that high.", "order": 44986, "speaker": "Walter"},
  {"season": 8, "episode": 4, "scene": 40, "line": "Right, it feels strange.", "order": 44987, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 4, "scene": 40, "line": "Just take it down a little.", "order": 44988, "speaker": "Walter"},
  {"season": 8, "episode": 4, "scene": 40, "line": "Saying I love... Saying I love you...", "order": 44989, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 4, "scene": 40, "line": "Here, give it to me. Okay. Uh, something like... [singing] Saying I love you is...", "order": 44990, "speaker": "Walter"},
  {"season": 8, "episode": 4, "scene": 40, "line": "...not the words...", "order": 44991, "speaker": "Andy Bernard & Walter"},
  {"season": 8, "episode": 4, "scene": 40, "line": "You don't come in yet. [singing] ...I want to hear from you, It's not that I want you...", "order": 44992, "speaker": "Walter"},
  {"season": 8, "episode": 4, "scene": 40, "line": "...not to say it but if you only knew... More than words is all you have to do to make it real...", "order": 44993, "speaker": "Andy Bernard & Walter"},
  {"season": 8, "episode": 4, "scene": 40, "line": "Is anyone filming this? Seriously! Erin!", "order": 44994, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 4, "scene": 40, "line": "[holding up cell phone] It's either taping or calling.", "order": 44995, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 4, "scene": 40, "line": "What would you do if my heart was torn in two...", "order": 44996, "speaker": "Andy Bernard & Walter"},
  {"season": 8, "episode": 4, "scene": 40, "line": "You know what, Walter Jr. is here. Why don't you come up and join us?", "order": 44997, "speaker": "Walter"},
  {"season": 8, "episode": 4, "scene": 40, "line": "Dad, no. I'm just having a good time getting to know Andy's friends here.", "order": 44998, "speaker": "Walter Jr"},
  {"season": 8, "episode": 4, "scene": 40, "line": "Get up there and sing or I will cut your larynx and you'll never be able to sing again. Woo!", "order": 44999, "speaker": "Meredith Palmer"},
  {"season": 8, "episode": 4, "scene": 40, "line": "[singing] May the good lord be with you down every road you roam. And may sunshine and happiness surround you when you're far from home. And may you grow to be proud, dignified and true. And do unto others as you'd have done to you...", "order": 45000, "speaker": "Walter & Walter Jr"},
  {"season": 8, "episode": 4, "scene": 40, "line": "[applauds] Cheers! Alright! Cheers, cheers, cheers, get your own guitar.", "order": 45001, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 4, "scene": 40, "line": "If you'd like to make a call, please hang up and try again.", "order": 45002, "speaker": "Erin Hannon's Cell Phone"},
  {"season": 8, "episode": 4, "scene": 40, "line": "Sorry.", "order": 45003, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 4, "scene": 41, "line": "I am so cold.", "order": 45004, "speaker": "Kelly Kapoor"},
  {"season": 8, "episode": 4, "scene": 41, "line": "That's because you didn't bring a jacket.", "order": 45005, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 4, "scene": 42, "line": "My first love is beet farming, but it's a young man's game. Who ever heard of an old beet farmer?", "order": 45006, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 4, "scene": 42, "line": "Forget the beets. Concentrate on the hosting. I could spend a considerable amount of money having my birthday party here.", "order": 45007, "speaker": "Robert California"},
  {"season": 8, "episode": 4, "scene": 42, "line": "Oh really? Well, we have a number of birthday packages. The Pewter Package has the least amount of goats, not no goats, it's still 10-12 goats, depending on the availability of the goats. Now the Goat Package obviously has the most goats. What were you thinking?", "order": 45008, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 4, "scene": 42, "line": "Of course I am not interested in goats. Why would you spend so much time going over the goats with me?", "order": 45009, "speaker": "Robert California"},
  {"season": 8, "episode": 4, "scene": 42, "line": "I can get you exotic meats ' hippo steaks, giraffe burgers...", "order": 45010, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 4, "scene": 42, "line": "We'll talk. [walks away]", "order": 45011, "speaker": "Robert California"},
  {"season": 8, "episode": 4, "scene": 42, "line": "It'll all be goat.", "order": 45012, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 4, "scene": 43, "line": "Hello, Cece Halpert? This is Andrew Bernard. I'd very much like to speak with you about your paper supply needs. That wasn't even my worst sales call.", "order": 45013, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 4, "scene": 43, "line": "[knocks] Hi. What was that display?", "order": 45014, "speaker": "Walter"},
  {"season": 8, "episode": 4, "scene": 43, "line": "I don't know, I just thought if I could throw this great garden party and show you how respected I am that you'd be proud of me.", "order": 45015, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 4, "scene": 43, "line": "Andrew...", "order": 45016, "speaker": "Walter"},
  {"season": 8, "episode": 4, "scene": 43, "line": "I know, I know that you're proud of me.", "order": 45017, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 4, "scene": 43, "line": "I'm not going to tell you how impressed I am that you're a manager of some rinky dink branch of a paper company in Scranton, Pennsylvania. How long are you going to go on needing my approval? You're a grown man, don't act like a little boy who needs...", "order": 45018, "speaker": "Walter"},
  {"season": 8, "episode": 4, "scene": 44, "line": "I think if I had parents like that I'd be trying to convince everyone all the time how great I was, too.", "order": 45019, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 4, "scene": 44, "line": "Guess we found Andy's rosebud.", "order": 45020, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 4, "scene": 44, "line": "Rosebud?", "order": 45021, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 4, "scene": 44, "line": "It's a reference to Citizen Kane. Something that explains why a person became the way they are.", "order": 45022, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 4, "scene": 44, "line": "I know Citizen Kane. Rosebud didn't explain why he was how he was, it just represented what was important to him as a child, that he missed.", "order": 45023, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 4, "scene": 44, "line": "Different school of thought. Let's just agree to disagree.", "order": 45024, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 4, "scene": 44, "line": "No. You're wrong.", "order": 45025, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 4, "scene": 45, "line": "Are you sure?", "order": 45026, "speaker": "Robert California"},
  {"season": 8, "episode": 4, "scene": 45, "line": "Yeah! I'm too hot anyway.", "order": 45027, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 4, "scene": 45, "line": "My body has somehow become acclimated to southern Italy. Isn't that strange? I've never been there! Oh, ah, yes, that's nice. Thank you.", "order": 45028, "speaker": "Robert California"},
  {"season": 8, "episode": 4, "scene": 46, "line": "It was a nice party, the setting was a little strange. Uh, the food...", "order": 45029, "speaker": "Walter"},
  {"season": 8, "episode": 4, "scene": 46, "line": "Sandwiches were dry.", "order": 45030, "speaker": "Ellen"},
  {"season": 8, "episode": 4, "scene": 46, "line": "Bye Tuna, bye Pam!", "order": 45031, "speaker": "Walter Jr"},
  {"season": 8, "episode": 4, "scene": 47, "line": "Hey everybody, I'm gonna leave and I just wanted to say I'm really sorry.", "order": 45032, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 4, "scene": 47, "line": "Cheeseburger or hamburger.", "order": 45033, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 4, "scene": 47, "line": "Um, cheeseburger.", "order": 45034, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 4, "scene": 47, "line": "Narddog. [throws Andy a can]", "order": 45035, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 4, "scene": 47, "line": "Thanks, Oscar.", "order": 45036, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 4, "scene": 48, "line": "What are they doing?", "order": 45037, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 4, "scene": 48, "line": "Closing ceremonies.", "order": 45038, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 4, "scene": 48, "line": "Nice touch.", "order": 45039, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 4, "scene": 49, "line": "I think I left my wallet in your house.", "order": 45040, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 4, "scene": 49, "line": "Who cares.", "order": 45041, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 4, "scene": 49, "line": "Right here.", "order": 45042, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 4, "scene": 49, "line": "MR. JAMES HALPERT!", "order": 45043, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 4, "scene": 49, "line": "Keys, keys...", "order": 45044, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 4, "scene": 49, "line": "Stop forgetting things.", "order": 45045, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 4, "scene": 49, "line": "I didn't forget them, they're right here.", "order": 45046, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 4, "scene": 49, "line": "MR. JAMES HALPERT!", "order": 45047, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 4, "scene": 49, "line": "I'm so sorry, I think I forgot that thing...", "order": 45048, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 4, "scene": 49, "line": "What? Idiot.", "order": 45049, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 4, "scene": 49, "line": "Whoo.", "order": 45050, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 4, "scene": 49, "line": "MIS... MI... What are you doing?", "order": 45051, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 4, "scene": 49, "line": "Hey, I have a question. Who do you think is really the best salesman in this office?", "order": 45052, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 4, "scene": 49, "line": "That's a stupid question, obviously mISTER JAMES HALPERT!", "order": 45053, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 4, "scene": 50, "line": "I cannot believe how condescending Andy's being.", "order": 45054, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 4, "scene": 50, "line": "He gets condescending when he's nervous. Or when he's excited or sleepy. Not an easy person to be friends with.", "order": 45055, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 4, "scene": 50, "line": "Well I just hate being treated like some bum with no class. I'm a lady. I'm a mother. I'm a former boxer!", "order": 45056, "speaker": "Meredith Palmer"},
  {"season": 8, "episode": 4, "scene": 50, "line": "Hey guys, getting psyched for the party? Ryan? Can I count on you to wear an understated satirical outfit?", "order": 45057, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 4, "scene": 50, "line": "Can't wait. A folk colonialist gathering when unemployment's at 9%. I wouldn't miss it for the world.", "order": 45058, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 4, "scene": 50, "line": "Awesome.", "order": 45059, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 4, "scene": 51, "line": "Erin....the receptionist!", "order": 45060, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 4, "scene": 51, "line": "Oh, oh, oh, Kevin. I am reminded of a lame but useful saying I learned as a kid. What is the difference between Hors d'oeuvres and the animals on Noah's ark?", "order": 45061, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 4, "scene": 51, "line": "What?", "order": 45062, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 4, "scene": 51, "line": "With Hors d'oeuvres we only take one at a time.", "order": 45063, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 4, "scene": 51, "line": "Oh, OK. What's the difference between a vacuum cleaner and a blonde?", "order": 45064, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 4, "scene": 52, "line": "Chapter seven: The host should always present something spectacular to draw the eye of his guests.", "order": 45065, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 4, "scene": 52, "line": "When all this is over, I'd like to actually go to one of Trickington's parties, they sound like a blast.", "order": 45066, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 4, "scene": 53, "line": "Ladies and Gentlemen! I present to you...the Alamo! [reveals lump of ice]", "order": 45067, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 4, "scene": 54, "line": "Little Cici asleep?", "order": 45068, "speaker": "Angela Martin"},
  {"season": 8, "episode": 4, "scene": 54, "line": "Yeah, she just went down.", "order": 45069, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 4, "scene": 54, "line": "Such a precious little angel.", "order": 45070, "speaker": "Angela Martin"},
  {"season": 8, "episode": 4, "scene": 54, "line": "Aww.", "order": 45071, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 4, "scene": 54, "line": "Cecilia....and Phillip.", "order": 45072, "speaker": "Angela Martin"},
  {"season": 8, "episode": 4, "scene": 54, "line": "Yes, after my grandfather.", "order": 45073, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 4, "scene": 54, "line": "Phillip is wasted on you. Jim is never gonna take the time to call him Phillip! He doesn't even take the time to get a haircut. And Phil! Ugh! It's just something you do to a hole!", "order": 45074, "speaker": "Angela Martin"},
  {"season": 8, "episode": 4, "scene": 54, "line": "Well I'm having my baby first, so that's that.", "order": 45075, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 4, "scene": 54, "line": "You know what? It could go either way. My doctor said my hips are so dainty that I'll most definitely have a C-section, so I could go early.", "order": 45076, "speaker": "Angela Martin"},
  {"season": 8, "episode": 4, "scene": 54, "line": "You think your doctor's going to give you a C-section at six months?", "order": 45077, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 4, "scene": 54, "line": "Yeah! If I request it he will. And that is that.", "order": 45078, "speaker": "Angela Martin"},
  {"season": 8, "episode": 4, "scene": 55, "line": "My parents are used to a certain type of class. The people that I work with are a different type of class. One is not better than the other, it's just that maybe one's a little higher than the other?", "order": 45079, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 5, "scene": 1, "line": "[looking at Angela's costume] Approved!", "order": 45080, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 5, "scene": 2, "line": "[claps] Chef from South Park, it's genius!", "order": 45081, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 5, "scene": 2, "line": "Just some chef.", "order": 45082, "speaker": "Stanley Hudson"},
  {"season": 8, "episode": 5, "scene": 3, "line": "I've decided to pre-screen all the Halloween costumes this year. I have three simple rules - don't be offensive, don't be cliche, and don't take the first two rules too seriously.", "order": 45083, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 5, "scene": 4, "line": "The gorilla from Rise of the Planet of the Apes? Huh? The one who sacrifices his life.", "order": 45084, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 5, "scene": 4, "line": "Whoa! Aw, spoiler alert.", "order": 45085, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 5, "scene": 4, "line": "It's been out for ages, man.", "order": 45086, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 5, "scene": 4, "line": "Costume vetoed.", "order": 45087, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 5, "scene": 5, "line": "Ah, it's, uh... somebody's already called that.", "order": 45088, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 5, "scene": 5, "line": "Who?", "order": 45089, "speaker": "Phyllis Vance"},
  {"season": 8, "episode": 5, "scene": 5, "line": "Kevin has a gorilla suit you could borrow.", "order": 45090, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 5, "scene": 6, "line": "This is ridiculous! Why can't there just be two Kate Middleton's?", "order": 45091, "speaker": "Kelly Kapoor"},
  {"season": 8, "episode": 5, "scene": 6, "line": "Guys, I know, I mean, I wish there could be, too. It's like, I can't choose. They're both amazing. It's just...", "order": 45092, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 5, "scene": 6, "line": "Look, I stayed up all night and I watched that gd wedding, and then I came to work and I made everyone else watch it all day. Meredith wasn't even here.", "order": 45093, "speaker": "Kelly Kapoor"},
  {"season": 8, "episode": 5, "scene": 6, "line": "Because I was there. [shows Kelly her cell phone video] Your the people's princess! Diana was nothing!", "order": 45094, "speaker": "Meredith Palmer"},
  {"season": 8, "episode": 5, "scene": 6, "line": "I thought you were at your sister's funeral.", "order": 45095, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 5, "scene": 6, "line": "What I said was, 'My sister's funeral is this weekend.' Didn't say I'd be there.", "order": 45096, "speaker": "Meredith Palmer"},
  {"season": 8, "episode": 5, "scene": 7, "line": "Why is it such a shock that I follow the royal story? Warms my heart, thinking about them two kids, doing it.", "order": 45097, "speaker": "Meredith Palmer"},
  {"season": 8, "episode": 5, "scene": 8, "line": "Um, can I, maybe, squeeze through?", "order": 45098, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 5, "scene": 8, "line": "Here you go.", "order": 45099, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 5, "scene": 8, "line": "There you go. Thank you.", "order": 45100, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 5, "scene": 9, "line": "When they talk about all the nice things about dating a coworker, they don't mention one of the best parts. After you're done dating, you still get to work together, every single day.", "order": 45101, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 5, "scene": 10, "line": "Jim, put it on.", "order": 45102, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 5, "scene": 10, "line": "Put it on, man.", "order": 45103, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 5, "scene": 10, "line": "I don't know if I can.", "order": 45104, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 5, "scene": 10, "line": "Come on, the Three Amigos.", "order": 45105, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 5, "scene": 10, "line": "Three Kings.", "order": 45106, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 5, "scene": 10, "line": "Alright.", "order": 45107, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 5, "scene": 11, "line": "I know, I know, I know. But Darryl and Kevin needed a third. They bought me this jersey. I said no. Kevin started crying. So, I am Chris Bosh.", "order": 45108, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 5, "scene": 12, "line": "[to Kelly] If you get into season 1, you can really...", "order": 45109, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 5, "scene": 12, "line": "[Dwight walks in] Oh my god, what the hell is wrong with you?", "order": 45110, "speaker": "Kelly Kapoor"},
  {"season": 8, "episode": 5, "scene": 12, "line": "It's called a costume.", "order": 45111, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 5, "scene": 12, "line": "What are you, some kind of Jamaican zombie woman?", "order": 45112, "speaker": "Kelly Kapoor"},
  {"season": 8, "episode": 5, "scene": 12, "line": "Ryan, will you please tell her who I am?", "order": 45113, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 5, "scene": 12, "line": "Whoopi Goldberg.", "order": 45114, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 5, "scene": 12, "line": "Has no one here heard of Kerrigan, from Starcraft? Queen of blades? It's all Toby's fault.", "order": 45115, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 5, "scene": 13, "line": "Every Halloween I tell him the same thing - You can't bring weapons into the office, and every year he says the same thing - As soon as I get my weapons back I'm gonna kill you. But there I am at Thanksgiving, alive, you know. I'm a lucky turkey.", "order": 45116, "speaker": "Toby Flenderson"},
  {"season": 8, "episode": 5, "scene": 14, "line": "Everybody looking good, this is, this is the best we can do? I'm not judging, I, I think you guys look great, I just... wanna make sure this is the best we can do?", "order": 45117, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 5, "scene": 15, "line": "I just got a text from Broccoli Rob - 'Boo!' Scared me. And then I got this text from Robert California - 'Looking forward to Halloween party. Expectations are high.' Scared the s**t out of me.", "order": 45118, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 5, "scene": 16, "line": "G'day, Pameroo. Could you shoot this off for me?", "order": 45119, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 5, "scene": 16, "line": "Yeah.", "order": 45120, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 5, "scene": 16, "line": "Australian accent...", "order": 45121, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 5, "scene": 17, "line": "[faxing, Erin watching over her shoulder] What are you doing?", "order": 45122, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 5, "scene": 17, "line": "Oh, I just wanted to see how you do it, if you're doing something I don't.", "order": 45123, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 5, "scene": 17, "line": "Oh. [sends fax, Erin nods] Are you...", "order": 45124, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 5, "scene": 17, "line": "That Andy, so hot and cold. One day he's like, fax these documents, please. The next he's like, Pam, you fax them, who cares what Erin's feeling, right?", "order": 45125, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 5, "scene": 17, "line": "Oh, Erin...", "order": 45126, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 5, "scene": 17, "line": "Pam, how would you rate me as a receptionist on a scale of 1 to 3?", "order": 45127, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 5, "scene": 17, "line": "Um, 2?", "order": 45128, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 5, "scene": 17, "line": "That's like, the second to last thing I wanted to hear.", "order": 45129, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 5, "scene": 17, "line": "No, I mean, you're doing great, and Andy put you in charge of the whole party, right?", "order": 45130, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 5, "scene": 17, "line": "Yeah...", "order": 45131, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 5, "scene": 17, "line": "Yeah.", "order": 45132, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 5, "scene": 17, "line": "Send completed. You are the best in the biz, I can't deny.", "order": 45133, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 5, "scene": 18, "line": "Bob and I are doing this Scranton Haunted Walking Tour.", "order": 45134, "speaker": "Phyllis Vance"},
  {"season": 8, "episode": 5, "scene": 18, "line": "I always wondered what kind of people went on that thing.", "order": 45135, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 5, "scene": 18, "line": "Oh, hey, if you go by the Banshee Pub, tell the Man In Black I say hello.", "order": 45136, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 5, "scene": 18, "line": "[sigh] What happened, Pam?", "order": 45137, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 5, "scene": 18, "line": "Okay. When I was 22, I worked there and everybody said the place was haunted. I didn't believe it until one day before we opened, I look up into the mirror, you know, behind the bar, and I see this old man dressed all in black, but when I turn around, there's no one there. So, I tell the cook my story...", "order": 45138, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 5, "scene": 18, "line": "Wait, they have food there?", "order": 45139, "speaker": "Phyllis Vance"},
  {"season": 8, "episode": 5, "scene": 18, "line": "...and he said, That's what everyone sees, that's the Man In Black.", "order": 45140, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 5, "scene": 19, "line": "No! My wife does not believe in ghosts.", "order": 45141, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 5, "scene": 20, "line": "Hey.", "order": 45142, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 5, "scene": 20, "line": "Hey!", "order": 45143, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 5, "scene": 20, "line": "Oh, this Man In Black thing, what do you think that was about?", "order": 45144, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 5, "scene": 20, "line": "What do you mean?", "order": 45145, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 5, "scene": 20, "line": "Was it, like, trickery in the lights, or maybe you were so primed to see it, then there it was?", "order": 45146, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 5, "scene": 20, "line": "I saw a ghost.", "order": 45147, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 5, "scene": 20, "line": "Mmhmm. No, but what I'm saying is, like, do you ever wonder what it was?", "order": 45148, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 5, "scene": 20, "line": "It was a ghost, I told you this on, like, our first date.", "order": 45149, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 5, "scene": 20, "line": "Yeah, I had just told you about the day that I met the Blue Angels. I figured you had to top it.", "order": 45150, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 5, "scene": 20, "line": "I don't know what to tell you, Jim, but I saw a ghost.", "order": 45151, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 5, "scene": 21, "line": "Hey! Uh-oh, looks like we're under a Jack attack!", "order": 45152, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 5, "scene": 21, "line": "Yes, Andrew. And you, on this day of fantasy, are a laborer.", "order": 45153, "speaker": "Robert California"},
  {"season": 8, "episode": 5, "scene": 21, "line": "Yes.", "order": 45154, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 5, "scene": 21, "line": "Everyone, this Bert, my son. Bert, this is... a paper company.", "order": 45155, "speaker": "Robert California"},
  {"season": 8, "episode": 5, "scene": 21, "line": "Hello. Can I use a computer? I need to check a hurricane.", "order": 45156, "speaker": "Bert"},
  {"season": 8, "episode": 5, "scene": 21, "line": "Here, use this one. [walking to Jim's desk]", "order": 45157, "speaker": "Robert California"},
  {"season": 8, "episode": 5, "scene": 21, "line": "Oh, look, Pin The Wart On The Wench. How did you know I was bringing my son?", "order": 45158, "speaker": "Robert California"},
  {"season": 8, "episode": 5, "scene": 21, "line": "Oh, I didn't. It was for us, but he can play. Bertie-boy, would you like to play this game?", "order": 45159, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 5, "scene": 21, "line": "That stuff's for babies.", "order": 45160, "speaker": "Bert"},
  {"season": 8, "episode": 5, "scene": 21, "line": "Well, perhaps this party will awaken the baby in all of us.", "order": 45161, "speaker": "Robert California"},
  {"season": 8, "episode": 5, "scene": 21, "line": "Wow, who shot our grown-up party with a kiddie raygun? We're still getting it set up, it's gonna be really cool.", "order": 45162, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 5, "scene": 22, "line": "[laughing] Extraordinary. Did you plan this?", "order": 45163, "speaker": "Robert California"},
  {"season": 8, "episode": 5, "scene": 22, "line": "Well, Toby and I did, yeah.", "order": 45164, "speaker": "Kelly Kapoor"},
  {"season": 8, "episode": 5, "scene": 22, "line": "And I overheard, and thought, hey, that'd be fun, don't mind if I do.", "order": 45165, "speaker": "Gabe Lewis"},
  {"season": 8, "episode": 5, "scene": 22, "line": "If you turn out the lights we'll do a little dance.", "order": 45166, "speaker": "Toby Flenderson"},
  {"season": 8, "episode": 5, "scene": 22, "line": "1, 2, 3!", "order": 45167, "speaker": "Kelly Kapoor"},
  {"season": 8, "episode": 5, "scene": 22, "line": "[singing and dancing] Dem bones, dem bones, dem tired bones, now we're the skeleton crew.", "order": 45168, "speaker": "Gabe Lewis & Kelly Kapoor & Toby Flenderson"},
  {"season": 8, "episode": 5, "scene": 22, "line": "[laughing and clapping] Delightful.", "order": 45169, "speaker": "Robert California"},
  {"season": 8, "episode": 5, "scene": 22, "line": "Thank you. [Gabe mumbles]", "order": 45170, "speaker": "Kelly Kapoor"},
  {"season": 8, "episode": 5, "scene": 22, "line": "Now then, how are we today?", "order": 45171, "speaker": "Robert California"},
  {"season": 8, "episode": 5, "scene": 22, "line": "Fine.", "order": 45172, "speaker": "Kelly Kapoor"},
  {"season": 8, "episode": 5, "scene": 22, "line": "Great.", "order": 45173, "speaker": "Toby Flenderson"},
  {"season": 8, "episode": 5, "scene": 22, "line": "Just fine, Kelly? Everything alright?", "order": 45174, "speaker": "Robert California"},
  {"season": 8, "episode": 5, "scene": 22, "line": "Mmhmm.", "order": 45175, "speaker": "Kelly Kapoor"},
  {"season": 8, "episode": 5, "scene": 22, "line": "You feeling fulfilled in your life?", "order": 45176, "speaker": "Robert California"},
  {"season": 8, "episode": 5, "scene": 22, "line": "I guess.", "order": 45177, "speaker": "Kelly Kapoor"},
  {"season": 8, "episode": 5, "scene": 22, "line": "You guess? So, there is something you want that you do not have.", "order": 45178, "speaker": "Robert California"},
  {"season": 8, "episode": 5, "scene": 22, "line": "I try not to think about it.", "order": 45179, "speaker": "Kelly Kapoor"},
  {"season": 8, "episode": 5, "scene": 22, "line": "Because it's too terrifying to imagine. Now we're cooking. What is it, Kelly? What is this great fear of yours?", "order": 45180, "speaker": "Robert California"},
  {"season": 8, "episode": 5, "scene": 22, "line": "Never marrying.", "order": 45181, "speaker": "Kelly Kapoor"},
  {"season": 8, "episode": 5, "scene": 22, "line": "Yes. Dying alone, that is very scary. And how are you, Toby?", "order": 45182, "speaker": "Robert California"},
  {"season": 8, "episode": 5, "scene": 22, "line": "So great.", "order": 45183, "speaker": "Toby Flenderson"},
  {"season": 8, "episode": 5, "scene": 23, "line": "Oh, I put those up.", "order": 45184, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 5, "scene": 23, "line": "I know. I'm taking them down.", "order": 45185, "speaker": "Angela Martin"},
  {"season": 8, "episode": 5, "scene": 23, "line": "I almost wonder if putting nothing on this wall is more Halloween-y.", "order": 45186, "speaker": "Phyllis Vance"},
  {"season": 8, "episode": 5, "scene": 23, "line": "I don't know about this, guys, Andy put me in charge of the Halloween party, so...", "order": 45187, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 5, "scene": 23, "line": "Well, Andy sent us in here, so which is it?", "order": 45188, "speaker": "Angela Martin"},
  {"season": 8, "episode": 5, "scene": 23, "line": "Oh.", "order": 45189, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 5, "scene": 23, "line": "So, can we speak our minds now, or are we still sparing feelings, because I hate all of this.", "order": 45190, "speaker": "Angela Martin"},
  {"season": 8, "episode": 5, "scene": 24, "line": "Very low pressure in the Sargasso Sea, warm air from South America, cold air from Greenland. All signs point to the perfect storm.", "order": 45191, "speaker": "Bert"},
  {"season": 8, "episode": 5, "scene": 24, "line": "Yeah, perfectly mediocre.", "order": 45192, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 5, "scene": 24, "line": "What are you, anyway?", "order": 45193, "speaker": "Bert"},
  {"season": 8, "episode": 5, "scene": 24, "line": "I'm a Jamaican zombie woman, leave me alone, ghoul.", "order": 45194, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 5, "scene": 24, "line": "If you had some really big wings with blades on the end, you'd kind of look like Kerrigan from Starcraft.", "order": 45195, "speaker": "Bert"},
  {"season": 8, "episode": 5, "scene": 24, "line": "Damnit. I AM Kerrigan from Starcraft! I've been censored.", "order": 45196, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 5, "scene": 24, "line": "If you're going to be a Zerg, at least be a Lurker, not some girl.", "order": 45197, "speaker": "Bert"},
  {"season": 8, "episode": 5, "scene": 24, "line": "Kerrigan is ruler of the Zerg swarm!", "order": 45198, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 5, "scene": 24, "line": "Yeah, she also has boobs.", "order": 45199, "speaker": "Bert"},
  {"season": 8, "episode": 5, "scene": 24, "line": "Yeah, but no nipples.", "order": 45200, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 5, "scene": 25, "line": "Hey.", "order": 45201, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 5, "scene": 25, "line": "Hey. What's up?", "order": 45202, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 5, "scene": 25, "line": "November's sure creeping up, ain't it? Can't stop that month!", "order": 45203, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 5, "scene": 25, "line": "Yeah...", "order": 45204, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 5, "scene": 25, "line": "Hey, what's the jive with Angela and Phyllis helping with the party, you know?", "order": 45205, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 5, "scene": 25, "line": "Um, I just thought you could use some help, you know, because Robert came in and thought the party seemed a little kiddie, and I guess I agreed, and maybe we could mix a little more 13 into the PG.", "order": 45206, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 5, "scene": 25, "line": "But that's it? There's nothing about me, or I?", "order": 45207, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 5, "scene": 25, "line": "Can we talk about it at the end of the day? I gotta call, gotta make a call.", "order": 45208, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 5, "scene": 25, "line": "Oh, yeah, sorry. Yes, we can.", "order": 45209, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 5, "scene": 25, "line": "[pretending to be on the phone] Yes. [laughs] I don't know. Mmhmm.", "order": 45210, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 5, "scene": 26, "line": "Gabe?", "order": 45211, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 5, "scene": 26, "line": "Sweetheart.", "order": 45212, "speaker": "Gabe Lewis"},
  {"season": 8, "episode": 5, "scene": 26, "line": "I'm throwing the Halloween Party and I just want to amp it up a little. I think it could use some extra pizazz.", "order": 45213, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 5, "scene": 26, "line": "Ok where does Gabe factor in?", "order": 45214, "speaker": "Gabe Lewis"},
  {"season": 8, "episode": 5, "scene": 26, "line": "Remember that Halloween party you took me to once? The one where I started crying as soon as I walked in and I didn't stop crying?", "order": 45215, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 5, "scene": 26, "line": "Yes. Lars and Decocco's", "order": 45216, "speaker": "Gabe Lewis"},
  {"season": 8, "episode": 5, "scene": 26, "line": "Ok. Let's say that I wanted this party to be a tiny, tiny bit like that one. Just' more adult' more' scary and sexy", "order": 45217, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 5, "scene": 26, "line": "I will make this sexier than you could ever imagine.", "order": 45218, "speaker": "Gabe Lewis"},
  {"season": 8, "episode": 5, "scene": 26, "line": "No ' just scary. If we wanted ideas for scary stuff.", "order": 45219, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 5, "scene": 26, "line": "[laughs, eventually Erin joins him laughing] Oh that would be scary!", "order": 45220, "speaker": "Gabe Lewis"},
  {"season": 8, "episode": 5, "scene": 26, "line": "What are you thinking?", "order": 45221, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 5, "scene": 26, "line": "Ok let me go get it.", "order": 45222, "speaker": "Gabe Lewis"},
  {"season": 8, "episode": 5, "scene": 27, "line": "[answers phone] Pam Halpert.", "order": 45223, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 5, "scene": 27, "line": "Hey it's Jim Halpert. I was wondering if you wanted to see a movie tonight. Because I've read a lot about this really great documentary.", "order": 45224, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 5, "scene": 27, "line": "Is it called Ghostbusters?", "order": 45225, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 5, "scene": 27, "line": "[laughs] It's called Ghostbusters.", "order": 45226, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 5, "scene": 27, "line": "It didn't look like that.", "order": 45227, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 5, "scene": 27, "line": "It didn't have a buster sign around him? Why don't you draw him? Why don't we see this whole thing.", "order": 45228, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 5, "scene": 27, "line": "Ok fine I'll draw him. [hangs up phone and draws]", "order": 45229, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 5, "scene": 27, "line": "I ain't fraid of no ghost. [Pam holds up drawing of a hand with the middle finger raised] Whoa!", "order": 45230, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 5, "scene": 27, "line": "Mmhmm. Dwight are you eating a stick?", "order": 45231, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 5, "scene": 27, "line": "It's a root you idiot.", "order": 45232, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 5, "scene": 27, "line": "Everyone hates you.", "order": 45233, "speaker": "Bert"},
  {"season": 8, "episode": 5, "scene": 27, "line": "That's really rude. I don't tell you hurricanes suck even though it's true.", "order": 45234, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 5, "scene": 27, "line": "What do you like? Tornadoes?", "order": 45235, "speaker": "Bert"},
  {"season": 8, "episode": 5, "scene": 27, "line": "Try influenza.", "order": 45236, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 5, "scene": 27, "line": "Oh yeah? What's the vaccine you can take to avoid a hurricane?", "order": 45237, "speaker": "Bert"},
  {"season": 8, "episode": 5, "scene": 27, "line": "Open up a newspaper. Oh look a hurricane's coming. I suppose you're going to tell me the scariest animal is a shark?", "order": 45238, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 5, "scene": 27, "line": "Try a box jelly fish.", "order": 45239, "speaker": "Bert"},
  {"season": 8, "episode": 5, "scene": 28, "line": "You know that's'", "order": 45240, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 5, "scene": 28, "line": "What are we talking about?", "order": 45241, "speaker": "Robert California"},
  {"season": 8, "episode": 5, "scene": 28, "line": "I was talking about my wife and how she believes in ghosts. And then we had a little debate and Meredith said she believes in them too.", "order": 45242, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 5, "scene": 28, "line": "[to Kevin] You seem unimpressed. Ghosts don't scare you?", "order": 45243, "speaker": "Robert California"},
  {"season": 8, "episode": 5, "scene": 28, "line": "I'm only scared of real things like serial killers and kidnappers. Not things that don't exist like ghosts or mummies.", "order": 45244, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 5, "scene": 28, "line": "Mummies are real. There are mummies at museums.", "order": 45245, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 5, "scene": 28, "line": "Yeah [nervously]'prank", "order": 45246, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 5, "scene": 28, "line": "It's true. They've been preserved for thousands of years. They're all over.", "order": 45247, "speaker": "Robert California"},
  {"season": 8, "episode": 5, "scene": 29, "line": "[screaming] Why on Earth would a museum put a mummy in it?!?", "order": 45248, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 5, "scene": 30, "line": "Witch's brew?", "order": 45249, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 5, "scene": 31, "line": "I grew this party up real fast. Get out of here little kid party. Nobody loves you. And clean up your room! Grownups are going to use it later! Oooh!", "order": 45250, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 5, "scene": 32, "line": "Party looks fun doesn't it? Everybody seems to be in there having a great time. So maybe now would be a great time for me to pop back on the computer.", "order": 45251, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 5, "scene": 32, "line": "I'm using it. I'm about to play Starcraft with him [points to Dwight].", "order": 45252, "speaker": "Bert"},
  {"season": 8, "episode": 5, "scene": 32, "line": "[to Dwight] Are you serious?", "order": 45253, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 5, "scene": 32, "line": "Yeah I'm serious.", "order": 45254, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 5, "scene": 32, "line": "That's funny. Ok.", "order": 45255, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 5, "scene": 32, "line": "Loser.", "order": 45256, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 5, "scene": 32, "line": "Yeah.", "order": 45257, "speaker": "Bert"},
  {"season": 8, "episode": 5, "scene": 33, "line": "Pam do you think anyone's going to notice I've worn this costume before? When I wasn't pregnant. You know I bet nobody would believe it still fits.", "order": 45258, "speaker": "Angela Martin"},
  {"season": 8, "episode": 5, "scene": 34, "line": "Hey guys. I'm an Oscar [gestures to face] liar [gestures to nametag saying 'Representative Weiner] weiner.", "order": 45259, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 5, "scene": 34, "line": "Oh my god!", "order": 45260, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 5, "scene": 35, "line": "This party's tight. The fog is cool.", "order": 45261, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 5, "scene": 35, "line": "Thanks. It's on medium.", "order": 45262, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 5, "scene": 35, "line": "Perfect.", "order": 45263, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 5, "scene": 35, "line": "It really looks great. You did a great job.", "order": 45264, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 5, "scene": 35, "line": "Oh, so we don't have to have that talk.", "order": 45265, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 5, "scene": 35, "line": "We should still have that talk. Maybe you can come by my office at like 4:45? [Erin nods] Cool.", "order": 45266, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 5, "scene": 36, "line": "[in ghostly voice] Oookayyy everybody. Be prepared to be scared. Ok. [hits play on DVD player, 'Do yes disturb meditations of horror' appears on screen, Gabe winks at Erin, on screen food deflates, a mouse crawls across a photograph of a woman, a person brushes very dirty teeth]", "order": 45267, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 5, "scene": 37, "line": "The cinema of the unsettling is a growing film movement. The most well known film in the genre is an hour long shot of a squirrel with diarrhea.", "order": 45268, "speaker": "Gabe Lewis"},
  {"season": 8, "episode": 5, "scene": 38, "line": "[cuts back to a Happy Birthday cake gushing blood when cut into, a doll melts, a woman eats food and puckers her face] Is that my grandmother?", "order": 45269, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 5, "scene": 38, "line": "What's the story?", "order": 45270, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 5, "scene": 38, "line": "There is no story.", "order": 45271, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 5, "scene": 38, "line": "Yeah it seems like there isn't a narrative. [on screen a man gets into a car] Maybe the filmmaker realized that even narrative is comforting.", "order": 45272, "speaker": "Gabe Lewis"},
  {"season": 8, "episode": 5, "scene": 38, "line": "What the hell is going on here?", "order": 45273, "speaker": "Stanley Hudson"},
  {"season": 8, "episode": 5, "scene": 38, "line": "I think we've seen enough. You can turn it off now. [everyone murmurs agreement] Yeah turn it off now.", "order": 45274, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 5, "scene": 38, "line": "How did you get in my car?", "order": 45275, "speaker": "Stanley Hudson"},
  {"season": 8, "episode": 5, "scene": 38, "line": "Where is this from? That is so upsetting!", "order": 45276, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 5, "scene": 38, "line": "That was awful. Robert I apologize.", "order": 45277, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 5, "scene": 38, "line": "I'm sorry. I got confused. I heard you wanted to make the party more adult. But I think I know what to do now [grabs box]. This game is called 'pecker Poker'. [fighting tears] It's the game of cards that gets you hard.", "order": 45278, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 5, "scene": 39, "line": "What we have here is a classic misunderstanding.", "order": 45279, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 5, "scene": 39, "line": "Why didn't you simply ask Andy to clarify? Asking is a very easy thing to do. You're obviously very close. [Andy and Erin look awkwardly at each other and Robert]. Oh I see. This no longer seems like my business [Robert sits].", "order": 45280, "speaker": "Robert California"},
  {"season": 8, "episode": 5, "scene": 39, "line": "All I know is you wanted to have a talk with me and I got nervous so'", "order": 45281, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 5, "scene": 39, "line": "You were going to talk at the end of the' I'm not here.", "order": 45282, "speaker": "Robert California"},
  {"season": 8, "episode": 5, "scene": 39, "line": "Did you think I was going to fire you? No I wasn't. [to Robert] I'm sorry this must be really uncomfortable for you.", "order": 45283, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 5, "scene": 39, "line": "I'm never uncomfortable.", "order": 45284, "speaker": "Robert California"},
  {"season": 8, "episode": 5, "scene": 39, "line": "Ok. Erin I think you know I've been dating someone.", "order": 45285, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 5, "scene": 39, "line": "Sure.", "order": 45286, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 5, "scene": 39, "line": "And it's getting a little more serious. She's never come by.", "order": 45287, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 5, "scene": 39, "line": "And she's never called here. Unless it's your mom.", "order": 45288, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 5, "scene": 39, "line": "No I didn't want her to call because I thought it would be weird. But now it's weird that she's not calling.", "order": 45289, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 5, "scene": 39, "line": "Two dates? Three dates?", "order": 45290, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 5, "scene": 39, "line": "Thirty-one.", "order": 45291, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 5, "scene": 39, "line": "Wow. I'm so happy for you guys. Um let me know when you get to forty. I'll see you guys.", "order": 45292, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 5, "scene": 39, "line": "I should go.", "order": 45293, "speaker": "Robert California"},
  {"season": 8, "episode": 5, "scene": 40, "line": "I just don't get it, Pam. I mean, you're a rational person.", "order": 45294, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 5, "scene": 40, "line": "[whispering] Thank you.", "order": 45295, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 5, "scene": 40, "line": "Jim doesn't let me wash his NFL jersey during the playoffs. How is this any less logical?", "order": 45296, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 5, "scene": 40, "line": "Careful, whoa. First of all, it's not like I think that's going to help the Eagles win.", "order": 45297, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 5, "scene": 40, "line": "Really.", "order": 45298, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 5, "scene": 40, "line": "No. That is just a bunch of people participating in a collective thing that maybe the Eagles will hear about and want to play better. It's not...", "order": 45299, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 5, "scene": 40, "line": "Exactly.", "order": 45300, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 5, "scene": 40, "line": "Thank you.", "order": 45301, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 5, "scene": 41, "line": "Go, get up there right now.", "order": 45302, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 5, "scene": 41, "line": "Got it.", "order": 45303, "speaker": "Bert"},
  {"season": 8, "episode": 5, "scene": 41, "line": "Dwight?", "order": 45304, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 5, "scene": 41, "line": "You wanna attack or let them come to us? Your call, B.", "order": 45305, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 5, "scene": 41, "line": "Unleash the hellstorm.", "order": 45306, "speaker": "Bert"},
  {"season": 8, "episode": 5, "scene": 41, "line": "Dwight.", "order": 45307, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 5, "scene": 41, "line": "[laughs] Nice.", "order": 45308, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 5, "scene": 41, "line": "Dwight!", "order": 45309, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 5, "scene": 41, "line": "Got 'em. Go!", "order": 45310, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 5, "scene": 42, "line": "Is she Asian?", "order": 45311, "speaker": "Phyllis Vance"},
  {"season": 8, "episode": 5, "scene": 42, "line": "I don't know. She's from somewhere, I bet. Maybe from the forest.", "order": 45312, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 5, "scene": 42, "line": "Forest? Did Andy say his girlfriend's from the forest?", "order": 45313, "speaker": "Phyllis Vance"},
  {"season": 8, "episode": 5, "scene": 42, "line": "I don't know, Phyllis. Maybe she's from the city.", "order": 45314, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 5, "scene": 42, "line": "Hmm.", "order": 45315, "speaker": "Phyllis Vance"},
  {"season": 8, "episode": 5, "scene": 43, "line": "Looks terribly real, doesn't it, Creed?", "order": 45316, "speaker": "Robert California"},
  {"season": 8, "episode": 5, "scene": 43, "line": "No...", "order": 45317, "speaker": "Creed Bratton"},
  {"season": 8, "episode": 5, "scene": 43, "line": "Are you scared of snakes?", "order": 45318, "speaker": "Robert California"},
  {"season": 8, "episode": 5, "scene": 43, "line": "You don't live as long as I have without a healthy fear of snakes, Bobby.", "order": 45319, "speaker": "Creed Bratton"},
  {"season": 8, "episode": 5, "scene": 44, "line": "[in the bathroom with Robert[ Yeah, I guess sometimes I have nightmares about being buried alive.", "order": 45320, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 5, "scene": 45, "line": "[talking to Robert] Honestly, Jim gives me the creeps.", "order": 45321, "speaker": "Meredith Palmer"},
  {"season": 8, "episode": 5, "scene": 46, "line": "[to camera] What am I up to?", "order": 45322, "speaker": "Robert California"},
  {"season": 8, "episode": 5, "scene": 47, "line": "Like, a few years down the road, Cece says, 'Mom, there's a ghost in my closet.' Now, you say one of two things - one, 'You're just having a bad dream,' or two, 'Let's go see what it was.'", "order": 45323, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 5, "scene": 47, "line": "I'm not gonna freak her out, Jim.", "order": 45324, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 5, "scene": 47, "line": "Ok.", "order": 45325, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 5, "scene": 47, "line": "I'm not gonna lie to her, either.", "order": 45326, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 5, "scene": 47, "line": "Oh, come on!", "order": 45327, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 5, "scene": 47, "line": "When I was a boy, there was an empty house just up the hill from my family's. It was rumored a man committed suicide there after being possessed by the devil. One day, a young woman, Lydia, moved into the house with her infant child. That very night, Lydia was awakened by a loud, heinous hissing sound. [hisses] She walked to the nursery, and there, in baby's crib, was a snake wrapped around baby's neck, squeezing tighter and tighter.", "order": 45328, "speaker": "Robert California"},
  {"season": 8, "episode": 5, "scene": 47, "line": "Oh my goodness.", "order": 45329, "speaker": "Creed Bratton"},
  {"season": 8, "episode": 5, "scene": 47, "line": "The crib was full of dirt. Baby struggled to free itself from underneath, reaching and clawing, gasping for air. Embalmed bodies rose from their sarcophagi, lurching toward baby, for they were mummies.", "order": 45330, "speaker": "Robert California"},
  {"season": 8, "episode": 5, "scene": 47, "line": "Nooo!", "order": 45331, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 5, "scene": 47, "line": "Amongst them was a man, tall, slim.", "order": 45332, "speaker": "Robert California"},
  {"season": 8, "episode": 5, "scene": 47, "line": "Jim. [rolls eyes]", "order": 45333, "speaker": "Meredith Palmer"},
  {"season": 8, "episode": 5, "scene": 47, "line": "Almost instinctively, she turned to her husband. 'Oh, wait,' she thought, 'I don't have a husband.' For Lydia and her husband had had an argument, one they couldn't get past. Each night, they slept one inch farther apart, until one night, Lydia left. It was about this time she lost herself in imaginary worlds. She had quit the book club, the choir, citing something about their high expectations. Her lips slowly grew together from disuse. Everytime she wanted to act and didn't, another part of her face hardened, until it was stone. And that fevered night, she rushed to the nursery, threw open the door, 'Baby, are you okay?' Baby sat up slowly, turned to mother and said, 'I'm fine, b!tch, I'm fine.'", "order": 45334, "speaker": "Robert California"},
  {"season": 8, "episode": 5, "scene": 47, "line": "[laughs]", "order": 45335, "speaker": "Bert"},
  {"season": 8, "episode": 5, "scene": 48, "line": "Fear plays an interesting role in our lives. How dare we let it motivate us. How dare we let it into our decision making, into our livelihoods, into our relationships. It's funny, isn't it, we take a day a year to dress up in costume and celebrate fear.", "order": 45336, "speaker": "Robert California"},
  {"season": 8, "episode": 5, "scene": 49, "line": "Toby?", "order": 45337, "speaker": "Bert"},
  {"season": 8, "episode": 5, "scene": 49, "line": "Oh, hey, Bert. Wanna see the dance? Dem bones, dem bones, dem dry...", "order": 45338, "speaker": "Toby Flenderson"},
  {"season": 8, "episode": 5, "scene": 49, "line": "You're fired.", "order": 45339, "speaker": "Bert"},
  {"season": 8, "episode": 5, "scene": 49, "line": "...bones. What?", "order": 45340, "speaker": "Toby Flenderson"},
  {"season": 8, "episode": 5, "scene": 49, "line": "You heard me. Pack your things.", "order": 45341, "speaker": "Bert"},
  {"season": 8, "episode": 5, "scene": 49, "line": "What... you can't... Gabe? Are you...", "order": 45342, "speaker": "Toby Flenderson"},
  {"season": 8, "episode": 5, "scene": 49, "line": "I'm the CEO's son. Pack your things. You're done.", "order": 45343, "speaker": "Bert"},
  {"season": 8, "episode": 5, "scene": 50, "line": "Oh, no.", "order": 45344, "speaker": "Angela Martin"},
  {"season": 8, "episode": 5, "scene": 50, "line": "Pink is the red-", "order": 45345, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 5, "scene": 50, "line": "What are we talking about?", "order": 45346, "speaker": "Robert California"},
  {"season": 8, "episode": 5, "scene": 50, "line": "I was talking about my wife, and how she believes in ghosts. And then we had a little debate. Meredith said she believes in them too.", "order": 45347, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 5, "scene": 50, "line": "Have you seen a ghost?", "order": 45348, "speaker": "Robert California"},
  {"season": 8, "episode": 5, "scene": 50, "line": "Seen and banged.", "order": 45349, "speaker": "Meredith Palmer"},
  {"season": 8, "episode": 5, "scene": 50, "line": "Hmph.", "order": 45350, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 5, "scene": 50, "line": "Ok, I met him in a bar, right? There's something weird about him. He doesn't smell right, the clothes are all tattered and dirty and from another age. Anyways, we end up back and my place and we go at it all night-", "order": 45351, "speaker": "Meredith Palmer"},
  {"season": 8, "episode": 5, "scene": 50, "line": "Meredith, don't.", "order": 45352, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 5, "scene": 50, "line": "No, no. I'm very comfortable with all things sexual. Continue.", "order": 45353, "speaker": "Robert California"},
  {"season": 8, "episode": 5, "scene": 50, "line": "I wake up the next morning, all my stuff's gone. Whole house turned over. No trace. You tell me what happened.", "order": 45354, "speaker": "Meredith Palmer"},
  {"season": 8, "episode": 5, "scene": 50, "line": "Have you considered the possibility that you slept with a drifter?", "order": 45355, "speaker": "Robert California"},
  {"season": 8, "episode": 5, "scene": 50, "line": "He didn't smell like a drifter.", "order": 45356, "speaker": "Meredith Palmer"},
  {"season": 8, "episode": 5, "scene": 51, "line": "What are you anyway?", "order": 45357, "speaker": "Bert"},
  {"season": 8, "episode": 5, "scene": 51, "line": "I'm a Jamaican zombie woman. Leave me alone, ghoul.", "order": 45358, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 5, "scene": 51, "line": "I'm a zombie from Walking Dead. It's a show.", "order": 45359, "speaker": "Bert"},
  {"season": 8, "episode": 5, "scene": 51, "line": "Uh, I know what it is. Ok? I have like a thousand people over every week to watch it. Ok? We all kinda hate it though.", "order": 45360, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 5, "scene": 51, "line": "Who's your favorite character?", "order": 45361, "speaker": "Bert"},
  {"season": 8, "episode": 5, "scene": 51, "line": "The city of Atlanta.", "order": 45362, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 5, "scene": 52, "line": "If ghosts are real, how come everyone knows what they look like? It's not like a bunch of people got together and agreed on a lie.", "order": 45363, "speaker": "Stanley Hudson"},
  {"season": 8, "episode": 5, "scene": 52, "line": "Thank you.", "order": 45364, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 5, "scene": 52, "line": "Interesting. Every culture holds this true. Clearly, there's some sort of real phenomenon out there...", "order": 45365, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 5, "scene": 52, "line": "Oscar, how are you on that side?", "order": 45366, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 5, "scene": 52, "line": "This isn't about sides. This is about me seeing a blueish gray old man in the mirror and then he vanishes.", "order": 45367, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 5, "scene": 52, "line": "Pam, this is important. Was he me? Am I him?", "order": 45368, "speaker": "Creed Bratton"},
  {"season": 8, "episode": 5, "scene": 52, "line": "No, Creed.", "order": 45369, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 5, "scene": 52, "line": "But that would make more sense. [Pam sighs]", "order": 45370, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 6, "scene": 1, "line": "[Flickering Lights] Hey everybody it's closing time. You don't got to go home but you can't stay here. [Plays radio and the song is Closing Time by Semisonic]", "order": 45371, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 6, "scene": 1, "line": "[Laughs and shrieks]", "order": 45372, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 6, "scene": 1, "line": "Closing time.", "order": 45373, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 6, "scene": 2, "line": "Every office needs an end of the day tradition. Something to tell you the day is over. Otherwise, you go home and the night just feels like more day. It's weird.", "order": 45374, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 6, "scene": 3, "line": "Closing time", "order": 45375, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 6, "scene": 3, "line": "[On phone] ...W R K.", "order": 45376, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 6, "scene": 3, "line": "One last call for alcohol so finish your whiskey and beer.", "order": 45377, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 6, "scene": 3, "line": "Uh no it's W R K, as in kitten. Oh my boss is singing Closing Time, maybe that's what you are hearing.", "order": 45378, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 6, "scene": 3, "line": "Come on pam!", "order": 45379, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 6, "scene": 3, "line": "[Singing, Pam mumbling lyrics] Closing time, time for you to go home to the places you will be from.", "order": 45380, "speaker": "Andy Bernard & Pam Halpert"},
  {"season": 8, "episode": 6, "scene": 4, "line": "Let's see. Andy has been manager for a hundred and five days. Which means I've heard 'Closing Time' a hundred and five times. [nods and shakes head] Still don't know the words. Tah wa Ta way hm hm home and home and home.", "order": 45381, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 6, "scene": 5, "line": "I know who I want to take me home. I know who I want to take me home. [Pulls towel through legs] I know who I want to take me home. [Spins Meredith in chair] Take me hooo'hooome! You know what fine! I try to start fun traditions for you guys, but if you don't want to sing' no traditions!", "order": 45382, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 6, "scene": 5, "line": "[singing] Closing time every new beginning'", "order": 45383, "speaker": "Stanley Hudson"},
  {"season": 8, "episode": 6, "scene": 6, "line": "I've never heard that song before. And once I heard it, I did not care for it, but that song means it's time to go home. Now'it's my favorite song.", "order": 45384, "speaker": "Stanley Hudson"},
  {"season": 8, "episode": 6, "scene": 7, "line": "[singing] Every new beginning comes from some other beginnings end.", "order": 45385, "speaker": "Andy Bernard & Stanley Hudson"},
  {"season": 8, "episode": 6, "scene": 7, "line": "Goodnight.", "order": 45386, "speaker": "Stanley Hudson"},
  {"season": 8, "episode": 6, "scene": 8, "line": "Whose your favorite iron chef?", "order": 45387, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 6, "scene": 8, "line": "[speaking at the same time as andy] This is atrocious.", "order": 45388, "speaker": "Robert California"},
  {"season": 8, "episode": 6, "scene": 8, "line": "You go first.", "order": 45389, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 6, "scene": 8, "line": "The ticketing software paints a picture of a sloppy, careless, error prone office.", "order": 45390, "speaker": "Robert California"},
  {"season": 8, "episode": 6, "scene": 8, "line": "Well the monitoring software is a double edged sword. Sometimes'", "order": 45391, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 6, "scene": 8, "line": "[runs in and interrupts] Sorry, go ahead.", "order": 45392, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 6, "scene": 8, "line": "[to dwight] Did you need something from us?", "order": 45393, "speaker": "Robert California"},
  {"season": 8, "episode": 6, "scene": 8, "line": "Wha'Yes. Your attention. Uh because' No that is all. [walks out]", "order": 45394, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 6, "scene": 9, "line": "Last night I dreamed that the number two was the most valued number in the world. The vice president had all the power. Athletes fought for silver medals. Women were considered the best gender. And stadiums of fans shouted 'We're number two!'. As with all my dreams, I'm guessing it was about my fear of immigrants.", "order": 45395, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 6, "scene": 10, "line": "Last week an accounting mistake resulted in a client getting their order for free.", "order": 45396, "speaker": "Robert California"},
  {"season": 8, "episode": 6, "scene": 10, "line": "Umpf'that's not good. Chalk that one up to Tweedle Dee and Tweedle Dumb out there.", "order": 45397, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 6, "scene": 10, "line": "Who are they?", "order": 45398, "speaker": "Robert California"},
  {"season": 8, "episode": 6, "scene": 10, "line": "They're both Kevin. Oscar is the Sex and the City gang and Angela, if you can picture'", "order": 45399, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 6, "scene": 10, "line": "Andrew sometimes I feel like you don't know me at all.", "order": 45400, "speaker": "Robert California"},
  {"season": 8, "episode": 6, "scene": 10, "line": "I would agree with that.", "order": 45401, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 6, "scene": 10, "line": "Simply'end the mistakes", "order": 45402, "speaker": "Robert California"},
  {"season": 8, "episode": 6, "scene": 10, "line": "End the mistakes, easy-", "order": 45403, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 6, "scene": 10, "line": "When I come back next week and this report shows me no mistakes, we can talk about names, all day. Our favorite names, silly made up names, normal names said in a silly voice. Wouldn't that be nice?", "order": 45404, "speaker": "Robert California"},
  {"season": 8, "episode": 6, "scene": 10, "line": "I would like that.", "order": 45405, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 6, "scene": 10, "line": "End the mistakes. That is all I ask. [gets up from chair] And you can't have a favorite Iron Chef. It depends entirely on the secret ingredient. Sometimes I feel you don't know food at all.", "order": 45406, "speaker": "Robert California"},
  {"season": 8, "episode": 6, "scene": 11, "line": "I'm just saying with the NBA lockout I think roller derby's in a really good place now. So'my pitch is'me, Pam, you and, someone else maybe Justine.", "order": 45407, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 6, "scene": 11, "line": "Nahh! [shakes head] No. Not Justine. Never Justine.", "order": 45408, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 6, "scene": 11, "line": "Is that off again?", "order": 45409, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 6, "scene": 11, "line": "Oh yeah.", "order": 45410, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 6, "scene": 11, "line": "Ok.", "order": 45411, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 6, "scene": 11, "line": "Hey, mandatory warehouse safety meeting. Today.", "order": 45412, "speaker": "Val Johnson"},
  {"season": 8, "episode": 6, "scene": 11, "line": "Ahh'We don't really do those. We just sign the thing.", "order": 45413, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 6, "scene": 11, "line": "Are you really this lazy?", "order": 45414, "speaker": "Val Johnson"},
  {"season": 8, "episode": 6, "scene": 11, "line": "I'll be there.", "order": 45415, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 6, "scene": 12, "line": "Hey.", "order": 45416, "speaker": "Gabe Lewis"},
  {"season": 8, "episode": 6, "scene": 12, "line": "Hey.", "order": 45417, "speaker": "Val Johnson"},
  {"season": 8, "episode": 6, "scene": 12, "line": "Monday's suck.", "order": 45418, "speaker": "Gabe Lewis"},
  {"season": 8, "episode": 6, "scene": 12, "line": "Yeaha'yup. [awkward silence then points to door] Just trying to get in.", "order": 45419, "speaker": "Val Johnson"},
  {"season": 8, "episode": 6, "scene": 13, "line": "I may have a little solution to our mistakes problem. This is a project I've been working on for quite some time and today, might be the day to use it.", "order": 45420, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 6, "scene": 13, "line": "What do you got?", "order": 45421, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 6, "scene": 13, "line": "[opens folder] Allow me. You're going to love this. [struggles opening folder] Ugh'should've used a shorter string. Never mind, I know it by heart. It is a system that holds people accountable for everyone else's work.", "order": 45422, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 6, "scene": 13, "line": "Sounds controversial.", "order": 45423, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 6, "scene": 13, "line": "Have I not been worthy of your trust? Have I not been a reliable number two?", "order": 45424, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 6, "scene": 13, "line": "Do not go there! You're the deuce I never want to drop.", "order": 45425, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 6, "scene": 13, "line": "Well, I can make this work. I'll set it up right now. Just need your go ahead.", "order": 45426, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 6, "scene": 13, "line": "Go do the voodoo that you do so well.", "order": 45427, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 6, "scene": 13, "line": "I will do my voodoo.", "order": 45428, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 6, "scene": 13, "line": "Mmhmmm.", "order": 45429, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 6, "scene": 14, "line": "Hey. I need you to get the paperwork rolling on a new workplace relationship.", "order": 45430, "speaker": "Gabe Lewis"},
  {"season": 8, "episode": 6, "scene": 14, "line": "For you?", "order": 45431, "speaker": "Toby Flenderson"},
  {"season": 8, "episode": 6, "scene": 14, "line": "Yes. For Gabe.", "order": 45432, "speaker": "Gabe Lewis"},
  {"season": 8, "episode": 6, "scene": 14, "line": "Who are you seeing? That's gr'", "order": 45433, "speaker": "Toby Flenderson"},
  {"season": 8, "episode": 6, "scene": 14, "line": "Whom I'm seeing is Val from down in the warehouse.", "order": 45434, "speaker": "Gabe Lewis"},
  {"season": 8, "episode": 6, "scene": 14, "line": "Oh.", "order": 45435, "speaker": "Toby Flenderson"},
  {"season": 8, "episode": 6, "scene": 14, "line": "I'm not technically seeing her, but uh I've seen her, with the eyes and uh there was attraction. In at least one direction. So..[holds up fist]", "order": 45436, "speaker": "Gabe Lewis"},
  {"season": 8, "episode": 6, "scene": 14, "line": "You know I don't have to do the paperwork unless you're actually dating.", "order": 45437, "speaker": "Toby Flenderson"},
  {"season": 8, "episode": 6, "scene": 14, "line": "Ok, but once this starts, it's going to be moving fast. It's going to be hot and heavy and I don't want a bunch of bureaucratic red tape wrapped around my jock. You know?", "order": 45438, "speaker": "Gabe Lewis"},
  {"season": 8, "episode": 6, "scene": 14, "line": "But I mean'uh have you talked to her? Is sh'", "order": 45439, "speaker": "Toby Flenderson"},
  {"season": 8, "episode": 6, "scene": 14, "line": "Yeah we had a whole conversation about Mondays'", "order": 45440, "speaker": "Gabe Lewis"},
  {"season": 8, "episode": 6, "scene": 14, "line": "Do you know her last name, yet?", "order": 45441, "speaker": "Toby Flenderson"},
  {"season": 8, "episode": 6, "scene": 14, "line": "Toby I'm going to tell you her last name tomorrow because she's going to be screaming it tonight.", "order": 45442, "speaker": "Gabe Lewis"},
  {"season": 8, "episode": 6, "scene": 14, "line": "She's going to be screaming her own last name?", "order": 45443, "speaker": "Toby Flenderson"},
  {"season": 8, "episode": 6, "scene": 14, "line": "Hey! Watch it.", "order": 45444, "speaker": "Gabe Lewis"},
  {"season": 8, "episode": 6, "scene": 14, "line": "Good luck Gabe.", "order": 45445, "speaker": "Toby Flenderson"},
  {"season": 8, "episode": 6, "scene": 15, "line": "Hi guys. I just wanted to say that, you all have been doing amazing work., really.", "order": 45446, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 6, "scene": 15, "line": "Thank you.", "order": 45447, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 6, "scene": 15, "line": "And I'd like to add that your work has been a little sloppy. So, Dwight and I have implemented a new program that we like to call'Dwight'", "order": 45448, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 6, "scene": 15, "line": "The accountability booster. It registers every time a mistake has been made in the office. From a late delivery to an accounting error. Five strikes in a day equals a home run. One home run and you're out.", "order": 45449, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 6, "scene": 15, "line": "If we as a group make five mistakes in a day, something bad happens like we block Minesweeper.", "order": 45450, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 6, "scene": 15, "line": "Or in this case an email gets sent to Robert California containing the consultants report from last year. Remember the one that recommended the branch be shutdown? And as a fail-safe also every negative email you've ever written about him to the group will also be forwarded to him.", "order": 45451, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 6, "scene": 15, "line": "What emails are you talking about?", "order": 45452, "speaker": "Kelly Kapoor"},
  {"season": 8, "episode": 6, "scene": 15, "line": "Robert's favorite songs: Creep by TLC, Creep by Radio Head. You remember that one Jim? There is no way he hasn't strangled at least one stripper. Oscar. He eats his yogurt like he is punishing it for disappointing him. Kelly.", "order": 45453, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 6, "scene": 15, "line": "That's not that bad actually.", "order": 45454, "speaker": "Kelly Kapoor"},
  {"season": 8, "episode": 6, "scene": 15, "line": "P.S. We should should kill him.", "order": 45455, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 6, "scene": 15, "line": "Wait, so you installed a doomsday device?", "order": 45456, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 6, "scene": 15, "line": "No, it's an accountability booster.", "order": 45457, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 6, "scene": 15, "line": "Which when it goes off it destroys everything. Very similar to a doomsday device.", "order": 45458, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 6, "scene": 15, "line": "Jim, you're trying to make me sound like some kind of evil maniac. Now the point is that we are now working in an environment where we have accountability to each other. I am confident that you guys are equal to the task.", "order": 45459, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 6, "scene": 15, "line": "Um, no we're not and you are a psycho who is ruining our lives.", "order": 45460, "speaker": "Kelly Kapoor"},
  {"season": 8, "episode": 6, "scene": 15, "line": "We can't do this Dwight.", "order": 45461, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 6, "scene": 15, "line": "[Everyone angrily disagreeing]Smile'nod. Smile and nod.", "order": 45462, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 6, "scene": 16, "line": "They are making me out to be a Bond villian. I like to think of myself as a brilliant scientist who will stop at nothing to remake the world. Like'not Doctor Moreau someone good. Doctor Frankenstein, Doctor Jekyll, not them. Doctor'", "order": 45463, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 6, "scene": 17, "line": "Are you sure this is a good idea? I mean the thing about this office is, we make a lot of mistakes.", "order": 45464, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 6, "scene": 17, "line": "But the device will change that. Without a safety net, people will improve.", "order": 45465, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 6, "scene": 18, "line": "Alright everybody, looks like we need to be getting to work. Be extra careful. Double check everything or the accountability booster will getcha.", "order": 45466, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 6, "scene": 18, "line": "This doomsdays device sounds like a scare tactic to me.", "order": 45467, "speaker": "Stanley Hudson"},
  {"season": 8, "episode": 6, "scene": 18, "line": "There's an easy way to tell if this device is a sham. We just make a mistake. I'll send an order down to shipping before we've received payment.", "order": 45468, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 6, "scene": 18, "line": "[alert goes off] Oh, there's one mistake.", "order": 45469, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 6, "scene": 18, "line": "Now we only have four strikes left until a home run.", "order": 45470, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 6, "scene": 18, "line": "Dwight's our co-worker and he worked really hard on this doomsday device so I'", "order": 45471, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 6, "scene": 18, "line": "It's not a doomsday device, gosh. [Erin makes red strike on desk] We can do this you guys.", "order": 45472, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 6, "scene": 19, "line": "First. Second.", "order": 45473, "speaker": "Warehouse Crew"},
  {"season": 8, "episode": 6, "scene": 19, "line": "It's not a race.", "order": 45474, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 6, "scene": 19, "line": "Thirrrr'.third.", "order": 45475, "speaker": "Warehouse Crew"},
  {"season": 8, "episode": 6, "scene": 19, "line": "Who knows what the belt is for?", "order": 45476, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 6, "scene": 19, "line": "It's for protecting my ass. When you suckers lift more than you can handle. Sup, I'm Gabe. Corporate. Continue. Don't be nervous.", "order": 45477, "speaker": "Gabe Lewis"},
  {"season": 8, "episode": 6, "scene": 19, "line": "Apparently we have a visitor. Gabe, everyone.", "order": 45478, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 6, "scene": 19, "line": "Hi, gabe.", "order": 45479, "speaker": "Warehouse Crew"},
  {"season": 8, "episode": 6, "scene": 19, "line": "All right so the support belt. Now this one is mine. Doesn't get much use nowadays.", "order": 45480, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 6, "scene": 19, "line": "Look at this, this is enormous.", "order": 45481, "speaker": "Gabe Lewis"},
  {"season": 8, "episode": 6, "scene": 20, "line": "I get the sense that Val enjoys a good putdown. Considering that's the only thing I know about her. I will be milking that hard. [Gestures milking a cow]", "order": 45482, "speaker": "Gabe Lewis"},
  {"season": 8, "episode": 6, "scene": 21, "line": "It's like a hula hoop. Right. Mele Kalikimaka is the wise way.", "order": 45483, "speaker": "Gabe Lewis"},
  {"season": 8, "episode": 6, "scene": 21, "line": "You done?", "order": 45484, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 6, "scene": 21, "line": "The Michelin man called, he wants his cummerbund back.", "order": 45485, "speaker": "Gabe Lewis"},
  {"season": 8, "episode": 6, "scene": 22, "line": "Remember we have to give Rigo Escrow their refund by five.", "order": 45486, "speaker": "Phyllis Vance"},
  {"season": 8, "episode": 6, "scene": 22, "line": "I'm on it.", "order": 45487, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 6, "scene": 22, "line": "Kevin, maybe I should handle that. We really need you to focus on your project.", "order": 45488, "speaker": "Angela Martin"},
  {"season": 8, "episode": 6, "scene": 22, "line": "Good thinking.", "order": 45489, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 6, "scene": 23, "line": "Apparently a big client for this company, needs to know the story of how paper gets made.", "order": 45490, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 6, "scene": 24, "line": "Oscar, use a calculator. [Oscar shakes head]", "order": 45491, "speaker": "Angela Martin"},
  {"season": 8, "episode": 6, "scene": 25, "line": "When the stakes are this high, there is only one computer that I trust. And it's powered by thai food and spanish reds. [points to his head]", "order": 45492, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 6, "scene": 26, "line": "Alright everybody, you are doing great!", "order": 45493, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 6, "scene": 26, "line": "Well, I wouldn't say that. Three mistakes already is pretty terrible, but I do see improvement. Meredith kept someone on hold for thirty minutes and now look she's hard at work. [Meredith gives dwight the finger]", "order": 45494, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 6, "scene": 26, "line": "Dwight, question.", "order": 45495, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 6, "scene": 26, "line": "No questions.", "order": 45496, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 6, "scene": 26, "line": "If this doomsday device goes off...", "order": 45497, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 6, "scene": 26, "line": "Accountability booster.", "order": 45498, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 6, "scene": 26, "line": "If this bad idea goes off and we all lose our jobs. Are you going to feel good about that?", "order": 45499, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 6, "scene": 26, "line": "I haven't even considered it. That's how sure I am that this accountability booster is going to work.", "order": 45500, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 6, "scene": 27, "line": "Try mose1234.", "order": 45501, "speaker": "Stanley Hudson"},
  {"season": 8, "episode": 6, "scene": 27, "line": "Dwight would never be that obvious. Try something like'z64$8. [incorrect password] Not that exactly Jim, something like that.", "order": 45502, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 6, "scene": 27, "line": "Ok.", "order": 45503, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 6, "scene": 27, "line": "How about Scrantonstrangler666.", "order": 45504, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 6, "scene": 27, "line": "Nope.", "order": 45505, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 6, "scene": 27, "line": "No. Oh shoot. Ha ha ha'You guys are never gonna shut down the machine, ok? But I appreciate your energy and your team work. If you applied this to your regular work, You won't even notice that the device is there, watching you ready to strike.", "order": 45506, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 6, "scene": 27, "line": "Wha'what's Dwight's mothers name.", "order": 45507, "speaker": "Kelly Kapoor"},
  {"season": 8, "episode": 6, "scene": 27, "line": "Hmm'Heda. [alert] No.", "order": 45508, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 6, "scene": 28, "line": "Once you read the packet, sign the back.", "order": 45509, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 6, "scene": 28, "line": "Hey Darryl, I was thinking, uh, maybe while we read through this, uh, you could grab us all some coffee. My treat. It's a hundred dollar bill. Should cover it I think. Don't bring me any of that caramel soy latte crap, ok? I want a decaf frappuccino. Val'", "order": 45510, "speaker": "Gabe Lewis"},
  {"season": 8, "episode": 6, "scene": 28, "line": "Actually, Val, why don't you come with? I'll need the extra hands to carry them back.", "order": 45511, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 6, "scene": 28, "line": "Yeah, sure.", "order": 45512, "speaker": "Val Johnson"},
  {"season": 8, "episode": 6, "scene": 28, "line": "Uh.", "order": 45513, "speaker": "Gabe Lewis"},
  {"season": 8, "episode": 6, "scene": 28, "line": "Mmhmm. [points at gabe] Caramel soy latte.", "order": 45514, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 6, "scene": 28, "line": "Decaf frap-", "order": 45515, "speaker": "Gabe Lewis"},
  {"season": 8, "episode": 6, "scene": 28, "line": "Got it.", "order": 45516, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 6, "scene": 29, "line": "You sent the late notice to Ryan Heart & Wolf. Right?", "order": 45517, "speaker": "Angela Martin"},
  {"season": 8, "episode": 6, "scene": 29, "line": "Mhm. Six-forty, six-twenty.", "order": 45518, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 6, "scene": 29, "line": "Six-seventy, six-twenty.", "order": 45519, "speaker": "Angela Martin"},
  {"season": 8, "episode": 6, "scene": 29, "line": "Nuh uh.", "order": 45520, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 6, "scene": 29, "line": "yes!", "order": 45521, "speaker": "Angela Martin"},
  {"season": 8, "episode": 6, "scene": 29, "line": "Five eighty-eight plus fifteen percent- Oh no.", "order": 45522, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 6, "scene": 29, "line": "What does this mean? What does it mean!", "order": 45523, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 6, "scene": 29, "line": "Andy. [alert sounds]", "order": 45524, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 6, "scene": 29, "line": "Ohhhh!", "order": 45525, "speaker": "Angela Martin"},
  {"season": 8, "episode": 6, "scene": 29, "line": "Gahhh. That's five strikes.", "order": 45526, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 6, "scene": 29, "line": "[pulls out Brandy bottle] Well'I was saving this for my retirement, which I guess is today.", "order": 45527, "speaker": "Stanley Hudson"},
  {"season": 8, "episode": 6, "scene": 29, "line": "Dwight we got five strikes.", "order": 45528, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 6, "scene": 29, "line": "Really?", "order": 45529, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 6, "scene": 29, "line": "Did the email go out or'", "order": 45530, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 6, "scene": 29, "line": "It goes out automatically at five P.M.", "order": 45531, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 6, "scene": 29, "line": "Well, th- There's gotta be a way to stop it.", "order": 45532, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 6, "scene": 29, "line": "Well, I would have to enter my password in order to cancel it.", "order": 45533, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 6, "scene": 29, "line": "Ok! Dwight you may now enter your password.", "order": 45534, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 6, "scene": 29, "line": "No.", "order": 45535, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 6, "scene": 29, "line": "What?!", "order": 45536, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 6, "scene": 29, "line": "You don't deserve to have this branch. Five mistakes in less than a day.", "order": 45537, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 6, "scene": 29, "line": "We did our best.", "order": 45538, "speaker": "Phyllis Vance"},
  {"season": 8, "episode": 6, "scene": 29, "line": "No you didn't, Phyllis. You complained the whole time. You yelled at me. You tried to break into the machine. [everyone interjects] What?!", "order": 45539, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 6, "scene": 29, "line": "You're a real crumb bum, you know that?", "order": 45540, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 6, "scene": 29, "line": "Hey, you can't just change the rules because you don't like the outcome. What about you, Kevin? What about you and your fake task? Can you tell me now where paper comes from?", "order": 45541, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 6, "scene": 29, "line": "Uh, the man tree puts its penis-", "order": 45542, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 6, "scene": 29, "line": "Ha. Ok, alright. Andy back me up here, please.", "order": 45543, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 6, "scene": 29, "line": "Nn no.", "order": 45544, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 6, "scene": 29, "line": "What?", "order": 45545, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 6, "scene": 29, "line": "No!", "order": 45546, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 6, "scene": 29, "line": "Dwight be human for once. Shut down the machine.", "order": 45547, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 6, "scene": 29, "line": "Shut it down! Shut it down! Shut down the machine!!! [erin joins in yelling] Shut it down! Shut it down!", "order": 45548, "speaker": "Kelly Kapoor"},
  {"season": 8, "episode": 6, "scene": 29, "line": "Good luck finding a new job idiots. I'll make sure to write you a glowing reference. Glowingly negative.", "order": 45549, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 6, "scene": 30, "line": "Dwight's car is gone.", "order": 45550, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 6, "scene": 30, "line": "I bet he went home.", "order": 45551, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 6, "scene": 30, "line": "Some of us should go there and talk some sense into him. Get him to stop that email.", "order": 45552, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 6, "scene": 30, "line": "Pam, you should come with me. Dwight really likes you and your breasts are enormous that could help us.", "order": 45553, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 6, "scene": 30, "line": "Yeah.", "order": 45554, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 6, "scene": 30, "line": "Kevin has that lovability.", "order": 45555, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 6, "scene": 30, "line": "Guys come on. I'm right here.", "order": 45556, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 6, "scene": 30, "line": "Jim, I want you to go find Robert just be where he is in case that email goes out at five. You can try to delete it or something.", "order": 45557, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 6, "scene": 30, "line": "Ok, where is he?", "order": 45558, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 6, "scene": 30, "line": "Uh, he's at some club where you either eat squash or play squash.", "order": 45559, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 6, "scene": 30, "line": "I'll try both.", "order": 45560, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 6, "scene": 31, "line": "[digging] Oh. Come to reason with me?", "order": 45561, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 6, "scene": 31, "line": "Gotcha something. [gives Dwight cap, Dwight throws it aside] And, uh, I just really want to talk to you-", "order": 45562, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 6, "scene": 31, "line": "Get lost.", "order": 45563, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 6, "scene": 31, "line": "Well now hold on it, it-", "order": 45564, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 6, "scene": 31, "line": "What are you doing?", "order": 45565, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 6, "scene": 31, "line": "What does it look like I'm doing? Digging a grave for a horse.", "order": 45566, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 6, "scene": 31, "line": "Uhm.", "order": 45567, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 6, "scene": 31, "line": "Do you need a hand?", "order": 45568, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 6, "scene": 31, "line": "Yeah, do you need a hand? [everyone joins in]", "order": 45569, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 6, "scene": 31, "line": "If you hit another horse, you've dug to far.", "order": 45570, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 6, "scene": 32, "line": "I'll see you next week Will. [at the club, playing squash]", "order": 45571, "speaker": "Robert California"},
  {"season": 8, "episode": 6, "scene": 32, "line": "Robert.", "order": 45572, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 6, "scene": 32, "line": "Jim what are you'What a surprise.", "order": 45573, "speaker": "Robert California"},
  {"season": 8, "episode": 6, "scene": 32, "line": "Yeah, well, you know just had a meeting. Squash meeting.", "order": 45574, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 6, "scene": 32, "line": "Yeah.", "order": 45575, "speaker": "Robert California"},
  {"season": 8, "episode": 6, "scene": 32, "line": "You up for a game?", "order": 45576, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 6, "scene": 32, "line": "A game or a match?", "order": 45577, "speaker": "Robert California"},
  {"season": 8, "episode": 6, "scene": 32, "line": "Exactly. Here we go. Let's do it.", "order": 45578, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 6, "scene": 33, "line": "Are you okay, Kevin? [Kevin gives thumbs down]", "order": 45579, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 6, "scene": 33, "line": "[to Dwight] Where you going?", "order": 45580, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 6, "scene": 33, "line": "In. I'm hungry.", "order": 45581, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 6, "scene": 33, "line": "Uh, could we come in too? Just for some water.", "order": 45582, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 6, "scene": 33, "line": "Okay. Take off your shoes. Except you Kevin, they stay on.", "order": 45583, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 6, "scene": 33, "line": "Oh, wow. I forgot how pretty your house is.", "order": 45584, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 6, "scene": 33, "line": "This is the new addition, built my Erasmus Schrute in 1808. It doubled as a tuberculosis recovery room until 2009.", "order": 45585, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 6, "scene": 34, "line": "That's too much Doodle and not enough Lab.", "order": 45586, "speaker": "Val Johnson"},
  {"season": 8, "episode": 6, "scene": 34, "line": "Yeah, when are they gonna do a Labradoodle that's just Lab?", "order": 45587, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 6, "scene": 34, "line": "That's what I'm sayin'.", "order": 45588, "speaker": "Val Johnson"},
  {"season": 8, "episode": 6, "scene": 34, "line": "[to Val] Hey. You're welcome.", "order": 45589, "speaker": "Gabe Lewis"},
  {"season": 8, "episode": 6, "scene": 34, "line": "Thanks for the coffee.", "order": 45590, "speaker": "Val Johnson"},
  {"season": 8, "episode": 6, "scene": 34, "line": "So, tonight I was thinking, I'm gonna go to the cemetery. I'm gonna drink a little wine and I thought maybe you'd like to come with me.", "order": 45591, "speaker": "Gabe Lewis"},
  {"season": 8, "episode": 6, "scene": 34, "line": "Are you asking me on a date?", "order": 45592, "speaker": "Val Johnson"},
  {"season": 8, "episode": 6, "scene": 34, "line": "Yes, I am.", "order": 45593, "speaker": "Gabe Lewis"},
  {"season": 8, "episode": 6, "scene": 34, "line": "Because I don't date coworkers. It's not personal, it's a matter of policy.", "order": 45594, "speaker": "Val Johnson"},
  {"season": 8, "episode": 6, "scene": 34, "line": "I could quit. Problem solved.", "order": 45595, "speaker": "Gabe Lewis"},
  {"season": 8, "episode": 6, "scene": 34, "line": "Don't quit. [Darryl eavesdropping, nods]", "order": 45596, "speaker": "Val Johnson"},
  {"season": 8, "episode": 6, "scene": 35, "line": "Good policy. Sensible. Smart.", "order": 45597, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 6, "scene": 36, "line": "Alright. Serving. Serving. Serving. Serving.", "order": 45598, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 6, "scene": 36, "line": "In the box.", "order": 45599, "speaker": "Robert California"},
  {"season": 8, "episode": 6, "scene": 36, "line": "In the box. [hits it straight into the floor]", "order": 45600, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 6, "scene": 37, "line": "Why haven't we heard anything? It's 4:45. There's only 15 minutes left.", "order": 45601, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 6, "scene": 37, "line": "Oh, now you can do math? Where were you 2 hours ago 'A Beautiful Mind'-", "order": 45602, "speaker": "Angela Martin"},
  {"season": 8, "episode": 6, "scene": 37, "line": "I made a mistake. I'm sorry.", "order": 45603, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 6, "scene": 37, "line": "I know how to save the company, everyone. Just write a petition, get everyones' signature, including our clients, march down to Florida, and shove it up your butt! Ha! [laughing and drinking Brandy]", "order": 45604, "speaker": "Stanley Hudson"},
  {"season": 8, "episode": 6, "scene": 37, "line": "It's not that funny.", "order": 45605, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 6, "scene": 38, "line": "[Kevin comes around corner with a pan, ready to strike Dwight, Pam shakes her head] Oh, Pam. You got something on your shirt.", "order": 45606, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 6, "scene": 38, "line": "Oh, haha. Oh well. Pobody's nerfect, right?", "order": 45607, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 6, "scene": 38, "line": "Did you just have a stroke, Pam? It's 'Nobody's perfect.' Nice stroke, Pam.", "order": 45608, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 6, "scene": 38, "line": "No. It's a jokey saying. Pobody's nerfect, like I can't even say those words right. Ha.", "order": 45609, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 6, "scene": 38, "line": "I hadn't heard that before, that's, that's funny.", "order": 45610, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 6, "scene": 38, "line": "Dwight, there's just a small matter of a-", "order": 45611, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 6, "scene": 38, "line": "You know what would go so great with this cabbage pie? Milk.", "order": 45612, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 6, "scene": 38, "line": "Any specific animal?", "order": 45613, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 6, "scene": 38, "line": "I'm thinking cow-", "order": 45614, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 6, "scene": 38, "line": "Don't say cow- Ugh.", "order": 45615, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 6, "scene": 38, "line": "What are you doing? It's 5 to 5.", "order": 45616, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 6, "scene": 38, "line": "Just don't talk about the email, okay? He's gonna cancel it on his own, I really think he will.", "order": 45617, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 6, "scene": 38, "line": "That's insane!", "order": 45618, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 6, "scene": 38, "line": "Just trust me.", "order": 45619, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 6, "scene": 38, "line": "Trust you, like I trusted Dwight this morning.", "order": 45620, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 6, "scene": 38, "line": "I got this.", "order": 45621, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 6, "scene": 38, "line": "Enngh-", "order": 45622, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 6, "scene": 39, "line": "Hahhh, thanks for everything.", "order": 45623, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 6, "scene": 39, "line": "Sive drafely. [Pam points back at him, smiling]", "order": 45624, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 6, "scene": 39, "line": "Isn't it supposed to be, 'Drive safely'?", "order": 45625, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 6, "scene": 40, "line": "[a text tone goes off] Is that my phone?", "order": 45626, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 6, "scene": 40, "line": "Sounded like mine.", "order": 45627, "speaker": "Robert California"},
  {"season": 8, "episode": 6, "scene": 40, "line": "Nah, I think it's mine. Lemme just check real quick, here. Alright.", "order": 45628, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 6, "scene": 40, "line": "Well, it's mine. You took it out of my bag.", "order": 45629, "speaker": "Robert California"},
  {"season": 8, "episode": 6, "scene": 40, "line": "Oh, oh yeah.", "order": 45630, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 6, "scene": 40, "line": "Can I have it?", "order": 45631, "speaker": "Robert California"},
  {"season": 8, "episode": 6, "scene": 40, "line": "Yes. Right now? Yes.", "order": 45632, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 6, "scene": 40, "line": "Yep.", "order": 45633, "speaker": "Robert California"},
  {"season": 8, "episode": 6, "scene": 40, "line": "Here you go. [throws it over glass]", "order": 45634, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 6, "scene": 40, "line": "Whoa, no, wait, wait, wait, whoa. [tries to catch it with racket, misses, lands on floor]", "order": 45635, "speaker": "Robert California"},
  {"season": 8, "episode": 6, "scene": 40, "line": "[censored beep] ha, Jesus.", "order": 45636, "speaker": "Robert California"},
  {"season": 8, "episode": 6, "scene": 40, "line": "Awww, sorry. Did it break?", "order": 45637, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 6, "scene": 40, "line": "Nah, it's good.", "order": 45638, "speaker": "Robert California"},
  {"season": 8, "episode": 6, "scene": 40, "line": "You sure?", "order": 45639, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 6, "scene": 40, "line": "Yeah.", "order": 45640, "speaker": "Robert California"},
  {"season": 8, "episode": 6, "scene": 40, "line": "What kinda iPhone is that?", "order": 45641, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 6, "scene": 40, "line": "It's the standard one. The one everyone has.", "order": 45642, "speaker": "Robert California"},
  {"season": 8, "episode": 6, "scene": 40, "line": "Oh yeah. I have the one that nobody has. Is there anything interesting?", "order": 45643, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 6, "scene": 40, "line": "It depends Jim. Do you find one-day only Jet Blue sales to Buffalo interesting?", "order": 45644, "speaker": "Robert California"},
  {"season": 8, "episode": 6, "scene": 40, "line": "Ha ha. No, I don't. No, I don't. Alright, well, I am sore and obviously horrible at this, so- [texting on his phone]", "order": 45645, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 6, "scene": 40, "line": "My serve!", "order": 45646, "speaker": "Robert California"},
  {"season": 8, "episode": 6, "scene": 41, "line": "[her text tones rings] Dwight stopped the device!", "order": 45647, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 6, "scene": 41, "line": "Oh!", "order": 45648, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 6, "scene": 41, "line": "Yes!", "order": 45649, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 6, "scene": 41, "line": "Haho!", "order": 45650, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 6, "scene": 42, "line": "[his phone vibrates] He stopped it!", "order": 45651, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 6, "scene": 42, "line": "Oh! [everyone yelling out, celebrating] We still have our jobs.", "order": 45652, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 6, "scene": 43, "line": "They're not my favorite people in the world. I wouldn't even call them friends. They come over here, eat my pie, dig the crappiest horse grave you've ever seen. [pulls cap he previously discarded, out of the dirt] God, I'm gonna have to work with them forever, aren't I?", "order": 45653, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 6, "scene": 44, "line": "Take it easy. Nice and easy.", "order": 45654, "speaker": "Robert California"},
  {"season": 8, "episode": 6, "scene": 44, "line": "[hits ball, ricochets to groin] Ah! Classic, right? [Jim continues to rally poorly and falls down]", "order": 45655, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 6, "scene": 44, "line": "Oo!", "order": 45656, "speaker": "Robert California"},
  {"season": 8, "episode": 6, "scene": 44, "line": "I'm alright.", "order": 45657, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 6, "scene": 44, "line": "You alright?", "order": 45658, "speaker": "Robert California"},
  {"season": 8, "episode": 6, "scene": 44, "line": "Yep.", "order": 45659, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 6, "scene": 44, "line": "Skinned knee.", "order": 45660, "speaker": "Robert California"},
  {"season": 8, "episode": 6, "scene": 44, "line": "Yep. Ah. Oh. [and skinned elbow]", "order": 45661, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 6, "scene": 44, "line": "Oo, a little ice on that maybe.", "order": 45662, "speaker": "Robert California"},
  {"season": 8, "episode": 6, "scene": 44, "line": "Yeah. That does not feel good.", "order": 45663, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 6, "scene": 44, "line": "Okay. My serve.", "order": 45664, "speaker": "Robert California"},
  {"season": 8, "episode": 6, "scene": 45, "line": "Let's not make any mistakes today. Or at least no more than four.", "order": 45665, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 6, "scene": 45, "line": "This doomsday device sounds like a scare tactic to me. Y2K all over again.", "order": 45666, "speaker": "Stanley Hudson"},
  {"season": 8, "episode": 6, "scene": 46, "line": "Oh, I was all about the Y2K bug. Paid some guy to update my PC, threw out my microwave, canceled my plane ticket, sold all my stocks, spent New Year's Eve with a gas mask on my face. I was even wearing a diaper! Someone said Y2K would get the toilets.", "order": 45667, "speaker": "Stanley Hudson"},
  {"season": 8, "episode": 6, "scene": 47, "line": "This could be real. Dwight's pretty handy with computers. He fixed my laptop once.", "order": 45668, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 6, "scene": 47, "line": "Ok, he didn't fix it. The brightness level was all the way down.", "order": 45669, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 6, "scene": 47, "line": "You couldn't fix it.", "order": 45670, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 6, "scene": 47, "line": "I wasn't here that day.", "order": 45671, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 6, "scene": 47, "line": "Yes you were.", "order": 45672, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 6, "scene": 47, "line": "No- Ok. Well, we'll talk about it later. Straussburg! I had a meeting in Straussburg and I dropped you off beforehand. I was wearing a tan shirt with stripes?", "order": 45673, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 6, "scene": 47, "line": "I don't think so, babe.", "order": 45674, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 6, "scene": 47, "line": "Ok, this is crazy.", "order": 45675, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 6, "scene": 48, "line": "I have never played squash. But I have seen it in countless 80's movies and it seems like the most common mistake is that you serve too hard and hit yourself right in the squash balls. So, as long as I don't do that, I think I'll be alright.", "order": 45676, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 6, "scene": 49, "line": "Eleven love. I win again.", "order": 45677, "speaker": "Robert California"},
  {"season": 8, "episode": 6, "scene": 49, "line": "[grunts] Good game. You are a worthy opponent. You wanna play one more?", "order": 45678, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 6, "scene": 49, "line": "I don't think so.", "order": 45679, "speaker": "Robert California"},
  {"season": 8, "episode": 6, "scene": 49, "line": "No? Come on.", "order": 45680, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 6, "scene": 49, "line": "Why? Not only are you terrible at squash, but you don't even seem to enjoy it. Jim, this is not enough of a challenge for me.", "order": 45681, "speaker": "Robert California"},
  {"season": 8, "episode": 6, "scene": 49, "line": "Well, that is... because you didn't realize that... I was left-handed.", "order": 45682, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 6, "scene": 50, "line": "What are you doing here, Gabe?", "order": 45683, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 6, "scene": 50, "line": "Well Darryl, considering that I basically own the place, I'm just chillin' at mi casa. This is me, in repose..I suppose.", "order": 45684, "speaker": "Gabe Lewis"},
  {"season": 8, "episode": 6, "scene": 51, "line": "You know, there's this expression: Bro's before Ho's. And what that's short for is brothers before whores. Uh, I don't buy that. I would throw any brother under the bus for any whore. Woman.", "order": 45685, "speaker": "Gabe Lewis"},
  {"season": 8, "episode": 6, "scene": 52, "line": "Robert, do you wanna smoke pipes during our meeting?", "order": 45686, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 6, "scene": 52, "line": "Let's wait, until we have something to smoke about.", "order": 45687, "speaker": "Robert California"},
  {"season": 8, "episode": 6, "scene": 52, "line": "Yes. Of course. [whispers] Erin..[throws pipes at Erin]", "order": 45688, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 6, "scene": 52, "line": "Hey, Andy.", "order": 45689, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 6, "scene": 52, "line": "Yeah?", "order": 45690, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 6, "scene": 52, "line": "Maybe I could sit in on this one with you? You know, that way I can cover you in case you need to urinate or you just want another opinion, or defecate...", "order": 45691, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 6, "scene": 52, "line": "I don't think so.", "order": 45692, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 6, "scene": 52, "line": "Well I just thought with my new increased responsibilities and all...", "order": 45693, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 6, "scene": 52, "line": "Yeah. I'm still figuring out the two man bobsled here, so let's hold off on the D-man for now.", "order": 45694, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 7, "scene": 1, "line": "Erin.", "order": 45695, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 7, "scene": 1, "line": "Yeah.", "order": 45696, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 7, "scene": 1, "line": "In two minutes I want you to come into this meeting and tell me I have a really important phone call. I'm not going to take it because I want him to know how important the meeting is to me.", "order": 45697, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 7, "scene": 1, "line": "Who's calling?", "order": 45698, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 7, "scene": 1, "line": "Nobody. Just say that I'm- Just make it up that I have a phone call. And then I'm going to refuse to take it.", "order": 45699, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 7, "scene": 1, "line": "You're not going to take it?", "order": 45700, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 7, "scene": 1, "line": "Just make up a phone call. It's not a real call. Make it up.", "order": 45701, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 7, "scene": 1, "line": "Ohohohoh.", "order": 45702, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 7, "scene": 1, "line": "And come and tell me. Doesn't matter what it is.", "order": 45703, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 7, "scene": 1, "line": "You're not going to get it though?", "order": 45704, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 7, "scene": 1, "line": "I'm not going to take it. And then he's going to be like 'whoa this is a really important meeting'.", "order": 45705, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 7, "scene": 1, "line": "Copy that.", "order": 45706, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 7, "scene": 1, "line": "Okay.", "order": 45707, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 7, "scene": 2, "line": "Ok, so tell me exactly what kind of deal you are getting now and I'll tell you how we can beat it.", "order": 45708, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 7, "scene": 2, "line": "Uh well we've been going with'", "order": 45709, "speaker": "Client"},
  {"season": 8, "episode": 7, "scene": 2, "line": "Andy.", "order": 45710, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 7, "scene": 2, "line": "Yeah.", "order": 45711, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 7, "scene": 2, "line": "You have a very important call.", "order": 45712, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 7, "scene": 2, "line": "I'm sorry ' I'm with a very important client. It'll have to wait.", "order": 45713, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 7, "scene": 2, "line": "Are you sure? It's really, really important.", "order": 45714, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 7, "scene": 2, "line": "There is nothing more important to me right now than this meeting.", "order": 45715, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 7, "scene": 2, "line": "Really? Because your mother is dead.", "order": 45716, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 7, "scene": 2, "line": "Oh my God.", "order": 45717, "speaker": "Client"},
  {"season": 8, "episode": 7, "scene": 2, "line": "I don't think she's dead.", "order": 45718, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 7, "scene": 2, "line": "She's dead. She was hit by a bus.", "order": 45719, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 7, "scene": 2, "line": "She's not dead. This is exactly the kind of thing my mom pulls.", "order": 45720, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 7, "scene": 2, "line": "This isn't one of those times. It's the police. They said it's the worst they've ever seen.", "order": 45721, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 7, "scene": 2, "line": "Andy I'm really sorry about your mother. My deepest condolences.", "order": 45722, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 7, "scene": 2, "line": "Oh you must take this call. It's'", "order": 45723, "speaker": "Client"},
  {"season": 8, "episode": 7, "scene": 2, "line": "Yeah. Um line 1?", "order": 45724, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 7, "scene": 2, "line": "Line 2.", "order": 45725, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 7, "scene": 2, "line": "Hi.", "order": 45726, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 7, "scene": 2, "line": "[on phone]It's Darryl. Erin told me to pretend to be a cop and say your mom died.", "order": 45727, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 7, "scene": 2, "line": "Ooh, gosh!", "order": 45728, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 7, "scene": 2, "line": "Dude.", "order": 45729, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 7, "scene": 2, "line": "Officer.", "order": 45730, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 7, "scene": 2, "line": "Look man this is a bad idea.", "order": 45731, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 7, "scene": 2, "line": "Did she have any last words or?", "order": 45732, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 7, "scene": 2, "line": "Really? That is messed up man.", "order": 45733, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 7, "scene": 2, "line": "Oh make sure that your client gets the best deal possible.", "order": 45734, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 7, "scene": 2, "line": "You're a bad man Andy Bernard.", "order": 45735, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 7, "scene": 2, "line": "That is so mom.", "order": 45736, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 7, "scene": 2, "line": "That stuff can come back to get you. It's called karma. You do not want to be messing around. I got an uncle'", "order": 45737, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 7, "scene": 2, "line": "Alright thank you officer. Erin please hold all my other calls. Where were we?", "order": 45738, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 7, "scene": 3, "line": "Hey Cathy.", "order": 45739, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 7, "scene": 3, "line": "Hi.", "order": 45740, "speaker": "Cathy Simms"},
  {"season": 8, "episode": 7, "scene": 3, "line": "How's it going? Everything make sense?", "order": 45741, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 7, "scene": 3, "line": "I think everything is under control.", "order": 45742, "speaker": "Cathy Simms"},
  {"season": 8, "episode": 7, "scene": 3, "line": "Great.", "order": 45743, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 7, "scene": 3, "line": "You should sit down.", "order": 45744, "speaker": "Cathy Simms"},
  {"season": 8, "episode": 7, "scene": 3, "line": "Oh no, I'm fine.", "order": 45745, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 7, "scene": 3, "line": "No I should go fill out my paperwork.", "order": 45746, "speaker": "Cathy Simms"},
  {"season": 8, "episode": 7, "scene": 3, "line": "Ok [squeaking sound] Oh that was just me. Pregnant Pam. And I make sounds much worse than this.", "order": 45747, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 7, "scene": 3, "line": "Oh we know.", "order": 45748, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 7, "scene": 4, "line": "I'm training a temp to be my replacement while I'm on maternity leave. Oh I should've mentioned I'm pregnant. You probably didn't notice because it's impossible to tell I'm so small. But yeah I'm pregnant. [another squeak] Oh come on!", "order": 45749, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 7, "scene": 5, "line": "Hey ' asking for a friend. Do you happen to know if that new girl is single?", "order": 45750, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 7, "scene": 5, "line": "Hmm. Doubt it.", "order": 45751, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 7, "scene": 5, "line": "Yeah me too.", "order": 45752, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 7, "scene": 5, "line": "You doubt it?", "order": 45753, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 7, "scene": 5, "line": "What's that?", "order": 45754, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 7, "scene": 5, "line": "Why do you doubt that she's single?", "order": 45755, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 7, "scene": 5, "line": "Honestly I have no idea. I just figured we'd save her from Ryan right?", "order": 45756, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 7, "scene": 6, "line": "What are you doing with my lunch?", "order": 45757, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 7, "scene": 6, "line": "I'm delivering it from the fridge. It's like a porno. Hey did anyone order a pizza?", "order": 45758, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 7, "scene": 6, "line": "It's not pizza.", "order": 45759, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 7, "scene": 6, "line": "Yeah and we're not about to make love. I just thought maybe you'd want to eat lunch at your desk today so that during lunch we could go down to the warehouse and bang out a few tunes.", "order": 45760, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 7, "scene": 6, "line": "I like the sound of that. Is Kevin in?", "order": 45761, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 7, "scene": 6, "line": "Uh you tell me [Kevin playing drums with chicken legs]", "order": 45762, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 7, "scene": 7, "line": "So word on the street is she has a boyfriend.", "order": 45763, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 7, "scene": 7, "line": "Well he's probably a drug dealer. That's the best way to land a hot girlfriend. You just uh get her hooked on blow.", "order": 45764, "speaker": "Gabe Lewis"},
  {"season": 8, "episode": 7, "scene": 7, "line": "It's going to be nice to have just a healthy, young, fit presence in the middle of the office.", "order": 45765, "speaker": "Toby Flenderson"},
  {"season": 8, "episode": 7, "scene": 7, "line": "Yeah aesthetically speaking she adds a nice presence.", "order": 45766, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 7, "scene": 7, "line": "Good energy", "order": 45767, "speaker": "Offscreen"},
  {"season": 8, "episode": 7, "scene": 7, "line": "It's going to be good to have someone hot at Pam's desk huh?", "order": 45768, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 7, "scene": 7, "line": "No, no. Not even.", "order": 45769, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 7, "scene": 7, "line": "I'm kidding! Oh my gosh you guys! She's obviously super cute I get it. But I'd like to point out there's 50 pounds more of me to love if that's your thing.", "order": 45770, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 7, "scene": 7, "line": "Pam you look more beautiful now than ever.", "order": 45771, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 7, "scene": 7, "line": "Radiant.", "order": 45772, "speaker": "Toby Flenderson"},
  {"season": 8, "episode": 7, "scene": 7, "line": "Thank you. Really you guys thank you. Very sweet.", "order": 45773, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 7, "scene": 7, "line": "Yeah you have this sexy glow.", "order": 45774, "speaker": "Phyllis Vance"},
  {"season": 8, "episode": 7, "scene": 7, "line": "It's one of the most common fetishes.", "order": 45775, "speaker": "Gabe Lewis"},
  {"season": 8, "episode": 7, "scene": 7, "line": "Really well thank you all.", "order": 45776, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 7, "scene": 7, "line": "You know it's not just pregnant women who don't get their due. You know who's gorgeous? Helen Mirren.", "order": 45777, "speaker": "Toby Flenderson"},
  {"season": 8, "episode": 7, "scene": 7, "line": "Yes! Have you seen her in a bikini? Amazing?", "order": 45778, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 7, "scene": 7, "line": "You know what would be the hottest thing ever? It's a pregnant Helen Mirren.", "order": 45779, "speaker": "Toby Flenderson"},
  {"season": 8, "episode": 7, "scene": 7, "line": "Ok ok ok no no no. This is disgusting. You realize what you're saying? The hottest thing ever would be a 66 year old pregnant woman.", "order": 45780, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 7, "scene": 7, "line": "In this case yes.", "order": 45781, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 7, "scene": 7, "line": "No! There are universal biological standards of beauty and attraction. And you are purposefully celebrating the opposite of them to mollycoddle a pregnant woman.", "order": 45782, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 7, "scene": 7, "line": "No we're not.", "order": 45783, "speaker": "Phyllis Vance"},
  {"season": 8, "episode": 7, "scene": 7, "line": "Yes you are. And another thing. Helen Mirren was born Helen Mironov. That's right. You're fake salivating over a Soviet era Russian.", "order": 45784, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 7, "scene": 8, "line": "[scatting] Ske-be-do-bah-bap-bah-de, ske-be-de-bapa-de-bapa-boopa-dooten-bebe-daten-booray.", "order": 45785, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 7, "scene": 8, "line": "Nice scatting, man.", "order": 45786, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 7, "scene": 8, "line": "Thank you. I think I said doop instead of boop at one point.", "order": 45787, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 7, "scene": 8, "line": "Not bad fellas, you're better than you look.", "order": 45788, "speaker": "Val Johnson"},
  {"season": 8, "episode": 7, "scene": 8, "line": "Hey, screw you!", "order": 45789, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 7, "scene": 8, "line": "[sees Robert California enter] Hey Robert, are we meeting early?", "order": 45790, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 7, "scene": 8, "line": "Just taking a stroll. What exactly have I stumbled upon here?", "order": 45791, "speaker": "Robert California"},
  {"season": 8, "episode": 7, "scene": 8, "line": "Well we're all musicians and we play together sometimes.", "order": 45792, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 7, "scene": 8, "line": "You're a band.", "order": 45793, "speaker": "Robert California"},
  {"season": 8, "episode": 7, "scene": 8, "line": "Thank you.", "order": 45794, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 7, "scene": 8, "line": "We're called Kevin and the Zits.", "order": 45795, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 7, "scene": 8, "line": "That was never agreed upon.", "order": 45796, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 7, "scene": 8, "line": "I miss being in a band.", "order": 45797, "speaker": "Robert California"},
  {"season": 8, "episode": 7, "scene": 8, "line": "Miss no more! What do you play? Join us!", "order": 45798, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 7, "scene": 8, "line": "I have a tambourine.", "order": 45799, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 7, "scene": 8, "line": "Tambourine? You know I'm the CEO, right?", "order": 45800, "speaker": "Robert California"},
  {"season": 8, "episode": 7, "scene": 8, "line": "[to Kevin] CEOs don't play tambourines. Tambourines are for girlfriends.", "order": 45801, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 7, "scene": 8, "line": "I play harmonica. I think I have one in the car.", "order": 45802, "speaker": "Robert California"},
  {"season": 8, "episode": 7, "scene": 8, "line": "Great!", "order": 45803, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 7, "scene": 8, "line": "Nice guys, Robert is going to be a Zit!", "order": 45804, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 7, "scene": 8, "line": "Again, never agreed upon.", "order": 45805, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 7, "scene": 9, "line": "I had totally given up on hanging out with Robert California and now he wants to be in our band! And when you're in a rock and roll band with somebody, you're bonded for life!", "order": 45806, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 7, "scene": 9, "line": "Usually that life is short and tragic. That's okay, right?", "order": 45807, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 7, "scene": 9, "line": "Yeah, even cooler.", "order": 45808, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 7, "scene": 9, "line": "We all got to go sometime.", "order": 45809, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 7, "scene": 10, "line": "Hey.", "order": 45810, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 7, "scene": 10, "line": "Hey.", "order": 45811, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 7, "scene": 10, "line": "Helen Mirren. Hot?", "order": 45812, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 7, "scene": 10, "line": "Yeah. Super pretty.", "order": 45813, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 7, "scene": 10, "line": "Huh. What about Cathy?", "order": 45814, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 7, "scene": 10, "line": "The temp?", "order": 45815, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 7, "scene": 10, "line": "Yeah. Do you think she's hot?", "order": 45816, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 7, "scene": 10, "line": "Nope.", "order": 45817, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 7, "scene": 10, "line": "I'm not asking if you're in to her. Just, objectively, do you find her attractive?", "order": 45818, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 7, "scene": 10, "line": "And I'm telling you, I don't.", "order": 45819, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 7, "scene": 10, "line": "You don't find Cathy attractive?", "order": 45820, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 7, "scene": 10, "line": "No, I don't.", "order": 45821, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 7, "scene": 11, "line": "No, I'm not going to tell my nine-months-pregnant wife that I find her replacement objectively attractive. Just like I'm not going to tell my two-year-old daughter that violent video games are objectively more fun. It's true, but it doesn't help anybody.", "order": 45822, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 7, "scene": 12, "line": "Look at her. Even I want some fries with that shake.", "order": 45823, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 7, "scene": 12, "line": "Okay. Uh, I don't. So are we good? [Jim leaves]", "order": 45824, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 7, "scene": 12, "line": "That's just absurd.", "order": 45825, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 7, "scene": 12, "line": "Yes, because she's hot, right?", "order": 45826, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 7, "scene": 12, "line": "Her breasts are large, her waist is small, her reproductive health in ample evidence. And facial symmetry- come on.", "order": 45827, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 7, "scene": 13, "line": "The thing about pregnancy is people treat you differently. Like you're a kid almost. They lose all sense of boundaries. They start acting weird, telling you things that clearly aren't true. I know it sounds nuts, but I think Dwight is the only one who's telling me the truth.", "order": 45828, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 7, "scene": 14, "line": "Dwight, am I hot right now?", "order": 45829, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 7, "scene": 14, "line": "Why would I or anyone else think that you're hot right now? I can't impregnate you, and that's the driving force between male-female attraction.", "order": 45830, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 7, "scene": 14, "line": "What about before? Was I attractive before?", "order": 45831, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 7, "scene": 14, "line": "Meh... you were at your most attractive when you were 24 with a slight, gradual decline and a steep drop-off when you got pregnant for the first time. Gradual recovery and, uh, well now, obviously, you're at an all-time low.", "order": 45832, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 7, "scene": 14, "line": "Hmm. I think Jim's lying to me about not being attracted to Cathy.", "order": 45833, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 7, "scene": 14, "line": "You think Jim's lying? [laughs] That's so cute. I know he's lying.", "order": 45834, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 7, "scene": 14, "line": "Five bucks if you can get him to admit it.", "order": 45835, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 7, "scene": 14, "line": "Done. [Pam goes for a high five] I never touch a pregnant woman.", "order": 45836, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 7, "scene": 14, "line": "Yep, that's the Dwight I need.", "order": 45837, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 7, "scene": 14, "line": "If we're going to work together, we need some ground rules.", "order": 45838, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 7, "scene": 14, "line": "Okay.", "order": 45839, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 7, "scene": 14, "line": "Rule one: our only loyalty is to the truth.", "order": 45840, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 7, "scene": 14, "line": "I think so. Okay.", "order": 45841, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 7, "scene": 14, "line": "Rule two: we stop at nothing.", "order": 45842, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 7, "scene": 14, "line": "Well, what does that... is that... okay.", "order": 45843, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 7, "scene": 14, "line": "Rule three: Don't fall in love.", "order": 45844, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 7, "scene": 14, "line": "Yep, good.", "order": 45845, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 7, "scene": 15, "line": "We're gonna bust this guy.", "order": 45846, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 7, "scene": 15, "line": "Honesty is very important to me.", "order": 45847, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 7, "scene": 15, "line": "So important. And then we will destroy the man himself.", "order": 45848, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 7, "scene": 15, "line": "Let's just see how we feel when we get there.", "order": 45849, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 7, "scene": 16, "line": "Well, you came to the right person. You have to follow your intuition, Pam. You don't want to end up like Elin Nordegren. Actually, what am I saying, you wish you had her life- no offense, Pam.", "order": 45850, "speaker": "Kelly Kapoor"},
  {"season": 8, "episode": 7, "scene": 16, "line": "None taken. What do you got?", "order": 45851, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 7, "scene": 16, "line": "It is called the matchmaker test, and it is very powerful. Basically we have Pam ask Jim which of his friends he would set up with Cathy. If Jim picks a really hot friend, then we know that he thinks that Cathy's hot.", "order": 45852, "speaker": "Kelly Kapoor"},
  {"season": 8, "episode": 7, "scene": 16, "line": "Hmm.", "order": 45853, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 7, "scene": 17, "line": "[to the keyboardist, Curtis, and the drummer] Hey, you found us!", "order": 45854, "speaker": "Robert California"},
  {"season": 8, "episode": 7, "scene": 17, "line": "You guys sound great, man.", "order": 45855, "speaker": "Curtis"},
  {"season": 8, "episode": 7, "scene": 17, "line": "Darryl, Andy, Kevin, this is Curtis Dorough, local musician and the officiate at my wedding.", "order": 45856, "speaker": "Robert California"},
  {"season": 8, "episode": 7, "scene": 17, "line": "Local musician? Local legend! I used to come hear you play every week at the Deerhead.", "order": 45857, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 7, "scene": 17, "line": "[to drummer] Dude, you're on TV! You're the sportscaster on channel seven.", "order": 45858, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 7, "scene": 17, "line": "Go Eagles! [laughs]", "order": 45859, "speaker": "Drummer"},
  {"season": 8, "episode": 7, "scene": 17, "line": "You do that on TV!", "order": 45860, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 7, "scene": 17, "line": "You guys mind if they join us?", "order": 45861, "speaker": "Robert California"},
  {"season": 8, "episode": 7, "scene": 17, "line": "Yeah, absolutely!", "order": 45862, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 7, "scene": 17, "line": "Guys, I've got some instruments right here. [holds up tambourine and giro]", "order": 45863, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 7, "scene": 17, "line": "Hey, why don't you and me play those?", "order": 45864, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 7, "scene": 17, "line": "Yeah? Okay, this is awesome.", "order": 45865, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 7, "scene": 17, "line": "What should we play?", "order": 45866, "speaker": "Robert California"},
  {"season": 8, "episode": 7, "scene": 17, "line": "Maybe we should warm up with some scales?", "order": 45867, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 7, "scene": 17, "line": "Midnight Rambler?", "order": 45868, "speaker": "Robert California"},
  {"season": 8, "episode": 7, "scene": 17, "line": "Yeah.", "order": 45869, "speaker": "Curtis"},
  {"season": 8, "episode": 7, "scene": 17, "line": "Midnight what?", "order": 45870, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 7, "scene": 17, "line": "[singing] Have you heard about the midnight rambler? [Lisa, the guitarist, enters] Hey, come on in, baby. Yeah!", "order": 45871, "speaker": "Curtis"},
  {"season": 8, "episode": 7, "scene": 18, "line": "Hey, I was talking to Cathy. Turns out she is single.", "order": 45872, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 7, "scene": 18, "line": "Oh, I stand corrected.", "order": 45873, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 7, "scene": 18, "line": "I thought it might be fun to set her up with someone. Any ideas?", "order": 45874, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 7, "scene": 18, "line": "Oh, you know who might be good? Mike Tibbets.", "order": 45875, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 7, "scene": 18, "line": "Mike Tibbets, really?", "order": 45876, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 7, "scene": 18, "line": "Mm-hmm.", "order": 45877, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 7, "scene": 18, "line": "Hey, who's this Mike Tibbets guy? What kind of car does he drive?", "order": 45878, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 7, "scene": 18, "line": "Not his mom's car.", "order": 45879, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 7, "scene": 18, "line": "Yeah, 'cause his mom's car's probably not a Nissan Z.", "order": 45880, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 7, "scene": 18, "line": "Touche?", "order": 45881, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 7, "scene": 19, "line": "[sees photo of Mike Tibbets] Eww!", "order": 45882, "speaker": "Kelly Kapoor"},
  {"season": 8, "episode": 7, "scene": 19, "line": "Kelly, calm down.", "order": 45883, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 7, "scene": 19, "line": "I mean, I guess he'd be okay with hair. Okay, you should see if he'll get hair plugs.", "order": 45884, "speaker": "Kelly Kapoor"},
  {"season": 8, "episode": 7, "scene": 19, "line": "I don't think Jim cares about his hair.", "order": 45885, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 7, "scene": 19, "line": "Yeah, but I do Pam, okay? It's called being a nice person.", "order": 45886, "speaker": "Kelly Kapoor"},
  {"season": 8, "episode": 7, "scene": 19, "line": "I don't see what's so ugly about him. He's got the broad face of a brewer.", "order": 45887, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 7, "scene": 19, "line": "Jim's on to me.", "order": 45888, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 7, "scene": 19, "line": "Hmm?", "order": 45889, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 7, "scene": 19, "line": "Yeah, Jim barely talks to Mike. We had to go through like two levels of friends to even find his profile. Jim picked someone just unattractive enough to shut me up without tipping it.", "order": 45890, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 7, "scene": 19, "line": "Just ugly enough to have deniability.", "order": 45891, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 7, "scene": 19, "line": "Yep.", "order": 45892, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 7, "scene": 20, "line": "Mike Tibbets is like the most boring-looking guy I know. So if that was for the matchmaker test, I think I'm in the clear. If that wasn't for the matchmaker test, then... Cathy, he's a really nice guy.", "order": 45893, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 7, "scene": 21, "line": "Well, Jim may be lying with his words, but he can't lie with his body.", "order": 45894, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 7, "scene": 21, "line": "I'm gonna write something mean on his wall.", "order": 45895, "speaker": "Kelly Kapoor"},
  {"season": 8, "episode": 7, "scene": 21, "line": "No, Kelly, don't.", "order": 45896, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 7, "scene": 21, "line": "The male reveals attraction through unconscious and involuntary physical signs. The puffing of the chest, mirroring, increased blood flow to the crotch. I say we start there.", "order": 45897, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 7, "scene": 21, "line": "With the crotch?", "order": 45898, "speaker": "Pam Halpert & Kelly Kapoor"},
  {"season": 8, "episode": 7, "scene": 21, "line": "With the crotch.", "order": 45899, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 7, "scene": 22, "line": "[music plays] Whoo!", "order": 45900, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 7, "scene": 23, "line": "Psst. We're not here. [slips Toblerone candy to Creed]", "order": 45901, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 7, "scene": 23, "line": "Who said that?", "order": 45902, "speaker": "Creed Bratton"},
  {"season": 8, "episode": 7, "scene": 23, "line": "Exactly.", "order": 45903, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 7, "scene": 23, "line": "How'd I get this long triangle?", "order": 45904, "speaker": "Creed Bratton"},
  {"season": 8, "episode": 7, "scene": 23, "line": "Okay, just shut it.", "order": 45905, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 7, "scene": 23, "line": "Is he puffing out his chest?", "order": 45906, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 7, "scene": 23, "line": "I can't tell. It's unnaturally sunken. [Jim says something and Cathy laughs] Busted. He just was mirroring. Did you see that?", "order": 45907, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 7, "scene": 23, "line": "No. Maybe he just said something funny.", "order": 45908, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 7, "scene": 23, "line": "Jim has no discernible sense of humor, Pam. You should know that.", "order": 45909, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 7, "scene": 23, "line": "I think he's just making her laugh.", "order": 45910, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 7, "scene": 23, "line": "Time for me to find out.", "order": 45911, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 7, "scene": 23, "line": "Why's he making her laugh so much?", "order": 45912, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 7, "scene": 23, "line": "Just going to walk over here- [fake trips] whoa, whoa, I'm slipping and falling! Oh, stumbling, I need something to grab on to! [grabs Jim's crotch]", "order": 45913, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 7, "scene": 23, "line": "Dwight!", "order": 45914, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 7, "scene": 23, "line": "Are you okay?", "order": 45915, "speaker": "Cathy Simms"},
  {"season": 8, "episode": 7, "scene": 23, "line": "I'm fine, I'm totally fine.", "order": 45916, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 7, "scene": 23, "line": "Dwight! Dwight!", "order": 45917, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 7, "scene": 23, "line": "Yes? [Jim pushes Dwight's hands away] Wha- Jim.", "order": 45918, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 7, "scene": 23, "line": "[to Cathy] Sorry about that.", "order": 45919, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 7, "scene": 23, "line": "Aw, cramp, I'm just- [grabs Jim's crotch again]", "order": 45920, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 7, "scene": 23, "line": "Dwight! [pushes Dwight's hands away] Why?", "order": 45921, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 7, "scene": 23, "line": "I'm sorry, I fell down, Mr. Balance.", "order": 45922, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 7, "scene": 23, "line": "Leave.", "order": 45923, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 7, "scene": 23, "line": "[to Pam] Does your husband have very soft erections? Because if not, I just grabbed a very soft penis for nothing.", "order": 45924, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 7, "scene": 24, "line": "Why was he making her laugh so much?", "order": 45925, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 7, "scene": 25, "line": "Oh, hey, I'll just be a second.", "order": 45926, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 7, "scene": 25, "line": "Yeah, take your time.", "order": 45927, "speaker": "Cathy Simms"},
  {"season": 8, "episode": 7, "scene": 25, "line": "Okay.", "order": 45928, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 7, "scene": 25, "line": "[to Jim] Oh, that line from Zoolander?", "order": 45929, "speaker": "Cathy Simms"},
  {"season": 8, "episode": 7, "scene": 25, "line": "Mm-hmm?", "order": 45930, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 7, "scene": 25, "line": "It was from a deleted scene so we were both right.", "order": 45931, "speaker": "Cathy Simms"},
  {"season": 8, "episode": 7, "scene": 25, "line": "[laughs] Told you.", "order": 45932, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 7, "scene": 25, "line": "Um, do I hand in my expense reports to a particular accountant or-", "order": 45933, "speaker": "Cathy Simms"},
  {"season": 8, "episode": 7, "scene": 25, "line": "Oscar, Cathy has a question!", "order": 45934, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 7, "scene": 25, "line": "Sorry, um, I'll just go ask Oscar.", "order": 45935, "speaker": "Cathy Simms"},
  {"season": 8, "episode": 7, "scene": 25, "line": "[to Jim] Yeah?", "order": 45936, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 7, "scene": 25, "line": "You okay?", "order": 45937, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 7, "scene": 25, "line": "Why won't you just admit that she's attractive? It's kind of annoying that you won't say it.", "order": 45938, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 7, "scene": 25, "line": "Okay, what can I do to make you believe me?", "order": 45939, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 7, "scene": 25, "line": "Well, Dwight had this idea, and I thought it was kind of crazy. But maybe that's where we are now.", "order": 45940, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 7, "scene": 26, "line": "She called it crazy? Aw, man, that's insulting. All I did was propose a makeshift lie-detector test. Monitoring his blood pressure, pulse, perspiration, and breathing rate.", "order": 45941, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 7, "scene": 27, "line": "Yeah, its nuts. But I don't know what else to do.", "order": 45942, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 7, "scene": 28, "line": "And she called it nuts?", "order": 45943, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 7, "scene": 29, "line": "Seriously, that was hot. Feel like you and the newsman had a groove going.", "order": 45944, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 7, "scene": 29, "line": "Mm-hmm.", "order": 45945, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 7, "scene": 29, "line": "Did you know that Lisa toured with Chaka Khan?", "order": 45946, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 7, "scene": 29, "line": "Are you serious? From Star Trek?", "order": 45947, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 7, "scene": 29, "line": "[to Val] Hey, you caught some of that?", "order": 45948, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 7, "scene": 29, "line": "I caught it.", "order": 45949, "speaker": "Val Johnson"},
  {"season": 8, "episode": 7, "scene": 29, "line": "What, you don't like the blues?", "order": 45950, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 7, "scene": 29, "line": "I might enjoy seeing you guys play the blues.", "order": 45951, "speaker": "Val Johnson"},
  {"season": 8, "episode": 7, "scene": 29, "line": "Well, we are playing. We're all playing together. These are our jam buddies. It's a jam session. We go where the music takes us. [music starts]", "order": 45952, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 7, "scene": 29, "line": "I think the music left without you.", "order": 45953, "speaker": "Val Johnson"},
  {"season": 8, "episode": 7, "scene": 30, "line": "Come on.", "order": 45954, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 7, "scene": 30, "line": "Stop shoving me.", "order": 45955, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 7, "scene": 30, "line": "[mocking] Stop shoving me. Stop grabbing my penis. Grow up. Take off your jacket and take a seat. When it lies, the human body exhibits many telltale signs.", "order": 45956, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 7, "scene": 30, "line": "[to Pam] Really?", "order": 45957, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 7, "scene": 30, "line": "This could all go away if you just tell me the truth.", "order": 45958, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 7, "scene": 30, "line": "Uh, are you all in line?", "order": 45959, "speaker": "Old Man"},
  {"season": 8, "episode": 7, "scene": 30, "line": "Why don't you go check out the sympathy cards, old man? [to Jim] Now I'm going to ask you just a few simple questions. Wait for this to constrict. Here we go. Is your name Jim Halpert?", "order": 45960, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 7, "scene": 30, "line": "Si.", "order": 45961, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 7, "scene": 30, "line": "Wait, that's it? That's the question you're going to ask him?", "order": 45962, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 7, "scene": 30, "line": "We have to start with a baseline question to establish what the truth is.", "order": 45963, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 7, "scene": 30, "line": "You have to share the machine with others.", "order": 45964, "speaker": "Old Man"},
  {"season": 8, "episode": 7, "scene": 30, "line": "[mimicking] Well, that's what they taught me in my 19th century kindergarten.", "order": 45965, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 7, "scene": 30, "line": "Okay, Dwight, come on. [to old man] You can go first.", "order": 45966, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 7, "scene": 30, "line": "Thank you.", "order": 45967, "speaker": "Old Man"},
  {"season": 8, "episode": 7, "scene": 30, "line": "Jim, are you serious?", "order": 45968, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 7, "scene": 30, "line": "Oh, you know what? This reminds me, Cece needs a new toothbrush.", "order": 45969, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 7, "scene": 31, "line": "Maybe we could switch instruments.", "order": 45970, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 7, "scene": 31, "line": "Yeah.", "order": 45971, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 7, "scene": 31, "line": "Cause, uh, my body's starting to get bruised.", "order": 45972, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 7, "scene": 31, "line": "Yeah, maybe everyone move one instrument to the right?", "order": 45973, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 7, "scene": 32, "line": "We had to leave because of creative differences.", "order": 45974, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 7, "scene": 32, "line": "Yeah they kind of had a specific sound that didn't really fit in with our thing.", "order": 45975, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 7, "scene": 32, "line": "Guys, this means they're Kevin and the Zits now.", "order": 45976, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 7, "scene": 32, "line": "No man, we are.", "order": 45977, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 7, "scene": 32, "line": "Well-", "order": 45978, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 7, "scene": 32, "line": "No, no, no.", "order": 45979, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 7, "scene": 33, "line": "[to old man] Okay, you're done.", "order": 45980, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 7, "scene": 33, "line": "I have a new heart, you know.", "order": 45981, "speaker": "Old Man"},
  {"season": 8, "episode": 7, "scene": 33, "line": "Do you really, Tin Man? Okay, where were we?", "order": 45982, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 7, "scene": 34, "line": "No, I'm not worried. Because this lie isn't for me, it's for Pam. And when she gets her body back and her confidence back... yes, I will tell her the truth. That I had feelings for a co-worker today that I haven't had in years. But in my defense, he was grabbing my crotch fairly aggressively at the time.", "order": 45983, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 7, "scene": 35, "line": "Do you find Cathy Simms attractive?", "order": 45984, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 7, "scene": 35, "line": "No.", "order": 45985, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 7, "scene": 35, "line": "Yep, he's lying.", "order": 45986, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 7, "scene": 35, "line": "Ah, see, was that so hard?", "order": 45987, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 7, "scene": 35, "line": "I am not lying.", "order": 45988, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 7, "scene": 35, "line": "Really?", "order": 45989, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 7, "scene": 35, "line": "Pam, are you really gonna listen to his stupid homemade test?", "order": 45990, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 7, "scene": 35, "line": "I would listen to my homemade test, because your husband is definitely lying. In fact, he's lied about every question. Even his name. Who are you really?", "order": 45991, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 7, "scene": 35, "line": "Wait, what?", "order": 45992, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 7, "scene": 35, "line": "Look at the numbers. Every single time, it's come up 150 over 100. Your husband is a pathological liar.", "order": 45993, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 7, "scene": 35, "line": "Jim, you have high blood pressure.", "order": 45994, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 7, "scene": 35, "line": "Oh, he is definitely attracted to her.", "order": 45995, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 7, "scene": 35, "line": "Doesn't your dad have high blood pressure?", "order": 45996, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 7, "scene": 35, "line": "Yeah, but I don't smoke.", "order": 45997, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 7, "scene": 35, "line": "When was the last time you went to the doctor?", "order": 45998, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 7, "scene": 35, "line": "I don't know.", "order": 45999, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 7, "scene": 35, "line": "It's important to go every month and get your prostate checked. You can do it at home by yourself with your finger. You just stick it-", "order": 46000, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 7, "scene": 35, "line": "Dwight, stop. I'm not kidding, I mean, I need you to take care of yourself. What would we do if something happened to you?", "order": 46001, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 7, "scene": 35, "line": "Okay, easy. Trust me, I'm around for the long-haul.", "order": 46002, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 7, "scene": 35, "line": "It's not really your choice, is it? Death waits for no man.", "order": 46003, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 7, "scene": 35, "line": "Okay.", "order": 46004, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 7, "scene": 35, "line": "Come on, let's go. I wanna call your doctor.", "order": 46005, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 7, "scene": 35, "line": "Doesn't it worry you in the slightest that Jim is not his real name?", "order": 46006, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 7, "scene": 35, "line": "Just go home. It doesn't matter.", "order": 46007, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 7, "scene": 35, "line": "But... hey, Cece's toothbrush.", "order": 46008, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 7, "scene": 35, "line": "Thank you.", "order": 46009, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 7, "scene": 36, "line": "Oh baby I love your way.", "order": 46010, "speaker": "Andy Bernard & Darryl Philbin"},
  {"season": 8, "episode": 7, "scene": 36, "line": "Every day.", "order": 46011, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 7, "scene": 36, "line": "Wanna be with you night and day.", "order": 46012, "speaker": "Andy Bernard & Darryl Philbin"},
  {"season": 8, "episode": 7, "scene": 36, "line": "And day.", "order": 46013, "speaker": "Darryl Philbin & Kevin Malone"},
  {"season": 8, "episode": 7, "scene": 36, "line": "Oh baby I love your way.", "order": 46014, "speaker": "Andy Bernard & Darryl Philbin"},
  {"season": 8, "episode": 7, "scene": 36, "line": "[scatting] Rudit-do-do-do-di-do.", "order": 46015, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 8, "scene": 1, "line": "The Sabre Code of Conduct outlines the governing principles key to establishing and maintaining trust with our employees, clients-", "order": 46016, "speaker": "Gabe Lewis"},
  {"season": 8, "episode": 8, "scene": 1, "line": "Oh my God, kill me!", "order": 46017, "speaker": "Kelly Kapoor"},
  {"season": 8, "episode": 8, "scene": 1, "line": "Hey! All right, obviously we all want to die but we have to get through this. So, Gabe go ahead. It's okay.", "order": 46018, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 8, "scene": 1, "line": "Oh, is it okay with you? Because if it's not, you work for me, so... Comply with all applicable laws, regulations, policies and contracts governing our business. Be honest, fair-", "order": 46019, "speaker": "Gabe Lewis"},
  {"season": 8, "episode": 8, "scene": 1, "line": "[whispers to Jim] I'm gonna do it.", "order": 46020, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 8, "scene": 1, "line": "And trustworthy in all your business activities and relationships. Treat one another-", "order": 46021, "speaker": "Gabe Lewis"},
  {"season": 8, "episode": 8, "scene": 1, "line": "Oh! I'm going into labor! Oh my goodness!", "order": 46022, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 8, "scene": 1, "line": "Oh okay, she's going into labor. Make way, everybody!", "order": 46023, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 8, "scene": 2, "line": "I know it's wrong to fake going into labor just to get out of things, but sometimes it's necessary.", "order": 46024, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 8, "scene": 3, "line": "[after Angela gives her a stack of files]: I'm going into labor!", "order": 46025, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 8, "scene": 4, "line": "Or should I have corndogs. I mean-", "order": 46026, "speaker": "Phyllis Vance"},
  {"season": 8, "episode": 8, "scene": 4, "line": "I'm going into labor!", "order": 46027, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 8, "scene": 5, "line": "Okay, three reasons you are wrong about True Blood. Number one-", "order": 46028, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 8, "scene": 5, "line": "I'm going into labor.", "order": 46029, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 8, "scene": 6, "line": "Here we go!", "order": 46030, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 8, "scene": 6, "line": "Hey guys, uh, can't keep saying you're going into labor. Everyone knows you're full of it.", "order": 46031, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 8, "scene": 6, "line": "Yeah.", "order": 46032, "speaker": "Kelly Kapoor"},
  {"season": 8, "episode": 8, "scene": 6, "line": "It's not fair, you guys.", "order": 46033, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 8, "scene": 6, "line": "Pitiful.", "order": 46034, "speaker": "Kelly Kapoor"},
  {"season": 8, "episode": 8, "scene": 6, "line": "It's stupid.", "order": 46035, "speaker": "Meredith Palmer"},
  {"season": 8, "episode": 8, "scene": 6, "line": "Never cry wolf.", "order": 46036, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 8, "scene": 6, "line": "Okay.", "order": 46037, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 8, "scene": 6, "line": "Okay.", "order": 46038, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 8, "scene": 6, "line": "Oh. [liquid splatters]", "order": 46039, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 8, "scene": 6, "line": "Oh! Oh!", "order": 46040, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 8, "scene": 6, "line": "Oh! Oh my goodness!", "order": 46041, "speaker": "Everyone"},
  {"season": 8, "episode": 8, "scene": 6, "line": "Oh my God!", "order": 46042, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 8, "scene": 6, "line": "I'm really in labor! This is happening!", "order": 46043, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 8, "scene": 6, "line": "Okay guys, here we go! We'll see you!", "order": 46044, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 8, "scene": 6, "line": "Oh!", "order": 46045, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 8, "scene": 6, "line": "How do you feel?", "order": 46046, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 8, "scene": 6, "line": "Drive carefully!", "order": 46047, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 8, "scene": 6, "line": "Good luck!", "order": 46048, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 8, "scene": 6, "line": "Goodbye! Good luck! [empty bottle falls to the ground]", "order": 46049, "speaker": "Everyone"},
  {"season": 8, "episode": 8, "scene": 6, "line": "False alarm.", "order": 46050, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 8, "scene": 7, "line": "[on the phone] What?! They took another client from us? Okay, bye. Man! Business is war! Customers, clients- it's like a war out there.", "order": 46051, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 8, "scene": 8, "line": "I am a leader. But you can only inspire people so much in a place like this. So today I'm turning the inspire-factor up to ten with a little help from my friend America's bloodiest battle.", "order": 46052, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 8, "scene": 9, "line": "Why even read business books? We should be studying war. Going to places like Gettysburg. Where is that?", "order": 46053, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 8, "scene": 9, "line": "It's right here in PA.", "order": 46054, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 8, "scene": 9, "line": "[gags, coughs] Well we should take a fieldtrip there. I mean, that would be so cool. I wonder if that bus downstairs is-", "order": 46055, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 8, "scene": 9, "line": "Okay, Andy, we get it. It's a trip to Gettysburg.", "order": 46056, "speaker": "Angela Martin"},
  {"season": 8, "episode": 8, "scene": 9, "line": "That sounds super inspiring! I'm in!", "order": 46057, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 8, "scene": 9, "line": "Gettysburg? Hmm. Could be interesting. Second-most northern battle in the Civil War.", "order": 46058, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 8, "scene": 9, "line": "Actually it is the northernmost.", "order": 46059, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 8, "scene": 9, "line": "Ha!", "order": 46060, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 8, "scene": 10, "line": "The Civil War history industry has conveniently forgotten about the battle of Schrute Farms. [scoffs] Whatever. I'm over it. It's just grossly irresponsible.", "order": 46061, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 8, "scene": 11, "line": "Charge!", "order": 46062, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 8, "scene": 11, "line": "Well, this could be fun. I-", "order": 46063, "speaker": "Phyllis Vance"},
  {"season": 8, "episode": 8, "scene": 11, "line": "Yeah, well, the bus has free wifi and I made special low-sugar lunches for everyone. And is anyone kosher or halal?", "order": 46064, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 8, "scene": 11, "line": "What's the halal option?", "order": 46065, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 8, "scene": 11, "line": "Dates, tabbouleh, and a bagel with cream cheese.", "order": 46066, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 8, "scene": 11, "line": "Out.", "order": 46067, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 8, "scene": 11, "line": "You know, it's the same as the kosher option. There's a lesson in there. I mean, I can't force you to go. You're not my slaves. Thanks to Gettysburg. But... who's coming with me?", "order": 46068, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 8, "scene": 11, "line": "I'm in.", "order": 46069, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 8, "scene": 11, "line": "I'm in too.", "order": 46070, "speaker": "Phyllis Vance"},
  {"season": 8, "episode": 8, "scene": 11, "line": "Guess I'm a sucker for historical fiction.", "order": 46071, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 8, "scene": 11, "line": "Anyone who's not going, you're dead to me. You're uninvited. I don't want you to come. But, FYI, there will be leftover turkey and pesto sammies in the fridge.", "order": 46072, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 8, "scene": 11, "line": "Yes!", "order": 46073, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 8, "scene": 12, "line": "[passes out hats] One for you.", "order": 46074, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 8, "scene": 12, "line": "Cool. Thanks.", "order": 46075, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 8, "scene": 12, "line": "And one for you.", "order": 46076, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 8, "scene": 12, "line": "Cool. Thanks. [puts his hat on Erin's head]", "order": 46077, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 8, "scene": 12, "line": "And- oh. You missed your head. [laughs and puts hat on Jim's head] There you go. Phyllis, think fast!", "order": 46078, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 8, "scene": 13, "line": "All right, guys, a little foreplay before we do it. Fans of Ken Burns' Jazz will most certainly enjoy Civil War.", "order": 46079, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 8, "scene": 13, "line": "You know, I just got Limitless on my iPad. I bet I could get it on the TV.", "order": 46080, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 8, "scene": 13, "line": "Ooh. Isn't that the one where the guy becomes limitless?", "order": 46081, "speaker": "Phyllis Vance"},
  {"season": 8, "episode": 8, "scene": 13, "line": "It's just not appropri- I mean, if we were going to visit Bradley Cooper's birthplace, I'd be the first one suggesting it. I'd be rooting for it.", "order": 46082, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 8, "scene": 13, "line": "Limitless! Limitless! Limitless! Limitless!", "order": 46083, "speaker": "Everyone"},
  {"season": 8, "episode": 8, "scene": 13, "line": "All right, all right, all right.", "order": 46084, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 8, "scene": 14, "line": "Not food and stuff.", "order": 46085, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 8, "scene": 14, "line": "Here. Like it?", "order": 46086, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 8, "scene": 14, "line": "Oh, if you buy the picnic table then you've got to get the fire pit.", "order": 46087, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 8, "scene": 14, "line": "I can't get a fire pit. I have two babies.", "order": 46088, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 8, "scene": 14, "line": "The fire pit is a no-brainer.", "order": 46089, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 8, "scene": 14, "line": "Oh, hi there.", "order": 46090, "speaker": "Robert California"},
  {"season": 8, "episode": 8, "scene": 14, "line": "Plants and- hi, Robert! Hey, um, how are you doing? Good to see you again.", "order": 46091, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 8, "scene": 14, "line": "Where is everyone? Where is Andy?", "order": 46092, "speaker": "Robert California"},
  {"season": 8, "episode": 8, "scene": 14, "line": "Andy took some of the other people on a corporate retreat to Gettysburg.", "order": 46093, "speaker": "Kelly Kapoor"},
  {"season": 8, "episode": 8, "scene": 14, "line": "Well, I was hoping to talk out some ideas with Andy. But what we have here... is perhaps better. By not going on the trip, you've shown you're the free-thinkers of the office.", "order": 46094, "speaker": "Robert California"},
  {"season": 8, "episode": 8, "scene": 14, "line": "Robert, you got your sheep and you got your black sheep, and I'm not even a sheep. I'm on the freaking moon.", "order": 46095, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 8, "scene": 14, "line": "So, here what we can do. Game changers- changes to the game such that the game can never be played the same way again. Everyone, brainstorm some innovations. Don't be afraid to get weird with it. Meredith! [wakes her up] Excited!", "order": 46096, "speaker": "Robert California"},
  {"season": 8, "episode": 8, "scene": 15, "line": "[tries stapler/marker combo] Okay.", "order": 46097, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 8, "scene": 16, "line": "You guys...", "order": 46098, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 8, "scene": 16, "line": "J-j-j-ju...", "order": 46099, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 8, "scene": 16, "line": "Get excited!", "order": 46100, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 8, "scene": 16, "line": "Shh! Movie's almost over.", "order": 46101, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 8, "scene": 16, "line": "All right! We're here. Limitless can wait.", "order": 46102, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 8, "scene": 16, "line": "Fun fact. In France, they call Limitless 'The Man with Many Capabilities.'", "order": 46103, "speaker": "Gabe Lewis"},
  {"season": 8, "episode": 8, "scene": 16, "line": "Woo-hoo! Ladies and gentlemen the eighteen hundreds await you. We can watch Limitless on the way back.", "order": 46104, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 8, "scene": 16, "line": "I got Source Code on the way back.", "order": 46105, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 8, "scene": 16, "line": "Ooh! All right!", "order": 46106, "speaker": "Everyone"},
  {"season": 8, "episode": 8, "scene": 16, "line": "Woo!", "order": 46107, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 8, "scene": 16, "line": "Whoa, where you going?", "order": 46108, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 8, "scene": 16, "line": "Visitor center. Gonna grab a map for the memorials, right?", "order": 46109, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 8, "scene": 16, "line": "Yeah, we're not going to the visitor center. We're not tourists.", "order": 46110, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 8, "scene": 16, "line": "No, of course we're not tourists. We're just people that aren't from here who are taking a tour.", "order": 46111, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 8, "scene": 16, "line": "Yeah, sign says 'Begin tour here.'", "order": 46112, "speaker": "Phyllis Vance"},
  {"season": 8, "episode": 8, "scene": 16, "line": "Unless you're going on the very specially-created and meticulously-researched Andy Bernard tour.", "order": 46113, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 8, "scene": 17, "line": "After Chancellorsville, Lee brought his army up the Shenandoah Valley, right through here! They stopped in this field for a picnic, which they called lunch.", "order": 46114, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 8, "scene": 17, "line": "[to Dwight] Yeah, but I'm confused...", "order": 46115, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 8, "scene": 17, "line": "Total deaths belongs to Gettysburg but when you're talking about D.P.A., that's deaths per acre...", "order": 46116, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 8, "scene": 17, "line": "Mm-hmm.", "order": 46117, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 8, "scene": 17, "line": "...nothing beats the battle of Schrute Farms.", "order": 46118, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 8, "scene": 17, "line": "Oh. D.P.A. sounds way more important that total deaths.", "order": 46119, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 8, "scene": 17, "line": "Oh, it is. And you should read some of these letters that the soldiers wrote home. I mean, it makes the battle of Gettysburg sound like a bunch of schoolgirls wrestling over a hairbrush. [laughs] I'm telling you, they're heartbreaking too. So beautifully written.", "order": 46120, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 8, "scene": 17, "line": "Dwight, what are you telling this girl?", "order": 46121, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 8, "scene": 17, "line": "The truth.", "order": 46122, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 8, "scene": 17, "line": "Stop filling her head with nonsense, okay? She doesn't know any better.", "order": 46123, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 8, "scene": 17, "line": "Oscar, I am so glad you just got here. I would've believed everything he said.", "order": 46124, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 8, "scene": 17, "line": "No, no, no! You're filling her head with nonsense. You and the history books. I'm telling the truth.", "order": 46125, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 8, "scene": 17, "line": "Interesting.", "order": 46126, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 8, "scene": 17, "line": "Yes, thank you. All of history has been whitewashed.", "order": 46127, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 8, "scene": 17, "line": "Really? Why don't you tell us the real history, Gore Vidal?", "order": 46128, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 8, "scene": 17, "line": "Okay, I will. I don't know who that is, but I'm gonna tell you this-", "order": 46129, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 8, "scene": 17, "line": "he's a historian.", "order": 46130, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 8, "scene": 17, "line": "Gettysburg was very important. Credit where credit is due, okay? Big, mad props to Gettysburg. Was it, however, the most northern battle of the civil war?", "order": 46131, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 8, "scene": 17, "line": "Yes, yes, yes!", "order": 46132, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 8, "scene": 17, "line": "Not by a long shot!", "order": 46133, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 8, "scene": 17, "line": "No!", "order": 46134, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 8, "scene": 17, "line": "Yes!", "order": 46135, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 8, "scene": 17, "line": "No, it was not!", "order": 46136, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 8, "scene": 17, "line": "Argh!", "order": 46137, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 8, "scene": 17, "line": "No, it was not. Was it the second-most northern?", "order": 46138, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 8, "scene": 17, "line": "What?", "order": 46139, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 8, "scene": 17, "line": "Sure! I will cede it was the second-most northernmost battle!", "order": 46140, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 8, "scene": 17, "line": "Erin-", "order": 46141, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 8, "scene": 17, "line": "Was it the northernmost? No. Get out of here, Oscar.", "order": 46142, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 8, "scene": 17, "line": "Get out of here!", "order": 46143, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 8, "scene": 18, "line": "I am so eager to hear your game-changers. Let's dig in, shall we?", "order": 46144, "speaker": "Robert California"},
  {"season": 8, "episode": 8, "scene": 18, "line": "May I go first?", "order": 46145, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 8, "scene": 18, "line": "Absolutely.", "order": 46146, "speaker": "Robert California"},
  {"season": 8, "episode": 8, "scene": 18, "line": "Raw fish- the disgusting food from Japan that Americans would never want to eat. Now, we can't get enough of it. From movie stars to construction workers, sushi is what's for dinner. Let me throw another idea at you. Origami. What? The crazy art of paper folding from, that's right, Japan. Don't you wish you could go back to 1980 and open the first sushi restaurant in Manhattan? We can do that! With... origami. It's the sushi of paper.", "order": 46147, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 8, "scene": 18, "line": "This idea hasn't gripped me. What else did you come up with?", "order": 46148, "speaker": "Robert California"},
  {"season": 8, "episode": 8, "scene": 18, "line": "Well I had to memorize the presentation, Robert, and it took a long time to build the swan, so-", "order": 46149, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 8, "scene": 18, "line": "That was bad.", "order": 46150, "speaker": "Robert California"},
  {"season": 8, "episode": 8, "scene": 19, "line": "If your woman is like mine, I bet you come home to hear the same thing all the time. This paper is so hard. It scratches. Why can't there be a paper just for me? Well now there is. 'Papyr.' Paper for women. It's pink, scented and silky soft. Now, you can watch the game and she can write a letter to her sister.", "order": 46151, "speaker": "Stanley Hudson"},
  {"season": 8, "episode": 8, "scene": 19, "line": "The situation you described, returning home to a wife complaining about her paper being too masculine, is not one I'm familiar with.", "order": 46152, "speaker": "Robert California"},
  {"season": 8, "episode": 8, "scene": 19, "line": "In the African-American community-", "order": 46153, "speaker": "Stanley Hudson"},
  {"season": 8, "episode": 8, "scene": 19, "line": "No.", "order": 46154, "speaker": "Robert California"},
  {"season": 8, "episode": 8, "scene": 19, "line": "[murmurs] Thought it was worth a try.", "order": 46155, "speaker": "Stanley Hudson"},
  {"season": 8, "episode": 8, "scene": 20, "line": "That's fascinating. Tell me, what's the significance of the peach orchard, thought?", "order": 46156, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 8, "scene": 20, "line": "Oh, well, that's a great question. Actually some of the most-", "order": 46157, "speaker": "Park Ranger"},
  {"season": 8, "episode": 8, "scene": 20, "line": "Excuse me, I got a question for you.", "order": 46158, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 8, "scene": 20, "line": "Sure.", "order": 46159, "speaker": "Park Ranger"},
  {"season": 8, "episode": 8, "scene": 20, "line": "Can you tell us about the battle of Schrute Farms?", "order": 46160, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 8, "scene": 20, "line": "Uh, I haven't heard of that one.", "order": 46161, "speaker": "Park Ranger"},
  {"season": 8, "episode": 8, "scene": 20, "line": "Really. Okay, follow-up question. How much are they paying you to keep your mouth shut?", "order": 46162, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 8, "scene": 20, "line": "I apologize for my friend and for the Republicans who are cutting your funding.", "order": 46163, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 8, "scene": 20, "line": "We don't need to bother this poor gentleman. I know exactly where we're going. Giddy up! Tallyho!", "order": 46164, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 8, "scene": 20, "line": "[taps Gabe's shoulder] Are you Lincoln?", "order": 46165, "speaker": "Chelsea"},
  {"season": 8, "episode": 8, "scene": 20, "line": "No, no, I'm-", "order": 46166, "speaker": "Gabe Lewis"},
  {"season": 8, "episode": 8, "scene": 21, "line": "Apparently, I bear a passing resemblance to Abraham Lincoln. Makes it kind of hard for me to go to places like museums, historical monuments, elementary schools... I don't see it. Chelsea's Mom: Chelsea, give Mr. Lincoln your hat so I can take a picture.", "order": 46167, "speaker": "Gabe Lewis"},
  {"season": 8, "episode": 8, "scene": 21, "line": "Okay, Quick.", "order": 46168, "speaker": "Gabe Lewis"},
  {"season": 8, "episode": 8, "scene": 21, "line": "Hey! Lincoln's starting. [light applause]", "order": 46169, "speaker": "Man"},
  {"season": 8, "episode": 8, "scene": 21, "line": "Oh, uh, no. No, no, no, no. I'm, uh, I'm actually with a tour group myself, so- [laughter] Hello! I'm Abraham Lincoln! Some people call me the great emancipator, but, uh, you might know me from the penny. [laughter]", "order": 46170, "speaker": "Gabe Lewis"},
  {"season": 8, "episode": 8, "scene": 22, "line": "Okay. You know the test booklets that they give out in all the schools. I was thinking that we could put a coupon in the back that people would mail in to us... and as, you know, as I tell it, I don't like it. Unless, of course, you are responding to it.", "order": 46171, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 8, "scene": 22, "line": "I am not.", "order": 46172, "speaker": "Robert California"},
  {"season": 8, "episode": 8, "scene": 22, "line": "Um, excuse me. I'm gonna go to the bathroom.", "order": 46173, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 8, "scene": 23, "line": "At this point, when you're this pregnant, it's kind of like senior spring. The other day I spit my gum out on the carpet.", "order": 46174, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 8, "scene": 24, "line": "Kevin, you've been quiet. I'm curious to know what your game-changer is.", "order": 46175, "speaker": "Robert California"},
  {"season": 8, "episode": 8, "scene": 24, "line": "Well, you know how in the vending machine they have the chocolate chip cookies in the A-1 spot? They do that 'cause they think A-1's the best spot for the best cookie. But the real best spot is D-4. Right? That's where the eyes go. So...", "order": 46176, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 8, "scene": 24, "line": "Cookies. Cookie placement.", "order": 46177, "speaker": "Robert California"},
  {"season": 8, "episode": 8, "scene": 24, "line": "Yeah. But not just the cookies, though. That was just a 'for instance.'", "order": 46178, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 8, "scene": 24, "line": "Who else agrees with Kevin, that we're wasting facilities, manpower, on our underselling products when we should be pushing our top-performers? [everyone raises their hand, Robert laughs] There you go. Consensus.", "order": 46179, "speaker": "Robert California"},
  {"season": 8, "episode": 8, "scene": 25, "line": "Okay, we are now on a planet where Kevin is the most creative person around, and I am just some good-looking guy.", "order": 46180, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 8, "scene": 26, "line": "[as Lincoln] I just don't understand. It's 1865, victory is ours, I've saved the very soul of our nation, and yet... happiness eludes me. Oh, perhaps a trip to the theater will enliven my spirits.", "order": 46181, "speaker": "Gabe Lewis"},
  {"season": 8, "episode": 8, "scene": 26, "line": "No!", "order": 46182, "speaker": "Audience"},
  {"season": 8, "episode": 8, "scene": 27, "line": "Kay, so another thing about oatmeal cookies. Who even wants them? I mean, I've seen Toby eat one, like, once, but other than that... [scoffs] like, forget it.", "order": 46183, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 8, "scene": 27, "line": "Interesting. So what is Dunder Mifflin's oatmeal cookie? What is the product that no one wants?", "order": 46184, "speaker": "Robert California"},
  {"season": 8, "episode": 8, "scene": 27, "line": "how about that two-hole-punch letter? Only the lawyers want that punch at the top and they use legal.", "order": 46185, "speaker": "Stanley Hudson"},
  {"season": 8, "episode": 8, "scene": 27, "line": "That's the oatmeal cookie. Fantastic, Kevin. Fantastic. [laughs]", "order": 46186, "speaker": "Robert California"},
  {"season": 8, "episode": 8, "scene": 27, "line": "Thank you.", "order": 46187, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 8, "scene": 28, "line": "By the way, did we leave all the food on the bus?", "order": 46188, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 8, "scene": 28, "line": "Let's talk about food for a second. Food for thought.", "order": 46189, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 8, "scene": 28, "line": "Yeah, that's what I had for breakfast and I think that's probably why I'm still hungry.", "order": 46190, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 8, "scene": 28, "line": "Hunger! Hunger for victory! Hunger for honor. Hunger for pride.", "order": 46191, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 8, "scene": 28, "line": "Hunger for hamburger.", "order": 46192, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 8, "scene": 28, "line": "Hunger for chicken chimichangas. Right, Darryl?", "order": 46193, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 8, "scene": 28, "line": "That's good.", "order": 46194, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 8, "scene": 28, "line": "Exactly. Now do you know the Civil War soldiers were hungry for? Pride! Now, each battalion had its own flag and they guarded these flags with their lives. Colonel Harrison Jeffords of the Fourth Michigan Infantry saw his flag being carried away, chased it down with nothing but a sword. Fought tooth and nail at the foot of that flag until he died. He wasn't about to let them have that flag. Pride. Right, guys? [holds up flag] I commissioned this flag for Dunder Mifflin. Cost me two hundred dollars.", "order": 46195, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 8, "scene": 28, "line": "Only two hundred dollars?", "order": 46196, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 8, "scene": 28, "line": "We are all branches on this tree. And from the tree comes paper. We're all part of a business. But business is war. What's that I hear? Uh, a rebel paper company is coming to take our flag! Wha- what's going on here? Wee-hoo! Come and get it! Who's gonna get the flag? Who's getting it? Whoa! Hey! Ho! Don't look where I am, look where I'm going. Juke right, juke left.", "order": 46197, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 8, "scene": 28, "line": "Andy, this is inappropriate. People died here, man.", "order": 46198, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 8, "scene": 28, "line": "Get the flag! Get the flag! Come on, Big Tuna. What you gonna do about it? We got a flag right here. Wee-hoo!", "order": 46199, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 8, "scene": 29, "line": "Spangler Springs is a mile this way.", "order": 46200, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 8, "scene": 29, "line": "Oh wow. So that's two miles if you incorporate the walk back.", "order": 46201, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 8, "scene": 29, "line": "Its' I mean come on.", "order": 46202, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 8, "scene": 29, "line": "I don't think I should walk anymore. You know all I had for breakfast was oatmeal, yogurt, coffee, orange juice and toast. Two poached eggs. And then half a sandwich on the bus.", "order": 46203, "speaker": "Phyllis Vance"},
  {"season": 8, "episode": 8, "scene": 29, "line": "Alright fine. You know what ' I guess this place just hasn't rubbed off on you the way I hoped it would. I'm still going. And I'm not going to ask anymore. I'm not even going to look back. I'm just going to assume that you're with me.", "order": 46204, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 8, "scene": 29, "line": "[Andy looks back to find everyone sitting] You said you weren't going to look back.", "order": 46205, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 8, "scene": 30, "line": "And why is Black Rock suddenly the paragon by which all hedge funds must now be compared?", "order": 46206, "speaker": "Robert California"},
  {"season": 8, "episode": 8, "scene": 30, "line": "I don't know.", "order": 46207, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 8, "scene": 30, "line": "Right. I mean you're an accountant. Those bogus prospectuses must drive you insane.", "order": 46208, "speaker": "Robert California"},
  {"season": 8, "episode": 8, "scene": 30, "line": "Yes. I am an accountant.", "order": 46209, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 8, "scene": 31, "line": "Dwight ' this is one of the archivists here. I thought maybe we could consult him.", "order": 46210, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 8, "scene": 31, "line": "Really?", "order": 46211, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 8, "scene": 31, "line": "Yeah.", "order": 46212, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 8, "scene": 31, "line": "Well. Anyone employed by the Gettysburg Industrial Complex is certainly going to want to keep quiet about the Battle of Schrute Farms.", "order": 46213, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 8, "scene": 31, "line": "Schrute Farms did you say? That is a fascinating little chapter of the Civil War.", "order": 46214, "speaker": "Archivist"},
  {"season": 8, "episode": 8, "scene": 31, "line": "You've heard about it?", "order": 46215, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 8, "scene": 31, "line": "YES! Ha! Prepare to be refuted! Go on.", "order": 46216, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 8, "scene": 32, "line": "There you go.", "order": 46217, "speaker": "Archivist"},
  {"season": 8, "episode": 8, "scene": 32, "line": "Families and sweethearts back home waited desperately for letters from the front.", "order": 46218, "speaker": "Narrator"},
  {"season": 8, "episode": 8, "scene": 32, "line": "Dearest mother I'm sorry it has been so long since my last letter. It is three months since I arrived at Schrute Farms and I fear I may never leave this place alive. Melvin Fifer Garris.", "order": 46219, "speaker": "Soldier"},
  {"season": 8, "episode": 8, "scene": 32, "line": "Hallowed ground.", "order": 46220, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 8, "scene": 32, "line": "But the Battle at Schrute Farms was no battle at all. It was a code used by pacifists from both North and South who turned the Pennsylvania farmhouse into an artistic community and a refuge from the war.", "order": 46221, "speaker": "Narrator"},
  {"season": 8, "episode": 8, "scene": 32, "line": "[on film] You have to understand. Poets, artists, dancers ' these kind of men preferred peace to war. These delicate lovely men found a place of refuge among the Schrutes at Schrute Farms. Amidst the macho brutality of war this was a place where dandies and dreamers could put on plays and sing tender ballads and dance in the moonlight. I like to think of Schrute Farms as the Underground Railroad for the sensitive' and well' fabulous.", "order": 46222, "speaker": "Amanda"},
  {"season": 8, "episode": 8, "scene": 32, "line": "Wow. This is so much better than the story you made up.", "order": 46223, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 8, "scene": 32, "line": "I've seen enough.", "order": 46224, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 8, "scene": 32, "line": "You're right. There should be a monument to this.", "order": 46225, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 8, "scene": 33, "line": "Never trust a cookie with a woman's name. Pecan Sandy. Lorna Doone. Madeline. They'll just break your heart.", "order": 46226, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 8, "scene": 33, "line": "[laughing] Kevin!", "order": 46227, "speaker": "Robert California"},
  {"season": 8, "episode": 8, "scene": 33, "line": "Robert I hate to interrupt. But I would love to pitch you one last idea. I call it the Big Mac idea.", "order": 46228, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 8, "scene": 33, "line": "What?!? No!", "order": 46229, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 8, "scene": 33, "line": "Big Mac idea. That sounds encouraging.", "order": 46230, "speaker": "Robert California"},
  {"season": 8, "episode": 8, "scene": 33, "line": "It's really, really good Robert. Let me explain.", "order": 46231, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 8, "scene": 33, "line": "No! This is not fair! This is my idea. He's trying to steal it because he's jealous of me.", "order": 46232, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 8, "scene": 33, "line": "Well what is the idea?", "order": 46233, "speaker": "Robert California"},
  {"season": 8, "episode": 8, "scene": 33, "line": "Every time you buy a Big Mac you set one ingredient aside. Then at the end of the week you have a free Big Mac. And you love it even more because you made it with your own hands.", "order": 46234, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 8, "scene": 33, "line": "You know what? Now I remember. That was your idea. [patting Kevin on the back] That is 100 percent your idea.", "order": 46235, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 8, "scene": 33, "line": "Oh my. It was just actually cookies the whole time?", "order": 46236, "speaker": "Robert California"},
  {"season": 8, "episode": 8, "scene": 34, "line": "[making pencil imprint] Dammit.", "order": 46237, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 8, "scene": 34, "line": "Hey.", "order": 46238, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 8, "scene": 34, "line": "You guys came. Where's everyone else?", "order": 46239, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 8, "scene": 34, "line": "Back at the bus. We were locked out. Phyllis is sitting on the ground eating a dirty sandwich.", "order": 46240, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 8, "scene": 34, "line": "Yeah I asked the bus driver to lock it because our stuff was in there. I guess he follows orders.", "order": 46241, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 8, "scene": 34, "line": "Yeah sorry everyone else didn't come. I think they're just tired. With holes in their shoes. And they have dysentery.", "order": 46242, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 8, "scene": 34, "line": "Even without an audience you're still at it.", "order": 46243, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 8, "scene": 34, "line": "What are you talking about?", "order": 46244, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 8, "scene": 34, "line": "Our office has a disease. And it goes by many names. Sarcasm. Snark. Wisecracks. You take things people care about and you make them feel lame about it with your jokes. That's what you did with this trip.", "order": 46245, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 8, "scene": 34, "line": "Andy ' this whole idea of our situation being just like war? It's just not true. We just work at a paper company. And you're our regional manager. And guess what man? You don't have to prove anything. We like you as regional manager. Andy if you don't believe me take a look at what's on my head. I'm wearing a very pink hat. I've been getting weird looks all day because I'm pretty sure 'DM does GB' means something kinda sexual. But guess who's wearing them? All of us. Just for you man. That's huge.", "order": 46246, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 8, "scene": 34, "line": "You don't like the hats?", "order": 46247, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 8, "scene": 34, "line": "They're terrible.", "order": 46248, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 8, "scene": 34, "line": "I hate myself.", "order": 46249, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 8, "scene": 34, "line": "They just didn't turn out how I wanted. In my head they were cooler, but they do look weird.", "order": 46250, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 8, "scene": 35, "line": "[voiceover the three walking] The world will little note, nor long remember the fight Jim and I had here at Gettysburg and that's good because I was basically wrong. I wanted my team to be, like, this army and I was their general. But I guess it's really more like they're just people who work in an office and I'm their manager. Yeah that's really probably a better analogy now that I think about it.", "order": 46251, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 8, "scene": 36, "line": "Abe and Mary are seated watching the show. [in Lincoln voice] Oh Mary this is wonderful' Ok Mary stop your scolding. I'll be quiet. [aside to the audience] I need her like I need a hole in the head! [scattered chuckles from the crowd] [Gabe raises his hand with a finger pointed in the shape of a gun to his head] BANG! Ooh [Gabe falls to the ground and the crowd claps, Gabe picks up the Lincoln hat, bows, runs to the crowd and trades it back for his pink 'DM does GB' hat and runs off]", "order": 46252, "speaker": "Gabe Lewis"},
  {"season": 8, "episode": 8, "scene": 37, "line": "Haha! There he is. You mind lending a hand? I'm typing up a memo to share your ideas with the board.", "order": 46253, "speaker": "Robert California"},
  {"season": 8, "episode": 8, "scene": 37, "line": "I can do that.", "order": 46254, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 8, "scene": 37, "line": "Great....ok.", "order": 46255, "speaker": "Robert California"},
  {"season": 8, "episode": 8, "scene": 37, "line": "Your arm feels good on my back.", "order": 46256, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 8, "scene": 38, "line": "For some reason, my boss is obsessed with cookies. It's not my specialty. My specialty is pizza. But I'm flexible.", "order": 46257, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 8, "scene": 39, "line": "Charge!", "order": 46258, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 8, "scene": 39, "line": "Isn't Gettysburg like three hours away?", "order": 46259, "speaker": "Kelly Kapoor"},
  {"season": 8, "episode": 8, "scene": 39, "line": "No. It's like two hours and forty-six minutes.", "order": 46260, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 8, "scene": 39, "line": "It's kind of a haul, so we should probably get going. And tuna, I made a special sandwich for you, guess which kind.", "order": 46261, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 8, "scene": 39, "line": "Tuna?", "order": 46262, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 8, "scene": 39, "line": "Yes! Tuna of the land. Turkey. With pesto.", "order": 46263, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 8, "scene": 39, "line": "And to drink?", "order": 46264, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 8, "scene": 39, "line": "Water.", "order": 46265, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 8, "scene": 39, "line": "Oh-ho, I am in.", "order": 46266, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 8, "scene": 40, "line": "I'm excited, I've actually never been to Gettysburg. Uh, my family was gonna go once, but then my brothers convinced me if was full of dead soldier zombies, so I freaked out and we turned the car around. But I'm older now, I can outrun a zombie.", "order": 46267, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 8, "scene": 41, "line": "Getting everyone on the bus, check. Giving everyone hats, check. Sexiest eastern European girls, uh, check.", "order": 46268, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 8, "scene": 42, "line": "Hey, Devil's Den is that way, we should check it out. That's where the snipers hid.", "order": 46269, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 8, "scene": 42, "line": "Please! Everyone and their mother goes to Devil's Den. It's the Olive Garden of Gettysburg.", "order": 46270, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 8, "scene": 42, "line": "Oh, I like Olive Garden.", "order": 46271, "speaker": "Phyllis Vance"},
  {"season": 8, "episode": 8, "scene": 42, "line": "Well, I meant that in a bad way. Obviously it tastes good, but it's not cool.", "order": 46272, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 8, "scene": 42, "line": "Where do all the cool kids go when they come to this consecrated ground?", "order": 46273, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 8, "scene": 42, "line": "That is what I'm showing you.", "order": 46274, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 8, "scene": 42, "line": "I know what you guys are all thinking right now: Hey, let's go pee on the grave of some confederate soldiers, but you know what? Joke's on you. It only makes the flowers grow back more beautifully.", "order": 46275, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 8, "scene": 43, "line": "Best thing you can do for the deceased is to pee on their graves. Then you dance and work the urine into the root systems. Doesn't hurt to have a smile on your face, either.", "order": 46276, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 8, "scene": 44, "line": "Guys! Over here, we found the bus.", "order": 46277, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 8, "scene": 44, "line": "Open the door, Jim.", "order": 46278, "speaker": "Phyllis Vance"},
  {"season": 8, "episode": 8, "scene": 44, "line": "It's locked.", "order": 46279, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 8, "scene": 44, "line": "Are you sure? Yeah, it's locked.", "order": 46280, "speaker": "Phyllis Vance"},
  {"season": 8, "episode": 8, "scene": 44, "line": "It's not locked, gimme that...", "order": 46281, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 8, "scene": 44, "line": "That's weird, if it's not locked, why isn't it opening?", "order": 46282, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 8, "scene": 44, "line": "It's, it's latched closed.", "order": 46283, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 8, "scene": 44, "line": "Ok, you know what? Andy probably has the number for this guy. I'm gonna find him.", "order": 46284, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 8, "scene": 44, "line": "I'll come with you.", "order": 46285, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 8, "scene": 44, "line": "Ow! Ee! I sat on my sandwich.", "order": 46286, "speaker": "Phyllis Vance"},
  {"season": 8, "episode": 8, "scene": 44, "line": "And that's how the printing press was invented.", "order": 46287, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 8, "scene": 45, "line": "[enters office doing Michael Jackson impression] Everybody beat it. Don't beat it, stay where you are.", "order": 46288, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 8, "scene": 45, "line": "There you are. Dillinger Graphics said their shipment is late.", "order": 46289, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 8, "scene": 45, "line": "What did you say?", "order": 46290, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 8, "scene": 45, "line": "Nothing, I just-", "order": 46291, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 8, "scene": 45, "line": "What would you say?", "order": 46292, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 8, "scene": 45, "line": "That it'll be the first shipment out today.", "order": 46293, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 8, "scene": 45, "line": "That is exactly the right thing to say! Do it! Git-er-done! Phyllis, you just married Joe Order, you're new name is Phyllis Order [fill this order], get it?", "order": 46294, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 8, "scene": 45, "line": "I don't get it.", "order": 46295, "speaker": "Phyllis Vance"},
  {"season": 8, "episode": 8, "scene": 45, "line": "Jim, in regards to our high-five scheduled for four PM today, it has been moved up to now. Yes.", "order": 46296, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 8, "scene": 45, "line": "We didn't have one scheduled.", "order": 46297, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 9, "scene": 1, "line": "[knocks water bottle on to Jim's desk] Oh, little help. Wow your hair is really thinning.", "order": 46298, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 9, "scene": 2, "line": "Standing is proven to be healthier, increases productivity and just looks cooler. Picture someone doing something heroic. Now was he sitting or standing? Not counting FDR.", "order": 46299, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 9, "scene": 3, "line": "Every second you sit there is an hour off your life. Look at all of you. I feel like you're in a suicide cult.", "order": 46300, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 9, "scene": 3, "line": "No. No. No. You're way off on that one.", "order": 46301, "speaker": "Creed Bratton"},
  {"season": 8, "episode": 9, "scene": 3, "line": "Oh yeah?", "order": 46302, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 9, "scene": 3, "line": "Are you really comfortable standing there? I tried one of those and I just never hit the sweet spot.", "order": 46303, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 9, "scene": 3, "line": "Hey guys let's just all admit it. Ok? Dwight's better than us. He had the guts to stop sitting. And he's never, ever going to go back on it right?", "order": 46304, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 9, "scene": 3, "line": "That's right Jim.", "order": 46305, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 9, "scene": 4, "line": "Hey man.", "order": 46306, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 9, "scene": 4, "line": "[jumping up from an almost sitting position] Hey.", "order": 46307, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 9, "scene": 4, "line": "Taking a load off huh?", "order": 46308, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 9, "scene": 4, "line": "No. Putting a load on more like it.", "order": 46309, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 9, "scene": 4, "line": "Good luck with that.", "order": 46310, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 9, "scene": 4, "line": "Last thing I need is luck. [hunches down again]", "order": 46311, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 9, "scene": 5, "line": "[to Angela] Thank you so much. [hums]", "order": 46312, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 9, "scene": 5, "line": "[sees a pole poking out Dwight's pant leg] What are you up to? Oops look out! [grabs Dwight's wallet]", "order": 46313, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 9, "scene": 5, "line": "What are you doing? Give that back. Come on!", "order": 46314, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 9, "scene": 5, "line": "Prank! [throws money from Dwight's wallet on the floor]", "order": 46315, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 9, "scene": 5, "line": "Oh that's real creative.", "order": 46316, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 9, "scene": 5, "line": "You know what it's not my best. Because you could just easily bend over and pick it up right?", "order": 46317, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 9, "scene": 5, "line": "I could, but I just don't feel like it loser.", "order": 46318, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 9, "scene": 5, "line": "Because you're sitting?", "order": 46319, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 9, "scene": 5, "line": "Standing.", "order": 46320, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 9, "scene": 5, "line": "Ok.", "order": 46321, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 9, "scene": 5, "line": "Don't!", "order": 46322, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 9, "scene": 5, "line": "Because you're sitting?", "order": 46323, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 9, "scene": 5, "line": "Standing.", "order": 46324, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 9, "scene": 5, "line": "You know I have to do this.", "order": 46325, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 9, "scene": 5, "line": "I know. [Jim pushes him over, Dwight screams]", "order": 46326, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 9, "scene": 6, "line": "[signing his name] Andrew' Baines' Bernard.", "order": 46327, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 9, "scene": 6, "line": "I think you could just do Andy Bernard.", "order": 46328, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 9, "scene": 6, "line": "Andrew Baines Bernard. I love filling these out. Because it's like taking a test, but I know the answers.", "order": 46329, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 9, "scene": 6, "line": "[loudly knocking on the window] In four seconds my wife is going to be coming through that door. I told her she could work here. Under no circumstances can that be allowed to happen. [to Mrs. California] There you are! Honey how on earth could you miss the elevator?", "order": 46330, "speaker": "Robert California"},
  {"season": 8, "episode": 9, "scene": 6, "line": "What was that?", "order": 46331, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 9, "scene": 6, "line": "I don't know. He wasn't talking to me. But if I were you I wouldn't hire his wife.", "order": 46332, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 9, "scene": 7, "line": "That's why my foundation ' The Dream for a Wish Foundation ' is going to put them out of business. They're not going to know what hit them.", "order": 46333, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 9, "scene": 7, "line": "Everyone. I'd like to introduce my wife Mrs. Robert California. Mrs. California this is everyone.", "order": 46334, "speaker": "Robert California"},
  {"season": 8, "episode": 9, "scene": 7, "line": "Hello. Oh call me Susan please.", "order": 46335, "speaker": "Susan California"},
  {"season": 8, "episode": 9, "scene": 7, "line": "Last night at dinner Susan and I were talking about her returning to work. So she's here today to see if there's a good fit. Something tells me it just might work out. This is Andy Bernard the regional manager.", "order": 46336, "speaker": "Robert California"},
  {"season": 8, "episode": 9, "scene": 7, "line": "Hello nice to meet you.", "order": 46337, "speaker": "Susan California"},
  {"season": 8, "episode": 9, "scene": 7, "line": "Hey there. It's a pleasure.", "order": 46338, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 9, "scene": 7, "line": "Andy would you be so kind as to show Susan around the office. Show her the various departments. Find a place where she'll shine.", "order": 46339, "speaker": "Robert California"},
  {"season": 8, "episode": 9, "scene": 7, "line": "I would love to. But I have to be completely honest with you guys. We're 100 percent staffed up.", "order": 46340, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 9, "scene": 7, "line": "Andy. Let's see if there's a good fit first. Then we'll talk.", "order": 46341, "speaker": "Robert California"},
  {"season": 8, "episode": 9, "scene": 7, "line": "Alright.", "order": 46342, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 9, "scene": 8, "line": "Knock, knock, knock, knock, knock.", "order": 46343, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 9, "scene": 8, "line": "You don't have to say it if you're doing it.", "order": 46344, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 9, "scene": 8, "line": "[laughs] Remember how you said that you were looking in the office to do calisthenics and aerobics?", "order": 46345, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 9, "scene": 8, "line": "Uh weight lifting yeah.", "order": 46346, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 9, "scene": 8, "line": "Well today's your lucky day. I have opened in this very office building Dwight Schrute's Gym for Muscles. You wanna take a look?", "order": 46347, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 9, "scene": 8, "line": "I'll take a look.", "order": 46348, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 9, "scene": 8, "line": "Your path from obesity begins right here!", "order": 46349, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 9, "scene": 9, "line": "I've been meaning to join a gym. [narrating over a montage of scenes with him and Val] For my health. I used to say I wanted to live long enough to see a black President. I didn't realize how easy that would be. So now I want to live long enough to see a really, really gay President. Or a supermodel President. I want to see all the different kinds of Presidents.", "order": 46350, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 9, "scene": 10, "line": "Obese people in my office are a drain on resources. Chairs wear out faster, it takes more Freon to keep them cool, they flush the toilets more often, plus their massive BMs bust the rivets on my pipes. But a gym turns fat into cash.", "order": 46351, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 9, "scene": 11, "line": "Alright! The DM Express is pulling out.", "order": 46352, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 9, "scene": 11, "line": "Well it was really nice to meet you Brian.", "order": 46353, "speaker": "Susan California"},
  {"season": 8, "episode": 9, "scene": 11, "line": "Actually Ryan.", "order": 46354, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 9, "scene": 11, "line": "Oh, Ryan.", "order": 46355, "speaker": "Susan California"},
  {"season": 8, "episode": 9, "scene": 12, "line": "Bitttch!", "order": 46356, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 9, "scene": 13, "line": "We will start here at Erinville.", "order": 46357, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 9, "scene": 13, "line": "Hi.", "order": 46358, "speaker": "Susan California"},
  {"season": 8, "episode": 9, "scene": 13, "line": "Hoo-hoo!", "order": 46359, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 9, "scene": 13, "line": "Reception. Your classic one-man operation.", "order": 46360, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 9, "scene": 13, "line": "Although I was watching a movie and Merryl Streep had two secretaries.", "order": 46361, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 9, "scene": 13, "line": "I was watching a movie and a bunch of apes took over San Francisco. Just saying.", "order": 46362, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 9, "scene": 13, "line": "Two secretaries could convey a sense of importance and success to our clients. Certainly worth exploring; wouldn't you agree Andy?", "order": 46363, "speaker": "Robert California"},
  {"season": 8, "episode": 9, "scene": 13, "line": "I'll explore exploring it.", "order": 46364, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 9, "scene": 13, "line": "Good. It seems like you have this under control. So I'll just duck out and run the company for a while.", "order": 46365, "speaker": "Robert California"},
  {"season": 8, "episode": 9, "scene": 13, "line": "Ok uh next stop on the tour sales. Here we go. Station stop Jim Halpert. Oh boy our resident truth teller. [Jim laughs nervously] Alright have at it. Let 'er rip. What do you hate about this place?", "order": 46366, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 9, "scene": 13, "line": "Well it's sales. So you have to be able to live in the world of rejection.", "order": 46367, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 9, "scene": 13, "line": "Maybe you do. Hi. I'm Dwight. Sales is really not so hard ok? It's paper. We have it and they want it so bad they are willing to pay for it.", "order": 46368, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 9, "scene": 13, "line": "Jim?", "order": 46369, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 9, "scene": 13, "line": "Well it's not that easy. It's kind of sometimes difficult.", "order": 46370, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 9, "scene": 13, "line": "It's the second easiest job in the world. [to camera] Being a mom.", "order": 46371, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 9, "scene": 13, "line": "I love shopping and sales is just the other side of that.", "order": 46372, "speaker": "Susan California"},
  {"season": 8, "episode": 9, "scene": 13, "line": "That is true.", "order": 46373, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 9, "scene": 13, "line": "You know if you joined our sales department you would be working very closely with our department head Mr. Dwight Schrute.", "order": 46374, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 9, "scene": 13, "line": "That's me.", "order": 46375, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 9, "scene": 13, "line": "Dwight why don't you tell Susan about some of your hobbies. Survival skills. Ranking of animals.", "order": 46376, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 9, "scene": 13, "line": "Maybe over a beer after she's closed her first sale [makes gun click noise].", "order": 46377, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 9, "scene": 13, "line": "Well make that a red wine and I'll tell you my animal rankings [makes gun click noise back].", "order": 46378, "speaker": "Susan California"},
  {"season": 8, "episode": 9, "scene": 14, "line": "Oh how am I supposed to pull this off? [montage of office workers in friendly interactions with Susan]. The entire office is being nice to her than they've ever been to me. What I wouldn't give for one of Phyllis' classic room clearing farts right now.", "order": 46379, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 9, "scene": 15, "line": "Ready? What do you think?", "order": 46380, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 9, "scene": 15, "line": "Um no. This is not a gym. This is like a scene out of Saw 5.", "order": 46381, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 9, "scene": 15, "line": "You haven't even looked around yet. Look. Gravel bucket squat yoke [squats, grunts and lifts] Right? Dedicated phone book ripping station [tries and fails to tear a phone book in half]. You ever cut tin before? Five yards in and your forearms will be on fire. Plus I will buy the tin back from you that you cut for two cents a yard. Now let's go over membership. I'm going to need the first month's and the last month's up front. $49 per month. But every third month is $59 a month. But the fourth month is a discount month at $19 per month'", "order": 46382, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 9, "scene": 15, "line": "Obviously you know how a gym works. So you know I'm not going to pay money for this. You want people to come to your gym? Make a real gym.", "order": 46383, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 9, "scene": 15, "line": "Darryl... [picks up sledgehammer and swings it at a hanging tire]", "order": 46384, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 9, "scene": 15, "line": "Make a real gym.", "order": 46385, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 9, "scene": 16, "line": "I was thinking accounting might be a good fit because I really clicked with Oscar and I do have some experience in bookkeeping.", "order": 46386, "speaker": "Susan California"},
  {"season": 8, "episode": 9, "scene": 16, "line": "But we already have a surplus of accountants as it is.", "order": 46387, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 9, "scene": 16, "line": "Uh I could work in customer service. HR even.", "order": 46388, "speaker": "Susan California"},
  {"season": 8, "episode": 9, "scene": 16, "line": "Those are fully staffed as well.", "order": 46389, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 9, "scene": 16, "line": "Are you telling me that there's no one in this entire office that could use an extra pair of hands?", "order": 46390, "speaker": "Robert California"},
  {"season": 8, "episode": 9, "scene": 16, "line": "[laughing nervously] Yes.", "order": 46391, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 9, "scene": 16, "line": "I am the CEO and I am telling an employee of mine what to do.", "order": 46392, "speaker": "Robert California"},
  {"season": 8, "episode": 9, "scene": 16, "line": "Let me beat around the bush for a second. Our chairman of the board has some very strong feelings about-", "order": 46393, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 9, "scene": 16, "line": "Robert this is very uncomfortable. Obviously there's no place for me here. So thank you for trying. Why don't we just let it go.", "order": 46394, "speaker": "Susan California"},
  {"season": 8, "episode": 9, "scene": 16, "line": "No. Absolutely not. Andrew there must be something you can do. Think.", "order": 46395, "speaker": "Robert California"},
  {"season": 8, "episode": 9, "scene": 16, "line": "There are lots of considerations.", "order": 46396, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 9, "scene": 16, "line": "I would be eternally grateful. It's not a bad thing to have the CEO owe you one.", "order": 46397, "speaker": "Robert California"},
  {"season": 8, "episode": 9, "scene": 16, "line": "Alright. Well then welcome aboard.", "order": 46398, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 9, "scene": 17, "line": "So what happens next?", "order": 46399, "speaker": "Susan California"},
  {"season": 8, "episode": 9, "scene": 17, "line": "We get you over to HR and then we get you set up with accounting. [phone rings] Hang on. Hello?", "order": 46400, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 9, "scene": 17, "line": "[on phone] Say hello grandma.", "order": 46401, "speaker": "Robert California"},
  {"season": 8, "episode": 9, "scene": 17, "line": "Hi gam-gam.", "order": 46402, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 9, "scene": 17, "line": "Hold one finger up to Susan. You'll just be a second. You numbskull. You were given a very simple task. I could not have been more clear with you. Now say, 'are you taking your medicine?'", "order": 46403, "speaker": "Robert California"},
  {"season": 8, "episode": 9, "scene": 17, "line": "Are you taking your med-meds?", "order": 46404, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 9, "scene": 17, "line": "Why can you not say exactly what I tell you? Undo this. Undo it. [Robert and Andy hang up phones]", "order": 46405, "speaker": "Robert California"},
  {"season": 8, "episode": 9, "scene": 17, "line": "You didn't say goodbye to your grandma.", "order": 46406, "speaker": "Susan California"},
  {"season": 8, "episode": 9, "scene": 17, "line": "We promised we'd never say goodbye.", "order": 46407, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 9, "scene": 18, "line": "Attention! I am now accepting memberships for Dwight Schrute's Gym for Muscles. The first 20 members can take advantage of the pay what you weigh promotion.", "order": 46408, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 9, "scene": 18, "line": "All I need is another gym. As if I don't have enough drama.", "order": 46409, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 9, "scene": 18, "line": "We have a gym at home. It's called the bedroom.", "order": 46410, "speaker": "Phyllis Vance"},
  {"season": 8, "episode": 9, "scene": 18, "line": "Who's spotting who? Don't use the bike in the corner. That's Bruce's. Jeremy says'", "order": 46411, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 9, "scene": 18, "line": "Enough Oscar, enough.", "order": 46412, "speaker": "Angela Martin"},
  {"season": 8, "episode": 9, "scene": 18, "line": "Kelly?", "order": 46413, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 9, "scene": 18, "line": "I have these new sneakers which are basically like a gym for your feet.", "order": 46414, "speaker": "Kelly Kapoor"},
  {"season": 8, "episode": 9, "scene": 19, "line": "That's okay. I know how to build a business. You gotta get the black people to do it to get the white people to do it. Then you gotta get the black people to stop doing it. One step at a time.", "order": 46415, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 9, "scene": 20, "line": "Did you bring your passport with you?", "order": 46416, "speaker": "Toby Flenderson"},
  {"season": 8, "episode": 9, "scene": 20, "line": "Who walks around with their passport?", "order": 46417, "speaker": "Susan California"},
  {"season": 8, "episode": 9, "scene": 20, "line": "Well, I do. Always ready for adventure.", "order": 46418, "speaker": "Toby Flenderson"},
  {"season": 8, "episode": 9, "scene": 20, "line": "Have you had any?", "order": 46419, "speaker": "Susan California"},
  {"season": 8, "episode": 9, "scene": 20, "line": "No.", "order": 46420, "speaker": "Toby Flenderson"},
  {"season": 8, "episode": 9, "scene": 20, "line": "[sees Susan is busy in the annex] Hey, uh, guys. So Robert doesn't want his wife working here. So now that she is, we have to drive her away.", "order": 46421, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 9, "scene": 20, "line": "Andy, if he didn't want her working here then why did you hire her?", "order": 46422, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 9, "scene": 20, "line": "it was a mistake. But one that can be fixed by all of us being mean to her.", "order": 46423, "speaker": "Andy Bernard & Oscar Martinez"},
  {"season": 8, "episode": 9, "scene": 20, "line": "How can we do that? She's simply wonderful.", "order": 46424, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 9, "scene": 20, "line": "This is all pathetic.", "order": 46425, "speaker": "Angela Martin"},
  {"season": 8, "episode": 9, "scene": 20, "line": "I am not asking, I am ordering.", "order": 46426, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 9, "scene": 20, "line": "How mean are we talking about?", "order": 46427, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 9, "scene": 20, "line": "It would be great if she were gone by lunch. Erin, you're up. Find her a place to work, but not too comfortable.", "order": 46428, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 9, "scene": 20, "line": "Got it. I know exactly which stapler to give her.", "order": 46429, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 9, "scene": 21, "line": "Here you go. [gives Susan a tiny stapler]", "order": 46430, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 9, "scene": 21, "line": "Um, do you think it would be possible to move to that desk over there? Because this seems a bit cramped and I don't have a computer.", "order": 46431, "speaker": "Susan California"},
  {"season": 8, "episode": 9, "scene": 21, "line": "Well that's a sales desk.", "order": 46432, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 9, "scene": 21, "line": "Yeah. You need to be over here with us accountants. Just sit here and be quiet. And if you have a question, just raise your hand. But I'm gonna save you some time, sweetie, and give you the answer now. I. Don't. Know.", "order": 46433, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 9, "scene": 22, "line": "No, I don't think we should be trying to make this place seem unpleasant. I think we should let this place just crush her spirit by itself. I mean, it knows what it's doing.", "order": 46434, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 9, "scene": 23, "line": "That's Gerald. [hands phone to Jim]", "order": 46435, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 9, "scene": 23, "line": "Oh, wow. So cute.", "order": 46436, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 9, "scene": 23, "line": "Oh, that is so cute-", "order": 46437, "speaker": "Susan California"},
  {"season": 8, "episode": 9, "scene": 23, "line": "[grabs phone] Aw... He's so tiny. Is he in a ladle?", "order": 46438, "speaker": "Phyllis Vance"},
  {"season": 8, "episode": 9, "scene": 23, "line": "Yeah, he's in a ladle.", "order": 46439, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 9, "scene": 23, "line": "That's adorable. How on earth did you get him in a ladle?", "order": 46440, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 9, "scene": 23, "line": "He did it himself.", "order": 46441, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 9, "scene": 23, "line": "Is that ladle stainless or...", "order": 46442, "speaker": "Phyllis Vance"},
  {"season": 8, "episode": 9, "scene": 23, "line": "Oh, stainless. Williams Sonoma.", "order": 46443, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 9, "scene": 23, "line": "Excuse me, waiter, there's a dog in my soup. [Jim chuckles]", "order": 46444, "speaker": "Susan California"},
  {"season": 8, "episode": 9, "scene": 23, "line": "It's not that kind of ladle.", "order": 46445, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 9, "scene": 23, "line": "Very cute. [holds out the phone]", "order": 46446, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 9, "scene": 23, "line": "Aw, let me see-", "order": 46447, "speaker": "Susan California"},
  {"season": 8, "episode": 9, "scene": 23, "line": "[grabs phone] Ah, just the battery on these things. I'm sorry, I've got to recharge this.", "order": 46448, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 9, "scene": 24, "line": "I get it. Last time I had a job, I remember I hated the boss' wife. Of course, she was married to Robert.", "order": 46449, "speaker": "Susan California"},
  {"season": 8, "episode": 9, "scene": 25, "line": "Your employees don't seem to be taking to me.", "order": 46450, "speaker": "Susan California"},
  {"season": 8, "episode": 9, "scene": 25, "line": "Andy! Andy, could you step in here, please? We'll fix this.", "order": 46451, "speaker": "Robert California"},
  {"season": 8, "episode": 9, "scene": 25, "line": "[in robot voice] What is going on?", "order": 46452, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 9, "scene": 25, "line": "[chuckles] Please. Andrew, my wife... has brought up an issue that requires your attention. So, uh, if you'll excuse me, I should step out, get to the ATM before that homeless man sets up camp for the night.", "order": 46453, "speaker": "Robert California"},
  {"season": 8, "episode": 9, "scene": 25, "line": "Okey-dokey, artichokey. You hate it, don't you?", "order": 46454, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 9, "scene": 25, "line": "Do you not want me here?", "order": 46455, "speaker": "Susan California"},
  {"season": 8, "episode": 9, "scene": 25, "line": "Why would you say that?", "order": 46456, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 9, "scene": 25, "line": "It's okay. I understand. I'm not sure how I would feel about having the boss' wife work for me either.", "order": 46457, "speaker": "Susan California"},
  {"season": 8, "episode": 9, "scene": 25, "line": "It's not that, exactly.", "order": 46458, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 9, "scene": 25, "line": "But it is something.", "order": 46459, "speaker": "Susan California"},
  {"season": 8, "episode": 9, "scene": 25, "line": "Let's just say that if you don't want to work here, I am totally prepared to respect that.", "order": 46460, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 9, "scene": 25, "line": "Why would you not want me working here?", "order": 46461, "speaker": "Susan California"},
  {"season": 8, "episode": 9, "scene": 25, "line": "I don't know. [chuckles]", "order": 46462, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 9, "scene": 25, "line": "I think I understand what you're going through. My husband can be a very difficult man to read, can't he?", "order": 46463, "speaker": "Susan California"},
  {"season": 8, "episode": 9, "scene": 25, "line": "Yes! The dude is an enigma.", "order": 46464, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 9, "scene": 25, "line": "Mm-hmm. So he is mixed up in this, isn't he?", "order": 46465, "speaker": "Susan California"},
  {"season": 8, "episode": 9, "scene": 25, "line": "Ah... [stammers]", "order": 46466, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 9, "scene": 25, "line": "Got it.", "order": 46467, "speaker": "Susan California"},
  {"season": 8, "episode": 9, "scene": 26, "line": "What do you think you're doing?", "order": 46468, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 9, "scene": 26, "line": "Getting my sweat on.", "order": 46469, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 9, "scene": 26, "line": "I build you this temple to the human body, and you're lifting what, five pounds?", "order": 46470, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 9, "scene": 26, "line": "I just did, like, 35 minutes on the treadmill.", "order": 46471, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 9, "scene": 26, "line": "I was watching you. You want to know how long it really was?", "order": 46472, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 9, "scene": 26, "line": "No.", "order": 46473, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 9, "scene": 26, "line": "Eight minutes!", "order": 46474, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 9, "scene": 26, "line": "Dude, I'm gonna be here all week, right? Five days a week, I figure I'll start slow.", "order": 46475, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 9, "scene": 26, "line": "Is that the same philosophy you apply to buffalo wings? [points] I want you to bring that same buffalo wing passion to this gym! I'm gonna make you look like Lebron James!", "order": 46476, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 9, "scene": 26, "line": "It's Lejon Brames.", "order": 46477, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 9, "scene": 26, "line": "That's what I said.", "order": 46478, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 9, "scene": 26, "line": "You know, get it right.", "order": 46479, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 9, "scene": 26, "line": "I know.", "order": 46480, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 9, "scene": 27, "line": "[to Susan] Cameras...", "order": 46481, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 9, "scene": 27, "line": "[enters] Oh, still here. Terrific.", "order": 46482, "speaker": "Robert California"},
  {"season": 8, "episode": 9, "scene": 27, "line": "Andy and I had a great conversation. And I just want to let you know that I am not going to be working here.", "order": 46483, "speaker": "Susan California"},
  {"season": 8, "episode": 9, "scene": 27, "line": "Well that's a shame.", "order": 46484, "speaker": "Robert California"},
  {"season": 8, "episode": 9, "scene": 27, "line": "It just was one of those things-", "order": 46485, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 9, "scene": 27, "line": "Is it? Because we talked about it and it seems like that might be what you want. Which is fine.", "order": 46486, "speaker": "Susan California"},
  {"season": 8, "episode": 9, "scene": 27, "line": "Where did this come from? Did Andy say that?", "order": 46487, "speaker": "Robert California"},
  {"season": 8, "episode": 9, "scene": 27, "line": "No, he did not, but I could tell he was struggling to understand what was expected of him.", "order": 46488, "speaker": "Susan California"},
  {"season": 8, "episode": 9, "scene": 27, "line": "What's the struggle? He made a great call, hired a great person.", "order": 46489, "speaker": "Robert California"},
  {"season": 8, "episode": 9, "scene": 27, "line": "You sure about that?", "order": 46490, "speaker": "Susan California"},
  {"season": 8, "episode": 9, "scene": 27, "line": "Absolutely.", "order": 46491, "speaker": "Robert California"},
  {"season": 8, "episode": 9, "scene": 27, "line": "No games?", "order": 46492, "speaker": "Susan California"},
  {"season": 8, "episode": 9, "scene": 27, "line": "No games.", "order": 46493, "speaker": "Robert California"},
  {"season": 8, "episode": 9, "scene": 27, "line": "Because Andy seemed to think there was some information he was not able to share with me.", "order": 46494, "speaker": "Susan California"},
  {"season": 8, "episode": 9, "scene": 27, "line": "Okay, enough of this. I mean, really, enough. It's gotten way out of hand. Andrew, this is my wife. Whatever privacy you were trying to protect between us, I thank you for. But it is not welcome right now. Just be honest.", "order": 46495, "speaker": "Robert California"},
  {"season": 8, "episode": 9, "scene": 27, "line": "Andy?", "order": 46496, "speaker": "Susan California"},
  {"season": 8, "episode": 9, "scene": 27, "line": "Yes?", "order": 46497, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 9, "scene": 27, "line": "Why do you keep looking at my husband?", "order": 46498, "speaker": "Susan California"},
  {"season": 8, "episode": 9, "scene": 27, "line": "Andy, just answer the question.", "order": 46499, "speaker": "Robert California"},
  {"season": 8, "episode": 9, "scene": 27, "line": "No games?", "order": 46500, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 9, "scene": 27, "line": "No games.", "order": 46501, "speaker": "Robert California"},
  {"season": 8, "episode": 9, "scene": 27, "line": "Okay. I mean, it was kind of funny, actually. Robert storms in and he says, 'in four seconds, my wife's gonna be in here. I told her she could work here. Under no circumstances can that be allowed to happen.' [laughs]", "order": 46502, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 9, "scene": 27, "line": "You lying son of a bitch!", "order": 46503, "speaker": "Robert California"},
  {"season": 8, "episode": 9, "scene": 28, "line": "Tell my wife you're lying.", "order": 46504, "speaker": "Robert California"},
  {"season": 8, "episode": 9, "scene": 28, "line": "I am lying. I am a compulsive liar.", "order": 46505, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 9, "scene": 28, "line": "That's not true, is it?", "order": 46506, "speaker": "Susan California"},
  {"season": 8, "episode": 9, "scene": 28, "line": "No, it's not. I tell the truth most of the time and I was just telling the truth about your husband. Jim knows. He was there.", "order": 46507, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 9, "scene": 28, "line": "This is preposterous.", "order": 46508, "speaker": "Robert California"},
  {"season": 8, "episode": 9, "scene": 28, "line": "Robert...", "order": 46509, "speaker": "Susan California"},
  {"season": 8, "episode": 9, "scene": 28, "line": "Let's get Jim in here. [Jim shoves out of fram in his chair, Robert looks for Jim] Where's Jim?", "order": 46510, "speaker": "Robert California"},
  {"season": 8, "episode": 9, "scene": 28, "line": "He just rolled out and crawled out.", "order": 46511, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 9, "scene": 28, "line": "Can you call security, tell 'em to keep an eye out for him? Time to settle this.", "order": 46512, "speaker": "Robert California"},
  {"season": 8, "episode": 9, "scene": 29, "line": "[to Hank who is closing the gate] Oh wait, wait, wait! Hold on, hold on. I just need to get through. Just real quick. Real quick, open... uh, okay. [runs]", "order": 46513, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 9, "scene": 30, "line": "[sees Jim's abandoned car] That's weird. Well, he's gotta be around here somewhere.", "order": 46514, "speaker": "Robert California"},
  {"season": 8, "episode": 9, "scene": 31, "line": "[shoe falls while climbing ladder] Oh. Ow. Ow. Ow. [grunts] Creed. I was never here, all right?", "order": 46515, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 9, "scene": 31, "line": "Okay. What about your friend?", "order": 46516, "speaker": "Creed Bratton"},
  {"season": 8, "episode": 9, "scene": 31, "line": "Oh boy.", "order": 46517, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 9, "scene": 32, "line": "This is my gym, my rules. You do exactly as I say, no questions asked.", "order": 46518, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 9, "scene": 32, "line": "Yeah, I'm gonna ask questions and I might not do what you say.", "order": 46519, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 9, "scene": 32, "line": "First thing, we're gonna stretch the pelvic bowl. Ready? Get down on the floor.", "order": 46520, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 9, "scene": 32, "line": "Okay, I'm not doing that.", "order": 46521, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 9, "scene": 32, "line": "Really? Too embarrassing for you? Huh? You wish that every exercise was strutting around the gym like the Fonz? Well how do you think the Fonz got so cool? He stretched his pelvic bowl!", "order": 46522, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 9, "scene": 32, "line": "I didn't know Darryl joined.", "order": 46523, "speaker": "Gabe Lewis"},
  {"season": 8, "episode": 9, "scene": 32, "line": "Looks that way.", "order": 46524, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 9, "scene": 32, "line": "Nice pelvic bowl. Deep.", "order": 46525, "speaker": "Gabe Lewis"},
  {"season": 8, "episode": 9, "scene": 33, "line": "Hello Jim. [Jim tries to escape]", "order": 46526, "speaker": "Robert California"},
  {"season": 8, "episode": 9, "scene": 33, "line": "[grabs Jim's leg] No, no. No, no, no! Don't! Damn it, Tuna!", "order": 46527, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 9, "scene": 34, "line": "So I think you know what we want to know.", "order": 46528, "speaker": "Robert California"},
  {"season": 8, "episode": 9, "scene": 34, "line": "Do I?", "order": 46529, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 9, "scene": 34, "line": "Yes.", "order": 46530, "speaker": "Robert California"},
  {"season": 8, "episode": 9, "scene": 34, "line": "[mutters] I know, I do.", "order": 46531, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 9, "scene": 34, "line": "Jim, I've had enough of your unhelpfulness.", "order": 46532, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 9, "scene": 34, "line": "I really wish I could help. I'm not really sure how I can...", "order": 46533, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 9, "scene": 34, "line": "For God's sake, did Robert tell you that he didn't want me working here?", "order": 46534, "speaker": "Susan California"},
  {"season": 8, "episode": 9, "scene": 34, "line": "That's the thing. See, I didn't want to get in the middle of this. Still don't. But here's something: I feel that maybe, if we take a step back, this is a simple case of anxiety to work with a spouse.", "order": 46535, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 9, "scene": 34, "line": "[laughs] I think we've got ourselves an answer.", "order": 46536, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 9, "scene": 34, "line": "No.", "order": 46537, "speaker": "Robert California"},
  {"season": 8, "episode": 9, "scene": 34, "line": "That makes complete sense. Thank you all for a wonderful day.", "order": 46538, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 9, "scene": 34, "line": "Perfect!", "order": 46539, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 9, "scene": 34, "line": "Jim...", "order": 46540, "speaker": "Susan California"},
  {"season": 8, "episode": 9, "scene": 34, "line": "Yes?", "order": 46541, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 9, "scene": 34, "line": "Answer the question, please.", "order": 46542, "speaker": "Susan California"},
  {"season": 8, "episode": 9, "scene": 34, "line": "[sighs] I don't know what you want me to tell you. To be really honest, my wife works here. And I love it. She literally makes me work harder. She makes me smarter. She makes me remember why I'm here. And between us, she's on maternity leave right now, and I would love to leave this room and see her face. I would love it. I don't know how this helps, but it's just what I'm thinking.", "order": 46543, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 9, "scene": 34, "line": "All right. Thanks.", "order": 46544, "speaker": "Robert California"},
  {"season": 8, "episode": 9, "scene": 34, "line": "Yes. All right. Great. [leaves]", "order": 46545, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 9, "scene": 34, "line": "Uh...", "order": 46546, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 9, "scene": 34, "line": "Yeah.", "order": 46547, "speaker": "Robert California"},
  {"season": 8, "episode": 9, "scene": 34, "line": "Yep.", "order": 46548, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 9, "scene": 35, "line": "[Robert holds the door, she walks through] Thank you. [leaves]", "order": 46549, "speaker": "Susan California"},
  {"season": 8, "episode": 9, "scene": 35, "line": "[claps Andy's shoulder] Heh.", "order": 46550, "speaker": "Robert California"},
  {"season": 8, "episode": 9, "scene": 36, "line": "Andy.", "order": 46551, "speaker": "Susan California"},
  {"season": 8, "episode": 9, "scene": 36, "line": "Hey, Mrs. California.", "order": 46552, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 9, "scene": 36, "line": "Hey. Uh, I'm sorry about the position you were put in today.", "order": 46553, "speaker": "Susan California"},
  {"season": 8, "episode": 9, "scene": 36, "line": "You know, honestly, we all wanted you to work there. Except for him. Especially me.", "order": 46554, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 9, "scene": 36, "line": "Yeah, I thought we really hit it off.", "order": 46555, "speaker": "Susan California"},
  {"season": 8, "episode": 9, "scene": 36, "line": "Yeah.", "order": 46556, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 9, "scene": 36, "line": "Well, different circumstances.", "order": 46557, "speaker": "Susan California"},
  {"season": 8, "episode": 9, "scene": 36, "line": "Yeah.", "order": 46558, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 9, "scene": 36, "line": "Who knows?", "order": 46559, "speaker": "Susan California"},
  {"season": 8, "episode": 9, "scene": 36, "line": "Who knows?", "order": 46560, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 9, "scene": 36, "line": "Maybe after all this settles down...", "order": 46561, "speaker": "Susan California"},
  {"season": 8, "episode": 9, "scene": 36, "line": "Totally.", "order": 46562, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 9, "scene": 36, "line": "It's a date.", "order": 46563, "speaker": "Susan California"},
  {"season": 8, "episode": 9, "scene": 36, "line": "Hmm?", "order": 46564, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 9, "scene": 37, "line": "You're nothing! You're so weak, you call yourself a man? Huh?", "order": 46565, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 9, "scene": 37, "line": "This doesn't help me. I don't respond to that kind of strategy.", "order": 46566, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 9, "scene": 37, "line": "Okay, fine. Finish your set on your own and never come back to my gym again!", "order": 46567, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 9, "scene": 37, "line": "Dwight, come back and spot me.", "order": 46568, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 9, "scene": 37, "line": "All right, I'll help you. But first, you gotta tell me what your goal is. What do you want?", "order": 46569, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 9, "scene": 37, "line": "To push this bar up.", "order": 46570, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 9, "scene": 37, "line": "No! Because if that was the case, the bar would be up by now. What is your goal?", "order": 46571, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 9, "scene": 37, "line": "Help me!", "order": 46572, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 9, "scene": 37, "line": "What do you want!?!", "order": 46573, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 9, "scene": 37, "line": "To look good for Val!", "order": 46574, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 9, "scene": 37, "line": "Val Kilmer? I don't buy it. That doesn't make any sense. Wow... I figured out your goal. I am going to make you the buffest dude Val Kilmer has ever seen.", "order": 46575, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 9, "scene": 38, "line": "They say that if you can strengthen your core, it improves your sexual performance. So, you will find me on the Ab-Blaster. And then you will find me blasting...uh, a very nice young woman.", "order": 46576, "speaker": "Gabe Lewis"},
  {"season": 8, "episode": 9, "scene": 39, "line": "Gotta get in shape. I'm doing Ironman next month. And I want him to be attracted to me.", "order": 46577, "speaker": "Meredith Palmer"},
  {"season": 8, "episode": 9, "scene": 40, "line": "Hey, what are you listening to?", "order": 46578, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 9, "scene": 40, "line": "Steve Martin. [laughs, then falls] uh, I'm fine. [grunts]", "order": 46579, "speaker": "Gabe Lewis"},
  {"season": 8, "episode": 9, "scene": 41, "line": "Um, where's the shower?", "order": 46580, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 9, "scene": 41, "line": "There is no shower.", "order": 46581, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 9, "scene": 41, "line": "There isn't?", "order": 46582, "speaker": "Kathy"},
  {"season": 8, "episode": 9, "scene": 41, "line": "What does this look like, a shower store? Get lost.", "order": 46583, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 9, "scene": 41, "line": "You have got to be kidding me! No shower?!", "order": 46584, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 9, "scene": 42, "line": "Exercise lengthens life. Improves mood, boosts sex drive, and I've already covered the BM of it all. It's miraculous the effect that I will have on their lives.", "order": 46585, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 9, "scene": 43, "line": "So, Roberto Californio doesn't want to work with his wife. I get it. And, he wants to give his wife a job. Totally get it. What I am struggling with is the part of this that's impossible.", "order": 46586, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 9, "scene": 44, "line": "I love my wife.", "order": 46587, "speaker": "Robert California"},
  {"season": 8, "episode": 9, "scene": 45, "line": "This whole thing is making me very uncomfortable. I'm not getting between the boss and his wife so just ask me about something else. You know Stanley's tie is really the story of the day...", "order": 46588, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 9, "scene": 46, "line": "It's my birthday.", "order": 46589, "speaker": "Stanley Hudson"},
  {"season": 8, "episode": 9, "scene": 47, "line": "Ok, so that's a disaster. Luckily have a two-part plan. One, run. Two, hide. It's pretty smart, right? [walks into Vance Refrigeration office] Hi, how you doing? I'm Jim Halpert from across the hall. We are redoing our supply closet and I was wondering if I could take a look at yours for some ideas.", "order": 46590, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 9, "scene": 47, "line": "Let me just check with Bob.", "order": 46591, "speaker": "Bob's Secretary"},
  {"season": 8, "episode": 9, "scene": 47, "line": "You absolutely should. He said it was ok, so till you get him, I'll just be in, you know. [closes door behind him to supply closet]", "order": 46592, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 9, "scene": 47, "line": "[on phone] Bob? Jim just went into the closet.", "order": 46593, "speaker": "Bob's Secretary"},
  {"season": 8, "episode": 9, "scene": 48, "line": "Ugh! He's always one step ahead of us! How does he do that?", "order": 46594, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 9, "scene": 48, "line": "This is insulting!", "order": 46595, "speaker": "Susan California"},
  {"season": 8, "episode": 9, "scene": 48, "line": "This whole accusation is insulting. Come on.", "order": 46596, "speaker": "Robert California"},
  {"season": 8, "episode": 10, "scene": 1, "line": "[Singing as if he were the dolls in his office] Christmas tiiiime is heeeere! [Normal voice] Wow. Thanks guys, that sounded amazing. Hi, I'm Andy Bernard and I am the first office Santa ever to make holiday wishes come true.", "order": 46597, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 10, "scene": 2, "line": "Who's excited to get their holiday wishes?", "order": 46598, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 10, "scene": 2, "line": "Holiday wishes.", "order": 46599, "speaker": "Stanley Hudson"},
  {"season": 8, "episode": 10, "scene": 2, "line": "What's that, Stanley?", "order": 46600, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 10, "scene": 2, "line": "We know exactly what holiday you're referring to.", "order": 46601, "speaker": "Stanley Hudson"},
  {"season": 8, "episode": 10, "scene": 2, "line": "It is important to be mindful of all belief systems at our holiday party.", "order": 46602, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 10, "scene": 2, "line": "I've been here eighteen years and have suffered through some weird thematic Christmases. A Honolulu Christmas, A Pulp Fiction Christmas, A Muslim Christmas, Moroccan Christmas. Mo-rocca Christmas. I don't want it. Christmas is Christmas is Christmas is Christmas.", "order": 46603, "speaker": "Stanley Hudson"},
  {"season": 8, "episode": 10, "scene": 3, "line": "I got Stanley tickets to see Lewis Black for his birthday. ...Might not have been the best idea.", "order": 46604, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 10, "scene": 4, "line": "I don't want no Kwanza wreath, I don't need a dreidel in my face, that's it's own thing. And who's that black Santa for?! I don't care! I know Santa ain't black! I could care less. I want Christmas! Just give me plain baby Jesus, lying in a manger, Christmas!", "order": 46605, "speaker": "Stanley Hudson"},
  {"season": 8, "episode": 10, "scene": 5, "line": "What is the status on my wish?", "order": 46606, "speaker": "Meredith Palmer"},
  {"season": 8, "episode": 10, "scene": 5, "line": "Fulfilled! Brought my bicycle, so I can be your designated driver later. In the event that you drink to much, which is by no means required.", "order": 46607, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 10, "scene": 5, "line": "What about my wish that we don't have to attend meetings that degrade our sense of adulthood?", "order": 46608, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 10, "scene": 5, "line": "That, I cannot do, but I did get you an acre of property on the moon.", "order": 46609, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 10, "scene": 5, "line": "Where? Dark side or light side?", "order": 46610, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 10, "scene": 5, "line": "Light side.", "order": 46611, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 10, "scene": 5, "line": "Is it by the Sea of Tranquility?", "order": 46612, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 10, "scene": 5, "line": "As a matter of fact, yes. Directly adjacent....beachfront.", "order": 46613, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 10, "scene": 5, "line": "Thank you, Andy.", "order": 46614, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 10, "scene": 5, "line": "So, who's wish is next? Oh, what about Santa's wish? My biggest wish is that you all get along well with Jessica....C'mon guys, Jessica?...Jim, tell 'em who Jessica is.", "order": 46615, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 10, "scene": 5, "line": "She's not your grandmother, is she?", "order": 46616, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 10, "scene": 5, "line": "Ugh! Gam-gam's name is Ruth, Jim, you should know. I introduced you on speakerphone that time?", "order": 46617, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 10, "scene": 5, "line": "Yeah.", "order": 46618, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 10, "scene": 5, "line": "Jessica is my super serious girlfriend, who is seriously awesome and seriously sexy. Only thing that's not serious, by the way? Our repartee.", "order": 46619, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 10, "scene": 5, "line": "That's great. When you know you know. Hey, what's Ruth's deal, man?", "order": 46620, "speaker": "Creed Bratton"},
  {"season": 8, "episode": 10, "scene": 5, "line": "Totally out of your league. So in summation, ho ho ho, please be nice to Jessica and mistletoe is not an excuse for sexual assault, and don't go near Gam-gam.", "order": 46621, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 10, "scene": 6, "line": "I love Jessica, and I haven't even met her yet. It's like we don't even need to meet, you know? I already love you. Stay home.", "order": 46622, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 10, "scene": 7, "line": "I just want you to know that I will be mean to Jessica if you want me to be.", "order": 46623, "speaker": "Kelly Kapoor"},
  {"season": 8, "episode": 10, "scene": 7, "line": "Oh no, no. It's fine, Kelly.", "order": 46624, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 10, "scene": 7, "line": "It's really no problem. I was already planning on being mean to her.", "order": 46625, "speaker": "Kelly Kapoor"},
  {"season": 8, "episode": 10, "scene": 7, "line": "That's OK, I don't want you to do that.", "order": 46626, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 10, "scene": 7, "line": "[laughing] Hello, ah. Merry Christmas, Erin. Kelly, Happy Pancha Ganapati.", "order": 46627, "speaker": "Robert California"},
  {"season": 8, "episode": 10, "scene": 7, "line": "Eww, what is that?", "order": 46628, "speaker": "Kelly Kapoor"},
  {"season": 8, "episode": 10, "scene": 7, "line": "The five day Hindu celebration in December honoring the god Ganesh, Patron of the Arts.", "order": 46629, "speaker": "Robert California"},
  {"season": 8, "episode": 10, "scene": 7, "line": "Cool.", "order": 46630, "speaker": "Kelly Kapoor"},
  {"season": 8, "episode": 10, "scene": 7, "line": "Hey, man.", "order": 46631, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 10, "scene": 7, "line": "Hey! [Kevin embraces Robert in a tight hug] Oh!", "order": 46632, "speaker": "Robert California"},
  {"season": 8, "episode": 10, "scene": 7, "line": "How ya holdin' up...because of your wife leaving you?", "order": 46633, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 10, "scene": 7, "line": "Kevin?", "order": 46634, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 10, "scene": 7, "line": "I actually appreciate the human intimacy. Feel like a kitten being cradled by a gorilla.", "order": 46635, "speaker": "Robert California"},
  {"season": 8, "episode": 10, "scene": 7, "line": "Yeah.", "order": 46636, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 10, "scene": 7, "line": "It's been ten days since I had sexual intercourse.", "order": 46637, "speaker": "Robert California"},
  {"season": 8, "episode": 10, "scene": 7, "line": "Well you came to the right place....Ah, this, this party'll cheer you right up.", "order": 46638, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 10, "scene": 7, "line": "I hope so. The corporate party was wretched. I am so tired of the Black Eyed Peas. It's rock-n-roll for people who don't like rock-n-roll. It's rap for people who don't like rap. It's pop for people who don't like pop.....ah, heartbreaking. [gestures to Andy in Santa costume]", "order": 46639, "speaker": "Robert California"},
  {"season": 8, "episode": 10, "scene": 7, "line": "Oh, thank you.", "order": 46640, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 10, "scene": 8, "line": "Well, I hate to have to take my Santa hat off, and put on my hard ass hat. [puts on baseball cap that has a foam rear-end on it with the words 'HARD ASS'] But, this is serious. It's come to my attention that somebody who shall remain nameless, wants to switch desk clumps.", "order": 46641, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 10, "scene": 8, "line": "Fine with me. Jim can leave anytime he wants. Goodbye.", "order": 46642, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 10, "scene": 8, "line": "It wasn't me.", "order": 46643, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 10, "scene": 8, "line": "It wasn't either of you.", "order": 46644, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 10, "scene": 8, "line": "Kathy wants to leave our clump?", "order": 46645, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 10, "scene": 8, "line": "Sh sh sh sh! Don't look. Who it was is not important...but she did say that your constant pranking and one-upsmanship is driving her crazy. She or he.", "order": 46646, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 10, "scene": 8, "line": "I just wanna say, this is not my fault. OK? The weak always bully the strong. Contrary to what you see in the media. I am always acting in self-defense. Occasionally preemptive self-defense. [punches Jim in the arm]", "order": 46647, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 10, "scene": 8, "line": "Ow, What are you doing? [Jim fights back]", "order": 46648, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 10, "scene": 8, "line": "Hey! Hey! Respect the hat!", "order": 46649, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 10, "scene": 8, "line": "Pam never seemed to have a problem with us.", "order": 46650, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 10, "scene": 8, "line": "Alright. I'm gonna speak in a language you both understand....Mo-nay.", "order": 46651, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 10, "scene": 8, "line": "What was that?", "order": 46652, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 10, "scene": 8, "line": "What is it?", "order": 46653, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 10, "scene": 8, "line": "Money....You both have sizable Christmas bonuses coming your way. If I catch either of you messing with the other, I will give both bonuses to the other person.", "order": 46654, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 10, "scene": 8, "line": "Can't do that.", "order": 46655, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 10, "scene": 8, "line": "No, absolutely not.", "order": 46656, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 10, "scene": 8, "line": "You need consequences. OK? I want you both walking on eggshells.", "order": 46657, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 10, "scene": 9, "line": "Hi. I'm Jessica. I'm looking for Andy?", "order": 46658, "speaker": "Jessica"},
  {"season": 8, "episode": 10, "scene": 9, "line": "Jessica. Yes. We don't say hi, we hug. [gets up and hugs Jessica] I'm Erin.", "order": 46659, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 10, "scene": 9, "line": "Oh! [laughs] Oof!", "order": 46660, "speaker": "Jessica"},
  {"season": 8, "episode": 10, "scene": 9, "line": "Sorry, hi, I hope you feel really welcome, we all want you here.", "order": 46661, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 10, "scene": 9, "line": "Oh.", "order": 46662, "speaker": "Jessica"},
  {"season": 8, "episode": 10, "scene": 10, "line": "My ex is meeting my sex....which is always scary, you know? And not just because you think they might talk about your penis...that's just part of it.", "order": 46663, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 10, "scene": 11, "line": "Hey! Jess, Erin, I hope you're not talking about my penis. [laughs and kisses Jessica on the cheek] Hi.", "order": 46664, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 10, "scene": 11, "line": "Hi.", "order": 46665, "speaker": "Jessica"},
  {"season": 8, "episode": 10, "scene": 11, "line": "Hey everyone! I want you to meet Jessica, she is an assistant cross country coach at Bryn Mawr.", "order": 46666, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 10, "scene": 11, "line": "Wow.", "order": 46667, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 10, "scene": 11, "line": "Erin, by the way, amazing receptionist.", "order": 46668, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 10, "scene": 11, "line": "Oh, that's great. Backbone of the office.", "order": 46669, "speaker": "Jessica"},
  {"season": 8, "episode": 10, "scene": 11, "line": "Which is funny, actually, because my spine is a mess.", "order": 46670, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 10, "scene": 11, "line": "What?", "order": 46671, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 10, "scene": 11, "line": "Oh.", "order": 46672, "speaker": "Jessica"},
  {"season": 8, "episode": 10, "scene": 11, "line": "Scoliosis. Had to wear a back brace for three years but I never did. [Jessica laughs, Erin exaggerates back curve]", "order": 46673, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 10, "scene": 11, "line": "Oh no.", "order": 46674, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 10, "scene": 11, "line": "Ah..[laughs]", "order": 46675, "speaker": "Jessica"},
  {"season": 8, "episode": 10, "scene": 11, "line": "Ut oh, it's kicking in. Spontaneous scoliosis.", "order": 46676, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 10, "scene": 11, "line": "Spontaneous scoliosis. [both laugh]", "order": 46677, "speaker": "Jessica"},
  {"season": 8, "episode": 10, "scene": 11, "line": "Oh.", "order": 46678, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 10, "scene": 12, "line": "This thing could take your arm off, your head off, you know, just exercise caution.", "order": 46679, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 10, "scene": 12, "line": "Thank you.", "order": 46680, "speaker": "Val Johnson"},
  {"season": 8, "episode": 10, "scene": 12, "line": "Alright.", "order": 46681, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 10, "scene": 12, "line": "Alright.", "order": 46682, "speaker": "Val Johnson"},
  {"season": 8, "episode": 10, "scene": 12, "line": "Oh yeah, and um there's a Christmas party upstairs tonight, wanted to tell you about.", "order": 46683, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 10, "scene": 12, "line": "Isn't that just for popsicles?", "order": 46684, "speaker": "Val Johnson"},
  {"season": 8, "episode": 10, "scene": 12, "line": "Popsicles?", "order": 46685, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 10, "scene": 12, "line": "Yeah, upstairs people? Cause you got a stick up your butt?", "order": 46686, "speaker": "Val Johnson"},
  {"season": 8, "episode": 10, "scene": 12, "line": "Ah! See, I, I, I thought it was because they're so rich they could all eat popsicles all the time. [laughs] I, in my, I...", "order": 46687, "speaker": "Nate Nickerson"},
  {"season": 8, "episode": 10, "scene": 12, "line": "Well you should definitely come. The foreman always comes. Plus it's fun, you know cookies, smoked fish, alcohol, people acting stupid.", "order": 46688, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 10, "scene": 12, "line": "You had me at 'clookies.' I can't wait to find out what they are.", "order": 46689, "speaker": "Nate Nickerson"},
  {"season": 8, "episode": 10, "scene": 12, "line": "What should I wear?", "order": 46690, "speaker": "Val Johnson"},
  {"season": 8, "episode": 10, "scene": 12, "line": "Oh, it's nice, it's real nice. People get dressed up.", "order": 46691, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 10, "scene": 12, "line": "I will look so handsome for you, Darryl.", "order": 46692, "speaker": "Nate Nickerson"},
  {"season": 8, "episode": 10, "scene": 13, "line": "Ah...[sees Dwight's wallet and open email] I'm gonna s- [see's Dwight watching from break room]", "order": 46693, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 10, "scene": 14, "line": "Dwight really wants my bonus. He's trying to entrap me. Oh god, now I can't drink at this thing....I get really pranky when I drink.", "order": 46694, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 10, "scene": 15, "line": "Erin, what can I get for you?", "order": 46695, "speaker": "Robert California"},
  {"season": 8, "episode": 10, "scene": 15, "line": "Uh, do you have cola? Kirkland if you have it.", "order": 46696, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 10, "scene": 15, "line": "Now why would you come to a bar and ask for a cola when you can get some from the kitchen? Did some small part of you want something a little stronger? Ryan, Kevin, Phyllis, Oscar, come and take these shots!", "order": 46697, "speaker": "Robert California"},
  {"season": 8, "episode": 10, "scene": 15, "line": "Yes!", "order": 46698, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 10, "scene": 15, "line": "Yes!", "order": 46699, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 10, "scene": 15, "line": "Will do.", "order": 46700, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 10, "scene": 15, "line": "The fifth one is for you, Erin. You could take it or leave it. To take it would be to accept that you're at a party and you're an adult woman, with an adventurous spirit. To leave it would be fine too.", "order": 46701, "speaker": "Robert California"},
  {"season": 8, "episode": 10, "scene": 15, "line": "One, two three! [group takes shots]", "order": 46702, "speaker": "Phyllis Vance"},
  {"season": 8, "episode": 10, "scene": 15, "line": "Yes.", "order": 46703, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 10, "scene": 15, "line": "OK. [takes shot, group laughs]", "order": 46704, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 10, "scene": 15, "line": "Mmm. Wow.", "order": 46705, "speaker": "Robert California"},
  {"season": 8, "episode": 10, "scene": 15, "line": "Whoo! Jiminy Christmas!", "order": 46706, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 10, "scene": 15, "line": "Jiminey Christmas indeed.", "order": 46707, "speaker": "Robert California"},
  {"season": 8, "episode": 10, "scene": 15, "line": "Hit 'er up.", "order": 46708, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 10, "scene": 15, "line": "Oh.", "order": 46709, "speaker": "Robert California"},
  {"season": 8, "episode": 10, "scene": 15, "line": "Oh yes.", "order": 46710, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 10, "scene": 15, "line": "Whoo!", "order": 46711, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 10, "scene": 15, "line": "Yeah! [laughs]", "order": 46712, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 10, "scene": 15, "line": "Wow.", "order": 46713, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 10, "scene": 16, "line": "[on phone] Uh, yeah. I just got my replacement credit card...you want the number? Oh it's uh four seven nine three, zero zero three two, three three one three. The security code is nine two seven. OK great. Thank you very much. Bye.", "order": 46714, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 10, "scene": 17, "line": "So Dwight did take the bait. He used my credit card numbers to send a two hundred dollar bouquet of flowers, to my wife...from me.", "order": 46715, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 10, "scene": 18, "line": "[laughing] Boom!", "order": 46716, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 10, "scene": 19, "line": "But look, it's Christmas, so you're allowed...[Val walks in dressed very formally] whoa, hello. Um, you're allowed to do...", "order": 46717, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 10, "scene": 19, "line": "Good cookie.", "order": 46718, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 10, "scene": 20, "line": "I meant dressed up compared to normal. You usually dress like a ghost-buster.", "order": 46719, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 10, "scene": 20, "line": "OK.", "order": 46720, "speaker": "Val Johnson"},
  {"season": 8, "episode": 10, "scene": 20, "line": "I thought you'd wear a sweater.", "order": 46721, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 10, "scene": 20, "line": "Since when does a sweater mean 'dressed up'? Am I your grandson?", "order": 46722, "speaker": "Val Johnson"},
  {"season": 8, "episode": 10, "scene": 20, "line": "Come on, stay, alright? It's good for people to get to know you.", "order": 46723, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 10, "scene": 20, "line": "As a contestant on Dancing with the Stars, maybe.", "order": 46724, "speaker": "Val Johnson"},
  {"season": 8, "episode": 10, "scene": 21, "line": "[imitating Stanley] I want.. my sugar free cookie, and then I want a sugar cookie. [group laughs] and then I want my nap!", "order": 46725, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 10, "scene": 21, "line": "That's right. [laughs]", "order": 46726, "speaker": "Stanley Hudson"},
  {"season": 8, "episode": 10, "scene": 21, "line": "Whoo! [group laughs] Thank you.", "order": 46727, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 10, "scene": 21, "line": "She, she is remarkable.", "order": 46728, "speaker": "Robert California"},
  {"season": 8, "episode": 10, "scene": 21, "line": "Edgy impressions.", "order": 46729, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 10, "scene": 21, "line": "Thank you.", "order": 46730, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 10, "scene": 21, "line": "How many drinks have you had tonight? I can't be driving everybody home.", "order": 46731, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 10, "scene": 21, "line": "A thousand.", "order": 46732, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 10, "scene": 21, "line": "Whoa! Maybe you should take a break.", "order": 46733, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 10, "scene": 21, "line": "[serious] Maybe you should mind your own business. [normal voice] Just kidding!", "order": 46734, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 10, "scene": 22, "line": "Ah! I've been attacked! Oh my god! Oh! Someone put a porcupine in my drawer!", "order": 46735, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 10, "scene": 22, "line": "Oh my god.", "order": 46736, "speaker": "Toby Flenderson"},
  {"season": 8, "episode": 10, "scene": 22, "line": "Yeah, I was just sitting here at my desk and I, I reached into my drawer to grab my toothbrush and some tooth powder and, and all of a sudden I was attacked by this blood thirsty rabid creature!", "order": 46737, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 10, "scene": 22, "line": "I wonder, in this office, who has access to a porcupine?", "order": 46738, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 10, "scene": 22, "line": "Or who in this office knows that I have access and is trying to set me up?", "order": 46739, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 10, "scene": 22, "line": "Hmm...", "order": 46740, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 10, "scene": 22, "line": "You know this sounds a lot like the premise of my latest Chad Flendermen novel. A murder for framing.", "order": 46741, "speaker": "Toby Flenderson"},
  {"season": 8, "episode": 10, "scene": 23, "line": "Chad Flendermen. Just an easy going black guy, he knows the streets, yet he also went to Oxford. So. Just as comfortable on a motorcycle as he is on Air Force One. Oh and he's also the world's leading Egyptologist.", "order": 46742, "speaker": "Toby Flenderson"},
  {"season": 8, "episode": 10, "scene": 24, "line": "Toby! Nobody cares about your sex-crazed black detective.", "order": 46743, "speaker": "Angela Martin"},
  {"season": 8, "episode": 10, "scene": 24, "line": "No, no, no, no, no. Women chase him. He misses his wife.", "order": 46744, "speaker": "Toby Flenderson"},
  {"season": 8, "episode": 10, "scene": 24, "line": "Hello? Lacerated hand here folks.", "order": 46745, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 10, "scene": 24, "line": "Nice try, this is ridiculous.", "order": 46746, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 10, "scene": 24, "line": "Oh is it really? Two separate times you have set me up to believe I was being recruited by the CIA.", "order": 46747, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 10, "scene": 24, "line": "Three times.", "order": 46748, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 10, "scene": 24, "line": "You see?", "order": 46749, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 10, "scene": 24, "line": "Jim, this has your fingerprints all over it.", "order": 46750, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 10, "scene": 24, "line": "Andy, you've gotta be kidding me.", "order": 46751, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 10, "scene": 24, "line": "Fingerprints can be planted. You know with a severed hand...", "order": 46752, "speaker": "Toby Flenderson"},
  {"season": 8, "episode": 10, "scene": 24, "line": "Do you think that's what happened? Do you think he used a severed hand?", "order": 46753, "speaker": "Kelly Kapoor"},
  {"season": 8, "episode": 10, "scene": 24, "line": "OK, you know what? Why don't we just call animal control...", "order": 46754, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 10, "scene": 24, "line": "Might wanna run that by Angela cause it's so cute.", "order": 46755, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 10, "scene": 24, "line": "No. Porcupines don't have souls. They're like dogs.", "order": 46756, "speaker": "Angela Martin"},
  {"season": 8, "episode": 10, "scene": 24, "line": "[on phone] Yes, I'm calling from Dunder Mifflin. We have a very rabid porcupine in our office, someone should come pick it up.", "order": 46757, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 10, "scene": 24, "line": "Come down right away!", "order": 46758, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 10, "scene": 24, "line": "[on phone]Uh, I don't know, let me ask. [to Dwight] Uh, were you quilled?", "order": 46759, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 10, "scene": 24, "line": "Yes, I was quilled.", "order": 46760, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 10, "scene": 24, "line": "And what's it's name?", "order": 46761, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 10, "scene": 24, "line": "Henrietta.", "order": 46762, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 10, "scene": 24, "line": "Oops. [hangs up]", "order": 46763, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 10, "scene": 24, "line": "What? ...Alright, get her out of here, Dwight.", "order": 46764, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 10, "scene": 25, "line": "Hey, guys. I just wanted to say. Come here! I'm so happy you guys are together. OK? [Andy and Jessica laugh] And you both have such beautiful hair.", "order": 46765, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 10, "scene": 25, "line": "[laughs] Thank you.", "order": 46766, "speaker": "Jessica"},
  {"season": 8, "episode": 10, "scene": 25, "line": "I hope you guys get married....and who knows? Maybe at the wedding, I meet someone.", "order": 46767, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 10, "scene": 25, "line": "OK!", "order": 46768, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 10, "scene": 25, "line": "And later on tonight, I hope I see you guys kiss. [blows kisses and pushes Andy and Jessica's faces together.] Ah! [laughs] OK, well I'm gonna go do another reverse spit. That's how the cool kids say 'get a drink'. [blows kisses and dances away]", "order": 46769, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 10, "scene": 25, "line": "That's uh, that's the girl you dated.", "order": 46770, "speaker": "Jessica"},
  {"season": 8, "episode": 10, "scene": 25, "line": "Um, yeah, she's not always like that. It's um...", "order": 46771, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 10, "scene": 25, "line": "No, she, she seems fine.", "order": 46772, "speaker": "Jessica"},
  {"season": 8, "episode": 10, "scene": 25, "line": "Yeah, uh, excuse me.", "order": 46773, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 10, "scene": 25, "line": "OK.", "order": 46774, "speaker": "Jessica"},
  {"season": 8, "episode": 10, "scene": 25, "line": "Yep. [leaves Jessica to talk to Erin] Hey!", "order": 46775, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 10, "scene": 25, "line": "Hey.", "order": 46776, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 10, "scene": 25, "line": "Have you had anything to eat besides candy canes?", "order": 46777, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 10, "scene": 25, "line": "Every martini has an olive.", "order": 46778, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 10, "scene": 25, "line": "OK...maybe I should make you some oatmeal or something.", "order": 46779, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 10, "scene": 25, "line": "I don't wanna put you out, but if someone's making oatmeal, I'll take a apple cinnamon and a maple brown sugar in one bowl with whole milk.", "order": 46780, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 10, "scene": 25, "line": "Hey, I never told you my Christmas wish.", "order": 46781, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 10, "scene": 25, "line": "Ah, OK.", "order": 46782, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 10, "scene": 25, "line": "It's about you.", "order": 46783, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 10, "scene": 25, "line": "That's not what it should be, it should just be like a trinket or something.", "order": 46784, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 10, "scene": 25, "line": "[slurring slightly] It's that I wish Jessica was dead.", "order": 46785, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 10, "scene": 25, "line": "You you, wait. You mean you wish she wasn't here or something.", "order": 46786, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 10, "scene": 25, "line": "I wish she was in a graveyard! Under the ground...with worms coming out of her mouth.", "order": 46787, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 10, "scene": 25, "line": "Hey, you know, you can't say that, OK? That's my girlfriend that you're talking about. You and I are not together anymore. You need to get over it! Take your wish back.", "order": 46788, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 10, "scene": 25, "line": "Too late! It's already been wished! And you promised it would come true. You wrote it in an email! So, which one are you? A murderer or a liar? [Andy leaves]", "order": 46789, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 10, "scene": 25, "line": "Hey.", "order": 46790, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 10, "scene": 25, "line": "Hey.", "order": 46791, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 10, "scene": 25, "line": "So, there was talk of oatmeal.", "order": 46792, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 10, "scene": 26, "line": "You know, don't listen to what anyone is saying. You look like a, a princess.", "order": 46793, "speaker": "Phyllis Vance"},
  {"season": 8, "episode": 10, "scene": 26, "line": "Yeah. Thanks.", "order": 46794, "speaker": "Val Johnson"},
  {"season": 8, "episode": 10, "scene": 27, "line": "I swear...", "order": 46795, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 10, "scene": 27, "line": "Did you not...", "order": 46796, "speaker": "Jessica"},
  {"season": 8, "episode": 10, "scene": 27, "line": "I was too embarrassed! [group laughs]", "order": 46797, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 10, "scene": 27, "line": "Hey guys, sorry to interrupt, I uh, just found this on my desk. [shows picture of Cici with devil doodles] Need to talk to somebody about it. Can you believe that?", "order": 46798, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 10, "scene": 27, "line": "Is that Cici?", "order": 46799, "speaker": "Phyllis Vance"},
  {"season": 8, "episode": 10, "scene": 27, "line": "Yes. It is.", "order": 46800, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 10, "scene": 27, "line": "Um.", "order": 46801, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 10, "scene": 27, "line": "That's awful.", "order": 46802, "speaker": "Phyllis Vance"},
  {"season": 8, "episode": 10, "scene": 27, "line": "Cici is Jim's daughter.", "order": 46803, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 10, "scene": 27, "line": "Oh my god. How could somebody do that?", "order": 46804, "speaker": "Jessica"},
  {"season": 8, "episode": 10, "scene": 27, "line": "I know, I mean the way I see it is you can deface any picture of me, any one, pick one. Not my kid though.", "order": 46805, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 10, "scene": 27, "line": "Who did this? Who did this? Do you know who did this?", "order": 46806, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 10, "scene": 27, "line": "I don't-", "order": 46807, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 10, "scene": 27, "line": "I know who did this....Dwight.", "order": 46808, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 10, "scene": 27, "line": "Dwight?", "order": 46809, "speaker": "Phyllis Vance"},
  {"season": 8, "episode": 10, "scene": 27, "line": "Yeah.", "order": 46810, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 10, "scene": 27, "line": "Dwight.", "order": 46811, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 10, "scene": 27, "line": "No!", "order": 46812, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 10, "scene": 27, "line": "He should pay.", "order": 46813, "speaker": "Phyllis Vance"},
  {"season": 8, "episode": 10, "scene": 27, "line": "Yeah. He is gonna pay. This is fire-able.", "order": 46814, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 10, "scene": 27, "line": "O...K.", "order": 46815, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 10, "scene": 27, "line": "It's, I mean.", "order": 46816, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 10, "scene": 27, "line": "Let me just see that one more time. Yep, you know what? I know who my friends are, now. But I shouldn't have got you involved because the truth is, I don't even really care about this picture, it's a little out of focus. It was probably an accident, right? Like...", "order": 46817, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 10, "scene": 27, "line": "That's no accident.", "order": 46818, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 10, "scene": 27, "line": "Right. You're right. So maybe it was me who did it...by accident.", "order": 46819, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 10, "scene": 27, "line": "What?", "order": 46820, "speaker": "Phyllis Vance"},
  {"season": 8, "episode": 10, "scene": 27, "line": "What?", "order": 46821, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 10, "scene": 27, "line": "I'll figure it out, get to the bottom of it....Merry merry.", "order": 46822, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 10, "scene": 28, "line": "Enough of this garbage. [harshly places boom box on table and hits play] This is Christmas. [Christmas Eve by Trans-Siberian Orchestra plays. Dwight plays air violin/guitar and sings along] Hi-yah!!!!! [gabe, Creed and Nate join in as shots show various gift giving] Yeah!!!! Christmas!!!!", "order": 46823, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 10, "scene": 29, "line": "You alright, Santa?", "order": 46824, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 10, "scene": 29, "line": "You know those movies where two friends are sleeping together and it's like, hey, can they stay friends?", "order": 46825, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 10, "scene": 29, "line": "Yes.", "order": 46826, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 10, "scene": 29, "line": "Do you think two friends who are not sleeping together can stay friends?", "order": 46827, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 10, "scene": 29, "line": "Two friends who are not sleeping together, can they remain friends? Yes, yeah.", "order": 46828, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 10, "scene": 29, "line": "No...I don't, hmm, I don't know.", "order": 46829, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 10, "scene": 29, "line": "OK.", "order": 46830, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 10, "scene": 29, "line": "By the way, I'm not gonna mess with you and Dwight's bonuses. I think it's causing more problems. So just be yourselves, have fun, and try not to let it affect your work.", "order": 46831, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 10, "scene": 29, "line": "Alright. I will definitely do that.", "order": 46832, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 10, "scene": 29, "line": "Alright, I'll tell Dwight.", "order": 46833, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 10, "scene": 29, "line": "You know what? You're sitting, and thinking, and it's probably better if it comes from me anyway.", "order": 46834, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 10, "scene": 29, "line": "Christmas miracles do happen.", "order": 46835, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 10, "scene": 29, "line": "Yes. They totally do.", "order": 46836, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 10, "scene": 30, "line": "You can't click on these Kardashian links, that's why you have so many viruses.", "order": 46837, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 10, "scene": 30, "line": "Well help me, OK? Just, uh take...", "order": 46838, "speaker": "Kelly Kapoor"},
  {"season": 8, "episode": 10, "scene": 30, "line": "I'm trying but you need to-", "order": 46839, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 10, "scene": 30, "line": "Hey.", "order": 46840, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 10, "scene": 30, "line": "Yo.", "order": 46841, "speaker": "Kelly Kapoor"},
  {"season": 8, "episode": 10, "scene": 30, "line": "Game on.", "order": 46842, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 10, "scene": 30, "line": "On it.", "order": 46843, "speaker": "Kelly Kapoor"},
  {"season": 8, "episode": 10, "scene": 31, "line": "[group laughing] I don't know what I was thinking, it was awful...", "order": 46844, "speaker": "Jessica"},
  {"season": 8, "episode": 10, "scene": 31, "line": "Jessica, did you just fart? [group stops laughing]", "order": 46845, "speaker": "Kelly Kapoor"},
  {"season": 8, "episode": 10, "scene": 32, "line": "And that, is how it's done.", "order": 46846, "speaker": "Kelly Kapoor"},
  {"season": 8, "episode": 10, "scene": 33, "line": "I would like another alcohol.", "order": 46847, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 10, "scene": 33, "line": "Let's you and I take a walk. Oscar, you're in charge of the bar.", "order": 46848, "speaker": "Robert California"},
  {"season": 8, "episode": 10, "scene": 33, "line": "What? I haven't bar tended in forever. Oh, never considered myself a mixologist, oh this is daunting. Um, I need a mortar, pestle, muddler..does anyone have any chocolate shavings?", "order": 46849, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 10, "scene": 34, "line": "Your heart is broken. So is mine.", "order": 46850, "speaker": "Robert California"},
  {"season": 8, "episode": 10, "scene": 34, "line": "And...", "order": 46851, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 10, "scene": 34, "line": "And what?", "order": 46852, "speaker": "Robert California"},
  {"season": 8, "episode": 10, "scene": 34, "line": "And you have any advice or anything?", "order": 46853, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 10, "scene": 34, "line": "No [laughs] my god.", "order": 46854, "speaker": "Robert California"},
  {"season": 8, "episode": 10, "scene": 34, "line": "...Help me feel better...", "order": 46855, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 10, "scene": 34, "line": "I've been married thrice and each has ended in an acrimonious divorce, I'm not sure I'm the best person to give love advice....I was hoping you were gonna make me feel better.", "order": 46856, "speaker": "Robert California"},
  {"season": 8, "episode": 10, "scene": 35, "line": "When you do your makeup,", "order": 46857, "speaker": "Nate Nickerson"},
  {"season": 8, "episode": 10, "scene": 35, "line": "Uh huh.", "order": 46858, "speaker": "Jessica"},
  {"season": 8, "episode": 10, "scene": 35, "line": "How long you, does it take usually? Cause...", "order": 46859, "speaker": "Nate Nickerson"},
  {"season": 8, "episode": 10, "scene": 35, "line": "Um, it ta-, um it depends.", "order": 46860, "speaker": "Jessica"},
  {"season": 8, "episode": 10, "scene": 36, "line": "..Chad Flendermen's kryptonite. [Darryl walks up to Toby and Val in a tuxedo]", "order": 46861, "speaker": "Toby Flenderson"},
  {"season": 8, "episode": 10, "scene": 36, "line": "Whoa. We got a real Clarence Thomas here.", "order": 46862, "speaker": "Creed Bratton"},
  {"season": 8, "episode": 10, "scene": 36, "line": "Hi.", "order": 46863, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 10, "scene": 37, "line": "I'll see you at home?", "order": 46864, "speaker": "Jessica"},
  {"season": 8, "episode": 10, "scene": 37, "line": "OK great.", "order": 46865, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 10, "scene": 37, "line": "OK", "order": 46866, "speaker": "Jessica"},
  {"season": 8, "episode": 10, "scene": 37, "line": "Perfect.", "order": 46867, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 10, "scene": 37, "line": "Bye [kiss]...I still don't get why you can't just call her a cab and pay for it.", "order": 46868, "speaker": "Jessica"},
  {"season": 8, "episode": 10, "scene": 37, "line": "I would, it just, that wasn't the holiday wish.", "order": 46869, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 10, "scene": 37, "line": "Oh", "order": 46870, "speaker": "Jessica"},
  {"season": 8, "episode": 10, "scene": 37, "line": "Either he drives or I drive. Now I'm drunk and mad.", "order": 46871, "speaker": "Meredith Palmer"},
  {"season": 8, "episode": 10, "scene": 37, "line": "OK [laughs] Bye.", "order": 46872, "speaker": "Jessica"},
  {"season": 8, "episode": 10, "scene": 37, "line": "OK, bye. [helps Meredith off the floor] Alright Meredith,", "order": 46873, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 10, "scene": 37, "line": "Thank you Santa.", "order": 46874, "speaker": "Meredith Palmer"},
  {"season": 8, "episode": 10, "scene": 37, "line": "You bet.", "order": 46875, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 10, "scene": 38, "line": "Thank god Erin's getting a ride home with Robert because she is trashed. [laughs] and who better to drive her home than Robert, I mean, what a stand up guy, you know? I mean he's going through a lot, separating from his wife, showing up to the Christmas party in a track suit and a blazer,", "order": 46876, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 10, "scene": 39, "line": "Let's go, let's go.", "order": 46877, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 10, "scene": 39, "line": "I know, I'm coming, I'm coming, I'm coming, I'm coming, I'm coming.", "order": 46878, "speaker": "Meredith Palmer"},
  {"season": 8, "episode": 10, "scene": 39, "line": "Hang on.", "order": 46879, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 10, "scene": 39, "line": "Alright. [Andy opens back of her van, things fall out]", "order": 46880, "speaker": "Meredith Palmer"},
  {"season": 8, "episode": 10, "scene": 39, "line": "What?", "order": 46881, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 10, "scene": 39, "line": "Whoa! My stuff!", "order": 46882, "speaker": "Meredith Palmer"},
  {"season": 8, "episode": 10, "scene": 39, "line": "What is all that?", "order": 46883, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 10, "scene": 39, "line": "It's my valuables!", "order": 46884, "speaker": "Meredith Palmer"},
  {"season": 8, "episode": 10, "scene": 39, "line": "It's junk!", "order": 46885, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 10, "scene": 39, "line": "This is my treasures, no they're my treasures!", "order": 46886, "speaker": "Meredith Palmer"},
  {"season": 8, "episode": 10, "scene": 39, "line": "You're a hoarder. My god!", "order": 46887, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 10, "scene": 39, "line": "No I'm not, I'm gonna sell it on Ebay! No no no, you be careful. Be careful.", "order": 46888, "speaker": "Meredith Palmer"},
  {"season": 8, "episode": 10, "scene": 39, "line": "Ah! My Santa suit's stuck.", "order": 46889, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 10, "scene": 39, "line": "[laughs while spray painting 'Jim is Awesome' on his own car, talks to porcupine in carrier] What are we gonna do with all that bonus money, Henrietta? Huh?", "order": 46890, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 10, "scene": 40, "line": "Where are we? This ain't my street.", "order": 46891, "speaker": "Meredith Palmer"},
  {"season": 8, "episode": 10, "scene": 40, "line": "Sh, sh, sh, sh, shh.", "order": 46892, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 10, "scene": 40, "line": "Ah, this is the posh part of town, huh?", "order": 46893, "speaker": "Meredith Palmer"},
  {"season": 8, "episode": 10, "scene": 40, "line": "Oh my god.", "order": 46894, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 10, "scene": 40, "line": "Wonder what a studio condo would cost in this neighborhood?", "order": 46895, "speaker": "Meredith Palmer"},
  {"season": 8, "episode": 10, "scene": 40, "line": "Meredith, shut your drunk hole right now.", "order": 46896, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 10, "scene": 40, "line": "Erin, it was great fun tonight. Take a few aspirin and a whole lot of water, sleep in in the morning.", "order": 46897, "speaker": "Robert California"},
  {"season": 8, "episode": 10, "scene": 40, "line": "Thank you, goodnight.", "order": 46898, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 10, "scene": 40, "line": "What are you smiling for? You thinkin' what I'm thinkin'?", "order": 46899, "speaker": "Meredith Palmer"},
  {"season": 8, "episode": 10, "scene": 40, "line": "I'm sure I am not. Let's get you home.", "order": 46900, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 10, "scene": 40, "line": "Ow! Take it easy.", "order": 46901, "speaker": "Meredith Palmer"},
  {"season": 8, "episode": 10, "scene": 40, "line": "Whoo.", "order": 46902, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 10, "scene": 41, "line": "[yawns, 'Idiot' is written on his forehead] Ah, man. Ah, fell asleep, took a nap. Hey guys, ah I feel refreshed now. How's it goin?", "order": 46903, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 10, "scene": 41, "line": "Dwight.", "order": 46904, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 10, "scene": 41, "line": "Took a little nap right next to Jim's desk. I feel so good right now. Mmm, cookies. What's everyone staring at?", "order": 46905, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 10, "scene": 42, "line": "Oh man, I was supposed to tell Dwight something......C'mon, Jim. [shakes head] I got nothin'.", "order": 46906, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 10, "scene": 43, "line": "Where can I get a drink? Oh...", "order": 46907, "speaker": "Robert California"},
  {"season": 8, "episode": 10, "scene": 43, "line": "Oh, uh. Well, we thought we wouldn't put the alcohol out till 11AM. It's just...common...decency.", "order": 46908, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 10, "scene": 43, "line": "Par for the course. Par for the...freaking course. I'll be in the party room having a cookie until 11. Haha! Yule-log.", "order": 46909, "speaker": "Robert California"},
  {"season": 8, "episode": 10, "scene": 44, "line": "Not destroyed. No..... Not destructive. Quite...off-kilter, sure. But... that's too vague. [long pause] Erratic. Darkly erratic. [laughs awkwardly] That's my mood.", "order": 46910, "speaker": "Robert California"},
  {"season": 8, "episode": 10, "scene": 45, "line": "Cake?...You seem a little down.", "order": 46911, "speaker": "Meredith Palmer"},
  {"season": 8, "episode": 10, "scene": 45, "line": "I am. [laughs] I'm a mess.", "order": 46912, "speaker": "Robert California"},
  {"season": 8, "episode": 10, "scene": 45, "line": "How about I fix you some warm milk with some bourbon?", "order": 46913, "speaker": "Meredith Palmer"},
  {"season": 8, "episode": 10, "scene": 45, "line": "Oh, that sounds nice.", "order": 46914, "speaker": "Robert California"},
  {"season": 8, "episode": 10, "scene": 45, "line": "Maybe watch a movie...under a blanket...", "order": 46915, "speaker": "Meredith Palmer"},
  {"season": 8, "episode": 10, "scene": 45, "line": "Lovely. Wait, where is this taking place?", "order": 46916, "speaker": "Robert California"},
  {"season": 8, "episode": 10, "scene": 45, "line": "The Murphy bed of my basement.", "order": 46917, "speaker": "Meredith Palmer"},
  {"season": 8, "episode": 10, "scene": 45, "line": "What...is happening to me? [pause] The lines in your haggard face are paths that lead nowhere. Your hair is the fire of hell. [hugs Meredith] I sincerely hope you find a sexual partner tonight.", "order": 46918, "speaker": "Robert California"},
  {"season": 8, "episode": 10, "scene": 46, "line": "It's come to my attention that someone, who shall remain nameless, wants to switch desk clumps.", "order": 46919, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 10, "scene": 46, "line": "Cathy wants to leave our clump?", "order": 46920, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 10, "scene": 46, "line": "Don't look. Who it was is not important...but she did say that your constant pranking and one-upsmanship is driving her crazy. She or he.", "order": 46921, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 10, "scene": 47, "line": "Hey. Enjoy your new clump.", "order": 46922, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 10, "scene": 47, "line": "Thanks.", "order": 46923, "speaker": "Kathy"},
  {"season": 8, "episode": 10, "scene": 47, "line": "It's so much closer to the bathroom, right?", "order": 46924, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 10, "scene": 47, "line": "Yeah.", "order": 46925, "speaker": "Kathy"},
  {"season": 8, "episode": 10, "scene": 47, "line": "Uh huh.", "order": 46926, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 10, "scene": 47, "line": "Sorry it didn't work out. Hope you find what you're looking for.", "order": 46927, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 10, "scene": 47, "line": "No, I just have more room.", "order": 46928, "speaker": "Kathy"},
  {"season": 8, "episode": 10, "scene": 47, "line": "That's ok.", "order": 46929, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 10, "scene": 47, "line": "You'll be fine over there.", "order": 46930, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 10, "scene": 48, "line": "[talking loudly] Sorry guys, Jim! This package came for you, do you want me to put it on your desk?", "order": 46931, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 10, "scene": 48, "line": "That'd be great, thank you. [Erin throws package, it hits Kathy. She thinks it was Jim who threw it]", "order": 46932, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 10, "scene": 49, "line": "So, I got this bucket from Jim's garage and I filled it with everything disgusting. Except excrement. I can't wait to see the look on his face when that falls on my face. [laughs, then camera shows bucket being dumped on Kathy] Dammit Kathy!", "order": 46933, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 10, "scene": 50, "line": "For the past month, I have been collecting wishes from everyone in the office, and I intend to make good on every single one of them.", "order": 46934, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 10, "scene": 51, "line": "Oh, I see.", "order": 46935, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 10, "scene": 52, "line": "Now look, I may not have a great laugh like Santa, or a flying sled. But tell you what I do have. A Prius, a heart of freaking gold....", "order": 46936, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 10, "scene": 53, "line": "This.", "order": 46937, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 10, "scene": 53, "line": "Alright, you got it.", "order": 46938, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 10, "scene": 54, "line": "...and an American Express green card.", "order": 46939, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 10, "scene": 55, "line": "Ryan, for your holiday wish, you wanted ten sick days.", "order": 46940, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 10, "scene": 55, "line": "I'd be satisfied with eight.", "order": 46941, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 10, "scene": 55, "line": "Well I'll do you one better.", "order": 46942, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 10, "scene": 55, "line": "Nine?", "order": 46943, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 10, "scene": 55, "line": "I got you health insurance.", "order": 46944, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 10, "scene": 55, "line": "Oh, neat.", "order": 46945, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 10, "scene": 55, "line": "Yeah! Right? But with great perks come great responsibility, so I'm gonna be expecting a lot more from you...no more zoning out in your office.", "order": 46946, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 10, "scene": 55, "line": "Oh, great.", "order": 46947, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 10, "scene": 55, "line": "Yes!", "order": 46948, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 11, "scene": 1, "line": "Oh yeah!", "order": 46949, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 11, "scene": 1, "line": "Aw!", "order": 46950, "speaker": "All"},
  {"season": 8, "episode": 11, "scene": 1, "line": "Knew it! I knew it! Soon as I heard that wrapper.", "order": 46951, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 11, "scene": 1, "line": "You really have to say 'oh yeah' every time you eat a candy bar?", "order": 46952, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 11, "scene": 1, "line": "I can't help it, Oscar. It's just really good. [takes another bite] Oh yeah!", "order": 46953, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 11, "scene": 1, "line": "All right, not bad at all. I think we can beat 20 minutes though so let's try again. Get it all out now if you have to.", "order": 46954, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 11, "scene": 1, "line": "It was a raccoon! Eating a hamburger like a person!", "order": 46955, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 11, "scene": 1, "line": "You need to stop banging your pen on your desk or it's going to drive me insane.", "order": 46956, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 11, "scene": 1, "line": "Okay, done.", "order": 46957, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 11, "scene": 1, "line": "[shows bloody hand] Does anyone have a first aid kit?", "order": 46958, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 11, "scene": 1, "line": "Check out this song I wrote: I'ma love you downstairs tonight... [overlapping chatter]", "order": 46959, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 11, "scene": 1, "line": "All right, here we go! Everybody get read in three, two, good luck, one-", "order": 46960, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 11, "scene": 2, "line": "[writing on board] Love letters, yes! I love it. What else can be done with paper?", "order": 46961, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 11, "scene": 2, "line": "You can write a book about chairs.", "order": 46962, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 11, "scene": 2, "line": "Books! Excellent, Jim.", "order": 46963, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 11, "scene": 2, "line": "Andy, please! Please, just stop. You can do infinite things with paper-", "order": 46964, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 11, "scene": 2, "line": "[gasps] Shh! Did you hear that? Infinity! There is an infinity of things that you can do with paper! Now, who wants to buy some paper? [scattered applause]", "order": 46965, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 11, "scene": 2, "line": "Very nice. Very nice sales pitch for our clients that don't know what paper is.", "order": 46966, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 11, "scene": 2, "line": "I'm talking about you guys! Who in this room, right now, wants to buy some paper? Let's get high on our own supply!", "order": 46967, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 11, "scene": 2, "line": "Andy, you want us to buy our own paper?", "order": 46968, "speaker": "Angela Martin"},
  {"season": 8, "episode": 11, "scene": 2, "line": "Yes!", "order": 46969, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 11, "scene": 3, "line": "Robert said that we needed to double our sales growth to eight percent by the end of the quarter. That's today. And we're eight hundred and thirty dollars short. And I can't afford to keep buying paper from us. So today, we need to sell eight hundred and thirty dollars of paper and the next quarter I need to sell the twenty-two hundred dollars of paper that's in my garage.", "order": 46970, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 11, "scene": 4, "line": "Animals, machines, vast virtual armies. All of these things I have successfully managed. The only thing I haven't managed is people. I saw an ad on the Sabre website for an open manager position in their printers division. I spoke to Robert California about it and he said for me to come by and see him sometime. So, like a Spanish conquistador, I have come to Florida to claim what is rightfully mine.", "order": 46971, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 11, "scene": 5, "line": "Dwight Schrute for Robert California!", "order": 46972, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 11, "scene": 5, "line": "Oh, hello Mr. Schrute. I'll tell him you're here. Can I get you anything at all?", "order": 46973, "speaker": "Receptionist"},
  {"season": 8, "episode": 11, "scene": 5, "line": "I'm not here to be given anything. I'm here to take what is mine.", "order": 46974, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 11, "scene": 5, "line": "Ooh. I'm glad you're on our team. Here, please have a seat.", "order": 46975, "speaker": "Receptionist"},
  {"season": 8, "episode": 11, "scene": 5, "line": "Dwight? What are you doing here?", "order": 46976, "speaker": "Gabe Lewis"},
  {"season": 8, "episode": 11, "scene": 5, "line": "Gabe? You don't know?", "order": 46977, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 11, "scene": 5, "line": "Course I know.", "order": 46978, "speaker": "Gabe Lewis"},
  {"season": 8, "episode": 11, "scene": 5, "line": "Hmm. Impressive office you have here. Surrounded by shrubbery, like a squirrel's office.", "order": 46979, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 11, "scene": 6, "line": "Corporate says to me, 'Gabe, we need you in Scranton.' Scranton says, 'Gabe, go back down to Florida. You're needed there.' So, Tuesdays and Thursdays, I'm up there. Mondays, Wednesdays and Fridays, I'm down here. I can think of no better way to confront my deathly fear of flying.", "order": 46980, "speaker": "Gabe Lewis"},
  {"season": 8, "episode": 11, "scene": 7, "line": "Dwight.", "order": 46981, "speaker": "Robert California"},
  {"season": 8, "episode": 11, "scene": 7, "line": "Robert.", "order": 46982, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 11, "scene": 7, "line": "You made it.", "order": 46983, "speaker": "Robert California"},
  {"season": 8, "episode": 11, "scene": 7, "line": "Yes.", "order": 46984, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 11, "scene": 7, "line": "Impressive initiative. I don't know what's worse, the trip or the destination. [Gabe laughs] Well, uh, let me settle in and I'll be with you shortly.", "order": 46985, "speaker": "Robert California"},
  {"season": 8, "episode": 11, "scene": 7, "line": "Very good.", "order": 46986, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 11, "scene": 8, "line": "Hey C-span. So my dad says an accountant can really help you out, if they're willing to 'play ball.' Those were his words.", "order": 46987, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 11, "scene": 8, "line": "Gosh, Andy, you had a great quarter. Okay? Robert will understand. Eight hundred dollars is a rounding error!", "order": 46988, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 11, "scene": 8, "line": "So make that error!", "order": 46989, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 11, "scene": 8, "line": "Andy, no-", "order": 46990, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 11, "scene": 8, "line": "Hey! I can make that error.", "order": 46991, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 11, "scene": 8, "line": "[clicks tongue] Aw...", "order": 46992, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 11, "scene": 8, "line": "Well, it's just, I know that making errors sounds like your kind of thing, but it's a little more complicated than that.", "order": 46993, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 11, "scene": 8, "line": "No, I-", "order": 46994, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 11, "scene": 8, "line": "It's just, I really need a real accountant on this. [to Oscar] What do you say?", "order": 46995, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 11, "scene": 8, "line": "I'm leaving early today because tonight I have a trivia contest in Philadelphia.", "order": 46996, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 11, "scene": 8, "line": "Oh.", "order": 46997, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 11, "scene": 8, "line": "Any other crunch time, I would love to stay in, cook the books for you so you can save face in front of your CEO, but tonight is Triviocalypse!", "order": 46998, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 11, "scene": 8, "line": "What?", "order": 46999, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 11, "scene": 8, "line": "It's only the biggest night of trivia of the year. There's a thousand dollar prize. I'm committed to my friends, they're committed to me.", "order": 47000, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 11, "scene": 8, "line": "You know what? Go. Have fun.", "order": 47001, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 11, "scene": 9, "line": "There's a trivia contest at a bar in Philadelphia.", "order": 47002, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 11, "scene": 9, "line": "Stop right there. I love it.", "order": 47003, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 11, "scene": 9, "line": "I'm in.", "order": 47004, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 11, "scene": 9, "line": "I didn't even say what it is.", "order": 47005, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 11, "scene": 9, "line": "It's trivia.", "order": 47006, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 11, "scene": 9, "line": "In Philadelphia.", "order": 47007, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 11, "scene": 9, "line": "But here's the best part. The prize is a thousand dollars, and if we win, we can use that money to buy paper here, close the gap on our eight percent profit increase...", "order": 47008, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 11, "scene": 9, "line": "That's a great idea.", "order": 47009, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 11, "scene": 9, "line": "That's a great plan.", "order": 47010, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 11, "scene": 9, "line": "Yeah? You like it?", "order": 47011, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 11, "scene": 9, "line": "Yeah.", "order": 47012, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 11, "scene": 9, "line": "All right.", "order": 47013, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 11, "scene": 9, "line": "All right, good stuff.", "order": 47014, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 11, "scene": 9, "line": "I'm so psyched you guys are into it because I thought- I was like, 'this sounds really stupid.'", "order": 47015, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 11, "scene": 9, "line": "You just made a good idea, a great idea.", "order": 47016, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 11, "scene": 9, "line": "There is one problem with this plan.", "order": 47017, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 11, "scene": 9, "line": "What?", "order": 47018, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 11, "scene": 9, "line": "We'd have to leave work, like, right now, to do this.", "order": 47019, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 11, "scene": 10, "line": "[on the phone] You can reduce your prices by ten percent or we're going to be finding a new source for our morning bagels. All right. [hangs up]", "order": 47020, "speaker": "Gabe Lewis"},
  {"season": 8, "episode": 11, "scene": 10, "line": "Gabe, I always wondered what it is you did around here. Now I found out. You're the bagel guy.", "order": 47021, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 11, "scene": 10, "line": "Yeah. But not just bagels. All unwanted problems. Question: What's the most important appliance in your house?", "order": 47022, "speaker": "Gabe Lewis"},
  {"season": 8, "episode": 11, "scene": 10, "line": "Meat grinder.", "order": 47023, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 11, "scene": 10, "line": "[makes buzzer sound] Too slow. It's the toilet. And I am the toilet of this office. I flush away annoying problems so others can keep their hands clean. And, just like a toilet, I am essential.", "order": 47024, "speaker": "Gabe Lewis"},
  {"season": 8, "episode": 11, "scene": 10, "line": "You know, Gabe? You could have gone with garbage disposal, incinerator or eraser, and instead you chose toilet. God bless you. You're an American classic.", "order": 47025, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 11, "scene": 10, "line": "Dwight. Today is not my day at all, I'm afraid. I'm not going to be able to meet with you, but I'm leaving you in the very capable hands of our COO.", "order": 47026, "speaker": "Robert California"},
  {"season": 8, "episode": 11, "scene": 10, "line": "But I can give you this pitch in one minute-", "order": 47027, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 11, "scene": 10, "line": "No, he's going to meet with you later. No, no, no. I don't want you to rush it, okay? Trust me. Meet with Bill. He's a great person to know. I'll dialogue with him tonight. [leaves]", "order": 47028, "speaker": "Robert California"},
  {"season": 8, "episode": 11, "scene": 10, "line": "CEO to COO. What a difference a letter makes.", "order": 47029, "speaker": "Gabe Lewis"},
  {"season": 8, "episode": 11, "scene": 10, "line": "Still an important position. Still a chief.", "order": 47030, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 11, "scene": 10, "line": "You're really going to like Bill. He, uh, he has me toilet a lot of people for him. [Dwight sighs, Gabe's phone rings] Hallway phone, Gabe Lewis speaking.", "order": 47031, "speaker": "Gabe Lewis"},
  {"season": 8, "episode": 11, "scene": 10, "line": "Gabe, listen to me. Don't bother Bill with this. He has to go put out a fire on the home front. Just wait twenty minutes and then I want you to listen to Dwight's pitch. Make him feel valued. Make him feel heard.", "order": 47032, "speaker": "Robert California"},
  {"season": 8, "episode": 11, "scene": 10, "line": "Your wish is my command.", "order": 47033, "speaker": "Gabe Lewis"},
  {"season": 8, "episode": 11, "scene": 10, "line": "It's a command.", "order": 47034, "speaker": "Robert California"},
  {"season": 8, "episode": 11, "scene": 11, "line": "I don't believe this. What are you doing here, Andy?", "order": 47035, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 11, "scene": 11, "line": "You left us no choice, but, this should put a smile on your face. How would you like to be captain of the Dunder-Mifflin team? Although I reserve the right to overrule you.", "order": 47036, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 11, "scene": 11, "line": "What? No!", "order": 47037, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 11, "scene": 11, "line": "I've got a quota to hit. I don't care how I hit it.", "order": 47038, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 11, "scene": 11, "line": "And you guys thought this was a good idea?", "order": 47039, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 11, "scene": 11, "line": "I thought it was a fun idea.", "order": 47040, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 11, "scene": 11, "line": "There were times on the two and a half hour drive when I experienced doubt. That's the thing about long drives, you know, you're always going to- this is a gay bar.", "order": 47041, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 11, "scene": 11, "line": "Wait, what? Everyone in here is gay?", "order": 47042, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 11, "scene": 11, "line": "Yes! It's a gay bar! So you guys want to go home now?", "order": 47043, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 11, "scene": 11, "line": "No. Nah.", "order": 47044, "speaker": "All"},
  {"season": 8, "episode": 11, "scene": 11, "line": "What does this say about you? That you followed me here? That you think you're going to win your sales quota? At a gay bar's trivia night? [laughs]", "order": 47045, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 11, "scene": 11, "line": "It says that I believe that my staff's intelligence and that I'm willing to try anything. [Oscar's friend walks up] Not anything.", "order": 47046, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 11, "scene": 11, "line": "Good luck.", "order": 47047, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 11, "scene": 11, "line": "Uh, all right. We need to divide up into teams, but it's winner-take-all so no need to divide evenly. We need an A-team, a backup team and a just-have-fun team. So you guys all know yourselves.", "order": 47048, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 11, "scene": 11, "line": "Good-timers follow me.", "order": 47049, "speaker": "Meredith Palmer"},
  {"season": 8, "episode": 11, "scene": 11, "line": "Backups. [Jim goes to join the backups]", "order": 47050, "speaker": "Phyllis Vance"},
  {"season": 8, "episode": 11, "scene": 11, "line": "Go on, kid. You know you don't belong here.", "order": 47051, "speaker": "Stanley Hudson"},
  {"season": 8, "episode": 11, "scene": 11, "line": "A-team.", "order": 47052, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 11, "scene": 11, "line": "Okay.", "order": 47053, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 11, "scene": 11, "line": "All right, everybody. Nice self-awareness. Except... [looks at Kevin, who has joined the A-team]", "order": 47054, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 11, "scene": 11, "line": "Okay. [leaves]", "order": 47055, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 11, "scene": 11, "line": "There's a table over here.", "order": 47056, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 11, "scene": 12, "line": "All right, first question everyone: Ray Charles famously had this state on his mind. What is its capital?", "order": 47057, "speaker": "Host"},
  {"season": 8, "episode": 11, "scene": 12, "line": "Oh, we got this!", "order": 47058, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 11, "scene": 12, "line": "Let's reverse engineer this. You're a black singer. Where do you go? Somewhere where you're a novelty. Alaska?", "order": 47059, "speaker": "Creed Bratton"},
  {"season": 8, "episode": 11, "scene": 12, "line": "Atlanta.", "order": 47060, "speaker": "Stanley Hudson"},
  {"season": 8, "episode": 11, "scene": 12, "line": "Oh I know you think that, because that's where the Olympics were held.", "order": 47061, "speaker": "Phyllis Vance"},
  {"season": 8, "episode": 11, "scene": 12, "line": "My cousins were actually at those Olympics.", "order": 47062, "speaker": "Cathy Simms"},
  {"season": 8, "episode": 11, "scene": 12, "line": "Keep talking all you want.", "order": 47063, "speaker": "Stanley Hudson"},
  {"season": 8, "episode": 11, "scene": 12, "line": "How am I supposed to know what was on his mind? Ooh, what do blind people think about?", "order": 47064, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 11, "scene": 12, "line": "Okay, dogs, canes, signs, manholes, stairs, piano, darkness.", "order": 47065, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 11, "scene": 12, "line": "Okay, time's up. Let's get the boards up. 'Atlanta, Georgia' is the correct answer. [Kevin holds up 'What is...SEE-attle']", "order": 47066, "speaker": "Host"},
  {"season": 8, "episode": 11, "scene": 13, "line": "Hey man, uh, look. Unfortunately, Bill had to go fight a fire, so your appointment got-", "order": 47067, "speaker": "Gabe Lewis"},
  {"season": 8, "episode": 11, "scene": 13, "line": "[sniffs] There's no fires within eight miles of here.", "order": 47068, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 11, "scene": 13, "line": "Well, it's nine miles away. I'm going to be meeting with you on his behalf.", "order": 47069, "speaker": "Gabe Lewis"},
  {"season": 8, "episode": 11, "scene": 13, "line": "What? No, no. Excuse me! Lady! Get anyone from the COO's office on the phone this instant!", "order": 47070, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 11, "scene": 13, "line": "This must be very frustrating.", "order": 47071, "speaker": "Receptionist"},
  {"season": 8, "episode": 11, "scene": 13, "line": "I can see you in the jungle now.", "order": 47072, "speaker": "Gabe Lewis"},
  {"season": 8, "episode": 11, "scene": 14, "line": "Okay, now, you're not an all-star of the NBA, but you did get your game on when you won the NBA's Sixth Man of the Year award in 2011. Who are you?", "order": 47073, "speaker": "Host"},
  {"season": 8, "episode": 11, "scene": 14, "line": "Jim, Darryl. Your time to shine.", "order": 47074, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 11, "scene": 14, "line": "Shawn Marion.", "order": 47075, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 11, "scene": 14, "line": "Yes, Shawn Marion.", "order": 47076, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 11, "scene": 14, "line": "That doesn't sound right. I want to say, LaDameon Washington.", "order": 47077, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 11, "scene": 14, "line": "Wrong, for so many reasons.", "order": 47078, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 11, "scene": 14, "line": "Well, I know Elizabeth Taylor's sixth man was Richard Burton. Is that helpful?", "order": 47079, "speaker": "Phyllis Vance"},
  {"season": 8, "episode": 11, "scene": 14, "line": "That's it. I'm going to go watch the boats on the river.", "order": 47080, "speaker": "Stanley Hudson"},
  {"season": 8, "episode": 11, "scene": 14, "line": "Ron Artest, Kelly.", "order": 47081, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 11, "scene": 14, "line": "No, it's Lamar Odom. If it'd been Ron Artest, it would have come up in Dancing with the Stars, when they pan over his trophy case when he's at home with his family. Lamar winning Sixth Man was a big storyline on Chloe and Lamar.", "order": 47082, "speaker": "Kelly Kapoor"},
  {"season": 8, "episode": 11, "scene": 14, "line": "All right, boards up. Let's see who got it. 'Lamar Odom' is what we were looking for. 'Lamar Odom' Thank you. [scattered cheers]", "order": 47083, "speaker": "Host"},
  {"season": 8, "episode": 11, "scene": 14, "line": "Oh my God. Lamar Odom, yes.", "order": 47084, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 11, "scene": 14, "line": "Nicely done!", "order": 47085, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 11, "scene": 14, "line": "You're my sports guys! You're ESPN, you're ESPN Classic, Ryan is MSNBC. I'm E!, TLC and Oxygen.", "order": 47086, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 11, "scene": 14, "line": "Chill, man. This plan is airtight.", "order": 47087, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 11, "scene": 15, "line": "So it's a very simple argument of why I should be put in charge of southeast printer sales. Nobody has sold more printers in the northeast than me. Bottom line, I know the product. I get it!", "order": 47088, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 11, "scene": 15, "line": "Well, you got my vote.", "order": 47089, "speaker": "Receptionist"},
  {"season": 8, "episode": 11, "scene": 15, "line": "Oh my God.", "order": 47090, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 11, "scene": 15, "line": "I know.", "order": 47091, "speaker": "Gabe Lewis"},
  {"season": 8, "episode": 11, "scene": 15, "line": "Most of all, I believe that character is destiny. And my character is one that- wait why are you smiling?", "order": 47092, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 11, "scene": 15, "line": "What?", "order": 47093, "speaker": "Gabe Lewis"},
  {"season": 8, "episode": 11, "scene": 15, "line": "What's with the smile?", "order": 47094, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 11, "scene": 15, "line": "You're doing great. You know, so good.", "order": 47095, "speaker": "Gabe Lewis"},
  {"season": 8, "episode": 11, "scene": 15, "line": "And my character is one that will never give up until greatness is on the horizon, behind us.", "order": 47096, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 11, "scene": 15, "line": "Dwight, that was a fantastic presentation. Put your hand on my hand. Flush! [Gabe makes a flushing motion, Dwight twists Gabe's arm] Ow!", "order": 47097, "speaker": "Gabe Lewis"},
  {"season": 8, "episode": 11, "scene": 15, "line": "Take me to Robert.", "order": 47098, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 11, "scene": 15, "line": "Ow! He went home.", "order": 47099, "speaker": "Gabe Lewis"},
  {"season": 8, "episode": 11, "scene": 15, "line": "Take me to his house.", "order": 47100, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 11, "scene": 15, "line": "Uh, it's a condo and it's long-term business housing.", "order": 47101, "speaker": "Gabe Lewis"},
  {"season": 8, "episode": 11, "scene": 15, "line": "You know where it is. Lead me there.", "order": 47102, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 11, "scene": 15, "line": "Ow! Ow! Stephanie, help!", "order": 47103, "speaker": "Gabe Lewis"},
  {"season": 8, "episode": 11, "scene": 15, "line": "Listen, you're a perfectly fine toilet. I'm just an extraordinary piece of crap. Let's go.", "order": 47104, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 11, "scene": 16, "line": "Excuse me, sir, on the Dunder-Mifflin A-team? Excuse me, sir?", "order": 47105, "speaker": "Host"},
  {"season": 8, "episode": 11, "scene": 16, "line": "Yeah?", "order": 47106, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 11, "scene": 16, "line": "I'm sure you're just checking your Grindr account... [laughter] but you can't check smart phones during trivia, it's against the rules.", "order": 47107, "speaker": "Host"},
  {"season": 8, "episode": 11, "scene": 16, "line": "Okay, I'm turning it off.", "order": 47108, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 11, "scene": 16, "line": "Okay, you're not turning it off.", "order": 47109, "speaker": "Host"},
  {"season": 8, "episode": 11, "scene": 16, "line": "I won't look at it.", "order": 47110, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 11, "scene": 16, "line": "Sir?", "order": 47111, "speaker": "Host"},
  {"season": 8, "episode": 11, "scene": 16, "line": "I can't- I can't not touch it.", "order": 47112, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 11, "scene": 16, "line": "Okay, then we're going to have to take it away. [employee takes the phone] Thank you.", "order": 47113, "speaker": "Host"},
  {"season": 8, "episode": 11, "scene": 16, "line": "Look, I can't, I can't not have my phone. I'm sorry. I want to be with my phone. [leaves]", "order": 47114, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 11, "scene": 16, "line": "All right, guys. After nine rounds, let's check the scores. In first place, with nine points, it's Aesop's Foibles. [Oscar's team touches fingers] The Queerenstein Bears have seven points. [a team of hairy men growl] Dunder-Mifflin A-Team has four points.", "order": 47115, "speaker": "Host"},
  {"season": 8, "episode": 11, "scene": 16, "line": "[claps] All right.", "order": 47116, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 11, "scene": 16, "line": "D.M. Backup Team has three points. The Einsteins have eight points. [the 'just-for-fun' Dunder-Mifflin team cheers] Ladies Gaga have five points. [more cheering]", "order": 47117, "speaker": "Host"},
  {"season": 8, "episode": 11, "scene": 16, "line": "So the best chance of hitting our mark is now in the hands, and brains, of Kevin, Meredith, Erin and Kelly. Do I like these odds? My answer is no.", "order": 47118, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 11, "scene": 17, "line": "Which one is it?", "order": 47119, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 11, "scene": 17, "line": "I don't know. All I know is the building.", "order": 47120, "speaker": "Gabe Lewis"},
  {"season": 8, "episode": 11, "scene": 17, "line": "Robert! Robert California! Robert!", "order": 47121, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 11, "scene": 17, "line": "Dwight! How nice of Gabe to show you where I live. Come around. 102.", "order": 47122, "speaker": "Robert California"},
  {"season": 8, "episode": 11, "scene": 18, "line": "All right guys, time's up. Who was the relatively unknown patent clerk who discovered that energy equals mass times the speed of light squared? Looks like everyone gets a point for Albert Einstein. Oh wait. Except for the Einsteins. That's all right. Which means the top three teams are going to finish it off in the speed round. So let's get everyone some bells. In third place we have the Einsteins.", "order": 47123, "speaker": "Host"},
  {"season": 8, "episode": 11, "scene": 18, "line": "Oh yeah!", "order": 47124, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 11, "scene": 18, "line": "What! [cheers]", "order": 47125, "speaker": "Kelly Kapoor"},
  {"season": 8, "episode": 11, "scene": 18, "line": "In second place, we have the Queerenstein Bears.", "order": 47126, "speaker": "Host"},
  {"season": 8, "episode": 11, "scene": 18, "line": "Whoo! Let's go!", "order": 47127, "speaker": "Queerenstein Bears"},
  {"season": 8, "episode": 11, "scene": 18, "line": "And, in first place, is Aesop's Foibles.", "order": 47128, "speaker": "Host"},
  {"season": 8, "episode": 11, "scene": 18, "line": "Whoo!", "order": 47129, "speaker": "Oscar Martinez's friend"},
  {"season": 8, "episode": 11, "scene": 18, "line": "Yeah, we're going to get clobbered.", "order": 47130, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 11, "scene": 18, "line": "Ring it in when you know it. First question: This man had a fatwa declared on him when- [Erin rings bell] Einsteins?", "order": 47131, "speaker": "Host"},
  {"season": 8, "episode": 11, "scene": 18, "line": "What?", "order": 47132, "speaker": "Meredith Palmer"},
  {"season": 8, "episode": 11, "scene": 18, "line": "What is it?", "order": 47133, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 11, "scene": 18, "line": "What?", "order": 47134, "speaker": "Meredith Palmer"},
  {"season": 8, "episode": 11, "scene": 18, "line": "I did my part, babe. I'm just the bell girl. [Oscar rings bell]", "order": 47135, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 11, "scene": 18, "line": "Aesop's?", "order": 47136, "speaker": "Host"},
  {"season": 8, "episode": 11, "scene": 18, "line": "Salman Rushdie.", "order": 47137, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 11, "scene": 18, "line": "Salman Rushdie is correct. [applause] Heading out to sea, sailors. On a square-rigged ship, the sale set furthest forward is called what? [Erin rings bell]", "order": 47138, "speaker": "Host"},
  {"season": 8, "episode": 11, "scene": 18, "line": "Princess Ding-Dong, do not hit that bell unless-", "order": 47139, "speaker": "Meredith Palmer"},
  {"season": 8, "episode": 11, "scene": 18, "line": "Flying jib.", "order": 47140, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 11, "scene": 18, "line": "Flying jib is correct for the Einsteins. [cheers and applause]", "order": 47141, "speaker": "Host"},
  {"season": 8, "episode": 11, "scene": 19, "line": "Shalom. [sighs] Give us just a minute. Stu and I are just finishing up our lesson. Trust me. One nine-minute bout is a cardiovascular equivalent of running uphill for three hours. [grunt] I could go to the gym three times a week or I can wrestle Stu once a month. [slaps the mat, they wrestle]", "order": 47142, "speaker": "Robert California"},
  {"season": 8, "episode": 11, "scene": 19, "line": "Grab my knee!", "order": 47143, "speaker": "Stu"},
  {"season": 8, "episode": 11, "scene": 19, "line": "Yay Robert!", "order": 47144, "speaker": "Gabe Lewis"},
  {"season": 8, "episode": 11, "scene": 19, "line": "Guys, please help yourself to some drinks from the fridge.", "order": 47145, "speaker": "Robert California"},
  {"season": 8, "episode": 11, "scene": 20, "line": "According to a recent survey, this is the most common learning disability among American adolescents.", "order": 47146, "speaker": "Host"},
  {"season": 8, "episode": 11, "scene": 20, "line": "[rings bell] Boom! ADHD!", "order": 47147, "speaker": "Oscar Martinez's friend"},
  {"season": 8, "episode": 11, "scene": 20, "line": "No. [another bell rings]", "order": 47148, "speaker": "Host"},
  {"season": 8, "episode": 11, "scene": 20, "line": "Wrong. The answer is dyslexia!", "order": 47149, "speaker": "Meredith Palmer"},
  {"season": 8, "episode": 11, "scene": 20, "line": "That's correct for the Einsteins. [cheers and applause]", "order": 47150, "speaker": "Host"},
  {"season": 8, "episode": 11, "scene": 20, "line": "[dances] Whoo! Yeah!", "order": 47151, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 11, "scene": 20, "line": "Sir-", "order": 47152, "speaker": "Host"},
  {"season": 8, "episode": 11, "scene": 20, "line": "Go Einsteins!", "order": 47153, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 11, "scene": 20, "line": "Dial it back, this isn't Tail Feathers, okay? [laughter]", "order": 47154, "speaker": "Host"},
  {"season": 8, "episode": 11, "scene": 21, "line": "They haven't really improved on the Oreo, have they?", "order": 47155, "speaker": "Robert California"},
  {"season": 8, "episode": 11, "scene": 21, "line": "No thank you.", "order": 47156, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 11, "scene": 21, "line": "Sit down, Dwight.", "order": 47157, "speaker": "Robert California"},
  {"season": 8, "episode": 11, "scene": 21, "line": "Let me tell you why I should be the next manager with a riddle: A manager, a salesman, a leader and a warrior walk into a restaurant. The hostess says, 'table for one?' How is this possible?", "order": 47158, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 11, "scene": 21, "line": "You were dining alone? All those people are you?", "order": 47159, "speaker": "Robert California"},
  {"season": 8, "episode": 11, "scene": 21, "line": "Yes, exactly. Riddle number two: Who is going-", "order": 47160, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 11, "scene": 21, "line": "Ah-ah-ah- your drive, your ambition. It would be wasted on a manager's job. And Florida, you don't want to live here. Even I don't want to live here. That's why I'm always at my place in Scranton. Florida is America's basement: It's wet, it's filled with mold, strange insects, alligators. Alligators are dinosaurs, Dwight. You know that, right?", "order": 47161, "speaker": "Robert California"},
  {"season": 8, "episode": 11, "scene": 21, "line": "N... it's complicated.", "order": 47162, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 11, "scene": 21, "line": "[holds a medal to the light] This medal was my grandfather's. He received it for acts of courage. For excellence. It's a tribute one man gives another. I could give you a job, Dwight. Why not let me give you something even better? [holds out medal]", "order": 47163, "speaker": "Robert California"},
  {"season": 8, "episode": 11, "scene": 21, "line": "It's a job interview, not a flea market.", "order": 47164, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 11, "scene": 21, "line": "Dwight, the job is not right for you. Now, when something comes along that is right for you, I'll try you out. Now get the hell out of my place.", "order": 47165, "speaker": "Robert California"},
  {"season": 8, "episode": 11, "scene": 21, "line": "Yes.", "order": 47166, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 11, "scene": 22, "line": "Final round. Last two teams squaring off. I hope you're ready to play doctor. Our question is about health and the human body.", "order": 47167, "speaker": "Host"},
  {"season": 8, "episode": 11, "scene": 22, "line": "Oh, come on!", "order": 47168, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 11, "scene": 22, "line": "The standard American analog scale has a maximum capacity of what weight? [bell rings]", "order": 47169, "speaker": "Host"},
  {"season": 8, "episode": 11, "scene": 22, "line": "Three hundred pounds!", "order": 47170, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 11, "scene": 22, "line": "Point for the Einsteins. [applause] Here's your final question. Cinephiles, put on your memory berets: This 2001 masterpiece from Gilles Paquet-Brenner explores the intricate dynamics of a family in disarray.", "order": 47171, "speaker": "Host"},
  {"season": 8, "episode": 11, "scene": 22, "line": "[rings bell, answers in French] Le titre du film est Le Scaphandre et le Papillon.", "order": 47172, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 11, "scene": 22, "line": "Yes!", "order": 47173, "speaker": "Oscar Martinez's friend"},
  {"season": 8, "episode": 11, "scene": 22, "line": "I'm sorry, no. Over to the Einsteins.", "order": 47174, "speaker": "Host"},
  {"season": 8, "episode": 11, "scene": 22, "line": "[rings bell] Les Jolies Choses.", "order": 47175, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 11, "scene": 22, "line": "Are you sure?", "order": 47176, "speaker": "Meredith Palmer"},
  {"season": 8, "episode": 11, "scene": 22, "line": "Marie Cotillard exposes herself a number of times in that film.", "order": 47177, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 11, "scene": 22, "line": "The Einsteins win it! [applause]", "order": 47178, "speaker": "Host"},
  {"season": 8, "episode": 11, "scene": 22, "line": "No! Come on!", "order": 47179, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 11, "scene": 23, "line": "Look, I know it's easy to say tonight was just a fluke, and maybe it was, but here's a piece of trivia: a fluke is one of the most common fish in the sea. So if you go fishing for a fluke, chances are, you just might catch one.", "order": 47180, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 11, "scene": 24, "line": "Welcome to the Stonewall Alliance Trivia Championships! First prize is forty-five hundred dollars!", "order": 47181, "speaker": "Stonewall Host"},
  {"season": 8, "episode": 11, "scene": 24, "line": "Let's just do our thing, collect our hardware and get the hell home.", "order": 47182, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 11, "scene": 25, "line": "Riboflavin.", "order": 47183, "speaker": "Stonewall Host"},
  {"season": 8, "episode": 11, "scene": 25, "line": "[holds up 'Robitussin'] Riboflavin?", "order": 47184, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 11, "scene": 25, "line": "Michigan. [Kevin holds up 'A Mitten'] The President of the United States is 'P.O.T.U.S.' [Kevin holds up 'P.O.T.A.T.O.'] John Steinbeck wrote The Grapes of Wrath. [Kevin holds up 'The California Raisins']", "order": 47185, "speaker": "Stonewall Host"},
  {"season": 8, "episode": 11, "scene": 26, "line": "Cause it's like, really...", "order": 47186, "speaker": "Kelly Kapoor"},
  {"season": 8, "episode": 11, "scene": 26, "line": "Einsteins! Great work.", "order": 47187, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 11, "scene": 26, "line": "Thank you.", "order": 47188, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 11, "scene": 26, "line": "You know what I thought would be fun? Is we do like uh, a switch-em-up maybe? Since only one of us needs to win, maybe I can get Kevin and Kelly and put together an all-star team?", "order": 47189, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 11, "scene": 26, "line": "Whoa, whoa, whoa, whoa, whoa, whoa! Andy, there is no switching teams.", "order": 47190, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 11, "scene": 26, "line": "Andy, we're fine. We don't wanna switch. We're already like, the perfect unit.", "order": 47191, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 11, "scene": 26, "line": "Alright. Well just stay focused, try to concentrate.", "order": 47192, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 11, "scene": 26, "line": "Whoo! Brought some brain food.", "order": 47193, "speaker": "Meredith Palmer"},
  {"season": 8, "episode": 11, "scene": 26, "line": "Yeah!", "order": 47194, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 11, "scene": 26, "line": "Having fun.", "order": 47195, "speaker": "Kelly Kapoor"},
  {"season": 8, "episode": 11, "scene": 26, "line": "One, two, three...[team takes shots]", "order": 47196, "speaker": "Meredith Palmer & Kelly Kapoor"},
  {"season": 8, "episode": 11, "scene": 27, "line": "When I dropped out of school to watch more sports, a lot of people thought that I was nuts. Well who's laughing now?", "order": 47197, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 11, "scene": 28, "line": "Say 'Shalom' to your shrubs on this Jewish holiday that celebrates the new year for trees.", "order": 47198, "speaker": "Announcer"},
  {"season": 8, "episode": 11, "scene": 28, "line": "Tu Bishvat!", "order": 47199, "speaker": "Kelly Kapoor"},
  {"season": 8, "episode": 11, "scene": 28, "line": "That's correct.", "order": 47200, "speaker": "Announcer"},
  {"season": 8, "episode": 11, "scene": 28, "line": "Whoo!", "order": 47201, "speaker": "Einsteins"},
  {"season": 8, "episode": 11, "scene": 28, "line": "Point for the Einsteins.", "order": 47202, "speaker": "Announcer"},
  {"season": 8, "episode": 11, "scene": 28, "line": "Hey, is it true that you're not allowed to spend time with your girlfriend during the month of Tu Bishvat?", "order": 47203, "speaker": "Kelly Kapoor"},
  {"season": 8, "episode": 11, "scene": 28, "line": "Uh, I don't think so.", "order": 47204, "speaker": "Announcer"},
  {"season": 8, "episode": 11, "scene": 28, "line": "I knew it. I knew it. I knew it. Ryan lies to me and says he has a whole Jewish holiday for an entire month.....", "order": 47205, "speaker": "Kelly Kapoor"},
  {"season": 8, "episode": 11, "scene": 29, "line": "It gets better, kids. It gets so much better that one day, your stupid coworkers will be excited to show up at your gay bar and ruin your trivia night.", "order": 47206, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 11, "scene": 30, "line": "Alright, if you're not going to help us out by buying our own paper, could you sell eight hundred dollars more paper than usual today? That would be amazing.", "order": 47207, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 11, "scene": 30, "line": "Andy, it's the last day of the quarter. We've already called all our clients, so making that much in a day is going to be tough.", "order": 47208, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 11, "scene": 30, "line": "Not helping, tuna.", "order": 47209, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 11, "scene": 31, "line": "Yeah, I'm sitting on twenty-five hundred in sales I can make at any time but those are my wait till the separation is legal sales.", "order": 47210, "speaker": "Stanley Hudson"},
  {"season": 8, "episode": 11, "scene": 32, "line": "We gotta figure this out. Think of the look on Robert's face when we tell him we met our 8% goal.", "order": 47211, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 11, "scene": 32, "line": "Oh, will we get to see that look?", "order": 47212, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 11, "scene": 32, "line": "No, that's why...that's why I said think of it.", "order": 47213, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 11, "scene": 32, "line": "Andy, we're gonna do our best. But you know what? At the end of the day seven point...whatever percent is pretty good.", "order": 47214, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 11, "scene": 32, "line": "Yeah.", "order": 47215, "speaker": "Group"},
  {"season": 8, "episode": 11, "scene": 32, "line": "Yeah, it is, Andy.", "order": 47216, "speaker": "Angela Martin"},
  {"season": 8, "episode": 11, "scene": 33, "line": "Have I mentioned that it's also quite good for amateur animation? [flips crude animation of stick figures representing him and Robert] Took me two weeks.", "order": 47217, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 12, "scene": 1, "line": "Oh, ugh. [stands and looks at meatball in chair] What is this, a meatball? Really? [Stanley laughs]", "order": 47218, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 12, "scene": 2, "line": "It's always more fun to mess with Dwight with an audience. That was usually Pam so now that she's out I had to find someone else. Turns out that Stanley is quite the comedy fan. But not everything makes him laugh. He has very specific tastes. Through a painstaking process of trial and error, I've found out what he likes. And it's really weird.", "order": 47219, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 12, "scene": 3, "line": "Jim, come on! That's so juvenile! What the- [opens drawer full of meatballs]", "order": 47220, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 12, "scene": 3, "line": "You've been meatballed! [laughs]", "order": 47221, "speaker": "Stanley Hudson"},
  {"season": 8, "episode": 12, "scene": 3, "line": "Ugh.", "order": 47222, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 12, "scene": 3, "line": "Are you ready for some meatball?", "order": 47223, "speaker": "Stanley Hudson"},
  {"season": 8, "episode": 12, "scene": 3, "line": "Aw, man. [Stanley laughs] This is not very clever, Jim.", "order": 47224, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 12, "scene": 3, "line": "I know.", "order": 47225, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 12, "scene": 3, "line": "Look for your stapler!", "order": 47226, "speaker": "Stanley Hudson"},
  {"season": 8, "episode": 12, "scene": 3, "line": "[Dwight finds stapler in giant meatball] Really Jim? Really? Very funny.", "order": 47227, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 12, "scene": 4, "line": "[to Phyllis] Oh okay. Good night. [climbs in to Dwight's car] What's the haul?", "order": 47228, "speaker": "Stanley Hudson"},
  {"season": 8, "episode": 12, "scene": 4, "line": "Thirty-two meatballs.", "order": 47229, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 12, "scene": 4, "line": "Good day.", "order": 47230, "speaker": "Stanley Hudson"},
  {"season": 8, "episode": 12, "scene": 5, "line": "That idiot's been feeding us for a week.", "order": 47231, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 12, "scene": 5, "line": "We'll never have to buy meatballs again.", "order": 47232, "speaker": "Stanley Hudson"},
  {"season": 8, "episode": 12, "scene": 6, "line": "[phone rings] Dunder Mifflin. Jessica, hi! How are you? Oh yay, that's so great to hear.", "order": 47233, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 12, "scene": 7, "line": "I'm not going to be one of those exes who can't move on. They have their life and I have mine. I'm taking an Italian class. So far I've learned tortellini, spagettini, linguini... Well it's not so much a class as a restaurant, but I do Monday, Wednesday, Friday from seven to nine.", "order": 47234, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 12, "scene": 8, "line": "I will patch you through right now. You sound really pretty today. Okay.", "order": 47235, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 12, "scene": 8, "line": "Jessica! I love you! That's a message from my mom.", "order": 47236, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 12, "scene": 9, "line": "My parents met Jessica and they completely flipped for her so they gave me this old family ring to use on her. I know, whoa! Pump the breaks, Bernard, too early! I get it. I just, you know, I'm just carrying it around, seeing how it feels. I haven't proposed to anyone in years... Mom took the main diamond out, she thought that had more of a my little brother kind of vibe to it, but...", "order": 47237, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 12, "scene": 10, "line": "[Robert is looking at pictures of a house online] Whoa, looks pretty nice. Got a little bit of a Shining vibe, though.", "order": 47238, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 12, "scene": 10, "line": "Oh, who needs a house that size?", "order": 47239, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 12, "scene": 10, "line": "Big. Stupid. Pure chump bait.", "order": 47240, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 12, "scene": 10, "line": "I'm selling the house, actually.", "order": 47241, "speaker": "Robert California"},
  {"season": 8, "episode": 12, "scene": 10, "line": "You know, there's a glare from over here... oh wow, that's magnificent.", "order": 47242, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 12, "scene": 10, "line": "It's mid-recession in a depressed area of a faltering state and I've got the most expensive house on the market. The one percent are suffering too, people. I wanted it to be my Playboy mansion. A temple to wine, revelry, sex, intrigue... this was hot on the heels of Eyes Wide Shut, mind you. Then I met my wife, she moved in, made it her own. Now she's left me and forced me to sell the place. The ultimate insult? They're calling my speakeasy lounge a rumpus room. [Jim laughs] Does my turmoil amuse you, Jim?", "order": 47243, "speaker": "Robert California"},
  {"season": 8, "episode": 12, "scene": 10, "line": "I'm sorry, I thought you were making a joke.", "order": 47244, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 12, "scene": 10, "line": "What could you possibly have found funny in what I said? What was the joke you thought you heard?", "order": 47245, "speaker": "Robert California"},
  {"season": 8, "episode": 12, "scene": 10, "line": "I guess I thought you were approaching it with more of a sarcasm than misery. Kind of laughing at your own pain, sad clown thing.", "order": 47246, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 12, "scene": 10, "line": "Oh yes. How hilarious it is to laugh at clowns, the painted jesters of the dying circus industry. Very funny, Jim. I get it.", "order": 47247, "speaker": "Robert California"},
  {"season": 8, "episode": 12, "scene": 10, "line": "Um, I'm getting reports of a serious outbreak of the grumpies in here.", "order": 47248, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 12, "scene": 10, "line": "A beautiful monster cost me my forties and my dream home. I think I'm entitled to the occasional bad day.", "order": 47249, "speaker": "Robert California"},
  {"season": 8, "episode": 12, "scene": 10, "line": "Well, please tell Susan we all say hi.", "order": 47250, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 12, "scene": 10, "line": "Dude, what if, since you're feeling grumpy, we all swing by tonight and check out your indoor pool? [Robert laughs]", "order": 47251, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 12, "scene": 10, "line": "Kevin, no.", "order": 47252, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 12, "scene": 10, "line": "What, as some sort of last hoorah?", "order": 47253, "speaker": "Robert California"},
  {"season": 8, "episode": 12, "scene": 10, "line": "Yeah. All of us in the pool, saying hoorah. Maybe the last one that says hoorah is it.", "order": 47254, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 12, "scene": 10, "line": "You know I suppose someone should enjoy the place before I hand it over to the staging experts at Remax tomorrow. Let's try this: everyone, tonight, my house, wear a swimsuit. Let's just call it a get-together. And let's say no food.", "order": 47255, "speaker": "Robert California"},
  {"season": 8, "episode": 12, "scene": 10, "line": "Hey Oscar, was that you who just created a party out of thin air or was it me?", "order": 47256, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 12, "scene": 10, "line": "That was you, Kevin.", "order": 47257, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 12, "scene": 10, "line": "It was me.", "order": 47258, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 12, "scene": 11, "line": "You going tonight, kiddo? Cause I can give you a lift.", "order": 47259, "speaker": "Meredith Palmer"},
  {"season": 8, "episode": 12, "scene": 11, "line": "Oh, I don't know, Meredith. It seems like you shouldn't drive maybe ever.", "order": 47260, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 12, "scene": 11, "line": "It's no problem. You live right near me.", "order": 47261, "speaker": "Meredith Palmer"},
  {"season": 8, "episode": 12, "scene": 11, "line": "How do you know where I live?", "order": 47262, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 12, "scene": 11, "line": "Andy followed you home after the Christmas party.", "order": 47263, "speaker": "Meredith Palmer"},
  {"season": 8, "episode": 12, "scene": 11, "line": "Why?", "order": 47264, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 12, "scene": 11, "line": "He wanted to make sure California didn't put it in you.", "order": 47265, "speaker": "Meredith Palmer"},
  {"season": 8, "episode": 12, "scene": 11, "line": "Oh. Come on.", "order": 47266, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 12, "scene": 12, "line": "Wow. Andy's such a weird stalker. Following me home like that when he has a girlfriend? I should get a restraining order. [squeaks]", "order": 47267, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 12, "scene": 13, "line": "[sticks a hand in the pool] Oh, it is warm.", "order": 47268, "speaker": "Angela Martin"},
  {"season": 8, "episode": 12, "scene": 13, "line": "It's almost too warm.", "order": 47269, "speaker": "Cathy Simms"},
  {"season": 8, "episode": 12, "scene": 13, "line": "I'm feeling eighty-two, eighty-two and a half.", "order": 47270, "speaker": "Gabe Lewis"},
  {"season": 8, "episode": 12, "scene": 13, "line": "Oh, so close. Eighty-one.", "order": 47271, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 12, "scene": 13, "line": "[to Cathy] Well, we'll say its eight-two and it'll be our secret.", "order": 47272, "speaker": "Gabe Lewis"},
  {"season": 8, "episode": 12, "scene": 13, "line": "[to Jim] Hey, late guy.", "order": 47273, "speaker": "Cathy Simms"},
  {"season": 8, "episode": 12, "scene": 13, "line": "Hey. Wow. Just stopping by. Got another party to go to. A wife and two kids at home party.", "order": 47274, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 12, "scene": 13, "line": "Oh.", "order": 47275, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 12, "scene": 13, "line": "DJ Pam Halpert is spinning some serious Radio Disney tonight.", "order": 47276, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 12, "scene": 14, "line": "You're looking at the master of leaving parties early. They key is, you have to make a strong impression, so you want to have a picture taken, you want to say some peculiar non sequitur that people remember, you want to note something unique, a talking point, for later. I don't mean to brag, but New Year's Eve, I was home by nine.", "order": 47277, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 12, "scene": 15, "line": "Robert, just wanted to grab you one second. This place is amazing, by the way.", "order": 47278, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 12, "scene": 15, "line": "You should see the whole thing.", "order": 47279, "speaker": "Robert California"},
  {"season": 8, "episode": 12, "scene": 15, "line": "I bet I should. [takes cell phone photo] That's beautiful, I'm going to email that to you.", "order": 47280, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 12, "scene": 15, "line": "I'm just about to give the tour.", "order": 47281, "speaker": "Robert California"},
  {"season": 8, "episode": 12, "scene": 15, "line": "All right-", "order": 47282, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 12, "scene": 15, "line": "Join us. You must see what you were laughing about.", "order": 47283, "speaker": "Robert California"},
  {"season": 8, "episode": 12, "scene": 15, "line": "I must...", "order": 47284, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 12, "scene": 16, "line": "[looks at pool] Wow.", "order": 47285, "speaker": "Jessica"},
  {"season": 8, "episode": 12, "scene": 16, "line": "[hides ring] Yeah, it's pretty serious poolage.", "order": 47286, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 12, "scene": 16, "line": "[sees Andy's hand in his pocket] What are you doing?", "order": 47287, "speaker": "Jessica"},
  {"season": 8, "episode": 12, "scene": 16, "line": "Hmm? Flicking a bug off my wiener. [they both laugh]", "order": 47288, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 12, "scene": 16, "line": "Gross. I'm getting a drink. Do you want anything?", "order": 47289, "speaker": "Jessica"},
  {"season": 8, "episode": 12, "scene": 16, "line": "No, I'm good.", "order": 47290, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 12, "scene": 16, "line": "[runs to Andy] Funny how we can be surrounded by people and still feel so lonely. Hi.", "order": 47291, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 12, "scene": 16, "line": "Hi.", "order": 47292, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 12, "scene": 16, "line": "How is everything? How's your car?", "order": 47293, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 12, "scene": 16, "line": "It's great. You know. Reliable. Great mileage.", "order": 47294, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 12, "scene": 16, "line": "Is that so? How about this weekend we take that sucker to a duck pond or something? Maybe get caught in the rain?", "order": 47295, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 12, "scene": 16, "line": "Well, I can't. I'm going skiing with Jessica. you know, a couple of dopes on the slopes.", "order": 47296, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 12, "scene": 16, "line": "Oh, like a goodbye trip.", "order": 47297, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 12, "scene": 16, "line": "No. What?", "order": 47298, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 12, "scene": 16, "line": "Hey.", "order": 47299, "speaker": "Jessica"},
  {"season": 8, "episode": 12, "scene": 16, "line": "Hi.", "order": 47300, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 12, "scene": 17, "line": "I guess Andy isn't totally over his current girlfriend. But, if he was jealous once before then maybe I can make him jealous again. Just not with Robert. He told me he was a ride I wouldn't survive, and I believe him.", "order": 47301, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 12, "scene": 18, "line": "Val. You made it.", "order": 47302, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 12, "scene": 18, "line": "Yup, yup.", "order": 47303, "speaker": "Val Johnson"},
  {"season": 8, "episode": 12, "scene": 18, "line": "Uh oh, look at this. Red plastic cup, red plastic cup. How about that?", "order": 47304, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 12, "scene": 18, "line": "You know, you are just as dumb at night.", "order": 47305, "speaker": "Val Johnson"},
  {"season": 8, "episode": 12, "scene": 18, "line": "Mmm. [they clink cups]", "order": 47306, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 12, "scene": 18, "line": "All right, theres- this would be no problem. I could swim under, one breath.", "order": 47307, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 12, "scene": 18, "line": "No, show me.", "order": 47308, "speaker": "Jessica"},
  {"season": 8, "episode": 12, "scene": 18, "line": "Okay.", "order": 47309, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 12, "scene": 18, "line": "Dive in right here.", "order": 47310, "speaker": "Jessica"},
  {"season": 8, "episode": 12, "scene": 18, "line": "In a minute. In a minute.", "order": 47311, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 12, "scene": 18, "line": "Okay. You don't know what you're doing.", "order": 47312, "speaker": "Jessica"},
  {"season": 8, "episode": 12, "scene": 18, "line": "A minute would be cool.", "order": 47313, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 12, "scene": 18, "line": "Hey, Dwight Snoot.", "order": 47314, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 12, "scene": 18, "line": "What-", "order": 47315, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 12, "scene": 18, "line": "What you doing?", "order": 47316, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 12, "scene": 18, "line": "I'm relaxing. Scram. [pushes her]", "order": 47317, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 12, "scene": 18, "line": "Ow.", "order": 47318, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 12, "scene": 18, "line": "Hey, Stanley. Um, what happened to my pants?", "order": 47319, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 12, "scene": 18, "line": "I moved them. Pants only need a chair if there's a person in them.", "order": 47320, "speaker": "Stanley Hudson"},
  {"season": 8, "episode": 12, "scene": 18, "line": "Where... [finds pants, looks for ring]", "order": 47321, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 12, "scene": 18, "line": "Come on, don't you want to play?", "order": 47322, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 12, "scene": 18, "line": "Oh really?", "order": 47323, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 12, "scene": 18, "line": "Yeah.", "order": 47324, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 12, "scene": 18, "line": "Yeah, you want to play you little hick? [kicks her in to the pool, Erin screams]", "order": 47325, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 12, "scene": 18, "line": "Whoa.", "order": 47326, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 12, "scene": 18, "line": "Dwight! Oh my gosh! Dwight!", "order": 47327, "speaker": "Angela Martin"},
  {"season": 8, "episode": 12, "scene": 19, "line": "Here we have the parlor. I imagined people would set down their coats and symbolically their inhibitions. This was the gateway. You enter this room a lawyer, a doctor, a teacher, a judge, but beyond it you're simply a penis, a vagina, hunger, ache. Susan used it as a Pilates studio.", "order": 47328, "speaker": "Robert California"},
  {"season": 8, "episode": 12, "scene": 19, "line": "[sees wine collection] Holy cow!", "order": 47329, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 12, "scene": 19, "line": "Wine collection.", "order": 47330, "speaker": "Robert California"},
  {"season": 8, "episode": 12, "scene": 19, "line": "How many bottles? Three hundred?", "order": 47331, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 12, "scene": 19, "line": "[laughs] About twelve hundred. What the hell, grab a bottle. Less inventory for the lawyers.", "order": 47332, "speaker": "Robert California"},
  {"season": 8, "episode": 12, "scene": 19, "line": "Toby! Chateau Margaux ninety-five. You know your wine.", "order": 47333, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 12, "scene": 19, "line": "Well, and you have a... yes, a- d- another chateau.", "order": 47334, "speaker": "Toby Flenderson"},
  {"season": 8, "episode": 12, "scene": 19, "line": "Robert, you are too kind.", "order": 47335, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 12, "scene": 19, "line": "Oh.", "order": 47336, "speaker": "Robert California"},
  {"season": 8, "episode": 12, "scene": 19, "line": "Too kind doesn't begin to cover it.", "order": 47337, "speaker": "Gabe Lewis"},
  {"season": 8, "episode": 12, "scene": 19, "line": "With ammunition like this we are in for quite a night, you and me.", "order": 47338, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 12, "scene": 19, "line": "And Gabe-y makes three.", "order": 47339, "speaker": "Gabe Lewis"},
  {"season": 8, "episode": 12, "scene": 19, "line": "Robert, thank you. Thank you for this. Thank you for all this. This night's been magical.", "order": 47340, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 12, "scene": 19, "line": "Jim, come see this next room. I think you especially would like it.", "order": 47341, "speaker": "Robert California"},
  {"season": 8, "episode": 12, "scene": 19, "line": "Really? Why?", "order": 47342, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 12, "scene": 19, "line": "I don't know. Maybe not. Just come.", "order": 47343, "speaker": "Robert California"},
  {"season": 8, "episode": 12, "scene": 20, "line": "[Dwight dunks her] Oh!", "order": 47344, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 12, "scene": 20, "line": "[laughs] You regret attacking me now, hick? Huh?", "order": 47345, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 12, "scene": 20, "line": "Stop it. Dwight, I was flirting with you. I was trying to use you to make Andy jealous.", "order": 47346, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 12, "scene": 20, "line": "I'm not going to help you. Why would you choose me? Because I'm mighty? Because I'm the manliest man in the office? I'll do it. [he picks her up]", "order": 47347, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 12, "scene": 20, "line": "[giggles] Oh Dwight!", "order": 47348, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 12, "scene": 21, "line": "[giggles] Stop.", "order": 47349, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 12, "scene": 21, "line": "You stop.", "order": 47350, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 12, "scene": 21, "line": "No, you stop.", "order": 47351, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 12, "scene": 21, "line": "No, you stop.", "order": 47352, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 12, "scene": 21, "line": "[to camera] Is he looking? [shakes her head] Oh. You can stop.", "order": 47353, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 12, "scene": 21, "line": "Okay, you can stop.", "order": 47354, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 12, "scene": 21, "line": "No, no, no, he's not looking.", "order": 47355, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 12, "scene": 21, "line": "No, you can stop.", "order": 47356, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 12, "scene": 21, "line": "You can actually stop.", "order": 47357, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 12, "scene": 21, "line": "Whoa, you guys, I just found this insane engagement ring. Is anyone missing this?", "order": 47358, "speaker": "Kelly Kapoor"},
  {"season": 8, "episode": 12, "scene": 21, "line": "The main stone's missing.", "order": 47359, "speaker": "Meredith Palmer"},
  {"season": 8, "episode": 12, "scene": 21, "line": "I don't know. It looks pretty great to me.", "order": 47360, "speaker": "Kelly Kapoor"},
  {"season": 8, "episode": 12, "scene": 22, "line": "I pictured myself here every night eating a leg of mutton, the juices dripping down my bare chest, wiping my fingers on the walls. Then I met the vegan.", "order": 47361, "speaker": "Robert California"},
  {"season": 8, "episode": 12, "scene": 22, "line": "Good night.", "order": 47362, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 12, "scene": 22, "line": "[raises wine bottle] To the kitchen!", "order": 47363, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 12, "scene": 22, "line": "To the kitchen!", "order": 47364, "speaker": "All"},
  {"season": 8, "episode": 12, "scene": 22, "line": "To the kitchen. Onward!", "order": 47365, "speaker": "Robert California"},
  {"season": 8, "episode": 12, "scene": 22, "line": "Toby, what's compelling about this is the note of persimmon. Right?", "order": 47366, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 12, "scene": 22, "line": "Note? It's a symphony.", "order": 47367, "speaker": "Toby Flenderson"},
  {"season": 8, "episode": 12, "scene": 22, "line": "Okay, you have to join my wine-tasting club.", "order": 47368, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 12, "scene": 22, "line": "I would love that.", "order": 47369, "speaker": "Toby Flenderson"},
  {"season": 8, "episode": 12, "scene": 23, "line": "Toby, you are playing a dangerous game. Guess I'm through the gateway now, though, right?", "order": 47370, "speaker": "Toby Flenderson"},
  {"season": 8, "episode": 12, "scene": 24, "line": "[to Val] You know, I don't think I've ever been in this exact angle before. I was scared at first, but I like it.", "order": 47371, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 12, "scene": 24, "line": "You guys got to try this pool. No top scum, no band-aids. This thing is choice.", "order": 47372, "speaker": "Meredith Palmer"},
  {"season": 8, "episode": 12, "scene": 24, "line": "You in?", "order": 47373, "speaker": "Val Johnson"},
  {"season": 8, "episode": 12, "scene": 24, "line": "Yeah, sure, sure. I'll be right in.", "order": 47374, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 12, "scene": 25, "line": "I've been working out. But, the problem is, I've been building muscle underneath. And that top layer hasn't burned off yet. Awkward stage.", "order": 47375, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 12, "scene": 26, "line": "[he and Erin feed each other chips] Ah, mmm. So good. Now take a chip, crush it into my face, really rub the grease around. Do it. Now rub it in. Oh, yeah, that's so good. Ah...", "order": 47376, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 12, "scene": 26, "line": "Andy's not even looking. I think sexy eating is a dead end.", "order": 47377, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 12, "scene": 26, "line": "Damn it.", "order": 47378, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 12, "scene": 26, "line": "What is the most romantic possible thing?", "order": 47379, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 12, "scene": 26, "line": "We can get some chicken fights going in the pool.", "order": 47380, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 12, "scene": 26, "line": "Dwight, that's just- that's really perfect. Thank you.", "order": 47381, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 12, "scene": 26, "line": "Yes! [they high five and jump in the pool]", "order": 47382, "speaker": "Both"},
  {"season": 8, "episode": 12, "scene": 26, "line": "You're in my way!", "order": 47383, "speaker": "Angela Martin"},
  {"season": 8, "episode": 12, "scene": 26, "line": "Andy, Dwight and I challenge you and Jess to a chicken fight. Winner take all.", "order": 47384, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 12, "scene": 26, "line": "Chicken fight!", "order": 47385, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 12, "scene": 26, "line": "No thanks.", "order": 47386, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 12, "scene": 26, "line": "Dang it! What the heck already?", "order": 47387, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 12, "scene": 26, "line": "Hey, Cathy. Chicken fight!", "order": 47388, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 12, "scene": 26, "line": "Okay, yeah. Who's going to be my partner? Where's Jim?", "order": 47389, "speaker": "Cathy Simms"},
  {"season": 8, "episode": 12, "scene": 26, "line": "I'm right here! [gets in pool] Cold. Cold. Come on, Cath.", "order": 47390, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 12, "scene": 27, "line": "Yeah- oh!", "order": 47391, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 12, "scene": 27, "line": "[knocks Cathy off Kevin's shoulders] Yes! Woo!", "order": 47392, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 12, "scene": 27, "line": "Woohoo! Yes!", "order": 47393, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 12, "scene": 27, "line": "Dwight, our chemistry is really clicking. We work so well together.", "order": 47394, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 12, "scene": 27, "line": "I know. I could just bang you right now.", "order": 47395, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 12, "scene": 27, "line": "He's not looking. [Dwight dumps her in the pool] Hey!", "order": 47396, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 12, "scene": 28, "line": "I had two bears sewn together to make this king-size. Total waste of two bears.", "order": 47397, "speaker": "Robert California"},
  {"season": 8, "episode": 12, "scene": 28, "line": "To both these bears.", "order": 47398, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 12, "scene": 28, "line": "To both these bears.", "order": 47399, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 12, "scene": 28, "line": "Bears.", "order": 47400, "speaker": "Toby Flenderson"},
  {"season": 8, "episode": 12, "scene": 28, "line": "To both these bears.", "order": 47401, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 12, "scene": 29, "line": "When I put in the screening room, I bought three movies: Caligula, Last Tango in Paris, and Emmanuelle 2. Last two movies I actually watched in here Marley and Me and On Golden [bleep] Pond.", "order": 47402, "speaker": "Robert California"},
  {"season": 8, "episode": 12, "scene": 29, "line": "I mean, it's clearly meant for watching erotic cinema.", "order": 47403, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 12, "scene": 29, "line": "Yup. We could watch some right now if you want. I got a Korean film on my iPod if you want to just- if you have the cables.", "order": 47404, "speaker": "Gabe Lewis"},
  {"season": 8, "episode": 12, "scene": 30, "line": "Kelly, that's a crazy ring you found.", "order": 47405, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 12, "scene": 30, "line": "Yeah, thanks. I'm really glad I found it.", "order": 47406, "speaker": "Kelly Kapoor"},
  {"season": 8, "episode": 12, "scene": 30, "line": "I can't believe you're wearing it. Are you not superstitious at all?", "order": 47407, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 12, "scene": 30, "line": "Shh. Of course I'm superstitious. What are you talking about?", "order": 47408, "speaker": "Kelly Kapoor"},
  {"season": 8, "episode": 12, "scene": 30, "line": "The ring of a failed marriage might have some sinister energy, right? Am I just being silly?", "order": 47409, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 12, "scene": 30, "line": "Oh, I don't think you're being silly.", "order": 47410, "speaker": "Phyllis Vance"},
  {"season": 8, "episode": 12, "scene": 30, "line": "Oh God. [takes off ring]", "order": 47411, "speaker": "Kelly Kapoor"},
  {"season": 8, "episode": 12, "scene": 30, "line": "You know what, I can just sell it and put the money in the party fund.", "order": 47412, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 12, "scene": 30, "line": "[snatches ring] Then another woman will get it. We can't allow that. We have to destroy it.", "order": 47413, "speaker": "Phyllis Vance"},
  {"season": 8, "episode": 12, "scene": 30, "line": "[to Andy] Come on. Let's chicken fight those two.", "order": 47414, "speaker": "Jessica"},
  {"season": 8, "episode": 12, "scene": 30, "line": "[comes up from under water] How was that?", "order": 47415, "speaker": "Val Johnson"},
  {"season": 8, "episode": 12, "scene": 30, "line": "Okay. Watch my toes. [does hand stand]", "order": 47416, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 12, "scene": 30, "line": "Hey Val. Want a beer? It might taste better than that pool water you've been drinking.", "order": 47417, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 12, "scene": 30, "line": "No, I'm good. Thank you.", "order": 47418, "speaker": "Val Johnson"},
  {"season": 8, "episode": 12, "scene": 30, "line": "Cool.", "order": 47419, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 12, "scene": 30, "line": "Cool. [to Kevin] Does Darryl not swim?", "order": 47420, "speaker": "Val Johnson"},
  {"season": 8, "episode": 12, "scene": 30, "line": "That's racist! I don't know. But I would say, by looking at him, no, Darryl does not swim.", "order": 47421, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 12, "scene": 31, "line": "[knocks Erin off Dwight's shoulders] Yes!", "order": 47422, "speaker": "Jessica"},
  {"season": 8, "episode": 12, "scene": 31, "line": "Yeah!", "order": 47423, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 12, "scene": 31, "line": "[to Erin] Maybe we should take a little break.", "order": 47424, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 12, "scene": 31, "line": "Dwight, we've got this. I promise. I will not leave your shoulders, no matter what.", "order": 47425, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 12, "scene": 31, "line": "Okay.", "order": 47426, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 12, "scene": 31, "line": "One more?", "order": 47427, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 12, "scene": 31, "line": "Yeah!", "order": 47428, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 12, "scene": 31, "line": "Yeah! Great! [others cheer and clap]", "order": 47429, "speaker": "Jessica"},
  {"season": 8, "episode": 12, "scene": 31, "line": "Mush, mush, mush! Come on!", "order": 47430, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 12, "scene": 31, "line": "Go!", "order": 47431, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 12, "scene": 31, "line": "Go! Okay. Yeah.", "order": 47432, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 12, "scene": 31, "line": "Here we go! [knocks Erin over] Yes!", "order": 47433, "speaker": "Jessica"},
  {"season": 8, "episode": 12, "scene": 31, "line": "Yay! [Erin comes back up] Whoa!", "order": 47434, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 12, "scene": 31, "line": "Wow!", "order": 47435, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 12, "scene": 31, "line": "I cannot believe you're still up!", "order": 47436, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 12, "scene": 31, "line": "I cannot believe it either.", "order": 47437, "speaker": "Jessica"},
  {"season": 8, "episode": 12, "scene": 31, "line": "I've got this! [Dwight gasps] Charge! Go! Go! [Dwight sinks]", "order": 47438, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 12, "scene": 31, "line": "[muffled] Dwight are you okay? Hey, damn it-", "order": 47439, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 12, "scene": 32, "line": "[coughs up water] Erin, did we win?", "order": 47440, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 12, "scene": 32, "line": "Sure. Sure we did.", "order": 47441, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 12, "scene": 32, "line": "You're lying. We didn't win.", "order": 47442, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 12, "scene": 32, "line": "Hey, hey, hey Dwight. It's okay. Just rest. Just rest.", "order": 47443, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 12, "scene": 33, "line": "You've broken up your last couple, you evil ring. Do it. [Meredith sets fire to paper ring boat]", "order": 47444, "speaker": "Kelly Kapoor"},
  {"season": 8, "episode": 12, "scene": 33, "line": "We're in the pool!", "order": 47445, "speaker": "Angela Martin"},
  {"season": 8, "episode": 12, "scene": 33, "line": "Shut it, Angela.", "order": 47446, "speaker": "Meredith Palmer"},
  {"season": 8, "episode": 12, "scene": 34, "line": "Ugh. Same old party, same old people. Am I right? Reminds me of Phyllis's birthday.", "order": 47447, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 12, "scene": 34, "line": "Ooh, do not remind me of Phyllis's birthday.", "order": 47448, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 12, "scene": 34, "line": "I know. [laughs] But boy, that Erin. She sure is a ripe little tiger, isn't she? Rroww! And to think, I always thought of her as a second Meredith. Respectfully, I don't want us walking into a similar Angela kind of situation.", "order": 47449, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 12, "scene": 34, "line": "Mmm.", "order": 47450, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 12, "scene": 34, "line": "So I just want to make sure that you are completely, one hundred percent done with Erin.", "order": 47451, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 12, "scene": 34, "line": "Last I checked, I'm with Jessica. And I like to get my monog on. It's monogamy for my hog 'n me.", "order": 47452, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 12, "scene": 34, "line": "Not what I asked.", "order": 47453, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 12, "scene": 34, "line": "We're done. Erin and I are over.", "order": 47454, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 12, "scene": 34, "line": "So then you won't mind if tonight I just go crazy on her, just go nuts, rrargh. With sex.", "order": 47455, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 12, "scene": 34, "line": "Have at it. Or take it slow. Whatever you guys work out.", "order": 47456, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 12, "scene": 34, "line": "[sighs] You're an idiot.", "order": 47457, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 12, "scene": 34, "line": "Kelly, that is mine! This is mine! [Val, Erin and Kevin play with pool noodles]", "order": 47458, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 12, "scene": 34, "line": "Uh-uh. Uh-uh.", "order": 47459, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 12, "scene": 34, "line": "No!", "order": 47460, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 12, "scene": 34, "line": "Oh I've got him!", "order": 47461, "speaker": "Val Johnson"},
  {"season": 8, "episode": 12, "scene": 34, "line": "Cannonball! [jumps in pool] Let's do this!", "order": 47462, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 12, "scene": 35, "line": "[Erin swims up with ring] Wow.", "order": 47463, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 12, "scene": 35, "line": "So I kind of stepped on this. I think it's yours.", "order": 47464, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 12, "scene": 35, "line": "Yeah. Oh wow. How did you know it was mine?", "order": 47465, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 12, "scene": 35, "line": "The Bernard family seal. Duh.", "order": 47466, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 12, "scene": 35, "line": "Duh.", "order": 47467, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 12, "scene": 35, "line": "Sorry if your special night was ruined.", "order": 47468, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 12, "scene": 35, "line": "Oh, whoa. Whoa, no, it's not a special night.", "order": 47469, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 12, "scene": 35, "line": "Oh, you weren't going to do that?", "order": 47470, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 12, "scene": 35, "line": "Honestly Erin, I don't know what I'm doing. I... I just... I don't know. Thank you.", "order": 47471, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 12, "scene": 36, "line": "Andy's confused. That's not what I was hoping for, but it's not so bad either. I can live with confused. I get confused. I totally get confused.", "order": 47472, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 12, "scene": 37, "line": "And of course, the pool. The ultimate lubricant for any wild evening. It was here that my parties would have crescendoed into true madness.", "order": 47473, "speaker": "Robert California"},
  {"season": 8, "episode": 12, "scene": 37, "line": "To madness.", "order": 47474, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 12, "scene": 37, "line": "To madness.", "order": 47475, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 12, "scene": 37, "line": "To madness. [Robert laughs]", "order": 47476, "speaker": "Toby Flenderson"},
  {"season": 8, "episode": 12, "scene": 37, "line": "To madness.", "order": 47477, "speaker": "Meredith Palmer"},
  {"season": 8, "episode": 12, "scene": 37, "line": "Hey, um, I think you parked my car in. Is there any way you can move your van?", "order": 47478, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 12, "scene": 37, "line": "Oh, I'm sorry. When I got here, I put my keys in a bowl.", "order": 47479, "speaker": "Meredith Palmer"},
  {"season": 8, "episode": 12, "scene": 37, "line": "Are you serious?", "order": 47480, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 12, "scene": 37, "line": "Robert, I want you to know, I'm here to rock with you as late as you want, man.", "order": 47481, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 12, "scene": 37, "line": "And that goes double for me. I'll stay even later than you'd like.", "order": 47482, "speaker": "Gabe Lewis"},
  {"season": 8, "episode": 12, "scene": 37, "line": "[Toby pours wine into Oscar's mouth] Toby! I am Bacchus, god of wine!", "order": 47483, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 12, "scene": 37, "line": "And I am Bacchus's friend!", "order": 47484, "speaker": "Toby Flenderson"},
  {"season": 8, "episode": 12, "scene": 37, "line": "Gentlemen, bear witness. While I've been mourning the nights that never were, one of them has been unfolding here before me. This is no get-together. This is a party. [Robert strips, jumps in pool, Gabe and Ryan join him]", "order": 47485, "speaker": "Robert California"},
  {"season": 8, "episode": 12, "scene": 37, "line": "Yes! Woohoo! Bravo!", "order": 47486, "speaker": "All"},
  {"season": 8, "episode": 12, "scene": 37, "line": "And there's my talking point.", "order": 47487, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 12, "scene": 38, "line": "Yes, that's it. Push yourselves, boys. It's not a party if you don't do something that scares you. I need a breather. Oh. Oh. You two keep going.", "order": 47488, "speaker": "Robert California"},
  {"season": 8, "episode": 12, "scene": 38, "line": "Hey, he's asleep. We can just leave.", "order": 47489, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 12, "scene": 38, "line": "So leave.", "order": 47490, "speaker": "Gabe Lewis"},
  {"season": 8, "episode": 12, "scene": 39, "line": "Two pools. A divider. A bridge. Good choice. It says everything about everything, right Robert?", "order": 47491, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 12, "scene": 39, "line": "What's the water like?", "order": 47492, "speaker": "Kathy"},
  {"season": 8, "episode": 12, "scene": 39, "line": "Nice.", "order": 47493, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 12, "scene": 39, "line": "Oh, it is warm.", "order": 47494, "speaker": "Angela Martin"},
  {"season": 8, "episode": 12, "scene": 40, "line": "Robert's house is, well....I don't mean to sound offensive, but it's like where a basketball player would live.", "order": 47495, "speaker": "Phyllis Vance"},
  {"season": 8, "episode": 12, "scene": 41, "line": "Oh, Dwight.", "order": 47496, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 12, "scene": 41, "line": "Uh huh.", "order": 47497, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 12, "scene": 41, "line": "That feels so good!", "order": 47498, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 12, "scene": 41, "line": "Yeah....baby.", "order": 47499, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 12, "scene": 41, "line": "[grunting] Hey, Andy.", "order": 47500, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 12, "scene": 41, "line": "Hey.", "order": 47501, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 12, "scene": 41, "line": "Dwight gives the best back massages.", "order": 47502, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 12, "scene": 41, "line": "Yeah, it sounds like it.", "order": 47503, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 12, "scene": 41, "line": "Hey, have you ever been checked for scoliosis?", "order": 47504, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 12, "scene": 41, "line": "I don't know, why don't you check me Dr. Shrute. [laughing]", "order": 47505, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 12, "scene": 41, "line": "No, seriously. Your spine is jacked. It's like the devil's cursive.", "order": 47506, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 12, "scene": 41, "line": "You mean more like dangerous curves...", "order": 47507, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 12, "scene": 41, "line": "You'd be like 8 feet tall if this thing was straightened out...[pushes Erin back to floor]", "order": 47508, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 12, "scene": 41, "line": "Ow!", "order": 47509, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 12, "scene": 42, "line": "You date a guy, you find out he was engaged to your coworker so you throw cake at him. It's over. You start liking him again so you ask him out with a puppet show. He says no. Then he follows you home to make sure that you don't kiss somebody. Then he ignores you at a pool party? Am I right ladies?", "order": 47510, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 12, "scene": 43, "line": "[Erin helps Dwight stretch his inner thighs] Yeah. Oh, yeah. Oooooh, yeah.", "order": 47511, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 12, "scene": 43, "line": "You guys ready?", "order": 47512, "speaker": "Jessica"},
  {"season": 8, "episode": 12, "scene": 43, "line": "Bring it on guys.", "order": 47513, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 12, "scene": 43, "line": "Yeah...", "order": 47514, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 12, "scene": 43, "line": "Let's go before I vomit. [Erin and Dwight enter pool]", "order": 47515, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 12, "scene": 43, "line": "Ready?", "order": 47516, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 12, "scene": 43, "line": "I'm ready.", "order": 47517, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 12, "scene": 43, "line": "Whoo!", "order": 47518, "speaker": "Jessica"},
  {"season": 8, "episode": 12, "scene": 43, "line": "Alright! Here we go.", "order": 47519, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 12, "scene": 43, "line": "Ok.", "order": 47520, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 12, "scene": 43, "line": "RAHHH!!!!!!!", "order": 47521, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 12, "scene": 43, "line": "Yeah, come on!", "order": 47522, "speaker": "Jessica"},
  {"season": 8, "episode": 12, "scene": 43, "line": "Get her! Ruin her! [Andy and Jessica beat Dwight and Erin]", "order": 47523, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 12, "scene": 43, "line": "Hey! High-five!", "order": 47524, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 12, "scene": 43, "line": "Yeah!", "order": 47525, "speaker": "Jessica"},
  {"season": 8, "episode": 12, "scene": 44, "line": "My brother and I were the chicken fight champions of our swim club growing up. I mean, we beat the Strauss twins. Ok, I guess somebody didn't grow up in west Hartford, Connecticut.", "order": 47526, "speaker": "Jessica"},
  {"season": 8, "episode": 12, "scene": 45, "line": "This room I liked for the view. I dreamed that I would watch my guests walk to their cars at dawn, their faces flushed with the shame and regret for the choices they made the night before. [laughs]", "order": 47527, "speaker": "Robert California"},
  {"season": 8, "episode": 12, "scene": 45, "line": "I get that.", "order": 47528, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 12, "scene": 45, "line": "Beautiful sentiment.", "order": 47529, "speaker": "Gabe Lewis"},
  {"season": 8, "episode": 12, "scene": 45, "line": "To the shame room! [raises bottle]", "order": 47530, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 12, "scene": 45, "line": "[Robert laughs] I live in this room.", "order": 47531, "speaker": "Gabe Lewis"},
  {"season": 8, "episode": 12, "scene": 45, "line": "[Toby and Oscar swig from wine bottles] Oh, Mineral-ey.", "order": 47532, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 12, "scene": 45, "line": "Mineral-ey.", "order": 47533, "speaker": "Toby Flenderson"},
  {"season": 8, "episode": 13, "scene": 1, "line": "Val, I need the space.", "order": 47534, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 13, "scene": 1, "line": "All right guys, clear out.", "order": 47535, "speaker": "Val Johnson"},
  {"season": 8, "episode": 13, "scene": 1, "line": "Everybody out.", "order": 47536, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 13, "scene": 1, "line": "Promise me you're going to clean up.", "order": 47537, "speaker": "Val Johnson"},
  {"season": 8, "episode": 13, "scene": 1, "line": "I can't promise what I'm going to do or not do.", "order": 47538, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 13, "scene": 1, "line": "Promise me that-", "order": 47539, "speaker": "Val Johnson"},
  {"season": 8, "episode": 13, "scene": 1, "line": "Obviously I'm going to clean up.", "order": 47540, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 13, "scene": 2, "line": "Stress is like the uptight mayor or a town who's saying, 'Hey, we're uptight, you can't dance,' and then you have to be like, 'Oh yeah, Mayor Stress? Well watch this!' And then... we dance. Oh how we dance. [dances to Kenny Loggin's Footloose] Sex also works.", "order": 47541, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 13, "scene": 3, "line": "Hey! Jim's back from jury duty.", "order": 47542, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 13, "scene": 3, "line": "Hey! Tuna! He's back.", "order": 47543, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 13, "scene": 3, "line": "Hey.", "order": 47544, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 13, "scene": 3, "line": "Oh, look at this. [hugs Jim] Tuna wrap.", "order": 47545, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 13, "scene": 3, "line": "Okay.", "order": 47546, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 13, "scene": 3, "line": "[grabs Jim's wrist] Hand roll.", "order": 47547, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 13, "scene": 3, "line": "Yeah.", "order": 47548, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 13, "scene": 3, "line": "How was jury duty?", "order": 47549, "speaker": "Phyllis Vance"},
  {"season": 8, "episode": 13, "scene": 3, "line": "It was pretty uneventful, actually.", "order": 47550, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 13, "scene": 3, "line": "Can't believe they let someone like you serve on a jury. Makes me question the whole judicial system.", "order": 47551, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 13, "scene": 3, "line": "What was the case?", "order": 47552, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 13, "scene": 3, "line": "Uh, hit and run.", "order": 47553, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 13, "scene": 3, "line": "Ooh, 'the case of the hit and run,' that's exciting.", "order": 47554, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 13, "scene": 3, "line": "Did you send him to the slammer?", "order": 47555, "speaker": "Phyllis Vance"},
  {"season": 8, "episode": 13, "scene": 3, "line": "Nope. Not guilty.", "order": 47556, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 13, "scene": 3, "line": "Of course you found him not guilty. [mocking voice] 'Oh, he apologized and I just really want him to like me, mm.'", "order": 47557, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 13, "scene": 3, "line": "We're best friends, actually. We're going wine-tasting next weekend, if you want to come.", "order": 47558, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 13, "scene": 3, "line": "Not coming, have plans.", "order": 47559, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 13, "scene": 3, "line": "Okay.", "order": 47560, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 13, "scene": 4, "line": "I did get called in to jury duty. And they released me around noon, so, didn't think it was worth it to come back to work for a half day. And then the next morning Pam was a little overwhelmed with the kids so I took an extra day to help out. And then three other days happened.", "order": 47561, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 13, "scene": 5, "line": "Ah! Angela had the baby!", "order": 47562, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 13, "scene": 5, "line": "Is it black? 'Cause that would be hilarious.", "order": 47563, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 13, "scene": 5, "line": "Why?", "order": 47564, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 13, "scene": 5, "line": "You know.", "order": 47565, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 13, "scene": 5, "line": "Would it be more hilarious than Angela having a Chinese baby?", "order": 47566, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 13, "scene": 5, "line": "A little bit, yeah. Right guys? Back me up.", "order": 47567, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 13, "scene": 5, "line": "Eh, a little bit.", "order": 47568, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 13, "scene": 5, "line": "Oh, did I win the pool?", "order": 47569, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 13, "scene": 5, "line": "Ah, no. Right month, wrong year.", "order": 47570, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 13, "scene": 5, "line": "Well no one won the pool. Angela wasn't due for another month.", "order": 47571, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 13, "scene": 5, "line": "We should all go to the hospital and visit her.", "order": 47572, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 13, "scene": 5, "line": "Oh, I'd love to but, um-", "order": 47573, "speaker": "Phyllis Vance"},
  {"season": 8, "episode": 13, "scene": 5, "line": "Aw man, I would but I can't miss any more work.", "order": 47574, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 13, "scene": 5, "line": "Well Kevin and I have to go, Accounting is its own little family.", "order": 47575, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 13, "scene": 5, "line": "Yeah, Oscar's the dad, I'm Oscar's dad and Angela's my mom.", "order": 47576, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 13, "scene": 5, "line": "Everyone, our very own Angela-", "order": 47577, "speaker": "Gabe Lewis"},
  {"season": 8, "episode": 13, "scene": 5, "line": "We all got the email, some of us are going to go visit.", "order": 47578, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 13, "scene": 5, "line": "Oh, I am so in.", "order": 47579, "speaker": "Gabe Lewis"},
  {"season": 8, "episode": 13, "scene": 6, "line": "I love maternity wards. It's the perfect blend of love and horror. Things can go so wrong or so right.", "order": 47580, "speaker": "Gabe Lewis"},
  {"season": 8, "episode": 13, "scene": 7, "line": "Come on, Judge Judy, tell us your case. What were the deets?", "order": 47581, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 13, "scene": 7, "line": "Mine was actually a pretty boring case, so-", "order": 47582, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 13, "scene": 7, "line": "How could it be boring? You were there for five days. Titillate us.", "order": 47583, "speaker": "Meredith Palmer"},
  {"season": 8, "episode": 13, "scene": 7, "line": "Yeah, you owe us. I had to stay until six twice to cover for you. My wife got to the TV first, had to sit through damn Rizzoli and damn Isles.", "order": 47584, "speaker": "Stanley Hudson"},
  {"season": 8, "episode": 13, "scene": 7, "line": "Yeah, my car got smashed visiting one of your clients and I had to have it detailed and they took my meter change.", "order": 47585, "speaker": "Phyllis Vance"},
  {"season": 8, "episode": 13, "scene": 7, "line": "Wow, I'm really sorry.", "order": 47586, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 13, "scene": 7, "line": "Yeah.", "order": 47587, "speaker": "Phyllis Vance"},
  {"season": 8, "episode": 13, "scene": 8, "line": "I never considered that my week off would make everyone else's job harder. The least I could do is give them a good story.", "order": 47588, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 13, "scene": 9, "line": "[demonstrating with napkin holder and salt shaker] So Phyllis, he was here, and then the car came and did- he was like that. Yeah, so it had to be a double backflip, actually.", "order": 47589, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 13, "scene": 9, "line": "You know, when I was on j-duty, uh, Strangler case, we used to recreate the various stranglings with empanadas from- at Ernesto's.", "order": 47590, "speaker": "Toby Flenderson"},
  {"season": 8, "episode": 13, "scene": 9, "line": "Oh man, Ernesto's. That was our favorite restaurant too.", "order": 47591, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 13, "scene": 9, "line": "You mean food truck. Ernesto's was a food truck.", "order": 47592, "speaker": "Toby Flenderson"},
  {"season": 8, "episode": 13, "scene": 9, "line": "Toby, shut your hole about the empanadas. Nobody cares about the empanadas.", "order": 47593, "speaker": "Meredith Palmer"},
  {"season": 8, "episode": 13, "scene": 10, "line": "I was in Los Angeles last year. Jim tricked me into thinking I'd won a walk-on part on NCIS, but that's not important. While I was being kicked off the lot, I saw food trucks everywhere. Everyone in Los Angeles eats from them. And nobody calls them restaurants.", "order": 47594, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 13, "scene": 11, "line": "[whispering] Guys, I don't know how many of you have seen- Gabe? Bring it in. I don't know how many of you have seen a premature baby before. It's going to be really tiny, so please don't say anything offensive.", "order": 47595, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 13, "scene": 11, "line": "Got that, bimbo?", "order": 47596, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 13, "scene": 11, "line": "Got it, bimbo. [Oscar knocks at the door]", "order": 47597, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 13, "scene": 11, "line": "Yes?", "order": 47598, "speaker": "Angela Martin"},
  {"season": 8, "episode": 13, "scene": 11, "line": "Knock, knock. Oh!", "order": 47599, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 13, "scene": 11, "line": "Hi!", "order": 47600, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 13, "scene": 11, "line": "Oh... I don't- I don't want any- what are you guys doing here?", "order": 47601, "speaker": "Angela Martin"},
  {"season": 8, "episode": 13, "scene": 11, "line": "We wanted to say hi to the newest little Dunder Mifflinite.", "order": 47602, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 13, "scene": 11, "line": "Well, meet our not-so-little bundle of joy, Philip Halsted Lipton.", "order": 47603, "speaker": "Senator Lipton"},
  {"season": 8, "episode": 13, "scene": 11, "line": "Phillip is so fat.", "order": 47604, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 13, "scene": 11, "line": "Kevin!", "order": 47605, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 13, "scene": 11, "line": "You warned me not to say anything if it was tiny, Oscar. But you didn't prepare me for a big, giant, fat baby.", "order": 47606, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 13, "scene": 11, "line": "Hey.", "order": 47607, "speaker": "Angela Martin"},
  {"season": 8, "episode": 13, "scene": 11, "line": "Yes, he's substantial.", "order": 47608, "speaker": "Senator Lipton"},
  {"season": 8, "episode": 13, "scene": 11, "line": "He's more than substantial. He's a monster.", "order": 47609, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 13, "scene": 12, "line": "So this whole hit and run thing, there's just one part I can't figure out. You said it was at a four-way stop...", "order": 47610, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 13, "scene": 12, "line": "Mm-hmm.", "order": 47611, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 13, "scene": 12, "line": "And the victim rode his bicycle into the left hand turn lane but the perp was already in the left-hand turn lane?", "order": 47612, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 13, "scene": 12, "line": "Mm-hmm.", "order": 47613, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 13, "scene": 12, "line": "See, that's what I'm having trouble with, because the fact is, you never said he was on a bicycle. [villainous laugh] Busted, Halpert!", "order": 47614, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 13, "scene": 12, "line": "Okay, wait, wait.", "order": 47615, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 13, "scene": 12, "line": "Yes! Andy, get out here.", "order": 47616, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 13, "scene": 12, "line": "No. I never said he was on a bicycle. I just said I wasn't listening to you.", "order": 47617, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 13, "scene": 12, "line": "I am making a citizen's arrest. You have the right to remain silent. You have the right to beg for mercy. You have the right to request judgment by combat. Dwight's rights.", "order": 47618, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 13, "scene": 12, "line": "What's up, gangstas?", "order": 47619, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 13, "scene": 12, "line": "Jim was lying about jury duty. He was lying the whole time so he could go do yoga or go canoeing. I don't know what this pervert likes. Fire him!", "order": 47620, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 13, "scene": 12, "line": "Dwight, not everything is a conspiracy theory.", "order": 47621, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 13, "scene": 12, "line": "I have Jim on the record saying that the vic-", "order": 47622, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 13, "scene": 12, "line": "No. Dwight! I have me on the record, saying to you, take a chill pill.", "order": 47623, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 13, "scene": 12, "line": "I don't- I don't need to take a chill pill.", "order": 47624, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 13, "scene": 12, "line": "Here, right there. [mimes giving Dwight a pill and a glass] Down the hatch.", "order": 47625, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 13, "scene": 12, "line": "I really don't want to take the chill pill. [mimes taking pill]", "order": 47626, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 13, "scene": 12, "line": "Attaboy. Good. Now, chill.", "order": 47627, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 13, "scene": 12, "line": "Okay, okay. [sits, exhales] But let's just say that Jim was lying about jury duty. You would have to fire him, right?", "order": 47628, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 13, "scene": 12, "line": "Yeah, sure, of course. I'd fire him to Timbuktu. [Jim laughs nervously]", "order": 47629, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 13, "scene": 12, "line": "That's good enough for me. [exhales again]", "order": 47630, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 13, "scene": 13, "line": "...and Pam was just going crazy, trying to take care of Philip because Cece has been out of control recently, and- look, Andy, it doesn't matter. The truth is, I just feel terrible lying to you.", "order": 47631, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 13, "scene": 13, "line": "I feel terrible getting lied to. No one's ever lied to me before.", "order": 47632, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 13, "scene": 13, "line": "Okay. I think the best thing to do right now is just come clean and tell everybody the truth.", "order": 47633, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 13, "scene": 13, "line": "No, not after what I told Dwight about firing you. No. Here's what we're going to do. We're going to have ourselves a good old-fashioned cover-up. Have you ever heard of a Connecticut cover-up?", "order": 47634, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 13, "scene": 13, "line": "No.", "order": 47635, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 13, "scene": 13, "line": "Do you know why you never heard of it?", "order": 47636, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 13, "scene": 13, "line": "No.", "order": 47637, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 13, "scene": 13, "line": "Covered it up.", "order": 47638, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 13, "scene": 13, "line": "Are you sure you can keep this under wraps?", "order": 47639, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 13, "scene": 13, "line": "They used to call me King Tut because I'm so good at keeping things under wraps.", "order": 47640, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 13, "scene": 14, "line": "My nickname was actually King Butt, because I had a king-size butt.", "order": 47641, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 13, "scene": 15, "line": "[pulls food truck up to where Dwight and Nate are standing] Where you want I park?", "order": 47642, "speaker": "Ernesto"},
  {"season": 8, "episode": 13, "scene": 16, "line": "Okay, everybody, gather 'round. Got a real special surprise for you. [indicates Ernesto and assistant who are carrying empanadas]", "order": 47643, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 13, "scene": 16, "line": "Ernesto!", "order": 47644, "speaker": "Toby Flenderson"},
  {"season": 8, "episode": 13, "scene": 16, "line": "Hola Toby! [mimes choking]", "order": 47645, "speaker": "Ernesto"},
  {"season": 8, "episode": 13, "scene": 16, "line": "Yeah, hey.", "order": 47646, "speaker": "Toby Flenderson"},
  {"season": 8, "episode": 13, "scene": 16, "line": "Como esta? [speaks Spanish]", "order": 47647, "speaker": "Ernesto"},
  {"season": 8, "episode": 13, "scene": 16, "line": "Uh, I'm going to say 'I' when I'm talking for Ernesto, so instead of 'he says blah, blah, blah,' I'm going to say 'I say, blah, blah, blah,' but that's for him. Anyway, it was something like, 'He remembers Toby.' [makes face] 'I remember Toby.'", "order": 47648, "speaker": "Nate Nickerson"},
  {"season": 8, "episode": 13, "scene": 16, "line": "Okay- oh, Jim. Hey, this guy look familiar?", "order": 47649, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 13, "scene": 16, "line": "Uh-", "order": 47650, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 13, "scene": 16, "line": "It's the empanada guy.", "order": 47651, "speaker": "Toby Flenderson"},
  {"season": 8, "episode": 13, "scene": 16, "line": "The empanada guy!", "order": 47652, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 13, "scene": 16, "line": "No, Toby! [bleep] Damn it, Toby! No, I wasn't asking you. [sighs] Ask him if he remembers Jim. [Nate asks Ernesto a question in Spanish]", "order": 47653, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 13, "scene": 16, "line": "No.", "order": 47654, "speaker": "Ernesto"},
  {"season": 8, "episode": 13, "scene": 16, "line": "He says, 'No.'", "order": 47655, "speaker": "Nate Nickerson"},
  {"season": 8, "episode": 13, "scene": 16, "line": "He says, 'No!' Boom!", "order": 47656, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 13, "scene": 16, "line": "Yeah but who remembers all their customers? I mean, I can name like three of our clients.", "order": 47657, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 13, "scene": 16, "line": "Okay, he remembers Toby, the most forgettable man in the known world. [Ernesto says something in Spanish]", "order": 47658, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 13, "scene": 16, "line": "Ah, he doesn't want any trouble, he sees lots of people, they come from all around to eat his delicious meat pockets.", "order": 47659, "speaker": "Nate Nickerson"},
  {"season": 8, "episode": 13, "scene": 16, "line": "Uh, you guys are going to love these. So, empanadas on me. You guys gotta try them.", "order": 47660, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 13, "scene": 16, "line": "Usually I'm a burrito guy, but if you won't tell, I won't. Wink, wink.", "order": 47661, "speaker": "Creed Bratton"},
  {"season": 8, "episode": 13, "scene": 16, "line": "Great. And while we're enjoying these delicious empanadas, Jim, why don't you tell us your story again?", "order": 47662, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 13, "scene": 16, "line": "Why? Everybody's heard it.", "order": 47663, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 13, "scene": 16, "line": "Walk us through it. What time would you get there every morning?", "order": 47664, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 13, "scene": 16, "line": "Hey, Murder She Wrote. How about we drop the whole 'Murder, She Wrote' thing, okay? Jim was at the courthouse for jury duty every morning. How do I know? Because I drove him there.", "order": 47665, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 13, "scene": 16, "line": "Why?", "order": 47666, "speaker": "Phyllis Vance"},
  {"season": 8, "episode": 13, "scene": 16, "line": "Why? Why? [looks at Jim]", "order": 47667, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 13, "scene": 16, "line": "Because my car broke down.", "order": 47668, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 13, "scene": 16, "line": "His car broke down. So he called me, 'cause I live near the courthouse.", "order": 47669, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 13, "scene": 16, "line": "Wait, wait. You live by the courthouse. So you drove from near the courthouse, out to Jim's house, and then back to the courthouse?", "order": 47670, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 13, "scene": 16, "line": "Thirty minutes out, thirty minutes back, easy hour. And I would watch that cute little tushie scurry up those courthouse stairs every morning and that was that.", "order": 47671, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 13, "scene": 16, "line": "[to Ernesto] I'm sorry, do you have any American Mexican food?", "order": 47672, "speaker": "Phyllis Vance"},
  {"season": 8, "episode": 13, "scene": 17, "line": "We're getting buried out there. What was that stuff about the car breaking down?", "order": 47673, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 13, "scene": 17, "line": "Well, I think you handled it pretty well.", "order": 47674, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 13, "scene": 17, "line": "I'm not Rumpelstiltskin, Jim. I can't keep spinning gold out of your [bleep].", "order": 47675, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 13, "scene": 17, "line": "Okay, listen, all right? Dwight's on to us, he's going to figure it out really soon, so let's just get ahead of it, let's tell the truth.", "order": 47676, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 13, "scene": 17, "line": "[sighs] I don't even know what the truth is anymore.", "order": 47677, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 13, "scene": 18, "line": "Wow, it's so... healthy.", "order": 47678, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 13, "scene": 18, "line": "I'm going to call him Little Kevin.", "order": 47679, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 13, "scene": 18, "line": "No, you will not.", "order": 47680, "speaker": "Angela Martin"},
  {"season": 8, "episode": 13, "scene": 18, "line": "Is he really five pounds?", "order": 47681, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 13, "scene": 18, "line": "Mm-hmm.", "order": 47682, "speaker": "Angela Martin"},
  {"season": 8, "episode": 13, "scene": 18, "line": "Because I squat with five pounds. This- [squats, grunts] yeah, this feels like more than that.", "order": 47683, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 13, "scene": 18, "line": "No, no, no, he's nine pounds, seven ounces.", "order": 47684, "speaker": "Senator Lipton"},
  {"season": 8, "episode": 13, "scene": 18, "line": "Nine pounds? Really? I thought you said five. You know what? I was under so many drugs, I felt like I was at a James Taylor concert or something, oh. So, what did you bring?", "order": 47685, "speaker": "Angela Martin"},
  {"season": 8, "episode": 13, "scene": 18, "line": "Oh yeah, I don't know if it's right, but-", "order": 47686, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 13, "scene": 18, "line": "Well, if it's anything like that gorgeous wrapping paper, you hit it out of the park.", "order": 47687, "speaker": "Senator Lipton"},
  {"season": 8, "episode": 13, "scene": 18, "line": "Aw, preemie pajamas!", "order": 47688, "speaker": "Angela Martin"},
  {"season": 8, "episode": 13, "scene": 18, "line": "Again, it may not be the right size. I can return-", "order": 47689, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 13, "scene": 18, "line": "No, I think he came early just so he could wear these. Thank you.", "order": 47690, "speaker": "Angela Martin"},
  {"season": 8, "episode": 13, "scene": 18, "line": "I got Little Kevin Call of Duty.", "order": 47691, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 13, "scene": 19, "line": "This baby is clearly not premature. They're lying about the date it was conceived. It's very interesting. And her husband's gay. I don't even know which thread to follow.", "order": 47692, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 13, "scene": 20, "line": "Uh, excuse me, can I have everyone's attention for a second? First off, I just want to say that I'm really sorry, I didn't know that my absence last week would have been a burden on any of you, because, though I did have jury duty last week, they did dismiss me early on Monday. And I think you're going to find this pretty hilarious. I kind of, uh, well, maybe took the week off.", "order": 47693, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 13, "scene": 20, "line": "What?", "order": 47694, "speaker": "Stanley Hudson"},
  {"season": 8, "episode": 13, "scene": 20, "line": "Funny? No? Nobody laughing?", "order": 47695, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 13, "scene": 20, "line": "[laughs] You're screwed! Oh, it's happening. It's really happening.", "order": 47696, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 13, "scene": 20, "line": "What the hell, Jim? I covered for your bony butt.", "order": 47697, "speaker": "Stanley Hudson"},
  {"season": 8, "episode": 13, "scene": 20, "line": "Pam really needed some help with the kids, so, I promise my intentions were good.", "order": 47698, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 13, "scene": 20, "line": "Your clients. They're all mine.", "order": 47699, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 13, "scene": 20, "line": "Jim, I got my ass chewed out because one of your orders got messed up. And Andy, you said you drove him to the courthouse.", "order": 47700, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 13, "scene": 20, "line": "Uh, I did, every morning. And if I didn't, then I hope I die.", "order": 47701, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 13, "scene": 20, "line": "All right, well. Let's get this show on the road, shall we? Allow me to give you a hand. [puts Jim's things in a box] Here we are. So long, clump-mate. I'm going to miss you. Truth be told, I never thought that this was how it was going to end. I always thought that I was going to defeat you somehow. But you've defeated yourself. [laughs]", "order": 47702, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 13, "scene": 20, "line": "Dwight, cut it out. I'm not firing Jim.", "order": 47703, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 13, "scene": 20, "line": "No, no, no. You said- you said that you were-", "order": 47704, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 13, "scene": 20, "line": "I know what I said. Jim, you're in deep doo-doo. Do you understand? I have one thing to say to you, and I'm going to say it in front of this whole office. Look me in the eye. [gives Jim a limp slap]", "order": 47705, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 13, "scene": 20, "line": "That's it? This is crap! [dumps the box on Jim's desk and leaves]", "order": 47706, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 13, "scene": 21, "line": "[on voicemail] You've reached Gabe Lewis, I'm currently indisposed. Leave me a message. Ciao. [beep]", "order": 47707, "speaker": "Gabe Lewis"},
  {"season": 8, "episode": 13, "scene": 21, "line": "Gabe, it's Dwight again. Highest priority, triple-urgent. Call me. Immediately. That means ASAP.", "order": 47708, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 13, "scene": 22, "line": "I'm gonna find Gabe, tell him what Jim did and let the little stickler do what he does best: stickle.", "order": 47709, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 13, "scene": 23, "line": "Shh.", "order": 47710, "speaker": "Angela Martin"},
  {"season": 8, "episode": 13, "scene": 23, "line": "Honestly, I can return this for a larger size.", "order": 47711, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 13, "scene": 23, "line": "It's fine. Pajamas aren't supposed to be baggy, right? It's not the barrio.", "order": 47712, "speaker": "Angela Martin"},
  {"season": 8, "episode": 13, "scene": 23, "line": "The only premature baby in this room is the baby this baby ate.", "order": 47713, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 13, "scene": 23, "line": "Ah!", "order": 47714, "speaker": "Angela Martin"},
  {"season": 8, "episode": 13, "scene": 23, "line": "[giggles] Little Kevin.", "order": 47715, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 13, "scene": 23, "line": "Really?", "order": 47716, "speaker": "Angela Martin"},
  {"season": 8, "episode": 13, "scene": 23, "line": "Angela.", "order": 47717, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 13, "scene": 23, "line": "Fine, God. Philip was conceived nine months ago.", "order": 47718, "speaker": "Angela Martin"},
  {"season": 8, "episode": 13, "scene": 23, "line": "I knew that, I knew it.", "order": 47719, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 13, "scene": 23, "line": "The senator and I wanted to wait, but we had just seen Thor and there was way too much wine in my chicken piccata.", "order": 47720, "speaker": "Angela Martin"},
  {"season": 8, "episode": 13, "scene": 23, "line": "Chicken marsala.", "order": 47721, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 13, "scene": 23, "line": "Right. Um, point is, Philip was conceived out of wedlock.", "order": 47722, "speaker": "Angela Martin"},
  {"season": 8, "episode": 13, "scene": 23, "line": "Mm-hmm.", "order": 47723, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 13, "scene": 23, "line": "And now you all know, but you can never tell. I'm serious.", "order": 47724, "speaker": "Angela Martin"},
  {"season": 8, "episode": 13, "scene": 24, "line": "Dwight. Hey.", "order": 47725, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 13, "scene": 24, "line": "Oh, hey. Have you seen Gabe?", "order": 47726, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 13, "scene": 24, "line": "He went to the car or something, but he'll be back.", "order": 47727, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 13, "scene": 24, "line": "Okay. [sits]", "order": 47728, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 13, "scene": 24, "line": "Don't you want to see the baby?", "order": 47729, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 13, "scene": 24, "line": "Psh! Why? I know what Angela and the senator look like. I can mash that up in my head right now.", "order": 47730, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 13, "scene": 24, "line": "I promised I wouldn't tell.", "order": 47731, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 13, "scene": 24, "line": "So don't.", "order": 47732, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 13, "scene": 24, "line": "Angela got pregnant before the wedding.", "order": 47733, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 13, "scene": 24, "line": "What?", "order": 47734, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 13, "scene": 24, "line": "She got pregnant before the wedding.", "order": 47735, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 13, "scene": 24, "line": "How long before?", "order": 47736, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 13, "scene": 24, "line": "A month. [Dwight leaves, Oscar calls after him] You didn't hear it from me!", "order": 47737, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 13, "scene": 24, "line": "Yes I did.", "order": 47738, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 13, "scene": 25, "line": "Dwight?", "order": 47739, "speaker": "Angela Martin"},
  {"season": 8, "episode": 13, "scene": 25, "line": "Dwight.", "order": 47740, "speaker": "Senator Lipton"},
  {"season": 8, "episode": 13, "scene": 25, "line": "I want to see the baby.", "order": 47741, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 13, "scene": 25, "line": "Oh, Angela will make you cut your fingernails. It's not worth it.", "order": 47742, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 13, "scene": 25, "line": "Oh, yes. Oh, what a beautiful child. Prominent forehead, short arms, tiny nose. You will lead millions... [whispers] willingly, or as slaves.", "order": 47743, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 13, "scene": 26, "line": "That baby is a Schrute. And unless somebody taught Mose sex, that baby is mine.", "order": 47744, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 13, "scene": 27, "line": "He's hungry.", "order": 47745, "speaker": "Angela Martin"},
  {"season": 8, "episode": 13, "scene": 27, "line": "Oh, that's my cue to leave.", "order": 47746, "speaker": "Senator Lipton"},
  {"season": 8, "episode": 13, "scene": 27, "line": "No, you don't have to. I'm going to wear this cover.", "order": 47747, "speaker": "Angela Martin"},
  {"season": 8, "episode": 13, "scene": 27, "line": "Still. Still.", "order": 47748, "speaker": "Senator Lipton"},
  {"season": 8, "episode": 13, "scene": 27, "line": "You won't see-", "order": 47749, "speaker": "Angela Martin"},
  {"season": 8, "episode": 13, "scene": 28, "line": "[to Cece] You want a giraffe?", "order": 47750, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 13, "scene": 29, "line": "Yes, I am brining my kids in to help me get out of this hole. And you all remember my beautiful wife, Pam.", "order": 47751, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 13, "scene": 29, "line": "Hi. Wow, I really thought I'd be more excited to be here.", "order": 47752, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 13, "scene": 29, "line": "Whoa, whoa, whoa, game face, baby, game face.", "order": 47753, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 13, "scene": 29, "line": "Oh right, okay. Hey Stanley, it's great to see you!", "order": 47754, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 13, "scene": 29, "line": "Whoa, no, no. That's overdoing it, I think.", "order": 47755, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 13, "scene": 29, "line": "Oh, hi Stanley.", "order": 47756, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 13, "scene": 29, "line": "Split the difference?", "order": 47757, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 13, "scene": 29, "line": "Jim.", "order": 47758, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 13, "scene": 29, "line": "Okay, let's go.", "order": 47759, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 13, "scene": 30, "line": "Hey.", "order": 47760, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 13, "scene": 30, "line": "Hi.", "order": 47761, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 13, "scene": 30, "line": "Hi everybody!", "order": 47762, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 13, "scene": 30, "line": "What?", "order": 47763, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 13, "scene": 30, "line": "Hi.", "order": 47764, "speaker": "All"},
  {"season": 8, "episode": 13, "scene": 30, "line": "How about a little visit?", "order": 47765, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 13, "scene": 30, "line": "Wow, what a surprise. That's crazy. You guys get to meet the little heck-raisers.", "order": 47766, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 13, "scene": 30, "line": "Hey, Angela's back with her baby.", "order": 47767, "speaker": "Creed Bratton"},
  {"season": 8, "episode": 13, "scene": 30, "line": "Yes, well, you guys all know Cece, but we wanted to introduce you to baby Philip.", "order": 47768, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 13, "scene": 30, "line": "Aw! You guys. He's licking on my finger, just like my cat does.", "order": 47769, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 13, "scene": 30, "line": "Let me have a turn.", "order": 47770, "speaker": "Creed Bratton"},
  {"season": 8, "episode": 13, "scene": 30, "line": "No, it's the pacifier's turn.", "order": 47771, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 13, "scene": 30, "line": "All right.", "order": 47772, "speaker": "Creed Bratton"},
  {"season": 8, "episode": 13, "scene": 30, "line": "Did you say something about this one bringing in something for these people?", "order": 47773, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 13, "scene": 30, "line": "[gasps] Yes! Cece wanted to thank everybody for letting her daddy stay home with her all last week and play, so she brought you a little treat.", "order": 47774, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 13, "scene": 30, "line": "Cookies?", "order": 47775, "speaker": "Phyllis Vance"},
  {"season": 8, "episode": 13, "scene": 30, "line": "No, but that would've been a really good idea.", "order": 47776, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 13, "scene": 30, "line": "That was a great idea.", "order": 47777, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 13, "scene": 30, "line": "No, she brought you drawings.", "order": 47778, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 13, "scene": 30, "line": "Oh my goodness, let's take a look at these. They're usually amazing, so let's see. Yup, they are. Uncle Andy.", "order": 47779, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 13, "scene": 30, "line": "Oh!", "order": 47780, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 13, "scene": 30, "line": "Oh, Aunt Phyllis.", "order": 47781, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 13, "scene": 30, "line": "Oh.", "order": 47782, "speaker": "Phyllis Vance"},
  {"season": 8, "episode": 13, "scene": 30, "line": "Wow, these are incredible. Cece, did you do these?", "order": 47783, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 13, "scene": 30, "line": "No.", "order": 47784, "speaker": "Cece"},
  {"season": 8, "episode": 13, "scene": 30, "line": "She says 'no' to everything. You know, she thinks my name is 'No.' Cece, do you want some broccoli?", "order": 47785, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 13, "scene": 30, "line": "Yes.", "order": 47786, "speaker": "Cece"},
  {"season": 8, "episode": 13, "scene": 30, "line": "No. It's crazy.", "order": 47787, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 13, "scene": 30, "line": "Why am I shorter than the table that I'm standing next to?", "order": 47788, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 13, "scene": 30, "line": "There's cross-hatching in some of these. That's kind of advanced for a two-year-old.", "order": 47789, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 13, "scene": 30, "line": "Cece, this is your big sister Kelly. Did you color this pretty picture?", "order": 47790, "speaker": "Kelly Kapoor"},
  {"season": 8, "episode": 13, "scene": 30, "line": "No.", "order": 47791, "speaker": "Cece"},
  {"season": 8, "episode": 13, "scene": 30, "line": "So then this means nothing to you. [rips picture]", "order": 47792, "speaker": "Kelly Kapoor"},
  {"season": 8, "episode": 13, "scene": 30, "line": "Hey, Cece, why don't you draw another picture for us, exactly like this one, or at least in the exact same style?", "order": 47793, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 13, "scene": 30, "line": "You know what? I don't think you need to do things on command. That's very weird. I'll just take that. Thank you. I think we should just wrap up the show, kiddos. Right?", "order": 47794, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 13, "scene": 30, "line": "[cries] Mama!", "order": 47795, "speaker": "Cece"},
  {"season": 8, "episode": 13, "scene": 30, "line": "Shh.", "order": 47796, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 13, "scene": 30, "line": "I don't know what else we can do here.", "order": 47797, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 13, "scene": 30, "line": "[cries] Mama, mama, mama.", "order": 47798, "speaker": "Cece"},
  {"season": 8, "episode": 13, "scene": 30, "line": "It's okay.", "order": 47799, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 13, "scene": 30, "line": "Oh, it's okay, sweetie.", "order": 47800, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 13, "scene": 30, "line": "All right, all right. Okay.", "order": 47801, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 13, "scene": 30, "line": "It's okay, honey. [Philip cries] It's okay, it's okay.", "order": 47802, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 13, "scene": 30, "line": "All right, I know, I know. Let's just get this.", "order": 47803, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 13, "scene": 30, "line": "Shh.", "order": 47804, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 13, "scene": 30, "line": "Mama!", "order": 47805, "speaker": "Cece"},
  {"season": 8, "episode": 13, "scene": 31, "line": "Angela, this child is definitely mine. He looks just like me.", "order": 47806, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 13, "scene": 31, "line": "Every baby looks just like you. Your face kind of looks like a baby.", "order": 47807, "speaker": "Angela Martin"},
  {"season": 8, "episode": 13, "scene": 31, "line": "Need I remind you that we were together a month before the wedding?", "order": 47808, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 13, "scene": 31, "line": "That is completely untrue.", "order": 47809, "speaker": "Angela Martin"},
  {"season": 8, "episode": 13, "scene": 31, "line": "Completely true. Remember? Angela. No.", "order": 47810, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 13, "scene": 31, "line": "You said that Robert was not fulfilling you-", "order": 47811, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 13, "scene": 31, "line": "I did not. Uh-uh.", "order": 47812, "speaker": "Angela Martin"},
  {"season": 8, "episode": 13, "scene": 31, "line": "-and I said, 'I bet I could fulfill you,' and you said, 'I'd like to see you try,' and then I kissed you with the force of a thousand waterfalls.", "order": 47813, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 13, "scene": 31, "line": "That didn't happen.", "order": 47814, "speaker": "Angela Martin"},
  {"season": 8, "episode": 13, "scene": 31, "line": "And then I inserted my penis-", "order": 47815, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 13, "scene": 31, "line": "No! Stop it!", "order": 47816, "speaker": "Angela Martin"},
  {"season": 8, "episode": 13, "scene": 31, "line": "Into your-", "order": 47817, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 13, "scene": 31, "line": "Dwight.", "order": 47818, "speaker": "Angela Martin"},
  {"season": 8, "episode": 13, "scene": 31, "line": "Vagina and-", "order": 47819, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 13, "scene": 31, "line": "And even if it did, it's just a coincidence.", "order": 47820, "speaker": "Angela Martin"},
  {"season": 8, "episode": 13, "scene": 31, "line": "Admit that there is a chance.", "order": 47821, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 13, "scene": 31, "line": "I will not.", "order": 47822, "speaker": "Angela Martin"},
  {"season": 8, "episode": 13, "scene": 31, "line": "Admit it. Admit it.", "order": 47823, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 13, "scene": 31, "line": "I will not, it's not-", "order": 47824, "speaker": "Angela Martin"},
  {"season": 8, "episode": 13, "scene": 31, "line": "All done?", "order": 47825, "speaker": "Senator Lipton"},
  {"season": 8, "episode": 13, "scene": 31, "line": "Yeah.", "order": 47826, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 13, "scene": 31, "line": "Mmm.", "order": 47827, "speaker": "Senator Lipton"},
  {"season": 8, "episode": 13, "scene": 31, "line": "Yes, yes. He's sleeping.", "order": 47828, "speaker": "Angela Martin"},
  {"season": 8, "episode": 13, "scene": 31, "line": "Before I go, may I?", "order": 47829, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 13, "scene": 31, "line": "Sure. [gives Philip to Dwight] Watch the head, watch the head.", "order": 47830, "speaker": "Angela Martin"},
  {"season": 8, "episode": 13, "scene": 32, "line": "Nurse, you know that baby in there, baby Philip? Cancel the circumcision.", "order": 47831, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 13, "scene": 32, "line": "Who are you?", "order": 47832, "speaker": "Nurse"},
  {"season": 8, "episode": 13, "scene": 32, "line": "I just might be his father.", "order": 47833, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 13, "scene": 32, "line": "I don't know what that means. We're gonna circumcise him.", "order": 47834, "speaker": "Nurse"},
  {"season": 8, "episode": 13, "scene": 33, "line": "[Philip is crying] I know, buddy, I know. Cece, you want to come? Want to come out?", "order": 47835, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 13, "scene": 33, "line": "No!", "order": 47836, "speaker": "Cece"},
  {"season": 8, "episode": 13, "scene": 33, "line": "Oh bud, I know. Do you have a pacifier or anything?", "order": 47837, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 13, "scene": 33, "line": "I'm looking, I'm looking for the pacifier.", "order": 47838, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 13, "scene": 33, "line": "Cece!", "order": 47839, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 13, "scene": 33, "line": "Okay, we're going.", "order": 47840, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 13, "scene": 33, "line": "Okay, okay, we gotta go. All right, we're going home, we're going home. We're going home, we're going home. I know, I know, bud.", "order": 47841, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 13, "scene": 33, "line": "Shh, shh, shh.", "order": 47842, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 13, "scene": 33, "line": "I know, I know.", "order": 47843, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 13, "scene": 33, "line": "You want to grab her?", "order": 47844, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 13, "scene": 33, "line": "I'm gonna grab her, okay.", "order": 47845, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 13, "scene": 33, "line": "Here we go, here we go.", "order": 47846, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 13, "scene": 33, "line": "Hi, all right. I'm just going to go down to the car and I'm going to put her in her seat and I'll be right back up.", "order": 47847, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 13, "scene": 33, "line": "[groans] We'll see you tomorrow.", "order": 47848, "speaker": "Stanley Hudson"},
  {"season": 8, "episode": 13, "scene": 33, "line": "No, no, it's okay, I'll be right back.", "order": 47849, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 13, "scene": 33, "line": "No, just go home.", "order": 47850, "speaker": "Phyllis Vance"},
  {"season": 8, "episode": 13, "scene": 33, "line": "It's all good, we got this.", "order": 47851, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 13, "scene": 34, "line": "I have a kid. Last week, Jim at home? That was no vacation.", "order": 47852, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 13, "scene": 35, "line": "Jim.", "order": 47853, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 13, "scene": 35, "line": "Just go.", "order": 47854, "speaker": "Phyllis Vance"},
  {"season": 8, "episode": 13, "scene": 35, "line": "Hey, we'll be just fine.", "order": 47855, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 13, "scene": 35, "line": "Thanks guys. All right, here we go. Let's go.", "order": 47856, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 13, "scene": 35, "line": "Oh, whew!", "order": 47857, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 13, "scene": 36, "line": "Dwight, what the hell? You can't smoke in here.", "order": 47858, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 13, "scene": 36, "line": "Oh, right. [sighs] The office looks different now. Huh. Smaller. Maybe I just feel bigger. Hello Gabe.", "order": 47859, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 13, "scene": 36, "line": "You had something important to tell me?", "order": 47860, "speaker": "Gabe Lewis"},
  {"season": 8, "episode": 13, "scene": 36, "line": "Oh, you know, I did but now it seems infinitely insignificant.", "order": 47861, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 13, "scene": 37, "line": "Jim? Oh, right. Nah, forget it. He was doing it for his kids. I get it. Kids drive us dads crazy. Sometimes I feel like they're raising us. Am I right?", "order": 47862, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 13, "scene": 38, "line": "I would love for everyone to just forgive me. But, if that's not happening, I'm not averse to just buying them all off.", "order": 47863, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 13, "scene": 39, "line": "Alright guys. You know how we all like the red licorice but it's always buried behind like a ton of black licorice? [points to pile of candy] Bought it all. Now it's like red city in there. So, enjoy it.", "order": 47864, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 13, "scene": 39, "line": "Mmm-hmm. Moneybags. Must be nice.", "order": 47865, "speaker": "Phyllis Vance"},
  {"season": 8, "episode": 13, "scene": 39, "line": "So you effectively spent what...four dollars on the entire office?", "order": 47866, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 13, "scene": 39, "line": "Uh, I can't get a handle on it. Am I cheap or rich? I can't...", "order": 47867, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 13, "scene": 39, "line": "You're nothing. When I think about all the gas money I spent on you. Washing my car to make sure you-", "order": 47868, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 13, "scene": 39, "line": "[interrupting] Alright. Well, enjoy the red licorice, and good riddance, right? [tosses black licorice into trash]", "order": 47869, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 13, "scene": 39, "line": "Whoa whoa whoa whoa whoa! So you're just gonna throw away perfectly good food?", "order": 47870, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 13, "scene": 39, "line": "It's not really food, right?", "order": 47871, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 13, "scene": 39, "line": "I'm really hating this Jim guy!", "order": 47872, "speaker": "Creed Bratton"},
  {"season": 8, "episode": 13, "scene": 39, "line": "Ok, ok, ok.", "order": 47873, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 13, "scene": 39, "line": "I know what you can do Jim. Take those black licorice, then go get some of those red licorice, take 'em in your hand, roll 'em up real tight...", "order": 47874, "speaker": "Stanley Hudson"},
  {"season": 8, "episode": 13, "scene": 39, "line": "And shove it up my butt.'", "order": 47875, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 13, "scene": 39, "line": "Dammit Jim! You stole my afternoons, now you stole my line! It's not 'shove it up your butt' it's....I'll kill you! I'll kill you dead!", "order": 47876, "speaker": "Stanley Hudson"},
  {"season": 8, "episode": 13, "scene": 39, "line": "Eat it.", "order": 47877, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 13, "scene": 39, "line": "I'm not gonna eat it.", "order": 47878, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 13, "scene": 39, "line": "Eat. It.", "order": 47879, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 13, "scene": 39, "line": "I'm not-", "order": 47880, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 13, "scene": 39, "line": "EAT IT! [Jim begins eating black licorice]", "order": 47881, "speaker": "Stanley Hudson"},
  {"season": 8, "episode": 13, "scene": 40, "line": "[on phone] They hate me, Pam.", "order": 47882, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 13, "scene": 40, "line": "They don't hate you.", "order": 47883, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 13, "scene": 40, "line": "No, it's bad. Darryl canceled go-karting....", "order": 47884, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 13, "scene": 40, "line": "When were you going go-karting with Darryl?", "order": 47885, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 13, "scene": 40, "line": "It's a lunch thing.", "order": 47886, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 13, "scene": 40, "line": "Alright. Just don't worry, we'll figure this out.", "order": 47887, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 13, "scene": 40, "line": "I feel like a stranger in my own country.", "order": 47888, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 13, "scene": 40, "line": "Ok, drama.", "order": 47889, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 13, "scene": 40, "line": "Ok. Well, hey, you're not here. You don't know, Ok? It's intense. They made me eat black licorice.", "order": 47890, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 13, "scene": 41, "line": "I have a recurring nightmare that I've been wrongly accused of a crime and twelve of these idiots are on the jury. Stanley is fast asleep, Kevin eats the evidence, Phyllis's name is Allison but it really is Phyllis, Jim is foreman of the jury and pronounces me guilty. The cop takes me away and tells me that I've been a bad boy and we have sex in the hall. She leaves the handcuffs on. They take me away to prison. The guards are all women. [sighs]", "order": 47891, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 13, "scene": 42, "line": "Everyone, this is Officer Foley from the Scranton PD. Works down at the courthouse. I think he has something he's like to say.", "order": 47892, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 13, "scene": 42, "line": "I understand that some of you don't believe that Mr. Bernard drove his friend into the courthouse for jury duty last week.", "order": 47893, "speaker": "Officer Foley"},
  {"season": 8, "episode": 13, "scene": 42, "line": "Correction: we don't care.", "order": 47894, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 13, "scene": 42, "line": "Hey, aren't you an actor?", "order": 47895, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 13, "scene": 42, "line": "No.", "order": 47896, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 13, "scene": 42, "line": "Weren't you in Sweeny Todd last year with Andy?", "order": 47897, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 13, "scene": 42, "line": "Uh, no.", "order": 47898, "speaker": "Officer Foley"},
  {"season": 8, "episode": 13, "scene": 42, "line": "You hired your actor friend to come here as a cop?", "order": 47899, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 13, "scene": 42, "line": "That is so offensive and ridiculous. I-", "order": 47900, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 13, "scene": 42, "line": "So if I started singing 'Down By the Old Mill Stream', you couldn't join me in a three part harmony. [Andy and Officer Foley shake their heads no, Darryl begins singing] Down by the, down by the, down by the....", "order": 47901, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 13, "scene": 42, "line": "[singing] Old mill stream. When I first met you, down by the old mill stream [Andy & Foley hold 'stream']", "order": 47902, "speaker": "Andy Bernard, Darryl Philbin & Officer Foley"},
  {"season": 8, "episode": 13, "scene": 42, "line": "[Low singing] Old, mill, stream.", "order": 47903, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 13, "scene": 42, "line": "Ho hah!....oh, shoot.", "order": 47904, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 13, "scene": 42, "line": "Mm hmm.", "order": 47905, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 14, "scene": 1, "line": "Oh ho! Look who's back reporting for duty.", "order": 47906, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 14, "scene": 1, "line": "Hey guys!", "order": 47907, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 14, "scene": 1, "line": "Hey.", "order": 47908, "speaker": "All"},
  {"season": 8, "episode": 14, "scene": 1, "line": "[laughing] Hi.", "order": 47909, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 14, "scene": 1, "line": "Pam! Ahh! Look what I can do now that she's no longer pregnant. [pretends to punch Pam in the stomach] Bam! Bam! Bam! Bam!", "order": 47910, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 14, "scene": 1, "line": "Are you glad to be back?", "order": 47911, "speaker": "Phyllis Vance"},
  {"season": 8, "episode": 14, "scene": 1, "line": "Yeah. Yeah, I mean I could have used another week, or three.", "order": 47912, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 14, "scene": 1, "line": "You still had eight weeks more than we did.", "order": 47913, "speaker": "Stanley Hudson"},
  {"season": 8, "episode": 14, "scene": 1, "line": "[laughing] Well, it's not exactly a vacation.", "order": 47914, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 14, "scene": 1, "line": "Hello everyone! Remember little old me. Hi!", "order": 47915, "speaker": "Angela Martin"},
  {"season": 8, "episode": 14, "scene": 1, "line": "What are you doing here? You just had our baby. Our collective Dunder Mifflin...family baby. Four days ago.", "order": 47916, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 14, "scene": 1, "line": "I wanted to come back to work. Not everybody needs some long luxurious Parisian maternity leave. [removes coat]", "order": 47917, "speaker": "Angela Martin"},
  {"season": 8, "episode": 14, "scene": 1, "line": "What?!", "order": 47918, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 14, "scene": 1, "line": "Damn girl! Your body!", "order": 47919, "speaker": "Kelly Kapoor"},
  {"season": 8, "episode": 14, "scene": 1, "line": "Angela, you look amazing.", "order": 47920, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 14, "scene": 1, "line": "Really? I feel like this big rhinoceros. Anyway, I wanted to thank you guys for covering for me so I made some treats. I've got brownies and magic cookie bars.", "order": 47921, "speaker": "Angela Martin"},
  {"season": 8, "episode": 14, "scene": 1, "line": "[murmurs of delight as people shuffle toward the treats]", "order": 47922, "speaker": "Group"},
  {"season": 8, "episode": 14, "scene": 1, "line": "Oh Angela, those brownies have walnuts in them and I think Kevin's allergic to walnuts. You're allergic to walnuts, right Kevin?", "order": 47923, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 14, "scene": 1, "line": "Extremely, but I'm gonna fight through it.", "order": 47924, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 14, "scene": 1, "line": "Aw. It's OK Angela. I have mommy brain too.", "order": 47925, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 14, "scene": 1, "line": "I don't know what that is, Pam. I made a batch with no nuts, special for you Kevin, right here.", "order": 47926, "speaker": "Angela Martin"},
  {"season": 8, "episode": 14, "scene": 1, "line": "When did you find time to do all this?", "order": 47927, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 14, "scene": 1, "line": "Babies sleep a lot Pam, if you feed them enough. Brownie Pam?", "order": 47928, "speaker": "Angela Martin"},
  {"season": 8, "episode": 14, "scene": 1, "line": "Thank you. [takes bite] Oh my gosh. It's really good.", "order": 47929, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 14, "scene": 1, "line": "I wouldn't know, I'm watching my weight. Ugh.", "order": 47930, "speaker": "Angela Martin"},
  {"season": 8, "episode": 14, "scene": 1, "line": "So you guys, you know what's an even more useful treat...is cash. So-", "order": 47931, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 14, "scene": 1, "line": "Nope. It's over.", "order": 47932, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 14, "scene": 1, "line": "...we wanted to say thank you-", "order": 47933, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 14, "scene": 1, "line": "Nope. OK.", "order": 47934, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 14, "scene": 2, "line": "Dwight, I have exciting news for you.", "order": 47935, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 14, "scene": 2, "line": "What?", "order": 47936, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 14, "scene": 2, "line": "I think you're going to like it.", "order": 47937, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 14, "scene": 2, "line": "You can't tantalize me.", "order": 47938, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 14, "scene": 2, "line": "Oooh, maybe I can. [slowly] I got an email... from corporate....that told me that...", "order": 47939, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 14, "scene": 2, "line": "OK. [looks at watch and leaves]", "order": 47940, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 14, "scene": 2, "line": "That...[quickly] you got a promotion! [Dwight turns back] Right? I mean that's not the kinda thing you wanna read quietly at your desk.", "order": 47941, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 14, "scene": 2, "line": "If you make me head of sales one more time, I swear.", "order": 47942, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 14, "scene": 2, "line": "No, this is legit. You will be assembling a team to go to Tallahassee for three weeks to develop and launch a chain of Sabre stores.", "order": 47943, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 14, "scene": 2, "line": "Yes! Yes! Yes! Yes! Yes! Yes! Yes! Yes! Yes! Yes, yes, yes, hi-yes! Yeah yeah yeah yeah!", "order": 47944, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 14, "scene": 3, "line": "The Shrutes have a word for when everything comes together in a man's life perfectly: Perfectinschlag. Hmm. Right now, I am in it. I finally get a chance to prove myself to corporate, I am assembling a competent team, I am likely a father, I am so deep inside of perfectinschlag right now. And just to be clear, there is a second definition, 'perfect pork anus' which I don't mean.", "order": 47945, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 14, "scene": 4, "line": "And here's the fax for you.", "order": 47946, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 14, "scene": 4, "line": "And here's some facts for you. Did you know frogs can hear with their lungs? And that flamingos can have orgasms that last 30 minutes.", "order": 47947, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 14, "scene": 4, "line": "Ah! Love to learn.", "order": 47948, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 14, "scene": 4, "line": "Yeah, it's weird that I said that last one.", "order": 47949, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 14, "scene": 4, "line": "So... [points out their matching heart pins]", "order": 47950, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 14, "scene": 4, "line": "Uh yeah, look at that.", "order": 47951, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 14, "scene": 4, "line": "We're pin twins.", "order": 47952, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 14, "scene": 4, "line": "Pin twins! This is a gift from Jessica, kind of a gag gift really. Real gifts come later.", "order": 47953, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 14, "scene": 4, "line": "Yeah. That's cute. When I saw it in CVS it made me gag too. [Andy laughs, walks away, Erin throws away her pin]", "order": 47954, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 14, "scene": 5, "line": "For a while there I thought something would change. But nope. Andy's still with Jessica, they even carpool together most days. I don't know, I wouldn't mind carpooling every day with Andy, but I wouldn't want to spend that much time with Jessica.", "order": 47955, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 14, "scene": 6, "line": "What do we have here?", "order": 47956, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 14, "scene": 7, "line": "Val knit me a beanie. But I can't if it's a 'we're just friends' beanie or a 'I'm hot, you're hot, let's get it poppin'' beanie. So I'm gonna up the ante, give her a clearly romantic gift, and we'll get to the meaning of the beanie.", "order": 47957, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 14, "scene": 8, "line": "Pam, pack up your post-natal swimwear, make it a one piece or this offer is rescinded, and join me on a fantastic barbeque one week from today in Tallahassee, Florida where I'm going to be living for the next three weeks.", "order": 47958, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 14, "scene": 8, "line": "Really?", "order": 47959, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 14, "scene": 8, "line": "Mm hm.", "order": 47960, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 14, "scene": 8, "line": "I'll fly anywhere for some good barbeque.", "order": 47961, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 14, "scene": 8, "line": "Ooh, me three.", "order": 47962, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 14, "scene": 8, "line": "Eh! No plus one's. This is for competent workers only. And don't worry about the cost, Sabre is footing the bill.", "order": 47963, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 14, "scene": 8, "line": "Does this have anything to do with what you were talking to Andy about?", "order": 47964, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 14, "scene": 8, "line": "God, you're such a spy.", "order": 47965, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 14, "scene": 9, "line": "[clears throat] Notice anything different about me?", "order": 47966, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 14, "scene": 9, "line": "You're wearing the beanie. You like it?", "order": 47967, "speaker": "Val Johnson"},
  {"season": 8, "episode": 14, "scene": 9, "line": "Super comfortable. Like sweatpants for my head.", "order": 47968, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 14, "scene": 9, "line": "I love it too Val. It's it's really itchy, uh but to be fair my head is constantly itching so I can't really peg it on the hat.", "order": 47969, "speaker": "Nate Nickerson"},
  {"season": 8, "episode": 14, "scene": 9, "line": "[noticing all the warehouse workers wearing homemade beanies] Wow. It's like the nation of Islam down here.", "order": 47970, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 14, "scene": 9, "line": "I like to knit, don't hate. And who's that for?", "order": 47971, "speaker": "Val Johnson"},
  {"season": 8, "episode": 14, "scene": 9, "line": "Oh, this is a gift, for....my man Nate here.", "order": 47972, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 14, "scene": 9, "line": "What?", "order": 47973, "speaker": "Nate Nickerson"},
  {"season": 8, "episode": 14, "scene": 9, "line": "Yeah.", "order": 47974, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 14, "scene": 9, "line": "Darryl, you shouldn't have.", "order": 47975, "speaker": "Nate Nickerson"},
  {"season": 8, "episode": 14, "scene": 9, "line": "Hey.", "order": 47976, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 14, "scene": 9, "line": "Can I open it?", "order": 47977, "speaker": "Nate Nickerson"},
  {"season": 8, "episode": 14, "scene": 9, "line": "No, no, no. Maybe just later.", "order": 47978, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 14, "scene": 9, "line": "Ah, I can't wait. I'm sorry. I get too excited. [opens gift] Darryl.", "order": 47979, "speaker": "Nate Nickerson"},
  {"season": 8, "episode": 14, "scene": 9, "line": "Wow. Those are really nice.", "order": 47980, "speaker": "Val Johnson"},
  {"season": 8, "episode": 14, "scene": 9, "line": "They're so elegant.", "order": 47981, "speaker": "Nate Nickerson"},
  {"season": 8, "episode": 14, "scene": 9, "line": "Cashmere.", "order": 47982, "speaker": "Val Johnson"},
  {"season": 8, "episode": 14, "scene": 9, "line": "How'd you know?", "order": 47983, "speaker": "Nate Nickerson"},
  {"season": 8, "episode": 14, "scene": 9, "line": "Just a hunch. Your gift is on the way.", "order": 47984, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 14, "scene": 9, "line": "Can't wait.", "order": 47985, "speaker": "Val Johnson"},
  {"season": 8, "episode": 14, "scene": 9, "line": "[reading card] 'I'm glad you're in my life. Happy Valentine's Day.' Ugh, Darryl. I am glad to be in your life too. Oh, your card is more beautiful than the gloves.", "order": 47986, "speaker": "Nate Nickerson"},
  {"season": 8, "episode": 14, "scene": 10, "line": "My first task as special project manager Dwight Shrute? Assembling a crack team. I need people who are loyal. People who'll help me get an inroad with the gay Hispanic community. People who won't be missed. We don't need idiots, good for nothing's, methheads or... What's your name?", "order": 47987, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 14, "scene": 10, "line": "Kathy.", "order": 47988, "speaker": "Kathy"},
  {"season": 8, "episode": 14, "scene": 10, "line": "Kathy.", "order": 47989, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 14, "scene": 11, "line": "I just got a text from Robert California that says 'bring your clubs to Florida'.", "order": 47990, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 14, "scene": 11, "line": "Why? Does he think you're going to Florida?", "order": 47991, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 14, "scene": 11, "line": "I hope not. [laughs] Because I am not going. Two question marks?", "order": 47992, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 14, "scene": 11, "line": "No, just do one. Two question marks is kind of aggressive. You know it's like wha-what?? Just do one.", "order": 47993, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 14, "scene": 12, "line": "You wanna take Darryl, Phyllis, Toby, Angela and Oscar?", "order": 47994, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 14, "scene": 12, "line": "Yes.", "order": 47995, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 14, "scene": 12, "line": "Can I interest you in someone less essential? Like uh a Creed or a Meredith perhaps?", "order": 47996, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 14, "scene": 12, "line": "Oh my god.", "order": 47997, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 14, "scene": 12, "line": "They're not expendable exactly, I just can't...I can't think of the word I'm trying to find.", "order": 47998, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 14, "scene": 12, "line": "Are you kidding me?! I'm supposed to get in an airplane with those nincompoops? We'd never get off the runway.", "order": 47999, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 14, "scene": 12, "line": "Alright, look. I gotta keep this office functioning somehow. So, I have put together a list, here's your team. Darryl and Phyllis you can have, but you're also taking Kathy, Kelly and Kevin.", "order": 48000, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 14, "scene": 12, "line": "Oh god.", "order": 48001, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 14, "scene": 12, "line": "You have your team.", "order": 48002, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 14, "scene": 13, "line": "Kevin!? Kelly!? Kathy!? Andy just gave me a chain with three weak links. Have you ever tried to use a chain with three weak links? I have. And now I no longer own an arctic wolf.", "order": 48003, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 14, "scene": 14, "line": "Well, let's hear it.", "order": 48004, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 14, "scene": 14, "line": "Robert, Hey!' Exclamation point.", "order": 48005, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 14, "scene": 14, "line": "I like it so far.", "order": 48006, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 14, "scene": 14, "line": "Got your text, awesome idea. Let's hit the links next time you're in PA' Dash JH.", "order": 48007, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 14, "scene": 14, "line": "It's perfect. You emphasized the golf, completely omit the Florida.", "order": 48008, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 14, "scene": 14, "line": "Yeah, it's a golf text.", "order": 48009, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 14, "scene": 14, "line": "Total golf text. Send it.", "order": 48010, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 14, "scene": 14, "line": "Duh- Wait. I just feel weird. I should just call him.", "order": 48011, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 14, "scene": 14, "line": "You wanna call someone? That texted you!? Do you wanna drive them away? I mean, ugh.", "order": 48012, "speaker": "Kelly Kapoor"},
  {"season": 8, "episode": 14, "scene": 15, "line": "[on phone] This is Darryl.", "order": 48013, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 14, "scene": 15, "line": "Oh, sorry I asked for the main shipping number. I just need the address for the warehouse? I want to send my girlfriend some flowers.", "order": 48014, "speaker": "Bob"},
  {"season": 8, "episode": 14, "scene": 15, "line": "Your girlfriend? Is your girlfriend Val?", "order": 48015, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 14, "scene": 15, "line": "Yeah, do you know her?", "order": 48016, "speaker": "Bob"},
  {"season": 8, "episode": 14, "scene": 15, "line": "We're friends. We're friends.", "order": 48017, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 14, "scene": 15, "line": "Cool. Um, so can I get that address or...?", "order": 48018, "speaker": "Bob"},
  {"season": 8, "episode": 14, "scene": 15, "line": "Yeah sorry, I'm just looking for a pen.", "order": 48019, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 14, "scene": 15, "line": "Why do you need a pen?", "order": 48020, "speaker": "Bob"},
  {"season": 8, "episode": 14, "scene": 15, "line": "Back off! I got my reasons.", "order": 48021, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 14, "scene": 16, "line": "May I have your attention please? Could Kathy, Darryl, Phyllis, Kevin and Kelly please join me in the conference room immediately?", "order": 48022, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 14, "scene": 16, "line": "Why?", "order": 48023, "speaker": "Kelly Kapoor"},
  {"season": 8, "episode": 14, "scene": 16, "line": "Oh, no big deal. It's just that you five have been deemed most deserving of a three week all expenses paid business trip to Tallahassee, Florida.", "order": 48024, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 14, "scene": 16, "line": "Hell yeah.", "order": 48025, "speaker": "Kelly Kapoor"},
  {"season": 8, "episode": 14, "scene": 16, "line": "What?!", "order": 48026, "speaker": "Stanley Hudson"},
  {"season": 8, "episode": 14, "scene": 16, "line": "Oh, I'm sorry Stanley, I can't share any more details with you. You see, Andy rejected you.", "order": 48027, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 14, "scene": 16, "line": "Wait. Kathy gets to go? Uh, why does she even still work here? Pam is back.", "order": 48028, "speaker": "Meredith Palmer"},
  {"season": 8, "episode": 14, "scene": 16, "line": "It just feels like a slap in the face.", "order": 48029, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 14, "scene": 16, "line": "I can understand your pain and your rage, but you know what? Andy is an honorable man, let us not question his choices. I'm sure he had his reasons.", "order": 48030, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 14, "scene": 16, "line": "We're not questioning his reasons. I just wanna know what they are.", "order": 48031, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 14, "scene": 16, "line": "I know, you and me both, brother. It seemed kinda random to me, but he was pretty clear on who he thought truly deserved this boondoggle of a lifetime.", "order": 48032, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 14, "scene": 16, "line": "Well, he nailed it because I do deserve a vacation. Sometimes Batman's gotta take off his cape.", "order": 48033, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 14, "scene": 16, "line": "Good point. But we're gonna be working pretty hard until five PM. After that it's gonna be beach volleyball, trips to Cape Canaveral, and sea kayaking with Gloria Estefan.", "order": 48034, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 14, "scene": 16, "line": "What are the criteria for going?", "order": 48035, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 14, "scene": 16, "line": "It might be innate goodness versus innate badness? But there's an easy way to find out. [knocks on Andy's office door] Oh Andy, gotta few questions for you.", "order": 48036, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 14, "scene": 16, "line": "Mooshie mooshie. [Dwight laughs]", "order": 48037, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 14, "scene": 16, "line": "Uh, the Florida picks got out, and people were just kind of curious as to why they weren't picked and maybe you could just clarify?", "order": 48038, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 14, "scene": 16, "line": "Well uh...well the deliberations were confidential so...I feel like we should respect that.", "order": 48039, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 14, "scene": 16, "line": "Respect it? You're trying to tell me Kevin Malone deserves more than Stanley Hudson.", "order": 48040, "speaker": "Stanley Hudson"},
  {"season": 8, "episode": 14, "scene": 16, "line": "Hey!", "order": 48041, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 14, "scene": 16, "line": "Ok, it's obvious Andy picked the people that are least important to the office.", "order": 48042, "speaker": "Angela Martin"},
  {"season": 8, "episode": 14, "scene": 16, "line": "Important? Oh because you and your enormous monster baby are so important to the branch.", "order": 48043, "speaker": "Kelly Kapoor"},
  {"season": 8, "episode": 14, "scene": 16, "line": "My baby is not a monster!", "order": 48044, "speaker": "Angela Martin"},
  {"season": 8, "episode": 14, "scene": 16, "line": "Hey. Be proud of your enormous monster baby. I was once an enormous monster baby.", "order": 48045, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 14, "scene": 16, "line": "Guys. Guys. Guys. Come on, seriously? We cannot let this trip cause this kind of rift, guys!", "order": 48046, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 14, "scene": 16, "line": "Stop calling us 'guys'.", "order": 48047, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 14, "scene": 17, "line": "I use the word 'guys' a lot when I'm nervous...guys.", "order": 48048, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 14, "scene": 18, "line": "Guys! Guys! Guys!", "order": 48049, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 14, "scene": 18, "line": "Stop it!", "order": 48050, "speaker": "Group"},
  {"season": 8, "episode": 14, "scene": 18, "line": "Guys..", "order": 48051, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 14, "scene": 18, "line": "Stop. Stop it Andy.", "order": 48052, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 14, "scene": 18, "line": "...Andy.", "order": 48053, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 14, "scene": 18, "line": "Dudes...the dudes who are going to Florida were picked by Dwight and me.", "order": 48054, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 14, "scene": 18, "line": "OK hey. I specifically remember there were a lot of other people I thought should be considered.", "order": 48055, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 14, "scene": 18, "line": "Wha-", "order": 48056, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 14, "scene": 18, "line": "If anybody's going to Florida, it should be me. Every shirt I have that isn't a work shirt is a Tommy Bahama. I'm the only person in this office who watches Burn Notice.", "order": 48057, "speaker": "Stanley Hudson"},
  {"season": 8, "episode": 14, "scene": 18, "line": "OK, I did not realize that so many of you wanted to go so badly, so if you feel like you have a very good reason to go to Florida, I'm happy to hear you out.", "order": 48058, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 14, "scene": 18, "line": "Great idea! So why doesn't everyone just take the next five to six hours, come up with a statement on why you feel you deserve to go to Florida and Andy and I will listen to it.", "order": 48059, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 14, "scene": 18, "line": "No. That is a ridiculous waste of time.", "order": 48060, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 14, "scene": 18, "line": "For you maybe.", "order": 48061, "speaker": "Kelly Kapoor"},
  {"season": 8, "episode": 14, "scene": 18, "line": "Five to six hours?", "order": 48062, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 14, "scene": 18, "line": "Three to four hours.", "order": 48063, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 14, "scene": 18, "line": "No no no no.", "order": 48064, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 14, "scene": 18, "line": "Two to three hours...", "order": 48065, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 14, "scene": 18, "line": "Nope.", "order": 48066, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 14, "scene": 18, "line": "Come up with a statement in the next hour....", "order": 48067, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 14, "scene": 18, "line": "Thirty minutes!", "order": 48068, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 14, "scene": 18, "line": "I'm out.", "order": 48069, "speaker": "Creed Bratton"},
  {"season": 8, "episode": 14, "scene": 19, "line": "Ok, read it back to me.", "order": 48070, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 14, "scene": 19, "line": "[reading text] Robert, great offer. Wish I could hit the links with you in Florida but a father of a newborn really should be helping out his wife any chance he gets.", "order": 48071, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 14, "scene": 19, "line": "Good. Doesn't sound pushy...you're just stating a fact.", "order": 48072, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 14, "scene": 19, "line": "Absolutely. Works for me.", "order": 48073, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 14, "scene": 19, "line": "Ok, sending.", "order": 48074, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 14, "scene": 19, "line": "Well done. And we managed to kill the entire morning.", "order": 48075, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 14, "scene": 19, "line": "Really did [Text bleep] He responded L-O-L.", "order": 48076, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 14, "scene": 19, "line": "[laughs exaggeratedly]", "order": 48077, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 14, "scene": 20, "line": "I have a new swimsuit I need to break in....", "order": 48078, "speaker": "Phyllis Vance"},
  {"season": 8, "episode": 14, "scene": 20, "line": "I am...", "order": 48079, "speaker": "Meredith Palmer"},
  {"season": 8, "episode": 14, "scene": 20, "line": "I have already picked my team: Darryl, Oscar, Angela, Phyllis and Toby and that is the team that I am taking. What's that? It's not up to me? I only have influence? Well, that's all Baltzer Gladfielder had and no one eats owls for Thanksgiving.", "order": 48080, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 14, "scene": 21, "line": "You have two young dynamic people in this office who know trends, who know youth. That's myself and Kelly Kapoor, you need one of us there.", "order": 48081, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 14, "scene": 21, "line": "Or both?", "order": 48082, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 14, "scene": 21, "line": "Not both. Just one, me. Or if not me, Kelly. Ideally me. Again, youth knowledge. That's what you get when you put Ryan Howard on your special project team...or Kelly Kapoor. Again, not both. Thanks.", "order": 48083, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 14, "scene": 21, "line": "Very impressive. He put a lot of work into that.", "order": 48084, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 14, "scene": 21, "line": "Mm-hm. Powerpoints are the peacocks of the business world. All show, no meat.", "order": 48085, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 14, "scene": 22, "line": "Because I feel like that I'm in a place with my gambling rehab that I can finally start going to dog racing again. You know, just sitting, watching, enjoying the sport? Maybe putting down a few dollars if there's like a crazy mismatch or something...", "order": 48086, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 14, "scene": 22, "line": "Ok. Thank you Kevin, we'll let you know.", "order": 48087, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 14, "scene": 22, "line": "Thank you.", "order": 48088, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 14, "scene": 22, "line": "When do we leave?", "order": 48089, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 14, "scene": 22, "line": "Thank you.", "order": 48090, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 14, "scene": 23, "line": "It has been a long, lonely winter.", "order": 48091, "speaker": "Toby Flenderson"},
  {"season": 8, "episode": 14, "scene": 23, "line": "Wow. Seasonal effective disorder, AKA: sad. That sounds like a very real thing, Toby.", "order": 48092, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 14, "scene": 23, "line": "It is real, thank you for saying that.", "order": 48093, "speaker": "Toby Flenderson"},
  {"season": 8, "episode": 14, "scene": 23, "line": "Yeah, wow. It's almost like we're not all experiencing the same winter?", "order": 48094, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 14, "scene": 24, "line": "Florida Stanley smiles. Florida Stanley is happy to go to work. Florida Stanley is who you want on your Florida team.", "order": 48095, "speaker": "Stanley Hudson"},
  {"season": 8, "episode": 14, "scene": 25, "line": "Maybe it's what she does here...", "order": 48096, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 14, "scene": 25, "line": "Well...", "order": 48097, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 14, "scene": 25, "line": "Hey guys, any spots left?", "order": 48098, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 14, "scene": 25, "line": "Erin, you wanna go to Tallahassee?", "order": 48099, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 14, "scene": 25, "line": "I do. I really do. I think it would be a nice way to clear my head.", "order": 48100, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 14, "scene": 25, "line": "You know I don't think it's a good idea for you to clear your head any more than it has been already. I think you need a workplace where the burdens of everyday life keep you tethered to reality.", "order": 48101, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 14, "scene": 25, "line": "Either or. But if you can find someone to fill in, I would love to go.", "order": 48102, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 14, "scene": 25, "line": "Well it wouldn't be hard to find someone to fill in..", "order": 48103, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 14, "scene": 25, "line": "Really? Oh, great. Good, ahem, good.", "order": 48104, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 14, "scene": 26, "line": "Now, is it too dark to say that Cici's having an operation?", "order": 48105, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 14, "scene": 26, "line": "I think you need to go to Florida.", "order": 48106, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 14, "scene": 26, "line": "I think you're right.", "order": 48107, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 14, "scene": 26, "line": "It's only for three weeks, you know with my mom and sister at the house it'll be..", "order": 48108, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 14, "scene": 26, "line": "Total nightmare.", "order": 48109, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 14, "scene": 26, "line": "I was going to say good because I'll have all the help I need?", "order": 48110, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 14, "scene": 26, "line": "Yeah, that's what I'm saying. They're incredibly helpful, you're lucky to have them. I mean with them there, you probably won't even know I'm gone.", "order": 48111, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 14, "scene": 26, "line": "Exactly.", "order": 48112, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 14, "scene": 27, "line": "Well, [Jim enters] No! No! Absolutely not! You are not going. Over my dead body, no. Andy?", "order": 48113, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 14, "scene": 27, "line": "Tuns, I'm really sorry. You're too essential to the operation here, I can't let you go.", "order": 48114, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 14, "scene": 27, "line": "I wouldn't say that...it's a bit much.", "order": 48115, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 14, "scene": 27, "line": "You know Dwight, if you didn't want me to go the smart move would be to tell Andy that I actually am essential to the operation. That way I couldn't go.", "order": 48116, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 14, "scene": 27, "line": "Jim is essential to th-", "order": 48117, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 14, "scene": 27, "line": "Hold on, just wanna get it on camera. [holds up cellphone]", "order": 48118, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 14, "scene": 27, "line": "Andy, Jim is just too...essen-...", "order": 48119, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 14, "scene": 27, "line": "Essential.", "order": 48120, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 14, "scene": 27, "line": "This is stupid! Cut.", "order": 48121, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 14, "scene": 27, "line": "Alright, I'm gonna pack my trunks.", "order": 48122, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 14, "scene": 27, "line": "He doesn't even want to go.", "order": 48123, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 14, "scene": 27, "line": "Well, I got a text from RC inviting me to come down so I think that's gonna make us roomates.", "order": 48124, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 14, "scene": 27, "line": "Oh my god.", "order": 48125, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 14, "scene": 27, "line": "Hey, quick question: Do you shower at night or in the morning? Cause I wanna shower when you're showering. Save some water.", "order": 48126, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 14, "scene": 28, "line": "OK, listen up everyone, here's who's going to Florida: Kathy..", "order": 48127, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 14, "scene": 28, "line": "What?", "order": 48128, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 14, "scene": 28, "line": ".Stanley..", "order": 48129, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 14, "scene": 28, "line": "No.", "order": 48130, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 14, "scene": 28, "line": "...Ryan...", "order": 48131, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 14, "scene": 28, "line": "No!", "order": 48132, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 14, "scene": 28, "line": "...Erin...", "order": 48133, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 14, "scene": 28, "line": "(Bleep)", "order": 48134, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 14, "scene": 28, "line": "...And, Jim.", "order": 48135, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 14, "scene": 28, "line": "You've gotta be (bleep)-ing kidding me. Ok. Ok, Florida group, welcome to the team. [goes into conference room and slams door] AHHHHH!!!!!!!!!!!", "order": 48136, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 14, "scene": 29, "line": "Let's go, step it up you runts. You infants, let's move.", "order": 48137, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 14, "scene": 29, "line": "Why is it so hot in here?", "order": 48138, "speaker": "Stanley Hudson"},
  {"season": 8, "episode": 14, "scene": 29, "line": "Hot? What are you talking about? This is a nice temperate Florida morning. Eighty-five degrees, seventy-five percent humidity. This is the exact environment you will be facing for the next three weeks of your life. Welcome to special projects orientation. The next three weeks of your life are gonna be the most miserable you've ever faced. They're gonna be hard, they're gonna be dirty. You're gonna wish you were dead.", "order": 48139, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 14, "scene": 29, "line": "But...", "order": 48140, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 14, "scene": 29, "line": "But? There's not buts. That's it. You'll wish you were dead. You seem a little disturbed, you wanna stay here?", "order": 48141, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 14, "scene": 29, "line": "No no, I want to go.", "order": 48142, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 14, "scene": 29, "line": "Cause if any of you would prefer to stay here, all you need to do is ring this bell. [holds up Taboo buzzer]", "order": 48143, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 14, "scene": 29, "line": "Is that the buzzer from Taboo?", "order": 48144, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 14, "scene": 29, "line": "SHUT UP MAGGOT! YOU DON'T WANT TO GO, RING THE BELL! RING IT! Ring it.", "order": 48145, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 14, "scene": 30, "line": "How many of you have seen the documentary Deliverance?", "order": 48146, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 14, "scene": 30, "line": "How did a mosquito get in here?", "order": 48147, "speaker": "Stanley Hudson"},
  {"season": 8, "episode": 14, "scene": 31, "line": "I released three hundred mosquitoes in the conference room, just temporarily. When I'm done, the frogs will take care of the mosquitoes. [Frog in plastic cage ribbits]", "order": 48148, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 14, "scene": 32, "line": "Yes, when he brushes your soft supple cheeks with his worn leathery hands and says 'I'm gonna make you the seventh Mrs. Rosenblatt' unless you ring this bell. Ring it! Ring the bell. You wanna spend the rest of your life changing your husband's colostomy bags? Huh? Do you? Ring it! Wha-, [Jim slaps Dwight's forehead] oh. What was that?", "order": 48149, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 14, "scene": 32, "line": "Mosquito.", "order": 48150, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 14, "scene": 32, "line": "[Sighs and grunts] Orientation is over.", "order": 48151, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 14, "scene": 33, "line": "Oh Darryl, hey.", "order": 48152, "speaker": "Nate Nickerson"},
  {"season": 8, "episode": 14, "scene": 33, "line": "Hey what's up? You called?", "order": 48153, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 14, "scene": 33, "line": "Yeah, you know I, I felt really terrible that you got me these lovely gloves and I didn't get you anything...", "order": 48154, "speaker": "Nate Nickerson"},
  {"season": 8, "episode": 14, "scene": 33, "line": "It's cool really.", "order": 48155, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 14, "scene": 33, "line": "No, it's anything but cool. Now I haven't really had time to go out and buy you anything, but uh, here goes. [Hands Darryl pink slips of paper]", "order": 48156, "speaker": "Nate Nickerson"},
  {"season": 8, "episode": 14, "scene": 33, "line": "This coupon entitles you to one free tickle monster attack'", "order": 48157, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 14, "scene": 33, "line": "Yeah, they're Nate coupons. Or Nate-pons. And they're all different. Cash that one in and I will bring you a stick of gum, anytime, any place. I'll find you.", "order": 48158, "speaker": "Nate Nickerson"},
  {"season": 8, "episode": 14, "scene": 33, "line": "Thanks. [to Val] Nice flowers.", "order": 48159, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 14, "scene": 33, "line": "Thanks, they're from my mom.", "order": 48160, "speaker": "Val Johnson"},
  {"season": 8, "episode": 14, "scene": 33, "line": "Your mom?", "order": 48161, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 14, "scene": 33, "line": "Yeah.", "order": 48162, "speaker": "Val Johnson"},
  {"season": 8, "episode": 14, "scene": 33, "line": "Uh. So I guess that was your mom who called me earlier looking for the address? Real deep voiced woman?", "order": 48163, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 14, "scene": 33, "line": "Yeah, that's her.", "order": 48164, "speaker": "Val Johnson"},
  {"season": 8, "episode": 14, "scene": 33, "line": "She said her name was Brandon, I think. Your mom's name is Brandon?", "order": 48165, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 14, "scene": 33, "line": "[laughs] Yeah Darryl, my mom's name is Brandon.", "order": 48166, "speaker": "Val Johnson"},
  {"season": 8, "episode": 14, "scene": 34, "line": "Then I guess I figured out where I stand. This is a love beanie.", "order": 48167, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 14, "scene": 35, "line": "Ok, Florida team, let's reconvene.", "order": 48168, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 14, "scene": 36, "line": "I'm going to Florida.....and I'm not coming back.", "order": 48169, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 14, "scene": 37, "line": "So Sabre has set up a conference room for our use while we're at corporate.", "order": 48170, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 14, "scene": 37, "line": "So, what is this special project?", "order": 48171, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 14, "scene": 37, "line": "Basically Sabre has tasked us with helping them branch into the retail marketplace. They want to set up their own version of an Apple store.", "order": 48172, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 14, "scene": 37, "line": "That sounds awesome.", "order": 48173, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 14, "scene": 37, "line": "It did, it did.", "order": 48174, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 14, "scene": 37, "line": "You know what might be great? Is if instead of a genius bar we have a lounge area where people can ask questions about the product with other customers who liked it.", "order": 48175, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 14, "scene": 37, "line": "Yeah. And they should have a place where people can check their computers or printers before they see you so you don't have to carry it around like a dope. Like a coat check. [Dwight writes on whiteboard] Oh, don't write it down unless you like it.", "order": 48176, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 14, "scene": 37, "line": "Oh, I like it.", "order": 48177, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 14, "scene": 37, "line": "It seems to me that the Apple store is kind of like a party. So I think our question is: How do we make this a better party?", "order": 48178, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 14, "scene": 37, "line": "No. We sell business tools and the stores need to reflect that. They need to be all business. Let all the other stores look like a toy store.", "order": 48179, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 14, "scene": 37, "line": "Right. Think different, from Apple.", "order": 48180, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 14, "scene": 37, "line": "Dwight, is now a good time to go over what you expect of us?", "order": 48181, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 14, "scene": 37, "line": "[long pause] Yes. What are my expectations for the group?", "order": 48182, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 14, "scene": 38, "line": "I have been given the responsibility to manage Stanley, a solid player, Ryan, who is capable of surprises, Erin, an excellent follower and Kathy, a probably not totally useless enigma. And, well, Jim. Under the right manager, that's not a bad team. Perfectinschlag.", "order": 48183, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 14, "scene": 39, "line": "I don't know how I'm going to live here without you.", "order": 48184, "speaker": "Kelly Kapoor"},
  {"season": 8, "episode": 14, "scene": 39, "line": "Me neither. Hey, will you put this coat on my chair? I just realized I'm not going to need it down there.", "order": 48185, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 14, "scene": 39, "line": "Yes, I will take your coat. And I will keep it with me, and I will sleep with it because it smells like you.", "order": 48186, "speaker": "Kelly Kapoor"},
  {"season": 8, "episode": 14, "scene": 39, "line": "No, no, no, no. Just put it on my chair.", "order": 48187, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 14, "scene": 40, "line": "Alright [kiss]", "order": 48188, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 14, "scene": 40, "line": "Bye.", "order": 48189, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 14, "scene": 40, "line": "Bye. Call me when you land.", "order": 48190, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 14, "scene": 40, "line": "I will.", "order": 48191, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 14, "scene": 40, "line": "Safe travels.", "order": 48192, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 14, "scene": 40, "line": "Goodbye for a very very long time.", "order": 48193, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 14, "scene": 40, "line": "Oh, K. It's only three weeks.", "order": 48194, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 14, "scene": 41, "line": "[On phone] All expenses paid. Yeah, Jim's gonna be there. Their marriage is not good. Nobody knows better than me. Definitely we will. It's three weeks in Tallahassee, what else is there to do?", "order": 48195, "speaker": "Kathy"},
  {"season": 8, "episode": 15, "scene": 1, "line": "Today is the first day of Sabre's new project to develop a chain of retail stores. I am meeting my new boss, Nellie Bertram, head of special projects. Work starts at nine. Sabre HQ is thirty minutes away, driving the speed limit. Giving everyone twenty minutes to shower, plus fifty for Jim to style his hair, twenty for breakfast, forty for Erin to get lost between her room and the lobby, ninety for Ryan to do his morning ecstasy... We're already twenty minutes late.", "order": 48196, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 15, "scene": 2, "line": "Wake up! [Cathy screams]", "order": 48197, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 15, "scene": 3, "line": "Hey, hey, hey, hey! Wake up! [Erin kicks him] Ow! Why are you sleeping that way?", "order": 48198, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 15, "scene": 3, "line": "Oh. I was reading the mattress tag and I fell asleep.", "order": 48199, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 15, "scene": 4, "line": "Stanley! Wake up! You've got to wake up, the hotel's on fire!", "order": 48200, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 15, "scene": 4, "line": "Stanley, wake up, it's pretzel day! [Dwight pinches Stanley's nose and covers his mouth]", "order": 48201, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 15, "scene": 4, "line": "Mmm! [struggles] Ugh!", "order": 48202, "speaker": "Stanley Hudson"},
  {"season": 8, "episode": 15, "scene": 4, "line": "[lets go] Good morning.", "order": 48203, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 15, "scene": 5, "line": "Hey, wake up. Let's have some fun. We're in Florida now.", "order": 48204, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 15, "scene": 5, "line": "Hey, you. I'm so glad this is happening. I actually came to your door twice last night. Come on in, the water's fine. [sees Dwight] Oh, not cool!", "order": 48205, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 15, "scene": 6, "line": "I am on the two kid sleep schedule so I'm up and at 'em at four fifteen, but no kids, so I honestly didn't know what to do with myself, and then I thought of something. Uh- [hears door, hides]", "order": 48206, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 15, "scene": 6, "line": "Heeeere's Dwi- what the-? [sees trashed room] Oh man.", "order": 48207, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 15, "scene": 6, "line": "What do you think happened?", "order": 48208, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 15, "scene": 6, "line": "Looks like Jim got mixed up with some bad apples. [sees 'IT WAS DWIGHT' written in lipstick on the door] Oh no, no, no, no. It wasn't me. I gotta find Luwanda at The Alcohol Club. Oh. [Jim falls out of the closet, Erin and Dwight scream]", "order": 48209, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 15, "scene": 7, "line": "Puppet.", "order": 48210, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 15, "scene": 7, "line": "Cool, for your kids?", "order": 48211, "speaker": "Cathy Simms"},
  {"season": 8, "episode": 15, "scene": 7, "line": "Yeah. It's weird being away from them. Never done this before.", "order": 48212, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 15, "scene": 7, "line": "Attention, Dunder Mifflin group. Proceed outside. The vehicle is waiting. Seats have been assigned. Shotgun goes to Ryan. Congratulations, Ryan.", "order": 48213, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 15, "scene": 8, "line": "I need to make Nellie see me as a leader today. First impressions get locked in forever. When I first met Pam, she said something that slightly rubbed me the wrong way. Since then I've loved working with Pam and she's frankly wonderful, but I hate her.", "order": 48214, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 15, "scene": 9, "line": "I'll have a bottle of the antacid. Also, I want to get a souvenir for my son, but his mother doesn't acknowledge my paternity. Do you have anything for that? Also, I want it to have a Florida feel.", "order": 48215, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 15, "scene": 9, "line": "What does he like?", "order": 48216, "speaker": "Saleswoman"},
  {"season": 8, "episode": 15, "scene": 9, "line": "Power.", "order": 48217, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 15, "scene": 10, "line": "Okay, and this one is, 'Huh. Don't see too many museums around here.'", "order": 48218, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 15, "scene": 10, "line": "Okay, Twiggy, that's enough. Get in the car.", "order": 48219, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 15, "scene": 10, "line": "Hey, are you okay?", "order": 48220, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 15, "scene": 10, "line": "I'm fine, okay? It's just stress. You know, 'cause I care about this project. And frankly the fact that none of you are vomiting or diarrheal right now I find very alarming.", "order": 48221, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 15, "scene": 10, "line": "Who says none of us are diarrheal?", "order": 48222, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 15, "scene": 10, "line": "Are you sure it's stress? Because I did poison you.", "order": 48223, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 15, "scene": 10, "line": "Very funny, Jim.", "order": 48224, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 15, "scene": 10, "line": "Oh no, I'm serious. I was thinking, 'For this trip I have to do something epic, so what should that be?' and then I thought of it. I'll poison you. What are you gonna do? You gonna steal my newspaper or put a cricket in my cereal or something?", "order": 48225, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 15, "scene": 10, "line": "I'm gonna set your face on fire.", "order": 48226, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 15, "scene": 10, "line": "That's a good one. [a red sports car drives up] Whoa, Stanley! Did you just come back from burning down a rival nightclub?", "order": 48227, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 15, "scene": 10, "line": "Laugh it up, Halpert. I'm in Florida for a month without my family. I'm gonna enjoy this. Want to get in? You can work the iPod.", "order": 48228, "speaker": "Stanley Hudson"},
  {"season": 8, "episode": 15, "scene": 10, "line": "Yes.", "order": 48229, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 15, "scene": 10, "line": "You're a nice guy, Jim, but you have no idea how to vacation. Find some Kenny Loggins.", "order": 48230, "speaker": "Stanley Hudson"},
  {"season": 8, "episode": 15, "scene": 10, "line": "Loggins and Messina.", "order": 48231, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 15, "scene": 10, "line": "Did I say 'Messina?' [tires screech]", "order": 48232, "speaker": "Stanley Hudson"},
  {"season": 8, "episode": 15, "scene": 11, "line": "Ahh! [laughs] Sorry I couldn't resist. It's so quiet.", "order": 48233, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 15, "scene": 11, "line": "I like it. It's so peaceful, I've already written like, twelve plays today.", "order": 48234, "speaker": "Creed Bratton"},
  {"season": 8, "episode": 15, "scene": 11, "line": "It's so quiet, one might say, you could hear a pin... [drops pin] I thought that would be cooler.", "order": 48235, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 15, "scene": 11, "line": "I loved it.", "order": 48236, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 15, "scene": 11, "line": "Hey, can you pick up the pin? Some of us like to work in our bare feet.", "order": 48237, "speaker": "Meredith Palmer"},
  {"season": 8, "episode": 15, "scene": 11, "line": "Of course, I will pick up the pin. It is right here. Got it.", "order": 48238, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 15, "scene": 11, "line": "Can we see that? Did you really find it?", "order": 48239, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 15, "scene": 11, "line": "Yes, right here. Got it. Dink, ow. [chuckles, clicks tongue] In the trash.", "order": 48240, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 15, "scene": 11, "line": "Hey, why is it so quiet? Shouldn't the phone be ringing? Uh-oh. Erin set the phone to voicemail.", "order": 48241, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 15, "scene": 11, "line": "Oh great. [overlapping chatter]", "order": 48242, "speaker": "Everyone"},
  {"season": 8, "episode": 15, "scene": 11, "line": "We're screwed.", "order": 48243, "speaker": "Kelly Kapoor"},
  {"season": 8, "episode": 15, "scene": 11, "line": "There. [phone rings]", "order": 48244, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 15, "scene": 11, "line": "Oh! There we go! Pam?", "order": 48245, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 15, "scene": 11, "line": "Yeah?", "order": 48246, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 15, "scene": 11, "line": "Can you get the phone?", "order": 48247, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 15, "scene": 11, "line": "Well I'm not the receptionist.", "order": 48248, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 15, "scene": 11, "line": "Mm, well, you used to be.", "order": 48249, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 15, "scene": 11, "line": "I know, but I can't cover reception. I have a ton of work to do.", "order": 48250, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 15, "scene": 11, "line": "Phone's ringing.", "order": 48251, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 15, "scene": 11, "line": "Will one of you get the phone? I am freaking out! I know it's for me. [Andy and Pam silently argue] Are you guys kidding me? Stop flirting and someone get the phone!", "order": 48252, "speaker": "Kelly Kapoor"},
  {"season": 8, "episode": 15, "scene": 11, "line": "Somebody! Answer the phone.", "order": 48253, "speaker": "Everyone"},
  {"season": 8, "episode": 15, "scene": 11, "line": "Andy, pick up the phone!", "order": 48254, "speaker": "Kelly Kapoor"},
  {"season": 8, "episode": 15, "scene": 11, "line": "Get the damn phone.", "order": 48255, "speaker": "Stanley Hudson"},
  {"season": 8, "episode": 15, "scene": 11, "line": "You're the closest one to it.", "order": 48256, "speaker": "Meredith Palmer"},
  {"season": 8, "episode": 15, "scene": 11, "line": "Fine. Hello, Dunder Mifflin. [ringing continues] Hello, you've reached Dunder Mifflin. How may I be of service?", "order": 48257, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 15, "scene": 12, "line": "Man.", "order": 48258, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 15, "scene": 12, "line": "How was the drive?", "order": 48259, "speaker": "Cathy Simms"},
  {"season": 8, "episode": 15, "scene": 12, "line": "Fantastic. Stanley drives so fast.", "order": 48260, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 15, "scene": 12, "line": "Life is short. 'Drive fast and leave a sexy corpse.' That's one of my mottoes.", "order": 48261, "speaker": "Stanley Hudson"},
  {"season": 8, "episode": 15, "scene": 12, "line": "I would love to hear the other mottos.", "order": 48262, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 15, "scene": 13, "line": "Quick query, Halpert.", "order": 48263, "speaker": "Todd Packer"},
  {"season": 8, "episode": 15, "scene": 13, "line": "No way.", "order": 48264, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 15, "scene": 13, "line": "Still queer?", "order": 48265, "speaker": "Todd Packer"},
  {"season": 8, "episode": 15, "scene": 13, "line": "Packer.", "order": 48266, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 15, "scene": 13, "line": "You can't put me down. Too strong!", "order": 48267, "speaker": "Todd Packer"},
  {"season": 8, "episode": 15, "scene": 14, "line": "Yeah, Dwight and Jim tried to get me fired, but I landed on my feet down here in Florida. You see, this cat's got nine lives, and a nine-inch-", "order": 48268, "speaker": "Todd Packer"},
  {"season": 8, "episode": 15, "scene": 15, "line": "Psst. Say, 'So who's leading this thing, anyway?'", "order": 48269, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 15, "scene": 15, "line": "So who's leading this thing, anyway?", "order": 48270, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 15, "scene": 15, "line": "Psst. Say, 'I can't wait to meet him.'", "order": 48271, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 15, "scene": 15, "line": "I can't wait to meet him.", "order": 48272, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 15, "scene": 15, "line": "Him, you say? Don't think a woman can be a leader?", "order": 48273, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 15, "scene": 15, "line": "I-", "order": 48274, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 15, "scene": 15, "line": "You poor, simple boy. Expected a man, did you? Strong, powerful. Huge, whopping penis? Well, sorry to disappoint, but my huge, whopping penis is right here, [points to her head] and I'm not afraid to use it. So stop looking at my breasts, and start looking at my penis. [slow clap] I'm Nellie Bertram, president of special projects. In the next few weeks we're going to throw everything you know about business out of the window and do it our own way. Are your minds blown? So, how did this special project come about anyway? Well, I interviewed to be the manager of your branch. [points to Jim] After you decided I wasn't 'a good fit-'", "order": 48275, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 15, "scene": 15, "line": "It was-", "order": 48276, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 15, "scene": 15, "line": "Ah! It was. I went on a shopping spree. Very destructive. I bought thirteen pianos. Then I realized, what if Sabre had a store? Hmm? So I called Jo, old friend, founder of Sabre, and I told her and she grabbed me by the shoulders and she said, 'Yes!' So let's talk about the Sabre store then. Probably gonna look stupid, right? Like a big turd with a door on the front, right? What do you think it should look like?", "order": 48277, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 15, "scene": 15, "line": "Um, it might be fun if each store had a different theme, like a beach one, a winter one, a jungle one.", "order": 48278, "speaker": "Cathy Simms"},
  {"season": 8, "episode": 15, "scene": 15, "line": "No, not a big fan of the winter aesthetic.", "order": 48279, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 15, "scene": 15, "line": "Well that was just one idea. It doesn't have to be winter.", "order": 48280, "speaker": "Cathy Simms"},
  {"season": 8, "episode": 15, "scene": 15, "line": "Oh, no, it does. It does. It has to be winter, and I reject it. So I drew up a design concept. Probably gonna be rubbish, right? Would you like to look at it? Just so you could laugh at it.", "order": 48281, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 15, "scene": 15, "line": "Yeah!", "order": 48282, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 15, "scene": 15, "line": "Let me just get the projector working.", "order": 48283, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 15, "scene": 15, "line": "Uh. [eats antacids]", "order": 48284, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 15, "scene": 15, "line": "You got to stop with the antacids. It's not the antidote.", "order": 48285, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 15, "scene": 15, "line": "You didn't poison me, it's just stress.", "order": 48286, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 15, "scene": 15, "line": "Okay.", "order": 48287, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 15, "scene": 15, "line": "What is the antidote?", "order": 48288, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 15, "scene": 15, "line": "True love's kiss.", "order": 48289, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 15, "scene": 15, "line": "Jim, help me lower this screen. You're a big, tall man. [points to Ryan] You'd be hilarious trying to do it, like a little boy. Just let go of his balloon.", "order": 48290, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 15, "scene": 15, "line": "I'll do it! I always say, 'You want something done right? Ask Dwight.' Right? Dwight. Right? Dwight. Right Dwight, right Dwight. Sorry, now you'll never be able to get that out of your head. [reaches up, whimpers]", "order": 48291, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 15, "scene": 15, "line": "I can do it.", "order": 48292, "speaker": "Todd Packer"},
  {"season": 8, "episode": 15, "scene": 15, "line": "Mmm! [screams, pulls down screen] There we go.", "order": 48293, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 15, "scene": 16, "line": "[on phone] Yes, a complete inability to pass gas, pervert. Ugh, yeah, it's tender. It can't be appendicitis, I eat more than enough bacon. Okay, what poison mimics the symptoms of appendicitis? Oh, you don't know? Hold on. [to Jim] What kind of poison did you use?", "order": 48294, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 15, "scene": 16, "line": "Dwight, I didn't poison you. I was kidding.", "order": 48295, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 15, "scene": 16, "line": "Yeah, I'm gonna need an ambulance. Hold on, I have the address in my phone. [sees chart on screen] Hold on. Hold on one second. Uh, quick question. Vice president, uh, who is that? There's no name listed.", "order": 48296, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 15, "scene": 16, "line": "Is there not? Maybe I'm just waiting for someone to wow me. [winks]", "order": 48297, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 15, "scene": 16, "line": "Sir? Sir, where should we send the ambulance?", "order": 48298, "speaker": "Emergency Operator"},
  {"season": 8, "episode": 15, "scene": 16, "line": "Send it to the frickin' moon, idiot.", "order": 48299, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 15, "scene": 17, "line": "I tell you what though, since my move down to Florida I've really gotten back into hunting big time.", "order": 48300, "speaker": "Todd Packer"},
  {"season": 8, "episode": 15, "scene": 17, "line": "Hunting's so primal. Almost sexual.", "order": 48301, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 15, "scene": 17, "line": "Totally. I mean, I'd never bang an animal, you can hardly tell the difference between a boy or a girl, you know, but hunting's intense.", "order": 48302, "speaker": "Todd Packer"},
  {"season": 8, "episode": 15, "scene": 17, "line": "You talking about hunting? I love hunting. I'm a master hunter.", "order": 48303, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 15, "scene": 17, "line": "Did you say 'masturbator?'", "order": 48304, "speaker": "Todd Packer"},
  {"season": 8, "episode": 15, "scene": 17, "line": "I'm a decent baiter. My cousin Mose, that's a master baiter.", "order": 48305, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 15, "scene": 17, "line": "Why are you sitting down like that?", "order": 48306, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 15, "scene": 17, "line": "Why is everyone else standing up? Okay team, let's get back to work! Break's over!", "order": 48307, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 15, "scene": 18, "line": "Wow. Are you that bored?", "order": 48308, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 15, "scene": 18, "line": "It's just rum. I'm not bored, I'm a pirate.", "order": 48309, "speaker": "Stanley Hudson"},
  {"season": 8, "episode": 15, "scene": 18, "line": "Is that another motto?", "order": 48310, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 15, "scene": 18, "line": "It's whatever you want. [offers Jim the bottle]", "order": 48311, "speaker": "Stanley Hudson"},
  {"season": 8, "episode": 15, "scene": 18, "line": "Mmm.", "order": 48312, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 15, "scene": 18, "line": "Or do you only drink with your kids?", "order": 48313, "speaker": "Stanley Hudson"},
  {"season": 8, "episode": 15, "scene": 18, "line": "Ah, let's do it. [laughs] Oh, that's healthy.", "order": 48314, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 15, "scene": 19, "line": "I've spent so much of my life telling myself 'Please, don't end up like Stanley,' and now I'm wondering if I even have what it takes.", "order": 48315, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 15, "scene": 20, "line": "We have the Sabre Pyramid, right? Hmm? We've got a bunch of humans. You guys, humans... pyramid. Human pyramid. Do you follow? It's a team-building exercise. You'll love it. Who's in? Hmm?", "order": 48316, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 15, "scene": 20, "line": "Yup. Tallahassee, let's go.", "order": 48317, "speaker": "Todd Packer"},
  {"season": 8, "episode": 15, "scene": 20, "line": "Jim, are you in?", "order": 48318, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 15, "scene": 20, "line": "Oh, I don't know.", "order": 48319, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 15, "scene": 20, "line": "Sounds like a hoot. I'm in.", "order": 48320, "speaker": "Stanley Hudson"},
  {"season": 8, "episode": 15, "scene": 20, "line": "All right, what the heck? Let's do it.", "order": 48321, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 15, "scene": 20, "line": "This is great. This is gonna be great.", "order": 48322, "speaker": "Todd Packer"},
  {"season": 8, "episode": 15, "scene": 20, "line": "I feel like I'm in ancient Egypt.", "order": 48323, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 15, "scene": 20, "line": "I'll be on top. It's the most important position.", "order": 48324, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 15, "scene": 20, "line": "Dude, I think you have appendicitis.", "order": 48325, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 15, "scene": 20, "line": "[tries to climb human pyramid] Ahh!", "order": 48326, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 15, "scene": 20, "line": "Dwight?", "order": 48327, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 15, "scene": 20, "line": "Dwight, why don't we just hold off with the human pyramids for a while?", "order": 48328, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 15, "scene": 20, "line": "Everyone stop moving! Everyone stop wiggling! Oh, I feel like I'm gonna vomit!", "order": 48329, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 15, "scene": 20, "line": "Dude, don't you yak on me. This shirt is Van Heusen.", "order": 48330, "speaker": "Todd Packer"},
  {"season": 8, "episode": 15, "scene": 20, "line": "[groans] Stop moving!", "order": 48331, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 15, "scene": 20, "line": "No one's moving!", "order": 48332, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 15, "scene": 20, "line": "Oh! [collapses]", "order": 48333, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 15, "scene": 20, "line": "Oh, oh! [general commotion]", "order": 48334, "speaker": "Everyone"},
  {"season": 8, "episode": 15, "scene": 21, "line": "Arrest Jim. He poisoned me.", "order": 48335, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 15, "scene": 21, "line": "Wha- no. No, it was not a poisoning.", "order": 48336, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 15, "scene": 22, "line": "The one thing Pam made sure I knew, Florida's pretty loose with the death penalty.", "order": 48337, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 15, "scene": 22, "line": "You need an operation. You have appendicitis.", "order": 48338, "speaker": "Paramedic"},
  {"season": 8, "episode": 15, "scene": 22, "line": "Oh! Who called it? Nothin' but net.", "order": 48339, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 15, "scene": 22, "line": "How long will he be gone?", "order": 48340, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 15, "scene": 22, "line": "Two or three days.", "order": 48341, "speaker": "Paramedic"},
  {"season": 8, "episode": 15, "scene": 22, "line": "Don't remember me like this. Remember me as the man who pulled down the screen.", "order": 48342, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 15, "scene": 22, "line": "Drama queen, am I right?", "order": 48343, "speaker": "Todd Packer"},
  {"season": 8, "episode": 15, "scene": 22, "line": "[to phone] Phillip, if you're hearing this memo, that can only mean one thing, I'm dead. You are the rightful heir to Schrute Farms. Please, you must do one thing. Kill Mose before he kills you. [presses button] Mose, hey, it's Dwight. Listen, yeah, I just want to give you a heads-up.", "order": 48344, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 15, "scene": 22, "line": "That was an interesting diversion. Shall we get back to the meeting?", "order": 48345, "speaker": "Todd Packer"},
  {"season": 8, "episode": 15, "scene": 23, "line": "Whoa, are those mini pizzas?", "order": 48346, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 15, "scene": 23, "line": "Yeah, I figured we'd keep things savory while Oscar's mouth is in canker country. I also have some bacon-wrapped dates on deck.", "order": 48347, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 15, "scene": 23, "line": "Look at these little mini pizzas. Does this make me look huge? [giggles] Hey guys, look at me, I'm huge.", "order": 48348, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 15, "scene": 23, "line": "Hey Darryl, good news, your fax went through.", "order": 48349, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 15, "scene": 23, "line": "Oh [bleep] yeah.", "order": 48350, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 15, "scene": 23, "line": "Up high.", "order": 48351, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 15, "scene": 23, "line": "Yes sir. Thanks.", "order": 48352, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 15, "scene": 23, "line": "Dunder Mifflin, this is Andy. [whispers to Kelly] I put out some new magazines, check it out.", "order": 48353, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 15, "scene": 23, "line": "Ah, Dwell.", "order": 48354, "speaker": "Creed Bratton"},
  {"season": 8, "episode": 15, "scene": 23, "line": "I'm acting like I like reception and I'm a really good actor, so people are actually buying that I actually like reception, but, I mean, seriously, reception sucks. [phone rings] Oh, there's the phone!", "order": 48355, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 15, "scene": 24, "line": "I just got out of surgery. What's going on? Fill me in.", "order": 48356, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 15, "scene": 24, "line": "Well, we broke into groups and the groups are doing presentations later.", "order": 48357, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 15, "scene": 24, "line": "Who's doing the presentations?", "order": 48358, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 15, "scene": 24, "line": "Packer's giving one. [Dwight growls] Jim'll probably give ours, I guess.", "order": 48359, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 15, "scene": 24, "line": "Damn it!", "order": 48360, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 15, "scene": 24, "line": "Let's see, what else have you missed? Ryan switched his name tag to his pants, so now it's like if you wear yours on your shirt, you're a total dip, but if you switch, you're a copycat. But I think I figured out a solution. What else? My shoes are gonna-", "order": 48361, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 15, "scene": 25, "line": "-features a tech support area. Bring in your product, a bunch of nerdy virgins fix it on the spot. Huge for building loyalty.", "order": 48362, "speaker": "Todd Packer"},
  {"season": 8, "episode": 15, "scene": 25, "line": "Very good point.", "order": 48363, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 15, "scene": 25, "line": "Yeah Todd, decent idea. Obvious, but interesting.", "order": 48364, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 15, "scene": 25, "line": "Dwight, are you all right?", "order": 48365, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 15, "scene": 25, "line": "I'm great. How are you?", "order": 48366, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 15, "scene": 26, "line": "What's our presentation about?", "order": 48367, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 15, "scene": 26, "line": "Dwight, will you go back to the hospital? You were there for like three hours.", "order": 48368, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 15, "scene": 26, "line": "I got the surgery, what else is there to do?", "order": 48369, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 15, "scene": 26, "line": "Do a hundred jumping jacks.", "order": 48370, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 15, "scene": 26, "line": "No, I don't feel like it. You do a hundred jumping jacks.", "order": 48371, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 15, "scene": 26, "line": "I don't feel like it either!", "order": 48372, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 15, "scene": 27, "line": "[doing jumping jacks] Ninety-seven, ninety-eight, ninety-nine-", "order": 48373, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 15, "scene": 28, "line": "I want that vice-presidency.", "order": 48374, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 15, "scene": 28, "line": "You haven't done any of the research.", "order": 48375, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 15, "scene": 28, "line": "You're too slow, you're too small, Seabiscuit is a stupid name.' You guys sound just like the enemies of Seabiscuit. I'm going to do it.", "order": 48376, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 15, "scene": 28, "line": "Aw, let him do it.", "order": 48377, "speaker": "Stanley Hudson"},
  {"season": 8, "episode": 15, "scene": 28, "line": "Stanley, are you listening to music?", "order": 48378, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 15, "scene": 28, "line": "Yup.", "order": 48379, "speaker": "Stanley Hudson"},
  {"season": 8, "episode": 15, "scene": 28, "line": "All right, who's ready for the next presentation?", "order": 48380, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 15, "scene": 28, "line": "Ah, what is your topic?", "order": 48381, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 15, "scene": 28, "line": "What is our topic? Oh, what a topic it is.", "order": 48382, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 15, "scene": 28, "line": "Retail consumer habits.", "order": 48383, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 15, "scene": 28, "line": "Really? Okay. Retail consumer habits is... [sighs] The only thing you need to know about retail consumer habits is that consumers are mindless lemmings. They just want to be told what to do. [Erin makes the first slide appear] Fast forward to today, and the retail consumer is independent, well-informed, super smart. Super smart. Okay.", "order": 48384, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 15, "scene": 29, "line": "Shopping habits are both seasonal and cyclical. What does this mean?", "order": 48385, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 15, "scene": 29, "line": "I can field this one.", "order": 48386, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 15, "scene": 29, "line": "No, sit down. 'Seasonal.' Well, the Christmas shopping season is certainly a busy one, back-to-school is, as well, and in early summer it seems to slow down a bit, but shopping is also cyclical. The menstrual cycle determines every choice a woman makes. You see, during ovulation, a woman's only goal is to get pregnant, and during menses, she is sad that she has failed. And how does the woman console herself? Shopping. Shopping. Just- Jim, could you fetch me some ice chips? Thank you. Just gonna... take a... brief pause at this point. [Jim gives Dwight a bowl of ice chips] Thank you. [Dwight sticks his head in the bowl] Oh, God. Next slide.", "order": 48387, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 15, "scene": 30, "line": "Mail call! [sings] His name is Oscar, and he got some mail, and he better open it, or go to jail 'cause it's your taxes. His name is Kevin, and no mail for him, but he got a coupon, for some frozen... yogurt.", "order": 48388, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 15, "scene": 30, "line": "Are those the lyrics?", "order": 48389, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 15, "scene": 31, "line": "Anderson's three pillars of retail. Crucial. So important. Next. Are there any questions?", "order": 48390, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 15, "scene": 31, "line": "What are the three pillars of retail?", "order": 48391, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 15, "scene": 31, "line": "[whispers] Convenience.", "order": 48392, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 15, "scene": 31, "line": "Ingredients.", "order": 48393, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 15, "scene": 31, "line": "Service.", "order": 48394, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 15, "scene": 31, "line": "Burgers.", "order": 48395, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 15, "scene": 31, "line": "Building loyalty.", "order": 48396, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 15, "scene": 31, "line": "Killing royalty. The truth be told, we should really disregard Anderson's three pillars. He was later diagnosed with dementia. You know what is important? Is Dwight's pillars, and there is only one: desire. Do we have a desire to dominate the retail space? Not just succeed in it, but to dominate, and looking around this room I'm not so sure that we do, but we can get there, and I can lead us there, and that is all that matters.", "order": 48397, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 15, "scene": 31, "line": "Very true. Where there's a will, there's a way. I once spent a passionate night with Hugh Grant's brother, John Grant. He's older than Hugh, just a little bit uglier. How did I pull that off? Sheer force of will. That is very good, Dwight.", "order": 48398, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 15, "scene": 31, "line": "Thank you very much. Any other questions? Jim?", "order": 48399, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 15, "scene": 31, "line": "You are bleeding through your shirt.", "order": 48400, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 15, "scene": 31, "line": "Oops. That's embarrassing. Egg on my face. [ties jacket around wound] Ah.", "order": 48401, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 15, "scene": 32, "line": "Hey, having fun?", "order": 48402, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 15, "scene": 32, "line": "Yes I am, as a matter of fact.", "order": 48403, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 15, "scene": 32, "line": "Well I know how it is. I know it's a lot of fun. I don't know how it is. Andy, this is a lame job. What are you doing here?", "order": 48404, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 15, "scene": 32, "line": "I found my calling.", "order": 48405, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 15, "scene": 32, "line": "Andy, when I was twelve years old, we did a field trip, a lock-in at the zoo. I met this girl, I thought she was so perfect. I was in love with her. But when the sun came up, I knew it wasn't real, 'cause she was ugly and I had grown tired of her. I know you want reception today, but tomorrow you won't want to do her. She's a dog.", "order": 48406, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 15, "scene": 32, "line": "Guys, I don't like this analogy.", "order": 48407, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 15, "scene": 32, "line": "[groans] Ugh! Fine. Did you see this? [points to pens in cup]", "order": 48408, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 15, "scene": 32, "line": "It's nice.", "order": 48409, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 15, "scene": 32, "line": "I mean... [Pam giggles]", "order": 48410, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 15, "scene": 33, "line": "How are you feeling?", "order": 48411, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 15, "scene": 33, "line": "Amazing. Just lost four ounces of appendix, already replaced it with muscle.", "order": 48412, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 15, "scene": 33, "line": "Can I see the wound?", "order": 48413, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 15, "scene": 33, "line": "[show her the wound] Oh God.", "order": 48414, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 15, "scene": 33, "line": "Oh! That's disgusting. That's barely healed.", "order": 48415, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 15, "scene": 33, "line": "You're not paying me to heal, you're paying me to kick ass.", "order": 48416, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 15, "scene": 33, "line": "A few hours ago, your body was open like a cabinet.", "order": 48417, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 15, "scene": 33, "line": "That's right.", "order": 48418, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 15, "scene": 33, "line": "That is amazing. Todd, look at that.", "order": 48419, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 15, "scene": 33, "line": "Oh, yikes. Incoming- [tries to touch the wound]", "order": 48420, "speaker": "Todd Packer"},
  {"season": 8, "episode": 15, "scene": 33, "line": "Ah! Not so fast.", "order": 48421, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 15, "scene": 33, "line": "Would you come in early tomorrow so we can talk about the store over breakfast? I feel you [points to both] have a lot to offer.", "order": 48422, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 15, "scene": 33, "line": "It would be an honor, ma'am.", "order": 48423, "speaker": "Todd Packer"},
  {"season": 8, "episode": 15, "scene": 33, "line": "I'll go ahead and cancel my post-op check-up right now.", "order": 48424, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 15, "scene": 34, "line": "I had two goals today. The first was to make myself the clear candidate for vice president. I achieved that, no thanks to my appendix. The second was to find a memorable souvenir for Philip. For that, I do have to thank my appendix.", "order": 48425, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 15, "scene": 35, "line": "[answers the reception phone] Dunder Mifflin, how can I help you save money on paper? This is Andrew.", "order": 48426, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 15, "scene": 35, "line": "Hi Andrew. What happened to Erin?", "order": 48427, "speaker": "Donna Muraski"},
  {"season": 8, "episode": 15, "scene": 35, "line": "Oh, she's on an assignment in Florida for a couple of weeks.", "order": 48428, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 15, "scene": 35, "line": "Oh, good for her. She's such a sweetheart. You tell her Donna Muraski misses her.", "order": 48429, "speaker": "Donna Muraski"},
  {"season": 8, "episode": 15, "scene": 35, "line": "I miss her too.", "order": 48430, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 15, "scene": 36, "line": "Everyone, we have a very special guest. He turned an idea into a store, into hundreds of stores. I give you Wally Amos, founder of Famous Amos Cookies. [cheers, claps]", "order": 48431, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 15, "scene": 36, "line": "Aloha, aloha, aloha. So I hear you're all looking to bake up some dough in the sales game. Well, back when I was regular Amos-", "order": 48432, "speaker": "Wally Amos"},
  {"season": 8, "episode": 15, "scene": 36, "line": "What's under the cloth?", "order": 48433, "speaker": "Stanley Hudson"},
  {"season": 8, "episode": 15, "scene": 36, "line": "We'll get to that.", "order": 48434, "speaker": "Wally Amos"},
  {"season": 8, "episode": 15, "scene": 36, "line": "Cookies. Bet you anything it's cookies.", "order": 48435, "speaker": "Todd Packer"},
  {"season": 8, "episode": 15, "scene": 36, "line": "It's cookies. Let me- let me finish my speech first.", "order": 48436, "speaker": "Wally Amos"},
  {"season": 8, "episode": 15, "scene": 36, "line": "Ah, we get the gist. It's just success and effort, isn't it? So just, don't be coy, make with the cookies! [everyone goes for the cookies]", "order": 48437, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 15, "scene": 36, "line": "Famous, hi. I'm sure you get ideas for new cookies all the time, but I-", "order": 48438, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 15, "scene": 36, "line": "Is it oatmeal with no raisins?", "order": 48439, "speaker": "Wally Amos"},
  {"season": 8, "episode": 15, "scene": 36, "line": "I'm sorry to have wasted your time.", "order": 48440, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 16, "scene": 1, "line": "Hey Angela, you wanna see a picture of Phillip wearing those little booties you got us?", "order": 48441, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 16, "scene": 1, "line": "So cute.", "order": 48442, "speaker": "Angela Martin"},
  {"season": 8, "episode": 16, "scene": 1, "line": "Hey, you guys want to see a picture of Gerald wearing galoshes? He refused to go out in the rain until I bought these. Now going out in the rain is all he wants to do.", "order": 48443, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 16, "scene": 2, "line": "Oscar thinks that having a dog is just like having a baby.", "order": 48444, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 16, "scene": 2, "line": "News flash - If you didn't carry it around for nine months, it isn't your kid.", "order": 48445, "speaker": "Angela Martin"},
  {"season": 8, "episode": 16, "scene": 2, "line": "Exactly. Unless you adopted, of course.", "order": 48446, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 16, "scene": 2, "line": "That is where we disagree.", "order": 48447, "speaker": "Angela Martin"},
  {"season": 8, "episode": 16, "scene": 3, "line": "Hey, are your little dudes crawling yet?", "order": 48448, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 16, "scene": 3, "line": "No, three-month-old humans don't do that.", "order": 48449, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 16, "scene": 3, "line": "My Phillip is crawling.", "order": 48450, "speaker": "Angela Martin"},
  {"season": 8, "episode": 16, "scene": 4, "line": "Angela is such a liar!", "order": 48451, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 16, "scene": 4, "line": "It's maddening!", "order": 48452, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 16, "scene": 5, "line": "Exactly. That's just like crate training. All night long, all night long.", "order": 48453, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 16, "scene": 5, "line": "Well count yourself lucky. Wait until you have two. That's two sleep schedules, two naps that don't coincide, I mean, you'll never sleep again.", "order": 48454, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 16, "scene": 6, "line": "No one said 'you must have two'", "order": 48455, "speaker": "Angela Martin"},
  {"season": 8, "episode": 16, "scene": 6, "line": "Like her genes are so important. The world just needs more Pam/Jim DNA. Thank you, no.", "order": 48456, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 16, "scene": 6, "line": "No thank you.", "order": 48457, "speaker": "Angela Martin"},
  {"season": 8, "episode": 16, "scene": 7, "line": "[laughing] Check this out. My brother just got a new sailboat. He has NO idea what he just got himself into. There's nothing harder than taking care of a boat'am i right?", "order": 48458, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 16, "scene": 8, "line": "Unbelievable!", "order": 48459, "speaker": "Angela Martin & Pam Halpert"},
  {"season": 8, "episode": 16, "scene": 8, "line": "Un-be-liev-a-ble.", "order": 48460, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 16, "scene": 9, "line": "It is the end of the day, it is the end of the week. We managed to fill 40 hours somehow. That's not bad, is it?", "order": 48461, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 16, "scene": 9, "line": "That's great.", "order": 48462, "speaker": "Todd Packer"},
  {"season": 8, "episode": 16, "scene": 9, "line": "Pretty good.", "order": 48463, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 16, "scene": 9, "line": "Well thank you. This is very helpful feedback. [Dwight raises his hand] Dwight, I am still not ready to name a VP.", "order": 48464, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 16, "scene": 9, "line": "[laughing] That's not what I was going to ask. Gosh, jump to conclusions. Come on, I know, you've got so much on your plate. Right now, you're like 'oh, what's more important? Dwight's question, figure out who's the VP?' Dwight, VP. Dwight, VP. Dwight, VP. Dwight, VP.", "order": 48465, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 16, "scene": 9, "line": "I can't be hypnotized, Dwight. I tried it, I ended up smoking more.", "order": 48466, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 16, "scene": 9, "line": "Can we at least eliminate those that are not in the running? Free them up to focus more completely on the menial. Like stacking or sorting, or-", "order": 48467, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 16, "scene": 9, "line": "Catching butterflies.", "order": 48468, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 16, "scene": 9, "line": "That's a hobby, unless it's for food.", "order": 48469, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 16, "scene": 9, "line": "Theater.", "order": 48470, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 16, "scene": 9, "line": "Waste of time.", "order": 48471, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 16, "scene": 9, "line": "Dragging.", "order": 48472, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 16, "scene": 9, "line": "That's just a verb.", "order": 48473, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 16, "scene": 9, "line": "Dragging sticks.", "order": 48474, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 16, "scene": 9, "line": "Yes, nice, perfect. Dragging sticks.", "order": 48475, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 16, "scene": 9, "line": "Alright, I'm gonna eliminate three people right now, okay? Just' you three, no.", "order": 48476, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 16, "scene": 9, "line": "Not good enough (clicks tongue)", "order": 48477, "speaker": "Todd Packer"},
  {"season": 8, "episode": 16, "scene": 10, "line": "English peoples' main use today is judging American talent. [British accent] You're crap. You're wonderful. [back to American] They're mean, but they're incisive.", "order": 48478, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 16, "scene": 11, "line": "Everyone meet in the hotel bar at 7:00. I'm not allowed to say it's mandatory, so let's just call it compulsory.", "order": 48479, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 16, "scene": 11, "line": "I'll be there at 6:00", "order": 48480, "speaker": "Todd Packer"},
  {"season": 8, "episode": 16, "scene": 11, "line": "Ugh, we're gonna have one of those crazy nights, aren't we? Maybe we'll see the real 'Talla-nasty' we've been hearing so much about.", "order": 48481, "speaker": "Cathy Simms"},
  {"season": 8, "episode": 16, "scene": 11, "line": "Talla-nasty', very clever.", "order": 48482, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 16, "scene": 11, "line": "Thank you-", "order": 48483, "speaker": "Cathy Simms"},
  {"season": 8, "episode": 16, "scene": 11, "line": "Wait, wait, you think she invented 'Talla-nasty?' [chuckles] no, no, no, no'", "order": 48484, "speaker": "Gabe Lewis"},
  {"season": 8, "episode": 16, "scene": 12, "line": "Cathy? It's been great. Fun, normal. [in voiceover] I thought I was gonna be hanging out with stanley on this trip, but he's turned out to be kind of a loose cannon.", "order": 48485, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 16, "scene": 12, "line": "My friend and I are new here in Tallahassee. Would you like to get a cocktail? [Jim slowly slinks down in his seat, with the 'Jim Face']. Maybe go out for a little dancing. Beautiful day, no?", "order": 48486, "speaker": "Stanley Hudson"},
  {"season": 8, "episode": 16, "scene": 13, "line": "Okay, everybody. 5:00. Workday is over. Put your pencils down. Aaannnnddd bring 'em back up, because now, the late night work jam begins!", "order": 48487, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 16, "scene": 13, "line": "Yes, the conference room is set up. I've got pens, I've got paper, I've got a whiteboard, we are good to go!", "order": 48488, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 16, "scene": 13, "line": "Say what?", "order": 48489, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 16, "scene": 13, "line": "We are good to goooo!", "order": 48490, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 16, "scene": 13, "line": "Say what?", "order": 48491, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 16, "scene": 13, "line": "We're good to goooo!", "order": 48492, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 16, "scene": 13, "line": "Stop it.", "order": 48493, "speaker": "Angela Martin"},
  {"season": 8, "episode": 16, "scene": 13, "line": "Say-", "order": 48494, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 16, "scene": 13, "line": "Stop it.", "order": 48495, "speaker": "Angela Martin"},
  {"season": 8, "episode": 16, "scene": 13, "line": "Andy.", "order": 48496, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 16, "scene": 14, "line": "Tonight we're staying late to service the accounts of the people who went on the Florida trip.", "order": 48497, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 16, "scene": 14, "line": "Yes, of course, we could've just been doing this the whole time, but SOMEONE dropped the ball.", "order": 48498, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 16, "scene": 14, "line": "ball droppings can be beautiful. For example, when it turns an awkward soprano into a rich, full tenor.", "order": 48499, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 16, "scene": 15, "line": "Jason Bourne would kick Bond's ass.", "order": 48500, "speaker": "Todd Packer"},
  {"season": 8, "episode": 16, "scene": 15, "line": "Jason Bourne has no support staff. His own government's out to get him.", "order": 48501, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 16, "scene": 15, "line": "Genghis Khan could take 'em both down 'cause he's not afraid to kill children ' What? it's true. He- He would- he would eviscerate babies and hang them from poles outside of the villages. (Jim taps Dwight on the shoulder)", "order": 48502, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 16, "scene": 15, "line": "Okay, drinking game. Count of three, take three sips.", "order": 48503, "speaker": "Todd Packer"},
  {"season": 8, "episode": 16, "scene": 15, "line": "That's a stupid game. I got a great drinking game. Okay, everyone empty your pockets. Whoever has the most seeds is the king. Whoever has the least buttons is the hunchback.", "order": 48504, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 16, "scene": 15, "line": "What happens if you're the hunchback?", "order": 48505, "speaker": "Gabe Lewis"},
  {"season": 8, "episode": 16, "scene": 15, "line": "Oh, you get kicked.", "order": 48506, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 16, "scene": 15, "line": "How many buttons do you have?", "order": 48507, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 16, "scene": 15, "line": "(takes out bag) 40. Always.", "order": 48508, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 16, "scene": 15, "line": "Can I see?", "order": 48509, "speaker": "Cathy Simms"},
  {"season": 8, "episode": 16, "scene": 15, "line": "Sure. (Hands bag to Cathy)", "order": 48510, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 16, "scene": 15, "line": "So, I get to kick you now.", "order": 48511, "speaker": "Cathy Simms"},
  {"season": 8, "episode": 16, "scene": 15, "line": "No, they're not transferrable just 'cause I handed 'em to you.", "order": 48512, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 16, "scene": 15, "line": "Well, that's how I played it in college.", "order": 48513, "speaker": "Cathy Simms"},
  {"season": 8, "episode": 16, "scene": 15, "line": "College rules (Cathy throws Jim the bag and Jim kicks Dwight)", "order": 48514, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 16, "scene": 15, "line": "Aah!", "order": 48515, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 16, "scene": 15, "line": "Aaaaand he host at his own lame game.", "order": 48516, "speaker": "Todd Packer"},
  {"season": 8, "episode": 16, "scene": 15, "line": "Ooh, double meaning! The game was lame, and now he's lame from the kick. Quick wit.", "order": 48517, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 16, "scene": 15, "line": "Thank you.", "order": 48518, "speaker": "Todd Packer"},
  {"season": 8, "episode": 16, "scene": 16, "line": "I'll have a glass of your oakiest chardonnay, please.", "order": 48519, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 16, "scene": 16, "line": "And I will have waffle with your mapliest syrup.", "order": 48520, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 16, "scene": 16, "line": "Sorry, no waffles.", "order": 48521, "speaker": "Waitress"},
  {"season": 8, "episode": 16, "scene": 16, "line": "Oh, okay. Just forget it, then. Forget it. Forget it.", "order": 48522, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 16, "scene": 16, "line": "A waffle?", "order": 48523, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 16, "scene": 16, "line": "A hotel waffle. I know what I want to eat. Is that crazy?", "order": 48524, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 16, "scene": 16, "line": "No.", "order": 48525, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 16, "scene": 16, "line": "I'm moving down here, you know.", "order": 48526, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 16, "scene": 16, "line": "Oh, no, I didn't know.", "order": 48527, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 16, "scene": 16, "line": "I'm young, and I can ' and if I can't, I'm still pretty young. I guess I'll always be young.", "order": 48528, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 16, "scene": 16, "line": "You come with me. We're gonna get you that waffle.", "order": 48529, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 16, "scene": 17, "line": "Why would Erin confide in me that she's staying here in Florida if she didn't want me to make some last-ditch, nothing to lose, Kelly Ka-who run at her? (Erin walks over) Hey.", "order": 48530, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 16, "scene": 17, "line": "Hey.", "order": 48531, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 16, "scene": 18, "line": "Watson carpet and tile.", "order": 48532, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 16, "scene": 18, "line": "One order.", "order": 48533, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 16, "scene": 18, "line": "Order reference number 00983-126.", "order": 48534, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 16, "scene": 18, "line": "Eight Cases, bright white inkjet.", "order": 48535, "speaker": "Phyllis Vance"},
  {"season": 8, "episode": 16, "scene": 18, "line": "Paid.", "order": 48536, "speaker": "Angela Martin"},
  {"season": 8, "episode": 16, "scene": 18, "line": "I'm dying!", "order": 48537, "speaker": "Kelly Kapoor"},
  {"season": 8, "episode": 16, "scene": 18, "line": "Processed.", "order": 48538, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 16, "scene": 18, "line": "And delivered.", "order": 48539, "speaker": "Val Johnson"},
  {"season": 8, "episode": 16, "scene": 19, "line": "Sometimes I get so bored I just want to scream, and then sometimes I actually do scream. I just sorta feel out what the situation calls for.", "order": 48540, "speaker": "Kelly Kapoor"},
  {"season": 8, "episode": 16, "scene": 20, "line": "I will be right back.", "order": 48541, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 16, "scene": 20, "line": "I will be here.", "order": 48542, "speaker": "Todd Packer"},
  {"season": 8, "episode": 16, "scene": 20, "line": "Hey. I see what you're doing. It's futile. the VP position is mine.", "order": 48543, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 16, "scene": 20, "line": "Oh, please, You're through. She's going to give it to me as I'm giving it to her tonight. The cowgirl has chosen her saddle. [clicks tongue, whinnies]", "order": 48544, "speaker": "Todd Packer"},
  {"season": 8, "episode": 16, "scene": 20, "line": "Well, saddles' [clicks tongue, whinnies] ' sometimes fall off, especially if you don't properly cinch the girth.", "order": 48545, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 16, "scene": 20, "line": "What? That's a joke, right? You see the way she's all over me.", "order": 48546, "speaker": "Todd Packer"},
  {"season": 8, "episode": 16, "scene": 21, "line": "If anyone's having sex with Nellie for personal gain, it's me, and I'm not joking at all. If you don't tighten your saddle, you may fall off.", "order": 48547, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 16, "scene": 22, "line": "Who is ready for dinner? 'oh, I bet it's pizza, or tacos, or something stupid'. No. [claps] I went south of the border for this. And then I went south of that border. And we have ourselves' [takes foil off tray] [Jamaican accent] A Jamaican feast, mon!", "order": 48548, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 16, "scene": 22, "line": "If I wanted Jamaican food I'd just hire a bunch of bodyguards and go there.", "order": 48549, "speaker": "Phyllis Vance"},
  {"season": 8, "episode": 16, "scene": 22, "line": "Additional trivia factoid, this food comes to us through Val. And our server is none other than Brandon, her boyf!", "order": 48550, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 16, "scene": 22, "line": "Hey.", "order": 48551, "speaker": "Val Johnson"},
  {"season": 8, "episode": 16, "scene": 22, "line": "How you doing, Val? [Val and Brandon kiss]", "order": 48552, "speaker": "Brandon"},
  {"season": 8, "episode": 16, "scene": 22, "line": "Jamaican, huh? I just read about the elections down there. Crazy stuff.", "order": 48553, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 16, "scene": 22, "line": "Oh no, I'm not actually Jamaican.", "order": 48554, "speaker": "Brandon"},
  {"season": 8, "episode": 16, "scene": 22, "line": "Terrific.", "order": 48555, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 16, "scene": 22, "line": "What's up man? I'm Darryl.", "order": 48556, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 16, "scene": 22, "line": "Darryl, I heard about you. You doing good?", "order": 48557, "speaker": "Brandon"},
  {"season": 8, "episode": 16, "scene": 22, "line": "Oh yeah.", "order": 48558, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 16, "scene": 22, "line": "Must be doing real good since you're f***ing my girlfriend.", "order": 48559, "speaker": "Brandon"},
  {"season": 8, "episode": 16, "scene": 22, "line": "Whoa.", "order": 48560, "speaker": "Everyone"},
  {"season": 8, "episode": 16, "scene": 22, "line": "Dude, you didn't tell me you were f***ing Val. High five!", "order": 48561, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 16, "scene": 22, "line": "I'm not sleeping with your girlfriend.", "order": 48562, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 16, "scene": 22, "line": "Brandon, what the hell are you talking about?", "order": 48563, "speaker": "Val Johnson"},
  {"season": 8, "episode": 16, "scene": 22, "line": "Don't play dumb with me. I know what's up.", "order": 48564, "speaker": "Brandon"},
  {"season": 8, "episode": 16, "scene": 22, "line": "Honey, I am not sleeping with-", "order": 48565, "speaker": "Val Johnson"},
  {"season": 8, "episode": 16, "scene": 22, "line": "Heyyy' let's get back to the food. Brandon, tell us about these delicious looking goops!", "order": 48566, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 16, "scene": 22, "line": "Hope you all like goat.", "order": 48567, "speaker": "Brandon"},
  {"season": 8, "episode": 16, "scene": 22, "line": "Goat'", "order": 48568, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 16, "scene": 23, "line": "Blow in my ear.", "order": 48569, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 16, "scene": 23, "line": "Alright.", "order": 48570, "speaker": "Todd Packer"},
  {"season": 8, "episode": 16, "scene": 23, "line": "Like I'm on the beach.", "order": 48571, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 16, "scene": 23, "line": "[imitates seagull calls] Seagull. [Continues seagull calls]", "order": 48572, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 16, "scene": 23, "line": "And a wave crashing' a wave crashing.", "order": 48573, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 16, "scene": 23, "line": "Oh, that is lovely.", "order": 48574, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 16, "scene": 23, "line": "Alright, that's gonna do it for me tonight. Have a lovely evening.", "order": 48575, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 16, "scene": 24, "line": "Hey, how's it going?", "order": 48576, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 16, "scene": 24, "line": "Hey, uh, good. Uh, the heating system though in my room is all messed up. It's like 90 degrees in there. Maintenance is working on it. Can I hang out here?", "order": 48577, "speaker": "Cathy Simms"},
  {"season": 8, "episode": 16, "scene": 24, "line": "Uh' yeah, I'm just watching basketball.", "order": 48578, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 16, "scene": 24, "line": "Okay, yeah.", "order": 48579, "speaker": "Cathy Simms"},
  {"season": 8, "episode": 16, "scene": 24, "line": "Okay.", "order": 48580, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 16, "scene": 24, "line": "Cool' [Jumps onto bed] Is this March Madness? I love March Madness.", "order": 48581, "speaker": "Cathy Simms"},
  {"season": 8, "episode": 16, "scene": 24, "line": "Oh, no, that's not this 'cause that's in' March. Uh, this is just the NBA. Professional. Miami Heat, actually, so' [sits on floor]", "order": 48582, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 16, "scene": 24, "line": "LeBron James.", "order": 48583, "speaker": "Cathy Simms"},
  {"season": 8, "episode": 16, "scene": 24, "line": "Yes, nice. Good word association", "order": 48584, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 16, "scene": 24, "line": "[giggles]", "order": 48585, "speaker": "Cathy Simms"},
  {"season": 8, "episode": 16, "scene": 25, "line": "Impressive.", "order": 48586, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 16, "scene": 25, "line": "Oh please, anyone can tie a knot, the real skill is in untying it [puts string in his mouth]", "order": 48587, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 16, "scene": 26, "line": "Thornwood Wholesalers.", "order": 48588, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 16, "scene": 26, "line": "One order.", "order": 48589, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 16, "scene": 26, "line": "I read the text you sent to Val, man.", "order": 48590, "speaker": "Brandon"},
  {"season": 8, "episode": 16, "scene": 26, "line": "That's messed up.", "order": 48591, "speaker": "Val Johnson"},
  {"season": 8, "episode": 16, "scene": 26, "line": "Is this spicy?", "order": 48592, "speaker": "Angela Martin"},
  {"season": 8, "episode": 16, "scene": 26, "line": "No, baby girl. It would be messed up if I didn't find anything, but I did, so-", "order": 48593, "speaker": "Brandon"},
  {"season": 8, "episode": 16, "scene": 26, "line": "I'm pretty sure if Darryl sent Val a text, it was about some paper emergency or something.", "order": 48594, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 16, "scene": 26, "line": "At midnight?", "order": 48595, "speaker": "Brandon"},
  {"season": 8, "episode": 16, "scene": 26, "line": " yeah'", "order": 48596, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 16, "scene": 26, "line": "It happens! Like a hospital needs more napkins for surgery'", "order": 48597, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 16, "scene": 26, "line": "Guys, we're not gonna settle anything this way. I think we just need to be grown-ups here.", "order": 48598, "speaker": "Kelly Kapoor"},
  {"season": 8, "episode": 16, "scene": 26, "line": "Thank you.", "order": 48599, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 16, "scene": 26, "line": "I thin we're gonna need to here those texts.", "order": 48600, "speaker": "Kelly Kapoor"},
  {"season": 8, "episode": 16, "scene": 26, "line": "Kelly, remember how you wanted to go home before?", "order": 48601, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 16, "scene": 26, "line": "No. There's no way in hell I'm leaving. Something interesting is happening here for once in my life, I am staying here. Darryl, read the texts.", "order": 48602, "speaker": "Kelly Kapoor"},
  {"season": 8, "episode": 16, "scene": 27, "line": "Yup, coming. [opens door] All right.", "order": 48603, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 16, "scene": 27, "line": "My mini bar is oddly out of rum.", "order": 48604, "speaker": "Stanley Hudson"},
  {"season": 8, "episode": 16, "scene": 27, "line": "We have plenty.", "order": 48605, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 16, "scene": 27, "line": "Oh, hello. [laughs with Cathy]", "order": 48606, "speaker": "Stanley Hudson"},
  {"season": 8, "episode": 16, "scene": 27, "line": "Do you want to watch the game with us? We're watching a game. The Heat game. Just a game.", "order": 48607, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 16, "scene": 27, "line": "Got it. Good night. Careful Jim, it gets easier and easier.", "order": 48608, "speaker": "Stanley Hudson"},
  {"season": 8, "episode": 16, "scene": 27, "line": "No, no, no, Stanley.", "order": 48609, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 16, "scene": 27, "line": "mm-hmm. [about to leave]", "order": 48610, "speaker": "Stanley Hudson"},
  {"season": 8, "episode": 16, "scene": 27, "line": "Stanley. Stanley.", "order": 48611, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 16, "scene": 27, "line": "uh-huh.", "order": 48612, "speaker": "Stanley Hudson"},
  {"season": 8, "episode": 16, "scene": 27, "line": "Stanley. Uhh' you know what? Just bring back those bottles!", "order": 48613, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 16, "scene": 28, "line": "Whoa. the lights are still on.", "order": 48614, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 16, "scene": 28, "line": "Yeah, they keep them on 'cause it's less expensive than turning them on and off.", "order": 48615, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 16, "scene": 28, "line": "I like how guys just know stuff all the time.", "order": 48616, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 16, "scene": 28, "line": "Girls know a lot of stuff too, okay? And nobody knows more than you. Especially me.", "order": 48617, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 16, "scene": 28, "line": "[gasps] Hello, waffle iron.", "order": 48618, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 16, "scene": 28, "line": "Hello, what do we have here?", "order": 48619, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 16, "scene": 28, "line": "Okay, so the recipe says we need a mixing bowl'", "order": 48620, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 16, "scene": 28, "line": "Right.", "order": 48621, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 16, "scene": 28, "line": " a measuring cup'", "order": 48622, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 16, "scene": 28, "line": "Got that. [Metallic thud] Get down.", "order": 48623, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 16, "scene": 28, "line": "[whispers] It's the fuzz!", "order": 48624, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 16, "scene": 28, "line": "[whispers] Shh! do you want us to get scolded? Shut your beautiful, beautiful mouth, please.", "order": 48625, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 16, "scene": 29, "line": "I got too much ice cream. You want some?' 'Getting' my fry on.'", "order": 48626, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 16, "scene": 29, "line": "Boring.", "order": 48627, "speaker": "Kelly Kapoor"},
  {"season": 8, "episode": 16, "scene": 29, "line": "Uh, 'The moon is huge tonight.'", "order": 48628, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 16, "scene": 29, "line": "Ooh, gosh, the moon one's damning. Yeah, sorry.", "order": 48629, "speaker": "Phyllis Vance"},
  {"season": 8, "episode": 16, "scene": 29, "line": "That's regular text talk.", "order": 48630, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 16, "scene": 29, "line": "You forgot one.", "order": 48631, "speaker": "Brandon"},
  {"season": 8, "episode": 16, "scene": 29, "line": "You're such a great friend.'", "order": 48632, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 16, "scene": 29, "line": "With the dots.", "order": 48633, "speaker": "Brandon"},
  {"season": 8, "episode": 16, "scene": 29, "line": "You're such a great friend, dot dot dot dot dot.'", "order": 48634, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 16, "scene": 29, "line": "Five dots, Darryl, are you kidding me? Okay, 'cause three dots means 'to be continued', four dots is a typo, but five dots means 'Whoa, do not make me say what I want to say, baby, but if I did, it would blow your mind, dot, dot, dot, dot, dot.", "order": 48635, "speaker": "Kelly Kapoor"},
  {"season": 8, "episode": 16, "scene": 29, "line": "Oh'", "order": 48636, "speaker": "Everyone"},
  {"season": 8, "episode": 16, "scene": 29, "line": "See? Yes. Thank you, sister.", "order": 48637, "speaker": "Brandon"},
  {"season": 8, "episode": 16, "scene": 29, "line": "Brandon, Darryl and me? That's ridiculous, right?", "order": 48638, "speaker": "Val Johnson"},
  {"season": 8, "episode": 16, "scene": 29, "line": "Yeah, totally crazy. Puts me in an insane asylum just thinking about it. I'm stranded on shutter island over here.", "order": 48639, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 16, "scene": 30, "line": "You look like Ed Harris if they stretched him a little bit.", "order": 48640, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 16, "scene": 30, "line": "You wanna see a picture of me trapped under a tree? [cell phone rings] 'ahhmmm' excuse me' What?! Not now, I'm busy.", "order": 48641, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 16, "scene": 30, "line": "[on phone] I'm sorry, I thought you'd want to know that I saw a bedbug in my room. But, never mind, sorry to interrupt.", "order": 48642, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 16, "scene": 30, "line": "Wait wait wait wait, bedbugs? Oh no' Freak, I need a favor.", "order": 48643, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 16, "scene": 30, "line": "Well, then you have to call me by my name. Gabriel Susan Lewis.", "order": 48644, "speaker": "Gabe Lewis"},
  {"season": 8, "episode": 16, "scene": 30, "line": "I gotta be gone for a few minutes. You make sure that Packer does NOT sleep with Nellie.", "order": 48645, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 16, "scene": 30, "line": "What's in it for GSL?", "order": 48646, "speaker": "Gabe Lewis"},
  {"season": 8, "episode": 16, "scene": 30, "line": "You really want Packer as your boss?", "order": 48647, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 16, "scene": 30, "line": "Got it.", "order": 48648, "speaker": "Gabe Lewis"},
  {"season": 8, "episode": 16, "scene": 31, "line": "If Jim has bedbugs, that means they're everywhere. I can't risk them coming back to Schrute Farms. Our biggest attraction is our 200 year old mattresses.", "order": 48649, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 16, "scene": 32, "line": "Where did you see it?", "order": 48650, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 16, "scene": 32, "line": "In the bed.", "order": 48651, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 16, "scene": 32, "line": "I haven't seen anything.", "order": 48652, "speaker": "Cathy Simms"},
  {"season": 8, "episode": 16, "scene": 32, "line": "We gotta find it before it eggs. [pulls sheets off bed]", "order": 48653, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 16, "scene": 32, "line": "Jeez'", "order": 48654, "speaker": "Cathy Simms"},
  {"season": 8, "episode": 16, "scene": 32, "line": "Describe it.", "order": 48655, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 16, "scene": 32, "line": "Brown, shiny, painful bite.", "order": 48656, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 16, "scene": 32, "line": "Could be a bat weevil' Describe its mood. Did it seen sleepy?", "order": 48657, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 16, "scene": 32, "line": "Stressed, but to be fair, it was a tense situation.", "order": 48658, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 16, "scene": 32, "line": "Fair enough, but after it bit you, did it run away fearful, or did it walk away smug, self-assured?", "order": 48659, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 16, "scene": 32, "line": "So smug. Like he thought it was funny, like this.", "order": 48660, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 16, "scene": 32, "line": "Pshh. That's a bedbug.", "order": 48661, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 16, "scene": 32, "line": "Yeah.", "order": 48662, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 16, "scene": 32, "line": "Everything's a joke.", "order": 48663, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 16, "scene": 32, "line": "I know.", "order": 48664, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 16, "scene": 32, "line": "Check your hair! [checks Jim's hair]", "order": 48665, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 16, "scene": 32, "line": "Ow.", "order": 48666, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 16, "scene": 32, "line": "God, oh. So greasy, you should just shave all this.", "order": 48667, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 16, "scene": 32, "line": "Just check it.", "order": 48668, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 16, "scene": 32, "line": "You are clean. Okay' One thing a bedbug thrives on is heat and carbon dioxide [starts running in place and turning up the thermostat] I am going to generate myself into a human trap. [starts to take off clothes] When I jump into the bed, you are going to cover me with the sheet immediately. And then we'll see who's laughing. [dastardly laugh]", "order": 48669, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 16, "scene": 32, "line": "Alright.", "order": 48670, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 16, "scene": 32, "line": "Yeah. [jumps into bed] Cover me!", "order": 48671, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 16, "scene": 32, "line": "Is this really nessecary?", "order": 48672, "speaker": "Cathy Simms"},
  {"season": 8, "episode": 16, "scene": 32, "line": "He knows what he's doing.", "order": 48673, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 16, "scene": 32, "line": "Let the bedbugs bite!", "order": 48674, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 16, "scene": 32, "line": "Ugh, god, I feel so gross. I have to go take a shower.", "order": 48675, "speaker": "Cathy Simms"},
  {"season": 8, "episode": 16, "scene": 32, "line": "Alright, then I will catch you later' What do we do now?", "order": 48676, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 16, "scene": 32, "line": "We wait. [Shower starts] Come to papa.", "order": 48677, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 16, "scene": 32, "line": "Oh.", "order": 48678, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 16, "scene": 33, "line": "I don't know, Dwight, I think maybe you should check again.", "order": 48679, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 16, "scene": 33, "line": "Nope, I wasn't bitten.", "order": 48680, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 16, "scene": 33, "line": "Well, maybe it isn't warm enough in here.", "order": 48681, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 16, "scene": 33, "line": "Oh, it's plenty warm, and I was farting continuously under the sheet, creating a kind of greenhouse effect.", "order": 48682, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 16, "scene": 33, "line": "Come on.", "order": 48683, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 16, "scene": 33, "line": "If there were any in here, They would've imbedded themselves in me.", "order": 48684, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 16, "scene": 33, "line": "You know what? Maybe they just ate.", "order": 48685, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 16, "scene": 33, "line": "No, you're good. Clean bill of health! Besides I gotta get back downstairs. I left Packer alone with Nellie for way too long.", "order": 48686, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 16, "scene": 33, "line": "Okay, they're fine. They're adults.", "order": 48687, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 16, "scene": 33, "line": "No, that's the problem. Packer is trying to bed Nellie for the VP job unless I do it first.", "order": 48688, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 16, "scene": 33, "line": "Is that really how you want to get this job?", "order": 48689, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 16, "scene": 33, "line": "Such a chorus girl.", "order": 48690, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 16, "scene": 33, "line": "Okay, Dwight' Dwight' Dwight, Dwight Dwight!' uh'", "order": 48691, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 16, "scene": 33, "line": "[in a bathrobe] Is crazy gone?", "order": 48692, "speaker": "Cathy Simms"},
  {"season": 8, "episode": 16, "scene": 34, "line": "Oh!", "order": 48693, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 16, "scene": 34, "line": "They don't make these cords in boot cut anymore!", "order": 48694, "speaker": "Gabe Lewis"},
  {"season": 8, "episode": 16, "scene": 34, "line": "Euughh' Pathetic, huh? A real man swallows his vomit when a lady is present' Hey.", "order": 48695, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 16, "scene": 35, "line": "Yeah, one death by chocolate, one bananas foster' Okay, cool. Thanks' I know, I'm a pig, right?", "order": 48696, "speaker": "Cathy Simms"},
  {"season": 8, "episode": 16, "scene": 35, "line": "Hmm? No.", "order": 48697, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 16, "scene": 35, "line": "[giggles] Wait, this why I exercise like a fiend. Feel. Seriously.", "order": 48698, "speaker": "Cathy Simms"},
  {"season": 8, "episode": 16, "scene": 35, "line": "Okay alright. [gets up] I'm really sorry, uh, I just have to say it. I'm-I'm married. I'm very happily married.", "order": 48699, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 16, "scene": 35, "line": "Oh my God, what are you thinking?", "order": 48700, "speaker": "Cathy Simms"},
  {"season": 8, "episode": 16, "scene": 35, "line": "um' I mean-", "order": 48701, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 16, "scene": 35, "line": "I know that you're married, I sat at your wife's desk. How little do you think of me?", "order": 48702, "speaker": "Cathy Simms"},
  {"season": 8, "episode": 16, "scene": 35, "line": "I'm sorry, I feel like maybe I'm-I'm I misread things. Okay, let's just go back to watching.", "order": 48703, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 16, "scene": 35, "line": "Can you' without running to the other side of the room all night?", "order": 48704, "speaker": "Cathy Simms"},
  {"season": 8, "episode": 16, "scene": 35, "line": "Yes, I can. [Cathy laughs] uh, all right. I'm really sorry. I think we'll just' we'll just watch. You can watch, not watch, whatever you want to do. Take another shower, I don't care.", "order": 48705, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 16, "scene": 35, "line": "Okay. [Sits next to Jim.] I am so cold.", "order": 48706, "speaker": "Cathy Simms"},
  {"season": 8, "episode": 16, "scene": 35, "line": "[Jim Face]", "order": 48707, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 16, "scene": 36, "line": "Hey, Darryl. You okay?", "order": 48708, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 16, "scene": 36, "line": "She's got a boyfriend.", "order": 48709, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 16, "scene": 36, "line": "Play it cool, man. She'll come around.", "order": 48710, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 16, "scene": 36, "line": "No, you should go for it. I mean nothing would've happened with me and Jim if he didn't put himself out there.", "order": 48711, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 16, "scene": 36, "line": "Yeah, but' My friend Jim would tell you to play it cool.", "order": 48712, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 16, "scene": 36, "line": "My husband would tell you to go for it.", "order": 48713, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 16, "scene": 37, "line": "Hey.", "order": 48714, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 16, "scene": 37, "line": "Hey.", "order": 48715, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 16, "scene": 37, "line": "Hey.", "order": 48716, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 16, "scene": 37, "line": "Hey.", "order": 48717, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 16, "scene": 37, "line": "Hey, this is fun.", "order": 48718, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 16, "scene": 37, "line": "A lot of fun.", "order": 48719, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 16, "scene": 37, "line": "I know. Can I give you a compliment? I'm really impressed by how much you've grown. Since I met you, it's like night and day.", "order": 48720, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 16, "scene": 37, "line": "You should move down here with me.", "order": 48721, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 16, "scene": 37, "line": "Yeah?", "order": 48722, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 16, "scene": 37, "line": "We could be roommates.", "order": 48723, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 16, "scene": 37, "line": "Really?", "order": 48724, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 16, "scene": 37, "line": "We could get a dog. We could go to R-rated movies. And who knows, I mean, you're a guy, I'm a girl-", "order": 48725, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 16, "scene": 37, "line": "Yeah.", "order": 48726, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 16, "scene": 37, "line": "Maybe in six months-", "order": 48727, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 16, "scene": 37, "line": "[louder] Six months?' um, okay, I'm in love with Kelly.", "order": 48728, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 16, "scene": 38, "line": "Put your forehead near mine, and see if you can read my thoughts.", "order": 48729, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 16, "scene": 38, "line": "Okay' Seven, one, one, nine'", "order": 48730, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 16, "scene": 38, "line": "No, no, no, not numbers, no.", "order": 48731, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 16, "scene": 38, "line": "No, okay.", "order": 48732, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 16, "scene": 38, "line": "No.", "order": 48733, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 16, "scene": 38, "line": "Try again' Ugh, I'm still getting numbers! Seven, one, one' is anyone around here thinking the numbers seven one one? 'Cause please stop, okay?", "order": 48734, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 16, "scene": 38, "line": "Dwight, give me a key card to your room. Meet me in seven minutes for some one-on-one time.", "order": 48735, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 16, "scene": 38, "line": "[gasps]Wait, the numbers!", "order": 48736, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 16, "scene": 38, "line": "Mm-hmm.", "order": 48737, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 16, "scene": 38, "line": "oh'", "order": 48738, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 16, "scene": 38, "line": "mm-hmm'", "order": 48739, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 16, "scene": 38, "line": "There you go.", "order": 48740, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 16, "scene": 38, "line": "I will see you in seven-", "order": 48741, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 16, "scene": 38, "line": "Seven minutes. [Dwight and Nellie kiss]' Nellie, wait. Let me write my room number on the card. [chuckles]. [Dwight writes on the key card scratches it with a magnet and hands it back to Nellie]", "order": 48742, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 16, "scene": 38, "line": "[in voiceover] Win at all costs, don't respect women. These are the tenets I was brought up with, and they have served me well. But my ancestors never worked in corporate America, and before that, hunters. And before that, time travelers. And before that, me again. At least, that's how the legend goes. The point is they never had to worry about how they got ahead. They just had to put food on the table and not alter the past.", "order": 48743, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 16, "scene": 39, "line": "1434-967, paid.", "order": 48744, "speaker": "Angela Martin"},
  {"season": 8, "episode": 16, "scene": 39, "line": "It has been processed.", "order": 48745, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 16, "scene": 39, "line": "It has been delivered.", "order": 48746, "speaker": "Val Johnson"},
  {"season": 8, "episode": 16, "scene": 39, "line": "That's it, last one.", "order": 48747, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 16, "scene": 39, "line": "Andy this has been an amazing night. Epic.", "order": 48748, "speaker": "Kelly Kapoor"},
  {"season": 8, "episode": 16, "scene": 39, "line": "Cool-down fiesta begins right now. I got decaf coffee'", "order": 48749, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 16, "scene": 39, "line": "No way.", "order": 48750, "speaker": "Phyllis Vance"},
  {"season": 8, "episode": 16, "scene": 39, "line": "I got Romy and Michele's High School Reunion'", "order": 48751, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 16, "scene": 39, "line": "Hey. I just wanted to apologize. I mean, that was so weird. Can we just, like, never talk about it again?", "order": 48752, "speaker": "Val Johnson"},
  {"season": 8, "episode": 16, "scene": 39, "line": "Sure.", "order": 48753, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 16, "scene": 39, "line": "Okay, thanks.", "order": 48754, "speaker": "Val Johnson"},
  {"season": 8, "episode": 16, "scene": 39, "line": "Hey, just so you know, me and you' I don't think that's ridiculous' Dot, dot, dot' dot, dot.", "order": 48755, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 16, "scene": 40, "line": "All right. Now I think it's time for you to go.", "order": 48756, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 16, "scene": 40, "line": "What? Oh, Jim, I thought we talked about this. You're cool, right?", "order": 48757, "speaker": "Cathy Simms"},
  {"season": 8, "episode": 16, "scene": 40, "line": "Cathy, go.", "order": 48758, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 16, "scene": 40, "line": "[wearing face mask with spray chemicals in hand] Where's the bug?", "order": 48759, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 16, "scene": 40, "line": "Awesome.", "order": 48760, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 16, "scene": 40, "line": "[sprays the bed and Cathy] Stand back!", "order": 48761, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 16, "scene": 40, "line": "Aah! Stop, it, oh my god! That burns! what is that?!", "order": 48762, "speaker": "Cathy Simms"},
  {"season": 8, "episode": 16, "scene": 40, "line": "[continues spraying the bed] It's a compound of chemicals I pulled off the maid's cart!", "order": 48763, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 16, "scene": 40, "line": "[coughs and moans]", "order": 48764, "speaker": "Cathy Simms"},
  {"season": 8, "episode": 16, "scene": 40, "line": "Right there! [points to Cathy]", "order": 48765, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 16, "scene": 40, "line": "Oh stop it, stop it, stop it!", "order": 48766, "speaker": "Cathy Simms"},
  {"season": 8, "episode": 16, "scene": 40, "line": "[coughs] Oh, big one! Big one! Really big one!", "order": 48767, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 16, "scene": 40, "line": "I can't breathe!", "order": 48768, "speaker": "Cathy Simms"},
  {"season": 8, "episode": 16, "scene": 40, "line": "I think I saw it!", "order": 48769, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 16, "scene": 40, "line": "Stop it! [runs out the door]", "order": 48770, "speaker": "Cathy Simms"},
  {"season": 8, "episode": 16, "scene": 40, "line": "Nice job, I think you got 'em.", "order": 48771, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 16, "scene": 40, "line": "You can't stay here, this place is a biohazard. If I were you, I'd just bunk with Cathy.", "order": 48772, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 16, "scene": 40, "line": "[Jim face]", "order": 48773, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 16, "scene": 41, "line": "Second best Bananas Foster I've ever had.", "order": 48774, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 16, "scene": 41, "line": "Oh yeah? What's the first best?", "order": 48775, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 16, "scene": 41, "line": "[at the door, unsuccessfully trying to open it with the demagnetized key card] Dwight? [knocks] Hello? Dwight?", "order": 48776, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 16, "scene": 41, "line": "[whispers] Is that Nellie?", "order": 48777, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 16, "scene": 41, "line": "[whispers] Don't let-shh!", "order": 48778, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 16, "scene": 41, "line": "Are you in there? I can see the light on under your door. Hello? [knocks] [Dwight turns off the lights] Oh, look at that. The light went off, just as I said the light went on. Hello? [knocks] Dwight? [whispers] Dwight. [Dwight eats his Bananas Foster]", "order": 48779, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 17, "scene": 1, "line": "Today is the test launch day for the inaugural Sabre store. Brr brr brr BRR [imitating trumpet] and I, Dwight Schrute, am in charge of the entire operation. If I can prove myself today and the store is a hit with the media and Nellie sees this, the vice presidency is mine.", "order": 48780, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 17, "scene": 1, "line": "Are you holding this chair?", "order": 48781, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 17, "scene": 1, "line": "Yes.", "order": 48782, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 17, "scene": 1, "line": "cause I feel like I'm gonna fall off.", "order": 48783, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 17, "scene": 1, "line": "Yes. Yes.", "order": 48784, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 17, "scene": 1, "line": "I'm not wearing the right shoes for this.", "order": 48785, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 17, "scene": 1, "line": "We went over this, ok? Your tiny fingers make the best knots.", "order": 48786, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 17, "scene": 1, "line": "Hey Strangers. So stoked for the Sabre store opening.", "order": 48787, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 17, "scene": 2, "line": "Hey, my name's Tabitha. I'm camped out in front of the Sabre store so I can be first in line for the new Pyramid. Psst. It's me Erin. Dwight had me pretend to be a hipster to create hype, and it's working. There's already people camped out behind me.", "order": 48788, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 17, "scene": 3, "line": "Test launch day, people. Now, I would like to fill you in on a little secret about me to inspire you today. Now, I know you probably all think I'm this patrician goddess. But here's the truth. I was born in the little working class town of Basildon, and until the age of 32, [cockney accent] I talked like this, which was bloody horrendous, innit? I came from dirt, no lower than ' what's lower than dirt?", "order": 48789, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 17, "scene": 3, "line": "Loam, magma, mantle, outer core, inner core.", "order": 48790, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 17, "scene": 3, "line": "Yeah, thank you. Loam. Bloody loam, I came from. I hit rock bottom when I auditioned for the Spice Girls. I didn't even get a callback.", "order": 48791, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 17, "scene": 3, "line": "Which Spice Girl?", "order": 48792, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 17, "scene": 3, "line": "The black one. I never stood a chance.", "order": 48793, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 17, "scene": 3, "line": "Okay.", "order": 48794, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 17, "scene": 3, "line": "Now, think about my journey here today, and let it inspire your journey.", "order": 48795, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 17, "scene": 3, "line": "Okay. Nellie, thank you. Thank you so much.", "order": 48796, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 17, "scene": 3, "line": "Yep", "order": 48797, "speaker": "Todd Packer"},
  {"season": 8, "episode": 17, "scene": 3, "line": "Today is press day and press is gonna make or break this store. And for a tech company, press can only mean one thing ' bloggers. Dossier on bloggers. Bloggers are gross. Bloggers are obese. Bloggers have halitosis. You're gonna love 'em. Ryan is going to be the main event today. He is the pitchman who is going to give the feisty yet profound speech worthy of a world's fair. Ryan, you ready to do this?", "order": 48798, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 17, "scene": 3, "line": "When people see this presentation, they're gonna [bleep] in their pants.", "order": 48799, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 17, "scene": 3, "line": "Okay.", "order": 48800, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 17, "scene": 3, "line": "Come on, man.", "order": 48801, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 17, "scene": 3, "line": "Seriously disgusting.", "order": 48802, "speaker": "Cathy Simms"},
  {"season": 8, "episode": 17, "scene": 3, "line": "Cathy, you will be the hot girl who talks to bloggers.", "order": 48803, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 17, "scene": 3, "line": "Ugh. Kill me. That was my idea.", "order": 48804, "speaker": "Cathy Simms"},
  {"season": 8, "episode": 17, "scene": 3, "line": "Packer, you will be the sexual predator who has come to prey on the trendy teenage girls who are obsessed with the Pyramid.", "order": 48805, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 17, "scene": 4, "line": "Uh'", "order": 48806, "speaker": "Todd Packer"},
  {"season": 8, "episode": 17, "scene": 4, "line": "Uh' that is excellent.", "order": 48807, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 17, "scene": 4, "line": "I don't see what that gets us, but I'm a team player.", "order": 48808, "speaker": "Todd Packer"},
  {"season": 8, "episode": 17, "scene": 4, "line": "Perfect casting, right?", "order": 48809, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 17, "scene": 5, "line": "Schrute's out to get me. But I'm playing the long game. As soon as he messes up, I swoop in like a sexual predator.", "order": 48810, "speaker": "Todd Packer"},
  {"season": 8, "episode": 17, "scene": 6, "line": "I want to create a sense of wonder and enthusiasm as if, at the end of E.T., candy poured out of the screen. Do you understand? I wanna get goose pimples.", "order": 48811, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 17, "scene": 6, "line": "Speaking of pimples, let's release the BLOGGERS!", "order": 48812, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 17, "scene": 7, "line": "Morning, everyone.", "order": 48813, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 17, "scene": 7, "line": "Oh, Andy, guess what happened to me this morning?", "order": 48814, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 17, "scene": 7, "line": "Don't care. Tell me later.", "order": 48815, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 17, "scene": 7, "line": "Listen, it's important. You've gotta hear this.", "order": 48816, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 17, "scene": 7, "line": "What do you got?", "order": 48817, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 17, "scene": 7, "line": "Oh, my god!", "order": 48818, "speaker": "Kelly Kapoor"},
  {"season": 8, "episode": 17, "scene": 7, "line": "Do you have a black eye?", "order": 48819, "speaker": "Phyllis Vance"},
  {"season": 8, "episode": 17, "scene": 7, "line": "Yes, I do. Phyllis.", "order": 48820, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 17, "scene": 7, "line": "I woke up at 4 am by accident in time for the paper to be delivered. Guess what?", "order": 48821, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 17, "scene": 7, "line": "What?", "order": 48822, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 17, "scene": 7, "line": "It's not a kid on a bike. It's a man in a car.", "order": 48823, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 17, "scene": 7, "line": "Andy, who punched you?", "order": 48824, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 17, "scene": 7, "line": "Hey, I was on the can. What's this about a black guy in the office?", "order": 48825, "speaker": "Meredith Palmer"},
  {"season": 8, "episode": 17, "scene": 7, "line": "Black eye, Meredith.", "order": 48826, "speaker": "Angela Martin"},
  {"season": 8, "episode": 17, "scene": 7, "line": "Will someone please explain what's going on here? Since the interesting thing happened til now, so much time has passed, it's like my life is buffering.", "order": 48827, "speaker": "Kelly Kapoor"},
  {"season": 8, "episode": 17, "scene": 7, "line": "Here's what happened. Pam and I were arriving for the day. And there was a gang in the parking lot on bikes, on, on motorcycles. And they were just hassling Pam'", "order": 48828, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 17, "scene": 7, "line": "That's true.", "order": 48829, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 17, "scene": 7, "line": "They had, uh' weapons.", "order": 48830, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 17, "scene": 7, "line": "Weapons.", "order": 48831, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 17, "scene": 7, "line": "I just stepped in to talk some sense into them.", "order": 48832, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 17, "scene": 7, "line": "But these were not the kind of people who use their words.", "order": 48833, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 17, "scene": 7, "line": "Punches were going, and I ducked a few, landed a couple, and I was fighting them off. It was totally, like, like, senseless crime.", "order": 48834, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 17, "scene": 7, "line": "Thank goodness he was there.", "order": 48835, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 17, "scene": 7, "line": "Good job Andy.", "order": 48836, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 17, "scene": 7, "line": "Yeah [all murmuring]", "order": 48837, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 17, "scene": 7, "line": "I didn't do anything any of you wouldn't have done.", "order": 48838, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 17, "scene": 8, "line": "Open the gates! There's plenty for everyone. No need to panic. There's plenty for everyone!", "order": 48839, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 17, "scene": 8, "line": "Quit it.", "order": 48840, "speaker": "Man"},
  {"season": 8, "episode": 17, "scene": 8, "line": "There's plenty. Don't stampede. No need to stampede, sir.", "order": 48841, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 17, "scene": 8, "line": "I was ahead of you!", "order": 48842, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 17, "scene": 8, "line": "Okay, okay. Hey, hey. It's gonna be fine. It's gonna be fine. There's plenty of Pyramids.", "order": 48843, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 17, "scene": 8, "line": "Come on. I was in line before you.", "order": 48844, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 17, "scene": 9, "line": "So you're a blogger right?", "order": 48845, "speaker": "Cathy Simms"},
  {"season": 8, "episode": 17, "scene": 9, "line": "Yeah. Blogger.", "order": 48846, "speaker": "Blogger"},
  {"season": 8, "episode": 17, "scene": 9, "line": "God, stay away. Oh, I always get in trouble around bloggers. I'm trying to be a good girl for once.", "order": 48847, "speaker": "Cathy Simms"},
  {"season": 8, "episode": 17, "scene": 10, "line": "Uh yeah. I already bought my Pyramid, but I don't want to leave yet. I haven't had so much fun since seeing' zoo-Ey Desh-channel at the Couch-arilla music festival. So fun.", "order": 48848, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 17, "scene": 11, "line": "How can we feel safe knowing that there are gangs here? We should call the police right now!", "order": 48849, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 17, "scene": 11, "line": "No.", "order": 48850, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 17, "scene": 11, "line": "Yes.", "order": 48851, "speaker": "Angela Martin"},
  {"season": 8, "episode": 17, "scene": 11, "line": "No, no, no, no, no, we don't need to call the police. They'll just ask everybody questions, get up in everyone's business, right, Pam?", "order": 48852, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 17, "scene": 11, "line": "Police are a hassle. We settled this on the street.", "order": 48853, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 17, "scene": 11, "line": "And my eye will heal. But if the police come, then we will forever stain our neighborhood as a troubled area.", "order": 48854, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 17, "scene": 11, "line": "Why would you care what the police think of our neighborhood?", "order": 48855, "speaker": "Angela Martin"},
  {"season": 8, "episode": 17, "scene": 11, "line": "Because I have neighborhood Pride. 1-8-5-0-5.", "order": 48856, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 17, "scene": 11, "line": "1-8-5-0-5.", "order": 48857, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 17, "scene": 11, "line": "Guys, guys. That's so vague. You gotta do the zip plus four. 1-8-5-0-5 dash 7-4-2-7.", "order": 48858, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 17, "scene": 11, "line": "Look, I don't feel safe. I think we should call the police.", "order": 48859, "speaker": "Phyllis Vance"},
  {"season": 8, "episode": 17, "scene": 11, "line": "Yes.", "order": 48860, "speaker": "Angela Martin"},
  {"season": 8, "episode": 17, "scene": 11, "line": "Exactly. We need to feel safe, which is why' Toby is giving us self defense training.", "order": 48861, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 17, "scene": 11, "line": "Me?", "order": 48862, "speaker": "Toby Flenderson"},
  {"season": 8, "episode": 17, "scene": 11, "line": "Right Toby? Will you teach us self defense?", "order": 48863, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 17, "scene": 11, "line": "Yeah, um... I can't believe you remembered. I do self-defense. Um, I'll go put on my cup.", "order": 48864, "speaker": "Toby Flenderson"},
  {"season": 8, "episode": 17, "scene": 11, "line": "Great.", "order": 48865, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 17, "scene": 12, "line": "Yes!", "order": 48866, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 17, "scene": 12, "line": "Okay.", "order": 48867, "speaker": "Blogger"},
  {"season": 8, "episode": 17, "scene": 12, "line": "I wasn't really sure which one of you is Chuck.", "order": 48868, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 17, "scene": 12, "line": "[on phone] Yeah, no, it seems to be going great. Andy got beat up by a fifth grade girl?", "order": 48869, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 17, "scene": 12, "line": "Look at that guy. He's got his Sabre phone on, and he's not even using it.", "order": 48870, "speaker": "Blogger"},
  {"season": 8, "episode": 17, "scene": 12, "line": "This is a perfect photo for my Daily Fail blog.", "order": 48871, "speaker": "Blogger 2"},
  {"season": 8, "episode": 17, "scene": 12, "line": "Uh, I - I gotta go. Okay.", "order": 48872, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 17, "scene": 12, "line": "Dwight, what is a fail? That sounds bad.", "order": 48873, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 17, "scene": 12, "line": "Oh, no, no, no. It's good. It's really -- on the Internet, it's a really -- that's a really good, good, thing.", "order": 48874, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 17, "scene": 13, "line": "Are you trying to sabotage this entire event?", "order": 48875, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 17, "scene": 13, "line": "I'm very sorry.", "order": 48876, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 17, "scene": 13, "line": "We gave you an Arrowhead for free for the day. How hard would it have been to do this, hmmm? 'Hello. Hi sweetie. It's Jim. I'm calling you from the new Arrowhead, which is why my voice is crystal clear. And my hand will never get tired because of the ergonomic shape.'", "order": 48877, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 17, "scene": 13, "line": "I'm really sorry. Is there anything I can do, maybe pretend to be Chuck?", "order": 48878, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 17, "scene": 13, "line": "You could have pretended to be Chuck. I begged you to pretend to be Chuck, but you chose to be yourself, and you can no longer be Chuck! Surrender the tripack. You know what you have to do.", "order": 48879, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 17, "scene": 14, "line": "Point it towards the store, idiot!", "order": 48880, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 17, "scene": 15, "line": "[laughs] You know what I mean? One of these buttons is -- damn it. Take over.", "order": 48881, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 17, "scene": 16, "line": "Oh hey, Hey, hey, you guys, you must be lost. Listen. Excuse me, sir. Yeah, the fountain where you can feed the pigeons is out behind the bank. Tell your great-grandson to bring his kid by. Okay. So long. Here we go. Erin! Psst! Come on! The elderly suck the life out of the young. Get them out of here!", "order": 48882, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 17, "scene": 16, "line": "We are closed! Come on.", "order": 48883, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 17, "scene": 17, "line": "Self-defense is not some fun boxing match, okay? This is about escaping with your life. So... strike, scream, and run. All right? Let's try it.", "order": 48884, "speaker": "Toby Flenderson"},
  {"season": 8, "episode": 17, "scene": 17, "line": "[smacks Meredith's head] [screams] [runs out]", "order": 48885, "speaker": "Creed Bratton"},
  {"season": 8, "episode": 17, "scene": 17, "line": "Ow.", "order": 48886, "speaker": "Meredith Palmer"},
  {"season": 8, "episode": 17, "scene": 17, "line": "That may have been my fault.", "order": 48887, "speaker": "Toby Flenderson"},
  {"season": 8, "episode": 17, "scene": 17, "line": "What the hell, Toby?", "order": 48888, "speaker": "Meredith Palmer"},
  {"season": 8, "episode": 17, "scene": 17, "line": "Okay, look, in a real crisis situation, you're not gonna have to time to think, okay? So just remember, I-A-A-T-G. 'It's all about the groin.'", "order": 48889, "speaker": "Toby Flenderson"},
  {"season": 8, "episode": 17, "scene": 17, "line": "What if you're being attacked by a ' smallish man who happens to not have a groin?", "order": 48890, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 17, "scene": 17, "line": "I don't think that's very common.", "order": 48891, "speaker": "Toby Flenderson"},
  {"season": 8, "episode": 17, "scene": 17, "line": "What if you're being attacked by a 4'11' man who is penisless?", "order": 48892, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 17, "scene": 17, "line": "Why are you fixated on this hypothetical transgendered attacker?", "order": 48893, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 17, "scene": 17, "line": "Why don't we start with the basics? Show us how to defend ourselves against a baby, and then like, a fifth grade girl, and then, you know, if we have time, on up to a scary man.", "order": 48894, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 17, "scene": 17, "line": "Well, the most common scenario is a larger man attacking a smaller female.", "order": 48895, "speaker": "Toby Flenderson"},
  {"season": 8, "episode": 17, "scene": 17, "line": "So in that scenario, what if the victim sucker-punches the attacker in the face? What can the attacker then do to better protect himself?", "order": 48896, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 17, "scene": 17, "line": "It's interesting that you're drawn to the point of view of the attacker. You would like the turn of the table. Okay, the latest Chad Flenderman novel... [everyone groans] written from the point of view of his nemesis, Dr. Lucifer Wu.", "order": 48897, "speaker": "Toby Flenderson"},
  {"season": 8, "episode": 17, "scene": 17, "line": "Can I please leave? I have a rape flute.", "order": 48898, "speaker": "Angela Martin"},
  {"season": 8, "episode": 17, "scene": 17, "line": "All right, well, let's try one simple technique together. Okay, why doesn't everyone stand? Okay, so... you're being attacked. You've got your hands up. Simple palm strike to the chin. Up to the chin. One, two.", "order": 48899, "speaker": "Toby Flenderson"},
  {"season": 8, "episode": 17, "scene": 17, "line": "One, two.", "order": 48900, "speaker": "Everyone"},
  {"season": 8, "episode": 17, "scene": 17, "line": "[softly] Take that, kid.", "order": 48901, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 17, "scene": 18, "line": "Cathy, I would like to introduce you to Fatty Gruesome. He is a freelancer for Wired magazine.", "order": 48902, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 17, "scene": 18, "line": "Patty Grossman. I'm a woman.", "order": 48903, "speaker": "Lady Blogger"},
  {"season": 8, "episode": 17, "scene": 18, "line": "But you still work for Wired, right?", "order": 48904, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 17, "scene": 18, "line": "Yes.", "order": 48905, "speaker": "Patty"},
  {"season": 8, "episode": 17, "scene": 18, "line": "Good! Okay. Flirt away.", "order": 48906, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 17, "scene": 19, "line": "Sabre. It's time to come home.", "order": 48907, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 17, "scene": 19, "line": "Yeah. Yeah, I mean, I think ...it seemed like you were a little nervous.", "order": 48908, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 17, "scene": 19, "line": "Yeah, no [bleep], Sherlock! Can somebody please tell me something encouraging about this presentation before I go out there in front of a million people and do it?", "order": 48909, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 17, "scene": 19, "line": "Okay. I know, I know, champ. Calm down, just listen.", "order": 48910, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 17, "scene": 19, "line": "You know what?", "order": 48911, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 17, "scene": 19, "line": "You just need to realize that so much rides on this. You have no idea.", "order": 48912, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 17, "scene": 19, "line": "Dwight.", "order": 48913, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 17, "scene": 19, "line": "I'm trying to make him feel important.", "order": 48914, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 17, "scene": 19, "line": "God. I wish Kelly were here. She always knew what to say.", "order": 48915, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 17, "scene": 19, "line": "Um... [imitating Kelly] Oh, Ryan, you're so smart. You're smarter than Mark Zuckerberg and those Google guys all combined. Hee hee hee.", "order": 48916, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 17, "scene": 19, "line": "You're so ignorant. You barely know what you're talking about. That is so ridiculous. You really need to read a couple books.", "order": 48917, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 17, "scene": 19, "line": "What's a book? [giggles]", "order": 48918, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 17, "scene": 19, "line": "On my God. You're so embarrassing. My mom would say the best stuff, though.", "order": 48919, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 17, "scene": 19, "line": "[ahem] You can... [slightly effeminate] You can do it Ryan.", "order": 48920, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 17, "scene": 19, "line": "And you know that I'm capable of this.", "order": 48921, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 17, "scene": 19, "line": "You're the only one who can do it, s-sweetie.", "order": 48922, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 17, "scene": 19, "line": "What did you think of the presentation?", "order": 48923, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 17, "scene": 19, "line": "I thought it was great, sweetie. I would just fix that one --", "order": 48924, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 17, "scene": 19, "line": "Oh! 'Fix' means you hate it! I knew it! I need something to drink!", "order": 48925, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 17, "scene": 19, "line": "Jim, get him a water.", "order": 48926, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 17, "scene": 19, "line": "No, not a water. A sports drink. I hate everything in that fridge. Not red! Get me something yellow or green from a nearby store. Not red!", "order": 48927, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 17, "scene": 19, "line": "Why are you just standing there? Go to a nearby store and get him a yellow or green sports drink!", "order": 48928, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 17, "scene": 20, "line": "Now, if your attacker is willing to defile a corpse, you better stop playing dead right away and just make it known that you're alive.", "order": 48929, "speaker": "Toby Flenderson"},
  {"season": 8, "episode": 17, "scene": 20, "line": "Forgive me for interrupting. I believe my daughter had an altercation with somebody here, some fancy gentleman with a squeaky voice?", "order": 48930, "speaker": "Lady"},
  {"season": 8, "episode": 17, "scene": 20, "line": "[deep voice] I think you guys might have the wrong Office.", "order": 48931, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 17, "scene": 20, "line": "That's him... the guy I hit.", "order": 48932, "speaker": "Girl"},
  {"season": 8, "episode": 17, "scene": 20, "line": "What?", "order": 48933, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 17, "scene": 20, "line": "You've gotta be kidding me.", "order": 48934, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 17, "scene": 20, "line": "Poor Andy! First you got beat up by a gang, and now she kicks your ass?", "order": 48935, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 17, "scene": 20, "line": "No, Kevin -- [sighs]", "order": 48936, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 17, "scene": 20, "line": "What about the lady you hit with the pine cone?", "order": 48937, "speaker": "Lady"},
  {"season": 8, "episode": 17, "scene": 20, "line": "There. That chubby one.", "order": 48938, "speaker": "Girl"},
  {"season": 8, "episode": 17, "scene": 20, "line": "I just had a baby.", "order": 48939, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 17, "scene": 20, "line": "Yesterday?", "order": 48940, "speaker": "Girl"},
  {"season": 8, "episode": 17, "scene": 20, "line": "Wow.", "order": 48941, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 17, "scene": 20, "line": "Apologize.", "order": 48942, "speaker": "Lady"},
  {"season": 8, "episode": 17, "scene": 20, "line": "Sorry I kicked your ass in front of your 'thin' girlfriend.", "order": 48943, "speaker": "Girl"},
  {"season": 8, "episode": 17, "scene": 20, "line": "How 'bout we wait til next year after you have your kid?", "order": 48944, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 17, "scene": 20, "line": "You know what? Tiffy's going to college.", "order": 48945, "speaker": "Lady"},
  {"season": 8, "episode": 17, "scene": 20, "line": "[deep voice] Listen, I don't know what you guys are talking about, but I guess I'll just accept your apology so we can get on with our day.", "order": 48946, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 17, "scene": 20, "line": "God bless. Friend of mine uses your paper. You do good work. Bye bye.", "order": 48947, "speaker": "Lady"},
  {"season": 8, "episode": 17, "scene": 20, "line": "Bye.", "order": 48948, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 17, "scene": 20, "line": "So, Toby, I think we should do a different self-defense seminar -- 'How to protect ourselves against tiny little girls.'", "order": 48949, "speaker": "Kelly Kapoor"},
  {"season": 8, "episode": 17, "scene": 20, "line": "There's no shame in getting beaten up by a girl. My ex-wife used to demolish me.", "order": 48950, "speaker": "Toby Flenderson"},
  {"season": 8, "episode": 17, "scene": 20, "line": "No, there is shame in it, okay? We have to draw the line somewhere.", "order": 48951, "speaker": "Kelly Kapoor"},
  {"season": 8, "episode": 17, "scene": 20, "line": "Oh my God, I think I see the imprint of a ring pop.", "order": 48952, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 17, "scene": 20, "line": "Oh... [Kelly laughing]", "order": 48953, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 17, "scene": 21, "line": "Have you seen Erin?", "order": 48954, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 17, "scene": 21, "line": "I'm on break.", "order": 48955, "speaker": "Stanley Hudson"},
  {"season": 8, "episode": 17, "scene": 21, "line": "Oh God... Hey no! Where do you think you're going? You've gotta stay for the big presentation we've got this young wiz kid -- Ryan. He's like an even more handsome Bill Gates.", "order": 48956, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 17, "scene": 21, "line": "When's the presentation?", "order": 48957, "speaker": "Blogger"},
  {"season": 8, "episode": 17, "scene": 21, "line": "It's moments away. Just stay here!", "order": 48958, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 17, "scene": 22, "line": "[on phone] Hey Uncle Lucas, it's your nephew Ryan. Honestly, I could use a prescription for ritalin right now. Well, I know you did one for Aunt Carol. Oh, so it's different because it's your wife? Well, that doesn't make any sense to me.", "order": 48959, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 17, "scene": 22, "line": "How you doing?", "order": 48960, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 17, "scene": 22, "line": "Don't talk to me right now. I'm sorry. I- I know you're my boss, but seriously, you need to get the hell out of my face. What I don't understand is... [voice fades]", "order": 48961, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 17, "scene": 22, "line": "[to Dwight] Your little man is unraveling. Now go and fix it.", "order": 48962, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 17, "scene": 23, "line": "Sorry about kicking you out. It's just, we don't want our brand associated with death.", "order": 48963, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 17, "scene": 23, "line": "It's okay. I'll go to the Costco and search for handsome men.", "order": 48964, "speaker": "Old Lady"},
  {"season": 8, "episode": 17, "scene": 23, "line": "You're not married yet?", "order": 48965, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 17, "scene": 23, "line": "[laughs] Oh, I was. My husband was my best friend. He passed away.", "order": 48966, "speaker": "Old Lady"},
  {"season": 8, "episode": 17, "scene": 23, "line": "My best friend was my boss, Andy. We dated for a while, but since then, he rejected me, and we're not really friends.", "order": 48967, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 17, "scene": 23, "line": "Someone rejected you? With that body and those bazongas? Forget him!", "order": 48968, "speaker": "Old Lady"},
  {"season": 8, "episode": 17, "scene": 23, "line": "Yeah! Forget him! And you should forget your husband.", "order": 48969, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 17, "scene": 23, "line": "Well...", "order": 48970, "speaker": "Old Lady"},
  {"season": 8, "episode": 17, "scene": 24, "line": "How long has he been in the bathroom?", "order": 48971, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 17, "scene": 24, "line": "About ten minutes.", "order": 48972, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 17, "scene": 24, "line": "Jeez! What's he doing in there? [cell phone vibrates]", "order": 48973, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 17, "scene": 24, "line": "Oops, that's my phone. Am I allowed to answer it or are you gonna freak out?", "order": 48974, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 17, "scene": 24, "line": "Are there any bloggers around?", "order": 48975, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 17, "scene": 24, "line": "It's Ryan. 'I'm sorry. I lied. I'm not in the bathroom. I can't do it. I need to see my mom. I'm going home.'", "order": 48976, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 17, "scene": 24, "line": "What is the delay here? Where's Ryan? Why is he not here?", "order": 48977, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 17, "scene": 24, "line": "I had to send him home. As brilliant and creative as he is, he is nothing... compared to this guy!", "order": 48978, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 17, "scene": 24, "line": "Uh...", "order": 48979, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 17, "scene": 24, "line": "What?", "order": 48980, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 17, "scene": 24, "line": "That's right. Will you just give us a second?", "order": 48981, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 17, "scene": 24, "line": "You are gonna bloody ruin it. You're gonna bloody ruin it because you're a no-good half-assed cock-eyed...", "order": 48982, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 17, "scene": 24, "line": "okay.", "order": 48983, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 17, "scene": 24, "line": "That's....", "order": 48984, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 17, "scene": 24, "line": "Jim --", "order": 48985, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 17, "scene": 24, "line": "I'm not doing the Presentation.", "order": 48986, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 17, "scene": 24, "line": "Look at me. Look at me! Look... at... this... face. This is not the face of a performer. This is the face of a scary apparition you see before you die. I'm telling you... if you don't do this, [whispers] I don't stand a chance. Please, Jim.", "order": 48987, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 17, "scene": 24, "line": "Okay, I'll do it.", "order": 48988, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 17, "scene": 24, "line": "Oh, my God. O... Kay! Go get into Ryan's costume and check out his notes.", "order": 48989, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 17, "scene": 24, "line": "A costume?", "order": 48990, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 17, "scene": 24, "line": "Of course there's a costume! [laughs] Oh, this is gonna be great. There's nothing like some last-minutes changes to really energize a presentation.", "order": 48991, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 17, "scene": 25, "line": "Did you pass out in there? What is taking so long?", "order": 48992, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 17, "scene": 25, "line": "I've been in here for 20 seconds.", "order": 48993, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 17, "scene": 25, "line": "Hurry up. Let me in. I wanna watch you get dressed. Did you find the eyeliner?", "order": 48994, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 17, "scene": 25, "line": "I'm not wearing eyeliner.", "order": 48995, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 17, "scene": 25, "line": "You are wearing eyeliner, Jim.", "order": 48996, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 17, "scene": 26, "line": "Time. Space. Gender. [Dwight mouthing words] There are no rules anymore. All boundaries are breaking down in the wake of the infinite future. The only thing that -- the only thing that remain -- the only thing that remains are the things that have stood the test of time -- love, values, and of course, the pyramids -- the strongest shape ever constructed, a shape that fits all other shapes inside of it. [softly] No, that's --", "order": 48997, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 17, "scene": 26, "line": "It's true.", "order": 48998, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 17, "scene": 26, "line": "This... is the future, because... This is the past. I've been through a lot of issues in my life. I've seen drug addiction -- unemployment. I've been in a relationship that tore my heart apart, without ever being able to accept that love drove the pain. [scattered applause]", "order": 48999, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 17, "scene": 26, "line": "Yep.", "order": 49000, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 17, "scene": 26, "line": "When I was ten years old, my parents took me to Disney world. I cried the whole time. I was not able to comprehend the beauty that was before me. I just wanted... to go home. This is what the Pyramid will do for you. It is the bridge to the world. It has a usb port. Wireless... will be available in 2013. You can play Anything from Chuck to Cars 2. With the Pyramid, you have the connection to everything -- in time... and space.", "order": 49001, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 17, "scene": 26, "line": "ahhhhhh", "order": 49002, "speaker": "Crowd"},
  {"season": 8, "episode": 17, "scene": 26, "line": "Psst.", "order": 49003, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 17, "scene": 26, "line": "Oh.", "order": 49004, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 17, "scene": 26, "line": "[image on Pyramid] Sabre... It's time... to come home.", "order": 49005, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 17, "scene": 26, "line": "[applause]", "order": 49006, "speaker": "Crowd"},
  {"season": 8, "episode": 17, "scene": 26, "line": "All right. Thank you so much. Wow. Wow! And good night! [applause continues]", "order": 49007, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 17, "scene": 26, "line": "Yeah. Thank you. Thank you.", "order": 49008, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 17, "scene": 27, "line": "Okay, this isn't over. Let's stay focused, okay? We made fun of Andy earlier for getting beat up by a little girl, but... little things can be dangerous.", "order": 49009, "speaker": "Toby Flenderson"},
  {"season": 8, "episode": 17, "scene": 27, "line": "Whether it's a gremlin or chucky the doll. The key is to throw it in something. Like a fireplace, or a tub of electricity.", "order": 49010, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 17, "scene": 27, "line": "Okay. Good point...", "order": 49011, "speaker": "Toby Flenderson"},
  {"season": 8, "episode": 17, "scene": 27, "line": "Good point? What is a tub of electricity?", "order": 49012, "speaker": "Angela Martin"},
  {"season": 8, "episode": 17, "scene": 27, "line": "I don't... I don't...", "order": 49013, "speaker": "Toby Flenderson"},
  {"season": 8, "episode": 17, "scene": 27, "line": "With all due respect we know what we're defending against: a twelve year old female bully.", "order": 49014, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 17, "scene": 27, "line": "I was a twelve year old bully.", "order": 49015, "speaker": "Kelly Kapoor"},
  {"season": 8, "episode": 17, "scene": 27, "line": "Great! I think Kelly should attack Toby.", "order": 49016, "speaker": "Angela Martin"},
  {"season": 8, "episode": 17, "scene": 27, "line": "Yeah.", "order": 49017, "speaker": "Others"},
  {"season": 8, "episode": 17, "scene": 27, "line": "Yeah, that's not a bad idea, actually, because I have had a lot of pent up aggression.", "order": 49018, "speaker": "Kelly Kapoor"},
  {"season": 8, "episode": 17, "scene": 27, "line": "Thanks.", "order": 49019, "speaker": "Angela Martin"},
  {"season": 8, "episode": 17, "scene": 27, "line": "Good. Let's go.", "order": 49020, "speaker": "Kelly Kapoor"},
  {"season": 8, "episode": 17, "scene": 27, "line": "I don't know if this is gonna help...Uh...", "order": 49021, "speaker": "Toby Flenderson"},
  {"season": 8, "episode": 17, "scene": 27, "line": "Let's go Kelly.", "order": 49022, "speaker": "Angela Martin"},
  {"season": 8, "episode": 17, "scene": 27, "line": "We should stay to maybe some more traditional models...", "order": 49023, "speaker": "Toby Flenderson"},
  {"season": 8, "episode": 17, "scene": 27, "line": "You think you're so pretty! Well you're not gonna be so pretty come Prom time!", "order": 49024, "speaker": "Kelly Kapoor"},
  {"season": 8, "episode": 17, "scene": 27, "line": "Okay, this is what's called pre-violent posturing.", "order": 49025, "speaker": "Toby Flenderson"},
  {"season": 8, "episode": 17, "scene": 27, "line": "Take that! Not so pre-violent anymore!", "order": 49026, "speaker": "Kelly Kapoor"},
  {"season": 8, "episode": 17, "scene": 27, "line": "Okay, I'm at what's called 'the decision point.'", "order": 49027, "speaker": "Toby Flenderson"},
  {"season": 8, "episode": 17, "scene": 27, "line": "Hey, hey, hey. Kelly Kelly, Kelly... come on.... Ow! God! My good eye!", "order": 49028, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 17, "scene": 27, "line": "[laughs] Oh boy. Oh no, I'm not laughing at you. I'm laughing at, um, something that Cece did on the playground earlier -- yesterday. This morning.", "order": 49029, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 17, "scene": 27, "line": "You know why I got hit by girls? Because I stood up for others. Pam and for Toby. I stepped in and I didn't care that I was standing up to girls. You may wanna ask yourselves, 'Where were you when the girls came?'", "order": 49030, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 17, "scene": 28, "line": "Tough day. Yes. But I feel good. I put the office in their place, took a bunch of pain killers, drank a half a bottle of wine, took my pants off. I just feel good!", "order": 49031, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 17, "scene": 29, "line": "Okay, okay, I will be the first to admit it. We could have integrated more Chuck into the Presentation.", "order": 49032, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 17, "scene": 29, "line": "Dwight. You're the vice president.", "order": 49033, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 17, "scene": 29, "line": "Ha, ha, ha, ha! Yeah!", "order": 49034, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 17, "scene": 29, "line": "[squealing]", "order": 49035, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 17, "scene": 29, "line": "Okay! Come on!", "order": 49036, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 17, "scene": 29, "line": "aagh!", "order": 49037, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 17, "scene": 29, "line": "Hah! Yah! [kicking and punching the air] Boom!", "order": 49038, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 18, "scene": 1, "line": "[exiting office] Everyone stop what you're doing, I have terrible news. Dwight is no longer with us.", "order": 49039, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 18, "scene": 1, "line": "[gasps] What?! Why is that?", "order": 49040, "speaker": "Everyone"},
  {"season": 8, "episode": 18, "scene": 1, "line": "He's gone, damn it! He's been promoted to VP of Sabre Retail and he's staying in Florida forever.", "order": 49041, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 18, "scene": 1, "line": "So, he's alive.", "order": 49042, "speaker": "Angela Martin"},
  {"season": 8, "episode": 18, "scene": 1, "line": "Yeah. That was him on the phone. He sounds wonderful.", "order": 49043, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 18, "scene": 1, "line": "Well, the way you said it made it sound like he was dead.", "order": 49044, "speaker": "Angela Martin"},
  {"season": 8, "episode": 18, "scene": 1, "line": "How could I have been more clear? He had a massive stroke [slight pause] of good fortune and he is now in a better place.", "order": 49045, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 18, "scene": 1, "line": "If Dwight's not coming back, does that mean we can open his treasure?", "order": 49046, "speaker": "Phyllis Vance"},
  {"season": 8, "episode": 18, "scene": 1, "line": "You guys, we've gone over this, there is no treasure. [Erin retrieves the 'treasure box' and slams it down on Dwight's desk]", "order": 49047, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 18, "scene": 2, "line": "When the team left for Tallahassee, Dwight told everyone not to touch his treasure. [cut to Dwight holding treasure chest and telling the office 'Don't touch my treasure. Ok, you understand?'] Obviously he wants us to obsess about it. There's nothing in there. [has realization] Which is obviously what he would want us to think, making it the perfect place to hide a treasure. Oh god, I'm Wallace Shawn in The Princess Bride.", "order": 49048, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 18, "scene": 3, "line": "I think that Dwight wanted us to realize that this conversation among friends is the true treasure. [everyone disagrees]", "order": 49049, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 18, "scene": 3, "line": "I am dying to know what's in there.", "order": 49050, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 18, "scene": 3, "line": "Yeah, I know, Oscar we all are but nobody's gonna open it. You'd have to be insane. [everyone turns to look at Creed]", "order": 49051, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 18, "scene": 3, "line": "Hi, hello.", "order": 49052, "speaker": "Creed Bratton"},
  {"season": 8, "episode": 18, "scene": 4, "line": "[to Creed standing in front of the 'treasure box'] Now, carefully... open the box.", "order": 49053, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 18, "scene": 4, "line": "[opens box, pulls out and displays contents] It's a photo of all of us.", "order": 49054, "speaker": "Creed Bratton"},
  {"season": 8, "episode": 18, "scene": 4, "line": "Aw, that's so sweet! [dart fires out of box and lodges in ceiling]", "order": 49055, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 18, "scene": 5, "line": "[feigning surprise] A dart? Are you kidding me? Who would put a poison dart, well, I mean, I don't know that it's poison. I mean, I just have to imagine. God, I'm glad he's OK though. Kinda sounds like he deserved it, opening another man's treasure and all. Wow!", "order": 49056, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 18, "scene": 6, "line": "[on a golf course] Well, mister ball, it's been a pleasure. Now, give my regards to hell. Da! [hits ball]", "order": 49057, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 18, "scene": 6, "line": "Oh, all right! Well swung my VIP VP.", "order": 49058, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 18, "scene": 6, "line": "I am loving the chemistry between you two.", "order": 49059, "speaker": "Robert California"},
  {"season": 8, "episode": 18, "scene": 6, "line": "I am so happy Dwight is gonna be working alongside me. We are a regular Archibald and his man George.", "order": 49060, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 18, "scene": 6, "line": "I bet. I'm excited.", "order": 49061, "speaker": "Robert California"},
  {"season": 8, "episode": 18, "scene": 6, "line": "Trick... there's no such thing. It's not even a real English duo.", "order": 49062, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 18, "scene": 6, "line": "Ah!", "order": 49063, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 18, "scene": 6, "line": "I just made you look like the goat of Dover. And that doesn't exist either.", "order": 49064, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 18, "scene": 6, "line": "[to Robert] You think you're excited? You should feel my nipples. [Robert gives a fake laugh]", "order": 49065, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 18, "scene": 6, "line": "Oh, that reminds me. [reaching into golf cart] Little something from all of us. [hand Dwight a small wrapped gift]", "order": 49066, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 18, "scene": 6, "line": "[sarcastically] Oh my gosh, thank you Jim, that's so thoughtful. [sets gift on golf tee] Four! [smashes gift] Oh yeah!", "order": 49067, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 18, "scene": 6, "line": "He doesn't even care.", "order": 49068, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 18, "scene": 6, "line": "Don't even care!", "order": 49069, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 18, "scene": 7, "line": "This is the last time I'll ever see Dwight. It's a weird feeling, it's, um, what's the word? It's not, it's not bittersweet. It's uh... sweet. Yeah.", "order": 49070, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 18, "scene": 8, "line": "[entering Andy's office] Hey, man. Selling cookies for Jada. Want the same as last year?", "order": 49071, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 18, "scene": 8, "line": "Would if I could. And I can so I will. Put me down for one box. Don't care what it is, dealer's choice.", "order": 49072, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 18, "scene": 8, "line": "I'll put you down for shortbreads.", "order": 49073, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 18, "scene": 8, "line": "Damn it.", "order": 49074, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 18, "scene": 8, "line": "[entering Andy's office] Knock, knock. Hey, Sasha's first year in the troops, so I'm selling cookies for her. Anyway, would you like some?", "order": 49075, "speaker": "Toby Flenderson"},
  {"season": 8, "episode": 18, "scene": 8, "line": "No. No.", "order": 49076, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 18, "scene": 8, "line": "What?", "order": 49077, "speaker": "Toby Flenderson"},
  {"season": 8, "episode": 18, "scene": 8, "line": "I been selling here for five years. This is my spot.", "order": 49078, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 18, "scene": 8, "line": "You can't claim territories. This is the only place I interact with people. Can't you sell at your church or barber shop? [Darryl looks hurt] Or chess club, or?", "order": 49079, "speaker": "Toby Flenderson"},
  {"season": 8, "episode": 18, "scene": 8, "line": "You know what? It's your first time, let's split the office. You can have sales, the annex, Creed, and I don't know I'll just take, uh, accounting.", "order": 49080, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 18, "scene": 8, "line": "That's it? Yeah, well, ok. Thanks Darryl.", "order": 49081, "speaker": "Toby Flenderson"},
  {"season": 8, "episode": 18, "scene": 8, "line": "OK, great.", "order": 49082, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 18, "scene": 9, "line": "[exits Andy's office smiling] All I need is Kevin. Dude buys more cookies than everyone combined and then some. [Kevin waves at him] When I first started selling cookies he was a relatively thin man. Not a thin man, mind you. Relatively thin.", "order": 49083, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 18, "scene": 10, "line": "[entering a home carrying groceries] Hellosi, I'm home, babaloo.", "order": 49084, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 18, "scene": 10, "line": "[rises from chair] Oh, here let me help.", "order": 49085, "speaker": "Elderly Woman"},
  {"season": 8, "episode": 18, "scene": 10, "line": "[spilling groceries] I got it. It was so busy at the store today. Really good cheese samples. I had, like, a hundred.", "order": 49086, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 18, "scene": 11, "line": "Irene hired me as her live-in helper. We met at the store launch. I told her I was moving here and I needed a place and it just sort of made sense. I basically do everything for her. [Erin randomly adds an assortment of pills to a weekly pill box] I run errands. I do chores around the house. I cook and clean. Honestly, I don't know how she survived without me.", "order": 49087, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 18, "scene": 12, "line": "When can I introduce you to my grandson? He's a wonderful swimmer. Shallow end, deep end. He does it all.", "order": 49088, "speaker": "Irene"},
  {"season": 8, "episode": 18, "scene": 12, "line": "Well, today might be kind of tough, Irene. I have to talk to my old boss, Andy, and tell him I'm staying in Florida. [hands Irene a mug]", "order": 49089, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 18, "scene": 12, "line": "[takes mug] Thank you. [takes a sip] Oh, what kind of tea is this?", "order": 49090, "speaker": "Irene"},
  {"season": 8, "episode": 18, "scene": 12, "line": "Oh, I boiled some Gatorade.", "order": 49091, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 18, "scene": 13, "line": "[misses putt] Ah. In England, they put the holes a little bit to the right, you see. We'll just chalk it up to cultural bias.", "order": 49092, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 18, "scene": 13, "line": "If I may, [assists Nellie] try holding the putter... yeah, with your wrists here. And your thumbs here.", "order": 49093, "speaker": "Robert California"},
  {"season": 8, "episode": 18, "scene": 13, "line": "Oh.", "order": 49094, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 18, "scene": 13, "line": "That's right. Your little finger.", "order": 49095, "speaker": "Robert California"},
  {"season": 8, "episode": 18, "scene": 14, "line": "[approaches Kevin] Would you like to buy some cookies?", "order": 49096, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 18, "scene": 14, "line": "Cookies, eh?", "order": 49097, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 18, "scene": 15, "line": "[singing] Oh, the springtime thinks that it's the best. And fall time thinks that it's the best. Cold time has, kind of a strut. And Valentine's thinks that it's the best. But gather round, peeps, I'll tell you the truth. Nothing beats the cookie season, that's the truth.", "order": 49098, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 18, "scene": 16, "line": "[Kevin scratches cookie order form and sniffs it] It's not a scratch-and-sniff, Kev.", "order": 49099, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 18, "scene": 16, "line": "I know. But sometimes you still get a little something.", "order": 49100, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 18, "scene": 16, "line": "[to Darryl] Hey, you tricked me. You just wanted Kevin.", "order": 49101, "speaker": "Toby Flenderson"},
  {"season": 8, "episode": 18, "scene": 16, "line": "You're new to the game. You learned a lesson today. See you next year, sport.", "order": 49102, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 18, "scene": 16, "line": "No, no, no. It's not fair. What if Kevin wants to buy cookies from me?", "order": 49103, "speaker": "Toby Flenderson"},
  {"season": 8, "episode": 18, "scene": 16, "line": "I do.", "order": 49104, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 18, "scene": 16, "line": "See?", "order": 49105, "speaker": "Toby Flenderson"},
  {"season": 8, "episode": 18, "scene": 16, "line": "That doesn't mean anything. [to Kevin] Kevin, do you want to buy cookies from me?", "order": 49106, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 18, "scene": 16, "line": "Oh, I definitely do.", "order": 49107, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 18, "scene": 16, "line": "[to Toby] Huh. Hit the road, jack.", "order": 49108, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 18, "scene": 16, "line": "No, you hit the road, jack.", "order": 49109, "speaker": "Toby Flenderson"},
  {"season": 8, "episode": 18, "scene": 16, "line": "[looking pleased] Hey guys, come on. Don't fight over me.", "order": 49110, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 18, "scene": 16, "line": "You know, why don't we split the order? It's only fair.", "order": 49111, "speaker": "Toby Flenderson"},
  {"season": 8, "episode": 18, "scene": 16, "line": "No. Wait, no. I'm buying, I make the rules. I actually do want you to fight over me. I wanna be wined and dined and... 69ed.", "order": 49112, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 18, "scene": 16, "line": "Ugh.", "order": 49113, "speaker": "Angela Martin"},
  {"season": 8, "episode": 18, "scene": 16, "line": "Metaphorically 69ed. Ew. Perverts... no offence Oscar. [Oscar looks flabbergasted]", "order": 49114, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 18, "scene": 17, "line": "Hop in, we have places to be. No rest for the wicked.", "order": 49115, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 18, "scene": 17, "line": "[to Dwight] All right. So... I guess this is it.", "order": 49116, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 18, "scene": 17, "line": "Well, Jim, I just want to say that we haven't always got along and at times, I've even hated your guts. But...[smiles] bye, bye. I win.", "order": 49117, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 18, "scene": 17, "line": "[attempts to shake Dwight's hand] Goodbye, Dwight.", "order": 49118, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 18, "scene": 17, "line": "[drops golf ball into Jim's open hand] Robert, race you to the clubhouse, gentlemen's bet. Woohoo! [takes off in golf cart]", "order": 49119, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 18, "scene": 18, "line": "Well, he's Florida's problem now.", "order": 49120, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 18, "scene": 18, "line": "I'll let Dwight have his fun. Today will not be his day.", "order": 49121, "speaker": "Robert California"},
  {"season": 8, "episode": 18, "scene": 18, "line": "What's that?", "order": 49122, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 18, "scene": 18, "line": "I'm gonna tank the Sabre store at the presentation to the board.", "order": 49123, "speaker": "Robert California"},
  {"season": 8, "episode": 18, "scene": 18, "line": "I thought you liked the store?", "order": 49124, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 18, "scene": 18, "line": "Well, the store is lovely. You created a wonderful space to showcase our product line. Great job. Cheers.", "order": 49125, "speaker": "Robert California"},
  {"season": 8, "episode": 18, "scene": 18, "line": "Thank you.", "order": 49126, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 18, "scene": 18, "line": "But, there's a reason we sell our products online and over-the-phone. Have you ever used Sabre electronics, Jim? [Jim shakes head] They're cheap. They're unintuitive. The Sabre store would work if we adopted the carnival model of leaving town once everyone's wives do us.", "order": 49127, "speaker": "Robert California"},
  {"season": 8, "episode": 18, "scene": 18, "line": "Wow. When you put it that way, I guess it does sound pretty terrible.", "order": 49128, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 18, "scene": 18, "line": "I couldn't just kill the project from the start. Jo Bennett endorsed it. Shame though, I did like Dwight.", "order": 49129, "speaker": "Robert California"},
  {"season": 8, "episode": 18, "scene": 18, "line": "[from distanced golf cart] Robert! I'm gonna win, ha ha, I'm the gentleman! Suck it!", "order": 49130, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 18, "scene": 18, "line": "[to Dwight] Bravo, Dwight! Very good! [Jim looks surprised] [to Jim] Shame.", "order": 49131, "speaker": "Robert California"},
  {"season": 8, "episode": 18, "scene": 19, "line": "[at Sabre headquarters] The math is simple folks. Deeper market penetration, plus greater visibility, will raise Sabre [Nellie emerges from behind Dwight] [together] to the power of two.", "order": 49132, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 18, "scene": 19, "line": "How did that look?", "order": 49133, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 18, "scene": 19, "line": "I'm not just saying this, that was the best thing I've ever seen.", "order": 49134, "speaker": "Gabe Lewis"},
  {"season": 8, "episode": 18, "scene": 19, "line": "[to Nellie] I told you.", "order": 49135, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 18, "scene": 19, "line": "When you guys do that whole power of two shebang, how 'bout I pop up also? I guess we'd have to say 'power of three'. Hm, actually, you know what, yeah, that actually works. [to Nellie] You'd spin off right. I'd spin off-", "order": 49136, "speaker": "Todd Packer"},
  {"season": 8, "episode": 18, "scene": 19, "line": "You know what? That is a great idea. Cathy, write that down and print it out. It's gonna make some really good toilet paper.", "order": 49137, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 18, "scene": 19, "line": "[enters room] All right. There he is.", "order": 49138, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 18, "scene": 19, "line": "What are you doing here? I thought I got rid of you?", "order": 49139, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 18, "scene": 19, "line": "Can I just talk to you for one quick second?", "order": 49140, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 18, "scene": 19, "line": "What, your stylist ran out of 'messy spray'? [others laugh]", "order": 49141, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 18, "scene": 19, "line": "Um, actually it's, it's for your own good. I think maybe we should- [gestures toward hallway]", "order": 49142, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 18, "scene": 19, "line": "You want to do something for my own good? Turn around, walk out that door, do not stop 'til you get to Scranton, find my cell phone charger, mail it back to me, and then go hell. [others laugh]", "order": 49143, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 18, "scene": 19, "line": "Nice. [To Jim] Hey, Halpert, anyone ever tell you you look like Wooly Willy?", "order": 49144, "speaker": "Todd Packer"},
  {"season": 8, "episode": 18, "scene": 19, "line": "[to Packer] Silence.", "order": 49145, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 18, "scene": 19, "line": "Aw, I'm just trying to-", "order": 49146, "speaker": "Todd Packer"},
  {"season": 8, "episode": 18, "scene": 19, "line": "[interrupts] I know what you're trying to do, I don't want it. [to Jim] But your face does look like the guy from Operation.", "order": 49147, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 18, "scene": 19, "line": "That's... that's the same guy. It's the joke I made.", "order": 49148, "speaker": "Todd Packer"},
  {"season": 8, "episode": 18, "scene": 19, "line": "Different guy.", "order": 49149, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 18, "scene": 19, "line": "You know, I just think you should know that-", "order": 49150, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 18, "scene": 19, "line": "[interrupts] That you look like the world's tallest hobbit. [others laugh]", "order": 49151, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 18, "scene": 20, "line": "Well, I tried. [gestures the meeting room] You saw it, so, it's on the record. I have a plane to catch.", "order": 49152, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 18, "scene": 21, "line": "Can you help me? I'm trying to make a video chat with Andy.", "order": 49153, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 18, "scene": 21, "line": "Just open the program and type in his user name.", "order": 49154, "speaker": "Irene"},
  {"season": 8, "episode": 18, "scene": 21, "line": "Can you just do it?", "order": 49155, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 18, "scene": 21, "line": "[after briefly typing] Here, type in your password.", "order": 49156, "speaker": "Irene"},
  {"season": 8, "episode": 18, "scene": 21, "line": "Erin123'", "order": 49157, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 18, "scene": 21, "line": "That's a terrible password. And you don't 'make a video chat', you video chat.", "order": 49158, "speaker": "Irene"},
  {"season": 8, "episode": 18, "scene": 21, "line": "[annoyed] All right.", "order": 49159, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 18, "scene": 21, "line": "[video chat begins, Andy's head enters the screen from the side] Hello?", "order": 49160, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 18, "scene": 21, "line": "That's so weird. There's something wrong with my laptop. [turns laptop on its side] Oh. I fixed it.", "order": 49161, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 18, "scene": 21, "line": "Oh, now mine's broken. Hang on. [turns his laptop on its side while lying on desk] Oh, there we go.", "order": 49162, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 18, "scene": 22, "line": "[on phone with Jim] He said, 'I did like Dwight'? He's gonna fire him.", "order": 49163, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 18, "scene": 22, "line": "No, no, I think it was more like, 'you know, I liked him, but I don't anymore because he did a bad job, so I'm definitely gonna yell at him'.", "order": 49164, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 18, "scene": 22, "line": "Robert doesn't talk like that. You have to stop Dwight from doing this.", "order": 49165, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 18, "scene": 22, "line": "I tried. He will not listen.", "order": 49166, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 18, "scene": 22, "line": "Did you actually try your hardest?", "order": 49167, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 18, "scene": 22, "line": "Yes... my pretty hardest. Look, you haven't dealt with him in awhile, all right. He's like super Dwight. It's like he's been bitten by a radioactive Dwight, or som- Stanley, back me up.", "order": 49168, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 18, "scene": 22, "line": "Don't talk to me.", "order": 49169, "speaker": "Stanley Hudson"},
  {"season": 8, "episode": 18, "scene": 22, "line": "Stanley's very upset that we're leaving Florida. But he would back me up.", "order": 49170, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 18, "scene": 22, "line": "If Dwight's about to get fired, you have to tell him. Just get the words out. That's all you can do.", "order": 49171, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 18, "scene": 22, "line": "OK. All right.", "order": 49172, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 18, "scene": 23, "line": "[to Kevin] What's a skinny guy like Toby know about cookies? You can't trust him to understand the wants and needs of the thick man. Maybe Toby from two years ago.", "order": 49173, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 18, "scene": 23, "line": "[laughs] Yeah.", "order": 49174, "speaker": "Phyllis Vance"},
  {"season": 8, "episode": 18, "scene": 23, "line": "That's true. If I have a question about my cookies at midnight, who am I gonna call? Darryl. Toby's probably in bed with some model.", "order": 49175, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 18, "scene": 23, "line": "Thank you.", "order": 49176, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 18, "scene": 23, "line": "I'm, I'm not gonna comment on my personal life.", "order": 49177, "speaker": "Toby Flenderson"},
  {"season": 8, "episode": 18, "scene": 23, "line": "Look, I need this. Ok? Your daughter is a pretty little girl. Let her go door-to-door. You think people gonna buy cookies from my [hesitates] chubby daughter?", "order": 49178, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 18, "scene": 23, "line": "Oh. [looks away]", "order": 49179, "speaker": "Phyllis Vance"},
  {"season": 8, "episode": 18, "scene": 24, "line": "Baby, if you're watching this, you're not chubby, you're beautiful. Daddy's just got to sell some cookies. And we're also gonna exercise more. It's gonna be fun.", "order": 49180, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 18, "scene": 25, "line": "[to Kevin] Oh, you know what you have to do? To decide? You need to make them do things for you. And, like, buy you things.", "order": 49181, "speaker": "Kelly Kapoor"},
  {"season": 8, "episode": 18, "scene": 25, "line": "Or have them sing that song! That the frog does in Looney Tunes.", "order": 49182, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 18, "scene": 25, "line": "Make them kiss each other.", "order": 49183, "speaker": "Phyllis Vance"},
  {"season": 8, "episode": 18, "scene": 25, "line": "Make them kiss me. [everyone 'ew's] [Toby and Darryl look at each other]", "order": 49184, "speaker": "Meredith Palmer"},
  {"season": 8, "episode": 18, "scene": 26, "line": "Yes, this is too tan. This right here-", "order": 49185, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 18, "scene": 26, "line": "No.", "order": 49186, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 18, "scene": 26, "line": "This is a tanned spot.", "order": 49187, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 18, "scene": 26, "line": "I don't buy it. It, that is a freckle. That is not a tan, ok? I'm gonna have to inspect it in person when you get back here. I want you looking totally 'puerto ricania' when i see you.", "order": 49188, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 18, "scene": 26, "line": "Well, Andy, I'm not coming back.", "order": 49189, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 18, "scene": 26, "line": "What?", "order": 49190, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 18, "scene": 26, "line": "I have a job here. I work for an old lady. [turns laptop to put Irene into view]", "order": 49191, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 18, "scene": 26, "line": "[waves] Hello!", "order": 49192, "speaker": "Irene"},
  {"season": 8, "episode": 18, "scene": 26, "line": "Andy?", "order": 49193, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 18, "scene": 26, "line": "Yeah, that's awesome. That's great.", "order": 49194, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 18, "scene": 27, "line": "[Toby and Darryl perform Hello! Ma Baby] This is tough. 'Cause Darryl, you sang better and you dance better but Toby has that indescribable quality that makes a star. I think I've reached my decision. I have decided... that you guys are gonna keep doing things for me.", "order": 49195, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 18, "scene": 27, "line": "No, no, no, no. It's not worth it.", "order": 49196, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 18, "scene": 27, "line": "No.", "order": 49197, "speaker": "Toby Flenderson"},
  {"season": 8, "episode": 18, "scene": 27, "line": "No, it's not worth it? That's too bad. 'Cause I was feeling particularly hungry this year.", "order": 49198, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 18, "scene": 27, "line": "Yeah, ok so what, you buy 40 boxes?", "order": 49199, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 18, "scene": 27, "line": "Hungrier.", "order": 49200, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 18, "scene": 27, "line": "50?", "order": 49201, "speaker": "Toby Flenderson"},
  {"season": 8, "episode": 18, "scene": 27, "line": "Hungrier.", "order": 49202, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 18, "scene": 27, "line": "You're not talking... triple digits?", "order": 49203, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 18, "scene": 27, "line": "Oh yeah, I'm talking triple digits. [folds arms satisfied while Toby and Darryl look at each other] Again. [both start performing Hello! Ma Baby again]", "order": 49204, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 18, "scene": 28, "line": "[straightens tie in mirror] Showtime.", "order": 49205, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 18, "scene": 28, "line": "[enters abruptly] Dwight.", "order": 49206, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 18, "scene": 28, "line": "You again? Gosh, I keep throwing you away, you keep flying back here. You're like an Amish return stick.", "order": 49207, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 18, "scene": 28, "line": "OK, great, listen to me. Listen to me. [Dwight makes funny gesture] No, no, I know. Will you just let me tell you one thing, please?", "order": 49208, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 18, "scene": 28, "line": "Yes, you may tell me one thing. Wait, you want to borrow money?", "order": 49209, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 18, "scene": 28, "line": "Listen to me. Robert is going to veto the Sabre store.", "order": 49210, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 18, "scene": 28, "line": "[rolls eyes] Jim, come on.", "order": 49211, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 18, "scene": 28, "line": "Dwight, he's gonna kill the store.", "order": 49212, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 18, "scene": 28, "line": "Uh-huh.", "order": 49213, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 18, "scene": 28, "line": "And then, I'm pretty sure he's gonna fire you for it.", "order": 49214, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 18, "scene": 28, "line": "Wait. [holds up fingers] He's gonna kill the store? And he's gonna fire me?", "order": 49215, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 18, "scene": 28, "line": "Yes.", "order": 49216, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 18, "scene": 28, "line": "[smiles] That's two things.", "order": 49217, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 18, "scene": 28, "line": "Dwight, please.", "order": 49218, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 18, "scene": 28, "line": "Nice try, Jim. Your pranks have never worked in the past and they're not going to work today.", "order": 49219, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 18, "scene": 28, "line": "OK, first of all, they've mostly worked, so-", "order": 49220, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 18, "scene": 28, "line": "You know what? You might want to get to the airport. It's gonna take you a long time to get through security with all those beauty products. Bye.", "order": 49221, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 18, "scene": 28, "line": "Dwight, come o-", "order": 49222, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 18, "scene": 28, "line": "Nothing is gonna stop me. That is the mark of a great man. Unstoppability. Dunder-Mifflin, the farm, Mose, all those things vanish in my rearview mirror. Now, if you'll excuse me, I have a date with destiny. And from what I hear, she's a slu- Ah![Jim tackles Dwight]", "order": 49223, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 18, "scene": 29, "line": "[Jim holds him back and tries to cover his mouth] Help! Help!", "order": 49224, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 18, "scene": 29, "line": "Gross! Don't lick my hand! God, why is there so much saliva?", "order": 49225, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 18, "scene": 29, "line": "AlI had to do was think about pie and my salivary glands did the rest.", "order": 49226, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 18, "scene": 29, "line": "Gross!", "order": 49227, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 18, "scene": 29, "line": "[attempting to break from Jim's grasp] Ah!", "order": 49228, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 18, "scene": 29, "line": "What? What, what, what, what, what?", "order": 49229, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 18, "scene": 29, "line": "[clutching side] Oh, god! You... oh! My appendix-", "order": 49230, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 18, "scene": 29, "line": "I'm sorry.", "order": 49231, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 18, "scene": 29, "line": "My wound hasn't healed yet.", "order": 49232, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 18, "scene": 29, "line": "I'm so sorry, I forgot.", "order": 49233, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 18, "scene": 29, "line": "Oh, man!", "order": 49234, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 18, "scene": 29, "line": "You all right? You ok?", "order": 49235, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 18, "scene": 29, "line": "[stops whining and charges Jim] Rrrah!", "order": 49236, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 18, "scene": 30, "line": "[to Cathy] Where the hell is he? I cannot do this without him.", "order": 49237, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 18, "scene": 30, "line": "I don't know. He's not picking up.", "order": 49238, "speaker": "Cathy Simms"},
  {"season": 8, "episode": 18, "scene": 30, "line": "Well, looks like Shnoot's a no-show. Guess he wasn't vice presy material after all. But I am. Put me in, babe. I got the info down backwards, forwards, and doggy-style. I'm your man.", "order": 49239, "speaker": "Todd Packer"},
  {"season": 8, "episode": 18, "scene": 30, "line": "[considers and decides on Packer] Right, let's begin then. Ladies and gentlemen, allow me to introduce the vice president of Sabre Retail, Mr. Todd Packer.", "order": 49240, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 18, "scene": 30, "line": "Sup?", "order": 49241, "speaker": "Todd Packer"},
  {"season": 8, "episode": 18, "scene": 31, "line": "[notices Andy boxing up things from Erin's desk] What are you doing?", "order": 49242, "speaker": "Phyllis Vance"},
  {"season": 8, "episode": 18, "scene": 31, "line": "I'm just dealing with Erin's stuff since apparently she's not coming back. And she didn't bother to tell anyone.", "order": 49243, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 18, "scene": 31, "line": "We knew. Ryan told us.", "order": 49244, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 18, "scene": 31, "line": "Ryan, why didn't you tell me?", "order": 49245, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 18, "scene": 31, "line": "Thought you checker my Tumblr?", "order": 49246, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 18, "scene": 31, "line": "You never update it.", "order": 49247, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 18, "scene": 31, "line": "Well, I updated it.", "order": 49248, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 18, "scene": 31, "line": "Does anyone else think this is weird? And like, kind of uncool, actually? To leave us without a receptionist?", "order": 49249, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 18, "scene": 31, "line": "We'll find another receptionist. I mean, that's easy. We'll be fine. [Andy nods reluctantly]", "order": 49250, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 18, "scene": 32, "line": "[trying to get around Jim] Huh, huh, huh! [slides between Jim's legs]", "order": 49251, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 18, "scene": 32, "line": "What are you doing?", "order": 49252, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 18, "scene": 32, "line": "I was trying to go-", "order": 49253, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 18, "scene": 32, "line": "Get up.", "order": 49254, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 18, "scene": 32, "line": "Ok, ok.", "order": 49255, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 18, "scene": 32, "line": "Get up. All right? I'm not gonna let you by.", "order": 49256, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 18, "scene": 32, "line": "Then you know what? I'm just gonna have to run right through you.", "order": 49257, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 18, "scene": 32, "line": "OK. [Dwight runs in place] What are you doing? Are you really revving up? you know that doesn't work.", "order": 49258, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 18, "scene": 32, "line": "[tries to jump off wall] Jackie Chan! [falls and groans]", "order": 49259, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 18, "scene": 33, "line": "[holding mobile phone up] Go.", "order": 49260, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 18, "scene": 33, "line": "[in a feminine voice] Hi. This is Alex.", "order": 49261, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 18, "scene": 33, "line": "[in feminine voice] And this is Sam.", "order": 49262, "speaker": "Toby Flenderson"},
  {"season": 8, "episode": 18, "scene": 33, "line": "[in a feminine voice] Kevin can't come to the phone right now because he's busy with us.", "order": 49263, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 18, "scene": 33, "line": "Perfect! Now people will think I'm doing hot girls all day.", "order": 49264, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 18, "scene": 33, "line": "I don't know, man, they might think we're drag queens.", "order": 49265, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 18, "scene": 33, "line": "Yeah, I don't know why you picked names that are also guys' names.", "order": 49266, "speaker": "Toby Flenderson"},
  {"season": 8, "episode": 18, "scene": 33, "line": "Okay, now who do I ride to the kitchen like a pony?", "order": 49267, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 18, "scene": 33, "line": "Don't make me be your pony, Kevin.", "order": 49268, "speaker": "Toby Flenderson"},
  {"season": 8, "episode": 18, "scene": 33, "line": "Forget it, man.", "order": 49269, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 18, "scene": 33, "line": "What do you mean?", "order": 49270, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 18, "scene": 33, "line": "I'm out. And so is Toby.", "order": 49271, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 18, "scene": 34, "line": "This may be wrong. But there's a limit to what I would do for my child.", "order": 49272, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 18, "scene": 34, "line": "Yeah. I have my dignity too. I refuse to be another man's horsey.", "order": 49273, "speaker": "Toby Flenderson"},
  {"season": 8, "episode": 18, "scene": 35, "line": "No guys, stop! You have to sell me cookies. I'll do anything. [starts performing Hello! Ma Baby] I'm even gonna kiss Meredith. [kisses Meredith] That is... hmph... that's ah... so good. [almost in tears Meredith pulls him toward her by his tie]", "order": 49274, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 18, "scene": 36, "line": "[tackled by Dwight] What are you doing? No. No, no, no.", "order": 49275, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 18, "scene": 36, "line": "This. Ends. Now.", "order": 49276, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 18, "scene": 36, "line": "This is dangerous.", "order": 49277, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 18, "scene": 36, "line": "[picks Jim up] Rrahh! [both fall]", "order": 49278, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 18, "scene": 36, "line": "Why?", "order": 49279, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 18, "scene": 36, "line": "Anything else you need to talk about?", "order": 49280, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 18, "scene": 36, "line": "Nope. I think that was it. [Dwight gets up and walks away]", "order": 49281, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 18, "scene": 37, "line": "[looking disheveled in mirror] Once again, it's show time.", "order": 49282, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 18, "scene": 38, "line": "What baffles me is how you could take a perfectly good idea, [Dwight enters] which the great Jo Bennett rightfully signed off on, and then utterly botch it in execution.", "order": 49283, "speaker": "Robert California"},
  {"season": 8, "episode": 18, "scene": 38, "line": "If I may speak to that, I have only been vice president of this project for the last half hour, so the man you want is Dwight Shrute.", "order": 49284, "speaker": "Todd Packer"},
  {"season": 8, "episode": 18, "scene": 38, "line": "I don't see Dwight. He clearly had the infinite wisdom to stay as far away from this clown show as possible.", "order": 49285, "speaker": "Robert California"},
  {"season": 8, "episode": 18, "scene": 38, "line": "Whatever you do, do not blame Todd Packer. It is not his fault. Blame his upbringing, his parents, the society that would mold this idiotic creature. Fire the employee, yes... but not the man. You may not cancel his soul.", "order": 49286, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 18, "scene": 38, "line": "That was never on the table.", "order": 49287, "speaker": "Robert California"},
  {"season": 8, "episode": 18, "scene": 38, "line": "[to Nellie] Are you kidding? [to Robert] She's the queen of the whole freaking Magilla!", "order": 49288, "speaker": "Todd Packer"},
  {"season": 8, "episode": 18, "scene": 38, "line": "And yet Todd, it's you who's fired.", "order": 49289, "speaker": "Robert California"},
  {"season": 8, "episode": 18, "scene": 38, "line": "What, you, I, I can't get fired. I'm an institution. I have been at this company for 20 years. How many of you have been here that long, huh? [Dwight exits meeting room and helps Jim up]", "order": 49290, "speaker": "Todd Packer"},
  {"season": 8, "episode": 18, "scene": 39, "line": "[Dwight and Jim enter office] Hey, hey. They're back. [Everyone greets them]", "order": 49291, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 18, "scene": 39, "line": "Dwight, what are you doing here? I thought you were supposed to be in Florida?", "order": 49292, "speaker": "Kelly Kapoor"},
  {"season": 8, "episode": 18, "scene": 39, "line": "Yeah, the crazy thing about that is, um, wow you look great. Did you lose some weight?", "order": 49293, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 18, "scene": 39, "line": "Thank you, for someone who actually notices this. No, I didn't lose weight. But I started using my makeup to contour my face to look skinnier. I actually put on five pounds.", "order": 49294, "speaker": "Kelly Kapoor"},
  {"season": 8, "episode": 18, "scene": 39, "line": "[to Stanley] Hey! Good to have you back.", "order": 49295, "speaker": "Phyllis Vance"},
  {"season": 8, "episode": 18, "scene": 39, "line": "[insincerely] Good to be back.", "order": 49296, "speaker": "Stanley Hudson"},
  {"season": 8, "episode": 18, "scene": 39, "line": "[to Jim] Hey, stranger!", "order": 49297, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 18, "scene": 39, "line": "Hey.", "order": 49298, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 18, "scene": 39, "line": "Aw, I missed you.", "order": 49299, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 18, "scene": 39, "line": "I missed you. [they embrace and kiss as Andy watches on sadly]", "order": 49300, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 18, "scene": 39, "line": "Aw.", "order": 49301, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 18, "scene": 40, "line": "I'm going to Florida to get Erin. [grabs coat, runs out, then returns] Forgot to turn off my email. It's crazy, right? It's just, when she said she was leaving, I felt so- [looks at computer frustrated] 'You're about to close four tabs, are you sure you want to continue?' Yes. I am sure. Ah, slow computer!", "order": 49302, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 19, "scene": 1, "line": "[telephone ringing] This is Pam. Oh my God, are you sure? Uh, okay. Okay, um, we'll be right there. Everybody, the balloon is falling.", "order": 49303, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 19, "scene": 1, "line": "Nice.", "order": 49304, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 19, "scene": 2, "line": "There's this balloon that has been floating in the rafters of the warehouse for, like, ever. And, okay, it's not -- it doesn't sound cool. You just have to see it.", "order": 49305, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 19, "scene": 3, "line": "Well, I guess it doesn't look that cool either. But, it's been up there a long time, so it's become a pretty big deal.", "order": 49306, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 19, "scene": 4, "line": "Wow. It's the end of an era.", "order": 49307, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 19, "scene": 4, "line": "Did a good job, Buddy. Now it's time to come home.", "order": 49308, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 19, "scene": 4, "line": "I remember when that balloon went up there. I was still with Gil. We were so happy.", "order": 49309, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 19, "scene": 4, "line": "When that went up there, I had hair like Rapunzel.", "order": 49310, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 19, "scene": 4, "line": "How long do you think it's been up there, Kevin?", "order": 49311, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 19, "scene": 4, "line": "I just remember pushing aside my silky bangs to say, 'Look, a balloon.'", "order": 49312, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 19, "scene": 4, "line": "My warcraft clan was still on speaking terms.", "order": 49313, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 19, "scene": 4, "line": "My kid didn't have a face tattoo.", "order": 49314, "speaker": "Meredith Palmer"},
  {"season": 8, "episode": 19, "scene": 4, "line": "I was still thinking of going back to school.", "order": 49315, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 19, "scene": 4, "line": "And I was still just a paper salesman.", "order": 49316, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 19, "scene": 4, "line": "Well this has been fun, Pam. Thanks for calling us all down here. [everyone sighs]", "order": 49317, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 19, "scene": 5, "line": "Kill the balloon. Kill the balloon. Kill the balloon. Kill the balloon. Kill the balloon. [pops loudly] [cheers and applause]", "order": 49318, "speaker": "All"},
  {"season": 8, "episode": 19, "scene": 6, "line": "Hey. So they accidentally gave us an extra egg sandwich this morning. Who wants it?", "order": 49319, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 19, "scene": 6, "line": "oooh", "order": 49320, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 19, "scene": 6, "line": "Me.", "order": 49321, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 19, "scene": 6, "line": "Yeah.", "order": 49322, "speaker": "Phyllis Vance"},
  {"season": 8, "episode": 19, "scene": 6, "line": "Right here.", "order": 49323, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 19, "scene": 6, "line": "Allow me to solve your problem, then.", "order": 49324, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 19, "scene": 6, "line": "Nellie", "order": 49325, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 19, "scene": 6, "line": "Mmm. [bites sandwich] Oh, that is disgusting. Do you call that a King James breakfast pie?", "order": 49326, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 19, "scene": 6, "line": "What brings you to town?", "order": 49327, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 19, "scene": 6, "line": "Certainly not the Harry Houdini Museum. What a nobody. Oh look, some Hungarian just found their way out of a sack. Let's build a shrine. No, I've come to work here.", "order": 49328, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 19, "scene": 6, "line": "What?", "order": 49329, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 19, "scene": 7, "line": "How could Robert transfer Nellie here after the Sabre store debacle? She stinks of failure. The fact that she could show her face around here is an embarrassment. I should know. I'm in an identical situation.", "order": 49330, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 19, "scene": 8, "line": "Robert did say you'd be joining us. Welcome. I'm Tony.", "order": 49331, "speaker": "Toby Flenderson"},
  {"season": 8, "episode": 19, "scene": 8, "line": "Mm.", "order": 49332, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 19, "scene": 8, "line": "What?", "order": 49333, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 19, "scene": 8, "line": "I- I said I'm Tony. Okay I made a mistake. I thought it might go unnoticed. But uh, I'm Toby.", "order": 49334, "speaker": "Toby Flenderson"},
  {"season": 8, "episode": 19, "scene": 8, "line": "You messed up saying your name?", "order": 49335, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 19, "scene": 8, "line": "It happens, okay? Uh, so let's just find an empty desk for you, and uh, I'm sure Robert will be with you as soon as he gets in.", "order": 49336, "speaker": "Toby Flenderson"},
  {"season": 8, "episode": 19, "scene": 8, "line": "That one looks empty.", "order": 49337, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 19, "scene": 8, "line": "No, that's Andy's office.", "order": 49338, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 19, "scene": 8, "line": "Oh, is it?", "order": 49339, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 19, "scene": 9, "line": "I have one simple philosophy in business: if the seat is open, the job is open. It's how I came to briefly race a formula one car. The three slowest laps ever recorded.", "order": 49340, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 19, "scene": 10, "line": "Another beautiful day in Tallahassee. Ooh. Good morning, Alonzo.", "order": 49341, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 19, "scene": 10, "line": "Good morning Erin. [driving by delivering newspapers]", "order": 49342, "speaker": "Alonzo"},
  {"season": 8, "episode": 19, "scene": 10, "line": "How are you? How's your family doing?", "order": 49343, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 19, "scene": 10, "line": "Bye.", "order": 49344, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 19, "scene": 11, "line": "Look at this place! Five rooms, and I get to clean them all. That's right. I'm a maid for an old lady. Her grandson's staying with us too because he ran out of money. [chuckles] Listen to me, bragging away.", "order": 49345, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 19, "scene": 12, "line": "Nellie.", "order": 49346, "speaker": "Robert California"},
  {"season": 8, "episode": 19, "scene": 12, "line": "Robert.", "order": 49347, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 19, "scene": 12, "line": "How are you adjusting to life in Scranton so far?", "order": 49348, "speaker": "Robert California"},
  {"season": 8, "episode": 19, "scene": 12, "line": "So far, so good.", "order": 49349, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 19, "scene": 12, "line": "Good.", "order": 49350, "speaker": "Robert California"},
  {"season": 8, "episode": 19, "scene": 12, "line": "Water pressure in the hotel is marvelous.", "order": 49351, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 19, "scene": 12, "line": "Ah, well, it is astounding what a difference that can make, isn't it?", "order": 49352, "speaker": "Robert California"},
  {"season": 8, "episode": 19, "scene": 12, "line": "Mm-hm.", "order": 49353, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 19, "scene": 12, "line": "Now. Let's find you something fun to do here, shall we?", "order": 49354, "speaker": "Robert California"},
  {"season": 8, "episode": 19, "scene": 12, "line": "Oh, I've already found it. I am manager, a natural next step. It fits like a glove.", "order": 49355, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 19, "scene": 12, "line": "We have a manager.", "order": 49356, "speaker": "Robert California"},
  {"season": 8, "episode": 19, "scene": 12, "line": "Do you really? Because it is 10:00. I've been in this chair for an hour, and no other manager has come and sat in my lap.", "order": 49357, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 19, "scene": 13, "line": "Excuse me, has anyone seen Andy this morning?", "order": 49358, "speaker": "Robert California"},
  {"season": 8, "episode": 19, "scene": 13, "line": "Huh. Yeah, that's weird. He's usually here by now. Right guys?", "order": 49359, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 19, "scene": 13, "line": "[on phone] Hello.", "order": 49360, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 19, "scene": 13, "line": "Andy, it's Robert. Why aren't you at work?", "order": 49361, "speaker": "Robert California"},
  {"season": 8, "episode": 19, "scene": 13, "line": "Hey, Robert. Ah, I really wish I could come in to work today, but I'm super sick.", "order": 49362, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 19, "scene": 13, "line": "I don't care, I don't care. Please come to work immediately.", "order": 49363, "speaker": "Robert California"},
  {"season": 8, "episode": 19, "scene": 13, "line": "Okay, I'll try to come in even though I'm really sick with the...", "order": 49364, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 19, "scene": 14, "line": "[standing in ocean] ...Florida Flu.", "order": 49365, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 19, "scene": 15, "line": "He just hung up on me mid-sentence.", "order": 49366, "speaker": "Robert California"},
  {"season": 8, "episode": 19, "scene": 16, "line": "I'm in Florida to get Erin. As soon as I heard she wasn't coming back to Scranton, I jumped straight in my car and drove straight down till I hit the ocean. My heart is my map. Turns out, Tallahassee is about 200 miles inland, so I overshot a good amount. But still, not bad for a heart map right? [picking up dead fish] Oh, no. Thanks a lot, BP.", "order": 49367, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 19, "scene": 17, "line": "Not much we can do about this until he gets here.", "order": 49368, "speaker": "Robert California"},
  {"season": 8, "episode": 19, "scene": 17, "line": "Can't you do something about this?", "order": 49369, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 19, "scene": 17, "line": "Sometimes the flowers arrange themselves, Jim.", "order": 49370, "speaker": "Robert California"},
  {"season": 8, "episode": 19, "scene": 17, "line": "-Right.", "order": 49371, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 19, "scene": 18, "line": "What is going on? And where's Andy? And what is going on?", "order": 49372, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 19, "scene": 19, "line": "Erin, you got a package.", "order": 49373, "speaker": "Irene"},
  {"season": 8, "episode": 19, "scene": 19, "line": "I'm in the bathroom.", "order": 49374, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 19, "scene": 19, "line": "Where's the postage? I mean, there's no shipping label.", "order": 49375, "speaker": "Glenn"},
  {"season": 8, "episode": 19, "scene": 19, "line": "Did you wash your hands?", "order": 49376, "speaker": "Irene"},
  {"season": 8, "episode": 19, "scene": 19, "line": "Yes.", "order": 49377, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 19, "scene": 19, "line": "[screaming as Andy busts out of box]", "order": 49378, "speaker": "Erin Hannon & Irene"},
  {"season": 8, "episode": 19, "scene": 19, "line": "[singing] Here I am; Signed, sealed, delivered; I'm yours!", "order": 49379, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 19, "scene": 19, "line": "Andy, what are you doing here? It's great to see you.", "order": 49380, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 19, "scene": 20, "line": "Uh, I am here to take you back to Scranton. Because I love you and I want to be with you.", "order": 49381, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 19, "scene": 20, "line": "Where's the ring?", "order": 49382, "speaker": "Irene"},
  {"season": 8, "episode": 19, "scene": 20, "line": "Hm?", "order": 49383, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 19, "scene": 20, "line": "Where's the ring, Lancelot?", "order": 49384, "speaker": "Irene"},
  {"season": 8, "episode": 19, "scene": 20, "line": "Uh, would you guys mind if we talked privately for a second? Sorry, Glenn.", "order": 49385, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 19, "scene": 20, "line": "Um, Andy... I am so happy to see you. But I'm not coming back with you.", "order": 49386, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 19, "scene": 20, "line": "[whispering off screen] Why won't she go with him?", "order": 49387, "speaker": "Glenn"},
  {"season": 8, "episode": 19, "scene": 20, "line": "[whispering] I don't know. Maybe it's 'cause he's not that handsome.", "order": 49388, "speaker": "Irene"},
  {"season": 8, "episode": 19, "scene": 20, "line": "Hey... don't listen.", "order": 49389, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 19, "scene": 21, "line": "So what is going on? That does seem to be the question, doesn't it? [in American accent] Hey, this is messed up, bro. Who is this weird lady? [normal voice] I know. It's a lot. So, who knows what's going on? Anyone? You, yes. Wait, I am very good at intuiting names. Is it... chumbo?", "order": 49390, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 19, "scene": 22, "line": "I think it's a cross between Dumbo and Jumbo, with a hint of chubby. It's not a name.", "order": 49391, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 19, "scene": 23, "line": "So no one can tell me what's going on. Well, let me illuminate things for you. We are getting to know each other. [in American accent] But why, m'lady? [in normal voice] Because I am your new manager.", "order": 49392, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 19, "scene": 23, "line": "Robert, is Nellie our new manager?", "order": 49393, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 19, "scene": 23, "line": "[laughs] This is an odd situation. But it's very interesting how it's playing out.", "order": 49394, "speaker": "Robert California"},
  {"season": 8, "episode": 19, "scene": 24, "line": "We live in this world of routines and rhythms. Kevin ate someone's lunch. Phyllis has a new necklace. Who is this woman?", "order": 49395, "speaker": "Robert California"},
  {"season": 8, "episode": 19, "scene": 25, "line": "I came in here simply trying to get to know you, learn your names, maybe have someone teach me the company song. And what I got in return was nothing but disrespect. You leave me no choice but to get to know you in a more intimate way.", "order": 49396, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 19, "scene": 25, "line": "Hot tub party?", "order": 49397, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 19, "scene": 25, "line": "Performance reviews.", "order": 49398, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 19, "scene": 25, "line": "How can you give us performance reviews if you don't know us?", "order": 49399, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 19, "scene": 25, "line": "On first impressions, so I recommend smiling. It goes a long way with me.", "order": 49400, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 19, "scene": 26, "line": "Erin, you really nailed the hot dogs today.", "order": 49401, "speaker": "Glenn"},
  {"season": 8, "episode": 19, "scene": 26, "line": "Oh, thanks. I've been re-using the hot dog water so it gets more flavor. It's only going to keep getting better.", "order": 49402, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 19, "scene": 26, "line": "So, Andrew, how does your skinny brunette girlfriend Jessica feel about you visiting your ex-girlfriend?", "order": 49403, "speaker": "Irene"},
  {"season": 8, "episode": 19, "scene": 26, "line": "Well, we're not really dating anymore, so... basically, I mean, I just have to cross a few Ts and dot a few I's, you know.", "order": 49404, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 19, "scene": 26, "line": "So you came to get me, but you still have a girlfriend.", "order": 49405, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 19, "scene": 26, "line": "I think you should leave, young man.", "order": 49406, "speaker": "Irene"},
  {"season": 8, "episode": 19, "scene": 26, "line": "All right. Technically, yes, I am still technically dating Jessica. But when I realized that I wanted you back, I just jumped in the car and I drove down here, and I didn't want to stop until I saw you.", "order": 49407, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 19, "scene": 26, "line": "You didn't even stop to pee? Gross.", "order": 49408, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 19, "scene": 27, "line": "Any luck?", "order": 49409, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 19, "scene": 27, "line": "No. He just keeps letting all the calls go to voicemail.", "order": 49410, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 19, "scene": 27, "line": "[on phone message, singing] Please leave a message for Andy Bernard, include your na-", "order": 49411, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 19, "scene": 28, "line": "Oh, you're doing so good. Oh my God, you're superman over there. [Andy's phone vibrating]", "order": 49412, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 19, "scene": 28, "line": "You should take it. It's probably your girlfriend wondering where you are.", "order": 49413, "speaker": "Irene"},
  {"season": 8, "episode": 19, "scene": 28, "line": "Nope. It's just work. Not important. You know, I can really feel this is my quads, I can tell I'm getting stronger.", "order": 49414, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 19, "scene": 28, "line": "You really wouldn't feel those kinds of results after one session.", "order": 49415, "speaker": "Irene"},
  {"season": 8, "episode": 19, "scene": 28, "line": "I don't know. [phone vibrating] Just let it vibrate. It's fine.", "order": 49416, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 19, "scene": 28, "line": "You could put it on silent.", "order": 49417, "speaker": "Irene"},
  {"season": 8, "episode": 19, "scene": 28, "line": "I don't think it does that.", "order": 49418, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 19, "scene": 28, "line": "Just go to preferences, then click '", "order": 49419, "speaker": "Irene"},
  {"season": 8, "episode": 19, "scene": 28, "line": "[answering Andy's phone] Proctology.", "order": 49420, "speaker": "Glenn"},
  {"season": 8, "episode": 19, "scene": 28, "line": "[on phone] Andy?", "order": 49421, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 19, "scene": 28, "line": "Jim?", "order": 49422, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 19, "scene": 28, "line": "Andy, where are you?", "order": 49423, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 19, "scene": 28, "line": "I- I'm home in bed. I've been in bed all day. I got the-- I got the serious poops, man.", "order": 49424, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 19, "scene": 28, "line": "He's here in Tallahassee, trying to turn my life upside down.", "order": 49425, "speaker": "Irene"},
  {"season": 8, "episode": 19, "scene": 28, "line": "What? You're in Florida? Andy, Nellie Bertram's trying to take your job.", "order": 49426, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 19, "scene": 28, "line": "What do you mean, take my job?", "order": 49427, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 19, "scene": 28, "line": "Like, set up camp in your office and is calling herself the manager.", "order": 49428, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 19, "scene": 28, "line": "Okay... Jim, I really appreciate you looking out for me, but I'm not coming home without Erin. So I'll talk to you later.", "order": 49429, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 19, "scene": 29, "line": "Guys, we cannot do these performance reviews, okay? If we go into them, you're basically accepting Nellie as your manager, and trust me, you do not want her as your manager.", "order": 49430, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 19, "scene": 29, "line": "Trust you? The way Pam trusted you to provide for her so she wouldn't have to work?", "order": 49431, "speaker": "Angela Martin"},
  {"season": 8, "episode": 19, "scene": 29, "line": "I like working here.", "order": 49432, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 19, "scene": 29, "line": "Dwight, should she be our manager?", "order": 49433, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 19, "scene": 29, "line": "I wouldn't let her manage a celery farm.", "order": 49434, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 19, "scene": 30, "line": "Those who can't farm, farm celery.", "order": 49435, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 19, "scene": 31, "line": "And when was the last time Dwight and I agreed on anything ever? [clears throat]", "order": 49436, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 19, "scene": 31, "line": "All right. Let's get going. First up, it is the woman with the beautiful fingernails and the tiny feet, Phyllis.", "order": 49437, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 19, "scene": 31, "line": "Nellie, I'm sorry, but I don't feel comfortable being evaluated by someone I don't know.", "order": 49438, "speaker": "Phyllis Vance"},
  {"season": 8, "episode": 19, "scene": 31, "line": "Oh. Oh, okay. I understand. Let me show you how these are gonna go. Dwight.", "order": 49439, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 19, "scene": 31, "line": "I refuse to be judged by someone that I do not respect. I lost respect for you in Florida. If it was up to me, you would be in jail forever.", "order": 49440, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 19, "scene": 31, "line": "Dwight, I have completed your evaluation. You're getting a raise.", "order": 49441, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 19, "scene": 31, "line": "What?", "order": 49442, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 19, "scene": 31, "line": "Dwight, you carry this company on your massive shoulders. You are our Atlas, and for that do you not think you deserve a raise?", "order": 49443, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 19, "scene": 31, "line": "There's no limit to what I think I deserve.", "order": 49444, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 19, "scene": 31, "line": "Then you accept it?", "order": 49445, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 19, "scene": 31, "line": "Five percent. No less.", "order": 49446, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 19, "scene": 31, "line": "Absolutely not. Seven percent.", "order": 49447, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 19, "scene": 31, "line": "Six percent, I know my worth.", "order": 49448, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 19, "scene": 31, "line": "The raise isn't real.", "order": 49449, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 19, "scene": 31, "line": "Money isn't real ever since we got off the gold standard.", "order": 49450, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 19, "scene": 31, "line": "So Phyllis, now you know the rules of the game. Would you care to have a go? I'm fairly certain you're going to like it.", "order": 49451, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 19, "scene": 31, "line": "Phyllis.", "order": 49452, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 19, "scene": 31, "line": "Pam.", "order": 49453, "speaker": "Phyllis Vance"},
  {"season": 8, "episode": 19, "scene": 32, "line": "So, Phyllis, I have been very impressed with you.", "order": 49454, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 19, "scene": 32, "line": "Really?", "order": 49455, "speaker": "Phyllis Vance"},
  {"season": 8, "episode": 19, "scene": 32, "line": "Oh, yeah. The way you conquered your fear and came in here, and now the way you sit there... all very impressive.", "order": 49456, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 19, "scene": 33, "line": "Um... are you aware that Nellie is giving out raises?", "order": 49457, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 19, "scene": 33, "line": "I am not. Huh.", "order": 49458, "speaker": "Robert California"},
  {"season": 8, "episode": 19, "scene": 33, "line": "Yeah. She gave one to Dwight, Phyllis.", "order": 49459, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 19, "scene": 33, "line": "Let me guess, you want one too? Take the family to Disneytown?", "order": 49460, "speaker": "Robert California"},
  {"season": 8, "episode": 19, "scene": 33, "line": "Land. World. Uh, it's not that I don't want a-- well, yes, I guess I'd take a raise. That's not what I'm saying. That's not-- sorry.", "order": 49461, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 19, "scene": 33, "line": "Jim, I told Andy to come in, and yet he is still not in. By contrast, Nellie Bertram saw a vacuum and filled it. To make no mention of her business experience or her relationship with Jo Bennett, my boss.", "order": 49462, "speaker": "Robert California"},
  {"season": 8, "episode": 19, "scene": 33, "line": "Yes.", "order": 49463, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 19, "scene": 33, "line": "Jim, would you prefer a nature metaphor or a sexual metaphor?", "order": 49464, "speaker": "Robert California"},
  {"season": 8, "episode": 19, "scene": 33, "line": "Oh, god, nature, please.", "order": 49465, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 19, "scene": 33, "line": "When two animals are having sex, one of them...", "order": 49466, "speaker": "Robert California"},
  {"season": 8, "episode": 19, "scene": 33, "line": "[exhales sharply]", "order": 49467, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 19, "scene": 33, "line": " is communicating a message to the other. Nothing is mutua-- this isn't very helpful. You're gonna want to hear the sexual metaphor.", "order": 49468, "speaker": "Robert California"},
  {"season": 8, "episode": 19, "scene": 33, "line": "Was that not the--", "order": 49469, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 19, "scene": 33, "line": "All life is sex. And all sex is competition.", "order": 49470, "speaker": "Robert California"},
  {"season": 8, "episode": 19, "scene": 33, "line": "Mm-hm.", "order": 49471, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 19, "scene": 33, "line": "And there are no rules to that game. That wasn't so perverted, now was it?", "order": 49472, "speaker": "Robert California"},
  {"season": 8, "episode": 19, "scene": 33, "line": "Was that it? No, that wasn't bad.", "order": 49473, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 19, "scene": 33, "line": "My point is, there is one person in charge of every office in America, and that person is Charles Darwin. In the end, doesn't he decide who the manager is?", "order": 49474, "speaker": "Robert California"},
  {"season": 8, "episode": 19, "scene": 33, "line": "No. I would've said no.", "order": 49475, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 19, "scene": 34, "line": "Mm, let's see, review, review, review. Yes, good stuff. Here you go, have a raise.", "order": 49476, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 19, "scene": 34, "line": "Oh, great.", "order": 49477, "speaker": "Kelly Kapoor"},
  {"season": 8, "episode": 19, "scene": 35, "line": "I mean, if we go in there and take these raises, what are we saying? That it's okay for someone to just take someone else's job? Shouldn't work like that.", "order": 49478, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 19, "scene": 35, "line": "No, you know what? Nellie's right. That is exactly how it should work. Darryl, this office is mine now.", "order": 49479, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 19, "scene": 35, "line": "No.", "order": 49480, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 19, "scene": 35, "line": "Yes.", "order": 49481, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 19, "scene": 35, "line": "No.", "order": 49482, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 19, "scene": 35, "line": "Yes.", "order": 49483, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 19, "scene": 35, "line": "No.", "order": 49484, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 19, "scene": 35, "line": "Yes.", "order": 49485, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 19, "scene": 35, "line": "No.", "order": 49486, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 19, "scene": 35, "line": "Yes.", "order": 49487, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 19, "scene": 35, "line": "No.", "order": 49488, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 19, "scene": 35, "line": "Yes. Yes, sorry too late. I'm here. This is mine. Back off.", "order": 49489, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 19, "scene": 35, "line": "[Darryl grabbing him by his hair, dragging him from office] Ow! Ow, ow, ow. Ow, ow, ow, Ow.", "order": 49490, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 19, "scene": 35, "line": "[panting] Well fought. I accept the outcome.", "order": 49491, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 19, "scene": 35, "line": "Touch me and I'll sue.", "order": 49492, "speaker": "Creed Bratton"},
  {"season": 8, "episode": 19, "scene": 36, "line": "I've heard that's amazing when it works.", "order": 49493, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 19, "scene": 36, "line": "Yeah, if I had my own--", "order": 49494, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 19, "scene": 36, "line": "Andy, I care about you and I think--", "order": 49495, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 19, "scene": 36, "line": "--sorry, you go.", "order": 49496, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 19, "scene": 36, "line": "No, go ahead.", "order": 49497, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 19, "scene": 36, "line": "Well, I was just going to say that, if I had my own fountain, it would be two frogs that are spitting into each other's mouth. It's just stupid. What were you going to say?", "order": 49498, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 19, "scene": 36, "line": "Andy, you broke my heart. Do you know what it feels like to be constantly rejected by you and to have to watch you date someone else?", "order": 49499, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 19, "scene": 36, "line": "You broke my heart too.", "order": 49500, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 19, "scene": 36, "line": "You broke my heart more recently and more often. And I think at some point, in my head, it just sort of clicked that we're not meant to be.", "order": 49501, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 19, "scene": 36, "line": "I'm so sorry that we have not loved each other at the same time.", "order": 49502, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 19, "scene": 36, "line": "I know.", "order": 49503, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 19, "scene": 36, "line": "[sighs]", "order": 49504, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 19, "scene": 37, "line": "So, Pamela, I'm going to start this review, not by telling you what you lack, but rather, asking you what it is you want.", "order": 49505, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 19, "scene": 37, "line": "Really? Okay, well, um, I like consistency in the manager's position, no weird silent coups. Stuff like that. I'm easy.", "order": 49506, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 19, "scene": 37, "line": "That's not really what you want. What you want is a good night's sleep, working mother of two.", "order": 49507, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 19, "scene": 37, "line": "Whoa, that's kind of condescending. Would you say something like that to Jim?", "order": 49508, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 19, "scene": 37, "line": "Does Jim have to breastfeed? Did Jim carry around those babies for-- what is it? 12 months? Be honest, does Jim really get up in the night?", "order": 49509, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 19, "scene": 37, "line": "Well, I mean, the only reason he doesn't is because I'm breastfeeding, and Phillip doesn't like a bottle.", "order": 49510, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 19, "scene": 37, "line": "You must be exhausted. Fancy a nap?", "order": 49511, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 19, "scene": 37, "line": "No.", "order": 49512, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 19, "scene": 37, "line": "Go on. Have a nap. Lie down right here. There's a blanket in here. I used it earlier myself. Dreamt I could breathe underwater like Jacques Cousteau. No one will know. I'll wake you up. And when you wake up, you will earn more money.", "order": 49513, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 19, "scene": 37, "line": "I think... you're a witch.", "order": 49514, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 19, "scene": 37, "line": "I think you're amazing. You know that, right? Go on, say it: 'I'm amazing.'", "order": 49515, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 19, "scene": 37, "line": "[drowsily] I'm amazing. [sighs]", "order": 49516, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 19, "scene": 38, "line": "Erin, I think you're making a big mistake right now. Andy is a nice boy.", "order": 49517, "speaker": "Irene"},
  {"season": 8, "episode": 19, "scene": 38, "line": "Really? 'cause you've been kind of a B to him all afternoon.", "order": 49518, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 19, "scene": 38, "line": "I was protecting you because I thought he was no good. But I can tell you really like him. And he's willing to lose his job for you, in this economy with Europe on the brink--", "order": 49519, "speaker": "Irene"},
  {"season": 8, "episode": 19, "scene": 38, "line": "Irene, what would you do without me?", "order": 49520, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 19, "scene": 38, "line": "We're not your concern, sweetheart. Besides, Glenn's going to sue Home Depot.", "order": 49521, "speaker": "Irene"},
  {"season": 8, "episode": 19, "scene": 38, "line": "Why?", "order": 49522, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 19, "scene": 38, "line": "He got his foreskin caught in some lawn furniture.", "order": 49523, "speaker": "Irene"},
  {"season": 8, "episode": 19, "scene": 39, "line": "Jim, time for your review.", "order": 49524, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 19, "scene": 39, "line": "No, it's not, because you don't really work here.", "order": 49525, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 19, "scene": 39, "line": "Why are you so staunchly defending your friend, who has abandoned you? Wouldn't you rather come talk with me? Maybe definitely get a raise?", "order": 49526, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 19, "scene": 39, "line": "Yeah, that's the thing. I don't know if you can even give raises.", "order": 49527, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 19, "scene": 39, "line": "Jim, have you ever heard of a character named Tinkerbell?", "order": 49528, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 19, "scene": 39, "line": "Yes.", "order": 49529, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 19, "scene": 39, "line": "I'm Tinkerbell.", "order": 49530, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 19, "scene": 39, "line": "No.", "order": 49531, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 19, "scene": 39, "line": "Mm-hm. I'm a magical fairy who floated into your office to bring a little bit of magic into your lives, to give you all raises.", "order": 49532, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 19, "scene": 39, "line": "And we are grateful.", "order": 49533, "speaker": "Stanley Hudson"},
  {"season": 8, "episode": 19, "scene": 39, "line": "But here's the thing about Tinkerbell, Jim. Everyone has to believe in her or she doesn't exist.", "order": 49534, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 19, "scene": 39, "line": "She dies.", "order": 49535, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 19, "scene": 39, "line": "She dies! Now who here believes in Tinkerbell?", "order": 49536, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 19, "scene": 39, "line": "Let's see it. Show of hands.", "order": 49537, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 19, "scene": 39, "line": "I do. [applause] Come on everyone...", "order": 49538, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 19, "scene": 39, "line": "All right, guys, stop.", "order": 49539, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 19, "scene": 39, "line": "I already spent the money.", "order": 49540, "speaker": "Meredith Palmer"},
  {"season": 8, "episode": 19, "scene": 39, "line": "How?", "order": 49541, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 19, "scene": 39, "line": "Come on Jim, you're killing her!", "order": 49542, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 19, "scene": 39, "line": "We believe! We believe!", "order": 49543, "speaker": "All"},
  {"season": 8, "episode": 19, "scene": 39, "line": "Look at this wonderful recognition of Nellie's leadership. I believe. [applause continues]", "order": 49544, "speaker": "Robert California"},
  {"season": 8, "episode": 19, "scene": 40, "line": "That did not go how I wanted it to. Ugh. I have a whole ton of Erin's favorite food for the drive back, eggplant parm. [tosses it out car window] It's biodegradable. Animals will eat it.", "order": 49545, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 19, "scene": 40, "line": "Andy wait! [running down street] Andy! Andy wait! Don't go! Andy I love you! Stop! Oh, this is the wrong Prius. I don't love you. I'm sorry.", "order": 49546, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 19, "scene": 40, "line": "Erin!", "order": 49547, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 19, "scene": 40, "line": "Andy. [horns honking as they kiss in the street]", "order": 49548, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 19, "scene": 40, "line": "Let's get out of here.", "order": 49549, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 19, "scene": 40, "line": "Okay.", "order": 49550, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 19, "scene": 40, "line": "Let's go.", "order": 49551, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 19, "scene": 40, "line": "Wait, we have to go back and get your stuff.", "order": 49552, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 19, "scene": 40, "line": "No, I don't have any stuff.", "order": 49553, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 19, "scene": 40, "line": "Like your toothbrush and stuff.", "order": 49554, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 19, "scene": 40, "line": "I don't have one.", "order": 49555, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 19, "scene": 40, "line": "You don't have a toothbrush?", "order": 49556, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 19, "scene": 40, "line": "No.", "order": 49557, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 19, "scene": 40, "line": "How do you not have a toothbrush?", "order": 49558, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 19, "scene": 40, "line": "I just... there's always one around.", "order": 49559, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 19, "scene": 40, "line": "You just use whichever one is sitting there?", "order": 49560, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 19, "scene": 40, "line": "I always find one. Have you ever heard of buying a toothbrush?", "order": 49561, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 19, "scene": 40, "line": "Of course. I own my toothbrush.", "order": 49562, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 19, "scene": 41, "line": "I grew up poor. I had little formal education. No real skills. I don't work especially hard, and most of my ideas are either unoriginal or total crap. And yet, I walked right into a job for which I was ill-prepared, ill-suited, and somebody else already had, and I got it. If you ask me, that's the American dream right there. Anything can happen to anyone. It's just random.", "order": 49563, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 20, "scene": 1, "line": "Hey Jim. Stanley's back from the hospital today. Can you sign his card?", "order": 49564, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 20, "scene": 1, "line": "Oh, great. [reads] 'Glad they didn't mix up your tonsillectomy with a moustachectomy.' Oh that's not good.", "order": 49565, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 20, "scene": 1, "line": "Oh, because your jokes are all hilarious.", "order": 49566, "speaker": "Phyllis Vance"},
  {"season": 8, "episode": 20, "scene": 1, "line": "It's nice. It's funny. It mentions his tonsillectomy and makes a funny little joke about his moustache.", "order": 49567, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 20, "scene": 1, "line": "Stanley doesn't have a moustache.", "order": 49568, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 20, "scene": 1, "line": "Yeah he does.", "order": 49569, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 20, "scene": 1, "line": "Pam, hit the brakes. Stanley does not have a moustache. I misspoke. I'm not sure. I think he has one, now that- I think he has a moustache.", "order": 49570, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 20, "scene": 1, "line": "Okay, Phyllis sits across from him every day. Phyllis, does he have a moustache or not?", "order": 49571, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 20, "scene": 1, "line": "Oh, I don't know. Now I think he doesn't.", "order": 49572, "speaker": "Phyllis Vance"},
  {"season": 8, "episode": 20, "scene": 1, "line": "Phyllis! What are you talking- The whole card depends on this!", "order": 49573, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 20, "scene": 1, "line": "Okay, the man's worked here for 25 years. How can none of us picture his face?", "order": 49574, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 20, "scene": 1, "line": "Cause we come here to do our jobs. We don't stick our noses in other people's business.", "order": 49575, "speaker": "Angela Martin"},
  {"season": 8, "episode": 20, "scene": 1, "line": "Okay, which one of these looks more right? [holds up drawing of Stanley with and without a moustache]", "order": 49576, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 20, "scene": 1, "line": "Neither of those looks like any person that has ever existed or been dreamt of in the history of human insanity. That said, the one on the left.", "order": 49577, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 20, "scene": 1, "line": "[bell dings] Guys, that's the elevator. What if it's him?", "order": 49578, "speaker": "Gabe Lewis"},
  {"season": 8, "episode": 20, "scene": 1, "line": "Okay, quick. Who says moustache? [Pam, Oscar, Dwight, and Creed raise their hands]", "order": 49579, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 20, "scene": 1, "line": "Yep.", "order": 49580, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 20, "scene": 1, "line": "Who says no moustache? [Jim, Angela, and Phyllis raise their hands; Gabe enters, hiding Stanley's face]", "order": 49581, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 20, "scene": 1, "line": "[reveals Stanley's moustache] Ah! Ha ha ha!", "order": 49582, "speaker": "Gabe Lewis"},
  {"season": 8, "episode": 20, "scene": 1, "line": "He does have a moustache.", "order": 49583, "speaker": "Phyllis Vance"},
  {"season": 8, "episode": 20, "scene": 1, "line": "Yes!", "order": 49584, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 20, "scene": 1, "line": "Welcome back, Stanley. [Stanley grunts]", "order": 49585, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 20, "scene": 2, "line": "Good morning, Robert,' says no one because our receptionist is in Florida. [no one responds] Pam!", "order": 49586, "speaker": "Robert California"},
  {"season": 8, "episode": 20, "scene": 2, "line": "Oh! [takes out earbuds]", "order": 49587, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 20, "scene": 2, "line": "Is this a video conference you're having with 'Drake, featuring Swizz Beatz'?", "order": 49588, "speaker": "Robert California"},
  {"season": 8, "episode": 20, "scene": 2, "line": "Um, no, I was just, um, just having a cup of coffee, kind of warming up for the day.", "order": 49589, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 20, "scene": 2, "line": "People, you should come to work already warm. Nine to ten a.m. is the most productive potential that a human being-", "order": 49590, "speaker": "Robert California"},
  {"season": 8, "episode": 20, "scene": 2, "line": "Sorry, sorry, everyone, I'm late! But you all are here, so no harm done at all by my lateness.", "order": 49591, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 20, "scene": 2, "line": "[laughing] Nellie, really, nine fifty?", "order": 49592, "speaker": "Robert California"},
  {"season": 8, "episode": 20, "scene": 2, "line": "Oh, here's what happened. Seven forty-six, my alarm clock goes off. I hear it. Whack the snooze.", "order": 49593, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 20, "scene": 2, "line": "Ah.", "order": 49594, "speaker": "Robert California"},
  {"season": 8, "episode": 20, "scene": 2, "line": "Nine minutes. Bzz! Off it goes again. Whack! Seven more times I did that. Bzz, whack. Bzz, whack! By the time I got up, it was ten minutes ago. No willpower. That is my curse!", "order": 49595, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 20, "scene": 2, "line": "[laughs] I've never believed willpower was very important in a woman.", "order": 49596, "speaker": "Robert California"},
  {"season": 8, "episode": 20, "scene": 2, "line": "Ah ha!", "order": 49597, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 20, "scene": 2, "line": "I don't think you would have shown up to work nearly an hour late for no reason.", "order": 49598, "speaker": "Robert California"},
  {"season": 8, "episode": 20, "scene": 2, "line": "Oh, I assure you, I would have done. I did, and I will keep doing it.", "order": 49599, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 20, "scene": 2, "line": "No, no, no, no. There's something going on. Some stress in your life.", "order": 49600, "speaker": "Robert California"},
  {"season": 8, "episode": 20, "scene": 2, "line": "Well, yes, uh... there is the whole moving to Scranton nonsense. Thirty boxes arrived yesterday from England and two trunks from Florida. Then I have to move in to my apartment in this dreadful, God knows where it is backwater suburb of a suburb, and I mean, no offense but are there a lot of Irish people living around here?", "order": 49601, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 20, "scene": 2, "line": "[in unison] Yes.", "order": 49602, "speaker": "Kevin Malone & Meredith Palmer"},
  {"season": 8, "episode": 20, "scene": 2, "line": "Ugh! I hate that! No offense.", "order": 49603, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 20, "scene": 2, "line": "None taken.", "order": 49604, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 20, "scene": 2, "line": "Actually, Nellie, this monologue you're delivering is very offensive.", "order": 49605, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 20, "scene": 2, "line": "oh! Ay, carumba! The natives are getting restless!", "order": 49606, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 20, "scene": 2, "line": "Who's a native?", "order": 49607, "speaker": "Stanley Hudson"},
  {"season": 8, "episode": 20, "scene": 2, "line": "Uh, excuse me, the tone here is getting quite hostile. I would appreciate it if you would keep that stuff on the basketball court. [everyone groans]", "order": 49608, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 20, "scene": 2, "line": "What does that mean?", "order": 49609, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 20, "scene": 2, "line": "Okay, okay.", "order": 49610, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 20, "scene": 2, "line": "If you'd let me finish- Or the squash court, or the Supreme Court. Hmm?", "order": 49611, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 20, "scene": 2, "line": "Nellie?", "order": 49612, "speaker": "Robert California"},
  {"season": 8, "episode": 20, "scene": 2, "line": "Yes, Robert!", "order": 49613, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 20, "scene": 2, "line": "You're clearly under a lot of... stress with the moving and the work situation you've found yourself in.", "order": 49614, "speaker": "Robert California"},
  {"season": 8, "episode": 20, "scene": 2, "line": "Yeah.", "order": 49615, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 20, "scene": 2, "line": "Let's help her out, shall we? Go above and beyond today to show her some of that warm Scranton hospitality. Jim. Dwight. Take the day. Help Nellie move those boxes into her new place.", "order": 49616, "speaker": "Robert California"},
  {"season": 8, "episode": 20, "scene": 2, "line": "Why Jim?", "order": 49617, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 20, "scene": 2, "line": "The rest of you, let's throw a party at the end of the day to welcome the newest member of our little community.", "order": 49618, "speaker": "Robert California"},
  {"season": 8, "episode": 20, "scene": 3, "line": "I'm sorry, we're throwing a party for someone because they're being horrible?", "order": 49619, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 20, "scene": 4, "line": "I'm sorry, we're taking work time to move someone's personal belongings into their new apartment.", "order": 49620, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 20, "scene": 5, "line": "I'm still not sure why this woman is even here.", "order": 49621, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 20, "scene": 6, "line": "Why is she here?", "order": 49622, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 20, "scene": 7, "line": "[sings] Two crazy kids on the journey of life.", "order": 49623, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 20, "scene": 7, "line": "[sings] Going to Pennsylvania.", "order": 49624, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 20, "scene": 8, "line": "On the road with my new girlfriend. But first, a small pit stop to break up with my current girlfriend who is at her parents' cabin in southern Pennsylvania.", "order": 49625, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 20, "scene": 8, "line": "Oh. That must be nice.", "order": 49626, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 20, "scene": 8, "line": "Mm-hmm. It's a beautiful place. Great place to let her down easy.", "order": 49627, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 20, "scene": 8, "line": "Oh, no, no, I meant that it must be nice to have parents.", "order": 49628, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 20, "scene": 8, "line": "Oh. Hmm.", "order": 49629, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 20, "scene": 9, "line": "[sings] Time to have a little kiss!", "order": 49630, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 20, "scene": 9, "line": "No.", "order": 49631, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 20, "scene": 9, "line": "Mm. Sorry. Right. No kisses till the breakup is official.", "order": 49632, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 20, "scene": 9, "line": "Yes.", "order": 49633, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 20, "scene": 9, "line": "I believe in that.", "order": 49634, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 20, "scene": 9, "line": "Yes. So do I.", "order": 49635, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 20, "scene": 9, "line": "I think that's important. That is important. Bummer, but important.", "order": 49636, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 20, "scene": 9, "line": "Important.", "order": 49637, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 20, "scene": 9, "line": "Mm-hmm.", "order": 49638, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 20, "scene": 10, "line": "I can't believe he's making us throw a party for her.", "order": 49639, "speaker": "Phyllis Vance"},
  {"season": 8, "episode": 20, "scene": 10, "line": "I know, right?", "order": 49640, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 20, "scene": 10, "line": "She's always late, she's always rude...", "order": 49641, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 20, "scene": 10, "line": "It kinda makes me want to throw a really bad party.", "order": 49642, "speaker": "Phyllis Vance"},
  {"season": 8, "episode": 20, "scene": 10, "line": "Yeah.", "order": 49643, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 20, "scene": 10, "line": "On purpose.", "order": 49644, "speaker": "Phyllis Vance"},
  {"season": 8, "episode": 20, "scene": 10, "line": "Phyllis!", "order": 49645, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 20, "scene": 10, "line": "[laughs] We should do it right here in the break room. [they giggle]", "order": 49646, "speaker": "Angela Martin"},
  {"season": 8, "episode": 20, "scene": 10, "line": "Order carrot cake. [laughter]", "order": 49647, "speaker": "Phyllis Vance"},
  {"season": 8, "episode": 20, "scene": 11, "line": "...and Jessica, just so you know, this is way more about my love for Erin than anything wrong with you.", "order": 49648, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 20, "scene": 11, "line": "Well, Andy, I'm upset, but you did this in the best way possible, and I knew you as a lover and I'll remember you as a gentleman. Okay, that was one minute and ten seconds.", "order": 49649, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 20, "scene": 11, "line": "Consider it nailed.", "order": 49650, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 20, "scene": 11, "line": "[vocalizing] I think we should try again. This time, worst case scenario.", "order": 49651, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 20, "scene": 11, "line": "Okay, here we go. Jessica, I'm really sorry. I just need you to know-", "order": 49652, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 20, "scene": 11, "line": "[whining] What?", "order": 49653, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 20, "scene": 11, "line": "I just need you to know-", "order": 49654, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 20, "scene": 11, "line": "What is it? I didn't sleep well last night. [they laugh]", "order": 49655, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 20, "scene": 12, "line": "[lifting box] Ugh! Ow.", "order": 49656, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 20, "scene": 12, "line": "Nellie, that reminds me. Do you want this chair in the bedroom? 'Cause to be honest, I don't think it's gonna fit through the door.", "order": 49657, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 20, "scene": 12, "line": "Don't listen to Jim. Have you ever seen him play Tetris? 'Oh, I think I'll just use this line horizontally. Oh, I had no idea what a gift this line is.'", "order": 49658, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 20, "scene": 12, "line": "That was one time-", "order": 49659, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 20, "scene": 12, "line": "I will get the chair in. Watch the Great Schrutini work his magic.", "order": 49660, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 20, "scene": 12, "line": "Oh, no. Really? Magic? No, no, no. Let's not go there. No, nothing is more repellant than magicians. Bunch of grown men waving wands, pulling bunnies out of body crevices. Magicians are repulsive. Next topic.", "order": 49661, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 20, "scene": 12, "line": "[cell phone rings] Excuse me, one second. Hi.", "order": 49662, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 20, "scene": 12, "line": "Hey, what's up?", "order": 49663, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 20, "scene": 12, "line": "Ah, nothing, just hauling some cube with Dwight.", "order": 49664, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 20, "scene": 12, "line": "[shouting] Haulin' cube!", "order": 49665, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 20, "scene": 12, "line": "That's moving boxes. We just came up with the term to make it sound cooler.", "order": 49666, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 20, "scene": 12, "line": "So we're planning this party for Nellie, and we're gonna make it really bad.", "order": 49667, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 20, "scene": 12, "line": "Sounds like every other party.", "order": 49668, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 20, "scene": 12, "line": "No. We're gonna make it like a prank. Like order bad food, give her this passive-aggressive card.", "order": 49669, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 20, "scene": 12, "line": "Oh! Hire a magician.", "order": 49670, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 20, "scene": 12, "line": "What?", "order": 49671, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 20, "scene": 12, "line": "Trust me.", "order": 49672, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 20, "scene": 12, "line": "[laughs] Okay.", "order": 49673, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 20, "scene": 13, "line": "Wow. There's a lot of cars here. This is just weird.", "order": 49674, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 20, "scene": 13, "line": "[knocks on car window] Andy! Hey, what are you doing here?", "order": 49675, "speaker": "Lauren"},
  {"season": 8, "episode": 20, "scene": 13, "line": "Hey, Lauren. Look at you.", "order": 49676, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 20, "scene": 13, "line": "Well, I know. Look at me.", "order": 49677, "speaker": "Lauren"},
  {"season": 8, "episode": 20, "scene": 13, "line": "I was just on my way back from this business trip, thought I'd stop and say hi to Jessica.", "order": 49678, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 20, "scene": 13, "line": "Aww! [clicks tongue]", "order": 49679, "speaker": "Lauren"},
  {"season": 8, "episode": 20, "scene": 13, "line": "Why is Erin with me? That's a great question. She is my coworker, and she needed a ride because she totaled her car.", "order": 49680, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 20, "scene": 13, "line": "Oh. God.", "order": 49681, "speaker": "Lauren"},
  {"season": 8, "episode": 20, "scene": 13, "line": "So, I'm Erin. Hi.", "order": 49682, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 20, "scene": 13, "line": "Yep. This is Erin.", "order": 49683, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 20, "scene": 13, "line": "Nice to meet you. [Erin gasps at another girl outside her window] Well, Jess went out for a run but she'll be back soon. Hey ladies, Andy's here!", "order": 49684, "speaker": "Lauren"},
  {"season": 8, "episode": 20, "scene": 13, "line": "Okay Andy, that is a bachelorette party, this is Jessica's friends and they've been drinking during the daytime. Should we go?", "order": 49685, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 20, "scene": 13, "line": "Yeah. Uh, you know, we had such a quick window to make this work, I think we should probably just-", "order": 49686, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 20, "scene": 13, "line": "Oh, Jess! [claps] Look who stopped by after his business trip!", "order": 49687, "speaker": "Lauren"},
  {"season": 8, "episode": 20, "scene": 13, "line": "Andy! Hey!", "order": 49688, "speaker": "Jessica"},
  {"season": 8, "episode": 20, "scene": 14, "line": "I wonder if king-sized sheets are called presidential-sized in England. I really should have a Tweeter account.", "order": 49689, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 20, "scene": 14, "line": "Yes, you should.", "order": 49690, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 20, "scene": 14, "line": "[finds shoe box] Ooh.", "order": 49691, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 20, "scene": 14, "line": "[reads] 'Nellie, don't open, stupid. Love, Nellie.'", "order": 49692, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 20, "scene": 14, "line": "I have to see these shoes.", "order": 49693, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 20, "scene": 14, "line": "I doubt that they're sh-", "order": 49694, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 20, "scene": 14, "line": "Oh!", "order": 49695, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 20, "scene": 14, "line": "Whoa. Who is this guy?", "order": 49696, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 20, "scene": 14, "line": "Here's the two of them taking a hike. I'm guessing he's some kind of friend.", "order": 49697, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 20, "scene": 14, "line": "Boyfriend.", "order": 49698, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 20, "scene": 14, "line": "Here's the two of them kissing at a beach and kissing at the Eiffel Tower. I'm guessing he's some kind of close romantic friend.", "order": 49699, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 20, "scene": 14, "line": "Like a boyfriend?", "order": 49700, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 20, "scene": 14, "line": "You read my mind.", "order": 49701, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 20, "scene": 14, "line": "Yeah. Whoa. Here's one with his face whited out.", "order": 49702, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 20, "scene": 14, "line": "Hmm. Maybe someone threw a pie in his face. New theory- he's a hated Italian politician.", "order": 49703, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 20, "scene": 14, "line": "Better theory. This is her ex-boyfriend and they went through some kind of painful breakup.", "order": 49704, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 20, "scene": 14, "line": "[walks in] Oh.", "order": 49705, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 20, "scene": 14, "line": "Oh, Nellie. I'm so sorry. We were just...", "order": 49706, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 20, "scene": 14, "line": "I see you've discovered Benjamin. That's what I call my box full of photos of Henry.", "order": 49707, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 20, "scene": 14, "line": "Why not call...", "order": 49708, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 20, "scene": 14, "line": "Shh!", "order": 49709, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 20, "scene": 15, "line": "God. We owned this flat together. Then one morning, just like that, he was gone. He ran off with the waitress at our favorite restaurant.", "order": 49710, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 20, "scene": 15, "line": "That's awful. What kind of restaurant.", "order": 49711, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 20, "scene": 15, "line": "Dwight!", "order": 49712, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 20, "scene": 15, "line": "I couldn't afford the flat myself. So I sold it at a loss. Ah, but what's ten years of bliss down the drain compared with the thrill of starting over with nothing and no one?", "order": 49713, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 20, "scene": 15, "line": "I'm so sorry. These must be very painful memories.", "order": 49714, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 20, "scene": 15, "line": "If it would help you to forget, I could hit you in the brain stem with this candlestick.", "order": 49715, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 20, "scene": 15, "line": "Thank you. No. Look, Jim, Dwight, please, don't tell anyone about this.", "order": 49716, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 20, "scene": 15, "line": "No, no. Of course. Just one quick question. Was this um, Halloween, or...", "order": 49717, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 20, "scene": 15, "line": "God, no. That's the most embarrassing thing of all this. What kind of fool gets her heart broken by bloody stage magician?", "order": 49718, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 20, "scene": 16, "line": "[in the phone] You know what, Pam? I was thinking maybe we should change course here. Let's give up on all this mean stuff.", "order": 49719, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 20, "scene": 16, "line": "What? No! No, I just had this brilliant idea- everyone loved it. You don't have a copyright on pranks. I might be better at this than you.", "order": 49720, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 20, "scene": 16, "line": "No, that's not what I-", "order": 49721, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 20, "scene": 16, "line": "Jim, could you give us a hand?", "order": 49722, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 20, "scene": 16, "line": "Absolutely. Yeah. [whispers] Call it off, Pam. Call it off, okay? It's way more complicated than you think. Cancel the magician. Trust me.", "order": 49723, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 20, "scene": 16, "line": "Oh, okay. Okay, I will. [hangs up phone]", "order": 49724, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 20, "scene": 16, "line": "Pam, we have a great idea.", "order": 49725, "speaker": "Angela Martin"},
  {"season": 8, "episode": 20, "scene": 16, "line": "Listen to this.", "order": 49726, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 20, "scene": 16, "line": "We're going to have the fluorescent lights flickering. It's gonna make everyone sick.", "order": 49727, "speaker": "Angela Martin"},
  {"season": 8, "episode": 20, "scene": 16, "line": "Or what if...", "order": 49728, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 20, "scene": 16, "line": "Okay.", "order": 49729, "speaker": "Angela Martin"},
  {"season": 8, "episode": 20, "scene": 16, "line": "We discuss... [Angela and Phyllis giggle] the idea of doing the party totally normal. Like, not mean. Just a regular party. [giggling stops] Not mean.", "order": 49730, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 20, "scene": 16, "line": "I knew she'd crack! I wanted to leave you out, you know.", "order": 49731, "speaker": "Angela Martin"},
  {"season": 8, "episode": 20, "scene": 16, "line": "We're in far too deep. We can't change course at this point.", "order": 49732, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 20, "scene": 16, "line": "What are you talking about? Yes we can.", "order": 49733, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 20, "scene": 16, "line": "What I mean to say is we don't want to.", "order": 49734, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 20, "scene": 16, "line": "Toots, we're not stopping this train, so get off the tracks.", "order": 49735, "speaker": "Phyllis Vance"},
  {"season": 8, "episode": 20, "scene": 17, "line": "[pops balloon] Suck it! [cheers, applause]", "order": 49736, "speaker": "Lauren"},
  {"season": 8, "episode": 20, "scene": 17, "line": "Andy-", "order": 49737, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 20, "scene": 17, "line": "It'll be fine. Just act natural. Do you want a gummy penis?", "order": 49738, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 20, "scene": 17, "line": "No, uh, I'll just have some gummy bears.", "order": 49739, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 20, "scene": 17, "line": "These are delicious.", "order": 49740, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 20, "scene": 17, "line": "But... they're penises.", "order": 49741, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 20, "scene": 17, "line": "And we come to Matthew. The guy who was with his old girlfriend basically the whole time we were together. [party guests groan]", "order": 49742, "speaker": "Lauren"},
  {"season": 8, "episode": 20, "scene": 17, "line": "Ew! Aww! That seems gross at first blush, right? But relationships are always more complicated than you think. I mean, we don't know Matthew's history with this other woman. Maybe she saved his life. [laughs] I don't know. I just- I don't think we should rush to judgment about Matthew. Maybe we don't pop that one.", "order": 49743, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 20, "scene": 17, "line": "No, pop it. Pop it! [Lauren pops the balloon, party guests cheer]", "order": 49744, "speaker": "Party guests"},
  {"season": 8, "episode": 20, "scene": 17, "line": "Hey.", "order": 49745, "speaker": "Jessica"},
  {"season": 8, "episode": 20, "scene": 17, "line": "Hey.", "order": 49746, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 20, "scene": 17, "line": "Sorry. It's kind of a madhouse.", "order": 49747, "speaker": "Jessica"},
  {"season": 8, "episode": 20, "scene": 17, "line": "[grabs Andy] Ahh!", "order": 49748, "speaker": "Megan"},
  {"season": 8, "episode": 20, "scene": 17, "line": "Oh! Ah, Megan.", "order": 49749, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 20, "scene": 17, "line": "You are one of the good ones, nard dog.", "order": 49750, "speaker": "Megan"},
  {"season": 8, "episode": 20, "scene": 17, "line": "Aww.", "order": 49751, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 20, "scene": 17, "line": "No, really. [to Jessica] Where's my Andy?", "order": 49752, "speaker": "Megan"},
  {"season": 8, "episode": 20, "scene": 17, "line": "I dunno.", "order": 49753, "speaker": "Jessica"},
  {"season": 8, "episode": 20, "scene": 17, "line": "Oh, he's out there.", "order": 49754, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 20, "scene": 17, "line": "Aww. Are you going to sing for us?", "order": 49755, "speaker": "Megan"},
  {"season": 8, "episode": 20, "scene": 17, "line": "Well, you guys are doing a pretty good job with the scary yelling, so...", "order": 49756, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 20, "scene": 17, "line": "That's not like you. That's not like you. Come on, sing! Sing!", "order": 49757, "speaker": "Megan"},
  {"season": 8, "episode": 20, "scene": 17, "line": "[singing] By yon bonnie banks and by yon bonnie braes...", "order": 49758, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 20, "scene": 18, "line": "Okay, they're almost here- What? Come on. If you guys are gonna be mean, could you at least be subtle? [rips down ugly photo of Nellie]", "order": 49759, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 20, "scene": 18, "line": "Oh, in the warehouse we use code names for people we want to talk about. Andy was Jelly Roll. Mike was Dennis the Menace. Ryan was douche bag.", "order": 49760, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 20, "scene": 18, "line": "Hey, that's not a code name. That's just an insult.", "order": 49761, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 20, "scene": 18, "line": "Plus everyone would know who you meant.", "order": 49762, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 20, "scene": 18, "line": "Yeah!", "order": 49763, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 20, "scene": 18, "line": "No, that's a great idea. Let's have a code name. How about Mondays? I hate Mondays? Mondays are the worst?", "order": 49764, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 20, "scene": 18, "line": "Nobody's named Monday.", "order": 49765, "speaker": "Angela Martin"},
  {"season": 8, "episode": 20, "scene": 18, "line": "Hey, how about we go with Pam? Simple, easy to remember.", "order": 49766, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 20, "scene": 18, "line": "Cause there's someone already here named Pam.", "order": 49767, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 20, "scene": 18, "line": "Shh, here she comes!", "order": 49768, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 20, "scene": 18, "line": "Hey!", "order": 49769, "speaker": "All"},
  {"season": 8, "episode": 20, "scene": 18, "line": "Welcome to your party.", "order": 49770, "speaker": "Phyllis Vance"},
  {"season": 8, "episode": 20, "scene": 18, "line": "Everybody get comfy now. This first song's over a half hour long. [plays off-tune notes]", "order": 49771, "speaker": "Creed Bratton"},
  {"season": 8, "episode": 20, "scene": 19, "line": "Best gig ever. They asked me to play only originals. I said, 'Have you heard my originals? They're terrible.' They said, 'Even better.' I said, 'I get it. It's an ironic party for Nellie.'", "order": 49772, "speaker": "Creed Bratton"},
  {"season": 8, "episode": 20, "scene": 20, "line": "Maybe we should just go.", "order": 49773, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 20, "scene": 20, "line": "No. I gotta do this. Hey Jessica, could I talk to you?", "order": 49774, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 20, "scene": 20, "line": "Uh, yeah, sure. What's up?", "order": 49775, "speaker": "Jessica"},
  {"season": 8, "episode": 20, "scene": 20, "line": "Uh, maybe we could talk in private?", "order": 49776, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 20, "scene": 20, "line": "Uh, yeah. What is it?", "order": 49777, "speaker": "Jessica"},
  {"season": 8, "episode": 20, "scene": 20, "line": "Well, first just let me say that I hope when I'm done with the sort of ugly bits that we can stay friends.", "order": 49778, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 20, "scene": 20, "line": "You're breaking up with me?", "order": 49779, "speaker": "Jessica"},
  {"season": 8, "episode": 20, "scene": 20, "line": "Uh, no. No. You always do this. You twist my words around. Part of me thinks we should just end this right now.", "order": 49780, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 20, "scene": 20, "line": "Oh my God. Are you leaving me for Erin? You said she wasn't relationship material and she wasn't as good as me, but it's her, isn't it?", "order": 49781, "speaker": "Jessica"},
  {"season": 8, "episode": 20, "scene": 20, "line": "Whoa! That? No. Okay. You want honesty? Super-honesty time. I'm gay.", "order": 49782, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 20, "scene": 20, "line": "What?", "order": 49783, "speaker": "Jessica"},
  {"season": 8, "episode": 20, "scene": 20, "line": "I am gay, and I prefer men.", "order": 49784, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 20, "scene": 20, "line": "I knew it!", "order": 49785, "speaker": "Kenny"},
  {"season": 8, "episode": 20, "scene": 20, "line": "You did not, Kenny!", "order": 49786, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 20, "scene": 20, "line": "You invited me to go shopping with you.", "order": 49787, "speaker": "Kenny"},
  {"season": 8, "episode": 20, "scene": 20, "line": "I like hanging out with you. You're a cool guy. Which proves my point. That I'm gay.", "order": 49788, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 20, "scene": 20, "line": "Andy, you're not gay. I mean, we were... together. And you seemed pretty excitable.", "order": 49789, "speaker": "Jessica"},
  {"season": 8, "episode": 20, "scene": 20, "line": "Well, I was faking it. I had to fake it every time. I had to imagine that I was in a steam room with John Stamos.", "order": 49790, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 20, "scene": 20, "line": "I can't say it doesn't make sense...", "order": 49791, "speaker": "Jessica"},
  {"season": 8, "episode": 20, "scene": 20, "line": "Well... I mean, I was good at hiding it, but-", "order": 49792, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 20, "scene": 20, "line": "Look, it's fine, Andy. I didn't think we were gonna get married or anything. I just... I'm just upset for now.", "order": 49793, "speaker": "Jessica"},
  {"season": 8, "episode": 20, "scene": 20, "line": "Understandable. And I'm really sorry. I really am. [hugs Jessica, grunts] So... we should probably... go.", "order": 49794, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 20, "scene": 21, "line": "This humble but sincere effort is all for our valued new colleague. Excellent.", "order": 49795, "speaker": "Robert California"},
  {"season": 8, "episode": 20, "scene": 21, "line": "To Nellie Bertram, you are a most welcome and friendly presence at our company.", "order": 49796, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 20, "scene": 21, "line": "Cheers.", "order": 49797, "speaker": "Robert California"},
  {"season": 8, "episode": 20, "scene": 21, "line": "Why thank you.", "order": 49798, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 20, "scene": 21, "line": "Pam, on the other hand, is a most unwelcome, entitled, unfriendly presence in our company.", "order": 49799, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 20, "scene": 21, "line": "Cheers.", "order": 49800, "speaker": "All"},
  {"season": 8, "episode": 20, "scene": 21, "line": "Nellie is terrific, but to be honest, every day I imagine how happy I'd be if Pam died. [laughter]", "order": 49801, "speaker": "Angela Martin"},
  {"season": 8, "episode": 20, "scene": 21, "line": "Oh. Well. I feel that as someone who knows Pam only a little bit... enough. A good amount. Not the most, though. I would say that she is misunderstood and that maybe there's some stuff in her past that you guys don't know about that's a little messed up and probably makes her such a torture to work with.", "order": 49802, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 20, "scene": 21, "line": "We hate Pam. We hate Pam.", "order": 49803, "speaker": "Creed Bratton"},
  {"season": 8, "episode": 20, "scene": 21, "line": "We hate Pam! We hate Pam!", "order": 49804, "speaker": "All"},
  {"season": 8, "episode": 20, "scene": 21, "line": "So we've established-", "order": 49805, "speaker": "Magician"},
  {"season": 8, "episode": 20, "scene": 21, "line": "No. No.", "order": 49806, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 20, "scene": 21, "line": "-that you guys hate Pam. Do you ever wish she would just... disappear? [sets off flash paper]", "order": 49807, "speaker": "Magician"},
  {"season": 8, "episode": 20, "scene": 21, "line": "Oh! [laughter, murmuring]", "order": 49808, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 20, "scene": 22, "line": "That was a really rough scene.", "order": 49809, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 20, "scene": 22, "line": "Right? Rough scene.", "order": 49810, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 20, "scene": 22, "line": "Yeah.", "order": 49811, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 20, "scene": 22, "line": "Oh... at least we can kiss now.", "order": 49812, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 20, "scene": 22, "line": "Oh, yeah. [gives him a quick kiss]", "order": 49813, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 20, "scene": 22, "line": "You know, that stuff that I said about you to her... I did- That's just- I had to say it. You know, I was dating her at the time.", "order": 49814, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 20, "scene": 22, "line": "Yeah. Yes, of course. Ah... I feel really tired.", "order": 49815, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 20, "scene": 22, "line": "Yeah.", "order": 49816, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 20, "scene": 22, "line": "Probably from seeing that turkey.", "order": 49817, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 20, "scene": 22, "line": "Oh yeah.", "order": 49818, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 20, "scene": 22, "line": "When we drove by the farm. Oh. Always does it.", "order": 49819, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 20, "scene": 23, "line": "Are you Nellie? Oh, I think you're my volunteer. Come on over here, huh?", "order": 49820, "speaker": "Magician"},
  {"season": 8, "episode": 20, "scene": 23, "line": "Oh, I'll do it. I will volunteer.", "order": 49821, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 20, "scene": 23, "line": "no, Nellie, she-", "order": 49822, "speaker": "Robert California"},
  {"season": 8, "episode": 20, "scene": 23, "line": "I'll do it.", "order": 49823, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 20, "scene": 23, "line": "Oh! Big guy, huh? How's the air up there? Watch out for... birds. [chuckles] All right, let's uh- let's do some card magic. Now, what I want you to do is... I want you to pick a card just by looking at it. Do not say what it is.", "order": 49824, "speaker": "Magician"},
  {"season": 8, "episode": 20, "scene": 23, "line": "[picks a card] It's the four of hearts.", "order": 49825, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 20, "scene": 23, "line": "Oh, no, you-", "order": 49826, "speaker": "Robert California"},
  {"season": 8, "episode": 20, "scene": 23, "line": "Looks like we got a guy from another country here, huh? No hablo el cardo, senor? Hmm? Ha. All right, the card is picked. Now sir, will you please shuffled the deck? [Jim drops the cards] You didn't just do that on purpose, did ya?", "order": 49827, "speaker": "Magician"},
  {"season": 8, "episode": 20, "scene": 23, "line": "[to Dwight] Why is Jim treating the magician poorly?", "order": 49828, "speaker": "Robert California"},
  {"season": 8, "episode": 20, "scene": 23, "line": "Little known fact about me. Before I was a magician, I used to work at a rope factory.", "order": 49829, "speaker": "Magician"},
  {"season": 8, "episode": 20, "scene": 23, "line": "Not true. 'Cause that's not a real place.", "order": 49830, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 20, "scene": 23, "line": "But I never could seem to figure out those knots.", "order": 49831, "speaker": "Magician"},
  {"season": 8, "episode": 20, "scene": 23, "line": "That's not a real knot. When you pull on it, it disappears.", "order": 49832, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 20, "scene": 23, "line": "[yells] What the hell? All right, where's Phyllis? Who's Phyllis? Look. This is really uncool, okay? I put on a clean show here-", "order": 49833, "speaker": "Magician"},
  {"season": 8, "episode": 20, "scene": 23, "line": "Okay scram, wizard.", "order": 49834, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 20, "scene": 23, "line": "What?", "order": 49835, "speaker": "Magician"},
  {"season": 8, "episode": 20, "scene": 23, "line": "You heard me!", "order": 49836, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 20, "scene": 23, "line": "Huh!", "order": 49837, "speaker": "Magician"},
  {"season": 8, "episode": 20, "scene": 23, "line": "Well Nellie, I'm sorry. If I'd known Jim and Pam would use this party as an excuse to abuse a magician-", "order": 49838, "speaker": "Robert California"},
  {"season": 8, "episode": 20, "scene": 23, "line": "Well, I thought it was quite fun. In fact, I think they're brilliant employees, in their own way. Don't you see what I see?", "order": 49839, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 20, "scene": 23, "line": "Interesting. Yes. No, yes, I see that. Great work, team. Great party.", "order": 49840, "speaker": "Robert California"},
  {"season": 8, "episode": 20, "scene": 23, "line": "You think this is a great party? This cake has vegetables in it. Like a salad bar, Robert. How do I get this taste out of my mouth?", "order": 49841, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 20, "scene": 24, "line": "What? Why are we-", "order": 49842, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 20, "scene": 24, "line": "I just gotta do one thing. [leaves car, knocks on door]", "order": 49843, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 20, "scene": 24, "line": "Oh. Hello. Hey everybody, look who's here.", "order": 49844, "speaker": "Megan"},
  {"season": 8, "episode": 20, "scene": 24, "line": "What are you doing here?", "order": 49845, "speaker": "Jessica"},
  {"season": 8, "episode": 20, "scene": 24, "line": "Hi. Super-duper honesty time. I'm not gay. In fact, I'm so not gay, I'm in love with a girl. Her name is Erin Hannon and she's right there. She's sweet, funny and beautiful and total relationship material.", "order": 49846, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 20, "scene": 24, "line": "Why the hell did you come back here?", "order": 49847, "speaker": "Megan"},
  {"season": 8, "episode": 20, "scene": 24, "line": "Go away.", "order": 49848, "speaker": "Jessica"},
  {"season": 8, "episode": 20, "scene": 24, "line": "Get lost!", "order": 49849, "speaker": "Kenny"},
  {"season": 8, "episode": 20, "scene": 24, "line": "Get out!", "order": 49850, "speaker": "Woman"},
  {"season": 8, "episode": 20, "scene": 24, "line": "Okay.", "order": 49851, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 20, "scene": 24, "line": "You're done!", "order": 49852, "speaker": "Lauren"},
  {"season": 8, "episode": 20, "scene": 24, "line": "Bye guys.", "order": 49853, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 20, "scene": 24, "line": "Get out! You ruined my party! Who does that? Are you kidding me? [Andy and Erin run to the car] Yeah, run away!", "order": 49854, "speaker": "Lauren"},
  {"season": 8, "episode": 20, "scene": 24, "line": "You're disgusting!", "order": 49855, "speaker": "Kenny"},
  {"season": 8, "episode": 20, "scene": 24, "line": "I can't believe you're not gay! [Andy and Erin kiss]", "order": 49856, "speaker": "Lauren"},
  {"season": 8, "episode": 20, "scene": 24, "line": "Yeah get out of here.", "order": 49857, "speaker": "Kenny"},
  {"season": 8, "episode": 20, "scene": 24, "line": "Don't come back. [someone throws food at the car]", "order": 49858, "speaker": "Various"},
  {"season": 8, "episode": 20, "scene": 24, "line": "Uh-oh! [laughs, they drive away]", "order": 49859, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 20, "scene": 24, "line": "You don't even know how to drive! Bye Andy! Loser! Nice car!", "order": 49860, "speaker": "Various"},
  {"season": 8, "episode": 20, "scene": 25, "line": "Hey Hank.", "order": 49861, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 20, "scene": 25, "line": "Yes ma'am.", "order": 49862, "speaker": "Hank Tate"},
  {"season": 8, "episode": 20, "scene": 25, "line": "I thought I was very specific about you not letting up a magician.", "order": 49863, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 20, "scene": 25, "line": "Yes ma'am. I got my eyes open.", "order": 49864, "speaker": "Hank Tate"},
  {"season": 8, "episode": 20, "scene": 25, "line": "Well, turns out he actually made it up there.", "order": 49865, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 20, "scene": 25, "line": "What? That ain't right.", "order": 49866, "speaker": "Hank Tate"},
  {"season": 8, "episode": 20, "scene": 25, "line": "Yeah, he came and went.", "order": 49867, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 20, "scene": 25, "line": "Wait a minute! You said it was a magician, right? [Pam nods] You don't think he could've used... it couldn't have been...", "order": 49868, "speaker": "Hank Tate"},
  {"season": 8, "episode": 20, "scene": 25, "line": "Let's just- let's go.", "order": 49869, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 20, "scene": 25, "line": "Night-night.", "order": 49870, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 21, "scene": 1, "line": "Whoo! Wow, it is raining cats and dogs out there. Holy moley.", "order": 49871, "speaker": "Phyllis Vance"},
  {"season": 8, "episode": 21, "scene": 2, "line": "Phyllis says the same twelve clichs every time it rains. So, I promised everyone that if she says them all by noon today, I will send out for hot chocolates.", "order": 49872, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 21, "scene": 3, "line": "So how was the drive in?", "order": 49873, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 21, "scene": 3, "line": "Oh, nobody knows how to drive in the rain.", "order": 49874, "speaker": "Phyllis Vance"},
  {"season": 8, "episode": 21, "scene": 3, "line": "You don't say?", "order": 49875, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 21, "scene": 3, "line": "Yeah. You know the roads are actually the slickest in the first half hour?", "order": 49876, "speaker": "Phyllis Vance"},
  {"season": 8, "episode": 21, "scene": 4, "line": "Oh, the plants are gonna love this.", "order": 49877, "speaker": "Phyllis Vance"},
  {"season": 8, "episode": 21, "scene": 4, "line": "Yeah.", "order": 49878, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 21, "scene": 5, "line": "I actually sleep better when it's raining.", "order": 49879, "speaker": "Phyllis Vance"},
  {"season": 8, "episode": 21, "scene": 5, "line": "Tell me about it.", "order": 49880, "speaker": "Meredith Palmer"},
  {"season": 8, "episode": 21, "scene": 6, "line": "Time's almost up. How many are left?", "order": 49881, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 21, "scene": 6, "line": "Just one. 'This weather makes me want to stay at home, curled up with a good book.'", "order": 49882, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 21, "scene": 6, "line": "Phyllis. This rain... does it make you wanna be doing something?", "order": 49883, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 21, "scene": 6, "line": "What do you mean?", "order": 49884, "speaker": "Phyllis Vance"},
  {"season": 8, "episode": 21, "scene": 6, "line": "You know, like aren't some things just so nice and cozy in the rain?", "order": 49885, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 21, "scene": 6, "line": "Hey, come on.", "order": 49886, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 21, "scene": 6, "line": "Lots of things are cozy in the rain.", "order": 49887, "speaker": "Phyllis Vance"},
  {"season": 8, "episode": 21, "scene": 6, "line": "And that's noon. Exactly. [others groan]", "order": 49888, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 21, "scene": 7, "line": "I mean, normally the rain would make me want to stay home, curled up with a good book. But everybody's being so nice to me today. I'm really happy being here.", "order": 49889, "speaker": "Phyllis Vance"},
  {"season": 8, "episode": 21, "scene": 8, "line": "[singing] My girlfriend's back and there's gonna be trouble-", "order": 49890, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 21, "scene": 8, "line": "Hey la, hey la...", "order": 49891, "speaker": "Andy Bernard & Erin Hannon"},
  {"season": 8, "episode": 21, "scene": 8, "line": "...my girlfriend's back!", "order": 49892, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 21, "scene": 8, "line": "[simultaneously] ...his girlfriend's back! [others cheer and applaud]", "order": 49893, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 21, "scene": 8, "line": "Anyway, I know it's the end of the day. We just wanted to stop by and say hi.", "order": 49894, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 21, "scene": 8, "line": "Welcome home.", "order": 49895, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 21, "scene": 8, "line": "Thank you.", "order": 49896, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 21, "scene": 8, "line": "Hey, Kev.", "order": 49897, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 21, "scene": 8, "line": "Yeah.", "order": 49898, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 21, "scene": 8, "line": "Nice sweater.", "order": 49899, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 21, "scene": 8, "line": "[wearing a Cornell sweater] Thank you. Nellie was nice enough to give it to me. She's sweet. I just wish there was pockets.", "order": 49900, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 21, "scene": 8, "line": "What happened to old salty?", "order": 49901, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 21, "scene": 8, "line": "Nellie let me bobble-ize him. His name is now Captain Mutato.", "order": 49902, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 21, "scene": 9, "line": "I've written quite a bit of X-Men fan fiction. Captain Mutato is half man, half mermaid. So he can fight crime as a man and make love as a mermaid. Most of my writing involves the latter.", "order": 49903, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 21, "scene": 10, "line": "Okey dokey. [knocks on door]", "order": 49904, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 21, "scene": 10, "line": "Yes?", "order": 49905, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 21, "scene": 10, "line": "Whoa. Well, you must be the famous Nellie Bertram I've been hearing all about. I am the famous Andy Bernard you've been hearing all about.", "order": 49906, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 21, "scene": 10, "line": "Oh yes.", "order": 49907, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 21, "scene": 10, "line": "I just want to thank you for jumping in and minding the store during my temporary absence.", "order": 49908, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 21, "scene": 10, "line": "You are most welcome.", "order": 49909, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 21, "scene": 10, "line": "Anyway, now that I'm back, I would love to have my office back, whenever you get a chance.", "order": 49910, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 21, "scene": 10, "line": "No.", "order": 49911, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 21, "scene": 10, "line": "Obviously, we'll figure out the, uh, logistics of moving all this stuff out of here. But, you know, the sooner the better.", "order": 49912, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 21, "scene": 10, "line": "Mm-hmm.", "order": 49913, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 21, "scene": 10, "line": "Get back to normalcy.", "order": 49914, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 21, "scene": 10, "line": "Hmm, no.", "order": 49915, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 21, "scene": 11, "line": "...and then just lay him in his crib, and then bicycle his legs. And then after Jim quiets down, you do the same thing with your baby. [Pam, Jim and Kelly laugh] But if he keeps having problems, just give me a call.", "order": 49916, "speaker": "Ravi"},
  {"season": 8, "episode": 21, "scene": 11, "line": "Oh my gosh, thank you so much. But seriously, we don't want to bother you any more than we already have.", "order": 49917, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 21, "scene": 11, "line": "That's it.", "order": 49918, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 21, "scene": 11, "line": "It's no bother, you guys are our friends.", "order": 49919, "speaker": "Kelly Kapoor"},
  {"season": 8, "episode": 21, "scene": 12, "line": "Ravi, our amazing pediatrician, was asking us if we knew any girls and I said I know the perfect girl.", "order": 49920, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 21, "scene": 12, "line": "Yep. Because Kelly is Indian and... oh, that's it.", "order": 49921, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 21, "scene": 12, "line": "Race had nothing to do with it. I just knew they'd be good together.", "order": 49922, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 21, "scene": 13, "line": "[talks over flashbacks] Kelly has been a handful in the past.", "order": 49923, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 21, "scene": 13, "line": "[cries, slams fist on desk] Why?", "order": 49924, "speaker": "Kelly Kapoor"},
  {"season": 8, "episode": 21, "scene": 13, "line": "[Ryan and Kelly make out in the nook] But she's had a bad influence. She's like an addict. [Ryan and Kelly argue] And I just had to get her clean.", "order": 49925, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 21, "scene": 13, "line": "[Ryan and Kelly make out on Oscar's desk] Get lower.", "order": 49926, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 21, "scene": 14, "line": "Um, also, little tip, never shake the baby.", "order": 49927, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 21, "scene": 14, "line": "Sorry, just to be clear, you're saying do NOT shake the baby.", "order": 49928, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 21, "scene": 14, "line": "Don't shake the baby. Um, a lot of times, parents get frustrated 'cause the baby's crying and they shake the baby. And you got to, um, you can't do that.", "order": 49929, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 21, "scene": 14, "line": "Don't shake our baby?", "order": 49930, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 21, "scene": 14, "line": "Yeah.", "order": 49931, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 21, "scene": 14, "line": "Okay. I'd never heard that before. So, thank you.", "order": 49932, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 21, "scene": 14, "line": "Oh, my God.", "order": 49933, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 21, "scene": 14, "line": "Yeah, I'm glad you said something.", "order": 49934, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 21, "scene": 14, "line": "Me too.", "order": 49935, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 21, "scene": 15, "line": "Kelly and I broke up and she can do whatever she wants. And her new boyfriend seems awesome, if you're into Indian people. I'm not.", "order": 49936, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 21, "scene": 16, "line": "Hey, um, what's the deal with this guy? He's really into Kelly, huh?", "order": 49937, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 21, "scene": 16, "line": "Yeah, they're really great together.", "order": 49938, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 21, "scene": 16, "line": "Maybe we weren't right together, but... it's weird. I'd rather she be alone than with somebody. Is that love?", "order": 49939, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 21, "scene": 16, "line": "That's about it, yeah.", "order": 49940, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 21, "scene": 17, "line": "Nellie! You've been terrific in your interim capacity. But, Andrew is the rightful manager so if you would just give him his office-", "order": 49941, "speaker": "Robert California"},
  {"season": 8, "episode": 21, "scene": 17, "line": "No.", "order": 49942, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 21, "scene": 17, "line": "I'm sorry?", "order": 49943, "speaker": "Robert California"},
  {"season": 8, "episode": 21, "scene": 17, "line": "This is what I was trying to tell you.", "order": 49944, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 21, "scene": 17, "line": "I'm not accustomed to people saying no to me. [laughs]", "order": 49945, "speaker": "Robert California"},
  {"season": 8, "episode": 21, "scene": 17, "line": "Well, Bobby, get accustomed to it because then it feels so good when they finally say yes.", "order": 49946, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 21, "scene": 17, "line": "You're both adults. I'm sure you can figure this out between yourselves.", "order": 49947, "speaker": "Robert California"},
  {"season": 8, "episode": 21, "scene": 18, "line": "I never allow sexual desire to influence a business decision. So I find it best to excuse myself temporarily until I've had a chance to make love and then go back and analyze the situation rationally. Buffett operates the same way.", "order": 49948, "speaker": "Robert California"},
  {"season": 8, "episode": 21, "scene": 19, "line": "I'm gonna count down from five and if you are not out of my office, I'm going to dock your pay one hundred dollars.", "order": 49949, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 21, "scene": 19, "line": "[chuckling] Okay.", "order": 49950, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 21, "scene": 19, "line": "Five... four...", "order": 49951, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 21, "scene": 19, "line": "You can't dock my pay-", "order": 49952, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 21, "scene": 19, "line": "Angela! Dock Andy's pay one hundred dollars.", "order": 49953, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 21, "scene": 19, "line": "On it!", "order": 49954, "speaker": "Angela Martin"},
  {"season": 8, "episode": 21, "scene": 19, "line": "Great. Five, four, three, two, one. Angela, please dock Nellie's pay a hundred dollars. Angela?", "order": 49955, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 21, "scene": 19, "line": "Do you want to go again?", "order": 49956, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 21, "scene": 19, "line": "Angela?", "order": 49957, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 21, "scene": 19, "line": "Let's go again. Five... four...", "order": 49958, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 21, "scene": 19, "line": "Ooh, she's counting again.", "order": 49959, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 21, "scene": 19, "line": "Three... two... one...", "order": 49960, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 21, "scene": 19, "line": "Oh, oh!", "order": 49961, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 21, "scene": 19, "line": "Angela, two hundred dollars!", "order": 49962, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 21, "scene": 19, "line": "You got it.", "order": 49963, "speaker": "Angela Martin"},
  {"season": 8, "episode": 21, "scene": 19, "line": "Seriously, Angela?", "order": 49964, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 21, "scene": 20, "line": "I got a little bit of an anger problem. Got me in some trouble a couple years ago.", "order": 49965, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 21, "scene": 20, "line": "When I see him start to get mad, I just put my hand on his arm like this.", "order": 49966, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 21, "scene": 21, "line": "I know what, let's go... ten thousand dollars! [Andy laughs] Five... four...", "order": 49967, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 21, "scene": 21, "line": "You're just saying numbers. It's meaningless.", "order": 49968, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 21, "scene": 21, "line": "Three...", "order": 49969, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 21, "scene": 21, "line": "It's literally like-", "order": 49970, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 21, "scene": 21, "line": "Two... [Andy runs out of the room] one.", "order": 49971, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 21, "scene": 22, "line": "There you go. [helps Andy in the car] Hey. we're gonna have a nice, hot date.", "order": 49972, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 21, "scene": 23, "line": "Hey. So last night was so not a big deal.", "order": 49973, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 21, "scene": 23, "line": "Oh, yeah, I was just tired.", "order": 49974, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 21, "scene": 23, "line": "We both were. Plus, I was definitely not my normal sexy self.", "order": 49975, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 21, "scene": 23, "line": "Whoa. No. Are you kidding? You were so sexy. Just the thought of you last night, like, crazy turns me on. It just didn't last night.", "order": 49976, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 21, "scene": 23, "line": "Really. It's not a big deal.", "order": 49977, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 21, "scene": 23, "line": "Yeah. I know it's not... a big deal.", "order": 49978, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 21, "scene": 24, "line": "I found this the other day while I was journaling and they reminded me of you.", "order": 49979, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 21, "scene": 24, "line": "Oh, those are from our weekend at the time share.", "order": 49980, "speaker": "Kelly Kapoor"},
  {"season": 8, "episode": 21, "scene": 24, "line": "Yeah, the fractional ownership property. [sigh] Oh, we took this one right before we got in that huge fight.", "order": 49981, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 21, "scene": 24, "line": "God I don't even remember what that fight was about.", "order": 49982, "speaker": "Kelly Kapoor"},
  {"season": 8, "episode": 21, "scene": 24, "line": "You were being really bratty about where we would go out to dinner. But all I remember is how pretty you looked, taking those pictures of me. Anyway, if you want to order prints of your own, I can send you the link.", "order": 49983, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 21, "scene": 25, "line": "I'm in love with Kelly Kapoor. And I don't know how I'm gonna feel tomorrow or the next day or the day after that, but I do know that right here, right now, all I can think about is spending the rest of my life with her. Again, that could change.", "order": 49984, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 21, "scene": 26, "line": "When you lost the manager job-", "order": 49985, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 21, "scene": 26, "line": "Yes?", "order": 49986, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 21, "scene": 26, "line": "Did it affect you outside of the office?", "order": 49987, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 21, "scene": 26, "line": "How?", "order": 49988, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 21, "scene": 26, "line": "I don't know what the technical term is... Penial softiosis?", "order": 49989, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 21, "scene": 26, "line": "Erin, I am so glad that you trusted me. You came to the exact right person for this. No, I have never once experienced anything remotely like that. Never.", "order": 49990, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 21, "scene": 26, "line": "Oh, okay.", "order": 49991, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 21, "scene": 26, "line": "Okay?", "order": 49992, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 21, "scene": 26, "line": "Okay.", "order": 49993, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 21, "scene": 26, "line": "Washington Monument.", "order": 49994, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 21, "scene": 26, "line": "Oh.", "order": 49995, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 21, "scene": 26, "line": "Eiffel Tower.", "order": 49996, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 21, "scene": 26, "line": "Okay, okay.", "order": 49997, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 21, "scene": 27, "line": "I saw you were getting along with Ryan again.", "order": 49998, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 21, "scene": 27, "line": "He's so sweet. He pointed to my latte and he said, 'Kelly, that will be the color of our children.'", "order": 49999, "speaker": "Kelly Kapoor"},
  {"season": 8, "episode": 21, "scene": 27, "line": "Yeah, he's so great. Remember how it felt when he cheated on you though?", "order": 50000, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 21, "scene": 27, "line": "Which time?", "order": 50001, "speaker": "Kelly Kapoor"},
  {"season": 8, "episode": 21, "scene": 28, "line": "I am not going to let Kelly throw her life away on Ryan. And it has nothing to do with access to my pediatrician. Why you would even ask or were going to ask, because I- I felt like that question was coming.", "order": 50002, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 21, "scene": 29, "line": "One of Toby's eyes is getting smaller. So there's that. Gabe bragged about having an extra ticket to the air show this weekend and Meredith said she was interested and then Gabe said immediately that his friend might be taking the ticket. So I'll keep you posted on that.", "order": 50003, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 21, "scene": 30, "line": "Every day I brief Nellie on what's going on in the office. Most of it's irrelevant. But a good informer doesn't judge what's worth passing on.", "order": 50004, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 21, "scene": 31, "line": "Oh, and Andy lost his masculinity, so congratulations on that.", "order": 50005, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 21, "scene": 31, "line": "What do you mean?", "order": 50006, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 21, "scene": 31, "line": "Erin made it clear to me that he was unable to perform sexually last night. By contrast, I went to sleep with an erection so large it was like I was wearing no blanket at all. Wow, I knew you'd win, but you just demolished him! I'm a little bit jealous, actually. Reduced him to a mere ant.", "order": 50007, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 21, "scene": 32, "line": "I just wanted to take the man's job, not his manhood.", "order": 50008, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 21, "scene": 33, "line": "Okay, I would like to invite everyone into the conference room.", "order": 50009, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 21, "scene": 33, "line": "You can't call a meeting. [laughs] But I would like to have a meeting in the conference room right now! So let's get in the conference room. Thank you. Good. Thank you for coming to my meeting.", "order": 50010, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 21, "scene": 33, "line": "Okay, if you would like to take a seat we can get started.", "order": 50011, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 21, "scene": 33, "line": "Oh! Can't do it 'cause I gotta run a meeting. So.", "order": 50012, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 21, "scene": 33, "line": "No.", "order": 50013, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 21, "scene": 33, "line": "Wanted to talk to all of you guys about [reads whiteboard] importance... and know that each and every one of you is vitally important.", "order": 50014, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 21, "scene": 33, "line": "Andrew, not everyone here is important. And the word is 'impotence.'", "order": 50015, "speaker": "Robert California"},
  {"season": 8, "episode": 21, "scene": 33, "line": "Which is important in its own way. So if you'd just like to take a seat, Andy.", "order": 50016, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 21, "scene": 34, "line": "Take a man's job, but leave him his balls.' Margaret Thatcher said that... probably. Don't know. Don't read. Didn't see the movie.", "order": 50017, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 21, "scene": 35, "line": "This meeting is not about any one person in particular. It is a human problem.", "order": 50018, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 21, "scene": 35, "line": "It's not just a human problem. Flounders frequently experience impotence, especially when converting from male to female and then back again to male.", "order": 50019, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 21, "scene": 35, "line": "Nellie, does the person affected by this want us to help her in any way in particular?", "order": 50020, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 21, "scene": 35, "line": "Oh, oh, it's not me. No, no. I've never had any problem in that arena. And I have been with several older men.", "order": 50021, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 21, "scene": 35, "line": "[in unison] How old?", "order": 50022, "speaker": "Robert California & Creed Bratton"},
  {"season": 8, "episode": 21, "scene": 35, "line": "Jinx. Buy me some Coke.", "order": 50023, "speaker": "Creed Bratton"},
  {"season": 8, "episode": 21, "scene": 35, "line": "Dwight told me about it earlier.", "order": 50024, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 21, "scene": 35, "line": "Dwight!", "order": 50025, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 21, "scene": 35, "line": "What?", "order": 50026, "speaker": "All"},
  {"season": 8, "episode": 21, "scene": 35, "line": "You promised!", "order": 50027, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 21, "scene": 35, "line": "Dwight couldn't get it up for Nellie?", "order": 50028, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 21, "scene": 35, "line": "No, no, no, no, no, It's not me. I'm gonna prove it right here and now. [thrusts his pelvis]", "order": 50029, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 21, "scene": 35, "line": "What are you doing?", "order": 50030, "speaker": "Angela Martin"},
  {"season": 8, "episode": 21, "scene": 35, "line": "What is this?", "order": 50031, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 21, "scene": 35, "line": "Stop that! Dwight! Stop that, stop it.", "order": 50032, "speaker": "Angela Martin"},
  {"season": 8, "episode": 21, "scene": 35, "line": "Fine, it was me. I couldn't- I had a problem with Erin last night. Happy?", "order": 50033, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 21, "scene": 36, "line": "I had a lot on my mind last night. And I didn't perform. Okay? It happens to plenty of guys. It's usually not followed by a giant workplace discussion and an interview.", "order": 50034, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 21, "scene": 37, "line": "Every guy in this room has been touched by this affliction. Tuna? T-dog?", "order": 50035, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 21, "scene": 37, "line": "Um...", "order": 50036, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 21, "scene": 37, "line": "Uh... I have other issues. I'm terrible at math. Overweight. You- you're in great shape. A lot better shape than I am, tell you what.", "order": 50037, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 21, "scene": 37, "line": "It seems Andy is the only one with this problem. Fascinating.", "order": 50038, "speaker": "Robert California"},
  {"season": 8, "episode": 21, "scene": 37, "line": "Fascinating.", "order": 50039, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 21, "scene": 37, "line": "I don't really see what the problem is. Erin doesn't even like sex, remember? You said it feels like getting tackled by a skeleton.", "order": 50040, "speaker": "Gabe Lewis"},
  {"season": 8, "episode": 21, "scene": 37, "line": "Chumbo, come on, help me out. Any problems with Little Chumbo?", "order": 50041, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 21, "scene": 37, "line": "Tip-top shape.", "order": 50042, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 21, "scene": 37, "line": "Oh... Stanley?", "order": 50043, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 21, "scene": 37, "line": "No. Um-um.", "order": 50044, "speaker": "Stanley Hudson"},
  {"season": 8, "episode": 21, "scene": 37, "line": "Creed, you are a thousand years old.", "order": 50045, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 21, "scene": 37, "line": "Haven't heard any complaints. Wouldn't care if I did.", "order": 50046, "speaker": "Creed Bratton"},
  {"season": 8, "episode": 21, "scene": 37, "line": "Okay, so I-", "order": 50047, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 21, "scene": 37, "line": "Actually Andy, I think maybe we experienced it.", "order": 50048, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 21, "scene": 37, "line": "What happened?", "order": 50049, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 21, "scene": 37, "line": "That a couple of times-", "order": 50050, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 21, "scene": 37, "line": "Couple of times.", "order": 50051, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 21, "scene": 37, "line": "Jim, maybe you could tell us about one of those times.", "order": 50052, "speaker": "Robert California"},
  {"season": 8, "episode": 21, "scene": 37, "line": "Yeah Jim.", "order": 50053, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 21, "scene": 37, "line": "Yeah Jim, get it out there.", "order": 50054, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 21, "scene": 37, "line": "Um... I don't... Uh, yes, that time that it was very late. Uh, we'd had sex so many times already, I was exhausted...", "order": 50055, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 21, "scene": 37, "line": "Okay, stop.", "order": 50056, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 21, "scene": 37, "line": "I was very drunk.", "order": 50057, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 21, "scene": 37, "line": "If it makes you feel any better, I never had an orgasm until I was forty-two. And then when I did, it lasted 'til I was forty-four.", "order": 50058, "speaker": "Phyllis Vance"},
  {"season": 8, "episode": 21, "scene": 38, "line": "Forty-three was ju- I got nothing done. [laughs]", "order": 50059, "speaker": "Phyllis Vance"},
  {"season": 8, "episode": 21, "scene": 39, "line": "I've read- and I don't know anything about this personally- but they say prostate stimulation can help.", "order": 50060, "speaker": "Gabe Lewis"},
  {"season": 8, "episode": 21, "scene": 39, "line": "Ugh.", "order": 50061, "speaker": "Angela Martin"},
  {"season": 8, "episode": 21, "scene": 39, "line": "This is just, uh- This American Life, I think I heard it on.", "order": 50062, "speaker": "Gabe Lewis"},
  {"season": 8, "episode": 21, "scene": 39, "line": "No, that's absolutely the case.", "order": 50063, "speaker": "Robert California"},
  {"season": 8, "episode": 21, "scene": 39, "line": "I know, right?", "order": 50064, "speaker": "Gabe Lewis"},
  {"season": 8, "episode": 21, "scene": 39, "line": "You know, my rectal electro-ejaculator is rated for bovine use only but I could let you rent it.", "order": 50065, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 21, "scene": 39, "line": "Andy, why don't you tell us about the best erection of your life? Or does anyone else have any remarkable erections they'd like to share? [Creed, Kevin and Meredith raise their hands]", "order": 50066, "speaker": "Robert California"},
  {"season": 8, "episode": 21, "scene": 39, "line": "Ooh, ooh!", "order": 50067, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 21, "scene": 39, "line": "Wow, what are we talking about?", "order": 50068, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 21, "scene": 39, "line": "Toby, doesn't HR have some rules against talking about this kind of stuff?", "order": 50069, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 21, "scene": 39, "line": "Erin, HR is a joke. I can't do anything about anything.", "order": 50070, "speaker": "Toby Flenderson"},
  {"season": 8, "episode": 21, "scene": 39, "line": "The most important thing to remember is not to stress about this. Stress just makes it worse, and then you stress more. And that's a vicious spiral.", "order": 50071, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 21, "scene": 40, "line": "I almost didn't come in today. [laughs]", "order": 50072, "speaker": "Robert California"},
  {"season": 8, "episode": 21, "scene": 41, "line": "[on the phone] Dad, don't think of it as a demotion. Just think of it as a promotion to a lower level. I don't think you have to tell your friends anything. It hasn't been decided yet.", "order": 50073, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 21, "scene": 41, "line": "Erin, on phone memos you're writing the date American style. Month, day, year. I prefer it day, month, year. Small, bigger, biggest. Oh, sexual innuendo. Not intentional.", "order": 50074, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 21, "scene": 41, "line": "Shut up. Shut up! I am sick of your dumb opinions. And if you don't like the way that I take phone messages, here! [throws phone] Take 'em yourself!", "order": 50075, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 21, "scene": 41, "line": "Oh, and another thing! Our sex life is none of your businesses!", "order": 50076, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 21, "scene": 41, "line": "And Andy is the manager, not Nellie!", "order": 50077, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 21, "scene": 41, "line": "Stop protecting me! I'm a man- I can protect myself! This is misdirected anger and I'm sorry! I don't mean to lash out at you! There's a lot coming up right now, all at once! [into phone] Dad go to hell, I'm taller than you!", "order": 50078, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 21, "scene": 41, "line": "Okay, just calm down.", "order": 50079, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 21, "scene": 41, "line": "You are not the manager. I earned that job. I was personally chosen after Robert was chosen and quit.", "order": 50080, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 21, "scene": 41, "line": "Andy, why don't you just take a seat?", "order": 50081, "speaker": "Robert California"},
  {"season": 8, "episode": 21, "scene": 41, "line": "Why don't you take a seat, you idiot? [throws chair]", "order": 50082, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 21, "scene": 41, "line": "And why don't you take all your stupid memos and your stupid pens and your dumb caramels?", "order": 50083, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 21, "scene": 41, "line": "And your stupid face! [grabs Nellie's photo off the wall and smashes it on the ground] And your stupid office! [punches hole in the wall] Ah!", "order": 50084, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 21, "scene": 41, "line": "He does not like that wall.", "order": 50085, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 21, "scene": 42, "line": "Maybe we'll get sent to anger management together.", "order": 50086, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 21, "scene": 42, "line": "That would actually be cool. I'd love for you to meet some of the guys.", "order": 50087, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 21, "scene": 42, "line": "Andy, Erin, you can join us now.", "order": 50088, "speaker": "Robert California"},
  {"season": 8, "episode": 21, "scene": 43, "line": "Hey, I hear you been bad-mouthing me to Kelly.", "order": 50089, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 21, "scene": 43, "line": "All I did was remind her that you used to treat her badly.", "order": 50090, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 21, "scene": 43, "line": "Well that's your opinion and it's her opinion, but it's not my opinion. If you have something bad to say to me, Pam, say it to my face.", "order": 50091, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 21, "scene": 43, "line": "Fair enough. Um... I don't think you're a very good person. And forgive me, but I feel like I've said this to you before: I don't like you very much.", "order": 50092, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 21, "scene": 43, "line": "Well a lot of people would say that I'm a better match for Kelly than Ravi is.", "order": 50093, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 21, "scene": 43, "line": "Oh come on, Ryan. Really? Ravi's way better.", "order": 50094, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 21, "scene": 43, "line": "For Kelly?", "order": 50095, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 21, "scene": 43, "line": "Yeah. Man, you're insane right now. Ravi's the whole package.", "order": 50096, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 21, "scene": 43, "line": "I've never met Ravi personally, but I'm gonna go ahead and say, just having knowing you a short while, Brian, that I prefer Ravi. And again, I've never even met the guy.", "order": 50097, "speaker": "Nate Nickerson"},
  {"season": 8, "episode": 21, "scene": 43, "line": "Kelly, I wrote you the most amazing love poem. But I can't even read it. My heart couldn't handle it.", "order": 50098, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 21, "scene": 43, "line": "Oh, no, no, no.", "order": 50099, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 21, "scene": 43, "line": "Oh, come on.", "order": 50100, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 21, "scene": 43, "line": "Read it, please. I love amazing poetry.", "order": 50101, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 21, "scene": 43, "line": "No. It would cause me too much pain.", "order": 50102, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 21, "scene": 43, "line": "Read through the pain. Be strong.", "order": 50103, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 21, "scene": 43, "line": "This poem would crush you!", "order": 50104, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 21, "scene": 43, "line": "Ryan, go away! She found herself a beautiful boyfriend!", "order": 50105, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 21, "scene": 43, "line": "Yeah, man, he's absolutely gorgeous! Please leave her alone.", "order": 50106, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 21, "scene": 43, "line": "You see, Kelly? Our love scares them. It screws up their cookie-cutter world.", "order": 50107, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 21, "scene": 44, "line": "Ravi makes me incredibly happy. And Ryan puts me through so much drama. So I guess I just have to decide which of those is more important to me.", "order": 50108, "speaker": "Kelly Kapoor"},
  {"season": 8, "episode": 21, "scene": 45, "line": "Andy, we're going to go with Nellie as manager and put you back on the sales team. I promise you, in time, we'll all laugh about this incident. I already think it's kind of funny. [chuckles]", "order": 50109, "speaker": "Robert California"},
  {"season": 8, "episode": 21, "scene": 45, "line": "No.", "order": 50110, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 21, "scene": 45, "line": "Excuse me?", "order": 50111, "speaker": "Robert California"},
  {"season": 8, "episode": 21, "scene": 45, "line": "I'm saying no.", "order": 50112, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 21, "scene": 45, "line": "Well you can't say no.", "order": 50113, "speaker": "Robert California"},
  {"season": 8, "episode": 21, "scene": 45, "line": "No.", "order": 50114, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 21, "scene": 46, "line": "[sees Ryan on the horse] Oh, boo! Boo! Boo to you! Boo!", "order": 50115, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 21, "scene": 46, "line": "You are toxic! You are toxic!", "order": 50116, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 21, "scene": 46, "line": "Boo!", "order": 50117, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 21, "scene": 46, "line": "Hey, hey. Okay.", "order": 50118, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 21, "scene": 46, "line": "Kelly, I have a few things to say to you, so please don't interrupt.", "order": 50119, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 21, "scene": 46, "line": "I wasn't going to interrupt.", "order": 50120, "speaker": "Kelly Kapoor"},
  {"season": 8, "episode": 21, "scene": 46, "line": "Well you do a lot, so just don't. Thank you. I know that I haven't always treated you the way that you, for whatever reason, feel you deserve to be treated. But I want to marry you, Kelly Kapoor. Maybe not today, maybe not tomorrow, but someday, and probably.", "order": 50121, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 21, "scene": 46, "line": "Barf! Ugh, you suck!", "order": 50122, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 21, "scene": 46, "line": "What is your problem? [to horse wrangler] Hey, hey, hey, can we turn this back around quickly, please? This is very important to me.", "order": 50123, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 21, "scene": 46, "line": "Turn around. Turn around. Turn around!", "order": 50124, "speaker": "Wrangler"},
  {"season": 8, "episode": 21, "scene": 46, "line": "Can we turn this [bleep]-ing thing around? Thank you.", "order": 50125, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 21, "scene": 46, "line": "She don't wanna turn around.", "order": 50126, "speaker": "Wrangler"},
  {"season": 8, "episode": 21, "scene": 46, "line": "Thank you. Kelly, I can't promise you that we'll always stay together. I can't promise you that I'll never cheat on you. Nor should I. Modern marriages aren't built that way. Men aren't built that way. There's a very interesting article I can email to you. But I can tell you this. Even if the odds are fifty-fifty that we'll break up within the week, I wanna roll those dice. I love you, Kelly.", "order": 50127, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 21, "scene": 46, "line": "Ryan.", "order": 50128, "speaker": "Kelly Kapoor"},
  {"season": 8, "episode": 21, "scene": 46, "line": "Will you roll those dice with me?", "order": 50129, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 21, "scene": 46, "line": "You mean so much to me. But, I love Ravi and I choose him.", "order": 50130, "speaker": "Kelly Kapoor"},
  {"season": 8, "episode": 21, "scene": 46, "line": "No you don't.", "order": 50131, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 21, "scene": 46, "line": "I do.", "order": 50132, "speaker": "Kelly Kapoor"},
  {"season": 8, "episode": 21, "scene": 46, "line": "No you don't.", "order": 50133, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 21, "scene": 46, "line": "I hope we can still be friends. Can I have a hug?", "order": 50134, "speaker": "Kelly Kapoor"},
  {"season": 8, "episode": 21, "scene": 46, "line": "What? [they hug]", "order": 50135, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 21, "scene": 46, "line": "That was really cool. [they make out]", "order": 50136, "speaker": "Kelly Kapoor"},
  {"season": 8, "episode": 21, "scene": 46, "line": "Ugh. Eww!", "order": 50137, "speaker": "Angela Martin"},
  {"season": 8, "episode": 21, "scene": 46, "line": "Well, it's good to see Kelly's maturing.", "order": 50138, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 21, "scene": 46, "line": "Oh God!", "order": 50139, "speaker": "Angela Martin"},
  {"season": 8, "episode": 21, "scene": 47, "line": "Look, Andrew, we can discuss the specifics of the job.", "order": 50140, "speaker": "Robert California"},
  {"season": 8, "episode": 21, "scene": 47, "line": "No.", "order": 50141, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 21, "scene": 47, "line": "It's the apology. I really have to insist.", "order": 50142, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 21, "scene": 47, "line": "[British accent] No.", "order": 50143, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 21, "scene": 47, "line": "Stop saying no.", "order": 50144, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 21, "scene": 47, "line": "No.", "order": 50145, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 21, "scene": 47, "line": "Andrew, if you say no one more time, you're fired. So... is there anything else you wanna say?", "order": 50146, "speaker": "Robert California"},
  {"season": 8, "episode": 21, "scene": 47, "line": "No.", "order": 50147, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 21, "scene": 48, "line": "I can't describe it. I just, for the first time in a long time, I actually feel in control. I feel... alive.", "order": 50148, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 21, "scene": 48, "line": "Here- [grabs the box Andy's holding]", "order": 50149, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 21, "scene": 48, "line": "Ah- da, da, da, da. I got it.", "order": 50150, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 21, "scene": 48, "line": "But you hurt your hand.", "order": 50151, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 21, "scene": 48, "line": "I... Got... It.", "order": 50152, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 21, "scene": 48, "line": "Right now?", "order": 50153, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 21, "scene": 48, "line": "Yes.", "order": 50154, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 21, "scene": 49, "line": "You're not the least bit curious to hear a poem straight from Ryan's soul?", "order": 50155, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 21, "scene": 49, "line": "Not at all, can we go?", "order": 50156, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 21, "scene": 49, "line": "Kapoor and ka-desperate, he watches.'", "order": 50157, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 21, "scene": 49, "line": "Second line.", "order": 50158, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 21, "scene": 49, "line": "He is a drifter out to sea.'", "order": 50159, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 21, "scene": 50, "line": "And when the Indian Ocean calms, one speck of white remains in waters cold and Kelly green.'", "order": 50160, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 21, "scene": 50, "line": "It's just so dumb. [chokes up] But when he describes himself as a child, lost on the life raft...", "order": 50161, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 21, "scene": 51, "line": "Uh, Ryan can never know.", "order": 50162, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 22, "scene": 1, "line": "[pacing back and forth.] No, no, no, no, no, no, no! No, no, no, no, no, no.", "order": 50163, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 22, "scene": 1, "line": "Okay fine. Ryan, something the matter?", "order": 50164, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 22, "scene": 1, "line": "Smokey's dead.", "order": 50165, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 22, "scene": 1, "line": "Smokey, the bear?", "order": 50166, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 22, "scene": 1, "line": "Smokey Robinson Pam. He died like an hour ago, I guess I'm the first to know.", "order": 50167, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 22, "scene": 1, "line": "Wow That's terrible, I really liked him.", "order": 50168, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 22, "scene": 1, "line": "Oh you liked him? That's nice. Did you like when he changed the course of American music like two or three times? Did you like that tracks of my tears is maybe the last true love song ever written? I'm glad you liked him Jim. I am completely devastated right now.", "order": 50169, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 22, "scene": 1, "line": "Well, I second that emotion.", "order": 50170, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 22, "scene": 1, "line": "Huh?", "order": 50171, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 22, "scene": 1, "line": "I know a few of his songs, but what were his big ones?", "order": 50172, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 22, "scene": 1, "line": "Oh God, Nellie! What wasn't his? I mean, um, 'Tracks of my Tears'?", "order": 50173, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 22, "scene": 1, "line": "Yeah", "order": 50174, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 22, "scene": 1, "line": "Um... God, so many, Nellie!", "order": 50175, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 22, "scene": 1, "line": "No, no, no, 'Tracks of My Tears' and what else? What are some more? What's one more?", "order": 50176, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 22, "scene": 1, "line": "Okay, I'm not... I'm not playing this game, Pam. Not today.", "order": 50177, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 22, "scene": 1, "line": "I don't think you love Smokey Robinson. I think you're just doing all of this to prove how deep you are about music", "order": 50178, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 22, "scene": 1, "line": "Okay, I'm sorry I'm not a fan of Jason Mraz or the Beatles.", "order": 50179, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 22, "scene": 1, "line": "You don't like the Beatles?", "order": 50180, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 22, "scene": 1, "line": "That's... That's not the point!", "order": 50181, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 22, "scene": 1, "line": "Eleanor Rigby? Paperback writer?", "order": 50182, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 22, "scene": 1, "line": "Okay, you know, you always think you have time to see these legends before they go. What was I so busy doing?", "order": 50183, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 22, "scene": 1, "line": "It says here this Smokey Robinson dead thing was a hoax. It's on CNN as of two minutes ago.", "order": 50184, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 22, "scene": 1, "line": "Okay well, that's a relief!", "order": 50185, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 22, "scene": 1, "line": "Wow! Look at that! It says he's actually playing State College. That's only three hours away!", "order": 50186, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 22, "scene": 1, "line": "Oh my God, Ryan! That's perfect! You have to go!", "order": 50187, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 22, "scene": 1, "line": "Tickets are 250 bucks.", "order": 50188, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 22, "scene": 1, "line": "250 dollars is nothing to the world's biggest Smokey Robinson fan.", "order": 50189, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 22, "scene": 1, "line": "Yeah... Who's opening?", "order": 50190, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 22, "scene": 1, "line": "Paul Anka.", "order": 50191, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 22, "scene": 1, "line": "Paul Anka?! How can they make the Smoke Man play with someone like that? I don't think I can see this.", "order": 50192, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 22, "scene": 1, "line": "Okay, you could just show up late though.", "order": 50193, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 22, "scene": 1, "line": "How much is parking, like 30 bucks? That's not what Smokey would have wanted. Does want.", "order": 50194, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 22, "scene": 1, "line": "Tears of a clown!", "order": 50195, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 22, "scene": 1, "line": "Don't call me a clown, Pam. You're better than that!", "order": 50196, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 22, "scene": 2, "line": "Looking good, RC!", "order": 50197, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 22, "scene": 2, "line": "Ugh, I hate ties! I feel like I'm being strangled like I'm at some erotic asphyxiation sex club over on I-84. The red room say? Or Dominick's?", "order": 50198, "speaker": "Robert California"},
  {"season": 8, "episode": 22, "scene": 2, "line": "Robert! The senator was going to wear dark brown tonight... I'm sure it will be fine.", "order": 50199, "speaker": "Angela Martin"},
  {"season": 8, "episode": 22, "scene": 3, "line": "My husband is sponsoring a fundraiser tonight for local dog shelters! Robert California bought two tables for everyone here! These people were lucky to get seats because it is going to be a who's who of the northern 22nd district!", "order": 50200, "speaker": "Angela Martin"},
  {"season": 8, "episode": 22, "scene": 4, "line": "I don't want to alarm people, but there is a distinct chance that we are all about to be killed", "order": 50201, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 22, "scene": 4, "line": "Well, as long as you don't want to alarm people...", "order": 50202, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 22, "scene": 4, "line": "What's going on?", "order": 50203, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 22, "scene": 4, "line": "There is a disgruntled ex-employee sitting in his car in the parking lot.", "order": 50204, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 22, "scene": 4, "line": "Oh that's Andy. He's just hanging out", "order": 50205, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 22, "scene": 4, "line": "That's how workplace tragedies always begin. A middle aged white male 'hanging out'. Call the cops!", "order": 50206, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 22, "scene": 4, "line": "Dwight, I don't think he's going to hurt anybody.", "order": 50207, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 22, "scene": 4, "line": "How do you? I mean, why do you think he's there?", "order": 50208, "speaker": "Phyllis Vance"},
  {"season": 8, "episode": 22, "scene": 4, "line": "I don't even know what kind of weapon he has. Could be a knife, could be a gun. Could be a series of guns all lined up to shoot parallel. I'm going up to the roof. [Grabs bag.] And I'm gonna bring my gym bag just in case.", "order": 50209, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 22, "scene": 4, "line": "I knew this would happen! Everybody told me if I moved to America, I'd be murdered.", "order": 50210, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 22, "scene": 5, "line": "Hey!", "order": 50211, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 22, "scene": 5, "line": "Hey, just wanted to say hi and hear you say everything is normal. Maybe video tape you saying that so that everyone upstairs can see.", "order": 50212, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 22, "scene": 5, "line": "We think you might kill Robert.", "order": 50213, "speaker": "Angela Martin"},
  {"season": 8, "episode": 22, "scene": 5, "line": "What?", "order": 50214, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 22, "scene": 5, "line": "because he fired you which means apparently you're living in your car now.", "order": 50215, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 22, "scene": 5, "line": "guys, everything is fine! I'm just here to pick up Erin. We're going to the Fundraiser.", "order": 50216, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 22, "scene": 5, "line": "Oh, great! That sounds good!", "order": 50217, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 22, "scene": 5, "line": "Wait, what? You're going to the fundraiser tonight? That's going to be weird.", "order": 50218, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 22, "scene": 5, "line": "Why would that be weird?", "order": 50219, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 22, "scene": 5, "line": "It's going to be super weird, he just fired you last week.", "order": 50220, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 22, "scene": 5, "line": "Andy's just coming as my date.", "order": 50221, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 22, "scene": 5, "line": "Hey, I hear you! I hope you're right. It just seems like it's going to be really, really weird.", "order": 50222, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 22, "scene": 6, "line": "[hears knocks on door.] Come in!", "order": 50223, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 22, "scene": 6, "line": "You really re-did Andy's office, huh?", "order": 50224, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 22, "scene": 6, "line": "Yes, cut out the clutter. Very simple, very minimal.", "order": 50225, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 22, "scene": 6, "line": "I need you to sign these, we got a shipment going out.", "order": 50226, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 22, "scene": 6, "line": "How are things in the warehouse?", "order": 50227, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 22, "scene": 6, "line": "You could go downstairs and ask them?", "order": 50228, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 22, "scene": 6, "line": "The warehouse isn't downstairs? [laughs.] Is it? Is it?", "order": 50229, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 22, "scene": 6, "line": "Who knew, right?", "order": 50230, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 22, "scene": 6, "line": "[speechless.] I...", "order": 50231, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 22, "scene": 7, "line": "Tonight could be the night that Darryl and I go from casual work friends to actual good friends. The only thing standing in our way is the contempt he seems to feel for me.", "order": 50232, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 22, "scene": 8, "line": "Robert, the senator and I wanted to stop by and say hello.", "order": 50233, "speaker": "Angela Martin"},
  {"season": 8, "episode": 22, "scene": 8, "line": "Hello! Did anyone order a blast from the past, with a side order of sexy?", "order": 50234, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 22, "scene": 8, "line": "Oh man, this is weird!", "order": 50235, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 22, "scene": 8, "line": "Andrew. [Robert extends his hand.]", "order": 50236, "speaker": "Robert California"},
  {"season": 8, "episode": 22, "scene": 8, "line": "Oh, Wanna shake my hand, huh? Cause I want to shake your body! [hugs Robert and laughs.] I had you, I had you!", "order": 50237, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 22, "scene": 9, "line": "[points back and forth at the multiple cameras] Where do I look? It's been so long since I did one of these things? Okay, alright! What's the question? How am I doing? Umm... Great!", "order": 50238, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 22, "scene": 10, "line": "Creed, I just bid twenty dollars on six Jiu-Jitsu lessons. No one's raping this guy!", "order": 50239, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 22, "scene": 10, "line": "Well, I don't want to get raped [picks up clipboard] Twenty-Two!", "order": 50240, "speaker": "Creed Bratton"},
  {"season": 8, "episode": 22, "scene": 10, "line": "No! It was my idea to not be raped!", "order": 50241, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 22, "scene": 10, "line": "[Dwight enters] Wait, you think Jiu-Jitsu classes cost $22 [grabs clipboard.] If you're going to guess the price, you might as well try to be halfway accurate. $180!", "order": 50242, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 22, "scene": 10, "line": "Whoa, Dwight, I don't think you understand.", "order": 50243, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 22, "scene": 10, "line": "You guess the price, you win the prize. Have you never been to a Quaker fair before? God!", "order": 50244, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 22, "scene": 11, "line": "So Dwight doesn't understand silent auctions. I guess he's the stupid guy in the office, huh? Up till now, we didn't have one!", "order": 50245, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 22, "scene": 12, "line": "What haven't I been doing? Gosh, just today I was working on this rock opera that I'm writing, though it feels more like I'm receiving it than writing it.", "order": 50246, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 22, "scene": 12, "line": "Wow, that's exciting! But until you firm up the idea, you might want to keep it between us.", "order": 50247, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 22, "scene": 12, "line": "The hero lives in this dystopian future and he flies around in a spaceship that's shaped like a treble clef. And he has to sing his heart out to destroy all evil.", "order": 50248, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 22, "scene": 12, "line": "Sounds like you're doing alright.", "order": 50249, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 22, "scene": 12, "line": "Ehh, a little better than alright, actually. Really good!", "order": 50250, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 22, "scene": 13, "line": "Oh, this guy is having a breakdown.", "order": 50251, "speaker": "Ryan Howard"},
  {"season": 8, "episode": 22, "scene": 14, "line": "You know, Oscar, I really had no idea you were so passionate about animals.", "order": 50252, "speaker": "Senator"},
  {"season": 8, "episode": 22, "scene": 14, "line": "my dog Gerald is my life.", "order": 50253, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 22, "scene": 14, "line": "really? Well, if you want to get involved, call me. This is my cell. I'm more likely to pick up night, say after 9... Excuse me. [Walks away.]", "order": 50254, "speaker": "Senator"},
  {"season": 8, "episode": 22, "scene": 15, "line": "This confirms three things: I'm right about the senator, I still got it, and poor Angela.", "order": 50255, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 22, "scene": 16, "line": "[telling story to Nellie.] I stepped in right away and start--", "order": 50256, "speaker": "Robert California"},
  {"season": 8, "episode": 22, "scene": 16, "line": "Bobby! Bobbo! You're a rock opera guy, right? You like rock operas?", "order": 50257, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 22, "scene": 16, "line": "Well...", "order": 50258, "speaker": "Robert California"},
  {"season": 8, "episode": 22, "scene": 16, "line": "You gotta check out this thing I'm working on, it's really cool. There's this character Thomas Oregon, and he wants to destroy all the guitars in the world because he realizes that music is the one thing he can't control.", "order": 50259, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 22, "scene": 16, "line": "So, Thomas Oregon is an evil figure?", "order": 50260, "speaker": "Robert California"},
  {"season": 8, "episode": 22, "scene": 16, "line": "Evil! [chuckles.] although he's humanized in the end because he weeps uncontrolably and pees in his pants.", "order": 50261, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 22, "scene": 16, "line": "and the hero, who's that based on?", "order": 50262, "speaker": "Robert California"},
  {"season": 8, "episode": 22, "scene": 16, "line": "Me, I guess. [sings.] We're flying so high, we're crackin' the sky! Gonna fly out of this dome my girlfriend and I!", "order": 50263, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 22, "scene": 16, "line": "Hey jabroni. Show some class.", "order": 50264, "speaker": "Meredith Palmer"},
  {"season": 8, "episode": 22, "scene": 16, "line": "She's right, Andy. you're being a jabroni", "order": 50265, "speaker": "Stanley Hudson"},
  {"season": 8, "episode": 22, "scene": 16, "line": "You're being a Thomas Oregon!", "order": 50266, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 22, "scene": 16, "line": "Andrew, I think this may have been a bad idea. Why don't you let me pay for you and Erin to go out to a great romantic dinner tonight?", "order": 50267, "speaker": "Robert California"},
  {"season": 8, "episode": 22, "scene": 16, "line": "Don't need you to pay for me. I'm doing just fine, thank you. Why don't you quit harshing our mellow?", "order": 50268, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 22, "scene": 16, "line": "Andy you should leave. Now.", "order": 50269, "speaker": "Robert California"},
  {"season": 8, "episode": 22, "scene": 16, "line": "[to Waiter.] Excuse me. I would like to purchase two seats at another table, please.", "order": 50270, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 22, "scene": 16, "line": "I'm sorry, the tables are sold as complete units.", "order": 50271, "speaker": "Waiter"},
  {"season": 8, "episode": 22, "scene": 16, "line": "then I'll take a table!", "order": 50272, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 22, "scene": 16, "line": "Okay", "order": 50273, "speaker": "Waiter"},
  {"season": 8, "episode": 22, "scene": 16, "line": "And a high five! [Raises hand, misses high five with waiter.] Oh, let's do this again!", "order": 50274, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 22, "scene": 17, "line": "[waiter is cracking pepper into Andys salad] Oh, yes indeed... When! [Waiter begins walking away.] Whoa, whoa, whoa! You forgot a few salads! [waiter peppers another salad.] When!", "order": 50275, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 22, "scene": 18, "line": "I stumbled into a very dramatic situation. Angelas husband just hit on me!", "order": 50276, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 22, "scene": 18, "line": "Oh my god!", "order": 50277, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 22, "scene": 18, "line": "I know!", "order": 50278, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 22, "scene": 18, "line": "Wait, what? Come on!", "order": 50279, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 22, "scene": 18, "line": "We were talking about animals, he gives me his cell phone number. He was just dying for me to have it.", "order": 50280, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 22, "scene": 18, "line": "Okay, Oscar, I'm not saying you're not dreamy, because you are, but isn't it possible that he was just schmoosing a voter?", "order": 50281, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 22, "scene": 18, "line": "Well, if you would have seen the look he gave me, he wanted to run more than just my vote.", "order": 50282, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 22, "scene": 18, "line": "Okay, what was this look? [Oscar does the look.]", "order": 50283, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 22, "scene": 18, "line": "Whoa!", "order": 50284, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 22, "scene": 18, "line": "What happened, did he do it?", "order": 50285, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 22, "scene": 18, "line": "Are you-- Twice! For real?", "order": 50286, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 22, "scene": 18, "line": "Okay guys, not every glance means something, alright? Life isn't Downton Abbey.", "order": 50287, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 22, "scene": 18, "line": "Life is Downton Abbey.", "order": 50288, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 22, "scene": 18, "line": "Here's what I'm going to do. I'm going to go over there and I'm going to talk to him and I guarantee you he gives out his cell phone to everybody.", "order": 50289, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 22, "scene": 19, "line": "[on phone.] How much do you guys charge for a one full year gym membership? Thank you!", "order": 50290, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 22, "scene": 20, "line": "It's $475! Like candy from a baby!", "order": 50291, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 22, "scene": 21, "line": "Hey... David Wallace!", "order": 50292, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 22, "scene": 21, "line": "Andy Bernard!", "order": 50293, "speaker": "David Wallace"},
  {"season": 8, "episode": 22, "scene": 21, "line": "Hey, how are you?", "order": 50294, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 22, "scene": 21, "line": "How you doing, I'm great! How you doing, are you still with Dunder Mifflin?", "order": 50295, "speaker": "David Wallace"},
  {"season": 8, "episode": 22, "scene": 21, "line": "No, got canned last week.", "order": 50296, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 22, "scene": 21, "line": "Oh, I'm sorry to hear that.", "order": 50297, "speaker": "David Wallace"},
  {"season": 8, "episode": 22, "scene": 21, "line": "No, best thing that ever happened to me.", "order": 50298, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 22, "scene": 21, "line": "That's the attitude to have. You know what, when I got canned, I was lost, right? I poured myself into this ridiculous vacuum for toys called Suck-It.", "order": 50299, "speaker": "David Wallace"},
  {"season": 8, "episode": 22, "scene": 21, "line": "Yikes!", "order": 50300, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 22, "scene": 21, "line": "Yeah, exactly right? Then suddenly out of nowhere, the US military bought the patent from me for twenty million dollars! The point is, forget those guys, k? Move on! Good to see you, Andy!", "order": 50301, "speaker": "David Wallace"},
  {"season": 8, "episode": 22, "scene": 22, "line": "Call me a lame man, but I wish there was some real food here. You know, like hamburgers... Or Oreos... Or a pizza pie or, what's another food that we like?", "order": 50302, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 22, "scene": 22, "line": "Tacos.", "order": 50303, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 22, "scene": 22, "line": "What I wouldn't give for a big mess of tacos right now!", "order": 50304, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 22, "scene": 22, "line": "I can go get us some tacos.", "order": 50305, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 22, "scene": 22, "line": "Brilliant!", "order": 50306, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 22, "scene": 22, "line": "If you loan me some money.", "order": 50307, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 22, "scene": 22, "line": "Yes, I can do that. For, um, for two tacos, we'd probably need about what 20... $20? Or $25? $20?", "order": 50308, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 22, "scene": 22, "line": "30", "order": 50309, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 22, "scene": 22, "line": "$30, yes!", "order": 50310, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 22, "scene": 23, "line": "I've never eaten a taco. I'm not entirely sure what they are. As long as they're not slimy, and please god don't let them have eyes!", "order": 50311, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 22, "scene": 24, "line": "[Jim shakes Senators hand, returns to table.] So?", "order": 50312, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 22, "scene": 24, "line": "Boom! [hands Oscar a business card.]", "order": 50313, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 22, "scene": 24, "line": "This is interesting.", "order": 50314, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 22, "scene": 24, "line": "Hm-hmm.", "order": 50315, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 22, "scene": 24, "line": "what is interesting? I just proved that he gives his cell out to everybody.", "order": 50316, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 22, "scene": 24, "line": "Or you proved that he thinks you're gay.", "order": 50317, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 22, "scene": 24, "line": "He does not think Jim's gay. A gay man would not leave the house wearing those shoes.", "order": 50318, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 22, "scene": 24, "line": "Well, a gay man would not leave the store wearing those shoes!", "order": 50319, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 22, "scene": 24, "line": "Oh, hey! you bought me those shoes!", "order": 50320, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 22, "scene": 25, "line": "Before we bring out our guest of honor tonight, we are very proud to introduce a great philanthropist and a great guy. Robert California!", "order": 50321, "speaker": "Speaker"},
  {"season": 8, "episode": 22, "scene": 25, "line": "Thank you. Why do we love dogs? Want me to tell you why? There is no answer. Our love for them confounds reason!", "order": 50322, "speaker": "Robert California"},
  {"season": 8, "episode": 22, "scene": 25, "line": "can you believe this guy?", "order": 50323, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 22, "scene": 25, "line": "The state senator, Robert Lipton, loves dogs. he asked me if I loved dogs. you know what I said? Yes! [laughter.]", "order": 50324, "speaker": "Robert California"},
  {"season": 8, "episode": 22, "scene": 25, "line": "Not a joke. That was not even a joke.", "order": 50325, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 22, "scene": 25, "line": "Bella here was a therapy dog for ten years. when her owner passed away she came to this organization for placement. But people don't often adopt older dogs, so Bella and eleven heroes like her are being cared for by our generous volunteers because frankly nobody else will", "order": 50326, "speaker": "Robert California"},
  {"season": 8, "episode": 22, "scene": 25, "line": "I will! I will take all of those dogs!", "order": 50327, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 22, "scene": 25, "line": "Andy, that's very kind--", "order": 50328, "speaker": "Robert California"},
  {"season": 8, "episode": 22, "scene": 25, "line": "No, no, no. This guy can talk and talk all he wants, but it's not that complicated.", "order": 50329, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 22, "scene": 25, "line": "Andy why don't we discuss this at a--", "order": 50330, "speaker": "Robert California"},
  {"season": 8, "episode": 22, "scene": 25, "line": "No, no, no, it's about being there for someone after it's become inconvenient for them to be around. Hello everyone, I am Andy Bernard and I am going to take that bitch home! That is a female dog reference. [Points at himself] This bitch understands loyalty! Sassy human reference [grabs dog] thank you, I will take Bella and every single one of her friends!", "order": 50331, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 22, "scene": 25, "line": "Oh God!", "order": 50332, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 22, "scene": 25, "line": "Mr. Bernard, right this way.", "order": 50333, "speaker": "Senator"},
  {"season": 8, "episode": 22, "scene": 25, "line": "Okay! Hope you all learned something!", "order": 50334, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 22, "scene": 26, "line": "Huey is going to need this medication once every 90 minutes. You can administer it orally, but he's going to puke it up. So, other end is best. Don't split up Daisy and Mojo because daisy will start to, uh... I was going to say bark, but it's more of a scream.", "order": 50335, "speaker": "Vet"},
  {"season": 8, "episode": 22, "scene": 26, "line": "I'm so ready to love all of these animals. This one's even bonding with me already.", "order": 50336, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 22, "scene": 26, "line": "Uh, no. Kenny's a therapy dog. He apparently thinks you're in some kind of emotional crisis.", "order": 50337, "speaker": "Vet"},
  {"season": 8, "episode": 22, "scene": 26, "line": "Stupid dog.", "order": 50338, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 22, "scene": 27, "line": "Well, if you would like to talk about this some more, my office is always open! So here's my office number, and my cell number.", "order": 50339, "speaker": "Senator"},
  {"season": 8, "episode": 22, "scene": 27, "line": "Thank you!", "order": 50340, "speaker": "Meredith Palmer"},
  {"season": 8, "episode": 22, "scene": 28, "line": "Well, looks like he really did just want to talk about the issues. i'm Sorry Oscar.", "order": 50341, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 22, "scene": 28, "line": "sorry about what? There's nothing to be sorry about here.", "order": 50342, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 22, "scene": 29, "line": "No. I'm certainly not disappointed that Angelas husband was not hitting on me. I'd have to be a monster to root for that. A lonely, aging monster.", "order": 50343, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 22, "scene": 30, "line": "Tacos were on sale, eight for $3!", "order": 50344, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 22, "scene": 30, "line": "Oh, great! Okay, oh... Oh, these tacos!", "order": 50345, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 22, "scene": 30, "line": "Hmhmm.", "order": 50346, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 22, "scene": 30, "line": "Mmm! Uhh [Eats Taco incorrectly.] Mmmm... Oh! Hmmph!", "order": 50347, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 22, "scene": 31, "line": "She's trying.", "order": 50348, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 22, "scene": 32, "line": "The winner of the three day trip to the sky top lodge is Dwight Schrute!", "order": 50349, "speaker": "Speaker"},
  {"season": 8, "episode": 22, "scene": 32, "line": "[applause] Yes! Thank you!", "order": 50350, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 22, "scene": 32, "line": "The yearlong membership to Scraton-Bikram Yoga is Dwight Schrute!", "order": 50351, "speaker": "Speaker"},
  {"season": 8, "episode": 22, "scene": 32, "line": "[applause] Oh yes! Yeah!", "order": 50352, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 22, "scene": 32, "line": "A one hour appointment with the kissing magician goes to Dwight Schrute!", "order": 50353, "speaker": "Speaker"},
  {"season": 8, "episode": 22, "scene": 32, "line": "[applause] Oh, oh, oh! Yeah! Ha, ha!", "order": 50354, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 22, "scene": 32, "line": "Well, I think I can save us some time, Dwight Schrute has won every single item here!", "order": 50355, "speaker": "Speaker"},
  {"season": 8, "episode": 22, "scene": 32, "line": "Thank you very much! All I had to do was look at the prices, idiots! Suck it!", "order": 50356, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 22, "scene": 32, "line": "Well, Dwight, yes! You certainly are a record breaker! Your donation is the largest we've ever received at over 34 thousand dollars! [applause]", "order": 50357, "speaker": "Speaker"},
  {"season": 8, "episode": 22, "scene": 32, "line": "Speech! Speech! Speech! Speech!", "order": 50358, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 22, "scene": 32, "line": "Thank you. Wow, I can't tell you what an honor it is to support this thing... And obviously that amount of money is no concern to me whatsoever. But, I want to ask you something: when did it become all about the money. when did it become about the flower arrangements, and the white wine spritser, hmm? and all the dinner rolls. you people should be ashamed of yourselves! How many courses did we have tonight, two? Three maybe, if you choose the pudding? I mean what waste! these tables tarted up like Victorian whores! lets' remember we are all here for the dog society. He's what's important, whatever his name is. Not any of this. So that is going to be my donation to you. Thank you and good night! [throws microphone and runs away]", "order": 50359, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 22, "scene": 33, "line": "Oscar!", "order": 50360, "speaker": "Senator"},
  {"season": 8, "episode": 22, "scene": 33, "line": "Nice to see you again, it was lovely!", "order": 50361, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 22, "scene": 33, "line": "It was lovely! And don't forget to call.", "order": 50362, "speaker": "Senator"},
  {"season": 8, "episode": 22, "scene": 33, "line": "okay", "order": 50363, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 22, "scene": 33, "line": "thanks so much for coming", "order": 50364, "speaker": "Senator"},
  {"season": 8, "episode": 22, "scene": 34, "line": "why does this always happen to me? Ahh! I just feel so bad for Angela", "order": 50365, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 22, "scene": 35, "line": "[applying diaper to dog.] so it just goes on under here like this?", "order": 50366, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 22, "scene": 35, "line": "that's right!", "order": 50367, "speaker": "Vet"},
  {"season": 8, "episode": 22, "scene": 35, "line": "Ugh, oh god!", "order": 50368, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 22, "scene": 35, "line": "Yeah, you never get used to that.", "order": 50369, "speaker": "Vet"},
  {"season": 8, "episode": 22, "scene": 35, "line": "Hey, just wanted to check in. See how you are doing.", "order": 50370, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 22, "scene": 35, "line": "I am so great!", "order": 50371, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 22, "scene": 35, "line": "He's great. we're all great. Twelve dogs. This my life now, I'm a dog nurse.", "order": 50372, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 22, "scene": 35, "line": "Look at that one though, he's smiling!", "order": 50373, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 22, "scene": 35, "line": "Yeah, he should be! It's his first day without a muzzle.", "order": 50374, "speaker": "Vet"},
  {"season": 8, "episode": 22, "scene": 35, "line": "This is the greatest thing that has ever happened to me", "order": 50375, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 22, "scene": 35, "line": "Absolutely, it's awesome--", "order": 50376, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 22, "scene": 35, "line": "Yes--", "order": 50377, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 22, "scene": 35, "line": "I'm sorry, are you guys nuts? He's not doing great! He was fired! This is terrible! This is literally the worst thing that has ever happened to you, not the best.", "order": 50378, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 22, "scene": 35, "line": "You're right. he's right, I am a mess. This whole night I've been trying to convince you guys that I'm fine. I guess I thought that if I could convince you that I'm fine, maybe--", "order": 50379, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 22, "scene": 35, "line": "Maybe you would think it too! [silence.] I'm sorry, it's just I don't get to be in a lot of human conversations.", "order": 50380, "speaker": "Vet"},
  {"season": 8, "episode": 22, "scene": 35, "line": "Okay well, Andy, listen. It's okay if you don't feel totally settled. This is all very fresh.", "order": 50381, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 22, "scene": 35, "line": "Yeah, I mean, admitting you need help is the first step!", "order": 50382, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 22, "scene": 35, "line": "Yes! And also, focusing on the positive. Like I got a lot of good things going on!", "order": 50383, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 22, "scene": 35, "line": "Yeah! Like that rock opera! You could always do that right?", "order": 50384, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 22, "scene": 35, "line": "Yeah, I do. I have that.", "order": 50385, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 22, "scene": 35, "line": "Yeah", "order": 50386, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 22, "scene": 35, "line": "You're going to be alright", "order": 50387, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 22, "scene": 35, "line": "No. No he's not.", "order": 50388, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 22, "scene": 35, "line": "Yes, I am! Thank you, Kev!", "order": 50389, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 22, "scene": 35, "line": "You're welcome!", "order": 50390, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 22, "scene": 36, "line": "Sometimes I feel like every one I work with is an idiot. and by sometimes I mean all times. All the times. Every of the times!", "order": 50391, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 22, "scene": 37, "line": "So some of us ended up adopting those elderly dogs. It's been great!", "order": 50392, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 22, "scene": 37, "line": "Pepper's been getting me out of the house, going on runs.", "order": 50393, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 22, "scene": 37, "line": "My dog Ruby doesn't do anything, she just lays there all day! She's so chill.", "order": 50394, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 22, "scene": 37, "line": "What do you feed her?", "order": 50395, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 22, "scene": 37, "line": "Well I put out Pro Bow-Wow, but she barely touches it. She's so dainty!", "order": 50396, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 22, "scene": 37, "line": "Is she sick? How are her poops?", "order": 50397, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 22, "scene": 37, "line": "Doesn't really poop. It's perfect, nothing to pick up! She just kind of lies there all day like a good girl. I put on the TV for her, but I have to prop her eyes open so she can see it.", "order": 50398, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 22, "scene": 37, "line": "Does she smell?", "order": 50399, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 22, "scene": 37, "line": "She smells horrible. It's unbelievable. But I don't want to put her in the bath, because I'm afraid that she'll drown.", "order": 50400, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 22, "scene": 38, "line": "People seem awful interested in you Ruby. Guess they're just jealous, right! [dog licks Kevins face.] Yeah, that a girl, that a girl! Man that stinks!", "order": 50401, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 23, "scene": 1, "line": "Ugh, man. My delts are blasted. I wish they had a chart for how much protein powder to scoop for a 180 pound man with no fat.", "order": 50402, "speaker": "Gabe Lewis"},
  {"season": 8, "episode": 23, "scene": 1, "line": "Protein powder, huh? You cut it with water? Why don't you just take estrogen? [swallows powder] [coughs] There you go boys. See how papa takes care of you? [kisses bicep] Mwah.", "order": 50403, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 23, "scene": 2, "line": "I remember when people thought biceps were all that. They'd flex them all night at the discotheque.", "order": 50404, "speaker": "Gabe Lewis"},
  {"season": 8, "episode": 23, "scene": 2, "line": "Oh, I bet you think it's all about core, huh?", "order": 50405, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 23, "scene": 2, "line": "Yeah.", "order": 50406, "speaker": "Gabe Lewis"},
  {"season": 8, "episode": 23, "scene": 2, "line": "Oh, please.", "order": 50407, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 23, "scene": 2, "line": "Core's critical. There are four tenets of pilates that I live my life by. One ' lengthen. Two ' elongate.", "order": 50408, "speaker": "Gabe Lewis"},
  {"season": 8, "episode": 23, "scene": 2, "line": "Listen, guys, I think we all want to know the same thing, right? Who's the strongest? Well, there's only one way to solve that ' flat curl contest.", "order": 50409, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 23, "scene": 3, "line": "All right, here we go everybody. May the manliest man win. Go.", "order": 50410, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 23, "scene": 3, "line": "Feast on this, Lewis.", "order": 50411, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 23, "scene": 3, "line": "I love the burn. The burn is where I live.", "order": 50412, "speaker": "Gabe Lewis"},
  {"season": 8, "episode": 23, "scene": 3, "line": "Come on, Gabe, you can't handle his hamstrings. You're getting hypno-thigh-zed.", "order": 50413, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 23, "scene": 3, "line": "Speed set. One. Two.", "order": 50414, "speaker": "Gabe Lewis"},
  {"season": 8, "episode": 23, "scene": 3, "line": "Here, this is for your elbows, for your elbows.", "order": 50415, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 23, "scene": 3, "line": "Oh, thank you.", "order": 50416, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 23, "scene": 3, "line": "You're welcome.", "order": 50417, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 23, "scene": 3, "line": "Five. Six.", "order": 50418, "speaker": "Gabe Lewis"},
  {"season": 8, "episode": 23, "scene": 3, "line": "Quick phone call from you guys, keep going,", "order": 50419, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 23, "scene": 3, "line": "Eight, nine, ten.", "order": 50420, "speaker": "All"},
  {"season": 8, "episode": 23, "scene": 3, "line": "We got it?", "order": 50421, "speaker": "Gabe Lewis"},
  {"season": 8, "episode": 23, "scene": 4, "line": "Very funny Jim.", "order": 50422, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 23, "scene": 4, "line": "Yeah, Jim. Way to mock us for perfecting our bodies.", "order": 50423, "speaker": "Gabe Lewis"},
  {"season": 8, "episode": 23, "scene": 4, "line": "Everyone, conference room, now. [Dwight and Gabe stand up, falling over]", "order": 50424, "speaker": "Robert California"},
  {"season": 8, "episode": 23, "scene": 4, "line": "All right, easy there, grandpa.", "order": 50425, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 23, "scene": 4, "line": "I don't need your help.", "order": 50426, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 23, "scene": 4, "line": "Okay. You don't need my help?", "order": 50427, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 23, "scene": 4, "line": "Here, here' Just'", "order": 50428, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 23, "scene": 5, "line": "Morning.", "order": 50429, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 23, "scene": 5, "line": "Hey.", "order": 50430, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 23, "scene": 5, "line": "Somebody left in such a hurry this morning that she forgot' these.", "order": 50431, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 23, "scene": 5, "line": "Oh.", "order": 50432, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 23, "scene": 5, "line": "You know the only thing more delicious than your feet is the feast that I am going to prepare for everyone.", "order": 50433, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 23, "scene": 5, "line": "Andy, if you're gonna hang out for a while, uh'", "order": 50434, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 23, "scene": 5, "line": "What's this?", "order": 50435, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 23, "scene": 5, "line": "This dumb rule Robert made, he just wants visitors to sign in.", "order": 50436, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 23, "scene": 5, "line": "Is this Robert's attempt to embarrass me?", "order": 50437, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 23, "scene": 5, "line": "No, of course not. It's just ' I think it's like if we make an exception for you, then we have to make an exception for the water guy, and then, it's like, where does it end? So just' [puts visitors tag on Andy]", "order": 50438, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 23, "scene": 6, "line": "Why is it when other people spend all their time at the office, they're rewarded for it, and when I do it, I am told it's a little much? 'Is it because I am not an employee anymore, because that's what it feels like.", "order": 50439, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 23, "scene": 7, "line": "All right, well, enjoy the alumni game.", "order": 50440, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 23, "scene": 7, "line": "Good, we have a deal?", "order": 50441, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 23, "scene": 7, "line": "Thanks Janet.", "order": 50442, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 23, "scene": 7, "line": "Thanks so much Earl.", "order": 50443, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 23, "scene": 7, "line": "Wow, simultaneous sale.", "order": 50444, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 23, "scene": 7, "line": "And they said it couldn't be done. Boom!", "order": 50445, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 23, "scene": 7, "line": "Screw 'em.", "order": 50446, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 23, "scene": 7, "line": "Lot going on guys. What's happening?", "order": 50447, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 23, "scene": 7, "line": "Binghamton branch closed last night and their clients are up for grabs.", "order": 50448, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 23, "scene": 7, "line": "That was a fine branch. Things are really bad under Robert California, I guess. It's like a festival of poo.", "order": 50449, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 23, "scene": 7, "line": "Hey, hey, come on, language.", "order": 50450, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 23, "scene": 7, "line": "Yeah, and we're not interested in your sour grapes, okay? Jim, tell him where he can stick his grapes.", "order": 50451, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 23, "scene": 7, "line": "In the fridge.", "order": 50452, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 23, "scene": 7, "line": "No, Jim, the butt, in his butt.", "order": 50453, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 23, "scene": 7, "line": "Sorry, man, I can't focus on zingers. There's too many potential clients.", "order": 50454, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 23, "scene": 7, "line": "You two better watch yourselves.", "order": 50455, "speaker": "Stanley Hudson"},
  {"season": 8, "episode": 23, "scene": 7, "line": "Yeah, the Syracuse branch can't be happy you're taking New York clients.", "order": 50456, "speaker": "Phyllis Vance"},
  {"season": 8, "episode": 23, "scene": 7, "line": "Shh' shh' [vomits in trash can]", "order": 50457, "speaker": "Robert California"},
  {"season": 8, "episode": 23, "scene": 7, "line": "Robert?", "order": 50458, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 23, "scene": 7, "line": "Why did Binghamton close?", "order": 50459, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 23, "scene": 7, "line": "Can everyone just, please' I had a one-man saturnalia last night, in celebration of the finalization of my divorce. I got into a case of Australian reds, and ' how should I say this ' Columbian whites. What ' what is this about, uh, Binghamton?", "order": 50460, "speaker": "Robert California"},
  {"season": 8, "episode": 23, "scene": 7, "line": "The branch closed. Forever.", "order": 50461, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 23, "scene": 8, "line": "Closing the Binghamton branch never occurred to me before today. Or, I guess, last night. But, in vino veritas as they say, I'm not gonna start doubting my drunken self now.", "order": 50462, "speaker": "Robert California"},
  {"season": 8, "episode": 23, "scene": 9, "line": "I got your voicemail. From ' from last night.", "order": 50463, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 23, "scene": 9, "line": "Wonderful.", "order": 50464, "speaker": "Robert California"},
  {"season": 8, "episode": 23, "scene": 9, "line": "And the answer' is yes, yes, yes, yes, and never. [leaves]", "order": 50465, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 23, "scene": 9, "line": "Pam, when's the last time you lived so intensely that your brain literally couldn't hold the memories in?", "order": 50466, "speaker": "Robert California"},
  {"season": 8, "episode": 23, "scene": 9, "line": "Oh, it was this summer '", "order": 50467, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 23, "scene": 9, "line": "Apparently, I left a phone message for Nellie last night, and I need you to find out what I said.", "order": 50468, "speaker": "Robert California"},
  {"season": 8, "episode": 23, "scene": 9, "line": "Um, I am a little busy.", "order": 50469, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 23, "scene": 9, "line": "Yes, 'course. Why don't you list the things that would keep you from helping me.", "order": 50470, "speaker": "Robert California"},
  {"season": 8, "episode": 23, "scene": 9, "line": "Yeah, I can make you a list.", "order": 50471, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 23, "scene": 9, "line": "Let's do it now. What's number one?", "order": 50472, "speaker": "Robert California"},
  {"season": 8, "episode": 23, "scene": 9, "line": "Why don't I help you now?", "order": 50473, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 23, "scene": 9, "line": "There we go.", "order": 50474, "speaker": "Robert California"},
  {"season": 8, "episode": 23, "scene": 9, "line": "Okay.", "order": 50475, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 23, "scene": 10, "line": "Who the hell are Jim Halpert and Dwight Schrute?", "order": 50476, "speaker": "Harry"},
  {"season": 8, "episode": 23, "scene": 10, "line": "Jim, Dwight, what are your last names?", "order": 50477, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 23, "scene": 10, "line": "And you are'", "order": 50478, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 23, "scene": 10, "line": "Harry Jannerone, Dunder Mifflin Syracuse.", "order": 50479, "speaker": "Harry"},
  {"season": 8, "episode": 23, "scene": 10, "line": "What the hell's all this?", "order": 50480, "speaker": "Harry"},
  {"season": 8, "episode": 23, "scene": 10, "line": "Uh, cherries jubilee over homemade gelato.", "order": 50481, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 23, "scene": 10, "line": "You live well down here in P.A. I want to talk to you guys right now. Oh, and Lloyd Gross too. Which one's that guy?", "order": 50482, "speaker": "Harry"},
  {"season": 8, "episode": 23, "scene": 11, "line": "The salesmen have a commission cap, but we figured out a way around it.", "order": 50483, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 23, "scene": 11, "line": "Lloyd Gross is a fictional salesman we invented to ' how do I put this ' steal from the company. Embezzle. To commit fraud.", "order": 50484, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 23, "scene": 11, "line": "Okay, it sounds sketchy, but it helps us get more money.", "order": 50485, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 23, "scene": 11, "line": "Yes.", "order": 50486, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 23, "scene": 11, "line": "Pam made a drawing of Lloyd. He is a blend of all the salesman. [shows sketch]", "order": 50487, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 23, "scene": 12, "line": "[pointing at Toby] There he is. That's Lloyd.", "order": 50488, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 23, "scene": 12, "line": "Me?", "order": 50489, "speaker": "Toby Flenderson"},
  {"season": 8, "episode": 23, "scene": 12, "line": "Yeah, you.", "order": 50490, "speaker": "Creed Bratton"},
  {"season": 8, "episode": 23, "scene": 13, "line": "Where do you get off crossing state lines?", "order": 50491, "speaker": "Harry"},
  {"season": 8, "episode": 23, "scene": 13, "line": "Now, we're actually a lot closer to Binghamton than you are. Kimosabe.", "order": 50492, "speaker": "Toby Flenderson"},
  {"season": 8, "episode": 23, "scene": 14, "line": "I like to think Lloyd Gross is a no-nonsense guy who doesn't back down from anybody. And he calls people 'Kimosabe'.", "order": 50493, "speaker": "Toby Flenderson"},
  {"season": 8, "episode": 23, "scene": 15, "line": "They're New York. We're New York. Sate line is the dividing line. That's the way it's always been.", "order": 50494, "speaker": "Harry"},
  {"season": 8, "episode": 23, "scene": 15, "line": "There's actually not a rule that says that.", "order": 50495, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 23, "scene": 15, "line": "That's true.", "order": 50496, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 23, "scene": 15, "line": "That's true. There's no rule. You can check the employee handbook. Oh, can I check the employee handbook Lloyd? Well, does it say anything about me choking a man with my bare hands?", "order": 50497, "speaker": "Toby Flenderson"},
  {"season": 8, "episode": 23, "scene": 15, "line": "No.", "order": 50498, "speaker": "Toby Flenderson"},
  {"season": 8, "episode": 23, "scene": 15, "line": "Wait, no? Are you kidding me? You told me there was a rule. I could've choked so many people by now.", "order": 50499, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 23, "scene": 15, "line": "Stay out of my state. It's in your best interest to stay out of my state.", "order": 50500, "speaker": "Harry"},
  {"season": 8, "episode": 23, "scene": 15, "line": "I've seen guys like you. Big guys who like to push the little guys around. Lloyd Gross eats bullies like you for breakfast.", "order": 50501, "speaker": "Toby Flenderson"},
  {"season": 8, "episode": 23, "scene": 15, "line": "Just stay out of New York, Lloyd.", "order": 50502, "speaker": "Harry"},
  {"season": 8, "episode": 23, "scene": 15, "line": "Hey, text from the old wife. Gonna take that. [runs outside]", "order": 50503, "speaker": "Toby Flenderson"},
  {"season": 8, "episode": 23, "scene": 15, "line": "How about this? How about we just ask Robert? Can we all agree that maybe the C.E.O should decide this?", "order": 50504, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 23, "scene": 15, "line": "Robert's here. Look at us. Bickering like schoolgirls, looking around the room for things to hit each other with. I don't think we were doing that.", "order": 50505, "speaker": "Harry"},
  {"season": 8, "episode": 23, "scene": 15, "line": "Chair, lamp, plant, table leg, Jim's leg.", "order": 50506, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 23, "scene": 16, "line": "Where's the Advil, Jim? I think I've hit my limit on the Tylenol ' Oh.", "order": 50507, "speaker": "Robert California"},
  {"season": 8, "episode": 23, "scene": 16, "line": "[Doing dishes] Sorry, not Jim.", "order": 50508, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 23, "scene": 16, "line": "Andrew, what do we have to do to get rid of you? Hire you back and send Erin back to Florida?", "order": 50509, "speaker": "Robert California"},
  {"season": 8, "episode": 23, "scene": 16, "line": "Message received loud and clear. Just have to get the caramelized sugar off the pan before it dries.", "order": 50510, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 23, "scene": 16, "line": "Oh, for god '", "order": 50511, "speaker": "Robert California"},
  {"season": 8, "episode": 23, "scene": 16, "line": "Robert California. What a surprise you're here in Scranton.", "order": 50512, "speaker": "Harry"},
  {"season": 8, "episode": 23, "scene": 16, "line": "Harry'", "order": 50513, "speaker": "Robert California"},
  {"season": 8, "episode": 23, "scene": 16, "line": "So why would you close Binghamton down without a transition plan in place?", "order": 50514, "speaker": "Harry"},
  {"season": 8, "episode": 23, "scene": 16, "line": "How do you mean?", "order": 50515, "speaker": "Robert California"},
  {"season": 8, "episode": 23, "scene": 16, "line": "I forgot, a' a pan, uh '", "order": 50516, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 23, "scene": 16, "line": "No, no, no, no, no, kid, stay there, do your dishes, go ahead.", "order": 50517, "speaker": "Harry"},
  {"season": 8, "episode": 23, "scene": 16, "line": "Harry there is a time for every decision, predetermined many years ago. There's no benefit in questioning why this particular decision seems' so poorly timed.", "order": 50518, "speaker": "Robert California"},
  {"season": 8, "episode": 23, "scene": 16, "line": "Okay, what are you deciding? We get a say.", "order": 50519, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 23, "scene": 16, "line": "Listen, Robert, I don't have time. There's a big client in play. Prestige direct mail solutions '", "order": 50520, "speaker": "Harry"},
  {"season": 8, "episode": 23, "scene": 16, "line": "Don't listen to him.", "order": 50521, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 23, "scene": 16, "line": "Used to be Binghamton's '", "order": 50522, "speaker": "Harry"},
  {"season": 8, "episode": 23, "scene": 16, "line": "Nope.", "order": 50523, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 23, "scene": 16, "line": "I want it, it's mine.", "order": 50524, "speaker": "Harry"},
  {"season": 8, "episode": 23, "scene": 16, "line": "Prestige is ours. Okay, they're responsible for half of the junk mail on the eastern seaboard. We get them. We already put a call into them, Robert.", "order": 50525, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 23, "scene": 16, "line": "We need you to make a decision.", "order": 50526, "speaker": "Harry"},
  {"season": 8, "episode": 23, "scene": 16, "line": "Make a decision.", "order": 50527, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 23, "scene": 16, "line": "I have decided. Neither of you are to have any contact with either Prestige or any other Binghamton client until I have figured out how to divide things up. As Solomon once said' [Andy walks out]", "order": 50528, "speaker": "Robert California"},
  {"season": 8, "episode": 23, "scene": 17, "line": "Some bizarre energy in this place today. Robert is going off the rails, making some funky decisions. Like why is nobody gonna call on Prestige? That is a huge client. [walking to car] I mean, they could give their business to the first person to walk in the door. Could be any idiot. Any idiot at all.", "order": 50529, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 23, "scene": 18, "line": "Shaping a company is, in a sense, similar to training a geisha. You have to mold not merely the physical form, but also the character. The two must harmonize. Are they still there? [camera pans to right, Harry, Dwight, and Jim watching Robert in conference room] They want a decision who gets the big client. Well, they can wait. I'll still be talking about geishas long past their bedtime. You know, I trained as one.", "order": 50530, "speaker": "Robert California"},
  {"season": 8, "episode": 23, "scene": 19, "line": "Is it just me or is our boss a freakin' weirdo? [stands up, walks outside] I'm gonna get some air.", "order": 50531, "speaker": "Harry"},
  {"season": 8, "episode": 23, "scene": 19, "line": "Jim, you know what would be really dastardly? If we snuck out of here and got to the client first.", "order": 50532, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 23, "scene": 19, "line": "[Gets up and looks out Nellie's office window] He's running!", "order": 50533, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 23, "scene": 19, "line": "Damn it!", "order": 50534, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 23, "scene": 19, "line": "Damn it.", "order": 50535, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 23, "scene": 20, "line": "Wha ' what is this supposed to be?", "order": 50536, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 23, "scene": 20, "line": "It's a monkey.", "order": 50537, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 23, "scene": 20, "line": "Jim, great real. This is not a monkey. It's got a hula skirt and a blue nose.", "order": 50538, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 23, "scene": 20, "line": "Hold on, hold on. Is this him?", "order": 50539, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 23, "scene": 20, "line": "What?", "order": 50540, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 23, "scene": 20, "line": "Is that him?", "order": 50541, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 23, "scene": 20, "line": "It's him! Do something! Get out!", "order": 50542, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 23, "scene": 20, "line": "What? What am I gonna do? I don't '", "order": 50543, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 23, "scene": 20, "line": "Go slash his tires! Go dent his hood. [Jim opens passenger door] That's it? Oh, that's great. That's like a five second delay.", "order": 50544, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 23, "scene": 20, "line": "Dwight!", "order": 50545, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 23, "scene": 20, "line": "Come on, let's go! Does this thing have turbo? Nitrous? Hit the nos.", "order": 50546, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 23, "scene": 20, "line": "Nos? You mean like in fast and furious?", "order": 50547, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 23, "scene": 20, "line": "Yeah.", "order": 50548, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 23, "scene": 20, "line": "Oh, yeah, definitely have nos.", "order": 50549, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 23, "scene": 20, "line": "Hit the nos.", "order": 50550, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 23, "scene": 20, "line": "Are you sure?", "order": 50551, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 23, "scene": 20, "line": "Yes.", "order": 50552, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 23, "scene": 20, "line": "Brace yourself. 3' 2'", "order": 50553, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 23, "scene": 20, "line": "Got it. Go.", "order": 50554, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 23, "scene": 20, "line": "1. Here we go! [turns on wipers]", "order": 50555, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 23, "scene": 21, "line": "Hello. Andy Bernard to see the C.E.O.", "order": 50556, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 23, "scene": 21, "line": "Oh, do you have an appointment?", "order": 50557, "speaker": "Receptionist"},
  {"season": 8, "episode": 23, "scene": 21, "line": "No, I do not.", "order": 50558, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 23, "scene": 21, "line": "Okay, I think I can squeeze you in.", "order": 50559, "speaker": "Receptionist"},
  {"season": 8, "episode": 23, "scene": 21, "line": "Seriously? 'Cause I could just be anyone. I mean, I thought I was gonna have to convince you.", "order": 50560, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 23, "scene": 21, "line": "He's really not that busy.", "order": 50561, "speaker": "Receptionist"},
  {"season": 8, "episode": 23, "scene": 21, "line": "Is there someone here to see me?", "order": 50562, "speaker": "Mr. Ramish"},
  {"season": 8, "episode": 23, "scene": 21, "line": "Yes, this man.", "order": 50563, "speaker": "Receptionist"},
  {"season": 8, "episode": 23, "scene": 21, "line": "Come on in. [Andy walks in]", "order": 50564, "speaker": "Mr. Ramish"},
  {"season": 8, "episode": 23, "scene": 22, "line": "So'what do you make of this Robert California guy? I mean, what does a guy like that do on an average weeknight?", "order": 50565, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 23, "scene": 22, "line": "Oh. Oh, I'll tell you what he does.", "order": 50566, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 23, "scene": 22, "line": "[walks in] Hello! Hello, my clucking hens. Got room for another in the roost? Huh? Don't worry, I won't lay an egg.", "order": 50567, "speaker": "Angela Martin"},
  {"season": 8, "episode": 23, "scene": 23, "line": "Robert sent me to take over if Pam fails. If? [laughs]", "order": 50568, "speaker": "Angela Martin"},
  {"season": 8, "episode": 23, "scene": 24, "line": "I have been crunching numbers all day. Math is for boys. I need girl talk.", "order": 50569, "speaker": "Angela Martin"},
  {"season": 8, "episode": 23, "scene": 24, "line": "Did someone say girl talk?", "order": 50570, "speaker": "Gabe Lewis"},
  {"season": 8, "episode": 23, "scene": 25, "line": "Sometimes I wonder if I have ovaries in my scrotum, because I am great at girl talk.", "order": 50571, "speaker": "Gabe Lewis"},
  {"season": 8, "episode": 23, "scene": 26, "line": "Have you guys been watching any good Korean soap operas? I'm pretty deep into Hee-Jungcinderella girl. Although, I definitely fast-forward through the young-Tae storylines.", "order": 50572, "speaker": "Gabe Lewis"},
  {"season": 8, "episode": 23, "scene": 26, "line": "Do you think I'd like that, or is it important to have an Asian fetish?", "order": 50573, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 23, "scene": 26, "line": "Uh, I think you're gonna need to have an Asian fetish. Yeah. [chuckles] It'll be upsetting if you don't.", "order": 50574, "speaker": "Gabe Lewis"},
  {"season": 8, "episode": 23, "scene": 27, "line": "I'm a former paper executive. I know the product. I know the margins. I can save you 25% on your costs.", "order": 50575, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 23, "scene": 27, "line": "Why haven't I heard of you? You got any references?", "order": 50576, "speaker": "Mr. Ramish"},
  {"season": 8, "episode": 23, "scene": 27, "line": "No. I'm a rogue.", "order": 50577, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 23, "scene": 27, "line": "Uh-huh.", "order": 50578, "speaker": "Mr. Ramish"},
  {"season": 8, "episode": 23, "scene": 27, "line": "Which is the best part. That means you will be my first customer and your business will get 100% of my attention. Now' [pulls out business card] I have written down my personal phone number. You call this anytime.", "order": 50579, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 23, "scene": 27, "line": "Every salesman I've ever met has given me his personal phone number.", "order": 50580, "speaker": "Mr. Ramish"},
  {"season": 8, "episode": 23, "scene": 27, "line": "Of course they have. Which is why I'm giving you a key to my house. [gives key to C.E.O] Whatever you need ' anytime, night or day ' you just stop on by.", "order": 50581, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 23, "scene": 27, "line": "You want me to drive to your house if I need paper.", "order": 50582, "speaker": "Mr. Ramish"},
  {"season": 8, "episode": 23, "scene": 27, "line": "Maybe you just want someone to talk to. Maybe' you need a place to crash for a couple of days. My wireless password is eat pray love. Easy to remember.", "order": 50583, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 23, "scene": 28, "line": "Ready? Go! Go, go, go, go, go, go, go, go, go, go, go. [takes off belt, ties doors together]", "order": 50584, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 23, "scene": 28, "line": "[Running] Ahhh! [Slides into elevator]", "order": 50585, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 23, "scene": 28, "line": "You all right?", "order": 50586, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 23, "scene": 28, "line": "Yeah. [Doors about to close, hand stops them] Oh.", "order": 50587, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 23, "scene": 28, "line": "Ah! [Harry walks in]", "order": 50588, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 23, "scene": 28, "line": "[Dwight pushes button for floor two] Dwight, what are you doing?", "order": 50589, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 23, "scene": 28, "line": "Go, go! Take the stairs! Now!", "order": 50590, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 23, "scene": 28, "line": "What are you talking about?", "order": 50591, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 23, "scene": 28, "line": "Just run! Take the stairs!", "order": 50592, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 23, "scene": 28, "line": "I don't even know where the stairs are!", "order": 50593, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 23, "scene": 28, "line": "I'll stall him. Go!", "order": 50594, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 23, "scene": 28, "line": "God!", "order": 50595, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 23, "scene": 28, "line": "[Dwight jumping] What are you doing?", "order": 50596, "speaker": "Harry"},
  {"season": 8, "episode": 23, "scene": 28, "line": "I'm gonna activate the seismic failsafe. We'll be stuck between floors for hours. [pants fall down] Oh. [Jim runs in] My pants fell down.", "order": 50597, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 23, "scene": 28, "line": "What?", "order": 50598, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 23, "scene": 28, "line": "My pants fell down! I don't have a belt!", "order": 50599, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 23, "scene": 29, "line": "[walks into lobby] Hello, sir. Good day. Dwight K. Schrute. Dunder Mifflin, Scranton. Forgive my pants, they fell down. An appointment with Mr. Ramish, please. Right now is fine. No, no, no, I was here first. Dwight K. Schrute. Dunder Mifflin, Scranton.", "order": 50600, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 23, "scene": 29, "line": "What's going on?", "order": 50601, "speaker": "Mr. Ramish"},
  {"season": 8, "episode": 23, "scene": 29, "line": "Well '", "order": 50602, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 23, "scene": 29, "line": "Mr. Ramish, Harry Jannerone. Dunder Mifflin, Syracuse '", "order": 50603, "speaker": "Harry"},
  {"season": 8, "episode": 23, "scene": 29, "line": "I was here first.", "order": 50604, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 23, "scene": 29, "line": "Oh, oh, oh, oh, oh, okay.", "order": 50605, "speaker": "Mr. Ramish"},
  {"season": 8, "episode": 23, "scene": 29, "line": "I already made an appointment' with your secretary.", "order": 50606, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 23, "scene": 29, "line": "Let me stop you all right here. I've already picked a new paper supplier.", "order": 50607, "speaker": "Mr. Ramish"},
  {"season": 8, "episode": 23, "scene": 29, "line": "Wait, it's not D.M Utica, is it?", "order": 50608, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 23, "scene": 29, "line": "No, no, it's not Dunder Mifflin at all actually. It's' Big Red Paper Company.", "order": 50609, "speaker": "Mr. Ramish"},
  {"season": 8, "episode": 23, "scene": 29, "line": "Big Red Paper Company?", "order": 50610, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 23, "scene": 29, "line": "Mm-hmm.", "order": 50611, "speaker": "Mr. Ramish"},
  {"season": 8, "episode": 23, "scene": 30, "line": "Yes, yes, yes!", "order": 50612, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 23, "scene": 31, "line": "Give me a cup of coffee.", "order": 50613, "speaker": "Harry"},
  {"season": 8, "episode": 23, "scene": 31, "line": "Me too. Or do you also have a monopoly on thirst?", "order": 50614, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 23, "scene": 31, "line": "All right, guys. It didn't work out for any of us, so' we're still on the same team. Let me get these.", "order": 50615, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 23, "scene": 31, "line": "No. Let him get his own. It's Syracuse money.", "order": 50616, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 23, "scene": 31, "line": "You know, your partner's got a lotta attitude. But I like that. How long you guys been dating?", "order": 50617, "speaker": "Harry"},
  {"season": 8, "episode": 23, "scene": 31, "line": "Jim couldn't land me in a thousand years.", "order": 50618, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 23, "scene": 31, "line": "But you're saying there's a chance.", "order": 50619, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 23, "scene": 31, "line": "Shut up.", "order": 50620, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 23, "scene": 32, "line": "[Walks into conference room] Hey.", "order": 50621, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 23, "scene": 32, "line": "Hmm.", "order": 50622, "speaker": "Robert California"},
  {"season": 8, "episode": 23, "scene": 32, "line": "I stole Nellie's phone.", "order": 50623, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 23, "scene": 32, "line": "Excellent. Excellent. Though troubling that your first instinct is thievery.", "order": 50624, "speaker": "Robert California"},
  {"season": 8, "episode": 23, "scene": 32, "line": "What do you want from me?", "order": 50625, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 23, "scene": 32, "line": "Now we get to the bottom of Nellie's 'yes, yes, yes, yes, never.'", "order": 50626, "speaker": "Robert California"},
  {"season": 8, "episode": 23, "scene": 32, "line": "Hi, Nell, it's mom. Do keep your chin up. It can't be as bad as you described.", "order": 50627, "speaker": "Phone"},
  {"season": 8, "episode": 23, "scene": 32, "line": "Oh yes it can.", "order": 50628, "speaker": "Robert California"},
  {"season": 8, "episode": 23, "scene": 32, "line": "This is MasterCard. You are over the limit. Send the minimum payment of $448 by Monday, or we will be forced to send it to collections.", "order": 50629, "speaker": "Phone"},
  {"season": 8, "episode": 23, "scene": 32, "line": "Shopaholic.", "order": 50630, "speaker": "Robert California"},
  {"season": 8, "episode": 23, "scene": 32, "line": "Sounds like it.", "order": 50631, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 23, "scene": 32, "line": "Yeah.", "order": 50632, "speaker": "Robert California"},
  {"season": 8, "episode": 23, "scene": 32, "line": "Hi, sis. Is your boss still hitting on you?", "order": 50633, "speaker": "Phone"},
  {"season": 8, "episode": 23, "scene": 32, "line": "Ah.", "order": 50634, "speaker": "Robert California"},
  {"season": 8, "episode": 23, "scene": 32, "line": "This is Annie from second nests. I'm sorry, but the Romanian orphanage felt more comfortable with a two-person nuclear family than a single mother, so, we're gonna hold out for that.", "order": 50635, "speaker": "Phone"},
  {"season": 8, "episode": 23, "scene": 32, "line": "Okay, that's enough. [grabs phone]", "order": 50636, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 23, "scene": 32, "line": "Pam, we need to get to the bottom of this.", "order": 50637, "speaker": "Robert California"},
  {"season": 8, "episode": 23, "scene": 32, "line": "No, no, no!", "order": 50638, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 23, "scene": 32, "line": "No, come on.", "order": 50639, "speaker": "Robert California"},
  {"season": 8, "episode": 23, "scene": 32, "line": "Robert! Okay, oops! I deleted them all. They're all deleted.", "order": 50640, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 23, "scene": 32, "line": "Pam, Pam, you've completely bungled this!", "order": 50641, "speaker": "Robert California"},
  {"season": 8, "episode": 23, "scene": 32, "line": "Ah. Ahh. [walks out]", "order": 50642, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 23, "scene": 33, "line": "Hey.", "order": 50643, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 23, "scene": 33, "line": "Can I do it, Pam? Can I put off a gold Arabian sandal?", "order": 50644, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 23, "scene": 33, "line": "Um' yes. Definitely. With your hair '", "order": 50645, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 23, "scene": 33, "line": "Oh!", "order": 50646, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 23, "scene": 33, "line": "Certainly. Um' you dropped your cell phone.", "order": 50647, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 23, "scene": 33, "line": "Oh, gosh.", "order": 50648, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 23, "scene": 33, "line": "Yep.", "order": 50649, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 23, "scene": 33, "line": "Thank you. I'm' so stupid.", "order": 50650, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 23, "scene": 33, "line": "No. My goodness. You have a lot going on. With Robert and everything.", "order": 50651, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 23, "scene": 33, "line": "Oh, god, Pam. Don't get me started.", "order": 50652, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 23, "scene": 33, "line": "No, I will not.", "order": 50653, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 23, "scene": 33, "line": "You've just got me started. Robert' is' a filthy beast. I mean, don't you get the feeling, he's just thinking of fifteen different ways to do you?", "order": 50654, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 23, "scene": 33, "line": "Well '", "order": 50655, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 23, "scene": 33, "line": "I mean, the man talks of nothing but sex.", "order": 50656, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 23, "scene": 33, "line": "But sometimes he talks about flesh' and bacchanals.", "order": 50657, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 23, "scene": 33, "line": "I cannot even tell you what he left on my phone last night.", "order": 50658, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 23, "scene": 33, "line": "No' don't. Just put it out of your mind.", "order": 50659, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 23, "scene": 33, "line": "Pam, what is your address? I'm gonna send you a pair of these gold harem shoes. Oh, no. You don't '", "order": 50660, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 23, "scene": 33, "line": "Oh yes. Come on, a little gold Arabian slipper.", "order": 50661, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 23, "scene": 34, "line": "Things are looking up. I might be a mother soon. I have MasterCard right where I want them. And' I have a new friend. A friend. At work.", "order": 50662, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 23, "scene": 35, "line": "[Erin opens door] Erin.", "order": 50663, "speaker": "Robert California"},
  {"season": 8, "episode": 23, "scene": 35, "line": "There's a call for you on line one.", "order": 50664, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 23, "scene": 35, "line": "Who is it?", "order": 50665, "speaker": "Robert California"},
  {"season": 8, "episode": 23, "scene": 35, "line": "He says salvation. No last name.", "order": 50666, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 23, "scene": 35, "line": "Yeah, hello?", "order": 50667, "speaker": "Robert California"},
  {"season": 8, "episode": 23, "scene": 35, "line": "[in car] You once put me on a list of the losers in the office. Well, this loser just got your biggest client to give him all their business. So hire me back, that business is yours. Don't, and I will find another buyer.", "order": 50668, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 23, "scene": 35, "line": "You're blackmailing me.", "order": 50669, "speaker": "Robert California"},
  {"season": 8, "episode": 23, "scene": 35, "line": "It's just business.", "order": 50670, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 23, "scene": 35, "line": "Ah, well, I will not be blackmailed by some ineffectual, privileged, effete, soft-penised debutante. You wanna start a street fight with me, bring it on. You're gonna be surprised by how ugly it gets. You don't even know my real name. I'm the *bleep* lizard king. [disconnects]", "order": 50671, "speaker": "Robert California"},
  {"season": 8, "episode": 23, "scene": 35, "line": "Whoa. Well I gave him a chance. [gets out of car, walks to house]", "order": 50672, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 23, "scene": 35, "line": "[opens door] Andy Bernard.", "order": 50673, "speaker": "David Wallace"},
  {"season": 8, "episode": 23, "scene": 35, "line": "You got a minute?", "order": 50674, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 23, "scene": 35, "line": "Um' I'm in the middle of a piano lesson.", "order": 50675, "speaker": "David Wallace"},
  {"season": 8, "episode": 23, "scene": 35, "line": "I wanted to see if I could interest you in an investment. Dunder Mifflin.", "order": 50676, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 23, "scene": 35, "line": "Dunder Mifflin. [closes door] Now' why would I want that? It's worth half of what it was three years ago.", "order": 50677, "speaker": "David Wallace"},
  {"season": 8, "episode": 23, "scene": 35, "line": "Exactly. And you know better than anyone that with the right management it could be worth twice what you would pay for it today.", "order": 50678, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 23, "scene": 35, "line": "Why don't you come in? [Andy walks in, closes door]", "order": 50679, "speaker": "David Wallace"},
  {"season": 8, "episode": 23, "scene": 36, "line": "So what would you do if you weren't selling paper?", "order": 50680, "speaker": "Harry"},
  {"season": 8, "episode": 23, "scene": 36, "line": "Oh, man, I'd have to sell beets. Probably submit them for competitions.", "order": 50681, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 23, "scene": 36, "line": "What?", "order": 50682, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 23, "scene": 36, "line": "Yeah! I know it sounds stupid, but nationals has always kinda been a dream of mine.", "order": 50683, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 23, "scene": 36, "line": "How have we never talked about this before? Wait. You don't even care about nationals.", "order": 50684, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 23, "scene": 36, "line": "Nothing?", "order": 50685, "speaker": "Harry"},
  {"season": 8, "episode": 23, "scene": 36, "line": "I don't know. I've always wanted to own a bike shop, but what about you?", "order": 50686, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 23, "scene": 36, "line": "I'd like to sell one big thing, you know? Like' a plane. One sale, I'm out.", "order": 50687, "speaker": "Harry"},
  {"season": 8, "episode": 23, "scene": 36, "line": "That sounds lovely.", "order": 50688, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 23, "scene": 36, "line": "Anyway, Robert's gonna run this company into the ground, so' We won't be doing this in six months.", "order": 50689, "speaker": "Harry"},
  {"season": 8, "episode": 24, "scene": 1, "line": "[to webcam] Hello. My name's Oscar. I'm an accountant in Scranton, Pennsylvania and I'm gay. And I'm here to tell you that yes- it does get better. When I was younger, um-", "order": 50690, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 24, "scene": 1, "line": "What's he doing?", "order": 50691, "speaker": "Phyllis Vance"},
  {"season": 8, "episode": 24, "scene": 1, "line": "He's searching out younger gays.", "order": 50692, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 24, "scene": 1, "line": "Oh.", "order": 50693, "speaker": "Phyllis Vance"},
  {"season": 8, "episode": 24, "scene": 1, "line": "No, it's just a way to tell young people, no matter how hard it gets for them, there's a brighter future ahead.", "order": 50694, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 24, "scene": 1, "line": "...with you. [chuckles]", "order": 50695, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 24, "scene": 1, "line": "No matter how hard it gets for you, eh- Kelly, seriously, I-", "order": 50696, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 24, "scene": 1, "line": "Just keep chatting. I'm just checking my makeup in your webcam.", "order": 50697, "speaker": "Kelly Kapoor"},
  {"season": 8, "episode": 24, "scene": 1, "line": "Do you not own a mirror?", "order": 50698, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 24, "scene": 1, "line": "Webcams make me look the way I do in my fantasies.", "order": 50699, "speaker": "Kelly Kapoor"},
  {"season": 8, "episode": 24, "scene": 1, "line": "I know, right? What is it? You always look so good in those things.", "order": 50700, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 24, "scene": 1, "line": "I'm throwing out all my mirrors.", "order": 50701, "speaker": "Kelly Kapoor"},
  {"season": 8, "episode": 24, "scene": 1, "line": "Okay, as you can see, I now have a cool job at a dynamic workplace. Being gay is a celebration of life, it's a simple-", "order": 50702, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 24, "scene": 1, "line": "No, I'm sorry, I just can't sit idly by and have the gay youth of America misled by some reductionist-", "order": 50703, "speaker": "Robert California"},
  {"season": 8, "episode": 24, "scene": 1, "line": "It's not misleading.", "order": 50704, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 24, "scene": 1, "line": "-pep talk. Sexuality is a spectrum. It's a paradox to think of any sexual activity as 'normal.' It gets better but it also gets vastly more complicated.", "order": 50705, "speaker": "Robert California"},
  {"season": 8, "episode": 24, "scene": 1, "line": "Hey, Robert, that guy, he looks just like you. [Robert backs into Kevin's nose] Ooh! [whimpers]", "order": 50706, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 24, "scene": 1, "line": "Ow! Damn it, Kevin! God!", "order": 50707, "speaker": "Robert California"},
  {"season": 8, "episode": 24, "scene": 1, "line": "You okay, Robert?", "order": 50708, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 24, "scene": 1, "line": "Fine.", "order": 50709, "speaker": "Robert California"},
  {"season": 8, "episode": 24, "scene": 1, "line": "Put some ice on it.", "order": 50710, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 24, "scene": 1, "line": "In any case, it gets better. [Kevin cries] Maybe- maybe not much better... but better.", "order": 50711, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 24, "scene": 2, "line": "One, two, three!", "order": 50712, "speaker": "Sasha"},
  {"season": 8, "episode": 24, "scene": 3, "line": "I wanted to give a nice gift to the tenants in the building. At first I thought, 'muffin basket.' Then I thought, 'What's even more precious to people than muffins?'", "order": 50713, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 24, "scene": 4, "line": "Can you just smile, please?", "order": 50714, "speaker": "Jake"},
  {"season": 8, "episode": 24, "scene": 4, "line": "I don't want to!", "order": 50715, "speaker": "Meredith Palmer"},
  {"season": 8, "episode": 24, "scene": 5, "line": "...their own children.", "order": 50716, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 24, "scene": 6, "line": "Hey everybody, just a few hours left for the free family portrait studio. Darryl. We'd love to see little Jada come by. Angela? Why don't you bring by your little angel?", "order": 50717, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 24, "scene": 6, "line": "No thank you.", "order": 50718, "speaker": "Angela Martin"},
  {"season": 8, "episode": 24, "scene": 6, "line": "Jim, you've got those two cute kids. We sure would love to see them.", "order": 50719, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 24, "scene": 6, "line": "I know why you're doing this, Dwight.", "order": 50720, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 24, "scene": 7, "line": "Last week, I may have gone too far. I'll explain it quickly. Basically, I found out where he gets his clothes dry-cleaned. Custom ordered the same suit, made with tear-away Velcro. And... you can fill in the rest.", "order": 50721, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 24, "scene": 8, "line": "[Jim rips off his clothes] Wha-? [Stanley laughs] Damn it, Jim!", "order": 50722, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 24, "scene": 9, "line": "Now he's trying to get me to bring my children in to work. I think it's fair to be cautious.", "order": 50723, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 24, "scene": 10, "line": "Let me get this straight. You lost all of it. All your winnings. A hundred and fifty thousand dollars.", "order": 50724, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 24, "scene": 10, "line": "Bad economy. Bad investment.", "order": 50725, "speaker": "Hidetoshi Hasagawa"},
  {"season": 8, "episode": 24, "scene": 10, "line": "You mean to tell me no one wanted an energy drink for Asian homosexuals?", "order": 50726, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 24, "scene": 10, "line": "They did not.", "order": 50727, "speaker": "Calvin"},
  {"season": 8, "episode": 24, "scene": 10, "line": "And you got half a million of these? Well I gotta try it.", "order": 50728, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 24, "scene": 10, "line": "I wouldn't.", "order": 50729, "speaker": "Calvin"},
  {"season": 8, "episode": 24, "scene": 10, "line": "Aw, come on. What's the harm? [makes face] Mmm. What flavor was that?", "order": 50730, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 24, "scene": 10, "line": "Coconut penis.", "order": 50731, "speaker": "Hidetoshi Hasagawa"},
  {"season": 8, "episode": 24, "scene": 10, "line": "The coconut's pretty subtle.", "order": 50732, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 24, "scene": 10, "line": "Come on, man. Can't you just give us a yes or a no?", "order": 50733, "speaker": "Calvin"},
  {"season": 8, "episode": 24, "scene": 10, "line": "I don't know, man. Y'all quit with a lot of confidence. I mean, it was like, y'all came up in here dancing and everybody was- [sees Val] ooh, hold on a second. [sees Brandon] Oh.", "order": 50734, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 24, "scene": 11, "line": "If I were Val, I would break up with Brandon. Then I would date the hell out of me. I wouldn't give in to me too fast, let me buy myself some nice dinners and such. But when I finally did give in, I would go crazy on myself.", "order": 50735, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 24, "scene": 12, "line": "[wearing janitor outfit] Hi guys.", "order": 50736, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 24, "scene": 12, "line": "How's it going, man?", "order": 50737, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 24, "scene": 12, "line": "[whimpering] I'm- I'm good, thank you. And how are you?", "order": 50738, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 24, "scene": 12, "line": "Great, yeah, good.", "order": 50739, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 24, "scene": 12, "line": "Oh, how the mediocre have fallen.", "order": 50740, "speaker": "Gabe Lewis"},
  {"season": 8, "episode": 24, "scene": 12, "line": "Oh God.", "order": 50741, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 24, "scene": 12, "line": "Look, I'm not here to get my old job back. I had my opportunity, I blew it, I'm moving on. I just wanted to come in here and literally beg you for something, anything. I will sweep the floors, water the plants...", "order": 50742, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 24, "scene": 12, "line": "No, you don't understand-", "order": 50743, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 24, "scene": 12, "line": "Clean the toilets.", "order": 50744, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 24, "scene": 12, "line": "I don't need anyone to-", "order": 50745, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 24, "scene": 12, "line": "Pull the poop out of the toilets.", "order": 50746, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 24, "scene": 12, "line": "That's not even a thing.", "order": 50747, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 24, "scene": 12, "line": "Just, please...", "order": 50748, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 24, "scene": 12, "line": "Andy.", "order": 50749, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 24, "scene": 12, "line": "Please!", "order": 50750, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 24, "scene": 12, "line": "Andy.", "order": 50751, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 24, "scene": 12, "line": "Give me something!", "order": 50752, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 24, "scene": 12, "line": "Why don't you clean the carpets for today?", "order": 50753, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 24, "scene": 12, "line": "Thank you.", "order": 50754, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 24, "scene": 12, "line": "You're welcome. Up you get then. Pick yourself up. There it is.", "order": 50755, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 24, "scene": 12, "line": "Thank you.", "order": 50756, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 24, "scene": 12, "line": "You don't need to-", "order": 50757, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 24, "scene": 13, "line": "I have a delicious secret.", "order": 50758, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 24, "scene": 14, "line": "Good, good. This carpet's overdue for a good mopping.", "order": 50759, "speaker": "Creed Bratton"},
  {"season": 8, "episode": 24, "scene": 14, "line": "Is a mop the most efficient tool to use on a carpet?", "order": 50760, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 24, "scene": 14, "line": "Yeah, it is actually.", "order": 50761, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 24, "scene": 15, "line": "Mmm, I can't hold it in any longer. Um, I am faking this, okay? Because I convinced David Wallace to buy Dunder Mifflin back from Sabre. And at three o'clock today he's gonna walk in here and reinstate me as manager.", "order": 50762, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 24, "scene": 16, "line": "[enters with the kids] Family picture!", "order": 50763, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 24, "scene": 16, "line": "Pam? No!", "order": 50764, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 24, "scene": 16, "line": "What? Honey, we talked about this.", "order": 50765, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 24, "scene": 16, "line": "No, we talked about not bringing them in to Dwight's photo studio slash trap.", "order": 50766, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 24, "scene": 16, "line": "Yes, and then we decided that was crazy.", "order": 50767, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 24, "scene": 16, "line": "No, we decided it was crazy not to worry about it.", "order": 50768, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 24, "scene": 16, "line": "No, we settled in a much more rational place, remember?", "order": 50769, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 24, "scene": 16, "line": "Nope.", "order": 50770, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 24, "scene": 16, "line": "We decided that there's no way Dwight would harm a child.", "order": 50771, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 24, "scene": 16, "line": "But are you a hundred percent sure? I don't think any of us are.", "order": 50772, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 24, "scene": 16, "line": "No, no.", "order": 50773, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 24, "scene": 16, "line": "Nope.", "order": 50774, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 24, "scene": 16, "line": "But it's free. And we'll keep our eye on them.", "order": 50775, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 24, "scene": 16, "line": "That's-", "order": 50776, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 24, "scene": 16, "line": "Yes!", "order": 50777, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 24, "scene": 16, "line": "That's-", "order": 50778, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 24, "scene": 16, "line": "And we'll make sure Dwight doesn't do anything C-R-A-Z-Y.", "order": 50779, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 24, "scene": 16, "line": "Okay.", "order": 50780, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 24, "scene": 16, "line": "Wait, C-R-A-Z...", "order": 50781, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 24, "scene": 17, "line": "Great, right up there.", "order": 50782, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 24, "scene": 17, "line": "Yeah?", "order": 50783, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 24, "scene": 17, "line": "Yeah. You just sit right here. Here we go.", "order": 50784, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 24, "scene": 17, "line": "Okay.", "order": 50785, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 24, "scene": 17, "line": "Alright. Why don't you just-", "order": 50786, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 24, "scene": 17, "line": "Oh, great. Thank you.", "order": 50787, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 24, "scene": 17, "line": "-give him to me and... perfect.", "order": 50788, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 24, "scene": 17, "line": "Okay.", "order": 50789, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 24, "scene": 17, "line": "This is great.", "order": 50790, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 24, "scene": 17, "line": "You ready?", "order": 50791, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 24, "scene": 17, "line": "Excuse me.", "order": 50792, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 24, "scene": 17, "line": "There we go.", "order": 50793, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 24, "scene": 17, "line": "Just do it.", "order": 50794, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 24, "scene": 17, "line": "I'll just stand here?", "order": 50795, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 24, "scene": 17, "line": "Yeah, just keep your eye on Dwight, great.", "order": 50796, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 24, "scene": 17, "line": "Sir, could you look into the camera?", "order": 50797, "speaker": "Photographer"},
  {"season": 8, "episode": 24, "scene": 17, "line": "Let's do it, shoot it.", "order": 50798, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 24, "scene": 17, "line": "Sir.", "order": 50799, "speaker": "Photographer"},
  {"season": 8, "episode": 24, "scene": 17, "line": "Great.", "order": 50800, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 24, "scene": 17, "line": "Up here. [whistles]", "order": 50801, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 24, "scene": 17, "line": "Yeah. Yeah, yeah, yeah.", "order": 50802, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 24, "scene": 17, "line": "Jim, right here, right here. [snaps fingers] Okay, good.", "order": 50803, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 24, "scene": 17, "line": "Oh, so that's it?", "order": 50804, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 24, "scene": 17, "line": "That's it.", "order": 50805, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 24, "scene": 17, "line": "See? That wasn't so hard.", "order": 50806, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 24, "scene": 17, "line": "Cute kids, thanks for coming.", "order": 50807, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 24, "scene": 18, "line": "Hello, Erin. Hello, everybody.", "order": 50808, "speaker": "Senator Lipton"},
  {"season": 8, "episode": 24, "scene": 18, "line": "Uh, what? Why? Uh, hi honey. What- how are you doing?", "order": 50809, "speaker": "Angela Martin"},
  {"season": 8, "episode": 24, "scene": 18, "line": "Hi, you know, my office got a call that they were shooting family portraits right here. And if there's one thing that every politician instinctively understands, it's a good photo op.", "order": 50810, "speaker": "Senator Lipton"},
  {"season": 8, "episode": 24, "scene": 18, "line": "[laughs] Yeah.", "order": 50811, "speaker": "Phyllis Vance"},
  {"season": 8, "episode": 24, "scene": 18, "line": "Heh.", "order": 50812, "speaker": "Angela Martin"},
  {"season": 8, "episode": 24, "scene": 18, "line": "Okay, you're going to go through the hallway. Here's a map. And if you get lost, just follow the blue line. [points to line on floor]", "order": 50813, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 24, "scene": 18, "line": "Thanks.", "order": 50814, "speaker": "Senator Lipton"},
  {"season": 8, "episode": 24, "scene": 19, "line": "Well, well, well. Senator Lipton, welcome. Angela.", "order": 50815, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 24, "scene": 19, "line": "Dwight.", "order": 50816, "speaker": "Angela Martin"},
  {"season": 8, "episode": 24, "scene": 19, "line": "Nice to see you. May I hold him?", "order": 50817, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 24, "scene": 19, "line": "Sure.", "order": 50818, "speaker": "Senator Lipton"},
  {"season": 8, "episode": 24, "scene": 19, "line": "No. I am going to hold him because I haven't seen him all day.", "order": 50819, "speaker": "Angela Martin"},
  {"season": 8, "episode": 24, "scene": 20, "line": "I think that Dwight is doing this whole thing to, um, get a sample of my baby's DNA to prove he's the father. Which... is impossible... because the senator is the only man I've ever been with.", "order": 50820, "speaker": "Angela Martin"},
  {"season": 8, "episode": 24, "scene": 21, "line": "[Phillip cries] See the duckie!", "order": 50821, "speaker": "Angela Martin"},
  {"season": 8, "episode": 24, "scene": 21, "line": "Okay, hold on for a second, folks. A few of the baby's hairs are out of place.", "order": 50822, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 24, "scene": 21, "line": "Oh, thank you.", "order": 50823, "speaker": "Senator Lipton"},
  {"season": 8, "episode": 24, "scene": 21, "line": "[slaps Dwight's hand away] No. No. I like the baby's hair the way it is.", "order": 50824, "speaker": "Angela Martin"},
  {"season": 8, "episode": 24, "scene": 21, "line": "Really?", "order": 50825, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 24, "scene": 22, "line": "This is getting more and more delicious by the minute. [gargles alcohol, chokes, coughs] It's go time.", "order": 50826, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 24, "scene": 23, "line": "Hey Nellie, I made soup - I made you some soup.", "order": 50827, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 24, "scene": 23, "line": "Well, I don't want any soup.", "order": 50828, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 24, "scene": 23, "line": "But it's really good- [drops soup] Oh!", "order": 50829, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 24, "scene": 23, "line": "Oh come on, Andy!", "order": 50830, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 24, "scene": 24, "line": "I know this is going to be a great payoff.", "order": 50831, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 24, "scene": 24, "line": "A delicious moment.", "order": 50832, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 24, "scene": 24, "line": "But after you're manager, some of these images are going to be hard to shake.", "order": 50833, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 24, "scene": 24, "line": "Well, but it-", "order": 50834, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 24, "scene": 24, "line": "Just calibrate. Okay? Calibrate.", "order": 50835, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 24, "scene": 25, "line": "Okay, here we go. Oh, you know what? I don't want to throw a wrench in the works, but I want everything to be perfect and the baby's fingernails are just bothering me-", "order": 50836, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 24, "scene": 25, "line": "Uh-uh, leave us alone.", "order": 50837, "speaker": "Angela Martin"},
  {"season": 8, "episode": 24, "scene": 25, "line": "I guess we're okay with the fingernails.", "order": 50838, "speaker": "Senator Lipton"},
  {"season": 8, "episode": 24, "scene": 25, "line": "Okay, great. Suit yourself. Although, are those excess skin cells on the baby's cheek distracting to you as well?", "order": 50839, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 24, "scene": 25, "line": "Dwight, we're fine!", "order": 50840, "speaker": "Angela Martin"},
  {"season": 8, "episode": 24, "scene": 25, "line": "Oopsie, did somebody make a boopsie?", "order": 50841, "speaker": "Senator Lipton"},
  {"season": 8, "episode": 24, "scene": 25, "line": "Oh no. [laughs]", "order": 50842, "speaker": "Angela Martin"},
  {"season": 8, "episode": 24, "scene": 25, "line": "I'm just gonna take him out and change him just for a second.", "order": 50843, "speaker": "Senator Lipton"},
  {"season": 8, "episode": 24, "scene": 25, "line": "He defecated, aw.", "order": 50844, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 24, "scene": 25, "line": "You're stinky.", "order": 50845, "speaker": "Senator Lipton"},
  {"season": 8, "episode": 24, "scene": 26, "line": "A lot has changed since y'all left, okay? You're gonna have to jump through some hoops. And the new foreman is here. Now she's one of the best we've ever known, okay? So I need you to show her your upmost respect. Make sure she feels welcome and at home, okay? Can you do that?", "order": 50846, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 24, "scene": 26, "line": "That's some pretty blatant complementing you're doing there, man. I don't even talk to her like that.", "order": 50847, "speaker": "Brandon"},
  {"season": 8, "episode": 24, "scene": 26, "line": "Uh, Brandon?", "order": 50848, "speaker": "Val Johnson"},
  {"season": 8, "episode": 24, "scene": 26, "line": "I would.", "order": 50849, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 24, "scene": 26, "line": "You would?", "order": 50850, "speaker": "Brandon"},
  {"season": 8, "episode": 24, "scene": 26, "line": "Yeah.", "order": 50851, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 24, "scene": 26, "line": "Sounds like you're trying to hit on my girlfriend.", "order": 50852, "speaker": "Brandon"},
  {"season": 8, "episode": 24, "scene": 26, "line": "Calm down, he's not.", "order": 50853, "speaker": "Val Johnson"},
  {"season": 8, "episode": 24, "scene": 26, "line": "Yes, I am. Just so everyone's on the same page.", "order": 50854, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 24, "scene": 26, "line": "[scoffs] So you really think she's gonna leave a guy who owns his own restaurant for a dude who ate his own restaurant?", "order": 50855, "speaker": "Brandon"},
  {"season": 8, "episode": 24, "scene": 26, "line": "Brandon!", "order": 50856, "speaker": "Val Johnson"},
  {"season": 8, "episode": 24, "scene": 26, "line": "Good slam.", "order": 50857, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 24, "scene": 26, "line": "Thank you, Biggie.", "order": 50858, "speaker": "Brandon"},
  {"season": 8, "episode": 24, "scene": 26, "line": "Good luck to you.", "order": 50859, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 24, "scene": 26, "line": "Oh, and have a burger for me.", "order": 50860, "speaker": "Brandon"},
  {"season": 8, "episode": 24, "scene": 27, "line": "Okay, shall we take a few more?", "order": 50861, "speaker": "Senator Lipton"},
  {"season": 8, "episode": 24, "scene": 27, "line": "I think we have all the shots we need. Thank you, Dwight.", "order": 50862, "speaker": "Angela Martin"},
  {"season": 8, "episode": 24, "scene": 27, "line": "Okay. There we go.", "order": 50863, "speaker": "Senator Lipton"},
  {"season": 8, "episode": 24, "scene": 28, "line": "No! Dwight! Give me the diaper! Dwight!", "order": 50864, "speaker": "Angela Martin"},
  {"season": 8, "episode": 24, "scene": 29, "line": "Is this what you were looking for? Huh? Huh?", "order": 50865, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 24, "scene": 30, "line": "Andy, we wanted to talk to you. A lot of us are very concerned about you.", "order": 50866, "speaker": "Toby Flenderson"},
  {"season": 8, "episode": 24, "scene": 30, "line": "You're dirty, you stink of booze, you're limping inexplicably.", "order": 50867, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 24, "scene": 30, "line": "I just want you guys not to worry. 'Cause old Andy's gonna be just fine.", "order": 50868, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 24, "scene": 30, "line": "No, no, see? This is what we're talking about. I mean, what was that accent? And last time I checked, you were drunk and now you're not drunk...", "order": 50869, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 24, "scene": 30, "line": "No, unfortunately it's true. He's been a nightmare. And the worst part is, he's been taking it out on me.", "order": 50870, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 24, "scene": 30, "line": "What?", "order": 50871, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 24, "scene": 30, "line": "You hit her?", "order": 50872, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 24, "scene": 30, "line": "No. That is not the deal. Calibrate.", "order": 50873, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 24, "scene": 30, "line": "He's not hitting me. But, he's been verbally abusing me-", "order": 50874, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 24, "scene": 30, "line": "That's not true either! That's also total- Everyone, please relax. I think you're gonna like this surprise guest.", "order": 50875, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 24, "scene": 30, "line": "[enters] Happy birthday to Gabe!", "order": 50876, "speaker": "Gabe Lewis"},
  {"season": 8, "episode": 24, "scene": 30, "line": "Oh, get out, skeleton man!", "order": 50877, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 24, "scene": 30, "line": "I can refer you to someone to talk to.", "order": 50878, "speaker": "Toby Flenderson"},
  {"season": 8, "episode": 24, "scene": 30, "line": "[his phone rings] Huh? Hold on.", "order": 50879, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 24, "scene": 30, "line": "Give you a name or-", "order": 50880, "speaker": "Toby Flenderson"},
  {"season": 8, "episode": 24, "scene": 30, "line": "I just gonna- quick- [answers phone] Hello? Hey, what's the scoop? Are you close? What? No. no, no, no, no, no. You have to come now. Traffic clears? No, next Friday... that's not gonna work. Okay. Yeah. Okay. [inhales, hangs up] Damn it! Well, this is not going to be quite as delicious as I wanted, but I do have a very tasty announcement for you guys. Not only am I not a lowly janitor, I am the regional manager once again! Guys, I got my old job back!", "order": 50881, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 24, "scene": 30, "line": "Oh boy.", "order": 50882, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 24, "scene": 30, "line": "Oh, Andy.", "order": 50883, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 24, "scene": 30, "line": "What?", "order": 50884, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 24, "scene": 30, "line": "I'm not crazy. I convinced David Wallace to give me my job back.", "order": 50885, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 24, "scene": 30, "line": "David Wallace hasn't worked here in years.", "order": 50886, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 24, "scene": 30, "line": "Okay, yes, I see the confusion. I saw David at the fundraiser. He is now a multi-millionaire because he sold his toy vacuum 'Suck It' to the U.S. military.", "order": 50887, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 24, "scene": 30, "line": "Andy, come on.", "order": 50888, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 24, "scene": 30, "line": "Even I know that's weird.", "order": 50889, "speaker": "Kevin Malone"},
  {"season": 8, "episode": 24, "scene": 30, "line": "I- I- Okay, I get how that sounds crazy.", "order": 50890, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 24, "scene": 30, "line": "Hey, Andy, Nobody's calling you crazy, Andy. We're your friends, Andy.", "order": 50891, "speaker": "Toby Flenderson"},
  {"season": 8, "episode": 24, "scene": 30, "line": "Stop saying my name.", "order": 50892, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 24, "scene": 30, "line": "No, he's not making this up.", "order": 50893, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 24, "scene": 30, "line": "Thank you, Erin.", "order": 50894, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 24, "scene": 30, "line": "Andy tells me about seeing David Wallace all the time.", "order": 50895, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 24, "scene": 30, "line": "But have you ever actually seen him yourself?", "order": 50896, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 24, "scene": 30, "line": "Oh my God.", "order": 50897, "speaker": "Erin Hannon"},
  {"season": 8, "episode": 24, "scene": 30, "line": "Erin, come- Come on. You know I've been talking to David Wallace.", "order": 50898, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 24, "scene": 30, "line": "Do you see David Wallace in the room right now?", "order": 50899, "speaker": "Toby Flenderson"},
  {"season": 8, "episode": 24, "scene": 31, "line": "[into phone] Operation Phoenix is a go. Just get the car ready.", "order": 50900, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 24, "scene": 32, "line": "Now you look at me like you're adoring me, I'm gonna look at the camera like I don't even know you're there.", "order": 50901, "speaker": "Kelly Kapoor"},
  {"season": 8, "episode": 24, "scene": 32, "line": "I do adore you.", "order": 50902, "speaker": "Ravi"},
  {"season": 8, "episode": 24, "scene": 33, "line": "Andrew, it's time for you to go home. You're better than this.", "order": 50903, "speaker": "Robert California"},
  {"season": 8, "episode": 24, "scene": 33, "line": "Yeah, I know.", "order": 50904, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 24, "scene": 33, "line": "Everyone's better than this, because this is the worst thing I have ever seen. [drinks coconut penis energy drink] Why'd they add coconut? I miss original.", "order": 50905, "speaker": "Robert California"},
  {"season": 8, "episode": 24, "scene": 33, "line": "Hey.", "order": 50906, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 24, "scene": 33, "line": "Hey!", "order": 50907, "speaker": "Others"},
  {"season": 8, "episode": 24, "scene": 33, "line": "Hey guys, long time.", "order": 50908, "speaker": "David Wallace"},
  {"season": 8, "episode": 24, "scene": 33, "line": "David, what are you doing here?", "order": 50909, "speaker": "Pam Halpert"},
  {"season": 8, "episode": 24, "scene": 33, "line": "Is it true you're buying the company?", "order": 50910, "speaker": "Phyllis Vance"},
  {"season": 8, "episode": 24, "scene": 33, "line": "Okay, I guess the word is out. Uh, please keep this a secret, but yes, I've been talking with Andy-", "order": 50911, "speaker": "David Wallace"},
  {"season": 8, "episode": 24, "scene": 33, "line": "What? Thought I heard my name. What? I'm the new manager?", "order": 50912, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 24, "scene": 33, "line": "I'll get to that in a second, Andy.", "order": 50913, "speaker": "David Wallace"},
  {"season": 8, "episode": 24, "scene": 33, "line": "But it's- it is- it is me? Right?", "order": 50914, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 24, "scene": 33, "line": "Yes, as we've discussed-", "order": 50915, "speaker": "David Wallace"},
  {"season": 8, "episode": 24, "scene": 33, "line": "What?", "order": 50916, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 24, "scene": 33, "line": "But it's very possible-", "order": 50917, "speaker": "David Wallace"},
  {"season": 8, "episode": 24, "scene": 33, "line": "Oh my God!", "order": 50918, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 24, "scene": 33, "line": "Probable.", "order": 50919, "speaker": "David Wallace"},
  {"season": 8, "episode": 24, "scene": 33, "line": "Wow. From janitor to manager?", "order": 50920, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 24, "scene": 33, "line": "Yep.", "order": 50921, "speaker": "David Wallace"},
  {"season": 8, "episode": 24, "scene": 33, "line": "That's quite a Cinderella story. From M-O-P to M-V-P.", "order": 50922, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 24, "scene": 33, "line": "There's an official announcement, a few details to be-", "order": 50923, "speaker": "David Wallace"},
  {"season": 8, "episode": 24, "scene": 33, "line": "From total loss to total boss, I mean-", "order": 50924, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 24, "scene": 33, "line": "Can we expect any payroll interruptions?", "order": 50925, "speaker": "Stanley Hudson"},
  {"season": 8, "episode": 24, "scene": 33, "line": "Now that is a great question, Stanley. Right now I think all your operations or most of your operations are pretty much down in Florida, so to shift back up-", "order": 50926, "speaker": "David Wallace"},
  {"season": 8, "episode": 24, "scene": 34, "line": "I was so looking forward to that and it did not... go as I thought it would.", "order": 50927, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 24, "scene": 35, "line": "So we're not gonna be a part of Sabre anymore?", "order": 50928, "speaker": "Phyllis Vance"},
  {"season": 8, "episode": 24, "scene": 35, "line": "Actually, nothing is gonna be a part of Sabre anymore. Jo Bennett's planning on liquidating the rest of the company.", "order": 50929, "speaker": "David Wallace"},
  {"season": 8, "episode": 24, "scene": 35, "line": "Oof. [laughs] Wouldn't wanna be a Sabre employee right about now. [laughs] I'm actually the CEO.", "order": 50930, "speaker": "Robert California"},
  {"season": 8, "episode": 24, "scene": 35, "line": "Ah, I didn't realize you were standing there.", "order": 50931, "speaker": "David Wallace"},
  {"season": 8, "episode": 24, "scene": 35, "line": "Hey, my friend, trust me. This is for the best. I never understood that corporate mess.", "order": 50932, "speaker": "Robert California"},
  {"season": 8, "episode": 24, "scene": 35, "line": "Well, okay. Great to meet you.", "order": 50933, "speaker": "David Wallace"},
  {"season": 8, "episode": 24, "scene": 35, "line": "Likewise, I'm Bob. Bob Kazamakis.", "order": 50934, "speaker": "Robert California"},
  {"season": 8, "episode": 24, "scene": 35, "line": "Pleasure.", "order": 50935, "speaker": "David Wallace"},
  {"season": 8, "episode": 24, "scene": 35, "line": "I'd love to give you a little rundown on what I've learned about this place.", "order": 50936, "speaker": "Robert California"},
  {"season": 8, "episode": 24, "scene": 35, "line": "That's very gracious of you, Bob. I would love that.", "order": 50937, "speaker": "David Wallace"},
  {"season": 8, "episode": 24, "scene": 35, "line": "Please. [laughs]", "order": 50938, "speaker": "Robert California"},
  {"season": 8, "episode": 24, "scene": 35, "line": "Guess I'd better take off these dirty rags. Figure out how to be a manager of this place.", "order": 50939, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 24, "scene": 35, "line": "Perhaps your year of experience managing this branch will come in handy.", "order": 50940, "speaker": "Jim Halpert"},
  {"season": 8, "episode": 24, "scene": 35, "line": "Gosh, I hope so.", "order": 50941, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 24, "scene": 36, "line": "No! God, Mose! God!", "order": 50942, "speaker": "Angela Martin"},
  {"season": 8, "episode": 24, "scene": 36, "line": "Hi, Angela.", "order": 50943, "speaker": "Mose Schrute"},
  {"season": 8, "episode": 24, "scene": 36, "line": "Get out of the car! Get out! Where is he?", "order": 50944, "speaker": "Angela Martin"},
  {"season": 8, "episode": 24, "scene": 36, "line": "I'm not supposed to say.", "order": 50945, "speaker": "Mose Schrute"},
  {"season": 8, "episode": 24, "scene": 36, "line": "Yes! [slaps Mose] Tell me! Tell me where he is! Mose, damn it! Where did he go? Mose! Get back here!", "order": 50946, "speaker": "Angela Martin"},
  {"season": 8, "episode": 24, "scene": 37, "line": "[taking photo with Jada] Let's see that smile. Go on. [waves to Val]", "order": 50947, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 24, "scene": 37, "line": "[stands next to him] Right here?", "order": 50948, "speaker": "Val Johnson"},
  {"season": 8, "episode": 24, "scene": 37, "line": "Cool.", "order": 50949, "speaker": "Darryl Philbin"},
  {"season": 8, "episode": 24, "scene": 38, "line": "Looks like I might get my delicious moment after all.", "order": 50950, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 24, "scene": 39, "line": "May I say one thing?", "order": 50951, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 24, "scene": 39, "line": "It would be rude of me not to let you say whatever's on your mind.", "order": 50952, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 24, "scene": 39, "line": "The quality of mercy is not strained.", "order": 50953, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 24, "scene": 39, "line": "No.", "order": 50954, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 24, "scene": 39, "line": "It droppeth as the gentle rain from heaven upon the place beneath.", "order": 50955, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 24, "scene": 39, "line": "Do not bring Shakespeare into this. How dare you play the bard card?", "order": 50956, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 24, "scene": 39, "line": "It blesseth he who gives and he who takes. 'Tis mightiest in the mightiest.", "order": 50957, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 24, "scene": 40, "line": "I just want one mother[bleep] delicious moment. Is that too much to ask?", "order": 50958, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 24, "scene": 41, "line": "If I were to hire you back. If. What do you think you would do well?", "order": 50959, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 24, "scene": 41, "line": "Special projects manager, that's my background. I just go around doing whatever I want.", "order": 50960, "speaker": "Nellie Bertram"},
  {"season": 8, "episode": 24, "scene": 41, "line": "All right, you sly bastard. When can you start?", "order": 50961, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 24, "scene": 42, "line": "Okay everyone. I had a conversation with Bob, and since I am going to be CEO now there'll be no need for him.", "order": 50962, "speaker": "David Wallace"},
  {"season": 8, "episode": 24, "scene": 42, "line": "Ouch. That's gotta hurt!", "order": 50963, "speaker": "Meredith Palmer"},
  {"season": 8, "episode": 24, "scene": 42, "line": "But, he is going to be making so much money from the liquidation, he's gonna be freed up to do something much more important.", "order": 50964, "speaker": "David Wallace"},
  {"season": 8, "episode": 24, "scene": 42, "line": "David has generously offered to donate one million dollars in matching funds to a cause that's very dear to me. So, for the next three years, I'll be travelling overseas, concentrating all of my efforts on educating- mentoring, some African, some Asian, but mainly Eastern European women.", "order": 50965, "speaker": "Robert California"},
  {"season": 8, "episode": 24, "scene": 42, "line": "I had no idea how pervasive this problem was until Bob explained it to me.", "order": 50966, "speaker": "David Wallace"},
  {"season": 8, "episode": 24, "scene": 42, "line": "Oprah Winfrey's leadership academy and other schools like it, while wonderful, end with high school. I want to see these girls right through college, especially the gymnasts. They've lost so many years of crucial education to perfecting their bodies. Muscle groups the rest of us can't even fathom.", "order": 50967, "speaker": "Robert California"},
  {"season": 8, "episode": 24, "scene": 42, "line": "Gymnasts? You're going to seek out uneducated gymnasts?", "order": 50968, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 24, "scene": 42, "line": "Yeah, so I'm hoping to identify some truly exceptional women who show great promise. Well, Andy, it's been fun.", "order": 50969, "speaker": "Robert California"},
  {"season": 8, "episode": 24, "scene": 42, "line": "Mm. Mm-hmm. [they hug, Robert kisses him on the lips]", "order": 50970, "speaker": "Andy Bernard"},
  {"season": 8, "episode": 24, "scene": 42, "line": "It's been a great year.", "order": 50971, "speaker": "Robert California"},
  {"season": 8, "episode": 24, "scene": 43, "line": "Hello.", "order": 50972, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 24, "scene": 43, "line": "Where is it?", "order": 50973, "speaker": "Angela Martin"},
  {"season": 8, "episode": 24, "scene": 43, "line": "They're testing it now.", "order": 50974, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 24, "scene": 43, "line": "How long does it take to get the results?", "order": 50975, "speaker": "Angela Martin"},
  {"season": 8, "episode": 24, "scene": 43, "line": "Seventy-two hours.", "order": 50976, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 24, "scene": 43, "line": "You're gonna wait here?", "order": 50977, "speaker": "Angela Martin"},
  {"season": 8, "episode": 24, "scene": 43, "line": "Yes. [Angela sits and holds his hand]", "order": 50978, "speaker": "Dwight Schrute"},
  {"season": 8, "episode": 24, "scene": 43, "line": "[Dwight kisses her] Dwight, I can't do this. Dwight!", "order": 50979, "speaker": "Angela Martin"},
  {"season": 8, "episode": 24, "scene": 44, "line": "Hey.", "order": 50980, "speaker": "Senator Lipton"},
  {"season": 8, "episode": 24, "scene": 44, "line": "Senator Lipton. Hey, how are you?", "order": 50981, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 24, "scene": 44, "line": "You didn't call.", "order": 50982, "speaker": "Senator Lipton"},
  {"season": 8, "episode": 24, "scene": 44, "line": "Um, well, uh, the issue that I- that concerned me...", "order": 50983, "speaker": "Oscar Martinez"},
  {"season": 8, "episode": 24, "scene": 44, "line": "You know what this is about. [covers Phillip's eyes] Call.", "order": 50984, "speaker": "Senator Lipton"}
]
