import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import Fuse from 'fuse.js'
import React from 'react'
import SpeakerImages from './../assets/images/SpeakerImages'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { Link } from 'react-router-dom'
import { useState } from 'react'

type QuoteCardProps = React.HTMLAttributes<HTMLDivElement> & {
    quote: Fuse.FuseResult<Quote>
    searchPhrase: string
    ref?: React.Ref<HTMLDivElement>
    showContextArrow: boolean
}

const QuoteCard: React.FC<QuoteCardProps> = React.forwardRef((props, ref) => {
    const { item: quote } = props.quote
    const [copied, setCopied] = useState(false)

    const escapeRegExp = (string: string) => {
        let replaceString = string ? string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') : ''
        return replaceString
    }

    const displayCopied = () => {
        setCopied(true)

        setTimeout(() => {
            setCopied(false)
        }, 1500)
    }

    return (
        <div className="p-6 mb-6 border rounded-sm" ref={ref}>
            <p
                id={`quote${quote.order}`}
                className="mb-6 dark:text-gray-200"
                dangerouslySetInnerHTML={{
                    __html: quote.line.replace(new RegExp(escapeRegExp(props.searchPhrase), 'gi'), (match) =>
                        match ? `<mark class="rounded-sm bg-highlight-yellow">${match}</mark>` : ''
                    ),
                }}
            />
            <div className="flex justify-between">
                <div className="flex items-center">
                    <img
                        className="object-cover w-12 h-12 mr-4 rounded-full"
                        src={SpeakerImages[quote.speaker] || SpeakerImages['Unknown']}
                        alt={quote.speaker}
                    />
                    <div className="text-sm">
                        <p className="leading-none dark:text-gray-200">{quote.speaker}</p>
                        <p className="text-gray-600 dark:text-gray-400">
                            Season {quote.season}, Episode {quote.episode}
                        </p>
                    </div>
                </div>
                <div className="flex items-end text-lg">
                    <div className="relative">
                        <div
                            className={`transition-opacity duration-150 ease-out text-xs p-2 absolute flex justify-center rounded-sm -top-10 -left-4 -right-3 bg-black dark:bg-white dark:text-black text-gray-200 ${
                                copied ? 'opacity-100' : 'opacity-0'
                            }`}
                        >
                            Copied!
                        </div>
                        <CopyToClipboard text={quote.line} onCopy={displayCopied}>
                            <div
                                tabIndex={2}
                                role="button"
                                className="flex items-center justify-center w-10 h-10 rounded-full hover:opacity-50 dark:text-gray-200"
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6 rounded fill-current">
                                    <path d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z" />
                                </svg>
                            </div>
                        </CopyToClipboard>
                    </div>
                    {props.showContextArrow && (
                        <Link to={`/quote/${quote.order}`}>
                            <div
                                tabIndex={3}
                                role="button"
                                className="flex items-center justify-center w-10 h-10 rounded-full hover:opacity-50 dark:text-gray-200"
                            >
                                <ArrowForwardIcon />
                            </div>
                        </Link>
                    )}
                </div>
            </div>
        </div>
    )
})

export default QuoteCard
