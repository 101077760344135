import { useState, useEffect } from 'react'

const useLocalStorage = (key: string, defaultValue: any) => {
    const [state, setState] = useState(() => localStorage.getItem(key) || defaultValue)
    useEffect(() => {
        localStorage.setItem(key, state)
    }, [key, state])
    return [state, setState]
}

export default useLocalStorage
