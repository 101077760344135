import React from 'react'
import TWSSLogo from '../assets/images/logo.png'
import { useTheme } from '../contexts/ThemeContext'
import { Link } from 'react-router-dom'

type HeaderProps = React.HTMLAttributes<HTMLHeadElement> & {
    searchbar?: React.ReactNode
    backButton?: React.ReactNode
}

const Header: React.FC<HeaderProps> = (props) => {
    const { theme, toggleTheme } = useTheme()
    const title = "That's What Scranton Said"

    return (
        <header
            title={title}
            className="fixed top-0 z-50 flex items-center w-full h-16 bg-white border-b dark:bg-black"
        >
            <div className="relative flex w-full max-w-screen-lg px-6 mx-auto">
                <div className="flex pr-6 lg:w-1/5">
                    <Link to="/" className="flex flex-grow">
                        <img
                            className="self-center object-scale-down h-10 lg:mr-2"
                            src={TWSSLogo}
                            alt={title}
                            title={title}
                        />
                        <div className="self-center hidden font-bold lg:flex w-28 dark:text-gray-200">
                            That's What
                            <br />
                            Scranton Said
                        </div>
                    </Link>
                </div>
                {props.searchbar && <div className="flex flex-grow w-full min-w-0 lg:w-4/5">{props.searchbar}</div>}
                {props.backButton && (
                    <div className="flex self-center justify-end flex-grow w-full min-w-0 lg:w-4/5 dark:text-gray-200">
                        {props.backButton}
                    </div>
                )}
                <button
                    aria-label="Toggle Dark Mode"
                    type="button"
                    className="self-center w-10 h-10 p-2 ml-4 border rounded-sm hover:opacity-50"
                    onClick={toggleTheme}
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        stroke="currentColor"
                        className="w-5 h-5 text-gray-800 dark:text-gray-200"
                    >
                        {theme === 'dark' ? (
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M12 3v1m0 16v1m9-9h-1M4 12H3m15.364 6.364l-.707-.707M6.343 6.343l-.707-.707m12.728 0l-.707.707M6.343 17.657l-.707.707M16 12a4 4 0 11-8 0 4 4 0 018 0z"
                            />
                        ) : (
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M20.354 15.354A9 9 0 018.646 3.646 9.003 9.003 0 0012 21a9.003 9.003 0 008.354-5.646z"
                            />
                        )}
                    </svg>
                </button>
            </div>
        </header>
    )
}

export default Header
